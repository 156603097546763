'use strict';

module.exports = {
    lexi: [
		{"word":"abaissant","word_nosc":"abaissant","lemma":"abaissant","pos":"ADJ"} ,
		{"word":"abaissante","word_nosc":"abaissante","lemma":"abaissant","pos":"ADJ"} ,
		{"word":"abaissé","word_nosc":"abaisse","lemma":"abaissé","pos":"ADJ"} ,
		{"word":"abaissée","word_nosc":"abaissee","lemma":"abaissé","pos":"ADJ"} ,
		{"word":"abaissées","word_nosc":"abaissees","lemma":"abaissé","pos":"ADJ"} ,
		{"word":"abaissés","word_nosc":"abaisses","lemma":"abaissé","pos":"ADJ"} ,
		{"word":"abandonnique","word_nosc":"abandonnique","lemma":"abandonnique","pos":"ADJ"} ,
		{"word":"abandonné","word_nosc":"abandonne","lemma":"abandonné","pos":"ADJ"} ,
		{"word":"abandonnée","word_nosc":"abandonnee","lemma":"abandonné","pos":"ADJ"} ,
		{"word":"abandonnées","word_nosc":"abandonnees","lemma":"abandonné","pos":"ADJ"} ,
		{"word":"abandonnés","word_nosc":"abandonnes","lemma":"abandonné","pos":"ADJ"} ,
		{"word":"abasourdi","word_nosc":"abasourdi","lemma":"abasourdi","pos":"ADJ"} ,
		{"word":"abasourdie","word_nosc":"abasourdie","lemma":"abasourdi","pos":"ADJ"} ,
		{"word":"abasourdis","word_nosc":"abasourdis","lemma":"abasourdi","pos":"ADJ"} ,
		{"word":"abasourdissant","word_nosc":"abasourdissant","lemma":"abasourdissant","pos":"ADJ"} ,
		{"word":"abasourdissants","word_nosc":"abasourdissants","lemma":"abasourdissant","pos":"ADJ"} ,
		{"word":"abattu","word_nosc":"abattu","lemma":"abattu","pos":"ADJ"} ,
		{"word":"abattue","word_nosc":"abattue","lemma":"abattu","pos":"ADJ"} ,
		{"word":"abattues","word_nosc":"abattues","lemma":"abattu","pos":"ADJ"} ,
		{"word":"abattus","word_nosc":"abattus","lemma":"abattu","pos":"ADJ"} ,
		{"word":"abbatial","word_nosc":"abbatial","lemma":"abbatial","pos":"ADJ"} ,
		{"word":"abbatiale","word_nosc":"abbatiale","lemma":"abbatial","pos":"ADJ"} ,
		{"word":"abbatiales","word_nosc":"abbatiales","lemma":"abbatial","pos":"ADJ"} ,
		{"word":"abdominal","word_nosc":"abdominal","lemma":"abdominal","pos":"ADJ"} ,
		{"word":"abdominale","word_nosc":"abdominale","lemma":"abdominal","pos":"ADJ"} ,
		{"word":"abdominales","word_nosc":"abdominales","lemma":"abdominal","pos":"ADJ"} ,
		{"word":"abdominaux","word_nosc":"abdominaux","lemma":"abdominal","pos":"ADJ"} ,
		{"word":"abducteur","word_nosc":"abducteur","lemma":"abducteur","pos":"ADJ"} ,
		{"word":"aberrant","word_nosc":"aberrant","lemma":"aberrant","pos":"ADJ"} ,
		{"word":"aberrante","word_nosc":"aberrante","lemma":"aberrant","pos":"ADJ"} ,
		{"word":"aberrantes","word_nosc":"aberrantes","lemma":"aberrant","pos":"ADJ"} ,
		{"word":"aberrants","word_nosc":"aberrants","lemma":"aberrant","pos":"ADJ"} ,
		{"word":"abhorré","word_nosc":"abhorre","lemma":"abhorré","pos":"ADJ"} ,
		{"word":"abhorrée","word_nosc":"abhorree","lemma":"abhorré","pos":"ADJ"} ,
		{"word":"abject","word_nosc":"abject","lemma":"abject","pos":"ADJ"} ,
		{"word":"abjecte","word_nosc":"abjecte","lemma":"abject","pos":"ADJ"} ,
		{"word":"abjectes","word_nosc":"abjectes","lemma":"abject","pos":"ADJ"} ,
		{"word":"abjects","word_nosc":"abjects","lemma":"abject","pos":"ADJ"} ,
		{"word":"abkhaze","word_nosc":"abkhaze","lemma":"abkhaze","pos":"ADJ"} ,
		{"word":"aboli","word_nosc":"aboli","lemma":"aboli","pos":"ADJ"} ,
		{"word":"abolie","word_nosc":"abolie","lemma":"aboli","pos":"ADJ"} ,
		{"word":"abolies","word_nosc":"abolies","lemma":"aboli","pos":"ADJ"} ,
		{"word":"abolis","word_nosc":"abolis","lemma":"aboli","pos":"ADJ"} ,
		{"word":"abolitionniste","word_nosc":"abolitionniste","lemma":"abolitionniste","pos":"ADJ"} ,
		{"word":"abolitionnistes","word_nosc":"abolitionnistes","lemma":"abolitionniste","pos":"ADJ"} ,
		{"word":"abominable","word_nosc":"abominable","lemma":"abominable","pos":"ADJ"} ,
		{"word":"abominables","word_nosc":"abominables","lemma":"abominable","pos":"ADJ"} ,
		{"word":"abondant","word_nosc":"abondant","lemma":"abondant","pos":"ADJ"} ,
		{"word":"abondante","word_nosc":"abondante","lemma":"abondant","pos":"ADJ"} ,
		{"word":"abondantes","word_nosc":"abondantes","lemma":"abondant","pos":"ADJ"} ,
		{"word":"abondants","word_nosc":"abondants","lemma":"abondant","pos":"ADJ"} ,
		{"word":"abonné","word_nosc":"abonne","lemma":"abonné","pos":"ADJ"} ,
		{"word":"abonnée","word_nosc":"abonnee","lemma":"abonné","pos":"ADJ"} ,
		{"word":"abonnés","word_nosc":"abonnes","lemma":"abonné","pos":"ADJ"} ,
		{"word":"abordable","word_nosc":"abordable","lemma":"abordable","pos":"ADJ"} ,
		{"word":"abordables","word_nosc":"abordables","lemma":"abordable","pos":"ADJ"} ,
		{"word":"abordé","word_nosc":"aborde","lemma":"abordé","pos":"ADJ"} ,
		{"word":"abordées","word_nosc":"abordees","lemma":"abordé","pos":"ADJ"} ,
		{"word":"abordés","word_nosc":"abordes","lemma":"abordé","pos":"ADJ"} ,
		{"word":"aborigène","word_nosc":"aborigene","lemma":"aborigène","pos":"ADJ"} ,
		{"word":"aborigènes","word_nosc":"aborigenes","lemma":"aborigène","pos":"ADJ"} ,
		{"word":"abortif","word_nosc":"abortif","lemma":"abortif","pos":"ADJ"} ,
		{"word":"abortive","word_nosc":"abortive","lemma":"abortif","pos":"ADJ"} ,
		{"word":"abortives","word_nosc":"abortives","lemma":"abortif","pos":"ADJ"} ,
		{"word":"abouliques","word_nosc":"abouliques","lemma":"aboulique","pos":"ADJ"} ,
		{"word":"abouti","word_nosc":"abouti","lemma":"abouti","pos":"ADJ"} ,
		{"word":"aboutie","word_nosc":"aboutie","lemma":"abouti","pos":"ADJ"} ,
		{"word":"aboutis","word_nosc":"aboutis","lemma":"abouti","pos":"ADJ"} ,
		{"word":"aboyant","word_nosc":"aboyant","lemma":"aboyant","pos":"ADJ"} ,
		{"word":"aboyante","word_nosc":"aboyante","lemma":"aboyant","pos":"ADJ"} ,
		{"word":"aboyeur","word_nosc":"aboyeur","lemma":"aboyeur","pos":"ADJ"} ,
		{"word":"aboyeurs","word_nosc":"aboyeurs","lemma":"aboyeur","pos":"ADJ"} ,
		{"word":"abracadabrant","word_nosc":"abracadabrant","lemma":"abracadabrant","pos":"ADJ"} ,
		{"word":"abracadabrante","word_nosc":"abracadabrante","lemma":"abracadabrant","pos":"ADJ"} ,
		{"word":"abracadabrantes","word_nosc":"abracadabrantes","lemma":"abracadabrant","pos":"ADJ"} ,
		{"word":"abracadabrants","word_nosc":"abracadabrants","lemma":"abracadabrant","pos":"ADJ"} ,
		{"word":"abrasif","word_nosc":"abrasif","lemma":"abrasif","pos":"ADJ"} ,
		{"word":"abrasifs","word_nosc":"abrasifs","lemma":"abrasif","pos":"ADJ"} ,
		{"word":"abrasive","word_nosc":"abrasive","lemma":"abrasif","pos":"ADJ"} ,
		{"word":"abreuvé","word_nosc":"abreuve","lemma":"abreuvé","pos":"ADJ"} ,
		{"word":"abreuvés","word_nosc":"abreuves","lemma":"abreuvé","pos":"ADJ"} ,
		{"word":"abricot","word_nosc":"abricot","lemma":"abricot","pos":"ADJ"} ,
		{"word":"abritant","word_nosc":"abritant","lemma":"abritant","pos":"ADJ"} ,
		{"word":"abrité","word_nosc":"abrite","lemma":"abrité","pos":"ADJ"} ,
		{"word":"abritée","word_nosc":"abritee","lemma":"abrité","pos":"ADJ"} ,
		{"word":"abrités","word_nosc":"abrites","lemma":"abrité","pos":"ADJ"} ,
		{"word":"abrogé","word_nosc":"abroge","lemma":"abrogé","pos":"ADJ"} ,
		{"word":"abrupt","word_nosc":"abrupt","lemma":"abrupt","pos":"ADJ"} ,
		{"word":"abrupte","word_nosc":"abrupte","lemma":"abrupt","pos":"ADJ"} ,
		{"word":"abruptes","word_nosc":"abruptes","lemma":"abrupt","pos":"ADJ"} ,
		{"word":"abrupts","word_nosc":"abrupts","lemma":"abrupt","pos":"ADJ"} ,
		{"word":"abruti","word_nosc":"abruti","lemma":"abruti","pos":"ADJ"} ,
		{"word":"abrutie","word_nosc":"abrutie","lemma":"abruti","pos":"ADJ"} ,
		{"word":"abruties","word_nosc":"abruties","lemma":"abruti","pos":"ADJ"} ,
		{"word":"abrutis","word_nosc":"abrutis","lemma":"abruti","pos":"ADJ"} ,
		{"word":"abrutissant","word_nosc":"abrutissant","lemma":"abrutissant","pos":"ADJ"} ,
		{"word":"abrutissante","word_nosc":"abrutissante","lemma":"abrutissant","pos":"ADJ"} ,
		{"word":"abrutissantes","word_nosc":"abrutissantes","lemma":"abrutissant","pos":"ADJ"} ,
		{"word":"abrutissants","word_nosc":"abrutissants","lemma":"abrutissant","pos":"ADJ"} ,
		{"word":"abrégé","word_nosc":"abrege","lemma":"abrégé","pos":"ADJ"} ,
		{"word":"abrégée","word_nosc":"abregee","lemma":"abrégé","pos":"ADJ"} ,
		{"word":"abrégés","word_nosc":"abreges","lemma":"abrégé","pos":"ADJ"} ,
		{"word":"abréviatif","word_nosc":"abreviatif","lemma":"abréviatif","pos":"ADJ"} ,
		{"word":"abscons","word_nosc":"abscons","lemma":"abscons","pos":"ADJ"} ,
		{"word":"absconse","word_nosc":"absconse","lemma":"abscons","pos":"ADJ"} ,
		{"word":"absconses","word_nosc":"absconses","lemma":"abscons","pos":"ADJ"} ,
		{"word":"absent","word_nosc":"absent","lemma":"absent","pos":"ADJ"} ,
		{"word":"absente","word_nosc":"absente","lemma":"absent","pos":"ADJ"} ,
		{"word":"absentes","word_nosc":"absentes","lemma":"absent","pos":"ADJ"} ,
		{"word":"absents","word_nosc":"absents","lemma":"absent","pos":"ADJ"} ,
		{"word":"absentéiste","word_nosc":"absenteiste","lemma":"absentéiste","pos":"ADJ"} ,
		{"word":"absidiales","word_nosc":"absidiales","lemma":"absidial","pos":"ADJ"} ,
		{"word":"absolu","word_nosc":"absolu","lemma":"absolu","pos":"ADJ"} ,
		{"word":"absolue","word_nosc":"absolue","lemma":"absolu","pos":"ADJ"} ,
		{"word":"absolues","word_nosc":"absolues","lemma":"absolu","pos":"ADJ"} ,
		{"word":"absolus","word_nosc":"absolus","lemma":"absolu","pos":"ADJ"} ,
		{"word":"absolvant","word_nosc":"absolvant","lemma":"absolvant","pos":"ADJ"} ,
		{"word":"absorbables","word_nosc":"absorbables","lemma":"absorbable","pos":"ADJ"} ,
		{"word":"absorbant","word_nosc":"absorbant","lemma":"absorbant","pos":"ADJ"} ,
		{"word":"absorbante","word_nosc":"absorbante","lemma":"absorbant","pos":"ADJ"} ,
		{"word":"absorbantes","word_nosc":"absorbantes","lemma":"absorbant","pos":"ADJ"} ,
		{"word":"absorbants","word_nosc":"absorbants","lemma":"absorbant","pos":"ADJ"} ,
		{"word":"absorbé","word_nosc":"absorbe","lemma":"absorbé","pos":"ADJ"} ,
		{"word":"absorbée","word_nosc":"absorbee","lemma":"absorbé","pos":"ADJ"} ,
		{"word":"absorbées","word_nosc":"absorbees","lemma":"absorbé","pos":"ADJ"} ,
		{"word":"absorbés","word_nosc":"absorbes","lemma":"absorbé","pos":"ADJ"} ,
		{"word":"absous","word_nosc":"absous","lemma":"absous","pos":"ADJ"} ,
		{"word":"absoute","word_nosc":"absoute","lemma":"absoute","pos":"ADJ"} ,
		{"word":"abstenue","word_nosc":"abstenue","lemma":"abstenu","pos":"ADJ"} ,
		{"word":"abstinent","word_nosc":"abstinent","lemma":"abstinent","pos":"ADJ"} ,
		{"word":"abstinente","word_nosc":"abstinente","lemma":"abstinent","pos":"ADJ"} ,
		{"word":"abstrait","word_nosc":"abstrait","lemma":"abstrait","pos":"ADJ"} ,
		{"word":"abstraite","word_nosc":"abstraite","lemma":"abstrait","pos":"ADJ"} ,
		{"word":"abstraites","word_nosc":"abstraites","lemma":"abstrait","pos":"ADJ"} ,
		{"word":"abstraits","word_nosc":"abstraits","lemma":"abstrait","pos":"ADJ"} ,
		{"word":"abstrus","word_nosc":"abstrus","lemma":"abstrus","pos":"ADJ"} ,
		{"word":"abstruse","word_nosc":"abstruse","lemma":"abstrus","pos":"ADJ"} ,
		{"word":"abstruses","word_nosc":"abstruses","lemma":"abstrus","pos":"ADJ"} ,
		{"word":"absurde","word_nosc":"absurde","lemma":"absurde","pos":"ADJ"} ,
		{"word":"absurdes","word_nosc":"absurdes","lemma":"absurde","pos":"ADJ"} ,
		{"word":"abusif","word_nosc":"abusif","lemma":"abusif","pos":"ADJ"} ,
		{"word":"abusifs","word_nosc":"abusifs","lemma":"abusif","pos":"ADJ"} ,
		{"word":"abusive","word_nosc":"abusive","lemma":"abusif","pos":"ADJ"} ,
		{"word":"abusives","word_nosc":"abusives","lemma":"abusif","pos":"ADJ"} ,
		{"word":"abusé","word_nosc":"abuse","lemma":"abusé","pos":"ADJ"} ,
		{"word":"abusée","word_nosc":"abusee","lemma":"abusé","pos":"ADJ"} ,
		{"word":"abusées","word_nosc":"abusees","lemma":"abusé","pos":"ADJ"} ,
		{"word":"abusés","word_nosc":"abuses","lemma":"abusé","pos":"ADJ"} ,
		{"word":"abyssal","word_nosc":"abyssal","lemma":"abyssal","pos":"ADJ"} ,
		{"word":"abyssale","word_nosc":"abyssale","lemma":"abyssal","pos":"ADJ"} ,
		{"word":"abyssales","word_nosc":"abyssales","lemma":"abyssal","pos":"ADJ"} ,
		{"word":"abyssin","word_nosc":"abyssin","lemma":"abyssin","pos":"ADJ"} ,
		{"word":"abyssinien","word_nosc":"abyssinien","lemma":"abyssinien","pos":"ADJ"} ,
		{"word":"abyssins","word_nosc":"abyssins","lemma":"abyssin","pos":"ADJ"} ,
		{"word":"abâtardie","word_nosc":"abatardie","lemma":"abâtardi","pos":"ADJ"} ,
		{"word":"abêti","word_nosc":"abeti","lemma":"abêti","pos":"ADJ"} ,
		{"word":"abêties","word_nosc":"abeties","lemma":"abêti","pos":"ADJ"} ,
		{"word":"abêtis","word_nosc":"abetis","lemma":"abêti","pos":"ADJ"} ,
		{"word":"acadien","word_nosc":"acadien","lemma":"acadien","pos":"ADJ"} ,
		{"word":"académique","word_nosc":"academique","lemma":"académique","pos":"ADJ"} ,
		{"word":"académiques","word_nosc":"academiques","lemma":"académique","pos":"ADJ"} ,
		{"word":"acajou","word_nosc":"acajou","lemma":"acajou","pos":"ADJ"} ,
		{"word":"acariâtre","word_nosc":"acariatre","lemma":"acariâtre","pos":"ADJ"} ,
		{"word":"acariâtres","word_nosc":"acariatres","lemma":"acariâtre","pos":"ADJ"} ,
		{"word":"accablant","word_nosc":"accablant","lemma":"accablant","pos":"ADJ"} ,
		{"word":"accablante","word_nosc":"accablante","lemma":"accablant","pos":"ADJ"} ,
		{"word":"accablantes","word_nosc":"accablantes","lemma":"accablant","pos":"ADJ"} ,
		{"word":"accablants","word_nosc":"accablants","lemma":"accablant","pos":"ADJ"} ,
		{"word":"accablé","word_nosc":"accable","lemma":"accablé","pos":"ADJ"} ,
		{"word":"accablée","word_nosc":"accablee","lemma":"accablé","pos":"ADJ"} ,
		{"word":"accablées","word_nosc":"accablees","lemma":"accablé","pos":"ADJ"} ,
		{"word":"accablés","word_nosc":"accables","lemma":"accablé","pos":"ADJ"} ,
		{"word":"accaparant","word_nosc":"accaparant","lemma":"accaparant","pos":"ADJ"} ,
		{"word":"accaparante","word_nosc":"accaparante","lemma":"accaparant","pos":"ADJ"} ,
		{"word":"accapareur","word_nosc":"accapareur","lemma":"accapareur","pos":"ADJ"} ,
		{"word":"accentué","word_nosc":"accentue","lemma":"accentué","pos":"ADJ"} ,
		{"word":"accentuée","word_nosc":"accentuee","lemma":"accentué","pos":"ADJ"} ,
		{"word":"accentuées","word_nosc":"accentuees","lemma":"accentué","pos":"ADJ"} ,
		{"word":"accentués","word_nosc":"accentues","lemma":"accentué","pos":"ADJ"} ,
		{"word":"acceptable","word_nosc":"acceptable","lemma":"acceptable","pos":"ADJ"} ,
		{"word":"acceptables","word_nosc":"acceptables","lemma":"acceptable","pos":"ADJ"} ,
		{"word":"accessible","word_nosc":"accessible","lemma":"accessible","pos":"ADJ"} ,
		{"word":"accessibles","word_nosc":"accessibles","lemma":"accessible","pos":"ADJ"} ,
		{"word":"accessoire","word_nosc":"accessoire","lemma":"accessoire","pos":"ADJ"} ,
		{"word":"accessoires","word_nosc":"accessoires","lemma":"accessoire","pos":"ADJ"} ,
		{"word":"accidentel","word_nosc":"accidentel","lemma":"accidentel","pos":"ADJ"} ,
		{"word":"accidentelle","word_nosc":"accidentelle","lemma":"accidentel","pos":"ADJ"} ,
		{"word":"accidentelles","word_nosc":"accidentelles","lemma":"accidentel","pos":"ADJ"} ,
		{"word":"accidentels","word_nosc":"accidentels","lemma":"accidentel","pos":"ADJ"} ,
		{"word":"accidenté","word_nosc":"accidente","lemma":"accidenté","pos":"ADJ"} ,
		{"word":"accidentée","word_nosc":"accidentee","lemma":"accidenté","pos":"ADJ"} ,
		{"word":"accidentées","word_nosc":"accidentees","lemma":"accidenté","pos":"ADJ"} ,
		{"word":"accidentés","word_nosc":"accidentes","lemma":"accidenté","pos":"ADJ"} ,
		{"word":"accolée","word_nosc":"accolee","lemma":"accolé","pos":"ADJ"} ,
		{"word":"accolées","word_nosc":"accolees","lemma":"accolé","pos":"ADJ"} ,
		{"word":"accolés","word_nosc":"accoles","lemma":"accolé","pos":"ADJ"} ,
		{"word":"accommodant","word_nosc":"accommodant","lemma":"accommodant","pos":"ADJ"} ,
		{"word":"accommodante","word_nosc":"accommodante","lemma":"accommodant","pos":"ADJ"} ,
		{"word":"accommodantes","word_nosc":"accommodantes","lemma":"accommodant","pos":"ADJ"} ,
		{"word":"accommodants","word_nosc":"accommodants","lemma":"accommodant","pos":"ADJ"} ,
		{"word":"accommodatrices","word_nosc":"accommodatrices","lemma":"accommodateur","pos":"ADJ"} ,
		{"word":"accompagnant","word_nosc":"accompagnant","lemma":"accompagnant","pos":"ADJ"} ,
		{"word":"accompagné","word_nosc":"accompagne","lemma":"accompagné","pos":"ADJ"} ,
		{"word":"accompagnée","word_nosc":"accompagnee","lemma":"accompagné","pos":"ADJ"} ,
		{"word":"accompagnées","word_nosc":"accompagnees","lemma":"accompagné","pos":"ADJ"} ,
		{"word":"accompagnés","word_nosc":"accompagnes","lemma":"accompagné","pos":"ADJ"} ,
		{"word":"accompli","word_nosc":"accompli","lemma":"accompli","pos":"ADJ"} ,
		{"word":"accomplie","word_nosc":"accomplie","lemma":"accompli","pos":"ADJ"} ,
		{"word":"accomplies","word_nosc":"accomplies","lemma":"accompli","pos":"ADJ"} ,
		{"word":"accomplis","word_nosc":"accomplis","lemma":"accompli","pos":"ADJ"} ,
		{"word":"accore","word_nosc":"accore","lemma":"accore","pos":"ADJ"} ,
		{"word":"accort","word_nosc":"accort","lemma":"accort","pos":"ADJ"} ,
		{"word":"accorte","word_nosc":"accorte","lemma":"accort","pos":"ADJ"} ,
		{"word":"accortes","word_nosc":"accortes","lemma":"accort","pos":"ADJ"} ,
		{"word":"accorts","word_nosc":"accorts","lemma":"accort","pos":"ADJ"} ,
		{"word":"accostant","word_nosc":"accostant","lemma":"accostant","pos":"ADJ"} ,
		{"word":"accouchant","word_nosc":"accouchant","lemma":"accouchant","pos":"ADJ"} ,
		{"word":"accoudé","word_nosc":"accoude","lemma":"accoudé","pos":"ADJ"} ,
		{"word":"accoudée","word_nosc":"accoudee","lemma":"accoudé","pos":"ADJ"} ,
		{"word":"accoudés","word_nosc":"accoudes","lemma":"accoudé","pos":"ADJ"} ,
		{"word":"accoutré","word_nosc":"accoutre","lemma":"accoutré","pos":"ADJ"} ,
		{"word":"accoutrée","word_nosc":"accoutree","lemma":"accoutré","pos":"ADJ"} ,
		{"word":"accoutrés","word_nosc":"accoutres","lemma":"accoutré","pos":"ADJ"} ,
		{"word":"accoutumé","word_nosc":"accoutume","lemma":"accoutumé","pos":"ADJ"} ,
		{"word":"accoutumée","word_nosc":"accoutumee","lemma":"accoutumé","pos":"ADJ"} ,
		{"word":"accoutumées","word_nosc":"accoutumees","lemma":"accoutumé","pos":"ADJ"} ,
		{"word":"accoutumés","word_nosc":"accoutumes","lemma":"accoutumé","pos":"ADJ"} ,
		{"word":"accro","word_nosc":"accro","lemma":"accro","pos":"ADJ"} ,
		{"word":"accrochant","word_nosc":"accrochant","lemma":"accrochant","pos":"ADJ"} ,
		{"word":"accrocheur","word_nosc":"accrocheur","lemma":"accrocheur","pos":"ADJ"} ,
		{"word":"accrocheurs","word_nosc":"accrocheurs","lemma":"accrocheur","pos":"ADJ"} ,
		{"word":"accrocheuse","word_nosc":"accrocheuse","lemma":"accrocheur","pos":"ADJ"} ,
		{"word":"accrocheuses","word_nosc":"accrocheuses","lemma":"accrocheur","pos":"ADJ"} ,
		{"word":"accroché","word_nosc":"accroche","lemma":"accroché","pos":"ADJ"} ,
		{"word":"accrochée","word_nosc":"accrochee","lemma":"accroché","pos":"ADJ"} ,
		{"word":"accrochées","word_nosc":"accrochees","lemma":"accroché","pos":"ADJ"} ,
		{"word":"accrochés","word_nosc":"accroches","lemma":"accroché","pos":"ADJ"} ,
		{"word":"accros","word_nosc":"accros","lemma":"accro","pos":"ADJ"} ,
		{"word":"accroupi","word_nosc":"accroupi","lemma":"accroupi","pos":"ADJ"} ,
		{"word":"accroupie","word_nosc":"accroupie","lemma":"accroupi","pos":"ADJ"} ,
		{"word":"accroupies","word_nosc":"accroupies","lemma":"accroupi","pos":"ADJ"} ,
		{"word":"accroupis","word_nosc":"accroupis","lemma":"accroupi","pos":"ADJ"} ,
		{"word":"accru","word_nosc":"accru","lemma":"accru","pos":"ADJ"} ,
		{"word":"accrue","word_nosc":"accrue","lemma":"accru","pos":"ADJ"} ,
		{"word":"accrues","word_nosc":"accrues","lemma":"accru","pos":"ADJ"} ,
		{"word":"accrus","word_nosc":"accrus","lemma":"accru","pos":"ADJ"} ,
		{"word":"accrédité","word_nosc":"accredite","lemma":"accrédité","pos":"ADJ"} ,
		{"word":"accréditée","word_nosc":"accreditee","lemma":"accrédité","pos":"ADJ"} ,
		{"word":"accrédités","word_nosc":"accredites","lemma":"accrédité","pos":"ADJ"} ,
		{"word":"accueillant","word_nosc":"accueillant","lemma":"accueillant","pos":"ADJ"} ,
		{"word":"accueillante","word_nosc":"accueillante","lemma":"accueillant","pos":"ADJ"} ,
		{"word":"accueillantes","word_nosc":"accueillantes","lemma":"accueillant","pos":"ADJ"} ,
		{"word":"accueillants","word_nosc":"accueillants","lemma":"accueillant","pos":"ADJ"} ,
		{"word":"acculé","word_nosc":"accule","lemma":"acculé","pos":"ADJ"} ,
		{"word":"acculés","word_nosc":"accules","lemma":"acculé","pos":"ADJ"} ,
		{"word":"accusateur","word_nosc":"accusateur","lemma":"accusateur","pos":"ADJ"} ,
		{"word":"accusateurs","word_nosc":"accusateurs","lemma":"accusateur","pos":"ADJ"} ,
		{"word":"accusatoire","word_nosc":"accusatoire","lemma":"accusatoire","pos":"ADJ"} ,
		{"word":"accusatrice","word_nosc":"accusatrice","lemma":"accusateur","pos":"ADJ"} ,
		{"word":"accusé","word_nosc":"accuse","lemma":"accusé","pos":"ADJ"} ,
		{"word":"accusée","word_nosc":"accusee","lemma":"accusé","pos":"ADJ"} ,
		{"word":"accusées","word_nosc":"accusees","lemma":"accusé","pos":"ADJ"} ,
		{"word":"accusés","word_nosc":"accuses","lemma":"accusé","pos":"ADJ"} ,
		{"word":"accélérant","word_nosc":"accelerant","lemma":"accélérant","pos":"ADJ"} ,
		{"word":"accélérants","word_nosc":"accelerants","lemma":"accélérant","pos":"ADJ"} ,
		{"word":"accélérateur","word_nosc":"accelerateur","lemma":"accélérateur","pos":"ADJ"} ,
		{"word":"accélérateurs","word_nosc":"accelerateurs","lemma":"accélérateur","pos":"ADJ"} ,
		{"word":"accélératrice","word_nosc":"acceleratrice","lemma":"accélérateur","pos":"ADJ"} ,
		{"word":"accéléré","word_nosc":"accelere","lemma":"accéléré","pos":"ADJ"} ,
		{"word":"accélérés","word_nosc":"acceleres","lemma":"accéléré","pos":"ADJ"} ,
		{"word":"acellulaire","word_nosc":"acellulaire","lemma":"acellulaire","pos":"ADJ"} ,
		{"word":"acerbe","word_nosc":"acerbe","lemma":"acerbe","pos":"ADJ"} ,
		{"word":"acerbes","word_nosc":"acerbes","lemma":"acerbe","pos":"ADJ"} ,
		{"word":"achalandé","word_nosc":"achalande","lemma":"achalandé","pos":"ADJ"} ,
		{"word":"achalandée","word_nosc":"achalandee","lemma":"achalandé","pos":"ADJ"} ,
		{"word":"achalandées","word_nosc":"achalandees","lemma":"achalandé","pos":"ADJ"} ,
		{"word":"achalandés","word_nosc":"achalandes","lemma":"achalandé","pos":"ADJ"} ,
		{"word":"acharné","word_nosc":"acharne","lemma":"acharné","pos":"ADJ"} ,
		{"word":"acharnée","word_nosc":"acharnee","lemma":"acharné","pos":"ADJ"} ,
		{"word":"acharnées","word_nosc":"acharnees","lemma":"acharné","pos":"ADJ"} ,
		{"word":"acharnés","word_nosc":"acharnes","lemma":"acharné","pos":"ADJ"} ,
		{"word":"achetable","word_nosc":"achetable","lemma":"achetable","pos":"ADJ"} ,
		{"word":"achevé","word_nosc":"acheve","lemma":"achevé","pos":"ADJ"} ,
		{"word":"achevée","word_nosc":"achevee","lemma":"achevé","pos":"ADJ"} ,
		{"word":"achevées","word_nosc":"achevees","lemma":"achevé","pos":"ADJ"} ,
		{"word":"achevés","word_nosc":"acheves","lemma":"achevé","pos":"ADJ"} ,
		{"word":"acide","word_nosc":"acide","lemma":"acide","pos":"ADJ"} ,
		{"word":"acides","word_nosc":"acides","lemma":"acide","pos":"ADJ"} ,
		{"word":"acidulé","word_nosc":"acidule","lemma":"acidulé","pos":"ADJ"} ,
		{"word":"acidulée","word_nosc":"acidulee","lemma":"acidulé","pos":"ADJ"} ,
		{"word":"acidulées","word_nosc":"acidulees","lemma":"acidulé","pos":"ADJ"} ,
		{"word":"acidulés","word_nosc":"acidules","lemma":"acidulé","pos":"ADJ"} ,
		{"word":"acnéiques","word_nosc":"acneiques","lemma":"acnéique","pos":"ADJ"} ,
		{"word":"acoustique","word_nosc":"acoustique","lemma":"acoustique","pos":"ADJ"} ,
		{"word":"acoustiques","word_nosc":"acoustiques","lemma":"acoustique","pos":"ADJ"} ,
		{"word":"acquis","word_nosc":"acquis","lemma":"acquis","pos":"ADJ"} ,
		{"word":"acquise","word_nosc":"acquise","lemma":"acquis","pos":"ADJ"} ,
		{"word":"acquises","word_nosc":"acquises","lemma":"acquis","pos":"ADJ"} ,
		{"word":"acquitté","word_nosc":"acquitte","lemma":"acquitté","pos":"ADJ"} ,
		{"word":"acquittée","word_nosc":"acquittee","lemma":"acquitté","pos":"ADJ"} ,
		{"word":"acquittés","word_nosc":"acquittes","lemma":"acquitté","pos":"ADJ"} ,
		{"word":"acrimonieuse","word_nosc":"acrimonieuse","lemma":"acrimonieux","pos":"ADJ"} ,
		{"word":"acrimonieuses","word_nosc":"acrimonieuses","lemma":"acrimonieux","pos":"ADJ"} ,
		{"word":"acrimonieux","word_nosc":"acrimonieux","lemma":"acrimonieux","pos":"ADJ"} ,
		{"word":"acrobatique","word_nosc":"acrobatique","lemma":"acrobatique","pos":"ADJ"} ,
		{"word":"acrobatiques","word_nosc":"acrobatiques","lemma":"acrobatique","pos":"ADJ"} ,
		{"word":"acrocéphale","word_nosc":"acrocephale","lemma":"acrocéphale","pos":"ADJ"} ,
		{"word":"acrylique","word_nosc":"acrylique","lemma":"acrylique","pos":"ADJ"} ,
		{"word":"acryliques","word_nosc":"acryliques","lemma":"acrylique","pos":"ADJ"} ,
		{"word":"actif","word_nosc":"actif","lemma":"actif","pos":"ADJ"} ,
		{"word":"actifs","word_nosc":"actifs","lemma":"actif","pos":"ADJ"} ,
		{"word":"activant","word_nosc":"activant","lemma":"activant","pos":"ADJ"} ,
		{"word":"active","word_nosc":"active","lemma":"actif","pos":"ADJ"} ,
		{"word":"actives","word_nosc":"actives","lemma":"actif","pos":"ADJ"} ,
		{"word":"activiste","word_nosc":"activiste","lemma":"activiste","pos":"ADJ"} ,
		{"word":"activistes","word_nosc":"activistes","lemma":"activiste","pos":"ADJ"} ,
		{"word":"activé","word_nosc":"active","lemma":"activé","pos":"ADJ"} ,
		{"word":"activée","word_nosc":"activee","lemma":"activé","pos":"ADJ"} ,
		{"word":"activées","word_nosc":"activees","lemma":"activé","pos":"ADJ"} ,
		{"word":"activés","word_nosc":"actives","lemma":"activé","pos":"ADJ"} ,
		{"word":"actuariel","word_nosc":"actuariel","lemma":"actuariel","pos":"ADJ"} ,
		{"word":"actuel","word_nosc":"actuel","lemma":"actuel","pos":"ADJ"} ,
		{"word":"actuelle","word_nosc":"actuelle","lemma":"actuel","pos":"ADJ"} ,
		{"word":"actuelles","word_nosc":"actuelles","lemma":"actuel","pos":"ADJ"} ,
		{"word":"actuels","word_nosc":"actuels","lemma":"actuel","pos":"ADJ"} ,
		{"word":"acéphale","word_nosc":"acephale","lemma":"acéphale","pos":"ADJ"} ,
		{"word":"acéré","word_nosc":"acere","lemma":"acéré","pos":"ADJ"} ,
		{"word":"acérée","word_nosc":"aceree","lemma":"acéré","pos":"ADJ"} ,
		{"word":"acérées","word_nosc":"acerees","lemma":"acéré","pos":"ADJ"} ,
		{"word":"acérés","word_nosc":"aceres","lemma":"acéré","pos":"ADJ"} ,
		{"word":"acétique","word_nosc":"acetique","lemma":"acétique","pos":"ADJ"} ,
		{"word":"acétylsalicylique","word_nosc":"acetylsalicylique","lemma":"acétylsalicylique","pos":"ADJ"} ,
		{"word":"ad hoc","word_nosc":"ad hoc","lemma":"ad hoc","pos":"ADJ"} ,
		{"word":"adamantin","word_nosc":"adamantin","lemma":"adamantin","pos":"ADJ"} ,
		{"word":"adamantine","word_nosc":"adamantine","lemma":"adamantin","pos":"ADJ"} ,
		{"word":"adamique","word_nosc":"adamique","lemma":"adamique","pos":"ADJ"} ,
		{"word":"adaptable","word_nosc":"adaptable","lemma":"adaptable","pos":"ADJ"} ,
		{"word":"adaptables","word_nosc":"adaptables","lemma":"adaptable","pos":"ADJ"} ,
		{"word":"adaptatif","word_nosc":"adaptatif","lemma":"adaptatif","pos":"ADJ"} ,
		{"word":"adaptatifs","word_nosc":"adaptatifs","lemma":"adaptatif","pos":"ADJ"} ,
		{"word":"additif","word_nosc":"additif","lemma":"additif","pos":"ADJ"} ,
		{"word":"additifs","word_nosc":"additifs","lemma":"additif","pos":"ADJ"} ,
		{"word":"additionnel","word_nosc":"additionnel","lemma":"additionnel","pos":"ADJ"} ,
		{"word":"additionnelle","word_nosc":"additionnelle","lemma":"additionnel","pos":"ADJ"} ,
		{"word":"additionnelles","word_nosc":"additionnelles","lemma":"additionnel","pos":"ADJ"} ,
		{"word":"additionnels","word_nosc":"additionnels","lemma":"additionnel","pos":"ADJ"} ,
		{"word":"adhérent","word_nosc":"adherent","lemma":"adhérent","pos":"ADJ"} ,
		{"word":"adhérente","word_nosc":"adherente","lemma":"adhérent","pos":"ADJ"} ,
		{"word":"adhérentes","word_nosc":"adherentes","lemma":"adhérent","pos":"ADJ"} ,
		{"word":"adhérents","word_nosc":"adherents","lemma":"adhérent","pos":"ADJ"} ,
		{"word":"adhésif","word_nosc":"adhesif","lemma":"adhésif","pos":"ADJ"} ,
		{"word":"adhésifs","word_nosc":"adhesifs","lemma":"adhésif","pos":"ADJ"} ,
		{"word":"adhésive","word_nosc":"adhesive","lemma":"adhésif","pos":"ADJ"} ,
		{"word":"adhésives","word_nosc":"adhesives","lemma":"adhésif","pos":"ADJ"} ,
		{"word":"adipeuse","word_nosc":"adipeuse","lemma":"adipeux","pos":"ADJ"} ,
		{"word":"adipeuses","word_nosc":"adipeuses","lemma":"adipeux","pos":"ADJ"} ,
		{"word":"adipeux","word_nosc":"adipeux","lemma":"adipeux","pos":"ADJ"} ,
		{"word":"adipique","word_nosc":"adipique","lemma":"adipique","pos":"ADJ"} ,
		{"word":"adjacent","word_nosc":"adjacent","lemma":"adjacent","pos":"ADJ"} ,
		{"word":"adjacente","word_nosc":"adjacente","lemma":"adjacent","pos":"ADJ"} ,
		{"word":"adjacentes","word_nosc":"adjacentes","lemma":"adjacent","pos":"ADJ"} ,
		{"word":"adjacents","word_nosc":"adjacents","lemma":"adjacent","pos":"ADJ"} ,
		{"word":"adjectif","word_nosc":"adjectif","lemma":"adjectif","pos":"ADJ"} ,
		{"word":"adjective","word_nosc":"adjective","lemma":"adjectif","pos":"ADJ"} ,
		{"word":"adjoint","word_nosc":"adjoint","lemma":"adjoint","pos":"ADJ"} ,
		{"word":"adjointe","word_nosc":"adjointe","lemma":"adjoint","pos":"ADJ"} ,
		{"word":"adjoints","word_nosc":"adjoints","lemma":"adjoint","pos":"ADJ"} ,
		{"word":"adjuvants","word_nosc":"adjuvants","lemma":"adjuvant","pos":"ADJ"} ,
		{"word":"administrant","word_nosc":"administrant","lemma":"administrant","pos":"ADJ"} ,
		{"word":"administratif","word_nosc":"administratif","lemma":"administratif","pos":"ADJ"} ,
		{"word":"administratifs","word_nosc":"administratifs","lemma":"administratif","pos":"ADJ"} ,
		{"word":"administrative","word_nosc":"administrative","lemma":"administratif","pos":"ADJ"} ,
		{"word":"administratives","word_nosc":"administratives","lemma":"administratif","pos":"ADJ"} ,
		{"word":"administré","word_nosc":"administre","lemma":"administré","pos":"ADJ"} ,
		{"word":"administrée","word_nosc":"administree","lemma":"administré","pos":"ADJ"} ,
		{"word":"administrées","word_nosc":"administrees","lemma":"administré","pos":"ADJ"} ,
		{"word":"admirable","word_nosc":"admirable","lemma":"admirable","pos":"ADJ"} ,
		{"word":"admirables","word_nosc":"admirables","lemma":"admirable","pos":"ADJ"} ,
		{"word":"admirateur","word_nosc":"admirateur","lemma":"admirateur","pos":"ADJ"} ,
		{"word":"admirateurs","word_nosc":"admirateurs","lemma":"admirateur","pos":"ADJ"} ,
		{"word":"admiratif","word_nosc":"admiratif","lemma":"admiratif","pos":"ADJ"} ,
		{"word":"admiratifs","word_nosc":"admiratifs","lemma":"admiratif","pos":"ADJ"} ,
		{"word":"admirative","word_nosc":"admirative","lemma":"admiratif","pos":"ADJ"} ,
		{"word":"admiratives","word_nosc":"admiratives","lemma":"admiratif","pos":"ADJ"} ,
		{"word":"admiratrice","word_nosc":"admiratrice","lemma":"admirateur","pos":"ADJ"} ,
		{"word":"admis","word_nosc":"admis","lemma":"admis","pos":"ADJ"} ,
		{"word":"admise","word_nosc":"admise","lemma":"admis","pos":"ADJ"} ,
		{"word":"admises","word_nosc":"admises","lemma":"admis","pos":"ADJ"} ,
		{"word":"admissible","word_nosc":"admissible","lemma":"admissible","pos":"ADJ"} ,
		{"word":"admissibles","word_nosc":"admissibles","lemma":"admissible","pos":"ADJ"} ,
		{"word":"adolescent","word_nosc":"adolescent","lemma":"adolescent","pos":"ADJ"} ,
		{"word":"adolescente","word_nosc":"adolescente","lemma":"adolescent","pos":"ADJ"} ,
		{"word":"adolescentes","word_nosc":"adolescentes","lemma":"adolescent","pos":"ADJ"} ,
		{"word":"adolescents","word_nosc":"adolescents","lemma":"adolescent","pos":"ADJ"} ,
		{"word":"adoptable","word_nosc":"adoptable","lemma":"adoptable","pos":"ADJ"} ,
		{"word":"adoptant","word_nosc":"adoptant","lemma":"adoptant","pos":"ADJ"} ,
		{"word":"adoptif","word_nosc":"adoptif","lemma":"adoptif","pos":"ADJ"} ,
		{"word":"adoptifs","word_nosc":"adoptifs","lemma":"adoptif","pos":"ADJ"} ,
		{"word":"adoptive","word_nosc":"adoptive","lemma":"adoptif","pos":"ADJ"} ,
		{"word":"adoptives","word_nosc":"adoptives","lemma":"adoptif","pos":"ADJ"} ,
		{"word":"adopté","word_nosc":"adopte","lemma":"adopté","pos":"ADJ"} ,
		{"word":"adoptée","word_nosc":"adoptee","lemma":"adopté","pos":"ADJ"} ,
		{"word":"adoptées","word_nosc":"adoptees","lemma":"adopté","pos":"ADJ"} ,
		{"word":"adoptés","word_nosc":"adoptes","lemma":"adopté","pos":"ADJ"} ,
		{"word":"adorable","word_nosc":"adorable","lemma":"adorable","pos":"ADJ"} ,
		{"word":"adorables","word_nosc":"adorables","lemma":"adorable","pos":"ADJ"} ,
		{"word":"adorant","word_nosc":"adorant","lemma":"adorant","pos":"ADJ"} ,
		{"word":"adorante","word_nosc":"adorante","lemma":"adorant","pos":"ADJ"} ,
		{"word":"adorants","word_nosc":"adorants","lemma":"adorant","pos":"ADJ"} ,
		{"word":"adossé","word_nosc":"adosse","lemma":"adossé","pos":"ADJ"} ,
		{"word":"adossée","word_nosc":"adossee","lemma":"adossé","pos":"ADJ"} ,
		{"word":"adossés","word_nosc":"adosses","lemma":"adossé","pos":"ADJ"} ,
		{"word":"adouci","word_nosc":"adouci","lemma":"adouci","pos":"ADJ"} ,
		{"word":"adoucis","word_nosc":"adoucis","lemma":"adouci","pos":"ADJ"} ,
		{"word":"adoucissant","word_nosc":"adoucissant","lemma":"adoucissant","pos":"ADJ"} ,
		{"word":"adoucissantes","word_nosc":"adoucissantes","lemma":"adoucissant","pos":"ADJ"} ,
		{"word":"adragante","word_nosc":"adragante","lemma":"adragant","pos":"ADJ"} ,
		{"word":"adriatique","word_nosc":"adriatique","lemma":"adriatique","pos":"ADJ"} ,
		{"word":"adriatiques","word_nosc":"adriatiques","lemma":"adriatique","pos":"ADJ"} ,
		{"word":"adroit","word_nosc":"adroit","lemma":"adroit","pos":"ADJ"} ,
		{"word":"adroite","word_nosc":"adroite","lemma":"adroit","pos":"ADJ"} ,
		{"word":"adroites","word_nosc":"adroites","lemma":"adroit","pos":"ADJ"} ,
		{"word":"adroits","word_nosc":"adroits","lemma":"adroit","pos":"ADJ"} ,
		{"word":"adulateur","word_nosc":"adulateur","lemma":"adulateur","pos":"ADJ"} ,
		{"word":"adulte","word_nosc":"adulte","lemma":"adulte","pos":"ADJ"} ,
		{"word":"adultes","word_nosc":"adultes","lemma":"adulte","pos":"ADJ"} ,
		{"word":"adultère","word_nosc":"adultere","lemma":"adultère","pos":"ADJ"} ,
		{"word":"adultères","word_nosc":"adulteres","lemma":"adultère","pos":"ADJ"} ,
		{"word":"adultérin","word_nosc":"adulterin","lemma":"adultérin","pos":"ADJ"} ,
		{"word":"adultérine","word_nosc":"adulterine","lemma":"adultérin","pos":"ADJ"} ,
		{"word":"adultérins","word_nosc":"adulterins","lemma":"adultérin","pos":"ADJ"} ,
		{"word":"adventices","word_nosc":"adventices","lemma":"adventice","pos":"ADJ"} ,
		{"word":"adventiste","word_nosc":"adventiste","lemma":"adventiste","pos":"ADJ"} ,
		{"word":"advenue","word_nosc":"advenue","lemma":"advenu","pos":"ADJ"} ,
		{"word":"adverse","word_nosc":"adverse","lemma":"adverse","pos":"ADJ"} ,
		{"word":"adverses","word_nosc":"adverses","lemma":"adverse","pos":"ADJ"} ,
		{"word":"adéquat","word_nosc":"adequat","lemma":"adéquat","pos":"ADJ"} ,
		{"word":"adéquate","word_nosc":"adequate","lemma":"adéquat","pos":"ADJ"} ,
		{"word":"adéquates","word_nosc":"adequates","lemma":"adéquat","pos":"ADJ"} ,
		{"word":"adéquats","word_nosc":"adequats","lemma":"adéquat","pos":"ADJ"} ,
		{"word":"affable","word_nosc":"affable","lemma":"affable","pos":"ADJ"} ,
		{"word":"affables","word_nosc":"affables","lemma":"affable","pos":"ADJ"} ,
		{"word":"affaibli","word_nosc":"affaibli","lemma":"affaibli","pos":"ADJ"} ,
		{"word":"affaiblie","word_nosc":"affaiblie","lemma":"affaibli","pos":"ADJ"} ,
		{"word":"affaiblies","word_nosc":"affaiblies","lemma":"affaibli","pos":"ADJ"} ,
		{"word":"affaiblis","word_nosc":"affaiblis","lemma":"affaibli","pos":"ADJ"} ,
		{"word":"affaiblissant","word_nosc":"affaiblissant","lemma":"affaiblissant","pos":"ADJ"} ,
		{"word":"affairiste","word_nosc":"affairiste","lemma":"affairiste","pos":"ADJ"} ,
		{"word":"affairé","word_nosc":"affaire","lemma":"affairé","pos":"ADJ"} ,
		{"word":"affairée","word_nosc":"affairee","lemma":"affairé","pos":"ADJ"} ,
		{"word":"affairées","word_nosc":"affairees","lemma":"affairé","pos":"ADJ"} ,
		{"word":"affairés","word_nosc":"affaires","lemma":"affairé","pos":"ADJ"} ,
		{"word":"affamantes","word_nosc":"affamantes","lemma":"affamant","pos":"ADJ"} ,
		{"word":"affamé","word_nosc":"affame","lemma":"affamé","pos":"ADJ"} ,
		{"word":"affamée","word_nosc":"affamee","lemma":"affamé","pos":"ADJ"} ,
		{"word":"affamées","word_nosc":"affamees","lemma":"affamé","pos":"ADJ"} ,
		{"word":"affamés","word_nosc":"affames","lemma":"affamé","pos":"ADJ"} ,
		{"word":"affectif","word_nosc":"affectif","lemma":"affectif","pos":"ADJ"} ,
		{"word":"affectifs","word_nosc":"affectifs","lemma":"affectif","pos":"ADJ"} ,
		{"word":"affectionné","word_nosc":"affectionne","lemma":"affectionné","pos":"ADJ"} ,
		{"word":"affectionnée","word_nosc":"affectionnee","lemma":"affectionné","pos":"ADJ"} ,
		{"word":"affectionnés","word_nosc":"affectionnes","lemma":"affectionné","pos":"ADJ"} ,
		{"word":"affective","word_nosc":"affective","lemma":"affectif","pos":"ADJ"} ,
		{"word":"affectives","word_nosc":"affectives","lemma":"affectif","pos":"ADJ"} ,
		{"word":"affectueuse","word_nosc":"affectueuse","lemma":"affectueux","pos":"ADJ"} ,
		{"word":"affectueuses","word_nosc":"affectueuses","lemma":"affectueux","pos":"ADJ"} ,
		{"word":"affectueux","word_nosc":"affectueux","lemma":"affectueux","pos":"ADJ"} ,
		{"word":"affecté","word_nosc":"affecte","lemma":"affecté","pos":"ADJ"} ,
		{"word":"affectée","word_nosc":"affectee","lemma":"affecté","pos":"ADJ"} ,
		{"word":"affectées","word_nosc":"affectees","lemma":"affecté","pos":"ADJ"} ,
		{"word":"affectés","word_nosc":"affectes","lemma":"affecté","pos":"ADJ"} ,
		{"word":"affichant","word_nosc":"affichant","lemma":"affichant","pos":"ADJ"} ,
		{"word":"affidée","word_nosc":"affidee","lemma":"affidé","pos":"ADJ"} ,
		{"word":"affilié","word_nosc":"affilie","lemma":"affilié","pos":"ADJ"} ,
		{"word":"affiliée","word_nosc":"affiliee","lemma":"affilié","pos":"ADJ"} ,
		{"word":"affiliées","word_nosc":"affiliees","lemma":"affilié","pos":"ADJ"} ,
		{"word":"affiliés","word_nosc":"affilies","lemma":"affilié","pos":"ADJ"} ,
		{"word":"affilé","word_nosc":"affile","lemma":"affilé","pos":"ADJ"} ,
		{"word":"affilée","word_nosc":"affilee","lemma":"affilé","pos":"ADJ"} ,
		{"word":"affilées","word_nosc":"affilees","lemma":"affilé","pos":"ADJ"} ,
		{"word":"affilés","word_nosc":"affiles","lemma":"affilé","pos":"ADJ"} ,
		{"word":"affin","word_nosc":"affin","lemma":"affin","pos":"ADJ"} ,
		{"word":"affiné","word_nosc":"affine","lemma":"affiné","pos":"ADJ"} ,
		{"word":"affinée","word_nosc":"affinee","lemma":"affiné","pos":"ADJ"} ,
		{"word":"affirmatif","word_nosc":"affirmatif","lemma":"affirmatif","pos":"ADJ"} ,
		{"word":"affirmatifs","word_nosc":"affirmatifs","lemma":"affirmatif","pos":"ADJ"} ,
		{"word":"affirmative","word_nosc":"affirmative","lemma":"affirmatif","pos":"ADJ"} ,
		{"word":"affixé","word_nosc":"affixe","lemma":"affixé","pos":"ADJ"} ,
		{"word":"affleurant","word_nosc":"affleurant","lemma":"affleurant","pos":"ADJ"} ,
		{"word":"affligeant","word_nosc":"affligeant","lemma":"affligeant","pos":"ADJ"} ,
		{"word":"affligeante","word_nosc":"affligeante","lemma":"affligeant","pos":"ADJ"} ,
		{"word":"affligeantes","word_nosc":"affligeantes","lemma":"affligeant","pos":"ADJ"} ,
		{"word":"affligeants","word_nosc":"affligeants","lemma":"affligeant","pos":"ADJ"} ,
		{"word":"affligé","word_nosc":"afflige","lemma":"affligé","pos":"ADJ"} ,
		{"word":"affligée","word_nosc":"affligee","lemma":"affligé","pos":"ADJ"} ,
		{"word":"affligés","word_nosc":"affliges","lemma":"affligé","pos":"ADJ"} ,
		{"word":"affluent","word_nosc":"affluent","lemma":"affluent","pos":"ADJ"} ,
		{"word":"affluentes","word_nosc":"affluentes","lemma":"affluent","pos":"ADJ"} ,
		{"word":"affluents","word_nosc":"affluents","lemma":"affluent","pos":"ADJ"} ,
		{"word":"affolant","word_nosc":"affolant","lemma":"affolant","pos":"ADJ"} ,
		{"word":"affolante","word_nosc":"affolante","lemma":"affolant","pos":"ADJ"} ,
		{"word":"affolantes","word_nosc":"affolantes","lemma":"affolant","pos":"ADJ"} ,
		{"word":"affolants","word_nosc":"affolants","lemma":"affolant","pos":"ADJ"} ,
		{"word":"affolé","word_nosc":"affole","lemma":"affolé","pos":"ADJ"} ,
		{"word":"affolée","word_nosc":"affolee","lemma":"affolé","pos":"ADJ"} ,
		{"word":"affolées","word_nosc":"affolees","lemma":"affolé","pos":"ADJ"} ,
		{"word":"affolés","word_nosc":"affoles","lemma":"affolé","pos":"ADJ"} ,
		{"word":"affranchi","word_nosc":"affranchi","lemma":"affranchi","pos":"ADJ"} ,
		{"word":"affranchie","word_nosc":"affranchie","lemma":"affranchi","pos":"ADJ"} ,
		{"word":"affranchies","word_nosc":"affranchies","lemma":"affranchi","pos":"ADJ"} ,
		{"word":"affranchis","word_nosc":"affranchis","lemma":"affranchi","pos":"ADJ"} ,
		{"word":"affreuse","word_nosc":"affreuse","lemma":"affreux","pos":"ADJ"} ,
		{"word":"affreuses","word_nosc":"affreuses","lemma":"affreux","pos":"ADJ"} ,
		{"word":"affreux","word_nosc":"affreux","lemma":"affreux","pos":"ADJ"} ,
		{"word":"affriolant","word_nosc":"affriolant","lemma":"affriolant","pos":"ADJ"} ,
		{"word":"affriolante","word_nosc":"affriolante","lemma":"affriolant","pos":"ADJ"} ,
		{"word":"affriolantes","word_nosc":"affriolantes","lemma":"affriolant","pos":"ADJ"} ,
		{"word":"affriolants","word_nosc":"affriolants","lemma":"affriolant","pos":"ADJ"} ,
		{"word":"affrontées","word_nosc":"affrontees","lemma":"affronté","pos":"ADJ"} ,
		{"word":"affrontés","word_nosc":"affrontes","lemma":"affronté","pos":"ADJ"} ,
		{"word":"affublé","word_nosc":"affuble","lemma":"affublé","pos":"ADJ"} ,
		{"word":"affublée","word_nosc":"affublee","lemma":"affublé","pos":"ADJ"} ,
		{"word":"affublés","word_nosc":"affubles","lemma":"affublé","pos":"ADJ"} ,
		{"word":"afférent","word_nosc":"afferent","lemma":"afférent","pos":"ADJ"} ,
		{"word":"afférente","word_nosc":"afferente","lemma":"afférent","pos":"ADJ"} ,
		{"word":"afférentes","word_nosc":"afferentes","lemma":"afférent","pos":"ADJ"} ,
		{"word":"afférents","word_nosc":"afferents","lemma":"afférent","pos":"ADJ"} ,
		{"word":"affétées","word_nosc":"affetees","lemma":"affété","pos":"ADJ"} ,
		{"word":"afghan","word_nosc":"afghan","lemma":"afghan","pos":"ADJ"} ,
		{"word":"afghane","word_nosc":"afghane","lemma":"afghan","pos":"ADJ"} ,
		{"word":"afghanes","word_nosc":"afghanes","lemma":"afghan","pos":"ADJ"} ,
		{"word":"afghans","word_nosc":"afghans","lemma":"afghan","pos":"ADJ"} ,
		{"word":"africain","word_nosc":"africain","lemma":"africain","pos":"ADJ"} ,
		{"word":"africaine","word_nosc":"africaine","lemma":"africain","pos":"ADJ"} ,
		{"word":"africaines","word_nosc":"africaines","lemma":"africain","pos":"ADJ"} ,
		{"word":"africains","word_nosc":"africains","lemma":"africain","pos":"ADJ"} ,
		{"word":"afrikaans","word_nosc":"afrikaans","lemma":"afrikaans","pos":"ADJ"} ,
		{"word":"afro","word_nosc":"afro","lemma":"afro","pos":"ADJ"} ,
		{"word":"afro-américain","word_nosc":"afro-americain","lemma":"afro-américain","pos":"ADJ"} ,
		{"word":"afro-américaine","word_nosc":"afro-americaine","lemma":"afro-américain","pos":"ADJ"} ,
		{"word":"afro-américains","word_nosc":"afro-americains","lemma":"afro-américain","pos":"ADJ"} ,
		{"word":"afro-asiatique","word_nosc":"afro-asiatique","lemma":"afro-asiatique","pos":"ADJ"} ,
		{"word":"afro-brésilienne","word_nosc":"afro-bresilienne","lemma":"afro-brésilien","pos":"ADJ"} ,
		{"word":"afro-cubain","word_nosc":"afro-cubain","lemma":"afro-cubain","pos":"ADJ"} ,
		{"word":"afro-cubaine","word_nosc":"afro-cubaine","lemma":"afro-cubain","pos":"ADJ"} ,
		{"word":"afro-cubains","word_nosc":"afro-cubains","lemma":"afro-cubain","pos":"ADJ"} ,
		{"word":"agaçant","word_nosc":"agacant","lemma":"agaçant","pos":"ADJ"} ,
		{"word":"agaçante","word_nosc":"agacante","lemma":"agaçant","pos":"ADJ"} ,
		{"word":"agaçantes","word_nosc":"agacantes","lemma":"agaçant","pos":"ADJ"} ,
		{"word":"agaçants","word_nosc":"agacants","lemma":"agaçant","pos":"ADJ"} ,
		{"word":"agenouillé","word_nosc":"agenouille","lemma":"agenouillé","pos":"ADJ"} ,
		{"word":"agenouillée","word_nosc":"agenouillee","lemma":"agenouillé","pos":"ADJ"} ,
		{"word":"agenouillées","word_nosc":"agenouillees","lemma":"agenouillé","pos":"ADJ"} ,
		{"word":"agenouillés","word_nosc":"agenouilles","lemma":"agenouillé","pos":"ADJ"} ,
		{"word":"aggloméré","word_nosc":"agglomere","lemma":"aggloméré","pos":"ADJ"} ,
		{"word":"agglomérés","word_nosc":"agglomeres","lemma":"aggloméré","pos":"ADJ"} ,
		{"word":"agglutinatif","word_nosc":"agglutinatif","lemma":"agglutinatif","pos":"ADJ"} ,
		{"word":"aggravante","word_nosc":"aggravante","lemma":"aggravant","pos":"ADJ"} ,
		{"word":"aggravantes","word_nosc":"aggravantes","lemma":"aggravant","pos":"ADJ"} ,
		{"word":"agile","word_nosc":"agile","lemma":"agile","pos":"ADJ"} ,
		{"word":"agiles","word_nosc":"agiles","lemma":"agile","pos":"ADJ"} ,
		{"word":"agissant","word_nosc":"agissant","lemma":"agissant","pos":"ADJ"} ,
		{"word":"agissante","word_nosc":"agissante","lemma":"agissant","pos":"ADJ"} ,
		{"word":"agissantes","word_nosc":"agissantes","lemma":"agissant","pos":"ADJ"} ,
		{"word":"agitant","word_nosc":"agitant","lemma":"agitant","pos":"ADJ"} ,
		{"word":"agité","word_nosc":"agite","lemma":"agité","pos":"ADJ"} ,
		{"word":"agitée","word_nosc":"agitee","lemma":"agité","pos":"ADJ"} ,
		{"word":"agitées","word_nosc":"agitees","lemma":"agité","pos":"ADJ"} ,
		{"word":"agités","word_nosc":"agites","lemma":"agité","pos":"ADJ"} ,
		{"word":"agnostique","word_nosc":"agnostique","lemma":"agnostique","pos":"ADJ"} ,
		{"word":"agnostiques","word_nosc":"agnostiques","lemma":"agnostique","pos":"ADJ"} ,
		{"word":"agonique","word_nosc":"agonique","lemma":"agonique","pos":"ADJ"} ,
		{"word":"agonisant","word_nosc":"agonisant","lemma":"agonisant","pos":"ADJ"} ,
		{"word":"agonisante","word_nosc":"agonisante","lemma":"agonisant","pos":"ADJ"} ,
		{"word":"agonisantes","word_nosc":"agonisantes","lemma":"agonisant","pos":"ADJ"} ,
		{"word":"agonisants","word_nosc":"agonisants","lemma":"agonisant","pos":"ADJ"} ,
		{"word":"agoniste","word_nosc":"agoniste","lemma":"agoniste","pos":"ADJ"} ,
		{"word":"agoraphobe","word_nosc":"agoraphobe","lemma":"agoraphobe","pos":"ADJ"} ,
		{"word":"agraire","word_nosc":"agraire","lemma":"agraire","pos":"ADJ"} ,
		{"word":"agraires","word_nosc":"agraires","lemma":"agraire","pos":"ADJ"} ,
		{"word":"agrarien","word_nosc":"agrarien","lemma":"agrarien","pos":"ADJ"} ,
		{"word":"agresseur","word_nosc":"agresseur","lemma":"agresseur","pos":"ADJ"} ,
		{"word":"agresseurs","word_nosc":"agresseurs","lemma":"agresseur","pos":"ADJ"} ,
		{"word":"agressif","word_nosc":"agressif","lemma":"agressif","pos":"ADJ"} ,
		{"word":"agressifs","word_nosc":"agressifs","lemma":"agressif","pos":"ADJ"} ,
		{"word":"agressive","word_nosc":"agressive","lemma":"agressif","pos":"ADJ"} ,
		{"word":"agressives","word_nosc":"agressives","lemma":"agressif","pos":"ADJ"} ,
		{"word":"agreste","word_nosc":"agreste","lemma":"agreste","pos":"ADJ"} ,
		{"word":"agrestes","word_nosc":"agrestes","lemma":"agreste","pos":"ADJ"} ,
		{"word":"agricole","word_nosc":"agricole","lemma":"agricole","pos":"ADJ"} ,
		{"word":"agricoles","word_nosc":"agricoles","lemma":"agricole","pos":"ADJ"} ,
		{"word":"agrippant","word_nosc":"agrippant","lemma":"agrippant","pos":"ADJ"} ,
		{"word":"agrippants","word_nosc":"agrippants","lemma":"agrippant","pos":"ADJ"} ,
		{"word":"agro-alimentaire","word_nosc":"agro-alimentaire","lemma":"agro-alimentaire","pos":"ADJ"} ,
		{"word":"agroalimentaire","word_nosc":"agroalimentaire","lemma":"agroalimentaire","pos":"ADJ"} ,
		{"word":"agronomique","word_nosc":"agronomique","lemma":"agronomique","pos":"ADJ"} ,
		{"word":"agréable","word_nosc":"agreable","lemma":"agréable","pos":"ADJ"} ,
		{"word":"agréables","word_nosc":"agreables","lemma":"agréable","pos":"ADJ"} ,
		{"word":"agrégatif","word_nosc":"agregatif","lemma":"agrégatif","pos":"ADJ"} ,
		{"word":"agrégé","word_nosc":"agrege","lemma":"agrégé","pos":"ADJ"} ,
		{"word":"agrégés","word_nosc":"agreges","lemma":"agrégé","pos":"ADJ"} ,
		{"word":"agrémenté","word_nosc":"agremente","lemma":"agrémenté","pos":"ADJ"} ,
		{"word":"agrémentée","word_nosc":"agrementee","lemma":"agrémenté","pos":"ADJ"} ,
		{"word":"agréé","word_nosc":"agree","lemma":"agréé","pos":"ADJ"} ,
		{"word":"agréée","word_nosc":"agreee","lemma":"agréé","pos":"ADJ"} ,
		{"word":"agréés","word_nosc":"agrees","lemma":"agréé","pos":"ADJ"} ,
		{"word":"aguerri","word_nosc":"aguerri","lemma":"aguerri","pos":"ADJ"} ,
		{"word":"aguerrie","word_nosc":"aguerrie","lemma":"aguerri","pos":"ADJ"} ,
		{"word":"aguerries","word_nosc":"aguerries","lemma":"aguerri","pos":"ADJ"} ,
		{"word":"aguerris","word_nosc":"aguerris","lemma":"aguerri","pos":"ADJ"} ,
		{"word":"aguichant","word_nosc":"aguichant","lemma":"aguichant","pos":"ADJ"} ,
		{"word":"aguichante","word_nosc":"aguichante","lemma":"aguichant","pos":"ADJ"} ,
		{"word":"aguichantes","word_nosc":"aguichantes","lemma":"aguichant","pos":"ADJ"} ,
		{"word":"aguicheur","word_nosc":"aguicheur","lemma":"aguicheur","pos":"ADJ"} ,
		{"word":"aguicheurs","word_nosc":"aguicheurs","lemma":"aguicheur","pos":"ADJ"} ,
		{"word":"aguicheuse","word_nosc":"aguicheuse","lemma":"aguicheur","pos":"ADJ"} ,
		{"word":"aguicheuses","word_nosc":"aguicheuses","lemma":"aguicheur","pos":"ADJ"} ,
		{"word":"ahanant","word_nosc":"ahanant","lemma":"ahanant","pos":"ADJ"} ,
		{"word":"ahanante","word_nosc":"ahanante","lemma":"ahanant","pos":"ADJ"} ,
		{"word":"ahuri","word_nosc":"ahuri","lemma":"ahuri","pos":"ADJ"} ,
		{"word":"ahurie","word_nosc":"ahurie","lemma":"ahuri","pos":"ADJ"} ,
		{"word":"ahuries","word_nosc":"ahuries","lemma":"ahuri","pos":"ADJ"} ,
		{"word":"ahuris","word_nosc":"ahuris","lemma":"ahuri","pos":"ADJ"} ,
		{"word":"ahurissant","word_nosc":"ahurissant","lemma":"ahurissant","pos":"ADJ"} ,
		{"word":"ahurissante","word_nosc":"ahurissante","lemma":"ahurissant","pos":"ADJ"} ,
		{"word":"ahurissantes","word_nosc":"ahurissantes","lemma":"ahurissant","pos":"ADJ"} ,
		{"word":"ahurissants","word_nosc":"ahurissants","lemma":"ahurissant","pos":"ADJ"} ,
		{"word":"aigre","word_nosc":"aigre","lemma":"aigre","pos":"ADJ"} ,
		{"word":"aigre-douce","word_nosc":"aigre-douce","lemma":"aigre-doux","pos":"ADJ"} ,
		{"word":"aigre-doux","word_nosc":"aigre-doux","lemma":"aigre-doux","pos":"ADJ"} ,
		{"word":"aigrelet","word_nosc":"aigrelet","lemma":"aigrelet","pos":"ADJ"} ,
		{"word":"aigrelets","word_nosc":"aigrelets","lemma":"aigrelet","pos":"ADJ"} ,
		{"word":"aigrelette","word_nosc":"aigrelette","lemma":"aigrelet","pos":"ADJ"} ,
		{"word":"aigrelettes","word_nosc":"aigrelettes","lemma":"aigrelet","pos":"ADJ"} ,
		{"word":"aigres","word_nosc":"aigres","lemma":"aigre","pos":"ADJ"} ,
		{"word":"aigres-douces","word_nosc":"aigres-douces","lemma":"aigre-doux","pos":"ADJ"} ,
		{"word":"aigres-doux","word_nosc":"aigres-doux","lemma":"aigre-doux","pos":"ADJ"} ,
		{"word":"aigrette","word_nosc":"aigrette","lemma":"aigret","pos":"ADJ"} ,
		{"word":"aigrettes","word_nosc":"aigrettes","lemma":"aigret","pos":"ADJ"} ,
		{"word":"aigri","word_nosc":"aigri","lemma":"aigri","pos":"ADJ"} ,
		{"word":"aigrie","word_nosc":"aigrie","lemma":"aigri","pos":"ADJ"} ,
		{"word":"aigries","word_nosc":"aigries","lemma":"aigri","pos":"ADJ"} ,
		{"word":"aigris","word_nosc":"aigris","lemma":"aigri","pos":"ADJ"} ,
		{"word":"aigu","word_nosc":"aigu","lemma":"aigu","pos":"ADJ"} ,
		{"word":"aiguillonnante","word_nosc":"aiguillonnante","lemma":"aiguillonnant","pos":"ADJ"} ,
		{"word":"aiguisé","word_nosc":"aiguise","lemma":"aiguisé","pos":"ADJ"} ,
		{"word":"aiguisée","word_nosc":"aiguisee","lemma":"aiguisé","pos":"ADJ"} ,
		{"word":"aiguisées","word_nosc":"aiguisees","lemma":"aiguisé","pos":"ADJ"} ,
		{"word":"aiguisés","word_nosc":"aiguises","lemma":"aiguisé","pos":"ADJ"} ,
		{"word":"aigus","word_nosc":"aigus","lemma":"aigu","pos":"ADJ"} ,
		{"word":"aiguë","word_nosc":"aigue","lemma":"aigu","pos":"ADJ"} ,
		{"word":"aiguës","word_nosc":"aigues","lemma":"aigu","pos":"ADJ"} ,
		{"word":"ailé","word_nosc":"aile","lemma":"ailé","pos":"ADJ"} ,
		{"word":"ailée","word_nosc":"ailee","lemma":"ailé","pos":"ADJ"} ,
		{"word":"ailées","word_nosc":"ailees","lemma":"ailé","pos":"ADJ"} ,
		{"word":"ailés","word_nosc":"ailes","lemma":"ailé","pos":"ADJ"} ,
		{"word":"aimable","word_nosc":"aimable","lemma":"aimable","pos":"ADJ"} ,
		{"word":"aimables","word_nosc":"aimables","lemma":"aimable","pos":"ADJ"} ,
		{"word":"aimant","word_nosc":"aimant","lemma":"aimant","pos":"ADJ"} ,
		{"word":"aimante","word_nosc":"aimante","lemma":"aimant","pos":"ADJ"} ,
		{"word":"aimantes","word_nosc":"aimantes","lemma":"aimant","pos":"ADJ"} ,
		{"word":"aimants","word_nosc":"aimants","lemma":"aimant","pos":"ADJ"} ,
		{"word":"aimé","word_nosc":"aime","lemma":"aimé","pos":"ADJ"} ,
		{"word":"aimée","word_nosc":"aimee","lemma":"aimé","pos":"ADJ"} ,
		{"word":"aimées","word_nosc":"aimees","lemma":"aimé","pos":"ADJ"} ,
		{"word":"aimés","word_nosc":"aimes","lemma":"aimé","pos":"ADJ"} ,
		{"word":"air-air","word_nosc":"air-air","lemma":"air-air","pos":"ADJ"} ,
		{"word":"air-mer","word_nosc":"air-mer","lemma":"air-mer","pos":"ADJ"} ,
		{"word":"air-sol","word_nosc":"air-sol","lemma":"air-sol","pos":"ADJ"} ,
		{"word":"aise","word_nosc":"aise","lemma":"aise","pos":"ADJ"} ,
		{"word":"aises","word_nosc":"aises","lemma":"aise","pos":"ADJ"} ,
		{"word":"aisé","word_nosc":"aise","lemma":"aisé","pos":"ADJ"} ,
		{"word":"aisée","word_nosc":"aisee","lemma":"aisé","pos":"ADJ"} ,
		{"word":"aisées","word_nosc":"aisees","lemma":"aisé","pos":"ADJ"} ,
		{"word":"aisés","word_nosc":"aises","lemma":"aisé","pos":"ADJ"} ,
		{"word":"aixois","word_nosc":"aixois","lemma":"aixois","pos":"ADJ"} ,
		{"word":"ajistes","word_nosc":"ajistes","lemma":"ajiste","pos":"ADJ"} ,
		{"word":"ajourné","word_nosc":"ajourne","lemma":"ajourné","pos":"ADJ"} ,
		{"word":"ajournée","word_nosc":"ajournee","lemma":"ajourné","pos":"ADJ"} ,
		{"word":"ajournés","word_nosc":"ajournes","lemma":"ajourné","pos":"ADJ"} ,
		{"word":"ajouré","word_nosc":"ajoure","lemma":"ajouré","pos":"ADJ"} ,
		{"word":"ajourée","word_nosc":"ajouree","lemma":"ajouré","pos":"ADJ"} ,
		{"word":"ajourées","word_nosc":"ajourees","lemma":"ajouré","pos":"ADJ"} ,
		{"word":"ajourés","word_nosc":"ajoures","lemma":"ajouré","pos":"ADJ"} ,
		{"word":"ajouté","word_nosc":"ajoute","lemma":"ajouté","pos":"ADJ"} ,
		{"word":"ajoutée","word_nosc":"ajoutee","lemma":"ajouté","pos":"ADJ"} ,
		{"word":"ajoutées","word_nosc":"ajoutees","lemma":"ajouté","pos":"ADJ"} ,
		{"word":"ajoutés","word_nosc":"ajoutes","lemma":"ajouté","pos":"ADJ"} ,
		{"word":"ajustable","word_nosc":"ajustable","lemma":"ajustable","pos":"ADJ"} ,
		{"word":"ajustables","word_nosc":"ajustables","lemma":"ajustable","pos":"ADJ"} ,
		{"word":"ajusté","word_nosc":"ajuste","lemma":"ajusté","pos":"ADJ"} ,
		{"word":"ajustée","word_nosc":"ajustee","lemma":"ajusté","pos":"ADJ"} ,
		{"word":"ajustées","word_nosc":"ajustees","lemma":"ajusté","pos":"ADJ"} ,
		{"word":"ajustés","word_nosc":"ajustes","lemma":"ajusté","pos":"ADJ"} ,
		{"word":"akkadienne","word_nosc":"akkadienne","lemma":"akkadienne","pos":"ADJ"} ,
		{"word":"alaire","word_nosc":"alaire","lemma":"alaire","pos":"ADJ"} ,
		{"word":"alambiquée","word_nosc":"alambiquee","lemma":"alambiqué","pos":"ADJ"} ,
		{"word":"alambiquées","word_nosc":"alambiquees","lemma":"alambiqué","pos":"ADJ"} ,
		{"word":"alambiqués","word_nosc":"alambiques","lemma":"alambiqué","pos":"ADJ"} ,
		{"word":"alangui","word_nosc":"alangui","lemma":"alangui","pos":"ADJ"} ,
		{"word":"alanguie","word_nosc":"alanguie","lemma":"alangui","pos":"ADJ"} ,
		{"word":"alanguies","word_nosc":"alanguies","lemma":"alangui","pos":"ADJ"} ,
		{"word":"alanguis","word_nosc":"alanguis","lemma":"alangui","pos":"ADJ"} ,
		{"word":"alanguissante","word_nosc":"alanguissante","lemma":"alanguissant","pos":"ADJ"} ,
		{"word":"alarmant","word_nosc":"alarmant","lemma":"alarmant","pos":"ADJ"} ,
		{"word":"alarmante","word_nosc":"alarmante","lemma":"alarmant","pos":"ADJ"} ,
		{"word":"alarmantes","word_nosc":"alarmantes","lemma":"alarmant","pos":"ADJ"} ,
		{"word":"alarmants","word_nosc":"alarmants","lemma":"alarmant","pos":"ADJ"} ,
		{"word":"alarmiste","word_nosc":"alarmiste","lemma":"alarmiste","pos":"ADJ"} ,
		{"word":"alarmistes","word_nosc":"alarmistes","lemma":"alarmiste","pos":"ADJ"} ,
		{"word":"albanais","word_nosc":"albanais","lemma":"albanais","pos":"ADJ"} ,
		{"word":"albanaise","word_nosc":"albanaise","lemma":"albanais","pos":"ADJ"} ,
		{"word":"albanaises","word_nosc":"albanaises","lemma":"albanais","pos":"ADJ"} ,
		{"word":"albanophone","word_nosc":"albanophone","lemma":"albanophone","pos":"ADJ"} ,
		{"word":"albigeois","word_nosc":"albigeois","lemma":"albigeois","pos":"ADJ"} ,
		{"word":"albinos","word_nosc":"albinos","lemma":"albinos","pos":"ADJ"} ,
		{"word":"albumineuse","word_nosc":"albumineuse","lemma":"albumineux","pos":"ADJ"} ,
		{"word":"albâtre","word_nosc":"albatre","lemma":"albâtre","pos":"ADJ"} ,
		{"word":"alcalin","word_nosc":"alcalin","lemma":"alcalin","pos":"ADJ"} ,
		{"word":"alcaline","word_nosc":"alcaline","lemma":"alcalin","pos":"ADJ"} ,
		{"word":"alcalines","word_nosc":"alcalines","lemma":"alcalin","pos":"ADJ"} ,
		{"word":"alcalins","word_nosc":"alcalins","lemma":"alcalin","pos":"ADJ"} ,
		{"word":"alchimique","word_nosc":"alchimique","lemma":"alchimique","pos":"ADJ"} ,
		{"word":"alchimiques","word_nosc":"alchimiques","lemma":"alchimique","pos":"ADJ"} ,
		{"word":"alcoolique","word_nosc":"alcoolique","lemma":"alcoolique","pos":"ADJ"} ,
		{"word":"alcooliques","word_nosc":"alcooliques","lemma":"alcoolique","pos":"ADJ"} ,
		{"word":"alcoolisé","word_nosc":"alcoolise","lemma":"alcoolisé","pos":"ADJ"} ,
		{"word":"alcoolisée","word_nosc":"alcoolisee","lemma":"alcoolisé","pos":"ADJ"} ,
		{"word":"alcoolisées","word_nosc":"alcoolisees","lemma":"alcoolisé","pos":"ADJ"} ,
		{"word":"alcoolisés","word_nosc":"alcoolises","lemma":"alcoolisé","pos":"ADJ"} ,
		{"word":"alcoolo","word_nosc":"alcoolo","lemma":"alcoolo","pos":"ADJ"} ,
		{"word":"alcoolos","word_nosc":"alcoolos","lemma":"alcoolo","pos":"ADJ"} ,
		{"word":"alençonnais","word_nosc":"alenconnais","lemma":"alençonnais","pos":"ADJ"} ,
		{"word":"alençonnaise","word_nosc":"alenconnaise","lemma":"alençonnais","pos":"ADJ"} ,
		{"word":"alerte","word_nosc":"alerte","lemma":"alerte","pos":"ADJ"} ,
		{"word":"alertes","word_nosc":"alertes","lemma":"alerte","pos":"ADJ"} ,
		{"word":"alerté","word_nosc":"alerte","lemma":"alerté","pos":"ADJ"} ,
		{"word":"alertée","word_nosc":"alertee","lemma":"alerté","pos":"ADJ"} ,
		{"word":"alertées","word_nosc":"alertees","lemma":"alerté","pos":"ADJ"} ,
		{"word":"alertés","word_nosc":"alertes","lemma":"alerté","pos":"ADJ"} ,
		{"word":"alexandrin","word_nosc":"alexandrin","lemma":"alexandrin","pos":"ADJ"} ,
		{"word":"alexandrine","word_nosc":"alexandrine","lemma":"alexandrin","pos":"ADJ"} ,
		{"word":"alexandrines","word_nosc":"alexandrines","lemma":"alexandrin","pos":"ADJ"} ,
		{"word":"alexandrins","word_nosc":"alexandrins","lemma":"alexandrin","pos":"ADJ"} ,
		{"word":"alezan","word_nosc":"alezan","lemma":"alezan","pos":"ADJ"} ,
		{"word":"alezane","word_nosc":"alezane","lemma":"alezan","pos":"ADJ"} ,
		{"word":"alezanes","word_nosc":"alezanes","lemma":"alezan","pos":"ADJ"} ,
		{"word":"alezans","word_nosc":"alezans","lemma":"alezan","pos":"ADJ"} ,
		{"word":"algonquin","word_nosc":"algonquin","lemma":"algonquin","pos":"ADJ"} ,
		{"word":"algorithmique","word_nosc":"algorithmique","lemma":"algorithmique","pos":"ADJ"} ,
		{"word":"algébrique","word_nosc":"algebrique","lemma":"algébrique","pos":"ADJ"} ,
		{"word":"algébriques","word_nosc":"algebriques","lemma":"algébrique","pos":"ADJ"} ,
		{"word":"algérien","word_nosc":"algerien","lemma":"algérien","pos":"ADJ"} ,
		{"word":"algérienne","word_nosc":"algerienne","lemma":"algérien","pos":"ADJ"} ,
		{"word":"algériennes","word_nosc":"algeriennes","lemma":"algérien","pos":"ADJ"} ,
		{"word":"algériens","word_nosc":"algeriens","lemma":"algérien","pos":"ADJ"} ,
		{"word":"algérois","word_nosc":"algerois","lemma":"algérois","pos":"ADJ"} ,
		{"word":"algéroise","word_nosc":"algeroise","lemma":"algérois","pos":"ADJ"} ,
		{"word":"aligné","word_nosc":"aligne","lemma":"aligné","pos":"ADJ"} ,
		{"word":"alignée","word_nosc":"alignee","lemma":"aligné","pos":"ADJ"} ,
		{"word":"alignées","word_nosc":"alignees","lemma":"aligné","pos":"ADJ"} ,
		{"word":"alignés","word_nosc":"alignes","lemma":"aligné","pos":"ADJ"} ,
		{"word":"aligoté","word_nosc":"aligote","lemma":"aligoté","pos":"ADJ"} ,
		{"word":"alimentaire","word_nosc":"alimentaire","lemma":"alimentaire","pos":"ADJ"} ,
		{"word":"alimentaires","word_nosc":"alimentaires","lemma":"alimentaire","pos":"ADJ"} ,
		{"word":"alizé","word_nosc":"alize","lemma":"alizé","pos":"ADJ"} ,
		{"word":"alizés","word_nosc":"alizes","lemma":"alizé","pos":"ADJ"} ,
		{"word":"aliénant","word_nosc":"alienant","lemma":"aliénant","pos":"ADJ"} ,
		{"word":"aliénante","word_nosc":"alienante","lemma":"aliénant","pos":"ADJ"} ,
		{"word":"aliéné","word_nosc":"aliene","lemma":"aliéné","pos":"ADJ"} ,
		{"word":"aliénée","word_nosc":"alienee","lemma":"aliéné","pos":"ADJ"} ,
		{"word":"aliénés","word_nosc":"alienes","lemma":"aliéné","pos":"ADJ"} ,
		{"word":"allaitant","word_nosc":"allaitant","lemma":"allaitant","pos":"ADJ"} ,
		{"word":"allaitante","word_nosc":"allaitante","lemma":"allaitant","pos":"ADJ"} ,
		{"word":"allaitantes","word_nosc":"allaitantes","lemma":"allaitant","pos":"ADJ"} ,
		{"word":"allant","word_nosc":"allant","lemma":"allant","pos":"ADJ"} ,
		{"word":"allante","word_nosc":"allante","lemma":"allant","pos":"ADJ"} ,
		{"word":"allemand","word_nosc":"allemand","lemma":"allemand","pos":"ADJ"} ,
		{"word":"allemande","word_nosc":"allemande","lemma":"allemand","pos":"ADJ"} ,
		{"word":"allemandes","word_nosc":"allemandes","lemma":"allemand","pos":"ADJ"} ,
		{"word":"allemands","word_nosc":"allemands","lemma":"allemand","pos":"ADJ"} ,
		{"word":"allergique","word_nosc":"allergique","lemma":"allergique","pos":"ADJ"} ,
		{"word":"allergiques","word_nosc":"allergiques","lemma":"allergique","pos":"ADJ"} ,
		{"word":"allergène","word_nosc":"allergene","lemma":"allergène","pos":"ADJ"} ,
		{"word":"allergènes","word_nosc":"allergenes","lemma":"allergène","pos":"ADJ"} ,
		{"word":"alliacé","word_nosc":"alliace","lemma":"alliacé","pos":"ADJ"} ,
		{"word":"allitératif","word_nosc":"alliteratif","lemma":"allitératif","pos":"ADJ"} ,
		{"word":"allié","word_nosc":"allie","lemma":"allié","pos":"ADJ"} ,
		{"word":"alliée","word_nosc":"alliee","lemma":"allié","pos":"ADJ"} ,
		{"word":"alliées","word_nosc":"alliees","lemma":"allié","pos":"ADJ"} ,
		{"word":"alliés","word_nosc":"allies","lemma":"allié","pos":"ADJ"} ,
		{"word":"allobroges","word_nosc":"allobroges","lemma":"allobroge","pos":"ADJ"} ,
		{"word":"allocations","word_nosc":"allocations","lemma":"allocations","pos":"ADJ"} ,
		{"word":"allogènes","word_nosc":"allogenes","lemma":"allogène","pos":"ADJ"} ,
		{"word":"allongé","word_nosc":"allonge","lemma":"allongé","pos":"ADJ"} ,
		{"word":"allongée","word_nosc":"allongee","lemma":"allongé","pos":"ADJ"} ,
		{"word":"allongées","word_nosc":"allongees","lemma":"allongé","pos":"ADJ"} ,
		{"word":"allongés","word_nosc":"allonges","lemma":"allongé","pos":"ADJ"} ,
		{"word":"allostérique","word_nosc":"allosterique","lemma":"allostérique","pos":"ADJ"} ,
		{"word":"allumé","word_nosc":"allume","lemma":"allumé","pos":"ADJ"} ,
		{"word":"allumée","word_nosc":"allumee","lemma":"allumé","pos":"ADJ"} ,
		{"word":"allumées","word_nosc":"allumees","lemma":"allumé","pos":"ADJ"} ,
		{"word":"allumés","word_nosc":"allumes","lemma":"allumé","pos":"ADJ"} ,
		{"word":"alluré","word_nosc":"allure","lemma":"alluré","pos":"ADJ"} ,
		{"word":"allusif","word_nosc":"allusif","lemma":"allusif","pos":"ADJ"} ,
		{"word":"allusifs","word_nosc":"allusifs","lemma":"allusif","pos":"ADJ"} ,
		{"word":"allusive","word_nosc":"allusive","lemma":"allusif","pos":"ADJ"} ,
		{"word":"allusives","word_nosc":"allusives","lemma":"allusif","pos":"ADJ"} ,
		{"word":"allègre","word_nosc":"allegre","lemma":"allègre","pos":"ADJ"} ,
		{"word":"allègres","word_nosc":"allegres","lemma":"allègre","pos":"ADJ"} ,
		{"word":"allèle","word_nosc":"allele","lemma":"allèle","pos":"ADJ"} ,
		{"word":"alléchant","word_nosc":"allechant","lemma":"alléchant","pos":"ADJ"} ,
		{"word":"alléchante","word_nosc":"allechante","lemma":"alléchant","pos":"ADJ"} ,
		{"word":"alléchantes","word_nosc":"allechantes","lemma":"alléchant","pos":"ADJ"} ,
		{"word":"alléchants","word_nosc":"allechants","lemma":"alléchant","pos":"ADJ"} ,
		{"word":"allégorique","word_nosc":"allegorique","lemma":"allégorique","pos":"ADJ"} ,
		{"word":"allégoriques","word_nosc":"allegoriques","lemma":"allégorique","pos":"ADJ"} ,
		{"word":"alpestre","word_nosc":"alpestre","lemma":"alpestre","pos":"ADJ"} ,
		{"word":"alpestres","word_nosc":"alpestres","lemma":"alpestre","pos":"ADJ"} ,
		{"word":"alphabétique","word_nosc":"alphabetique","lemma":"alphabétique","pos":"ADJ"} ,
		{"word":"alphabétisé","word_nosc":"alphabetise","lemma":"alphabétisé","pos":"ADJ"} ,
		{"word":"alphanumérique","word_nosc":"alphanumerique","lemma":"alphanumérique","pos":"ADJ"} ,
		{"word":"alpin","word_nosc":"alpin","lemma":"alpin","pos":"ADJ"} ,
		{"word":"alpine","word_nosc":"alpine","lemma":"alpin","pos":"ADJ"} ,
		{"word":"alpines","word_nosc":"alpines","lemma":"alpin","pos":"ADJ"} ,
		{"word":"alpins","word_nosc":"alpins","lemma":"alpin","pos":"ADJ"} ,
		{"word":"alsacien","word_nosc":"alsacien","lemma":"alsacien","pos":"ADJ"} ,
		{"word":"alsacienne","word_nosc":"alsacienne","lemma":"alsacien","pos":"ADJ"} ,
		{"word":"alsaciennes","word_nosc":"alsaciennes","lemma":"alsacien","pos":"ADJ"} ,
		{"word":"alsaciens","word_nosc":"alsaciens","lemma":"alsacien","pos":"ADJ"} ,
		{"word":"alternant","word_nosc":"alternant","lemma":"alternant","pos":"ADJ"} ,
		{"word":"alternatif","word_nosc":"alternatif","lemma":"alternatif","pos":"ADJ"} ,
		{"word":"alternatifs","word_nosc":"alternatifs","lemma":"alternatif","pos":"ADJ"} ,
		{"word":"alternative","word_nosc":"alternative","lemma":"alternatif","pos":"ADJ"} ,
		{"word":"alternatives","word_nosc":"alternatives","lemma":"alternatif","pos":"ADJ"} ,
		{"word":"alterne","word_nosc":"alterne","lemma":"alterne","pos":"ADJ"} ,
		{"word":"alterné","word_nosc":"alterne","lemma":"alterné","pos":"ADJ"} ,
		{"word":"alternée","word_nosc":"alternee","lemma":"alterné","pos":"ADJ"} ,
		{"word":"alternées","word_nosc":"alternees","lemma":"alterné","pos":"ADJ"} ,
		{"word":"alternés","word_nosc":"alternes","lemma":"alterné","pos":"ADJ"} ,
		{"word":"altier","word_nosc":"altier","lemma":"altier","pos":"ADJ"} ,
		{"word":"altiers","word_nosc":"altiers","lemma":"altier","pos":"ADJ"} ,
		{"word":"altière","word_nosc":"altiere","lemma":"altier","pos":"ADJ"} ,
		{"word":"altières","word_nosc":"altieres","lemma":"altier","pos":"ADJ"} ,
		{"word":"altruiste","word_nosc":"altruiste","lemma":"altruiste","pos":"ADJ"} ,
		{"word":"altruistes","word_nosc":"altruistes","lemma":"altruiste","pos":"ADJ"} ,
		{"word":"altérable","word_nosc":"alterable","lemma":"altérable","pos":"ADJ"} ,
		{"word":"altéré","word_nosc":"altere","lemma":"altéré","pos":"ADJ"} ,
		{"word":"altérée","word_nosc":"alteree","lemma":"altéré","pos":"ADJ"} ,
		{"word":"altérées","word_nosc":"alterees","lemma":"altéré","pos":"ADJ"} ,
		{"word":"altérés","word_nosc":"alteres","lemma":"altéré","pos":"ADJ"} ,
		{"word":"alvin","word_nosc":"alvin","lemma":"alvin","pos":"ADJ"} ,
		{"word":"alvéolaire","word_nosc":"alveolaire","lemma":"alvéolaire","pos":"ADJ"} ,
		{"word":"alvéolé","word_nosc":"alveole","lemma":"alvéolé","pos":"ADJ"} ,
		{"word":"alvéolée","word_nosc":"alveolee","lemma":"alvéolé","pos":"ADJ"} ,
		{"word":"alvéolées","word_nosc":"alveolees","lemma":"alvéolé","pos":"ADJ"} ,
		{"word":"alvéolés","word_nosc":"alveoles","lemma":"alvéolé","pos":"ADJ"} ,
		{"word":"aléatoire","word_nosc":"aleatoire","lemma":"aléatoire","pos":"ADJ"} ,
		{"word":"aléatoires","word_nosc":"aleatoires","lemma":"aléatoire","pos":"ADJ"} ,
		{"word":"alémaniques","word_nosc":"alemaniques","lemma":"alémanique","pos":"ADJ"} ,
		{"word":"aléoutien","word_nosc":"aleoutien","lemma":"aléoutien","pos":"ADJ"} ,
		{"word":"amaigri","word_nosc":"amaigri","lemma":"amaigri","pos":"ADJ"} ,
		{"word":"amaigrie","word_nosc":"amaigrie","lemma":"amaigri","pos":"ADJ"} ,
		{"word":"amaigries","word_nosc":"amaigries","lemma":"amaigri","pos":"ADJ"} ,
		{"word":"amaigris","word_nosc":"amaigris","lemma":"amaigri","pos":"ADJ"} ,
		{"word":"amaigrissant","word_nosc":"amaigrissant","lemma":"amaigrissant","pos":"ADJ"} ,
		{"word":"amaigrissante","word_nosc":"amaigrissante","lemma":"amaigrissant","pos":"ADJ"} ,
		{"word":"amaigrissantes","word_nosc":"amaigrissantes","lemma":"amaigrissant","pos":"ADJ"} ,
		{"word":"amaigrissants","word_nosc":"amaigrissants","lemma":"amaigrissant","pos":"ADJ"} ,
		{"word":"amalfitaine","word_nosc":"amalfitaine","lemma":"amalfitain","pos":"ADJ"} ,
		{"word":"amarante","word_nosc":"amarante","lemma":"amarante","pos":"ADJ"} ,
		{"word":"amateur","word_nosc":"amateur","lemma":"amateur","pos":"ADJ"} ,
		{"word":"amateurs","word_nosc":"amateurs","lemma":"amateur","pos":"ADJ"} ,
		{"word":"amatrice","word_nosc":"amatrice","lemma":"amateur","pos":"ADJ"} ,
		{"word":"amazonien","word_nosc":"amazonien","lemma":"amazonien","pos":"ADJ"} ,
		{"word":"amazonienne","word_nosc":"amazonienne","lemma":"amazonien","pos":"ADJ"} ,
		{"word":"amazoniennes","word_nosc":"amazoniennes","lemma":"amazonien","pos":"ADJ"} ,
		{"word":"ambiant","word_nosc":"ambiant","lemma":"ambiant","pos":"ADJ"} ,
		{"word":"ambiante","word_nosc":"ambiante","lemma":"ambiant","pos":"ADJ"} ,
		{"word":"ambiantes","word_nosc":"ambiantes","lemma":"ambiant","pos":"ADJ"} ,
		{"word":"ambiants","word_nosc":"ambiants","lemma":"ambiant","pos":"ADJ"} ,
		{"word":"ambidextre","word_nosc":"ambidextre","lemma":"ambidextre","pos":"ADJ"} ,
		{"word":"ambigu","word_nosc":"ambigu","lemma":"ambigu","pos":"ADJ"} ,
		{"word":"ambigus","word_nosc":"ambigus","lemma":"ambigu","pos":"ADJ"} ,
		{"word":"ambiguë","word_nosc":"ambigue","lemma":"ambigu","pos":"ADJ"} ,
		{"word":"ambiguës","word_nosc":"ambigues","lemma":"ambigu","pos":"ADJ"} ,
		{"word":"ambitieuse","word_nosc":"ambitieuse","lemma":"ambitieux","pos":"ADJ"} ,
		{"word":"ambitieuses","word_nosc":"ambitieuses","lemma":"ambitieux","pos":"ADJ"} ,
		{"word":"ambitieux","word_nosc":"ambitieux","lemma":"ambitieux","pos":"ADJ"} ,
		{"word":"ambivalent","word_nosc":"ambivalent","lemma":"ambivalent","pos":"ADJ"} ,
		{"word":"ambivalente","word_nosc":"ambivalente","lemma":"ambivalent","pos":"ADJ"} ,
		{"word":"ambré","word_nosc":"ambre","lemma":"ambré","pos":"ADJ"} ,
		{"word":"ambrée","word_nosc":"ambree","lemma":"ambré","pos":"ADJ"} ,
		{"word":"ambrées","word_nosc":"ambrees","lemma":"ambré","pos":"ADJ"} ,
		{"word":"ambrés","word_nosc":"ambres","lemma":"ambré","pos":"ADJ"} ,
		{"word":"ambulant","word_nosc":"ambulant","lemma":"ambulant","pos":"ADJ"} ,
		{"word":"ambulante","word_nosc":"ambulante","lemma":"ambulant","pos":"ADJ"} ,
		{"word":"ambulantes","word_nosc":"ambulantes","lemma":"ambulant","pos":"ADJ"} ,
		{"word":"ambulants","word_nosc":"ambulants","lemma":"ambulant","pos":"ADJ"} ,
		{"word":"ambulatoire","word_nosc":"ambulatoire","lemma":"ambulatoire","pos":"ADJ"} ,
		{"word":"amer","word_nosc":"amer","lemma":"amer","pos":"ADJ"} ,
		{"word":"amers","word_nosc":"amers","lemma":"amer","pos":"ADJ"} ,
		{"word":"ami","word_nosc":"ami","lemma":"ami","pos":"ADJ"} ,
		{"word":"amiable","word_nosc":"amiable","lemma":"amiable","pos":"ADJ"} ,
		{"word":"amibienne","word_nosc":"amibienne","lemma":"amibien","pos":"ADJ"} ,
		{"word":"amical","word_nosc":"amical","lemma":"amical","pos":"ADJ"} ,
		{"word":"amicale","word_nosc":"amicale","lemma":"amical","pos":"ADJ"} ,
		{"word":"amicales","word_nosc":"amicales","lemma":"amical","pos":"ADJ"} ,
		{"word":"amicaux","word_nosc":"amicaux","lemma":"amical","pos":"ADJ"} ,
		{"word":"amie","word_nosc":"amie","lemma":"ami","pos":"ADJ"} ,
		{"word":"amies","word_nosc":"amies","lemma":"ami","pos":"ADJ"} ,
		{"word":"aminci","word_nosc":"aminci","lemma":"aminci","pos":"ADJ"} ,
		{"word":"amincie","word_nosc":"amincie","lemma":"aminci","pos":"ADJ"} ,
		{"word":"amincies","word_nosc":"amincies","lemma":"aminci","pos":"ADJ"} ,
		{"word":"amincis","word_nosc":"amincis","lemma":"aminci","pos":"ADJ"} ,
		{"word":"amincissant","word_nosc":"amincissant","lemma":"amincissant","pos":"ADJ"} ,
		{"word":"amincissante","word_nosc":"amincissante","lemma":"amincissant","pos":"ADJ"} ,
		{"word":"amincissantes","word_nosc":"amincissantes","lemma":"amincissant","pos":"ADJ"} ,
		{"word":"amincissants","word_nosc":"amincissants","lemma":"amincissant","pos":"ADJ"} ,
		{"word":"aminé","word_nosc":"amine","lemma":"aminé","pos":"ADJ"} ,
		{"word":"aminés","word_nosc":"amines","lemma":"aminé","pos":"ADJ"} ,
		{"word":"amiral","word_nosc":"amiral","lemma":"amiral","pos":"ADJ"} ,
		{"word":"amirale","word_nosc":"amirale","lemma":"amiral","pos":"ADJ"} ,
		{"word":"amis","word_nosc":"amis","lemma":"ami","pos":"ADJ"} ,
		{"word":"amish","word_nosc":"amish","lemma":"amish","pos":"ADJ"} ,
		{"word":"amitieuse","word_nosc":"amitieuse","lemma":"amitieux","pos":"ADJ"} ,
		{"word":"ammoniac","word_nosc":"ammoniac","lemma":"ammoniac","pos":"ADJ"} ,
		{"word":"ammoniacale","word_nosc":"ammoniacale","lemma":"ammoniacal","pos":"ADJ"} ,
		{"word":"ammoniacales","word_nosc":"ammoniacales","lemma":"ammoniacal","pos":"ADJ"} ,
		{"word":"ammoniacaux","word_nosc":"ammoniacaux","lemma":"ammoniacal","pos":"ADJ"} ,
		{"word":"ammoniaque","word_nosc":"ammoniaque","lemma":"ammoniaque","pos":"ADJ"} ,
		{"word":"ammoniaqué","word_nosc":"ammoniaque","lemma":"ammoniaqué","pos":"ADJ"} ,
		{"word":"ammoniaquée","word_nosc":"ammoniaquee","lemma":"ammoniaqué","pos":"ADJ"} ,
		{"word":"ammoniaquées","word_nosc":"ammoniaquees","lemma":"ammoniaqué","pos":"ADJ"} ,
		{"word":"amniotique","word_nosc":"amniotique","lemma":"amniotique","pos":"ADJ"} ,
		{"word":"amnistiable","word_nosc":"amnistiable","lemma":"amnistiable","pos":"ADJ"} ,
		{"word":"amnistiante","word_nosc":"amnistiante","lemma":"amnistiant","pos":"ADJ"} ,
		{"word":"amnistiée","word_nosc":"amnistiee","lemma":"amnistié","pos":"ADJ"} ,
		{"word":"amnistiés","word_nosc":"amnisties","lemma":"amnistié","pos":"ADJ"} ,
		{"word":"amnésique","word_nosc":"amnesique","lemma":"amnésique","pos":"ADJ"} ,
		{"word":"amnésiques","word_nosc":"amnesiques","lemma":"amnésique","pos":"ADJ"} ,
		{"word":"amollissant","word_nosc":"amollissant","lemma":"amollissant","pos":"ADJ"} ,
		{"word":"amont","word_nosc":"amont","lemma":"amont","pos":"ADJ"} ,
		{"word":"amoral","word_nosc":"amoral","lemma":"amoral","pos":"ADJ"} ,
		{"word":"amorale","word_nosc":"amorale","lemma":"amoral","pos":"ADJ"} ,
		{"word":"amoraux","word_nosc":"amoraux","lemma":"amoral","pos":"ADJ"} ,
		{"word":"amorphe","word_nosc":"amorphe","lemma":"amorphe","pos":"ADJ"} ,
		{"word":"amorphes","word_nosc":"amorphes","lemma":"amorphe","pos":"ADJ"} ,
		{"word":"amorti","word_nosc":"amorti","lemma":"amorti","pos":"ADJ"} ,
		{"word":"amortie","word_nosc":"amortie","lemma":"amorti","pos":"ADJ"} ,
		{"word":"amorties","word_nosc":"amorties","lemma":"amorti","pos":"ADJ"} ,
		{"word":"amortis","word_nosc":"amortis","lemma":"amorti","pos":"ADJ"} ,
		{"word":"amoureuse","word_nosc":"amoureuse","lemma":"amoureux","pos":"ADJ"} ,
		{"word":"amoureuses","word_nosc":"amoureuses","lemma":"amoureux","pos":"ADJ"} ,
		{"word":"amoureux","word_nosc":"amoureux","lemma":"amoureux","pos":"ADJ"} ,
		{"word":"amovible","word_nosc":"amovible","lemma":"amovible","pos":"ADJ"} ,
		{"word":"amovibles","word_nosc":"amovibles","lemma":"amovible","pos":"ADJ"} ,
		{"word":"amphibie","word_nosc":"amphibie","lemma":"amphibie","pos":"ADJ"} ,
		{"word":"amphibies","word_nosc":"amphibies","lemma":"amphibie","pos":"ADJ"} ,
		{"word":"amphigourique","word_nosc":"amphigourique","lemma":"amphigourique","pos":"ADJ"} ,
		{"word":"amphigouriques","word_nosc":"amphigouriques","lemma":"amphigourique","pos":"ADJ"} ,
		{"word":"ample","word_nosc":"ample","lemma":"ample","pos":"ADJ"} ,
		{"word":"amples","word_nosc":"amples","lemma":"ample","pos":"ADJ"} ,
		{"word":"amplificateur","word_nosc":"amplificateur","lemma":"amplificateur","pos":"ADJ"} ,
		{"word":"amplificateurs","word_nosc":"amplificateurs","lemma":"amplificateur","pos":"ADJ"} ,
		{"word":"ampoulé","word_nosc":"ampoule","lemma":"ampoulé","pos":"ADJ"} ,
		{"word":"ampoulée","word_nosc":"ampoulee","lemma":"ampoulé","pos":"ADJ"} ,
		{"word":"ampoulées","word_nosc":"ampoulees","lemma":"ampoulé","pos":"ADJ"} ,
		{"word":"ampoulés","word_nosc":"ampoules","lemma":"ampoulé","pos":"ADJ"} ,
		{"word":"amputé","word_nosc":"ampute","lemma":"amputé","pos":"ADJ"} ,
		{"word":"amputée","word_nosc":"amputee","lemma":"amputé","pos":"ADJ"} ,
		{"word":"amputées","word_nosc":"amputees","lemma":"amputé","pos":"ADJ"} ,
		{"word":"amputés","word_nosc":"amputes","lemma":"amputé","pos":"ADJ"} ,
		{"word":"amusant","word_nosc":"amusant","lemma":"amusant","pos":"ADJ"} ,
		{"word":"amusante","word_nosc":"amusante","lemma":"amusant","pos":"ADJ"} ,
		{"word":"amusantes","word_nosc":"amusantes","lemma":"amusant","pos":"ADJ"} ,
		{"word":"amusants","word_nosc":"amusants","lemma":"amusant","pos":"ADJ"} ,
		{"word":"amygdalien","word_nosc":"amygdalien","lemma":"amygdalien","pos":"ADJ"} ,
		{"word":"amylique","word_nosc":"amylique","lemma":"amylique","pos":"ADJ"} ,
		{"word":"amyotrophique","word_nosc":"amyotrophique","lemma":"amyotrophique","pos":"ADJ"} ,
		{"word":"amène","word_nosc":"amene","lemma":"amène","pos":"ADJ"} ,
		{"word":"amènes","word_nosc":"amenes","lemma":"amène","pos":"ADJ"} ,
		{"word":"amère","word_nosc":"amere","lemma":"amer","pos":"ADJ"} ,
		{"word":"amères","word_nosc":"ameres","lemma":"amer","pos":"ADJ"} ,
		{"word":"améliorable","word_nosc":"ameliorable","lemma":"améliorable","pos":"ADJ"} ,
		{"word":"améliorateur","word_nosc":"ameliorateur","lemma":"améliorateur","pos":"ADJ"} ,
		{"word":"amélioré","word_nosc":"ameliore","lemma":"amélioré","pos":"ADJ"} ,
		{"word":"améliorée","word_nosc":"amelioree","lemma":"amélioré","pos":"ADJ"} ,
		{"word":"améliorées","word_nosc":"ameliorees","lemma":"amélioré","pos":"ADJ"} ,
		{"word":"améliorés","word_nosc":"ameliores","lemma":"amélioré","pos":"ADJ"} ,
		{"word":"américain","word_nosc":"americain","lemma":"américain","pos":"ADJ"} ,
		{"word":"américaine","word_nosc":"americaine","lemma":"américain","pos":"ADJ"} ,
		{"word":"américaines","word_nosc":"americaines","lemma":"américain","pos":"ADJ"} ,
		{"word":"américains","word_nosc":"americains","lemma":"américain","pos":"ADJ"} ,
		{"word":"américano-britannique","word_nosc":"americano-britannique","lemma":"américano-britannique","pos":"ADJ"} ,
		{"word":"américano-japonaise","word_nosc":"americano-japonaise","lemma":"américano-japonais","pos":"ADJ"} ,
		{"word":"américano-russe","word_nosc":"americano-russe","lemma":"américano-russe","pos":"ADJ"} ,
		{"word":"américano-soviétique","word_nosc":"americano-sovietique","lemma":"américano-soviétique","pos":"ADJ"} ,
		{"word":"américano-suédoise","word_nosc":"americano-suedoise","lemma":"américano-suédois","pos":"ADJ"} ,
		{"word":"américanophile","word_nosc":"americanophile","lemma":"américanophile","pos":"ADJ"} ,
		{"word":"américanophiles","word_nosc":"americanophiles","lemma":"américanophile","pos":"ADJ"} ,
		{"word":"amérindien","word_nosc":"amerindien","lemma":"amérindien","pos":"ADJ"} ,
		{"word":"amérindienne","word_nosc":"amerindienne","lemma":"amérindien","pos":"ADJ"} ,
		{"word":"amérindiennes","word_nosc":"amerindiennes","lemma":"amérindien","pos":"ADJ"} ,
		{"word":"amérindiens","word_nosc":"amerindiens","lemma":"amérindien","pos":"ADJ"} ,
		{"word":"améthyste","word_nosc":"amethyste","lemma":"améthyste","pos":"ADJ"} ,
		{"word":"anabaptiste","word_nosc":"anabaptiste","lemma":"anabaptiste","pos":"ADJ"} ,
		{"word":"anabaptistes","word_nosc":"anabaptistes","lemma":"anabaptiste","pos":"ADJ"} ,
		{"word":"anabolisant","word_nosc":"anabolisant","lemma":"anabolisant","pos":"ADJ"} ,
		{"word":"anabolisants","word_nosc":"anabolisants","lemma":"anabolisant","pos":"ADJ"} ,
		{"word":"anachronique","word_nosc":"anachronique","lemma":"anachronique","pos":"ADJ"} ,
		{"word":"anachroniques","word_nosc":"anachroniques","lemma":"anachronique","pos":"ADJ"} ,
		{"word":"anadyomène","word_nosc":"anadyomene","lemma":"anadyomène","pos":"ADJ"} ,
		{"word":"anal","word_nosc":"anal","lemma":"anal","pos":"ADJ"} ,
		{"word":"anale","word_nosc":"anale","lemma":"anal","pos":"ADJ"} ,
		{"word":"anales","word_nosc":"anales","lemma":"anal","pos":"ADJ"} ,
		{"word":"analgésique","word_nosc":"analgesique","lemma":"analgésique","pos":"ADJ"} ,
		{"word":"analgésiques","word_nosc":"analgesiques","lemma":"analgésique","pos":"ADJ"} ,
		{"word":"analogique","word_nosc":"analogique","lemma":"analogique","pos":"ADJ"} ,
		{"word":"analogiques","word_nosc":"analogiques","lemma":"analogique","pos":"ADJ"} ,
		{"word":"analogue","word_nosc":"analogue","lemma":"analogue","pos":"ADJ"} ,
		{"word":"analogues","word_nosc":"analogues","lemma":"analogue","pos":"ADJ"} ,
		{"word":"analphabète","word_nosc":"analphabete","lemma":"analphabète","pos":"ADJ"} ,
		{"word":"analphabètes","word_nosc":"analphabetes","lemma":"analphabète","pos":"ADJ"} ,
		{"word":"analytique","word_nosc":"analytique","lemma":"analytique","pos":"ADJ"} ,
		{"word":"anaphylactique","word_nosc":"anaphylactique","lemma":"anaphylactique","pos":"ADJ"} ,
		{"word":"anar","word_nosc":"anar","lemma":"anar","pos":"ADJ"} ,
		{"word":"anarchique","word_nosc":"anarchique","lemma":"anarchique","pos":"ADJ"} ,
		{"word":"anarchiques","word_nosc":"anarchiques","lemma":"anarchique","pos":"ADJ"} ,
		{"word":"anarchisant","word_nosc":"anarchisant","lemma":"anarchisant","pos":"ADJ"} ,
		{"word":"anarchiste","word_nosc":"anarchiste","lemma":"anarchiste","pos":"ADJ"} ,
		{"word":"anarchistes","word_nosc":"anarchistes","lemma":"anarchiste","pos":"ADJ"} ,
		{"word":"anarcho-syndicaliste","word_nosc":"anarcho-syndicaliste","lemma":"anarcho-syndicaliste","pos":"ADJ"} ,
		{"word":"anarcho-syndicalistes","word_nosc":"anarcho-syndicalistes","lemma":"anarcho-syndicaliste","pos":"ADJ"} ,
		{"word":"anatomique","word_nosc":"anatomique","lemma":"anatomique","pos":"ADJ"} ,
		{"word":"anatomiques","word_nosc":"anatomiques","lemma":"anatomique","pos":"ADJ"} ,
		{"word":"anatomophysiologique","word_nosc":"anatomophysiologique","lemma":"anatomophysiologique","pos":"ADJ"} ,
		{"word":"anaux","word_nosc":"anaux","lemma":"anal","pos":"ADJ"} ,
		{"word":"anaérobie","word_nosc":"anaerobie","lemma":"anaérobie","pos":"ADJ"} ,
		{"word":"ancestral","word_nosc":"ancestral","lemma":"ancestral","pos":"ADJ"} ,
		{"word":"ancestrale","word_nosc":"ancestrale","lemma":"ancestral","pos":"ADJ"} ,
		{"word":"ancestrales","word_nosc":"ancestrales","lemma":"ancestral","pos":"ADJ"} ,
		{"word":"ancestraux","word_nosc":"ancestraux","lemma":"ancestral","pos":"ADJ"} ,
		{"word":"ancien","word_nosc":"ancien","lemma":"ancien","pos":"ADJ"} ,
		{"word":"ancienne","word_nosc":"ancienne","lemma":"ancien","pos":"ADJ"} ,
		{"word":"anciennes","word_nosc":"anciennes","lemma":"ancien","pos":"ADJ"} ,
		{"word":"anciens","word_nosc":"anciens","lemma":"ancien","pos":"ADJ"} ,
		{"word":"ancillaire","word_nosc":"ancillaire","lemma":"ancillaire","pos":"ADJ"} ,
		{"word":"ancillaires","word_nosc":"ancillaires","lemma":"ancillaire","pos":"ADJ"} ,
		{"word":"ancré","word_nosc":"ancre","lemma":"ancré","pos":"ADJ"} ,
		{"word":"ancrée","word_nosc":"ancree","lemma":"ancré","pos":"ADJ"} ,
		{"word":"ancrées","word_nosc":"ancrees","lemma":"ancré","pos":"ADJ"} ,
		{"word":"ancrés","word_nosc":"ancres","lemma":"ancré","pos":"ADJ"} ,
		{"word":"andalou","word_nosc":"andalou","lemma":"andalou","pos":"ADJ"} ,
		{"word":"andalous","word_nosc":"andalous","lemma":"andalou","pos":"ADJ"} ,
		{"word":"andalouse","word_nosc":"andalouse","lemma":"andalou","pos":"ADJ"} ,
		{"word":"andalouses","word_nosc":"andalouses","lemma":"andalou","pos":"ADJ"} ,
		{"word":"andine","word_nosc":"andine","lemma":"andin","pos":"ADJ"} ,
		{"word":"andines","word_nosc":"andines","lemma":"andin","pos":"ADJ"} ,
		{"word":"andorranes","word_nosc":"andorranes","lemma":"andorran","pos":"ADJ"} ,
		{"word":"androgyne","word_nosc":"androgyne","lemma":"androgyne","pos":"ADJ"} ,
		{"word":"androgynes","word_nosc":"androgynes","lemma":"androgyne","pos":"ADJ"} ,
		{"word":"androgynique","word_nosc":"androgynique","lemma":"androgynique","pos":"ADJ"} ,
		{"word":"androïde","word_nosc":"androide","lemma":"androïde","pos":"ADJ"} ,
		{"word":"androïdes","word_nosc":"androides","lemma":"androïde","pos":"ADJ"} ,
		{"word":"anecdotique","word_nosc":"anecdotique","lemma":"anecdotique","pos":"ADJ"} ,
		{"word":"anecdotiques","word_nosc":"anecdotiques","lemma":"anecdotique","pos":"ADJ"} ,
		{"word":"anesthésiant","word_nosc":"anesthesiant","lemma":"anesthésiant","pos":"ADJ"} ,
		{"word":"anesthésiante","word_nosc":"anesthesiante","lemma":"anesthésiant","pos":"ADJ"} ,
		{"word":"anesthésiantes","word_nosc":"anesthesiantes","lemma":"anesthésiant","pos":"ADJ"} ,
		{"word":"anesthésiants","word_nosc":"anesthesiants","lemma":"anesthésiant","pos":"ADJ"} ,
		{"word":"anesthésique","word_nosc":"anesthesique","lemma":"anesthésique","pos":"ADJ"} ,
		{"word":"angevin","word_nosc":"angevin","lemma":"angevin","pos":"ADJ"} ,
		{"word":"angevine","word_nosc":"angevine","lemma":"angevin","pos":"ADJ"} ,
		{"word":"anglais","word_nosc":"anglais","lemma":"anglais","pos":"ADJ"} ,
		{"word":"anglaise","word_nosc":"anglaise","lemma":"anglais","pos":"ADJ"} ,
		{"word":"anglaises","word_nosc":"anglaises","lemma":"anglais","pos":"ADJ"} ,
		{"word":"anglican","word_nosc":"anglican","lemma":"anglican","pos":"ADJ"} ,
		{"word":"anglicane","word_nosc":"anglicane","lemma":"anglican","pos":"ADJ"} ,
		{"word":"anglicans","word_nosc":"anglicans","lemma":"anglican","pos":"ADJ"} ,
		{"word":"anglo-albanais","word_nosc":"anglo-albanais","lemma":"anglo-albanais","pos":"ADJ"} ,
		{"word":"anglo-allemand","word_nosc":"anglo-allemand","lemma":"anglo-allemand","pos":"ADJ"} ,
		{"word":"anglo-américain","word_nosc":"anglo-americain","lemma":"anglo-américain","pos":"ADJ"} ,
		{"word":"anglo-américaine","word_nosc":"anglo-americaine","lemma":"anglo-américain","pos":"ADJ"} ,
		{"word":"anglo-américaines","word_nosc":"anglo-americaines","lemma":"anglo-américain","pos":"ADJ"} ,
		{"word":"anglo-américains","word_nosc":"anglo-americains","lemma":"anglo-américain","pos":"ADJ"} ,
		{"word":"anglo-arabe","word_nosc":"anglo-arabe","lemma":"anglo-arabe","pos":"ADJ"} ,
		{"word":"anglo-arabes","word_nosc":"anglo-arabes","lemma":"anglo-arabe","pos":"ADJ"} ,
		{"word":"anglo-chinois","word_nosc":"anglo-chinois","lemma":"anglo-chinois","pos":"ADJ"} ,
		{"word":"anglo-français","word_nosc":"anglo-francais","lemma":"anglo-français","pos":"ADJ"} ,
		{"word":"anglo-française","word_nosc":"anglo-francaise","lemma":"anglo-français","pos":"ADJ"} ,
		{"word":"anglo-hellénique","word_nosc":"anglo-hellenique","lemma":"anglo-hellénique","pos":"ADJ"} ,
		{"word":"anglo-irlandais","word_nosc":"anglo-irlandais","lemma":"anglo-irlandais","pos":"ADJ"} ,
		{"word":"anglo-normand","word_nosc":"anglo-normand","lemma":"anglo-normand","pos":"ADJ"} ,
		{"word":"anglo-normande","word_nosc":"anglo-normande","lemma":"anglo-normand","pos":"ADJ"} ,
		{"word":"anglo-normandes","word_nosc":"anglo-normandes","lemma":"anglo-normand","pos":"ADJ"} ,
		{"word":"anglo-russe","word_nosc":"anglo-russe","lemma":"anglo-russe","pos":"ADJ"} ,
		{"word":"anglo-russes","word_nosc":"anglo-russes","lemma":"anglo-russe","pos":"ADJ"} ,
		{"word":"anglo-saxon","word_nosc":"anglo-saxon","lemma":"anglo-saxon","pos":"ADJ"} ,
		{"word":"anglo-saxonne","word_nosc":"anglo-saxonne","lemma":"anglo-saxon","pos":"ADJ"} ,
		{"word":"anglo-saxonnes","word_nosc":"anglo-saxonnes","lemma":"anglo-saxon","pos":"ADJ"} ,
		{"word":"anglo-saxons","word_nosc":"anglo-saxons","lemma":"anglo-saxon","pos":"ADJ"} ,
		{"word":"anglo-soviétique","word_nosc":"anglo-sovietique","lemma":"anglo-soviétique","pos":"ADJ"} ,
		{"word":"anglo-égyptien","word_nosc":"anglo-egyptien","lemma":"anglo-égyptien","pos":"ADJ"} ,
		{"word":"anglo-égyptienne","word_nosc":"anglo-egyptienne","lemma":"anglo-égyptien","pos":"ADJ"} ,
		{"word":"anglo-éthiopien","word_nosc":"anglo-ethiopien","lemma":"anglo-éthiopien","pos":"ADJ"} ,
		{"word":"anglomane","word_nosc":"anglomane","lemma":"anglomane","pos":"ADJ"} ,
		{"word":"anglomanes","word_nosc":"anglomanes","lemma":"anglomane","pos":"ADJ"} ,
		{"word":"anglophile","word_nosc":"anglophile","lemma":"anglophile","pos":"ADJ"} ,
		{"word":"anglophobe","word_nosc":"anglophobe","lemma":"anglophobe","pos":"ADJ"} ,
		{"word":"anglophone","word_nosc":"anglophone","lemma":"anglophone","pos":"ADJ"} ,
		{"word":"anglophones","word_nosc":"anglophones","lemma":"anglophone","pos":"ADJ"} ,
		{"word":"angoissant","word_nosc":"angoissant","lemma":"angoissant","pos":"ADJ"} ,
		{"word":"angoissante","word_nosc":"angoissante","lemma":"angoissant","pos":"ADJ"} ,
		{"word":"angoissantes","word_nosc":"angoissantes","lemma":"angoissant","pos":"ADJ"} ,
		{"word":"angoissants","word_nosc":"angoissants","lemma":"angoissant","pos":"ADJ"} ,
		{"word":"angoissé","word_nosc":"angoisse","lemma":"angoissé","pos":"ADJ"} ,
		{"word":"angoissée","word_nosc":"angoissee","lemma":"angoissé","pos":"ADJ"} ,
		{"word":"angoissées","word_nosc":"angoissees","lemma":"angoissé","pos":"ADJ"} ,
		{"word":"angoissés","word_nosc":"angoisses","lemma":"angoissé","pos":"ADJ"} ,
		{"word":"angolais","word_nosc":"angolais","lemma":"angolais","pos":"ADJ"} ,
		{"word":"angora","word_nosc":"angora","lemma":"angora","pos":"ADJ"} ,
		{"word":"angoras","word_nosc":"angoras","lemma":"angora","pos":"ADJ"} ,
		{"word":"angulaire","word_nosc":"angulaire","lemma":"angulaire","pos":"ADJ"} ,
		{"word":"angulaires","word_nosc":"angulaires","lemma":"angulaire","pos":"ADJ"} ,
		{"word":"anguleuse","word_nosc":"anguleuse","lemma":"anguleux","pos":"ADJ"} ,
		{"word":"anguleuses","word_nosc":"anguleuses","lemma":"anguleux","pos":"ADJ"} ,
		{"word":"anguleux","word_nosc":"anguleux","lemma":"anguleux","pos":"ADJ"} ,
		{"word":"angélique","word_nosc":"angelique","lemma":"angélique","pos":"ADJ"} ,
		{"word":"angéliques","word_nosc":"angeliques","lemma":"angélique","pos":"ADJ"} ,
		{"word":"anhydre","word_nosc":"anhydre","lemma":"anhydre","pos":"ADJ"} ,
		{"word":"animal","word_nosc":"animal","lemma":"animal","pos":"ADJ"} ,
		{"word":"animale","word_nosc":"animale","lemma":"animal","pos":"ADJ"} ,
		{"word":"animales","word_nosc":"animales","lemma":"animal","pos":"ADJ"} ,
		{"word":"animalier","word_nosc":"animalier","lemma":"animalier","pos":"ADJ"} ,
		{"word":"animaliers","word_nosc":"animaliers","lemma":"animalier","pos":"ADJ"} ,
		{"word":"animalière","word_nosc":"animaliere","lemma":"animalier","pos":"ADJ"} ,
		{"word":"animalières","word_nosc":"animalieres","lemma":"animalier","pos":"ADJ"} ,
		{"word":"animaux","word_nosc":"animaux","lemma":"animaux","pos":"ADJ"} ,
		{"word":"animique","word_nosc":"animique","lemma":"animique","pos":"ADJ"} ,
		{"word":"animiste","word_nosc":"animiste","lemma":"animiste","pos":"ADJ"} ,
		{"word":"animé","word_nosc":"anime","lemma":"animé","pos":"ADJ"} ,
		{"word":"animée","word_nosc":"animee","lemma":"animé","pos":"ADJ"} ,
		{"word":"animées","word_nosc":"animees","lemma":"animé","pos":"ADJ"} ,
		{"word":"animés","word_nosc":"animes","lemma":"animé","pos":"ADJ"} ,
		{"word":"anionique","word_nosc":"anionique","lemma":"anionique","pos":"ADJ"} ,
		{"word":"ankylosé","word_nosc":"ankylose","lemma":"ankylosé","pos":"ADJ"} ,
		{"word":"ankylosée","word_nosc":"ankylosee","lemma":"ankylosé","pos":"ADJ"} ,
		{"word":"ankylosées","word_nosc":"ankylosees","lemma":"ankylosé","pos":"ADJ"} ,
		{"word":"ankylosés","word_nosc":"ankyloses","lemma":"ankylosé","pos":"ADJ"} ,
		{"word":"annamite","word_nosc":"annamite","lemma":"annamite","pos":"ADJ"} ,
		{"word":"annelé","word_nosc":"annele","lemma":"annelé","pos":"ADJ"} ,
		{"word":"annelée","word_nosc":"annelee","lemma":"annelé","pos":"ADJ"} ,
		{"word":"annelées","word_nosc":"annelees","lemma":"annelé","pos":"ADJ"} ,
		{"word":"annelés","word_nosc":"anneles","lemma":"annelé","pos":"ADJ"} ,
		{"word":"annexe","word_nosc":"annexe","lemma":"annexe","pos":"ADJ"} ,
		{"word":"annexes","word_nosc":"annexes","lemma":"annexe","pos":"ADJ"} ,
		{"word":"annexée","word_nosc":"annexee","lemma":"annexé","pos":"ADJ"} ,
		{"word":"annexées","word_nosc":"annexees","lemma":"annexé","pos":"ADJ"} ,
		{"word":"annexés","word_nosc":"annexes","lemma":"annexé","pos":"ADJ"} ,
		{"word":"annihilant","word_nosc":"annihilant","lemma":"annihilant","pos":"ADJ"} ,
		{"word":"annihilante","word_nosc":"annihilante","lemma":"annihilant","pos":"ADJ"} ,
		{"word":"annihilateur","word_nosc":"annihilateur","lemma":"annihilateur","pos":"ADJ"} ,
		{"word":"anniversaire","word_nosc":"anniversaire","lemma":"anniversaire","pos":"ADJ"} ,
		{"word":"anniversaires","word_nosc":"anniversaires","lemma":"anniversaire","pos":"ADJ"} ,
		{"word":"annonciateur","word_nosc":"annonciateur","lemma":"annonciateur","pos":"ADJ"} ,
		{"word":"annonciateurs","word_nosc":"annonciateurs","lemma":"annonciateur","pos":"ADJ"} ,
		{"word":"annonciatrice","word_nosc":"annonciatrice","lemma":"annonciateur","pos":"ADJ"} ,
		{"word":"annonciatrices","word_nosc":"annonciatrices","lemma":"annonciateur","pos":"ADJ"} ,
		{"word":"annuel","word_nosc":"annuel","lemma":"annuel","pos":"ADJ"} ,
		{"word":"annuelle","word_nosc":"annuelle","lemma":"annuel","pos":"ADJ"} ,
		{"word":"annuelles","word_nosc":"annuelles","lemma":"annuel","pos":"ADJ"} ,
		{"word":"annuels","word_nosc":"annuels","lemma":"annuel","pos":"ADJ"} ,
		{"word":"annulaire","word_nosc":"annulaire","lemma":"annulaire","pos":"ADJ"} ,
		{"word":"annulé","word_nosc":"annule","lemma":"annulé","pos":"ADJ"} ,
		{"word":"annulée","word_nosc":"annulee","lemma":"annulé","pos":"ADJ"} ,
		{"word":"annulées","word_nosc":"annulees","lemma":"annulé","pos":"ADJ"} ,
		{"word":"annulés","word_nosc":"annules","lemma":"annulé","pos":"ADJ"} ,
		{"word":"anobli","word_nosc":"anobli","lemma":"anobli","pos":"ADJ"} ,
		{"word":"anoblie","word_nosc":"anoblie","lemma":"anobli","pos":"ADJ"} ,
		{"word":"anoblies","word_nosc":"anoblies","lemma":"anobli","pos":"ADJ"} ,
		{"word":"anoblissante","word_nosc":"anoblissante","lemma":"anoblissant","pos":"ADJ"} ,
		{"word":"anodin","word_nosc":"anodin","lemma":"anodin","pos":"ADJ"} ,
		{"word":"anodine","word_nosc":"anodine","lemma":"anodin","pos":"ADJ"} ,
		{"word":"anodines","word_nosc":"anodines","lemma":"anodin","pos":"ADJ"} ,
		{"word":"anodins","word_nosc":"anodins","lemma":"anodin","pos":"ADJ"} ,
		{"word":"anodique","word_nosc":"anodique","lemma":"anodique","pos":"ADJ"} ,
		{"word":"anonyme","word_nosc":"anonyme","lemma":"anonyme","pos":"ADJ"} ,
		{"word":"anonymes","word_nosc":"anonymes","lemma":"anonyme","pos":"ADJ"} ,
		{"word":"anorexique","word_nosc":"anorexique","lemma":"anorexique","pos":"ADJ"} ,
		{"word":"anorexiques","word_nosc":"anorexiques","lemma":"anorexique","pos":"ADJ"} ,
		{"word":"anormal","word_nosc":"anormal","lemma":"anormal","pos":"ADJ"} ,
		{"word":"anormale","word_nosc":"anormale","lemma":"anormal","pos":"ADJ"} ,
		{"word":"anormales","word_nosc":"anormales","lemma":"anormal","pos":"ADJ"} ,
		{"word":"anormaux","word_nosc":"anormaux","lemma":"anormal","pos":"ADJ"} ,
		{"word":"anosmique","word_nosc":"anosmique","lemma":"anosmique","pos":"ADJ"} ,
		{"word":"ansée","word_nosc":"ansee","lemma":"ansé","pos":"ADJ"} ,
		{"word":"ansées","word_nosc":"ansees","lemma":"ansé","pos":"ADJ"} ,
		{"word":"antagonique","word_nosc":"antagonique","lemma":"antagonique","pos":"ADJ"} ,
		{"word":"antagoniste","word_nosc":"antagoniste","lemma":"antagoniste","pos":"ADJ"} ,
		{"word":"antagonistes","word_nosc":"antagonistes","lemma":"antagoniste","pos":"ADJ"} ,
		{"word":"antarctique","word_nosc":"antarctique","lemma":"antarctique","pos":"ADJ"} ,
		{"word":"antarctiques","word_nosc":"antarctiques","lemma":"antarctique","pos":"ADJ"} ,
		{"word":"anthracite","word_nosc":"anthracite","lemma":"anthracite","pos":"ADJ"} ,
		{"word":"anthropiques","word_nosc":"anthropiques","lemma":"anthropique","pos":"ADJ"} ,
		{"word":"anthropologique","word_nosc":"anthropologique","lemma":"anthropologique","pos":"ADJ"} ,
		{"word":"anthropologiques","word_nosc":"anthropologiques","lemma":"anthropologique","pos":"ADJ"} ,
		{"word":"anthropomorphe","word_nosc":"anthropomorphe","lemma":"anthropomorphe","pos":"ADJ"} ,
		{"word":"anthropomorphique","word_nosc":"anthropomorphique","lemma":"anthropomorphique","pos":"ADJ"} ,
		{"word":"anthropométrique","word_nosc":"anthropometrique","lemma":"anthropométrique","pos":"ADJ"} ,
		{"word":"anthropométriques","word_nosc":"anthropometriques","lemma":"anthropométrique","pos":"ADJ"} ,
		{"word":"anthropophage","word_nosc":"anthropophage","lemma":"anthropophage","pos":"ADJ"} ,
		{"word":"anthropophages","word_nosc":"anthropophages","lemma":"anthropophage","pos":"ADJ"} ,
		{"word":"anthropophagiques","word_nosc":"anthropophagiques","lemma":"anthropophagique","pos":"ADJ"} ,
		{"word":"anthropoïde","word_nosc":"anthropoide","lemma":"anthropoïde","pos":"ADJ"} ,
		{"word":"anthropoïdes","word_nosc":"anthropoides","lemma":"anthropoïde","pos":"ADJ"} ,
		{"word":"anthume","word_nosc":"anthume","lemma":"anthume","pos":"ADJ"} ,
		{"word":"anti-anatomique","word_nosc":"anti-anatomique","lemma":"anti-anatomique","pos":"ADJ"} ,
		{"word":"anti-aphrodisiaque","word_nosc":"anti-aphrodisiaque","lemma":"anti-aphrodisiaque","pos":"ADJ"} ,
		{"word":"anti-atomique","word_nosc":"anti-atomique","lemma":"anti-atomique","pos":"ADJ"} ,
		{"word":"anti-atomiques","word_nosc":"anti-atomiques","lemma":"anti-atomique","pos":"ADJ"} ,
		{"word":"anti-aérien","word_nosc":"anti-aerien","lemma":"anti-aérien","pos":"ADJ"} ,
		{"word":"anti-aérienne","word_nosc":"anti-aerienne","lemma":"anti-aérien","pos":"ADJ"} ,
		{"word":"anti-aériens","word_nosc":"anti-aeriens","lemma":"anti-aérien","pos":"ADJ"} ,
		{"word":"anti-brouillard","word_nosc":"anti-brouillard","lemma":"anti-brouillard","pos":"ADJ"} ,
		{"word":"anti-calciques","word_nosc":"anti-calciques","lemma":"anti-calcique","pos":"ADJ"} ,
		{"word":"anti-capitaliste","word_nosc":"anti-capitaliste","lemma":"anti-capitaliste","pos":"ADJ"} ,
		{"word":"anti-castriste","word_nosc":"anti-castriste","lemma":"anti-castriste","pos":"ADJ"} ,
		{"word":"anti-communiste","word_nosc":"anti-communiste","lemma":"anti-communiste","pos":"ADJ"} ,
		{"word":"anti-conglutinatif","word_nosc":"anti-conglutinatif","lemma":"anti-conglutinatif","pos":"ADJ"} ,
		{"word":"anti-célibataires","word_nosc":"anti-celibataires","lemma":"anti-célibataire","pos":"ADJ"} ,
		{"word":"anti-dramatique","word_nosc":"anti-dramatique","lemma":"anti-dramatique","pos":"ADJ"} ,
		{"word":"anti-démocratique","word_nosc":"anti-democratique","lemma":"anti-démocratique","pos":"ADJ"} ,
		{"word":"anti-démocratiques","word_nosc":"anti-democratiques","lemma":"anti-démocratique","pos":"ADJ"} ,
		{"word":"anti-fasciste","word_nosc":"anti-fasciste","lemma":"anti-fasciste","pos":"ADJ"} ,
		{"word":"anti-fusionnel","word_nosc":"anti-fusionnel","lemma":"anti-fusionnel","pos":"ADJ"} ,
		{"word":"anti-féministe","word_nosc":"anti-feministe","lemma":"anti-féministe","pos":"ADJ"} ,
		{"word":"anti-g","word_nosc":"anti-g","lemma":"anti-g","pos":"ADJ"} ,
		{"word":"anti-gros","word_nosc":"anti-gros","lemma":"anti-gros","pos":"ADJ"} ,
		{"word":"anti-hypertenseur","word_nosc":"anti-hypertenseur","lemma":"anti-hypertenseur","pos":"ADJ"} ,
		{"word":"anti-immigrés","word_nosc":"anti-immigres","lemma":"anti-immigré","pos":"ADJ"} ,
		{"word":"anti-impérialiste","word_nosc":"anti-imperialiste","lemma":"anti-impérialiste","pos":"ADJ"} ,
		{"word":"anti-infectieux","word_nosc":"anti-infectieux","lemma":"anti-infectieux","pos":"ADJ"} ,
		{"word":"anti-inflammatoire","word_nosc":"anti-inflammatoire","lemma":"anti-inflammatoire","pos":"ADJ"} ,
		{"word":"anti-insurrectionnel","word_nosc":"anti-insurrectionnel","lemma":"anti-insurrectionnel","pos":"ADJ"} ,
		{"word":"anti-jeunes","word_nosc":"anti-jeunes","lemma":"anti-jeunes","pos":"ADJ"} ,
		{"word":"anti-monstres","word_nosc":"anti-monstres","lemma":"anti-monstre","pos":"ADJ"} ,
		{"word":"anti-métaphysique","word_nosc":"anti-metaphysique","lemma":"anti-métaphysique","pos":"ADJ"} ,
		{"word":"anti-nucléaire","word_nosc":"anti-nucleaire","lemma":"anti-nucléaire","pos":"ADJ"} ,
		{"word":"anti-nucléaires","word_nosc":"anti-nucleaires","lemma":"anti-nucléaire","pos":"ADJ"} ,
		{"word":"anti-odorants","word_nosc":"anti-odorants","lemma":"anti-odorant","pos":"ADJ"} ,
		{"word":"anti-patriotique","word_nosc":"anti-patriotique","lemma":"anti-patriotique","pos":"ADJ"} ,
		{"word":"anti-pelliculaire","word_nosc":"anti-pelliculaire","lemma":"anti-pelliculaire","pos":"ADJ"} ,
		{"word":"anti-pirate","word_nosc":"anti-pirate","lemma":"anti-pirate","pos":"ADJ"} ,
		{"word":"anti-psychotique","word_nosc":"anti-psychotique","lemma":"anti-psychotique","pos":"ADJ"} ,
		{"word":"anti-pédérastiques","word_nosc":"anti-pederastiques","lemma":"anti-pédérastique","pos":"ADJ"} ,
		{"word":"anti-romantique","word_nosc":"anti-romantique","lemma":"anti-romantique","pos":"ADJ"} ,
		{"word":"anti-russe","word_nosc":"anti-russe","lemma":"anti-russe","pos":"ADJ"} ,
		{"word":"anti-socialistes","word_nosc":"anti-socialistes","lemma":"anti-socialiste","pos":"ADJ"} ,
		{"word":"anti-sociaux","word_nosc":"anti-sociaux","lemma":"anti-sociaux","pos":"ADJ"} ,
		{"word":"anti-sous-marin","word_nosc":"anti-sous-marin","lemma":"anti-sous-marin","pos":"ADJ"} ,
		{"word":"anti-soviétique","word_nosc":"anti-sovietique","lemma":"anti-soviétique","pos":"ADJ"} ,
		{"word":"anti-syndical","word_nosc":"anti-syndical","lemma":"anti-syndical","pos":"ADJ"} ,
		{"word":"anti-sèche","word_nosc":"anti-seche","lemma":"anti-sèche","pos":"ADJ"} ,
		{"word":"anti-sèches","word_nosc":"anti-seches","lemma":"anti-sèche","pos":"ADJ"} ,
		{"word":"anti-sémite","word_nosc":"anti-semite","lemma":"anti-sémite","pos":"ADJ"} ,
		{"word":"anti-sémites","word_nosc":"anti-semites","lemma":"anti-sémite","pos":"ADJ"} ,
		{"word":"anti-tabac","word_nosc":"anti-tabac","lemma":"anti-tabac","pos":"ADJ"} ,
		{"word":"anti-terroriste","word_nosc":"anti-terroriste","lemma":"anti-terroriste","pos":"ADJ"} ,
		{"word":"anti-viral","word_nosc":"anti-viral","lemma":"anti-viral","pos":"ADJ"} ,
		{"word":"anti-âge","word_nosc":"anti-age","lemma":"anti-âge","pos":"ADJ"} ,
		{"word":"anti-émeute","word_nosc":"anti-emeute","lemma":"anti-émeute","pos":"ADJ"} ,
		{"word":"anti-émeutes","word_nosc":"anti-emeutes","lemma":"anti-émeute","pos":"ADJ"} ,
		{"word":"anti-émotionnelle","word_nosc":"anti-emotionnelle","lemma":"anti-émotionnelle","pos":"ADJ"} ,
		{"word":"antiacide","word_nosc":"antiacide","lemma":"antiacide","pos":"ADJ"} ,
		{"word":"antiacides","word_nosc":"antiacides","lemma":"antiacide","pos":"ADJ"} ,
		{"word":"antialcoolique","word_nosc":"antialcoolique","lemma":"antialcoolique","pos":"ADJ"} ,
		{"word":"antiallemands","word_nosc":"antiallemands","lemma":"antiallemand","pos":"ADJ"} ,
		{"word":"antiaméricain","word_nosc":"antiamericain","lemma":"antiaméricain","pos":"ADJ"} ,
		{"word":"antiaméricaine","word_nosc":"antiamericaine","lemma":"antiaméricain","pos":"ADJ"} ,
		{"word":"antiaméricaines","word_nosc":"antiamericaines","lemma":"antiaméricain","pos":"ADJ"} ,
		{"word":"antiatomique","word_nosc":"antiatomique","lemma":"antiatomique","pos":"ADJ"} ,
		{"word":"antiaérien","word_nosc":"antiaerien","lemma":"antiaérien","pos":"ADJ"} ,
		{"word":"antiaérienne","word_nosc":"antiaerienne","lemma":"antiaérien","pos":"ADJ"} ,
		{"word":"antiaériennes","word_nosc":"antiaeriennes","lemma":"antiaérien","pos":"ADJ"} ,
		{"word":"antiaériens","word_nosc":"antiaeriens","lemma":"antiaérien","pos":"ADJ"} ,
		{"word":"antibactérien","word_nosc":"antibacterien","lemma":"antibactérien","pos":"ADJ"} ,
		{"word":"antibiotique","word_nosc":"antibiotique","lemma":"antibiotique","pos":"ADJ"} ,
		{"word":"antibiotiques","word_nosc":"antibiotiques","lemma":"antibiotique","pos":"ADJ"} ,
		{"word":"antiblocage","word_nosc":"antiblocage","lemma":"antiblocage","pos":"ADJ"} ,
		{"word":"antibolcheviques","word_nosc":"antibolcheviques","lemma":"antibolchevique","pos":"ADJ"} ,
		{"word":"antibourgeois","word_nosc":"antibourgeois","lemma":"antibourgeois","pos":"ADJ"} ,
		{"word":"antibrouillard","word_nosc":"antibrouillard","lemma":"antibrouillard","pos":"ADJ"} ,
		{"word":"antibruit","word_nosc":"antibruit","lemma":"antibruit","pos":"ADJ"} ,
		{"word":"anticalcaire","word_nosc":"anticalcaire","lemma":"anticalcaire","pos":"ADJ"} ,
		{"word":"anticapitaliste","word_nosc":"anticapitaliste","lemma":"anticapitaliste","pos":"ADJ"} ,
		{"word":"anticastriste","word_nosc":"anticastriste","lemma":"anticastriste","pos":"ADJ"} ,
		{"word":"anticastristes","word_nosc":"anticastristes","lemma":"anticastriste","pos":"ADJ"} ,
		{"word":"anticatholique","word_nosc":"anticatholique","lemma":"anticatholique","pos":"ADJ"} ,
		{"word":"antichar","word_nosc":"antichar","lemma":"antichar","pos":"ADJ"} ,
		{"word":"antichars","word_nosc":"antichars","lemma":"antichar","pos":"ADJ"} ,
		{"word":"antichoc","word_nosc":"antichoc","lemma":"antichoc","pos":"ADJ"} ,
		{"word":"anticholinergique","word_nosc":"anticholinergique","lemma":"anticholinergique","pos":"ADJ"} ,
		{"word":"antichrétien","word_nosc":"antichretien","lemma":"antichrétien","pos":"ADJ"} ,
		{"word":"antichrétienne","word_nosc":"antichretienne","lemma":"antichrétien","pos":"ADJ"} ,
		{"word":"anticipateur","word_nosc":"anticipateur","lemma":"anticipateur","pos":"ADJ"} ,
		{"word":"anticipatoire","word_nosc":"anticipatoire","lemma":"anticipatoire","pos":"ADJ"} ,
		{"word":"anticipatrice","word_nosc":"anticipatrice","lemma":"anticipateur","pos":"ADJ"} ,
		{"word":"anticipé","word_nosc":"anticipe","lemma":"anticipé","pos":"ADJ"} ,
		{"word":"anticipée","word_nosc":"anticipee","lemma":"anticipé","pos":"ADJ"} ,
		{"word":"anticipées","word_nosc":"anticipees","lemma":"anticipé","pos":"ADJ"} ,
		{"word":"anticipés","word_nosc":"anticipes","lemma":"anticipé","pos":"ADJ"} ,
		{"word":"anticlérical","word_nosc":"anticlerical","lemma":"anticlérical","pos":"ADJ"} ,
		{"word":"anticléricale","word_nosc":"anticlericale","lemma":"anticlérical","pos":"ADJ"} ,
		{"word":"anticléricales","word_nosc":"anticlericales","lemma":"anticlérical","pos":"ADJ"} ,
		{"word":"anticléricaux","word_nosc":"anticlericaux","lemma":"anticlérical","pos":"ADJ"} ,
		{"word":"anticommuniste","word_nosc":"anticommuniste","lemma":"anticommuniste","pos":"ADJ"} ,
		{"word":"anticommunistes","word_nosc":"anticommunistes","lemma":"anticommuniste","pos":"ADJ"} ,
		{"word":"anticonceptionnelle","word_nosc":"anticonceptionnelle","lemma":"anticonceptionnel","pos":"ADJ"} ,
		{"word":"anticonceptionnelles","word_nosc":"anticonceptionnelles","lemma":"anticonceptionnel","pos":"ADJ"} ,
		{"word":"anticonceptionnels","word_nosc":"anticonceptionnels","lemma":"anticonceptionnel","pos":"ADJ"} ,
		{"word":"anticonformiste","word_nosc":"anticonformiste","lemma":"anticonformiste","pos":"ADJ"} ,
		{"word":"anticonstitutionnel","word_nosc":"anticonstitutionnel","lemma":"anticonstitutionnel","pos":"ADJ"} ,
		{"word":"anticonstitutionnelle","word_nosc":"anticonstitutionnelle","lemma":"anticonstitutionnel","pos":"ADJ"} ,
		{"word":"anticyclique","word_nosc":"anticyclique","lemma":"anticyclique","pos":"ADJ"} ,
		{"word":"anticycloniques","word_nosc":"anticycloniques","lemma":"anticyclonique","pos":"ADJ"} ,
		{"word":"antidictatoriaux","word_nosc":"antidictatoriaux","lemma":"antidictatorial","pos":"ADJ"} ,
		{"word":"antidopage","word_nosc":"antidopage","lemma":"antidopage","pos":"ADJ"} ,
		{"word":"antidouleur","word_nosc":"antidouleur","lemma":"antidouleur","pos":"ADJ"} ,
		{"word":"antidreyfusarde","word_nosc":"antidreyfusarde","lemma":"antidreyfusard","pos":"ADJ"} ,
		{"word":"antidrogue","word_nosc":"antidrogue","lemma":"antidrogue","pos":"ADJ"} ,
		{"word":"antidémocratique","word_nosc":"antidemocratique","lemma":"antidémocratique","pos":"ADJ"} ,
		{"word":"antidépresseur","word_nosc":"antidepresseur","lemma":"antidépresseur","pos":"ADJ"} ,
		{"word":"antidépresseurs","word_nosc":"antidepresseurs","lemma":"antidépresseur","pos":"ADJ"} ,
		{"word":"antidérapant","word_nosc":"antiderapant","lemma":"antidérapant","pos":"ADJ"} ,
		{"word":"antidérapante","word_nosc":"antiderapante","lemma":"antidérapant","pos":"ADJ"} ,
		{"word":"antidérapantes","word_nosc":"antiderapantes","lemma":"antidérapant","pos":"ADJ"} ,
		{"word":"antidérapants","word_nosc":"antiderapants","lemma":"antidérapant","pos":"ADJ"} ,
		{"word":"antifasciste","word_nosc":"antifasciste","lemma":"antifasciste","pos":"ADJ"} ,
		{"word":"antifascistes","word_nosc":"antifascistes","lemma":"antifasciste","pos":"ADJ"} ,
		{"word":"antifrançaise","word_nosc":"antifrancaise","lemma":"antifrançais","pos":"ADJ"} ,
		{"word":"antifrançaises","word_nosc":"antifrancaises","lemma":"antifrançais","pos":"ADJ"} ,
		{"word":"antifumée","word_nosc":"antifumee","lemma":"antifumée","pos":"ADJ"} ,
		{"word":"antigang","word_nosc":"antigang","lemma":"antigang","pos":"ADJ"} ,
		{"word":"antigaullistes","word_nosc":"antigaullistes","lemma":"antigaulliste","pos":"ADJ"} ,
		{"word":"antigel","word_nosc":"antigel","lemma":"antigel","pos":"ADJ"} ,
		{"word":"antigravitationnel","word_nosc":"antigravitationnel","lemma":"antigravitationnel","pos":"ADJ"} ,
		{"word":"antihistaminique","word_nosc":"antihistaminique","lemma":"antihistaminique","pos":"ADJ"} ,
		{"word":"antihistaminiques","word_nosc":"antihistaminiques","lemma":"antihistaminique","pos":"ADJ"} ,
		{"word":"antihygiénique","word_nosc":"antihygienique","lemma":"antihygiénique","pos":"ADJ"} ,
		{"word":"antihémorragiques","word_nosc":"antihemorragiques","lemma":"antihémorragique","pos":"ADJ"} ,
		{"word":"antillais","word_nosc":"antillais","lemma":"antillais","pos":"ADJ"} ,
		{"word":"antillaises","word_nosc":"antillaises","lemma":"antillais","pos":"ADJ"} ,
		{"word":"antilogique","word_nosc":"antilogique","lemma":"antilogique","pos":"ADJ"} ,
		{"word":"antimarxiste","word_nosc":"antimarxiste","lemma":"antimarxiste","pos":"ADJ"} ,
		{"word":"antimaçonniques","word_nosc":"antimaconniques","lemma":"antimaçonnique","pos":"ADJ"} ,
		{"word":"antimicrobien","word_nosc":"antimicrobien","lemma":"antimicrobien","pos":"ADJ"} ,
		{"word":"antimigraineux","word_nosc":"antimigraineux","lemma":"antimigraineux","pos":"ADJ"} ,
		{"word":"antimilitariste","word_nosc":"antimilitariste","lemma":"antimilitariste","pos":"ADJ"} ,
		{"word":"antimilitaristes","word_nosc":"antimilitaristes","lemma":"antimilitariste","pos":"ADJ"} ,
		{"word":"antimissile","word_nosc":"antimissile","lemma":"antimissile","pos":"ADJ"} ,
		{"word":"antimite","word_nosc":"antimite","lemma":"antimite","pos":"ADJ"} ,
		{"word":"antimonde","word_nosc":"antimonde","lemma":"antimonde","pos":"ADJ"} ,
		{"word":"antinationale","word_nosc":"antinationale","lemma":"antinational","pos":"ADJ"} ,
		{"word":"antinationales","word_nosc":"antinationales","lemma":"antinational","pos":"ADJ"} ,
		{"word":"antinationaliste","word_nosc":"antinationaliste","lemma":"antinationaliste","pos":"ADJ"} ,
		{"word":"antinaturel","word_nosc":"antinaturel","lemma":"antinaturel","pos":"ADJ"} ,
		{"word":"antinaturelle","word_nosc":"antinaturelle","lemma":"antinaturel","pos":"ADJ"} ,
		{"word":"antinazie","word_nosc":"antinazie","lemma":"antinazi","pos":"ADJ"} ,
		{"word":"antinazis","word_nosc":"antinazis","lemma":"antinazi","pos":"ADJ"} ,
		{"word":"antinomique","word_nosc":"antinomique","lemma":"antinomique","pos":"ADJ"} ,
		{"word":"antinomiques","word_nosc":"antinomiques","lemma":"antinomique","pos":"ADJ"} ,
		{"word":"antinucléaire","word_nosc":"antinucleaire","lemma":"antinucléaire","pos":"ADJ"} ,
		{"word":"antinucléaires","word_nosc":"antinucleaires","lemma":"antinucléaire","pos":"ADJ"} ,
		{"word":"antiparasitage","word_nosc":"antiparasitage","lemma":"antiparasitage","pos":"ADJ"} ,
		{"word":"antiparasite","word_nosc":"antiparasite","lemma":"antiparasite","pos":"ADJ"} ,
		{"word":"antipathique","word_nosc":"antipathique","lemma":"antipathique","pos":"ADJ"} ,
		{"word":"antipathiques","word_nosc":"antipathiques","lemma":"antipathique","pos":"ADJ"} ,
		{"word":"antipatriote","word_nosc":"antipatriote","lemma":"antipatriote","pos":"ADJ"} ,
		{"word":"antipatriotiques","word_nosc":"antipatriotiques","lemma":"antipatriotique","pos":"ADJ"} ,
		{"word":"antipelliculaire","word_nosc":"antipelliculaire","lemma":"antipelliculaire","pos":"ADJ"} ,
		{"word":"antipersonnel","word_nosc":"antipersonnel","lemma":"antipersonnel","pos":"ADJ"} ,
		{"word":"antipersonnelle","word_nosc":"antipersonnelle","lemma":"antipersonnel","pos":"ADJ"} ,
		{"word":"antipersonnelles","word_nosc":"antipersonnelles","lemma":"antipersonnel","pos":"ADJ"} ,
		{"word":"antiphysiques","word_nosc":"antiphysiques","lemma":"antiphysique","pos":"ADJ"} ,
		{"word":"antipodaire","word_nosc":"antipodaire","lemma":"antipodaire","pos":"ADJ"} ,
		{"word":"antipoison","word_nosc":"antipoison","lemma":"antipoison","pos":"ADJ"} ,
		{"word":"antipoliomyélitique","word_nosc":"antipoliomyelitique","lemma":"antipoliomyélitique","pos":"ADJ"} ,
		{"word":"antipollution","word_nosc":"antipollution","lemma":"antipollution","pos":"ADJ"} ,
		{"word":"antipopulaire","word_nosc":"antipopulaire","lemma":"antipopulaire","pos":"ADJ"} ,
		{"word":"antipsychotique","word_nosc":"antipsychotique","lemma":"antipsychotique","pos":"ADJ"} ,
		{"word":"antipsychotiques","word_nosc":"antipsychotiques","lemma":"antipsychotique","pos":"ADJ"} ,
		{"word":"antique","word_nosc":"antique","lemma":"antique","pos":"ADJ"} ,
		{"word":"antiques","word_nosc":"antiques","lemma":"antique","pos":"ADJ"} ,
		{"word":"antirabique","word_nosc":"antirabique","lemma":"antirabique","pos":"ADJ"} ,
		{"word":"antiracistes","word_nosc":"antiracistes","lemma":"antiraciste","pos":"ADJ"} ,
		{"word":"antiradiation","word_nosc":"antiradiation","lemma":"antiradiation","pos":"ADJ"} ,
		{"word":"antireligieuse","word_nosc":"antireligieuse","lemma":"antireligieux","pos":"ADJ"} ,
		{"word":"antireligieux","word_nosc":"antireligieux","lemma":"antireligieux","pos":"ADJ"} ,
		{"word":"antirides","word_nosc":"antirides","lemma":"antirides","pos":"ADJ"} ,
		{"word":"antirouille","word_nosc":"antirouille","lemma":"antirouille","pos":"ADJ"} ,
		{"word":"antirusse","word_nosc":"antirusse","lemma":"antirusse","pos":"ADJ"} ,
		{"word":"antirépublicain","word_nosc":"antirepublicain","lemma":"antirépublicain","pos":"ADJ"} ,
		{"word":"antisatellite","word_nosc":"antisatellite","lemma":"antisatellite","pos":"ADJ"} ,
		{"word":"antiscientifique","word_nosc":"antiscientifique","lemma":"antiscientifique","pos":"ADJ"} ,
		{"word":"antiseptique","word_nosc":"antiseptique","lemma":"antiseptique","pos":"ADJ"} ,
		{"word":"antiseptiques","word_nosc":"antiseptiques","lemma":"antiseptique","pos":"ADJ"} ,
		{"word":"antisexistes","word_nosc":"antisexistes","lemma":"antisexiste","pos":"ADJ"} ,
		{"word":"antisexuel","word_nosc":"antisexuel","lemma":"antisexuel","pos":"ADJ"} ,
		{"word":"antisionistes","word_nosc":"antisionistes","lemma":"antisioniste","pos":"ADJ"} ,
		{"word":"antiskating","word_nosc":"antiskating","lemma":"antiskating","pos":"ADJ"} ,
		{"word":"antisocial","word_nosc":"antisocial","lemma":"antisocial","pos":"ADJ"} ,
		{"word":"antisociale","word_nosc":"antisociale","lemma":"antisocial","pos":"ADJ"} ,
		{"word":"antisociales","word_nosc":"antisociales","lemma":"antisocial","pos":"ADJ"} ,
		{"word":"antisociaux","word_nosc":"antisociaux","lemma":"antisocial","pos":"ADJ"} ,
		{"word":"antisolaire","word_nosc":"antisolaire","lemma":"antisolaire","pos":"ADJ"} ,
		{"word":"antisolaires","word_nosc":"antisolaires","lemma":"antisolaire","pos":"ADJ"} ,
		{"word":"antisoviétique","word_nosc":"antisovietique","lemma":"antisoviétique","pos":"ADJ"} ,
		{"word":"antisoviétiques","word_nosc":"antisovietiques","lemma":"antisoviétique","pos":"ADJ"} ,
		{"word":"antispasmodique","word_nosc":"antispasmodique","lemma":"antispasmodique","pos":"ADJ"} ,
		{"word":"antisportif","word_nosc":"antisportif","lemma":"antisportif","pos":"ADJ"} ,
		{"word":"antistalinien","word_nosc":"antistalinien","lemma":"antistalinien","pos":"ADJ"} ,
		{"word":"antistaliniens","word_nosc":"antistaliniens","lemma":"antistalinien","pos":"ADJ"} ,
		{"word":"antistatique","word_nosc":"antistatique","lemma":"antistatique","pos":"ADJ"} ,
		{"word":"antistatiques","word_nosc":"antistatiques","lemma":"antistatique","pos":"ADJ"} ,
		{"word":"antistress","word_nosc":"antistress","lemma":"antistress","pos":"ADJ"} ,
		{"word":"antisémite","word_nosc":"antisemite","lemma":"antisémite","pos":"ADJ"} ,
		{"word":"antisémites","word_nosc":"antisemites","lemma":"antisémite","pos":"ADJ"} ,
		{"word":"antitabac","word_nosc":"antitabac","lemma":"antitabac","pos":"ADJ"} ,
		{"word":"antiterroriste","word_nosc":"antiterroriste","lemma":"antiterroriste","pos":"ADJ"} ,
		{"word":"antithétiques","word_nosc":"antithetiques","lemma":"antithétique","pos":"ADJ"} ,
		{"word":"antitoxique","word_nosc":"antitoxique","lemma":"antitoxique","pos":"ADJ"} ,
		{"word":"antitrust","word_nosc":"antitrust","lemma":"antitrust","pos":"ADJ"} ,
		{"word":"antitussif","word_nosc":"antitussif","lemma":"antitussif","pos":"ADJ"} ,
		{"word":"antitussives","word_nosc":"antitussives","lemma":"antitussif","pos":"ADJ"} ,
		{"word":"antityphoïdique","word_nosc":"antityphoidique","lemma":"antityphoïdique","pos":"ADJ"} ,
		{"word":"antitétanique","word_nosc":"antitetanique","lemma":"antitétanique","pos":"ADJ"} ,
		{"word":"antivariolique","word_nosc":"antivariolique","lemma":"antivariolique","pos":"ADJ"} ,
		{"word":"antivenimeux","word_nosc":"antivenimeux","lemma":"antivenimeux","pos":"ADJ"} ,
		{"word":"antiviolence","word_nosc":"antiviolence","lemma":"antiviolence","pos":"ADJ"} ,
		{"word":"antiviral","word_nosc":"antiviral","lemma":"antiviral","pos":"ADJ"} ,
		{"word":"antiviraux","word_nosc":"antiviraux","lemma":"antiviral","pos":"ADJ"} ,
		{"word":"antivol","word_nosc":"antivol","lemma":"antivol","pos":"ADJ"} ,
		{"word":"antiémeute","word_nosc":"antiemeute","lemma":"antiémeute","pos":"ADJ"} ,
		{"word":"antonin","word_nosc":"antonin","lemma":"antonin","pos":"ADJ"} ,
		{"word":"antécédent","word_nosc":"antecedent","lemma":"antécédent","pos":"ADJ"} ,
		{"word":"antécédents","word_nosc":"antecedents","lemma":"antécédent","pos":"ADJ"} ,
		{"word":"antédiluvien","word_nosc":"antediluvien","lemma":"antédiluvien","pos":"ADJ"} ,
		{"word":"antédiluvienne","word_nosc":"antediluvienne","lemma":"antédiluvien","pos":"ADJ"} ,
		{"word":"antédiluviennes","word_nosc":"antediluviennes","lemma":"antédiluvien","pos":"ADJ"} ,
		{"word":"antédiluviens","word_nosc":"antediluviens","lemma":"antédiluvien","pos":"ADJ"} ,
		{"word":"antérieur","word_nosc":"anterieur","lemma":"antérieur","pos":"ADJ"} ,
		{"word":"antérieure","word_nosc":"anterieure","lemma":"antérieur","pos":"ADJ"} ,
		{"word":"antérieures","word_nosc":"anterieures","lemma":"antérieur","pos":"ADJ"} ,
		{"word":"antérieurs","word_nosc":"anterieurs","lemma":"antérieur","pos":"ADJ"} ,
		{"word":"antérograde","word_nosc":"anterograde","lemma":"antérograde","pos":"ADJ"} ,
		{"word":"anxieuse","word_nosc":"anxieuse","lemma":"anxieux","pos":"ADJ"} ,
		{"word":"anxieuses","word_nosc":"anxieuses","lemma":"anxieux","pos":"ADJ"} ,
		{"word":"anxieux","word_nosc":"anxieux","lemma":"anxieux","pos":"ADJ"} ,
		{"word":"anéantissante","word_nosc":"aneantissante","lemma":"anéantissant","pos":"ADJ"} ,
		{"word":"anéantissantes","word_nosc":"aneantissantes","lemma":"anéantissant","pos":"ADJ"} ,
		{"word":"anémiante","word_nosc":"anemiante","lemma":"anémiant","pos":"ADJ"} ,
		{"word":"anémique","word_nosc":"anemique","lemma":"anémique","pos":"ADJ"} ,
		{"word":"anémiques","word_nosc":"anemiques","lemma":"anémique","pos":"ADJ"} ,
		{"word":"anémié","word_nosc":"anemie","lemma":"anémié","pos":"ADJ"} ,
		{"word":"anémiée","word_nosc":"anemiee","lemma":"anémié","pos":"ADJ"} ,
		{"word":"anémiés","word_nosc":"anemies","lemma":"anémié","pos":"ADJ"} ,
		{"word":"anémométrique","word_nosc":"anemometrique","lemma":"anémométrique","pos":"ADJ"} ,
		{"word":"aortique","word_nosc":"aortique","lemma":"aortique","pos":"ADJ"} ,
		{"word":"aortiques","word_nosc":"aortiques","lemma":"aortique","pos":"ADJ"} ,
		{"word":"apache","word_nosc":"apache","lemma":"apache","pos":"ADJ"} ,
		{"word":"apaches","word_nosc":"apaches","lemma":"apache","pos":"ADJ"} ,
		{"word":"apaisant","word_nosc":"apaisant","lemma":"apaisant","pos":"ADJ"} ,
		{"word":"apaisante","word_nosc":"apaisante","lemma":"apaisant","pos":"ADJ"} ,
		{"word":"apaisantes","word_nosc":"apaisantes","lemma":"apaisant","pos":"ADJ"} ,
		{"word":"apaisants","word_nosc":"apaisants","lemma":"apaisant","pos":"ADJ"} ,
		{"word":"apathique","word_nosc":"apathique","lemma":"apathique","pos":"ADJ"} ,
		{"word":"apathiques","word_nosc":"apathiques","lemma":"apathique","pos":"ADJ"} ,
		{"word":"apatride","word_nosc":"apatride","lemma":"apatride","pos":"ADJ"} ,
		{"word":"apatrides","word_nosc":"apatrides","lemma":"apatride","pos":"ADJ"} ,
		{"word":"apeuré","word_nosc":"apeure","lemma":"apeuré","pos":"ADJ"} ,
		{"word":"apeurée","word_nosc":"apeuree","lemma":"apeuré","pos":"ADJ"} ,
		{"word":"apeurées","word_nosc":"apeurees","lemma":"apeuré","pos":"ADJ"} ,
		{"word":"apeurés","word_nosc":"apeures","lemma":"apeuré","pos":"ADJ"} ,
		{"word":"aphasique","word_nosc":"aphasique","lemma":"aphasique","pos":"ADJ"} ,
		{"word":"aphasiques","word_nosc":"aphasiques","lemma":"aphasique","pos":"ADJ"} ,
		{"word":"aphone","word_nosc":"aphone","lemma":"aphone","pos":"ADJ"} ,
		{"word":"aphones","word_nosc":"aphones","lemma":"aphone","pos":"ADJ"} ,
		{"word":"aphrodisiaque","word_nosc":"aphrodisiaque","lemma":"aphrodisiaque","pos":"ADJ"} ,
		{"word":"aphrodisiaques","word_nosc":"aphrodisiaques","lemma":"aphrodisiaque","pos":"ADJ"} ,
		{"word":"aphteuse","word_nosc":"aphteuse","lemma":"aphteux","pos":"ADJ"} ,
		{"word":"aphteux","word_nosc":"aphteux","lemma":"aphteux","pos":"ADJ"} ,
		{"word":"apivore","word_nosc":"apivore","lemma":"apivore","pos":"ADJ"} ,
		{"word":"aplati","word_nosc":"aplati","lemma":"aplati","pos":"ADJ"} ,
		{"word":"aplatie","word_nosc":"aplatie","lemma":"aplati","pos":"ADJ"} ,
		{"word":"aplaties","word_nosc":"aplaties","lemma":"aplati","pos":"ADJ"} ,
		{"word":"aplatis","word_nosc":"aplatis","lemma":"aplati","pos":"ADJ"} ,
		{"word":"apocalyptique","word_nosc":"apocalyptique","lemma":"apocalyptique","pos":"ADJ"} ,
		{"word":"apocalyptiques","word_nosc":"apocalyptiques","lemma":"apocalyptique","pos":"ADJ"} ,
		{"word":"apocryphe","word_nosc":"apocryphe","lemma":"apocryphe","pos":"ADJ"} ,
		{"word":"apocryphes","word_nosc":"apocryphes","lemma":"apocryphe","pos":"ADJ"} ,
		{"word":"apodictique","word_nosc":"apodictique","lemma":"apodictique","pos":"ADJ"} ,
		{"word":"apolitique","word_nosc":"apolitique","lemma":"apolitique","pos":"ADJ"} ,
		{"word":"apolitiques","word_nosc":"apolitiques","lemma":"apolitique","pos":"ADJ"} ,
		{"word":"apologétique","word_nosc":"apologetique","lemma":"apologétique","pos":"ADJ"} ,
		{"word":"apologétiques","word_nosc":"apologetiques","lemma":"apologétique","pos":"ADJ"} ,
		{"word":"aponévrotique","word_nosc":"aponevrotique","lemma":"aponévrotique","pos":"ADJ"} ,
		{"word":"apoplectique","word_nosc":"apoplectique","lemma":"apoplectique","pos":"ADJ"} ,
		{"word":"apoplectiques","word_nosc":"apoplectiques","lemma":"apoplectique","pos":"ADJ"} ,
		{"word":"apostat","word_nosc":"apostat","lemma":"apostat","pos":"ADJ"} ,
		{"word":"apostats","word_nosc":"apostats","lemma":"apostat","pos":"ADJ"} ,
		{"word":"apostolique","word_nosc":"apostolique","lemma":"apostolique","pos":"ADJ"} ,
		{"word":"apostoliques","word_nosc":"apostoliques","lemma":"apostolique","pos":"ADJ"} ,
		{"word":"apparaissant","word_nosc":"apparaissant","lemma":"apparaissant","pos":"ADJ"} ,
		{"word":"apparent","word_nosc":"apparent","lemma":"apparent","pos":"ADJ"} ,
		{"word":"apparente","word_nosc":"apparente","lemma":"apparent","pos":"ADJ"} ,
		{"word":"apparentes","word_nosc":"apparentes","lemma":"apparent","pos":"ADJ"} ,
		{"word":"apparents","word_nosc":"apparents","lemma":"apparent","pos":"ADJ"} ,
		{"word":"apparenté","word_nosc":"apparente","lemma":"apparenté","pos":"ADJ"} ,
		{"word":"apparentée","word_nosc":"apparentee","lemma":"apparenté","pos":"ADJ"} ,
		{"word":"apparentés","word_nosc":"apparentes","lemma":"apparenté","pos":"ADJ"} ,
		{"word":"appartenant","word_nosc":"appartenant","lemma":"appartenant","pos":"ADJ"} ,
		{"word":"appauvrissant","word_nosc":"appauvrissant","lemma":"appauvrissant","pos":"ADJ"} ,
		{"word":"appauvrissante","word_nosc":"appauvrissante","lemma":"appauvrissant","pos":"ADJ"} ,
		{"word":"appelant","word_nosc":"appelant","lemma":"appelant","pos":"ADJ"} ,
		{"word":"applicable","word_nosc":"applicable","lemma":"applicable","pos":"ADJ"} ,
		{"word":"applicables","word_nosc":"applicables","lemma":"applicable","pos":"ADJ"} ,
		{"word":"appliqué","word_nosc":"applique","lemma":"appliqué","pos":"ADJ"} ,
		{"word":"appliquée","word_nosc":"appliquee","lemma":"appliqué","pos":"ADJ"} ,
		{"word":"appliquées","word_nosc":"appliquees","lemma":"appliqué","pos":"ADJ"} ,
		{"word":"appliqués","word_nosc":"appliques","lemma":"appliqué","pos":"ADJ"} ,
		{"word":"appointé","word_nosc":"appointe","lemma":"appointé","pos":"ADJ"} ,
		{"word":"appointée","word_nosc":"appointee","lemma":"appointé","pos":"ADJ"} ,
		{"word":"appointés","word_nosc":"appointes","lemma":"appointé","pos":"ADJ"} ,
		{"word":"appris","word_nosc":"appris","lemma":"appris","pos":"ADJ"} ,
		{"word":"apprise","word_nosc":"apprise","lemma":"appris","pos":"ADJ"} ,
		{"word":"apprises","word_nosc":"apprises","lemma":"appris","pos":"ADJ"} ,
		{"word":"apprivoiseur","word_nosc":"apprivoiseur","lemma":"apprivoiseur","pos":"ADJ"} ,
		{"word":"approbateur","word_nosc":"approbateur","lemma":"approbateur","pos":"ADJ"} ,
		{"word":"approbateurs","word_nosc":"approbateurs","lemma":"approbateur","pos":"ADJ"} ,
		{"word":"approbatif","word_nosc":"approbatif","lemma":"approbatif","pos":"ADJ"} ,
		{"word":"approbatrice","word_nosc":"approbatrice","lemma":"approbateur","pos":"ADJ"} ,
		{"word":"approchant","word_nosc":"approchant","lemma":"approchant","pos":"ADJ"} ,
		{"word":"approchants","word_nosc":"approchants","lemma":"approchant","pos":"ADJ"} ,
		{"word":"approché","word_nosc":"approche","lemma":"approché","pos":"ADJ"} ,
		{"word":"approchée","word_nosc":"approchee","lemma":"approché","pos":"ADJ"} ,
		{"word":"approchés","word_nosc":"approches","lemma":"approché","pos":"ADJ"} ,
		{"word":"approfondi","word_nosc":"approfondi","lemma":"approfondi","pos":"ADJ"} ,
		{"word":"approfondie","word_nosc":"approfondie","lemma":"approfondi","pos":"ADJ"} ,
		{"word":"approfondies","word_nosc":"approfondies","lemma":"approfondi","pos":"ADJ"} ,
		{"word":"approfondis","word_nosc":"approfondis","lemma":"approfondi","pos":"ADJ"} ,
		{"word":"approprié","word_nosc":"approprie","lemma":"approprié","pos":"ADJ"} ,
		{"word":"appropriée","word_nosc":"appropriee","lemma":"approprié","pos":"ADJ"} ,
		{"word":"appropriées","word_nosc":"appropriees","lemma":"approprié","pos":"ADJ"} ,
		{"word":"appropriés","word_nosc":"appropries","lemma":"approprié","pos":"ADJ"} ,
		{"word":"approuvé","word_nosc":"approuve","lemma":"approuvé","pos":"ADJ"} ,
		{"word":"approuvée","word_nosc":"approuvee","lemma":"approuvé","pos":"ADJ"} ,
		{"word":"approuvées","word_nosc":"approuvees","lemma":"approuvé","pos":"ADJ"} ,
		{"word":"approuvés","word_nosc":"approuves","lemma":"approuvé","pos":"ADJ"} ,
		{"word":"approximatif","word_nosc":"approximatif","lemma":"approximatif","pos":"ADJ"} ,
		{"word":"approximatifs","word_nosc":"approximatifs","lemma":"approximatif","pos":"ADJ"} ,
		{"word":"approximative","word_nosc":"approximative","lemma":"approximatif","pos":"ADJ"} ,
		{"word":"approximatives","word_nosc":"approximatives","lemma":"approximatif","pos":"ADJ"} ,
		{"word":"appréciable","word_nosc":"appreciable","lemma":"appréciable","pos":"ADJ"} ,
		{"word":"appréciables","word_nosc":"appreciables","lemma":"appréciable","pos":"ADJ"} ,
		{"word":"appréhensif","word_nosc":"apprehensif","lemma":"appréhensif","pos":"ADJ"} ,
		{"word":"apprêté","word_nosc":"apprete","lemma":"apprêté","pos":"ADJ"} ,
		{"word":"apprêtée","word_nosc":"appretee","lemma":"apprêté","pos":"ADJ"} ,
		{"word":"apprêtées","word_nosc":"appretees","lemma":"apprêté","pos":"ADJ"} ,
		{"word":"apprêtés","word_nosc":"appretes","lemma":"apprêté","pos":"ADJ"} ,
		{"word":"appuyé","word_nosc":"appuye","lemma":"appuyé","pos":"ADJ"} ,
		{"word":"appuyée","word_nosc":"appuyee","lemma":"appuyé","pos":"ADJ"} ,
		{"word":"appuyées","word_nosc":"appuyees","lemma":"appuyé","pos":"ADJ"} ,
		{"word":"appuyés","word_nosc":"appuyes","lemma":"appuyé","pos":"ADJ"} ,
		{"word":"appétissant","word_nosc":"appetissant","lemma":"appétissant","pos":"ADJ"} ,
		{"word":"appétissante","word_nosc":"appetissante","lemma":"appétissant","pos":"ADJ"} ,
		{"word":"appétissantes","word_nosc":"appetissantes","lemma":"appétissant","pos":"ADJ"} ,
		{"word":"appétissants","word_nosc":"appetissants","lemma":"appétissant","pos":"ADJ"} ,
		{"word":"après-rasage","word_nosc":"apres-rasage","lemma":"après-rasage","pos":"ADJ"} ,
		{"word":"après-vente","word_nosc":"apres-vente","lemma":"après-vente","pos":"ADJ"} ,
		{"word":"apte","word_nosc":"apte","lemma":"apte","pos":"ADJ"} ,
		{"word":"aptes","word_nosc":"aptes","lemma":"apte","pos":"ADJ"} ,
		{"word":"aptère","word_nosc":"aptere","lemma":"aptère","pos":"ADJ"} ,
		{"word":"aptères","word_nosc":"apteres","lemma":"aptère","pos":"ADJ"} ,
		{"word":"apéritif","word_nosc":"aperitif","lemma":"apéritif","pos":"ADJ"} ,
		{"word":"apéritifs","word_nosc":"aperitifs","lemma":"apéritif","pos":"ADJ"} ,
		{"word":"apéritive","word_nosc":"aperitive","lemma":"apéritif","pos":"ADJ"} ,
		{"word":"apéritives","word_nosc":"aperitives","lemma":"apéritif","pos":"ADJ"} ,
		{"word":"aquarellés","word_nosc":"aquarelles","lemma":"aquarellé","pos":"ADJ"} ,
		{"word":"aquatique","word_nosc":"aquatique","lemma":"aquatique","pos":"ADJ"} ,
		{"word":"aquatiques","word_nosc":"aquatiques","lemma":"aquatique","pos":"ADJ"} ,
		{"word":"aqueuse","word_nosc":"aqueuse","lemma":"aqueux","pos":"ADJ"} ,
		{"word":"aqueuses","word_nosc":"aqueuses","lemma":"aqueux","pos":"ADJ"} ,
		{"word":"aqueux","word_nosc":"aqueux","lemma":"aqueux","pos":"ADJ"} ,
		{"word":"aquifère","word_nosc":"aquifere","lemma":"aquifère","pos":"ADJ"} ,
		{"word":"aquilin","word_nosc":"aquilin","lemma":"aquilin","pos":"ADJ"} ,
		{"word":"aquitain","word_nosc":"aquitain","lemma":"aquitain","pos":"ADJ"} ,
		{"word":"arabe","word_nosc":"arabe","lemma":"arabe","pos":"ADJ"} ,
		{"word":"arabes","word_nosc":"arabes","lemma":"arabe","pos":"ADJ"} ,
		{"word":"arabique","word_nosc":"arabique","lemma":"arabique","pos":"ADJ"} ,
		{"word":"arabisant","word_nosc":"arabisant","lemma":"arabisant","pos":"ADJ"} ,
		{"word":"arabisants","word_nosc":"arabisants","lemma":"arabisant","pos":"ADJ"} ,
		{"word":"arable","word_nosc":"arable","lemma":"arable","pos":"ADJ"} ,
		{"word":"arables","word_nosc":"arables","lemma":"arable","pos":"ADJ"} ,
		{"word":"arachnéen","word_nosc":"arachneen","lemma":"arachnéen","pos":"ADJ"} ,
		{"word":"arachnéenne","word_nosc":"arachneenne","lemma":"arachnéen","pos":"ADJ"} ,
		{"word":"arachnéennes","word_nosc":"arachneennes","lemma":"arachnéen","pos":"ADJ"} ,
		{"word":"aragonais","word_nosc":"aragonais","lemma":"aragonais","pos":"ADJ"} ,
		{"word":"aragonaise","word_nosc":"aragonaise","lemma":"aragonais","pos":"ADJ"} ,
		{"word":"aramide","word_nosc":"aramide","lemma":"aramide","pos":"ADJ"} ,
		{"word":"araméen","word_nosc":"arameen","lemma":"araméen","pos":"ADJ"} ,
		{"word":"araméenne","word_nosc":"arameenne","lemma":"araméen","pos":"ADJ"} ,
		{"word":"aranéeuse","word_nosc":"araneeuse","lemma":"aranéeux","pos":"ADJ"} ,
		{"word":"aratoire","word_nosc":"aratoire","lemma":"aratoire","pos":"ADJ"} ,
		{"word":"aratoires","word_nosc":"aratoires","lemma":"aratoire","pos":"ADJ"} ,
		{"word":"araucanien","word_nosc":"araucanien","lemma":"araucanien","pos":"ADJ"} ,
		{"word":"araucaniennes","word_nosc":"araucaniennes","lemma":"araucanien","pos":"ADJ"} ,
		{"word":"araucaniens","word_nosc":"araucaniens","lemma":"araucanien","pos":"ADJ"} ,
		{"word":"arbitraire","word_nosc":"arbitraire","lemma":"arbitraire","pos":"ADJ"} ,
		{"word":"arbitraires","word_nosc":"arbitraires","lemma":"arbitraire","pos":"ADJ"} ,
		{"word":"arbitrale","word_nosc":"arbitrale","lemma":"arbitral","pos":"ADJ"} ,
		{"word":"arborescent","word_nosc":"arborescent","lemma":"arborescent","pos":"ADJ"} ,
		{"word":"arborescentes","word_nosc":"arborescentes","lemma":"arborescent","pos":"ADJ"} ,
		{"word":"arborescents","word_nosc":"arborescents","lemma":"arborescent","pos":"ADJ"} ,
		{"word":"arboricole","word_nosc":"arboricole","lemma":"arboricole","pos":"ADJ"} ,
		{"word":"arc-en-ciel","word_nosc":"arc-en-ciel","lemma":"arc-en-ciel","pos":"ADJ"} ,
		{"word":"arcadien","word_nosc":"arcadien","lemma":"arcadien","pos":"ADJ"} ,
		{"word":"arcadienne","word_nosc":"arcadienne","lemma":"arcadien","pos":"ADJ"} ,
		{"word":"arcadiens","word_nosc":"arcadiens","lemma":"arcadien","pos":"ADJ"} ,
		{"word":"archangélique","word_nosc":"archangelique","lemma":"archangélique","pos":"ADJ"} ,
		{"word":"archaïque","word_nosc":"archaique","lemma":"archaïque","pos":"ADJ"} ,
		{"word":"archaïques","word_nosc":"archaiques","lemma":"archaïque","pos":"ADJ"} ,
		{"word":"archi-blet","word_nosc":"archi-blet","lemma":"archi-blet","pos":"ADJ"} ,
		{"word":"archi-chiants","word_nosc":"archi-chiants","lemma":"archi-chiant","pos":"ADJ"} ,
		{"word":"archi-connu","word_nosc":"archi-connu","lemma":"archi-connu","pos":"ADJ"} ,
		{"word":"archi-connus","word_nosc":"archi-connus","lemma":"archi-connu","pos":"ADJ"} ,
		{"word":"archi-dégueu","word_nosc":"archi-degueu","lemma":"archi-dégueu","pos":"ADJ"} ,
		{"word":"archi-faux","word_nosc":"archi-faux","lemma":"archi-faux","pos":"ADJ"} ,
		{"word":"archi-libre","word_nosc":"archi-libre","lemma":"archi-libre","pos":"ADJ"} ,
		{"word":"archi-prouvé","word_nosc":"archi-prouve","lemma":"archi-prouvé","pos":"ADJ"} ,
		{"word":"archi-sophistiquée","word_nosc":"archi-sophistiquee","lemma":"archi-sophistiqué","pos":"ADJ"} ,
		{"word":"archi-sèches","word_nosc":"archi-seches","lemma":"archi-sèche","pos":"ADJ"} ,
		{"word":"archi-usé","word_nosc":"archi-use","lemma":"archi-usé","pos":"ADJ"} ,
		{"word":"archi-vieux","word_nosc":"archi-vieux","lemma":"archi-vieux","pos":"ADJ"} ,
		{"word":"archicomble","word_nosc":"archicomble","lemma":"archicomble","pos":"ADJ"} ,
		{"word":"archicombles","word_nosc":"archicombles","lemma":"archicomble","pos":"ADJ"} ,
		{"word":"archiconnu","word_nosc":"archiconnu","lemma":"archiconnu","pos":"ADJ"} ,
		{"word":"archifaux","word_nosc":"archifaux","lemma":"archifaux","pos":"ADJ"} ,
		{"word":"archinulles","word_nosc":"archinulles","lemma":"archinul","pos":"ADJ"} ,
		{"word":"architectonique","word_nosc":"architectonique","lemma":"architectonique","pos":"ADJ"} ,
		{"word":"architectural","word_nosc":"architectural","lemma":"architectural","pos":"ADJ"} ,
		{"word":"architecturale","word_nosc":"architecturale","lemma":"architectural","pos":"ADJ"} ,
		{"word":"architecturales","word_nosc":"architecturales","lemma":"architectural","pos":"ADJ"} ,
		{"word":"architecturaux","word_nosc":"architecturaux","lemma":"architectural","pos":"ADJ"} ,
		{"word":"archiépiscopal","word_nosc":"archiepiscopal","lemma":"archiépiscopal","pos":"ADJ"} ,
		{"word":"archéologique","word_nosc":"archeologique","lemma":"archéologique","pos":"ADJ"} ,
		{"word":"archéologiques","word_nosc":"archeologiques","lemma":"archéologique","pos":"ADJ"} ,
		{"word":"archétypale","word_nosc":"archetypale","lemma":"archétypal","pos":"ADJ"} ,
		{"word":"archétypaux","word_nosc":"archetypaux","lemma":"archétypal","pos":"ADJ"} ,
		{"word":"arctique","word_nosc":"arctique","lemma":"arctique","pos":"ADJ"} ,
		{"word":"arctiques","word_nosc":"arctiques","lemma":"arctique","pos":"ADJ"} ,
		{"word":"ardent","word_nosc":"ardent","lemma":"ardent","pos":"ADJ"} ,
		{"word":"ardente","word_nosc":"ardente","lemma":"ardent","pos":"ADJ"} ,
		{"word":"ardentes","word_nosc":"ardentes","lemma":"ardent","pos":"ADJ"} ,
		{"word":"ardents","word_nosc":"ardents","lemma":"ardent","pos":"ADJ"} ,
		{"word":"ardoise","word_nosc":"ardoise","lemma":"ardoise","pos":"ADJ"} ,
		{"word":"ardoisée","word_nosc":"ardoisee","lemma":"ardoisé","pos":"ADJ"} ,
		{"word":"ardoisés","word_nosc":"ardoises","lemma":"ardoisé","pos":"ADJ"} ,
		{"word":"ardu","word_nosc":"ardu","lemma":"ardu","pos":"ADJ"} ,
		{"word":"ardue","word_nosc":"ardue","lemma":"ardu","pos":"ADJ"} ,
		{"word":"ardues","word_nosc":"ardues","lemma":"ardu","pos":"ADJ"} ,
		{"word":"ardus","word_nosc":"ardus","lemma":"ardu","pos":"ADJ"} ,
		{"word":"ardéchoise","word_nosc":"ardechoise","lemma":"ardéchois","pos":"ADJ"} ,
		{"word":"argent","word_nosc":"argent","lemma":"argent","pos":"ADJ"} ,
		{"word":"argentifères","word_nosc":"argentiferes","lemma":"argentifère","pos":"ADJ"} ,
		{"word":"argentin","word_nosc":"argentin","lemma":"argentin","pos":"ADJ"} ,
		{"word":"argentine","word_nosc":"argentine","lemma":"argentin","pos":"ADJ"} ,
		{"word":"argentines","word_nosc":"argentines","lemma":"argentin","pos":"ADJ"} ,
		{"word":"argentins","word_nosc":"argentins","lemma":"argentin","pos":"ADJ"} ,
		{"word":"argenté","word_nosc":"argente","lemma":"argenté","pos":"ADJ"} ,
		{"word":"argentée","word_nosc":"argentee","lemma":"argenté","pos":"ADJ"} ,
		{"word":"argentées","word_nosc":"argentees","lemma":"argenté","pos":"ADJ"} ,
		{"word":"argentés","word_nosc":"argentes","lemma":"argenté","pos":"ADJ"} ,
		{"word":"argileuse","word_nosc":"argileuse","lemma":"argileux","pos":"ADJ"} ,
		{"word":"argileux","word_nosc":"argileux","lemma":"argileux","pos":"ADJ"} ,
		{"word":"argotique","word_nosc":"argotique","lemma":"argotique","pos":"ADJ"} ,
		{"word":"argotiques","word_nosc":"argotiques","lemma":"argotique","pos":"ADJ"} ,
		{"word":"aride","word_nosc":"aride","lemma":"aride","pos":"ADJ"} ,
		{"word":"arides","word_nosc":"arides","lemma":"aride","pos":"ADJ"} ,
		{"word":"arien","word_nosc":"arien","lemma":"arien","pos":"ADJ"} ,
		{"word":"arienne","word_nosc":"arienne","lemma":"arien","pos":"ADJ"} ,
		{"word":"ariens","word_nosc":"ariens","lemma":"arien","pos":"ADJ"} ,
		{"word":"aristo","word_nosc":"aristo","lemma":"aristo","pos":"ADJ"} ,
		{"word":"aristocrate","word_nosc":"aristocrate","lemma":"aristocrate","pos":"ADJ"} ,
		{"word":"aristocrates","word_nosc":"aristocrates","lemma":"aristocrate","pos":"ADJ"} ,
		{"word":"aristocratique","word_nosc":"aristocratique","lemma":"aristocratique","pos":"ADJ"} ,
		{"word":"aristocratiques","word_nosc":"aristocratiques","lemma":"aristocratique","pos":"ADJ"} ,
		{"word":"aristos","word_nosc":"aristos","lemma":"aristo","pos":"ADJ"} ,
		{"word":"aristotélicien","word_nosc":"aristotelicien","lemma":"aristotélicien","pos":"ADJ"} ,
		{"word":"aristotélicienne","word_nosc":"aristotelicienne","lemma":"aristotélicien","pos":"ADJ"} ,
		{"word":"arithmétique","word_nosc":"arithmetique","lemma":"arithmétique","pos":"ADJ"} ,
		{"word":"arithmétiques","word_nosc":"arithmetiques","lemma":"arithmétique","pos":"ADJ"} ,
		{"word":"ariégeois","word_nosc":"ariegeois","lemma":"ariégeois","pos":"ADJ"} ,
		{"word":"ariégeoise","word_nosc":"ariegeoise","lemma":"ariégeois","pos":"ADJ"} ,
		{"word":"armorial","word_nosc":"armorial","lemma":"armorial","pos":"ADJ"} ,
		{"word":"armoricaine","word_nosc":"armoricaine","lemma":"armoricain","pos":"ADJ"} ,
		{"word":"armé","word_nosc":"arme","lemma":"armé","pos":"ADJ"} ,
		{"word":"armée","word_nosc":"armee","lemma":"armé","pos":"ADJ"} ,
		{"word":"armées","word_nosc":"armees","lemma":"armé","pos":"ADJ"} ,
		{"word":"arménien","word_nosc":"armenien","lemma":"arménien","pos":"ADJ"} ,
		{"word":"arménienne","word_nosc":"armenienne","lemma":"arménien","pos":"ADJ"} ,
		{"word":"arméniennes","word_nosc":"armeniennes","lemma":"arménien","pos":"ADJ"} ,
		{"word":"arméniens","word_nosc":"armeniens","lemma":"arménien","pos":"ADJ"} ,
		{"word":"arménoïde","word_nosc":"armenoide","lemma":"arménoïde","pos":"ADJ"} ,
		{"word":"armés","word_nosc":"armes","lemma":"armé","pos":"ADJ"} ,
		{"word":"aromatique","word_nosc":"aromatique","lemma":"aromatique","pos":"ADJ"} ,
		{"word":"aromatiques","word_nosc":"aromatiques","lemma":"aromatique","pos":"ADJ"} ,
		{"word":"arqué","word_nosc":"arque","lemma":"arqué","pos":"ADJ"} ,
		{"word":"arquée","word_nosc":"arquee","lemma":"arqué","pos":"ADJ"} ,
		{"word":"arquées","word_nosc":"arquees","lemma":"arqué","pos":"ADJ"} ,
		{"word":"arqués","word_nosc":"arques","lemma":"arqué","pos":"ADJ"} ,
		{"word":"arraché","word_nosc":"arrache","lemma":"arraché","pos":"ADJ"} ,
		{"word":"arrachée","word_nosc":"arrachee","lemma":"arraché","pos":"ADJ"} ,
		{"word":"arrachées","word_nosc":"arrachees","lemma":"arraché","pos":"ADJ"} ,
		{"word":"arrachés","word_nosc":"arraches","lemma":"arraché","pos":"ADJ"} ,
		{"word":"arrangeant","word_nosc":"arrangeant","lemma":"arrangeant","pos":"ADJ"} ,
		{"word":"arrangeante","word_nosc":"arrangeante","lemma":"arrangeant","pos":"ADJ"} ,
		{"word":"arrangeantes","word_nosc":"arrangeantes","lemma":"arrangeant","pos":"ADJ"} ,
		{"word":"arrangeants","word_nosc":"arrangeants","lemma":"arrangeant","pos":"ADJ"} ,
		{"word":"arriviste","word_nosc":"arriviste","lemma":"arriviste","pos":"ADJ"} ,
		{"word":"arrivistes","word_nosc":"arrivistes","lemma":"arriviste","pos":"ADJ"} ,
		{"word":"arrivé","word_nosc":"arrive","lemma":"arrivé","pos":"ADJ"} ,
		{"word":"arrivée","word_nosc":"arrivee","lemma":"arrivé","pos":"ADJ"} ,
		{"word":"arrivées","word_nosc":"arrivees","lemma":"arrivé","pos":"ADJ"} ,
		{"word":"arrivés","word_nosc":"arrives","lemma":"arrivé","pos":"ADJ"} ,
		{"word":"arrière","word_nosc":"arriere","lemma":"arrière","pos":"ADJ"} ,
		{"word":"arriéré","word_nosc":"arriere","lemma":"arriéré","pos":"ADJ"} ,
		{"word":"arriérée","word_nosc":"arrieree","lemma":"arriéré","pos":"ADJ"} ,
		{"word":"arriérées","word_nosc":"arrierees","lemma":"arriéré","pos":"ADJ"} ,
		{"word":"arriérés","word_nosc":"arrieres","lemma":"arriéré","pos":"ADJ"} ,
		{"word":"arrogant","word_nosc":"arrogant","lemma":"arrogant","pos":"ADJ"} ,
		{"word":"arrogante","word_nosc":"arrogante","lemma":"arrogant","pos":"ADJ"} ,
		{"word":"arrogantes","word_nosc":"arrogantes","lemma":"arrogant","pos":"ADJ"} ,
		{"word":"arrogants","word_nosc":"arrogants","lemma":"arrogant","pos":"ADJ"} ,
		{"word":"arrondi","word_nosc":"arrondi","lemma":"arrondi","pos":"ADJ"} ,
		{"word":"arrondie","word_nosc":"arrondie","lemma":"arrondi","pos":"ADJ"} ,
		{"word":"arrondies","word_nosc":"arrondies","lemma":"arrondi","pos":"ADJ"} ,
		{"word":"arrondis","word_nosc":"arrondis","lemma":"arrondi","pos":"ADJ"} ,
		{"word":"arrosé","word_nosc":"arrose","lemma":"arrosé","pos":"ADJ"} ,
		{"word":"arrosée","word_nosc":"arrosee","lemma":"arrosé","pos":"ADJ"} ,
		{"word":"arrosées","word_nosc":"arrosees","lemma":"arrosé","pos":"ADJ"} ,
		{"word":"arrosés","word_nosc":"arroses","lemma":"arrosé","pos":"ADJ"} ,
		{"word":"arrêté","word_nosc":"arrete","lemma":"arrêté","pos":"ADJ"} ,
		{"word":"arrêtée","word_nosc":"arretee","lemma":"arrêté","pos":"ADJ"} ,
		{"word":"arrêtées","word_nosc":"arretees","lemma":"arrêté","pos":"ADJ"} ,
		{"word":"arrêtés","word_nosc":"arretes","lemma":"arrêté","pos":"ADJ"} ,
		{"word":"arsenicales","word_nosc":"arsenicales","lemma":"arsenical","pos":"ADJ"} ,
		{"word":"arsouille","word_nosc":"arsouille","lemma":"arsouille","pos":"ADJ"} ,
		{"word":"arsouilles","word_nosc":"arsouilles","lemma":"arsouille","pos":"ADJ"} ,
		{"word":"arsénieux","word_nosc":"arsenieux","lemma":"arsénieux","pos":"ADJ"} ,
		{"word":"arthritique","word_nosc":"arthritique","lemma":"arthritique","pos":"ADJ"} ,
		{"word":"arthritiques","word_nosc":"arthritiques","lemma":"arthritique","pos":"ADJ"} ,
		{"word":"arthurien","word_nosc":"arthurien","lemma":"arthurien","pos":"ADJ"} ,
		{"word":"arthurienne","word_nosc":"arthurienne","lemma":"arthurien","pos":"ADJ"} ,
		{"word":"arthuriens","word_nosc":"arthuriens","lemma":"arthurien","pos":"ADJ"} ,
		{"word":"articulaire","word_nosc":"articulaire","lemma":"articulaire","pos":"ADJ"} ,
		{"word":"articulaires","word_nosc":"articulaires","lemma":"articulaire","pos":"ADJ"} ,
		{"word":"articulé","word_nosc":"articule","lemma":"articulé","pos":"ADJ"} ,
		{"word":"articulée","word_nosc":"articulee","lemma":"articulé","pos":"ADJ"} ,
		{"word":"articulées","word_nosc":"articulees","lemma":"articulé","pos":"ADJ"} ,
		{"word":"articulés","word_nosc":"articules","lemma":"articulé","pos":"ADJ"} ,
		{"word":"artificiel","word_nosc":"artificiel","lemma":"artificiel","pos":"ADJ"} ,
		{"word":"artificielle","word_nosc":"artificielle","lemma":"artificiel","pos":"ADJ"} ,
		{"word":"artificielles","word_nosc":"artificielles","lemma":"artificiel","pos":"ADJ"} ,
		{"word":"artificiels","word_nosc":"artificiels","lemma":"artificiel","pos":"ADJ"} ,
		{"word":"artificieuse","word_nosc":"artificieuse","lemma":"artificieux","pos":"ADJ"} ,
		{"word":"artificieuses","word_nosc":"artificieuses","lemma":"artificieux","pos":"ADJ"} ,
		{"word":"artisanal","word_nosc":"artisanal","lemma":"artisanal","pos":"ADJ"} ,
		{"word":"artisanale","word_nosc":"artisanale","lemma":"artisanal","pos":"ADJ"} ,
		{"word":"artisanales","word_nosc":"artisanales","lemma":"artisanal","pos":"ADJ"} ,
		{"word":"artisanaux","word_nosc":"artisanaux","lemma":"artisanal","pos":"ADJ"} ,
		{"word":"artiste","word_nosc":"artiste","lemma":"artiste","pos":"ADJ"} ,
		{"word":"artiste-peintre","word_nosc":"artiste-peintre","lemma":"artiste-peintre","pos":"ADJ"} ,
		{"word":"artistes","word_nosc":"artistes","lemma":"artiste","pos":"ADJ"} ,
		{"word":"artistique","word_nosc":"artistique","lemma":"artistique","pos":"ADJ"} ,
		{"word":"artistiques","word_nosc":"artistiques","lemma":"artistique","pos":"ADJ"} ,
		{"word":"artériel","word_nosc":"arteriel","lemma":"artériel","pos":"ADJ"} ,
		{"word":"artérielle","word_nosc":"arterielle","lemma":"artériel","pos":"ADJ"} ,
		{"word":"artérielles","word_nosc":"arterielles","lemma":"artériel","pos":"ADJ"} ,
		{"word":"artésien","word_nosc":"artesien","lemma":"artésien","pos":"ADJ"} ,
		{"word":"aryen","word_nosc":"aryen","lemma":"aryen","pos":"ADJ"} ,
		{"word":"aryenne","word_nosc":"aryenne","lemma":"aryen","pos":"ADJ"} ,
		{"word":"aryennes","word_nosc":"aryennes","lemma":"aryen","pos":"ADJ"} ,
		{"word":"aryens","word_nosc":"aryens","lemma":"aryen","pos":"ADJ"} ,
		{"word":"arythmique","word_nosc":"arythmique","lemma":"arythmique","pos":"ADJ"} ,
		{"word":"ascendant","word_nosc":"ascendant","lemma":"ascendant","pos":"ADJ"} ,
		{"word":"ascendante","word_nosc":"ascendante","lemma":"ascendant","pos":"ADJ"} ,
		{"word":"ascendantes","word_nosc":"ascendantes","lemma":"ascendant","pos":"ADJ"} ,
		{"word":"ascendants","word_nosc":"ascendants","lemma":"ascendant","pos":"ADJ"} ,
		{"word":"ascensionnel","word_nosc":"ascensionnel","lemma":"ascensionnel","pos":"ADJ"} ,
		{"word":"ascensionnelle","word_nosc":"ascensionnelle","lemma":"ascensionnel","pos":"ADJ"} ,
		{"word":"ascensionnelles","word_nosc":"ascensionnelles","lemma":"ascensionnel","pos":"ADJ"} ,
		{"word":"ascorbique","word_nosc":"ascorbique","lemma":"ascorbique","pos":"ADJ"} ,
		{"word":"ascétique","word_nosc":"ascetique","lemma":"ascétique","pos":"ADJ"} ,
		{"word":"ascétiques","word_nosc":"ascetiques","lemma":"ascétique","pos":"ADJ"} ,
		{"word":"aseptique","word_nosc":"aseptique","lemma":"aseptique","pos":"ADJ"} ,
		{"word":"aseptiques","word_nosc":"aseptiques","lemma":"aseptique","pos":"ADJ"} ,
		{"word":"aseptisé","word_nosc":"aseptise","lemma":"aseptisé","pos":"ADJ"} ,
		{"word":"aseptisée","word_nosc":"aseptisee","lemma":"aseptisé","pos":"ADJ"} ,
		{"word":"aseptisées","word_nosc":"aseptisees","lemma":"aseptisé","pos":"ADJ"} ,
		{"word":"aseptisés","word_nosc":"aseptises","lemma":"aseptisé","pos":"ADJ"} ,
		{"word":"asexuelle","word_nosc":"asexuelle","lemma":"asexuel","pos":"ADJ"} ,
		{"word":"asexué","word_nosc":"asexue","lemma":"asexué","pos":"ADJ"} ,
		{"word":"asexuée","word_nosc":"asexuee","lemma":"asexué","pos":"ADJ"} ,
		{"word":"asexuées","word_nosc":"asexuees","lemma":"asexué","pos":"ADJ"} ,
		{"word":"asexués","word_nosc":"asexues","lemma":"asexué","pos":"ADJ"} ,
		{"word":"asiate","word_nosc":"asiate","lemma":"asiate","pos":"ADJ"} ,
		{"word":"asiatique","word_nosc":"asiatique","lemma":"asiatique","pos":"ADJ"} ,
		{"word":"asiatiques","word_nosc":"asiatiques","lemma":"asiatique","pos":"ADJ"} ,
		{"word":"asilaire","word_nosc":"asilaire","lemma":"asilaire","pos":"ADJ"} ,
		{"word":"asocial","word_nosc":"asocial","lemma":"asocial","pos":"ADJ"} ,
		{"word":"asociale","word_nosc":"asociale","lemma":"asocial","pos":"ADJ"} ,
		{"word":"asociales","word_nosc":"asociales","lemma":"asocial","pos":"ADJ"} ,
		{"word":"asociaux","word_nosc":"asociaux","lemma":"asocial","pos":"ADJ"} ,
		{"word":"aspartique","word_nosc":"aspartique","lemma":"aspartique","pos":"ADJ"} ,
		{"word":"asphyxiant","word_nosc":"asphyxiant","lemma":"asphyxiant","pos":"ADJ"} ,
		{"word":"asphyxiante","word_nosc":"asphyxiante","lemma":"asphyxiant","pos":"ADJ"} ,
		{"word":"asphyxiants","word_nosc":"asphyxiants","lemma":"asphyxiant","pos":"ADJ"} ,
		{"word":"asphyxié","word_nosc":"asphyxie","lemma":"asphyxié","pos":"ADJ"} ,
		{"word":"asphyxiée","word_nosc":"asphyxiee","lemma":"asphyxié","pos":"ADJ"} ,
		{"word":"asphyxiées","word_nosc":"asphyxiees","lemma":"asphyxié","pos":"ADJ"} ,
		{"word":"asphyxiés","word_nosc":"asphyxies","lemma":"asphyxié","pos":"ADJ"} ,
		{"word":"aspirant","word_nosc":"aspirant","lemma":"aspirant","pos":"ADJ"} ,
		{"word":"aspirante","word_nosc":"aspirante","lemma":"aspirant","pos":"ADJ"} ,
		{"word":"aspirantes","word_nosc":"aspirantes","lemma":"aspirant","pos":"ADJ"} ,
		{"word":"aspirants","word_nosc":"aspirants","lemma":"aspirant","pos":"ADJ"} ,
		{"word":"aspirateur","word_nosc":"aspirateur","lemma":"aspirateur","pos":"ADJ"} ,
		{"word":"aspirateurs","word_nosc":"aspirateurs","lemma":"aspirateur","pos":"ADJ"} ,
		{"word":"aspiré","word_nosc":"aspire","lemma":"aspiré","pos":"ADJ"} ,
		{"word":"aspirée","word_nosc":"aspiree","lemma":"aspiré","pos":"ADJ"} ,
		{"word":"aspirées","word_nosc":"aspirees","lemma":"aspiré","pos":"ADJ"} ,
		{"word":"aspirés","word_nosc":"aspires","lemma":"aspiré","pos":"ADJ"} ,
		{"word":"aspécifique","word_nosc":"aspecifique","lemma":"aspécifique","pos":"ADJ"} ,
		{"word":"assagi","word_nosc":"assagi","lemma":"assagi","pos":"ADJ"} ,
		{"word":"assagie","word_nosc":"assagie","lemma":"assagi","pos":"ADJ"} ,
		{"word":"assagis","word_nosc":"assagis","lemma":"assagi","pos":"ADJ"} ,
		{"word":"assaillant","word_nosc":"assaillant","lemma":"assaillant","pos":"ADJ"} ,
		{"word":"assaillante","word_nosc":"assaillante","lemma":"assaillant","pos":"ADJ"} ,
		{"word":"assaillants","word_nosc":"assaillants","lemma":"assaillant","pos":"ADJ"} ,
		{"word":"assassin","word_nosc":"assassin","lemma":"assassin","pos":"ADJ"} ,
		{"word":"assassine","word_nosc":"assassine","lemma":"assassin","pos":"ADJ"} ,
		{"word":"assassines","word_nosc":"assassines","lemma":"assassin","pos":"ADJ"} ,
		{"word":"assassins","word_nosc":"assassins","lemma":"assassin","pos":"ADJ"} ,
		{"word":"assassiné","word_nosc":"assassine","lemma":"assassiné","pos":"ADJ"} ,
		{"word":"assassinée","word_nosc":"assassinee","lemma":"assassiné","pos":"ADJ"} ,
		{"word":"assassinées","word_nosc":"assassinees","lemma":"assassiné","pos":"ADJ"} ,
		{"word":"assassinés","word_nosc":"assassines","lemma":"assassiné","pos":"ADJ"} ,
		{"word":"assemblé","word_nosc":"assemble","lemma":"assemblé","pos":"ADJ"} ,
		{"word":"assemblés","word_nosc":"assembles","lemma":"assemblé","pos":"ADJ"} ,
		{"word":"assermenté","word_nosc":"assermente","lemma":"assermenté","pos":"ADJ"} ,
		{"word":"assermentés","word_nosc":"assermentes","lemma":"assermenté","pos":"ADJ"} ,
		{"word":"asservi","word_nosc":"asservi","lemma":"asservi","pos":"ADJ"} ,
		{"word":"asservie","word_nosc":"asservie","lemma":"asservi","pos":"ADJ"} ,
		{"word":"asservies","word_nosc":"asservies","lemma":"asservi","pos":"ADJ"} ,
		{"word":"asservis","word_nosc":"asservis","lemma":"asservi","pos":"ADJ"} ,
		{"word":"asservissant","word_nosc":"asservissant","lemma":"asservissant","pos":"ADJ"} ,
		{"word":"assidu","word_nosc":"assidu","lemma":"assidu","pos":"ADJ"} ,
		{"word":"assidue","word_nosc":"assidue","lemma":"assidu","pos":"ADJ"} ,
		{"word":"assidues","word_nosc":"assidues","lemma":"assidu","pos":"ADJ"} ,
		{"word":"assidus","word_nosc":"assidus","lemma":"assidu","pos":"ADJ"} ,
		{"word":"assimilable","word_nosc":"assimilable","lemma":"assimilable","pos":"ADJ"} ,
		{"word":"assimilables","word_nosc":"assimilables","lemma":"assimilable","pos":"ADJ"} ,
		{"word":"assimilant","word_nosc":"assimilant","lemma":"assimilant","pos":"ADJ"} ,
		{"word":"assimilateurs","word_nosc":"assimilateurs","lemma":"assimilateur","pos":"ADJ"} ,
		{"word":"assimilé","word_nosc":"assimile","lemma":"assimilé","pos":"ADJ"} ,
		{"word":"assimilée","word_nosc":"assimilee","lemma":"assimilé","pos":"ADJ"} ,
		{"word":"assimilées","word_nosc":"assimilees","lemma":"assimilé","pos":"ADJ"} ,
		{"word":"assimilés","word_nosc":"assimiles","lemma":"assimilé","pos":"ADJ"} ,
		{"word":"assis","word_nosc":"assis","lemma":"assis","pos":"ADJ"} ,
		{"word":"assise","word_nosc":"assise","lemma":"assis","pos":"ADJ"} ,
		{"word":"assises","word_nosc":"assises","lemma":"assis","pos":"ADJ"} ,
		{"word":"assisté","word_nosc":"assiste","lemma":"assisté","pos":"ADJ"} ,
		{"word":"assistée","word_nosc":"assistee","lemma":"assisté","pos":"ADJ"} ,
		{"word":"assistées","word_nosc":"assistees","lemma":"assisté","pos":"ADJ"} ,
		{"word":"assistés","word_nosc":"assistes","lemma":"assisté","pos":"ADJ"} ,
		{"word":"assiégeantes","word_nosc":"assiegeantes","lemma":"assiégeant","pos":"ADJ"} ,
		{"word":"assiégé","word_nosc":"assiege","lemma":"assiégé","pos":"ADJ"} ,
		{"word":"assiégée","word_nosc":"assiegee","lemma":"assiégé","pos":"ADJ"} ,
		{"word":"assiégées","word_nosc":"assiegees","lemma":"assiégé","pos":"ADJ"} ,
		{"word":"assiégés","word_nosc":"assieges","lemma":"assiégé","pos":"ADJ"} ,
		{"word":"associatifs","word_nosc":"associatifs","lemma":"associatif","pos":"ADJ"} ,
		{"word":"associative","word_nosc":"associative","lemma":"associatif","pos":"ADJ"} ,
		{"word":"associé","word_nosc":"associe","lemma":"associé","pos":"ADJ"} ,
		{"word":"associée","word_nosc":"associee","lemma":"associé","pos":"ADJ"} ,
		{"word":"associées","word_nosc":"associees","lemma":"associé","pos":"ADJ"} ,
		{"word":"associés","word_nosc":"associes","lemma":"associé","pos":"ADJ"} ,
		{"word":"assoiffé","word_nosc":"assoiffe","lemma":"assoiffé","pos":"ADJ"} ,
		{"word":"assoiffée","word_nosc":"assoiffee","lemma":"assoiffé","pos":"ADJ"} ,
		{"word":"assoiffées","word_nosc":"assoiffees","lemma":"assoiffé","pos":"ADJ"} ,
		{"word":"assoiffés","word_nosc":"assoiffes","lemma":"assoiffé","pos":"ADJ"} ,
		{"word":"assommant","word_nosc":"assommant","lemma":"assommant","pos":"ADJ"} ,
		{"word":"assommante","word_nosc":"assommante","lemma":"assommant","pos":"ADJ"} ,
		{"word":"assommantes","word_nosc":"assommantes","lemma":"assommant","pos":"ADJ"} ,
		{"word":"assommants","word_nosc":"assommants","lemma":"assommant","pos":"ADJ"} ,
		{"word":"assonants","word_nosc":"assonants","lemma":"assonant","pos":"ADJ"} ,
		{"word":"assorti","word_nosc":"assorti","lemma":"assorti","pos":"ADJ"} ,
		{"word":"assortie","word_nosc":"assortie","lemma":"assorti","pos":"ADJ"} ,
		{"word":"assorties","word_nosc":"assorties","lemma":"assorti","pos":"ADJ"} ,
		{"word":"assortis","word_nosc":"assortis","lemma":"assorti","pos":"ADJ"} ,
		{"word":"assoupi","word_nosc":"assoupi","lemma":"assoupi","pos":"ADJ"} ,
		{"word":"assoupie","word_nosc":"assoupie","lemma":"assoupi","pos":"ADJ"} ,
		{"word":"assoupies","word_nosc":"assoupies","lemma":"assoupi","pos":"ADJ"} ,
		{"word":"assoupis","word_nosc":"assoupis","lemma":"assoupi","pos":"ADJ"} ,
		{"word":"assoupissant","word_nosc":"assoupissant","lemma":"assoupissant","pos":"ADJ"} ,
		{"word":"assourdi","word_nosc":"assourdi","lemma":"assourdi","pos":"ADJ"} ,
		{"word":"assourdie","word_nosc":"assourdie","lemma":"assourdi","pos":"ADJ"} ,
		{"word":"assourdies","word_nosc":"assourdies","lemma":"assourdi","pos":"ADJ"} ,
		{"word":"assourdis","word_nosc":"assourdis","lemma":"assourdi","pos":"ADJ"} ,
		{"word":"assourdissant","word_nosc":"assourdissant","lemma":"assourdissant","pos":"ADJ"} ,
		{"word":"assourdissante","word_nosc":"assourdissante","lemma":"assourdissant","pos":"ADJ"} ,
		{"word":"assourdissantes","word_nosc":"assourdissantes","lemma":"assourdissant","pos":"ADJ"} ,
		{"word":"assourdissants","word_nosc":"assourdissants","lemma":"assourdissant","pos":"ADJ"} ,
		{"word":"assujetti","word_nosc":"assujetti","lemma":"assujetti","pos":"ADJ"} ,
		{"word":"assujetties","word_nosc":"assujetties","lemma":"assujetti","pos":"ADJ"} ,
		{"word":"assujettis","word_nosc":"assujettis","lemma":"assujetti","pos":"ADJ"} ,
		{"word":"assujettissant","word_nosc":"assujettissant","lemma":"assujettissant","pos":"ADJ"} ,
		{"word":"assurable","word_nosc":"assurable","lemma":"assurable","pos":"ADJ"} ,
		{"word":"assuré","word_nosc":"assure","lemma":"assuré","pos":"ADJ"} ,
		{"word":"assurée","word_nosc":"assuree","lemma":"assuré","pos":"ADJ"} ,
		{"word":"assurées","word_nosc":"assurees","lemma":"assuré","pos":"ADJ"} ,
		{"word":"assurés","word_nosc":"assures","lemma":"assuré","pos":"ADJ"} ,
		{"word":"assyrien","word_nosc":"assyrien","lemma":"assyrien","pos":"ADJ"} ,
		{"word":"assyrienne","word_nosc":"assyrienne","lemma":"assyrien","pos":"ADJ"} ,
		{"word":"assyriennes","word_nosc":"assyriennes","lemma":"assyrien","pos":"ADJ"} ,
		{"word":"assyriens","word_nosc":"assyriens","lemma":"assyrien","pos":"ADJ"} ,
		{"word":"asthmatique","word_nosc":"asthmatique","lemma":"asthmatique","pos":"ADJ"} ,
		{"word":"asthmatiques","word_nosc":"asthmatiques","lemma":"asthmatique","pos":"ADJ"} ,
		{"word":"astigmate","word_nosc":"astigmate","lemma":"astigmate","pos":"ADJ"} ,
		{"word":"astral","word_nosc":"astral","lemma":"astral","pos":"ADJ"} ,
		{"word":"astrale","word_nosc":"astrale","lemma":"astral","pos":"ADJ"} ,
		{"word":"astrales","word_nosc":"astrales","lemma":"astral","pos":"ADJ"} ,
		{"word":"astraux","word_nosc":"astraux","lemma":"astral","pos":"ADJ"} ,
		{"word":"astreignant","word_nosc":"astreignant","lemma":"astreignant","pos":"ADJ"} ,
		{"word":"astreignante","word_nosc":"astreignante","lemma":"astreignant","pos":"ADJ"} ,
		{"word":"astreignantes","word_nosc":"astreignantes","lemma":"astreignant","pos":"ADJ"} ,
		{"word":"astreint","word_nosc":"astreint","lemma":"astreint","pos":"ADJ"} ,
		{"word":"astreinte","word_nosc":"astreinte","lemma":"astreint","pos":"ADJ"} ,
		{"word":"astreintes","word_nosc":"astreintes","lemma":"astreint","pos":"ADJ"} ,
		{"word":"astreints","word_nosc":"astreints","lemma":"astreint","pos":"ADJ"} ,
		{"word":"astringent","word_nosc":"astringent","lemma":"astringent","pos":"ADJ"} ,
		{"word":"astringente","word_nosc":"astringente","lemma":"astringent","pos":"ADJ"} ,
		{"word":"astringentes","word_nosc":"astringentes","lemma":"astringent","pos":"ADJ"} ,
		{"word":"astrologique","word_nosc":"astrologique","lemma":"astrologique","pos":"ADJ"} ,
		{"word":"astrologiques","word_nosc":"astrologiques","lemma":"astrologique","pos":"ADJ"} ,
		{"word":"astronomique","word_nosc":"astronomique","lemma":"astronomique","pos":"ADJ"} ,
		{"word":"astronomiques","word_nosc":"astronomiques","lemma":"astronomique","pos":"ADJ"} ,
		{"word":"astucieuse","word_nosc":"astucieuse","lemma":"astucieux","pos":"ADJ"} ,
		{"word":"astucieuses","word_nosc":"astucieuses","lemma":"astucieux","pos":"ADJ"} ,
		{"word":"astucieux","word_nosc":"astucieux","lemma":"astucieux","pos":"ADJ"} ,
		{"word":"asymptomatique","word_nosc":"asymptomatique","lemma":"asymptomatique","pos":"ADJ"} ,
		{"word":"asymptomatiques","word_nosc":"asymptomatiques","lemma":"asymptomatique","pos":"ADJ"} ,
		{"word":"asymétrique","word_nosc":"asymetrique","lemma":"asymétrique","pos":"ADJ"} ,
		{"word":"asymétriques","word_nosc":"asymetriques","lemma":"asymétrique","pos":"ADJ"} ,
		{"word":"asynchrone","word_nosc":"asynchrone","lemma":"asynchrone","pos":"ADJ"} ,
		{"word":"atavique","word_nosc":"atavique","lemma":"atavique","pos":"ADJ"} ,
		{"word":"ataviques","word_nosc":"ataviques","lemma":"atavique","pos":"ADJ"} ,
		{"word":"athlétique","word_nosc":"athletique","lemma":"athlétique","pos":"ADJ"} ,
		{"word":"athlétiques","word_nosc":"athletiques","lemma":"athlétique","pos":"ADJ"} ,
		{"word":"athée","word_nosc":"athee","lemma":"athée","pos":"ADJ"} ,
		{"word":"athées","word_nosc":"athees","lemma":"athée","pos":"ADJ"} ,
		{"word":"athéiste","word_nosc":"atheiste","lemma":"athéiste","pos":"ADJ"} ,
		{"word":"athénien","word_nosc":"athenien","lemma":"athénien","pos":"ADJ"} ,
		{"word":"athénienne","word_nosc":"athenienne","lemma":"athénien","pos":"ADJ"} ,
		{"word":"athéniennes","word_nosc":"atheniennes","lemma":"athénien","pos":"ADJ"} ,
		{"word":"athéniens","word_nosc":"atheniens","lemma":"athénien","pos":"ADJ"} ,
		{"word":"atlantique","word_nosc":"atlantique","lemma":"atlantique","pos":"ADJ"} ,
		{"word":"atlantiques","word_nosc":"atlantiques","lemma":"atlantique","pos":"ADJ"} ,
		{"word":"atmosphérique","word_nosc":"atmospherique","lemma":"atmosphérique","pos":"ADJ"} ,
		{"word":"atmosphériques","word_nosc":"atmospheriques","lemma":"atmosphérique","pos":"ADJ"} ,
		{"word":"atomique","word_nosc":"atomique","lemma":"atomique","pos":"ADJ"} ,
		{"word":"atomiques","word_nosc":"atomiques","lemma":"atomique","pos":"ADJ"} ,
		{"word":"atomiste","word_nosc":"atomiste","lemma":"atomiste","pos":"ADJ"} ,
		{"word":"atomistes","word_nosc":"atomistes","lemma":"atomiste","pos":"ADJ"} ,
		{"word":"atomisé","word_nosc":"atomise","lemma":"atomisé","pos":"ADJ"} ,
		{"word":"atomisée","word_nosc":"atomisee","lemma":"atomisé","pos":"ADJ"} ,
		{"word":"atonal","word_nosc":"atonal","lemma":"atonal","pos":"ADJ"} ,
		{"word":"atonale","word_nosc":"atonale","lemma":"atonal","pos":"ADJ"} ,
		{"word":"atonales","word_nosc":"atonales","lemma":"atonal","pos":"ADJ"} ,
		{"word":"atone","word_nosc":"atone","lemma":"atone","pos":"ADJ"} ,
		{"word":"atones","word_nosc":"atones","lemma":"atone","pos":"ADJ"} ,
		{"word":"atrabilaire","word_nosc":"atrabilaire","lemma":"atrabilaire","pos":"ADJ"} ,
		{"word":"atroce","word_nosc":"atroce","lemma":"atroce","pos":"ADJ"} ,
		{"word":"atroces","word_nosc":"atroces","lemma":"atroce","pos":"ADJ"} ,
		{"word":"atrophié","word_nosc":"atrophie","lemma":"atrophié","pos":"ADJ"} ,
		{"word":"atrophiée","word_nosc":"atrophiee","lemma":"atrophié","pos":"ADJ"} ,
		{"word":"atrophiées","word_nosc":"atrophiees","lemma":"atrophié","pos":"ADJ"} ,
		{"word":"atrophiés","word_nosc":"atrophies","lemma":"atrophié","pos":"ADJ"} ,
		{"word":"attablé","word_nosc":"attable","lemma":"attablé","pos":"ADJ"} ,
		{"word":"attablée","word_nosc":"attablee","lemma":"attablé","pos":"ADJ"} ,
		{"word":"attablées","word_nosc":"attablees","lemma":"attablé","pos":"ADJ"} ,
		{"word":"attablés","word_nosc":"attables","lemma":"attablé","pos":"ADJ"} ,
		{"word":"attachant","word_nosc":"attachant","lemma":"attachant","pos":"ADJ"} ,
		{"word":"attachante","word_nosc":"attachante","lemma":"attachant","pos":"ADJ"} ,
		{"word":"attachants","word_nosc":"attachants","lemma":"attachant","pos":"ADJ"} ,
		{"word":"attaché","word_nosc":"attache","lemma":"attaché","pos":"ADJ"} ,
		{"word":"attachée","word_nosc":"attachee","lemma":"attaché","pos":"ADJ"} ,
		{"word":"attachées","word_nosc":"attachees","lemma":"attaché","pos":"ADJ"} ,
		{"word":"attachés","word_nosc":"attaches","lemma":"attaché","pos":"ADJ"} ,
		{"word":"attaquable","word_nosc":"attaquable","lemma":"attaquable","pos":"ADJ"} ,
		{"word":"attaquant","word_nosc":"attaquant","lemma":"attaquant","pos":"ADJ"} ,
		{"word":"attaquante","word_nosc":"attaquante","lemma":"attaquant","pos":"ADJ"} ,
		{"word":"attaquants","word_nosc":"attaquants","lemma":"attaquant","pos":"ADJ"} ,
		{"word":"attardé","word_nosc":"attarde","lemma":"attardé","pos":"ADJ"} ,
		{"word":"attardée","word_nosc":"attardee","lemma":"attardé","pos":"ADJ"} ,
		{"word":"attardées","word_nosc":"attardees","lemma":"attardé","pos":"ADJ"} ,
		{"word":"attardés","word_nosc":"attardes","lemma":"attardé","pos":"ADJ"} ,
		{"word":"atteignable","word_nosc":"atteignable","lemma":"atteignable","pos":"ADJ"} ,
		{"word":"atteint","word_nosc":"atteint","lemma":"atteint","pos":"ADJ"} ,
		{"word":"atteinte","word_nosc":"atteinte","lemma":"atteint","pos":"ADJ"} ,
		{"word":"atteintes","word_nosc":"atteintes","lemma":"atteint","pos":"ADJ"} ,
		{"word":"atteints","word_nosc":"atteints","lemma":"atteint","pos":"ADJ"} ,
		{"word":"attenant","word_nosc":"attenant","lemma":"attenant","pos":"ADJ"} ,
		{"word":"attenante","word_nosc":"attenante","lemma":"attenant","pos":"ADJ"} ,
		{"word":"attenantes","word_nosc":"attenantes","lemma":"attenant","pos":"ADJ"} ,
		{"word":"attendant","word_nosc":"attendant","lemma":"attendant","pos":"ADJ"} ,
		{"word":"attendrissant","word_nosc":"attendrissant","lemma":"attendrissant","pos":"ADJ"} ,
		{"word":"attendrissante","word_nosc":"attendrissante","lemma":"attendrissant","pos":"ADJ"} ,
		{"word":"attendrissantes","word_nosc":"attendrissantes","lemma":"attendrissant","pos":"ADJ"} ,
		{"word":"attendrissants","word_nosc":"attendrissants","lemma":"attendrissant","pos":"ADJ"} ,
		{"word":"attendu","word_nosc":"attendu","lemma":"attendu","pos":"ADJ"} ,
		{"word":"attendue","word_nosc":"attendue","lemma":"attendu","pos":"ADJ"} ,
		{"word":"attendues","word_nosc":"attendues","lemma":"attendu","pos":"ADJ"} ,
		{"word":"attendus","word_nosc":"attendus","lemma":"attendu","pos":"ADJ"} ,
		{"word":"attentatoire","word_nosc":"attentatoire","lemma":"attentatoire","pos":"ADJ"} ,
		{"word":"attentatoires","word_nosc":"attentatoires","lemma":"attentatoire","pos":"ADJ"} ,
		{"word":"attentif","word_nosc":"attentif","lemma":"attentif","pos":"ADJ"} ,
		{"word":"attentifs","word_nosc":"attentifs","lemma":"attentif","pos":"ADJ"} ,
		{"word":"attentionné","word_nosc":"attentionne","lemma":"attentionné","pos":"ADJ"} ,
		{"word":"attentionnée","word_nosc":"attentionnee","lemma":"attentionné","pos":"ADJ"} ,
		{"word":"attentionnées","word_nosc":"attentionnees","lemma":"attentionné","pos":"ADJ"} ,
		{"word":"attentionnés","word_nosc":"attentionnes","lemma":"attentionné","pos":"ADJ"} ,
		{"word":"attentiste","word_nosc":"attentiste","lemma":"attentiste","pos":"ADJ"} ,
		{"word":"attentive","word_nosc":"attentive","lemma":"attentif","pos":"ADJ"} ,
		{"word":"attentives","word_nosc":"attentives","lemma":"attentif","pos":"ADJ"} ,
		{"word":"atterrant","word_nosc":"atterrant","lemma":"atterrant","pos":"ADJ"} ,
		{"word":"atterrante","word_nosc":"atterrante","lemma":"atterrant","pos":"ADJ"} ,
		{"word":"atterré","word_nosc":"atterre","lemma":"atterré","pos":"ADJ"} ,
		{"word":"atterrée","word_nosc":"atterree","lemma":"atterré","pos":"ADJ"} ,
		{"word":"atterrés","word_nosc":"atterres","lemma":"atterré","pos":"ADJ"} ,
		{"word":"attesté","word_nosc":"atteste","lemma":"attesté","pos":"ADJ"} ,
		{"word":"attestée","word_nosc":"attestee","lemma":"attesté","pos":"ADJ"} ,
		{"word":"attique","word_nosc":"attique","lemma":"attique","pos":"ADJ"} ,
		{"word":"attiques","word_nosc":"attiques","lemma":"attique","pos":"ADJ"} ,
		{"word":"attirant","word_nosc":"attirant","lemma":"attirant","pos":"ADJ"} ,
		{"word":"attirante","word_nosc":"attirante","lemma":"attirant","pos":"ADJ"} ,
		{"word":"attirantes","word_nosc":"attirantes","lemma":"attirant","pos":"ADJ"} ,
		{"word":"attirants","word_nosc":"attirants","lemma":"attirant","pos":"ADJ"} ,
		{"word":"attitré","word_nosc":"attitre","lemma":"attitré","pos":"ADJ"} ,
		{"word":"attitrée","word_nosc":"attitree","lemma":"attitré","pos":"ADJ"} ,
		{"word":"attitrées","word_nosc":"attitrees","lemma":"attitré","pos":"ADJ"} ,
		{"word":"attitrés","word_nosc":"attitres","lemma":"attitré","pos":"ADJ"} ,
		{"word":"attracteur","word_nosc":"attracteur","lemma":"attracteur","pos":"ADJ"} ,
		{"word":"attracteurs","word_nosc":"attracteurs","lemma":"attracteur","pos":"ADJ"} ,
		{"word":"attractif","word_nosc":"attractif","lemma":"attractif","pos":"ADJ"} ,
		{"word":"attractifs","word_nosc":"attractifs","lemma":"attractif","pos":"ADJ"} ,
		{"word":"attractive","word_nosc":"attractive","lemma":"attractif","pos":"ADJ"} ,
		{"word":"attrayant","word_nosc":"attrayant","lemma":"attrayant","pos":"ADJ"} ,
		{"word":"attrayante","word_nosc":"attrayante","lemma":"attrayant","pos":"ADJ"} ,
		{"word":"attrayantes","word_nosc":"attrayantes","lemma":"attrayant","pos":"ADJ"} ,
		{"word":"attrayants","word_nosc":"attrayants","lemma":"attrayant","pos":"ADJ"} ,
		{"word":"attribuable","word_nosc":"attribuable","lemma":"attribuable","pos":"ADJ"} ,
		{"word":"attribué","word_nosc":"attribue","lemma":"attribué","pos":"ADJ"} ,
		{"word":"attribuée","word_nosc":"attribuee","lemma":"attribué","pos":"ADJ"} ,
		{"word":"attribuées","word_nosc":"attribuees","lemma":"attribué","pos":"ADJ"} ,
		{"word":"attribués","word_nosc":"attribues","lemma":"attribué","pos":"ADJ"} ,
		{"word":"attristant","word_nosc":"attristant","lemma":"attristant","pos":"ADJ"} ,
		{"word":"attristante","word_nosc":"attristante","lemma":"attristant","pos":"ADJ"} ,
		{"word":"attristantes","word_nosc":"attristantes","lemma":"attristant","pos":"ADJ"} ,
		{"word":"attristé","word_nosc":"attriste","lemma":"attristé","pos":"ADJ"} ,
		{"word":"attristée","word_nosc":"attristee","lemma":"attristé","pos":"ADJ"} ,
		{"word":"attristés","word_nosc":"attristes","lemma":"attristé","pos":"ADJ"} ,
		{"word":"atténuante","word_nosc":"attenuante","lemma":"atténuant","pos":"ADJ"} ,
		{"word":"atténuantes","word_nosc":"attenuantes","lemma":"atténuant","pos":"ADJ"} ,
		{"word":"atténué","word_nosc":"attenue","lemma":"atténué","pos":"ADJ"} ,
		{"word":"atténuée","word_nosc":"attenuee","lemma":"atténué","pos":"ADJ"} ,
		{"word":"atténuées","word_nosc":"attenuees","lemma":"atténué","pos":"ADJ"} ,
		{"word":"atténués","word_nosc":"attenues","lemma":"atténué","pos":"ADJ"} ,
		{"word":"atypique","word_nosc":"atypique","lemma":"atypique","pos":"ADJ"} ,
		{"word":"atypiques","word_nosc":"atypiques","lemma":"atypique","pos":"ADJ"} ,
		{"word":"aubergine","word_nosc":"aubergine","lemma":"aubergine","pos":"ADJ"} ,
		{"word":"auburn","word_nosc":"auburn","lemma":"auburn","pos":"ADJ"} ,
		{"word":"aubère","word_nosc":"aubere","lemma":"aubère","pos":"ADJ"} ,
		{"word":"aucun","word_nosc":"aucun","lemma":"aucun","pos":"ADJ:ind"} ,
		{"word":"aucune","word_nosc":"aucune","lemma":"aucune","pos":"ADJ:ind"} ,
		{"word":"aucunes","word_nosc":"aucunes","lemma":"aucunes","pos":"ADJ:ind"} ,
		{"word":"aucuns","word_nosc":"aucuns","lemma":"aucuns","pos":"ADJ:ind"} ,
		{"word":"audacieuse","word_nosc":"audacieuse","lemma":"audacieux","pos":"ADJ"} ,
		{"word":"audacieuses","word_nosc":"audacieuses","lemma":"audacieux","pos":"ADJ"} ,
		{"word":"audacieux","word_nosc":"audacieux","lemma":"audacieux","pos":"ADJ"} ,
		{"word":"audible","word_nosc":"audible","lemma":"audible","pos":"ADJ"} ,
		{"word":"audibles","word_nosc":"audibles","lemma":"audible","pos":"ADJ"} ,
		{"word":"audienciers","word_nosc":"audienciers","lemma":"audiencier","pos":"ADJ"} ,
		{"word":"audio","word_nosc":"audio","lemma":"audio","pos":"ADJ"} ,
		{"word":"audio-visuel","word_nosc":"audio-visuel","lemma":"audio-visuel","pos":"ADJ"} ,
		{"word":"audio-visuelle","word_nosc":"audio-visuelle","lemma":"audiovisuel","pos":"ADJ"} ,
		{"word":"audiovisuel","word_nosc":"audiovisuel","lemma":"audiovisuel","pos":"ADJ"} ,
		{"word":"audiovisuelle","word_nosc":"audiovisuelle","lemma":"audiovisuel","pos":"ADJ"} ,
		{"word":"audiovisuelles","word_nosc":"audiovisuelles","lemma":"audiovisuel","pos":"ADJ"} ,
		{"word":"auditif","word_nosc":"auditif","lemma":"auditif","pos":"ADJ"} ,
		{"word":"auditifs","word_nosc":"auditifs","lemma":"auditif","pos":"ADJ"} ,
		{"word":"auditive","word_nosc":"auditive","lemma":"auditif","pos":"ADJ"} ,
		{"word":"auditives","word_nosc":"auditives","lemma":"auditif","pos":"ADJ"} ,
		{"word":"augural","word_nosc":"augural","lemma":"augural","pos":"ADJ"} ,
		{"word":"augurale","word_nosc":"augurale","lemma":"augural","pos":"ADJ"} ,
		{"word":"auguste","word_nosc":"auguste","lemma":"auguste","pos":"ADJ"} ,
		{"word":"augustes","word_nosc":"augustes","lemma":"auguste","pos":"ADJ"} ,
		{"word":"augustinien","word_nosc":"augustinien","lemma":"augustinien","pos":"ADJ"} ,
		{"word":"aulique","word_nosc":"aulique","lemma":"aulique","pos":"ADJ"} ,
		{"word":"auriculaire","word_nosc":"auriculaire","lemma":"auriculaire","pos":"ADJ"} ,
		{"word":"aurifère","word_nosc":"aurifere","lemma":"aurifère","pos":"ADJ"} ,
		{"word":"aurifères","word_nosc":"auriferes","lemma":"aurifère","pos":"ADJ"} ,
		{"word":"auroral","word_nosc":"auroral","lemma":"auroral","pos":"ADJ"} ,
		{"word":"auréolaire","word_nosc":"aureolaire","lemma":"auréolaire","pos":"ADJ"} ,
		{"word":"auréolé","word_nosc":"aureole","lemma":"auréolé","pos":"ADJ"} ,
		{"word":"auréolée","word_nosc":"aureolee","lemma":"auréolé","pos":"ADJ"} ,
		{"word":"auréolées","word_nosc":"aureolees","lemma":"auréolé","pos":"ADJ"} ,
		{"word":"auréolés","word_nosc":"aureoles","lemma":"auréolé","pos":"ADJ"} ,
		{"word":"austral","word_nosc":"austral","lemma":"austral","pos":"ADJ"} ,
		{"word":"australe","word_nosc":"australe","lemma":"austral","pos":"ADJ"} ,
		{"word":"australes","word_nosc":"australes","lemma":"austral","pos":"ADJ"} ,
		{"word":"australien","word_nosc":"australien","lemma":"australien","pos":"ADJ"} ,
		{"word":"australienne","word_nosc":"australienne","lemma":"australien","pos":"ADJ"} ,
		{"word":"australiennes","word_nosc":"australiennes","lemma":"australien","pos":"ADJ"} ,
		{"word":"australiens","word_nosc":"australiens","lemma":"australien","pos":"ADJ"} ,
		{"word":"austro-hongrois","word_nosc":"austro-hongrois","lemma":"austro-hongrois","pos":"ADJ"} ,
		{"word":"austro-hongroises","word_nosc":"austro-hongroises","lemma":"austro-hongrois","pos":"ADJ"} ,
		{"word":"austère","word_nosc":"austere","lemma":"austère","pos":"ADJ"} ,
		{"word":"austères","word_nosc":"austeres","lemma":"austère","pos":"ADJ"} ,
		{"word":"autarcique","word_nosc":"autarcique","lemma":"autarcique","pos":"ADJ"} ,
		{"word":"autarciques","word_nosc":"autarciques","lemma":"autarcique","pos":"ADJ"} ,
		{"word":"authentique","word_nosc":"authentique","lemma":"authentique","pos":"ADJ"} ,
		{"word":"authentiques","word_nosc":"authentiques","lemma":"authentique","pos":"ADJ"} ,
		{"word":"autiste","word_nosc":"autiste","lemma":"autiste","pos":"ADJ"} ,
		{"word":"autistes","word_nosc":"autistes","lemma":"autiste","pos":"ADJ"} ,
		{"word":"autistique","word_nosc":"autistique","lemma":"autistique","pos":"ADJ"} ,
		{"word":"auto-adhésive","word_nosc":"auto-adhesive","lemma":"auto-adhésif","pos":"ADJ"} ,
		{"word":"auto-immun","word_nosc":"auto-immun","lemma":"auto-immun","pos":"ADJ"} ,
		{"word":"auto-immune","word_nosc":"auto-immune","lemma":"auto-immun","pos":"ADJ"} ,
		{"word":"autobiographique","word_nosc":"autobiographique","lemma":"autobiographique","pos":"ADJ"} ,
		{"word":"autobiographiques","word_nosc":"autobiographiques","lemma":"autobiographique","pos":"ADJ"} ,
		{"word":"autochtone","word_nosc":"autochtone","lemma":"autochtone","pos":"ADJ"} ,
		{"word":"autochtones","word_nosc":"autochtones","lemma":"autochtone","pos":"ADJ"} ,
		{"word":"autocollant","word_nosc":"autocollant","lemma":"autocollant","pos":"ADJ"} ,
		{"word":"autocollante","word_nosc":"autocollante","lemma":"autocollant","pos":"ADJ"} ,
		{"word":"autocollantes","word_nosc":"autocollantes","lemma":"autocollant","pos":"ADJ"} ,
		{"word":"autocollants","word_nosc":"autocollants","lemma":"autocollant","pos":"ADJ"} ,
		{"word":"autocrate","word_nosc":"autocrate","lemma":"autocrate","pos":"ADJ"} ,
		{"word":"autocratique","word_nosc":"autocratique","lemma":"autocratique","pos":"ADJ"} ,
		{"word":"autocratiques","word_nosc":"autocratiques","lemma":"autocratique","pos":"ADJ"} ,
		{"word":"autodestructeur","word_nosc":"autodestructeur","lemma":"autodestructeur","pos":"ADJ"} ,
		{"word":"autodestructeurs","word_nosc":"autodestructeurs","lemma":"autodestructeur","pos":"ADJ"} ,
		{"word":"autodestructible","word_nosc":"autodestructible","lemma":"autodestructible","pos":"ADJ"} ,
		{"word":"autodestructrice","word_nosc":"autodestructrice","lemma":"autodestructeur","pos":"ADJ"} ,
		{"word":"autodidacte","word_nosc":"autodidacte","lemma":"autodidacte","pos":"ADJ"} ,
		{"word":"autodidactes","word_nosc":"autodidactes","lemma":"autodidacte","pos":"ADJ"} ,
		{"word":"autodidactique","word_nosc":"autodidactique","lemma":"autodidactique","pos":"ADJ"} ,
		{"word":"autodirecteur","word_nosc":"autodirecteur","lemma":"autodirecteur","pos":"ADJ"} ,
		{"word":"autofocus","word_nosc":"autofocus","lemma":"autofocus","pos":"ADJ"} ,
		{"word":"autographe","word_nosc":"autographe","lemma":"autographe","pos":"ADJ"} ,
		{"word":"autographes","word_nosc":"autographes","lemma":"autographe","pos":"ADJ"} ,
		{"word":"autoguidé","word_nosc":"autoguide","lemma":"autoguidé","pos":"ADJ"} ,
		{"word":"autogène","word_nosc":"autogene","lemma":"autogène","pos":"ADJ"} ,
		{"word":"autogérée","word_nosc":"autogeree","lemma":"autogéré","pos":"ADJ"} ,
		{"word":"autologues","word_nosc":"autologues","lemma":"autologue","pos":"ADJ"} ,
		{"word":"automatique","word_nosc":"automatique","lemma":"automatique","pos":"ADJ"} ,
		{"word":"automatiques","word_nosc":"automatiques","lemma":"automatique","pos":"ADJ"} ,
		{"word":"automatisé","word_nosc":"automatise","lemma":"automatisé","pos":"ADJ"} ,
		{"word":"automatisée","word_nosc":"automatisee","lemma":"automatisé","pos":"ADJ"} ,
		{"word":"automatisées","word_nosc":"automatisees","lemma":"automatisé","pos":"ADJ"} ,
		{"word":"automatisés","word_nosc":"automatises","lemma":"automatisé","pos":"ADJ"} ,
		{"word":"automnal","word_nosc":"automnal","lemma":"automnal","pos":"ADJ"} ,
		{"word":"automnale","word_nosc":"automnale","lemma":"automnal","pos":"ADJ"} ,
		{"word":"automnales","word_nosc":"automnales","lemma":"automnal","pos":"ADJ"} ,
		{"word":"automobile","word_nosc":"automobile","lemma":"automobile","pos":"ADJ"} ,
		{"word":"automobiles","word_nosc":"automobiles","lemma":"automobile","pos":"ADJ"} ,
		{"word":"automoteur","word_nosc":"automoteur","lemma":"automoteur","pos":"ADJ"} ,
		{"word":"automoteurs","word_nosc":"automoteurs","lemma":"automoteur","pos":"ADJ"} ,
		{"word":"automotrice","word_nosc":"automotrice","lemma":"automoteur","pos":"ADJ"} ,
		{"word":"autonettoyant","word_nosc":"autonettoyant","lemma":"autonettoyant","pos":"ADJ"} ,
		{"word":"autonettoyante","word_nosc":"autonettoyante","lemma":"autonettoyant","pos":"ADJ"} ,
		{"word":"autonome","word_nosc":"autonome","lemma":"autonome","pos":"ADJ"} ,
		{"word":"autonomes","word_nosc":"autonomes","lemma":"autonome","pos":"ADJ"} ,
		{"word":"autonomique","word_nosc":"autonomique","lemma":"autonomique","pos":"ADJ"} ,
		{"word":"autonomistes","word_nosc":"autonomistes","lemma":"autonomiste","pos":"ADJ"} ,
		{"word":"autoportée","word_nosc":"autoportee","lemma":"autoporté","pos":"ADJ"} ,
		{"word":"autopropulsé","word_nosc":"autopropulse","lemma":"autopropulsé","pos":"ADJ"} ,
		{"word":"autopropulsée","word_nosc":"autopropulsee","lemma":"autopropulsé","pos":"ADJ"} ,
		{"word":"autopropulsés","word_nosc":"autopropulses","lemma":"autopropulsé","pos":"ADJ"} ,
		{"word":"autorisé","word_nosc":"autorise","lemma":"autorisé","pos":"ADJ"} ,
		{"word":"autorisée","word_nosc":"autorisee","lemma":"autorisé","pos":"ADJ"} ,
		{"word":"autorisées","word_nosc":"autorisees","lemma":"autorisé","pos":"ADJ"} ,
		{"word":"autorisés","word_nosc":"autorises","lemma":"autorisé","pos":"ADJ"} ,
		{"word":"autoritaire","word_nosc":"autoritaire","lemma":"autoritaire","pos":"ADJ"} ,
		{"word":"autoritaires","word_nosc":"autoritaires","lemma":"autoritaire","pos":"ADJ"} ,
		{"word":"autoroutiers","word_nosc":"autoroutiers","lemma":"autoroutier","pos":"ADJ"} ,
		{"word":"autoroutière","word_nosc":"autoroutiere","lemma":"autoroutier","pos":"ADJ"} ,
		{"word":"autoroutière","word_nosc":"autoroutiere","lemma":"autoroutière","pos":"ADJ"} ,
		{"word":"autorégulateur","word_nosc":"autoregulateur","lemma":"autorégulateur","pos":"ADJ"} ,
		{"word":"autosuffisant","word_nosc":"autosuffisant","lemma":"autosuffisant","pos":"ADJ"} ,
		{"word":"autosuffisants","word_nosc":"autosuffisants","lemma":"autosuffisant","pos":"ADJ"} ,
		{"word":"autoérotique","word_nosc":"autoerotique","lemma":"autoérotique","pos":"ADJ"} ,
		{"word":"autre","word_nosc":"autre","lemma":"autre","pos":"ADJ"} ,
		{"word":"autres","word_nosc":"autres","lemma":"autre","pos":"ADJ"} ,
		{"word":"autres","word_nosc":"autres","lemma":"autres","pos":"ADJ:num"} ,
		{"word":"autrichien","word_nosc":"autrichien","lemma":"autrichien","pos":"ADJ"} ,
		{"word":"autrichienne","word_nosc":"autrichienne","lemma":"autrichien","pos":"ADJ"} ,
		{"word":"autrichiennes","word_nosc":"autrichiennes","lemma":"autrichien","pos":"ADJ"} ,
		{"word":"autrichiens","word_nosc":"autrichiens","lemma":"autrichien","pos":"ADJ"} ,
		{"word":"auvergnat","word_nosc":"auvergnat","lemma":"auvergnat","pos":"ADJ"} ,
		{"word":"auvergnate","word_nosc":"auvergnate","lemma":"auvergnat","pos":"ADJ"} ,
		{"word":"auvergnates","word_nosc":"auvergnates","lemma":"auvergnat","pos":"ADJ"} ,
		{"word":"auvergnats","word_nosc":"auvergnats","lemma":"auvergnat","pos":"ADJ"} ,
		{"word":"auxiliaire","word_nosc":"auxiliaire","lemma":"auxiliaire","pos":"ADJ"} ,
		{"word":"auxiliaires","word_nosc":"auxiliaires","lemma":"auxiliaire","pos":"ADJ"} ,
		{"word":"avachi","word_nosc":"avachi","lemma":"avachi","pos":"ADJ"} ,
		{"word":"avachie","word_nosc":"avachie","lemma":"avachi","pos":"ADJ"} ,
		{"word":"avachies","word_nosc":"avachies","lemma":"avachi","pos":"ADJ"} ,
		{"word":"avachis","word_nosc":"avachis","lemma":"avachi","pos":"ADJ"} ,
		{"word":"avalant","word_nosc":"avalant","lemma":"avalant","pos":"ADJ"} ,
		{"word":"avancé","word_nosc":"avance","lemma":"avancé","pos":"ADJ"} ,
		{"word":"avancée","word_nosc":"avancee","lemma":"avancé","pos":"ADJ"} ,
		{"word":"avancées","word_nosc":"avancees","lemma":"avancé","pos":"ADJ"} ,
		{"word":"avancés","word_nosc":"avances","lemma":"avancé","pos":"ADJ"} ,
		{"word":"avant","word_nosc":"avant","lemma":"avant","pos":"ADJ"} ,
		{"word":"avant-coureur","word_nosc":"avant-coureur","lemma":"avant-coureur","pos":"ADJ"} ,
		{"word":"avant-coureurs","word_nosc":"avant-coureurs","lemma":"avant-coureur","pos":"ADJ"} ,
		{"word":"avant-courrière","word_nosc":"avant-courriere","lemma":"avant-courrier","pos":"ADJ"} ,
		{"word":"avant-dernier","word_nosc":"avant-dernier","lemma":"avant-dernier","pos":"ADJ"} ,
		{"word":"avant-dernière","word_nosc":"avant-derniere","lemma":"avant-dernier","pos":"ADJ"} ,
		{"word":"avant-gardiste","word_nosc":"avant-gardiste","lemma":"avant-gardiste","pos":"ADJ"} ,
		{"word":"avant-gardistes","word_nosc":"avant-gardistes","lemma":"avant-gardiste","pos":"ADJ"} ,
		{"word":"avant-gauche","word_nosc":"avant-gauche","lemma":"avant-gauche","pos":"ADJ"} ,
		{"word":"avant-première","word_nosc":"avant-premiere","lemma":"avant-première","pos":"ADJ"} ,
		{"word":"avantageuse","word_nosc":"avantageuse","lemma":"avantageux","pos":"ADJ"} ,
		{"word":"avantageuses","word_nosc":"avantageuses","lemma":"avantageux","pos":"ADJ"} ,
		{"word":"avantageux","word_nosc":"avantageux","lemma":"avantageux","pos":"ADJ"} ,
		{"word":"avare","word_nosc":"avare","lemma":"avare","pos":"ADJ"} ,
		{"word":"avares","word_nosc":"avares","lemma":"avare","pos":"ADJ"} ,
		{"word":"avaricieuse","word_nosc":"avaricieuse","lemma":"avaricieux","pos":"ADJ"} ,
		{"word":"avaricieux","word_nosc":"avaricieux","lemma":"avaricieux","pos":"ADJ"} ,
		{"word":"avarié","word_nosc":"avarie","lemma":"avarié","pos":"ADJ"} ,
		{"word":"avariée","word_nosc":"avariee","lemma":"avarié","pos":"ADJ"} ,
		{"word":"avariées","word_nosc":"avariees","lemma":"avarié","pos":"ADJ"} ,
		{"word":"avariés","word_nosc":"avaries","lemma":"avarié","pos":"ADJ"} ,
		{"word":"avenant","word_nosc":"avenant","lemma":"avenant","pos":"ADJ"} ,
		{"word":"avenante","word_nosc":"avenante","lemma":"avenant","pos":"ADJ"} ,
		{"word":"avenantes","word_nosc":"avenantes","lemma":"avenant","pos":"ADJ"} ,
		{"word":"avenants","word_nosc":"avenants","lemma":"avenant","pos":"ADJ"} ,
		{"word":"aventureuse","word_nosc":"aventureuse","lemma":"aventureux","pos":"ADJ"} ,
		{"word":"aventureuses","word_nosc":"aventureuses","lemma":"aventureux","pos":"ADJ"} ,
		{"word":"aventureux","word_nosc":"aventureux","lemma":"aventureux","pos":"ADJ"} ,
		{"word":"aventurier","word_nosc":"aventurier","lemma":"aventurier","pos":"ADJ"} ,
		{"word":"aventuriers","word_nosc":"aventuriers","lemma":"aventurier","pos":"ADJ"} ,
		{"word":"aventurière","word_nosc":"aventuriere","lemma":"aventurier","pos":"ADJ"} ,
		{"word":"aventuré","word_nosc":"aventure","lemma":"aventuré","pos":"ADJ"} ,
		{"word":"aventurée","word_nosc":"aventuree","lemma":"aventuré","pos":"ADJ"} ,
		{"word":"aventurées","word_nosc":"aventurees","lemma":"aventuré","pos":"ADJ"} ,
		{"word":"aventurés","word_nosc":"aventures","lemma":"aventuré","pos":"ADJ"} ,
		{"word":"avenu","word_nosc":"avenu","lemma":"avenu","pos":"ADJ"} ,
		{"word":"avenue","word_nosc":"avenue","lemma":"avenue","pos":"ADJ"} ,
		{"word":"avenues","word_nosc":"avenues","lemma":"avenu","pos":"ADJ"} ,
		{"word":"avenus","word_nosc":"avenus","lemma":"avenu","pos":"ADJ"} ,
		{"word":"averti","word_nosc":"averti","lemma":"averti","pos":"ADJ"} ,
		{"word":"avertie","word_nosc":"avertie","lemma":"averti","pos":"ADJ"} ,
		{"word":"averties","word_nosc":"averties","lemma":"averti","pos":"ADJ"} ,
		{"word":"avertis","word_nosc":"avertis","lemma":"averti","pos":"ADJ"} ,
		{"word":"avertisseur","word_nosc":"avertisseur","lemma":"avertisseur","pos":"ADJ"} ,
		{"word":"avertisseurs","word_nosc":"avertisseurs","lemma":"avertisseur","pos":"ADJ"} ,
		{"word":"avertisseuses","word_nosc":"avertisseuses","lemma":"avertisseur","pos":"ADJ"} ,
		{"word":"aveuglant","word_nosc":"aveuglant","lemma":"aveuglant","pos":"ADJ"} ,
		{"word":"aveuglante","word_nosc":"aveuglante","lemma":"aveuglant","pos":"ADJ"} ,
		{"word":"aveuglantes","word_nosc":"aveuglantes","lemma":"aveuglant","pos":"ADJ"} ,
		{"word":"aveuglants","word_nosc":"aveuglants","lemma":"aveuglant","pos":"ADJ"} ,
		{"word":"aveugle","word_nosc":"aveugle","lemma":"aveugle","pos":"ADJ"} ,
		{"word":"aveugles","word_nosc":"aveugles","lemma":"aveugle","pos":"ADJ"} ,
		{"word":"aveulissante","word_nosc":"aveulissante","lemma":"aveulissant","pos":"ADJ"} ,
		{"word":"aviaire","word_nosc":"aviaire","lemma":"aviaire","pos":"ADJ"} ,
		{"word":"avicole","word_nosc":"avicole","lemma":"avicole","pos":"ADJ"} ,
		{"word":"avide","word_nosc":"avide","lemma":"avide","pos":"ADJ"} ,
		{"word":"avides","word_nosc":"avides","lemma":"avide","pos":"ADJ"} ,
		{"word":"avignonnaise","word_nosc":"avignonnaise","lemma":"avignonnais","pos":"ADJ"} ,
		{"word":"avilissant","word_nosc":"avilissant","lemma":"avilissant","pos":"ADJ"} ,
		{"word":"avilissante","word_nosc":"avilissante","lemma":"avilissant","pos":"ADJ"} ,
		{"word":"avilissantes","word_nosc":"avilissantes","lemma":"avilissant","pos":"ADJ"} ,
		{"word":"avilissants","word_nosc":"avilissants","lemma":"avilissant","pos":"ADJ"} ,
		{"word":"aviné","word_nosc":"avine","lemma":"aviné","pos":"ADJ"} ,
		{"word":"avinée","word_nosc":"avinee","lemma":"aviné","pos":"ADJ"} ,
		{"word":"avinées","word_nosc":"avinees","lemma":"aviné","pos":"ADJ"} ,
		{"word":"avinés","word_nosc":"avines","lemma":"aviné","pos":"ADJ"} ,
		{"word":"avisé","word_nosc":"avise","lemma":"avisé","pos":"ADJ"} ,
		{"word":"avisée","word_nosc":"avisee","lemma":"avisé","pos":"ADJ"} ,
		{"word":"avisées","word_nosc":"avisees","lemma":"avisé","pos":"ADJ"} ,
		{"word":"avisés","word_nosc":"avises","lemma":"avisé","pos":"ADJ"} ,
		{"word":"avivé","word_nosc":"avive","lemma":"avivé","pos":"ADJ"} ,
		{"word":"avoisinant","word_nosc":"avoisinant","lemma":"avoisinant","pos":"ADJ"} ,
		{"word":"avoisinante","word_nosc":"avoisinante","lemma":"avoisinant","pos":"ADJ"} ,
		{"word":"avoisinantes","word_nosc":"avoisinantes","lemma":"avoisinant","pos":"ADJ"} ,
		{"word":"avoisinants","word_nosc":"avoisinants","lemma":"avoisinant","pos":"ADJ"} ,
		{"word":"avorté","word_nosc":"avorte","lemma":"avorté","pos":"ADJ"} ,
		{"word":"avortée","word_nosc":"avortee","lemma":"avorté","pos":"ADJ"} ,
		{"word":"avortées","word_nosc":"avortees","lemma":"avorté","pos":"ADJ"} ,
		{"word":"avortés","word_nosc":"avortes","lemma":"avorté","pos":"ADJ"} ,
		{"word":"avouable","word_nosc":"avouable","lemma":"avouable","pos":"ADJ"} ,
		{"word":"avouables","word_nosc":"avouables","lemma":"avouable","pos":"ADJ"} ,
		{"word":"avoué","word_nosc":"avoue","lemma":"avoué","pos":"ADJ"} ,
		{"word":"avouée","word_nosc":"avouee","lemma":"avoué","pos":"ADJ"} ,
		{"word":"avouées","word_nosc":"avouees","lemma":"avoué","pos":"ADJ"} ,
		{"word":"avoués","word_nosc":"avoues","lemma":"avoué","pos":"ADJ"} ,
		{"word":"avunculaire","word_nosc":"avunculaire","lemma":"avunculaire","pos":"ADJ"} ,
		{"word":"avéré","word_nosc":"avere","lemma":"avéré","pos":"ADJ"} ,
		{"word":"avérée","word_nosc":"averee","lemma":"avéré","pos":"ADJ"} ,
		{"word":"avérées","word_nosc":"averees","lemma":"avéré","pos":"ADJ"} ,
		{"word":"avérés","word_nosc":"averes","lemma":"avéré","pos":"ADJ"} ,
		{"word":"axial","word_nosc":"axial","lemma":"axial","pos":"ADJ"} ,
		{"word":"axiale","word_nosc":"axiale","lemma":"axial","pos":"ADJ"} ,
		{"word":"axillaire","word_nosc":"axillaire","lemma":"axillaire","pos":"ADJ"} ,
		{"word":"azerbaïdjanais","word_nosc":"azerbaidjanais","lemma":"azerbaïdjanais","pos":"ADJ"} ,
		{"word":"azimutal","word_nosc":"azimutal","lemma":"azimutal","pos":"ADJ"} ,
		{"word":"azimuté","word_nosc":"azimute","lemma":"azimuté","pos":"ADJ"} ,
		{"word":"azoïque","word_nosc":"azoique","lemma":"azoïque","pos":"ADJ"} ,
		{"word":"aztèque","word_nosc":"azteque","lemma":"aztèque","pos":"ADJ"} ,
		{"word":"aztèques","word_nosc":"azteques","lemma":"aztèque","pos":"ADJ"} ,
		{"word":"azur","word_nosc":"azur","lemma":"azur","pos":"ADJ"} ,
		{"word":"azuré","word_nosc":"azure","lemma":"azuré","pos":"ADJ"} ,
		{"word":"azurée","word_nosc":"azuree","lemma":"azuré","pos":"ADJ"} ,
		{"word":"azurées","word_nosc":"azurees","lemma":"azuré","pos":"ADJ"} ,
		{"word":"azurés","word_nosc":"azures","lemma":"azuré","pos":"ADJ"} ,
		{"word":"azygos","word_nosc":"azygos","lemma":"azygos","pos":"ADJ"} ,
		{"word":"azyme","word_nosc":"azyme","lemma":"azyme","pos":"ADJ"} ,
		{"word":"azéri","word_nosc":"azeri","lemma":"azéri","pos":"ADJ"} ,
		{"word":"azéris","word_nosc":"azeris","lemma":"azéri","pos":"ADJ"} ,
		{"word":"aérien","word_nosc":"aerien","lemma":"aérien","pos":"ADJ"} ,
		{"word":"aérienne","word_nosc":"aerienne","lemma":"aérien","pos":"ADJ"} ,
		{"word":"aériennes","word_nosc":"aeriennes","lemma":"aérien","pos":"ADJ"} ,
		{"word":"aériens","word_nosc":"aeriens","lemma":"aérien","pos":"ADJ"} ,
		{"word":"aérodynamique","word_nosc":"aerodynamique","lemma":"aérodynamique","pos":"ADJ"} ,
		{"word":"aérodynamiques","word_nosc":"aerodynamiques","lemma":"aérodynamique","pos":"ADJ"} ,
		{"word":"aéromobile","word_nosc":"aeromobile","lemma":"aéromobile","pos":"ADJ"} ,
		{"word":"aéronautique","word_nosc":"aeronautique","lemma":"aéronautique","pos":"ADJ"} ,
		{"word":"aéronautiques","word_nosc":"aeronautiques","lemma":"aéronautique","pos":"ADJ"} ,
		{"word":"aéronaval","word_nosc":"aeronaval","lemma":"aéronaval","pos":"ADJ"} ,
		{"word":"aéronavale","word_nosc":"aeronavale","lemma":"aéronaval","pos":"ADJ"} ,
		{"word":"aéronavales","word_nosc":"aeronavales","lemma":"aéronaval","pos":"ADJ"} ,
		{"word":"aéronavals","word_nosc":"aeronavals","lemma":"aéronaval","pos":"ADJ"} ,
		{"word":"aéroporté","word_nosc":"aeroporte","lemma":"aéroporté","pos":"ADJ"} ,
		{"word":"aéroportée","word_nosc":"aeroportee","lemma":"aéroporté","pos":"ADJ"} ,
		{"word":"aéroportées","word_nosc":"aeroportees","lemma":"aéroporté","pos":"ADJ"} ,
		{"word":"aéroportés","word_nosc":"aeroportes","lemma":"aéroporté","pos":"ADJ"} ,
		{"word":"aérospatial","word_nosc":"aerospatial","lemma":"aérospatial","pos":"ADJ"} ,
		{"word":"aérospatiale","word_nosc":"aerospatiale","lemma":"aérospatial","pos":"ADJ"} ,
		{"word":"aéré","word_nosc":"aere","lemma":"aéré","pos":"ADJ"} ,
		{"word":"aérée","word_nosc":"aeree","lemma":"aéré","pos":"ADJ"} ,
		{"word":"aérées","word_nosc":"aerees","lemma":"aéré","pos":"ADJ"} ,
		{"word":"aérés","word_nosc":"aeres","lemma":"aéré","pos":"ADJ"} ,
		{"word":"aîné","word_nosc":"aine","lemma":"aîné","pos":"ADJ"} ,
		{"word":"aînée","word_nosc":"ainee","lemma":"aîné","pos":"ADJ"} ,
		{"word":"aînées","word_nosc":"ainees","lemma":"aîné","pos":"ADJ"} ,
		{"word":"aînés","word_nosc":"aines","lemma":"aîné","pos":"ADJ"} ,
		{"word":"baba","word_nosc":"baba","lemma":"baba","pos":"ADJ"} ,
		{"word":"babillard","word_nosc":"babillard","lemma":"babillard","pos":"ADJ"} ,
		{"word":"babillarde","word_nosc":"babillarde","lemma":"babillard","pos":"ADJ"} ,
		{"word":"babylonien","word_nosc":"babylonien","lemma":"babylonien","pos":"ADJ"} ,
		{"word":"babylonienne","word_nosc":"babylonienne","lemma":"babylonien","pos":"ADJ"} ,
		{"word":"babyloniens","word_nosc":"babyloniens","lemma":"babylonien","pos":"ADJ"} ,
		{"word":"babélienne","word_nosc":"babelienne","lemma":"babélien","pos":"ADJ"} ,
		{"word":"bachique","word_nosc":"bachique","lemma":"bachique","pos":"ADJ"} ,
		{"word":"bachiques","word_nosc":"bachiques","lemma":"bachique","pos":"ADJ"} ,
		{"word":"bacillaires","word_nosc":"bacillaires","lemma":"bacillaire","pos":"ADJ"} ,
		{"word":"bactéricide","word_nosc":"bactericide","lemma":"bactéricide","pos":"ADJ"} ,
		{"word":"bactéricides","word_nosc":"bactericides","lemma":"bactéricide","pos":"ADJ"} ,
		{"word":"bactérien","word_nosc":"bacterien","lemma":"bactérien","pos":"ADJ"} ,
		{"word":"bactérienne","word_nosc":"bacterienne","lemma":"bactérien","pos":"ADJ"} ,
		{"word":"bactériennes","word_nosc":"bacteriennes","lemma":"bactérien","pos":"ADJ"} ,
		{"word":"bactériologique","word_nosc":"bacteriologique","lemma":"bactériologique","pos":"ADJ"} ,
		{"word":"bactériologiques","word_nosc":"bacteriologiques","lemma":"bactériologique","pos":"ADJ"} ,
		{"word":"badaud","word_nosc":"badaud","lemma":"badaud","pos":"ADJ"} ,
		{"word":"badauds","word_nosc":"badauds","lemma":"badaud","pos":"ADJ"} ,
		{"word":"badgés","word_nosc":"badges","lemma":"badgé","pos":"ADJ"} ,
		{"word":"badin","word_nosc":"badin","lemma":"badin","pos":"ADJ"} ,
		{"word":"badine","word_nosc":"badine","lemma":"badin","pos":"ADJ"} ,
		{"word":"badins","word_nosc":"badins","lemma":"badin","pos":"ADJ"} ,
		{"word":"badois","word_nosc":"badois","lemma":"badois","pos":"ADJ"} ,
		{"word":"badoise","word_nosc":"badoise","lemma":"badois","pos":"ADJ"} ,
		{"word":"bafouillant","word_nosc":"bafouillant","lemma":"bafouillant","pos":"ADJ"} ,
		{"word":"bafouillante","word_nosc":"bafouillante","lemma":"bafouillant","pos":"ADJ"} ,
		{"word":"bafouillantes","word_nosc":"bafouillantes","lemma":"bafouillant","pos":"ADJ"} ,
		{"word":"bafoué","word_nosc":"bafoue","lemma":"bafoué","pos":"ADJ"} ,
		{"word":"bafouée","word_nosc":"bafouee","lemma":"bafoué","pos":"ADJ"} ,
		{"word":"bafouées","word_nosc":"bafouees","lemma":"bafoué","pos":"ADJ"} ,
		{"word":"bafoués","word_nosc":"bafoues","lemma":"bafoué","pos":"ADJ"} ,
		{"word":"bagarreur","word_nosc":"bagarreur","lemma":"bagarreur","pos":"ADJ"} ,
		{"word":"bagarreurs","word_nosc":"bagarreurs","lemma":"bagarreur","pos":"ADJ"} ,
		{"word":"bagarreuse","word_nosc":"bagarreuse","lemma":"bagarreur","pos":"ADJ"} ,
		{"word":"bagarreuses","word_nosc":"bagarreuses","lemma":"bagarreur","pos":"ADJ"} ,
		{"word":"bagousées","word_nosc":"bagousees","lemma":"bagousé","pos":"ADJ"} ,
		{"word":"bagué","word_nosc":"bague","lemma":"bagué","pos":"ADJ"} ,
		{"word":"baguée","word_nosc":"baguee","lemma":"bagué","pos":"ADJ"} ,
		{"word":"baguées","word_nosc":"baguees","lemma":"bagué","pos":"ADJ"} ,
		{"word":"bagués","word_nosc":"bagues","lemma":"bagué","pos":"ADJ"} ,
		{"word":"bai","word_nosc":"bai","lemma":"bai","pos":"ADJ"} ,
		{"word":"baie","word_nosc":"baie","lemma":"baie","pos":"ADJ"} ,
		{"word":"baies","word_nosc":"baies","lemma":"baie","pos":"ADJ"} ,
		{"word":"bais","word_nosc":"bais","lemma":"bai","pos":"ADJ"} ,
		{"word":"baisable","word_nosc":"baisable","lemma":"baisable","pos":"ADJ"} ,
		{"word":"baisables","word_nosc":"baisables","lemma":"baisable","pos":"ADJ"} ,
		{"word":"baissier","word_nosc":"baissier","lemma":"baissier","pos":"ADJ"} ,
		{"word":"baladeur","word_nosc":"baladeur","lemma":"baladeur","pos":"ADJ"} ,
		{"word":"baladeurs","word_nosc":"baladeurs","lemma":"baladeur","pos":"ADJ"} ,
		{"word":"baladeuse","word_nosc":"baladeuse","lemma":"baladeur","pos":"ADJ"} ,
		{"word":"baladeuses","word_nosc":"baladeuses","lemma":"baladeur","pos":"ADJ"} ,
		{"word":"balafré","word_nosc":"balafre","lemma":"balafré","pos":"ADJ"} ,
		{"word":"balafrée","word_nosc":"balafree","lemma":"balafré","pos":"ADJ"} ,
		{"word":"balafrés","word_nosc":"balafres","lemma":"balafré","pos":"ADJ"} ,
		{"word":"balais","word_nosc":"balais","lemma":"balais","pos":"ADJ"} ,
		{"word":"balaise","word_nosc":"balaise","lemma":"balaise","pos":"ADJ"} ,
		{"word":"balaises","word_nosc":"balaises","lemma":"balaise","pos":"ADJ"} ,
		{"word":"balancé","word_nosc":"balance","lemma":"balancé","pos":"ADJ"} ,
		{"word":"balancée","word_nosc":"balancee","lemma":"balancé","pos":"ADJ"} ,
		{"word":"balancées","word_nosc":"balancees","lemma":"balancé","pos":"ADJ"} ,
		{"word":"balancés","word_nosc":"balances","lemma":"balancé","pos":"ADJ"} ,
		{"word":"balayant","word_nosc":"balayant","lemma":"balayant","pos":"ADJ"} ,
		{"word":"balbutiant","word_nosc":"balbutiant","lemma":"balbutiant","pos":"ADJ"} ,
		{"word":"balbutiante","word_nosc":"balbutiante","lemma":"balbutiant","pos":"ADJ"} ,
		{"word":"balbutiantes","word_nosc":"balbutiantes","lemma":"balbutiant","pos":"ADJ"} ,
		{"word":"baleinier","word_nosc":"baleinier","lemma":"baleinier","pos":"ADJ"} ,
		{"word":"baleiniers","word_nosc":"baleiniers","lemma":"baleinier","pos":"ADJ"} ,
		{"word":"baleinière","word_nosc":"baleiniere","lemma":"baleinier","pos":"ADJ"} ,
		{"word":"baleinières","word_nosc":"baleinieres","lemma":"baleinier","pos":"ADJ"} ,
		{"word":"baleiné","word_nosc":"baleine","lemma":"baleiné","pos":"ADJ"} ,
		{"word":"baleinée","word_nosc":"baleinee","lemma":"baleiné","pos":"ADJ"} ,
		{"word":"baleinées","word_nosc":"baleinees","lemma":"baleiné","pos":"ADJ"} ,
		{"word":"baleinés","word_nosc":"baleines","lemma":"baleiné","pos":"ADJ"} ,
		{"word":"balinais","word_nosc":"balinais","lemma":"balinais","pos":"ADJ"} ,
		{"word":"balinaise","word_nosc":"balinaise","lemma":"balinais","pos":"ADJ"} ,
		{"word":"balistes","word_nosc":"balistes","lemma":"baliste","pos":"ADJ"} ,
		{"word":"balistique","word_nosc":"balistique","lemma":"balistique","pos":"ADJ"} ,
		{"word":"balistiques","word_nosc":"balistiques","lemma":"balistique","pos":"ADJ"} ,
		{"word":"balkanique","word_nosc":"balkanique","lemma":"balkanique","pos":"ADJ"} ,
		{"word":"balkaniques","word_nosc":"balkaniques","lemma":"balkanique","pos":"ADJ"} ,
		{"word":"ballant","word_nosc":"ballant","lemma":"ballant","pos":"ADJ"} ,
		{"word":"ballante","word_nosc":"ballante","lemma":"ballant","pos":"ADJ"} ,
		{"word":"ballantes","word_nosc":"ballantes","lemma":"ballant","pos":"ADJ"} ,
		{"word":"ballants","word_nosc":"ballants","lemma":"ballant","pos":"ADJ"} ,
		{"word":"ballonnant","word_nosc":"ballonnant","lemma":"ballonnant","pos":"ADJ"} ,
		{"word":"ballonnante","word_nosc":"ballonnante","lemma":"ballonnant","pos":"ADJ"} ,
		{"word":"ballonnants","word_nosc":"ballonnants","lemma":"ballonnant","pos":"ADJ"} ,
		{"word":"ballonné","word_nosc":"ballonne","lemma":"ballonné","pos":"ADJ"} ,
		{"word":"ballonnée","word_nosc":"ballonnee","lemma":"ballonné","pos":"ADJ"} ,
		{"word":"ballonnées","word_nosc":"ballonnees","lemma":"ballonné","pos":"ADJ"} ,
		{"word":"ballonnés","word_nosc":"ballonnes","lemma":"ballonné","pos":"ADJ"} ,
		{"word":"ballot","word_nosc":"ballot","lemma":"ballot","pos":"ADJ"} ,
		{"word":"ballots","word_nosc":"ballots","lemma":"ballot","pos":"ADJ"} ,
		{"word":"ballotté","word_nosc":"ballotte","lemma":"ballotté","pos":"ADJ"} ,
		{"word":"ballottée","word_nosc":"ballottee","lemma":"ballotté","pos":"ADJ"} ,
		{"word":"balnéaire","word_nosc":"balneaire","lemma":"balnéaire","pos":"ADJ"} ,
		{"word":"balnéaires","word_nosc":"balneaires","lemma":"balnéaire","pos":"ADJ"} ,
		{"word":"balourd","word_nosc":"balourd","lemma":"balourd","pos":"ADJ"} ,
		{"word":"balourde","word_nosc":"balourde","lemma":"balourd","pos":"ADJ"} ,
		{"word":"balourds","word_nosc":"balourds","lemma":"balourd","pos":"ADJ"} ,
		{"word":"balsamique","word_nosc":"balsamique","lemma":"balsamique","pos":"ADJ"} ,
		{"word":"balsamiques","word_nosc":"balsamiques","lemma":"balsamique","pos":"ADJ"} ,
		{"word":"balte","word_nosc":"balte","lemma":"balte","pos":"ADJ"} ,
		{"word":"baltes","word_nosc":"baltes","lemma":"balte","pos":"ADJ"} ,
		{"word":"baltique","word_nosc":"baltique","lemma":"baltique","pos":"ADJ"} ,
		{"word":"baltiques","word_nosc":"baltiques","lemma":"baltique","pos":"ADJ"} ,
		{"word":"balzacien","word_nosc":"balzacien","lemma":"balzacien","pos":"ADJ"} ,
		{"word":"balzacienne","word_nosc":"balzacienne","lemma":"balzacien","pos":"ADJ"} ,
		{"word":"balzaciennes","word_nosc":"balzaciennes","lemma":"balzacien","pos":"ADJ"} ,
		{"word":"balzaciens","word_nosc":"balzaciens","lemma":"balzacien","pos":"ADJ"} ,
		{"word":"balèze","word_nosc":"baleze","lemma":"balèze","pos":"ADJ"} ,
		{"word":"balèzes","word_nosc":"balezes","lemma":"balèze","pos":"ADJ"} ,
		{"word":"bambara","word_nosc":"bambara","lemma":"bambara","pos":"ADJ"} ,
		{"word":"banal","word_nosc":"banal","lemma":"banal","pos":"ADJ"} ,
		{"word":"banale","word_nosc":"banale","lemma":"banal","pos":"ADJ"} ,
		{"word":"banales","word_nosc":"banales","lemma":"banal","pos":"ADJ"} ,
		{"word":"banals","word_nosc":"banals","lemma":"banal","pos":"ADJ"} ,
		{"word":"bananier","word_nosc":"bananier","lemma":"bananier","pos":"ADJ"} ,
		{"word":"bananiers","word_nosc":"bananiers","lemma":"bananier","pos":"ADJ"} ,
		{"word":"bananière","word_nosc":"bananiere","lemma":"bananier","pos":"ADJ"} ,
		{"word":"banaux","word_nosc":"banaux","lemma":"banal","pos":"ADJ"} ,
		{"word":"bancaire","word_nosc":"bancaire","lemma":"bancaire","pos":"ADJ"} ,
		{"word":"bancaires","word_nosc":"bancaires","lemma":"bancaire","pos":"ADJ"} ,
		{"word":"bancal","word_nosc":"bancal","lemma":"bancal","pos":"ADJ"} ,
		{"word":"bancale","word_nosc":"bancale","lemma":"bancal","pos":"ADJ"} ,
		{"word":"bancales","word_nosc":"bancales","lemma":"bancal","pos":"ADJ"} ,
		{"word":"bancals","word_nosc":"bancals","lemma":"bancal","pos":"ADJ"} ,
		{"word":"banco","word_nosc":"banco","lemma":"banco","pos":"ADJ"} ,
		{"word":"bancroche","word_nosc":"bancroche","lemma":"bancroche","pos":"ADJ"} ,
		{"word":"bancroches","word_nosc":"bancroches","lemma":"bancroche","pos":"ADJ"} ,
		{"word":"bandant","word_nosc":"bandant","lemma":"bandant","pos":"ADJ"} ,
		{"word":"bandante","word_nosc":"bandante","lemma":"bandant","pos":"ADJ"} ,
		{"word":"bandantes","word_nosc":"bandantes","lemma":"bandant","pos":"ADJ"} ,
		{"word":"bandants","word_nosc":"bandants","lemma":"bandant","pos":"ADJ"} ,
		{"word":"bandé","word_nosc":"bande","lemma":"bandé","pos":"ADJ"} ,
		{"word":"bandée","word_nosc":"bandee","lemma":"bandé","pos":"ADJ"} ,
		{"word":"bandées","word_nosc":"bandees","lemma":"bandé","pos":"ADJ"} ,
		{"word":"bandés","word_nosc":"bandes","lemma":"bandé","pos":"ADJ"} ,
		{"word":"banlieusard","word_nosc":"banlieusard","lemma":"banlieusard","pos":"ADJ"} ,
		{"word":"banlieusarde","word_nosc":"banlieusarde","lemma":"banlieusard","pos":"ADJ"} ,
		{"word":"banlieusardes","word_nosc":"banlieusardes","lemma":"banlieusard","pos":"ADJ"} ,
		{"word":"banlieusards","word_nosc":"banlieusards","lemma":"banlieusard","pos":"ADJ"} ,
		{"word":"banni","word_nosc":"banni","lemma":"banni","pos":"ADJ"} ,
		{"word":"bannie","word_nosc":"bannie","lemma":"banni","pos":"ADJ"} ,
		{"word":"bannies","word_nosc":"bannies","lemma":"banni","pos":"ADJ"} ,
		{"word":"bannis","word_nosc":"bannis","lemma":"banni","pos":"ADJ"} ,
		{"word":"bantou","word_nosc":"bantou","lemma":"bantou","pos":"ADJ"} ,
		{"word":"bantoue","word_nosc":"bantoue","lemma":"bantou","pos":"ADJ"} ,
		{"word":"baptismal","word_nosc":"baptismal","lemma":"baptismal","pos":"ADJ"} ,
		{"word":"baptismale","word_nosc":"baptismale","lemma":"baptismal","pos":"ADJ"} ,
		{"word":"baptismales","word_nosc":"baptismales","lemma":"baptismal","pos":"ADJ"} ,
		{"word":"baptismaux","word_nosc":"baptismaux","lemma":"baptismal","pos":"ADJ"} ,
		{"word":"baptiste","word_nosc":"baptiste","lemma":"baptiste","pos":"ADJ"} ,
		{"word":"baptistes","word_nosc":"baptistes","lemma":"baptiste","pos":"ADJ"} ,
		{"word":"baraqué","word_nosc":"baraque","lemma":"baraqué","pos":"ADJ"} ,
		{"word":"baraquée","word_nosc":"baraquee","lemma":"baraqué","pos":"ADJ"} ,
		{"word":"baraqués","word_nosc":"baraques","lemma":"baraqué","pos":"ADJ"} ,
		{"word":"baratineur","word_nosc":"baratineur","lemma":"baratineur","pos":"ADJ"} ,
		{"word":"baratineuse","word_nosc":"baratineuse","lemma":"baratineur","pos":"ADJ"} ,
		{"word":"barbant","word_nosc":"barbant","lemma":"barbant","pos":"ADJ"} ,
		{"word":"barbante","word_nosc":"barbante","lemma":"barbant","pos":"ADJ"} ,
		{"word":"barbantes","word_nosc":"barbantes","lemma":"barbant","pos":"ADJ"} ,
		{"word":"barbants","word_nosc":"barbants","lemma":"barbant","pos":"ADJ"} ,
		{"word":"barbare","word_nosc":"barbare","lemma":"barbare","pos":"ADJ"} ,
		{"word":"barbares","word_nosc":"barbares","lemma":"barbare","pos":"ADJ"} ,
		{"word":"barbaresque","word_nosc":"barbaresque","lemma":"barbaresque","pos":"ADJ"} ,
		{"word":"barbaresques","word_nosc":"barbaresques","lemma":"barbaresque","pos":"ADJ"} ,
		{"word":"barbelé","word_nosc":"barbele","lemma":"barbelé","pos":"ADJ"} ,
		{"word":"barbelée","word_nosc":"barbelee","lemma":"barbelé","pos":"ADJ"} ,
		{"word":"barbelées","word_nosc":"barbelees","lemma":"barbelé","pos":"ADJ"} ,
		{"word":"barbelés","word_nosc":"barbeles","lemma":"barbelé","pos":"ADJ"} ,
		{"word":"barbette","word_nosc":"barbette","lemma":"barbette","pos":"ADJ"} ,
		{"word":"barbichu","word_nosc":"barbichu","lemma":"barbichu","pos":"ADJ"} ,
		{"word":"barbichus","word_nosc":"barbichus","lemma":"barbichu","pos":"ADJ"} ,
		{"word":"barbiturique","word_nosc":"barbiturique","lemma":"barbiturique","pos":"ADJ"} ,
		{"word":"barbituriques","word_nosc":"barbituriques","lemma":"barbiturique","pos":"ADJ"} ,
		{"word":"barboteuses","word_nosc":"barboteuses","lemma":"barboteur","pos":"ADJ"} ,
		{"word":"barbouillé","word_nosc":"barbouille","lemma":"barbouillé","pos":"ADJ"} ,
		{"word":"barbouillée","word_nosc":"barbouillee","lemma":"barbouillé","pos":"ADJ"} ,
		{"word":"barbouillées","word_nosc":"barbouillees","lemma":"barbouillé","pos":"ADJ"} ,
		{"word":"barbouillés","word_nosc":"barbouilles","lemma":"barbouillé","pos":"ADJ"} ,
		{"word":"barbouze","word_nosc":"barbouze","lemma":"barbouze","pos":"ADJ"} ,
		{"word":"barbu","word_nosc":"barbu","lemma":"barbu","pos":"ADJ"} ,
		{"word":"barbue","word_nosc":"barbue","lemma":"barbu","pos":"ADJ"} ,
		{"word":"barbues","word_nosc":"barbues","lemma":"barbu","pos":"ADJ"} ,
		{"word":"barbus","word_nosc":"barbus","lemma":"barbu","pos":"ADJ"} ,
		{"word":"bardiques","word_nosc":"bardiques","lemma":"bardique","pos":"ADJ"} ,
		{"word":"barge","word_nosc":"barge","lemma":"barge","pos":"ADJ"} ,
		{"word":"barges","word_nosc":"barges","lemma":"barge","pos":"ADJ"} ,
		{"word":"bariolé","word_nosc":"bariole","lemma":"bariolé","pos":"ADJ"} ,
		{"word":"bariolée","word_nosc":"bariolee","lemma":"bariolé","pos":"ADJ"} ,
		{"word":"bariolées","word_nosc":"bariolees","lemma":"bariolé","pos":"ADJ"} ,
		{"word":"bariolés","word_nosc":"barioles","lemma":"bariolé","pos":"ADJ"} ,
		{"word":"barje","word_nosc":"barje","lemma":"barje","pos":"ADJ"} ,
		{"word":"barjes","word_nosc":"barjes","lemma":"barje","pos":"ADJ"} ,
		{"word":"barjo","word_nosc":"barjo","lemma":"barjo","pos":"ADJ"} ,
		{"word":"barjos","word_nosc":"barjos","lemma":"barjo","pos":"ADJ"} ,
		{"word":"barjot","word_nosc":"barjot","lemma":"barjot","pos":"ADJ"} ,
		{"word":"barjots","word_nosc":"barjots","lemma":"barjot","pos":"ADJ"} ,
		{"word":"barométrique","word_nosc":"barometrique","lemma":"barométrique","pos":"ADJ"} ,
		{"word":"baroque","word_nosc":"baroque","lemma":"baroque","pos":"ADJ"} ,
		{"word":"baroques","word_nosc":"baroques","lemma":"baroque","pos":"ADJ"} ,
		{"word":"barreaudées","word_nosc":"barreaudees","lemma":"barreaudé","pos":"ADJ"} ,
		{"word":"barré","word_nosc":"barre","lemma":"barré","pos":"ADJ"} ,
		{"word":"barrée","word_nosc":"barree","lemma":"barré","pos":"ADJ"} ,
		{"word":"barrées","word_nosc":"barrees","lemma":"barré","pos":"ADJ"} ,
		{"word":"barrés","word_nosc":"barres","lemma":"barré","pos":"ADJ"} ,
		{"word":"baryté","word_nosc":"baryte","lemma":"baryté","pos":"ADJ"} ,
		{"word":"bas","word_nosc":"bas","lemma":"bas","pos":"ADJ"} ,
		{"word":"basal","word_nosc":"basal","lemma":"basal","pos":"ADJ"} ,
		{"word":"basaltique","word_nosc":"basaltique","lemma":"basaltique","pos":"ADJ"} ,
		{"word":"basané","word_nosc":"basane","lemma":"basané","pos":"ADJ"} ,
		{"word":"basanée","word_nosc":"basanee","lemma":"basané","pos":"ADJ"} ,
		{"word":"basanées","word_nosc":"basanees","lemma":"basané","pos":"ADJ"} ,
		{"word":"basanés","word_nosc":"basanes","lemma":"basané","pos":"ADJ"} ,
		{"word":"basculant","word_nosc":"basculant","lemma":"basculant","pos":"ADJ"} ,
		{"word":"basculante","word_nosc":"basculante","lemma":"basculant","pos":"ADJ"} ,
		{"word":"basculants","word_nosc":"basculants","lemma":"basculant","pos":"ADJ"} ,
		{"word":"basilaire","word_nosc":"basilaire","lemma":"basilaire","pos":"ADJ"} ,
		{"word":"basilique","word_nosc":"basilique","lemma":"basilique","pos":"ADJ"} ,
		{"word":"basiliques","word_nosc":"basiliques","lemma":"basilique","pos":"ADJ"} ,
		{"word":"basique","word_nosc":"basique","lemma":"basique","pos":"ADJ"} ,
		{"word":"basiques","word_nosc":"basiques","lemma":"basique","pos":"ADJ"} ,
		{"word":"basquaise","word_nosc":"basquaise","lemma":"basquais","pos":"ADJ"} ,
		{"word":"basque","word_nosc":"basque","lemma":"basque","pos":"ADJ"} ,
		{"word":"basques","word_nosc":"basques","lemma":"basque","pos":"ADJ"} ,
		{"word":"basse","word_nosc":"basse","lemma":"bas","pos":"ADJ"} ,
		{"word":"basses","word_nosc":"basses","lemma":"bas","pos":"ADJ"} ,
		{"word":"basé","word_nosc":"base","lemma":"basé","pos":"ADJ"} ,
		{"word":"basée","word_nosc":"basee","lemma":"basé","pos":"ADJ"} ,
		{"word":"basées","word_nosc":"basees","lemma":"basé","pos":"ADJ"} ,
		{"word":"basés","word_nosc":"bases","lemma":"basé","pos":"ADJ"} ,
		{"word":"batailleur","word_nosc":"batailleur","lemma":"batailleur","pos":"ADJ"} ,
		{"word":"batailleurs","word_nosc":"batailleurs","lemma":"batailleur","pos":"ADJ"} ,
		{"word":"batailleuse","word_nosc":"batailleuse","lemma":"batailleur","pos":"ADJ"} ,
		{"word":"batailleuses","word_nosc":"batailleuses","lemma":"batailleur","pos":"ADJ"} ,
		{"word":"batave","word_nosc":"batave","lemma":"batave","pos":"ADJ"} ,
		{"word":"bataves","word_nosc":"bataves","lemma":"batave","pos":"ADJ"} ,
		{"word":"bath","word_nosc":"bath","lemma":"bath","pos":"ADJ"} ,
		{"word":"bathyale","word_nosc":"bathyale","lemma":"bathyal","pos":"ADJ"} ,
		{"word":"bathymétrique","word_nosc":"bathymetrique","lemma":"bathymétrique","pos":"ADJ"} ,
		{"word":"batracienne","word_nosc":"batracienne","lemma":"batracien","pos":"ADJ"} ,
		{"word":"battant","word_nosc":"battant","lemma":"battant","pos":"ADJ"} ,
		{"word":"battante","word_nosc":"battante","lemma":"battant","pos":"ADJ"} ,
		{"word":"battantes","word_nosc":"battantes","lemma":"battant","pos":"ADJ"} ,
		{"word":"battants","word_nosc":"battants","lemma":"battant","pos":"ADJ"} ,
		{"word":"battu","word_nosc":"battu","lemma":"battu","pos":"ADJ"} ,
		{"word":"battue","word_nosc":"battue","lemma":"battu","pos":"ADJ"} ,
		{"word":"battues","word_nosc":"battues","lemma":"battu","pos":"ADJ"} ,
		{"word":"battus","word_nosc":"battus","lemma":"battu","pos":"ADJ"} ,
		{"word":"baudelairien","word_nosc":"baudelairien","lemma":"baudelairien","pos":"ADJ"} ,
		{"word":"baudelairiennes","word_nosc":"baudelairiennes","lemma":"baudelairien","pos":"ADJ"} ,
		{"word":"baudelairiens","word_nosc":"baudelairiens","lemma":"baudelairien","pos":"ADJ"} ,
		{"word":"bavard","word_nosc":"bavard","lemma":"bavard","pos":"ADJ"} ,
		{"word":"bavarde","word_nosc":"bavarde","lemma":"bavard","pos":"ADJ"} ,
		{"word":"bavardes","word_nosc":"bavardes","lemma":"bavard","pos":"ADJ"} ,
		{"word":"bavards","word_nosc":"bavards","lemma":"bavard","pos":"ADJ"} ,
		{"word":"bavarois","word_nosc":"bavarois","lemma":"bavarois","pos":"ADJ"} ,
		{"word":"bavaroise","word_nosc":"bavaroise","lemma":"bavarois","pos":"ADJ"} ,
		{"word":"bavaroises","word_nosc":"bavaroises","lemma":"bavarois","pos":"ADJ"} ,
		{"word":"bavasseur","word_nosc":"bavasseur","lemma":"bavasseur","pos":"ADJ"} ,
		{"word":"baveur","word_nosc":"baveur","lemma":"baveur","pos":"ADJ"} ,
		{"word":"baveurs","word_nosc":"baveurs","lemma":"baveur","pos":"ADJ"} ,
		{"word":"baveuse","word_nosc":"baveuse","lemma":"baveux","pos":"ADJ"} ,
		{"word":"baveuses","word_nosc":"baveuses","lemma":"baveux","pos":"ADJ"} ,
		{"word":"baveux","word_nosc":"baveux","lemma":"baveux","pos":"ADJ"} ,
		{"word":"bayadère","word_nosc":"bayadere","lemma":"bayadère","pos":"ADJ"} ,
		{"word":"bayadères","word_nosc":"bayaderes","lemma":"bayadère","pos":"ADJ"} ,
		{"word":"bcbg","word_nosc":"bcbg","lemma":"bcbg","pos":"ADJ"} ,
		{"word":"beat","word_nosc":"beat","lemma":"beat","pos":"ADJ"} ,
		{"word":"beatnik","word_nosc":"beatnik","lemma":"beatnik","pos":"ADJ"} ,
		{"word":"beatniks","word_nosc":"beatniks","lemma":"beatnik","pos":"ADJ"} ,
		{"word":"beau","word_nosc":"beau","lemma":"beau","pos":"ADJ"} ,
		{"word":"beauceron","word_nosc":"beauceron","lemma":"beauceron","pos":"ADJ"} ,
		{"word":"beauceronne","word_nosc":"beauceronne","lemma":"beauceron","pos":"ADJ"} ,
		{"word":"beauceronnes","word_nosc":"beauceronnes","lemma":"beauceron","pos":"ADJ"} ,
		{"word":"beaucerons","word_nosc":"beaucerons","lemma":"beauceron","pos":"ADJ"} ,
		{"word":"beaux","word_nosc":"beaux","lemma":"beau","pos":"ADJ"} ,
		{"word":"bedonnant","word_nosc":"bedonnant","lemma":"bedonnant","pos":"ADJ"} ,
		{"word":"bedonnante","word_nosc":"bedonnante","lemma":"bedonnant","pos":"ADJ"} ,
		{"word":"bedonnantes","word_nosc":"bedonnantes","lemma":"bedonnant","pos":"ADJ"} ,
		{"word":"bedonnants","word_nosc":"bedonnants","lemma":"bedonnant","pos":"ADJ"} ,
		{"word":"beethovénien","word_nosc":"beethovenien","lemma":"beethovénien","pos":"ADJ"} ,
		{"word":"behavioriste","word_nosc":"behavioriste","lemma":"behavioriste","pos":"ADJ"} ,
		{"word":"beige","word_nosc":"beige","lemma":"beige","pos":"ADJ"} ,
		{"word":"beigeasses","word_nosc":"beigeasses","lemma":"beigeasse","pos":"ADJ"} ,
		{"word":"beiges","word_nosc":"beiges","lemma":"beige","pos":"ADJ"} ,
		{"word":"bel","word_nosc":"bel","lemma":"bel","pos":"ADJ"} ,
		{"word":"belge","word_nosc":"belge","lemma":"belge","pos":"ADJ"} ,
		{"word":"belges","word_nosc":"belges","lemma":"belge","pos":"ADJ"} ,
		{"word":"belle","word_nosc":"belle","lemma":"beau","pos":"ADJ"} ,
		{"word":"belles","word_nosc":"belles","lemma":"beau","pos":"ADJ"} ,
		{"word":"bellevillois","word_nosc":"bellevillois","lemma":"bellevillois","pos":"ADJ"} ,
		{"word":"bellevilloise","word_nosc":"bellevilloise","lemma":"bellevillois","pos":"ADJ"} ,
		{"word":"belliciste","word_nosc":"belliciste","lemma":"belliciste","pos":"ADJ"} ,
		{"word":"bellicistes","word_nosc":"bellicistes","lemma":"belliciste","pos":"ADJ"} ,
		{"word":"belligérant","word_nosc":"belligerant","lemma":"belligérant","pos":"ADJ"} ,
		{"word":"belligérante","word_nosc":"belligerante","lemma":"belligérant","pos":"ADJ"} ,
		{"word":"belligérantes","word_nosc":"belligerantes","lemma":"belligérant","pos":"ADJ"} ,
		{"word":"belligérants","word_nosc":"belligerants","lemma":"belligérant","pos":"ADJ"} ,
		{"word":"belliqueuse","word_nosc":"belliqueuse","lemma":"belliqueux","pos":"ADJ"} ,
		{"word":"belliqueuses","word_nosc":"belliqueuses","lemma":"belliqueux","pos":"ADJ"} ,
		{"word":"belliqueux","word_nosc":"belliqueux","lemma":"belliqueux","pos":"ADJ"} ,
		{"word":"bellissime","word_nosc":"bellissime","lemma":"bellissime","pos":"ADJ"} ,
		{"word":"bellot","word_nosc":"bellot","lemma":"bellot","pos":"ADJ"} ,
		{"word":"beluga","word_nosc":"beluga","lemma":"beluga","pos":"ADJ"} ,
		{"word":"bengalais","word_nosc":"bengalais","lemma":"bengalais","pos":"ADJ"} ,
		{"word":"bengali","word_nosc":"bengali","lemma":"bengali","pos":"ADJ"} ,
		{"word":"bengalis","word_nosc":"bengalis","lemma":"bengali","pos":"ADJ"} ,
		{"word":"benoît","word_nosc":"benoit","lemma":"benoît","pos":"ADJ"} ,
		{"word":"benoîte","word_nosc":"benoite","lemma":"benoît","pos":"ADJ"} ,
		{"word":"benthique","word_nosc":"benthique","lemma":"benthique","pos":"ADJ"} ,
		{"word":"benzylique","word_nosc":"benzylique","lemma":"benzylique","pos":"ADJ"} ,
		{"word":"benêt","word_nosc":"benet","lemma":"benêt","pos":"ADJ"} ,
		{"word":"benêts","word_nosc":"benets","lemma":"benêt","pos":"ADJ"} ,
		{"word":"berbère","word_nosc":"berbere","lemma":"berbère","pos":"ADJ"} ,
		{"word":"berbères","word_nosc":"berberes","lemma":"berbère","pos":"ADJ"} ,
		{"word":"berceur","word_nosc":"berceur","lemma":"berceur","pos":"ADJ"} ,
		{"word":"berceurs","word_nosc":"berceurs","lemma":"berceur","pos":"ADJ"} ,
		{"word":"berceuse","word_nosc":"berceuse","lemma":"berceur","pos":"ADJ"} ,
		{"word":"berceuses","word_nosc":"berceuses","lemma":"berceur","pos":"ADJ"} ,
		{"word":"berlinois","word_nosc":"berlinois","lemma":"berlinois","pos":"ADJ"} ,
		{"word":"berlinoise","word_nosc":"berlinoise","lemma":"berlinois","pos":"ADJ"} ,
		{"word":"berlinoises","word_nosc":"berlinoises","lemma":"berlinois","pos":"ADJ"} ,
		{"word":"bernois","word_nosc":"bernois","lemma":"bernois","pos":"ADJ"} ,
		{"word":"bernoises","word_nosc":"bernoises","lemma":"bernois","pos":"ADJ"} ,
		{"word":"berrichon","word_nosc":"berrichon","lemma":"berrichon","pos":"ADJ"} ,
		{"word":"berrichonne","word_nosc":"berrichonne","lemma":"berrichon","pos":"ADJ"} ,
		{"word":"besogneuse","word_nosc":"besogneuse","lemma":"besogneux","pos":"ADJ"} ,
		{"word":"besogneuses","word_nosc":"besogneuses","lemma":"besogneux","pos":"ADJ"} ,
		{"word":"besogneux","word_nosc":"besogneux","lemma":"besogneux","pos":"ADJ"} ,
		{"word":"bestial","word_nosc":"bestial","lemma":"bestial","pos":"ADJ"} ,
		{"word":"bestiale","word_nosc":"bestiale","lemma":"bestial","pos":"ADJ"} ,
		{"word":"bestiales","word_nosc":"bestiales","lemma":"bestial","pos":"ADJ"} ,
		{"word":"bestiaux","word_nosc":"bestiaux","lemma":"bestial","pos":"ADJ"} ,
		{"word":"beur","word_nosc":"beur","lemma":"beur","pos":"ADJ"} ,
		{"word":"beurre","word_nosc":"beurre","lemma":"beurre","pos":"ADJ"} ,
		{"word":"beurré","word_nosc":"beurre","lemma":"beurré","pos":"ADJ"} ,
		{"word":"beurrée","word_nosc":"beurree","lemma":"beurré","pos":"ADJ"} ,
		{"word":"beurrées","word_nosc":"beurrees","lemma":"beurré","pos":"ADJ"} ,
		{"word":"beurrés","word_nosc":"beurres","lemma":"beurré","pos":"ADJ"} ,
		{"word":"biais","word_nosc":"biais","lemma":"biais","pos":"ADJ"} ,
		{"word":"biaise","word_nosc":"biaise","lemma":"biais","pos":"ADJ"} ,
		{"word":"biaises","word_nosc":"biaises","lemma":"biais","pos":"ADJ"} ,
		{"word":"biaisé","word_nosc":"biaise","lemma":"biaisé","pos":"ADJ"} ,
		{"word":"biaisée","word_nosc":"biaisee","lemma":"biaisé","pos":"ADJ"} ,
		{"word":"bibliographique","word_nosc":"bibliographique","lemma":"bibliographique","pos":"ADJ"} ,
		{"word":"bibliographiques","word_nosc":"bibliographiques","lemma":"bibliographique","pos":"ADJ"} ,
		{"word":"bibliophilique","word_nosc":"bibliophilique","lemma":"bibliophilique","pos":"ADJ"} ,
		{"word":"biblique","word_nosc":"biblique","lemma":"biblique","pos":"ADJ"} ,
		{"word":"bibliques","word_nosc":"bibliques","lemma":"biblique","pos":"ADJ"} ,
		{"word":"bicamérale","word_nosc":"bicamerale","lemma":"bicaméral","pos":"ADJ"} ,
		{"word":"bicentenaire","word_nosc":"bicentenaire","lemma":"bicentenaire","pos":"ADJ"} ,
		{"word":"bicolore","word_nosc":"bicolore","lemma":"bicolore","pos":"ADJ"} ,
		{"word":"bicolores","word_nosc":"bicolores","lemma":"bicolore","pos":"ADJ"} ,
		{"word":"biconvexes","word_nosc":"biconvexes","lemma":"biconvexe","pos":"ADJ"} ,
		{"word":"bicorne","word_nosc":"bicorne","lemma":"bicorne","pos":"ADJ"} ,
		{"word":"bicornes","word_nosc":"bicornes","lemma":"bicorne","pos":"ADJ"} ,
		{"word":"biculturelle","word_nosc":"biculturelle","lemma":"biculturel","pos":"ADJ"} ,
		{"word":"bicuspide","word_nosc":"bicuspide","lemma":"bicuspide","pos":"ADJ"} ,
		{"word":"bicycliste","word_nosc":"bicycliste","lemma":"bicycliste","pos":"ADJ"} ,
		{"word":"bicéphale","word_nosc":"bicephale","lemma":"bicéphale","pos":"ADJ"} ,
		{"word":"bicéphales","word_nosc":"bicephales","lemma":"bicéphale","pos":"ADJ"} ,
		{"word":"bidimensionnel","word_nosc":"bidimensionnel","lemma":"bidimensionnel","pos":"ADJ"} ,
		{"word":"bidon","word_nosc":"bidon","lemma":"bidon","pos":"ADJ"} ,
		{"word":"bidonnant","word_nosc":"bidonnant","lemma":"bidonnant","pos":"ADJ"} ,
		{"word":"bidonnante","word_nosc":"bidonnante","lemma":"bidonnant","pos":"ADJ"} ,
		{"word":"bien","word_nosc":"bien","lemma":"bien","pos":"ADJ"} ,
		{"word":"bien-aimé","word_nosc":"bien-aime","lemma":"bien-aimé","pos":"ADJ"} ,
		{"word":"bien-aimée","word_nosc":"bien-aimee","lemma":"bien-aimé","pos":"ADJ"} ,
		{"word":"bien-aimées","word_nosc":"bien-aimees","lemma":"bien-aimé","pos":"ADJ"} ,
		{"word":"bien-aimés","word_nosc":"bien-aimes","lemma":"bien-aimé","pos":"ADJ"} ,
		{"word":"bien-disants","word_nosc":"bien-disants","lemma":"bien-disant","pos":"ADJ"} ,
		{"word":"bien-pensant","word_nosc":"bien-pensant","lemma":"bien-pensant","pos":"ADJ"} ,
		{"word":"bien-pensante","word_nosc":"bien-pensante","lemma":"bien-pensant","pos":"ADJ"} ,
		{"word":"bien-pensants","word_nosc":"bien-pensants","lemma":"bien-pensant","pos":"ADJ"} ,
		{"word":"bien-portant","word_nosc":"bien-portant","lemma":"bien-portant","pos":"ADJ"} ,
		{"word":"bien-portants","word_nosc":"bien-portants","lemma":"bien-portant","pos":"ADJ"} ,
		{"word":"bienfaisant","word_nosc":"bienfaisant","lemma":"bienfaisant","pos":"ADJ"} ,
		{"word":"bienfaisante","word_nosc":"bienfaisante","lemma":"bienfaisant","pos":"ADJ"} ,
		{"word":"bienfaisantes","word_nosc":"bienfaisantes","lemma":"bienfaisant","pos":"ADJ"} ,
		{"word":"bienfaisants","word_nosc":"bienfaisants","lemma":"bienfaisant","pos":"ADJ"} ,
		{"word":"bienheureuse","word_nosc":"bienheureuse","lemma":"bienheureux","pos":"ADJ"} ,
		{"word":"bienheureuses","word_nosc":"bienheureuses","lemma":"bienheureux","pos":"ADJ"} ,
		{"word":"bienheureux","word_nosc":"bienheureux","lemma":"bienheureux","pos":"ADJ"} ,
		{"word":"bienséant","word_nosc":"bienseant","lemma":"bienséant","pos":"ADJ"} ,
		{"word":"bienséante","word_nosc":"bienseante","lemma":"bienséant","pos":"ADJ"} ,
		{"word":"bienséantes","word_nosc":"bienseantes","lemma":"bienséant","pos":"ADJ"} ,
		{"word":"bienséants","word_nosc":"bienseants","lemma":"bienséant","pos":"ADJ"} ,
		{"word":"bienveillant","word_nosc":"bienveillant","lemma":"bienveillant","pos":"ADJ"} ,
		{"word":"bienveillante","word_nosc":"bienveillante","lemma":"bienveillant","pos":"ADJ"} ,
		{"word":"bienveillantes","word_nosc":"bienveillantes","lemma":"bienveillant","pos":"ADJ"} ,
		{"word":"bienveillants","word_nosc":"bienveillants","lemma":"bienveillant","pos":"ADJ"} ,
		{"word":"bienvenu","word_nosc":"bienvenu","lemma":"bienvenu","pos":"ADJ"} ,
		{"word":"bienvenue","word_nosc":"bienvenue","lemma":"bienvenue","pos":"ADJ"} ,
		{"word":"bienvenus","word_nosc":"bienvenus","lemma":"bienvenu","pos":"ADJ"} ,
		{"word":"bifaces","word_nosc":"bifaces","lemma":"biface","pos":"ADJ"} ,
		{"word":"bifide","word_nosc":"bifide","lemma":"bifide","pos":"ADJ"} ,
		{"word":"bifides","word_nosc":"bifides","lemma":"bifide","pos":"ADJ"} ,
		{"word":"bifrons","word_nosc":"bifrons","lemma":"bifron","pos":"ADJ"} ,
		{"word":"bigame","word_nosc":"bigame","lemma":"bigame","pos":"ADJ"} ,
		{"word":"bigarré","word_nosc":"bigarre","lemma":"bigarré","pos":"ADJ"} ,
		{"word":"bigarrée","word_nosc":"bigarree","lemma":"bigarré","pos":"ADJ"} ,
		{"word":"bigarrées","word_nosc":"bigarrees","lemma":"bigarré","pos":"ADJ"} ,
		{"word":"bigarrés","word_nosc":"bigarres","lemma":"bigarré","pos":"ADJ"} ,
		{"word":"bigle","word_nosc":"bigle","lemma":"bigle","pos":"ADJ"} ,
		{"word":"bigles","word_nosc":"bigles","lemma":"bigle","pos":"ADJ"} ,
		{"word":"bigleuse","word_nosc":"bigleuse","lemma":"bigleux","pos":"ADJ"} ,
		{"word":"bigleux","word_nosc":"bigleux","lemma":"bigleux","pos":"ADJ"} ,
		{"word":"bigot","word_nosc":"bigot","lemma":"bigot","pos":"ADJ"} ,
		{"word":"bigote","word_nosc":"bigote","lemma":"bigot","pos":"ADJ"} ,
		{"word":"bigots","word_nosc":"bigots","lemma":"bigot","pos":"ADJ"} ,
		{"word":"bigouden","word_nosc":"bigouden","lemma":"bigouden","pos":"ADJ"} ,
		{"word":"bihebdomadaire","word_nosc":"bihebdomadaire","lemma":"bihebdomadaire","pos":"ADJ"} ,
		{"word":"bihebdomadaires","word_nosc":"bihebdomadaires","lemma":"bihebdomadaire","pos":"ADJ"} ,
		{"word":"bilatéral","word_nosc":"bilateral","lemma":"bilatéral","pos":"ADJ"} ,
		{"word":"bilatérale","word_nosc":"bilaterale","lemma":"bilatéral","pos":"ADJ"} ,
		{"word":"bilatéraux","word_nosc":"bilateraux","lemma":"bilatéral","pos":"ADJ"} ,
		{"word":"bileux","word_nosc":"bileux","lemma":"bileux","pos":"ADJ"} ,
		{"word":"biliaire","word_nosc":"biliaire","lemma":"biliaire","pos":"ADJ"} ,
		{"word":"biliaires","word_nosc":"biliaires","lemma":"biliaire","pos":"ADJ"} ,
		{"word":"bilieuse","word_nosc":"bilieuse","lemma":"bilieux","pos":"ADJ"} ,
		{"word":"bilieuses","word_nosc":"bilieuses","lemma":"bilieux","pos":"ADJ"} ,
		{"word":"bilieux","word_nosc":"bilieux","lemma":"bilieux","pos":"ADJ"} ,
		{"word":"bilingue","word_nosc":"bilingue","lemma":"bilingue","pos":"ADJ"} ,
		{"word":"bilingues","word_nosc":"bilingues","lemma":"bilingue","pos":"ADJ"} ,
		{"word":"bilobée","word_nosc":"bilobee","lemma":"bilobé","pos":"ADJ"} ,
		{"word":"bimensuel","word_nosc":"bimensuel","lemma":"bimensuel","pos":"ADJ"} ,
		{"word":"bimensuelle","word_nosc":"bimensuelle","lemma":"bimensuel","pos":"ADJ"} ,
		{"word":"bimensuelles","word_nosc":"bimensuelles","lemma":"bimensuel","pos":"ADJ"} ,
		{"word":"bimoteur","word_nosc":"bimoteur","lemma":"bimoteur","pos":"ADJ"} ,
		{"word":"binaire","word_nosc":"binaire","lemma":"binaire","pos":"ADJ"} ,
		{"word":"binaires","word_nosc":"binaires","lemma":"binaire","pos":"ADJ"} ,
		{"word":"binoclard","word_nosc":"binoclard","lemma":"binoclard","pos":"ADJ"} ,
		{"word":"binoclarde","word_nosc":"binoclarde","lemma":"binoclard","pos":"ADJ"} ,
		{"word":"binoclards","word_nosc":"binoclards","lemma":"binoclard","pos":"ADJ"} ,
		{"word":"binoculaire","word_nosc":"binoculaire","lemma":"binoculaire","pos":"ADJ"} ,
		{"word":"bio","word_nosc":"bio","lemma":"bio","pos":"ADJ"} ,
		{"word":"biochimique","word_nosc":"biochimique","lemma":"biochimique","pos":"ADJ"} ,
		{"word":"biochimiques","word_nosc":"biochimiques","lemma":"biochimique","pos":"ADJ"} ,
		{"word":"biodégradable","word_nosc":"biodegradable","lemma":"biodégradable","pos":"ADJ"} ,
		{"word":"biodégradables","word_nosc":"biodegradables","lemma":"biodégradable","pos":"ADJ"} ,
		{"word":"biographique","word_nosc":"biographique","lemma":"biographique","pos":"ADJ"} ,
		{"word":"biographiques","word_nosc":"biographiques","lemma":"biographique","pos":"ADJ"} ,
		{"word":"biogénique","word_nosc":"biogenique","lemma":"biogénique","pos":"ADJ"} ,
		{"word":"biogénétique","word_nosc":"biogenetique","lemma":"biogénétique","pos":"ADJ"} ,
		{"word":"biologique","word_nosc":"biologique","lemma":"biologique","pos":"ADJ"} ,
		{"word":"biologiques","word_nosc":"biologiques","lemma":"biologique","pos":"ADJ"} ,
		{"word":"bioluminescente","word_nosc":"bioluminescente","lemma":"bioluminescent","pos":"ADJ"} ,
		{"word":"biomédical","word_nosc":"biomedical","lemma":"biomédical","pos":"ADJ"} ,
		{"word":"biomédicale","word_nosc":"biomedicale","lemma":"biomédical","pos":"ADJ"} ,
		{"word":"biomédicaux","word_nosc":"biomedicaux","lemma":"biomédical","pos":"ADJ"} ,
		{"word":"biométrique","word_nosc":"biometrique","lemma":"biométrique","pos":"ADJ"} ,
		{"word":"biométriques","word_nosc":"biometriques","lemma":"biométrique","pos":"ADJ"} ,
		{"word":"bionique","word_nosc":"bionique","lemma":"bionique","pos":"ADJ"} ,
		{"word":"bioniques","word_nosc":"bioniques","lemma":"bionique","pos":"ADJ"} ,
		{"word":"biosynthétiques","word_nosc":"biosynthetiques","lemma":"biosynthétique","pos":"ADJ"} ,
		{"word":"biotique","word_nosc":"biotique","lemma":"biotique","pos":"ADJ"} ,
		{"word":"bioélectrique","word_nosc":"bioelectrique","lemma":"bioélectrique","pos":"ADJ"} ,
		{"word":"bipartisan","word_nosc":"bipartisan","lemma":"bipartisan","pos":"ADJ"} ,
		{"word":"bipartite","word_nosc":"bipartite","lemma":"bipartite","pos":"ADJ"} ,
		{"word":"biplace","word_nosc":"biplace","lemma":"biplace","pos":"ADJ"} ,
		{"word":"biplaces","word_nosc":"biplaces","lemma":"biplace","pos":"ADJ"} ,
		{"word":"biplan","word_nosc":"biplan","lemma":"biplan","pos":"ADJ"} ,
		{"word":"bipolaire","word_nosc":"bipolaire","lemma":"bipolaire","pos":"ADJ"} ,
		{"word":"bipolaires","word_nosc":"bipolaires","lemma":"bipolaire","pos":"ADJ"} ,
		{"word":"bipède","word_nosc":"bipede","lemma":"bipède","pos":"ADJ"} ,
		{"word":"bipèdes","word_nosc":"bipedes","lemma":"bipède","pos":"ADJ"} ,
		{"word":"biquotidienne","word_nosc":"biquotidienne","lemma":"biquotidien","pos":"ADJ"} ,
		{"word":"biquotidiens","word_nosc":"biquotidiens","lemma":"biquotidien","pos":"ADJ"} ,
		{"word":"birman","word_nosc":"birman","lemma":"birman","pos":"ADJ"} ,
		{"word":"birmane","word_nosc":"birmane","lemma":"birman","pos":"ADJ"} ,
		{"word":"birmanes","word_nosc":"birmanes","lemma":"birman","pos":"ADJ"} ,
		{"word":"birmans","word_nosc":"birmans","lemma":"birman","pos":"ADJ"} ,
		{"word":"bis","word_nosc":"bis","lemma":"bis","pos":"ADJ"} ,
		{"word":"bisannuel","word_nosc":"bisannuel","lemma":"bisannuel","pos":"ADJ"} ,
		{"word":"bisannuelles","word_nosc":"bisannuelles","lemma":"bisannuel","pos":"ADJ"} ,
		{"word":"biscornu","word_nosc":"biscornu","lemma":"biscornu","pos":"ADJ"} ,
		{"word":"biscornue","word_nosc":"biscornue","lemma":"biscornu","pos":"ADJ"} ,
		{"word":"biscornues","word_nosc":"biscornues","lemma":"biscornu","pos":"ADJ"} ,
		{"word":"biscornus","word_nosc":"biscornus","lemma":"biscornu","pos":"ADJ"} ,
		{"word":"bise","word_nosc":"bise","lemma":"bise","pos":"ADJ"} ,
		{"word":"bises","word_nosc":"bises","lemma":"bis","pos":"ADJ"} ,
		{"word":"bisexuel","word_nosc":"bisexuel","lemma":"bisexuel","pos":"ADJ"} ,
		{"word":"bisexuelle","word_nosc":"bisexuelle","lemma":"bisexuel","pos":"ADJ"} ,
		{"word":"bisexuelles","word_nosc":"bisexuelles","lemma":"bisexuel","pos":"ADJ"} ,
		{"word":"bisexuels","word_nosc":"bisexuels","lemma":"bisexuel","pos":"ADJ"} ,
		{"word":"bisexué","word_nosc":"bisexue","lemma":"bisexué","pos":"ADJ"} ,
		{"word":"bisexués","word_nosc":"bisexues","lemma":"bisexué","pos":"ADJ"} ,
		{"word":"bismarckien","word_nosc":"bismarckien","lemma":"bismarckien","pos":"ADJ"} ,
		{"word":"bissextile","word_nosc":"bissextile","lemma":"bissextile","pos":"ADJ"} ,
		{"word":"bissextiles","word_nosc":"bissextiles","lemma":"bissextile","pos":"ADJ"} ,
		{"word":"bistre","word_nosc":"bistre","lemma":"bistre","pos":"ADJ"} ,
		{"word":"bistrée","word_nosc":"bistree","lemma":"bistré","pos":"ADJ"} ,
		{"word":"bistrés","word_nosc":"bistres","lemma":"bistré","pos":"ADJ"} ,
		{"word":"bithynien","word_nosc":"bithynien","lemma":"bithynien","pos":"ADJ"} ,
		{"word":"bithyniens","word_nosc":"bithyniens","lemma":"bithynien","pos":"ADJ"} ,
		{"word":"bitumeuse","word_nosc":"bitumeuse","lemma":"bitumeux","pos":"ADJ"} ,
		{"word":"bitumeux","word_nosc":"bitumeux","lemma":"bitumeux","pos":"ADJ"} ,
		{"word":"bitumineuse","word_nosc":"bitumineuse","lemma":"bitumineux","pos":"ADJ"} ,
		{"word":"bitumineux","word_nosc":"bitumineux","lemma":"bitumineux","pos":"ADJ"} ,
		{"word":"bivalente","word_nosc":"bivalente","lemma":"bivalent","pos":"ADJ"} ,
		{"word":"bivalves","word_nosc":"bivalves","lemma":"bivalve","pos":"ADJ"} ,
		{"word":"bizarde","word_nosc":"bizarde","lemma":"bizarde","pos":"ADJ"} ,
		{"word":"bizarre","word_nosc":"bizarre","lemma":"bizarre","pos":"ADJ"} ,
		{"word":"bizarres","word_nosc":"bizarres","lemma":"bizarre","pos":"ADJ"} ,
		{"word":"bizarroïde","word_nosc":"bizarroide","lemma":"bizarroïde","pos":"ADJ"} ,
		{"word":"bizarroïdes","word_nosc":"bizarroides","lemma":"bizarroïde","pos":"ADJ"} ,
		{"word":"black","word_nosc":"black","lemma":"black","pos":"ADJ"} ,
		{"word":"blacks","word_nosc":"blacks","lemma":"black","pos":"ADJ"} ,
		{"word":"blafard","word_nosc":"blafard","lemma":"blafard","pos":"ADJ"} ,
		{"word":"blafarde","word_nosc":"blafarde","lemma":"blafard","pos":"ADJ"} ,
		{"word":"blafardes","word_nosc":"blafardes","lemma":"blafard","pos":"ADJ"} ,
		{"word":"blafards","word_nosc":"blafards","lemma":"blafard","pos":"ADJ"} ,
		{"word":"blagueur","word_nosc":"blagueur","lemma":"blagueur","pos":"ADJ"} ,
		{"word":"blagueurs","word_nosc":"blagueurs","lemma":"blagueur","pos":"ADJ"} ,
		{"word":"blagueuses","word_nosc":"blagueuses","lemma":"blagueur","pos":"ADJ"} ,
		{"word":"blanc","word_nosc":"blanc","lemma":"blanc","pos":"ADJ"} ,
		{"word":"blanc-gris","word_nosc":"blanc-gris","lemma":"blanc-gris","pos":"ADJ"} ,
		{"word":"blanche","word_nosc":"blanche","lemma":"blanc","pos":"ADJ"} ,
		{"word":"blanches","word_nosc":"blanches","lemma":"blanc","pos":"ADJ"} ,
		{"word":"blanchi","word_nosc":"blanchi","lemma":"blanchi","pos":"ADJ"} ,
		{"word":"blanchie","word_nosc":"blanchie","lemma":"blanchi","pos":"ADJ"} ,
		{"word":"blanchies","word_nosc":"blanchies","lemma":"blanchi","pos":"ADJ"} ,
		{"word":"blanchis","word_nosc":"blanchis","lemma":"blanchi","pos":"ADJ"} ,
		{"word":"blanchissant","word_nosc":"blanchissant","lemma":"blanchissant","pos":"ADJ"} ,
		{"word":"blanchissante","word_nosc":"blanchissante","lemma":"blanchissant","pos":"ADJ"} ,
		{"word":"blanchissantes","word_nosc":"blanchissantes","lemma":"blanchissant","pos":"ADJ"} ,
		{"word":"blanchissants","word_nosc":"blanchissants","lemma":"blanchissant","pos":"ADJ"} ,
		{"word":"blanchâtre","word_nosc":"blanchatre","lemma":"blanchâtre","pos":"ADJ"} ,
		{"word":"blanchâtres","word_nosc":"blanchatres","lemma":"blanchâtre","pos":"ADJ"} ,
		{"word":"blancs","word_nosc":"blancs","lemma":"blanc","pos":"ADJ"} ,
		{"word":"blancs-manteaux","word_nosc":"blancs-manteaux","lemma":"blancs-manteaux","pos":"ADJ"} ,
		{"word":"blasphémateur","word_nosc":"blasphemateur","lemma":"blasphémateur","pos":"ADJ"} ,
		{"word":"blasphémateurs","word_nosc":"blasphemateurs","lemma":"blasphémateur","pos":"ADJ"} ,
		{"word":"blasphématoire","word_nosc":"blasphematoire","lemma":"blasphématoire","pos":"ADJ"} ,
		{"word":"blasphématoires","word_nosc":"blasphematoires","lemma":"blasphématoire","pos":"ADJ"} ,
		{"word":"blasé","word_nosc":"blase","lemma":"blasé","pos":"ADJ"} ,
		{"word":"blasée","word_nosc":"blasee","lemma":"blasé","pos":"ADJ"} ,
		{"word":"blasées","word_nosc":"blasees","lemma":"blasé","pos":"ADJ"} ,
		{"word":"blasés","word_nosc":"blases","lemma":"blasé","pos":"ADJ"} ,
		{"word":"blessant","word_nosc":"blessant","lemma":"blessant","pos":"ADJ"} ,
		{"word":"blessante","word_nosc":"blessante","lemma":"blessant","pos":"ADJ"} ,
		{"word":"blessantes","word_nosc":"blessantes","lemma":"blessant","pos":"ADJ"} ,
		{"word":"blessants","word_nosc":"blessants","lemma":"blessant","pos":"ADJ"} ,
		{"word":"blessé","word_nosc":"blesse","lemma":"blessé","pos":"ADJ"} ,
		{"word":"blessée","word_nosc":"blessee","lemma":"blessé","pos":"ADJ"} ,
		{"word":"blessées","word_nosc":"blessees","lemma":"blessé","pos":"ADJ"} ,
		{"word":"blessés","word_nosc":"blesses","lemma":"blessé","pos":"ADJ"} ,
		{"word":"blet","word_nosc":"blet","lemma":"blet","pos":"ADJ"} ,
		{"word":"blets","word_nosc":"blets","lemma":"blet","pos":"ADJ"} ,
		{"word":"blette","word_nosc":"blette","lemma":"blet","pos":"ADJ"} ,
		{"word":"blettes","word_nosc":"blettes","lemma":"blet","pos":"ADJ"} ,
		{"word":"bleu","word_nosc":"bleu","lemma":"bleu","pos":"ADJ"} ,
		{"word":"bleu-ciel","word_nosc":"bleu-ciel","lemma":"bleu-ciel","pos":"ADJ"} ,
		{"word":"bleu-noir","word_nosc":"bleu-noir","lemma":"bleu-noir","pos":"ADJ"} ,
		{"word":"bleu-vert","word_nosc":"bleu-vert","lemma":"bleu-vert","pos":"ADJ"} ,
		{"word":"bleue","word_nosc":"bleue","lemma":"bleu","pos":"ADJ"} ,
		{"word":"bleues","word_nosc":"bleues","lemma":"bleu","pos":"ADJ"} ,
		{"word":"bleui","word_nosc":"bleui","lemma":"bleui","pos":"ADJ"} ,
		{"word":"bleuie","word_nosc":"bleuie","lemma":"bleui","pos":"ADJ"} ,
		{"word":"bleuies","word_nosc":"bleuies","lemma":"bleui","pos":"ADJ"} ,
		{"word":"bleuis","word_nosc":"bleuis","lemma":"bleui","pos":"ADJ"} ,
		{"word":"bleuissante","word_nosc":"bleuissante","lemma":"bleuissant","pos":"ADJ"} ,
		{"word":"bleuissantes","word_nosc":"bleuissantes","lemma":"bleuissant","pos":"ADJ"} ,
		{"word":"bleus","word_nosc":"bleus","lemma":"bleu","pos":"ADJ"} ,
		{"word":"bleuté","word_nosc":"bleute","lemma":"bleuté","pos":"ADJ"} ,
		{"word":"bleutée","word_nosc":"bleutee","lemma":"bleuté","pos":"ADJ"} ,
		{"word":"bleutées","word_nosc":"bleutees","lemma":"bleuté","pos":"ADJ"} ,
		{"word":"bleutés","word_nosc":"bleutes","lemma":"bleuté","pos":"ADJ"} ,
		{"word":"bleuâtre","word_nosc":"bleuatre","lemma":"bleuâtre","pos":"ADJ"} ,
		{"word":"bleuâtres","word_nosc":"bleuatres","lemma":"bleuâtre","pos":"ADJ"} ,
		{"word":"blindé","word_nosc":"blinde","lemma":"blindé","pos":"ADJ"} ,
		{"word":"blindée","word_nosc":"blindee","lemma":"blindé","pos":"ADJ"} ,
		{"word":"blindées","word_nosc":"blindees","lemma":"blindé","pos":"ADJ"} ,
		{"word":"blindés","word_nosc":"blindes","lemma":"blindé","pos":"ADJ"} ,
		{"word":"blond","word_nosc":"blond","lemma":"blond","pos":"ADJ"} ,
		{"word":"blondasse","word_nosc":"blondasse","lemma":"blondasse","pos":"ADJ"} ,
		{"word":"blondasses","word_nosc":"blondasses","lemma":"blondasse","pos":"ADJ"} ,
		{"word":"blonde","word_nosc":"blonde","lemma":"blond","pos":"ADJ"} ,
		{"word":"blondes","word_nosc":"blondes","lemma":"blond","pos":"ADJ"} ,
		{"word":"blondin","word_nosc":"blondin","lemma":"blondin","pos":"ADJ"} ,
		{"word":"blondine","word_nosc":"blondine","lemma":"blondin","pos":"ADJ"} ,
		{"word":"blondines","word_nosc":"blondines","lemma":"blondin","pos":"ADJ"} ,
		{"word":"blondinet","word_nosc":"blondinet","lemma":"blondinet","pos":"ADJ"} ,
		{"word":"blondinette","word_nosc":"blondinette","lemma":"blondinet","pos":"ADJ"} ,
		{"word":"blonds","word_nosc":"blonds","lemma":"blond","pos":"ADJ"} ,
		{"word":"bloquant","word_nosc":"bloquant","lemma":"bloquant","pos":"ADJ"} ,
		{"word":"bloqueur","word_nosc":"bloqueur","lemma":"bloqueur","pos":"ADJ"} ,
		{"word":"bloqueurs","word_nosc":"bloqueurs","lemma":"bloqueur","pos":"ADJ"} ,
		{"word":"bloqué","word_nosc":"bloque","lemma":"bloqué","pos":"ADJ"} ,
		{"word":"bloquée","word_nosc":"bloquee","lemma":"bloqué","pos":"ADJ"} ,
		{"word":"bloquées","word_nosc":"bloquees","lemma":"bloqué","pos":"ADJ"} ,
		{"word":"bloqués","word_nosc":"bloques","lemma":"bloqué","pos":"ADJ"} ,
		{"word":"blotti","word_nosc":"blotti","lemma":"blotti","pos":"ADJ"} ,
		{"word":"blottie","word_nosc":"blottie","lemma":"blotti","pos":"ADJ"} ,
		{"word":"blotties","word_nosc":"blotties","lemma":"blotti","pos":"ADJ"} ,
		{"word":"blottis","word_nosc":"blottis","lemma":"blotti","pos":"ADJ"} ,
		{"word":"blousantes","word_nosc":"blousantes","lemma":"blousant","pos":"ADJ"} ,
		{"word":"blâmable","word_nosc":"blamable","lemma":"blâmable","pos":"ADJ"} ,
		{"word":"blèche","word_nosc":"bleche","lemma":"blèche","pos":"ADJ"} ,
		{"word":"blèches","word_nosc":"bleches","lemma":"blèche","pos":"ADJ"} ,
		{"word":"blême","word_nosc":"bleme","lemma":"blême","pos":"ADJ"} ,
		{"word":"blêmes","word_nosc":"blemes","lemma":"blême","pos":"ADJ"} ,
		{"word":"blêmissante","word_nosc":"blemissante","lemma":"blêmissant","pos":"ADJ"} ,
		{"word":"bocagères","word_nosc":"bocageres","lemma":"bocager","pos":"ADJ"} ,
		{"word":"boche","word_nosc":"boche","lemma":"boche","pos":"ADJ"} ,
		{"word":"boches","word_nosc":"boches","lemma":"boche","pos":"ADJ"} ,
		{"word":"boeuf","word_nosc":"boeuf","lemma":"boeuf","pos":"ADJ"} ,
		{"word":"bohème","word_nosc":"boheme","lemma":"bohème","pos":"ADJ"} ,
		{"word":"bohèmes","word_nosc":"bohemes","lemma":"bohème","pos":"ADJ"} ,
		{"word":"bohémien","word_nosc":"bohemien","lemma":"bohémien","pos":"ADJ"} ,
		{"word":"bohémienne","word_nosc":"bohemienne","lemma":"bohémien","pos":"ADJ"} ,
		{"word":"bohémiens","word_nosc":"bohemiens","lemma":"bohémien","pos":"ADJ"} ,
		{"word":"boisé","word_nosc":"boise","lemma":"boisé","pos":"ADJ"} ,
		{"word":"boisée","word_nosc":"boisee","lemma":"boisé","pos":"ADJ"} ,
		{"word":"boisées","word_nosc":"boisees","lemma":"boisé","pos":"ADJ"} ,
		{"word":"boisés","word_nosc":"boises","lemma":"boisé","pos":"ADJ"} ,
		{"word":"boiteuse","word_nosc":"boiteuse","lemma":"boiteux","pos":"ADJ"} ,
		{"word":"boiteuses","word_nosc":"boiteuses","lemma":"boiteux","pos":"ADJ"} ,
		{"word":"boiteux","word_nosc":"boiteux","lemma":"boiteux","pos":"ADJ"} ,
		{"word":"boitillant","word_nosc":"boitillant","lemma":"boitillant","pos":"ADJ"} ,
		{"word":"bolchevique","word_nosc":"bolchevique","lemma":"bolchevique","pos":"ADJ"} ,
		{"word":"bolcheviques","word_nosc":"bolcheviques","lemma":"bolchevique","pos":"ADJ"} ,
		{"word":"bolcheviste","word_nosc":"bolcheviste","lemma":"bolcheviste","pos":"ADJ"} ,
		{"word":"bolchevistes","word_nosc":"bolchevistes","lemma":"bolcheviste","pos":"ADJ"} ,
		{"word":"bold","word_nosc":"bold","lemma":"bold","pos":"ADJ"} ,
		{"word":"bolivien","word_nosc":"bolivien","lemma":"bolivien","pos":"ADJ"} ,
		{"word":"bolivienne","word_nosc":"bolivienne","lemma":"bolivien","pos":"ADJ"} ,
		{"word":"boliviens","word_nosc":"boliviens","lemma":"bolivien","pos":"ADJ"} ,
		{"word":"bolognais","word_nosc":"bolognais","lemma":"bolognais","pos":"ADJ"} ,
		{"word":"bolognaise","word_nosc":"bolognaise","lemma":"bolognais","pos":"ADJ"} ,
		{"word":"bolognaises","word_nosc":"bolognaises","lemma":"bolognais","pos":"ADJ"} ,
		{"word":"bolonaise","word_nosc":"bolonaise","lemma":"bolonais","pos":"ADJ"} ,
		{"word":"bombardé","word_nosc":"bombarde","lemma":"bombardé","pos":"ADJ"} ,
		{"word":"bombardée","word_nosc":"bombardee","lemma":"bombardé","pos":"ADJ"} ,
		{"word":"bombardées","word_nosc":"bombardees","lemma":"bombardé","pos":"ADJ"} ,
		{"word":"bombardés","word_nosc":"bombardes","lemma":"bombardé","pos":"ADJ"} ,
		{"word":"bombé","word_nosc":"bombe","lemma":"bombé","pos":"ADJ"} ,
		{"word":"bombée","word_nosc":"bombee","lemma":"bombé","pos":"ADJ"} ,
		{"word":"bombées","word_nosc":"bombees","lemma":"bombé","pos":"ADJ"} ,
		{"word":"bombés","word_nosc":"bombes","lemma":"bombé","pos":"ADJ"} ,
		{"word":"bon","word_nosc":"bon","lemma":"bon","pos":"ADJ"} ,
		{"word":"bonard","word_nosc":"bonard","lemma":"bonard","pos":"ADJ"} ,
		{"word":"bonardes","word_nosc":"bonardes","lemma":"bonard","pos":"ADJ"} ,
		{"word":"bonasse","word_nosc":"bonasse","lemma":"bonasse","pos":"ADJ"} ,
		{"word":"bonasses","word_nosc":"bonasses","lemma":"bonasse","pos":"ADJ"} ,
		{"word":"bondissant","word_nosc":"bondissant","lemma":"bondissant","pos":"ADJ"} ,
		{"word":"bondissante","word_nosc":"bondissante","lemma":"bondissant","pos":"ADJ"} ,
		{"word":"bondissantes","word_nosc":"bondissantes","lemma":"bondissant","pos":"ADJ"} ,
		{"word":"bondissants","word_nosc":"bondissants","lemma":"bondissant","pos":"ADJ"} ,
		{"word":"bondé","word_nosc":"bonde","lemma":"bondé","pos":"ADJ"} ,
		{"word":"bondée","word_nosc":"bondee","lemma":"bondé","pos":"ADJ"} ,
		{"word":"bondées","word_nosc":"bondees","lemma":"bondé","pos":"ADJ"} ,
		{"word":"bondés","word_nosc":"bondes","lemma":"bondé","pos":"ADJ"} ,
		{"word":"bonhomme","word_nosc":"bonhomme","lemma":"bonhomme","pos":"ADJ"} ,
		{"word":"bonhommes","word_nosc":"bonhommes","lemma":"bonhomme","pos":"ADJ"} ,
		{"word":"bonnard","word_nosc":"bonnard","lemma":"bonnard","pos":"ADJ"} ,
		{"word":"bonnarde","word_nosc":"bonnarde","lemma":"bonnard","pos":"ADJ"} ,
		{"word":"bonnardes","word_nosc":"bonnardes","lemma":"bonnard","pos":"ADJ"} ,
		{"word":"bonnards","word_nosc":"bonnards","lemma":"bonnard","pos":"ADJ"} ,
		{"word":"bonne","word_nosc":"bonne","lemma":"bon","pos":"ADJ"} ,
		{"word":"bonnes","word_nosc":"bonnes","lemma":"bon","pos":"ADJ"} ,
		{"word":"bons","word_nosc":"bons","lemma":"bon","pos":"ADJ"} ,
		{"word":"bordeaux","word_nosc":"bordeaux","lemma":"bordeaux","pos":"ADJ"} ,
		{"word":"bordelais","word_nosc":"bordelais","lemma":"bordelais","pos":"ADJ"} ,
		{"word":"bordelaise","word_nosc":"bordelaise","lemma":"bordelais","pos":"ADJ"} ,
		{"word":"bordier","word_nosc":"bordier","lemma":"bordier","pos":"ADJ"} ,
		{"word":"bordé","word_nosc":"borde","lemma":"bordé","pos":"ADJ"} ,
		{"word":"bordée","word_nosc":"bordee","lemma":"bordée","pos":"ADJ"} ,
		{"word":"bordées","word_nosc":"bordees","lemma":"bordée","pos":"ADJ"} ,
		{"word":"bordélique","word_nosc":"bordelique","lemma":"bordélique","pos":"ADJ"} ,
		{"word":"bordéliques","word_nosc":"bordeliques","lemma":"bordélique","pos":"ADJ"} ,
		{"word":"bordés","word_nosc":"bordes","lemma":"bordé","pos":"ADJ"} ,
		{"word":"borgne","word_nosc":"borgne","lemma":"borgne","pos":"ADJ"} ,
		{"word":"borgnes","word_nosc":"borgnes","lemma":"borgne","pos":"ADJ"} ,
		{"word":"borique","word_nosc":"borique","lemma":"borique","pos":"ADJ"} ,
		{"word":"borné","word_nosc":"borne","lemma":"borné","pos":"ADJ"} ,
		{"word":"bornée","word_nosc":"bornee","lemma":"borné","pos":"ADJ"} ,
		{"word":"bornées","word_nosc":"bornees","lemma":"borné","pos":"ADJ"} ,
		{"word":"bornés","word_nosc":"bornes","lemma":"borné","pos":"ADJ"} ,
		{"word":"boréal","word_nosc":"boreal","lemma":"boréal","pos":"ADJ"} ,
		{"word":"boréale","word_nosc":"boreale","lemma":"boréal","pos":"ADJ"} ,
		{"word":"boréales","word_nosc":"boreales","lemma":"boréal","pos":"ADJ"} ,
		{"word":"boscotte","word_nosc":"boscotte","lemma":"boscot","pos":"ADJ"} ,
		{"word":"bosniaque","word_nosc":"bosniaque","lemma":"bosniaque","pos":"ADJ"} ,
		{"word":"bosniaques","word_nosc":"bosniaques","lemma":"bosniaque","pos":"ADJ"} ,
		{"word":"bosnien","word_nosc":"bosnien","lemma":"bosnien","pos":"ADJ"} ,
		{"word":"bosselé","word_nosc":"bossele","lemma":"bosselé","pos":"ADJ"} ,
		{"word":"bosselée","word_nosc":"bosselee","lemma":"bosselé","pos":"ADJ"} ,
		{"word":"bosselées","word_nosc":"bosselees","lemma":"bosselé","pos":"ADJ"} ,
		{"word":"bosselés","word_nosc":"bosseles","lemma":"bosselé","pos":"ADJ"} ,
		{"word":"bosseur","word_nosc":"bosseur","lemma":"bosseur","pos":"ADJ"} ,
		{"word":"bosseurs","word_nosc":"bosseurs","lemma":"bosseur","pos":"ADJ"} ,
		{"word":"bosseuse","word_nosc":"bosseuse","lemma":"bosseur","pos":"ADJ"} ,
		{"word":"bossu","word_nosc":"bossu","lemma":"bossu","pos":"ADJ"} ,
		{"word":"bossue","word_nosc":"bossue","lemma":"bossu","pos":"ADJ"} ,
		{"word":"bossues","word_nosc":"bossues","lemma":"bossu","pos":"ADJ"} ,
		{"word":"bossus","word_nosc":"bossus","lemma":"bossu","pos":"ADJ"} ,
		{"word":"bossué","word_nosc":"bossue","lemma":"bossué","pos":"ADJ"} ,
		{"word":"bossuée","word_nosc":"bossuee","lemma":"bossué","pos":"ADJ"} ,
		{"word":"bossués","word_nosc":"bossues","lemma":"bossué","pos":"ADJ"} ,
		{"word":"bostonien","word_nosc":"bostonien","lemma":"bostonien","pos":"ADJ"} ,
		{"word":"bostonienne","word_nosc":"bostonienne","lemma":"bostonien","pos":"ADJ"} ,
		{"word":"bostoniens","word_nosc":"bostoniens","lemma":"bostonien","pos":"ADJ"} ,
		{"word":"bot","word_nosc":"bot","lemma":"bot","pos":"ADJ"} ,
		{"word":"botanique","word_nosc":"botanique","lemma":"botanique","pos":"ADJ"} ,
		{"word":"botaniques","word_nosc":"botaniques","lemma":"botanique","pos":"ADJ"} ,
		{"word":"bote","word_nosc":"bote","lemma":"bot","pos":"ADJ"} ,
		{"word":"bottelé","word_nosc":"bottele","lemma":"bottelé","pos":"ADJ"} ,
		{"word":"botté","word_nosc":"botte","lemma":"botté","pos":"ADJ"} ,
		{"word":"bottée","word_nosc":"bottee","lemma":"botté","pos":"ADJ"} ,
		{"word":"bottées","word_nosc":"bottees","lemma":"botté","pos":"ADJ"} ,
		{"word":"bottés","word_nosc":"bottes","lemma":"botté","pos":"ADJ"} ,
		{"word":"botulique","word_nosc":"botulique","lemma":"botulique","pos":"ADJ"} ,
		{"word":"bouchonné","word_nosc":"bouchonne","lemma":"bouchonné","pos":"ADJ"} ,
		{"word":"bouché","word_nosc":"bouche","lemma":"bouché","pos":"ADJ"} ,
		{"word":"bouchée","word_nosc":"bouchee","lemma":"bouché","pos":"ADJ"} ,
		{"word":"bouchées","word_nosc":"bouchees","lemma":"bouché","pos":"ADJ"} ,
		{"word":"bouchés","word_nosc":"bouches","lemma":"bouché","pos":"ADJ"} ,
		{"word":"bouclé","word_nosc":"boucle","lemma":"bouclé","pos":"ADJ"} ,
		{"word":"bouclée","word_nosc":"bouclee","lemma":"bouclé","pos":"ADJ"} ,
		{"word":"bouclées","word_nosc":"bouclees","lemma":"bouclé","pos":"ADJ"} ,
		{"word":"bouclés","word_nosc":"boucles","lemma":"bouclé","pos":"ADJ"} ,
		{"word":"bouddhique","word_nosc":"bouddhique","lemma":"bouddhique","pos":"ADJ"} ,
		{"word":"bouddhiques","word_nosc":"bouddhiques","lemma":"bouddhique","pos":"ADJ"} ,
		{"word":"bouddhiste","word_nosc":"bouddhiste","lemma":"bouddhiste","pos":"ADJ"} ,
		{"word":"bouddhistes","word_nosc":"bouddhistes","lemma":"bouddhiste","pos":"ADJ"} ,
		{"word":"boudeur","word_nosc":"boudeur","lemma":"boudeur","pos":"ADJ"} ,
		{"word":"boudeurs","word_nosc":"boudeurs","lemma":"boudeur","pos":"ADJ"} ,
		{"word":"boudeuse","word_nosc":"boudeuse","lemma":"boudeur","pos":"ADJ"} ,
		{"word":"boudeuses","word_nosc":"boudeuses","lemma":"boudeur","pos":"ADJ"} ,
		{"word":"boudiné","word_nosc":"boudine","lemma":"boudiné","pos":"ADJ"} ,
		{"word":"boudinée","word_nosc":"boudinee","lemma":"boudiné","pos":"ADJ"} ,
		{"word":"boudinées","word_nosc":"boudinees","lemma":"boudiné","pos":"ADJ"} ,
		{"word":"boudinés","word_nosc":"boudines","lemma":"boudiné","pos":"ADJ"} ,
		{"word":"boueuse","word_nosc":"boueuse","lemma":"boueux","pos":"ADJ"} ,
		{"word":"boueuses","word_nosc":"boueuses","lemma":"boueux","pos":"ADJ"} ,
		{"word":"boueux","word_nosc":"boueux","lemma":"boueux","pos":"ADJ"} ,
		{"word":"bouffable","word_nosc":"bouffable","lemma":"bouffable","pos":"ADJ"} ,
		{"word":"bouffant","word_nosc":"bouffant","lemma":"bouffant","pos":"ADJ"} ,
		{"word":"bouffante","word_nosc":"bouffante","lemma":"bouffant","pos":"ADJ"} ,
		{"word":"bouffantes","word_nosc":"bouffantes","lemma":"bouffant","pos":"ADJ"} ,
		{"word":"bouffants","word_nosc":"bouffants","lemma":"bouffant","pos":"ADJ"} ,
		{"word":"bouffe","word_nosc":"bouffe","lemma":"bouffe","pos":"ADJ"} ,
		{"word":"bouffes","word_nosc":"bouffes","lemma":"bouffe","pos":"ADJ"} ,
		{"word":"bouffeur","word_nosc":"bouffeur","lemma":"bouffeur","pos":"ADJ"} ,
		{"word":"bouffeurs","word_nosc":"bouffeurs","lemma":"bouffeur","pos":"ADJ"} ,
		{"word":"bouffeuse","word_nosc":"bouffeuse","lemma":"bouffeur","pos":"ADJ"} ,
		{"word":"bouffeuses","word_nosc":"bouffeuses","lemma":"bouffeur","pos":"ADJ"} ,
		{"word":"bouffi","word_nosc":"bouffi","lemma":"bouffi","pos":"ADJ"} ,
		{"word":"bouffie","word_nosc":"bouffie","lemma":"bouffi","pos":"ADJ"} ,
		{"word":"bouffies","word_nosc":"bouffies","lemma":"bouffi","pos":"ADJ"} ,
		{"word":"bouffis","word_nosc":"bouffis","lemma":"bouffi","pos":"ADJ"} ,
		{"word":"bouffon","word_nosc":"bouffon","lemma":"bouffon","pos":"ADJ"} ,
		{"word":"bouffonnant","word_nosc":"bouffonnant","lemma":"bouffonnant","pos":"ADJ"} ,
		{"word":"bouffonnante","word_nosc":"bouffonnante","lemma":"bouffonnant","pos":"ADJ"} ,
		{"word":"bouffonne","word_nosc":"bouffonne","lemma":"bouffon","pos":"ADJ"} ,
		{"word":"bouffonnes","word_nosc":"bouffonnes","lemma":"bouffon","pos":"ADJ"} ,
		{"word":"bouffons","word_nosc":"bouffons","lemma":"bouffon","pos":"ADJ"} ,
		{"word":"bougon","word_nosc":"bougon","lemma":"bougon","pos":"ADJ"} ,
		{"word":"bougonne","word_nosc":"bougonne","lemma":"bougon","pos":"ADJ"} ,
		{"word":"bougons","word_nosc":"bougons","lemma":"bougon","pos":"ADJ"} ,
		{"word":"bougé","word_nosc":"bouge","lemma":"bougé","pos":"ADJ"} ,
		{"word":"bouillant","word_nosc":"bouillant","lemma":"bouillant","pos":"ADJ"} ,
		{"word":"bouillante","word_nosc":"bouillante","lemma":"bouillant","pos":"ADJ"} ,
		{"word":"bouillantes","word_nosc":"bouillantes","lemma":"bouillant","pos":"ADJ"} ,
		{"word":"bouillants","word_nosc":"bouillants","lemma":"bouillant","pos":"ADJ"} ,
		{"word":"bouilli","word_nosc":"bouilli","lemma":"bouilli","pos":"ADJ"} ,
		{"word":"bouillie","word_nosc":"bouillie","lemma":"bouilli","pos":"ADJ"} ,
		{"word":"bouillies","word_nosc":"bouillies","lemma":"bouilli","pos":"ADJ"} ,
		{"word":"bouillis","word_nosc":"bouillis","lemma":"bouilli","pos":"ADJ"} ,
		{"word":"bouillonnant","word_nosc":"bouillonnant","lemma":"bouillonnant","pos":"ADJ"} ,
		{"word":"bouillonnante","word_nosc":"bouillonnante","lemma":"bouillonnant","pos":"ADJ"} ,
		{"word":"bouillonnantes","word_nosc":"bouillonnantes","lemma":"bouillonnant","pos":"ADJ"} ,
		{"word":"bouillonnants","word_nosc":"bouillonnants","lemma":"bouillonnant","pos":"ADJ"} ,
		{"word":"bouillonneux","word_nosc":"bouillonneux","lemma":"bouillonneux","pos":"ADJ"} ,
		{"word":"bouillu","word_nosc":"bouillu","lemma":"bouillu","pos":"ADJ"} ,
		{"word":"boulevardier","word_nosc":"boulevardier","lemma":"boulevardier","pos":"ADJ"} ,
		{"word":"boulevardière","word_nosc":"boulevardiere","lemma":"boulevardier","pos":"ADJ"} ,
		{"word":"bouleversant","word_nosc":"bouleversant","lemma":"bouleversant","pos":"ADJ"} ,
		{"word":"bouleversante","word_nosc":"bouleversante","lemma":"bouleversant","pos":"ADJ"} ,
		{"word":"bouleversantes","word_nosc":"bouleversantes","lemma":"bouleversant","pos":"ADJ"} ,
		{"word":"bouleversants","word_nosc":"bouleversants","lemma":"bouleversant","pos":"ADJ"} ,
		{"word":"bouleversé","word_nosc":"bouleverse","lemma":"bouleversé","pos":"ADJ"} ,
		{"word":"bouleversée","word_nosc":"bouleversee","lemma":"bouleversé","pos":"ADJ"} ,
		{"word":"bouleversées","word_nosc":"bouleversees","lemma":"bouleversé","pos":"ADJ"} ,
		{"word":"bouleversés","word_nosc":"bouleverses","lemma":"bouleversé","pos":"ADJ"} ,
		{"word":"boulimique","word_nosc":"boulimique","lemma":"boulimique","pos":"ADJ"} ,
		{"word":"boulimiques","word_nosc":"boulimiques","lemma":"boulimique","pos":"ADJ"} ,
		{"word":"boulonnais","word_nosc":"boulonnais","lemma":"boulonnais","pos":"ADJ"} ,
		{"word":"boulot","word_nosc":"boulot","lemma":"boulot","pos":"ADJ"} ,
		{"word":"boulot-refuge","word_nosc":"boulot-refuge","lemma":"boulot-refuge","pos":"ADJ"} ,
		{"word":"boulots","word_nosc":"boulots","lemma":"boulot","pos":"ADJ"} ,
		{"word":"boulotte","word_nosc":"boulotte","lemma":"boulot","pos":"ADJ"} ,
		{"word":"boulottes","word_nosc":"boulottes","lemma":"boulot","pos":"ADJ"} ,
		{"word":"boulu","word_nosc":"boulu","lemma":"boulu","pos":"ADJ"} ,
		{"word":"boulus","word_nosc":"boulus","lemma":"boulu","pos":"ADJ"} ,
		{"word":"bouqueté","word_nosc":"bouquete","lemma":"bouqueté","pos":"ADJ"} ,
		{"word":"bourbeuse","word_nosc":"bourbeuse","lemma":"bourbeux","pos":"ADJ"} ,
		{"word":"bourbeuses","word_nosc":"bourbeuses","lemma":"bourbeux","pos":"ADJ"} ,
		{"word":"bourbeux","word_nosc":"bourbeux","lemma":"bourbeux","pos":"ADJ"} ,
		{"word":"bourbonien","word_nosc":"bourbonien","lemma":"bourbonien","pos":"ADJ"} ,
		{"word":"bourbonienne","word_nosc":"bourbonienne","lemma":"bourbonien","pos":"ADJ"} ,
		{"word":"bourdonnant","word_nosc":"bourdonnant","lemma":"bourdonnant","pos":"ADJ"} ,
		{"word":"bourdonnante","word_nosc":"bourdonnante","lemma":"bourdonnant","pos":"ADJ"} ,
		{"word":"bourdonnantes","word_nosc":"bourdonnantes","lemma":"bourdonnant","pos":"ADJ"} ,
		{"word":"bourdonnants","word_nosc":"bourdonnants","lemma":"bourdonnant","pos":"ADJ"} ,
		{"word":"bourge","word_nosc":"bourge","lemma":"bourge","pos":"ADJ"} ,
		{"word":"bourgeois","word_nosc":"bourgeois","lemma":"bourgeois","pos":"ADJ"} ,
		{"word":"bourgeoise","word_nosc":"bourgeoise","lemma":"bourgeois","pos":"ADJ"} ,
		{"word":"bourgeoises","word_nosc":"bourgeoises","lemma":"bourgeois","pos":"ADJ"} ,
		{"word":"bourgeonnant","word_nosc":"bourgeonnant","lemma":"bourgeonnant","pos":"ADJ"} ,
		{"word":"bourgeonnante","word_nosc":"bourgeonnante","lemma":"bourgeonnant","pos":"ADJ"} ,
		{"word":"bourgeonnants","word_nosc":"bourgeonnants","lemma":"bourgeonnant","pos":"ADJ"} ,
		{"word":"bourgeonneuse","word_nosc":"bourgeonneuse","lemma":"bourgeonneux","pos":"ADJ"} ,
		{"word":"bourges","word_nosc":"bourges","lemma":"bourge","pos":"ADJ"} ,
		{"word":"bourguignon","word_nosc":"bourguignon","lemma":"bourguignon","pos":"ADJ"} ,
		{"word":"bourguignonne","word_nosc":"bourguignonne","lemma":"bourguignon","pos":"ADJ"} ,
		{"word":"bourguignonnes","word_nosc":"bourguignonnes","lemma":"bourguignon","pos":"ADJ"} ,
		{"word":"bourguignons","word_nosc":"bourguignons","lemma":"bourguignon","pos":"ADJ"} ,
		{"word":"bouriates","word_nosc":"bouriates","lemma":"bouriate","pos":"ADJ"} ,
		{"word":"bourratif","word_nosc":"bourratif","lemma":"bourratif","pos":"ADJ"} ,
		{"word":"bourrative","word_nosc":"bourrative","lemma":"bourratif","pos":"ADJ"} ,
		{"word":"bourratives","word_nosc":"bourratives","lemma":"bourratif","pos":"ADJ"} ,
		{"word":"bourru","word_nosc":"bourru","lemma":"bourru","pos":"ADJ"} ,
		{"word":"bourrue","word_nosc":"bourrue","lemma":"bourru","pos":"ADJ"} ,
		{"word":"bourrues","word_nosc":"bourrues","lemma":"bourru","pos":"ADJ"} ,
		{"word":"bourrus","word_nosc":"bourrus","lemma":"bourru","pos":"ADJ"} ,
		{"word":"bourré","word_nosc":"bourre","lemma":"bourré","pos":"ADJ"} ,
		{"word":"bourrée","word_nosc":"bourree","lemma":"bourré","pos":"ADJ"} ,
		{"word":"bourrées","word_nosc":"bourrees","lemma":"bourré","pos":"ADJ"} ,
		{"word":"bourrés","word_nosc":"bourres","lemma":"bourré","pos":"ADJ"} ,
		{"word":"boursier","word_nosc":"boursier","lemma":"boursier","pos":"ADJ"} ,
		{"word":"boursiers","word_nosc":"boursiers","lemma":"boursier","pos":"ADJ"} ,
		{"word":"boursière","word_nosc":"boursiere","lemma":"boursier","pos":"ADJ"} ,
		{"word":"boursières","word_nosc":"boursieres","lemma":"boursier","pos":"ADJ"} ,
		{"word":"boursouflé","word_nosc":"boursoufle","lemma":"boursouflé","pos":"ADJ"} ,
		{"word":"boursouflée","word_nosc":"boursouflee","lemma":"boursouflé","pos":"ADJ"} ,
		{"word":"boursouflées","word_nosc":"boursouflees","lemma":"boursouflé","pos":"ADJ"} ,
		{"word":"boursouflés","word_nosc":"boursoufles","lemma":"boursouflé","pos":"ADJ"} ,
		{"word":"bousillé","word_nosc":"bousille","lemma":"bousillé","pos":"ADJ"} ,
		{"word":"bousillée","word_nosc":"bousillee","lemma":"bousillé","pos":"ADJ"} ,
		{"word":"bousillées","word_nosc":"bousillees","lemma":"bousillé","pos":"ADJ"} ,
		{"word":"bousillés","word_nosc":"bousilles","lemma":"bousillé","pos":"ADJ"} ,
		{"word":"boutiquier","word_nosc":"boutiquier","lemma":"boutiquier","pos":"ADJ"} ,
		{"word":"boutiquiers","word_nosc":"boutiquiers","lemma":"boutiquier","pos":"ADJ"} ,
		{"word":"boutiquières","word_nosc":"boutiquieres","lemma":"boutiquier","pos":"ADJ"} ,
		{"word":"boutonnant","word_nosc":"boutonnant","lemma":"boutonnant","pos":"ADJ"} ,
		{"word":"boutonneuse","word_nosc":"boutonneuse","lemma":"boutonneux","pos":"ADJ"} ,
		{"word":"boutonneuses","word_nosc":"boutonneuses","lemma":"boutonneux","pos":"ADJ"} ,
		{"word":"boutonneux","word_nosc":"boutonneux","lemma":"boutonneux","pos":"ADJ"} ,
		{"word":"boutonné","word_nosc":"boutonne","lemma":"boutonné","pos":"ADJ"} ,
		{"word":"boutonnée","word_nosc":"boutonnee","lemma":"boutonné","pos":"ADJ"} ,
		{"word":"boutonnées","word_nosc":"boutonnees","lemma":"boutonné","pos":"ADJ"} ,
		{"word":"boutonnés","word_nosc":"boutonnes","lemma":"boutonné","pos":"ADJ"} ,
		{"word":"bovin","word_nosc":"bovin","lemma":"bovin","pos":"ADJ"} ,
		{"word":"bovine","word_nosc":"bovine","lemma":"bovin","pos":"ADJ"} ,
		{"word":"bovines","word_nosc":"bovines","lemma":"bovin","pos":"ADJ"} ,
		{"word":"bovins","word_nosc":"bovins","lemma":"bovin","pos":"ADJ"} ,
		{"word":"boy-scoutesque","word_nosc":"boy-scoutesque","lemma":"boy-scoutesque","pos":"ADJ"} ,
		{"word":"brabançon","word_nosc":"brabancon","lemma":"brabançon","pos":"ADJ"} ,
		{"word":"brabançonne","word_nosc":"brabanconne","lemma":"brabançon","pos":"ADJ"} ,
		{"word":"brachial","word_nosc":"brachial","lemma":"brachial","pos":"ADJ"} ,
		{"word":"brachiale","word_nosc":"brachiale","lemma":"brachial","pos":"ADJ"} ,
		{"word":"brachiocéphalique","word_nosc":"brachiocephalique","lemma":"brachiocéphalique","pos":"ADJ"} ,
		{"word":"brachycéphales","word_nosc":"brachycephales","lemma":"brachycéphale","pos":"ADJ"} ,
		{"word":"brahmanique","word_nosc":"brahmanique","lemma":"brahmanique","pos":"ADJ"} ,
		{"word":"brahmanistes","word_nosc":"brahmanistes","lemma":"brahmaniste","pos":"ADJ"} ,
		{"word":"braillard","word_nosc":"braillard","lemma":"braillard","pos":"ADJ"} ,
		{"word":"braillarde","word_nosc":"braillarde","lemma":"braillard","pos":"ADJ"} ,
		{"word":"braillardes","word_nosc":"braillardes","lemma":"braillard","pos":"ADJ"} ,
		{"word":"braillards","word_nosc":"braillards","lemma":"braillard","pos":"ADJ"} ,
		{"word":"brailleur","word_nosc":"brailleur","lemma":"brailleur","pos":"ADJ"} ,
		{"word":"brailleurs","word_nosc":"brailleurs","lemma":"brailleur","pos":"ADJ"} ,
		{"word":"braisillant","word_nosc":"braisillant","lemma":"braisillant","pos":"ADJ"} ,
		{"word":"braisillante","word_nosc":"braisillante","lemma":"braisillant","pos":"ADJ"} ,
		{"word":"braisé","word_nosc":"braise","lemma":"braisé","pos":"ADJ"} ,
		{"word":"braisée","word_nosc":"braisee","lemma":"braisé","pos":"ADJ"} ,
		{"word":"braisés","word_nosc":"braises","lemma":"braisé","pos":"ADJ"} ,
		{"word":"branchiale","word_nosc":"branchiale","lemma":"branchial","pos":"ADJ"} ,
		{"word":"branchu","word_nosc":"branchu","lemma":"branchu","pos":"ADJ"} ,
		{"word":"branchue","word_nosc":"branchue","lemma":"branchu","pos":"ADJ"} ,
		{"word":"branchus","word_nosc":"branchus","lemma":"branchu","pos":"ADJ"} ,
		{"word":"branché","word_nosc":"branche","lemma":"branché","pos":"ADJ"} ,
		{"word":"branchée","word_nosc":"branchee","lemma":"branché","pos":"ADJ"} ,
		{"word":"branchées","word_nosc":"branchees","lemma":"branché","pos":"ADJ"} ,
		{"word":"branchés","word_nosc":"branches","lemma":"branché","pos":"ADJ"} ,
		{"word":"brandebourgeois","word_nosc":"brandebourgeois","lemma":"brandebourgeois","pos":"ADJ"} ,
		{"word":"branlant","word_nosc":"branlant","lemma":"branlant","pos":"ADJ"} ,
		{"word":"branlante","word_nosc":"branlante","lemma":"branlant","pos":"ADJ"} ,
		{"word":"branlantes","word_nosc":"branlantes","lemma":"branlant","pos":"ADJ"} ,
		{"word":"branlants","word_nosc":"branlants","lemma":"branlant","pos":"ADJ"} ,
		{"word":"branlée","word_nosc":"branlee","lemma":"branlée","pos":"ADJ"} ,
		{"word":"branque","word_nosc":"branque","lemma":"branque","pos":"ADJ"} ,
		{"word":"branques","word_nosc":"branques","lemma":"branque","pos":"ADJ"} ,
		{"word":"braque","word_nosc":"braque","lemma":"braque","pos":"ADJ"} ,
		{"word":"brassicourt","word_nosc":"brassicourt","lemma":"brassicourt","pos":"ADJ"} ,
		{"word":"bravache","word_nosc":"bravache","lemma":"bravache","pos":"ADJ"} ,
		{"word":"bravaches","word_nosc":"bravaches","lemma":"bravache","pos":"ADJ"} ,
		{"word":"brave","word_nosc":"brave","lemma":"brave","pos":"ADJ"} ,
		{"word":"braves","word_nosc":"braves","lemma":"brave","pos":"ADJ"} ,
		{"word":"bredouillant","word_nosc":"bredouillant","lemma":"bredouillant","pos":"ADJ"} ,
		{"word":"bredouillante","word_nosc":"bredouillante","lemma":"bredouillant","pos":"ADJ"} ,
		{"word":"bredouille","word_nosc":"bredouille","lemma":"bredouille","pos":"ADJ"} ,
		{"word":"bredouilles","word_nosc":"bredouilles","lemma":"bredouille","pos":"ADJ"} ,
		{"word":"bref","word_nosc":"bref","lemma":"bref","pos":"ADJ"} ,
		{"word":"brefs","word_nosc":"brefs","lemma":"bref","pos":"ADJ"} ,
		{"word":"breneuses","word_nosc":"breneuses","lemma":"breneux","pos":"ADJ"} ,
		{"word":"breneux","word_nosc":"breneux","lemma":"breneux","pos":"ADJ"} ,
		{"word":"bressan","word_nosc":"bressan","lemma":"bressan","pos":"ADJ"} ,
		{"word":"bressane","word_nosc":"bressane","lemma":"bressan","pos":"ADJ"} ,
		{"word":"bressanes","word_nosc":"bressanes","lemma":"bressan","pos":"ADJ"} ,
		{"word":"bressans","word_nosc":"bressans","lemma":"bressan","pos":"ADJ"} ,
		{"word":"brestois","word_nosc":"brestois","lemma":"brestois","pos":"ADJ"} ,
		{"word":"brestoise","word_nosc":"brestoise","lemma":"brestois","pos":"ADJ"} ,
		{"word":"breton","word_nosc":"breton","lemma":"breton","pos":"ADJ"} ,
		{"word":"bretonnant","word_nosc":"bretonnant","lemma":"bretonnant","pos":"ADJ"} ,
		{"word":"bretonnants","word_nosc":"bretonnants","lemma":"bretonnant","pos":"ADJ"} ,
		{"word":"bretonne","word_nosc":"bretonne","lemma":"breton","pos":"ADJ"} ,
		{"word":"bretonnes","word_nosc":"bretonnes","lemma":"breton","pos":"ADJ"} ,
		{"word":"bretons","word_nosc":"bretons","lemma":"breton","pos":"ADJ"} ,
		{"word":"breveté","word_nosc":"brevete","lemma":"breveté","pos":"ADJ"} ,
		{"word":"brevetée","word_nosc":"brevetee","lemma":"breveté","pos":"ADJ"} ,
		{"word":"brevetées","word_nosc":"brevetees","lemma":"breveté","pos":"ADJ"} ,
		{"word":"brevetés","word_nosc":"brevetes","lemma":"breveté","pos":"ADJ"} ,
		{"word":"briard","word_nosc":"briard","lemma":"briard","pos":"ADJ"} ,
		{"word":"briarde","word_nosc":"briarde","lemma":"briard","pos":"ADJ"} ,
		{"word":"bricoleur","word_nosc":"bricoleur","lemma":"bricoleur","pos":"ADJ"} ,
		{"word":"bricoleurs","word_nosc":"bricoleurs","lemma":"bricoleur","pos":"ADJ"} ,
		{"word":"bridé","word_nosc":"bride","lemma":"bridé","pos":"ADJ"} ,
		{"word":"bridée","word_nosc":"bridee","lemma":"bridé","pos":"ADJ"} ,
		{"word":"bridées","word_nosc":"bridees","lemma":"bridé","pos":"ADJ"} ,
		{"word":"bridés","word_nosc":"brides","lemma":"bridé","pos":"ADJ"} ,
		{"word":"brigadiste","word_nosc":"brigadiste","lemma":"brigadiste","pos":"ADJ"} ,
		{"word":"brillant","word_nosc":"brillant","lemma":"brillant","pos":"ADJ"} ,
		{"word":"brillante","word_nosc":"brillante","lemma":"brillant","pos":"ADJ"} ,
		{"word":"brillantes","word_nosc":"brillantes","lemma":"brillant","pos":"ADJ"} ,
		{"word":"brillants","word_nosc":"brillants","lemma":"brillant","pos":"ADJ"} ,
		{"word":"brindezingue","word_nosc":"brindezingue","lemma":"brindezingue","pos":"ADJ"} ,
		{"word":"bringuebalant","word_nosc":"bringuebalant","lemma":"bringuebalant","pos":"ADJ"} ,
		{"word":"bringuebalante","word_nosc":"bringuebalante","lemma":"bringuebalant","pos":"ADJ"} ,
		{"word":"bringuebalantes","word_nosc":"bringuebalantes","lemma":"bringuebalant","pos":"ADJ"} ,
		{"word":"bringuebalants","word_nosc":"bringuebalants","lemma":"bringuebalant","pos":"ADJ"} ,
		{"word":"brinquebalants","word_nosc":"brinquebalants","lemma":"brinquebalant","pos":"ADJ"} ,
		{"word":"brioché","word_nosc":"brioche","lemma":"brioché","pos":"ADJ"} ,
		{"word":"briochée","word_nosc":"briochee","lemma":"brioché","pos":"ADJ"} ,
		{"word":"briochés","word_nosc":"brioches","lemma":"brioché","pos":"ADJ"} ,
		{"word":"brique","word_nosc":"brique","lemma":"brique","pos":"ADJ"} ,
		{"word":"briquetées","word_nosc":"briquetees","lemma":"briqueté","pos":"ADJ"} ,
		{"word":"brisant","word_nosc":"brisant","lemma":"brisant","pos":"ADJ"} ,
		{"word":"brisante","word_nosc":"brisante","lemma":"brisant","pos":"ADJ"} ,
		{"word":"brisantes","word_nosc":"brisantes","lemma":"brisant","pos":"ADJ"} ,
		{"word":"brisants","word_nosc":"brisants","lemma":"brisant","pos":"ADJ"} ,
		{"word":"brisé","word_nosc":"brise","lemma":"brisé","pos":"ADJ"} ,
		{"word":"brisée","word_nosc":"brisee","lemma":"brisé","pos":"ADJ"} ,
		{"word":"brisés","word_nosc":"brises","lemma":"brisé","pos":"ADJ"} ,
		{"word":"britannique","word_nosc":"britannique","lemma":"britannique","pos":"ADJ"} ,
		{"word":"britanniques","word_nosc":"britanniques","lemma":"britannique","pos":"ADJ"} ,
		{"word":"british","word_nosc":"british","lemma":"british","pos":"ADJ"} ,
		{"word":"brivadoise","word_nosc":"brivadoise","lemma":"brivadois","pos":"ADJ"} ,
		{"word":"brochant","word_nosc":"brochant","lemma":"brochant","pos":"ADJ"} ,
		{"word":"broché","word_nosc":"broche","lemma":"broché","pos":"ADJ"} ,
		{"word":"brochée","word_nosc":"brochee","lemma":"broché","pos":"ADJ"} ,
		{"word":"brochées","word_nosc":"brochees","lemma":"broché","pos":"ADJ"} ,
		{"word":"brochés","word_nosc":"broches","lemma":"broché","pos":"ADJ"} ,
		{"word":"brodé","word_nosc":"brode","lemma":"brodé","pos":"ADJ"} ,
		{"word":"brodée","word_nosc":"brodee","lemma":"brodé","pos":"ADJ"} ,
		{"word":"brodées","word_nosc":"brodees","lemma":"brodé","pos":"ADJ"} ,
		{"word":"brodés","word_nosc":"brodes","lemma":"brodé","pos":"ADJ"} ,
		{"word":"bronchique","word_nosc":"bronchique","lemma":"bronchique","pos":"ADJ"} ,
		{"word":"bronchiques","word_nosc":"bronchiques","lemma":"bronchique","pos":"ADJ"} ,
		{"word":"bronchitique","word_nosc":"bronchitique","lemma":"bronchitique","pos":"ADJ"} ,
		{"word":"bronchitiques","word_nosc":"bronchitiques","lemma":"bronchitique","pos":"ADJ"} ,
		{"word":"bronzant","word_nosc":"bronzant","lemma":"bronzant","pos":"ADJ"} ,
		{"word":"bronzante","word_nosc":"bronzante","lemma":"bronzant","pos":"ADJ"} ,
		{"word":"bronzants","word_nosc":"bronzants","lemma":"bronzant","pos":"ADJ"} ,
		{"word":"bronze","word_nosc":"bronze","lemma":"bronze","pos":"ADJ"} ,
		{"word":"bronzé","word_nosc":"bronze","lemma":"bronzé","pos":"ADJ"} ,
		{"word":"bronzée","word_nosc":"bronzee","lemma":"bronzé","pos":"ADJ"} ,
		{"word":"bronzées","word_nosc":"bronzees","lemma":"bronzé","pos":"ADJ"} ,
		{"word":"bronzés","word_nosc":"bronzes","lemma":"bronzé","pos":"ADJ"} ,
		{"word":"brouillard","word_nosc":"brouillard","lemma":"brouillard","pos":"ADJ"} ,
		{"word":"brouillardeuse","word_nosc":"brouillardeuse","lemma":"brouillardeux","pos":"ADJ"} ,
		{"word":"brouillardeux","word_nosc":"brouillardeux","lemma":"brouillardeux","pos":"ADJ"} ,
		{"word":"brouillon","word_nosc":"brouillon","lemma":"brouillon","pos":"ADJ"} ,
		{"word":"brouillonne","word_nosc":"brouillonne","lemma":"brouillon","pos":"ADJ"} ,
		{"word":"brouillonnes","word_nosc":"brouillonnes","lemma":"brouillon","pos":"ADJ"} ,
		{"word":"brouillons","word_nosc":"brouillons","lemma":"brouillon","pos":"ADJ"} ,
		{"word":"brouillé","word_nosc":"brouille","lemma":"brouillé","pos":"ADJ"} ,
		{"word":"brouillée","word_nosc":"brouillee","lemma":"brouillé","pos":"ADJ"} ,
		{"word":"brouillées","word_nosc":"brouillees","lemma":"brouillé","pos":"ADJ"} ,
		{"word":"brouillés","word_nosc":"brouilles","lemma":"brouillé","pos":"ADJ"} ,
		{"word":"broussailleuse","word_nosc":"broussailleuse","lemma":"broussailleux","pos":"ADJ"} ,
		{"word":"broussailleuses","word_nosc":"broussailleuses","lemma":"broussailleux","pos":"ADJ"} ,
		{"word":"broussailleux","word_nosc":"broussailleux","lemma":"broussailleux","pos":"ADJ"} ,
		{"word":"broutantes","word_nosc":"broutantes","lemma":"broutant","pos":"ADJ"} ,
		{"word":"brouteur","word_nosc":"brouteur","lemma":"brouteur","pos":"ADJ"} ,
		{"word":"brouteurs","word_nosc":"brouteurs","lemma":"brouteur","pos":"ADJ"} ,
		{"word":"brouteuse","word_nosc":"brouteuse","lemma":"brouteur","pos":"ADJ"} ,
		{"word":"brouteuses","word_nosc":"brouteuses","lemma":"brouteur","pos":"ADJ"} ,
		{"word":"broyeur","word_nosc":"broyeur","lemma":"broyeur","pos":"ADJ"} ,
		{"word":"broyeurs","word_nosc":"broyeurs","lemma":"broyeur","pos":"ADJ"} ,
		{"word":"broyeuse","word_nosc":"broyeuse","lemma":"broyeur","pos":"ADJ"} ,
		{"word":"broyé","word_nosc":"broye","lemma":"broyé","pos":"ADJ"} ,
		{"word":"broyée","word_nosc":"broyee","lemma":"broyé","pos":"ADJ"} ,
		{"word":"broyées","word_nosc":"broyees","lemma":"broyé","pos":"ADJ"} ,
		{"word":"broyés","word_nosc":"broyes","lemma":"broyé","pos":"ADJ"} ,
		{"word":"brugeois","word_nosc":"brugeois","lemma":"brugeois","pos":"ADJ"} ,
		{"word":"brugeoise","word_nosc":"brugeoise","lemma":"brugeois","pos":"ADJ"} ,
		{"word":"brugeoises","word_nosc":"brugeoises","lemma":"brugeois","pos":"ADJ"} ,
		{"word":"bruissant","word_nosc":"bruissant","lemma":"bruissant","pos":"ADJ"} ,
		{"word":"bruissante","word_nosc":"bruissante","lemma":"bruissant","pos":"ADJ"} ,
		{"word":"bruissantes","word_nosc":"bruissantes","lemma":"bruissant","pos":"ADJ"} ,
		{"word":"bruissants","word_nosc":"bruissants","lemma":"bruissant","pos":"ADJ"} ,
		{"word":"brumeuse","word_nosc":"brumeuse","lemma":"brumeux","pos":"ADJ"} ,
		{"word":"brumeuses","word_nosc":"brumeuses","lemma":"brumeux","pos":"ADJ"} ,
		{"word":"brumeux","word_nosc":"brumeux","lemma":"brumeux","pos":"ADJ"} ,
		{"word":"brun","word_nosc":"brun","lemma":"brun","pos":"ADJ"} ,
		{"word":"brune","word_nosc":"brune","lemma":"brun","pos":"ADJ"} ,
		{"word":"brunes","word_nosc":"brunes","lemma":"brun","pos":"ADJ"} ,
		{"word":"brunet","word_nosc":"brunet","lemma":"brunet","pos":"ADJ"} ,
		{"word":"brunette","word_nosc":"brunette","lemma":"brunet","pos":"ADJ"} ,
		{"word":"bruni","word_nosc":"bruni","lemma":"bruni","pos":"ADJ"} ,
		{"word":"brunie","word_nosc":"brunie","lemma":"bruni","pos":"ADJ"} ,
		{"word":"brunies","word_nosc":"brunies","lemma":"bruni","pos":"ADJ"} ,
		{"word":"brunis","word_nosc":"brunis","lemma":"bruni","pos":"ADJ"} ,
		{"word":"bruns","word_nosc":"bruns","lemma":"brun","pos":"ADJ"} ,
		{"word":"brunâtre","word_nosc":"brunatre","lemma":"brunâtre","pos":"ADJ"} ,
		{"word":"brunâtres","word_nosc":"brunatres","lemma":"brunâtre","pos":"ADJ"} ,
		{"word":"brusque","word_nosc":"brusque","lemma":"brusque","pos":"ADJ"} ,
		{"word":"brusques","word_nosc":"brusques","lemma":"brusque","pos":"ADJ"} ,
		{"word":"brusquette","word_nosc":"brusquette","lemma":"brusquet","pos":"ADJ"} ,
		{"word":"brut","word_nosc":"brut","lemma":"brut","pos":"ADJ"} ,
		{"word":"brutal","word_nosc":"brutal","lemma":"brutal","pos":"ADJ"} ,
		{"word":"brutale","word_nosc":"brutale","lemma":"brutal","pos":"ADJ"} ,
		{"word":"brutales","word_nosc":"brutales","lemma":"brutal","pos":"ADJ"} ,
		{"word":"brutaux","word_nosc":"brutaux","lemma":"brutal","pos":"ADJ"} ,
		{"word":"brute","word_nosc":"brute","lemma":"brut","pos":"ADJ"} ,
		{"word":"brutes","word_nosc":"brutes","lemma":"brut","pos":"ADJ"} ,
		{"word":"bruts","word_nosc":"bruts","lemma":"brut","pos":"ADJ"} ,
		{"word":"bruxellois","word_nosc":"bruxellois","lemma":"bruxellois","pos":"ADJ"} ,
		{"word":"bruxelloise","word_nosc":"bruxelloise","lemma":"bruxellois","pos":"ADJ"} ,
		{"word":"bruyant","word_nosc":"bruyant","lemma":"bruyant","pos":"ADJ"} ,
		{"word":"bruyante","word_nosc":"bruyante","lemma":"bruyant","pos":"ADJ"} ,
		{"word":"bruyantes","word_nosc":"bruyantes","lemma":"bruyant","pos":"ADJ"} ,
		{"word":"bruyants","word_nosc":"bruyants","lemma":"bruyant","pos":"ADJ"} ,
		{"word":"brève","word_nosc":"breve","lemma":"bref","pos":"ADJ"} ,
		{"word":"brèves","word_nosc":"breves","lemma":"bref","pos":"ADJ"} ,
		{"word":"bréhaigne","word_nosc":"brehaigne","lemma":"bréhaigne","pos":"ADJ"} ,
		{"word":"bréhaignes","word_nosc":"brehaignes","lemma":"bréhaigne","pos":"ADJ"} ,
		{"word":"brésilien","word_nosc":"bresilien","lemma":"brésilien","pos":"ADJ"} ,
		{"word":"brésilienne","word_nosc":"bresilienne","lemma":"brésilien","pos":"ADJ"} ,
		{"word":"brésiliennes","word_nosc":"bresiliennes","lemma":"brésilien","pos":"ADJ"} ,
		{"word":"brésiliens","word_nosc":"bresiliens","lemma":"brésilien","pos":"ADJ"} ,
		{"word":"brûlant","word_nosc":"brulant","lemma":"brûlant","pos":"ADJ"} ,
		{"word":"brûlante","word_nosc":"brulante","lemma":"brûlant","pos":"ADJ"} ,
		{"word":"brûlantes","word_nosc":"brulantes","lemma":"brûlant","pos":"ADJ"} ,
		{"word":"brûlants","word_nosc":"brulants","lemma":"brûlant","pos":"ADJ"} ,
		{"word":"brûlé","word_nosc":"brule","lemma":"brûlé","pos":"ADJ"} ,
		{"word":"brûlée","word_nosc":"brulee","lemma":"brûlé","pos":"ADJ"} ,
		{"word":"brûlées","word_nosc":"brulees","lemma":"brûlé","pos":"ADJ"} ,
		{"word":"brûlés","word_nosc":"brules","lemma":"brûlé","pos":"ADJ"} ,
		{"word":"bu","word_nosc":"bu","lemma":"bu","pos":"ADJ"} ,
		{"word":"bubonique","word_nosc":"bubonique","lemma":"bubonique","pos":"ADJ"} ,
		{"word":"buccal","word_nosc":"buccal","lemma":"buccal","pos":"ADJ"} ,
		{"word":"buccale","word_nosc":"buccale","lemma":"buccal","pos":"ADJ"} ,
		{"word":"buccales","word_nosc":"buccales","lemma":"buccal","pos":"ADJ"} ,
		{"word":"buccaux","word_nosc":"buccaux","lemma":"buccal","pos":"ADJ"} ,
		{"word":"bucco-génital","word_nosc":"bucco-genital","lemma":"bucco-génital","pos":"ADJ"} ,
		{"word":"bucco-génitales","word_nosc":"bucco-genitales","lemma":"bucco-génital","pos":"ADJ"} ,
		{"word":"bucco-génitaux","word_nosc":"bucco-genitaux","lemma":"bucco-génital","pos":"ADJ"} ,
		{"word":"buccogénitales","word_nosc":"buccogenitales","lemma":"buccogénital","pos":"ADJ"} ,
		{"word":"bucolique","word_nosc":"bucolique","lemma":"bucolique","pos":"ADJ"} ,
		{"word":"bucoliques","word_nosc":"bucoliques","lemma":"bucolique","pos":"ADJ"} ,
		{"word":"budgétaire","word_nosc":"budgetaire","lemma":"budgétaire","pos":"ADJ"} ,
		{"word":"budgétaires","word_nosc":"budgetaires","lemma":"budgétaire","pos":"ADJ"} ,
		{"word":"bue","word_nosc":"bue","lemma":"bu","pos":"ADJ"} ,
		{"word":"bues","word_nosc":"bues","lemma":"bu","pos":"ADJ"} ,
		{"word":"buissonnant","word_nosc":"buissonnant","lemma":"buissonnant","pos":"ADJ"} ,
		{"word":"buissonnants","word_nosc":"buissonnants","lemma":"buissonnant","pos":"ADJ"} ,
		{"word":"buissonneuse","word_nosc":"buissonneuse","lemma":"buissonneux","pos":"ADJ"} ,
		{"word":"buissonneuses","word_nosc":"buissonneuses","lemma":"buissonneux","pos":"ADJ"} ,
		{"word":"buissonneux","word_nosc":"buissonneux","lemma":"buissonneux","pos":"ADJ"} ,
		{"word":"buissonnière","word_nosc":"buissonniere","lemma":"buissonnier","pos":"ADJ"} ,
		{"word":"buissonnières","word_nosc":"buissonnieres","lemma":"buissonnier","pos":"ADJ"} ,
		{"word":"bulbeuse","word_nosc":"bulbeuse","lemma":"bulbeux","pos":"ADJ"} ,
		{"word":"bulbeux","word_nosc":"bulbeux","lemma":"bulbeux","pos":"ADJ"} ,
		{"word":"bulgare","word_nosc":"bulgare","lemma":"bulgare","pos":"ADJ"} ,
		{"word":"bulgares","word_nosc":"bulgares","lemma":"bulgare","pos":"ADJ"} ,
		{"word":"bulleux","word_nosc":"bulleux","lemma":"bulleux","pos":"ADJ"} ,
		{"word":"bureaucrate","word_nosc":"bureaucrate","lemma":"bureaucrate","pos":"ADJ"} ,
		{"word":"bureaucrates","word_nosc":"bureaucrates","lemma":"bureaucrate","pos":"ADJ"} ,
		{"word":"bureaucratique","word_nosc":"bureaucratique","lemma":"bureaucratique","pos":"ADJ"} ,
		{"word":"bureaucratiques","word_nosc":"bureaucratiques","lemma":"bureaucratique","pos":"ADJ"} ,
		{"word":"bureautique","word_nosc":"bureautique","lemma":"bureautique","pos":"ADJ"} ,
		{"word":"burgondes","word_nosc":"burgondes","lemma":"burgonde","pos":"ADJ"} ,
		{"word":"buriné","word_nosc":"burine","lemma":"buriné","pos":"ADJ"} ,
		{"word":"burinée","word_nosc":"burinee","lemma":"buriné","pos":"ADJ"} ,
		{"word":"burinées","word_nosc":"burinees","lemma":"buriné","pos":"ADJ"} ,
		{"word":"burinés","word_nosc":"burines","lemma":"buriné","pos":"ADJ"} ,
		{"word":"burkinabé","word_nosc":"burkinabe","lemma":"burkinabé","pos":"ADJ"} ,
		{"word":"burlesque","word_nosc":"burlesque","lemma":"burlesque","pos":"ADJ"} ,
		{"word":"burlesques","word_nosc":"burlesques","lemma":"burlesque","pos":"ADJ"} ,
		{"word":"busqué","word_nosc":"busque","lemma":"busqué","pos":"ADJ"} ,
		{"word":"butineur","word_nosc":"butineur","lemma":"butineur","pos":"ADJ"} ,
		{"word":"butineuse","word_nosc":"butineuse","lemma":"butineur","pos":"ADJ"} ,
		{"word":"butineuses","word_nosc":"butineuses","lemma":"butineur","pos":"ADJ"} ,
		{"word":"buté","word_nosc":"bute","lemma":"buté","pos":"ADJ"} ,
		{"word":"butée","word_nosc":"butee","lemma":"buté","pos":"ADJ"} ,
		{"word":"butées","word_nosc":"butees","lemma":"buté","pos":"ADJ"} ,
		{"word":"butés","word_nosc":"butes","lemma":"buté","pos":"ADJ"} ,
		{"word":"buvable","word_nosc":"buvable","lemma":"buvable","pos":"ADJ"} ,
		{"word":"buvables","word_nosc":"buvables","lemma":"buvable","pos":"ADJ"} ,
		{"word":"buvard","word_nosc":"buvard","lemma":"buvard","pos":"ADJ"} ,
		{"word":"buvards","word_nosc":"buvards","lemma":"buvard","pos":"ADJ"} ,
		{"word":"by night","word_nosc":"by night","lemma":"by night","pos":"ADJ"} ,
		{"word":"byronien","word_nosc":"byronien","lemma":"byronien","pos":"ADJ"} ,
		{"word":"byzantin","word_nosc":"byzantin","lemma":"byzantin","pos":"ADJ"} ,
		{"word":"byzantine","word_nosc":"byzantine","lemma":"byzantin","pos":"ADJ"} ,
		{"word":"byzantines","word_nosc":"byzantines","lemma":"byzantin","pos":"ADJ"} ,
		{"word":"byzantinisant","word_nosc":"byzantinisant","lemma":"byzantinisant","pos":"ADJ"} ,
		{"word":"byzantins","word_nosc":"byzantins","lemma":"byzantin","pos":"ADJ"} ,
		{"word":"bâché","word_nosc":"bache","lemma":"bâché","pos":"ADJ"} ,
		{"word":"bâchée","word_nosc":"bachee","lemma":"bâché","pos":"ADJ"} ,
		{"word":"bâchées","word_nosc":"bachees","lemma":"bâché","pos":"ADJ"} ,
		{"word":"bâchés","word_nosc":"baches","lemma":"bâché","pos":"ADJ"} ,
		{"word":"bâillant","word_nosc":"baillant","lemma":"bâillant","pos":"ADJ"} ,
		{"word":"bâillante","word_nosc":"baillante","lemma":"bâillant","pos":"ADJ"} ,
		{"word":"bâillantes","word_nosc":"baillantes","lemma":"bâillant","pos":"ADJ"} ,
		{"word":"bâtard","word_nosc":"batard","lemma":"bâtard","pos":"ADJ"} ,
		{"word":"bâtarde","word_nosc":"batarde","lemma":"bâtard","pos":"ADJ"} ,
		{"word":"bâtardes","word_nosc":"batardes","lemma":"bâtard","pos":"ADJ"} ,
		{"word":"bâtards","word_nosc":"batards","lemma":"bâtard","pos":"ADJ"} ,
		{"word":"bâti","word_nosc":"bati","lemma":"bâti","pos":"ADJ"} ,
		{"word":"bâtie","word_nosc":"batie","lemma":"bâti","pos":"ADJ"} ,
		{"word":"bâties","word_nosc":"baties","lemma":"bâti","pos":"ADJ"} ,
		{"word":"bâtis","word_nosc":"batis","lemma":"bâti","pos":"ADJ"} ,
		{"word":"bâté","word_nosc":"bate","lemma":"bâté","pos":"ADJ"} ,
		{"word":"bâtés","word_nosc":"bates","lemma":"bâté","pos":"ADJ"} ,
		{"word":"bègue","word_nosc":"begue","lemma":"bègue","pos":"ADJ"} ,
		{"word":"bègues","word_nosc":"begues","lemma":"bègue","pos":"ADJ"} ,
		{"word":"béant","word_nosc":"beant","lemma":"béant","pos":"ADJ"} ,
		{"word":"béante","word_nosc":"beante","lemma":"béant","pos":"ADJ"} ,
		{"word":"béantes","word_nosc":"beantes","lemma":"béant","pos":"ADJ"} ,
		{"word":"béants","word_nosc":"beants","lemma":"béant","pos":"ADJ"} ,
		{"word":"béarnais","word_nosc":"bearnais","lemma":"béarnais","pos":"ADJ"} ,
		{"word":"béarnaise","word_nosc":"bearnaise","lemma":"béarnais","pos":"ADJ"} ,
		{"word":"béat","word_nosc":"beat","lemma":"béat","pos":"ADJ"} ,
		{"word":"béate","word_nosc":"beate","lemma":"béat","pos":"ADJ"} ,
		{"word":"béates","word_nosc":"beates","lemma":"béat","pos":"ADJ"} ,
		{"word":"béatifique","word_nosc":"beatifique","lemma":"béatifique","pos":"ADJ"} ,
		{"word":"béats","word_nosc":"beats","lemma":"béat","pos":"ADJ"} ,
		{"word":"bébête","word_nosc":"bebete","lemma":"bébête","pos":"ADJ"} ,
		{"word":"bébêtes","word_nosc":"bebetes","lemma":"bébête","pos":"ADJ"} ,
		{"word":"bécasse","word_nosc":"becasse","lemma":"bécasse","pos":"ADJ"} ,
		{"word":"bécasses","word_nosc":"becasses","lemma":"bécasse","pos":"ADJ"} ,
		{"word":"bécoteur","word_nosc":"becoteur","lemma":"bécoteur","pos":"ADJ"} ,
		{"word":"bédouin","word_nosc":"bedouin","lemma":"bédouin","pos":"ADJ"} ,
		{"word":"bédouine","word_nosc":"bedouine","lemma":"bédouin","pos":"ADJ"} ,
		{"word":"bédouines","word_nosc":"bedouines","lemma":"bédouin","pos":"ADJ"} ,
		{"word":"bédouins","word_nosc":"bedouins","lemma":"bédouin","pos":"ADJ"} ,
		{"word":"bée","word_nosc":"bee","lemma":"bé","pos":"ADJ"} ,
		{"word":"bée","word_nosc":"bee","lemma":"bée","pos":"ADJ"} ,
		{"word":"bées","word_nosc":"bees","lemma":"bé","pos":"ADJ"} ,
		{"word":"bégayant","word_nosc":"begayant","lemma":"bégayant","pos":"ADJ"} ,
		{"word":"bégayante","word_nosc":"begayante","lemma":"bégayant","pos":"ADJ"} ,
		{"word":"bégayantes","word_nosc":"begayantes","lemma":"bégayant","pos":"ADJ"} ,
		{"word":"bégayants","word_nosc":"begayants","lemma":"bégayant","pos":"ADJ"} ,
		{"word":"bégayeur","word_nosc":"begayeur","lemma":"bégayeur","pos":"ADJ"} ,
		{"word":"bégueule","word_nosc":"begueule","lemma":"bégueule","pos":"ADJ"} ,
		{"word":"bégueules","word_nosc":"begueules","lemma":"bégueule","pos":"ADJ"} ,
		{"word":"bénard","word_nosc":"benard","lemma":"bénard","pos":"ADJ"} ,
		{"word":"bénarde","word_nosc":"benarde","lemma":"bénard","pos":"ADJ"} ,
		{"word":"bénards","word_nosc":"benards","lemma":"bénard","pos":"ADJ"} ,
		{"word":"bénigne","word_nosc":"benigne","lemma":"bénin","pos":"ADJ"} ,
		{"word":"bénignes","word_nosc":"benignes","lemma":"bénin","pos":"ADJ"} ,
		{"word":"bénin","word_nosc":"benin","lemma":"bénin","pos":"ADJ"} ,
		{"word":"béninois","word_nosc":"beninois","lemma":"béninois","pos":"ADJ"} ,
		{"word":"bénins","word_nosc":"benins","lemma":"bénin","pos":"ADJ"} ,
		{"word":"bénisseur","word_nosc":"benisseur","lemma":"bénisseur","pos":"ADJ"} ,
		{"word":"bénisseurs","word_nosc":"benisseurs","lemma":"bénisseur","pos":"ADJ"} ,
		{"word":"bénisseuse","word_nosc":"benisseuse","lemma":"bénisseur","pos":"ADJ"} ,
		{"word":"bénit","word_nosc":"benit","lemma":"bénit","pos":"ADJ"} ,
		{"word":"bénite","word_nosc":"benite","lemma":"bénit","pos":"ADJ"} ,
		{"word":"bénites","word_nosc":"benites","lemma":"bénit","pos":"ADJ"} ,
		{"word":"bénits","word_nosc":"benits","lemma":"bénit","pos":"ADJ"} ,
		{"word":"bénédictin","word_nosc":"benedictin","lemma":"bénédictin","pos":"ADJ"} ,
		{"word":"bénédictine","word_nosc":"benedictine","lemma":"bénédictin","pos":"ADJ"} ,
		{"word":"bénédictines","word_nosc":"benedictines","lemma":"bénédictin","pos":"ADJ"} ,
		{"word":"bénédictins","word_nosc":"benedictins","lemma":"bénédictin","pos":"ADJ"} ,
		{"word":"bénéficiaire","word_nosc":"beneficiaire","lemma":"bénéficiaire","pos":"ADJ"} ,
		{"word":"bénéficiaires","word_nosc":"beneficiaires","lemma":"bénéficiaire","pos":"ADJ"} ,
		{"word":"bénéfique","word_nosc":"benefique","lemma":"bénéfique","pos":"ADJ"} ,
		{"word":"bénéfiques","word_nosc":"benefiques","lemma":"bénéfique","pos":"ADJ"} ,
		{"word":"bénévole","word_nosc":"benevole","lemma":"bénévole","pos":"ADJ"} ,
		{"word":"bénévoles","word_nosc":"benevoles","lemma":"bénévole","pos":"ADJ"} ,
		{"word":"béotien","word_nosc":"beotien","lemma":"béotien","pos":"ADJ"} ,
		{"word":"béotienne","word_nosc":"beotienne","lemma":"béotien","pos":"ADJ"} ,
		{"word":"béotiens","word_nosc":"beotiens","lemma":"béotien","pos":"ADJ"} ,
		{"word":"béquillard","word_nosc":"bequillard","lemma":"béquillard","pos":"ADJ"} ,
		{"word":"béquilleux","word_nosc":"bequilleux","lemma":"béquilleux","pos":"ADJ"} ,
		{"word":"bétonné","word_nosc":"betonne","lemma":"bétonné","pos":"ADJ"} ,
		{"word":"bétonnée","word_nosc":"betonnee","lemma":"bétonné","pos":"ADJ"} ,
		{"word":"bétonnées","word_nosc":"betonnees","lemma":"bétonné","pos":"ADJ"} ,
		{"word":"bétonnés","word_nosc":"betonnes","lemma":"bétonné","pos":"ADJ"} ,
		{"word":"bêcheur","word_nosc":"becheur","lemma":"bêcheur","pos":"ADJ"} ,
		{"word":"bêcheurs","word_nosc":"becheurs","lemma":"bêcheur","pos":"ADJ"} ,
		{"word":"bêcheuse","word_nosc":"becheuse","lemma":"bêcheur","pos":"ADJ"} ,
		{"word":"bêcheuses","word_nosc":"becheuses","lemma":"bêcheur","pos":"ADJ"} ,
		{"word":"bêlant","word_nosc":"belant","lemma":"bêlant","pos":"ADJ"} ,
		{"word":"bêlantes","word_nosc":"belantes","lemma":"bêlant","pos":"ADJ"} ,
		{"word":"bêlants","word_nosc":"belants","lemma":"bêlant","pos":"ADJ"} ,
		{"word":"bêta","word_nosc":"beta","lemma":"bêta","pos":"ADJ"} ,
		{"word":"bêtas","word_nosc":"betas","lemma":"bêta","pos":"ADJ"} ,
		{"word":"bêtasse","word_nosc":"betasse","lemma":"bêta","pos":"ADJ"} ,
		{"word":"bêtasses","word_nosc":"betasses","lemma":"bêta","pos":"ADJ"} ,
		{"word":"bête","word_nosc":"bete","lemma":"bête","pos":"ADJ"} ,
		{"word":"bêtes","word_nosc":"betes","lemma":"bête","pos":"ADJ"} ,
		{"word":"bêtifiant","word_nosc":"betifiant","lemma":"bêtifiant","pos":"ADJ"} ,
		{"word":"bûcheur","word_nosc":"bucheur","lemma":"bûcheur","pos":"ADJ"} ,
		{"word":"bûcheurs","word_nosc":"bucheurs","lemma":"bûcheur","pos":"ADJ"} ,
		{"word":"c","word_nosc":"c","lemma":"c","pos":"ADJ:num"} ,
		{"word":"cabalistique","word_nosc":"cabalistique","lemma":"cabalistique","pos":"ADJ"} ,
		{"word":"cabalistiques","word_nosc":"cabalistiques","lemma":"cabalistique","pos":"ADJ"} ,
		{"word":"cabochard","word_nosc":"cabochard","lemma":"cabochard","pos":"ADJ"} ,
		{"word":"cabocharde","word_nosc":"cabocharde","lemma":"cabochard","pos":"ADJ"} ,
		{"word":"cabochards","word_nosc":"cabochards","lemma":"cabochard","pos":"ADJ"} ,
		{"word":"cabotin","word_nosc":"cabotin","lemma":"cabotin","pos":"ADJ"} ,
		{"word":"cabotine","word_nosc":"cabotine","lemma":"cabotin","pos":"ADJ"} ,
		{"word":"cabotines","word_nosc":"cabotines","lemma":"cabotin","pos":"ADJ"} ,
		{"word":"cabotins","word_nosc":"cabotins","lemma":"cabotin","pos":"ADJ"} ,
		{"word":"cabus","word_nosc":"cabus","lemma":"cabus","pos":"ADJ"} ,
		{"word":"caca","word_nosc":"caca","lemma":"caca","pos":"ADJ"} ,
		{"word":"cacaotés","word_nosc":"cacaotes","lemma":"cacaoté","pos":"ADJ"} ,
		{"word":"cachectique","word_nosc":"cachectique","lemma":"cachectique","pos":"ADJ"} ,
		{"word":"cachectiques","word_nosc":"cachectiques","lemma":"cachectique","pos":"ADJ"} ,
		{"word":"cachottier","word_nosc":"cachottier","lemma":"cachottier","pos":"ADJ"} ,
		{"word":"cachottiers","word_nosc":"cachottiers","lemma":"cachottier","pos":"ADJ"} ,
		{"word":"cachottière","word_nosc":"cachottiere","lemma":"cachottier","pos":"ADJ"} ,
		{"word":"cachottières","word_nosc":"cachottieres","lemma":"cachottier","pos":"ADJ"} ,
		{"word":"cachou","word_nosc":"cachou","lemma":"cachou","pos":"ADJ"} ,
		{"word":"cachère","word_nosc":"cachere","lemma":"cachère","pos":"ADJ"} ,
		{"word":"caché","word_nosc":"cache","lemma":"caché","pos":"ADJ"} ,
		{"word":"cachée","word_nosc":"cachee","lemma":"caché","pos":"ADJ"} ,
		{"word":"cachées","word_nosc":"cachees","lemma":"caché","pos":"ADJ"} ,
		{"word":"cachés","word_nosc":"caches","lemma":"caché","pos":"ADJ"} ,
		{"word":"cacochyme","word_nosc":"cacochyme","lemma":"cacochyme","pos":"ADJ"} ,
		{"word":"cacochymes","word_nosc":"cacochymes","lemma":"cacochyme","pos":"ADJ"} ,
		{"word":"cacophonique","word_nosc":"cacophonique","lemma":"cacophonique","pos":"ADJ"} ,
		{"word":"cacophoniques","word_nosc":"cacophoniques","lemma":"cacophonique","pos":"ADJ"} ,
		{"word":"cadastral","word_nosc":"cadastral","lemma":"cadastral","pos":"ADJ"} ,
		{"word":"cadastrale","word_nosc":"cadastrale","lemma":"cadastral","pos":"ADJ"} ,
		{"word":"cadastraux","word_nosc":"cadastraux","lemma":"cadastral","pos":"ADJ"} ,
		{"word":"cadavéreuse","word_nosc":"cadavereuse","lemma":"cadavéreux","pos":"ADJ"} ,
		{"word":"cadavéreux","word_nosc":"cadavereux","lemma":"cadavéreux","pos":"ADJ"} ,
		{"word":"cadavérique","word_nosc":"cadaverique","lemma":"cadavérique","pos":"ADJ"} ,
		{"word":"cadavériques","word_nosc":"cadaveriques","lemma":"cadavérique","pos":"ADJ"} ,
		{"word":"cadencé","word_nosc":"cadence","lemma":"cadencé","pos":"ADJ"} ,
		{"word":"cadencée","word_nosc":"cadencee","lemma":"cadencé","pos":"ADJ"} ,
		{"word":"cadencées","word_nosc":"cadencees","lemma":"cadencé","pos":"ADJ"} ,
		{"word":"cadencés","word_nosc":"cadences","lemma":"cadencé","pos":"ADJ"} ,
		{"word":"cadet","word_nosc":"cadet","lemma":"cadet","pos":"ADJ"} ,
		{"word":"cadets","word_nosc":"cadets","lemma":"cadet","pos":"ADJ"} ,
		{"word":"cadette","word_nosc":"cadette","lemma":"cadet","pos":"ADJ"} ,
		{"word":"cadettes","word_nosc":"cadettes","lemma":"cadet","pos":"ADJ"} ,
		{"word":"caduc","word_nosc":"caduc","lemma":"caduc","pos":"ADJ"} ,
		{"word":"caducs","word_nosc":"caducs","lemma":"caduc","pos":"ADJ"} ,
		{"word":"caduque","word_nosc":"caduque","lemma":"caduque","pos":"ADJ"} ,
		{"word":"caduques","word_nosc":"caduques","lemma":"caduque","pos":"ADJ"} ,
		{"word":"caf","word_nosc":"caf","lemma":"caf","pos":"ADJ"} ,
		{"word":"cafard","word_nosc":"cafard","lemma":"cafard","pos":"ADJ"} ,
		{"word":"cafarde","word_nosc":"cafarde","lemma":"cafard","pos":"ADJ"} ,
		{"word":"cafardeuse","word_nosc":"cafardeuse","lemma":"cafardeuse","pos":"ADJ"} ,
		{"word":"cafardeux","word_nosc":"cafardeux","lemma":"cafardeux","pos":"ADJ"} ,
		{"word":"cafards","word_nosc":"cafards","lemma":"cafard","pos":"ADJ"} ,
		{"word":"cafouilleuse","word_nosc":"cafouilleuse","lemma":"cafouilleux","pos":"ADJ"} ,
		{"word":"cafouilleux","word_nosc":"cafouilleux","lemma":"cafouilleux","pos":"ADJ"} ,
		{"word":"café","word_nosc":"cafe","lemma":"café","pos":"ADJ"} ,
		{"word":"café-crème","word_nosc":"cafe-creme","lemma":"café-crème","pos":"ADJ"} ,
		{"word":"cagnard","word_nosc":"cagnard","lemma":"cagnard","pos":"ADJ"} ,
		{"word":"cagnards","word_nosc":"cagnards","lemma":"cagnard","pos":"ADJ"} ,
		{"word":"cagneuse","word_nosc":"cagneuse","lemma":"cagneux","pos":"ADJ"} ,
		{"word":"cagneuses","word_nosc":"cagneuses","lemma":"cagneux","pos":"ADJ"} ,
		{"word":"cagneux","word_nosc":"cagneux","lemma":"cagneux","pos":"ADJ"} ,
		{"word":"cagot","word_nosc":"cagot","lemma":"cagot","pos":"ADJ"} ,
		{"word":"cagotes","word_nosc":"cagotes","lemma":"cagot","pos":"ADJ"} ,
		{"word":"cagoulé","word_nosc":"cagoule","lemma":"cagoulé","pos":"ADJ"} ,
		{"word":"cagoulés","word_nosc":"cagoules","lemma":"cagoulé","pos":"ADJ"} ,
		{"word":"cahotant","word_nosc":"cahotant","lemma":"cahotant","pos":"ADJ"} ,
		{"word":"cahotante","word_nosc":"cahotante","lemma":"cahotant","pos":"ADJ"} ,
		{"word":"cahoteuse","word_nosc":"cahoteuse","lemma":"cahoteux","pos":"ADJ"} ,
		{"word":"cahoteuses","word_nosc":"cahoteuses","lemma":"cahoteux","pos":"ADJ"} ,
		{"word":"cahoteux","word_nosc":"cahoteux","lemma":"cahoteux","pos":"ADJ"} ,
		{"word":"caillouteuse","word_nosc":"caillouteuse","lemma":"caillouteux","pos":"ADJ"} ,
		{"word":"caillouteuses","word_nosc":"caillouteuses","lemma":"caillouteux","pos":"ADJ"} ,
		{"word":"caillouteux","word_nosc":"caillouteux","lemma":"caillouteux","pos":"ADJ"} ,
		{"word":"caillé","word_nosc":"caille","lemma":"caillé","pos":"ADJ"} ,
		{"word":"cairote","word_nosc":"cairote","lemma":"cairote","pos":"ADJ"} ,
		{"word":"cajoleur","word_nosc":"cajoleur","lemma":"cajoleur","pos":"ADJ"} ,
		{"word":"cajoleuse","word_nosc":"cajoleuse","lemma":"cajoleur","pos":"ADJ"} ,
		{"word":"cajun","word_nosc":"cajun","lemma":"cajun","pos":"ADJ"} ,
		{"word":"cajuns","word_nosc":"cajuns","lemma":"cajun","pos":"ADJ"} ,
		{"word":"calabrais","word_nosc":"calabrais","lemma":"calabrais","pos":"ADJ"} ,
		{"word":"calabraises","word_nosc":"calabraises","lemma":"calabrais","pos":"ADJ"} ,
		{"word":"calamistré","word_nosc":"calamistre","lemma":"calamistré","pos":"ADJ"} ,
		{"word":"calamistrée","word_nosc":"calamistree","lemma":"calamistré","pos":"ADJ"} ,
		{"word":"calamistrés","word_nosc":"calamistres","lemma":"calamistré","pos":"ADJ"} ,
		{"word":"calamiteuse","word_nosc":"calamiteuse","lemma":"calamiteux","pos":"ADJ"} ,
		{"word":"calamiteuses","word_nosc":"calamiteuses","lemma":"calamiteux","pos":"ADJ"} ,
		{"word":"calamiteux","word_nosc":"calamiteux","lemma":"calamiteux","pos":"ADJ"} ,
		{"word":"calcaire","word_nosc":"calcaire","lemma":"calcaire","pos":"ADJ"} ,
		{"word":"calcaires","word_nosc":"calcaires","lemma":"calcaire","pos":"ADJ"} ,
		{"word":"calcifié","word_nosc":"calcifie","lemma":"calcifié","pos":"ADJ"} ,
		{"word":"calcifiée","word_nosc":"calcifiee","lemma":"calcifié","pos":"ADJ"} ,
		{"word":"calciné","word_nosc":"calcine","lemma":"calciné","pos":"ADJ"} ,
		{"word":"calcinée","word_nosc":"calcinee","lemma":"calciné","pos":"ADJ"} ,
		{"word":"calcinées","word_nosc":"calcinees","lemma":"calciné","pos":"ADJ"} ,
		{"word":"calcinés","word_nosc":"calcines","lemma":"calciné","pos":"ADJ"} ,
		{"word":"calculables","word_nosc":"calculables","lemma":"calculable","pos":"ADJ"} ,
		{"word":"calculant","word_nosc":"calculant","lemma":"calculant","pos":"ADJ"} ,
		{"word":"calculateur","word_nosc":"calculateur","lemma":"calculateur","pos":"ADJ"} ,
		{"word":"calculateurs","word_nosc":"calculateurs","lemma":"calculateur","pos":"ADJ"} ,
		{"word":"calculatrice","word_nosc":"calculatrice","lemma":"calculatrice","pos":"ADJ"} ,
		{"word":"calculatrices","word_nosc":"calculatrices","lemma":"calculatrice","pos":"ADJ"} ,
		{"word":"calculeuse","word_nosc":"calculeuse","lemma":"calculeux","pos":"ADJ"} ,
		{"word":"calendaire","word_nosc":"calendaire","lemma":"calendaire","pos":"ADJ"} ,
		{"word":"californien","word_nosc":"californien","lemma":"californien","pos":"ADJ"} ,
		{"word":"californienne","word_nosc":"californienne","lemma":"californien","pos":"ADJ"} ,
		{"word":"californiennes","word_nosc":"californiennes","lemma":"californien","pos":"ADJ"} ,
		{"word":"californiens","word_nosc":"californiens","lemma":"californien","pos":"ADJ"} ,
		{"word":"calleuse","word_nosc":"calleuse","lemma":"calleux","pos":"ADJ"} ,
		{"word":"calleuses","word_nosc":"calleuses","lemma":"calleux","pos":"ADJ"} ,
		{"word":"calleux","word_nosc":"calleux","lemma":"calleux","pos":"ADJ"} ,
		{"word":"calligraphique","word_nosc":"calligraphique","lemma":"calligraphique","pos":"ADJ"} ,
		{"word":"calligraphiques","word_nosc":"calligraphiques","lemma":"calligraphique","pos":"ADJ"} ,
		{"word":"callipyge","word_nosc":"callipyge","lemma":"callipyge","pos":"ADJ"} ,
		{"word":"calmant","word_nosc":"calmant","lemma":"calmant","pos":"ADJ"} ,
		{"word":"calmante","word_nosc":"calmante","lemma":"calmant","pos":"ADJ"} ,
		{"word":"calmants","word_nosc":"calmants","lemma":"calmant","pos":"ADJ"} ,
		{"word":"calme","word_nosc":"calme","lemma":"calme","pos":"ADJ"} ,
		{"word":"calmes","word_nosc":"calmes","lemma":"calme","pos":"ADJ"} ,
		{"word":"calomnieuse","word_nosc":"calomnieuse","lemma":"calomnieux","pos":"ADJ"} ,
		{"word":"calomnieuses","word_nosc":"calomnieuses","lemma":"calomnieux","pos":"ADJ"} ,
		{"word":"calomnieux","word_nosc":"calomnieux","lemma":"calomnieux","pos":"ADJ"} ,
		{"word":"calorifique","word_nosc":"calorifique","lemma":"calorifique","pos":"ADJ"} ,
		{"word":"calorifère","word_nosc":"calorifere","lemma":"calorifère","pos":"ADJ"} ,
		{"word":"calorifères","word_nosc":"caloriferes","lemma":"calorifère","pos":"ADJ"} ,
		{"word":"calorique","word_nosc":"calorique","lemma":"calorique","pos":"ADJ"} ,
		{"word":"calviniste","word_nosc":"calviniste","lemma":"calviniste","pos":"ADJ"} ,
		{"word":"calvinistes","word_nosc":"calvinistes","lemma":"calviniste","pos":"ADJ"} ,
		{"word":"calé","word_nosc":"cale","lemma":"calé","pos":"ADJ"} ,
		{"word":"calédonien","word_nosc":"caledonien","lemma":"calédonien","pos":"ADJ"} ,
		{"word":"calédonienne","word_nosc":"caledonienne","lemma":"calédonien","pos":"ADJ"} ,
		{"word":"calée","word_nosc":"calee","lemma":"calé","pos":"ADJ"} ,
		{"word":"calées","word_nosc":"calees","lemma":"calé","pos":"ADJ"} ,
		{"word":"calés","word_nosc":"cales","lemma":"calé","pos":"ADJ"} ,
		{"word":"camard","word_nosc":"camard","lemma":"camard","pos":"ADJ"} ,
		{"word":"camarde","word_nosc":"camarde","lemma":"camard","pos":"ADJ"} ,
		{"word":"camards","word_nosc":"camards","lemma":"camard","pos":"ADJ"} ,
		{"word":"camarguais","word_nosc":"camarguais","lemma":"camarguais","pos":"ADJ"} ,
		{"word":"cambodgien","word_nosc":"cambodgien","lemma":"cambodgien","pos":"ADJ"} ,
		{"word":"cambodgienne","word_nosc":"cambodgienne","lemma":"cambodgien","pos":"ADJ"} ,
		{"word":"cambodgiennes","word_nosc":"cambodgiennes","lemma":"cambodgien","pos":"ADJ"} ,
		{"word":"cambodgiens","word_nosc":"cambodgiens","lemma":"cambodgien","pos":"ADJ"} ,
		{"word":"cambrienne","word_nosc":"cambrienne","lemma":"cambrien","pos":"ADJ"} ,
		{"word":"cambré","word_nosc":"cambre","lemma":"cambré","pos":"ADJ"} ,
		{"word":"cambrée","word_nosc":"cambree","lemma":"cambré","pos":"ADJ"} ,
		{"word":"cambrées","word_nosc":"cambrees","lemma":"cambré","pos":"ADJ"} ,
		{"word":"cambrés","word_nosc":"cambres","lemma":"cambré","pos":"ADJ"} ,
		{"word":"camerounais","word_nosc":"camerounais","lemma":"camerounais","pos":"ADJ"} ,
		{"word":"camerounaise","word_nosc":"camerounaise","lemma":"camerounais","pos":"ADJ"} ,
		{"word":"campagnard","word_nosc":"campagnard","lemma":"campagnard","pos":"ADJ"} ,
		{"word":"campagnarde","word_nosc":"campagnarde","lemma":"campagnard","pos":"ADJ"} ,
		{"word":"campagnardes","word_nosc":"campagnardes","lemma":"campagnard","pos":"ADJ"} ,
		{"word":"campagnards","word_nosc":"campagnards","lemma":"campagnard","pos":"ADJ"} ,
		{"word":"campanaire","word_nosc":"campanaire","lemma":"campanaire","pos":"ADJ"} ,
		{"word":"camphrée","word_nosc":"camphree","lemma":"camphré","pos":"ADJ"} ,
		{"word":"camphrées","word_nosc":"camphrees","lemma":"camphré","pos":"ADJ"} ,
		{"word":"campé","word_nosc":"campe","lemma":"campé","pos":"ADJ"} ,
		{"word":"campée","word_nosc":"campee","lemma":"campé","pos":"ADJ"} ,
		{"word":"camus","word_nosc":"camus","lemma":"camus","pos":"ADJ"} ,
		{"word":"camuse","word_nosc":"camuse","lemma":"camus","pos":"ADJ"} ,
		{"word":"camé","word_nosc":"came","lemma":"camé","pos":"ADJ"} ,
		{"word":"camée","word_nosc":"camee","lemma":"camé","pos":"ADJ"} ,
		{"word":"camées","word_nosc":"camees","lemma":"camé","pos":"ADJ"} ,
		{"word":"camés","word_nosc":"cames","lemma":"camé","pos":"ADJ"} ,
		{"word":"canadien","word_nosc":"canadien","lemma":"canadien","pos":"ADJ"} ,
		{"word":"canadienne","word_nosc":"canadienne","lemma":"canadienne","pos":"ADJ"} ,
		{"word":"canadiennes","word_nosc":"canadiennes","lemma":"canadienne","pos":"ADJ"} ,
		{"word":"canadiens","word_nosc":"canadiens","lemma":"canadien","pos":"ADJ"} ,
		{"word":"canaille","word_nosc":"canaille","lemma":"canaille","pos":"ADJ"} ,
		{"word":"canailles","word_nosc":"canailles","lemma":"canaille","pos":"ADJ"} ,
		{"word":"cananéen","word_nosc":"cananeen","lemma":"cananéen","pos":"ADJ"} ,
		{"word":"canaque","word_nosc":"canaque","lemma":"canaque","pos":"ADJ"} ,
		{"word":"canari","word_nosc":"canari","lemma":"canari","pos":"ADJ"} ,
		{"word":"cancanier","word_nosc":"cancanier","lemma":"cancanier","pos":"ADJ"} ,
		{"word":"cancanière","word_nosc":"cancaniere","lemma":"cancanier","pos":"ADJ"} ,
		{"word":"cancéreuse","word_nosc":"cancereuse","lemma":"cancéreux","pos":"ADJ"} ,
		{"word":"cancéreuses","word_nosc":"cancereuses","lemma":"cancéreux","pos":"ADJ"} ,
		{"word":"cancéreux","word_nosc":"cancereux","lemma":"cancéreux","pos":"ADJ"} ,
		{"word":"cancérigène","word_nosc":"cancerigene","lemma":"cancérigène","pos":"ADJ"} ,
		{"word":"cancérigènes","word_nosc":"cancerigenes","lemma":"cancérigène","pos":"ADJ"} ,
		{"word":"cancérogène","word_nosc":"cancerogene","lemma":"cancérogène","pos":"ADJ"} ,
		{"word":"candi","word_nosc":"candi","lemma":"candi","pos":"ADJ"} ,
		{"word":"candide","word_nosc":"candide","lemma":"candide","pos":"ADJ"} ,
		{"word":"candides","word_nosc":"candides","lemma":"candide","pos":"ADJ"} ,
		{"word":"candis","word_nosc":"candis","lemma":"candi","pos":"ADJ"} ,
		{"word":"caniculaire","word_nosc":"caniculaire","lemma":"caniculaire","pos":"ADJ"} ,
		{"word":"caniculaires","word_nosc":"caniculaires","lemma":"caniculaire","pos":"ADJ"} ,
		{"word":"canin","word_nosc":"canin","lemma":"canin","pos":"ADJ"} ,
		{"word":"canine","word_nosc":"canine","lemma":"canine","pos":"ADJ"} ,
		{"word":"canines","word_nosc":"canines","lemma":"canine","pos":"ADJ"} ,
		{"word":"canins","word_nosc":"canins","lemma":"canin","pos":"ADJ"} ,
		{"word":"cannelle","word_nosc":"cannelle","lemma":"cannelle","pos":"ADJ"} ,
		{"word":"cannelé","word_nosc":"cannele","lemma":"cannelé","pos":"ADJ"} ,
		{"word":"cannelée","word_nosc":"cannelee","lemma":"cannelé","pos":"ADJ"} ,
		{"word":"cannelées","word_nosc":"cannelees","lemma":"cannelé","pos":"ADJ"} ,
		{"word":"cannelés","word_nosc":"canneles","lemma":"cannelé","pos":"ADJ"} ,
		{"word":"cannibale","word_nosc":"cannibale","lemma":"cannibale","pos":"ADJ"} ,
		{"word":"cannibales","word_nosc":"cannibales","lemma":"cannibale","pos":"ADJ"} ,
		{"word":"canné","word_nosc":"canne","lemma":"canné","pos":"ADJ"} ,
		{"word":"cannée","word_nosc":"cannee","lemma":"canné","pos":"ADJ"} ,
		{"word":"cannées","word_nosc":"cannees","lemma":"canné","pos":"ADJ"} ,
		{"word":"canon","word_nosc":"canon","lemma":"canon","pos":"ADJ"} ,
		{"word":"canoniale","word_nosc":"canoniale","lemma":"canonial","pos":"ADJ"} ,
		{"word":"canoniales","word_nosc":"canoniales","lemma":"canonial","pos":"ADJ"} ,
		{"word":"canonique","word_nosc":"canonique","lemma":"canonique","pos":"ADJ"} ,
		{"word":"canoniques","word_nosc":"canoniques","lemma":"canonique","pos":"ADJ"} ,
		{"word":"canons","word_nosc":"canons","lemma":"canon","pos":"ADJ"} ,
		{"word":"cantabrique","word_nosc":"cantabrique","lemma":"cantabrique","pos":"ADJ"} ,
		{"word":"cantabriques","word_nosc":"cantabriques","lemma":"cantabrique","pos":"ADJ"} ,
		{"word":"cantilever","word_nosc":"cantilever","lemma":"cantilever","pos":"ADJ"} ,
		{"word":"cantonais","word_nosc":"cantonais","lemma":"cantonais","pos":"ADJ"} ,
		{"word":"cantonaise","word_nosc":"cantonaise","lemma":"cantonais","pos":"ADJ"} ,
		{"word":"cantonal","word_nosc":"cantonal","lemma":"cantonal","pos":"ADJ"} ,
		{"word":"cantonale","word_nosc":"cantonale","lemma":"cantonal","pos":"ADJ"} ,
		{"word":"cantonales","word_nosc":"cantonales","lemma":"cantonal","pos":"ADJ"} ,
		{"word":"canularesque","word_nosc":"canularesque","lemma":"canularesque","pos":"ADJ"} ,
		{"word":"caoutchouteuse","word_nosc":"caoutchouteuse","lemma":"caoutchouteux","pos":"ADJ"} ,
		{"word":"caoutchouteuses","word_nosc":"caoutchouteuses","lemma":"caoutchouteux","pos":"ADJ"} ,
		{"word":"caoutchouteux","word_nosc":"caoutchouteux","lemma":"caoutchouteux","pos":"ADJ"} ,
		{"word":"caoutchouté","word_nosc":"caoutchoute","lemma":"caoutchouté","pos":"ADJ"} ,
		{"word":"caoutchoutée","word_nosc":"caoutchoutee","lemma":"caoutchouté","pos":"ADJ"} ,
		{"word":"caoutchoutées","word_nosc":"caoutchoutees","lemma":"caoutchouté","pos":"ADJ"} ,
		{"word":"caoutchoutés","word_nosc":"caoutchoutes","lemma":"caoutchouté","pos":"ADJ"} ,
		{"word":"capable","word_nosc":"capable","lemma":"capable","pos":"ADJ"} ,
		{"word":"capables","word_nosc":"capables","lemma":"capable","pos":"ADJ"} ,
		{"word":"capacités","word_nosc":"capacites","lemma":"capacités","pos":"ADJ"} ,
		{"word":"capillaire","word_nosc":"capillaire","lemma":"capillaire","pos":"ADJ"} ,
		{"word":"capillaires","word_nosc":"capillaires","lemma":"capillaire","pos":"ADJ"} ,
		{"word":"capital","word_nosc":"capital","lemma":"capital","pos":"ADJ"} ,
		{"word":"capitale","word_nosc":"capitale","lemma":"capital","pos":"ADJ"} ,
		{"word":"capitales","word_nosc":"capitales","lemma":"capital","pos":"ADJ"} ,
		{"word":"capitaliste","word_nosc":"capitaliste","lemma":"capitaliste","pos":"ADJ"} ,
		{"word":"capitalistes","word_nosc":"capitalistes","lemma":"capitaliste","pos":"ADJ"} ,
		{"word":"capitalistiques","word_nosc":"capitalistiques","lemma":"capitalistique","pos":"ADJ"} ,
		{"word":"capitane","word_nosc":"capitane","lemma":"capitane","pos":"ADJ"} ,
		{"word":"capitaux","word_nosc":"capitaux","lemma":"capital","pos":"ADJ"} ,
		{"word":"capiteuse","word_nosc":"capiteuse","lemma":"capiteux","pos":"ADJ"} ,
		{"word":"capiteuses","word_nosc":"capiteuses","lemma":"capiteux","pos":"ADJ"} ,
		{"word":"capiteux","word_nosc":"capiteux","lemma":"capiteux","pos":"ADJ"} ,
		{"word":"capitonné","word_nosc":"capitonne","lemma":"capitonné","pos":"ADJ"} ,
		{"word":"capitonnée","word_nosc":"capitonnee","lemma":"capitonné","pos":"ADJ"} ,
		{"word":"capitonnées","word_nosc":"capitonnees","lemma":"capitonné","pos":"ADJ"} ,
		{"word":"capitonnés","word_nosc":"capitonnes","lemma":"capitonné","pos":"ADJ"} ,
		{"word":"capitulaire","word_nosc":"capitulaire","lemma":"capitulaire","pos":"ADJ"} ,
		{"word":"capon","word_nosc":"capon","lemma":"capon","pos":"ADJ"} ,
		{"word":"capons","word_nosc":"capons","lemma":"capon","pos":"ADJ"} ,
		{"word":"capot","word_nosc":"capot","lemma":"capot","pos":"ADJ"} ,
		{"word":"capricant","word_nosc":"capricant","lemma":"capricant","pos":"ADJ"} ,
		{"word":"capricants","word_nosc":"capricants","lemma":"capricant","pos":"ADJ"} ,
		{"word":"capricieuse","word_nosc":"capricieuse","lemma":"capricieux","pos":"ADJ"} ,
		{"word":"capricieuses","word_nosc":"capricieuses","lemma":"capricieux","pos":"ADJ"} ,
		{"word":"capricieux","word_nosc":"capricieux","lemma":"capricieux","pos":"ADJ"} ,
		{"word":"caprine","word_nosc":"caprine","lemma":"caprin","pos":"ADJ"} ,
		{"word":"capsulaire","word_nosc":"capsulaire","lemma":"capsulaire","pos":"ADJ"} ,
		{"word":"captieuse","word_nosc":"captieuse","lemma":"captieux","pos":"ADJ"} ,
		{"word":"captieux","word_nosc":"captieux","lemma":"captieux","pos":"ADJ"} ,
		{"word":"captif","word_nosc":"captif","lemma":"captif","pos":"ADJ"} ,
		{"word":"captifs","word_nosc":"captifs","lemma":"captif","pos":"ADJ"} ,
		{"word":"captivant","word_nosc":"captivant","lemma":"captivant","pos":"ADJ"} ,
		{"word":"captivante","word_nosc":"captivante","lemma":"captivant","pos":"ADJ"} ,
		{"word":"captivantes","word_nosc":"captivantes","lemma":"captivant","pos":"ADJ"} ,
		{"word":"captive","word_nosc":"captive","lemma":"captif","pos":"ADJ"} ,
		{"word":"captives","word_nosc":"captives","lemma":"captif","pos":"ADJ"} ,
		{"word":"capétien","word_nosc":"capetien","lemma":"capétien","pos":"ADJ"} ,
		{"word":"capétienne","word_nosc":"capetienne","lemma":"capétien","pos":"ADJ"} ,
		{"word":"capétiennes","word_nosc":"capetiennes","lemma":"capétien","pos":"ADJ"} ,
		{"word":"caquetant","word_nosc":"caquetant","lemma":"caquetant","pos":"ADJ"} ,
		{"word":"caquetante","word_nosc":"caquetante","lemma":"caquetant","pos":"ADJ"} ,
		{"word":"caquetantes","word_nosc":"caquetantes","lemma":"caquetant","pos":"ADJ"} ,
		{"word":"carabiné","word_nosc":"carabine","lemma":"carabiné","pos":"ADJ"} ,
		{"word":"carabinée","word_nosc":"carabinee","lemma":"carabiné","pos":"ADJ"} ,
		{"word":"carabinés","word_nosc":"carabines","lemma":"carabiné","pos":"ADJ"} ,
		{"word":"caracolant","word_nosc":"caracolant","lemma":"caracolant","pos":"ADJ"} ,
		{"word":"caractériel","word_nosc":"caracteriel","lemma":"caractériel","pos":"ADJ"} ,
		{"word":"caractérielle","word_nosc":"caracterielle","lemma":"caractériel","pos":"ADJ"} ,
		{"word":"caractérielles","word_nosc":"caracterielles","lemma":"caractériel","pos":"ADJ"} ,
		{"word":"caractériels","word_nosc":"caracteriels","lemma":"caractériel","pos":"ADJ"} ,
		{"word":"caractérisant","word_nosc":"caracterisant","lemma":"caractérisant","pos":"ADJ"} ,
		{"word":"caractéristique","word_nosc":"caracteristique","lemma":"caractéristique","pos":"ADJ"} ,
		{"word":"caractéristiques","word_nosc":"caracteristiques","lemma":"caractéristique","pos":"ADJ"} ,
		{"word":"caractérisé","word_nosc":"caracterise","lemma":"caractérisé","pos":"ADJ"} ,
		{"word":"caractérisée","word_nosc":"caracterisee","lemma":"caractérisé","pos":"ADJ"} ,
		{"word":"caractérisées","word_nosc":"caracterisees","lemma":"caractérisé","pos":"ADJ"} ,
		{"word":"caractérisés","word_nosc":"caracterises","lemma":"caractérisé","pos":"ADJ"} ,
		{"word":"caramel","word_nosc":"caramel","lemma":"caramel","pos":"ADJ"} ,
		{"word":"caramélisé","word_nosc":"caramelise","lemma":"caramélisé","pos":"ADJ"} ,
		{"word":"caramélisée","word_nosc":"caramelisee","lemma":"caramélisé","pos":"ADJ"} ,
		{"word":"caramélisées","word_nosc":"caramelisees","lemma":"caramélisé","pos":"ADJ"} ,
		{"word":"caramélisés","word_nosc":"caramelises","lemma":"caramélisé","pos":"ADJ"} ,
		{"word":"caraïbe","word_nosc":"caraibe","lemma":"caraïbe","pos":"ADJ"} ,
		{"word":"carbonique","word_nosc":"carbonique","lemma":"carbonique","pos":"ADJ"} ,
		{"word":"carboniques","word_nosc":"carboniques","lemma":"carbonique","pos":"ADJ"} ,
		{"word":"carboné","word_nosc":"carbone","lemma":"carboné","pos":"ADJ"} ,
		{"word":"carburant","word_nosc":"carburant","lemma":"carburant","pos":"ADJ"} ,
		{"word":"carburants","word_nosc":"carburants","lemma":"carburant","pos":"ADJ"} ,
		{"word":"carcinoïde","word_nosc":"carcinoide","lemma":"carcinoïde","pos":"ADJ"} ,
		{"word":"carcéral","word_nosc":"carceral","lemma":"carcéral","pos":"ADJ"} ,
		{"word":"carcérale","word_nosc":"carcerale","lemma":"carcéral","pos":"ADJ"} ,
		{"word":"carcérales","word_nosc":"carcerales","lemma":"carcéral","pos":"ADJ"} ,
		{"word":"carcéraux","word_nosc":"carceraux","lemma":"carcéral","pos":"ADJ"} ,
		{"word":"cardiaque","word_nosc":"cardiaque","lemma":"cardiaque","pos":"ADJ"} ,
		{"word":"cardiaques","word_nosc":"cardiaques","lemma":"cardiaque","pos":"ADJ"} ,
		{"word":"cardinal","word_nosc":"cardinal","lemma":"cardinal","pos":"ADJ"} ,
		{"word":"cardinal-archevêque","word_nosc":"cardinal-archeveque","lemma":"cardinal-archevêque","pos":"ADJ"} ,
		{"word":"cardinale","word_nosc":"cardinale","lemma":"cardinal","pos":"ADJ"} ,
		{"word":"cardinales","word_nosc":"cardinales","lemma":"cardinal","pos":"ADJ"} ,
		{"word":"cardinalice","word_nosc":"cardinalice","lemma":"cardinalice","pos":"ADJ"} ,
		{"word":"cardinalices","word_nosc":"cardinalices","lemma":"cardinalice","pos":"ADJ"} ,
		{"word":"cardinaux","word_nosc":"cardinaux","lemma":"cardinal","pos":"ADJ"} ,
		{"word":"cardio-pulmonaire","word_nosc":"cardio-pulmonaire","lemma":"cardio-pulmonaire","pos":"ADJ"} ,
		{"word":"cardio-respiratoire","word_nosc":"cardio-respiratoire","lemma":"cardio-respiratoire","pos":"ADJ"} ,
		{"word":"cardio-vasculaire","word_nosc":"cardio-vasculaire","lemma":"cardio-vasculaire","pos":"ADJ"} ,
		{"word":"cardiotonique","word_nosc":"cardiotonique","lemma":"cardiotonique","pos":"ADJ"} ,
		{"word":"cardiovasculaire","word_nosc":"cardiovasculaire","lemma":"cardiovasculaire","pos":"ADJ"} ,
		{"word":"cardioïde","word_nosc":"cardioide","lemma":"cardioïde","pos":"ADJ"} ,
		{"word":"caressant","word_nosc":"caressant","lemma":"caressant","pos":"ADJ"} ,
		{"word":"caressante","word_nosc":"caressante","lemma":"caressant","pos":"ADJ"} ,
		{"word":"caressantes","word_nosc":"caressantes","lemma":"caressant","pos":"ADJ"} ,
		{"word":"caressants","word_nosc":"caressants","lemma":"caressant","pos":"ADJ"} ,
		{"word":"caresseurs","word_nosc":"caresseurs","lemma":"caresseur","pos":"ADJ"} ,
		{"word":"caribéenne","word_nosc":"caribeenne","lemma":"caribéen","pos":"ADJ"} ,
		{"word":"caricatural","word_nosc":"caricatural","lemma":"caricatural","pos":"ADJ"} ,
		{"word":"caricaturale","word_nosc":"caricaturale","lemma":"caricatural","pos":"ADJ"} ,
		{"word":"caricaturales","word_nosc":"caricaturales","lemma":"caricatural","pos":"ADJ"} ,
		{"word":"caricaturaux","word_nosc":"caricaturaux","lemma":"caricatural","pos":"ADJ"} ,
		{"word":"carien","word_nosc":"carien","lemma":"carien","pos":"ADJ"} ,
		{"word":"carillonné","word_nosc":"carillonne","lemma":"carillonné","pos":"ADJ"} ,
		{"word":"carillonnées","word_nosc":"carillonnees","lemma":"carillonné","pos":"ADJ"} ,
		{"word":"carillonnés","word_nosc":"carillonnes","lemma":"carillonné","pos":"ADJ"} ,
		{"word":"carioca","word_nosc":"carioca","lemma":"carioca","pos":"ADJ"} ,
		{"word":"carissime","word_nosc":"carissime","lemma":"carissime","pos":"ADJ"} ,
		{"word":"caritatif","word_nosc":"caritatif","lemma":"caritatif","pos":"ADJ"} ,
		{"word":"caritatifs","word_nosc":"caritatifs","lemma":"caritatif","pos":"ADJ"} ,
		{"word":"caritative","word_nosc":"caritative","lemma":"caritatif","pos":"ADJ"} ,
		{"word":"caritatives","word_nosc":"caritatives","lemma":"caritatif","pos":"ADJ"} ,
		{"word":"carié","word_nosc":"carie","lemma":"carié","pos":"ADJ"} ,
		{"word":"cariée","word_nosc":"cariee","lemma":"carié","pos":"ADJ"} ,
		{"word":"cariées","word_nosc":"cariees","lemma":"carié","pos":"ADJ"} ,
		{"word":"carliste","word_nosc":"carliste","lemma":"carliste","pos":"ADJ"} ,
		{"word":"carlistes","word_nosc":"carlistes","lemma":"carliste","pos":"ADJ"} ,
		{"word":"carmin","word_nosc":"carmin","lemma":"carmin","pos":"ADJ"} ,
		{"word":"carminé","word_nosc":"carmine","lemma":"carminé","pos":"ADJ"} ,
		{"word":"carminée","word_nosc":"carminee","lemma":"carminé","pos":"ADJ"} ,
		{"word":"carminées","word_nosc":"carminees","lemma":"carminé","pos":"ADJ"} ,
		{"word":"carminés","word_nosc":"carmines","lemma":"carminé","pos":"ADJ"} ,
		{"word":"carnassier","word_nosc":"carnassier","lemma":"carnassier","pos":"ADJ"} ,
		{"word":"carnassiers","word_nosc":"carnassiers","lemma":"carnassier","pos":"ADJ"} ,
		{"word":"carnassière","word_nosc":"carnassiere","lemma":"carnassier","pos":"ADJ"} ,
		{"word":"carnassières","word_nosc":"carnassieres","lemma":"carnassier","pos":"ADJ"} ,
		{"word":"carnavalesque","word_nosc":"carnavalesque","lemma":"carnavalesque","pos":"ADJ"} ,
		{"word":"carnivore","word_nosc":"carnivore","lemma":"carnivore","pos":"ADJ"} ,
		{"word":"carnivores","word_nosc":"carnivores","lemma":"carnivore","pos":"ADJ"} ,
		{"word":"carné","word_nosc":"carne","lemma":"carné","pos":"ADJ"} ,
		{"word":"carnée","word_nosc":"carnee","lemma":"carné","pos":"ADJ"} ,
		{"word":"carnées","word_nosc":"carnees","lemma":"carné","pos":"ADJ"} ,
		{"word":"carnés","word_nosc":"carnes","lemma":"carné","pos":"ADJ"} ,
		{"word":"caroline","word_nosc":"caroline","lemma":"carolin","pos":"ADJ"} ,
		{"word":"carolines","word_nosc":"carolines","lemma":"carolin","pos":"ADJ"} ,
		{"word":"carolingien","word_nosc":"carolingien","lemma":"carolingien","pos":"ADJ"} ,
		{"word":"carolingienne","word_nosc":"carolingienne","lemma":"carolingien","pos":"ADJ"} ,
		{"word":"carolingiens","word_nosc":"carolingiens","lemma":"carolingien","pos":"ADJ"} ,
		{"word":"carolins","word_nosc":"carolins","lemma":"carolin","pos":"ADJ"} ,
		{"word":"carotidien","word_nosc":"carotidien","lemma":"carotidien","pos":"ADJ"} ,
		{"word":"carotidienne","word_nosc":"carotidienne","lemma":"carotidien","pos":"ADJ"} ,
		{"word":"carotte","word_nosc":"carotte","lemma":"carotte","pos":"ADJ"} ,
		{"word":"carotteur","word_nosc":"carotteur","lemma":"carotteur","pos":"ADJ"} ,
		{"word":"carotteuse","word_nosc":"carotteuse","lemma":"carotteur","pos":"ADJ"} ,
		{"word":"carottier","word_nosc":"carottier","lemma":"carottier","pos":"ADJ"} ,
		{"word":"carpien","word_nosc":"carpien","lemma":"carpien","pos":"ADJ"} ,
		{"word":"carpienne","word_nosc":"carpienne","lemma":"carpien","pos":"ADJ"} ,
		{"word":"carpé","word_nosc":"carpe","lemma":"carpé","pos":"ADJ"} ,
		{"word":"carrossable","word_nosc":"carrossable","lemma":"carrossable","pos":"ADJ"} ,
		{"word":"carrossables","word_nosc":"carrossables","lemma":"carrossable","pos":"ADJ"} ,
		{"word":"carré","word_nosc":"carre","lemma":"carré","pos":"ADJ"} ,
		{"word":"carrée","word_nosc":"carree","lemma":"carré","pos":"ADJ"} ,
		{"word":"carrées","word_nosc":"carrees","lemma":"carré","pos":"ADJ"} ,
		{"word":"carrés","word_nosc":"carres","lemma":"carré","pos":"ADJ"} ,
		{"word":"carthaginois","word_nosc":"carthaginois","lemma":"carthaginois","pos":"ADJ"} ,
		{"word":"carthaginoise","word_nosc":"carthaginoise","lemma":"carthaginois","pos":"ADJ"} ,
		{"word":"cartilagineuse","word_nosc":"cartilagineuse","lemma":"cartilagineux","pos":"ADJ"} ,
		{"word":"cartilagineuses","word_nosc":"cartilagineuses","lemma":"cartilagineux","pos":"ADJ"} ,
		{"word":"cartilagineux","word_nosc":"cartilagineux","lemma":"cartilagineux","pos":"ADJ"} ,
		{"word":"cartographique","word_nosc":"cartographique","lemma":"cartographique","pos":"ADJ"} ,
		{"word":"cartographiques","word_nosc":"cartographiques","lemma":"cartographique","pos":"ADJ"} ,
		{"word":"cartonneuse","word_nosc":"cartonneuse","lemma":"cartonneux","pos":"ADJ"} ,
		{"word":"cartonneuses","word_nosc":"cartonneuses","lemma":"cartonneux","pos":"ADJ"} ,
		{"word":"cartonneux","word_nosc":"cartonneux","lemma":"cartonneux","pos":"ADJ"} ,
		{"word":"cartonniers","word_nosc":"cartonniers","lemma":"cartonnier","pos":"ADJ"} ,
		{"word":"cartésien","word_nosc":"cartesien","lemma":"cartésien","pos":"ADJ"} ,
		{"word":"cartésienne","word_nosc":"cartesienne","lemma":"cartésien","pos":"ADJ"} ,
		{"word":"cartésiennes","word_nosc":"cartesiennes","lemma":"cartésien","pos":"ADJ"} ,
		{"word":"cartésiens","word_nosc":"cartesiens","lemma":"cartésien","pos":"ADJ"} ,
		{"word":"caréné","word_nosc":"carene","lemma":"caréné","pos":"ADJ"} ,
		{"word":"casablancaises","word_nosc":"casablancaises","lemma":"casablancais","pos":"ADJ"} ,
		{"word":"casanier","word_nosc":"casanier","lemma":"casanier","pos":"ADJ"} ,
		{"word":"casaniers","word_nosc":"casaniers","lemma":"casanier","pos":"ADJ"} ,
		{"word":"casanière","word_nosc":"casaniere","lemma":"casanier","pos":"ADJ"} ,
		{"word":"casanières","word_nosc":"casanieres","lemma":"casanier","pos":"ADJ"} ,
		{"word":"cascher","word_nosc":"cascher","lemma":"cascher","pos":"ADJ"} ,
		{"word":"casher","word_nosc":"casher","lemma":"casher","pos":"ADJ"} ,
		{"word":"caspiennes","word_nosc":"caspiennes","lemma":"caspien","pos":"ADJ"} ,
		{"word":"caspiennes","word_nosc":"caspiennes","lemma":"caspienne","pos":"ADJ"} ,
		{"word":"casquettés","word_nosc":"casquettes","lemma":"casquetté","pos":"ADJ"} ,
		{"word":"casqué","word_nosc":"casque","lemma":"casqué","pos":"ADJ"} ,
		{"word":"casquée","word_nosc":"casquee","lemma":"casqué","pos":"ADJ"} ,
		{"word":"casquées","word_nosc":"casquees","lemma":"casqué","pos":"ADJ"} ,
		{"word":"casqués","word_nosc":"casques","lemma":"casqué","pos":"ADJ"} ,
		{"word":"cassable","word_nosc":"cassable","lemma":"cassable","pos":"ADJ"} ,
		{"word":"cassables","word_nosc":"cassables","lemma":"cassable","pos":"ADJ"} ,
		{"word":"cassant","word_nosc":"cassant","lemma":"cassant","pos":"ADJ"} ,
		{"word":"cassante","word_nosc":"cassante","lemma":"cassant","pos":"ADJ"} ,
		{"word":"cassantes","word_nosc":"cassantes","lemma":"cassant","pos":"ADJ"} ,
		{"word":"cassants","word_nosc":"cassants","lemma":"cassant","pos":"ADJ"} ,
		{"word":"casse-cou","word_nosc":"casse-cou","lemma":"casse-cou","pos":"ADJ"} ,
		{"word":"casse-cul","word_nosc":"casse-cul","lemma":"casse-cul","pos":"ADJ"} ,
		{"word":"casse-gueule","word_nosc":"casse-gueule","lemma":"casse-gueule","pos":"ADJ"} ,
		{"word":"casse-pieds","word_nosc":"casse-pieds","lemma":"casse-pieds","pos":"ADJ"} ,
		{"word":"cassé","word_nosc":"casse","lemma":"cassé","pos":"ADJ"} ,
		{"word":"cassée","word_nosc":"cassee","lemma":"cassé","pos":"ADJ"} ,
		{"word":"cassées","word_nosc":"cassees","lemma":"cassé","pos":"ADJ"} ,
		{"word":"cassés","word_nosc":"casses","lemma":"cassé","pos":"ADJ"} ,
		{"word":"castillan","word_nosc":"castillan","lemma":"castillan","pos":"ADJ"} ,
		{"word":"castillane","word_nosc":"castillane","lemma":"castillan","pos":"ADJ"} ,
		{"word":"castillanes","word_nosc":"castillanes","lemma":"castillan","pos":"ADJ"} ,
		{"word":"castillans","word_nosc":"castillans","lemma":"castillan","pos":"ADJ"} ,
		{"word":"castrateur","word_nosc":"castrateur","lemma":"castrateur","pos":"ADJ"} ,
		{"word":"castratrice","word_nosc":"castratrice","lemma":"castrateur","pos":"ADJ"} ,
		{"word":"castratrices","word_nosc":"castratrices","lemma":"castrateur","pos":"ADJ"} ,
		{"word":"castriste","word_nosc":"castriste","lemma":"castriste","pos":"ADJ"} ,
		{"word":"castristes","word_nosc":"castristes","lemma":"castriste","pos":"ADJ"} ,
		{"word":"casuel","word_nosc":"casuel","lemma":"casuel","pos":"ADJ"} ,
		{"word":"casuistique","word_nosc":"casuistique","lemma":"casuistique","pos":"ADJ"} ,
		{"word":"casuistiques","word_nosc":"casuistiques","lemma":"casuistique","pos":"ADJ"} ,
		{"word":"caséeuse","word_nosc":"caseeuse","lemma":"caséeux","pos":"ADJ"} ,
		{"word":"catabolique","word_nosc":"catabolique","lemma":"catabolique","pos":"ADJ"} ,
		{"word":"cataclysmique","word_nosc":"cataclysmique","lemma":"cataclysmique","pos":"ADJ"} ,
		{"word":"cataclysmiques","word_nosc":"cataclysmiques","lemma":"cataclysmique","pos":"ADJ"} ,
		{"word":"catalan","word_nosc":"catalan","lemma":"catalan","pos":"ADJ"} ,
		{"word":"catalane","word_nosc":"catalane","lemma":"catalan","pos":"ADJ"} ,
		{"word":"catalanes","word_nosc":"catalanes","lemma":"catalan","pos":"ADJ"} ,
		{"word":"catalans","word_nosc":"catalans","lemma":"catalan","pos":"ADJ"} ,
		{"word":"catalauniques","word_nosc":"catalauniques","lemma":"catalaunique","pos":"ADJ"} ,
		{"word":"cataleptique","word_nosc":"cataleptique","lemma":"cataleptique","pos":"ADJ"} ,
		{"word":"catalyseur","word_nosc":"catalyseur","lemma":"catalyseur","pos":"ADJ"} ,
		{"word":"catalyseurs","word_nosc":"catalyseurs","lemma":"catalyseur","pos":"ADJ"} ,
		{"word":"catalytique","word_nosc":"catalytique","lemma":"catalytique","pos":"ADJ"} ,
		{"word":"catalytiques","word_nosc":"catalytiques","lemma":"catalytique","pos":"ADJ"} ,
		{"word":"catarrhal","word_nosc":"catarrhal","lemma":"catarrhal","pos":"ADJ"} ,
		{"word":"catarrheuse","word_nosc":"catarrheuse","lemma":"catarrheux","pos":"ADJ"} ,
		{"word":"catarrheux","word_nosc":"catarrheux","lemma":"catarrheux","pos":"ADJ"} ,
		{"word":"catastrophique","word_nosc":"catastrophique","lemma":"catastrophique","pos":"ADJ"} ,
		{"word":"catastrophiques","word_nosc":"catastrophiques","lemma":"catastrophique","pos":"ADJ"} ,
		{"word":"catastrophiste","word_nosc":"catastrophiste","lemma":"catastrophiste","pos":"ADJ"} ,
		{"word":"catatonique","word_nosc":"catatonique","lemma":"catatonique","pos":"ADJ"} ,
		{"word":"cathare","word_nosc":"cathare","lemma":"cathare","pos":"ADJ"} ,
		{"word":"cathares","word_nosc":"cathares","lemma":"cathare","pos":"ADJ"} ,
		{"word":"cathartique","word_nosc":"cathartique","lemma":"cathartique","pos":"ADJ"} ,
		{"word":"cathodique","word_nosc":"cathodique","lemma":"cathodique","pos":"ADJ"} ,
		{"word":"cathodiques","word_nosc":"cathodiques","lemma":"cathodique","pos":"ADJ"} ,
		{"word":"catholique","word_nosc":"catholique","lemma":"catholique","pos":"ADJ"} ,
		{"word":"catholiques","word_nosc":"catholiques","lemma":"catholique","pos":"ADJ"} ,
		{"word":"cathédral","word_nosc":"cathedral","lemma":"cathédral","pos":"ADJ"} ,
		{"word":"cathédrale","word_nosc":"cathedrale","lemma":"cathédrale","pos":"ADJ"} ,
		{"word":"cathédrales","word_nosc":"cathedrales","lemma":"cathédrale","pos":"ADJ"} ,
		{"word":"catégorique","word_nosc":"categorique","lemma":"catégorique","pos":"ADJ"} ,
		{"word":"catégoriques","word_nosc":"categoriques","lemma":"catégorique","pos":"ADJ"} ,
		{"word":"caucasien","word_nosc":"caucasien","lemma":"caucasien","pos":"ADJ"} ,
		{"word":"caucasienne","word_nosc":"caucasienne","lemma":"caucasien","pos":"ADJ"} ,
		{"word":"caucasiens","word_nosc":"caucasiens","lemma":"caucasien","pos":"ADJ"} ,
		{"word":"caucasique","word_nosc":"caucasique","lemma":"caucasique","pos":"ADJ"} ,
		{"word":"cauchemardesque","word_nosc":"cauchemardesque","lemma":"cauchemardesque","pos":"ADJ"} ,
		{"word":"cauchemardesques","word_nosc":"cauchemardesques","lemma":"cauchemardesque","pos":"ADJ"} ,
		{"word":"cauchemardeuse","word_nosc":"cauchemardeuse","lemma":"cauchemardeux","pos":"ADJ"} ,
		{"word":"cauchemardeuses","word_nosc":"cauchemardeuses","lemma":"cauchemardeux","pos":"ADJ"} ,
		{"word":"cauchemardeux","word_nosc":"cauchemardeux","lemma":"cauchemardeux","pos":"ADJ"} ,
		{"word":"cauchois","word_nosc":"cauchois","lemma":"cauchois","pos":"ADJ"} ,
		{"word":"cauchoises","word_nosc":"cauchoises","lemma":"cauchois","pos":"ADJ"} ,
		{"word":"caudal","word_nosc":"caudal","lemma":"caudal","pos":"ADJ"} ,
		{"word":"caudale","word_nosc":"caudale","lemma":"caudal","pos":"ADJ"} ,
		{"word":"caudales","word_nosc":"caudales","lemma":"caudal","pos":"ADJ"} ,
		{"word":"caudines","word_nosc":"caudines","lemma":"caudines","pos":"ADJ"} ,
		{"word":"causal","word_nosc":"causal","lemma":"causal","pos":"ADJ"} ,
		{"word":"causaliste","word_nosc":"causaliste","lemma":"causaliste","pos":"ADJ"} ,
		{"word":"causant","word_nosc":"causant","lemma":"causant","pos":"ADJ"} ,
		{"word":"causante","word_nosc":"causante","lemma":"causant","pos":"ADJ"} ,
		{"word":"causantes","word_nosc":"causantes","lemma":"causant","pos":"ADJ"} ,
		{"word":"causants","word_nosc":"causants","lemma":"causant","pos":"ADJ"} ,
		{"word":"causeur","word_nosc":"causeur","lemma":"causeur","pos":"ADJ"} ,
		{"word":"caussenard","word_nosc":"caussenard","lemma":"caussenard","pos":"ADJ"} ,
		{"word":"caustique","word_nosc":"caustique","lemma":"caustique","pos":"ADJ"} ,
		{"word":"caustiques","word_nosc":"caustiques","lemma":"caustique","pos":"ADJ"} ,
		{"word":"cauteleuse","word_nosc":"cauteleuse","lemma":"cauteleux","pos":"ADJ"} ,
		{"word":"cauteleuses","word_nosc":"cauteleuses","lemma":"cauteleux","pos":"ADJ"} ,
		{"word":"cauteleux","word_nosc":"cauteleux","lemma":"cauteleux","pos":"ADJ"} ,
		{"word":"cavaleur","word_nosc":"cavaleur","lemma":"cavaleur","pos":"ADJ"} ,
		{"word":"cavaleurs","word_nosc":"cavaleurs","lemma":"cavaleur","pos":"ADJ"} ,
		{"word":"cavalier","word_nosc":"cavalier","lemma":"cavalier","pos":"ADJ"} ,
		{"word":"cavaliers","word_nosc":"cavaliers","lemma":"cavalier","pos":"ADJ"} ,
		{"word":"cavalière","word_nosc":"cavaliere","lemma":"cavalier","pos":"ADJ"} ,
		{"word":"cavalières","word_nosc":"cavalieres","lemma":"cavalier","pos":"ADJ"} ,
		{"word":"cave","word_nosc":"cave","lemma":"cave","pos":"ADJ"} ,
		{"word":"cavecés","word_nosc":"caveces","lemma":"cavecé","pos":"ADJ"} ,
		{"word":"caverneuse","word_nosc":"caverneuse","lemma":"caverneux","pos":"ADJ"} ,
		{"word":"caverneuses","word_nosc":"caverneuses","lemma":"caverneux","pos":"ADJ"} ,
		{"word":"caverneux","word_nosc":"caverneux","lemma":"caverneux","pos":"ADJ"} ,
		{"word":"cavernicoles","word_nosc":"cavernicoles","lemma":"cavernicole","pos":"ADJ"} ,
		{"word":"caves","word_nosc":"caves","lemma":"cave","pos":"ADJ"} ,
		{"word":"cavillons","word_nosc":"cavillons","lemma":"cavillon","pos":"ADJ"} ,
		{"word":"ce","word_nosc":"ce","lemma":"ce","pos":"ADJ:dem"} ,
		{"word":"ceint","word_nosc":"ceint","lemma":"ceint","pos":"ADJ"} ,
		{"word":"cellulaire","word_nosc":"cellulaire","lemma":"cellulaire","pos":"ADJ"} ,
		{"word":"cellulaires","word_nosc":"cellulaires","lemma":"cellulaire","pos":"ADJ"} ,
		{"word":"cellules","word_nosc":"cellules","lemma":"cellules","pos":"ADJ"} ,
		{"word":"celluliteux","word_nosc":"celluliteux","lemma":"celluliteux","pos":"ADJ"} ,
		{"word":"cellérier","word_nosc":"cellerier","lemma":"cellérier","pos":"ADJ"} ,
		{"word":"celte","word_nosc":"celte","lemma":"celte","pos":"ADJ"} ,
		{"word":"celtes","word_nosc":"celtes","lemma":"celte","pos":"ADJ"} ,
		{"word":"celtique","word_nosc":"celtique","lemma":"celtique","pos":"ADJ"} ,
		{"word":"celtiques","word_nosc":"celtiques","lemma":"celtique","pos":"ADJ"} ,
		{"word":"celtisants","word_nosc":"celtisants","lemma":"celtisant","pos":"ADJ"} ,
		{"word":"cendreuse","word_nosc":"cendreuse","lemma":"cendreux","pos":"ADJ"} ,
		{"word":"cendreuses","word_nosc":"cendreuses","lemma":"cendreux","pos":"ADJ"} ,
		{"word":"cendreux","word_nosc":"cendreux","lemma":"cendreux","pos":"ADJ"} ,
		{"word":"cendré","word_nosc":"cendre","lemma":"cendré","pos":"ADJ"} ,
		{"word":"cendrée","word_nosc":"cendree","lemma":"cendré","pos":"ADJ"} ,
		{"word":"cendrées","word_nosc":"cendrees","lemma":"cendré","pos":"ADJ"} ,
		{"word":"cendrés","word_nosc":"cendres","lemma":"cendré","pos":"ADJ"} ,
		{"word":"censitaire","word_nosc":"censitaire","lemma":"censitaire","pos":"ADJ"} ,
		{"word":"censé","word_nosc":"cense","lemma":"censé","pos":"ADJ"} ,
		{"word":"censée","word_nosc":"censee","lemma":"censé","pos":"ADJ"} ,
		{"word":"censées","word_nosc":"censees","lemma":"censé","pos":"ADJ"} ,
		{"word":"censés","word_nosc":"censes","lemma":"censé","pos":"ADJ"} ,
		{"word":"cent","word_nosc":"cent","lemma":"cent","pos":"ADJ:num"} ,
		{"word":"centaines","word_nosc":"centaines","lemma":"centaines","pos":"ADJ:num"} ,
		{"word":"centenaire","word_nosc":"centenaire","lemma":"centenaire","pos":"ADJ"} ,
		{"word":"centenaires","word_nosc":"centenaires","lemma":"centenaire","pos":"ADJ"} ,
		{"word":"centenaires","word_nosc":"centenaires","lemma":"centenaires","pos":"ADJ:num"} ,
		{"word":"centième","word_nosc":"centieme","lemma":"centième","pos":"ADJ"} ,
		{"word":"centièmes","word_nosc":"centiemes","lemma":"centième","pos":"ADJ"} ,
		{"word":"centrafricain","word_nosc":"centrafricain","lemma":"centrafricain","pos":"ADJ"} ,
		{"word":"centrafricaine","word_nosc":"centrafricaine","lemma":"centrafricain","pos":"ADJ"} ,
		{"word":"central","word_nosc":"central","lemma":"central","pos":"ADJ"} ,
		{"word":"centrale","word_nosc":"centrale","lemma":"central","pos":"ADJ"} ,
		{"word":"centrales","word_nosc":"centrales","lemma":"central","pos":"ADJ"} ,
		{"word":"centrales","word_nosc":"centrales","lemma":"centrales","pos":"ADJ:num"} ,
		{"word":"centralisatrice","word_nosc":"centralisatrice","lemma":"centralisateur","pos":"ADJ"} ,
		{"word":"centraux","word_nosc":"centraux","lemma":"central","pos":"ADJ"} ,
		{"word":"centrifuge","word_nosc":"centrifuge","lemma":"centrifuge","pos":"ADJ"} ,
		{"word":"centrifuges","word_nosc":"centrifuges","lemma":"centrifuge","pos":"ADJ"} ,
		{"word":"centripète","word_nosc":"centripete","lemma":"centripète","pos":"ADJ"} ,
		{"word":"centripètes","word_nosc":"centripetes","lemma":"centripète","pos":"ADJ"} ,
		{"word":"centriste","word_nosc":"centriste","lemma":"centriste","pos":"ADJ"} ,
		{"word":"centré","word_nosc":"centre","lemma":"centré","pos":"ADJ"} ,
		{"word":"centrée","word_nosc":"centree","lemma":"centré","pos":"ADJ"} ,
		{"word":"centrés","word_nosc":"centres","lemma":"centré","pos":"ADJ"} ,
		{"word":"cents","word_nosc":"cents","lemma":"cents","pos":"ADJ:num"} ,
		{"word":"cerise","word_nosc":"cerise","lemma":"cerise","pos":"ADJ"} ,
		{"word":"cernable","word_nosc":"cernable","lemma":"cernable","pos":"ADJ"} ,
		{"word":"cerné","word_nosc":"cerne","lemma":"cerné","pos":"ADJ"} ,
		{"word":"cernée","word_nosc":"cernee","lemma":"cerné","pos":"ADJ"} ,
		{"word":"cernées","word_nosc":"cernees","lemma":"cerné","pos":"ADJ"} ,
		{"word":"cernés","word_nosc":"cernes","lemma":"cerné","pos":"ADJ"} ,
		{"word":"certain","word_nosc":"certain","lemma":"certain","pos":"ADJ"} ,
		{"word":"certain","word_nosc":"certain","lemma":"certain","pos":"ADJ:ind"} ,
		{"word":"certaine","word_nosc":"certaine","lemma":"certain","pos":"ADJ"} ,
		{"word":"certaine","word_nosc":"certaine","lemma":"certaine","pos":"ADJ:ind"} ,
		{"word":"certaines","word_nosc":"certaines","lemma":"certain","pos":"ADJ"} ,
		{"word":"certaines","word_nosc":"certaines","lemma":"certaines","pos":"ADJ:ind"} ,
		{"word":"certains","word_nosc":"certains","lemma":"certain","pos":"ADJ"} ,
		{"word":"certains","word_nosc":"certains","lemma":"certains","pos":"ADJ:ind"} ,
		{"word":"certifié","word_nosc":"certifie","lemma":"certifié","pos":"ADJ"} ,
		{"word":"certifiée","word_nosc":"certifiee","lemma":"certifié","pos":"ADJ"} ,
		{"word":"certifiées","word_nosc":"certifiees","lemma":"certifié","pos":"ADJ"} ,
		{"word":"certifiés","word_nosc":"certifies","lemma":"certifié","pos":"ADJ"} ,
		{"word":"cervical","word_nosc":"cervical","lemma":"cervical","pos":"ADJ"} ,
		{"word":"cervicale","word_nosc":"cervicale","lemma":"cervical","pos":"ADJ"} ,
		{"word":"cervicales","word_nosc":"cervicales","lemma":"cervical","pos":"ADJ"} ,
		{"word":"cervicaux","word_nosc":"cervicaux","lemma":"cervical","pos":"ADJ"} ,
		{"word":"ces","word_nosc":"ces","lemma":"ces","pos":"ADJ:dem"} ,
		{"word":"cessant","word_nosc":"cessant","lemma":"cessant","pos":"ADJ"} ,
		{"word":"cessante","word_nosc":"cessante","lemma":"cessant","pos":"ADJ"} ,
		{"word":"cessantes","word_nosc":"cessantes","lemma":"cessant","pos":"ADJ"} ,
		{"word":"cet","word_nosc":"cet","lemma":"cet","pos":"ADJ:dem"} ,
		{"word":"cette","word_nosc":"cette","lemma":"cette","pos":"ADJ:dem"} ,
		{"word":"ch'timi","word_nosc":"ch'timi","lemma":"ch'timi","pos":"ADJ"} ,
		{"word":"ch'timis","word_nosc":"ch'timis","lemma":"ch'timi","pos":"ADJ"} ,
		{"word":"chafouin","word_nosc":"chafouin","lemma":"chafouin","pos":"ADJ"} ,
		{"word":"chafouine","word_nosc":"chafouine","lemma":"chafouin","pos":"ADJ"} ,
		{"word":"chafouins","word_nosc":"chafouins","lemma":"chafouin","pos":"ADJ"} ,
		{"word":"chagrin","word_nosc":"chagrin","lemma":"chagrin","pos":"ADJ"} ,
		{"word":"chagrine","word_nosc":"chagrine","lemma":"chagrin","pos":"ADJ"} ,
		{"word":"chagrines","word_nosc":"chagrines","lemma":"chagrin","pos":"ADJ"} ,
		{"word":"chagrins","word_nosc":"chagrins","lemma":"chagrin","pos":"ADJ"} ,
		{"word":"chagriné","word_nosc":"chagrine","lemma":"chagriné","pos":"ADJ"} ,
		{"word":"chagrinée","word_nosc":"chagrinee","lemma":"chagriné","pos":"ADJ"} ,
		{"word":"chagrinés","word_nosc":"chagrines","lemma":"chagriné","pos":"ADJ"} ,
		{"word":"chahuteur","word_nosc":"chahuteur","lemma":"chahuteur","pos":"ADJ"} ,
		{"word":"chahuteurs","word_nosc":"chahuteurs","lemma":"chahuteur","pos":"ADJ"} ,
		{"word":"chair","word_nosc":"chair","lemma":"chair","pos":"ADJ"} ,
		{"word":"chaldaïques","word_nosc":"chaldaiques","lemma":"chaldaïque","pos":"ADJ"} ,
		{"word":"chaldéens","word_nosc":"chaldeens","lemma":"chaldéen","pos":"ADJ"} ,
		{"word":"chaleureuse","word_nosc":"chaleureuse","lemma":"chaleureux","pos":"ADJ"} ,
		{"word":"chaleureuses","word_nosc":"chaleureuses","lemma":"chaleureux","pos":"ADJ"} ,
		{"word":"chaleureux","word_nosc":"chaleureux","lemma":"chaleureux","pos":"ADJ"} ,
		{"word":"chalonnaises","word_nosc":"chalonnaises","lemma":"chalonnais","pos":"ADJ"} ,
		{"word":"chaloupé","word_nosc":"chaloupe","lemma":"chaloupé","pos":"ADJ"} ,
		{"word":"chaloupée","word_nosc":"chaloupee","lemma":"chaloupé","pos":"ADJ"} ,
		{"word":"chamailleur","word_nosc":"chamailleur","lemma":"chamailleur","pos":"ADJ"} ,
		{"word":"chamailleurs","word_nosc":"chamailleurs","lemma":"chamailleur","pos":"ADJ"} ,
		{"word":"chamailleuse","word_nosc":"chamailleuse","lemma":"chamailleur","pos":"ADJ"} ,
		{"word":"chamarré","word_nosc":"chamarre","lemma":"chamarré","pos":"ADJ"} ,
		{"word":"chamarrée","word_nosc":"chamarree","lemma":"chamarré","pos":"ADJ"} ,
		{"word":"chamarrées","word_nosc":"chamarrees","lemma":"chamarré","pos":"ADJ"} ,
		{"word":"chamarrés","word_nosc":"chamarres","lemma":"chamarré","pos":"ADJ"} ,
		{"word":"chamelier","word_nosc":"chamelier","lemma":"chamelier","pos":"ADJ"} ,
		{"word":"champagne","word_nosc":"champagne","lemma":"champagne","pos":"ADJ"} ,
		{"word":"champagnes","word_nosc":"champagnes","lemma":"champagne","pos":"ADJ"} ,
		{"word":"champenois","word_nosc":"champenois","lemma":"champenois","pos":"ADJ"} ,
		{"word":"champenoise","word_nosc":"champenoise","lemma":"champenois","pos":"ADJ"} ,
		{"word":"champenoises","word_nosc":"champenoises","lemma":"champenois","pos":"ADJ"} ,
		{"word":"champi","word_nosc":"champi","lemma":"champi","pos":"ADJ"} ,
		{"word":"champêtre","word_nosc":"champetre","lemma":"champêtre","pos":"ADJ"} ,
		{"word":"champêtres","word_nosc":"champetres","lemma":"champêtre","pos":"ADJ"} ,
		{"word":"chancelant","word_nosc":"chancelant","lemma":"chancelant","pos":"ADJ"} ,
		{"word":"chancelante","word_nosc":"chancelante","lemma":"chancelant","pos":"ADJ"} ,
		{"word":"chancelantes","word_nosc":"chancelantes","lemma":"chancelant","pos":"ADJ"} ,
		{"word":"chancelants","word_nosc":"chancelants","lemma":"chancelant","pos":"ADJ"} ,
		{"word":"chanceuse","word_nosc":"chanceuse","lemma":"chanceux","pos":"ADJ"} ,
		{"word":"chanceuses","word_nosc":"chanceuses","lemma":"chanceux","pos":"ADJ"} ,
		{"word":"chanceux","word_nosc":"chanceux","lemma":"chanceux","pos":"ADJ"} ,
		{"word":"changeant","word_nosc":"changeant","lemma":"changeant","pos":"ADJ"} ,
		{"word":"changeante","word_nosc":"changeante","lemma":"changeant","pos":"ADJ"} ,
		{"word":"changeantes","word_nosc":"changeantes","lemma":"changeant","pos":"ADJ"} ,
		{"word":"changeants","word_nosc":"changeants","lemma":"changeant","pos":"ADJ"} ,
		{"word":"changé","word_nosc":"change","lemma":"changé","pos":"ADJ"} ,
		{"word":"changée","word_nosc":"changee","lemma":"changé","pos":"ADJ"} ,
		{"word":"changées","word_nosc":"changees","lemma":"changé","pos":"ADJ"} ,
		{"word":"changés","word_nosc":"changes","lemma":"changé","pos":"ADJ"} ,
		{"word":"chantant","word_nosc":"chantant","lemma":"chantant","pos":"ADJ"} ,
		{"word":"chantante","word_nosc":"chantante","lemma":"chantant","pos":"ADJ"} ,
		{"word":"chantantes","word_nosc":"chantantes","lemma":"chantant","pos":"ADJ"} ,
		{"word":"chantants","word_nosc":"chantants","lemma":"chantant","pos":"ADJ"} ,
		{"word":"chantonnant","word_nosc":"chantonnant","lemma":"chantonnant","pos":"ADJ"} ,
		{"word":"chantonnante","word_nosc":"chantonnante","lemma":"chantonnant","pos":"ADJ"} ,
		{"word":"chançard","word_nosc":"chancard","lemma":"chançard","pos":"ADJ"} ,
		{"word":"chaotique","word_nosc":"chaotique","lemma":"chaotique","pos":"ADJ"} ,
		{"word":"chaotiques","word_nosc":"chaotiques","lemma":"chaotique","pos":"ADJ"} ,
		{"word":"chapeauté","word_nosc":"chapeaute","lemma":"chapeauté","pos":"ADJ"} ,
		{"word":"chapeautée","word_nosc":"chapeautee","lemma":"chapeauté","pos":"ADJ"} ,
		{"word":"chapeautées","word_nosc":"chapeautees","lemma":"chapeauté","pos":"ADJ"} ,
		{"word":"chapeautés","word_nosc":"chapeautes","lemma":"chapeauté","pos":"ADJ"} ,
		{"word":"chaplinesque","word_nosc":"chaplinesque","lemma":"chaplinesque","pos":"ADJ"} ,
		{"word":"chapés","word_nosc":"chapes","lemma":"chapé","pos":"ADJ"} ,
		{"word":"chaque","word_nosc":"chaque","lemma":"chaque","pos":"ADJ:ind"} ,
		{"word":"charançonné","word_nosc":"charanconne","lemma":"charançonné","pos":"ADJ"} ,
		{"word":"charançonnée","word_nosc":"charanconnee","lemma":"charançonné","pos":"ADJ"} ,
		{"word":"charançonnés","word_nosc":"charanconnes","lemma":"charançonné","pos":"ADJ"} ,
		{"word":"charbonneuse","word_nosc":"charbonneuse","lemma":"charbonneux","pos":"ADJ"} ,
		{"word":"charbonneuses","word_nosc":"charbonneuses","lemma":"charbonneux","pos":"ADJ"} ,
		{"word":"charbonneux","word_nosc":"charbonneux","lemma":"charbonneux","pos":"ADJ"} ,
		{"word":"charbonnier","word_nosc":"charbonnier","lemma":"charbonnier","pos":"ADJ"} ,
		{"word":"charbonniers","word_nosc":"charbonniers","lemma":"charbonnier","pos":"ADJ"} ,
		{"word":"charbonnière","word_nosc":"charbonniere","lemma":"charbonnier","pos":"ADJ"} ,
		{"word":"charbonnières","word_nosc":"charbonnieres","lemma":"charbonnier","pos":"ADJ"} ,
		{"word":"charentaise","word_nosc":"charentaise","lemma":"charentais","pos":"ADJ"} ,
		{"word":"charentaises","word_nosc":"charentaises","lemma":"charentais","pos":"ADJ"} ,
		{"word":"chargé","word_nosc":"charge","lemma":"chargé","pos":"ADJ"} ,
		{"word":"chargée","word_nosc":"chargee","lemma":"chargé","pos":"ADJ"} ,
		{"word":"chargées","word_nosc":"chargees","lemma":"chargé","pos":"ADJ"} ,
		{"word":"chargés","word_nosc":"charges","lemma":"chargé","pos":"ADJ"} ,
		{"word":"charismatique","word_nosc":"charismatique","lemma":"charismatique","pos":"ADJ"} ,
		{"word":"charitable","word_nosc":"charitable","lemma":"charitable","pos":"ADJ"} ,
		{"word":"charitables","word_nosc":"charitables","lemma":"charitable","pos":"ADJ"} ,
		{"word":"charleston","word_nosc":"charleston","lemma":"charleston","pos":"ADJ"} ,
		{"word":"charmant","word_nosc":"charmant","lemma":"charmant","pos":"ADJ"} ,
		{"word":"charmante","word_nosc":"charmante","lemma":"charmant","pos":"ADJ"} ,
		{"word":"charmantes","word_nosc":"charmantes","lemma":"charmant","pos":"ADJ"} ,
		{"word":"charmants","word_nosc":"charmants","lemma":"charmant","pos":"ADJ"} ,
		{"word":"charmeur","word_nosc":"charmeur","lemma":"charmeur","pos":"ADJ"} ,
		{"word":"charmeurs","word_nosc":"charmeurs","lemma":"charmeur","pos":"ADJ"} ,
		{"word":"charmeuse","word_nosc":"charmeuse","lemma":"charmeur","pos":"ADJ"} ,
		{"word":"charmeuses","word_nosc":"charmeuses","lemma":"charmeur","pos":"ADJ"} ,
		{"word":"charmé","word_nosc":"charme","lemma":"charmé","pos":"ADJ"} ,
		{"word":"charmée","word_nosc":"charmee","lemma":"charmé","pos":"ADJ"} ,
		{"word":"charmés","word_nosc":"charmes","lemma":"charmé","pos":"ADJ"} ,
		{"word":"charnel","word_nosc":"charnel","lemma":"charnel","pos":"ADJ"} ,
		{"word":"charnelle","word_nosc":"charnelle","lemma":"charnel","pos":"ADJ"} ,
		{"word":"charnelles","word_nosc":"charnelles","lemma":"charnel","pos":"ADJ"} ,
		{"word":"charnels","word_nosc":"charnels","lemma":"charnel","pos":"ADJ"} ,
		{"word":"charnu","word_nosc":"charnu","lemma":"charnu","pos":"ADJ"} ,
		{"word":"charnue","word_nosc":"charnue","lemma":"charnu","pos":"ADJ"} ,
		{"word":"charnues","word_nosc":"charnues","lemma":"charnu","pos":"ADJ"} ,
		{"word":"charnus","word_nosc":"charnus","lemma":"charnu","pos":"ADJ"} ,
		{"word":"charolaises","word_nosc":"charolaises","lemma":"charolais","pos":"ADJ"} ,
		{"word":"charpenté","word_nosc":"charpente","lemma":"charpenté","pos":"ADJ"} ,
		{"word":"charpentée","word_nosc":"charpentee","lemma":"charpenté","pos":"ADJ"} ,
		{"word":"charpentés","word_nosc":"charpentes","lemma":"charpenté","pos":"ADJ"} ,
		{"word":"charriant","word_nosc":"charriant","lemma":"charriant","pos":"ADJ"} ,
		{"word":"charrieurs","word_nosc":"charrieurs","lemma":"charrieur","pos":"ADJ"} ,
		{"word":"charrié","word_nosc":"charrie","lemma":"charrié","pos":"ADJ"} ,
		{"word":"chartiste","word_nosc":"chartiste","lemma":"chartiste","pos":"ADJ"} ,
		{"word":"chassant","word_nosc":"chassant","lemma":"chassant","pos":"ADJ"} ,
		{"word":"chassieuse","word_nosc":"chassieuse","lemma":"chassieux","pos":"ADJ"} ,
		{"word":"chassieux","word_nosc":"chassieux","lemma":"chassieux","pos":"ADJ"} ,
		{"word":"chassé","word_nosc":"chasse","lemma":"chassé","pos":"ADJ"} ,
		{"word":"chassée","word_nosc":"chassee","lemma":"chassé","pos":"ADJ"} ,
		{"word":"chassées","word_nosc":"chassees","lemma":"chassé","pos":"ADJ"} ,
		{"word":"chassés","word_nosc":"chasses","lemma":"chassé","pos":"ADJ"} ,
		{"word":"chaste","word_nosc":"chaste","lemma":"chaste","pos":"ADJ"} ,
		{"word":"chastes","word_nosc":"chastes","lemma":"chaste","pos":"ADJ"} ,
		{"word":"chatouilleuse","word_nosc":"chatouilleuse","lemma":"chatouilleux","pos":"ADJ"} ,
		{"word":"chatouilleuses","word_nosc":"chatouilleuses","lemma":"chatouilleux","pos":"ADJ"} ,
		{"word":"chatouilleux","word_nosc":"chatouilleux","lemma":"chatouilleux","pos":"ADJ"} ,
		{"word":"chatoyant","word_nosc":"chatoyant","lemma":"chatoyant","pos":"ADJ"} ,
		{"word":"chatoyante","word_nosc":"chatoyante","lemma":"chatoyant","pos":"ADJ"} ,
		{"word":"chatoyantes","word_nosc":"chatoyantes","lemma":"chatoyant","pos":"ADJ"} ,
		{"word":"chatoyants","word_nosc":"chatoyants","lemma":"chatoyant","pos":"ADJ"} ,
		{"word":"chaud","word_nosc":"chaud","lemma":"chaud","pos":"ADJ"} ,
		{"word":"chaude","word_nosc":"chaude","lemma":"chaud","pos":"ADJ"} ,
		{"word":"chaudes","word_nosc":"chaudes","lemma":"chaud","pos":"ADJ"} ,
		{"word":"chauds","word_nosc":"chauds","lemma":"chaud","pos":"ADJ"} ,
		{"word":"chauffant","word_nosc":"chauffant","lemma":"chauffant","pos":"ADJ"} ,
		{"word":"chauffante","word_nosc":"chauffante","lemma":"chauffant","pos":"ADJ"} ,
		{"word":"chauffantes","word_nosc":"chauffantes","lemma":"chauffant","pos":"ADJ"} ,
		{"word":"chauffants","word_nosc":"chauffants","lemma":"chauffant","pos":"ADJ"} ,
		{"word":"chaussant","word_nosc":"chaussant","lemma":"chaussant","pos":"ADJ"} ,
		{"word":"chaussures","word_nosc":"chaussures","lemma":"chaussures","pos":"ADJ"} ,
		{"word":"chaussé","word_nosc":"chausse","lemma":"chaussé","pos":"ADJ"} ,
		{"word":"chaussée","word_nosc":"chaussee","lemma":"chaussé","pos":"ADJ"} ,
		{"word":"chaussées","word_nosc":"chaussees","lemma":"chaussé","pos":"ADJ"} ,
		{"word":"chaussés","word_nosc":"chausses","lemma":"chaussé","pos":"ADJ"} ,
		{"word":"chauve","word_nosc":"chauve","lemma":"chauve","pos":"ADJ"} ,
		{"word":"chauves","word_nosc":"chauves","lemma":"chauve","pos":"ADJ"} ,
		{"word":"chauvin","word_nosc":"chauvin","lemma":"chauvin","pos":"ADJ"} ,
		{"word":"chauvines","word_nosc":"chauvines","lemma":"chauvin","pos":"ADJ"} ,
		{"word":"chauvins","word_nosc":"chauvins","lemma":"chauvin","pos":"ADJ"} ,
		{"word":"chavirant","word_nosc":"chavirant","lemma":"chavirant","pos":"ADJ"} ,
		{"word":"chavirante","word_nosc":"chavirante","lemma":"chavirant","pos":"ADJ"} ,
		{"word":"cheap","word_nosc":"cheap","lemma":"cheap","pos":"ADJ"} ,
		{"word":"chenillé","word_nosc":"chenille","lemma":"chenillé","pos":"ADJ"} ,
		{"word":"chenillés","word_nosc":"chenilles","lemma":"chenillé","pos":"ADJ"} ,
		{"word":"chenu","word_nosc":"chenu","lemma":"chenu","pos":"ADJ"} ,
		{"word":"chenue","word_nosc":"chenue","lemma":"chenu","pos":"ADJ"} ,
		{"word":"chenues","word_nosc":"chenues","lemma":"chenu","pos":"ADJ"} ,
		{"word":"chenus","word_nosc":"chenus","lemma":"chenu","pos":"ADJ"} ,
		{"word":"cher","word_nosc":"cher","lemma":"cher","pos":"ADJ"} ,
		{"word":"chercheur","word_nosc":"chercheur","lemma":"chercheur","pos":"ADJ"} ,
		{"word":"chercheurs","word_nosc":"chercheurs","lemma":"chercheur","pos":"ADJ"} ,
		{"word":"chercheuse","word_nosc":"chercheuse","lemma":"chercheur","pos":"ADJ"} ,
		{"word":"chercheuses","word_nosc":"chercheuses","lemma":"chercheur","pos":"ADJ"} ,
		{"word":"chers","word_nosc":"chers","lemma":"cher","pos":"ADJ"} ,
		{"word":"chevaleresque","word_nosc":"chevaleresque","lemma":"chevaleresque","pos":"ADJ"} ,
		{"word":"chevaleresques","word_nosc":"chevaleresques","lemma":"chevaleresque","pos":"ADJ"} ,
		{"word":"chevalin","word_nosc":"chevalin","lemma":"chevalin","pos":"ADJ"} ,
		{"word":"chevaline","word_nosc":"chevaline","lemma":"chevalin","pos":"ADJ"} ,
		{"word":"chevalins","word_nosc":"chevalins","lemma":"chevalin","pos":"ADJ"} ,
		{"word":"chevauchant","word_nosc":"chevauchant","lemma":"chevauchant","pos":"ADJ"} ,
		{"word":"chevelu","word_nosc":"chevelu","lemma":"chevelu","pos":"ADJ"} ,
		{"word":"chevelue","word_nosc":"chevelue","lemma":"chevelu","pos":"ADJ"} ,
		{"word":"chevelues","word_nosc":"chevelues","lemma":"chevelu","pos":"ADJ"} ,
		{"word":"chevelus","word_nosc":"chevelus","lemma":"chevelu","pos":"ADJ"} ,
		{"word":"chevronné","word_nosc":"chevronne","lemma":"chevronné","pos":"ADJ"} ,
		{"word":"chevronnés","word_nosc":"chevronnes","lemma":"chevronné","pos":"ADJ"} ,
		{"word":"chevrotante","word_nosc":"chevrotante","lemma":"chevrotant","pos":"ADJ"} ,
		{"word":"chialeur","word_nosc":"chialeur","lemma":"chialeur","pos":"ADJ"} ,
		{"word":"chiant","word_nosc":"chiant","lemma":"chiant","pos":"ADJ"} ,
		{"word":"chiante","word_nosc":"chiante","lemma":"chiant","pos":"ADJ"} ,
		{"word":"chiantes","word_nosc":"chiantes","lemma":"chiant","pos":"ADJ"} ,
		{"word":"chiants","word_nosc":"chiants","lemma":"chiant","pos":"ADJ"} ,
		{"word":"chiasseux","word_nosc":"chiasseux","lemma":"chiasseux","pos":"ADJ"} ,
		{"word":"chiatique","word_nosc":"chiatique","lemma":"chiatique","pos":"ADJ"} ,
		{"word":"chiatiques","word_nosc":"chiatiques","lemma":"chiatique","pos":"ADJ"} ,
		{"word":"chic","word_nosc":"chic","lemma":"chic","pos":"ADJ"} ,
		{"word":"chic-type","word_nosc":"chic-type","lemma":"chic-type","pos":"ADJ"} ,
		{"word":"chicaneur","word_nosc":"chicaneur","lemma":"chicaneur","pos":"ADJ"} ,
		{"word":"chicanier","word_nosc":"chicanier","lemma":"chicanier","pos":"ADJ"} ,
		{"word":"chicanière","word_nosc":"chicaniere","lemma":"chicanier","pos":"ADJ"} ,
		{"word":"chicano","word_nosc":"chicano","lemma":"chicano","pos":"ADJ"} ,
		{"word":"chicanos","word_nosc":"chicanos","lemma":"chicano","pos":"ADJ"} ,
		{"word":"chiche","word_nosc":"chiche","lemma":"chiche","pos":"ADJ"} ,
		{"word":"chiches","word_nosc":"chiches","lemma":"chiche","pos":"ADJ"} ,
		{"word":"chichiteuse","word_nosc":"chichiteuse","lemma":"chichiteux","pos":"ADJ"} ,
		{"word":"chichiteuses","word_nosc":"chichiteuses","lemma":"chichiteux","pos":"ADJ"} ,
		{"word":"chichiteux","word_nosc":"chichiteux","lemma":"chichiteux","pos":"ADJ"} ,
		{"word":"chics","word_nosc":"chics","lemma":"chic","pos":"ADJ"} ,
		{"word":"chiens","word_nosc":"chiens","lemma":"chiens","pos":"ADJ"} ,
		{"word":"chiffonné","word_nosc":"chiffonne","lemma":"chiffonné","pos":"ADJ"} ,
		{"word":"chiffonnée","word_nosc":"chiffonnee","lemma":"chiffonné","pos":"ADJ"} ,
		{"word":"chiffonnées","word_nosc":"chiffonnees","lemma":"chiffonné","pos":"ADJ"} ,
		{"word":"chiffonnés","word_nosc":"chiffonnes","lemma":"chiffonné","pos":"ADJ"} ,
		{"word":"chiffrable","word_nosc":"chiffrable","lemma":"chiffrable","pos":"ADJ"} ,
		{"word":"chiffré","word_nosc":"chiffre","lemma":"chiffré","pos":"ADJ"} ,
		{"word":"chiffrée","word_nosc":"chiffree","lemma":"chiffré","pos":"ADJ"} ,
		{"word":"chiffrées","word_nosc":"chiffrees","lemma":"chiffré","pos":"ADJ"} ,
		{"word":"chiffrés","word_nosc":"chiffres","lemma":"chiffré","pos":"ADJ"} ,
		{"word":"chilien","word_nosc":"chilien","lemma":"chilien","pos":"ADJ"} ,
		{"word":"chilienne","word_nosc":"chilienne","lemma":"chilien","pos":"ADJ"} ,
		{"word":"chiliennes","word_nosc":"chiliennes","lemma":"chilien","pos":"ADJ"} ,
		{"word":"chiliens","word_nosc":"chiliens","lemma":"chilien","pos":"ADJ"} ,
		{"word":"chimique","word_nosc":"chimique","lemma":"chimique","pos":"ADJ"} ,
		{"word":"chimiques","word_nosc":"chimiques","lemma":"chimique","pos":"ADJ"} ,
		{"word":"chimérique","word_nosc":"chimerique","lemma":"chimérique","pos":"ADJ"} ,
		{"word":"chimériques","word_nosc":"chimeriques","lemma":"chimérique","pos":"ADJ"} ,
		{"word":"chinois","word_nosc":"chinois","lemma":"chinois","pos":"ADJ"} ,
		{"word":"chinoise","word_nosc":"chinoise","lemma":"chinois","pos":"ADJ"} ,
		{"word":"chinoises","word_nosc":"chinoises","lemma":"chinois","pos":"ADJ"} ,
		{"word":"chiné","word_nosc":"chine","lemma":"chiné","pos":"ADJ"} ,
		{"word":"chinée","word_nosc":"chinee","lemma":"chiné","pos":"ADJ"} ,
		{"word":"chinés","word_nosc":"chines","lemma":"chiné","pos":"ADJ"} ,
		{"word":"chipeur","word_nosc":"chipeur","lemma":"chipeur","pos":"ADJ"} ,
		{"word":"chipoteur","word_nosc":"chipoteur","lemma":"chipoteur","pos":"ADJ"} ,
		{"word":"chipoteuse","word_nosc":"chipoteuse","lemma":"chipoteur","pos":"ADJ"} ,
		{"word":"chippendale","word_nosc":"chippendale","lemma":"chippendale","pos":"ADJ"} ,
		{"word":"chiraquien","word_nosc":"chiraquien","lemma":"chiraquien","pos":"ADJ"} ,
		{"word":"chiricahua","word_nosc":"chiricahua","lemma":"chiricahua","pos":"ADJ"} ,
		{"word":"chiricahuas","word_nosc":"chiricahuas","lemma":"chiricahua","pos":"ADJ"} ,
		{"word":"chirurgical","word_nosc":"chirurgical","lemma":"chirurgical","pos":"ADJ"} ,
		{"word":"chirurgicale","word_nosc":"chirurgicale","lemma":"chirurgical","pos":"ADJ"} ,
		{"word":"chirurgicales","word_nosc":"chirurgicales","lemma":"chirurgical","pos":"ADJ"} ,
		{"word":"chirurgicaux","word_nosc":"chirurgicaux","lemma":"chirurgical","pos":"ADJ"} ,
		{"word":"chitineux","word_nosc":"chitineux","lemma":"chitineux","pos":"ADJ"} ,
		{"word":"chlass","word_nosc":"chlass","lemma":"chlass","pos":"ADJ"} ,
		{"word":"chleuh","word_nosc":"chleuh","lemma":"chleuh","pos":"ADJ"} ,
		{"word":"chleuhs","word_nosc":"chleuhs","lemma":"chleuh","pos":"ADJ"} ,
		{"word":"chlorhydrique","word_nosc":"chlorhydrique","lemma":"chlorhydrique","pos":"ADJ"} ,
		{"word":"chlorique","word_nosc":"chlorique","lemma":"chlorique","pos":"ADJ"} ,
		{"word":"chlorophyllien","word_nosc":"chlorophyllien","lemma":"chlorophyllien","pos":"ADJ"} ,
		{"word":"chlorotique","word_nosc":"chlorotique","lemma":"chlorotique","pos":"ADJ"} ,
		{"word":"chlorotiques","word_nosc":"chlorotiques","lemma":"chlorotique","pos":"ADJ"} ,
		{"word":"chloré","word_nosc":"chlore","lemma":"chloré","pos":"ADJ"} ,
		{"word":"chlorée","word_nosc":"chloree","lemma":"chloré","pos":"ADJ"} ,
		{"word":"chnoque","word_nosc":"chnoque","lemma":"chnoque","pos":"ADJ"} ,
		{"word":"choc","word_nosc":"choc","lemma":"choc","pos":"ADJ"} ,
		{"word":"chochotte","word_nosc":"chochotte","lemma":"chochotte","pos":"ADJ"} ,
		{"word":"chochottes","word_nosc":"chochottes","lemma":"chochotte","pos":"ADJ"} ,
		{"word":"chocolat","word_nosc":"chocolat","lemma":"chocolat","pos":"ADJ"} ,
		{"word":"chocolaté","word_nosc":"chocolate","lemma":"chocolaté","pos":"ADJ"} ,
		{"word":"chocolatée","word_nosc":"chocolatee","lemma":"chocolaté","pos":"ADJ"} ,
		{"word":"chocolatées","word_nosc":"chocolatees","lemma":"chocolaté","pos":"ADJ"} ,
		{"word":"chocolatés","word_nosc":"chocolates","lemma":"chocolaté","pos":"ADJ"} ,
		{"word":"chocs","word_nosc":"chocs","lemma":"choc","pos":"ADJ"} ,
		{"word":"choisi","word_nosc":"choisi","lemma":"choisi","pos":"ADJ"} ,
		{"word":"choisie","word_nosc":"choisie","lemma":"choisi","pos":"ADJ"} ,
		{"word":"choisies","word_nosc":"choisies","lemma":"choisi","pos":"ADJ"} ,
		{"word":"choisis","word_nosc":"choisis","lemma":"choisi","pos":"ADJ"} ,
		{"word":"cholique","word_nosc":"cholique","lemma":"cholique","pos":"ADJ"} ,
		{"word":"cholédoque","word_nosc":"choledoque","lemma":"cholédoque","pos":"ADJ"} ,
		{"word":"choquant","word_nosc":"choquant","lemma":"choquant","pos":"ADJ"} ,
		{"word":"choquante","word_nosc":"choquante","lemma":"choquant","pos":"ADJ"} ,
		{"word":"choquantes","word_nosc":"choquantes","lemma":"choquant","pos":"ADJ"} ,
		{"word":"choquants","word_nosc":"choquants","lemma":"choquant","pos":"ADJ"} ,
		{"word":"choqué","word_nosc":"choque","lemma":"choqué","pos":"ADJ"} ,
		{"word":"choquée","word_nosc":"choquee","lemma":"choqué","pos":"ADJ"} ,
		{"word":"choquées","word_nosc":"choquees","lemma":"choqué","pos":"ADJ"} ,
		{"word":"choqués","word_nosc":"choques","lemma":"choqué","pos":"ADJ"} ,
		{"word":"choral","word_nosc":"choral","lemma":"choral","pos":"ADJ"} ,
		{"word":"chorale","word_nosc":"chorale","lemma":"choral","pos":"ADJ"} ,
		{"word":"chorales","word_nosc":"chorales","lemma":"choral","pos":"ADJ"} ,
		{"word":"choraux","word_nosc":"choraux","lemma":"choral","pos":"ADJ"} ,
		{"word":"chorégraphique","word_nosc":"choregraphique","lemma":"chorégraphique","pos":"ADJ"} ,
		{"word":"chorégraphiques","word_nosc":"choregraphiques","lemma":"chorégraphique","pos":"ADJ"} ,
		{"word":"choucard","word_nosc":"choucard","lemma":"choucard","pos":"ADJ"} ,
		{"word":"choucarde","word_nosc":"choucarde","lemma":"choucard","pos":"ADJ"} ,
		{"word":"choucardes","word_nosc":"choucardes","lemma":"choucard","pos":"ADJ"} ,
		{"word":"choucards","word_nosc":"choucards","lemma":"choucard","pos":"ADJ"} ,
		{"word":"chouette","word_nosc":"chouette","lemma":"chouette","pos":"ADJ"} ,
		{"word":"chouettes","word_nosc":"chouettes","lemma":"chouette","pos":"ADJ"} ,
		{"word":"choyé","word_nosc":"choye","lemma":"choyé","pos":"ADJ"} ,
		{"word":"choyée","word_nosc":"choyee","lemma":"choyé","pos":"ADJ"} ,
		{"word":"choyées","word_nosc":"choyees","lemma":"choyé","pos":"ADJ"} ,
		{"word":"choyés","word_nosc":"choyes","lemma":"choyé","pos":"ADJ"} ,
		{"word":"christique","word_nosc":"christique","lemma":"christique","pos":"ADJ"} ,
		{"word":"christologique","word_nosc":"christologique","lemma":"christologique","pos":"ADJ"} ,
		{"word":"chromatique","word_nosc":"chromatique","lemma":"chromatique","pos":"ADJ"} ,
		{"word":"chromatiques","word_nosc":"chromatiques","lemma":"chromatique","pos":"ADJ"} ,
		{"word":"chromogènes","word_nosc":"chromogenes","lemma":"chromogène","pos":"ADJ"} ,
		{"word":"chromosomique","word_nosc":"chromosomique","lemma":"chromosomique","pos":"ADJ"} ,
		{"word":"chromosomiques","word_nosc":"chromosomiques","lemma":"chromosomique","pos":"ADJ"} ,
		{"word":"chronique","word_nosc":"chronique","lemma":"chronique","pos":"ADJ"} ,
		{"word":"chroniques","word_nosc":"chroniques","lemma":"chronique","pos":"ADJ"} ,
		{"word":"chronologique","word_nosc":"chronologique","lemma":"chronologique","pos":"ADJ"} ,
		{"word":"chronologiques","word_nosc":"chronologiques","lemma":"chronologique","pos":"ADJ"} ,
		{"word":"chronophotographique","word_nosc":"chronophotographique","lemma":"chronophotographique","pos":"ADJ"} ,
		{"word":"chrétien","word_nosc":"chretien","lemma":"chrétien","pos":"ADJ"} ,
		{"word":"chrétien-démocrate","word_nosc":"chretien-democrate","lemma":"chrétien-démocrate","pos":"ADJ"} ,
		{"word":"chrétienne","word_nosc":"chretienne","lemma":"chrétien","pos":"ADJ"} ,
		{"word":"chrétiennes","word_nosc":"chretiennes","lemma":"chrétien","pos":"ADJ"} ,
		{"word":"chrétiens","word_nosc":"chretiens","lemma":"chrétien","pos":"ADJ"} ,
		{"word":"chrétiens-démocrates","word_nosc":"chretiens-democrates","lemma":"chrétien-démocrate","pos":"ADJ"} ,
		{"word":"chtarbé","word_nosc":"chtarbe","lemma":"chtarbé","pos":"ADJ"} ,
		{"word":"chtarbée","word_nosc":"chtarbee","lemma":"chtarbé","pos":"ADJ"} ,
		{"word":"chtarbées","word_nosc":"chtarbees","lemma":"chtarbé","pos":"ADJ"} ,
		{"word":"chthoniennes","word_nosc":"chthoniennes","lemma":"chthonien","pos":"ADJ"} ,
		{"word":"chtimi","word_nosc":"chtimi","lemma":"chtimi","pos":"ADJ"} ,
		{"word":"chuchotant","word_nosc":"chuchotant","lemma":"chuchotant","pos":"ADJ"} ,
		{"word":"chuchotante","word_nosc":"chuchotante","lemma":"chuchotant","pos":"ADJ"} ,
		{"word":"chuchotantes","word_nosc":"chuchotantes","lemma":"chuchotant","pos":"ADJ"} ,
		{"word":"chuchotants","word_nosc":"chuchotants","lemma":"chuchotant","pos":"ADJ"} ,
		{"word":"chuchoteur","word_nosc":"chuchoteur","lemma":"chuchoteur","pos":"ADJ"} ,
		{"word":"chuchoteuse","word_nosc":"chuchoteuse","lemma":"chuchoteur","pos":"ADJ"} ,
		{"word":"chuintant","word_nosc":"chuintant","lemma":"chuintant","pos":"ADJ"} ,
		{"word":"chuintante","word_nosc":"chuintante","lemma":"chuintant","pos":"ADJ"} ,
		{"word":"chuintants","word_nosc":"chuintants","lemma":"chuintant","pos":"ADJ"} ,
		{"word":"churrigueresque","word_nosc":"churrigueresque","lemma":"churrigueresque","pos":"ADJ"} ,
		{"word":"chypriote","word_nosc":"chypriote","lemma":"chypriote","pos":"ADJ"} ,
		{"word":"châlonnais","word_nosc":"chalonnais","lemma":"châlonnais","pos":"ADJ"} ,
		{"word":"châtaigne","word_nosc":"chataigne","lemma":"châtaigne","pos":"ADJ"} ,
		{"word":"châtain","word_nosc":"chatain","lemma":"châtain","pos":"ADJ"} ,
		{"word":"châtaine","word_nosc":"chataine","lemma":"châtain","pos":"ADJ"} ,
		{"word":"châtaines","word_nosc":"chataines","lemma":"châtain","pos":"ADJ"} ,
		{"word":"châtains","word_nosc":"chatains","lemma":"châtain","pos":"ADJ"} ,
		{"word":"châtel","word_nosc":"chatel","lemma":"châtel","pos":"ADJ"} ,
		{"word":"châtré","word_nosc":"chatre","lemma":"châtré","pos":"ADJ"} ,
		{"word":"châtrés","word_nosc":"chatres","lemma":"châtré","pos":"ADJ"} ,
		{"word":"chère","word_nosc":"chere","lemma":"cher","pos":"ADJ"} ,
		{"word":"chères","word_nosc":"cheres","lemma":"cher","pos":"ADJ"} ,
		{"word":"chébran","word_nosc":"chebran","lemma":"chébran","pos":"ADJ"} ,
		{"word":"chéri","word_nosc":"cheri","lemma":"chéri","pos":"ADJ"} ,
		{"word":"chérie","word_nosc":"cherie","lemma":"chéri","pos":"ADJ"} ,
		{"word":"chéries","word_nosc":"cheries","lemma":"chéri","pos":"ADJ"} ,
		{"word":"chérifienne","word_nosc":"cherifienne","lemma":"chérifien","pos":"ADJ"} ,
		{"word":"chéris","word_nosc":"cheris","lemma":"chéri","pos":"ADJ"} ,
		{"word":"chérot","word_nosc":"cherot","lemma":"chérot","pos":"ADJ"} ,
		{"word":"chérubinique","word_nosc":"cherubinique","lemma":"chérubinique","pos":"ADJ"} ,
		{"word":"chétif","word_nosc":"chetif","lemma":"chétif","pos":"ADJ"} ,
		{"word":"chétifs","word_nosc":"chetifs","lemma":"chétif","pos":"ADJ"} ,
		{"word":"chétive","word_nosc":"chetive","lemma":"chétif","pos":"ADJ"} ,
		{"word":"chétives","word_nosc":"chetives","lemma":"chétif","pos":"ADJ"} ,
		{"word":"chômé","word_nosc":"chome","lemma":"chômé","pos":"ADJ"} ,
		{"word":"chômée","word_nosc":"chomee","lemma":"chômé","pos":"ADJ"} ,
		{"word":"chômés","word_nosc":"chomes","lemma":"chômé","pos":"ADJ"} ,
		{"word":"ci-inclus","word_nosc":"ci-inclus","lemma":"ci-inclus","pos":"ADJ"} ,
		{"word":"ci-incluse","word_nosc":"ci-incluse","lemma":"ci-inclus","pos":"ADJ"} ,
		{"word":"ci-incluses","word_nosc":"ci-incluses","lemma":"ci-inclus","pos":"ADJ"} ,
		{"word":"ci-joint","word_nosc":"ci-joint","lemma":"ci-joint","pos":"ADJ"} ,
		{"word":"ci-jointe","word_nosc":"ci-jointe","lemma":"ci-joint","pos":"ADJ"} ,
		{"word":"ci-joints","word_nosc":"ci-joints","lemma":"ci-joint","pos":"ADJ"} ,
		{"word":"cicatriciel","word_nosc":"cicatriciel","lemma":"cicatriciel","pos":"ADJ"} ,
		{"word":"cicatriciels","word_nosc":"cicatriciels","lemma":"cicatriciel","pos":"ADJ"} ,
		{"word":"cicatrisable","word_nosc":"cicatrisable","lemma":"cicatrisable","pos":"ADJ"} ,
		{"word":"cicatrisante","word_nosc":"cicatrisante","lemma":"cicatrisant","pos":"ADJ"} ,
		{"word":"cicatrisants","word_nosc":"cicatrisants","lemma":"cicatrisant","pos":"ADJ"} ,
		{"word":"cicéronienne","word_nosc":"ciceronienne","lemma":"cicéronien","pos":"ADJ"} ,
		{"word":"ciliaires","word_nosc":"ciliaires","lemma":"ciliaire","pos":"ADJ"} ,
		{"word":"cinghalais","word_nosc":"cinghalais","lemma":"cinghalais","pos":"ADJ"} ,
		{"word":"cinghalaises","word_nosc":"cinghalaises","lemma":"cinghalais","pos":"ADJ"} ,
		{"word":"cinglant","word_nosc":"cinglant","lemma":"cinglant","pos":"ADJ"} ,
		{"word":"cinglante","word_nosc":"cinglante","lemma":"cinglant","pos":"ADJ"} ,
		{"word":"cinglantes","word_nosc":"cinglantes","lemma":"cinglant","pos":"ADJ"} ,
		{"word":"cinglants","word_nosc":"cinglants","lemma":"cinglant","pos":"ADJ"} ,
		{"word":"cinglé","word_nosc":"cingle","lemma":"cinglé","pos":"ADJ"} ,
		{"word":"cinglée","word_nosc":"cinglee","lemma":"cinglé","pos":"ADJ"} ,
		{"word":"cinglées","word_nosc":"cinglees","lemma":"cinglé","pos":"ADJ"} ,
		{"word":"cinglés","word_nosc":"cingles","lemma":"cinglé","pos":"ADJ"} ,
		{"word":"cinoque","word_nosc":"cinoque","lemma":"cinoque","pos":"ADJ"} ,
		{"word":"cinq","word_nosc":"cinq","lemma":"cinq","pos":"ADJ:num"} ,
		{"word":"cinquante","word_nosc":"cinquante","lemma":"cinquante","pos":"ADJ:num"} ,
		{"word":"cinquante-cinq","word_nosc":"cinquante-cinq","lemma":"cinquante-cinq","pos":"ADJ:num"} ,
		{"word":"cinquante-deux","word_nosc":"cinquante-deux","lemma":"cinquante-deux","pos":"ADJ:num"} ,
		{"word":"cinquante-huit","word_nosc":"cinquante-huit","lemma":"cinquante-huit","pos":"ADJ:num"} ,
		{"word":"cinquante-huitième","word_nosc":"cinquante-huitieme","lemma":"cinquante-huitième","pos":"ADJ"} ,
		{"word":"cinquante-neuf","word_nosc":"cinquante-neuf","lemma":"cinquante-neuf","pos":"ADJ:num"} ,
		{"word":"cinquante-neuvième","word_nosc":"cinquante-neuvieme","lemma":"cinquante-neuvième","pos":"ADJ"} ,
		{"word":"cinquante-quatre","word_nosc":"cinquante-quatre","lemma":"cinquante-quatre","pos":"ADJ:num"} ,
		{"word":"cinquante-sept","word_nosc":"cinquante-sept","lemma":"cinquante-sept","pos":"ADJ:num"} ,
		{"word":"cinquante-septième","word_nosc":"cinquante-septieme","lemma":"cinquante-septième","pos":"ADJ"} ,
		{"word":"cinquante-six","word_nosc":"cinquante-six","lemma":"cinquante-six","pos":"ADJ:num"} ,
		{"word":"cinquante-sixième","word_nosc":"cinquante-sixieme","lemma":"cinquante-sixième","pos":"ADJ"} ,
		{"word":"cinquante-trois","word_nosc":"cinquante-trois","lemma":"cinquante-trois","pos":"ADJ:num"} ,
		{"word":"cinquante-troisième","word_nosc":"cinquante-troisieme","lemma":"cinquante-troisième","pos":"ADJ"} ,
		{"word":"cinquantième","word_nosc":"cinquantieme","lemma":"cinquantième","pos":"ADJ"} ,
		{"word":"cinquantièmes","word_nosc":"cinquantiemes","lemma":"cinquantième","pos":"ADJ"} ,
		{"word":"cinquième","word_nosc":"cinquieme","lemma":"cinquième","pos":"ADJ"} ,
		{"word":"cinquièmes","word_nosc":"cinquiemes","lemma":"cinquième","pos":"ADJ"} ,
		{"word":"cintré","word_nosc":"cintre","lemma":"cintré","pos":"ADJ"} ,
		{"word":"cintrée","word_nosc":"cintree","lemma":"cintré","pos":"ADJ"} ,
		{"word":"cintrées","word_nosc":"cintrees","lemma":"cintré","pos":"ADJ"} ,
		{"word":"cintrés","word_nosc":"cintres","lemma":"cintré","pos":"ADJ"} ,
		{"word":"cinématographique","word_nosc":"cinematographique","lemma":"cinématographique","pos":"ADJ"} ,
		{"word":"cinématographiques","word_nosc":"cinematographiques","lemma":"cinématographique","pos":"ADJ"} ,
		{"word":"cinéphile","word_nosc":"cinephile","lemma":"cinéphile","pos":"ADJ"} ,
		{"word":"cinéphiles","word_nosc":"cinephiles","lemma":"cinéphile","pos":"ADJ"} ,
		{"word":"cinéraire","word_nosc":"cineraire","lemma":"cinéraire","pos":"ADJ"} ,
		{"word":"cinétique","word_nosc":"cinetique","lemma":"cinétique","pos":"ADJ"} ,
		{"word":"circadien","word_nosc":"circadien","lemma":"circadien","pos":"ADJ"} ,
		{"word":"circassienne","word_nosc":"circassienne","lemma":"circassien","pos":"ADJ"} ,
		{"word":"circoncis","word_nosc":"circoncis","lemma":"circoncis","pos":"ADJ"} ,
		{"word":"circonflexe","word_nosc":"circonflexe","lemma":"circonflexe","pos":"ADJ"} ,
		{"word":"circonflexes","word_nosc":"circonflexes","lemma":"circonflexe","pos":"ADJ"} ,
		{"word":"circonspect","word_nosc":"circonspect","lemma":"circonspect","pos":"ADJ"} ,
		{"word":"circonspecte","word_nosc":"circonspecte","lemma":"circonspect","pos":"ADJ"} ,
		{"word":"circonspectes","word_nosc":"circonspectes","lemma":"circonspect","pos":"ADJ"} ,
		{"word":"circonspects","word_nosc":"circonspects","lemma":"circonspect","pos":"ADJ"} ,
		{"word":"circonstanciel","word_nosc":"circonstanciel","lemma":"circonstanciel","pos":"ADJ"} ,
		{"word":"circonstancielle","word_nosc":"circonstancielle","lemma":"circonstanciel","pos":"ADJ"} ,
		{"word":"circonstanciels","word_nosc":"circonstanciels","lemma":"circonstanciel","pos":"ADJ"} ,
		{"word":"circonstancié","word_nosc":"circonstancie","lemma":"circonstancié","pos":"ADJ"} ,
		{"word":"circonstanciées","word_nosc":"circonstanciees","lemma":"circonstancié","pos":"ADJ"} ,
		{"word":"circonstanciés","word_nosc":"circonstancies","lemma":"circonstancié","pos":"ADJ"} ,
		{"word":"circulaire","word_nosc":"circulaire","lemma":"circulaire","pos":"ADJ"} ,
		{"word":"circulaires","word_nosc":"circulaires","lemma":"circulaire","pos":"ADJ"} ,
		{"word":"circulant","word_nosc":"circulant","lemma":"circulant","pos":"ADJ"} ,
		{"word":"circulatoire","word_nosc":"circulatoire","lemma":"circulatoire","pos":"ADJ"} ,
		{"word":"circulatoires","word_nosc":"circulatoires","lemma":"circulatoire","pos":"ADJ"} ,
		{"word":"cireuse","word_nosc":"cireuse","lemma":"cireux","pos":"ADJ"} ,
		{"word":"cireuses","word_nosc":"cireuses","lemma":"cireux","pos":"ADJ"} ,
		{"word":"cireux","word_nosc":"cireux","lemma":"cireux","pos":"ADJ"} ,
		{"word":"cirrhotique","word_nosc":"cirrhotique","lemma":"cirrhotique","pos":"ADJ"} ,
		{"word":"ciré","word_nosc":"cire","lemma":"ciré","pos":"ADJ"} ,
		{"word":"cirés","word_nosc":"cires","lemma":"ciré","pos":"ADJ"} ,
		{"word":"cis","word_nosc":"cis","lemma":"cis","pos":"ADJ"} ,
		{"word":"cisaillant","word_nosc":"cisaillant","lemma":"cisaillant","pos":"ADJ"} ,
		{"word":"ciselé","word_nosc":"cisele","lemma":"ciselé","pos":"ADJ"} ,
		{"word":"ciselée","word_nosc":"ciselee","lemma":"ciselé","pos":"ADJ"} ,
		{"word":"ciselées","word_nosc":"ciselees","lemma":"ciselé","pos":"ADJ"} ,
		{"word":"ciselés","word_nosc":"ciseles","lemma":"ciselé","pos":"ADJ"} ,
		{"word":"cistercien","word_nosc":"cistercien","lemma":"cistercien","pos":"ADJ"} ,
		{"word":"cistercienne","word_nosc":"cistercienne","lemma":"cistercien","pos":"ADJ"} ,
		{"word":"cisterciens","word_nosc":"cisterciens","lemma":"cistercien","pos":"ADJ"} ,
		{"word":"citadin","word_nosc":"citadin","lemma":"citadin","pos":"ADJ"} ,
		{"word":"citadine","word_nosc":"citadine","lemma":"citadin","pos":"ADJ"} ,
		{"word":"citadines","word_nosc":"citadines","lemma":"citadin","pos":"ADJ"} ,
		{"word":"citadins","word_nosc":"citadins","lemma":"citadin","pos":"ADJ"} ,
		{"word":"citrine","word_nosc":"citrine","lemma":"citrine","pos":"ADJ"} ,
		{"word":"citrique","word_nosc":"citrique","lemma":"citrique","pos":"ADJ"} ,
		{"word":"citron","word_nosc":"citron","lemma":"citron","pos":"ADJ"} ,
		{"word":"citronné","word_nosc":"citronne","lemma":"citronné","pos":"ADJ"} ,
		{"word":"citronnée","word_nosc":"citronnee","lemma":"citronné","pos":"ADJ"} ,
		{"word":"cité","word_nosc":"cite","lemma":"cité","pos":"ADJ"} ,
		{"word":"citée","word_nosc":"citee","lemma":"cité","pos":"ADJ"} ,
		{"word":"citées","word_nosc":"citees","lemma":"cité","pos":"ADJ"} ,
		{"word":"citérieure","word_nosc":"citerieure","lemma":"citérieur","pos":"ADJ"} ,
		{"word":"cités","word_nosc":"cites","lemma":"cité","pos":"ADJ"} ,
		{"word":"civil","word_nosc":"civil","lemma":"civil","pos":"ADJ"} ,
		{"word":"civile","word_nosc":"civile","lemma":"civil","pos":"ADJ"} ,
		{"word":"civiles","word_nosc":"civiles","lemma":"civil","pos":"ADJ"} ,
		{"word":"civilisateur","word_nosc":"civilisateur","lemma":"civilisateur","pos":"ADJ"} ,
		{"word":"civilisatrice","word_nosc":"civilisatrice","lemma":"civilisateur","pos":"ADJ"} ,
		{"word":"civilisé","word_nosc":"civilise","lemma":"civilisé","pos":"ADJ"} ,
		{"word":"civilisée","word_nosc":"civilisee","lemma":"civilisé","pos":"ADJ"} ,
		{"word":"civilisées","word_nosc":"civilisees","lemma":"civilisé","pos":"ADJ"} ,
		{"word":"civilisés","word_nosc":"civilises","lemma":"civilisé","pos":"ADJ"} ,
		{"word":"civils","word_nosc":"civils","lemma":"civil","pos":"ADJ"} ,
		{"word":"civique","word_nosc":"civique","lemma":"civique","pos":"ADJ"} ,
		{"word":"civiques","word_nosc":"civiques","lemma":"civique","pos":"ADJ"} ,
		{"word":"clair","word_nosc":"clair","lemma":"clair","pos":"ADJ"} ,
		{"word":"claire","word_nosc":"claire","lemma":"clair","pos":"ADJ"} ,
		{"word":"claires","word_nosc":"claires","lemma":"clair","pos":"ADJ"} ,
		{"word":"clairette","word_nosc":"clairette","lemma":"clairet","pos":"ADJ"} ,
		{"word":"claironnant","word_nosc":"claironnant","lemma":"claironnant","pos":"ADJ"} ,
		{"word":"claironnante","word_nosc":"claironnante","lemma":"claironnant","pos":"ADJ"} ,
		{"word":"claironnantes","word_nosc":"claironnantes","lemma":"claironnant","pos":"ADJ"} ,
		{"word":"claironnants","word_nosc":"claironnants","lemma":"claironnant","pos":"ADJ"} ,
		{"word":"clairs","word_nosc":"clairs","lemma":"clair","pos":"ADJ"} ,
		{"word":"clairsemé","word_nosc":"clairseme","lemma":"clairsemé","pos":"ADJ"} ,
		{"word":"clairsemée","word_nosc":"clairsemee","lemma":"clairsemé","pos":"ADJ"} ,
		{"word":"clairsemées","word_nosc":"clairsemees","lemma":"clairsemé","pos":"ADJ"} ,
		{"word":"clairsemés","word_nosc":"clairsemes","lemma":"clairsemé","pos":"ADJ"} ,
		{"word":"clairvoyant","word_nosc":"clairvoyant","lemma":"clairvoyant","pos":"ADJ"} ,
		{"word":"clairvoyante","word_nosc":"clairvoyante","lemma":"clairvoyant","pos":"ADJ"} ,
		{"word":"clairvoyants","word_nosc":"clairvoyants","lemma":"clairvoyant","pos":"ADJ"} ,
		{"word":"clandestin","word_nosc":"clandestin","lemma":"clandestin","pos":"ADJ"} ,
		{"word":"clandestine","word_nosc":"clandestine","lemma":"clandestin","pos":"ADJ"} ,
		{"word":"clandestines","word_nosc":"clandestines","lemma":"clandestin","pos":"ADJ"} ,
		{"word":"clandestins","word_nosc":"clandestins","lemma":"clandestin","pos":"ADJ"} ,
		{"word":"clapotant","word_nosc":"clapotant","lemma":"clapotant","pos":"ADJ"} ,
		{"word":"clapotante","word_nosc":"clapotante","lemma":"clapotant","pos":"ADJ"} ,
		{"word":"clapotantes","word_nosc":"clapotantes","lemma":"clapotant","pos":"ADJ"} ,
		{"word":"claquant","word_nosc":"claquant","lemma":"claquant","pos":"ADJ"} ,
		{"word":"claquante","word_nosc":"claquante","lemma":"claquant","pos":"ADJ"} ,
		{"word":"claquantes","word_nosc":"claquantes","lemma":"claquant","pos":"ADJ"} ,
		{"word":"claquants","word_nosc":"claquants","lemma":"claquant","pos":"ADJ"} ,
		{"word":"claquemurée","word_nosc":"claquemuree","lemma":"claquemuré","pos":"ADJ"} ,
		{"word":"claquemurées","word_nosc":"claquemurees","lemma":"claquemuré","pos":"ADJ"} ,
		{"word":"class","word_nosc":"class","lemma":"class","pos":"ADJ"} ,
		{"word":"classable","word_nosc":"classable","lemma":"classable","pos":"ADJ"} ,
		{"word":"classieuse","word_nosc":"classieuse","lemma":"classieux","pos":"ADJ"} ,
		{"word":"classieux","word_nosc":"classieux","lemma":"classieux","pos":"ADJ"} ,
		{"word":"classifiables","word_nosc":"classifiables","lemma":"classifiable","pos":"ADJ"} ,
		{"word":"classificateur","word_nosc":"classificateur","lemma":"classificateur","pos":"ADJ"} ,
		{"word":"classificatrice","word_nosc":"classificatrice","lemma":"classificateur","pos":"ADJ"} ,
		{"word":"classique","word_nosc":"classique","lemma":"classique","pos":"ADJ"} ,
		{"word":"classiques","word_nosc":"classiques","lemma":"classique","pos":"ADJ"} ,
		{"word":"classé","word_nosc":"classe","lemma":"classé","pos":"ADJ"} ,
		{"word":"classée","word_nosc":"classee","lemma":"classé","pos":"ADJ"} ,
		{"word":"classées","word_nosc":"classees","lemma":"classé","pos":"ADJ"} ,
		{"word":"classés","word_nosc":"classes","lemma":"classé","pos":"ADJ"} ,
		{"word":"claudicant","word_nosc":"claudicant","lemma":"claudicant","pos":"ADJ"} ,
		{"word":"claudicante","word_nosc":"claudicante","lemma":"claudicant","pos":"ADJ"} ,
		{"word":"claudicants","word_nosc":"claudicants","lemma":"claudicant","pos":"ADJ"} ,
		{"word":"claudélienne","word_nosc":"claudelienne","lemma":"claudélien","pos":"ADJ"} ,
		{"word":"clausewitziens","word_nosc":"clausewitziens","lemma":"clausewitzien","pos":"ADJ"} ,
		{"word":"claustrale","word_nosc":"claustrale","lemma":"claustral","pos":"ADJ"} ,
		{"word":"claustrophobe","word_nosc":"claustrophobe","lemma":"claustrophobe","pos":"ADJ"} ,
		{"word":"clean","word_nosc":"clean","lemma":"clean","pos":"ADJ"} ,
		{"word":"clermontoise","word_nosc":"clermontoise","lemma":"clermontois","pos":"ADJ"} ,
		{"word":"cliché","word_nosc":"cliche","lemma":"cliché","pos":"ADJ"} ,
		{"word":"clichée","word_nosc":"clichee","lemma":"cliché","pos":"ADJ"} ,
		{"word":"clichés","word_nosc":"cliches","lemma":"cliché","pos":"ADJ"} ,
		{"word":"clignotant","word_nosc":"clignotant","lemma":"clignotant","pos":"ADJ"} ,
		{"word":"clignotante","word_nosc":"clignotante","lemma":"clignotant","pos":"ADJ"} ,
		{"word":"clignotantes","word_nosc":"clignotantes","lemma":"clignotant","pos":"ADJ"} ,
		{"word":"clignotants","word_nosc":"clignotants","lemma":"clignotant","pos":"ADJ"} ,
		{"word":"climatique","word_nosc":"climatique","lemma":"climatique","pos":"ADJ"} ,
		{"word":"climatiques","word_nosc":"climatiques","lemma":"climatique","pos":"ADJ"} ,
		{"word":"climatisé","word_nosc":"climatise","lemma":"climatisé","pos":"ADJ"} ,
		{"word":"climatisée","word_nosc":"climatisee","lemma":"climatisé","pos":"ADJ"} ,
		{"word":"climatisées","word_nosc":"climatisees","lemma":"climatisé","pos":"ADJ"} ,
		{"word":"climatisés","word_nosc":"climatises","lemma":"climatisé","pos":"ADJ"} ,
		{"word":"climatériques","word_nosc":"climateriques","lemma":"climatérique","pos":"ADJ"} ,
		{"word":"clinique","word_nosc":"clinique","lemma":"clinique","pos":"ADJ"} ,
		{"word":"cliniques","word_nosc":"cliniques","lemma":"clinique","pos":"ADJ"} ,
		{"word":"clinquant","word_nosc":"clinquant","lemma":"clinquant","pos":"ADJ"} ,
		{"word":"clinquante","word_nosc":"clinquante","lemma":"clinquant","pos":"ADJ"} ,
		{"word":"clinquantes","word_nosc":"clinquantes","lemma":"clinquant","pos":"ADJ"} ,
		{"word":"clinquants","word_nosc":"clinquants","lemma":"clinquant","pos":"ADJ"} ,
		{"word":"cliquetant","word_nosc":"cliquetant","lemma":"cliquetant","pos":"ADJ"} ,
		{"word":"cliquetante","word_nosc":"cliquetante","lemma":"cliquetant","pos":"ADJ"} ,
		{"word":"cliquetantes","word_nosc":"cliquetantes","lemma":"cliquetant","pos":"ADJ"} ,
		{"word":"cliquetants","word_nosc":"cliquetants","lemma":"cliquetant","pos":"ADJ"} ,
		{"word":"clitoridien","word_nosc":"clitoridien","lemma":"clitoridien","pos":"ADJ"} ,
		{"word":"clitoridienne","word_nosc":"clitoridienne","lemma":"clitoridien","pos":"ADJ"} ,
		{"word":"clivés","word_nosc":"clives","lemma":"clivé","pos":"ADJ"} ,
		{"word":"cloacal","word_nosc":"cloacal","lemma":"cloacal","pos":"ADJ"} ,
		{"word":"cloche","word_nosc":"cloche","lemma":"cloche","pos":"ADJ"} ,
		{"word":"cloches","word_nosc":"cloches","lemma":"cloche","pos":"ADJ"} ,
		{"word":"cloisonné","word_nosc":"cloisonne","lemma":"cloisonné","pos":"ADJ"} ,
		{"word":"cloisonnée","word_nosc":"cloisonnee","lemma":"cloisonné","pos":"ADJ"} ,
		{"word":"cloisonnées","word_nosc":"cloisonnees","lemma":"cloisonné","pos":"ADJ"} ,
		{"word":"cloisonnés","word_nosc":"cloisonnes","lemma":"cloisonné","pos":"ADJ"} ,
		{"word":"clonique","word_nosc":"clonique","lemma":"clonique","pos":"ADJ"} ,
		{"word":"cloqué","word_nosc":"cloque","lemma":"cloqué","pos":"ADJ"} ,
		{"word":"cloquée","word_nosc":"cloquee","lemma":"cloqué","pos":"ADJ"} ,
		{"word":"cloqués","word_nosc":"cloques","lemma":"cloqué","pos":"ADJ"} ,
		{"word":"clos","word_nosc":"clos","lemma":"clos","pos":"ADJ"} ,
		{"word":"close","word_nosc":"close","lemma":"clos","pos":"ADJ"} ,
		{"word":"closes","word_nosc":"closes","lemma":"clos","pos":"ADJ"} ,
		{"word":"clouté","word_nosc":"cloute","lemma":"clouté","pos":"ADJ"} ,
		{"word":"cloutée","word_nosc":"cloutee","lemma":"clouté","pos":"ADJ"} ,
		{"word":"cloutées","word_nosc":"cloutees","lemma":"clouté","pos":"ADJ"} ,
		{"word":"cloutés","word_nosc":"cloutes","lemma":"clouté","pos":"ADJ"} ,
		{"word":"cloué","word_nosc":"cloue","lemma":"cloué","pos":"ADJ"} ,
		{"word":"clouée","word_nosc":"clouee","lemma":"cloué","pos":"ADJ"} ,
		{"word":"clouées","word_nosc":"clouees","lemma":"cloué","pos":"ADJ"} ,
		{"word":"cloués","word_nosc":"cloues","lemma":"cloué","pos":"ADJ"} ,
		{"word":"clownesque","word_nosc":"clownesque","lemma":"clownesque","pos":"ADJ"} ,
		{"word":"clownesques","word_nosc":"clownesques","lemma":"clownesque","pos":"ADJ"} ,
		{"word":"cloîtré","word_nosc":"cloitre","lemma":"cloîtré","pos":"ADJ"} ,
		{"word":"cloîtrée","word_nosc":"cloitree","lemma":"cloîtré","pos":"ADJ"} ,
		{"word":"cloîtrées","word_nosc":"cloitrees","lemma":"cloîtré","pos":"ADJ"} ,
		{"word":"cloîtrés","word_nosc":"cloitres","lemma":"cloîtré","pos":"ADJ"} ,
		{"word":"clunisien","word_nosc":"clunisien","lemma":"clunisien","pos":"ADJ"} ,
		{"word":"clément","word_nosc":"clement","lemma":"clément","pos":"ADJ"} ,
		{"word":"clémente","word_nosc":"clemente","lemma":"clément","pos":"ADJ"} ,
		{"word":"clémentes","word_nosc":"clementes","lemma":"clément","pos":"ADJ"} ,
		{"word":"cléments","word_nosc":"clements","lemma":"clément","pos":"ADJ"} ,
		{"word":"clérical","word_nosc":"clerical","lemma":"clérical","pos":"ADJ"} ,
		{"word":"cléricale","word_nosc":"clericale","lemma":"clérical","pos":"ADJ"} ,
		{"word":"cléricales","word_nosc":"clericales","lemma":"clérical","pos":"ADJ"} ,
		{"word":"cléricaux","word_nosc":"clericaux","lemma":"clérical","pos":"ADJ"} ,
		{"word":"co-dépendante","word_nosc":"co-dependante","lemma":"co-dépendant","pos":"ADJ"} ,
		{"word":"co-pilote","word_nosc":"co-pilote","lemma":"co-pilote","pos":"ADJ"} ,
		{"word":"co-pilotes","word_nosc":"co-pilotes","lemma":"co-pilote","pos":"ADJ"} ,
		{"word":"co-responsable","word_nosc":"co-responsable","lemma":"co-responsable","pos":"ADJ"} ,
		{"word":"co-éducative","word_nosc":"co-educative","lemma":"co-éducatif","pos":"ADJ"} ,
		{"word":"coagulant","word_nosc":"coagulant","lemma":"coagulant","pos":"ADJ"} ,
		{"word":"coagulantes","word_nosc":"coagulantes","lemma":"coagulant","pos":"ADJ"} ,
		{"word":"coalisée","word_nosc":"coalisee","lemma":"coalisé","pos":"ADJ"} ,
		{"word":"coaxial","word_nosc":"coaxial","lemma":"coaxial","pos":"ADJ"} ,
		{"word":"coaxiales","word_nosc":"coaxiales","lemma":"coaxial","pos":"ADJ"} ,
		{"word":"cocardier","word_nosc":"cocardier","lemma":"cocardier","pos":"ADJ"} ,
		{"word":"cocardiers","word_nosc":"cocardiers","lemma":"cocardier","pos":"ADJ"} ,
		{"word":"cocardière","word_nosc":"cocardiere","lemma":"cocardier","pos":"ADJ"} ,
		{"word":"cocardières","word_nosc":"cocardieres","lemma":"cocardier","pos":"ADJ"} ,
		{"word":"cocasse","word_nosc":"cocasse","lemma":"cocasse","pos":"ADJ"} ,
		{"word":"cocasses","word_nosc":"cocasses","lemma":"cocasse","pos":"ADJ"} ,
		{"word":"cochléaire","word_nosc":"cochleaire","lemma":"cochléaire","pos":"ADJ"} ,
		{"word":"cochléaires","word_nosc":"cochleaires","lemma":"cochléaire","pos":"ADJ"} ,
		{"word":"cochon","word_nosc":"cochon","lemma":"cochon","pos":"ADJ"} ,
		{"word":"cochonne","word_nosc":"cochonne","lemma":"cochon","pos":"ADJ"} ,
		{"word":"cochonnes","word_nosc":"cochonnes","lemma":"cochon","pos":"ADJ"} ,
		{"word":"cochonnées","word_nosc":"cochonnees","lemma":"cochonnée","pos":"ADJ"} ,
		{"word":"cochons","word_nosc":"cochons","lemma":"cochon","pos":"ADJ"} ,
		{"word":"cochère","word_nosc":"cochere","lemma":"cocher","pos":"ADJ"} ,
		{"word":"cochères","word_nosc":"cocheres","lemma":"cocher","pos":"ADJ"} ,
		{"word":"cocu","word_nosc":"cocu","lemma":"cocu","pos":"ADJ"} ,
		{"word":"cocue","word_nosc":"cocue","lemma":"cocu","pos":"ADJ"} ,
		{"word":"cocues","word_nosc":"cocues","lemma":"cocu","pos":"ADJ"} ,
		{"word":"cocufié","word_nosc":"cocufie","lemma":"cocufié","pos":"ADJ"} ,
		{"word":"cocus","word_nosc":"cocus","lemma":"cocu","pos":"ADJ"} ,
		{"word":"codante","word_nosc":"codante","lemma":"codant","pos":"ADJ"} ,
		{"word":"codé","word_nosc":"code","lemma":"codé","pos":"ADJ"} ,
		{"word":"codée","word_nosc":"codee","lemma":"codé","pos":"ADJ"} ,
		{"word":"codées","word_nosc":"codees","lemma":"codé","pos":"ADJ"} ,
		{"word":"codés","word_nosc":"codes","lemma":"codé","pos":"ADJ"} ,
		{"word":"coeliaque","word_nosc":"coeliaque","lemma":"coeliaque","pos":"ADJ"} ,
		{"word":"coercitif","word_nosc":"coercitif","lemma":"coercitif","pos":"ADJ"} ,
		{"word":"coercitive","word_nosc":"coercitive","lemma":"coercitif","pos":"ADJ"} ,
		{"word":"coexistants","word_nosc":"coexistants","lemma":"coexistant","pos":"ADJ"} ,
		{"word":"cofondé","word_nosc":"cofonde","lemma":"cofondé","pos":"ADJ"} ,
		{"word":"cognitif","word_nosc":"cognitif","lemma":"cognitif","pos":"ADJ"} ,
		{"word":"cognitifs","word_nosc":"cognitifs","lemma":"cognitif","pos":"ADJ"} ,
		{"word":"cognitive","word_nosc":"cognitive","lemma":"cognitif","pos":"ADJ"} ,
		{"word":"cognitives","word_nosc":"cognitives","lemma":"cognitif","pos":"ADJ"} ,
		{"word":"cohabitant","word_nosc":"cohabitant","lemma":"cohabitant","pos":"ADJ"} ,
		{"word":"cohérent","word_nosc":"coherent","lemma":"cohérent","pos":"ADJ"} ,
		{"word":"cohérente","word_nosc":"coherente","lemma":"cohérent","pos":"ADJ"} ,
		{"word":"cohérentes","word_nosc":"coherentes","lemma":"cohérent","pos":"ADJ"} ,
		{"word":"cohérents","word_nosc":"coherents","lemma":"cohérent","pos":"ADJ"} ,
		{"word":"cohésive","word_nosc":"cohesive","lemma":"cohésif","pos":"ADJ"} ,
		{"word":"coi","word_nosc":"coi","lemma":"coi","pos":"ADJ"} ,
		{"word":"coiffant","word_nosc":"coiffant","lemma":"coiffant","pos":"ADJ"} ,
		{"word":"coiffante","word_nosc":"coiffante","lemma":"coiffant","pos":"ADJ"} ,
		{"word":"coiffé","word_nosc":"coiffe","lemma":"coiffé","pos":"ADJ"} ,
		{"word":"coiffée","word_nosc":"coiffee","lemma":"coiffé","pos":"ADJ"} ,
		{"word":"coiffées","word_nosc":"coiffees","lemma":"coiffé","pos":"ADJ"} ,
		{"word":"coiffés","word_nosc":"coiffes","lemma":"coiffé","pos":"ADJ"} ,
		{"word":"coincé","word_nosc":"coince","lemma":"coincé","pos":"ADJ"} ,
		{"word":"coincée","word_nosc":"coincee","lemma":"coincé","pos":"ADJ"} ,
		{"word":"coincées","word_nosc":"coincees","lemma":"coincé","pos":"ADJ"} ,
		{"word":"coincés","word_nosc":"coinces","lemma":"coincé","pos":"ADJ"} ,
		{"word":"cois","word_nosc":"cois","lemma":"coi","pos":"ADJ"} ,
		{"word":"coite","word_nosc":"coite","lemma":"coi","pos":"ADJ"} ,
		{"word":"coites","word_nosc":"coites","lemma":"coi","pos":"ADJ"} ,
		{"word":"cold","word_nosc":"cold","lemma":"cold","pos":"ADJ"} ,
		{"word":"colique","word_nosc":"colique","lemma":"colique","pos":"ADJ"} ,
		{"word":"coliques","word_nosc":"coliques","lemma":"colique","pos":"ADJ"} ,
		{"word":"collaborationniste","word_nosc":"collaborationniste","lemma":"collaborationniste","pos":"ADJ"} ,
		{"word":"collaborationnistes","word_nosc":"collaborationnistes","lemma":"collaborationniste","pos":"ADJ"} ,
		{"word":"collant","word_nosc":"collant","lemma":"collant","pos":"ADJ"} ,
		{"word":"collante","word_nosc":"collante","lemma":"collant","pos":"ADJ"} ,
		{"word":"collantes","word_nosc":"collantes","lemma":"collant","pos":"ADJ"} ,
		{"word":"collants","word_nosc":"collants","lemma":"collant","pos":"ADJ"} ,
		{"word":"collatéral","word_nosc":"collateral","lemma":"collatéral","pos":"ADJ"} ,
		{"word":"collatérale","word_nosc":"collaterale","lemma":"collatéral","pos":"ADJ"} ,
		{"word":"collatérales","word_nosc":"collaterales","lemma":"collatéral","pos":"ADJ"} ,
		{"word":"collatéraux","word_nosc":"collateraux","lemma":"collatéral","pos":"ADJ"} ,
		{"word":"collectif","word_nosc":"collectif","lemma":"collectif","pos":"ADJ"} ,
		{"word":"collectifs","word_nosc":"collectifs","lemma":"collectif","pos":"ADJ"} ,
		{"word":"collective","word_nosc":"collective","lemma":"collectif","pos":"ADJ"} ,
		{"word":"collectives","word_nosc":"collectives","lemma":"collectif","pos":"ADJ"} ,
		{"word":"collectiviste","word_nosc":"collectiviste","lemma":"collectiviste","pos":"ADJ"} ,
		{"word":"collectrice","word_nosc":"collectrice","lemma":"collecteur","pos":"ADJ"} ,
		{"word":"colloïdale","word_nosc":"colloidale","lemma":"colloïdal","pos":"ADJ"} ,
		{"word":"collégial","word_nosc":"collegial","lemma":"collégial","pos":"ADJ"} ,
		{"word":"collégiale","word_nosc":"collegiale","lemma":"collégial","pos":"ADJ"} ,
		{"word":"collégiales","word_nosc":"collegiales","lemma":"collégial","pos":"ADJ"} ,
		{"word":"collégiaux","word_nosc":"collegiaux","lemma":"collégial","pos":"ADJ"} ,
		{"word":"colombien","word_nosc":"colombien","lemma":"colombien","pos":"ADJ"} ,
		{"word":"colombienne","word_nosc":"colombienne","lemma":"colombien","pos":"ADJ"} ,
		{"word":"colombiens","word_nosc":"colombiens","lemma":"colombien","pos":"ADJ"} ,
		{"word":"colombin","word_nosc":"colombin","lemma":"colombin","pos":"ADJ"} ,
		{"word":"colombine","word_nosc":"colombine","lemma":"colombin","pos":"ADJ"} ,
		{"word":"colombines","word_nosc":"colombines","lemma":"colombin","pos":"ADJ"} ,
		{"word":"colombophile","word_nosc":"colombophile","lemma":"colombophile","pos":"ADJ"} ,
		{"word":"colonial","word_nosc":"colonial","lemma":"colonial","pos":"ADJ"} ,
		{"word":"coloniale","word_nosc":"coloniale","lemma":"colonial","pos":"ADJ"} ,
		{"word":"coloniales","word_nosc":"coloniales","lemma":"colonial","pos":"ADJ"} ,
		{"word":"colonialiste","word_nosc":"colonialiste","lemma":"colonialiste","pos":"ADJ"} ,
		{"word":"colonialistes","word_nosc":"colonialistes","lemma":"colonialiste","pos":"ADJ"} ,
		{"word":"coloniaux","word_nosc":"coloniaux","lemma":"colonial","pos":"ADJ"} ,
		{"word":"colonisatrice","word_nosc":"colonisatrice","lemma":"colonisateur","pos":"ADJ"} ,
		{"word":"colonisé","word_nosc":"colonise","lemma":"colonisé","pos":"ADJ"} ,
		{"word":"colonisée","word_nosc":"colonisee","lemma":"colonisé","pos":"ADJ"} ,
		{"word":"colonisés","word_nosc":"colonises","lemma":"colonisé","pos":"ADJ"} ,
		{"word":"colorant","word_nosc":"colorant","lemma":"colorant","pos":"ADJ"} ,
		{"word":"colorantes","word_nosc":"colorantes","lemma":"colorant","pos":"ADJ"} ,
		{"word":"colorants","word_nosc":"colorants","lemma":"colorant","pos":"ADJ"} ,
		{"word":"colorectal","word_nosc":"colorectal","lemma":"colorectal","pos":"ADJ"} ,
		{"word":"coloré","word_nosc":"colore","lemma":"coloré","pos":"ADJ"} ,
		{"word":"colorée","word_nosc":"coloree","lemma":"coloré","pos":"ADJ"} ,
		{"word":"colorées","word_nosc":"colorees","lemma":"coloré","pos":"ADJ"} ,
		{"word":"colorés","word_nosc":"colores","lemma":"coloré","pos":"ADJ"} ,
		{"word":"colossal","word_nosc":"colossal","lemma":"colossal","pos":"ADJ"} ,
		{"word":"colossale","word_nosc":"colossale","lemma":"colossal","pos":"ADJ"} ,
		{"word":"colossales","word_nosc":"colossales","lemma":"colossal","pos":"ADJ"} ,
		{"word":"colossaux","word_nosc":"colossaux","lemma":"colossal","pos":"ADJ"} ,
		{"word":"coléreuse","word_nosc":"colereuse","lemma":"coléreux","pos":"ADJ"} ,
		{"word":"coléreux","word_nosc":"colereux","lemma":"coléreux","pos":"ADJ"} ,
		{"word":"colérique","word_nosc":"colerique","lemma":"colérique","pos":"ADJ"} ,
		{"word":"colériques","word_nosc":"coleriques","lemma":"colérique","pos":"ADJ"} ,
		{"word":"comac","word_nosc":"comac","lemma":"comac","pos":"ADJ"} ,
		{"word":"comacs","word_nosc":"comacs","lemma":"comac","pos":"ADJ"} ,
		{"word":"comateuse","word_nosc":"comateuse","lemma":"comateux","pos":"ADJ"} ,
		{"word":"comateux","word_nosc":"comateux","lemma":"comateux","pos":"ADJ"} ,
		{"word":"combatif","word_nosc":"combatif","lemma":"combatif","pos":"ADJ"} ,
		{"word":"combatifs","word_nosc":"combatifs","lemma":"combatif","pos":"ADJ"} ,
		{"word":"combative","word_nosc":"combative","lemma":"combatif","pos":"ADJ"} ,
		{"word":"combattant","word_nosc":"combattant","lemma":"combattant","pos":"ADJ"} ,
		{"word":"combattante","word_nosc":"combattante","lemma":"combattant","pos":"ADJ"} ,
		{"word":"combattantes","word_nosc":"combattantes","lemma":"combattant","pos":"ADJ"} ,
		{"word":"combattants","word_nosc":"combattants","lemma":"combattant","pos":"ADJ"} ,
		{"word":"combientième","word_nosc":"combientieme","lemma":"combientième","pos":"ADJ"} ,
		{"word":"combinard","word_nosc":"combinard","lemma":"combinard","pos":"ADJ"} ,
		{"word":"combinards","word_nosc":"combinards","lemma":"combinard","pos":"ADJ"} ,
		{"word":"combinatoire","word_nosc":"combinatoire","lemma":"combinatoire","pos":"ADJ"} ,
		{"word":"combinatoires","word_nosc":"combinatoires","lemma":"combinatoire","pos":"ADJ"} ,
		{"word":"combiné","word_nosc":"combine","lemma":"combiné","pos":"ADJ"} ,
		{"word":"combinée","word_nosc":"combinee","lemma":"combiné","pos":"ADJ"} ,
		{"word":"combinées","word_nosc":"combinees","lemma":"combiné","pos":"ADJ"} ,
		{"word":"combinés","word_nosc":"combines","lemma":"combiné","pos":"ADJ"} ,
		{"word":"comblant","word_nosc":"comblant","lemma":"comblant","pos":"ADJ"} ,
		{"word":"comblants","word_nosc":"comblants","lemma":"comblant","pos":"ADJ"} ,
		{"word":"comble","word_nosc":"comble","lemma":"comble","pos":"ADJ"} ,
		{"word":"combles","word_nosc":"combles","lemma":"comble","pos":"ADJ"} ,
		{"word":"comblé","word_nosc":"comble","lemma":"comblé","pos":"ADJ"} ,
		{"word":"comblée","word_nosc":"comblee","lemma":"comblé","pos":"ADJ"} ,
		{"word":"comblées","word_nosc":"comblees","lemma":"comblé","pos":"ADJ"} ,
		{"word":"comblés","word_nosc":"combles","lemma":"comblé","pos":"ADJ"} ,
		{"word":"comburant","word_nosc":"comburant","lemma":"comburant","pos":"ADJ"} ,
		{"word":"combustible","word_nosc":"combustible","lemma":"combustible","pos":"ADJ"} ,
		{"word":"combustibles","word_nosc":"combustibles","lemma":"combustible","pos":"ADJ"} ,
		{"word":"comestible","word_nosc":"comestible","lemma":"comestible","pos":"ADJ"} ,
		{"word":"comestibles","word_nosc":"comestibles","lemma":"comestible","pos":"ADJ"} ,
		{"word":"comique","word_nosc":"comique","lemma":"comique","pos":"ADJ"} ,
		{"word":"comiques","word_nosc":"comiques","lemma":"comique","pos":"ADJ"} ,
		{"word":"commandant","word_nosc":"commandant","lemma":"commandant","pos":"ADJ"} ,
		{"word":"commandante","word_nosc":"commandante","lemma":"commandant","pos":"ADJ"} ,
		{"word":"commandants","word_nosc":"commandants","lemma":"commandant","pos":"ADJ"} ,
		{"word":"commandé","word_nosc":"commande","lemma":"commandé","pos":"ADJ"} ,
		{"word":"commandée","word_nosc":"commandee","lemma":"commandé","pos":"ADJ"} ,
		{"word":"commandées","word_nosc":"commandees","lemma":"commandé","pos":"ADJ"} ,
		{"word":"commandés","word_nosc":"commandes","lemma":"commandé","pos":"ADJ"} ,
		{"word":"commensurable","word_nosc":"commensurable","lemma":"commensurable","pos":"ADJ"} ,
		{"word":"commensurables","word_nosc":"commensurables","lemma":"commensurable","pos":"ADJ"} ,
		{"word":"commençant","word_nosc":"commencant","lemma":"commençant","pos":"ADJ"} ,
		{"word":"commençante","word_nosc":"commencante","lemma":"commençant","pos":"ADJ"} ,
		{"word":"commençantes","word_nosc":"commencantes","lemma":"commençant","pos":"ADJ"} ,
		{"word":"commençants","word_nosc":"commencants","lemma":"commençant","pos":"ADJ"} ,
		{"word":"commercial","word_nosc":"commercial","lemma":"commercial","pos":"ADJ"} ,
		{"word":"commerciale","word_nosc":"commerciale","lemma":"commercial","pos":"ADJ"} ,
		{"word":"commerciales","word_nosc":"commerciales","lemma":"commercial","pos":"ADJ"} ,
		{"word":"commercialisable","word_nosc":"commercialisable","lemma":"commercialisable","pos":"ADJ"} ,
		{"word":"commerciaux","word_nosc":"commerciaux","lemma":"commercial","pos":"ADJ"} ,
		{"word":"commerçant","word_nosc":"commercant","lemma":"commerçant","pos":"ADJ"} ,
		{"word":"commerçante","word_nosc":"commercante","lemma":"commerçant","pos":"ADJ"} ,
		{"word":"commerçantes","word_nosc":"commercantes","lemma":"commerçant","pos":"ADJ"} ,
		{"word":"commerçants","word_nosc":"commercants","lemma":"commerçant","pos":"ADJ"} ,
		{"word":"comminatoire","word_nosc":"comminatoire","lemma":"comminatoire","pos":"ADJ"} ,
		{"word":"comminatoires","word_nosc":"comminatoires","lemma":"comminatoire","pos":"ADJ"} ,
		{"word":"comminutive","word_nosc":"comminutive","lemma":"comminutif","pos":"ADJ"} ,
		{"word":"commissurale","word_nosc":"commissurale","lemma":"commissural","pos":"ADJ"} ,
		{"word":"commode","word_nosc":"commode","lemma":"commode","pos":"ADJ"} ,
		{"word":"commodes","word_nosc":"commodes","lemma":"commode","pos":"ADJ"} ,
		{"word":"commun","word_nosc":"commun","lemma":"commun","pos":"ADJ"} ,
		{"word":"communal","word_nosc":"communal","lemma":"communal","pos":"ADJ"} ,
		{"word":"communale","word_nosc":"communale","lemma":"communal","pos":"ADJ"} ,
		{"word":"communales","word_nosc":"communales","lemma":"communal","pos":"ADJ"} ,
		{"word":"communautaire","word_nosc":"communautaire","lemma":"communautaire","pos":"ADJ"} ,
		{"word":"communautaires","word_nosc":"communautaires","lemma":"communautaire","pos":"ADJ"} ,
		{"word":"communaux","word_nosc":"communaux","lemma":"communal","pos":"ADJ"} ,
		{"word":"commune","word_nosc":"commune","lemma":"commun","pos":"ADJ"} ,
		{"word":"communes","word_nosc":"communes","lemma":"commun","pos":"ADJ"} ,
		{"word":"communicable","word_nosc":"communicable","lemma":"communicable","pos":"ADJ"} ,
		{"word":"communicables","word_nosc":"communicables","lemma":"communicable","pos":"ADJ"} ,
		{"word":"communicante","word_nosc":"communicante","lemma":"communicant","pos":"ADJ"} ,
		{"word":"communicantes","word_nosc":"communicantes","lemma":"communicant","pos":"ADJ"} ,
		{"word":"communicants","word_nosc":"communicants","lemma":"communicant","pos":"ADJ"} ,
		{"word":"communicateur","word_nosc":"communicateur","lemma":"communicateur","pos":"ADJ"} ,
		{"word":"communicatif","word_nosc":"communicatif","lemma":"communicatif","pos":"ADJ"} ,
		{"word":"communicatifs","word_nosc":"communicatifs","lemma":"communicatif","pos":"ADJ"} ,
		{"word":"communicative","word_nosc":"communicative","lemma":"communicatif","pos":"ADJ"} ,
		{"word":"communisant","word_nosc":"communisant","lemma":"communisant","pos":"ADJ"} ,
		{"word":"communisants","word_nosc":"communisants","lemma":"communisant","pos":"ADJ"} ,
		{"word":"communiste","word_nosc":"communiste","lemma":"communiste","pos":"ADJ"} ,
		{"word":"communistes","word_nosc":"communistes","lemma":"communiste","pos":"ADJ"} ,
		{"word":"communs","word_nosc":"communs","lemma":"commun","pos":"ADJ"} ,
		{"word":"commémoratif","word_nosc":"commemoratif","lemma":"commémoratif","pos":"ADJ"} ,
		{"word":"commémoratifs","word_nosc":"commemoratifs","lemma":"commémoratif","pos":"ADJ"} ,
		{"word":"commémorative","word_nosc":"commemorative","lemma":"commémoratif","pos":"ADJ"} ,
		{"word":"commémoratives","word_nosc":"commemoratives","lemma":"commémoratif","pos":"ADJ"} ,
		{"word":"comnène","word_nosc":"comnene","lemma":"comnène","pos":"ADJ"} ,
		{"word":"compact","word_nosc":"compact","lemma":"compact","pos":"ADJ"} ,
		{"word":"compacte","word_nosc":"compacte","lemma":"compact","pos":"ADJ"} ,
		{"word":"compactes","word_nosc":"compactes","lemma":"compact","pos":"ADJ"} ,
		{"word":"compacts","word_nosc":"compacts","lemma":"compact","pos":"ADJ"} ,
		{"word":"comparable","word_nosc":"comparable","lemma":"comparable","pos":"ADJ"} ,
		{"word":"comparables","word_nosc":"comparables","lemma":"comparable","pos":"ADJ"} ,
		{"word":"comparant","word_nosc":"comparant","lemma":"comparant","pos":"ADJ"} ,
		{"word":"comparatif","word_nosc":"comparatif","lemma":"comparatif","pos":"ADJ"} ,
		{"word":"comparatifs","word_nosc":"comparatifs","lemma":"comparatif","pos":"ADJ"} ,
		{"word":"comparative","word_nosc":"comparative","lemma":"comparatif","pos":"ADJ"} ,
		{"word":"comparatives","word_nosc":"comparatives","lemma":"comparatif","pos":"ADJ"} ,
		{"word":"compartimenté","word_nosc":"compartimente","lemma":"compartimenté","pos":"ADJ"} ,
		{"word":"compartimentée","word_nosc":"compartimentee","lemma":"compartimenté","pos":"ADJ"} ,
		{"word":"comparé","word_nosc":"compare","lemma":"comparé","pos":"ADJ"} ,
		{"word":"comparée","word_nosc":"comparee","lemma":"comparé","pos":"ADJ"} ,
		{"word":"comparées","word_nosc":"comparees","lemma":"comparé","pos":"ADJ"} ,
		{"word":"comparés","word_nosc":"compares","lemma":"comparé","pos":"ADJ"} ,
		{"word":"compassé","word_nosc":"compasse","lemma":"compassé","pos":"ADJ"} ,
		{"word":"compassée","word_nosc":"compassee","lemma":"compassé","pos":"ADJ"} ,
		{"word":"compassées","word_nosc":"compassees","lemma":"compassé","pos":"ADJ"} ,
		{"word":"compassés","word_nosc":"compasses","lemma":"compassé","pos":"ADJ"} ,
		{"word":"compatible","word_nosc":"compatible","lemma":"compatible","pos":"ADJ"} ,
		{"word":"compatibles","word_nosc":"compatibles","lemma":"compatible","pos":"ADJ"} ,
		{"word":"compatissant","word_nosc":"compatissant","lemma":"compatissant","pos":"ADJ"} ,
		{"word":"compatissante","word_nosc":"compatissante","lemma":"compatissant","pos":"ADJ"} ,
		{"word":"compatissantes","word_nosc":"compatissantes","lemma":"compatissant","pos":"ADJ"} ,
		{"word":"compatissants","word_nosc":"compatissants","lemma":"compatissant","pos":"ADJ"} ,
		{"word":"compensateur","word_nosc":"compensateur","lemma":"compensateur","pos":"ADJ"} ,
		{"word":"compensateurs","word_nosc":"compensateurs","lemma":"compensateur","pos":"ADJ"} ,
		{"word":"compensatoire","word_nosc":"compensatoire","lemma":"compensatoire","pos":"ADJ"} ,
		{"word":"compensé","word_nosc":"compense","lemma":"compensé","pos":"ADJ"} ,
		{"word":"compensée","word_nosc":"compensee","lemma":"compensé","pos":"ADJ"} ,
		{"word":"compensées","word_nosc":"compensees","lemma":"compensé","pos":"ADJ"} ,
		{"word":"compensés","word_nosc":"compenses","lemma":"compensé","pos":"ADJ"} ,
		{"word":"complaisant","word_nosc":"complaisant","lemma":"complaisant","pos":"ADJ"} ,
		{"word":"complaisante","word_nosc":"complaisante","lemma":"complaisant","pos":"ADJ"} ,
		{"word":"complaisantes","word_nosc":"complaisantes","lemma":"complaisant","pos":"ADJ"} ,
		{"word":"complaisants","word_nosc":"complaisants","lemma":"complaisant","pos":"ADJ"} ,
		{"word":"complet","word_nosc":"complet","lemma":"complet","pos":"ADJ"} ,
		{"word":"complets","word_nosc":"complets","lemma":"complet","pos":"ADJ"} ,
		{"word":"complexe","word_nosc":"complexe","lemma":"complexe","pos":"ADJ"} ,
		{"word":"complexes","word_nosc":"complexes","lemma":"complexe","pos":"ADJ"} ,
		{"word":"complexé","word_nosc":"complexe","lemma":"complexé","pos":"ADJ"} ,
		{"word":"complexée","word_nosc":"complexee","lemma":"complexé","pos":"ADJ"} ,
		{"word":"complexés","word_nosc":"complexes","lemma":"complexé","pos":"ADJ"} ,
		{"word":"complice","word_nosc":"complice","lemma":"complice","pos":"ADJ"} ,
		{"word":"complices","word_nosc":"complices","lemma":"complice","pos":"ADJ"} ,
		{"word":"compliqué","word_nosc":"complique","lemma":"compliqué","pos":"ADJ"} ,
		{"word":"compliquée","word_nosc":"compliquee","lemma":"compliqué","pos":"ADJ"} ,
		{"word":"compliquées","word_nosc":"compliquees","lemma":"compliqué","pos":"ADJ"} ,
		{"word":"compliqués","word_nosc":"compliques","lemma":"compliqué","pos":"ADJ"} ,
		{"word":"complu","word_nosc":"complu","lemma":"complu","pos":"ADJ"} ,
		{"word":"complète","word_nosc":"complete","lemma":"complet","pos":"ADJ"} ,
		{"word":"complètes","word_nosc":"completes","lemma":"complet","pos":"ADJ"} ,
		{"word":"complémentaire","word_nosc":"complementaire","lemma":"complémentaire","pos":"ADJ"} ,
		{"word":"complémentaires","word_nosc":"complementaires","lemma":"complémentaire","pos":"ADJ"} ,
		{"word":"comportemental","word_nosc":"comportemental","lemma":"comportemental","pos":"ADJ"} ,
		{"word":"comportementale","word_nosc":"comportementale","lemma":"comportemental","pos":"ADJ"} ,
		{"word":"comportementales","word_nosc":"comportementales","lemma":"comportemental","pos":"ADJ"} ,
		{"word":"comportementaliste","word_nosc":"comportementaliste","lemma":"comportementaliste","pos":"ADJ"} ,
		{"word":"comportementaux","word_nosc":"comportementaux","lemma":"comportemental","pos":"ADJ"} ,
		{"word":"composant","word_nosc":"composant","lemma":"composant","pos":"ADJ"} ,
		{"word":"composante","word_nosc":"composante","lemma":"composant","pos":"ADJ"} ,
		{"word":"composantes","word_nosc":"composantes","lemma":"composant","pos":"ADJ"} ,
		{"word":"composants","word_nosc":"composants","lemma":"composant","pos":"ADJ"} ,
		{"word":"composite","word_nosc":"composite","lemma":"composite","pos":"ADJ"} ,
		{"word":"composites","word_nosc":"composites","lemma":"composite","pos":"ADJ"} ,
		{"word":"composé","word_nosc":"compose","lemma":"composé","pos":"ADJ"} ,
		{"word":"composée","word_nosc":"composee","lemma":"composé","pos":"ADJ"} ,
		{"word":"composées","word_nosc":"composees","lemma":"composé","pos":"ADJ"} ,
		{"word":"composés","word_nosc":"composes","lemma":"composé","pos":"ADJ"} ,
		{"word":"compound","word_nosc":"compound","lemma":"compound","pos":"ADJ"} ,
		{"word":"comprador","word_nosc":"comprador","lemma":"comprador","pos":"ADJ"} ,
		{"word":"compresseur","word_nosc":"compresseur","lemma":"compresseur","pos":"ADJ"} ,
		{"word":"compresseurs","word_nosc":"compresseurs","lemma":"compresseur","pos":"ADJ"} ,
		{"word":"compressible","word_nosc":"compressible","lemma":"compressible","pos":"ADJ"} ,
		{"word":"compressibles","word_nosc":"compressibles","lemma":"compressible","pos":"ADJ"} ,
		{"word":"compressif","word_nosc":"compressif","lemma":"compressif","pos":"ADJ"} ,
		{"word":"compressé","word_nosc":"compresse","lemma":"compressé","pos":"ADJ"} ,
		{"word":"compressée","word_nosc":"compressee","lemma":"compressé","pos":"ADJ"} ,
		{"word":"compressées","word_nosc":"compressees","lemma":"compressé","pos":"ADJ"} ,
		{"word":"compressés","word_nosc":"compresses","lemma":"compressé","pos":"ADJ"} ,
		{"word":"comprimant","word_nosc":"comprimant","lemma":"comprimant","pos":"ADJ"} ,
		{"word":"comprimé","word_nosc":"comprime","lemma":"comprimé","pos":"ADJ"} ,
		{"word":"comprimée","word_nosc":"comprimee","lemma":"comprimé","pos":"ADJ"} ,
		{"word":"comprimées","word_nosc":"comprimees","lemma":"comprimé","pos":"ADJ"} ,
		{"word":"comprimés","word_nosc":"comprimes","lemma":"comprimé","pos":"ADJ"} ,
		{"word":"compris","word_nosc":"compris","lemma":"compris","pos":"ADJ"} ,
		{"word":"comprise","word_nosc":"comprise","lemma":"compris","pos":"ADJ"} ,
		{"word":"comprises","word_nosc":"comprises","lemma":"compris","pos":"ADJ"} ,
		{"word":"compromettant","word_nosc":"compromettant","lemma":"compromettant","pos":"ADJ"} ,
		{"word":"compromettante","word_nosc":"compromettante","lemma":"compromettant","pos":"ADJ"} ,
		{"word":"compromettantes","word_nosc":"compromettantes","lemma":"compromettant","pos":"ADJ"} ,
		{"word":"compromettants","word_nosc":"compromettants","lemma":"compromettant","pos":"ADJ"} ,
		{"word":"compromissoire","word_nosc":"compromissoire","lemma":"compromissoire","pos":"ADJ"} ,
		{"word":"compréhensible","word_nosc":"comprehensible","lemma":"compréhensible","pos":"ADJ"} ,
		{"word":"compréhensibles","word_nosc":"comprehensibles","lemma":"compréhensible","pos":"ADJ"} ,
		{"word":"compréhensif","word_nosc":"comprehensif","lemma":"compréhensif","pos":"ADJ"} ,
		{"word":"compréhensifs","word_nosc":"comprehensifs","lemma":"compréhensif","pos":"ADJ"} ,
		{"word":"compréhensive","word_nosc":"comprehensive","lemma":"compréhensif","pos":"ADJ"} ,
		{"word":"compréhensives","word_nosc":"comprehensives","lemma":"compréhensif","pos":"ADJ"} ,
		{"word":"comptable","word_nosc":"comptable","lemma":"comptable","pos":"ADJ"} ,
		{"word":"comptables","word_nosc":"comptables","lemma":"comptable","pos":"ADJ"} ,
		{"word":"comptant","word_nosc":"comptant","lemma":"comptant","pos":"ADJ"} ,
		{"word":"compulsif","word_nosc":"compulsif","lemma":"compulsif","pos":"ADJ"} ,
		{"word":"compulsifs","word_nosc":"compulsifs","lemma":"compulsif","pos":"ADJ"} ,
		{"word":"compulsive","word_nosc":"compulsive","lemma":"compulsif","pos":"ADJ"} ,
		{"word":"compulsives","word_nosc":"compulsives","lemma":"compulsif","pos":"ADJ"} ,
		{"word":"compétent","word_nosc":"competent","lemma":"compétent","pos":"ADJ"} ,
		{"word":"compétente","word_nosc":"competente","lemma":"compétent","pos":"ADJ"} ,
		{"word":"compétentes","word_nosc":"competentes","lemma":"compétent","pos":"ADJ"} ,
		{"word":"compétents","word_nosc":"competents","lemma":"compétent","pos":"ADJ"} ,
		{"word":"compétitif","word_nosc":"competitif","lemma":"compétitif","pos":"ADJ"} ,
		{"word":"compétitifs","word_nosc":"competitifs","lemma":"compétitif","pos":"ADJ"} ,
		{"word":"compétitive","word_nosc":"competitive","lemma":"compétitif","pos":"ADJ"} ,
		{"word":"comtal","word_nosc":"comtal","lemma":"comtal","pos":"ADJ"} ,
		{"word":"comtale","word_nosc":"comtale","lemma":"comtal","pos":"ADJ"} ,
		{"word":"comtois","word_nosc":"comtois","lemma":"comtois","pos":"ADJ"} ,
		{"word":"comtoise","word_nosc":"comtoise","lemma":"comtois","pos":"ADJ"} ,
		{"word":"comédien","word_nosc":"comedien","lemma":"comédien","pos":"ADJ"} ,
		{"word":"comédienne","word_nosc":"comedienne","lemma":"comédien","pos":"ADJ"} ,
		{"word":"comédiennes","word_nosc":"comediennes","lemma":"comédien","pos":"ADJ"} ,
		{"word":"comédiens","word_nosc":"comediens","lemma":"comédien","pos":"ADJ"} ,
		{"word":"con","word_nosc":"con","lemma":"con","pos":"ADJ"} ,
		{"word":"conard","word_nosc":"conard","lemma":"conard","pos":"ADJ"} ,
		{"word":"conarde","word_nosc":"conarde","lemma":"conard","pos":"ADJ"} ,
		{"word":"concave","word_nosc":"concave","lemma":"concave","pos":"ADJ"} ,
		{"word":"concaves","word_nosc":"concaves","lemma":"concave","pos":"ADJ"} ,
		{"word":"concentrationnaire","word_nosc":"concentrationnaire","lemma":"concentrationnaire","pos":"ADJ"} ,
		{"word":"concentrationnaires","word_nosc":"concentrationnaires","lemma":"concentrationnaire","pos":"ADJ"} ,
		{"word":"concentrique","word_nosc":"concentrique","lemma":"concentrique","pos":"ADJ"} ,
		{"word":"concentriques","word_nosc":"concentriques","lemma":"concentrique","pos":"ADJ"} ,
		{"word":"concentré","word_nosc":"concentre","lemma":"concentré","pos":"ADJ"} ,
		{"word":"concentrée","word_nosc":"concentree","lemma":"concentré","pos":"ADJ"} ,
		{"word":"concentrées","word_nosc":"concentrees","lemma":"concentré","pos":"ADJ"} ,
		{"word":"concentrés","word_nosc":"concentres","lemma":"concentré","pos":"ADJ"} ,
		{"word":"concepteur","word_nosc":"concepteur","lemma":"concepteur","pos":"ADJ"} ,
		{"word":"concepteurs","word_nosc":"concepteurs","lemma":"concepteur","pos":"ADJ"} ,
		{"word":"conceptuel","word_nosc":"conceptuel","lemma":"conceptuel","pos":"ADJ"} ,
		{"word":"conceptuelle","word_nosc":"conceptuelle","lemma":"conceptuel","pos":"ADJ"} ,
		{"word":"conceptuelles","word_nosc":"conceptuelles","lemma":"conceptuel","pos":"ADJ"} ,
		{"word":"conceptuels","word_nosc":"conceptuels","lemma":"conceptuel","pos":"ADJ"} ,
		{"word":"concerté","word_nosc":"concerte","lemma":"concerté","pos":"ADJ"} ,
		{"word":"concertée","word_nosc":"concertee","lemma":"concerté","pos":"ADJ"} ,
		{"word":"concertées","word_nosc":"concertees","lemma":"concerté","pos":"ADJ"} ,
		{"word":"concertés","word_nosc":"concertes","lemma":"concerté","pos":"ADJ"} ,
		{"word":"concessionnaire","word_nosc":"concessionnaire","lemma":"concessionnaire","pos":"ADJ"} ,
		{"word":"concessionnaires","word_nosc":"concessionnaires","lemma":"concessionnaire","pos":"ADJ"} ,
		{"word":"concevable","word_nosc":"concevable","lemma":"concevable","pos":"ADJ"} ,
		{"word":"concevables","word_nosc":"concevables","lemma":"concevable","pos":"ADJ"} ,
		{"word":"conchoïdaux","word_nosc":"conchoidaux","lemma":"conchoïdal","pos":"ADJ"} ,
		{"word":"conciliable","word_nosc":"conciliable","lemma":"conciliable","pos":"ADJ"} ,
		{"word":"conciliables","word_nosc":"conciliables","lemma":"conciliable","pos":"ADJ"} ,
		{"word":"conciliaires","word_nosc":"conciliaires","lemma":"conciliaire","pos":"ADJ"} ,
		{"word":"conciliant","word_nosc":"conciliant","lemma":"conciliant","pos":"ADJ"} ,
		{"word":"conciliante","word_nosc":"conciliante","lemma":"conciliant","pos":"ADJ"} ,
		{"word":"conciliantes","word_nosc":"conciliantes","lemma":"conciliant","pos":"ADJ"} ,
		{"word":"conciliants","word_nosc":"conciliants","lemma":"conciliant","pos":"ADJ"} ,
		{"word":"conciliateur","word_nosc":"conciliateur","lemma":"conciliateur","pos":"ADJ"} ,
		{"word":"conciliateurs","word_nosc":"conciliateurs","lemma":"conciliateur","pos":"ADJ"} ,
		{"word":"concis","word_nosc":"concis","lemma":"concis","pos":"ADJ"} ,
		{"word":"concise","word_nosc":"concise","lemma":"concis","pos":"ADJ"} ,
		{"word":"concluant","word_nosc":"concluant","lemma":"concluant","pos":"ADJ"} ,
		{"word":"concluante","word_nosc":"concluante","lemma":"concluant","pos":"ADJ"} ,
		{"word":"concluantes","word_nosc":"concluantes","lemma":"concluant","pos":"ADJ"} ,
		{"word":"concluants","word_nosc":"concluants","lemma":"concluant","pos":"ADJ"} ,
		{"word":"conclusive","word_nosc":"conclusive","lemma":"conclusif","pos":"ADJ"} ,
		{"word":"concomitant","word_nosc":"concomitant","lemma":"concomitant","pos":"ADJ"} ,
		{"word":"concomitante","word_nosc":"concomitante","lemma":"concomitant","pos":"ADJ"} ,
		{"word":"concomitantes","word_nosc":"concomitantes","lemma":"concomitant","pos":"ADJ"} ,
		{"word":"concordant","word_nosc":"concordant","lemma":"concordant","pos":"ADJ"} ,
		{"word":"concordante","word_nosc":"concordante","lemma":"concordant","pos":"ADJ"} ,
		{"word":"concordantes","word_nosc":"concordantes","lemma":"concordant","pos":"ADJ"} ,
		{"word":"concordants","word_nosc":"concordants","lemma":"concordant","pos":"ADJ"} ,
		{"word":"concourant","word_nosc":"concourant","lemma":"concourant","pos":"ADJ"} ,
		{"word":"concret","word_nosc":"concret","lemma":"concret","pos":"ADJ"} ,
		{"word":"concrets","word_nosc":"concrets","lemma":"concret","pos":"ADJ"} ,
		{"word":"concrète","word_nosc":"concrete","lemma":"concret","pos":"ADJ"} ,
		{"word":"concrètes","word_nosc":"concretes","lemma":"concret","pos":"ADJ"} ,
		{"word":"concupiscent","word_nosc":"concupiscent","lemma":"concupiscent","pos":"ADJ"} ,
		{"word":"concupiscente","word_nosc":"concupiscente","lemma":"concupiscent","pos":"ADJ"} ,
		{"word":"concupiscentes","word_nosc":"concupiscentes","lemma":"concupiscent","pos":"ADJ"} ,
		{"word":"concupiscents","word_nosc":"concupiscents","lemma":"concupiscent","pos":"ADJ"} ,
		{"word":"concurrent","word_nosc":"concurrent","lemma":"concurrent","pos":"ADJ"} ,
		{"word":"concurrente","word_nosc":"concurrente","lemma":"concurrent","pos":"ADJ"} ,
		{"word":"concurrentes","word_nosc":"concurrentes","lemma":"concurrent","pos":"ADJ"} ,
		{"word":"concurrentiel","word_nosc":"concurrentiel","lemma":"concurrentiel","pos":"ADJ"} ,
		{"word":"concurrentielle","word_nosc":"concurrentielle","lemma":"concurrentiel","pos":"ADJ"} ,
		{"word":"concurrentiels","word_nosc":"concurrentiels","lemma":"concurrentiel","pos":"ADJ"} ,
		{"word":"concurrents","word_nosc":"concurrents","lemma":"concurrent","pos":"ADJ"} ,
		{"word":"concussionnaire","word_nosc":"concussionnaire","lemma":"concussionnaire","pos":"ADJ"} ,
		{"word":"condamnable","word_nosc":"condamnable","lemma":"condamnable","pos":"ADJ"} ,
		{"word":"condamnables","word_nosc":"condamnables","lemma":"condamnable","pos":"ADJ"} ,
		{"word":"condamné","word_nosc":"condamne","lemma":"condamné","pos":"ADJ"} ,
		{"word":"condamnée","word_nosc":"condamnee","lemma":"condamné","pos":"ADJ"} ,
		{"word":"condamnées","word_nosc":"condamnees","lemma":"condamné","pos":"ADJ"} ,
		{"word":"condamnés","word_nosc":"condamnes","lemma":"condamné","pos":"ADJ"} ,
		{"word":"condensé","word_nosc":"condense","lemma":"condensé","pos":"ADJ"} ,
		{"word":"condensée","word_nosc":"condensee","lemma":"condensé","pos":"ADJ"} ,
		{"word":"condensés","word_nosc":"condenses","lemma":"condensé","pos":"ADJ"} ,
		{"word":"condescendant","word_nosc":"condescendant","lemma":"condescendant","pos":"ADJ"} ,
		{"word":"condescendante","word_nosc":"condescendante","lemma":"condescendant","pos":"ADJ"} ,
		{"word":"condescendants","word_nosc":"condescendants","lemma":"condescendant","pos":"ADJ"} ,
		{"word":"conditionnelle","word_nosc":"conditionnelle","lemma":"conditionnel","pos":"ADJ"} ,
		{"word":"conditionnelles","word_nosc":"conditionnelles","lemma":"conditionnel","pos":"ADJ"} ,
		{"word":"conditionnels","word_nosc":"conditionnels","lemma":"conditionnel","pos":"ADJ"} ,
		{"word":"conditionné","word_nosc":"conditionne","lemma":"conditionné","pos":"ADJ"} ,
		{"word":"conditionnée","word_nosc":"conditionnee","lemma":"conditionné","pos":"ADJ"} ,
		{"word":"conditionnées","word_nosc":"conditionnees","lemma":"conditionné","pos":"ADJ"} ,
		{"word":"conditionnés","word_nosc":"conditionnes","lemma":"conditionné","pos":"ADJ"} ,
		{"word":"condoléants","word_nosc":"condoleants","lemma":"condoléant","pos":"ADJ"} ,
		{"word":"conducteur","word_nosc":"conducteur","lemma":"conducteur","pos":"ADJ"} ,
		{"word":"conducteurs","word_nosc":"conducteurs","lemma":"conducteur","pos":"ADJ"} ,
		{"word":"conductrice","word_nosc":"conductrice","lemma":"conducteur","pos":"ADJ"} ,
		{"word":"conductrices","word_nosc":"conductrices","lemma":"conducteur","pos":"ADJ"} ,
		{"word":"conduite","word_nosc":"conduite","lemma":"conduit","pos":"ADJ"} ,
		{"word":"conduites","word_nosc":"conduites","lemma":"conduit","pos":"ADJ"} ,
		{"word":"confessionnel","word_nosc":"confessionnel","lemma":"confessionnel","pos":"ADJ"} ,
		{"word":"confessionnelle","word_nosc":"confessionnelle","lemma":"confessionnel","pos":"ADJ"} ,
		{"word":"confessionnels","word_nosc":"confessionnels","lemma":"confessionnel","pos":"ADJ"} ,
		{"word":"confiant","word_nosc":"confiant","lemma":"confiant","pos":"ADJ"} ,
		{"word":"confiante","word_nosc":"confiante","lemma":"confiant","pos":"ADJ"} ,
		{"word":"confiantes","word_nosc":"confiantes","lemma":"confiant","pos":"ADJ"} ,
		{"word":"confiants","word_nosc":"confiants","lemma":"confiant","pos":"ADJ"} ,
		{"word":"confidentiel","word_nosc":"confidentiel","lemma":"confidentiel","pos":"ADJ"} ,
		{"word":"confidentielle","word_nosc":"confidentielle","lemma":"confidentiel","pos":"ADJ"} ,
		{"word":"confidentielles","word_nosc":"confidentielles","lemma":"confidentiel","pos":"ADJ"} ,
		{"word":"confidentiels","word_nosc":"confidentiels","lemma":"confidentiel","pos":"ADJ"} ,
		{"word":"confiné","word_nosc":"confine","lemma":"confiné","pos":"ADJ"} ,
		{"word":"confinée","word_nosc":"confinee","lemma":"confiné","pos":"ADJ"} ,
		{"word":"confinées","word_nosc":"confinees","lemma":"confiné","pos":"ADJ"} ,
		{"word":"confinés","word_nosc":"confines","lemma":"confiné","pos":"ADJ"} ,
		{"word":"confirmé","word_nosc":"confirme","lemma":"confirmé","pos":"ADJ"} ,
		{"word":"confirmée","word_nosc":"confirmee","lemma":"confirmé","pos":"ADJ"} ,
		{"word":"confirmées","word_nosc":"confirmees","lemma":"confirmé","pos":"ADJ"} ,
		{"word":"confirmés","word_nosc":"confirmes","lemma":"confirmé","pos":"ADJ"} ,
		{"word":"confisqué","word_nosc":"confisque","lemma":"confisqué","pos":"ADJ"} ,
		{"word":"confisquée","word_nosc":"confisquee","lemma":"confisqué","pos":"ADJ"} ,
		{"word":"confisquées","word_nosc":"confisquees","lemma":"confisqué","pos":"ADJ"} ,
		{"word":"confisqués","word_nosc":"confisques","lemma":"confisqué","pos":"ADJ"} ,
		{"word":"confit","word_nosc":"confit","lemma":"confit","pos":"ADJ"} ,
		{"word":"confite","word_nosc":"confite","lemma":"confit","pos":"ADJ"} ,
		{"word":"confites","word_nosc":"confites","lemma":"confit","pos":"ADJ"} ,
		{"word":"confits","word_nosc":"confits","lemma":"confit","pos":"ADJ"} ,
		{"word":"conflictuel","word_nosc":"conflictuel","lemma":"conflictuel","pos":"ADJ"} ,
		{"word":"conflictuelle","word_nosc":"conflictuelle","lemma":"conflictuel","pos":"ADJ"} ,
		{"word":"conflictuelles","word_nosc":"conflictuelles","lemma":"conflictuel","pos":"ADJ"} ,
		{"word":"conflictuels","word_nosc":"conflictuels","lemma":"conflictuel","pos":"ADJ"} ,
		{"word":"confluent","word_nosc":"confluent","lemma":"confluent","pos":"ADJ"} ,
		{"word":"confondant","word_nosc":"confondant","lemma":"confondant","pos":"ADJ"} ,
		{"word":"confondante","word_nosc":"confondante","lemma":"confondant","pos":"ADJ"} ,
		{"word":"confondantes","word_nosc":"confondantes","lemma":"confondant","pos":"ADJ"} ,
		{"word":"confondants","word_nosc":"confondants","lemma":"confondant","pos":"ADJ"} ,
		{"word":"confondu","word_nosc":"confondu","lemma":"confondu","pos":"ADJ"} ,
		{"word":"confondue","word_nosc":"confondue","lemma":"confondu","pos":"ADJ"} ,
		{"word":"confondues","word_nosc":"confondues","lemma":"confondu","pos":"ADJ"} ,
		{"word":"confondus","word_nosc":"confondus","lemma":"confondu","pos":"ADJ"} ,
		{"word":"conforme","word_nosc":"conforme","lemma":"conforme","pos":"ADJ"} ,
		{"word":"conformes","word_nosc":"conformes","lemma":"conforme","pos":"ADJ"} ,
		{"word":"conformiste","word_nosc":"conformiste","lemma":"conformiste","pos":"ADJ"} ,
		{"word":"conformistes","word_nosc":"conformistes","lemma":"conformiste","pos":"ADJ"} ,
		{"word":"conformé","word_nosc":"conforme","lemma":"conformé","pos":"ADJ"} ,
		{"word":"confortable","word_nosc":"confortable","lemma":"confortable","pos":"ADJ"} ,
		{"word":"confortables","word_nosc":"confortables","lemma":"confortable","pos":"ADJ"} ,
		{"word":"confraternel","word_nosc":"confraternel","lemma":"confraternel","pos":"ADJ"} ,
		{"word":"confraternelle","word_nosc":"confraternelle","lemma":"confraternel","pos":"ADJ"} ,
		{"word":"confraternelles","word_nosc":"confraternelles","lemma":"confraternel","pos":"ADJ"} ,
		{"word":"confraternels","word_nosc":"confraternels","lemma":"confraternel","pos":"ADJ"} ,
		{"word":"confronté","word_nosc":"confronte","lemma":"confronté","pos":"ADJ"} ,
		{"word":"confrontée","word_nosc":"confrontee","lemma":"confronté","pos":"ADJ"} ,
		{"word":"confrontées","word_nosc":"confrontees","lemma":"confronté","pos":"ADJ"} ,
		{"word":"confrontés","word_nosc":"confrontes","lemma":"confronté","pos":"ADJ"} ,
		{"word":"confucéenne","word_nosc":"confuceenne","lemma":"confucéen","pos":"ADJ"} ,
		{"word":"confus","word_nosc":"confus","lemma":"confus","pos":"ADJ"} ,
		{"word":"confuse","word_nosc":"confuse","lemma":"confus","pos":"ADJ"} ,
		{"word":"confuses","word_nosc":"confuses","lemma":"confus","pos":"ADJ"} ,
		{"word":"confédéral","word_nosc":"confederal","lemma":"confédéral","pos":"ADJ"} ,
		{"word":"confédéraux","word_nosc":"confederaux","lemma":"confédéral","pos":"ADJ"} ,
		{"word":"confédéré","word_nosc":"confedere","lemma":"confédéré","pos":"ADJ"} ,
		{"word":"confédérée","word_nosc":"confederee","lemma":"confédéré","pos":"ADJ"} ,
		{"word":"confédérés","word_nosc":"confederes","lemma":"confédéré","pos":"ADJ"} ,
		{"word":"conféré","word_nosc":"confere","lemma":"conféré","pos":"ADJ"} ,
		{"word":"conférées","word_nosc":"conferees","lemma":"conféré","pos":"ADJ"} ,
		{"word":"conférés","word_nosc":"conferes","lemma":"conféré","pos":"ADJ"} ,
		{"word":"congelé","word_nosc":"congele","lemma":"congelé","pos":"ADJ"} ,
		{"word":"congelée","word_nosc":"congelee","lemma":"congelé","pos":"ADJ"} ,
		{"word":"congelées","word_nosc":"congelees","lemma":"congelé","pos":"ADJ"} ,
		{"word":"congelés","word_nosc":"congeles","lemma":"congelé","pos":"ADJ"} ,
		{"word":"congestif","word_nosc":"congestif","lemma":"congestif","pos":"ADJ"} ,
		{"word":"congestionné","word_nosc":"congestionne","lemma":"congestionné","pos":"ADJ"} ,
		{"word":"congestionnée","word_nosc":"congestionnee","lemma":"congestionné","pos":"ADJ"} ,
		{"word":"congestionnées","word_nosc":"congestionnees","lemma":"congestionné","pos":"ADJ"} ,
		{"word":"congestionnés","word_nosc":"congestionnes","lemma":"congestionné","pos":"ADJ"} ,
		{"word":"congestive","word_nosc":"congestive","lemma":"congestif","pos":"ADJ"} ,
		{"word":"congolais","word_nosc":"congolais","lemma":"congolais","pos":"ADJ"} ,
		{"word":"congolaise","word_nosc":"congolaise","lemma":"congolais","pos":"ADJ"} ,
		{"word":"congrue","word_nosc":"congrue","lemma":"congru","pos":"ADJ"} ,
		{"word":"congrégationaliste","word_nosc":"congregationaliste","lemma":"congrégationaliste","pos":"ADJ"} ,
		{"word":"congédié","word_nosc":"congedie","lemma":"congédié","pos":"ADJ"} ,
		{"word":"congédiée","word_nosc":"congediee","lemma":"congédié","pos":"ADJ"} ,
		{"word":"congénital","word_nosc":"congenital","lemma":"congénital","pos":"ADJ"} ,
		{"word":"congénitale","word_nosc":"congenitale","lemma":"congénital","pos":"ADJ"} ,
		{"word":"congénitales","word_nosc":"congenitales","lemma":"congénital","pos":"ADJ"} ,
		{"word":"congénitaux","word_nosc":"congenitaux","lemma":"congénital","pos":"ADJ"} ,
		{"word":"congénère","word_nosc":"congenere","lemma":"congénère","pos":"ADJ"} ,
		{"word":"congénères","word_nosc":"congeneres","lemma":"congénère","pos":"ADJ"} ,
		{"word":"conique","word_nosc":"conique","lemma":"conique","pos":"ADJ"} ,
		{"word":"coniques","word_nosc":"coniques","lemma":"conique","pos":"ADJ"} ,
		{"word":"conjectural","word_nosc":"conjectural","lemma":"conjectural","pos":"ADJ"} ,
		{"word":"conjecturale","word_nosc":"conjecturale","lemma":"conjectural","pos":"ADJ"} ,
		{"word":"conjoint","word_nosc":"conjoint","lemma":"conjoint","pos":"ADJ"} ,
		{"word":"conjointe","word_nosc":"conjointe","lemma":"conjoint","pos":"ADJ"} ,
		{"word":"conjointes","word_nosc":"conjointes","lemma":"conjoint","pos":"ADJ"} ,
		{"word":"conjoints","word_nosc":"conjoints","lemma":"conjoint","pos":"ADJ"} ,
		{"word":"conjonctif","word_nosc":"conjonctif","lemma":"conjonctif","pos":"ADJ"} ,
		{"word":"conjonctive","word_nosc":"conjonctive","lemma":"conjonctif","pos":"ADJ"} ,
		{"word":"conjonctives","word_nosc":"conjonctives","lemma":"conjonctif","pos":"ADJ"} ,
		{"word":"conjugal","word_nosc":"conjugal","lemma":"conjugal","pos":"ADJ"} ,
		{"word":"conjugale","word_nosc":"conjugale","lemma":"conjugal","pos":"ADJ"} ,
		{"word":"conjugales","word_nosc":"conjugales","lemma":"conjugal","pos":"ADJ"} ,
		{"word":"conjugaux","word_nosc":"conjugaux","lemma":"conjugal","pos":"ADJ"} ,
		{"word":"conjugué","word_nosc":"conjugue","lemma":"conjugué","pos":"ADJ"} ,
		{"word":"conjuguée","word_nosc":"conjuguee","lemma":"conjugué","pos":"ADJ"} ,
		{"word":"conjuratoire","word_nosc":"conjuratoire","lemma":"conjuratoire","pos":"ADJ"} ,
		{"word":"conjuré","word_nosc":"conjure","lemma":"conjuré","pos":"ADJ"} ,
		{"word":"conjurée","word_nosc":"conjuree","lemma":"conjuré","pos":"ADJ"} ,
		{"word":"conjurés","word_nosc":"conjures","lemma":"conjuré","pos":"ADJ"} ,
		{"word":"connaissable","word_nosc":"connaissable","lemma":"connaissable","pos":"ADJ"} ,
		{"word":"connaissables","word_nosc":"connaissables","lemma":"connaissable","pos":"ADJ"} ,
		{"word":"connaissant","word_nosc":"connaissant","lemma":"connaissant","pos":"ADJ"} ,
		{"word":"connaisseur","word_nosc":"connaisseur","lemma":"connaisseur","pos":"ADJ"} ,
		{"word":"connaisseurs","word_nosc":"connaisseurs","lemma":"connaisseur","pos":"ADJ"} ,
		{"word":"connaisseuse","word_nosc":"connaisseuse","lemma":"connaisseur","pos":"ADJ"} ,
		{"word":"connard","word_nosc":"connard","lemma":"connard","pos":"ADJ"} ,
		{"word":"connards","word_nosc":"connards","lemma":"connard","pos":"ADJ"} ,
		{"word":"connasse","word_nosc":"connasse","lemma":"connasse","pos":"ADJ"} ,
		{"word":"connasses","word_nosc":"connasses","lemma":"connasse","pos":"ADJ"} ,
		{"word":"conne","word_nosc":"conne","lemma":"con","pos":"ADJ"} ,
		{"word":"connes","word_nosc":"connes","lemma":"con","pos":"ADJ"} ,
		{"word":"connexe","word_nosc":"connexe","lemma":"connexe","pos":"ADJ"} ,
		{"word":"connexes","word_nosc":"connexes","lemma":"connexe","pos":"ADJ"} ,
		{"word":"connu","word_nosc":"connu","lemma":"connu","pos":"ADJ"} ,
		{"word":"connue","word_nosc":"connue","lemma":"connu","pos":"ADJ"} ,
		{"word":"connues","word_nosc":"connues","lemma":"connu","pos":"ADJ"} ,
		{"word":"connus","word_nosc":"connus","lemma":"connu","pos":"ADJ"} ,
		{"word":"conoïdaux","word_nosc":"conoidaux","lemma":"conoïdal","pos":"ADJ"} ,
		{"word":"conoïde","word_nosc":"conoide","lemma":"conoïde","pos":"ADJ"} ,
		{"word":"conquis","word_nosc":"conquis","lemma":"conquis","pos":"ADJ"} ,
		{"word":"conquise","word_nosc":"conquise","lemma":"conquis","pos":"ADJ"} ,
		{"word":"conquises","word_nosc":"conquises","lemma":"conquis","pos":"ADJ"} ,
		{"word":"conquérant","word_nosc":"conquerant","lemma":"conquérant","pos":"ADJ"} ,
		{"word":"conquérante","word_nosc":"conquerante","lemma":"conquérant","pos":"ADJ"} ,
		{"word":"conquérantes","word_nosc":"conquerantes","lemma":"conquérant","pos":"ADJ"} ,
		{"word":"conquérants","word_nosc":"conquerants","lemma":"conquérant","pos":"ADJ"} ,
		{"word":"cons","word_nosc":"cons","lemma":"con","pos":"ADJ"} ,
		{"word":"consacré","word_nosc":"consacre","lemma":"consacré","pos":"ADJ"} ,
		{"word":"consacrée","word_nosc":"consacree","lemma":"consacré","pos":"ADJ"} ,
		{"word":"consacrées","word_nosc":"consacrees","lemma":"consacré","pos":"ADJ"} ,
		{"word":"consacrés","word_nosc":"consacres","lemma":"consacré","pos":"ADJ"} ,
		{"word":"consanguin","word_nosc":"consanguin","lemma":"consanguin","pos":"ADJ"} ,
		{"word":"consanguine","word_nosc":"consanguine","lemma":"consanguin","pos":"ADJ"} ,
		{"word":"consanguins","word_nosc":"consanguins","lemma":"consanguin","pos":"ADJ"} ,
		{"word":"consciencieuse","word_nosc":"consciencieuse","lemma":"consciencieux","pos":"ADJ"} ,
		{"word":"consciencieuses","word_nosc":"consciencieuses","lemma":"consciencieux","pos":"ADJ"} ,
		{"word":"consciencieux","word_nosc":"consciencieux","lemma":"consciencieux","pos":"ADJ"} ,
		{"word":"conscient","word_nosc":"conscient","lemma":"conscient","pos":"ADJ"} ,
		{"word":"consciente","word_nosc":"consciente","lemma":"conscient","pos":"ADJ"} ,
		{"word":"conscientes","word_nosc":"conscientes","lemma":"conscient","pos":"ADJ"} ,
		{"word":"conscients","word_nosc":"conscients","lemma":"conscient","pos":"ADJ"} ,
		{"word":"consensuel","word_nosc":"consensuel","lemma":"consensuel","pos":"ADJ"} ,
		{"word":"consensuelle","word_nosc":"consensuelle","lemma":"consensuel","pos":"ADJ"} ,
		{"word":"consentant","word_nosc":"consentant","lemma":"consentant","pos":"ADJ"} ,
		{"word":"consentante","word_nosc":"consentante","lemma":"consentant","pos":"ADJ"} ,
		{"word":"consentantes","word_nosc":"consentantes","lemma":"consentant","pos":"ADJ"} ,
		{"word":"consentants","word_nosc":"consentants","lemma":"consentant","pos":"ADJ"} ,
		{"word":"conservateur","word_nosc":"conservateur","lemma":"conservateur","pos":"ADJ"} ,
		{"word":"conservateurs","word_nosc":"conservateurs","lemma":"conservateur","pos":"ADJ"} ,
		{"word":"conservatrice","word_nosc":"conservatrice","lemma":"conservateur","pos":"ADJ"} ,
		{"word":"conservatrices","word_nosc":"conservatrices","lemma":"conservateur","pos":"ADJ"} ,
		{"word":"conservé","word_nosc":"conserve","lemma":"conservé","pos":"ADJ"} ,
		{"word":"conservée","word_nosc":"conservee","lemma":"conservé","pos":"ADJ"} ,
		{"word":"conservées","word_nosc":"conservees","lemma":"conservé","pos":"ADJ"} ,
		{"word":"conservés","word_nosc":"conserves","lemma":"conservé","pos":"ADJ"} ,
		{"word":"considérable","word_nosc":"considerable","lemma":"considérable","pos":"ADJ"} ,
		{"word":"considérables","word_nosc":"considerables","lemma":"considérable","pos":"ADJ"} ,
		{"word":"consigné","word_nosc":"consigne","lemma":"consigné","pos":"ADJ"} ,
		{"word":"consignée","word_nosc":"consignee","lemma":"consigné","pos":"ADJ"} ,
		{"word":"consignées","word_nosc":"consignees","lemma":"consigné","pos":"ADJ"} ,
		{"word":"consignés","word_nosc":"consignes","lemma":"consigné","pos":"ADJ"} ,
		{"word":"consistant","word_nosc":"consistant","lemma":"consistant","pos":"ADJ"} ,
		{"word":"consistante","word_nosc":"consistante","lemma":"consistant","pos":"ADJ"} ,
		{"word":"consistantes","word_nosc":"consistantes","lemma":"consistant","pos":"ADJ"} ,
		{"word":"consistants","word_nosc":"consistants","lemma":"consistant","pos":"ADJ"} ,
		{"word":"consistorial","word_nosc":"consistorial","lemma":"consistorial","pos":"ADJ"} ,
		{"word":"consistoriales","word_nosc":"consistoriales","lemma":"consistorial","pos":"ADJ"} ,
		{"word":"consolant","word_nosc":"consolant","lemma":"consolant","pos":"ADJ"} ,
		{"word":"consolante","word_nosc":"consolante","lemma":"consolant","pos":"ADJ"} ,
		{"word":"consolantes","word_nosc":"consolantes","lemma":"consolant","pos":"ADJ"} ,
		{"word":"consolants","word_nosc":"consolants","lemma":"consolant","pos":"ADJ"} ,
		{"word":"consolateur","word_nosc":"consolateur","lemma":"consolateur","pos":"ADJ"} ,
		{"word":"consolateurs","word_nosc":"consolateurs","lemma":"consolateur","pos":"ADJ"} ,
		{"word":"consolatrice","word_nosc":"consolatrice","lemma":"consolateur","pos":"ADJ"} ,
		{"word":"consolatrices","word_nosc":"consolatrices","lemma":"consolateur","pos":"ADJ"} ,
		{"word":"consolidé","word_nosc":"consolide","lemma":"consolidé","pos":"ADJ"} ,
		{"word":"consolidée","word_nosc":"consolidee","lemma":"consolidé","pos":"ADJ"} ,
		{"word":"consolidées","word_nosc":"consolidees","lemma":"consolidé","pos":"ADJ"} ,
		{"word":"consolidés","word_nosc":"consolides","lemma":"consolidé","pos":"ADJ"} ,
		{"word":"consommable","word_nosc":"consommable","lemma":"consommable","pos":"ADJ"} ,
		{"word":"consommables","word_nosc":"consommables","lemma":"consommable","pos":"ADJ"} ,
		{"word":"consommé","word_nosc":"consomme","lemma":"consommé","pos":"ADJ"} ,
		{"word":"consommée","word_nosc":"consommee","lemma":"consommé","pos":"ADJ"} ,
		{"word":"consommés","word_nosc":"consommes","lemma":"consommé","pos":"ADJ"} ,
		{"word":"consomptive","word_nosc":"consomptive","lemma":"consomptif","pos":"ADJ"} ,
		{"word":"consort","word_nosc":"consort","lemma":"consort","pos":"ADJ"} ,
		{"word":"consorts","word_nosc":"consorts","lemma":"consort","pos":"ADJ"} ,
		{"word":"conspirateur","word_nosc":"conspirateur","lemma":"conspirateur","pos":"ADJ"} ,
		{"word":"conspirateurs","word_nosc":"conspirateurs","lemma":"conspirateur","pos":"ADJ"} ,
		{"word":"conspiratif","word_nosc":"conspiratif","lemma":"conspiratif","pos":"ADJ"} ,
		{"word":"conspiratrice","word_nosc":"conspiratrice","lemma":"conspirateur","pos":"ADJ"} ,
		{"word":"constant","word_nosc":"constant","lemma":"constant","pos":"ADJ"} ,
		{"word":"constante","word_nosc":"constante","lemma":"constant","pos":"ADJ"} ,
		{"word":"constantes","word_nosc":"constantes","lemma":"constant","pos":"ADJ"} ,
		{"word":"constants","word_nosc":"constants","lemma":"constant","pos":"ADJ"} ,
		{"word":"constat","word_nosc":"constat","lemma":"constat","pos":"ADJ"} ,
		{"word":"constats","word_nosc":"constats","lemma":"constat","pos":"ADJ"} ,
		{"word":"constellé","word_nosc":"constelle","lemma":"constellé","pos":"ADJ"} ,
		{"word":"constellée","word_nosc":"constellee","lemma":"constellé","pos":"ADJ"} ,
		{"word":"constellées","word_nosc":"constellees","lemma":"constellé","pos":"ADJ"} ,
		{"word":"consternant","word_nosc":"consternant","lemma":"consternant","pos":"ADJ"} ,
		{"word":"consternante","word_nosc":"consternante","lemma":"consternant","pos":"ADJ"} ,
		{"word":"consternantes","word_nosc":"consternantes","lemma":"consternant","pos":"ADJ"} ,
		{"word":"consternants","word_nosc":"consternants","lemma":"consternant","pos":"ADJ"} ,
		{"word":"consterné","word_nosc":"consterne","lemma":"consterné","pos":"ADJ"} ,
		{"word":"consternée","word_nosc":"consternee","lemma":"consterné","pos":"ADJ"} ,
		{"word":"consternées","word_nosc":"consternees","lemma":"consterné","pos":"ADJ"} ,
		{"word":"consternés","word_nosc":"consternes","lemma":"consterné","pos":"ADJ"} ,
		{"word":"constipant","word_nosc":"constipant","lemma":"constipant","pos":"ADJ"} ,
		{"word":"constipé","word_nosc":"constipe","lemma":"constipé","pos":"ADJ"} ,
		{"word":"constipée","word_nosc":"constipee","lemma":"constipé","pos":"ADJ"} ,
		{"word":"constipés","word_nosc":"constipes","lemma":"constipé","pos":"ADJ"} ,
		{"word":"constituant","word_nosc":"constituant","lemma":"constituant","pos":"ADJ"} ,
		{"word":"constituante","word_nosc":"constituante","lemma":"constituant","pos":"ADJ"} ,
		{"word":"constituantes","word_nosc":"constituantes","lemma":"constituant","pos":"ADJ"} ,
		{"word":"constituants","word_nosc":"constituants","lemma":"constituant","pos":"ADJ"} ,
		{"word":"constitutif","word_nosc":"constitutif","lemma":"constitutif","pos":"ADJ"} ,
		{"word":"constitutifs","word_nosc":"constitutifs","lemma":"constitutif","pos":"ADJ"} ,
		{"word":"constitutionnel","word_nosc":"constitutionnel","lemma":"constitutionnel","pos":"ADJ"} ,
		{"word":"constitutionnelle","word_nosc":"constitutionnelle","lemma":"constitutionnel","pos":"ADJ"} ,
		{"word":"constitutionnelles","word_nosc":"constitutionnelles","lemma":"constitutionnel","pos":"ADJ"} ,
		{"word":"constitutionnels","word_nosc":"constitutionnels","lemma":"constitutionnel","pos":"ADJ"} ,
		{"word":"constitué","word_nosc":"constitue","lemma":"constitué","pos":"ADJ"} ,
		{"word":"constituée","word_nosc":"constituee","lemma":"constitué","pos":"ADJ"} ,
		{"word":"constituées","word_nosc":"constituees","lemma":"constitué","pos":"ADJ"} ,
		{"word":"constitués","word_nosc":"constitues","lemma":"constitué","pos":"ADJ"} ,
		{"word":"constrictive","word_nosc":"constrictive","lemma":"constrictif","pos":"ADJ"} ,
		{"word":"constrictor","word_nosc":"constrictor","lemma":"constrictor","pos":"ADJ"} ,
		{"word":"constrictors","word_nosc":"constrictors","lemma":"constrictor","pos":"ADJ"} ,
		{"word":"constructeur","word_nosc":"constructeur","lemma":"constructeur","pos":"ADJ"} ,
		{"word":"constructeurs","word_nosc":"constructeurs","lemma":"constructeur","pos":"ADJ"} ,
		{"word":"constructif","word_nosc":"constructif","lemma":"constructif","pos":"ADJ"} ,
		{"word":"constructifs","word_nosc":"constructifs","lemma":"constructif","pos":"ADJ"} ,
		{"word":"constructive","word_nosc":"constructive","lemma":"constructif","pos":"ADJ"} ,
		{"word":"constructives","word_nosc":"constructives","lemma":"constructif","pos":"ADJ"} ,
		{"word":"consubstantiel","word_nosc":"consubstantiel","lemma":"consubstantiel","pos":"ADJ"} ,
		{"word":"consubstantielle","word_nosc":"consubstantielle","lemma":"consubstantiel","pos":"ADJ"} ,
		{"word":"consubstantiels","word_nosc":"consubstantiels","lemma":"consubstantiel","pos":"ADJ"} ,
		{"word":"consulaire","word_nosc":"consulaire","lemma":"consulaire","pos":"ADJ"} ,
		{"word":"consulaires","word_nosc":"consulaires","lemma":"consulaire","pos":"ADJ"} ,
		{"word":"consultable","word_nosc":"consultable","lemma":"consultable","pos":"ADJ"} ,
		{"word":"consultant","word_nosc":"consultant","lemma":"consultant","pos":"ADJ"} ,
		{"word":"consultante","word_nosc":"consultante","lemma":"consultant","pos":"ADJ"} ,
		{"word":"consultants","word_nosc":"consultants","lemma":"consultant","pos":"ADJ"} ,
		{"word":"consultatif","word_nosc":"consultatif","lemma":"consultatif","pos":"ADJ"} ,
		{"word":"consultatifs","word_nosc":"consultatifs","lemma":"consultatif","pos":"ADJ"} ,
		{"word":"consultative","word_nosc":"consultative","lemma":"consultatif","pos":"ADJ"} ,
		{"word":"consumable","word_nosc":"consumable","lemma":"consumable","pos":"ADJ"} ,
		{"word":"consumériste","word_nosc":"consumeriste","lemma":"consumériste","pos":"ADJ"} ,
		{"word":"consécutif","word_nosc":"consecutif","lemma":"consécutif","pos":"ADJ"} ,
		{"word":"consécutifs","word_nosc":"consecutifs","lemma":"consécutif","pos":"ADJ"} ,
		{"word":"consécutive","word_nosc":"consecutive","lemma":"consécutif","pos":"ADJ"} ,
		{"word":"consécutives","word_nosc":"consecutives","lemma":"consécutif","pos":"ADJ"} ,
		{"word":"conséquent","word_nosc":"consequent","lemma":"conséquent","pos":"ADJ"} ,
		{"word":"conséquente","word_nosc":"consequente","lemma":"conséquent","pos":"ADJ"} ,
		{"word":"conséquentes","word_nosc":"consequentes","lemma":"conséquent","pos":"ADJ"} ,
		{"word":"conséquents","word_nosc":"consequents","lemma":"conséquent","pos":"ADJ"} ,
		{"word":"contagieuse","word_nosc":"contagieuse","lemma":"contagieux","pos":"ADJ"} ,
		{"word":"contagieuses","word_nosc":"contagieuses","lemma":"contagieux","pos":"ADJ"} ,
		{"word":"contagieux","word_nosc":"contagieux","lemma":"contagieux","pos":"ADJ"} ,
		{"word":"contaminant","word_nosc":"contaminant","lemma":"contaminant","pos":"ADJ"} ,
		{"word":"contaminants","word_nosc":"contaminants","lemma":"contaminant","pos":"ADJ"} ,
		{"word":"contemplatif","word_nosc":"contemplatif","lemma":"contemplatif","pos":"ADJ"} ,
		{"word":"contemplatifs","word_nosc":"contemplatifs","lemma":"contemplatif","pos":"ADJ"} ,
		{"word":"contemplative","word_nosc":"contemplative","lemma":"contemplatif","pos":"ADJ"} ,
		{"word":"contemplatives","word_nosc":"contemplatives","lemma":"contemplatif","pos":"ADJ"} ,
		{"word":"contemporain","word_nosc":"contemporain","lemma":"contemporain","pos":"ADJ"} ,
		{"word":"contemporaine","word_nosc":"contemporaine","lemma":"contemporain","pos":"ADJ"} ,
		{"word":"contemporaines","word_nosc":"contemporaines","lemma":"contemporain","pos":"ADJ"} ,
		{"word":"contemporains","word_nosc":"contemporains","lemma":"contemporain","pos":"ADJ"} ,
		{"word":"content","word_nosc":"content","lemma":"content","pos":"ADJ"} ,
		{"word":"contente","word_nosc":"contente","lemma":"content","pos":"ADJ"} ,
		{"word":"contentes","word_nosc":"contentes","lemma":"content","pos":"ADJ"} ,
		{"word":"contents","word_nosc":"contents","lemma":"content","pos":"ADJ"} ,
		{"word":"contenu","word_nosc":"contenu","lemma":"contenu","pos":"ADJ"} ,
		{"word":"contenue","word_nosc":"contenue","lemma":"contenu","pos":"ADJ"} ,
		{"word":"contenues","word_nosc":"contenues","lemma":"contenu","pos":"ADJ"} ,
		{"word":"contenus","word_nosc":"contenus","lemma":"contenu","pos":"ADJ"} ,
		{"word":"contestable","word_nosc":"contestable","lemma":"contestable","pos":"ADJ"} ,
		{"word":"contestables","word_nosc":"contestables","lemma":"contestable","pos":"ADJ"} ,
		{"word":"contestataire","word_nosc":"contestataire","lemma":"contestataire","pos":"ADJ"} ,
		{"word":"contestataires","word_nosc":"contestataires","lemma":"contestataire","pos":"ADJ"} ,
		{"word":"contextuel","word_nosc":"contextuel","lemma":"contextuel","pos":"ADJ"} ,
		{"word":"contigu","word_nosc":"contigu","lemma":"contigu","pos":"ADJ"} ,
		{"word":"contigus","word_nosc":"contigus","lemma":"contigu","pos":"ADJ"} ,
		{"word":"contiguë","word_nosc":"contigue","lemma":"contigu","pos":"ADJ"} ,
		{"word":"contiguës","word_nosc":"contigues","lemma":"contigu","pos":"ADJ"} ,
		{"word":"continent","word_nosc":"continent","lemma":"continent","pos":"ADJ"} ,
		{"word":"continental","word_nosc":"continental","lemma":"continental","pos":"ADJ"} ,
		{"word":"continentale","word_nosc":"continentale","lemma":"continental","pos":"ADJ"} ,
		{"word":"continentales","word_nosc":"continentales","lemma":"continental","pos":"ADJ"} ,
		{"word":"continentaux","word_nosc":"continentaux","lemma":"continental","pos":"ADJ"} ,
		{"word":"contingent","word_nosc":"contingent","lemma":"contingent","pos":"ADJ"} ,
		{"word":"contingente","word_nosc":"contingente","lemma":"contingent","pos":"ADJ"} ,
		{"word":"contingentes","word_nosc":"contingentes","lemma":"contingent","pos":"ADJ"} ,
		{"word":"contingents","word_nosc":"contingents","lemma":"contingent","pos":"ADJ"} ,
		{"word":"continu","word_nosc":"continu","lemma":"continu","pos":"ADJ"} ,
		{"word":"continue","word_nosc":"continue","lemma":"continue","pos":"ADJ"} ,
		{"word":"continuel","word_nosc":"continuel","lemma":"continuel","pos":"ADJ"} ,
		{"word":"continuelle","word_nosc":"continuelle","lemma":"continuel","pos":"ADJ"} ,
		{"word":"continuelles","word_nosc":"continuelles","lemma":"continuel","pos":"ADJ"} ,
		{"word":"continuels","word_nosc":"continuels","lemma":"continuel","pos":"ADJ"} ,
		{"word":"continues","word_nosc":"continues","lemma":"continu","pos":"ADJ"} ,
		{"word":"continus","word_nosc":"continus","lemma":"continu","pos":"ADJ"} ,
		{"word":"contondant","word_nosc":"contondant","lemma":"contondant","pos":"ADJ"} ,
		{"word":"contondante","word_nosc":"contondante","lemma":"contondant","pos":"ADJ"} ,
		{"word":"contondants","word_nosc":"contondants","lemma":"contondant","pos":"ADJ"} ,
		{"word":"contourné","word_nosc":"contourne","lemma":"contourné","pos":"ADJ"} ,
		{"word":"contournée","word_nosc":"contournee","lemma":"contourné","pos":"ADJ"} ,
		{"word":"contournées","word_nosc":"contournees","lemma":"contourné","pos":"ADJ"} ,
		{"word":"contournés","word_nosc":"contournes","lemma":"contourné","pos":"ADJ"} ,
		{"word":"contraceptif","word_nosc":"contraceptif","lemma":"contraceptif","pos":"ADJ"} ,
		{"word":"contraceptive","word_nosc":"contraceptive","lemma":"contraceptif","pos":"ADJ"} ,
		{"word":"contraceptives","word_nosc":"contraceptives","lemma":"contraceptif","pos":"ADJ"} ,
		{"word":"contractant","word_nosc":"contractant","lemma":"contractant","pos":"ADJ"} ,
		{"word":"contractantes","word_nosc":"contractantes","lemma":"contractant","pos":"ADJ"} ,
		{"word":"contracte","word_nosc":"contracte","lemma":"contracte","pos":"ADJ"} ,
		{"word":"contractile","word_nosc":"contractile","lemma":"contractile","pos":"ADJ"} ,
		{"word":"contractuel","word_nosc":"contractuel","lemma":"contractuel","pos":"ADJ"} ,
		{"word":"contractuelle","word_nosc":"contractuelle","lemma":"contractuel","pos":"ADJ"} ,
		{"word":"contractuelles","word_nosc":"contractuelles","lemma":"contractuel","pos":"ADJ"} ,
		{"word":"contractuels","word_nosc":"contractuels","lemma":"contractuel","pos":"ADJ"} ,
		{"word":"contracté","word_nosc":"contracte","lemma":"contracté","pos":"ADJ"} ,
		{"word":"contractée","word_nosc":"contractee","lemma":"contracté","pos":"ADJ"} ,
		{"word":"contractées","word_nosc":"contractees","lemma":"contracté","pos":"ADJ"} ,
		{"word":"contractés","word_nosc":"contractes","lemma":"contracté","pos":"ADJ"} ,
		{"word":"contradicteur","word_nosc":"contradicteur","lemma":"contradicteur","pos":"ADJ"} ,
		{"word":"contradicteurs","word_nosc":"contradicteurs","lemma":"contradicteur","pos":"ADJ"} ,
		{"word":"contradictoire","word_nosc":"contradictoire","lemma":"contradictoire","pos":"ADJ"} ,
		{"word":"contradictoires","word_nosc":"contradictoires","lemma":"contradictoire","pos":"ADJ"} ,
		{"word":"contraignant","word_nosc":"contraignant","lemma":"contraignant","pos":"ADJ"} ,
		{"word":"contraignante","word_nosc":"contraignante","lemma":"contraignant","pos":"ADJ"} ,
		{"word":"contraignantes","word_nosc":"contraignantes","lemma":"contraignant","pos":"ADJ"} ,
		{"word":"contraint","word_nosc":"contraint","lemma":"contraint","pos":"ADJ"} ,
		{"word":"contrainte","word_nosc":"contrainte","lemma":"contraint","pos":"ADJ"} ,
		{"word":"contraintes","word_nosc":"contraintes","lemma":"contraint","pos":"ADJ"} ,
		{"word":"contraints","word_nosc":"contraints","lemma":"contraint","pos":"ADJ"} ,
		{"word":"contraire","word_nosc":"contraire","lemma":"contraire","pos":"ADJ"} ,
		{"word":"contraires","word_nosc":"contraires","lemma":"contraire","pos":"ADJ"} ,
		{"word":"contrapunctique","word_nosc":"contrapunctique","lemma":"contrapunctique","pos":"ADJ"} ,
		{"word":"contrapuntique","word_nosc":"contrapuntique","lemma":"contrapuntique","pos":"ADJ"} ,
		{"word":"contrariant","word_nosc":"contrariant","lemma":"contrariant","pos":"ADJ"} ,
		{"word":"contrariante","word_nosc":"contrariante","lemma":"contrariant","pos":"ADJ"} ,
		{"word":"contrariantes","word_nosc":"contrariantes","lemma":"contrariant","pos":"ADJ"} ,
		{"word":"contrariants","word_nosc":"contrariants","lemma":"contrariant","pos":"ADJ"} ,
		{"word":"contrarié","word_nosc":"contrarie","lemma":"contrarié","pos":"ADJ"} ,
		{"word":"contrariée","word_nosc":"contrariee","lemma":"contrarié","pos":"ADJ"} ,
		{"word":"contrariées","word_nosc":"contrariees","lemma":"contrarié","pos":"ADJ"} ,
		{"word":"contrariés","word_nosc":"contraries","lemma":"contrarié","pos":"ADJ"} ,
		{"word":"contrastant","word_nosc":"contrastant","lemma":"contrastant","pos":"ADJ"} ,
		{"word":"contrasté","word_nosc":"contraste","lemma":"contrasté","pos":"ADJ"} ,
		{"word":"contrastée","word_nosc":"contrastee","lemma":"contrasté","pos":"ADJ"} ,
		{"word":"contrastées","word_nosc":"contrastees","lemma":"contrasté","pos":"ADJ"} ,
		{"word":"contrastés","word_nosc":"contrastes","lemma":"contrasté","pos":"ADJ"} ,
		{"word":"contre-indiqué","word_nosc":"contre-indique","lemma":"contre-indiqué","pos":"ADJ"} ,
		{"word":"contre-nature","word_nosc":"contre-nature","lemma":"contre-nature","pos":"ADJ"} ,
		{"word":"contre-productif","word_nosc":"contre-productif","lemma":"contre-productif","pos":"ADJ"} ,
		{"word":"contre-productive","word_nosc":"contre-productive","lemma":"contre-productif","pos":"ADJ"} ,
		{"word":"contre-révolutionnaire","word_nosc":"contre-revolutionnaire","lemma":"contre-révolutionnaire","pos":"ADJ"} ,
		{"word":"contre-révolutionnaires","word_nosc":"contre-revolutionnaires","lemma":"contre-révolutionnaire","pos":"ADJ"} ,
		{"word":"contre-terroriste","word_nosc":"contre-terroriste","lemma":"contre-terroriste","pos":"ADJ"} ,
		{"word":"contrebasse","word_nosc":"contrebasse","lemma":"contrebasse","pos":"ADJ"} ,
		{"word":"contrecollé","word_nosc":"contrecolle","lemma":"contrecollé","pos":"ADJ"} ,
		{"word":"contrefait","word_nosc":"contrefait","lemma":"contrefait","pos":"ADJ"} ,
		{"word":"contrefaite","word_nosc":"contrefaite","lemma":"contrefait","pos":"ADJ"} ,
		{"word":"contrefaits","word_nosc":"contrefaits","lemma":"contrefait","pos":"ADJ"} ,
		{"word":"contrit","word_nosc":"contrit","lemma":"contrit","pos":"ADJ"} ,
		{"word":"contrite","word_nosc":"contrite","lemma":"contrit","pos":"ADJ"} ,
		{"word":"contrites","word_nosc":"contrites","lemma":"contrit","pos":"ADJ"} ,
		{"word":"contrits","word_nosc":"contrits","lemma":"contrit","pos":"ADJ"} ,
		{"word":"control","word_nosc":"control","lemma":"control","pos":"ADJ"} ,
		{"word":"controversé","word_nosc":"controverse","lemma":"controversé","pos":"ADJ"} ,
		{"word":"controversée","word_nosc":"controversee","lemma":"controversé","pos":"ADJ"} ,
		{"word":"controversées","word_nosc":"controversees","lemma":"controversé","pos":"ADJ"} ,
		{"word":"controversés","word_nosc":"controverses","lemma":"controversé","pos":"ADJ"} ,
		{"word":"contrôlable","word_nosc":"controlable","lemma":"contrôlable","pos":"ADJ"} ,
		{"word":"contrôlables","word_nosc":"controlables","lemma":"contrôlable","pos":"ADJ"} ,
		{"word":"contumax","word_nosc":"contumax","lemma":"contumax","pos":"ADJ"} ,
		{"word":"contuse","word_nosc":"contuse","lemma":"contus","pos":"ADJ"} ,
		{"word":"contusionné","word_nosc":"contusionne","lemma":"contusionné","pos":"ADJ"} ,
		{"word":"contusionnée","word_nosc":"contusionnee","lemma":"contusionné","pos":"ADJ"} ,
		{"word":"contusionnés","word_nosc":"contusionnes","lemma":"contusionné","pos":"ADJ"} ,
		{"word":"convaincant","word_nosc":"convaincant","lemma":"convaincant","pos":"ADJ"} ,
		{"word":"convaincante","word_nosc":"convaincante","lemma":"convaincant","pos":"ADJ"} ,
		{"word":"convaincantes","word_nosc":"convaincantes","lemma":"convaincant","pos":"ADJ"} ,
		{"word":"convaincants","word_nosc":"convaincants","lemma":"convaincant","pos":"ADJ"} ,
		{"word":"convaincu","word_nosc":"convaincu","lemma":"convaincu","pos":"ADJ"} ,
		{"word":"convaincue","word_nosc":"convaincue","lemma":"convaincu","pos":"ADJ"} ,
		{"word":"convaincues","word_nosc":"convaincues","lemma":"convaincu","pos":"ADJ"} ,
		{"word":"convaincus","word_nosc":"convaincus","lemma":"convaincu","pos":"ADJ"} ,
		{"word":"convalescent","word_nosc":"convalescent","lemma":"convalescent","pos":"ADJ"} ,
		{"word":"convalescente","word_nosc":"convalescente","lemma":"convalescent","pos":"ADJ"} ,
		{"word":"convalescentes","word_nosc":"convalescentes","lemma":"convalescent","pos":"ADJ"} ,
		{"word":"convalescents","word_nosc":"convalescents","lemma":"convalescent","pos":"ADJ"} ,
		{"word":"convenable","word_nosc":"convenable","lemma":"convenable","pos":"ADJ"} ,
		{"word":"convenables","word_nosc":"convenables","lemma":"convenable","pos":"ADJ"} ,
		{"word":"conventionnel","word_nosc":"conventionnel","lemma":"conventionnel","pos":"ADJ"} ,
		{"word":"conventionnelle","word_nosc":"conventionnelle","lemma":"conventionnel","pos":"ADJ"} ,
		{"word":"conventionnelles","word_nosc":"conventionnelles","lemma":"conventionnel","pos":"ADJ"} ,
		{"word":"conventionnels","word_nosc":"conventionnels","lemma":"conventionnel","pos":"ADJ"} ,
		{"word":"conventionnée","word_nosc":"conventionnee","lemma":"conventionné","pos":"ADJ"} ,
		{"word":"conventuel","word_nosc":"conventuel","lemma":"conventuel","pos":"ADJ"} ,
		{"word":"conventuelle","word_nosc":"conventuelle","lemma":"conventuel","pos":"ADJ"} ,
		{"word":"conventuelles","word_nosc":"conventuelles","lemma":"conventuel","pos":"ADJ"} ,
		{"word":"conventuels","word_nosc":"conventuels","lemma":"conventuel","pos":"ADJ"} ,
		{"word":"convenu","word_nosc":"convenu","lemma":"convenu","pos":"ADJ"} ,
		{"word":"convenue","word_nosc":"convenue","lemma":"convenu","pos":"ADJ"} ,
		{"word":"convenues","word_nosc":"convenues","lemma":"convenu","pos":"ADJ"} ,
		{"word":"convenus","word_nosc":"convenus","lemma":"convenu","pos":"ADJ"} ,
		{"word":"convergent","word_nosc":"convergent","lemma":"convergent","pos":"ADJ"} ,
		{"word":"convergente","word_nosc":"convergente","lemma":"convergent","pos":"ADJ"} ,
		{"word":"convergentes","word_nosc":"convergentes","lemma":"convergent","pos":"ADJ"} ,
		{"word":"convergents","word_nosc":"convergents","lemma":"convergent","pos":"ADJ"} ,
		{"word":"convers","word_nosc":"convers","lemma":"convers","pos":"ADJ"} ,
		{"word":"converse","word_nosc":"converse","lemma":"converse","pos":"ADJ"} ,
		{"word":"converses","word_nosc":"converses","lemma":"converse","pos":"ADJ"} ,
		{"word":"converti","word_nosc":"converti","lemma":"converti","pos":"ADJ"} ,
		{"word":"convertible","word_nosc":"convertible","lemma":"convertible","pos":"ADJ"} ,
		{"word":"convertibles","word_nosc":"convertibles","lemma":"convertible","pos":"ADJ"} ,
		{"word":"convertie","word_nosc":"convertie","lemma":"converti","pos":"ADJ"} ,
		{"word":"converties","word_nosc":"converties","lemma":"converti","pos":"ADJ"} ,
		{"word":"convertis","word_nosc":"convertis","lemma":"converti","pos":"ADJ"} ,
		{"word":"convexe","word_nosc":"convexe","lemma":"convexe","pos":"ADJ"} ,
		{"word":"convexes","word_nosc":"convexes","lemma":"convexe","pos":"ADJ"} ,
		{"word":"convivial","word_nosc":"convivial","lemma":"convivial","pos":"ADJ"} ,
		{"word":"conviviale","word_nosc":"conviviale","lemma":"convivial","pos":"ADJ"} ,
		{"word":"conviviales","word_nosc":"conviviales","lemma":"convivial","pos":"ADJ"} ,
		{"word":"conviviaux","word_nosc":"conviviaux","lemma":"convivial","pos":"ADJ"} ,
		{"word":"convié","word_nosc":"convie","lemma":"convié","pos":"ADJ"} ,
		{"word":"conviée","word_nosc":"conviee","lemma":"convié","pos":"ADJ"} ,
		{"word":"conviés","word_nosc":"convies","lemma":"convié","pos":"ADJ"} ,
		{"word":"convocable","word_nosc":"convocable","lemma":"convocable","pos":"ADJ"} ,
		{"word":"convoiteur","word_nosc":"convoiteur","lemma":"convoiteur","pos":"ADJ"} ,
		{"word":"convoiteux","word_nosc":"convoiteux","lemma":"convoiteux","pos":"ADJ"} ,
		{"word":"convulsif","word_nosc":"convulsif","lemma":"convulsif","pos":"ADJ"} ,
		{"word":"convulsifs","word_nosc":"convulsifs","lemma":"convulsif","pos":"ADJ"} ,
		{"word":"convulsionnaire","word_nosc":"convulsionnaire","lemma":"convulsionnaire","pos":"ADJ"} ,
		{"word":"convulsive","word_nosc":"convulsive","lemma":"convulsif","pos":"ADJ"} ,
		{"word":"convulsives","word_nosc":"convulsives","lemma":"convulsif","pos":"ADJ"} ,
		{"word":"convulsé","word_nosc":"convulse","lemma":"convulsé","pos":"ADJ"} ,
		{"word":"convulsée","word_nosc":"convulsee","lemma":"convulsé","pos":"ADJ"} ,
		{"word":"convulsées","word_nosc":"convulsees","lemma":"convulsé","pos":"ADJ"} ,
		{"word":"convulsés","word_nosc":"convulses","lemma":"convulsé","pos":"ADJ"} ,
		{"word":"conçu","word_nosc":"concu","lemma":"conçu","pos":"ADJ"} ,
		{"word":"conçue","word_nosc":"concue","lemma":"conçu","pos":"ADJ"} ,
		{"word":"conçues","word_nosc":"concues","lemma":"conçu","pos":"ADJ"} ,
		{"word":"conçus","word_nosc":"concus","lemma":"conçu","pos":"ADJ"} ,
		{"word":"cooccupant","word_nosc":"cooccupant","lemma":"cooccupant","pos":"ADJ"} ,
		{"word":"cool","word_nosc":"cool","lemma":"cool","pos":"ADJ"} ,
		{"word":"coolos","word_nosc":"coolos","lemma":"coolos","pos":"ADJ"} ,
		{"word":"coopératif","word_nosc":"cooperatif","lemma":"coopératif","pos":"ADJ"} ,
		{"word":"coopératifs","word_nosc":"cooperatifs","lemma":"coopératif","pos":"ADJ"} ,
		{"word":"coopérative","word_nosc":"cooperative","lemma":"coopératif","pos":"ADJ"} ,
		{"word":"coopératives","word_nosc":"cooperatives","lemma":"coopératif","pos":"ADJ"} ,
		{"word":"coordinateur","word_nosc":"coordinateur","lemma":"coordinateur","pos":"ADJ"} ,
		{"word":"coordinatrice","word_nosc":"coordinatrice","lemma":"coordinateur","pos":"ADJ"} ,
		{"word":"coordonnateur","word_nosc":"coordonnateur","lemma":"coordonnateur","pos":"ADJ"} ,
		{"word":"coordonné","word_nosc":"coordonne","lemma":"coordonné","pos":"ADJ"} ,
		{"word":"copernicienne","word_nosc":"copernicienne","lemma":"copernicien","pos":"ADJ"} ,
		{"word":"copiable","word_nosc":"copiable","lemma":"copiable","pos":"ADJ"} ,
		{"word":"copieuse","word_nosc":"copieuse","lemma":"copieur","pos":"ADJ"} ,
		{"word":"copieuses","word_nosc":"copieuses","lemma":"copieur","pos":"ADJ"} ,
		{"word":"copieux","word_nosc":"copieux","lemma":"copieux","pos":"ADJ"} ,
		{"word":"copineur","word_nosc":"copineur","lemma":"copineur","pos":"ADJ"} ,
		{"word":"copineurs","word_nosc":"copineurs","lemma":"copineur","pos":"ADJ"} ,
		{"word":"copineuse","word_nosc":"copineuse","lemma":"copineur","pos":"ADJ"} ,
		{"word":"coprologique","word_nosc":"coprologique","lemma":"coprologique","pos":"ADJ"} ,
		{"word":"coprophage","word_nosc":"coprophage","lemma":"coprophage","pos":"ADJ"} ,
		{"word":"copte","word_nosc":"copte","lemma":"copte","pos":"ADJ"} ,
		{"word":"coquebin","word_nosc":"coquebin","lemma":"coquebin","pos":"ADJ"} ,
		{"word":"coquet","word_nosc":"coquet","lemma":"coquet","pos":"ADJ"} ,
		{"word":"coquets","word_nosc":"coquets","lemma":"coquet","pos":"ADJ"} ,
		{"word":"coquette","word_nosc":"coquette","lemma":"coquet","pos":"ADJ"} ,
		{"word":"coquettes","word_nosc":"coquettes","lemma":"coquet","pos":"ADJ"} ,
		{"word":"coquilleux","word_nosc":"coquilleux","lemma":"coquilleux","pos":"ADJ"} ,
		{"word":"coquin","word_nosc":"coquin","lemma":"coquin","pos":"ADJ"} ,
		{"word":"coquine","word_nosc":"coquine","lemma":"coquin","pos":"ADJ"} ,
		{"word":"coquines","word_nosc":"coquines","lemma":"coquin","pos":"ADJ"} ,
		{"word":"coquinet","word_nosc":"coquinet","lemma":"coquinet","pos":"ADJ"} ,
		{"word":"coquins","word_nosc":"coquins","lemma":"coquin","pos":"ADJ"} ,
		{"word":"corail","word_nosc":"corail","lemma":"corail","pos":"ADJ"} ,
		{"word":"corallien","word_nosc":"corallien","lemma":"corallien","pos":"ADJ"} ,
		{"word":"coralliens","word_nosc":"coralliens","lemma":"corallien","pos":"ADJ"} ,
		{"word":"coranique","word_nosc":"coranique","lemma":"coranique","pos":"ADJ"} ,
		{"word":"coraniques","word_nosc":"coraniques","lemma":"coranique","pos":"ADJ"} ,
		{"word":"cordial","word_nosc":"cordial","lemma":"cordial","pos":"ADJ"} ,
		{"word":"cordiale","word_nosc":"cordiale","lemma":"cordial","pos":"ADJ"} ,
		{"word":"cordiales","word_nosc":"cordiales","lemma":"cordial","pos":"ADJ"} ,
		{"word":"cordiaux","word_nosc":"cordiaux","lemma":"cordial","pos":"ADJ"} ,
		{"word":"cordiforme","word_nosc":"cordiforme","lemma":"cordiforme","pos":"ADJ"} ,
		{"word":"cordiformes","word_nosc":"cordiformes","lemma":"cordiforme","pos":"ADJ"} ,
		{"word":"cordouan","word_nosc":"cordouan","lemma":"cordouan","pos":"ADJ"} ,
		{"word":"cordé","word_nosc":"corde","lemma":"cordé","pos":"ADJ"} ,
		{"word":"cordée","word_nosc":"cordee","lemma":"cordée","pos":"ADJ"} ,
		{"word":"cordées","word_nosc":"cordees","lemma":"cordée","pos":"ADJ"} ,
		{"word":"cordés","word_nosc":"cordes","lemma":"cordé","pos":"ADJ"} ,
		{"word":"corelliens","word_nosc":"corelliens","lemma":"corellien","pos":"ADJ"} ,
		{"word":"coriace","word_nosc":"coriace","lemma":"coriace","pos":"ADJ"} ,
		{"word":"coriaces","word_nosc":"coriaces","lemma":"coriace","pos":"ADJ"} ,
		{"word":"corinthien","word_nosc":"corinthien","lemma":"corinthien","pos":"ADJ"} ,
		{"word":"corinthiennes","word_nosc":"corinthiennes","lemma":"corinthien","pos":"ADJ"} ,
		{"word":"corinthiens","word_nosc":"corinthiens","lemma":"corinthien","pos":"ADJ"} ,
		{"word":"cornu","word_nosc":"cornu","lemma":"cornu","pos":"ADJ"} ,
		{"word":"cornue","word_nosc":"cornue","lemma":"cornu","pos":"ADJ"} ,
		{"word":"cornues","word_nosc":"cornues","lemma":"cornu","pos":"ADJ"} ,
		{"word":"cornus","word_nosc":"cornus","lemma":"cornu","pos":"ADJ"} ,
		{"word":"corné","word_nosc":"corne","lemma":"corné","pos":"ADJ"} ,
		{"word":"cornée","word_nosc":"cornee","lemma":"corné","pos":"ADJ"} ,
		{"word":"cornéen","word_nosc":"corneen","lemma":"cornéen","pos":"ADJ"} ,
		{"word":"cornéenne","word_nosc":"corneenne","lemma":"cornéen","pos":"ADJ"} ,
		{"word":"cornées","word_nosc":"cornees","lemma":"corné","pos":"ADJ"} ,
		{"word":"cornélien","word_nosc":"cornelien","lemma":"cornélien","pos":"ADJ"} ,
		{"word":"cornélienne","word_nosc":"cornelienne","lemma":"cornélien","pos":"ADJ"} ,
		{"word":"cornéliennes","word_nosc":"corneliennes","lemma":"cornélien","pos":"ADJ"} ,
		{"word":"cornés","word_nosc":"cornes","lemma":"corné","pos":"ADJ"} ,
		{"word":"coronaire","word_nosc":"coronaire","lemma":"coronaire","pos":"ADJ"} ,
		{"word":"coronaires","word_nosc":"coronaires","lemma":"coronaire","pos":"ADJ"} ,
		{"word":"coronal","word_nosc":"coronal","lemma":"coronal","pos":"ADJ"} ,
		{"word":"coronale","word_nosc":"coronale","lemma":"coronal","pos":"ADJ"} ,
		{"word":"coronales","word_nosc":"coronales","lemma":"coronal","pos":"ADJ"} ,
		{"word":"coronarien","word_nosc":"coronarien","lemma":"coronarien","pos":"ADJ"} ,
		{"word":"coronarienne","word_nosc":"coronarienne","lemma":"coronarien","pos":"ADJ"} ,
		{"word":"corporatif","word_nosc":"corporatif","lemma":"corporatif","pos":"ADJ"} ,
		{"word":"corporatifs","word_nosc":"corporatifs","lemma":"corporatif","pos":"ADJ"} ,
		{"word":"corporatiste","word_nosc":"corporatiste","lemma":"corporatiste","pos":"ADJ"} ,
		{"word":"corporative","word_nosc":"corporative","lemma":"corporatif","pos":"ADJ"} ,
		{"word":"corporatives","word_nosc":"corporatives","lemma":"corporatif","pos":"ADJ"} ,
		{"word":"corporel","word_nosc":"corporel","lemma":"corporel","pos":"ADJ"} ,
		{"word":"corporelle","word_nosc":"corporelle","lemma":"corporel","pos":"ADJ"} ,
		{"word":"corporelles","word_nosc":"corporelles","lemma":"corporel","pos":"ADJ"} ,
		{"word":"corporels","word_nosc":"corporels","lemma":"corporel","pos":"ADJ"} ,
		{"word":"corpulent","word_nosc":"corpulent","lemma":"corpulent","pos":"ADJ"} ,
		{"word":"corpulente","word_nosc":"corpulente","lemma":"corpulent","pos":"ADJ"} ,
		{"word":"corpulentes","word_nosc":"corpulentes","lemma":"corpulent","pos":"ADJ"} ,
		{"word":"corpulents","word_nosc":"corpulents","lemma":"corpulent","pos":"ADJ"} ,
		{"word":"correct","word_nosc":"correct","lemma":"correct","pos":"ADJ"} ,
		{"word":"correcte","word_nosc":"correcte","lemma":"correct","pos":"ADJ"} ,
		{"word":"correctes","word_nosc":"correctes","lemma":"correct","pos":"ADJ"} ,
		{"word":"correcteur","word_nosc":"correcteur","lemma":"correcteur","pos":"ADJ"} ,
		{"word":"correcteurs","word_nosc":"correcteurs","lemma":"correcteur","pos":"ADJ"} ,
		{"word":"correctif","word_nosc":"correctif","lemma":"correctif","pos":"ADJ"} ,
		{"word":"correctionnel","word_nosc":"correctionnel","lemma":"correctionnel","pos":"ADJ"} ,
		{"word":"correctionnelle","word_nosc":"correctionnelle","lemma":"correctionnel","pos":"ADJ"} ,
		{"word":"correctionnelles","word_nosc":"correctionnelles","lemma":"correctionnel","pos":"ADJ"} ,
		{"word":"correctionnels","word_nosc":"correctionnels","lemma":"correctionnel","pos":"ADJ"} ,
		{"word":"corrective","word_nosc":"corrective","lemma":"correctif","pos":"ADJ"} ,
		{"word":"correctrice","word_nosc":"correctrice","lemma":"correcteur","pos":"ADJ"} ,
		{"word":"correctrices","word_nosc":"correctrices","lemma":"correcteur","pos":"ADJ"} ,
		{"word":"corrects","word_nosc":"corrects","lemma":"correct","pos":"ADJ"} ,
		{"word":"correspondant","word_nosc":"correspondant","lemma":"correspondant","pos":"ADJ"} ,
		{"word":"correspondante","word_nosc":"correspondante","lemma":"correspondant","pos":"ADJ"} ,
		{"word":"correspondantes","word_nosc":"correspondantes","lemma":"correspondant","pos":"ADJ"} ,
		{"word":"correspondants","word_nosc":"correspondants","lemma":"correspondant","pos":"ADJ"} ,
		{"word":"corrigé","word_nosc":"corrige","lemma":"corrigé","pos":"ADJ"} ,
		{"word":"corrigée","word_nosc":"corrigee","lemma":"corrigé","pos":"ADJ"} ,
		{"word":"corrigées","word_nosc":"corrigees","lemma":"corrigé","pos":"ADJ"} ,
		{"word":"corrigés","word_nosc":"corriges","lemma":"corrigé","pos":"ADJ"} ,
		{"word":"corrodant","word_nosc":"corrodant","lemma":"corrodant","pos":"ADJ"} ,
		{"word":"corrodante","word_nosc":"corrodante","lemma":"corrodant","pos":"ADJ"} ,
		{"word":"corrompu","word_nosc":"corrompu","lemma":"corrompu","pos":"ADJ"} ,
		{"word":"corrompue","word_nosc":"corrompue","lemma":"corrompre","pos":"ADJ"} ,
		{"word":"corrompues","word_nosc":"corrompues","lemma":"corrompre","pos":"ADJ"} ,
		{"word":"corrompus","word_nosc":"corrompus","lemma":"corrompu","pos":"ADJ"} ,
		{"word":"corrosif","word_nosc":"corrosif","lemma":"corrosif","pos":"ADJ"} ,
		{"word":"corrosifs","word_nosc":"corrosifs","lemma":"corrosif","pos":"ADJ"} ,
		{"word":"corrosive","word_nosc":"corrosive","lemma":"corrosif","pos":"ADJ"} ,
		{"word":"corrosives","word_nosc":"corrosives","lemma":"corrosif","pos":"ADJ"} ,
		{"word":"corrupteur","word_nosc":"corrupteur","lemma":"corrupteur","pos":"ADJ"} ,
		{"word":"corruptible","word_nosc":"corruptible","lemma":"corruptible","pos":"ADJ"} ,
		{"word":"corruptibles","word_nosc":"corruptibles","lemma":"corruptible","pos":"ADJ"} ,
		{"word":"corruptrice","word_nosc":"corruptrice","lemma":"corrupteur","pos":"ADJ"} ,
		{"word":"corruptrices","word_nosc":"corruptrices","lemma":"corrupteur","pos":"ADJ"} ,
		{"word":"corrélatif","word_nosc":"correlatif","lemma":"corrélatif","pos":"ADJ"} ,
		{"word":"corrélative","word_nosc":"correlative","lemma":"corrélatif","pos":"ADJ"} ,
		{"word":"corréziennes","word_nosc":"correziennes","lemma":"corrézien","pos":"ADJ"} ,
		{"word":"corse","word_nosc":"corse","lemma":"corse","pos":"ADJ"} ,
		{"word":"corses","word_nosc":"corses","lemma":"corse","pos":"ADJ"} ,
		{"word":"corsé","word_nosc":"corse","lemma":"corsé","pos":"ADJ"} ,
		{"word":"corsée","word_nosc":"corsee","lemma":"corsé","pos":"ADJ"} ,
		{"word":"corsées","word_nosc":"corsees","lemma":"corsé","pos":"ADJ"} ,
		{"word":"corsés","word_nosc":"corses","lemma":"corsé","pos":"ADJ"} ,
		{"word":"cortical","word_nosc":"cortical","lemma":"cortical","pos":"ADJ"} ,
		{"word":"corticale","word_nosc":"corticale","lemma":"cortical","pos":"ADJ"} ,
		{"word":"corticales","word_nosc":"corticales","lemma":"cortical","pos":"ADJ"} ,
		{"word":"corticaux","word_nosc":"corticaux","lemma":"cortical","pos":"ADJ"} ,
		{"word":"coruscant","word_nosc":"coruscant","lemma":"coruscant","pos":"ADJ"} ,
		{"word":"coruscante","word_nosc":"coruscante","lemma":"coruscant","pos":"ADJ"} ,
		{"word":"coruscants","word_nosc":"coruscants","lemma":"coruscant","pos":"ADJ"} ,
		{"word":"corvéable","word_nosc":"corveable","lemma":"corvéable","pos":"ADJ"} ,
		{"word":"coréen","word_nosc":"coreen","lemma":"coréen","pos":"ADJ"} ,
		{"word":"coréenne","word_nosc":"coreenne","lemma":"coréen","pos":"ADJ"} ,
		{"word":"coréennes","word_nosc":"coreennes","lemma":"coréen","pos":"ADJ"} ,
		{"word":"coréens","word_nosc":"coreens","lemma":"coréen","pos":"ADJ"} ,
		{"word":"cosmique","word_nosc":"cosmique","lemma":"cosmique","pos":"ADJ"} ,
		{"word":"cosmiques","word_nosc":"cosmiques","lemma":"cosmique","pos":"ADJ"} ,
		{"word":"cosmogonique","word_nosc":"cosmogonique","lemma":"cosmogonique","pos":"ADJ"} ,
		{"word":"cosmologique","word_nosc":"cosmologique","lemma":"cosmologique","pos":"ADJ"} ,
		{"word":"cosmologiques","word_nosc":"cosmologiques","lemma":"cosmologique","pos":"ADJ"} ,
		{"word":"cosmopolite","word_nosc":"cosmopolite","lemma":"cosmopolite","pos":"ADJ"} ,
		{"word":"cosmopolites","word_nosc":"cosmopolites","lemma":"cosmopolite","pos":"ADJ"} ,
		{"word":"cosmétique","word_nosc":"cosmetique","lemma":"cosmétique","pos":"ADJ"} ,
		{"word":"cosmétiques","word_nosc":"cosmetiques","lemma":"cosmétique","pos":"ADJ"} ,
		{"word":"cossard","word_nosc":"cossard","lemma":"cossard","pos":"ADJ"} ,
		{"word":"cossarde","word_nosc":"cossarde","lemma":"cossard","pos":"ADJ"} ,
		{"word":"cossards","word_nosc":"cossards","lemma":"cossard","pos":"ADJ"} ,
		{"word":"cossu","word_nosc":"cossu","lemma":"cossu","pos":"ADJ"} ,
		{"word":"cossue","word_nosc":"cossue","lemma":"cossu","pos":"ADJ"} ,
		{"word":"cossues","word_nosc":"cossues","lemma":"cossu","pos":"ADJ"} ,
		{"word":"cossus","word_nosc":"cossus","lemma":"cossu","pos":"ADJ"} ,
		{"word":"costal","word_nosc":"costal","lemma":"costal","pos":"ADJ"} ,
		{"word":"costale","word_nosc":"costale","lemma":"costal","pos":"ADJ"} ,
		{"word":"costaricain","word_nosc":"costaricain","lemma":"costaricain","pos":"ADJ"} ,
		{"word":"costaud","word_nosc":"costaud","lemma":"costaud","pos":"ADJ"} ,
		{"word":"costaude","word_nosc":"costaude","lemma":"costaud","pos":"ADJ"} ,
		{"word":"costaudes","word_nosc":"costaudes","lemma":"costaud","pos":"ADJ"} ,
		{"word":"costauds","word_nosc":"costauds","lemma":"costaud","pos":"ADJ"} ,
		{"word":"costaux","word_nosc":"costaux","lemma":"costal","pos":"ADJ"} ,
		{"word":"costumé","word_nosc":"costume","lemma":"costumé","pos":"ADJ"} ,
		{"word":"costumée","word_nosc":"costumee","lemma":"costumé","pos":"ADJ"} ,
		{"word":"costumés","word_nosc":"costumes","lemma":"costumé","pos":"ADJ"} ,
		{"word":"cosy","word_nosc":"cosy","lemma":"cosy","pos":"ADJ"} ,
		{"word":"cotonneuse","word_nosc":"cotonneuse","lemma":"cotonneux","pos":"ADJ"} ,
		{"word":"cotonneuses","word_nosc":"cotonneuses","lemma":"cotonneux","pos":"ADJ"} ,
		{"word":"cotonneux","word_nosc":"cotonneux","lemma":"cotonneux","pos":"ADJ"} ,
		{"word":"cotonnier","word_nosc":"cotonnier","lemma":"cotonnier","pos":"ADJ"} ,
		{"word":"coté","word_nosc":"cote","lemma":"coté","pos":"ADJ"} ,
		{"word":"cotée","word_nosc":"cotee","lemma":"coté","pos":"ADJ"} ,
		{"word":"cotées","word_nosc":"cotees","lemma":"coté","pos":"ADJ"} ,
		{"word":"cotés","word_nosc":"cotes","lemma":"coté","pos":"ADJ"} ,
		{"word":"couard","word_nosc":"couard","lemma":"couard","pos":"ADJ"} ,
		{"word":"couarde","word_nosc":"couarde","lemma":"couard","pos":"ADJ"} ,
		{"word":"couards","word_nosc":"couards","lemma":"couard","pos":"ADJ"} ,
		{"word":"couchant","word_nosc":"couchant","lemma":"couchant","pos":"ADJ"} ,
		{"word":"couchants","word_nosc":"couchants","lemma":"couchant","pos":"ADJ"} ,
		{"word":"couché","word_nosc":"couche","lemma":"couché","pos":"ADJ"} ,
		{"word":"couchée","word_nosc":"couchee","lemma":"couché","pos":"ADJ"} ,
		{"word":"couchées","word_nosc":"couchees","lemma":"couché","pos":"ADJ"} ,
		{"word":"couchés","word_nosc":"couches","lemma":"couché","pos":"ADJ"} ,
		{"word":"coudé","word_nosc":"coude","lemma":"coudé","pos":"ADJ"} ,
		{"word":"coudée","word_nosc":"coudee","lemma":"coudé","pos":"ADJ"} ,
		{"word":"coudées","word_nosc":"coudees","lemma":"coudé","pos":"ADJ"} ,
		{"word":"couillon","word_nosc":"couillon","lemma":"couillon","pos":"ADJ"} ,
		{"word":"couillons","word_nosc":"couillons","lemma":"couillon","pos":"ADJ"} ,
		{"word":"couillu","word_nosc":"couillu","lemma":"couillu","pos":"ADJ"} ,
		{"word":"couillus","word_nosc":"couillus","lemma":"couillu","pos":"ADJ"} ,
		{"word":"couinant","word_nosc":"couinant","lemma":"couinant","pos":"ADJ"} ,
		{"word":"coulant","word_nosc":"coulant","lemma":"coulant","pos":"ADJ"} ,
		{"word":"coulante","word_nosc":"coulante","lemma":"coulant","pos":"ADJ"} ,
		{"word":"coulants","word_nosc":"coulants","lemma":"coulant","pos":"ADJ"} ,
		{"word":"coulis","word_nosc":"coulis","lemma":"coulis","pos":"ADJ"} ,
		{"word":"coulissant","word_nosc":"coulissant","lemma":"coulissant","pos":"ADJ"} ,
		{"word":"coulissante","word_nosc":"coulissante","lemma":"coulissant","pos":"ADJ"} ,
		{"word":"coulissantes","word_nosc":"coulissantes","lemma":"coulissant","pos":"ADJ"} ,
		{"word":"coulissants","word_nosc":"coulissants","lemma":"coulissant","pos":"ADJ"} ,
		{"word":"coulé","word_nosc":"coule","lemma":"coulé","pos":"ADJ"} ,
		{"word":"coulée","word_nosc":"coulee","lemma":"coulé","pos":"ADJ"} ,
		{"word":"coulées","word_nosc":"coulees","lemma":"coulé","pos":"ADJ"} ,
		{"word":"coulés","word_nosc":"coules","lemma":"coulé","pos":"ADJ"} ,
		{"word":"country","word_nosc":"country","lemma":"country","pos":"ADJ"} ,
		{"word":"coupable","word_nosc":"coupable","lemma":"coupable","pos":"ADJ"} ,
		{"word":"coupables","word_nosc":"coupables","lemma":"coupable","pos":"ADJ"} ,
		{"word":"coupant","word_nosc":"coupant","lemma":"coupant","pos":"ADJ"} ,
		{"word":"coupante","word_nosc":"coupante","lemma":"coupant","pos":"ADJ"} ,
		{"word":"coupantes","word_nosc":"coupantes","lemma":"coupant","pos":"ADJ"} ,
		{"word":"coupants","word_nosc":"coupants","lemma":"coupant","pos":"ADJ"} ,
		{"word":"couperosé","word_nosc":"couperose","lemma":"couperosé","pos":"ADJ"} ,
		{"word":"couperosée","word_nosc":"couperosee","lemma":"couperosé","pos":"ADJ"} ,
		{"word":"couperosées","word_nosc":"couperosees","lemma":"couperosé","pos":"ADJ"} ,
		{"word":"coupé","word_nosc":"coupe","lemma":"coupé","pos":"ADJ"} ,
		{"word":"coupée","word_nosc":"coupee","lemma":"coupé","pos":"ADJ"} ,
		{"word":"coupées","word_nosc":"coupees","lemma":"coupé","pos":"ADJ"} ,
		{"word":"coupés","word_nosc":"coupes","lemma":"coupé","pos":"ADJ"} ,
		{"word":"courageuse","word_nosc":"courageuse","lemma":"courageux","pos":"ADJ"} ,
		{"word":"courageuses","word_nosc":"courageuses","lemma":"courageux","pos":"ADJ"} ,
		{"word":"courageux","word_nosc":"courageux","lemma":"courageux","pos":"ADJ"} ,
		{"word":"courant","word_nosc":"courant","lemma":"courant","pos":"ADJ"} ,
		{"word":"courante","word_nosc":"courante","lemma":"courant","pos":"ADJ"} ,
		{"word":"courantes","word_nosc":"courantes","lemma":"courant","pos":"ADJ"} ,
		{"word":"courants","word_nosc":"courants","lemma":"courant","pos":"ADJ"} ,
		{"word":"courbatu","word_nosc":"courbatu","lemma":"courbatu","pos":"ADJ"} ,
		{"word":"courbatue","word_nosc":"courbatue","lemma":"courbatu","pos":"ADJ"} ,
		{"word":"courbaturé","word_nosc":"courbature","lemma":"courbaturé","pos":"ADJ"} ,
		{"word":"courbaturée","word_nosc":"courbaturee","lemma":"courbaturé","pos":"ADJ"} ,
		{"word":"courbatus","word_nosc":"courbatus","lemma":"courbatu","pos":"ADJ"} ,
		{"word":"courbe","word_nosc":"courbe","lemma":"courbe","pos":"ADJ"} ,
		{"word":"courbes","word_nosc":"courbes","lemma":"courbe","pos":"ADJ"} ,
		{"word":"courbé","word_nosc":"courbe","lemma":"courbé","pos":"ADJ"} ,
		{"word":"courbée","word_nosc":"courbee","lemma":"courbé","pos":"ADJ"} ,
		{"word":"courbées","word_nosc":"courbees","lemma":"courbé","pos":"ADJ"} ,
		{"word":"courbés","word_nosc":"courbes","lemma":"courbé","pos":"ADJ"} ,
		{"word":"couronné","word_nosc":"couronne","lemma":"couronné","pos":"ADJ"} ,
		{"word":"couronnée","word_nosc":"couronnee","lemma":"couronné","pos":"ADJ"} ,
		{"word":"couronnées","word_nosc":"couronnees","lemma":"couronné","pos":"ADJ"} ,
		{"word":"couronnés","word_nosc":"couronnes","lemma":"couronné","pos":"ADJ"} ,
		{"word":"court","word_nosc":"court","lemma":"court","pos":"ADJ"} ,
		{"word":"court-circuits","word_nosc":"court-circuits","lemma":"court-circuits","pos":"ADJ"} ,
		{"word":"court-vêtue","word_nosc":"court-vetue","lemma":"court-vêtu","pos":"ADJ"} ,
		{"word":"court-vêtues","word_nosc":"court-vetues","lemma":"court-vêtu","pos":"ADJ"} ,
		{"word":"courtaud","word_nosc":"courtaud","lemma":"courtaud","pos":"ADJ"} ,
		{"word":"courtaude","word_nosc":"courtaude","lemma":"courtaud","pos":"ADJ"} ,
		{"word":"courtaudes","word_nosc":"courtaudes","lemma":"courtaud","pos":"ADJ"} ,
		{"word":"courtauds","word_nosc":"courtauds","lemma":"courtaud","pos":"ADJ"} ,
		{"word":"courte","word_nosc":"courte","lemma":"court","pos":"ADJ"} ,
		{"word":"courtelinesque","word_nosc":"courtelinesque","lemma":"courtelinesque","pos":"ADJ"} ,
		{"word":"courtelinesques","word_nosc":"courtelinesques","lemma":"courtelinesque","pos":"ADJ"} ,
		{"word":"courtes","word_nosc":"courtes","lemma":"court","pos":"ADJ"} ,
		{"word":"courtisan","word_nosc":"courtisan","lemma":"courtisan","pos":"ADJ"} ,
		{"word":"courtisans","word_nosc":"courtisans","lemma":"courtisan","pos":"ADJ"} ,
		{"word":"courtois","word_nosc":"courtois","lemma":"courtois","pos":"ADJ"} ,
		{"word":"courtoise","word_nosc":"courtoise","lemma":"courtois","pos":"ADJ"} ,
		{"word":"courtoises","word_nosc":"courtoises","lemma":"courtois","pos":"ADJ"} ,
		{"word":"courts","word_nosc":"courts","lemma":"court","pos":"ADJ"} ,
		{"word":"couru","word_nosc":"couru","lemma":"couru","pos":"ADJ"} ,
		{"word":"courue","word_nosc":"courue","lemma":"couru","pos":"ADJ"} ,
		{"word":"courues","word_nosc":"courues","lemma":"couru","pos":"ADJ"} ,
		{"word":"courus","word_nosc":"courus","lemma":"couru","pos":"ADJ"} ,
		{"word":"cousu","word_nosc":"cousu","lemma":"cousu","pos":"ADJ"} ,
		{"word":"cousue","word_nosc":"cousue","lemma":"cousu","pos":"ADJ"} ,
		{"word":"cousues","word_nosc":"cousues","lemma":"cousu","pos":"ADJ"} ,
		{"word":"cousus","word_nosc":"cousus","lemma":"cousu","pos":"ADJ"} ,
		{"word":"coutumier","word_nosc":"coutumier","lemma":"coutumier","pos":"ADJ"} ,
		{"word":"coutumiers","word_nosc":"coutumiers","lemma":"coutumier","pos":"ADJ"} ,
		{"word":"coutumière","word_nosc":"coutumiere","lemma":"coutumier","pos":"ADJ"} ,
		{"word":"coutumières","word_nosc":"coutumieres","lemma":"coutumier","pos":"ADJ"} ,
		{"word":"couturé","word_nosc":"couture","lemma":"couturé","pos":"ADJ"} ,
		{"word":"couturée","word_nosc":"couturee","lemma":"couturé","pos":"ADJ"} ,
		{"word":"couturées","word_nosc":"couturees","lemma":"couturé","pos":"ADJ"} ,
		{"word":"couvert","word_nosc":"couvert","lemma":"couvert","pos":"ADJ"} ,
		{"word":"couverte","word_nosc":"couverte","lemma":"couvert","pos":"ADJ"} ,
		{"word":"couvertes","word_nosc":"couvertes","lemma":"couvert","pos":"ADJ"} ,
		{"word":"couverts","word_nosc":"couverts","lemma":"couvert","pos":"ADJ"} ,
		{"word":"couvis","word_nosc":"couvis","lemma":"couvi","pos":"ADJ"} ,
		{"word":"couvrant","word_nosc":"couvrant","lemma":"couvrant","pos":"ADJ"} ,
		{"word":"couvrante","word_nosc":"couvrante","lemma":"couvrant","pos":"ADJ"} ,
		{"word":"couvrantes","word_nosc":"couvrantes","lemma":"couvrant","pos":"ADJ"} ,
		{"word":"covalent","word_nosc":"covalent","lemma":"covalent","pos":"ADJ"} ,
		{"word":"covalente","word_nosc":"covalente","lemma":"covalent","pos":"ADJ"} ,
		{"word":"coxalgique","word_nosc":"coxalgique","lemma":"coxalgique","pos":"ADJ"} ,
		{"word":"coïncident","word_nosc":"coincident","lemma":"coïncident","pos":"ADJ"} ,
		{"word":"coûtant","word_nosc":"coutant","lemma":"coûtant","pos":"ADJ"} ,
		{"word":"coûteuse","word_nosc":"couteuse","lemma":"coûteux","pos":"ADJ"} ,
		{"word":"coûteuses","word_nosc":"couteuses","lemma":"coûteux","pos":"ADJ"} ,
		{"word":"coûteux","word_nosc":"couteux","lemma":"coûteux","pos":"ADJ"} ,
		{"word":"crachotant","word_nosc":"crachotant","lemma":"crachotant","pos":"ADJ"} ,
		{"word":"crachotantes","word_nosc":"crachotantes","lemma":"crachotant","pos":"ADJ"} ,
		{"word":"crachotants","word_nosc":"crachotants","lemma":"crachotant","pos":"ADJ"} ,
		{"word":"crachoteuse","word_nosc":"crachoteuse","lemma":"crachoteux","pos":"ADJ"} ,
		{"word":"crachoteux","word_nosc":"crachoteux","lemma":"crachoteux","pos":"ADJ"} ,
		{"word":"craché","word_nosc":"crache","lemma":"craché","pos":"ADJ"} ,
		{"word":"crachée","word_nosc":"crachee","lemma":"craché","pos":"ADJ"} ,
		{"word":"crachés","word_nosc":"craches","lemma":"craché","pos":"ADJ"} ,
		{"word":"cracra","word_nosc":"cracra","lemma":"cracra","pos":"ADJ"} ,
		{"word":"crade","word_nosc":"crade","lemma":"crade","pos":"ADJ"} ,
		{"word":"crades","word_nosc":"crades","lemma":"crade","pos":"ADJ"} ,
		{"word":"cradingue","word_nosc":"cradingue","lemma":"cradingue","pos":"ADJ"} ,
		{"word":"cradingues","word_nosc":"cradingues","lemma":"cradingue","pos":"ADJ"} ,
		{"word":"crado","word_nosc":"crado","lemma":"crado","pos":"ADJ"} ,
		{"word":"cradoque","word_nosc":"cradoque","lemma":"cradoque","pos":"ADJ"} ,
		{"word":"crados","word_nosc":"crados","lemma":"crado","pos":"ADJ"} ,
		{"word":"craignos","word_nosc":"craignos","lemma":"craignos","pos":"ADJ"} ,
		{"word":"craintif","word_nosc":"craintif","lemma":"craintif","pos":"ADJ"} ,
		{"word":"craintifs","word_nosc":"craintifs","lemma":"craintif","pos":"ADJ"} ,
		{"word":"craintive","word_nosc":"craintive","lemma":"craintif","pos":"ADJ"} ,
		{"word":"craintives","word_nosc":"craintives","lemma":"craintif","pos":"ADJ"} ,
		{"word":"cramoisi","word_nosc":"cramoisi","lemma":"cramoisi","pos":"ADJ"} ,
		{"word":"cramoisie","word_nosc":"cramoisie","lemma":"cramoisi","pos":"ADJ"} ,
		{"word":"cramoisies","word_nosc":"cramoisies","lemma":"cramoisi","pos":"ADJ"} ,
		{"word":"cramoisis","word_nosc":"cramoisis","lemma":"cramoisi","pos":"ADJ"} ,
		{"word":"craonnais","word_nosc":"craonnais","lemma":"craonnais","pos":"ADJ"} ,
		{"word":"craonnaise","word_nosc":"craonnaise","lemma":"craonnais","pos":"ADJ"} ,
		{"word":"craonnaises","word_nosc":"craonnaises","lemma":"craonnais","pos":"ADJ"} ,
		{"word":"crapoteuse","word_nosc":"crapoteuse","lemma":"crapoteux","pos":"ADJ"} ,
		{"word":"crapoteuses","word_nosc":"crapoteuses","lemma":"crapoteux","pos":"ADJ"} ,
		{"word":"crapoteux","word_nosc":"crapoteux","lemma":"crapoteux","pos":"ADJ"} ,
		{"word":"crapule","word_nosc":"crapule","lemma":"crapule","pos":"ADJ"} ,
		{"word":"crapules","word_nosc":"crapules","lemma":"crapule","pos":"ADJ"} ,
		{"word":"crapuleuse","word_nosc":"crapuleuse","lemma":"crapuleux","pos":"ADJ"} ,
		{"word":"crapuleuses","word_nosc":"crapuleuses","lemma":"crapuleux","pos":"ADJ"} ,
		{"word":"crapuleux","word_nosc":"crapuleux","lemma":"crapuleux","pos":"ADJ"} ,
		{"word":"craquant","word_nosc":"craquant","lemma":"craquant","pos":"ADJ"} ,
		{"word":"craquante","word_nosc":"craquante","lemma":"craquant","pos":"ADJ"} ,
		{"word":"craquantes","word_nosc":"craquantes","lemma":"craquant","pos":"ADJ"} ,
		{"word":"craquants","word_nosc":"craquants","lemma":"craquant","pos":"ADJ"} ,
		{"word":"craquelé","word_nosc":"craquele","lemma":"craquelé","pos":"ADJ"} ,
		{"word":"craquelée","word_nosc":"craquelee","lemma":"craquelé","pos":"ADJ"} ,
		{"word":"craquelées","word_nosc":"craquelees","lemma":"craquelé","pos":"ADJ"} ,
		{"word":"craquelés","word_nosc":"craqueles","lemma":"craquelé","pos":"ADJ"} ,
		{"word":"craspec","word_nosc":"craspec","lemma":"craspec","pos":"ADJ"} ,
		{"word":"crasseuse","word_nosc":"crasseuse","lemma":"crasseux","pos":"ADJ"} ,
		{"word":"crasseuses","word_nosc":"crasseuses","lemma":"crasseux","pos":"ADJ"} ,
		{"word":"crasseux","word_nosc":"crasseux","lemma":"crasseux","pos":"ADJ"} ,
		{"word":"crawlé","word_nosc":"crawle","lemma":"crawlé","pos":"ADJ"} ,
		{"word":"crayeuse","word_nosc":"crayeuse","lemma":"crayeux","pos":"ADJ"} ,
		{"word":"crayeuses","word_nosc":"crayeuses","lemma":"crayeux","pos":"ADJ"} ,
		{"word":"crayeux","word_nosc":"crayeux","lemma":"crayeux","pos":"ADJ"} ,
		{"word":"crayonnés","word_nosc":"crayonnes","lemma":"crayonné","pos":"ADJ"} ,
		{"word":"crescendo","word_nosc":"crescendo","lemma":"crescendo","pos":"ADJ"} ,
		{"word":"creuse","word_nosc":"creuse","lemma":"creux","pos":"ADJ"} ,
		{"word":"creuses","word_nosc":"creuses","lemma":"creux","pos":"ADJ"} ,
		{"word":"creux","word_nosc":"creux","lemma":"creux","pos":"ADJ"} ,
		{"word":"crevant","word_nosc":"crevant","lemma":"crevant","pos":"ADJ"} ,
		{"word":"crevante","word_nosc":"crevante","lemma":"crevant","pos":"ADJ"} ,
		{"word":"crevants","word_nosc":"crevants","lemma":"crevant","pos":"ADJ"} ,
		{"word":"crevarde","word_nosc":"crevarde","lemma":"crevard","pos":"ADJ"} ,
		{"word":"crevards","word_nosc":"crevards","lemma":"crevard","pos":"ADJ"} ,
		{"word":"crevette","word_nosc":"crevette","lemma":"crevette","pos":"ADJ"} ,
		{"word":"crevé","word_nosc":"creve","lemma":"crevé","pos":"ADJ"} ,
		{"word":"criant","word_nosc":"criant","lemma":"criant","pos":"ADJ"} ,
		{"word":"criante","word_nosc":"criante","lemma":"criant","pos":"ADJ"} ,
		{"word":"criantes","word_nosc":"criantes","lemma":"criant","pos":"ADJ"} ,
		{"word":"criard","word_nosc":"criard","lemma":"criard","pos":"ADJ"} ,
		{"word":"criarde","word_nosc":"criarde","lemma":"criard","pos":"ADJ"} ,
		{"word":"criardes","word_nosc":"criardes","lemma":"criard","pos":"ADJ"} ,
		{"word":"criards","word_nosc":"criards","lemma":"criard","pos":"ADJ"} ,
		{"word":"cricoïde","word_nosc":"cricoide","lemma":"cricoïde","pos":"ADJ"} ,
		{"word":"criminalistique","word_nosc":"criminalistique","lemma":"criminalistique","pos":"ADJ"} ,
		{"word":"criminel","word_nosc":"criminel","lemma":"criminel","pos":"ADJ"} ,
		{"word":"criminelle","word_nosc":"criminelle","lemma":"criminel","pos":"ADJ"} ,
		{"word":"criminelles","word_nosc":"criminelles","lemma":"criminel","pos":"ADJ"} ,
		{"word":"criminels","word_nosc":"criminels","lemma":"criminel","pos":"ADJ"} ,
		{"word":"crispant","word_nosc":"crispant","lemma":"crispant","pos":"ADJ"} ,
		{"word":"crispante","word_nosc":"crispante","lemma":"crispant","pos":"ADJ"} ,
		{"word":"crispantes","word_nosc":"crispantes","lemma":"crispant","pos":"ADJ"} ,
		{"word":"cristallin","word_nosc":"cristallin","lemma":"cristallin","pos":"ADJ"} ,
		{"word":"cristalline","word_nosc":"cristalline","lemma":"cristallin","pos":"ADJ"} ,
		{"word":"cristallines","word_nosc":"cristallines","lemma":"cristallin","pos":"ADJ"} ,
		{"word":"cristallins","word_nosc":"cristallins","lemma":"cristallin","pos":"ADJ"} ,
		{"word":"cristallisé","word_nosc":"cristallise","lemma":"cristallisé","pos":"ADJ"} ,
		{"word":"cristallisée","word_nosc":"cristallisee","lemma":"cristallisé","pos":"ADJ"} ,
		{"word":"cristallisées","word_nosc":"cristallisees","lemma":"cristallisé","pos":"ADJ"} ,
		{"word":"cristalloïde","word_nosc":"cristalloide","lemma":"cristalloïde","pos":"ADJ"} ,
		{"word":"critiquable","word_nosc":"critiquable","lemma":"critiquable","pos":"ADJ"} ,
		{"word":"critiquables","word_nosc":"critiquables","lemma":"critiquable","pos":"ADJ"} ,
		{"word":"critique","word_nosc":"critique","lemma":"critique","pos":"ADJ"} ,
		{"word":"critiques","word_nosc":"critiques","lemma":"critique","pos":"ADJ"} ,
		{"word":"croassant","word_nosc":"croassant","lemma":"croassant","pos":"ADJ"} ,
		{"word":"croate","word_nosc":"croate","lemma":"croate","pos":"ADJ"} ,
		{"word":"croates","word_nosc":"croates","lemma":"croate","pos":"ADJ"} ,
		{"word":"crochu","word_nosc":"crochu","lemma":"crochu","pos":"ADJ"} ,
		{"word":"crochue","word_nosc":"crochue","lemma":"crochu","pos":"ADJ"} ,
		{"word":"crochues","word_nosc":"crochues","lemma":"crochu","pos":"ADJ"} ,
		{"word":"crochus","word_nosc":"crochus","lemma":"crochu","pos":"ADJ"} ,
		{"word":"croissant","word_nosc":"croissant","lemma":"croissant","pos":"ADJ"} ,
		{"word":"croissante","word_nosc":"croissante","lemma":"croissant","pos":"ADJ"} ,
		{"word":"croissantes","word_nosc":"croissantes","lemma":"croissant","pos":"ADJ"} ,
		{"word":"croissants","word_nosc":"croissants","lemma":"croissant","pos":"ADJ"} ,
		{"word":"croisé","word_nosc":"croise","lemma":"croisé","pos":"ADJ"} ,
		{"word":"croisée","word_nosc":"croisee","lemma":"croisé","pos":"ADJ"} ,
		{"word":"croisées","word_nosc":"croisees","lemma":"croisé","pos":"ADJ"} ,
		{"word":"croisés","word_nosc":"croises","lemma":"croisé","pos":"ADJ"} ,
		{"word":"croquant","word_nosc":"croquant","lemma":"croquant","pos":"ADJ"} ,
		{"word":"croquante","word_nosc":"croquante","lemma":"croquant","pos":"ADJ"} ,
		{"word":"croquantes","word_nosc":"croquantes","lemma":"croquant","pos":"ADJ"} ,
		{"word":"croquants","word_nosc":"croquants","lemma":"croquant","pos":"ADJ"} ,
		{"word":"croqueur","word_nosc":"croqueur","lemma":"croqueur","pos":"ADJ"} ,
		{"word":"croqueuse","word_nosc":"croqueuse","lemma":"croqueur","pos":"ADJ"} ,
		{"word":"croquignol","word_nosc":"croquignol","lemma":"croquignol","pos":"ADJ"} ,
		{"word":"croquignolet","word_nosc":"croquignolet","lemma":"croquignolet","pos":"ADJ"} ,
		{"word":"croquignolets","word_nosc":"croquignolets","lemma":"croquignolet","pos":"ADJ"} ,
		{"word":"crossé","word_nosc":"crosse","lemma":"crossé","pos":"ADJ"} ,
		{"word":"crotté","word_nosc":"crotte","lemma":"crotté","pos":"ADJ"} ,
		{"word":"crottée","word_nosc":"crottee","lemma":"crotté","pos":"ADJ"} ,
		{"word":"crottées","word_nosc":"crottees","lemma":"crotté","pos":"ADJ"} ,
		{"word":"crottés","word_nosc":"crottes","lemma":"crotté","pos":"ADJ"} ,
		{"word":"croulant","word_nosc":"croulant","lemma":"croulant","pos":"ADJ"} ,
		{"word":"croulante","word_nosc":"croulante","lemma":"croulant","pos":"ADJ"} ,
		{"word":"croulantes","word_nosc":"croulantes","lemma":"croulant","pos":"ADJ"} ,
		{"word":"croulants","word_nosc":"croulants","lemma":"croulant","pos":"ADJ"} ,
		{"word":"croupi","word_nosc":"croupi","lemma":"croupi","pos":"ADJ"} ,
		{"word":"croupie","word_nosc":"croupie","lemma":"croupi","pos":"ADJ"} ,
		{"word":"croupies","word_nosc":"croupies","lemma":"croupi","pos":"ADJ"} ,
		{"word":"croupissant","word_nosc":"croupissant","lemma":"croupissant","pos":"ADJ"} ,
		{"word":"croupissante","word_nosc":"croupissante","lemma":"croupissant","pos":"ADJ"} ,
		{"word":"croupissantes","word_nosc":"croupissantes","lemma":"croupissant","pos":"ADJ"} ,
		{"word":"croustillant","word_nosc":"croustillant","lemma":"croustillant","pos":"ADJ"} ,
		{"word":"croustillante","word_nosc":"croustillante","lemma":"croustillant","pos":"ADJ"} ,
		{"word":"croustillantes","word_nosc":"croustillantes","lemma":"croustillant","pos":"ADJ"} ,
		{"word":"croustillants","word_nosc":"croustillants","lemma":"croustillant","pos":"ADJ"} ,
		{"word":"croyable","word_nosc":"croyable","lemma":"croyable","pos":"ADJ"} ,
		{"word":"croyables","word_nosc":"croyables","lemma":"croyable","pos":"ADJ"} ,
		{"word":"croyant","word_nosc":"croyant","lemma":"croyant","pos":"ADJ"} ,
		{"word":"croyante","word_nosc":"croyante","lemma":"croyant","pos":"ADJ"} ,
		{"word":"croyantes","word_nosc":"croyantes","lemma":"croyant","pos":"ADJ"} ,
		{"word":"croyants","word_nosc":"croyants","lemma":"croyant","pos":"ADJ"} ,
		{"word":"croûteuse","word_nosc":"crouteuse","lemma":"croûteux","pos":"ADJ"} ,
		{"word":"croûteuses","word_nosc":"crouteuses","lemma":"croûteux","pos":"ADJ"} ,
		{"word":"croûteux","word_nosc":"crouteux","lemma":"croûteux","pos":"ADJ"} ,
		{"word":"cru","word_nosc":"cru","lemma":"cru","pos":"ADJ"} ,
		{"word":"cruche","word_nosc":"cruche","lemma":"cruche","pos":"ADJ"} ,
		{"word":"cruches","word_nosc":"cruches","lemma":"cruche","pos":"ADJ"} ,
		{"word":"crucial","word_nosc":"crucial","lemma":"crucial","pos":"ADJ"} ,
		{"word":"cruciale","word_nosc":"cruciale","lemma":"crucial","pos":"ADJ"} ,
		{"word":"cruciales","word_nosc":"cruciales","lemma":"crucial","pos":"ADJ"} ,
		{"word":"cruciaux","word_nosc":"cruciaux","lemma":"crucial","pos":"ADJ"} ,
		{"word":"crucifiant","word_nosc":"crucifiant","lemma":"crucifiant","pos":"ADJ"} ,
		{"word":"crucifiante","word_nosc":"crucifiante","lemma":"crucifiant","pos":"ADJ"} ,
		{"word":"crucifié","word_nosc":"crucifie","lemma":"crucifié","pos":"ADJ"} ,
		{"word":"crucifiée","word_nosc":"crucifiee","lemma":"crucifié","pos":"ADJ"} ,
		{"word":"crucifiées","word_nosc":"crucifiees","lemma":"crucifié","pos":"ADJ"} ,
		{"word":"crucifiés","word_nosc":"crucifies","lemma":"crucifié","pos":"ADJ"} ,
		{"word":"cruciforme","word_nosc":"cruciforme","lemma":"cruciforme","pos":"ADJ"} ,
		{"word":"cruciformes","word_nosc":"cruciformes","lemma":"cruciforme","pos":"ADJ"} ,
		{"word":"crue","word_nosc":"crue","lemma":"cru","pos":"ADJ"} ,
		{"word":"cruel","word_nosc":"cruel","lemma":"cruel","pos":"ADJ"} ,
		{"word":"cruelle","word_nosc":"cruelle","lemma":"cruel","pos":"ADJ"} ,
		{"word":"cruelles","word_nosc":"cruelles","lemma":"cruel","pos":"ADJ"} ,
		{"word":"cruels","word_nosc":"cruels","lemma":"cruel","pos":"ADJ"} ,
		{"word":"crues","word_nosc":"crues","lemma":"cru","pos":"ADJ"} ,
		{"word":"crus","word_nosc":"crus","lemma":"cru","pos":"ADJ"} ,
		{"word":"crustacé","word_nosc":"crustace","lemma":"crustacé","pos":"ADJ"} ,
		{"word":"crustacés","word_nosc":"crustaces","lemma":"crustacé","pos":"ADJ"} ,
		{"word":"cryogène","word_nosc":"cryogene","lemma":"cryogène","pos":"ADJ"} ,
		{"word":"cryogénique","word_nosc":"cryogenique","lemma":"cryogénique","pos":"ADJ"} ,
		{"word":"cryogéniques","word_nosc":"cryogeniques","lemma":"cryogénique","pos":"ADJ"} ,
		{"word":"cryonique","word_nosc":"cryonique","lemma":"cryonique","pos":"ADJ"} ,
		{"word":"cryptique","word_nosc":"cryptique","lemma":"cryptique","pos":"ADJ"} ,
		{"word":"cryptiques","word_nosc":"cryptiques","lemma":"cryptique","pos":"ADJ"} ,
		{"word":"cryptogame","word_nosc":"cryptogame","lemma":"cryptogame","pos":"ADJ"} ,
		{"word":"cryptogamique","word_nosc":"cryptogamique","lemma":"cryptogamique","pos":"ADJ"} ,
		{"word":"cryptographique","word_nosc":"cryptographique","lemma":"cryptographique","pos":"ADJ"} ,
		{"word":"crâne","word_nosc":"crane","lemma":"crâne","pos":"ADJ"} ,
		{"word":"crânes","word_nosc":"cranes","lemma":"crâne","pos":"ADJ"} ,
		{"word":"crâneur","word_nosc":"craneur","lemma":"crâneur","pos":"ADJ"} ,
		{"word":"crâneurs","word_nosc":"craneurs","lemma":"crâneur","pos":"ADJ"} ,
		{"word":"crâneuse","word_nosc":"craneuse","lemma":"crâneur","pos":"ADJ"} ,
		{"word":"crânien","word_nosc":"cranien","lemma":"crânien","pos":"ADJ"} ,
		{"word":"crânienne","word_nosc":"cranienne","lemma":"crânien","pos":"ADJ"} ,
		{"word":"crâniens","word_nosc":"craniens","lemma":"crânien","pos":"ADJ"} ,
		{"word":"crème","word_nosc":"creme","lemma":"crème","pos":"ADJ"} ,
		{"word":"créanciers","word_nosc":"creanciers","lemma":"créancier","pos":"ADJ"} ,
		{"word":"créateur","word_nosc":"createur","lemma":"créateur","pos":"ADJ"} ,
		{"word":"créateurs","word_nosc":"createurs","lemma":"créateur","pos":"ADJ"} ,
		{"word":"créatif","word_nosc":"creatif","lemma":"créatif","pos":"ADJ"} ,
		{"word":"créatifs","word_nosc":"creatifs","lemma":"créatif","pos":"ADJ"} ,
		{"word":"créative","word_nosc":"creative","lemma":"créatif","pos":"ADJ"} ,
		{"word":"créatives","word_nosc":"creatives","lemma":"créatif","pos":"ADJ"} ,
		{"word":"créatrice","word_nosc":"creatrice","lemma":"créateur","pos":"ADJ"} ,
		{"word":"créatrices","word_nosc":"creatrices","lemma":"créateur","pos":"ADJ"} ,
		{"word":"crédible","word_nosc":"credible","lemma":"crédible","pos":"ADJ"} ,
		{"word":"crédibles","word_nosc":"credibles","lemma":"crédible","pos":"ADJ"} ,
		{"word":"créditeur","word_nosc":"crediteur","lemma":"créditeur","pos":"ADJ"} ,
		{"word":"créditeurs","word_nosc":"crediteurs","lemma":"créditeur","pos":"ADJ"} ,
		{"word":"créditrice","word_nosc":"creditrice","lemma":"créditeur","pos":"ADJ"} ,
		{"word":"crédule","word_nosc":"credule","lemma":"crédule","pos":"ADJ"} ,
		{"word":"crédules","word_nosc":"credules","lemma":"crédule","pos":"ADJ"} ,
		{"word":"crémant","word_nosc":"cremant","lemma":"crémant","pos":"ADJ"} ,
		{"word":"crématoire","word_nosc":"crematoire","lemma":"crématoire","pos":"ADJ"} ,
		{"word":"crématoires","word_nosc":"crematoires","lemma":"crématoire","pos":"ADJ"} ,
		{"word":"crémeuse","word_nosc":"cremeuse","lemma":"crémeux","pos":"ADJ"} ,
		{"word":"crémeuses","word_nosc":"cremeuses","lemma":"crémeux","pos":"ADJ"} ,
		{"word":"crémeux","word_nosc":"cremeux","lemma":"crémeux","pos":"ADJ"} ,
		{"word":"crénelé","word_nosc":"crenele","lemma":"crénelé","pos":"ADJ"} ,
		{"word":"crénelée","word_nosc":"crenelee","lemma":"crénelé","pos":"ADJ"} ,
		{"word":"crénelées","word_nosc":"crenelees","lemma":"crénelé","pos":"ADJ"} ,
		{"word":"crénelés","word_nosc":"creneles","lemma":"crénelé","pos":"ADJ"} ,
		{"word":"créole","word_nosc":"creole","lemma":"créole","pos":"ADJ"} ,
		{"word":"créoles","word_nosc":"creoles","lemma":"créole","pos":"ADJ"} ,
		{"word":"créolophone","word_nosc":"creolophone","lemma":"créolophone","pos":"ADJ"} ,
		{"word":"crépitant","word_nosc":"crepitant","lemma":"crépitant","pos":"ADJ"} ,
		{"word":"crépitante","word_nosc":"crepitante","lemma":"crépitant","pos":"ADJ"} ,
		{"word":"crépitantes","word_nosc":"crepitantes","lemma":"crépitant","pos":"ADJ"} ,
		{"word":"crépitants","word_nosc":"crepitants","lemma":"crépitant","pos":"ADJ"} ,
		{"word":"crépu","word_nosc":"crepu","lemma":"crépu","pos":"ADJ"} ,
		{"word":"crépue","word_nosc":"crepue","lemma":"crépu","pos":"ADJ"} ,
		{"word":"crépues","word_nosc":"crepues","lemma":"crépu","pos":"ADJ"} ,
		{"word":"crépus","word_nosc":"crepus","lemma":"crépu","pos":"ADJ"} ,
		{"word":"crépusculaire","word_nosc":"crepusculaire","lemma":"crépusculaire","pos":"ADJ"} ,
		{"word":"crépusculaires","word_nosc":"crepusculaires","lemma":"crépusculaire","pos":"ADJ"} ,
		{"word":"crésylée","word_nosc":"cresylee","lemma":"crésylé","pos":"ADJ"} ,
		{"word":"crétacée","word_nosc":"cretacee","lemma":"crétacé","pos":"ADJ"} ,
		{"word":"crétin","word_nosc":"cretin","lemma":"crétin","pos":"ADJ"} ,
		{"word":"crétine","word_nosc":"cretine","lemma":"crétin","pos":"ADJ"} ,
		{"word":"crétineux","word_nosc":"cretineux","lemma":"crétineux","pos":"ADJ"} ,
		{"word":"crétinisant","word_nosc":"cretinisant","lemma":"crétinisant","pos":"ADJ"} ,
		{"word":"crétins","word_nosc":"cretins","lemma":"crétin","pos":"ADJ"} ,
		{"word":"crétois","word_nosc":"cretois","lemma":"crétois","pos":"ADJ"} ,
		{"word":"crétoise","word_nosc":"cretoise","lemma":"crétois","pos":"ADJ"} ,
		{"word":"crétoises","word_nosc":"cretoises","lemma":"crétois","pos":"ADJ"} ,
		{"word":"crêpelées","word_nosc":"crepelees","lemma":"crêpelé","pos":"ADJ"} ,
		{"word":"crêpelés","word_nosc":"crepeles","lemma":"crêpelé","pos":"ADJ"} ,
		{"word":"crêpée","word_nosc":"crepee","lemma":"crêpé","pos":"ADJ"} ,
		{"word":"crêpés","word_nosc":"crepes","lemma":"crêpé","pos":"ADJ"} ,
		{"word":"crêtée","word_nosc":"cretee","lemma":"crêté","pos":"ADJ"} ,
		{"word":"crêtés","word_nosc":"cretes","lemma":"crêté","pos":"ADJ"} ,
		{"word":"cubain","word_nosc":"cubain","lemma":"cubain","pos":"ADJ"} ,
		{"word":"cubaine","word_nosc":"cubaine","lemma":"cubain","pos":"ADJ"} ,
		{"word":"cubaines","word_nosc":"cubaines","lemma":"cubain","pos":"ADJ"} ,
		{"word":"cubains","word_nosc":"cubains","lemma":"cubain","pos":"ADJ"} ,
		{"word":"cubique","word_nosc":"cubique","lemma":"cubique","pos":"ADJ"} ,
		{"word":"cubiques","word_nosc":"cubiques","lemma":"cubique","pos":"ADJ"} ,
		{"word":"cubiste","word_nosc":"cubiste","lemma":"cubiste","pos":"ADJ"} ,
		{"word":"cubistes","word_nosc":"cubistes","lemma":"cubiste","pos":"ADJ"} ,
		{"word":"cubital","word_nosc":"cubital","lemma":"cubital","pos":"ADJ"} ,
		{"word":"cubitale","word_nosc":"cubitale","lemma":"cubital","pos":"ADJ"} ,
		{"word":"cucu","word_nosc":"cucu","lemma":"cucu","pos":"ADJ"} ,
		{"word":"cucul","word_nosc":"cucul","lemma":"cucul","pos":"ADJ"} ,
		{"word":"cuirassé","word_nosc":"cuirasse","lemma":"cuirassé","pos":"ADJ"} ,
		{"word":"cuirassée","word_nosc":"cuirassee","lemma":"cuirassé","pos":"ADJ"} ,
		{"word":"cuirassées","word_nosc":"cuirassees","lemma":"cuirassé","pos":"ADJ"} ,
		{"word":"cuirassés","word_nosc":"cuirasses","lemma":"cuirassé","pos":"ADJ"} ,
		{"word":"cuisant","word_nosc":"cuisant","lemma":"cuisant","pos":"ADJ"} ,
		{"word":"cuisante","word_nosc":"cuisante","lemma":"cuisant","pos":"ADJ"} ,
		{"word":"cuisantes","word_nosc":"cuisantes","lemma":"cuisant","pos":"ADJ"} ,
		{"word":"cuisants","word_nosc":"cuisants","lemma":"cuisant","pos":"ADJ"} ,
		{"word":"cuisiné","word_nosc":"cuisine","lemma":"cuisiné","pos":"ADJ"} ,
		{"word":"cuisinée","word_nosc":"cuisinee","lemma":"cuisiné","pos":"ADJ"} ,
		{"word":"cuisinées","word_nosc":"cuisinees","lemma":"cuisiné","pos":"ADJ"} ,
		{"word":"cuisinés","word_nosc":"cuisines","lemma":"cuisiné","pos":"ADJ"} ,
		{"word":"cuit","word_nosc":"cuit","lemma":"cuit","pos":"ADJ"} ,
		{"word":"cuite","word_nosc":"cuite","lemma":"cuit","pos":"ADJ"} ,
		{"word":"cuites","word_nosc":"cuites","lemma":"cuit","pos":"ADJ"} ,
		{"word":"cuits","word_nosc":"cuits","lemma":"cuit","pos":"ADJ"} ,
		{"word":"cuivreux","word_nosc":"cuivreux","lemma":"cuivreux","pos":"ADJ"} ,
		{"word":"cuivré","word_nosc":"cuivre","lemma":"cuivré","pos":"ADJ"} ,
		{"word":"cuivrée","word_nosc":"cuivree","lemma":"cuivré","pos":"ADJ"} ,
		{"word":"cuivrées","word_nosc":"cuivrees","lemma":"cuivré","pos":"ADJ"} ,
		{"word":"cuivrés","word_nosc":"cuivres","lemma":"cuivré","pos":"ADJ"} ,
		{"word":"cul-cul","word_nosc":"cul-cul","lemma":"cul-cul","pos":"ADJ"} ,
		{"word":"culinaire","word_nosc":"culinaire","lemma":"culinaire","pos":"ADJ"} ,
		{"word":"culinaires","word_nosc":"culinaires","lemma":"culinaire","pos":"ADJ"} ,
		{"word":"culminant","word_nosc":"culminant","lemma":"culminant","pos":"ADJ"} ,
		{"word":"culminants","word_nosc":"culminants","lemma":"culminant","pos":"ADJ"} ,
		{"word":"culotté","word_nosc":"culotte","lemma":"culotté","pos":"ADJ"} ,
		{"word":"culottée","word_nosc":"culottee","lemma":"culotté","pos":"ADJ"} ,
		{"word":"culottés","word_nosc":"culottes","lemma":"culotté","pos":"ADJ"} ,
		{"word":"culpabilisant","word_nosc":"culpabilisant","lemma":"culpabilisant","pos":"ADJ"} ,
		{"word":"cultivable","word_nosc":"cultivable","lemma":"cultivable","pos":"ADJ"} ,
		{"word":"cultivables","word_nosc":"cultivables","lemma":"cultivable","pos":"ADJ"} ,
		{"word":"cultivé","word_nosc":"cultive","lemma":"cultivé","pos":"ADJ"} ,
		{"word":"cultivée","word_nosc":"cultivee","lemma":"cultivé","pos":"ADJ"} ,
		{"word":"cultivées","word_nosc":"cultivees","lemma":"cultivé","pos":"ADJ"} ,
		{"word":"cultivés","word_nosc":"cultives","lemma":"cultivé","pos":"ADJ"} ,
		{"word":"cultural","word_nosc":"cultural","lemma":"cultural","pos":"ADJ"} ,
		{"word":"culturel","word_nosc":"culturel","lemma":"culturel","pos":"ADJ"} ,
		{"word":"culturelle","word_nosc":"culturelle","lemma":"culturel","pos":"ADJ"} ,
		{"word":"culturelles","word_nosc":"culturelles","lemma":"culturel","pos":"ADJ"} ,
		{"word":"culturels","word_nosc":"culturels","lemma":"culturel","pos":"ADJ"} ,
		{"word":"culturiste","word_nosc":"culturiste","lemma":"culturiste","pos":"ADJ"} ,
		{"word":"culturistes","word_nosc":"culturistes","lemma":"culturiste","pos":"ADJ"} ,
		{"word":"cumulatif","word_nosc":"cumulatif","lemma":"cumulatif","pos":"ADJ"} ,
		{"word":"cumulatifs","word_nosc":"cumulatifs","lemma":"cumulatif","pos":"ADJ"} ,
		{"word":"cumulative","word_nosc":"cumulative","lemma":"cumulatif","pos":"ADJ"} ,
		{"word":"cunéiforme","word_nosc":"cuneiforme","lemma":"cunéiforme","pos":"ADJ"} ,
		{"word":"cunéiformes","word_nosc":"cuneiformes","lemma":"cunéiforme","pos":"ADJ"} ,
		{"word":"cupide","word_nosc":"cupide","lemma":"cupide","pos":"ADJ"} ,
		{"word":"cupides","word_nosc":"cupides","lemma":"cupide","pos":"ADJ"} ,
		{"word":"cuprifères","word_nosc":"cupriferes","lemma":"cuprifère","pos":"ADJ"} ,
		{"word":"cupriques","word_nosc":"cupriques","lemma":"cuprique","pos":"ADJ"} ,
		{"word":"curable","word_nosc":"curable","lemma":"curable","pos":"ADJ"} ,
		{"word":"curables","word_nosc":"curables","lemma":"curable","pos":"ADJ"} ,
		{"word":"curarisant","word_nosc":"curarisant","lemma":"curarisant","pos":"ADJ"} ,
		{"word":"curatif","word_nosc":"curatif","lemma":"curatif","pos":"ADJ"} ,
		{"word":"curatifs","word_nosc":"curatifs","lemma":"curatif","pos":"ADJ"} ,
		{"word":"curative","word_nosc":"curative","lemma":"curatif","pos":"ADJ"} ,
		{"word":"curatives","word_nosc":"curatives","lemma":"curatif","pos":"ADJ"} ,
		{"word":"curial","word_nosc":"curial","lemma":"curial","pos":"ADJ"} ,
		{"word":"curiales","word_nosc":"curiales","lemma":"curial","pos":"ADJ"} ,
		{"word":"curieuse","word_nosc":"curieuse","lemma":"curieux","pos":"ADJ"} ,
		{"word":"curieuses","word_nosc":"curieuses","lemma":"curieux","pos":"ADJ"} ,
		{"word":"curieux","word_nosc":"curieux","lemma":"curieux","pos":"ADJ"} ,
		{"word":"cursif","word_nosc":"cursif","lemma":"cursif","pos":"ADJ"} ,
		{"word":"cursive","word_nosc":"cursive","lemma":"cursif","pos":"ADJ"} ,
		{"word":"curve","word_nosc":"curve","lemma":"curve","pos":"ADJ"} ,
		{"word":"curviligne","word_nosc":"curviligne","lemma":"curviligne","pos":"ADJ"} ,
		{"word":"curvilignes","word_nosc":"curvilignes","lemma":"curviligne","pos":"ADJ"} ,
		{"word":"cutané","word_nosc":"cutane","lemma":"cutané","pos":"ADJ"} ,
		{"word":"cutanée","word_nosc":"cutanee","lemma":"cutané","pos":"ADJ"} ,
		{"word":"cutanées","word_nosc":"cutanees","lemma":"cutané","pos":"ADJ"} ,
		{"word":"cutanés","word_nosc":"cutanes","lemma":"cutané","pos":"ADJ"} ,
		{"word":"cyan","word_nosc":"cyan","lemma":"cyan","pos":"ADJ"} ,
		{"word":"cyanhydrique","word_nosc":"cyanhydrique","lemma":"cyanhydrique","pos":"ADJ"} ,
		{"word":"cybernéticien","word_nosc":"cyberneticien","lemma":"cybernéticien","pos":"ADJ"} ,
		{"word":"cybernétique","word_nosc":"cybernetique","lemma":"cybernétique","pos":"ADJ"} ,
		{"word":"cybernétiques","word_nosc":"cybernetiques","lemma":"cybernétique","pos":"ADJ"} ,
		{"word":"cyclable","word_nosc":"cyclable","lemma":"cyclable","pos":"ADJ"} ,
		{"word":"cyclables","word_nosc":"cyclables","lemma":"cyclable","pos":"ADJ"} ,
		{"word":"cyclique","word_nosc":"cyclique","lemma":"cyclique","pos":"ADJ"} ,
		{"word":"cycliques","word_nosc":"cycliques","lemma":"cyclique","pos":"ADJ"} ,
		{"word":"cycliste","word_nosc":"cycliste","lemma":"cycliste","pos":"ADJ"} ,
		{"word":"cyclistes","word_nosc":"cyclistes","lemma":"cycliste","pos":"ADJ"} ,
		{"word":"cyclonique","word_nosc":"cyclonique","lemma":"cyclonique","pos":"ADJ"} ,
		{"word":"cyclopéen","word_nosc":"cyclopeen","lemma":"cyclopéen","pos":"ADJ"} ,
		{"word":"cyclopéenne","word_nosc":"cyclopeenne","lemma":"cyclopéen","pos":"ADJ"} ,
		{"word":"cyclopéennes","word_nosc":"cyclopeennes","lemma":"cyclopéen","pos":"ADJ"} ,
		{"word":"cyclopéens","word_nosc":"cyclopeens","lemma":"cyclopéen","pos":"ADJ"} ,
		{"word":"cyclothymique","word_nosc":"cyclothymique","lemma":"cyclothymique","pos":"ADJ"} ,
		{"word":"cycloïdal","word_nosc":"cycloidal","lemma":"cycloïdal","pos":"ADJ"} ,
		{"word":"cylindrique","word_nosc":"cylindrique","lemma":"cylindrique","pos":"ADJ"} ,
		{"word":"cylindriques","word_nosc":"cylindriques","lemma":"cylindrique","pos":"ADJ"} ,
		{"word":"cynique","word_nosc":"cynique","lemma":"cynique","pos":"ADJ"} ,
		{"word":"cyniques","word_nosc":"cyniques","lemma":"cynique","pos":"ADJ"} ,
		{"word":"cynophile","word_nosc":"cynophile","lemma":"cynophile","pos":"ADJ"} ,
		{"word":"cynégétique","word_nosc":"cynegetique","lemma":"cynégétique","pos":"ADJ"} ,
		{"word":"cynégétiques","word_nosc":"cynegetiques","lemma":"cynégétique","pos":"ADJ"} ,
		{"word":"cypriote","word_nosc":"cypriote","lemma":"cypriote","pos":"ADJ"} ,
		{"word":"cypriotes","word_nosc":"cypriotes","lemma":"cypriote","pos":"ADJ"} ,
		{"word":"cyrillique","word_nosc":"cyrillique","lemma":"cyrillique","pos":"ADJ"} ,
		{"word":"cyrilliques","word_nosc":"cyrilliques","lemma":"cyrillique","pos":"ADJ"} ,
		{"word":"cystique","word_nosc":"cystique","lemma":"cystique","pos":"ADJ"} ,
		{"word":"cytologiques","word_nosc":"cytologiques","lemma":"cytologique","pos":"ADJ"} ,
		{"word":"cytoplasmique","word_nosc":"cytoplasmique","lemma":"cytoplasmique","pos":"ADJ"} ,
		{"word":"cytotoxique","word_nosc":"cytotoxique","lemma":"cytotoxique","pos":"ADJ"} ,
		{"word":"câblé","word_nosc":"cable","lemma":"câblé","pos":"ADJ"} ,
		{"word":"câblée","word_nosc":"cablee","lemma":"câblé","pos":"ADJ"} ,
		{"word":"câblées","word_nosc":"cablees","lemma":"câblé","pos":"ADJ"} ,
		{"word":"câlin","word_nosc":"calin","lemma":"câlin","pos":"ADJ"} ,
		{"word":"câline","word_nosc":"caline","lemma":"câlin","pos":"ADJ"} ,
		{"word":"câlines","word_nosc":"calines","lemma":"câlin","pos":"ADJ"} ,
		{"word":"câlins","word_nosc":"calins","lemma":"câlin","pos":"ADJ"} ,
		{"word":"cédulaire","word_nosc":"cedulaire","lemma":"cédulaire","pos":"ADJ"} ,
		{"word":"cégétistes","word_nosc":"cegetistes","lemma":"cégétiste","pos":"ADJ"} ,
		{"word":"céladon","word_nosc":"celadon","lemma":"céladon","pos":"ADJ"} ,
		{"word":"céleste","word_nosc":"celeste","lemma":"céleste","pos":"ADJ"} ,
		{"word":"célestes","word_nosc":"celestes","lemma":"céleste","pos":"ADJ"} ,
		{"word":"célibataire","word_nosc":"celibataire","lemma":"célibataire","pos":"ADJ"} ,
		{"word":"célibataires","word_nosc":"celibataires","lemma":"célibataire","pos":"ADJ"} ,
		{"word":"célinien","word_nosc":"celinien","lemma":"célinien","pos":"ADJ"} ,
		{"word":"célinienne","word_nosc":"celinienne","lemma":"célinien","pos":"ADJ"} ,
		{"word":"célèbre","word_nosc":"celebre","lemma":"célèbre","pos":"ADJ"} ,
		{"word":"célèbres","word_nosc":"celebres","lemma":"célèbre","pos":"ADJ"} ,
		{"word":"célébrant","word_nosc":"celebrant","lemma":"célébrant","pos":"ADJ"} ,
		{"word":"célébrissime","word_nosc":"celebrissime","lemma":"célébrissime","pos":"ADJ"} ,
		{"word":"céphalique","word_nosc":"cephalique","lemma":"céphalique","pos":"ADJ"} ,
		{"word":"céphalo-rachidien","word_nosc":"cephalo-rachidien","lemma":"céphalo-rachidien","pos":"ADJ"} ,
		{"word":"céphalorachidien","word_nosc":"cephalorachidien","lemma":"céphalorachidien","pos":"ADJ"} ,
		{"word":"céramique","word_nosc":"ceramique","lemma":"céramique","pos":"ADJ"} ,
		{"word":"céramiques","word_nosc":"ceramiques","lemma":"céramique","pos":"ADJ"} ,
		{"word":"céruléen","word_nosc":"ceruleen","lemma":"céruléen","pos":"ADJ"} ,
		{"word":"céruléens","word_nosc":"ceruleens","lemma":"céruléen","pos":"ADJ"} ,
		{"word":"cérusé","word_nosc":"ceruse","lemma":"cérusé","pos":"ADJ"} ,
		{"word":"céréalier","word_nosc":"cerealier","lemma":"céréalier","pos":"ADJ"} ,
		{"word":"céréaliers","word_nosc":"cerealiers","lemma":"céréalier","pos":"ADJ"} ,
		{"word":"céréalière","word_nosc":"cerealiere","lemma":"céréalier","pos":"ADJ"} ,
		{"word":"céréalières","word_nosc":"cerealieres","lemma":"céréalier","pos":"ADJ"} ,
		{"word":"cérébelleuse","word_nosc":"cerebelleuse","lemma":"cérébelleux","pos":"ADJ"} ,
		{"word":"cérébelleuses","word_nosc":"cerebelleuses","lemma":"cérébelleux","pos":"ADJ"} ,
		{"word":"cérébral","word_nosc":"cerebral","lemma":"cérébral","pos":"ADJ"} ,
		{"word":"cérébrale","word_nosc":"cerebrale","lemma":"cérébral","pos":"ADJ"} ,
		{"word":"cérébrales","word_nosc":"cerebrales","lemma":"cérébral","pos":"ADJ"} ,
		{"word":"cérébraux","word_nosc":"cerebraux","lemma":"cérébral","pos":"ADJ"} ,
		{"word":"cérébro-spinale","word_nosc":"cerebro-spinale","lemma":"cérébro-spinal","pos":"ADJ"} ,
		{"word":"cérémonial","word_nosc":"ceremonial","lemma":"cérémonial","pos":"ADJ"} ,
		{"word":"cérémoniale","word_nosc":"ceremoniale","lemma":"cérémonial","pos":"ADJ"} ,
		{"word":"cérémoniel","word_nosc":"ceremoniel","lemma":"cérémoniel","pos":"ADJ"} ,
		{"word":"cérémonielle","word_nosc":"ceremonielle","lemma":"cérémoniel","pos":"ADJ"} ,
		{"word":"cérémoniels","word_nosc":"ceremoniels","lemma":"cérémoniel","pos":"ADJ"} ,
		{"word":"cérémonieuse","word_nosc":"ceremonieuse","lemma":"cérémonieux","pos":"ADJ"} ,
		{"word":"cérémonieuses","word_nosc":"ceremonieuses","lemma":"cérémonieux","pos":"ADJ"} ,
		{"word":"cérémonieux","word_nosc":"ceremonieux","lemma":"cérémonieux","pos":"ADJ"} ,
		{"word":"césarien","word_nosc":"cesarien","lemma":"césarien","pos":"ADJ"} ,
		{"word":"césarienne","word_nosc":"cesarienne","lemma":"césarienne","pos":"ADJ"} ,
		{"word":"césariens","word_nosc":"cesariens","lemma":"césarien","pos":"ADJ"} ,
		{"word":"côtelé","word_nosc":"cotele","lemma":"côtelé","pos":"ADJ"} ,
		{"word":"côtelée","word_nosc":"cotelee","lemma":"côtelé","pos":"ADJ"} ,
		{"word":"côtier","word_nosc":"cotier","lemma":"côtier","pos":"ADJ"} ,
		{"word":"côtiers","word_nosc":"cotiers","lemma":"côtier","pos":"ADJ"} ,
		{"word":"côtière","word_nosc":"cotiere","lemma":"côtier","pos":"ADJ"} ,
		{"word":"côtières","word_nosc":"cotieres","lemma":"côtier","pos":"ADJ"} ,
		{"word":"d","word_nosc":"d","lemma":"d","pos":"ADJ:num"} ,
		{"word":"d'autres","word_nosc":"d'autres","lemma":"d'autres","pos":"ADJ:ind"} ,
		{"word":"da","word_nosc":"da","lemma":"da","pos":"ADJ"} ,
		{"word":"dace","word_nosc":"dace","lemma":"dace","pos":"ADJ"} ,
		{"word":"daces","word_nosc":"daces","lemma":"dace","pos":"ADJ"} ,
		{"word":"dacique","word_nosc":"dacique","lemma":"dacique","pos":"ADJ"} ,
		{"word":"dactylographié","word_nosc":"dactylographie","lemma":"dactylographié","pos":"ADJ"} ,
		{"word":"dactylographiée","word_nosc":"dactylographiee","lemma":"dactylographié","pos":"ADJ"} ,
		{"word":"dactylographiées","word_nosc":"dactylographiees","lemma":"dactylographié","pos":"ADJ"} ,
		{"word":"dactylographiés","word_nosc":"dactylographies","lemma":"dactylographié","pos":"ADJ"} ,
		{"word":"dadaïste","word_nosc":"dadaiste","lemma":"dadaïste","pos":"ADJ"} ,
		{"word":"dadaïstes","word_nosc":"dadaistes","lemma":"dadaïste","pos":"ADJ"} ,
		{"word":"dahoméenne","word_nosc":"dahomeenne","lemma":"dahoméen","pos":"ADJ"} ,
		{"word":"dakarois","word_nosc":"dakarois","lemma":"dakarois","pos":"ADJ"} ,
		{"word":"dakaroise","word_nosc":"dakaroise","lemma":"dakarois","pos":"ADJ"} ,
		{"word":"dakotas","word_nosc":"dakotas","lemma":"dakota","pos":"ADJ"} ,
		{"word":"dalinienne","word_nosc":"dalinienne","lemma":"dalinien","pos":"ADJ"} ,
		{"word":"dalmate","word_nosc":"dalmate","lemma":"dalmate","pos":"ADJ"} ,
		{"word":"dalmates","word_nosc":"dalmates","lemma":"dalmate","pos":"ADJ"} ,
		{"word":"daltonien","word_nosc":"daltonien","lemma":"daltonien","pos":"ADJ"} ,
		{"word":"daltonienne","word_nosc":"daltonienne","lemma":"daltonien","pos":"ADJ"} ,
		{"word":"daltoniennes","word_nosc":"daltoniennes","lemma":"daltonien","pos":"ADJ"} ,
		{"word":"daltoniens","word_nosc":"daltoniens","lemma":"daltonien","pos":"ADJ"} ,
		{"word":"damasquins","word_nosc":"damasquins","lemma":"damasquin","pos":"ADJ"} ,
		{"word":"damasquiné","word_nosc":"damasquine","lemma":"damasquiné","pos":"ADJ"} ,
		{"word":"damasquinée","word_nosc":"damasquinee","lemma":"damasquiné","pos":"ADJ"} ,
		{"word":"damasquinés","word_nosc":"damasquines","lemma":"damasquiné","pos":"ADJ"} ,
		{"word":"damassé","word_nosc":"damasse","lemma":"damassé","pos":"ADJ"} ,
		{"word":"damassée","word_nosc":"damassee","lemma":"damassé","pos":"ADJ"} ,
		{"word":"damassées","word_nosc":"damassees","lemma":"damassé","pos":"ADJ"} ,
		{"word":"damassés","word_nosc":"damasses","lemma":"damassé","pos":"ADJ"} ,
		{"word":"damnable","word_nosc":"damnable","lemma":"damnable","pos":"ADJ"} ,
		{"word":"damnables","word_nosc":"damnables","lemma":"damnable","pos":"ADJ"} ,
		{"word":"damné","word_nosc":"damne","lemma":"damné","pos":"ADJ"} ,
		{"word":"damnée","word_nosc":"damnee","lemma":"damné","pos":"ADJ"} ,
		{"word":"damnées","word_nosc":"damnees","lemma":"damné","pos":"ADJ"} ,
		{"word":"damnés","word_nosc":"damnes","lemma":"damné","pos":"ADJ"} ,
		{"word":"dangereuse","word_nosc":"dangereuse","lemma":"dangereux","pos":"ADJ"} ,
		{"word":"dangereuses","word_nosc":"dangereuses","lemma":"dangereux","pos":"ADJ"} ,
		{"word":"dangereux","word_nosc":"dangereux","lemma":"dangereux","pos":"ADJ"} ,
		{"word":"danois","word_nosc":"danois","lemma":"danois","pos":"ADJ"} ,
		{"word":"danoise","word_nosc":"danoise","lemma":"danois","pos":"ADJ"} ,
		{"word":"danoises","word_nosc":"danoises","lemma":"danois","pos":"ADJ"} ,
		{"word":"dansant","word_nosc":"dansant","lemma":"dansant","pos":"ADJ"} ,
		{"word":"dansante","word_nosc":"dansante","lemma":"dansant","pos":"ADJ"} ,
		{"word":"dansantes","word_nosc":"dansantes","lemma":"dansant","pos":"ADJ"} ,
		{"word":"dansants","word_nosc":"dansants","lemma":"dansant","pos":"ADJ"} ,
		{"word":"dantesque","word_nosc":"dantesque","lemma":"dantesque","pos":"ADJ"} ,
		{"word":"dantesques","word_nosc":"dantesques","lemma":"dantesque","pos":"ADJ"} ,
		{"word":"danubien","word_nosc":"danubien","lemma":"danubien","pos":"ADJ"} ,
		{"word":"danubienne","word_nosc":"danubienne","lemma":"danubien","pos":"ADJ"} ,
		{"word":"danubiennes","word_nosc":"danubiennes","lemma":"danubien","pos":"ADJ"} ,
		{"word":"dartreuses","word_nosc":"dartreuses","lemma":"dartreux","pos":"ADJ"} ,
		{"word":"datable","word_nosc":"datable","lemma":"datable","pos":"ADJ"} ,
		{"word":"dateur","word_nosc":"dateur","lemma":"dateur","pos":"ADJ"} ,
		{"word":"dauphinois","word_nosc":"dauphinois","lemma":"dauphinois","pos":"ADJ"} ,
		{"word":"dc","word_nosc":"dc","lemma":"dc","pos":"ADJ:num"} ,
		{"word":"deltoïde","word_nosc":"deltoide","lemma":"deltoïde","pos":"ADJ"} ,
		{"word":"deltoïdes","word_nosc":"deltoides","lemma":"deltoïde","pos":"ADJ"} ,
		{"word":"demain","word_nosc":"demain","lemma":"demain","pos":"ADJ"} ,
		{"word":"demeuré","word_nosc":"demeure","lemma":"demeuré","pos":"ADJ"} ,
		{"word":"demeurée","word_nosc":"demeuree","lemma":"demeuré","pos":"ADJ"} ,
		{"word":"demeurées","word_nosc":"demeurees","lemma":"demeuré","pos":"ADJ"} ,
		{"word":"demeurés","word_nosc":"demeures","lemma":"demeuré","pos":"ADJ"} ,
		{"word":"demi","word_nosc":"demi","lemma":"demi","pos":"ADJ"} ,
		{"word":"demi-dévêtu","word_nosc":"demi-devetu","lemma":"demi-dévêtu","pos":"ADJ"} ,
		{"word":"demi-dévêtue","word_nosc":"demi-devetue","lemma":"demi-dévêtu","pos":"ADJ"} ,
		{"word":"demi-juif","word_nosc":"demi-juif","lemma":"demi-juif","pos":"ADJ"} ,
		{"word":"demi-mort","word_nosc":"demi-mort","lemma":"demi-mort","pos":"ADJ"} ,
		{"word":"demi-morte","word_nosc":"demi-morte","lemma":"demi-mort","pos":"ADJ"} ,
		{"word":"demi-nu","word_nosc":"demi-nu","lemma":"demi-nu","pos":"ADJ"} ,
		{"word":"demi-ouverte","word_nosc":"demi-ouverte","lemma":"demi-ouvert","pos":"ADJ"} ,
		{"word":"demi-paralysé","word_nosc":"demi-paralyse","lemma":"demi-paralysé","pos":"ADJ"} ,
		{"word":"demi-pas","word_nosc":"demi-pas","lemma":"demi-pas","pos":"ADJ"} ,
		{"word":"demi-personne","word_nosc":"demi-personne","lemma":"demi-personne","pos":"ADJ"} ,
		{"word":"demi-plein","word_nosc":"demi-plein","lemma":"demi-plein","pos":"ADJ"} ,
		{"word":"demi-pouce","word_nosc":"demi-pouce","lemma":"demi-pouce","pos":"ADJ"} ,
		{"word":"demi-queue","word_nosc":"demi-queue","lemma":"demi-queue","pos":"ADJ"} ,
		{"word":"demie","word_nosc":"demie","lemma":"demi","pos":"ADJ"} ,
		{"word":"demies","word_nosc":"demies","lemma":"demi","pos":"ADJ"} ,
		{"word":"demis","word_nosc":"demis","lemma":"demi","pos":"ADJ"} ,
		{"word":"dense","word_nosc":"dense","lemma":"dense","pos":"ADJ"} ,
		{"word":"denses","word_nosc":"denses","lemma":"dense","pos":"ADJ"} ,
		{"word":"dentaire","word_nosc":"dentaire","lemma":"dentaire","pos":"ADJ"} ,
		{"word":"dentaires","word_nosc":"dentaires","lemma":"dentaire","pos":"ADJ"} ,
		{"word":"dental","word_nosc":"dental","lemma":"dental","pos":"ADJ"} ,
		{"word":"dentale","word_nosc":"dentale","lemma":"dental","pos":"ADJ"} ,
		{"word":"dentales","word_nosc":"dentales","lemma":"dental","pos":"ADJ"} ,
		{"word":"dentellière","word_nosc":"dentelliere","lemma":"dentellier","pos":"ADJ"} ,
		{"word":"dentelé","word_nosc":"dentele","lemma":"dentelé","pos":"ADJ"} ,
		{"word":"dentelée","word_nosc":"dentelee","lemma":"dentelé","pos":"ADJ"} ,
		{"word":"dentelées","word_nosc":"dentelees","lemma":"dentelé","pos":"ADJ"} ,
		{"word":"dentelés","word_nosc":"denteles","lemma":"dentelé","pos":"ADJ"} ,
		{"word":"dentifrice","word_nosc":"dentifrice","lemma":"dentifrice","pos":"ADJ"} ,
		{"word":"denté","word_nosc":"dente","lemma":"denté","pos":"ADJ"} ,
		{"word":"dentée","word_nosc":"dentee","lemma":"denté","pos":"ADJ"} ,
		{"word":"dentées","word_nosc":"dentees","lemma":"denté","pos":"ADJ"} ,
		{"word":"dentés","word_nosc":"dentes","lemma":"denté","pos":"ADJ"} ,
		{"word":"dermatologique","word_nosc":"dermatologique","lemma":"dermatologique","pos":"ADJ"} ,
		{"word":"dermatologiques","word_nosc":"dermatologiques","lemma":"dermatologique","pos":"ADJ"} ,
		{"word":"dermique","word_nosc":"dermique","lemma":"dermique","pos":"ADJ"} ,
		{"word":"dermiques","word_nosc":"dermiques","lemma":"dermique","pos":"ADJ"} ,
		{"word":"dernier","word_nosc":"dernier","lemma":"dernier","pos":"ADJ"} ,
		{"word":"derniers","word_nosc":"derniers","lemma":"dernier","pos":"ADJ"} ,
		{"word":"dernière","word_nosc":"derniere","lemma":"dernier","pos":"ADJ"} ,
		{"word":"dernières","word_nosc":"dernieres","lemma":"dernier","pos":"ADJ"} ,
		{"word":"descendant","word_nosc":"descendant","lemma":"descendant","pos":"ADJ"} ,
		{"word":"descendante","word_nosc":"descendante","lemma":"descendant","pos":"ADJ"} ,
		{"word":"descendantes","word_nosc":"descendantes","lemma":"descendant","pos":"ADJ"} ,
		{"word":"descendants","word_nosc":"descendants","lemma":"descendant","pos":"ADJ"} ,
		{"word":"descriptible","word_nosc":"descriptible","lemma":"descriptible","pos":"ADJ"} ,
		{"word":"descriptibles","word_nosc":"descriptibles","lemma":"descriptible","pos":"ADJ"} ,
		{"word":"descriptif","word_nosc":"descriptif","lemma":"descriptif","pos":"ADJ"} ,
		{"word":"descriptifs","word_nosc":"descriptifs","lemma":"descriptif","pos":"ADJ"} ,
		{"word":"descriptive","word_nosc":"descriptive","lemma":"descriptif","pos":"ADJ"} ,
		{"word":"descriptives","word_nosc":"descriptives","lemma":"descriptif","pos":"ADJ"} ,
		{"word":"despote","word_nosc":"despote","lemma":"despote","pos":"ADJ"} ,
		{"word":"despotique","word_nosc":"despotique","lemma":"despotique","pos":"ADJ"} ,
		{"word":"despotiques","word_nosc":"despotiques","lemma":"despotique","pos":"ADJ"} ,
		{"word":"desquamante","word_nosc":"desquamante","lemma":"desquamant","pos":"ADJ"} ,
		{"word":"dessalé","word_nosc":"dessale","lemma":"dessalé","pos":"ADJ"} ,
		{"word":"dessalée","word_nosc":"dessalee","lemma":"dessalé","pos":"ADJ"} ,
		{"word":"dessalées","word_nosc":"dessalees","lemma":"dessalé","pos":"ADJ"} ,
		{"word":"dessalés","word_nosc":"dessales","lemma":"dessalé","pos":"ADJ"} ,
		{"word":"desservant","word_nosc":"desservant","lemma":"desservant","pos":"ADJ"} ,
		{"word":"desséchant","word_nosc":"dessechant","lemma":"desséchant","pos":"ADJ"} ,
		{"word":"desséchante","word_nosc":"dessechante","lemma":"desséchant","pos":"ADJ"} ,
		{"word":"destiné","word_nosc":"destine","lemma":"destiné","pos":"ADJ"} ,
		{"word":"destinée","word_nosc":"destinee","lemma":"destiné","pos":"ADJ"} ,
		{"word":"destinées","word_nosc":"destinees","lemma":"destiné","pos":"ADJ"} ,
		{"word":"destinés","word_nosc":"destines","lemma":"destiné","pos":"ADJ"} ,
		{"word":"destroy","word_nosc":"destroy","lemma":"destroy","pos":"ADJ"} ,
		{"word":"destructeur","word_nosc":"destructeur","lemma":"destructeur","pos":"ADJ"} ,
		{"word":"destructeurs","word_nosc":"destructeurs","lemma":"destructeur","pos":"ADJ"} ,
		{"word":"destructible","word_nosc":"destructible","lemma":"destructible","pos":"ADJ"} ,
		{"word":"destructif","word_nosc":"destructif","lemma":"destructif","pos":"ADJ"} ,
		{"word":"destructifs","word_nosc":"destructifs","lemma":"destructif","pos":"ADJ"} ,
		{"word":"destructive","word_nosc":"destructive","lemma":"destructif","pos":"ADJ"} ,
		{"word":"destructrice","word_nosc":"destructrice","lemma":"destructeur","pos":"ADJ"} ,
		{"word":"destructrices","word_nosc":"destructrices","lemma":"destructeur","pos":"ADJ"} ,
		{"word":"deutsche mark","word_nosc":"deutsche mark","lemma":"deutsche mark","pos":"ADJ"} ,
		{"word":"deux","word_nosc":"deux","lemma":"deux","pos":"ADJ:num"} ,
		{"word":"deuxième","word_nosc":"deuxieme","lemma":"deuxième","pos":"ADJ"} ,
		{"word":"deuxièmes","word_nosc":"deuxiemes","lemma":"deuxième","pos":"ADJ"} ,
		{"word":"dextre","word_nosc":"dextre","lemma":"dextre","pos":"ADJ"} ,
		{"word":"diable","word_nosc":"diable","lemma":"diable","pos":"ADJ"} ,
		{"word":"diabolique","word_nosc":"diabolique","lemma":"diabolique","pos":"ADJ"} ,
		{"word":"diaboliques","word_nosc":"diaboliques","lemma":"diabolique","pos":"ADJ"} ,
		{"word":"diabétique","word_nosc":"diabetique","lemma":"diabétique","pos":"ADJ"} ,
		{"word":"diabétiques","word_nosc":"diabetiques","lemma":"diabétique","pos":"ADJ"} ,
		{"word":"diachronique","word_nosc":"diachronique","lemma":"diachronique","pos":"ADJ"} ,
		{"word":"diacritique","word_nosc":"diacritique","lemma":"diacritique","pos":"ADJ"} ,
		{"word":"diagnostique","word_nosc":"diagnostique","lemma":"diagnostique","pos":"ADJ"} ,
		{"word":"diagnostiques","word_nosc":"diagnostiques","lemma":"diagnostique","pos":"ADJ"} ,
		{"word":"diagonal","word_nosc":"diagonal","lemma":"diagonal","pos":"ADJ"} ,
		{"word":"diagonale","word_nosc":"diagonale","lemma":"diagonal","pos":"ADJ"} ,
		{"word":"diagonales","word_nosc":"diagonales","lemma":"diagonal","pos":"ADJ"} ,
		{"word":"diagonaux","word_nosc":"diagonaux","lemma":"diagonal","pos":"ADJ"} ,
		{"word":"dialectal","word_nosc":"dialectal","lemma":"dialectal","pos":"ADJ"} ,
		{"word":"dialectale","word_nosc":"dialectale","lemma":"dialectal","pos":"ADJ"} ,
		{"word":"dialectique","word_nosc":"dialectique","lemma":"dialectique","pos":"ADJ"} ,
		{"word":"dialectiques","word_nosc":"dialectiques","lemma":"dialectique","pos":"ADJ"} ,
		{"word":"dialogique","word_nosc":"dialogique","lemma":"dialogique","pos":"ADJ"} ,
		{"word":"diamantifère","word_nosc":"diamantifere","lemma":"diamantifère","pos":"ADJ"} ,
		{"word":"diamantin","word_nosc":"diamantin","lemma":"diamantin","pos":"ADJ"} ,
		{"word":"diamantine","word_nosc":"diamantine","lemma":"diamantin","pos":"ADJ"} ,
		{"word":"diamantines","word_nosc":"diamantines","lemma":"diamantin","pos":"ADJ"} ,
		{"word":"diamantins","word_nosc":"diamantins","lemma":"diamantin","pos":"ADJ"} ,
		{"word":"diamanté","word_nosc":"diamante","lemma":"diamanté","pos":"ADJ"} ,
		{"word":"diamantés","word_nosc":"diamantes","lemma":"diamanté","pos":"ADJ"} ,
		{"word":"diaphane","word_nosc":"diaphane","lemma":"diaphane","pos":"ADJ"} ,
		{"word":"diaphanes","word_nosc":"diaphanes","lemma":"diaphane","pos":"ADJ"} ,
		{"word":"diaphorétique","word_nosc":"diaphoretique","lemma":"diaphorétique","pos":"ADJ"} ,
		{"word":"diaphragmatique","word_nosc":"diaphragmatique","lemma":"diaphragmatique","pos":"ADJ"} ,
		{"word":"diapositifs","word_nosc":"diapositifs","lemma":"diapositif","pos":"ADJ"} ,
		{"word":"diapositive","word_nosc":"diapositive","lemma":"diapositif","pos":"ADJ"} ,
		{"word":"diapositives","word_nosc":"diapositives","lemma":"diapositif","pos":"ADJ"} ,
		{"word":"diapré","word_nosc":"diapre","lemma":"diapré","pos":"ADJ"} ,
		{"word":"diaprée","word_nosc":"diapree","lemma":"diapré","pos":"ADJ"} ,
		{"word":"diaprés","word_nosc":"diapres","lemma":"diapré","pos":"ADJ"} ,
		{"word":"diarrhéique","word_nosc":"diarrheique","lemma":"diarrhéique","pos":"ADJ"} ,
		{"word":"diastolique","word_nosc":"diastolique","lemma":"diastolique","pos":"ADJ"} ,
		{"word":"diastoliques","word_nosc":"diastoliques","lemma":"diastolique","pos":"ADJ"} ,
		{"word":"diatonique","word_nosc":"diatonique","lemma":"diatonique","pos":"ADJ"} ,
		{"word":"dichroïque","word_nosc":"dichroique","lemma":"dichroïque","pos":"ADJ"} ,
		{"word":"dicible","word_nosc":"dicible","lemma":"dicible","pos":"ADJ"} ,
		{"word":"dictatorial","word_nosc":"dictatorial","lemma":"dictatorial","pos":"ADJ"} ,
		{"word":"dictatoriale","word_nosc":"dictatoriale","lemma":"dictatorial","pos":"ADJ"} ,
		{"word":"dictatoriales","word_nosc":"dictatoriales","lemma":"dictatorial","pos":"ADJ"} ,
		{"word":"dictatoriaux","word_nosc":"dictatoriaux","lemma":"dictatorial","pos":"ADJ"} ,
		{"word":"didactique","word_nosc":"didactique","lemma":"didactique","pos":"ADJ"} ,
		{"word":"didactiques","word_nosc":"didactiques","lemma":"didactique","pos":"ADJ"} ,
		{"word":"dieppoise","word_nosc":"dieppoise","lemma":"dieppois","pos":"ADJ"} ,
		{"word":"dieppoises","word_nosc":"dieppoises","lemma":"dieppois","pos":"ADJ"} ,
		{"word":"diffamatoire","word_nosc":"diffamatoire","lemma":"diffamatoire","pos":"ADJ"} ,
		{"word":"diffamatoires","word_nosc":"diffamatoires","lemma":"diffamatoire","pos":"ADJ"} ,
		{"word":"diffamé","word_nosc":"diffame","lemma":"diffamé","pos":"ADJ"} ,
		{"word":"diffamée","word_nosc":"diffamee","lemma":"diffamé","pos":"ADJ"} ,
		{"word":"diffamés","word_nosc":"diffames","lemma":"diffamé","pos":"ADJ"} ,
		{"word":"difficile","word_nosc":"difficile","lemma":"difficile","pos":"ADJ"} ,
		{"word":"difficiles","word_nosc":"difficiles","lemma":"difficile","pos":"ADJ"} ,
		{"word":"difficultueux","word_nosc":"difficultueux","lemma":"difficultueux","pos":"ADJ"} ,
		{"word":"difforme","word_nosc":"difforme","lemma":"difforme","pos":"ADJ"} ,
		{"word":"difformes","word_nosc":"difformes","lemma":"difforme","pos":"ADJ"} ,
		{"word":"diffus","word_nosc":"diffus","lemma":"diffus","pos":"ADJ"} ,
		{"word":"diffusant","word_nosc":"diffusant","lemma":"diffusant","pos":"ADJ"} ,
		{"word":"diffuse","word_nosc":"diffuse","lemma":"diffus","pos":"ADJ"} ,
		{"word":"diffuses","word_nosc":"diffuses","lemma":"diffus","pos":"ADJ"} ,
		{"word":"différenciables","word_nosc":"differenciables","lemma":"différenciable","pos":"ADJ"} ,
		{"word":"différencié","word_nosc":"differencie","lemma":"différencié","pos":"ADJ"} ,
		{"word":"différenciée","word_nosc":"differenciee","lemma":"différencié","pos":"ADJ"} ,
		{"word":"différenciées","word_nosc":"differenciees","lemma":"différencié","pos":"ADJ"} ,
		{"word":"différent","word_nosc":"different","lemma":"différent","pos":"ADJ"} ,
		{"word":"différente","word_nosc":"differente","lemma":"différent","pos":"ADJ"} ,
		{"word":"différentes","word_nosc":"differentes","lemma":"différent","pos":"ADJ"} ,
		{"word":"différentiel","word_nosc":"differentiel","lemma":"différentiel","pos":"ADJ"} ,
		{"word":"différentielle","word_nosc":"differentielle","lemma":"différentiel","pos":"ADJ"} ,
		{"word":"différentielles","word_nosc":"differentielles","lemma":"différentiel","pos":"ADJ"} ,
		{"word":"différents","word_nosc":"differents","lemma":"différent","pos":"ADJ"} ,
		{"word":"différents","word_nosc":"differents","lemma":"différents","pos":"ADJ:ind"} ,
		{"word":"différé","word_nosc":"differe","lemma":"différé","pos":"ADJ"} ,
		{"word":"différée","word_nosc":"differee","lemma":"différé","pos":"ADJ"} ,
		{"word":"différées","word_nosc":"differees","lemma":"différé","pos":"ADJ"} ,
		{"word":"différés","word_nosc":"differes","lemma":"différé","pos":"ADJ"} ,
		{"word":"digeste","word_nosc":"digeste","lemma":"digeste","pos":"ADJ"} ,
		{"word":"digestibles","word_nosc":"digestibles","lemma":"digestible","pos":"ADJ"} ,
		{"word":"digestif","word_nosc":"digestif","lemma":"digestif","pos":"ADJ"} ,
		{"word":"digestifs","word_nosc":"digestifs","lemma":"digestif","pos":"ADJ"} ,
		{"word":"digestive","word_nosc":"digestive","lemma":"digestif","pos":"ADJ"} ,
		{"word":"digestives","word_nosc":"digestives","lemma":"digestif","pos":"ADJ"} ,
		{"word":"digital","word_nosc":"digital","lemma":"digital","pos":"ADJ"} ,
		{"word":"digitale","word_nosc":"digitale","lemma":"digital","pos":"ADJ"} ,
		{"word":"digitales","word_nosc":"digitales","lemma":"digital","pos":"ADJ"} ,
		{"word":"digitaux","word_nosc":"digitaux","lemma":"digital","pos":"ADJ"} ,
		{"word":"digitée","word_nosc":"digitee","lemma":"digité","pos":"ADJ"} ,
		{"word":"digne","word_nosc":"digne","lemma":"digne","pos":"ADJ"} ,
		{"word":"dignes","word_nosc":"dignes","lemma":"digne","pos":"ADJ"} ,
		{"word":"dijonnais","word_nosc":"dijonnais","lemma":"dijonnais","pos":"ADJ"} ,
		{"word":"dilatable","word_nosc":"dilatable","lemma":"dilatable","pos":"ADJ"} ,
		{"word":"dilatateur","word_nosc":"dilatateur","lemma":"dilatateur","pos":"ADJ"} ,
		{"word":"dilatateurs","word_nosc":"dilatateurs","lemma":"dilatateur","pos":"ADJ"} ,
		{"word":"dilatoire","word_nosc":"dilatoire","lemma":"dilatoire","pos":"ADJ"} ,
		{"word":"dilatoires","word_nosc":"dilatoires","lemma":"dilatoire","pos":"ADJ"} ,
		{"word":"dilaté","word_nosc":"dilate","lemma":"dilaté","pos":"ADJ"} ,
		{"word":"dilatée","word_nosc":"dilatee","lemma":"dilaté","pos":"ADJ"} ,
		{"word":"dilatées","word_nosc":"dilatees","lemma":"dilaté","pos":"ADJ"} ,
		{"word":"dilatés","word_nosc":"dilates","lemma":"dilaté","pos":"ADJ"} ,
		{"word":"dilettante","word_nosc":"dilettante","lemma":"dilettante","pos":"ADJ"} ,
		{"word":"dilettantes","word_nosc":"dilettantes","lemma":"dilettante","pos":"ADJ"} ,
		{"word":"diligent","word_nosc":"diligent","lemma":"diligent","pos":"ADJ"} ,
		{"word":"diligente","word_nosc":"diligente","lemma":"diligent","pos":"ADJ"} ,
		{"word":"diligentes","word_nosc":"diligentes","lemma":"diligent","pos":"ADJ"} ,
		{"word":"diligents","word_nosc":"diligents","lemma":"diligent","pos":"ADJ"} ,
		{"word":"diluant","word_nosc":"diluant","lemma":"diluant","pos":"ADJ"} ,
		{"word":"diluvien","word_nosc":"diluvien","lemma":"diluvien","pos":"ADJ"} ,
		{"word":"diluvienne","word_nosc":"diluvienne","lemma":"diluvien","pos":"ADJ"} ,
		{"word":"diluviennes","word_nosc":"diluviennes","lemma":"diluvien","pos":"ADJ"} ,
		{"word":"dimensionnel","word_nosc":"dimensionnel","lemma":"dimensionnel","pos":"ADJ"} ,
		{"word":"dimensionnelle","word_nosc":"dimensionnelle","lemma":"dimensionnel","pos":"ADJ"} ,
		{"word":"dimensionnelles","word_nosc":"dimensionnelles","lemma":"dimensionnel","pos":"ADJ"} ,
		{"word":"dimensionnels","word_nosc":"dimensionnels","lemma":"dimensionnel","pos":"ADJ"} ,
		{"word":"diminuant","word_nosc":"diminuant","lemma":"diminuant","pos":"ADJ"} ,
		{"word":"diminutif","word_nosc":"diminutif","lemma":"diminutif","pos":"ADJ"} ,
		{"word":"diminué","word_nosc":"diminue","lemma":"diminué","pos":"ADJ"} ,
		{"word":"diminuée","word_nosc":"diminuee","lemma":"diminué","pos":"ADJ"} ,
		{"word":"diminuées","word_nosc":"diminuees","lemma":"diminué","pos":"ADJ"} ,
		{"word":"diminués","word_nosc":"diminues","lemma":"diminué","pos":"ADJ"} ,
		{"word":"dingo","word_nosc":"dingo","lemma":"dingo","pos":"ADJ"} ,
		{"word":"dingos","word_nosc":"dingos","lemma":"dingo","pos":"ADJ"} ,
		{"word":"dingue","word_nosc":"dingue","lemma":"dingue","pos":"ADJ"} ,
		{"word":"dingues","word_nosc":"dingues","lemma":"dingue","pos":"ADJ"} ,
		{"word":"dioclétienne","word_nosc":"diocletienne","lemma":"dioclétien","pos":"ADJ"} ,
		{"word":"dioclétiennes","word_nosc":"diocletiennes","lemma":"dioclétien","pos":"ADJ"} ,
		{"word":"diocésain","word_nosc":"diocesain","lemma":"diocésain","pos":"ADJ"} ,
		{"word":"diocésaines","word_nosc":"diocesaines","lemma":"diocésain","pos":"ADJ"} ,
		{"word":"dionysiaque","word_nosc":"dionysiaque","lemma":"dionysiaque","pos":"ADJ"} ,
		{"word":"dionysiaques","word_nosc":"dionysiaques","lemma":"dionysiaque","pos":"ADJ"} ,
		{"word":"dionysien","word_nosc":"dionysien","lemma":"dionysien","pos":"ADJ"} ,
		{"word":"diplomate","word_nosc":"diplomate","lemma":"diplomate","pos":"ADJ"} ,
		{"word":"diplomates","word_nosc":"diplomates","lemma":"diplomate","pos":"ADJ"} ,
		{"word":"diplomatique","word_nosc":"diplomatique","lemma":"diplomatique","pos":"ADJ"} ,
		{"word":"diplomatiques","word_nosc":"diplomatiques","lemma":"diplomatique","pos":"ADJ"} ,
		{"word":"diplômé","word_nosc":"diplome","lemma":"diplômé","pos":"ADJ"} ,
		{"word":"diplômée","word_nosc":"diplomee","lemma":"diplômé","pos":"ADJ"} ,
		{"word":"diplômées","word_nosc":"diplomees","lemma":"diplômé","pos":"ADJ"} ,
		{"word":"diplômés","word_nosc":"diplomes","lemma":"diplômé","pos":"ADJ"} ,
		{"word":"dipsomane","word_nosc":"dipsomane","lemma":"dipsomane","pos":"ADJ"} ,
		{"word":"diptères","word_nosc":"dipteres","lemma":"diptère","pos":"ADJ"} ,
		{"word":"direct","word_nosc":"direct","lemma":"direct","pos":"ADJ"} ,
		{"word":"directe","word_nosc":"directe","lemma":"direct","pos":"ADJ"} ,
		{"word":"directes","word_nosc":"directes","lemma":"direct","pos":"ADJ"} ,
		{"word":"directeur","word_nosc":"directeur","lemma":"directeur","pos":"ADJ"} ,
		{"word":"directeur-adjoint","word_nosc":"directeur-adjoint","lemma":"directeur-adjoint","pos":"ADJ"} ,
		{"word":"directeurs","word_nosc":"directeurs","lemma":"directeur","pos":"ADJ"} ,
		{"word":"directif","word_nosc":"directif","lemma":"directif","pos":"ADJ"} ,
		{"word":"directionnel","word_nosc":"directionnel","lemma":"directionnel","pos":"ADJ"} ,
		{"word":"directionnelle","word_nosc":"directionnelle","lemma":"directionnel","pos":"ADJ"} ,
		{"word":"directionnelles","word_nosc":"directionnelles","lemma":"directionnel","pos":"ADJ"} ,
		{"word":"directionnels","word_nosc":"directionnels","lemma":"directionnel","pos":"ADJ"} ,
		{"word":"directive","word_nosc":"directive","lemma":"directif","pos":"ADJ"} ,
		{"word":"directives","word_nosc":"directives","lemma":"directif","pos":"ADJ"} ,
		{"word":"directorial","word_nosc":"directorial","lemma":"directorial","pos":"ADJ"} ,
		{"word":"directoriale","word_nosc":"directoriale","lemma":"directorial","pos":"ADJ"} ,
		{"word":"directoriaux","word_nosc":"directoriaux","lemma":"directorial","pos":"ADJ"} ,
		{"word":"directrice","word_nosc":"directrice","lemma":"directeur","pos":"ADJ"} ,
		{"word":"directrices","word_nosc":"directrices","lemma":"directeur","pos":"ADJ"} ,
		{"word":"directs","word_nosc":"directs","lemma":"direct","pos":"ADJ"} ,
		{"word":"dirigeable","word_nosc":"dirigeable","lemma":"dirigeable","pos":"ADJ"} ,
		{"word":"dirigeables","word_nosc":"dirigeables","lemma":"dirigeable","pos":"ADJ"} ,
		{"word":"dirigeant","word_nosc":"dirigeant","lemma":"dirigeant","pos":"ADJ"} ,
		{"word":"dirigeante","word_nosc":"dirigeante","lemma":"dirigeant","pos":"ADJ"} ,
		{"word":"dirigeantes","word_nosc":"dirigeantes","lemma":"dirigeant","pos":"ADJ"} ,
		{"word":"dirigeants","word_nosc":"dirigeants","lemma":"dirigeant","pos":"ADJ"} ,
		{"word":"dirigé","word_nosc":"dirige","lemma":"dirigé","pos":"ADJ"} ,
		{"word":"dirigée","word_nosc":"dirigee","lemma":"dirigé","pos":"ADJ"} ,
		{"word":"dirigées","word_nosc":"dirigees","lemma":"dirigé","pos":"ADJ"} ,
		{"word":"dirigés","word_nosc":"diriges","lemma":"dirigé","pos":"ADJ"} ,
		{"word":"dirimant","word_nosc":"dirimant","lemma":"dirimant","pos":"ADJ"} ,
		{"word":"dirimants","word_nosc":"dirimants","lemma":"dirimant","pos":"ADJ"} ,
		{"word":"disant","word_nosc":"disant","lemma":"disant","pos":"ADJ"} ,
		{"word":"disants","word_nosc":"disants","lemma":"disant","pos":"ADJ"} ,
		{"word":"discal","word_nosc":"discal","lemma":"discal","pos":"ADJ"} ,
		{"word":"discale","word_nosc":"discale","lemma":"discal","pos":"ADJ"} ,
		{"word":"discernable","word_nosc":"discernable","lemma":"discernable","pos":"ADJ"} ,
		{"word":"discernables","word_nosc":"discernables","lemma":"discernable","pos":"ADJ"} ,
		{"word":"disciplinaire","word_nosc":"disciplinaire","lemma":"disciplinaire","pos":"ADJ"} ,
		{"word":"disciplinaires","word_nosc":"disciplinaires","lemma":"disciplinaire","pos":"ADJ"} ,
		{"word":"discipliné","word_nosc":"discipline","lemma":"discipliné","pos":"ADJ"} ,
		{"word":"disciplinée","word_nosc":"disciplinee","lemma":"discipliné","pos":"ADJ"} ,
		{"word":"disciplinées","word_nosc":"disciplinees","lemma":"discipliné","pos":"ADJ"} ,
		{"word":"disciplinés","word_nosc":"disciplines","lemma":"discipliné","pos":"ADJ"} ,
		{"word":"disco","word_nosc":"disco","lemma":"disco","pos":"ADJ"} ,
		{"word":"discographiques","word_nosc":"discographiques","lemma":"discographique","pos":"ADJ"} ,
		{"word":"discontinu","word_nosc":"discontinu","lemma":"discontinu","pos":"ADJ"} ,
		{"word":"discontinue","word_nosc":"discontinue","lemma":"discontinu","pos":"ADJ"} ,
		{"word":"discontinues","word_nosc":"discontinues","lemma":"discontinu","pos":"ADJ"} ,
		{"word":"discontinus","word_nosc":"discontinus","lemma":"discontinu","pos":"ADJ"} ,
		{"word":"discordant","word_nosc":"discordant","lemma":"discordant","pos":"ADJ"} ,
		{"word":"discordante","word_nosc":"discordante","lemma":"discordant","pos":"ADJ"} ,
		{"word":"discordantes","word_nosc":"discordantes","lemma":"discordant","pos":"ADJ"} ,
		{"word":"discordants","word_nosc":"discordants","lemma":"discordant","pos":"ADJ"} ,
		{"word":"discos","word_nosc":"discos","lemma":"disco","pos":"ADJ"} ,
		{"word":"discourtois","word_nosc":"discourtois","lemma":"discourtois","pos":"ADJ"} ,
		{"word":"discourtoise","word_nosc":"discourtoise","lemma":"discourtois","pos":"ADJ"} ,
		{"word":"discourtoises","word_nosc":"discourtoises","lemma":"discourtois","pos":"ADJ"} ,
		{"word":"discret","word_nosc":"discret","lemma":"discret","pos":"ADJ"} ,
		{"word":"discrets","word_nosc":"discrets","lemma":"discret","pos":"ADJ"} ,
		{"word":"discriminant","word_nosc":"discriminant","lemma":"discriminant","pos":"ADJ"} ,
		{"word":"discriminants","word_nosc":"discriminants","lemma":"discriminant","pos":"ADJ"} ,
		{"word":"discriminatoire","word_nosc":"discriminatoire","lemma":"discriminatoire","pos":"ADJ"} ,
		{"word":"discrète","word_nosc":"discrete","lemma":"discret","pos":"ADJ"} ,
		{"word":"discrètes","word_nosc":"discretes","lemma":"discret","pos":"ADJ"} ,
		{"word":"discrétionnaire","word_nosc":"discretionnaire","lemma":"discrétionnaire","pos":"ADJ"} ,
		{"word":"discrétionnaires","word_nosc":"discretionnaires","lemma":"discrétionnaire","pos":"ADJ"} ,
		{"word":"discursives","word_nosc":"discursives","lemma":"discursif","pos":"ADJ"} ,
		{"word":"discutable","word_nosc":"discutable","lemma":"discutable","pos":"ADJ"} ,
		{"word":"discutables","word_nosc":"discutables","lemma":"discutable","pos":"ADJ"} ,
		{"word":"discutailleuse","word_nosc":"discutailleuse","lemma":"discutailleur","pos":"ADJ"} ,
		{"word":"discuté","word_nosc":"discute","lemma":"discuté","pos":"ADJ"} ,
		{"word":"discutée","word_nosc":"discutee","lemma":"discuté","pos":"ADJ"} ,
		{"word":"discutées","word_nosc":"discutees","lemma":"discuté","pos":"ADJ"} ,
		{"word":"disert","word_nosc":"disert","lemma":"disert","pos":"ADJ"} ,
		{"word":"diserte","word_nosc":"diserte","lemma":"disert","pos":"ADJ"} ,
		{"word":"disgracieuse","word_nosc":"disgracieuse","lemma":"disgracieux","pos":"ADJ"} ,
		{"word":"disgracieuses","word_nosc":"disgracieuses","lemma":"disgracieux","pos":"ADJ"} ,
		{"word":"disgracieux","word_nosc":"disgracieux","lemma":"disgracieux","pos":"ADJ"} ,
		{"word":"disgracié","word_nosc":"disgracie","lemma":"disgracié","pos":"ADJ"} ,
		{"word":"disgraciée","word_nosc":"disgraciee","lemma":"disgracié","pos":"ADJ"} ,
		{"word":"disgraciées","word_nosc":"disgraciees","lemma":"disgracié","pos":"ADJ"} ,
		{"word":"disgraciés","word_nosc":"disgracies","lemma":"disgracié","pos":"ADJ"} ,
		{"word":"disjoint","word_nosc":"disjoint","lemma":"disjoint","pos":"ADJ"} ,
		{"word":"disjointe","word_nosc":"disjointe","lemma":"disjoint","pos":"ADJ"} ,
		{"word":"disjointes","word_nosc":"disjointes","lemma":"disjoint","pos":"ADJ"} ,
		{"word":"disjoints","word_nosc":"disjoints","lemma":"disjoint","pos":"ADJ"} ,
		{"word":"disloqué","word_nosc":"disloque","lemma":"disloqué","pos":"ADJ"} ,
		{"word":"disloquée","word_nosc":"disloquee","lemma":"disloqué","pos":"ADJ"} ,
		{"word":"disloquées","word_nosc":"disloquees","lemma":"disloqué","pos":"ADJ"} ,
		{"word":"disloqués","word_nosc":"disloques","lemma":"disloqué","pos":"ADJ"} ,
		{"word":"disparaissant","word_nosc":"disparaissant","lemma":"disparaissant","pos":"ADJ"} ,
		{"word":"disparaissante","word_nosc":"disparaissante","lemma":"disparaissant","pos":"ADJ"} ,
		{"word":"disparate","word_nosc":"disparate","lemma":"disparate","pos":"ADJ"} ,
		{"word":"disparates","word_nosc":"disparates","lemma":"disparate","pos":"ADJ"} ,
		{"word":"disparu","word_nosc":"disparu","lemma":"disparu","pos":"ADJ"} ,
		{"word":"disparue","word_nosc":"disparue","lemma":"disparu","pos":"ADJ"} ,
		{"word":"disparues","word_nosc":"disparues","lemma":"disparu","pos":"ADJ"} ,
		{"word":"disparus","word_nosc":"disparus","lemma":"disparu","pos":"ADJ"} ,
		{"word":"dispendieuse","word_nosc":"dispendieuse","lemma":"dispendieux","pos":"ADJ"} ,
		{"word":"dispendieux","word_nosc":"dispendieux","lemma":"dispendieux","pos":"ADJ"} ,
		{"word":"dispensable","word_nosc":"dispensable","lemma":"dispensable","pos":"ADJ"} ,
		{"word":"dispensateur","word_nosc":"dispensateur","lemma":"dispensateur","pos":"ADJ"} ,
		{"word":"dispensateurs","word_nosc":"dispensateurs","lemma":"dispensateur","pos":"ADJ"} ,
		{"word":"dispensé","word_nosc":"dispense","lemma":"dispensé","pos":"ADJ"} ,
		{"word":"dispensées","word_nosc":"dispensees","lemma":"dispensé","pos":"ADJ"} ,
		{"word":"dispensés","word_nosc":"dispenses","lemma":"dispensé","pos":"ADJ"} ,
		{"word":"dispersant","word_nosc":"dispersant","lemma":"dispersant","pos":"ADJ"} ,
		{"word":"dispersif","word_nosc":"dispersif","lemma":"dispersif","pos":"ADJ"} ,
		{"word":"dispersé","word_nosc":"disperse","lemma":"dispersé","pos":"ADJ"} ,
		{"word":"dispersée","word_nosc":"dispersee","lemma":"dispersé","pos":"ADJ"} ,
		{"word":"dispersées","word_nosc":"dispersees","lemma":"dispersé","pos":"ADJ"} ,
		{"word":"dispersés","word_nosc":"disperses","lemma":"dispersé","pos":"ADJ"} ,
		{"word":"dispo","word_nosc":"dispo","lemma":"dispo","pos":"ADJ"} ,
		{"word":"disponible","word_nosc":"disponible","lemma":"disponible","pos":"ADJ"} ,
		{"word":"disponibles","word_nosc":"disponibles","lemma":"disponible","pos":"ADJ"} ,
		{"word":"dispos","word_nosc":"dispos","lemma":"dispos","pos":"ADJ"} ,
		{"word":"dispose","word_nosc":"dispose","lemma":"dispos","pos":"ADJ"} ,
		{"word":"disposé","word_nosc":"dispose","lemma":"disposé","pos":"ADJ"} ,
		{"word":"disposée","word_nosc":"disposee","lemma":"disposé","pos":"ADJ"} ,
		{"word":"disposées","word_nosc":"disposees","lemma":"disposé","pos":"ADJ"} ,
		{"word":"disposés","word_nosc":"disposes","lemma":"disposé","pos":"ADJ"} ,
		{"word":"disproportionnelle","word_nosc":"disproportionnelle","lemma":"disproportionnel","pos":"ADJ"} ,
		{"word":"disproportionné","word_nosc":"disproportionne","lemma":"disproportionné","pos":"ADJ"} ,
		{"word":"disproportionnée","word_nosc":"disproportionnee","lemma":"disproportionné","pos":"ADJ"} ,
		{"word":"disproportionnées","word_nosc":"disproportionnees","lemma":"disproportionné","pos":"ADJ"} ,
		{"word":"disproportionnés","word_nosc":"disproportionnes","lemma":"disproportionné","pos":"ADJ"} ,
		{"word":"disputé","word_nosc":"dispute","lemma":"disputé","pos":"ADJ"} ,
		{"word":"disputée","word_nosc":"disputee","lemma":"disputé","pos":"ADJ"} ,
		{"word":"disputées","word_nosc":"disputees","lemma":"disputé","pos":"ADJ"} ,
		{"word":"disputés","word_nosc":"disputes","lemma":"disputé","pos":"ADJ"} ,
		{"word":"disqualifié","word_nosc":"disqualifie","lemma":"disqualifié","pos":"ADJ"} ,
		{"word":"disqualifiée","word_nosc":"disqualifiee","lemma":"disqualifié","pos":"ADJ"} ,
		{"word":"disqualifiées","word_nosc":"disqualifiees","lemma":"disqualifié","pos":"ADJ"} ,
		{"word":"disqualifiés","word_nosc":"disqualifies","lemma":"disqualifié","pos":"ADJ"} ,
		{"word":"disruptif","word_nosc":"disruptif","lemma":"disruptif","pos":"ADJ"} ,
		{"word":"dissemblable","word_nosc":"dissemblable","lemma":"dissemblable","pos":"ADJ"} ,
		{"word":"dissemblables","word_nosc":"dissemblables","lemma":"dissemblable","pos":"ADJ"} ,
		{"word":"dissident","word_nosc":"dissident","lemma":"dissident","pos":"ADJ"} ,
		{"word":"dissidente","word_nosc":"dissidente","lemma":"dissident","pos":"ADJ"} ,
		{"word":"dissidentes","word_nosc":"dissidentes","lemma":"dissident","pos":"ADJ"} ,
		{"word":"dissidents","word_nosc":"dissidents","lemma":"dissident","pos":"ADJ"} ,
		{"word":"dissimilaires","word_nosc":"dissimilaires","lemma":"dissimilaire","pos":"ADJ"} ,
		{"word":"dissimulables","word_nosc":"dissimulables","lemma":"dissimulable","pos":"ADJ"} ,
		{"word":"dissimulateur","word_nosc":"dissimulateur","lemma":"dissimulateur","pos":"ADJ"} ,
		{"word":"dissimulateurs","word_nosc":"dissimulateurs","lemma":"dissimulateur","pos":"ADJ"} ,
		{"word":"dissimulatrice","word_nosc":"dissimulatrice","lemma":"dissimulateur","pos":"ADJ"} ,
		{"word":"dissimulatrices","word_nosc":"dissimulatrices","lemma":"dissimulateur","pos":"ADJ"} ,
		{"word":"dissimulé","word_nosc":"dissimule","lemma":"dissimulé","pos":"ADJ"} ,
		{"word":"dissimulée","word_nosc":"dissimulee","lemma":"dissimulé","pos":"ADJ"} ,
		{"word":"dissimulées","word_nosc":"dissimulees","lemma":"dissimulé","pos":"ADJ"} ,
		{"word":"dissimulés","word_nosc":"dissimules","lemma":"dissimulé","pos":"ADJ"} ,
		{"word":"dissipé","word_nosc":"dissipe","lemma":"dissipé","pos":"ADJ"} ,
		{"word":"dissipée","word_nosc":"dissipee","lemma":"dissipé","pos":"ADJ"} ,
		{"word":"dissipées","word_nosc":"dissipees","lemma":"dissipé","pos":"ADJ"} ,
		{"word":"dissipés","word_nosc":"dissipes","lemma":"dissipé","pos":"ADJ"} ,
		{"word":"dissociatif","word_nosc":"dissociatif","lemma":"dissociatif","pos":"ADJ"} ,
		{"word":"dissociatifs","word_nosc":"dissociatifs","lemma":"dissociatif","pos":"ADJ"} ,
		{"word":"dissociative","word_nosc":"dissociative","lemma":"dissociatif","pos":"ADJ"} ,
		{"word":"dissolu","word_nosc":"dissolu","lemma":"dissolu","pos":"ADJ"} ,
		{"word":"dissolue","word_nosc":"dissolue","lemma":"dissolu","pos":"ADJ"} ,
		{"word":"dissolues","word_nosc":"dissolues","lemma":"dissolu","pos":"ADJ"} ,
		{"word":"dissolus","word_nosc":"dissolus","lemma":"dissolu","pos":"ADJ"} ,
		{"word":"dissolvant","word_nosc":"dissolvant","lemma":"dissolvant","pos":"ADJ"} ,
		{"word":"dissolvante","word_nosc":"dissolvante","lemma":"dissolvant","pos":"ADJ"} ,
		{"word":"dissolvantes","word_nosc":"dissolvantes","lemma":"dissolvant","pos":"ADJ"} ,
		{"word":"dissolvants","word_nosc":"dissolvants","lemma":"dissolvant","pos":"ADJ"} ,
		{"word":"dissonant","word_nosc":"dissonant","lemma":"dissonant","pos":"ADJ"} ,
		{"word":"dissonants","word_nosc":"dissonants","lemma":"dissonant","pos":"ADJ"} ,
		{"word":"dissous","word_nosc":"dissous","lemma":"dissous","pos":"ADJ"} ,
		{"word":"dissoute","word_nosc":"dissoute","lemma":"dissoute","pos":"ADJ"} ,
		{"word":"dissoutes","word_nosc":"dissoutes","lemma":"dissoute","pos":"ADJ"} ,
		{"word":"dissuasif","word_nosc":"dissuasif","lemma":"dissuasif","pos":"ADJ"} ,
		{"word":"dissuasive","word_nosc":"dissuasive","lemma":"dissuasif","pos":"ADJ"} ,
		{"word":"dissymétrique","word_nosc":"dissymetrique","lemma":"dissymétrique","pos":"ADJ"} ,
		{"word":"dissymétriques","word_nosc":"dissymetriques","lemma":"dissymétrique","pos":"ADJ"} ,
		{"word":"disséminé","word_nosc":"dissemine","lemma":"disséminé","pos":"ADJ"} ,
		{"word":"disséminée","word_nosc":"disseminee","lemma":"disséminé","pos":"ADJ"} ,
		{"word":"disséminées","word_nosc":"disseminees","lemma":"disséminé","pos":"ADJ"} ,
		{"word":"disséminés","word_nosc":"dissemines","lemma":"disséminé","pos":"ADJ"} ,
		{"word":"distal","word_nosc":"distal","lemma":"distal","pos":"ADJ"} ,
		{"word":"distale","word_nosc":"distale","lemma":"distal","pos":"ADJ"} ,
		{"word":"distant","word_nosc":"distant","lemma":"distant","pos":"ADJ"} ,
		{"word":"distante","word_nosc":"distante","lemma":"distant","pos":"ADJ"} ,
		{"word":"distantes","word_nosc":"distantes","lemma":"distant","pos":"ADJ"} ,
		{"word":"distants","word_nosc":"distants","lemma":"distant","pos":"ADJ"} ,
		{"word":"distaux","word_nosc":"distaux","lemma":"distal","pos":"ADJ"} ,
		{"word":"distendu","word_nosc":"distendu","lemma":"distendu","pos":"ADJ"} ,
		{"word":"distendue","word_nosc":"distendue","lemma":"distendu","pos":"ADJ"} ,
		{"word":"distendues","word_nosc":"distendues","lemma":"distendu","pos":"ADJ"} ,
		{"word":"distendus","word_nosc":"distendus","lemma":"distendu","pos":"ADJ"} ,
		{"word":"distinct","word_nosc":"distinct","lemma":"distinct","pos":"ADJ"} ,
		{"word":"distincte","word_nosc":"distincte","lemma":"distinct","pos":"ADJ"} ,
		{"word":"distinctes","word_nosc":"distinctes","lemma":"distinct","pos":"ADJ"} ,
		{"word":"distinctif","word_nosc":"distinctif","lemma":"distinctif","pos":"ADJ"} ,
		{"word":"distinctifs","word_nosc":"distinctifs","lemma":"distinctif","pos":"ADJ"} ,
		{"word":"distinctive","word_nosc":"distinctive","lemma":"distinctif","pos":"ADJ"} ,
		{"word":"distinctives","word_nosc":"distinctives","lemma":"distinctif","pos":"ADJ"} ,
		{"word":"distincts","word_nosc":"distincts","lemma":"distinct","pos":"ADJ"} ,
		{"word":"distingué","word_nosc":"distingue","lemma":"distingué","pos":"ADJ"} ,
		{"word":"distinguée","word_nosc":"distinguee","lemma":"distingué","pos":"ADJ"} ,
		{"word":"distinguées","word_nosc":"distinguees","lemma":"distingué","pos":"ADJ"} ,
		{"word":"distingués","word_nosc":"distingues","lemma":"distingué","pos":"ADJ"} ,
		{"word":"distractives","word_nosc":"distractives","lemma":"distractif","pos":"ADJ"} ,
		{"word":"distrait","word_nosc":"distrait","lemma":"distrait","pos":"ADJ"} ,
		{"word":"distraite","word_nosc":"distraite","lemma":"distrait","pos":"ADJ"} ,
		{"word":"distraites","word_nosc":"distraites","lemma":"distrait","pos":"ADJ"} ,
		{"word":"distraits","word_nosc":"distraits","lemma":"distrait","pos":"ADJ"} ,
		{"word":"distrayant","word_nosc":"distrayant","lemma":"distrayant","pos":"ADJ"} ,
		{"word":"distrayante","word_nosc":"distrayante","lemma":"distrayant","pos":"ADJ"} ,
		{"word":"distrayantes","word_nosc":"distrayantes","lemma":"distrayant","pos":"ADJ"} ,
		{"word":"distrayants","word_nosc":"distrayants","lemma":"distrayant","pos":"ADJ"} ,
		{"word":"distributive","word_nosc":"distributive","lemma":"distributif","pos":"ADJ"} ,
		{"word":"distributrice","word_nosc":"distributrice","lemma":"distributeur","pos":"ADJ"} ,
		{"word":"distribué","word_nosc":"distribue","lemma":"distribué","pos":"ADJ"} ,
		{"word":"distribuée","word_nosc":"distribuee","lemma":"distribué","pos":"ADJ"} ,
		{"word":"distribuées","word_nosc":"distribuees","lemma":"distribué","pos":"ADJ"} ,
		{"word":"distribués","word_nosc":"distribues","lemma":"distribué","pos":"ADJ"} ,
		{"word":"dit","word_nosc":"dit","lemma":"dit","pos":"ADJ"} ,
		{"word":"dite","word_nosc":"dite","lemma":"dit","pos":"ADJ"} ,
		{"word":"dites","word_nosc":"dites","lemma":"dit","pos":"ADJ"} ,
		{"word":"dithyrambique","word_nosc":"dithyrambique","lemma":"dithyrambique","pos":"ADJ"} ,
		{"word":"dithyrambiques","word_nosc":"dithyrambiques","lemma":"dithyrambique","pos":"ADJ"} ,
		{"word":"dits","word_nosc":"dits","lemma":"dit","pos":"ADJ"} ,
		{"word":"diurnal","word_nosc":"diurnal","lemma":"diurnal","pos":"ADJ"} ,
		{"word":"diurne","word_nosc":"diurne","lemma":"diurne","pos":"ADJ"} ,
		{"word":"diurnes","word_nosc":"diurnes","lemma":"diurne","pos":"ADJ"} ,
		{"word":"diurétique","word_nosc":"diuretique","lemma":"diurétique","pos":"ADJ"} ,
		{"word":"diurétiques","word_nosc":"diuretiques","lemma":"diurétique","pos":"ADJ"} ,
		{"word":"divagant","word_nosc":"divagant","lemma":"divagant","pos":"ADJ"} ,
		{"word":"divagants","word_nosc":"divagants","lemma":"divagant","pos":"ADJ"} ,
		{"word":"dive","word_nosc":"dive","lemma":"dive","pos":"ADJ"} ,
		{"word":"divergent","word_nosc":"divergent","lemma":"divergent","pos":"ADJ"} ,
		{"word":"divergente","word_nosc":"divergente","lemma":"divergent","pos":"ADJ"} ,
		{"word":"divergentes","word_nosc":"divergentes","lemma":"divergent","pos":"ADJ"} ,
		{"word":"divergents","word_nosc":"divergents","lemma":"divergent","pos":"ADJ"} ,
		{"word":"divers","word_nosc":"divers","lemma":"divers","pos":"ADJ"} ,
		{"word":"divers","word_nosc":"divers","lemma":"divers","pos":"ADJ:ind"} ,
		{"word":"diverse","word_nosc":"diverse","lemma":"divers","pos":"ADJ"} ,
		{"word":"diverses","word_nosc":"diverses","lemma":"divers","pos":"ADJ"} ,
		{"word":"diverses","word_nosc":"diverses","lemma":"diverses","pos":"ADJ:ind"} ,
		{"word":"divertissant","word_nosc":"divertissant","lemma":"divertissant","pos":"ADJ"} ,
		{"word":"divertissante","word_nosc":"divertissante","lemma":"divertissant","pos":"ADJ"} ,
		{"word":"divertissantes","word_nosc":"divertissantes","lemma":"divertissant","pos":"ADJ"} ,
		{"word":"divertissants","word_nosc":"divertissants","lemma":"divertissant","pos":"ADJ"} ,
		{"word":"divin","word_nosc":"divin","lemma":"divin","pos":"ADJ"} ,
		{"word":"divinatoire","word_nosc":"divinatoire","lemma":"divinatoire","pos":"ADJ"} ,
		{"word":"divinatoires","word_nosc":"divinatoires","lemma":"divinatoire","pos":"ADJ"} ,
		{"word":"divinatrice","word_nosc":"divinatrice","lemma":"divinateur","pos":"ADJ"} ,
		{"word":"divinatrices","word_nosc":"divinatrices","lemma":"divinateur","pos":"ADJ"} ,
		{"word":"divine","word_nosc":"divine","lemma":"divin","pos":"ADJ"} ,
		{"word":"divines","word_nosc":"divines","lemma":"divin","pos":"ADJ"} ,
		{"word":"divinisant","word_nosc":"divinisant","lemma":"divinisant","pos":"ADJ"} ,
		{"word":"divins","word_nosc":"divins","lemma":"divin","pos":"ADJ"} ,
		{"word":"divise","word_nosc":"divise","lemma":"divis","pos":"ADJ"} ,
		{"word":"divises","word_nosc":"divises","lemma":"divis","pos":"ADJ"} ,
		{"word":"divisible","word_nosc":"divisible","lemma":"divisible","pos":"ADJ"} ,
		{"word":"divisibles","word_nosc":"divisibles","lemma":"divisible","pos":"ADJ"} ,
		{"word":"divisionnaire","word_nosc":"divisionnaire","lemma":"divisionnaire","pos":"ADJ"} ,
		{"word":"divisionnaires","word_nosc":"divisionnaires","lemma":"divisionnaire","pos":"ADJ"} ,
		{"word":"divisé","word_nosc":"divise","lemma":"divisé","pos":"ADJ"} ,
		{"word":"divisée","word_nosc":"divisee","lemma":"divisé","pos":"ADJ"} ,
		{"word":"divisées","word_nosc":"divisees","lemma":"divisé","pos":"ADJ"} ,
		{"word":"divisés","word_nosc":"divises","lemma":"divisé","pos":"ADJ"} ,
		{"word":"divorcé","word_nosc":"divorce","lemma":"divorcé","pos":"ADJ"} ,
		{"word":"divorcée","word_nosc":"divorcee","lemma":"divorcé","pos":"ADJ"} ,
		{"word":"divorcées","word_nosc":"divorcees","lemma":"divorcé","pos":"ADJ"} ,
		{"word":"divorcés","word_nosc":"divorces","lemma":"divorcé","pos":"ADJ"} ,
		{"word":"dix","word_nosc":"dix","lemma":"dix","pos":"ADJ:num"} ,
		{"word":"dix-huit","word_nosc":"dix-huit","lemma":"dix-huit","pos":"ADJ:num"} ,
		{"word":"dix-huitième","word_nosc":"dix-huitieme","lemma":"dix-huitième","pos":"ADJ"} ,
		{"word":"dix-neuf","word_nosc":"dix-neuf","lemma":"dix-neuf","pos":"ADJ:num"} ,
		{"word":"dix-neuvième","word_nosc":"dix-neuvieme","lemma":"dix-neuvième","pos":"ADJ"} ,
		{"word":"dix-sept","word_nosc":"dix-sept","lemma":"dix-sept","pos":"ADJ:num"} ,
		{"word":"dix-septième","word_nosc":"dix-septieme","lemma":"dix-septième","pos":"ADJ"} ,
		{"word":"dixit","word_nosc":"dixit","lemma":"dixit","pos":"ADJ"} ,
		{"word":"dixième","word_nosc":"dixieme","lemma":"dixième","pos":"ADJ"} ,
		{"word":"dixièmes","word_nosc":"dixiemes","lemma":"dixième","pos":"ADJ"} ,
		{"word":"diéthylénique","word_nosc":"diethylenique","lemma":"diéthylénique","pos":"ADJ"} ,
		{"word":"diététique","word_nosc":"dietetique","lemma":"diététique","pos":"ADJ"} ,
		{"word":"diététiques","word_nosc":"dietetiques","lemma":"diététique","pos":"ADJ"} ,
		{"word":"dm","word_nosc":"dm","lemma":"dm","pos":"ADJ:num"} ,
		{"word":"docile","word_nosc":"docile","lemma":"docile","pos":"ADJ"} ,
		{"word":"dociles","word_nosc":"dociles","lemma":"docile","pos":"ADJ"} ,
		{"word":"docte","word_nosc":"docte","lemma":"docte","pos":"ADJ"} ,
		{"word":"doctes","word_nosc":"doctes","lemma":"docte","pos":"ADJ"} ,
		{"word":"doctissime","word_nosc":"doctissime","lemma":"doctissime","pos":"ADJ"} ,
		{"word":"doctoral","word_nosc":"doctoral","lemma":"doctoral","pos":"ADJ"} ,
		{"word":"doctorale","word_nosc":"doctorale","lemma":"doctoral","pos":"ADJ"} ,
		{"word":"doctrinaire","word_nosc":"doctrinaire","lemma":"doctrinaire","pos":"ADJ"} ,
		{"word":"doctrinal","word_nosc":"doctrinal","lemma":"doctrinal","pos":"ADJ"} ,
		{"word":"doctrinale","word_nosc":"doctrinale","lemma":"doctrinal","pos":"ADJ"} ,
		{"word":"doctrinales","word_nosc":"doctrinales","lemma":"doctrinal","pos":"ADJ"} ,
		{"word":"documentaire","word_nosc":"documentaire","lemma":"documentaire","pos":"ADJ"} ,
		{"word":"documentaires","word_nosc":"documentaires","lemma":"documentaire","pos":"ADJ"} ,
		{"word":"documenté","word_nosc":"documente","lemma":"documenté","pos":"ADJ"} ,
		{"word":"documentée","word_nosc":"documentee","lemma":"documenté","pos":"ADJ"} ,
		{"word":"documentés","word_nosc":"documentes","lemma":"documenté","pos":"ADJ"} ,
		{"word":"dodelinant","word_nosc":"dodelinant","lemma":"dodelinant","pos":"ADJ"} ,
		{"word":"dodelinante","word_nosc":"dodelinante","lemma":"dodelinant","pos":"ADJ"} ,
		{"word":"dodelinantes","word_nosc":"dodelinantes","lemma":"dodelinant","pos":"ADJ"} ,
		{"word":"dodu","word_nosc":"dodu","lemma":"dodu","pos":"ADJ"} ,
		{"word":"dodue","word_nosc":"dodue","lemma":"dodu","pos":"ADJ"} ,
		{"word":"dodues","word_nosc":"dodues","lemma":"dodu","pos":"ADJ"} ,
		{"word":"dodus","word_nosc":"dodus","lemma":"dodu","pos":"ADJ"} ,
		{"word":"dogmatique","word_nosc":"dogmatique","lemma":"dogmatique","pos":"ADJ"} ,
		{"word":"dogmatiques","word_nosc":"dogmatiques","lemma":"dogmatique","pos":"ADJ"} ,
		{"word":"dolent","word_nosc":"dolent","lemma":"dolent","pos":"ADJ"} ,
		{"word":"dolente","word_nosc":"dolente","lemma":"dolent","pos":"ADJ"} ,
		{"word":"dolentes","word_nosc":"dolentes","lemma":"dolent","pos":"ADJ"} ,
		{"word":"dolents","word_nosc":"dolents","lemma":"dolent","pos":"ADJ"} ,
		{"word":"dolichocéphale","word_nosc":"dolichocephale","lemma":"dolichocéphale","pos":"ADJ"} ,
		{"word":"dolichocéphales","word_nosc":"dolichocephales","lemma":"dolichocéphale","pos":"ADJ"} ,
		{"word":"domanial","word_nosc":"domanial","lemma":"domanial","pos":"ADJ"} ,
		{"word":"domaniale","word_nosc":"domaniale","lemma":"domanial","pos":"ADJ"} ,
		{"word":"domaniaux","word_nosc":"domaniaux","lemma":"domanial","pos":"ADJ"} ,
		{"word":"domestique","word_nosc":"domestique","lemma":"domestique","pos":"ADJ"} ,
		{"word":"domestiques","word_nosc":"domestiques","lemma":"domestique","pos":"ADJ"} ,
		{"word":"domiciliaires","word_nosc":"domiciliaires","lemma":"domiciliaire","pos":"ADJ"} ,
		{"word":"domicilié","word_nosc":"domicilie","lemma":"domicilié","pos":"ADJ"} ,
		{"word":"domiciliée","word_nosc":"domiciliee","lemma":"domicilié","pos":"ADJ"} ,
		{"word":"domiciliés","word_nosc":"domicilies","lemma":"domicilié","pos":"ADJ"} ,
		{"word":"dominant","word_nosc":"dominant","lemma":"dominant","pos":"ADJ"} ,
		{"word":"dominante","word_nosc":"dominante","lemma":"dominant","pos":"ADJ"} ,
		{"word":"dominantes","word_nosc":"dominantes","lemma":"dominant","pos":"ADJ"} ,
		{"word":"dominants","word_nosc":"dominants","lemma":"dominant","pos":"ADJ"} ,
		{"word":"dominateur","word_nosc":"dominateur","lemma":"dominateur","pos":"ADJ"} ,
		{"word":"dominateurs","word_nosc":"dominateurs","lemma":"dominateur","pos":"ADJ"} ,
		{"word":"dominatrice","word_nosc":"dominatrice","lemma":"dominateur","pos":"ADJ"} ,
		{"word":"dominatrices","word_nosc":"dominatrices","lemma":"dominateur","pos":"ADJ"} ,
		{"word":"dominicain","word_nosc":"dominicain","lemma":"dominicain","pos":"ADJ"} ,
		{"word":"dominicaine","word_nosc":"dominicaine","lemma":"dominicain","pos":"ADJ"} ,
		{"word":"dominicains","word_nosc":"dominicains","lemma":"dominicain","pos":"ADJ"} ,
		{"word":"dominical","word_nosc":"dominical","lemma":"dominical","pos":"ADJ"} ,
		{"word":"dominicale","word_nosc":"dominicale","lemma":"dominical","pos":"ADJ"} ,
		{"word":"dominicales","word_nosc":"dominicales","lemma":"dominical","pos":"ADJ"} ,
		{"word":"dominicaux","word_nosc":"dominicaux","lemma":"dominical","pos":"ADJ"} ,
		{"word":"dommageable","word_nosc":"dommageable","lemma":"dommageable","pos":"ADJ"} ,
		{"word":"dommageables","word_nosc":"dommageables","lemma":"dommageable","pos":"ADJ"} ,
		{"word":"dompté","word_nosc":"dompte","lemma":"dompté","pos":"ADJ"} ,
		{"word":"domptée","word_nosc":"domptee","lemma":"dompté","pos":"ADJ"} ,
		{"word":"domptées","word_nosc":"domptees","lemma":"dompté","pos":"ADJ"} ,
		{"word":"domptés","word_nosc":"domptes","lemma":"dompté","pos":"ADJ"} ,
		{"word":"donjuanesque","word_nosc":"donjuanesque","lemma":"donjuanesque","pos":"ADJ"} ,
		{"word":"donnant","word_nosc":"donnant","lemma":"donnant","pos":"ADJ"} ,
		{"word":"donneur","word_nosc":"donneur","lemma":"donneur","pos":"ADJ"} ,
		{"word":"donneurs","word_nosc":"donneurs","lemma":"donneur","pos":"ADJ"} ,
		{"word":"donneuse","word_nosc":"donneuse","lemma":"donneur","pos":"ADJ"} ,
		{"word":"donné","word_nosc":"donne","lemma":"donné","pos":"ADJ"} ,
		{"word":"donnée","word_nosc":"donnee","lemma":"donné","pos":"ADJ"} ,
		{"word":"données","word_nosc":"donnees","lemma":"donné","pos":"ADJ"} ,
		{"word":"donnés","word_nosc":"donnes","lemma":"donné","pos":"ADJ"} ,
		{"word":"donquichottesque","word_nosc":"donquichottesque","lemma":"donquichottesque","pos":"ADJ"} ,
		{"word":"donquichottesques","word_nosc":"donquichottesques","lemma":"donquichottesque","pos":"ADJ"} ,
		{"word":"dopant","word_nosc":"dopant","lemma":"dopant","pos":"ADJ"} ,
		{"word":"dopants","word_nosc":"dopants","lemma":"dopant","pos":"ADJ"} ,
		{"word":"doriennes","word_nosc":"doriennes","lemma":"dorien","pos":"ADJ"} ,
		{"word":"doriens","word_nosc":"doriens","lemma":"dorien","pos":"ADJ"} ,
		{"word":"dorique","word_nosc":"dorique","lemma":"dorique","pos":"ADJ"} ,
		{"word":"doriques","word_nosc":"doriques","lemma":"dorique","pos":"ADJ"} ,
		{"word":"dormant","word_nosc":"dormant","lemma":"dormant","pos":"ADJ"} ,
		{"word":"dormante","word_nosc":"dormante","lemma":"dormant","pos":"ADJ"} ,
		{"word":"dormantes","word_nosc":"dormantes","lemma":"dormant","pos":"ADJ"} ,
		{"word":"dormants","word_nosc":"dormants","lemma":"dormant","pos":"ADJ"} ,
		{"word":"dormeur","word_nosc":"dormeur","lemma":"dormeur","pos":"ADJ"} ,
		{"word":"dormeurs","word_nosc":"dormeurs","lemma":"dormeur","pos":"ADJ"} ,
		{"word":"dormeuse","word_nosc":"dormeuse","lemma":"dormeur","pos":"ADJ"} ,
		{"word":"dormitif","word_nosc":"dormitif","lemma":"dormitif","pos":"ADJ"} ,
		{"word":"dorsal","word_nosc":"dorsal","lemma":"dorsal","pos":"ADJ"} ,
		{"word":"dorsale","word_nosc":"dorsale","lemma":"dorsal","pos":"ADJ"} ,
		{"word":"dorsales","word_nosc":"dorsales","lemma":"dorsal","pos":"ADJ"} ,
		{"word":"dorsaux","word_nosc":"dorsaux","lemma":"dorsal","pos":"ADJ"} ,
		{"word":"doré","word_nosc":"dore","lemma":"doré","pos":"ADJ"} ,
		{"word":"dorée","word_nosc":"doree","lemma":"doré","pos":"ADJ"} ,
		{"word":"dorées","word_nosc":"dorees","lemma":"doré","pos":"ADJ"} ,
		{"word":"dorés","word_nosc":"dores","lemma":"doré","pos":"ADJ"} ,
		{"word":"dostoïevskiens","word_nosc":"dostoievskiens","lemma":"dostoïevskien","pos":"ADJ"} ,
		{"word":"doté","word_nosc":"dote","lemma":"doté","pos":"ADJ"} ,
		{"word":"dotée","word_nosc":"dotee","lemma":"doté","pos":"ADJ"} ,
		{"word":"dotées","word_nosc":"dotees","lemma":"doté","pos":"ADJ"} ,
		{"word":"dotés","word_nosc":"dotes","lemma":"doté","pos":"ADJ"} ,
		{"word":"douanier","word_nosc":"douanier","lemma":"douanier","pos":"ADJ"} ,
		{"word":"douaniers","word_nosc":"douaniers","lemma":"douanier","pos":"ADJ"} ,
		{"word":"douanière","word_nosc":"douaniere","lemma":"douanier","pos":"ADJ"} ,
		{"word":"douanières","word_nosc":"douanieres","lemma":"douanier","pos":"ADJ"} ,
		{"word":"double","word_nosc":"double","lemma":"double","pos":"ADJ"} ,
		{"word":"doubles","word_nosc":"doubles","lemma":"double","pos":"ADJ"} ,
		{"word":"doublé","word_nosc":"double","lemma":"doublé","pos":"ADJ"} ,
		{"word":"doublée","word_nosc":"doublee","lemma":"doublé","pos":"ADJ"} ,
		{"word":"doublées","word_nosc":"doublees","lemma":"doublé","pos":"ADJ"} ,
		{"word":"doublés","word_nosc":"doubles","lemma":"doublé","pos":"ADJ"} ,
		{"word":"douce","word_nosc":"douce","lemma":"doux","pos":"ADJ"} ,
		{"word":"douce-amère","word_nosc":"douce-amere","lemma":"douce-amère","pos":"ADJ"} ,
		{"word":"doucereuse","word_nosc":"doucereuse","lemma":"doucereux","pos":"ADJ"} ,
		{"word":"doucereuses","word_nosc":"doucereuses","lemma":"doucereux","pos":"ADJ"} ,
		{"word":"doucereux","word_nosc":"doucereux","lemma":"doucereux","pos":"ADJ"} ,
		{"word":"douces","word_nosc":"douces","lemma":"doux","pos":"ADJ"} ,
		{"word":"doucet","word_nosc":"doucet","lemma":"doucet","pos":"ADJ"} ,
		{"word":"doucette","word_nosc":"doucette","lemma":"doucet","pos":"ADJ"} ,
		{"word":"douceâtre","word_nosc":"douceatre","lemma":"douceâtre","pos":"ADJ"} ,
		{"word":"douceâtres","word_nosc":"douceatres","lemma":"douceâtre","pos":"ADJ"} ,
		{"word":"douillet","word_nosc":"douillet","lemma":"douillet","pos":"ADJ"} ,
		{"word":"douillets","word_nosc":"douillets","lemma":"douillet","pos":"ADJ"} ,
		{"word":"douillette","word_nosc":"douillette","lemma":"douillet","pos":"ADJ"} ,
		{"word":"douillettes","word_nosc":"douillettes","lemma":"douillet","pos":"ADJ"} ,
		{"word":"douloureuse","word_nosc":"douloureuse","lemma":"douloureux","pos":"ADJ"} ,
		{"word":"douloureuses","word_nosc":"douloureuses","lemma":"douloureux","pos":"ADJ"} ,
		{"word":"douloureux","word_nosc":"douloureux","lemma":"douloureux","pos":"ADJ"} ,
		{"word":"douteurs","word_nosc":"douteurs","lemma":"douteur","pos":"ADJ"} ,
		{"word":"douteuse","word_nosc":"douteuse","lemma":"douteux","pos":"ADJ"} ,
		{"word":"douteuses","word_nosc":"douteuses","lemma":"douteux","pos":"ADJ"} ,
		{"word":"douteux","word_nosc":"douteux","lemma":"douteux","pos":"ADJ"} ,
		{"word":"doux","word_nosc":"doux","lemma":"doux","pos":"ADJ"} ,
		{"word":"doux-amer","word_nosc":"doux-amer","lemma":"doux-amer","pos":"ADJ"} ,
		{"word":"douze","word_nosc":"douze","lemma":"douze","pos":"ADJ:num"} ,
		{"word":"douzième","word_nosc":"douzieme","lemma":"douzième","pos":"ADJ"} ,
		{"word":"douçâtre","word_nosc":"doucatre","lemma":"douçâtre","pos":"ADJ"} ,
		{"word":"doué","word_nosc":"doue","lemma":"doué","pos":"ADJ"} ,
		{"word":"douée","word_nosc":"douee","lemma":"doué","pos":"ADJ"} ,
		{"word":"douées","word_nosc":"douees","lemma":"doué","pos":"ADJ"} ,
		{"word":"doués","word_nosc":"doues","lemma":"doué","pos":"ADJ"} ,
		{"word":"down","word_nosc":"down","lemma":"down","pos":"ADJ"} ,
		{"word":"draconien","word_nosc":"draconien","lemma":"draconien","pos":"ADJ"} ,
		{"word":"draconienne","word_nosc":"draconienne","lemma":"draconien","pos":"ADJ"} ,
		{"word":"draconiennes","word_nosc":"draconiennes","lemma":"draconien","pos":"ADJ"} ,
		{"word":"draconiens","word_nosc":"draconiens","lemma":"draconien","pos":"ADJ"} ,
		{"word":"dramatique","word_nosc":"dramatique","lemma":"dramatique","pos":"ADJ"} ,
		{"word":"dramatiques","word_nosc":"dramatiques","lemma":"dramatique","pos":"ADJ"} ,
		{"word":"drapier","word_nosc":"drapier","lemma":"drapier","pos":"ADJ"} ,
		{"word":"drapiers","word_nosc":"drapiers","lemma":"drapier","pos":"ADJ"} ,
		{"word":"drapé","word_nosc":"drape","lemma":"drapé","pos":"ADJ"} ,
		{"word":"drapés","word_nosc":"drapes","lemma":"drapé","pos":"ADJ"} ,
		{"word":"drastique","word_nosc":"drastique","lemma":"drastique","pos":"ADJ"} ,
		{"word":"drastiques","word_nosc":"drastiques","lemma":"drastique","pos":"ADJ"} ,
		{"word":"dressant","word_nosc":"dressant","lemma":"dressant","pos":"ADJ"} ,
		{"word":"dressé","word_nosc":"dresse","lemma":"dressé","pos":"ADJ"} ,
		{"word":"dressée","word_nosc":"dressee","lemma":"dressé","pos":"ADJ"} ,
		{"word":"dressées","word_nosc":"dressees","lemma":"dressé","pos":"ADJ"} ,
		{"word":"dressés","word_nosc":"dresses","lemma":"dressé","pos":"ADJ"} ,
		{"word":"drogué","word_nosc":"drogue","lemma":"drogué","pos":"ADJ"} ,
		{"word":"droguée","word_nosc":"droguee","lemma":"drogué","pos":"ADJ"} ,
		{"word":"droguées","word_nosc":"droguees","lemma":"drogué","pos":"ADJ"} ,
		{"word":"drogués","word_nosc":"drogues","lemma":"drogué","pos":"ADJ"} ,
		{"word":"droit","word_nosc":"droit","lemma":"droit","pos":"ADJ"} ,
		{"word":"droite","word_nosc":"droite","lemma":"droit","pos":"ADJ"} ,
		{"word":"droites","word_nosc":"droites","lemma":"droit","pos":"ADJ"} ,
		{"word":"droitier","word_nosc":"droitier","lemma":"droitier","pos":"ADJ"} ,
		{"word":"droitiers","word_nosc":"droitiers","lemma":"droitier","pos":"ADJ"} ,
		{"word":"droitière","word_nosc":"droitiere","lemma":"droitier","pos":"ADJ"} ,
		{"word":"droits","word_nosc":"droits","lemma":"droit","pos":"ADJ"} ,
		{"word":"drolatique","word_nosc":"drolatique","lemma":"drolatique","pos":"ADJ"} ,
		{"word":"drolatiques","word_nosc":"drolatiques","lemma":"drolatique","pos":"ADJ"} ,
		{"word":"dru","word_nosc":"dru","lemma":"dru","pos":"ADJ"} ,
		{"word":"drue","word_nosc":"drue","lemma":"dru","pos":"ADJ"} ,
		{"word":"drues","word_nosc":"drues","lemma":"dru","pos":"ADJ"} ,
		{"word":"druidique","word_nosc":"druidique","lemma":"druidique","pos":"ADJ"} ,
		{"word":"druidiques","word_nosc":"druidiques","lemma":"druidique","pos":"ADJ"} ,
		{"word":"drus","word_nosc":"drus","lemma":"dru","pos":"ADJ"} ,
		{"word":"druze","word_nosc":"druze","lemma":"druze","pos":"ADJ"} ,
		{"word":"druzes","word_nosc":"druzes","lemma":"druze","pos":"ADJ"} ,
		{"word":"dry","word_nosc":"dry","lemma":"dry","pos":"ADJ"} ,
		{"word":"drôle","word_nosc":"drole","lemma":"drôle","pos":"ADJ"} ,
		{"word":"drôles","word_nosc":"droles","lemma":"drôle","pos":"ADJ"} ,
		{"word":"drôlet","word_nosc":"drolet","lemma":"drôlet","pos":"ADJ"} ,
		{"word":"drôlette","word_nosc":"drolette","lemma":"drôlet","pos":"ADJ"} ,
		{"word":"dual","word_nosc":"dual","lemma":"dual","pos":"ADJ"} ,
		{"word":"dualiste","word_nosc":"dualiste","lemma":"dualiste","pos":"ADJ"} ,
		{"word":"dubitatif","word_nosc":"dubitatif","lemma":"dubitatif","pos":"ADJ"} ,
		{"word":"dubitatifs","word_nosc":"dubitatifs","lemma":"dubitatif","pos":"ADJ"} ,
		{"word":"dubitative","word_nosc":"dubitative","lemma":"dubitatif","pos":"ADJ"} ,
		{"word":"dubitatives","word_nosc":"dubitatives","lemma":"dubitatif","pos":"ADJ"} ,
		{"word":"ducal","word_nosc":"ducal","lemma":"ducal","pos":"ADJ"} ,
		{"word":"ducale","word_nosc":"ducale","lemma":"ducal","pos":"ADJ"} ,
		{"word":"ducales","word_nosc":"ducales","lemma":"ducal","pos":"ADJ"} ,
		{"word":"ducaux","word_nosc":"ducaux","lemma":"ducal","pos":"ADJ"} ,
		{"word":"ductile","word_nosc":"ductile","lemma":"ductile","pos":"ADJ"} ,
		{"word":"due","word_nosc":"due","lemma":"dû","pos":"ADJ"} ,
		{"word":"dues","word_nosc":"dues","lemma":"dû","pos":"ADJ"} ,
		{"word":"dum-dum","word_nosc":"dum-dum","lemma":"dum-dum","pos":"ADJ"} ,
		{"word":"duodécimale","word_nosc":"duodecimale","lemma":"duodécimal","pos":"ADJ"} ,
		{"word":"duodénal","word_nosc":"duodenal","lemma":"duodénal","pos":"ADJ"} ,
		{"word":"duodénale","word_nosc":"duodenale","lemma":"duodénal","pos":"ADJ"} ,
		{"word":"dupe","word_nosc":"dupe","lemma":"dupe","pos":"ADJ"} ,
		{"word":"dupes","word_nosc":"dupes","lemma":"dupe","pos":"ADJ"} ,
		{"word":"duplex","word_nosc":"duplex","lemma":"duplex","pos":"ADJ"} ,
		{"word":"dur","word_nosc":"dur","lemma":"dur","pos":"ADJ"} ,
		{"word":"durable","word_nosc":"durable","lemma":"durable","pos":"ADJ"} ,
		{"word":"durables","word_nosc":"durables","lemma":"durable","pos":"ADJ"} ,
		{"word":"duraille","word_nosc":"duraille","lemma":"duraille","pos":"ADJ"} ,
		{"word":"durailles","word_nosc":"durailles","lemma":"duraille","pos":"ADJ"} ,
		{"word":"durale","word_nosc":"durale","lemma":"dural","pos":"ADJ"} ,
		{"word":"durci","word_nosc":"durci","lemma":"durci","pos":"ADJ"} ,
		{"word":"durcie","word_nosc":"durcie","lemma":"durci","pos":"ADJ"} ,
		{"word":"durcies","word_nosc":"durcies","lemma":"durci","pos":"ADJ"} ,
		{"word":"durcis","word_nosc":"durcis","lemma":"durci","pos":"ADJ"} ,
		{"word":"dure","word_nosc":"dure","lemma":"dur","pos":"ADJ"} ,
		{"word":"dures","word_nosc":"dures","lemma":"dur","pos":"ADJ"} ,
		{"word":"durs","word_nosc":"durs","lemma":"dur","pos":"ADJ"} ,
		{"word":"dus","word_nosc":"dus","lemma":"dû","pos":"ADJ"} ,
		{"word":"duveteuse","word_nosc":"duveteuse","lemma":"duveteux","pos":"ADJ"} ,
		{"word":"duveteuses","word_nosc":"duveteuses","lemma":"duveteux","pos":"ADJ"} ,
		{"word":"duveteux","word_nosc":"duveteux","lemma":"duveteux","pos":"ADJ"} ,
		{"word":"duveté","word_nosc":"duvete","lemma":"duveté","pos":"ADJ"} ,
		{"word":"duvetée","word_nosc":"duvetee","lemma":"duveté","pos":"ADJ"} ,
		{"word":"duvetées","word_nosc":"duvetees","lemma":"duveté","pos":"ADJ"} ,
		{"word":"duvetés","word_nosc":"duvetes","lemma":"duveté","pos":"ADJ"} ,
		{"word":"dynamique","word_nosc":"dynamique","lemma":"dynamique","pos":"ADJ"} ,
		{"word":"dynamiques","word_nosc":"dynamiques","lemma":"dynamique","pos":"ADJ"} ,
		{"word":"dynamométrique","word_nosc":"dynamometrique","lemma":"dynamométrique","pos":"ADJ"} ,
		{"word":"dynastique","word_nosc":"dynastique","lemma":"dynastique","pos":"ADJ"} ,
		{"word":"dysentérique","word_nosc":"dysenterique","lemma":"dysentérique","pos":"ADJ"} ,
		{"word":"dysentériques","word_nosc":"dysenteriques","lemma":"dysentérique","pos":"ADJ"} ,
		{"word":"dyslexique","word_nosc":"dyslexique","lemma":"dyslexique","pos":"ADJ"} ,
		{"word":"dyspepsique","word_nosc":"dyspepsique","lemma":"dyspepsique","pos":"ADJ"} ,
		{"word":"dyspeptique","word_nosc":"dyspeptique","lemma":"dyspeptique","pos":"ADJ"} ,
		{"word":"dyspnéique","word_nosc":"dyspneique","lemma":"dyspnéique","pos":"ADJ"} ,
		{"word":"dyspnéiques","word_nosc":"dyspneiques","lemma":"dyspnéique","pos":"ADJ"} ,
		{"word":"déambulant","word_nosc":"deambulant","lemma":"déambulant","pos":"ADJ"} ,
		{"word":"déambulante","word_nosc":"deambulante","lemma":"déambulant","pos":"ADJ"} ,
		{"word":"déambulateur","word_nosc":"deambulateur","lemma":"déambulateur","pos":"ADJ"} ,
		{"word":"déambulatoire","word_nosc":"deambulatoire","lemma":"déambulatoire","pos":"ADJ"} ,
		{"word":"déambulatoires","word_nosc":"deambulatoires","lemma":"déambulatoire","pos":"ADJ"} ,
		{"word":"débarqué","word_nosc":"debarque","lemma":"débarqué","pos":"ADJ"} ,
		{"word":"débarquée","word_nosc":"debarquee","lemma":"débarqué","pos":"ADJ"} ,
		{"word":"débarquées","word_nosc":"debarquees","lemma":"débarqué","pos":"ADJ"} ,
		{"word":"débarqués","word_nosc":"debarques","lemma":"débarqué","pos":"ADJ"} ,
		{"word":"débauché","word_nosc":"debauche","lemma":"débauché","pos":"ADJ"} ,
		{"word":"débauchée","word_nosc":"debauchee","lemma":"débauché","pos":"ADJ"} ,
		{"word":"débauchées","word_nosc":"debauchees","lemma":"débauché","pos":"ADJ"} ,
		{"word":"débauchés","word_nosc":"debauches","lemma":"débauché","pos":"ADJ"} ,
		{"word":"débile","word_nosc":"debile","lemma":"débile","pos":"ADJ"} ,
		{"word":"débiles","word_nosc":"debiles","lemma":"débile","pos":"ADJ"} ,
		{"word":"débilitant","word_nosc":"debilitant","lemma":"débilitant","pos":"ADJ"} ,
		{"word":"débilitante","word_nosc":"debilitante","lemma":"débilitant","pos":"ADJ"} ,
		{"word":"débilitantes","word_nosc":"debilitantes","lemma":"débilitant","pos":"ADJ"} ,
		{"word":"débiteur","word_nosc":"debiteur","lemma":"débiteur","pos":"ADJ"} ,
		{"word":"débiteurs","word_nosc":"debiteurs","lemma":"débiteur","pos":"ADJ"} ,
		{"word":"débitrice","word_nosc":"debitrice","lemma":"débiteur","pos":"ADJ"} ,
		{"word":"déblayeur","word_nosc":"deblayeur","lemma":"déblayeur","pos":"ADJ"} ,
		{"word":"débonnaire","word_nosc":"debonnaire","lemma":"débonnaire","pos":"ADJ"} ,
		{"word":"débonnaires","word_nosc":"debonnaires","lemma":"débonnaire","pos":"ADJ"} ,
		{"word":"débordant","word_nosc":"debordant","lemma":"débordant","pos":"ADJ"} ,
		{"word":"débordante","word_nosc":"debordante","lemma":"débordant","pos":"ADJ"} ,
		{"word":"débordantes","word_nosc":"debordantes","lemma":"débordant","pos":"ADJ"} ,
		{"word":"débordants","word_nosc":"debordants","lemma":"débordant","pos":"ADJ"} ,
		{"word":"déboussolantes","word_nosc":"deboussolantes","lemma":"déboussolant","pos":"ADJ"} ,
		{"word":"débraillé","word_nosc":"debraille","lemma":"débraillé","pos":"ADJ"} ,
		{"word":"débraillée","word_nosc":"debraillee","lemma":"débraillé","pos":"ADJ"} ,
		{"word":"débraillées","word_nosc":"debraillees","lemma":"débraillé","pos":"ADJ"} ,
		{"word":"débraillés","word_nosc":"debrailles","lemma":"débraillé","pos":"ADJ"} ,
		{"word":"débridé","word_nosc":"debride","lemma":"débridé","pos":"ADJ"} ,
		{"word":"débridée","word_nosc":"debridee","lemma":"débridé","pos":"ADJ"} ,
		{"word":"débridées","word_nosc":"debridees","lemma":"débridé","pos":"ADJ"} ,
		{"word":"débridés","word_nosc":"debrides","lemma":"débridé","pos":"ADJ"} ,
		{"word":"débrouillard","word_nosc":"debrouillard","lemma":"débrouillard","pos":"ADJ"} ,
		{"word":"débrouillarde","word_nosc":"debrouillarde","lemma":"débrouillard","pos":"ADJ"} ,
		{"word":"débrouillards","word_nosc":"debrouillards","lemma":"débrouillard","pos":"ADJ"} ,
		{"word":"débutant","word_nosc":"debutant","lemma":"débutant","pos":"ADJ"} ,
		{"word":"débutante","word_nosc":"debutante","lemma":"débutant","pos":"ADJ"} ,
		{"word":"débutantes","word_nosc":"debutantes","lemma":"débutant","pos":"ADJ"} ,
		{"word":"débutants","word_nosc":"debutants","lemma":"débutant","pos":"ADJ"} ,
		{"word":"déc","word_nosc":"dec","lemma":"déc","pos":"ADJ"} ,
		{"word":"décadent","word_nosc":"decadent","lemma":"décadent","pos":"ADJ"} ,
		{"word":"décadente","word_nosc":"decadente","lemma":"décadent","pos":"ADJ"} ,
		{"word":"décadentes","word_nosc":"decadentes","lemma":"décadent","pos":"ADJ"} ,
		{"word":"décadents","word_nosc":"decadents","lemma":"décadent","pos":"ADJ"} ,
		{"word":"décaféiné","word_nosc":"decafeine","lemma":"décaféiné","pos":"ADJ"} ,
		{"word":"décalcomanies","word_nosc":"decalcomanies","lemma":"décalcomanies","pos":"ADJ"} ,
		{"word":"décalé","word_nosc":"decale","lemma":"décalé","pos":"ADJ"} ,
		{"word":"décalée","word_nosc":"decalee","lemma":"décalé","pos":"ADJ"} ,
		{"word":"décalées","word_nosc":"decalees","lemma":"décalé","pos":"ADJ"} ,
		{"word":"décalés","word_nosc":"decales","lemma":"décalé","pos":"ADJ"} ,
		{"word":"décapant","word_nosc":"decapant","lemma":"décapant","pos":"ADJ"} ,
		{"word":"décapante","word_nosc":"decapante","lemma":"décapant","pos":"ADJ"} ,
		{"word":"décapants","word_nosc":"decapants","lemma":"décapant","pos":"ADJ"} ,
		{"word":"décapotable","word_nosc":"decapotable","lemma":"décapotable","pos":"ADJ"} ,
		{"word":"décapotables","word_nosc":"decapotables","lemma":"décapotable","pos":"ADJ"} ,
		{"word":"décapoté","word_nosc":"decapote","lemma":"décapoté","pos":"ADJ"} ,
		{"word":"décapotée","word_nosc":"decapotee","lemma":"décapoté","pos":"ADJ"} ,
		{"word":"décati","word_nosc":"decati","lemma":"décati","pos":"ADJ"} ,
		{"word":"décatie","word_nosc":"decatie","lemma":"décati","pos":"ADJ"} ,
		{"word":"décatis","word_nosc":"decatis","lemma":"décati","pos":"ADJ"} ,
		{"word":"décavé","word_nosc":"decave","lemma":"décavé","pos":"ADJ"} ,
		{"word":"décelable","word_nosc":"decelable","lemma":"décelable","pos":"ADJ"} ,
		{"word":"décennal","word_nosc":"decennal","lemma":"décennal","pos":"ADJ"} ,
		{"word":"décennale","word_nosc":"decennale","lemma":"décennal","pos":"ADJ"} ,
		{"word":"décent","word_nosc":"decent","lemma":"décent","pos":"ADJ"} ,
		{"word":"décente","word_nosc":"decente","lemma":"décent","pos":"ADJ"} ,
		{"word":"décentes","word_nosc":"decentes","lemma":"décent","pos":"ADJ"} ,
		{"word":"décents","word_nosc":"decents","lemma":"décent","pos":"ADJ"} ,
		{"word":"décevant","word_nosc":"decevant","lemma":"décevant","pos":"ADJ"} ,
		{"word":"décevante","word_nosc":"decevante","lemma":"décevant","pos":"ADJ"} ,
		{"word":"décevantes","word_nosc":"decevantes","lemma":"décevant","pos":"ADJ"} ,
		{"word":"décevants","word_nosc":"decevants","lemma":"décevant","pos":"ADJ"} ,
		{"word":"décharné","word_nosc":"decharne","lemma":"décharné","pos":"ADJ"} ,
		{"word":"décharnée","word_nosc":"decharnee","lemma":"décharné","pos":"ADJ"} ,
		{"word":"décharnées","word_nosc":"decharnees","lemma":"décharné","pos":"ADJ"} ,
		{"word":"décharnés","word_nosc":"decharnes","lemma":"décharné","pos":"ADJ"} ,
		{"word":"déchaussé","word_nosc":"dechausse","lemma":"déchaussé","pos":"ADJ"} ,
		{"word":"déchaussée","word_nosc":"dechaussee","lemma":"déchaussé","pos":"ADJ"} ,
		{"word":"déchaussées","word_nosc":"dechaussees","lemma":"déchaussé","pos":"ADJ"} ,
		{"word":"déchaussés","word_nosc":"dechausses","lemma":"déchaussé","pos":"ADJ"} ,
		{"word":"déchaîné","word_nosc":"dechaine","lemma":"déchaîné","pos":"ADJ"} ,
		{"word":"déchaînée","word_nosc":"dechainee","lemma":"déchaîné","pos":"ADJ"} ,
		{"word":"déchaînées","word_nosc":"dechainees","lemma":"déchaîné","pos":"ADJ"} ,
		{"word":"déchaînés","word_nosc":"dechaines","lemma":"déchaîné","pos":"ADJ"} ,
		{"word":"déchiffrable","word_nosc":"dechiffrable","lemma":"déchiffrable","pos":"ADJ"} ,
		{"word":"déchiffrables","word_nosc":"dechiffrables","lemma":"déchiffrable","pos":"ADJ"} ,
		{"word":"déchiqueté","word_nosc":"dechiquete","lemma":"déchiqueté","pos":"ADJ"} ,
		{"word":"déchiquetée","word_nosc":"dechiquetee","lemma":"déchiqueté","pos":"ADJ"} ,
		{"word":"déchiquetées","word_nosc":"dechiquetees","lemma":"déchiqueté","pos":"ADJ"} ,
		{"word":"déchiquetés","word_nosc":"dechiquetes","lemma":"déchiqueté","pos":"ADJ"} ,
		{"word":"déchirant","word_nosc":"dechirant","lemma":"déchirant","pos":"ADJ"} ,
		{"word":"déchirante","word_nosc":"dechirante","lemma":"déchirant","pos":"ADJ"} ,
		{"word":"déchirantes","word_nosc":"dechirantes","lemma":"déchirant","pos":"ADJ"} ,
		{"word":"déchirants","word_nosc":"dechirants","lemma":"déchirant","pos":"ADJ"} ,
		{"word":"déchiré","word_nosc":"dechire","lemma":"déchiré","pos":"ADJ"} ,
		{"word":"déchirée","word_nosc":"dechiree","lemma":"déchiré","pos":"ADJ"} ,
		{"word":"déchirées","word_nosc":"dechirees","lemma":"déchiré","pos":"ADJ"} ,
		{"word":"déchirés","word_nosc":"dechires","lemma":"déchiré","pos":"ADJ"} ,
		{"word":"déchu","word_nosc":"dechu","lemma":"déchu","pos":"ADJ"} ,
		{"word":"déchue","word_nosc":"dechue","lemma":"déchu","pos":"ADJ"} ,
		{"word":"déchues","word_nosc":"dechues","lemma":"déchu","pos":"ADJ"} ,
		{"word":"déchus","word_nosc":"dechus","lemma":"déchu","pos":"ADJ"} ,
		{"word":"décidé","word_nosc":"decide","lemma":"décidé","pos":"ADJ"} ,
		{"word":"décidée","word_nosc":"decidee","lemma":"décidé","pos":"ADJ"} ,
		{"word":"décidées","word_nosc":"decidees","lemma":"décidé","pos":"ADJ"} ,
		{"word":"décidés","word_nosc":"decides","lemma":"décidé","pos":"ADJ"} ,
		{"word":"décimal","word_nosc":"decimal","lemma":"décimal","pos":"ADJ"} ,
		{"word":"décimale","word_nosc":"decimale","lemma":"décimal","pos":"ADJ"} ,
		{"word":"décimales","word_nosc":"decimales","lemma":"décimal","pos":"ADJ"} ,
		{"word":"décisif","word_nosc":"decisif","lemma":"décisif","pos":"ADJ"} ,
		{"word":"décisifs","word_nosc":"decisifs","lemma":"décisif","pos":"ADJ"} ,
		{"word":"décisionnel","word_nosc":"decisionnel","lemma":"décisionnel","pos":"ADJ"} ,
		{"word":"décisionnelles","word_nosc":"decisionnelles","lemma":"décisionnel","pos":"ADJ"} ,
		{"word":"décisive","word_nosc":"decisive","lemma":"décisif","pos":"ADJ"} ,
		{"word":"décisives","word_nosc":"decisives","lemma":"décisif","pos":"ADJ"} ,
		{"word":"déclamatoire","word_nosc":"declamatoire","lemma":"déclamatoire","pos":"ADJ"} ,
		{"word":"déclamatoires","word_nosc":"declamatoires","lemma":"déclamatoire","pos":"ADJ"} ,
		{"word":"déclarant","word_nosc":"declarant","lemma":"déclarant","pos":"ADJ"} ,
		{"word":"déclarative","word_nosc":"declarative","lemma":"déclaratif","pos":"ADJ"} ,
		{"word":"déclaratoire","word_nosc":"declaratoire","lemma":"déclaratoire","pos":"ADJ"} ,
		{"word":"déclaré","word_nosc":"declare","lemma":"déclaré","pos":"ADJ"} ,
		{"word":"déclarée","word_nosc":"declaree","lemma":"déclaré","pos":"ADJ"} ,
		{"word":"déclarées","word_nosc":"declarees","lemma":"déclaré","pos":"ADJ"} ,
		{"word":"déclarés","word_nosc":"declares","lemma":"déclaré","pos":"ADJ"} ,
		{"word":"déclassé","word_nosc":"declasse","lemma":"déclassé","pos":"ADJ"} ,
		{"word":"déclassés","word_nosc":"declasses","lemma":"déclassé","pos":"ADJ"} ,
		{"word":"déclenchant","word_nosc":"declenchant","lemma":"déclenchant","pos":"ADJ"} ,
		{"word":"déclinant","word_nosc":"declinant","lemma":"déclinant","pos":"ADJ"} ,
		{"word":"déclinante","word_nosc":"declinante","lemma":"déclinant","pos":"ADJ"} ,
		{"word":"déclinants","word_nosc":"declinants","lemma":"déclinant","pos":"ADJ"} ,
		{"word":"déclive","word_nosc":"declive","lemma":"déclive","pos":"ADJ"} ,
		{"word":"déclives","word_nosc":"declives","lemma":"déclive","pos":"ADJ"} ,
		{"word":"déclose","word_nosc":"declose","lemma":"déclos","pos":"ADJ"} ,
		{"word":"décloses","word_nosc":"decloses","lemma":"déclos","pos":"ADJ"} ,
		{"word":"déco","word_nosc":"deco","lemma":"déco","pos":"ADJ"} ,
		{"word":"décolleté","word_nosc":"decollete","lemma":"décolleté","pos":"ADJ"} ,
		{"word":"décolletée","word_nosc":"decolletee","lemma":"décolleté","pos":"ADJ"} ,
		{"word":"décolletées","word_nosc":"decolletees","lemma":"décolleté","pos":"ADJ"} ,
		{"word":"décolletés","word_nosc":"decolletes","lemma":"décolleté","pos":"ADJ"} ,
		{"word":"décolorant","word_nosc":"decolorant","lemma":"décolorant","pos":"ADJ"} ,
		{"word":"décoloré","word_nosc":"decolore","lemma":"décoloré","pos":"ADJ"} ,
		{"word":"décolorée","word_nosc":"decoloree","lemma":"décoloré","pos":"ADJ"} ,
		{"word":"décolorées","word_nosc":"decolorees","lemma":"décoloré","pos":"ADJ"} ,
		{"word":"décolorés","word_nosc":"decolores","lemma":"décoloré","pos":"ADJ"} ,
		{"word":"décomposable","word_nosc":"decomposable","lemma":"décomposable","pos":"ADJ"} ,
		{"word":"décomposé","word_nosc":"decompose","lemma":"décomposé","pos":"ADJ"} ,
		{"word":"décomposée","word_nosc":"decomposee","lemma":"décomposé","pos":"ADJ"} ,
		{"word":"décomposées","word_nosc":"decomposees","lemma":"décomposé","pos":"ADJ"} ,
		{"word":"décomposés","word_nosc":"decomposes","lemma":"décomposé","pos":"ADJ"} ,
		{"word":"déconcertant","word_nosc":"deconcertant","lemma":"déconcertant","pos":"ADJ"} ,
		{"word":"déconcertante","word_nosc":"deconcertante","lemma":"déconcertant","pos":"ADJ"} ,
		{"word":"déconcertantes","word_nosc":"deconcertantes","lemma":"déconcertant","pos":"ADJ"} ,
		{"word":"déconcertants","word_nosc":"deconcertants","lemma":"déconcertant","pos":"ADJ"} ,
		{"word":"déconcerté","word_nosc":"deconcerte","lemma":"déconcerté","pos":"ADJ"} ,
		{"word":"déconcertée","word_nosc":"deconcertee","lemma":"déconcerté","pos":"ADJ"} ,
		{"word":"déconcertés","word_nosc":"deconcertes","lemma":"déconcerté","pos":"ADJ"} ,
		{"word":"déconfit","word_nosc":"deconfit","lemma":"déconfit","pos":"ADJ"} ,
		{"word":"déconfite","word_nosc":"deconfite","lemma":"déconfit","pos":"ADJ"} ,
		{"word":"déconfits","word_nosc":"deconfits","lemma":"déconfit","pos":"ADJ"} ,
		{"word":"décontenancé","word_nosc":"decontenance","lemma":"décontenancé","pos":"ADJ"} ,
		{"word":"décontenancée","word_nosc":"decontenancee","lemma":"décontenancé","pos":"ADJ"} ,
		{"word":"décontenancés","word_nosc":"decontenances","lemma":"décontenancé","pos":"ADJ"} ,
		{"word":"décontract","word_nosc":"decontract","lemma":"décontract","pos":"ADJ"} ,
		{"word":"décontracté","word_nosc":"decontracte","lemma":"décontracté","pos":"ADJ"} ,
		{"word":"décontractée","word_nosc":"decontractee","lemma":"décontracté","pos":"ADJ"} ,
		{"word":"décontractées","word_nosc":"decontractees","lemma":"décontracté","pos":"ADJ"} ,
		{"word":"décontractés","word_nosc":"decontractes","lemma":"décontracté","pos":"ADJ"} ,
		{"word":"décoratif","word_nosc":"decoratif","lemma":"décoratif","pos":"ADJ"} ,
		{"word":"décoratifs","word_nosc":"decoratifs","lemma":"décoratif","pos":"ADJ"} ,
		{"word":"décorative","word_nosc":"decorative","lemma":"décoratif","pos":"ADJ"} ,
		{"word":"décoratives","word_nosc":"decoratives","lemma":"décoratif","pos":"ADJ"} ,
		{"word":"décortiqué","word_nosc":"decortique","lemma":"décortiqué","pos":"ADJ"} ,
		{"word":"décortiquée","word_nosc":"decortiquee","lemma":"décortiqué","pos":"ADJ"} ,
		{"word":"décoré","word_nosc":"decore","lemma":"décoré","pos":"ADJ"} ,
		{"word":"décorée","word_nosc":"decoree","lemma":"décoré","pos":"ADJ"} ,
		{"word":"décorées","word_nosc":"decorees","lemma":"décoré","pos":"ADJ"} ,
		{"word":"décorés","word_nosc":"decores","lemma":"décoré","pos":"ADJ"} ,
		{"word":"découplé","word_nosc":"decouple","lemma":"découplé","pos":"ADJ"} ,
		{"word":"découplée","word_nosc":"decouplee","lemma":"découplé","pos":"ADJ"} ,
		{"word":"découpé","word_nosc":"decoupe","lemma":"découpé","pos":"ADJ"} ,
		{"word":"découpée","word_nosc":"decoupee","lemma":"découpé","pos":"ADJ"} ,
		{"word":"découpées","word_nosc":"decoupees","lemma":"découpé","pos":"ADJ"} ,
		{"word":"découpés","word_nosc":"decoupes","lemma":"découpé","pos":"ADJ"} ,
		{"word":"décourageant","word_nosc":"decourageant","lemma":"décourageant","pos":"ADJ"} ,
		{"word":"décourageante","word_nosc":"decourageante","lemma":"décourageant","pos":"ADJ"} ,
		{"word":"décourageantes","word_nosc":"decourageantes","lemma":"décourageant","pos":"ADJ"} ,
		{"word":"décourageants","word_nosc":"decourageants","lemma":"décourageant","pos":"ADJ"} ,
		{"word":"découragé","word_nosc":"decourage","lemma":"découragé","pos":"ADJ"} ,
		{"word":"découragée","word_nosc":"decouragee","lemma":"découragé","pos":"ADJ"} ,
		{"word":"découragées","word_nosc":"decouragees","lemma":"découragé","pos":"ADJ"} ,
		{"word":"découragés","word_nosc":"decourages","lemma":"découragé","pos":"ADJ"} ,
		{"word":"décousu","word_nosc":"decousu","lemma":"décousu","pos":"ADJ"} ,
		{"word":"décousue","word_nosc":"decousue","lemma":"décousu","pos":"ADJ"} ,
		{"word":"décousues","word_nosc":"decousues","lemma":"décousu","pos":"ADJ"} ,
		{"word":"décousus","word_nosc":"decousus","lemma":"décousu","pos":"ADJ"} ,
		{"word":"découvert","word_nosc":"decouvert","lemma":"découvert","pos":"ADJ"} ,
		{"word":"découverte","word_nosc":"decouverte","lemma":"découvert","pos":"ADJ"} ,
		{"word":"découvertes","word_nosc":"decouvertes","lemma":"découvert","pos":"ADJ"} ,
		{"word":"découverts","word_nosc":"decouverts","lemma":"découvert","pos":"ADJ"} ,
		{"word":"décrit","word_nosc":"decrit","lemma":"décrit","pos":"ADJ"} ,
		{"word":"décrite","word_nosc":"decrite","lemma":"décrit","pos":"ADJ"} ,
		{"word":"décrites","word_nosc":"decrites","lemma":"décrit","pos":"ADJ"} ,
		{"word":"décrits","word_nosc":"decrits","lemma":"décrit","pos":"ADJ"} ,
		{"word":"décrié","word_nosc":"decrie","lemma":"décrié","pos":"ADJ"} ,
		{"word":"décriée","word_nosc":"decriee","lemma":"décrié","pos":"ADJ"} ,
		{"word":"décriées","word_nosc":"decriees","lemma":"décrié","pos":"ADJ"} ,
		{"word":"décriés","word_nosc":"decries","lemma":"décrié","pos":"ADJ"} ,
		{"word":"décrochant","word_nosc":"decrochant","lemma":"décrochant","pos":"ADJ"} ,
		{"word":"décroissant","word_nosc":"decroissant","lemma":"décroissant","pos":"ADJ"} ,
		{"word":"décroissante","word_nosc":"decroissante","lemma":"décroissant","pos":"ADJ"} ,
		{"word":"décroissantes","word_nosc":"decroissantes","lemma":"décroissant","pos":"ADJ"} ,
		{"word":"décroissants","word_nosc":"decroissants","lemma":"décroissant","pos":"ADJ"} ,
		{"word":"décrépit","word_nosc":"decrepit","lemma":"décrépit","pos":"ADJ"} ,
		{"word":"décrépite","word_nosc":"decrepite","lemma":"décrépit","pos":"ADJ"} ,
		{"word":"décrépites","word_nosc":"decrepites","lemma":"décrépit","pos":"ADJ"} ,
		{"word":"décrépits","word_nosc":"decrepits","lemma":"décrépit","pos":"ADJ"} ,
		{"word":"déculotté","word_nosc":"deculotte","lemma":"déculotté","pos":"ADJ"} ,
		{"word":"déculottée","word_nosc":"deculottee","lemma":"déculotté","pos":"ADJ"} ,
		{"word":"déculottées","word_nosc":"deculottees","lemma":"déculotté","pos":"ADJ"} ,
		{"word":"déculottés","word_nosc":"deculottes","lemma":"déculotté","pos":"ADJ"} ,
		{"word":"décuple","word_nosc":"decuple","lemma":"décuple","pos":"ADJ"} ,
		{"word":"décuplé","word_nosc":"decuple","lemma":"décuplé","pos":"ADJ"} ,
		{"word":"décuplée","word_nosc":"decuplee","lemma":"décuplé","pos":"ADJ"} ,
		{"word":"décédé","word_nosc":"decede","lemma":"décédé","pos":"ADJ"} ,
		{"word":"décédée","word_nosc":"decedee","lemma":"décédé","pos":"ADJ"} ,
		{"word":"décédées","word_nosc":"decedees","lemma":"décédé","pos":"ADJ"} ,
		{"word":"décédés","word_nosc":"decedes","lemma":"décédé","pos":"ADJ"} ,
		{"word":"dédaignables","word_nosc":"dedaignables","lemma":"dédaignable","pos":"ADJ"} ,
		{"word":"dédaigneuse","word_nosc":"dedaigneuse","lemma":"dédaigneux","pos":"ADJ"} ,
		{"word":"dédaigneuses","word_nosc":"dedaigneuses","lemma":"dédaigneux","pos":"ADJ"} ,
		{"word":"dédaigneux","word_nosc":"dedaigneux","lemma":"dédaigneux","pos":"ADJ"} ,
		{"word":"dédicacé","word_nosc":"dedicace","lemma":"dédicacé","pos":"ADJ"} ,
		{"word":"dédicacée","word_nosc":"dedicacee","lemma":"dédicacé","pos":"ADJ"} ,
		{"word":"dédicacées","word_nosc":"dedicacees","lemma":"dédicacé","pos":"ADJ"} ,
		{"word":"dédicacés","word_nosc":"dedicaces","lemma":"dédicacé","pos":"ADJ"} ,
		{"word":"dédicatoire","word_nosc":"dedicatoire","lemma":"dédicatoire","pos":"ADJ"} ,
		{"word":"dédicatoires","word_nosc":"dedicatoires","lemma":"dédicatoire","pos":"ADJ"} ,
		{"word":"dédié","word_nosc":"dedie","lemma":"dédié","pos":"ADJ"} ,
		{"word":"dédiée","word_nosc":"dediee","lemma":"dédié","pos":"ADJ"} ,
		{"word":"dédiés","word_nosc":"dedies","lemma":"dédié","pos":"ADJ"} ,
		{"word":"déductible","word_nosc":"deductible","lemma":"déductible","pos":"ADJ"} ,
		{"word":"déductibles","word_nosc":"deductibles","lemma":"déductible","pos":"ADJ"} ,
		{"word":"déductif","word_nosc":"deductif","lemma":"déductif","pos":"ADJ"} ,
		{"word":"déductive","word_nosc":"deductive","lemma":"déductif","pos":"ADJ"} ,
		{"word":"défaillant","word_nosc":"defaillant","lemma":"défaillant","pos":"ADJ"} ,
		{"word":"défaillante","word_nosc":"defaillante","lemma":"défaillant","pos":"ADJ"} ,
		{"word":"défaillantes","word_nosc":"defaillantes","lemma":"défaillant","pos":"ADJ"} ,
		{"word":"défaillants","word_nosc":"defaillants","lemma":"défaillant","pos":"ADJ"} ,
		{"word":"défait","word_nosc":"defait","lemma":"défait","pos":"ADJ"} ,
		{"word":"défaite","word_nosc":"defaite","lemma":"défait","pos":"ADJ"} ,
		{"word":"défaites","word_nosc":"defaites","lemma":"défait","pos":"ADJ"} ,
		{"word":"défaitiste","word_nosc":"defaitiste","lemma":"défaitiste","pos":"ADJ"} ,
		{"word":"défaitistes","word_nosc":"defaitistes","lemma":"défaitiste","pos":"ADJ"} ,
		{"word":"défaits","word_nosc":"defaits","lemma":"défait","pos":"ADJ"} ,
		{"word":"défavorable","word_nosc":"defavorable","lemma":"défavorable","pos":"ADJ"} ,
		{"word":"défavorables","word_nosc":"defavorables","lemma":"défavorable","pos":"ADJ"} ,
		{"word":"défavorisé","word_nosc":"defavorise","lemma":"défavorisé","pos":"ADJ"} ,
		{"word":"défavorisée","word_nosc":"defavorisee","lemma":"défavorisé","pos":"ADJ"} ,
		{"word":"défavorisées","word_nosc":"defavorisees","lemma":"défavorisé","pos":"ADJ"} ,
		{"word":"défavorisés","word_nosc":"defavorises","lemma":"défavorisé","pos":"ADJ"} ,
		{"word":"défectible","word_nosc":"defectible","lemma":"défectible","pos":"ADJ"} ,
		{"word":"défectif","word_nosc":"defectif","lemma":"défectif","pos":"ADJ"} ,
		{"word":"défectionnaires","word_nosc":"defectionnaires","lemma":"défectionnaire","pos":"ADJ"} ,
		{"word":"défectueuse","word_nosc":"defectueuse","lemma":"défectueux","pos":"ADJ"} ,
		{"word":"défectueuses","word_nosc":"defectueuses","lemma":"défectueux","pos":"ADJ"} ,
		{"word":"défectueux","word_nosc":"defectueux","lemma":"défectueux","pos":"ADJ"} ,
		{"word":"défendable","word_nosc":"defendable","lemma":"défendable","pos":"ADJ"} ,
		{"word":"défendu","word_nosc":"defendu","lemma":"défendu","pos":"ADJ"} ,
		{"word":"défendue","word_nosc":"defendue","lemma":"défendu","pos":"ADJ"} ,
		{"word":"défendues","word_nosc":"defendues","lemma":"défendu","pos":"ADJ"} ,
		{"word":"défendus","word_nosc":"defendus","lemma":"défendu","pos":"ADJ"} ,
		{"word":"défensif","word_nosc":"defensif","lemma":"défensif","pos":"ADJ"} ,
		{"word":"défensifs","word_nosc":"defensifs","lemma":"défensif","pos":"ADJ"} ,
		{"word":"défensive","word_nosc":"defensive","lemma":"défensif","pos":"ADJ"} ,
		{"word":"défensives","word_nosc":"defensives","lemma":"défensif","pos":"ADJ"} ,
		{"word":"déferlant","word_nosc":"deferlant","lemma":"déferlant","pos":"ADJ"} ,
		{"word":"déferlante","word_nosc":"deferlante","lemma":"déferlant","pos":"ADJ"} ,
		{"word":"déferlantes","word_nosc":"deferlantes","lemma":"déferlant","pos":"ADJ"} ,
		{"word":"déferré","word_nosc":"deferre","lemma":"déferré","pos":"ADJ"} ,
		{"word":"défiant","word_nosc":"defiant","lemma":"défiant","pos":"ADJ"} ,
		{"word":"défiante","word_nosc":"defiante","lemma":"défiant","pos":"ADJ"} ,
		{"word":"déficient","word_nosc":"deficient","lemma":"déficient","pos":"ADJ"} ,
		{"word":"déficiente","word_nosc":"deficiente","lemma":"déficient","pos":"ADJ"} ,
		{"word":"déficientes","word_nosc":"deficientes","lemma":"déficient","pos":"ADJ"} ,
		{"word":"déficients","word_nosc":"deficients","lemma":"déficient","pos":"ADJ"} ,
		{"word":"déficitaire","word_nosc":"deficitaire","lemma":"déficitaire","pos":"ADJ"} ,
		{"word":"déficitaires","word_nosc":"deficitaires","lemma":"déficitaire","pos":"ADJ"} ,
		{"word":"défiguré","word_nosc":"defigure","lemma":"défiguré","pos":"ADJ"} ,
		{"word":"défigurée","word_nosc":"defiguree","lemma":"défiguré","pos":"ADJ"} ,
		{"word":"défigurées","word_nosc":"defigurees","lemma":"défiguré","pos":"ADJ"} ,
		{"word":"défigurés","word_nosc":"defigures","lemma":"défiguré","pos":"ADJ"} ,
		{"word":"défilantes","word_nosc":"defilantes","lemma":"défilant","pos":"ADJ"} ,
		{"word":"défilants","word_nosc":"defilants","lemma":"défilant","pos":"ADJ"} ,
		{"word":"défini","word_nosc":"defini","lemma":"défini","pos":"ADJ"} ,
		{"word":"définie","word_nosc":"definie","lemma":"défini","pos":"ADJ"} ,
		{"word":"définies","word_nosc":"definies","lemma":"défini","pos":"ADJ"} ,
		{"word":"définis","word_nosc":"definis","lemma":"défini","pos":"ADJ"} ,
		{"word":"définissable","word_nosc":"definissable","lemma":"définissable","pos":"ADJ"} ,
		{"word":"définissables","word_nosc":"definissables","lemma":"définissable","pos":"ADJ"} ,
		{"word":"définitif","word_nosc":"definitif","lemma":"définitif","pos":"ADJ"} ,
		{"word":"définitifs","word_nosc":"definitifs","lemma":"définitif","pos":"ADJ"} ,
		{"word":"définitive","word_nosc":"definitive","lemma":"définitif","pos":"ADJ"} ,
		{"word":"définitives","word_nosc":"definitives","lemma":"définitif","pos":"ADJ"} ,
		{"word":"déflagrante","word_nosc":"deflagrante","lemma":"déflagrant","pos":"ADJ"} ,
		{"word":"déflationniste","word_nosc":"deflationniste","lemma":"déflationniste","pos":"ADJ"} ,
		{"word":"déflationnistes","word_nosc":"deflationnistes","lemma":"déflationniste","pos":"ADJ"} ,
		{"word":"déflecteur","word_nosc":"deflecteur","lemma":"déflecteur","pos":"ADJ"} ,
		{"word":"défoncé","word_nosc":"defonce","lemma":"défoncé","pos":"ADJ"} ,
		{"word":"défoncée","word_nosc":"defoncee","lemma":"défoncé","pos":"ADJ"} ,
		{"word":"défoncées","word_nosc":"defoncees","lemma":"défoncé","pos":"ADJ"} ,
		{"word":"défoncés","word_nosc":"defonces","lemma":"défoncé","pos":"ADJ"} ,
		{"word":"déformant","word_nosc":"deformant","lemma":"déformant","pos":"ADJ"} ,
		{"word":"déformante","word_nosc":"deformante","lemma":"déformant","pos":"ADJ"} ,
		{"word":"déformantes","word_nosc":"deformantes","lemma":"déformant","pos":"ADJ"} ,
		{"word":"déformants","word_nosc":"deformants","lemma":"déformant","pos":"ADJ"} ,
		{"word":"déformé","word_nosc":"deforme","lemma":"déformé","pos":"ADJ"} ,
		{"word":"déformée","word_nosc":"deformee","lemma":"déformé","pos":"ADJ"} ,
		{"word":"déformées","word_nosc":"deformees","lemma":"déformé","pos":"ADJ"} ,
		{"word":"déformés","word_nosc":"deformes","lemma":"déformé","pos":"ADJ"} ,
		{"word":"défraîchi","word_nosc":"defraichi","lemma":"défraîchi","pos":"ADJ"} ,
		{"word":"défraîchie","word_nosc":"defraichie","lemma":"défraîchi","pos":"ADJ"} ,
		{"word":"défraîchies","word_nosc":"defraichies","lemma":"défraîchi","pos":"ADJ"} ,
		{"word":"défraîchis","word_nosc":"defraichis","lemma":"défraîchi","pos":"ADJ"} ,
		{"word":"défroqué","word_nosc":"defroque","lemma":"défroqué","pos":"ADJ"} ,
		{"word":"défroquée","word_nosc":"defroquee","lemma":"défroqué","pos":"ADJ"} ,
		{"word":"défroqués","word_nosc":"defroques","lemma":"défroqué","pos":"ADJ"} ,
		{"word":"défunt","word_nosc":"defunt","lemma":"défunt","pos":"ADJ"} ,
		{"word":"défunte","word_nosc":"defunte","lemma":"défunt","pos":"ADJ"} ,
		{"word":"défuntes","word_nosc":"defuntes","lemma":"défunt","pos":"ADJ"} ,
		{"word":"défunts","word_nosc":"defunts","lemma":"défunt","pos":"ADJ"} ,
		{"word":"défécatoire","word_nosc":"defecatoire","lemma":"défécatoire","pos":"ADJ"} ,
		{"word":"déférent","word_nosc":"deferent","lemma":"déférent","pos":"ADJ"} ,
		{"word":"déférente","word_nosc":"deferente","lemma":"déférent","pos":"ADJ"} ,
		{"word":"déférentes","word_nosc":"deferentes","lemma":"déférent","pos":"ADJ"} ,
		{"word":"déférents","word_nosc":"deferents","lemma":"déférent","pos":"ADJ"} ,
		{"word":"dégagé","word_nosc":"degage","lemma":"dégagé","pos":"ADJ"} ,
		{"word":"dégagée","word_nosc":"degagee","lemma":"dégagé","pos":"ADJ"} ,
		{"word":"dégagées","word_nosc":"degagees","lemma":"dégagé","pos":"ADJ"} ,
		{"word":"dégagés","word_nosc":"degages","lemma":"dégagé","pos":"ADJ"} ,
		{"word":"dégarni","word_nosc":"degarni","lemma":"dégarni","pos":"ADJ"} ,
		{"word":"dégarnie","word_nosc":"degarnie","lemma":"dégarni","pos":"ADJ"} ,
		{"word":"dégarnies","word_nosc":"degarnies","lemma":"dégarni","pos":"ADJ"} ,
		{"word":"dégingandé","word_nosc":"degingande","lemma":"dégingandé","pos":"ADJ"} ,
		{"word":"dégingandée","word_nosc":"degingandee","lemma":"dégingandé","pos":"ADJ"} ,
		{"word":"dégingandées","word_nosc":"degingandees","lemma":"dégingandé","pos":"ADJ"} ,
		{"word":"dégingandés","word_nosc":"degingandes","lemma":"dégingandé","pos":"ADJ"} ,
		{"word":"déglingué","word_nosc":"deglingue","lemma":"déglingué","pos":"ADJ"} ,
		{"word":"déglinguée","word_nosc":"deglinguee","lemma":"déglingué","pos":"ADJ"} ,
		{"word":"déglinguées","word_nosc":"deglinguees","lemma":"déglingué","pos":"ADJ"} ,
		{"word":"déglingués","word_nosc":"deglingues","lemma":"déglingué","pos":"ADJ"} ,
		{"word":"dégonflé","word_nosc":"degonfle","lemma":"dégonflé","pos":"ADJ"} ,
		{"word":"dégonflée","word_nosc":"degonflee","lemma":"dégonflé","pos":"ADJ"} ,
		{"word":"dégonflées","word_nosc":"degonflees","lemma":"dégonflé","pos":"ADJ"} ,
		{"word":"dégonflés","word_nosc":"degonfles","lemma":"dégonflé","pos":"ADJ"} ,
		{"word":"dégoulinant","word_nosc":"degoulinant","lemma":"dégoulinant","pos":"ADJ"} ,
		{"word":"dégoulinante","word_nosc":"degoulinante","lemma":"dégoulinant","pos":"ADJ"} ,
		{"word":"dégoulinantes","word_nosc":"degoulinantes","lemma":"dégoulinant","pos":"ADJ"} ,
		{"word":"dégoulinants","word_nosc":"degoulinants","lemma":"dégoulinant","pos":"ADJ"} ,
		{"word":"dégourdi","word_nosc":"degourdi","lemma":"dégourdi","pos":"ADJ"} ,
		{"word":"dégourdie","word_nosc":"degourdie","lemma":"dégourdi","pos":"ADJ"} ,
		{"word":"dégourdies","word_nosc":"degourdies","lemma":"dégourdi","pos":"ADJ"} ,
		{"word":"dégourdis","word_nosc":"degourdis","lemma":"dégourdi","pos":"ADJ"} ,
		{"word":"dégouttant","word_nosc":"degouttant","lemma":"dégouttant","pos":"ADJ"} ,
		{"word":"dégouttante","word_nosc":"degouttante","lemma":"dégouttant","pos":"ADJ"} ,
		{"word":"dégouttantes","word_nosc":"degouttantes","lemma":"dégouttant","pos":"ADJ"} ,
		{"word":"dégouttants","word_nosc":"degouttants","lemma":"dégouttant","pos":"ADJ"} ,
		{"word":"dégoûtant","word_nosc":"degoutant","lemma":"dégoûtant","pos":"ADJ"} ,
		{"word":"dégoûtante","word_nosc":"degoutante","lemma":"dégoûtant","pos":"ADJ"} ,
		{"word":"dégoûtantes","word_nosc":"degoutantes","lemma":"dégoûtant","pos":"ADJ"} ,
		{"word":"dégoûtants","word_nosc":"degoutants","lemma":"dégoûtant","pos":"ADJ"} ,
		{"word":"dégoûté","word_nosc":"degoute","lemma":"dégoûté","pos":"ADJ"} ,
		{"word":"dégoûtée","word_nosc":"degoutee","lemma":"dégoûté","pos":"ADJ"} ,
		{"word":"dégoûtées","word_nosc":"degoutees","lemma":"dégoûté","pos":"ADJ"} ,
		{"word":"dégoûtés","word_nosc":"degoutes","lemma":"dégoûté","pos":"ADJ"} ,
		{"word":"dégradable","word_nosc":"degradable","lemma":"dégradable","pos":"ADJ"} ,
		{"word":"dégradant","word_nosc":"degradant","lemma":"dégradant","pos":"ADJ"} ,
		{"word":"dégradante","word_nosc":"degradante","lemma":"dégradant","pos":"ADJ"} ,
		{"word":"dégradantes","word_nosc":"degradantes","lemma":"dégradant","pos":"ADJ"} ,
		{"word":"dégradants","word_nosc":"degradants","lemma":"dégradant","pos":"ADJ"} ,
		{"word":"dégradé","word_nosc":"degrade","lemma":"dégradé","pos":"ADJ"} ,
		{"word":"dégradée","word_nosc":"degradee","lemma":"dégradé","pos":"ADJ"} ,
		{"word":"dégradées","word_nosc":"degradees","lemma":"dégradé","pos":"ADJ"} ,
		{"word":"dégradés","word_nosc":"degrades","lemma":"dégradé","pos":"ADJ"} ,
		{"word":"dégraissante","word_nosc":"degraissante","lemma":"dégraissant","pos":"ADJ"} ,
		{"word":"dégressifs","word_nosc":"degressifs","lemma":"dégressif","pos":"ADJ"} ,
		{"word":"dégriffé","word_nosc":"degriffe","lemma":"dégriffé","pos":"ADJ"} ,
		{"word":"dégriffés","word_nosc":"degriffes","lemma":"dégriffé","pos":"ADJ"} ,
		{"word":"dégrossi","word_nosc":"degrossi","lemma":"dégrossi","pos":"ADJ"} ,
		{"word":"dégrossie","word_nosc":"degrossie","lemma":"dégrossi","pos":"ADJ"} ,
		{"word":"dégrossies","word_nosc":"degrossies","lemma":"dégrossi","pos":"ADJ"} ,
		{"word":"dégrossis","word_nosc":"degrossis","lemma":"dégrossi","pos":"ADJ"} ,
		{"word":"déguenillé","word_nosc":"deguenille","lemma":"déguenillé","pos":"ADJ"} ,
		{"word":"déguenillée","word_nosc":"deguenillee","lemma":"déguenillé","pos":"ADJ"} ,
		{"word":"déguenillées","word_nosc":"deguenillees","lemma":"déguenillé","pos":"ADJ"} ,
		{"word":"déguenillés","word_nosc":"deguenilles","lemma":"déguenillé","pos":"ADJ"} ,
		{"word":"dégueu","word_nosc":"degueu","lemma":"dégueu","pos":"ADJ"} ,
		{"word":"dégueulasse","word_nosc":"degueulasse","lemma":"dégueulasse","pos":"ADJ"} ,
		{"word":"dégueulasses","word_nosc":"degueulasses","lemma":"dégueulasse","pos":"ADJ"} ,
		{"word":"dégueus","word_nosc":"degueus","lemma":"dégueu","pos":"ADJ"} ,
		{"word":"déguisé","word_nosc":"deguise","lemma":"déguisé","pos":"ADJ"} ,
		{"word":"déguisée","word_nosc":"deguisee","lemma":"déguisé","pos":"ADJ"} ,
		{"word":"déguisées","word_nosc":"deguisees","lemma":"déguisé","pos":"ADJ"} ,
		{"word":"déguisés","word_nosc":"deguises","lemma":"déguisé","pos":"ADJ"} ,
		{"word":"dégénératif","word_nosc":"degeneratif","lemma":"dégénératif","pos":"ADJ"} ,
		{"word":"dégénérative","word_nosc":"degenerative","lemma":"dégénératif","pos":"ADJ"} ,
		{"word":"dégénéré","word_nosc":"degenere","lemma":"dégénéré","pos":"ADJ"} ,
		{"word":"dégénérée","word_nosc":"degeneree","lemma":"dégénéré","pos":"ADJ"} ,
		{"word":"dégénérées","word_nosc":"degenerees","lemma":"dégénéré","pos":"ADJ"} ,
		{"word":"dégénérés","word_nosc":"degeneres","lemma":"dégénéré","pos":"ADJ"} ,
		{"word":"déhanché","word_nosc":"dehanche","lemma":"déhanché","pos":"ADJ"} ,
		{"word":"déhanchée","word_nosc":"dehanchee","lemma":"déhanché","pos":"ADJ"} ,
		{"word":"déhanchées","word_nosc":"dehanchees","lemma":"déhanché","pos":"ADJ"} ,
		{"word":"déhanchés","word_nosc":"dehanches","lemma":"déhanché","pos":"ADJ"} ,
		{"word":"déicide","word_nosc":"deicide","lemma":"déicide","pos":"ADJ"} ,
		{"word":"déicides","word_nosc":"deicides","lemma":"déicide","pos":"ADJ"} ,
		{"word":"déjanté","word_nosc":"dejante","lemma":"déjanté","pos":"ADJ"} ,
		{"word":"déjantés","word_nosc":"dejantes","lemma":"déjanté","pos":"ADJ"} ,
		{"word":"déjeté","word_nosc":"dejete","lemma":"déjeté","pos":"ADJ"} ,
		{"word":"déjetés","word_nosc":"dejetes","lemma":"déjeté","pos":"ADJ"} ,
		{"word":"délabré","word_nosc":"delabre","lemma":"délabré","pos":"ADJ"} ,
		{"word":"délabrée","word_nosc":"delabree","lemma":"délabré","pos":"ADJ"} ,
		{"word":"délabrées","word_nosc":"delabrees","lemma":"délabré","pos":"ADJ"} ,
		{"word":"délabrés","word_nosc":"delabres","lemma":"délabré","pos":"ADJ"} ,
		{"word":"délaissé","word_nosc":"delaisse","lemma":"délaissé","pos":"ADJ"} ,
		{"word":"délaissée","word_nosc":"delaissee","lemma":"délaissé","pos":"ADJ"} ,
		{"word":"délaissées","word_nosc":"delaissees","lemma":"délaissé","pos":"ADJ"} ,
		{"word":"délaissés","word_nosc":"delaisses","lemma":"délaissé","pos":"ADJ"} ,
		{"word":"délassant","word_nosc":"delassant","lemma":"délassant","pos":"ADJ"} ,
		{"word":"délassante","word_nosc":"delassante","lemma":"délassant","pos":"ADJ"} ,
		{"word":"délavé","word_nosc":"delave","lemma":"délavé","pos":"ADJ"} ,
		{"word":"délavée","word_nosc":"delavee","lemma":"délavé","pos":"ADJ"} ,
		{"word":"délavées","word_nosc":"delavees","lemma":"délavé","pos":"ADJ"} ,
		{"word":"délavés","word_nosc":"delaves","lemma":"délavé","pos":"ADJ"} ,
		{"word":"délayé","word_nosc":"delaye","lemma":"délayé","pos":"ADJ"} ,
		{"word":"délayée","word_nosc":"delayee","lemma":"délayé","pos":"ADJ"} ,
		{"word":"délectable","word_nosc":"delectable","lemma":"délectable","pos":"ADJ"} ,
		{"word":"délectables","word_nosc":"delectables","lemma":"délectable","pos":"ADJ"} ,
		{"word":"délibérative","word_nosc":"deliberative","lemma":"délibératif","pos":"ADJ"} ,
		{"word":"délibératoire","word_nosc":"deliberatoire","lemma":"délibératoire","pos":"ADJ"} ,
		{"word":"délibéré","word_nosc":"delibere","lemma":"délibéré","pos":"ADJ"} ,
		{"word":"délibérée","word_nosc":"deliberee","lemma":"délibéré","pos":"ADJ"} ,
		{"word":"délibérées","word_nosc":"deliberees","lemma":"délibéré","pos":"ADJ"} ,
		{"word":"délibérés","word_nosc":"deliberes","lemma":"délibéré","pos":"ADJ"} ,
		{"word":"délicat","word_nosc":"delicat","lemma":"délicat","pos":"ADJ"} ,
		{"word":"délicate","word_nosc":"delicate","lemma":"délicat","pos":"ADJ"} ,
		{"word":"délicates","word_nosc":"delicates","lemma":"délicat","pos":"ADJ"} ,
		{"word":"délicats","word_nosc":"delicats","lemma":"délicat","pos":"ADJ"} ,
		{"word":"délicieuse","word_nosc":"delicieuse","lemma":"délicieux","pos":"ADJ"} ,
		{"word":"délicieuses","word_nosc":"delicieuses","lemma":"délicieux","pos":"ADJ"} ,
		{"word":"délicieux","word_nosc":"delicieux","lemma":"délicieux","pos":"ADJ"} ,
		{"word":"délictuelle","word_nosc":"delictuelle","lemma":"délictuel","pos":"ADJ"} ,
		{"word":"délictueuse","word_nosc":"delictueuse","lemma":"délictueux","pos":"ADJ"} ,
		{"word":"délictueuses","word_nosc":"delictueuses","lemma":"délictueux","pos":"ADJ"} ,
		{"word":"délictueux","word_nosc":"delictueux","lemma":"délictueux","pos":"ADJ"} ,
		{"word":"délimitant","word_nosc":"delimitant","lemma":"délimitant","pos":"ADJ"} ,
		{"word":"délinquant","word_nosc":"delinquant","lemma":"délinquant","pos":"ADJ"} ,
		{"word":"délinquante","word_nosc":"delinquante","lemma":"délinquant","pos":"ADJ"} ,
		{"word":"délinquantes","word_nosc":"delinquantes","lemma":"délinquant","pos":"ADJ"} ,
		{"word":"délinquants","word_nosc":"delinquants","lemma":"délinquant","pos":"ADJ"} ,
		{"word":"déliquescent","word_nosc":"deliquescent","lemma":"déliquescent","pos":"ADJ"} ,
		{"word":"déliquescente","word_nosc":"deliquescente","lemma":"déliquescent","pos":"ADJ"} ,
		{"word":"déliquescents","word_nosc":"deliquescents","lemma":"déliquescent","pos":"ADJ"} ,
		{"word":"délirant","word_nosc":"delirant","lemma":"délirant","pos":"ADJ"} ,
		{"word":"délirante","word_nosc":"delirante","lemma":"délirant","pos":"ADJ"} ,
		{"word":"délirantes","word_nosc":"delirantes","lemma":"délirant","pos":"ADJ"} ,
		{"word":"délirants","word_nosc":"delirants","lemma":"délirant","pos":"ADJ"} ,
		{"word":"délié","word_nosc":"delie","lemma":"délié","pos":"ADJ"} ,
		{"word":"déliée","word_nosc":"deliee","lemma":"délié","pos":"ADJ"} ,
		{"word":"déliées","word_nosc":"deliees","lemma":"délié","pos":"ADJ"} ,
		{"word":"déliés","word_nosc":"delies","lemma":"délié","pos":"ADJ"} ,
		{"word":"déloyal","word_nosc":"deloyal","lemma":"déloyal","pos":"ADJ"} ,
		{"word":"déloyale","word_nosc":"deloyale","lemma":"déloyal","pos":"ADJ"} ,
		{"word":"déloyaux","word_nosc":"deloyaux","lemma":"déloyal","pos":"ADJ"} ,
		{"word":"déluré","word_nosc":"delure","lemma":"déluré","pos":"ADJ"} ,
		{"word":"délurée","word_nosc":"deluree","lemma":"déluré","pos":"ADJ"} ,
		{"word":"délurées","word_nosc":"delurees","lemma":"déluré","pos":"ADJ"} ,
		{"word":"délégué","word_nosc":"delegue","lemma":"délégué","pos":"ADJ"} ,
		{"word":"délégué-général","word_nosc":"delegue-general","lemma":"délégué-général","pos":"ADJ"} ,
		{"word":"déléguée","word_nosc":"deleguee","lemma":"délégué","pos":"ADJ"} ,
		{"word":"délégués","word_nosc":"delegues","lemma":"délégué","pos":"ADJ"} ,
		{"word":"délétère","word_nosc":"deletere","lemma":"délétère","pos":"ADJ"} ,
		{"word":"délétères","word_nosc":"deleteres","lemma":"délétère","pos":"ADJ"} ,
		{"word":"démago","word_nosc":"demago","lemma":"démago","pos":"ADJ"} ,
		{"word":"démagogique","word_nosc":"demagogique","lemma":"démagogique","pos":"ADJ"} ,
		{"word":"démagogiques","word_nosc":"demagogiques","lemma":"démagogique","pos":"ADJ"} ,
		{"word":"démagogue","word_nosc":"demagogue","lemma":"démagogue","pos":"ADJ"} ,
		{"word":"démagogues","word_nosc":"demagogues","lemma":"démagogue","pos":"ADJ"} ,
		{"word":"démaquillant","word_nosc":"demaquillant","lemma":"démaquillant","pos":"ADJ"} ,
		{"word":"démaquillante","word_nosc":"demaquillante","lemma":"démaquillant","pos":"ADJ"} ,
		{"word":"dément","word_nosc":"dement","lemma":"dément","pos":"ADJ"} ,
		{"word":"démente","word_nosc":"demente","lemma":"dément","pos":"ADJ"} ,
		{"word":"démentes","word_nosc":"dementes","lemma":"dément","pos":"ADJ"} ,
		{"word":"démenti","word_nosc":"dementi","lemma":"démenti","pos":"ADJ"} ,
		{"word":"démentie","word_nosc":"dementie","lemma":"démenti","pos":"ADJ"} ,
		{"word":"démentiel","word_nosc":"dementiel","lemma":"démentiel","pos":"ADJ"} ,
		{"word":"démentielle","word_nosc":"dementielle","lemma":"démentiel","pos":"ADJ"} ,
		{"word":"démentielles","word_nosc":"dementielles","lemma":"démentiel","pos":"ADJ"} ,
		{"word":"démentiels","word_nosc":"dementiels","lemma":"démentiel","pos":"ADJ"} ,
		{"word":"démenties","word_nosc":"dementies","lemma":"démenti","pos":"ADJ"} ,
		{"word":"démentis","word_nosc":"dementis","lemma":"démenti","pos":"ADJ"} ,
		{"word":"déments","word_nosc":"dements","lemma":"dément","pos":"ADJ"} ,
		{"word":"démerdard","word_nosc":"demerdard","lemma":"démerdard","pos":"ADJ"} ,
		{"word":"démesuré","word_nosc":"demesure","lemma":"démesuré","pos":"ADJ"} ,
		{"word":"démesurée","word_nosc":"demesuree","lemma":"démesuré","pos":"ADJ"} ,
		{"word":"démesurées","word_nosc":"demesurees","lemma":"démesuré","pos":"ADJ"} ,
		{"word":"démesurés","word_nosc":"demesures","lemma":"démesuré","pos":"ADJ"} ,
		{"word":"démis","word_nosc":"demis","lemma":"démis","pos":"ADJ"} ,
		{"word":"démise","word_nosc":"demise","lemma":"démis","pos":"ADJ"} ,
		{"word":"démises","word_nosc":"demises","lemma":"démis","pos":"ADJ"} ,
		{"word":"démissionnaire","word_nosc":"demissionnaire","lemma":"démissionnaire","pos":"ADJ"} ,
		{"word":"démissionnaires","word_nosc":"demissionnaires","lemma":"démissionnaire","pos":"ADJ"} ,
		{"word":"démobilisateur","word_nosc":"demobilisateur","lemma":"démobilisateur","pos":"ADJ"} ,
		{"word":"démobilisé","word_nosc":"demobilise","lemma":"démobilisé","pos":"ADJ"} ,
		{"word":"démobilisés","word_nosc":"demobilises","lemma":"démobilisé","pos":"ADJ"} ,
		{"word":"démocrate","word_nosc":"democrate","lemma":"démocrate","pos":"ADJ"} ,
		{"word":"démocrate-chrétien","word_nosc":"democrate-chretien","lemma":"démocrate-chrétien","pos":"ADJ"} ,
		{"word":"démocrates","word_nosc":"democrates","lemma":"démocrate","pos":"ADJ"} ,
		{"word":"démocrates-chrétiens","word_nosc":"democrates-chretiens","lemma":"démocrate-chrétien","pos":"ADJ"} ,
		{"word":"démocratique","word_nosc":"democratique","lemma":"démocratique","pos":"ADJ"} ,
		{"word":"démocratiques","word_nosc":"democratiques","lemma":"démocratique","pos":"ADJ"} ,
		{"word":"démodé","word_nosc":"demode","lemma":"démodé","pos":"ADJ"} ,
		{"word":"démodée","word_nosc":"demodee","lemma":"démodé","pos":"ADJ"} ,
		{"word":"démodées","word_nosc":"demodees","lemma":"démodé","pos":"ADJ"} ,
		{"word":"démodés","word_nosc":"demodes","lemma":"démodé","pos":"ADJ"} ,
		{"word":"démographique","word_nosc":"demographique","lemma":"démographique","pos":"ADJ"} ,
		{"word":"démographiques","word_nosc":"demographiques","lemma":"démographique","pos":"ADJ"} ,
		{"word":"démoniaque","word_nosc":"demoniaque","lemma":"démoniaque","pos":"ADJ"} ,
		{"word":"démoniaques","word_nosc":"demoniaques","lemma":"démoniaque","pos":"ADJ"} ,
		{"word":"démonique","word_nosc":"demonique","lemma":"démonique","pos":"ADJ"} ,
		{"word":"démonstratif","word_nosc":"demonstratif","lemma":"démonstratif","pos":"ADJ"} ,
		{"word":"démonstratifs","word_nosc":"demonstratifs","lemma":"démonstratif","pos":"ADJ"} ,
		{"word":"démonstrative","word_nosc":"demonstrative","lemma":"démonstratif","pos":"ADJ"} ,
		{"word":"démontable","word_nosc":"demontable","lemma":"démontable","pos":"ADJ"} ,
		{"word":"démontables","word_nosc":"demontables","lemma":"démontable","pos":"ADJ"} ,
		{"word":"démontrable","word_nosc":"demontrable","lemma":"démontrable","pos":"ADJ"} ,
		{"word":"démonté","word_nosc":"demonte","lemma":"démonté","pos":"ADJ"} ,
		{"word":"démontée","word_nosc":"demontee","lemma":"démonté","pos":"ADJ"} ,
		{"word":"démontées","word_nosc":"demontees","lemma":"démonté","pos":"ADJ"} ,
		{"word":"démontés","word_nosc":"demontes","lemma":"démonté","pos":"ADJ"} ,
		{"word":"démoralisant","word_nosc":"demoralisant","lemma":"démoralisant","pos":"ADJ"} ,
		{"word":"démoralisante","word_nosc":"demoralisante","lemma":"démoralisant","pos":"ADJ"} ,
		{"word":"démoralisants","word_nosc":"demoralisants","lemma":"démoralisant","pos":"ADJ"} ,
		{"word":"démoralisateurs","word_nosc":"demoralisateurs","lemma":"démoralisateur","pos":"ADJ"} ,
		{"word":"démoralisé","word_nosc":"demoralise","lemma":"démoralisé","pos":"ADJ"} ,
		{"word":"démoralisée","word_nosc":"demoralisee","lemma":"démoralisé","pos":"ADJ"} ,
		{"word":"démoralisées","word_nosc":"demoralisees","lemma":"démoralisé","pos":"ADJ"} ,
		{"word":"démoralisés","word_nosc":"demoralises","lemma":"démoralisé","pos":"ADJ"} ,
		{"word":"démotique","word_nosc":"demotique","lemma":"démotique","pos":"ADJ"} ,
		{"word":"démuni","word_nosc":"demuni","lemma":"démuni","pos":"ADJ"} ,
		{"word":"démunie","word_nosc":"demunie","lemma":"démuni","pos":"ADJ"} ,
		{"word":"démunies","word_nosc":"demunies","lemma":"démuni","pos":"ADJ"} ,
		{"word":"démunis","word_nosc":"demunis","lemma":"démuni","pos":"ADJ"} ,
		{"word":"démystificatrice","word_nosc":"demystificatrice","lemma":"démystificateur","pos":"ADJ"} ,
		{"word":"démêlant","word_nosc":"demelant","lemma":"démêlant","pos":"ADJ"} ,
		{"word":"démêlés","word_nosc":"demeles","lemma":"démêlé","pos":"ADJ"} ,
		{"word":"dénaturé","word_nosc":"denature","lemma":"dénaturé","pos":"ADJ"} ,
		{"word":"dénaturée","word_nosc":"denaturee","lemma":"dénaturé","pos":"ADJ"} ,
		{"word":"dénaturées","word_nosc":"denaturees","lemma":"dénaturé","pos":"ADJ"} ,
		{"word":"dénaturés","word_nosc":"denatures","lemma":"dénaturé","pos":"ADJ"} ,
		{"word":"dénigrant","word_nosc":"denigrant","lemma":"dénigrant","pos":"ADJ"} ,
		{"word":"dénigrante","word_nosc":"denigrante","lemma":"dénigrant","pos":"ADJ"} ,
		{"word":"dénivelé","word_nosc":"denivele","lemma":"dénivelé","pos":"ADJ"} ,
		{"word":"dénivelés","word_nosc":"deniveles","lemma":"dénivelé","pos":"ADJ"} ,
		{"word":"dénombrables","word_nosc":"denombrables","lemma":"dénombrable","pos":"ADJ"} ,
		{"word":"dénominatif","word_nosc":"denominatif","lemma":"dénominatif","pos":"ADJ"} ,
		{"word":"dénommé","word_nosc":"denomme","lemma":"dénommé","pos":"ADJ"} ,
		{"word":"dénommée","word_nosc":"denommee","lemma":"dénommé","pos":"ADJ"} ,
		{"word":"dénonciatrice","word_nosc":"denonciatrice","lemma":"dénonciateur","pos":"ADJ"} ,
		{"word":"dénudé","word_nosc":"denude","lemma":"dénudé","pos":"ADJ"} ,
		{"word":"dénudée","word_nosc":"denudee","lemma":"dénudé","pos":"ADJ"} ,
		{"word":"dénudées","word_nosc":"denudees","lemma":"dénudé","pos":"ADJ"} ,
		{"word":"dénudés","word_nosc":"denudes","lemma":"dénudé","pos":"ADJ"} ,
		{"word":"dénué","word_nosc":"denue","lemma":"dénué","pos":"ADJ"} ,
		{"word":"dénuée","word_nosc":"denuee","lemma":"dénué","pos":"ADJ"} ,
		{"word":"dénués","word_nosc":"denues","lemma":"dénué","pos":"ADJ"} ,
		{"word":"dénégateurs","word_nosc":"denegateurs","lemma":"dénégateur","pos":"ADJ"} ,
		{"word":"déodorant","word_nosc":"deodorant","lemma":"déodorant","pos":"ADJ"} ,
		{"word":"déodorants","word_nosc":"deodorants","lemma":"déodorant","pos":"ADJ"} ,
		{"word":"déontologique","word_nosc":"deontologique","lemma":"déontologique","pos":"ADJ"} ,
		{"word":"déontologiques","word_nosc":"deontologiques","lemma":"déontologique","pos":"ADJ"} ,
		{"word":"dépareillé","word_nosc":"depareille","lemma":"dépareillé","pos":"ADJ"} ,
		{"word":"dépareillée","word_nosc":"depareillee","lemma":"dépareillé","pos":"ADJ"} ,
		{"word":"dépareillées","word_nosc":"depareillees","lemma":"dépareillé","pos":"ADJ"} ,
		{"word":"dépareillés","word_nosc":"depareilles","lemma":"dépareillé","pos":"ADJ"} ,
		{"word":"départemental","word_nosc":"departemental","lemma":"départemental","pos":"ADJ"} ,
		{"word":"départementale","word_nosc":"departementale","lemma":"départemental","pos":"ADJ"} ,
		{"word":"départementales","word_nosc":"departementales","lemma":"départemental","pos":"ADJ"} ,
		{"word":"départementaux","word_nosc":"departementaux","lemma":"départemental","pos":"ADJ"} ,
		{"word":"dépassé","word_nosc":"depasse","lemma":"dépassé","pos":"ADJ"} ,
		{"word":"dépassée","word_nosc":"depassee","lemma":"dépassé","pos":"ADJ"} ,
		{"word":"dépassées","word_nosc":"depassees","lemma":"dépassé","pos":"ADJ"} ,
		{"word":"dépassés","word_nosc":"depasses","lemma":"dépassé","pos":"ADJ"} ,
		{"word":"dépaysant","word_nosc":"depaysant","lemma":"dépaysant","pos":"ADJ"} ,
		{"word":"dépaysante","word_nosc":"depaysante","lemma":"dépaysant","pos":"ADJ"} ,
		{"word":"dépaysantes","word_nosc":"depaysantes","lemma":"dépaysant","pos":"ADJ"} ,
		{"word":"dépeint","word_nosc":"depeint","lemma":"dépeint","pos":"ADJ"} ,
		{"word":"dépeinte","word_nosc":"depeinte","lemma":"dépeint","pos":"ADJ"} ,
		{"word":"dépeintes","word_nosc":"depeintes","lemma":"dépeint","pos":"ADJ"} ,
		{"word":"dépeints","word_nosc":"depeints","lemma":"dépeint","pos":"ADJ"} ,
		{"word":"dépenaillé","word_nosc":"depenaille","lemma":"dépenaillé","pos":"ADJ"} ,
		{"word":"dépenaillée","word_nosc":"depenaillee","lemma":"dépenaillé","pos":"ADJ"} ,
		{"word":"dépenaillées","word_nosc":"depenaillees","lemma":"dépenaillé","pos":"ADJ"} ,
		{"word":"dépenaillés","word_nosc":"depenailles","lemma":"dépenaillé","pos":"ADJ"} ,
		{"word":"dépendant","word_nosc":"dependant","lemma":"dépendant","pos":"ADJ"} ,
		{"word":"dépendante","word_nosc":"dependante","lemma":"dépendant","pos":"ADJ"} ,
		{"word":"dépendantes","word_nosc":"dependantes","lemma":"dépendant","pos":"ADJ"} ,
		{"word":"dépendants","word_nosc":"dependants","lemma":"dépendant","pos":"ADJ"} ,
		{"word":"dépensier","word_nosc":"depensier","lemma":"dépensier","pos":"ADJ"} ,
		{"word":"dépensiers","word_nosc":"depensiers","lemma":"dépensier","pos":"ADJ"} ,
		{"word":"dépensière","word_nosc":"depensiere","lemma":"dépensier","pos":"ADJ"} ,
		{"word":"dépersonnalisée","word_nosc":"depersonnalisee","lemma":"dépersonnalisé","pos":"ADJ"} ,
		{"word":"dépeuplé","word_nosc":"depeuple","lemma":"dépeuplé","pos":"ADJ"} ,
		{"word":"dépeuplée","word_nosc":"depeuplee","lemma":"dépeuplé","pos":"ADJ"} ,
		{"word":"dépeuplées","word_nosc":"depeuplees","lemma":"dépeuplé","pos":"ADJ"} ,
		{"word":"dépeuplés","word_nosc":"depeuples","lemma":"dépeuplé","pos":"ADJ"} ,
		{"word":"déphasé","word_nosc":"dephase","lemma":"déphasé","pos":"ADJ"} ,
		{"word":"déphasée","word_nosc":"dephasee","lemma":"déphasé","pos":"ADJ"} ,
		{"word":"déphasés","word_nosc":"dephases","lemma":"déphasé","pos":"ADJ"} ,
		{"word":"dépilatoire","word_nosc":"depilatoire","lemma":"dépilatoire","pos":"ADJ"} ,
		{"word":"dépité","word_nosc":"depite","lemma":"dépité","pos":"ADJ"} ,
		{"word":"dépitée","word_nosc":"depitee","lemma":"dépité","pos":"ADJ"} ,
		{"word":"dépités","word_nosc":"depites","lemma":"dépité","pos":"ADJ"} ,
		{"word":"déplacé","word_nosc":"deplace","lemma":"déplacé","pos":"ADJ"} ,
		{"word":"déplacée","word_nosc":"deplacee","lemma":"déplacé","pos":"ADJ"} ,
		{"word":"déplacées","word_nosc":"deplacees","lemma":"déplacé","pos":"ADJ"} ,
		{"word":"déplacés","word_nosc":"deplaces","lemma":"déplacé","pos":"ADJ"} ,
		{"word":"déplaisant","word_nosc":"deplaisant","lemma":"déplaisant","pos":"ADJ"} ,
		{"word":"déplaisante","word_nosc":"deplaisante","lemma":"déplaisant","pos":"ADJ"} ,
		{"word":"déplaisantes","word_nosc":"deplaisantes","lemma":"déplaisant","pos":"ADJ"} ,
		{"word":"déplaisants","word_nosc":"deplaisants","lemma":"déplaisant","pos":"ADJ"} ,
		{"word":"dépliable","word_nosc":"depliable","lemma":"dépliable","pos":"ADJ"} ,
		{"word":"dépliant","word_nosc":"depliant","lemma":"dépliant","pos":"ADJ"} ,
		{"word":"dépliante","word_nosc":"depliante","lemma":"dépliant","pos":"ADJ"} ,
		{"word":"dépliants","word_nosc":"depliants","lemma":"dépliant","pos":"ADJ"} ,
		{"word":"déplorable","word_nosc":"deplorable","lemma":"déplorable","pos":"ADJ"} ,
		{"word":"déplorables","word_nosc":"deplorables","lemma":"déplorable","pos":"ADJ"} ,
		{"word":"déployé","word_nosc":"deploye","lemma":"déployé","pos":"ADJ"} ,
		{"word":"déployée","word_nosc":"deployee","lemma":"déployé","pos":"ADJ"} ,
		{"word":"déployées","word_nosc":"deployees","lemma":"déployé","pos":"ADJ"} ,
		{"word":"déployés","word_nosc":"deployes","lemma":"déployé","pos":"ADJ"} ,
		{"word":"déplumé","word_nosc":"deplume","lemma":"déplumé","pos":"ADJ"} ,
		{"word":"déplumée","word_nosc":"deplumee","lemma":"déplumé","pos":"ADJ"} ,
		{"word":"déplumés","word_nosc":"deplumes","lemma":"déplumé","pos":"ADJ"} ,
		{"word":"dépoitraillé","word_nosc":"depoitraille","lemma":"dépoitraillé","pos":"ADJ"} ,
		{"word":"dépoitraillée","word_nosc":"depoitraillee","lemma":"dépoitraillé","pos":"ADJ"} ,
		{"word":"dépoitraillées","word_nosc":"depoitraillees","lemma":"dépoitraillé","pos":"ADJ"} ,
		{"word":"dépoitraillés","word_nosc":"depoitrailles","lemma":"dépoitraillé","pos":"ADJ"} ,
		{"word":"dépoli","word_nosc":"depoli","lemma":"dépoli","pos":"ADJ"} ,
		{"word":"dépolie","word_nosc":"depolie","lemma":"dépoli","pos":"ADJ"} ,
		{"word":"dépolies","word_nosc":"depolies","lemma":"dépoli","pos":"ADJ"} ,
		{"word":"dépolis","word_nosc":"depolis","lemma":"dépoli","pos":"ADJ"} ,
		{"word":"déporté","word_nosc":"deporte","lemma":"déporté","pos":"ADJ"} ,
		{"word":"déportée","word_nosc":"deportee","lemma":"déporté","pos":"ADJ"} ,
		{"word":"déportées","word_nosc":"deportees","lemma":"déporté","pos":"ADJ"} ,
		{"word":"déportés","word_nosc":"deportes","lemma":"déporté","pos":"ADJ"} ,
		{"word":"déposant","word_nosc":"deposant","lemma":"déposant","pos":"ADJ"} ,
		{"word":"dépouillé","word_nosc":"depouille","lemma":"dépouillé","pos":"ADJ"} ,
		{"word":"dépouillée","word_nosc":"depouillee","lemma":"dépouillé","pos":"ADJ"} ,
		{"word":"dépouillées","word_nosc":"depouillees","lemma":"dépouillé","pos":"ADJ"} ,
		{"word":"dépouillés","word_nosc":"depouilles","lemma":"dépouillé","pos":"ADJ"} ,
		{"word":"dépourvu","word_nosc":"depourvu","lemma":"dépourvu","pos":"ADJ"} ,
		{"word":"dépourvue","word_nosc":"depourvue","lemma":"dépourvu","pos":"ADJ"} ,
		{"word":"dépourvues","word_nosc":"depourvues","lemma":"dépourvu","pos":"ADJ"} ,
		{"word":"dépourvus","word_nosc":"depourvus","lemma":"dépourvu","pos":"ADJ"} ,
		{"word":"dépravé","word_nosc":"deprave","lemma":"dépravé","pos":"ADJ"} ,
		{"word":"dépravée","word_nosc":"depravee","lemma":"dépravé","pos":"ADJ"} ,
		{"word":"dépravées","word_nosc":"depravees","lemma":"dépravé","pos":"ADJ"} ,
		{"word":"dépravés","word_nosc":"depraves","lemma":"dépravé","pos":"ADJ"} ,
		{"word":"dépressif","word_nosc":"depressif","lemma":"dépressif","pos":"ADJ"} ,
		{"word":"dépressifs","word_nosc":"depressifs","lemma":"dépressif","pos":"ADJ"} ,
		{"word":"dépressionnaire","word_nosc":"depressionnaire","lemma":"dépressionnaire","pos":"ADJ"} ,
		{"word":"dépressive","word_nosc":"depressive","lemma":"dépressif","pos":"ADJ"} ,
		{"word":"dépressives","word_nosc":"depressives","lemma":"dépressif","pos":"ADJ"} ,
		{"word":"déprimant","word_nosc":"deprimant","lemma":"déprimant","pos":"ADJ"} ,
		{"word":"déprimante","word_nosc":"deprimante","lemma":"déprimant","pos":"ADJ"} ,
		{"word":"déprimantes","word_nosc":"deprimantes","lemma":"déprimant","pos":"ADJ"} ,
		{"word":"déprimants","word_nosc":"deprimants","lemma":"déprimant","pos":"ADJ"} ,
		{"word":"déprimé","word_nosc":"deprime","lemma":"déprimé","pos":"ADJ"} ,
		{"word":"déprimée","word_nosc":"deprimee","lemma":"déprimé","pos":"ADJ"} ,
		{"word":"déprimées","word_nosc":"deprimees","lemma":"déprimé","pos":"ADJ"} ,
		{"word":"déprimés","word_nosc":"deprimes","lemma":"déprimé","pos":"ADJ"} ,
		{"word":"déprédateur","word_nosc":"depredateur","lemma":"déprédateur","pos":"ADJ"} ,
		{"word":"dépuratifs","word_nosc":"depuratifs","lemma":"dépuratif","pos":"ADJ"} ,
		{"word":"déracinant","word_nosc":"deracinant","lemma":"déracinant","pos":"ADJ"} ,
		{"word":"déraciné","word_nosc":"deracine","lemma":"déraciné","pos":"ADJ"} ,
		{"word":"déracinée","word_nosc":"deracinee","lemma":"déraciné","pos":"ADJ"} ,
		{"word":"déracinées","word_nosc":"deracinees","lemma":"déraciné","pos":"ADJ"} ,
		{"word":"déracinés","word_nosc":"deracines","lemma":"déraciné","pos":"ADJ"} ,
		{"word":"déraisonnable","word_nosc":"deraisonnable","lemma":"déraisonnable","pos":"ADJ"} ,
		{"word":"déraisonnables","word_nosc":"deraisonnables","lemma":"déraisonnable","pos":"ADJ"} ,
		{"word":"dérangeant","word_nosc":"derangeant","lemma":"dérangeant","pos":"ADJ"} ,
		{"word":"dérangeante","word_nosc":"derangeante","lemma":"dérangeant","pos":"ADJ"} ,
		{"word":"dérangeantes","word_nosc":"derangeantes","lemma":"dérangeant","pos":"ADJ"} ,
		{"word":"dérangeants","word_nosc":"derangeants","lemma":"dérangeant","pos":"ADJ"} ,
		{"word":"dérangé","word_nosc":"derange","lemma":"dérangé","pos":"ADJ"} ,
		{"word":"dérangée","word_nosc":"derangee","lemma":"dérangé","pos":"ADJ"} ,
		{"word":"dérangées","word_nosc":"derangees","lemma":"dérangé","pos":"ADJ"} ,
		{"word":"dérangés","word_nosc":"deranges","lemma":"dérangé","pos":"ADJ"} ,
		{"word":"dérisoire","word_nosc":"derisoire","lemma":"dérisoire","pos":"ADJ"} ,
		{"word":"dérisoires","word_nosc":"derisoires","lemma":"dérisoire","pos":"ADJ"} ,
		{"word":"dérivant","word_nosc":"derivant","lemma":"dérivant","pos":"ADJ"} ,
		{"word":"dérivante","word_nosc":"derivante","lemma":"dérivant","pos":"ADJ"} ,
		{"word":"dérivé","word_nosc":"derive","lemma":"dérivé","pos":"ADJ"} ,
		{"word":"dérivée","word_nosc":"derivee","lemma":"dérivé","pos":"ADJ"} ,
		{"word":"dérivées","word_nosc":"derivees","lemma":"dérivé","pos":"ADJ"} ,
		{"word":"dérivés","word_nosc":"derives","lemma":"dérivé","pos":"ADJ"} ,
		{"word":"dérobeuse","word_nosc":"derobeuse","lemma":"dérobeur","pos":"ADJ"} ,
		{"word":"dérobé","word_nosc":"derobe","lemma":"dérobé","pos":"ADJ"} ,
		{"word":"dérobée","word_nosc":"derobee","lemma":"dérobé","pos":"ADJ"} ,
		{"word":"dérobées","word_nosc":"derobees","lemma":"dérobé","pos":"ADJ"} ,
		{"word":"dérobés","word_nosc":"derobes","lemma":"dérobé","pos":"ADJ"} ,
		{"word":"dérogatoire","word_nosc":"derogatoire","lemma":"dérogatoire","pos":"ADJ"} ,
		{"word":"déroulant","word_nosc":"deroulant","lemma":"déroulant","pos":"ADJ"} ,
		{"word":"déroutant","word_nosc":"deroutant","lemma":"déroutant","pos":"ADJ"} ,
		{"word":"déroutante","word_nosc":"deroutante","lemma":"déroutant","pos":"ADJ"} ,
		{"word":"déroutantes","word_nosc":"deroutantes","lemma":"déroutant","pos":"ADJ"} ,
		{"word":"déroutants","word_nosc":"deroutants","lemma":"déroutant","pos":"ADJ"} ,
		{"word":"déréglé","word_nosc":"deregle","lemma":"déréglé","pos":"ADJ"} ,
		{"word":"déréglée","word_nosc":"dereglee","lemma":"déréglé","pos":"ADJ"} ,
		{"word":"déréglées","word_nosc":"dereglees","lemma":"déréglé","pos":"ADJ"} ,
		{"word":"déréglés","word_nosc":"deregles","lemma":"déréglé","pos":"ADJ"} ,
		{"word":"désabusé","word_nosc":"desabuse","lemma":"désabusé","pos":"ADJ"} ,
		{"word":"désabusée","word_nosc":"desabusee","lemma":"désabusé","pos":"ADJ"} ,
		{"word":"désabusées","word_nosc":"desabusees","lemma":"désabusé","pos":"ADJ"} ,
		{"word":"désabusés","word_nosc":"desabuses","lemma":"désabusé","pos":"ADJ"} ,
		{"word":"désaffecté","word_nosc":"desaffecte","lemma":"désaffecté","pos":"ADJ"} ,
		{"word":"désaffectée","word_nosc":"desaffectee","lemma":"désaffecté","pos":"ADJ"} ,
		{"word":"désaffectées","word_nosc":"desaffectees","lemma":"désaffecté","pos":"ADJ"} ,
		{"word":"désaffectés","word_nosc":"desaffectes","lemma":"désaffecté","pos":"ADJ"} ,
		{"word":"désagréable","word_nosc":"desagreable","lemma":"désagréable","pos":"ADJ"} ,
		{"word":"désagréables","word_nosc":"desagreables","lemma":"désagréable","pos":"ADJ"} ,
		{"word":"désaltérante","word_nosc":"desalterante","lemma":"désaltérant","pos":"ADJ"} ,
		{"word":"désappointé","word_nosc":"desappointe","lemma":"désappointé","pos":"ADJ"} ,
		{"word":"désappointée","word_nosc":"desappointee","lemma":"désappointé","pos":"ADJ"} ,
		{"word":"désappointés","word_nosc":"desappointes","lemma":"désappointé","pos":"ADJ"} ,
		{"word":"désapprobateur","word_nosc":"desapprobateur","lemma":"désapprobateur","pos":"ADJ"} ,
		{"word":"désapprobateurs","word_nosc":"desapprobateurs","lemma":"désapprobateur","pos":"ADJ"} ,
		{"word":"désapprobatrice","word_nosc":"desapprobatrice","lemma":"désapprobateur","pos":"ADJ"} ,
		{"word":"désapprobatrices","word_nosc":"desapprobatrices","lemma":"désapprobateur","pos":"ADJ"} ,
		{"word":"désargenté","word_nosc":"desargente","lemma":"désargenté","pos":"ADJ"} ,
		{"word":"désargentées","word_nosc":"desargentees","lemma":"désargenté","pos":"ADJ"} ,
		{"word":"désargentés","word_nosc":"desargentes","lemma":"désargenté","pos":"ADJ"} ,
		{"word":"désarmant","word_nosc":"desarmant","lemma":"désarmant","pos":"ADJ"} ,
		{"word":"désarmante","word_nosc":"desarmante","lemma":"désarmant","pos":"ADJ"} ,
		{"word":"désarmants","word_nosc":"desarmants","lemma":"désarmant","pos":"ADJ"} ,
		{"word":"désarmé","word_nosc":"desarme","lemma":"désarmé","pos":"ADJ"} ,
		{"word":"désarmée","word_nosc":"desarmee","lemma":"désarmé","pos":"ADJ"} ,
		{"word":"désarmées","word_nosc":"desarmees","lemma":"désarmé","pos":"ADJ"} ,
		{"word":"désarmés","word_nosc":"desarmes","lemma":"désarmé","pos":"ADJ"} ,
		{"word":"désarçonné","word_nosc":"desarconne","lemma":"désarçonné","pos":"ADJ"} ,
		{"word":"désarçonnée","word_nosc":"desarconnee","lemma":"désarçonné","pos":"ADJ"} ,
		{"word":"désarçonnés","word_nosc":"desarconnes","lemma":"désarçonné","pos":"ADJ"} ,
		{"word":"désassortis","word_nosc":"desassortis","lemma":"désassorti","pos":"ADJ"} ,
		{"word":"désastreuse","word_nosc":"desastreuse","lemma":"désastreux","pos":"ADJ"} ,
		{"word":"désastreuses","word_nosc":"desastreuses","lemma":"désastreux","pos":"ADJ"} ,
		{"word":"désastreux","word_nosc":"desastreux","lemma":"désastreux","pos":"ADJ"} ,
		{"word":"désavantageuse","word_nosc":"desavantageuse","lemma":"désavantageux","pos":"ADJ"} ,
		{"word":"désaxé","word_nosc":"desaxe","lemma":"désaxé","pos":"ADJ"} ,
		{"word":"désaxée","word_nosc":"desaxee","lemma":"désaxé","pos":"ADJ"} ,
		{"word":"désaxés","word_nosc":"desaxes","lemma":"désaxé","pos":"ADJ"} ,
		{"word":"désemparé","word_nosc":"desempare","lemma":"désemparé","pos":"ADJ"} ,
		{"word":"désemparée","word_nosc":"desemparee","lemma":"désemparé","pos":"ADJ"} ,
		{"word":"désemparées","word_nosc":"desemparees","lemma":"désemparé","pos":"ADJ"} ,
		{"word":"désemparés","word_nosc":"desempares","lemma":"désemparé","pos":"ADJ"} ,
		{"word":"désenchanté","word_nosc":"desenchante","lemma":"désenchanté","pos":"ADJ"} ,
		{"word":"désenchantée","word_nosc":"desenchantee","lemma":"désenchanté","pos":"ADJ"} ,
		{"word":"désenchantées","word_nosc":"desenchantees","lemma":"désenchanté","pos":"ADJ"} ,
		{"word":"désert","word_nosc":"desert","lemma":"désert","pos":"ADJ"} ,
		{"word":"déserte","word_nosc":"deserte","lemma":"désert","pos":"ADJ"} ,
		{"word":"désertes","word_nosc":"desertes","lemma":"désert","pos":"ADJ"} ,
		{"word":"désertique","word_nosc":"desertique","lemma":"désertique","pos":"ADJ"} ,
		{"word":"désertiques","word_nosc":"desertiques","lemma":"désertique","pos":"ADJ"} ,
		{"word":"déserts","word_nosc":"deserts","lemma":"désert","pos":"ADJ"} ,
		{"word":"déserté","word_nosc":"deserte","lemma":"déserté","pos":"ADJ"} ,
		{"word":"désertée","word_nosc":"desertee","lemma":"déserté","pos":"ADJ"} ,
		{"word":"désertées","word_nosc":"desertees","lemma":"déserté","pos":"ADJ"} ,
		{"word":"désertés","word_nosc":"desertes","lemma":"déserté","pos":"ADJ"} ,
		{"word":"désespérant","word_nosc":"desesperant","lemma":"désespérant","pos":"ADJ"} ,
		{"word":"désespérante","word_nosc":"desesperante","lemma":"désespérant","pos":"ADJ"} ,
		{"word":"désespérants","word_nosc":"desesperants","lemma":"désespérant","pos":"ADJ"} ,
		{"word":"désespéré","word_nosc":"desespere","lemma":"désespéré","pos":"ADJ"} ,
		{"word":"désespérée","word_nosc":"desesperee","lemma":"désespéré","pos":"ADJ"} ,
		{"word":"désespérées","word_nosc":"desesperees","lemma":"désespéré","pos":"ADJ"} ,
		{"word":"désespérés","word_nosc":"desesperes","lemma":"désespéré","pos":"ADJ"} ,
		{"word":"déshabillé","word_nosc":"deshabille","lemma":"déshabillé","pos":"ADJ"} ,
		{"word":"déshabillée","word_nosc":"deshabillee","lemma":"déshabillé","pos":"ADJ"} ,
		{"word":"déshabillées","word_nosc":"deshabillees","lemma":"déshabillé","pos":"ADJ"} ,
		{"word":"déshabillés","word_nosc":"deshabilles","lemma":"déshabillé","pos":"ADJ"} ,
		{"word":"déshabité","word_nosc":"deshabite","lemma":"déshabité","pos":"ADJ"} ,
		{"word":"déshabitée","word_nosc":"deshabitee","lemma":"déshabité","pos":"ADJ"} ,
		{"word":"déshabités","word_nosc":"deshabites","lemma":"déshabité","pos":"ADJ"} ,
		{"word":"désherbant","word_nosc":"desherbant","lemma":"désherbant","pos":"ADJ"} ,
		{"word":"désherbants","word_nosc":"desherbants","lemma":"désherbant","pos":"ADJ"} ,
		{"word":"déshonnête","word_nosc":"deshonnete","lemma":"déshonnête","pos":"ADJ"} ,
		{"word":"déshonnêtes","word_nosc":"deshonnetes","lemma":"déshonnête","pos":"ADJ"} ,
		{"word":"déshonorant","word_nosc":"deshonorant","lemma":"déshonorant","pos":"ADJ"} ,
		{"word":"déshonorante","word_nosc":"deshonorante","lemma":"déshonorant","pos":"ADJ"} ,
		{"word":"déshonorantes","word_nosc":"deshonorantes","lemma":"déshonorant","pos":"ADJ"} ,
		{"word":"déshonorants","word_nosc":"deshonorants","lemma":"déshonorant","pos":"ADJ"} ,
		{"word":"déshumanisante","word_nosc":"deshumanisante","lemma":"déshumanisant","pos":"ADJ"} ,
		{"word":"déshydraté","word_nosc":"deshydrate","lemma":"déshydraté","pos":"ADJ"} ,
		{"word":"déshydratée","word_nosc":"deshydratee","lemma":"déshydraté","pos":"ADJ"} ,
		{"word":"déshydratées","word_nosc":"deshydratees","lemma":"déshydraté","pos":"ADJ"} ,
		{"word":"déshydratés","word_nosc":"deshydrates","lemma":"déshydraté","pos":"ADJ"} ,
		{"word":"déshérité","word_nosc":"desherite","lemma":"déshérité","pos":"ADJ"} ,
		{"word":"déshéritée","word_nosc":"desheritee","lemma":"déshérité","pos":"ADJ"} ,
		{"word":"déshéritées","word_nosc":"desheritees","lemma":"déshérité","pos":"ADJ"} ,
		{"word":"déshérités","word_nosc":"desherites","lemma":"déshérité","pos":"ADJ"} ,
		{"word":"désigné","word_nosc":"designe","lemma":"désigné","pos":"ADJ"} ,
		{"word":"désignée","word_nosc":"designee","lemma":"désigné","pos":"ADJ"} ,
		{"word":"désignées","word_nosc":"designees","lemma":"désigné","pos":"ADJ"} ,
		{"word":"désignés","word_nosc":"designes","lemma":"désigné","pos":"ADJ"} ,
		{"word":"désincarné","word_nosc":"desincarne","lemma":"désincarné","pos":"ADJ"} ,
		{"word":"désincarnée","word_nosc":"desincarnee","lemma":"désincarné","pos":"ADJ"} ,
		{"word":"désincarnées","word_nosc":"desincarnees","lemma":"désincarné","pos":"ADJ"} ,
		{"word":"désincarnés","word_nosc":"desincarnes","lemma":"désincarné","pos":"ADJ"} ,
		{"word":"désincrustante","word_nosc":"desincrustante","lemma":"désincrustant","pos":"ADJ"} ,
		{"word":"désinfectant","word_nosc":"desinfectant","lemma":"désinfectant","pos":"ADJ"} ,
		{"word":"désinfectante","word_nosc":"desinfectante","lemma":"désinfectant","pos":"ADJ"} ,
		{"word":"désinfectantes","word_nosc":"desinfectantes","lemma":"désinfectant","pos":"ADJ"} ,
		{"word":"désintéressé","word_nosc":"desinteresse","lemma":"désintéressé","pos":"ADJ"} ,
		{"word":"désintéressée","word_nosc":"desinteressee","lemma":"désintéressé","pos":"ADJ"} ,
		{"word":"désintéressées","word_nosc":"desinteressees","lemma":"désintéressé","pos":"ADJ"} ,
		{"word":"désintéressés","word_nosc":"desinteresses","lemma":"désintéressé","pos":"ADJ"} ,
		{"word":"désinvolte","word_nosc":"desinvolte","lemma":"désinvolte","pos":"ADJ"} ,
		{"word":"désinvoltes","word_nosc":"desinvoltes","lemma":"désinvolte","pos":"ADJ"} ,
		{"word":"désirable","word_nosc":"desirable","lemma":"désirable","pos":"ADJ"} ,
		{"word":"désirables","word_nosc":"desirables","lemma":"désirable","pos":"ADJ"} ,
		{"word":"désirant","word_nosc":"desirant","lemma":"désirant","pos":"ADJ"} ,
		{"word":"désirante","word_nosc":"desirante","lemma":"désirant","pos":"ADJ"} ,
		{"word":"désireuse","word_nosc":"desireuse","lemma":"désireux","pos":"ADJ"} ,
		{"word":"désireuses","word_nosc":"desireuses","lemma":"désireux","pos":"ADJ"} ,
		{"word":"désireux","word_nosc":"desireux","lemma":"désireux","pos":"ADJ"} ,
		{"word":"désiré","word_nosc":"desire","lemma":"désiré","pos":"ADJ"} ,
		{"word":"désirée","word_nosc":"desiree","lemma":"désiré","pos":"ADJ"} ,
		{"word":"désirées","word_nosc":"desirees","lemma":"désiré","pos":"ADJ"} ,
		{"word":"désirés","word_nosc":"desires","lemma":"désiré","pos":"ADJ"} ,
		{"word":"désobligeant","word_nosc":"desobligeant","lemma":"désobligeant","pos":"ADJ"} ,
		{"word":"désobligeante","word_nosc":"desobligeante","lemma":"désobligeant","pos":"ADJ"} ,
		{"word":"désobligeantes","word_nosc":"desobligeantes","lemma":"désobligeant","pos":"ADJ"} ,
		{"word":"désobligeants","word_nosc":"desobligeants","lemma":"désobligeant","pos":"ADJ"} ,
		{"word":"désobéissant","word_nosc":"desobeissant","lemma":"désobéissant","pos":"ADJ"} ,
		{"word":"désobéissante","word_nosc":"desobeissante","lemma":"désobéissant","pos":"ADJ"} ,
		{"word":"désobéissants","word_nosc":"desobeissants","lemma":"désobéissant","pos":"ADJ"} ,
		{"word":"désoccupés","word_nosc":"desoccupes","lemma":"désoccupé","pos":"ADJ"} ,
		{"word":"désodorisant","word_nosc":"desodorisant","lemma":"désodorisant","pos":"ADJ"} ,
		{"word":"désodorisante","word_nosc":"desodorisante","lemma":"désodorisant","pos":"ADJ"} ,
		{"word":"désodé","word_nosc":"desode","lemma":"désodé","pos":"ADJ"} ,
		{"word":"désoeuvré","word_nosc":"desoeuvre","lemma":"désoeuvré","pos":"ADJ"} ,
		{"word":"désoeuvrée","word_nosc":"desoeuvree","lemma":"désoeuvré","pos":"ADJ"} ,
		{"word":"désoeuvrées","word_nosc":"desoeuvrees","lemma":"désoeuvré","pos":"ADJ"} ,
		{"word":"désoeuvrés","word_nosc":"desoeuvres","lemma":"désoeuvré","pos":"ADJ"} ,
		{"word":"désolant","word_nosc":"desolant","lemma":"désolant","pos":"ADJ"} ,
		{"word":"désolante","word_nosc":"desolante","lemma":"désolant","pos":"ADJ"} ,
		{"word":"désolantes","word_nosc":"desolantes","lemma":"désolant","pos":"ADJ"} ,
		{"word":"désolants","word_nosc":"desolants","lemma":"désolant","pos":"ADJ"} ,
		{"word":"désolé","word_nosc":"desole","lemma":"désolé","pos":"ADJ"} ,
		{"word":"désolée","word_nosc":"desolee","lemma":"désolé","pos":"ADJ"} ,
		{"word":"désolées","word_nosc":"desolees","lemma":"désolé","pos":"ADJ"} ,
		{"word":"désolés","word_nosc":"desoles","lemma":"désolé","pos":"ADJ"} ,
		{"word":"désopilant","word_nosc":"desopilant","lemma":"désopilant","pos":"ADJ"} ,
		{"word":"désopilante","word_nosc":"desopilante","lemma":"désopilant","pos":"ADJ"} ,
		{"word":"désopilantes","word_nosc":"desopilantes","lemma":"désopilant","pos":"ADJ"} ,
		{"word":"désopilants","word_nosc":"desopilants","lemma":"désopilant","pos":"ADJ"} ,
		{"word":"désordonné","word_nosc":"desordonne","lemma":"désordonné","pos":"ADJ"} ,
		{"word":"désordonnée","word_nosc":"desordonnee","lemma":"désordonné","pos":"ADJ"} ,
		{"word":"désordonnées","word_nosc":"desordonnees","lemma":"désordonné","pos":"ADJ"} ,
		{"word":"désordonnés","word_nosc":"desordonnes","lemma":"désordonné","pos":"ADJ"} ,
		{"word":"désorienté","word_nosc":"desoriente","lemma":"désorienté","pos":"ADJ"} ,
		{"word":"désorientée","word_nosc":"desorientee","lemma":"désorienté","pos":"ADJ"} ,
		{"word":"désorientées","word_nosc":"desorientees","lemma":"désorienté","pos":"ADJ"} ,
		{"word":"désorientés","word_nosc":"desorientes","lemma":"désorienté","pos":"ADJ"} ,
		{"word":"désoxyribonucléique","word_nosc":"desoxyribonucleique","lemma":"désoxyribonucléique","pos":"ADJ"} ,
		{"word":"déstabilisant","word_nosc":"destabilisant","lemma":"déstabilisant","pos":"ADJ"} ,
		{"word":"déstabilisante","word_nosc":"destabilisante","lemma":"déstabilisant","pos":"ADJ"} ,
		{"word":"déstabilisantes","word_nosc":"destabilisantes","lemma":"déstabilisant","pos":"ADJ"} ,
		{"word":"déstabilisatrice","word_nosc":"destabilisatrice","lemma":"déstabilisateur","pos":"ADJ"} ,
		{"word":"désuet","word_nosc":"desuet","lemma":"désuet","pos":"ADJ"} ,
		{"word":"désuets","word_nosc":"desuets","lemma":"désuet","pos":"ADJ"} ,
		{"word":"désuni","word_nosc":"desuni","lemma":"désuni","pos":"ADJ"} ,
		{"word":"désunie","word_nosc":"desunie","lemma":"désuni","pos":"ADJ"} ,
		{"word":"désunies","word_nosc":"desunies","lemma":"désuni","pos":"ADJ"} ,
		{"word":"désunis","word_nosc":"desunis","lemma":"désuni","pos":"ADJ"} ,
		{"word":"désuète","word_nosc":"desuete","lemma":"désuet","pos":"ADJ"} ,
		{"word":"désuètes","word_nosc":"desuetes","lemma":"désuet","pos":"ADJ"} ,
		{"word":"déséquilibré","word_nosc":"desequilibre","lemma":"déséquilibré","pos":"ADJ"} ,
		{"word":"déséquilibrée","word_nosc":"desequilibree","lemma":"déséquilibré","pos":"ADJ"} ,
		{"word":"déséquilibrées","word_nosc":"desequilibrees","lemma":"déséquilibré","pos":"ADJ"} ,
		{"word":"déséquilibrés","word_nosc":"desequilibres","lemma":"déséquilibré","pos":"ADJ"} ,
		{"word":"détachable","word_nosc":"detachable","lemma":"détachable","pos":"ADJ"} ,
		{"word":"détachant","word_nosc":"detachant","lemma":"détachant","pos":"ADJ"} ,
		{"word":"détaché","word_nosc":"detache","lemma":"détaché","pos":"ADJ"} ,
		{"word":"détachée","word_nosc":"detachee","lemma":"détaché","pos":"ADJ"} ,
		{"word":"détachées","word_nosc":"detachees","lemma":"détaché","pos":"ADJ"} ,
		{"word":"détachés","word_nosc":"detaches","lemma":"détaché","pos":"ADJ"} ,
		{"word":"détaillant","word_nosc":"detaillant","lemma":"détaillant","pos":"ADJ"} ,
		{"word":"détaillante","word_nosc":"detaillante","lemma":"détaillant","pos":"ADJ"} ,
		{"word":"détaillants","word_nosc":"detaillants","lemma":"détaillant","pos":"ADJ"} ,
		{"word":"détaillé","word_nosc":"detaille","lemma":"détaillé","pos":"ADJ"} ,
		{"word":"détaillée","word_nosc":"detaillee","lemma":"détaillé","pos":"ADJ"} ,
		{"word":"détaillées","word_nosc":"detaillees","lemma":"détaillé","pos":"ADJ"} ,
		{"word":"détaillés","word_nosc":"detailles","lemma":"détaillé","pos":"ADJ"} ,
		{"word":"détartrants","word_nosc":"detartrants","lemma":"détartrant","pos":"ADJ"} ,
		{"word":"détectable","word_nosc":"detectable","lemma":"détectable","pos":"ADJ"} ,
		{"word":"détectables","word_nosc":"detectables","lemma":"détectable","pos":"ADJ"} ,
		{"word":"détecteur","word_nosc":"detecteur","lemma":"détecteur","pos":"ADJ"} ,
		{"word":"détecteurs","word_nosc":"detecteurs","lemma":"détecteur","pos":"ADJ"} ,
		{"word":"détendu","word_nosc":"detendu","lemma":"détendu","pos":"ADJ"} ,
		{"word":"détendue","word_nosc":"detendue","lemma":"détendu","pos":"ADJ"} ,
		{"word":"détendues","word_nosc":"detendues","lemma":"détendu","pos":"ADJ"} ,
		{"word":"détendus","word_nosc":"detendus","lemma":"détendu","pos":"ADJ"} ,
		{"word":"détenteur","word_nosc":"detenteur","lemma":"détenteur","pos":"ADJ"} ,
		{"word":"détenteurs","word_nosc":"detenteurs","lemma":"détenteur","pos":"ADJ"} ,
		{"word":"détentrice","word_nosc":"detentrice","lemma":"détenteur","pos":"ADJ"} ,
		{"word":"détentrices","word_nosc":"detentrices","lemma":"détenteur","pos":"ADJ"} ,
		{"word":"détenu","word_nosc":"detenu","lemma":"détenu","pos":"ADJ"} ,
		{"word":"détenue","word_nosc":"detenue","lemma":"détenu","pos":"ADJ"} ,
		{"word":"détenues","word_nosc":"detenues","lemma":"détenu","pos":"ADJ"} ,
		{"word":"détenus","word_nosc":"detenus","lemma":"détenu","pos":"ADJ"} ,
		{"word":"détergent","word_nosc":"detergent","lemma":"détergent","pos":"ADJ"} ,
		{"word":"détergents","word_nosc":"detergents","lemma":"détergent","pos":"ADJ"} ,
		{"word":"déterminant","word_nosc":"determinant","lemma":"déterminant","pos":"ADJ"} ,
		{"word":"déterminante","word_nosc":"determinante","lemma":"déterminant","pos":"ADJ"} ,
		{"word":"déterminantes","word_nosc":"determinantes","lemma":"déterminant","pos":"ADJ"} ,
		{"word":"déterminants","word_nosc":"determinants","lemma":"déterminant","pos":"ADJ"} ,
		{"word":"déterministe","word_nosc":"deterministe","lemma":"déterministe","pos":"ADJ"} ,
		{"word":"déterministes","word_nosc":"deterministes","lemma":"déterministe","pos":"ADJ"} ,
		{"word":"déterminé","word_nosc":"determine","lemma":"déterminé","pos":"ADJ"} ,
		{"word":"déterminée","word_nosc":"determinee","lemma":"déterminé","pos":"ADJ"} ,
		{"word":"déterminées","word_nosc":"determinees","lemma":"déterminé","pos":"ADJ"} ,
		{"word":"déterminés","word_nosc":"determines","lemma":"déterminé","pos":"ADJ"} ,
		{"word":"déterré","word_nosc":"deterre","lemma":"déterré","pos":"ADJ"} ,
		{"word":"déterrée","word_nosc":"deterree","lemma":"déterré","pos":"ADJ"} ,
		{"word":"déterrées","word_nosc":"deterrees","lemma":"déterré","pos":"ADJ"} ,
		{"word":"détersifs","word_nosc":"detersifs","lemma":"détersif","pos":"ADJ"} ,
		{"word":"détersive","word_nosc":"detersive","lemma":"détersif","pos":"ADJ"} ,
		{"word":"détestable","word_nosc":"detestable","lemma":"détestable","pos":"ADJ"} ,
		{"word":"détestables","word_nosc":"detestables","lemma":"détestable","pos":"ADJ"} ,
		{"word":"détesté","word_nosc":"deteste","lemma":"détesté","pos":"ADJ"} ,
		{"word":"détestée","word_nosc":"detestee","lemma":"détesté","pos":"ADJ"} ,
		{"word":"détestés","word_nosc":"detestes","lemma":"détesté","pos":"ADJ"} ,
		{"word":"détimbrée","word_nosc":"detimbree","lemma":"détimbré","pos":"ADJ"} ,
		{"word":"détonant","word_nosc":"detonant","lemma":"détonant","pos":"ADJ"} ,
		{"word":"détonante","word_nosc":"detonante","lemma":"détonant","pos":"ADJ"} ,
		{"word":"détonants","word_nosc":"detonants","lemma":"détonant","pos":"ADJ"} ,
		{"word":"détourné","word_nosc":"detourne","lemma":"détourné","pos":"ADJ"} ,
		{"word":"détournée","word_nosc":"detournee","lemma":"détourné","pos":"ADJ"} ,
		{"word":"détournées","word_nosc":"detournees","lemma":"détourné","pos":"ADJ"} ,
		{"word":"détournés","word_nosc":"detournes","lemma":"détourné","pos":"ADJ"} ,
		{"word":"détracteur","word_nosc":"detracteur","lemma":"détracteur","pos":"ADJ"} ,
		{"word":"détracteurs","word_nosc":"detracteurs","lemma":"détracteur","pos":"ADJ"} ,
		{"word":"détraqué","word_nosc":"detraque","lemma":"détraqué","pos":"ADJ"} ,
		{"word":"détraquée","word_nosc":"detraquee","lemma":"détraqué","pos":"ADJ"} ,
		{"word":"détraquées","word_nosc":"detraquees","lemma":"détraqué","pos":"ADJ"} ,
		{"word":"détraqués","word_nosc":"detraques","lemma":"détraqué","pos":"ADJ"} ,
		{"word":"détritiques","word_nosc":"detritiques","lemma":"détritique","pos":"ADJ"} ,
		{"word":"détrôné","word_nosc":"detrone","lemma":"détrôné","pos":"ADJ"} ,
		{"word":"détrônée","word_nosc":"detronee","lemma":"détrôné","pos":"ADJ"} ,
		{"word":"dévalorisant","word_nosc":"devalorisant","lemma":"dévalorisant","pos":"ADJ"} ,
		{"word":"dévastateur","word_nosc":"devastateur","lemma":"dévastateur","pos":"ADJ"} ,
		{"word":"dévastateurs","word_nosc":"devastateurs","lemma":"dévastateur","pos":"ADJ"} ,
		{"word":"dévastatrice","word_nosc":"devastatrice","lemma":"dévastateur","pos":"ADJ"} ,
		{"word":"dévastatrices","word_nosc":"devastatrices","lemma":"dévastateur","pos":"ADJ"} ,
		{"word":"dévasté","word_nosc":"devaste","lemma":"dévasté","pos":"ADJ"} ,
		{"word":"dévastée","word_nosc":"devastee","lemma":"dévasté","pos":"ADJ"} ,
		{"word":"dévastées","word_nosc":"devastees","lemma":"dévasté","pos":"ADJ"} ,
		{"word":"dévastés","word_nosc":"devastes","lemma":"dévasté","pos":"ADJ"} ,
		{"word":"développé","word_nosc":"developpe","lemma":"développé","pos":"ADJ"} ,
		{"word":"développée","word_nosc":"developpee","lemma":"développé","pos":"ADJ"} ,
		{"word":"développées","word_nosc":"developpees","lemma":"développé","pos":"ADJ"} ,
		{"word":"développés","word_nosc":"developpes","lemma":"développé","pos":"ADJ"} ,
		{"word":"dévergondé","word_nosc":"devergonde","lemma":"dévergondé","pos":"ADJ"} ,
		{"word":"dévergondée","word_nosc":"devergondee","lemma":"dévergondé","pos":"ADJ"} ,
		{"word":"dévergondées","word_nosc":"devergondees","lemma":"dévergondé","pos":"ADJ"} ,
		{"word":"dévergondés","word_nosc":"devergondes","lemma":"dévergondé","pos":"ADJ"} ,
		{"word":"déversant","word_nosc":"deversant","lemma":"déversant","pos":"ADJ"} ,
		{"word":"déviant","word_nosc":"deviant","lemma":"déviant","pos":"ADJ"} ,
		{"word":"déviante","word_nosc":"deviante","lemma":"déviant","pos":"ADJ"} ,
		{"word":"déviants","word_nosc":"deviants","lemma":"déviant","pos":"ADJ"} ,
		{"word":"déviateur","word_nosc":"deviateur","lemma":"déviateur","pos":"ADJ"} ,
		{"word":"déviationniste","word_nosc":"deviationniste","lemma":"déviationniste","pos":"ADJ"} ,
		{"word":"dévissable","word_nosc":"devissable","lemma":"dévissable","pos":"ADJ"} ,
		{"word":"dévoilant","word_nosc":"devoilant","lemma":"dévoilant","pos":"ADJ"} ,
		{"word":"dévolu","word_nosc":"devolu","lemma":"dévolu","pos":"ADJ"} ,
		{"word":"dévolue","word_nosc":"devolue","lemma":"dévolu","pos":"ADJ"} ,
		{"word":"dévolues","word_nosc":"devolues","lemma":"dévolu","pos":"ADJ"} ,
		{"word":"dévolus","word_nosc":"devolus","lemma":"dévolu","pos":"ADJ"} ,
		{"word":"dévonienne","word_nosc":"devonienne","lemma":"dévonien","pos":"ADJ"} ,
		{"word":"dévorant","word_nosc":"devorant","lemma":"dévorant","pos":"ADJ"} ,
		{"word":"dévorante","word_nosc":"devorante","lemma":"dévorant","pos":"ADJ"} ,
		{"word":"dévorantes","word_nosc":"devorantes","lemma":"dévorant","pos":"ADJ"} ,
		{"word":"dévorants","word_nosc":"devorants","lemma":"dévorant","pos":"ADJ"} ,
		{"word":"dévorateur","word_nosc":"devorateur","lemma":"dévorateur","pos":"ADJ"} ,
		{"word":"dévorateurs","word_nosc":"devorateurs","lemma":"dévorateur","pos":"ADJ"} ,
		{"word":"dévoratrice","word_nosc":"devoratrice","lemma":"dévorateur","pos":"ADJ"} ,
		{"word":"dévot","word_nosc":"devot","lemma":"dévot","pos":"ADJ"} ,
		{"word":"dévote","word_nosc":"devote","lemma":"dévot","pos":"ADJ"} ,
		{"word":"dévotes","word_nosc":"devotes","lemma":"dévot","pos":"ADJ"} ,
		{"word":"dévotionnelle","word_nosc":"devotionnelle","lemma":"dévotionnel","pos":"ADJ"} ,
		{"word":"dévots","word_nosc":"devots","lemma":"dévot","pos":"ADJ"} ,
		{"word":"dévoué","word_nosc":"devoue","lemma":"dévoué","pos":"ADJ"} ,
		{"word":"dévouée","word_nosc":"devouee","lemma":"dévoué","pos":"ADJ"} ,
		{"word":"dévouées","word_nosc":"devouees","lemma":"dévoué","pos":"ADJ"} ,
		{"word":"dévoués","word_nosc":"devoues","lemma":"dévoué","pos":"ADJ"} ,
		{"word":"dévoyé","word_nosc":"devoye","lemma":"dévoyé","pos":"ADJ"} ,
		{"word":"dévoyée","word_nosc":"devoyee","lemma":"dévoyé","pos":"ADJ"} ,
		{"word":"dévoyées","word_nosc":"devoyees","lemma":"dévoyé","pos":"ADJ"} ,
		{"word":"dévoyés","word_nosc":"devoyes","lemma":"dévoyé","pos":"ADJ"} ,
		{"word":"déçu","word_nosc":"decu","lemma":"déçu","pos":"ADJ"} ,
		{"word":"déçue","word_nosc":"decue","lemma":"déçu","pos":"ADJ"} ,
		{"word":"déçues","word_nosc":"decues","lemma":"déçu","pos":"ADJ"} ,
		{"word":"déçus","word_nosc":"decus","lemma":"déçu","pos":"ADJ"} ,
		{"word":"dînatoire","word_nosc":"dinatoire","lemma":"dînatoire","pos":"ADJ"} ,
		{"word":"dînatoires","word_nosc":"dinatoires","lemma":"dînatoire","pos":"ADJ"} ,
		{"word":"dîner","word_nosc":"diner","lemma":"dîner","pos":"ADJ"} ,
		{"word":"dû","word_nosc":"du","lemma":"dû","pos":"ADJ"} ,
		{"word":"e","word_nosc":"e","lemma":"2e","pos":"ADJ"} ,
		{"word":"e","word_nosc":"e","lemma":"58e","pos":"ADJ"} ,
		{"word":"e","word_nosc":"e","lemma":"7e","pos":"ADJ"} ,
		{"word":"ecchymosé","word_nosc":"ecchymose","lemma":"ecchymosé","pos":"ADJ"} ,
		{"word":"ecchymosée","word_nosc":"ecchymosee","lemma":"ecchymosé","pos":"ADJ"} ,
		{"word":"ecclésiale","word_nosc":"ecclesiale","lemma":"ecclésial","pos":"ADJ"} ,
		{"word":"ecclésiastique","word_nosc":"ecclesiastique","lemma":"ecclésiastique","pos":"ADJ"} ,
		{"word":"ecclésiastiques","word_nosc":"ecclesiastiques","lemma":"ecclésiastique","pos":"ADJ"} ,
		{"word":"ectodermique","word_nosc":"ectodermique","lemma":"ectodermique","pos":"ADJ"} ,
		{"word":"ectopique","word_nosc":"ectopique","lemma":"ectopique","pos":"ADJ"} ,
		{"word":"ectoplasmique","word_nosc":"ectoplasmique","lemma":"ectoplasmique","pos":"ADJ"} ,
		{"word":"ectoplasmiques","word_nosc":"ectoplasmiques","lemma":"ectoplasmique","pos":"ADJ"} ,
		{"word":"eczémateuses","word_nosc":"eczemateuses","lemma":"eczémateux","pos":"ADJ"} ,
		{"word":"eczémateux","word_nosc":"eczemateux","lemma":"eczémateux","pos":"ADJ"} ,
		{"word":"edwardienne","word_nosc":"edwardienne","lemma":"edwardien","pos":"ADJ"} ,
		{"word":"effacé","word_nosc":"efface","lemma":"effacé","pos":"ADJ"} ,
		{"word":"effacée","word_nosc":"effacee","lemma":"effacé","pos":"ADJ"} ,
		{"word":"effacées","word_nosc":"effacees","lemma":"effacé","pos":"ADJ"} ,
		{"word":"effacés","word_nosc":"effaces","lemma":"effacé","pos":"ADJ"} ,
		{"word":"effarant","word_nosc":"effarant","lemma":"effarant","pos":"ADJ"} ,
		{"word":"effarante","word_nosc":"effarante","lemma":"effarant","pos":"ADJ"} ,
		{"word":"effarantes","word_nosc":"effarantes","lemma":"effarant","pos":"ADJ"} ,
		{"word":"effarants","word_nosc":"effarants","lemma":"effarant","pos":"ADJ"} ,
		{"word":"effarouché","word_nosc":"effarouche","lemma":"effarouché","pos":"ADJ"} ,
		{"word":"effarouchée","word_nosc":"effarouchee","lemma":"effarouché","pos":"ADJ"} ,
		{"word":"effarouchées","word_nosc":"effarouchees","lemma":"effarouché","pos":"ADJ"} ,
		{"word":"effarouchés","word_nosc":"effarouches","lemma":"effarouché","pos":"ADJ"} ,
		{"word":"effaré","word_nosc":"effare","lemma":"effaré","pos":"ADJ"} ,
		{"word":"effarée","word_nosc":"effaree","lemma":"effaré","pos":"ADJ"} ,
		{"word":"effarées","word_nosc":"effarees","lemma":"effaré","pos":"ADJ"} ,
		{"word":"effarés","word_nosc":"effares","lemma":"effaré","pos":"ADJ"} ,
		{"word":"effaçable","word_nosc":"effacable","lemma":"effaçable","pos":"ADJ"} ,
		{"word":"effaçables","word_nosc":"effacables","lemma":"effaçable","pos":"ADJ"} ,
		{"word":"effectif","word_nosc":"effectif","lemma":"effectif","pos":"ADJ"} ,
		{"word":"effectifs","word_nosc":"effectifs","lemma":"effectif","pos":"ADJ"} ,
		{"word":"effective","word_nosc":"effective","lemma":"effectif","pos":"ADJ"} ,
		{"word":"effectives","word_nosc":"effectives","lemma":"effectif","pos":"ADJ"} ,
		{"word":"effervescent","word_nosc":"effervescent","lemma":"effervescent","pos":"ADJ"} ,
		{"word":"effervescente","word_nosc":"effervescente","lemma":"effervescent","pos":"ADJ"} ,
		{"word":"effervescentes","word_nosc":"effervescentes","lemma":"effervescent","pos":"ADJ"} ,
		{"word":"effervescents","word_nosc":"effervescents","lemma":"effervescent","pos":"ADJ"} ,
		{"word":"efficace","word_nosc":"efficace","lemma":"efficace","pos":"ADJ"} ,
		{"word":"efficaces","word_nosc":"efficaces","lemma":"efficace","pos":"ADJ"} ,
		{"word":"efficient","word_nosc":"efficient","lemma":"efficient","pos":"ADJ"} ,
		{"word":"efficiente","word_nosc":"efficiente","lemma":"efficient","pos":"ADJ"} ,
		{"word":"efficientes","word_nosc":"efficientes","lemma":"efficient","pos":"ADJ"} ,
		{"word":"efficients","word_nosc":"efficients","lemma":"efficient","pos":"ADJ"} ,
		{"word":"effilé","word_nosc":"effile","lemma":"effilé","pos":"ADJ"} ,
		{"word":"effilée","word_nosc":"effilee","lemma":"effilé","pos":"ADJ"} ,
		{"word":"effilées","word_nosc":"effilees","lemma":"effilé","pos":"ADJ"} ,
		{"word":"effilés","word_nosc":"effiles","lemma":"effilé","pos":"ADJ"} ,
		{"word":"efflanqué","word_nosc":"efflanque","lemma":"efflanqué","pos":"ADJ"} ,
		{"word":"efflanquée","word_nosc":"efflanquee","lemma":"efflanqué","pos":"ADJ"} ,
		{"word":"efflanquées","word_nosc":"efflanquees","lemma":"efflanqué","pos":"ADJ"} ,
		{"word":"efflanqués","word_nosc":"efflanques","lemma":"efflanqué","pos":"ADJ"} ,
		{"word":"effondré","word_nosc":"effondre","lemma":"effondré","pos":"ADJ"} ,
		{"word":"effondrée","word_nosc":"effondree","lemma":"effondré","pos":"ADJ"} ,
		{"word":"effondrées","word_nosc":"effondrees","lemma":"effondré","pos":"ADJ"} ,
		{"word":"effondrés","word_nosc":"effondres","lemma":"effondré","pos":"ADJ"} ,
		{"word":"effrayant","word_nosc":"effrayant","lemma":"effrayant","pos":"ADJ"} ,
		{"word":"effrayante","word_nosc":"effrayante","lemma":"effrayant","pos":"ADJ"} ,
		{"word":"effrayantes","word_nosc":"effrayantes","lemma":"effrayant","pos":"ADJ"} ,
		{"word":"effrayants","word_nosc":"effrayants","lemma":"effrayant","pos":"ADJ"} ,
		{"word":"effrayé","word_nosc":"effraye","lemma":"effrayé","pos":"ADJ"} ,
		{"word":"effrayée","word_nosc":"effrayee","lemma":"effrayé","pos":"ADJ"} ,
		{"word":"effrayées","word_nosc":"effrayees","lemma":"effrayé","pos":"ADJ"} ,
		{"word":"effrayés","word_nosc":"effrayes","lemma":"effrayé","pos":"ADJ"} ,
		{"word":"effronté","word_nosc":"effronte","lemma":"effronté","pos":"ADJ"} ,
		{"word":"effrontée","word_nosc":"effrontee","lemma":"effronté","pos":"ADJ"} ,
		{"word":"effrontées","word_nosc":"effrontees","lemma":"effronté","pos":"ADJ"} ,
		{"word":"effrontés","word_nosc":"effrontes","lemma":"effronté","pos":"ADJ"} ,
		{"word":"effroyable","word_nosc":"effroyable","lemma":"effroyable","pos":"ADJ"} ,
		{"word":"effroyables","word_nosc":"effroyables","lemma":"effroyable","pos":"ADJ"} ,
		{"word":"effréné","word_nosc":"effrene","lemma":"effréné","pos":"ADJ"} ,
		{"word":"effrénée","word_nosc":"effrenee","lemma":"effréné","pos":"ADJ"} ,
		{"word":"effrénées","word_nosc":"effrenees","lemma":"effréné","pos":"ADJ"} ,
		{"word":"effrénés","word_nosc":"effrenes","lemma":"effréné","pos":"ADJ"} ,
		{"word":"effulgente","word_nosc":"effulgente","lemma":"effulgent","pos":"ADJ"} ,
		{"word":"effusionniste","word_nosc":"effusionniste","lemma":"effusionniste","pos":"ADJ"} ,
		{"word":"effusionnistes","word_nosc":"effusionnistes","lemma":"effusionniste","pos":"ADJ"} ,
		{"word":"efféminé","word_nosc":"effemine","lemma":"efféminé","pos":"ADJ"} ,
		{"word":"efféminée","word_nosc":"effeminee","lemma":"efféminé","pos":"ADJ"} ,
		{"word":"efféminés","word_nosc":"effemines","lemma":"efféminé","pos":"ADJ"} ,
		{"word":"eidétique","word_nosc":"eidetique","lemma":"eidétique","pos":"ADJ"} ,
		{"word":"einsteinienne","word_nosc":"einsteinienne","lemma":"einsteinien","pos":"ADJ"} ,
		{"word":"ellipsoïde","word_nosc":"ellipsoide","lemma":"ellipsoïde","pos":"ADJ"} ,
		{"word":"elliptique","word_nosc":"elliptique","lemma":"elliptique","pos":"ADJ"} ,
		{"word":"elliptiques","word_nosc":"elliptiques","lemma":"elliptique","pos":"ADJ"} ,
		{"word":"emballé","word_nosc":"emballe","lemma":"emballé","pos":"ADJ"} ,
		{"word":"emballée","word_nosc":"emballee","lemma":"emballé","pos":"ADJ"} ,
		{"word":"emballées","word_nosc":"emballees","lemma":"emballé","pos":"ADJ"} ,
		{"word":"emballés","word_nosc":"emballes","lemma":"emballé","pos":"ADJ"} ,
		{"word":"embarrassant","word_nosc":"embarrassant","lemma":"embarrassant","pos":"ADJ"} ,
		{"word":"embarrassante","word_nosc":"embarrassante","lemma":"embarrassant","pos":"ADJ"} ,
		{"word":"embarrassantes","word_nosc":"embarrassantes","lemma":"embarrassant","pos":"ADJ"} ,
		{"word":"embarrassants","word_nosc":"embarrassants","lemma":"embarrassant","pos":"ADJ"} ,
		{"word":"embarrassé","word_nosc":"embarrasse","lemma":"embarrassé","pos":"ADJ"} ,
		{"word":"embarrassée","word_nosc":"embarrassee","lemma":"embarrassé","pos":"ADJ"} ,
		{"word":"embarrassées","word_nosc":"embarrassees","lemma":"embarrassé","pos":"ADJ"} ,
		{"word":"embarrassés","word_nosc":"embarrasses","lemma":"embarrassé","pos":"ADJ"} ,
		{"word":"embaumant","word_nosc":"embaumant","lemma":"embaumant","pos":"ADJ"} ,
		{"word":"embaumants","word_nosc":"embaumants","lemma":"embaumant","pos":"ADJ"} ,
		{"word":"emberlificoté","word_nosc":"emberlificote","lemma":"emberlificoté","pos":"ADJ"} ,
		{"word":"emberlificotés","word_nosc":"emberlificotes","lemma":"emberlificoté","pos":"ADJ"} ,
		{"word":"embijoutée","word_nosc":"embijoutee","lemma":"embijouté","pos":"ADJ"} ,
		{"word":"emblématique","word_nosc":"emblematique","lemma":"emblématique","pos":"ADJ"} ,
		{"word":"emblématiques","word_nosc":"emblematiques","lemma":"emblématique","pos":"ADJ"} ,
		{"word":"embolique","word_nosc":"embolique","lemma":"embolique","pos":"ADJ"} ,
		{"word":"embouché","word_nosc":"embouche","lemma":"embouché","pos":"ADJ"} ,
		{"word":"embouchée","word_nosc":"embouchee","lemma":"embouché","pos":"ADJ"} ,
		{"word":"embouchées","word_nosc":"embouchees","lemma":"embouché","pos":"ADJ"} ,
		{"word":"embouchés","word_nosc":"embouches","lemma":"embouché","pos":"ADJ"} ,
		{"word":"embourbé","word_nosc":"embourbe","lemma":"embourbé","pos":"ADJ"} ,
		{"word":"embourbée","word_nosc":"embourbee","lemma":"embourbé","pos":"ADJ"} ,
		{"word":"embourbées","word_nosc":"embourbees","lemma":"embourbé","pos":"ADJ"} ,
		{"word":"embourbés","word_nosc":"embourbes","lemma":"embourbé","pos":"ADJ"} ,
		{"word":"embourgeoisé","word_nosc":"embourgeoise","lemma":"embourgeoisé","pos":"ADJ"} ,
		{"word":"embouteillée","word_nosc":"embouteillee","lemma":"embouteillé","pos":"ADJ"} ,
		{"word":"embouteillées","word_nosc":"embouteillees","lemma":"embouteillé","pos":"ADJ"} ,
		{"word":"embouti","word_nosc":"embouti","lemma":"embouti","pos":"ADJ"} ,
		{"word":"emboutie","word_nosc":"emboutie","lemma":"embouti","pos":"ADJ"} ,
		{"word":"embouties","word_nosc":"embouties","lemma":"embouti","pos":"ADJ"} ,
		{"word":"emboîtables","word_nosc":"emboitables","lemma":"emboîtable","pos":"ADJ"} ,
		{"word":"embrassant","word_nosc":"embrassant","lemma":"embrassant","pos":"ADJ"} ,
		{"word":"embrasseur","word_nosc":"embrasseur","lemma":"embrasseur","pos":"ADJ"} ,
		{"word":"embrassé","word_nosc":"embrasse","lemma":"embrassé","pos":"ADJ"} ,
		{"word":"embrassée","word_nosc":"embrassee","lemma":"embrassé","pos":"ADJ"} ,
		{"word":"embrassées","word_nosc":"embrassees","lemma":"embrassé","pos":"ADJ"} ,
		{"word":"embrassés","word_nosc":"embrasses","lemma":"embrassé","pos":"ADJ"} ,
		{"word":"embrasé","word_nosc":"embrase","lemma":"embrasé","pos":"ADJ"} ,
		{"word":"embrasée","word_nosc":"embrasee","lemma":"embrasé","pos":"ADJ"} ,
		{"word":"embrasées","word_nosc":"embrasees","lemma":"embrasé","pos":"ADJ"} ,
		{"word":"embrasés","word_nosc":"embrases","lemma":"embrasé","pos":"ADJ"} ,
		{"word":"embrigadé","word_nosc":"embrigade","lemma":"embrigadé","pos":"ADJ"} ,
		{"word":"embrouillé","word_nosc":"embrouille","lemma":"embrouillé","pos":"ADJ"} ,
		{"word":"embrouillée","word_nosc":"embrouillee","lemma":"embrouillé","pos":"ADJ"} ,
		{"word":"embrouillées","word_nosc":"embrouillees","lemma":"embrouillé","pos":"ADJ"} ,
		{"word":"embrouillés","word_nosc":"embrouilles","lemma":"embrouillé","pos":"ADJ"} ,
		{"word":"embroussaillé","word_nosc":"embroussaille","lemma":"embroussaillé","pos":"ADJ"} ,
		{"word":"embroussaillés","word_nosc":"embroussailles","lemma":"embroussaillé","pos":"ADJ"} ,
		{"word":"embruiné","word_nosc":"embruine","lemma":"embruiné","pos":"ADJ"} ,
		{"word":"embrumé","word_nosc":"embrume","lemma":"embrumé","pos":"ADJ"} ,
		{"word":"embrumée","word_nosc":"embrumee","lemma":"embrumé","pos":"ADJ"} ,
		{"word":"embrumées","word_nosc":"embrumees","lemma":"embrumé","pos":"ADJ"} ,
		{"word":"embrumés","word_nosc":"embrumes","lemma":"embrumé","pos":"ADJ"} ,
		{"word":"embryonnaire","word_nosc":"embryonnaire","lemma":"embryonnaire","pos":"ADJ"} ,
		{"word":"embryonnaires","word_nosc":"embryonnaires","lemma":"embryonnaire","pos":"ADJ"} ,
		{"word":"embusqué","word_nosc":"embusque","lemma":"embusqué","pos":"ADJ"} ,
		{"word":"embusquée","word_nosc":"embusquee","lemma":"embusqué","pos":"ADJ"} ,
		{"word":"embusquées","word_nosc":"embusquees","lemma":"embusqué","pos":"ADJ"} ,
		{"word":"embusqués","word_nosc":"embusques","lemma":"embusqué","pos":"ADJ"} ,
		{"word":"embué","word_nosc":"embue","lemma":"embué","pos":"ADJ"} ,
		{"word":"embuée","word_nosc":"embuee","lemma":"embué","pos":"ADJ"} ,
		{"word":"embuées","word_nosc":"embuees","lemma":"embué","pos":"ADJ"} ,
		{"word":"embués","word_nosc":"embues","lemma":"embué","pos":"ADJ"} ,
		{"word":"embêtant","word_nosc":"embetant","lemma":"embêtant","pos":"ADJ"} ,
		{"word":"embêtante","word_nosc":"embetante","lemma":"embêtant","pos":"ADJ"} ,
		{"word":"embêtantes","word_nosc":"embetantes","lemma":"embêtant","pos":"ADJ"} ,
		{"word":"embêtants","word_nosc":"embetants","lemma":"embêtant","pos":"ADJ"} ,
		{"word":"embêté","word_nosc":"embete","lemma":"embêté","pos":"ADJ"} ,
		{"word":"embêtée","word_nosc":"embetee","lemma":"embêté","pos":"ADJ"} ,
		{"word":"embêtés","word_nosc":"embetes","lemma":"embêté","pos":"ADJ"} ,
		{"word":"emmagasinée","word_nosc":"emmagasinee","lemma":"emmagasiné","pos":"ADJ"} ,
		{"word":"emmagasinées","word_nosc":"emmagasinees","lemma":"emmagasiné","pos":"ADJ"} ,
		{"word":"emmagasinés","word_nosc":"emmagasines","lemma":"emmagasiné","pos":"ADJ"} ,
		{"word":"emmailloté","word_nosc":"emmaillote","lemma":"emmailloté","pos":"ADJ"} ,
		{"word":"emmaillotée","word_nosc":"emmaillotee","lemma":"emmailloté","pos":"ADJ"} ,
		{"word":"emmaillotées","word_nosc":"emmaillotees","lemma":"emmailloté","pos":"ADJ"} ,
		{"word":"emmerdant","word_nosc":"emmerdant","lemma":"emmerdant","pos":"ADJ"} ,
		{"word":"emmerdante","word_nosc":"emmerdante","lemma":"emmerdant","pos":"ADJ"} ,
		{"word":"emmerdantes","word_nosc":"emmerdantes","lemma":"emmerdant","pos":"ADJ"} ,
		{"word":"emmerdants","word_nosc":"emmerdants","lemma":"emmerdant","pos":"ADJ"} ,
		{"word":"emmerdatoires","word_nosc":"emmerdatoires","lemma":"emmerdatoire","pos":"ADJ"} ,
		{"word":"emmerdeur","word_nosc":"emmerdeur","lemma":"emmerdeur","pos":"ADJ"} ,
		{"word":"emmerdeurs","word_nosc":"emmerdeurs","lemma":"emmerdeur","pos":"ADJ"} ,
		{"word":"emmerdeuse","word_nosc":"emmerdeuse","lemma":"emmerdeur","pos":"ADJ"} ,
		{"word":"emmerdeuses","word_nosc":"emmerdeuses","lemma":"emmerdeur","pos":"ADJ"} ,
		{"word":"emmitouflé","word_nosc":"emmitoufle","lemma":"emmitouflé","pos":"ADJ"} ,
		{"word":"emmitouflée","word_nosc":"emmitouflee","lemma":"emmitouflé","pos":"ADJ"} ,
		{"word":"emmitouflées","word_nosc":"emmitouflees","lemma":"emmitouflé","pos":"ADJ"} ,
		{"word":"emmitouflés","word_nosc":"emmitoufles","lemma":"emmitouflé","pos":"ADJ"} ,
		{"word":"emmuré","word_nosc":"emmure","lemma":"emmuré","pos":"ADJ"} ,
		{"word":"emmurée","word_nosc":"emmuree","lemma":"emmuré","pos":"ADJ"} ,
		{"word":"emmurées","word_nosc":"emmurees","lemma":"emmuré","pos":"ADJ"} ,
		{"word":"emmurés","word_nosc":"emmures","lemma":"emmuré","pos":"ADJ"} ,
		{"word":"emmêlé","word_nosc":"emmele","lemma":"emmêlé","pos":"ADJ"} ,
		{"word":"emmêlée","word_nosc":"emmelee","lemma":"emmêlé","pos":"ADJ"} ,
		{"word":"emmêlées","word_nosc":"emmelees","lemma":"emmêlé","pos":"ADJ"} ,
		{"word":"emmêlés","word_nosc":"emmeles","lemma":"emmêlé","pos":"ADJ"} ,
		{"word":"empaillé","word_nosc":"empaille","lemma":"empaillé","pos":"ADJ"} ,
		{"word":"empaillée","word_nosc":"empaillee","lemma":"empaillé","pos":"ADJ"} ,
		{"word":"empaillées","word_nosc":"empaillees","lemma":"empaillé","pos":"ADJ"} ,
		{"word":"empaillés","word_nosc":"empailles","lemma":"empaillé","pos":"ADJ"} ,
		{"word":"empalé","word_nosc":"empale","lemma":"empalé","pos":"ADJ"} ,
		{"word":"empalée","word_nosc":"empalee","lemma":"empalé","pos":"ADJ"} ,
		{"word":"empalés","word_nosc":"empales","lemma":"empalé","pos":"ADJ"} ,
		{"word":"empanaché","word_nosc":"empanache","lemma":"empanaché","pos":"ADJ"} ,
		{"word":"empanachée","word_nosc":"empanachee","lemma":"empanaché","pos":"ADJ"} ,
		{"word":"empanachées","word_nosc":"empanachees","lemma":"empanaché","pos":"ADJ"} ,
		{"word":"empanachés","word_nosc":"empanaches","lemma":"empanaché","pos":"ADJ"} ,
		{"word":"empaqueté","word_nosc":"empaquete","lemma":"empaqueté","pos":"ADJ"} ,
		{"word":"empaquetée","word_nosc":"empaquetee","lemma":"empaqueté","pos":"ADJ"} ,
		{"word":"empaquetées","word_nosc":"empaquetees","lemma":"empaqueté","pos":"ADJ"} ,
		{"word":"empaquetés","word_nosc":"empaquetes","lemma":"empaqueté","pos":"ADJ"} ,
		{"word":"empathique","word_nosc":"empathique","lemma":"empathique","pos":"ADJ"} ,
		{"word":"empathiques","word_nosc":"empathiques","lemma":"empathique","pos":"ADJ"} ,
		{"word":"emperlousées","word_nosc":"emperlousees","lemma":"emperlousé","pos":"ADJ"} ,
		{"word":"emperlé","word_nosc":"emperle","lemma":"emperlé","pos":"ADJ"} ,
		{"word":"emperlées","word_nosc":"emperlees","lemma":"emperlé","pos":"ADJ"} ,
		{"word":"emperlés","word_nosc":"emperles","lemma":"emperlé","pos":"ADJ"} ,
		{"word":"emperruqués","word_nosc":"emperruques","lemma":"emperruqué","pos":"ADJ"} ,
		{"word":"empesé","word_nosc":"empese","lemma":"empesé","pos":"ADJ"} ,
		{"word":"empesée","word_nosc":"empesee","lemma":"empesé","pos":"ADJ"} ,
		{"word":"empesées","word_nosc":"empesees","lemma":"empesé","pos":"ADJ"} ,
		{"word":"empesés","word_nosc":"empeses","lemma":"empesé","pos":"ADJ"} ,
		{"word":"emphatique","word_nosc":"emphatique","lemma":"emphatique","pos":"ADJ"} ,
		{"word":"emphatiques","word_nosc":"emphatiques","lemma":"emphatique","pos":"ADJ"} ,
		{"word":"emphysémateuse","word_nosc":"emphysemateuse","lemma":"emphysémateux","pos":"ADJ"} ,
		{"word":"empierré","word_nosc":"empierre","lemma":"empierré","pos":"ADJ"} ,
		{"word":"empierrée","word_nosc":"empierree","lemma":"empierré","pos":"ADJ"} ,
		{"word":"empierrées","word_nosc":"empierrees","lemma":"empierré","pos":"ADJ"} ,
		{"word":"empierrés","word_nosc":"empierres","lemma":"empierré","pos":"ADJ"} ,
		{"word":"empirique","word_nosc":"empirique","lemma":"empirique","pos":"ADJ"} ,
		{"word":"empiriques","word_nosc":"empiriques","lemma":"empirique","pos":"ADJ"} ,
		{"word":"empli","word_nosc":"empli","lemma":"empli","pos":"ADJ"} ,
		{"word":"emplie","word_nosc":"emplie","lemma":"empli","pos":"ADJ"} ,
		{"word":"emplis","word_nosc":"emplis","lemma":"empli","pos":"ADJ"} ,
		{"word":"employé","word_nosc":"employe","lemma":"employé","pos":"ADJ"} ,
		{"word":"employée","word_nosc":"employee","lemma":"employé","pos":"ADJ"} ,
		{"word":"employées","word_nosc":"employees","lemma":"employé","pos":"ADJ"} ,
		{"word":"employés","word_nosc":"employes","lemma":"employé","pos":"ADJ"} ,
		{"word":"emplumé","word_nosc":"emplume","lemma":"emplumé","pos":"ADJ"} ,
		{"word":"emplumée","word_nosc":"emplumee","lemma":"emplumé","pos":"ADJ"} ,
		{"word":"emplumés","word_nosc":"emplumes","lemma":"emplumé","pos":"ADJ"} ,
		{"word":"empoignant","word_nosc":"empoignant","lemma":"empoignant","pos":"ADJ"} ,
		{"word":"empoisonnant","word_nosc":"empoisonnant","lemma":"empoisonnant","pos":"ADJ"} ,
		{"word":"empoisonnante","word_nosc":"empoisonnante","lemma":"empoisonnant","pos":"ADJ"} ,
		{"word":"empoisonnantes","word_nosc":"empoisonnantes","lemma":"empoisonnant","pos":"ADJ"} ,
		{"word":"emporté","word_nosc":"emporte","lemma":"emporté","pos":"ADJ"} ,
		{"word":"emportée","word_nosc":"emportee","lemma":"emporté","pos":"ADJ"} ,
		{"word":"emportées","word_nosc":"emportees","lemma":"emporté","pos":"ADJ"} ,
		{"word":"emportés","word_nosc":"emportes","lemma":"emporté","pos":"ADJ"} ,
		{"word":"empoté","word_nosc":"empote","lemma":"empoté","pos":"ADJ"} ,
		{"word":"empotée","word_nosc":"empotee","lemma":"empoté","pos":"ADJ"} ,
		{"word":"empotés","word_nosc":"empotes","lemma":"empoté","pos":"ADJ"} ,
		{"word":"empoussiéré","word_nosc":"empoussiere","lemma":"empoussiéré","pos":"ADJ"} ,
		{"word":"empoussiérée","word_nosc":"empoussieree","lemma":"empoussiéré","pos":"ADJ"} ,
		{"word":"empoussiérées","word_nosc":"empoussierees","lemma":"empoussiéré","pos":"ADJ"} ,
		{"word":"empoussiérés","word_nosc":"empoussieres","lemma":"empoussiéré","pos":"ADJ"} ,
		{"word":"empreint","word_nosc":"empreint","lemma":"empreint","pos":"ADJ"} ,
		{"word":"empreints","word_nosc":"empreints","lemma":"empreint","pos":"ADJ"} ,
		{"word":"empressé","word_nosc":"empresse","lemma":"empressé","pos":"ADJ"} ,
		{"word":"empressée","word_nosc":"empressee","lemma":"empressé","pos":"ADJ"} ,
		{"word":"empressées","word_nosc":"empressees","lemma":"empressé","pos":"ADJ"} ,
		{"word":"empressés","word_nosc":"empresses","lemma":"empressé","pos":"ADJ"} ,
		{"word":"emprisonné","word_nosc":"emprisonne","lemma":"emprisonné","pos":"ADJ"} ,
		{"word":"emprisonnée","word_nosc":"emprisonnee","lemma":"emprisonné","pos":"ADJ"} ,
		{"word":"emprisonnées","word_nosc":"emprisonnees","lemma":"emprisonné","pos":"ADJ"} ,
		{"word":"emprisonnés","word_nosc":"emprisonnes","lemma":"emprisonné","pos":"ADJ"} ,
		{"word":"emprunté","word_nosc":"emprunte","lemma":"emprunté","pos":"ADJ"} ,
		{"word":"empruntée","word_nosc":"empruntee","lemma":"emprunté","pos":"ADJ"} ,
		{"word":"empruntées","word_nosc":"empruntees","lemma":"emprunté","pos":"ADJ"} ,
		{"word":"empruntés","word_nosc":"empruntes","lemma":"emprunté","pos":"ADJ"} ,
		{"word":"empuanti","word_nosc":"empuanti","lemma":"empuanti","pos":"ADJ"} ,
		{"word":"empuantie","word_nosc":"empuantie","lemma":"empuanti","pos":"ADJ"} ,
		{"word":"empâté","word_nosc":"empate","lemma":"empâté","pos":"ADJ"} ,
		{"word":"empâtée","word_nosc":"empatee","lemma":"empâté","pos":"ADJ"} ,
		{"word":"empâtées","word_nosc":"empatees","lemma":"empâté","pos":"ADJ"} ,
		{"word":"empâtés","word_nosc":"empates","lemma":"empâté","pos":"ADJ"} ,
		{"word":"empêché","word_nosc":"empeche","lemma":"empêché","pos":"ADJ"} ,
		{"word":"empêchée","word_nosc":"empechee","lemma":"empêché","pos":"ADJ"} ,
		{"word":"empêchés","word_nosc":"empeches","lemma":"empêché","pos":"ADJ"} ,
		{"word":"empêtré","word_nosc":"empetre","lemma":"empêtré","pos":"ADJ"} ,
		{"word":"empêtrée","word_nosc":"empetree","lemma":"empêtré","pos":"ADJ"} ,
		{"word":"empêtrées","word_nosc":"empetrees","lemma":"empêtré","pos":"ADJ"} ,
		{"word":"empêtrés","word_nosc":"empetres","lemma":"empêtré","pos":"ADJ"} ,
		{"word":"enamourée","word_nosc":"enamouree","lemma":"enamouré","pos":"ADJ"} ,
		{"word":"enamourées","word_nosc":"enamourees","lemma":"enamouré","pos":"ADJ"} ,
		{"word":"encadrant","word_nosc":"encadrant","lemma":"encadrant","pos":"ADJ"} ,
		{"word":"encagoulées","word_nosc":"encagoulees","lemma":"encagoulé","pos":"ADJ"} ,
		{"word":"encagoulés","word_nosc":"encagoules","lemma":"encagoulé","pos":"ADJ"} ,
		{"word":"encagé","word_nosc":"encage","lemma":"encagé","pos":"ADJ"} ,
		{"word":"encagée","word_nosc":"encagee","lemma":"encagé","pos":"ADJ"} ,
		{"word":"encagés","word_nosc":"encages","lemma":"encagé","pos":"ADJ"} ,
		{"word":"encaissable","word_nosc":"encaissable","lemma":"encaissable","pos":"ADJ"} ,
		{"word":"encaissant","word_nosc":"encaissant","lemma":"encaissant","pos":"ADJ"} ,
		{"word":"encaissé","word_nosc":"encaisse","lemma":"encaissé","pos":"ADJ"} ,
		{"word":"encaissée","word_nosc":"encaissee","lemma":"encaissé","pos":"ADJ"} ,
		{"word":"encaissées","word_nosc":"encaissees","lemma":"encaissé","pos":"ADJ"} ,
		{"word":"encaissés","word_nosc":"encaisses","lemma":"encaissé","pos":"ADJ"} ,
		{"word":"encalminé","word_nosc":"encalmine","lemma":"encalminé","pos":"ADJ"} ,
		{"word":"encalminés","word_nosc":"encalmines","lemma":"encalminé","pos":"ADJ"} ,
		{"word":"encapuchonné","word_nosc":"encapuchonne","lemma":"encapuchonné","pos":"ADJ"} ,
		{"word":"encapuchonnée","word_nosc":"encapuchonnee","lemma":"encapuchonné","pos":"ADJ"} ,
		{"word":"encapuchonnés","word_nosc":"encapuchonnes","lemma":"encapuchonné","pos":"ADJ"} ,
		{"word":"encastré","word_nosc":"encastre","lemma":"encastré","pos":"ADJ"} ,
		{"word":"encastrée","word_nosc":"encastree","lemma":"encastré","pos":"ADJ"} ,
		{"word":"encastrées","word_nosc":"encastrees","lemma":"encastré","pos":"ADJ"} ,
		{"word":"encastrés","word_nosc":"encastres","lemma":"encastré","pos":"ADJ"} ,
		{"word":"encaustiqué","word_nosc":"encaustique","lemma":"encaustiqué","pos":"ADJ"} ,
		{"word":"encaustiquée","word_nosc":"encaustiquee","lemma":"encaustiqué","pos":"ADJ"} ,
		{"word":"enceinte","word_nosc":"enceinte","lemma":"enceinte","pos":"ADJ"} ,
		{"word":"enceintes","word_nosc":"enceintes","lemma":"enceinte","pos":"ADJ"} ,
		{"word":"enchanteresse","word_nosc":"enchanteresse","lemma":"enchanteur","pos":"ADJ"} ,
		{"word":"enchanteresses","word_nosc":"enchanteresses","lemma":"enchanteur","pos":"ADJ"} ,
		{"word":"enchanteur","word_nosc":"enchanteur","lemma":"enchanteur","pos":"ADJ"} ,
		{"word":"enchanteurs","word_nosc":"enchanteurs","lemma":"enchanteur","pos":"ADJ"} ,
		{"word":"enchanté","word_nosc":"enchante","lemma":"enchanté","pos":"ADJ"} ,
		{"word":"enchantée","word_nosc":"enchantee","lemma":"enchanté","pos":"ADJ"} ,
		{"word":"enchantées","word_nosc":"enchantees","lemma":"enchanté","pos":"ADJ"} ,
		{"word":"enchantés","word_nosc":"enchantes","lemma":"enchanté","pos":"ADJ"} ,
		{"word":"enchaîné","word_nosc":"enchaine","lemma":"enchaîné","pos":"ADJ"} ,
		{"word":"enchaînée","word_nosc":"enchainee","lemma":"enchaîné","pos":"ADJ"} ,
		{"word":"enchaînées","word_nosc":"enchainees","lemma":"enchaîné","pos":"ADJ"} ,
		{"word":"enchaînés","word_nosc":"enchaines","lemma":"enchaîné","pos":"ADJ"} ,
		{"word":"enchevêtré","word_nosc":"enchevetre","lemma":"enchevêtré","pos":"ADJ"} ,
		{"word":"enchevêtrée","word_nosc":"enchevetree","lemma":"enchevêtré","pos":"ADJ"} ,
		{"word":"enchevêtrées","word_nosc":"enchevetrees","lemma":"enchevêtré","pos":"ADJ"} ,
		{"word":"enchevêtrés","word_nosc":"enchevetres","lemma":"enchevêtré","pos":"ADJ"} ,
		{"word":"enchâssé","word_nosc":"enchasse","lemma":"enchâssé","pos":"ADJ"} ,
		{"word":"enchâssée","word_nosc":"enchassee","lemma":"enchâssé","pos":"ADJ"} ,
		{"word":"enchâssés","word_nosc":"enchasses","lemma":"enchâssé","pos":"ADJ"} ,
		{"word":"enclavé","word_nosc":"enclave","lemma":"enclavé","pos":"ADJ"} ,
		{"word":"enclavée","word_nosc":"enclavee","lemma":"enclavé","pos":"ADJ"} ,
		{"word":"enclavées","word_nosc":"enclavees","lemma":"enclavé","pos":"ADJ"} ,
		{"word":"enclenché","word_nosc":"enclenche","lemma":"enclenché","pos":"ADJ"} ,
		{"word":"enclenchée","word_nosc":"enclenchee","lemma":"enclenché","pos":"ADJ"} ,
		{"word":"enclenchées","word_nosc":"enclenchees","lemma":"enclenché","pos":"ADJ"} ,
		{"word":"enclenchés","word_nosc":"enclenches","lemma":"enclenché","pos":"ADJ"} ,
		{"word":"enclin","word_nosc":"enclin","lemma":"enclin","pos":"ADJ"} ,
		{"word":"encline","word_nosc":"encline","lemma":"enclin","pos":"ADJ"} ,
		{"word":"enclines","word_nosc":"enclines","lemma":"enclin","pos":"ADJ"} ,
		{"word":"enclins","word_nosc":"enclins","lemma":"enclin","pos":"ADJ"} ,
		{"word":"encolérée","word_nosc":"encoleree","lemma":"encoléré","pos":"ADJ"} ,
		{"word":"encolérés","word_nosc":"encoleres","lemma":"encoléré","pos":"ADJ"} ,
		{"word":"encombrant","word_nosc":"encombrant","lemma":"encombrant","pos":"ADJ"} ,
		{"word":"encombrante","word_nosc":"encombrante","lemma":"encombrant","pos":"ADJ"} ,
		{"word":"encombrantes","word_nosc":"encombrantes","lemma":"encombrant","pos":"ADJ"} ,
		{"word":"encombrants","word_nosc":"encombrants","lemma":"encombrant","pos":"ADJ"} ,
		{"word":"encombré","word_nosc":"encombre","lemma":"encombré","pos":"ADJ"} ,
		{"word":"encombrée","word_nosc":"encombree","lemma":"encombré","pos":"ADJ"} ,
		{"word":"encombrées","word_nosc":"encombrees","lemma":"encombré","pos":"ADJ"} ,
		{"word":"encombrés","word_nosc":"encombres","lemma":"encombré","pos":"ADJ"} ,
		{"word":"encordé","word_nosc":"encorde","lemma":"encordé","pos":"ADJ"} ,
		{"word":"encordés","word_nosc":"encordes","lemma":"encordé","pos":"ADJ"} ,
		{"word":"encorné","word_nosc":"encorne","lemma":"encorné","pos":"ADJ"} ,
		{"word":"encornée","word_nosc":"encornee","lemma":"encorné","pos":"ADJ"} ,
		{"word":"encornés","word_nosc":"encornes","lemma":"encorné","pos":"ADJ"} ,
		{"word":"encourageant","word_nosc":"encourageant","lemma":"encourageant","pos":"ADJ"} ,
		{"word":"encourageante","word_nosc":"encourageante","lemma":"encourageant","pos":"ADJ"} ,
		{"word":"encourageantes","word_nosc":"encourageantes","lemma":"encourageant","pos":"ADJ"} ,
		{"word":"encourageants","word_nosc":"encourageants","lemma":"encourageant","pos":"ADJ"} ,
		{"word":"encrassé","word_nosc":"encrasse","lemma":"encrassé","pos":"ADJ"} ,
		{"word":"encrassée","word_nosc":"encrassee","lemma":"encrassé","pos":"ADJ"} ,
		{"word":"encrassées","word_nosc":"encrassees","lemma":"encrassé","pos":"ADJ"} ,
		{"word":"encrassés","word_nosc":"encrasses","lemma":"encrassé","pos":"ADJ"} ,
		{"word":"encreur","word_nosc":"encreur","lemma":"encreur","pos":"ADJ"} ,
		{"word":"encreurs","word_nosc":"encreurs","lemma":"encreur","pos":"ADJ"} ,
		{"word":"encroûté","word_nosc":"encroute","lemma":"encroûté","pos":"ADJ"} ,
		{"word":"encroûtés","word_nosc":"encroutes","lemma":"encroûté","pos":"ADJ"} ,
		{"word":"enculé","word_nosc":"encule","lemma":"enculé","pos":"ADJ"} ,
		{"word":"enculée","word_nosc":"enculee","lemma":"enculé","pos":"ADJ"} ,
		{"word":"enculées","word_nosc":"enculees","lemma":"enculé","pos":"ADJ"} ,
		{"word":"enculés","word_nosc":"encules","lemma":"enculé","pos":"ADJ"} ,
		{"word":"encyclopédique","word_nosc":"encyclopedique","lemma":"encyclopédique","pos":"ADJ"} ,
		{"word":"encyclopédiques","word_nosc":"encyclopediques","lemma":"encyclopédique","pos":"ADJ"} ,
		{"word":"encéphalique","word_nosc":"encephalique","lemma":"encéphalique","pos":"ADJ"} ,
		{"word":"encéphaliques","word_nosc":"encephaliques","lemma":"encéphalique","pos":"ADJ"} ,
		{"word":"endetté","word_nosc":"endette","lemma":"endetté","pos":"ADJ"} ,
		{"word":"endettée","word_nosc":"endettee","lemma":"endetté","pos":"ADJ"} ,
		{"word":"endettés","word_nosc":"endettes","lemma":"endetté","pos":"ADJ"} ,
		{"word":"endeuillé","word_nosc":"endeuille","lemma":"endeuillé","pos":"ADJ"} ,
		{"word":"endeuillée","word_nosc":"endeuillee","lemma":"endeuillé","pos":"ADJ"} ,
		{"word":"endeuillées","word_nosc":"endeuillees","lemma":"endeuillé","pos":"ADJ"} ,
		{"word":"endeuillés","word_nosc":"endeuilles","lemma":"endeuillé","pos":"ADJ"} ,
		{"word":"endiablé","word_nosc":"endiable","lemma":"endiablé","pos":"ADJ"} ,
		{"word":"endiablée","word_nosc":"endiablee","lemma":"endiablé","pos":"ADJ"} ,
		{"word":"endiablées","word_nosc":"endiablees","lemma":"endiablé","pos":"ADJ"} ,
		{"word":"endiablés","word_nosc":"endiables","lemma":"endiablé","pos":"ADJ"} ,
		{"word":"endiamanté","word_nosc":"endiamante","lemma":"endiamanté","pos":"ADJ"} ,
		{"word":"endiamantée","word_nosc":"endiamantee","lemma":"endiamanté","pos":"ADJ"} ,
		{"word":"endiamantées","word_nosc":"endiamantees","lemma":"endiamanté","pos":"ADJ"} ,
		{"word":"endiamantés","word_nosc":"endiamantes","lemma":"endiamanté","pos":"ADJ"} ,
		{"word":"endimanché","word_nosc":"endimanche","lemma":"endimanché","pos":"ADJ"} ,
		{"word":"endimanchée","word_nosc":"endimanchee","lemma":"endimanché","pos":"ADJ"} ,
		{"word":"endimanchées","word_nosc":"endimanchees","lemma":"endimanché","pos":"ADJ"} ,
		{"word":"endimanchés","word_nosc":"endimanches","lemma":"endimanché","pos":"ADJ"} ,
		{"word":"endocrine","word_nosc":"endocrine","lemma":"endocrine","pos":"ADJ"} ,
		{"word":"endocrines","word_nosc":"endocrines","lemma":"endocrine","pos":"ADJ"} ,
		{"word":"endocrinien","word_nosc":"endocrinien","lemma":"endocrinien","pos":"ADJ"} ,
		{"word":"endocrinienne","word_nosc":"endocrinienne","lemma":"endocrinien","pos":"ADJ"} ,
		{"word":"endocriniens","word_nosc":"endocriniens","lemma":"endocrinien","pos":"ADJ"} ,
		{"word":"endogène","word_nosc":"endogene","lemma":"endogène","pos":"ADJ"} ,
		{"word":"endolori","word_nosc":"endolori","lemma":"endolori","pos":"ADJ"} ,
		{"word":"endolorie","word_nosc":"endolorie","lemma":"endolori","pos":"ADJ"} ,
		{"word":"endolories","word_nosc":"endolories","lemma":"endolori","pos":"ADJ"} ,
		{"word":"endoloris","word_nosc":"endoloris","lemma":"endolori","pos":"ADJ"} ,
		{"word":"endommagé","word_nosc":"endommage","lemma":"endommagé","pos":"ADJ"} ,
		{"word":"endommagée","word_nosc":"endommagee","lemma":"endommagé","pos":"ADJ"} ,
		{"word":"endommagées","word_nosc":"endommagees","lemma":"endommagé","pos":"ADJ"} ,
		{"word":"endommagés","word_nosc":"endommages","lemma":"endommagé","pos":"ADJ"} ,
		{"word":"endomorphe","word_nosc":"endomorphe","lemma":"endomorphe","pos":"ADJ"} ,
		{"word":"endoplasmique","word_nosc":"endoplasmique","lemma":"endoplasmique","pos":"ADJ"} ,
		{"word":"endormant","word_nosc":"endormant","lemma":"endormant","pos":"ADJ"} ,
		{"word":"endormante","word_nosc":"endormante","lemma":"endormant","pos":"ADJ"} ,
		{"word":"endormi","word_nosc":"endormi","lemma":"endormi","pos":"ADJ"} ,
		{"word":"endormie","word_nosc":"endormie","lemma":"endormi","pos":"ADJ"} ,
		{"word":"endormies","word_nosc":"endormies","lemma":"endormi","pos":"ADJ"} ,
		{"word":"endormis","word_nosc":"endormis","lemma":"endormi","pos":"ADJ"} ,
		{"word":"endoscopique","word_nosc":"endoscopique","lemma":"endoscopique","pos":"ADJ"} ,
		{"word":"endossables","word_nosc":"endossables","lemma":"endossable","pos":"ADJ"} ,
		{"word":"endothermique","word_nosc":"endothermique","lemma":"endothermique","pos":"ADJ"} ,
		{"word":"endurable","word_nosc":"endurable","lemma":"endurable","pos":"ADJ"} ,
		{"word":"endurant","word_nosc":"endurant","lemma":"endurant","pos":"ADJ"} ,
		{"word":"endurante","word_nosc":"endurante","lemma":"endurant","pos":"ADJ"} ,
		{"word":"endurants","word_nosc":"endurants","lemma":"endurant","pos":"ADJ"} ,
		{"word":"endurci","word_nosc":"endurci","lemma":"endurci","pos":"ADJ"} ,
		{"word":"endurcie","word_nosc":"endurcie","lemma":"endurci","pos":"ADJ"} ,
		{"word":"endurcies","word_nosc":"endurcies","lemma":"endurci","pos":"ADJ"} ,
		{"word":"endurcis","word_nosc":"endurcis","lemma":"endurci","pos":"ADJ"} ,
		{"word":"endémique","word_nosc":"endemique","lemma":"endémique","pos":"ADJ"} ,
		{"word":"endémiques","word_nosc":"endemiques","lemma":"endémique","pos":"ADJ"} ,
		{"word":"enfantin","word_nosc":"enfantin","lemma":"enfantin","pos":"ADJ"} ,
		{"word":"enfantine","word_nosc":"enfantine","lemma":"enfantin","pos":"ADJ"} ,
		{"word":"enfantines","word_nosc":"enfantines","lemma":"enfantin","pos":"ADJ"} ,
		{"word":"enfantins","word_nosc":"enfantins","lemma":"enfantin","pos":"ADJ"} ,
		{"word":"enfariné","word_nosc":"enfarine","lemma":"enfariné","pos":"ADJ"} ,
		{"word":"enfarinée","word_nosc":"enfarinee","lemma":"enfariné","pos":"ADJ"} ,
		{"word":"enfarinés","word_nosc":"enfarines","lemma":"enfariné","pos":"ADJ"} ,
		{"word":"enfermé","word_nosc":"enferme","lemma":"enfermé","pos":"ADJ"} ,
		{"word":"enfermée","word_nosc":"enfermee","lemma":"enfermé","pos":"ADJ"} ,
		{"word":"enfermées","word_nosc":"enfermees","lemma":"enfermé","pos":"ADJ"} ,
		{"word":"enfermés","word_nosc":"enfermes","lemma":"enfermé","pos":"ADJ"} ,
		{"word":"enfilé","word_nosc":"enfile","lemma":"enfilé","pos":"ADJ"} ,
		{"word":"enfilée","word_nosc":"enfilee","lemma":"enfilé","pos":"ADJ"} ,
		{"word":"enfilées","word_nosc":"enfilees","lemma":"enfilé","pos":"ADJ"} ,
		{"word":"enfilés","word_nosc":"enfiles","lemma":"enfilé","pos":"ADJ"} ,
		{"word":"enfiévré","word_nosc":"enfievre","lemma":"enfiévré","pos":"ADJ"} ,
		{"word":"enfiévrée","word_nosc":"enfievree","lemma":"enfiévré","pos":"ADJ"} ,
		{"word":"enfiévrées","word_nosc":"enfievrees","lemma":"enfiévré","pos":"ADJ"} ,
		{"word":"enfiévrés","word_nosc":"enfievres","lemma":"enfiévré","pos":"ADJ"} ,
		{"word":"enflammé","word_nosc":"enflamme","lemma":"enflammé","pos":"ADJ"} ,
		{"word":"enflammée","word_nosc":"enflammee","lemma":"enflammé","pos":"ADJ"} ,
		{"word":"enflammées","word_nosc":"enflammees","lemma":"enflammé","pos":"ADJ"} ,
		{"word":"enflammés","word_nosc":"enflammes","lemma":"enflammé","pos":"ADJ"} ,
		{"word":"enflé","word_nosc":"enfle","lemma":"enflé","pos":"ADJ"} ,
		{"word":"enflée","word_nosc":"enflee","lemma":"enflé","pos":"ADJ"} ,
		{"word":"enflées","word_nosc":"enflees","lemma":"enflé","pos":"ADJ"} ,
		{"word":"enflés","word_nosc":"enfles","lemma":"enflé","pos":"ADJ"} ,
		{"word":"enfoiré","word_nosc":"enfoire","lemma":"enfoiré","pos":"ADJ"} ,
		{"word":"enfoirée","word_nosc":"enfoiree","lemma":"enfoiré","pos":"ADJ"} ,
		{"word":"enfoirés","word_nosc":"enfoires","lemma":"enfoiré","pos":"ADJ"} ,
		{"word":"enfoncé","word_nosc":"enfonce","lemma":"enfoncé","pos":"ADJ"} ,
		{"word":"enfoncée","word_nosc":"enfoncee","lemma":"enfoncé","pos":"ADJ"} ,
		{"word":"enfoncées","word_nosc":"enfoncees","lemma":"enfoncé","pos":"ADJ"} ,
		{"word":"enfoncés","word_nosc":"enfonces","lemma":"enfoncé","pos":"ADJ"} ,
		{"word":"enfoui","word_nosc":"enfoui","lemma":"enfoui","pos":"ADJ"} ,
		{"word":"enfouie","word_nosc":"enfouie","lemma":"enfoui","pos":"ADJ"} ,
		{"word":"enfouies","word_nosc":"enfouies","lemma":"enfoui","pos":"ADJ"} ,
		{"word":"enfouis","word_nosc":"enfouis","lemma":"enfoui","pos":"ADJ"} ,
		{"word":"enfouraillé","word_nosc":"enfouraille","lemma":"enfouraillé","pos":"ADJ"} ,
		{"word":"enfouraillée","word_nosc":"enfouraillee","lemma":"enfouraillé","pos":"ADJ"} ,
		{"word":"enfouraillés","word_nosc":"enfourailles","lemma":"enfouraillé","pos":"ADJ"} ,
		{"word":"enfumé","word_nosc":"enfume","lemma":"enfumé","pos":"ADJ"} ,
		{"word":"enfumée","word_nosc":"enfumee","lemma":"enfumé","pos":"ADJ"} ,
		{"word":"enfumées","word_nosc":"enfumees","lemma":"enfumé","pos":"ADJ"} ,
		{"word":"enfumés","word_nosc":"enfumes","lemma":"enfumé","pos":"ADJ"} ,
		{"word":"engageant","word_nosc":"engageant","lemma":"engageant","pos":"ADJ"} ,
		{"word":"engageante","word_nosc":"engageante","lemma":"engageant","pos":"ADJ"} ,
		{"word":"engageantes","word_nosc":"engageantes","lemma":"engageant","pos":"ADJ"} ,
		{"word":"engageants","word_nosc":"engageants","lemma":"engageant","pos":"ADJ"} ,
		{"word":"engagé","word_nosc":"engage","lemma":"engagé","pos":"ADJ"} ,
		{"word":"engagée","word_nosc":"engagee","lemma":"engagé","pos":"ADJ"} ,
		{"word":"engagées","word_nosc":"engagees","lemma":"engagé","pos":"ADJ"} ,
		{"word":"engagés","word_nosc":"engages","lemma":"engagé","pos":"ADJ"} ,
		{"word":"engendreuse","word_nosc":"engendreuse","lemma":"engendreur","pos":"ADJ"} ,
		{"word":"engliche","word_nosc":"engliche","lemma":"engliche","pos":"ADJ"} ,
		{"word":"englobant","word_nosc":"englobant","lemma":"englobant","pos":"ADJ"} ,
		{"word":"englué","word_nosc":"englue","lemma":"englué","pos":"ADJ"} ,
		{"word":"engluée","word_nosc":"engluee","lemma":"englué","pos":"ADJ"} ,
		{"word":"engluées","word_nosc":"engluees","lemma":"englué","pos":"ADJ"} ,
		{"word":"englués","word_nosc":"englues","lemma":"englué","pos":"ADJ"} ,
		{"word":"engoncé","word_nosc":"engonce","lemma":"engoncé","pos":"ADJ"} ,
		{"word":"engoncés","word_nosc":"engonces","lemma":"engoncé","pos":"ADJ"} ,
		{"word":"engorgé","word_nosc":"engorge","lemma":"engorgé","pos":"ADJ"} ,
		{"word":"engorgée","word_nosc":"engorgee","lemma":"engorgé","pos":"ADJ"} ,
		{"word":"engorgées","word_nosc":"engorgees","lemma":"engorgé","pos":"ADJ"} ,
		{"word":"engorgés","word_nosc":"engorges","lemma":"engorgé","pos":"ADJ"} ,
		{"word":"engourdi","word_nosc":"engourdi","lemma":"engourdi","pos":"ADJ"} ,
		{"word":"engourdie","word_nosc":"engourdie","lemma":"engourdi","pos":"ADJ"} ,
		{"word":"engourdies","word_nosc":"engourdies","lemma":"engourdi","pos":"ADJ"} ,
		{"word":"engourdis","word_nosc":"engourdis","lemma":"engourdi","pos":"ADJ"} ,
		{"word":"engourdissant","word_nosc":"engourdissant","lemma":"engourdissant","pos":"ADJ"} ,
		{"word":"engourdissante","word_nosc":"engourdissante","lemma":"engourdissant","pos":"ADJ"} ,
		{"word":"engourdissantes","word_nosc":"engourdissantes","lemma":"engourdissant","pos":"ADJ"} ,
		{"word":"enguirlandée","word_nosc":"enguirlandee","lemma":"enguirlandé","pos":"ADJ"} ,
		{"word":"enguirlandées","word_nosc":"enguirlandees","lemma":"enguirlandé","pos":"ADJ"} ,
		{"word":"enguirlandés","word_nosc":"enguirlandes","lemma":"enguirlandé","pos":"ADJ"} ,
		{"word":"enivrant","word_nosc":"enivrant","lemma":"enivrant","pos":"ADJ"} ,
		{"word":"enivrante","word_nosc":"enivrante","lemma":"enivrant","pos":"ADJ"} ,
		{"word":"enivrantes","word_nosc":"enivrantes","lemma":"enivrant","pos":"ADJ"} ,
		{"word":"enivrants","word_nosc":"enivrants","lemma":"enivrant","pos":"ADJ"} ,
		{"word":"enjoint","word_nosc":"enjoint","lemma":"enjoint","pos":"ADJ"} ,
		{"word":"enjoué","word_nosc":"enjoue","lemma":"enjoué","pos":"ADJ"} ,
		{"word":"enjouée","word_nosc":"enjouee","lemma":"enjoué","pos":"ADJ"} ,
		{"word":"enjouées","word_nosc":"enjouees","lemma":"enjoué","pos":"ADJ"} ,
		{"word":"enjoués","word_nosc":"enjoues","lemma":"enjoué","pos":"ADJ"} ,
		{"word":"enjuivés","word_nosc":"enjuives","lemma":"enjuivé","pos":"ADJ"} ,
		{"word":"enjôleur","word_nosc":"enjoleur","lemma":"enjôleur","pos":"ADJ"} ,
		{"word":"enjôleurs","word_nosc":"enjoleurs","lemma":"enjôleur","pos":"ADJ"} ,
		{"word":"enjôleuse","word_nosc":"enjoleuse","lemma":"enjôleur","pos":"ADJ"} ,
		{"word":"enjôleuses","word_nosc":"enjoleuses","lemma":"enjôleur","pos":"ADJ"} ,
		{"word":"enkystées","word_nosc":"enkystees","lemma":"enkysté","pos":"ADJ"} ,
		{"word":"enlaidie","word_nosc":"enlaidie","lemma":"enlaidi","pos":"ADJ"} ,
		{"word":"enlaçant","word_nosc":"enlacant","lemma":"enlaçant","pos":"ADJ"} ,
		{"word":"enlevé","word_nosc":"enleve","lemma":"enleve","pos":"ADJ"} ,
		{"word":"enlevé","word_nosc":"enleve","lemma":"enlevé","pos":"ADJ"} ,
		{"word":"enlevée","word_nosc":"enlevee","lemma":"enlevé","pos":"ADJ"} ,
		{"word":"enlevées","word_nosc":"enlevees","lemma":"enlevé","pos":"ADJ"} ,
		{"word":"enlevés","word_nosc":"enleves","lemma":"enlevé","pos":"ADJ"} ,
		{"word":"enluminé","word_nosc":"enlumine","lemma":"enluminé","pos":"ADJ"} ,
		{"word":"enluminée","word_nosc":"enluminee","lemma":"enluminé","pos":"ADJ"} ,
		{"word":"enluminées","word_nosc":"enluminees","lemma":"enluminé","pos":"ADJ"} ,
		{"word":"enluminés","word_nosc":"enlumines","lemma":"enluminé","pos":"ADJ"} ,
		{"word":"enneigé","word_nosc":"enneige","lemma":"enneigé","pos":"ADJ"} ,
		{"word":"enneigée","word_nosc":"enneigee","lemma":"enneigé","pos":"ADJ"} ,
		{"word":"enneigées","word_nosc":"enneigees","lemma":"enneigé","pos":"ADJ"} ,
		{"word":"enneigés","word_nosc":"enneiges","lemma":"enneigé","pos":"ADJ"} ,
		{"word":"ennemi","word_nosc":"ennemi","lemma":"ennemi","pos":"ADJ"} ,
		{"word":"ennemie","word_nosc":"ennemie","lemma":"ennemi","pos":"ADJ"} ,
		{"word":"ennemies","word_nosc":"ennemies","lemma":"ennemi","pos":"ADJ"} ,
		{"word":"ennemis","word_nosc":"ennemis","lemma":"ennemi","pos":"ADJ"} ,
		{"word":"ennuyant","word_nosc":"ennuyant","lemma":"ennuyant","pos":"ADJ"} ,
		{"word":"ennuyante","word_nosc":"ennuyante","lemma":"ennuyant","pos":"ADJ"} ,
		{"word":"ennuyeuse","word_nosc":"ennuyeuse","lemma":"ennuyeux","pos":"ADJ"} ,
		{"word":"ennuyeuses","word_nosc":"ennuyeuses","lemma":"ennuyeux","pos":"ADJ"} ,
		{"word":"ennuyeux","word_nosc":"ennuyeux","lemma":"ennuyeux","pos":"ADJ"} ,
		{"word":"ennuyé","word_nosc":"ennuye","lemma":"ennuyé","pos":"ADJ"} ,
		{"word":"ennuyée","word_nosc":"ennuyee","lemma":"ennuyé","pos":"ADJ"} ,
		{"word":"ennuyées","word_nosc":"ennuyees","lemma":"ennuyé","pos":"ADJ"} ,
		{"word":"ennuyés","word_nosc":"ennuyes","lemma":"ennuyé","pos":"ADJ"} ,
		{"word":"enquiquinant","word_nosc":"enquiquinant","lemma":"enquiquinant","pos":"ADJ"} ,
		{"word":"enquiquinante","word_nosc":"enquiquinante","lemma":"enquiquinant","pos":"ADJ"} ,
		{"word":"enquiquinants","word_nosc":"enquiquinants","lemma":"enquiquinant","pos":"ADJ"} ,
		{"word":"enraciné","word_nosc":"enracine","lemma":"enraciné","pos":"ADJ"} ,
		{"word":"enracinée","word_nosc":"enracinee","lemma":"enraciné","pos":"ADJ"} ,
		{"word":"enracinées","word_nosc":"enracinees","lemma":"enraciné","pos":"ADJ"} ,
		{"word":"enracinés","word_nosc":"enracines","lemma":"enraciné","pos":"ADJ"} ,
		{"word":"enrageante","word_nosc":"enrageante","lemma":"enrageant","pos":"ADJ"} ,
		{"word":"enragé","word_nosc":"enrage","lemma":"enragé","pos":"ADJ"} ,
		{"word":"enragée","word_nosc":"enragee","lemma":"enragé","pos":"ADJ"} ,
		{"word":"enragées","word_nosc":"enragees","lemma":"enragé","pos":"ADJ"} ,
		{"word":"enragés","word_nosc":"enrages","lemma":"enragé","pos":"ADJ"} ,
		{"word":"enregistrable","word_nosc":"enregistrable","lemma":"enregistrable","pos":"ADJ"} ,
		{"word":"enregistreur","word_nosc":"enregistreur","lemma":"enregistreur","pos":"ADJ"} ,
		{"word":"enregistreurs","word_nosc":"enregistreurs","lemma":"enregistreur","pos":"ADJ"} ,
		{"word":"enregistreuse","word_nosc":"enregistreuse","lemma":"enregistreur","pos":"ADJ"} ,
		{"word":"enregistreuses","word_nosc":"enregistreuses","lemma":"enregistreur","pos":"ADJ"} ,
		{"word":"enrhumé","word_nosc":"enrhume","lemma":"enrhumé","pos":"ADJ"} ,
		{"word":"enrhumée","word_nosc":"enrhumee","lemma":"enrhumé","pos":"ADJ"} ,
		{"word":"enrhumées","word_nosc":"enrhumees","lemma":"enrhumé","pos":"ADJ"} ,
		{"word":"enrhumés","word_nosc":"enrhumes","lemma":"enrhumé","pos":"ADJ"} ,
		{"word":"enrichi","word_nosc":"enrichi","lemma":"enrichi","pos":"ADJ"} ,
		{"word":"enrichie","word_nosc":"enrichie","lemma":"enrichi","pos":"ADJ"} ,
		{"word":"enrichies","word_nosc":"enrichies","lemma":"enrichi","pos":"ADJ"} ,
		{"word":"enrichis","word_nosc":"enrichis","lemma":"enrichi","pos":"ADJ"} ,
		{"word":"enrichissant","word_nosc":"enrichissant","lemma":"enrichissant","pos":"ADJ"} ,
		{"word":"enrichissante","word_nosc":"enrichissante","lemma":"enrichissant","pos":"ADJ"} ,
		{"word":"enrichissantes","word_nosc":"enrichissantes","lemma":"enrichissant","pos":"ADJ"} ,
		{"word":"enrichissants","word_nosc":"enrichissants","lemma":"enrichissant","pos":"ADJ"} ,
		{"word":"enrobé","word_nosc":"enrobe","lemma":"enrobé","pos":"ADJ"} ,
		{"word":"enrobée","word_nosc":"enrobee","lemma":"enrobé","pos":"ADJ"} ,
		{"word":"enrobées","word_nosc":"enrobees","lemma":"enrobé","pos":"ADJ"} ,
		{"word":"enrobés","word_nosc":"enrobes","lemma":"enrobé","pos":"ADJ"} ,
		{"word":"enroué","word_nosc":"enroue","lemma":"enroué","pos":"ADJ"} ,
		{"word":"enrouée","word_nosc":"enrouee","lemma":"enroué","pos":"ADJ"} ,
		{"word":"enrouées","word_nosc":"enrouees","lemma":"enroué","pos":"ADJ"} ,
		{"word":"enroués","word_nosc":"enroues","lemma":"enroué","pos":"ADJ"} ,
		{"word":"enrubanné","word_nosc":"enrubanne","lemma":"enrubanné","pos":"ADJ"} ,
		{"word":"enrubannée","word_nosc":"enrubannee","lemma":"enrubanné","pos":"ADJ"} ,
		{"word":"enrubannées","word_nosc":"enrubannees","lemma":"enrubanné","pos":"ADJ"} ,
		{"word":"enrubannés","word_nosc":"enrubannes","lemma":"enrubanné","pos":"ADJ"} ,
		{"word":"ensablé","word_nosc":"ensable","lemma":"ensablé","pos":"ADJ"} ,
		{"word":"ensablée","word_nosc":"ensablee","lemma":"ensablé","pos":"ADJ"} ,
		{"word":"ensablées","word_nosc":"ensablees","lemma":"ensablé","pos":"ADJ"} ,
		{"word":"ensablés","word_nosc":"ensables","lemma":"ensablé","pos":"ADJ"} ,
		{"word":"ensanglanté","word_nosc":"ensanglante","lemma":"ensanglanté","pos":"ADJ"} ,
		{"word":"ensanglantée","word_nosc":"ensanglantee","lemma":"ensanglanté","pos":"ADJ"} ,
		{"word":"ensanglantées","word_nosc":"ensanglantees","lemma":"ensanglanté","pos":"ADJ"} ,
		{"word":"ensanglantés","word_nosc":"ensanglantes","lemma":"ensanglanté","pos":"ADJ"} ,
		{"word":"enseignant","word_nosc":"enseignant","lemma":"enseignant","pos":"ADJ"} ,
		{"word":"enseignante","word_nosc":"enseignante","lemma":"enseignant","pos":"ADJ"} ,
		{"word":"enseignantes","word_nosc":"enseignantes","lemma":"enseignant","pos":"ADJ"} ,
		{"word":"enseignants","word_nosc":"enseignants","lemma":"enseignant","pos":"ADJ"} ,
		{"word":"enseigné","word_nosc":"enseigne","lemma":"enseigné","pos":"ADJ"} ,
		{"word":"enseignée","word_nosc":"enseignee","lemma":"enseigné","pos":"ADJ"} ,
		{"word":"enseignés","word_nosc":"enseignes","lemma":"enseigné","pos":"ADJ"} ,
		{"word":"ensellé","word_nosc":"enselle","lemma":"ensellé","pos":"ADJ"} ,
		{"word":"ensellés","word_nosc":"enselles","lemma":"ensellé","pos":"ADJ"} ,
		{"word":"enseveli","word_nosc":"enseveli","lemma":"enseveli","pos":"ADJ"} ,
		{"word":"ensevelie","word_nosc":"ensevelie","lemma":"enseveli","pos":"ADJ"} ,
		{"word":"ensevelies","word_nosc":"ensevelies","lemma":"enseveli","pos":"ADJ"} ,
		{"word":"ensevelis","word_nosc":"ensevelis","lemma":"enseveli","pos":"ADJ"} ,
		{"word":"ensoleillé","word_nosc":"ensoleille","lemma":"ensoleillé","pos":"ADJ"} ,
		{"word":"ensoleillée","word_nosc":"ensoleillee","lemma":"ensoleillé","pos":"ADJ"} ,
		{"word":"ensoleillées","word_nosc":"ensoleillees","lemma":"ensoleillé","pos":"ADJ"} ,
		{"word":"ensoleillés","word_nosc":"ensoleilles","lemma":"ensoleillé","pos":"ADJ"} ,
		{"word":"ensommeillé","word_nosc":"ensommeille","lemma":"ensommeillé","pos":"ADJ"} ,
		{"word":"ensommeillée","word_nosc":"ensommeillee","lemma":"ensommeillé","pos":"ADJ"} ,
		{"word":"ensommeillées","word_nosc":"ensommeillees","lemma":"ensommeillé","pos":"ADJ"} ,
		{"word":"ensommeillés","word_nosc":"ensommeilles","lemma":"ensommeillé","pos":"ADJ"} ,
		{"word":"ensorcelant","word_nosc":"ensorcelant","lemma":"ensorcelant","pos":"ADJ"} ,
		{"word":"ensorcelante","word_nosc":"ensorcelante","lemma":"ensorcelant","pos":"ADJ"} ,
		{"word":"ensorcelantes","word_nosc":"ensorcelantes","lemma":"ensorcelant","pos":"ADJ"} ,
		{"word":"ensorceleur","word_nosc":"ensorceleur","lemma":"ensorceleur","pos":"ADJ"} ,
		{"word":"ensorceleuse","word_nosc":"ensorceleuse","lemma":"ensorceleur","pos":"ADJ"} ,
		{"word":"ensorceleuses","word_nosc":"ensorceleuses","lemma":"ensorceleur","pos":"ADJ"} ,
		{"word":"ensorcelé","word_nosc":"ensorcele","lemma":"ensorcelé","pos":"ADJ"} ,
		{"word":"ensorcelée","word_nosc":"ensorcelee","lemma":"ensorcelé","pos":"ADJ"} ,
		{"word":"ensorcelées","word_nosc":"ensorcelees","lemma":"ensorcelé","pos":"ADJ"} ,
		{"word":"ensorcelés","word_nosc":"ensorceles","lemma":"ensorcelé","pos":"ADJ"} ,
		{"word":"ensoutanée","word_nosc":"ensoutanee","lemma":"ensoutané","pos":"ADJ"} ,
		{"word":"entaché","word_nosc":"entache","lemma":"entaché","pos":"ADJ"} ,
		{"word":"entachée","word_nosc":"entachee","lemma":"entaché","pos":"ADJ"} ,
		{"word":"entachées","word_nosc":"entachees","lemma":"entaché","pos":"ADJ"} ,
		{"word":"entaillé","word_nosc":"entaille","lemma":"entaillé","pos":"ADJ"} ,
		{"word":"entaillée","word_nosc":"entaillee","lemma":"entaillé","pos":"ADJ"} ,
		{"word":"entaillées","word_nosc":"entaillees","lemma":"entaillé","pos":"ADJ"} ,
		{"word":"entaillés","word_nosc":"entailles","lemma":"entaillé","pos":"ADJ"} ,
		{"word":"entendant","word_nosc":"entendant","lemma":"entendant","pos":"ADJ"} ,
		{"word":"entendu","word_nosc":"entendu","lemma":"entendu","pos":"ADJ"} ,
		{"word":"entendue","word_nosc":"entendue","lemma":"entendu","pos":"ADJ"} ,
		{"word":"entendues","word_nosc":"entendues","lemma":"entendu","pos":"ADJ"} ,
		{"word":"entendus","word_nosc":"entendus","lemma":"entendu","pos":"ADJ"} ,
		{"word":"enterré","word_nosc":"enterre","lemma":"enterré","pos":"ADJ"} ,
		{"word":"enterrée","word_nosc":"enterree","lemma":"enterré","pos":"ADJ"} ,
		{"word":"enterrées","word_nosc":"enterrees","lemma":"enterré","pos":"ADJ"} ,
		{"word":"enterrés","word_nosc":"enterres","lemma":"enterré","pos":"ADJ"} ,
		{"word":"enthousiasmant","word_nosc":"enthousiasmant","lemma":"enthousiasmant","pos":"ADJ"} ,
		{"word":"enthousiasmante","word_nosc":"enthousiasmante","lemma":"enthousiasmant","pos":"ADJ"} ,
		{"word":"enthousiaste","word_nosc":"enthousiaste","lemma":"enthousiaste","pos":"ADJ"} ,
		{"word":"enthousiastes","word_nosc":"enthousiastes","lemma":"enthousiaste","pos":"ADJ"} ,
		{"word":"entichée","word_nosc":"entichee","lemma":"entiché","pos":"ADJ"} ,
		{"word":"entier","word_nosc":"entier","lemma":"entier","pos":"ADJ"} ,
		{"word":"entiers","word_nosc":"entiers","lemma":"entier","pos":"ADJ"} ,
		{"word":"entière","word_nosc":"entiere","lemma":"entier","pos":"ADJ"} ,
		{"word":"entières","word_nosc":"entieres","lemma":"entier","pos":"ADJ"} ,
		{"word":"entomologique","word_nosc":"entomologique","lemma":"entomologique","pos":"ADJ"} ,
		{"word":"entortillé","word_nosc":"entortille","lemma":"entortillé","pos":"ADJ"} ,
		{"word":"entortillée","word_nosc":"entortillee","lemma":"entortillé","pos":"ADJ"} ,
		{"word":"entortillés","word_nosc":"entortilles","lemma":"entortillé","pos":"ADJ"} ,
		{"word":"entouré","word_nosc":"entoure","lemma":"entouré","pos":"ADJ"} ,
		{"word":"entourée","word_nosc":"entouree","lemma":"entouré","pos":"ADJ"} ,
		{"word":"entourées","word_nosc":"entourees","lemma":"entouré","pos":"ADJ"} ,
		{"word":"entourés","word_nosc":"entoures","lemma":"entouré","pos":"ADJ"} ,
		{"word":"entr'ouvert","word_nosc":"entr'ouvert","lemma":"entr'ouvert","pos":"ADJ"} ,
		{"word":"entr'ouverte","word_nosc":"entr'ouverte","lemma":"entr'ouvert","pos":"ADJ"} ,
		{"word":"entr'ouvertes","word_nosc":"entr'ouvertes","lemma":"entr'ouvert","pos":"ADJ"} ,
		{"word":"entr'ouverts","word_nosc":"entr'ouverts","lemma":"entr'ouvert","pos":"ADJ"} ,
		{"word":"entrant","word_nosc":"entrant","lemma":"entrant","pos":"ADJ"} ,
		{"word":"entrante","word_nosc":"entrante","lemma":"entrant","pos":"ADJ"} ,
		{"word":"entrantes","word_nosc":"entrantes","lemma":"entrant","pos":"ADJ"} ,
		{"word":"entrants","word_nosc":"entrants","lemma":"entrant","pos":"ADJ"} ,
		{"word":"entravé","word_nosc":"entrave","lemma":"entravé","pos":"ADJ"} ,
		{"word":"entravée","word_nosc":"entravee","lemma":"entravé","pos":"ADJ"} ,
		{"word":"entravées","word_nosc":"entravees","lemma":"entravé","pos":"ADJ"} ,
		{"word":"entravés","word_nosc":"entraves","lemma":"entravé","pos":"ADJ"} ,
		{"word":"entraînant","word_nosc":"entrainant","lemma":"entraînant","pos":"ADJ"} ,
		{"word":"entraînante","word_nosc":"entrainante","lemma":"entraînant","pos":"ADJ"} ,
		{"word":"entraînants","word_nosc":"entrainants","lemma":"entraînant","pos":"ADJ"} ,
		{"word":"entraîné","word_nosc":"entraine","lemma":"entraîné","pos":"ADJ"} ,
		{"word":"entraînée","word_nosc":"entrainee","lemma":"entraîné","pos":"ADJ"} ,
		{"word":"entraînées","word_nosc":"entrainees","lemma":"entraîné","pos":"ADJ"} ,
		{"word":"entraînés","word_nosc":"entraines","lemma":"entraîné","pos":"ADJ"} ,
		{"word":"entrebâillé","word_nosc":"entrebaille","lemma":"entrebâillé","pos":"ADJ"} ,
		{"word":"entrebâillée","word_nosc":"entrebaillee","lemma":"entrebâillé","pos":"ADJ"} ,
		{"word":"entrebâillées","word_nosc":"entrebaillees","lemma":"entrebâillé","pos":"ADJ"} ,
		{"word":"entrebâillés","word_nosc":"entrebailles","lemma":"entrebâillé","pos":"ADJ"} ,
		{"word":"entrecloses","word_nosc":"entrecloses","lemma":"entreclos","pos":"ADJ"} ,
		{"word":"entrecoupé","word_nosc":"entrecoupe","lemma":"entrecoupé","pos":"ADJ"} ,
		{"word":"entrecoupée","word_nosc":"entrecoupee","lemma":"entrecoupé","pos":"ADJ"} ,
		{"word":"entrecoupées","word_nosc":"entrecoupees","lemma":"entrecoupé","pos":"ADJ"} ,
		{"word":"entrecoupés","word_nosc":"entrecoupes","lemma":"entrecoupé","pos":"ADJ"} ,
		{"word":"entrelacé","word_nosc":"entrelace","lemma":"entrelacé","pos":"ADJ"} ,
		{"word":"entrelacée","word_nosc":"entrelacee","lemma":"entrelacé","pos":"ADJ"} ,
		{"word":"entrelacées","word_nosc":"entrelacees","lemma":"entrelacé","pos":"ADJ"} ,
		{"word":"entrelacés","word_nosc":"entrelaces","lemma":"entrelacé","pos":"ADJ"} ,
		{"word":"entrelardé","word_nosc":"entrelarde","lemma":"entrelardé","pos":"ADJ"} ,
		{"word":"entreposé","word_nosc":"entrepose","lemma":"entreposé","pos":"ADJ"} ,
		{"word":"entreposée","word_nosc":"entreposee","lemma":"entreposé","pos":"ADJ"} ,
		{"word":"entreposées","word_nosc":"entreposees","lemma":"entreposé","pos":"ADJ"} ,
		{"word":"entreposés","word_nosc":"entreposes","lemma":"entreposé","pos":"ADJ"} ,
		{"word":"entreprenant","word_nosc":"entreprenant","lemma":"entreprenant","pos":"ADJ"} ,
		{"word":"entreprenante","word_nosc":"entreprenante","lemma":"entreprenant","pos":"ADJ"} ,
		{"word":"entreprenantes","word_nosc":"entreprenantes","lemma":"entreprenant","pos":"ADJ"} ,
		{"word":"entreprenants","word_nosc":"entreprenants","lemma":"entreprenant","pos":"ADJ"} ,
		{"word":"entretenu","word_nosc":"entretenu","lemma":"entretenu","pos":"ADJ"} ,
		{"word":"entretenue","word_nosc":"entretenue","lemma":"entretenu","pos":"ADJ"} ,
		{"word":"entretenues","word_nosc":"entretenues","lemma":"entretenu","pos":"ADJ"} ,
		{"word":"entretenus","word_nosc":"entretenus","lemma":"entretenu","pos":"ADJ"} ,
		{"word":"entropique","word_nosc":"entropique","lemma":"entropique","pos":"ADJ"} ,
		{"word":"entropiques","word_nosc":"entropiques","lemma":"entropique","pos":"ADJ"} ,
		{"word":"entrouvert","word_nosc":"entrouvert","lemma":"entrouvert","pos":"ADJ"} ,
		{"word":"entrouverte","word_nosc":"entrouverte","lemma":"entrouvert","pos":"ADJ"} ,
		{"word":"entrouvertes","word_nosc":"entrouvertes","lemma":"entrouvert","pos":"ADJ"} ,
		{"word":"entrouverts","word_nosc":"entrouverts","lemma":"entrouvert","pos":"ADJ"} ,
		{"word":"enturbanné","word_nosc":"enturbanne","lemma":"enturbanné","pos":"ADJ"} ,
		{"word":"enturbannée","word_nosc":"enturbannee","lemma":"enturbanné","pos":"ADJ"} ,
		{"word":"enturbannées","word_nosc":"enturbannees","lemma":"enturbanné","pos":"ADJ"} ,
		{"word":"enturbannés","word_nosc":"enturbannes","lemma":"enturbanné","pos":"ADJ"} ,
		{"word":"entérique","word_nosc":"enterique","lemma":"entérique","pos":"ADJ"} ,
		{"word":"entêtant","word_nosc":"entetant","lemma":"entêtant","pos":"ADJ"} ,
		{"word":"entêtante","word_nosc":"entetante","lemma":"entêtant","pos":"ADJ"} ,
		{"word":"entêtantes","word_nosc":"entetantes","lemma":"entêtant","pos":"ADJ"} ,
		{"word":"entêtants","word_nosc":"entetants","lemma":"entêtant","pos":"ADJ"} ,
		{"word":"entêté","word_nosc":"entete","lemma":"entêté","pos":"ADJ"} ,
		{"word":"entêtée","word_nosc":"entetee","lemma":"entêté","pos":"ADJ"} ,
		{"word":"entêtées","word_nosc":"entetees","lemma":"entêté","pos":"ADJ"} ,
		{"word":"entêtés","word_nosc":"entetes","lemma":"entêté","pos":"ADJ"} ,
		{"word":"envahi","word_nosc":"envahi","lemma":"envahi","pos":"ADJ"} ,
		{"word":"envahie","word_nosc":"envahie","lemma":"envahi","pos":"ADJ"} ,
		{"word":"envahies","word_nosc":"envahies","lemma":"envahi","pos":"ADJ"} ,
		{"word":"envahis","word_nosc":"envahis","lemma":"envahi","pos":"ADJ"} ,
		{"word":"envahissant","word_nosc":"envahissant","lemma":"envahissant","pos":"ADJ"} ,
		{"word":"envahissante","word_nosc":"envahissante","lemma":"envahissant","pos":"ADJ"} ,
		{"word":"envahissantes","word_nosc":"envahissantes","lemma":"envahissant","pos":"ADJ"} ,
		{"word":"envahissants","word_nosc":"envahissants","lemma":"envahissant","pos":"ADJ"} ,
		{"word":"enveloppant","word_nosc":"enveloppant","lemma":"enveloppant","pos":"ADJ"} ,
		{"word":"enveloppante","word_nosc":"enveloppante","lemma":"enveloppant","pos":"ADJ"} ,
		{"word":"enveloppantes","word_nosc":"enveloppantes","lemma":"enveloppant","pos":"ADJ"} ,
		{"word":"enveloppants","word_nosc":"enveloppants","lemma":"enveloppant","pos":"ADJ"} ,
		{"word":"enveloppé","word_nosc":"enveloppe","lemma":"enveloppé","pos":"ADJ"} ,
		{"word":"enveloppée","word_nosc":"enveloppee","lemma":"enveloppé","pos":"ADJ"} ,
		{"word":"enveloppées","word_nosc":"enveloppees","lemma":"enveloppé","pos":"ADJ"} ,
		{"word":"enveloppés","word_nosc":"enveloppes","lemma":"enveloppé","pos":"ADJ"} ,
		{"word":"envenimé","word_nosc":"envenime","lemma":"envenimé","pos":"ADJ"} ,
		{"word":"envenimée","word_nosc":"envenimee","lemma":"envenimé","pos":"ADJ"} ,
		{"word":"enviable","word_nosc":"enviable","lemma":"enviable","pos":"ADJ"} ,
		{"word":"enviables","word_nosc":"enviables","lemma":"enviable","pos":"ADJ"} ,
		{"word":"envieuse","word_nosc":"envieuse","lemma":"envieux","pos":"ADJ"} ,
		{"word":"envieuses","word_nosc":"envieuses","lemma":"envieux","pos":"ADJ"} ,
		{"word":"envieux","word_nosc":"envieux","lemma":"envieux","pos":"ADJ"} ,
		{"word":"environnant","word_nosc":"environnant","lemma":"environnant","pos":"ADJ"} ,
		{"word":"environnante","word_nosc":"environnante","lemma":"environnant","pos":"ADJ"} ,
		{"word":"environnantes","word_nosc":"environnantes","lemma":"environnant","pos":"ADJ"} ,
		{"word":"environnants","word_nosc":"environnants","lemma":"environnant","pos":"ADJ"} ,
		{"word":"environnemental","word_nosc":"environnemental","lemma":"environnemental","pos":"ADJ"} ,
		{"word":"environnementale","word_nosc":"environnementale","lemma":"environnemental","pos":"ADJ"} ,
		{"word":"environnementaux","word_nosc":"environnementaux","lemma":"environnemental","pos":"ADJ"} ,
		{"word":"envisageable","word_nosc":"envisageable","lemma":"envisageable","pos":"ADJ"} ,
		{"word":"envisageables","word_nosc":"envisageables","lemma":"envisageable","pos":"ADJ"} ,
		{"word":"envisagé","word_nosc":"envisage","lemma":"envisagé","pos":"ADJ"} ,
		{"word":"envisagée","word_nosc":"envisagee","lemma":"envisagé","pos":"ADJ"} ,
		{"word":"envisagées","word_nosc":"envisagees","lemma":"envisagé","pos":"ADJ"} ,
		{"word":"envisagés","word_nosc":"envisages","lemma":"envisagé","pos":"ADJ"} ,
		{"word":"envoûtant","word_nosc":"envoutant","lemma":"envoûtant","pos":"ADJ"} ,
		{"word":"envoûtante","word_nosc":"envoutante","lemma":"envoûtant","pos":"ADJ"} ,
		{"word":"envoûtantes","word_nosc":"envoutantes","lemma":"envoûtant","pos":"ADJ"} ,
		{"word":"envoûtants","word_nosc":"envoutants","lemma":"envoûtant","pos":"ADJ"} ,
		{"word":"envoûté","word_nosc":"envoute","lemma":"envoûté","pos":"ADJ"} ,
		{"word":"envoûtée","word_nosc":"envoutee","lemma":"envoûté","pos":"ADJ"} ,
		{"word":"envoûtés","word_nosc":"envoutes","lemma":"envoûté","pos":"ADJ"} ,
		{"word":"enzymatique","word_nosc":"enzymatique","lemma":"enzymatique","pos":"ADJ"} ,
		{"word":"ergonomique","word_nosc":"ergonomique","lemma":"ergonomique","pos":"ADJ"} ,
		{"word":"ergoteur","word_nosc":"ergoteur","lemma":"ergoteur","pos":"ADJ"} ,
		{"word":"ergoteurs","word_nosc":"ergoteurs","lemma":"ergoteur","pos":"ADJ"} ,
		{"word":"ergoteuse","word_nosc":"ergoteuse","lemma":"ergoteur","pos":"ADJ"} ,
		{"word":"ergoté","word_nosc":"ergote","lemma":"ergoté","pos":"ADJ"} ,
		{"word":"errant","word_nosc":"errant","lemma":"errant","pos":"ADJ"} ,
		{"word":"errante","word_nosc":"errante","lemma":"errant","pos":"ADJ"} ,
		{"word":"errantes","word_nosc":"errantes","lemma":"errant","pos":"ADJ"} ,
		{"word":"errants","word_nosc":"errants","lemma":"errant","pos":"ADJ"} ,
		{"word":"erratique","word_nosc":"erratique","lemma":"erratique","pos":"ADJ"} ,
		{"word":"erratiques","word_nosc":"erratiques","lemma":"erratique","pos":"ADJ"} ,
		{"word":"erroné","word_nosc":"errone","lemma":"erroné","pos":"ADJ"} ,
		{"word":"erronée","word_nosc":"erronee","lemma":"erroné","pos":"ADJ"} ,
		{"word":"erronées","word_nosc":"erronees","lemma":"erroné","pos":"ADJ"} ,
		{"word":"erronés","word_nosc":"errones","lemma":"erroné","pos":"ADJ"} ,
		{"word":"escamotable","word_nosc":"escamotable","lemma":"escamotable","pos":"ADJ"} ,
		{"word":"escamotables","word_nosc":"escamotables","lemma":"escamotable","pos":"ADJ"} ,
		{"word":"escarpé","word_nosc":"escarpe","lemma":"escarpé","pos":"ADJ"} ,
		{"word":"escarpée","word_nosc":"escarpee","lemma":"escarpé","pos":"ADJ"} ,
		{"word":"escarpées","word_nosc":"escarpees","lemma":"escarpé","pos":"ADJ"} ,
		{"word":"escarpés","word_nosc":"escarpes","lemma":"escarpé","pos":"ADJ"} ,
		{"word":"esclavagiste","word_nosc":"esclavagiste","lemma":"esclavagiste","pos":"ADJ"} ,
		{"word":"esclavagistes","word_nosc":"esclavagistes","lemma":"esclavagiste","pos":"ADJ"} ,
		{"word":"esclave","word_nosc":"esclave","lemma":"esclave","pos":"ADJ"} ,
		{"word":"esclaves","word_nosc":"esclaves","lemma":"esclave","pos":"ADJ"} ,
		{"word":"eskimo","word_nosc":"eskimo","lemma":"eskimo","pos":"ADJ"} ,
		{"word":"eskimos","word_nosc":"eskimos","lemma":"eskimo","pos":"ADJ"} ,
		{"word":"espacé","word_nosc":"espace","lemma":"espacé","pos":"ADJ"} ,
		{"word":"espacée","word_nosc":"espacee","lemma":"espacé","pos":"ADJ"} ,
		{"word":"espacées","word_nosc":"espacees","lemma":"espacé","pos":"ADJ"} ,
		{"word":"espacés","word_nosc":"espaces","lemma":"espacé","pos":"ADJ"} ,
		{"word":"espagnol","word_nosc":"espagnol","lemma":"espagnol","pos":"ADJ"} ,
		{"word":"espagnole","word_nosc":"espagnole","lemma":"espagnol","pos":"ADJ"} ,
		{"word":"espagnoles","word_nosc":"espagnoles","lemma":"espagnol","pos":"ADJ"} ,
		{"word":"espagnols","word_nosc":"espagnols","lemma":"espagnol","pos":"ADJ"} ,
		{"word":"espiègle","word_nosc":"espiegle","lemma":"espiègle","pos":"ADJ"} ,
		{"word":"espiègles","word_nosc":"espiegles","lemma":"espiègle","pos":"ADJ"} ,
		{"word":"esquimau","word_nosc":"esquimau","lemma":"esquimau","pos":"ADJ"} ,
		{"word":"esquimaude","word_nosc":"esquimaude","lemma":"esquimaud","pos":"ADJ"} ,
		{"word":"esquimaudes","word_nosc":"esquimaudes","lemma":"esquimaud","pos":"ADJ"} ,
		{"word":"esquimaux","word_nosc":"esquimaux","lemma":"esquimau","pos":"ADJ"} ,
		{"word":"esquinté","word_nosc":"esquinte","lemma":"esquinté","pos":"ADJ"} ,
		{"word":"esquintée","word_nosc":"esquintee","lemma":"esquinté","pos":"ADJ"} ,
		{"word":"esquintés","word_nosc":"esquintes","lemma":"esquinté","pos":"ADJ"} ,
		{"word":"essential","word_nosc":"essential","lemma":"essential","pos":"ADJ"} ,
		{"word":"essentiel","word_nosc":"essentiel","lemma":"essentiel","pos":"ADJ"} ,
		{"word":"essentielle","word_nosc":"essentielle","lemma":"essentiel","pos":"ADJ"} ,
		{"word":"essentielles","word_nosc":"essentielles","lemma":"essentiel","pos":"ADJ"} ,
		{"word":"essentiels","word_nosc":"essentiels","lemma":"essentiel","pos":"ADJ"} ,
		{"word":"esseulé","word_nosc":"esseule","lemma":"esseulé","pos":"ADJ"} ,
		{"word":"esseulée","word_nosc":"esseulee","lemma":"esseulé","pos":"ADJ"} ,
		{"word":"esseulées","word_nosc":"esseulees","lemma":"esseulé","pos":"ADJ"} ,
		{"word":"esseulés","word_nosc":"esseules","lemma":"esseulé","pos":"ADJ"} ,
		{"word":"essoré","word_nosc":"essore","lemma":"essoré","pos":"ADJ"} ,
		{"word":"essorée","word_nosc":"essoree","lemma":"essoré","pos":"ADJ"} ,
		{"word":"essorés","word_nosc":"essores","lemma":"essoré","pos":"ADJ"} ,
		{"word":"essoufflé","word_nosc":"essouffle","lemma":"essoufflé","pos":"ADJ"} ,
		{"word":"essoufflée","word_nosc":"essoufflee","lemma":"essoufflé","pos":"ADJ"} ,
		{"word":"essoufflées","word_nosc":"essoufflees","lemma":"essoufflé","pos":"ADJ"} ,
		{"word":"essoufflés","word_nosc":"essouffles","lemma":"essoufflé","pos":"ADJ"} ,
		{"word":"est","word_nosc":"est","lemma":"est","pos":"ADJ"} ,
		{"word":"est-africain","word_nosc":"est-africain","lemma":"est-africain","pos":"ADJ"} ,
		{"word":"est-allemand","word_nosc":"est-allemand","lemma":"est-allemand","pos":"ADJ"} ,
		{"word":"est-allemande","word_nosc":"est-allemande","lemma":"est-allemand","pos":"ADJ"} ,
		{"word":"est-allemands","word_nosc":"est-allemands","lemma":"est-allemand","pos":"ADJ"} ,
		{"word":"est-ouest","word_nosc":"est-ouest","lemma":"est-ouest","pos":"ADJ"} ,
		{"word":"estampillé","word_nosc":"estampille","lemma":"estampillé","pos":"ADJ"} ,
		{"word":"estampillées","word_nosc":"estampillees","lemma":"estampillé","pos":"ADJ"} ,
		{"word":"estampillés","word_nosc":"estampilles","lemma":"estampillé","pos":"ADJ"} ,
		{"word":"esthétique","word_nosc":"esthetique","lemma":"esthétique","pos":"ADJ"} ,
		{"word":"esthétiques","word_nosc":"esthetiques","lemma":"esthétique","pos":"ADJ"} ,
		{"word":"estimable","word_nosc":"estimable","lemma":"estimable","pos":"ADJ"} ,
		{"word":"estimables","word_nosc":"estimables","lemma":"estimable","pos":"ADJ"} ,
		{"word":"estival","word_nosc":"estival","lemma":"estival","pos":"ADJ"} ,
		{"word":"estivale","word_nosc":"estivale","lemma":"estival","pos":"ADJ"} ,
		{"word":"estivales","word_nosc":"estivales","lemma":"estival","pos":"ADJ"} ,
		{"word":"estivaux","word_nosc":"estivaux","lemma":"estival","pos":"ADJ"} ,
		{"word":"estompé","word_nosc":"estompe","lemma":"estompé","pos":"ADJ"} ,
		{"word":"estompée","word_nosc":"estompee","lemma":"estompé","pos":"ADJ"} ,
		{"word":"estompées","word_nosc":"estompees","lemma":"estompé","pos":"ADJ"} ,
		{"word":"estompés","word_nosc":"estompes","lemma":"estompé","pos":"ADJ"} ,
		{"word":"estonien","word_nosc":"estonien","lemma":"estonien","pos":"ADJ"} ,
		{"word":"estropié","word_nosc":"estropie","lemma":"estropié","pos":"ADJ"} ,
		{"word":"estropiée","word_nosc":"estropiee","lemma":"estropié","pos":"ADJ"} ,
		{"word":"estropiés","word_nosc":"estropies","lemma":"estropié","pos":"ADJ"} ,
		{"word":"estudiantin","word_nosc":"estudiantin","lemma":"estudiantin","pos":"ADJ"} ,
		{"word":"estudiantine","word_nosc":"estudiantine","lemma":"estudiantin","pos":"ADJ"} ,
		{"word":"estudiantines","word_nosc":"estudiantines","lemma":"estudiantin","pos":"ADJ"} ,
		{"word":"estudiantins","word_nosc":"estudiantins","lemma":"estudiantin","pos":"ADJ"} ,
		{"word":"ethmoïdal","word_nosc":"ethmoidal","lemma":"ethmoïdal","pos":"ADJ"} ,
		{"word":"ethmoïdaux","word_nosc":"ethmoidaux","lemma":"ethmoïdal","pos":"ADJ"} ,
		{"word":"ethnique","word_nosc":"ethnique","lemma":"ethnique","pos":"ADJ"} ,
		{"word":"ethniques","word_nosc":"ethniques","lemma":"ethnique","pos":"ADJ"} ,
		{"word":"ethnographique","word_nosc":"ethnographique","lemma":"ethnographique","pos":"ADJ"} ,
		{"word":"ethnologique","word_nosc":"ethnologique","lemma":"ethnologique","pos":"ADJ"} ,
		{"word":"ethnologiques","word_nosc":"ethnologiques","lemma":"ethnologique","pos":"ADJ"} ,
		{"word":"eucharistique","word_nosc":"eucharistique","lemma":"eucharistique","pos":"ADJ"} ,
		{"word":"eucharistiques","word_nosc":"eucharistiques","lemma":"eucharistique","pos":"ADJ"} ,
		{"word":"euclidien","word_nosc":"euclidien","lemma":"euclidien","pos":"ADJ"} ,
		{"word":"euclidienne","word_nosc":"euclidienne","lemma":"euclidien","pos":"ADJ"} ,
		{"word":"euclidiens","word_nosc":"euclidiens","lemma":"euclidien","pos":"ADJ"} ,
		{"word":"eues","word_nosc":"eues","lemma":"eue","pos":"ADJ"} ,
		{"word":"eugénique","word_nosc":"eugenique","lemma":"eugénique","pos":"ADJ"} ,
		{"word":"euphonique","word_nosc":"euphonique","lemma":"euphonique","pos":"ADJ"} ,
		{"word":"euphoniques","word_nosc":"euphoniques","lemma":"euphonique","pos":"ADJ"} ,
		{"word":"euphorique","word_nosc":"euphorique","lemma":"euphorique","pos":"ADJ"} ,
		{"word":"euphorisant","word_nosc":"euphorisant","lemma":"euphorisant","pos":"ADJ"} ,
		{"word":"euphorisante","word_nosc":"euphorisante","lemma":"euphorisant","pos":"ADJ"} ,
		{"word":"euphorisants","word_nosc":"euphorisants","lemma":"euphorisant","pos":"ADJ"} ,
		{"word":"eurafricaine","word_nosc":"eurafricaine","lemma":"eurafricain","pos":"ADJ"} ,
		{"word":"eurafricains","word_nosc":"eurafricains","lemma":"eurafricain","pos":"ADJ"} ,
		{"word":"eurasien","word_nosc":"eurasien","lemma":"eurasien","pos":"ADJ"} ,
		{"word":"eurasienne","word_nosc":"eurasienne","lemma":"eurasienne","pos":"ADJ"} ,
		{"word":"euro-africaines","word_nosc":"euro-africaines","lemma":"euro-africain","pos":"ADJ"} ,
		{"word":"européen","word_nosc":"europeen","lemma":"européen","pos":"ADJ"} ,
		{"word":"européenne","word_nosc":"europeenne","lemma":"européen","pos":"ADJ"} ,
		{"word":"européennes","word_nosc":"europeennes","lemma":"européen","pos":"ADJ"} ,
		{"word":"européens","word_nosc":"europeens","lemma":"européen","pos":"ADJ"} ,
		{"word":"eurythmiques","word_nosc":"eurythmiques","lemma":"eurythmique","pos":"ADJ"} ,
		{"word":"ex-associé","word_nosc":"ex-associe","lemma":"ex-associé","pos":"ADJ"} ,
		{"word":"ex-gauchiste","word_nosc":"ex-gauchiste","lemma":"ex-gauchiste","pos":"ADJ"} ,
		{"word":"ex-marine","word_nosc":"ex-marine","lemma":"ex-marine","pos":"ADJ"} ,
		{"word":"ex-militaires","word_nosc":"ex-militaires","lemma":"ex-militaire","pos":"ADJ"} ,
		{"word":"ex-petit","word_nosc":"ex-petit","lemma":"ex-petit","pos":"ADJ"} ,
		{"word":"ex-petite","word_nosc":"ex-petite","lemma":"ex-petit","pos":"ADJ"} ,
		{"word":"ex-petits","word_nosc":"ex-petits","lemma":"ex-petit","pos":"ADJ"} ,
		{"word":"ex-pilote","word_nosc":"ex-pilote","lemma":"ex-pilote","pos":"ADJ"} ,
		{"word":"ex-plaquée","word_nosc":"ex-plaquee","lemma":"ex-plaqué","pos":"ADJ"} ,
		{"word":"ex-premier","word_nosc":"ex-premier","lemma":"ex-premier","pos":"ADJ"} ,
		{"word":"ex-rebelle","word_nosc":"ex-rebelle","lemma":"ex-rebelle","pos":"ADJ"} ,
		{"word":"ex-super","word_nosc":"ex-super","lemma":"ex-super","pos":"ADJ"} ,
		{"word":"ex-sévère","word_nosc":"ex-severe","lemma":"ex-sévère","pos":"ADJ"} ,
		{"word":"exacerbé","word_nosc":"exacerbe","lemma":"exacerbé","pos":"ADJ"} ,
		{"word":"exacerbée","word_nosc":"exacerbee","lemma":"exacerbé","pos":"ADJ"} ,
		{"word":"exacerbées","word_nosc":"exacerbees","lemma":"exacerbé","pos":"ADJ"} ,
		{"word":"exacerbés","word_nosc":"exacerbes","lemma":"exacerbé","pos":"ADJ"} ,
		{"word":"exact","word_nosc":"exact","lemma":"exact","pos":"ADJ"} ,
		{"word":"exacte","word_nosc":"exacte","lemma":"exact","pos":"ADJ"} ,
		{"word":"exactes","word_nosc":"exactes","lemma":"exact","pos":"ADJ"} ,
		{"word":"exacts","word_nosc":"exacts","lemma":"exact","pos":"ADJ"} ,
		{"word":"exagéré","word_nosc":"exagere","lemma":"exagéré","pos":"ADJ"} ,
		{"word":"exagérée","word_nosc":"exageree","lemma":"exagéré","pos":"ADJ"} ,
		{"word":"exagérées","word_nosc":"exagerees","lemma":"exagéré","pos":"ADJ"} ,
		{"word":"exagérés","word_nosc":"exageres","lemma":"exagéré","pos":"ADJ"} ,
		{"word":"exaltant","word_nosc":"exaltant","lemma":"exaltant","pos":"ADJ"} ,
		{"word":"exaltante","word_nosc":"exaltante","lemma":"exaltant","pos":"ADJ"} ,
		{"word":"exaltantes","word_nosc":"exaltantes","lemma":"exaltant","pos":"ADJ"} ,
		{"word":"exaltants","word_nosc":"exaltants","lemma":"exaltant","pos":"ADJ"} ,
		{"word":"exalté","word_nosc":"exalte","lemma":"exalté","pos":"ADJ"} ,
		{"word":"exaltée","word_nosc":"exaltee","lemma":"exalté","pos":"ADJ"} ,
		{"word":"exaltées","word_nosc":"exaltees","lemma":"exalté","pos":"ADJ"} ,
		{"word":"exaltés","word_nosc":"exaltes","lemma":"exalté","pos":"ADJ"} ,
		{"word":"exaspérant","word_nosc":"exasperant","lemma":"exaspérant","pos":"ADJ"} ,
		{"word":"exaspérante","word_nosc":"exasperante","lemma":"exaspérant","pos":"ADJ"} ,
		{"word":"exaspérantes","word_nosc":"exasperantes","lemma":"exaspérant","pos":"ADJ"} ,
		{"word":"exaspérants","word_nosc":"exasperants","lemma":"exaspérant","pos":"ADJ"} ,
		{"word":"excellent","word_nosc":"excellent","lemma":"excellent","pos":"ADJ"} ,
		{"word":"excellente","word_nosc":"excellente","lemma":"excellent","pos":"ADJ"} ,
		{"word":"excellentes","word_nosc":"excellentes","lemma":"excellent","pos":"ADJ"} ,
		{"word":"excellentissime","word_nosc":"excellentissime","lemma":"excellentissime","pos":"ADJ"} ,
		{"word":"excellents","word_nosc":"excellents","lemma":"excellent","pos":"ADJ"} ,
		{"word":"excentrique","word_nosc":"excentrique","lemma":"excentrique","pos":"ADJ"} ,
		{"word":"excentriques","word_nosc":"excentriques","lemma":"excentrique","pos":"ADJ"} ,
		{"word":"excentré","word_nosc":"excentre","lemma":"excentré","pos":"ADJ"} ,
		{"word":"excentrée","word_nosc":"excentree","lemma":"excentré","pos":"ADJ"} ,
		{"word":"exceptionnel","word_nosc":"exceptionnel","lemma":"exceptionnel","pos":"ADJ"} ,
		{"word":"exceptionnelle","word_nosc":"exceptionnelle","lemma":"exceptionnel","pos":"ADJ"} ,
		{"word":"exceptionnelles","word_nosc":"exceptionnelles","lemma":"exceptionnel","pos":"ADJ"} ,
		{"word":"exceptionnels","word_nosc":"exceptionnels","lemma":"exceptionnel","pos":"ADJ"} ,
		{"word":"excepté","word_nosc":"excepte","lemma":"excepté","pos":"ADJ"} ,
		{"word":"exceptée","word_nosc":"exceptee","lemma":"excepté","pos":"ADJ"} ,
		{"word":"exceptées","word_nosc":"exceptees","lemma":"excepté","pos":"ADJ"} ,
		{"word":"exceptés","word_nosc":"exceptes","lemma":"excepté","pos":"ADJ"} ,
		{"word":"excessif","word_nosc":"excessif","lemma":"excessif","pos":"ADJ"} ,
		{"word":"excessifs","word_nosc":"excessifs","lemma":"excessif","pos":"ADJ"} ,
		{"word":"excessive","word_nosc":"excessive","lemma":"excessif","pos":"ADJ"} ,
		{"word":"excessives","word_nosc":"excessives","lemma":"excessif","pos":"ADJ"} ,
		{"word":"excisée","word_nosc":"excisee","lemma":"excisé","pos":"ADJ"} ,
		{"word":"excisées","word_nosc":"excisees","lemma":"excisé","pos":"ADJ"} ,
		{"word":"excitable","word_nosc":"excitable","lemma":"excitable","pos":"ADJ"} ,
		{"word":"excitant","word_nosc":"excitant","lemma":"excitant","pos":"ADJ"} ,
		{"word":"excitante","word_nosc":"excitante","lemma":"excitant","pos":"ADJ"} ,
		{"word":"excitantes","word_nosc":"excitantes","lemma":"excitant","pos":"ADJ"} ,
		{"word":"excitants","word_nosc":"excitants","lemma":"excitant","pos":"ADJ"} ,
		{"word":"excité","word_nosc":"excite","lemma":"excité","pos":"ADJ"} ,
		{"word":"excitée","word_nosc":"excitee","lemma":"excité","pos":"ADJ"} ,
		{"word":"excitées","word_nosc":"excitees","lemma":"excité","pos":"ADJ"} ,
		{"word":"excités","word_nosc":"excites","lemma":"excité","pos":"ADJ"} ,
		{"word":"exclamatif","word_nosc":"exclamatif","lemma":"exclamatif","pos":"ADJ"} ,
		{"word":"exclamative","word_nosc":"exclamative","lemma":"exclamatif","pos":"ADJ"} ,
		{"word":"exclu","word_nosc":"exclu","lemma":"exclu","pos":"ADJ"} ,
		{"word":"exclue","word_nosc":"exclue","lemma":"exclu","pos":"ADJ"} ,
		{"word":"exclues","word_nosc":"exclues","lemma":"exclu","pos":"ADJ"} ,
		{"word":"exclus","word_nosc":"exclus","lemma":"exclu","pos":"ADJ"} ,
		{"word":"exclusif","word_nosc":"exclusif","lemma":"exclusif","pos":"ADJ"} ,
		{"word":"exclusifs","word_nosc":"exclusifs","lemma":"exclusif","pos":"ADJ"} ,
		{"word":"exclusive","word_nosc":"exclusive","lemma":"exclusif","pos":"ADJ"} ,
		{"word":"exclusives","word_nosc":"exclusives","lemma":"exclusif","pos":"ADJ"} ,
		{"word":"excommunié","word_nosc":"excommunie","lemma":"excommunié","pos":"ADJ"} ,
		{"word":"excommuniés","word_nosc":"excommunies","lemma":"excommunié","pos":"ADJ"} ,
		{"word":"excrémentiel","word_nosc":"excrementiel","lemma":"excrémentiel","pos":"ADJ"} ,
		{"word":"excrémentielle","word_nosc":"excrementielle","lemma":"excrémentiel","pos":"ADJ"} ,
		{"word":"excusable","word_nosc":"excusable","lemma":"excusable","pos":"ADJ"} ,
		{"word":"excusables","word_nosc":"excusables","lemma":"excusable","pos":"ADJ"} ,
		{"word":"excédant","word_nosc":"excedant","lemma":"excédant","pos":"ADJ"} ,
		{"word":"excédentaire","word_nosc":"excedentaire","lemma":"excédentaire","pos":"ADJ"} ,
		{"word":"excédentaires","word_nosc":"excedentaires","lemma":"excédentaire","pos":"ADJ"} ,
		{"word":"exemplaire","word_nosc":"exemplaire","lemma":"exemplaire","pos":"ADJ"} ,
		{"word":"exemplaires","word_nosc":"exemplaires","lemma":"exemplaire","pos":"ADJ"} ,
		{"word":"exempt","word_nosc":"exempt","lemma":"exempt","pos":"ADJ"} ,
		{"word":"exempte","word_nosc":"exempte","lemma":"exempt","pos":"ADJ"} ,
		{"word":"exemptes","word_nosc":"exemptes","lemma":"exempt","pos":"ADJ"} ,
		{"word":"exempts","word_nosc":"exempts","lemma":"exempt","pos":"ADJ"} ,
		{"word":"exemptée","word_nosc":"exemptee","lemma":"exempté","pos":"ADJ"} ,
		{"word":"exemptés","word_nosc":"exemptes","lemma":"exempté","pos":"ADJ"} ,
		{"word":"exercé","word_nosc":"exerce","lemma":"exercé","pos":"ADJ"} ,
		{"word":"exercée","word_nosc":"exercee","lemma":"exercé","pos":"ADJ"} ,
		{"word":"exercées","word_nosc":"exercees","lemma":"exercé","pos":"ADJ"} ,
		{"word":"exercés","word_nosc":"exerces","lemma":"exercé","pos":"ADJ"} ,
		{"word":"exerçant","word_nosc":"exercant","lemma":"exerçant","pos":"ADJ"} ,
		{"word":"exfoliant","word_nosc":"exfoliant","lemma":"exfoliant","pos":"ADJ"} ,
		{"word":"exhalant","word_nosc":"exhalant","lemma":"exhalant","pos":"ADJ"} ,
		{"word":"exhaustif","word_nosc":"exhaustif","lemma":"exhaustif","pos":"ADJ"} ,
		{"word":"exhaustifs","word_nosc":"exhaustifs","lemma":"exhaustif","pos":"ADJ"} ,
		{"word":"exhaustive","word_nosc":"exhaustive","lemma":"exhaustif","pos":"ADJ"} ,
		{"word":"exigeant","word_nosc":"exigeant","lemma":"exigeant","pos":"ADJ"} ,
		{"word":"exigeante","word_nosc":"exigeante","lemma":"exigeant","pos":"ADJ"} ,
		{"word":"exigeantes","word_nosc":"exigeantes","lemma":"exigeant","pos":"ADJ"} ,
		{"word":"exigeants","word_nosc":"exigeants","lemma":"exigeant","pos":"ADJ"} ,
		{"word":"exigible","word_nosc":"exigible","lemma":"exigible","pos":"ADJ"} ,
		{"word":"exigu","word_nosc":"exigu","lemma":"exigu","pos":"ADJ"} ,
		{"word":"exigus","word_nosc":"exigus","lemma":"exigu","pos":"ADJ"} ,
		{"word":"exiguë","word_nosc":"exigue","lemma":"exigu","pos":"ADJ"} ,
		{"word":"exiguës","word_nosc":"exigues","lemma":"exigu","pos":"ADJ"} ,
		{"word":"exilé","word_nosc":"exile","lemma":"exilé","pos":"ADJ"} ,
		{"word":"exilée","word_nosc":"exilee","lemma":"exilé","pos":"ADJ"} ,
		{"word":"exilées","word_nosc":"exilees","lemma":"exilé","pos":"ADJ"} ,
		{"word":"exilés","word_nosc":"exiles","lemma":"exilé","pos":"ADJ"} ,
		{"word":"existant","word_nosc":"existant","lemma":"existant","pos":"ADJ"} ,
		{"word":"existante","word_nosc":"existante","lemma":"existant","pos":"ADJ"} ,
		{"word":"existantes","word_nosc":"existantes","lemma":"existant","pos":"ADJ"} ,
		{"word":"existants","word_nosc":"existants","lemma":"existant","pos":"ADJ"} ,
		{"word":"existentialiste","word_nosc":"existentialiste","lemma":"existentialiste","pos":"ADJ"} ,
		{"word":"existentialistes","word_nosc":"existentialistes","lemma":"existentialiste","pos":"ADJ"} ,
		{"word":"existentiel","word_nosc":"existentiel","lemma":"existentiel","pos":"ADJ"} ,
		{"word":"existentielle","word_nosc":"existentielle","lemma":"existentiel","pos":"ADJ"} ,
		{"word":"existentielles","word_nosc":"existentielles","lemma":"existentiel","pos":"ADJ"} ,
		{"word":"exogamique","word_nosc":"exogamique","lemma":"exogamique","pos":"ADJ"} ,
		{"word":"exogène","word_nosc":"exogene","lemma":"exogène","pos":"ADJ"} ,
		{"word":"exophtalmique","word_nosc":"exophtalmique","lemma":"exophtalmique","pos":"ADJ"} ,
		{"word":"exorable","word_nosc":"exorable","lemma":"exorable","pos":"ADJ"} ,
		{"word":"exorbitant","word_nosc":"exorbitant","lemma":"exorbitant","pos":"ADJ"} ,
		{"word":"exorbitante","word_nosc":"exorbitante","lemma":"exorbitant","pos":"ADJ"} ,
		{"word":"exorbitantes","word_nosc":"exorbitantes","lemma":"exorbitant","pos":"ADJ"} ,
		{"word":"exorbitants","word_nosc":"exorbitants","lemma":"exorbitant","pos":"ADJ"} ,
		{"word":"exorbité","word_nosc":"exorbite","lemma":"exorbité","pos":"ADJ"} ,
		{"word":"exorbitées","word_nosc":"exorbitees","lemma":"exorbité","pos":"ADJ"} ,
		{"word":"exorbités","word_nosc":"exorbites","lemma":"exorbité","pos":"ADJ"} ,
		{"word":"exothermique","word_nosc":"exothermique","lemma":"exothermique","pos":"ADJ"} ,
		{"word":"exotique","word_nosc":"exotique","lemma":"exotique","pos":"ADJ"} ,
		{"word":"exotiques","word_nosc":"exotiques","lemma":"exotique","pos":"ADJ"} ,
		{"word":"expansible","word_nosc":"expansible","lemma":"expansible","pos":"ADJ"} ,
		{"word":"expansif","word_nosc":"expansif","lemma":"expansif","pos":"ADJ"} ,
		{"word":"expansifs","word_nosc":"expansifs","lemma":"expansif","pos":"ADJ"} ,
		{"word":"expansionniste","word_nosc":"expansionniste","lemma":"expansionniste","pos":"ADJ"} ,
		{"word":"expansive","word_nosc":"expansive","lemma":"expansif","pos":"ADJ"} ,
		{"word":"expansives","word_nosc":"expansives","lemma":"expansif","pos":"ADJ"} ,
		{"word":"expansé","word_nosc":"expanse","lemma":"expansé","pos":"ADJ"} ,
		{"word":"expatrié","word_nosc":"expatrie","lemma":"expatrié","pos":"ADJ"} ,
		{"word":"expectatif","word_nosc":"expectatif","lemma":"expectatif","pos":"ADJ"} ,
		{"word":"expert","word_nosc":"expert","lemma":"expert","pos":"ADJ"} ,
		{"word":"experte","word_nosc":"experte","lemma":"expert","pos":"ADJ"} ,
		{"word":"expertes","word_nosc":"expertes","lemma":"expert","pos":"ADJ"} ,
		{"word":"experts","word_nosc":"experts","lemma":"expert","pos":"ADJ"} ,
		{"word":"expiatoire","word_nosc":"expiatoire","lemma":"expiatoire","pos":"ADJ"} ,
		{"word":"expiatoires","word_nosc":"expiatoires","lemma":"expiatoire","pos":"ADJ"} ,
		{"word":"expiatrice","word_nosc":"expiatrice","lemma":"expiateur","pos":"ADJ"} ,
		{"word":"expirant","word_nosc":"expirant","lemma":"expirant","pos":"ADJ"} ,
		{"word":"expirante","word_nosc":"expirante","lemma":"expirant","pos":"ADJ"} ,
		{"word":"expirantes","word_nosc":"expirantes","lemma":"expirant","pos":"ADJ"} ,
		{"word":"explicable","word_nosc":"explicable","lemma":"explicable","pos":"ADJ"} ,
		{"word":"explicables","word_nosc":"explicables","lemma":"explicable","pos":"ADJ"} ,
		{"word":"explicatif","word_nosc":"explicatif","lemma":"explicatif","pos":"ADJ"} ,
		{"word":"explicatifs","word_nosc":"explicatifs","lemma":"explicatif","pos":"ADJ"} ,
		{"word":"explicative","word_nosc":"explicative","lemma":"explicatif","pos":"ADJ"} ,
		{"word":"explicatives","word_nosc":"explicatives","lemma":"explicatif","pos":"ADJ"} ,
		{"word":"explicite","word_nosc":"explicite","lemma":"explicite","pos":"ADJ"} ,
		{"word":"explicites","word_nosc":"explicites","lemma":"explicite","pos":"ADJ"} ,
		{"word":"exploitable","word_nosc":"exploitable","lemma":"exploitable","pos":"ADJ"} ,
		{"word":"exploitables","word_nosc":"exploitables","lemma":"exploitable","pos":"ADJ"} ,
		{"word":"exploitant","word_nosc":"exploitant","lemma":"exploitant","pos":"ADJ"} ,
		{"word":"exploité","word_nosc":"exploite","lemma":"exploité","pos":"ADJ"} ,
		{"word":"exploitée","word_nosc":"exploitee","lemma":"exploité","pos":"ADJ"} ,
		{"word":"exploitées","word_nosc":"exploitees","lemma":"exploité","pos":"ADJ"} ,
		{"word":"exploités","word_nosc":"exploites","lemma":"exploité","pos":"ADJ"} ,
		{"word":"explorateur","word_nosc":"explorateur","lemma":"explorateur","pos":"ADJ"} ,
		{"word":"explorateurs","word_nosc":"explorateurs","lemma":"explorateur","pos":"ADJ"} ,
		{"word":"exploratoire","word_nosc":"exploratoire","lemma":"exploratoire","pos":"ADJ"} ,
		{"word":"exploratoires","word_nosc":"exploratoires","lemma":"exploratoire","pos":"ADJ"} ,
		{"word":"exploratrice","word_nosc":"exploratrice","lemma":"explorateur","pos":"ADJ"} ,
		{"word":"exploratrices","word_nosc":"exploratrices","lemma":"explorateur","pos":"ADJ"} ,
		{"word":"explosif","word_nosc":"explosif","lemma":"explosif","pos":"ADJ"} ,
		{"word":"explosifs","word_nosc":"explosifs","lemma":"explosif","pos":"ADJ"} ,
		{"word":"explosive","word_nosc":"explosive","lemma":"explosif","pos":"ADJ"} ,
		{"word":"explosives","word_nosc":"explosives","lemma":"explosif","pos":"ADJ"} ,
		{"word":"explétive","word_nosc":"expletive","lemma":"explétif","pos":"ADJ"} ,
		{"word":"exponentiel","word_nosc":"exponentiel","lemma":"exponentiel","pos":"ADJ"} ,
		{"word":"exponentielle","word_nosc":"exponentielle","lemma":"exponentiel","pos":"ADJ"} ,
		{"word":"exponentielles","word_nosc":"exponentielles","lemma":"exponentiel","pos":"ADJ"} ,
		{"word":"exportateur","word_nosc":"exportateur","lemma":"exportateur","pos":"ADJ"} ,
		{"word":"exportateurs","word_nosc":"exportateurs","lemma":"exportateur","pos":"ADJ"} ,
		{"word":"exposé","word_nosc":"expose","lemma":"exposé","pos":"ADJ"} ,
		{"word":"exposée","word_nosc":"exposee","lemma":"exposé","pos":"ADJ"} ,
		{"word":"exposées","word_nosc":"exposees","lemma":"exposé","pos":"ADJ"} ,
		{"word":"exposés","word_nosc":"exposes","lemma":"exposé","pos":"ADJ"} ,
		{"word":"express","word_nosc":"express","lemma":"express","pos":"ADJ"} ,
		{"word":"expresse","word_nosc":"expresse","lemma":"exprès","pos":"ADJ"} ,
		{"word":"expresses","word_nosc":"expresses","lemma":"exprès","pos":"ADJ"} ,
		{"word":"expressif","word_nosc":"expressif","lemma":"expressif","pos":"ADJ"} ,
		{"word":"expressifs","word_nosc":"expressifs","lemma":"expressif","pos":"ADJ"} ,
		{"word":"expressionniste","word_nosc":"expressionniste","lemma":"expressionniste","pos":"ADJ"} ,
		{"word":"expressionnistes","word_nosc":"expressionnistes","lemma":"expressionniste","pos":"ADJ"} ,
		{"word":"expressive","word_nosc":"expressive","lemma":"expressif","pos":"ADJ"} ,
		{"word":"expressives","word_nosc":"expressives","lemma":"expressif","pos":"ADJ"} ,
		{"word":"exprimable","word_nosc":"exprimable","lemma":"exprimable","pos":"ADJ"} ,
		{"word":"exprès","word_nosc":"expres","lemma":"exprès","pos":"ADJ"} ,
		{"word":"expulsive","word_nosc":"expulsive","lemma":"expulsif","pos":"ADJ"} ,
		{"word":"expulsé","word_nosc":"expulse","lemma":"expulsé","pos":"ADJ"} ,
		{"word":"expulsée","word_nosc":"expulsee","lemma":"expulsé","pos":"ADJ"} ,
		{"word":"expulsées","word_nosc":"expulsees","lemma":"expulsé","pos":"ADJ"} ,
		{"word":"expulsés","word_nosc":"expulses","lemma":"expulsé","pos":"ADJ"} ,
		{"word":"expurgée","word_nosc":"expurgee","lemma":"expurgé","pos":"ADJ"} ,
		{"word":"expédient","word_nosc":"expedient","lemma":"expédient","pos":"ADJ"} ,
		{"word":"expédientes","word_nosc":"expedientes","lemma":"expédient","pos":"ADJ"} ,
		{"word":"expéditeur","word_nosc":"expediteur","lemma":"expéditeur","pos":"ADJ"} ,
		{"word":"expéditeurs","word_nosc":"expediteurs","lemma":"expéditeur","pos":"ADJ"} ,
		{"word":"expéditif","word_nosc":"expeditif","lemma":"expéditif","pos":"ADJ"} ,
		{"word":"expéditifs","word_nosc":"expeditifs","lemma":"expéditif","pos":"ADJ"} ,
		{"word":"expéditionnaire","word_nosc":"expeditionnaire","lemma":"expéditionnaire","pos":"ADJ"} ,
		{"word":"expéditionnaires","word_nosc":"expeditionnaires","lemma":"expéditionnaire","pos":"ADJ"} ,
		{"word":"expéditive","word_nosc":"expeditive","lemma":"expéditif","pos":"ADJ"} ,
		{"word":"expéditives","word_nosc":"expeditives","lemma":"expéditif","pos":"ADJ"} ,
		{"word":"expérimental","word_nosc":"experimental","lemma":"expérimental","pos":"ADJ"} ,
		{"word":"expérimentale","word_nosc":"experimentale","lemma":"expérimental","pos":"ADJ"} ,
		{"word":"expérimentales","word_nosc":"experimentales","lemma":"expérimental","pos":"ADJ"} ,
		{"word":"expérimentaux","word_nosc":"experimentaux","lemma":"expérimental","pos":"ADJ"} ,
		{"word":"expérimenté","word_nosc":"experimente","lemma":"expérimenté","pos":"ADJ"} ,
		{"word":"expérimentée","word_nosc":"experimentee","lemma":"expérimenté","pos":"ADJ"} ,
		{"word":"expérimentées","word_nosc":"experimentees","lemma":"expérimenté","pos":"ADJ"} ,
		{"word":"expérimentés","word_nosc":"experimentes","lemma":"expérimenté","pos":"ADJ"} ,
		{"word":"exquis","word_nosc":"exquis","lemma":"exquis","pos":"ADJ"} ,
		{"word":"exquise","word_nosc":"exquise","lemma":"exquis","pos":"ADJ"} ,
		{"word":"exquises","word_nosc":"exquises","lemma":"exquis","pos":"ADJ"} ,
		{"word":"exsangue","word_nosc":"exsangue","lemma":"exsangue","pos":"ADJ"} ,
		{"word":"exsangues","word_nosc":"exsangues","lemma":"exsangue","pos":"ADJ"} ,
		{"word":"extasiant","word_nosc":"extasiant","lemma":"extasiant","pos":"ADJ"} ,
		{"word":"extasiantes","word_nosc":"extasiantes","lemma":"extasiant","pos":"ADJ"} ,
		{"word":"extasié","word_nosc":"extasie","lemma":"extasié","pos":"ADJ"} ,
		{"word":"extasiée","word_nosc":"extasiee","lemma":"extasié","pos":"ADJ"} ,
		{"word":"extasiés","word_nosc":"extasies","lemma":"extasié","pos":"ADJ"} ,
		{"word":"extatique","word_nosc":"extatique","lemma":"extatique","pos":"ADJ"} ,
		{"word":"extatiques","word_nosc":"extatiques","lemma":"extatique","pos":"ADJ"} ,
		{"word":"extenseur","word_nosc":"extenseur","lemma":"extenseur","pos":"ADJ"} ,
		{"word":"extenseurs","word_nosc":"extenseurs","lemma":"extenseur","pos":"ADJ"} ,
		{"word":"extensible","word_nosc":"extensible","lemma":"extensible","pos":"ADJ"} ,
		{"word":"extensibles","word_nosc":"extensibles","lemma":"extensible","pos":"ADJ"} ,
		{"word":"extensive","word_nosc":"extensive","lemma":"extensif","pos":"ADJ"} ,
		{"word":"exterminateur","word_nosc":"exterminateur","lemma":"exterminateur","pos":"ADJ"} ,
		{"word":"exterminateurs","word_nosc":"exterminateurs","lemma":"exterminateur","pos":"ADJ"} ,
		{"word":"exterminatrice","word_nosc":"exterminatrice","lemma":"exterminateur","pos":"ADJ"} ,
		{"word":"exterminatrices","word_nosc":"exterminatrices","lemma":"exterminateur","pos":"ADJ"} ,
		{"word":"externe","word_nosc":"externe","lemma":"externe","pos":"ADJ"} ,
		{"word":"externes","word_nosc":"externes","lemma":"externe","pos":"ADJ"} ,
		{"word":"extincteur","word_nosc":"extincteur","lemma":"extincteur","pos":"ADJ"} ,
		{"word":"extincteurs","word_nosc":"extincteurs","lemma":"extincteur","pos":"ADJ"} ,
		{"word":"extinctrice","word_nosc":"extinctrice","lemma":"extinctrice","pos":"ADJ"} ,
		{"word":"extra","word_nosc":"extra","lemma":"extra","pos":"ADJ"} ,
		{"word":"extra-fin","word_nosc":"extra-fin","lemma":"extra-fin","pos":"ADJ"} ,
		{"word":"extra-fins","word_nosc":"extra-fins","lemma":"extra-fin","pos":"ADJ"} ,
		{"word":"extra-fort","word_nosc":"extra-fort","lemma":"extra-fort","pos":"ADJ"} ,
		{"word":"extra-forte","word_nosc":"extra-forte","lemma":"extra-fort","pos":"ADJ"} ,
		{"word":"extra-lucide","word_nosc":"extra-lucide","lemma":"extra-lucide","pos":"ADJ"} ,
		{"word":"extra-lucides","word_nosc":"extra-lucides","lemma":"extra-lucide","pos":"ADJ"} ,
		{"word":"extra-muros","word_nosc":"extra-muros","lemma":"extra-muros","pos":"ADJ"} ,
		{"word":"extra-sensoriel","word_nosc":"extra-sensoriel","lemma":"extra-sensoriel","pos":"ADJ"} ,
		{"word":"extra-sensorielle","word_nosc":"extra-sensorielle","lemma":"extra-sensoriel","pos":"ADJ"} ,
		{"word":"extra-souples","word_nosc":"extra-souples","lemma":"extra-souple","pos":"ADJ"} ,
		{"word":"extra-terrestre","word_nosc":"extra-terrestre","lemma":"extra-terrestre","pos":"ADJ"} ,
		{"word":"extra-terrestres","word_nosc":"extra-terrestres","lemma":"extra-terrestre","pos":"ADJ"} ,
		{"word":"extra-utérin","word_nosc":"extra-uterin","lemma":"extra-utérin","pos":"ADJ"} ,
		{"word":"extra-utérine","word_nosc":"extra-uterine","lemma":"extra-utérin","pos":"ADJ"} ,
		{"word":"extraconjugal","word_nosc":"extraconjugal","lemma":"extraconjugal","pos":"ADJ"} ,
		{"word":"extraconjugale","word_nosc":"extraconjugale","lemma":"extraconjugal","pos":"ADJ"} ,
		{"word":"extraconjugales","word_nosc":"extraconjugales","lemma":"extraconjugal","pos":"ADJ"} ,
		{"word":"extraconjugaux","word_nosc":"extraconjugaux","lemma":"extraconjugal","pos":"ADJ"} ,
		{"word":"extracorporelle","word_nosc":"extracorporelle","lemma":"extracorporel","pos":"ADJ"} ,
		{"word":"extractive","word_nosc":"extractive","lemma":"extractif","pos":"ADJ"} ,
		{"word":"extradé","word_nosc":"extrade","lemma":"extradé","pos":"ADJ"} ,
		{"word":"extradée","word_nosc":"extradee","lemma":"extradé","pos":"ADJ"} ,
		{"word":"extraforte","word_nosc":"extraforte","lemma":"extrafort","pos":"ADJ"} ,
		{"word":"extragalactique","word_nosc":"extragalactique","lemma":"extragalactique","pos":"ADJ"} ,
		{"word":"extrait","word_nosc":"extrait","lemma":"extrait","pos":"ADJ"} ,
		{"word":"extraits","word_nosc":"extraits","lemma":"extrait","pos":"ADJ"} ,
		{"word":"extralucide","word_nosc":"extralucide","lemma":"extralucide","pos":"ADJ"} ,
		{"word":"extralucides","word_nosc":"extralucides","lemma":"extralucide","pos":"ADJ"} ,
		{"word":"extraordinaire","word_nosc":"extraordinaire","lemma":"extraordinaire","pos":"ADJ"} ,
		{"word":"extraordinaires","word_nosc":"extraordinaires","lemma":"extraordinaire","pos":"ADJ"} ,
		{"word":"extrascolaire","word_nosc":"extrascolaire","lemma":"extrascolaire","pos":"ADJ"} ,
		{"word":"extrascolaires","word_nosc":"extrascolaires","lemma":"extrascolaire","pos":"ADJ"} ,
		{"word":"extrasensoriel","word_nosc":"extrasensoriel","lemma":"extrasensoriel","pos":"ADJ"} ,
		{"word":"extrasensorielle","word_nosc":"extrasensorielle","lemma":"extrasensoriel","pos":"ADJ"} ,
		{"word":"extrasensoriels","word_nosc":"extrasensoriels","lemma":"extrasensoriel","pos":"ADJ"} ,
		{"word":"extraterrestre","word_nosc":"extraterrestre","lemma":"extraterrestre","pos":"ADJ"} ,
		{"word":"extraterrestres","word_nosc":"extraterrestres","lemma":"extraterrestre","pos":"ADJ"} ,
		{"word":"extraterritoriale","word_nosc":"extraterritoriale","lemma":"extraterritorial","pos":"ADJ"} ,
		{"word":"extravagant","word_nosc":"extravagant","lemma":"extravagant","pos":"ADJ"} ,
		{"word":"extravagante","word_nosc":"extravagante","lemma":"extravagant","pos":"ADJ"} ,
		{"word":"extravagantes","word_nosc":"extravagantes","lemma":"extravagant","pos":"ADJ"} ,
		{"word":"extravagants","word_nosc":"extravagants","lemma":"extravagant","pos":"ADJ"} ,
		{"word":"extraverti","word_nosc":"extraverti","lemma":"extraverti","pos":"ADJ"} ,
		{"word":"extravertie","word_nosc":"extravertie","lemma":"extraverti","pos":"ADJ"} ,
		{"word":"extravéhiculaire","word_nosc":"extravehiculaire","lemma":"extravéhiculaire","pos":"ADJ"} ,
		{"word":"extroverti","word_nosc":"extroverti","lemma":"extroverti","pos":"ADJ"} ,
		{"word":"extrovertie","word_nosc":"extrovertie","lemma":"extroverti","pos":"ADJ"} ,
		{"word":"extrémiste","word_nosc":"extremiste","lemma":"extrémiste","pos":"ADJ"} ,
		{"word":"extrémistes","word_nosc":"extremistes","lemma":"extrémiste","pos":"ADJ"} ,
		{"word":"extrême","word_nosc":"extreme","lemma":"extrême","pos":"ADJ"} ,
		{"word":"extrême-orientale","word_nosc":"extreme-orientale","lemma":"extrême-oriental","pos":"ADJ"} ,
		{"word":"extrêmes","word_nosc":"extremes","lemma":"extrême","pos":"ADJ"} ,
		{"word":"exténuant","word_nosc":"extenuant","lemma":"exténuant","pos":"ADJ"} ,
		{"word":"exténuante","word_nosc":"extenuante","lemma":"exténuant","pos":"ADJ"} ,
		{"word":"exténuantes","word_nosc":"extenuantes","lemma":"exténuant","pos":"ADJ"} ,
		{"word":"exténuants","word_nosc":"extenuants","lemma":"exténuant","pos":"ADJ"} ,
		{"word":"exténué","word_nosc":"extenue","lemma":"exténué","pos":"ADJ"} ,
		{"word":"exténuée","word_nosc":"extenuee","lemma":"exténué","pos":"ADJ"} ,
		{"word":"exténuées","word_nosc":"extenuees","lemma":"exténué","pos":"ADJ"} ,
		{"word":"exténués","word_nosc":"extenues","lemma":"exténué","pos":"ADJ"} ,
		{"word":"extérieur","word_nosc":"exterieur","lemma":"extérieur","pos":"ADJ"} ,
		{"word":"extérieure","word_nosc":"exterieure","lemma":"extérieur","pos":"ADJ"} ,
		{"word":"extérieures","word_nosc":"exterieures","lemma":"extérieur","pos":"ADJ"} ,
		{"word":"extérieurs","word_nosc":"exterieurs","lemma":"extérieur","pos":"ADJ"} ,
		{"word":"exubérant","word_nosc":"exuberant","lemma":"exubérant","pos":"ADJ"} ,
		{"word":"exubérante","word_nosc":"exuberante","lemma":"exubérant","pos":"ADJ"} ,
		{"word":"exubérantes","word_nosc":"exuberantes","lemma":"exubérant","pos":"ADJ"} ,
		{"word":"exubérants","word_nosc":"exuberants","lemma":"exubérant","pos":"ADJ"} ,
		{"word":"exultant","word_nosc":"exultant","lemma":"exultant","pos":"ADJ"} ,
		{"word":"exultante","word_nosc":"exultante","lemma":"exultant","pos":"ADJ"} ,
		{"word":"exultantes","word_nosc":"exultantes","lemma":"exultant","pos":"ADJ"} ,
		{"word":"exultants","word_nosc":"exultants","lemma":"exultant","pos":"ADJ"} ,
		{"word":"exécrable","word_nosc":"execrable","lemma":"exécrable","pos":"ADJ"} ,
		{"word":"exécrables","word_nosc":"execrables","lemma":"exécrable","pos":"ADJ"} ,
		{"word":"exécutif","word_nosc":"executif","lemma":"exécutif","pos":"ADJ"} ,
		{"word":"exécutifs","word_nosc":"executifs","lemma":"exécutif","pos":"ADJ"} ,
		{"word":"exécutive","word_nosc":"executive","lemma":"exécutif","pos":"ADJ"} ,
		{"word":"exécutoire","word_nosc":"executoire","lemma":"exécutoire","pos":"ADJ"} ,
		{"word":"exécutoires","word_nosc":"executoires","lemma":"exécutoire","pos":"ADJ"} ,
		{"word":"fabulateur","word_nosc":"fabulateur","lemma":"fabulateur","pos":"ADJ"} ,
		{"word":"fabuleuse","word_nosc":"fabuleuse","lemma":"fabuleux","pos":"ADJ"} ,
		{"word":"fabuleuses","word_nosc":"fabuleuses","lemma":"fabuleux","pos":"ADJ"} ,
		{"word":"fabuleux","word_nosc":"fabuleux","lemma":"fabuleux","pos":"ADJ"} ,
		{"word":"facho","word_nosc":"facho","lemma":"facho","pos":"ADJ"} ,
		{"word":"fachos","word_nosc":"fachos","lemma":"facho","pos":"ADJ"} ,
		{"word":"facial","word_nosc":"facial","lemma":"facial","pos":"ADJ"} ,
		{"word":"faciale","word_nosc":"faciale","lemma":"facial","pos":"ADJ"} ,
		{"word":"faciales","word_nosc":"faciales","lemma":"facial","pos":"ADJ"} ,
		{"word":"faciaux","word_nosc":"faciaux","lemma":"facial","pos":"ADJ"} ,
		{"word":"facile","word_nosc":"facile","lemma":"facile","pos":"ADJ"} ,
		{"word":"faciles","word_nosc":"faciles","lemma":"facile","pos":"ADJ"} ,
		{"word":"facilitateur","word_nosc":"facilitateur","lemma":"facilitateur","pos":"ADJ"} ,
		{"word":"factice","word_nosc":"factice","lemma":"factice","pos":"ADJ"} ,
		{"word":"factices","word_nosc":"factices","lemma":"factice","pos":"ADJ"} ,
		{"word":"factieux","word_nosc":"factieux","lemma":"factieux","pos":"ADJ"} ,
		{"word":"factuel","word_nosc":"factuel","lemma":"factuel","pos":"ADJ"} ,
		{"word":"factuelles","word_nosc":"factuelles","lemma":"factuel","pos":"ADJ"} ,
		{"word":"factuels","word_nosc":"factuels","lemma":"factuel","pos":"ADJ"} ,
		{"word":"facturé","word_nosc":"facture","lemma":"facturé","pos":"ADJ"} ,
		{"word":"facturée","word_nosc":"facturee","lemma":"facturé","pos":"ADJ"} ,
		{"word":"facturés","word_nosc":"factures","lemma":"facturé","pos":"ADJ"} ,
		{"word":"facultatif","word_nosc":"facultatif","lemma":"facultatif","pos":"ADJ"} ,
		{"word":"facultatifs","word_nosc":"facultatifs","lemma":"facultatif","pos":"ADJ"} ,
		{"word":"facultative","word_nosc":"facultative","lemma":"facultatif","pos":"ADJ"} ,
		{"word":"facétieuse","word_nosc":"facetieuse","lemma":"facétieux","pos":"ADJ"} ,
		{"word":"facétieuses","word_nosc":"facetieuses","lemma":"facétieux","pos":"ADJ"} ,
		{"word":"facétieux","word_nosc":"facetieux","lemma":"facétieux","pos":"ADJ"} ,
		{"word":"fada","word_nosc":"fada","lemma":"fada","pos":"ADJ"} ,
		{"word":"fadasse","word_nosc":"fadasse","lemma":"fadasse","pos":"ADJ"} ,
		{"word":"fadasses","word_nosc":"fadasses","lemma":"fadasse","pos":"ADJ"} ,
		{"word":"fade","word_nosc":"fade","lemma":"fade","pos":"ADJ"} ,
		{"word":"fades","word_nosc":"fades","lemma":"fade","pos":"ADJ"} ,
		{"word":"fadé","word_nosc":"fade","lemma":"fadé","pos":"ADJ"} ,
		{"word":"fadée","word_nosc":"fadee","lemma":"fadé","pos":"ADJ"} ,
		{"word":"fagoté","word_nosc":"fagote","lemma":"fagoté","pos":"ADJ"} ,
		{"word":"fagotée","word_nosc":"fagotee","lemma":"fagoté","pos":"ADJ"} ,
		{"word":"fagotées","word_nosc":"fagotees","lemma":"fagoté","pos":"ADJ"} ,
		{"word":"fagotés","word_nosc":"fagotes","lemma":"fagoté","pos":"ADJ"} ,
		{"word":"fahrenheit","word_nosc":"fahrenheit","lemma":"fahrenheit","pos":"ADJ"} ,
		{"word":"faiblard","word_nosc":"faiblard","lemma":"faiblard","pos":"ADJ"} ,
		{"word":"faiblarde","word_nosc":"faiblarde","lemma":"faiblard","pos":"ADJ"} ,
		{"word":"faiblards","word_nosc":"faiblards","lemma":"faiblard","pos":"ADJ"} ,
		{"word":"faible","word_nosc":"faible","lemma":"faible","pos":"ADJ"} ,
		{"word":"faibles","word_nosc":"faibles","lemma":"faible","pos":"ADJ"} ,
		{"word":"faibli","word_nosc":"faibli","lemma":"faibli","pos":"ADJ"} ,
		{"word":"faiblissante","word_nosc":"faiblissante","lemma":"faiblissant","pos":"ADJ"} ,
		{"word":"faiblissantes","word_nosc":"faiblissantes","lemma":"faiblissant","pos":"ADJ"} ,
		{"word":"faignante","word_nosc":"faignante","lemma":"faignant","pos":"ADJ"} ,
		{"word":"faignants","word_nosc":"faignants","lemma":"faignant","pos":"ADJ"} ,
		{"word":"failli","word_nosc":"failli","lemma":"failli","pos":"ADJ"} ,
		{"word":"faillible","word_nosc":"faillible","lemma":"faillible","pos":"ADJ"} ,
		{"word":"faillibles","word_nosc":"faillibles","lemma":"faillible","pos":"ADJ"} ,
		{"word":"faillie","word_nosc":"faillie","lemma":"faillie","pos":"ADJ"} ,
		{"word":"faillies","word_nosc":"faillies","lemma":"faillie","pos":"ADJ"} ,
		{"word":"fainéant","word_nosc":"faineant","lemma":"fainéant","pos":"ADJ"} ,
		{"word":"fainéante","word_nosc":"faineante","lemma":"fainéant","pos":"ADJ"} ,
		{"word":"fainéantes","word_nosc":"faineantes","lemma":"fainéant","pos":"ADJ"} ,
		{"word":"fainéants","word_nosc":"faineants","lemma":"fainéant","pos":"ADJ"} ,
		{"word":"fair-play","word_nosc":"fair-play","lemma":"fair-play","pos":"ADJ"} ,
		{"word":"faisable","word_nosc":"faisable","lemma":"faisable","pos":"ADJ"} ,
		{"word":"faisandé","word_nosc":"faisande","lemma":"faisandé","pos":"ADJ"} ,
		{"word":"faisandée","word_nosc":"faisandee","lemma":"faisandé","pos":"ADJ"} ,
		{"word":"faisandées","word_nosc":"faisandees","lemma":"faisandé","pos":"ADJ"} ,
		{"word":"faisandés","word_nosc":"faisandes","lemma":"faisandé","pos":"ADJ"} ,
		{"word":"fait","word_nosc":"fait","lemma":"fait","pos":"ADJ"} ,
		{"word":"faite","word_nosc":"faite","lemma":"fait","pos":"ADJ"} ,
		{"word":"faites","word_nosc":"faites","lemma":"fait","pos":"ADJ"} ,
		{"word":"faits","word_nosc":"faits","lemma":"fait","pos":"ADJ"} ,
		{"word":"falciforme","word_nosc":"falciforme","lemma":"falciforme","pos":"ADJ"} ,
		{"word":"fallacieuse","word_nosc":"fallacieuse","lemma":"fallacieux","pos":"ADJ"} ,
		{"word":"fallacieuses","word_nosc":"fallacieuses","lemma":"fallacieux","pos":"ADJ"} ,
		{"word":"fallacieux","word_nosc":"fallacieux","lemma":"fallacieux","pos":"ADJ"} ,
		{"word":"falot","word_nosc":"falot","lemma":"falot","pos":"ADJ"} ,
		{"word":"falote","word_nosc":"falote","lemma":"falot","pos":"ADJ"} ,
		{"word":"falotes","word_nosc":"falotes","lemma":"falot","pos":"ADJ"} ,
		{"word":"falots","word_nosc":"falots","lemma":"falot","pos":"ADJ"} ,
		{"word":"fameuse","word_nosc":"fameuse","lemma":"fameux","pos":"ADJ"} ,
		{"word":"fameuses","word_nosc":"fameuses","lemma":"fameux","pos":"ADJ"} ,
		{"word":"fameux","word_nosc":"fameux","lemma":"fameux","pos":"ADJ"} ,
		{"word":"familial","word_nosc":"familial","lemma":"familial","pos":"ADJ"} ,
		{"word":"familiale","word_nosc":"familiale","lemma":"familial","pos":"ADJ"} ,
		{"word":"familiales","word_nosc":"familiales","lemma":"familial","pos":"ADJ"} ,
		{"word":"familiaux","word_nosc":"familiaux","lemma":"familial","pos":"ADJ"} ,
		{"word":"familier","word_nosc":"familier","lemma":"familier","pos":"ADJ"} ,
		{"word":"familiers","word_nosc":"familiers","lemma":"familier","pos":"ADJ"} ,
		{"word":"familière","word_nosc":"familiere","lemma":"familier","pos":"ADJ"} ,
		{"word":"familières","word_nosc":"familieres","lemma":"familier","pos":"ADJ"} ,
		{"word":"famé","word_nosc":"fame","lemma":"famé","pos":"ADJ"} ,
		{"word":"famée","word_nosc":"famee","lemma":"famé","pos":"ADJ"} ,
		{"word":"famées","word_nosc":"famees","lemma":"famé","pos":"ADJ"} ,
		{"word":"famélique","word_nosc":"famelique","lemma":"famélique","pos":"ADJ"} ,
		{"word":"faméliques","word_nosc":"fameliques","lemma":"famélique","pos":"ADJ"} ,
		{"word":"famés","word_nosc":"fames","lemma":"famé","pos":"ADJ"} ,
		{"word":"fana","word_nosc":"fana","lemma":"fana","pos":"ADJ"} ,
		{"word":"fanas","word_nosc":"fanas","lemma":"fana","pos":"ADJ"} ,
		{"word":"fanatique","word_nosc":"fanatique","lemma":"fanatique","pos":"ADJ"} ,
		{"word":"fanatiques","word_nosc":"fanatiques","lemma":"fanatique","pos":"ADJ"} ,
		{"word":"fanfaron","word_nosc":"fanfaron","lemma":"fanfaron","pos":"ADJ"} ,
		{"word":"fanfaronne","word_nosc":"fanfaronne","lemma":"fanfaron","pos":"ADJ"} ,
		{"word":"fanfaronnes","word_nosc":"fanfaronnes","lemma":"fanfaron","pos":"ADJ"} ,
		{"word":"fanfarons","word_nosc":"fanfarons","lemma":"fanfaron","pos":"ADJ"} ,
		{"word":"fang","word_nosc":"fang","lemma":"fang","pos":"ADJ"} ,
		{"word":"fangeuse","word_nosc":"fangeuse","lemma":"fangeux","pos":"ADJ"} ,
		{"word":"fangeuses","word_nosc":"fangeuses","lemma":"fangeux","pos":"ADJ"} ,
		{"word":"fangeux","word_nosc":"fangeux","lemma":"fangeux","pos":"ADJ"} ,
		{"word":"fanny","word_nosc":"fanny","lemma":"fanny","pos":"ADJ"} ,
		{"word":"fantaisiste","word_nosc":"fantaisiste","lemma":"fantaisiste","pos":"ADJ"} ,
		{"word":"fantaisistes","word_nosc":"fantaisistes","lemma":"fantaisiste","pos":"ADJ"} ,
		{"word":"fantasmagorique","word_nosc":"fantasmagorique","lemma":"fantasmagorique","pos":"ADJ"} ,
		{"word":"fantasmatique","word_nosc":"fantasmatique","lemma":"fantasmatique","pos":"ADJ"} ,
		{"word":"fantasque","word_nosc":"fantasque","lemma":"fantasque","pos":"ADJ"} ,
		{"word":"fantasques","word_nosc":"fantasques","lemma":"fantasque","pos":"ADJ"} ,
		{"word":"fantastique","word_nosc":"fantastique","lemma":"fantastique","pos":"ADJ"} ,
		{"word":"fantastiques","word_nosc":"fantastiques","lemma":"fantastique","pos":"ADJ"} ,
		{"word":"fantomale","word_nosc":"fantomale","lemma":"fantomal","pos":"ADJ"} ,
		{"word":"fantomales","word_nosc":"fantomales","lemma":"fantomal","pos":"ADJ"} ,
		{"word":"fantomatique","word_nosc":"fantomatique","lemma":"fantomatique","pos":"ADJ"} ,
		{"word":"fantomatiques","word_nosc":"fantomatiques","lemma":"fantomatique","pos":"ADJ"} ,
		{"word":"fané","word_nosc":"fane","lemma":"fané","pos":"ADJ"} ,
		{"word":"fanée","word_nosc":"fanee","lemma":"fané","pos":"ADJ"} ,
		{"word":"fanées","word_nosc":"fanees","lemma":"fané","pos":"ADJ"} ,
		{"word":"fanés","word_nosc":"fanes","lemma":"fané","pos":"ADJ"} ,
		{"word":"faramineuse","word_nosc":"faramineuse","lemma":"faramineux","pos":"ADJ"} ,
		{"word":"faramineuses","word_nosc":"faramineuses","lemma":"faramineux","pos":"ADJ"} ,
		{"word":"faramineux","word_nosc":"faramineux","lemma":"faramineux","pos":"ADJ"} ,
		{"word":"faraud","word_nosc":"faraud","lemma":"faraud","pos":"ADJ"} ,
		{"word":"faraude","word_nosc":"faraude","lemma":"faraud","pos":"ADJ"} ,
		{"word":"farauds","word_nosc":"farauds","lemma":"faraud","pos":"ADJ"} ,
		{"word":"farceur","word_nosc":"farceur","lemma":"farceur","pos":"ADJ"} ,
		{"word":"farceurs","word_nosc":"farceurs","lemma":"farceur","pos":"ADJ"} ,
		{"word":"farceuse","word_nosc":"farceuse","lemma":"farceur","pos":"ADJ"} ,
		{"word":"farci","word_nosc":"farci","lemma":"farci","pos":"ADJ"} ,
		{"word":"farcie","word_nosc":"farcie","lemma":"farci","pos":"ADJ"} ,
		{"word":"farcies","word_nosc":"farcies","lemma":"farci","pos":"ADJ"} ,
		{"word":"farcis","word_nosc":"farcis","lemma":"farci","pos":"ADJ"} ,
		{"word":"farfelu","word_nosc":"farfelu","lemma":"farfelu","pos":"ADJ"} ,
		{"word":"farfelue","word_nosc":"farfelue","lemma":"farfelu","pos":"ADJ"} ,
		{"word":"farfelues","word_nosc":"farfelues","lemma":"farfelu","pos":"ADJ"} ,
		{"word":"farfelus","word_nosc":"farfelus","lemma":"farfelu","pos":"ADJ"} ,
		{"word":"farfouilleurs","word_nosc":"farfouilleurs","lemma":"farfouilleur","pos":"ADJ"} ,
		{"word":"farineuse","word_nosc":"farineuse","lemma":"farineux","pos":"ADJ"} ,
		{"word":"farineuses","word_nosc":"farineuses","lemma":"farineux","pos":"ADJ"} ,
		{"word":"farineux","word_nosc":"farineux","lemma":"farineux","pos":"ADJ"} ,
		{"word":"farouche","word_nosc":"farouche","lemma":"farouche","pos":"ADJ"} ,
		{"word":"farouches","word_nosc":"farouches","lemma":"farouche","pos":"ADJ"} ,
		{"word":"fascinant","word_nosc":"fascinant","lemma":"fascinant","pos":"ADJ"} ,
		{"word":"fascinante","word_nosc":"fascinante","lemma":"fascinant","pos":"ADJ"} ,
		{"word":"fascinantes","word_nosc":"fascinantes","lemma":"fascinant","pos":"ADJ"} ,
		{"word":"fascinants","word_nosc":"fascinants","lemma":"fascinant","pos":"ADJ"} ,
		{"word":"fasciste","word_nosc":"fasciste","lemma":"fasciste","pos":"ADJ"} ,
		{"word":"fascistes","word_nosc":"fascistes","lemma":"fasciste","pos":"ADJ"} ,
		{"word":"fashionable","word_nosc":"fashionable","lemma":"fashionable","pos":"ADJ"} ,
		{"word":"faste","word_nosc":"faste","lemma":"faste","pos":"ADJ"} ,
		{"word":"fastes","word_nosc":"fastes","lemma":"faste","pos":"ADJ"} ,
		{"word":"fastidieuse","word_nosc":"fastidieuse","lemma":"fastidieux","pos":"ADJ"} ,
		{"word":"fastidieuses","word_nosc":"fastidieuses","lemma":"fastidieux","pos":"ADJ"} ,
		{"word":"fastidieux","word_nosc":"fastidieux","lemma":"fastidieux","pos":"ADJ"} ,
		{"word":"fastoche","word_nosc":"fastoche","lemma":"fastoche","pos":"ADJ"} ,
		{"word":"fastueuse","word_nosc":"fastueuse","lemma":"fastueux","pos":"ADJ"} ,
		{"word":"fastueuses","word_nosc":"fastueuses","lemma":"fastueux","pos":"ADJ"} ,
		{"word":"fastueux","word_nosc":"fastueux","lemma":"fastueux","pos":"ADJ"} ,
		{"word":"fat","word_nosc":"fat","lemma":"fat","pos":"ADJ"} ,
		{"word":"fatal","word_nosc":"fatal","lemma":"fatal","pos":"ADJ"} ,
		{"word":"fatale","word_nosc":"fatale","lemma":"fatal","pos":"ADJ"} ,
		{"word":"fatales","word_nosc":"fatales","lemma":"fatal","pos":"ADJ"} ,
		{"word":"fataliste","word_nosc":"fataliste","lemma":"fataliste","pos":"ADJ"} ,
		{"word":"fatalistes","word_nosc":"fatalistes","lemma":"fataliste","pos":"ADJ"} ,
		{"word":"fatals","word_nosc":"fatals","lemma":"fatal","pos":"ADJ"} ,
		{"word":"fate","word_nosc":"fate","lemma":"fat","pos":"ADJ"} ,
		{"word":"fatidique","word_nosc":"fatidique","lemma":"fatidique","pos":"ADJ"} ,
		{"word":"fatidiques","word_nosc":"fatidiques","lemma":"fatidique","pos":"ADJ"} ,
		{"word":"fatigant","word_nosc":"fatigant","lemma":"fatigant","pos":"ADJ"} ,
		{"word":"fatigante","word_nosc":"fatigante","lemma":"fatigant","pos":"ADJ"} ,
		{"word":"fatigantes","word_nosc":"fatigantes","lemma":"fatigant","pos":"ADJ"} ,
		{"word":"fatigants","word_nosc":"fatigants","lemma":"fatigant","pos":"ADJ"} ,
		{"word":"fatigué","word_nosc":"fatigue","lemma":"fatigué","pos":"ADJ"} ,
		{"word":"fatiguée","word_nosc":"fatiguee","lemma":"fatigué","pos":"ADJ"} ,
		{"word":"fatiguées","word_nosc":"fatiguees","lemma":"fatigué","pos":"ADJ"} ,
		{"word":"fatigués","word_nosc":"fatigues","lemma":"fatigué","pos":"ADJ"} ,
		{"word":"fats","word_nosc":"fats","lemma":"fat","pos":"ADJ"} ,
		{"word":"faubourien","word_nosc":"faubourien","lemma":"faubourien","pos":"ADJ"} ,
		{"word":"faubourienne","word_nosc":"faubourienne","lemma":"faubourien","pos":"ADJ"} ,
		{"word":"faubouriennes","word_nosc":"faubouriennes","lemma":"faubourien","pos":"ADJ"} ,
		{"word":"faubouriens","word_nosc":"faubouriens","lemma":"faubourien","pos":"ADJ"} ,
		{"word":"fauché","word_nosc":"fauche","lemma":"fauché","pos":"ADJ"} ,
		{"word":"fauchée","word_nosc":"fauchee","lemma":"fauché","pos":"ADJ"} ,
		{"word":"fauchées","word_nosc":"fauchees","lemma":"fauché","pos":"ADJ"} ,
		{"word":"fauchés","word_nosc":"fauches","lemma":"fauché","pos":"ADJ"} ,
		{"word":"faunesque","word_nosc":"faunesque","lemma":"faunesque","pos":"ADJ"} ,
		{"word":"faunesques","word_nosc":"faunesques","lemma":"faunesque","pos":"ADJ"} ,
		{"word":"fausse","word_nosc":"fausse","lemma":"faux","pos":"ADJ"} ,
		{"word":"fausses","word_nosc":"fausses","lemma":"fausses","pos":"ADJ"} ,
		{"word":"fausses","word_nosc":"fausses","lemma":"faux","pos":"ADJ"} ,
		{"word":"faustien","word_nosc":"faustien","lemma":"faustien","pos":"ADJ"} ,
		{"word":"faustienne","word_nosc":"faustienne","lemma":"faustien","pos":"ADJ"} ,
		{"word":"fautif","word_nosc":"fautif","lemma":"fautif","pos":"ADJ"} ,
		{"word":"fautifs","word_nosc":"fautifs","lemma":"fautif","pos":"ADJ"} ,
		{"word":"fautive","word_nosc":"fautive","lemma":"fautif","pos":"ADJ"} ,
		{"word":"fautives","word_nosc":"fautives","lemma":"fautif","pos":"ADJ"} ,
		{"word":"fauve","word_nosc":"fauve","lemma":"fauve","pos":"ADJ"} ,
		{"word":"fauves","word_nosc":"fauves","lemma":"fauve","pos":"ADJ"} ,
		{"word":"faux","word_nosc":"faux","lemma":"faux","pos":"ADJ"} ,
		{"word":"faux-frère","word_nosc":"faux-frere","lemma":"faux-frère","pos":"ADJ"} ,
		{"word":"favorable","word_nosc":"favorable","lemma":"favorable","pos":"ADJ"} ,
		{"word":"favorables","word_nosc":"favorables","lemma":"favorable","pos":"ADJ"} ,
		{"word":"favori","word_nosc":"favori","lemma":"favori","pos":"ADJ"} ,
		{"word":"favoris","word_nosc":"favoris","lemma":"favori","pos":"ADJ"} ,
		{"word":"favorite","word_nosc":"favorite","lemma":"favori","pos":"ADJ"} ,
		{"word":"favorites","word_nosc":"favorites","lemma":"favori","pos":"ADJ"} ,
		{"word":"fayot","word_nosc":"fayot","lemma":"fayot","pos":"ADJ"} ,
		{"word":"fayots","word_nosc":"fayots","lemma":"fayot","pos":"ADJ"} ,
		{"word":"façonnable","word_nosc":"faconnable","lemma":"façonnable","pos":"ADJ"} ,
		{"word":"faîtière","word_nosc":"faitiere","lemma":"faîtier","pos":"ADJ"} ,
		{"word":"faîtières","word_nosc":"faitieres","lemma":"faîtier","pos":"ADJ"} ,
		{"word":"feignant","word_nosc":"feignant","lemma":"feignant","pos":"ADJ"} ,
		{"word":"feignante","word_nosc":"feignante","lemma":"feignant","pos":"ADJ"} ,
		{"word":"feignants","word_nosc":"feignants","lemma":"feignant","pos":"ADJ"} ,
		{"word":"feignasse","word_nosc":"feignasse","lemma":"feignasse","pos":"ADJ"} ,
		{"word":"feignasses","word_nosc":"feignasses","lemma":"feignasse","pos":"ADJ"} ,
		{"word":"feint","word_nosc":"feint","lemma":"feint","pos":"ADJ"} ,
		{"word":"feinte","word_nosc":"feinte","lemma":"feint","pos":"ADJ"} ,
		{"word":"feintes","word_nosc":"feintes","lemma":"feint","pos":"ADJ"} ,
		{"word":"feints","word_nosc":"feints","lemma":"feint","pos":"ADJ"} ,
		{"word":"fellinienne","word_nosc":"fellinienne","lemma":"fellinien","pos":"ADJ"} ,
		{"word":"femelle","word_nosc":"femelle","lemma":"femelle","pos":"ADJ"} ,
		{"word":"femelles","word_nosc":"femelles","lemma":"femelle","pos":"ADJ"} ,
		{"word":"fendant","word_nosc":"fendant","lemma":"fendant","pos":"ADJ"} ,
		{"word":"fendants","word_nosc":"fendants","lemma":"fendant","pos":"ADJ"} ,
		{"word":"fendard","word_nosc":"fendard","lemma":"fendard","pos":"ADJ"} ,
		{"word":"fendillé","word_nosc":"fendille","lemma":"fendillé","pos":"ADJ"} ,
		{"word":"fendillée","word_nosc":"fendillee","lemma":"fendillé","pos":"ADJ"} ,
		{"word":"fendillées","word_nosc":"fendillees","lemma":"fendillé","pos":"ADJ"} ,
		{"word":"fendu","word_nosc":"fendu","lemma":"fendu","pos":"ADJ"} ,
		{"word":"fendue","word_nosc":"fendue","lemma":"fendu","pos":"ADJ"} ,
		{"word":"fendues","word_nosc":"fendues","lemma":"fendu","pos":"ADJ"} ,
		{"word":"fendus","word_nosc":"fendus","lemma":"fendu","pos":"ADJ"} ,
		{"word":"fermant","word_nosc":"fermant","lemma":"fermant","pos":"ADJ"} ,
		{"word":"ferme","word_nosc":"ferme","lemma":"ferme","pos":"ADJ"} ,
		{"word":"fermenté","word_nosc":"fermente","lemma":"fermenté","pos":"ADJ"} ,
		{"word":"fermentée","word_nosc":"fermentee","lemma":"fermenté","pos":"ADJ"} ,
		{"word":"fermentées","word_nosc":"fermentees","lemma":"fermenté","pos":"ADJ"} ,
		{"word":"fermentés","word_nosc":"fermentes","lemma":"fermenté","pos":"ADJ"} ,
		{"word":"fermes","word_nosc":"fermes","lemma":"ferme","pos":"ADJ"} ,
		{"word":"fermier","word_nosc":"fermier","lemma":"fermier","pos":"ADJ"} ,
		{"word":"fermiers","word_nosc":"fermiers","lemma":"fermier","pos":"ADJ"} ,
		{"word":"fermière","word_nosc":"fermiere","lemma":"fermier","pos":"ADJ"} ,
		{"word":"fermé","word_nosc":"ferme","lemma":"fermé","pos":"ADJ"} ,
		{"word":"fermée","word_nosc":"fermee","lemma":"fermé","pos":"ADJ"} ,
		{"word":"fermées","word_nosc":"fermees","lemma":"fermé","pos":"ADJ"} ,
		{"word":"fermés","word_nosc":"fermes","lemma":"fermé","pos":"ADJ"} ,
		{"word":"ferraillant","word_nosc":"ferraillant","lemma":"ferraillant","pos":"ADJ"} ,
		{"word":"ferraillante","word_nosc":"ferraillante","lemma":"ferraillant","pos":"ADJ"} ,
		{"word":"ferreuse","word_nosc":"ferreuse","lemma":"ferreux","pos":"ADJ"} ,
		{"word":"ferreux","word_nosc":"ferreux","lemma":"ferreux","pos":"ADJ"} ,
		{"word":"ferriques","word_nosc":"ferriques","lemma":"ferrique","pos":"ADJ"} ,
		{"word":"ferroviaire","word_nosc":"ferroviaire","lemma":"ferroviaire","pos":"ADJ"} ,
		{"word":"ferroviaires","word_nosc":"ferroviaires","lemma":"ferroviaire","pos":"ADJ"} ,
		{"word":"ferrugineuse","word_nosc":"ferrugineuse","lemma":"ferrugineux","pos":"ADJ"} ,
		{"word":"ferrugineuses","word_nosc":"ferrugineuses","lemma":"ferrugineux","pos":"ADJ"} ,
		{"word":"ferrugineux","word_nosc":"ferrugineux","lemma":"ferrugineux","pos":"ADJ"} ,
		{"word":"ferré","word_nosc":"ferre","lemma":"ferré","pos":"ADJ"} ,
		{"word":"ferrée","word_nosc":"ferree","lemma":"ferré","pos":"ADJ"} ,
		{"word":"ferrées","word_nosc":"ferrees","lemma":"ferré","pos":"ADJ"} ,
		{"word":"ferrés","word_nosc":"ferres","lemma":"ferré","pos":"ADJ"} ,
		{"word":"fertile","word_nosc":"fertile","lemma":"fertile","pos":"ADJ"} ,
		{"word":"fertiles","word_nosc":"fertiles","lemma":"fertile","pos":"ADJ"} ,
		{"word":"fertilisable","word_nosc":"fertilisable","lemma":"fertilisable","pos":"ADJ"} ,
		{"word":"fertilisant","word_nosc":"fertilisant","lemma":"fertilisant","pos":"ADJ"} ,
		{"word":"fertilisateur","word_nosc":"fertilisateur","lemma":"fertilisateur","pos":"ADJ"} ,
		{"word":"fervent","word_nosc":"fervent","lemma":"fervent","pos":"ADJ"} ,
		{"word":"fervente","word_nosc":"fervente","lemma":"fervent","pos":"ADJ"} ,
		{"word":"ferventes","word_nosc":"ferventes","lemma":"fervent","pos":"ADJ"} ,
		{"word":"fervents","word_nosc":"fervents","lemma":"fervent","pos":"ADJ"} ,
		{"word":"fessier","word_nosc":"fessier","lemma":"fessier","pos":"ADJ"} ,
		{"word":"fessiers","word_nosc":"fessiers","lemma":"fessier","pos":"ADJ"} ,
		{"word":"fessière","word_nosc":"fessiere","lemma":"fessier","pos":"ADJ"} ,
		{"word":"fessières","word_nosc":"fessieres","lemma":"fessier","pos":"ADJ"} ,
		{"word":"fessu","word_nosc":"fessu","lemma":"fessu","pos":"ADJ"} ,
		{"word":"fessue","word_nosc":"fessue","lemma":"fessu","pos":"ADJ"} ,
		{"word":"fessues","word_nosc":"fessues","lemma":"fessu","pos":"ADJ"} ,
		{"word":"festif","word_nosc":"festif","lemma":"festif","pos":"ADJ"} ,
		{"word":"festive","word_nosc":"festive","lemma":"festif","pos":"ADJ"} ,
		{"word":"festives","word_nosc":"festives","lemma":"festif","pos":"ADJ"} ,
		{"word":"feu","word_nosc":"feu","lemma":"feu","pos":"ADJ"} ,
		{"word":"feue","word_nosc":"feue","lemma":"feu","pos":"ADJ"} ,
		{"word":"feuille-morte","word_nosc":"feuille-morte","lemma":"feuille-morte","pos":"ADJ"} ,
		{"word":"feuilleté","word_nosc":"feuillete","lemma":"feuilleté","pos":"ADJ"} ,
		{"word":"feuilletée","word_nosc":"feuilletee","lemma":"feuilleté","pos":"ADJ"} ,
		{"word":"feuilletées","word_nosc":"feuilletees","lemma":"feuilleté","pos":"ADJ"} ,
		{"word":"feuilletés","word_nosc":"feuilletes","lemma":"feuilleté","pos":"ADJ"} ,
		{"word":"feuillu","word_nosc":"feuillu","lemma":"feuillu","pos":"ADJ"} ,
		{"word":"feuillue","word_nosc":"feuillue","lemma":"feuillu","pos":"ADJ"} ,
		{"word":"feuillues","word_nosc":"feuillues","lemma":"feuillu","pos":"ADJ"} ,
		{"word":"feuillus","word_nosc":"feuillus","lemma":"feuillu","pos":"ADJ"} ,
		{"word":"feuj","word_nosc":"feuj","lemma":"feuj","pos":"ADJ"} ,
		{"word":"feutré","word_nosc":"feutre","lemma":"feutré","pos":"ADJ"} ,
		{"word":"feutrée","word_nosc":"feutree","lemma":"feutré","pos":"ADJ"} ,
		{"word":"feutrées","word_nosc":"feutrees","lemma":"feutré","pos":"ADJ"} ,
		{"word":"feutrés","word_nosc":"feutres","lemma":"feutré","pos":"ADJ"} ,
		{"word":"fiable","word_nosc":"fiable","lemma":"fiable","pos":"ADJ"} ,
		{"word":"fiables","word_nosc":"fiables","lemma":"fiable","pos":"ADJ"} ,
		{"word":"fibreuse","word_nosc":"fibreuse","lemma":"fibreux","pos":"ADJ"} ,
		{"word":"fibreuses","word_nosc":"fibreuses","lemma":"fibreux","pos":"ADJ"} ,
		{"word":"fibreux","word_nosc":"fibreux","lemma":"fibreux","pos":"ADJ"} ,
		{"word":"fibrilleux","word_nosc":"fibrilleux","lemma":"fibrilleux","pos":"ADJ"} ,
		{"word":"fibromateuse","word_nosc":"fibromateuse","lemma":"fibromateux","pos":"ADJ"} ,
		{"word":"fibré","word_nosc":"fibre","lemma":"fibré","pos":"ADJ"} ,
		{"word":"ficelé","word_nosc":"ficele","lemma":"ficelé","pos":"ADJ"} ,
		{"word":"ficelée","word_nosc":"ficelee","lemma":"ficelé","pos":"ADJ"} ,
		{"word":"ficelées","word_nosc":"ficelees","lemma":"ficelé","pos":"ADJ"} ,
		{"word":"ficelés","word_nosc":"ficeles","lemma":"ficelé","pos":"ADJ"} ,
		{"word":"fichant","word_nosc":"fichant","lemma":"fichant","pos":"ADJ"} ,
		{"word":"fichu","word_nosc":"fichu","lemma":"fichu","pos":"ADJ"} ,
		{"word":"fichue","word_nosc":"fichue","lemma":"fichu","pos":"ADJ"} ,
		{"word":"fichues","word_nosc":"fichues","lemma":"fichu","pos":"ADJ"} ,
		{"word":"fichus","word_nosc":"fichus","lemma":"fichu","pos":"ADJ"} ,
		{"word":"fictif","word_nosc":"fictif","lemma":"fictif","pos":"ADJ"} ,
		{"word":"fictifs","word_nosc":"fictifs","lemma":"fictif","pos":"ADJ"} ,
		{"word":"fictionnel","word_nosc":"fictionnel","lemma":"fictionnel","pos":"ADJ"} ,
		{"word":"fictive","word_nosc":"fictive","lemma":"fictif","pos":"ADJ"} ,
		{"word":"fictives","word_nosc":"fictives","lemma":"fictif","pos":"ADJ"} ,
		{"word":"fiduciaire","word_nosc":"fiduciaire","lemma":"fiduciaire","pos":"ADJ"} ,
		{"word":"fiduciaires","word_nosc":"fiduciaires","lemma":"fiduciaire","pos":"ADJ"} ,
		{"word":"fidèle","word_nosc":"fidele","lemma":"fidèle","pos":"ADJ"} ,
		{"word":"fidèles","word_nosc":"fideles","lemma":"fidèle","pos":"ADJ"} ,
		{"word":"fieffé","word_nosc":"fieffe","lemma":"fieffé","pos":"ADJ"} ,
		{"word":"fieffée","word_nosc":"fieffee","lemma":"fieffé","pos":"ADJ"} ,
		{"word":"fieffés","word_nosc":"fieffes","lemma":"fieffé","pos":"ADJ"} ,
		{"word":"fielleuse","word_nosc":"fielleuse","lemma":"fielleux","pos":"ADJ"} ,
		{"word":"fielleuses","word_nosc":"fielleuses","lemma":"fielleux","pos":"ADJ"} ,
		{"word":"fielleux","word_nosc":"fielleux","lemma":"fielleux","pos":"ADJ"} ,
		{"word":"fienteuse","word_nosc":"fienteuse","lemma":"fienteux","pos":"ADJ"} ,
		{"word":"fienteux","word_nosc":"fienteux","lemma":"fienteux","pos":"ADJ"} ,
		{"word":"fier","word_nosc":"fier","lemma":"fier","pos":"ADJ"} ,
		{"word":"fiers","word_nosc":"fiers","lemma":"fier","pos":"ADJ"} ,
		{"word":"figuratif","word_nosc":"figuratif","lemma":"figuratif","pos":"ADJ"} ,
		{"word":"figuratifs","word_nosc":"figuratifs","lemma":"figuratif","pos":"ADJ"} ,
		{"word":"figurative","word_nosc":"figurative","lemma":"figuratif","pos":"ADJ"} ,
		{"word":"figuratives","word_nosc":"figuratives","lemma":"figuratif","pos":"ADJ"} ,
		{"word":"figuré","word_nosc":"figure","lemma":"figuré","pos":"ADJ"} ,
		{"word":"figurée","word_nosc":"figuree","lemma":"figuré","pos":"ADJ"} ,
		{"word":"figurées","word_nosc":"figurees","lemma":"figuré","pos":"ADJ"} ,
		{"word":"figurés","word_nosc":"figures","lemma":"figuré","pos":"ADJ"} ,
		{"word":"figé","word_nosc":"fige","lemma":"figé","pos":"ADJ"} ,
		{"word":"figée","word_nosc":"figee","lemma":"figé","pos":"ADJ"} ,
		{"word":"figées","word_nosc":"figees","lemma":"figé","pos":"ADJ"} ,
		{"word":"figés","word_nosc":"figes","lemma":"figé","pos":"ADJ"} ,
		{"word":"filaire","word_nosc":"filaire","lemma":"filaire","pos":"ADJ"} ,
		{"word":"filamenteux","word_nosc":"filamenteux","lemma":"filamenteux","pos":"ADJ"} ,
		{"word":"filandreuse","word_nosc":"filandreuse","lemma":"filandreux","pos":"ADJ"} ,
		{"word":"filandreuses","word_nosc":"filandreuses","lemma":"filandreux","pos":"ADJ"} ,
		{"word":"filandreux","word_nosc":"filandreux","lemma":"filandreux","pos":"ADJ"} ,
		{"word":"filant","word_nosc":"filant","lemma":"filant","pos":"ADJ"} ,
		{"word":"filante","word_nosc":"filante","lemma":"filant","pos":"ADJ"} ,
		{"word":"filantes","word_nosc":"filantes","lemma":"filant","pos":"ADJ"} ,
		{"word":"filasse","word_nosc":"filasse","lemma":"filasse","pos":"ADJ"} ,
		{"word":"fileté","word_nosc":"filete","lemma":"fileté","pos":"ADJ"} ,
		{"word":"filetée","word_nosc":"filetee","lemma":"fileté","pos":"ADJ"} ,
		{"word":"filial","word_nosc":"filial","lemma":"filial","pos":"ADJ"} ,
		{"word":"filiale","word_nosc":"filiale","lemma":"filial","pos":"ADJ"} ,
		{"word":"filiales","word_nosc":"filiales","lemma":"filial","pos":"ADJ"} ,
		{"word":"filiaux","word_nosc":"filiaux","lemma":"filial","pos":"ADJ"} ,
		{"word":"filiforme","word_nosc":"filiforme","lemma":"filiforme","pos":"ADJ"} ,
		{"word":"filiformes","word_nosc":"filiformes","lemma":"filiforme","pos":"ADJ"} ,
		{"word":"filioque","word_nosc":"filioque","lemma":"filioque","pos":"ADJ"} ,
		{"word":"filmique","word_nosc":"filmique","lemma":"filmique","pos":"ADJ"} ,
		{"word":"filoguidé","word_nosc":"filoguide","lemma":"filoguidé","pos":"ADJ"} ,
		{"word":"filtrant","word_nosc":"filtrant","lemma":"filtrant","pos":"ADJ"} ,
		{"word":"filtrantes","word_nosc":"filtrantes","lemma":"filtrant","pos":"ADJ"} ,
		{"word":"filtrants","word_nosc":"filtrants","lemma":"filtrant","pos":"ADJ"} ,
		{"word":"filé","word_nosc":"file","lemma":"filé","pos":"ADJ"} ,
		{"word":"filée","word_nosc":"filee","lemma":"filé","pos":"ADJ"} ,
		{"word":"filés","word_nosc":"files","lemma":"filé","pos":"ADJ"} ,
		{"word":"fin","word_nosc":"fin","lemma":"fin","pos":"ADJ"} ,
		{"word":"final","word_nosc":"final","lemma":"final","pos":"ADJ"} ,
		{"word":"finale","word_nosc":"finale","lemma":"final","pos":"ADJ"} ,
		{"word":"finales","word_nosc":"finales","lemma":"final","pos":"ADJ"} ,
		{"word":"finaliste","word_nosc":"finaliste","lemma":"finaliste","pos":"ADJ"} ,
		{"word":"finalistes","word_nosc":"finalistes","lemma":"finaliste","pos":"ADJ"} ,
		{"word":"financier","word_nosc":"financier","lemma":"financier","pos":"ADJ"} ,
		{"word":"financiers","word_nosc":"financiers","lemma":"financier","pos":"ADJ"} ,
		{"word":"financière","word_nosc":"financiere","lemma":"financier","pos":"ADJ"} ,
		{"word":"financières","word_nosc":"financieres","lemma":"financier","pos":"ADJ"} ,
		{"word":"finaud","word_nosc":"finaud","lemma":"finaud","pos":"ADJ"} ,
		{"word":"finaude","word_nosc":"finaude","lemma":"finaud","pos":"ADJ"} ,
		{"word":"finauds","word_nosc":"finauds","lemma":"finaud","pos":"ADJ"} ,
		{"word":"finaux","word_nosc":"finaux","lemma":"final","pos":"ADJ"} ,
		{"word":"fine","word_nosc":"fine","lemma":"fin","pos":"ADJ"} ,
		{"word":"fines","word_nosc":"fines","lemma":"fin","pos":"ADJ"} ,
		{"word":"finet","word_nosc":"finet","lemma":"finet","pos":"ADJ"} ,
		{"word":"fini","word_nosc":"fini","lemma":"fini","pos":"ADJ"} ,
		{"word":"finie","word_nosc":"finie","lemma":"fini","pos":"ADJ"} ,
		{"word":"finies","word_nosc":"finies","lemma":"fini","pos":"ADJ"} ,
		{"word":"finis","word_nosc":"finis","lemma":"fini","pos":"ADJ"} ,
		{"word":"finissant","word_nosc":"finissant","lemma":"finissant","pos":"ADJ"} ,
		{"word":"finissante","word_nosc":"finissante","lemma":"finissant","pos":"ADJ"} ,
		{"word":"finissantes","word_nosc":"finissantes","lemma":"finissant","pos":"ADJ"} ,
		{"word":"finissants","word_nosc":"finissants","lemma":"finissant","pos":"ADJ"} ,
		{"word":"finlandais","word_nosc":"finlandais","lemma":"finlandais","pos":"ADJ"} ,
		{"word":"finlandaise","word_nosc":"finlandaise","lemma":"finlandais","pos":"ADJ"} ,
		{"word":"finlandaises","word_nosc":"finlandaises","lemma":"finlandais","pos":"ADJ"} ,
		{"word":"finnois","word_nosc":"finnois","lemma":"finnois","pos":"ADJ"} ,
		{"word":"fins","word_nosc":"fins","lemma":"fin","pos":"ADJ"} ,
		{"word":"fiscal","word_nosc":"fiscal","lemma":"fiscal","pos":"ADJ"} ,
		{"word":"fiscale","word_nosc":"fiscale","lemma":"fiscal","pos":"ADJ"} ,
		{"word":"fiscales","word_nosc":"fiscales","lemma":"fiscal","pos":"ADJ"} ,
		{"word":"fiscaux","word_nosc":"fiscaux","lemma":"fiscal","pos":"ADJ"} ,
		{"word":"fissible","word_nosc":"fissible","lemma":"fissible","pos":"ADJ"} ,
		{"word":"fissibles","word_nosc":"fissibles","lemma":"fissible","pos":"ADJ"} ,
		{"word":"fitzgéraldiennes","word_nosc":"fitzgeraldiennes","lemma":"fitzgéraldien","pos":"ADJ"} ,
		{"word":"fixateur","word_nosc":"fixateur","lemma":"fixateur","pos":"ADJ"} ,
		{"word":"fixe","word_nosc":"fixe","lemma":"fixe","pos":"ADJ"} ,
		{"word":"fixes","word_nosc":"fixes","lemma":"fixe","pos":"ADJ"} ,
		{"word":"fixé","word_nosc":"fixe","lemma":"fixé","pos":"ADJ"} ,
		{"word":"fixée","word_nosc":"fixee","lemma":"fixé","pos":"ADJ"} ,
		{"word":"fixées","word_nosc":"fixees","lemma":"fixé","pos":"ADJ"} ,
		{"word":"fixés","word_nosc":"fixes","lemma":"fixé","pos":"ADJ"} ,
		{"word":"fière","word_nosc":"fiere","lemma":"fier","pos":"ADJ"} ,
		{"word":"fières","word_nosc":"fieres","lemma":"fier","pos":"ADJ"} ,
		{"word":"fiérot","word_nosc":"fierot","lemma":"fiérot","pos":"ADJ"} ,
		{"word":"fiérote","word_nosc":"fierote","lemma":"fiérot","pos":"ADJ"} ,
		{"word":"fiérots","word_nosc":"fierots","lemma":"fiérot","pos":"ADJ"} ,
		{"word":"fiévreuse","word_nosc":"fievreuse","lemma":"fiévreux","pos":"ADJ"} ,
		{"word":"fiévreuses","word_nosc":"fievreuses","lemma":"fiévreux","pos":"ADJ"} ,
		{"word":"fiévreux","word_nosc":"fievreux","lemma":"fiévreux","pos":"ADJ"} ,
		{"word":"flaccide","word_nosc":"flaccide","lemma":"flaccide","pos":"ADJ"} ,
		{"word":"flagada","word_nosc":"flagada","lemma":"flagada","pos":"ADJ"} ,
		{"word":"flagellé","word_nosc":"flagelle","lemma":"flagellé","pos":"ADJ"} ,
		{"word":"flagellée","word_nosc":"flagellee","lemma":"flagellé","pos":"ADJ"} ,
		{"word":"flagellées","word_nosc":"flagellees","lemma":"flagellé","pos":"ADJ"} ,
		{"word":"flagellés","word_nosc":"flagelles","lemma":"flagellé","pos":"ADJ"} ,
		{"word":"flageolant","word_nosc":"flageolant","lemma":"flageolant","pos":"ADJ"} ,
		{"word":"flageolante","word_nosc":"flageolante","lemma":"flageolant","pos":"ADJ"} ,
		{"word":"flageolantes","word_nosc":"flageolantes","lemma":"flageolant","pos":"ADJ"} ,
		{"word":"flageolants","word_nosc":"flageolants","lemma":"flageolant","pos":"ADJ"} ,
		{"word":"flagorneuse","word_nosc":"flagorneuse","lemma":"flagorneur","pos":"ADJ"} ,
		{"word":"flagrant","word_nosc":"flagrant","lemma":"flagrant","pos":"ADJ"} ,
		{"word":"flagrante","word_nosc":"flagrante","lemma":"flagrant","pos":"ADJ"} ,
		{"word":"flagrantes","word_nosc":"flagrantes","lemma":"flagrant","pos":"ADJ"} ,
		{"word":"flagrants","word_nosc":"flagrants","lemma":"flagrant","pos":"ADJ"} ,
		{"word":"flamand","word_nosc":"flamand","lemma":"flamand","pos":"ADJ"} ,
		{"word":"flamande","word_nosc":"flamande","lemma":"flamand","pos":"ADJ"} ,
		{"word":"flamandes","word_nosc":"flamandes","lemma":"flamand","pos":"ADJ"} ,
		{"word":"flamands","word_nosc":"flamands","lemma":"flamand","pos":"ADJ"} ,
		{"word":"flambant","word_nosc":"flambant","lemma":"flambant","pos":"ADJ"} ,
		{"word":"flambante","word_nosc":"flambante","lemma":"flambant","pos":"ADJ"} ,
		{"word":"flambantes","word_nosc":"flambantes","lemma":"flambant","pos":"ADJ"} ,
		{"word":"flambants","word_nosc":"flambants","lemma":"flambant","pos":"ADJ"} ,
		{"word":"flamboyant","word_nosc":"flamboyant","lemma":"flamboyant","pos":"ADJ"} ,
		{"word":"flamboyante","word_nosc":"flamboyante","lemma":"flamboyant","pos":"ADJ"} ,
		{"word":"flamboyantes","word_nosc":"flamboyantes","lemma":"flamboyant","pos":"ADJ"} ,
		{"word":"flamboyants","word_nosc":"flamboyants","lemma":"flamboyant","pos":"ADJ"} ,
		{"word":"flambé","word_nosc":"flambe","lemma":"flambé","pos":"ADJ"} ,
		{"word":"flambés","word_nosc":"flambes","lemma":"flambé","pos":"ADJ"} ,
		{"word":"flamenco","word_nosc":"flamenco","lemma":"flamenco","pos":"ADJ"} ,
		{"word":"flamencos","word_nosc":"flamencos","lemma":"flamenco","pos":"ADJ"} ,
		{"word":"flammé","word_nosc":"flamme","lemma":"flammé","pos":"ADJ"} ,
		{"word":"flammée","word_nosc":"flammee","lemma":"flammé","pos":"ADJ"} ,
		{"word":"flammés","word_nosc":"flammes","lemma":"flammé","pos":"ADJ"} ,
		{"word":"flapi","word_nosc":"flapi","lemma":"flapi","pos":"ADJ"} ,
		{"word":"flapie","word_nosc":"flapie","lemma":"flapi","pos":"ADJ"} ,
		{"word":"flapies","word_nosc":"flapies","lemma":"flapi","pos":"ADJ"} ,
		{"word":"flapis","word_nosc":"flapis","lemma":"flapi","pos":"ADJ"} ,
		{"word":"flasque","word_nosc":"flasque","lemma":"flasque","pos":"ADJ"} ,
		{"word":"flasques","word_nosc":"flasques","lemma":"flasque","pos":"ADJ"} ,
		{"word":"flat","word_nosc":"flat","lemma":"flat","pos":"ADJ"} ,
		{"word":"flats","word_nosc":"flats","lemma":"flat","pos":"ADJ"} ,
		{"word":"flatteur","word_nosc":"flatteur","lemma":"flatteur","pos":"ADJ"} ,
		{"word":"flatteurs","word_nosc":"flatteurs","lemma":"flatteur","pos":"ADJ"} ,
		{"word":"flatteuse","word_nosc":"flatteuse","lemma":"flatteur","pos":"ADJ"} ,
		{"word":"flatteuses","word_nosc":"flatteuses","lemma":"flatteur","pos":"ADJ"} ,
		{"word":"flatulent","word_nosc":"flatulent","lemma":"flatulent","pos":"ADJ"} ,
		{"word":"flatulents","word_nosc":"flatulents","lemma":"flatulent","pos":"ADJ"} ,
		{"word":"flave","word_nosc":"flave","lemma":"flave","pos":"ADJ"} ,
		{"word":"flegmatique","word_nosc":"flegmatique","lemma":"flegmatique","pos":"ADJ"} ,
		{"word":"flegmatiques","word_nosc":"flegmatiques","lemma":"flegmatique","pos":"ADJ"} ,
		{"word":"flemmard","word_nosc":"flemmard","lemma":"flemmard","pos":"ADJ"} ,
		{"word":"flemmarde","word_nosc":"flemmarde","lemma":"flemmard","pos":"ADJ"} ,
		{"word":"flemmardes","word_nosc":"flemmardes","lemma":"flemmard","pos":"ADJ"} ,
		{"word":"flemmards","word_nosc":"flemmards","lemma":"flemmard","pos":"ADJ"} ,
		{"word":"fleurdelisé","word_nosc":"fleurdelise","lemma":"fleurdelisé","pos":"ADJ"} ,
		{"word":"fleurdelisée","word_nosc":"fleurdelisee","lemma":"fleurdelisé","pos":"ADJ"} ,
		{"word":"fleurdelisés","word_nosc":"fleurdelises","lemma":"fleurdelisé","pos":"ADJ"} ,
		{"word":"fleuri","word_nosc":"fleuri","lemma":"fleuri","pos":"ADJ"} ,
		{"word":"fleurie","word_nosc":"fleurie","lemma":"fleuri","pos":"ADJ"} ,
		{"word":"fleuries","word_nosc":"fleuries","lemma":"fleuri","pos":"ADJ"} ,
		{"word":"fleuris","word_nosc":"fleuris","lemma":"fleuri","pos":"ADJ"} ,
		{"word":"fleurissant","word_nosc":"fleurissant","lemma":"fleurissant","pos":"ADJ"} ,
		{"word":"fleuronnée","word_nosc":"fleuronnee","lemma":"fleuronné","pos":"ADJ"} ,
		{"word":"flexible","word_nosc":"flexible","lemma":"flexible","pos":"ADJ"} ,
		{"word":"flexibles","word_nosc":"flexibles","lemma":"flexible","pos":"ADJ"} ,
		{"word":"flicard","word_nosc":"flicard","lemma":"flicard","pos":"ADJ"} ,
		{"word":"flicarde","word_nosc":"flicarde","lemma":"flicard","pos":"ADJ"} ,
		{"word":"flicardes","word_nosc":"flicardes","lemma":"flicard","pos":"ADJ"} ,
		{"word":"flicards","word_nosc":"flicards","lemma":"flicard","pos":"ADJ"} ,
		{"word":"flippé","word_nosc":"flippe","lemma":"flippé","pos":"ADJ"} ,
		{"word":"flippée","word_nosc":"flippee","lemma":"flippé","pos":"ADJ"} ,
		{"word":"flippés","word_nosc":"flippes","lemma":"flippé","pos":"ADJ"} ,
		{"word":"fliqué","word_nosc":"flique","lemma":"fliqué","pos":"ADJ"} ,
		{"word":"fliqués","word_nosc":"fliques","lemma":"fliqué","pos":"ADJ"} ,
		{"word":"flirteur","word_nosc":"flirteur","lemma":"flirteur","pos":"ADJ"} ,
		{"word":"flirteuse","word_nosc":"flirteuse","lemma":"flirteur","pos":"ADJ"} ,
		{"word":"flirteuses","word_nosc":"flirteuses","lemma":"flirteur","pos":"ADJ"} ,
		{"word":"floche","word_nosc":"floche","lemma":"floche","pos":"ADJ"} ,
		{"word":"floches","word_nosc":"floches","lemma":"floche","pos":"ADJ"} ,
		{"word":"floconneuse","word_nosc":"floconneuse","lemma":"floconneux","pos":"ADJ"} ,
		{"word":"floconneuses","word_nosc":"floconneuses","lemma":"floconneux","pos":"ADJ"} ,
		{"word":"floconneux","word_nosc":"floconneux","lemma":"floconneux","pos":"ADJ"} ,
		{"word":"flood","word_nosc":"flood","lemma":"flood","pos":"ADJ"} ,
		{"word":"floral","word_nosc":"floral","lemma":"floral","pos":"ADJ"} ,
		{"word":"florale","word_nosc":"florale","lemma":"floral","pos":"ADJ"} ,
		{"word":"florales","word_nosc":"florales","lemma":"floral","pos":"ADJ"} ,
		{"word":"floraux","word_nosc":"floraux","lemma":"floral","pos":"ADJ"} ,
		{"word":"florentin","word_nosc":"florentin","lemma":"florentin","pos":"ADJ"} ,
		{"word":"florentine","word_nosc":"florentine","lemma":"florentin","pos":"ADJ"} ,
		{"word":"florentines","word_nosc":"florentines","lemma":"florentin","pos":"ADJ"} ,
		{"word":"florentins","word_nosc":"florentins","lemma":"florentin","pos":"ADJ"} ,
		{"word":"florissant","word_nosc":"florissant","lemma":"florissant","pos":"ADJ"} ,
		{"word":"florissante","word_nosc":"florissante","lemma":"florissant","pos":"ADJ"} ,
		{"word":"florissantes","word_nosc":"florissantes","lemma":"florissant","pos":"ADJ"} ,
		{"word":"florissants","word_nosc":"florissants","lemma":"florissant","pos":"ADJ"} ,
		{"word":"flottant","word_nosc":"flottant","lemma":"flottant","pos":"ADJ"} ,
		{"word":"flottante","word_nosc":"flottante","lemma":"flottant","pos":"ADJ"} ,
		{"word":"flottantes","word_nosc":"flottantes","lemma":"flottant","pos":"ADJ"} ,
		{"word":"flottants","word_nosc":"flottants","lemma":"flottant","pos":"ADJ"} ,
		{"word":"flou","word_nosc":"flou","lemma":"flou","pos":"ADJ"} ,
		{"word":"floue","word_nosc":"floue","lemma":"flou","pos":"ADJ"} ,
		{"word":"floues","word_nosc":"floues","lemma":"flou","pos":"ADJ"} ,
		{"word":"flous","word_nosc":"flous","lemma":"flou","pos":"ADJ"} ,
		{"word":"fluctuant","word_nosc":"fluctuant","lemma":"fluctuant","pos":"ADJ"} ,
		{"word":"fluctuante","word_nosc":"fluctuante","lemma":"fluctuant","pos":"ADJ"} ,
		{"word":"fluet","word_nosc":"fluet","lemma":"fluet","pos":"ADJ"} ,
		{"word":"fluets","word_nosc":"fluets","lemma":"fluet","pos":"ADJ"} ,
		{"word":"fluette","word_nosc":"fluette","lemma":"fluet","pos":"ADJ"} ,
		{"word":"fluettes","word_nosc":"fluettes","lemma":"fluet","pos":"ADJ"} ,
		{"word":"fluide","word_nosc":"fluide","lemma":"fluide","pos":"ADJ"} ,
		{"word":"fluides","word_nosc":"fluides","lemma":"fluide","pos":"ADJ"} ,
		{"word":"fluidiques","word_nosc":"fluidiques","lemma":"fluidique","pos":"ADJ"} ,
		{"word":"fluo","word_nosc":"fluo","lemma":"fluo","pos":"ADJ"} ,
		{"word":"fluorescent","word_nosc":"fluorescent","lemma":"fluorescent","pos":"ADJ"} ,
		{"word":"fluorescente","word_nosc":"fluorescente","lemma":"fluorescent","pos":"ADJ"} ,
		{"word":"fluorescentes","word_nosc":"fluorescentes","lemma":"fluorescent","pos":"ADJ"} ,
		{"word":"fluorescents","word_nosc":"fluorescents","lemma":"fluorescent","pos":"ADJ"} ,
		{"word":"fluorhydrique","word_nosc":"fluorhydrique","lemma":"fluorhydrique","pos":"ADJ"} ,
		{"word":"fluorée","word_nosc":"fluoree","lemma":"fluoré","pos":"ADJ"} ,
		{"word":"fluvial","word_nosc":"fluvial","lemma":"fluvial","pos":"ADJ"} ,
		{"word":"fluviale","word_nosc":"fluviale","lemma":"fluvial","pos":"ADJ"} ,
		{"word":"fluviales","word_nosc":"fluviales","lemma":"fluvial","pos":"ADJ"} ,
		{"word":"fluviatile","word_nosc":"fluviatile","lemma":"fluviatile","pos":"ADJ"} ,
		{"word":"fluviaux","word_nosc":"fluviaux","lemma":"fluvial","pos":"ADJ"} ,
		{"word":"flâneur","word_nosc":"flaneur","lemma":"flâneur","pos":"ADJ"} ,
		{"word":"flâneurs","word_nosc":"flaneurs","lemma":"flâneur","pos":"ADJ"} ,
		{"word":"flâneuse","word_nosc":"flaneuse","lemma":"flâneur","pos":"ADJ"} ,
		{"word":"flâneuses","word_nosc":"flaneuses","lemma":"flâneur","pos":"ADJ"} ,
		{"word":"fléchisseur","word_nosc":"flechisseur","lemma":"fléchisseur","pos":"ADJ"} ,
		{"word":"fléché","word_nosc":"fleche","lemma":"fléché","pos":"ADJ"} ,
		{"word":"fléchée","word_nosc":"flechee","lemma":"fléché","pos":"ADJ"} ,
		{"word":"fléchées","word_nosc":"flechees","lemma":"fléché","pos":"ADJ"} ,
		{"word":"fléchés","word_nosc":"fleches","lemma":"fléché","pos":"ADJ"} ,
		{"word":"flétrissant","word_nosc":"fletrissant","lemma":"flétrissant","pos":"ADJ"} ,
		{"word":"flûté","word_nosc":"flute","lemma":"flûté","pos":"ADJ"} ,
		{"word":"flûtée","word_nosc":"flutee","lemma":"flûté","pos":"ADJ"} ,
		{"word":"flûtés","word_nosc":"flutes","lemma":"flûté","pos":"ADJ"} ,
		{"word":"focal","word_nosc":"focal","lemma":"focal","pos":"ADJ"} ,
		{"word":"focale","word_nosc":"focale","lemma":"focal","pos":"ADJ"} ,
		{"word":"focales","word_nosc":"focales","lemma":"focal","pos":"ADJ"} ,
		{"word":"focaux","word_nosc":"focaux","lemma":"focal","pos":"ADJ"} ,
		{"word":"foetal","word_nosc":"foetal","lemma":"foetal","pos":"ADJ"} ,
		{"word":"foetale","word_nosc":"foetale","lemma":"foetal","pos":"ADJ"} ,
		{"word":"foetales","word_nosc":"foetales","lemma":"foetal","pos":"ADJ"} ,
		{"word":"foetaux","word_nosc":"foetaux","lemma":"foetal","pos":"ADJ"} ,
		{"word":"fofolle","word_nosc":"fofolle","lemma":"fofolle","pos":"ADJ"} ,
		{"word":"fofolles","word_nosc":"fofolles","lemma":"fofolle","pos":"ADJ"} ,
		{"word":"foireuse","word_nosc":"foireuse","lemma":"foireux","pos":"ADJ"} ,
		{"word":"foireuses","word_nosc":"foireuses","lemma":"foireux","pos":"ADJ"} ,
		{"word":"foireux","word_nosc":"foireux","lemma":"foireux","pos":"ADJ"} ,
		{"word":"foisonnant","word_nosc":"foisonnant","lemma":"foisonnant","pos":"ADJ"} ,
		{"word":"foisonnante","word_nosc":"foisonnante","lemma":"foisonnant","pos":"ADJ"} ,
		{"word":"foisonnantes","word_nosc":"foisonnantes","lemma":"foisonnant","pos":"ADJ"} ,
		{"word":"fol","word_nosc":"fol","lemma":"fol","pos":"ADJ"} ,
		{"word":"foldingue","word_nosc":"foldingue","lemma":"foldingue","pos":"ADJ"} ,
		{"word":"foldingues","word_nosc":"foldingues","lemma":"foldingue","pos":"ADJ"} ,
		{"word":"foliacée","word_nosc":"foliacee","lemma":"foliacé","pos":"ADJ"} ,
		{"word":"folichon","word_nosc":"folichon","lemma":"folichon","pos":"ADJ"} ,
		{"word":"folichonne","word_nosc":"folichonne","lemma":"folichon","pos":"ADJ"} ,
		{"word":"folichonnes","word_nosc":"folichonnes","lemma":"folichon","pos":"ADJ"} ,
		{"word":"folingue","word_nosc":"folingue","lemma":"folingue","pos":"ADJ"} ,
		{"word":"folingues","word_nosc":"folingues","lemma":"folingue","pos":"ADJ"} ,
		{"word":"folique","word_nosc":"folique","lemma":"folique","pos":"ADJ"} ,
		{"word":"folklo","word_nosc":"folklo","lemma":"folklo","pos":"ADJ"} ,
		{"word":"folklorique","word_nosc":"folklorique","lemma":"folklorique","pos":"ADJ"} ,
		{"word":"folkloriques","word_nosc":"folkloriques","lemma":"folklorique","pos":"ADJ"} ,
		{"word":"folle","word_nosc":"folle","lemma":"fou","pos":"ADJ"} ,
		{"word":"folles","word_nosc":"folles","lemma":"fou","pos":"ADJ"} ,
		{"word":"follet","word_nosc":"follet","lemma":"follet","pos":"ADJ"} ,
		{"word":"follets","word_nosc":"follets","lemma":"follet","pos":"ADJ"} ,
		{"word":"follette","word_nosc":"follette","lemma":"follet","pos":"ADJ"} ,
		{"word":"follettes","word_nosc":"follettes","lemma":"follet","pos":"ADJ"} ,
		{"word":"folliculaire","word_nosc":"folliculaire","lemma":"folliculaire","pos":"ADJ"} ,
		{"word":"follingue","word_nosc":"follingue","lemma":"follingue","pos":"ADJ"} ,
		{"word":"fols","word_nosc":"fols","lemma":"fol","pos":"ADJ"} ,
		{"word":"folâtre","word_nosc":"folatre","lemma":"folâtre","pos":"ADJ"} ,
		{"word":"folâtres","word_nosc":"folatres","lemma":"folâtre","pos":"ADJ"} ,
		{"word":"fonceur","word_nosc":"fonceur","lemma":"fonceur","pos":"ADJ"} ,
		{"word":"fonceurs","word_nosc":"fonceurs","lemma":"fonceur","pos":"ADJ"} ,
		{"word":"fonceuse","word_nosc":"fonceuse","lemma":"fonceur","pos":"ADJ"} ,
		{"word":"foncier","word_nosc":"foncier","lemma":"foncier","pos":"ADJ"} ,
		{"word":"fonciers","word_nosc":"fonciers","lemma":"foncier","pos":"ADJ"} ,
		{"word":"foncière","word_nosc":"fonciere","lemma":"foncier","pos":"ADJ"} ,
		{"word":"foncières","word_nosc":"foncieres","lemma":"foncier","pos":"ADJ"} ,
		{"word":"fonctionnaliste","word_nosc":"fonctionnaliste","lemma":"fonctionnaliste","pos":"ADJ"} ,
		{"word":"fonctionnel","word_nosc":"fonctionnel","lemma":"fonctionnel","pos":"ADJ"} ,
		{"word":"fonctionnelle","word_nosc":"fonctionnelle","lemma":"fonctionnel","pos":"ADJ"} ,
		{"word":"fonctionnelles","word_nosc":"fonctionnelles","lemma":"fonctionnel","pos":"ADJ"} ,
		{"word":"fonctionnels","word_nosc":"fonctionnels","lemma":"fonctionnel","pos":"ADJ"} ,
		{"word":"foncé","word_nosc":"fonce","lemma":"foncé","pos":"ADJ"} ,
		{"word":"foncée","word_nosc":"foncee","lemma":"foncé","pos":"ADJ"} ,
		{"word":"foncées","word_nosc":"foncees","lemma":"foncé","pos":"ADJ"} ,
		{"word":"foncés","word_nosc":"fonces","lemma":"foncé","pos":"ADJ"} ,
		{"word":"fondamental","word_nosc":"fondamental","lemma":"fondamental","pos":"ADJ"} ,
		{"word":"fondamentale","word_nosc":"fondamentale","lemma":"fondamental","pos":"ADJ"} ,
		{"word":"fondamentales","word_nosc":"fondamentales","lemma":"fondamental","pos":"ADJ"} ,
		{"word":"fondamentaliste","word_nosc":"fondamentaliste","lemma":"fondamentaliste","pos":"ADJ"} ,
		{"word":"fondamentalistes","word_nosc":"fondamentalistes","lemma":"fondamentaliste","pos":"ADJ"} ,
		{"word":"fondamentaux","word_nosc":"fondamentaux","lemma":"fondamental","pos":"ADJ"} ,
		{"word":"fondant","word_nosc":"fondant","lemma":"fondant","pos":"ADJ"} ,
		{"word":"fondante","word_nosc":"fondante","lemma":"fondant","pos":"ADJ"} ,
		{"word":"fondantes","word_nosc":"fondantes","lemma":"fondant","pos":"ADJ"} ,
		{"word":"fondants","word_nosc":"fondants","lemma":"fondant","pos":"ADJ"} ,
		{"word":"fondateur","word_nosc":"fondateur","lemma":"fondateur","pos":"ADJ"} ,
		{"word":"fondateurs","word_nosc":"fondateurs","lemma":"fondateur","pos":"ADJ"} ,
		{"word":"fondatrice","word_nosc":"fondatrice","lemma":"fondateur","pos":"ADJ"} ,
		{"word":"fondatrices","word_nosc":"fondatrices","lemma":"fondateur","pos":"ADJ"} ,
		{"word":"fondu","word_nosc":"fondu","lemma":"fondu","pos":"ADJ"} ,
		{"word":"fondus","word_nosc":"fondus","lemma":"fondu","pos":"ADJ"} ,
		{"word":"fondé","word_nosc":"fonde","lemma":"fondé","pos":"ADJ"} ,
		{"word":"fondée","word_nosc":"fondee","lemma":"fondé","pos":"ADJ"} ,
		{"word":"fondées","word_nosc":"fondees","lemma":"fondé","pos":"ADJ"} ,
		{"word":"fondés","word_nosc":"fondes","lemma":"fondé","pos":"ADJ"} ,
		{"word":"fongible","word_nosc":"fongible","lemma":"fongible","pos":"ADJ"} ,
		{"word":"fongicide","word_nosc":"fongicide","lemma":"fongicide","pos":"ADJ"} ,
		{"word":"fongique","word_nosc":"fongique","lemma":"fongique","pos":"ADJ"} ,
		{"word":"fongueuse","word_nosc":"fongueuse","lemma":"fongueux","pos":"ADJ"} ,
		{"word":"fongueuses","word_nosc":"fongueuses","lemma":"fongueux","pos":"ADJ"} ,
		{"word":"footballistique","word_nosc":"footballistique","lemma":"footballistique","pos":"ADJ"} ,
		{"word":"footballistiques","word_nosc":"footballistiques","lemma":"footballistique","pos":"ADJ"} ,
		{"word":"footeuse","word_nosc":"footeuse","lemma":"footeux","pos":"ADJ"} ,
		{"word":"forain","word_nosc":"forain","lemma":"forain","pos":"ADJ"} ,
		{"word":"foraine","word_nosc":"foraine","lemma":"forain","pos":"ADJ"} ,
		{"word":"foraines","word_nosc":"foraines","lemma":"forain","pos":"ADJ"} ,
		{"word":"forains","word_nosc":"forains","lemma":"forain","pos":"ADJ"} ,
		{"word":"force","word_nosc":"force","lemma":"force","pos":"ADJ:ind"} ,
		{"word":"forcené","word_nosc":"forcene","lemma":"forcené","pos":"ADJ"} ,
		{"word":"forcenée","word_nosc":"forcenee","lemma":"forcené","pos":"ADJ"} ,
		{"word":"forcenées","word_nosc":"forcenees","lemma":"forcené","pos":"ADJ"} ,
		{"word":"forcenés","word_nosc":"forcenes","lemma":"forcené","pos":"ADJ"} ,
		{"word":"forcé","word_nosc":"force","lemma":"forcé","pos":"ADJ"} ,
		{"word":"forcée","word_nosc":"forcee","lemma":"forcé","pos":"ADJ"} ,
		{"word":"forcées","word_nosc":"forcees","lemma":"forcé","pos":"ADJ"} ,
		{"word":"forcés","word_nosc":"forces","lemma":"forcé","pos":"ADJ"} ,
		{"word":"forestier","word_nosc":"forestier","lemma":"forestier","pos":"ADJ"} ,
		{"word":"forestiers","word_nosc":"forestiers","lemma":"forestier","pos":"ADJ"} ,
		{"word":"forestière","word_nosc":"forestiere","lemma":"forestier","pos":"ADJ"} ,
		{"word":"forestières","word_nosc":"forestieres","lemma":"forestier","pos":"ADJ"} ,
		{"word":"foreur","word_nosc":"foreur","lemma":"foreur","pos":"ADJ"} ,
		{"word":"foreurs","word_nosc":"foreurs","lemma":"foreur","pos":"ADJ"} ,
		{"word":"forfaitaire","word_nosc":"forfaitaire","lemma":"forfaitaire","pos":"ADJ"} ,
		{"word":"forfaitaires","word_nosc":"forfaitaires","lemma":"forfaitaire","pos":"ADJ"} ,
		{"word":"formaliste","word_nosc":"formaliste","lemma":"formaliste","pos":"ADJ"} ,
		{"word":"formalistes","word_nosc":"formalistes","lemma":"formaliste","pos":"ADJ"} ,
		{"word":"formalisé","word_nosc":"formalise","lemma":"formalisé","pos":"ADJ"} ,
		{"word":"formateur","word_nosc":"formateur","lemma":"formateur","pos":"ADJ"} ,
		{"word":"formatrice","word_nosc":"formatrice","lemma":"formateur","pos":"ADJ"} ,
		{"word":"formatrices","word_nosc":"formatrices","lemma":"formateur","pos":"ADJ"} ,
		{"word":"formel","word_nosc":"formel","lemma":"formel","pos":"ADJ"} ,
		{"word":"formelle","word_nosc":"formelle","lemma":"formel","pos":"ADJ"} ,
		{"word":"formelles","word_nosc":"formelles","lemma":"formel","pos":"ADJ"} ,
		{"word":"formels","word_nosc":"formels","lemma":"formel","pos":"ADJ"} ,
		{"word":"formid","word_nosc":"formid","lemma":"formid","pos":"ADJ"} ,
		{"word":"formidable","word_nosc":"formidable","lemma":"formidable","pos":"ADJ"} ,
		{"word":"formidables","word_nosc":"formidables","lemma":"formidable","pos":"ADJ"} ,
		{"word":"formide","word_nosc":"formide","lemma":"formide","pos":"ADJ"} ,
		{"word":"formides","word_nosc":"formides","lemma":"formide","pos":"ADJ"} ,
		{"word":"formids","word_nosc":"formids","lemma":"formid","pos":"ADJ"} ,
		{"word":"formique","word_nosc":"formique","lemma":"formique","pos":"ADJ"} ,
		{"word":"formulé","word_nosc":"formule","lemma":"formulé","pos":"ADJ"} ,
		{"word":"formulée","word_nosc":"formulee","lemma":"formulé","pos":"ADJ"} ,
		{"word":"formulées","word_nosc":"formulees","lemma":"formulé","pos":"ADJ"} ,
		{"word":"formulés","word_nosc":"formules","lemma":"formulé","pos":"ADJ"} ,
		{"word":"formé","word_nosc":"forme","lemma":"formé","pos":"ADJ"} ,
		{"word":"formée","word_nosc":"formee","lemma":"formé","pos":"ADJ"} ,
		{"word":"formées","word_nosc":"formees","lemma":"formé","pos":"ADJ"} ,
		{"word":"formés","word_nosc":"formes","lemma":"formé","pos":"ADJ"} ,
		{"word":"fort","word_nosc":"fort","lemma":"fort","pos":"ADJ"} ,
		{"word":"forte","word_nosc":"forte","lemma":"fort","pos":"ADJ"} ,
		{"word":"fortes","word_nosc":"fortes","lemma":"fort","pos":"ADJ"} ,
		{"word":"fortiche","word_nosc":"fortiche","lemma":"fortiche","pos":"ADJ"} ,
		{"word":"fortiches","word_nosc":"fortiches","lemma":"fortiche","pos":"ADJ"} ,
		{"word":"fortifiant","word_nosc":"fortifiant","lemma":"fortifiant","pos":"ADJ"} ,
		{"word":"fortifiants","word_nosc":"fortifiants","lemma":"fortifiant","pos":"ADJ"} ,
		{"word":"fortifié","word_nosc":"fortifie","lemma":"fortifié","pos":"ADJ"} ,
		{"word":"fortifiée","word_nosc":"fortifiee","lemma":"fortifié","pos":"ADJ"} ,
		{"word":"fortifiées","word_nosc":"fortifiees","lemma":"fortifié","pos":"ADJ"} ,
		{"word":"fortifiés","word_nosc":"fortifies","lemma":"fortifié","pos":"ADJ"} ,
		{"word":"forts","word_nosc":"forts","lemma":"fort","pos":"ADJ"} ,
		{"word":"fortuit","word_nosc":"fortuit","lemma":"fortuit","pos":"ADJ"} ,
		{"word":"fortuite","word_nosc":"fortuite","lemma":"fortuit","pos":"ADJ"} ,
		{"word":"fortuites","word_nosc":"fortuites","lemma":"fortuit","pos":"ADJ"} ,
		{"word":"fortuits","word_nosc":"fortuits","lemma":"fortuit","pos":"ADJ"} ,
		{"word":"fortuné","word_nosc":"fortune","lemma":"fortuné","pos":"ADJ"} ,
		{"word":"fortunée","word_nosc":"fortunee","lemma":"fortuné","pos":"ADJ"} ,
		{"word":"fortunées","word_nosc":"fortunees","lemma":"fortuné","pos":"ADJ"} ,
		{"word":"fortunés","word_nosc":"fortunes","lemma":"fortuné","pos":"ADJ"} ,
		{"word":"fossile","word_nosc":"fossile","lemma":"fossile","pos":"ADJ"} ,
		{"word":"fossiles","word_nosc":"fossiles","lemma":"fossile","pos":"ADJ"} ,
		{"word":"fou","word_nosc":"fou","lemma":"fou","pos":"ADJ"} ,
		{"word":"fou-fou","word_nosc":"fou-fou","lemma":"fou-fou","pos":"ADJ"} ,
		{"word":"fou-rire","word_nosc":"fou-rire","lemma":"fou-rire","pos":"ADJ"} ,
		{"word":"foudroyant","word_nosc":"foudroyant","lemma":"foudroyant","pos":"ADJ"} ,
		{"word":"foudroyante","word_nosc":"foudroyante","lemma":"foudroyant","pos":"ADJ"} ,
		{"word":"foudroyantes","word_nosc":"foudroyantes","lemma":"foudroyant","pos":"ADJ"} ,
		{"word":"foudroyants","word_nosc":"foudroyants","lemma":"foudroyant","pos":"ADJ"} ,
		{"word":"fouettard","word_nosc":"fouettard","lemma":"fouettard","pos":"ADJ"} ,
		{"word":"fouettards","word_nosc":"fouettards","lemma":"fouettard","pos":"ADJ"} ,
		{"word":"fouetteurs","word_nosc":"fouetteurs","lemma":"fouetteur","pos":"ADJ"} ,
		{"word":"foufou","word_nosc":"foufou","lemma":"foufou","pos":"ADJ"} ,
		{"word":"foufous","word_nosc":"foufous","lemma":"foufou","pos":"ADJ"} ,
		{"word":"fougueuse","word_nosc":"fougueuse","lemma":"fougueux","pos":"ADJ"} ,
		{"word":"fougueuses","word_nosc":"fougueuses","lemma":"fougueux","pos":"ADJ"} ,
		{"word":"fougueux","word_nosc":"fougueux","lemma":"fougueux","pos":"ADJ"} ,
		{"word":"fouilleur","word_nosc":"fouilleur","lemma":"fouilleur","pos":"ADJ"} ,
		{"word":"fouilleurs","word_nosc":"fouilleurs","lemma":"fouilleur","pos":"ADJ"} ,
		{"word":"fouinards","word_nosc":"fouinards","lemma":"fouinard","pos":"ADJ"} ,
		{"word":"fouineur","word_nosc":"fouineur","lemma":"fouineur","pos":"ADJ"} ,
		{"word":"fouineurs","word_nosc":"fouineurs","lemma":"fouineur","pos":"ADJ"} ,
		{"word":"fouineuse","word_nosc":"fouineuse","lemma":"fouineur","pos":"ADJ"} ,
		{"word":"fouisseur","word_nosc":"fouisseur","lemma":"fouisseur","pos":"ADJ"} ,
		{"word":"fouisseurs","word_nosc":"fouisseurs","lemma":"fouisseur","pos":"ADJ"} ,
		{"word":"fouisseuse","word_nosc":"fouisseuse","lemma":"fouisseur","pos":"ADJ"} ,
		{"word":"fouisseuses","word_nosc":"fouisseuses","lemma":"fouisseur","pos":"ADJ"} ,
		{"word":"foulant","word_nosc":"foulant","lemma":"foulant","pos":"ADJ"} ,
		{"word":"foulbé","word_nosc":"foulbe","lemma":"foulbé","pos":"ADJ"} ,
		{"word":"fourbe","word_nosc":"fourbe","lemma":"fourbe","pos":"ADJ"} ,
		{"word":"fourbes","word_nosc":"fourbes","lemma":"fourbe","pos":"ADJ"} ,
		{"word":"fourbi","word_nosc":"fourbi","lemma":"fourbi","pos":"ADJ"} ,
		{"word":"fourbis","word_nosc":"fourbis","lemma":"fourbi","pos":"ADJ"} ,
		{"word":"fourbu","word_nosc":"fourbu","lemma":"fourbu","pos":"ADJ"} ,
		{"word":"fourbue","word_nosc":"fourbue","lemma":"fourbu","pos":"ADJ"} ,
		{"word":"fourbues","word_nosc":"fourbues","lemma":"fourbu","pos":"ADJ"} ,
		{"word":"fourbus","word_nosc":"fourbus","lemma":"fourbu","pos":"ADJ"} ,
		{"word":"fourchu","word_nosc":"fourchu","lemma":"fourchu","pos":"ADJ"} ,
		{"word":"fourchue","word_nosc":"fourchue","lemma":"fourchu","pos":"ADJ"} ,
		{"word":"fourchues","word_nosc":"fourchues","lemma":"fourchu","pos":"ADJ"} ,
		{"word":"fourchus","word_nosc":"fourchus","lemma":"fourchu","pos":"ADJ"} ,
		{"word":"fourmillant","word_nosc":"fourmillant","lemma":"fourmillant","pos":"ADJ"} ,
		{"word":"fourmillante","word_nosc":"fourmillante","lemma":"fourmillant","pos":"ADJ"} ,
		{"word":"fourmillants","word_nosc":"fourmillants","lemma":"fourmillant","pos":"ADJ"} ,
		{"word":"fourni","word_nosc":"fourni","lemma":"fourni","pos":"ADJ"} ,
		{"word":"fournie","word_nosc":"fournie","lemma":"fourni","pos":"ADJ"} ,
		{"word":"fournies","word_nosc":"fournies","lemma":"fourni","pos":"ADJ"} ,
		{"word":"fournis","word_nosc":"fournis","lemma":"fourni","pos":"ADJ"} ,
		{"word":"fourrager","word_nosc":"fourrager","lemma":"fourrager","pos":"ADJ"} ,
		{"word":"fourragère","word_nosc":"fourragere","lemma":"fourrager","pos":"ADJ"} ,
		{"word":"fourragères","word_nosc":"fourrageres","lemma":"fourrager","pos":"ADJ"} ,
		{"word":"fourre-tout","word_nosc":"fourre-tout","lemma":"fourre-tout","pos":"ADJ"} ,
		{"word":"fourré","word_nosc":"fourre","lemma":"fourré","pos":"ADJ"} ,
		{"word":"fourrée","word_nosc":"fourree","lemma":"fourré","pos":"ADJ"} ,
		{"word":"fourrées","word_nosc":"fourrees","lemma":"fourré","pos":"ADJ"} ,
		{"word":"fourrés","word_nosc":"fourres","lemma":"fourré","pos":"ADJ"} ,
		{"word":"fourvoyé","word_nosc":"fourvoye","lemma":"fourvoyé","pos":"ADJ"} ,
		{"word":"fourvoyées","word_nosc":"fourvoyees","lemma":"fourvoyé","pos":"ADJ"} ,
		{"word":"fourvoyés","word_nosc":"fourvoyes","lemma":"fourvoyé","pos":"ADJ"} ,
		{"word":"fous","word_nosc":"fous","lemma":"fou","pos":"ADJ"} ,
		{"word":"foutral","word_nosc":"foutral","lemma":"foutral","pos":"ADJ"} ,
		{"word":"foutrale","word_nosc":"foutrale","lemma":"foutral","pos":"ADJ"} ,
		{"word":"foutraque","word_nosc":"foutraque","lemma":"foutraque","pos":"ADJ"} ,
		{"word":"foutu","word_nosc":"foutu","lemma":"foutu","pos":"ADJ"} ,
		{"word":"foutue","word_nosc":"foutue","lemma":"foutu","pos":"ADJ"} ,
		{"word":"foutues","word_nosc":"foutues","lemma":"foutu","pos":"ADJ"} ,
		{"word":"foutus","word_nosc":"foutus","lemma":"foutu","pos":"ADJ"} ,
		{"word":"foyer","word_nosc":"foyer","lemma":"foyer","pos":"ADJ"} ,
		{"word":"foyers","word_nosc":"foyers","lemma":"foyer","pos":"ADJ"} ,
		{"word":"fracassant","word_nosc":"fracassant","lemma":"fracassant","pos":"ADJ"} ,
		{"word":"fracassante","word_nosc":"fracassante","lemma":"fracassant","pos":"ADJ"} ,
		{"word":"fracassantes","word_nosc":"fracassantes","lemma":"fracassant","pos":"ADJ"} ,
		{"word":"fracassants","word_nosc":"fracassants","lemma":"fracassant","pos":"ADJ"} ,
		{"word":"fracassé","word_nosc":"fracasse","lemma":"fracassé","pos":"ADJ"} ,
		{"word":"fracassée","word_nosc":"fracassee","lemma":"fracassé","pos":"ADJ"} ,
		{"word":"fracassées","word_nosc":"fracassees","lemma":"fracassé","pos":"ADJ"} ,
		{"word":"fracassés","word_nosc":"fracasses","lemma":"fracassé","pos":"ADJ"} ,
		{"word":"fractal","word_nosc":"fractal","lemma":"fractal","pos":"ADJ"} ,
		{"word":"fractale","word_nosc":"fractale","lemma":"fractal","pos":"ADJ"} ,
		{"word":"fractales","word_nosc":"fractales","lemma":"fractal","pos":"ADJ"} ,
		{"word":"fractionnel","word_nosc":"fractionnel","lemma":"fractionnel","pos":"ADJ"} ,
		{"word":"fractionnelles","word_nosc":"fractionnelles","lemma":"fractionnel","pos":"ADJ"} ,
		{"word":"fractionnée","word_nosc":"fractionnee","lemma":"fractionné","pos":"ADJ"} ,
		{"word":"fractionnées","word_nosc":"fractionnees","lemma":"fractionné","pos":"ADJ"} ,
		{"word":"fracturé","word_nosc":"fracture","lemma":"fracturé","pos":"ADJ"} ,
		{"word":"fracturée","word_nosc":"fracturee","lemma":"fracturé","pos":"ADJ"} ,
		{"word":"fracturées","word_nosc":"fracturees","lemma":"fracturé","pos":"ADJ"} ,
		{"word":"fracturés","word_nosc":"fractures","lemma":"fracturé","pos":"ADJ"} ,
		{"word":"fragile","word_nosc":"fragile","lemma":"fragile","pos":"ADJ"} ,
		{"word":"fragiles","word_nosc":"fragiles","lemma":"fragile","pos":"ADJ"} ,
		{"word":"fragilisé","word_nosc":"fragilise","lemma":"fragilisé","pos":"ADJ"} ,
		{"word":"fragilisés","word_nosc":"fragilises","lemma":"fragilisé","pos":"ADJ"} ,
		{"word":"fragmentaire","word_nosc":"fragmentaire","lemma":"fragmentaire","pos":"ADJ"} ,
		{"word":"fragmentaires","word_nosc":"fragmentaires","lemma":"fragmentaire","pos":"ADJ"} ,
		{"word":"fragmenté","word_nosc":"fragmente","lemma":"fragmenté","pos":"ADJ"} ,
		{"word":"fragmentée","word_nosc":"fragmentee","lemma":"fragmenté","pos":"ADJ"} ,
		{"word":"fragmentées","word_nosc":"fragmentees","lemma":"fragmenté","pos":"ADJ"} ,
		{"word":"fragmentés","word_nosc":"fragmentes","lemma":"fragmenté","pos":"ADJ"} ,
		{"word":"fragrant","word_nosc":"fragrant","lemma":"fragrant","pos":"ADJ"} ,
		{"word":"frais","word_nosc":"frais","lemma":"frais","pos":"ADJ"} ,
		{"word":"franc","word_nosc":"franc","lemma":"franc","pos":"ADJ"} ,
		{"word":"franc-comtoise","word_nosc":"franc-comtoise","lemma":"franc-comtois","pos":"ADJ"} ,
		{"word":"franche","word_nosc":"franche","lemma":"franc","pos":"ADJ"} ,
		{"word":"franches","word_nosc":"franches","lemma":"franc","pos":"ADJ"} ,
		{"word":"franchissables","word_nosc":"franchissables","lemma":"franchissable","pos":"ADJ"} ,
		{"word":"franchouillard","word_nosc":"franchouillard","lemma":"franchouillard","pos":"ADJ"} ,
		{"word":"franchouillarde","word_nosc":"franchouillarde","lemma":"franchouillard","pos":"ADJ"} ,
		{"word":"franciscain","word_nosc":"franciscain","lemma":"franciscain","pos":"ADJ"} ,
		{"word":"franciscaine","word_nosc":"franciscaine","lemma":"franciscain","pos":"ADJ"} ,
		{"word":"franciscains","word_nosc":"franciscains","lemma":"franciscain","pos":"ADJ"} ,
		{"word":"franco-africain","word_nosc":"franco-africain","lemma":"franco-africain","pos":"ADJ"} ,
		{"word":"franco-algérienne","word_nosc":"franco-algerienne","lemma":"franco-algérien","pos":"ADJ"} ,
		{"word":"franco-allemand","word_nosc":"franco-allemand","lemma":"franco-allemand","pos":"ADJ"} ,
		{"word":"franco-allemande","word_nosc":"franco-allemande","lemma":"franco-allemand","pos":"ADJ"} ,
		{"word":"franco-allemandes","word_nosc":"franco-allemandes","lemma":"franco-allemand","pos":"ADJ"} ,
		{"word":"franco-américain","word_nosc":"franco-americain","lemma":"franco-américain","pos":"ADJ"} ,
		{"word":"franco-américaine","word_nosc":"franco-americaine","lemma":"franco-américain","pos":"ADJ"} ,
		{"word":"franco-américaines","word_nosc":"franco-americaines","lemma":"franco-américain","pos":"ADJ"} ,
		{"word":"franco-américains","word_nosc":"franco-americains","lemma":"franco-américain","pos":"ADJ"} ,
		{"word":"franco-anglais","word_nosc":"franco-anglais","lemma":"franco-anglais","pos":"ADJ"} ,
		{"word":"franco-anglaise","word_nosc":"franco-anglaise","lemma":"franco-anglais","pos":"ADJ"} ,
		{"word":"franco-anglaises","word_nosc":"franco-anglaises","lemma":"franco-anglais","pos":"ADJ"} ,
		{"word":"franco-belge","word_nosc":"franco-belge","lemma":"franco-belge","pos":"ADJ"} ,
		{"word":"franco-britannique","word_nosc":"franco-britannique","lemma":"franco-britannique","pos":"ADJ"} ,
		{"word":"franco-britanniques","word_nosc":"franco-britanniques","lemma":"franco-britannique","pos":"ADJ"} ,
		{"word":"franco-chinoises","word_nosc":"franco-chinoises","lemma":"franco-chinois","pos":"ADJ"} ,
		{"word":"franco-italienne","word_nosc":"franco-italienne","lemma":"franco-italien","pos":"ADJ"} ,
		{"word":"franco-italienne","word_nosc":"franco-italienne","lemma":"franco-italienne","pos":"ADJ"} ,
		{"word":"franco-japonaise","word_nosc":"franco-japonaise","lemma":"franco-japonais","pos":"ADJ"} ,
		{"word":"franco-libanais","word_nosc":"franco-libanais","lemma":"franco-libanais","pos":"ADJ"} ,
		{"word":"franco-mongole","word_nosc":"franco-mongole","lemma":"franco-mongol","pos":"ADJ"} ,
		{"word":"franco-polonaise","word_nosc":"franco-polonaise","lemma":"franco-polonais","pos":"ADJ"} ,
		{"word":"franco-russe","word_nosc":"franco-russe","lemma":"franco-russe","pos":"ADJ"} ,
		{"word":"franco-soviétique","word_nosc":"franco-sovietique","lemma":"franco-soviétique","pos":"ADJ"} ,
		{"word":"franco-syrien","word_nosc":"franco-syrien","lemma":"franco-syrien","pos":"ADJ"} ,
		{"word":"franco-turque","word_nosc":"franco-turque","lemma":"franco-turque","pos":"ADJ"} ,
		{"word":"franco-vietnamien","word_nosc":"franco-vietnamien","lemma":"franco-vietnamien","pos":"ADJ"} ,
		{"word":"francophile","word_nosc":"francophile","lemma":"francophile","pos":"ADJ"} ,
		{"word":"francophiles","word_nosc":"francophiles","lemma":"francophile","pos":"ADJ"} ,
		{"word":"francophone","word_nosc":"francophone","lemma":"francophone","pos":"ADJ"} ,
		{"word":"francophones","word_nosc":"francophones","lemma":"francophone","pos":"ADJ"} ,
		{"word":"francs","word_nosc":"francs","lemma":"franc","pos":"ADJ"} ,
		{"word":"franque","word_nosc":"franque","lemma":"franque","pos":"ADJ"} ,
		{"word":"franques","word_nosc":"franques","lemma":"franque","pos":"ADJ"} ,
		{"word":"franquiste","word_nosc":"franquiste","lemma":"franquiste","pos":"ADJ"} ,
		{"word":"franquistes","word_nosc":"franquistes","lemma":"franquiste","pos":"ADJ"} ,
		{"word":"français","word_nosc":"francais","lemma":"français","pos":"ADJ"} ,
		{"word":"française","word_nosc":"francaise","lemma":"français","pos":"ADJ"} ,
		{"word":"françaises","word_nosc":"francaises","lemma":"français","pos":"ADJ"} ,
		{"word":"frapadingue","word_nosc":"frapadingue","lemma":"frapadingue","pos":"ADJ"} ,
		{"word":"frapadingues","word_nosc":"frapadingues","lemma":"frapadingue","pos":"ADJ"} ,
		{"word":"frappadingue","word_nosc":"frappadingue","lemma":"frappadingue","pos":"ADJ"} ,
		{"word":"frappadingues","word_nosc":"frappadingues","lemma":"frappadingue","pos":"ADJ"} ,
		{"word":"frappant","word_nosc":"frappant","lemma":"frappant","pos":"ADJ"} ,
		{"word":"frappante","word_nosc":"frappante","lemma":"frappant","pos":"ADJ"} ,
		{"word":"frappantes","word_nosc":"frappantes","lemma":"frappant","pos":"ADJ"} ,
		{"word":"frappants","word_nosc":"frappants","lemma":"frappant","pos":"ADJ"} ,
		{"word":"frappé","word_nosc":"frappe","lemma":"frappé","pos":"ADJ"} ,
		{"word":"frappée","word_nosc":"frappee","lemma":"frappé","pos":"ADJ"} ,
		{"word":"frappées","word_nosc":"frappees","lemma":"frappé","pos":"ADJ"} ,
		{"word":"frappés","word_nosc":"frappes","lemma":"frappé","pos":"ADJ"} ,
		{"word":"fraternel","word_nosc":"fraternel","lemma":"fraternel","pos":"ADJ"} ,
		{"word":"fraternelle","word_nosc":"fraternelle","lemma":"fraternel","pos":"ADJ"} ,
		{"word":"fraternelles","word_nosc":"fraternelles","lemma":"fraternel","pos":"ADJ"} ,
		{"word":"fraternels","word_nosc":"fraternels","lemma":"fraternel","pos":"ADJ"} ,
		{"word":"fratricide","word_nosc":"fratricide","lemma":"fratricide","pos":"ADJ"} ,
		{"word":"fratricides","word_nosc":"fratricides","lemma":"fratricide","pos":"ADJ"} ,
		{"word":"fraudeuse","word_nosc":"fraudeuse","lemma":"fraudeur","pos":"ADJ"} ,
		{"word":"frauduleuse","word_nosc":"frauduleuse","lemma":"frauduleux","pos":"ADJ"} ,
		{"word":"frauduleuses","word_nosc":"frauduleuses","lemma":"frauduleux","pos":"ADJ"} ,
		{"word":"frauduleux","word_nosc":"frauduleux","lemma":"frauduleux","pos":"ADJ"} ,
		{"word":"fraîche","word_nosc":"fraiche","lemma":"frais","pos":"ADJ"} ,
		{"word":"fraîches","word_nosc":"fraiches","lemma":"frais","pos":"ADJ"} ,
		{"word":"free-lance","word_nosc":"free-lance","lemma":"free-lance","pos":"ADJ"} ,
		{"word":"frelaté","word_nosc":"frelate","lemma":"frelaté","pos":"ADJ"} ,
		{"word":"frelatée","word_nosc":"frelatee","lemma":"frelaté","pos":"ADJ"} ,
		{"word":"frelatées","word_nosc":"frelatees","lemma":"frelaté","pos":"ADJ"} ,
		{"word":"frelatés","word_nosc":"frelates","lemma":"frelaté","pos":"ADJ"} ,
		{"word":"freudien","word_nosc":"freudien","lemma":"freudien","pos":"ADJ"} ,
		{"word":"freudienne","word_nosc":"freudienne","lemma":"freudien","pos":"ADJ"} ,
		{"word":"freudiennes","word_nosc":"freudiennes","lemma":"freudien","pos":"ADJ"} ,
		{"word":"freudiens","word_nosc":"freudiens","lemma":"freudien","pos":"ADJ"} ,
		{"word":"friable","word_nosc":"friable","lemma":"friable","pos":"ADJ"} ,
		{"word":"friables","word_nosc":"friables","lemma":"friable","pos":"ADJ"} ,
		{"word":"friand","word_nosc":"friand","lemma":"friand","pos":"ADJ"} ,
		{"word":"friande","word_nosc":"friande","lemma":"friand","pos":"ADJ"} ,
		{"word":"friandes","word_nosc":"friandes","lemma":"friand","pos":"ADJ"} ,
		{"word":"friands","word_nosc":"friands","lemma":"friand","pos":"ADJ"} ,
		{"word":"fricatif","word_nosc":"fricatif","lemma":"fricatif","pos":"ADJ"} ,
		{"word":"frigide","word_nosc":"frigide","lemma":"frigide","pos":"ADJ"} ,
		{"word":"frigides","word_nosc":"frigides","lemma":"frigide","pos":"ADJ"} ,
		{"word":"frigorifique","word_nosc":"frigorifique","lemma":"frigorifique","pos":"ADJ"} ,
		{"word":"frigorifiques","word_nosc":"frigorifiques","lemma":"frigorifique","pos":"ADJ"} ,
		{"word":"frigorifié","word_nosc":"frigorifie","lemma":"frigorifié","pos":"ADJ"} ,
		{"word":"frigorifiée","word_nosc":"frigorifiee","lemma":"frigorifié","pos":"ADJ"} ,
		{"word":"frigorifiées","word_nosc":"frigorifiees","lemma":"frigorifié","pos":"ADJ"} ,
		{"word":"frigorifiés","word_nosc":"frigorifies","lemma":"frigorifié","pos":"ADJ"} ,
		{"word":"frigorigène","word_nosc":"frigorigene","lemma":"frigorigène","pos":"ADJ"} ,
		{"word":"frileuse","word_nosc":"frileuse","lemma":"frileux","pos":"ADJ"} ,
		{"word":"frileuses","word_nosc":"frileuses","lemma":"frileux","pos":"ADJ"} ,
		{"word":"frileux","word_nosc":"frileux","lemma":"frileux","pos":"ADJ"} ,
		{"word":"frimeur","word_nosc":"frimeur","lemma":"frimeur","pos":"ADJ"} ,
		{"word":"frimeurs","word_nosc":"frimeurs","lemma":"frimeur","pos":"ADJ"} ,
		{"word":"frimeuse","word_nosc":"frimeuse","lemma":"frimeur","pos":"ADJ"} ,
		{"word":"fringant","word_nosc":"fringant","lemma":"fringant","pos":"ADJ"} ,
		{"word":"fringante","word_nosc":"fringante","lemma":"fringant","pos":"ADJ"} ,
		{"word":"fringantes","word_nosc":"fringantes","lemma":"fringant","pos":"ADJ"} ,
		{"word":"fringants","word_nosc":"fringants","lemma":"fringant","pos":"ADJ"} ,
		{"word":"frio","word_nosc":"frio","lemma":"frio","pos":"ADJ"} ,
		{"word":"fripon","word_nosc":"fripon","lemma":"fripon","pos":"ADJ"} ,
		{"word":"friponne","word_nosc":"friponne","lemma":"fripon","pos":"ADJ"} ,
		{"word":"fripons","word_nosc":"fripons","lemma":"fripon","pos":"ADJ"} ,
		{"word":"fripé","word_nosc":"fripe","lemma":"fripé","pos":"ADJ"} ,
		{"word":"fripée","word_nosc":"fripee","lemma":"fripé","pos":"ADJ"} ,
		{"word":"fripées","word_nosc":"fripees","lemma":"fripé","pos":"ADJ"} ,
		{"word":"fripés","word_nosc":"fripes","lemma":"fripé","pos":"ADJ"} ,
		{"word":"friqué","word_nosc":"frique","lemma":"friqué","pos":"ADJ"} ,
		{"word":"friquée","word_nosc":"friquee","lemma":"friqué","pos":"ADJ"} ,
		{"word":"friquées","word_nosc":"friquees","lemma":"friqué","pos":"ADJ"} ,
		{"word":"friqués","word_nosc":"friques","lemma":"friqué","pos":"ADJ"} ,
		{"word":"frisant","word_nosc":"frisant","lemma":"frisant","pos":"ADJ"} ,
		{"word":"frisante","word_nosc":"frisante","lemma":"frisant","pos":"ADJ"} ,
		{"word":"frisants","word_nosc":"frisants","lemma":"frisant","pos":"ADJ"} ,
		{"word":"frison","word_nosc":"frison","lemma":"frison","pos":"ADJ"} ,
		{"word":"frisonne","word_nosc":"frisonne","lemma":"frison","pos":"ADJ"} ,
		{"word":"frisonnes","word_nosc":"frisonnes","lemma":"frison","pos":"ADJ"} ,
		{"word":"frisottant","word_nosc":"frisottant","lemma":"frisottant","pos":"ADJ"} ,
		{"word":"frisottants","word_nosc":"frisottants","lemma":"frisottant","pos":"ADJ"} ,
		{"word":"frisotté","word_nosc":"frisotte","lemma":"frisotté","pos":"ADJ"} ,
		{"word":"frisottée","word_nosc":"frisottee","lemma":"frisotté","pos":"ADJ"} ,
		{"word":"frisquet","word_nosc":"frisquet","lemma":"frisquet","pos":"ADJ"} ,
		{"word":"frisquette","word_nosc":"frisquette","lemma":"frisquet","pos":"ADJ"} ,
		{"word":"frissonnant","word_nosc":"frissonnant","lemma":"frissonnant","pos":"ADJ"} ,
		{"word":"frissonnante","word_nosc":"frissonnante","lemma":"frissonnant","pos":"ADJ"} ,
		{"word":"frissonnantes","word_nosc":"frissonnantes","lemma":"frissonnant","pos":"ADJ"} ,
		{"word":"frissonnants","word_nosc":"frissonnants","lemma":"frissonnant","pos":"ADJ"} ,
		{"word":"frisé","word_nosc":"frise","lemma":"frisé","pos":"ADJ"} ,
		{"word":"frisée","word_nosc":"frisee","lemma":"frisé","pos":"ADJ"} ,
		{"word":"frisées","word_nosc":"frisees","lemma":"frisé","pos":"ADJ"} ,
		{"word":"frisés","word_nosc":"frises","lemma":"frisé","pos":"ADJ"} ,
		{"word":"frite","word_nosc":"frite","lemma":"frite","pos":"ADJ"} ,
		{"word":"frites","word_nosc":"frites","lemma":"frite","pos":"ADJ"} ,
		{"word":"frivole","word_nosc":"frivole","lemma":"frivole","pos":"ADJ"} ,
		{"word":"frivoles","word_nosc":"frivoles","lemma":"frivole","pos":"ADJ"} ,
		{"word":"froid","word_nosc":"froid","lemma":"froid","pos":"ADJ"} ,
		{"word":"froidasse","word_nosc":"froidasse","lemma":"froidasse","pos":"ADJ"} ,
		{"word":"froide","word_nosc":"froide","lemma":"froid","pos":"ADJ"} ,
		{"word":"froides","word_nosc":"froides","lemma":"froid","pos":"ADJ"} ,
		{"word":"froids","word_nosc":"froids","lemma":"froid","pos":"ADJ"} ,
		{"word":"froissé","word_nosc":"froisse","lemma":"froissé","pos":"ADJ"} ,
		{"word":"froissée","word_nosc":"froissee","lemma":"froissé","pos":"ADJ"} ,
		{"word":"froissées","word_nosc":"froissees","lemma":"froissé","pos":"ADJ"} ,
		{"word":"froissés","word_nosc":"froisses","lemma":"froissé","pos":"ADJ"} ,
		{"word":"fromager","word_nosc":"fromager","lemma":"fromager","pos":"ADJ"} ,
		{"word":"fromagers","word_nosc":"fromagers","lemma":"fromager","pos":"ADJ"} ,
		{"word":"fromagère","word_nosc":"fromagere","lemma":"fromager","pos":"ADJ"} ,
		{"word":"froncé","word_nosc":"fronce","lemma":"froncé","pos":"ADJ"} ,
		{"word":"froncée","word_nosc":"froncee","lemma":"froncé","pos":"ADJ"} ,
		{"word":"froncées","word_nosc":"froncees","lemma":"froncé","pos":"ADJ"} ,
		{"word":"froncés","word_nosc":"fronces","lemma":"froncé","pos":"ADJ"} ,
		{"word":"frondeur","word_nosc":"frondeur","lemma":"frondeur","pos":"ADJ"} ,
		{"word":"frondeurs","word_nosc":"frondeurs","lemma":"frondeur","pos":"ADJ"} ,
		{"word":"frondeuse","word_nosc":"frondeuse","lemma":"frondeur","pos":"ADJ"} ,
		{"word":"frontal","word_nosc":"frontal","lemma":"frontal","pos":"ADJ"} ,
		{"word":"frontale","word_nosc":"frontale","lemma":"frontal","pos":"ADJ"} ,
		{"word":"frontalier","word_nosc":"frontalier","lemma":"frontalier","pos":"ADJ"} ,
		{"word":"frontaliers","word_nosc":"frontaliers","lemma":"frontalier","pos":"ADJ"} ,
		{"word":"frontalière","word_nosc":"frontaliere","lemma":"frontalier","pos":"ADJ"} ,
		{"word":"frontalières","word_nosc":"frontalieres","lemma":"frontalier","pos":"ADJ"} ,
		{"word":"frontaux","word_nosc":"frontaux","lemma":"frontal","pos":"ADJ"} ,
		{"word":"frottant","word_nosc":"frottant","lemma":"frottant","pos":"ADJ"} ,
		{"word":"frotteur","word_nosc":"frotteur","lemma":"frotteur","pos":"ADJ"} ,
		{"word":"frotteurs","word_nosc":"frotteurs","lemma":"frotteur","pos":"ADJ"} ,
		{"word":"frotté","word_nosc":"frotte","lemma":"frotté","pos":"ADJ"} ,
		{"word":"frottés","word_nosc":"frottes","lemma":"frotté","pos":"ADJ"} ,
		{"word":"froufroutant","word_nosc":"froufroutant","lemma":"froufroutant","pos":"ADJ"} ,
		{"word":"froufroutante","word_nosc":"froufroutante","lemma":"froufroutant","pos":"ADJ"} ,
		{"word":"froufroutantes","word_nosc":"froufroutantes","lemma":"froufroutant","pos":"ADJ"} ,
		{"word":"froufroutants","word_nosc":"froufroutants","lemma":"froufroutant","pos":"ADJ"} ,
		{"word":"froussard","word_nosc":"froussard","lemma":"froussard","pos":"ADJ"} ,
		{"word":"froussarde","word_nosc":"froussarde","lemma":"froussard","pos":"ADJ"} ,
		{"word":"froussards","word_nosc":"froussards","lemma":"froussard","pos":"ADJ"} ,
		{"word":"fructueuse","word_nosc":"fructueuse","lemma":"fructueux","pos":"ADJ"} ,
		{"word":"fructueuses","word_nosc":"fructueuses","lemma":"fructueux","pos":"ADJ"} ,
		{"word":"fructueux","word_nosc":"fructueux","lemma":"fructueux","pos":"ADJ"} ,
		{"word":"frugal","word_nosc":"frugal","lemma":"frugal","pos":"ADJ"} ,
		{"word":"frugale","word_nosc":"frugale","lemma":"frugal","pos":"ADJ"} ,
		{"word":"frugales","word_nosc":"frugales","lemma":"frugal","pos":"ADJ"} ,
		{"word":"frugaux","word_nosc":"frugaux","lemma":"frugal","pos":"ADJ"} ,
		{"word":"frugivore","word_nosc":"frugivore","lemma":"frugivore","pos":"ADJ"} ,
		{"word":"fruiteux","word_nosc":"fruiteux","lemma":"fruiteux","pos":"ADJ"} ,
		{"word":"fruitier","word_nosc":"fruitier","lemma":"fruitier","pos":"ADJ"} ,
		{"word":"fruitiers","word_nosc":"fruitiers","lemma":"fruitier","pos":"ADJ"} ,
		{"word":"fruitière","word_nosc":"fruitiere","lemma":"fruitier","pos":"ADJ"} ,
		{"word":"fruitières","word_nosc":"fruitieres","lemma":"fruitier","pos":"ADJ"} ,
		{"word":"fruité","word_nosc":"fruite","lemma":"fruité","pos":"ADJ"} ,
		{"word":"fruitée","word_nosc":"fruitee","lemma":"fruité","pos":"ADJ"} ,
		{"word":"fruitées","word_nosc":"fruitees","lemma":"fruité","pos":"ADJ"} ,
		{"word":"fruités","word_nosc":"fruites","lemma":"fruité","pos":"ADJ"} ,
		{"word":"fruste","word_nosc":"fruste","lemma":"fruste","pos":"ADJ"} ,
		{"word":"frustes","word_nosc":"frustes","lemma":"fruste","pos":"ADJ"} ,
		{"word":"frustrant","word_nosc":"frustrant","lemma":"frustrant","pos":"ADJ"} ,
		{"word":"frustrante","word_nosc":"frustrante","lemma":"frustrant","pos":"ADJ"} ,
		{"word":"frustrantes","word_nosc":"frustrantes","lemma":"frustrant","pos":"ADJ"} ,
		{"word":"frustré","word_nosc":"frustre","lemma":"frustré","pos":"ADJ"} ,
		{"word":"frustrée","word_nosc":"frustree","lemma":"frustré","pos":"ADJ"} ,
		{"word":"frustrées","word_nosc":"frustrees","lemma":"frustré","pos":"ADJ"} ,
		{"word":"frustrés","word_nosc":"frustres","lemma":"frustré","pos":"ADJ"} ,
		{"word":"frémissant","word_nosc":"fremissant","lemma":"frémissant","pos":"ADJ"} ,
		{"word":"frémissante","word_nosc":"fremissante","lemma":"frémissant","pos":"ADJ"} ,
		{"word":"frémissantes","word_nosc":"fremissantes","lemma":"frémissant","pos":"ADJ"} ,
		{"word":"frémissants","word_nosc":"fremissants","lemma":"frémissant","pos":"ADJ"} ,
		{"word":"frénétique","word_nosc":"frenetique","lemma":"frénétique","pos":"ADJ"} ,
		{"word":"frénétiques","word_nosc":"frenetiques","lemma":"frénétique","pos":"ADJ"} ,
		{"word":"fréquent","word_nosc":"frequent","lemma":"fréquent","pos":"ADJ"} ,
		{"word":"fréquentable","word_nosc":"frequentable","lemma":"fréquentable","pos":"ADJ"} ,
		{"word":"fréquentables","word_nosc":"frequentables","lemma":"fréquentable","pos":"ADJ"} ,
		{"word":"fréquente","word_nosc":"frequente","lemma":"fréquent","pos":"ADJ"} ,
		{"word":"fréquentes","word_nosc":"frequentes","lemma":"fréquent","pos":"ADJ"} ,
		{"word":"fréquents","word_nosc":"frequents","lemma":"fréquent","pos":"ADJ"} ,
		{"word":"fréquenté","word_nosc":"frequente","lemma":"fréquenté","pos":"ADJ"} ,
		{"word":"fréquentée","word_nosc":"frequentee","lemma":"fréquenté","pos":"ADJ"} ,
		{"word":"fréquentées","word_nosc":"frequentees","lemma":"fréquenté","pos":"ADJ"} ,
		{"word":"fréquentés","word_nosc":"frequentes","lemma":"fréquenté","pos":"ADJ"} ,
		{"word":"frétillant","word_nosc":"fretillant","lemma":"frétillant","pos":"ADJ"} ,
		{"word":"frétillante","word_nosc":"fretillante","lemma":"frétillant","pos":"ADJ"} ,
		{"word":"frétillantes","word_nosc":"fretillantes","lemma":"frétillant","pos":"ADJ"} ,
		{"word":"frétillants","word_nosc":"fretillants","lemma":"frétillant","pos":"ADJ"} ,
		{"word":"frétillard","word_nosc":"fretillard","lemma":"frétillard","pos":"ADJ"} ,
		{"word":"frétillarde","word_nosc":"fretillarde","lemma":"frétillard","pos":"ADJ"} ,
		{"word":"frétillon","word_nosc":"fretillon","lemma":"frétillon","pos":"ADJ"} ,
		{"word":"frêle","word_nosc":"frele","lemma":"frêle","pos":"ADJ"} ,
		{"word":"frêles","word_nosc":"freles","lemma":"frêle","pos":"ADJ"} ,
		{"word":"frôleur","word_nosc":"froleur","lemma":"frôleur","pos":"ADJ"} ,
		{"word":"frôleurs","word_nosc":"froleurs","lemma":"frôleur","pos":"ADJ"} ,
		{"word":"frôleuse","word_nosc":"froleuse","lemma":"frôleur","pos":"ADJ"} ,
		{"word":"frôleuses","word_nosc":"froleuses","lemma":"frôleur","pos":"ADJ"} ,
		{"word":"fuchsia","word_nosc":"fuchsia","lemma":"fuchsia","pos":"ADJ"} ,
		{"word":"fugace","word_nosc":"fugace","lemma":"fugace","pos":"ADJ"} ,
		{"word":"fugaces","word_nosc":"fugaces","lemma":"fugace","pos":"ADJ"} ,
		{"word":"fugitif","word_nosc":"fugitif","lemma":"fugitif","pos":"ADJ"} ,
		{"word":"fugitifs","word_nosc":"fugitifs","lemma":"fugitif","pos":"ADJ"} ,
		{"word":"fugitive","word_nosc":"fugitive","lemma":"fugitif","pos":"ADJ"} ,
		{"word":"fugitives","word_nosc":"fugitives","lemma":"fugitif","pos":"ADJ"} ,
		{"word":"fugueur","word_nosc":"fugueur","lemma":"fugueur","pos":"ADJ"} ,
		{"word":"fugueurs","word_nosc":"fugueurs","lemma":"fugueur","pos":"ADJ"} ,
		{"word":"fugueuse","word_nosc":"fugueuse","lemma":"fugueur","pos":"ADJ"} ,
		{"word":"fugueuses","word_nosc":"fugueuses","lemma":"fugueur","pos":"ADJ"} ,
		{"word":"fuguées","word_nosc":"fuguees","lemma":"fugué","pos":"ADJ"} ,
		{"word":"fui","word_nosc":"fui","lemma":"fui","pos":"ADJ"} ,
		{"word":"fuis","word_nosc":"fuis","lemma":"fui","pos":"ADJ"} ,
		{"word":"fulgurant","word_nosc":"fulgurant","lemma":"fulgurant","pos":"ADJ"} ,
		{"word":"fulgurante","word_nosc":"fulgurante","lemma":"fulgurant","pos":"ADJ"} ,
		{"word":"fulgurantes","word_nosc":"fulgurantes","lemma":"fulgurant","pos":"ADJ"} ,
		{"word":"fulgurants","word_nosc":"fulgurants","lemma":"fulgurant","pos":"ADJ"} ,
		{"word":"fuligineuse","word_nosc":"fuligineuse","lemma":"fuligineux","pos":"ADJ"} ,
		{"word":"fuligineuses","word_nosc":"fuligineuses","lemma":"fuligineux","pos":"ADJ"} ,
		{"word":"fuligineux","word_nosc":"fuligineux","lemma":"fuligineux","pos":"ADJ"} ,
		{"word":"fulminant","word_nosc":"fulminant","lemma":"fulminant","pos":"ADJ"} ,
		{"word":"fulminante","word_nosc":"fulminante","lemma":"fulminant","pos":"ADJ"} ,
		{"word":"fulminantes","word_nosc":"fulminantes","lemma":"fulminant","pos":"ADJ"} ,
		{"word":"fulminants","word_nosc":"fulminants","lemma":"fulminant","pos":"ADJ"} ,
		{"word":"fumant","word_nosc":"fumant","lemma":"fumant","pos":"ADJ"} ,
		{"word":"fumante","word_nosc":"fumante","lemma":"fumant","pos":"ADJ"} ,
		{"word":"fumantes","word_nosc":"fumantes","lemma":"fumant","pos":"ADJ"} ,
		{"word":"fumants","word_nosc":"fumants","lemma":"fumant","pos":"ADJ"} ,
		{"word":"fumasse","word_nosc":"fumasse","lemma":"fumasse","pos":"ADJ"} ,
		{"word":"fumeuse","word_nosc":"fumeuse","lemma":"fumeur","pos":"ADJ"} ,
		{"word":"fumeuses","word_nosc":"fumeuses","lemma":"fumeur","pos":"ADJ"} ,
		{"word":"fumeux","word_nosc":"fumeux","lemma":"fumeux","pos":"ADJ"} ,
		{"word":"fumigène","word_nosc":"fumigene","lemma":"fumigène","pos":"ADJ"} ,
		{"word":"fumigènes","word_nosc":"fumigenes","lemma":"fumigène","pos":"ADJ"} ,
		{"word":"fumiste","word_nosc":"fumiste","lemma":"fumiste","pos":"ADJ"} ,
		{"word":"fumé","word_nosc":"fume","lemma":"fumé","pos":"ADJ"} ,
		{"word":"fumée","word_nosc":"fumee","lemma":"fumé","pos":"ADJ"} ,
		{"word":"fumées","word_nosc":"fumees","lemma":"fumé","pos":"ADJ"} ,
		{"word":"fumés","word_nosc":"fumes","lemma":"fumé","pos":"ADJ"} ,
		{"word":"funambulesque","word_nosc":"funambulesque","lemma":"funambulesque","pos":"ADJ"} ,
		{"word":"funeste","word_nosc":"funeste","lemma":"funeste","pos":"ADJ"} ,
		{"word":"funestes","word_nosc":"funestes","lemma":"funeste","pos":"ADJ"} ,
		{"word":"fung","word_nosc":"fung","lemma":"fung","pos":"ADJ"} ,
		{"word":"funk","word_nosc":"funk","lemma":"funk","pos":"ADJ"} ,
		{"word":"funky","word_nosc":"funky","lemma":"funky","pos":"ADJ"} ,
		{"word":"funèbre","word_nosc":"funebre","lemma":"funèbre","pos":"ADJ"} ,
		{"word":"funèbres","word_nosc":"funebres","lemma":"funèbre","pos":"ADJ"} ,
		{"word":"funéraire","word_nosc":"funeraire","lemma":"funéraire","pos":"ADJ"} ,
		{"word":"funéraires","word_nosc":"funeraires","lemma":"funéraire","pos":"ADJ"} ,
		{"word":"furax","word_nosc":"furax","lemma":"furax","pos":"ADJ"} ,
		{"word":"fureteur","word_nosc":"fureteur","lemma":"fureteur","pos":"ADJ"} ,
		{"word":"fureteurs","word_nosc":"fureteurs","lemma":"fureteur","pos":"ADJ"} ,
		{"word":"fureteuse","word_nosc":"fureteuse","lemma":"fureteur","pos":"ADJ"} ,
		{"word":"fureteuses","word_nosc":"fureteuses","lemma":"fureteur","pos":"ADJ"} ,
		{"word":"furibard","word_nosc":"furibard","lemma":"furibard","pos":"ADJ"} ,
		{"word":"furibarde","word_nosc":"furibarde","lemma":"furibard","pos":"ADJ"} ,
		{"word":"furibardes","word_nosc":"furibardes","lemma":"furibard","pos":"ADJ"} ,
		{"word":"furibond","word_nosc":"furibond","lemma":"furibond","pos":"ADJ"} ,
		{"word":"furibonde","word_nosc":"furibonde","lemma":"furibond","pos":"ADJ"} ,
		{"word":"furibondes","word_nosc":"furibondes","lemma":"furibond","pos":"ADJ"} ,
		{"word":"furibonds","word_nosc":"furibonds","lemma":"furibond","pos":"ADJ"} ,
		{"word":"furieuse","word_nosc":"furieuse","lemma":"furieux","pos":"ADJ"} ,
		{"word":"furieuses","word_nosc":"furieuses","lemma":"furieux","pos":"ADJ"} ,
		{"word":"furieux","word_nosc":"furieux","lemma":"furieux","pos":"ADJ"} ,
		{"word":"furioso","word_nosc":"furioso","lemma":"furioso","pos":"ADJ"} ,
		{"word":"furonculeux","word_nosc":"furonculeux","lemma":"furonculeux","pos":"ADJ"} ,
		{"word":"furtif","word_nosc":"furtif","lemma":"furtif","pos":"ADJ"} ,
		{"word":"furtifs","word_nosc":"furtifs","lemma":"furtif","pos":"ADJ"} ,
		{"word":"furtive","word_nosc":"furtive","lemma":"furtif","pos":"ADJ"} ,
		{"word":"furtives","word_nosc":"furtives","lemma":"furtif","pos":"ADJ"} ,
		{"word":"fusant","word_nosc":"fusant","lemma":"fusant","pos":"ADJ"} ,
		{"word":"fusante","word_nosc":"fusante","lemma":"fusant","pos":"ADJ"} ,
		{"word":"fusants","word_nosc":"fusants","lemma":"fusant","pos":"ADJ"} ,
		{"word":"fuselé","word_nosc":"fusele","lemma":"fuselé","pos":"ADJ"} ,
		{"word":"fuselée","word_nosc":"fuselee","lemma":"fuselé","pos":"ADJ"} ,
		{"word":"fuselées","word_nosc":"fuselees","lemma":"fuselé","pos":"ADJ"} ,
		{"word":"fuselés","word_nosc":"fuseles","lemma":"fuselé","pos":"ADJ"} ,
		{"word":"fusiforme","word_nosc":"fusiforme","lemma":"fusiforme","pos":"ADJ"} ,
		{"word":"fusillé","word_nosc":"fusille","lemma":"fusillé","pos":"ADJ"} ,
		{"word":"fusillée","word_nosc":"fusillee","lemma":"fusillé","pos":"ADJ"} ,
		{"word":"fusillées","word_nosc":"fusillees","lemma":"fusillé","pos":"ADJ"} ,
		{"word":"fusillés","word_nosc":"fusilles","lemma":"fusillé","pos":"ADJ"} ,
		{"word":"fusionnel","word_nosc":"fusionnel","lemma":"fusionnel","pos":"ADJ"} ,
		{"word":"fusionnelle","word_nosc":"fusionnelle","lemma":"fusionnel","pos":"ADJ"} ,
		{"word":"futile","word_nosc":"futile","lemma":"futile","pos":"ADJ"} ,
		{"word":"futiles","word_nosc":"futiles","lemma":"futile","pos":"ADJ"} ,
		{"word":"futur","word_nosc":"futur","lemma":"futur","pos":"ADJ"} ,
		{"word":"future","word_nosc":"future","lemma":"futur","pos":"ADJ"} ,
		{"word":"futures","word_nosc":"futures","lemma":"futur","pos":"ADJ"} ,
		{"word":"futuriste","word_nosc":"futuriste","lemma":"futuriste","pos":"ADJ"} ,
		{"word":"futuristes","word_nosc":"futuristes","lemma":"futuriste","pos":"ADJ"} ,
		{"word":"futurs","word_nosc":"futurs","lemma":"futur","pos":"ADJ"} ,
		{"word":"futé","word_nosc":"fute","lemma":"futé","pos":"ADJ"} ,
		{"word":"futée","word_nosc":"futee","lemma":"futé","pos":"ADJ"} ,
		{"word":"futées","word_nosc":"futees","lemma":"futé","pos":"ADJ"} ,
		{"word":"futés","word_nosc":"futes","lemma":"futé","pos":"ADJ"} ,
		{"word":"fuyant","word_nosc":"fuyant","lemma":"fuyant","pos":"ADJ"} ,
		{"word":"fuyante","word_nosc":"fuyante","lemma":"fuyant","pos":"ADJ"} ,
		{"word":"fuyantes","word_nosc":"fuyantes","lemma":"fuyant","pos":"ADJ"} ,
		{"word":"fuyants","word_nosc":"fuyants","lemma":"fuyant","pos":"ADJ"} ,
		{"word":"fuyard","word_nosc":"fuyard","lemma":"fuyard","pos":"ADJ"} ,
		{"word":"fuyarde","word_nosc":"fuyarde","lemma":"fuyard","pos":"ADJ"} ,
		{"word":"fuyards","word_nosc":"fuyards","lemma":"fuyard","pos":"ADJ"} ,
		{"word":"fâcheuse","word_nosc":"facheuse","lemma":"fâcheux","pos":"ADJ"} ,
		{"word":"fâcheuses","word_nosc":"facheuses","lemma":"fâcheux","pos":"ADJ"} ,
		{"word":"fâcheux","word_nosc":"facheux","lemma":"fâcheux","pos":"ADJ"} ,
		{"word":"fâché","word_nosc":"fache","lemma":"fâché","pos":"ADJ"} ,
		{"word":"fâchée","word_nosc":"fachee","lemma":"fâché","pos":"ADJ"} ,
		{"word":"fâchées","word_nosc":"fachees","lemma":"fâché","pos":"ADJ"} ,
		{"word":"fâchés","word_nosc":"faches","lemma":"fâché","pos":"ADJ"} ,
		{"word":"féal","word_nosc":"feal","lemma":"féal","pos":"ADJ"} ,
		{"word":"féaux","word_nosc":"feaux","lemma":"féal","pos":"ADJ"} ,
		{"word":"fébrile","word_nosc":"febrile","lemma":"fébrile","pos":"ADJ"} ,
		{"word":"fébriles","word_nosc":"febriles","lemma":"fébrile","pos":"ADJ"} ,
		{"word":"fécal","word_nosc":"fecal","lemma":"fécal","pos":"ADJ"} ,
		{"word":"fécale","word_nosc":"fecale","lemma":"fécal","pos":"ADJ"} ,
		{"word":"fécales","word_nosc":"fecales","lemma":"fécal","pos":"ADJ"} ,
		{"word":"fécaux","word_nosc":"fecaux","lemma":"fécal","pos":"ADJ"} ,
		{"word":"fécond","word_nosc":"fecond","lemma":"fécond","pos":"ADJ"} ,
		{"word":"fécondant","word_nosc":"fecondant","lemma":"fécondant","pos":"ADJ"} ,
		{"word":"fécondante","word_nosc":"fecondante","lemma":"fécondant","pos":"ADJ"} ,
		{"word":"féconde","word_nosc":"feconde","lemma":"fécond","pos":"ADJ"} ,
		{"word":"fécondes","word_nosc":"fecondes","lemma":"fécond","pos":"ADJ"} ,
		{"word":"féconds","word_nosc":"feconds","lemma":"fécond","pos":"ADJ"} ,
		{"word":"féculents","word_nosc":"feculents","lemma":"féculent","pos":"ADJ"} ,
		{"word":"fédéral","word_nosc":"federal","lemma":"fédéral","pos":"ADJ"} ,
		{"word":"fédérale","word_nosc":"federale","lemma":"fédéral","pos":"ADJ"} ,
		{"word":"fédérales","word_nosc":"federales","lemma":"fédéral","pos":"ADJ"} ,
		{"word":"fédéraliste","word_nosc":"federaliste","lemma":"fédéraliste","pos":"ADJ"} ,
		{"word":"fédérateur","word_nosc":"federateur","lemma":"fédérateur","pos":"ADJ"} ,
		{"word":"fédérative","word_nosc":"federative","lemma":"fédératif","pos":"ADJ"} ,
		{"word":"fédéraux","word_nosc":"federaux","lemma":"fédéral","pos":"ADJ"} ,
		{"word":"fédéré","word_nosc":"federe","lemma":"fédéré","pos":"ADJ"} ,
		{"word":"fédérée","word_nosc":"federee","lemma":"fédéré","pos":"ADJ"} ,
		{"word":"fédérées","word_nosc":"federees","lemma":"fédéré","pos":"ADJ"} ,
		{"word":"fédérés","word_nosc":"federes","lemma":"fédéré","pos":"ADJ"} ,
		{"word":"féerique","word_nosc":"feerique","lemma":"féerique","pos":"ADJ"} ,
		{"word":"féeriques","word_nosc":"feeriques","lemma":"féerique","pos":"ADJ"} ,
		{"word":"félin","word_nosc":"felin","lemma":"félin","pos":"ADJ"} ,
		{"word":"féline","word_nosc":"feline","lemma":"félin","pos":"ADJ"} ,
		{"word":"félines","word_nosc":"felines","lemma":"félin","pos":"ADJ"} ,
		{"word":"félins","word_nosc":"felins","lemma":"félin","pos":"ADJ"} ,
		{"word":"félon","word_nosc":"felon","lemma":"félon","pos":"ADJ"} ,
		{"word":"félonne","word_nosc":"felonne","lemma":"félon","pos":"ADJ"} ,
		{"word":"félons","word_nosc":"felons","lemma":"félon","pos":"ADJ"} ,
		{"word":"féminin","word_nosc":"feminin","lemma":"féminin","pos":"ADJ"} ,
		{"word":"féminine","word_nosc":"feminine","lemma":"féminin","pos":"ADJ"} ,
		{"word":"féminines","word_nosc":"feminines","lemma":"féminin","pos":"ADJ"} ,
		{"word":"féminins","word_nosc":"feminins","lemma":"féminin","pos":"ADJ"} ,
		{"word":"féministe","word_nosc":"feministe","lemma":"féministe","pos":"ADJ"} ,
		{"word":"féministes","word_nosc":"feministes","lemma":"féministe","pos":"ADJ"} ,
		{"word":"fémoral","word_nosc":"femoral","lemma":"fémoral","pos":"ADJ"} ,
		{"word":"fémorale","word_nosc":"femorale","lemma":"fémoral","pos":"ADJ"} ,
		{"word":"fémorales","word_nosc":"femorales","lemma":"fémoral","pos":"ADJ"} ,
		{"word":"fémoraux","word_nosc":"femoraux","lemma":"fémoral","pos":"ADJ"} ,
		{"word":"fénelonienne","word_nosc":"fenelonienne","lemma":"fénelonien","pos":"ADJ"} ,
		{"word":"féodal","word_nosc":"feodal","lemma":"féodal","pos":"ADJ"} ,
		{"word":"féodale","word_nosc":"feodale","lemma":"féodal","pos":"ADJ"} ,
		{"word":"féodales","word_nosc":"feodales","lemma":"féodal","pos":"ADJ"} ,
		{"word":"féodaux","word_nosc":"feodaux","lemma":"féodal","pos":"ADJ"} ,
		{"word":"férié","word_nosc":"ferie","lemma":"férié","pos":"ADJ"} ,
		{"word":"fériés","word_nosc":"feries","lemma":"férié","pos":"ADJ"} ,
		{"word":"féroce","word_nosc":"feroce","lemma":"féroce","pos":"ADJ"} ,
		{"word":"féroces","word_nosc":"feroces","lemma":"féroce","pos":"ADJ"} ,
		{"word":"féru","word_nosc":"feru","lemma":"féru","pos":"ADJ"} ,
		{"word":"férue","word_nosc":"ferue","lemma":"féru","pos":"ADJ"} ,
		{"word":"férues","word_nosc":"ferues","lemma":"féru","pos":"ADJ"} ,
		{"word":"férus","word_nosc":"ferus","lemma":"féru","pos":"ADJ"} ,
		{"word":"fétichiste","word_nosc":"fetichiste","lemma":"fétichiste","pos":"ADJ"} ,
		{"word":"fétichistes","word_nosc":"fetichistes","lemma":"fétichiste","pos":"ADJ"} ,
		{"word":"fétide","word_nosc":"fetide","lemma":"fétide","pos":"ADJ"} ,
		{"word":"fétides","word_nosc":"fetides","lemma":"fétide","pos":"ADJ"} ,
		{"word":"fêlé","word_nosc":"fele","lemma":"fêlé","pos":"ADJ"} ,
		{"word":"fêlée","word_nosc":"felee","lemma":"fêlé","pos":"ADJ"} ,
		{"word":"fêlées","word_nosc":"felees","lemma":"fêlé","pos":"ADJ"} ,
		{"word":"fêlés","word_nosc":"feles","lemma":"fêlé","pos":"ADJ"} ,
		{"word":"gabonais","word_nosc":"gabonais","lemma":"gabonais","pos":"ADJ"} ,
		{"word":"gabonaise","word_nosc":"gabonaise","lemma":"gabonais","pos":"ADJ"} ,
		{"word":"gaffeur","word_nosc":"gaffeur","lemma":"gaffeur","pos":"ADJ"} ,
		{"word":"gaffeurs","word_nosc":"gaffeurs","lemma":"gaffeur","pos":"ADJ"} ,
		{"word":"gaffeuses","word_nosc":"gaffeuses","lemma":"gaffeur","pos":"ADJ"} ,
		{"word":"gaga","word_nosc":"gaga","lemma":"gaga","pos":"ADJ"} ,
		{"word":"gagas","word_nosc":"gagas","lemma":"gaga","pos":"ADJ"} ,
		{"word":"gagnant","word_nosc":"gagnant","lemma":"gagnant","pos":"ADJ"} ,
		{"word":"gagnante","word_nosc":"gagnante","lemma":"gagnant","pos":"ADJ"} ,
		{"word":"gagnantes","word_nosc":"gagnantes","lemma":"gagnant","pos":"ADJ"} ,
		{"word":"gagnants","word_nosc":"gagnants","lemma":"gagnant","pos":"ADJ"} ,
		{"word":"gagneurs","word_nosc":"gagneurs","lemma":"gagneur","pos":"ADJ"} ,
		{"word":"gagneuse","word_nosc":"gagneuse","lemma":"gagneur","pos":"ADJ"} ,
		{"word":"gagné","word_nosc":"gagne","lemma":"gagné","pos":"ADJ"} ,
		{"word":"gagnée","word_nosc":"gagnee","lemma":"gagné","pos":"ADJ"} ,
		{"word":"gagnées","word_nosc":"gagnees","lemma":"gagné","pos":"ADJ"} ,
		{"word":"gagnés","word_nosc":"gagnes","lemma":"gagné","pos":"ADJ"} ,
		{"word":"gagé","word_nosc":"gage","lemma":"gagé","pos":"ADJ"} ,
		{"word":"gai","word_nosc":"gai","lemma":"gai","pos":"ADJ"} ,
		{"word":"gaie","word_nosc":"gaie","lemma":"gai","pos":"ADJ"} ,
		{"word":"gaies","word_nosc":"gaies","lemma":"gai","pos":"ADJ"} ,
		{"word":"gaillard","word_nosc":"gaillard","lemma":"gaillard","pos":"ADJ"} ,
		{"word":"gaillarde","word_nosc":"gaillarde","lemma":"gaillard","pos":"ADJ"} ,
		{"word":"gaillardes","word_nosc":"gaillardes","lemma":"gaillard","pos":"ADJ"} ,
		{"word":"gaillards","word_nosc":"gaillards","lemma":"gaillard","pos":"ADJ"} ,
		{"word":"gainées","word_nosc":"gainees","lemma":"gainé","pos":"ADJ"} ,
		{"word":"gainés","word_nosc":"gaines","lemma":"gainé","pos":"ADJ"} ,
		{"word":"gais","word_nosc":"gais","lemma":"gai","pos":"ADJ"} ,
		{"word":"galactique","word_nosc":"galactique","lemma":"galactique","pos":"ADJ"} ,
		{"word":"galactiques","word_nosc":"galactiques","lemma":"galactique","pos":"ADJ"} ,
		{"word":"galactophore","word_nosc":"galactophore","lemma":"galactophore","pos":"ADJ"} ,
		{"word":"galant","word_nosc":"galant","lemma":"galant","pos":"ADJ"} ,
		{"word":"galante","word_nosc":"galante","lemma":"galant","pos":"ADJ"} ,
		{"word":"galantes","word_nosc":"galantes","lemma":"galant","pos":"ADJ"} ,
		{"word":"galants","word_nosc":"galants","lemma":"galant","pos":"ADJ"} ,
		{"word":"galates","word_nosc":"galates","lemma":"galate","pos":"ADJ"} ,
		{"word":"galbé","word_nosc":"galbe","lemma":"galbé","pos":"ADJ"} ,
		{"word":"galbée","word_nosc":"galbee","lemma":"galbé","pos":"ADJ"} ,
		{"word":"galbées","word_nosc":"galbees","lemma":"galbé","pos":"ADJ"} ,
		{"word":"galbés","word_nosc":"galbes","lemma":"galbé","pos":"ADJ"} ,
		{"word":"galetteux","word_nosc":"galetteux","lemma":"galetteux","pos":"ADJ"} ,
		{"word":"galeuse","word_nosc":"galeuse","lemma":"galeux","pos":"ADJ"} ,
		{"word":"galeuses","word_nosc":"galeuses","lemma":"galeux","pos":"ADJ"} ,
		{"word":"galeux","word_nosc":"galeux","lemma":"galeux","pos":"ADJ"} ,
		{"word":"galiciennes","word_nosc":"galiciennes","lemma":"galicien","pos":"ADJ"} ,
		{"word":"galiléen","word_nosc":"galileen","lemma":"galiléen","pos":"ADJ"} ,
		{"word":"gallicanes","word_nosc":"gallicanes","lemma":"gallican","pos":"ADJ"} ,
		{"word":"galline","word_nosc":"galline","lemma":"galline","pos":"ADJ"} ,
		{"word":"gallique","word_nosc":"gallique","lemma":"gallique","pos":"ADJ"} ,
		{"word":"gallo","word_nosc":"gallo","lemma":"gallo","pos":"ADJ"} ,
		{"word":"gallo-romain","word_nosc":"gallo-romain","lemma":"gallo-romain","pos":"ADJ"} ,
		{"word":"gallo-romains","word_nosc":"gallo-romains","lemma":"gallo-romain","pos":"ADJ"} ,
		{"word":"gallois","word_nosc":"gallois","lemma":"gallois","pos":"ADJ"} ,
		{"word":"galloise","word_nosc":"galloise","lemma":"gallois","pos":"ADJ"} ,
		{"word":"galloises","word_nosc":"galloises","lemma":"gallois","pos":"ADJ"} ,
		{"word":"gallos","word_nosc":"gallos","lemma":"gallo","pos":"ADJ"} ,
		{"word":"galonné","word_nosc":"galonne","lemma":"galonné","pos":"ADJ"} ,
		{"word":"galonnée","word_nosc":"galonnee","lemma":"galonné","pos":"ADJ"} ,
		{"word":"galonnées","word_nosc":"galonnees","lemma":"galonné","pos":"ADJ"} ,
		{"word":"galonnés","word_nosc":"galonnes","lemma":"galonné","pos":"ADJ"} ,
		{"word":"galopant","word_nosc":"galopant","lemma":"galopant","pos":"ADJ"} ,
		{"word":"galopante","word_nosc":"galopante","lemma":"galopant","pos":"ADJ"} ,
		{"word":"galopantes","word_nosc":"galopantes","lemma":"galopant","pos":"ADJ"} ,
		{"word":"galopants","word_nosc":"galopants","lemma":"galopant","pos":"ADJ"} ,
		{"word":"galvanique","word_nosc":"galvanique","lemma":"galvanique","pos":"ADJ"} ,
		{"word":"galéjeur","word_nosc":"galejeur","lemma":"galéjeur","pos":"ADJ"} ,
		{"word":"gambadant","word_nosc":"gambadant","lemma":"gambadant","pos":"ADJ"} ,
		{"word":"gambadante","word_nosc":"gambadante","lemma":"gambadant","pos":"ADJ"} ,
		{"word":"gamin","word_nosc":"gamin","lemma":"gamin","pos":"ADJ"} ,
		{"word":"gamine","word_nosc":"gamine","lemma":"gamin","pos":"ADJ"} ,
		{"word":"gamines","word_nosc":"gamines","lemma":"gamin","pos":"ADJ"} ,
		{"word":"gamins","word_nosc":"gamins","lemma":"gamin","pos":"ADJ"} ,
		{"word":"gammée","word_nosc":"gammee","lemma":"gammée","pos":"ADJ"} ,
		{"word":"gammées","word_nosc":"gammees","lemma":"gammée","pos":"ADJ"} ,
		{"word":"ganglionnaires","word_nosc":"ganglionnaires","lemma":"ganglionnaire","pos":"ADJ"} ,
		{"word":"gangreneux","word_nosc":"gangreneux","lemma":"gangreneux","pos":"ADJ"} ,
		{"word":"gangrené","word_nosc":"gangrene","lemma":"gangrené","pos":"ADJ"} ,
		{"word":"gangrenées","word_nosc":"gangrenees","lemma":"gangrené","pos":"ADJ"} ,
		{"word":"gangrenés","word_nosc":"gangrenes","lemma":"gangrené","pos":"ADJ"} ,
		{"word":"ganté","word_nosc":"gante","lemma":"ganté","pos":"ADJ"} ,
		{"word":"gantée","word_nosc":"gantee","lemma":"ganté","pos":"ADJ"} ,
		{"word":"gantées","word_nosc":"gantees","lemma":"ganté","pos":"ADJ"} ,
		{"word":"gantés","word_nosc":"gantes","lemma":"ganté","pos":"ADJ"} ,
		{"word":"garance","word_nosc":"garance","lemma":"garance","pos":"ADJ"} ,
		{"word":"garant","word_nosc":"garant","lemma":"garant","pos":"ADJ"} ,
		{"word":"garante","word_nosc":"garante","lemma":"garant","pos":"ADJ"} ,
		{"word":"garantes","word_nosc":"garantes","lemma":"garant","pos":"ADJ"} ,
		{"word":"garanti","word_nosc":"garanti","lemma":"garanti","pos":"ADJ"} ,
		{"word":"garantie","word_nosc":"garantie","lemma":"garanti","pos":"ADJ"} ,
		{"word":"garanties","word_nosc":"garanties","lemma":"garanti","pos":"ADJ"} ,
		{"word":"garantis","word_nosc":"garantis","lemma":"garanti","pos":"ADJ"} ,
		{"word":"garants","word_nosc":"garants","lemma":"garant","pos":"ADJ"} ,
		{"word":"gardien","word_nosc":"gardien","lemma":"gardien","pos":"ADJ"} ,
		{"word":"gardienne","word_nosc":"gardienne","lemma":"gardien","pos":"ADJ"} ,
		{"word":"gardiennes","word_nosc":"gardiennes","lemma":"gardien","pos":"ADJ"} ,
		{"word":"gardiens","word_nosc":"gardiens","lemma":"gardien","pos":"ADJ"} ,
		{"word":"gardois","word_nosc":"gardois","lemma":"gardois","pos":"ADJ"} ,
		{"word":"gardé","word_nosc":"garde","lemma":"gardé","pos":"ADJ"} ,
		{"word":"gardée","word_nosc":"gardee","lemma":"gardé","pos":"ADJ"} ,
		{"word":"gardées","word_nosc":"gardees","lemma":"gardé","pos":"ADJ"} ,
		{"word":"gardés","word_nosc":"gardes","lemma":"gardé","pos":"ADJ"} ,
		{"word":"gargantuesque","word_nosc":"gargantuesque","lemma":"gargantuesque","pos":"ADJ"} ,
		{"word":"gargantuesques","word_nosc":"gargantuesques","lemma":"gargantuesque","pos":"ADJ"} ,
		{"word":"gargouillant","word_nosc":"gargouillant","lemma":"gargouillant","pos":"ADJ"} ,
		{"word":"gargouillante","word_nosc":"gargouillante","lemma":"gargouillant","pos":"ADJ"} ,
		{"word":"garibaldien","word_nosc":"garibaldien","lemma":"garibaldien","pos":"ADJ"} ,
		{"word":"garibaldiens","word_nosc":"garibaldiens","lemma":"garibaldien","pos":"ADJ"} ,
		{"word":"garni","word_nosc":"garni","lemma":"garni","pos":"ADJ"} ,
		{"word":"garnie","word_nosc":"garnie","lemma":"garni","pos":"ADJ"} ,
		{"word":"garnies","word_nosc":"garnies","lemma":"garni","pos":"ADJ"} ,
		{"word":"garnis","word_nosc":"garnis","lemma":"garni","pos":"ADJ"} ,
		{"word":"garçonnier","word_nosc":"garconnier","lemma":"garçonnier","pos":"ADJ"} ,
		{"word":"garçonniers","word_nosc":"garconniers","lemma":"garçonnier","pos":"ADJ"} ,
		{"word":"garçonnière","word_nosc":"garconniere","lemma":"garçonnier","pos":"ADJ"} ,
		{"word":"gascon","word_nosc":"gascon","lemma":"gascon","pos":"ADJ"} ,
		{"word":"gasconne","word_nosc":"gasconne","lemma":"gascon","pos":"ADJ"} ,
		{"word":"gascons","word_nosc":"gascons","lemma":"gascon","pos":"ADJ"} ,
		{"word":"gaspilleur","word_nosc":"gaspilleur","lemma":"gaspilleur","pos":"ADJ"} ,
		{"word":"gaspilleuse","word_nosc":"gaspilleuse","lemma":"gaspilleur","pos":"ADJ"} ,
		{"word":"gastrique","word_nosc":"gastrique","lemma":"gastrique","pos":"ADJ"} ,
		{"word":"gastriques","word_nosc":"gastriques","lemma":"gastrique","pos":"ADJ"} ,
		{"word":"gastro-intestinal","word_nosc":"gastro-intestinal","lemma":"gastro-intestinal","pos":"ADJ"} ,
		{"word":"gastro-intestinale","word_nosc":"gastro-intestinale","lemma":"gastro-intestinal","pos":"ADJ"} ,
		{"word":"gastro-intestinaux","word_nosc":"gastro-intestinaux","lemma":"gastro-intestinal","pos":"ADJ"} ,
		{"word":"gastrolâtre","word_nosc":"gastrolatre","lemma":"gastrolâtre","pos":"ADJ"} ,
		{"word":"gastronomique","word_nosc":"gastronomique","lemma":"gastronomique","pos":"ADJ"} ,
		{"word":"gastronomiques","word_nosc":"gastronomiques","lemma":"gastronomique","pos":"ADJ"} ,
		{"word":"gauche","word_nosc":"gauche","lemma":"gauche","pos":"ADJ"} ,
		{"word":"gaucher","word_nosc":"gaucher","lemma":"gaucher","pos":"ADJ"} ,
		{"word":"gauchers","word_nosc":"gauchers","lemma":"gaucher","pos":"ADJ"} ,
		{"word":"gauches","word_nosc":"gauches","lemma":"gauche","pos":"ADJ"} ,
		{"word":"gauchisante","word_nosc":"gauchisante","lemma":"gauchisant","pos":"ADJ"} ,
		{"word":"gauchisantes","word_nosc":"gauchisantes","lemma":"gauchisant","pos":"ADJ"} ,
		{"word":"gauchiste","word_nosc":"gauchiste","lemma":"gauchiste","pos":"ADJ"} ,
		{"word":"gauchistes","word_nosc":"gauchistes","lemma":"gauchiste","pos":"ADJ"} ,
		{"word":"gauchère","word_nosc":"gauchere","lemma":"gaucher","pos":"ADJ"} ,
		{"word":"gaufré","word_nosc":"gaufre","lemma":"gaufré","pos":"ADJ"} ,
		{"word":"gaufrée","word_nosc":"gaufree","lemma":"gaufré","pos":"ADJ"} ,
		{"word":"gaufrées","word_nosc":"gaufrees","lemma":"gaufré","pos":"ADJ"} ,
		{"word":"gaufrés","word_nosc":"gaufres","lemma":"gaufré","pos":"ADJ"} ,
		{"word":"gaulliste","word_nosc":"gaulliste","lemma":"gaulliste","pos":"ADJ"} ,
		{"word":"gaullistes","word_nosc":"gaullistes","lemma":"gaulliste","pos":"ADJ"} ,
		{"word":"gaulois","word_nosc":"gaulois","lemma":"gaulois","pos":"ADJ"} ,
		{"word":"gauloise","word_nosc":"gauloise","lemma":"gaulois","pos":"ADJ"} ,
		{"word":"gauloises","word_nosc":"gauloises","lemma":"gaulois","pos":"ADJ"} ,
		{"word":"gavroche","word_nosc":"gavroche","lemma":"gavroche","pos":"ADJ"} ,
		{"word":"gay","word_nosc":"gay","lemma":"gay","pos":"ADJ"} ,
		{"word":"gays","word_nosc":"gays","lemma":"gay","pos":"ADJ"} ,
		{"word":"gazeuse","word_nosc":"gazeuse","lemma":"gazeux","pos":"ADJ"} ,
		{"word":"gazeuses","word_nosc":"gazeuses","lemma":"gazeux","pos":"ADJ"} ,
		{"word":"gazeux","word_nosc":"gazeux","lemma":"gazeux","pos":"ADJ"} ,
		{"word":"gazier","word_nosc":"gazier","lemma":"gazier","pos":"ADJ"} ,
		{"word":"gazonné","word_nosc":"gazonne","lemma":"gazonné","pos":"ADJ"} ,
		{"word":"gazonnée","word_nosc":"gazonnee","lemma":"gazonné","pos":"ADJ"} ,
		{"word":"gazouillant","word_nosc":"gazouillant","lemma":"gazouillant","pos":"ADJ"} ,
		{"word":"gazouillante","word_nosc":"gazouillante","lemma":"gazouillant","pos":"ADJ"} ,
		{"word":"gazouillants","word_nosc":"gazouillants","lemma":"gazouillant","pos":"ADJ"} ,
		{"word":"gazouilleurs","word_nosc":"gazouilleurs","lemma":"gazouilleur","pos":"ADJ"} ,
		{"word":"gazé","word_nosc":"gaze","lemma":"gazé","pos":"ADJ"} ,
		{"word":"gazée","word_nosc":"gazee","lemma":"gazé","pos":"ADJ"} ,
		{"word":"gazés","word_nosc":"gazes","lemma":"gazé","pos":"ADJ"} ,
		{"word":"gaélique","word_nosc":"gaelique","lemma":"gaélique","pos":"ADJ"} ,
		{"word":"geignant","word_nosc":"geignant","lemma":"geignant","pos":"ADJ"} ,
		{"word":"geignante","word_nosc":"geignante","lemma":"geignant","pos":"ADJ"} ,
		{"word":"geignantes","word_nosc":"geignantes","lemma":"geignant","pos":"ADJ"} ,
		{"word":"geignard","word_nosc":"geignard","lemma":"geignard","pos":"ADJ"} ,
		{"word":"geignarde","word_nosc":"geignarde","lemma":"geignard","pos":"ADJ"} ,
		{"word":"geignardes","word_nosc":"geignardes","lemma":"geignard","pos":"ADJ"} ,
		{"word":"geignards","word_nosc":"geignards","lemma":"geignard","pos":"ADJ"} ,
		{"word":"gelé","word_nosc":"gele","lemma":"gelé","pos":"ADJ"} ,
		{"word":"gelés","word_nosc":"geles","lemma":"gelé","pos":"ADJ"} ,
		{"word":"genevois","word_nosc":"genevois","lemma":"genevois","pos":"ADJ"} ,
		{"word":"genevoises","word_nosc":"genevoises","lemma":"genevois","pos":"ADJ"} ,
		{"word":"gengiskhanide","word_nosc":"gengiskhanide","lemma":"gengiskhanide","pos":"ADJ"} ,
		{"word":"genreux","word_nosc":"genreux","lemma":"genreux","pos":"ADJ"} ,
		{"word":"gente","word_nosc":"gente","lemma":"gent","pos":"ADJ"} ,
		{"word":"gente","word_nosc":"gente","lemma":"gente","pos":"ADJ"} ,
		{"word":"gentes","word_nosc":"gentes","lemma":"gent","pos":"ADJ"} ,
		{"word":"gentil","word_nosc":"gentil","lemma":"gentil","pos":"ADJ"} ,
		{"word":"gentilice","word_nosc":"gentilice","lemma":"gentilice","pos":"ADJ"} ,
		{"word":"gentille","word_nosc":"gentille","lemma":"gentil","pos":"ADJ"} ,
		{"word":"gentilles","word_nosc":"gentilles","lemma":"gentil","pos":"ADJ"} ,
		{"word":"gentillet","word_nosc":"gentillet","lemma":"gentillet","pos":"ADJ"} ,
		{"word":"gentillets","word_nosc":"gentillets","lemma":"gentillet","pos":"ADJ"} ,
		{"word":"gentillette","word_nosc":"gentillette","lemma":"gentillet","pos":"ADJ"} ,
		{"word":"gentils","word_nosc":"gentils","lemma":"gentil","pos":"ADJ"} ,
		{"word":"germain","word_nosc":"germain","lemma":"germain","pos":"ADJ"} ,
		{"word":"germaine","word_nosc":"germaine","lemma":"germain","pos":"ADJ"} ,
		{"word":"germaines","word_nosc":"germaines","lemma":"germain","pos":"ADJ"} ,
		{"word":"germains","word_nosc":"germains","lemma":"germain","pos":"ADJ"} ,
		{"word":"germanique","word_nosc":"germanique","lemma":"germanique","pos":"ADJ"} ,
		{"word":"germaniques","word_nosc":"germaniques","lemma":"germanique","pos":"ADJ"} ,
		{"word":"germano-anglaises","word_nosc":"germano-anglaises","lemma":"germano-anglais","pos":"ADJ"} ,
		{"word":"germano-belge","word_nosc":"germano-belge","lemma":"germano-belge","pos":"ADJ"} ,
		{"word":"germano-irlandais","word_nosc":"germano-irlandais","lemma":"germano-irlandais","pos":"ADJ"} ,
		{"word":"germano-russe","word_nosc":"germano-russe","lemma":"germano-russe","pos":"ADJ"} ,
		{"word":"germano-soviétique","word_nosc":"germano-sovietique","lemma":"germano-soviétique","pos":"ADJ"} ,
		{"word":"germanophone","word_nosc":"germanophone","lemma":"germanophone","pos":"ADJ"} ,
		{"word":"germant","word_nosc":"germant","lemma":"germant","pos":"ADJ"} ,
		{"word":"germinal","word_nosc":"germinal","lemma":"germinal","pos":"ADJ"} ,
		{"word":"germinale","word_nosc":"germinale","lemma":"germinal","pos":"ADJ"} ,
		{"word":"germé","word_nosc":"germe","lemma":"germé","pos":"ADJ"} ,
		{"word":"germée","word_nosc":"germee","lemma":"germé","pos":"ADJ"} ,
		{"word":"germées","word_nosc":"germees","lemma":"germé","pos":"ADJ"} ,
		{"word":"gesticulant","word_nosc":"gesticulant","lemma":"gesticulant","pos":"ADJ"} ,
		{"word":"gesticulante","word_nosc":"gesticulante","lemma":"gesticulant","pos":"ADJ"} ,
		{"word":"gesticulantes","word_nosc":"gesticulantes","lemma":"gesticulant","pos":"ADJ"} ,
		{"word":"gesticulants","word_nosc":"gesticulants","lemma":"gesticulant","pos":"ADJ"} ,
		{"word":"gesticulatoire","word_nosc":"gesticulatoire","lemma":"gesticulatoire","pos":"ADJ"} ,
		{"word":"gestionnaire","word_nosc":"gestionnaire","lemma":"gestionnaire","pos":"ADJ"} ,
		{"word":"gestionnaires","word_nosc":"gestionnaires","lemma":"gestionnaire","pos":"ADJ"} ,
		{"word":"gestuel","word_nosc":"gestuel","lemma":"gestuel","pos":"ADJ"} ,
		{"word":"gestuelle","word_nosc":"gestuelle","lemma":"gestuel","pos":"ADJ"} ,
		{"word":"ghanéens","word_nosc":"ghaneens","lemma":"ghanéen","pos":"ADJ"} ,
		{"word":"gibbeuse","word_nosc":"gibbeuse","lemma":"gibbeux","pos":"ADJ"} ,
		{"word":"gibbeux","word_nosc":"gibbeux","lemma":"gibbeux","pos":"ADJ"} ,
		{"word":"gibeline","word_nosc":"gibeline","lemma":"gibelin","pos":"ADJ"} ,
		{"word":"giboyeuse","word_nosc":"giboyeuse","lemma":"giboyeux","pos":"ADJ"} ,
		{"word":"giboyeuses","word_nosc":"giboyeuses","lemma":"giboyeux","pos":"ADJ"} ,
		{"word":"giboyeux","word_nosc":"giboyeux","lemma":"giboyeux","pos":"ADJ"} ,
		{"word":"gidien","word_nosc":"gidien","lemma":"gidien","pos":"ADJ"} ,
		{"word":"gidienne","word_nosc":"gidienne","lemma":"gidien","pos":"ADJ"} ,
		{"word":"gidiennes","word_nosc":"gidiennes","lemma":"gidien","pos":"ADJ"} ,
		{"word":"giga","word_nosc":"giga","lemma":"giga","pos":"ADJ"} ,
		{"word":"gigantesque","word_nosc":"gigantesque","lemma":"gigantesque","pos":"ADJ"} ,
		{"word":"gigantesques","word_nosc":"gigantesques","lemma":"gigantesque","pos":"ADJ"} ,
		{"word":"gigas","word_nosc":"gigas","lemma":"giga","pos":"ADJ"} ,
		{"word":"gigogne","word_nosc":"gigogne","lemma":"gigogne","pos":"ADJ"} ,
		{"word":"gigognes","word_nosc":"gigognes","lemma":"gigogne","pos":"ADJ"} ,
		{"word":"gigoté","word_nosc":"gigote","lemma":"gigoté","pos":"ADJ"} ,
		{"word":"giocoso","word_nosc":"giocoso","lemma":"giocoso","pos":"ADJ"} ,
		{"word":"giratoire","word_nosc":"giratoire","lemma":"giratoire","pos":"ADJ"} ,
		{"word":"girond","word_nosc":"girond","lemma":"girond","pos":"ADJ"} ,
		{"word":"gironde","word_nosc":"gironde","lemma":"girond","pos":"ADJ"} ,
		{"word":"girondes","word_nosc":"girondes","lemma":"girond","pos":"ADJ"} ,
		{"word":"girondin","word_nosc":"girondin","lemma":"girondin","pos":"ADJ"} ,
		{"word":"girondine","word_nosc":"girondine","lemma":"girondin","pos":"ADJ"} ,
		{"word":"girondines","word_nosc":"girondines","lemma":"girondin","pos":"ADJ"} ,
		{"word":"girondins","word_nosc":"girondins","lemma":"girondin","pos":"ADJ"} ,
		{"word":"gironds","word_nosc":"gironds","lemma":"girond","pos":"ADJ"} ,
		{"word":"gisant","word_nosc":"gisant","lemma":"gisant","pos":"ADJ"} ,
		{"word":"gisante","word_nosc":"gisante","lemma":"gisant","pos":"ADJ"} ,
		{"word":"gisantes","word_nosc":"gisantes","lemma":"gisant","pos":"ADJ"} ,
		{"word":"gitan","word_nosc":"gitan","lemma":"gitan","pos":"ADJ"} ,
		{"word":"gitane","word_nosc":"gitane","lemma":"gitan","pos":"ADJ"} ,
		{"word":"gitanes","word_nosc":"gitanes","lemma":"gitan","pos":"ADJ"} ,
		{"word":"gitans","word_nosc":"gitans","lemma":"gitan","pos":"ADJ"} ,
		{"word":"givrante","word_nosc":"givrante","lemma":"givrant","pos":"ADJ"} ,
		{"word":"givré","word_nosc":"givre","lemma":"givré","pos":"ADJ"} ,
		{"word":"givrée","word_nosc":"givree","lemma":"givré","pos":"ADJ"} ,
		{"word":"givrées","word_nosc":"givrees","lemma":"givré","pos":"ADJ"} ,
		{"word":"givrés","word_nosc":"givres","lemma":"givré","pos":"ADJ"} ,
		{"word":"gla-gla","word_nosc":"gla-gla","lemma":"gla-gla","pos":"ADJ"} ,
		{"word":"glabre","word_nosc":"glabre","lemma":"glabre","pos":"ADJ"} ,
		{"word":"glabres","word_nosc":"glabres","lemma":"glabre","pos":"ADJ"} ,
		{"word":"glaciaire","word_nosc":"glaciaire","lemma":"glaciaire","pos":"ADJ"} ,
		{"word":"glaciaires","word_nosc":"glaciaires","lemma":"glaciaire","pos":"ADJ"} ,
		{"word":"glacial","word_nosc":"glacial","lemma":"glacial","pos":"ADJ"} ,
		{"word":"glaciale","word_nosc":"glaciale","lemma":"glacial","pos":"ADJ"} ,
		{"word":"glaciales","word_nosc":"glaciales","lemma":"glacial","pos":"ADJ"} ,
		{"word":"glacials","word_nosc":"glacials","lemma":"glacial","pos":"ADJ"} ,
		{"word":"glaciaux","word_nosc":"glaciaux","lemma":"glacial","pos":"ADJ"} ,
		{"word":"glacé","word_nosc":"glace","lemma":"glacé","pos":"ADJ"} ,
		{"word":"glacée","word_nosc":"glacee","lemma":"glacé","pos":"ADJ"} ,
		{"word":"glacées","word_nosc":"glacees","lemma":"glacé","pos":"ADJ"} ,
		{"word":"glacés","word_nosc":"glaces","lemma":"glacé","pos":"ADJ"} ,
		{"word":"glaireuse","word_nosc":"glaireuse","lemma":"glaireux","pos":"ADJ"} ,
		{"word":"glaireuses","word_nosc":"glaireuses","lemma":"glaireux","pos":"ADJ"} ,
		{"word":"glaireux","word_nosc":"glaireux","lemma":"glaireux","pos":"ADJ"} ,
		{"word":"glaiseuse","word_nosc":"glaiseuse","lemma":"glaiseux","pos":"ADJ"} ,
		{"word":"glaiseuses","word_nosc":"glaiseuses","lemma":"glaiseux","pos":"ADJ"} ,
		{"word":"glaiseux","word_nosc":"glaiseux","lemma":"glaiseux","pos":"ADJ"} ,
		{"word":"glandilleuse","word_nosc":"glandilleuse","lemma":"glandilleuse","pos":"ADJ"} ,
		{"word":"glandilleuses","word_nosc":"glandilleuses","lemma":"glandilleuse","pos":"ADJ"} ,
		{"word":"glandilleux","word_nosc":"glandilleux","lemma":"glandilleux","pos":"ADJ"} ,
		{"word":"glandulaire","word_nosc":"glandulaire","lemma":"glandulaire","pos":"ADJ"} ,
		{"word":"glandulaires","word_nosc":"glandulaires","lemma":"glandulaire","pos":"ADJ"} ,
		{"word":"glanduleuse","word_nosc":"glanduleuse","lemma":"glanduleux","pos":"ADJ"} ,
		{"word":"glapissant","word_nosc":"glapissant","lemma":"glapissant","pos":"ADJ"} ,
		{"word":"glapissante","word_nosc":"glapissante","lemma":"glapissant","pos":"ADJ"} ,
		{"word":"glapissantes","word_nosc":"glapissantes","lemma":"glapissant","pos":"ADJ"} ,
		{"word":"glapissants","word_nosc":"glapissants","lemma":"glapissant","pos":"ADJ"} ,
		{"word":"glapisseurs","word_nosc":"glapisseurs","lemma":"glapisseur","pos":"ADJ"} ,
		{"word":"glauque","word_nosc":"glauque","lemma":"glauque","pos":"ADJ"} ,
		{"word":"glauques","word_nosc":"glauques","lemma":"glauque","pos":"ADJ"} ,
		{"word":"glaçant","word_nosc":"glacant","lemma":"glaçant","pos":"ADJ"} ,
		{"word":"glaçante","word_nosc":"glacante","lemma":"glaçant","pos":"ADJ"} ,
		{"word":"glissant","word_nosc":"glissant","lemma":"glissant","pos":"ADJ"} ,
		{"word":"glissante","word_nosc":"glissante","lemma":"glissant","pos":"ADJ"} ,
		{"word":"glissantes","word_nosc":"glissantes","lemma":"glissant","pos":"ADJ"} ,
		{"word":"glissants","word_nosc":"glissants","lemma":"glissant","pos":"ADJ"} ,
		{"word":"global","word_nosc":"global","lemma":"global","pos":"ADJ"} ,
		{"word":"globale","word_nosc":"globale","lemma":"global","pos":"ADJ"} ,
		{"word":"globaux","word_nosc":"globaux","lemma":"global","pos":"ADJ"} ,
		{"word":"globulaire","word_nosc":"globulaire","lemma":"globulaire","pos":"ADJ"} ,
		{"word":"globulaires","word_nosc":"globulaires","lemma":"globulaire","pos":"ADJ"} ,
		{"word":"globuleuse","word_nosc":"globuleuse","lemma":"globuleux","pos":"ADJ"} ,
		{"word":"globuleux","word_nosc":"globuleux","lemma":"globuleux","pos":"ADJ"} ,
		{"word":"glorieuse","word_nosc":"glorieuse","lemma":"glorieux","pos":"ADJ"} ,
		{"word":"glorieuses","word_nosc":"glorieuses","lemma":"glorieux","pos":"ADJ"} ,
		{"word":"glorieux","word_nosc":"glorieux","lemma":"glorieux","pos":"ADJ"} ,
		{"word":"glottique","word_nosc":"glottique","lemma":"glottique","pos":"ADJ"} ,
		{"word":"gloussant","word_nosc":"gloussant","lemma":"gloussant","pos":"ADJ"} ,
		{"word":"gloussante","word_nosc":"gloussante","lemma":"gloussant","pos":"ADJ"} ,
		{"word":"gloussantes","word_nosc":"gloussantes","lemma":"gloussant","pos":"ADJ"} ,
		{"word":"gloussants","word_nosc":"gloussants","lemma":"gloussant","pos":"ADJ"} ,
		{"word":"glouton","word_nosc":"glouton","lemma":"glouton","pos":"ADJ"} ,
		{"word":"gloutonne","word_nosc":"gloutonne","lemma":"glouton","pos":"ADJ"} ,
		{"word":"gloutons","word_nosc":"gloutons","lemma":"glouton","pos":"ADJ"} ,
		{"word":"gluant","word_nosc":"gluant","lemma":"gluant","pos":"ADJ"} ,
		{"word":"gluante","word_nosc":"gluante","lemma":"gluant","pos":"ADJ"} ,
		{"word":"gluantes","word_nosc":"gluantes","lemma":"gluant","pos":"ADJ"} ,
		{"word":"gluants","word_nosc":"gluants","lemma":"gluant","pos":"ADJ"} ,
		{"word":"gluconique","word_nosc":"gluconique","lemma":"gluconique","pos":"ADJ"} ,
		{"word":"glénoïde","word_nosc":"glenoide","lemma":"glénoïde","pos":"ADJ"} ,
		{"word":"gnangnan","word_nosc":"gnangnan","lemma":"gnangnan","pos":"ADJ"} ,
		{"word":"gnostique","word_nosc":"gnostique","lemma":"gnostique","pos":"ADJ"} ,
		{"word":"gnostiques","word_nosc":"gnostiques","lemma":"gnostique","pos":"ADJ"} ,
		{"word":"godiche","word_nosc":"godiche","lemma":"godiche","pos":"ADJ"} ,
		{"word":"godiches","word_nosc":"godiches","lemma":"godiche","pos":"ADJ"} ,
		{"word":"gogol","word_nosc":"gogol","lemma":"gogol","pos":"ADJ"} ,
		{"word":"gogols","word_nosc":"gogols","lemma":"gogol","pos":"ADJ"} ,
		{"word":"goguenard","word_nosc":"goguenard","lemma":"goguenard","pos":"ADJ"} ,
		{"word":"goguenarde","word_nosc":"goguenarde","lemma":"goguenard","pos":"ADJ"} ,
		{"word":"goguenardes","word_nosc":"goguenardes","lemma":"goguenard","pos":"ADJ"} ,
		{"word":"goguenards","word_nosc":"goguenards","lemma":"goguenard","pos":"ADJ"} ,
		{"word":"goinfre","word_nosc":"goinfre","lemma":"goinfre","pos":"ADJ"} ,
		{"word":"goinfres","word_nosc":"goinfres","lemma":"goinfre","pos":"ADJ"} ,
		{"word":"goitreux","word_nosc":"goitreux","lemma":"goitreux","pos":"ADJ"} ,
		{"word":"gold","word_nosc":"gold","lemma":"gold","pos":"ADJ"} ,
		{"word":"gommeuse","word_nosc":"gommeuse","lemma":"gommeux","pos":"ADJ"} ,
		{"word":"gommeux","word_nosc":"gommeux","lemma":"gommeux","pos":"ADJ"} ,
		{"word":"gommé","word_nosc":"gomme","lemma":"gommé","pos":"ADJ"} ,
		{"word":"gommée","word_nosc":"gommee","lemma":"gommé","pos":"ADJ"} ,
		{"word":"gommées","word_nosc":"gommees","lemma":"gommé","pos":"ADJ"} ,
		{"word":"goménolée","word_nosc":"gomenolee","lemma":"goménolé","pos":"ADJ"} ,
		{"word":"gonadotropes","word_nosc":"gonadotropes","lemma":"gonadotrope","pos":"ADJ"} ,
		{"word":"gondolant","word_nosc":"gondolant","lemma":"gondolant","pos":"ADJ"} ,
		{"word":"gondolé","word_nosc":"gondole","lemma":"gondolé","pos":"ADJ"} ,
		{"word":"gondolée","word_nosc":"gondolee","lemma":"gondolé","pos":"ADJ"} ,
		{"word":"gondolées","word_nosc":"gondolees","lemma":"gondolé","pos":"ADJ"} ,
		{"word":"gondolés","word_nosc":"gondoles","lemma":"gondolé","pos":"ADJ"} ,
		{"word":"gonflable","word_nosc":"gonflable","lemma":"gonflable","pos":"ADJ"} ,
		{"word":"gonflables","word_nosc":"gonflables","lemma":"gonflable","pos":"ADJ"} ,
		{"word":"gonflant","word_nosc":"gonflant","lemma":"gonflant","pos":"ADJ"} ,
		{"word":"gonflante","word_nosc":"gonflante","lemma":"gonflant","pos":"ADJ"} ,
		{"word":"gonflantes","word_nosc":"gonflantes","lemma":"gonflant","pos":"ADJ"} ,
		{"word":"gonflants","word_nosc":"gonflants","lemma":"gonflant","pos":"ADJ"} ,
		{"word":"gonflé","word_nosc":"gonfle","lemma":"gonflé","pos":"ADJ"} ,
		{"word":"gonflée","word_nosc":"gonflee","lemma":"gonflé","pos":"ADJ"} ,
		{"word":"gonflées","word_nosc":"gonflees","lemma":"gonflé","pos":"ADJ"} ,
		{"word":"gonflés","word_nosc":"gonfles","lemma":"gonflé","pos":"ADJ"} ,
		{"word":"gongoriste","word_nosc":"gongoriste","lemma":"gongoriste","pos":"ADJ"} ,
		{"word":"gonococcique","word_nosc":"gonococcique","lemma":"gonococcique","pos":"ADJ"} ,
		{"word":"gordien","word_nosc":"gordien","lemma":"gordien","pos":"ADJ"} ,
		{"word":"gordiens","word_nosc":"gordiens","lemma":"gordien","pos":"ADJ"} ,
		{"word":"gore","word_nosc":"gore","lemma":"gore","pos":"ADJ"} ,
		{"word":"gorge-de-pigeon","word_nosc":"gorge-de-pigeon","lemma":"gorge-de-pigeon","pos":"ADJ"} ,
		{"word":"gorgé","word_nosc":"gorge","lemma":"gorgé","pos":"ADJ"} ,
		{"word":"gorgés","word_nosc":"gorges","lemma":"gorgé","pos":"ADJ"} ,
		{"word":"gothique","word_nosc":"gothique","lemma":"gothique","pos":"ADJ"} ,
		{"word":"gothiques","word_nosc":"gothiques","lemma":"gothique","pos":"ADJ"} ,
		{"word":"gouailleur","word_nosc":"gouailleur","lemma":"gouailleur","pos":"ADJ"} ,
		{"word":"gouailleurs","word_nosc":"gouailleurs","lemma":"gouailleur","pos":"ADJ"} ,
		{"word":"gouailleuse","word_nosc":"gouailleuse","lemma":"gouailleur","pos":"ADJ"} ,
		{"word":"gouailleuses","word_nosc":"gouailleuses","lemma":"gouailleur","pos":"ADJ"} ,
		{"word":"goudronneux","word_nosc":"goudronneux","lemma":"goudronneux","pos":"ADJ"} ,
		{"word":"goudronné","word_nosc":"goudronne","lemma":"goudronné","pos":"ADJ"} ,
		{"word":"goudronnée","word_nosc":"goudronnee","lemma":"goudronné","pos":"ADJ"} ,
		{"word":"goudronnées","word_nosc":"goudronnees","lemma":"goudronné","pos":"ADJ"} ,
		{"word":"goudronnés","word_nosc":"goudronnes","lemma":"goudronné","pos":"ADJ"} ,
		{"word":"gouleyant","word_nosc":"gouleyant","lemma":"gouleyant","pos":"ADJ"} ,
		{"word":"goulu","word_nosc":"goulu","lemma":"goulu","pos":"ADJ"} ,
		{"word":"goulue","word_nosc":"goulue","lemma":"goulu","pos":"ADJ"} ,
		{"word":"goulues","word_nosc":"goulues","lemma":"goulu","pos":"ADJ"} ,
		{"word":"goulus","word_nosc":"goulus","lemma":"goulu","pos":"ADJ"} ,
		{"word":"gourd","word_nosc":"gourd","lemma":"gourd","pos":"ADJ"} ,
		{"word":"gourde","word_nosc":"gourde","lemma":"gourde","pos":"ADJ"} ,
		{"word":"gourdes","word_nosc":"gourdes","lemma":"gourde","pos":"ADJ"} ,
		{"word":"gourds","word_nosc":"gourds","lemma":"gourd","pos":"ADJ"} ,
		{"word":"gourmand","word_nosc":"gourmand","lemma":"gourmand","pos":"ADJ"} ,
		{"word":"gourmande","word_nosc":"gourmande","lemma":"gourmand","pos":"ADJ"} ,
		{"word":"gourmandes","word_nosc":"gourmandes","lemma":"gourmand","pos":"ADJ"} ,
		{"word":"gourmands","word_nosc":"gourmands","lemma":"gourmand","pos":"ADJ"} ,
		{"word":"gourmé","word_nosc":"gourme","lemma":"gourmé","pos":"ADJ"} ,
		{"word":"gourmée","word_nosc":"gourmee","lemma":"gourmé","pos":"ADJ"} ,
		{"word":"gourmés","word_nosc":"gourmes","lemma":"gourmé","pos":"ADJ"} ,
		{"word":"gouttereau","word_nosc":"gouttereau","lemma":"gouttereau","pos":"ADJ"} ,
		{"word":"gouttereaux","word_nosc":"gouttereaux","lemma":"gouttereau","pos":"ADJ"} ,
		{"word":"goutteuse","word_nosc":"goutteuse","lemma":"goutteux","pos":"ADJ"} ,
		{"word":"goutteuses","word_nosc":"goutteuses","lemma":"goutteux","pos":"ADJ"} ,
		{"word":"goutteux","word_nosc":"goutteux","lemma":"goutteux","pos":"ADJ"} ,
		{"word":"gouvernable","word_nosc":"gouvernable","lemma":"gouvernable","pos":"ADJ"} ,
		{"word":"gouvernant","word_nosc":"gouvernant","lemma":"gouvernant","pos":"ADJ"} ,
		{"word":"gouvernants","word_nosc":"gouvernants","lemma":"gouvernant","pos":"ADJ"} ,
		{"word":"gouvernemental","word_nosc":"gouvernemental","lemma":"gouvernemental","pos":"ADJ"} ,
		{"word":"gouvernementale","word_nosc":"gouvernementale","lemma":"gouvernemental","pos":"ADJ"} ,
		{"word":"gouvernementales","word_nosc":"gouvernementales","lemma":"gouvernemental","pos":"ADJ"} ,
		{"word":"gouvernementaux","word_nosc":"gouvernementaux","lemma":"gouvernemental","pos":"ADJ"} ,
		{"word":"goy","word_nosc":"goy","lemma":"goy","pos":"ADJ"} ,
		{"word":"goûtables","word_nosc":"goutables","lemma":"goûtable","pos":"ADJ"} ,
		{"word":"goûteuse","word_nosc":"gouteuse","lemma":"goûteux","pos":"ADJ"} ,
		{"word":"goûteuses","word_nosc":"gouteuses","lemma":"goûteux","pos":"ADJ"} ,
		{"word":"goûteux","word_nosc":"gouteux","lemma":"goûteux","pos":"ADJ"} ,
		{"word":"grabataire","word_nosc":"grabataire","lemma":"grabataire","pos":"ADJ"} ,
		{"word":"grabataires","word_nosc":"grabataires","lemma":"grabataire","pos":"ADJ"} ,
		{"word":"gracieuse","word_nosc":"gracieuse","lemma":"gracieux","pos":"ADJ"} ,
		{"word":"gracieuses","word_nosc":"gracieuses","lemma":"gracieux","pos":"ADJ"} ,
		{"word":"gracieusé","word_nosc":"gracieuse","lemma":"gracieusé","pos":"ADJ"} ,
		{"word":"gracieux","word_nosc":"gracieux","lemma":"gracieux","pos":"ADJ"} ,
		{"word":"gracile","word_nosc":"gracile","lemma":"gracile","pos":"ADJ"} ,
		{"word":"graciles","word_nosc":"graciles","lemma":"gracile","pos":"ADJ"} ,
		{"word":"gracié","word_nosc":"gracie","lemma":"gracié","pos":"ADJ"} ,
		{"word":"graciés","word_nosc":"gracies","lemma":"gracié","pos":"ADJ"} ,
		{"word":"graduel","word_nosc":"graduel","lemma":"graduel","pos":"ADJ"} ,
		{"word":"graduelle","word_nosc":"graduelle","lemma":"graduel","pos":"ADJ"} ,
		{"word":"gradué","word_nosc":"gradue","lemma":"gradué","pos":"ADJ"} ,
		{"word":"graduée","word_nosc":"graduee","lemma":"gradué","pos":"ADJ"} ,
		{"word":"graduées","word_nosc":"graduees","lemma":"gradué","pos":"ADJ"} ,
		{"word":"gradé","word_nosc":"grade","lemma":"gradé","pos":"ADJ"} ,
		{"word":"gradée","word_nosc":"gradee","lemma":"gradé","pos":"ADJ"} ,
		{"word":"gradés","word_nosc":"grades","lemma":"gradé","pos":"ADJ"} ,
		{"word":"graillonnant","word_nosc":"graillonnant","lemma":"graillonnant","pos":"ADJ"} ,
		{"word":"graillonneux","word_nosc":"graillonneux","lemma":"graillonneux","pos":"ADJ"} ,
		{"word":"grainetier","word_nosc":"grainetier","lemma":"grainetier","pos":"ADJ"} ,
		{"word":"grainetiers","word_nosc":"grainetiers","lemma":"grainetier","pos":"ADJ"} ,
		{"word":"graisseur","word_nosc":"graisseur","lemma":"graisseur","pos":"ADJ"} ,
		{"word":"graisseuse","word_nosc":"graisseuse","lemma":"graisseux","pos":"ADJ"} ,
		{"word":"graisseuses","word_nosc":"graisseuses","lemma":"graisseux","pos":"ADJ"} ,
		{"word":"graisseux","word_nosc":"graisseux","lemma":"graisseux","pos":"ADJ"} ,
		{"word":"graissé","word_nosc":"graisse","lemma":"graissé","pos":"ADJ"} ,
		{"word":"graissée","word_nosc":"graissee","lemma":"graissé","pos":"ADJ"} ,
		{"word":"graissées","word_nosc":"graissees","lemma":"graissé","pos":"ADJ"} ,
		{"word":"graissés","word_nosc":"graisses","lemma":"graissé","pos":"ADJ"} ,
		{"word":"grammatical","word_nosc":"grammatical","lemma":"grammatical","pos":"ADJ"} ,
		{"word":"grammaticale","word_nosc":"grammaticale","lemma":"grammatical","pos":"ADJ"} ,
		{"word":"grammaticales","word_nosc":"grammaticales","lemma":"grammatical","pos":"ADJ"} ,
		{"word":"grammaticaux","word_nosc":"grammaticaux","lemma":"grammatical","pos":"ADJ"} ,
		{"word":"grand","word_nosc":"grand","lemma":"grand","pos":"ADJ"} ,
		{"word":"grand-ducale","word_nosc":"grand-ducale","lemma":"grand-ducal","pos":"ADJ"} ,
		{"word":"grand-guignolesque","word_nosc":"grand-guignolesque","lemma":"grand-guignolesque","pos":"ADJ"} ,
		{"word":"grand-prêtre","word_nosc":"grand-pretre","lemma":"grand-prêtre","pos":"ADJ"} ,
		{"word":"grand-quartier","word_nosc":"grand-quartier","lemma":"grand-quartier","pos":"ADJ"} ,
		{"word":"grande","word_nosc":"grande","lemma":"grand","pos":"ADJ"} ,
		{"word":"grandes","word_nosc":"grandes","lemma":"grand","pos":"ADJ"} ,
		{"word":"grandet","word_nosc":"grandet","lemma":"grandet","pos":"ADJ"} ,
		{"word":"grandiloque","word_nosc":"grandiloque","lemma":"grandiloque","pos":"ADJ"} ,
		{"word":"grandiloquent","word_nosc":"grandiloquent","lemma":"grandiloquent","pos":"ADJ"} ,
		{"word":"grandiloquente","word_nosc":"grandiloquente","lemma":"grandiloquent","pos":"ADJ"} ,
		{"word":"grandiloquentes","word_nosc":"grandiloquentes","lemma":"grandiloquent","pos":"ADJ"} ,
		{"word":"grandiloquents","word_nosc":"grandiloquents","lemma":"grandiloquent","pos":"ADJ"} ,
		{"word":"grandiose","word_nosc":"grandiose","lemma":"grandiose","pos":"ADJ"} ,
		{"word":"grandioses","word_nosc":"grandioses","lemma":"grandiose","pos":"ADJ"} ,
		{"word":"grandissant","word_nosc":"grandissant","lemma":"grandissant","pos":"ADJ"} ,
		{"word":"grandissante","word_nosc":"grandissante","lemma":"grandissant","pos":"ADJ"} ,
		{"word":"grandissantes","word_nosc":"grandissantes","lemma":"grandissant","pos":"ADJ"} ,
		{"word":"grandissants","word_nosc":"grandissants","lemma":"grandissant","pos":"ADJ"} ,
		{"word":"grandissime","word_nosc":"grandissime","lemma":"grandissime","pos":"ADJ"} ,
		{"word":"grands","word_nosc":"grands","lemma":"grand","pos":"ADJ"} ,
		{"word":"granitique","word_nosc":"granitique","lemma":"granitique","pos":"ADJ"} ,
		{"word":"granitiques","word_nosc":"granitiques","lemma":"granitique","pos":"ADJ"} ,
		{"word":"granités","word_nosc":"granites","lemma":"granité","pos":"ADJ"} ,
		{"word":"granulaire","word_nosc":"granulaire","lemma":"granulaire","pos":"ADJ"} ,
		{"word":"granuleuse","word_nosc":"granuleuse","lemma":"granuleux","pos":"ADJ"} ,
		{"word":"granuleuses","word_nosc":"granuleuses","lemma":"granuleux","pos":"ADJ"} ,
		{"word":"granuleux","word_nosc":"granuleux","lemma":"granuleux","pos":"ADJ"} ,
		{"word":"granulé","word_nosc":"granule","lemma":"granulé","pos":"ADJ"} ,
		{"word":"granulés","word_nosc":"granules","lemma":"granulé","pos":"ADJ"} ,
		{"word":"graphique","word_nosc":"graphique","lemma":"graphique","pos":"ADJ"} ,
		{"word":"graphiques","word_nosc":"graphiques","lemma":"graphique","pos":"ADJ"} ,
		{"word":"graphologique","word_nosc":"graphologique","lemma":"graphologique","pos":"ADJ"} ,
		{"word":"gras","word_nosc":"gras","lemma":"gras","pos":"ADJ"} ,
		{"word":"grasse","word_nosc":"grasse","lemma":"gras","pos":"ADJ"} ,
		{"word":"grasses","word_nosc":"grasses","lemma":"gras","pos":"ADJ"} ,
		{"word":"grasseyant","word_nosc":"grasseyant","lemma":"grasseyant","pos":"ADJ"} ,
		{"word":"grasseyante","word_nosc":"grasseyante","lemma":"grasseyant","pos":"ADJ"} ,
		{"word":"grassouillet","word_nosc":"grassouillet","lemma":"grassouillet","pos":"ADJ"} ,
		{"word":"grassouillets","word_nosc":"grassouillets","lemma":"grassouillet","pos":"ADJ"} ,
		{"word":"grassouillette","word_nosc":"grassouillette","lemma":"grassouillet","pos":"ADJ"} ,
		{"word":"grassouillettes","word_nosc":"grassouillettes","lemma":"grassouillet","pos":"ADJ"} ,
		{"word":"gratifiant","word_nosc":"gratifiant","lemma":"gratifiant","pos":"ADJ"} ,
		{"word":"gratifiante","word_nosc":"gratifiante","lemma":"gratifiant","pos":"ADJ"} ,
		{"word":"gratiné","word_nosc":"gratine","lemma":"gratiné","pos":"ADJ"} ,
		{"word":"gratinés","word_nosc":"gratines","lemma":"gratiné","pos":"ADJ"} ,
		{"word":"grattant","word_nosc":"grattant","lemma":"grattant","pos":"ADJ"} ,
		{"word":"gratuit","word_nosc":"gratuit","lemma":"gratuit","pos":"ADJ"} ,
		{"word":"gratuite","word_nosc":"gratuite","lemma":"gratuit","pos":"ADJ"} ,
		{"word":"gratuites","word_nosc":"gratuites","lemma":"gratuit","pos":"ADJ"} ,
		{"word":"gratuits","word_nosc":"gratuits","lemma":"gratuit","pos":"ADJ"} ,
		{"word":"grave","word_nosc":"grave","lemma":"grave","pos":"ADJ"} ,
		{"word":"graveleuse","word_nosc":"graveleuse","lemma":"graveleux","pos":"ADJ"} ,
		{"word":"graveleuses","word_nosc":"graveleuses","lemma":"graveleux","pos":"ADJ"} ,
		{"word":"graveleux","word_nosc":"graveleux","lemma":"graveleux","pos":"ADJ"} ,
		{"word":"graves","word_nosc":"graves","lemma":"grave","pos":"ADJ"} ,
		{"word":"gravide","word_nosc":"gravide","lemma":"gravide","pos":"ADJ"} ,
		{"word":"gravissime","word_nosc":"gravissime","lemma":"gravissime","pos":"ADJ"} ,
		{"word":"gravissimes","word_nosc":"gravissimes","lemma":"gravissime","pos":"ADJ"} ,
		{"word":"gravitationnel","word_nosc":"gravitationnel","lemma":"gravitationnel","pos":"ADJ"} ,
		{"word":"gravitationnelle","word_nosc":"gravitationnelle","lemma":"gravitationnel","pos":"ADJ"} ,
		{"word":"gravitationnelles","word_nosc":"gravitationnelles","lemma":"gravitationnel","pos":"ADJ"} ,
		{"word":"gravitationnels","word_nosc":"gravitationnels","lemma":"gravitationnel","pos":"ADJ"} ,
		{"word":"gravos","word_nosc":"gravos","lemma":"gravos","pos":"ADJ"} ,
		{"word":"gravé","word_nosc":"grave","lemma":"gravé","pos":"ADJ"} ,
		{"word":"gravée","word_nosc":"gravee","lemma":"gravé","pos":"ADJ"} ,
		{"word":"gravées","word_nosc":"gravees","lemma":"gravé","pos":"ADJ"} ,
		{"word":"gravés","word_nosc":"graves","lemma":"gravé","pos":"ADJ"} ,
		{"word":"grec","word_nosc":"grec","lemma":"grec","pos":"ADJ"} ,
		{"word":"grecque","word_nosc":"grecque","lemma":"grec","pos":"ADJ"} ,
		{"word":"grecques","word_nosc":"grecques","lemma":"grec","pos":"ADJ"} ,
		{"word":"grecs","word_nosc":"grecs","lemma":"grec","pos":"ADJ"} ,
		{"word":"greffe","word_nosc":"greffe","lemma":"greffe","pos":"ADJ"} ,
		{"word":"greffes","word_nosc":"greffes","lemma":"greffe","pos":"ADJ"} ,
		{"word":"greffé","word_nosc":"greffe","lemma":"greffé","pos":"ADJ"} ,
		{"word":"greffée","word_nosc":"greffee","lemma":"greffé","pos":"ADJ"} ,
		{"word":"greffés","word_nosc":"greffes","lemma":"greffé","pos":"ADJ"} ,
		{"word":"grelottant","word_nosc":"grelottant","lemma":"grelottant","pos":"ADJ"} ,
		{"word":"grelottante","word_nosc":"grelottante","lemma":"grelottant","pos":"ADJ"} ,
		{"word":"grelottantes","word_nosc":"grelottantes","lemma":"grelottant","pos":"ADJ"} ,
		{"word":"grelottants","word_nosc":"grelottants","lemma":"grelottant","pos":"ADJ"} ,
		{"word":"grenadine","word_nosc":"grenadine","lemma":"grenadin","pos":"ADJ"} ,
		{"word":"grenat","word_nosc":"grenat","lemma":"grenat","pos":"ADJ"} ,
		{"word":"grenoblois","word_nosc":"grenoblois","lemma":"grenoblois","pos":"ADJ"} ,
		{"word":"grenouillard","word_nosc":"grenouillard","lemma":"grenouillard","pos":"ADJ"} ,
		{"word":"grenu","word_nosc":"grenu","lemma":"grenu","pos":"ADJ"} ,
		{"word":"grenue","word_nosc":"grenue","lemma":"grenu","pos":"ADJ"} ,
		{"word":"grenues","word_nosc":"grenues","lemma":"grenu","pos":"ADJ"} ,
		{"word":"grenus","word_nosc":"grenus","lemma":"grenu","pos":"ADJ"} ,
		{"word":"gribiche","word_nosc":"gribiche","lemma":"gribiche","pos":"ADJ"} ,
		{"word":"griffu","word_nosc":"griffu","lemma":"griffu","pos":"ADJ"} ,
		{"word":"griffue","word_nosc":"griffue","lemma":"griffu","pos":"ADJ"} ,
		{"word":"griffues","word_nosc":"griffues","lemma":"griffu","pos":"ADJ"} ,
		{"word":"griffus","word_nosc":"griffus","lemma":"griffu","pos":"ADJ"} ,
		{"word":"griffé","word_nosc":"griffe","lemma":"griffé","pos":"ADJ"} ,
		{"word":"griffée","word_nosc":"griffee","lemma":"griffé","pos":"ADJ"} ,
		{"word":"griffées","word_nosc":"griffees","lemma":"griffé","pos":"ADJ"} ,
		{"word":"griffés","word_nosc":"griffes","lemma":"griffé","pos":"ADJ"} ,
		{"word":"grignoteuses","word_nosc":"grignoteuses","lemma":"grignoteur","pos":"ADJ"} ,
		{"word":"grillagé","word_nosc":"grillage","lemma":"grillagé","pos":"ADJ"} ,
		{"word":"grillagée","word_nosc":"grillagee","lemma":"grillagé","pos":"ADJ"} ,
		{"word":"grillagées","word_nosc":"grillagees","lemma":"grillagé","pos":"ADJ"} ,
		{"word":"grillagés","word_nosc":"grillages","lemma":"grillagé","pos":"ADJ"} ,
		{"word":"grillé","word_nosc":"grille","lemma":"grillé","pos":"ADJ"} ,
		{"word":"grillée","word_nosc":"grillee","lemma":"grillé","pos":"ADJ"} ,
		{"word":"grillées","word_nosc":"grillees","lemma":"grillé","pos":"ADJ"} ,
		{"word":"grillés","word_nosc":"grilles","lemma":"grillé","pos":"ADJ"} ,
		{"word":"grimacier","word_nosc":"grimacier","lemma":"grimacier","pos":"ADJ"} ,
		{"word":"grimaciers","word_nosc":"grimaciers","lemma":"grimacier","pos":"ADJ"} ,
		{"word":"grimaçant","word_nosc":"grimacant","lemma":"grimaçant","pos":"ADJ"} ,
		{"word":"grimaçante","word_nosc":"grimacante","lemma":"grimaçant","pos":"ADJ"} ,
		{"word":"grimaçantes","word_nosc":"grimacantes","lemma":"grimaçant","pos":"ADJ"} ,
		{"word":"grimaçants","word_nosc":"grimacants","lemma":"grimaçant","pos":"ADJ"} ,
		{"word":"grimpant","word_nosc":"grimpant","lemma":"grimpant","pos":"ADJ"} ,
		{"word":"grimpante","word_nosc":"grimpante","lemma":"grimpant","pos":"ADJ"} ,
		{"word":"grimpantes","word_nosc":"grimpantes","lemma":"grimpant","pos":"ADJ"} ,
		{"word":"grimpants","word_nosc":"grimpants","lemma":"grimpant","pos":"ADJ"} ,
		{"word":"grimpeur","word_nosc":"grimpeur","lemma":"grimpeur","pos":"ADJ"} ,
		{"word":"grinche","word_nosc":"grinche","lemma":"grinche","pos":"ADJ"} ,
		{"word":"grincheuse","word_nosc":"grincheuse","lemma":"grincheux","pos":"ADJ"} ,
		{"word":"grincheuses","word_nosc":"grincheuses","lemma":"grincheux","pos":"ADJ"} ,
		{"word":"grincheux","word_nosc":"grincheux","lemma":"grincheux","pos":"ADJ"} ,
		{"word":"gringalet","word_nosc":"gringalet","lemma":"gringalet","pos":"ADJ"} ,
		{"word":"gringalets","word_nosc":"gringalets","lemma":"gringalet","pos":"ADJ"} ,
		{"word":"grinçant","word_nosc":"grincant","lemma":"grinçant","pos":"ADJ"} ,
		{"word":"grinçante","word_nosc":"grincante","lemma":"grinçant","pos":"ADJ"} ,
		{"word":"grinçantes","word_nosc":"grincantes","lemma":"grinçant","pos":"ADJ"} ,
		{"word":"grinçants","word_nosc":"grincants","lemma":"grinçant","pos":"ADJ"} ,
		{"word":"grippal","word_nosc":"grippal","lemma":"grippal","pos":"ADJ"} ,
		{"word":"grippaux","word_nosc":"grippaux","lemma":"grippal","pos":"ADJ"} ,
		{"word":"grippe-sou","word_nosc":"grippe-sou","lemma":"grippe-sou","pos":"ADJ"} ,
		{"word":"grippe-sous","word_nosc":"grippe-sous","lemma":"grippe-sou","pos":"ADJ"} ,
		{"word":"grippé","word_nosc":"grippe","lemma":"grippé","pos":"ADJ"} ,
		{"word":"grippée","word_nosc":"grippee","lemma":"grippé","pos":"ADJ"} ,
		{"word":"grippés","word_nosc":"grippes","lemma":"grippé","pos":"ADJ"} ,
		{"word":"gris","word_nosc":"gris","lemma":"gris","pos":"ADJ"} ,
		{"word":"gris-blanc","word_nosc":"gris-blanc","lemma":"gris-blanc","pos":"ADJ"} ,
		{"word":"gris-bleu","word_nosc":"gris-bleu","lemma":"gris-bleu","pos":"ADJ"} ,
		{"word":"gris-jaune","word_nosc":"gris-jaune","lemma":"gris-jaune","pos":"ADJ"} ,
		{"word":"gris-perle","word_nosc":"gris-perle","lemma":"gris-perle","pos":"ADJ"} ,
		{"word":"gris-rose","word_nosc":"gris-rose","lemma":"gris-rose","pos":"ADJ"} ,
		{"word":"gris-vert","word_nosc":"gris-vert","lemma":"gris-vert","pos":"ADJ"} ,
		{"word":"grisant","word_nosc":"grisant","lemma":"grisant","pos":"ADJ"} ,
		{"word":"grisante","word_nosc":"grisante","lemma":"grisant","pos":"ADJ"} ,
		{"word":"grisantes","word_nosc":"grisantes","lemma":"grisant","pos":"ADJ"} ,
		{"word":"grisants","word_nosc":"grisants","lemma":"grisant","pos":"ADJ"} ,
		{"word":"grise","word_nosc":"grise","lemma":"gris","pos":"ADJ"} ,
		{"word":"grises","word_nosc":"grises","lemma":"gris","pos":"ADJ"} ,
		{"word":"grisonnant","word_nosc":"grisonnant","lemma":"grisonnant","pos":"ADJ"} ,
		{"word":"grisonnante","word_nosc":"grisonnante","lemma":"grisonnant","pos":"ADJ"} ,
		{"word":"grisonnantes","word_nosc":"grisonnantes","lemma":"grisonnant","pos":"ADJ"} ,
		{"word":"grisonnants","word_nosc":"grisonnants","lemma":"grisonnant","pos":"ADJ"} ,
		{"word":"grisonne","word_nosc":"grisonne","lemma":"grison","pos":"ADJ"} ,
		{"word":"grisons","word_nosc":"grisons","lemma":"grison","pos":"ADJ"} ,
		{"word":"grisâtre","word_nosc":"grisatre","lemma":"grisâtre","pos":"ADJ"} ,
		{"word":"grisâtres","word_nosc":"grisatres","lemma":"grisâtre","pos":"ADJ"} ,
		{"word":"grisé","word_nosc":"grise","lemma":"grisé","pos":"ADJ"} ,
		{"word":"grisée","word_nosc":"grisee","lemma":"grisé","pos":"ADJ"} ,
		{"word":"grisées","word_nosc":"grisees","lemma":"grisé","pos":"ADJ"} ,
		{"word":"grisés","word_nosc":"grises","lemma":"grisé","pos":"ADJ"} ,
		{"word":"grivelée","word_nosc":"grivelee","lemma":"grivelé","pos":"ADJ"} ,
		{"word":"grivois","word_nosc":"grivois","lemma":"grivois","pos":"ADJ"} ,
		{"word":"grivoise","word_nosc":"grivoise","lemma":"grivois","pos":"ADJ"} ,
		{"word":"grivoises","word_nosc":"grivoises","lemma":"grivois","pos":"ADJ"} ,
		{"word":"groenlandais","word_nosc":"groenlandais","lemma":"groenlandais","pos":"ADJ"} ,
		{"word":"groggy","word_nosc":"groggy","lemma":"groggy","pos":"ADJ"} ,
		{"word":"grognant","word_nosc":"grognant","lemma":"grognant","pos":"ADJ"} ,
		{"word":"grogneurs","word_nosc":"grogneurs","lemma":"grogneur","pos":"ADJ"} ,
		{"word":"grogneuse","word_nosc":"grogneuse","lemma":"grogneur","pos":"ADJ"} ,
		{"word":"grognon","word_nosc":"grognon","lemma":"grognon","pos":"ADJ"} ,
		{"word":"grognonne","word_nosc":"grognonne","lemma":"grognon","pos":"ADJ"} ,
		{"word":"grognons","word_nosc":"grognons","lemma":"grognon","pos":"ADJ"} ,
		{"word":"grondant","word_nosc":"grondant","lemma":"grondant","pos":"ADJ"} ,
		{"word":"grondante","word_nosc":"grondante","lemma":"grondant","pos":"ADJ"} ,
		{"word":"grondants","word_nosc":"grondants","lemma":"grondant","pos":"ADJ"} ,
		{"word":"grondeur","word_nosc":"grondeur","lemma":"grondeur","pos":"ADJ"} ,
		{"word":"grondeurs","word_nosc":"grondeurs","lemma":"grondeur","pos":"ADJ"} ,
		{"word":"grondeuse","word_nosc":"grondeuse","lemma":"grondeur","pos":"ADJ"} ,
		{"word":"grondeuses","word_nosc":"grondeuses","lemma":"grondeur","pos":"ADJ"} ,
		{"word":"gros","word_nosc":"gros","lemma":"gros","pos":"ADJ"} ,
		{"word":"groseille","word_nosc":"groseille","lemma":"groseille","pos":"ADJ"} ,
		{"word":"grosse","word_nosc":"grosse","lemma":"gros","pos":"ADJ"} ,
		{"word":"grosses","word_nosc":"grosses","lemma":"gros","pos":"ADJ"} ,
		{"word":"grossi","word_nosc":"grossi","lemma":"grossi","pos":"ADJ"} ,
		{"word":"grossie","word_nosc":"grossie","lemma":"grossi","pos":"ADJ"} ,
		{"word":"grossier","word_nosc":"grossier","lemma":"grossier","pos":"ADJ"} ,
		{"word":"grossiers","word_nosc":"grossiers","lemma":"grossier","pos":"ADJ"} ,
		{"word":"grossis","word_nosc":"grossis","lemma":"grossi","pos":"ADJ"} ,
		{"word":"grossissant","word_nosc":"grossissant","lemma":"grossissant","pos":"ADJ"} ,
		{"word":"grossissante","word_nosc":"grossissante","lemma":"grossissant","pos":"ADJ"} ,
		{"word":"grossissantes","word_nosc":"grossissantes","lemma":"grossissant","pos":"ADJ"} ,
		{"word":"grossissants","word_nosc":"grossissants","lemma":"grossissant","pos":"ADJ"} ,
		{"word":"grossière","word_nosc":"grossiere","lemma":"grossier","pos":"ADJ"} ,
		{"word":"grossières","word_nosc":"grossieres","lemma":"grossier","pos":"ADJ"} ,
		{"word":"grotesque","word_nosc":"grotesque","lemma":"grotesque","pos":"ADJ"} ,
		{"word":"grotesques","word_nosc":"grotesques","lemma":"grotesque","pos":"ADJ"} ,
		{"word":"grouillant","word_nosc":"grouillant","lemma":"grouillant","pos":"ADJ"} ,
		{"word":"grouillante","word_nosc":"grouillante","lemma":"grouillant","pos":"ADJ"} ,
		{"word":"grouillantes","word_nosc":"grouillantes","lemma":"grouillant","pos":"ADJ"} ,
		{"word":"grouillants","word_nosc":"grouillants","lemma":"grouillant","pos":"ADJ"} ,
		{"word":"groupé","word_nosc":"groupe","lemma":"groupé","pos":"ADJ"} ,
		{"word":"groupée","word_nosc":"groupee","lemma":"groupé","pos":"ADJ"} ,
		{"word":"groupées","word_nosc":"groupees","lemma":"groupé","pos":"ADJ"} ,
		{"word":"groupés","word_nosc":"groupes","lemma":"groupé","pos":"ADJ"} ,
		{"word":"grumeleuse","word_nosc":"grumeleuse","lemma":"grumeleux","pos":"ADJ"} ,
		{"word":"grumeleuses","word_nosc":"grumeleuses","lemma":"grumeleux","pos":"ADJ"} ,
		{"word":"grumeleux","word_nosc":"grumeleux","lemma":"grumeleux","pos":"ADJ"} ,
		{"word":"grège","word_nosc":"grege","lemma":"grège","pos":"ADJ"} ,
		{"word":"grèges","word_nosc":"greges","lemma":"grège","pos":"ADJ"} ,
		{"word":"gréco-latine","word_nosc":"greco-latine","lemma":"gréco-latin","pos":"ADJ"} ,
		{"word":"gréco-latines","word_nosc":"greco-latines","lemma":"gréco-latin","pos":"ADJ"} ,
		{"word":"gréco-romain","word_nosc":"greco-romain","lemma":"gréco-romain","pos":"ADJ"} ,
		{"word":"gréco-romaine","word_nosc":"greco-romaine","lemma":"gréco-romain","pos":"ADJ"} ,
		{"word":"gréco-romaines","word_nosc":"greco-romaines","lemma":"gréco-romain","pos":"ADJ"} ,
		{"word":"gréco-romains","word_nosc":"greco-romains","lemma":"gréco-romain","pos":"ADJ"} ,
		{"word":"grégaire","word_nosc":"gregaire","lemma":"grégaire","pos":"ADJ"} ,
		{"word":"grégeois","word_nosc":"gregeois","lemma":"grégeois","pos":"ADJ"} ,
		{"word":"grégorien","word_nosc":"gregorien","lemma":"grégorien","pos":"ADJ"} ,
		{"word":"grégorienne","word_nosc":"gregorienne","lemma":"grégorien","pos":"ADJ"} ,
		{"word":"grégoriens","word_nosc":"gregoriens","lemma":"grégorien","pos":"ADJ"} ,
		{"word":"grésillant","word_nosc":"gresillant","lemma":"grésillant","pos":"ADJ"} ,
		{"word":"grésillante","word_nosc":"gresillante","lemma":"grésillant","pos":"ADJ"} ,
		{"word":"grésillantes","word_nosc":"gresillantes","lemma":"grésillant","pos":"ADJ"} ,
		{"word":"gréviste","word_nosc":"greviste","lemma":"gréviste","pos":"ADJ"} ,
		{"word":"grévistes","word_nosc":"grevistes","lemma":"gréviste","pos":"ADJ"} ,
		{"word":"grêle","word_nosc":"grele","lemma":"grêle","pos":"ADJ"} ,
		{"word":"grêles","word_nosc":"greles","lemma":"grêle","pos":"ADJ"} ,
		{"word":"grêlé","word_nosc":"grele","lemma":"grêlé","pos":"ADJ"} ,
		{"word":"grêlée","word_nosc":"grelee","lemma":"grêlé","pos":"ADJ"} ,
		{"word":"grêlés","word_nosc":"greles","lemma":"grêlé","pos":"ADJ"} ,
		{"word":"guarani","word_nosc":"guarani","lemma":"guarani","pos":"ADJ"} ,
		{"word":"guatémaltèque","word_nosc":"guatemalteque","lemma":"guatémaltèque","pos":"ADJ"} ,
		{"word":"guelfe","word_nosc":"guelfe","lemma":"guelfe","pos":"ADJ"} ,
		{"word":"guenilleux","word_nosc":"guenilleux","lemma":"guenilleux","pos":"ADJ"} ,
		{"word":"guerrier","word_nosc":"guerrier","lemma":"guerrier","pos":"ADJ"} ,
		{"word":"guerriers","word_nosc":"guerriers","lemma":"guerrier","pos":"ADJ"} ,
		{"word":"guerrière","word_nosc":"guerriere","lemma":"guerrier","pos":"ADJ"} ,
		{"word":"guerrières","word_nosc":"guerrieres","lemma":"guerrier","pos":"ADJ"} ,
		{"word":"gueulard","word_nosc":"gueulard","lemma":"gueulard","pos":"ADJ"} ,
		{"word":"gueularde","word_nosc":"gueularde","lemma":"gueulard","pos":"ADJ"} ,
		{"word":"gueulards","word_nosc":"gueulards","lemma":"gueulard","pos":"ADJ"} ,
		{"word":"guignolesque","word_nosc":"guignolesque","lemma":"guignolesque","pos":"ADJ"} ,
		{"word":"guilleret","word_nosc":"guilleret","lemma":"guilleret","pos":"ADJ"} ,
		{"word":"guillerets","word_nosc":"guillerets","lemma":"guilleret","pos":"ADJ"} ,
		{"word":"guillerette","word_nosc":"guillerette","lemma":"guilleret","pos":"ADJ"} ,
		{"word":"guillerettes","word_nosc":"guillerettes","lemma":"guilleret","pos":"ADJ"} ,
		{"word":"guillotiné","word_nosc":"guillotine","lemma":"guillotiné","pos":"ADJ"} ,
		{"word":"guillotinée","word_nosc":"guillotinee","lemma":"guillotiné","pos":"ADJ"} ,
		{"word":"guillotinées","word_nosc":"guillotinees","lemma":"guillotiné","pos":"ADJ"} ,
		{"word":"guillotinés","word_nosc":"guillotines","lemma":"guillotiné","pos":"ADJ"} ,
		{"word":"guindé","word_nosc":"guinde","lemma":"guindé","pos":"ADJ"} ,
		{"word":"guindée","word_nosc":"guindee","lemma":"guindé","pos":"ADJ"} ,
		{"word":"guindées","word_nosc":"guindees","lemma":"guindé","pos":"ADJ"} ,
		{"word":"guindés","word_nosc":"guindes","lemma":"guindé","pos":"ADJ"} ,
		{"word":"guinéen","word_nosc":"guineen","lemma":"guinéen","pos":"ADJ"} ,
		{"word":"guinéenne","word_nosc":"guineenne","lemma":"guinéen","pos":"ADJ"} ,
		{"word":"guinéennes","word_nosc":"guineennes","lemma":"guinéen","pos":"ADJ"} ,
		{"word":"guinéens","word_nosc":"guineens","lemma":"guinéen","pos":"ADJ"} ,
		{"word":"gustatif","word_nosc":"gustatif","lemma":"gustatif","pos":"ADJ"} ,
		{"word":"gustative","word_nosc":"gustative","lemma":"gustatif","pos":"ADJ"} ,
		{"word":"gustatives","word_nosc":"gustatives","lemma":"gustatif","pos":"ADJ"} ,
		{"word":"guttural","word_nosc":"guttural","lemma":"guttural","pos":"ADJ"} ,
		{"word":"gutturale","word_nosc":"gutturale","lemma":"guttural","pos":"ADJ"} ,
		{"word":"gutturales","word_nosc":"gutturales","lemma":"guttural","pos":"ADJ"} ,
		{"word":"gutturaux","word_nosc":"gutturaux","lemma":"guttural","pos":"ADJ"} ,
		{"word":"guyanais","word_nosc":"guyanais","lemma":"guyanais","pos":"ADJ"} ,
		{"word":"guéable","word_nosc":"gueable","lemma":"guéable","pos":"ADJ"} ,
		{"word":"guéri","word_nosc":"gueri","lemma":"guéri","pos":"ADJ"} ,
		{"word":"guérie","word_nosc":"guerie","lemma":"guéri","pos":"ADJ"} ,
		{"word":"guéries","word_nosc":"gueries","lemma":"guéri","pos":"ADJ"} ,
		{"word":"guéris","word_nosc":"gueris","lemma":"guéri","pos":"ADJ"} ,
		{"word":"guérissable","word_nosc":"guerissable","lemma":"guérissable","pos":"ADJ"} ,
		{"word":"guérissables","word_nosc":"guerissables","lemma":"guérissable","pos":"ADJ"} ,
		{"word":"guévariste","word_nosc":"guevariste","lemma":"guévariste","pos":"ADJ"} ,
		{"word":"guêtré","word_nosc":"guetre","lemma":"guêtré","pos":"ADJ"} ,
		{"word":"gymnique","word_nosc":"gymnique","lemma":"gymnique","pos":"ADJ"} ,
		{"word":"gymniques","word_nosc":"gymniques","lemma":"gymnique","pos":"ADJ"} ,
		{"word":"gynécologique","word_nosc":"gynecologique","lemma":"gynécologique","pos":"ADJ"} ,
		{"word":"gynécologiques","word_nosc":"gynecologiques","lemma":"gynécologique","pos":"ADJ"} ,
		{"word":"gypseuse","word_nosc":"gypseuse","lemma":"gypseux","pos":"ADJ"} ,
		{"word":"gypseux","word_nosc":"gypseux","lemma":"gypseux","pos":"ADJ"} ,
		{"word":"gyroscopique","word_nosc":"gyroscopique","lemma":"gyroscopique","pos":"ADJ"} ,
		{"word":"gâcheuse","word_nosc":"gacheuse","lemma":"gâcheur","pos":"ADJ"} ,
		{"word":"gâteuse","word_nosc":"gateuse","lemma":"gâteux","pos":"ADJ"} ,
		{"word":"gâteuses","word_nosc":"gateuses","lemma":"gâteux","pos":"ADJ"} ,
		{"word":"gâteux","word_nosc":"gateux","lemma":"gâteux","pos":"ADJ"} ,
		{"word":"gâté","word_nosc":"gate","lemma":"gâté","pos":"ADJ"} ,
		{"word":"gâtée","word_nosc":"gatee","lemma":"gâté","pos":"ADJ"} ,
		{"word":"gâtées","word_nosc":"gatees","lemma":"gâté","pos":"ADJ"} ,
		{"word":"gâtés","word_nosc":"gates","lemma":"gâté","pos":"ADJ"} ,
		{"word":"géant","word_nosc":"geant","lemma":"géant","pos":"ADJ"} ,
		{"word":"géante","word_nosc":"geante","lemma":"géant","pos":"ADJ"} ,
		{"word":"géantes","word_nosc":"geantes","lemma":"géant","pos":"ADJ"} ,
		{"word":"géants","word_nosc":"geants","lemma":"géant","pos":"ADJ"} ,
		{"word":"gélatineuse","word_nosc":"gelatineuse","lemma":"gélatineux","pos":"ADJ"} ,
		{"word":"gélatineuses","word_nosc":"gelatineuses","lemma":"gélatineux","pos":"ADJ"} ,
		{"word":"gélatineux","word_nosc":"gelatineux","lemma":"gélatineux","pos":"ADJ"} ,
		{"word":"gémeau","word_nosc":"gemeau","lemma":"gémeau","pos":"ADJ"} ,
		{"word":"gémeaux","word_nosc":"gemeaux","lemma":"gémeau","pos":"ADJ"} ,
		{"word":"gémellaire","word_nosc":"gemellaire","lemma":"gémellaire","pos":"ADJ"} ,
		{"word":"gémellaires","word_nosc":"gemellaires","lemma":"gémellaire","pos":"ADJ"} ,
		{"word":"gémissant","word_nosc":"gemissant","lemma":"gémissant","pos":"ADJ"} ,
		{"word":"gémissante","word_nosc":"gemissante","lemma":"gémissant","pos":"ADJ"} ,
		{"word":"gémissantes","word_nosc":"gemissantes","lemma":"gémissant","pos":"ADJ"} ,
		{"word":"gémissants","word_nosc":"gemissants","lemma":"gémissant","pos":"ADJ"} ,
		{"word":"gémisseur","word_nosc":"gemisseur","lemma":"gémisseur","pos":"ADJ"} ,
		{"word":"gémisseurs","word_nosc":"gemisseurs","lemma":"gémisseur","pos":"ADJ"} ,
		{"word":"génial","word_nosc":"genial","lemma":"génial","pos":"ADJ"} ,
		{"word":"géniale","word_nosc":"geniale","lemma":"génial","pos":"ADJ"} ,
		{"word":"géniales","word_nosc":"geniales","lemma":"génial","pos":"ADJ"} ,
		{"word":"géniaux","word_nosc":"geniaux","lemma":"génial","pos":"ADJ"} ,
		{"word":"génique","word_nosc":"genique","lemma":"génique","pos":"ADJ"} ,
		{"word":"génital","word_nosc":"genital","lemma":"génital","pos":"ADJ"} ,
		{"word":"génitale","word_nosc":"genitale","lemma":"génital","pos":"ADJ"} ,
		{"word":"génitales","word_nosc":"genitales","lemma":"génital","pos":"ADJ"} ,
		{"word":"génitaux","word_nosc":"genitaux","lemma":"génital","pos":"ADJ"} ,
		{"word":"génocidaire","word_nosc":"genocidaire","lemma":"génocidaire","pos":"ADJ"} ,
		{"word":"génois","word_nosc":"genois","lemma":"génois","pos":"ADJ"} ,
		{"word":"génomique","word_nosc":"genomique","lemma":"génomique","pos":"ADJ"} ,
		{"word":"génomiques","word_nosc":"genomiques","lemma":"génomique","pos":"ADJ"} ,
		{"word":"génuine","word_nosc":"genuine","lemma":"génuine","pos":"ADJ"} ,
		{"word":"généalogique","word_nosc":"genealogique","lemma":"généalogique","pos":"ADJ"} ,
		{"word":"généalogiques","word_nosc":"genealogiques","lemma":"généalogique","pos":"ADJ"} ,
		{"word":"général","word_nosc":"general","lemma":"général","pos":"ADJ"} ,
		{"word":"générale","word_nosc":"generale","lemma":"général","pos":"ADJ"} ,
		{"word":"générales","word_nosc":"generales","lemma":"général","pos":"ADJ"} ,
		{"word":"généralisable","word_nosc":"generalisable","lemma":"généralisable","pos":"ADJ"} ,
		{"word":"généraliste","word_nosc":"generaliste","lemma":"généraliste","pos":"ADJ"} ,
		{"word":"généralistes","word_nosc":"generalistes","lemma":"généraliste","pos":"ADJ"} ,
		{"word":"généralisé","word_nosc":"generalise","lemma":"généralisé","pos":"ADJ"} ,
		{"word":"généralisée","word_nosc":"generalisee","lemma":"généralisé","pos":"ADJ"} ,
		{"word":"généralisées","word_nosc":"generalisees","lemma":"généralisé","pos":"ADJ"} ,
		{"word":"générateur","word_nosc":"generateur","lemma":"générateur","pos":"ADJ"} ,
		{"word":"générateurs","word_nosc":"generateurs","lemma":"générateur","pos":"ADJ"} ,
		{"word":"génératif","word_nosc":"generatif","lemma":"génératif","pos":"ADJ"} ,
		{"word":"générationnel","word_nosc":"generationnel","lemma":"générationnel","pos":"ADJ"} ,
		{"word":"générative","word_nosc":"generative","lemma":"génératif","pos":"ADJ"} ,
		{"word":"génératrice","word_nosc":"generatrice","lemma":"générateur","pos":"ADJ"} ,
		{"word":"génératrices","word_nosc":"generatrices","lemma":"générateur","pos":"ADJ"} ,
		{"word":"généraux","word_nosc":"generaux","lemma":"général","pos":"ADJ"} ,
		{"word":"généreuse","word_nosc":"genereuse","lemma":"généreux","pos":"ADJ"} ,
		{"word":"généreuses","word_nosc":"genereuses","lemma":"généreux","pos":"ADJ"} ,
		{"word":"généreux","word_nosc":"genereux","lemma":"généreux","pos":"ADJ"} ,
		{"word":"générique","word_nosc":"generique","lemma":"générique","pos":"ADJ"} ,
		{"word":"génériques","word_nosc":"generiques","lemma":"générique","pos":"ADJ"} ,
		{"word":"généré","word_nosc":"genere","lemma":"généré","pos":"ADJ"} ,
		{"word":"générée","word_nosc":"generee","lemma":"généré","pos":"ADJ"} ,
		{"word":"générés","word_nosc":"generes","lemma":"généré","pos":"ADJ"} ,
		{"word":"génésique","word_nosc":"genesique","lemma":"génésique","pos":"ADJ"} ,
		{"word":"génétique","word_nosc":"genetique","lemma":"génétique","pos":"ADJ"} ,
		{"word":"génétiques","word_nosc":"genetiques","lemma":"génétique","pos":"ADJ"} ,
		{"word":"géodésiens","word_nosc":"geodesiens","lemma":"géodésien","pos":"ADJ"} ,
		{"word":"géodésique","word_nosc":"geodesique","lemma":"géodésique","pos":"ADJ"} ,
		{"word":"géodésiques","word_nosc":"geodesiques","lemma":"géodésique","pos":"ADJ"} ,
		{"word":"géographique","word_nosc":"geographique","lemma":"géographique","pos":"ADJ"} ,
		{"word":"géographiques","word_nosc":"geographiques","lemma":"géographique","pos":"ADJ"} ,
		{"word":"géologique","word_nosc":"geologique","lemma":"géologique","pos":"ADJ"} ,
		{"word":"géologiques","word_nosc":"geologiques","lemma":"géologique","pos":"ADJ"} ,
		{"word":"géomagnétique","word_nosc":"geomagnetique","lemma":"géomagnétique","pos":"ADJ"} ,
		{"word":"géomagnétiques","word_nosc":"geomagnetiques","lemma":"géomagnétique","pos":"ADJ"} ,
		{"word":"géométrique","word_nosc":"geometrique","lemma":"géométrique","pos":"ADJ"} ,
		{"word":"géométriques","word_nosc":"geometriques","lemma":"géométrique","pos":"ADJ"} ,
		{"word":"géophysique","word_nosc":"geophysique","lemma":"géophysique","pos":"ADJ"} ,
		{"word":"géopolitique","word_nosc":"geopolitique","lemma":"géopolitique","pos":"ADJ"} ,
		{"word":"géorgien","word_nosc":"georgien","lemma":"géorgien","pos":"ADJ"} ,
		{"word":"géorgienne","word_nosc":"georgienne","lemma":"géorgien","pos":"ADJ"} ,
		{"word":"géorgiennes","word_nosc":"georgiennes","lemma":"géorgien","pos":"ADJ"} ,
		{"word":"géorgiens","word_nosc":"georgiens","lemma":"géorgien","pos":"ADJ"} ,
		{"word":"géostationnaire","word_nosc":"geostationnaire","lemma":"géostationnaire","pos":"ADJ"} ,
		{"word":"géosynchrone","word_nosc":"geosynchrone","lemma":"géosynchrone","pos":"ADJ"} ,
		{"word":"géothermale","word_nosc":"geothermale","lemma":"géothermal","pos":"ADJ"} ,
		{"word":"géothermique","word_nosc":"geothermique","lemma":"géothermique","pos":"ADJ"} ,
		{"word":"géotropique","word_nosc":"geotropique","lemma":"géotropique","pos":"ADJ"} ,
		{"word":"géotropiques","word_nosc":"geotropiques","lemma":"géotropique","pos":"ADJ"} ,
		{"word":"gérable","word_nosc":"gerable","lemma":"gérable","pos":"ADJ"} ,
		{"word":"gérables","word_nosc":"gerables","lemma":"gérable","pos":"ADJ"} ,
		{"word":"gériatrique","word_nosc":"geriatrique","lemma":"gériatrique","pos":"ADJ"} ,
		{"word":"gériatriques","word_nosc":"geriatriques","lemma":"gériatrique","pos":"ADJ"} ,
		{"word":"gênant","word_nosc":"genant","lemma":"gênant","pos":"ADJ"} ,
		{"word":"gênante","word_nosc":"genante","lemma":"gênant","pos":"ADJ"} ,
		{"word":"gênantes","word_nosc":"genantes","lemma":"gênant","pos":"ADJ"} ,
		{"word":"gênants","word_nosc":"genants","lemma":"gênant","pos":"ADJ"} ,
		{"word":"gêné","word_nosc":"gene","lemma":"gêné","pos":"ADJ"} ,
		{"word":"gênée","word_nosc":"genee","lemma":"gêné","pos":"ADJ"} ,
		{"word":"gênées","word_nosc":"genees","lemma":"gêné","pos":"ADJ"} ,
		{"word":"gênés","word_nosc":"genes","lemma":"gêné","pos":"ADJ"} ,
		{"word":"gîte","word_nosc":"gite","lemma":"gîte","pos":"ADJ"} ,
		{"word":"habile","word_nosc":"habile","lemma":"habile","pos":"ADJ"} ,
		{"word":"habiles","word_nosc":"habiles","lemma":"habile","pos":"ADJ"} ,
		{"word":"habilité","word_nosc":"habilite","lemma":"habilité","pos":"ADJ"} ,
		{"word":"habilitée","word_nosc":"habilitee","lemma":"habilité","pos":"ADJ"} ,
		{"word":"habilitées","word_nosc":"habilitees","lemma":"habilité","pos":"ADJ"} ,
		{"word":"habilités","word_nosc":"habilites","lemma":"habilité","pos":"ADJ"} ,
		{"word":"habillé","word_nosc":"habille","lemma":"habillé","pos":"ADJ"} ,
		{"word":"habillée","word_nosc":"habillee","lemma":"habillé","pos":"ADJ"} ,
		{"word":"habillées","word_nosc":"habillees","lemma":"habillé","pos":"ADJ"} ,
		{"word":"habillés","word_nosc":"habilles","lemma":"habillé","pos":"ADJ"} ,
		{"word":"habitable","word_nosc":"habitable","lemma":"habitable","pos":"ADJ"} ,
		{"word":"habitables","word_nosc":"habitables","lemma":"habitable","pos":"ADJ"} ,
		{"word":"habituel","word_nosc":"habituel","lemma":"habituel","pos":"ADJ"} ,
		{"word":"habituelle","word_nosc":"habituelle","lemma":"habituel","pos":"ADJ"} ,
		{"word":"habituelles","word_nosc":"habituelles","lemma":"habituel","pos":"ADJ"} ,
		{"word":"habituels","word_nosc":"habituels","lemma":"habituel","pos":"ADJ"} ,
		{"word":"habitué","word_nosc":"habitue","lemma":"habitué","pos":"ADJ"} ,
		{"word":"habituée","word_nosc":"habituee","lemma":"habitué","pos":"ADJ"} ,
		{"word":"habituées","word_nosc":"habituees","lemma":"habitué","pos":"ADJ"} ,
		{"word":"habitués","word_nosc":"habitues","lemma":"habitué","pos":"ADJ"} ,
		{"word":"habité","word_nosc":"habite","lemma":"habité","pos":"ADJ"} ,
		{"word":"habitée","word_nosc":"habitee","lemma":"habité","pos":"ADJ"} ,
		{"word":"habitées","word_nosc":"habitees","lemma":"habité","pos":"ADJ"} ,
		{"word":"habités","word_nosc":"habites","lemma":"habité","pos":"ADJ"} ,
		{"word":"haché","word_nosc":"hache","lemma":"haché","pos":"ADJ"} ,
		{"word":"hachée","word_nosc":"hachee","lemma":"haché","pos":"ADJ"} ,
		{"word":"hachées","word_nosc":"hachees","lemma":"haché","pos":"ADJ"} ,
		{"word":"hachés","word_nosc":"haches","lemma":"haché","pos":"ADJ"} ,
		{"word":"hagard","word_nosc":"hagard","lemma":"hagard","pos":"ADJ"} ,
		{"word":"hagarde","word_nosc":"hagarde","lemma":"hagard","pos":"ADJ"} ,
		{"word":"hagardes","word_nosc":"hagardes","lemma":"hagard","pos":"ADJ"} ,
		{"word":"hagards","word_nosc":"hagards","lemma":"hagard","pos":"ADJ"} ,
		{"word":"haillonneuse","word_nosc":"haillonneuse","lemma":"haillonneux","pos":"ADJ"} ,
		{"word":"haillonneux","word_nosc":"haillonneux","lemma":"haillonneux","pos":"ADJ"} ,
		{"word":"haineuse","word_nosc":"haineuse","lemma":"haineux","pos":"ADJ"} ,
		{"word":"haineuses","word_nosc":"haineuses","lemma":"haineux","pos":"ADJ"} ,
		{"word":"haineux","word_nosc":"haineux","lemma":"haineux","pos":"ADJ"} ,
		{"word":"halal","word_nosc":"halal","lemma":"halal","pos":"ADJ"} ,
		{"word":"haletant","word_nosc":"haletant","lemma":"haletant","pos":"ADJ"} ,
		{"word":"haletante","word_nosc":"haletante","lemma":"haletant","pos":"ADJ"} ,
		{"word":"haletantes","word_nosc":"haletantes","lemma":"haletant","pos":"ADJ"} ,
		{"word":"haletants","word_nosc":"haletants","lemma":"haletant","pos":"ADJ"} ,
		{"word":"halieutiques","word_nosc":"halieutiques","lemma":"halieutique","pos":"ADJ"} ,
		{"word":"hallucinant","word_nosc":"hallucinant","lemma":"hallucinant","pos":"ADJ"} ,
		{"word":"hallucinante","word_nosc":"hallucinante","lemma":"hallucinant","pos":"ADJ"} ,
		{"word":"hallucinantes","word_nosc":"hallucinantes","lemma":"hallucinant","pos":"ADJ"} ,
		{"word":"hallucinants","word_nosc":"hallucinants","lemma":"hallucinant","pos":"ADJ"} ,
		{"word":"hallucinatoire","word_nosc":"hallucinatoire","lemma":"hallucinatoire","pos":"ADJ"} ,
		{"word":"hallucinatoires","word_nosc":"hallucinatoires","lemma":"hallucinatoire","pos":"ADJ"} ,
		{"word":"hallucinogène","word_nosc":"hallucinogene","lemma":"hallucinogène","pos":"ADJ"} ,
		{"word":"hallucinogènes","word_nosc":"hallucinogenes","lemma":"hallucinogène","pos":"ADJ"} ,
		{"word":"halluciné","word_nosc":"hallucine","lemma":"halluciné","pos":"ADJ"} ,
		{"word":"hallucinée","word_nosc":"hallucinee","lemma":"halluciné","pos":"ADJ"} ,
		{"word":"hallucinées","word_nosc":"hallucinees","lemma":"halluciné","pos":"ADJ"} ,
		{"word":"hallucinés","word_nosc":"hallucines","lemma":"halluciné","pos":"ADJ"} ,
		{"word":"halogène","word_nosc":"halogene","lemma":"halogène","pos":"ADJ"} ,
		{"word":"halogènes","word_nosc":"halogenes","lemma":"halogène","pos":"ADJ"} ,
		{"word":"hambourgeois","word_nosc":"hambourgeois","lemma":"hambourgeois","pos":"ADJ"} ,
		{"word":"han","word_nosc":"han","lemma":"han","pos":"ADJ"} ,
		{"word":"handicapant","word_nosc":"handicapant","lemma":"handicapant","pos":"ADJ"} ,
		{"word":"handicapé","word_nosc":"handicape","lemma":"handicapé","pos":"ADJ"} ,
		{"word":"handicapés","word_nosc":"handicapes","lemma":"handicapé","pos":"ADJ"} ,
		{"word":"hanovrien","word_nosc":"hanovrien","lemma":"hanovrien","pos":"ADJ"} ,
		{"word":"hanséatique","word_nosc":"hanseatique","lemma":"hanséatique","pos":"ADJ"} ,
		{"word":"hanteur","word_nosc":"hanteur","lemma":"hanteur","pos":"ADJ"} ,
		{"word":"hanté","word_nosc":"hante","lemma":"hanté","pos":"ADJ"} ,
		{"word":"hantée","word_nosc":"hantee","lemma":"hanté","pos":"ADJ"} ,
		{"word":"hantées","word_nosc":"hantees","lemma":"hanté","pos":"ADJ"} ,
		{"word":"hantés","word_nosc":"hantes","lemma":"hanté","pos":"ADJ"} ,
		{"word":"harassant","word_nosc":"harassant","lemma":"harassant","pos":"ADJ"} ,
		{"word":"harassante","word_nosc":"harassante","lemma":"harassant","pos":"ADJ"} ,
		{"word":"harassantes","word_nosc":"harassantes","lemma":"harassant","pos":"ADJ"} ,
		{"word":"harassants","word_nosc":"harassants","lemma":"harassant","pos":"ADJ"} ,
		{"word":"harassé","word_nosc":"harasse","lemma":"harassé","pos":"ADJ"} ,
		{"word":"harassée","word_nosc":"harassee","lemma":"harassé","pos":"ADJ"} ,
		{"word":"harassées","word_nosc":"harassees","lemma":"harassé","pos":"ADJ"} ,
		{"word":"harassés","word_nosc":"harasses","lemma":"harassé","pos":"ADJ"} ,
		{"word":"harcelant","word_nosc":"harcelant","lemma":"harcelant","pos":"ADJ"} ,
		{"word":"harcelante","word_nosc":"harcelante","lemma":"harcelant","pos":"ADJ"} ,
		{"word":"harcelantes","word_nosc":"harcelantes","lemma":"harcelant","pos":"ADJ"} ,
		{"word":"harcelants","word_nosc":"harcelants","lemma":"harcelant","pos":"ADJ"} ,
		{"word":"harceleur","word_nosc":"harceleur","lemma":"harceleur","pos":"ADJ"} ,
		{"word":"hard","word_nosc":"hard","lemma":"hard","pos":"ADJ"} ,
		{"word":"hardi","word_nosc":"hardi","lemma":"hardi","pos":"ADJ"} ,
		{"word":"hardie","word_nosc":"hardie","lemma":"hardi","pos":"ADJ"} ,
		{"word":"hardies","word_nosc":"hardies","lemma":"hardi","pos":"ADJ"} ,
		{"word":"hardis","word_nosc":"hardis","lemma":"hardi","pos":"ADJ"} ,
		{"word":"haret","word_nosc":"haret","lemma":"haret","pos":"ADJ"} ,
		{"word":"hargneuse","word_nosc":"hargneuse","lemma":"hargneux","pos":"ADJ"} ,
		{"word":"hargneuses","word_nosc":"hargneuses","lemma":"hargneux","pos":"ADJ"} ,
		{"word":"hargneux","word_nosc":"hargneux","lemma":"hargneux","pos":"ADJ"} ,
		{"word":"harmonieuse","word_nosc":"harmonieuse","lemma":"harmonieux","pos":"ADJ"} ,
		{"word":"harmonieuses","word_nosc":"harmonieuses","lemma":"harmonieux","pos":"ADJ"} ,
		{"word":"harmonieux","word_nosc":"harmonieux","lemma":"harmonieux","pos":"ADJ"} ,
		{"word":"harmonique","word_nosc":"harmonique","lemma":"harmonique","pos":"ADJ"} ,
		{"word":"harmoniques","word_nosc":"harmoniques","lemma":"harmonique","pos":"ADJ"} ,
		{"word":"harmonisateurs","word_nosc":"harmonisateurs","lemma":"harmonisateur","pos":"ADJ"} ,
		{"word":"harmonisatrices","word_nosc":"harmonisatrices","lemma":"harmonisateur","pos":"ADJ"} ,
		{"word":"harnaché","word_nosc":"harnache","lemma":"harnaché","pos":"ADJ"} ,
		{"word":"harnachée","word_nosc":"harnachee","lemma":"harnaché","pos":"ADJ"} ,
		{"word":"harnachées","word_nosc":"harnachees","lemma":"harnaché","pos":"ADJ"} ,
		{"word":"harnachés","word_nosc":"harnaches","lemma":"harnaché","pos":"ADJ"} ,
		{"word":"hasardeuse","word_nosc":"hasardeuse","lemma":"hasardeux","pos":"ADJ"} ,
		{"word":"hasardeuses","word_nosc":"hasardeuses","lemma":"hasardeux","pos":"ADJ"} ,
		{"word":"hasardeux","word_nosc":"hasardeux","lemma":"hasardeux","pos":"ADJ"} ,
		{"word":"hasardée","word_nosc":"hasardee","lemma":"hasardé","pos":"ADJ"} ,
		{"word":"hassidique","word_nosc":"hassidique","lemma":"hassidique","pos":"ADJ"} ,
		{"word":"haussier","word_nosc":"haussier","lemma":"haussier","pos":"ADJ"} ,
		{"word":"haussière","word_nosc":"haussiere","lemma":"haussier","pos":"ADJ"} ,
		{"word":"haut","word_nosc":"haut","lemma":"haut","pos":"ADJ"} ,
		{"word":"hautain","word_nosc":"hautain","lemma":"hautain","pos":"ADJ"} ,
		{"word":"hautaine","word_nosc":"hautaine","lemma":"hautain","pos":"ADJ"} ,
		{"word":"hautaines","word_nosc":"hautaines","lemma":"hautain","pos":"ADJ"} ,
		{"word":"hautains","word_nosc":"hautains","lemma":"hautain","pos":"ADJ"} ,
		{"word":"haute","word_nosc":"haute","lemma":"haut","pos":"ADJ"} ,
		{"word":"hautes","word_nosc":"hautes","lemma":"haut","pos":"ADJ"} ,
		{"word":"hauts","word_nosc":"hauts","lemma":"haut","pos":"ADJ"} ,
		{"word":"hauturière","word_nosc":"hauturiere","lemma":"hauturier","pos":"ADJ"} ,
		{"word":"havane","word_nosc":"havane","lemma":"havane","pos":"ADJ"} ,
		{"word":"havrais","word_nosc":"havrais","lemma":"havrais","pos":"ADJ"} ,
		{"word":"hawaiienne","word_nosc":"hawaiienne","lemma":"hawaiien","pos":"ADJ"} ,
		{"word":"hawaiiennes","word_nosc":"hawaiiennes","lemma":"hawaiien","pos":"ADJ"} ,
		{"word":"hawaïen","word_nosc":"hawaien","lemma":"hawaïen","pos":"ADJ"} ,
		{"word":"hawaïenne","word_nosc":"hawaienne","lemma":"hawaïen","pos":"ADJ"} ,
		{"word":"haï","word_nosc":"hai","lemma":"haï","pos":"ADJ"} ,
		{"word":"haïe","word_nosc":"haie","lemma":"haï","pos":"ADJ"} ,
		{"word":"haïs","word_nosc":"hais","lemma":"haï","pos":"ADJ"} ,
		{"word":"haïssable","word_nosc":"haissable","lemma":"haïssable","pos":"ADJ"} ,
		{"word":"haïssables","word_nosc":"haissables","lemma":"haïssable","pos":"ADJ"} ,
		{"word":"haïtien","word_nosc":"haitien","lemma":"haïtien","pos":"ADJ"} ,
		{"word":"haïtienne","word_nosc":"haitienne","lemma":"haïtien","pos":"ADJ"} ,
		{"word":"haïtiens","word_nosc":"haitiens","lemma":"haïtien","pos":"ADJ"} ,
		{"word":"hebdomadaire","word_nosc":"hebdomadaire","lemma":"hebdomadaire","pos":"ADJ"} ,
		{"word":"hebdomadaires","word_nosc":"hebdomadaires","lemma":"hebdomadaire","pos":"ADJ"} ,
		{"word":"hellène","word_nosc":"hellene","lemma":"hellène","pos":"ADJ"} ,
		{"word":"hellènes","word_nosc":"hellenes","lemma":"hellène","pos":"ADJ"} ,
		{"word":"hellénique","word_nosc":"hellenique","lemma":"hellénique","pos":"ADJ"} ,
		{"word":"helléniques","word_nosc":"helleniques","lemma":"hellénique","pos":"ADJ"} ,
		{"word":"hellénistique","word_nosc":"hellenistique","lemma":"hellénistique","pos":"ADJ"} ,
		{"word":"hellénistiques","word_nosc":"hellenistiques","lemma":"hellénistique","pos":"ADJ"} ,
		{"word":"helvète","word_nosc":"helvete","lemma":"helvète","pos":"ADJ"} ,
		{"word":"helvètes","word_nosc":"helvetes","lemma":"helvète","pos":"ADJ"} ,
		{"word":"helvétien","word_nosc":"helvetien","lemma":"helvétien","pos":"ADJ"} ,
		{"word":"helvétique","word_nosc":"helvetique","lemma":"helvétique","pos":"ADJ"} ,
		{"word":"helvétiques","word_nosc":"helvetiques","lemma":"helvétique","pos":"ADJ"} ,
		{"word":"hennissant","word_nosc":"hennissant","lemma":"hennissant","pos":"ADJ"} ,
		{"word":"hennissantes","word_nosc":"hennissantes","lemma":"hennissant","pos":"ADJ"} ,
		{"word":"herbeuse","word_nosc":"herbeuse","lemma":"herbeux","pos":"ADJ"} ,
		{"word":"herbeuses","word_nosc":"herbeuses","lemma":"herbeux","pos":"ADJ"} ,
		{"word":"herbeux","word_nosc":"herbeux","lemma":"herbeux","pos":"ADJ"} ,
		{"word":"herbicide","word_nosc":"herbicide","lemma":"herbicide","pos":"ADJ"} ,
		{"word":"herbivore","word_nosc":"herbivore","lemma":"herbivore","pos":"ADJ"} ,
		{"word":"herbivores","word_nosc":"herbivores","lemma":"herbivore","pos":"ADJ"} ,
		{"word":"herbu","word_nosc":"herbu","lemma":"herbu","pos":"ADJ"} ,
		{"word":"herbue","word_nosc":"herbue","lemma":"herbu","pos":"ADJ"} ,
		{"word":"herbues","word_nosc":"herbues","lemma":"herbu","pos":"ADJ"} ,
		{"word":"herbus","word_nosc":"herbus","lemma":"herbu","pos":"ADJ"} ,
		{"word":"herculéen","word_nosc":"herculeen","lemma":"herculéen","pos":"ADJ"} ,
		{"word":"herculéenne","word_nosc":"herculeenne","lemma":"herculéen","pos":"ADJ"} ,
		{"word":"herculéennes","word_nosc":"herculeennes","lemma":"herculéen","pos":"ADJ"} ,
		{"word":"herculéens","word_nosc":"herculeens","lemma":"herculéen","pos":"ADJ"} ,
		{"word":"hercynien","word_nosc":"hercynien","lemma":"hercynien","pos":"ADJ"} ,
		{"word":"hercyniennes","word_nosc":"hercyniennes","lemma":"hercynien","pos":"ADJ"} ,
		{"word":"hermaphrodite","word_nosc":"hermaphrodite","lemma":"hermaphrodite","pos":"ADJ"} ,
		{"word":"hermaphrodites","word_nosc":"hermaphrodites","lemma":"hermaphrodite","pos":"ADJ"} ,
		{"word":"herminé","word_nosc":"hermine","lemma":"herminé","pos":"ADJ"} ,
		{"word":"herméneutique","word_nosc":"hermeneutique","lemma":"herméneutique","pos":"ADJ"} ,
		{"word":"hermétique","word_nosc":"hermetique","lemma":"hermétique","pos":"ADJ"} ,
		{"word":"hermétiques","word_nosc":"hermetiques","lemma":"hermétique","pos":"ADJ"} ,
		{"word":"herniaire","word_nosc":"herniaire","lemma":"herniaire","pos":"ADJ"} ,
		{"word":"herniaires","word_nosc":"herniaires","lemma":"herniaire","pos":"ADJ"} ,
		{"word":"hernieux","word_nosc":"hernieux","lemma":"hernieux","pos":"ADJ"} ,
		{"word":"herpétique","word_nosc":"herpetique","lemma":"herpétique","pos":"ADJ"} ,
		{"word":"hertzienne","word_nosc":"hertzienne","lemma":"hertzien","pos":"ADJ"} ,
		{"word":"hertziennes","word_nosc":"hertziennes","lemma":"hertzien","pos":"ADJ"} ,
		{"word":"hertziens","word_nosc":"hertziens","lemma":"hertzien","pos":"ADJ"} ,
		{"word":"hessois","word_nosc":"hessois","lemma":"hessois","pos":"ADJ"} ,
		{"word":"hessoise","word_nosc":"hessoise","lemma":"hessois","pos":"ADJ"} ,
		{"word":"heureuse","word_nosc":"heureuse","lemma":"heureux","pos":"ADJ"} ,
		{"word":"heureuses","word_nosc":"heureuses","lemma":"heureux","pos":"ADJ"} ,
		{"word":"heureux","word_nosc":"heureux","lemma":"heureux","pos":"ADJ"} ,
		{"word":"heuristique","word_nosc":"heuristique","lemma":"heuristique","pos":"ADJ"} ,
		{"word":"heurté","word_nosc":"heurte","lemma":"heurté","pos":"ADJ"} ,
		{"word":"heurtée","word_nosc":"heurtee","lemma":"heurté","pos":"ADJ"} ,
		{"word":"heurtées","word_nosc":"heurtees","lemma":"heurté","pos":"ADJ"} ,
		{"word":"heurtés","word_nosc":"heurtes","lemma":"heurté","pos":"ADJ"} ,
		{"word":"hexagonal","word_nosc":"hexagonal","lemma":"hexagonal","pos":"ADJ"} ,
		{"word":"hexagonale","word_nosc":"hexagonale","lemma":"hexagonal","pos":"ADJ"} ,
		{"word":"hexagonales","word_nosc":"hexagonales","lemma":"hexagonal","pos":"ADJ"} ,
		{"word":"hexagonaux","word_nosc":"hexagonaux","lemma":"hexagonal","pos":"ADJ"} ,
		{"word":"hiatale","word_nosc":"hiatale","lemma":"hiatal","pos":"ADJ"} ,
		{"word":"hideuse","word_nosc":"hideuse","lemma":"hideux","pos":"ADJ"} ,
		{"word":"hideuses","word_nosc":"hideuses","lemma":"hideux","pos":"ADJ"} ,
		{"word":"hideux","word_nosc":"hideux","lemma":"hideux","pos":"ADJ"} ,
		{"word":"high tech","word_nosc":"high tech","lemma":"high-tech","pos":"ADJ"} ,
		{"word":"high-tech","word_nosc":"high-tech","lemma":"high-tech","pos":"ADJ"} ,
		{"word":"hilaire","word_nosc":"hilaire","lemma":"hilaire","pos":"ADJ"} ,
		{"word":"hilarant","word_nosc":"hilarant","lemma":"hilarant","pos":"ADJ"} ,
		{"word":"hilarante","word_nosc":"hilarante","lemma":"hilarant","pos":"ADJ"} ,
		{"word":"hilarantes","word_nosc":"hilarantes","lemma":"hilarant","pos":"ADJ"} ,
		{"word":"hilarants","word_nosc":"hilarants","lemma":"hilarant","pos":"ADJ"} ,
		{"word":"hilare","word_nosc":"hilare","lemma":"hilare","pos":"ADJ"} ,
		{"word":"hilares","word_nosc":"hilares","lemma":"hilare","pos":"ADJ"} ,
		{"word":"himalayenne","word_nosc":"himalayenne","lemma":"himalayen","pos":"ADJ"} ,
		{"word":"himalayens","word_nosc":"himalayens","lemma":"himalayen","pos":"ADJ"} ,
		{"word":"hindou","word_nosc":"hindou","lemma":"hindou","pos":"ADJ"} ,
		{"word":"hindoue","word_nosc":"hindoue","lemma":"hindou","pos":"ADJ"} ,
		{"word":"hindoues","word_nosc":"hindoues","lemma":"hindou","pos":"ADJ"} ,
		{"word":"hindous","word_nosc":"hindous","lemma":"hindou","pos":"ADJ"} ,
		{"word":"hippie","word_nosc":"hippie","lemma":"hippie","pos":"ADJ"} ,
		{"word":"hippies","word_nosc":"hippies","lemma":"hippie","pos":"ADJ"} ,
		{"word":"hippique","word_nosc":"hippique","lemma":"hippique","pos":"ADJ"} ,
		{"word":"hippiques","word_nosc":"hippiques","lemma":"hippique","pos":"ADJ"} ,
		{"word":"hippocratique","word_nosc":"hippocratique","lemma":"hippocratique","pos":"ADJ"} ,
		{"word":"hippomobile","word_nosc":"hippomobile","lemma":"hippomobile","pos":"ADJ"} ,
		{"word":"hippophagique","word_nosc":"hippophagique","lemma":"hippophagique","pos":"ADJ"} ,
		{"word":"hippophagiques","word_nosc":"hippophagiques","lemma":"hippophagique","pos":"ADJ"} ,
		{"word":"hippopotamesque","word_nosc":"hippopotamesque","lemma":"hippopotamesque","pos":"ADJ"} ,
		{"word":"hippy","word_nosc":"hippy","lemma":"hippy","pos":"ADJ"} ,
		{"word":"hirsute","word_nosc":"hirsute","lemma":"hirsute","pos":"ADJ"} ,
		{"word":"hirsutes","word_nosc":"hirsutes","lemma":"hirsute","pos":"ADJ"} ,
		{"word":"hispanique","word_nosc":"hispanique","lemma":"hispanique","pos":"ADJ"} ,
		{"word":"hispaniques","word_nosc":"hispaniques","lemma":"hispanique","pos":"ADJ"} ,
		{"word":"hispanisante","word_nosc":"hispanisante","lemma":"hispanisant","pos":"ADJ"} ,
		{"word":"hispanisants","word_nosc":"hispanisants","lemma":"hispanisant","pos":"ADJ"} ,
		{"word":"hispano-américain","word_nosc":"hispano-americain","lemma":"hispano-américain","pos":"ADJ"} ,
		{"word":"hispano-américaine","word_nosc":"hispano-americaine","lemma":"hispano-américain","pos":"ADJ"} ,
		{"word":"hispano-cubain","word_nosc":"hispano-cubain","lemma":"hispano-cubain","pos":"ADJ"} ,
		{"word":"hispano-mauresque","word_nosc":"hispano-mauresque","lemma":"hispano-mauresque","pos":"ADJ"} ,
		{"word":"histaminique","word_nosc":"histaminique","lemma":"histaminique","pos":"ADJ"} ,
		{"word":"histocompatibles","word_nosc":"histocompatibles","lemma":"histocompatible","pos":"ADJ"} ,
		{"word":"histologique","word_nosc":"histologique","lemma":"histologique","pos":"ADJ"} ,
		{"word":"historico-culturelle","word_nosc":"historico-culturelle","lemma":"historico-culturel","pos":"ADJ"} ,
		{"word":"historique","word_nosc":"historique","lemma":"historique","pos":"ADJ"} ,
		{"word":"historiques","word_nosc":"historiques","lemma":"historique","pos":"ADJ"} ,
		{"word":"historiée","word_nosc":"historiee","lemma":"historié","pos":"ADJ"} ,
		{"word":"historiés","word_nosc":"histories","lemma":"historié","pos":"ADJ"} ,
		{"word":"hitchcockien","word_nosc":"hitchcockien","lemma":"hitchcockien","pos":"ADJ"} ,
		{"word":"hitlérien","word_nosc":"hitlerien","lemma":"hitlérien","pos":"ADJ"} ,
		{"word":"hitlérienne","word_nosc":"hitlerienne","lemma":"hitlérien","pos":"ADJ"} ,
		{"word":"hitlériennes","word_nosc":"hitleriennes","lemma":"hitlérien","pos":"ADJ"} ,
		{"word":"hitlériens","word_nosc":"hitleriens","lemma":"hitlérien","pos":"ADJ"} ,
		{"word":"hittite","word_nosc":"hittite","lemma":"hittite","pos":"ADJ"} ,
		{"word":"hittites","word_nosc":"hittites","lemma":"hittite","pos":"ADJ"} ,
		{"word":"hiv","word_nosc":"hiv","lemma":"hiv","pos":"ADJ"} ,
		{"word":"hivernal","word_nosc":"hivernal","lemma":"hivernal","pos":"ADJ"} ,
		{"word":"hivernale","word_nosc":"hivernale","lemma":"hivernal","pos":"ADJ"} ,
		{"word":"hivernales","word_nosc":"hivernales","lemma":"hivernal","pos":"ADJ"} ,
		{"word":"hivernant","word_nosc":"hivernant","lemma":"hivernant","pos":"ADJ"} ,
		{"word":"hivernante","word_nosc":"hivernante","lemma":"hivernant","pos":"ADJ"} ,
		{"word":"hivernantes","word_nosc":"hivernantes","lemma":"hivernant","pos":"ADJ"} ,
		{"word":"hivernaux","word_nosc":"hivernaux","lemma":"hivernal","pos":"ADJ"} ,
		{"word":"hiérarchique","word_nosc":"hierarchique","lemma":"hiérarchique","pos":"ADJ"} ,
		{"word":"hiérarchiques","word_nosc":"hierarchiques","lemma":"hiérarchique","pos":"ADJ"} ,
		{"word":"hiérarchisé","word_nosc":"hierarchise","lemma":"hiérarchisé","pos":"ADJ"} ,
		{"word":"hiérarchisée","word_nosc":"hierarchisee","lemma":"hiérarchisé","pos":"ADJ"} ,
		{"word":"hiérarchisés","word_nosc":"hierarchises","lemma":"hiérarchisé","pos":"ADJ"} ,
		{"word":"hiératique","word_nosc":"hieratique","lemma":"hiératique","pos":"ADJ"} ,
		{"word":"hiératiques","word_nosc":"hieratiques","lemma":"hiératique","pos":"ADJ"} ,
		{"word":"hiéroglyphique","word_nosc":"hieroglyphique","lemma":"hiéroglyphique","pos":"ADJ"} ,
		{"word":"hiéroglyphiques","word_nosc":"hieroglyphiques","lemma":"hiéroglyphique","pos":"ADJ"} ,
		{"word":"hodgkinien","word_nosc":"hodgkinien","lemma":"hodgkinien","pos":"ADJ"} ,
		{"word":"holistique","word_nosc":"holistique","lemma":"holistique","pos":"ADJ"} ,
		{"word":"holistiques","word_nosc":"holistiques","lemma":"holistique","pos":"ADJ"} ,
		{"word":"hollandais","word_nosc":"hollandais","lemma":"hollandais","pos":"ADJ"} ,
		{"word":"hollandaise","word_nosc":"hollandaise","lemma":"hollandais","pos":"ADJ"} ,
		{"word":"hollandaises","word_nosc":"hollandaises","lemma":"hollandais","pos":"ADJ"} ,
		{"word":"hollywoodien","word_nosc":"hollywoodien","lemma":"hollywoodien","pos":"ADJ"} ,
		{"word":"hollywoodienne","word_nosc":"hollywoodienne","lemma":"hollywoodien","pos":"ADJ"} ,
		{"word":"hollywoodiennes","word_nosc":"hollywoodiennes","lemma":"hollywoodien","pos":"ADJ"} ,
		{"word":"hollywoodiens","word_nosc":"hollywoodiens","lemma":"hollywoodien","pos":"ADJ"} ,
		{"word":"holographe","word_nosc":"holographe","lemma":"holographe","pos":"ADJ"} ,
		{"word":"holographes","word_nosc":"holographes","lemma":"holographe","pos":"ADJ"} ,
		{"word":"holographique","word_nosc":"holographique","lemma":"holographique","pos":"ADJ"} ,
		{"word":"holographiques","word_nosc":"holographiques","lemma":"holographique","pos":"ADJ"} ,
		{"word":"holoèdres","word_nosc":"holoedres","lemma":"holoèdre","pos":"ADJ"} ,
		{"word":"homicide","word_nosc":"homicide","lemma":"homicide","pos":"ADJ"} ,
		{"word":"homicides","word_nosc":"homicides","lemma":"homicide","pos":"ADJ"} ,
		{"word":"hommasse","word_nosc":"hommasse","lemma":"hommasse","pos":"ADJ"} ,
		{"word":"hommasses","word_nosc":"hommasses","lemma":"hommasse","pos":"ADJ"} ,
		{"word":"homo","word_nosc":"homo","lemma":"homo","pos":"ADJ"} ,
		{"word":"homogène","word_nosc":"homogene","lemma":"homogène","pos":"ADJ"} ,
		{"word":"homogènes","word_nosc":"homogenes","lemma":"homogène","pos":"ADJ"} ,
		{"word":"homogénéisé","word_nosc":"homogeneise","lemma":"homogénéisé","pos":"ADJ"} ,
		{"word":"homogénéisée","word_nosc":"homogeneisee","lemma":"homogénéisé","pos":"ADJ"} ,
		{"word":"homologable","word_nosc":"homologable","lemma":"homologable","pos":"ADJ"} ,
		{"word":"homologue","word_nosc":"homologue","lemma":"homologue","pos":"ADJ"} ,
		{"word":"homologues","word_nosc":"homologues","lemma":"homologue","pos":"ADJ"} ,
		{"word":"homologué","word_nosc":"homologue","lemma":"homologué","pos":"ADJ"} ,
		{"word":"homologuée","word_nosc":"homologuee","lemma":"homologué","pos":"ADJ"} ,
		{"word":"homologués","word_nosc":"homologues","lemma":"homologué","pos":"ADJ"} ,
		{"word":"homonyme","word_nosc":"homonyme","lemma":"homonyme","pos":"ADJ"} ,
		{"word":"homonymique","word_nosc":"homonymique","lemma":"homonymique","pos":"ADJ"} ,
		{"word":"homophobe","word_nosc":"homophobe","lemma":"homophobe","pos":"ADJ"} ,
		{"word":"homophobes","word_nosc":"homophobes","lemma":"homophobe","pos":"ADJ"} ,
		{"word":"homos","word_nosc":"homos","lemma":"homo","pos":"ADJ"} ,
		{"word":"homosexuel","word_nosc":"homosexuel","lemma":"homosexuel","pos":"ADJ"} ,
		{"word":"homosexuelle","word_nosc":"homosexuelle","lemma":"homosexuel","pos":"ADJ"} ,
		{"word":"homosexuelles","word_nosc":"homosexuelles","lemma":"homosexuel","pos":"ADJ"} ,
		{"word":"homosexuels","word_nosc":"homosexuels","lemma":"homosexuel","pos":"ADJ"} ,
		{"word":"homozygote","word_nosc":"homozygote","lemma":"homozygote","pos":"ADJ"} ,
		{"word":"homéopathe","word_nosc":"homeopathe","lemma":"homéopathe","pos":"ADJ"} ,
		{"word":"homéopathique","word_nosc":"homeopathique","lemma":"homéopathique","pos":"ADJ"} ,
		{"word":"homéopathiques","word_nosc":"homeopathiques","lemma":"homéopathique","pos":"ADJ"} ,
		{"word":"homéostatique","word_nosc":"homeostatique","lemma":"homéostatique","pos":"ADJ"} ,
		{"word":"homérique","word_nosc":"homerique","lemma":"homérique","pos":"ADJ"} ,
		{"word":"homériques","word_nosc":"homeriques","lemma":"homérique","pos":"ADJ"} ,
		{"word":"hondurien","word_nosc":"hondurien","lemma":"hondurien","pos":"ADJ"} ,
		{"word":"hongre","word_nosc":"hongre","lemma":"hongre","pos":"ADJ"} ,
		{"word":"hongres","word_nosc":"hongres","lemma":"hongre","pos":"ADJ"} ,
		{"word":"hongrois","word_nosc":"hongrois","lemma":"hongrois","pos":"ADJ"} ,
		{"word":"hongroise","word_nosc":"hongroise","lemma":"hongrois","pos":"ADJ"} ,
		{"word":"hongroises","word_nosc":"hongroises","lemma":"hongrois","pos":"ADJ"} ,
		{"word":"honni","word_nosc":"honni","lemma":"honni","pos":"ADJ"} ,
		{"word":"honnie","word_nosc":"honnie","lemma":"honni","pos":"ADJ"} ,
		{"word":"honnis","word_nosc":"honnis","lemma":"honni","pos":"ADJ"} ,
		{"word":"honnête","word_nosc":"honnete","lemma":"honnête","pos":"ADJ"} ,
		{"word":"honnêtes","word_nosc":"honnetes","lemma":"honnête","pos":"ADJ"} ,
		{"word":"honorable","word_nosc":"honorable","lemma":"honorable","pos":"ADJ"} ,
		{"word":"honorables","word_nosc":"honorables","lemma":"honorable","pos":"ADJ"} ,
		{"word":"honoraire","word_nosc":"honoraire","lemma":"honoraire","pos":"ADJ"} ,
		{"word":"honoraires","word_nosc":"honoraires","lemma":"honoraire","pos":"ADJ"} ,
		{"word":"honorifique","word_nosc":"honorifique","lemma":"honorifique","pos":"ADJ"} ,
		{"word":"honorifiques","word_nosc":"honorifiques","lemma":"honorifique","pos":"ADJ"} ,
		{"word":"honoré","word_nosc":"honore","lemma":"honoré","pos":"ADJ"} ,
		{"word":"honorée","word_nosc":"honoree","lemma":"honoré","pos":"ADJ"} ,
		{"word":"honorés","word_nosc":"honores","lemma":"honoré","pos":"ADJ"} ,
		{"word":"honteuse","word_nosc":"honteuse","lemma":"honteux","pos":"ADJ"} ,
		{"word":"honteuses","word_nosc":"honteuses","lemma":"honteux","pos":"ADJ"} ,
		{"word":"honteux","word_nosc":"honteux","lemma":"honteux","pos":"ADJ"} ,
		{"word":"hopi","word_nosc":"hopi","lemma":"hopi","pos":"ADJ"} ,
		{"word":"hopis","word_nosc":"hopis","lemma":"hopi","pos":"ADJ"} ,
		{"word":"horaire","word_nosc":"horaire","lemma":"horaire","pos":"ADJ"} ,
		{"word":"horaires","word_nosc":"horaires","lemma":"horaire","pos":"ADJ"} ,
		{"word":"horizontal","word_nosc":"horizontal","lemma":"horizontal","pos":"ADJ"} ,
		{"word":"horizontale","word_nosc":"horizontale","lemma":"horizontal","pos":"ADJ"} ,
		{"word":"horizontales","word_nosc":"horizontales","lemma":"horizontal","pos":"ADJ"} ,
		{"word":"horizontaux","word_nosc":"horizontaux","lemma":"horizontal","pos":"ADJ"} ,
		{"word":"horloger","word_nosc":"horloger","lemma":"horloger","pos":"ADJ"} ,
		{"word":"horlogers","word_nosc":"horlogers","lemma":"horloger","pos":"ADJ"} ,
		{"word":"horlogère","word_nosc":"horlogere","lemma":"horloger","pos":"ADJ"} ,
		{"word":"hormonal","word_nosc":"hormonal","lemma":"hormonal","pos":"ADJ"} ,
		{"word":"hormonale","word_nosc":"hormonale","lemma":"hormonal","pos":"ADJ"} ,
		{"word":"hormonales","word_nosc":"hormonales","lemma":"hormonal","pos":"ADJ"} ,
		{"word":"hormonaux","word_nosc":"hormonaux","lemma":"hormonal","pos":"ADJ"} ,
		{"word":"horrible","word_nosc":"horrible","lemma":"horrible","pos":"ADJ"} ,
		{"word":"horribles","word_nosc":"horribles","lemma":"horrible","pos":"ADJ"} ,
		{"word":"horrifiant","word_nosc":"horrifiant","lemma":"horrifiant","pos":"ADJ"} ,
		{"word":"horrifiante","word_nosc":"horrifiante","lemma":"horrifiant","pos":"ADJ"} ,
		{"word":"horrifiants","word_nosc":"horrifiants","lemma":"horrifiant","pos":"ADJ"} ,
		{"word":"horrifique","word_nosc":"horrifique","lemma":"horrifique","pos":"ADJ"} ,
		{"word":"horrifié","word_nosc":"horrifie","lemma":"horrifié","pos":"ADJ"} ,
		{"word":"horrifiée","word_nosc":"horrifiee","lemma":"horrifié","pos":"ADJ"} ,
		{"word":"horrifiées","word_nosc":"horrifiees","lemma":"horrifié","pos":"ADJ"} ,
		{"word":"horrifiés","word_nosc":"horrifies","lemma":"horrifié","pos":"ADJ"} ,
		{"word":"horripilant","word_nosc":"horripilant","lemma":"horripilant","pos":"ADJ"} ,
		{"word":"horripilante","word_nosc":"horripilante","lemma":"horripilant","pos":"ADJ"} ,
		{"word":"horripilantes","word_nosc":"horripilantes","lemma":"horripilant","pos":"ADJ"} ,
		{"word":"horripilants","word_nosc":"horripilants","lemma":"horripilant","pos":"ADJ"} ,
		{"word":"hors-bord","word_nosc":"hors-bord","lemma":"hors-bord","pos":"ADJ"} ,
		{"word":"hors-cote","word_nosc":"hors-cote","lemma":"hors-cote","pos":"ADJ"} ,
		{"word":"hors-jeu","word_nosc":"hors-jeu","lemma":"hors-jeu","pos":"ADJ"} ,
		{"word":"hors-série","word_nosc":"hors-serie","lemma":"hors-série","pos":"ADJ"} ,
		{"word":"horticoles","word_nosc":"horticoles","lemma":"horticole","pos":"ADJ"} ,
		{"word":"hospitalier","word_nosc":"hospitalier","lemma":"hospitalier","pos":"ADJ"} ,
		{"word":"hospitaliers","word_nosc":"hospitaliers","lemma":"hospitalier","pos":"ADJ"} ,
		{"word":"hospitalière","word_nosc":"hospitaliere","lemma":"hospitalier","pos":"ADJ"} ,
		{"word":"hospitalières","word_nosc":"hospitalieres","lemma":"hospitalier","pos":"ADJ"} ,
		{"word":"hostile","word_nosc":"hostile","lemma":"hostile","pos":"ADJ"} ,
		{"word":"hostiles","word_nosc":"hostiles","lemma":"hostile","pos":"ADJ"} ,
		{"word":"hot","word_nosc":"hot","lemma":"hot","pos":"ADJ"} ,
		{"word":"houiller","word_nosc":"houiller","lemma":"houiller","pos":"ADJ"} ,
		{"word":"houillère","word_nosc":"houillere","lemma":"houillère","pos":"ADJ"} ,
		{"word":"houleuse","word_nosc":"houleuse","lemma":"houleux","pos":"ADJ"} ,
		{"word":"houleuses","word_nosc":"houleuses","lemma":"houleux","pos":"ADJ"} ,
		{"word":"houleux","word_nosc":"houleux","lemma":"houleux","pos":"ADJ"} ,
		{"word":"hugolien","word_nosc":"hugolien","lemma":"hugolien","pos":"ADJ"} ,
		{"word":"hugoliens","word_nosc":"hugoliens","lemma":"hugolien","pos":"ADJ"} ,
		{"word":"huguenot","word_nosc":"huguenot","lemma":"huguenot","pos":"ADJ"} ,
		{"word":"huguenote","word_nosc":"huguenote","lemma":"huguenot","pos":"ADJ"} ,
		{"word":"huileuse","word_nosc":"huileuse","lemma":"huileux","pos":"ADJ"} ,
		{"word":"huileuses","word_nosc":"huileuses","lemma":"huileux","pos":"ADJ"} ,
		{"word":"huileux","word_nosc":"huileux","lemma":"huileux","pos":"ADJ"} ,
		{"word":"huilier","word_nosc":"huilier","lemma":"huilier","pos":"ADJ"} ,
		{"word":"huiliers","word_nosc":"huiliers","lemma":"huilier","pos":"ADJ"} ,
		{"word":"huilé","word_nosc":"huile","lemma":"huilé","pos":"ADJ"} ,
		{"word":"huilée","word_nosc":"huilee","lemma":"huilé","pos":"ADJ"} ,
		{"word":"huilées","word_nosc":"huilees","lemma":"huilé","pos":"ADJ"} ,
		{"word":"huilés","word_nosc":"huiles","lemma":"huilé","pos":"ADJ"} ,
		{"word":"huit","word_nosc":"huit","lemma":"huit","pos":"ADJ:num"} ,
		{"word":"huitième","word_nosc":"huitieme","lemma":"huitième","pos":"ADJ"} ,
		{"word":"humain","word_nosc":"humain","lemma":"humain","pos":"ADJ"} ,
		{"word":"humain-robot","word_nosc":"humain-robot","lemma":"humain-robot","pos":"ADJ"} ,
		{"word":"humaine","word_nosc":"humaine","lemma":"humain","pos":"ADJ"} ,
		{"word":"humaines","word_nosc":"humaines","lemma":"humain","pos":"ADJ"} ,
		{"word":"humains","word_nosc":"humains","lemma":"humain","pos":"ADJ"} ,
		{"word":"humaniste","word_nosc":"humaniste","lemma":"humaniste","pos":"ADJ"} ,
		{"word":"humanistes","word_nosc":"humanistes","lemma":"humaniste","pos":"ADJ"} ,
		{"word":"humanitaire","word_nosc":"humanitaire","lemma":"humanitaire","pos":"ADJ"} ,
		{"word":"humanitaires","word_nosc":"humanitaires","lemma":"humanitaire","pos":"ADJ"} ,
		{"word":"humanoïde","word_nosc":"humanoide","lemma":"humanoïde","pos":"ADJ"} ,
		{"word":"humanoïdes","word_nosc":"humanoides","lemma":"humanoïde","pos":"ADJ"} ,
		{"word":"humble","word_nosc":"humble","lemma":"humble","pos":"ADJ"} ,
		{"word":"humbles","word_nosc":"humbles","lemma":"humble","pos":"ADJ"} ,
		{"word":"humbug","word_nosc":"humbug","lemma":"humbug","pos":"ADJ"} ,
		{"word":"humectant","word_nosc":"humectant","lemma":"humectant","pos":"ADJ"} ,
		{"word":"humide","word_nosc":"humide","lemma":"humide","pos":"ADJ"} ,
		{"word":"humides","word_nosc":"humides","lemma":"humide","pos":"ADJ"} ,
		{"word":"humiliant","word_nosc":"humiliant","lemma":"humiliant","pos":"ADJ"} ,
		{"word":"humiliante","word_nosc":"humiliante","lemma":"humiliant","pos":"ADJ"} ,
		{"word":"humiliantes","word_nosc":"humiliantes","lemma":"humiliant","pos":"ADJ"} ,
		{"word":"humiliants","word_nosc":"humiliants","lemma":"humiliant","pos":"ADJ"} ,
		{"word":"humilié","word_nosc":"humilie","lemma":"humilié","pos":"ADJ"} ,
		{"word":"humiliée","word_nosc":"humiliee","lemma":"humilié","pos":"ADJ"} ,
		{"word":"humiliées","word_nosc":"humiliees","lemma":"humilié","pos":"ADJ"} ,
		{"word":"humiliés","word_nosc":"humilies","lemma":"humilié","pos":"ADJ"} ,
		{"word":"humoral","word_nosc":"humoral","lemma":"humoral","pos":"ADJ"} ,
		{"word":"humoriste","word_nosc":"humoriste","lemma":"humoriste","pos":"ADJ"} ,
		{"word":"humoristes","word_nosc":"humoristes","lemma":"humoriste","pos":"ADJ"} ,
		{"word":"humoristique","word_nosc":"humoristique","lemma":"humoristique","pos":"ADJ"} ,
		{"word":"humoristiques","word_nosc":"humoristiques","lemma":"humoristique","pos":"ADJ"} ,
		{"word":"humérale","word_nosc":"humerale","lemma":"huméral","pos":"ADJ"} ,
		{"word":"huppé","word_nosc":"huppe","lemma":"huppé","pos":"ADJ"} ,
		{"word":"huppée","word_nosc":"huppee","lemma":"huppé","pos":"ADJ"} ,
		{"word":"huppées","word_nosc":"huppees","lemma":"huppé","pos":"ADJ"} ,
		{"word":"huppés","word_nosc":"huppes","lemma":"huppé","pos":"ADJ"} ,
		{"word":"hurlant","word_nosc":"hurlant","lemma":"hurlant","pos":"ADJ"} ,
		{"word":"hurlante","word_nosc":"hurlante","lemma":"hurlant","pos":"ADJ"} ,
		{"word":"hurlantes","word_nosc":"hurlantes","lemma":"hurlant","pos":"ADJ"} ,
		{"word":"hurlants","word_nosc":"hurlants","lemma":"hurlant","pos":"ADJ"} ,
		{"word":"hurleur","word_nosc":"hurleur","lemma":"hurleur","pos":"ADJ"} ,
		{"word":"hurleurs","word_nosc":"hurleurs","lemma":"hurleur","pos":"ADJ"} ,
		{"word":"huron","word_nosc":"huron","lemma":"huron","pos":"ADJ"} ,
		{"word":"huronne","word_nosc":"huronne","lemma":"huron","pos":"ADJ"} ,
		{"word":"hybride","word_nosc":"hybride","lemma":"hybride","pos":"ADJ"} ,
		{"word":"hybrides","word_nosc":"hybrides","lemma":"hybride","pos":"ADJ"} ,
		{"word":"hydatique","word_nosc":"hydatique","lemma":"hydatique","pos":"ADJ"} ,
		{"word":"hydratant","word_nosc":"hydratant","lemma":"hydratant","pos":"ADJ"} ,
		{"word":"hydratante","word_nosc":"hydratante","lemma":"hydratant","pos":"ADJ"} ,
		{"word":"hydratantes","word_nosc":"hydratantes","lemma":"hydratant","pos":"ADJ"} ,
		{"word":"hydratants","word_nosc":"hydratants","lemma":"hydratant","pos":"ADJ"} ,
		{"word":"hydraulique","word_nosc":"hydraulique","lemma":"hydraulique","pos":"ADJ"} ,
		{"word":"hydrauliques","word_nosc":"hydrauliques","lemma":"hydraulique","pos":"ADJ"} ,
		{"word":"hydrique","word_nosc":"hydrique","lemma":"hydrique","pos":"ADJ"} ,
		{"word":"hydro-électrique","word_nosc":"hydro-electrique","lemma":"hydro-électrique","pos":"ADJ"} ,
		{"word":"hydrochlorique","word_nosc":"hydrochlorique","lemma":"hydrochlorique","pos":"ADJ"} ,
		{"word":"hydrocéphale","word_nosc":"hydrocephale","lemma":"hydrocéphale","pos":"ADJ"} ,
		{"word":"hydrocéphales","word_nosc":"hydrocephales","lemma":"hydrocéphale","pos":"ADJ"} ,
		{"word":"hydrodynamique","word_nosc":"hydrodynamique","lemma":"hydrodynamique","pos":"ADJ"} ,
		{"word":"hydrographe","word_nosc":"hydrographe","lemma":"hydrographe","pos":"ADJ"} ,
		{"word":"hydrographique","word_nosc":"hydrographique","lemma":"hydrographique","pos":"ADJ"} ,
		{"word":"hydrographiques","word_nosc":"hydrographiques","lemma":"hydrographique","pos":"ADJ"} ,
		{"word":"hydrogéné","word_nosc":"hydrogene","lemma":"hydrogéné","pos":"ADJ"} ,
		{"word":"hydrogénée","word_nosc":"hydrogenee","lemma":"hydrogéné","pos":"ADJ"} ,
		{"word":"hydrophile","word_nosc":"hydrophile","lemma":"hydrophile","pos":"ADJ"} ,
		{"word":"hydrophiles","word_nosc":"hydrophiles","lemma":"hydrophile","pos":"ADJ"} ,
		{"word":"hydrophobe","word_nosc":"hydrophobe","lemma":"hydrophobe","pos":"ADJ"} ,
		{"word":"hydropique","word_nosc":"hydropique","lemma":"hydropique","pos":"ADJ"} ,
		{"word":"hydropneumatique","word_nosc":"hydropneumatique","lemma":"hydropneumatique","pos":"ADJ"} ,
		{"word":"hydroponique","word_nosc":"hydroponique","lemma":"hydroponique","pos":"ADJ"} ,
		{"word":"hydroponiques","word_nosc":"hydroponiques","lemma":"hydroponique","pos":"ADJ"} ,
		{"word":"hydrostatique","word_nosc":"hydrostatique","lemma":"hydrostatique","pos":"ADJ"} ,
		{"word":"hydroélectrique","word_nosc":"hydroelectrique","lemma":"hydroélectrique","pos":"ADJ"} ,
		{"word":"hygiénique","word_nosc":"hygienique","lemma":"hygiénique","pos":"ADJ"} ,
		{"word":"hygiéniques","word_nosc":"hygieniques","lemma":"hygiénique","pos":"ADJ"} ,
		{"word":"hygrométrique","word_nosc":"hygrometrique","lemma":"hygrométrique","pos":"ADJ"} ,
		{"word":"hygrométriques","word_nosc":"hygrometriques","lemma":"hygrométrique","pos":"ADJ"} ,
		{"word":"hylémorphique","word_nosc":"hylemorphique","lemma":"hylémorphique","pos":"ADJ"} ,
		{"word":"hyoïde","word_nosc":"hyoide","lemma":"hyoïde","pos":"ADJ"} ,
		{"word":"hyperactif","word_nosc":"hyperactif","lemma":"hyperactif","pos":"ADJ"} ,
		{"word":"hyperactive","word_nosc":"hyperactive","lemma":"hyperactif","pos":"ADJ"} ,
		{"word":"hyperbare","word_nosc":"hyperbare","lemma":"hyperbare","pos":"ADJ"} ,
		{"word":"hyperbolique","word_nosc":"hyperbolique","lemma":"hyperbolique","pos":"ADJ"} ,
		{"word":"hyperboréen","word_nosc":"hyperboreen","lemma":"hyperboréen","pos":"ADJ"} ,
		{"word":"hyperboréenne","word_nosc":"hyperboreenne","lemma":"hyperboréen","pos":"ADJ"} ,
		{"word":"hyperboréens","word_nosc":"hyperboreens","lemma":"hyperboréen","pos":"ADJ"} ,
		{"word":"hypernerveuse","word_nosc":"hypernerveuse","lemma":"hypernerveux","pos":"ADJ"} ,
		{"word":"hypernerveux","word_nosc":"hypernerveux","lemma":"hypernerveux","pos":"ADJ"} ,
		{"word":"hyperréaliste","word_nosc":"hyperrealiste","lemma":"hyperréaliste","pos":"ADJ"} ,
		{"word":"hyperréalistes","word_nosc":"hyperrealistes","lemma":"hyperréaliste","pos":"ADJ"} ,
		{"word":"hypersensible","word_nosc":"hypersensible","lemma":"hypersensible","pos":"ADJ"} ,
		{"word":"hypersensibles","word_nosc":"hypersensibles","lemma":"hypersensible","pos":"ADJ"} ,
		{"word":"hypersonique","word_nosc":"hypersonique","lemma":"hypersonique","pos":"ADJ"} ,
		{"word":"hypersustentateur","word_nosc":"hypersustentateur","lemma":"hypersustentateur","pos":"ADJ"} ,
		{"word":"hypertendu","word_nosc":"hypertendu","lemma":"hypertendu","pos":"ADJ"} ,
		{"word":"hypertendue","word_nosc":"hypertendue","lemma":"hypertendu","pos":"ADJ"} ,
		{"word":"hyperthyroïdien","word_nosc":"hyperthyroidien","lemma":"hyperthyroïdien","pos":"ADJ"} ,
		{"word":"hypertonique","word_nosc":"hypertonique","lemma":"hypertonique","pos":"ADJ"} ,
		{"word":"hypertrophique","word_nosc":"hypertrophique","lemma":"hypertrophique","pos":"ADJ"} ,
		{"word":"hypertrophié","word_nosc":"hypertrophie","lemma":"hypertrophié","pos":"ADJ"} ,
		{"word":"hypertrophiée","word_nosc":"hypertrophiee","lemma":"hypertrophié","pos":"ADJ"} ,
		{"word":"hypertrophiés","word_nosc":"hypertrophies","lemma":"hypertrophié","pos":"ADJ"} ,
		{"word":"hyperémotive","word_nosc":"hyperemotive","lemma":"hyperémotif","pos":"ADJ"} ,
		{"word":"hypnagogique","word_nosc":"hypnagogique","lemma":"hypnagogique","pos":"ADJ"} ,
		{"word":"hypnagogiques","word_nosc":"hypnagogiques","lemma":"hypnagogique","pos":"ADJ"} ,
		{"word":"hypnogène","word_nosc":"hypnogene","lemma":"hypnogène","pos":"ADJ"} ,
		{"word":"hypnotique","word_nosc":"hypnotique","lemma":"hypnotique","pos":"ADJ"} ,
		{"word":"hypnotiques","word_nosc":"hypnotiques","lemma":"hypnotique","pos":"ADJ"} ,
		{"word":"hypoallergénique","word_nosc":"hypoallergenique","lemma":"hypoallergénique","pos":"ADJ"} ,
		{"word":"hypoallergéniques","word_nosc":"hypoallergeniques","lemma":"hypoallergénique","pos":"ADJ"} ,
		{"word":"hypochondriaque","word_nosc":"hypochondriaque","lemma":"hypochondriaque","pos":"ADJ"} ,
		{"word":"hypocondriaque","word_nosc":"hypocondriaque","lemma":"hypocondriaque","pos":"ADJ"} ,
		{"word":"hypocondriaques","word_nosc":"hypocondriaques","lemma":"hypocondriaque","pos":"ADJ"} ,
		{"word":"hypocrite","word_nosc":"hypocrite","lemma":"hypocrite","pos":"ADJ"} ,
		{"word":"hypocrites","word_nosc":"hypocrites","lemma":"hypocrite","pos":"ADJ"} ,
		{"word":"hypodermique","word_nosc":"hypodermique","lemma":"hypodermique","pos":"ADJ"} ,
		{"word":"hypodermiques","word_nosc":"hypodermiques","lemma":"hypodermique","pos":"ADJ"} ,
		{"word":"hypoglycémique","word_nosc":"hypoglycemique","lemma":"hypoglycémique","pos":"ADJ"} ,
		{"word":"hypoglycémiques","word_nosc":"hypoglycemiques","lemma":"hypoglycémique","pos":"ADJ"} ,
		{"word":"hypophysaire","word_nosc":"hypophysaire","lemma":"hypophysaire","pos":"ADJ"} ,
		{"word":"hypotendu","word_nosc":"hypotendu","lemma":"hypotendu","pos":"ADJ"} ,
		{"word":"hypotendue","word_nosc":"hypotendue","lemma":"hypotendu","pos":"ADJ"} ,
		{"word":"hypotensif","word_nosc":"hypotensif","lemma":"hypotensif","pos":"ADJ"} ,
		{"word":"hypotensive","word_nosc":"hypotensive","lemma":"hypotensif","pos":"ADJ"} ,
		{"word":"hypothermique","word_nosc":"hypothermique","lemma":"hypothermique","pos":"ADJ"} ,
		{"word":"hypothécaire","word_nosc":"hypothecaire","lemma":"hypothécaire","pos":"ADJ"} ,
		{"word":"hypothécaires","word_nosc":"hypothecaires","lemma":"hypothécaire","pos":"ADJ"} ,
		{"word":"hypothétique","word_nosc":"hypothetique","lemma":"hypothétique","pos":"ADJ"} ,
		{"word":"hypothétiques","word_nosc":"hypothetiques","lemma":"hypothétique","pos":"ADJ"} ,
		{"word":"hypoxique","word_nosc":"hypoxique","lemma":"hypoxique","pos":"ADJ"} ,
		{"word":"hystérique","word_nosc":"hysterique","lemma":"hystérique","pos":"ADJ"} ,
		{"word":"hystériques","word_nosc":"hysteriques","lemma":"hystérique","pos":"ADJ"} ,
		{"word":"hystéro","word_nosc":"hystero","lemma":"hystéro","pos":"ADJ"} ,
		{"word":"hâbleur","word_nosc":"hableur","lemma":"hâbleur","pos":"ADJ"} ,
		{"word":"hâbleurs","word_nosc":"hableurs","lemma":"hâbleur","pos":"ADJ"} ,
		{"word":"hâlé","word_nosc":"hale","lemma":"hâlé","pos":"ADJ"} ,
		{"word":"hâlée","word_nosc":"halee","lemma":"hâlé","pos":"ADJ"} ,
		{"word":"hâlées","word_nosc":"halees","lemma":"hâlé","pos":"ADJ"} ,
		{"word":"hâlés","word_nosc":"hales","lemma":"hâlé","pos":"ADJ"} ,
		{"word":"hâtif","word_nosc":"hatif","lemma":"hâtif","pos":"ADJ"} ,
		{"word":"hâtifs","word_nosc":"hatifs","lemma":"hâtif","pos":"ADJ"} ,
		{"word":"hâtive","word_nosc":"hative","lemma":"hâtif","pos":"ADJ"} ,
		{"word":"hâtives","word_nosc":"hatives","lemma":"hâtif","pos":"ADJ"} ,
		{"word":"hâve","word_nosc":"have","lemma":"hâve","pos":"ADJ"} ,
		{"word":"hâves","word_nosc":"haves","lemma":"hâve","pos":"ADJ"} ,
		{"word":"hébraïque","word_nosc":"hebraique","lemma":"hébraïque","pos":"ADJ"} ,
		{"word":"hébraïques","word_nosc":"hebraiques","lemma":"hébraïque","pos":"ADJ"} ,
		{"word":"hébraïsant","word_nosc":"hebraisant","lemma":"hébraïsant","pos":"ADJ"} ,
		{"word":"hébreu","word_nosc":"hebreu","lemma":"hébreu","pos":"ADJ"} ,
		{"word":"hébreux","word_nosc":"hebreux","lemma":"hébreu","pos":"ADJ"} ,
		{"word":"hébéphrénique","word_nosc":"hebephrenique","lemma":"hébéphrénique","pos":"ADJ"} ,
		{"word":"hébété","word_nosc":"hebete","lemma":"hébété","pos":"ADJ"} ,
		{"word":"hébétée","word_nosc":"hebetee","lemma":"hébété","pos":"ADJ"} ,
		{"word":"hébétées","word_nosc":"hebetees","lemma":"hébété","pos":"ADJ"} ,
		{"word":"hébétés","word_nosc":"hebetes","lemma":"hébété","pos":"ADJ"} ,
		{"word":"hédoniste","word_nosc":"hedoniste","lemma":"hédoniste","pos":"ADJ"} ,
		{"word":"hédonistes","word_nosc":"hedonistes","lemma":"hédoniste","pos":"ADJ"} ,
		{"word":"hégélien","word_nosc":"hegelien","lemma":"hégélien","pos":"ADJ"} ,
		{"word":"hégélienne","word_nosc":"hegelienne","lemma":"hégélien","pos":"ADJ"} ,
		{"word":"hégéliens","word_nosc":"hegeliens","lemma":"hégélien","pos":"ADJ"} ,
		{"word":"hélicoïdal","word_nosc":"helicoidal","lemma":"hélicoïdal","pos":"ADJ"} ,
		{"word":"hélicoïdale","word_nosc":"helicoidale","lemma":"hélicoïdal","pos":"ADJ"} ,
		{"word":"héliporté","word_nosc":"heliporte","lemma":"héliporté","pos":"ADJ"} ,
		{"word":"héliportée","word_nosc":"heliportee","lemma":"héliporté","pos":"ADJ"} ,
		{"word":"héliportés","word_nosc":"heliportes","lemma":"héliporté","pos":"ADJ"} ,
		{"word":"hématologique","word_nosc":"hematologique","lemma":"hématologique","pos":"ADJ"} ,
		{"word":"hémiplégique","word_nosc":"hemiplegique","lemma":"hémiplégique","pos":"ADJ"} ,
		{"word":"hémisphérique","word_nosc":"hemispherique","lemma":"hémisphérique","pos":"ADJ"} ,
		{"word":"hémisphériques","word_nosc":"hemispheriques","lemma":"hémisphérique","pos":"ADJ"} ,
		{"word":"hémièdres","word_nosc":"hemiedres","lemma":"hémièdre","pos":"ADJ"} ,
		{"word":"hémodynamique","word_nosc":"hemodynamique","lemma":"hémodynamique","pos":"ADJ"} ,
		{"word":"hémolytique","word_nosc":"hemolytique","lemma":"hémolytique","pos":"ADJ"} ,
		{"word":"hémophile","word_nosc":"hemophile","lemma":"hémophile","pos":"ADJ"} ,
		{"word":"hémophiles","word_nosc":"hemophiles","lemma":"hémophile","pos":"ADJ"} ,
		{"word":"hémorragique","word_nosc":"hemorragique","lemma":"hémorragique","pos":"ADJ"} ,
		{"word":"hémorroïdaire","word_nosc":"hemorroidaire","lemma":"hémorroïdaire","pos":"ADJ"} ,
		{"word":"hémorroïdale","word_nosc":"hemorroidale","lemma":"hémorroïdal","pos":"ADJ"} ,
		{"word":"hémostatique","word_nosc":"hemostatique","lemma":"hémostatique","pos":"ADJ"} ,
		{"word":"hépatique","word_nosc":"hepatique","lemma":"hépatique","pos":"ADJ"} ,
		{"word":"hépatiques","word_nosc":"hepatiques","lemma":"hépatique","pos":"ADJ"} ,
		{"word":"héraclitienne","word_nosc":"heraclitienne","lemma":"héraclitien","pos":"ADJ"} ,
		{"word":"héraclitéen","word_nosc":"heracliteen","lemma":"héraclitéen","pos":"ADJ"} ,
		{"word":"héraclitéenne","word_nosc":"heracliteenne","lemma":"héraclitéen","pos":"ADJ"} ,
		{"word":"héraldique","word_nosc":"heraldique","lemma":"héraldique","pos":"ADJ"} ,
		{"word":"héraldiques","word_nosc":"heraldiques","lemma":"héraldique","pos":"ADJ"} ,
		{"word":"hérissé","word_nosc":"herisse","lemma":"hérissé","pos":"ADJ"} ,
		{"word":"hérissée","word_nosc":"herissee","lemma":"hérissé","pos":"ADJ"} ,
		{"word":"hérissées","word_nosc":"herissees","lemma":"hérissé","pos":"ADJ"} ,
		{"word":"hérissés","word_nosc":"herisses","lemma":"hérissé","pos":"ADJ"} ,
		{"word":"héroïque","word_nosc":"heroique","lemma":"héroïque","pos":"ADJ"} ,
		{"word":"héroïques","word_nosc":"heroiques","lemma":"héroïque","pos":"ADJ"} ,
		{"word":"héréditaire","word_nosc":"hereditaire","lemma":"héréditaire","pos":"ADJ"} ,
		{"word":"héréditaires","word_nosc":"hereditaires","lemma":"héréditaire","pos":"ADJ"} ,
		{"word":"hérétique","word_nosc":"heretique","lemma":"hérétique","pos":"ADJ"} ,
		{"word":"hérétiques","word_nosc":"heretiques","lemma":"hérétique","pos":"ADJ"} ,
		{"word":"hésitant","word_nosc":"hesitant","lemma":"hésitant","pos":"ADJ"} ,
		{"word":"hésitante","word_nosc":"hesitante","lemma":"hésitant","pos":"ADJ"} ,
		{"word":"hésitantes","word_nosc":"hesitantes","lemma":"hésitant","pos":"ADJ"} ,
		{"word":"hésitants","word_nosc":"hesitants","lemma":"hésitant","pos":"ADJ"} ,
		{"word":"hétéro","word_nosc":"hetero","lemma":"hétéro","pos":"ADJ"} ,
		{"word":"hétéroclite","word_nosc":"heteroclite","lemma":"hétéroclite","pos":"ADJ"} ,
		{"word":"hétéroclites","word_nosc":"heteroclites","lemma":"hétéroclite","pos":"ADJ"} ,
		{"word":"hétérodoxes","word_nosc":"heterodoxes","lemma":"hétérodoxe","pos":"ADJ"} ,
		{"word":"hétérogène","word_nosc":"heterogene","lemma":"hétérogène","pos":"ADJ"} ,
		{"word":"hétérogènes","word_nosc":"heterogenes","lemma":"hétérogène","pos":"ADJ"} ,
		{"word":"hétéros","word_nosc":"heteros","lemma":"hétéro","pos":"ADJ"} ,
		{"word":"hétérosexuel","word_nosc":"heterosexuel","lemma":"hétérosexuel","pos":"ADJ"} ,
		{"word":"hétérosexuelle","word_nosc":"heterosexuelle","lemma":"hétérosexuel","pos":"ADJ"} ,
		{"word":"hétérosexuelles","word_nosc":"heterosexuelles","lemma":"hétérosexuel","pos":"ADJ"} ,
		{"word":"hétérosexuels","word_nosc":"heterosexuels","lemma":"hétérosexuel","pos":"ADJ"} ,
		{"word":"hétérozygote","word_nosc":"heterozygote","lemma":"hétérozygote","pos":"ADJ"} ,
		{"word":"hétérozygotes","word_nosc":"heterozygotes","lemma":"hétérozygote","pos":"ADJ"} ,
		{"word":"hôtelier","word_nosc":"hotelier","lemma":"hôtelier","pos":"ADJ"} ,
		{"word":"hôteliers","word_nosc":"hoteliers","lemma":"hôtelier","pos":"ADJ"} ,
		{"word":"hôtelière","word_nosc":"hoteliere","lemma":"hôtelier","pos":"ADJ"} ,
		{"word":"hôtelières","word_nosc":"hotelieres","lemma":"hôtelier","pos":"ADJ"} ,
		{"word":"i","word_nosc":"i","lemma":"i","pos":"ADJ:num"} ,
		{"word":"iambique","word_nosc":"iambique","lemma":"iambique","pos":"ADJ"} ,
		{"word":"iambiques","word_nosc":"iambiques","lemma":"iambique","pos":"ADJ"} ,
		{"word":"ibo","word_nosc":"ibo","lemma":"ibo","pos":"ADJ"} ,
		{"word":"ibère","word_nosc":"ibere","lemma":"ibère","pos":"ADJ"} ,
		{"word":"ibères","word_nosc":"iberes","lemma":"ibère","pos":"ADJ"} ,
		{"word":"ibérique","word_nosc":"iberique","lemma":"ibérique","pos":"ADJ"} ,
		{"word":"ibériques","word_nosc":"iberiques","lemma":"ibérique","pos":"ADJ"} ,
		{"word":"icarienne","word_nosc":"icarienne","lemma":"icarienne","pos":"ADJ"} ,
		{"word":"ici","word_nosc":"ici","lemma":"ici","pos":"ADJ:num"} ,
		{"word":"iconoclaste","word_nosc":"iconoclaste","lemma":"iconoclaste","pos":"ADJ"} ,
		{"word":"iconoclastes","word_nosc":"iconoclastes","lemma":"iconoclaste","pos":"ADJ"} ,
		{"word":"iconographique","word_nosc":"iconographique","lemma":"iconographique","pos":"ADJ"} ,
		{"word":"ictérique","word_nosc":"icterique","lemma":"ictérique","pos":"ADJ"} ,
		{"word":"identifiable","word_nosc":"identifiable","lemma":"identifiable","pos":"ADJ"} ,
		{"word":"identifiables","word_nosc":"identifiables","lemma":"identifiable","pos":"ADJ"} ,
		{"word":"identifiant","word_nosc":"identifiant","lemma":"identifiant","pos":"ADJ"} ,
		{"word":"identificateur","word_nosc":"identificateur","lemma":"identificateur","pos":"ADJ"} ,
		{"word":"identique","word_nosc":"identique","lemma":"identique","pos":"ADJ"} ,
		{"word":"identiques","word_nosc":"identiques","lemma":"identique","pos":"ADJ"} ,
		{"word":"identitaire","word_nosc":"identitaire","lemma":"identitaire","pos":"ADJ"} ,
		{"word":"identitaires","word_nosc":"identitaires","lemma":"identitaire","pos":"ADJ"} ,
		{"word":"idiomatique","word_nosc":"idiomatique","lemma":"idiomatique","pos":"ADJ"} ,
		{"word":"idiomatiques","word_nosc":"idiomatiques","lemma":"idiomatique","pos":"ADJ"} ,
		{"word":"idiopathique","word_nosc":"idiopathique","lemma":"idiopathique","pos":"ADJ"} ,
		{"word":"idiosyncrasique","word_nosc":"idiosyncrasique","lemma":"idiosyncrasique","pos":"ADJ"} ,
		{"word":"idiot","word_nosc":"idiot","lemma":"idiot","pos":"ADJ"} ,
		{"word":"idiote","word_nosc":"idiote","lemma":"idiot","pos":"ADJ"} ,
		{"word":"idiotes","word_nosc":"idiotes","lemma":"idiot","pos":"ADJ"} ,
		{"word":"idiots","word_nosc":"idiots","lemma":"idiot","pos":"ADJ"} ,
		{"word":"idoine","word_nosc":"idoine","lemma":"idoine","pos":"ADJ"} ,
		{"word":"idoines","word_nosc":"idoines","lemma":"idoine","pos":"ADJ"} ,
		{"word":"idolâtre","word_nosc":"idolatre","lemma":"idolâtre","pos":"ADJ"} ,
		{"word":"idolâtres","word_nosc":"idolatres","lemma":"idolâtre","pos":"ADJ"} ,
		{"word":"idyllique","word_nosc":"idyllique","lemma":"idyllique","pos":"ADJ"} ,
		{"word":"idylliques","word_nosc":"idylliques","lemma":"idyllique","pos":"ADJ"} ,
		{"word":"idéal","word_nosc":"ideal","lemma":"idéal","pos":"ADJ"} ,
		{"word":"idéale","word_nosc":"ideale","lemma":"idéal","pos":"ADJ"} ,
		{"word":"idéales","word_nosc":"ideales","lemma":"idéal","pos":"ADJ"} ,
		{"word":"idéaliste","word_nosc":"idealiste","lemma":"idéaliste","pos":"ADJ"} ,
		{"word":"idéalistes","word_nosc":"idealistes","lemma":"idéaliste","pos":"ADJ"} ,
		{"word":"idéals","word_nosc":"ideals","lemma":"idéal","pos":"ADJ"} ,
		{"word":"idéaux","word_nosc":"ideaux","lemma":"idéal","pos":"ADJ"} ,
		{"word":"idéel","word_nosc":"ideel","lemma":"idéel","pos":"ADJ"} ,
		{"word":"idéographique","word_nosc":"ideographique","lemma":"idéographique","pos":"ADJ"} ,
		{"word":"idéologique","word_nosc":"ideologique","lemma":"idéologique","pos":"ADJ"} ,
		{"word":"idéologiques","word_nosc":"ideologiques","lemma":"idéologique","pos":"ADJ"} ,
		{"word":"ignare","word_nosc":"ignare","lemma":"ignare","pos":"ADJ"} ,
		{"word":"ignares","word_nosc":"ignares","lemma":"ignare","pos":"ADJ"} ,
		{"word":"ignifuge","word_nosc":"ignifuge","lemma":"ignifuge","pos":"ADJ"} ,
		{"word":"ignifugeant","word_nosc":"ignifugeant","lemma":"ignifugeant","pos":"ADJ"} ,
		{"word":"ignifugé","word_nosc":"ignifuge","lemma":"ignifugé","pos":"ADJ"} ,
		{"word":"ignifugée","word_nosc":"ignifugee","lemma":"ignifugé","pos":"ADJ"} ,
		{"word":"ignifugés","word_nosc":"ignifuges","lemma":"ignifugé","pos":"ADJ"} ,
		{"word":"ignoble","word_nosc":"ignoble","lemma":"ignoble","pos":"ADJ"} ,
		{"word":"ignobles","word_nosc":"ignobles","lemma":"ignoble","pos":"ADJ"} ,
		{"word":"ignominieuse","word_nosc":"ignominieuse","lemma":"ignominieux","pos":"ADJ"} ,
		{"word":"ignominieuses","word_nosc":"ignominieuses","lemma":"ignominieux","pos":"ADJ"} ,
		{"word":"ignominieux","word_nosc":"ignominieux","lemma":"ignominieux","pos":"ADJ"} ,
		{"word":"ignorant","word_nosc":"ignorant","lemma":"ignorant","pos":"ADJ"} ,
		{"word":"ignorante","word_nosc":"ignorante","lemma":"ignorant","pos":"ADJ"} ,
		{"word":"ignorantes","word_nosc":"ignorantes","lemma":"ignorant","pos":"ADJ"} ,
		{"word":"ignorantins","word_nosc":"ignorantins","lemma":"ignorantin","pos":"ADJ"} ,
		{"word":"ignorants","word_nosc":"ignorants","lemma":"ignorant","pos":"ADJ"} ,
		{"word":"ignoré","word_nosc":"ignore","lemma":"ignoré","pos":"ADJ"} ,
		{"word":"ignorée","word_nosc":"ignoree","lemma":"ignoré","pos":"ADJ"} ,
		{"word":"ignorées","word_nosc":"ignorees","lemma":"ignoré","pos":"ADJ"} ,
		{"word":"ignorés","word_nosc":"ignores","lemma":"ignoré","pos":"ADJ"} ,
		{"word":"igné","word_nosc":"igne","lemma":"igné","pos":"ADJ"} ,
		{"word":"ignée","word_nosc":"ignee","lemma":"igné","pos":"ADJ"} ,
		{"word":"il","word_nosc":"il","lemma":"il","pos":"ADJ:num"} ,
		{"word":"iliaque","word_nosc":"iliaque","lemma":"iliaque","pos":"ADJ"} ,
		{"word":"iliaques","word_nosc":"iliaques","lemma":"iliaque","pos":"ADJ"} ,
		{"word":"illettré","word_nosc":"illettre","lemma":"illettré","pos":"ADJ"} ,
		{"word":"illettrée","word_nosc":"illettree","lemma":"illettré","pos":"ADJ"} ,
		{"word":"illettrés","word_nosc":"illettres","lemma":"illettré","pos":"ADJ"} ,
		{"word":"illicite","word_nosc":"illicite","lemma":"illicite","pos":"ADJ"} ,
		{"word":"illicites","word_nosc":"illicites","lemma":"illicite","pos":"ADJ"} ,
		{"word":"illimité","word_nosc":"illimite","lemma":"illimité","pos":"ADJ"} ,
		{"word":"illimitée","word_nosc":"illimitee","lemma":"illimité","pos":"ADJ"} ,
		{"word":"illimitées","word_nosc":"illimitees","lemma":"illimité","pos":"ADJ"} ,
		{"word":"illimités","word_nosc":"illimites","lemma":"illimité","pos":"ADJ"} ,
		{"word":"illisible","word_nosc":"illisible","lemma":"illisible","pos":"ADJ"} ,
		{"word":"illisibles","word_nosc":"illisibles","lemma":"illisible","pos":"ADJ"} ,
		{"word":"illogique","word_nosc":"illogique","lemma":"illogique","pos":"ADJ"} ,
		{"word":"illogiques","word_nosc":"illogiques","lemma":"illogique","pos":"ADJ"} ,
		{"word":"illuminant","word_nosc":"illuminant","lemma":"illuminant","pos":"ADJ"} ,
		{"word":"illuministes","word_nosc":"illuministes","lemma":"illuministe","pos":"ADJ"} ,
		{"word":"illuminé","word_nosc":"illumine","lemma":"illuminé","pos":"ADJ"} ,
		{"word":"illuminée","word_nosc":"illuminee","lemma":"illuminé","pos":"ADJ"} ,
		{"word":"illuminées","word_nosc":"illuminees","lemma":"illuminé","pos":"ADJ"} ,
		{"word":"illuminés","word_nosc":"illumines","lemma":"illuminé","pos":"ADJ"} ,
		{"word":"illusoire","word_nosc":"illusoire","lemma":"illusoire","pos":"ADJ"} ,
		{"word":"illusoires","word_nosc":"illusoires","lemma":"illusoire","pos":"ADJ"} ,
		{"word":"illustre","word_nosc":"illustre","lemma":"illustre","pos":"ADJ"} ,
		{"word":"illustres","word_nosc":"illustres","lemma":"illustre","pos":"ADJ"} ,
		{"word":"illustrissime","word_nosc":"illustrissime","lemma":"illustrissime","pos":"ADJ"} ,
		{"word":"illustrissimes","word_nosc":"illustrissimes","lemma":"illustrissime","pos":"ADJ"} ,
		{"word":"illustré","word_nosc":"illustre","lemma":"illustré","pos":"ADJ"} ,
		{"word":"illustrée","word_nosc":"illustree","lemma":"illustré","pos":"ADJ"} ,
		{"word":"illustrées","word_nosc":"illustrees","lemma":"illustré","pos":"ADJ"} ,
		{"word":"illustrés","word_nosc":"illustres","lemma":"illustré","pos":"ADJ"} ,
		{"word":"illégal","word_nosc":"illegal","lemma":"illégal","pos":"ADJ"} ,
		{"word":"illégale","word_nosc":"illegale","lemma":"illégal","pos":"ADJ"} ,
		{"word":"illégales","word_nosc":"illegales","lemma":"illégal","pos":"ADJ"} ,
		{"word":"illégaux","word_nosc":"illegaux","lemma":"illégal","pos":"ADJ"} ,
		{"word":"illégitime","word_nosc":"illegitime","lemma":"illégitime","pos":"ADJ"} ,
		{"word":"illégitimes","word_nosc":"illegitimes","lemma":"illégitime","pos":"ADJ"} ,
		{"word":"iléaux","word_nosc":"ileaux","lemma":"iléal","pos":"ADJ"} ,
		{"word":"imaginable","word_nosc":"imaginable","lemma":"imaginable","pos":"ADJ"} ,
		{"word":"imaginables","word_nosc":"imaginables","lemma":"imaginable","pos":"ADJ"} ,
		{"word":"imaginaire","word_nosc":"imaginaire","lemma":"imaginaire","pos":"ADJ"} ,
		{"word":"imaginaires","word_nosc":"imaginaires","lemma":"imaginaire","pos":"ADJ"} ,
		{"word":"imaginant","word_nosc":"imaginant","lemma":"imaginant","pos":"ADJ"} ,
		{"word":"imaginatif","word_nosc":"imaginatif","lemma":"imaginatif","pos":"ADJ"} ,
		{"word":"imaginatifs","word_nosc":"imaginatifs","lemma":"imaginatif","pos":"ADJ"} ,
		{"word":"imaginative","word_nosc":"imaginative","lemma":"imaginatif","pos":"ADJ"} ,
		{"word":"imaginatives","word_nosc":"imaginatives","lemma":"imaginatif","pos":"ADJ"} ,
		{"word":"imagé","word_nosc":"image","lemma":"imagé","pos":"ADJ"} ,
		{"word":"imagée","word_nosc":"imagee","lemma":"imagé","pos":"ADJ"} ,
		{"word":"imagées","word_nosc":"imagees","lemma":"imagé","pos":"ADJ"} ,
		{"word":"imagés","word_nosc":"images","lemma":"imagé","pos":"ADJ"} ,
		{"word":"imbaisable","word_nosc":"imbaisable","lemma":"imbaisable","pos":"ADJ"} ,
		{"word":"imbattable","word_nosc":"imbattable","lemma":"imbattable","pos":"ADJ"} ,
		{"word":"imbattables","word_nosc":"imbattables","lemma":"imbattable","pos":"ADJ"} ,
		{"word":"imberbe","word_nosc":"imberbe","lemma":"imberbe","pos":"ADJ"} ,
		{"word":"imberbes","word_nosc":"imberbes","lemma":"imberbe","pos":"ADJ"} ,
		{"word":"imbibé","word_nosc":"imbibe","lemma":"imbibé","pos":"ADJ"} ,
		{"word":"imbibée","word_nosc":"imbibee","lemma":"imbibé","pos":"ADJ"} ,
		{"word":"imbibées","word_nosc":"imbibees","lemma":"imbibé","pos":"ADJ"} ,
		{"word":"imbibés","word_nosc":"imbibes","lemma":"imbibé","pos":"ADJ"} ,
		{"word":"imbitable","word_nosc":"imbitable","lemma":"imbitable","pos":"ADJ"} ,
		{"word":"imbitables","word_nosc":"imbitables","lemma":"imbitable","pos":"ADJ"} ,
		{"word":"imbittable","word_nosc":"imbittable","lemma":"imbittable","pos":"ADJ"} ,
		{"word":"imbriqué","word_nosc":"imbrique","lemma":"imbriqué","pos":"ADJ"} ,
		{"word":"imbriquées","word_nosc":"imbriquees","lemma":"imbriqué","pos":"ADJ"} ,
		{"word":"imbriqués","word_nosc":"imbriques","lemma":"imbriqué","pos":"ADJ"} ,
		{"word":"imbrisable","word_nosc":"imbrisable","lemma":"imbrisable","pos":"ADJ"} ,
		{"word":"imbu","word_nosc":"imbu","lemma":"imbu","pos":"ADJ"} ,
		{"word":"imbue","word_nosc":"imbue","lemma":"imbu","pos":"ADJ"} ,
		{"word":"imbues","word_nosc":"imbues","lemma":"imbu","pos":"ADJ"} ,
		{"word":"imbus","word_nosc":"imbus","lemma":"imbu","pos":"ADJ"} ,
		{"word":"imbuvable","word_nosc":"imbuvable","lemma":"imbuvable","pos":"ADJ"} ,
		{"word":"imbécile","word_nosc":"imbecile","lemma":"imbécile","pos":"ADJ"} ,
		{"word":"imbéciles","word_nosc":"imbeciles","lemma":"imbécile","pos":"ADJ"} ,
		{"word":"imitable","word_nosc":"imitable","lemma":"imitable","pos":"ADJ"} ,
		{"word":"imitables","word_nosc":"imitables","lemma":"imitable","pos":"ADJ"} ,
		{"word":"imitateur","word_nosc":"imitateur","lemma":"imitateur","pos":"ADJ"} ,
		{"word":"imitateurs","word_nosc":"imitateurs","lemma":"imitateur","pos":"ADJ"} ,
		{"word":"imitative","word_nosc":"imitative","lemma":"imitatif","pos":"ADJ"} ,
		{"word":"imitatives","word_nosc":"imitatives","lemma":"imitatif","pos":"ADJ"} ,
		{"word":"imité","word_nosc":"imite","lemma":"imité","pos":"ADJ"} ,
		{"word":"imitée","word_nosc":"imitee","lemma":"imité","pos":"ADJ"} ,
		{"word":"imitées","word_nosc":"imitees","lemma":"imité","pos":"ADJ"} ,
		{"word":"imités","word_nosc":"imites","lemma":"imité","pos":"ADJ"} ,
		{"word":"immaculé","word_nosc":"immacule","lemma":"immaculé","pos":"ADJ"} ,
		{"word":"immaculée","word_nosc":"immaculee","lemma":"immaculé","pos":"ADJ"} ,
		{"word":"immaculées","word_nosc":"immaculees","lemma":"immaculé","pos":"ADJ"} ,
		{"word":"immaculés","word_nosc":"immacules","lemma":"immaculé","pos":"ADJ"} ,
		{"word":"immanent","word_nosc":"immanent","lemma":"immanent","pos":"ADJ"} ,
		{"word":"immanente","word_nosc":"immanente","lemma":"immanent","pos":"ADJ"} ,
		{"word":"immangeable","word_nosc":"immangeable","lemma":"immangeable","pos":"ADJ"} ,
		{"word":"immangeables","word_nosc":"immangeables","lemma":"immangeable","pos":"ADJ"} ,
		{"word":"immanquable","word_nosc":"immanquable","lemma":"immanquable","pos":"ADJ"} ,
		{"word":"immanquables","word_nosc":"immanquables","lemma":"immanquable","pos":"ADJ"} ,
		{"word":"immarcescible","word_nosc":"immarcescible","lemma":"immarcescible","pos":"ADJ"} ,
		{"word":"immatriculé","word_nosc":"immatricule","lemma":"immatriculé","pos":"ADJ"} ,
		{"word":"immatriculée","word_nosc":"immatriculee","lemma":"immatriculé","pos":"ADJ"} ,
		{"word":"immature","word_nosc":"immature","lemma":"immature","pos":"ADJ"} ,
		{"word":"immatures","word_nosc":"immatures","lemma":"immature","pos":"ADJ"} ,
		{"word":"immatériel","word_nosc":"immateriel","lemma":"immatériel","pos":"ADJ"} ,
		{"word":"immatérielle","word_nosc":"immaterielle","lemma":"immatériel","pos":"ADJ"} ,
		{"word":"immatérielles","word_nosc":"immaterielles","lemma":"immatériel","pos":"ADJ"} ,
		{"word":"immatériels","word_nosc":"immateriels","lemma":"immatériel","pos":"ADJ"} ,
		{"word":"immense","word_nosc":"immense","lemma":"immense","pos":"ADJ"} ,
		{"word":"immenses","word_nosc":"immenses","lemma":"immense","pos":"ADJ"} ,
		{"word":"immensurable","word_nosc":"immensurable","lemma":"immensurable","pos":"ADJ"} ,
		{"word":"immergé","word_nosc":"immerge","lemma":"immergé","pos":"ADJ"} ,
		{"word":"immergée","word_nosc":"immergee","lemma":"immergé","pos":"ADJ"} ,
		{"word":"immergées","word_nosc":"immergees","lemma":"immergé","pos":"ADJ"} ,
		{"word":"immergés","word_nosc":"immerges","lemma":"immergé","pos":"ADJ"} ,
		{"word":"immettable","word_nosc":"immettable","lemma":"immettable","pos":"ADJ"} ,
		{"word":"immeuble","word_nosc":"immeuble","lemma":"immeuble","pos":"ADJ"} ,
		{"word":"immeubles","word_nosc":"immeubles","lemma":"immeuble","pos":"ADJ"} ,
		{"word":"immigrant","word_nosc":"immigrant","lemma":"immigrant","pos":"ADJ"} ,
		{"word":"immigrants","word_nosc":"immigrants","lemma":"immigrant","pos":"ADJ"} ,
		{"word":"immigré","word_nosc":"immigre","lemma":"immigré","pos":"ADJ"} ,
		{"word":"immigrée","word_nosc":"immigree","lemma":"immigré","pos":"ADJ"} ,
		{"word":"immigrées","word_nosc":"immigrees","lemma":"immigré","pos":"ADJ"} ,
		{"word":"immigrés","word_nosc":"immigres","lemma":"immigré","pos":"ADJ"} ,
		{"word":"imminent","word_nosc":"imminent","lemma":"imminent","pos":"ADJ"} ,
		{"word":"imminente","word_nosc":"imminente","lemma":"imminent","pos":"ADJ"} ,
		{"word":"imminentes","word_nosc":"imminentes","lemma":"imminent","pos":"ADJ"} ,
		{"word":"imminents","word_nosc":"imminents","lemma":"imminent","pos":"ADJ"} ,
		{"word":"immiscibles","word_nosc":"immiscibles","lemma":"immiscible","pos":"ADJ"} ,
		{"word":"immobile","word_nosc":"immobile","lemma":"immobile","pos":"ADJ"} ,
		{"word":"immobiles","word_nosc":"immobiles","lemma":"immobile","pos":"ADJ"} ,
		{"word":"immobilier","word_nosc":"immobilier","lemma":"immobilier","pos":"ADJ"} ,
		{"word":"immobiliers","word_nosc":"immobiliers","lemma":"immobilier","pos":"ADJ"} ,
		{"word":"immobilisant","word_nosc":"immobilisant","lemma":"immobilisant","pos":"ADJ"} ,
		{"word":"immobilière","word_nosc":"immobiliere","lemma":"immobilier","pos":"ADJ"} ,
		{"word":"immobilières","word_nosc":"immobilieres","lemma":"immobilier","pos":"ADJ"} ,
		{"word":"immodeste","word_nosc":"immodeste","lemma":"immodeste","pos":"ADJ"} ,
		{"word":"immodestes","word_nosc":"immodestes","lemma":"immodeste","pos":"ADJ"} ,
		{"word":"immodéré","word_nosc":"immodere","lemma":"immodéré","pos":"ADJ"} ,
		{"word":"immodérée","word_nosc":"immoderee","lemma":"immodéré","pos":"ADJ"} ,
		{"word":"immodérées","word_nosc":"immoderees","lemma":"immodéré","pos":"ADJ"} ,
		{"word":"immodérés","word_nosc":"immoderes","lemma":"immodéré","pos":"ADJ"} ,
		{"word":"immonde","word_nosc":"immonde","lemma":"immonde","pos":"ADJ"} ,
		{"word":"immondes","word_nosc":"immondes","lemma":"immonde","pos":"ADJ"} ,
		{"word":"immoral","word_nosc":"immoral","lemma":"immoral","pos":"ADJ"} ,
		{"word":"immorale","word_nosc":"immorale","lemma":"immoral","pos":"ADJ"} ,
		{"word":"immorales","word_nosc":"immorales","lemma":"immoral","pos":"ADJ"} ,
		{"word":"immoraux","word_nosc":"immoraux","lemma":"immoral","pos":"ADJ"} ,
		{"word":"immortel","word_nosc":"immortel","lemma":"immortel","pos":"ADJ"} ,
		{"word":"immortelle","word_nosc":"immortelle","lemma":"immortel","pos":"ADJ"} ,
		{"word":"immortelles","word_nosc":"immortelles","lemma":"immortel","pos":"ADJ"} ,
		{"word":"immortels","word_nosc":"immortels","lemma":"immortel","pos":"ADJ"} ,
		{"word":"immortifiées","word_nosc":"immortifiees","lemma":"immortifié","pos":"ADJ"} ,
		{"word":"immotivée","word_nosc":"immotivee","lemma":"immotivé","pos":"ADJ"} ,
		{"word":"immotivés","word_nosc":"immotives","lemma":"immotivé","pos":"ADJ"} ,
		{"word":"immuable","word_nosc":"immuable","lemma":"immuable","pos":"ADJ"} ,
		{"word":"immuables","word_nosc":"immuables","lemma":"immuable","pos":"ADJ"} ,
		{"word":"immune","word_nosc":"immune","lemma":"immun","pos":"ADJ"} ,
		{"word":"immunitaire","word_nosc":"immunitaire","lemma":"immunitaire","pos":"ADJ"} ,
		{"word":"immunitaires","word_nosc":"immunitaires","lemma":"immunitaire","pos":"ADJ"} ,
		{"word":"immunodéficitaire","word_nosc":"immunodeficitaire","lemma":"immunodéficitaire","pos":"ADJ"} ,
		{"word":"immunodéprimé","word_nosc":"immunodeprime","lemma":"immunodéprimé","pos":"ADJ"} ,
		{"word":"immunogène","word_nosc":"immunogene","lemma":"immunogène","pos":"ADJ"} ,
		{"word":"immunologique","word_nosc":"immunologique","lemma":"immunologique","pos":"ADJ"} ,
		{"word":"immédiat","word_nosc":"immediat","lemma":"immédiat","pos":"ADJ"} ,
		{"word":"immédiate","word_nosc":"immediate","lemma":"immédiat","pos":"ADJ"} ,
		{"word":"immédiates","word_nosc":"immediates","lemma":"immédiat","pos":"ADJ"} ,
		{"word":"immédiats","word_nosc":"immediats","lemma":"immédiat","pos":"ADJ"} ,
		{"word":"immémorial","word_nosc":"immemorial","lemma":"immémorial","pos":"ADJ"} ,
		{"word":"immémoriale","word_nosc":"immemoriale","lemma":"immémorial","pos":"ADJ"} ,
		{"word":"immémoriales","word_nosc":"immemoriales","lemma":"immémorial","pos":"ADJ"} ,
		{"word":"immémoriaux","word_nosc":"immemoriaux","lemma":"immémorial","pos":"ADJ"} ,
		{"word":"immérité","word_nosc":"immerite","lemma":"immérité","pos":"ADJ"} ,
		{"word":"imméritée","word_nosc":"immeritee","lemma":"immérité","pos":"ADJ"} ,
		{"word":"imméritées","word_nosc":"immeritees","lemma":"immérité","pos":"ADJ"} ,
		{"word":"immérités","word_nosc":"immerites","lemma":"immérité","pos":"ADJ"} ,
		{"word":"impair","word_nosc":"impair","lemma":"impair","pos":"ADJ"} ,
		{"word":"impaire","word_nosc":"impaire","lemma":"impair","pos":"ADJ"} ,
		{"word":"impaires","word_nosc":"impaires","lemma":"impair","pos":"ADJ"} ,
		{"word":"impairs","word_nosc":"impairs","lemma":"impair","pos":"ADJ"} ,
		{"word":"impalpable","word_nosc":"impalpable","lemma":"impalpable","pos":"ADJ"} ,
		{"word":"impalpables","word_nosc":"impalpables","lemma":"impalpable","pos":"ADJ"} ,
		{"word":"imparable","word_nosc":"imparable","lemma":"imparable","pos":"ADJ"} ,
		{"word":"imparables","word_nosc":"imparables","lemma":"imparable","pos":"ADJ"} ,
		{"word":"impardonnable","word_nosc":"impardonnable","lemma":"impardonnable","pos":"ADJ"} ,
		{"word":"impardonnables","word_nosc":"impardonnables","lemma":"impardonnable","pos":"ADJ"} ,
		{"word":"imparfait","word_nosc":"imparfait","lemma":"imparfait","pos":"ADJ"} ,
		{"word":"imparfaite","word_nosc":"imparfaite","lemma":"imparfait","pos":"ADJ"} ,
		{"word":"imparfaites","word_nosc":"imparfaites","lemma":"imparfait","pos":"ADJ"} ,
		{"word":"imparfaits","word_nosc":"imparfaits","lemma":"imparfait","pos":"ADJ"} ,
		{"word":"impartageable","word_nosc":"impartageable","lemma":"impartageable","pos":"ADJ"} ,
		{"word":"imparti","word_nosc":"imparti","lemma":"imparti","pos":"ADJ"} ,
		{"word":"impartial","word_nosc":"impartial","lemma":"impartial","pos":"ADJ"} ,
		{"word":"impartiale","word_nosc":"impartiale","lemma":"impartial","pos":"ADJ"} ,
		{"word":"impartiales","word_nosc":"impartiales","lemma":"impartial","pos":"ADJ"} ,
		{"word":"impartiaux","word_nosc":"impartiaux","lemma":"impartial","pos":"ADJ"} ,
		{"word":"impassable","word_nosc":"impassable","lemma":"impassable","pos":"ADJ"} ,
		{"word":"impassible","word_nosc":"impassible","lemma":"impassible","pos":"ADJ"} ,
		{"word":"impassibles","word_nosc":"impassibles","lemma":"impassible","pos":"ADJ"} ,
		{"word":"impatient","word_nosc":"impatient","lemma":"impatient","pos":"ADJ"} ,
		{"word":"impatiente","word_nosc":"impatiente","lemma":"impatient","pos":"ADJ"} ,
		{"word":"impatientes","word_nosc":"impatientes","lemma":"impatient","pos":"ADJ"} ,
		{"word":"impatients","word_nosc":"impatients","lemma":"impatient","pos":"ADJ"} ,
		{"word":"impavide","word_nosc":"impavide","lemma":"impavide","pos":"ADJ"} ,
		{"word":"impavides","word_nosc":"impavides","lemma":"impavide","pos":"ADJ"} ,
		{"word":"impayable","word_nosc":"impayable","lemma":"impayable","pos":"ADJ"} ,
		{"word":"impayables","word_nosc":"impayables","lemma":"impayable","pos":"ADJ"} ,
		{"word":"impayé","word_nosc":"impaye","lemma":"impayé","pos":"ADJ"} ,
		{"word":"impayée","word_nosc":"impayee","lemma":"impayé","pos":"ADJ"} ,
		{"word":"impayées","word_nosc":"impayees","lemma":"impayé","pos":"ADJ"} ,
		{"word":"impayés","word_nosc":"impayes","lemma":"impayé","pos":"ADJ"} ,
		{"word":"impec","word_nosc":"impec","lemma":"impec","pos":"ADJ"} ,
		{"word":"impeccable","word_nosc":"impeccable","lemma":"impeccable","pos":"ADJ"} ,
		{"word":"impeccables","word_nosc":"impeccables","lemma":"impeccable","pos":"ADJ"} ,
		{"word":"impensable","word_nosc":"impensable","lemma":"impensable","pos":"ADJ"} ,
		{"word":"impensables","word_nosc":"impensables","lemma":"impensable","pos":"ADJ"} ,
		{"word":"impensé","word_nosc":"impense","lemma":"impensé","pos":"ADJ"} ,
		{"word":"imperceptible","word_nosc":"imperceptible","lemma":"imperceptible","pos":"ADJ"} ,
		{"word":"imperceptibles","word_nosc":"imperceptibles","lemma":"imperceptible","pos":"ADJ"} ,
		{"word":"imperfectible","word_nosc":"imperfectible","lemma":"imperfectible","pos":"ADJ"} ,
		{"word":"imperméable","word_nosc":"impermeable","lemma":"imperméable","pos":"ADJ"} ,
		{"word":"imperméables","word_nosc":"impermeables","lemma":"imperméable","pos":"ADJ"} ,
		{"word":"impersonnel","word_nosc":"impersonnel","lemma":"impersonnel","pos":"ADJ"} ,
		{"word":"impersonnelle","word_nosc":"impersonnelle","lemma":"impersonnel","pos":"ADJ"} ,
		{"word":"impersonnelles","word_nosc":"impersonnelles","lemma":"impersonnel","pos":"ADJ"} ,
		{"word":"impersonnels","word_nosc":"impersonnels","lemma":"impersonnel","pos":"ADJ"} ,
		{"word":"impertinent","word_nosc":"impertinent","lemma":"impertinent","pos":"ADJ"} ,
		{"word":"impertinente","word_nosc":"impertinente","lemma":"impertinent","pos":"ADJ"} ,
		{"word":"impertinentes","word_nosc":"impertinentes","lemma":"impertinent","pos":"ADJ"} ,
		{"word":"impertinents","word_nosc":"impertinents","lemma":"impertinent","pos":"ADJ"} ,
		{"word":"imperturbable","word_nosc":"imperturbable","lemma":"imperturbable","pos":"ADJ"} ,
		{"word":"imperturbables","word_nosc":"imperturbables","lemma":"imperturbable","pos":"ADJ"} ,
		{"word":"impie","word_nosc":"impie","lemma":"impie","pos":"ADJ"} ,
		{"word":"impies","word_nosc":"impies","lemma":"impie","pos":"ADJ"} ,
		{"word":"impitoyable","word_nosc":"impitoyable","lemma":"impitoyable","pos":"ADJ"} ,
		{"word":"impitoyables","word_nosc":"impitoyables","lemma":"impitoyable","pos":"ADJ"} ,
		{"word":"implacable","word_nosc":"implacable","lemma":"implacable","pos":"ADJ"} ,
		{"word":"implacables","word_nosc":"implacables","lemma":"implacable","pos":"ADJ"} ,
		{"word":"implantable","word_nosc":"implantable","lemma":"implantable","pos":"ADJ"} ,
		{"word":"implanté","word_nosc":"implante","lemma":"implanté","pos":"ADJ"} ,
		{"word":"implantée","word_nosc":"implantee","lemma":"implanté","pos":"ADJ"} ,
		{"word":"implantées","word_nosc":"implantees","lemma":"implanté","pos":"ADJ"} ,
		{"word":"implantés","word_nosc":"implantes","lemma":"implanté","pos":"ADJ"} ,
		{"word":"implicite","word_nosc":"implicite","lemma":"implicite","pos":"ADJ"} ,
		{"word":"implicites","word_nosc":"implicites","lemma":"implicite","pos":"ADJ"} ,
		{"word":"implorant","word_nosc":"implorant","lemma":"implorant","pos":"ADJ"} ,
		{"word":"implorante","word_nosc":"implorante","lemma":"implorant","pos":"ADJ"} ,
		{"word":"implorantes","word_nosc":"implorantes","lemma":"implorant","pos":"ADJ"} ,
		{"word":"implorants","word_nosc":"implorants","lemma":"implorant","pos":"ADJ"} ,
		{"word":"implosif","word_nosc":"implosif","lemma":"implosif","pos":"ADJ"} ,
		{"word":"implosifs","word_nosc":"implosifs","lemma":"implosif","pos":"ADJ"} ,
		{"word":"implosives","word_nosc":"implosives","lemma":"implosif","pos":"ADJ"} ,
		{"word":"impoli","word_nosc":"impoli","lemma":"impoli","pos":"ADJ"} ,
		{"word":"impolie","word_nosc":"impolie","lemma":"impoli","pos":"ADJ"} ,
		{"word":"impolis","word_nosc":"impolis","lemma":"impoli","pos":"ADJ"} ,
		{"word":"impollu","word_nosc":"impollu","lemma":"impollu","pos":"ADJ"} ,
		{"word":"impolluable","word_nosc":"impolluable","lemma":"impolluable","pos":"ADJ"} ,
		{"word":"impollué","word_nosc":"impollue","lemma":"impollué","pos":"ADJ"} ,
		{"word":"impolluée","word_nosc":"impolluee","lemma":"impollué","pos":"ADJ"} ,
		{"word":"impolluées","word_nosc":"impolluees","lemma":"impollué","pos":"ADJ"} ,
		{"word":"impollués","word_nosc":"impollues","lemma":"impollué","pos":"ADJ"} ,
		{"word":"impondérable","word_nosc":"imponderable","lemma":"impondérable","pos":"ADJ"} ,
		{"word":"impondérables","word_nosc":"imponderables","lemma":"impondérable","pos":"ADJ"} ,
		{"word":"impopulaire","word_nosc":"impopulaire","lemma":"impopulaire","pos":"ADJ"} ,
		{"word":"impopulaires","word_nosc":"impopulaires","lemma":"impopulaire","pos":"ADJ"} ,
		{"word":"importable","word_nosc":"importable","lemma":"importable","pos":"ADJ"} ,
		{"word":"importables","word_nosc":"importables","lemma":"importable","pos":"ADJ"} ,
		{"word":"important","word_nosc":"important","lemma":"important","pos":"ADJ"} ,
		{"word":"importante","word_nosc":"importante","lemma":"important","pos":"ADJ"} ,
		{"word":"importantes","word_nosc":"importantes","lemma":"important","pos":"ADJ"} ,
		{"word":"importants","word_nosc":"importants","lemma":"important","pos":"ADJ"} ,
		{"word":"importateur","word_nosc":"importateur","lemma":"importateur","pos":"ADJ"} ,
		{"word":"importun","word_nosc":"importun","lemma":"importun","pos":"ADJ"} ,
		{"word":"importune","word_nosc":"importune","lemma":"importun","pos":"ADJ"} ,
		{"word":"importunes","word_nosc":"importunes","lemma":"importun","pos":"ADJ"} ,
		{"word":"importuns","word_nosc":"importuns","lemma":"importun","pos":"ADJ"} ,
		{"word":"importé","word_nosc":"importe","lemma":"importé","pos":"ADJ"} ,
		{"word":"importée","word_nosc":"importee","lemma":"importé","pos":"ADJ"} ,
		{"word":"importées","word_nosc":"importees","lemma":"importé","pos":"ADJ"} ,
		{"word":"importés","word_nosc":"importes","lemma":"importé","pos":"ADJ"} ,
		{"word":"imposable","word_nosc":"imposable","lemma":"imposable","pos":"ADJ"} ,
		{"word":"imposables","word_nosc":"imposables","lemma":"imposable","pos":"ADJ"} ,
		{"word":"imposant","word_nosc":"imposant","lemma":"imposant","pos":"ADJ"} ,
		{"word":"imposante","word_nosc":"imposante","lemma":"imposant","pos":"ADJ"} ,
		{"word":"imposantes","word_nosc":"imposantes","lemma":"imposant","pos":"ADJ"} ,
		{"word":"imposants","word_nosc":"imposants","lemma":"imposant","pos":"ADJ"} ,
		{"word":"impossible","word_nosc":"impossible","lemma":"impossible","pos":"ADJ"} ,
		{"word":"impossibles","word_nosc":"impossibles","lemma":"impossible","pos":"ADJ"} ,
		{"word":"imposé","word_nosc":"impose","lemma":"imposé","pos":"ADJ"} ,
		{"word":"imposée","word_nosc":"imposee","lemma":"imposé","pos":"ADJ"} ,
		{"word":"imposées","word_nosc":"imposees","lemma":"imposé","pos":"ADJ"} ,
		{"word":"imposés","word_nosc":"imposes","lemma":"imposé","pos":"ADJ"} ,
		{"word":"impotent","word_nosc":"impotent","lemma":"impotent","pos":"ADJ"} ,
		{"word":"impotente","word_nosc":"impotente","lemma":"impotent","pos":"ADJ"} ,
		{"word":"impotentes","word_nosc":"impotentes","lemma":"impotent","pos":"ADJ"} ,
		{"word":"impotents","word_nosc":"impotents","lemma":"impotent","pos":"ADJ"} ,
		{"word":"impraticable","word_nosc":"impraticable","lemma":"impraticable","pos":"ADJ"} ,
		{"word":"impraticables","word_nosc":"impraticables","lemma":"impraticable","pos":"ADJ"} ,
		{"word":"impratique","word_nosc":"impratique","lemma":"impratique","pos":"ADJ"} ,
		{"word":"imprenable","word_nosc":"imprenable","lemma":"imprenable","pos":"ADJ"} ,
		{"word":"imprenables","word_nosc":"imprenables","lemma":"imprenable","pos":"ADJ"} ,
		{"word":"imprescriptible","word_nosc":"imprescriptible","lemma":"imprescriptible","pos":"ADJ"} ,
		{"word":"imprescriptibles","word_nosc":"imprescriptibles","lemma":"imprescriptible","pos":"ADJ"} ,
		{"word":"impressionnable","word_nosc":"impressionnable","lemma":"impressionnable","pos":"ADJ"} ,
		{"word":"impressionnables","word_nosc":"impressionnables","lemma":"impressionnable","pos":"ADJ"} ,
		{"word":"impressionnant","word_nosc":"impressionnant","lemma":"impressionnant","pos":"ADJ"} ,
		{"word":"impressionnante","word_nosc":"impressionnante","lemma":"impressionnant","pos":"ADJ"} ,
		{"word":"impressionnantes","word_nosc":"impressionnantes","lemma":"impressionnant","pos":"ADJ"} ,
		{"word":"impressionnants","word_nosc":"impressionnants","lemma":"impressionnant","pos":"ADJ"} ,
		{"word":"impressionniste","word_nosc":"impressionniste","lemma":"impressionniste","pos":"ADJ"} ,
		{"word":"impressionnistes","word_nosc":"impressionnistes","lemma":"impressionniste","pos":"ADJ"} ,
		{"word":"impressive","word_nosc":"impressive","lemma":"impressif","pos":"ADJ"} ,
		{"word":"imprimable","word_nosc":"imprimable","lemma":"imprimable","pos":"ADJ"} ,
		{"word":"improbable","word_nosc":"improbable","lemma":"improbable","pos":"ADJ"} ,
		{"word":"improbables","word_nosc":"improbables","lemma":"improbable","pos":"ADJ"} ,
		{"word":"improductif","word_nosc":"improductif","lemma":"improductif","pos":"ADJ"} ,
		{"word":"improductifs","word_nosc":"improductifs","lemma":"improductif","pos":"ADJ"} ,
		{"word":"improductive","word_nosc":"improductive","lemma":"improductif","pos":"ADJ"} ,
		{"word":"improductives","word_nosc":"improductives","lemma":"improductif","pos":"ADJ"} ,
		{"word":"impromptu","word_nosc":"impromptu","lemma":"impromptu","pos":"ADJ"} ,
		{"word":"impromptue","word_nosc":"impromptue","lemma":"impromptu","pos":"ADJ"} ,
		{"word":"impromptues","word_nosc":"impromptues","lemma":"impromptu","pos":"ADJ"} ,
		{"word":"impromptus","word_nosc":"impromptus","lemma":"impromptu","pos":"ADJ"} ,
		{"word":"imprononcé","word_nosc":"imprononce","lemma":"imprononcé","pos":"ADJ"} ,
		{"word":"imprononçable","word_nosc":"imprononcable","lemma":"imprononçable","pos":"ADJ"} ,
		{"word":"imprononçables","word_nosc":"imprononcables","lemma":"imprononçable","pos":"ADJ"} ,
		{"word":"impropre","word_nosc":"impropre","lemma":"impropre","pos":"ADJ"} ,
		{"word":"impropres","word_nosc":"impropres","lemma":"impropre","pos":"ADJ"} ,
		{"word":"improuvable","word_nosc":"improuvable","lemma":"improuvable","pos":"ADJ"} ,
		{"word":"improvisé","word_nosc":"improvise","lemma":"improvisé","pos":"ADJ"} ,
		{"word":"improvisée","word_nosc":"improvisee","lemma":"improvisé","pos":"ADJ"} ,
		{"word":"improvisées","word_nosc":"improvisees","lemma":"improvisé","pos":"ADJ"} ,
		{"word":"improvisés","word_nosc":"improvises","lemma":"improvisé","pos":"ADJ"} ,
		{"word":"imprudent","word_nosc":"imprudent","lemma":"imprudent","pos":"ADJ"} ,
		{"word":"imprudente","word_nosc":"imprudente","lemma":"imprudent","pos":"ADJ"} ,
		{"word":"imprudentes","word_nosc":"imprudentes","lemma":"imprudent","pos":"ADJ"} ,
		{"word":"imprudents","word_nosc":"imprudents","lemma":"imprudent","pos":"ADJ"} ,
		{"word":"imprécis","word_nosc":"imprecis","lemma":"imprécis","pos":"ADJ"} ,
		{"word":"imprécise","word_nosc":"imprecise","lemma":"imprécis","pos":"ADJ"} ,
		{"word":"imprécises","word_nosc":"imprecises","lemma":"imprécis","pos":"ADJ"} ,
		{"word":"imprégnant","word_nosc":"impregnant","lemma":"imprégnant","pos":"ADJ"} ,
		{"word":"imprégné","word_nosc":"impregne","lemma":"imprégné","pos":"ADJ"} ,
		{"word":"imprégnée","word_nosc":"impregnee","lemma":"imprégné","pos":"ADJ"} ,
		{"word":"imprégnées","word_nosc":"impregnees","lemma":"imprégné","pos":"ADJ"} ,
		{"word":"imprégnés","word_nosc":"impregnes","lemma":"imprégné","pos":"ADJ"} ,
		{"word":"imprésentable","word_nosc":"impresentable","lemma":"imprésentable","pos":"ADJ"} ,
		{"word":"imprésentables","word_nosc":"impresentables","lemma":"imprésentable","pos":"ADJ"} ,
		{"word":"imprévisible","word_nosc":"imprevisible","lemma":"imprévisible","pos":"ADJ"} ,
		{"word":"imprévisibles","word_nosc":"imprevisibles","lemma":"imprévisible","pos":"ADJ"} ,
		{"word":"imprévoyant","word_nosc":"imprevoyant","lemma":"imprévoyant","pos":"ADJ"} ,
		{"word":"imprévoyante","word_nosc":"imprevoyante","lemma":"imprévoyant","pos":"ADJ"} ,
		{"word":"imprévoyants","word_nosc":"imprevoyants","lemma":"imprévoyant","pos":"ADJ"} ,
		{"word":"imprévu","word_nosc":"imprevu","lemma":"imprévu","pos":"ADJ"} ,
		{"word":"imprévue","word_nosc":"imprevue","lemma":"imprévu","pos":"ADJ"} ,
		{"word":"imprévues","word_nosc":"imprevues","lemma":"imprévu","pos":"ADJ"} ,
		{"word":"imprévus","word_nosc":"imprevus","lemma":"imprévu","pos":"ADJ"} ,
		{"word":"impubliable","word_nosc":"impubliable","lemma":"impubliable","pos":"ADJ"} ,
		{"word":"impubliables","word_nosc":"impubliables","lemma":"impubliable","pos":"ADJ"} ,
		{"word":"impubère","word_nosc":"impubere","lemma":"impubère","pos":"ADJ"} ,
		{"word":"impubères","word_nosc":"impuberes","lemma":"impubère","pos":"ADJ"} ,
		{"word":"impudent","word_nosc":"impudent","lemma":"impudent","pos":"ADJ"} ,
		{"word":"impudente","word_nosc":"impudente","lemma":"impudent","pos":"ADJ"} ,
		{"word":"impudentes","word_nosc":"impudentes","lemma":"impudent","pos":"ADJ"} ,
		{"word":"impudents","word_nosc":"impudents","lemma":"impudent","pos":"ADJ"} ,
		{"word":"impudique","word_nosc":"impudique","lemma":"impudique","pos":"ADJ"} ,
		{"word":"impudiques","word_nosc":"impudiques","lemma":"impudique","pos":"ADJ"} ,
		{"word":"impuissant","word_nosc":"impuissant","lemma":"impuissant","pos":"ADJ"} ,
		{"word":"impuissante","word_nosc":"impuissante","lemma":"impuissant","pos":"ADJ"} ,
		{"word":"impuissantes","word_nosc":"impuissantes","lemma":"impuissant","pos":"ADJ"} ,
		{"word":"impuissants","word_nosc":"impuissants","lemma":"impuissant","pos":"ADJ"} ,
		{"word":"impulsif","word_nosc":"impulsif","lemma":"impulsif","pos":"ADJ"} ,
		{"word":"impulsifs","word_nosc":"impulsifs","lemma":"impulsif","pos":"ADJ"} ,
		{"word":"impulsive","word_nosc":"impulsive","lemma":"impulsif","pos":"ADJ"} ,
		{"word":"impulsives","word_nosc":"impulsives","lemma":"impulsif","pos":"ADJ"} ,
		{"word":"impuni","word_nosc":"impuni","lemma":"impuni","pos":"ADJ"} ,
		{"word":"impunie","word_nosc":"impunie","lemma":"impuni","pos":"ADJ"} ,
		{"word":"impunis","word_nosc":"impunis","lemma":"impuni","pos":"ADJ"} ,
		{"word":"impur","word_nosc":"impur","lemma":"impur","pos":"ADJ"} ,
		{"word":"impure","word_nosc":"impure","lemma":"impur","pos":"ADJ"} ,
		{"word":"impures","word_nosc":"impures","lemma":"impur","pos":"ADJ"} ,
		{"word":"impurs","word_nosc":"impurs","lemma":"impur","pos":"ADJ"} ,
		{"word":"imputable","word_nosc":"imputable","lemma":"imputable","pos":"ADJ"} ,
		{"word":"imputables","word_nosc":"imputables","lemma":"imputable","pos":"ADJ"} ,
		{"word":"imputrescible","word_nosc":"imputrescible","lemma":"imputrescible","pos":"ADJ"} ,
		{"word":"imputrescibles","word_nosc":"imputrescibles","lemma":"imputrescible","pos":"ADJ"} ,
		{"word":"impécunieuse","word_nosc":"impecunieuse","lemma":"impécunieux","pos":"ADJ"} ,
		{"word":"impécunieux","word_nosc":"impecunieux","lemma":"impécunieux","pos":"ADJ"} ,
		{"word":"impénitent","word_nosc":"impenitent","lemma":"impénitent","pos":"ADJ"} ,
		{"word":"impénitente","word_nosc":"impenitente","lemma":"impénitent","pos":"ADJ"} ,
		{"word":"impénitents","word_nosc":"impenitents","lemma":"impénitent","pos":"ADJ"} ,
		{"word":"impénétrable","word_nosc":"impenetrable","lemma":"impénétrable","pos":"ADJ"} ,
		{"word":"impénétrables","word_nosc":"impenetrables","lemma":"impénétrable","pos":"ADJ"} ,
		{"word":"impératif","word_nosc":"imperatif","lemma":"impératif","pos":"ADJ"} ,
		{"word":"impératifs","word_nosc":"imperatifs","lemma":"impératif","pos":"ADJ"} ,
		{"word":"impérative","word_nosc":"imperative","lemma":"impératif","pos":"ADJ"} ,
		{"word":"impératives","word_nosc":"imperatives","lemma":"impératif","pos":"ADJ"} ,
		{"word":"impérial","word_nosc":"imperial","lemma":"impérial","pos":"ADJ"} ,
		{"word":"impériale","word_nosc":"imperiale","lemma":"impérial","pos":"ADJ"} ,
		{"word":"impériales","word_nosc":"imperiales","lemma":"impérial","pos":"ADJ"} ,
		{"word":"impérialiste","word_nosc":"imperialiste","lemma":"impérialiste","pos":"ADJ"} ,
		{"word":"impérialistes","word_nosc":"imperialistes","lemma":"impérialiste","pos":"ADJ"} ,
		{"word":"impériaux","word_nosc":"imperiaux","lemma":"impérial","pos":"ADJ"} ,
		{"word":"impérieuse","word_nosc":"imperieuse","lemma":"impérieux","pos":"ADJ"} ,
		{"word":"impérieuses","word_nosc":"imperieuses","lemma":"impérieux","pos":"ADJ"} ,
		{"word":"impérieux","word_nosc":"imperieux","lemma":"impérieux","pos":"ADJ"} ,
		{"word":"impérissable","word_nosc":"imperissable","lemma":"impérissable","pos":"ADJ"} ,
		{"word":"impérissables","word_nosc":"imperissables","lemma":"impérissable","pos":"ADJ"} ,
		{"word":"impétueuse","word_nosc":"impetueuse","lemma":"impétueux","pos":"ADJ"} ,
		{"word":"impétueuses","word_nosc":"impetueuses","lemma":"impétueux","pos":"ADJ"} ,
		{"word":"impétueux","word_nosc":"impetueux","lemma":"impétueux","pos":"ADJ"} ,
		{"word":"in","word_nosc":"in","lemma":"in","pos":"ADJ"} ,
		{"word":"in utero","word_nosc":"in utero","lemma":"in utero","pos":"ADJ"} ,
		{"word":"in vitro","word_nosc":"in vitro","lemma":"in vitro","pos":"ADJ"} ,
		{"word":"in-bord","word_nosc":"in-bord","lemma":"in-bord","pos":"ADJ"} ,
		{"word":"in-folio","word_nosc":"in-folio","lemma":"in-folio","pos":"ADJ"} ,
		{"word":"in-octavo","word_nosc":"in-octavo","lemma":"in-octavo","pos":"ADJ"} ,
		{"word":"in-quarto","word_nosc":"in-quarto","lemma":"in-quarto","pos":"ADJ"} ,
		{"word":"inabordable","word_nosc":"inabordable","lemma":"inabordable","pos":"ADJ"} ,
		{"word":"inabouti","word_nosc":"inabouti","lemma":"inabouti","pos":"ADJ"} ,
		{"word":"inaboutie","word_nosc":"inaboutie","lemma":"inabouti","pos":"ADJ"} ,
		{"word":"inacceptable","word_nosc":"inacceptable","lemma":"inacceptable","pos":"ADJ"} ,
		{"word":"inacceptables","word_nosc":"inacceptables","lemma":"inacceptable","pos":"ADJ"} ,
		{"word":"inaccessible","word_nosc":"inaccessible","lemma":"inaccessible","pos":"ADJ"} ,
		{"word":"inaccessibles","word_nosc":"inaccessibles","lemma":"inaccessible","pos":"ADJ"} ,
		{"word":"inaccompli","word_nosc":"inaccompli","lemma":"inaccompli","pos":"ADJ"} ,
		{"word":"inaccomplis","word_nosc":"inaccomplis","lemma":"inaccompli","pos":"ADJ"} ,
		{"word":"inaccoutumé","word_nosc":"inaccoutume","lemma":"inaccoutumé","pos":"ADJ"} ,
		{"word":"inaccoutumée","word_nosc":"inaccoutumee","lemma":"inaccoutumé","pos":"ADJ"} ,
		{"word":"inaccoutumées","word_nosc":"inaccoutumees","lemma":"inaccoutumé","pos":"ADJ"} ,
		{"word":"inachevé","word_nosc":"inacheve","lemma":"inachevé","pos":"ADJ"} ,
		{"word":"inachevée","word_nosc":"inachevee","lemma":"inachevé","pos":"ADJ"} ,
		{"word":"inachevées","word_nosc":"inachevees","lemma":"inachevé","pos":"ADJ"} ,
		{"word":"inachevés","word_nosc":"inacheves","lemma":"inachevé","pos":"ADJ"} ,
		{"word":"inactif","word_nosc":"inactif","lemma":"inactif","pos":"ADJ"} ,
		{"word":"inactifs","word_nosc":"inactifs","lemma":"inactif","pos":"ADJ"} ,
		{"word":"inactive","word_nosc":"inactive","lemma":"inactif","pos":"ADJ"} ,
		{"word":"inactives","word_nosc":"inactives","lemma":"inactif","pos":"ADJ"} ,
		{"word":"inactivé","word_nosc":"inactive","lemma":"inactivé","pos":"ADJ"} ,
		{"word":"inactuel","word_nosc":"inactuel","lemma":"inactuel","pos":"ADJ"} ,
		{"word":"inadaptable","word_nosc":"inadaptable","lemma":"inadaptable","pos":"ADJ"} ,
		{"word":"inadapté","word_nosc":"inadapte","lemma":"inadapté","pos":"ADJ"} ,
		{"word":"inadaptée","word_nosc":"inadaptee","lemma":"inadapté","pos":"ADJ"} ,
		{"word":"inadaptées","word_nosc":"inadaptees","lemma":"inadapté","pos":"ADJ"} ,
		{"word":"inadaptés","word_nosc":"inadaptes","lemma":"inadapté","pos":"ADJ"} ,
		{"word":"inadmissible","word_nosc":"inadmissible","lemma":"inadmissible","pos":"ADJ"} ,
		{"word":"inadmissibles","word_nosc":"inadmissibles","lemma":"inadmissible","pos":"ADJ"} ,
		{"word":"inadéquat","word_nosc":"inadequat","lemma":"inadéquat","pos":"ADJ"} ,
		{"word":"inadéquate","word_nosc":"inadequate","lemma":"inadéquat","pos":"ADJ"} ,
		{"word":"inadéquates","word_nosc":"inadequates","lemma":"inadéquat","pos":"ADJ"} ,
		{"word":"inadéquats","word_nosc":"inadequats","lemma":"inadéquat","pos":"ADJ"} ,
		{"word":"inaliénable","word_nosc":"inalienable","lemma":"inaliénable","pos":"ADJ"} ,
		{"word":"inaliénables","word_nosc":"inalienables","lemma":"inaliénable","pos":"ADJ"} ,
		{"word":"inaltérable","word_nosc":"inalterable","lemma":"inaltérable","pos":"ADJ"} ,
		{"word":"inaltérables","word_nosc":"inalterables","lemma":"inaltérable","pos":"ADJ"} ,
		{"word":"inaltéré","word_nosc":"inaltere","lemma":"inaltéré","pos":"ADJ"} ,
		{"word":"inaltérée","word_nosc":"inalteree","lemma":"inaltéré","pos":"ADJ"} ,
		{"word":"inaltérées","word_nosc":"inalterees","lemma":"inaltéré","pos":"ADJ"} ,
		{"word":"inaltérés","word_nosc":"inalteres","lemma":"inaltéré","pos":"ADJ"} ,
		{"word":"inamical","word_nosc":"inamical","lemma":"inamical","pos":"ADJ"} ,
		{"word":"inamicale","word_nosc":"inamicale","lemma":"inamical","pos":"ADJ"} ,
		{"word":"inamicaux","word_nosc":"inamicaux","lemma":"inamical","pos":"ADJ"} ,
		{"word":"inamovible","word_nosc":"inamovible","lemma":"inamovible","pos":"ADJ"} ,
		{"word":"inamovibles","word_nosc":"inamovibles","lemma":"inamovible","pos":"ADJ"} ,
		{"word":"inanalysable","word_nosc":"inanalysable","lemma":"inanalysable","pos":"ADJ"} ,
		{"word":"inanalysables","word_nosc":"inanalysables","lemma":"inanalysable","pos":"ADJ"} ,
		{"word":"inane","word_nosc":"inane","lemma":"inane","pos":"ADJ"} ,
		{"word":"inanimé","word_nosc":"inanime","lemma":"inanimé","pos":"ADJ"} ,
		{"word":"inanimée","word_nosc":"inanimee","lemma":"inanimé","pos":"ADJ"} ,
		{"word":"inanimées","word_nosc":"inanimees","lemma":"inanimé","pos":"ADJ"} ,
		{"word":"inanimés","word_nosc":"inanimes","lemma":"inanimé","pos":"ADJ"} ,
		{"word":"inapaisable","word_nosc":"inapaisable","lemma":"inapaisable","pos":"ADJ"} ,
		{"word":"inapaisée","word_nosc":"inapaisee","lemma":"inapaisé","pos":"ADJ"} ,
		{"word":"inapaisées","word_nosc":"inapaisees","lemma":"inapaisé","pos":"ADJ"} ,
		{"word":"inapaisés","word_nosc":"inapaises","lemma":"inapaisé","pos":"ADJ"} ,
		{"word":"inaperçu","word_nosc":"inapercu","lemma":"inaperçu","pos":"ADJ"} ,
		{"word":"inaperçue","word_nosc":"inapercue","lemma":"inaperçu","pos":"ADJ"} ,
		{"word":"inaperçues","word_nosc":"inapercues","lemma":"inaperçu","pos":"ADJ"} ,
		{"word":"inaperçus","word_nosc":"inapercus","lemma":"inaperçu","pos":"ADJ"} ,
		{"word":"inapplicable","word_nosc":"inapplicable","lemma":"inapplicable","pos":"ADJ"} ,
		{"word":"inapplicables","word_nosc":"inapplicables","lemma":"inapplicable","pos":"ADJ"} ,
		{"word":"inapprivoisables","word_nosc":"inapprivoisables","lemma":"inapprivoisable","pos":"ADJ"} ,
		{"word":"inapprochable","word_nosc":"inapprochable","lemma":"inapprochable","pos":"ADJ"} ,
		{"word":"inapproprié","word_nosc":"inapproprie","lemma":"inapproprié","pos":"ADJ"} ,
		{"word":"inappropriée","word_nosc":"inappropriee","lemma":"inapproprié","pos":"ADJ"} ,
		{"word":"inappropriées","word_nosc":"inappropriees","lemma":"inapproprié","pos":"ADJ"} ,
		{"word":"inappropriés","word_nosc":"inappropries","lemma":"inapproprié","pos":"ADJ"} ,
		{"word":"inappréciable","word_nosc":"inappreciable","lemma":"inappréciable","pos":"ADJ"} ,
		{"word":"inappréciables","word_nosc":"inappreciables","lemma":"inappréciable","pos":"ADJ"} ,
		{"word":"inappréciée","word_nosc":"inappreciee","lemma":"inapprécié","pos":"ADJ"} ,
		{"word":"inapte","word_nosc":"inapte","lemma":"inapte","pos":"ADJ"} ,
		{"word":"inaptes","word_nosc":"inaptes","lemma":"inapte","pos":"ADJ"} ,
		{"word":"inarrangeable","word_nosc":"inarrangeable","lemma":"inarrangeable","pos":"ADJ"} ,
		{"word":"inarticulé","word_nosc":"inarticule","lemma":"inarticulé","pos":"ADJ"} ,
		{"word":"inarticulée","word_nosc":"inarticulee","lemma":"inarticulé","pos":"ADJ"} ,
		{"word":"inarticulées","word_nosc":"inarticulees","lemma":"inarticulé","pos":"ADJ"} ,
		{"word":"inarticulés","word_nosc":"inarticules","lemma":"inarticulé","pos":"ADJ"} ,
		{"word":"inassimilable","word_nosc":"inassimilable","lemma":"inassimilable","pos":"ADJ"} ,
		{"word":"inassimilables","word_nosc":"inassimilables","lemma":"inassimilable","pos":"ADJ"} ,
		{"word":"inassouvi","word_nosc":"inassouvi","lemma":"inassouvi","pos":"ADJ"} ,
		{"word":"inassouvie","word_nosc":"inassouvie","lemma":"inassouvi","pos":"ADJ"} ,
		{"word":"inassouvies","word_nosc":"inassouvies","lemma":"inassouvi","pos":"ADJ"} ,
		{"word":"inassouvis","word_nosc":"inassouvis","lemma":"inassouvi","pos":"ADJ"} ,
		{"word":"inassouvissable","word_nosc":"inassouvissable","lemma":"inassouvissable","pos":"ADJ"} ,
		{"word":"inassouvissables","word_nosc":"inassouvissables","lemma":"inassouvissable","pos":"ADJ"} ,
		{"word":"inassurable","word_nosc":"inassurable","lemma":"inassurable","pos":"ADJ"} ,
		{"word":"inattaquable","word_nosc":"inattaquable","lemma":"inattaquable","pos":"ADJ"} ,
		{"word":"inattaquables","word_nosc":"inattaquables","lemma":"inattaquable","pos":"ADJ"} ,
		{"word":"inatteignable","word_nosc":"inatteignable","lemma":"inatteignable","pos":"ADJ"} ,
		{"word":"inatteignables","word_nosc":"inatteignables","lemma":"inatteignable","pos":"ADJ"} ,
		{"word":"inattendu","word_nosc":"inattendu","lemma":"inattendu","pos":"ADJ"} ,
		{"word":"inattendue","word_nosc":"inattendue","lemma":"inattendu","pos":"ADJ"} ,
		{"word":"inattendues","word_nosc":"inattendues","lemma":"inattendu","pos":"ADJ"} ,
		{"word":"inattendus","word_nosc":"inattendus","lemma":"inattendu","pos":"ADJ"} ,
		{"word":"inattentif","word_nosc":"inattentif","lemma":"inattentif","pos":"ADJ"} ,
		{"word":"inattentifs","word_nosc":"inattentifs","lemma":"inattentif","pos":"ADJ"} ,
		{"word":"inattentive","word_nosc":"inattentive","lemma":"inattentif","pos":"ADJ"} ,
		{"word":"inattentives","word_nosc":"inattentives","lemma":"inattentif","pos":"ADJ"} ,
		{"word":"inattrapable","word_nosc":"inattrapable","lemma":"inattrapable","pos":"ADJ"} ,
		{"word":"inaudible","word_nosc":"inaudible","lemma":"inaudible","pos":"ADJ"} ,
		{"word":"inaudibles","word_nosc":"inaudibles","lemma":"inaudible","pos":"ADJ"} ,
		{"word":"inaugural","word_nosc":"inaugural","lemma":"inaugural","pos":"ADJ"} ,
		{"word":"inaugurale","word_nosc":"inaugurale","lemma":"inaugural","pos":"ADJ"} ,
		{"word":"inaugurales","word_nosc":"inaugurales","lemma":"inaugural","pos":"ADJ"} ,
		{"word":"inaverti","word_nosc":"inaverti","lemma":"inaverti","pos":"ADJ"} ,
		{"word":"inavouable","word_nosc":"inavouable","lemma":"inavouable","pos":"ADJ"} ,
		{"word":"inavouables","word_nosc":"inavouables","lemma":"inavouable","pos":"ADJ"} ,
		{"word":"inavoué","word_nosc":"inavoue","lemma":"inavoué","pos":"ADJ"} ,
		{"word":"inavouée","word_nosc":"inavouee","lemma":"inavoué","pos":"ADJ"} ,
		{"word":"inavouées","word_nosc":"inavouees","lemma":"inavoué","pos":"ADJ"} ,
		{"word":"inavoués","word_nosc":"inavoues","lemma":"inavoué","pos":"ADJ"} ,
		{"word":"inca","word_nosc":"inca","lemma":"inca","pos":"ADJ"} ,
		{"word":"incalculable","word_nosc":"incalculable","lemma":"incalculable","pos":"ADJ"} ,
		{"word":"incalculables","word_nosc":"incalculables","lemma":"incalculable","pos":"ADJ"} ,
		{"word":"incandescent","word_nosc":"incandescent","lemma":"incandescent","pos":"ADJ"} ,
		{"word":"incandescente","word_nosc":"incandescente","lemma":"incandescent","pos":"ADJ"} ,
		{"word":"incandescentes","word_nosc":"incandescentes","lemma":"incandescent","pos":"ADJ"} ,
		{"word":"incandescents","word_nosc":"incandescents","lemma":"incandescent","pos":"ADJ"} ,
		{"word":"incantatoire","word_nosc":"incantatoire","lemma":"incantatoire","pos":"ADJ"} ,
		{"word":"incantatoires","word_nosc":"incantatoires","lemma":"incantatoire","pos":"ADJ"} ,
		{"word":"incapable","word_nosc":"incapable","lemma":"incapable","pos":"ADJ"} ,
		{"word":"incapables","word_nosc":"incapables","lemma":"incapable","pos":"ADJ"} ,
		{"word":"incapacitant","word_nosc":"incapacitant","lemma":"incapacitant","pos":"ADJ"} ,
		{"word":"incapacitante","word_nosc":"incapacitante","lemma":"incapacitant","pos":"ADJ"} ,
		{"word":"incapacitants","word_nosc":"incapacitants","lemma":"incapacitant","pos":"ADJ"} ,
		{"word":"incarcéré","word_nosc":"incarcere","lemma":"incarcéré","pos":"ADJ"} ,
		{"word":"incarcérée","word_nosc":"incarceree","lemma":"incarcéré","pos":"ADJ"} ,
		{"word":"incarcérées","word_nosc":"incarcerees","lemma":"incarcéré","pos":"ADJ"} ,
		{"word":"incarcérés","word_nosc":"incarceres","lemma":"incarcéré","pos":"ADJ"} ,
		{"word":"incarnadines","word_nosc":"incarnadines","lemma":"incarnadin","pos":"ADJ"} ,
		{"word":"incarnat","word_nosc":"incarnat","lemma":"incarnat","pos":"ADJ"} ,
		{"word":"incarné","word_nosc":"incarne","lemma":"incarné","pos":"ADJ"} ,
		{"word":"incarnée","word_nosc":"incarnee","lemma":"incarné","pos":"ADJ"} ,
		{"word":"incarnées","word_nosc":"incarnees","lemma":"incarné","pos":"ADJ"} ,
		{"word":"incarnés","word_nosc":"incarnes","lemma":"incarné","pos":"ADJ"} ,
		{"word":"incas","word_nosc":"incas","lemma":"inca","pos":"ADJ"} ,
		{"word":"incassable","word_nosc":"incassable","lemma":"incassable","pos":"ADJ"} ,
		{"word":"incassables","word_nosc":"incassables","lemma":"incassable","pos":"ADJ"} ,
		{"word":"incendiaire","word_nosc":"incendiaire","lemma":"incendiaire","pos":"ADJ"} ,
		{"word":"incendiaires","word_nosc":"incendiaires","lemma":"incendiaire","pos":"ADJ"} ,
		{"word":"incendié","word_nosc":"incendie","lemma":"incendié","pos":"ADJ"} ,
		{"word":"incendiée","word_nosc":"incendiee","lemma":"incendié","pos":"ADJ"} ,
		{"word":"incendiées","word_nosc":"incendiees","lemma":"incendié","pos":"ADJ"} ,
		{"word":"incendiés","word_nosc":"incendies","lemma":"incendié","pos":"ADJ"} ,
		{"word":"incernable","word_nosc":"incernable","lemma":"incernable","pos":"ADJ"} ,
		{"word":"incertain","word_nosc":"incertain","lemma":"incertain","pos":"ADJ"} ,
		{"word":"incertaine","word_nosc":"incertaine","lemma":"incertain","pos":"ADJ"} ,
		{"word":"incertaines","word_nosc":"incertaines","lemma":"incertain","pos":"ADJ"} ,
		{"word":"incertains","word_nosc":"incertains","lemma":"incertain","pos":"ADJ"} ,
		{"word":"incessant","word_nosc":"incessant","lemma":"incessant","pos":"ADJ"} ,
		{"word":"incessante","word_nosc":"incessante","lemma":"incessant","pos":"ADJ"} ,
		{"word":"incessantes","word_nosc":"incessantes","lemma":"incessant","pos":"ADJ"} ,
		{"word":"incessants","word_nosc":"incessants","lemma":"incessant","pos":"ADJ"} ,
		{"word":"incestueuse","word_nosc":"incestueuse","lemma":"incestueux","pos":"ADJ"} ,
		{"word":"incestueuses","word_nosc":"incestueuses","lemma":"incestueux","pos":"ADJ"} ,
		{"word":"incestueux","word_nosc":"incestueux","lemma":"incestueux","pos":"ADJ"} ,
		{"word":"inchangeable","word_nosc":"inchangeable","lemma":"inchangeable","pos":"ADJ"} ,
		{"word":"inchangé","word_nosc":"inchange","lemma":"inchangé","pos":"ADJ"} ,
		{"word":"inchangée","word_nosc":"inchangee","lemma":"inchangé","pos":"ADJ"} ,
		{"word":"inchangées","word_nosc":"inchangees","lemma":"inchangé","pos":"ADJ"} ,
		{"word":"inchangés","word_nosc":"inchanges","lemma":"inchangé","pos":"ADJ"} ,
		{"word":"inchavirable","word_nosc":"inchavirable","lemma":"inchavirable","pos":"ADJ"} ,
		{"word":"inchiffrable","word_nosc":"inchiffrable","lemma":"inchiffrable","pos":"ADJ"} ,
		{"word":"incident","word_nosc":"incident","lemma":"incident","pos":"ADJ"} ,
		{"word":"incidente","word_nosc":"incidente","lemma":"incident","pos":"ADJ"} ,
		{"word":"incidentes","word_nosc":"incidentes","lemma":"incident","pos":"ADJ"} ,
		{"word":"incidents","word_nosc":"incidents","lemma":"incident","pos":"ADJ"} ,
		{"word":"incirconcis","word_nosc":"incirconcis","lemma":"incirconcis","pos":"ADJ"} ,
		{"word":"incise","word_nosc":"incise","lemma":"incise","pos":"ADJ"} ,
		{"word":"incisif","word_nosc":"incisif","lemma":"incisif","pos":"ADJ"} ,
		{"word":"incisifs","word_nosc":"incisifs","lemma":"incisif","pos":"ADJ"} ,
		{"word":"incisive","word_nosc":"incisive","lemma":"incisif","pos":"ADJ"} ,
		{"word":"incisives","word_nosc":"incisives","lemma":"incisif","pos":"ADJ"} ,
		{"word":"incitant","word_nosc":"incitant","lemma":"incitant","pos":"ADJ"} ,
		{"word":"incitatif","word_nosc":"incitatif","lemma":"incitatif","pos":"ADJ"} ,
		{"word":"incivil","word_nosc":"incivil","lemma":"incivil","pos":"ADJ"} ,
		{"word":"incivils","word_nosc":"incivils","lemma":"incivil","pos":"ADJ"} ,
		{"word":"inclassable","word_nosc":"inclassable","lemma":"inclassable","pos":"ADJ"} ,
		{"word":"inclassables","word_nosc":"inclassables","lemma":"inclassable","pos":"ADJ"} ,
		{"word":"inclinable","word_nosc":"inclinable","lemma":"inclinable","pos":"ADJ"} ,
		{"word":"inclinables","word_nosc":"inclinables","lemma":"inclinable","pos":"ADJ"} ,
		{"word":"inclinante","word_nosc":"inclinante","lemma":"inclinant","pos":"ADJ"} ,
		{"word":"incliné","word_nosc":"incline","lemma":"incliné","pos":"ADJ"} ,
		{"word":"inclinée","word_nosc":"inclinee","lemma":"incliné","pos":"ADJ"} ,
		{"word":"inclinées","word_nosc":"inclinees","lemma":"incliné","pos":"ADJ"} ,
		{"word":"inclinés","word_nosc":"inclines","lemma":"incliné","pos":"ADJ"} ,
		{"word":"inclus","word_nosc":"inclus","lemma":"inclus","pos":"ADJ"} ,
		{"word":"incluse","word_nosc":"incluse","lemma":"inclus","pos":"ADJ"} ,
		{"word":"incluses","word_nosc":"incluses","lemma":"inclus","pos":"ADJ"} ,
		{"word":"inclusive","word_nosc":"inclusive","lemma":"inclusif","pos":"ADJ"} ,
		{"word":"inclément","word_nosc":"inclement","lemma":"inclément","pos":"ADJ"} ,
		{"word":"inclémente","word_nosc":"inclemente","lemma":"inclément","pos":"ADJ"} ,
		{"word":"incoercible","word_nosc":"incoercible","lemma":"incoercible","pos":"ADJ"} ,
		{"word":"incoercibles","word_nosc":"incoercibles","lemma":"incoercible","pos":"ADJ"} ,
		{"word":"incohérent","word_nosc":"incoherent","lemma":"incohérent","pos":"ADJ"} ,
		{"word":"incohérente","word_nosc":"incoherente","lemma":"incohérent","pos":"ADJ"} ,
		{"word":"incohérentes","word_nosc":"incoherentes","lemma":"incohérent","pos":"ADJ"} ,
		{"word":"incohérents","word_nosc":"incoherents","lemma":"incohérent","pos":"ADJ"} ,
		{"word":"incoiffables","word_nosc":"incoiffables","lemma":"incoiffable","pos":"ADJ"} ,
		{"word":"incollable","word_nosc":"incollable","lemma":"incollable","pos":"ADJ"} ,
		{"word":"incolore","word_nosc":"incolore","lemma":"incolore","pos":"ADJ"} ,
		{"word":"incolores","word_nosc":"incolores","lemma":"incolore","pos":"ADJ"} ,
		{"word":"incombustible","word_nosc":"incombustible","lemma":"incombustible","pos":"ADJ"} ,
		{"word":"incombustibles","word_nosc":"incombustibles","lemma":"incombustible","pos":"ADJ"} ,
		{"word":"incomestible","word_nosc":"incomestible","lemma":"incomestible","pos":"ADJ"} ,
		{"word":"incommensurable","word_nosc":"incommensurable","lemma":"incommensurable","pos":"ADJ"} ,
		{"word":"incommensurables","word_nosc":"incommensurables","lemma":"incommensurable","pos":"ADJ"} ,
		{"word":"incommodant","word_nosc":"incommodant","lemma":"incommodant","pos":"ADJ"} ,
		{"word":"incommodante","word_nosc":"incommodante","lemma":"incommodant","pos":"ADJ"} ,
		{"word":"incommodantes","word_nosc":"incommodantes","lemma":"incommodant","pos":"ADJ"} ,
		{"word":"incommode","word_nosc":"incommode","lemma":"incommode","pos":"ADJ"} ,
		{"word":"incommodes","word_nosc":"incommodes","lemma":"incommode","pos":"ADJ"} ,
		{"word":"incommodé","word_nosc":"incommode","lemma":"incommodé","pos":"ADJ"} ,
		{"word":"incommodée","word_nosc":"incommodee","lemma":"incommodé","pos":"ADJ"} ,
		{"word":"incommunicable","word_nosc":"incommunicable","lemma":"incommunicable","pos":"ADJ"} ,
		{"word":"incommunicables","word_nosc":"incommunicables","lemma":"incommunicable","pos":"ADJ"} ,
		{"word":"incomparable","word_nosc":"incomparable","lemma":"incomparable","pos":"ADJ"} ,
		{"word":"incomparables","word_nosc":"incomparables","lemma":"incomparable","pos":"ADJ"} ,
		{"word":"incompatible","word_nosc":"incompatible","lemma":"incompatible","pos":"ADJ"} ,
		{"word":"incompatibles","word_nosc":"incompatibles","lemma":"incompatible","pos":"ADJ"} ,
		{"word":"incomplet","word_nosc":"incomplet","lemma":"incomplet","pos":"ADJ"} ,
		{"word":"incomplets","word_nosc":"incomplets","lemma":"incomplet","pos":"ADJ"} ,
		{"word":"incomplète","word_nosc":"incomplete","lemma":"incomplet","pos":"ADJ"} ,
		{"word":"incomplètes","word_nosc":"incompletes","lemma":"incomplet","pos":"ADJ"} ,
		{"word":"incompressible","word_nosc":"incompressible","lemma":"incompressible","pos":"ADJ"} ,
		{"word":"incompris","word_nosc":"incompris","lemma":"incompris","pos":"ADJ"} ,
		{"word":"incomprise","word_nosc":"incomprise","lemma":"incompris","pos":"ADJ"} ,
		{"word":"incomprises","word_nosc":"incomprises","lemma":"incompris","pos":"ADJ"} ,
		{"word":"incompréhensible","word_nosc":"incomprehensible","lemma":"incompréhensible","pos":"ADJ"} ,
		{"word":"incompréhensibles","word_nosc":"incomprehensibles","lemma":"incompréhensible","pos":"ADJ"} ,
		{"word":"incompréhensif","word_nosc":"incomprehensif","lemma":"incompréhensif","pos":"ADJ"} ,
		{"word":"incompréhensifs","word_nosc":"incomprehensifs","lemma":"incompréhensif","pos":"ADJ"} ,
		{"word":"incompétent","word_nosc":"incompetent","lemma":"incompétent","pos":"ADJ"} ,
		{"word":"incompétente","word_nosc":"incompetente","lemma":"incompétent","pos":"ADJ"} ,
		{"word":"incompétents","word_nosc":"incompetents","lemma":"incompétent","pos":"ADJ"} ,
		{"word":"inconcevable","word_nosc":"inconcevable","lemma":"inconcevable","pos":"ADJ"} ,
		{"word":"inconcevables","word_nosc":"inconcevables","lemma":"inconcevable","pos":"ADJ"} ,
		{"word":"inconciliable","word_nosc":"inconciliable","lemma":"inconciliable","pos":"ADJ"} ,
		{"word":"inconciliables","word_nosc":"inconciliables","lemma":"inconciliable","pos":"ADJ"} ,
		{"word":"inconditionnel","word_nosc":"inconditionnel","lemma":"inconditionnel","pos":"ADJ"} ,
		{"word":"inconditionnelle","word_nosc":"inconditionnelle","lemma":"inconditionnel","pos":"ADJ"} ,
		{"word":"inconditionnelles","word_nosc":"inconditionnelles","lemma":"inconditionnel","pos":"ADJ"} ,
		{"word":"inconditionnels","word_nosc":"inconditionnels","lemma":"inconditionnel","pos":"ADJ"} ,
		{"word":"inconditionné","word_nosc":"inconditionne","lemma":"inconditionné","pos":"ADJ"} ,
		{"word":"inconditionnée","word_nosc":"inconditionnee","lemma":"inconditionné","pos":"ADJ"} ,
		{"word":"inconfortable","word_nosc":"inconfortable","lemma":"inconfortable","pos":"ADJ"} ,
		{"word":"inconfortables","word_nosc":"inconfortables","lemma":"inconfortable","pos":"ADJ"} ,
		{"word":"incongelables","word_nosc":"incongelables","lemma":"incongelable","pos":"ADJ"} ,
		{"word":"incongru","word_nosc":"incongru","lemma":"incongru","pos":"ADJ"} ,
		{"word":"incongrue","word_nosc":"incongrue","lemma":"incongru","pos":"ADJ"} ,
		{"word":"incongrues","word_nosc":"incongrues","lemma":"incongru","pos":"ADJ"} ,
		{"word":"incongrus","word_nosc":"incongrus","lemma":"incongru","pos":"ADJ"} ,
		{"word":"inconnaissable","word_nosc":"inconnaissable","lemma":"inconnaissable","pos":"ADJ"} ,
		{"word":"inconnu","word_nosc":"inconnu","lemma":"inconnu","pos":"ADJ"} ,
		{"word":"inconnue","word_nosc":"inconnue","lemma":"inconnu","pos":"ADJ"} ,
		{"word":"inconnues","word_nosc":"inconnues","lemma":"inconnu","pos":"ADJ"} ,
		{"word":"inconnus","word_nosc":"inconnus","lemma":"inconnu","pos":"ADJ"} ,
		{"word":"inconscient","word_nosc":"inconscient","lemma":"inconscient","pos":"ADJ"} ,
		{"word":"inconsciente","word_nosc":"inconsciente","lemma":"inconscient","pos":"ADJ"} ,
		{"word":"inconscientes","word_nosc":"inconscientes","lemma":"inconscient","pos":"ADJ"} ,
		{"word":"inconscients","word_nosc":"inconscients","lemma":"inconscient","pos":"ADJ"} ,
		{"word":"inconsidéré","word_nosc":"inconsidere","lemma":"inconsidéré","pos":"ADJ"} ,
		{"word":"inconsidérée","word_nosc":"inconsideree","lemma":"inconsidéré","pos":"ADJ"} ,
		{"word":"inconsidérées","word_nosc":"inconsiderees","lemma":"inconsidéré","pos":"ADJ"} ,
		{"word":"inconsidérés","word_nosc":"inconsideres","lemma":"inconsidéré","pos":"ADJ"} ,
		{"word":"inconsistant","word_nosc":"inconsistant","lemma":"inconsistant","pos":"ADJ"} ,
		{"word":"inconsistante","word_nosc":"inconsistante","lemma":"inconsistant","pos":"ADJ"} ,
		{"word":"inconsistantes","word_nosc":"inconsistantes","lemma":"inconsistant","pos":"ADJ"} ,
		{"word":"inconsistants","word_nosc":"inconsistants","lemma":"inconsistant","pos":"ADJ"} ,
		{"word":"inconsolable","word_nosc":"inconsolable","lemma":"inconsolable","pos":"ADJ"} ,
		{"word":"inconsolables","word_nosc":"inconsolables","lemma":"inconsolable","pos":"ADJ"} ,
		{"word":"inconsolé","word_nosc":"inconsole","lemma":"inconsolé","pos":"ADJ"} ,
		{"word":"inconsolée","word_nosc":"inconsolee","lemma":"inconsolé","pos":"ADJ"} ,
		{"word":"inconsolées","word_nosc":"inconsolees","lemma":"inconsolé","pos":"ADJ"} ,
		{"word":"inconsolés","word_nosc":"inconsoles","lemma":"inconsolé","pos":"ADJ"} ,
		{"word":"inconsommable","word_nosc":"inconsommable","lemma":"inconsommable","pos":"ADJ"} ,
		{"word":"inconstant","word_nosc":"inconstant","lemma":"inconstant","pos":"ADJ"} ,
		{"word":"inconstante","word_nosc":"inconstante","lemma":"inconstant","pos":"ADJ"} ,
		{"word":"inconstants","word_nosc":"inconstants","lemma":"inconstant","pos":"ADJ"} ,
		{"word":"inconstitutionnel","word_nosc":"inconstitutionnel","lemma":"inconstitutionnel","pos":"ADJ"} ,
		{"word":"inconstitutionnelle","word_nosc":"inconstitutionnelle","lemma":"inconstitutionnel","pos":"ADJ"} ,
		{"word":"inconstructibles","word_nosc":"inconstructibles","lemma":"inconstructible","pos":"ADJ"} ,
		{"word":"inconséquent","word_nosc":"inconsequent","lemma":"inconséquent","pos":"ADJ"} ,
		{"word":"inconséquente","word_nosc":"inconsequente","lemma":"inconséquent","pos":"ADJ"} ,
		{"word":"inconséquents","word_nosc":"inconsequents","lemma":"inconséquent","pos":"ADJ"} ,
		{"word":"incontestable","word_nosc":"incontestable","lemma":"incontestable","pos":"ADJ"} ,
		{"word":"incontestables","word_nosc":"incontestables","lemma":"incontestable","pos":"ADJ"} ,
		{"word":"incontesté","word_nosc":"inconteste","lemma":"incontesté","pos":"ADJ"} ,
		{"word":"incontestée","word_nosc":"incontestee","lemma":"incontesté","pos":"ADJ"} ,
		{"word":"incontestés","word_nosc":"incontestes","lemma":"incontesté","pos":"ADJ"} ,
		{"word":"incontinent","word_nosc":"incontinent","lemma":"incontinent","pos":"ADJ"} ,
		{"word":"incontinente","word_nosc":"incontinente","lemma":"incontinent","pos":"ADJ"} ,
		{"word":"incontinentes","word_nosc":"incontinentes","lemma":"incontinent","pos":"ADJ"} ,
		{"word":"incontinents","word_nosc":"incontinents","lemma":"incontinent","pos":"ADJ"} ,
		{"word":"incontournable","word_nosc":"incontournable","lemma":"incontournable","pos":"ADJ"} ,
		{"word":"incontournables","word_nosc":"incontournables","lemma":"incontournable","pos":"ADJ"} ,
		{"word":"incontrôlable","word_nosc":"incontrolable","lemma":"incontrôlable","pos":"ADJ"} ,
		{"word":"incontrôlables","word_nosc":"incontrolables","lemma":"incontrôlable","pos":"ADJ"} ,
		{"word":"incontrôlé","word_nosc":"incontrole","lemma":"incontrôlé","pos":"ADJ"} ,
		{"word":"incontrôlée","word_nosc":"incontrolee","lemma":"incontrôlé","pos":"ADJ"} ,
		{"word":"incontrôlées","word_nosc":"incontrolees","lemma":"incontrôlé","pos":"ADJ"} ,
		{"word":"incontrôlés","word_nosc":"incontroles","lemma":"incontrôlé","pos":"ADJ"} ,
		{"word":"inconvenant","word_nosc":"inconvenant","lemma":"inconvenant","pos":"ADJ"} ,
		{"word":"inconvenante","word_nosc":"inconvenante","lemma":"inconvenant","pos":"ADJ"} ,
		{"word":"inconvenantes","word_nosc":"inconvenantes","lemma":"inconvenant","pos":"ADJ"} ,
		{"word":"inconvenants","word_nosc":"inconvenants","lemma":"inconvenant","pos":"ADJ"} ,
		{"word":"inconvertible","word_nosc":"inconvertible","lemma":"inconvertible","pos":"ADJ"} ,
		{"word":"inconvertissable","word_nosc":"inconvertissable","lemma":"inconvertissable","pos":"ADJ"} ,
		{"word":"incorporable","word_nosc":"incorporable","lemma":"incorporable","pos":"ADJ"} ,
		{"word":"incorporel","word_nosc":"incorporel","lemma":"incorporel","pos":"ADJ"} ,
		{"word":"incorporelle","word_nosc":"incorporelle","lemma":"incorporel","pos":"ADJ"} ,
		{"word":"incorporelles","word_nosc":"incorporelles","lemma":"incorporel","pos":"ADJ"} ,
		{"word":"incorrect","word_nosc":"incorrect","lemma":"incorrect","pos":"ADJ"} ,
		{"word":"incorrecte","word_nosc":"incorrecte","lemma":"incorrect","pos":"ADJ"} ,
		{"word":"incorrects","word_nosc":"incorrects","lemma":"incorrect","pos":"ADJ"} ,
		{"word":"incorrigible","word_nosc":"incorrigible","lemma":"incorrigible","pos":"ADJ"} ,
		{"word":"incorrigibles","word_nosc":"incorrigibles","lemma":"incorrigible","pos":"ADJ"} ,
		{"word":"incorruptible","word_nosc":"incorruptible","lemma":"incorruptible","pos":"ADJ"} ,
		{"word":"incorruptibles","word_nosc":"incorruptibles","lemma":"incorruptible","pos":"ADJ"} ,
		{"word":"increvable","word_nosc":"increvable","lemma":"increvable","pos":"ADJ"} ,
		{"word":"increvables","word_nosc":"increvables","lemma":"increvable","pos":"ADJ"} ,
		{"word":"incrochetable","word_nosc":"incrochetable","lemma":"incrochetable","pos":"ADJ"} ,
		{"word":"incroyable","word_nosc":"incroyable","lemma":"incroyable","pos":"ADJ"} ,
		{"word":"incroyables","word_nosc":"incroyables","lemma":"incroyable","pos":"ADJ"} ,
		{"word":"incroyant","word_nosc":"incroyant","lemma":"incroyant","pos":"ADJ"} ,
		{"word":"incroyants","word_nosc":"incroyants","lemma":"incroyant","pos":"ADJ"} ,
		{"word":"incrédule","word_nosc":"incredule","lemma":"incrédule","pos":"ADJ"} ,
		{"word":"incrédules","word_nosc":"incredules","lemma":"incrédule","pos":"ADJ"} ,
		{"word":"incréé","word_nosc":"incree","lemma":"incréé","pos":"ADJ"} ,
		{"word":"incréée","word_nosc":"increee","lemma":"incréé","pos":"ADJ"} ,
		{"word":"incubateur","word_nosc":"incubateur","lemma":"incubateur","pos":"ADJ"} ,
		{"word":"incubateurs","word_nosc":"incubateurs","lemma":"incubateur","pos":"ADJ"} ,
		{"word":"inculpé","word_nosc":"inculpe","lemma":"inculpé","pos":"ADJ"} ,
		{"word":"inculpée","word_nosc":"inculpee","lemma":"inculpé","pos":"ADJ"} ,
		{"word":"inculpés","word_nosc":"inculpes","lemma":"inculpé","pos":"ADJ"} ,
		{"word":"inculte","word_nosc":"inculte","lemma":"inculte","pos":"ADJ"} ,
		{"word":"incultes","word_nosc":"incultes","lemma":"inculte","pos":"ADJ"} ,
		{"word":"incultivable","word_nosc":"incultivable","lemma":"incultivable","pos":"ADJ"} ,
		{"word":"incultivables","word_nosc":"incultivables","lemma":"incultivable","pos":"ADJ"} ,
		{"word":"incurable","word_nosc":"incurable","lemma":"incurable","pos":"ADJ"} ,
		{"word":"incurables","word_nosc":"incurables","lemma":"incurable","pos":"ADJ"} ,
		{"word":"incurieux","word_nosc":"incurieux","lemma":"incurieux","pos":"ADJ"} ,
		{"word":"incurvé","word_nosc":"incurve","lemma":"incurvé","pos":"ADJ"} ,
		{"word":"incurvée","word_nosc":"incurvee","lemma":"incurvé","pos":"ADJ"} ,
		{"word":"incurvées","word_nosc":"incurvees","lemma":"incurvé","pos":"ADJ"} ,
		{"word":"incurvés","word_nosc":"incurves","lemma":"incurvé","pos":"ADJ"} ,
		{"word":"incus","word_nosc":"incus","lemma":"incus","pos":"ADJ"} ,
		{"word":"indansable","word_nosc":"indansable","lemma":"indansable","pos":"ADJ"} ,
		{"word":"indemne","word_nosc":"indemne","lemma":"indemne","pos":"ADJ"} ,
		{"word":"indemnes","word_nosc":"indemnes","lemma":"indemne","pos":"ADJ"} ,
		{"word":"indemnisable","word_nosc":"indemnisable","lemma":"indemnisable","pos":"ADJ"} ,
		{"word":"indescriptible","word_nosc":"indescriptible","lemma":"indescriptible","pos":"ADJ"} ,
		{"word":"indescriptibles","word_nosc":"indescriptibles","lemma":"indescriptible","pos":"ADJ"} ,
		{"word":"indestructible","word_nosc":"indestructible","lemma":"indestructible","pos":"ADJ"} ,
		{"word":"indestructibles","word_nosc":"indestructibles","lemma":"indestructible","pos":"ADJ"} ,
		{"word":"indicateur","word_nosc":"indicateur","lemma":"indicateur","pos":"ADJ"} ,
		{"word":"indicateurs","word_nosc":"indicateurs","lemma":"indicateur","pos":"ADJ"} ,
		{"word":"indicatif","word_nosc":"indicatif","lemma":"indicatif","pos":"ADJ"} ,
		{"word":"indicatifs","word_nosc":"indicatifs","lemma":"indicatif","pos":"ADJ"} ,
		{"word":"indicatrice","word_nosc":"indicatrice","lemma":"indicateur","pos":"ADJ"} ,
		{"word":"indicatrices","word_nosc":"indicatrices","lemma":"indicateur","pos":"ADJ"} ,
		{"word":"indiciaire","word_nosc":"indiciaire","lemma":"indiciaire","pos":"ADJ"} ,
		{"word":"indiciaires","word_nosc":"indiciaires","lemma":"indiciaire","pos":"ADJ"} ,
		{"word":"indicible","word_nosc":"indicible","lemma":"indicible","pos":"ADJ"} ,
		{"word":"indicibles","word_nosc":"indicibles","lemma":"indicible","pos":"ADJ"} ,
		{"word":"indien","word_nosc":"indien","lemma":"indien","pos":"ADJ"} ,
		{"word":"indienne","word_nosc":"indienne","lemma":"indien","pos":"ADJ"} ,
		{"word":"indiennes","word_nosc":"indiennes","lemma":"indien","pos":"ADJ"} ,
		{"word":"indiens","word_nosc":"indiens","lemma":"indien","pos":"ADJ"} ,
		{"word":"indifférencié","word_nosc":"indifferencie","lemma":"indifférencié","pos":"ADJ"} ,
		{"word":"indifférenciée","word_nosc":"indifferenciee","lemma":"indifférencié","pos":"ADJ"} ,
		{"word":"indifférenciés","word_nosc":"indifferencies","lemma":"indifférencié","pos":"ADJ"} ,
		{"word":"indifférent","word_nosc":"indifferent","lemma":"indifférent","pos":"ADJ"} ,
		{"word":"indifférente","word_nosc":"indifferente","lemma":"indifférent","pos":"ADJ"} ,
		{"word":"indifférentes","word_nosc":"indifferentes","lemma":"indifférent","pos":"ADJ"} ,
		{"word":"indifférents","word_nosc":"indifferents","lemma":"indifférent","pos":"ADJ"} ,
		{"word":"indigent","word_nosc":"indigent","lemma":"indigent","pos":"ADJ"} ,
		{"word":"indigente","word_nosc":"indigente","lemma":"indigent","pos":"ADJ"} ,
		{"word":"indigentes","word_nosc":"indigentes","lemma":"indigent","pos":"ADJ"} ,
		{"word":"indigents","word_nosc":"indigents","lemma":"indigent","pos":"ADJ"} ,
		{"word":"indigeste","word_nosc":"indigeste","lemma":"indigeste","pos":"ADJ"} ,
		{"word":"indigestes","word_nosc":"indigestes","lemma":"indigeste","pos":"ADJ"} ,
		{"word":"indigne","word_nosc":"indigne","lemma":"indigne","pos":"ADJ"} ,
		{"word":"indignes","word_nosc":"indignes","lemma":"indigne","pos":"ADJ"} ,
		{"word":"indigné","word_nosc":"indigne","lemma":"indigné","pos":"ADJ"} ,
		{"word":"indignée","word_nosc":"indignee","lemma":"indigné","pos":"ADJ"} ,
		{"word":"indignées","word_nosc":"indignees","lemma":"indigné","pos":"ADJ"} ,
		{"word":"indignés","word_nosc":"indignes","lemma":"indigné","pos":"ADJ"} ,
		{"word":"indigo","word_nosc":"indigo","lemma":"indigo","pos":"ADJ"} ,
		{"word":"indigène","word_nosc":"indigene","lemma":"indigène","pos":"ADJ"} ,
		{"word":"indigènes","word_nosc":"indigenes","lemma":"indigène","pos":"ADJ"} ,
		{"word":"indiqué","word_nosc":"indique","lemma":"indiqué","pos":"ADJ"} ,
		{"word":"indiquée","word_nosc":"indiquee","lemma":"indiqué","pos":"ADJ"} ,
		{"word":"indiquées","word_nosc":"indiquees","lemma":"indiqué","pos":"ADJ"} ,
		{"word":"indiqués","word_nosc":"indiques","lemma":"indiqué","pos":"ADJ"} ,
		{"word":"indirect","word_nosc":"indirect","lemma":"indirect","pos":"ADJ"} ,
		{"word":"indirecte","word_nosc":"indirecte","lemma":"indirect","pos":"ADJ"} ,
		{"word":"indirectes","word_nosc":"indirectes","lemma":"indirect","pos":"ADJ"} ,
		{"word":"indirects","word_nosc":"indirects","lemma":"indirect","pos":"ADJ"} ,
		{"word":"indiscernable","word_nosc":"indiscernable","lemma":"indiscernable","pos":"ADJ"} ,
		{"word":"indiscernables","word_nosc":"indiscernables","lemma":"indiscernable","pos":"ADJ"} ,
		{"word":"indiscipliné","word_nosc":"indiscipline","lemma":"indiscipliné","pos":"ADJ"} ,
		{"word":"indisciplinée","word_nosc":"indisciplinee","lemma":"indiscipliné","pos":"ADJ"} ,
		{"word":"indisciplinées","word_nosc":"indisciplinees","lemma":"indiscipliné","pos":"ADJ"} ,
		{"word":"indisciplinés","word_nosc":"indisciplines","lemma":"indiscipliné","pos":"ADJ"} ,
		{"word":"indiscret","word_nosc":"indiscret","lemma":"indiscret","pos":"ADJ"} ,
		{"word":"indiscrets","word_nosc":"indiscrets","lemma":"indiscret","pos":"ADJ"} ,
		{"word":"indiscrète","word_nosc":"indiscrete","lemma":"indiscret","pos":"ADJ"} ,
		{"word":"indiscrètes","word_nosc":"indiscretes","lemma":"indiscret","pos":"ADJ"} ,
		{"word":"indiscutable","word_nosc":"indiscutable","lemma":"indiscutable","pos":"ADJ"} ,
		{"word":"indiscutables","word_nosc":"indiscutables","lemma":"indiscutable","pos":"ADJ"} ,
		{"word":"indiscuté","word_nosc":"indiscute","lemma":"indiscuté","pos":"ADJ"} ,
		{"word":"indiscutée","word_nosc":"indiscutee","lemma":"indiscuté","pos":"ADJ"} ,
		{"word":"indiscutés","word_nosc":"indiscutes","lemma":"indiscuté","pos":"ADJ"} ,
		{"word":"indispensable","word_nosc":"indispensable","lemma":"indispensable","pos":"ADJ"} ,
		{"word":"indispensables","word_nosc":"indispensables","lemma":"indispensable","pos":"ADJ"} ,
		{"word":"indisponible","word_nosc":"indisponible","lemma":"indisponible","pos":"ADJ"} ,
		{"word":"indisponibles","word_nosc":"indisponibles","lemma":"indisponible","pos":"ADJ"} ,
		{"word":"indispose","word_nosc":"indispose","lemma":"indispos","pos":"ADJ"} ,
		{"word":"indisposé","word_nosc":"indispose","lemma":"indisposé","pos":"ADJ"} ,
		{"word":"indisposée","word_nosc":"indisposee","lemma":"indisposé","pos":"ADJ"} ,
		{"word":"indissociable","word_nosc":"indissociable","lemma":"indissociable","pos":"ADJ"} ,
		{"word":"indissociables","word_nosc":"indissociables","lemma":"indissociable","pos":"ADJ"} ,
		{"word":"indissociés","word_nosc":"indissocies","lemma":"indissocié","pos":"ADJ"} ,
		{"word":"indissoluble","word_nosc":"indissoluble","lemma":"indissoluble","pos":"ADJ"} ,
		{"word":"indissolubles","word_nosc":"indissolubles","lemma":"indissoluble","pos":"ADJ"} ,
		{"word":"indistinct","word_nosc":"indistinct","lemma":"indistinct","pos":"ADJ"} ,
		{"word":"indistincte","word_nosc":"indistincte","lemma":"indistinct","pos":"ADJ"} ,
		{"word":"indistinctes","word_nosc":"indistinctes","lemma":"indistinct","pos":"ADJ"} ,
		{"word":"indistincts","word_nosc":"indistincts","lemma":"indistinct","pos":"ADJ"} ,
		{"word":"individualiste","word_nosc":"individualiste","lemma":"individualiste","pos":"ADJ"} ,
		{"word":"individualistes","word_nosc":"individualistes","lemma":"individualiste","pos":"ADJ"} ,
		{"word":"individualisé","word_nosc":"individualise","lemma":"individualisé","pos":"ADJ"} ,
		{"word":"individuel","word_nosc":"individuel","lemma":"individuel","pos":"ADJ"} ,
		{"word":"individuelle","word_nosc":"individuelle","lemma":"individuel","pos":"ADJ"} ,
		{"word":"individuelles","word_nosc":"individuelles","lemma":"individuel","pos":"ADJ"} ,
		{"word":"individuels","word_nosc":"individuels","lemma":"individuel","pos":"ADJ"} ,
		{"word":"individuée","word_nosc":"individuee","lemma":"individué","pos":"ADJ"} ,
		{"word":"indivis","word_nosc":"indivis","lemma":"indivis","pos":"ADJ"} ,
		{"word":"indivises","word_nosc":"indivises","lemma":"indivis","pos":"ADJ"} ,
		{"word":"indivisible","word_nosc":"indivisible","lemma":"indivisible","pos":"ADJ"} ,
		{"word":"indivisibles","word_nosc":"indivisibles","lemma":"indivisible","pos":"ADJ"} ,
		{"word":"indivisé","word_nosc":"indivise","lemma":"indivisé","pos":"ADJ"} ,
		{"word":"indo-européen","word_nosc":"indo-europeen","lemma":"indo-européen","pos":"ADJ"} ,
		{"word":"indo-européenne","word_nosc":"indo-europeenne","lemma":"indo-européen","pos":"ADJ"} ,
		{"word":"indo-européennes","word_nosc":"indo-europeennes","lemma":"indo-européen","pos":"ADJ"} ,
		{"word":"indo-européens","word_nosc":"indo-europeens","lemma":"indo-européen","pos":"ADJ"} ,
		{"word":"indochinois","word_nosc":"indochinois","lemma":"indochinois","pos":"ADJ"} ,
		{"word":"indochinoise","word_nosc":"indochinoise","lemma":"indochinois","pos":"ADJ"} ,
		{"word":"indochinoises","word_nosc":"indochinoises","lemma":"indochinois","pos":"ADJ"} ,
		{"word":"indocile","word_nosc":"indocile","lemma":"indocile","pos":"ADJ"} ,
		{"word":"indolent","word_nosc":"indolent","lemma":"indolent","pos":"ADJ"} ,
		{"word":"indolente","word_nosc":"indolente","lemma":"indolent","pos":"ADJ"} ,
		{"word":"indolentes","word_nosc":"indolentes","lemma":"indolent","pos":"ADJ"} ,
		{"word":"indolents","word_nosc":"indolents","lemma":"indolent","pos":"ADJ"} ,
		{"word":"indolore","word_nosc":"indolore","lemma":"indolore","pos":"ADJ"} ,
		{"word":"indolores","word_nosc":"indolores","lemma":"indolore","pos":"ADJ"} ,
		{"word":"indomptable","word_nosc":"indomptable","lemma":"indomptable","pos":"ADJ"} ,
		{"word":"indomptables","word_nosc":"indomptables","lemma":"indomptable","pos":"ADJ"} ,
		{"word":"indompté","word_nosc":"indompte","lemma":"indompté","pos":"ADJ"} ,
		{"word":"indomptée","word_nosc":"indomptee","lemma":"indompté","pos":"ADJ"} ,
		{"word":"indomptés","word_nosc":"indomptes","lemma":"indompté","pos":"ADJ"} ,
		{"word":"indonésien","word_nosc":"indonesien","lemma":"indonésien","pos":"ADJ"} ,
		{"word":"indonésienne","word_nosc":"indonesienne","lemma":"indonésien","pos":"ADJ"} ,
		{"word":"indonésiens","word_nosc":"indonesiens","lemma":"indonésien","pos":"ADJ"} ,
		{"word":"indoor","word_nosc":"indoor","lemma":"indoor","pos":"ADJ"} ,
		{"word":"indosable","word_nosc":"indosable","lemma":"indosable","pos":"ADJ"} ,
		{"word":"indou","word_nosc":"indou","lemma":"indou","pos":"ADJ"} ,
		{"word":"indous","word_nosc":"indous","lemma":"indou","pos":"ADJ"} ,
		{"word":"indu","word_nosc":"indu","lemma":"indu","pos":"ADJ"} ,
		{"word":"indubitable","word_nosc":"indubitable","lemma":"indubitable","pos":"ADJ"} ,
		{"word":"indubitables","word_nosc":"indubitables","lemma":"indubitable","pos":"ADJ"} ,
		{"word":"inductif","word_nosc":"inductif","lemma":"inductif","pos":"ADJ"} ,
		{"word":"inductive","word_nosc":"inductive","lemma":"inductif","pos":"ADJ"} ,
		{"word":"inductrice","word_nosc":"inductrice","lemma":"inducteur","pos":"ADJ"} ,
		{"word":"indue","word_nosc":"indue","lemma":"indu","pos":"ADJ"} ,
		{"word":"indues","word_nosc":"indues","lemma":"indu","pos":"ADJ"} ,
		{"word":"induit","word_nosc":"induit","lemma":"induit","pos":"ADJ"} ,
		{"word":"induite","word_nosc":"induite","lemma":"induit","pos":"ADJ"} ,
		{"word":"indulgent","word_nosc":"indulgent","lemma":"indulgent","pos":"ADJ"} ,
		{"word":"indulgente","word_nosc":"indulgente","lemma":"indulgent","pos":"ADJ"} ,
		{"word":"indulgentes","word_nosc":"indulgentes","lemma":"indulgent","pos":"ADJ"} ,
		{"word":"indulgents","word_nosc":"indulgents","lemma":"indulgent","pos":"ADJ"} ,
		{"word":"indurée","word_nosc":"induree","lemma":"induré","pos":"ADJ"} ,
		{"word":"indus","word_nosc":"indus","lemma":"indu","pos":"ADJ"} ,
		{"word":"industrialisé","word_nosc":"industrialise","lemma":"industrialisé","pos":"ADJ"} ,
		{"word":"industrialisée","word_nosc":"industrialisee","lemma":"industrialisé","pos":"ADJ"} ,
		{"word":"industrialisés","word_nosc":"industrialises","lemma":"industrialisé","pos":"ADJ"} ,
		{"word":"industriel","word_nosc":"industriel","lemma":"industriel","pos":"ADJ"} ,
		{"word":"industrielle","word_nosc":"industrielle","lemma":"industriel","pos":"ADJ"} ,
		{"word":"industrielles","word_nosc":"industrielles","lemma":"industriel","pos":"ADJ"} ,
		{"word":"industriels","word_nosc":"industriels","lemma":"industriel","pos":"ADJ"} ,
		{"word":"industrieuse","word_nosc":"industrieuse","lemma":"industrieux","pos":"ADJ"} ,
		{"word":"industrieuses","word_nosc":"industrieuses","lemma":"industrieux","pos":"ADJ"} ,
		{"word":"industrieux","word_nosc":"industrieux","lemma":"industrieux","pos":"ADJ"} ,
		{"word":"indécelable","word_nosc":"indecelable","lemma":"indécelable","pos":"ADJ"} ,
		{"word":"indécelables","word_nosc":"indecelables","lemma":"indécelable","pos":"ADJ"} ,
		{"word":"indécent","word_nosc":"indecent","lemma":"indécent","pos":"ADJ"} ,
		{"word":"indécente","word_nosc":"indecente","lemma":"indécent","pos":"ADJ"} ,
		{"word":"indécentes","word_nosc":"indecentes","lemma":"indécent","pos":"ADJ"} ,
		{"word":"indécents","word_nosc":"indecents","lemma":"indécent","pos":"ADJ"} ,
		{"word":"indéchiffrable","word_nosc":"indechiffrable","lemma":"indéchiffrable","pos":"ADJ"} ,
		{"word":"indéchiffrables","word_nosc":"indechiffrables","lemma":"indéchiffrable","pos":"ADJ"} ,
		{"word":"indéchirable","word_nosc":"indechirable","lemma":"indéchirable","pos":"ADJ"} ,
		{"word":"indéchirables","word_nosc":"indechirables","lemma":"indéchirable","pos":"ADJ"} ,
		{"word":"indécidable","word_nosc":"indecidable","lemma":"indécidable","pos":"ADJ"} ,
		{"word":"indécidables","word_nosc":"indecidables","lemma":"indécidable","pos":"ADJ"} ,
		{"word":"indécis","word_nosc":"indecis","lemma":"indécis","pos":"ADJ"} ,
		{"word":"indécise","word_nosc":"indecise","lemma":"indécis","pos":"ADJ"} ,
		{"word":"indécises","word_nosc":"indecises","lemma":"indécis","pos":"ADJ"} ,
		{"word":"indécodable","word_nosc":"indecodable","lemma":"indécodable","pos":"ADJ"} ,
		{"word":"indécollable","word_nosc":"indecollable","lemma":"indécollable","pos":"ADJ"} ,
		{"word":"indécollables","word_nosc":"indecollables","lemma":"indécollable","pos":"ADJ"} ,
		{"word":"indécrassables","word_nosc":"indecrassables","lemma":"indécrassable","pos":"ADJ"} ,
		{"word":"indécrottable","word_nosc":"indecrottable","lemma":"indécrottable","pos":"ADJ"} ,
		{"word":"indécrottables","word_nosc":"indecrottables","lemma":"indécrottable","pos":"ADJ"} ,
		{"word":"indéfectible","word_nosc":"indefectible","lemma":"indéfectible","pos":"ADJ"} ,
		{"word":"indéfectibles","word_nosc":"indefectibles","lemma":"indéfectible","pos":"ADJ"} ,
		{"word":"indéfendable","word_nosc":"indefendable","lemma":"indéfendable","pos":"ADJ"} ,
		{"word":"indéfini","word_nosc":"indefini","lemma":"indéfini","pos":"ADJ"} ,
		{"word":"indéfinie","word_nosc":"indefinie","lemma":"indéfini","pos":"ADJ"} ,
		{"word":"indéfinies","word_nosc":"indefinies","lemma":"indéfini","pos":"ADJ"} ,
		{"word":"indéfinis","word_nosc":"indefinis","lemma":"indéfini","pos":"ADJ"} ,
		{"word":"indéfinissable","word_nosc":"indefinissable","lemma":"indéfinissable","pos":"ADJ"} ,
		{"word":"indéfinissables","word_nosc":"indefinissables","lemma":"indéfinissable","pos":"ADJ"} ,
		{"word":"indéformables","word_nosc":"indeformables","lemma":"indéformable","pos":"ADJ"} ,
		{"word":"indéfriché","word_nosc":"indefriche","lemma":"indéfriché","pos":"ADJ"} ,
		{"word":"indélicat","word_nosc":"indelicat","lemma":"indélicat","pos":"ADJ"} ,
		{"word":"indélicate","word_nosc":"indelicate","lemma":"indélicat","pos":"ADJ"} ,
		{"word":"indélicats","word_nosc":"indelicats","lemma":"indélicat","pos":"ADJ"} ,
		{"word":"indélivrable","word_nosc":"indelivrable","lemma":"indélivrable","pos":"ADJ"} ,
		{"word":"indélogeable","word_nosc":"indelogeable","lemma":"indélogeable","pos":"ADJ"} ,
		{"word":"indélogeables","word_nosc":"indelogeables","lemma":"indélogeable","pos":"ADJ"} ,
		{"word":"indélébile","word_nosc":"indelebile","lemma":"indélébile","pos":"ADJ"} ,
		{"word":"indélébiles","word_nosc":"indelebiles","lemma":"indélébile","pos":"ADJ"} ,
		{"word":"indémaillable","word_nosc":"indemaillable","lemma":"indémaillable","pos":"ADJ"} ,
		{"word":"indémaillables","word_nosc":"indemaillables","lemma":"indémaillable","pos":"ADJ"} ,
		{"word":"indémodable","word_nosc":"indemodable","lemma":"indémodable","pos":"ADJ"} ,
		{"word":"indémodables","word_nosc":"indemodables","lemma":"indémodable","pos":"ADJ"} ,
		{"word":"indémontrable","word_nosc":"indemontrable","lemma":"indémontrable","pos":"ADJ"} ,
		{"word":"indémontrables","word_nosc":"indemontrables","lemma":"indémontrable","pos":"ADJ"} ,
		{"word":"indémêlables","word_nosc":"indemelables","lemma":"indémêlable","pos":"ADJ"} ,
		{"word":"indéniable","word_nosc":"indeniable","lemma":"indéniable","pos":"ADJ"} ,
		{"word":"indéniables","word_nosc":"indeniables","lemma":"indéniable","pos":"ADJ"} ,
		{"word":"indénombrables","word_nosc":"indenombrables","lemma":"indénombrable","pos":"ADJ"} ,
		{"word":"indénouable","word_nosc":"indenouable","lemma":"indénouable","pos":"ADJ"} ,
		{"word":"indépassable","word_nosc":"indepassable","lemma":"indépassable","pos":"ADJ"} ,
		{"word":"indépendant","word_nosc":"independant","lemma":"indépendant","pos":"ADJ"} ,
		{"word":"indépendante","word_nosc":"independante","lemma":"indépendant","pos":"ADJ"} ,
		{"word":"indépendantes","word_nosc":"independantes","lemma":"indépendant","pos":"ADJ"} ,
		{"word":"indépendantiste","word_nosc":"independantiste","lemma":"indépendantiste","pos":"ADJ"} ,
		{"word":"indépendantistes","word_nosc":"independantistes","lemma":"indépendantiste","pos":"ADJ"} ,
		{"word":"indépendants","word_nosc":"independants","lemma":"indépendant","pos":"ADJ"} ,
		{"word":"indéracinable","word_nosc":"inderacinable","lemma":"indéracinable","pos":"ADJ"} ,
		{"word":"indéracinables","word_nosc":"inderacinables","lemma":"indéracinable","pos":"ADJ"} ,
		{"word":"indéréglables","word_nosc":"indereglables","lemma":"indéréglable","pos":"ADJ"} ,
		{"word":"indésirable","word_nosc":"indesirable","lemma":"indésirable","pos":"ADJ"} ,
		{"word":"indésirables","word_nosc":"indesirables","lemma":"indésirable","pos":"ADJ"} ,
		{"word":"indétectable","word_nosc":"indetectable","lemma":"indétectable","pos":"ADJ"} ,
		{"word":"indétectables","word_nosc":"indetectables","lemma":"indétectable","pos":"ADJ"} ,
		{"word":"indéterminable","word_nosc":"indeterminable","lemma":"indéterminable","pos":"ADJ"} ,
		{"word":"indéterminé","word_nosc":"indetermine","lemma":"indéterminé","pos":"ADJ"} ,
		{"word":"indéterminée","word_nosc":"indeterminee","lemma":"indéterminé","pos":"ADJ"} ,
		{"word":"indéterminées","word_nosc":"indeterminees","lemma":"indéterminé","pos":"ADJ"} ,
		{"word":"indéterminés","word_nosc":"indetermines","lemma":"indéterminé","pos":"ADJ"} ,
		{"word":"ineffable","word_nosc":"ineffable","lemma":"ineffable","pos":"ADJ"} ,
		{"word":"ineffables","word_nosc":"ineffables","lemma":"ineffable","pos":"ADJ"} ,
		{"word":"ineffaçable","word_nosc":"ineffacable","lemma":"ineffaçable","pos":"ADJ"} ,
		{"word":"ineffaçables","word_nosc":"ineffacables","lemma":"ineffaçable","pos":"ADJ"} ,
		{"word":"inefficace","word_nosc":"inefficace","lemma":"inefficace","pos":"ADJ"} ,
		{"word":"inefficaces","word_nosc":"inefficaces","lemma":"inefficace","pos":"ADJ"} ,
		{"word":"inemployable","word_nosc":"inemployable","lemma":"inemployable","pos":"ADJ"} ,
		{"word":"inemployables","word_nosc":"inemployables","lemma":"inemployable","pos":"ADJ"} ,
		{"word":"inemployé","word_nosc":"inemploye","lemma":"inemployé","pos":"ADJ"} ,
		{"word":"inemployée","word_nosc":"inemployee","lemma":"inemployé","pos":"ADJ"} ,
		{"word":"inemployées","word_nosc":"inemployees","lemma":"inemployé","pos":"ADJ"} ,
		{"word":"inemployés","word_nosc":"inemployes","lemma":"inemployé","pos":"ADJ"} ,
		{"word":"inentamable","word_nosc":"inentamable","lemma":"inentamable","pos":"ADJ"} ,
		{"word":"inentamé","word_nosc":"inentame","lemma":"inentamé","pos":"ADJ"} ,
		{"word":"inentamée","word_nosc":"inentamee","lemma":"inentamé","pos":"ADJ"} ,
		{"word":"inentamées","word_nosc":"inentamees","lemma":"inentamé","pos":"ADJ"} ,
		{"word":"inenvisageable","word_nosc":"inenvisageable","lemma":"inenvisageable","pos":"ADJ"} ,
		{"word":"inepte","word_nosc":"inepte","lemma":"inepte","pos":"ADJ"} ,
		{"word":"ineptes","word_nosc":"ineptes","lemma":"inepte","pos":"ADJ"} ,
		{"word":"inerte","word_nosc":"inerte","lemma":"inerte","pos":"ADJ"} ,
		{"word":"inertes","word_nosc":"inertes","lemma":"inerte","pos":"ADJ"} ,
		{"word":"inertiel","word_nosc":"inertiel","lemma":"inertiel","pos":"ADJ"} ,
		{"word":"inertielle","word_nosc":"inertielle","lemma":"inertiel","pos":"ADJ"} ,
		{"word":"inespérable","word_nosc":"inesperable","lemma":"inespérable","pos":"ADJ"} ,
		{"word":"inespéré","word_nosc":"inespere","lemma":"inespéré","pos":"ADJ"} ,
		{"word":"inespérée","word_nosc":"inesperee","lemma":"inespéré","pos":"ADJ"} ,
		{"word":"inespérées","word_nosc":"inesperees","lemma":"inespéré","pos":"ADJ"} ,
		{"word":"inessentiel","word_nosc":"inessentiel","lemma":"inessentiel","pos":"ADJ"} ,
		{"word":"inesthétique","word_nosc":"inesthetique","lemma":"inesthétique","pos":"ADJ"} ,
		{"word":"inesthétiques","word_nosc":"inesthetiques","lemma":"inesthétique","pos":"ADJ"} ,
		{"word":"inestimable","word_nosc":"inestimable","lemma":"inestimable","pos":"ADJ"} ,
		{"word":"inestimables","word_nosc":"inestimables","lemma":"inestimable","pos":"ADJ"} ,
		{"word":"inexact","word_nosc":"inexact","lemma":"inexact","pos":"ADJ"} ,
		{"word":"inexacte","word_nosc":"inexacte","lemma":"inexact","pos":"ADJ"} ,
		{"word":"inexactes","word_nosc":"inexactes","lemma":"inexact","pos":"ADJ"} ,
		{"word":"inexacts","word_nosc":"inexacts","lemma":"inexact","pos":"ADJ"} ,
		{"word":"inexcusable","word_nosc":"inexcusable","lemma":"inexcusable","pos":"ADJ"} ,
		{"word":"inexcusables","word_nosc":"inexcusables","lemma":"inexcusable","pos":"ADJ"} ,
		{"word":"inexistant","word_nosc":"inexistant","lemma":"inexistant","pos":"ADJ"} ,
		{"word":"inexistante","word_nosc":"inexistante","lemma":"inexistant","pos":"ADJ"} ,
		{"word":"inexistantes","word_nosc":"inexistantes","lemma":"inexistant","pos":"ADJ"} ,
		{"word":"inexistants","word_nosc":"inexistants","lemma":"inexistant","pos":"ADJ"} ,
		{"word":"inexorable","word_nosc":"inexorable","lemma":"inexorable","pos":"ADJ"} ,
		{"word":"inexorables","word_nosc":"inexorables","lemma":"inexorable","pos":"ADJ"} ,
		{"word":"inexperte","word_nosc":"inexperte","lemma":"inexpert","pos":"ADJ"} ,
		{"word":"inexpiable","word_nosc":"inexpiable","lemma":"inexpiable","pos":"ADJ"} ,
		{"word":"inexplicable","word_nosc":"inexplicable","lemma":"inexplicable","pos":"ADJ"} ,
		{"word":"inexplicables","word_nosc":"inexplicables","lemma":"inexplicable","pos":"ADJ"} ,
		{"word":"inexpliqué","word_nosc":"inexplique","lemma":"inexpliqué","pos":"ADJ"} ,
		{"word":"inexpliquée","word_nosc":"inexpliquee","lemma":"inexpliqué","pos":"ADJ"} ,
		{"word":"inexpliquées","word_nosc":"inexpliquees","lemma":"inexpliqué","pos":"ADJ"} ,
		{"word":"inexpliqués","word_nosc":"inexpliques","lemma":"inexpliqué","pos":"ADJ"} ,
		{"word":"inexploitable","word_nosc":"inexploitable","lemma":"inexploitable","pos":"ADJ"} ,
		{"word":"inexploité","word_nosc":"inexploite","lemma":"inexploité","pos":"ADJ"} ,
		{"word":"inexploitée","word_nosc":"inexploitee","lemma":"inexploité","pos":"ADJ"} ,
		{"word":"inexploitées","word_nosc":"inexploitees","lemma":"inexploité","pos":"ADJ"} ,
		{"word":"inexploités","word_nosc":"inexploites","lemma":"inexploité","pos":"ADJ"} ,
		{"word":"inexploré","word_nosc":"inexplore","lemma":"inexploré","pos":"ADJ"} ,
		{"word":"inexplorée","word_nosc":"inexploree","lemma":"inexploré","pos":"ADJ"} ,
		{"word":"inexplorées","word_nosc":"inexplorees","lemma":"inexploré","pos":"ADJ"} ,
		{"word":"inexplorés","word_nosc":"inexplores","lemma":"inexploré","pos":"ADJ"} ,
		{"word":"inexplosible","word_nosc":"inexplosible","lemma":"inexplosible","pos":"ADJ"} ,
		{"word":"inexplosibles","word_nosc":"inexplosibles","lemma":"inexplosible","pos":"ADJ"} ,
		{"word":"inexpressif","word_nosc":"inexpressif","lemma":"inexpressif","pos":"ADJ"} ,
		{"word":"inexpressifs","word_nosc":"inexpressifs","lemma":"inexpressif","pos":"ADJ"} ,
		{"word":"inexpressive","word_nosc":"inexpressive","lemma":"inexpressif","pos":"ADJ"} ,
		{"word":"inexpressives","word_nosc":"inexpressives","lemma":"inexpressif","pos":"ADJ"} ,
		{"word":"inexprimable","word_nosc":"inexprimable","lemma":"inexprimable","pos":"ADJ"} ,
		{"word":"inexprimables","word_nosc":"inexprimables","lemma":"inexprimable","pos":"ADJ"} ,
		{"word":"inexprimé","word_nosc":"inexprime","lemma":"inexprimé","pos":"ADJ"} ,
		{"word":"inexprimée","word_nosc":"inexprimee","lemma":"inexprimé","pos":"ADJ"} ,
		{"word":"inexpugnable","word_nosc":"inexpugnable","lemma":"inexpugnable","pos":"ADJ"} ,
		{"word":"inexpugnables","word_nosc":"inexpugnables","lemma":"inexpugnable","pos":"ADJ"} ,
		{"word":"inexpérimenté","word_nosc":"inexperimente","lemma":"inexpérimenté","pos":"ADJ"} ,
		{"word":"inexpérimentée","word_nosc":"inexperimentee","lemma":"inexpérimenté","pos":"ADJ"} ,
		{"word":"inexpérimentées","word_nosc":"inexperimentees","lemma":"inexpérimenté","pos":"ADJ"} ,
		{"word":"inexpérimentés","word_nosc":"inexperimentes","lemma":"inexpérimenté","pos":"ADJ"} ,
		{"word":"inextinguible","word_nosc":"inextinguible","lemma":"inextinguible","pos":"ADJ"} ,
		{"word":"inextinguibles","word_nosc":"inextinguibles","lemma":"inextinguible","pos":"ADJ"} ,
		{"word":"inextirpable","word_nosc":"inextirpable","lemma":"inextirpable","pos":"ADJ"} ,
		{"word":"inextricable","word_nosc":"inextricable","lemma":"inextricable","pos":"ADJ"} ,
		{"word":"inextricables","word_nosc":"inextricables","lemma":"inextricable","pos":"ADJ"} ,
		{"word":"infaillible","word_nosc":"infaillible","lemma":"infaillible","pos":"ADJ"} ,
		{"word":"infaillibles","word_nosc":"infaillibles","lemma":"infaillible","pos":"ADJ"} ,
		{"word":"infaisable","word_nosc":"infaisable","lemma":"infaisable","pos":"ADJ"} ,
		{"word":"infalsifiable","word_nosc":"infalsifiable","lemma":"infalsifiable","pos":"ADJ"} ,
		{"word":"infamant","word_nosc":"infamant","lemma":"infamant","pos":"ADJ"} ,
		{"word":"infamante","word_nosc":"infamante","lemma":"infamant","pos":"ADJ"} ,
		{"word":"infamantes","word_nosc":"infamantes","lemma":"infamant","pos":"ADJ"} ,
		{"word":"infamants","word_nosc":"infamants","lemma":"infamant","pos":"ADJ"} ,
		{"word":"infanticide","word_nosc":"infanticide","lemma":"infanticide","pos":"ADJ"} ,
		{"word":"infantile","word_nosc":"infantile","lemma":"infantile","pos":"ADJ"} ,
		{"word":"infantiles","word_nosc":"infantiles","lemma":"infantile","pos":"ADJ"} ,
		{"word":"infatigable","word_nosc":"infatigable","lemma":"infatigable","pos":"ADJ"} ,
		{"word":"infatigables","word_nosc":"infatigables","lemma":"infatigable","pos":"ADJ"} ,
		{"word":"infatué","word_nosc":"infatue","lemma":"infatué","pos":"ADJ"} ,
		{"word":"infatués","word_nosc":"infatues","lemma":"infatué","pos":"ADJ"} ,
		{"word":"infect","word_nosc":"infect","lemma":"infect","pos":"ADJ"} ,
		{"word":"infecte","word_nosc":"infecte","lemma":"infect","pos":"ADJ"} ,
		{"word":"infectes","word_nosc":"infectes","lemma":"infect","pos":"ADJ"} ,
		{"word":"infectieuse","word_nosc":"infectieuse","lemma":"infectieux","pos":"ADJ"} ,
		{"word":"infectieuses","word_nosc":"infectieuses","lemma":"infectieux","pos":"ADJ"} ,
		{"word":"infectieux","word_nosc":"infectieux","lemma":"infectieux","pos":"ADJ"} ,
		{"word":"infects","word_nosc":"infects","lemma":"infect","pos":"ADJ"} ,
		{"word":"infecté","word_nosc":"infecte","lemma":"infecté","pos":"ADJ"} ,
		{"word":"infectée","word_nosc":"infectee","lemma":"infecté","pos":"ADJ"} ,
		{"word":"infectées","word_nosc":"infectees","lemma":"infecté","pos":"ADJ"} ,
		{"word":"infectés","word_nosc":"infectes","lemma":"infecté","pos":"ADJ"} ,
		{"word":"infernal","word_nosc":"infernal","lemma":"infernal","pos":"ADJ"} ,
		{"word":"infernale","word_nosc":"infernale","lemma":"infernal","pos":"ADJ"} ,
		{"word":"infernales","word_nosc":"infernales","lemma":"infernal","pos":"ADJ"} ,
		{"word":"infernaux","word_nosc":"infernaux","lemma":"infernal","pos":"ADJ"} ,
		{"word":"infertile","word_nosc":"infertile","lemma":"infertile","pos":"ADJ"} ,
		{"word":"infertiles","word_nosc":"infertiles","lemma":"infertile","pos":"ADJ"} ,
		{"word":"infidèle","word_nosc":"infidele","lemma":"infidèle","pos":"ADJ"} ,
		{"word":"infidèles","word_nosc":"infideles","lemma":"infidèle","pos":"ADJ"} ,
		{"word":"infigurable","word_nosc":"infigurable","lemma":"infigurable","pos":"ADJ"} ,
		{"word":"infime","word_nosc":"infime","lemma":"infime","pos":"ADJ"} ,
		{"word":"infimes","word_nosc":"infimes","lemma":"infime","pos":"ADJ"} ,
		{"word":"infini","word_nosc":"infini","lemma":"infini","pos":"ADJ"} ,
		{"word":"infinie","word_nosc":"infinie","lemma":"infini","pos":"ADJ"} ,
		{"word":"infinies","word_nosc":"infinies","lemma":"infini","pos":"ADJ"} ,
		{"word":"infinis","word_nosc":"infinis","lemma":"infini","pos":"ADJ"} ,
		{"word":"infinitive","word_nosc":"infinitive","lemma":"infinitif","pos":"ADJ"} ,
		{"word":"infinitésimal","word_nosc":"infinitesimal","lemma":"infinitésimal","pos":"ADJ"} ,
		{"word":"infinitésimale","word_nosc":"infinitesimale","lemma":"infinitésimal","pos":"ADJ"} ,
		{"word":"infinitésimales","word_nosc":"infinitesimales","lemma":"infinitésimal","pos":"ADJ"} ,
		{"word":"infinitésimaux","word_nosc":"infinitesimaux","lemma":"infinitésimal","pos":"ADJ"} ,
		{"word":"infirme","word_nosc":"infirme","lemma":"infirme","pos":"ADJ"} ,
		{"word":"infirmes","word_nosc":"infirmes","lemma":"infirme","pos":"ADJ"} ,
		{"word":"infirmier","word_nosc":"infirmier","lemma":"infirmier","pos":"ADJ"} ,
		{"word":"infirmiers","word_nosc":"infirmiers","lemma":"infirmier","pos":"ADJ"} ,
		{"word":"inflammable","word_nosc":"inflammable","lemma":"inflammable","pos":"ADJ"} ,
		{"word":"inflammables","word_nosc":"inflammables","lemma":"inflammable","pos":"ADJ"} ,
		{"word":"inflammatoire","word_nosc":"inflammatoire","lemma":"inflammatoire","pos":"ADJ"} ,
		{"word":"inflammatoires","word_nosc":"inflammatoires","lemma":"inflammatoire","pos":"ADJ"} ,
		{"word":"inflationniste","word_nosc":"inflationniste","lemma":"inflationniste","pos":"ADJ"} ,
		{"word":"inflexible","word_nosc":"inflexible","lemma":"inflexible","pos":"ADJ"} ,
		{"word":"inflexibles","word_nosc":"inflexibles","lemma":"inflexible","pos":"ADJ"} ,
		{"word":"influent","word_nosc":"influent","lemma":"influent","pos":"ADJ"} ,
		{"word":"influente","word_nosc":"influente","lemma":"influent","pos":"ADJ"} ,
		{"word":"influentes","word_nosc":"influentes","lemma":"influent","pos":"ADJ"} ,
		{"word":"influents","word_nosc":"influents","lemma":"influent","pos":"ADJ"} ,
		{"word":"influençable","word_nosc":"influencable","lemma":"influençable","pos":"ADJ"} ,
		{"word":"influençables","word_nosc":"influencables","lemma":"influençable","pos":"ADJ"} ,
		{"word":"infléchi","word_nosc":"inflechi","lemma":"infléchi","pos":"ADJ"} ,
		{"word":"infléchie","word_nosc":"inflechie","lemma":"infléchi","pos":"ADJ"} ,
		{"word":"infléchies","word_nosc":"inflechies","lemma":"infléchi","pos":"ADJ"} ,
		{"word":"infondé","word_nosc":"infonde","lemma":"infondé","pos":"ADJ"} ,
		{"word":"infondée","word_nosc":"infondee","lemma":"infondé","pos":"ADJ"} ,
		{"word":"infondées","word_nosc":"infondees","lemma":"infondé","pos":"ADJ"} ,
		{"word":"infondés","word_nosc":"infondes","lemma":"infondé","pos":"ADJ"} ,
		{"word":"informant","word_nosc":"informant","lemma":"informant","pos":"ADJ"} ,
		{"word":"informatif","word_nosc":"informatif","lemma":"informatif","pos":"ADJ"} ,
		{"word":"informatique","word_nosc":"informatique","lemma":"informatique","pos":"ADJ"} ,
		{"word":"informatiques","word_nosc":"informatiques","lemma":"informatique","pos":"ADJ"} ,
		{"word":"informatisé","word_nosc":"informatise","lemma":"informatisé","pos":"ADJ"} ,
		{"word":"informatisée","word_nosc":"informatisee","lemma":"informatisé","pos":"ADJ"} ,
		{"word":"informatisées","word_nosc":"informatisees","lemma":"informatisé","pos":"ADJ"} ,
		{"word":"informatisés","word_nosc":"informatises","lemma":"informatisé","pos":"ADJ"} ,
		{"word":"informative","word_nosc":"informative","lemma":"informatif","pos":"ADJ"} ,
		{"word":"informatives","word_nosc":"informatives","lemma":"informatif","pos":"ADJ"} ,
		{"word":"informe","word_nosc":"informe","lemma":"informe","pos":"ADJ"} ,
		{"word":"informel","word_nosc":"informel","lemma":"informel","pos":"ADJ"} ,
		{"word":"informelle","word_nosc":"informelle","lemma":"informel","pos":"ADJ"} ,
		{"word":"informelles","word_nosc":"informelles","lemma":"informel","pos":"ADJ"} ,
		{"word":"informels","word_nosc":"informels","lemma":"informel","pos":"ADJ"} ,
		{"word":"informes","word_nosc":"informes","lemma":"informe","pos":"ADJ"} ,
		{"word":"informulable","word_nosc":"informulable","lemma":"informulable","pos":"ADJ"} ,
		{"word":"informulables","word_nosc":"informulables","lemma":"informulable","pos":"ADJ"} ,
		{"word":"informulé","word_nosc":"informule","lemma":"informulé","pos":"ADJ"} ,
		{"word":"informulée","word_nosc":"informulee","lemma":"informulé","pos":"ADJ"} ,
		{"word":"informulées","word_nosc":"informulees","lemma":"informulé","pos":"ADJ"} ,
		{"word":"informé","word_nosc":"informe","lemma":"informé","pos":"ADJ"} ,
		{"word":"informée","word_nosc":"informee","lemma":"informé","pos":"ADJ"} ,
		{"word":"informées","word_nosc":"informees","lemma":"informé","pos":"ADJ"} ,
		{"word":"informés","word_nosc":"informes","lemma":"informé","pos":"ADJ"} ,
		{"word":"infortuné","word_nosc":"infortune","lemma":"infortuné","pos":"ADJ"} ,
		{"word":"infortunée","word_nosc":"infortunee","lemma":"infortuné","pos":"ADJ"} ,
		{"word":"infortunées","word_nosc":"infortunees","lemma":"infortuné","pos":"ADJ"} ,
		{"word":"infortunés","word_nosc":"infortunes","lemma":"infortuné","pos":"ADJ"} ,
		{"word":"infoutu","word_nosc":"infoutu","lemma":"infoutu","pos":"ADJ"} ,
		{"word":"infoutue","word_nosc":"infoutue","lemma":"infoutu","pos":"ADJ"} ,
		{"word":"infoutus","word_nosc":"infoutus","lemma":"infoutu","pos":"ADJ"} ,
		{"word":"infranchi","word_nosc":"infranchi","lemma":"infranchi","pos":"ADJ"} ,
		{"word":"infranchissable","word_nosc":"infranchissable","lemma":"infranchissable","pos":"ADJ"} ,
		{"word":"infranchissables","word_nosc":"infranchissables","lemma":"infranchissable","pos":"ADJ"} ,
		{"word":"infrangible","word_nosc":"infrangible","lemma":"infrangible","pos":"ADJ"} ,
		{"word":"infrarouge","word_nosc":"infrarouge","lemma":"infrarouge","pos":"ADJ"} ,
		{"word":"infrarouges","word_nosc":"infrarouges","lemma":"infrarouge","pos":"ADJ"} ,
		{"word":"infroissable","word_nosc":"infroissable","lemma":"infroissable","pos":"ADJ"} ,
		{"word":"infructueuse","word_nosc":"infructueuse","lemma":"infructueux","pos":"ADJ"} ,
		{"word":"infructueuses","word_nosc":"infructueuses","lemma":"infructueux","pos":"ADJ"} ,
		{"word":"infructueux","word_nosc":"infructueux","lemma":"infructueux","pos":"ADJ"} ,
		{"word":"infréquentable","word_nosc":"infrequentable","lemma":"infréquentable","pos":"ADJ"} ,
		{"word":"infréquentables","word_nosc":"infrequentables","lemma":"infréquentable","pos":"ADJ"} ,
		{"word":"infréquentée","word_nosc":"infrequentee","lemma":"infréquenté","pos":"ADJ"} ,
		{"word":"infréquentés","word_nosc":"infrequentes","lemma":"infréquenté","pos":"ADJ"} ,
		{"word":"infus","word_nosc":"infus","lemma":"infus","pos":"ADJ"} ,
		{"word":"infuse","word_nosc":"infuse","lemma":"infus","pos":"ADJ"} ,
		{"word":"infuses","word_nosc":"infuses","lemma":"infus","pos":"ADJ"} ,
		{"word":"infâme","word_nosc":"infame","lemma":"infâme","pos":"ADJ"} ,
		{"word":"infâmes","word_nosc":"infames","lemma":"infâme","pos":"ADJ"} ,
		{"word":"inféconde","word_nosc":"infeconde","lemma":"infécond","pos":"ADJ"} ,
		{"word":"infécondes","word_nosc":"infecondes","lemma":"infécond","pos":"ADJ"} ,
		{"word":"inférieur","word_nosc":"inferieur","lemma":"inférieur","pos":"ADJ"} ,
		{"word":"inférieure","word_nosc":"inferieure","lemma":"inférieur","pos":"ADJ"} ,
		{"word":"inférieures","word_nosc":"inferieures","lemma":"inférieur","pos":"ADJ"} ,
		{"word":"inférieurs","word_nosc":"inferieurs","lemma":"inférieur","pos":"ADJ"} ,
		{"word":"ingagnable","word_nosc":"ingagnable","lemma":"ingagnable","pos":"ADJ"} ,
		{"word":"ingambe","word_nosc":"ingambe","lemma":"ingambe","pos":"ADJ"} ,
		{"word":"ingambes","word_nosc":"ingambes","lemma":"ingambe","pos":"ADJ"} ,
		{"word":"inglorieux","word_nosc":"inglorieux","lemma":"inglorieux","pos":"ADJ"} ,
		{"word":"ingouvernable","word_nosc":"ingouvernable","lemma":"ingouvernable","pos":"ADJ"} ,
		{"word":"ingrat","word_nosc":"ingrat","lemma":"ingrat","pos":"ADJ"} ,
		{"word":"ingrate","word_nosc":"ingrate","lemma":"ingrat","pos":"ADJ"} ,
		{"word":"ingrates","word_nosc":"ingrates","lemma":"ingrat","pos":"ADJ"} ,
		{"word":"ingrats","word_nosc":"ingrats","lemma":"ingrat","pos":"ADJ"} ,
		{"word":"inguinal","word_nosc":"inguinal","lemma":"inguinal","pos":"ADJ"} ,
		{"word":"inguinales","word_nosc":"inguinales","lemma":"inguinal","pos":"ADJ"} ,
		{"word":"inguérissable","word_nosc":"inguerissable","lemma":"inguérissable","pos":"ADJ"} ,
		{"word":"inguérissables","word_nosc":"inguerissables","lemma":"inguérissable","pos":"ADJ"} ,
		{"word":"ingénieuse","word_nosc":"ingenieuse","lemma":"ingénieux","pos":"ADJ"} ,
		{"word":"ingénieuses","word_nosc":"ingenieuses","lemma":"ingénieux","pos":"ADJ"} ,
		{"word":"ingénieux","word_nosc":"ingenieux","lemma":"ingénieux","pos":"ADJ"} ,
		{"word":"ingénu","word_nosc":"ingenu","lemma":"ingénu","pos":"ADJ"} ,
		{"word":"ingénue","word_nosc":"ingenue","lemma":"ingénu","pos":"ADJ"} ,
		{"word":"ingénues","word_nosc":"ingenues","lemma":"ingénu","pos":"ADJ"} ,
		{"word":"ingénus","word_nosc":"ingenus","lemma":"ingénu","pos":"ADJ"} ,
		{"word":"ingérable","word_nosc":"ingerable","lemma":"ingérable","pos":"ADJ"} ,
		{"word":"inhabile","word_nosc":"inhabile","lemma":"inhabile","pos":"ADJ"} ,
		{"word":"inhabitable","word_nosc":"inhabitable","lemma":"inhabitable","pos":"ADJ"} ,
		{"word":"inhabitables","word_nosc":"inhabitables","lemma":"inhabitable","pos":"ADJ"} ,
		{"word":"inhabituel","word_nosc":"inhabituel","lemma":"inhabituel","pos":"ADJ"} ,
		{"word":"inhabituelle","word_nosc":"inhabituelle","lemma":"inhabituel","pos":"ADJ"} ,
		{"word":"inhabituelles","word_nosc":"inhabituelles","lemma":"inhabituel","pos":"ADJ"} ,
		{"word":"inhabituels","word_nosc":"inhabituels","lemma":"inhabituel","pos":"ADJ"} ,
		{"word":"inhabité","word_nosc":"inhabite","lemma":"inhabité","pos":"ADJ"} ,
		{"word":"inhabitée","word_nosc":"inhabitee","lemma":"inhabité","pos":"ADJ"} ,
		{"word":"inhabitées","word_nosc":"inhabitees","lemma":"inhabité","pos":"ADJ"} ,
		{"word":"inhabités","word_nosc":"inhabites","lemma":"inhabité","pos":"ADJ"} ,
		{"word":"inhalant","word_nosc":"inhalant","lemma":"inhalant","pos":"ADJ"} ,
		{"word":"inhalateur","word_nosc":"inhalateur","lemma":"inhalateur","pos":"ADJ"} ,
		{"word":"inharmonieux","word_nosc":"inharmonieux","lemma":"inharmonieux","pos":"ADJ"} ,
		{"word":"inhibiteur","word_nosc":"inhibiteur","lemma":"inhibiteur","pos":"ADJ"} ,
		{"word":"inhibiteurs","word_nosc":"inhibiteurs","lemma":"inhibiteur","pos":"ADJ"} ,
		{"word":"inhibitrice","word_nosc":"inhibitrice","lemma":"inhibiteur","pos":"ADJ"} ,
		{"word":"inhibé","word_nosc":"inhibe","lemma":"inhibé","pos":"ADJ"} ,
		{"word":"inhibée","word_nosc":"inhibee","lemma":"inhibé","pos":"ADJ"} ,
		{"word":"inhibés","word_nosc":"inhibes","lemma":"inhibé","pos":"ADJ"} ,
		{"word":"inhospitalier","word_nosc":"inhospitalier","lemma":"inhospitalier","pos":"ADJ"} ,
		{"word":"inhospitaliers","word_nosc":"inhospitaliers","lemma":"inhospitalier","pos":"ADJ"} ,
		{"word":"inhospitalière","word_nosc":"inhospitaliere","lemma":"inhospitalier","pos":"ADJ"} ,
		{"word":"inhospitalières","word_nosc":"inhospitalieres","lemma":"inhospitalier","pos":"ADJ"} ,
		{"word":"inhumain","word_nosc":"inhumain","lemma":"inhumain","pos":"ADJ"} ,
		{"word":"inhumaine","word_nosc":"inhumaine","lemma":"inhumain","pos":"ADJ"} ,
		{"word":"inhumaines","word_nosc":"inhumaines","lemma":"inhumain","pos":"ADJ"} ,
		{"word":"inhumains","word_nosc":"inhumains","lemma":"inhumain","pos":"ADJ"} ,
		{"word":"inhérent","word_nosc":"inherent","lemma":"inhérent","pos":"ADJ"} ,
		{"word":"inhérente","word_nosc":"inherente","lemma":"inhérent","pos":"ADJ"} ,
		{"word":"inhérentes","word_nosc":"inherentes","lemma":"inhérent","pos":"ADJ"} ,
		{"word":"inhérents","word_nosc":"inherents","lemma":"inhérent","pos":"ADJ"} ,
		{"word":"inidentifiable","word_nosc":"inidentifiable","lemma":"inidentifiable","pos":"ADJ"} ,
		{"word":"inidentifiables","word_nosc":"inidentifiables","lemma":"inidentifiable","pos":"ADJ"} ,
		{"word":"inimaginable","word_nosc":"inimaginable","lemma":"inimaginable","pos":"ADJ"} ,
		{"word":"inimaginables","word_nosc":"inimaginables","lemma":"inimaginable","pos":"ADJ"} ,
		{"word":"inimitable","word_nosc":"inimitable","lemma":"inimitable","pos":"ADJ"} ,
		{"word":"inimité","word_nosc":"inimite","lemma":"inimité","pos":"ADJ"} ,
		{"word":"ininflammable","word_nosc":"ininflammable","lemma":"ininflammable","pos":"ADJ"} ,
		{"word":"inintelligent","word_nosc":"inintelligent","lemma":"inintelligent","pos":"ADJ"} ,
		{"word":"inintelligents","word_nosc":"inintelligents","lemma":"inintelligent","pos":"ADJ"} ,
		{"word":"inintelligible","word_nosc":"inintelligible","lemma":"inintelligible","pos":"ADJ"} ,
		{"word":"inintelligibles","word_nosc":"inintelligibles","lemma":"inintelligible","pos":"ADJ"} ,
		{"word":"ininterprétables","word_nosc":"ininterpretables","lemma":"ininterprétable","pos":"ADJ"} ,
		{"word":"ininterrompu","word_nosc":"ininterrompu","lemma":"ininterrompu","pos":"ADJ"} ,
		{"word":"ininterrompue","word_nosc":"ininterrompue","lemma":"ininterrompu","pos":"ADJ"} ,
		{"word":"ininterrompues","word_nosc":"ininterrompues","lemma":"ininterrompu","pos":"ADJ"} ,
		{"word":"ininterrompus","word_nosc":"ininterrompus","lemma":"ininterrompu","pos":"ADJ"} ,
		{"word":"inintéressant","word_nosc":"ininteressant","lemma":"inintéressant","pos":"ADJ"} ,
		{"word":"inintéressante","word_nosc":"ininteressante","lemma":"inintéressant","pos":"ADJ"} ,
		{"word":"inintéressantes","word_nosc":"ininteressantes","lemma":"inintéressant","pos":"ADJ"} ,
		{"word":"inintéressants","word_nosc":"ininteressants","lemma":"inintéressant","pos":"ADJ"} ,
		{"word":"inique","word_nosc":"inique","lemma":"inique","pos":"ADJ"} ,
		{"word":"iniques","word_nosc":"iniques","lemma":"inique","pos":"ADJ"} ,
		{"word":"initial","word_nosc":"initial","lemma":"initial","pos":"ADJ"} ,
		{"word":"initiale","word_nosc":"initiale","lemma":"initial","pos":"ADJ"} ,
		{"word":"initiales","word_nosc":"initiales","lemma":"initial","pos":"ADJ"} ,
		{"word":"initialisé","word_nosc":"initialise","lemma":"initialisé","pos":"ADJ"} ,
		{"word":"initialisée","word_nosc":"initialisee","lemma":"initialisé","pos":"ADJ"} ,
		{"word":"initialisés","word_nosc":"initialises","lemma":"initialisé","pos":"ADJ"} ,
		{"word":"initiateur","word_nosc":"initiateur","lemma":"initiateur","pos":"ADJ"} ,
		{"word":"initiateurs","word_nosc":"initiateurs","lemma":"initiateur","pos":"ADJ"} ,
		{"word":"initiatique","word_nosc":"initiatique","lemma":"initiatique","pos":"ADJ"} ,
		{"word":"initiatiques","word_nosc":"initiatiques","lemma":"initiatique","pos":"ADJ"} ,
		{"word":"initiatrice","word_nosc":"initiatrice","lemma":"initiateur","pos":"ADJ"} ,
		{"word":"initiaux","word_nosc":"initiaux","lemma":"initial","pos":"ADJ"} ,
		{"word":"initié","word_nosc":"initie","lemma":"initié","pos":"ADJ"} ,
		{"word":"initiée","word_nosc":"initiee","lemma":"initié","pos":"ADJ"} ,
		{"word":"initiés","word_nosc":"inities","lemma":"initié","pos":"ADJ"} ,
		{"word":"injectable","word_nosc":"injectable","lemma":"injectable","pos":"ADJ"} ,
		{"word":"injecté","word_nosc":"injecte","lemma":"injecté","pos":"ADJ"} ,
		{"word":"injectée","word_nosc":"injectee","lemma":"injecté","pos":"ADJ"} ,
		{"word":"injectés","word_nosc":"injectes","lemma":"injecté","pos":"ADJ"} ,
		{"word":"injoignable","word_nosc":"injoignable","lemma":"injoignable","pos":"ADJ"} ,
		{"word":"injoignables","word_nosc":"injoignables","lemma":"injoignable","pos":"ADJ"} ,
		{"word":"injouable","word_nosc":"injouable","lemma":"injouable","pos":"ADJ"} ,
		{"word":"injurieuse","word_nosc":"injurieuse","lemma":"injurieux","pos":"ADJ"} ,
		{"word":"injurieux","word_nosc":"injurieux","lemma":"injurieux","pos":"ADJ"} ,
		{"word":"injuste","word_nosc":"injuste","lemma":"injuste","pos":"ADJ"} ,
		{"word":"injustes","word_nosc":"injustes","lemma":"injuste","pos":"ADJ"} ,
		{"word":"injustifiable","word_nosc":"injustifiable","lemma":"injustifiable","pos":"ADJ"} ,
		{"word":"injustifiables","word_nosc":"injustifiables","lemma":"injustifiable","pos":"ADJ"} ,
		{"word":"injustifié","word_nosc":"injustifie","lemma":"injustifié","pos":"ADJ"} ,
		{"word":"injustifiée","word_nosc":"injustifiee","lemma":"injustifié","pos":"ADJ"} ,
		{"word":"injustifiées","word_nosc":"injustifiees","lemma":"injustifié","pos":"ADJ"} ,
		{"word":"injustifiés","word_nosc":"injustifies","lemma":"injustifié","pos":"ADJ"} ,
		{"word":"inlassable","word_nosc":"inlassable","lemma":"inlassable","pos":"ADJ"} ,
		{"word":"inlassables","word_nosc":"inlassables","lemma":"inlassable","pos":"ADJ"} ,
		{"word":"innocent","word_nosc":"innocent","lemma":"innocent","pos":"ADJ"} ,
		{"word":"innocente","word_nosc":"innocente","lemma":"innocent","pos":"ADJ"} ,
		{"word":"innocentes","word_nosc":"innocentes","lemma":"innocent","pos":"ADJ"} ,
		{"word":"innocents","word_nosc":"innocents","lemma":"innocent","pos":"ADJ"} ,
		{"word":"innombrable","word_nosc":"innombrable","lemma":"innombrable","pos":"ADJ"} ,
		{"word":"innombrables","word_nosc":"innombrables","lemma":"innombrable","pos":"ADJ"} ,
		{"word":"innominés","word_nosc":"innomines","lemma":"innominé","pos":"ADJ"} ,
		{"word":"innommable","word_nosc":"innommable","lemma":"innommable","pos":"ADJ"} ,
		{"word":"innommables","word_nosc":"innommables","lemma":"innommable","pos":"ADJ"} ,
		{"word":"innommé","word_nosc":"innomme","lemma":"innommé","pos":"ADJ"} ,
		{"word":"innommée","word_nosc":"innommee","lemma":"innommé","pos":"ADJ"} ,
		{"word":"innommés","word_nosc":"innommes","lemma":"innommé","pos":"ADJ"} ,
		{"word":"innovant","word_nosc":"innovant","lemma":"innovant","pos":"ADJ"} ,
		{"word":"innovante","word_nosc":"innovante","lemma":"innovant","pos":"ADJ"} ,
		{"word":"innovateur","word_nosc":"innovateur","lemma":"innovateur","pos":"ADJ"} ,
		{"word":"innovatrice","word_nosc":"innovatrice","lemma":"innovateur","pos":"ADJ"} ,
		{"word":"inné","word_nosc":"inne","lemma":"inné","pos":"ADJ"} ,
		{"word":"innée","word_nosc":"innee","lemma":"inné","pos":"ADJ"} ,
		{"word":"innées","word_nosc":"innees","lemma":"inné","pos":"ADJ"} ,
		{"word":"innés","word_nosc":"innes","lemma":"inné","pos":"ADJ"} ,
		{"word":"inoccupé","word_nosc":"inoccupe","lemma":"inoccupé","pos":"ADJ"} ,
		{"word":"inoccupée","word_nosc":"inoccupee","lemma":"inoccupé","pos":"ADJ"} ,
		{"word":"inoccupées","word_nosc":"inoccupees","lemma":"inoccupé","pos":"ADJ"} ,
		{"word":"inoccupés","word_nosc":"inoccupes","lemma":"inoccupé","pos":"ADJ"} ,
		{"word":"inodore","word_nosc":"inodore","lemma":"inodore","pos":"ADJ"} ,
		{"word":"inodores","word_nosc":"inodores","lemma":"inodore","pos":"ADJ"} ,
		{"word":"inoffensif","word_nosc":"inoffensif","lemma":"inoffensif","pos":"ADJ"} ,
		{"word":"inoffensifs","word_nosc":"inoffensifs","lemma":"inoffensif","pos":"ADJ"} ,
		{"word":"inoffensive","word_nosc":"inoffensive","lemma":"inoffensif","pos":"ADJ"} ,
		{"word":"inoffensives","word_nosc":"inoffensives","lemma":"inoffensif","pos":"ADJ"} ,
		{"word":"inondable","word_nosc":"inondable","lemma":"inondable","pos":"ADJ"} ,
		{"word":"inondables","word_nosc":"inondables","lemma":"inondable","pos":"ADJ"} ,
		{"word":"inondé","word_nosc":"inonde","lemma":"inondé","pos":"ADJ"} ,
		{"word":"inondée","word_nosc":"inondee","lemma":"inondé","pos":"ADJ"} ,
		{"word":"inondées","word_nosc":"inondees","lemma":"inondé","pos":"ADJ"} ,
		{"word":"inondés","word_nosc":"inondes","lemma":"inondé","pos":"ADJ"} ,
		{"word":"inopiné","word_nosc":"inopine","lemma":"inopiné","pos":"ADJ"} ,
		{"word":"inopinée","word_nosc":"inopinee","lemma":"inopiné","pos":"ADJ"} ,
		{"word":"inopinées","word_nosc":"inopinees","lemma":"inopiné","pos":"ADJ"} ,
		{"word":"inopportun","word_nosc":"inopportun","lemma":"inopportun","pos":"ADJ"} ,
		{"word":"inopportune","word_nosc":"inopportune","lemma":"inopportun","pos":"ADJ"} ,
		{"word":"inopportunes","word_nosc":"inopportunes","lemma":"inopportun","pos":"ADJ"} ,
		{"word":"inopportuns","word_nosc":"inopportuns","lemma":"inopportun","pos":"ADJ"} ,
		{"word":"inopérable","word_nosc":"inoperable","lemma":"inopérable","pos":"ADJ"} ,
		{"word":"inopérables","word_nosc":"inoperables","lemma":"inopérable","pos":"ADJ"} ,
		{"word":"inopérant","word_nosc":"inoperant","lemma":"inopérant","pos":"ADJ"} ,
		{"word":"inopérante","word_nosc":"inoperante","lemma":"inopérant","pos":"ADJ"} ,
		{"word":"inopérantes","word_nosc":"inoperantes","lemma":"inopérant","pos":"ADJ"} ,
		{"word":"inopérants","word_nosc":"inoperants","lemma":"inopérant","pos":"ADJ"} ,
		{"word":"inorganique","word_nosc":"inorganique","lemma":"inorganique","pos":"ADJ"} ,
		{"word":"inorganisé","word_nosc":"inorganise","lemma":"inorganisé","pos":"ADJ"} ,
		{"word":"inorganisée","word_nosc":"inorganisee","lemma":"inorganisé","pos":"ADJ"} ,
		{"word":"inorganisées","word_nosc":"inorganisees","lemma":"inorganisé","pos":"ADJ"} ,
		{"word":"inoubliable","word_nosc":"inoubliable","lemma":"inoubliable","pos":"ADJ"} ,
		{"word":"inoubliables","word_nosc":"inoubliables","lemma":"inoubliable","pos":"ADJ"} ,
		{"word":"inoublié","word_nosc":"inoublie","lemma":"inoublié","pos":"ADJ"} ,
		{"word":"inoubliées","word_nosc":"inoubliees","lemma":"inoublié","pos":"ADJ"} ,
		{"word":"inouï","word_nosc":"inoui","lemma":"inouï","pos":"ADJ"} ,
		{"word":"inouïe","word_nosc":"inouie","lemma":"inouï","pos":"ADJ"} ,
		{"word":"inouïes","word_nosc":"inouies","lemma":"inouï","pos":"ADJ"} ,
		{"word":"inouïs","word_nosc":"inouis","lemma":"inouï","pos":"ADJ"} ,
		{"word":"inoxydable","word_nosc":"inoxydable","lemma":"inoxydable","pos":"ADJ"} ,
		{"word":"inoxydables","word_nosc":"inoxydables","lemma":"inoxydable","pos":"ADJ"} ,
		{"word":"inqualifiable","word_nosc":"inqualifiable","lemma":"inqualifiable","pos":"ADJ"} ,
		{"word":"inqualifiables","word_nosc":"inqualifiables","lemma":"inqualifiable","pos":"ADJ"} ,
		{"word":"inquiet","word_nosc":"inquiet","lemma":"inquiet","pos":"ADJ"} ,
		{"word":"inquiets","word_nosc":"inquiets","lemma":"inquiet","pos":"ADJ"} ,
		{"word":"inquisiteur","word_nosc":"inquisiteur","lemma":"inquisiteur","pos":"ADJ"} ,
		{"word":"inquisiteurs","word_nosc":"inquisiteurs","lemma":"inquisiteur","pos":"ADJ"} ,
		{"word":"inquisitive","word_nosc":"inquisitive","lemma":"inquisitif","pos":"ADJ"} ,
		{"word":"inquisitorial","word_nosc":"inquisitorial","lemma":"inquisitorial","pos":"ADJ"} ,
		{"word":"inquisitoriale","word_nosc":"inquisitoriale","lemma":"inquisitorial","pos":"ADJ"} ,
		{"word":"inquisitoriales","word_nosc":"inquisitoriales","lemma":"inquisitorial","pos":"ADJ"} ,
		{"word":"inquisitoriaux","word_nosc":"inquisitoriaux","lemma":"inquisitorial","pos":"ADJ"} ,
		{"word":"inquisitrice","word_nosc":"inquisitrice","lemma":"inquisiteur","pos":"ADJ"} ,
		{"word":"inquiète","word_nosc":"inquiete","lemma":"inquiet","pos":"ADJ"} ,
		{"word":"inquiètes","word_nosc":"inquietes","lemma":"inquiet","pos":"ADJ"} ,
		{"word":"inquiétant","word_nosc":"inquietant","lemma":"inquiétant","pos":"ADJ"} ,
		{"word":"inquiétante","word_nosc":"inquietante","lemma":"inquiétant","pos":"ADJ"} ,
		{"word":"inquiétantes","word_nosc":"inquietantes","lemma":"inquiétant","pos":"ADJ"} ,
		{"word":"inquiétants","word_nosc":"inquietants","lemma":"inquiétant","pos":"ADJ"} ,
		{"word":"inracontables","word_nosc":"inracontables","lemma":"inracontable","pos":"ADJ"} ,
		{"word":"insaisissable","word_nosc":"insaisissable","lemma":"insaisissable","pos":"ADJ"} ,
		{"word":"insaisissables","word_nosc":"insaisissables","lemma":"insaisissable","pos":"ADJ"} ,
		{"word":"insalissable","word_nosc":"insalissable","lemma":"insalissable","pos":"ADJ"} ,
		{"word":"insalubre","word_nosc":"insalubre","lemma":"insalubre","pos":"ADJ"} ,
		{"word":"insalubres","word_nosc":"insalubres","lemma":"insalubre","pos":"ADJ"} ,
		{"word":"insane","word_nosc":"insane","lemma":"insane","pos":"ADJ"} ,
		{"word":"insanes","word_nosc":"insanes","lemma":"insane","pos":"ADJ"} ,
		{"word":"insatiable","word_nosc":"insatiable","lemma":"insatiable","pos":"ADJ"} ,
		{"word":"insatiables","word_nosc":"insatiables","lemma":"insatiable","pos":"ADJ"} ,
		{"word":"insatisfaisant","word_nosc":"insatisfaisant","lemma":"insatisfaisant","pos":"ADJ"} ,
		{"word":"insatisfaisante","word_nosc":"insatisfaisante","lemma":"insatisfaisant","pos":"ADJ"} ,
		{"word":"insatisfaisantes","word_nosc":"insatisfaisantes","lemma":"insatisfaisant","pos":"ADJ"} ,
		{"word":"insatisfait","word_nosc":"insatisfait","lemma":"insatisfait","pos":"ADJ"} ,
		{"word":"insatisfaite","word_nosc":"insatisfaite","lemma":"insatisfait","pos":"ADJ"} ,
		{"word":"insatisfaites","word_nosc":"insatisfaites","lemma":"insatisfait","pos":"ADJ"} ,
		{"word":"insatisfaits","word_nosc":"insatisfaits","lemma":"insatisfait","pos":"ADJ"} ,
		{"word":"inscrit","word_nosc":"inscrit","lemma":"inscrit","pos":"ADJ"} ,
		{"word":"inscrite","word_nosc":"inscrite","lemma":"inscrit","pos":"ADJ"} ,
		{"word":"inscrites","word_nosc":"inscrites","lemma":"inscrit","pos":"ADJ"} ,
		{"word":"inscrits","word_nosc":"inscrits","lemma":"inscrit","pos":"ADJ"} ,
		{"word":"inscrutable","word_nosc":"inscrutable","lemma":"inscrutable","pos":"ADJ"} ,
		{"word":"insecticide","word_nosc":"insecticide","lemma":"insecticide","pos":"ADJ"} ,
		{"word":"insecticides","word_nosc":"insecticides","lemma":"insecticide","pos":"ADJ"} ,
		{"word":"insectivore","word_nosc":"insectivore","lemma":"insectivore","pos":"ADJ"} ,
		{"word":"insensible","word_nosc":"insensible","lemma":"insensible","pos":"ADJ"} ,
		{"word":"insensibles","word_nosc":"insensibles","lemma":"insensible","pos":"ADJ"} ,
		{"word":"insensé","word_nosc":"insense","lemma":"insensé","pos":"ADJ"} ,
		{"word":"insensée","word_nosc":"insensee","lemma":"insensé","pos":"ADJ"} ,
		{"word":"insensées","word_nosc":"insensees","lemma":"insensé","pos":"ADJ"} ,
		{"word":"insensés","word_nosc":"insenses","lemma":"insensé","pos":"ADJ"} ,
		{"word":"insidieuse","word_nosc":"insidieuse","lemma":"insidieux","pos":"ADJ"} ,
		{"word":"insidieuses","word_nosc":"insidieuses","lemma":"insidieux","pos":"ADJ"} ,
		{"word":"insidieux","word_nosc":"insidieux","lemma":"insidieux","pos":"ADJ"} ,
		{"word":"insigne","word_nosc":"insigne","lemma":"insigne","pos":"ADJ"} ,
		{"word":"insignes","word_nosc":"insignes","lemma":"insigne","pos":"ADJ"} ,
		{"word":"insignifiant","word_nosc":"insignifiant","lemma":"insignifiant","pos":"ADJ"} ,
		{"word":"insignifiante","word_nosc":"insignifiante","lemma":"insignifiant","pos":"ADJ"} ,
		{"word":"insignifiantes","word_nosc":"insignifiantes","lemma":"insignifiant","pos":"ADJ"} ,
		{"word":"insignifiants","word_nosc":"insignifiants","lemma":"insignifiant","pos":"ADJ"} ,
		{"word":"insincère","word_nosc":"insincere","lemma":"insincère","pos":"ADJ"} ,
		{"word":"insinuant","word_nosc":"insinuant","lemma":"insinuant","pos":"ADJ"} ,
		{"word":"insinuante","word_nosc":"insinuante","lemma":"insinuant","pos":"ADJ"} ,
		{"word":"insinuantes","word_nosc":"insinuantes","lemma":"insinuant","pos":"ADJ"} ,
		{"word":"insinuants","word_nosc":"insinuants","lemma":"insinuant","pos":"ADJ"} ,
		{"word":"insipide","word_nosc":"insipide","lemma":"insipide","pos":"ADJ"} ,
		{"word":"insipides","word_nosc":"insipides","lemma":"insipide","pos":"ADJ"} ,
		{"word":"insistant","word_nosc":"insistant","lemma":"insistant","pos":"ADJ"} ,
		{"word":"insistante","word_nosc":"insistante","lemma":"insistant","pos":"ADJ"} ,
		{"word":"insistantes","word_nosc":"insistantes","lemma":"insistant","pos":"ADJ"} ,
		{"word":"insistants","word_nosc":"insistants","lemma":"insistant","pos":"ADJ"} ,
		{"word":"insociable","word_nosc":"insociable","lemma":"insociable","pos":"ADJ"} ,
		{"word":"insolent","word_nosc":"insolent","lemma":"insolent","pos":"ADJ"} ,
		{"word":"insolente","word_nosc":"insolente","lemma":"insolent","pos":"ADJ"} ,
		{"word":"insolentes","word_nosc":"insolentes","lemma":"insolent","pos":"ADJ"} ,
		{"word":"insolents","word_nosc":"insolents","lemma":"insolent","pos":"ADJ"} ,
		{"word":"insolite","word_nosc":"insolite","lemma":"insolite","pos":"ADJ"} ,
		{"word":"insolites","word_nosc":"insolites","lemma":"insolite","pos":"ADJ"} ,
		{"word":"insoluble","word_nosc":"insoluble","lemma":"insoluble","pos":"ADJ"} ,
		{"word":"insolubles","word_nosc":"insolubles","lemma":"insoluble","pos":"ADJ"} ,
		{"word":"insolvable","word_nosc":"insolvable","lemma":"insolvable","pos":"ADJ"} ,
		{"word":"insolvables","word_nosc":"insolvables","lemma":"insolvable","pos":"ADJ"} ,
		{"word":"insomniaque","word_nosc":"insomniaque","lemma":"insomniaque","pos":"ADJ"} ,
		{"word":"insomniaques","word_nosc":"insomniaques","lemma":"insomniaque","pos":"ADJ"} ,
		{"word":"insondable","word_nosc":"insondable","lemma":"insondable","pos":"ADJ"} ,
		{"word":"insondables","word_nosc":"insondables","lemma":"insondable","pos":"ADJ"} ,
		{"word":"insonore","word_nosc":"insonore","lemma":"insonore","pos":"ADJ"} ,
		{"word":"insortable","word_nosc":"insortable","lemma":"insortable","pos":"ADJ"} ,
		{"word":"insortables","word_nosc":"insortables","lemma":"insortable","pos":"ADJ"} ,
		{"word":"insouciant","word_nosc":"insouciant","lemma":"insouciant","pos":"ADJ"} ,
		{"word":"insouciante","word_nosc":"insouciante","lemma":"insouciant","pos":"ADJ"} ,
		{"word":"insouciantes","word_nosc":"insouciantes","lemma":"insouciant","pos":"ADJ"} ,
		{"word":"insouciants","word_nosc":"insouciants","lemma":"insouciant","pos":"ADJ"} ,
		{"word":"insoucieuse","word_nosc":"insoucieuse","lemma":"insoucieux","pos":"ADJ"} ,
		{"word":"insoucieux","word_nosc":"insoucieux","lemma":"insoucieux","pos":"ADJ"} ,
		{"word":"insoulevables","word_nosc":"insoulevables","lemma":"insoulevable","pos":"ADJ"} ,
		{"word":"insoumis","word_nosc":"insoumis","lemma":"insoumis","pos":"ADJ"} ,
		{"word":"insoumise","word_nosc":"insoumise","lemma":"insoumis","pos":"ADJ"} ,
		{"word":"insoupçonnable","word_nosc":"insoupconnable","lemma":"insoupçonnable","pos":"ADJ"} ,
		{"word":"insoupçonnables","word_nosc":"insoupconnables","lemma":"insoupçonnable","pos":"ADJ"} ,
		{"word":"insoupçonné","word_nosc":"insoupconne","lemma":"insoupçonné","pos":"ADJ"} ,
		{"word":"insoupçonnée","word_nosc":"insoupconnee","lemma":"insoupçonné","pos":"ADJ"} ,
		{"word":"insoupçonnées","word_nosc":"insoupconnees","lemma":"insoupçonné","pos":"ADJ"} ,
		{"word":"insoupçonnés","word_nosc":"insoupconnes","lemma":"insoupçonné","pos":"ADJ"} ,
		{"word":"insoutenable","word_nosc":"insoutenable","lemma":"insoutenable","pos":"ADJ"} ,
		{"word":"insoutenables","word_nosc":"insoutenables","lemma":"insoutenable","pos":"ADJ"} ,
		{"word":"inspirant","word_nosc":"inspirant","lemma":"inspirant","pos":"ADJ"} ,
		{"word":"inspirante","word_nosc":"inspirante","lemma":"inspirant","pos":"ADJ"} ,
		{"word":"inspirantes","word_nosc":"inspirantes","lemma":"inspirant","pos":"ADJ"} ,
		{"word":"inspirants","word_nosc":"inspirants","lemma":"inspirant","pos":"ADJ"} ,
		{"word":"inspirateur","word_nosc":"inspirateur","lemma":"inspirateur","pos":"ADJ"} ,
		{"word":"inspiratoire","word_nosc":"inspiratoire","lemma":"inspiratoire","pos":"ADJ"} ,
		{"word":"inspiré","word_nosc":"inspire","lemma":"inspiré","pos":"ADJ"} ,
		{"word":"inspirée","word_nosc":"inspiree","lemma":"inspiré","pos":"ADJ"} ,
		{"word":"inspirées","word_nosc":"inspirees","lemma":"inspiré","pos":"ADJ"} ,
		{"word":"inspirés","word_nosc":"inspires","lemma":"inspiré","pos":"ADJ"} ,
		{"word":"instable","word_nosc":"instable","lemma":"instable","pos":"ADJ"} ,
		{"word":"instables","word_nosc":"instables","lemma":"instable","pos":"ADJ"} ,
		{"word":"instant","word_nosc":"instant","lemma":"instant","pos":"ADJ"} ,
		{"word":"instantané","word_nosc":"instantane","lemma":"instantané","pos":"ADJ"} ,
		{"word":"instantanée","word_nosc":"instantanee","lemma":"instantané","pos":"ADJ"} ,
		{"word":"instantanées","word_nosc":"instantanees","lemma":"instantané","pos":"ADJ"} ,
		{"word":"instantanés","word_nosc":"instantanes","lemma":"instantané","pos":"ADJ"} ,
		{"word":"instante","word_nosc":"instante","lemma":"instant","pos":"ADJ"} ,
		{"word":"instantes","word_nosc":"instantes","lemma":"instant","pos":"ADJ"} ,
		{"word":"instants","word_nosc":"instants","lemma":"instant","pos":"ADJ"} ,
		{"word":"instinctif","word_nosc":"instinctif","lemma":"instinctif","pos":"ADJ"} ,
		{"word":"instinctifs","word_nosc":"instinctifs","lemma":"instinctif","pos":"ADJ"} ,
		{"word":"instinctive","word_nosc":"instinctive","lemma":"instinctif","pos":"ADJ"} ,
		{"word":"instinctives","word_nosc":"instinctives","lemma":"instinctif","pos":"ADJ"} ,
		{"word":"instinctuel","word_nosc":"instinctuel","lemma":"instinctuel","pos":"ADJ"} ,
		{"word":"institutionnel","word_nosc":"institutionnel","lemma":"institutionnel","pos":"ADJ"} ,
		{"word":"institutionnelle","word_nosc":"institutionnelle","lemma":"institutionnel","pos":"ADJ"} ,
		{"word":"institutionnels","word_nosc":"institutionnels","lemma":"institutionnel","pos":"ADJ"} ,
		{"word":"instructeur","word_nosc":"instructeur","lemma":"instructeur","pos":"ADJ"} ,
		{"word":"instructeurs","word_nosc":"instructeurs","lemma":"instructeur","pos":"ADJ"} ,
		{"word":"instructif","word_nosc":"instructif","lemma":"instructif","pos":"ADJ"} ,
		{"word":"instructifs","word_nosc":"instructifs","lemma":"instructif","pos":"ADJ"} ,
		{"word":"instructive","word_nosc":"instructive","lemma":"instructif","pos":"ADJ"} ,
		{"word":"instructives","word_nosc":"instructives","lemma":"instructif","pos":"ADJ"} ,
		{"word":"instruit","word_nosc":"instruit","lemma":"instruit","pos":"ADJ"} ,
		{"word":"instruite","word_nosc":"instruite","lemma":"instruit","pos":"ADJ"} ,
		{"word":"instruites","word_nosc":"instruites","lemma":"instruit","pos":"ADJ"} ,
		{"word":"instruits","word_nosc":"instruits","lemma":"instruit","pos":"ADJ"} ,
		{"word":"instrumental","word_nosc":"instrumental","lemma":"instrumental","pos":"ADJ"} ,
		{"word":"instrumentale","word_nosc":"instrumentale","lemma":"instrumental","pos":"ADJ"} ,
		{"word":"instrumentaux","word_nosc":"instrumentaux","lemma":"instrumental","pos":"ADJ"} ,
		{"word":"insubmersible","word_nosc":"insubmersible","lemma":"insubmersible","pos":"ADJ"} ,
		{"word":"insubmersibles","word_nosc":"insubmersibles","lemma":"insubmersible","pos":"ADJ"} ,
		{"word":"insubordonné","word_nosc":"insubordonne","lemma":"insubordonné","pos":"ADJ"} ,
		{"word":"insubordonnée","word_nosc":"insubordonnee","lemma":"insubordonné","pos":"ADJ"} ,
		{"word":"insuffisant","word_nosc":"insuffisant","lemma":"insuffisant","pos":"ADJ"} ,
		{"word":"insuffisante","word_nosc":"insuffisante","lemma":"insuffisant","pos":"ADJ"} ,
		{"word":"insuffisantes","word_nosc":"insuffisantes","lemma":"insuffisant","pos":"ADJ"} ,
		{"word":"insuffisants","word_nosc":"insuffisants","lemma":"insuffisant","pos":"ADJ"} ,
		{"word":"insulaire","word_nosc":"insulaire","lemma":"insulaire","pos":"ADJ"} ,
		{"word":"insulaires","word_nosc":"insulaires","lemma":"insulaire","pos":"ADJ"} ,
		{"word":"insulinique","word_nosc":"insulinique","lemma":"insulinique","pos":"ADJ"} ,
		{"word":"insultant","word_nosc":"insultant","lemma":"insultant","pos":"ADJ"} ,
		{"word":"insultante","word_nosc":"insultante","lemma":"insultant","pos":"ADJ"} ,
		{"word":"insultantes","word_nosc":"insultantes","lemma":"insultant","pos":"ADJ"} ,
		{"word":"insultants","word_nosc":"insultants","lemma":"insultant","pos":"ADJ"} ,
		{"word":"insulté","word_nosc":"insulte","lemma":"insulté","pos":"ADJ"} ,
		{"word":"insultée","word_nosc":"insultee","lemma":"insulté","pos":"ADJ"} ,
		{"word":"insupportable","word_nosc":"insupportable","lemma":"insupportable","pos":"ADJ"} ,
		{"word":"insupportables","word_nosc":"insupportables","lemma":"insupportable","pos":"ADJ"} ,
		{"word":"insurgé","word_nosc":"insurge","lemma":"insurgé","pos":"ADJ"} ,
		{"word":"insurgés","word_nosc":"insurges","lemma":"insurgé","pos":"ADJ"} ,
		{"word":"insurmontable","word_nosc":"insurmontable","lemma":"insurmontable","pos":"ADJ"} ,
		{"word":"insurmontables","word_nosc":"insurmontables","lemma":"insurmontable","pos":"ADJ"} ,
		{"word":"insurpassable","word_nosc":"insurpassable","lemma":"insurpassable","pos":"ADJ"} ,
		{"word":"insurpassé","word_nosc":"insurpasse","lemma":"insurpassé","pos":"ADJ"} ,
		{"word":"insurrectionnel","word_nosc":"insurrectionnel","lemma":"insurrectionnel","pos":"ADJ"} ,
		{"word":"insurrectionnelle","word_nosc":"insurrectionnelle","lemma":"insurrectionnel","pos":"ADJ"} ,
		{"word":"insécable","word_nosc":"insecable","lemma":"insécable","pos":"ADJ"} ,
		{"word":"inséparable","word_nosc":"inseparable","lemma":"inséparable","pos":"ADJ"} ,
		{"word":"inséparables","word_nosc":"inseparables","lemma":"inséparable","pos":"ADJ"} ,
		{"word":"intact","word_nosc":"intact","lemma":"intact","pos":"ADJ"} ,
		{"word":"intacte","word_nosc":"intacte","lemma":"intact","pos":"ADJ"} ,
		{"word":"intactes","word_nosc":"intactes","lemma":"intact","pos":"ADJ"} ,
		{"word":"intacts","word_nosc":"intacts","lemma":"intact","pos":"ADJ"} ,
		{"word":"intangible","word_nosc":"intangible","lemma":"intangible","pos":"ADJ"} ,
		{"word":"intangibles","word_nosc":"intangibles","lemma":"intangible","pos":"ADJ"} ,
		{"word":"intarissable","word_nosc":"intarissable","lemma":"intarissable","pos":"ADJ"} ,
		{"word":"intarissables","word_nosc":"intarissables","lemma":"intarissable","pos":"ADJ"} ,
		{"word":"intellectuel","word_nosc":"intellectuel","lemma":"intellectuel","pos":"ADJ"} ,
		{"word":"intellectuelle","word_nosc":"intellectuelle","lemma":"intellectuel","pos":"ADJ"} ,
		{"word":"intellectuelles","word_nosc":"intellectuelles","lemma":"intellectuel","pos":"ADJ"} ,
		{"word":"intellectuels","word_nosc":"intellectuels","lemma":"intellectuel","pos":"ADJ"} ,
		{"word":"intelligent","word_nosc":"intelligent","lemma":"intelligent","pos":"ADJ"} ,
		{"word":"intelligente","word_nosc":"intelligente","lemma":"intelligent","pos":"ADJ"} ,
		{"word":"intelligentes","word_nosc":"intelligentes","lemma":"intelligent","pos":"ADJ"} ,
		{"word":"intelligents","word_nosc":"intelligents","lemma":"intelligent","pos":"ADJ"} ,
		{"word":"intelligible","word_nosc":"intelligible","lemma":"intelligible","pos":"ADJ"} ,
		{"word":"intelligibles","word_nosc":"intelligibles","lemma":"intelligible","pos":"ADJ"} ,
		{"word":"intempestif","word_nosc":"intempestif","lemma":"intempestif","pos":"ADJ"} ,
		{"word":"intempestifs","word_nosc":"intempestifs","lemma":"intempestif","pos":"ADJ"} ,
		{"word":"intempestive","word_nosc":"intempestive","lemma":"intempestif","pos":"ADJ"} ,
		{"word":"intempestives","word_nosc":"intempestives","lemma":"intempestif","pos":"ADJ"} ,
		{"word":"intemporel","word_nosc":"intemporel","lemma":"intemporel","pos":"ADJ"} ,
		{"word":"intemporelle","word_nosc":"intemporelle","lemma":"intemporel","pos":"ADJ"} ,
		{"word":"intemporelles","word_nosc":"intemporelles","lemma":"intemporel","pos":"ADJ"} ,
		{"word":"intempérante","word_nosc":"intemperante","lemma":"intempérant","pos":"ADJ"} ,
		{"word":"intenable","word_nosc":"intenable","lemma":"intenable","pos":"ADJ"} ,
		{"word":"intenables","word_nosc":"intenables","lemma":"intenable","pos":"ADJ"} ,
		{"word":"intense","word_nosc":"intense","lemma":"intense","pos":"ADJ"} ,
		{"word":"intenses","word_nosc":"intenses","lemma":"intense","pos":"ADJ"} ,
		{"word":"intensif","word_nosc":"intensif","lemma":"intensif","pos":"ADJ"} ,
		{"word":"intensifs","word_nosc":"intensifs","lemma":"intensif","pos":"ADJ"} ,
		{"word":"intensive","word_nosc":"intensive","lemma":"intensif","pos":"ADJ"} ,
		{"word":"intensives","word_nosc":"intensives","lemma":"intensif","pos":"ADJ"} ,
		{"word":"intentionnel","word_nosc":"intentionnel","lemma":"intentionnel","pos":"ADJ"} ,
		{"word":"intentionnelle","word_nosc":"intentionnelle","lemma":"intentionnel","pos":"ADJ"} ,
		{"word":"intentionnels","word_nosc":"intentionnels","lemma":"intentionnel","pos":"ADJ"} ,
		{"word":"intentionné","word_nosc":"intentionne","lemma":"intentionné","pos":"ADJ"} ,
		{"word":"intentionnée","word_nosc":"intentionnee","lemma":"intentionné","pos":"ADJ"} ,
		{"word":"intentionnées","word_nosc":"intentionnees","lemma":"intentionné","pos":"ADJ"} ,
		{"word":"intentionnés","word_nosc":"intentionnes","lemma":"intentionné","pos":"ADJ"} ,
		{"word":"interactif","word_nosc":"interactif","lemma":"interactif","pos":"ADJ"} ,
		{"word":"interactifs","word_nosc":"interactifs","lemma":"interactif","pos":"ADJ"} ,
		{"word":"interactive","word_nosc":"interactive","lemma":"interactif","pos":"ADJ"} ,
		{"word":"interallié","word_nosc":"interallie","lemma":"interallié","pos":"ADJ"} ,
		{"word":"interalliée","word_nosc":"interalliee","lemma":"interallié","pos":"ADJ"} ,
		{"word":"interalliées","word_nosc":"interalliees","lemma":"interallié","pos":"ADJ"} ,
		{"word":"interalliés","word_nosc":"interallies","lemma":"interallié","pos":"ADJ"} ,
		{"word":"interarmes","word_nosc":"interarmes","lemma":"interarmes","pos":"ADJ"} ,
		{"word":"interarmées","word_nosc":"interarmees","lemma":"interarmées","pos":"ADJ"} ,
		{"word":"intercalaire","word_nosc":"intercalaire","lemma":"intercalaire","pos":"ADJ"} ,
		{"word":"intercalaires","word_nosc":"intercalaires","lemma":"intercalaire","pos":"ADJ"} ,
		{"word":"intercalée","word_nosc":"intercalee","lemma":"intercalé","pos":"ADJ"} ,
		{"word":"intercalées","word_nosc":"intercalees","lemma":"intercalé","pos":"ADJ"} ,
		{"word":"intercalés","word_nosc":"intercales","lemma":"intercalé","pos":"ADJ"} ,
		{"word":"intercellulaire","word_nosc":"intercellulaire","lemma":"intercellulaire","pos":"ADJ"} ,
		{"word":"interchangeable","word_nosc":"interchangeable","lemma":"interchangeable","pos":"ADJ"} ,
		{"word":"interchangeables","word_nosc":"interchangeables","lemma":"interchangeable","pos":"ADJ"} ,
		{"word":"interclubs","word_nosc":"interclubs","lemma":"interclubs","pos":"ADJ"} ,
		{"word":"intercommunal","word_nosc":"intercommunal","lemma":"intercommunal","pos":"ADJ"} ,
		{"word":"intercommunautaire","word_nosc":"intercommunautaire","lemma":"intercommunautaire","pos":"ADJ"} ,
		{"word":"intercontinental","word_nosc":"intercontinental","lemma":"intercontinental","pos":"ADJ"} ,
		{"word":"intercontinentale","word_nosc":"intercontinentale","lemma":"intercontinental","pos":"ADJ"} ,
		{"word":"intercontinentales","word_nosc":"intercontinentales","lemma":"intercontinental","pos":"ADJ"} ,
		{"word":"intercontinentaux","word_nosc":"intercontinentaux","lemma":"intercontinental","pos":"ADJ"} ,
		{"word":"intercostal","word_nosc":"intercostal","lemma":"intercostal","pos":"ADJ"} ,
		{"word":"intercostale","word_nosc":"intercostale","lemma":"intercostal","pos":"ADJ"} ,
		{"word":"intercostaux","word_nosc":"intercostaux","lemma":"intercostal","pos":"ADJ"} ,
		{"word":"interculturel","word_nosc":"interculturel","lemma":"interculturel","pos":"ADJ"} ,
		{"word":"interculturels","word_nosc":"interculturels","lemma":"interculturel","pos":"ADJ"} ,
		{"word":"interdisciplinaires","word_nosc":"interdisciplinaires","lemma":"interdisciplinaire","pos":"ADJ"} ,
		{"word":"interdit","word_nosc":"interdit","lemma":"interdit","pos":"ADJ"} ,
		{"word":"interdite","word_nosc":"interdite","lemma":"interdit","pos":"ADJ"} ,
		{"word":"interdites","word_nosc":"interdites","lemma":"interdit","pos":"ADJ"} ,
		{"word":"interdits","word_nosc":"interdits","lemma":"interdit","pos":"ADJ"} ,
		{"word":"interdépartementaux","word_nosc":"interdepartementaux","lemma":"interdépartemental","pos":"ADJ"} ,
		{"word":"interdépendant","word_nosc":"interdependant","lemma":"interdépendant","pos":"ADJ"} ,
		{"word":"interethnique","word_nosc":"interethnique","lemma":"interethnique","pos":"ADJ"} ,
		{"word":"interférent","word_nosc":"interferent","lemma":"interférent","pos":"ADJ"} ,
		{"word":"intergalactique","word_nosc":"intergalactique","lemma":"intergalactique","pos":"ADJ"} ,
		{"word":"intergalactiques","word_nosc":"intergalactiques","lemma":"intergalactique","pos":"ADJ"} ,
		{"word":"interglaciaire","word_nosc":"interglaciaire","lemma":"interglaciaire","pos":"ADJ"} ,
		{"word":"intergouvernemental","word_nosc":"intergouvernemental","lemma":"intergouvernemental","pos":"ADJ"} ,
		{"word":"intergouvernementale","word_nosc":"intergouvernementale","lemma":"intergouvernemental","pos":"ADJ"} ,
		{"word":"interlocutoire","word_nosc":"interlocutoire","lemma":"interlocutoire","pos":"ADJ"} ,
		{"word":"interlocutoires","word_nosc":"interlocutoires","lemma":"interlocutoire","pos":"ADJ"} ,
		{"word":"interlope","word_nosc":"interlope","lemma":"interlope","pos":"ADJ"} ,
		{"word":"interlopes","word_nosc":"interlopes","lemma":"interlope","pos":"ADJ"} ,
		{"word":"interloqué","word_nosc":"interloque","lemma":"interloqué","pos":"ADJ"} ,
		{"word":"interloquée","word_nosc":"interloquee","lemma":"interloqué","pos":"ADJ"} ,
		{"word":"interloquées","word_nosc":"interloquees","lemma":"interloqué","pos":"ADJ"} ,
		{"word":"interloqués","word_nosc":"interloques","lemma":"interloqué","pos":"ADJ"} ,
		{"word":"interminable","word_nosc":"interminable","lemma":"interminable","pos":"ADJ"} ,
		{"word":"interminables","word_nosc":"interminables","lemma":"interminable","pos":"ADJ"} ,
		{"word":"interministérielles","word_nosc":"interministerielles","lemma":"interministériel","pos":"ADJ"} ,
		{"word":"interministériels","word_nosc":"interministeriels","lemma":"interministériel","pos":"ADJ"} ,
		{"word":"intermittent","word_nosc":"intermittent","lemma":"intermittent","pos":"ADJ"} ,
		{"word":"intermittente","word_nosc":"intermittente","lemma":"intermittent","pos":"ADJ"} ,
		{"word":"intermittentes","word_nosc":"intermittentes","lemma":"intermittent","pos":"ADJ"} ,
		{"word":"intermittents","word_nosc":"intermittents","lemma":"intermittent","pos":"ADJ"} ,
		{"word":"intermédiaire","word_nosc":"intermediaire","lemma":"intermédiaire","pos":"ADJ"} ,
		{"word":"intermédiaires","word_nosc":"intermediaires","lemma":"intermédiaire","pos":"ADJ"} ,
		{"word":"international","word_nosc":"international","lemma":"international","pos":"ADJ"} ,
		{"word":"internationale","word_nosc":"internationale","lemma":"international","pos":"ADJ"} ,
		{"word":"internationales","word_nosc":"internationales","lemma":"international","pos":"ADJ"} ,
		{"word":"internationaliste","word_nosc":"internationaliste","lemma":"internationaliste","pos":"ADJ"} ,
		{"word":"internationalistes","word_nosc":"internationalistes","lemma":"internationaliste","pos":"ADJ"} ,
		{"word":"internationaux","word_nosc":"internationaux","lemma":"international","pos":"ADJ"} ,
		{"word":"interne","word_nosc":"interne","lemma":"interne","pos":"ADJ"} ,
		{"word":"internes","word_nosc":"internes","lemma":"interne","pos":"ADJ"} ,
		{"word":"interné","word_nosc":"interne","lemma":"interné","pos":"ADJ"} ,
		{"word":"internée","word_nosc":"internee","lemma":"interné","pos":"ADJ"} ,
		{"word":"internées","word_nosc":"internees","lemma":"interné","pos":"ADJ"} ,
		{"word":"internés","word_nosc":"internes","lemma":"interné","pos":"ADJ"} ,
		{"word":"interplanétaire","word_nosc":"interplanetaire","lemma":"interplanétaire","pos":"ADJ"} ,
		{"word":"interplanétaires","word_nosc":"interplanetaires","lemma":"interplanétaire","pos":"ADJ"} ,
		{"word":"interposé","word_nosc":"interpose","lemma":"interposé","pos":"ADJ"} ,
		{"word":"interposée","word_nosc":"interposee","lemma":"interposé","pos":"ADJ"} ,
		{"word":"interposées","word_nosc":"interposees","lemma":"interposé","pos":"ADJ"} ,
		{"word":"interposés","word_nosc":"interposes","lemma":"interposé","pos":"ADJ"} ,
		{"word":"interprofessionnelle","word_nosc":"interprofessionnelle","lemma":"interprofessionnel","pos":"ADJ"} ,
		{"word":"interprétable","word_nosc":"interpretable","lemma":"interprétable","pos":"ADJ"} ,
		{"word":"interprétante","word_nosc":"interpretante","lemma":"interprétant","pos":"ADJ"} ,
		{"word":"interprétatif","word_nosc":"interpretatif","lemma":"interprétatif","pos":"ADJ"} ,
		{"word":"interprétative","word_nosc":"interpretative","lemma":"interprétatif","pos":"ADJ"} ,
		{"word":"interprétatives","word_nosc":"interpretatives","lemma":"interprétatif","pos":"ADJ"} ,
		{"word":"interracial","word_nosc":"interracial","lemma":"interracial","pos":"ADJ"} ,
		{"word":"interraciale","word_nosc":"interraciale","lemma":"interracial","pos":"ADJ"} ,
		{"word":"interraciaux","word_nosc":"interraciaux","lemma":"interracial","pos":"ADJ"} ,
		{"word":"interrogateur","word_nosc":"interrogateur","lemma":"interrogateur","pos":"ADJ"} ,
		{"word":"interrogateurs","word_nosc":"interrogateurs","lemma":"interrogateur","pos":"ADJ"} ,
		{"word":"interrogatif","word_nosc":"interrogatif","lemma":"interrogatif","pos":"ADJ"} ,
		{"word":"interrogatifs","word_nosc":"interrogatifs","lemma":"interrogatif","pos":"ADJ"} ,
		{"word":"interrogative","word_nosc":"interrogative","lemma":"interrogatif","pos":"ADJ"} ,
		{"word":"interrogatives","word_nosc":"interrogatives","lemma":"interrogatif","pos":"ADJ"} ,
		{"word":"interrogeant","word_nosc":"interrogeant","lemma":"interrogeant","pos":"ADJ"} ,
		{"word":"interrompu","word_nosc":"interrompu","lemma":"interrompu","pos":"ADJ"} ,
		{"word":"interrompue","word_nosc":"interrompue","lemma":"interrompu","pos":"ADJ"} ,
		{"word":"interrompues","word_nosc":"interrompues","lemma":"interrompu","pos":"ADJ"} ,
		{"word":"interrompus","word_nosc":"interrompus","lemma":"interrompu","pos":"ADJ"} ,
		{"word":"interscolaire","word_nosc":"interscolaire","lemma":"interscolaire","pos":"ADJ"} ,
		{"word":"interservices","word_nosc":"interservices","lemma":"interservices","pos":"ADJ"} ,
		{"word":"intersidéral","word_nosc":"intersideral","lemma":"intersidéral","pos":"ADJ"} ,
		{"word":"intersidérale","word_nosc":"intersiderale","lemma":"intersidéral","pos":"ADJ"} ,
		{"word":"intersidéraux","word_nosc":"intersideraux","lemma":"intersidéral","pos":"ADJ"} ,
		{"word":"interstellaire","word_nosc":"interstellaire","lemma":"interstellaire","pos":"ADJ"} ,
		{"word":"interstellaires","word_nosc":"interstellaires","lemma":"interstellaire","pos":"ADJ"} ,
		{"word":"intersubjectif","word_nosc":"intersubjectif","lemma":"intersubjectif","pos":"ADJ"} ,
		{"word":"intertribal","word_nosc":"intertribal","lemma":"intertribal","pos":"ADJ"} ,
		{"word":"interurbain","word_nosc":"interurbain","lemma":"interurbain","pos":"ADJ"} ,
		{"word":"interurbaine","word_nosc":"interurbaine","lemma":"interurbain","pos":"ADJ"} ,
		{"word":"intervenant","word_nosc":"intervenant","lemma":"intervenant","pos":"ADJ"} ,
		{"word":"interventionniste","word_nosc":"interventionniste","lemma":"interventionniste","pos":"ADJ"} ,
		{"word":"interventionnistes","word_nosc":"interventionnistes","lemma":"interventionniste","pos":"ADJ"} ,
		{"word":"interventriculaire","word_nosc":"interventriculaire","lemma":"interventriculaire","pos":"ADJ"} ,
		{"word":"intervertébral","word_nosc":"intervertebral","lemma":"intervertébral","pos":"ADJ"} ,
		{"word":"intervertébraux","word_nosc":"intervertebraux","lemma":"intervertébral","pos":"ADJ"} ,
		{"word":"interviewé","word_nosc":"interviewe","lemma":"interviewé","pos":"ADJ"} ,
		{"word":"interzones","word_nosc":"interzones","lemma":"interzone","pos":"ADJ"} ,
		{"word":"interétatique","word_nosc":"interetatique","lemma":"interétatique","pos":"ADJ"} ,
		{"word":"intestat","word_nosc":"intestat","lemma":"intestat","pos":"ADJ"} ,
		{"word":"intestin","word_nosc":"intestin","lemma":"intestin","pos":"ADJ"} ,
		{"word":"intestinal","word_nosc":"intestinal","lemma":"intestinal","pos":"ADJ"} ,
		{"word":"intestinale","word_nosc":"intestinale","lemma":"intestinal","pos":"ADJ"} ,
		{"word":"intestinales","word_nosc":"intestinales","lemma":"intestinal","pos":"ADJ"} ,
		{"word":"intestinaux","word_nosc":"intestinaux","lemma":"intestinal","pos":"ADJ"} ,
		{"word":"intestine","word_nosc":"intestine","lemma":"intestin","pos":"ADJ"} ,
		{"word":"intestines","word_nosc":"intestines","lemma":"intestin","pos":"ADJ"} ,
		{"word":"intestins","word_nosc":"intestins","lemma":"intestin","pos":"ADJ"} ,
		{"word":"intime","word_nosc":"intime","lemma":"intime","pos":"ADJ"} ,
		{"word":"intimes","word_nosc":"intimes","lemma":"intime","pos":"ADJ"} ,
		{"word":"intimidable","word_nosc":"intimidable","lemma":"intimidable","pos":"ADJ"} ,
		{"word":"intimidant","word_nosc":"intimidant","lemma":"intimidant","pos":"ADJ"} ,
		{"word":"intimidante","word_nosc":"intimidante","lemma":"intimidant","pos":"ADJ"} ,
		{"word":"intimidants","word_nosc":"intimidants","lemma":"intimidant","pos":"ADJ"} ,
		{"word":"intimiste","word_nosc":"intimiste","lemma":"intimiste","pos":"ADJ"} ,
		{"word":"intimistes","word_nosc":"intimistes","lemma":"intimiste","pos":"ADJ"} ,
		{"word":"intirable","word_nosc":"intirable","lemma":"intirable","pos":"ADJ"} ,
		{"word":"intolérable","word_nosc":"intolerable","lemma":"intolérable","pos":"ADJ"} ,
		{"word":"intolérables","word_nosc":"intolerables","lemma":"intolérable","pos":"ADJ"} ,
		{"word":"intolérant","word_nosc":"intolerant","lemma":"intolérant","pos":"ADJ"} ,
		{"word":"intolérante","word_nosc":"intolerante","lemma":"intolérant","pos":"ADJ"} ,
		{"word":"intolérants","word_nosc":"intolerants","lemma":"intolérant","pos":"ADJ"} ,
		{"word":"intouchable","word_nosc":"intouchable","lemma":"intouchable","pos":"ADJ"} ,
		{"word":"intouchables","word_nosc":"intouchables","lemma":"intouchable","pos":"ADJ"} ,
		{"word":"intouché","word_nosc":"intouche","lemma":"intouché","pos":"ADJ"} ,
		{"word":"intouchée","word_nosc":"intouchee","lemma":"intouché","pos":"ADJ"} ,
		{"word":"intouchées","word_nosc":"intouchees","lemma":"intouché","pos":"ADJ"} ,
		{"word":"intouchés","word_nosc":"intouches","lemma":"intouché","pos":"ADJ"} ,
		{"word":"intoxicant","word_nosc":"intoxicant","lemma":"intoxicant","pos":"ADJ"} ,
		{"word":"intoxiqué","word_nosc":"intoxique","lemma":"intoxiqué","pos":"ADJ"} ,
		{"word":"intoxiquée","word_nosc":"intoxiquee","lemma":"intoxiqué","pos":"ADJ"} ,
		{"word":"intoxiqués","word_nosc":"intoxiques","lemma":"intoxiqué","pos":"ADJ"} ,
		{"word":"intra-atomique","word_nosc":"intra-atomique","lemma":"intra-atomique","pos":"ADJ"} ,
		{"word":"intra-rachidienne","word_nosc":"intra-rachidienne","lemma":"rachidien","pos":"ADJ"} ,
		{"word":"intra-utérine","word_nosc":"intra-uterine","lemma":"intra-utérin","pos":"ADJ"} ,
		{"word":"intra-utérines","word_nosc":"intra-uterines","lemma":"intra-utérin","pos":"ADJ"} ,
		{"word":"intracardiaque","word_nosc":"intracardiaque","lemma":"intracardiaque","pos":"ADJ"} ,
		{"word":"intracrânien","word_nosc":"intracranien","lemma":"intracrânien","pos":"ADJ"} ,
		{"word":"intracrânienne","word_nosc":"intracranienne","lemma":"intracrânien","pos":"ADJ"} ,
		{"word":"intracérébrale","word_nosc":"intracerebrale","lemma":"intracérébral","pos":"ADJ"} ,
		{"word":"intradermique","word_nosc":"intradermique","lemma":"intradermique","pos":"ADJ"} ,
		{"word":"intraduisible","word_nosc":"intraduisible","lemma":"intraduisible","pos":"ADJ"} ,
		{"word":"intraduisibles","word_nosc":"intraduisibles","lemma":"intraduisible","pos":"ADJ"} ,
		{"word":"intraitable","word_nosc":"intraitable","lemma":"intraitable","pos":"ADJ"} ,
		{"word":"intraitables","word_nosc":"intraitables","lemma":"intraitable","pos":"ADJ"} ,
		{"word":"intramusculaire","word_nosc":"intramusculaire","lemma":"intramusculaire","pos":"ADJ"} ,
		{"word":"intramusculaires","word_nosc":"intramusculaires","lemma":"intramusculaire","pos":"ADJ"} ,
		{"word":"intransgressible","word_nosc":"intransgressible","lemma":"intransgressible","pos":"ADJ"} ,
		{"word":"intransigeant","word_nosc":"intransigeant","lemma":"intransigeant","pos":"ADJ"} ,
		{"word":"intransigeante","word_nosc":"intransigeante","lemma":"intransigeant","pos":"ADJ"} ,
		{"word":"intransigeantes","word_nosc":"intransigeantes","lemma":"intransigeant","pos":"ADJ"} ,
		{"word":"intransigeants","word_nosc":"intransigeants","lemma":"intransigeant","pos":"ADJ"} ,
		{"word":"intransmissible","word_nosc":"intransmissible","lemma":"intransmissible","pos":"ADJ"} ,
		{"word":"intransportable","word_nosc":"intransportable","lemma":"intransportable","pos":"ADJ"} ,
		{"word":"intransportables","word_nosc":"intransportables","lemma":"intransportable","pos":"ADJ"} ,
		{"word":"intraoculaire","word_nosc":"intraoculaire","lemma":"intraoculaire","pos":"ADJ"} ,
		{"word":"intravasculaire","word_nosc":"intravasculaire","lemma":"intravasculaire","pos":"ADJ"} ,
		{"word":"intraveineuse","word_nosc":"intraveineuse","lemma":"intraveineux","pos":"ADJ"} ,
		{"word":"intraveineuses","word_nosc":"intraveineuses","lemma":"intraveineux","pos":"ADJ"} ,
		{"word":"intraveineux","word_nosc":"intraveineux","lemma":"intraveineux","pos":"ADJ"} ,
		{"word":"intrigant","word_nosc":"intrigant","lemma":"intrigant","pos":"ADJ"} ,
		{"word":"intrigante","word_nosc":"intrigante","lemma":"intrigant","pos":"ADJ"} ,
		{"word":"intrigantes","word_nosc":"intrigantes","lemma":"intrigant","pos":"ADJ"} ,
		{"word":"intrigants","word_nosc":"intrigants","lemma":"intrigant","pos":"ADJ"} ,
		{"word":"intrinsèque","word_nosc":"intrinseque","lemma":"intrinsèque","pos":"ADJ"} ,
		{"word":"introducteur","word_nosc":"introducteur","lemma":"introducteur","pos":"ADJ"} ,
		{"word":"introductif","word_nosc":"introductif","lemma":"introductif","pos":"ADJ"} ,
		{"word":"introspectif","word_nosc":"introspectif","lemma":"introspectif","pos":"ADJ"} ,
		{"word":"introspective","word_nosc":"introspective","lemma":"introspectif","pos":"ADJ"} ,
		{"word":"introspectives","word_nosc":"introspectives","lemma":"introspectif","pos":"ADJ"} ,
		{"word":"introuvable","word_nosc":"introuvable","lemma":"introuvable","pos":"ADJ"} ,
		{"word":"introuvables","word_nosc":"introuvables","lemma":"introuvable","pos":"ADJ"} ,
		{"word":"introverti","word_nosc":"introverti","lemma":"introverti","pos":"ADJ"} ,
		{"word":"introvertie","word_nosc":"introvertie","lemma":"introverti","pos":"ADJ"} ,
		{"word":"introvertis","word_nosc":"introvertis","lemma":"introverti","pos":"ADJ"} ,
		{"word":"intrus","word_nosc":"intrus","lemma":"intrus","pos":"ADJ"} ,
		{"word":"intrusif","word_nosc":"intrusif","lemma":"intrusif","pos":"ADJ"} ,
		{"word":"intrépide","word_nosc":"intrepide","lemma":"intrépide","pos":"ADJ"} ,
		{"word":"intrépides","word_nosc":"intrepides","lemma":"intrépide","pos":"ADJ"} ,
		{"word":"intuitif","word_nosc":"intuitif","lemma":"intuitif","pos":"ADJ"} ,
		{"word":"intuitifs","word_nosc":"intuitifs","lemma":"intuitif","pos":"ADJ"} ,
		{"word":"intuitive","word_nosc":"intuitive","lemma":"intuitif","pos":"ADJ"} ,
		{"word":"intuitives","word_nosc":"intuitives","lemma":"intuitif","pos":"ADJ"} ,
		{"word":"intègre","word_nosc":"integre","lemma":"intègre","pos":"ADJ"} ,
		{"word":"intègres","word_nosc":"integres","lemma":"intègre","pos":"ADJ"} ,
		{"word":"intégral","word_nosc":"integral","lemma":"intégral","pos":"ADJ"} ,
		{"word":"intégrale","word_nosc":"integrale","lemma":"intégral","pos":"ADJ"} ,
		{"word":"intégrales","word_nosc":"integrales","lemma":"intégral","pos":"ADJ"} ,
		{"word":"intégrante","word_nosc":"integrante","lemma":"intégrant","pos":"ADJ"} ,
		{"word":"intégrateur","word_nosc":"integrateur","lemma":"intégrateur","pos":"ADJ"} ,
		{"word":"intégraux","word_nosc":"integraux","lemma":"intégral","pos":"ADJ"} ,
		{"word":"intégriste","word_nosc":"integriste","lemma":"intégriste","pos":"ADJ"} ,
		{"word":"intégristes","word_nosc":"integristes","lemma":"intégriste","pos":"ADJ"} ,
		{"word":"intégré","word_nosc":"integre","lemma":"intégré","pos":"ADJ"} ,
		{"word":"intégrée","word_nosc":"integree","lemma":"intégré","pos":"ADJ"} ,
		{"word":"intégrées","word_nosc":"integrees","lemma":"intégré","pos":"ADJ"} ,
		{"word":"intégrés","word_nosc":"integres","lemma":"intégré","pos":"ADJ"} ,
		{"word":"intéressant","word_nosc":"interessant","lemma":"intéressant","pos":"ADJ"} ,
		{"word":"intéressante","word_nosc":"interessante","lemma":"intéressant","pos":"ADJ"} ,
		{"word":"intéressantes","word_nosc":"interessantes","lemma":"intéressant","pos":"ADJ"} ,
		{"word":"intéressants","word_nosc":"interessants","lemma":"intéressant","pos":"ADJ"} ,
		{"word":"intéressé","word_nosc":"interesse","lemma":"intéressé","pos":"ADJ"} ,
		{"word":"intéressée","word_nosc":"interessee","lemma":"intéressé","pos":"ADJ"} ,
		{"word":"intéressées","word_nosc":"interessees","lemma":"intéressé","pos":"ADJ"} ,
		{"word":"intéressés","word_nosc":"interesses","lemma":"intéressé","pos":"ADJ"} ,
		{"word":"intérieur","word_nosc":"interieur","lemma":"intérieur","pos":"ADJ"} ,
		{"word":"intérieure","word_nosc":"interieure","lemma":"intérieur","pos":"ADJ"} ,
		{"word":"intérieures","word_nosc":"interieures","lemma":"intérieur","pos":"ADJ"} ,
		{"word":"intérieurs","word_nosc":"interieurs","lemma":"intérieur","pos":"ADJ"} ,
		{"word":"intérimaire","word_nosc":"interimaire","lemma":"intérimaire","pos":"ADJ"} ,
		{"word":"intérimaires","word_nosc":"interimaires","lemma":"intérimaire","pos":"ADJ"} ,
		{"word":"inusable","word_nosc":"inusable","lemma":"inusable","pos":"ADJ"} ,
		{"word":"inusables","word_nosc":"inusables","lemma":"inusable","pos":"ADJ"} ,
		{"word":"inusité","word_nosc":"inusite","lemma":"inusité","pos":"ADJ"} ,
		{"word":"inusitée","word_nosc":"inusitee","lemma":"inusité","pos":"ADJ"} ,
		{"word":"inusitées","word_nosc":"inusitees","lemma":"inusité","pos":"ADJ"} ,
		{"word":"inusités","word_nosc":"inusites","lemma":"inusité","pos":"ADJ"} ,
		{"word":"inusuelle","word_nosc":"inusuelle","lemma":"inusuel","pos":"ADJ"} ,
		{"word":"inutile","word_nosc":"inutile","lemma":"inutile","pos":"ADJ"} ,
		{"word":"inutiles","word_nosc":"inutiles","lemma":"inutile","pos":"ADJ"} ,
		{"word":"inutilisable","word_nosc":"inutilisable","lemma":"inutilisable","pos":"ADJ"} ,
		{"word":"inutilisables","word_nosc":"inutilisables","lemma":"inutilisable","pos":"ADJ"} ,
		{"word":"inutilisé","word_nosc":"inutilise","lemma":"inutilisé","pos":"ADJ"} ,
		{"word":"inutilisée","word_nosc":"inutilisee","lemma":"inutilisé","pos":"ADJ"} ,
		{"word":"inutilisées","word_nosc":"inutilisees","lemma":"inutilisé","pos":"ADJ"} ,
		{"word":"inutilisés","word_nosc":"inutilises","lemma":"inutilisé","pos":"ADJ"} ,
		{"word":"invaincu","word_nosc":"invaincu","lemma":"invaincu","pos":"ADJ"} ,
		{"word":"invaincue","word_nosc":"invaincue","lemma":"invaincu","pos":"ADJ"} ,
		{"word":"invaincues","word_nosc":"invaincues","lemma":"invaincu","pos":"ADJ"} ,
		{"word":"invaincus","word_nosc":"invaincus","lemma":"invaincu","pos":"ADJ"} ,
		{"word":"invalidant","word_nosc":"invalidant","lemma":"invalidant","pos":"ADJ"} ,
		{"word":"invalidante","word_nosc":"invalidante","lemma":"invalidant","pos":"ADJ"} ,
		{"word":"invalide","word_nosc":"invalide","lemma":"invalide","pos":"ADJ"} ,
		{"word":"invalides","word_nosc":"invalides","lemma":"invalide","pos":"ADJ"} ,
		{"word":"invariable","word_nosc":"invariable","lemma":"invariable","pos":"ADJ"} ,
		{"word":"invariables","word_nosc":"invariables","lemma":"invariable","pos":"ADJ"} ,
		{"word":"invasif","word_nosc":"invasif","lemma":"invasif","pos":"ADJ"} ,
		{"word":"invasive","word_nosc":"invasive","lemma":"invasif","pos":"ADJ"} ,
		{"word":"invasives","word_nosc":"invasives","lemma":"invasif","pos":"ADJ"} ,
		{"word":"invendable","word_nosc":"invendable","lemma":"invendable","pos":"ADJ"} ,
		{"word":"invendables","word_nosc":"invendables","lemma":"invendable","pos":"ADJ"} ,
		{"word":"invendu","word_nosc":"invendu","lemma":"invendu","pos":"ADJ"} ,
		{"word":"invendues","word_nosc":"invendues","lemma":"invendu","pos":"ADJ"} ,
		{"word":"invendus","word_nosc":"invendus","lemma":"invendu","pos":"ADJ"} ,
		{"word":"inventeur","word_nosc":"inventeur","lemma":"inventeur","pos":"ADJ"} ,
		{"word":"inventeurs","word_nosc":"inventeurs","lemma":"inventeur","pos":"ADJ"} ,
		{"word":"inventif","word_nosc":"inventif","lemma":"inventif","pos":"ADJ"} ,
		{"word":"inventifs","word_nosc":"inventifs","lemma":"inventif","pos":"ADJ"} ,
		{"word":"inventive","word_nosc":"inventive","lemma":"inventif","pos":"ADJ"} ,
		{"word":"inverse","word_nosc":"inverse","lemma":"inverse","pos":"ADJ"} ,
		{"word":"inverses","word_nosc":"inverses","lemma":"inverse","pos":"ADJ"} ,
		{"word":"inversible","word_nosc":"inversible","lemma":"inversible","pos":"ADJ"} ,
		{"word":"inverti","word_nosc":"inverti","lemma":"inverti","pos":"ADJ"} ,
		{"word":"invertébré","word_nosc":"invertebre","lemma":"invertébré","pos":"ADJ"} ,
		{"word":"invertébrée","word_nosc":"invertebree","lemma":"invertébré","pos":"ADJ"} ,
		{"word":"invertébrés","word_nosc":"invertebres","lemma":"invertébré","pos":"ADJ"} ,
		{"word":"investi","word_nosc":"investi","lemma":"investi","pos":"ADJ"} ,
		{"word":"investie","word_nosc":"investie","lemma":"investi","pos":"ADJ"} ,
		{"word":"investies","word_nosc":"investies","lemma":"investi","pos":"ADJ"} ,
		{"word":"investigateur","word_nosc":"investigateur","lemma":"investigateur","pos":"ADJ"} ,
		{"word":"investigateurs","word_nosc":"investigateurs","lemma":"investigateur","pos":"ADJ"} ,
		{"word":"investigatrice","word_nosc":"investigatrice","lemma":"investigateur","pos":"ADJ"} ,
		{"word":"investigatrices","word_nosc":"investigatrices","lemma":"investigateur","pos":"ADJ"} ,
		{"word":"investis","word_nosc":"investis","lemma":"investi","pos":"ADJ"} ,
		{"word":"investisseur","word_nosc":"investisseur","lemma":"investisseur","pos":"ADJ"} ,
		{"word":"investisseurs","word_nosc":"investisseurs","lemma":"investisseur","pos":"ADJ"} ,
		{"word":"invincible","word_nosc":"invincible","lemma":"invincible","pos":"ADJ"} ,
		{"word":"invincibles","word_nosc":"invincibles","lemma":"invincible","pos":"ADJ"} ,
		{"word":"inviolable","word_nosc":"inviolable","lemma":"inviolable","pos":"ADJ"} ,
		{"word":"inviolables","word_nosc":"inviolables","lemma":"inviolable","pos":"ADJ"} ,
		{"word":"inviolé","word_nosc":"inviole","lemma":"inviolé","pos":"ADJ"} ,
		{"word":"inviolée","word_nosc":"inviolee","lemma":"inviolé","pos":"ADJ"} ,
		{"word":"inviolées","word_nosc":"inviolees","lemma":"inviolé","pos":"ADJ"} ,
		{"word":"inviolés","word_nosc":"invioles","lemma":"inviolé","pos":"ADJ"} ,
		{"word":"invisible","word_nosc":"invisible","lemma":"invisible","pos":"ADJ"} ,
		{"word":"invisibles","word_nosc":"invisibles","lemma":"invisible","pos":"ADJ"} ,
		{"word":"invitant","word_nosc":"invitant","lemma":"invitant","pos":"ADJ"} ,
		{"word":"invitante","word_nosc":"invitante","lemma":"invitant","pos":"ADJ"} ,
		{"word":"invitantes","word_nosc":"invitantes","lemma":"invitant","pos":"ADJ"} ,
		{"word":"invitants","word_nosc":"invitants","lemma":"invitant","pos":"ADJ"} ,
		{"word":"invité","word_nosc":"invite","lemma":"invité","pos":"ADJ"} ,
		{"word":"invitée","word_nosc":"invitee","lemma":"invité","pos":"ADJ"} ,
		{"word":"invitées","word_nosc":"invitees","lemma":"invité","pos":"ADJ"} ,
		{"word":"invités","word_nosc":"invites","lemma":"invité","pos":"ADJ"} ,
		{"word":"invivable","word_nosc":"invivable","lemma":"invivable","pos":"ADJ"} ,
		{"word":"invocatoire","word_nosc":"invocatoire","lemma":"invocatoire","pos":"ADJ"} ,
		{"word":"involontaire","word_nosc":"involontaire","lemma":"involontaire","pos":"ADJ"} ,
		{"word":"involontaires","word_nosc":"involontaires","lemma":"involontaire","pos":"ADJ"} ,
		{"word":"involutif","word_nosc":"involutif","lemma":"involutif","pos":"ADJ"} ,
		{"word":"involutifs","word_nosc":"involutifs","lemma":"involutif","pos":"ADJ"} ,
		{"word":"invraisemblable","word_nosc":"invraisemblable","lemma":"invraisemblable","pos":"ADJ"} ,
		{"word":"invraisemblables","word_nosc":"invraisemblables","lemma":"invraisemblable","pos":"ADJ"} ,
		{"word":"invulnérable","word_nosc":"invulnerable","lemma":"invulnérable","pos":"ADJ"} ,
		{"word":"invulnérables","word_nosc":"invulnerables","lemma":"invulnérable","pos":"ADJ"} ,
		{"word":"invérifiable","word_nosc":"inverifiable","lemma":"invérifiable","pos":"ADJ"} ,
		{"word":"invérifiables","word_nosc":"inverifiables","lemma":"invérifiable","pos":"ADJ"} ,
		{"word":"invétéré","word_nosc":"invetere","lemma":"invétéré","pos":"ADJ"} ,
		{"word":"invétérée","word_nosc":"inveteree","lemma":"invétéré","pos":"ADJ"} ,
		{"word":"invétérées","word_nosc":"inveterees","lemma":"invétéré","pos":"ADJ"} ,
		{"word":"invétérés","word_nosc":"inveteres","lemma":"invétéré","pos":"ADJ"} ,
		{"word":"inébranlable","word_nosc":"inebranlable","lemma":"inébranlable","pos":"ADJ"} ,
		{"word":"inébranlables","word_nosc":"inebranlables","lemma":"inébranlable","pos":"ADJ"} ,
		{"word":"inébranlé","word_nosc":"inebranle","lemma":"inébranlé","pos":"ADJ"} ,
		{"word":"inéchangeable","word_nosc":"inechangeable","lemma":"inéchangeable","pos":"ADJ"} ,
		{"word":"inécoutée","word_nosc":"inecoutee","lemma":"inécouté","pos":"ADJ"} ,
		{"word":"inédit","word_nosc":"inedit","lemma":"inédit","pos":"ADJ"} ,
		{"word":"inédite","word_nosc":"inedite","lemma":"inédit","pos":"ADJ"} ,
		{"word":"inédites","word_nosc":"inedites","lemma":"inédit","pos":"ADJ"} ,
		{"word":"inédits","word_nosc":"inedits","lemma":"inédit","pos":"ADJ"} ,
		{"word":"inéducable","word_nosc":"ineducable","lemma":"inéducable","pos":"ADJ"} ,
		{"word":"inégal","word_nosc":"inegal","lemma":"inégal","pos":"ADJ"} ,
		{"word":"inégalable","word_nosc":"inegalable","lemma":"inégalable","pos":"ADJ"} ,
		{"word":"inégalables","word_nosc":"inegalables","lemma":"inégalable","pos":"ADJ"} ,
		{"word":"inégale","word_nosc":"inegale","lemma":"inégal","pos":"ADJ"} ,
		{"word":"inégales","word_nosc":"inegales","lemma":"inégal","pos":"ADJ"} ,
		{"word":"inégalé","word_nosc":"inegale","lemma":"inégalé","pos":"ADJ"} ,
		{"word":"inégalée","word_nosc":"inegalee","lemma":"inégalé","pos":"ADJ"} ,
		{"word":"inégalées","word_nosc":"inegalees","lemma":"inégalé","pos":"ADJ"} ,
		{"word":"inégaux","word_nosc":"inegaux","lemma":"inégal","pos":"ADJ"} ,
		{"word":"inéligible","word_nosc":"ineligible","lemma":"inéligible","pos":"ADJ"} ,
		{"word":"inéluctable","word_nosc":"ineluctable","lemma":"inéluctable","pos":"ADJ"} ,
		{"word":"inéluctables","word_nosc":"ineluctables","lemma":"inéluctable","pos":"ADJ"} ,
		{"word":"inélégant","word_nosc":"inelegant","lemma":"inélégant","pos":"ADJ"} ,
		{"word":"inélégantes","word_nosc":"inelegantes","lemma":"inélégant","pos":"ADJ"} ,
		{"word":"inénarrable","word_nosc":"inenarrable","lemma":"inénarrable","pos":"ADJ"} ,
		{"word":"inénarrables","word_nosc":"inenarrables","lemma":"inénarrable","pos":"ADJ"} ,
		{"word":"inépuisable","word_nosc":"inepuisable","lemma":"inépuisable","pos":"ADJ"} ,
		{"word":"inépuisables","word_nosc":"inepuisables","lemma":"inépuisable","pos":"ADJ"} ,
		{"word":"inépuisées","word_nosc":"inepuisees","lemma":"inépuisé","pos":"ADJ"} ,
		{"word":"inéquitable","word_nosc":"inequitable","lemma":"inéquitable","pos":"ADJ"} ,
		{"word":"inévaluable","word_nosc":"inevaluable","lemma":"inévaluable","pos":"ADJ"} ,
		{"word":"inévitable","word_nosc":"inevitable","lemma":"inévitable","pos":"ADJ"} ,
		{"word":"inévitables","word_nosc":"inevitables","lemma":"inévitable","pos":"ADJ"} ,
		{"word":"iodé","word_nosc":"iode","lemma":"iodé","pos":"ADJ"} ,
		{"word":"iodée","word_nosc":"iodee","lemma":"iodé","pos":"ADJ"} ,
		{"word":"ionien","word_nosc":"ionien","lemma":"ionien","pos":"ADJ"} ,
		{"word":"ionienne","word_nosc":"ionienne","lemma":"ionienne","pos":"ADJ"} ,
		{"word":"ioniennes","word_nosc":"ioniennes","lemma":"ionien","pos":"ADJ"} ,
		{"word":"ioniens","word_nosc":"ioniens","lemma":"ionien","pos":"ADJ"} ,
		{"word":"ionique","word_nosc":"ionique","lemma":"ionique","pos":"ADJ"} ,
		{"word":"ioniques","word_nosc":"ioniques","lemma":"ionique","pos":"ADJ"} ,
		{"word":"ionisant","word_nosc":"ionisant","lemma":"ionisant","pos":"ADJ"} ,
		{"word":"ionisante","word_nosc":"ionisante","lemma":"ionisant","pos":"ADJ"} ,
		{"word":"ionisé","word_nosc":"ionise","lemma":"ionisé","pos":"ADJ"} ,
		{"word":"ionisée","word_nosc":"ionisee","lemma":"ionisé","pos":"ADJ"} ,
		{"word":"irakien","word_nosc":"irakien","lemma":"irakien","pos":"ADJ"} ,
		{"word":"irakienne","word_nosc":"irakienne","lemma":"irakien","pos":"ADJ"} ,
		{"word":"irakiens","word_nosc":"irakiens","lemma":"irakien","pos":"ADJ"} ,
		{"word":"iranien","word_nosc":"iranien","lemma":"iranien","pos":"ADJ"} ,
		{"word":"iranienne","word_nosc":"iranienne","lemma":"iranien","pos":"ADJ"} ,
		{"word":"iraniennes","word_nosc":"iraniennes","lemma":"iranien","pos":"ADJ"} ,
		{"word":"iraniens","word_nosc":"iraniens","lemma":"iranien","pos":"ADJ"} ,
		{"word":"iraquien","word_nosc":"iraquien","lemma":"iraquien","pos":"ADJ"} ,
		{"word":"iraquienne","word_nosc":"iraquienne","lemma":"iraquien","pos":"ADJ"} ,
		{"word":"iraquiens","word_nosc":"iraquiens","lemma":"iraquien","pos":"ADJ"} ,
		{"word":"irascible","word_nosc":"irascible","lemma":"irascible","pos":"ADJ"} ,
		{"word":"irascibles","word_nosc":"irascibles","lemma":"irascible","pos":"ADJ"} ,
		{"word":"iridescent","word_nosc":"iridescent","lemma":"iridescent","pos":"ADJ"} ,
		{"word":"iridescentes","word_nosc":"iridescentes","lemma":"iridescent","pos":"ADJ"} ,
		{"word":"irisé","word_nosc":"irise","lemma":"irisé","pos":"ADJ"} ,
		{"word":"irisée","word_nosc":"irisee","lemma":"irisé","pos":"ADJ"} ,
		{"word":"irisées","word_nosc":"irisees","lemma":"irisé","pos":"ADJ"} ,
		{"word":"irisés","word_nosc":"irises","lemma":"irisé","pos":"ADJ"} ,
		{"word":"irlandais","word_nosc":"irlandais","lemma":"irlandais","pos":"ADJ"} ,
		{"word":"irlandaise","word_nosc":"irlandaise","lemma":"irlandais","pos":"ADJ"} ,
		{"word":"irlandaises","word_nosc":"irlandaises","lemma":"irlandais","pos":"ADJ"} ,
		{"word":"ironique","word_nosc":"ironique","lemma":"ironique","pos":"ADJ"} ,
		{"word":"ironiques","word_nosc":"ironiques","lemma":"ironique","pos":"ADJ"} ,
		{"word":"iroquois","word_nosc":"iroquois","lemma":"iroquois","pos":"ADJ"} ,
		{"word":"iroquoise","word_nosc":"iroquoise","lemma":"iroquois","pos":"ADJ"} ,
		{"word":"irradiant","word_nosc":"irradiant","lemma":"irradiant","pos":"ADJ"} ,
		{"word":"irradiante","word_nosc":"irradiante","lemma":"irradiant","pos":"ADJ"} ,
		{"word":"irradiantes","word_nosc":"irradiantes","lemma":"irradiant","pos":"ADJ"} ,
		{"word":"irraisonnable","word_nosc":"irraisonnable","lemma":"irraisonnable","pos":"ADJ"} ,
		{"word":"irraisonné","word_nosc":"irraisonne","lemma":"irraisonné","pos":"ADJ"} ,
		{"word":"irraisonnée","word_nosc":"irraisonnee","lemma":"irraisonné","pos":"ADJ"} ,
		{"word":"irraisonnés","word_nosc":"irraisonnes","lemma":"irraisonné","pos":"ADJ"} ,
		{"word":"irrationnel","word_nosc":"irrationnel","lemma":"irrationnel","pos":"ADJ"} ,
		{"word":"irrationnelle","word_nosc":"irrationnelle","lemma":"irrationnel","pos":"ADJ"} ,
		{"word":"irrationnelles","word_nosc":"irrationnelles","lemma":"irrationnel","pos":"ADJ"} ,
		{"word":"irrationnels","word_nosc":"irrationnels","lemma":"irrationnel","pos":"ADJ"} ,
		{"word":"irrattrapable","word_nosc":"irrattrapable","lemma":"irrattrapable","pos":"ADJ"} ,
		{"word":"irrattrapables","word_nosc":"irrattrapables","lemma":"irrattrapable","pos":"ADJ"} ,
		{"word":"irrecevable","word_nosc":"irrecevable","lemma":"irrecevable","pos":"ADJ"} ,
		{"word":"irrecevables","word_nosc":"irrecevables","lemma":"irrecevable","pos":"ADJ"} ,
		{"word":"irremplaçable","word_nosc":"irremplacable","lemma":"irremplaçable","pos":"ADJ"} ,
		{"word":"irremplaçables","word_nosc":"irremplacables","lemma":"irremplaçable","pos":"ADJ"} ,
		{"word":"irreprésentable","word_nosc":"irrepresentable","lemma":"irreprésentable","pos":"ADJ"} ,
		{"word":"irrespectueuse","word_nosc":"irrespectueuse","lemma":"irrespectueux","pos":"ADJ"} ,
		{"word":"irrespectueuses","word_nosc":"irrespectueuses","lemma":"irrespectueux","pos":"ADJ"} ,
		{"word":"irrespectueux","word_nosc":"irrespectueux","lemma":"irrespectueux","pos":"ADJ"} ,
		{"word":"irrespirable","word_nosc":"irrespirable","lemma":"irrespirable","pos":"ADJ"} ,
		{"word":"irrespirables","word_nosc":"irrespirables","lemma":"irrespirable","pos":"ADJ"} ,
		{"word":"irresponsable","word_nosc":"irresponsable","lemma":"irresponsable","pos":"ADJ"} ,
		{"word":"irresponsables","word_nosc":"irresponsables","lemma":"irresponsable","pos":"ADJ"} ,
		{"word":"irrigateur","word_nosc":"irrigateur","lemma":"irrigateur","pos":"ADJ"} ,
		{"word":"irritable","word_nosc":"irritable","lemma":"irritable","pos":"ADJ"} ,
		{"word":"irritables","word_nosc":"irritables","lemma":"irritable","pos":"ADJ"} ,
		{"word":"irritant","word_nosc":"irritant","lemma":"irritant","pos":"ADJ"} ,
		{"word":"irritante","word_nosc":"irritante","lemma":"irritant","pos":"ADJ"} ,
		{"word":"irritantes","word_nosc":"irritantes","lemma":"irritant","pos":"ADJ"} ,
		{"word":"irritants","word_nosc":"irritants","lemma":"irritant","pos":"ADJ"} ,
		{"word":"irréalisable","word_nosc":"irrealisable","lemma":"irréalisable","pos":"ADJ"} ,
		{"word":"irréalisables","word_nosc":"irrealisables","lemma":"irréalisable","pos":"ADJ"} ,
		{"word":"irréaliste","word_nosc":"irrealiste","lemma":"irréaliste","pos":"ADJ"} ,
		{"word":"irréalistes","word_nosc":"irrealistes","lemma":"irréaliste","pos":"ADJ"} ,
		{"word":"irréalisé","word_nosc":"irrealise","lemma":"irréalisé","pos":"ADJ"} ,
		{"word":"irréalisés","word_nosc":"irrealises","lemma":"irréalisé","pos":"ADJ"} ,
		{"word":"irréconciliable","word_nosc":"irreconciliable","lemma":"irréconciliable","pos":"ADJ"} ,
		{"word":"irréconciliables","word_nosc":"irreconciliables","lemma":"irréconciliable","pos":"ADJ"} ,
		{"word":"irrécupérable","word_nosc":"irrecuperable","lemma":"irrécupérable","pos":"ADJ"} ,
		{"word":"irrécupérables","word_nosc":"irrecuperables","lemma":"irrécupérable","pos":"ADJ"} ,
		{"word":"irrécusable","word_nosc":"irrecusable","lemma":"irrécusable","pos":"ADJ"} ,
		{"word":"irrécusables","word_nosc":"irrecusables","lemma":"irrécusable","pos":"ADJ"} ,
		{"word":"irréductible","word_nosc":"irreductible","lemma":"irréductible","pos":"ADJ"} ,
		{"word":"irréductibles","word_nosc":"irreductibles","lemma":"irréductible","pos":"ADJ"} ,
		{"word":"irréel","word_nosc":"irreel","lemma":"irréel","pos":"ADJ"} ,
		{"word":"irréelle","word_nosc":"irreelle","lemma":"irréel","pos":"ADJ"} ,
		{"word":"irréelles","word_nosc":"irreelles","lemma":"irréel","pos":"ADJ"} ,
		{"word":"irréels","word_nosc":"irreels","lemma":"irréel","pos":"ADJ"} ,
		{"word":"irréfléchi","word_nosc":"irreflechi","lemma":"irréfléchi","pos":"ADJ"} ,
		{"word":"irréfléchie","word_nosc":"irreflechie","lemma":"irréfléchi","pos":"ADJ"} ,
		{"word":"irréfléchis","word_nosc":"irreflechis","lemma":"irréfléchi","pos":"ADJ"} ,
		{"word":"irréfragable","word_nosc":"irrefragable","lemma":"irréfragable","pos":"ADJ"} ,
		{"word":"irréfutable","word_nosc":"irrefutable","lemma":"irréfutable","pos":"ADJ"} ,
		{"word":"irréfutables","word_nosc":"irrefutables","lemma":"irréfutable","pos":"ADJ"} ,
		{"word":"irrégulier","word_nosc":"irregulier","lemma":"irrégulier","pos":"ADJ"} ,
		{"word":"irréguliers","word_nosc":"irreguliers","lemma":"irrégulier","pos":"ADJ"} ,
		{"word":"irrégulière","word_nosc":"irreguliere","lemma":"irrégulier","pos":"ADJ"} ,
		{"word":"irrégulières","word_nosc":"irregulieres","lemma":"irrégulier","pos":"ADJ"} ,
		{"word":"irréligieuse","word_nosc":"irreligieuse","lemma":"irréligieux","pos":"ADJ"} ,
		{"word":"irréligieux","word_nosc":"irreligieux","lemma":"irréligieux","pos":"ADJ"} ,
		{"word":"irrémissible","word_nosc":"irremissible","lemma":"irrémissible","pos":"ADJ"} ,
		{"word":"irrémédiable","word_nosc":"irremediable","lemma":"irrémédiable","pos":"ADJ"} ,
		{"word":"irrémédiables","word_nosc":"irremediables","lemma":"irrémédiable","pos":"ADJ"} ,
		{"word":"irréparable","word_nosc":"irreparable","lemma":"irréparable","pos":"ADJ"} ,
		{"word":"irréparables","word_nosc":"irreparables","lemma":"irréparable","pos":"ADJ"} ,
		{"word":"irrépressible","word_nosc":"irrepressible","lemma":"irrépressible","pos":"ADJ"} ,
		{"word":"irrépressibles","word_nosc":"irrepressibles","lemma":"irrépressible","pos":"ADJ"} ,
		{"word":"irréprochable","word_nosc":"irreprochable","lemma":"irréprochable","pos":"ADJ"} ,
		{"word":"irréprochables","word_nosc":"irreprochables","lemma":"irréprochable","pos":"ADJ"} ,
		{"word":"irrésistible","word_nosc":"irresistible","lemma":"irrésistible","pos":"ADJ"} ,
		{"word":"irrésistibles","word_nosc":"irresistibles","lemma":"irrésistible","pos":"ADJ"} ,
		{"word":"irrésolu","word_nosc":"irresolu","lemma":"irrésolu","pos":"ADJ"} ,
		{"word":"irrésolue","word_nosc":"irresolue","lemma":"irrésolu","pos":"ADJ"} ,
		{"word":"irrésolues","word_nosc":"irresolues","lemma":"irrésolu","pos":"ADJ"} ,
		{"word":"irrésolus","word_nosc":"irresolus","lemma":"irrésolu","pos":"ADJ"} ,
		{"word":"irrétrécissable","word_nosc":"irretrecissable","lemma":"irrétrécissable","pos":"ADJ"} ,
		{"word":"irréversible","word_nosc":"irreversible","lemma":"irréversible","pos":"ADJ"} ,
		{"word":"irréversibles","word_nosc":"irreversibles","lemma":"irréversible","pos":"ADJ"} ,
		{"word":"irrévocable","word_nosc":"irrevocable","lemma":"irrévocable","pos":"ADJ"} ,
		{"word":"irrévocables","word_nosc":"irrevocables","lemma":"irrévocable","pos":"ADJ"} ,
		{"word":"irrévérencieuse","word_nosc":"irreverencieuse","lemma":"irrévérencieux","pos":"ADJ"} ,
		{"word":"irrévérencieuses","word_nosc":"irreverencieuses","lemma":"irrévérencieux","pos":"ADJ"} ,
		{"word":"irrévérencieux","word_nosc":"irreverencieux","lemma":"irrévérencieux","pos":"ADJ"} ,
		{"word":"isabelle","word_nosc":"isabelle","lemma":"isabelle","pos":"ADJ"} ,
		{"word":"ischémique","word_nosc":"ischemique","lemma":"ischémique","pos":"ADJ"} ,
		{"word":"islamique","word_nosc":"islamique","lemma":"islamique","pos":"ADJ"} ,
		{"word":"islamiques","word_nosc":"islamiques","lemma":"islamique","pos":"ADJ"} ,
		{"word":"islamisants","word_nosc":"islamisants","lemma":"islamisant","pos":"ADJ"} ,
		{"word":"islamiste","word_nosc":"islamiste","lemma":"islamiste","pos":"ADJ"} ,
		{"word":"islamistes","word_nosc":"islamistes","lemma":"islamiste","pos":"ADJ"} ,
		{"word":"islamisée","word_nosc":"islamisee","lemma":"islamisé","pos":"ADJ"} ,
		{"word":"islandais","word_nosc":"islandais","lemma":"islandais","pos":"ADJ"} ,
		{"word":"islandaise","word_nosc":"islandaise","lemma":"islandais","pos":"ADJ"} ,
		{"word":"ismaélite","word_nosc":"ismaelite","lemma":"ismaélite","pos":"ADJ"} ,
		{"word":"isocèle","word_nosc":"isocele","lemma":"isocèle","pos":"ADJ"} ,
		{"word":"isolables","word_nosc":"isolables","lemma":"isolable","pos":"ADJ"} ,
		{"word":"isolant","word_nosc":"isolant","lemma":"isolant","pos":"ADJ"} ,
		{"word":"isolante","word_nosc":"isolante","lemma":"isolant","pos":"ADJ"} ,
		{"word":"isolateur","word_nosc":"isolateur","lemma":"isolateur","pos":"ADJ"} ,
		{"word":"isolateurs","word_nosc":"isolateurs","lemma":"isolateur","pos":"ADJ"} ,
		{"word":"isolationniste","word_nosc":"isolationniste","lemma":"isolationniste","pos":"ADJ"} ,
		{"word":"isolé","word_nosc":"isole","lemma":"isolé","pos":"ADJ"} ,
		{"word":"isolée","word_nosc":"isolee","lemma":"isolé","pos":"ADJ"} ,
		{"word":"isolées","word_nosc":"isolees","lemma":"isolé","pos":"ADJ"} ,
		{"word":"isolés","word_nosc":"isoles","lemma":"isolé","pos":"ADJ"} ,
		{"word":"isomère","word_nosc":"isomere","lemma":"isomère","pos":"ADJ"} ,
		{"word":"isométrique","word_nosc":"isometrique","lemma":"isométrique","pos":"ADJ"} ,
		{"word":"isotherme","word_nosc":"isotherme","lemma":"isotherme","pos":"ADJ"} ,
		{"word":"isothermique","word_nosc":"isothermique","lemma":"isothermique","pos":"ADJ"} ,
		{"word":"isotonique","word_nosc":"isotonique","lemma":"isotonique","pos":"ADJ"} ,
		{"word":"isotopique","word_nosc":"isotopique","lemma":"isotopique","pos":"ADJ"} ,
		{"word":"israélien","word_nosc":"israelien","lemma":"israélien","pos":"ADJ"} ,
		{"word":"israélienne","word_nosc":"israelienne","lemma":"israélien","pos":"ADJ"} ,
		{"word":"israéliennes","word_nosc":"israeliennes","lemma":"israélien","pos":"ADJ"} ,
		{"word":"israéliens","word_nosc":"israeliens","lemma":"israélien","pos":"ADJ"} ,
		{"word":"israélite","word_nosc":"israelite","lemma":"israélite","pos":"ADJ"} ,
		{"word":"israélites","word_nosc":"israelites","lemma":"israélite","pos":"ADJ"} ,
		{"word":"israélo-arabe","word_nosc":"israelo-arabe","lemma":"israélo-arabe","pos":"ADJ"} ,
		{"word":"israélo-syrienne","word_nosc":"israelo-syrienne","lemma":"israélo-syrien","pos":"ADJ"} ,
		{"word":"issu","word_nosc":"issu","lemma":"issu","pos":"ADJ"} ,
		{"word":"issue","word_nosc":"issue","lemma":"issu","pos":"ADJ"} ,
		{"word":"issues","word_nosc":"issues","lemma":"issu","pos":"ADJ"} ,
		{"word":"issus","word_nosc":"issus","lemma":"issu","pos":"ADJ"} ,
		{"word":"isthmique","word_nosc":"isthmique","lemma":"isthmique","pos":"ADJ"} ,
		{"word":"italien","word_nosc":"italien","lemma":"italien","pos":"ADJ"} ,
		{"word":"italienne","word_nosc":"italienne","lemma":"italien","pos":"ADJ"} ,
		{"word":"italiennes","word_nosc":"italiennes","lemma":"italien","pos":"ADJ"} ,
		{"word":"italiens","word_nosc":"italiens","lemma":"italien","pos":"ADJ"} ,
		{"word":"italique","word_nosc":"italique","lemma":"italique","pos":"ADJ"} ,
		{"word":"italiques","word_nosc":"italiques","lemma":"italique","pos":"ADJ"} ,
		{"word":"italo-allemande","word_nosc":"italo-allemande","lemma":"italo-allemand","pos":"ADJ"} ,
		{"word":"italo-allemandes","word_nosc":"italo-allemandes","lemma":"italo-allemand","pos":"ADJ"} ,
		{"word":"italo-américain","word_nosc":"italo-americain","lemma":"italo-américain","pos":"ADJ"} ,
		{"word":"italo-américaine","word_nosc":"italo-americaine","lemma":"italo-américain","pos":"ADJ"} ,
		{"word":"italo-brésilien","word_nosc":"italo-bresilien","lemma":"italo-brésilien","pos":"ADJ"} ,
		{"word":"italo-français","word_nosc":"italo-francais","lemma":"italo-français","pos":"ADJ"} ,
		{"word":"italo-françaises","word_nosc":"italo-francaises","lemma":"italo-français","pos":"ADJ"} ,
		{"word":"italo-irlandais","word_nosc":"italo-irlandais","lemma":"italo-irlandais","pos":"ADJ"} ,
		{"word":"italo-polonais","word_nosc":"italo-polonais","lemma":"italo-polonais","pos":"ADJ"} ,
		{"word":"italo-égyptien","word_nosc":"italo-egyptien","lemma":"italo-égyptien","pos":"ADJ"} ,
		{"word":"ithyphallique","word_nosc":"ithyphallique","lemma":"ithyphallique","pos":"ADJ"} ,
		{"word":"itinérant","word_nosc":"itinerant","lemma":"itinérant","pos":"ADJ"} ,
		{"word":"itinérante","word_nosc":"itinerante","lemma":"itinérant","pos":"ADJ"} ,
		{"word":"itinérantes","word_nosc":"itinerantes","lemma":"itinérant","pos":"ADJ"} ,
		{"word":"itinérants","word_nosc":"itinerants","lemma":"itinérant","pos":"ADJ"} ,
		{"word":"ivoire","word_nosc":"ivoire","lemma":"ivoire","pos":"ADJ"} ,
		{"word":"ivoirien","word_nosc":"ivoirien","lemma":"ivoirien","pos":"ADJ"} ,
		{"word":"ivoirin","word_nosc":"ivoirin","lemma":"ivoirin","pos":"ADJ"} ,
		{"word":"ivoirins","word_nosc":"ivoirins","lemma":"ivoirin","pos":"ADJ"} ,
		{"word":"ivre","word_nosc":"ivre","lemma":"ivre","pos":"ADJ"} ,
		{"word":"ivres","word_nosc":"ivres","lemma":"ivre","pos":"ADJ"} ,
		{"word":"ivrogne","word_nosc":"ivrogne","lemma":"ivrogne","pos":"ADJ"} ,
		{"word":"ivrognes","word_nosc":"ivrognes","lemma":"ivrogne","pos":"ADJ"} ,
		{"word":"ixième","word_nosc":"ixieme","lemma":"ixième","pos":"ADJ"} ,
		{"word":"jacassant","word_nosc":"jacassant","lemma":"jacassant","pos":"ADJ"} ,
		{"word":"jacassante","word_nosc":"jacassante","lemma":"jacassant","pos":"ADJ"} ,
		{"word":"jacassantes","word_nosc":"jacassantes","lemma":"jacassant","pos":"ADJ"} ,
		{"word":"jacassants","word_nosc":"jacassants","lemma":"jacassant","pos":"ADJ"} ,
		{"word":"jacobin","word_nosc":"jacobin","lemma":"jacobin","pos":"ADJ"} ,
		{"word":"jacobine","word_nosc":"jacobine","lemma":"jacobin","pos":"ADJ"} ,
		{"word":"jacobins","word_nosc":"jacobins","lemma":"jacobin","pos":"ADJ"} ,
		{"word":"jacobite","word_nosc":"jacobite","lemma":"jacobite","pos":"ADJ"} ,
		{"word":"jacobites","word_nosc":"jacobites","lemma":"jacobite","pos":"ADJ"} ,
		{"word":"jactancier","word_nosc":"jactancier","lemma":"jactancier","pos":"ADJ"} ,
		{"word":"jacteur","word_nosc":"jacteur","lemma":"jacteur","pos":"ADJ"} ,
		{"word":"jaculatoire","word_nosc":"jaculatoire","lemma":"jaculatoire","pos":"ADJ"} ,
		{"word":"jade","word_nosc":"jade","lemma":"jade","pos":"ADJ"} ,
		{"word":"jadis","word_nosc":"jadis","lemma":"jadis","pos":"ADJ"} ,
		{"word":"jaillissant","word_nosc":"jaillissant","lemma":"jaillissant","pos":"ADJ"} ,
		{"word":"jaillissante","word_nosc":"jaillissante","lemma":"jaillissant","pos":"ADJ"} ,
		{"word":"jaillissantes","word_nosc":"jaillissantes","lemma":"jaillissant","pos":"ADJ"} ,
		{"word":"jaillissants","word_nosc":"jaillissants","lemma":"jaillissant","pos":"ADJ"} ,
		{"word":"jalmince","word_nosc":"jalmince","lemma":"jalmince","pos":"ADJ"} ,
		{"word":"jalminces","word_nosc":"jalminces","lemma":"jalmince","pos":"ADJ"} ,
		{"word":"jalonné","word_nosc":"jalonne","lemma":"jalonné","pos":"ADJ"} ,
		{"word":"jalonnée","word_nosc":"jalonnee","lemma":"jalonné","pos":"ADJ"} ,
		{"word":"jalouse","word_nosc":"jalouse","lemma":"jaloux","pos":"ADJ"} ,
		{"word":"jalouses","word_nosc":"jalouses","lemma":"jaloux","pos":"ADJ"} ,
		{"word":"jaloux","word_nosc":"jaloux","lemma":"jaloux","pos":"ADJ"} ,
		{"word":"jamaïcain","word_nosc":"jamaicain","lemma":"jamaïcain","pos":"ADJ"} ,
		{"word":"jamaïcaine","word_nosc":"jamaicaine","lemma":"jamaïcain","pos":"ADJ"} ,
		{"word":"jamaïcains","word_nosc":"jamaicains","lemma":"jamaïcain","pos":"ADJ"} ,
		{"word":"jamaïquain","word_nosc":"jamaiquain","lemma":"jamaïquain","pos":"ADJ"} ,
		{"word":"jamaïquains","word_nosc":"jamaiquains","lemma":"jamaïquain","pos":"ADJ"} ,
		{"word":"jambières","word_nosc":"jambieres","lemma":"jambier","pos":"ADJ"} ,
		{"word":"janséniste","word_nosc":"janseniste","lemma":"janséniste","pos":"ADJ"} ,
		{"word":"jansénistes","word_nosc":"jansenistes","lemma":"janséniste","pos":"ADJ"} ,
		{"word":"japonais","word_nosc":"japonais","lemma":"japonais","pos":"ADJ"} ,
		{"word":"japonaise","word_nosc":"japonaise","lemma":"japonais","pos":"ADJ"} ,
		{"word":"japonaises","word_nosc":"japonaises","lemma":"japonais","pos":"ADJ"} ,
		{"word":"jaunasse","word_nosc":"jaunasse","lemma":"jaunasse","pos":"ADJ"} ,
		{"word":"jaunasses","word_nosc":"jaunasses","lemma":"jaunasse","pos":"ADJ"} ,
		{"word":"jaune","word_nosc":"jaune","lemma":"jaune","pos":"ADJ"} ,
		{"word":"jaune-vert","word_nosc":"jaune-vert","lemma":"jaune-vert","pos":"ADJ"} ,
		{"word":"jaunes","word_nosc":"jaunes","lemma":"jaune","pos":"ADJ"} ,
		{"word":"jauni","word_nosc":"jauni","lemma":"jauni","pos":"ADJ"} ,
		{"word":"jaunie","word_nosc":"jaunie","lemma":"jauni","pos":"ADJ"} ,
		{"word":"jaunies","word_nosc":"jaunies","lemma":"jauni","pos":"ADJ"} ,
		{"word":"jaunis","word_nosc":"jaunis","lemma":"jauni","pos":"ADJ"} ,
		{"word":"jaunissant","word_nosc":"jaunissant","lemma":"jaunissant","pos":"ADJ"} ,
		{"word":"jaunissante","word_nosc":"jaunissante","lemma":"jaunissant","pos":"ADJ"} ,
		{"word":"jaunissantes","word_nosc":"jaunissantes","lemma":"jaunissant","pos":"ADJ"} ,
		{"word":"jaunissants","word_nosc":"jaunissants","lemma":"jaunissant","pos":"ADJ"} ,
		{"word":"jaunâtre","word_nosc":"jaunatre","lemma":"jaunâtre","pos":"ADJ"} ,
		{"word":"jaunâtres","word_nosc":"jaunatres","lemma":"jaunâtre","pos":"ADJ"} ,
		{"word":"javanais","word_nosc":"javanais","lemma":"javanais","pos":"ADJ"} ,
		{"word":"javanaise","word_nosc":"javanaise","lemma":"javanais","pos":"ADJ"} ,
		{"word":"javanaises","word_nosc":"javanaises","lemma":"javanais","pos":"ADJ"} ,
		{"word":"jazzy","word_nosc":"jazzy","lemma":"jazzy","pos":"ADJ"} ,
		{"word":"je-m'en-fichiste","word_nosc":"je-m'en-fichiste","lemma":"je-m'en-fichiste","pos":"ADJ"} ,
		{"word":"je-m'en-foutiste","word_nosc":"je-m'en-foutiste","lemma":"je-m'en-foutiste","pos":"ADJ"} ,
		{"word":"jetable","word_nosc":"jetable","lemma":"jetable","pos":"ADJ"} ,
		{"word":"jetables","word_nosc":"jetables","lemma":"jetable","pos":"ADJ"} ,
		{"word":"jeté","word_nosc":"jete","lemma":"jeté","pos":"ADJ"} ,
		{"word":"jetée","word_nosc":"jetee","lemma":"jeté","pos":"ADJ"} ,
		{"word":"jetées","word_nosc":"jetees","lemma":"jeté","pos":"ADJ"} ,
		{"word":"jetés","word_nosc":"jetes","lemma":"jeté","pos":"ADJ"} ,
		{"word":"jeune","word_nosc":"jeune","lemma":"jeune","pos":"ADJ"} ,
		{"word":"jeune-homme","word_nosc":"jeune-homme","lemma":"jeune-homme","pos":"ADJ"} ,
		{"word":"jeunes","word_nosc":"jeunes","lemma":"jeune","pos":"ADJ"} ,
		{"word":"jeunet","word_nosc":"jeunet","lemma":"jeunet","pos":"ADJ"} ,
		{"word":"jeunets","word_nosc":"jeunets","lemma":"jeunet","pos":"ADJ"} ,
		{"word":"jeunette","word_nosc":"jeunette","lemma":"jeunet","pos":"ADJ"} ,
		{"word":"jeunettes","word_nosc":"jeunettes","lemma":"jeunet","pos":"ADJ"} ,
		{"word":"jeunot","word_nosc":"jeunot","lemma":"jeunot","pos":"ADJ"} ,
		{"word":"jeunots","word_nosc":"jeunots","lemma":"jeunot","pos":"ADJ"} ,
		{"word":"jivaro","word_nosc":"jivaro","lemma":"jivaro","pos":"ADJ"} ,
		{"word":"jobard","word_nosc":"jobard","lemma":"jobard","pos":"ADJ"} ,
		{"word":"jobardes","word_nosc":"jobardes","lemma":"jobard","pos":"ADJ"} ,
		{"word":"jobards","word_nosc":"jobards","lemma":"jobard","pos":"ADJ"} ,
		{"word":"jocko","word_nosc":"jocko","lemma":"jocko","pos":"ADJ"} ,
		{"word":"joice","word_nosc":"joice","lemma":"joice","pos":"ADJ"} ,
		{"word":"joices","word_nosc":"joices","lemma":"joice","pos":"ADJ"} ,
		{"word":"joignable","word_nosc":"joignable","lemma":"joignable","pos":"ADJ"} ,
		{"word":"joint","word_nosc":"joint","lemma":"joint","pos":"ADJ"} ,
		{"word":"jointe","word_nosc":"jointe","lemma":"joint","pos":"ADJ"} ,
		{"word":"jointes","word_nosc":"jointes","lemma":"joint","pos":"ADJ"} ,
		{"word":"jointif","word_nosc":"jointif","lemma":"jointif","pos":"ADJ"} ,
		{"word":"jointifs","word_nosc":"jointifs","lemma":"jointif","pos":"ADJ"} ,
		{"word":"jointives","word_nosc":"jointives","lemma":"jointif","pos":"ADJ"} ,
		{"word":"joints","word_nosc":"joints","lemma":"joint","pos":"ADJ"} ,
		{"word":"jointée","word_nosc":"jointee","lemma":"jointé","pos":"ADJ"} ,
		{"word":"jojo","word_nosc":"jojo","lemma":"jojo","pos":"ADJ"} ,
		{"word":"jojos","word_nosc":"jojos","lemma":"jojo","pos":"ADJ"} ,
		{"word":"joli","word_nosc":"joli","lemma":"joli","pos":"ADJ"} ,
		{"word":"joli-coeur","word_nosc":"joli-coeur","lemma":"joli-coeur","pos":"ADJ"} ,
		{"word":"joli-joli","word_nosc":"joli-joli","lemma":"joli-joli","pos":"ADJ"} ,
		{"word":"jolie","word_nosc":"jolie","lemma":"joli","pos":"ADJ"} ,
		{"word":"jolie","word_nosc":"jolie","lemma":"jolie","pos":"ADJ"} ,
		{"word":"jolies","word_nosc":"jolies","lemma":"joli","pos":"ADJ"} ,
		{"word":"joliet","word_nosc":"joliet","lemma":"joliet","pos":"ADJ"} ,
		{"word":"joliette","word_nosc":"joliette","lemma":"joliet","pos":"ADJ"} ,
		{"word":"jolis","word_nosc":"jolis","lemma":"joli","pos":"ADJ"} ,
		{"word":"jonché","word_nosc":"jonche","lemma":"jonché","pos":"ADJ"} ,
		{"word":"jonchée","word_nosc":"jonchee","lemma":"jonché","pos":"ADJ"} ,
		{"word":"jonchées","word_nosc":"jonchees","lemma":"jonché","pos":"ADJ"} ,
		{"word":"jonchés","word_nosc":"jonches","lemma":"jonché","pos":"ADJ"} ,
		{"word":"jonquille","word_nosc":"jonquille","lemma":"jonquille","pos":"ADJ"} ,
		{"word":"jordanienne","word_nosc":"jordanienne","lemma":"jordanien","pos":"ADJ"} ,
		{"word":"jordanienne","word_nosc":"jordanienne","lemma":"jordanienne","pos":"ADJ"} ,
		{"word":"jordaniens","word_nosc":"jordaniens","lemma":"jordanien","pos":"ADJ"} ,
		{"word":"jouable","word_nosc":"jouable","lemma":"jouable","pos":"ADJ"} ,
		{"word":"jouasse","word_nosc":"jouasse","lemma":"jouasse","pos":"ADJ"} ,
		{"word":"jouasses","word_nosc":"jouasses","lemma":"jouasse","pos":"ADJ"} ,
		{"word":"joueur","word_nosc":"joueur","lemma":"joueur","pos":"ADJ"} ,
		{"word":"joueurs","word_nosc":"joueurs","lemma":"joueur","pos":"ADJ"} ,
		{"word":"joueuse","word_nosc":"joueuse","lemma":"joueur","pos":"ADJ"} ,
		{"word":"joueuses","word_nosc":"joueuses","lemma":"joueur","pos":"ADJ"} ,
		{"word":"joufflu","word_nosc":"joufflu","lemma":"joufflu","pos":"ADJ"} ,
		{"word":"joufflue","word_nosc":"joufflue","lemma":"joufflu","pos":"ADJ"} ,
		{"word":"joufflues","word_nosc":"joufflues","lemma":"joufflu","pos":"ADJ"} ,
		{"word":"joufflus","word_nosc":"joufflus","lemma":"joufflu","pos":"ADJ"} ,
		{"word":"jouissants","word_nosc":"jouissants","lemma":"jouissant","pos":"ADJ"} ,
		{"word":"jouisseur","word_nosc":"jouisseur","lemma":"jouisseur","pos":"ADJ"} ,
		{"word":"jouisseurs","word_nosc":"jouisseurs","lemma":"jouisseur","pos":"ADJ"} ,
		{"word":"jouisseuse","word_nosc":"jouisseuse","lemma":"jouisseur","pos":"ADJ"} ,
		{"word":"jouissif","word_nosc":"jouissif","lemma":"jouissif","pos":"ADJ"} ,
		{"word":"jouissifs","word_nosc":"jouissifs","lemma":"jouissif","pos":"ADJ"} ,
		{"word":"jouissive","word_nosc":"jouissive","lemma":"jouissif","pos":"ADJ"} ,
		{"word":"jouissives","word_nosc":"jouissives","lemma":"jouissif","pos":"ADJ"} ,
		{"word":"journalier","word_nosc":"journalier","lemma":"journalier","pos":"ADJ"} ,
		{"word":"journaliers","word_nosc":"journaliers","lemma":"journalier","pos":"ADJ"} ,
		{"word":"journalistique","word_nosc":"journalistique","lemma":"journalistique","pos":"ADJ"} ,
		{"word":"journalistiques","word_nosc":"journalistiques","lemma":"journalistique","pos":"ADJ"} ,
		{"word":"journalière","word_nosc":"journaliere","lemma":"journalier","pos":"ADJ"} ,
		{"word":"journalières","word_nosc":"journalieres","lemma":"journalier","pos":"ADJ"} ,
		{"word":"joué","word_nosc":"joue","lemma":"joué","pos":"ADJ"} ,
		{"word":"jouée","word_nosc":"jouee","lemma":"joué","pos":"ADJ"} ,
		{"word":"jouées","word_nosc":"jouees","lemma":"joué","pos":"ADJ"} ,
		{"word":"joués","word_nosc":"joues","lemma":"joué","pos":"ADJ"} ,
		{"word":"jovial","word_nosc":"jovial","lemma":"jovial","pos":"ADJ"} ,
		{"word":"joviale","word_nosc":"joviale","lemma":"jovial","pos":"ADJ"} ,
		{"word":"joviales","word_nosc":"joviales","lemma":"jovial","pos":"ADJ"} ,
		{"word":"joviaux","word_nosc":"joviaux","lemma":"jovial","pos":"ADJ"} ,
		{"word":"joyciennes","word_nosc":"joyciennes","lemma":"joycien","pos":"ADJ"} ,
		{"word":"joyeuse","word_nosc":"joyeuse","lemma":"joyeux","pos":"ADJ"} ,
		{"word":"joyeuses","word_nosc":"joyeuses","lemma":"joyeux","pos":"ADJ"} ,
		{"word":"joyeux","word_nosc":"joyeux","lemma":"joyeux","pos":"ADJ"} ,
		{"word":"jubilaire","word_nosc":"jubilaire","lemma":"jubilaire","pos":"ADJ"} ,
		{"word":"jubilant","word_nosc":"jubilant","lemma":"jubilant","pos":"ADJ"} ,
		{"word":"jubilante","word_nosc":"jubilante","lemma":"jubilant","pos":"ADJ"} ,
		{"word":"jubilantes","word_nosc":"jubilantes","lemma":"jubilant","pos":"ADJ"} ,
		{"word":"jubilants","word_nosc":"jubilants","lemma":"jubilant","pos":"ADJ"} ,
		{"word":"jubilatoire","word_nosc":"jubilatoire","lemma":"jubilatoire","pos":"ADJ"} ,
		{"word":"jubilatoires","word_nosc":"jubilatoires","lemma":"jubilatoire","pos":"ADJ"} ,
		{"word":"jubilé","word_nosc":"jubile","lemma":"jubilé","pos":"ADJ"} ,
		{"word":"juchée","word_nosc":"juchee","lemma":"juchée","pos":"ADJ"} ,
		{"word":"judaïque","word_nosc":"judaique","lemma":"judaïque","pos":"ADJ"} ,
		{"word":"judaïques","word_nosc":"judaiques","lemma":"judaïque","pos":"ADJ"} ,
		{"word":"judiciaire","word_nosc":"judiciaire","lemma":"judiciaire","pos":"ADJ"} ,
		{"word":"judiciaires","word_nosc":"judiciaires","lemma":"judiciaire","pos":"ADJ"} ,
		{"word":"judicieuse","word_nosc":"judicieuse","lemma":"judicieux","pos":"ADJ"} ,
		{"word":"judicieuses","word_nosc":"judicieuses","lemma":"judicieux","pos":"ADJ"} ,
		{"word":"judicieux","word_nosc":"judicieux","lemma":"judicieux","pos":"ADJ"} ,
		{"word":"judéen","word_nosc":"judeen","lemma":"judéen","pos":"ADJ"} ,
		{"word":"judéo-chrétienne","word_nosc":"judeo-chretienne","lemma":"judéo-chrétien","pos":"ADJ"} ,
		{"word":"judéo-chrétiennes","word_nosc":"judeo-chretiennes","lemma":"judéo-chrétien","pos":"ADJ"} ,
		{"word":"judéo-chrétiens","word_nosc":"judeo-chretiens","lemma":"judéo-chrétien","pos":"ADJ"} ,
		{"word":"jugulaire","word_nosc":"jugulaire","lemma":"jugulaire","pos":"ADJ"} ,
		{"word":"jugulaires","word_nosc":"jugulaires","lemma":"jugulaire","pos":"ADJ"} ,
		{"word":"jugé","word_nosc":"juge","lemma":"jugé","pos":"ADJ"} ,
		{"word":"jugés","word_nosc":"juges","lemma":"jugé","pos":"ADJ"} ,
		{"word":"juif","word_nosc":"juif","lemma":"juif","pos":"ADJ"} ,
		{"word":"juifs","word_nosc":"juifs","lemma":"juif","pos":"ADJ"} ,
		{"word":"juive","word_nosc":"juive","lemma":"juif","pos":"ADJ"} ,
		{"word":"juives","word_nosc":"juives","lemma":"juif","pos":"ADJ"} ,
		{"word":"julien","word_nosc":"julien","lemma":"julien","pos":"ADJ"} ,
		{"word":"julienne","word_nosc":"julienne","lemma":"julienne","pos":"ADJ"} ,
		{"word":"juliennes","word_nosc":"juliennes","lemma":"julienne","pos":"ADJ"} ,
		{"word":"jumeau","word_nosc":"jumeau","lemma":"jumeau","pos":"ADJ"} ,
		{"word":"jumelle","word_nosc":"jumelle","lemma":"jumeau","pos":"ADJ"} ,
		{"word":"jumelles","word_nosc":"jumelles","lemma":"jumeau","pos":"ADJ"} ,
		{"word":"jumelée","word_nosc":"jumelee","lemma":"jumelé","pos":"ADJ"} ,
		{"word":"jumelées","word_nosc":"jumelees","lemma":"jumelé","pos":"ADJ"} ,
		{"word":"jumelés","word_nosc":"jumeles","lemma":"jumelé","pos":"ADJ"} ,
		{"word":"jungien","word_nosc":"jungien","lemma":"jungien","pos":"ADJ"} ,
		{"word":"jungiens","word_nosc":"jungiens","lemma":"jungien","pos":"ADJ"} ,
		{"word":"junior","word_nosc":"junior","lemma":"junior","pos":"ADJ"} ,
		{"word":"juniors","word_nosc":"juniors","lemma":"junior","pos":"ADJ"} ,
		{"word":"jupitérien","word_nosc":"jupiterien","lemma":"jupitérien","pos":"ADJ"} ,
		{"word":"jupitérienne","word_nosc":"jupiterienne","lemma":"jupitérien","pos":"ADJ"} ,
		{"word":"jupitériens","word_nosc":"jupiteriens","lemma":"jupitérien","pos":"ADJ"} ,
		{"word":"jupée","word_nosc":"jupee","lemma":"jupé","pos":"ADJ"} ,
		{"word":"jupées","word_nosc":"jupees","lemma":"jupé","pos":"ADJ"} ,
		{"word":"jurassien","word_nosc":"jurassien","lemma":"jurassien","pos":"ADJ"} ,
		{"word":"jurassiens","word_nosc":"jurassiens","lemma":"jurassien","pos":"ADJ"} ,
		{"word":"jurassique","word_nosc":"jurassique","lemma":"jurassique","pos":"ADJ"} ,
		{"word":"jurassiques","word_nosc":"jurassiques","lemma":"jurassique","pos":"ADJ"} ,
		{"word":"juridictionnel","word_nosc":"juridictionnel","lemma":"juridictionnel","pos":"ADJ"} ,
		{"word":"juridictionnelle","word_nosc":"juridictionnelle","lemma":"juridictionnel","pos":"ADJ"} ,
		{"word":"juridique","word_nosc":"juridique","lemma":"juridique","pos":"ADJ"} ,
		{"word":"juridiques","word_nosc":"juridiques","lemma":"juridique","pos":"ADJ"} ,
		{"word":"juré","word_nosc":"jure","lemma":"juré","pos":"ADJ"} ,
		{"word":"jurée","word_nosc":"juree","lemma":"juré","pos":"ADJ"} ,
		{"word":"jurées","word_nosc":"jurees","lemma":"juré","pos":"ADJ"} ,
		{"word":"jurés","word_nosc":"jures","lemma":"juré","pos":"ADJ"} ,
		{"word":"juste","word_nosc":"juste","lemma":"juste","pos":"ADJ"} ,
		{"word":"justes","word_nosc":"justes","lemma":"juste","pos":"ADJ"} ,
		{"word":"justiciable","word_nosc":"justiciable","lemma":"justiciable","pos":"ADJ"} ,
		{"word":"justiciables","word_nosc":"justiciables","lemma":"justiciable","pos":"ADJ"} ,
		{"word":"justifiable","word_nosc":"justifiable","lemma":"justifiable","pos":"ADJ"} ,
		{"word":"justifiables","word_nosc":"justifiables","lemma":"justifiable","pos":"ADJ"} ,
		{"word":"justifiant","word_nosc":"justifiant","lemma":"justifiant","pos":"ADJ"} ,
		{"word":"justificatif","word_nosc":"justificatif","lemma":"justificatif","pos":"ADJ"} ,
		{"word":"justificative","word_nosc":"justificative","lemma":"justificatif","pos":"ADJ"} ,
		{"word":"justificatives","word_nosc":"justificatives","lemma":"justificatif","pos":"ADJ"} ,
		{"word":"justinien","word_nosc":"justinien","lemma":"justinien","pos":"ADJ"} ,
		{"word":"juteuse","word_nosc":"juteuse","lemma":"juteux","pos":"ADJ"} ,
		{"word":"juteuses","word_nosc":"juteuses","lemma":"juteux","pos":"ADJ"} ,
		{"word":"juteux","word_nosc":"juteux","lemma":"juteux","pos":"ADJ"} ,
		{"word":"juvénile","word_nosc":"juvenile","lemma":"juvénile","pos":"ADJ"} ,
		{"word":"juvéniles","word_nosc":"juveniles","lemma":"juvénile","pos":"ADJ"} ,
		{"word":"juxtaposées","word_nosc":"juxtaposees","lemma":"juxtaposé","pos":"ADJ"} ,
		{"word":"juxtaposés","word_nosc":"juxtaposes","lemma":"juxtaposé","pos":"ADJ"} ,
		{"word":"jèze","word_nosc":"jeze","lemma":"jèze","pos":"ADJ"} ,
		{"word":"jéjunale","word_nosc":"jejunale","lemma":"jéjunal","pos":"ADJ"} ,
		{"word":"jésuitique","word_nosc":"jesuitique","lemma":"jésuitique","pos":"ADJ"} ,
		{"word":"jésuitiques","word_nosc":"jesuitiques","lemma":"jésuitique","pos":"ADJ"} ,
		{"word":"k-o","word_nosc":"k-o","lemma":"k-o","pos":"ADJ"} ,
		{"word":"kabarde","word_nosc":"kabarde","lemma":"kabarde","pos":"ADJ"} ,
		{"word":"kabbalistique","word_nosc":"kabbalistique","lemma":"kabbalistique","pos":"ADJ"} ,
		{"word":"kabyle","word_nosc":"kabyle","lemma":"kabyle","pos":"ADJ"} ,
		{"word":"kabyles","word_nosc":"kabyles","lemma":"kabyle","pos":"ADJ"} ,
		{"word":"kacher","word_nosc":"kacher","lemma":"kacher","pos":"ADJ"} ,
		{"word":"kachoube","word_nosc":"kachoube","lemma":"kachoube","pos":"ADJ"} ,
		{"word":"kafkaïen","word_nosc":"kafkaien","lemma":"kafkaïen","pos":"ADJ"} ,
		{"word":"kaftan","word_nosc":"kaftan","lemma":"kaftan","pos":"ADJ"} ,
		{"word":"kaftans","word_nosc":"kaftans","lemma":"kaftan","pos":"ADJ"} ,
		{"word":"kaki","word_nosc":"kaki","lemma":"kaki","pos":"ADJ"} ,
		{"word":"kalmouk","word_nosc":"kalmouk","lemma":"kalmouk","pos":"ADJ"} ,
		{"word":"kalmouks","word_nosc":"kalmouks","lemma":"kalmouk","pos":"ADJ"} ,
		{"word":"kamikaze","word_nosc":"kamikaze","lemma":"kamikaze","pos":"ADJ"} ,
		{"word":"kamikazes","word_nosc":"kamikazes","lemma":"kamikaze","pos":"ADJ"} ,
		{"word":"kantien","word_nosc":"kantien","lemma":"kantien","pos":"ADJ"} ,
		{"word":"karen","word_nosc":"karen","lemma":"karen","pos":"ADJ"} ,
		{"word":"karmique","word_nosc":"karmique","lemma":"karmique","pos":"ADJ"} ,
		{"word":"kascher","word_nosc":"kascher","lemma":"kascher","pos":"ADJ"} ,
		{"word":"kasher","word_nosc":"kasher","lemma":"kasher","pos":"ADJ"} ,
		{"word":"katangais","word_nosc":"katangais","lemma":"katangais","pos":"ADJ"} ,
		{"word":"kazakh","word_nosc":"kazakh","lemma":"kazakh","pos":"ADJ"} ,
		{"word":"kazakhs","word_nosc":"kazakhs","lemma":"kazakh","pos":"ADJ"} ,
		{"word":"kebla","word_nosc":"kebla","lemma":"kebla","pos":"ADJ"} ,
		{"word":"keblas","word_nosc":"keblas","lemma":"kebla","pos":"ADJ"} ,
		{"word":"khazar","word_nosc":"khazar","lemma":"khazar","pos":"ADJ"} ,
		{"word":"khmer","word_nosc":"khmer","lemma":"khmer","pos":"ADJ"} ,
		{"word":"khmère","word_nosc":"khmere","lemma":"khmer","pos":"ADJ"} ,
		{"word":"khédival","word_nosc":"khedival","lemma":"khédival","pos":"ADJ"} ,
		{"word":"kif-kif","word_nosc":"kif-kif","lemma":"kif-kif","pos":"ADJ"} ,
		{"word":"kilométrique","word_nosc":"kilometrique","lemma":"kilométrique","pos":"ADJ"} ,
		{"word":"kilométriques","word_nosc":"kilometriques","lemma":"kilométrique","pos":"ADJ"} ,
		{"word":"kirghize","word_nosc":"kirghize","lemma":"kirghize","pos":"ADJ"} ,
		{"word":"kirghizes","word_nosc":"kirghizes","lemma":"kirghize","pos":"ADJ"} ,
		{"word":"kitch","word_nosc":"kitch","lemma":"kitch","pos":"ADJ"} ,
		{"word":"kitsch","word_nosc":"kitsch","lemma":"kitsch","pos":"ADJ"} ,
		{"word":"knock-out","word_nosc":"knock-out","lemma":"knock-out","pos":"ADJ"} ,
		{"word":"komi","word_nosc":"komi","lemma":"komi","pos":"ADJ"} ,
		{"word":"kurde","word_nosc":"kurde","lemma":"kurde","pos":"ADJ"} ,
		{"word":"kurdes","word_nosc":"kurdes","lemma":"kurde","pos":"ADJ"} ,
		{"word":"kystique","word_nosc":"kystique","lemma":"kystique","pos":"ADJ"} ,
		{"word":"kényan","word_nosc":"kenyan","lemma":"kényan","pos":"ADJ"} ,
		{"word":"kératinisée","word_nosc":"keratinisee","lemma":"kératinisé","pos":"ADJ"} ,
		{"word":"l","word_nosc":"l","lemma":"l","pos":"ADJ:num"} ,
		{"word":"la plupart de","word_nosc":"la plupart de","lemma":"la plupart de","pos":"ADJ:ind"} ,
		{"word":"la plupart des","word_nosc":"la plupart des","lemma":"la plupart des","pos":"ADJ:ind"} ,
		{"word":"la plupart du","word_nosc":"la plupart du","lemma":"la plupart du","pos":"ADJ:ind"} ,
		{"word":"labial","word_nosc":"labial","lemma":"labial","pos":"ADJ"} ,
		{"word":"labiale","word_nosc":"labiale","lemma":"labial","pos":"ADJ"} ,
		{"word":"labiales","word_nosc":"labiales","lemma":"labial","pos":"ADJ"} ,
		{"word":"labiaux","word_nosc":"labiaux","lemma":"labial","pos":"ADJ"} ,
		{"word":"labile","word_nosc":"labile","lemma":"labile","pos":"ADJ"} ,
		{"word":"laborieuse","word_nosc":"laborieuse","lemma":"laborieux","pos":"ADJ"} ,
		{"word":"laborieuses","word_nosc":"laborieuses","lemma":"laborieux","pos":"ADJ"} ,
		{"word":"laborieux","word_nosc":"laborieux","lemma":"laborieux","pos":"ADJ"} ,
		{"word":"labourable","word_nosc":"labourable","lemma":"labourable","pos":"ADJ"} ,
		{"word":"labyrinthique","word_nosc":"labyrinthique","lemma":"labyrinthique","pos":"ADJ"} ,
		{"word":"labyrinthiques","word_nosc":"labyrinthiques","lemma":"labyrinthique","pos":"ADJ"} ,
		{"word":"lacandon","word_nosc":"lacandon","lemma":"lacandon","pos":"ADJ"} ,
		{"word":"lacanien","word_nosc":"lacanien","lemma":"lacanien","pos":"ADJ"} ,
		{"word":"laconique","word_nosc":"laconique","lemma":"laconique","pos":"ADJ"} ,
		{"word":"laconiques","word_nosc":"laconiques","lemma":"laconique","pos":"ADJ"} ,
		{"word":"lacrymal","word_nosc":"lacrymal","lemma":"lacrymal","pos":"ADJ"} ,
		{"word":"lacrymale","word_nosc":"lacrymale","lemma":"lacrymal","pos":"ADJ"} ,
		{"word":"lacrymales","word_nosc":"lacrymales","lemma":"lacrymal","pos":"ADJ"} ,
		{"word":"lacrymaux","word_nosc":"lacrymaux","lemma":"lacrymal","pos":"ADJ"} ,
		{"word":"lacrymogène","word_nosc":"lacrymogene","lemma":"lacrymogène","pos":"ADJ"} ,
		{"word":"lacrymogènes","word_nosc":"lacrymogenes","lemma":"lacrymogène","pos":"ADJ"} ,
		{"word":"lactescent","word_nosc":"lactescent","lemma":"lactescent","pos":"ADJ"} ,
		{"word":"lactescente","word_nosc":"lactescente","lemma":"lactescent","pos":"ADJ"} ,
		{"word":"lactifère","word_nosc":"lactifere","lemma":"lactifère","pos":"ADJ"} ,
		{"word":"lactique","word_nosc":"lactique","lemma":"lactique","pos":"ADJ"} ,
		{"word":"lacté","word_nosc":"lacte","lemma":"lacté","pos":"ADJ"} ,
		{"word":"lactée","word_nosc":"lactee","lemma":"lacté","pos":"ADJ"} ,
		{"word":"lactés","word_nosc":"lactes","lemma":"lacté","pos":"ADJ"} ,
		{"word":"lacunaire","word_nosc":"lacunaire","lemma":"lacunaire","pos":"ADJ"} ,
		{"word":"lacustre","word_nosc":"lacustre","lemma":"lacustre","pos":"ADJ"} ,
		{"word":"lacustres","word_nosc":"lacustres","lemma":"lacustre","pos":"ADJ"} ,
		{"word":"lacéré","word_nosc":"lacere","lemma":"lacéré","pos":"ADJ"} ,
		{"word":"lacérée","word_nosc":"laceree","lemma":"lacéré","pos":"ADJ"} ,
		{"word":"lacérées","word_nosc":"lacerees","lemma":"lacéré","pos":"ADJ"} ,
		{"word":"lacérés","word_nosc":"laceres","lemma":"lacéré","pos":"ADJ"} ,
		{"word":"ladite","word_nosc":"ladite","lemma":"ladite","pos":"ADJ"} ,
		{"word":"ladres","word_nosc":"ladres","lemma":"ladre","pos":"ADJ"} ,
		{"word":"lagunaire","word_nosc":"lagunaire","lemma":"lagunaire","pos":"ADJ"} ,
		{"word":"lai","word_nosc":"lai","lemma":"lai","pos":"ADJ"} ,
		{"word":"laid","word_nosc":"laid","lemma":"laid","pos":"ADJ"} ,
		{"word":"laide","word_nosc":"laide","lemma":"laid","pos":"ADJ"} ,
		{"word":"laideron","word_nosc":"laideron","lemma":"laideron","pos":"ADJ"} ,
		{"word":"laiderons","word_nosc":"laiderons","lemma":"laideron","pos":"ADJ"} ,
		{"word":"laides","word_nosc":"laides","lemma":"laid","pos":"ADJ"} ,
		{"word":"laids","word_nosc":"laids","lemma":"laid","pos":"ADJ"} ,
		{"word":"laie","word_nosc":"laie","lemma":"laie","pos":"ADJ"} ,
		{"word":"laies","word_nosc":"laies","lemma":"laie","pos":"ADJ"} ,
		{"word":"laineuse","word_nosc":"laineuse","lemma":"laineux","pos":"ADJ"} ,
		{"word":"laineuses","word_nosc":"laineuses","lemma":"laineux","pos":"ADJ"} ,
		{"word":"laineux","word_nosc":"laineux","lemma":"laineux","pos":"ADJ"} ,
		{"word":"lainier","word_nosc":"lainier","lemma":"lainier","pos":"ADJ"} ,
		{"word":"lainière","word_nosc":"lainiere","lemma":"lainier","pos":"ADJ"} ,
		{"word":"lais","word_nosc":"lais","lemma":"lai","pos":"ADJ"} ,
		{"word":"laiteuse","word_nosc":"laiteuse","lemma":"laiteux","pos":"ADJ"} ,
		{"word":"laiteuses","word_nosc":"laiteuses","lemma":"laiteux","pos":"ADJ"} ,
		{"word":"laiteux","word_nosc":"laiteux","lemma":"laiteux","pos":"ADJ"} ,
		{"word":"laitier","word_nosc":"laitier","lemma":"laitier","pos":"ADJ"} ,
		{"word":"laitiers","word_nosc":"laitiers","lemma":"laitier","pos":"ADJ"} ,
		{"word":"laitière","word_nosc":"laitiere","lemma":"laitier","pos":"ADJ"} ,
		{"word":"laitières","word_nosc":"laitieres","lemma":"laitier","pos":"ADJ"} ,
		{"word":"lambin","word_nosc":"lambin","lemma":"lambin","pos":"ADJ"} ,
		{"word":"lambine","word_nosc":"lambine","lemma":"lambine","pos":"ADJ"} ,
		{"word":"lambins","word_nosc":"lambins","lemma":"lambin","pos":"ADJ"} ,
		{"word":"lambrissée","word_nosc":"lambrissee","lemma":"lambrissé","pos":"ADJ"} ,
		{"word":"lambrissées","word_nosc":"lambrissees","lemma":"lambrissé","pos":"ADJ"} ,
		{"word":"lamelliforme","word_nosc":"lamelliforme","lemma":"lamelliforme","pos":"ADJ"} ,
		{"word":"lamentable","word_nosc":"lamentable","lemma":"lamentable","pos":"ADJ"} ,
		{"word":"lamentables","word_nosc":"lamentables","lemma":"lamentable","pos":"ADJ"} ,
		{"word":"lamifié","word_nosc":"lamifie","lemma":"lamifié","pos":"ADJ"} ,
		{"word":"lamifiés","word_nosc":"lamifies","lemma":"lamifié","pos":"ADJ"} ,
		{"word":"laminaire","word_nosc":"laminaire","lemma":"laminaire","pos":"ADJ"} ,
		{"word":"lampadophore","word_nosc":"lampadophore","lemma":"lampadophore","pos":"ADJ"} ,
		{"word":"lamé","word_nosc":"lame","lemma":"lamé","pos":"ADJ"} ,
		{"word":"lamée","word_nosc":"lamee","lemma":"lamé","pos":"ADJ"} ,
		{"word":"lamés","word_nosc":"lames","lemma":"lamé","pos":"ADJ"} ,
		{"word":"lancinant","word_nosc":"lancinant","lemma":"lancinant","pos":"ADJ"} ,
		{"word":"lancinante","word_nosc":"lancinante","lemma":"lancinant","pos":"ADJ"} ,
		{"word":"lancinantes","word_nosc":"lancinantes","lemma":"lancinant","pos":"ADJ"} ,
		{"word":"lancinants","word_nosc":"lancinants","lemma":"lancinant","pos":"ADJ"} ,
		{"word":"lancéolés","word_nosc":"lanceoles","lemma":"lancéolé","pos":"ADJ"} ,
		{"word":"landais","word_nosc":"landais","lemma":"landais","pos":"ADJ"} ,
		{"word":"landaise","word_nosc":"landaise","lemma":"landais","pos":"ADJ"} ,
		{"word":"landaises","word_nosc":"landaises","lemma":"landais","pos":"ADJ"} ,
		{"word":"langagiers","word_nosc":"langagiers","lemma":"langagier","pos":"ADJ"} ,
		{"word":"langoureuse","word_nosc":"langoureuse","lemma":"langoureux","pos":"ADJ"} ,
		{"word":"langoureuses","word_nosc":"langoureuses","lemma":"langoureux","pos":"ADJ"} ,
		{"word":"langoureux","word_nosc":"langoureux","lemma":"langoureux","pos":"ADJ"} ,
		{"word":"languide","word_nosc":"languide","lemma":"languide","pos":"ADJ"} ,
		{"word":"languides","word_nosc":"languides","lemma":"languide","pos":"ADJ"} ,
		{"word":"languissant","word_nosc":"languissant","lemma":"languissant","pos":"ADJ"} ,
		{"word":"languissante","word_nosc":"languissante","lemma":"languissant","pos":"ADJ"} ,
		{"word":"languissantes","word_nosc":"languissantes","lemma":"languissant","pos":"ADJ"} ,
		{"word":"languissants","word_nosc":"languissants","lemma":"languissant","pos":"ADJ"} ,
		{"word":"lançant","word_nosc":"lancant","lemma":"lançant","pos":"ADJ"} ,
		{"word":"laotien","word_nosc":"laotien","lemma":"laotien","pos":"ADJ"} ,
		{"word":"laotienne","word_nosc":"laotienne","lemma":"laotien","pos":"ADJ"} ,
		{"word":"laotiens","word_nosc":"laotiens","lemma":"laotien","pos":"ADJ"} ,
		{"word":"lapidaire","word_nosc":"lapidaire","lemma":"lapidaire","pos":"ADJ"} ,
		{"word":"lapidaires","word_nosc":"lapidaires","lemma":"lapidaire","pos":"ADJ"} ,
		{"word":"lapon","word_nosc":"lapon","lemma":"lapon","pos":"ADJ"} ,
		{"word":"lapone","word_nosc":"lapone","lemma":"lapon","pos":"ADJ"} ,
		{"word":"lapones","word_nosc":"lapones","lemma":"lapon","pos":"ADJ"} ,
		{"word":"laponne","word_nosc":"laponne","lemma":"lapon","pos":"ADJ"} ,
		{"word":"laqué","word_nosc":"laque","lemma":"laqué","pos":"ADJ"} ,
		{"word":"laquée","word_nosc":"laquee","lemma":"laqué","pos":"ADJ"} ,
		{"word":"laquées","word_nosc":"laquees","lemma":"laqué","pos":"ADJ"} ,
		{"word":"laqués","word_nosc":"laques","lemma":"laqué","pos":"ADJ"} ,
		{"word":"lardeux","word_nosc":"lardeux","lemma":"lardeux","pos":"ADJ"} ,
		{"word":"lare","word_nosc":"lare","lemma":"lare","pos":"ADJ"} ,
		{"word":"lares","word_nosc":"lares","lemma":"lare","pos":"ADJ"} ,
		{"word":"largable","word_nosc":"largable","lemma":"largable","pos":"ADJ"} ,
		{"word":"large","word_nosc":"large","lemma":"large","pos":"ADJ"} ,
		{"word":"larges","word_nosc":"larges","lemma":"large","pos":"ADJ"} ,
		{"word":"largué","word_nosc":"largue","lemma":"largué","pos":"ADJ"} ,
		{"word":"larguée","word_nosc":"larguee","lemma":"largué","pos":"ADJ"} ,
		{"word":"larguées","word_nosc":"larguees","lemma":"largué","pos":"ADJ"} ,
		{"word":"largués","word_nosc":"largues","lemma":"largué","pos":"ADJ"} ,
		{"word":"larmoyant","word_nosc":"larmoyant","lemma":"larmoyant","pos":"ADJ"} ,
		{"word":"larmoyante","word_nosc":"larmoyante","lemma":"larmoyant","pos":"ADJ"} ,
		{"word":"larmoyantes","word_nosc":"larmoyantes","lemma":"larmoyant","pos":"ADJ"} ,
		{"word":"larmoyants","word_nosc":"larmoyants","lemma":"larmoyant","pos":"ADJ"} ,
		{"word":"larmoyeur","word_nosc":"larmoyeur","lemma":"larmoyeur","pos":"ADJ"} ,
		{"word":"larvaire","word_nosc":"larvaire","lemma":"larvaire","pos":"ADJ"} ,
		{"word":"larvaires","word_nosc":"larvaires","lemma":"larvaire","pos":"ADJ"} ,
		{"word":"larvé","word_nosc":"larve","lemma":"larvé","pos":"ADJ"} ,
		{"word":"larvée","word_nosc":"larvee","lemma":"larvé","pos":"ADJ"} ,
		{"word":"larvées","word_nosc":"larvees","lemma":"larvé","pos":"ADJ"} ,
		{"word":"laryngologique","word_nosc":"laryngologique","lemma":"laryngologique","pos":"ADJ"} ,
		{"word":"laryngé","word_nosc":"larynge","lemma":"laryngé","pos":"ADJ"} ,
		{"word":"laryngée","word_nosc":"laryngee","lemma":"laryngé","pos":"ADJ"} ,
		{"word":"las","word_nosc":"las","lemma":"las","pos":"ADJ"} ,
		{"word":"lascif","word_nosc":"lascif","lemma":"lascif","pos":"ADJ"} ,
		{"word":"lascifs","word_nosc":"lascifs","lemma":"lascif","pos":"ADJ"} ,
		{"word":"lascive","word_nosc":"lascive","lemma":"lascif","pos":"ADJ"} ,
		{"word":"lascives","word_nosc":"lascives","lemma":"lascif","pos":"ADJ"} ,
		{"word":"lassant","word_nosc":"lassant","lemma":"lassant","pos":"ADJ"} ,
		{"word":"lassante","word_nosc":"lassante","lemma":"lassant","pos":"ADJ"} ,
		{"word":"lassantes","word_nosc":"lassantes","lemma":"lassant","pos":"ADJ"} ,
		{"word":"lassants","word_nosc":"lassants","lemma":"lassant","pos":"ADJ"} ,
		{"word":"lasse","word_nosc":"lasse","lemma":"las","pos":"ADJ"} ,
		{"word":"lasses","word_nosc":"lasses","lemma":"las","pos":"ADJ"} ,
		{"word":"latent","word_nosc":"latent","lemma":"latent","pos":"ADJ"} ,
		{"word":"latente","word_nosc":"latente","lemma":"latent","pos":"ADJ"} ,
		{"word":"latentes","word_nosc":"latentes","lemma":"latent","pos":"ADJ"} ,
		{"word":"latents","word_nosc":"latents","lemma":"latent","pos":"ADJ"} ,
		{"word":"latin","word_nosc":"latin","lemma":"latin","pos":"ADJ"} ,
		{"word":"latine","word_nosc":"latine","lemma":"latin","pos":"ADJ"} ,
		{"word":"latines","word_nosc":"latines","lemma":"latin","pos":"ADJ"} ,
		{"word":"latins","word_nosc":"latins","lemma":"latin","pos":"ADJ"} ,
		{"word":"latéral","word_nosc":"lateral","lemma":"latéral","pos":"ADJ"} ,
		{"word":"latérale","word_nosc":"laterale","lemma":"latéral","pos":"ADJ"} ,
		{"word":"latérales","word_nosc":"laterales","lemma":"latéral","pos":"ADJ"} ,
		{"word":"latéralisée","word_nosc":"lateralisee","lemma":"latéralisé","pos":"ADJ"} ,
		{"word":"latéraux","word_nosc":"lateraux","lemma":"latéral","pos":"ADJ"} ,
		{"word":"laudateur","word_nosc":"laudateur","lemma":"laudateur","pos":"ADJ"} ,
		{"word":"laudative","word_nosc":"laudative","lemma":"laudatif","pos":"ADJ"} ,
		{"word":"lauréat","word_nosc":"laureat","lemma":"lauréat","pos":"ADJ"} ,
		{"word":"lauréate","word_nosc":"laureate","lemma":"lauréat","pos":"ADJ"} ,
		{"word":"lauréats","word_nosc":"laureats","lemma":"lauréat","pos":"ADJ"} ,
		{"word":"lausannoises","word_nosc":"lausannoises","lemma":"lausannois","pos":"ADJ"} ,
		{"word":"lavable","word_nosc":"lavable","lemma":"lavable","pos":"ADJ"} ,
		{"word":"lavables","word_nosc":"lavables","lemma":"lavable","pos":"ADJ"} ,
		{"word":"lavé","word_nosc":"lave","lemma":"lavé","pos":"ADJ"} ,
		{"word":"lavée","word_nosc":"lavee","lemma":"lavé","pos":"ADJ"} ,
		{"word":"lavées","word_nosc":"lavees","lemma":"lavé","pos":"ADJ"} ,
		{"word":"lavés","word_nosc":"laves","lemma":"lavé","pos":"ADJ"} ,
		{"word":"laxatif","word_nosc":"laxatif","lemma":"laxatif","pos":"ADJ"} ,
		{"word":"laxatifs","word_nosc":"laxatifs","lemma":"laxatif","pos":"ADJ"} ,
		{"word":"laxative","word_nosc":"laxative","lemma":"laxatif","pos":"ADJ"} ,
		{"word":"laxatives","word_nosc":"laxatives","lemma":"laxatif","pos":"ADJ"} ,
		{"word":"laxiste","word_nosc":"laxiste","lemma":"laxiste","pos":"ADJ"} ,
		{"word":"laxistes","word_nosc":"laxistes","lemma":"laxiste","pos":"ADJ"} ,
		{"word":"laïc","word_nosc":"laic","lemma":"laïc","pos":"ADJ"} ,
		{"word":"laïcs","word_nosc":"laics","lemma":"laïc","pos":"ADJ"} ,
		{"word":"laïque","word_nosc":"laique","lemma":"laïque","pos":"ADJ"} ,
		{"word":"laïques","word_nosc":"laiques","lemma":"laïque","pos":"ADJ"} ,
		{"word":"ledit","word_nosc":"ledit","lemma":"ledit","pos":"ADJ"} ,
		{"word":"lent","word_nosc":"lent","lemma":"lent","pos":"ADJ"} ,
		{"word":"lente","word_nosc":"lente","lemma":"lent","pos":"ADJ"} ,
		{"word":"lentes","word_nosc":"lentes","lemma":"lent","pos":"ADJ"} ,
		{"word":"lenticulaires","word_nosc":"lenticulaires","lemma":"lenticulaire","pos":"ADJ"} ,
		{"word":"lents","word_nosc":"lents","lemma":"lent","pos":"ADJ"} ,
		{"word":"leptonique","word_nosc":"leptonique","lemma":"leptonique","pos":"ADJ"} ,
		{"word":"lesbien","word_nosc":"lesbien","lemma":"lesbien","pos":"ADJ"} ,
		{"word":"lesbienne","word_nosc":"lesbienne","lemma":"lesbien","pos":"ADJ"} ,
		{"word":"lesbiennes","word_nosc":"lesbiennes","lemma":"lesbien","pos":"ADJ"} ,
		{"word":"lesdites","word_nosc":"lesdites","lemma":"lesdites","pos":"ADJ"} ,
		{"word":"lesdits","word_nosc":"lesdits","lemma":"lesdits","pos":"ADJ"} ,
		{"word":"leste","word_nosc":"leste","lemma":"leste","pos":"ADJ"} ,
		{"word":"lestes","word_nosc":"lestes","lemma":"leste","pos":"ADJ"} ,
		{"word":"lesté","word_nosc":"leste","lemma":"lesté","pos":"ADJ"} ,
		{"word":"lestée","word_nosc":"lestee","lemma":"lesté","pos":"ADJ"} ,
		{"word":"lestées","word_nosc":"lestees","lemma":"lesté","pos":"ADJ"} ,
		{"word":"let","word_nosc":"let","lemma":"let","pos":"ADJ"} ,
		{"word":"letton","word_nosc":"letton","lemma":"letton","pos":"ADJ"} ,
		{"word":"lettone","word_nosc":"lettone","lemma":"letton","pos":"ADJ"} ,
		{"word":"lettonne","word_nosc":"lettonne","lemma":"letton","pos":"ADJ"} ,
		{"word":"lettonnes","word_nosc":"lettonnes","lemma":"letton","pos":"ADJ"} ,
		{"word":"lettons","word_nosc":"lettons","lemma":"letton","pos":"ADJ"} ,
		{"word":"lettriques","word_nosc":"lettriques","lemma":"lettrique","pos":"ADJ"} ,
		{"word":"lettristes","word_nosc":"lettristes","lemma":"lettriste","pos":"ADJ"} ,
		{"word":"lettré","word_nosc":"lettre","lemma":"lettré","pos":"ADJ"} ,
		{"word":"lettrée","word_nosc":"lettree","lemma":"lettré","pos":"ADJ"} ,
		{"word":"lettrés","word_nosc":"lettres","lemma":"lettré","pos":"ADJ"} ,
		{"word":"leucocytaire","word_nosc":"leucocytaire","lemma":"leucocytaire","pos":"ADJ"} ,
		{"word":"leucémique","word_nosc":"leucemique","lemma":"leucémique","pos":"ADJ"} ,
		{"word":"leurs","word_nosc":"leurs","lemma":"leurs","pos":"ADJ:pos"} ,
		{"word":"levant","word_nosc":"levant","lemma":"levant","pos":"ADJ"} ,
		{"word":"levante","word_nosc":"levante","lemma":"levant","pos":"ADJ"} ,
		{"word":"levantin","word_nosc":"levantin","lemma":"levantin","pos":"ADJ"} ,
		{"word":"levantins","word_nosc":"levantins","lemma":"levantin","pos":"ADJ"} ,
		{"word":"levé","word_nosc":"leve","lemma":"levé","pos":"ADJ"} ,
		{"word":"levée","word_nosc":"levee","lemma":"levé","pos":"ADJ"} ,
		{"word":"levées","word_nosc":"levees","lemma":"levé","pos":"ADJ"} ,
		{"word":"levés","word_nosc":"leves","lemma":"levé","pos":"ADJ"} ,
		{"word":"lexical","word_nosc":"lexical","lemma":"lexical","pos":"ADJ"} ,
		{"word":"liant","word_nosc":"liant","lemma":"liant","pos":"ADJ"} ,
		{"word":"liante","word_nosc":"liante","lemma":"liant","pos":"ADJ"} ,
		{"word":"liants","word_nosc":"liants","lemma":"liant","pos":"ADJ"} ,
		{"word":"libanais","word_nosc":"libanais","lemma":"libanais","pos":"ADJ"} ,
		{"word":"libanaise","word_nosc":"libanaise","lemma":"libanais","pos":"ADJ"} ,
		{"word":"libanaises","word_nosc":"libanaises","lemma":"libanais","pos":"ADJ"} ,
		{"word":"libertaire","word_nosc":"libertaire","lemma":"libertaire","pos":"ADJ"} ,
		{"word":"libertaires","word_nosc":"libertaires","lemma":"libertaire","pos":"ADJ"} ,
		{"word":"liberticide","word_nosc":"liberticide","lemma":"liberticide","pos":"ADJ"} ,
		{"word":"libertin","word_nosc":"libertin","lemma":"libertin","pos":"ADJ"} ,
		{"word":"libertine","word_nosc":"libertine","lemma":"libertin","pos":"ADJ"} ,
		{"word":"libertines","word_nosc":"libertines","lemma":"libertin","pos":"ADJ"} ,
		{"word":"libertins","word_nosc":"libertins","lemma":"libertin","pos":"ADJ"} ,
		{"word":"libidinal","word_nosc":"libidinal","lemma":"libidinal","pos":"ADJ"} ,
		{"word":"libidinale","word_nosc":"libidinale","lemma":"libidinal","pos":"ADJ"} ,
		{"word":"libidinales","word_nosc":"libidinales","lemma":"libidinal","pos":"ADJ"} ,
		{"word":"libidinaux","word_nosc":"libidinaux","lemma":"libidinal","pos":"ADJ"} ,
		{"word":"libidineuse","word_nosc":"libidineuse","lemma":"libidineux","pos":"ADJ"} ,
		{"word":"libidineuses","word_nosc":"libidineuses","lemma":"libidineux","pos":"ADJ"} ,
		{"word":"libidineux","word_nosc":"libidineux","lemma":"libidineux","pos":"ADJ"} ,
		{"word":"libre","word_nosc":"libre","lemma":"libre","pos":"ADJ"} ,
		{"word":"libres","word_nosc":"libres","lemma":"libre","pos":"ADJ"} ,
		{"word":"libyen","word_nosc":"libyen","lemma":"libyen","pos":"ADJ"} ,
		{"word":"libyenne","word_nosc":"libyenne","lemma":"libyen","pos":"ADJ"} ,
		{"word":"libyens","word_nosc":"libyens","lemma":"libyen","pos":"ADJ"} ,
		{"word":"libérable","word_nosc":"liberable","lemma":"libérable","pos":"ADJ"} ,
		{"word":"libérables","word_nosc":"liberables","lemma":"libérable","pos":"ADJ"} ,
		{"word":"libéral","word_nosc":"liberal","lemma":"libéral","pos":"ADJ"} ,
		{"word":"libérale","word_nosc":"liberale","lemma":"libéral","pos":"ADJ"} ,
		{"word":"libérales","word_nosc":"liberales","lemma":"libéral","pos":"ADJ"} ,
		{"word":"libérateur","word_nosc":"liberateur","lemma":"libérateur","pos":"ADJ"} ,
		{"word":"libérateurs","word_nosc":"liberateurs","lemma":"libérateur","pos":"ADJ"} ,
		{"word":"libératoire","word_nosc":"liberatoire","lemma":"libératoire","pos":"ADJ"} ,
		{"word":"libératrice","word_nosc":"liberatrice","lemma":"libérateur","pos":"ADJ"} ,
		{"word":"libératrices","word_nosc":"liberatrices","lemma":"libérateur","pos":"ADJ"} ,
		{"word":"libéraux","word_nosc":"liberaux","lemma":"libéral","pos":"ADJ"} ,
		{"word":"libérien","word_nosc":"liberien","lemma":"libérien","pos":"ADJ"} ,
		{"word":"libéré","word_nosc":"libere","lemma":"libéré","pos":"ADJ"} ,
		{"word":"libérée","word_nosc":"liberee","lemma":"libéré","pos":"ADJ"} ,
		{"word":"libérées","word_nosc":"liberees","lemma":"libéré","pos":"ADJ"} ,
		{"word":"libérés","word_nosc":"liberes","lemma":"libéré","pos":"ADJ"} ,
		{"word":"licenciables","word_nosc":"licenciables","lemma":"licenciable","pos":"ADJ"} ,
		{"word":"licencieuse","word_nosc":"licencieuse","lemma":"licencieux","pos":"ADJ"} ,
		{"word":"licencieuses","word_nosc":"licencieuses","lemma":"licencieux","pos":"ADJ"} ,
		{"word":"licencieux","word_nosc":"licencieux","lemma":"licencieux","pos":"ADJ"} ,
		{"word":"licencié","word_nosc":"licencie","lemma":"licencié","pos":"ADJ"} ,
		{"word":"licenciée","word_nosc":"licenciee","lemma":"licencié","pos":"ADJ"} ,
		{"word":"licenciés","word_nosc":"licencies","lemma":"licencié","pos":"ADJ"} ,
		{"word":"licite","word_nosc":"licite","lemma":"licite","pos":"ADJ"} ,
		{"word":"licites","word_nosc":"licites","lemma":"licite","pos":"ADJ"} ,
		{"word":"lie-de-vin","word_nosc":"lie-de-vin","lemma":"lie-de-vin","pos":"ADJ"} ,
		{"word":"lifté","word_nosc":"lifte","lemma":"lifté","pos":"ADJ"} ,
		{"word":"liftée","word_nosc":"liftee","lemma":"lifté","pos":"ADJ"} ,
		{"word":"liftées","word_nosc":"liftees","lemma":"lifté","pos":"ADJ"} ,
		{"word":"ligamentaire","word_nosc":"ligamentaire","lemma":"ligamentaire","pos":"ADJ"} ,
		{"word":"lige","word_nosc":"lige","lemma":"lige","pos":"ADJ"} ,
		{"word":"liges","word_nosc":"liges","lemma":"lige","pos":"ADJ"} ,
		{"word":"light","word_nosc":"light","lemma":"light","pos":"ADJ"} ,
		{"word":"ligneuses","word_nosc":"ligneuses","lemma":"ligneux","pos":"ADJ"} ,
		{"word":"ligneux","word_nosc":"ligneux","lemma":"ligneux","pos":"ADJ"} ,
		{"word":"ligoté","word_nosc":"ligote","lemma":"ligoté","pos":"ADJ"} ,
		{"word":"ligotée","word_nosc":"ligotee","lemma":"ligoté","pos":"ADJ"} ,
		{"word":"ligotées","word_nosc":"ligotees","lemma":"ligoté","pos":"ADJ"} ,
		{"word":"ligotés","word_nosc":"ligotes","lemma":"ligoté","pos":"ADJ"} ,
		{"word":"ligure","word_nosc":"ligure","lemma":"ligure","pos":"ADJ"} ,
		{"word":"ligures","word_nosc":"ligures","lemma":"ligure","pos":"ADJ"} ,
		{"word":"liguées","word_nosc":"liguees","lemma":"ligué","pos":"ADJ"} ,
		{"word":"ligués","word_nosc":"ligues","lemma":"ligué","pos":"ADJ"} ,
		{"word":"lilas","word_nosc":"lilas","lemma":"lilas","pos":"ADJ"} ,
		{"word":"lilial","word_nosc":"lilial","lemma":"lilial","pos":"ADJ"} ,
		{"word":"liliale","word_nosc":"liliale","lemma":"lilial","pos":"ADJ"} ,
		{"word":"lilliputien","word_nosc":"lilliputien","lemma":"lilliputien","pos":"ADJ"} ,
		{"word":"lilliputienne","word_nosc":"lilliputienne","lemma":"lilliputien","pos":"ADJ"} ,
		{"word":"lilliputiens","word_nosc":"lilliputiens","lemma":"lilliputien","pos":"ADJ"} ,
		{"word":"limbique","word_nosc":"limbique","lemma":"limbique","pos":"ADJ"} ,
		{"word":"liminaire","word_nosc":"liminaire","lemma":"liminaire","pos":"ADJ"} ,
		{"word":"limitatif","word_nosc":"limitatif","lemma":"limitatif","pos":"ADJ"} ,
		{"word":"limitative","word_nosc":"limitative","lemma":"limitatif","pos":"ADJ"} ,
		{"word":"limitrophe","word_nosc":"limitrophe","lemma":"limitrophe","pos":"ADJ"} ,
		{"word":"limitrophes","word_nosc":"limitrophes","lemma":"limitrophe","pos":"ADJ"} ,
		{"word":"limité","word_nosc":"limite","lemma":"limité","pos":"ADJ"} ,
		{"word":"limitée","word_nosc":"limitee","lemma":"limité","pos":"ADJ"} ,
		{"word":"limitées","word_nosc":"limitees","lemma":"limité","pos":"ADJ"} ,
		{"word":"limités","word_nosc":"limites","lemma":"limité","pos":"ADJ"} ,
		{"word":"limogé","word_nosc":"limoge","lemma":"limogé","pos":"ADJ"} ,
		{"word":"limoneuse","word_nosc":"limoneuse","lemma":"limoneux","pos":"ADJ"} ,
		{"word":"limoneuses","word_nosc":"limoneuses","lemma":"limoneux","pos":"ADJ"} ,
		{"word":"limoneux","word_nosc":"limoneux","lemma":"limoneux","pos":"ADJ"} ,
		{"word":"limousin","word_nosc":"limousin","lemma":"limousin","pos":"ADJ"} ,
		{"word":"limousine","word_nosc":"limousine","lemma":"limousin","pos":"ADJ"} ,
		{"word":"limousines","word_nosc":"limousines","lemma":"limousin","pos":"ADJ"} ,
		{"word":"limousins","word_nosc":"limousins","lemma":"limousin","pos":"ADJ"} ,
		{"word":"limpide","word_nosc":"limpide","lemma":"limpide","pos":"ADJ"} ,
		{"word":"limpides","word_nosc":"limpides","lemma":"limpide","pos":"ADJ"} ,
		{"word":"linger","word_nosc":"linger","lemma":"linger","pos":"ADJ"} ,
		{"word":"lingual","word_nosc":"lingual","lemma":"lingual","pos":"ADJ"} ,
		{"word":"linguale","word_nosc":"linguale","lemma":"lingual","pos":"ADJ"} ,
		{"word":"linguaux","word_nosc":"linguaux","lemma":"lingual","pos":"ADJ"} ,
		{"word":"linguistique","word_nosc":"linguistique","lemma":"linguistique","pos":"ADJ"} ,
		{"word":"linguistiques","word_nosc":"linguistiques","lemma":"linguistique","pos":"ADJ"} ,
		{"word":"lingère","word_nosc":"lingere","lemma":"linger","pos":"ADJ"} ,
		{"word":"lingères","word_nosc":"lingeres","lemma":"linger","pos":"ADJ"} ,
		{"word":"linéaire","word_nosc":"lineaire","lemma":"linéaire","pos":"ADJ"} ,
		{"word":"linéaires","word_nosc":"lineaires","lemma":"linéaire","pos":"ADJ"} ,
		{"word":"lippu","word_nosc":"lippu","lemma":"lippu","pos":"ADJ"} ,
		{"word":"lippue","word_nosc":"lippue","lemma":"lippu","pos":"ADJ"} ,
		{"word":"lippues","word_nosc":"lippues","lemma":"lippu","pos":"ADJ"} ,
		{"word":"lippus","word_nosc":"lippus","lemma":"lippu","pos":"ADJ"} ,
		{"word":"liquidateur","word_nosc":"liquidateur","lemma":"liquidateur","pos":"ADJ"} ,
		{"word":"liquidative","word_nosc":"liquidative","lemma":"liquidatif","pos":"ADJ"} ,
		{"word":"liquide","word_nosc":"liquide","lemma":"liquide","pos":"ADJ"} ,
		{"word":"liquides","word_nosc":"liquides","lemma":"liquide","pos":"ADJ"} ,
		{"word":"liquoreux","word_nosc":"liquoreux","lemma":"liquoreux","pos":"ADJ"} ,
		{"word":"liquéfiante","word_nosc":"liquefiante","lemma":"liquéfiant","pos":"ADJ"} ,
		{"word":"lisant","word_nosc":"lisant","lemma":"lisant","pos":"ADJ"} ,
		{"word":"liseuse","word_nosc":"liseuse","lemma":"liseur","pos":"ADJ"} ,
		{"word":"lisible","word_nosc":"lisible","lemma":"lisible","pos":"ADJ"} ,
		{"word":"lisibles","word_nosc":"lisibles","lemma":"lisible","pos":"ADJ"} ,
		{"word":"lisse","word_nosc":"lisse","lemma":"lisse","pos":"ADJ"} ,
		{"word":"lisses","word_nosc":"lisses","lemma":"lisse","pos":"ADJ"} ,
		{"word":"lissotriche","word_nosc":"lissotriche","lemma":"lissotriche","pos":"ADJ"} ,
		{"word":"lithinés","word_nosc":"lithines","lemma":"lithiné","pos":"ADJ"} ,
		{"word":"lithique","word_nosc":"lithique","lemma":"lithique","pos":"ADJ"} ,
		{"word":"lithophages","word_nosc":"lithophages","lemma":"lithophage","pos":"ADJ"} ,
		{"word":"lithuanien","word_nosc":"lithuanien","lemma":"lithuanien","pos":"ADJ"} ,
		{"word":"lithuanienne","word_nosc":"lithuanienne","lemma":"lithuanien","pos":"ADJ"} ,
		{"word":"lithuaniennes","word_nosc":"lithuaniennes","lemma":"lithuanien","pos":"ADJ"} ,
		{"word":"litigieuse","word_nosc":"litigieuse","lemma":"litigieux","pos":"ADJ"} ,
		{"word":"litigieux","word_nosc":"litigieux","lemma":"litigieux","pos":"ADJ"} ,
		{"word":"littoral","word_nosc":"littoral","lemma":"littoral","pos":"ADJ"} ,
		{"word":"littorale","word_nosc":"littorale","lemma":"littoral","pos":"ADJ"} ,
		{"word":"littéraire","word_nosc":"litteraire","lemma":"littéraire","pos":"ADJ"} ,
		{"word":"littéraires","word_nosc":"litteraires","lemma":"littéraire","pos":"ADJ"} ,
		{"word":"littéral","word_nosc":"litteral","lemma":"littéral","pos":"ADJ"} ,
		{"word":"littérale","word_nosc":"litterale","lemma":"littéral","pos":"ADJ"} ,
		{"word":"littérales","word_nosc":"litterales","lemma":"littéral","pos":"ADJ"} ,
		{"word":"littéraux","word_nosc":"litteraux","lemma":"littéral","pos":"ADJ"} ,
		{"word":"lituanien","word_nosc":"lituanien","lemma":"lituanien","pos":"ADJ"} ,
		{"word":"lituanienne","word_nosc":"lituanienne","lemma":"lituanien","pos":"ADJ"} ,
		{"word":"lituaniennes","word_nosc":"lituaniennes","lemma":"lituanien","pos":"ADJ"} ,
		{"word":"lituaniens","word_nosc":"lituaniens","lemma":"lituanien","pos":"ADJ"} ,
		{"word":"liturgique","word_nosc":"liturgique","lemma":"liturgique","pos":"ADJ"} ,
		{"word":"liturgiques","word_nosc":"liturgiques","lemma":"liturgique","pos":"ADJ"} ,
		{"word":"live","word_nosc":"live","lemma":"live","pos":"ADJ"} ,
		{"word":"livide","word_nosc":"livide","lemma":"livide","pos":"ADJ"} ,
		{"word":"livides","word_nosc":"livides","lemma":"livide","pos":"ADJ"} ,
		{"word":"livrable","word_nosc":"livrable","lemma":"livrable","pos":"ADJ"} ,
		{"word":"livrables","word_nosc":"livrables","lemma":"livrable","pos":"ADJ"} ,
		{"word":"livresque","word_nosc":"livresque","lemma":"livresque","pos":"ADJ"} ,
		{"word":"livresques","word_nosc":"livresques","lemma":"livresque","pos":"ADJ"} ,
		{"word":"lié","word_nosc":"lie","lemma":"lié","pos":"ADJ"} ,
		{"word":"liée","word_nosc":"liee","lemma":"lié","pos":"ADJ"} ,
		{"word":"liées","word_nosc":"liees","lemma":"lié","pos":"ADJ"} ,
		{"word":"liégeois","word_nosc":"liegeois","lemma":"liégeois","pos":"ADJ"} ,
		{"word":"liés","word_nosc":"lies","lemma":"lié","pos":"ADJ"} ,
		{"word":"lobaire","word_nosc":"lobaire","lemma":"lobaire","pos":"ADJ"} ,
		{"word":"lobulaire","word_nosc":"lobulaire","lemma":"lobulaire","pos":"ADJ"} ,
		{"word":"lobée","word_nosc":"lobee","lemma":"lobé","pos":"ADJ"} ,
		{"word":"local","word_nosc":"local","lemma":"local","pos":"ADJ"} ,
		{"word":"locale","word_nosc":"locale","lemma":"local","pos":"ADJ"} ,
		{"word":"locales","word_nosc":"locales","lemma":"local","pos":"ADJ"} ,
		{"word":"localisable","word_nosc":"localisable","lemma":"localisable","pos":"ADJ"} ,
		{"word":"localisateur","word_nosc":"localisateur","lemma":"localisateur","pos":"ADJ"} ,
		{"word":"localisé","word_nosc":"localise","lemma":"localisé","pos":"ADJ"} ,
		{"word":"localisée","word_nosc":"localisee","lemma":"localisé","pos":"ADJ"} ,
		{"word":"localisées","word_nosc":"localisees","lemma":"localisé","pos":"ADJ"} ,
		{"word":"localisés","word_nosc":"localises","lemma":"localisé","pos":"ADJ"} ,
		{"word":"locatif","word_nosc":"locatif","lemma":"locatif","pos":"ADJ"} ,
		{"word":"locative","word_nosc":"locative","lemma":"locatif","pos":"ADJ"} ,
		{"word":"locatives","word_nosc":"locatives","lemma":"locatif","pos":"ADJ"} ,
		{"word":"locaux","word_nosc":"locaux","lemma":"local","pos":"ADJ"} ,
		{"word":"locdu","word_nosc":"locdu","lemma":"locdu","pos":"ADJ"} ,
		{"word":"locomoteur","word_nosc":"locomoteur","lemma":"locomoteur","pos":"ADJ"} ,
		{"word":"locomoteurs","word_nosc":"locomoteurs","lemma":"locomoteur","pos":"ADJ"} ,
		{"word":"locomotive","word_nosc":"locomotive","lemma":"locomotif","pos":"ADJ"} ,
		{"word":"locomotives","word_nosc":"locomotives","lemma":"locomotif","pos":"ADJ"} ,
		{"word":"logarithmique","word_nosc":"logarithmique","lemma":"logarithmique","pos":"ADJ"} ,
		{"word":"logeable","word_nosc":"logeable","lemma":"logeable","pos":"ADJ"} ,
		{"word":"logeables","word_nosc":"logeables","lemma":"logeable","pos":"ADJ"} ,
		{"word":"logiciel","word_nosc":"logiciel","lemma":"logiciel","pos":"ADJ"} ,
		{"word":"logiciels","word_nosc":"logiciels","lemma":"logiciel","pos":"ADJ"} ,
		{"word":"login","word_nosc":"login","lemma":"login","pos":"ADJ"} ,
		{"word":"logique","word_nosc":"logique","lemma":"logique","pos":"ADJ"} ,
		{"word":"logiques","word_nosc":"logiques","lemma":"logique","pos":"ADJ"} ,
		{"word":"logistique","word_nosc":"logistique","lemma":"logistique","pos":"ADJ"} ,
		{"word":"logistiques","word_nosc":"logistiques","lemma":"logistique","pos":"ADJ"} ,
		{"word":"logé","word_nosc":"loge","lemma":"logé","pos":"ADJ"} ,
		{"word":"logée","word_nosc":"logee","lemma":"logé","pos":"ADJ"} ,
		{"word":"logés","word_nosc":"loges","lemma":"logé","pos":"ADJ"} ,
		{"word":"lointain","word_nosc":"lointain","lemma":"lointain","pos":"ADJ"} ,
		{"word":"lointaine","word_nosc":"lointaine","lemma":"lointain","pos":"ADJ"} ,
		{"word":"lointaines","word_nosc":"lointaines","lemma":"lointain","pos":"ADJ"} ,
		{"word":"lointains","word_nosc":"lointains","lemma":"lointain","pos":"ADJ"} ,
		{"word":"loisible","word_nosc":"loisible","lemma":"loisible","pos":"ADJ"} ,
		{"word":"lombaire","word_nosc":"lombaire","lemma":"lombaire","pos":"ADJ"} ,
		{"word":"lombaires","word_nosc":"lombaires","lemma":"lombaire","pos":"ADJ"} ,
		{"word":"lombard","word_nosc":"lombard","lemma":"lombard","pos":"ADJ"} ,
		{"word":"lombarde","word_nosc":"lombarde","lemma":"lombard","pos":"ADJ"} ,
		{"word":"lombardes","word_nosc":"lombardes","lemma":"lombard","pos":"ADJ"} ,
		{"word":"lombards","word_nosc":"lombards","lemma":"lombard","pos":"ADJ"} ,
		{"word":"londonien","word_nosc":"londonien","lemma":"londonien","pos":"ADJ"} ,
		{"word":"londonienne","word_nosc":"londonienne","lemma":"londonien","pos":"ADJ"} ,
		{"word":"londoniennes","word_nosc":"londoniennes","lemma":"londonien","pos":"ADJ"} ,
		{"word":"londoniens","word_nosc":"londoniens","lemma":"londonien","pos":"ADJ"} ,
		{"word":"long","word_nosc":"long","lemma":"long","pos":"ADJ"} ,
		{"word":"longiforme","word_nosc":"longiforme","lemma":"longiforme","pos":"ADJ"} ,
		{"word":"longiligne","word_nosc":"longiligne","lemma":"longiligne","pos":"ADJ"} ,
		{"word":"longilignes","word_nosc":"longilignes","lemma":"longiligne","pos":"ADJ"} ,
		{"word":"longitudinal","word_nosc":"longitudinal","lemma":"longitudinal","pos":"ADJ"} ,
		{"word":"longitudinale","word_nosc":"longitudinale","lemma":"longitudinal","pos":"ADJ"} ,
		{"word":"longitudinales","word_nosc":"longitudinales","lemma":"longitudinal","pos":"ADJ"} ,
		{"word":"longitudinaux","word_nosc":"longitudinaux","lemma":"longitudinal","pos":"ADJ"} ,
		{"word":"longs","word_nosc":"longs","lemma":"long","pos":"ADJ"} ,
		{"word":"longue","word_nosc":"longue","lemma":"long","pos":"ADJ"} ,
		{"word":"longues","word_nosc":"longues","lemma":"long","pos":"ADJ"} ,
		{"word":"longuet","word_nosc":"longuet","lemma":"longuet","pos":"ADJ"} ,
		{"word":"longuette","word_nosc":"longuette","lemma":"longuet","pos":"ADJ"} ,
		{"word":"longuettes","word_nosc":"longuettes","lemma":"longuet","pos":"ADJ"} ,
		{"word":"loquace","word_nosc":"loquace","lemma":"loquace","pos":"ADJ"} ,
		{"word":"loquaces","word_nosc":"loquaces","lemma":"loquace","pos":"ADJ"} ,
		{"word":"loqueteuse","word_nosc":"loqueteuse","lemma":"loqueteux","pos":"ADJ"} ,
		{"word":"loqueteuses","word_nosc":"loqueteuses","lemma":"loqueteux","pos":"ADJ"} ,
		{"word":"loqueteux","word_nosc":"loqueteux","lemma":"loqueteux","pos":"ADJ"} ,
		{"word":"lorrain","word_nosc":"lorrain","lemma":"lorrain","pos":"ADJ"} ,
		{"word":"lorraine","word_nosc":"lorraine","lemma":"lorrain","pos":"ADJ"} ,
		{"word":"lorraines","word_nosc":"lorraines","lemma":"lorrain","pos":"ADJ"} ,
		{"word":"lorrains","word_nosc":"lorrains","lemma":"lorrain","pos":"ADJ"} ,
		{"word":"losangé","word_nosc":"losange","lemma":"losangé","pos":"ADJ"} ,
		{"word":"losangée","word_nosc":"losangee","lemma":"losangé","pos":"ADJ"} ,
		{"word":"losangés","word_nosc":"losanges","lemma":"losangé","pos":"ADJ"} ,
		{"word":"loti","word_nosc":"loti","lemma":"loti","pos":"ADJ"} ,
		{"word":"lotie","word_nosc":"lotie","lemma":"loti","pos":"ADJ"} ,
		{"word":"loties","word_nosc":"loties","lemma":"loti","pos":"ADJ"} ,
		{"word":"lotis","word_nosc":"lotis","lemma":"loti","pos":"ADJ"} ,
		{"word":"louable","word_nosc":"louable","lemma":"louable","pos":"ADJ"} ,
		{"word":"louables","word_nosc":"louables","lemma":"louable","pos":"ADJ"} ,
		{"word":"louangeur","word_nosc":"louangeur","lemma":"louangeur","pos":"ADJ"} ,
		{"word":"louangeuses","word_nosc":"louangeuses","lemma":"louangeur","pos":"ADJ"} ,
		{"word":"louche","word_nosc":"louche","lemma":"louche","pos":"ADJ"} ,
		{"word":"louches","word_nosc":"louches","lemma":"louche","pos":"ADJ"} ,
		{"word":"louf","word_nosc":"louf","lemma":"louf","pos":"ADJ"} ,
		{"word":"loufoque","word_nosc":"loufoque","lemma":"loufoque","pos":"ADJ"} ,
		{"word":"loufoques","word_nosc":"loufoques","lemma":"loufoque","pos":"ADJ"} ,
		{"word":"louftingue","word_nosc":"louftingue","lemma":"louftingue","pos":"ADJ"} ,
		{"word":"louis-philippard","word_nosc":"louis-philippard","lemma":"louis-philippard","pos":"ADJ"} ,
		{"word":"louis-philipparde","word_nosc":"louis-philipparde","lemma":"louis-philippard","pos":"ADJ"} ,
		{"word":"louis-philippards","word_nosc":"louis-philippards","lemma":"louis-philippard","pos":"ADJ"} ,
		{"word":"louisianais","word_nosc":"louisianais","lemma":"louisianais","pos":"ADJ"} ,
		{"word":"lourd","word_nosc":"lourd","lemma":"lourd","pos":"ADJ"} ,
		{"word":"lourdaud","word_nosc":"lourdaud","lemma":"lourdaud","pos":"ADJ"} ,
		{"word":"lourdaude","word_nosc":"lourdaude","lemma":"lourdaud","pos":"ADJ"} ,
		{"word":"lourdaudes","word_nosc":"lourdaudes","lemma":"lourdaud","pos":"ADJ"} ,
		{"word":"lourdauds","word_nosc":"lourdauds","lemma":"lourdaud","pos":"ADJ"} ,
		{"word":"lourde","word_nosc":"lourde","lemma":"lourd","pos":"ADJ"} ,
		{"word":"lourdes","word_nosc":"lourdes","lemma":"lourd","pos":"ADJ"} ,
		{"word":"lourdingue","word_nosc":"lourdingue","lemma":"lourdingue","pos":"ADJ"} ,
		{"word":"lourdingues","word_nosc":"lourdingues","lemma":"lourdingue","pos":"ADJ"} ,
		{"word":"lourds","word_nosc":"lourds","lemma":"lourd","pos":"ADJ"} ,
		{"word":"louvet","word_nosc":"louvet","lemma":"louvet","pos":"ADJ"} ,
		{"word":"louvoyant","word_nosc":"louvoyant","lemma":"louvoyant","pos":"ADJ"} ,
		{"word":"louvoyants","word_nosc":"louvoyants","lemma":"louvoyant","pos":"ADJ"} ,
		{"word":"loué","word_nosc":"loue","lemma":"loué","pos":"ADJ"} ,
		{"word":"louée","word_nosc":"louee","lemma":"loué","pos":"ADJ"} ,
		{"word":"louées","word_nosc":"louees","lemma":"loué","pos":"ADJ"} ,
		{"word":"loués","word_nosc":"loues","lemma":"loué","pos":"ADJ"} ,
		{"word":"loyal","word_nosc":"loyal","lemma":"loyal","pos":"ADJ"} ,
		{"word":"loyale","word_nosc":"loyale","lemma":"loyal","pos":"ADJ"} ,
		{"word":"loyales","word_nosc":"loyales","lemma":"loyal","pos":"ADJ"} ,
		{"word":"loyaliste","word_nosc":"loyaliste","lemma":"loyaliste","pos":"ADJ"} ,
		{"word":"loyalistes","word_nosc":"loyalistes","lemma":"loyaliste","pos":"ADJ"} ,
		{"word":"loyaux","word_nosc":"loyaux","lemma":"loyal","pos":"ADJ"} ,
		{"word":"lu","word_nosc":"lu","lemma":"lu","pos":"ADJ"} ,
		{"word":"lubrifiant","word_nosc":"lubrifiant","lemma":"lubrifiant","pos":"ADJ"} ,
		{"word":"lubrifiante","word_nosc":"lubrifiante","lemma":"lubrifiant","pos":"ADJ"} ,
		{"word":"lubrifié","word_nosc":"lubrifie","lemma":"lubrifié","pos":"ADJ"} ,
		{"word":"lubrifiée","word_nosc":"lubrifiee","lemma":"lubrifié","pos":"ADJ"} ,
		{"word":"lubrifiées","word_nosc":"lubrifiees","lemma":"lubrifié","pos":"ADJ"} ,
		{"word":"lubrifiés","word_nosc":"lubrifies","lemma":"lubrifié","pos":"ADJ"} ,
		{"word":"lubrique","word_nosc":"lubrique","lemma":"lubrique","pos":"ADJ"} ,
		{"word":"lubriques","word_nosc":"lubriques","lemma":"lubrique","pos":"ADJ"} ,
		{"word":"lucide","word_nosc":"lucide","lemma":"lucide","pos":"ADJ"} ,
		{"word":"lucides","word_nosc":"lucides","lemma":"lucide","pos":"ADJ"} ,
		{"word":"luciférien","word_nosc":"luciferien","lemma":"luciférien","pos":"ADJ"} ,
		{"word":"luciférienne","word_nosc":"luciferienne","lemma":"luciférien","pos":"ADJ"} ,
		{"word":"lucifériens","word_nosc":"luciferiens","lemma":"luciférien","pos":"ADJ"} ,
		{"word":"lucratif","word_nosc":"lucratif","lemma":"lucratif","pos":"ADJ"} ,
		{"word":"lucratifs","word_nosc":"lucratifs","lemma":"lucratif","pos":"ADJ"} ,
		{"word":"lucrative","word_nosc":"lucrative","lemma":"lucratif","pos":"ADJ"} ,
		{"word":"lucratives","word_nosc":"lucratives","lemma":"lucratif","pos":"ADJ"} ,
		{"word":"ludique","word_nosc":"ludique","lemma":"ludique","pos":"ADJ"} ,
		{"word":"ludiques","word_nosc":"ludiques","lemma":"ludique","pos":"ADJ"} ,
		{"word":"lue","word_nosc":"lue","lemma":"lu","pos":"ADJ"} ,
		{"word":"lues","word_nosc":"lues","lemma":"lu","pos":"ADJ"} ,
		{"word":"lugubre","word_nosc":"lugubre","lemma":"lugubre","pos":"ADJ"} ,
		{"word":"lugubres","word_nosc":"lugubres","lemma":"lugubre","pos":"ADJ"} ,
		{"word":"luisant","word_nosc":"luisant","lemma":"luisant","pos":"ADJ"} ,
		{"word":"luisante","word_nosc":"luisante","lemma":"luisant","pos":"ADJ"} ,
		{"word":"luisantes","word_nosc":"luisantes","lemma":"luisant","pos":"ADJ"} ,
		{"word":"luisants","word_nosc":"luisants","lemma":"luisant","pos":"ADJ"} ,
		{"word":"luminescent","word_nosc":"luminescent","lemma":"luminescent","pos":"ADJ"} ,
		{"word":"luminescente","word_nosc":"luminescente","lemma":"luminescent","pos":"ADJ"} ,
		{"word":"luminescentes","word_nosc":"luminescentes","lemma":"luminescent","pos":"ADJ"} ,
		{"word":"luminescents","word_nosc":"luminescents","lemma":"luminescent","pos":"ADJ"} ,
		{"word":"lumineuse","word_nosc":"lumineuse","lemma":"lumineux","pos":"ADJ"} ,
		{"word":"lumineuses","word_nosc":"lumineuses","lemma":"lumineux","pos":"ADJ"} ,
		{"word":"lumineux","word_nosc":"lumineux","lemma":"lumineux","pos":"ADJ"} ,
		{"word":"lunaire","word_nosc":"lunaire","lemma":"lunaire","pos":"ADJ"} ,
		{"word":"lunaires","word_nosc":"lunaires","lemma":"lunaire","pos":"ADJ"} ,
		{"word":"lunatique","word_nosc":"lunatique","lemma":"lunatique","pos":"ADJ"} ,
		{"word":"lunatiques","word_nosc":"lunatiques","lemma":"lunatique","pos":"ADJ"} ,
		{"word":"lunetteux","word_nosc":"lunetteux","lemma":"lunetteux","pos":"ADJ"} ,
		{"word":"lunetté","word_nosc":"lunette","lemma":"lunetté","pos":"ADJ"} ,
		{"word":"luné","word_nosc":"lune","lemma":"luné","pos":"ADJ"} ,
		{"word":"lunée","word_nosc":"lunee","lemma":"luné","pos":"ADJ"} ,
		{"word":"lunés","word_nosc":"lunes","lemma":"luné","pos":"ADJ"} ,
		{"word":"lus","word_nosc":"lus","lemma":"lu","pos":"ADJ"} ,
		{"word":"lusitanienne","word_nosc":"lusitanienne","lemma":"lusitanien","pos":"ADJ"} ,
		{"word":"lustral","word_nosc":"lustral","lemma":"lustral","pos":"ADJ"} ,
		{"word":"lustrale","word_nosc":"lustrale","lemma":"lustral","pos":"ADJ"} ,
		{"word":"lustrales","word_nosc":"lustrales","lemma":"lustral","pos":"ADJ"} ,
		{"word":"lustré","word_nosc":"lustre","lemma":"lustré","pos":"ADJ"} ,
		{"word":"lustrée","word_nosc":"lustree","lemma":"lustré","pos":"ADJ"} ,
		{"word":"lustrées","word_nosc":"lustrees","lemma":"lustré","pos":"ADJ"} ,
		{"word":"lustrés","word_nosc":"lustres","lemma":"lustré","pos":"ADJ"} ,
		{"word":"luthérien","word_nosc":"lutherien","lemma":"luthérien","pos":"ADJ"} ,
		{"word":"luthérienne","word_nosc":"lutherienne","lemma":"luthérien","pos":"ADJ"} ,
		{"word":"luthériennes","word_nosc":"lutheriennes","lemma":"luthérien","pos":"ADJ"} ,
		{"word":"luthériens","word_nosc":"lutheriens","lemma":"luthérien","pos":"ADJ"} ,
		{"word":"lutin","word_nosc":"lutin","lemma":"lutin","pos":"ADJ"} ,
		{"word":"lutine","word_nosc":"lutine","lemma":"lutin","pos":"ADJ"} ,
		{"word":"lutins","word_nosc":"lutins","lemma":"lutin","pos":"ADJ"} ,
		{"word":"lutécien","word_nosc":"lutecien","lemma":"lutécien","pos":"ADJ"} ,
		{"word":"luxembourgeois","word_nosc":"luxembourgeois","lemma":"luxembourgeois","pos":"ADJ"} ,
		{"word":"luxembourgeoise","word_nosc":"luxembourgeoise","lemma":"luxembourgeois","pos":"ADJ"} ,
		{"word":"luxueuse","word_nosc":"luxueuse","lemma":"luxueux","pos":"ADJ"} ,
		{"word":"luxueuses","word_nosc":"luxueuses","lemma":"luxueux","pos":"ADJ"} ,
		{"word":"luxueux","word_nosc":"luxueux","lemma":"luxueux","pos":"ADJ"} ,
		{"word":"luxuriant","word_nosc":"luxuriant","lemma":"luxuriant","pos":"ADJ"} ,
		{"word":"luxuriante","word_nosc":"luxuriante","lemma":"luxuriant","pos":"ADJ"} ,
		{"word":"luxuriantes","word_nosc":"luxuriantes","lemma":"luxuriant","pos":"ADJ"} ,
		{"word":"luxuriants","word_nosc":"luxuriants","lemma":"luxuriant","pos":"ADJ"} ,
		{"word":"luxurieuse","word_nosc":"luxurieuse","lemma":"luxurieux","pos":"ADJ"} ,
		{"word":"luxurieux","word_nosc":"luxurieux","lemma":"luxurieux","pos":"ADJ"} ,
		{"word":"lymphatique","word_nosc":"lymphatique","lemma":"lymphatique","pos":"ADJ"} ,
		{"word":"lymphatiques","word_nosc":"lymphatiques","lemma":"lymphatique","pos":"ADJ"} ,
		{"word":"lymphocytaire","word_nosc":"lymphocytaire","lemma":"lymphocytaire","pos":"ADJ"} ,
		{"word":"lymphocytaires","word_nosc":"lymphocytaires","lemma":"lymphocytaire","pos":"ADJ"} ,
		{"word":"lymphoïde","word_nosc":"lymphoide","lemma":"lymphoïde","pos":"ADJ"} ,
		{"word":"lyonnais","word_nosc":"lyonnais","lemma":"lyonnais","pos":"ADJ"} ,
		{"word":"lyonnaise","word_nosc":"lyonnaise","lemma":"lyonnais","pos":"ADJ"} ,
		{"word":"lyophilisé","word_nosc":"lyophilise","lemma":"lyophilisé","pos":"ADJ"} ,
		{"word":"lyophilisée","word_nosc":"lyophilisee","lemma":"lyophilisé","pos":"ADJ"} ,
		{"word":"lyophilisés","word_nosc":"lyophilises","lemma":"lyophilisé","pos":"ADJ"} ,
		{"word":"lyrique","word_nosc":"lyrique","lemma":"lyrique","pos":"ADJ"} ,
		{"word":"lyriques","word_nosc":"lyriques","lemma":"lyrique","pos":"ADJ"} ,
		{"word":"lysergique","word_nosc":"lysergique","lemma":"lysergique","pos":"ADJ"} ,
		{"word":"lâche","word_nosc":"lache","lemma":"lâche","pos":"ADJ"} ,
		{"word":"lâches","word_nosc":"laches","lemma":"lâche","pos":"ADJ"} ,
		{"word":"lâché","word_nosc":"lache","lemma":"lâché","pos":"ADJ"} ,
		{"word":"lâchée","word_nosc":"lachee","lemma":"lâché","pos":"ADJ"} ,
		{"word":"lâchées","word_nosc":"lachees","lemma":"lâché","pos":"ADJ"} ,
		{"word":"lâchés","word_nosc":"laches","lemma":"lâché","pos":"ADJ"} ,
		{"word":"léché","word_nosc":"leche","lemma":"léché","pos":"ADJ"} ,
		{"word":"léchée","word_nosc":"lechee","lemma":"léché","pos":"ADJ"} ,
		{"word":"léchées","word_nosc":"lechees","lemma":"léché","pos":"ADJ"} ,
		{"word":"léchés","word_nosc":"leches","lemma":"léché","pos":"ADJ"} ,
		{"word":"légal","word_nosc":"legal","lemma":"légal","pos":"ADJ"} ,
		{"word":"légale","word_nosc":"legale","lemma":"légal","pos":"ADJ"} ,
		{"word":"légales","word_nosc":"legales","lemma":"légal","pos":"ADJ"} ,
		{"word":"légaliste","word_nosc":"legaliste","lemma":"légaliste","pos":"ADJ"} ,
		{"word":"légalistes","word_nosc":"legalistes","lemma":"légaliste","pos":"ADJ"} ,
		{"word":"légalisé","word_nosc":"legalise","lemma":"légalisé","pos":"ADJ"} ,
		{"word":"légalisée","word_nosc":"legalisee","lemma":"légalisé","pos":"ADJ"} ,
		{"word":"légaux","word_nosc":"legaux","lemma":"légal","pos":"ADJ"} ,
		{"word":"légendaire","word_nosc":"legendaire","lemma":"légendaire","pos":"ADJ"} ,
		{"word":"légendaires","word_nosc":"legendaires","lemma":"légendaire","pos":"ADJ"} ,
		{"word":"légendée","word_nosc":"legendee","lemma":"légendé","pos":"ADJ"} ,
		{"word":"léger","word_nosc":"leger","lemma":"léger","pos":"ADJ"} ,
		{"word":"légers","word_nosc":"legers","lemma":"léger","pos":"ADJ"} ,
		{"word":"législatif","word_nosc":"legislatif","lemma":"législatif","pos":"ADJ"} ,
		{"word":"législatifs","word_nosc":"legislatifs","lemma":"législatif","pos":"ADJ"} ,
		{"word":"législative","word_nosc":"legislative","lemma":"législatif","pos":"ADJ"} ,
		{"word":"législatives","word_nosc":"legislatives","lemma":"législatif","pos":"ADJ"} ,
		{"word":"légitimant","word_nosc":"legitimant","lemma":"légitimant","pos":"ADJ"} ,
		{"word":"légitime","word_nosc":"legitime","lemma":"légitime","pos":"ADJ"} ,
		{"word":"légitimes","word_nosc":"legitimes","lemma":"légitime","pos":"ADJ"} ,
		{"word":"légitimiste","word_nosc":"legitimiste","lemma":"légitimiste","pos":"ADJ"} ,
		{"word":"légitimé","word_nosc":"legitime","lemma":"légitimé","pos":"ADJ"} ,
		{"word":"légitimée","word_nosc":"legitimee","lemma":"légitimé","pos":"ADJ"} ,
		{"word":"légumineux","word_nosc":"legumineux","lemma":"légumineux","pos":"ADJ"} ,
		{"word":"légué","word_nosc":"legue","lemma":"légué","pos":"ADJ"} ,
		{"word":"légère","word_nosc":"legere","lemma":"léger","pos":"ADJ"} ,
		{"word":"légères","word_nosc":"legeres","lemma":"léger","pos":"ADJ"} ,
		{"word":"lénifiant","word_nosc":"lenifiant","lemma":"lénifiant","pos":"ADJ"} ,
		{"word":"lénifiante","word_nosc":"lenifiante","lemma":"lénifiant","pos":"ADJ"} ,
		{"word":"lénifiantes","word_nosc":"lenifiantes","lemma":"lénifiant","pos":"ADJ"} ,
		{"word":"lénifiants","word_nosc":"lenifiants","lemma":"lénifiant","pos":"ADJ"} ,
		{"word":"léniniste","word_nosc":"leniniste","lemma":"léniniste","pos":"ADJ"} ,
		{"word":"léninistes","word_nosc":"leninistes","lemma":"léniniste","pos":"ADJ"} ,
		{"word":"lénitive","word_nosc":"lenitive","lemma":"lénitif","pos":"ADJ"} ,
		{"word":"lénitives","word_nosc":"lenitives","lemma":"lénitif","pos":"ADJ"} ,
		{"word":"léonard","word_nosc":"leonard","lemma":"léonard","pos":"ADJ"} ,
		{"word":"léonin","word_nosc":"leonin","lemma":"léonin","pos":"ADJ"} ,
		{"word":"léonine","word_nosc":"leonine","lemma":"léonin","pos":"ADJ"} ,
		{"word":"léonins","word_nosc":"leonins","lemma":"léonin","pos":"ADJ"} ,
		{"word":"léopardé","word_nosc":"leoparde","lemma":"léopardé","pos":"ADJ"} ,
		{"word":"lépreuse","word_nosc":"lepreuse","lemma":"lépreux","pos":"ADJ"} ,
		{"word":"lépreuses","word_nosc":"lepreuses","lemma":"lépreux","pos":"ADJ"} ,
		{"word":"lépreux","word_nosc":"lepreux","lemma":"lépreux","pos":"ADJ"} ,
		{"word":"lésionnaire","word_nosc":"lesionnaire","lemma":"lésionnaire","pos":"ADJ"} ,
		{"word":"létal","word_nosc":"letal","lemma":"létal","pos":"ADJ"} ,
		{"word":"létale","word_nosc":"letale","lemma":"létal","pos":"ADJ"} ,
		{"word":"létales","word_nosc":"letales","lemma":"létal","pos":"ADJ"} ,
		{"word":"léthargique","word_nosc":"lethargique","lemma":"léthargique","pos":"ADJ"} ,
		{"word":"léthargiques","word_nosc":"lethargiques","lemma":"léthargique","pos":"ADJ"} ,
		{"word":"lévitique","word_nosc":"levitique","lemma":"lévitique","pos":"ADJ"} ,
		{"word":"lézardé","word_nosc":"lezarde","lemma":"lézardé","pos":"ADJ"} ,
		{"word":"lézardée","word_nosc":"lezardee","lemma":"lézardé","pos":"ADJ"} ,
		{"word":"lézardées","word_nosc":"lezardees","lemma":"lézardé","pos":"ADJ"} ,
		{"word":"lézardés","word_nosc":"lezardes","lemma":"lézardé","pos":"ADJ"} ,
		{"word":"m","word_nosc":"m","lemma":"m","pos":"ADJ:num"} ,
		{"word":"m'as-tu-vu","word_nosc":"m'as-tu-vu","lemma":"m'as-tu-vu","pos":"ADJ"} ,
		{"word":"ma","word_nosc":"ma","lemma":"ma","pos":"ADJ:pos"} ,
		{"word":"maboul","word_nosc":"maboul","lemma":"maboul","pos":"ADJ"} ,
		{"word":"maboule","word_nosc":"maboule","lemma":"maboul","pos":"ADJ"} ,
		{"word":"maboules","word_nosc":"maboules","lemma":"maboul","pos":"ADJ"} ,
		{"word":"mabouls","word_nosc":"mabouls","lemma":"maboul","pos":"ADJ"} ,
		{"word":"macabre","word_nosc":"macabre","lemma":"macabre","pos":"ADJ"} ,
		{"word":"macabres","word_nosc":"macabres","lemma":"macabre","pos":"ADJ"} ,
		{"word":"macaque","word_nosc":"macaque","lemma":"macaque","pos":"ADJ"} ,
		{"word":"macaques","word_nosc":"macaques","lemma":"macaque","pos":"ADJ"} ,
		{"word":"macaronique","word_nosc":"macaronique","lemma":"macaronique","pos":"ADJ"} ,
		{"word":"machiavélique","word_nosc":"machiavelique","lemma":"machiavélique","pos":"ADJ"} ,
		{"word":"machiavéliques","word_nosc":"machiaveliques","lemma":"machiavélique","pos":"ADJ"} ,
		{"word":"machinal","word_nosc":"machinal","lemma":"machinal","pos":"ADJ"} ,
		{"word":"machinale","word_nosc":"machinale","lemma":"machinal","pos":"ADJ"} ,
		{"word":"machinales","word_nosc":"machinales","lemma":"machinal","pos":"ADJ"} ,
		{"word":"machinaux","word_nosc":"machinaux","lemma":"machinal","pos":"ADJ"} ,
		{"word":"machinique","word_nosc":"machinique","lemma":"machinique","pos":"ADJ"} ,
		{"word":"machiste","word_nosc":"machiste","lemma":"machiste","pos":"ADJ"} ,
		{"word":"machistes","word_nosc":"machistes","lemma":"machiste","pos":"ADJ"} ,
		{"word":"macho","word_nosc":"macho","lemma":"macho","pos":"ADJ"} ,
		{"word":"machos","word_nosc":"machos","lemma":"macho","pos":"ADJ"} ,
		{"word":"macoute","word_nosc":"macoute","lemma":"macoute","pos":"ADJ"} ,
		{"word":"macoutes","word_nosc":"macoutes","lemma":"macoute","pos":"ADJ"} ,
		{"word":"macrobiotique","word_nosc":"macrobiotique","lemma":"macrobiotique","pos":"ADJ"} ,
		{"word":"macroscopique","word_nosc":"macroscopique","lemma":"macroscopique","pos":"ADJ"} ,
		{"word":"macédonien","word_nosc":"macedonien","lemma":"macédonien","pos":"ADJ"} ,
		{"word":"macédonienne","word_nosc":"macedonienne","lemma":"macédonien","pos":"ADJ"} ,
		{"word":"macédoniennes","word_nosc":"macedoniennes","lemma":"macédonien","pos":"ADJ"} ,
		{"word":"madrilène","word_nosc":"madrilene","lemma":"madrilène","pos":"ADJ"} ,
		{"word":"madrilènes","word_nosc":"madrilenes","lemma":"madrilène","pos":"ADJ"} ,
		{"word":"madré","word_nosc":"madre","lemma":"madré","pos":"ADJ"} ,
		{"word":"madrée","word_nosc":"madree","lemma":"madré","pos":"ADJ"} ,
		{"word":"maffieux","word_nosc":"maffieux","lemma":"maffieux","pos":"ADJ"} ,
		{"word":"mafflu","word_nosc":"mafflu","lemma":"mafflu","pos":"ADJ"} ,
		{"word":"mafflue","word_nosc":"mafflue","lemma":"mafflu","pos":"ADJ"} ,
		{"word":"mafflus","word_nosc":"mafflus","lemma":"mafflu","pos":"ADJ"} ,
		{"word":"mafieuse","word_nosc":"mafieuse","lemma":"mafieux","pos":"ADJ"} ,
		{"word":"mafieuses","word_nosc":"mafieuses","lemma":"mafieux","pos":"ADJ"} ,
		{"word":"mafieux","word_nosc":"mafieux","lemma":"mafieux","pos":"ADJ"} ,
		{"word":"magdalénien","word_nosc":"magdalenien","lemma":"magdalénien","pos":"ADJ"} ,
		{"word":"magdaléniennes","word_nosc":"magdaleniennes","lemma":"magdalénien","pos":"ADJ"} ,
		{"word":"magenta","word_nosc":"magenta","lemma":"magenta","pos":"ADJ"} ,
		{"word":"maghrébin","word_nosc":"maghrebin","lemma":"maghrébin","pos":"ADJ"} ,
		{"word":"maghrébine","word_nosc":"maghrebine","lemma":"maghrébin","pos":"ADJ"} ,
		{"word":"maghrébines","word_nosc":"maghrebines","lemma":"maghrébin","pos":"ADJ"} ,
		{"word":"magicienne","word_nosc":"magicienne","lemma":"magicien","pos":"ADJ"} ,
		{"word":"magique","word_nosc":"magique","lemma":"magique","pos":"ADJ"} ,
		{"word":"magiques","word_nosc":"magiques","lemma":"magique","pos":"ADJ"} ,
		{"word":"magistral","word_nosc":"magistral","lemma":"magistral","pos":"ADJ"} ,
		{"word":"magistrale","word_nosc":"magistrale","lemma":"magistral","pos":"ADJ"} ,
		{"word":"magistrales","word_nosc":"magistrales","lemma":"magistral","pos":"ADJ"} ,
		{"word":"magistraux","word_nosc":"magistraux","lemma":"magistral","pos":"ADJ"} ,
		{"word":"magmatique","word_nosc":"magmatique","lemma":"magmatique","pos":"ADJ"} ,
		{"word":"magmatiques","word_nosc":"magmatiques","lemma":"magmatique","pos":"ADJ"} ,
		{"word":"magnanime","word_nosc":"magnanime","lemma":"magnanime","pos":"ADJ"} ,
		{"word":"magnanimes","word_nosc":"magnanimes","lemma":"magnanime","pos":"ADJ"} ,
		{"word":"magnifique","word_nosc":"magnifique","lemma":"magnifique","pos":"ADJ"} ,
		{"word":"magnifiques","word_nosc":"magnifiques","lemma":"magnifique","pos":"ADJ"} ,
		{"word":"magnétique","word_nosc":"magnetique","lemma":"magnétique","pos":"ADJ"} ,
		{"word":"magnétiques","word_nosc":"magnetiques","lemma":"magnétique","pos":"ADJ"} ,
		{"word":"magouilleur","word_nosc":"magouilleur","lemma":"magouilleur","pos":"ADJ"} ,
		{"word":"magyar","word_nosc":"magyar","lemma":"magyar","pos":"ADJ"} ,
		{"word":"magyare","word_nosc":"magyare","lemma":"magyar","pos":"ADJ"} ,
		{"word":"magyares","word_nosc":"magyares","lemma":"magyar","pos":"ADJ"} ,
		{"word":"magyars","word_nosc":"magyars","lemma":"magyar","pos":"ADJ"} ,
		{"word":"mahométan","word_nosc":"mahometan","lemma":"mahométan","pos":"ADJ"} ,
		{"word":"mahométane","word_nosc":"mahometane","lemma":"mahométan","pos":"ADJ"} ,
		{"word":"mahométans","word_nosc":"mahometans","lemma":"mahométan","pos":"ADJ"} ,
		{"word":"mahousse","word_nosc":"mahousse","lemma":"mahousse","pos":"ADJ"} ,
		{"word":"mahousses","word_nosc":"mahousses","lemma":"mahousse","pos":"ADJ"} ,
		{"word":"maigre","word_nosc":"maigre","lemma":"maigre","pos":"ADJ"} ,
		{"word":"maigrelet","word_nosc":"maigrelet","lemma":"maigrelet","pos":"ADJ"} ,
		{"word":"maigrelets","word_nosc":"maigrelets","lemma":"maigrelet","pos":"ADJ"} ,
		{"word":"maigrelette","word_nosc":"maigrelette","lemma":"maigrelet","pos":"ADJ"} ,
		{"word":"maigrelettes","word_nosc":"maigrelettes","lemma":"maigrelet","pos":"ADJ"} ,
		{"word":"maigres","word_nosc":"maigres","lemma":"maigre","pos":"ADJ"} ,
		{"word":"maigrichon","word_nosc":"maigrichon","lemma":"maigrichon","pos":"ADJ"} ,
		{"word":"maigrichonne","word_nosc":"maigrichonne","lemma":"maigrichon","pos":"ADJ"} ,
		{"word":"maigrichonnes","word_nosc":"maigrichonnes","lemma":"maigrichon","pos":"ADJ"} ,
		{"word":"maigrichons","word_nosc":"maigrichons","lemma":"maigrichon","pos":"ADJ"} ,
		{"word":"maigriot","word_nosc":"maigriot","lemma":"maigriot","pos":"ADJ"} ,
		{"word":"maillé","word_nosc":"maille","lemma":"maillé","pos":"ADJ"} ,
		{"word":"maillés","word_nosc":"mailles","lemma":"maillé","pos":"ADJ"} ,
		{"word":"maint","word_nosc":"maint","lemma":"maint","pos":"ADJ:ind"} ,
		{"word":"mainte","word_nosc":"mainte","lemma":"mainte","pos":"ADJ:ind"} ,
		{"word":"maintes","word_nosc":"maintes","lemma":"maintes","pos":"ADJ:ind"} ,
		{"word":"maints","word_nosc":"maints","lemma":"maints","pos":"ADJ:ind"} ,
		{"word":"majestueuse","word_nosc":"majestueuse","lemma":"majestueux","pos":"ADJ"} ,
		{"word":"majestueuses","word_nosc":"majestueuses","lemma":"majestueux","pos":"ADJ"} ,
		{"word":"majestueux","word_nosc":"majestueux","lemma":"majestueux","pos":"ADJ"} ,
		{"word":"majeur","word_nosc":"majeur","lemma":"majeur","pos":"ADJ"} ,
		{"word":"majeure","word_nosc":"majeure","lemma":"majeur","pos":"ADJ"} ,
		{"word":"majeures","word_nosc":"majeures","lemma":"majeur","pos":"ADJ"} ,
		{"word":"majeurs","word_nosc":"majeurs","lemma":"majeur","pos":"ADJ"} ,
		{"word":"majo","word_nosc":"majo","lemma":"majo","pos":"ADJ"} ,
		{"word":"majoritaire","word_nosc":"majoritaire","lemma":"majoritaire","pos":"ADJ"} ,
		{"word":"majoritaires","word_nosc":"majoritaires","lemma":"majoritaire","pos":"ADJ"} ,
		{"word":"majuscule","word_nosc":"majuscule","lemma":"majuscule","pos":"ADJ"} ,
		{"word":"majuscules","word_nosc":"majuscules","lemma":"majuscule","pos":"ADJ"} ,
		{"word":"mal","word_nosc":"mal","lemma":"mal","pos":"ADJ"} ,
		{"word":"mal-pensante","word_nosc":"mal-pensante","lemma":"mal-pensant","pos":"ADJ"} ,
		{"word":"malabar","word_nosc":"malabar","lemma":"malabar","pos":"ADJ"} ,
		{"word":"malabars","word_nosc":"malabars","lemma":"malabar","pos":"ADJ"} ,
		{"word":"malade","word_nosc":"malade","lemma":"malade","pos":"ADJ"} ,
		{"word":"malades","word_nosc":"malades","lemma":"malade","pos":"ADJ"} ,
		{"word":"maladif","word_nosc":"maladif","lemma":"maladif","pos":"ADJ"} ,
		{"word":"maladifs","word_nosc":"maladifs","lemma":"maladif","pos":"ADJ"} ,
		{"word":"maladive","word_nosc":"maladive","lemma":"maladif","pos":"ADJ"} ,
		{"word":"maladives","word_nosc":"maladives","lemma":"maladif","pos":"ADJ"} ,
		{"word":"maladroit","word_nosc":"maladroit","lemma":"maladroit","pos":"ADJ"} ,
		{"word":"maladroite","word_nosc":"maladroite","lemma":"maladroit","pos":"ADJ"} ,
		{"word":"maladroites","word_nosc":"maladroites","lemma":"maladroit","pos":"ADJ"} ,
		{"word":"maladroits","word_nosc":"maladroits","lemma":"maladroit","pos":"ADJ"} ,
		{"word":"malaire","word_nosc":"malaire","lemma":"malaire","pos":"ADJ"} ,
		{"word":"malais","word_nosc":"malais","lemma":"malais","pos":"ADJ"} ,
		{"word":"malaise","word_nosc":"malaise","lemma":"malaise","pos":"ADJ"} ,
		{"word":"malaises","word_nosc":"malaises","lemma":"malaise","pos":"ADJ"} ,
		{"word":"malaisien","word_nosc":"malaisien","lemma":"malaisien","pos":"ADJ"} ,
		{"word":"malaisienne","word_nosc":"malaisienne","lemma":"malaisien","pos":"ADJ"} ,
		{"word":"malaisé","word_nosc":"malaise","lemma":"malaisé","pos":"ADJ"} ,
		{"word":"malaisée","word_nosc":"malaisee","lemma":"malaisé","pos":"ADJ"} ,
		{"word":"malaisées","word_nosc":"malaisees","lemma":"malaisé","pos":"ADJ"} ,
		{"word":"malaisés","word_nosc":"malaises","lemma":"malaisé","pos":"ADJ"} ,
		{"word":"malappris","word_nosc":"malappris","lemma":"malappris","pos":"ADJ"} ,
		{"word":"malapprise","word_nosc":"malapprise","lemma":"malappris","pos":"ADJ"} ,
		{"word":"malavisé","word_nosc":"malavise","lemma":"malavisé","pos":"ADJ"} ,
		{"word":"malavisée","word_nosc":"malavisee","lemma":"malavisé","pos":"ADJ"} ,
		{"word":"malchanceuse","word_nosc":"malchanceuse","lemma":"malchanceux","pos":"ADJ"} ,
		{"word":"malchanceuses","word_nosc":"malchanceuses","lemma":"malchanceux","pos":"ADJ"} ,
		{"word":"malchanceux","word_nosc":"malchanceux","lemma":"malchanceux","pos":"ADJ"} ,
		{"word":"malcommode","word_nosc":"malcommode","lemma":"malcommode","pos":"ADJ"} ,
		{"word":"malcommodes","word_nosc":"malcommodes","lemma":"malcommode","pos":"ADJ"} ,
		{"word":"malcontent","word_nosc":"malcontent","lemma":"malcontent","pos":"ADJ"} ,
		{"word":"malcontents","word_nosc":"malcontents","lemma":"malcontent","pos":"ADJ"} ,
		{"word":"male","word_nosc":"male","lemma":"mal","pos":"ADJ"} ,
		{"word":"malencontreuse","word_nosc":"malencontreuse","lemma":"malencontreux","pos":"ADJ"} ,
		{"word":"malencontreuses","word_nosc":"malencontreuses","lemma":"malencontreux","pos":"ADJ"} ,
		{"word":"malencontreux","word_nosc":"malencontreux","lemma":"malencontreux","pos":"ADJ"} ,
		{"word":"malentendant","word_nosc":"malentendant","lemma":"malentendant","pos":"ADJ"} ,
		{"word":"malentendante","word_nosc":"malentendante","lemma":"malentendant","pos":"ADJ"} ,
		{"word":"males","word_nosc":"males","lemma":"mal","pos":"ADJ"} ,
		{"word":"malfaisant","word_nosc":"malfaisant","lemma":"malfaisant","pos":"ADJ"} ,
		{"word":"malfaisante","word_nosc":"malfaisante","lemma":"malfaisant","pos":"ADJ"} ,
		{"word":"malfaisantes","word_nosc":"malfaisantes","lemma":"malfaisant","pos":"ADJ"} ,
		{"word":"malfaisants","word_nosc":"malfaisants","lemma":"malfaisant","pos":"ADJ"} ,
		{"word":"malfamé","word_nosc":"malfame","lemma":"malfamé","pos":"ADJ"} ,
		{"word":"malfamée","word_nosc":"malfamee","lemma":"malfamé","pos":"ADJ"} ,
		{"word":"malfamés","word_nosc":"malfames","lemma":"malfamé","pos":"ADJ"} ,
		{"word":"malformé","word_nosc":"malforme","lemma":"malformé","pos":"ADJ"} ,
		{"word":"malgache","word_nosc":"malgache","lemma":"malgache","pos":"ADJ"} ,
		{"word":"malgaches","word_nosc":"malgaches","lemma":"malgache","pos":"ADJ"} ,
		{"word":"malgracieuse","word_nosc":"malgracieuse","lemma":"malgracieux","pos":"ADJ"} ,
		{"word":"malgracieux","word_nosc":"malgracieux","lemma":"malgracieux","pos":"ADJ"} ,
		{"word":"malhabile","word_nosc":"malhabile","lemma":"malhabile","pos":"ADJ"} ,
		{"word":"malhabiles","word_nosc":"malhabiles","lemma":"malhabile","pos":"ADJ"} ,
		{"word":"malheureuse","word_nosc":"malheureuse","lemma":"malheureux","pos":"ADJ"} ,
		{"word":"malheureuses","word_nosc":"malheureuses","lemma":"malheureux","pos":"ADJ"} ,
		{"word":"malheureux","word_nosc":"malheureux","lemma":"malheureux","pos":"ADJ"} ,
		{"word":"malhonnête","word_nosc":"malhonnete","lemma":"malhonnête","pos":"ADJ"} ,
		{"word":"malhonnêtes","word_nosc":"malhonnetes","lemma":"malhonnête","pos":"ADJ"} ,
		{"word":"malicieuse","word_nosc":"malicieuse","lemma":"malicieux","pos":"ADJ"} ,
		{"word":"malicieuses","word_nosc":"malicieuses","lemma":"malicieux","pos":"ADJ"} ,
		{"word":"malicieux","word_nosc":"malicieux","lemma":"malicieux","pos":"ADJ"} ,
		{"word":"malienne","word_nosc":"malienne","lemma":"malien","pos":"ADJ"} ,
		{"word":"maliens","word_nosc":"maliens","lemma":"malien","pos":"ADJ"} ,
		{"word":"maligne","word_nosc":"maligne","lemma":"malin","pos":"ADJ"} ,
		{"word":"malignes","word_nosc":"malignes","lemma":"malin","pos":"ADJ"} ,
		{"word":"malin","word_nosc":"malin","lemma":"malin","pos":"ADJ"} ,
		{"word":"malingre","word_nosc":"malingre","lemma":"malingre","pos":"ADJ"} ,
		{"word":"malingres","word_nosc":"malingres","lemma":"malingre","pos":"ADJ"} ,
		{"word":"malins","word_nosc":"malins","lemma":"malin","pos":"ADJ"} ,
		{"word":"malintentionné","word_nosc":"malintentionne","lemma":"malintentionné","pos":"ADJ"} ,
		{"word":"malintentionnée","word_nosc":"malintentionnee","lemma":"malintentionné","pos":"ADJ"} ,
		{"word":"malintentionnés","word_nosc":"malintentionnes","lemma":"malintentionné","pos":"ADJ"} ,
		{"word":"mallarméen","word_nosc":"mallarmeen","lemma":"mallarméen","pos":"ADJ"} ,
		{"word":"malléable","word_nosc":"malleable","lemma":"malléable","pos":"ADJ"} ,
		{"word":"malléables","word_nosc":"malleables","lemma":"malléable","pos":"ADJ"} ,
		{"word":"malléolaire","word_nosc":"malleolaire","lemma":"malléolaire","pos":"ADJ"} ,
		{"word":"malodorant","word_nosc":"malodorant","lemma":"malodorant","pos":"ADJ"} ,
		{"word":"malodorante","word_nosc":"malodorante","lemma":"malodorant","pos":"ADJ"} ,
		{"word":"malodorantes","word_nosc":"malodorantes","lemma":"malodorant","pos":"ADJ"} ,
		{"word":"malodorants","word_nosc":"malodorants","lemma":"malodorant","pos":"ADJ"} ,
		{"word":"malouin","word_nosc":"malouin","lemma":"malouin","pos":"ADJ"} ,
		{"word":"malouine","word_nosc":"malouine","lemma":"malouin","pos":"ADJ"} ,
		{"word":"malouines","word_nosc":"malouines","lemma":"malouin","pos":"ADJ"} ,
		{"word":"malouins","word_nosc":"malouins","lemma":"malouin","pos":"ADJ"} ,
		{"word":"malpoli","word_nosc":"malpoli","lemma":"malpoli","pos":"ADJ"} ,
		{"word":"malpolie","word_nosc":"malpolie","lemma":"malpoli","pos":"ADJ"} ,
		{"word":"malpolis","word_nosc":"malpolis","lemma":"malpoli","pos":"ADJ"} ,
		{"word":"malpropre","word_nosc":"malpropre","lemma":"malpropre","pos":"ADJ"} ,
		{"word":"malpropres","word_nosc":"malpropres","lemma":"malpropre","pos":"ADJ"} ,
		{"word":"malsain","word_nosc":"malsain","lemma":"malsain","pos":"ADJ"} ,
		{"word":"malsaine","word_nosc":"malsaine","lemma":"malsain","pos":"ADJ"} ,
		{"word":"malsaines","word_nosc":"malsaines","lemma":"malsain","pos":"ADJ"} ,
		{"word":"malsains","word_nosc":"malsains","lemma":"malsain","pos":"ADJ"} ,
		{"word":"malsonnants","word_nosc":"malsonnants","lemma":"malsonnant","pos":"ADJ"} ,
		{"word":"malséant","word_nosc":"malseant","lemma":"malséant","pos":"ADJ"} ,
		{"word":"malséante","word_nosc":"malseante","lemma":"malséant","pos":"ADJ"} ,
		{"word":"maltais","word_nosc":"maltais","lemma":"maltais","pos":"ADJ"} ,
		{"word":"maltaises","word_nosc":"maltaises","lemma":"maltais","pos":"ADJ"} ,
		{"word":"malveillant","word_nosc":"malveillant","lemma":"malveillant","pos":"ADJ"} ,
		{"word":"malveillante","word_nosc":"malveillante","lemma":"malveillant","pos":"ADJ"} ,
		{"word":"malveillantes","word_nosc":"malveillantes","lemma":"malveillant","pos":"ADJ"} ,
		{"word":"malveillants","word_nosc":"malveillants","lemma":"malveillant","pos":"ADJ"} ,
		{"word":"malvenu","word_nosc":"malvenu","lemma":"malvenu","pos":"ADJ"} ,
		{"word":"malvenue","word_nosc":"malvenue","lemma":"malvenu","pos":"ADJ"} ,
		{"word":"malvenus","word_nosc":"malvenus","lemma":"malvenu","pos":"ADJ"} ,
		{"word":"maléficié","word_nosc":"maleficie","lemma":"maléficié","pos":"ADJ"} ,
		{"word":"maléfique","word_nosc":"malefique","lemma":"maléfique","pos":"ADJ"} ,
		{"word":"maléfiques","word_nosc":"malefiques","lemma":"maléfique","pos":"ADJ"} ,
		{"word":"mamelonnée","word_nosc":"mamelonnee","lemma":"mamelonné","pos":"ADJ"} ,
		{"word":"mamelu","word_nosc":"mamelu","lemma":"mamelu","pos":"ADJ"} ,
		{"word":"mamelue","word_nosc":"mamelue","lemma":"mamelu","pos":"ADJ"} ,
		{"word":"mamelues","word_nosc":"mamelues","lemma":"mamelu","pos":"ADJ"} ,
		{"word":"mamelus","word_nosc":"mamelus","lemma":"mamelu","pos":"ADJ"} ,
		{"word":"mammaire","word_nosc":"mammaire","lemma":"mammaire","pos":"ADJ"} ,
		{"word":"mammaires","word_nosc":"mammaires","lemma":"mammaire","pos":"ADJ"} ,
		{"word":"mammifère","word_nosc":"mammifere","lemma":"mammifère","pos":"ADJ"} ,
		{"word":"mammifères","word_nosc":"mammiferes","lemma":"mammifère","pos":"ADJ"} ,
		{"word":"manceau","word_nosc":"manceau","lemma":"manceau","pos":"ADJ"} ,
		{"word":"manchot","word_nosc":"manchot","lemma":"manchot","pos":"ADJ"} ,
		{"word":"manchote","word_nosc":"manchote","lemma":"manchot","pos":"ADJ"} ,
		{"word":"manchots","word_nosc":"manchots","lemma":"manchot","pos":"ADJ"} ,
		{"word":"mandarine","word_nosc":"mandarine","lemma":"mandarine","pos":"ADJ"} ,
		{"word":"mandataire","word_nosc":"mandataire","lemma":"mandataire","pos":"ADJ"} ,
		{"word":"mandataires","word_nosc":"mandataires","lemma":"mandataire","pos":"ADJ"} ,
		{"word":"mandchoue","word_nosc":"mandchoue","lemma":"mandchou","pos":"ADJ"} ,
		{"word":"mandibulaire","word_nosc":"mandibulaire","lemma":"mandibulaire","pos":"ADJ"} ,
		{"word":"mandingue","word_nosc":"mandingue","lemma":"mandingue","pos":"ADJ"} ,
		{"word":"mange-tout","word_nosc":"mange-tout","lemma":"mange-tout","pos":"ADJ"} ,
		{"word":"mangeable","word_nosc":"mangeable","lemma":"mangeable","pos":"ADJ"} ,
		{"word":"mangeables","word_nosc":"mangeables","lemma":"mangeable","pos":"ADJ"} ,
		{"word":"maniable","word_nosc":"maniable","lemma":"maniable","pos":"ADJ"} ,
		{"word":"maniables","word_nosc":"maniables","lemma":"maniable","pos":"ADJ"} ,
		{"word":"maniaco-dépressif","word_nosc":"maniaco-depressif","lemma":"maniaco-dépressif","pos":"ADJ"} ,
		{"word":"maniaco-dépressifs","word_nosc":"maniaco-depressifs","lemma":"maniaco-dépressif","pos":"ADJ"} ,
		{"word":"maniaco-dépressive","word_nosc":"maniaco-depressive","lemma":"maniaco-dépressif","pos":"ADJ"} ,
		{"word":"maniacodépressif","word_nosc":"maniacodepressif","lemma":"maniacodépressif","pos":"ADJ"} ,
		{"word":"maniacodépressive","word_nosc":"maniacodepressive","lemma":"maniacodépressif","pos":"ADJ"} ,
		{"word":"maniaque","word_nosc":"maniaque","lemma":"maniaque","pos":"ADJ"} ,
		{"word":"maniaques","word_nosc":"maniaques","lemma":"maniaque","pos":"ADJ"} ,
		{"word":"manichéen","word_nosc":"manicheen","lemma":"manichéen","pos":"ADJ"} ,
		{"word":"manifeste","word_nosc":"manifeste","lemma":"manifeste","pos":"ADJ"} ,
		{"word":"manifestes","word_nosc":"manifestes","lemma":"manifeste","pos":"ADJ"} ,
		{"word":"manipulable","word_nosc":"manipulable","lemma":"manipulable","pos":"ADJ"} ,
		{"word":"manipulables","word_nosc":"manipulables","lemma":"manipulable","pos":"ADJ"} ,
		{"word":"maniériste","word_nosc":"manieriste","lemma":"maniériste","pos":"ADJ"} ,
		{"word":"maniéré","word_nosc":"maniere","lemma":"maniéré","pos":"ADJ"} ,
		{"word":"maniérée","word_nosc":"manieree","lemma":"maniéré","pos":"ADJ"} ,
		{"word":"maniérées","word_nosc":"manierees","lemma":"maniéré","pos":"ADJ"} ,
		{"word":"maniérés","word_nosc":"manieres","lemma":"maniéré","pos":"ADJ"} ,
		{"word":"manoeuvrable","word_nosc":"manoeuvrable","lemma":"manoeuvrable","pos":"ADJ"} ,
		{"word":"manoeuvrier","word_nosc":"manoeuvrier","lemma":"manoeuvrier","pos":"ADJ"} ,
		{"word":"manoeuvrière","word_nosc":"manoeuvriere","lemma":"manoeuvrier","pos":"ADJ"} ,
		{"word":"manoeuvrières","word_nosc":"manoeuvrieres","lemma":"manoeuvrier","pos":"ADJ"} ,
		{"word":"manquant","word_nosc":"manquant","lemma":"manquant","pos":"ADJ"} ,
		{"word":"manquante","word_nosc":"manquante","lemma":"manquant","pos":"ADJ"} ,
		{"word":"manquantes","word_nosc":"manquantes","lemma":"manquant","pos":"ADJ"} ,
		{"word":"manquants","word_nosc":"manquants","lemma":"manquant","pos":"ADJ"} ,
		{"word":"manqué","word_nosc":"manque","lemma":"manqué","pos":"ADJ"} ,
		{"word":"manquée","word_nosc":"manquee","lemma":"manqué","pos":"ADJ"} ,
		{"word":"manquées","word_nosc":"manquees","lemma":"manqué","pos":"ADJ"} ,
		{"word":"manqués","word_nosc":"manques","lemma":"manqué","pos":"ADJ"} ,
		{"word":"mansardé","word_nosc":"mansarde","lemma":"mansardé","pos":"ADJ"} ,
		{"word":"mansardée","word_nosc":"mansardee","lemma":"mansardé","pos":"ADJ"} ,
		{"word":"mansardées","word_nosc":"mansardees","lemma":"mansardé","pos":"ADJ"} ,
		{"word":"manuel","word_nosc":"manuel","lemma":"manuel","pos":"ADJ"} ,
		{"word":"manuelle","word_nosc":"manuelle","lemma":"manuel","pos":"ADJ"} ,
		{"word":"manuelles","word_nosc":"manuelles","lemma":"manuel","pos":"ADJ"} ,
		{"word":"manuels","word_nosc":"manuels","lemma":"manuel","pos":"ADJ"} ,
		{"word":"manufacturiers","word_nosc":"manufacturiers","lemma":"manufacturier","pos":"ADJ"} ,
		{"word":"manuscrit","word_nosc":"manuscrit","lemma":"manuscrit","pos":"ADJ"} ,
		{"word":"manuscrite","word_nosc":"manuscrite","lemma":"manuscrit","pos":"ADJ"} ,
		{"word":"manuscrites","word_nosc":"manuscrites","lemma":"manuscrit","pos":"ADJ"} ,
		{"word":"manuscrits","word_nosc":"manuscrits","lemma":"manuscrit","pos":"ADJ"} ,
		{"word":"manuélin","word_nosc":"manuelin","lemma":"manuélin","pos":"ADJ"} ,
		{"word":"maori","word_nosc":"maori","lemma":"maori","pos":"ADJ"} ,
		{"word":"maorie","word_nosc":"maorie","lemma":"maori","pos":"ADJ"} ,
		{"word":"maous","word_nosc":"maous","lemma":"maous","pos":"ADJ"} ,
		{"word":"maousse","word_nosc":"maousse","lemma":"maousse","pos":"ADJ"} ,
		{"word":"maoïste","word_nosc":"maoiste","lemma":"maoïste","pos":"ADJ"} ,
		{"word":"marbrier","word_nosc":"marbrier","lemma":"marbrier","pos":"ADJ"} ,
		{"word":"marbré","word_nosc":"marbre","lemma":"marbré","pos":"ADJ"} ,
		{"word":"marbrée","word_nosc":"marbree","lemma":"marbré","pos":"ADJ"} ,
		{"word":"marbrées","word_nosc":"marbrees","lemma":"marbré","pos":"ADJ"} ,
		{"word":"marbrés","word_nosc":"marbres","lemma":"marbré","pos":"ADJ"} ,
		{"word":"marchand","word_nosc":"marchand","lemma":"marchand","pos":"ADJ"} ,
		{"word":"marchande","word_nosc":"marchande","lemma":"marchand","pos":"ADJ"} ,
		{"word":"marchandes","word_nosc":"marchandes","lemma":"marchand","pos":"ADJ"} ,
		{"word":"marchands","word_nosc":"marchands","lemma":"marchand","pos":"ADJ"} ,
		{"word":"marchant","word_nosc":"marchant","lemma":"marchant","pos":"ADJ"} ,
		{"word":"marchante","word_nosc":"marchante","lemma":"marchant","pos":"ADJ"} ,
		{"word":"marchantes","word_nosc":"marchantes","lemma":"marchant","pos":"ADJ"} ,
		{"word":"marconi","word_nosc":"marconi","lemma":"marconi","pos":"ADJ"} ,
		{"word":"marengo","word_nosc":"marengo","lemma":"marengo","pos":"ADJ"} ,
		{"word":"marginal","word_nosc":"marginal","lemma":"marginal","pos":"ADJ"} ,
		{"word":"marginale","word_nosc":"marginale","lemma":"marginal","pos":"ADJ"} ,
		{"word":"marginales","word_nosc":"marginales","lemma":"marginal","pos":"ADJ"} ,
		{"word":"marginaux","word_nosc":"marginaux","lemma":"marginal","pos":"ADJ"} ,
		{"word":"mariable","word_nosc":"mariable","lemma":"mariable","pos":"ADJ"} ,
		{"word":"mariales","word_nosc":"mariales","lemma":"marial","pos":"ADJ"} ,
		{"word":"marin","word_nosc":"marin","lemma":"marin","pos":"ADJ"} ,
		{"word":"marine","word_nosc":"marine","lemma":"marine","pos":"ADJ"} ,
		{"word":"marines","word_nosc":"marines","lemma":"marine","pos":"ADJ"} ,
		{"word":"marins","word_nosc":"marins","lemma":"marin","pos":"ADJ"} ,
		{"word":"mariné","word_nosc":"marine","lemma":"mariné","pos":"ADJ"} ,
		{"word":"marinées","word_nosc":"marinees","lemma":"mariné","pos":"ADJ"} ,
		{"word":"marinés","word_nosc":"marines","lemma":"mariné","pos":"ADJ"} ,
		{"word":"mariol","word_nosc":"mariol","lemma":"mariol","pos":"ADJ"} ,
		{"word":"mariole","word_nosc":"mariole","lemma":"mariole","pos":"ADJ"} ,
		{"word":"marioles","word_nosc":"marioles","lemma":"mariole","pos":"ADJ"} ,
		{"word":"mariolle","word_nosc":"mariolle","lemma":"mariolle","pos":"ADJ"} ,
		{"word":"mariolles","word_nosc":"mariolles","lemma":"mariolle","pos":"ADJ"} ,
		{"word":"marital","word_nosc":"marital","lemma":"marital","pos":"ADJ"} ,
		{"word":"maritale","word_nosc":"maritale","lemma":"marital","pos":"ADJ"} ,
		{"word":"maritales","word_nosc":"maritales","lemma":"marital","pos":"ADJ"} ,
		{"word":"maritaux","word_nosc":"maritaux","lemma":"marital","pos":"ADJ"} ,
		{"word":"maritime","word_nosc":"maritime","lemma":"maritime","pos":"ADJ"} ,
		{"word":"maritimes","word_nosc":"maritimes","lemma":"maritime","pos":"ADJ"} ,
		{"word":"marié","word_nosc":"marie","lemma":"marié","pos":"ADJ"} ,
		{"word":"mariée","word_nosc":"mariee","lemma":"marié","pos":"ADJ"} ,
		{"word":"mariées","word_nosc":"mariees","lemma":"marié","pos":"ADJ"} ,
		{"word":"mariés","word_nosc":"maries","lemma":"marié","pos":"ADJ"} ,
		{"word":"marle","word_nosc":"marle","lemma":"marle","pos":"ADJ"} ,
		{"word":"marles","word_nosc":"marles","lemma":"marle","pos":"ADJ"} ,
		{"word":"marmiteuse","word_nosc":"marmiteuse","lemma":"marmiteux","pos":"ADJ"} ,
		{"word":"marmiteux","word_nosc":"marmiteux","lemma":"marmiteux","pos":"ADJ"} ,
		{"word":"marmoréen","word_nosc":"marmoreen","lemma":"marmoréen","pos":"ADJ"} ,
		{"word":"marmoréenne","word_nosc":"marmoreenne","lemma":"marmoréen","pos":"ADJ"} ,
		{"word":"marmoréens","word_nosc":"marmoreens","lemma":"marmoréen","pos":"ADJ"} ,
		{"word":"marnaise","word_nosc":"marnaise","lemma":"marnais","pos":"ADJ"} ,
		{"word":"marneuse","word_nosc":"marneuse","lemma":"marneux","pos":"ADJ"} ,
		{"word":"marocain","word_nosc":"marocain","lemma":"marocain","pos":"ADJ"} ,
		{"word":"marocaine","word_nosc":"marocaine","lemma":"marocain","pos":"ADJ"} ,
		{"word":"marocaines","word_nosc":"marocaines","lemma":"marocain","pos":"ADJ"} ,
		{"word":"marocains","word_nosc":"marocains","lemma":"marocain","pos":"ADJ"} ,
		{"word":"maronite","word_nosc":"maronite","lemma":"maronite","pos":"ADJ"} ,
		{"word":"maronites","word_nosc":"maronites","lemma":"maronite","pos":"ADJ"} ,
		{"word":"marquant","word_nosc":"marquant","lemma":"marquant","pos":"ADJ"} ,
		{"word":"marquante","word_nosc":"marquante","lemma":"marquant","pos":"ADJ"} ,
		{"word":"marquantes","word_nosc":"marquantes","lemma":"marquant","pos":"ADJ"} ,
		{"word":"marquants","word_nosc":"marquants","lemma":"marquant","pos":"ADJ"} ,
		{"word":"marquisien","word_nosc":"marquisien","lemma":"marquisien","pos":"ADJ"} ,
		{"word":"marquisiens","word_nosc":"marquisiens","lemma":"marquisien","pos":"ADJ"} ,
		{"word":"marqué","word_nosc":"marque","lemma":"marqué","pos":"ADJ"} ,
		{"word":"marquée","word_nosc":"marquee","lemma":"marqué","pos":"ADJ"} ,
		{"word":"marquées","word_nosc":"marquees","lemma":"marqué","pos":"ADJ"} ,
		{"word":"marqués","word_nosc":"marques","lemma":"marqué","pos":"ADJ"} ,
		{"word":"marrant","word_nosc":"marrant","lemma":"marrant","pos":"ADJ"} ,
		{"word":"marrante","word_nosc":"marrante","lemma":"marrant","pos":"ADJ"} ,
		{"word":"marrantes","word_nosc":"marrantes","lemma":"marrant","pos":"ADJ"} ,
		{"word":"marrants","word_nosc":"marrants","lemma":"marrant","pos":"ADJ"} ,
		{"word":"marri","word_nosc":"marri","lemma":"marri","pos":"ADJ"} ,
		{"word":"marrie","word_nosc":"marrie","lemma":"marri","pos":"ADJ"} ,
		{"word":"marris","word_nosc":"marris","lemma":"marri","pos":"ADJ"} ,
		{"word":"marron","word_nosc":"marron","lemma":"marron","pos":"ADJ"} ,
		{"word":"marronnasse","word_nosc":"marronnasse","lemma":"marronnasse","pos":"ADJ"} ,
		{"word":"marseillais","word_nosc":"marseillais","lemma":"marseillais","pos":"ADJ"} ,
		{"word":"marseillaise","word_nosc":"marseillaise","lemma":"marseillais","pos":"ADJ"} ,
		{"word":"marseillaises","word_nosc":"marseillaises","lemma":"marseillais","pos":"ADJ"} ,
		{"word":"marsupiaux","word_nosc":"marsupiaux","lemma":"marsupial","pos":"ADJ"} ,
		{"word":"martial","word_nosc":"martial","lemma":"martial","pos":"ADJ"} ,
		{"word":"martiale","word_nosc":"martiale","lemma":"martial","pos":"ADJ"} ,
		{"word":"martiales","word_nosc":"martiales","lemma":"martial","pos":"ADJ"} ,
		{"word":"martiaux","word_nosc":"martiaux","lemma":"martial","pos":"ADJ"} ,
		{"word":"martien","word_nosc":"martien","lemma":"martien","pos":"ADJ"} ,
		{"word":"martienne","word_nosc":"martienne","lemma":"martien","pos":"ADJ"} ,
		{"word":"martiennes","word_nosc":"martiennes","lemma":"martien","pos":"ADJ"} ,
		{"word":"martiens","word_nosc":"martiens","lemma":"martien","pos":"ADJ"} ,
		{"word":"martiniquais","word_nosc":"martiniquais","lemma":"martiniquais","pos":"ADJ"} ,
		{"word":"martiniquaise","word_nosc":"martiniquaise","lemma":"martiniquais","pos":"ADJ"} ,
		{"word":"martyr","word_nosc":"martyr","lemma":"martyr","pos":"ADJ"} ,
		{"word":"martyre","word_nosc":"martyre","lemma":"martyr","pos":"ADJ"} ,
		{"word":"martyres","word_nosc":"martyres","lemma":"martyr","pos":"ADJ"} ,
		{"word":"martyrs","word_nosc":"martyrs","lemma":"martyr","pos":"ADJ"} ,
		{"word":"marxiste","word_nosc":"marxiste","lemma":"marxiste","pos":"ADJ"} ,
		{"word":"marxiste-léniniste","word_nosc":"marxiste-leniniste","lemma":"marxiste-léniniste","pos":"ADJ"} ,
		{"word":"marxistes","word_nosc":"marxistes","lemma":"marxiste","pos":"ADJ"} ,
		{"word":"marxistes-léninistes","word_nosc":"marxistes-leninistes","lemma":"marxiste-léniniste","pos":"ADJ"} ,
		{"word":"marécageuse","word_nosc":"marecageuse","lemma":"marécageux","pos":"ADJ"} ,
		{"word":"marécageuses","word_nosc":"marecageuses","lemma":"marécageux","pos":"ADJ"} ,
		{"word":"marécageux","word_nosc":"marecageux","lemma":"marécageux","pos":"ADJ"} ,
		{"word":"marémotrice","word_nosc":"maremotrice","lemma":"marémoteur","pos":"ADJ"} ,
		{"word":"masaï","word_nosc":"masai","lemma":"masaï","pos":"ADJ"} ,
		{"word":"masculin","word_nosc":"masculin","lemma":"masculin","pos":"ADJ"} ,
		{"word":"masculine","word_nosc":"masculine","lemma":"masculin","pos":"ADJ"} ,
		{"word":"masculines","word_nosc":"masculines","lemma":"masculin","pos":"ADJ"} ,
		{"word":"masculins","word_nosc":"masculins","lemma":"masculin","pos":"ADJ"} ,
		{"word":"maso","word_nosc":"maso","lemma":"maso","pos":"ADJ"} ,
		{"word":"masochiste","word_nosc":"masochiste","lemma":"masochiste","pos":"ADJ"} ,
		{"word":"masochistes","word_nosc":"masochistes","lemma":"masochiste","pos":"ADJ"} ,
		{"word":"masos","word_nosc":"masos","lemma":"maso","pos":"ADJ"} ,
		{"word":"masqué","word_nosc":"masque","lemma":"masqué","pos":"ADJ"} ,
		{"word":"masquée","word_nosc":"masquee","lemma":"masqué","pos":"ADJ"} ,
		{"word":"masquées","word_nosc":"masquees","lemma":"masqué","pos":"ADJ"} ,
		{"word":"masqués","word_nosc":"masques","lemma":"masqué","pos":"ADJ"} ,
		{"word":"massacrant","word_nosc":"massacrant","lemma":"massacrant","pos":"ADJ"} ,
		{"word":"massacrante","word_nosc":"massacrante","lemma":"massacrant","pos":"ADJ"} ,
		{"word":"massif","word_nosc":"massif","lemma":"massif","pos":"ADJ"} ,
		{"word":"massifs","word_nosc":"massifs","lemma":"massif","pos":"ADJ"} ,
		{"word":"massive","word_nosc":"massive","lemma":"massif","pos":"ADJ"} ,
		{"word":"massives","word_nosc":"massives","lemma":"massif","pos":"ADJ"} ,
		{"word":"mastar","word_nosc":"mastar","lemma":"mastar","pos":"ADJ"} ,
		{"word":"mastard","word_nosc":"mastard","lemma":"mastard","pos":"ADJ"} ,
		{"word":"mastards","word_nosc":"mastards","lemma":"mastard","pos":"ADJ"} ,
		{"word":"mastic","word_nosc":"mastic","lemma":"mastic","pos":"ADJ"} ,
		{"word":"masticateurs","word_nosc":"masticateurs","lemma":"masticateur","pos":"ADJ"} ,
		{"word":"mastoc","word_nosc":"mastoc","lemma":"mastoc","pos":"ADJ"} ,
		{"word":"mastoïde","word_nosc":"mastoide","lemma":"mastoïde","pos":"ADJ"} ,
		{"word":"masturbateur","word_nosc":"masturbateur","lemma":"masturbateur","pos":"ADJ"} ,
		{"word":"masturbatoire","word_nosc":"masturbatoire","lemma":"masturbatoire","pos":"ADJ"} ,
		{"word":"masturbatoires","word_nosc":"masturbatoires","lemma":"masturbatoire","pos":"ADJ"} ,
		{"word":"mat","word_nosc":"mat","lemma":"mat","pos":"ADJ"} ,
		{"word":"mat'","word_nosc":"mat'","lemma":"mat","pos":"ADJ"} ,
		{"word":"mate","word_nosc":"mate","lemma":"mat","pos":"ADJ"} ,
		{"word":"matelassé","word_nosc":"matelasse","lemma":"matelassé","pos":"ADJ"} ,
		{"word":"matelassée","word_nosc":"matelassee","lemma":"matelassé","pos":"ADJ"} ,
		{"word":"maternel","word_nosc":"maternel","lemma":"maternel","pos":"ADJ"} ,
		{"word":"maternelle","word_nosc":"maternelle","lemma":"maternel","pos":"ADJ"} ,
		{"word":"maternelles","word_nosc":"maternelles","lemma":"maternel","pos":"ADJ"} ,
		{"word":"maternels","word_nosc":"maternels","lemma":"maternel","pos":"ADJ"} ,
		{"word":"mates","word_nosc":"mates","lemma":"mat","pos":"ADJ"} ,
		{"word":"mathématique","word_nosc":"mathematique","lemma":"mathématique","pos":"ADJ"} ,
		{"word":"mathématiques","word_nosc":"mathematiques","lemma":"mathématique","pos":"ADJ"} ,
		{"word":"mati","word_nosc":"mati","lemma":"mati","pos":"ADJ"} ,
		{"word":"matinal","word_nosc":"matinal","lemma":"matinal","pos":"ADJ"} ,
		{"word":"matinale","word_nosc":"matinale","lemma":"matinal","pos":"ADJ"} ,
		{"word":"matinales","word_nosc":"matinales","lemma":"matinal","pos":"ADJ"} ,
		{"word":"matinaux","word_nosc":"matinaux","lemma":"matinal","pos":"ADJ"} ,
		{"word":"matineux","word_nosc":"matineux","lemma":"matineux","pos":"ADJ"} ,
		{"word":"matinée","word_nosc":"matinee","lemma":"matinée","pos":"ADJ"} ,
		{"word":"matinées","word_nosc":"matinees","lemma":"matinée","pos":"ADJ"} ,
		{"word":"matois","word_nosc":"matois","lemma":"matois","pos":"ADJ"} ,
		{"word":"matoise","word_nosc":"matoise","lemma":"matois","pos":"ADJ"} ,
		{"word":"matriarcal","word_nosc":"matriarcal","lemma":"matriarcal","pos":"ADJ"} ,
		{"word":"matriarcale","word_nosc":"matriarcale","lemma":"matriarcal","pos":"ADJ"} ,
		{"word":"matriarcales","word_nosc":"matriarcales","lemma":"matriarcal","pos":"ADJ"} ,
		{"word":"matriciel","word_nosc":"matriciel","lemma":"matriciel","pos":"ADJ"} ,
		{"word":"matricielle","word_nosc":"matricielle","lemma":"matriciel","pos":"ADJ"} ,
		{"word":"matricielles","word_nosc":"matricielles","lemma":"matriciel","pos":"ADJ"} ,
		{"word":"matriciels","word_nosc":"matriciels","lemma":"matriciel","pos":"ADJ"} ,
		{"word":"matrilinéaire","word_nosc":"matrilineaire","lemma":"matrilinéaire","pos":"ADJ"} ,
		{"word":"matrilinéaires","word_nosc":"matrilineaires","lemma":"matrilinéaire","pos":"ADJ"} ,
		{"word":"matrilocales","word_nosc":"matrilocales","lemma":"matrilocal","pos":"ADJ"} ,
		{"word":"matrimonial","word_nosc":"matrimonial","lemma":"matrimonial","pos":"ADJ"} ,
		{"word":"matrimoniale","word_nosc":"matrimoniale","lemma":"matrimonial","pos":"ADJ"} ,
		{"word":"matrimoniales","word_nosc":"matrimoniales","lemma":"matrimonial","pos":"ADJ"} ,
		{"word":"matrimoniaux","word_nosc":"matrimoniaux","lemma":"matrimonial","pos":"ADJ"} ,
		{"word":"mats","word_nosc":"mats","lemma":"mat","pos":"ADJ"} ,
		{"word":"mature","word_nosc":"mature","lemma":"mature","pos":"ADJ"} ,
		{"word":"matures","word_nosc":"matures","lemma":"mature","pos":"ADJ"} ,
		{"word":"matutinal","word_nosc":"matutinal","lemma":"matutinal","pos":"ADJ"} ,
		{"word":"matutinale","word_nosc":"matutinale","lemma":"matutinal","pos":"ADJ"} ,
		{"word":"matutinales","word_nosc":"matutinales","lemma":"matutinal","pos":"ADJ"} ,
		{"word":"matutinaux","word_nosc":"matutinaux","lemma":"matutinal","pos":"ADJ"} ,
		{"word":"matérialiste","word_nosc":"materialiste","lemma":"matérialiste","pos":"ADJ"} ,
		{"word":"matérialistes","word_nosc":"materialistes","lemma":"matérialiste","pos":"ADJ"} ,
		{"word":"matériel","word_nosc":"materiel","lemma":"matériel","pos":"ADJ"} ,
		{"word":"matérielle","word_nosc":"materielle","lemma":"matériel","pos":"ADJ"} ,
		{"word":"matérielles","word_nosc":"materielles","lemma":"matériel","pos":"ADJ"} ,
		{"word":"matériels","word_nosc":"materiels","lemma":"matériel","pos":"ADJ"} ,
		{"word":"maudit","word_nosc":"maudit","lemma":"maudit","pos":"ADJ"} ,
		{"word":"maudite","word_nosc":"maudite","lemma":"maudit","pos":"ADJ"} ,
		{"word":"maudites","word_nosc":"maudites","lemma":"maudit","pos":"ADJ"} ,
		{"word":"maudits","word_nosc":"maudits","lemma":"maudit","pos":"ADJ"} ,
		{"word":"maure","word_nosc":"maure","lemma":"maure","pos":"ADJ"} ,
		{"word":"maures","word_nosc":"maures","lemma":"maure","pos":"ADJ"} ,
		{"word":"mauresque","word_nosc":"mauresque","lemma":"mauresque","pos":"ADJ"} ,
		{"word":"mauresques","word_nosc":"mauresques","lemma":"mauresque","pos":"ADJ"} ,
		{"word":"mauricienne","word_nosc":"mauricienne","lemma":"mauricien","pos":"ADJ"} ,
		{"word":"mauritanienne","word_nosc":"mauritanienne","lemma":"mauritanien","pos":"ADJ"} ,
		{"word":"maussade","word_nosc":"maussade","lemma":"maussade","pos":"ADJ"} ,
		{"word":"maussades","word_nosc":"maussades","lemma":"maussade","pos":"ADJ"} ,
		{"word":"mauvais","word_nosc":"mauvais","lemma":"mauvais","pos":"ADJ"} ,
		{"word":"mauvaise","word_nosc":"mauvaise","lemma":"mauvais","pos":"ADJ"} ,
		{"word":"mauvaises","word_nosc":"mauvaises","lemma":"mauvais","pos":"ADJ"} ,
		{"word":"mauve","word_nosc":"mauve","lemma":"mauve","pos":"ADJ"} ,
		{"word":"mauves","word_nosc":"mauves","lemma":"mauve","pos":"ADJ"} ,
		{"word":"maxi","word_nosc":"maxi","lemma":"maxi","pos":"ADJ"} ,
		{"word":"maxillaire","word_nosc":"maxillaire","lemma":"maxillaire","pos":"ADJ"} ,
		{"word":"maxillaires","word_nosc":"maxillaires","lemma":"maxillaire","pos":"ADJ"} ,
		{"word":"maxillo-facial","word_nosc":"maxillo-facial","lemma":"maxillo-facial","pos":"ADJ"} ,
		{"word":"maximal","word_nosc":"maximal","lemma":"maximal","pos":"ADJ"} ,
		{"word":"maximale","word_nosc":"maximale","lemma":"maximal","pos":"ADJ"} ,
		{"word":"maximales","word_nosc":"maximales","lemma":"maximal","pos":"ADJ"} ,
		{"word":"maximum","word_nosc":"maximum","lemma":"maximum","pos":"ADJ"} ,
		{"word":"maximums","word_nosc":"maximums","lemma":"maximum","pos":"ADJ"} ,
		{"word":"maya","word_nosc":"maya","lemma":"maya","pos":"ADJ"} ,
		{"word":"mayas","word_nosc":"mayas","lemma":"maya","pos":"ADJ"} ,
		{"word":"mazdéens","word_nosc":"mazdeens","lemma":"mazdéen","pos":"ADJ"} ,
		{"word":"mazoutés","word_nosc":"mazoutes","lemma":"mazouté","pos":"ADJ"} ,
		{"word":"maçonnique","word_nosc":"maconnique","lemma":"maçonnique","pos":"ADJ"} ,
		{"word":"maçonniques","word_nosc":"maconniques","lemma":"maçonnique","pos":"ADJ"} ,
		{"word":"maître","word_nosc":"maitre","lemma":"maître","pos":"ADJ"} ,
		{"word":"maître-chanteur","word_nosc":"maitre-chanteur","lemma":"maître-chanteur","pos":"ADJ"} ,
		{"word":"maîtres","word_nosc":"maitres","lemma":"maître","pos":"ADJ"} ,
		{"word":"maîtresse","word_nosc":"maitresse","lemma":"maître","pos":"ADJ"} ,
		{"word":"maîtresses","word_nosc":"maitresses","lemma":"maître","pos":"ADJ"} ,
		{"word":"maîtrisable","word_nosc":"maitrisable","lemma":"maîtrisable","pos":"ADJ"} ,
		{"word":"maîtrisables","word_nosc":"maitrisables","lemma":"maîtrisable","pos":"ADJ"} ,
		{"word":"maîtrisé","word_nosc":"maitrise","lemma":"maîtrisé","pos":"ADJ"} ,
		{"word":"maîtrisée","word_nosc":"maitrisee","lemma":"maîtrisé","pos":"ADJ"} ,
		{"word":"maîtrisées","word_nosc":"maitrisees","lemma":"maîtrisé","pos":"ADJ"} ,
		{"word":"maîtrisés","word_nosc":"maitrises","lemma":"maîtrisé","pos":"ADJ"} ,
		{"word":"meiji","word_nosc":"meiji","lemma":"meiji","pos":"ADJ"} ,
		{"word":"meilleur","word_nosc":"meilleur","lemma":"meilleur","pos":"ADJ"} ,
		{"word":"meilleure","word_nosc":"meilleure","lemma":"meilleur","pos":"ADJ"} ,
		{"word":"meilleures","word_nosc":"meilleures","lemma":"meilleur","pos":"ADJ"} ,
		{"word":"meilleurs","word_nosc":"meilleurs","lemma":"meilleur","pos":"ADJ"} ,
		{"word":"melba","word_nosc":"melba","lemma":"melba","pos":"ADJ"} ,
		{"word":"melliflues","word_nosc":"melliflues","lemma":"melliflue","pos":"ADJ"} ,
		{"word":"mellifère","word_nosc":"mellifere","lemma":"mellifère","pos":"ADJ"} ,
		{"word":"membranaire","word_nosc":"membranaire","lemma":"membranaire","pos":"ADJ"} ,
		{"word":"membraneuse","word_nosc":"membraneuse","lemma":"membraneux","pos":"ADJ"} ,
		{"word":"membraneuses","word_nosc":"membraneuses","lemma":"membraneux","pos":"ADJ"} ,
		{"word":"membraneux","word_nosc":"membraneux","lemma":"membraneux","pos":"ADJ"} ,
		{"word":"membrues","word_nosc":"membrues","lemma":"membru","pos":"ADJ"} ,
		{"word":"membré","word_nosc":"membre","lemma":"membré","pos":"ADJ"} ,
		{"word":"menacé","word_nosc":"menace","lemma":"menacé","pos":"ADJ"} ,
		{"word":"menacée","word_nosc":"menacee","lemma":"menacé","pos":"ADJ"} ,
		{"word":"menacées","word_nosc":"menacees","lemma":"menacé","pos":"ADJ"} ,
		{"word":"menacés","word_nosc":"menaces","lemma":"menacé","pos":"ADJ"} ,
		{"word":"menaçant","word_nosc":"menacant","lemma":"menaçant","pos":"ADJ"} ,
		{"word":"menaçante","word_nosc":"menacante","lemma":"menaçant","pos":"ADJ"} ,
		{"word":"menaçantes","word_nosc":"menacantes","lemma":"menaçant","pos":"ADJ"} ,
		{"word":"menaçants","word_nosc":"menacants","lemma":"menaçant","pos":"ADJ"} ,
		{"word":"mendé","word_nosc":"mende","lemma":"mendé","pos":"ADJ"} ,
		{"word":"mensonger","word_nosc":"mensonger","lemma":"mensonger","pos":"ADJ"} ,
		{"word":"mensongers","word_nosc":"mensongers","lemma":"mensonger","pos":"ADJ"} ,
		{"word":"mensongère","word_nosc":"mensongere","lemma":"mensonger","pos":"ADJ"} ,
		{"word":"mensongères","word_nosc":"mensongeres","lemma":"mensonger","pos":"ADJ"} ,
		{"word":"menstruel","word_nosc":"menstruel","lemma":"menstruel","pos":"ADJ"} ,
		{"word":"menstruelle","word_nosc":"menstruelle","lemma":"menstruel","pos":"ADJ"} ,
		{"word":"menstruelles","word_nosc":"menstruelles","lemma":"menstruel","pos":"ADJ"} ,
		{"word":"menstruels","word_nosc":"menstruels","lemma":"menstruel","pos":"ADJ"} ,
		{"word":"mensuel","word_nosc":"mensuel","lemma":"mensuel","pos":"ADJ"} ,
		{"word":"mensuelle","word_nosc":"mensuelle","lemma":"mensuel","pos":"ADJ"} ,
		{"word":"mensuelles","word_nosc":"mensuelles","lemma":"mensuel","pos":"ADJ"} ,
		{"word":"mensuels","word_nosc":"mensuels","lemma":"mensuel","pos":"ADJ"} ,
		{"word":"mental","word_nosc":"mental","lemma":"mental","pos":"ADJ"} ,
		{"word":"mentale","word_nosc":"mentale","lemma":"mental","pos":"ADJ"} ,
		{"word":"mentales","word_nosc":"mentales","lemma":"mental","pos":"ADJ"} ,
		{"word":"mentaux","word_nosc":"mentaux","lemma":"mental","pos":"ADJ"} ,
		{"word":"menteur","word_nosc":"menteur","lemma":"menteur","pos":"ADJ"} ,
		{"word":"menteurs","word_nosc":"menteurs","lemma":"menteur","pos":"ADJ"} ,
		{"word":"menteuse","word_nosc":"menteuse","lemma":"menteur","pos":"ADJ"} ,
		{"word":"menteuses","word_nosc":"menteuses","lemma":"menteur","pos":"ADJ"} ,
		{"word":"mentholé","word_nosc":"menthole","lemma":"mentholé","pos":"ADJ"} ,
		{"word":"mentholée","word_nosc":"mentholee","lemma":"mentholé","pos":"ADJ"} ,
		{"word":"mentholées","word_nosc":"mentholees","lemma":"mentholé","pos":"ADJ"} ,
		{"word":"mentonnier","word_nosc":"mentonnier","lemma":"mentonnier","pos":"ADJ"} ,
		{"word":"mentonnière","word_nosc":"mentonniere","lemma":"mentonnier","pos":"ADJ"} ,
		{"word":"menu","word_nosc":"menu","lemma":"menu","pos":"ADJ"} ,
		{"word":"menue","word_nosc":"menue","lemma":"menu","pos":"ADJ"} ,
		{"word":"menues","word_nosc":"menues","lemma":"menu","pos":"ADJ"} ,
		{"word":"menus","word_nosc":"menus","lemma":"menu","pos":"ADJ"} ,
		{"word":"mer-air","word_nosc":"mer-air","lemma":"mer-air","pos":"ADJ"} ,
		{"word":"mercantile","word_nosc":"mercantile","lemma":"mercantile","pos":"ADJ"} ,
		{"word":"mercantiles","word_nosc":"mercantiles","lemma":"mercantile","pos":"ADJ"} ,
		{"word":"mercenaire","word_nosc":"mercenaire","lemma":"mercenaire","pos":"ADJ"} ,
		{"word":"mercenaires","word_nosc":"mercenaires","lemma":"mercenaire","pos":"ADJ"} ,
		{"word":"merdeuse","word_nosc":"merdeuse","lemma":"merdeux","pos":"ADJ"} ,
		{"word":"merdeuses","word_nosc":"merdeuses","lemma":"merdeux","pos":"ADJ"} ,
		{"word":"merdeux","word_nosc":"merdeux","lemma":"merdeux","pos":"ADJ"} ,
		{"word":"merdique","word_nosc":"merdique","lemma":"merdique","pos":"ADJ"} ,
		{"word":"merdiques","word_nosc":"merdiques","lemma":"merdique","pos":"ADJ"} ,
		{"word":"merveilleuse","word_nosc":"merveilleuse","lemma":"merveilleux","pos":"ADJ"} ,
		{"word":"merveilleuses","word_nosc":"merveilleuses","lemma":"merveilleux","pos":"ADJ"} ,
		{"word":"merveilleux","word_nosc":"merveilleux","lemma":"merveilleux","pos":"ADJ"} ,
		{"word":"mes","word_nosc":"mes","lemma":"mes","pos":"ADJ:pos"} ,
		{"word":"mesquin","word_nosc":"mesquin","lemma":"mesquin","pos":"ADJ"} ,
		{"word":"mesquine","word_nosc":"mesquine","lemma":"mesquin","pos":"ADJ"} ,
		{"word":"mesquines","word_nosc":"mesquines","lemma":"mesquin","pos":"ADJ"} ,
		{"word":"mesquins","word_nosc":"mesquins","lemma":"mesquin","pos":"ADJ"} ,
		{"word":"messaliste","word_nosc":"messaliste","lemma":"messaliste","pos":"ADJ"} ,
		{"word":"messianique","word_nosc":"messianique","lemma":"messianique","pos":"ADJ"} ,
		{"word":"messianiques","word_nosc":"messianiques","lemma":"messianique","pos":"ADJ"} ,
		{"word":"messine","word_nosc":"messine","lemma":"messin","pos":"ADJ"} ,
		{"word":"mesurable","word_nosc":"mesurable","lemma":"mesurable","pos":"ADJ"} ,
		{"word":"mesurables","word_nosc":"mesurables","lemma":"mesurable","pos":"ADJ"} ,
		{"word":"mesuré","word_nosc":"mesure","lemma":"mesuré","pos":"ADJ"} ,
		{"word":"mesurée","word_nosc":"mesuree","lemma":"mesuré","pos":"ADJ"} ,
		{"word":"mesurées","word_nosc":"mesurees","lemma":"mesuré","pos":"ADJ"} ,
		{"word":"mesurés","word_nosc":"mesures","lemma":"mesuré","pos":"ADJ"} ,
		{"word":"mettable","word_nosc":"mettable","lemma":"mettable","pos":"ADJ"} ,
		{"word":"meublant","word_nosc":"meublant","lemma":"meublant","pos":"ADJ"} ,
		{"word":"meuble","word_nosc":"meuble","lemma":"meuble","pos":"ADJ"} ,
		{"word":"meubles","word_nosc":"meubles","lemma":"meuble","pos":"ADJ"} ,
		{"word":"meublé","word_nosc":"meuble","lemma":"meublé","pos":"ADJ"} ,
		{"word":"meublée","word_nosc":"meublee","lemma":"meublé","pos":"ADJ"} ,
		{"word":"meublées","word_nosc":"meublees","lemma":"meublé","pos":"ADJ"} ,
		{"word":"meublés","word_nosc":"meubles","lemma":"meublé","pos":"ADJ"} ,
		{"word":"meulière","word_nosc":"meuliere","lemma":"meulier","pos":"ADJ"} ,
		{"word":"meulières","word_nosc":"meulieres","lemma":"meulier","pos":"ADJ"} ,
		{"word":"meunier","word_nosc":"meunier","lemma":"meunier","pos":"ADJ"} ,
		{"word":"meuniers","word_nosc":"meuniers","lemma":"meunier","pos":"ADJ"} ,
		{"word":"meunière","word_nosc":"meuniere","lemma":"meunier","pos":"ADJ"} ,
		{"word":"meurtri","word_nosc":"meurtri","lemma":"meurtri","pos":"ADJ"} ,
		{"word":"meurtrie","word_nosc":"meurtrie","lemma":"meurtri","pos":"ADJ"} ,
		{"word":"meurtrier","word_nosc":"meurtrier","lemma":"meurtrier","pos":"ADJ"} ,
		{"word":"meurtriers","word_nosc":"meurtriers","lemma":"meurtrier","pos":"ADJ"} ,
		{"word":"meurtries","word_nosc":"meurtries","lemma":"meurtri","pos":"ADJ"} ,
		{"word":"meurtris","word_nosc":"meurtris","lemma":"meurtri","pos":"ADJ"} ,
		{"word":"meurtrière","word_nosc":"meurtriere","lemma":"meurtrier","pos":"ADJ"} ,
		{"word":"meurtrières","word_nosc":"meurtrieres","lemma":"meurtrier","pos":"ADJ"} ,
		{"word":"meusien","word_nosc":"meusien","lemma":"meusien","pos":"ADJ"} ,
		{"word":"meusienne","word_nosc":"meusienne","lemma":"meusien","pos":"ADJ"} ,
		{"word":"meusiennes","word_nosc":"meusiennes","lemma":"meusien","pos":"ADJ"} ,
		{"word":"meusiens","word_nosc":"meusiens","lemma":"meusien","pos":"ADJ"} ,
		{"word":"mexicain","word_nosc":"mexicain","lemma":"mexicain","pos":"ADJ"} ,
		{"word":"mexicaine","word_nosc":"mexicaine","lemma":"mexicain","pos":"ADJ"} ,
		{"word":"mexicaines","word_nosc":"mexicaines","lemma":"mexicain","pos":"ADJ"} ,
		{"word":"mexicains","word_nosc":"mexicains","lemma":"mexicain","pos":"ADJ"} ,
		{"word":"mi-accablé","word_nosc":"mi-accable","lemma":"mi-accablé","pos":"ADJ"} ,
		{"word":"mi-allongé","word_nosc":"mi-allonge","lemma":"mi-allongé","pos":"ADJ"} ,
		{"word":"mi-animal","word_nosc":"mi-animal","lemma":"mi-animal","pos":"ADJ"} ,
		{"word":"mi-animaux","word_nosc":"mi-animaux","lemma":"mi-animaux","pos":"ADJ"} ,
		{"word":"mi-appointées","word_nosc":"mi-appointees","lemma":"mi-appointé","pos":"ADJ"} ,
		{"word":"mi-arabe","word_nosc":"mi-arabe","lemma":"mi-arabe","pos":"ADJ"} ,
		{"word":"mi-biblique","word_nosc":"mi-biblique","lemma":"mi-biblique","pos":"ADJ"} ,
		{"word":"mi-black","word_nosc":"mi-black","lemma":"mi-black","pos":"ADJ"} ,
		{"word":"mi-bleu","word_nosc":"mi-bleu","lemma":"mi-bleu","pos":"ADJ"} ,
		{"word":"mi-bourgeois","word_nosc":"mi-bourgeois","lemma":"mi-bourgeois","pos":"ADJ"} ,
		{"word":"mi-britannique","word_nosc":"mi-britannique","lemma":"mi-britannique","pos":"ADJ"} ,
		{"word":"mi-bureaucrates","word_nosc":"mi-bureaucrates","lemma":"mi-bureaucrate","pos":"ADJ"} ,
		{"word":"mi-bête","word_nosc":"mi-bete","lemma":"mi-bête","pos":"ADJ"} ,
		{"word":"mi-café","word_nosc":"mi-cafe","lemma":"mi-café","pos":"ADJ"} ,
		{"word":"mi-chair","word_nosc":"mi-chair","lemma":"mi-chair","pos":"ADJ"} ,
		{"word":"mi-chauve","word_nosc":"mi-chauve","lemma":"mi-chauve","pos":"ADJ"} ,
		{"word":"mi-clair","word_nosc":"mi-clair","lemma":"mi-clair","pos":"ADJ"} ,
		{"word":"mi-clos","word_nosc":"mi-clos","lemma":"mi-clos","pos":"ADJ"} ,
		{"word":"mi-close","word_nosc":"mi-close","lemma":"mi-clos","pos":"ADJ"} ,
		{"word":"mi-closes","word_nosc":"mi-closes","lemma":"mi-clos","pos":"ADJ"} ,
		{"word":"mi-comique","word_nosc":"mi-comique","lemma":"mi-comique","pos":"ADJ"} ,
		{"word":"mi-complices","word_nosc":"mi-complices","lemma":"mi-complice","pos":"ADJ"} ,
		{"word":"mi-confondus","word_nosc":"mi-confondus","lemma":"mi-confondu","pos":"ADJ"} ,
		{"word":"mi-connue","word_nosc":"mi-connue","lemma":"mi-connu","pos":"ADJ"} ,
		{"word":"mi-consterné","word_nosc":"mi-consterne","lemma":"mi-consterné","pos":"ADJ"} ,
		{"word":"mi-contrariés","word_nosc":"mi-contraries","lemma":"mi-contrarié","pos":"ADJ"} ,
		{"word":"mi-courtois","word_nosc":"mi-courtois","lemma":"mi-courtois","pos":"ADJ"} ,
		{"word":"mi-cruel","word_nosc":"mi-cruel","lemma":"mi-cruel","pos":"ADJ"} ,
		{"word":"mi-curieux","word_nosc":"mi-curieux","lemma":"mi-curieux","pos":"ADJ"} ,
		{"word":"mi-céleste","word_nosc":"mi-celeste","lemma":"mi-céleste","pos":"ADJ"} ,
		{"word":"mi-cérémonieux","word_nosc":"mi-ceremonieux","lemma":"mi-cérémonieux","pos":"ADJ"} ,
		{"word":"mi-didactique","word_nosc":"mi-didactique","lemma":"mi-didactique","pos":"ADJ"} ,
		{"word":"mi-douce","word_nosc":"mi-douce","lemma":"mi-douce","pos":"ADJ"} ,
		{"word":"mi-effrayée","word_nosc":"mi-effrayee","lemma":"mi-effrayé","pos":"ADJ"} ,
		{"word":"mi-effrayés","word_nosc":"mi-effrayes","lemma":"mi-effrayé","pos":"ADJ"} ,
		{"word":"mi-enjouée","word_nosc":"mi-enjouee","lemma":"mi-enjoué","pos":"ADJ"} ,
		{"word":"mi-enterré","word_nosc":"mi-enterre","lemma":"mi-enterré","pos":"ADJ"} ,
		{"word":"mi-envieux","word_nosc":"mi-envieux","lemma":"mi-envieux","pos":"ADJ"} ,
		{"word":"mi-faux","word_nosc":"mi-faux","lemma":"mi-faux","pos":"ADJ"} ,
		{"word":"mi-furieux","word_nosc":"mi-furieux","lemma":"mi-furieux","pos":"ADJ"} ,
		{"word":"mi-goguenarde","word_nosc":"mi-goguenarde","lemma":"mi-goguenard","pos":"ADJ"} ,
		{"word":"mi-graves","word_nosc":"mi-graves","lemma":"mi-grave","pos":"ADJ"} ,
		{"word":"mi-grondeur","word_nosc":"mi-grondeur","lemma":"mi-grondeur","pos":"ADJ"} ,
		{"word":"mi-grossières","word_nosc":"mi-grossieres","lemma":"mi-grossier","pos":"ADJ"} ,
		{"word":"mi-haute","word_nosc":"mi-haute","lemma":"mi-haute","pos":"ADJ"} ,
		{"word":"mi-historiques","word_nosc":"mi-historiques","lemma":"mi-historique","pos":"ADJ"} ,
		{"word":"mi-hésitant","word_nosc":"mi-hesitant","lemma":"mi-hésitant","pos":"ADJ"} ,
		{"word":"mi-indignée","word_nosc":"mi-indignee","lemma":"mi-indigné","pos":"ADJ"} ,
		{"word":"mi-indigène","word_nosc":"mi-indigene","lemma":"mi-indigène","pos":"ADJ"} ,
		{"word":"mi-indulgent","word_nosc":"mi-indulgent","lemma":"mi-indulgent","pos":"ADJ"} ,
		{"word":"mi-inquiète","word_nosc":"mi-inquiete","lemma":"mi-inquiet","pos":"ADJ"} ,
		{"word":"mi-ironique","word_nosc":"mi-ironique","lemma":"mi-ironique","pos":"ADJ"} ,
		{"word":"mi-ironiques","word_nosc":"mi-ironiques","lemma":"mi-ironique","pos":"ADJ"} ,
		{"word":"mi-jaune","word_nosc":"mi-jaune","lemma":"mi-jaune","pos":"ADJ"} ,
		{"word":"mi-jaunes","word_nosc":"mi-jaunes","lemma":"mi-jaune","pos":"ADJ"} ,
		{"word":"mi-lent","word_nosc":"mi-lent","lemma":"mi-lent","pos":"ADJ"} ,
		{"word":"mi-lourd","word_nosc":"mi-lourd","lemma":"mi-lourd","pos":"ADJ"} ,
		{"word":"mi-menaçante","word_nosc":"mi-menacante","lemma":"mi-menaçant","pos":"ADJ"} ,
		{"word":"mi-miel","word_nosc":"mi-miel","lemma":"mi-miel","pos":"ADJ"} ,
		{"word":"mi-moyen","word_nosc":"mi-moyen","lemma":"mi-moyen","pos":"ADJ"} ,
		{"word":"mi-moyens","word_nosc":"mi-moyens","lemma":"mi-moyen","pos":"ADJ"} ,
		{"word":"mi-narquoise","word_nosc":"mi-narquoise","lemma":"mi-narquoise","pos":"ADJ"} ,
		{"word":"mi-obscure","word_nosc":"mi-obscure","lemma":"mi-obscur","pos":"ADJ"} ,
		{"word":"mi-partie","word_nosc":"mi-partie","lemma":"mi-parti","pos":"ADJ"} ,
		{"word":"mi-pathétique","word_nosc":"mi-pathetique","lemma":"mi-pathétique","pos":"ADJ"} ,
		{"word":"mi-pincé","word_nosc":"mi-pince","lemma":"mi-pincé","pos":"ADJ"} ,
		{"word":"mi-plaintif","word_nosc":"mi-plaintif","lemma":"mi-plaintif","pos":"ADJ"} ,
		{"word":"mi-rageur","word_nosc":"mi-rageur","lemma":"mi-rageur","pos":"ADJ"} ,
		{"word":"mi-raide","word_nosc":"mi-raide","lemma":"mi-raide","pos":"ADJ"} ,
		{"word":"mi-respectueuse","word_nosc":"mi-respectueuse","lemma":"mi-respectueux","pos":"ADJ"} ,
		{"word":"mi-riant","word_nosc":"mi-riant","lemma":"mi-riant","pos":"ADJ"} ,
		{"word":"mi-russe","word_nosc":"mi-russe","lemma":"mi-russe","pos":"ADJ"} ,
		{"word":"mi-réprobateur","word_nosc":"mi-reprobateur","lemma":"mi-réprobateur","pos":"ADJ"} ,
		{"word":"mi-samoan","word_nosc":"mi-samoan","lemma":"mi-samoan","pos":"ADJ"} ,
		{"word":"mi-sanglotant","word_nosc":"mi-sanglotant","lemma":"mi-sanglotant","pos":"ADJ"} ,
		{"word":"mi-satisfait","word_nosc":"mi-satisfait","lemma":"mi-satisfait","pos":"ADJ"} ,
		{"word":"mi-sceptique","word_nosc":"mi-sceptique","lemma":"mi-sceptique","pos":"ADJ"} ,
		{"word":"mi-scientifiques","word_nosc":"mi-scientifiques","lemma":"mi-scientifique","pos":"ADJ"} ,
		{"word":"mi-sidérée","word_nosc":"mi-sideree","lemma":"mi-sidéré","pos":"ADJ"} ,
		{"word":"mi-sombre","word_nosc":"mi-sombre","lemma":"mi-sombre","pos":"ADJ"} ,
		{"word":"mi-souriant","word_nosc":"mi-souriant","lemma":"mi-souriant","pos":"ADJ"} ,
		{"word":"mi-souriante","word_nosc":"mi-souriante","lemma":"mi-souriant","pos":"ADJ"} ,
		{"word":"mi-suisse","word_nosc":"mi-suisse","lemma":"mi-suisse","pos":"ADJ"} ,
		{"word":"mi-sérieux","word_nosc":"mi-serieux","lemma":"mi-sérieux","pos":"ADJ"} ,
		{"word":"mi-terrorisé","word_nosc":"mi-terrorise","lemma":"mi-terrorisé","pos":"ADJ"} ,
		{"word":"mi-timide","word_nosc":"mi-timide","lemma":"mi-timide","pos":"ADJ"} ,
		{"word":"mi-tortue","word_nosc":"mi-tortue","lemma":"mi-tortue","pos":"ADJ"} ,
		{"word":"mi-tragique","word_nosc":"mi-tragique","lemma":"mi-tragique","pos":"ADJ"} ,
		{"word":"mi-voluptueux","word_nosc":"mi-voluptueux","lemma":"mi-voluptueux","pos":"ADJ"} ,
		{"word":"mi-étonné","word_nosc":"mi-etonne","lemma":"mi-étonné","pos":"ADJ"} ,
		{"word":"mi-éveillée","word_nosc":"mi-eveillee","lemma":"mi-éveillé","pos":"ADJ"} ,
		{"word":"miao","word_nosc":"miao","lemma":"miao","pos":"ADJ"} ,
		{"word":"miauleur","word_nosc":"miauleur","lemma":"miauleur","pos":"ADJ"} ,
		{"word":"micacées","word_nosc":"micacees","lemma":"micacé","pos":"ADJ"} ,
		{"word":"microbienne","word_nosc":"microbienne","lemma":"microbien","pos":"ADJ"} ,
		{"word":"microbiens","word_nosc":"microbiens","lemma":"microbien","pos":"ADJ"} ,
		{"word":"microcéphale","word_nosc":"microcephale","lemma":"microcéphale","pos":"ADJ"} ,
		{"word":"microcéphales","word_nosc":"microcephales","lemma":"microcéphale","pos":"ADJ"} ,
		{"word":"microscopique","word_nosc":"microscopique","lemma":"microscopique","pos":"ADJ"} ,
		{"word":"microscopiques","word_nosc":"microscopiques","lemma":"microscopique","pos":"ADJ"} ,
		{"word":"midi","word_nosc":"midi","lemma":"midi","pos":"ADJ:num"} ,
		{"word":"miel","word_nosc":"miel","lemma":"miel","pos":"ADJ"} ,
		{"word":"mielleuse","word_nosc":"mielleuse","lemma":"mielleux","pos":"ADJ"} ,
		{"word":"mielleuses","word_nosc":"mielleuses","lemma":"mielleux","pos":"ADJ"} ,
		{"word":"mielleux","word_nosc":"mielleux","lemma":"mielleux","pos":"ADJ"} ,
		{"word":"miellé","word_nosc":"mielle","lemma":"miellé","pos":"ADJ"} ,
		{"word":"mien","word_nosc":"mien","lemma":"mien","pos":"ADJ:pos"} ,
		{"word":"mienne","word_nosc":"mienne","lemma":"mienne","pos":"ADJ:pos"} ,
		{"word":"miennes","word_nosc":"miennes","lemma":"miennes","pos":"ADJ:pos"} ,
		{"word":"miens","word_nosc":"miens","lemma":"miens","pos":"ADJ:pos"} ,
		{"word":"mignard","word_nosc":"mignard","lemma":"mignard","pos":"ADJ"} ,
		{"word":"mignarde","word_nosc":"mignarde","lemma":"mignard","pos":"ADJ"} ,
		{"word":"mignardes","word_nosc":"mignardes","lemma":"mignard","pos":"ADJ"} ,
		{"word":"mignards","word_nosc":"mignards","lemma":"mignard","pos":"ADJ"} ,
		{"word":"mignon","word_nosc":"mignon","lemma":"mignon","pos":"ADJ"} ,
		{"word":"mignonne","word_nosc":"mignonne","lemma":"mignon","pos":"ADJ"} ,
		{"word":"mignonnes","word_nosc":"mignonnes","lemma":"mignon","pos":"ADJ"} ,
		{"word":"mignonnet","word_nosc":"mignonnet","lemma":"mignonnet","pos":"ADJ"} ,
		{"word":"mignonnets","word_nosc":"mignonnets","lemma":"mignonnet","pos":"ADJ"} ,
		{"word":"mignonnette","word_nosc":"mignonnette","lemma":"mignonnet","pos":"ADJ"} ,
		{"word":"mignonnettes","word_nosc":"mignonnettes","lemma":"mignonnet","pos":"ADJ"} ,
		{"word":"mignons","word_nosc":"mignons","lemma":"mignon","pos":"ADJ"} ,
		{"word":"migraineux","word_nosc":"migraineux","lemma":"migraineux","pos":"ADJ"} ,
		{"word":"migrante","word_nosc":"migrante","lemma":"migrant","pos":"ADJ"} ,
		{"word":"migrants","word_nosc":"migrants","lemma":"migrant","pos":"ADJ"} ,
		{"word":"migrateur","word_nosc":"migrateur","lemma":"migrateur","pos":"ADJ"} ,
		{"word":"migrateurs","word_nosc":"migrateurs","lemma":"migrateur","pos":"ADJ"} ,
		{"word":"migratoire","word_nosc":"migratoire","lemma":"migratoire","pos":"ADJ"} ,
		{"word":"migratoires","word_nosc":"migratoires","lemma":"migratoire","pos":"ADJ"} ,
		{"word":"migratrice","word_nosc":"migratrice","lemma":"migrateur","pos":"ADJ"} ,
		{"word":"migratrices","word_nosc":"migratrices","lemma":"migrateur","pos":"ADJ"} ,
		{"word":"mil","word_nosc":"mil","lemma":"mil","pos":"ADJ:num"} ,
		{"word":"milanais","word_nosc":"milanais","lemma":"milanais","pos":"ADJ"} ,
		{"word":"milanaise","word_nosc":"milanaise","lemma":"milanais","pos":"ADJ"} ,
		{"word":"milanaises","word_nosc":"milanaises","lemma":"milanais","pos":"ADJ"} ,
		{"word":"militaire","word_nosc":"militaire","lemma":"militaire","pos":"ADJ"} ,
		{"word":"militaires","word_nosc":"militaires","lemma":"militaire","pos":"ADJ"} ,
		{"word":"militant","word_nosc":"militant","lemma":"militant","pos":"ADJ"} ,
		{"word":"militante","word_nosc":"militante","lemma":"militant","pos":"ADJ"} ,
		{"word":"militantes","word_nosc":"militantes","lemma":"militant","pos":"ADJ"} ,
		{"word":"militants","word_nosc":"militants","lemma":"militant","pos":"ADJ"} ,
		{"word":"militariste","word_nosc":"militariste","lemma":"militariste","pos":"ADJ"} ,
		{"word":"militaristes","word_nosc":"militaristes","lemma":"militariste","pos":"ADJ"} ,
		{"word":"militaro-industriel","word_nosc":"militaro-industriel","lemma":"militaro-industriel","pos":"ADJ"} ,
		{"word":"militaro-industrielle","word_nosc":"militaro-industrielle","lemma":"militaro-industriel","pos":"ADJ"} ,
		{"word":"mille","word_nosc":"mille","lemma":"mille","pos":"ADJ:num"} ,
		{"word":"milliaire","word_nosc":"milliaire","lemma":"milliaire","pos":"ADJ"} ,
		{"word":"milliardaire","word_nosc":"milliardaire","lemma":"milliardaire","pos":"ADJ"} ,
		{"word":"milliardaires","word_nosc":"milliardaires","lemma":"milliardaire","pos":"ADJ"} ,
		{"word":"milliardième","word_nosc":"milliardieme","lemma":"milliardième","pos":"ADJ"} ,
		{"word":"millimétrique","word_nosc":"millimetrique","lemma":"millimétrique","pos":"ADJ"} ,
		{"word":"millimétriques","word_nosc":"millimetriques","lemma":"millimétrique","pos":"ADJ"} ,
		{"word":"millimétré","word_nosc":"millimetre","lemma":"millimétré","pos":"ADJ"} ,
		{"word":"millimétrées","word_nosc":"millimetrees","lemma":"millimétré","pos":"ADJ"} ,
		{"word":"millimétrés","word_nosc":"millimetres","lemma":"millimétré","pos":"ADJ"} ,
		{"word":"millionième","word_nosc":"millionieme","lemma":"millionième","pos":"ADJ"} ,
		{"word":"millionnaire","word_nosc":"millionnaire","lemma":"millionnaire","pos":"ADJ"} ,
		{"word":"millionnaires","word_nosc":"millionnaires","lemma":"millionnaire","pos":"ADJ"} ,
		{"word":"millième","word_nosc":"millieme","lemma":"millième","pos":"ADJ"} ,
		{"word":"millièmes","word_nosc":"milliemes","lemma":"millième","pos":"ADJ"} ,
		{"word":"millénaire","word_nosc":"millenaire","lemma":"millénaire","pos":"ADJ"} ,
		{"word":"millénaires","word_nosc":"millenaires","lemma":"millénaire","pos":"ADJ"} ,
		{"word":"millénariste","word_nosc":"millenariste","lemma":"millénariste","pos":"ADJ"} ,
		{"word":"milésiennes","word_nosc":"milesiennes","lemma":"milésien","pos":"ADJ"} ,
		{"word":"mimi","word_nosc":"mimi","lemma":"mimi","pos":"ADJ"} ,
		{"word":"mimique","word_nosc":"mimique","lemma":"mimique","pos":"ADJ"} ,
		{"word":"mimiques","word_nosc":"mimiques","lemma":"mimique","pos":"ADJ"} ,
		{"word":"mimosa","word_nosc":"mimosa","lemma":"mimosa","pos":"ADJ"} ,
		{"word":"mimétique","word_nosc":"mimetique","lemma":"mimétique","pos":"ADJ"} ,
		{"word":"minable","word_nosc":"minable","lemma":"minable","pos":"ADJ"} ,
		{"word":"minables","word_nosc":"minables","lemma":"minable","pos":"ADJ"} ,
		{"word":"minaudant","word_nosc":"minaudant","lemma":"minaudant","pos":"ADJ"} ,
		{"word":"minaudants","word_nosc":"minaudants","lemma":"minaudant","pos":"ADJ"} ,
		{"word":"minaudeur","word_nosc":"minaudeur","lemma":"minaudeur","pos":"ADJ"} ,
		{"word":"minaudière","word_nosc":"minaudiere","lemma":"minaudier","pos":"ADJ"} ,
		{"word":"mince","word_nosc":"mince","lemma":"mince","pos":"ADJ"} ,
		{"word":"minces","word_nosc":"minces","lemma":"mince","pos":"ADJ"} ,
		{"word":"mineur","word_nosc":"mineur","lemma":"mineur","pos":"ADJ"} ,
		{"word":"mineure","word_nosc":"mineure","lemma":"mineur","pos":"ADJ"} ,
		{"word":"mineures","word_nosc":"mineures","lemma":"mineur","pos":"ADJ"} ,
		{"word":"mineurs","word_nosc":"mineurs","lemma":"mineur","pos":"ADJ"} ,
		{"word":"ming","word_nosc":"ming","lemma":"ming","pos":"ADJ"} ,
		{"word":"mini","word_nosc":"mini","lemma":"mini","pos":"ADJ"} ,
		{"word":"minier","word_nosc":"minier","lemma":"minier","pos":"ADJ"} ,
		{"word":"miniers","word_nosc":"miniers","lemma":"minier","pos":"ADJ"} ,
		{"word":"minimal","word_nosc":"minimal","lemma":"minimal","pos":"ADJ"} ,
		{"word":"minimale","word_nosc":"minimale","lemma":"minimal","pos":"ADJ"} ,
		{"word":"minimales","word_nosc":"minimales","lemma":"minimal","pos":"ADJ"} ,
		{"word":"minimaliste","word_nosc":"minimaliste","lemma":"minimaliste","pos":"ADJ"} ,
		{"word":"minimaux","word_nosc":"minimaux","lemma":"minimal","pos":"ADJ"} ,
		{"word":"minime","word_nosc":"minime","lemma":"minime","pos":"ADJ"} ,
		{"word":"minimes","word_nosc":"minimes","lemma":"minime","pos":"ADJ"} ,
		{"word":"minimum","word_nosc":"minimum","lemma":"minimum","pos":"ADJ"} ,
		{"word":"minimums","word_nosc":"minimums","lemma":"minimum","pos":"ADJ"} ,
		{"word":"ministrables","word_nosc":"ministrables","lemma":"ministrable","pos":"ADJ"} ,
		{"word":"ministériel","word_nosc":"ministeriel","lemma":"ministériel","pos":"ADJ"} ,
		{"word":"ministérielle","word_nosc":"ministerielle","lemma":"ministériel","pos":"ADJ"} ,
		{"word":"ministérielles","word_nosc":"ministerielles","lemma":"ministériel","pos":"ADJ"} ,
		{"word":"ministériels","word_nosc":"ministeriels","lemma":"ministériel","pos":"ADJ"} ,
		{"word":"minière","word_nosc":"miniere","lemma":"minier","pos":"ADJ"} ,
		{"word":"minières","word_nosc":"minieres","lemma":"minier","pos":"ADJ"} ,
		{"word":"mino","word_nosc":"mino","lemma":"mino","pos":"ADJ"} ,
		{"word":"minoen","word_nosc":"minoen","lemma":"minoen","pos":"ADJ"} ,
		{"word":"minoenne","word_nosc":"minoenne","lemma":"minoen","pos":"ADJ"} ,
		{"word":"minoens","word_nosc":"minoens","lemma":"minoen","pos":"ADJ"} ,
		{"word":"minoritaire","word_nosc":"minoritaire","lemma":"minoritaire","pos":"ADJ"} ,
		{"word":"minoritaires","word_nosc":"minoritaires","lemma":"minoritaire","pos":"ADJ"} ,
		{"word":"minouche","word_nosc":"minouche","lemma":"minouche","pos":"ADJ"} ,
		{"word":"minuscule","word_nosc":"minuscule","lemma":"minuscule","pos":"ADJ"} ,
		{"word":"minuscules","word_nosc":"minuscules","lemma":"minuscule","pos":"ADJ"} ,
		{"word":"minutieuse","word_nosc":"minutieuse","lemma":"minutieux","pos":"ADJ"} ,
		{"word":"minutieuses","word_nosc":"minutieuses","lemma":"minutieux","pos":"ADJ"} ,
		{"word":"minutieux","word_nosc":"minutieux","lemma":"minutieux","pos":"ADJ"} ,
		{"word":"minéral","word_nosc":"mineral","lemma":"minéral","pos":"ADJ"} ,
		{"word":"minérale","word_nosc":"minerale","lemma":"minéral","pos":"ADJ"} ,
		{"word":"minérales","word_nosc":"minerales","lemma":"minéral","pos":"ADJ"} ,
		{"word":"minéralisés","word_nosc":"mineralises","lemma":"minéralisé","pos":"ADJ"} ,
		{"word":"minéralogique","word_nosc":"mineralogique","lemma":"minéralogique","pos":"ADJ"} ,
		{"word":"minéralogiques","word_nosc":"mineralogiques","lemma":"minéralogique","pos":"ADJ"} ,
		{"word":"minéraux","word_nosc":"mineraux","lemma":"minéral","pos":"ADJ"} ,
		{"word":"miraculant","word_nosc":"miraculant","lemma":"miraculant","pos":"ADJ"} ,
		{"word":"miraculantes","word_nosc":"miraculantes","lemma":"miraculant","pos":"ADJ"} ,
		{"word":"miraculeuse","word_nosc":"miraculeuse","lemma":"miraculeux","pos":"ADJ"} ,
		{"word":"miraculeuses","word_nosc":"miraculeuses","lemma":"miraculeux","pos":"ADJ"} ,
		{"word":"miraculeux","word_nosc":"miraculeux","lemma":"miraculeux","pos":"ADJ"} ,
		{"word":"miraculé","word_nosc":"miracule","lemma":"miraculé","pos":"ADJ"} ,
		{"word":"miraculée","word_nosc":"miraculee","lemma":"miraculé","pos":"ADJ"} ,
		{"word":"miraud","word_nosc":"miraud","lemma":"miraud","pos":"ADJ"} ,
		{"word":"mirauds","word_nosc":"mirauds","lemma":"miraud","pos":"ADJ"} ,
		{"word":"mirifique","word_nosc":"mirifique","lemma":"mirifique","pos":"ADJ"} ,
		{"word":"mirifiques","word_nosc":"mirifiques","lemma":"mirifique","pos":"ADJ"} ,
		{"word":"miro","word_nosc":"miro","lemma":"miro","pos":"ADJ"} ,
		{"word":"mirobolant","word_nosc":"mirobolant","lemma":"mirobolant","pos":"ADJ"} ,
		{"word":"mirobolante","word_nosc":"mirobolante","lemma":"mirobolant","pos":"ADJ"} ,
		{"word":"mirobolantes","word_nosc":"mirobolantes","lemma":"mirobolant","pos":"ADJ"} ,
		{"word":"mirobolants","word_nosc":"mirobolants","lemma":"mirobolant","pos":"ADJ"} ,
		{"word":"miroitant","word_nosc":"miroitant","lemma":"miroitant","pos":"ADJ"} ,
		{"word":"miroitante","word_nosc":"miroitante","lemma":"miroitant","pos":"ADJ"} ,
		{"word":"miroitantes","word_nosc":"miroitantes","lemma":"miroitant","pos":"ADJ"} ,
		{"word":"miroitants","word_nosc":"miroitants","lemma":"miroitant","pos":"ADJ"} ,
		{"word":"miroités","word_nosc":"miroites","lemma":"miroité","pos":"ADJ"} ,
		{"word":"miros","word_nosc":"miros","lemma":"miro","pos":"ADJ"} ,
		{"word":"mis","word_nosc":"mis","lemma":"mis","pos":"ADJ"} ,
		{"word":"misanthrope","word_nosc":"misanthrope","lemma":"misanthrope","pos":"ADJ"} ,
		{"word":"misanthropes","word_nosc":"misanthropes","lemma":"misanthrope","pos":"ADJ"} ,
		{"word":"misanthropiques","word_nosc":"misanthropiques","lemma":"misanthropique","pos":"ADJ"} ,
		{"word":"miscible","word_nosc":"miscible","lemma":"miscible","pos":"ADJ"} ,
		{"word":"mise","word_nosc":"mise","lemma":"mise","pos":"ADJ"} ,
		{"word":"mises","word_nosc":"mises","lemma":"mise","pos":"ADJ"} ,
		{"word":"misogyne","word_nosc":"misogyne","lemma":"misogyne","pos":"ADJ"} ,
		{"word":"misogynes","word_nosc":"misogynes","lemma":"misogyne","pos":"ADJ"} ,
		{"word":"missionnaire","word_nosc":"missionnaire","lemma":"missionnaire","pos":"ADJ"} ,
		{"word":"missionnaires","word_nosc":"missionnaires","lemma":"missionnaire","pos":"ADJ"} ,
		{"word":"misérabiliste","word_nosc":"miserabiliste","lemma":"misérabiliste","pos":"ADJ"} ,
		{"word":"misérable","word_nosc":"miserable","lemma":"misérable","pos":"ADJ"} ,
		{"word":"misérables","word_nosc":"miserables","lemma":"misérable","pos":"ADJ"} ,
		{"word":"miséreuse","word_nosc":"misereuse","lemma":"miséreux","pos":"ADJ"} ,
		{"word":"miséreuses","word_nosc":"misereuses","lemma":"miséreux","pos":"ADJ"} ,
		{"word":"miséreux","word_nosc":"misereux","lemma":"miséreux","pos":"ADJ"} ,
		{"word":"miséricordieuse","word_nosc":"misericordieuse","lemma":"miséricordieux","pos":"ADJ"} ,
		{"word":"miséricordieuses","word_nosc":"misericordieuses","lemma":"miséricordieux","pos":"ADJ"} ,
		{"word":"miséricordieux","word_nosc":"misericordieux","lemma":"miséricordieux","pos":"ADJ"} ,
		{"word":"mitchouriniens","word_nosc":"mitchouriniens","lemma":"mitchourinien","pos":"ADJ"} ,
		{"word":"miteuse","word_nosc":"miteuse","lemma":"miteux","pos":"ADJ"} ,
		{"word":"miteuses","word_nosc":"miteuses","lemma":"miteux","pos":"ADJ"} ,
		{"word":"miteux","word_nosc":"miteux","lemma":"miteux","pos":"ADJ"} ,
		{"word":"mithriaque","word_nosc":"mithriaque","lemma":"mithriaque","pos":"ADJ"} ,
		{"word":"mitigé","word_nosc":"mitige","lemma":"mitigé","pos":"ADJ"} ,
		{"word":"mitigée","word_nosc":"mitigee","lemma":"mitigé","pos":"ADJ"} ,
		{"word":"mitigées","word_nosc":"mitigees","lemma":"mitigé","pos":"ADJ"} ,
		{"word":"mitochondrial","word_nosc":"mitochondrial","lemma":"mitochondrial","pos":"ADJ"} ,
		{"word":"mitotique","word_nosc":"mitotique","lemma":"mitotique","pos":"ADJ"} ,
		{"word":"mitoyen","word_nosc":"mitoyen","lemma":"mitoyen","pos":"ADJ"} ,
		{"word":"mitoyenne","word_nosc":"mitoyenne","lemma":"mitoyen","pos":"ADJ"} ,
		{"word":"mitoyennes","word_nosc":"mitoyennes","lemma":"mitoyen","pos":"ADJ"} ,
		{"word":"mitoyens","word_nosc":"mitoyens","lemma":"mitoyen","pos":"ADJ"} ,
		{"word":"mitral","word_nosc":"mitral","lemma":"mitral","pos":"ADJ"} ,
		{"word":"mitrale","word_nosc":"mitrale","lemma":"mitral","pos":"ADJ"} ,
		{"word":"mitré","word_nosc":"mitre","lemma":"mitré","pos":"ADJ"} ,
		{"word":"mitrés","word_nosc":"mitres","lemma":"mitré","pos":"ADJ"} ,
		{"word":"mité","word_nosc":"mite","lemma":"mité","pos":"ADJ"} ,
		{"word":"mitée","word_nosc":"mitee","lemma":"mité","pos":"ADJ"} ,
		{"word":"mitées","word_nosc":"mitees","lemma":"mité","pos":"ADJ"} ,
		{"word":"mités","word_nosc":"mites","lemma":"mité","pos":"ADJ"} ,
		{"word":"mixte","word_nosc":"mixte","lemma":"mixte","pos":"ADJ"} ,
		{"word":"mixtes","word_nosc":"mixtes","lemma":"mixte","pos":"ADJ"} ,
		{"word":"mièvre","word_nosc":"mievre","lemma":"mièvre","pos":"ADJ"} ,
		{"word":"mièvres","word_nosc":"mievres","lemma":"mièvre","pos":"ADJ"} ,
		{"word":"ml","word_nosc":"ml","lemma":"ml","pos":"ADJ:num"} ,
		{"word":"mlle","word_nosc":"mlle","lemma":"mlle","pos":"ADJ"} ,
		{"word":"mm","word_nosc":"mm","lemma":"mm","pos":"ADJ:num"} ,
		{"word":"mme","word_nosc":"mme","lemma":"mme","pos":"ADJ"} ,
		{"word":"mnémonique","word_nosc":"mnemonique","lemma":"mnémonique","pos":"ADJ"} ,
		{"word":"mnémoniques","word_nosc":"mnemoniques","lemma":"mnémonique","pos":"ADJ"} ,
		{"word":"mnémotechnique","word_nosc":"mnemotechnique","lemma":"mnémotechnique","pos":"ADJ"} ,
		{"word":"mnésiques","word_nosc":"mnesiques","lemma":"mnésique","pos":"ADJ"} ,
		{"word":"mobile","word_nosc":"mobile","lemma":"mobile","pos":"ADJ"} ,
		{"word":"mobiles","word_nosc":"mobiles","lemma":"mobile","pos":"ADJ"} ,
		{"word":"mobilier","word_nosc":"mobilier","lemma":"mobilier","pos":"ADJ"} ,
		{"word":"mobiliers","word_nosc":"mobiliers","lemma":"mobilier","pos":"ADJ"} ,
		{"word":"mobilisable","word_nosc":"mobilisable","lemma":"mobilisable","pos":"ADJ"} ,
		{"word":"mobilisables","word_nosc":"mobilisables","lemma":"mobilisable","pos":"ADJ"} ,
		{"word":"mobilisateur","word_nosc":"mobilisateur","lemma":"mobilisateur","pos":"ADJ"} ,
		{"word":"mobilisateurs","word_nosc":"mobilisateurs","lemma":"mobilisateur","pos":"ADJ"} ,
		{"word":"mobilière","word_nosc":"mobiliere","lemma":"mobilier","pos":"ADJ"} ,
		{"word":"mobilières","word_nosc":"mobilieres","lemma":"mobilier","pos":"ADJ"} ,
		{"word":"mochard","word_nosc":"mochard","lemma":"mochard","pos":"ADJ"} ,
		{"word":"mocharde","word_nosc":"mocharde","lemma":"mochard","pos":"ADJ"} ,
		{"word":"moche","word_nosc":"moche","lemma":"moche","pos":"ADJ"} ,
		{"word":"moches","word_nosc":"moches","lemma":"moche","pos":"ADJ"} ,
		{"word":"mod","word_nosc":"mod","lemma":"mod","pos":"ADJ"} ,
		{"word":"modelable","word_nosc":"modelable","lemma":"modelable","pos":"ADJ"} ,
		{"word":"modern style","word_nosc":"modern style","lemma":"modern style","pos":"ADJ"} ,
		{"word":"moderne","word_nosc":"moderne","lemma":"moderne","pos":"ADJ"} ,
		{"word":"modernes","word_nosc":"modernes","lemma":"moderne","pos":"ADJ"} ,
		{"word":"modernissime","word_nosc":"modernissime","lemma":"modernissime","pos":"ADJ"} ,
		{"word":"moderniste","word_nosc":"moderniste","lemma":"moderniste","pos":"ADJ"} ,
		{"word":"modernistes","word_nosc":"modernistes","lemma":"moderniste","pos":"ADJ"} ,
		{"word":"modeste","word_nosc":"modeste","lemma":"modeste","pos":"ADJ"} ,
		{"word":"modestes","word_nosc":"modestes","lemma":"modeste","pos":"ADJ"} ,
		{"word":"modifiable","word_nosc":"modifiable","lemma":"modifiable","pos":"ADJ"} ,
		{"word":"modifiables","word_nosc":"modifiables","lemma":"modifiable","pos":"ADJ"} ,
		{"word":"modificateur","word_nosc":"modificateur","lemma":"modificateur","pos":"ADJ"} ,
		{"word":"modifié","word_nosc":"modifie","lemma":"modifié","pos":"ADJ"} ,
		{"word":"modifiée","word_nosc":"modifiee","lemma":"modifié","pos":"ADJ"} ,
		{"word":"modifiées","word_nosc":"modifiees","lemma":"modifié","pos":"ADJ"} ,
		{"word":"modifiés","word_nosc":"modifies","lemma":"modifié","pos":"ADJ"} ,
		{"word":"modique","word_nosc":"modique","lemma":"modique","pos":"ADJ"} ,
		{"word":"modiques","word_nosc":"modiques","lemma":"modique","pos":"ADJ"} ,
		{"word":"modulable","word_nosc":"modulable","lemma":"modulable","pos":"ADJ"} ,
		{"word":"modulables","word_nosc":"modulables","lemma":"modulable","pos":"ADJ"} ,
		{"word":"modulaire","word_nosc":"modulaire","lemma":"modulaire","pos":"ADJ"} ,
		{"word":"modulaires","word_nosc":"modulaires","lemma":"modulaire","pos":"ADJ"} ,
		{"word":"modulateur","word_nosc":"modulateur","lemma":"modulateur","pos":"ADJ"} ,
		{"word":"modèle","word_nosc":"modele","lemma":"modèle","pos":"ADJ"} ,
		{"word":"modèles","word_nosc":"modeles","lemma":"modèle","pos":"ADJ"} ,
		{"word":"modérateur","word_nosc":"moderateur","lemma":"modérateur","pos":"ADJ"} ,
		{"word":"modéré","word_nosc":"modere","lemma":"modéré","pos":"ADJ"} ,
		{"word":"modérée","word_nosc":"moderee","lemma":"modéré","pos":"ADJ"} ,
		{"word":"modérées","word_nosc":"moderees","lemma":"modéré","pos":"ADJ"} ,
		{"word":"modérés","word_nosc":"moderes","lemma":"modéré","pos":"ADJ"} ,
		{"word":"moelleuse","word_nosc":"moelleuse","lemma":"moelleux","pos":"ADJ"} ,
		{"word":"moelleuses","word_nosc":"moelleuses","lemma":"moelleux","pos":"ADJ"} ,
		{"word":"moelleux","word_nosc":"moelleux","lemma":"moelleux","pos":"ADJ"} ,
		{"word":"mogol","word_nosc":"mogol","lemma":"mogol","pos":"ADJ"} ,
		{"word":"moindre","word_nosc":"moindre","lemma":"moindre","pos":"ADJ"} ,
		{"word":"moindres","word_nosc":"moindres","lemma":"moindre","pos":"ADJ"} ,
		{"word":"moiré","word_nosc":"moire","lemma":"moiré","pos":"ADJ"} ,
		{"word":"moirée","word_nosc":"moiree","lemma":"moiré","pos":"ADJ"} ,
		{"word":"moirées","word_nosc":"moirees","lemma":"moiré","pos":"ADJ"} ,
		{"word":"moirés","word_nosc":"moires","lemma":"moiré","pos":"ADJ"} ,
		{"word":"moisi","word_nosc":"moisi","lemma":"moisi","pos":"ADJ"} ,
		{"word":"moisie","word_nosc":"moisie","lemma":"moisi","pos":"ADJ"} ,
		{"word":"moisies","word_nosc":"moisies","lemma":"moisi","pos":"ADJ"} ,
		{"word":"moisis","word_nosc":"moisis","lemma":"moisi","pos":"ADJ"} ,
		{"word":"moite","word_nosc":"moite","lemma":"moite","pos":"ADJ"} ,
		{"word":"moites","word_nosc":"moites","lemma":"moite","pos":"ADJ"} ,
		{"word":"mol","word_nosc":"mol","lemma":"mol","pos":"ADJ"} ,
		{"word":"molasse","word_nosc":"molasse","lemma":"molasse","pos":"ADJ"} ,
		{"word":"moldave","word_nosc":"moldave","lemma":"moldave","pos":"ADJ"} ,
		{"word":"moldaves","word_nosc":"moldaves","lemma":"moldave","pos":"ADJ"} ,
		{"word":"moliéresque","word_nosc":"molieresque","lemma":"moliéresque","pos":"ADJ"} ,
		{"word":"mollasse","word_nosc":"mollasse","lemma":"mollasse","pos":"ADJ"} ,
		{"word":"mollasses","word_nosc":"mollasses","lemma":"mollasse","pos":"ADJ"} ,
		{"word":"mollasson","word_nosc":"mollasson","lemma":"mollasson","pos":"ADJ"} ,
		{"word":"mollassonne","word_nosc":"mollassonne","lemma":"mollasson","pos":"ADJ"} ,
		{"word":"mollassons","word_nosc":"mollassons","lemma":"mollasson","pos":"ADJ"} ,
		{"word":"molle","word_nosc":"molle","lemma":"mou,mol","pos":"ADJ"} ,
		{"word":"molles","word_nosc":"molles","lemma":"mou,mol","pos":"ADJ"} ,
		{"word":"mollet","word_nosc":"mollet","lemma":"mollet","pos":"ADJ"} ,
		{"word":"molletonneux","word_nosc":"molletonneux","lemma":"molletonneux","pos":"ADJ"} ,
		{"word":"molletonné","word_nosc":"molletonne","lemma":"molletonné","pos":"ADJ"} ,
		{"word":"molletonnée","word_nosc":"molletonnee","lemma":"molletonné","pos":"ADJ"} ,
		{"word":"mollets","word_nosc":"mollets","lemma":"mollet","pos":"ADJ"} ,
		{"word":"mollette","word_nosc":"mollette","lemma":"mollet","pos":"ADJ"} ,
		{"word":"mollettes","word_nosc":"mollettes","lemma":"mollet","pos":"ADJ"} ,
		{"word":"mols","word_nosc":"mols","lemma":"mol","pos":"ADJ"} ,
		{"word":"moléculaire","word_nosc":"moleculaire","lemma":"moléculaire","pos":"ADJ"} ,
		{"word":"moléculaires","word_nosc":"moleculaires","lemma":"moléculaire","pos":"ADJ"} ,
		{"word":"momentané","word_nosc":"momentane","lemma":"momentané","pos":"ADJ"} ,
		{"word":"momentanée","word_nosc":"momentanee","lemma":"momentané","pos":"ADJ"} ,
		{"word":"momentanées","word_nosc":"momentanees","lemma":"momentané","pos":"ADJ"} ,
		{"word":"momentanés","word_nosc":"momentanes","lemma":"momentané","pos":"ADJ"} ,
		{"word":"momifié","word_nosc":"momifie","lemma":"momifié","pos":"ADJ"} ,
		{"word":"momifiée","word_nosc":"momifiee","lemma":"momifié","pos":"ADJ"} ,
		{"word":"momifiées","word_nosc":"momifiees","lemma":"momifié","pos":"ADJ"} ,
		{"word":"momifiés","word_nosc":"momifies","lemma":"momifié","pos":"ADJ"} ,
		{"word":"mon","word_nosc":"mon","lemma":"mon","pos":"ADJ:pos"} ,
		{"word":"monacal","word_nosc":"monacal","lemma":"monacal","pos":"ADJ"} ,
		{"word":"monacale","word_nosc":"monacale","lemma":"monacal","pos":"ADJ"} ,
		{"word":"monacales","word_nosc":"monacales","lemma":"monacal","pos":"ADJ"} ,
		{"word":"monarchique","word_nosc":"monarchique","lemma":"monarchique","pos":"ADJ"} ,
		{"word":"monarchiste","word_nosc":"monarchiste","lemma":"monarchiste","pos":"ADJ"} ,
		{"word":"monarchistes","word_nosc":"monarchistes","lemma":"monarchiste","pos":"ADJ"} ,
		{"word":"monastique","word_nosc":"monastique","lemma":"monastique","pos":"ADJ"} ,
		{"word":"monastiques","word_nosc":"monastiques","lemma":"monastique","pos":"ADJ"} ,
		{"word":"mondain","word_nosc":"mondain","lemma":"mondain","pos":"ADJ"} ,
		{"word":"mondaine","word_nosc":"mondaine","lemma":"mondain","pos":"ADJ"} ,
		{"word":"mondaines","word_nosc":"mondaines","lemma":"mondain","pos":"ADJ"} ,
		{"word":"mondains","word_nosc":"mondains","lemma":"mondain","pos":"ADJ"} ,
		{"word":"mondial","word_nosc":"mondial","lemma":"mondial","pos":"ADJ"} ,
		{"word":"mondiale","word_nosc":"mondiale","lemma":"mondial","pos":"ADJ"} ,
		{"word":"mondiales","word_nosc":"mondiales","lemma":"mondial","pos":"ADJ"} ,
		{"word":"mondialistes","word_nosc":"mondialistes","lemma":"mondialiste","pos":"ADJ"} ,
		{"word":"mondiaux","word_nosc":"mondiaux","lemma":"mondial","pos":"ADJ"} ,
		{"word":"mongo","word_nosc":"mongo","lemma":"mongo","pos":"ADJ"} ,
		{"word":"mongol","word_nosc":"mongol","lemma":"mongol","pos":"ADJ"} ,
		{"word":"mongole","word_nosc":"mongole","lemma":"mongol","pos":"ADJ"} ,
		{"word":"mongoles","word_nosc":"mongoles","lemma":"mongol","pos":"ADJ"} ,
		{"word":"mongolien","word_nosc":"mongolien","lemma":"mongolien","pos":"ADJ"} ,
		{"word":"mongolienne","word_nosc":"mongolienne","lemma":"mongolien","pos":"ADJ"} ,
		{"word":"mongoliennes","word_nosc":"mongoliennes","lemma":"mongolien","pos":"ADJ"} ,
		{"word":"mongoliens","word_nosc":"mongoliens","lemma":"mongolien","pos":"ADJ"} ,
		{"word":"mongolique","word_nosc":"mongolique","lemma":"mongolique","pos":"ADJ"} ,
		{"word":"mongoliques","word_nosc":"mongoliques","lemma":"mongolique","pos":"ADJ"} ,
		{"word":"mongoloïde","word_nosc":"mongoloide","lemma":"mongoloïde","pos":"ADJ"} ,
		{"word":"mongols","word_nosc":"mongols","lemma":"mongol","pos":"ADJ"} ,
		{"word":"moniales","word_nosc":"moniales","lemma":"moniale","pos":"ADJ"} ,
		{"word":"monnayable","word_nosc":"monnayable","lemma":"monnayable","pos":"ADJ"} ,
		{"word":"monnayables","word_nosc":"monnayables","lemma":"monnayable","pos":"ADJ"} ,
		{"word":"monocellulaire","word_nosc":"monocellulaire","lemma":"monocellulaire","pos":"ADJ"} ,
		{"word":"monochrome","word_nosc":"monochrome","lemma":"monochrome","pos":"ADJ"} ,
		{"word":"monochromes","word_nosc":"monochromes","lemma":"monochrome","pos":"ADJ"} ,
		{"word":"monoclonal","word_nosc":"monoclonal","lemma":"monoclonal","pos":"ADJ"} ,
		{"word":"monoclonaux","word_nosc":"monoclonaux","lemma":"monoclonal","pos":"ADJ"} ,
		{"word":"monoclé","word_nosc":"monocle","lemma":"monoclé","pos":"ADJ"} ,
		{"word":"monocoque","word_nosc":"monocoque","lemma":"monocoque","pos":"ADJ"} ,
		{"word":"monocorde","word_nosc":"monocorde","lemma":"monocorde","pos":"ADJ"} ,
		{"word":"monocordes","word_nosc":"monocordes","lemma":"monocorde","pos":"ADJ"} ,
		{"word":"monoculaire","word_nosc":"monoculaire","lemma":"monoculaire","pos":"ADJ"} ,
		{"word":"monoculaires","word_nosc":"monoculaires","lemma":"monoculaire","pos":"ADJ"} ,
		{"word":"monocylindre","word_nosc":"monocylindre","lemma":"monocylindre","pos":"ADJ"} ,
		{"word":"monogame","word_nosc":"monogame","lemma":"monogame","pos":"ADJ"} ,
		{"word":"monogames","word_nosc":"monogames","lemma":"monogame","pos":"ADJ"} ,
		{"word":"monogrammées","word_nosc":"monogrammees","lemma":"monogrammé","pos":"ADJ"} ,
		{"word":"monolithe","word_nosc":"monolithe","lemma":"monolithe","pos":"ADJ"} ,
		{"word":"monolithique","word_nosc":"monolithique","lemma":"monolithique","pos":"ADJ"} ,
		{"word":"monolithiques","word_nosc":"monolithiques","lemma":"monolithique","pos":"ADJ"} ,
		{"word":"monomanes","word_nosc":"monomanes","lemma":"monomane","pos":"ADJ"} ,
		{"word":"monomaniaque","word_nosc":"monomaniaque","lemma":"monomaniaque","pos":"ADJ"} ,
		{"word":"monomaniaques","word_nosc":"monomaniaques","lemma":"monomaniaque","pos":"ADJ"} ,
		{"word":"monomoteurs","word_nosc":"monomoteurs","lemma":"monomoteur","pos":"ADJ"} ,
		{"word":"mononucléaire","word_nosc":"mononucleaire","lemma":"mononucléaire","pos":"ADJ"} ,
		{"word":"monoparental","word_nosc":"monoparental","lemma":"monoparental","pos":"ADJ"} ,
		{"word":"monoparentale","word_nosc":"monoparentale","lemma":"monoparental","pos":"ADJ"} ,
		{"word":"monoparentales","word_nosc":"monoparentales","lemma":"monoparental","pos":"ADJ"} ,
		{"word":"monoparentaux","word_nosc":"monoparentaux","lemma":"monoparental","pos":"ADJ"} ,
		{"word":"monophasée","word_nosc":"monophasee","lemma":"monophasé","pos":"ADJ"} ,
		{"word":"monoplace","word_nosc":"monoplace","lemma":"monoplace","pos":"ADJ"} ,
		{"word":"monoplan","word_nosc":"monoplan","lemma":"monoplan","pos":"ADJ"} ,
		{"word":"monopode","word_nosc":"monopode","lemma":"monopode","pos":"ADJ"} ,
		{"word":"monorail","word_nosc":"monorail","lemma":"monorail","pos":"ADJ"} ,
		{"word":"monosyllabique","word_nosc":"monosyllabique","lemma":"monosyllabique","pos":"ADJ"} ,
		{"word":"monosyllabiques","word_nosc":"monosyllabiques","lemma":"monosyllabique","pos":"ADJ"} ,
		{"word":"monothéiste","word_nosc":"monotheiste","lemma":"monothéiste","pos":"ADJ"} ,
		{"word":"monothéistes","word_nosc":"monotheistes","lemma":"monothéiste","pos":"ADJ"} ,
		{"word":"monotone","word_nosc":"monotone","lemma":"monotone","pos":"ADJ"} ,
		{"word":"monotones","word_nosc":"monotones","lemma":"monotone","pos":"ADJ"} ,
		{"word":"monoxyle","word_nosc":"monoxyle","lemma":"monoxyle","pos":"ADJ"} ,
		{"word":"monstre","word_nosc":"monstre","lemma":"monstre","pos":"ADJ"} ,
		{"word":"monstres","word_nosc":"monstres","lemma":"monstre","pos":"ADJ"} ,
		{"word":"monstrueuse","word_nosc":"monstrueuse","lemma":"monstrueux","pos":"ADJ"} ,
		{"word":"monstrueuses","word_nosc":"monstrueuses","lemma":"monstrueux","pos":"ADJ"} ,
		{"word":"monstrueux","word_nosc":"monstrueux","lemma":"monstrueux","pos":"ADJ"} ,
		{"word":"montagnard","word_nosc":"montagnard","lemma":"montagnard","pos":"ADJ"} ,
		{"word":"montagnarde","word_nosc":"montagnarde","lemma":"montagnard","pos":"ADJ"} ,
		{"word":"montagnardes","word_nosc":"montagnardes","lemma":"montagnard","pos":"ADJ"} ,
		{"word":"montagnards","word_nosc":"montagnards","lemma":"montagnard","pos":"ADJ"} ,
		{"word":"montagneuse","word_nosc":"montagneuse","lemma":"montagneux","pos":"ADJ"} ,
		{"word":"montagneuses","word_nosc":"montagneuses","lemma":"montagneux","pos":"ADJ"} ,
		{"word":"montagneux","word_nosc":"montagneux","lemma":"montagneux","pos":"ADJ"} ,
		{"word":"montant","word_nosc":"montant","lemma":"montant","pos":"ADJ"} ,
		{"word":"montante","word_nosc":"montante","lemma":"montant","pos":"ADJ"} ,
		{"word":"montantes","word_nosc":"montantes","lemma":"montant","pos":"ADJ"} ,
		{"word":"montants","word_nosc":"montants","lemma":"montant","pos":"ADJ"} ,
		{"word":"montmartrois","word_nosc":"montmartrois","lemma":"montmartrois","pos":"ADJ"} ,
		{"word":"montmartroise","word_nosc":"montmartroise","lemma":"montmartrois","pos":"ADJ"} ,
		{"word":"montmartroises","word_nosc":"montmartroises","lemma":"montmartrois","pos":"ADJ"} ,
		{"word":"montparno","word_nosc":"montparno","lemma":"montparno","pos":"ADJ"} ,
		{"word":"montrable","word_nosc":"montrable","lemma":"montrable","pos":"ADJ"} ,
		{"word":"montrables","word_nosc":"montrables","lemma":"montrable","pos":"ADJ"} ,
		{"word":"montueuse","word_nosc":"montueuse","lemma":"montueux","pos":"ADJ"} ,
		{"word":"montueuses","word_nosc":"montueuses","lemma":"montueux","pos":"ADJ"} ,
		{"word":"montueux","word_nosc":"montueux","lemma":"montueux","pos":"ADJ"} ,
		{"word":"monté","word_nosc":"monte","lemma":"monté","pos":"ADJ"} ,
		{"word":"montée","word_nosc":"montee","lemma":"monté","pos":"ADJ"} ,
		{"word":"montées","word_nosc":"montees","lemma":"monté","pos":"ADJ"} ,
		{"word":"monténégrin","word_nosc":"montenegrin","lemma":"monténégrin","pos":"ADJ"} ,
		{"word":"monténégrines","word_nosc":"montenegrines","lemma":"monténégrin","pos":"ADJ"} ,
		{"word":"montés","word_nosc":"montes","lemma":"monté","pos":"ADJ"} ,
		{"word":"monumental","word_nosc":"monumental","lemma":"monumental","pos":"ADJ"} ,
		{"word":"monumentale","word_nosc":"monumentale","lemma":"monumental","pos":"ADJ"} ,
		{"word":"monumentales","word_nosc":"monumentales","lemma":"monumental","pos":"ADJ"} ,
		{"word":"monumentaux","word_nosc":"monumentaux","lemma":"monumental","pos":"ADJ"} ,
		{"word":"monétaire","word_nosc":"monetaire","lemma":"monétaire","pos":"ADJ"} ,
		{"word":"monétaires","word_nosc":"monetaires","lemma":"monétaire","pos":"ADJ"} ,
		{"word":"monétariste","word_nosc":"monetariste","lemma":"monétariste","pos":"ADJ"} ,
		{"word":"moose","word_nosc":"moose","lemma":"moose","pos":"ADJ"} ,
		{"word":"moqueur","word_nosc":"moqueur","lemma":"moqueur","pos":"ADJ"} ,
		{"word":"moqueurs","word_nosc":"moqueurs","lemma":"moqueur","pos":"ADJ"} ,
		{"word":"moqueuse","word_nosc":"moqueuse","lemma":"moqueur","pos":"ADJ"} ,
		{"word":"moqueuses","word_nosc":"moqueuses","lemma":"moqueur","pos":"ADJ"} ,
		{"word":"morainique","word_nosc":"morainique","lemma":"morainique","pos":"ADJ"} ,
		{"word":"morainiques","word_nosc":"morainiques","lemma":"morainique","pos":"ADJ"} ,
		{"word":"moral","word_nosc":"moral","lemma":"moral","pos":"ADJ"} ,
		{"word":"morale","word_nosc":"morale","lemma":"moral","pos":"ADJ"} ,
		{"word":"morales","word_nosc":"morales","lemma":"moral","pos":"ADJ"} ,
		{"word":"moralisante","word_nosc":"moralisante","lemma":"moralisant","pos":"ADJ"} ,
		{"word":"moralisateur","word_nosc":"moralisateur","lemma":"moralisateur","pos":"ADJ"} ,
		{"word":"moralisateurs","word_nosc":"moralisateurs","lemma":"moralisateur","pos":"ADJ"} ,
		{"word":"moralisatrice","word_nosc":"moralisatrice","lemma":"moralisateur","pos":"ADJ"} ,
		{"word":"moraliste","word_nosc":"moraliste","lemma":"moraliste","pos":"ADJ"} ,
		{"word":"moralistes","word_nosc":"moralistes","lemma":"moraliste","pos":"ADJ"} ,
		{"word":"moratoire","word_nosc":"moratoire","lemma":"moratoire","pos":"ADJ"} ,
		{"word":"moraux","word_nosc":"moraux","lemma":"moral","pos":"ADJ"} ,
		{"word":"morave","word_nosc":"morave","lemma":"morave","pos":"ADJ"} ,
		{"word":"moraves","word_nosc":"moraves","lemma":"morave","pos":"ADJ"} ,
		{"word":"morbide","word_nosc":"morbide","lemma":"morbide","pos":"ADJ"} ,
		{"word":"morbides","word_nosc":"morbides","lemma":"morbide","pos":"ADJ"} ,
		{"word":"morcelé","word_nosc":"morcele","lemma":"morcelé","pos":"ADJ"} ,
		{"word":"morcelée","word_nosc":"morcelee","lemma":"morcelé","pos":"ADJ"} ,
		{"word":"morcelés","word_nosc":"morceles","lemma":"morcelé","pos":"ADJ"} ,
		{"word":"mordant","word_nosc":"mordant","lemma":"mordant","pos":"ADJ"} ,
		{"word":"mordante","word_nosc":"mordante","lemma":"mordant","pos":"ADJ"} ,
		{"word":"mordantes","word_nosc":"mordantes","lemma":"mordant","pos":"ADJ"} ,
		{"word":"mordants","word_nosc":"mordants","lemma":"mordant","pos":"ADJ"} ,
		{"word":"mordeur","word_nosc":"mordeur","lemma":"mordeur","pos":"ADJ"} ,
		{"word":"mordeurs","word_nosc":"mordeurs","lemma":"mordeur","pos":"ADJ"} ,
		{"word":"mordeuse","word_nosc":"mordeuse","lemma":"mordeur","pos":"ADJ"} ,
		{"word":"mordoré","word_nosc":"mordore","lemma":"mordoré","pos":"ADJ"} ,
		{"word":"mordorée","word_nosc":"mordoree","lemma":"mordoré","pos":"ADJ"} ,
		{"word":"mordorées","word_nosc":"mordorees","lemma":"mordoré","pos":"ADJ"} ,
		{"word":"mordorés","word_nosc":"mordores","lemma":"mordoré","pos":"ADJ"} ,
		{"word":"mordu","word_nosc":"mordu","lemma":"mordu","pos":"ADJ"} ,
		{"word":"mordue","word_nosc":"mordue","lemma":"mordu","pos":"ADJ"} ,
		{"word":"mordues","word_nosc":"mordues","lemma":"mordu","pos":"ADJ"} ,
		{"word":"mordus","word_nosc":"mordus","lemma":"mordu","pos":"ADJ"} ,
		{"word":"more","word_nosc":"more","lemma":"more","pos":"ADJ"} ,
		{"word":"moreau","word_nosc":"moreau","lemma":"moreau","pos":"ADJ"} ,
		{"word":"mores","word_nosc":"mores","lemma":"more","pos":"ADJ"} ,
		{"word":"moresque","word_nosc":"moresque","lemma":"moresque","pos":"ADJ"} ,
		{"word":"morfal","word_nosc":"morfal","lemma":"morfal","pos":"ADJ"} ,
		{"word":"morfale","word_nosc":"morfale","lemma":"morfal","pos":"ADJ"} ,
		{"word":"morfales","word_nosc":"morfales","lemma":"morfal","pos":"ADJ"} ,
		{"word":"morfals","word_nosc":"morfals","lemma":"morfal","pos":"ADJ"} ,
		{"word":"morfondu","word_nosc":"morfondu","lemma":"morfondu","pos":"ADJ"} ,
		{"word":"morfondue","word_nosc":"morfondue","lemma":"morfondu","pos":"ADJ"} ,
		{"word":"morganatique","word_nosc":"morganatique","lemma":"morganatique","pos":"ADJ"} ,
		{"word":"morganatiques","word_nosc":"morganatiques","lemma":"morganatique","pos":"ADJ"} ,
		{"word":"moribond","word_nosc":"moribond","lemma":"moribond","pos":"ADJ"} ,
		{"word":"moribonde","word_nosc":"moribonde","lemma":"moribond","pos":"ADJ"} ,
		{"word":"moribondes","word_nosc":"moribondes","lemma":"moribond","pos":"ADJ"} ,
		{"word":"moribonds","word_nosc":"moribonds","lemma":"moribond","pos":"ADJ"} ,
		{"word":"moricaud","word_nosc":"moricaud","lemma":"moricaud","pos":"ADJ"} ,
		{"word":"moricaude","word_nosc":"moricaude","lemma":"moricaud","pos":"ADJ"} ,
		{"word":"mormon","word_nosc":"mormon","lemma":"mormon","pos":"ADJ"} ,
		{"word":"mormone","word_nosc":"mormone","lemma":"mormon","pos":"ADJ"} ,
		{"word":"mormones","word_nosc":"mormones","lemma":"mormon","pos":"ADJ"} ,
		{"word":"mormons","word_nosc":"mormons","lemma":"mormon","pos":"ADJ"} ,
		{"word":"morne","word_nosc":"morne","lemma":"morne","pos":"ADJ"} ,
		{"word":"mornes","word_nosc":"mornes","lemma":"morne","pos":"ADJ"} ,
		{"word":"moro","word_nosc":"moro","lemma":"moro","pos":"ADJ"} ,
		{"word":"morose","word_nosc":"morose","lemma":"morose","pos":"ADJ"} ,
		{"word":"moroses","word_nosc":"moroses","lemma":"morose","pos":"ADJ"} ,
		{"word":"morphinomane","word_nosc":"morphinomane","lemma":"morphinomane","pos":"ADJ"} ,
		{"word":"morphique","word_nosc":"morphique","lemma":"morphique","pos":"ADJ"} ,
		{"word":"morphogénétique","word_nosc":"morphogenetique","lemma":"morphogénétique","pos":"ADJ"} ,
		{"word":"morphologique","word_nosc":"morphologique","lemma":"morphologique","pos":"ADJ"} ,
		{"word":"morphologiques","word_nosc":"morphologiques","lemma":"morphologique","pos":"ADJ"} ,
		{"word":"mort","word_nosc":"mort","lemma":"mort","pos":"ADJ"} ,
		{"word":"mort-né","word_nosc":"mort-ne","lemma":"mort-né","pos":"ADJ"} ,
		{"word":"mort-née","word_nosc":"mort-nee","lemma":"mort-né","pos":"ADJ"} ,
		{"word":"mort-nées","word_nosc":"mort-nees","lemma":"mort-né","pos":"ADJ"} ,
		{"word":"mort-nés","word_nosc":"mort-nes","lemma":"mort-né","pos":"ADJ"} ,
		{"word":"mort-vivant","word_nosc":"mort-vivant","lemma":"mort-vivant","pos":"ADJ"} ,
		{"word":"morte","word_nosc":"morte","lemma":"mort","pos":"ADJ"} ,
		{"word":"mortel","word_nosc":"mortel","lemma":"mortel","pos":"ADJ"} ,
		{"word":"mortelle","word_nosc":"mortelle","lemma":"mortel","pos":"ADJ"} ,
		{"word":"mortelles","word_nosc":"mortelles","lemma":"mortel","pos":"ADJ"} ,
		{"word":"mortels","word_nosc":"mortels","lemma":"mortel","pos":"ADJ"} ,
		{"word":"mortes","word_nosc":"mortes","lemma":"mort","pos":"ADJ"} ,
		{"word":"mortibus","word_nosc":"mortibus","lemma":"mortibus","pos":"ADJ"} ,
		{"word":"mortifiant","word_nosc":"mortifiant","lemma":"mortifiant","pos":"ADJ"} ,
		{"word":"mortifiante","word_nosc":"mortifiante","lemma":"mortifiant","pos":"ADJ"} ,
		{"word":"mortifiantes","word_nosc":"mortifiantes","lemma":"mortifiant","pos":"ADJ"} ,
		{"word":"mortifiants","word_nosc":"mortifiants","lemma":"mortifiant","pos":"ADJ"} ,
		{"word":"mortifère","word_nosc":"mortifere","lemma":"mortifère","pos":"ADJ"} ,
		{"word":"mortifères","word_nosc":"mortiferes","lemma":"mortifère","pos":"ADJ"} ,
		{"word":"morts","word_nosc":"morts","lemma":"mort","pos":"ADJ"} ,
		{"word":"mortuaire","word_nosc":"mortuaire","lemma":"mortuaire","pos":"ADJ"} ,
		{"word":"mortuaires","word_nosc":"mortuaires","lemma":"mortuaire","pos":"ADJ"} ,
		{"word":"morvandiau","word_nosc":"morvandiau","lemma":"morvandiau","pos":"ADJ"} ,
		{"word":"morveuse","word_nosc":"morveuse","lemma":"morveux","pos":"ADJ"} ,
		{"word":"morveuses","word_nosc":"morveuses","lemma":"morveux","pos":"ADJ"} ,
		{"word":"morveux","word_nosc":"morveux","lemma":"morveux","pos":"ADJ"} ,
		{"word":"mosaïquée","word_nosc":"mosaiquee","lemma":"mosaïqué","pos":"ADJ"} ,
		{"word":"mosaïqués","word_nosc":"mosaiques","lemma":"mosaïqué","pos":"ADJ"} ,
		{"word":"moscovite","word_nosc":"moscovite","lemma":"moscovite","pos":"ADJ"} ,
		{"word":"moscovites","word_nosc":"moscovites","lemma":"moscovite","pos":"ADJ"} ,
		{"word":"mosellane","word_nosc":"mosellane","lemma":"mosellan","pos":"ADJ"} ,
		{"word":"mosellanes","word_nosc":"mosellanes","lemma":"mosellan","pos":"ADJ"} ,
		{"word":"moteur","word_nosc":"moteur","lemma":"moteur","pos":"ADJ"} ,
		{"word":"moteurs","word_nosc":"moteurs","lemma":"moteur","pos":"ADJ"} ,
		{"word":"motivant","word_nosc":"motivant","lemma":"motivant","pos":"ADJ"} ,
		{"word":"motivante","word_nosc":"motivante","lemma":"motivant","pos":"ADJ"} ,
		{"word":"motivé","word_nosc":"motive","lemma":"motivé","pos":"ADJ"} ,
		{"word":"motivée","word_nosc":"motivee","lemma":"motivé","pos":"ADJ"} ,
		{"word":"motivées","word_nosc":"motivees","lemma":"motivé","pos":"ADJ"} ,
		{"word":"motivés","word_nosc":"motives","lemma":"motivé","pos":"ADJ"} ,
		{"word":"motorisé","word_nosc":"motorise","lemma":"motorisé","pos":"ADJ"} ,
		{"word":"motorisée","word_nosc":"motorisee","lemma":"motorisé","pos":"ADJ"} ,
		{"word":"motorisées","word_nosc":"motorisees","lemma":"motorisé","pos":"ADJ"} ,
		{"word":"motorisés","word_nosc":"motorises","lemma":"motorisé","pos":"ADJ"} ,
		{"word":"motrice","word_nosc":"motrice","lemma":"moteur","pos":"ADJ"} ,
		{"word":"motrices","word_nosc":"motrices","lemma":"moteur","pos":"ADJ"} ,
		{"word":"mou","word_nosc":"mou","lemma":"mou","pos":"ADJ"} ,
		{"word":"mouchard","word_nosc":"mouchard","lemma":"mouchard","pos":"ADJ"} ,
		{"word":"moucharde","word_nosc":"moucharde","lemma":"mouchard","pos":"ADJ"} ,
		{"word":"mouchardes","word_nosc":"mouchardes","lemma":"mouchard","pos":"ADJ"} ,
		{"word":"mouchards","word_nosc":"mouchards","lemma":"mouchard","pos":"ADJ"} ,
		{"word":"moucheté","word_nosc":"mouchete","lemma":"moucheté","pos":"ADJ"} ,
		{"word":"mouchetée","word_nosc":"mouchetee","lemma":"moucheté","pos":"ADJ"} ,
		{"word":"mouchetées","word_nosc":"mouchetees","lemma":"moucheté","pos":"ADJ"} ,
		{"word":"mouchetés","word_nosc":"mouchetes","lemma":"moucheté","pos":"ADJ"} ,
		{"word":"mouillant","word_nosc":"mouillant","lemma":"mouillant","pos":"ADJ"} ,
		{"word":"mouillante","word_nosc":"mouillante","lemma":"mouillant","pos":"ADJ"} ,
		{"word":"mouilleuse","word_nosc":"mouilleuse","lemma":"mouilleur","pos":"ADJ"} ,
		{"word":"mouillé","word_nosc":"mouille","lemma":"mouillé","pos":"ADJ"} ,
		{"word":"mouillée","word_nosc":"mouillee","lemma":"mouillé","pos":"ADJ"} ,
		{"word":"mouillées","word_nosc":"mouillees","lemma":"mouillé","pos":"ADJ"} ,
		{"word":"mouillés","word_nosc":"mouilles","lemma":"mouillé","pos":"ADJ"} ,
		{"word":"moulant","word_nosc":"moulant","lemma":"moulant","pos":"ADJ"} ,
		{"word":"moulante","word_nosc":"moulante","lemma":"moulant","pos":"ADJ"} ,
		{"word":"moulantes","word_nosc":"moulantes","lemma":"moulant","pos":"ADJ"} ,
		{"word":"moulants","word_nosc":"moulants","lemma":"moulant","pos":"ADJ"} ,
		{"word":"moulu","word_nosc":"moulu","lemma":"moulu","pos":"ADJ"} ,
		{"word":"moulue","word_nosc":"moulue","lemma":"moulu","pos":"ADJ"} ,
		{"word":"moulues","word_nosc":"moulues","lemma":"moulu","pos":"ADJ"} ,
		{"word":"moulus","word_nosc":"moulus","lemma":"moulu","pos":"ADJ"} ,
		{"word":"moulé","word_nosc":"moule","lemma":"moulé","pos":"ADJ"} ,
		{"word":"moulée","word_nosc":"moulee","lemma":"moulé","pos":"ADJ"} ,
		{"word":"moulées","word_nosc":"moulees","lemma":"moulé","pos":"ADJ"} ,
		{"word":"moulés","word_nosc":"moules","lemma":"moulé","pos":"ADJ"} ,
		{"word":"mourant","word_nosc":"mourant","lemma":"mourant","pos":"ADJ"} ,
		{"word":"mourante","word_nosc":"mourante","lemma":"mourant","pos":"ADJ"} ,
		{"word":"mourantes","word_nosc":"mourantes","lemma":"mourant","pos":"ADJ"} ,
		{"word":"mourants","word_nosc":"mourants","lemma":"mourant","pos":"ADJ"} ,
		{"word":"mourides","word_nosc":"mourides","lemma":"mouride","pos":"ADJ"} ,
		{"word":"mous","word_nosc":"mous","lemma":"mou","pos":"ADJ"} ,
		{"word":"moussant","word_nosc":"moussant","lemma":"moussant","pos":"ADJ"} ,
		{"word":"moussante","word_nosc":"moussante","lemma":"moussant","pos":"ADJ"} ,
		{"word":"moussants","word_nosc":"moussants","lemma":"moussant","pos":"ADJ"} ,
		{"word":"mousse","word_nosc":"mousse","lemma":"mousse","pos":"ADJ"} ,
		{"word":"mousses","word_nosc":"mousses","lemma":"mousse","pos":"ADJ"} ,
		{"word":"mousseuse","word_nosc":"mousseuse","lemma":"mousseux","pos":"ADJ"} ,
		{"word":"mousseuses","word_nosc":"mousseuses","lemma":"mousseux","pos":"ADJ"} ,
		{"word":"mousseux","word_nosc":"mousseux","lemma":"mousseux","pos":"ADJ"} ,
		{"word":"moussu","word_nosc":"moussu","lemma":"moussu","pos":"ADJ"} ,
		{"word":"moussue","word_nosc":"moussue","lemma":"moussu","pos":"ADJ"} ,
		{"word":"moussues","word_nosc":"moussues","lemma":"moussu","pos":"ADJ"} ,
		{"word":"moussus","word_nosc":"moussus","lemma":"moussu","pos":"ADJ"} ,
		{"word":"moustachu","word_nosc":"moustachu","lemma":"moustachu","pos":"ADJ"} ,
		{"word":"moustachue","word_nosc":"moustachue","lemma":"moustachu","pos":"ADJ"} ,
		{"word":"moustachues","word_nosc":"moustachues","lemma":"moustachu","pos":"ADJ"} ,
		{"word":"moustachus","word_nosc":"moustachus","lemma":"moustachu","pos":"ADJ"} ,
		{"word":"moutarde","word_nosc":"moutarde","lemma":"moutarde","pos":"ADJ"} ,
		{"word":"moutonnant","word_nosc":"moutonnant","lemma":"moutonnant","pos":"ADJ"} ,
		{"word":"moutonnante","word_nosc":"moutonnante","lemma":"moutonnant","pos":"ADJ"} ,
		{"word":"moutonnantes","word_nosc":"moutonnantes","lemma":"moutonnant","pos":"ADJ"} ,
		{"word":"moutonnants","word_nosc":"moutonnants","lemma":"moutonnant","pos":"ADJ"} ,
		{"word":"moutonneux","word_nosc":"moutonneux","lemma":"moutonneux","pos":"ADJ"} ,
		{"word":"moutonnier","word_nosc":"moutonnier","lemma":"moutonnier","pos":"ADJ"} ,
		{"word":"moutonniers","word_nosc":"moutonniers","lemma":"moutonnier","pos":"ADJ"} ,
		{"word":"moutonnière","word_nosc":"moutonniere","lemma":"moutonnier","pos":"ADJ"} ,
		{"word":"mouvant","word_nosc":"mouvant","lemma":"mouvant","pos":"ADJ"} ,
		{"word":"mouvante","word_nosc":"mouvante","lemma":"mouvant","pos":"ADJ"} ,
		{"word":"mouvantes","word_nosc":"mouvantes","lemma":"mouvant","pos":"ADJ"} ,
		{"word":"mouvants","word_nosc":"mouvants","lemma":"mouvant","pos":"ADJ"} ,
		{"word":"mouvementé","word_nosc":"mouvemente","lemma":"mouvementé","pos":"ADJ"} ,
		{"word":"mouvementée","word_nosc":"mouvementee","lemma":"mouvementé","pos":"ADJ"} ,
		{"word":"mouvementées","word_nosc":"mouvementees","lemma":"mouvementé","pos":"ADJ"} ,
		{"word":"mouvementés","word_nosc":"mouvementes","lemma":"mouvementé","pos":"ADJ"} ,
		{"word":"moyen","word_nosc":"moyen","lemma":"moyen","pos":"ADJ"} ,
		{"word":"moyen-oriental","word_nosc":"moyen-oriental","lemma":"moyen-oriental","pos":"ADJ"} ,
		{"word":"moyen-orientaux","word_nosc":"moyen-orientaux","lemma":"moyen-orientaux","pos":"ADJ"} ,
		{"word":"moyenne","word_nosc":"moyenne","lemma":"moyen","pos":"ADJ"} ,
		{"word":"moyennes","word_nosc":"moyennes","lemma":"moyen","pos":"ADJ"} ,
		{"word":"moyens","word_nosc":"moyens","lemma":"moyen","pos":"ADJ"} ,
		{"word":"moyenâgeuse","word_nosc":"moyenageuse","lemma":"moyenâgeux","pos":"ADJ"} ,
		{"word":"moyenâgeuses","word_nosc":"moyenageuses","lemma":"moyenâgeux","pos":"ADJ"} ,
		{"word":"moyenâgeux","word_nosc":"moyenageux","lemma":"moyenâgeux","pos":"ADJ"} ,
		{"word":"mozarabe","word_nosc":"mozarabe","lemma":"mozarabe","pos":"ADJ"} ,
		{"word":"mozartiennes","word_nosc":"mozartiennes","lemma":"mozartien","pos":"ADJ"} ,
		{"word":"moï","word_nosc":"moi","lemma":"moï","pos":"ADJ"} ,
		{"word":"mucilagineuse","word_nosc":"mucilagineuse","lemma":"mucilagineux","pos":"ADJ"} ,
		{"word":"mudéjar","word_nosc":"mudejar","lemma":"mudéjar","pos":"ADJ"} ,
		{"word":"mudéjare","word_nosc":"mudejare","lemma":"mudéjar","pos":"ADJ"} ,
		{"word":"muet","word_nosc":"muet","lemma":"muet","pos":"ADJ"} ,
		{"word":"muets","word_nosc":"muets","lemma":"muet","pos":"ADJ"} ,
		{"word":"muette","word_nosc":"muette","lemma":"muet","pos":"ADJ"} ,
		{"word":"muettes","word_nosc":"muettes","lemma":"muet","pos":"ADJ"} ,
		{"word":"mufle","word_nosc":"mufle","lemma":"mufle","pos":"ADJ"} ,
		{"word":"mufles","word_nosc":"mufles","lemma":"mufle","pos":"ADJ"} ,
		{"word":"mugissant","word_nosc":"mugissant","lemma":"mugissant","pos":"ADJ"} ,
		{"word":"mugissante","word_nosc":"mugissante","lemma":"mugissant","pos":"ADJ"} ,
		{"word":"mugissantes","word_nosc":"mugissantes","lemma":"mugissant","pos":"ADJ"} ,
		{"word":"mugissants","word_nosc":"mugissants","lemma":"mugissant","pos":"ADJ"} ,
		{"word":"mulard","word_nosc":"mulard","lemma":"mulard","pos":"ADJ"} ,
		{"word":"muletier","word_nosc":"muletier","lemma":"muletier","pos":"ADJ"} ,
		{"word":"muletière","word_nosc":"muletiere","lemma":"muletier","pos":"ADJ"} ,
		{"word":"mulhousien","word_nosc":"mulhousien","lemma":"mulhousien","pos":"ADJ"} ,
		{"word":"multi-tâches","word_nosc":"multi-taches","lemma":"multi-tâches","pos":"ADJ"} ,
		{"word":"multicellulaire","word_nosc":"multicellulaire","lemma":"multicellulaire","pos":"ADJ"} ,
		{"word":"multicolore","word_nosc":"multicolore","lemma":"multicolore","pos":"ADJ"} ,
		{"word":"multicolores","word_nosc":"multicolores","lemma":"multicolore","pos":"ADJ"} ,
		{"word":"multiconfessionnel","word_nosc":"multiconfessionnel","lemma":"multiconfessionnel","pos":"ADJ"} ,
		{"word":"multicouche","word_nosc":"multicouche","lemma":"multicouche","pos":"ADJ"} ,
		{"word":"multiculturel","word_nosc":"multiculturel","lemma":"multiculturel","pos":"ADJ"} ,
		{"word":"multiculturelle","word_nosc":"multiculturelle","lemma":"multiculturel","pos":"ADJ"} ,
		{"word":"multidimensionnel","word_nosc":"multidimensionnel","lemma":"multidimensionnel","pos":"ADJ"} ,
		{"word":"multidimensionnelle","word_nosc":"multidimensionnelle","lemma":"multidimensionnel","pos":"ADJ"} ,
		{"word":"multidimensionnels","word_nosc":"multidimensionnels","lemma":"multidimensionnel","pos":"ADJ"} ,
		{"word":"multidisciplinaire","word_nosc":"multidisciplinaire","lemma":"multidisciplinaire","pos":"ADJ"} ,
		{"word":"multifamilial","word_nosc":"multifamilial","lemma":"multifamilial","pos":"ADJ"} ,
		{"word":"multifonction","word_nosc":"multifonction","lemma":"multifonction","pos":"ADJ"} ,
		{"word":"multifonctionnel","word_nosc":"multifonctionnel","lemma":"multifonctionnel","pos":"ADJ"} ,
		{"word":"multifonctions","word_nosc":"multifonctions","lemma":"multifonctions","pos":"ADJ"} ,
		{"word":"multiforme","word_nosc":"multiforme","lemma":"multiforme","pos":"ADJ"} ,
		{"word":"multiformes","word_nosc":"multiformes","lemma":"multiforme","pos":"ADJ"} ,
		{"word":"multifréquences","word_nosc":"multifrequences","lemma":"multifréquence","pos":"ADJ"} ,
		{"word":"multigrade","word_nosc":"multigrade","lemma":"multigrade","pos":"ADJ"} ,
		{"word":"multimilliardaire","word_nosc":"multimilliardaire","lemma":"multimilliardaire","pos":"ADJ"} ,
		{"word":"multimillionnaire","word_nosc":"multimillionnaire","lemma":"multimillionnaire","pos":"ADJ"} ,
		{"word":"multimillionnaires","word_nosc":"multimillionnaires","lemma":"multimillionnaire","pos":"ADJ"} ,
		{"word":"multimédia","word_nosc":"multimedia","lemma":"multimédia","pos":"ADJ"} ,
		{"word":"multinational","word_nosc":"multinational","lemma":"multinational","pos":"ADJ"} ,
		{"word":"multinationale","word_nosc":"multinationale","lemma":"multinational","pos":"ADJ"} ,
		{"word":"multinationales","word_nosc":"multinationales","lemma":"multinational","pos":"ADJ"} ,
		{"word":"multinationaux","word_nosc":"multinationaux","lemma":"multinational","pos":"ADJ"} ,
		{"word":"multipartite","word_nosc":"multipartite","lemma":"multipartite","pos":"ADJ"} ,
		{"word":"multiple","word_nosc":"multiple","lemma":"multiple","pos":"ADJ"} ,
		{"word":"multiples","word_nosc":"multiples","lemma":"multiple","pos":"ADJ"} ,
		{"word":"multiplex","word_nosc":"multiplex","lemma":"multiplex","pos":"ADJ"} ,
		{"word":"multiplicateur","word_nosc":"multiplicateur","lemma":"multiplicateur","pos":"ADJ"} ,
		{"word":"multiplié","word_nosc":"multiplie","lemma":"multiplié","pos":"ADJ"} ,
		{"word":"multipliée","word_nosc":"multipliee","lemma":"multiplié","pos":"ADJ"} ,
		{"word":"multipliées","word_nosc":"multipliees","lemma":"multiplié","pos":"ADJ"} ,
		{"word":"multipliés","word_nosc":"multiplies","lemma":"multiplié","pos":"ADJ"} ,
		{"word":"multipoints","word_nosc":"multipoints","lemma":"multipoints","pos":"ADJ"} ,
		{"word":"multiracial","word_nosc":"multiracial","lemma":"multiracial","pos":"ADJ"} ,
		{"word":"multiraciale","word_nosc":"multiraciale","lemma":"multiracial","pos":"ADJ"} ,
		{"word":"multiraciaux","word_nosc":"multiraciaux","lemma":"multiracial","pos":"ADJ"} ,
		{"word":"multirisque","word_nosc":"multirisque","lemma":"multirisque","pos":"ADJ"} ,
		{"word":"multiséculaires","word_nosc":"multiseculaires","lemma":"multiséculaire","pos":"ADJ"} ,
		{"word":"multitâche","word_nosc":"multitache","lemma":"multitâche","pos":"ADJ"} ,
		{"word":"multitâches","word_nosc":"multitaches","lemma":"multitâche","pos":"ADJ"} ,
		{"word":"mulâtre","word_nosc":"mulatre","lemma":"mulâtre","pos":"ADJ"} ,
		{"word":"mulâtres","word_nosc":"mulatres","lemma":"mulâtre","pos":"ADJ"} ,
		{"word":"muni","word_nosc":"muni","lemma":"muni","pos":"ADJ"} ,
		{"word":"munichois","word_nosc":"munichois","lemma":"munichois","pos":"ADJ"} ,
		{"word":"munichoise","word_nosc":"munichoise","lemma":"munichois","pos":"ADJ"} ,
		{"word":"municipal","word_nosc":"municipal","lemma":"municipal","pos":"ADJ"} ,
		{"word":"municipale","word_nosc":"municipale","lemma":"municipal","pos":"ADJ"} ,
		{"word":"municipales","word_nosc":"municipales","lemma":"municipal","pos":"ADJ"} ,
		{"word":"municipaux","word_nosc":"municipaux","lemma":"municipal","pos":"ADJ"} ,
		{"word":"munie","word_nosc":"munie","lemma":"muni","pos":"ADJ"} ,
		{"word":"munificente","word_nosc":"munificente","lemma":"munificent","pos":"ADJ"} ,
		{"word":"munificents","word_nosc":"munificents","lemma":"munificent","pos":"ADJ"} ,
		{"word":"munis","word_nosc":"munis","lemma":"muni","pos":"ADJ"} ,
		{"word":"muqueuse","word_nosc":"muqueuse","lemma":"muqueux","pos":"ADJ"} ,
		{"word":"muqueuses","word_nosc":"muqueuses","lemma":"muqueux","pos":"ADJ"} ,
		{"word":"muqueux","word_nosc":"muqueux","lemma":"muqueux","pos":"ADJ"} ,
		{"word":"mural","word_nosc":"mural","lemma":"mural","pos":"ADJ"} ,
		{"word":"murale","word_nosc":"murale","lemma":"mural","pos":"ADJ"} ,
		{"word":"murales","word_nosc":"murales","lemma":"mural","pos":"ADJ"} ,
		{"word":"muraux","word_nosc":"muraux","lemma":"mural","pos":"ADJ"} ,
		{"word":"muriatique","word_nosc":"muriatique","lemma":"muriatique","pos":"ADJ"} ,
		{"word":"murmurant","word_nosc":"murmurant","lemma":"murmurant","pos":"ADJ"} ,
		{"word":"murmurante","word_nosc":"murmurante","lemma":"murmurant","pos":"ADJ"} ,
		{"word":"murmurantes","word_nosc":"murmurantes","lemma":"murmurant","pos":"ADJ"} ,
		{"word":"murmurants","word_nosc":"murmurants","lemma":"murmurant","pos":"ADJ"} ,
		{"word":"mus","word_nosc":"mus","lemma":"mu","pos":"ADJ"} ,
		{"word":"musagètes","word_nosc":"musagetes","lemma":"musagète","pos":"ADJ"} ,
		{"word":"musard","word_nosc":"musard","lemma":"musard","pos":"ADJ"} ,
		{"word":"musards","word_nosc":"musards","lemma":"musard","pos":"ADJ"} ,
		{"word":"musclé","word_nosc":"muscle","lemma":"musclé","pos":"ADJ"} ,
		{"word":"musclée","word_nosc":"musclee","lemma":"musclé","pos":"ADJ"} ,
		{"word":"musclées","word_nosc":"musclees","lemma":"musclé","pos":"ADJ"} ,
		{"word":"musclés","word_nosc":"muscles","lemma":"musclé","pos":"ADJ"} ,
		{"word":"musculaire","word_nosc":"musculaire","lemma":"musculaire","pos":"ADJ"} ,
		{"word":"musculaires","word_nosc":"musculaires","lemma":"musculaire","pos":"ADJ"} ,
		{"word":"musculeuse","word_nosc":"musculeuse","lemma":"musculeux","pos":"ADJ"} ,
		{"word":"musculeuses","word_nosc":"musculeuses","lemma":"musculeux","pos":"ADJ"} ,
		{"word":"musculeux","word_nosc":"musculeux","lemma":"musculeux","pos":"ADJ"} ,
		{"word":"musical","word_nosc":"musical","lemma":"musical","pos":"ADJ"} ,
		{"word":"musicale","word_nosc":"musicale","lemma":"musical","pos":"ADJ"} ,
		{"word":"musicales","word_nosc":"musicales","lemma":"musical","pos":"ADJ"} ,
		{"word":"musicaux","word_nosc":"musicaux","lemma":"musical","pos":"ADJ"} ,
		{"word":"musicien","word_nosc":"musicien","lemma":"musicien","pos":"ADJ"} ,
		{"word":"musicienne","word_nosc":"musicienne","lemma":"musicien","pos":"ADJ"} ,
		{"word":"musiciennes","word_nosc":"musiciennes","lemma":"musicien","pos":"ADJ"} ,
		{"word":"musiciens","word_nosc":"musiciens","lemma":"musicien","pos":"ADJ"} ,
		{"word":"musicologique","word_nosc":"musicologique","lemma":"musicologique","pos":"ADJ"} ,
		{"word":"musqué","word_nosc":"musque","lemma":"musqué","pos":"ADJ"} ,
		{"word":"musquée","word_nosc":"musquee","lemma":"musqué","pos":"ADJ"} ,
		{"word":"musquées","word_nosc":"musquees","lemma":"musqué","pos":"ADJ"} ,
		{"word":"musqués","word_nosc":"musques","lemma":"musqué","pos":"ADJ"} ,
		{"word":"mussolinien","word_nosc":"mussolinien","lemma":"mussolinien","pos":"ADJ"} ,
		{"word":"mussolinienne","word_nosc":"mussolinienne","lemma":"mussolinien","pos":"ADJ"} ,
		{"word":"mussoliniens","word_nosc":"mussoliniens","lemma":"mussolinien","pos":"ADJ"} ,
		{"word":"musulman","word_nosc":"musulman","lemma":"musulman","pos":"ADJ"} ,
		{"word":"musulmane","word_nosc":"musulmane","lemma":"musulman","pos":"ADJ"} ,
		{"word":"musulmanes","word_nosc":"musulmanes","lemma":"musulman","pos":"ADJ"} ,
		{"word":"musulmans","word_nosc":"musulmans","lemma":"musulman","pos":"ADJ"} ,
		{"word":"muséologique","word_nosc":"museologique","lemma":"muséologique","pos":"ADJ"} ,
		{"word":"mutable","word_nosc":"mutable","lemma":"mutable","pos":"ADJ"} ,
		{"word":"mutables","word_nosc":"mutables","lemma":"mutable","pos":"ADJ"} ,
		{"word":"mutagène","word_nosc":"mutagene","lemma":"mutagène","pos":"ADJ"} ,
		{"word":"mutant","word_nosc":"mutant","lemma":"mutant","pos":"ADJ"} ,
		{"word":"mutante","word_nosc":"mutante","lemma":"mutant","pos":"ADJ"} ,
		{"word":"mutantes","word_nosc":"mutantes","lemma":"mutant","pos":"ADJ"} ,
		{"word":"mutants","word_nosc":"mutants","lemma":"mutant","pos":"ADJ"} ,
		{"word":"mutilant","word_nosc":"mutilant","lemma":"mutilant","pos":"ADJ"} ,
		{"word":"mutilante","word_nosc":"mutilante","lemma":"mutilant","pos":"ADJ"} ,
		{"word":"mutilateur","word_nosc":"mutilateur","lemma":"mutilateur","pos":"ADJ"} ,
		{"word":"mutilé","word_nosc":"mutile","lemma":"mutilé","pos":"ADJ"} ,
		{"word":"mutilée","word_nosc":"mutilee","lemma":"mutilé","pos":"ADJ"} ,
		{"word":"mutilées","word_nosc":"mutilees","lemma":"mutilé","pos":"ADJ"} ,
		{"word":"mutilés","word_nosc":"mutiles","lemma":"mutilé","pos":"ADJ"} ,
		{"word":"mutin","word_nosc":"mutin","lemma":"mutin","pos":"ADJ"} ,
		{"word":"mutine","word_nosc":"mutine","lemma":"mutin","pos":"ADJ"} ,
		{"word":"mutines","word_nosc":"mutines","lemma":"mutin","pos":"ADJ"} ,
		{"word":"mutins","word_nosc":"mutins","lemma":"mutin","pos":"ADJ"} ,
		{"word":"mutiné","word_nosc":"mutine","lemma":"mutiné","pos":"ADJ"} ,
		{"word":"mutinés","word_nosc":"mutines","lemma":"mutiné","pos":"ADJ"} ,
		{"word":"mutique","word_nosc":"mutique","lemma":"mutique","pos":"ADJ"} ,
		{"word":"mutuel","word_nosc":"mutuel","lemma":"mutuel","pos":"ADJ"} ,
		{"word":"mutuelle","word_nosc":"mutuelle","lemma":"mutuel","pos":"ADJ"} ,
		{"word":"mutuelles","word_nosc":"mutuelles","lemma":"mutuel","pos":"ADJ"} ,
		{"word":"mutuels","word_nosc":"mutuels","lemma":"mutuel","pos":"ADJ"} ,
		{"word":"mycénien","word_nosc":"mycenien","lemma":"mycénien","pos":"ADJ"} ,
		{"word":"mycéniennes","word_nosc":"myceniennes","lemma":"mycénien","pos":"ADJ"} ,
		{"word":"mycéniens","word_nosc":"myceniens","lemma":"mycénien","pos":"ADJ"} ,
		{"word":"myocardique","word_nosc":"myocardique","lemma":"myocardique","pos":"ADJ"} ,
		{"word":"myopathique","word_nosc":"myopathique","lemma":"myopathique","pos":"ADJ"} ,
		{"word":"myope","word_nosc":"myope","lemma":"myope","pos":"ADJ"} ,
		{"word":"myopes","word_nosc":"myopes","lemma":"myope","pos":"ADJ"} ,
		{"word":"mystagogique","word_nosc":"mystagogique","lemma":"mystagogique","pos":"ADJ"} ,
		{"word":"mystificateurs","word_nosc":"mystificateurs","lemma":"mystificateur","pos":"ADJ"} ,
		{"word":"mystique","word_nosc":"mystique","lemma":"mystique","pos":"ADJ"} ,
		{"word":"mystiques","word_nosc":"mystiques","lemma":"mystique","pos":"ADJ"} ,
		{"word":"mystérieuse","word_nosc":"mysterieuse","lemma":"mystérieux","pos":"ADJ"} ,
		{"word":"mystérieuses","word_nosc":"mysterieuses","lemma":"mystérieux","pos":"ADJ"} ,
		{"word":"mystérieux","word_nosc":"mysterieux","lemma":"mystérieux","pos":"ADJ"} ,
		{"word":"mythique","word_nosc":"mythique","lemma":"mythique","pos":"ADJ"} ,
		{"word":"mythiques","word_nosc":"mythiques","lemma":"mythique","pos":"ADJ"} ,
		{"word":"mythologique","word_nosc":"mythologique","lemma":"mythologique","pos":"ADJ"} ,
		{"word":"mythologiques","word_nosc":"mythologiques","lemma":"mythologique","pos":"ADJ"} ,
		{"word":"mythomane","word_nosc":"mythomane","lemma":"mythomane","pos":"ADJ"} ,
		{"word":"mythomanes","word_nosc":"mythomanes","lemma":"mythomane","pos":"ADJ"} ,
		{"word":"myxoedémateuse","word_nosc":"myxoedemateuse","lemma":"myxoedémateux","pos":"ADJ"} ,
		{"word":"myéloïde","word_nosc":"myeloide","lemma":"myéloïde","pos":"ADJ"} ,
		{"word":"mâconnaise","word_nosc":"maconnaise","lemma":"mâconnais","pos":"ADJ"} ,
		{"word":"mâconnaises","word_nosc":"maconnaises","lemma":"mâconnais","pos":"ADJ"} ,
		{"word":"mâle","word_nosc":"male","lemma":"mâle","pos":"ADJ"} ,
		{"word":"mâles","word_nosc":"males","lemma":"mâle","pos":"ADJ"} ,
		{"word":"mâtiné","word_nosc":"matine","lemma":"mâtiné","pos":"ADJ"} ,
		{"word":"méandreux","word_nosc":"meandreux","lemma":"méandreux","pos":"ADJ"} ,
		{"word":"mécanique","word_nosc":"mecanique","lemma":"mécanique","pos":"ADJ"} ,
		{"word":"mécaniques","word_nosc":"mecaniques","lemma":"mécanique","pos":"ADJ"} ,
		{"word":"mécanistes","word_nosc":"mecanistes","lemma":"mécaniste","pos":"ADJ"} ,
		{"word":"mécanographiques","word_nosc":"mecanographiques","lemma":"mécanographique","pos":"ADJ"} ,
		{"word":"méchant","word_nosc":"mechant","lemma":"méchant","pos":"ADJ"} ,
		{"word":"méchante","word_nosc":"mechante","lemma":"méchant","pos":"ADJ"} ,
		{"word":"méchantes","word_nosc":"mechantes","lemma":"méchant","pos":"ADJ"} ,
		{"word":"méchants","word_nosc":"mechants","lemma":"méchant","pos":"ADJ"} ,
		{"word":"méconnaissable","word_nosc":"meconnaissable","lemma":"méconnaissable","pos":"ADJ"} ,
		{"word":"méconnaissables","word_nosc":"meconnaissables","lemma":"méconnaissable","pos":"ADJ"} ,
		{"word":"méconnu","word_nosc":"meconnu","lemma":"méconnu","pos":"ADJ"} ,
		{"word":"méconnue","word_nosc":"meconnue","lemma":"méconnu","pos":"ADJ"} ,
		{"word":"méconnus","word_nosc":"meconnus","lemma":"méconnu","pos":"ADJ"} ,
		{"word":"mécontent","word_nosc":"mecontent","lemma":"mécontent","pos":"ADJ"} ,
		{"word":"mécontente","word_nosc":"mecontente","lemma":"mécontent","pos":"ADJ"} ,
		{"word":"mécontentes","word_nosc":"mecontentes","lemma":"mécontent","pos":"ADJ"} ,
		{"word":"mécontents","word_nosc":"mecontents","lemma":"mécontent","pos":"ADJ"} ,
		{"word":"mécréant","word_nosc":"mecreant","lemma":"mécréant","pos":"ADJ"} ,
		{"word":"mécréante","word_nosc":"mecreante","lemma":"mécréant","pos":"ADJ"} ,
		{"word":"mécréants","word_nosc":"mecreants","lemma":"mécréant","pos":"ADJ"} ,
		{"word":"médaillé","word_nosc":"medaille","lemma":"médaillé","pos":"ADJ"} ,
		{"word":"médaillée","word_nosc":"medaillee","lemma":"médaillé","pos":"ADJ"} ,
		{"word":"médian","word_nosc":"median","lemma":"médian","pos":"ADJ"} ,
		{"word":"médiane","word_nosc":"mediane","lemma":"médian","pos":"ADJ"} ,
		{"word":"médianes","word_nosc":"medianes","lemma":"médian","pos":"ADJ"} ,
		{"word":"médians","word_nosc":"medians","lemma":"médian","pos":"ADJ"} ,
		{"word":"médiateur","word_nosc":"mediateur","lemma":"médiateur","pos":"ADJ"} ,
		{"word":"médiateurs","word_nosc":"mediateurs","lemma":"médiateur","pos":"ADJ"} ,
		{"word":"médiatique","word_nosc":"mediatique","lemma":"médiatique","pos":"ADJ"} ,
		{"word":"médiatiques","word_nosc":"mediatiques","lemma":"médiatique","pos":"ADJ"} ,
		{"word":"médiatrice","word_nosc":"mediatrice","lemma":"médiateur","pos":"ADJ"} ,
		{"word":"médiatrices","word_nosc":"mediatrices","lemma":"médiateur","pos":"ADJ"} ,
		{"word":"médical","word_nosc":"medical","lemma":"médical","pos":"ADJ"} ,
		{"word":"médicale","word_nosc":"medicale","lemma":"médical","pos":"ADJ"} ,
		{"word":"médicales","word_nosc":"medicales","lemma":"médical","pos":"ADJ"} ,
		{"word":"médicamenteuse","word_nosc":"medicamenteuse","lemma":"médicamenteux","pos":"ADJ"} ,
		{"word":"médicamenteux","word_nosc":"medicamenteux","lemma":"médicamenteux","pos":"ADJ"} ,
		{"word":"médicaux","word_nosc":"medicaux","lemma":"médical","pos":"ADJ"} ,
		{"word":"médicinal","word_nosc":"medicinal","lemma":"médicinal","pos":"ADJ"} ,
		{"word":"médicinale","word_nosc":"medicinale","lemma":"médicinal","pos":"ADJ"} ,
		{"word":"médicinales","word_nosc":"medicinales","lemma":"médicinal","pos":"ADJ"} ,
		{"word":"médicinaux","word_nosc":"medicinaux","lemma":"médicinal","pos":"ADJ"} ,
		{"word":"médico-légal","word_nosc":"medico-legal","lemma":"médico-légal","pos":"ADJ"} ,
		{"word":"médico-légale","word_nosc":"medico-legale","lemma":"médico-légal","pos":"ADJ"} ,
		{"word":"médico-légales","word_nosc":"medico-legales","lemma":"médico-légal","pos":"ADJ"} ,
		{"word":"médico-légaux","word_nosc":"medico-legaux","lemma":"médico-légal","pos":"ADJ"} ,
		{"word":"médico-psychologique","word_nosc":"medico-psychologique","lemma":"médico-psychologique","pos":"ADJ"} ,
		{"word":"médico-social","word_nosc":"medico-social","lemma":"médico-social","pos":"ADJ"} ,
		{"word":"médiocre","word_nosc":"mediocre","lemma":"médiocre","pos":"ADJ"} ,
		{"word":"médiocres","word_nosc":"mediocres","lemma":"médiocre","pos":"ADJ"} ,
		{"word":"médisant","word_nosc":"medisant","lemma":"médisant","pos":"ADJ"} ,
		{"word":"médisante","word_nosc":"medisante","lemma":"médisant","pos":"ADJ"} ,
		{"word":"méditant","word_nosc":"meditant","lemma":"méditant","pos":"ADJ"} ,
		{"word":"méditatif","word_nosc":"meditatif","lemma":"méditatif","pos":"ADJ"} ,
		{"word":"méditatifs","word_nosc":"meditatifs","lemma":"méditatif","pos":"ADJ"} ,
		{"word":"méditative","word_nosc":"meditative","lemma":"méditatif","pos":"ADJ"} ,
		{"word":"méditatives","word_nosc":"meditatives","lemma":"méditatif","pos":"ADJ"} ,
		{"word":"méditerranée","word_nosc":"mediterranee","lemma":"méditerrané","pos":"ADJ"} ,
		{"word":"méditerranéen","word_nosc":"mediterraneen","lemma":"méditerranéen","pos":"ADJ"} ,
		{"word":"méditerranéenne","word_nosc":"mediterraneenne","lemma":"méditerranéen","pos":"ADJ"} ,
		{"word":"méditerranéennes","word_nosc":"mediterraneennes","lemma":"méditerranéen","pos":"ADJ"} ,
		{"word":"méditerranéens","word_nosc":"mediterraneens","lemma":"méditerranéen","pos":"ADJ"} ,
		{"word":"médiumnique","word_nosc":"mediumnique","lemma":"médiumnique","pos":"ADJ"} ,
		{"word":"médiumniques","word_nosc":"mediumniques","lemma":"médiumnique","pos":"ADJ"} ,
		{"word":"médiéval","word_nosc":"medieval","lemma":"médiéval","pos":"ADJ"} ,
		{"word":"médiévale","word_nosc":"medievale","lemma":"médiéval","pos":"ADJ"} ,
		{"word":"médiévales","word_nosc":"medievales","lemma":"médiéval","pos":"ADJ"} ,
		{"word":"médiévaux","word_nosc":"medievaux","lemma":"médiéval","pos":"ADJ"} ,
		{"word":"médullaire","word_nosc":"medullaire","lemma":"médullaire","pos":"ADJ"} ,
		{"word":"médusé","word_nosc":"meduse","lemma":"médusé","pos":"ADJ"} ,
		{"word":"médusée","word_nosc":"medusee","lemma":"médusé","pos":"ADJ"} ,
		{"word":"médusées","word_nosc":"medusees","lemma":"médusé","pos":"ADJ"} ,
		{"word":"médusés","word_nosc":"meduses","lemma":"médusé","pos":"ADJ"} ,
		{"word":"méfiant","word_nosc":"mefiant","lemma":"méfiant","pos":"ADJ"} ,
		{"word":"méfiante","word_nosc":"mefiante","lemma":"méfiant","pos":"ADJ"} ,
		{"word":"méfiantes","word_nosc":"mefiantes","lemma":"méfiant","pos":"ADJ"} ,
		{"word":"méfiants","word_nosc":"mefiants","lemma":"méfiant","pos":"ADJ"} ,
		{"word":"méga","word_nosc":"mega","lemma":"méga","pos":"ADJ"} ,
		{"word":"mégalithique","word_nosc":"megalithique","lemma":"mégalithique","pos":"ADJ"} ,
		{"word":"mégalo","word_nosc":"megalo","lemma":"mégalo","pos":"ADJ"} ,
		{"word":"mégalomane","word_nosc":"megalomane","lemma":"mégalomane","pos":"ADJ"} ,
		{"word":"mégalomanes","word_nosc":"megalomanes","lemma":"mégalomane","pos":"ADJ"} ,
		{"word":"mégalomaniaque","word_nosc":"megalomaniaque","lemma":"mégalomaniaque","pos":"ADJ"} ,
		{"word":"mégalos","word_nosc":"megalos","lemma":"mégalo","pos":"ADJ"} ,
		{"word":"mélancolieux","word_nosc":"melancolieux","lemma":"mélancolieux","pos":"ADJ"} ,
		{"word":"mélancolique","word_nosc":"melancolique","lemma":"mélancolique","pos":"ADJ"} ,
		{"word":"mélancoliques","word_nosc":"melancoliques","lemma":"mélancolique","pos":"ADJ"} ,
		{"word":"mélangeant","word_nosc":"melangeant","lemma":"mélangeant","pos":"ADJ"} ,
		{"word":"mélangé","word_nosc":"melange","lemma":"mélangé","pos":"ADJ"} ,
		{"word":"mélangée","word_nosc":"melangee","lemma":"mélangé","pos":"ADJ"} ,
		{"word":"mélangées","word_nosc":"melangees","lemma":"mélangé","pos":"ADJ"} ,
		{"word":"mélangés","word_nosc":"melanges","lemma":"mélangé","pos":"ADJ"} ,
		{"word":"mélanésiennes","word_nosc":"melanesiennes","lemma":"mélanésien","pos":"ADJ"} ,
		{"word":"mélodieuse","word_nosc":"melodieuse","lemma":"mélodieux","pos":"ADJ"} ,
		{"word":"mélodieuses","word_nosc":"melodieuses","lemma":"mélodieux","pos":"ADJ"} ,
		{"word":"mélodieux","word_nosc":"melodieux","lemma":"mélodieux","pos":"ADJ"} ,
		{"word":"mélodique","word_nosc":"melodique","lemma":"mélodique","pos":"ADJ"} ,
		{"word":"mélodiques","word_nosc":"melodiques","lemma":"mélodique","pos":"ADJ"} ,
		{"word":"mélodramatique","word_nosc":"melodramatique","lemma":"mélodramatique","pos":"ADJ"} ,
		{"word":"mélodramatiques","word_nosc":"melodramatiques","lemma":"mélodramatique","pos":"ADJ"} ,
		{"word":"mélomane","word_nosc":"melomane","lemma":"mélomane","pos":"ADJ"} ,
		{"word":"mémorable","word_nosc":"memorable","lemma":"mémorable","pos":"ADJ"} ,
		{"word":"mémorables","word_nosc":"memorables","lemma":"mémorable","pos":"ADJ"} ,
		{"word":"mémoriel","word_nosc":"memoriel","lemma":"mémoriel","pos":"ADJ"} ,
		{"word":"mémorielle","word_nosc":"memorielle","lemma":"mémoriel","pos":"ADJ"} ,
		{"word":"mémoriels","word_nosc":"memoriels","lemma":"mémoriel","pos":"ADJ"} ,
		{"word":"mémorisables","word_nosc":"memorisables","lemma":"mémorisable","pos":"ADJ"} ,
		{"word":"ménager","word_nosc":"menager","lemma":"ménager","pos":"ADJ"} ,
		{"word":"ménagers","word_nosc":"menagers","lemma":"ménager","pos":"ADJ"} ,
		{"word":"ménagère","word_nosc":"menagere","lemma":"ménager","pos":"ADJ"} ,
		{"word":"ménagères","word_nosc":"menageres","lemma":"ménager","pos":"ADJ"} ,
		{"word":"méningée","word_nosc":"meningee","lemma":"méningé","pos":"ADJ"} ,
		{"word":"ménopausé","word_nosc":"menopause","lemma":"ménopausé","pos":"ADJ"} ,
		{"word":"ménopausée","word_nosc":"menopausee","lemma":"ménopausé","pos":"ADJ"} ,
		{"word":"ménopausées","word_nosc":"menopausees","lemma":"ménopausé","pos":"ADJ"} ,
		{"word":"méphistophélique","word_nosc":"mephistophelique","lemma":"méphistophélique","pos":"ADJ"} ,
		{"word":"méphistophéliques","word_nosc":"mephistopheliques","lemma":"méphistophélique","pos":"ADJ"} ,
		{"word":"méphitique","word_nosc":"mephitique","lemma":"méphitique","pos":"ADJ"} ,
		{"word":"méphitiques","word_nosc":"mephitiques","lemma":"méphitique","pos":"ADJ"} ,
		{"word":"méplats","word_nosc":"meplats","lemma":"méplat","pos":"ADJ"} ,
		{"word":"méprisable","word_nosc":"meprisable","lemma":"méprisable","pos":"ADJ"} ,
		{"word":"méprisables","word_nosc":"meprisables","lemma":"méprisable","pos":"ADJ"} ,
		{"word":"méprisant","word_nosc":"meprisant","lemma":"méprisant","pos":"ADJ"} ,
		{"word":"méprisante","word_nosc":"meprisante","lemma":"méprisant","pos":"ADJ"} ,
		{"word":"méprisantes","word_nosc":"meprisantes","lemma":"méprisant","pos":"ADJ"} ,
		{"word":"méprisants","word_nosc":"meprisants","lemma":"méprisant","pos":"ADJ"} ,
		{"word":"méridien","word_nosc":"meridien","lemma":"méridien","pos":"ADJ"} ,
		{"word":"méridienne","word_nosc":"meridienne","lemma":"méridien","pos":"ADJ"} ,
		{"word":"méridiennes","word_nosc":"meridiennes","lemma":"méridien","pos":"ADJ"} ,
		{"word":"méridional","word_nosc":"meridional","lemma":"méridional","pos":"ADJ"} ,
		{"word":"méridionale","word_nosc":"meridionale","lemma":"méridional","pos":"ADJ"} ,
		{"word":"méridionales","word_nosc":"meridionales","lemma":"méridional","pos":"ADJ"} ,
		{"word":"méridionaux","word_nosc":"meridionaux","lemma":"méridional","pos":"ADJ"} ,
		{"word":"méritant","word_nosc":"meritant","lemma":"méritant","pos":"ADJ"} ,
		{"word":"méritante","word_nosc":"meritante","lemma":"méritant","pos":"ADJ"} ,
		{"word":"méritantes","word_nosc":"meritantes","lemma":"méritant","pos":"ADJ"} ,
		{"word":"méritants","word_nosc":"meritants","lemma":"méritant","pos":"ADJ"} ,
		{"word":"méritoire","word_nosc":"meritoire","lemma":"méritoire","pos":"ADJ"} ,
		{"word":"méritoires","word_nosc":"meritoires","lemma":"méritoire","pos":"ADJ"} ,
		{"word":"mérovingien","word_nosc":"merovingien","lemma":"mérovingien","pos":"ADJ"} ,
		{"word":"mérovingienne","word_nosc":"merovingienne","lemma":"mérovingien","pos":"ADJ"} ,
		{"word":"mérovingiennes","word_nosc":"merovingiennes","lemma":"mérovingien","pos":"ADJ"} ,
		{"word":"mésentérique","word_nosc":"mesenterique","lemma":"mésentérique","pos":"ADJ"} ,
		{"word":"mésodermiques","word_nosc":"mesodermiques","lemma":"mésodermique","pos":"ADJ"} ,
		{"word":"mésomorphe","word_nosc":"mesomorphe","lemma":"mésomorphe","pos":"ADJ"} ,
		{"word":"mésomorphique","word_nosc":"mesomorphique","lemma":"mésomorphique","pos":"ADJ"} ,
		{"word":"mésopotamien","word_nosc":"mesopotamien","lemma":"mésopotamien","pos":"ADJ"} ,
		{"word":"mésopotamiens","word_nosc":"mesopotamiens","lemma":"mésopotamien","pos":"ADJ"} ,
		{"word":"mésozoïque","word_nosc":"mesozoique","lemma":"mésozoïque","pos":"ADJ"} ,
		{"word":"métabolique","word_nosc":"metabolique","lemma":"métabolique","pos":"ADJ"} ,
		{"word":"métacarpien","word_nosc":"metacarpien","lemma":"métacarpien","pos":"ADJ"} ,
		{"word":"métallique","word_nosc":"metallique","lemma":"métallique","pos":"ADJ"} ,
		{"word":"métalliques","word_nosc":"metalliques","lemma":"métallique","pos":"ADJ"} ,
		{"word":"métallurgique","word_nosc":"metallurgique","lemma":"métallurgique","pos":"ADJ"} ,
		{"word":"métallurgiques","word_nosc":"metallurgiques","lemma":"métallurgique","pos":"ADJ"} ,
		{"word":"métamorphique","word_nosc":"metamorphique","lemma":"métamorphique","pos":"ADJ"} ,
		{"word":"métaphorique","word_nosc":"metaphorique","lemma":"métaphorique","pos":"ADJ"} ,
		{"word":"métaphoriques","word_nosc":"metaphoriques","lemma":"métaphorique","pos":"ADJ"} ,
		{"word":"métaphysique","word_nosc":"metaphysique","lemma":"métaphysique","pos":"ADJ"} ,
		{"word":"métaphysiques","word_nosc":"metaphysiques","lemma":"métaphysique","pos":"ADJ"} ,
		{"word":"métapsychique","word_nosc":"metapsychique","lemma":"métapsychique","pos":"ADJ"} ,
		{"word":"métapsychologique","word_nosc":"metapsychologique","lemma":"métapsychologique","pos":"ADJ"} ,
		{"word":"métastatique","word_nosc":"metastatique","lemma":"métastatique","pos":"ADJ"} ,
		{"word":"métatarsienne","word_nosc":"metatarsienne","lemma":"métatarsien","pos":"ADJ"} ,
		{"word":"méthodique","word_nosc":"methodique","lemma":"méthodique","pos":"ADJ"} ,
		{"word":"méthodiques","word_nosc":"methodiques","lemma":"méthodique","pos":"ADJ"} ,
		{"word":"méthodiste","word_nosc":"methodiste","lemma":"méthodiste","pos":"ADJ"} ,
		{"word":"méthodistes","word_nosc":"methodistes","lemma":"méthodiste","pos":"ADJ"} ,
		{"word":"méthodologique","word_nosc":"methodologique","lemma":"méthodologique","pos":"ADJ"} ,
		{"word":"méthylique","word_nosc":"methylique","lemma":"méthylique","pos":"ADJ"} ,
		{"word":"méticuleuse","word_nosc":"meticuleuse","lemma":"méticuleux","pos":"ADJ"} ,
		{"word":"méticuleuses","word_nosc":"meticuleuses","lemma":"méticuleux","pos":"ADJ"} ,
		{"word":"méticuleux","word_nosc":"meticuleux","lemma":"méticuleux","pos":"ADJ"} ,
		{"word":"métis","word_nosc":"metis","lemma":"métis","pos":"ADJ"} ,
		{"word":"métisse","word_nosc":"metisse","lemma":"métis","pos":"ADJ"} ,
		{"word":"métisses","word_nosc":"metisses","lemma":"métis","pos":"ADJ"} ,
		{"word":"métrique","word_nosc":"metrique","lemma":"métrique","pos":"ADJ"} ,
		{"word":"métropolitain","word_nosc":"metropolitain","lemma":"métropolitain","pos":"ADJ"} ,
		{"word":"métropolitaine","word_nosc":"metropolitaine","lemma":"métropolitain","pos":"ADJ"} ,
		{"word":"métropolitaines","word_nosc":"metropolitaines","lemma":"métropolitain","pos":"ADJ"} ,
		{"word":"métropolitains","word_nosc":"metropolitains","lemma":"métropolitain","pos":"ADJ"} ,
		{"word":"météorique","word_nosc":"meteorique","lemma":"météorique","pos":"ADJ"} ,
		{"word":"météoritique","word_nosc":"meteoritique","lemma":"météoritique","pos":"ADJ"} ,
		{"word":"météorologique","word_nosc":"meteorologique","lemma":"météorologique","pos":"ADJ"} ,
		{"word":"météorologiques","word_nosc":"meteorologiques","lemma":"météorologique","pos":"ADJ"} ,
		{"word":"mêlé","word_nosc":"mele","lemma":"mêlé","pos":"ADJ"} ,
		{"word":"mêlés","word_nosc":"meles","lemma":"mêlé","pos":"ADJ"} ,
		{"word":"même","word_nosc":"meme","lemma":"même","pos":"ADJ"} ,
		{"word":"mêmes","word_nosc":"memes","lemma":"même","pos":"ADJ"} ,
		{"word":"mûr","word_nosc":"mur","lemma":"mûr","pos":"ADJ"} ,
		{"word":"mûre","word_nosc":"mure","lemma":"mûr","pos":"ADJ"} ,
		{"word":"mûres","word_nosc":"mures","lemma":"mûr","pos":"ADJ"} ,
		{"word":"mûrissant","word_nosc":"murissant","lemma":"mûrissant","pos":"ADJ"} ,
		{"word":"mûrissante","word_nosc":"murissante","lemma":"mûrissant","pos":"ADJ"} ,
		{"word":"mûrissantes","word_nosc":"murissantes","lemma":"mûrissant","pos":"ADJ"} ,
		{"word":"mûrissants","word_nosc":"murissants","lemma":"mûrissant","pos":"ADJ"} ,
		{"word":"mûrs","word_nosc":"murs","lemma":"mûr","pos":"ADJ"} ,
		{"word":"nacré","word_nosc":"nacre","lemma":"nacré","pos":"ADJ"} ,
		{"word":"nacrée","word_nosc":"nacree","lemma":"nacré","pos":"ADJ"} ,
		{"word":"nacrées","word_nosc":"nacrees","lemma":"nacré","pos":"ADJ"} ,
		{"word":"nacrés","word_nosc":"nacres","lemma":"nacré","pos":"ADJ"} ,
		{"word":"nageant","word_nosc":"nageant","lemma":"nageant","pos":"ADJ"} ,
		{"word":"nageante","word_nosc":"nageante","lemma":"nageant","pos":"ADJ"} ,
		{"word":"nain","word_nosc":"nain","lemma":"nain","pos":"ADJ"} ,
		{"word":"naine","word_nosc":"naine","lemma":"nain","pos":"ADJ"} ,
		{"word":"naines","word_nosc":"naines","lemma":"nain","pos":"ADJ"} ,
		{"word":"nains","word_nosc":"nains","lemma":"nain","pos":"ADJ"} ,
		{"word":"naissant","word_nosc":"naissant","lemma":"naissant","pos":"ADJ"} ,
		{"word":"naissante","word_nosc":"naissante","lemma":"naissant","pos":"ADJ"} ,
		{"word":"naissantes","word_nosc":"naissantes","lemma":"naissant","pos":"ADJ"} ,
		{"word":"naissants","word_nosc":"naissants","lemma":"naissant","pos":"ADJ"} ,
		{"word":"naisseur","word_nosc":"naisseur","lemma":"naisseur","pos":"ADJ"} ,
		{"word":"nancéiens","word_nosc":"nanceiens","lemma":"nancéien","pos":"ADJ"} ,
		{"word":"nantais","word_nosc":"nantais","lemma":"nantais","pos":"ADJ"} ,
		{"word":"nantaise","word_nosc":"nantaise","lemma":"nantais","pos":"ADJ"} ,
		{"word":"nanti","word_nosc":"nanti","lemma":"nanti","pos":"ADJ"} ,
		{"word":"nantie","word_nosc":"nantie","lemma":"nanti","pos":"ADJ"} ,
		{"word":"nantis","word_nosc":"nantis","lemma":"nanti","pos":"ADJ"} ,
		{"word":"nap","word_nosc":"nap","lemma":"nap","pos":"ADJ"} ,
		{"word":"napolitain","word_nosc":"napolitain","lemma":"napolitain","pos":"ADJ"} ,
		{"word":"napolitaine","word_nosc":"napolitaine","lemma":"napolitain","pos":"ADJ"} ,
		{"word":"napolitaines","word_nosc":"napolitaines","lemma":"napolitain","pos":"ADJ"} ,
		{"word":"napolitains","word_nosc":"napolitains","lemma":"napolitain","pos":"ADJ"} ,
		{"word":"napoléonien","word_nosc":"napoleonien","lemma":"napoléonien","pos":"ADJ"} ,
		{"word":"napoléonienne","word_nosc":"napoleonienne","lemma":"napoléonien","pos":"ADJ"} ,
		{"word":"napoléoniennes","word_nosc":"napoleoniennes","lemma":"napoléonien","pos":"ADJ"} ,
		{"word":"napoléoniens","word_nosc":"napoleoniens","lemma":"napoléonien","pos":"ADJ"} ,
		{"word":"narbonnais","word_nosc":"narbonnais","lemma":"narbonnais","pos":"ADJ"} ,
		{"word":"narcissique","word_nosc":"narcissique","lemma":"narcissique","pos":"ADJ"} ,
		{"word":"narcissiques","word_nosc":"narcissiques","lemma":"narcissique","pos":"ADJ"} ,
		{"word":"narcoleptique","word_nosc":"narcoleptique","lemma":"narcoleptique","pos":"ADJ"} ,
		{"word":"narcotique","word_nosc":"narcotique","lemma":"narcotique","pos":"ADJ"} ,
		{"word":"narcotiques","word_nosc":"narcotiques","lemma":"narcotique","pos":"ADJ"} ,
		{"word":"narquois","word_nosc":"narquois","lemma":"narquois","pos":"ADJ"} ,
		{"word":"narquoise","word_nosc":"narquoise","lemma":"narquois","pos":"ADJ"} ,
		{"word":"narquoises","word_nosc":"narquoises","lemma":"narquois","pos":"ADJ"} ,
		{"word":"narratif","word_nosc":"narratif","lemma":"narratif","pos":"ADJ"} ,
		{"word":"narratifs","word_nosc":"narratifs","lemma":"narratif","pos":"ADJ"} ,
		{"word":"narrative","word_nosc":"narrative","lemma":"narratif","pos":"ADJ"} ,
		{"word":"narratives","word_nosc":"narratives","lemma":"narratif","pos":"ADJ"} ,
		{"word":"nasal","word_nosc":"nasal","lemma":"nasal","pos":"ADJ"} ,
		{"word":"nasale","word_nosc":"nasale","lemma":"nasal","pos":"ADJ"} ,
		{"word":"nasales","word_nosc":"nasales","lemma":"nasal","pos":"ADJ"} ,
		{"word":"nasaux","word_nosc":"nasaux","lemma":"nasal","pos":"ADJ"} ,
		{"word":"nase","word_nosc":"nase","lemma":"nase","pos":"ADJ"} ,
		{"word":"nases","word_nosc":"nases","lemma":"nase","pos":"ADJ"} ,
		{"word":"nasillard","word_nosc":"nasillard","lemma":"nasillard","pos":"ADJ"} ,
		{"word":"nasillarde","word_nosc":"nasillarde","lemma":"nasillard","pos":"ADJ"} ,
		{"word":"nasillardes","word_nosc":"nasillardes","lemma":"nasillard","pos":"ADJ"} ,
		{"word":"nasillards","word_nosc":"nasillards","lemma":"nasillard","pos":"ADJ"} ,
		{"word":"nassérien","word_nosc":"nasserien","lemma":"nassérien","pos":"ADJ"} ,
		{"word":"natal","word_nosc":"natal","lemma":"natal","pos":"ADJ"} ,
		{"word":"natale","word_nosc":"natale","lemma":"natal","pos":"ADJ"} ,
		{"word":"natales","word_nosc":"natales","lemma":"natal","pos":"ADJ"} ,
		{"word":"natals","word_nosc":"natals","lemma":"natal","pos":"ADJ"} ,
		{"word":"natatoire","word_nosc":"natatoire","lemma":"natatoire","pos":"ADJ"} ,
		{"word":"natif","word_nosc":"natif","lemma":"natif","pos":"ADJ"} ,
		{"word":"natifs","word_nosc":"natifs","lemma":"natif","pos":"ADJ"} ,
		{"word":"national","word_nosc":"national","lemma":"national","pos":"ADJ"} ,
		{"word":"national-socialiste","word_nosc":"national-socialiste","lemma":"national-socialiste","pos":"ADJ"} ,
		{"word":"nationale","word_nosc":"nationale","lemma":"national","pos":"ADJ"} ,
		{"word":"nationale-socialiste","word_nosc":"nationale-socialiste","lemma":"nationale-socialiste","pos":"ADJ"} ,
		{"word":"nationales","word_nosc":"nationales","lemma":"national","pos":"ADJ"} ,
		{"word":"nationaliste","word_nosc":"nationaliste","lemma":"nationaliste","pos":"ADJ"} ,
		{"word":"nationalistes","word_nosc":"nationalistes","lemma":"nationaliste","pos":"ADJ"} ,
		{"word":"nationaux","word_nosc":"nationaux","lemma":"national","pos":"ADJ"} ,
		{"word":"nationaux-socialistes","word_nosc":"nationaux-socialistes","lemma":"nationaux-socialistes","pos":"ADJ"} ,
		{"word":"native","word_nosc":"native","lemma":"natif","pos":"ADJ"} ,
		{"word":"natives","word_nosc":"natives","lemma":"natif","pos":"ADJ"} ,
		{"word":"naturaliste","word_nosc":"naturaliste","lemma":"naturaliste","pos":"ADJ"} ,
		{"word":"naturalistes","word_nosc":"naturalistes","lemma":"naturaliste","pos":"ADJ"} ,
		{"word":"naturalisé","word_nosc":"naturalise","lemma":"naturalisé","pos":"ADJ"} ,
		{"word":"naturalisée","word_nosc":"naturalisee","lemma":"naturalisé","pos":"ADJ"} ,
		{"word":"naturalisés","word_nosc":"naturalises","lemma":"naturalisé","pos":"ADJ"} ,
		{"word":"nature","word_nosc":"nature","lemma":"nature","pos":"ADJ"} ,
		{"word":"naturel","word_nosc":"naturel","lemma":"naturel","pos":"ADJ"} ,
		{"word":"naturelle","word_nosc":"naturelle","lemma":"naturel","pos":"ADJ"} ,
		{"word":"naturelles","word_nosc":"naturelles","lemma":"naturel","pos":"ADJ"} ,
		{"word":"naturels","word_nosc":"naturels","lemma":"naturel","pos":"ADJ"} ,
		{"word":"naturiste","word_nosc":"naturiste","lemma":"naturiste","pos":"ADJ"} ,
		{"word":"naturistes","word_nosc":"naturistes","lemma":"naturiste","pos":"ADJ"} ,
		{"word":"naufragé","word_nosc":"naufrage","lemma":"naufragé","pos":"ADJ"} ,
		{"word":"naufragée","word_nosc":"naufragee","lemma":"naufragé","pos":"ADJ"} ,
		{"word":"naufragées","word_nosc":"naufragees","lemma":"naufragé","pos":"ADJ"} ,
		{"word":"naufragés","word_nosc":"naufrages","lemma":"naufragé","pos":"ADJ"} ,
		{"word":"nauséabond","word_nosc":"nauseabond","lemma":"nauséabond","pos":"ADJ"} ,
		{"word":"nauséabonde","word_nosc":"nauseabonde","lemma":"nauséabond","pos":"ADJ"} ,
		{"word":"nauséabondes","word_nosc":"nauseabondes","lemma":"nauséabond","pos":"ADJ"} ,
		{"word":"nauséabonds","word_nosc":"nauseabonds","lemma":"nauséabond","pos":"ADJ"} ,
		{"word":"nauséeuse","word_nosc":"nauseeuse","lemma":"nauséeux","pos":"ADJ"} ,
		{"word":"nauséeuses","word_nosc":"nauseeuses","lemma":"nauséeux","pos":"ADJ"} ,
		{"word":"nauséeux","word_nosc":"nauseeux","lemma":"nauséeux","pos":"ADJ"} ,
		{"word":"nautique","word_nosc":"nautique","lemma":"nautique","pos":"ADJ"} ,
		{"word":"nautiques","word_nosc":"nautiques","lemma":"nautique","pos":"ADJ"} ,
		{"word":"navajo","word_nosc":"navajo","lemma":"navajo","pos":"ADJ"} ,
		{"word":"navajos","word_nosc":"navajos","lemma":"navajo","pos":"ADJ"} ,
		{"word":"naval","word_nosc":"naval","lemma":"naval","pos":"ADJ"} ,
		{"word":"navale","word_nosc":"navale","lemma":"naval","pos":"ADJ"} ,
		{"word":"navales","word_nosc":"navales","lemma":"naval","pos":"ADJ"} ,
		{"word":"navals","word_nosc":"navals","lemma":"naval","pos":"ADJ"} ,
		{"word":"navarrais","word_nosc":"navarrais","lemma":"navarrais","pos":"ADJ"} ,
		{"word":"navigable","word_nosc":"navigable","lemma":"navigable","pos":"ADJ"} ,
		{"word":"navigables","word_nosc":"navigables","lemma":"navigable","pos":"ADJ"} ,
		{"word":"navigant","word_nosc":"navigant","lemma":"navigant","pos":"ADJ"} ,
		{"word":"navigante","word_nosc":"navigante","lemma":"navigant","pos":"ADJ"} ,
		{"word":"navrant","word_nosc":"navrant","lemma":"navrant","pos":"ADJ"} ,
		{"word":"navrante","word_nosc":"navrante","lemma":"navrant","pos":"ADJ"} ,
		{"word":"navrantes","word_nosc":"navrantes","lemma":"navrant","pos":"ADJ"} ,
		{"word":"navrants","word_nosc":"navrants","lemma":"navrant","pos":"ADJ"} ,
		{"word":"navré","word_nosc":"navre","lemma":"navré","pos":"ADJ"} ,
		{"word":"navrée","word_nosc":"navree","lemma":"navré","pos":"ADJ"} ,
		{"word":"navrées","word_nosc":"navrees","lemma":"navré","pos":"ADJ"} ,
		{"word":"navrés","word_nosc":"navres","lemma":"navré","pos":"ADJ"} ,
		{"word":"nazaréen","word_nosc":"nazareen","lemma":"nazaréen","pos":"ADJ"} ,
		{"word":"naze","word_nosc":"naze","lemma":"naze","pos":"ADJ"} ,
		{"word":"nazes","word_nosc":"nazes","lemma":"naze","pos":"ADJ"} ,
		{"word":"nazi","word_nosc":"nazi","lemma":"nazi","pos":"ADJ"} ,
		{"word":"nazie","word_nosc":"nazie","lemma":"nazi","pos":"ADJ"} ,
		{"word":"nazies","word_nosc":"nazies","lemma":"nazi","pos":"ADJ"} ,
		{"word":"nazis","word_nosc":"nazis","lemma":"nazi","pos":"ADJ"} ,
		{"word":"naïf","word_nosc":"naif","lemma":"naïf","pos":"ADJ"} ,
		{"word":"naïfs","word_nosc":"naifs","lemma":"naïf","pos":"ADJ"} ,
		{"word":"naïve","word_nosc":"naive","lemma":"naïf","pos":"ADJ"} ,
		{"word":"naïves","word_nosc":"naives","lemma":"naïf","pos":"ADJ"} ,
		{"word":"neigeuse","word_nosc":"neigeuse","lemma":"neigeux","pos":"ADJ"} ,
		{"word":"neigeuses","word_nosc":"neigeuses","lemma":"neigeux","pos":"ADJ"} ,
		{"word":"neigeux","word_nosc":"neigeux","lemma":"neigeux","pos":"ADJ"} ,
		{"word":"neptunienne","word_nosc":"neptunienne","lemma":"neptunien","pos":"ADJ"} ,
		{"word":"neptuniens","word_nosc":"neptuniens","lemma":"neptunien","pos":"ADJ"} ,
		{"word":"nerveuse","word_nosc":"nerveuse","lemma":"nerveux","pos":"ADJ"} ,
		{"word":"nerveuses","word_nosc":"nerveuses","lemma":"nerveux","pos":"ADJ"} ,
		{"word":"nerveux","word_nosc":"nerveux","lemma":"nerveux","pos":"ADJ"} ,
		{"word":"nestorien","word_nosc":"nestorien","lemma":"nestorien","pos":"ADJ"} ,
		{"word":"nestorienne","word_nosc":"nestorienne","lemma":"nestorien","pos":"ADJ"} ,
		{"word":"nestoriennes","word_nosc":"nestoriennes","lemma":"nestorien","pos":"ADJ"} ,
		{"word":"nestoriens","word_nosc":"nestoriens","lemma":"nestorien","pos":"ADJ"} ,
		{"word":"net","word_nosc":"net","lemma":"net","pos":"ADJ"} ,
		{"word":"nets","word_nosc":"nets","lemma":"net","pos":"ADJ"} ,
		{"word":"nette","word_nosc":"nette","lemma":"net","pos":"ADJ"} ,
		{"word":"nettes","word_nosc":"nettes","lemma":"net","pos":"ADJ"} ,
		{"word":"nettoyable","word_nosc":"nettoyable","lemma":"nettoyable","pos":"ADJ"} ,
		{"word":"nettoyables","word_nosc":"nettoyables","lemma":"nettoyable","pos":"ADJ"} ,
		{"word":"nettoyant","word_nosc":"nettoyant","lemma":"nettoyant","pos":"ADJ"} ,
		{"word":"nettoyante","word_nosc":"nettoyante","lemma":"nettoyant","pos":"ADJ"} ,
		{"word":"nettoyants","word_nosc":"nettoyants","lemma":"nettoyant","pos":"ADJ"} ,
		{"word":"neuf","word_nosc":"neuf","lemma":"neuf","pos":"ADJ"} ,
		{"word":"neuf","word_nosc":"neuf","lemma":"neuf","pos":"ADJ:num"} ,
		{"word":"neufs","word_nosc":"neufs","lemma":"neuf","pos":"ADJ"} ,
		{"word":"neuneu","word_nosc":"neuneu","lemma":"neuneu","pos":"ADJ"} ,
		{"word":"neural","word_nosc":"neural","lemma":"neural","pos":"ADJ"} ,
		{"word":"neurale","word_nosc":"neurale","lemma":"neural","pos":"ADJ"} ,
		{"word":"neurales","word_nosc":"neurales","lemma":"neural","pos":"ADJ"} ,
		{"word":"neurasthénique","word_nosc":"neurasthenique","lemma":"neurasthénique","pos":"ADJ"} ,
		{"word":"neurasthéniques","word_nosc":"neurastheniques","lemma":"neurasthénique","pos":"ADJ"} ,
		{"word":"neurochimique","word_nosc":"neurochimique","lemma":"neurochimique","pos":"ADJ"} ,
		{"word":"neurologique","word_nosc":"neurologique","lemma":"neurologique","pos":"ADJ"} ,
		{"word":"neurologiques","word_nosc":"neurologiques","lemma":"neurologique","pos":"ADJ"} ,
		{"word":"neuromusculaire","word_nosc":"neuromusculaire","lemma":"neuromusculaire","pos":"ADJ"} ,
		{"word":"neuronal","word_nosc":"neuronal","lemma":"neuronal","pos":"ADJ"} ,
		{"word":"neuronale","word_nosc":"neuronale","lemma":"neuronal","pos":"ADJ"} ,
		{"word":"neuronales","word_nosc":"neuronales","lemma":"neuronal","pos":"ADJ"} ,
		{"word":"neuronaux","word_nosc":"neuronaux","lemma":"neuronal","pos":"ADJ"} ,
		{"word":"neuronique","word_nosc":"neuronique","lemma":"neuronique","pos":"ADJ"} ,
		{"word":"neuroplégique","word_nosc":"neuroplegique","lemma":"neuroplégique","pos":"ADJ"} ,
		{"word":"neuropsychologiques","word_nosc":"neuropsychologiques","lemma":"neuropsychologique","pos":"ADJ"} ,
		{"word":"neurotoxique","word_nosc":"neurotoxique","lemma":"neurotoxique","pos":"ADJ"} ,
		{"word":"neutralisant","word_nosc":"neutralisant","lemma":"neutralisant","pos":"ADJ"} ,
		{"word":"neutralisante","word_nosc":"neutralisante","lemma":"neutralisant","pos":"ADJ"} ,
		{"word":"neutralisateur","word_nosc":"neutralisateur","lemma":"neutralisateur","pos":"ADJ"} ,
		{"word":"neutre","word_nosc":"neutre","lemma":"neutre","pos":"ADJ"} ,
		{"word":"neutres","word_nosc":"neutres","lemma":"neutre","pos":"ADJ"} ,
		{"word":"neutronique","word_nosc":"neutronique","lemma":"neutronique","pos":"ADJ"} ,
		{"word":"neuve","word_nosc":"neuve","lemma":"neuf","pos":"ADJ"} ,
		{"word":"neuves","word_nosc":"neuves","lemma":"neuf","pos":"ADJ"} ,
		{"word":"neuvième","word_nosc":"neuvieme","lemma":"neuvième","pos":"ADJ"} ,
		{"word":"neuvièmes","word_nosc":"neuviemes","lemma":"neuvième","pos":"ADJ"} ,
		{"word":"new","word_nosc":"new","lemma":"new","pos":"ADJ"} ,
		{"word":"new-yorkais","word_nosc":"new-yorkais","lemma":"new-yorkais","pos":"ADJ"} ,
		{"word":"new-yorkaise","word_nosc":"new-yorkaise","lemma":"new-yorkais","pos":"ADJ"} ,
		{"word":"new-yorkaises","word_nosc":"new-yorkaises","lemma":"new-yorkais","pos":"ADJ"} ,
		{"word":"niable","word_nosc":"niable","lemma":"niable","pos":"ADJ"} ,
		{"word":"niais","word_nosc":"niais","lemma":"niais","pos":"ADJ"} ,
		{"word":"niaise","word_nosc":"niaise","lemma":"niais","pos":"ADJ"} ,
		{"word":"niaises","word_nosc":"niaises","lemma":"niais","pos":"ADJ"} ,
		{"word":"niaiseuse","word_nosc":"niaiseuse","lemma":"niaiseux","pos":"ADJ"} ,
		{"word":"niaiseux","word_nosc":"niaiseux","lemma":"niaiseux","pos":"ADJ"} ,
		{"word":"nibergue","word_nosc":"nibergue","lemma":"nibergue","pos":"ADJ"} ,
		{"word":"nicaraguayen","word_nosc":"nicaraguayen","lemma":"nicaraguayen","pos":"ADJ"} ,
		{"word":"nicaraguayens","word_nosc":"nicaraguayens","lemma":"nicaraguayen","pos":"ADJ"} ,
		{"word":"nicet","word_nosc":"nicet","lemma":"nicet","pos":"ADJ"} ,
		{"word":"nicheurs","word_nosc":"nicheurs","lemma":"nicheur","pos":"ADJ"} ,
		{"word":"nichonnée","word_nosc":"nichonnee","lemma":"nichonnée","pos":"ADJ"} ,
		{"word":"nichonnées","word_nosc":"nichonnees","lemma":"nichonnée","pos":"ADJ"} ,
		{"word":"nickel","word_nosc":"nickel","lemma":"nickel","pos":"ADJ"} ,
		{"word":"nickelé","word_nosc":"nickele","lemma":"nickelé","pos":"ADJ"} ,
		{"word":"nickelée","word_nosc":"nickelee","lemma":"nickelé","pos":"ADJ"} ,
		{"word":"nickelées","word_nosc":"nickelees","lemma":"nickelé","pos":"ADJ"} ,
		{"word":"nickelés","word_nosc":"nickeles","lemma":"nickelé","pos":"ADJ"} ,
		{"word":"nictitant","word_nosc":"nictitant","lemma":"nictitant","pos":"ADJ"} ,
		{"word":"nictitante","word_nosc":"nictitante","lemma":"nictitant","pos":"ADJ"} ,
		{"word":"nietzschéen","word_nosc":"nietzscheen","lemma":"nietzschéen","pos":"ADJ"} ,
		{"word":"nietzschéenne","word_nosc":"nietzscheenne","lemma":"nietzschéen","pos":"ADJ"} ,
		{"word":"nietzschéennes","word_nosc":"nietzscheennes","lemma":"nietzschéen","pos":"ADJ"} ,
		{"word":"nigaud","word_nosc":"nigaud","lemma":"nigaud","pos":"ADJ"} ,
		{"word":"nigaude","word_nosc":"nigaude","lemma":"nigaud","pos":"ADJ"} ,
		{"word":"nigauds","word_nosc":"nigauds","lemma":"nigaud","pos":"ADJ"} ,
		{"word":"nigérian","word_nosc":"nigerian","lemma":"nigérian","pos":"ADJ"} ,
		{"word":"nigériane","word_nosc":"nigeriane","lemma":"nigérian","pos":"ADJ"} ,
		{"word":"nigérians","word_nosc":"nigerians","lemma":"nigérian","pos":"ADJ"} ,
		{"word":"nigérien","word_nosc":"nigerien","lemma":"nigérien","pos":"ADJ"} ,
		{"word":"nihiliste","word_nosc":"nihiliste","lemma":"nihiliste","pos":"ADJ"} ,
		{"word":"nihilistes","word_nosc":"nihilistes","lemma":"nihiliste","pos":"ADJ"} ,
		{"word":"nimbé","word_nosc":"nimbe","lemma":"nimbé","pos":"ADJ"} ,
		{"word":"nimbée","word_nosc":"nimbee","lemma":"nimbé","pos":"ADJ"} ,
		{"word":"nioulouque","word_nosc":"nioulouque","lemma":"nioulouque","pos":"ADJ"} ,
		{"word":"nippon","word_nosc":"nippon","lemma":"nippon","pos":"ADJ"} ,
		{"word":"nippone","word_nosc":"nippone","lemma":"nippon","pos":"ADJ"} ,
		{"word":"nippones","word_nosc":"nippones","lemma":"nippon","pos":"ADJ"} ,
		{"word":"nipponne","word_nosc":"nipponne","lemma":"nipponne","pos":"ADJ"} ,
		{"word":"nippons","word_nosc":"nippons","lemma":"nippon","pos":"ADJ"} ,
		{"word":"niquedouille","word_nosc":"niquedouille","lemma":"niquedouille","pos":"ADJ"} ,
		{"word":"nitreuse","word_nosc":"nitreuse","lemma":"nitreux","pos":"ADJ"} ,
		{"word":"nitreux","word_nosc":"nitreux","lemma":"nitreux","pos":"ADJ"} ,
		{"word":"nitrique","word_nosc":"nitrique","lemma":"nitrique","pos":"ADJ"} ,
		{"word":"nitré","word_nosc":"nitre","lemma":"nitré","pos":"ADJ"} ,
		{"word":"nivernais","word_nosc":"nivernais","lemma":"nivernais","pos":"ADJ"} ,
		{"word":"nivernaise","word_nosc":"nivernaise","lemma":"nivernaise","pos":"ADJ"} ,
		{"word":"niçois","word_nosc":"nicois","lemma":"niçois","pos":"ADJ"} ,
		{"word":"niçoise","word_nosc":"nicoise","lemma":"niçois","pos":"ADJ"} ,
		{"word":"niçoises","word_nosc":"nicoises","lemma":"niçois","pos":"ADJ"} ,
		{"word":"nié","word_nosc":"nie","lemma":"nié","pos":"ADJ"} ,
		{"word":"niée","word_nosc":"niee","lemma":"nié","pos":"ADJ"} ,
		{"word":"niés","word_nosc":"nies","lemma":"nié","pos":"ADJ"} ,
		{"word":"nobiliaire","word_nosc":"nobiliaire","lemma":"nobiliaire","pos":"ADJ"} ,
		{"word":"nobiliaires","word_nosc":"nobiliaires","lemma":"nobiliaire","pos":"ADJ"} ,
		{"word":"noble","word_nosc":"noble","lemma":"noble","pos":"ADJ"} ,
		{"word":"nobles","word_nosc":"nobles","lemma":"noble","pos":"ADJ"} ,
		{"word":"nocif","word_nosc":"nocif","lemma":"nocif","pos":"ADJ"} ,
		{"word":"nocifs","word_nosc":"nocifs","lemma":"nocif","pos":"ADJ"} ,
		{"word":"nocive","word_nosc":"nocive","lemma":"nocif","pos":"ADJ"} ,
		{"word":"nocives","word_nosc":"nocives","lemma":"nocif","pos":"ADJ"} ,
		{"word":"noctambule","word_nosc":"noctambule","lemma":"noctambule","pos":"ADJ"} ,
		{"word":"noctambules","word_nosc":"noctambules","lemma":"noctambule","pos":"ADJ"} ,
		{"word":"nocturne","word_nosc":"nocturne","lemma":"nocturne","pos":"ADJ"} ,
		{"word":"nocturnes","word_nosc":"nocturnes","lemma":"nocturne","pos":"ADJ"} ,
		{"word":"nodal","word_nosc":"nodal","lemma":"nodal","pos":"ADJ"} ,
		{"word":"nodales","word_nosc":"nodales","lemma":"nodal","pos":"ADJ"} ,
		{"word":"noduleux","word_nosc":"noduleux","lemma":"noduleux","pos":"ADJ"} ,
		{"word":"noir","word_nosc":"noir","lemma":"noir","pos":"ADJ"} ,
		{"word":"noiraud","word_nosc":"noiraud","lemma":"noiraud","pos":"ADJ"} ,
		{"word":"noiraude","word_nosc":"noiraude","lemma":"noiraud","pos":"ADJ"} ,
		{"word":"noiraudes","word_nosc":"noiraudes","lemma":"noiraud","pos":"ADJ"} ,
		{"word":"noirauds","word_nosc":"noirauds","lemma":"noiraud","pos":"ADJ"} ,
		{"word":"noirci","word_nosc":"noirci","lemma":"noirci","pos":"ADJ"} ,
		{"word":"noircie","word_nosc":"noircie","lemma":"noirci","pos":"ADJ"} ,
		{"word":"noircies","word_nosc":"noircies","lemma":"noirci","pos":"ADJ"} ,
		{"word":"noircis","word_nosc":"noircis","lemma":"noirci","pos":"ADJ"} ,
		{"word":"noire","word_nosc":"noire","lemma":"noir","pos":"ADJ"} ,
		{"word":"noires","word_nosc":"noires","lemma":"noir","pos":"ADJ"} ,
		{"word":"noirs","word_nosc":"noirs","lemma":"noir","pos":"ADJ"} ,
		{"word":"noirâtre","word_nosc":"noiratre","lemma":"noirâtre","pos":"ADJ"} ,
		{"word":"noirâtres","word_nosc":"noiratres","lemma":"noirâtre","pos":"ADJ"} ,
		{"word":"noisette","word_nosc":"noisette","lemma":"noisette","pos":"ADJ"} ,
		{"word":"nok","word_nosc":"nok","lemma":"nok","pos":"ADJ"} ,
		{"word":"nomade","word_nosc":"nomade","lemma":"nomade","pos":"ADJ"} ,
		{"word":"nomades","word_nosc":"nomades","lemma":"nomade","pos":"ADJ"} ,
		{"word":"nombreuse","word_nosc":"nombreuse","lemma":"nombreux","pos":"ADJ"} ,
		{"word":"nombreuses","word_nosc":"nombreuses","lemma":"nombreux","pos":"ADJ"} ,
		{"word":"nombreux","word_nosc":"nombreux","lemma":"nombreux","pos":"ADJ"} ,
		{"word":"nombriliste","word_nosc":"nombriliste","lemma":"nombriliste","pos":"ADJ"} ,
		{"word":"nominal","word_nosc":"nominal","lemma":"nominal","pos":"ADJ"} ,
		{"word":"nominale","word_nosc":"nominale","lemma":"nominal","pos":"ADJ"} ,
		{"word":"nominales","word_nosc":"nominales","lemma":"nominal","pos":"ADJ"} ,
		{"word":"nominaliste","word_nosc":"nominaliste","lemma":"nominaliste","pos":"ADJ"} ,
		{"word":"nominatif","word_nosc":"nominatif","lemma":"nominatif","pos":"ADJ"} ,
		{"word":"nominatifs","word_nosc":"nominatifs","lemma":"nominatif","pos":"ADJ"} ,
		{"word":"nominative","word_nosc":"nominative","lemma":"nominatif","pos":"ADJ"} ,
		{"word":"nominatives","word_nosc":"nominatives","lemma":"nominatif","pos":"ADJ"} ,
		{"word":"nominaux","word_nosc":"nominaux","lemma":"nominal","pos":"ADJ"} ,
		{"word":"nommé","word_nosc":"nomme","lemma":"nommé","pos":"ADJ"} ,
		{"word":"nommée","word_nosc":"nommee","lemma":"nommé","pos":"ADJ"} ,
		{"word":"nommées","word_nosc":"nommees","lemma":"nommé","pos":"ADJ"} ,
		{"word":"nommés","word_nosc":"nommes","lemma":"nommé","pos":"ADJ"} ,
		{"word":"non","word_nosc":"non","lemma":"non","pos":"ADJ"} ,
		{"word":"non-alignés","word_nosc":"non-alignes","lemma":"non-aligné","pos":"ADJ"} ,
		{"word":"non-combattant","word_nosc":"non-combattant","lemma":"non-combattant","pos":"ADJ"} ,
		{"word":"non-combattants","word_nosc":"non-combattants","lemma":"non-combattant","pos":"ADJ"} ,
		{"word":"non-conformiste","word_nosc":"non-conformiste","lemma":"non-conformiste","pos":"ADJ"} ,
		{"word":"non-conformistes","word_nosc":"non-conformistes","lemma":"non-conformiste","pos":"ADJ"} ,
		{"word":"non-fumeur","word_nosc":"non-fumeur","lemma":"non-fumeur","pos":"ADJ"} ,
		{"word":"non-fumeurs","word_nosc":"non-fumeurs","lemma":"non-fumeurs","pos":"ADJ"} ,
		{"word":"non-initié","word_nosc":"non-initie","lemma":"non-initié","pos":"ADJ"} ,
		{"word":"non-initiés","word_nosc":"non-inities","lemma":"non-initié","pos":"ADJ"} ,
		{"word":"non-stop","word_nosc":"non-stop","lemma":"non-stop","pos":"ADJ"} ,
		{"word":"non-violent","word_nosc":"non-violent","lemma":"non-violent","pos":"ADJ"} ,
		{"word":"non-violente","word_nosc":"non-violente","lemma":"non-violent","pos":"ADJ"} ,
		{"word":"non-violents","word_nosc":"non-violents","lemma":"non-violent","pos":"ADJ"} ,
		{"word":"nonagénaire","word_nosc":"nonagenaire","lemma":"nonagénaire","pos":"ADJ"} ,
		{"word":"nonagénaires","word_nosc":"nonagenaires","lemma":"nonagénaire","pos":"ADJ"} ,
		{"word":"nonante","word_nosc":"nonante","lemma":"nonante","pos":"ADJ:num"} ,
		{"word":"nonante-huit","word_nosc":"nonante-huit","lemma":"nonante-huit","pos":"ADJ:num"} ,
		{"word":"nonchalant","word_nosc":"nonchalant","lemma":"nonchalant","pos":"ADJ"} ,
		{"word":"nonchalante","word_nosc":"nonchalante","lemma":"nonchalant","pos":"ADJ"} ,
		{"word":"nonchalantes","word_nosc":"nonchalantes","lemma":"nonchalant","pos":"ADJ"} ,
		{"word":"nonchalants","word_nosc":"nonchalants","lemma":"nonchalant","pos":"ADJ"} ,
		{"word":"nonpareille","word_nosc":"nonpareille","lemma":"nonpareil","pos":"ADJ"} ,
		{"word":"nord","word_nosc":"nord","lemma":"nord","pos":"ADJ"} ,
		{"word":"nord-africain","word_nosc":"nord-africain","lemma":"nord-africain","pos":"ADJ"} ,
		{"word":"nord-africaine","word_nosc":"nord-africaine","lemma":"nord-africain","pos":"ADJ"} ,
		{"word":"nord-africaines","word_nosc":"nord-africaines","lemma":"nord-africain","pos":"ADJ"} ,
		{"word":"nord-africains","word_nosc":"nord-africains","lemma":"nord-africain","pos":"ADJ"} ,
		{"word":"nord-américain","word_nosc":"nord-americain","lemma":"nord-américain","pos":"ADJ"} ,
		{"word":"nord-américaine","word_nosc":"nord-americaine","lemma":"nord-américain","pos":"ADJ"} ,
		{"word":"nord-américains","word_nosc":"nord-americains","lemma":"nord-américain","pos":"ADJ"} ,
		{"word":"nord-coréen","word_nosc":"nord-coreen","lemma":"nord-coréen","pos":"ADJ"} ,
		{"word":"nord-coréenne","word_nosc":"nord-coreenne","lemma":"nord-coréen","pos":"ADJ"} ,
		{"word":"nord-coréennes","word_nosc":"nord-coreennes","lemma":"nord-coréen","pos":"ADJ"} ,
		{"word":"nord-coréens","word_nosc":"nord-coreens","lemma":"nord-coréen","pos":"ADJ"} ,
		{"word":"nord-est","word_nosc":"nord-est","lemma":"nord-est","pos":"ADJ"} ,
		{"word":"nord-ouest","word_nosc":"nord-ouest","lemma":"nord-ouest","pos":"ADJ"} ,
		{"word":"nord-sud","word_nosc":"nord-sud","lemma":"nord-sud","pos":"ADJ"} ,
		{"word":"nord-vietnamien","word_nosc":"nord-vietnamien","lemma":"nord-vietnamien","pos":"ADJ"} ,
		{"word":"nord-vietnamienne","word_nosc":"nord-vietnamienne","lemma":"nord-vietnamien","pos":"ADJ"} ,
		{"word":"nord-vietnamiens","word_nosc":"nord-vietnamiens","lemma":"nord-vietnamien","pos":"ADJ"} ,
		{"word":"nordique","word_nosc":"nordique","lemma":"nordique","pos":"ADJ"} ,
		{"word":"nordiques","word_nosc":"nordiques","lemma":"nordique","pos":"ADJ"} ,
		{"word":"nordiste","word_nosc":"nordiste","lemma":"nordiste","pos":"ADJ"} ,
		{"word":"nordistes","word_nosc":"nordistes","lemma":"nordiste","pos":"ADJ"} ,
		{"word":"noria","word_nosc":"noria","lemma":"noria","pos":"ADJ"} ,
		{"word":"normal","word_nosc":"normal","lemma":"normal","pos":"ADJ"} ,
		{"word":"normale","word_nosc":"normale","lemma":"normal","pos":"ADJ"} ,
		{"word":"normales","word_nosc":"normales","lemma":"normal","pos":"ADJ"} ,
		{"word":"normalien","word_nosc":"normalien","lemma":"normalien","pos":"ADJ"} ,
		{"word":"normalienne","word_nosc":"normalienne","lemma":"normalien","pos":"ADJ"} ,
		{"word":"normaliens","word_nosc":"normaliens","lemma":"normalien","pos":"ADJ"} ,
		{"word":"normalisés","word_nosc":"normalises","lemma":"normalisé","pos":"ADJ"} ,
		{"word":"normand","word_nosc":"normand","lemma":"normand","pos":"ADJ"} ,
		{"word":"normande","word_nosc":"normande","lemma":"normand","pos":"ADJ"} ,
		{"word":"normandes","word_nosc":"normandes","lemma":"normand","pos":"ADJ"} ,
		{"word":"normands","word_nosc":"normands","lemma":"normand","pos":"ADJ"} ,
		{"word":"normatif","word_nosc":"normatif","lemma":"normatif","pos":"ADJ"} ,
		{"word":"normative","word_nosc":"normative","lemma":"normatif","pos":"ADJ"} ,
		{"word":"normaux","word_nosc":"normaux","lemma":"normal","pos":"ADJ"} ,
		{"word":"norroise","word_nosc":"norroise","lemma":"norrois","pos":"ADJ"} ,
		{"word":"norvégien","word_nosc":"norvegien","lemma":"norvégien","pos":"ADJ"} ,
		{"word":"norvégienne","word_nosc":"norvegienne","lemma":"norvégien","pos":"ADJ"} ,
		{"word":"norvégiennes","word_nosc":"norvegiennes","lemma":"norvégien","pos":"ADJ"} ,
		{"word":"norvégiens","word_nosc":"norvegiens","lemma":"norvégien","pos":"ADJ"} ,
		{"word":"nos","word_nosc":"nos","lemma":"nos","pos":"ADJ:pos"} ,
		{"word":"nosocomiale","word_nosc":"nosocomiale","lemma":"nosocomial","pos":"ADJ"} ,
		{"word":"nostalgique","word_nosc":"nostalgique","lemma":"nostalgique","pos":"ADJ"} ,
		{"word":"nostalgiques","word_nosc":"nostalgiques","lemma":"nostalgique","pos":"ADJ"} ,
		{"word":"notable","word_nosc":"notable","lemma":"notable","pos":"ADJ"} ,
		{"word":"notables","word_nosc":"notables","lemma":"notable","pos":"ADJ"} ,
		{"word":"notarial","word_nosc":"notarial","lemma":"notarial","pos":"ADJ"} ,
		{"word":"notariale","word_nosc":"notariale","lemma":"notarial","pos":"ADJ"} ,
		{"word":"notariaux","word_nosc":"notariaux","lemma":"notarial","pos":"ADJ"} ,
		{"word":"notarié","word_nosc":"notarie","lemma":"notarié","pos":"ADJ"} ,
		{"word":"notariée","word_nosc":"notariee","lemma":"notarié","pos":"ADJ"} ,
		{"word":"notariés","word_nosc":"notaries","lemma":"notarié","pos":"ADJ"} ,
		{"word":"notifiée","word_nosc":"notifiee","lemma":"notifié","pos":"ADJ"} ,
		{"word":"notifiées","word_nosc":"notifiees","lemma":"notifié","pos":"ADJ"} ,
		{"word":"notoire","word_nosc":"notoire","lemma":"notoire","pos":"ADJ"} ,
		{"word":"notoires","word_nosc":"notoires","lemma":"notoire","pos":"ADJ"} ,
		{"word":"notre","word_nosc":"notre","lemma":"notre","pos":"ADJ:pos"} ,
		{"word":"noté","word_nosc":"note","lemma":"noté","pos":"ADJ"} ,
		{"word":"notée","word_nosc":"notee","lemma":"noté","pos":"ADJ"} ,
		{"word":"notées","word_nosc":"notees","lemma":"noté","pos":"ADJ"} ,
		{"word":"notés","word_nosc":"notes","lemma":"noté","pos":"ADJ"} ,
		{"word":"noueuse","word_nosc":"noueuse","lemma":"noueux","pos":"ADJ"} ,
		{"word":"noueuses","word_nosc":"noueuses","lemma":"noueux","pos":"ADJ"} ,
		{"word":"noueux","word_nosc":"noueux","lemma":"noueux","pos":"ADJ"} ,
		{"word":"nouille","word_nosc":"nouille","lemma":"nouille","pos":"ADJ"} ,
		{"word":"nouilles","word_nosc":"nouilles","lemma":"nouille","pos":"ADJ"} ,
		{"word":"nourri","word_nosc":"nourri","lemma":"nourri","pos":"ADJ"} ,
		{"word":"nourricier","word_nosc":"nourricier","lemma":"nourricier","pos":"ADJ"} ,
		{"word":"nourriciers","word_nosc":"nourriciers","lemma":"nourricier","pos":"ADJ"} ,
		{"word":"nourricière","word_nosc":"nourriciere","lemma":"nourricier","pos":"ADJ"} ,
		{"word":"nourricières","word_nosc":"nourricieres","lemma":"nourricier","pos":"ADJ"} ,
		{"word":"nourrie","word_nosc":"nourrie","lemma":"nourri","pos":"ADJ"} ,
		{"word":"nourries","word_nosc":"nourries","lemma":"nourri","pos":"ADJ"} ,
		{"word":"nourris","word_nosc":"nourris","lemma":"nourri","pos":"ADJ"} ,
		{"word":"nourrissant","word_nosc":"nourrissant","lemma":"nourrissant","pos":"ADJ"} ,
		{"word":"nourrissante","word_nosc":"nourrissante","lemma":"nourrissant","pos":"ADJ"} ,
		{"word":"nourrissantes","word_nosc":"nourrissantes","lemma":"nourrissant","pos":"ADJ"} ,
		{"word":"nourrissants","word_nosc":"nourrissants","lemma":"nourrissant","pos":"ADJ"} ,
		{"word":"nouveau","word_nosc":"nouveau","lemma":"nouveau","pos":"ADJ"} ,
		{"word":"nouveau-né","word_nosc":"nouveau-ne","lemma":"nouveau-né","pos":"ADJ"} ,
		{"word":"nouveau-nés","word_nosc":"nouveau-nes","lemma":"nouveau-né","pos":"ADJ"} ,
		{"word":"nouveaux","word_nosc":"nouveaux","lemma":"nouveau","pos":"ADJ"} ,
		{"word":"nouvel","word_nosc":"nouvel","lemma":"nouvel","pos":"ADJ"} ,
		{"word":"nouvelle","word_nosc":"nouvelle","lemma":"nouvelle","pos":"ADJ"} ,
		{"word":"nouvelles","word_nosc":"nouvelles","lemma":"nouvelle","pos":"ADJ"} ,
		{"word":"noué","word_nosc":"noue","lemma":"noué","pos":"ADJ"} ,
		{"word":"nouée","word_nosc":"nouee","lemma":"noué","pos":"ADJ"} ,
		{"word":"nouées","word_nosc":"nouees","lemma":"noué","pos":"ADJ"} ,
		{"word":"noués","word_nosc":"noues","lemma":"noué","pos":"ADJ"} ,
		{"word":"novateur","word_nosc":"novateur","lemma":"novateur","pos":"ADJ"} ,
		{"word":"novateurs","word_nosc":"novateurs","lemma":"novateur","pos":"ADJ"} ,
		{"word":"novatrice","word_nosc":"novatrice","lemma":"novateur","pos":"ADJ"} ,
		{"word":"novatrices","word_nosc":"novatrices","lemma":"novateur","pos":"ADJ"} ,
		{"word":"novice","word_nosc":"novice","lemma":"novice","pos":"ADJ"} ,
		{"word":"novices","word_nosc":"novices","lemma":"novice","pos":"ADJ"} ,
		{"word":"noyé","word_nosc":"noye","lemma":"noyé","pos":"ADJ"} ,
		{"word":"noyée","word_nosc":"noyee","lemma":"noyé","pos":"ADJ"} ,
		{"word":"noyées","word_nosc":"noyees","lemma":"noyé","pos":"ADJ"} ,
		{"word":"noyés","word_nosc":"noyes","lemma":"noyé","pos":"ADJ"} ,
		{"word":"nu","word_nosc":"nu","lemma":"nu","pos":"ADJ"} ,
		{"word":"nu-tête","word_nosc":"nu-tete","lemma":"nu-tête","pos":"ADJ"} ,
		{"word":"nuageuse","word_nosc":"nuageuse","lemma":"nuageux","pos":"ADJ"} ,
		{"word":"nuageuses","word_nosc":"nuageuses","lemma":"nuageux","pos":"ADJ"} ,
		{"word":"nuageux","word_nosc":"nuageux","lemma":"nuageux","pos":"ADJ"} ,
		{"word":"nuancé","word_nosc":"nuance","lemma":"nuancé","pos":"ADJ"} ,
		{"word":"nuancée","word_nosc":"nuancee","lemma":"nuancé","pos":"ADJ"} ,
		{"word":"nuancées","word_nosc":"nuancees","lemma":"nuancé","pos":"ADJ"} ,
		{"word":"nuancés","word_nosc":"nuances","lemma":"nuancé","pos":"ADJ"} ,
		{"word":"nubien","word_nosc":"nubien","lemma":"nubien","pos":"ADJ"} ,
		{"word":"nubienne","word_nosc":"nubienne","lemma":"nubien","pos":"ADJ"} ,
		{"word":"nubiens","word_nosc":"nubiens","lemma":"nubien","pos":"ADJ"} ,
		{"word":"nubile","word_nosc":"nubile","lemma":"nubile","pos":"ADJ"} ,
		{"word":"nubiles","word_nosc":"nubiles","lemma":"nubile","pos":"ADJ"} ,
		{"word":"nucal","word_nosc":"nucal","lemma":"nucal","pos":"ADJ"} ,
		{"word":"nucléaire","word_nosc":"nucleaire","lemma":"nucléaire","pos":"ADJ"} ,
		{"word":"nucléaires","word_nosc":"nucleaires","lemma":"nucléaire","pos":"ADJ"} ,
		{"word":"nucléique","word_nosc":"nucleique","lemma":"nucléique","pos":"ADJ"} ,
		{"word":"nucléée","word_nosc":"nucleee","lemma":"nucléé","pos":"ADJ"} ,
		{"word":"nudiste","word_nosc":"nudiste","lemma":"nudiste","pos":"ADJ"} ,
		{"word":"nudistes","word_nosc":"nudistes","lemma":"nudiste","pos":"ADJ"} ,
		{"word":"nue","word_nosc":"nue","lemma":"nu","pos":"ADJ"} ,
		{"word":"nues","word_nosc":"nues","lemma":"nu","pos":"ADJ"} ,
		{"word":"nuisible","word_nosc":"nuisible","lemma":"nuisible","pos":"ADJ"} ,
		{"word":"nuisibles","word_nosc":"nuisibles","lemma":"nuisible","pos":"ADJ"} ,
		{"word":"nul","word_nosc":"nul","lemma":"nul","pos":"ADJ"} ,
		{"word":"nul","word_nosc":"nul","lemma":"nul","pos":"ADJ:ind"} ,
		{"word":"nullard","word_nosc":"nullard","lemma":"nullard","pos":"ADJ"} ,
		{"word":"nullarde","word_nosc":"nullarde","lemma":"nullard","pos":"ADJ"} ,
		{"word":"nullardes","word_nosc":"nullardes","lemma":"nullard","pos":"ADJ"} ,
		{"word":"nullards","word_nosc":"nullards","lemma":"nullard","pos":"ADJ"} ,
		{"word":"nulle","word_nosc":"nulle","lemma":"nul","pos":"ADJ"} ,
		{"word":"nulle","word_nosc":"nulle","lemma":"nulle","pos":"ADJ:ind"} ,
		{"word":"nulles","word_nosc":"nulles","lemma":"nul","pos":"ADJ"} ,
		{"word":"nulles","word_nosc":"nulles","lemma":"nulles","pos":"ADJ:ind"} ,
		{"word":"nullissime","word_nosc":"nullissime","lemma":"nullissime","pos":"ADJ"} ,
		{"word":"nullos","word_nosc":"nullos","lemma":"nullos","pos":"ADJ"} ,
		{"word":"nuls","word_nosc":"nuls","lemma":"nul","pos":"ADJ"} ,
		{"word":"nuls","word_nosc":"nuls","lemma":"nuls","pos":"ADJ:ind"} ,
		{"word":"numide","word_nosc":"numide","lemma":"numide","pos":"ADJ"} ,
		{"word":"numides","word_nosc":"numides","lemma":"numide","pos":"ADJ"} ,
		{"word":"numismatique","word_nosc":"numismatique","lemma":"numismatique","pos":"ADJ"} ,
		{"word":"numéral","word_nosc":"numeral","lemma":"numéral","pos":"ADJ"} ,
		{"word":"numérique","word_nosc":"numerique","lemma":"numérique","pos":"ADJ"} ,
		{"word":"numériques","word_nosc":"numeriques","lemma":"numérique","pos":"ADJ"} ,
		{"word":"numéroté","word_nosc":"numerote","lemma":"numéroté","pos":"ADJ"} ,
		{"word":"numérotée","word_nosc":"numerotee","lemma":"numéroté","pos":"ADJ"} ,
		{"word":"numérotées","word_nosc":"numerotees","lemma":"numéroté","pos":"ADJ"} ,
		{"word":"numérotés","word_nosc":"numerotes","lemma":"numéroté","pos":"ADJ"} ,
		{"word":"nuncupatifs","word_nosc":"nuncupatifs","lemma":"nuncupatif","pos":"ADJ"} ,
		{"word":"nunuche","word_nosc":"nunuche","lemma":"nunuche","pos":"ADJ"} ,
		{"word":"nunuches","word_nosc":"nunuches","lemma":"nunuche","pos":"ADJ"} ,
		{"word":"nuptial","word_nosc":"nuptial","lemma":"nuptial","pos":"ADJ"} ,
		{"word":"nuptiale","word_nosc":"nuptiale","lemma":"nuptial","pos":"ADJ"} ,
		{"word":"nuptiales","word_nosc":"nuptiales","lemma":"nuptial","pos":"ADJ"} ,
		{"word":"nuptiaux","word_nosc":"nuptiaux","lemma":"nuptial","pos":"ADJ"} ,
		{"word":"nus","word_nosc":"nus","lemma":"nu","pos":"ADJ"} ,
		{"word":"nutritif","word_nosc":"nutritif","lemma":"nutritif","pos":"ADJ"} ,
		{"word":"nutritifs","word_nosc":"nutritifs","lemma":"nutritif","pos":"ADJ"} ,
		{"word":"nutritionnel","word_nosc":"nutritionnel","lemma":"nutritionnel","pos":"ADJ"} ,
		{"word":"nutritionnelle","word_nosc":"nutritionnelle","lemma":"nutritionnel","pos":"ADJ"} ,
		{"word":"nutritive","word_nosc":"nutritive","lemma":"nutritif","pos":"ADJ"} ,
		{"word":"nutritives","word_nosc":"nutritives","lemma":"nutritif","pos":"ADJ"} ,
		{"word":"nyctalope","word_nosc":"nyctalope","lemma":"nyctalope","pos":"ADJ"} ,
		{"word":"nyctalopes","word_nosc":"nyctalopes","lemma":"nyctalope","pos":"ADJ"} ,
		{"word":"nymphal","word_nosc":"nymphal","lemma":"nymphal","pos":"ADJ"} ,
		{"word":"nymphomane","word_nosc":"nymphomane","lemma":"nymphomane","pos":"ADJ"} ,
		{"word":"nymphomanes","word_nosc":"nymphomanes","lemma":"nymphomane","pos":"ADJ"} ,
		{"word":"nègre","word_nosc":"negre","lemma":"nègre","pos":"ADJ"} ,
		{"word":"nègres","word_nosc":"negres","lemma":"nègre","pos":"ADJ"} ,
		{"word":"né","word_nosc":"ne","lemma":"né","pos":"ADJ"} ,
		{"word":"néandertalien","word_nosc":"neandertalien","lemma":"néandertalien","pos":"ADJ"} ,
		{"word":"néanderthalien","word_nosc":"neanderthalien","lemma":"néanderthalien","pos":"ADJ"} ,
		{"word":"nébuleuse","word_nosc":"nebuleuse","lemma":"nébuleux","pos":"ADJ"} ,
		{"word":"nébuleuses","word_nosc":"nebuleuses","lemma":"nébuleux","pos":"ADJ"} ,
		{"word":"nébuleux","word_nosc":"nebuleux","lemma":"nébuleux","pos":"ADJ"} ,
		{"word":"nécessaire","word_nosc":"necessaire","lemma":"nécessaire","pos":"ADJ"} ,
		{"word":"nécessaires","word_nosc":"necessaires","lemma":"nécessaire","pos":"ADJ"} ,
		{"word":"nécessiteuse","word_nosc":"necessiteuse","lemma":"nécessiteux","pos":"ADJ"} ,
		{"word":"nécessiteuses","word_nosc":"necessiteuses","lemma":"nécessiteux","pos":"ADJ"} ,
		{"word":"nécessiteux","word_nosc":"necessiteux","lemma":"nécessiteux","pos":"ADJ"} ,
		{"word":"nécrologique","word_nosc":"necrologique","lemma":"nécrologique","pos":"ADJ"} ,
		{"word":"nécrologiques","word_nosc":"necrologiques","lemma":"nécrologique","pos":"ADJ"} ,
		{"word":"nécrophage","word_nosc":"necrophage","lemma":"nécrophage","pos":"ADJ"} ,
		{"word":"nécrophile","word_nosc":"necrophile","lemma":"nécrophile","pos":"ADJ"} ,
		{"word":"nécrophiles","word_nosc":"necrophiles","lemma":"nécrophile","pos":"ADJ"} ,
		{"word":"nécrotique","word_nosc":"necrotique","lemma":"nécrotique","pos":"ADJ"} ,
		{"word":"née","word_nosc":"nee","lemma":"né","pos":"ADJ"} ,
		{"word":"néerlandais","word_nosc":"neerlandais","lemma":"néerlandais","pos":"ADJ"} ,
		{"word":"néerlandaise","word_nosc":"neerlandaise","lemma":"néerlandais","pos":"ADJ"} ,
		{"word":"néerlandaises","word_nosc":"neerlandaises","lemma":"néerlandais","pos":"ADJ"} ,
		{"word":"nées","word_nosc":"nees","lemma":"né","pos":"ADJ"} ,
		{"word":"néfaste","word_nosc":"nefaste","lemma":"néfaste","pos":"ADJ"} ,
		{"word":"néfastes","word_nosc":"nefastes","lemma":"néfaste","pos":"ADJ"} ,
		{"word":"négateur","word_nosc":"negateur","lemma":"négateur","pos":"ADJ"} ,
		{"word":"négatif","word_nosc":"negatif","lemma":"négatif","pos":"ADJ"} ,
		{"word":"négatifs","word_nosc":"negatifs","lemma":"négatif","pos":"ADJ"} ,
		{"word":"négative","word_nosc":"negative","lemma":"négatif","pos":"ADJ"} ,
		{"word":"négatives","word_nosc":"negatives","lemma":"négatif","pos":"ADJ"} ,
		{"word":"négligeable","word_nosc":"negligeable","lemma":"négligeable","pos":"ADJ"} ,
		{"word":"négligeables","word_nosc":"negligeables","lemma":"négligeable","pos":"ADJ"} ,
		{"word":"négligent","word_nosc":"negligent","lemma":"négligent","pos":"ADJ"} ,
		{"word":"négligente","word_nosc":"negligente","lemma":"négligent","pos":"ADJ"} ,
		{"word":"négligents","word_nosc":"negligents","lemma":"négligent","pos":"ADJ"} ,
		{"word":"négligé","word_nosc":"neglige","lemma":"négligé","pos":"ADJ"} ,
		{"word":"négligée","word_nosc":"negligee","lemma":"négligé","pos":"ADJ"} ,
		{"word":"négligées","word_nosc":"negligees","lemma":"négligé","pos":"ADJ"} ,
		{"word":"négligés","word_nosc":"negliges","lemma":"négligé","pos":"ADJ"} ,
		{"word":"négociable","word_nosc":"negociable","lemma":"négociable","pos":"ADJ"} ,
		{"word":"négociables","word_nosc":"negociables","lemma":"négociable","pos":"ADJ"} ,
		{"word":"négresse","word_nosc":"negresse","lemma":"nègre","pos":"ADJ"} ,
		{"word":"négresses","word_nosc":"negresses","lemma":"nègre","pos":"ADJ"} ,
		{"word":"négrier","word_nosc":"negrier","lemma":"négrier","pos":"ADJ"} ,
		{"word":"négriers","word_nosc":"negriers","lemma":"négrier","pos":"ADJ"} ,
		{"word":"négrophile","word_nosc":"negrophile","lemma":"négrophile","pos":"ADJ"} ,
		{"word":"négrophiles","word_nosc":"negrophiles","lemma":"négrophile","pos":"ADJ"} ,
		{"word":"négroïde","word_nosc":"negroide","lemma":"négroïde","pos":"ADJ"} ,
		{"word":"négroïdes","word_nosc":"negroides","lemma":"négroïde","pos":"ADJ"} ,
		{"word":"néo-barbares","word_nosc":"neo-barbares","lemma":"néo-barbare","pos":"ADJ"} ,
		{"word":"néo-classique","word_nosc":"neo-classique","lemma":"néo-classique","pos":"ADJ"} ,
		{"word":"néo-communiste","word_nosc":"neo-communiste","lemma":"néo-communiste","pos":"ADJ"} ,
		{"word":"néo-fascistes","word_nosc":"neo-fascistes","lemma":"néo-fasciste","pos":"ADJ"} ,
		{"word":"néo-gothique","word_nosc":"neo-gothique","lemma":"néo-gothique","pos":"ADJ"} ,
		{"word":"néo-gothiques","word_nosc":"neo-gothiques","lemma":"néo-gothique","pos":"ADJ"} ,
		{"word":"néo-grec","word_nosc":"neo-grec","lemma":"néo-grec","pos":"ADJ"} ,
		{"word":"néo-helléniques","word_nosc":"neo-helleniques","lemma":"néo-hellénique","pos":"ADJ"} ,
		{"word":"néo-mauresque","word_nosc":"neo-mauresque","lemma":"néo-mauresque","pos":"ADJ"} ,
		{"word":"néo-mauresques","word_nosc":"neo-mauresques","lemma":"néo-mauresque","pos":"ADJ"} ,
		{"word":"néo-renaissant","word_nosc":"neo-renaissant","lemma":"néo-renaissant","pos":"ADJ"} ,
		{"word":"néo-romantique","word_nosc":"neo-romantique","lemma":"néo-romantique","pos":"ADJ"} ,
		{"word":"néo-russe","word_nosc":"neo-russe","lemma":"néo-russe","pos":"ADJ"} ,
		{"word":"néo-réaliste","word_nosc":"neo-realiste","lemma":"néo-réaliste","pos":"ADJ"} ,
		{"word":"néo-réalistes","word_nosc":"neo-realistes","lemma":"néo-réaliste","pos":"ADJ"} ,
		{"word":"néo-zélandais","word_nosc":"neo-zelandais","lemma":"néo-zélandais","pos":"ADJ"} ,
		{"word":"néo-zélandaise","word_nosc":"neo-zelandaise","lemma":"néo-zélandais","pos":"ADJ"} ,
		{"word":"néoclassique","word_nosc":"neoclassique","lemma":"néoclassique","pos":"ADJ"} ,
		{"word":"néocolonial","word_nosc":"neocolonial","lemma":"néocolonial","pos":"ADJ"} ,
		{"word":"néogothique","word_nosc":"neogothique","lemma":"néogothique","pos":"ADJ"} ,
		{"word":"néolithique","word_nosc":"neolithique","lemma":"néolithique","pos":"ADJ"} ,
		{"word":"néolithiques","word_nosc":"neolithiques","lemma":"néolithique","pos":"ADJ"} ,
		{"word":"néonatal","word_nosc":"neonatal","lemma":"néonatal","pos":"ADJ"} ,
		{"word":"néonatale","word_nosc":"neonatale","lemma":"néonatal","pos":"ADJ"} ,
		{"word":"néonazie","word_nosc":"neonazie","lemma":"néonazi","pos":"ADJ"} ,
		{"word":"néonazis","word_nosc":"neonazis","lemma":"néonazi","pos":"ADJ"} ,
		{"word":"néophyte","word_nosc":"neophyte","lemma":"néophyte","pos":"ADJ"} ,
		{"word":"néophytes","word_nosc":"neophytes","lemma":"néophyte","pos":"ADJ"} ,
		{"word":"néoplasique","word_nosc":"neoplasique","lemma":"néoplasique","pos":"ADJ"} ,
		{"word":"népalais","word_nosc":"nepalais","lemma":"népalais","pos":"ADJ"} ,
		{"word":"népalaise","word_nosc":"nepalaise","lemma":"népalais","pos":"ADJ"} ,
		{"word":"népalaises","word_nosc":"nepalaises","lemma":"népalais","pos":"ADJ"} ,
		{"word":"néphrétique","word_nosc":"nephretique","lemma":"néphrétique","pos":"ADJ"} ,
		{"word":"néphrétiques","word_nosc":"nephretiques","lemma":"néphrétique","pos":"ADJ"} ,
		{"word":"nés","word_nosc":"nes","lemma":"né","pos":"ADJ"} ,
		{"word":"névralgique","word_nosc":"nevralgique","lemma":"névralgique","pos":"ADJ"} ,
		{"word":"névralgiques","word_nosc":"nevralgiques","lemma":"névralgique","pos":"ADJ"} ,
		{"word":"névrosé","word_nosc":"nevrose","lemma":"névrosé","pos":"ADJ"} ,
		{"word":"névrosée","word_nosc":"nevrosee","lemma":"névrosé","pos":"ADJ"} ,
		{"word":"névrosées","word_nosc":"nevrosees","lemma":"névrosé","pos":"ADJ"} ,
		{"word":"névrosés","word_nosc":"nevroses","lemma":"névrosé","pos":"ADJ"} ,
		{"word":"névrotique","word_nosc":"nevrotique","lemma":"névrotique","pos":"ADJ"} ,
		{"word":"névrotiques","word_nosc":"nevrotiques","lemma":"névrotique","pos":"ADJ"} ,
		{"word":"nôtre","word_nosc":"notre","lemma":"nôtre","pos":"ADJ:pos"} ,
		{"word":"nôtres","word_nosc":"notres","lemma":"nôtres","pos":"ADJ:pos"} ,
		{"word":"obituaire","word_nosc":"obituaire","lemma":"obituaire","pos":"ADJ"} ,
		{"word":"objectal","word_nosc":"objectal","lemma":"objectal","pos":"ADJ"} ,
		{"word":"objectale","word_nosc":"objectale","lemma":"objectal","pos":"ADJ"} ,
		{"word":"objectif","word_nosc":"objectif","lemma":"objectif","pos":"ADJ"} ,
		{"word":"objectifs","word_nosc":"objectifs","lemma":"objectif","pos":"ADJ"} ,
		{"word":"objective","word_nosc":"objective","lemma":"objectif","pos":"ADJ"} ,
		{"word":"objectives","word_nosc":"objectives","lemma":"objectif","pos":"ADJ"} ,
		{"word":"oblatif","word_nosc":"oblatif","lemma":"oblatif","pos":"ADJ"} ,
		{"word":"oblative","word_nosc":"oblative","lemma":"oblatif","pos":"ADJ"} ,
		{"word":"obligataire","word_nosc":"obligataire","lemma":"obligataire","pos":"ADJ"} ,
		{"word":"obligataires","word_nosc":"obligataires","lemma":"obligataire","pos":"ADJ"} ,
		{"word":"obligatoire","word_nosc":"obligatoire","lemma":"obligatoire","pos":"ADJ"} ,
		{"word":"obligatoires","word_nosc":"obligatoires","lemma":"obligatoire","pos":"ADJ"} ,
		{"word":"obligeant","word_nosc":"obligeant","lemma":"obligeant","pos":"ADJ"} ,
		{"word":"obligeante","word_nosc":"obligeante","lemma":"obligeant","pos":"ADJ"} ,
		{"word":"obligeantes","word_nosc":"obligeantes","lemma":"obligeant","pos":"ADJ"} ,
		{"word":"obligeants","word_nosc":"obligeants","lemma":"obligeant","pos":"ADJ"} ,
		{"word":"obligé","word_nosc":"oblige","lemma":"obligé","pos":"ADJ"} ,
		{"word":"obligée","word_nosc":"obligee","lemma":"obligé","pos":"ADJ"} ,
		{"word":"obligées","word_nosc":"obligees","lemma":"obligé","pos":"ADJ"} ,
		{"word":"obligés","word_nosc":"obliges","lemma":"obligé","pos":"ADJ"} ,
		{"word":"oblique","word_nosc":"oblique","lemma":"oblique","pos":"ADJ"} ,
		{"word":"obliques","word_nosc":"obliques","lemma":"oblique","pos":"ADJ"} ,
		{"word":"oblitéré","word_nosc":"oblitere","lemma":"oblitéré","pos":"ADJ"} ,
		{"word":"oblitérés","word_nosc":"obliteres","lemma":"oblitéré","pos":"ADJ"} ,
		{"word":"oblong","word_nosc":"oblong","lemma":"oblong","pos":"ADJ"} ,
		{"word":"oblongs","word_nosc":"oblongs","lemma":"oblong","pos":"ADJ"} ,
		{"word":"oblongue","word_nosc":"oblongue","lemma":"oblong","pos":"ADJ"} ,
		{"word":"oblongues","word_nosc":"oblongues","lemma":"oblong","pos":"ADJ"} ,
		{"word":"obnubilé","word_nosc":"obnubile","lemma":"obnubilé","pos":"ADJ"} ,
		{"word":"obnubilée","word_nosc":"obnubilee","lemma":"obnubilé","pos":"ADJ"} ,
		{"word":"obscur","word_nosc":"obscur","lemma":"obscur","pos":"ADJ"} ,
		{"word":"obscurantiste","word_nosc":"obscurantiste","lemma":"obscurantiste","pos":"ADJ"} ,
		{"word":"obscure","word_nosc":"obscure","lemma":"obscur","pos":"ADJ"} ,
		{"word":"obscures","word_nosc":"obscures","lemma":"obscur","pos":"ADJ"} ,
		{"word":"obscurs","word_nosc":"obscurs","lemma":"obscur","pos":"ADJ"} ,
		{"word":"obscène","word_nosc":"obscene","lemma":"obscène","pos":"ADJ"} ,
		{"word":"obscènes","word_nosc":"obscenes","lemma":"obscène","pos":"ADJ"} ,
		{"word":"observable","word_nosc":"observable","lemma":"observable","pos":"ADJ"} ,
		{"word":"observant","word_nosc":"observant","lemma":"observant","pos":"ADJ"} ,
		{"word":"observateur","word_nosc":"observateur","lemma":"observateur","pos":"ADJ"} ,
		{"word":"observateurs","word_nosc":"observateurs","lemma":"observateur","pos":"ADJ"} ,
		{"word":"observatrice","word_nosc":"observatrice","lemma":"observateur","pos":"ADJ"} ,
		{"word":"observé","word_nosc":"observe","lemma":"observé","pos":"ADJ"} ,
		{"word":"observée","word_nosc":"observee","lemma":"observé","pos":"ADJ"} ,
		{"word":"observées","word_nosc":"observees","lemma":"observé","pos":"ADJ"} ,
		{"word":"observés","word_nosc":"observes","lemma":"observé","pos":"ADJ"} ,
		{"word":"obsessif","word_nosc":"obsessif","lemma":"obsessif","pos":"ADJ"} ,
		{"word":"obsessionnel","word_nosc":"obsessionnel","lemma":"obsessionnel","pos":"ADJ"} ,
		{"word":"obsessionnelle","word_nosc":"obsessionnelle","lemma":"obsessionnel","pos":"ADJ"} ,
		{"word":"obsessionnelles","word_nosc":"obsessionnelles","lemma":"obsessionnel","pos":"ADJ"} ,
		{"word":"obsessionnels","word_nosc":"obsessionnels","lemma":"obsessionnel","pos":"ADJ"} ,
		{"word":"obsessive","word_nosc":"obsessive","lemma":"obsessif","pos":"ADJ"} ,
		{"word":"obsidional","word_nosc":"obsidional","lemma":"obsidional","pos":"ADJ"} ,
		{"word":"obsidionale","word_nosc":"obsidionale","lemma":"obsidional","pos":"ADJ"} ,
		{"word":"obsolète","word_nosc":"obsolete","lemma":"obsolète","pos":"ADJ"} ,
		{"word":"obsolètes","word_nosc":"obsoletes","lemma":"obsolète","pos":"ADJ"} ,
		{"word":"obstiné","word_nosc":"obstine","lemma":"obstiné","pos":"ADJ"} ,
		{"word":"obstinée","word_nosc":"obstinee","lemma":"obstiné","pos":"ADJ"} ,
		{"word":"obstinées","word_nosc":"obstinees","lemma":"obstiné","pos":"ADJ"} ,
		{"word":"obstinés","word_nosc":"obstines","lemma":"obstiné","pos":"ADJ"} ,
		{"word":"obstructif","word_nosc":"obstructif","lemma":"obstructif","pos":"ADJ"} ,
		{"word":"obstructive","word_nosc":"obstructive","lemma":"obstructif","pos":"ADJ"} ,
		{"word":"obstrué","word_nosc":"obstrue","lemma":"obstrué","pos":"ADJ"} ,
		{"word":"obstruée","word_nosc":"obstruee","lemma":"obstrué","pos":"ADJ"} ,
		{"word":"obstrués","word_nosc":"obstrues","lemma":"obstrué","pos":"ADJ"} ,
		{"word":"obstétrical","word_nosc":"obstetrical","lemma":"obstétrical","pos":"ADJ"} ,
		{"word":"obsédant","word_nosc":"obsedant","lemma":"obsédant","pos":"ADJ"} ,
		{"word":"obsédante","word_nosc":"obsedante","lemma":"obsédant","pos":"ADJ"} ,
		{"word":"obsédantes","word_nosc":"obsedantes","lemma":"obsédant","pos":"ADJ"} ,
		{"word":"obsédants","word_nosc":"obsedants","lemma":"obsédant","pos":"ADJ"} ,
		{"word":"obsédé","word_nosc":"obsede","lemma":"obsédé","pos":"ADJ"} ,
		{"word":"obsédée","word_nosc":"obsedee","lemma":"obsédé","pos":"ADJ"} ,
		{"word":"obsédées","word_nosc":"obsedees","lemma":"obsédé","pos":"ADJ"} ,
		{"word":"obsédés","word_nosc":"obsedes","lemma":"obsédé","pos":"ADJ"} ,
		{"word":"obséquieuse","word_nosc":"obsequieuse","lemma":"obséquieux","pos":"ADJ"} ,
		{"word":"obséquieuses","word_nosc":"obsequieuses","lemma":"obséquieux","pos":"ADJ"} ,
		{"word":"obséquieux","word_nosc":"obsequieux","lemma":"obséquieux","pos":"ADJ"} ,
		{"word":"obturateur","word_nosc":"obturateur","lemma":"obturateur","pos":"ADJ"} ,
		{"word":"obturateurs","word_nosc":"obturateurs","lemma":"obturateur","pos":"ADJ"} ,
		{"word":"obtus","word_nosc":"obtus","lemma":"obtus","pos":"ADJ"} ,
		{"word":"obtuse","word_nosc":"obtuse","lemma":"obtus","pos":"ADJ"} ,
		{"word":"obtuses","word_nosc":"obtuses","lemma":"obtus","pos":"ADJ"} ,
		{"word":"obvie","word_nosc":"obvie","lemma":"obvie","pos":"ADJ"} ,
		{"word":"obèse","word_nosc":"obese","lemma":"obèse","pos":"ADJ"} ,
		{"word":"obèses","word_nosc":"obeses","lemma":"obèse","pos":"ADJ"} ,
		{"word":"obéissant","word_nosc":"obeissant","lemma":"obéissant","pos":"ADJ"} ,
		{"word":"obéissante","word_nosc":"obeissante","lemma":"obéissant","pos":"ADJ"} ,
		{"word":"obéissantes","word_nosc":"obeissantes","lemma":"obéissant","pos":"ADJ"} ,
		{"word":"obéissants","word_nosc":"obeissants","lemma":"obéissant","pos":"ADJ"} ,
		{"word":"occasionnel","word_nosc":"occasionnel","lemma":"occasionnel","pos":"ADJ"} ,
		{"word":"occasionnelle","word_nosc":"occasionnelle","lemma":"occasionnel","pos":"ADJ"} ,
		{"word":"occasionnelles","word_nosc":"occasionnelles","lemma":"occasionnel","pos":"ADJ"} ,
		{"word":"occasionnels","word_nosc":"occasionnels","lemma":"occasionnel","pos":"ADJ"} ,
		{"word":"occidental","word_nosc":"occidental","lemma":"occidental","pos":"ADJ"} ,
		{"word":"occidentale","word_nosc":"occidentale","lemma":"occidental","pos":"ADJ"} ,
		{"word":"occidentales","word_nosc":"occidentales","lemma":"occidental","pos":"ADJ"} ,
		{"word":"occidentaux","word_nosc":"occidentaux","lemma":"occidental","pos":"ADJ"} ,
		{"word":"occipital","word_nosc":"occipital","lemma":"occipital","pos":"ADJ"} ,
		{"word":"occipitale","word_nosc":"occipitale","lemma":"occipital","pos":"ADJ"} ,
		{"word":"occipitales","word_nosc":"occipitales","lemma":"occipital","pos":"ADJ"} ,
		{"word":"occipitaux","word_nosc":"occipitaux","lemma":"occipital","pos":"ADJ"} ,
		{"word":"occis","word_nosc":"occis","lemma":"occis","pos":"ADJ"} ,
		{"word":"occitan","word_nosc":"occitan","lemma":"occitan","pos":"ADJ"} ,
		{"word":"occitane","word_nosc":"occitane","lemma":"occitan","pos":"ADJ"} ,
		{"word":"occitans","word_nosc":"occitans","lemma":"occitan","pos":"ADJ"} ,
		{"word":"occulte","word_nosc":"occulte","lemma":"occulte","pos":"ADJ"} ,
		{"word":"occultes","word_nosc":"occultes","lemma":"occulte","pos":"ADJ"} ,
		{"word":"occultistes","word_nosc":"occultistes","lemma":"occultiste","pos":"ADJ"} ,
		{"word":"occupant","word_nosc":"occupant","lemma":"occupant","pos":"ADJ"} ,
		{"word":"occupante","word_nosc":"occupante","lemma":"occupant","pos":"ADJ"} ,
		{"word":"occupantes","word_nosc":"occupantes","lemma":"occupant","pos":"ADJ"} ,
		{"word":"occupants","word_nosc":"occupants","lemma":"occupant","pos":"ADJ"} ,
		{"word":"occupé","word_nosc":"occupe","lemma":"occupé","pos":"ADJ"} ,
		{"word":"occupée","word_nosc":"occupee","lemma":"occupé","pos":"ADJ"} ,
		{"word":"occupées","word_nosc":"occupees","lemma":"occupé","pos":"ADJ"} ,
		{"word":"occupés","word_nosc":"occupes","lemma":"occupé","pos":"ADJ"} ,
		{"word":"occurrentes","word_nosc":"occurrentes","lemma":"occurrent","pos":"ADJ"} ,
		{"word":"ocellée","word_nosc":"ocellee","lemma":"ocellé","pos":"ADJ"} ,
		{"word":"ocellés","word_nosc":"ocelles","lemma":"ocellé","pos":"ADJ"} ,
		{"word":"ocre","word_nosc":"ocre","lemma":"ocre","pos":"ADJ"} ,
		{"word":"ocreux","word_nosc":"ocreux","lemma":"ocreux","pos":"ADJ"} ,
		{"word":"ocrée","word_nosc":"ocree","lemma":"ocré","pos":"ADJ"} ,
		{"word":"ocrés","word_nosc":"ocres","lemma":"ocré","pos":"ADJ"} ,
		{"word":"octante","word_nosc":"octante","lemma":"octante","pos":"ADJ:num"} ,
		{"word":"octogonal","word_nosc":"octogonal","lemma":"octogonal","pos":"ADJ"} ,
		{"word":"octogonale","word_nosc":"octogonale","lemma":"octogonal","pos":"ADJ"} ,
		{"word":"octogonales","word_nosc":"octogonales","lemma":"octogonal","pos":"ADJ"} ,
		{"word":"octogonaux","word_nosc":"octogonaux","lemma":"octogonal","pos":"ADJ"} ,
		{"word":"octogénaire","word_nosc":"octogenaire","lemma":"octogénaire","pos":"ADJ"} ,
		{"word":"octogénaires","word_nosc":"octogenaires","lemma":"octogénaire","pos":"ADJ"} ,
		{"word":"octuple","word_nosc":"octuple","lemma":"octuple","pos":"ADJ"} ,
		{"word":"oculaire","word_nosc":"oculaire","lemma":"oculaire","pos":"ADJ"} ,
		{"word":"oculaires","word_nosc":"oculaires","lemma":"oculaire","pos":"ADJ"} ,
		{"word":"oculomoteur","word_nosc":"oculomoteur","lemma":"oculomoteur","pos":"ADJ"} ,
		{"word":"océane","word_nosc":"oceane","lemma":"océan","pos":"ADJ"} ,
		{"word":"océanienne","word_nosc":"oceanienne","lemma":"océanien","pos":"ADJ"} ,
		{"word":"océanique","word_nosc":"oceanique","lemma":"océanique","pos":"ADJ"} ,
		{"word":"océaniques","word_nosc":"oceaniques","lemma":"océanique","pos":"ADJ"} ,
		{"word":"océanographique","word_nosc":"oceanographique","lemma":"océanographique","pos":"ADJ"} ,
		{"word":"océanographiques","word_nosc":"oceanographiques","lemma":"océanographique","pos":"ADJ"} ,
		{"word":"odieuse","word_nosc":"odieuse","lemma":"odieux","pos":"ADJ"} ,
		{"word":"odieuses","word_nosc":"odieuses","lemma":"odieux","pos":"ADJ"} ,
		{"word":"odieux","word_nosc":"odieux","lemma":"odieux","pos":"ADJ"} ,
		{"word":"odorant","word_nosc":"odorant","lemma":"odorant","pos":"ADJ"} ,
		{"word":"odorante","word_nosc":"odorante","lemma":"odorant","pos":"ADJ"} ,
		{"word":"odorantes","word_nosc":"odorantes","lemma":"odorant","pos":"ADJ"} ,
		{"word":"odorants","word_nosc":"odorants","lemma":"odorant","pos":"ADJ"} ,
		{"word":"odoriférant","word_nosc":"odoriferant","lemma":"odoriférant","pos":"ADJ"} ,
		{"word":"odoriférante","word_nosc":"odoriferante","lemma":"odoriférant","pos":"ADJ"} ,
		{"word":"odoriférantes","word_nosc":"odoriferantes","lemma":"odoriférant","pos":"ADJ"} ,
		{"word":"odoriférants","word_nosc":"odoriferants","lemma":"odoriférant","pos":"ADJ"} ,
		{"word":"oecuménique","word_nosc":"oecumenique","lemma":"oecuménique","pos":"ADJ"} ,
		{"word":"oecuméniques","word_nosc":"oecumeniques","lemma":"oecuménique","pos":"ADJ"} ,
		{"word":"oedipien","word_nosc":"oedipien","lemma":"oedipien","pos":"ADJ"} ,
		{"word":"oedipienne","word_nosc":"oedipienne","lemma":"oedipien","pos":"ADJ"} ,
		{"word":"oedémateuse","word_nosc":"oedemateuse","lemma":"oedémateux","pos":"ADJ"} ,
		{"word":"oenologique","word_nosc":"oenologique","lemma":"oenologique","pos":"ADJ"} ,
		{"word":"oenophile","word_nosc":"oenophile","lemma":"oenophile","pos":"ADJ"} ,
		{"word":"oesophagien","word_nosc":"oesophagien","lemma":"oesophagien","pos":"ADJ"} ,
		{"word":"oesophagienne","word_nosc":"oesophagienne","lemma":"oesophagien","pos":"ADJ"} ,
		{"word":"oesophagiennes","word_nosc":"oesophagiennes","lemma":"oesophagien","pos":"ADJ"} ,
		{"word":"oestrogène","word_nosc":"oestrogene","lemma":"oestrogène","pos":"ADJ"} ,
		{"word":"oestrogènes","word_nosc":"oestrogenes","lemma":"oestrogène","pos":"ADJ"} ,
		{"word":"off","word_nosc":"off","lemma":"off","pos":"ADJ"} ,
		{"word":"offensant","word_nosc":"offensant","lemma":"offensant","pos":"ADJ"} ,
		{"word":"offensante","word_nosc":"offensante","lemma":"offensant","pos":"ADJ"} ,
		{"word":"offensantes","word_nosc":"offensantes","lemma":"offensant","pos":"ADJ"} ,
		{"word":"offensants","word_nosc":"offensants","lemma":"offensant","pos":"ADJ"} ,
		{"word":"offensif","word_nosc":"offensif","lemma":"offensif","pos":"ADJ"} ,
		{"word":"offensifs","word_nosc":"offensifs","lemma":"offensif","pos":"ADJ"} ,
		{"word":"offensive","word_nosc":"offensive","lemma":"offensif","pos":"ADJ"} ,
		{"word":"offensives","word_nosc":"offensives","lemma":"offensif","pos":"ADJ"} ,
		{"word":"offensé","word_nosc":"offense","lemma":"offensé","pos":"ADJ"} ,
		{"word":"offensée","word_nosc":"offensee","lemma":"offensé","pos":"ADJ"} ,
		{"word":"offensées","word_nosc":"offensees","lemma":"offensé","pos":"ADJ"} ,
		{"word":"offensés","word_nosc":"offenses","lemma":"offensé","pos":"ADJ"} ,
		{"word":"officiant","word_nosc":"officiant","lemma":"officiant","pos":"ADJ"} ,
		{"word":"officiants","word_nosc":"officiants","lemma":"officiant","pos":"ADJ"} ,
		{"word":"officiel","word_nosc":"officiel","lemma":"officiel","pos":"ADJ"} ,
		{"word":"officielle","word_nosc":"officielle","lemma":"officiel","pos":"ADJ"} ,
		{"word":"officielles","word_nosc":"officielles","lemma":"officiel","pos":"ADJ"} ,
		{"word":"officiels","word_nosc":"officiels","lemma":"officiel","pos":"ADJ"} ,
		{"word":"officieuse","word_nosc":"officieuse","lemma":"officieux","pos":"ADJ"} ,
		{"word":"officieuses","word_nosc":"officieuses","lemma":"officieux","pos":"ADJ"} ,
		{"word":"officieux","word_nosc":"officieux","lemma":"officieux","pos":"ADJ"} ,
		{"word":"offrant","word_nosc":"offrant","lemma":"offrant","pos":"ADJ"} ,
		{"word":"offrante","word_nosc":"offrante","lemma":"offrant","pos":"ADJ"} ,
		{"word":"offrantes","word_nosc":"offrantes","lemma":"offrant","pos":"ADJ"} ,
		{"word":"offrants","word_nosc":"offrants","lemma":"offrant","pos":"ADJ"} ,
		{"word":"offset","word_nosc":"offset","lemma":"offset","pos":"ADJ"} ,
		{"word":"offshore","word_nosc":"offshore","lemma":"offshore","pos":"ADJ"} ,
		{"word":"offusquant","word_nosc":"offusquant","lemma":"offusquant","pos":"ADJ"} ,
		{"word":"offusqué","word_nosc":"offusque","lemma":"offusqué","pos":"ADJ"} ,
		{"word":"offusquée","word_nosc":"offusquee","lemma":"offusqué","pos":"ADJ"} ,
		{"word":"offusquées","word_nosc":"offusquees","lemma":"offusqué","pos":"ADJ"} ,
		{"word":"offusqués","word_nosc":"offusques","lemma":"offusqué","pos":"ADJ"} ,
		{"word":"ogival","word_nosc":"ogival","lemma":"ogival","pos":"ADJ"} ,
		{"word":"ointe","word_nosc":"ointe","lemma":"oint","pos":"ADJ"} ,
		{"word":"ointes","word_nosc":"ointes","lemma":"oint","pos":"ADJ"} ,
		{"word":"oints","word_nosc":"oints","lemma":"oint","pos":"ADJ"} ,
		{"word":"oiseuse","word_nosc":"oiseuse","lemma":"oiseux","pos":"ADJ"} ,
		{"word":"oiseuses","word_nosc":"oiseuses","lemma":"oiseux","pos":"ADJ"} ,
		{"word":"oiseux","word_nosc":"oiseux","lemma":"oiseux","pos":"ADJ"} ,
		{"word":"oisif","word_nosc":"oisif","lemma":"oisif","pos":"ADJ"} ,
		{"word":"oisifs","word_nosc":"oisifs","lemma":"oisif","pos":"ADJ"} ,
		{"word":"oisive","word_nosc":"oisive","lemma":"oisif","pos":"ADJ"} ,
		{"word":"oisives","word_nosc":"oisives","lemma":"oisif","pos":"ADJ"} ,
		{"word":"ok","word_nosc":"ok","lemma":"ok","pos":"ADJ"} ,
		{"word":"olfactif","word_nosc":"olfactif","lemma":"olfactif","pos":"ADJ"} ,
		{"word":"olfactifs","word_nosc":"olfactifs","lemma":"olfactif","pos":"ADJ"} ,
		{"word":"olfactive","word_nosc":"olfactive","lemma":"olfactif","pos":"ADJ"} ,
		{"word":"olfactives","word_nosc":"olfactives","lemma":"olfactif","pos":"ADJ"} ,
		{"word":"olive","word_nosc":"olive","lemma":"olive","pos":"ADJ"} ,
		{"word":"olivâtre","word_nosc":"olivatre","lemma":"olivâtre","pos":"ADJ"} ,
		{"word":"olivâtres","word_nosc":"olivatres","lemma":"olivâtre","pos":"ADJ"} ,
		{"word":"olmèque","word_nosc":"olmeque","lemma":"olmèque","pos":"ADJ"} ,
		{"word":"olographe","word_nosc":"olographe","lemma":"olographe","pos":"ADJ"} ,
		{"word":"olympien","word_nosc":"olympien","lemma":"olympien","pos":"ADJ"} ,
		{"word":"olympienne","word_nosc":"olympienne","lemma":"olympien","pos":"ADJ"} ,
		{"word":"olympiens","word_nosc":"olympiens","lemma":"olympien","pos":"ADJ"} ,
		{"word":"olympique","word_nosc":"olympique","lemma":"olympique","pos":"ADJ"} ,
		{"word":"olympiques","word_nosc":"olympiques","lemma":"olympique","pos":"ADJ"} ,
		{"word":"olé-olé","word_nosc":"ole-ole","lemma":"olé-olé","pos":"ADJ"} ,
		{"word":"omani","word_nosc":"omani","lemma":"omani","pos":"ADJ"} ,
		{"word":"ombilical","word_nosc":"ombilical","lemma":"ombilical","pos":"ADJ"} ,
		{"word":"ombilicale","word_nosc":"ombilicale","lemma":"ombilical","pos":"ADJ"} ,
		{"word":"ombilicaux","word_nosc":"ombilicaux","lemma":"ombilical","pos":"ADJ"} ,
		{"word":"ombrageuse","word_nosc":"ombrageuse","lemma":"ombrageux","pos":"ADJ"} ,
		{"word":"ombrageuses","word_nosc":"ombrageuses","lemma":"ombrageux","pos":"ADJ"} ,
		{"word":"ombrageux","word_nosc":"ombrageux","lemma":"ombrageux","pos":"ADJ"} ,
		{"word":"ombragé","word_nosc":"ombrage","lemma":"ombragé","pos":"ADJ"} ,
		{"word":"ombragée","word_nosc":"ombragee","lemma":"ombragé","pos":"ADJ"} ,
		{"word":"ombragées","word_nosc":"ombragees","lemma":"ombragé","pos":"ADJ"} ,
		{"word":"ombragés","word_nosc":"ombrages","lemma":"ombragé","pos":"ADJ"} ,
		{"word":"ombrant","word_nosc":"ombrant","lemma":"ombrant","pos":"ADJ"} ,
		{"word":"ombreuse","word_nosc":"ombreuse","lemma":"ombreux","pos":"ADJ"} ,
		{"word":"ombreuses","word_nosc":"ombreuses","lemma":"ombreux","pos":"ADJ"} ,
		{"word":"ombreux","word_nosc":"ombreux","lemma":"ombreux","pos":"ADJ"} ,
		{"word":"ombré","word_nosc":"ombre","lemma":"ombré","pos":"ADJ"} ,
		{"word":"ombrés","word_nosc":"ombres","lemma":"ombré","pos":"ADJ"} ,
		{"word":"omis","word_nosc":"omis","lemma":"omis","pos":"ADJ"} ,
		{"word":"omnidirectionnel","word_nosc":"omnidirectionnel","lemma":"omnidirectionnel","pos":"ADJ"} ,
		{"word":"omnidirectionnelle","word_nosc":"omnidirectionnelle","lemma":"omnidirectionnel","pos":"ADJ"} ,
		{"word":"omnipotent","word_nosc":"omnipotent","lemma":"omnipotent","pos":"ADJ"} ,
		{"word":"omnipotente","word_nosc":"omnipotente","lemma":"omnipotent","pos":"ADJ"} ,
		{"word":"omnipotentes","word_nosc":"omnipotentes","lemma":"omnipotent","pos":"ADJ"} ,
		{"word":"omniprésent","word_nosc":"omnipresent","lemma":"omniprésent","pos":"ADJ"} ,
		{"word":"omniprésente","word_nosc":"omnipresente","lemma":"omniprésent","pos":"ADJ"} ,
		{"word":"omniprésentes","word_nosc":"omnipresentes","lemma":"omniprésent","pos":"ADJ"} ,
		{"word":"omniprésents","word_nosc":"omnipresents","lemma":"omniprésent","pos":"ADJ"} ,
		{"word":"omniscient","word_nosc":"omniscient","lemma":"omniscient","pos":"ADJ"} ,
		{"word":"omnisciente","word_nosc":"omnisciente","lemma":"omniscient","pos":"ADJ"} ,
		{"word":"omniscients","word_nosc":"omniscients","lemma":"omniscient","pos":"ADJ"} ,
		{"word":"omnisports","word_nosc":"omnisports","lemma":"omnisports","pos":"ADJ"} ,
		{"word":"omnivore","word_nosc":"omnivore","lemma":"omnivore","pos":"ADJ"} ,
		{"word":"omnivores","word_nosc":"omnivores","lemma":"omnivore","pos":"ADJ"} ,
		{"word":"oncologique","word_nosc":"oncologique","lemma":"oncologique","pos":"ADJ"} ,
		{"word":"onctueuse","word_nosc":"onctueuse","lemma":"onctueux","pos":"ADJ"} ,
		{"word":"onctueuses","word_nosc":"onctueuses","lemma":"onctueux","pos":"ADJ"} ,
		{"word":"onctueux","word_nosc":"onctueux","lemma":"onctueux","pos":"ADJ"} ,
		{"word":"ondoyant","word_nosc":"ondoyant","lemma":"ondoyant","pos":"ADJ"} ,
		{"word":"ondoyante","word_nosc":"ondoyante","lemma":"ondoyant","pos":"ADJ"} ,
		{"word":"ondoyantes","word_nosc":"ondoyantes","lemma":"ondoyant","pos":"ADJ"} ,
		{"word":"ondoyants","word_nosc":"ondoyants","lemma":"ondoyant","pos":"ADJ"} ,
		{"word":"ondulant","word_nosc":"ondulant","lemma":"ondulant","pos":"ADJ"} ,
		{"word":"ondulante","word_nosc":"ondulante","lemma":"ondulant","pos":"ADJ"} ,
		{"word":"ondulantes","word_nosc":"ondulantes","lemma":"ondulant","pos":"ADJ"} ,
		{"word":"ondulants","word_nosc":"ondulants","lemma":"ondulant","pos":"ADJ"} ,
		{"word":"ondulatoire","word_nosc":"ondulatoire","lemma":"ondulatoire","pos":"ADJ"} ,
		{"word":"ondulatoires","word_nosc":"ondulatoires","lemma":"ondulatoire","pos":"ADJ"} ,
		{"word":"onduleuse","word_nosc":"onduleuse","lemma":"onduleux","pos":"ADJ"} ,
		{"word":"onduleuses","word_nosc":"onduleuses","lemma":"onduleux","pos":"ADJ"} ,
		{"word":"onduleux","word_nosc":"onduleux","lemma":"onduleux","pos":"ADJ"} ,
		{"word":"ondulé","word_nosc":"ondule","lemma":"ondulé","pos":"ADJ"} ,
		{"word":"ondulée","word_nosc":"ondulee","lemma":"ondulé","pos":"ADJ"} ,
		{"word":"ondulées","word_nosc":"ondulees","lemma":"ondulé","pos":"ADJ"} ,
		{"word":"ondulés","word_nosc":"ondules","lemma":"ondulé","pos":"ADJ"} ,
		{"word":"ondée","word_nosc":"ondee","lemma":"ondée","pos":"ADJ"} ,
		{"word":"ondées","word_nosc":"ondees","lemma":"ondée","pos":"ADJ"} ,
		{"word":"ondés","word_nosc":"ondes","lemma":"ondé","pos":"ADJ"} ,
		{"word":"onglée","word_nosc":"onglee","lemma":"onglée","pos":"ADJ"} ,
		{"word":"ongulé","word_nosc":"ongule","lemma":"ongulé","pos":"ADJ"} ,
		{"word":"ongulés","word_nosc":"ongules","lemma":"ongulé","pos":"ADJ"} ,
		{"word":"onirique","word_nosc":"onirique","lemma":"onirique","pos":"ADJ"} ,
		{"word":"oniriques","word_nosc":"oniriques","lemma":"onirique","pos":"ADJ"} ,
		{"word":"onomatopéique","word_nosc":"onomatopeique","lemma":"onomatopéique","pos":"ADJ"} ,
		{"word":"ontologique","word_nosc":"ontologique","lemma":"ontologique","pos":"ADJ"} ,
		{"word":"onusienne","word_nosc":"onusienne","lemma":"onusien","pos":"ADJ"} ,
		{"word":"onusiennes","word_nosc":"onusiennes","lemma":"onusien","pos":"ADJ"} ,
		{"word":"onusiens","word_nosc":"onusiens","lemma":"onusien","pos":"ADJ"} ,
		{"word":"onze","word_nosc":"onze","lemma":"onze","pos":"ADJ:num"} ,
		{"word":"onzième","word_nosc":"onzieme","lemma":"onzième","pos":"ADJ"} ,
		{"word":"onéreuse","word_nosc":"onereuse","lemma":"onéreux","pos":"ADJ"} ,
		{"word":"onéreuses","word_nosc":"onereuses","lemma":"onéreux","pos":"ADJ"} ,
		{"word":"onéreux","word_nosc":"onereux","lemma":"onéreux","pos":"ADJ"} ,
		{"word":"opale","word_nosc":"opale","lemma":"opale","pos":"ADJ"} ,
		{"word":"opalescent","word_nosc":"opalescent","lemma":"opalescent","pos":"ADJ"} ,
		{"word":"opalescente","word_nosc":"opalescente","lemma":"opalescent","pos":"ADJ"} ,
		{"word":"opalescentes","word_nosc":"opalescentes","lemma":"opalescent","pos":"ADJ"} ,
		{"word":"opalin","word_nosc":"opalin","lemma":"opalin","pos":"ADJ"} ,
		{"word":"opaline","word_nosc":"opaline","lemma":"opalin","pos":"ADJ"} ,
		{"word":"opalines","word_nosc":"opalines","lemma":"opalin","pos":"ADJ"} ,
		{"word":"opalins","word_nosc":"opalins","lemma":"opalin","pos":"ADJ"} ,
		{"word":"opaque","word_nosc":"opaque","lemma":"opaque","pos":"ADJ"} ,
		{"word":"opaques","word_nosc":"opaques","lemma":"opaque","pos":"ADJ"} ,
		{"word":"open","word_nosc":"open","lemma":"open","pos":"ADJ"} ,
		{"word":"ophtalmique","word_nosc":"ophtalmique","lemma":"ophtalmique","pos":"ADJ"} ,
		{"word":"ophtalmologique","word_nosc":"ophtalmologique","lemma":"ophtalmologique","pos":"ADJ"} ,
		{"word":"opiacé","word_nosc":"opiace","lemma":"opiacé","pos":"ADJ"} ,
		{"word":"opiacées","word_nosc":"opiacees","lemma":"opiacé","pos":"ADJ"} ,
		{"word":"opiacés","word_nosc":"opiaces","lemma":"opiacé","pos":"ADJ"} ,
		{"word":"opimes","word_nosc":"opimes","lemma":"opimes","pos":"ADJ"} ,
		{"word":"opiniâtre","word_nosc":"opiniatre","lemma":"opiniâtre","pos":"ADJ"} ,
		{"word":"opiniâtres","word_nosc":"opiniatres","lemma":"opiniâtre","pos":"ADJ"} ,
		{"word":"opiomane","word_nosc":"opiomane","lemma":"opiomane","pos":"ADJ"} ,
		{"word":"opothérapiques","word_nosc":"opotherapiques","lemma":"opothérapique","pos":"ADJ"} ,
		{"word":"opportun","word_nosc":"opportun","lemma":"opportun","pos":"ADJ"} ,
		{"word":"opportune","word_nosc":"opportune","lemma":"opportun","pos":"ADJ"} ,
		{"word":"opportunes","word_nosc":"opportunes","lemma":"opportun","pos":"ADJ"} ,
		{"word":"opportuniste","word_nosc":"opportuniste","lemma":"opportuniste","pos":"ADJ"} ,
		{"word":"opportunistes","word_nosc":"opportunistes","lemma":"opportuniste","pos":"ADJ"} ,
		{"word":"opportuns","word_nosc":"opportuns","lemma":"opportun","pos":"ADJ"} ,
		{"word":"opposable","word_nosc":"opposable","lemma":"opposable","pos":"ADJ"} ,
		{"word":"opposables","word_nosc":"opposables","lemma":"opposable","pos":"ADJ"} ,
		{"word":"opposant","word_nosc":"opposant","lemma":"opposant","pos":"ADJ"} ,
		{"word":"oppositionnel","word_nosc":"oppositionnel","lemma":"oppositionnel","pos":"ADJ"} ,
		{"word":"oppositionnelle","word_nosc":"oppositionnelle","lemma":"oppositionnel","pos":"ADJ"} ,
		{"word":"opposé","word_nosc":"oppose","lemma":"opposé","pos":"ADJ"} ,
		{"word":"opposée","word_nosc":"opposee","lemma":"opposé","pos":"ADJ"} ,
		{"word":"opposées","word_nosc":"opposees","lemma":"opposé","pos":"ADJ"} ,
		{"word":"opposés","word_nosc":"opposes","lemma":"opposé","pos":"ADJ"} ,
		{"word":"oppressant","word_nosc":"oppressant","lemma":"oppressant","pos":"ADJ"} ,
		{"word":"oppressante","word_nosc":"oppressante","lemma":"oppressant","pos":"ADJ"} ,
		{"word":"oppressantes","word_nosc":"oppressantes","lemma":"oppressant","pos":"ADJ"} ,
		{"word":"oppressants","word_nosc":"oppressants","lemma":"oppressant","pos":"ADJ"} ,
		{"word":"oppresseur","word_nosc":"oppresseur","lemma":"oppresseur","pos":"ADJ"} ,
		{"word":"oppresseurs","word_nosc":"oppresseurs","lemma":"oppresseur","pos":"ADJ"} ,
		{"word":"oppressif","word_nosc":"oppressif","lemma":"oppressif","pos":"ADJ"} ,
		{"word":"oppressive","word_nosc":"oppressive","lemma":"oppressif","pos":"ADJ"} ,
		{"word":"oppressé","word_nosc":"oppresse","lemma":"oppressé","pos":"ADJ"} ,
		{"word":"oppressée","word_nosc":"oppressee","lemma":"oppressé","pos":"ADJ"} ,
		{"word":"oppressées","word_nosc":"oppressees","lemma":"oppressé","pos":"ADJ"} ,
		{"word":"opprimé","word_nosc":"opprime","lemma":"opprimé","pos":"ADJ"} ,
		{"word":"opprimée","word_nosc":"opprimee","lemma":"opprimé","pos":"ADJ"} ,
		{"word":"opprimées","word_nosc":"opprimees","lemma":"opprimé","pos":"ADJ"} ,
		{"word":"opprimés","word_nosc":"opprimes","lemma":"opprimé","pos":"ADJ"} ,
		{"word":"optatif","word_nosc":"optatif","lemma":"optatif","pos":"ADJ"} ,
		{"word":"optatifs","word_nosc":"optatifs","lemma":"optatif","pos":"ADJ"} ,
		{"word":"optimal","word_nosc":"optimal","lemma":"optimal","pos":"ADJ"} ,
		{"word":"optimale","word_nosc":"optimale","lemma":"optimal","pos":"ADJ"} ,
		{"word":"optimales","word_nosc":"optimales","lemma":"optimal","pos":"ADJ"} ,
		{"word":"optimaux","word_nosc":"optimaux","lemma":"optimal","pos":"ADJ"} ,
		{"word":"optimiste","word_nosc":"optimiste","lemma":"optimiste","pos":"ADJ"} ,
		{"word":"optimistes","word_nosc":"optimistes","lemma":"optimiste","pos":"ADJ"} ,
		{"word":"optionnel","word_nosc":"optionnel","lemma":"optionnel","pos":"ADJ"} ,
		{"word":"optionnelle","word_nosc":"optionnelle","lemma":"optionnel","pos":"ADJ"} ,
		{"word":"optique","word_nosc":"optique","lemma":"optique","pos":"ADJ"} ,
		{"word":"optiques","word_nosc":"optiques","lemma":"optique","pos":"ADJ"} ,
		{"word":"opulent","word_nosc":"opulent","lemma":"opulent","pos":"ADJ"} ,
		{"word":"opulente","word_nosc":"opulente","lemma":"opulent","pos":"ADJ"} ,
		{"word":"opulentes","word_nosc":"opulentes","lemma":"opulent","pos":"ADJ"} ,
		{"word":"opulents","word_nosc":"opulents","lemma":"opulent","pos":"ADJ"} ,
		{"word":"opérable","word_nosc":"operable","lemma":"opérable","pos":"ADJ"} ,
		{"word":"opérant","word_nosc":"operant","lemma":"opérant","pos":"ADJ"} ,
		{"word":"opérationnel","word_nosc":"operationnel","lemma":"opérationnel","pos":"ADJ"} ,
		{"word":"opérationnelle","word_nosc":"operationnelle","lemma":"opérationnel","pos":"ADJ"} ,
		{"word":"opérationnelles","word_nosc":"operationnelles","lemma":"opérationnel","pos":"ADJ"} ,
		{"word":"opérationnels","word_nosc":"operationnels","lemma":"opérationnel","pos":"ADJ"} ,
		{"word":"opératique","word_nosc":"operatique","lemma":"opératique","pos":"ADJ"} ,
		{"word":"opératoire","word_nosc":"operatoire","lemma":"opératoire","pos":"ADJ"} ,
		{"word":"opératoires","word_nosc":"operatoires","lemma":"opératoire","pos":"ADJ"} ,
		{"word":"opéré","word_nosc":"opere","lemma":"opéré","pos":"ADJ"} ,
		{"word":"opérée","word_nosc":"operee","lemma":"opéré","pos":"ADJ"} ,
		{"word":"opérées","word_nosc":"operees","lemma":"opéré","pos":"ADJ"} ,
		{"word":"opérés","word_nosc":"operes","lemma":"opéré","pos":"ADJ"} ,
		{"word":"or","word_nosc":"or","lemma":"or","pos":"ADJ"} ,
		{"word":"orageuse","word_nosc":"orageuse","lemma":"orageux","pos":"ADJ"} ,
		{"word":"orageuses","word_nosc":"orageuses","lemma":"orageux","pos":"ADJ"} ,
		{"word":"orageux","word_nosc":"orageux","lemma":"orageux","pos":"ADJ"} ,
		{"word":"oral","word_nosc":"oral","lemma":"oral","pos":"ADJ"} ,
		{"word":"orale","word_nosc":"orale","lemma":"oral","pos":"ADJ"} ,
		{"word":"orales","word_nosc":"orales","lemma":"oral","pos":"ADJ"} ,
		{"word":"oranais","word_nosc":"oranais","lemma":"oranais","pos":"ADJ"} ,
		{"word":"oranaise","word_nosc":"oranaise","lemma":"oranais","pos":"ADJ"} ,
		{"word":"orange","word_nosc":"orange","lemma":"orange","pos":"ADJ"} ,
		{"word":"orangé","word_nosc":"orange","lemma":"orangé","pos":"ADJ"} ,
		{"word":"orangée","word_nosc":"orangee","lemma":"orangé","pos":"ADJ"} ,
		{"word":"orangées","word_nosc":"orangees","lemma":"orangé","pos":"ADJ"} ,
		{"word":"orangés","word_nosc":"oranges","lemma":"orangé","pos":"ADJ"} ,
		{"word":"orantes","word_nosc":"orantes","lemma":"orant","pos":"ADJ"} ,
		{"word":"orants","word_nosc":"orants","lemma":"orant","pos":"ADJ"} ,
		{"word":"oratoire","word_nosc":"oratoire","lemma":"oratoire","pos":"ADJ"} ,
		{"word":"oratoires","word_nosc":"oratoires","lemma":"oratoire","pos":"ADJ"} ,
		{"word":"oraux","word_nosc":"oraux","lemma":"oral","pos":"ADJ"} ,
		{"word":"orbiculaire","word_nosc":"orbiculaire","lemma":"orbiculaire","pos":"ADJ"} ,
		{"word":"orbitaire","word_nosc":"orbitaire","lemma":"orbitaire","pos":"ADJ"} ,
		{"word":"orbitaires","word_nosc":"orbitaires","lemma":"orbitaire","pos":"ADJ"} ,
		{"word":"orbital","word_nosc":"orbital","lemma":"orbital","pos":"ADJ"} ,
		{"word":"orbitale","word_nosc":"orbitale","lemma":"orbital","pos":"ADJ"} ,
		{"word":"orbitales","word_nosc":"orbitales","lemma":"orbital","pos":"ADJ"} ,
		{"word":"orbitaux","word_nosc":"orbitaux","lemma":"orbital","pos":"ADJ"} ,
		{"word":"orbitèle","word_nosc":"orbitele","lemma":"orbitèle","pos":"ADJ"} ,
		{"word":"orbitèles","word_nosc":"orbiteles","lemma":"orbitèle","pos":"ADJ"} ,
		{"word":"orchestral","word_nosc":"orchestral","lemma":"orchestral","pos":"ADJ"} ,
		{"word":"orchestrale","word_nosc":"orchestrale","lemma":"orchestral","pos":"ADJ"} ,
		{"word":"orchestraux","word_nosc":"orchestraux","lemma":"orchestral","pos":"ADJ"} ,
		{"word":"orchestré","word_nosc":"orchestre","lemma":"orchestré","pos":"ADJ"} ,
		{"word":"orchestrée","word_nosc":"orchestree","lemma":"orchestré","pos":"ADJ"} ,
		{"word":"orchestrées","word_nosc":"orchestrees","lemma":"orchestré","pos":"ADJ"} ,
		{"word":"orchestrés","word_nosc":"orchestres","lemma":"orchestré","pos":"ADJ"} ,
		{"word":"ord","word_nosc":"ord","lemma":"ord","pos":"ADJ"} ,
		{"word":"ordinaire","word_nosc":"ordinaire","lemma":"ordinaire","pos":"ADJ"} ,
		{"word":"ordinaires","word_nosc":"ordinaires","lemma":"ordinaire","pos":"ADJ"} ,
		{"word":"ordinal","word_nosc":"ordinal","lemma":"ordinal","pos":"ADJ"} ,
		{"word":"ordonnateur","word_nosc":"ordonnateur","lemma":"ordonnateur","pos":"ADJ"} ,
		{"word":"ordonnatrice","word_nosc":"ordonnatrice","lemma":"ordonnateur","pos":"ADJ"} ,
		{"word":"ordonné","word_nosc":"ordonne","lemma":"ordonné","pos":"ADJ"} ,
		{"word":"ordonnée","word_nosc":"ordonnee","lemma":"ordonné","pos":"ADJ"} ,
		{"word":"ordonnées","word_nosc":"ordonnees","lemma":"ordonné","pos":"ADJ"} ,
		{"word":"ordonnés","word_nosc":"ordonnes","lemma":"ordonné","pos":"ADJ"} ,
		{"word":"ordurier","word_nosc":"ordurier","lemma":"ordurier","pos":"ADJ"} ,
		{"word":"orduriers","word_nosc":"orduriers","lemma":"ordurier","pos":"ADJ"} ,
		{"word":"ordurière","word_nosc":"orduriere","lemma":"ordurier","pos":"ADJ"} ,
		{"word":"ordurières","word_nosc":"ordurieres","lemma":"ordurier","pos":"ADJ"} ,
		{"word":"orfévrée","word_nosc":"orfevree","lemma":"orfévré","pos":"ADJ"} ,
		{"word":"organiciser","word_nosc":"organiciser","lemma":"organiciser","pos":"ADJ"} ,
		{"word":"organique","word_nosc":"organique","lemma":"organique","pos":"ADJ"} ,
		{"word":"organiques","word_nosc":"organiques","lemma":"organique","pos":"ADJ"} ,
		{"word":"organisateur","word_nosc":"organisateur","lemma":"organisateur","pos":"ADJ"} ,
		{"word":"organisateurs","word_nosc":"organisateurs","lemma":"organisateur","pos":"ADJ"} ,
		{"word":"organisationnelle","word_nosc":"organisationnelle","lemma":"organisationnel","pos":"ADJ"} ,
		{"word":"organisatrice","word_nosc":"organisatrice","lemma":"organisateur","pos":"ADJ"} ,
		{"word":"organistique","word_nosc":"organistique","lemma":"organistique","pos":"ADJ"} ,
		{"word":"organisé","word_nosc":"organise","lemma":"organisé","pos":"ADJ"} ,
		{"word":"organisée","word_nosc":"organisee","lemma":"organisé","pos":"ADJ"} ,
		{"word":"organisées","word_nosc":"organisees","lemma":"organisé","pos":"ADJ"} ,
		{"word":"organisés","word_nosc":"organises","lemma":"organisé","pos":"ADJ"} ,
		{"word":"organométallique","word_nosc":"organometallique","lemma":"organométallique","pos":"ADJ"} ,
		{"word":"orgasmique","word_nosc":"orgasmique","lemma":"orgasmique","pos":"ADJ"} ,
		{"word":"orgastique","word_nosc":"orgastique","lemma":"orgastique","pos":"ADJ"} ,
		{"word":"orgiaque","word_nosc":"orgiaque","lemma":"orgiaque","pos":"ADJ"} ,
		{"word":"orgiastique","word_nosc":"orgiastique","lemma":"orgiastique","pos":"ADJ"} ,
		{"word":"orgiastiques","word_nosc":"orgiastiques","lemma":"orgiastique","pos":"ADJ"} ,
		{"word":"orgueilleuse","word_nosc":"orgueilleuse","lemma":"orgueilleux","pos":"ADJ"} ,
		{"word":"orgueilleuses","word_nosc":"orgueilleuses","lemma":"orgueilleux","pos":"ADJ"} ,
		{"word":"orgueilleux","word_nosc":"orgueilleux","lemma":"orgueilleux","pos":"ADJ"} ,
		{"word":"orientable","word_nosc":"orientable","lemma":"orientable","pos":"ADJ"} ,
		{"word":"oriental","word_nosc":"oriental","lemma":"oriental","pos":"ADJ"} ,
		{"word":"orientale","word_nosc":"orientale","lemma":"oriental","pos":"ADJ"} ,
		{"word":"orientales","word_nosc":"orientales","lemma":"oriental","pos":"ADJ"} ,
		{"word":"orientaliste","word_nosc":"orientaliste","lemma":"orientaliste","pos":"ADJ"} ,
		{"word":"orientaux","word_nosc":"orientaux","lemma":"oriental","pos":"ADJ"} ,
		{"word":"orienteur","word_nosc":"orienteur","lemma":"orienteur","pos":"ADJ"} ,
		{"word":"orienté","word_nosc":"oriente","lemma":"orienté","pos":"ADJ"} ,
		{"word":"orientée","word_nosc":"orientee","lemma":"orienté","pos":"ADJ"} ,
		{"word":"orientées","word_nosc":"orientees","lemma":"orienté","pos":"ADJ"} ,
		{"word":"orientés","word_nosc":"orientes","lemma":"orienté","pos":"ADJ"} ,
		{"word":"originaire","word_nosc":"originaire","lemma":"originaire","pos":"ADJ"} ,
		{"word":"originaires","word_nosc":"originaires","lemma":"originaire","pos":"ADJ"} ,
		{"word":"original","word_nosc":"original","lemma":"original","pos":"ADJ"} ,
		{"word":"originale","word_nosc":"originale","lemma":"original","pos":"ADJ"} ,
		{"word":"originales","word_nosc":"originales","lemma":"original","pos":"ADJ"} ,
		{"word":"originaux","word_nosc":"originaux","lemma":"original","pos":"ADJ"} ,
		{"word":"originel","word_nosc":"originel","lemma":"originel","pos":"ADJ"} ,
		{"word":"originelle","word_nosc":"originelle","lemma":"originel","pos":"ADJ"} ,
		{"word":"originelles","word_nosc":"originelles","lemma":"originel","pos":"ADJ"} ,
		{"word":"originels","word_nosc":"originels","lemma":"originel","pos":"ADJ"} ,
		{"word":"orléanais","word_nosc":"orleanais","lemma":"orléanais","pos":"ADJ"} ,
		{"word":"orléanaise","word_nosc":"orleanaise","lemma":"orléanais","pos":"ADJ"} ,
		{"word":"orléanaises","word_nosc":"orleanaises","lemma":"orléanais","pos":"ADJ"} ,
		{"word":"orléaniste","word_nosc":"orleaniste","lemma":"orléaniste","pos":"ADJ"} ,
		{"word":"orléanistes","word_nosc":"orleanistes","lemma":"orléaniste","pos":"ADJ"} ,
		{"word":"ornemental","word_nosc":"ornemental","lemma":"ornemental","pos":"ADJ"} ,
		{"word":"ornementale","word_nosc":"ornementale","lemma":"ornemental","pos":"ADJ"} ,
		{"word":"ornementales","word_nosc":"ornementales","lemma":"ornemental","pos":"ADJ"} ,
		{"word":"ornithologique","word_nosc":"ornithologique","lemma":"ornithologique","pos":"ADJ"} ,
		{"word":"orné","word_nosc":"orne","lemma":"orné","pos":"ADJ"} ,
		{"word":"ornée","word_nosc":"ornee","lemma":"orné","pos":"ADJ"} ,
		{"word":"ornées","word_nosc":"ornees","lemma":"orné","pos":"ADJ"} ,
		{"word":"ornés","word_nosc":"ornes","lemma":"orné","pos":"ADJ"} ,
		{"word":"orphelin","word_nosc":"orphelin","lemma":"orphelin","pos":"ADJ"} ,
		{"word":"orpheline","word_nosc":"orpheline","lemma":"orphelin","pos":"ADJ"} ,
		{"word":"orphelines","word_nosc":"orphelines","lemma":"orphelin","pos":"ADJ"} ,
		{"word":"orphelins","word_nosc":"orphelins","lemma":"orphelin","pos":"ADJ"} ,
		{"word":"orphique","word_nosc":"orphique","lemma":"orphique","pos":"ADJ"} ,
		{"word":"orphiques","word_nosc":"orphiques","lemma":"orphique","pos":"ADJ"} ,
		{"word":"ort","word_nosc":"ort","lemma":"ort","pos":"ADJ"} ,
		{"word":"orthodontique","word_nosc":"orthodontique","lemma":"orthodontique","pos":"ADJ"} ,
		{"word":"orthodoxe","word_nosc":"orthodoxe","lemma":"orthodoxe","pos":"ADJ"} ,
		{"word":"orthodoxes","word_nosc":"orthodoxes","lemma":"orthodoxe","pos":"ADJ"} ,
		{"word":"orthogonal","word_nosc":"orthogonal","lemma":"orthogonal","pos":"ADJ"} ,
		{"word":"orthogonaux","word_nosc":"orthogonaux","lemma":"orthogonal","pos":"ADJ"} ,
		{"word":"orthographique","word_nosc":"orthographique","lemma":"orthographique","pos":"ADJ"} ,
		{"word":"orthographiques","word_nosc":"orthographiques","lemma":"orthographique","pos":"ADJ"} ,
		{"word":"orthopédique","word_nosc":"orthopedique","lemma":"orthopédique","pos":"ADJ"} ,
		{"word":"orthopédiques","word_nosc":"orthopediques","lemma":"orthopédique","pos":"ADJ"} ,
		{"word":"orthostatique","word_nosc":"orthostatique","lemma":"orthostatique","pos":"ADJ"} ,
		{"word":"oscillant","word_nosc":"oscillant","lemma":"oscillant","pos":"ADJ"} ,
		{"word":"oscillante","word_nosc":"oscillante","lemma":"oscillant","pos":"ADJ"} ,
		{"word":"oscillants","word_nosc":"oscillants","lemma":"oscillant","pos":"ADJ"} ,
		{"word":"oscillatoire","word_nosc":"oscillatoire","lemma":"oscillatoire","pos":"ADJ"} ,
		{"word":"oscillatoires","word_nosc":"oscillatoires","lemma":"oscillatoire","pos":"ADJ"} ,
		{"word":"osseuse","word_nosc":"osseuse","lemma":"osseux","pos":"ADJ"} ,
		{"word":"osseuses","word_nosc":"osseuses","lemma":"osseux","pos":"ADJ"} ,
		{"word":"osseux","word_nosc":"osseux","lemma":"osseux","pos":"ADJ"} ,
		{"word":"ossue","word_nosc":"ossue","lemma":"ossu","pos":"ADJ"} ,
		{"word":"ostensible","word_nosc":"ostensible","lemma":"ostensible","pos":"ADJ"} ,
		{"word":"ostentatoire","word_nosc":"ostentatoire","lemma":"ostentatoire","pos":"ADJ"} ,
		{"word":"ostrogoths","word_nosc":"ostrogoths","lemma":"ostrogoth","pos":"ADJ"} ,
		{"word":"ostéogéniques","word_nosc":"osteogeniques","lemma":"ostéogénique","pos":"ADJ"} ,
		{"word":"ostéologique","word_nosc":"osteologique","lemma":"ostéologique","pos":"ADJ"} ,
		{"word":"osé","word_nosc":"ose","lemma":"osé","pos":"ADJ"} ,
		{"word":"osée","word_nosc":"osee","lemma":"osé","pos":"ADJ"} ,
		{"word":"osées","word_nosc":"osees","lemma":"osé","pos":"ADJ"} ,
		{"word":"osés","word_nosc":"oses","lemma":"osé","pos":"ADJ"} ,
		{"word":"oto-rhino-laryngologique","word_nosc":"oto-rhino-laryngologique","lemma":"oto-rhino-laryngologique","pos":"ADJ"} ,
		{"word":"ottoman","word_nosc":"ottoman","lemma":"ottoman","pos":"ADJ"} ,
		{"word":"ottomane","word_nosc":"ottomane","lemma":"ottoman","pos":"ADJ"} ,
		{"word":"ottomanes","word_nosc":"ottomanes","lemma":"ottoman","pos":"ADJ"} ,
		{"word":"ottomans","word_nosc":"ottomans","lemma":"ottoman","pos":"ADJ"} ,
		{"word":"ouateuse","word_nosc":"ouateuse","lemma":"ouateux","pos":"ADJ"} ,
		{"word":"ouateux","word_nosc":"ouateux","lemma":"ouateux","pos":"ADJ"} ,
		{"word":"ouaté","word_nosc":"ouate","lemma":"ouaté","pos":"ADJ"} ,
		{"word":"ouatée","word_nosc":"ouatee","lemma":"ouaté","pos":"ADJ"} ,
		{"word":"ouatées","word_nosc":"ouatees","lemma":"ouaté","pos":"ADJ"} ,
		{"word":"ouatés","word_nosc":"ouates","lemma":"ouaté","pos":"ADJ"} ,
		{"word":"oubliable","word_nosc":"oubliable","lemma":"oubliable","pos":"ADJ"} ,
		{"word":"oublieuse","word_nosc":"oublieuse","lemma":"oublieux","pos":"ADJ"} ,
		{"word":"oublieuses","word_nosc":"oublieuses","lemma":"oublieux","pos":"ADJ"} ,
		{"word":"oublieux","word_nosc":"oublieux","lemma":"oublieux","pos":"ADJ"} ,
		{"word":"oublié","word_nosc":"oublie","lemma":"oublié","pos":"ADJ"} ,
		{"word":"oubliée","word_nosc":"oubliee","lemma":"oublié","pos":"ADJ"} ,
		{"word":"oubliées","word_nosc":"oubliees","lemma":"oublié","pos":"ADJ"} ,
		{"word":"oubliés","word_nosc":"oublies","lemma":"oublié","pos":"ADJ"} ,
		{"word":"ouest","word_nosc":"ouest","lemma":"ouest","pos":"ADJ"} ,
		{"word":"ouest-allemand","word_nosc":"ouest-allemand","lemma":"ouest-allemand","pos":"ADJ"} ,
		{"word":"ougandaise","word_nosc":"ougandaise","lemma":"ougandais","pos":"ADJ"} ,
		{"word":"ouralienne","word_nosc":"ouralienne","lemma":"ouralien","pos":"ADJ"} ,
		{"word":"ouraniennes","word_nosc":"ouraniennes","lemma":"ouranien","pos":"ADJ"} ,
		{"word":"oursins","word_nosc":"oursins","lemma":"oursin","pos":"ADJ"} ,
		{"word":"out","word_nosc":"out","lemma":"out","pos":"ADJ"} ,
		{"word":"outillé","word_nosc":"outille","lemma":"outillé","pos":"ADJ"} ,
		{"word":"outillée","word_nosc":"outillee","lemma":"outillé","pos":"ADJ"} ,
		{"word":"outillées","word_nosc":"outillees","lemma":"outillé","pos":"ADJ"} ,
		{"word":"outillés","word_nosc":"outilles","lemma":"outillé","pos":"ADJ"} ,
		{"word":"outrageant","word_nosc":"outrageant","lemma":"outrageant","pos":"ADJ"} ,
		{"word":"outrageante","word_nosc":"outrageante","lemma":"outrageant","pos":"ADJ"} ,
		{"word":"outrageantes","word_nosc":"outrageantes","lemma":"outrageant","pos":"ADJ"} ,
		{"word":"outrageants","word_nosc":"outrageants","lemma":"outrageant","pos":"ADJ"} ,
		{"word":"outrageuse","word_nosc":"outrageuse","lemma":"outrageux","pos":"ADJ"} ,
		{"word":"outrageuses","word_nosc":"outrageuses","lemma":"outrageux","pos":"ADJ"} ,
		{"word":"outrageux","word_nosc":"outrageux","lemma":"outrageux","pos":"ADJ"} ,
		{"word":"outrancier","word_nosc":"outrancier","lemma":"outrancier","pos":"ADJ"} ,
		{"word":"outrancière","word_nosc":"outranciere","lemma":"outrancier","pos":"ADJ"} ,
		{"word":"outrancières","word_nosc":"outrancieres","lemma":"outrancier","pos":"ADJ"} ,
		{"word":"outre-tombe","word_nosc":"outre-tombe","lemma":"outre-tombe","pos":"ADJ"} ,
		{"word":"outrecuidant","word_nosc":"outrecuidant","lemma":"outrecuidant","pos":"ADJ"} ,
		{"word":"outrecuidante","word_nosc":"outrecuidante","lemma":"outrecuidant","pos":"ADJ"} ,
		{"word":"outrecuidantes","word_nosc":"outrecuidantes","lemma":"outrecuidant","pos":"ADJ"} ,
		{"word":"outrecuidants","word_nosc":"outrecuidants","lemma":"outrecuidant","pos":"ADJ"} ,
		{"word":"outremer","word_nosc":"outremer","lemma":"outremer","pos":"ADJ"} ,
		{"word":"outré","word_nosc":"outre","lemma":"outré","pos":"ADJ"} ,
		{"word":"outrée","word_nosc":"outree","lemma":"outré","pos":"ADJ"} ,
		{"word":"outrées","word_nosc":"outrees","lemma":"outré","pos":"ADJ"} ,
		{"word":"outrés","word_nosc":"outres","lemma":"outré","pos":"ADJ"} ,
		{"word":"ouvert","word_nosc":"ouvert","lemma":"ouvert","pos":"ADJ"} ,
		{"word":"ouverte","word_nosc":"ouverte","lemma":"ouvert","pos":"ADJ"} ,
		{"word":"ouvertes","word_nosc":"ouvertes","lemma":"ouvert","pos":"ADJ"} ,
		{"word":"ouverts","word_nosc":"ouverts","lemma":"ouvert","pos":"ADJ"} ,
		{"word":"ouvrable","word_nosc":"ouvrable","lemma":"ouvrable","pos":"ADJ"} ,
		{"word":"ouvrables","word_nosc":"ouvrables","lemma":"ouvrable","pos":"ADJ"} ,
		{"word":"ouvragé","word_nosc":"ouvrage","lemma":"ouvragé","pos":"ADJ"} ,
		{"word":"ouvragée","word_nosc":"ouvragee","lemma":"ouvragé","pos":"ADJ"} ,
		{"word":"ouvragées","word_nosc":"ouvragees","lemma":"ouvragé","pos":"ADJ"} ,
		{"word":"ouvragés","word_nosc":"ouvrages","lemma":"ouvragé","pos":"ADJ"} ,
		{"word":"ouvrant","word_nosc":"ouvrant","lemma":"ouvrant","pos":"ADJ"} ,
		{"word":"ouvrants","word_nosc":"ouvrants","lemma":"ouvrant","pos":"ADJ"} ,
		{"word":"ouvrier","word_nosc":"ouvrier","lemma":"ouvrier","pos":"ADJ"} ,
		{"word":"ouvriers","word_nosc":"ouvriers","lemma":"ouvrier","pos":"ADJ"} ,
		{"word":"ouvrière","word_nosc":"ouvriere","lemma":"ouvrier","pos":"ADJ"} ,
		{"word":"ouvrières","word_nosc":"ouvrieres","lemma":"ouvrier","pos":"ADJ"} ,
		{"word":"ouvriériste","word_nosc":"ouvrieriste","lemma":"ouvriériste","pos":"ADJ"} ,
		{"word":"ouvré","word_nosc":"ouvre","lemma":"ouvré","pos":"ADJ"} ,
		{"word":"ouvrées","word_nosc":"ouvrees","lemma":"ouvré","pos":"ADJ"} ,
		{"word":"ouvrés","word_nosc":"ouvres","lemma":"ouvré","pos":"ADJ"} ,
		{"word":"ouzbeks","word_nosc":"ouzbeks","lemma":"ouzbek","pos":"ADJ"} ,
		{"word":"ovalaire","word_nosc":"ovalaire","lemma":"ovalaire","pos":"ADJ"} ,
		{"word":"ovale","word_nosc":"ovale","lemma":"ovale","pos":"ADJ"} ,
		{"word":"ovales","word_nosc":"ovales","lemma":"ovale","pos":"ADJ"} ,
		{"word":"ovarien","word_nosc":"ovarien","lemma":"ovarien","pos":"ADJ"} ,
		{"word":"ovarienne","word_nosc":"ovarienne","lemma":"ovarien","pos":"ADJ"} ,
		{"word":"ovin","word_nosc":"ovin","lemma":"ovin","pos":"ADJ"} ,
		{"word":"ovipare","word_nosc":"ovipare","lemma":"ovipare","pos":"ADJ"} ,
		{"word":"ovipares","word_nosc":"ovipares","lemma":"ovipare","pos":"ADJ"} ,
		{"word":"ovoïdal","word_nosc":"ovoidal","lemma":"ovoïdal","pos":"ADJ"} ,
		{"word":"ovoïdale","word_nosc":"ovoidale","lemma":"ovoïdal","pos":"ADJ"} ,
		{"word":"ovoïde","word_nosc":"ovoide","lemma":"ovoïde","pos":"ADJ"} ,
		{"word":"ovoïdes","word_nosc":"ovoides","lemma":"ovoïde","pos":"ADJ"} ,
		{"word":"ovulaire","word_nosc":"ovulaire","lemma":"ovulaire","pos":"ADJ"} ,
		{"word":"ovée","word_nosc":"ovee","lemma":"ové","pos":"ADJ"} ,
		{"word":"oxfordien","word_nosc":"oxfordien","lemma":"oxfordien","pos":"ADJ"} ,
		{"word":"oxfordiennes","word_nosc":"oxfordiennes","lemma":"oxfordien","pos":"ADJ"} ,
		{"word":"oxhydrique","word_nosc":"oxhydrique","lemma":"oxhydrique","pos":"ADJ"} ,
		{"word":"oxo","word_nosc":"oxo","lemma":"oxo","pos":"ADJ"} ,
		{"word":"oxygéné","word_nosc":"oxygene","lemma":"oxygéné","pos":"ADJ"} ,
		{"word":"oxygénée","word_nosc":"oxygenee","lemma":"oxygéné","pos":"ADJ"} ,
		{"word":"oxygénées","word_nosc":"oxygenees","lemma":"oxygéné","pos":"ADJ"} ,
		{"word":"oxygénés","word_nosc":"oxygenes","lemma":"oxygéné","pos":"ADJ"} ,
		{"word":"oyant","word_nosc":"oyant","lemma":"oyant","pos":"ADJ"} ,
		{"word":"pacas","word_nosc":"pacas","lemma":"paca","pos":"ADJ"} ,
		{"word":"pachydermique","word_nosc":"pachydermique","lemma":"pachydermique","pos":"ADJ"} ,
		{"word":"pachydermiques","word_nosc":"pachydermiques","lemma":"pachydermique","pos":"ADJ"} ,
		{"word":"pacifiante","word_nosc":"pacifiante","lemma":"pacifiant","pos":"ADJ"} ,
		{"word":"pacifiantes","word_nosc":"pacifiantes","lemma":"pacifiant","pos":"ADJ"} ,
		{"word":"pacificateur","word_nosc":"pacificateur","lemma":"pacificateur","pos":"ADJ"} ,
		{"word":"pacificateurs","word_nosc":"pacificateurs","lemma":"pacificateur","pos":"ADJ"} ,
		{"word":"pacificatrice","word_nosc":"pacificatrice","lemma":"pacificateur","pos":"ADJ"} ,
		{"word":"pacifique","word_nosc":"pacifique","lemma":"pacifique","pos":"ADJ"} ,
		{"word":"pacifiques","word_nosc":"pacifiques","lemma":"pacifique","pos":"ADJ"} ,
		{"word":"pacifiste","word_nosc":"pacifiste","lemma":"pacifiste","pos":"ADJ"} ,
		{"word":"pacifistes","word_nosc":"pacifistes","lemma":"pacifiste","pos":"ADJ"} ,
		{"word":"padouanes","word_nosc":"padouanes","lemma":"padouan","pos":"ADJ"} ,
		{"word":"paf","word_nosc":"paf","lemma":"paf","pos":"ADJ"} ,
		{"word":"pagailleux","word_nosc":"pagailleux","lemma":"pagailleux","pos":"ADJ"} ,
		{"word":"paillard","word_nosc":"paillard","lemma":"paillard","pos":"ADJ"} ,
		{"word":"paillarde","word_nosc":"paillarde","lemma":"paillard","pos":"ADJ"} ,
		{"word":"paillardes","word_nosc":"paillardes","lemma":"paillard","pos":"ADJ"} ,
		{"word":"paillards","word_nosc":"paillards","lemma":"paillard","pos":"ADJ"} ,
		{"word":"paille","word_nosc":"paille","lemma":"paille","pos":"ADJ"} ,
		{"word":"pailleté","word_nosc":"paillete","lemma":"pailleté","pos":"ADJ"} ,
		{"word":"pailletée","word_nosc":"pailletee","lemma":"pailleté","pos":"ADJ"} ,
		{"word":"pailletées","word_nosc":"pailletees","lemma":"pailleté","pos":"ADJ"} ,
		{"word":"pailletés","word_nosc":"pailletes","lemma":"pailleté","pos":"ADJ"} ,
		{"word":"pailleux","word_nosc":"pailleux","lemma":"pailleux","pos":"ADJ"} ,
		{"word":"paillu","word_nosc":"paillu","lemma":"paillu","pos":"ADJ"} ,
		{"word":"paillé","word_nosc":"paille","lemma":"paillé","pos":"ADJ"} ,
		{"word":"paillée","word_nosc":"paillee","lemma":"paillé","pos":"ADJ"} ,
		{"word":"paillées","word_nosc":"paillees","lemma":"paillé","pos":"ADJ"} ,
		{"word":"paimpolaise","word_nosc":"paimpolaise","lemma":"paimpolais","pos":"ADJ"} ,
		{"word":"pair","word_nosc":"pair","lemma":"pair","pos":"ADJ"} ,
		{"word":"paire","word_nosc":"paire","lemma":"pair","pos":"ADJ"} ,
		{"word":"paires","word_nosc":"paires","lemma":"pair","pos":"ADJ"} ,
		{"word":"pairs","word_nosc":"pairs","lemma":"pair","pos":"ADJ"} ,
		{"word":"paisible","word_nosc":"paisible","lemma":"paisible","pos":"ADJ"} ,
		{"word":"paisibles","word_nosc":"paisibles","lemma":"paisible","pos":"ADJ"} ,
		{"word":"pakistanais","word_nosc":"pakistanais","lemma":"pakistanais","pos":"ADJ"} ,
		{"word":"pakistanaise","word_nosc":"pakistanaise","lemma":"pakistanais","pos":"ADJ"} ,
		{"word":"pakistanaises","word_nosc":"pakistanaises","lemma":"pakistanais","pos":"ADJ"} ,
		{"word":"palatales","word_nosc":"palatales","lemma":"palatal","pos":"ADJ"} ,
		{"word":"palatin","word_nosc":"palatin","lemma":"palatin","pos":"ADJ"} ,
		{"word":"palatine","word_nosc":"palatine","lemma":"palatin","pos":"ADJ"} ,
		{"word":"palestinien","word_nosc":"palestinien","lemma":"palestinien","pos":"ADJ"} ,
		{"word":"palestinienne","word_nosc":"palestinienne","lemma":"palestinien","pos":"ADJ"} ,
		{"word":"palestiniens","word_nosc":"palestiniens","lemma":"palestinien","pos":"ADJ"} ,
		{"word":"palière","word_nosc":"paliere","lemma":"palier","pos":"ADJ"} ,
		{"word":"palladiennes","word_nosc":"palladiennes","lemma":"palladien","pos":"ADJ"} ,
		{"word":"palliatif","word_nosc":"palliatif","lemma":"palliatif","pos":"ADJ"} ,
		{"word":"palliatifs","word_nosc":"palliatifs","lemma":"palliatif","pos":"ADJ"} ,
		{"word":"palmaire","word_nosc":"palmaire","lemma":"palmaire","pos":"ADJ"} ,
		{"word":"palmipèdes","word_nosc":"palmipedes","lemma":"palmipède","pos":"ADJ"} ,
		{"word":"palmée","word_nosc":"palmee","lemma":"palmé","pos":"ADJ"} ,
		{"word":"palmées","word_nosc":"palmees","lemma":"palmé","pos":"ADJ"} ,
		{"word":"palmés","word_nosc":"palmes","lemma":"palmé","pos":"ADJ"} ,
		{"word":"palpable","word_nosc":"palpable","lemma":"palpable","pos":"ADJ"} ,
		{"word":"palpables","word_nosc":"palpables","lemma":"palpable","pos":"ADJ"} ,
		{"word":"palpitant","word_nosc":"palpitant","lemma":"palpitant","pos":"ADJ"} ,
		{"word":"palpitante","word_nosc":"palpitante","lemma":"palpitant","pos":"ADJ"} ,
		{"word":"palpitantes","word_nosc":"palpitantes","lemma":"palpitant","pos":"ADJ"} ,
		{"word":"palpitants","word_nosc":"palpitants","lemma":"palpitant","pos":"ADJ"} ,
		{"word":"paludiques","word_nosc":"paludiques","lemma":"paludique","pos":"ADJ"} ,
		{"word":"paludéenne","word_nosc":"paludeenne","lemma":"paludéen","pos":"ADJ"} ,
		{"word":"paludéennes","word_nosc":"paludeennes","lemma":"paludéen","pos":"ADJ"} ,
		{"word":"paludéens","word_nosc":"paludeens","lemma":"paludéen","pos":"ADJ"} ,
		{"word":"palustre","word_nosc":"palustre","lemma":"palustre","pos":"ADJ"} ,
		{"word":"palé","word_nosc":"pale","lemma":"palé","pos":"ADJ"} ,
		{"word":"paléolithique","word_nosc":"paleolithique","lemma":"paléolithique","pos":"ADJ"} ,
		{"word":"paléolithiques","word_nosc":"paleolithiques","lemma":"paléolithique","pos":"ADJ"} ,
		{"word":"paléontologiques","word_nosc":"paleontologiques","lemma":"paléontologique","pos":"ADJ"} ,
		{"word":"panaché","word_nosc":"panache","lemma":"panaché","pos":"ADJ"} ,
		{"word":"panachés","word_nosc":"panaches","lemma":"panaché","pos":"ADJ"} ,
		{"word":"panamienne","word_nosc":"panamienne","lemma":"panamien","pos":"ADJ"} ,
		{"word":"panamiens","word_nosc":"panamiens","lemma":"panamien","pos":"ADJ"} ,
		{"word":"panaméen","word_nosc":"panameen","lemma":"panaméen","pos":"ADJ"} ,
		{"word":"panaméenne","word_nosc":"panameenne","lemma":"panaméenne","pos":"ADJ"} ,
		{"word":"panaméricain","word_nosc":"panamericain","lemma":"panaméricain","pos":"ADJ"} ,
		{"word":"panaméricaine","word_nosc":"panamericaine","lemma":"panaméricain","pos":"ADJ"} ,
		{"word":"panard","word_nosc":"panard","lemma":"panard","pos":"ADJ"} ,
		{"word":"panards","word_nosc":"panards","lemma":"panard","pos":"ADJ"} ,
		{"word":"panathénaïque","word_nosc":"panathenaique","lemma":"panathénaïque","pos":"ADJ"} ,
		{"word":"panceltique","word_nosc":"panceltique","lemma":"panceltique","pos":"ADJ"} ,
		{"word":"pancréatique","word_nosc":"pancreatique","lemma":"pancréatique","pos":"ADJ"} ,
		{"word":"pancréatiques","word_nosc":"pancreatiques","lemma":"pancréatique","pos":"ADJ"} ,
		{"word":"paniquant","word_nosc":"paniquant","lemma":"paniquant","pos":"ADJ"} ,
		{"word":"paniqué","word_nosc":"panique","lemma":"paniqué","pos":"ADJ"} ,
		{"word":"paniquée","word_nosc":"paniquee","lemma":"paniqué","pos":"ADJ"} ,
		{"word":"paniqués","word_nosc":"paniques","lemma":"paniqué","pos":"ADJ"} ,
		{"word":"panné","word_nosc":"panne","lemma":"panné","pos":"ADJ"} ,
		{"word":"pano","word_nosc":"pano","lemma":"pano","pos":"ADJ"} ,
		{"word":"panoramique","word_nosc":"panoramique","lemma":"panoramique","pos":"ADJ"} ,
		{"word":"panoramiques","word_nosc":"panoramiques","lemma":"panoramique","pos":"ADJ"} ,
		{"word":"panos","word_nosc":"panos","lemma":"pano","pos":"ADJ"} ,
		{"word":"pansu","word_nosc":"pansu","lemma":"pansu","pos":"ADJ"} ,
		{"word":"pansue","word_nosc":"pansue","lemma":"pansu","pos":"ADJ"} ,
		{"word":"pansues","word_nosc":"pansues","lemma":"pansu","pos":"ADJ"} ,
		{"word":"pansus","word_nosc":"pansus","lemma":"pansu","pos":"ADJ"} ,
		{"word":"pantagruélique","word_nosc":"pantagruelique","lemma":"pantagruélique","pos":"ADJ"} ,
		{"word":"pantagruéliques","word_nosc":"pantagrueliques","lemma":"pantagruélique","pos":"ADJ"} ,
		{"word":"pantelant","word_nosc":"pantelant","lemma":"pantelant","pos":"ADJ"} ,
		{"word":"pantelante","word_nosc":"pantelante","lemma":"pantelant","pos":"ADJ"} ,
		{"word":"pantelantes","word_nosc":"pantelantes","lemma":"pantelant","pos":"ADJ"} ,
		{"word":"pantelants","word_nosc":"pantelants","lemma":"pantelant","pos":"ADJ"} ,
		{"word":"panthéiste","word_nosc":"pantheiste","lemma":"panthéiste","pos":"ADJ"} ,
		{"word":"pantins","word_nosc":"pantins","lemma":"pantins","pos":"ADJ"} ,
		{"word":"pantocrator","word_nosc":"pantocrator","lemma":"pantocrator","pos":"ADJ"} ,
		{"word":"pantois","word_nosc":"pantois","lemma":"pantois","pos":"ADJ"} ,
		{"word":"pantoise","word_nosc":"pantoise","lemma":"pantois","pos":"ADJ"} ,
		{"word":"pantoises","word_nosc":"pantoises","lemma":"pantois","pos":"ADJ"} ,
		{"word":"pantouflard","word_nosc":"pantouflard","lemma":"pantouflard","pos":"ADJ"} ,
		{"word":"pantouflarde","word_nosc":"pantouflarde","lemma":"pantouflard","pos":"ADJ"} ,
		{"word":"pané","word_nosc":"pane","lemma":"pané","pos":"ADJ"} ,
		{"word":"panée","word_nosc":"panee","lemma":"pané","pos":"ADJ"} ,
		{"word":"panées","word_nosc":"panees","lemma":"pané","pos":"ADJ"} ,
		{"word":"panés","word_nosc":"panes","lemma":"pané","pos":"ADJ"} ,
		{"word":"paolistes","word_nosc":"paolistes","lemma":"paoliste","pos":"ADJ"} ,
		{"word":"papable","word_nosc":"papable","lemma":"papable","pos":"ADJ"} ,
		{"word":"papal","word_nosc":"papal","lemma":"papal","pos":"ADJ"} ,
		{"word":"papale","word_nosc":"papale","lemma":"papal","pos":"ADJ"} ,
		{"word":"papaux","word_nosc":"papaux","lemma":"papal","pos":"ADJ"} ,
		{"word":"papelard","word_nosc":"papelard","lemma":"papelard","pos":"ADJ"} ,
		{"word":"papelarde","word_nosc":"papelarde","lemma":"papelard","pos":"ADJ"} ,
		{"word":"papelards","word_nosc":"papelards","lemma":"papelard","pos":"ADJ"} ,
		{"word":"papetier","word_nosc":"papetier","lemma":"papetier","pos":"ADJ"} ,
		{"word":"papetiers","word_nosc":"papetiers","lemma":"papetier","pos":"ADJ"} ,
		{"word":"papilionacé","word_nosc":"papilionace","lemma":"papilionacé","pos":"ADJ"} ,
		{"word":"papilionacée","word_nosc":"papilionacee","lemma":"papilionacé","pos":"ADJ"} ,
		{"word":"papilionacées","word_nosc":"papilionacees","lemma":"papilionacé","pos":"ADJ"} ,
		{"word":"papillaire","word_nosc":"papillaire","lemma":"papillaire","pos":"ADJ"} ,
		{"word":"papillonnant","word_nosc":"papillonnant","lemma":"papillonnant","pos":"ADJ"} ,
		{"word":"papillonnante","word_nosc":"papillonnante","lemma":"papillonnant","pos":"ADJ"} ,
		{"word":"papillotant","word_nosc":"papillotant","lemma":"papillotant","pos":"ADJ"} ,
		{"word":"papillotantes","word_nosc":"papillotantes","lemma":"papillotant","pos":"ADJ"} ,
		{"word":"papillotants","word_nosc":"papillotants","lemma":"papillotant","pos":"ADJ"} ,
		{"word":"papiste","word_nosc":"papiste","lemma":"papiste","pos":"ADJ"} ,
		{"word":"papistes","word_nosc":"papistes","lemma":"papiste","pos":"ADJ"} ,
		{"word":"paqueté","word_nosc":"paquete","lemma":"paqueté","pos":"ADJ"} ,
		{"word":"parabolique","word_nosc":"parabolique","lemma":"parabolique","pos":"ADJ"} ,
		{"word":"paraboliques","word_nosc":"paraboliques","lemma":"parabolique","pos":"ADJ"} ,
		{"word":"paradisiaque","word_nosc":"paradisiaque","lemma":"paradisiaque","pos":"ADJ"} ,
		{"word":"paradisiaques","word_nosc":"paradisiaques","lemma":"paradisiaque","pos":"ADJ"} ,
		{"word":"paradoxal","word_nosc":"paradoxal","lemma":"paradoxal","pos":"ADJ"} ,
		{"word":"paradoxale","word_nosc":"paradoxale","lemma":"paradoxal","pos":"ADJ"} ,
		{"word":"paradoxales","word_nosc":"paradoxales","lemma":"paradoxal","pos":"ADJ"} ,
		{"word":"paradoxaux","word_nosc":"paradoxaux","lemma":"paradoxal","pos":"ADJ"} ,
		{"word":"paraffiné","word_nosc":"paraffine","lemma":"paraffiné","pos":"ADJ"} ,
		{"word":"paraffinée","word_nosc":"paraffinee","lemma":"paraffiné","pos":"ADJ"} ,
		{"word":"paraffinés","word_nosc":"paraffines","lemma":"paraffiné","pos":"ADJ"} ,
		{"word":"paragouvernementales","word_nosc":"paragouvernementales","lemma":"paragouvernemental","pos":"ADJ"} ,
		{"word":"paraguayen","word_nosc":"paraguayen","lemma":"paraguayen","pos":"ADJ"} ,
		{"word":"paraguayenne","word_nosc":"paraguayenne","lemma":"paraguayen","pos":"ADJ"} ,
		{"word":"paraguayennes","word_nosc":"paraguayennes","lemma":"paraguayen","pos":"ADJ"} ,
		{"word":"paraguayens","word_nosc":"paraguayens","lemma":"paraguayen","pos":"ADJ"} ,
		{"word":"parallèle","word_nosc":"parallele","lemma":"parallèle","pos":"ADJ"} ,
		{"word":"parallèles","word_nosc":"paralleles","lemma":"parallèle","pos":"ADJ"} ,
		{"word":"paralléliste","word_nosc":"paralleliste","lemma":"paralléliste","pos":"ADJ"} ,
		{"word":"parallélépipédique","word_nosc":"parallelepipedique","lemma":"parallélépipédique","pos":"ADJ"} ,
		{"word":"paralympiques","word_nosc":"paralympiques","lemma":"paralympique","pos":"ADJ"} ,
		{"word":"paralysant","word_nosc":"paralysant","lemma":"paralysant","pos":"ADJ"} ,
		{"word":"paralysante","word_nosc":"paralysante","lemma":"paralysant","pos":"ADJ"} ,
		{"word":"paralysantes","word_nosc":"paralysantes","lemma":"paralysant","pos":"ADJ"} ,
		{"word":"paralysants","word_nosc":"paralysants","lemma":"paralysant","pos":"ADJ"} ,
		{"word":"paralysé","word_nosc":"paralyse","lemma":"paralysé","pos":"ADJ"} ,
		{"word":"paralysée","word_nosc":"paralysee","lemma":"paralysé","pos":"ADJ"} ,
		{"word":"paralysées","word_nosc":"paralysees","lemma":"paralysé","pos":"ADJ"} ,
		{"word":"paralysés","word_nosc":"paralyses","lemma":"paralysé","pos":"ADJ"} ,
		{"word":"paralytique","word_nosc":"paralytique","lemma":"paralytique","pos":"ADJ"} ,
		{"word":"paralytiques","word_nosc":"paralytiques","lemma":"paralytique","pos":"ADJ"} ,
		{"word":"paramagnétique","word_nosc":"paramagnetique","lemma":"paramagnétique","pos":"ADJ"} ,
		{"word":"paramilitaire","word_nosc":"paramilitaire","lemma":"paramilitaire","pos":"ADJ"} ,
		{"word":"paramilitaires","word_nosc":"paramilitaires","lemma":"paramilitaire","pos":"ADJ"} ,
		{"word":"paramédical","word_nosc":"paramedical","lemma":"paramédical","pos":"ADJ"} ,
		{"word":"paramédicale","word_nosc":"paramedicale","lemma":"paramédical","pos":"ADJ"} ,
		{"word":"paramédicaux","word_nosc":"paramedicaux","lemma":"paramédical","pos":"ADJ"} ,
		{"word":"paramétrique","word_nosc":"parametrique","lemma":"paramétrique","pos":"ADJ"} ,
		{"word":"parano","word_nosc":"parano","lemma":"parano","pos":"ADJ"} ,
		{"word":"paranormal","word_nosc":"paranormal","lemma":"paranormal","pos":"ADJ"} ,
		{"word":"paranormale","word_nosc":"paranormale","lemma":"paranormal","pos":"ADJ"} ,
		{"word":"paranormales","word_nosc":"paranormales","lemma":"paranormal","pos":"ADJ"} ,
		{"word":"paranormaux","word_nosc":"paranormaux","lemma":"paranormal","pos":"ADJ"} ,
		{"word":"paranos","word_nosc":"paranos","lemma":"parano","pos":"ADJ"} ,
		{"word":"paranoïaque","word_nosc":"paranoiaque","lemma":"paranoïaque","pos":"ADJ"} ,
		{"word":"paranoïaques","word_nosc":"paranoiaques","lemma":"paranoïaque","pos":"ADJ"} ,
		{"word":"paranoïde","word_nosc":"paranoide","lemma":"paranoïde","pos":"ADJ"} ,
		{"word":"paranéoplasique","word_nosc":"paraneoplasique","lemma":"paranéoplasique","pos":"ADJ"} ,
		{"word":"paraplégique","word_nosc":"paraplegique","lemma":"paraplégique","pos":"ADJ"} ,
		{"word":"paraplégiques","word_nosc":"paraplegiques","lemma":"paraplégique","pos":"ADJ"} ,
		{"word":"parapsychique","word_nosc":"parapsychique","lemma":"parapsychique","pos":"ADJ"} ,
		{"word":"parapsychologique","word_nosc":"parapsychologique","lemma":"parapsychologique","pos":"ADJ"} ,
		{"word":"parapsychologiques","word_nosc":"parapsychologiques","lemma":"parapsychologique","pos":"ADJ"} ,
		{"word":"parareligieuse","word_nosc":"parareligieuse","lemma":"parareligieux","pos":"ADJ"} ,
		{"word":"parascolaire","word_nosc":"parascolaire","lemma":"parascolaire","pos":"ADJ"} ,
		{"word":"parascolaires","word_nosc":"parascolaires","lemma":"parascolaire","pos":"ADJ"} ,
		{"word":"parasitaire","word_nosc":"parasitaire","lemma":"parasitaire","pos":"ADJ"} ,
		{"word":"parasitaires","word_nosc":"parasitaires","lemma":"parasitaire","pos":"ADJ"} ,
		{"word":"parasite","word_nosc":"parasite","lemma":"parasite","pos":"ADJ"} ,
		{"word":"parasites","word_nosc":"parasites","lemma":"parasite","pos":"ADJ"} ,
		{"word":"parasiticide","word_nosc":"parasiticide","lemma":"parasiticide","pos":"ADJ"} ,
		{"word":"parathyroïdienne","word_nosc":"parathyroidienne","lemma":"parathyroïdien","pos":"ADJ"} ,
		{"word":"parcellaire","word_nosc":"parcellaire","lemma":"parcellaire","pos":"ADJ"} ,
		{"word":"parchemineuse","word_nosc":"parchemineuse","lemma":"parchemineux","pos":"ADJ"} ,
		{"word":"parcheminé","word_nosc":"parchemine","lemma":"parcheminé","pos":"ADJ"} ,
		{"word":"parcheminée","word_nosc":"parcheminee","lemma":"parcheminé","pos":"ADJ"} ,
		{"word":"parcheminées","word_nosc":"parcheminees","lemma":"parcheminé","pos":"ADJ"} ,
		{"word":"parcheminés","word_nosc":"parchemines","lemma":"parcheminé","pos":"ADJ"} ,
		{"word":"parcimonieuse","word_nosc":"parcimonieuse","lemma":"parcimonieux","pos":"ADJ"} ,
		{"word":"parcimonieuses","word_nosc":"parcimonieuses","lemma":"parcimonieux","pos":"ADJ"} ,
		{"word":"parcimonieux","word_nosc":"parcimonieux","lemma":"parcimonieux","pos":"ADJ"} ,
		{"word":"pardonnable","word_nosc":"pardonnable","lemma":"pardonnable","pos":"ADJ"} ,
		{"word":"pardons","word_nosc":"pardons","lemma":"pardon","pos":"ADJ"} ,
		{"word":"pare-balle","word_nosc":"pare-balle","lemma":"pare-balle","pos":"ADJ"} ,
		{"word":"pare-balles","word_nosc":"pare-balles","lemma":"pare-balles","pos":"ADJ"} ,
		{"word":"pareil","word_nosc":"pareil","lemma":"pareil","pos":"ADJ"} ,
		{"word":"pareille","word_nosc":"pareille","lemma":"pareil","pos":"ADJ"} ,
		{"word":"pareilles","word_nosc":"pareilles","lemma":"pareil","pos":"ADJ"} ,
		{"word":"pareils","word_nosc":"pareils","lemma":"pareil","pos":"ADJ"} ,
		{"word":"parent","word_nosc":"parent","lemma":"parent","pos":"ADJ"} ,
		{"word":"parental","word_nosc":"parental","lemma":"parental","pos":"ADJ"} ,
		{"word":"parentale","word_nosc":"parentale","lemma":"parental","pos":"ADJ"} ,
		{"word":"parentales","word_nosc":"parentales","lemma":"parental","pos":"ADJ"} ,
		{"word":"parentaux","word_nosc":"parentaux","lemma":"parental","pos":"ADJ"} ,
		{"word":"parente","word_nosc":"parente","lemma":"parent","pos":"ADJ"} ,
		{"word":"parentes","word_nosc":"parentes","lemma":"parent","pos":"ADJ"} ,
		{"word":"parents","word_nosc":"parents","lemma":"parent","pos":"ADJ"} ,
		{"word":"paresseuse","word_nosc":"paresseuse","lemma":"paresseux","pos":"ADJ"} ,
		{"word":"paresseuses","word_nosc":"paresseuses","lemma":"paresseux","pos":"ADJ"} ,
		{"word":"paresseux","word_nosc":"paresseux","lemma":"paresseux","pos":"ADJ"} ,
		{"word":"parfait","word_nosc":"parfait","lemma":"parfait","pos":"ADJ"} ,
		{"word":"parfaite","word_nosc":"parfaite","lemma":"parfait","pos":"ADJ"} ,
		{"word":"parfaites","word_nosc":"parfaites","lemma":"parfait","pos":"ADJ"} ,
		{"word":"parfaits","word_nosc":"parfaits","lemma":"parfait","pos":"ADJ"} ,
		{"word":"parfumé","word_nosc":"parfume","lemma":"parfumé","pos":"ADJ"} ,
		{"word":"parfumée","word_nosc":"parfumee","lemma":"parfumé","pos":"ADJ"} ,
		{"word":"parfumées","word_nosc":"parfumees","lemma":"parfumé","pos":"ADJ"} ,
		{"word":"parfumés","word_nosc":"parfumes","lemma":"parfumé","pos":"ADJ"} ,
		{"word":"parigot","word_nosc":"parigot","lemma":"parigot","pos":"ADJ"} ,
		{"word":"parigote","word_nosc":"parigote","lemma":"parigot","pos":"ADJ"} ,
		{"word":"parigotes","word_nosc":"parigotes","lemma":"parigot","pos":"ADJ"} ,
		{"word":"parigots","word_nosc":"parigots","lemma":"parigot","pos":"ADJ"} ,
		{"word":"parisien","word_nosc":"parisien","lemma":"parisien","pos":"ADJ"} ,
		{"word":"parisienne","word_nosc":"parisienne","lemma":"parisien","pos":"ADJ"} ,
		{"word":"parisiennes","word_nosc":"parisiennes","lemma":"parisien","pos":"ADJ"} ,
		{"word":"parisiens","word_nosc":"parisiens","lemma":"parisien","pos":"ADJ"} ,
		{"word":"paritaire","word_nosc":"paritaire","lemma":"paritaire","pos":"ADJ"} ,
		{"word":"pariétal","word_nosc":"parietal","lemma":"pariétal","pos":"ADJ"} ,
		{"word":"pariétale","word_nosc":"parietale","lemma":"pariétal","pos":"ADJ"} ,
		{"word":"pariétaux","word_nosc":"parietaux","lemma":"pariétal","pos":"ADJ"} ,
		{"word":"parkinsonien","word_nosc":"parkinsonien","lemma":"parkinsonien","pos":"ADJ"} ,
		{"word":"parkinsonienne","word_nosc":"parkinsonienne","lemma":"parkinsonien","pos":"ADJ"} ,
		{"word":"parkinsoniens","word_nosc":"parkinsoniens","lemma":"parkinsonien","pos":"ADJ"} ,
		{"word":"parlant","word_nosc":"parlant","lemma":"parlant","pos":"ADJ"} ,
		{"word":"parlante","word_nosc":"parlante","lemma":"parlant","pos":"ADJ"} ,
		{"word":"parlantes","word_nosc":"parlantes","lemma":"parlant","pos":"ADJ"} ,
		{"word":"parlants","word_nosc":"parlants","lemma":"parlant","pos":"ADJ"} ,
		{"word":"parlementaire","word_nosc":"parlementaire","lemma":"parlementaire","pos":"ADJ"} ,
		{"word":"parlementaires","word_nosc":"parlementaires","lemma":"parlementaire","pos":"ADJ"} ,
		{"word":"parlementariste","word_nosc":"parlementariste","lemma":"parlementariste","pos":"ADJ"} ,
		{"word":"parler","word_nosc":"parler","lemma":"parler","pos":"ADJ"} ,
		{"word":"parlé","word_nosc":"parle","lemma":"parlé","pos":"ADJ"} ,
		{"word":"parlée","word_nosc":"parlee","lemma":"parlé","pos":"ADJ"} ,
		{"word":"parlées","word_nosc":"parlees","lemma":"parlé","pos":"ADJ"} ,
		{"word":"parlés","word_nosc":"parles","lemma":"parlé","pos":"ADJ"} ,
		{"word":"parme","word_nosc":"parme","lemma":"parme","pos":"ADJ"} ,
		{"word":"parmesan","word_nosc":"parmesan","lemma":"parmesan","pos":"ADJ"} ,
		{"word":"parmesane","word_nosc":"parmesane","lemma":"parmesan","pos":"ADJ"} ,
		{"word":"parménidien","word_nosc":"parmenidien","lemma":"parménidien","pos":"ADJ"} ,
		{"word":"parnassienne","word_nosc":"parnassienne","lemma":"parnassien","pos":"ADJ"} ,
		{"word":"parodique","word_nosc":"parodique","lemma":"parodique","pos":"ADJ"} ,
		{"word":"parodiques","word_nosc":"parodiques","lemma":"parodique","pos":"ADJ"} ,
		{"word":"parodontal","word_nosc":"parodontal","lemma":"parodontal","pos":"ADJ"} ,
		{"word":"parodontale","word_nosc":"parodontale","lemma":"parodontal","pos":"ADJ"} ,
		{"word":"paroissial","word_nosc":"paroissial","lemma":"paroissial","pos":"ADJ"} ,
		{"word":"paroissiale","word_nosc":"paroissiale","lemma":"paroissial","pos":"ADJ"} ,
		{"word":"paroissiales","word_nosc":"paroissiales","lemma":"paroissial","pos":"ADJ"} ,
		{"word":"paroissiaux","word_nosc":"paroissiaux","lemma":"paroissial","pos":"ADJ"} ,
		{"word":"parotidienne","word_nosc":"parotidienne","lemma":"parotidien","pos":"ADJ"} ,
		{"word":"paroxysmique","word_nosc":"paroxysmique","lemma":"paroxysmique","pos":"ADJ"} ,
		{"word":"paroxysmiques","word_nosc":"paroxysmiques","lemma":"paroxysmique","pos":"ADJ"} ,
		{"word":"paroxystique","word_nosc":"paroxystique","lemma":"paroxystique","pos":"ADJ"} ,
		{"word":"paroxystiques","word_nosc":"paroxystiques","lemma":"paroxystique","pos":"ADJ"} ,
		{"word":"parquée","word_nosc":"parquee","lemma":"parqué","pos":"ADJ"} ,
		{"word":"parqués","word_nosc":"parques","lemma":"parqué","pos":"ADJ"} ,
		{"word":"parricide","word_nosc":"parricide","lemma":"parricide","pos":"ADJ"} ,
		{"word":"parricides","word_nosc":"parricides","lemma":"parricide","pos":"ADJ"} ,
		{"word":"parsemé","word_nosc":"parseme","lemma":"parsemé","pos":"ADJ"} ,
		{"word":"parsemée","word_nosc":"parsemee","lemma":"parsemé","pos":"ADJ"} ,
		{"word":"parsemées","word_nosc":"parsemees","lemma":"parsemé","pos":"ADJ"} ,
		{"word":"parsi","word_nosc":"parsi","lemma":"parsi","pos":"ADJ"} ,
		{"word":"partageable","word_nosc":"partageable","lemma":"partageable","pos":"ADJ"} ,
		{"word":"partageant","word_nosc":"partageant","lemma":"partageant","pos":"ADJ"} ,
		{"word":"partageur","word_nosc":"partageur","lemma":"partageur","pos":"ADJ"} ,
		{"word":"partageurs","word_nosc":"partageurs","lemma":"partageur","pos":"ADJ"} ,
		{"word":"partageuse","word_nosc":"partageuse","lemma":"partageux","pos":"ADJ"} ,
		{"word":"partagé","word_nosc":"partage","lemma":"partagé","pos":"ADJ"} ,
		{"word":"partagée","word_nosc":"partagee","lemma":"partagé","pos":"ADJ"} ,
		{"word":"partagées","word_nosc":"partagees","lemma":"partagé","pos":"ADJ"} ,
		{"word":"partagés","word_nosc":"partages","lemma":"partagé","pos":"ADJ"} ,
		{"word":"partant","word_nosc":"partant","lemma":"partant","pos":"ADJ"} ,
		{"word":"partante","word_nosc":"partante","lemma":"partant","pos":"ADJ"} ,
		{"word":"partantes","word_nosc":"partantes","lemma":"partant","pos":"ADJ"} ,
		{"word":"partants","word_nosc":"partants","lemma":"partant","pos":"ADJ"} ,
		{"word":"parthe","word_nosc":"parthe","lemma":"parthe","pos":"ADJ"} ,
		{"word":"parthes","word_nosc":"parthes","lemma":"parthe","pos":"ADJ"} ,
		{"word":"parthique","word_nosc":"parthique","lemma":"parthique","pos":"ADJ"} ,
		{"word":"parthénogénétique","word_nosc":"parthenogenetique","lemma":"parthénogénétique","pos":"ADJ"} ,
		{"word":"parti","word_nosc":"parti","lemma":"parti","pos":"ADJ"} ,
		{"word":"partial","word_nosc":"partial","lemma":"partial","pos":"ADJ"} ,
		{"word":"partiale","word_nosc":"partiale","lemma":"partial","pos":"ADJ"} ,
		{"word":"partiales","word_nosc":"partiales","lemma":"partial","pos":"ADJ"} ,
		{"word":"partiaux","word_nosc":"partiaux","lemma":"partial","pos":"ADJ"} ,
		{"word":"participant","word_nosc":"participant","lemma":"participant","pos":"ADJ"} ,
		{"word":"participante","word_nosc":"participante","lemma":"participant","pos":"ADJ"} ,
		{"word":"participantes","word_nosc":"participantes","lemma":"participant","pos":"ADJ"} ,
		{"word":"participants","word_nosc":"participants","lemma":"participant","pos":"ADJ"} ,
		{"word":"participative","word_nosc":"participative","lemma":"participatif","pos":"ADJ"} ,
		{"word":"particulier","word_nosc":"particulier","lemma":"particulier","pos":"ADJ"} ,
		{"word":"particuliers","word_nosc":"particuliers","lemma":"particulier","pos":"ADJ"} ,
		{"word":"particulière","word_nosc":"particuliere","lemma":"particulier","pos":"ADJ"} ,
		{"word":"particulières","word_nosc":"particulieres","lemma":"particulier","pos":"ADJ"} ,
		{"word":"partiel","word_nosc":"partiel","lemma":"partiel","pos":"ADJ"} ,
		{"word":"partielle","word_nosc":"partielle","lemma":"partiel","pos":"ADJ"} ,
		{"word":"partielles","word_nosc":"partielles","lemma":"partiel","pos":"ADJ"} ,
		{"word":"partiels","word_nosc":"partiels","lemma":"partiel","pos":"ADJ"} ,
		{"word":"partis","word_nosc":"partis","lemma":"parti","pos":"ADJ"} ,
		{"word":"partisan","word_nosc":"partisan","lemma":"partisan","pos":"ADJ"} ,
		{"word":"partisane","word_nosc":"partisane","lemma":"partisan","pos":"ADJ"} ,
		{"word":"partisanes","word_nosc":"partisanes","lemma":"partisan","pos":"ADJ"} ,
		{"word":"partisans","word_nosc":"partisans","lemma":"partisan","pos":"ADJ"} ,
		{"word":"partitif","word_nosc":"partitif","lemma":"partitif","pos":"ADJ"} ,
		{"word":"partouzarde","word_nosc":"partouzarde","lemma":"partouzard","pos":"ADJ"} ,
		{"word":"partouzardes","word_nosc":"partouzardes","lemma":"partouzard","pos":"ADJ"} ,
		{"word":"partouzards","word_nosc":"partouzards","lemma":"partouzard","pos":"ADJ"} ,
		{"word":"parvenu","word_nosc":"parvenu","lemma":"parvenu","pos":"ADJ"} ,
		{"word":"parvenue","word_nosc":"parvenue","lemma":"parvenu","pos":"ADJ"} ,
		{"word":"parvenues","word_nosc":"parvenues","lemma":"parvenu","pos":"ADJ"} ,
		{"word":"parvenus","word_nosc":"parvenus","lemma":"parvenu","pos":"ADJ"} ,
		{"word":"paré","word_nosc":"pare","lemma":"paré","pos":"ADJ"} ,
		{"word":"parée","word_nosc":"paree","lemma":"paré","pos":"ADJ"} ,
		{"word":"parées","word_nosc":"parees","lemma":"paré","pos":"ADJ"} ,
		{"word":"parégorique","word_nosc":"paregorique","lemma":"parégorique","pos":"ADJ"} ,
		{"word":"parés","word_nosc":"pares","lemma":"paré","pos":"ADJ"} ,
		{"word":"pascal","word_nosc":"pascal","lemma":"pascal","pos":"ADJ"} ,
		{"word":"pascale","word_nosc":"pascale","lemma":"pascal","pos":"ADJ"} ,
		{"word":"pascales","word_nosc":"pascales","lemma":"pascal","pos":"ADJ"} ,
		{"word":"pascalien","word_nosc":"pascalien","lemma":"pascalien","pos":"ADJ"} ,
		{"word":"pascaliennes","word_nosc":"pascaliennes","lemma":"pascalien","pos":"ADJ"} ,
		{"word":"pascaliens","word_nosc":"pascaliens","lemma":"pascalien","pos":"ADJ"} ,
		{"word":"passable","word_nosc":"passable","lemma":"passable","pos":"ADJ"} ,
		{"word":"passables","word_nosc":"passables","lemma":"passable","pos":"ADJ"} ,
		{"word":"passager","word_nosc":"passager","lemma":"passager","pos":"ADJ"} ,
		{"word":"passagers","word_nosc":"passagers","lemma":"passager","pos":"ADJ"} ,
		{"word":"passagère","word_nosc":"passagere","lemma":"passager","pos":"ADJ"} ,
		{"word":"passagères","word_nosc":"passageres","lemma":"passager","pos":"ADJ"} ,
		{"word":"passant","word_nosc":"passant","lemma":"passant","pos":"ADJ"} ,
		{"word":"passante","word_nosc":"passante","lemma":"passant","pos":"ADJ"} ,
		{"word":"passantes","word_nosc":"passantes","lemma":"passant","pos":"ADJ"} ,
		{"word":"passants","word_nosc":"passants","lemma":"passant","pos":"ADJ"} ,
		{"word":"passible","word_nosc":"passible","lemma":"passible","pos":"ADJ"} ,
		{"word":"passibles","word_nosc":"passibles","lemma":"passible","pos":"ADJ"} ,
		{"word":"passif","word_nosc":"passif","lemma":"passif","pos":"ADJ"} ,
		{"word":"passifs","word_nosc":"passifs","lemma":"passif","pos":"ADJ"} ,
		{"word":"passionnant","word_nosc":"passionnant","lemma":"passionnant","pos":"ADJ"} ,
		{"word":"passionnante","word_nosc":"passionnante","lemma":"passionnant","pos":"ADJ"} ,
		{"word":"passionnantes","word_nosc":"passionnantes","lemma":"passionnant","pos":"ADJ"} ,
		{"word":"passionnants","word_nosc":"passionnants","lemma":"passionnant","pos":"ADJ"} ,
		{"word":"passionnel","word_nosc":"passionnel","lemma":"passionnel","pos":"ADJ"} ,
		{"word":"passionnelle","word_nosc":"passionnelle","lemma":"passionnel","pos":"ADJ"} ,
		{"word":"passionnelles","word_nosc":"passionnelles","lemma":"passionnel","pos":"ADJ"} ,
		{"word":"passionnels","word_nosc":"passionnels","lemma":"passionnel","pos":"ADJ"} ,
		{"word":"passionné","word_nosc":"passionne","lemma":"passionné","pos":"ADJ"} ,
		{"word":"passionnée","word_nosc":"passionnee","lemma":"passionné","pos":"ADJ"} ,
		{"word":"passionnées","word_nosc":"passionnees","lemma":"passionné","pos":"ADJ"} ,
		{"word":"passionnés","word_nosc":"passionnes","lemma":"passionné","pos":"ADJ"} ,
		{"word":"passive","word_nosc":"passive","lemma":"passif","pos":"ADJ"} ,
		{"word":"passives","word_nosc":"passives","lemma":"passif","pos":"ADJ"} ,
		{"word":"passé","word_nosc":"passe","lemma":"passé","pos":"ADJ"} ,
		{"word":"passée","word_nosc":"passee","lemma":"passé","pos":"ADJ"} ,
		{"word":"passées","word_nosc":"passees","lemma":"passé","pos":"ADJ"} ,
		{"word":"passéiste","word_nosc":"passeiste","lemma":"passéiste","pos":"ADJ"} ,
		{"word":"passés","word_nosc":"passes","lemma":"passé","pos":"ADJ"} ,
		{"word":"pastel","word_nosc":"pastel","lemma":"pastel","pos":"ADJ"} ,
		{"word":"pasteurisé","word_nosc":"pasteurise","lemma":"pasteurisé","pos":"ADJ"} ,
		{"word":"pasteurisée","word_nosc":"pasteurisee","lemma":"pasteurisé","pos":"ADJ"} ,
		{"word":"pasteurisés","word_nosc":"pasteurises","lemma":"pasteurisé","pos":"ADJ"} ,
		{"word":"pastoral","word_nosc":"pastoral","lemma":"pastoral","pos":"ADJ"} ,
		{"word":"pastorale","word_nosc":"pastorale","lemma":"pastoral","pos":"ADJ"} ,
		{"word":"pastorales","word_nosc":"pastorales","lemma":"pastoral","pos":"ADJ"} ,
		{"word":"pastoraux","word_nosc":"pastoraux","lemma":"pastoral","pos":"ADJ"} ,
		{"word":"pat","word_nosc":"pat","lemma":"pat","pos":"ADJ"} ,
		{"word":"patagon","word_nosc":"patagon","lemma":"patagon","pos":"ADJ"} ,
		{"word":"patagonien","word_nosc":"patagonien","lemma":"patagonien","pos":"ADJ"} ,
		{"word":"pataud","word_nosc":"pataud","lemma":"pataud","pos":"ADJ"} ,
		{"word":"pataude","word_nosc":"pataude","lemma":"pataud","pos":"ADJ"} ,
		{"word":"pataudes","word_nosc":"pataudes","lemma":"pataud","pos":"ADJ"} ,
		{"word":"patauds","word_nosc":"patauds","lemma":"pataud","pos":"ADJ"} ,
		{"word":"patelin","word_nosc":"patelin","lemma":"patelin","pos":"ADJ"} ,
		{"word":"pateline","word_nosc":"pateline","lemma":"patelin","pos":"ADJ"} ,
		{"word":"patelins","word_nosc":"patelins","lemma":"patelin","pos":"ADJ"} ,
		{"word":"patellaire","word_nosc":"patellaire","lemma":"patellaire","pos":"ADJ"} ,
		{"word":"patent","word_nosc":"patent","lemma":"patent","pos":"ADJ"} ,
		{"word":"patente","word_nosc":"patente","lemma":"patent","pos":"ADJ"} ,
		{"word":"patentes","word_nosc":"patentes","lemma":"patent","pos":"ADJ"} ,
		{"word":"patents","word_nosc":"patents","lemma":"patent","pos":"ADJ"} ,
		{"word":"patenté","word_nosc":"patente","lemma":"patenté","pos":"ADJ"} ,
		{"word":"patentée","word_nosc":"patentee","lemma":"patenté","pos":"ADJ"} ,
		{"word":"patentées","word_nosc":"patentees","lemma":"patenté","pos":"ADJ"} ,
		{"word":"patentés","word_nosc":"patentes","lemma":"patenté","pos":"ADJ"} ,
		{"word":"paternaliste","word_nosc":"paternaliste","lemma":"paternaliste","pos":"ADJ"} ,
		{"word":"paternalistes","word_nosc":"paternalistes","lemma":"paternaliste","pos":"ADJ"} ,
		{"word":"paterne","word_nosc":"paterne","lemma":"paterne","pos":"ADJ"} ,
		{"word":"paternel","word_nosc":"paternel","lemma":"paternel","pos":"ADJ"} ,
		{"word":"paternelle","word_nosc":"paternelle","lemma":"paternel","pos":"ADJ"} ,
		{"word":"paternelles","word_nosc":"paternelles","lemma":"paternel","pos":"ADJ"} ,
		{"word":"paternels","word_nosc":"paternels","lemma":"paternel","pos":"ADJ"} ,
		{"word":"pathogène","word_nosc":"pathogene","lemma":"pathogène","pos":"ADJ"} ,
		{"word":"pathogénique","word_nosc":"pathogenique","lemma":"pathogénique","pos":"ADJ"} ,
		{"word":"pathologique","word_nosc":"pathologique","lemma":"pathologique","pos":"ADJ"} ,
		{"word":"pathologiques","word_nosc":"pathologiques","lemma":"pathologique","pos":"ADJ"} ,
		{"word":"pathétique","word_nosc":"pathetique","lemma":"pathétique","pos":"ADJ"} ,
		{"word":"pathétiques","word_nosc":"pathetiques","lemma":"pathétique","pos":"ADJ"} ,
		{"word":"patibulaire","word_nosc":"patibulaire","lemma":"patibulaire","pos":"ADJ"} ,
		{"word":"patibulaires","word_nosc":"patibulaires","lemma":"patibulaire","pos":"ADJ"} ,
		{"word":"patient","word_nosc":"patient","lemma":"patient","pos":"ADJ"} ,
		{"word":"patiente","word_nosc":"patiente","lemma":"patient","pos":"ADJ"} ,
		{"word":"patientes","word_nosc":"patientes","lemma":"patient","pos":"ADJ"} ,
		{"word":"patients","word_nosc":"patients","lemma":"patient","pos":"ADJ"} ,
		{"word":"patoisante","word_nosc":"patoisante","lemma":"patoisant","pos":"ADJ"} ,
		{"word":"patraque","word_nosc":"patraque","lemma":"patraque","pos":"ADJ"} ,
		{"word":"patriarcal","word_nosc":"patriarcal","lemma":"patriarcal","pos":"ADJ"} ,
		{"word":"patriarcale","word_nosc":"patriarcale","lemma":"patriarcal","pos":"ADJ"} ,
		{"word":"patriarcales","word_nosc":"patriarcales","lemma":"patriarcal","pos":"ADJ"} ,
		{"word":"patriarcaux","word_nosc":"patriarcaux","lemma":"patriarcal","pos":"ADJ"} ,
		{"word":"patricien","word_nosc":"patricien","lemma":"patricien","pos":"ADJ"} ,
		{"word":"patricienne","word_nosc":"patricienne","lemma":"patricien","pos":"ADJ"} ,
		{"word":"patriciennes","word_nosc":"patriciennes","lemma":"patricien","pos":"ADJ"} ,
		{"word":"patriciens","word_nosc":"patriciens","lemma":"patricien","pos":"ADJ"} ,
		{"word":"patrilinéaire","word_nosc":"patrilineaire","lemma":"patrilinéaire","pos":"ADJ"} ,
		{"word":"patrimonial","word_nosc":"patrimonial","lemma":"patrimonial","pos":"ADJ"} ,
		{"word":"patriotard","word_nosc":"patriotard","lemma":"patriotard","pos":"ADJ"} ,
		{"word":"patriotardes","word_nosc":"patriotardes","lemma":"patriotard","pos":"ADJ"} ,
		{"word":"patriote","word_nosc":"patriote","lemma":"patriote","pos":"ADJ"} ,
		{"word":"patriotes","word_nosc":"patriotes","lemma":"patriote","pos":"ADJ"} ,
		{"word":"patriotique","word_nosc":"patriotique","lemma":"patriotique","pos":"ADJ"} ,
		{"word":"patriotiques","word_nosc":"patriotiques","lemma":"patriotique","pos":"ADJ"} ,
		{"word":"patronal","word_nosc":"patronal","lemma":"patronal","pos":"ADJ"} ,
		{"word":"patronale","word_nosc":"patronale","lemma":"patronal","pos":"ADJ"} ,
		{"word":"patronales","word_nosc":"patronales","lemma":"patronal","pos":"ADJ"} ,
		{"word":"patronaux","word_nosc":"patronaux","lemma":"patronal","pos":"ADJ"} ,
		{"word":"patronnesse","word_nosc":"patronnesse","lemma":"patronnesse","pos":"ADJ"} ,
		{"word":"patronnesses","word_nosc":"patronnesses","lemma":"patronnesse","pos":"ADJ"} ,
		{"word":"patronymique","word_nosc":"patronymique","lemma":"patronymique","pos":"ADJ"} ,
		{"word":"patronymiques","word_nosc":"patronymiques","lemma":"patronymique","pos":"ADJ"} ,
		{"word":"pattu","word_nosc":"pattu","lemma":"pattu","pos":"ADJ"} ,
		{"word":"pattus","word_nosc":"pattus","lemma":"pattu","pos":"ADJ"} ,
		{"word":"pattée","word_nosc":"pattee","lemma":"patté","pos":"ADJ"} ,
		{"word":"paulinienne","word_nosc":"paulinienne","lemma":"paulinien","pos":"ADJ"} ,
		{"word":"paumé","word_nosc":"paume","lemma":"paumé","pos":"ADJ"} ,
		{"word":"paumée","word_nosc":"paumee","lemma":"paumé","pos":"ADJ"} ,
		{"word":"paumées","word_nosc":"paumees","lemma":"paumé","pos":"ADJ"} ,
		{"word":"paumés","word_nosc":"paumes","lemma":"paumé","pos":"ADJ"} ,
		{"word":"pauvre","word_nosc":"pauvre","lemma":"pauvre","pos":"ADJ"} ,
		{"word":"pauvres","word_nosc":"pauvres","lemma":"pauvre","pos":"ADJ"} ,
		{"word":"pavillonnaire","word_nosc":"pavillonnaire","lemma":"pavillonnaire","pos":"ADJ"} ,
		{"word":"pavillonnaires","word_nosc":"pavillonnaires","lemma":"pavillonnaire","pos":"ADJ"} ,
		{"word":"pavlovien","word_nosc":"pavlovien","lemma":"pavlovien","pos":"ADJ"} ,
		{"word":"pavlovienne","word_nosc":"pavlovienne","lemma":"pavlovien","pos":"ADJ"} ,
		{"word":"pavé","word_nosc":"pave","lemma":"pavé","pos":"ADJ"} ,
		{"word":"pavée","word_nosc":"pavee","lemma":"pavé","pos":"ADJ"} ,
		{"word":"pavées","word_nosc":"pavees","lemma":"pavé","pos":"ADJ"} ,
		{"word":"pavés","word_nosc":"paves","lemma":"pavé","pos":"ADJ"} ,
		{"word":"payable","word_nosc":"payable","lemma":"payable","pos":"ADJ"} ,
		{"word":"payables","word_nosc":"payables","lemma":"payable","pos":"ADJ"} ,
		{"word":"payant","word_nosc":"payant","lemma":"payant","pos":"ADJ"} ,
		{"word":"payante","word_nosc":"payante","lemma":"payant","pos":"ADJ"} ,
		{"word":"payantes","word_nosc":"payantes","lemma":"payant","pos":"ADJ"} ,
		{"word":"payants","word_nosc":"payants","lemma":"payant","pos":"ADJ"} ,
		{"word":"payeur","word_nosc":"payeur","lemma":"payeur","pos":"ADJ"} ,
		{"word":"payeurs","word_nosc":"payeurs","lemma":"payeur","pos":"ADJ"} ,
		{"word":"paysager","word_nosc":"paysager","lemma":"paysager","pos":"ADJ"} ,
		{"word":"paysagé","word_nosc":"paysage","lemma":"paysagé","pos":"ADJ"} ,
		{"word":"paysagés","word_nosc":"paysages","lemma":"paysagé","pos":"ADJ"} ,
		{"word":"paysan","word_nosc":"paysan","lemma":"paysan","pos":"ADJ"} ,
		{"word":"paysanne","word_nosc":"paysanne","lemma":"paysan","pos":"ADJ"} ,
		{"word":"paysannes","word_nosc":"paysannes","lemma":"paysan","pos":"ADJ"} ,
		{"word":"paysans","word_nosc":"paysans","lemma":"paysan","pos":"ADJ"} ,
		{"word":"payé","word_nosc":"paye","lemma":"payé","pos":"ADJ"} ,
		{"word":"payée","word_nosc":"payee","lemma":"payé","pos":"ADJ"} ,
		{"word":"payées","word_nosc":"payees","lemma":"payé","pos":"ADJ"} ,
		{"word":"payés","word_nosc":"payes","lemma":"payé","pos":"ADJ"} ,
		{"word":"païen","word_nosc":"paien","lemma":"païen","pos":"ADJ"} ,
		{"word":"païenne","word_nosc":"paienne","lemma":"païen","pos":"ADJ"} ,
		{"word":"païennes","word_nosc":"paiennes","lemma":"païen","pos":"ADJ"} ,
		{"word":"païens","word_nosc":"paiens","lemma":"païen","pos":"ADJ"} ,
		{"word":"peau-rouge","word_nosc":"peau-rouge","lemma":"peau-rouge","pos":"ADJ"} ,
		{"word":"peaux-rouges","word_nosc":"peaux-rouges","lemma":"peau-rouge","pos":"ADJ"} ,
		{"word":"pec","word_nosc":"pec","lemma":"pec","pos":"ADJ"} ,
		{"word":"peccable","word_nosc":"peccable","lemma":"peccable","pos":"ADJ"} ,
		{"word":"pectoral","word_nosc":"pectoral","lemma":"pectoral","pos":"ADJ"} ,
		{"word":"pectorale","word_nosc":"pectorale","lemma":"pectoral","pos":"ADJ"} ,
		{"word":"pectorales","word_nosc":"pectorales","lemma":"pectoral","pos":"ADJ"} ,
		{"word":"pectoraux","word_nosc":"pectoraux","lemma":"pectoral","pos":"ADJ"} ,
		{"word":"peigné","word_nosc":"peigne","lemma":"peigné","pos":"ADJ"} ,
		{"word":"peignés","word_nosc":"peignes","lemma":"peigné","pos":"ADJ"} ,
		{"word":"peinard","word_nosc":"peinard","lemma":"peinard","pos":"ADJ"} ,
		{"word":"peinarde","word_nosc":"peinarde","lemma":"peinard","pos":"ADJ"} ,
		{"word":"peinardes","word_nosc":"peinardes","lemma":"peinard","pos":"ADJ"} ,
		{"word":"peinards","word_nosc":"peinards","lemma":"peinard","pos":"ADJ"} ,
		{"word":"peineuse","word_nosc":"peineuse","lemma":"peineuse","pos":"ADJ"} ,
		{"word":"peint","word_nosc":"peint","lemma":"peint","pos":"ADJ"} ,
		{"word":"peinte","word_nosc":"peinte","lemma":"peint","pos":"ADJ"} ,
		{"word":"peintes","word_nosc":"peintes","lemma":"peint","pos":"ADJ"} ,
		{"word":"peints","word_nosc":"peints","lemma":"peint","pos":"ADJ"} ,
		{"word":"pelliculaire","word_nosc":"pelliculaire","lemma":"pelliculaire","pos":"ADJ"} ,
		{"word":"peloteurs","word_nosc":"peloteurs","lemma":"peloteur","pos":"ADJ"} ,
		{"word":"peloteuse","word_nosc":"peloteuse","lemma":"peloteur","pos":"ADJ"} ,
		{"word":"pelucheuse","word_nosc":"pelucheuse","lemma":"pelucheux","pos":"ADJ"} ,
		{"word":"pelucheuses","word_nosc":"pelucheuses","lemma":"pelucheux","pos":"ADJ"} ,
		{"word":"pelucheux","word_nosc":"pelucheux","lemma":"pelucheux","pos":"ADJ"} ,
		{"word":"peluchée","word_nosc":"peluchee","lemma":"peluché","pos":"ADJ"} ,
		{"word":"pelvien","word_nosc":"pelvien","lemma":"pelvien","pos":"ADJ"} ,
		{"word":"pelvienne","word_nosc":"pelvienne","lemma":"pelvien","pos":"ADJ"} ,
		{"word":"pelé","word_nosc":"pele","lemma":"pelé","pos":"ADJ"} ,
		{"word":"pelée","word_nosc":"pelee","lemma":"pelé","pos":"ADJ"} ,
		{"word":"pelées","word_nosc":"pelees","lemma":"pelé","pos":"ADJ"} ,
		{"word":"pelés","word_nosc":"peles","lemma":"pelé","pos":"ADJ"} ,
		{"word":"penaud","word_nosc":"penaud","lemma":"penaud","pos":"ADJ"} ,
		{"word":"penaude","word_nosc":"penaude","lemma":"penaud","pos":"ADJ"} ,
		{"word":"penaudes","word_nosc":"penaudes","lemma":"penaud","pos":"ADJ"} ,
		{"word":"penauds","word_nosc":"penauds","lemma":"penaud","pos":"ADJ"} ,
		{"word":"penchant","word_nosc":"penchant","lemma":"penchant","pos":"ADJ"} ,
		{"word":"penchante","word_nosc":"penchante","lemma":"penchant","pos":"ADJ"} ,
		{"word":"penchants","word_nosc":"penchants","lemma":"penchant","pos":"ADJ"} ,
		{"word":"penché","word_nosc":"penche","lemma":"penché","pos":"ADJ"} ,
		{"word":"penchée","word_nosc":"penchee","lemma":"penché","pos":"ADJ"} ,
		{"word":"penchées","word_nosc":"penchees","lemma":"penché","pos":"ADJ"} ,
		{"word":"penchés","word_nosc":"penches","lemma":"penché","pos":"ADJ"} ,
		{"word":"pendable","word_nosc":"pendable","lemma":"pendable","pos":"ADJ"} ,
		{"word":"pendables","word_nosc":"pendables","lemma":"pendable","pos":"ADJ"} ,
		{"word":"pendant","word_nosc":"pendant","lemma":"pendant","pos":"ADJ"} ,
		{"word":"pendante","word_nosc":"pendante","lemma":"pendant","pos":"ADJ"} ,
		{"word":"pendantes","word_nosc":"pendantes","lemma":"pendant","pos":"ADJ"} ,
		{"word":"pendants","word_nosc":"pendants","lemma":"pendant","pos":"ADJ"} ,
		{"word":"pendu","word_nosc":"pendu","lemma":"pendu","pos":"ADJ"} ,
		{"word":"pendue","word_nosc":"pendue","lemma":"pendu","pos":"ADJ"} ,
		{"word":"pendues","word_nosc":"pendues","lemma":"pendu","pos":"ADJ"} ,
		{"word":"pendulaire","word_nosc":"pendulaire","lemma":"pendulaire","pos":"ADJ"} ,
		{"word":"pendus","word_nosc":"pendus","lemma":"pendu","pos":"ADJ"} ,
		{"word":"penny","word_nosc":"penny","lemma":"penny","pos":"ADJ"} ,
		{"word":"pennées","word_nosc":"pennees","lemma":"penné","pos":"ADJ"} ,
		{"word":"pensable","word_nosc":"pensable","lemma":"pensable","pos":"ADJ"} ,
		{"word":"pensant","word_nosc":"pensant","lemma":"pensant","pos":"ADJ"} ,
		{"word":"pensante","word_nosc":"pensante","lemma":"pensant","pos":"ADJ"} ,
		{"word":"pensantes","word_nosc":"pensantes","lemma":"pensant","pos":"ADJ"} ,
		{"word":"pensants","word_nosc":"pensants","lemma":"pensant","pos":"ADJ"} ,
		{"word":"pensif","word_nosc":"pensif","lemma":"pensif","pos":"ADJ"} ,
		{"word":"pensifs","word_nosc":"pensifs","lemma":"pensif","pos":"ADJ"} ,
		{"word":"pensionné","word_nosc":"pensionne","lemma":"pensionné","pos":"ADJ"} ,
		{"word":"pensionnés","word_nosc":"pensionnes","lemma":"pensionné","pos":"ADJ"} ,
		{"word":"pensive","word_nosc":"pensive","lemma":"pensif","pos":"ADJ"} ,
		{"word":"pentagonal","word_nosc":"pentagonal","lemma":"pentagonal","pos":"ADJ"} ,
		{"word":"pentu","word_nosc":"pentu","lemma":"pentu","pos":"ADJ"} ,
		{"word":"pentue","word_nosc":"pentue","lemma":"pentu","pos":"ADJ"} ,
		{"word":"pentues","word_nosc":"pentues","lemma":"pentu","pos":"ADJ"} ,
		{"word":"pentus","word_nosc":"pentus","lemma":"pentu","pos":"ADJ"} ,
		{"word":"pentélique","word_nosc":"pentelique","lemma":"pentélique","pos":"ADJ"} ,
		{"word":"people","word_nosc":"people","lemma":"people","pos":"ADJ"} ,
		{"word":"peptique","word_nosc":"peptique","lemma":"peptique","pos":"ADJ"} ,
		{"word":"percepteur","word_nosc":"percepteur","lemma":"percepteur","pos":"ADJ"} ,
		{"word":"percepteurs","word_nosc":"percepteurs","lemma":"percepteur","pos":"ADJ"} ,
		{"word":"perceptible","word_nosc":"perceptible","lemma":"perceptible","pos":"ADJ"} ,
		{"word":"perceptibles","word_nosc":"perceptibles","lemma":"perceptible","pos":"ADJ"} ,
		{"word":"perceptif","word_nosc":"perceptif","lemma":"perceptif","pos":"ADJ"} ,
		{"word":"perceptive","word_nosc":"perceptive","lemma":"perceptif","pos":"ADJ"} ,
		{"word":"perceptives","word_nosc":"perceptives","lemma":"perceptif","pos":"ADJ"} ,
		{"word":"perceptuels","word_nosc":"perceptuels","lemma":"perceptuel","pos":"ADJ"} ,
		{"word":"percevant","word_nosc":"percevant","lemma":"percevant","pos":"ADJ"} ,
		{"word":"percheron","word_nosc":"percheron","lemma":"percheron","pos":"ADJ"} ,
		{"word":"percheronne","word_nosc":"percheronne","lemma":"percheron","pos":"ADJ"} ,
		{"word":"percherons","word_nosc":"percherons","lemma":"percheron","pos":"ADJ"} ,
		{"word":"percheurs","word_nosc":"percheurs","lemma":"percheur","pos":"ADJ"} ,
		{"word":"perchlorique","word_nosc":"perchlorique","lemma":"perchlorique","pos":"ADJ"} ,
		{"word":"perclus","word_nosc":"perclus","lemma":"perclus","pos":"ADJ"} ,
		{"word":"percluse","word_nosc":"percluse","lemma":"perclus","pos":"ADJ"} ,
		{"word":"percluses","word_nosc":"percluses","lemma":"perclus","pos":"ADJ"} ,
		{"word":"percutant","word_nosc":"percutant","lemma":"percutant","pos":"ADJ"} ,
		{"word":"percutante","word_nosc":"percutante","lemma":"percutant","pos":"ADJ"} ,
		{"word":"percutantes","word_nosc":"percutantes","lemma":"percutant","pos":"ADJ"} ,
		{"word":"percutants","word_nosc":"percutants","lemma":"percutant","pos":"ADJ"} ,
		{"word":"perdant","word_nosc":"perdant","lemma":"perdant","pos":"ADJ"} ,
		{"word":"perdante","word_nosc":"perdante","lemma":"perdant","pos":"ADJ"} ,
		{"word":"perdants","word_nosc":"perdants","lemma":"perdant","pos":"ADJ"} ,
		{"word":"perdu","word_nosc":"perdu","lemma":"perdu","pos":"ADJ"} ,
		{"word":"perdue","word_nosc":"perdue","lemma":"perdu","pos":"ADJ"} ,
		{"word":"perdues","word_nosc":"perdues","lemma":"perdu","pos":"ADJ"} ,
		{"word":"perdurable","word_nosc":"perdurable","lemma":"perdurable","pos":"ADJ"} ,
		{"word":"perdus","word_nosc":"perdus","lemma":"perdu","pos":"ADJ"} ,
		{"word":"perfectible","word_nosc":"perfectible","lemma":"perfectible","pos":"ADJ"} ,
		{"word":"perfectibles","word_nosc":"perfectibles","lemma":"perfectible","pos":"ADJ"} ,
		{"word":"perfectionniste","word_nosc":"perfectionniste","lemma":"perfectionniste","pos":"ADJ"} ,
		{"word":"perfectionnistes","word_nosc":"perfectionnistes","lemma":"perfectionniste","pos":"ADJ"} ,
		{"word":"perfide","word_nosc":"perfide","lemma":"perfide","pos":"ADJ"} ,
		{"word":"perfides","word_nosc":"perfides","lemma":"perfide","pos":"ADJ"} ,
		{"word":"perforant","word_nosc":"perforant","lemma":"perforant","pos":"ADJ"} ,
		{"word":"perforante","word_nosc":"perforante","lemma":"perforant","pos":"ADJ"} ,
		{"word":"perforantes","word_nosc":"perforantes","lemma":"perforant","pos":"ADJ"} ,
		{"word":"perforants","word_nosc":"perforants","lemma":"perforant","pos":"ADJ"} ,
		{"word":"performant","word_nosc":"performant","lemma":"performant","pos":"ADJ"} ,
		{"word":"performante","word_nosc":"performante","lemma":"performant","pos":"ADJ"} ,
		{"word":"performantes","word_nosc":"performantes","lemma":"performant","pos":"ADJ"} ,
		{"word":"performants","word_nosc":"performants","lemma":"performant","pos":"ADJ"} ,
		{"word":"perforé","word_nosc":"perfore","lemma":"perforé","pos":"ADJ"} ,
		{"word":"perforée","word_nosc":"perforee","lemma":"perforé","pos":"ADJ"} ,
		{"word":"perforées","word_nosc":"perforees","lemma":"perforé","pos":"ADJ"} ,
		{"word":"perforés","word_nosc":"perfores","lemma":"perforé","pos":"ADJ"} ,
		{"word":"perlière","word_nosc":"perliere","lemma":"perlier","pos":"ADJ"} ,
		{"word":"perlière","word_nosc":"perliere","lemma":"perlière","pos":"ADJ"} ,
		{"word":"perlières","word_nosc":"perlieres","lemma":"perlier","pos":"ADJ"} ,
		{"word":"perlé","word_nosc":"perle","lemma":"perlé","pos":"ADJ"} ,
		{"word":"perlée","word_nosc":"perlee","lemma":"perlé","pos":"ADJ"} ,
		{"word":"perlées","word_nosc":"perlees","lemma":"perlé","pos":"ADJ"} ,
		{"word":"perlés","word_nosc":"perles","lemma":"perlé","pos":"ADJ"} ,
		{"word":"permanent","word_nosc":"permanent","lemma":"permanent","pos":"ADJ"} ,
		{"word":"permanente","word_nosc":"permanente","lemma":"permanent","pos":"ADJ"} ,
		{"word":"permanentes","word_nosc":"permanentes","lemma":"permanent","pos":"ADJ"} ,
		{"word":"permanents","word_nosc":"permanents","lemma":"permanent","pos":"ADJ"} ,
		{"word":"permis","word_nosc":"permis","lemma":"permis","pos":"ADJ"} ,
		{"word":"permissifs","word_nosc":"permissifs","lemma":"permissif","pos":"ADJ"} ,
		{"word":"permissive","word_nosc":"permissive","lemma":"permissif","pos":"ADJ"} ,
		{"word":"permutant","word_nosc":"permutant","lemma":"permutant","pos":"ADJ"} ,
		{"word":"perméable","word_nosc":"permeable","lemma":"perméable","pos":"ADJ"} ,
		{"word":"perméables","word_nosc":"permeables","lemma":"perméable","pos":"ADJ"} ,
		{"word":"pernicieuse","word_nosc":"pernicieuse","lemma":"pernicieux","pos":"ADJ"} ,
		{"word":"pernicieuses","word_nosc":"pernicieuses","lemma":"pernicieux","pos":"ADJ"} ,
		{"word":"pernicieux","word_nosc":"pernicieux","lemma":"pernicieux","pos":"ADJ"} ,
		{"word":"perpendiculaire","word_nosc":"perpendiculaire","lemma":"perpendiculaire","pos":"ADJ"} ,
		{"word":"perpendiculaires","word_nosc":"perpendiculaires","lemma":"perpendiculaire","pos":"ADJ"} ,
		{"word":"perplexe","word_nosc":"perplexe","lemma":"perplexe","pos":"ADJ"} ,
		{"word":"perplexes","word_nosc":"perplexes","lemma":"perplexe","pos":"ADJ"} ,
		{"word":"perpétrés","word_nosc":"perpetres","lemma":"perpétré","pos":"ADJ"} ,
		{"word":"perpétuel","word_nosc":"perpetuel","lemma":"perpétuel","pos":"ADJ"} ,
		{"word":"perpétuelle","word_nosc":"perpetuelle","lemma":"perpétuel","pos":"ADJ"} ,
		{"word":"perpétuelles","word_nosc":"perpetuelles","lemma":"perpétuel","pos":"ADJ"} ,
		{"word":"perpétuels","word_nosc":"perpetuels","lemma":"perpétuel","pos":"ADJ"} ,
		{"word":"perpétué","word_nosc":"perpetue","lemma":"perpétué","pos":"ADJ"} ,
		{"word":"perpétuée","word_nosc":"perpetuee","lemma":"perpétué","pos":"ADJ"} ,
		{"word":"pers","word_nosc":"pers","lemma":"pers","pos":"ADJ"} ,
		{"word":"persan","word_nosc":"persan","lemma":"persan","pos":"ADJ"} ,
		{"word":"persane","word_nosc":"persane","lemma":"persan","pos":"ADJ"} ,
		{"word":"persanes","word_nosc":"persanes","lemma":"persan","pos":"ADJ"} ,
		{"word":"persans","word_nosc":"persans","lemma":"persan","pos":"ADJ"} ,
		{"word":"perse","word_nosc":"perse","lemma":"perse","pos":"ADJ"} ,
		{"word":"perses","word_nosc":"perses","lemma":"perse","pos":"ADJ"} ,
		{"word":"persifleur","word_nosc":"persifleur","lemma":"persifleur","pos":"ADJ"} ,
		{"word":"persifleurs","word_nosc":"persifleurs","lemma":"persifleur","pos":"ADJ"} ,
		{"word":"persifleuse","word_nosc":"persifleuse","lemma":"persifleur","pos":"ADJ"} ,
		{"word":"persiflé","word_nosc":"persifle","lemma":"persiflé","pos":"ADJ"} ,
		{"word":"persillé","word_nosc":"persille","lemma":"persillé","pos":"ADJ"} ,
		{"word":"persillée","word_nosc":"persillee","lemma":"persillé","pos":"ADJ"} ,
		{"word":"persillées","word_nosc":"persillees","lemma":"persillé","pos":"ADJ"} ,
		{"word":"persique","word_nosc":"persique","lemma":"persique","pos":"ADJ"} ,
		{"word":"persistant","word_nosc":"persistant","lemma":"persistant","pos":"ADJ"} ,
		{"word":"persistante","word_nosc":"persistante","lemma":"persistant","pos":"ADJ"} ,
		{"word":"persistantes","word_nosc":"persistantes","lemma":"persistant","pos":"ADJ"} ,
		{"word":"persistants","word_nosc":"persistants","lemma":"persistant","pos":"ADJ"} ,
		{"word":"perso","word_nosc":"perso","lemma":"perso","pos":"ADJ"} ,
		{"word":"persona grata","word_nosc":"persona grata","lemma":"persona grata","pos":"ADJ"} ,
		{"word":"personnalisé","word_nosc":"personnalise","lemma":"personnalisé","pos":"ADJ"} ,
		{"word":"personnalisée","word_nosc":"personnalisee","lemma":"personnalisé","pos":"ADJ"} ,
		{"word":"personnalisées","word_nosc":"personnalisees","lemma":"personnalisé","pos":"ADJ"} ,
		{"word":"personne","word_nosc":"personne","lemma":"personne","pos":"ADJ"} ,
		{"word":"personnel","word_nosc":"personnel","lemma":"personnel","pos":"ADJ"} ,
		{"word":"personnelle","word_nosc":"personnelle","lemma":"personnel","pos":"ADJ"} ,
		{"word":"personnelles","word_nosc":"personnelles","lemma":"personnel","pos":"ADJ"} ,
		{"word":"personnels","word_nosc":"personnels","lemma":"personnel","pos":"ADJ"} ,
		{"word":"personnes","word_nosc":"personnes","lemma":"personnes","pos":"ADJ"} ,
		{"word":"personnifié","word_nosc":"personnifie","lemma":"personnifié","pos":"ADJ"} ,
		{"word":"personnifiée","word_nosc":"personnifiee","lemma":"personnifié","pos":"ADJ"} ,
		{"word":"persos","word_nosc":"persos","lemma":"perso","pos":"ADJ"} ,
		{"word":"perspective","word_nosc":"perspective","lemma":"perspectif","pos":"ADJ"} ,
		{"word":"perspectives","word_nosc":"perspectives","lemma":"perspectif","pos":"ADJ"} ,
		{"word":"perspicace","word_nosc":"perspicace","lemma":"perspicace","pos":"ADJ"} ,
		{"word":"perspicaces","word_nosc":"perspicaces","lemma":"perspicace","pos":"ADJ"} ,
		{"word":"persuadé","word_nosc":"persuade","lemma":"persuadé","pos":"ADJ"} ,
		{"word":"persuadée","word_nosc":"persuadee","lemma":"persuadé","pos":"ADJ"} ,
		{"word":"persuadées","word_nosc":"persuadees","lemma":"persuadé","pos":"ADJ"} ,
		{"word":"persuadés","word_nosc":"persuades","lemma":"persuadé","pos":"ADJ"} ,
		{"word":"persuasif","word_nosc":"persuasif","lemma":"persuasif","pos":"ADJ"} ,
		{"word":"persuasifs","word_nosc":"persuasifs","lemma":"persuasif","pos":"ADJ"} ,
		{"word":"persuasive","word_nosc":"persuasive","lemma":"persuasif","pos":"ADJ"} ,
		{"word":"persuasives","word_nosc":"persuasives","lemma":"persuasif","pos":"ADJ"} ,
		{"word":"persécuteurs","word_nosc":"persecuteurs","lemma":"persécuteur","pos":"ADJ"} ,
		{"word":"persécuté","word_nosc":"persecute","lemma":"persécuté","pos":"ADJ"} ,
		{"word":"persécutée","word_nosc":"persecutee","lemma":"persécuté","pos":"ADJ"} ,
		{"word":"persécutées","word_nosc":"persecutees","lemma":"persécuté","pos":"ADJ"} ,
		{"word":"persécutés","word_nosc":"persecutes","lemma":"persécuté","pos":"ADJ"} ,
		{"word":"persévérant","word_nosc":"perseverant","lemma":"persévérant","pos":"ADJ"} ,
		{"word":"persévérante","word_nosc":"perseverante","lemma":"persévérant","pos":"ADJ"} ,
		{"word":"persévérantes","word_nosc":"perseverantes","lemma":"persévérant","pos":"ADJ"} ,
		{"word":"persévérants","word_nosc":"perseverants","lemma":"persévérant","pos":"ADJ"} ,
		{"word":"pertinent","word_nosc":"pertinent","lemma":"pertinent","pos":"ADJ"} ,
		{"word":"pertinente","word_nosc":"pertinente","lemma":"pertinent","pos":"ADJ"} ,
		{"word":"pertinentes","word_nosc":"pertinentes","lemma":"pertinent","pos":"ADJ"} ,
		{"word":"pertinents","word_nosc":"pertinents","lemma":"pertinent","pos":"ADJ"} ,
		{"word":"perturbant","word_nosc":"perturbant","lemma":"perturbant","pos":"ADJ"} ,
		{"word":"perturbante","word_nosc":"perturbante","lemma":"perturbant","pos":"ADJ"} ,
		{"word":"perturbants","word_nosc":"perturbants","lemma":"perturbant","pos":"ADJ"} ,
		{"word":"perturbateur","word_nosc":"perturbateur","lemma":"perturbateur","pos":"ADJ"} ,
		{"word":"perturbateurs","word_nosc":"perturbateurs","lemma":"perturbateur","pos":"ADJ"} ,
		{"word":"perturbatrice","word_nosc":"perturbatrice","lemma":"perturbateur","pos":"ADJ"} ,
		{"word":"perturbé","word_nosc":"perturbe","lemma":"perturbé","pos":"ADJ"} ,
		{"word":"perturbée","word_nosc":"perturbee","lemma":"perturbé","pos":"ADJ"} ,
		{"word":"perturbées","word_nosc":"perturbees","lemma":"perturbé","pos":"ADJ"} ,
		{"word":"perturbés","word_nosc":"perturbes","lemma":"perturbé","pos":"ADJ"} ,
		{"word":"pervenche","word_nosc":"pervenche","lemma":"pervenche","pos":"ADJ"} ,
		{"word":"pervers","word_nosc":"pervers","lemma":"pervers","pos":"ADJ"} ,
		{"word":"perverse","word_nosc":"perverse","lemma":"pervers","pos":"ADJ"} ,
		{"word":"perverses","word_nosc":"perverses","lemma":"pervers","pos":"ADJ"} ,
		{"word":"perverti","word_nosc":"perverti","lemma":"perverti","pos":"ADJ"} ,
		{"word":"pervertie","word_nosc":"pervertie","lemma":"perverti","pos":"ADJ"} ,
		{"word":"perverties","word_nosc":"perverties","lemma":"perverti","pos":"ADJ"} ,
		{"word":"pervertis","word_nosc":"pervertis","lemma":"perverti","pos":"ADJ"} ,
		{"word":"perçant","word_nosc":"percant","lemma":"perçant","pos":"ADJ"} ,
		{"word":"perçante","word_nosc":"percante","lemma":"perçant","pos":"ADJ"} ,
		{"word":"perçantes","word_nosc":"percantes","lemma":"perçant","pos":"ADJ"} ,
		{"word":"perçants","word_nosc":"percants","lemma":"perçant","pos":"ADJ"} ,
		{"word":"perçu","word_nosc":"percu","lemma":"perçu","pos":"ADJ"} ,
		{"word":"perçue","word_nosc":"percue","lemma":"perçu","pos":"ADJ"} ,
		{"word":"perçues","word_nosc":"percues","lemma":"perçu","pos":"ADJ"} ,
		{"word":"perçus","word_nosc":"percus","lemma":"perçu","pos":"ADJ"} ,
		{"word":"pesant","word_nosc":"pesant","lemma":"pesant","pos":"ADJ"} ,
		{"word":"pesante","word_nosc":"pesante","lemma":"pesant","pos":"ADJ"} ,
		{"word":"pesantes","word_nosc":"pesantes","lemma":"pesant","pos":"ADJ"} ,
		{"word":"pesants","word_nosc":"pesants","lemma":"pesant","pos":"ADJ"} ,
		{"word":"pessimiste","word_nosc":"pessimiste","lemma":"pessimiste","pos":"ADJ"} ,
		{"word":"pessimistes","word_nosc":"pessimistes","lemma":"pessimiste","pos":"ADJ"} ,
		{"word":"pesteuse","word_nosc":"pesteuse","lemma":"pesteux","pos":"ADJ"} ,
		{"word":"pesticide","word_nosc":"pesticide","lemma":"pesticide","pos":"ADJ"} ,
		{"word":"pesticides","word_nosc":"pesticides","lemma":"pesticide","pos":"ADJ"} ,
		{"word":"pestiféré","word_nosc":"pestifere","lemma":"pestiféré","pos":"ADJ"} ,
		{"word":"pestiférée","word_nosc":"pestiferee","lemma":"pestiféré","pos":"ADJ"} ,
		{"word":"pestilentiel","word_nosc":"pestilentiel","lemma":"pestilentiel","pos":"ADJ"} ,
		{"word":"pestilentielle","word_nosc":"pestilentielle","lemma":"pestilentiel","pos":"ADJ"} ,
		{"word":"pestilentielles","word_nosc":"pestilentielles","lemma":"pestilentiel","pos":"ADJ"} ,
		{"word":"pestilentiels","word_nosc":"pestilentiels","lemma":"pestilentiel","pos":"ADJ"} ,
		{"word":"petiot","word_nosc":"petiot","lemma":"petiot","pos":"ADJ"} ,
		{"word":"petiote","word_nosc":"petiote","lemma":"petiot","pos":"ADJ"} ,
		{"word":"petiots","word_nosc":"petiots","lemma":"petiot","pos":"ADJ"} ,
		{"word":"petit","word_nosc":"petit","lemma":"petit","pos":"ADJ"} ,
		{"word":"petit-bourgeois","word_nosc":"petit-bourgeois","lemma":"petit-bourgeois","pos":"ADJ"} ,
		{"word":"petite","word_nosc":"petite","lemma":"petit","pos":"ADJ"} ,
		{"word":"petite-bourgeoise","word_nosc":"petite-bourgeoise","lemma":"petite-bourgeoise","pos":"ADJ"} ,
		{"word":"petites","word_nosc":"petites","lemma":"petit","pos":"ADJ"} ,
		{"word":"petits","word_nosc":"petits","lemma":"petit","pos":"ADJ"} ,
		{"word":"peuhl","word_nosc":"peuhl","lemma":"peuhl","pos":"ADJ"} ,
		{"word":"peul","word_nosc":"peul","lemma":"peul","pos":"ADJ"} ,
		{"word":"peuplé","word_nosc":"peuple","lemma":"peuplé","pos":"ADJ"} ,
		{"word":"peuplée","word_nosc":"peuplee","lemma":"peuplé","pos":"ADJ"} ,
		{"word":"peuplées","word_nosc":"peuplees","lemma":"peuplé","pos":"ADJ"} ,
		{"word":"peuplés","word_nosc":"peuples","lemma":"peuplé","pos":"ADJ"} ,
		{"word":"peureuse","word_nosc":"peureuse","lemma":"peureux","pos":"ADJ"} ,
		{"word":"peureuses","word_nosc":"peureuses","lemma":"peureux","pos":"ADJ"} ,
		{"word":"peureux","word_nosc":"peureux","lemma":"peureux","pos":"ADJ"} ,
		{"word":"phalangiste","word_nosc":"phalangiste","lemma":"phalangiste","pos":"ADJ"} ,
		{"word":"phallique","word_nosc":"phallique","lemma":"phallique","pos":"ADJ"} ,
		{"word":"phalliques","word_nosc":"phalliques","lemma":"phallique","pos":"ADJ"} ,
		{"word":"phallocentrique","word_nosc":"phallocentrique","lemma":"phallocentrique","pos":"ADJ"} ,
		{"word":"phallocrate","word_nosc":"phallocrate","lemma":"phallocrate","pos":"ADJ"} ,
		{"word":"phallocratique","word_nosc":"phallocratique","lemma":"phallocratique","pos":"ADJ"} ,
		{"word":"phallocratiques","word_nosc":"phallocratiques","lemma":"phallocratique","pos":"ADJ"} ,
		{"word":"phalloïdes","word_nosc":"phalloides","lemma":"phalloïde","pos":"ADJ"} ,
		{"word":"pharamineuses","word_nosc":"pharamineuses","lemma":"pharamineux","pos":"ADJ"} ,
		{"word":"pharamineux","word_nosc":"pharamineux","lemma":"pharamineux","pos":"ADJ"} ,
		{"word":"pharaonique","word_nosc":"pharaonique","lemma":"pharaonique","pos":"ADJ"} ,
		{"word":"pharaoniques","word_nosc":"pharaoniques","lemma":"pharaonique","pos":"ADJ"} ,
		{"word":"pharisaïque","word_nosc":"pharisaique","lemma":"pharisaïque","pos":"ADJ"} ,
		{"word":"pharisaïques","word_nosc":"pharisaiques","lemma":"pharisaïque","pos":"ADJ"} ,
		{"word":"pharisien","word_nosc":"pharisien","lemma":"pharisien","pos":"ADJ"} ,
		{"word":"pharisiens","word_nosc":"pharisiens","lemma":"pharisien","pos":"ADJ"} ,
		{"word":"pharmaceutique","word_nosc":"pharmaceutique","lemma":"pharmaceutique","pos":"ADJ"} ,
		{"word":"pharmaceutiques","word_nosc":"pharmaceutiques","lemma":"pharmaceutique","pos":"ADJ"} ,
		{"word":"pharmacologique","word_nosc":"pharmacologique","lemma":"pharmacologique","pos":"ADJ"} ,
		{"word":"philanthropique","word_nosc":"philanthropique","lemma":"philanthropique","pos":"ADJ"} ,
		{"word":"philanthropiques","word_nosc":"philanthropiques","lemma":"philanthropique","pos":"ADJ"} ,
		{"word":"philarète","word_nosc":"philarete","lemma":"philarète","pos":"ADJ"} ,
		{"word":"philharmonique","word_nosc":"philharmonique","lemma":"philharmonique","pos":"ADJ"} ,
		{"word":"philippin","word_nosc":"philippin","lemma":"philippin","pos":"ADJ"} ,
		{"word":"philippine","word_nosc":"philippine","lemma":"philippin","pos":"ADJ"} ,
		{"word":"philippines","word_nosc":"philippines","lemma":"philippin","pos":"ADJ"} ,
		{"word":"philippins","word_nosc":"philippins","lemma":"philippin","pos":"ADJ"} ,
		{"word":"philologique","word_nosc":"philologique","lemma":"philologique","pos":"ADJ"} ,
		{"word":"philologiques","word_nosc":"philologiques","lemma":"philologique","pos":"ADJ"} ,
		{"word":"philosophal","word_nosc":"philosophal","lemma":"philosophal","pos":"ADJ"} ,
		{"word":"philosophale","word_nosc":"philosophale","lemma":"philosophal","pos":"ADJ"} ,
		{"word":"philosophales","word_nosc":"philosophales","lemma":"philosophal","pos":"ADJ"} ,
		{"word":"philosophant","word_nosc":"philosophant","lemma":"philosophant","pos":"ADJ"} ,
		{"word":"philosophard","word_nosc":"philosophard","lemma":"philosophard","pos":"ADJ"} ,
		{"word":"philosophe","word_nosc":"philosophe","lemma":"philosophe","pos":"ADJ"} ,
		{"word":"philosophes","word_nosc":"philosophes","lemma":"philosophe","pos":"ADJ"} ,
		{"word":"philosophique","word_nosc":"philosophique","lemma":"philosophique","pos":"ADJ"} ,
		{"word":"philosophiques","word_nosc":"philosophiques","lemma":"philosophique","pos":"ADJ"} ,
		{"word":"philotechnique","word_nosc":"philotechnique","lemma":"philotechnique","pos":"ADJ"} ,
		{"word":"phobique","word_nosc":"phobique","lemma":"phobique","pos":"ADJ"} ,
		{"word":"phobiques","word_nosc":"phobiques","lemma":"phobique","pos":"ADJ"} ,
		{"word":"phocéenne","word_nosc":"phoceenne","lemma":"phocéen","pos":"ADJ"} ,
		{"word":"phonatoire","word_nosc":"phonatoire","lemma":"phonatoire","pos":"ADJ"} ,
		{"word":"phonique","word_nosc":"phonique","lemma":"phonique","pos":"ADJ"} ,
		{"word":"phoniques","word_nosc":"phoniques","lemma":"phonique","pos":"ADJ"} ,
		{"word":"phonographiques","word_nosc":"phonographiques","lemma":"phonographique","pos":"ADJ"} ,
		{"word":"phonétique","word_nosc":"phonetique","lemma":"phonétique","pos":"ADJ"} ,
		{"word":"phonétiques","word_nosc":"phonetiques","lemma":"phonétique","pos":"ADJ"} ,
		{"word":"phosphorescent","word_nosc":"phosphorescent","lemma":"phosphorescent","pos":"ADJ"} ,
		{"word":"phosphorescente","word_nosc":"phosphorescente","lemma":"phosphorescent","pos":"ADJ"} ,
		{"word":"phosphorescentes","word_nosc":"phosphorescentes","lemma":"phosphorescent","pos":"ADJ"} ,
		{"word":"phosphorescents","word_nosc":"phosphorescents","lemma":"phosphorescent","pos":"ADJ"} ,
		{"word":"phosphoré","word_nosc":"phosphore","lemma":"phosphoré","pos":"ADJ"} ,
		{"word":"photique","word_nosc":"photique","lemma":"photique","pos":"ADJ"} ,
		{"word":"photo-électrique","word_nosc":"photo-electrique","lemma":"photo-électrique","pos":"ADJ"} ,
		{"word":"photochimique","word_nosc":"photochimique","lemma":"photochimique","pos":"ADJ"} ,
		{"word":"photoconducteur","word_nosc":"photoconducteur","lemma":"photoconducteur","pos":"ADJ"} ,
		{"word":"photographique","word_nosc":"photographique","lemma":"photographique","pos":"ADJ"} ,
		{"word":"photographiques","word_nosc":"photographiques","lemma":"photographique","pos":"ADJ"} ,
		{"word":"photogénique","word_nosc":"photogenique","lemma":"photogénique","pos":"ADJ"} ,
		{"word":"photogéniques","word_nosc":"photogeniques","lemma":"photogénique","pos":"ADJ"} ,
		{"word":"photomaton","word_nosc":"photomaton","lemma":"photomaton","pos":"ADJ"} ,
		{"word":"photomatons","word_nosc":"photomatons","lemma":"photomaton","pos":"ADJ"} ,
		{"word":"photonique","word_nosc":"photonique","lemma":"photonique","pos":"ADJ"} ,
		{"word":"photopériodique","word_nosc":"photoperiodique","lemma":"photopériodique","pos":"ADJ"} ,
		{"word":"photosensible","word_nosc":"photosensible","lemma":"photosensible","pos":"ADJ"} ,
		{"word":"photosensibles","word_nosc":"photosensibles","lemma":"photosensible","pos":"ADJ"} ,
		{"word":"photovoltaïque","word_nosc":"photovoltaique","lemma":"photovoltaïque","pos":"ADJ"} ,
		{"word":"photoélectrique","word_nosc":"photoelectrique","lemma":"photoélectrique","pos":"ADJ"} ,
		{"word":"phraseurs","word_nosc":"phraseurs","lemma":"phraseur","pos":"ADJ"} ,
		{"word":"phraséologique","word_nosc":"phraseologique","lemma":"phraséologique","pos":"ADJ"} ,
		{"word":"phrygien","word_nosc":"phrygien","lemma":"phrygien","pos":"ADJ"} ,
		{"word":"phrygiens","word_nosc":"phrygiens","lemma":"phrygien","pos":"ADJ"} ,
		{"word":"phréatique","word_nosc":"phreatique","lemma":"phréatique","pos":"ADJ"} ,
		{"word":"phréatiques","word_nosc":"phreatiques","lemma":"phréatique","pos":"ADJ"} ,
		{"word":"phrénique","word_nosc":"phrenique","lemma":"phrénique","pos":"ADJ"} ,
		{"word":"phtisique","word_nosc":"phtisique","lemma":"phtisique","pos":"ADJ"} ,
		{"word":"phtisiques","word_nosc":"phtisiques","lemma":"phtisique","pos":"ADJ"} ,
		{"word":"physico-chimiques","word_nosc":"physico-chimiques","lemma":"physico-chimique","pos":"ADJ"} ,
		{"word":"physiologique","word_nosc":"physiologique","lemma":"physiologique","pos":"ADJ"} ,
		{"word":"physiologiques","word_nosc":"physiologiques","lemma":"physiologique","pos":"ADJ"} ,
		{"word":"physiologiste","word_nosc":"physiologiste","lemma":"physiologiste","pos":"ADJ"} ,
		{"word":"physionomique","word_nosc":"physionomique","lemma":"physionomique","pos":"ADJ"} ,
		{"word":"physionomiste","word_nosc":"physionomiste","lemma":"physionomiste","pos":"ADJ"} ,
		{"word":"physionomistes","word_nosc":"physionomistes","lemma":"physionomiste","pos":"ADJ"} ,
		{"word":"physique","word_nosc":"physique","lemma":"physique","pos":"ADJ"} ,
		{"word":"physiques","word_nosc":"physiques","lemma":"physique","pos":"ADJ"} ,
		{"word":"phénicien","word_nosc":"phenicien","lemma":"phénicien","pos":"ADJ"} ,
		{"word":"phénicienne","word_nosc":"phenicienne","lemma":"phénicien","pos":"ADJ"} ,
		{"word":"phéniciennes","word_nosc":"pheniciennes","lemma":"phénicien","pos":"ADJ"} ,
		{"word":"phéniciens","word_nosc":"pheniciens","lemma":"phénicien","pos":"ADJ"} ,
		{"word":"phénique","word_nosc":"phenique","lemma":"phénique","pos":"ADJ"} ,
		{"word":"phéniqués","word_nosc":"pheniques","lemma":"phéniqué","pos":"ADJ"} ,
		{"word":"phénoménal","word_nosc":"phenomenal","lemma":"phénoménal","pos":"ADJ"} ,
		{"word":"phénoménale","word_nosc":"phenomenale","lemma":"phénoménal","pos":"ADJ"} ,
		{"word":"phénoménales","word_nosc":"phenomenales","lemma":"phénoménal","pos":"ADJ"} ,
		{"word":"phénoménaux","word_nosc":"phenomenaux","lemma":"phénoménal","pos":"ADJ"} ,
		{"word":"phénoménologique","word_nosc":"phenomenologique","lemma":"phénoménologique","pos":"ADJ"} ,
		{"word":"piaffant","word_nosc":"piaffant","lemma":"piaffant","pos":"ADJ"} ,
		{"word":"piaffante","word_nosc":"piaffante","lemma":"piaffant","pos":"ADJ"} ,
		{"word":"piaffantes","word_nosc":"piaffantes","lemma":"piaffant","pos":"ADJ"} ,
		{"word":"piaffants","word_nosc":"piaffants","lemma":"piaffant","pos":"ADJ"} ,
		{"word":"piaillant","word_nosc":"piaillant","lemma":"piaillant","pos":"ADJ"} ,
		{"word":"piaillante","word_nosc":"piaillante","lemma":"piaillant","pos":"ADJ"} ,
		{"word":"piaillantes","word_nosc":"piaillantes","lemma":"piaillant","pos":"ADJ"} ,
		{"word":"piaillarde","word_nosc":"piaillarde","lemma":"piaillard","pos":"ADJ"} ,
		{"word":"piaillards","word_nosc":"piaillards","lemma":"piaillard","pos":"ADJ"} ,
		{"word":"piailleurs","word_nosc":"piailleurs","lemma":"piailleur","pos":"ADJ"} ,
		{"word":"piailleuse","word_nosc":"piailleuse","lemma":"piailleur","pos":"ADJ"} ,
		{"word":"piaulant","word_nosc":"piaulant","lemma":"piaulant","pos":"ADJ"} ,
		{"word":"piaulante","word_nosc":"piaulante","lemma":"piaulant","pos":"ADJ"} ,
		{"word":"picard","word_nosc":"picard","lemma":"picard","pos":"ADJ"} ,
		{"word":"picarde","word_nosc":"picarde","lemma":"picard","pos":"ADJ"} ,
		{"word":"picardes","word_nosc":"picardes","lemma":"picard","pos":"ADJ"} ,
		{"word":"picards","word_nosc":"picards","lemma":"picard","pos":"ADJ"} ,
		{"word":"picaresque","word_nosc":"picaresque","lemma":"picaresque","pos":"ADJ"} ,
		{"word":"picaresques","word_nosc":"picaresques","lemma":"picaresque","pos":"ADJ"} ,
		{"word":"picassien","word_nosc":"picassien","lemma":"picassien","pos":"ADJ"} ,
		{"word":"picrique","word_nosc":"picrique","lemma":"picrique","pos":"ADJ"} ,
		{"word":"pictural","word_nosc":"pictural","lemma":"pictural","pos":"ADJ"} ,
		{"word":"picturale","word_nosc":"picturale","lemma":"pictural","pos":"ADJ"} ,
		{"word":"picturales","word_nosc":"picturales","lemma":"pictural","pos":"ADJ"} ,
		{"word":"pie","word_nosc":"pie","lemma":"pie","pos":"ADJ"} ,
		{"word":"pied-de-poule","word_nosc":"pied-de-poule","lemma":"pied-de-poule","pos":"ADJ"} ,
		{"word":"pied-noir","word_nosc":"pied-noir","lemma":"pied-noir","pos":"ADJ"} ,
		{"word":"pierreuse","word_nosc":"pierreuse","lemma":"pierreux","pos":"ADJ"} ,
		{"word":"pierreuses","word_nosc":"pierreuses","lemma":"pierreux","pos":"ADJ"} ,
		{"word":"pierreux","word_nosc":"pierreux","lemma":"pierreux","pos":"ADJ"} ,
		{"word":"pieuse","word_nosc":"pieuse","lemma":"pieux","pos":"ADJ"} ,
		{"word":"pieuses","word_nosc":"pieuses","lemma":"pieux","pos":"ADJ"} ,
		{"word":"pieux","word_nosc":"pieux","lemma":"pieux","pos":"ADJ"} ,
		{"word":"piffrer","word_nosc":"piffrer","lemma":"piffrer","pos":"ADJ"} ,
		{"word":"pigeonnant","word_nosc":"pigeonnant","lemma":"pigeonnant","pos":"ADJ"} ,
		{"word":"pigeonnante","word_nosc":"pigeonnante","lemma":"pigeonnant","pos":"ADJ"} ,
		{"word":"pigeonnants","word_nosc":"pigeonnants","lemma":"pigeonnant","pos":"ADJ"} ,
		{"word":"pigmentaire","word_nosc":"pigmentaire","lemma":"pigmentaire","pos":"ADJ"} ,
		{"word":"pileuse","word_nosc":"pileuse","lemma":"pileux","pos":"ADJ"} ,
		{"word":"pileuses","word_nosc":"pileuses","lemma":"pileux","pos":"ADJ"} ,
		{"word":"pileux","word_nosc":"pileux","lemma":"pileux","pos":"ADJ"} ,
		{"word":"pilifère","word_nosc":"pilifere","lemma":"pilifère","pos":"ADJ"} ,
		{"word":"pillard","word_nosc":"pillard","lemma":"pillard","pos":"ADJ"} ,
		{"word":"pillarde","word_nosc":"pillarde","lemma":"pillard","pos":"ADJ"} ,
		{"word":"pillards","word_nosc":"pillards","lemma":"pillard","pos":"ADJ"} ,
		{"word":"pilote","word_nosc":"pilote","lemma":"pilote","pos":"ADJ"} ,
		{"word":"pilotes","word_nosc":"pilotes","lemma":"pilote","pos":"ADJ"} ,
		{"word":"pimbêche","word_nosc":"pimbeche","lemma":"pimbêche","pos":"ADJ"} ,
		{"word":"pimbêches","word_nosc":"pimbeches","lemma":"pimbêche","pos":"ADJ"} ,
		{"word":"pimenté","word_nosc":"pimente","lemma":"pimenté","pos":"ADJ"} ,
		{"word":"pimentée","word_nosc":"pimentee","lemma":"pimenté","pos":"ADJ"} ,
		{"word":"pimentées","word_nosc":"pimentees","lemma":"pimenté","pos":"ADJ"} ,
		{"word":"pimentés","word_nosc":"pimentes","lemma":"pimenté","pos":"ADJ"} ,
		{"word":"pimpant","word_nosc":"pimpant","lemma":"pimpant","pos":"ADJ"} ,
		{"word":"pimpante","word_nosc":"pimpante","lemma":"pimpant","pos":"ADJ"} ,
		{"word":"pimpantes","word_nosc":"pimpantes","lemma":"pimpant","pos":"ADJ"} ,
		{"word":"pimpants","word_nosc":"pimpants","lemma":"pimpant","pos":"ADJ"} ,
		{"word":"pince-sans-rire","word_nosc":"pince-sans-rire","lemma":"pince-sans-rire","pos":"ADJ"} ,
		{"word":"pincé","word_nosc":"pince","lemma":"pincé","pos":"ADJ"} ,
		{"word":"pincés","word_nosc":"pinces","lemma":"pincé","pos":"ADJ"} ,
		{"word":"pingre","word_nosc":"pingre","lemma":"pingre","pos":"ADJ"} ,
		{"word":"pingres","word_nosc":"pingres","lemma":"pingre","pos":"ADJ"} ,
		{"word":"pinçard","word_nosc":"pincard","lemma":"pinçard","pos":"ADJ"} ,
		{"word":"pinéal","word_nosc":"pineal","lemma":"pinéal","pos":"ADJ"} ,
		{"word":"pinéale","word_nosc":"pineale","lemma":"pinéal","pos":"ADJ"} ,
		{"word":"pinéales","word_nosc":"pineales","lemma":"pinéal","pos":"ADJ"} ,
		{"word":"pionnière","word_nosc":"pionniere","lemma":"pionnier","pos":"ADJ"} ,
		{"word":"piquant","word_nosc":"piquant","lemma":"piquant","pos":"ADJ"} ,
		{"word":"piquante","word_nosc":"piquante","lemma":"piquant","pos":"ADJ"} ,
		{"word":"piquantes","word_nosc":"piquantes","lemma":"piquant","pos":"ADJ"} ,
		{"word":"piquants","word_nosc":"piquants","lemma":"piquant","pos":"ADJ"} ,
		{"word":"piquetée","word_nosc":"piquetee","lemma":"piqueté","pos":"ADJ"} ,
		{"word":"piqueur","word_nosc":"piqueur","lemma":"piqueur","pos":"ADJ"} ,
		{"word":"piqueurs","word_nosc":"piqueurs","lemma":"piqueur","pos":"ADJ"} ,
		{"word":"piqueuse","word_nosc":"piqueuse","lemma":"piqueux","pos":"ADJ"} ,
		{"word":"piqueuses","word_nosc":"piqueuses","lemma":"piqueux","pos":"ADJ"} ,
		{"word":"piqué","word_nosc":"pique","lemma":"piqué","pos":"ADJ"} ,
		{"word":"piquée","word_nosc":"piquee","lemma":"piqué","pos":"ADJ"} ,
		{"word":"piquées","word_nosc":"piquees","lemma":"piqué","pos":"ADJ"} ,
		{"word":"piqués","word_nosc":"piques","lemma":"piqué","pos":"ADJ"} ,
		{"word":"pirandellienne","word_nosc":"pirandellienne","lemma":"pirandellien","pos":"ADJ"} ,
		{"word":"pirate","word_nosc":"pirate","lemma":"pirate","pos":"ADJ"} ,
		{"word":"pirates","word_nosc":"pirates","lemma":"pirate","pos":"ADJ"} ,
		{"word":"pire","word_nosc":"pire","lemma":"pire","pos":"ADJ"} ,
		{"word":"pires","word_nosc":"pires","lemma":"pire","pos":"ADJ"} ,
		{"word":"piriforme","word_nosc":"piriforme","lemma":"piriforme","pos":"ADJ"} ,
		{"word":"piriformes","word_nosc":"piriformes","lemma":"piriforme","pos":"ADJ"} ,
		{"word":"pis","word_nosc":"pis","lemma":"pis","pos":"ADJ"} ,
		{"word":"pisan","word_nosc":"pisan","lemma":"pisan","pos":"ADJ"} ,
		{"word":"pisans","word_nosc":"pisans","lemma":"pisan","pos":"ADJ"} ,
		{"word":"piscicole","word_nosc":"piscicole","lemma":"piscicole","pos":"ADJ"} ,
		{"word":"piscicoles","word_nosc":"piscicoles","lemma":"piscicole","pos":"ADJ"} ,
		{"word":"pisciforme","word_nosc":"pisciforme","lemma":"pisciforme","pos":"ADJ"} ,
		{"word":"pisseuse","word_nosc":"pisseuse","lemma":"pisseur","pos":"ADJ"} ,
		{"word":"pisseuses","word_nosc":"pisseuses","lemma":"pisseur","pos":"ADJ"} ,
		{"word":"pisseux","word_nosc":"pisseux","lemma":"pisseux","pos":"ADJ"} ,
		{"word":"pistache","word_nosc":"pistache","lemma":"pistache","pos":"ADJ"} ,
		{"word":"pistonnée","word_nosc":"pistonnee","lemma":"pistonné","pos":"ADJ"} ,
		{"word":"pitchoune","word_nosc":"pitchoune","lemma":"pitchoun","pos":"ADJ"} ,
		{"word":"piteuse","word_nosc":"piteuse","lemma":"piteux","pos":"ADJ"} ,
		{"word":"piteuses","word_nosc":"piteuses","lemma":"piteux","pos":"ADJ"} ,
		{"word":"piteux","word_nosc":"piteux","lemma":"piteux","pos":"ADJ"} ,
		{"word":"pitoyable","word_nosc":"pitoyable","lemma":"pitoyable","pos":"ADJ"} ,
		{"word":"pitoyables","word_nosc":"pitoyables","lemma":"pitoyable","pos":"ADJ"} ,
		{"word":"pittoresque","word_nosc":"pittoresque","lemma":"pittoresque","pos":"ADJ"} ,
		{"word":"pittoresques","word_nosc":"pittoresques","lemma":"pittoresque","pos":"ADJ"} ,
		{"word":"pituitaire","word_nosc":"pituitaire","lemma":"pituitaire","pos":"ADJ"} ,
		{"word":"pituitaires","word_nosc":"pituitaires","lemma":"pituitaire","pos":"ADJ"} ,
		{"word":"pivotant","word_nosc":"pivotant","lemma":"pivotant","pos":"ADJ"} ,
		{"word":"pivotante","word_nosc":"pivotante","lemma":"pivotant","pos":"ADJ"} ,
		{"word":"pivotantes","word_nosc":"pivotantes","lemma":"pivotant","pos":"ADJ"} ,
		{"word":"pivotants","word_nosc":"pivotants","lemma":"pivotant","pos":"ADJ"} ,
		{"word":"piètre","word_nosc":"pietre","lemma":"piètre","pos":"ADJ"} ,
		{"word":"piètres","word_nosc":"pietres","lemma":"piètre","pos":"ADJ"} ,
		{"word":"piémontais","word_nosc":"piemontais","lemma":"piémontais","pos":"ADJ"} ,
		{"word":"piémontaise","word_nosc":"piemontaise","lemma":"piémontais","pos":"ADJ"} ,
		{"word":"piétinant","word_nosc":"pietinant","lemma":"piétinant","pos":"ADJ"} ,
		{"word":"piétinante","word_nosc":"pietinante","lemma":"piétinant","pos":"ADJ"} ,
		{"word":"piétiste","word_nosc":"pietiste","lemma":"piétiste","pos":"ADJ"} ,
		{"word":"piéton","word_nosc":"pieton","lemma":"piéton","pos":"ADJ"} ,
		{"word":"piétonne","word_nosc":"pietonne","lemma":"piéton","pos":"ADJ"} ,
		{"word":"piétonnes","word_nosc":"pietonnes","lemma":"piéton","pos":"ADJ"} ,
		{"word":"piétonnier","word_nosc":"pietonnier","lemma":"piétonnier","pos":"ADJ"} ,
		{"word":"piétonnière","word_nosc":"pietonniere","lemma":"piétonnier","pos":"ADJ"} ,
		{"word":"piétonnières","word_nosc":"pietonnieres","lemma":"piétonnier","pos":"ADJ"} ,
		{"word":"piétons","word_nosc":"pietons","lemma":"piéton","pos":"ADJ"} ,
		{"word":"placentaire","word_nosc":"placentaire","lemma":"placentaire","pos":"ADJ"} ,
		{"word":"placide","word_nosc":"placide","lemma":"placide","pos":"ADJ"} ,
		{"word":"placides","word_nosc":"placides","lemma":"placide","pos":"ADJ"} ,
		{"word":"placé","word_nosc":"place","lemma":"placé","pos":"ADJ"} ,
		{"word":"placée","word_nosc":"placee","lemma":"placé","pos":"ADJ"} ,
		{"word":"placées","word_nosc":"placees","lemma":"placé","pos":"ADJ"} ,
		{"word":"placés","word_nosc":"places","lemma":"placé","pos":"ADJ"} ,
		{"word":"plafonné","word_nosc":"plafonne","lemma":"plafonné","pos":"ADJ"} ,
		{"word":"plafonnées","word_nosc":"plafonnees","lemma":"plafonné","pos":"ADJ"} ,
		{"word":"plaidable","word_nosc":"plaidable","lemma":"plaidable","pos":"ADJ"} ,
		{"word":"plaidants","word_nosc":"plaidants","lemma":"plaidant","pos":"ADJ"} ,
		{"word":"plaignante","word_nosc":"plaignante","lemma":"plaignant","pos":"ADJ"} ,
		{"word":"plaignants","word_nosc":"plaignants","lemma":"plaignant","pos":"ADJ"} ,
		{"word":"plain","word_nosc":"plain","lemma":"plain","pos":"ADJ"} ,
		{"word":"plaine","word_nosc":"plaine","lemma":"plaine","pos":"ADJ"} ,
		{"word":"plaines","word_nosc":"plaines","lemma":"plaine","pos":"ADJ"} ,
		{"word":"plains","word_nosc":"plains","lemma":"plain","pos":"ADJ"} ,
		{"word":"plaintif","word_nosc":"plaintif","lemma":"plaintif","pos":"ADJ"} ,
		{"word":"plaintifs","word_nosc":"plaintifs","lemma":"plaintif","pos":"ADJ"} ,
		{"word":"plaintive","word_nosc":"plaintive","lemma":"plaintif","pos":"ADJ"} ,
		{"word":"plaintives","word_nosc":"plaintives","lemma":"plaintif","pos":"ADJ"} ,
		{"word":"plaisant","word_nosc":"plaisant","lemma":"plaisant","pos":"ADJ"} ,
		{"word":"plaisante","word_nosc":"plaisante","lemma":"plaisant","pos":"ADJ"} ,
		{"word":"plaisantes","word_nosc":"plaisantes","lemma":"plaisant","pos":"ADJ"} ,
		{"word":"plaisantin","word_nosc":"plaisantin","lemma":"plaisantin","pos":"ADJ"} ,
		{"word":"plaisants","word_nosc":"plaisants","lemma":"plaisant","pos":"ADJ"} ,
		{"word":"plan","word_nosc":"plan","lemma":"plan","pos":"ADJ"} ,
		{"word":"planant","word_nosc":"planant","lemma":"planant","pos":"ADJ"} ,
		{"word":"planante","word_nosc":"planante","lemma":"planant","pos":"ADJ"} ,
		{"word":"planantes","word_nosc":"planantes","lemma":"planant","pos":"ADJ"} ,
		{"word":"planants","word_nosc":"planants","lemma":"planant","pos":"ADJ"} ,
		{"word":"plane","word_nosc":"plane","lemma":"plan","pos":"ADJ"} ,
		{"word":"planes","word_nosc":"planes","lemma":"plan","pos":"ADJ"} ,
		{"word":"planifiable","word_nosc":"planifiable","lemma":"planifiable","pos":"ADJ"} ,
		{"word":"planificateur","word_nosc":"planificateur","lemma":"planificateur","pos":"ADJ"} ,
		{"word":"planificatrice","word_nosc":"planificatrice","lemma":"planificateur","pos":"ADJ"} ,
		{"word":"planqué","word_nosc":"planque","lemma":"planqué","pos":"ADJ"} ,
		{"word":"planquée","word_nosc":"planquee","lemma":"planqué","pos":"ADJ"} ,
		{"word":"planquées","word_nosc":"planquees","lemma":"planqué","pos":"ADJ"} ,
		{"word":"planqués","word_nosc":"planques","lemma":"planqué","pos":"ADJ"} ,
		{"word":"plans","word_nosc":"plans","lemma":"plan","pos":"ADJ"} ,
		{"word":"plantaire","word_nosc":"plantaire","lemma":"plantaire","pos":"ADJ"} ,
		{"word":"plantaires","word_nosc":"plantaires","lemma":"plantaire","pos":"ADJ"} ,
		{"word":"plantureuse","word_nosc":"plantureuse","lemma":"plantureux","pos":"ADJ"} ,
		{"word":"plantureuses","word_nosc":"plantureuses","lemma":"plantureux","pos":"ADJ"} ,
		{"word":"plantureux","word_nosc":"plantureux","lemma":"plantureux","pos":"ADJ"} ,
		{"word":"planté","word_nosc":"plante","lemma":"planté","pos":"ADJ"} ,
		{"word":"plantée","word_nosc":"plantee","lemma":"planté","pos":"ADJ"} ,
		{"word":"plantées","word_nosc":"plantees","lemma":"planté","pos":"ADJ"} ,
		{"word":"plantés","word_nosc":"plantes","lemma":"planté","pos":"ADJ"} ,
		{"word":"planétaire","word_nosc":"planetaire","lemma":"planétaire","pos":"ADJ"} ,
		{"word":"planétaires","word_nosc":"planetaires","lemma":"planétaire","pos":"ADJ"} ,
		{"word":"plaquettaire","word_nosc":"plaquettaire","lemma":"plaquettaire","pos":"ADJ"} ,
		{"word":"plaqué","word_nosc":"plaque","lemma":"plaqué","pos":"ADJ"} ,
		{"word":"plaquée","word_nosc":"plaquee","lemma":"plaqué","pos":"ADJ"} ,
		{"word":"plaquées","word_nosc":"plaquees","lemma":"plaqué","pos":"ADJ"} ,
		{"word":"plaqués","word_nosc":"plaques","lemma":"plaqué","pos":"ADJ"} ,
		{"word":"plasmagène","word_nosc":"plasmagene","lemma":"plasmagène","pos":"ADJ"} ,
		{"word":"plasmique","word_nosc":"plasmique","lemma":"plasmique","pos":"ADJ"} ,
		{"word":"plastique","word_nosc":"plastique","lemma":"plastique","pos":"ADJ"} ,
		{"word":"plastiques","word_nosc":"plastiques","lemma":"plastique","pos":"ADJ"} ,
		{"word":"plat","word_nosc":"plat","lemma":"plat","pos":"ADJ"} ,
		{"word":"plat-ventre","word_nosc":"plat-ventre","lemma":"plat-ventre","pos":"ADJ"} ,
		{"word":"plate","word_nosc":"plate","lemma":"plat","pos":"ADJ"} ,
		{"word":"plateresque","word_nosc":"plateresque","lemma":"plateresque","pos":"ADJ"} ,
		{"word":"plateresques","word_nosc":"plateresques","lemma":"plateresque","pos":"ADJ"} ,
		{"word":"plates","word_nosc":"plates","lemma":"plat","pos":"ADJ"} ,
		{"word":"platiné","word_nosc":"platine","lemma":"platiné","pos":"ADJ"} ,
		{"word":"platinée","word_nosc":"platinee","lemma":"platiné","pos":"ADJ"} ,
		{"word":"platinées","word_nosc":"platinees","lemma":"platiné","pos":"ADJ"} ,
		{"word":"platinés","word_nosc":"platines","lemma":"platiné","pos":"ADJ"} ,
		{"word":"platonicien","word_nosc":"platonicien","lemma":"platonicien","pos":"ADJ"} ,
		{"word":"platonicienne","word_nosc":"platonicienne","lemma":"platonicien","pos":"ADJ"} ,
		{"word":"platoniciens","word_nosc":"platoniciens","lemma":"platonicien","pos":"ADJ"} ,
		{"word":"platonique","word_nosc":"platonique","lemma":"platonique","pos":"ADJ"} ,
		{"word":"platoniques","word_nosc":"platoniques","lemma":"platonique","pos":"ADJ"} ,
		{"word":"platonisant","word_nosc":"platonisant","lemma":"platonisant","pos":"ADJ"} ,
		{"word":"plats","word_nosc":"plats","lemma":"plat","pos":"ADJ"} ,
		{"word":"platyrrhiniens","word_nosc":"platyrrhiniens","lemma":"platyrrhinien","pos":"ADJ"} ,
		{"word":"plausible","word_nosc":"plausible","lemma":"plausible","pos":"ADJ"} ,
		{"word":"plausibles","word_nosc":"plausibles","lemma":"plausible","pos":"ADJ"} ,
		{"word":"plein","word_nosc":"plein","lemma":"plein","pos":"ADJ"} ,
		{"word":"plein-air","word_nosc":"plein-air","lemma":"plein-air","pos":"ADJ"} ,
		{"word":"pleine","word_nosc":"pleine","lemma":"plein","pos":"ADJ"} ,
		{"word":"pleines","word_nosc":"pleines","lemma":"plein","pos":"ADJ"} ,
		{"word":"pleins","word_nosc":"pleins","lemma":"plein","pos":"ADJ"} ,
		{"word":"pleural","word_nosc":"pleural","lemma":"pleural","pos":"ADJ"} ,
		{"word":"pleurale","word_nosc":"pleurale","lemma":"pleural","pos":"ADJ"} ,
		{"word":"pleurant","word_nosc":"pleurant","lemma":"pleurant","pos":"ADJ"} ,
		{"word":"pleurante","word_nosc":"pleurante","lemma":"pleurant","pos":"ADJ"} ,
		{"word":"pleurantes","word_nosc":"pleurantes","lemma":"pleurant","pos":"ADJ"} ,
		{"word":"pleurants","word_nosc":"pleurants","lemma":"pleurant","pos":"ADJ"} ,
		{"word":"pleurard","word_nosc":"pleurard","lemma":"pleurard","pos":"ADJ"} ,
		{"word":"pleurarde","word_nosc":"pleurarde","lemma":"pleurard","pos":"ADJ"} ,
		{"word":"pleurards","word_nosc":"pleurards","lemma":"pleurard","pos":"ADJ"} ,
		{"word":"pleureur","word_nosc":"pleureur","lemma":"pleureur","pos":"ADJ"} ,
		{"word":"pleureurs","word_nosc":"pleureurs","lemma":"pleureur","pos":"ADJ"} ,
		{"word":"pleureuse","word_nosc":"pleureuse","lemma":"pleureur","pos":"ADJ"} ,
		{"word":"pleureuses","word_nosc":"pleureuses","lemma":"pleureur","pos":"ADJ"} ,
		{"word":"pleureux","word_nosc":"pleureux","lemma":"pleureur","pos":"ADJ"} ,
		{"word":"pleurnichard","word_nosc":"pleurnichard","lemma":"pleurnichard","pos":"ADJ"} ,
		{"word":"pleurnicharde","word_nosc":"pleurnicharde","lemma":"pleurnichard","pos":"ADJ"} ,
		{"word":"pleurnichardes","word_nosc":"pleurnichardes","lemma":"pleurnichard","pos":"ADJ"} ,
		{"word":"pleurnichards","word_nosc":"pleurnichards","lemma":"pleurnichard","pos":"ADJ"} ,
		{"word":"pleurnicheur","word_nosc":"pleurnicheur","lemma":"pleurnicheur","pos":"ADJ"} ,
		{"word":"pleurnicheurs","word_nosc":"pleurnicheurs","lemma":"pleurnicheur","pos":"ADJ"} ,
		{"word":"pleurnicheuse","word_nosc":"pleurnicheuse","lemma":"pleurnicheur","pos":"ADJ"} ,
		{"word":"pleurnicheuses","word_nosc":"pleurnicheuses","lemma":"pleurnicheur","pos":"ADJ"} ,
		{"word":"pleurétique","word_nosc":"pleuretique","lemma":"pleurétique","pos":"ADJ"} ,
		{"word":"pleutre","word_nosc":"pleutre","lemma":"pleutre","pos":"ADJ"} ,
		{"word":"pleutres","word_nosc":"pleutres","lemma":"pleutre","pos":"ADJ"} ,
		{"word":"pliable","word_nosc":"pliable","lemma":"pliable","pos":"ADJ"} ,
		{"word":"pliant","word_nosc":"pliant","lemma":"pliant","pos":"ADJ"} ,
		{"word":"pliante","word_nosc":"pliante","lemma":"pliant","pos":"ADJ"} ,
		{"word":"pliantes","word_nosc":"pliantes","lemma":"pliant","pos":"ADJ"} ,
		{"word":"pliants","word_nosc":"pliants","lemma":"pliant","pos":"ADJ"} ,
		{"word":"plissé","word_nosc":"plisse","lemma":"plissé","pos":"ADJ"} ,
		{"word":"plissée","word_nosc":"plissee","lemma":"plissé","pos":"ADJ"} ,
		{"word":"plissées","word_nosc":"plissees","lemma":"plissé","pos":"ADJ"} ,
		{"word":"plissés","word_nosc":"plisses","lemma":"plissé","pos":"ADJ"} ,
		{"word":"plombé","word_nosc":"plombe","lemma":"plombé","pos":"ADJ"} ,
		{"word":"plombée","word_nosc":"plombee","lemma":"plombé","pos":"ADJ"} ,
		{"word":"plombées","word_nosc":"plombees","lemma":"plombé","pos":"ADJ"} ,
		{"word":"plombés","word_nosc":"plombes","lemma":"plombé","pos":"ADJ"} ,
		{"word":"plongeant","word_nosc":"plongeant","lemma":"plongeant","pos":"ADJ"} ,
		{"word":"plongeante","word_nosc":"plongeante","lemma":"plongeant","pos":"ADJ"} ,
		{"word":"plongeantes","word_nosc":"plongeantes","lemma":"plongeant","pos":"ADJ"} ,
		{"word":"plongeants","word_nosc":"plongeants","lemma":"plongeant","pos":"ADJ"} ,
		{"word":"plouc","word_nosc":"plouc","lemma":"plouc","pos":"ADJ"} ,
		{"word":"ploucs","word_nosc":"ploucs","lemma":"plouc","pos":"ADJ"} ,
		{"word":"ployant","word_nosc":"ployant","lemma":"ployant","pos":"ADJ"} ,
		{"word":"ployante","word_nosc":"ployante","lemma":"ployant","pos":"ADJ"} ,
		{"word":"plumeuse","word_nosc":"plumeuse","lemma":"plumeux","pos":"ADJ"} ,
		{"word":"plumeuses","word_nosc":"plumeuses","lemma":"plumeux","pos":"ADJ"} ,
		{"word":"plumeux","word_nosc":"plumeux","lemma":"plumeux","pos":"ADJ"} ,
		{"word":"pluraliste","word_nosc":"pluraliste","lemma":"pluraliste","pos":"ADJ"} ,
		{"word":"pluriannuel","word_nosc":"pluriannuel","lemma":"pluriannuel","pos":"ADJ"} ,
		{"word":"pluridisciplinaire","word_nosc":"pluridisciplinaire","lemma":"pluridisciplinaire","pos":"ADJ"} ,
		{"word":"pluriel","word_nosc":"pluriel","lemma":"pluriel","pos":"ADJ"} ,
		{"word":"plurielle","word_nosc":"plurielle","lemma":"pluriel","pos":"ADJ"} ,
		{"word":"plurinational","word_nosc":"plurinational","lemma":"plurinational","pos":"ADJ"} ,
		{"word":"plusieurs","word_nosc":"plusieurs","lemma":"plusieurs","pos":"ADJ:ind"} ,
		{"word":"plutonien","word_nosc":"plutonien","lemma":"plutonien","pos":"ADJ"} ,
		{"word":"plutonienne","word_nosc":"plutonienne","lemma":"plutonien","pos":"ADJ"} ,
		{"word":"plutonique","word_nosc":"plutonique","lemma":"plutonique","pos":"ADJ"} ,
		{"word":"pluvial","word_nosc":"pluvial","lemma":"pluvial","pos":"ADJ"} ,
		{"word":"pluviale","word_nosc":"pluviale","lemma":"pluvial","pos":"ADJ"} ,
		{"word":"pluviales","word_nosc":"pluviales","lemma":"pluvial","pos":"ADJ"} ,
		{"word":"pluviaux","word_nosc":"pluviaux","lemma":"pluvial","pos":"ADJ"} ,
		{"word":"pluvieuse","word_nosc":"pluvieuse","lemma":"pluvieux","pos":"ADJ"} ,
		{"word":"pluvieuses","word_nosc":"pluvieuses","lemma":"pluvieux","pos":"ADJ"} ,
		{"word":"pluvieux","word_nosc":"pluvieux","lemma":"pluvieux","pos":"ADJ"} ,
		{"word":"pluviométrique","word_nosc":"pluviometrique","lemma":"pluviométrique","pos":"ADJ"} ,
		{"word":"plâtreuse","word_nosc":"platreuse","lemma":"plâtreux","pos":"ADJ"} ,
		{"word":"plâtreuses","word_nosc":"platreuses","lemma":"plâtreux","pos":"ADJ"} ,
		{"word":"plâtreux","word_nosc":"platreux","lemma":"plâtreux","pos":"ADJ"} ,
		{"word":"plâtré","word_nosc":"platre","lemma":"plâtré","pos":"ADJ"} ,
		{"word":"plâtrée","word_nosc":"platree","lemma":"plâtré","pos":"ADJ"} ,
		{"word":"plâtrés","word_nosc":"platres","lemma":"plâtré","pos":"ADJ"} ,
		{"word":"plébéien","word_nosc":"plebeien","lemma":"plébéien","pos":"ADJ"} ,
		{"word":"plébéienne","word_nosc":"plebeienne","lemma":"plébéien","pos":"ADJ"} ,
		{"word":"plébéiennes","word_nosc":"plebeiennes","lemma":"plébéien","pos":"ADJ"} ,
		{"word":"plébéiens","word_nosc":"plebeiens","lemma":"plébéien","pos":"ADJ"} ,
		{"word":"plénier","word_nosc":"plenier","lemma":"plénier","pos":"ADJ"} ,
		{"word":"pléniers","word_nosc":"pleniers","lemma":"plénier","pos":"ADJ"} ,
		{"word":"plénipotentiaire","word_nosc":"plenipotentiaire","lemma":"plénipotentiaire","pos":"ADJ"} ,
		{"word":"plénipotentiaires","word_nosc":"plenipotentiaires","lemma":"plénipotentiaire","pos":"ADJ"} ,
		{"word":"plénière","word_nosc":"pleniere","lemma":"plénier","pos":"ADJ"} ,
		{"word":"plénières","word_nosc":"plenieres","lemma":"plénier","pos":"ADJ"} ,
		{"word":"pléonastiques","word_nosc":"pleonastiques","lemma":"pléonastique","pos":"ADJ"} ,
		{"word":"pléthorique","word_nosc":"plethorique","lemma":"pléthorique","pos":"ADJ"} ,
		{"word":"pléthoriques","word_nosc":"plethoriques","lemma":"pléthorique","pos":"ADJ"} ,
		{"word":"pneumatique","word_nosc":"pneumatique","lemma":"pneumatique","pos":"ADJ"} ,
		{"word":"pneumatiques","word_nosc":"pneumatiques","lemma":"pneumatique","pos":"ADJ"} ,
		{"word":"pneumonique","word_nosc":"pneumonique","lemma":"pneumonique","pos":"ADJ"} ,
		{"word":"pochard","word_nosc":"pochard","lemma":"pochard","pos":"ADJ"} ,
		{"word":"pocharde","word_nosc":"pocharde","lemma":"pochard","pos":"ADJ"} ,
		{"word":"pocheté","word_nosc":"pochete","lemma":"pocheté","pos":"ADJ"} ,
		{"word":"poché","word_nosc":"poche","lemma":"poché","pos":"ADJ"} ,
		{"word":"pochée","word_nosc":"pochee","lemma":"poché","pos":"ADJ"} ,
		{"word":"pochées","word_nosc":"pochees","lemma":"poché","pos":"ADJ"} ,
		{"word":"pochés","word_nosc":"poches","lemma":"poché","pos":"ADJ"} ,
		{"word":"podagre","word_nosc":"podagre","lemma":"podagre","pos":"ADJ"} ,
		{"word":"poignant","word_nosc":"poignant","lemma":"poignant","pos":"ADJ"} ,
		{"word":"poignante","word_nosc":"poignante","lemma":"poignant","pos":"ADJ"} ,
		{"word":"poignantes","word_nosc":"poignantes","lemma":"poignant","pos":"ADJ"} ,
		{"word":"poignants","word_nosc":"poignants","lemma":"poignant","pos":"ADJ"} ,
		{"word":"poilant","word_nosc":"poilant","lemma":"poilant","pos":"ADJ"} ,
		{"word":"poileux","word_nosc":"poileux","lemma":"poileux","pos":"ADJ"} ,
		{"word":"poilu","word_nosc":"poilu","lemma":"poilu","pos":"ADJ"} ,
		{"word":"poilue","word_nosc":"poilue","lemma":"poilu","pos":"ADJ"} ,
		{"word":"poilues","word_nosc":"poilues","lemma":"poilu","pos":"ADJ"} ,
		{"word":"poilus","word_nosc":"poilus","lemma":"poilu","pos":"ADJ"} ,
		{"word":"pointeur","word_nosc":"pointeur","lemma":"pointeur","pos":"ADJ"} ,
		{"word":"pointeurs","word_nosc":"pointeurs","lemma":"pointeur","pos":"ADJ"} ,
		{"word":"pointeuse","word_nosc":"pointeuse","lemma":"pointeur","pos":"ADJ"} ,
		{"word":"pointeuses","word_nosc":"pointeuses","lemma":"pointeur","pos":"ADJ"} ,
		{"word":"pointilleuse","word_nosc":"pointilleuse","lemma":"pointilleux","pos":"ADJ"} ,
		{"word":"pointilleuses","word_nosc":"pointilleuses","lemma":"pointilleux","pos":"ADJ"} ,
		{"word":"pointilleux","word_nosc":"pointilleux","lemma":"pointilleux","pos":"ADJ"} ,
		{"word":"pointilliste","word_nosc":"pointilliste","lemma":"pointilliste","pos":"ADJ"} ,
		{"word":"pointillistes","word_nosc":"pointillistes","lemma":"pointilliste","pos":"ADJ"} ,
		{"word":"pointu","word_nosc":"pointu","lemma":"pointu","pos":"ADJ"} ,
		{"word":"pointue","word_nosc":"pointue","lemma":"pointu","pos":"ADJ"} ,
		{"word":"pointues","word_nosc":"pointues","lemma":"pointu","pos":"ADJ"} ,
		{"word":"pointus","word_nosc":"pointus","lemma":"pointu","pos":"ADJ"} ,
		{"word":"pointé","word_nosc":"pointe","lemma":"pointé","pos":"ADJ"} ,
		{"word":"pointée","word_nosc":"pointee","lemma":"pointé","pos":"ADJ"} ,
		{"word":"pointées","word_nosc":"pointees","lemma":"pointé","pos":"ADJ"} ,
		{"word":"pointés","word_nosc":"pointes","lemma":"pointé","pos":"ADJ"} ,
		{"word":"poissard","word_nosc":"poissard","lemma":"poissard","pos":"ADJ"} ,
		{"word":"poisseuse","word_nosc":"poisseuse","lemma":"poisseux","pos":"ADJ"} ,
		{"word":"poisseuses","word_nosc":"poisseuses","lemma":"poisseux","pos":"ADJ"} ,
		{"word":"poisseux","word_nosc":"poisseux","lemma":"poisseux","pos":"ADJ"} ,
		{"word":"poissonneuse","word_nosc":"poissonneuse","lemma":"poissonneux","pos":"ADJ"} ,
		{"word":"poissonneuses","word_nosc":"poissonneuses","lemma":"poissonneux","pos":"ADJ"} ,
		{"word":"poissonneux","word_nosc":"poissonneux","lemma":"poissonneux","pos":"ADJ"} ,
		{"word":"poitevin","word_nosc":"poitevin","lemma":"poitevin","pos":"ADJ"} ,
		{"word":"poitevins","word_nosc":"poitevins","lemma":"poitevin","pos":"ADJ"} ,
		{"word":"poitrinaire","word_nosc":"poitrinaire","lemma":"poitrinaire","pos":"ADJ"} ,
		{"word":"poitrinaires","word_nosc":"poitrinaires","lemma":"poitrinaire","pos":"ADJ"} ,
		{"word":"poivré","word_nosc":"poivre","lemma":"poivré","pos":"ADJ"} ,
		{"word":"poivrée","word_nosc":"poivree","lemma":"poivré","pos":"ADJ"} ,
		{"word":"poivrées","word_nosc":"poivrees","lemma":"poivré","pos":"ADJ"} ,
		{"word":"poivrés","word_nosc":"poivres","lemma":"poivré","pos":"ADJ"} ,
		{"word":"polack","word_nosc":"polack","lemma":"polack","pos":"ADJ"} ,
		{"word":"polacks","word_nosc":"polacks","lemma":"polack","pos":"ADJ"} ,
		{"word":"polaire","word_nosc":"polaire","lemma":"polaire","pos":"ADJ"} ,
		{"word":"polaires","word_nosc":"polaires","lemma":"polaire","pos":"ADJ"} ,
		{"word":"polak","word_nosc":"polak","lemma":"polak","pos":"ADJ"} ,
		{"word":"polarde","word_nosc":"polarde","lemma":"polard","pos":"ADJ"} ,
		{"word":"polardes","word_nosc":"polardes","lemma":"polard","pos":"ADJ"} ,
		{"word":"polards","word_nosc":"polards","lemma":"polard","pos":"ADJ"} ,
		{"word":"polarisant","word_nosc":"polarisant","lemma":"polarisant","pos":"ADJ"} ,
		{"word":"polarisants","word_nosc":"polarisants","lemma":"polarisant","pos":"ADJ"} ,
		{"word":"polarisé","word_nosc":"polarise","lemma":"polarisé","pos":"ADJ"} ,
		{"word":"polarisée","word_nosc":"polarisee","lemma":"polarisé","pos":"ADJ"} ,
		{"word":"poli","word_nosc":"poli","lemma":"poli","pos":"ADJ"} ,
		{"word":"policier","word_nosc":"policier","lemma":"policier","pos":"ADJ"} ,
		{"word":"policiers","word_nosc":"policiers","lemma":"policier","pos":"ADJ"} ,
		{"word":"policière","word_nosc":"policiere","lemma":"policier","pos":"ADJ"} ,
		{"word":"policières","word_nosc":"policieres","lemma":"policier","pos":"ADJ"} ,
		{"word":"policé","word_nosc":"police","lemma":"policé","pos":"ADJ"} ,
		{"word":"policée","word_nosc":"policee","lemma":"policé","pos":"ADJ"} ,
		{"word":"policées","word_nosc":"policees","lemma":"policé","pos":"ADJ"} ,
		{"word":"policés","word_nosc":"polices","lemma":"policé","pos":"ADJ"} ,
		{"word":"polie","word_nosc":"polie","lemma":"poli","pos":"ADJ"} ,
		{"word":"polies","word_nosc":"polies","lemma":"poli","pos":"ADJ"} ,
		{"word":"poliomyélitique","word_nosc":"poliomyelitique","lemma":"poliomyélitique","pos":"ADJ"} ,
		{"word":"poliomyélitiques","word_nosc":"poliomyelitiques","lemma":"poliomyélitique","pos":"ADJ"} ,
		{"word":"poliorcétique","word_nosc":"poliorcetique","lemma":"poliorcétique","pos":"ADJ"} ,
		{"word":"polis","word_nosc":"polis","lemma":"poli","pos":"ADJ"} ,
		{"word":"polisson","word_nosc":"polisson","lemma":"polisson","pos":"ADJ"} ,
		{"word":"polissonne","word_nosc":"polissonne","lemma":"polisson","pos":"ADJ"} ,
		{"word":"polissonnes","word_nosc":"polissonnes","lemma":"polisson","pos":"ADJ"} ,
		{"word":"polissons","word_nosc":"polissons","lemma":"polisson","pos":"ADJ"} ,
		{"word":"politicard","word_nosc":"politicard","lemma":"politicard","pos":"ADJ"} ,
		{"word":"politicien","word_nosc":"politicien","lemma":"politicien","pos":"ADJ"} ,
		{"word":"politicienne","word_nosc":"politicienne","lemma":"politicien","pos":"ADJ"} ,
		{"word":"politiciennes","word_nosc":"politiciennes","lemma":"politicien","pos":"ADJ"} ,
		{"word":"politiciens","word_nosc":"politiciens","lemma":"politicien","pos":"ADJ"} ,
		{"word":"politico-religieuse","word_nosc":"politico-religieuse","lemma":"politico-religieux","pos":"ADJ"} ,
		{"word":"politique","word_nosc":"politique","lemma":"politique","pos":"ADJ"} ,
		{"word":"politiques","word_nosc":"politiques","lemma":"politique","pos":"ADJ"} ,
		{"word":"pollinique","word_nosc":"pollinique","lemma":"pollinique","pos":"ADJ"} ,
		{"word":"pollinisateur","word_nosc":"pollinisateur","lemma":"pollinisateur","pos":"ADJ"} ,
		{"word":"polluant","word_nosc":"polluant","lemma":"polluant","pos":"ADJ"} ,
		{"word":"polluante","word_nosc":"polluante","lemma":"polluant","pos":"ADJ"} ,
		{"word":"polluants","word_nosc":"polluants","lemma":"polluant","pos":"ADJ"} ,
		{"word":"pollueur","word_nosc":"pollueur","lemma":"pollueur","pos":"ADJ"} ,
		{"word":"polonais","word_nosc":"polonais","lemma":"polonais","pos":"ADJ"} ,
		{"word":"polonaise","word_nosc":"polonaise","lemma":"polonais","pos":"ADJ"} ,
		{"word":"polonaises","word_nosc":"polonaises","lemma":"polonais","pos":"ADJ"} ,
		{"word":"poltron","word_nosc":"poltron","lemma":"poltron","pos":"ADJ"} ,
		{"word":"poltronne","word_nosc":"poltronne","lemma":"poltron","pos":"ADJ"} ,
		{"word":"poltrons","word_nosc":"poltrons","lemma":"poltron","pos":"ADJ"} ,
		{"word":"poly-sexuelle","word_nosc":"poly-sexuelle","lemma":"poly-sexuel","pos":"ADJ"} ,
		{"word":"polyandre","word_nosc":"polyandre","lemma":"polyandre","pos":"ADJ"} ,
		{"word":"polychrome","word_nosc":"polychrome","lemma":"polychrome","pos":"ADJ"} ,
		{"word":"polychromes","word_nosc":"polychromes","lemma":"polychrome","pos":"ADJ"} ,
		{"word":"polychromé","word_nosc":"polychrome","lemma":"polychromé","pos":"ADJ"} ,
		{"word":"polychromées","word_nosc":"polychromees","lemma":"polychromé","pos":"ADJ"} ,
		{"word":"polycéphale","word_nosc":"polycephale","lemma":"polycéphale","pos":"ADJ"} ,
		{"word":"polygame","word_nosc":"polygame","lemma":"polygame","pos":"ADJ"} ,
		{"word":"polyglotte","word_nosc":"polyglotte","lemma":"polyglotte","pos":"ADJ"} ,
		{"word":"polyglottes","word_nosc":"polyglottes","lemma":"polyglotte","pos":"ADJ"} ,
		{"word":"polygonal","word_nosc":"polygonal","lemma":"polygonal","pos":"ADJ"} ,
		{"word":"polygonales","word_nosc":"polygonales","lemma":"polygonal","pos":"ADJ"} ,
		{"word":"polygonaux","word_nosc":"polygonaux","lemma":"polygonal","pos":"ADJ"} ,
		{"word":"polygénique","word_nosc":"polygenique","lemma":"polygénique","pos":"ADJ"} ,
		{"word":"polykystique","word_nosc":"polykystique","lemma":"polykystique","pos":"ADJ"} ,
		{"word":"polymorphe","word_nosc":"polymorphe","lemma":"polymorphe","pos":"ADJ"} ,
		{"word":"polymorphes","word_nosc":"polymorphes","lemma":"polymorphe","pos":"ADJ"} ,
		{"word":"polymère","word_nosc":"polymere","lemma":"polymère","pos":"ADJ"} ,
		{"word":"polymérique","word_nosc":"polymerique","lemma":"polymérique","pos":"ADJ"} ,
		{"word":"polymériques","word_nosc":"polymeriques","lemma":"polymérique","pos":"ADJ"} ,
		{"word":"polynésien","word_nosc":"polynesien","lemma":"polynésien","pos":"ADJ"} ,
		{"word":"polynésienne","word_nosc":"polynesienne","lemma":"polynésien","pos":"ADJ"} ,
		{"word":"polynésiennes","word_nosc":"polynesiennes","lemma":"polynésien","pos":"ADJ"} ,
		{"word":"polynésiens","word_nosc":"polynesiens","lemma":"polynésien","pos":"ADJ"} ,
		{"word":"polyphone","word_nosc":"polyphone","lemma":"polyphone","pos":"ADJ"} ,
		{"word":"polyphonique","word_nosc":"polyphonique","lemma":"polyphonique","pos":"ADJ"} ,
		{"word":"polysyllabe","word_nosc":"polysyllabe","lemma":"polysyllabe","pos":"ADJ"} ,
		{"word":"polysyllabique","word_nosc":"polysyllabique","lemma":"polysyllabique","pos":"ADJ"} ,
		{"word":"polytechnique","word_nosc":"polytechnique","lemma":"polytechnique","pos":"ADJ"} ,
		{"word":"polythéiste","word_nosc":"polytheiste","lemma":"polythéiste","pos":"ADJ"} ,
		{"word":"polytonal","word_nosc":"polytonal","lemma":"polytonal","pos":"ADJ"} ,
		{"word":"polyvalent","word_nosc":"polyvalent","lemma":"polyvalent","pos":"ADJ"} ,
		{"word":"polyvalente","word_nosc":"polyvalente","lemma":"polyvalent","pos":"ADJ"} ,
		{"word":"polyvalents","word_nosc":"polyvalents","lemma":"polyvalent","pos":"ADJ"} ,
		{"word":"polyvinylique","word_nosc":"polyvinylique","lemma":"polyvinylique","pos":"ADJ"} ,
		{"word":"polémique","word_nosc":"polemique","lemma":"polémique","pos":"ADJ"} ,
		{"word":"polémiques","word_nosc":"polemiques","lemma":"polémique","pos":"ADJ"} ,
		{"word":"pommadé","word_nosc":"pommade","lemma":"pommadé","pos":"ADJ"} ,
		{"word":"pommadées","word_nosc":"pommadees","lemma":"pommadé","pos":"ADJ"} ,
		{"word":"pommadés","word_nosc":"pommades","lemma":"pommadé","pos":"ADJ"} ,
		{"word":"pommelé","word_nosc":"pommele","lemma":"pommelé","pos":"ADJ"} ,
		{"word":"pommelées","word_nosc":"pommelees","lemma":"pommelé","pos":"ADJ"} ,
		{"word":"pommelés","word_nosc":"pommeles","lemma":"pommelé","pos":"ADJ"} ,
		{"word":"pommée","word_nosc":"pommee","lemma":"pommé","pos":"ADJ"} ,
		{"word":"pommées","word_nosc":"pommees","lemma":"pommé","pos":"ADJ"} ,
		{"word":"pommés","word_nosc":"pommes","lemma":"pommé","pos":"ADJ"} ,
		{"word":"pompadour","word_nosc":"pompadour","lemma":"pompadour","pos":"ADJ"} ,
		{"word":"pompette","word_nosc":"pompette","lemma":"pompette","pos":"ADJ"} ,
		{"word":"pompeuse","word_nosc":"pompeuse","lemma":"pompeux","pos":"ADJ"} ,
		{"word":"pompeuses","word_nosc":"pompeuses","lemma":"pompeux","pos":"ADJ"} ,
		{"word":"pompeux","word_nosc":"pompeux","lemma":"pompeux","pos":"ADJ"} ,
		{"word":"pompidoliens","word_nosc":"pompidoliens","lemma":"pompidolien","pos":"ADJ"} ,
		{"word":"pompier","word_nosc":"pompier","lemma":"pompier","pos":"ADJ"} ,
		{"word":"pompiers","word_nosc":"pompiers","lemma":"pompier","pos":"ADJ"} ,
		{"word":"pompé","word_nosc":"pompe","lemma":"pompé","pos":"ADJ"} ,
		{"word":"pompéien","word_nosc":"pompeien","lemma":"pompéien","pos":"ADJ"} ,
		{"word":"pompéienne","word_nosc":"pompeienne","lemma":"pompéien","pos":"ADJ"} ,
		{"word":"pompéiennes","word_nosc":"pompeiennes","lemma":"pompéien","pos":"ADJ"} ,
		{"word":"pompéiens","word_nosc":"pompeiens","lemma":"pompéien","pos":"ADJ"} ,
		{"word":"pompés","word_nosc":"pompes","lemma":"pompé","pos":"ADJ"} ,
		{"word":"poméranien","word_nosc":"pomeranien","lemma":"poméranien","pos":"ADJ"} ,
		{"word":"poméraniens","word_nosc":"pomeraniens","lemma":"poméranien","pos":"ADJ"} ,
		{"word":"ponceau","word_nosc":"ponceau","lemma":"ponceau","pos":"ADJ"} ,
		{"word":"ponctuel","word_nosc":"ponctuel","lemma":"ponctuel","pos":"ADJ"} ,
		{"word":"ponctuelle","word_nosc":"ponctuelle","lemma":"ponctuel","pos":"ADJ"} ,
		{"word":"ponctuelles","word_nosc":"ponctuelles","lemma":"ponctuel","pos":"ADJ"} ,
		{"word":"ponctuels","word_nosc":"ponctuels","lemma":"ponctuel","pos":"ADJ"} ,
		{"word":"ponctuée","word_nosc":"ponctuee","lemma":"ponctué","pos":"ADJ"} ,
		{"word":"ponctuées","word_nosc":"ponctuees","lemma":"ponctué","pos":"ADJ"} ,
		{"word":"ponctués","word_nosc":"ponctues","lemma":"ponctué","pos":"ADJ"} ,
		{"word":"pondeuse","word_nosc":"pondeuse","lemma":"pondeur","pos":"ADJ"} ,
		{"word":"pondeuses","word_nosc":"pondeuses","lemma":"pondeur","pos":"ADJ"} ,
		{"word":"pondérable","word_nosc":"ponderable","lemma":"pondérable","pos":"ADJ"} ,
		{"word":"pondérables","word_nosc":"ponderables","lemma":"pondérable","pos":"ADJ"} ,
		{"word":"pondérale","word_nosc":"ponderale","lemma":"pondéral","pos":"ADJ"} ,
		{"word":"pondéré","word_nosc":"pondere","lemma":"pondéré","pos":"ADJ"} ,
		{"word":"pondérée","word_nosc":"ponderee","lemma":"pondéré","pos":"ADJ"} ,
		{"word":"pondérées","word_nosc":"ponderees","lemma":"pondéré","pos":"ADJ"} ,
		{"word":"pondérés","word_nosc":"ponderes","lemma":"pondéré","pos":"ADJ"} ,
		{"word":"pontifiant","word_nosc":"pontifiant","lemma":"pontifiant","pos":"ADJ"} ,
		{"word":"pontifiante","word_nosc":"pontifiante","lemma":"pontifiant","pos":"ADJ"} ,
		{"word":"pontifiants","word_nosc":"pontifiants","lemma":"pontifiant","pos":"ADJ"} ,
		{"word":"pontifical","word_nosc":"pontifical","lemma":"pontifical","pos":"ADJ"} ,
		{"word":"pontificale","word_nosc":"pontificale","lemma":"pontifical","pos":"ADJ"} ,
		{"word":"pontificales","word_nosc":"pontificales","lemma":"pontifical","pos":"ADJ"} ,
		{"word":"pontificaux","word_nosc":"pontificaux","lemma":"pontifical","pos":"ADJ"} ,
		{"word":"pontin","word_nosc":"pontin","lemma":"pontin","pos":"ADJ"} ,
		{"word":"pontins","word_nosc":"pontins","lemma":"pontin","pos":"ADJ"} ,
		{"word":"ponté","word_nosc":"ponte","lemma":"ponté","pos":"ADJ"} ,
		{"word":"pop","word_nosc":"pop","lemma":"pop","pos":"ADJ"} ,
		{"word":"popistes","word_nosc":"popistes","lemma":"popiste","pos":"ADJ"} ,
		{"word":"poplité","word_nosc":"poplite","lemma":"poplité","pos":"ADJ"} ,
		{"word":"poplitée","word_nosc":"poplitee","lemma":"poplité","pos":"ADJ"} ,
		{"word":"popote","word_nosc":"popote","lemma":"popote","pos":"ADJ"} ,
		{"word":"populacier","word_nosc":"populacier","lemma":"populacier","pos":"ADJ"} ,
		{"word":"populacière","word_nosc":"populaciere","lemma":"populacier","pos":"ADJ"} ,
		{"word":"populacières","word_nosc":"populacieres","lemma":"populacier","pos":"ADJ"} ,
		{"word":"populaire","word_nosc":"populaire","lemma":"populaire","pos":"ADJ"} ,
		{"word":"populaires","word_nosc":"populaires","lemma":"populaire","pos":"ADJ"} ,
		{"word":"populeuse","word_nosc":"populeuse","lemma":"populeux","pos":"ADJ"} ,
		{"word":"populeuses","word_nosc":"populeuses","lemma":"populeux","pos":"ADJ"} ,
		{"word":"populeux","word_nosc":"populeux","lemma":"populeux","pos":"ADJ"} ,
		{"word":"populiste","word_nosc":"populiste","lemma":"populiste","pos":"ADJ"} ,
		{"word":"populistes","word_nosc":"populistes","lemma":"populiste","pos":"ADJ"} ,
		{"word":"porcin","word_nosc":"porcin","lemma":"porcin","pos":"ADJ"} ,
		{"word":"porcine","word_nosc":"porcine","lemma":"porcin","pos":"ADJ"} ,
		{"word":"porcins","word_nosc":"porcins","lemma":"porcin","pos":"ADJ"} ,
		{"word":"poreuse","word_nosc":"poreuse","lemma":"poreux","pos":"ADJ"} ,
		{"word":"poreuses","word_nosc":"poreuses","lemma":"poreux","pos":"ADJ"} ,
		{"word":"poreux","word_nosc":"poreux","lemma":"poreux","pos":"ADJ"} ,
		{"word":"porno","word_nosc":"porno","lemma":"porno","pos":"ADJ"} ,
		{"word":"pornographique","word_nosc":"pornographique","lemma":"pornographique","pos":"ADJ"} ,
		{"word":"pornographiques","word_nosc":"pornographiques","lemma":"pornographique","pos":"ADJ"} ,
		{"word":"pornos","word_nosc":"pornos","lemma":"porno","pos":"ADJ"} ,
		{"word":"portable","word_nosc":"portable","lemma":"portable","pos":"ADJ"} ,
		{"word":"portables","word_nosc":"portables","lemma":"portable","pos":"ADJ"} ,
		{"word":"portal","word_nosc":"portal","lemma":"portal","pos":"ADJ"} ,
		{"word":"portant","word_nosc":"portant","lemma":"portant","pos":"ADJ"} ,
		{"word":"portante","word_nosc":"portante","lemma":"portant","pos":"ADJ"} ,
		{"word":"portants","word_nosc":"portants","lemma":"portant","pos":"ADJ"} ,
		{"word":"portatif","word_nosc":"portatif","lemma":"portatif","pos":"ADJ"} ,
		{"word":"portatifs","word_nosc":"portatifs","lemma":"portatif","pos":"ADJ"} ,
		{"word":"portative","word_nosc":"portative","lemma":"portatif","pos":"ADJ"} ,
		{"word":"portatives","word_nosc":"portatives","lemma":"portatif","pos":"ADJ"} ,
		{"word":"porteur","word_nosc":"porteur","lemma":"porteur","pos":"ADJ"} ,
		{"word":"porteurs","word_nosc":"porteurs","lemma":"porteur","pos":"ADJ"} ,
		{"word":"porteuse","word_nosc":"porteuse","lemma":"porteur","pos":"ADJ"} ,
		{"word":"porteuses","word_nosc":"porteuses","lemma":"porteur","pos":"ADJ"} ,
		{"word":"portoricain","word_nosc":"portoricain","lemma":"portoricain","pos":"ADJ"} ,
		{"word":"portoricaine","word_nosc":"portoricaine","lemma":"portoricain","pos":"ADJ"} ,
		{"word":"portoricaines","word_nosc":"portoricaines","lemma":"portoricain","pos":"ADJ"} ,
		{"word":"portoricains","word_nosc":"portoricains","lemma":"portoricain","pos":"ADJ"} ,
		{"word":"portuaire","word_nosc":"portuaire","lemma":"portuaire","pos":"ADJ"} ,
		{"word":"portuaires","word_nosc":"portuaires","lemma":"portuaire","pos":"ADJ"} ,
		{"word":"portugais","word_nosc":"portugais","lemma":"portugais","pos":"ADJ"} ,
		{"word":"portugaise","word_nosc":"portugaise","lemma":"portugais","pos":"ADJ"} ,
		{"word":"portugaises","word_nosc":"portugaises","lemma":"portugais","pos":"ADJ"} ,
		{"word":"portée","word_nosc":"portee","lemma":"portée","pos":"ADJ"} ,
		{"word":"portées","word_nosc":"portees","lemma":"porté","pos":"ADJ"} ,
		{"word":"portées","word_nosc":"portees","lemma":"portée","pos":"ADJ"} ,
		{"word":"poseur","word_nosc":"poseur","lemma":"poseur","pos":"ADJ"} ,
		{"word":"poseurs","word_nosc":"poseurs","lemma":"poseur","pos":"ADJ"} ,
		{"word":"poseuse","word_nosc":"poseuse","lemma":"poseur","pos":"ADJ"} ,
		{"word":"positif","word_nosc":"positif","lemma":"positif","pos":"ADJ"} ,
		{"word":"positifs","word_nosc":"positifs","lemma":"positif","pos":"ADJ"} ,
		{"word":"positionnel","word_nosc":"positionnel","lemma":"positionnel","pos":"ADJ"} ,
		{"word":"positive","word_nosc":"positive","lemma":"positif","pos":"ADJ"} ,
		{"word":"positives","word_nosc":"positives","lemma":"positif","pos":"ADJ"} ,
		{"word":"positiviste","word_nosc":"positiviste","lemma":"positiviste","pos":"ADJ"} ,
		{"word":"positivistes","word_nosc":"positivistes","lemma":"positiviste","pos":"ADJ"} ,
		{"word":"possessif","word_nosc":"possessif","lemma":"possessif","pos":"ADJ"} ,
		{"word":"possessifs","word_nosc":"possessifs","lemma":"possessif","pos":"ADJ"} ,
		{"word":"possessive","word_nosc":"possessive","lemma":"possessif","pos":"ADJ"} ,
		{"word":"possessives","word_nosc":"possessives","lemma":"possessif","pos":"ADJ"} ,
		{"word":"possible","word_nosc":"possible","lemma":"possible","pos":"ADJ"} ,
		{"word":"possibles","word_nosc":"possibles","lemma":"possible","pos":"ADJ"} ,
		{"word":"possédant","word_nosc":"possedant","lemma":"possédant","pos":"ADJ"} ,
		{"word":"possédante","word_nosc":"possedante","lemma":"possédant","pos":"ADJ"} ,
		{"word":"possédé","word_nosc":"possede","lemma":"possédé","pos":"ADJ"} ,
		{"word":"possédée","word_nosc":"possedee","lemma":"possédé","pos":"ADJ"} ,
		{"word":"possédées","word_nosc":"possedees","lemma":"possédé","pos":"ADJ"} ,
		{"word":"possédés","word_nosc":"possedes","lemma":"possédé","pos":"ADJ"} ,
		{"word":"post-apocalyptique","word_nosc":"post-apocalyptique","lemma":"post-apocalyptique","pos":"ADJ"} ,
		{"word":"post-hypnotique","word_nosc":"post-hypnotique","lemma":"post-hypnotique","pos":"ADJ"} ,
		{"word":"post-moderne","word_nosc":"post-moderne","lemma":"post-moderne","pos":"ADJ"} ,
		{"word":"post-natal","word_nosc":"post-natal","lemma":"post-natal","pos":"ADJ"} ,
		{"word":"post-natale","word_nosc":"post-natale","lemma":"post-natal","pos":"ADJ"} ,
		{"word":"post-opératoire","word_nosc":"post-operatoire","lemma":"post-opératoire","pos":"ADJ"} ,
		{"word":"post-opératoires","word_nosc":"post-operatoires","lemma":"post-opératoire","pos":"ADJ"} ,
		{"word":"post-romantique","word_nosc":"post-romantique","lemma":"post-romantique","pos":"ADJ"} ,
		{"word":"post-surréalistes","word_nosc":"post-surrealistes","lemma":"post-surréaliste","pos":"ADJ"} ,
		{"word":"post-synchro","word_nosc":"post-synchro","lemma":"post-synchro","pos":"ADJ"} ,
		{"word":"post-traumatique","word_nosc":"post-traumatique","lemma":"post-traumatique","pos":"ADJ"} ,
		{"word":"post-traumatiques","word_nosc":"post-traumatiques","lemma":"post-traumatique","pos":"ADJ"} ,
		{"word":"post-victorien","word_nosc":"post-victorien","lemma":"post-victorien","pos":"ADJ"} ,
		{"word":"postal","word_nosc":"postal","lemma":"postal","pos":"ADJ"} ,
		{"word":"postale","word_nosc":"postale","lemma":"postal","pos":"ADJ"} ,
		{"word":"postales","word_nosc":"postales","lemma":"postal","pos":"ADJ"} ,
		{"word":"postaux","word_nosc":"postaux","lemma":"postal","pos":"ADJ"} ,
		{"word":"postdoctoral","word_nosc":"postdoctoral","lemma":"postdoctoral","pos":"ADJ"} ,
		{"word":"poster","word_nosc":"poster","lemma":"poster","pos":"ADJ"} ,
		{"word":"posters","word_nosc":"posters","lemma":"poster","pos":"ADJ"} ,
		{"word":"posthume","word_nosc":"posthume","lemma":"posthume","pos":"ADJ"} ,
		{"word":"posthumes","word_nosc":"posthumes","lemma":"posthume","pos":"ADJ"} ,
		{"word":"postiche","word_nosc":"postiche","lemma":"postiche","pos":"ADJ"} ,
		{"word":"postiches","word_nosc":"postiches","lemma":"postiche","pos":"ADJ"} ,
		{"word":"postier","word_nosc":"postier","lemma":"postier","pos":"ADJ"} ,
		{"word":"postiers","word_nosc":"postiers","lemma":"postier","pos":"ADJ"} ,
		{"word":"postmoderne","word_nosc":"postmoderne","lemma":"postmoderne","pos":"ADJ"} ,
		{"word":"postmoderniste","word_nosc":"postmoderniste","lemma":"postmoderniste","pos":"ADJ"} ,
		{"word":"postnatal","word_nosc":"postnatal","lemma":"postnatal","pos":"ADJ"} ,
		{"word":"postnatale","word_nosc":"postnatale","lemma":"postnatal","pos":"ADJ"} ,
		{"word":"postopératoire","word_nosc":"postoperatoire","lemma":"postopératoire","pos":"ADJ"} ,
		{"word":"postopératoires","word_nosc":"postoperatoires","lemma":"postopératoire","pos":"ADJ"} ,
		{"word":"posturaux","word_nosc":"posturaux","lemma":"postural","pos":"ADJ"} ,
		{"word":"posté","word_nosc":"poste","lemma":"posté","pos":"ADJ"} ,
		{"word":"postée","word_nosc":"postee","lemma":"posté","pos":"ADJ"} ,
		{"word":"postées","word_nosc":"postees","lemma":"posté","pos":"ADJ"} ,
		{"word":"postérieur","word_nosc":"posterieur","lemma":"postérieur","pos":"ADJ"} ,
		{"word":"postérieure","word_nosc":"posterieure","lemma":"postérieur","pos":"ADJ"} ,
		{"word":"postérieures","word_nosc":"posterieures","lemma":"postérieur","pos":"ADJ"} ,
		{"word":"postérieurs","word_nosc":"posterieurs","lemma":"postérieur","pos":"ADJ"} ,
		{"word":"postés","word_nosc":"postes","lemma":"posté","pos":"ADJ"} ,
		{"word":"posé","word_nosc":"pose","lemma":"posé","pos":"ADJ"} ,
		{"word":"posée","word_nosc":"posee","lemma":"posé","pos":"ADJ"} ,
		{"word":"posées","word_nosc":"posees","lemma":"posé","pos":"ADJ"} ,
		{"word":"posés","word_nosc":"poses","lemma":"posé","pos":"ADJ"} ,
		{"word":"pot-au-feu","word_nosc":"pot-au-feu","lemma":"pot-au-feu","pos":"ADJ"} ,
		{"word":"potable","word_nosc":"potable","lemma":"potable","pos":"ADJ"} ,
		{"word":"potables","word_nosc":"potables","lemma":"potable","pos":"ADJ"} ,
		{"word":"potager","word_nosc":"potager","lemma":"potager","pos":"ADJ"} ,
		{"word":"potagers","word_nosc":"potagers","lemma":"potager","pos":"ADJ"} ,
		{"word":"potagère","word_nosc":"potagere","lemma":"potager","pos":"ADJ"} ,
		{"word":"potagères","word_nosc":"potageres","lemma":"potager","pos":"ADJ"} ,
		{"word":"potelé","word_nosc":"potele","lemma":"potelé","pos":"ADJ"} ,
		{"word":"potelée","word_nosc":"potelee","lemma":"potelé","pos":"ADJ"} ,
		{"word":"potelées","word_nosc":"potelees","lemma":"potelé","pos":"ADJ"} ,
		{"word":"potelés","word_nosc":"poteles","lemma":"potelé","pos":"ADJ"} ,
		{"word":"potencée","word_nosc":"potencee","lemma":"potencé","pos":"ADJ"} ,
		{"word":"potentiel","word_nosc":"potentiel","lemma":"potentiel","pos":"ADJ"} ,
		{"word":"potentielle","word_nosc":"potentielle","lemma":"potentiel","pos":"ADJ"} ,
		{"word":"potentielles","word_nosc":"potentielles","lemma":"potentiel","pos":"ADJ"} ,
		{"word":"potentiels","word_nosc":"potentiels","lemma":"potentiel","pos":"ADJ"} ,
		{"word":"poudreuse","word_nosc":"poudreuse","lemma":"poudreux","pos":"ADJ"} ,
		{"word":"poudreuses","word_nosc":"poudreuses","lemma":"poudreux","pos":"ADJ"} ,
		{"word":"poudreux","word_nosc":"poudreux","lemma":"poudreux","pos":"ADJ"} ,
		{"word":"poudroyante","word_nosc":"poudroyante","lemma":"poudroyant","pos":"ADJ"} ,
		{"word":"poudroyantes","word_nosc":"poudroyantes","lemma":"poudroyant","pos":"ADJ"} ,
		{"word":"poudré","word_nosc":"poudre","lemma":"poudré","pos":"ADJ"} ,
		{"word":"poudrée","word_nosc":"poudree","lemma":"poudré","pos":"ADJ"} ,
		{"word":"poudrées","word_nosc":"poudrees","lemma":"poudré","pos":"ADJ"} ,
		{"word":"poudrés","word_nosc":"poudres","lemma":"poudré","pos":"ADJ"} ,
		{"word":"pouffant","word_nosc":"pouffant","lemma":"pouffant","pos":"ADJ"} ,
		{"word":"pouffante","word_nosc":"pouffante","lemma":"pouffant","pos":"ADJ"} ,
		{"word":"pouffantes","word_nosc":"pouffantes","lemma":"pouffant","pos":"ADJ"} ,
		{"word":"pouilleuse","word_nosc":"pouilleuse","lemma":"pouilleux","pos":"ADJ"} ,
		{"word":"pouilleuses","word_nosc":"pouilleuses","lemma":"pouilleux","pos":"ADJ"} ,
		{"word":"pouilleux","word_nosc":"pouilleux","lemma":"pouilleux","pos":"ADJ"} ,
		{"word":"poulinière","word_nosc":"pouliniere","lemma":"poulinière","pos":"ADJ"} ,
		{"word":"poupard","word_nosc":"poupard","lemma":"poupard","pos":"ADJ"} ,
		{"word":"poupards","word_nosc":"poupards","lemma":"poupard","pos":"ADJ"} ,
		{"word":"poupin","word_nosc":"poupin","lemma":"poupin","pos":"ADJ"} ,
		{"word":"poupine","word_nosc":"poupine","lemma":"poupin","pos":"ADJ"} ,
		{"word":"pourpre","word_nosc":"pourpre","lemma":"pourpre","pos":"ADJ"} ,
		{"word":"pourpres","word_nosc":"pourpres","lemma":"pourpre","pos":"ADJ"} ,
		{"word":"pourpré","word_nosc":"pourpre","lemma":"pourpré","pos":"ADJ"} ,
		{"word":"pourprée","word_nosc":"pourpree","lemma":"pourpré","pos":"ADJ"} ,
		{"word":"pourri","word_nosc":"pourri","lemma":"pourri","pos":"ADJ"} ,
		{"word":"pourrie","word_nosc":"pourrie","lemma":"pourri","pos":"ADJ"} ,
		{"word":"pourries","word_nosc":"pourries","lemma":"pourri","pos":"ADJ"} ,
		{"word":"pourris","word_nosc":"pourris","lemma":"pourri","pos":"ADJ"} ,
		{"word":"pourrissant","word_nosc":"pourrissant","lemma":"pourrissant","pos":"ADJ"} ,
		{"word":"pourrissante","word_nosc":"pourrissante","lemma":"pourrissant","pos":"ADJ"} ,
		{"word":"pourrissantes","word_nosc":"pourrissantes","lemma":"pourrissant","pos":"ADJ"} ,
		{"word":"pourrissants","word_nosc":"pourrissants","lemma":"pourrissant","pos":"ADJ"} ,
		{"word":"pourrisseur","word_nosc":"pourrisseur","lemma":"pourrisseur","pos":"ADJ"} ,
		{"word":"poussif","word_nosc":"poussif","lemma":"poussif","pos":"ADJ"} ,
		{"word":"poussifs","word_nosc":"poussifs","lemma":"poussif","pos":"ADJ"} ,
		{"word":"poussive","word_nosc":"poussive","lemma":"poussif","pos":"ADJ"} ,
		{"word":"poussives","word_nosc":"poussives","lemma":"poussif","pos":"ADJ"} ,
		{"word":"poussiéreuse","word_nosc":"poussiereuse","lemma":"poussiéreux","pos":"ADJ"} ,
		{"word":"poussiéreuses","word_nosc":"poussiereuses","lemma":"poussiéreux","pos":"ADJ"} ,
		{"word":"poussiéreux","word_nosc":"poussiereux","lemma":"poussiéreux","pos":"ADJ"} ,
		{"word":"poussé","word_nosc":"pousse","lemma":"poussé","pos":"ADJ"} ,
		{"word":"poussée","word_nosc":"poussee","lemma":"poussé","pos":"ADJ"} ,
		{"word":"poussées","word_nosc":"poussees","lemma":"poussé","pos":"ADJ"} ,
		{"word":"poussés","word_nosc":"pousses","lemma":"poussé","pos":"ADJ"} ,
		{"word":"poutine","word_nosc":"poutine","lemma":"poutine","pos":"ADJ"} ,
		{"word":"poétique","word_nosc":"poetique","lemma":"poétique","pos":"ADJ"} ,
		{"word":"poétiques","word_nosc":"poetiques","lemma":"poétique","pos":"ADJ"} ,
		{"word":"pragmatique","word_nosc":"pragmatique","lemma":"pragmatique","pos":"ADJ"} ,
		{"word":"pragmatiques","word_nosc":"pragmatiques","lemma":"pragmatique","pos":"ADJ"} ,
		{"word":"praliné","word_nosc":"praline","lemma":"praliné","pos":"ADJ"} ,
		{"word":"pralinés","word_nosc":"pralines","lemma":"praliné","pos":"ADJ"} ,
		{"word":"praticable","word_nosc":"praticable","lemma":"praticable","pos":"ADJ"} ,
		{"word":"praticables","word_nosc":"praticables","lemma":"praticable","pos":"ADJ"} ,
		{"word":"pratiquant","word_nosc":"pratiquant","lemma":"pratiquant","pos":"ADJ"} ,
		{"word":"pratiquante","word_nosc":"pratiquante","lemma":"pratiquant","pos":"ADJ"} ,
		{"word":"pratiquants","word_nosc":"pratiquants","lemma":"pratiquant","pos":"ADJ"} ,
		{"word":"pratique","word_nosc":"pratique","lemma":"pratique","pos":"ADJ"} ,
		{"word":"pratiques","word_nosc":"pratiques","lemma":"pratique","pos":"ADJ"} ,
		{"word":"premier","word_nosc":"premier","lemma":"premier","pos":"ADJ"} ,
		{"word":"premier-né","word_nosc":"premier-ne","lemma":"premier-né","pos":"ADJ"} ,
		{"word":"premiers","word_nosc":"premiers","lemma":"premier","pos":"ADJ"} ,
		{"word":"premiers-nés","word_nosc":"premiers-nes","lemma":"premier-né","pos":"ADJ"} ,
		{"word":"première","word_nosc":"premiere","lemma":"premier","pos":"ADJ"} ,
		{"word":"premièrement","word_nosc":"premierement","lemma":"premièrement","pos":"ADJ"} ,
		{"word":"premières","word_nosc":"premieres","lemma":"premier","pos":"ADJ"} ,
		{"word":"prenable","word_nosc":"prenable","lemma":"prenable","pos":"ADJ"} ,
		{"word":"prenables","word_nosc":"prenables","lemma":"prenable","pos":"ADJ"} ,
		{"word":"prenant","word_nosc":"prenant","lemma":"prenant","pos":"ADJ"} ,
		{"word":"prenante","word_nosc":"prenante","lemma":"prenant","pos":"ADJ"} ,
		{"word":"prenantes","word_nosc":"prenantes","lemma":"prenant","pos":"ADJ"} ,
		{"word":"prenants","word_nosc":"prenants","lemma":"prenant","pos":"ADJ"} ,
		{"word":"presbyte","word_nosc":"presbyte","lemma":"presbyte","pos":"ADJ"} ,
		{"word":"presbytérien","word_nosc":"presbyterien","lemma":"presbytérien","pos":"ADJ"} ,
		{"word":"presbytérienne","word_nosc":"presbyterienne","lemma":"presbytérien","pos":"ADJ"} ,
		{"word":"presbytériennes","word_nosc":"presbyteriennes","lemma":"presbytérien","pos":"ADJ"} ,
		{"word":"presciente","word_nosc":"presciente","lemma":"prescient","pos":"ADJ"} ,
		{"word":"pressant","word_nosc":"pressant","lemma":"pressant","pos":"ADJ"} ,
		{"word":"pressante","word_nosc":"pressante","lemma":"pressant","pos":"ADJ"} ,
		{"word":"pressantes","word_nosc":"pressantes","lemma":"pressant","pos":"ADJ"} ,
		{"word":"pressants","word_nosc":"pressants","lemma":"pressant","pos":"ADJ"} ,
		{"word":"presse-bouton","word_nosc":"presse-bouton","lemma":"presse-bouton","pos":"ADJ"} ,
		{"word":"pressenti","word_nosc":"pressenti","lemma":"pressenti","pos":"ADJ"} ,
		{"word":"pressentie","word_nosc":"pressentie","lemma":"pressenti","pos":"ADJ"} ,
		{"word":"pressenties","word_nosc":"pressenties","lemma":"pressenti","pos":"ADJ"} ,
		{"word":"pressentis","word_nosc":"pressentis","lemma":"pressenti","pos":"ADJ"} ,
		{"word":"presseur","word_nosc":"presseur","lemma":"presseur","pos":"ADJ"} ,
		{"word":"pressionnée","word_nosc":"pressionnee","lemma":"pressionné","pos":"ADJ"} ,
		{"word":"pressurisé","word_nosc":"pressurise","lemma":"pressurisé","pos":"ADJ"} ,
		{"word":"pressurisée","word_nosc":"pressurisee","lemma":"pressurisé","pos":"ADJ"} ,
		{"word":"pressurisés","word_nosc":"pressurises","lemma":"pressurisé","pos":"ADJ"} ,
		{"word":"pressé","word_nosc":"presse","lemma":"pressé","pos":"ADJ"} ,
		{"word":"pressée","word_nosc":"pressee","lemma":"pressé","pos":"ADJ"} ,
		{"word":"pressées","word_nosc":"pressees","lemma":"pressé","pos":"ADJ"} ,
		{"word":"pressés","word_nosc":"presses","lemma":"pressé","pos":"ADJ"} ,
		{"word":"preste","word_nosc":"preste","lemma":"preste","pos":"ADJ"} ,
		{"word":"prestes","word_nosc":"prestes","lemma":"preste","pos":"ADJ"} ,
		{"word":"prestigieuse","word_nosc":"prestigieuse","lemma":"prestigieux","pos":"ADJ"} ,
		{"word":"prestigieuses","word_nosc":"prestigieuses","lemma":"prestigieux","pos":"ADJ"} ,
		{"word":"prestigieux","word_nosc":"prestigieux","lemma":"prestigieux","pos":"ADJ"} ,
		{"word":"preux","word_nosc":"preux","lemma":"preux","pos":"ADJ"} ,
		{"word":"priant","word_nosc":"priant","lemma":"priant","pos":"ADJ"} ,
		{"word":"priapique","word_nosc":"priapique","lemma":"priapique","pos":"ADJ"} ,
		{"word":"priapiques","word_nosc":"priapiques","lemma":"priapique","pos":"ADJ"} ,
		{"word":"prieur","word_nosc":"prieur","lemma":"prieur","pos":"ADJ"} ,
		{"word":"primaire","word_nosc":"primaire","lemma":"primaire","pos":"ADJ"} ,
		{"word":"primaires","word_nosc":"primaires","lemma":"primaire","pos":"ADJ"} ,
		{"word":"primal","word_nosc":"primal","lemma":"primal","pos":"ADJ"} ,
		{"word":"primale","word_nosc":"primale","lemma":"primal","pos":"ADJ"} ,
		{"word":"prime","word_nosc":"prime","lemma":"prime","pos":"ADJ"} ,
		{"word":"primes","word_nosc":"primes","lemma":"prime","pos":"ADJ"} ,
		{"word":"primesautier","word_nosc":"primesautier","lemma":"primesautier","pos":"ADJ"} ,
		{"word":"primesautiers","word_nosc":"primesautiers","lemma":"primesautier","pos":"ADJ"} ,
		{"word":"primesautière","word_nosc":"primesautiere","lemma":"primesautier","pos":"ADJ"} ,
		{"word":"primitif","word_nosc":"primitif","lemma":"primitif","pos":"ADJ"} ,
		{"word":"primitifs","word_nosc":"primitifs","lemma":"primitif","pos":"ADJ"} ,
		{"word":"primitive","word_nosc":"primitive","lemma":"primitif","pos":"ADJ"} ,
		{"word":"primitives","word_nosc":"primitives","lemma":"primitif","pos":"ADJ"} ,
		{"word":"primordial","word_nosc":"primordial","lemma":"primordial","pos":"ADJ"} ,
		{"word":"primordiale","word_nosc":"primordiale","lemma":"primordial","pos":"ADJ"} ,
		{"word":"primordiales","word_nosc":"primordiales","lemma":"primordial","pos":"ADJ"} ,
		{"word":"primordiaux","word_nosc":"primordiaux","lemma":"primordial","pos":"ADJ"} ,
		{"word":"primé","word_nosc":"prime","lemma":"primé","pos":"ADJ"} ,
		{"word":"primée","word_nosc":"primee","lemma":"primé","pos":"ADJ"} ,
		{"word":"primées","word_nosc":"primees","lemma":"primé","pos":"ADJ"} ,
		{"word":"primés","word_nosc":"primes","lemma":"primé","pos":"ADJ"} ,
		{"word":"princeps","word_nosc":"princeps","lemma":"princeps","pos":"ADJ"} ,
		{"word":"princier","word_nosc":"princier","lemma":"princier","pos":"ADJ"} ,
		{"word":"princiers","word_nosc":"princiers","lemma":"princier","pos":"ADJ"} ,
		{"word":"principal","word_nosc":"principal","lemma":"principal","pos":"ADJ"} ,
		{"word":"principale","word_nosc":"principale","lemma":"principal","pos":"ADJ"} ,
		{"word":"principales","word_nosc":"principales","lemma":"principal","pos":"ADJ"} ,
		{"word":"principaux","word_nosc":"principaux","lemma":"principal","pos":"ADJ"} ,
		{"word":"princière","word_nosc":"princiere","lemma":"princier","pos":"ADJ"} ,
		{"word":"princières","word_nosc":"princieres","lemma":"princier","pos":"ADJ"} ,
		{"word":"printanier","word_nosc":"printanier","lemma":"printanier","pos":"ADJ"} ,
		{"word":"printaniers","word_nosc":"printaniers","lemma":"printanier","pos":"ADJ"} ,
		{"word":"printanière","word_nosc":"printaniere","lemma":"printanier","pos":"ADJ"} ,
		{"word":"printanières","word_nosc":"printanieres","lemma":"printanier","pos":"ADJ"} ,
		{"word":"prioritaire","word_nosc":"prioritaire","lemma":"prioritaire","pos":"ADJ"} ,
		{"word":"prioritaires","word_nosc":"prioritaires","lemma":"prioritaire","pos":"ADJ"} ,
		{"word":"pris","word_nosc":"pris","lemma":"pris","pos":"ADJ"} ,
		{"word":"prise","word_nosc":"prise","lemma":"pris","pos":"ADJ"} ,
		{"word":"prises","word_nosc":"prises","lemma":"pris","pos":"ADJ"} ,
		{"word":"prismatique","word_nosc":"prismatique","lemma":"prismatique","pos":"ADJ"} ,
		{"word":"prismatiques","word_nosc":"prismatiques","lemma":"prismatique","pos":"ADJ"} ,
		{"word":"prisonnier","word_nosc":"prisonnier","lemma":"prisonnier","pos":"ADJ"} ,
		{"word":"prisonniers","word_nosc":"prisonniers","lemma":"prisonnier","pos":"ADJ"} ,
		{"word":"prisonnière","word_nosc":"prisonniere","lemma":"prisonnier","pos":"ADJ"} ,
		{"word":"prisonnières","word_nosc":"prisonnieres","lemma":"prisonnier","pos":"ADJ"} ,
		{"word":"privatif","word_nosc":"privatif","lemma":"privatif","pos":"ADJ"} ,
		{"word":"privatifs","word_nosc":"privatifs","lemma":"privatif","pos":"ADJ"} ,
		{"word":"privative","word_nosc":"privative","lemma":"privatif","pos":"ADJ"} ,
		{"word":"privatives","word_nosc":"privatives","lemma":"privatif","pos":"ADJ"} ,
		{"word":"privilégié","word_nosc":"privilegie","lemma":"privilégié","pos":"ADJ"} ,
		{"word":"privilégiée","word_nosc":"privilegiee","lemma":"privilégié","pos":"ADJ"} ,
		{"word":"privilégiées","word_nosc":"privilegiees","lemma":"privilégié","pos":"ADJ"} ,
		{"word":"privilégiés","word_nosc":"privilegies","lemma":"privilégié","pos":"ADJ"} ,
		{"word":"privé","word_nosc":"prive","lemma":"privé","pos":"ADJ"} ,
		{"word":"privée","word_nosc":"privee","lemma":"privé","pos":"ADJ"} ,
		{"word":"privées","word_nosc":"privees","lemma":"privé","pos":"ADJ"} ,
		{"word":"privés","word_nosc":"prives","lemma":"privé","pos":"ADJ"} ,
		{"word":"pro-occidental","word_nosc":"pro-occidental","lemma":"pro-occidental","pos":"ADJ"} ,
		{"word":"proactif","word_nosc":"proactif","lemma":"proactif","pos":"ADJ"} ,
		{"word":"probable","word_nosc":"probable","lemma":"probable","pos":"ADJ"} ,
		{"word":"probables","word_nosc":"probables","lemma":"probable","pos":"ADJ"} ,
		{"word":"probant","word_nosc":"probant","lemma":"probant","pos":"ADJ"} ,
		{"word":"probante","word_nosc":"probante","lemma":"probant","pos":"ADJ"} ,
		{"word":"probantes","word_nosc":"probantes","lemma":"probant","pos":"ADJ"} ,
		{"word":"probants","word_nosc":"probants","lemma":"probant","pos":"ADJ"} ,
		{"word":"probatoire","word_nosc":"probatoire","lemma":"probatoire","pos":"ADJ"} ,
		{"word":"probe","word_nosc":"probe","lemma":"probe","pos":"ADJ"} ,
		{"word":"probes","word_nosc":"probes","lemma":"probe","pos":"ADJ"} ,
		{"word":"problématique","word_nosc":"problematique","lemma":"problématique","pos":"ADJ"} ,
		{"word":"problématiques","word_nosc":"problematiques","lemma":"problématique","pos":"ADJ"} ,
		{"word":"processionnaire","word_nosc":"processionnaire","lemma":"processionnaire","pos":"ADJ"} ,
		{"word":"processionnelle","word_nosc":"processionnelle","lemma":"processionnel","pos":"ADJ"} ,
		{"word":"processionnelles","word_nosc":"processionnelles","lemma":"processionnel","pos":"ADJ"} ,
		{"word":"prochain","word_nosc":"prochain","lemma":"prochain","pos":"ADJ"} ,
		{"word":"prochaine","word_nosc":"prochaine","lemma":"prochain","pos":"ADJ"} ,
		{"word":"prochaines","word_nosc":"prochaines","lemma":"prochain","pos":"ADJ"} ,
		{"word":"prochains","word_nosc":"prochains","lemma":"prochain","pos":"ADJ"} ,
		{"word":"proche","word_nosc":"proche","lemma":"proche","pos":"ADJ"} ,
		{"word":"proche-oriental","word_nosc":"proche-oriental","lemma":"proche-oriental","pos":"ADJ"} ,
		{"word":"proches","word_nosc":"proches","lemma":"proche","pos":"ADJ"} ,
		{"word":"prochinois","word_nosc":"prochinois","lemma":"prochinois","pos":"ADJ"} ,
		{"word":"prochinoise","word_nosc":"prochinoise","lemma":"prochinois","pos":"ADJ"} ,
		{"word":"procommuniste","word_nosc":"procommuniste","lemma":"procommuniste","pos":"ADJ"} ,
		{"word":"procréateur","word_nosc":"procreateur","lemma":"procréateur","pos":"ADJ"} ,
		{"word":"procréative","word_nosc":"procreative","lemma":"procréatif","pos":"ADJ"} ,
		{"word":"procréatrice","word_nosc":"procreatrice","lemma":"procréateur","pos":"ADJ"} ,
		{"word":"procréatrices","word_nosc":"procreatrices","lemma":"procréateur","pos":"ADJ"} ,
		{"word":"procédural","word_nosc":"procedural","lemma":"procédural","pos":"ADJ"} ,
		{"word":"procédurale","word_nosc":"procedurale","lemma":"procédural","pos":"ADJ"} ,
		{"word":"procédurier","word_nosc":"procedurier","lemma":"procédurier","pos":"ADJ"} ,
		{"word":"procédurière","word_nosc":"proceduriere","lemma":"procédurier","pos":"ADJ"} ,
		{"word":"procédurières","word_nosc":"procedurieres","lemma":"procédurier","pos":"ADJ"} ,
		{"word":"prodigieuse","word_nosc":"prodigieuse","lemma":"prodigieux","pos":"ADJ"} ,
		{"word":"prodigieuses","word_nosc":"prodigieuses","lemma":"prodigieux","pos":"ADJ"} ,
		{"word":"prodigieux","word_nosc":"prodigieux","lemma":"prodigieux","pos":"ADJ"} ,
		{"word":"prodigue","word_nosc":"prodigue","lemma":"prodigue","pos":"ADJ"} ,
		{"word":"prodigues","word_nosc":"prodigues","lemma":"prodigue","pos":"ADJ"} ,
		{"word":"producteur","word_nosc":"producteur","lemma":"producteur","pos":"ADJ"} ,
		{"word":"producteurs","word_nosc":"producteurs","lemma":"producteur","pos":"ADJ"} ,
		{"word":"productif","word_nosc":"productif","lemma":"productif","pos":"ADJ"} ,
		{"word":"productifs","word_nosc":"productifs","lemma":"productif","pos":"ADJ"} ,
		{"word":"productive","word_nosc":"productive","lemma":"productif","pos":"ADJ"} ,
		{"word":"productives","word_nosc":"productives","lemma":"productif","pos":"ADJ"} ,
		{"word":"productrice","word_nosc":"productrice","lemma":"producteur","pos":"ADJ"} ,
		{"word":"productrices","word_nosc":"productrices","lemma":"producteur","pos":"ADJ"} ,
		{"word":"produit","word_nosc":"produit","lemma":"produit","pos":"ADJ"} ,
		{"word":"produite","word_nosc":"produite","lemma":"produit","pos":"ADJ"} ,
		{"word":"produites","word_nosc":"produites","lemma":"produit","pos":"ADJ"} ,
		{"word":"produits","word_nosc":"produits","lemma":"produit","pos":"ADJ"} ,
		{"word":"profanateur","word_nosc":"profanateur","lemma":"profanateur","pos":"ADJ"} ,
		{"word":"profanateurs","word_nosc":"profanateurs","lemma":"profanateur","pos":"ADJ"} ,
		{"word":"profanatoire","word_nosc":"profanatoire","lemma":"profanatoire","pos":"ADJ"} ,
		{"word":"profane","word_nosc":"profane","lemma":"profane","pos":"ADJ"} ,
		{"word":"profanes","word_nosc":"profanes","lemma":"profane","pos":"ADJ"} ,
		{"word":"professe","word_nosc":"professe","lemma":"professe","pos":"ADJ"} ,
		{"word":"professionnel","word_nosc":"professionnel","lemma":"professionnel","pos":"ADJ"} ,
		{"word":"professionnelle","word_nosc":"professionnelle","lemma":"professionnel","pos":"ADJ"} ,
		{"word":"professionnelles","word_nosc":"professionnelles","lemma":"professionnel","pos":"ADJ"} ,
		{"word":"professionnels","word_nosc":"professionnels","lemma":"professionnel","pos":"ADJ"} ,
		{"word":"professoral","word_nosc":"professoral","lemma":"professoral","pos":"ADJ"} ,
		{"word":"profitable","word_nosc":"profitable","lemma":"profitable","pos":"ADJ"} ,
		{"word":"profitables","word_nosc":"profitables","lemma":"profitable","pos":"ADJ"} ,
		{"word":"profitant","word_nosc":"profitant","lemma":"profitant","pos":"ADJ"} ,
		{"word":"profond","word_nosc":"profond","lemma":"profond","pos":"ADJ"} ,
		{"word":"profonde","word_nosc":"profonde","lemma":"profond","pos":"ADJ"} ,
		{"word":"profondes","word_nosc":"profondes","lemma":"profond","pos":"ADJ"} ,
		{"word":"profonds","word_nosc":"profonds","lemma":"profond","pos":"ADJ"} ,
		{"word":"profus","word_nosc":"profus","lemma":"profus","pos":"ADJ"} ,
		{"word":"profuse","word_nosc":"profuse","lemma":"profus","pos":"ADJ"} ,
		{"word":"profuses","word_nosc":"profuses","lemma":"profus","pos":"ADJ"} ,
		{"word":"prognathe","word_nosc":"prognathe","lemma":"prognathe","pos":"ADJ"} ,
		{"word":"programmable","word_nosc":"programmable","lemma":"programmable","pos":"ADJ"} ,
		{"word":"programmables","word_nosc":"programmables","lemma":"programmable","pos":"ADJ"} ,
		{"word":"programmateur","word_nosc":"programmateur","lemma":"programmateur","pos":"ADJ"} ,
		{"word":"programmatrice","word_nosc":"programmatrice","lemma":"programmateur","pos":"ADJ"} ,
		{"word":"programmé","word_nosc":"programme","lemma":"programmé","pos":"ADJ"} ,
		{"word":"programmée","word_nosc":"programmee","lemma":"programmé","pos":"ADJ"} ,
		{"word":"programmées","word_nosc":"programmees","lemma":"programmé","pos":"ADJ"} ,
		{"word":"programmés","word_nosc":"programmes","lemma":"programmé","pos":"ADJ"} ,
		{"word":"progressif","word_nosc":"progressif","lemma":"progressif","pos":"ADJ"} ,
		{"word":"progressiste","word_nosc":"progressiste","lemma":"progressiste","pos":"ADJ"} ,
		{"word":"progressistes","word_nosc":"progressistes","lemma":"progressiste","pos":"ADJ"} ,
		{"word":"progressive","word_nosc":"progressive","lemma":"progressif","pos":"ADJ"} ,
		{"word":"progressives","word_nosc":"progressives","lemma":"progressif","pos":"ADJ"} ,
		{"word":"prohibitive","word_nosc":"prohibitive","lemma":"prohibitif","pos":"ADJ"} ,
		{"word":"prohibé","word_nosc":"prohibe","lemma":"prohibé","pos":"ADJ"} ,
		{"word":"prohibée","word_nosc":"prohibee","lemma":"prohibé","pos":"ADJ"} ,
		{"word":"prohibées","word_nosc":"prohibees","lemma":"prohibé","pos":"ADJ"} ,
		{"word":"prohibés","word_nosc":"prohibes","lemma":"prohibé","pos":"ADJ"} ,
		{"word":"projectif","word_nosc":"projectif","lemma":"projectif","pos":"ADJ"} ,
		{"word":"prolifique","word_nosc":"prolifique","lemma":"prolifique","pos":"ADJ"} ,
		{"word":"prolifiques","word_nosc":"prolifiques","lemma":"prolifique","pos":"ADJ"} ,
		{"word":"prolifère","word_nosc":"prolifere","lemma":"prolifère","pos":"ADJ"} ,
		{"word":"proliférant","word_nosc":"proliferant","lemma":"proliférant","pos":"ADJ"} ,
		{"word":"proliférante","word_nosc":"proliferante","lemma":"proliférant","pos":"ADJ"} ,
		{"word":"proliférantes","word_nosc":"proliferantes","lemma":"proliférant","pos":"ADJ"} ,
		{"word":"proliférants","word_nosc":"proliferants","lemma":"proliférant","pos":"ADJ"} ,
		{"word":"prolixe","word_nosc":"prolixe","lemma":"prolixe","pos":"ADJ"} ,
		{"word":"prolixes","word_nosc":"prolixes","lemma":"prolixe","pos":"ADJ"} ,
		{"word":"prolongé","word_nosc":"prolonge","lemma":"prolongé","pos":"ADJ"} ,
		{"word":"prolongée","word_nosc":"prolongee","lemma":"prolongé","pos":"ADJ"} ,
		{"word":"prolongées","word_nosc":"prolongees","lemma":"prolongé","pos":"ADJ"} ,
		{"word":"prolongés","word_nosc":"prolonges","lemma":"prolongé","pos":"ADJ"} ,
		{"word":"prolétaire","word_nosc":"proletaire","lemma":"prolétaire","pos":"ADJ"} ,
		{"word":"prolétaires","word_nosc":"proletaires","lemma":"prolétaire","pos":"ADJ"} ,
		{"word":"prolétarien","word_nosc":"proletarien","lemma":"prolétarien","pos":"ADJ"} ,
		{"word":"prolétarienne","word_nosc":"proletarienne","lemma":"prolétarien","pos":"ADJ"} ,
		{"word":"prolétariennes","word_nosc":"proletariennes","lemma":"prolétarien","pos":"ADJ"} ,
		{"word":"prolétariens","word_nosc":"proletariens","lemma":"prolétarien","pos":"ADJ"} ,
		{"word":"prometteur","word_nosc":"prometteur","lemma":"prometteur","pos":"ADJ"} ,
		{"word":"prometteurs","word_nosc":"prometteurs","lemma":"prometteur","pos":"ADJ"} ,
		{"word":"prometteuse","word_nosc":"prometteuse","lemma":"prometteur","pos":"ADJ"} ,
		{"word":"prometteuses","word_nosc":"prometteuses","lemma":"prometteur","pos":"ADJ"} ,
		{"word":"promis","word_nosc":"promis","lemma":"promis","pos":"ADJ"} ,
		{"word":"promise","word_nosc":"promise","lemma":"promis","pos":"ADJ"} ,
		{"word":"promises","word_nosc":"promises","lemma":"promis","pos":"ADJ"} ,
		{"word":"promotionnel","word_nosc":"promotionnel","lemma":"promotionnel","pos":"ADJ"} ,
		{"word":"promotionnelle","word_nosc":"promotionnelle","lemma":"promotionnel","pos":"ADJ"} ,
		{"word":"promotionnelles","word_nosc":"promotionnelles","lemma":"promotionnel","pos":"ADJ"} ,
		{"word":"prompt","word_nosc":"prompt","lemma":"prompt","pos":"ADJ"} ,
		{"word":"prompte","word_nosc":"prompte","lemma":"prompt","pos":"ADJ"} ,
		{"word":"promptes","word_nosc":"promptes","lemma":"prompt","pos":"ADJ"} ,
		{"word":"prompts","word_nosc":"prompts","lemma":"prompt","pos":"ADJ"} ,
		{"word":"promu","word_nosc":"promu","lemma":"promu","pos":"ADJ"} ,
		{"word":"promue","word_nosc":"promue","lemma":"promu","pos":"ADJ"} ,
		{"word":"promues","word_nosc":"promues","lemma":"promu","pos":"ADJ"} ,
		{"word":"promus","word_nosc":"promus","lemma":"promu","pos":"ADJ"} ,
		{"word":"prométhéen","word_nosc":"prometheen","lemma":"prométhéen","pos":"ADJ"} ,
		{"word":"prométhéenne","word_nosc":"prometheenne","lemma":"prométhéen","pos":"ADJ"} ,
		{"word":"pronateurs","word_nosc":"pronateurs","lemma":"pronateur","pos":"ADJ"} ,
		{"word":"pronominal","word_nosc":"pronominal","lemma":"pronominal","pos":"ADJ"} ,
		{"word":"pronominale","word_nosc":"pronominale","lemma":"pronominal","pos":"ADJ"} ,
		{"word":"pronominaux","word_nosc":"pronominaux","lemma":"pronominal","pos":"ADJ"} ,
		{"word":"prononcé","word_nosc":"prononce","lemma":"prononcé","pos":"ADJ"} ,
		{"word":"prononcée","word_nosc":"prononcee","lemma":"prononcé","pos":"ADJ"} ,
		{"word":"prononcées","word_nosc":"prononcees","lemma":"prononcé","pos":"ADJ"} ,
		{"word":"prononcés","word_nosc":"prononces","lemma":"prononcé","pos":"ADJ"} ,
		{"word":"prononçable","word_nosc":"prononcable","lemma":"prononçable","pos":"ADJ"} ,
		{"word":"pronostique","word_nosc":"pronostique","lemma":"pronostique","pos":"ADJ"} ,
		{"word":"propagandistes","word_nosc":"propagandistes","lemma":"propagandiste","pos":"ADJ"} ,
		{"word":"prophylactique","word_nosc":"prophylactique","lemma":"prophylactique","pos":"ADJ"} ,
		{"word":"prophylactiques","word_nosc":"prophylactiques","lemma":"prophylactique","pos":"ADJ"} ,
		{"word":"prophétique","word_nosc":"prophetique","lemma":"prophétique","pos":"ADJ"} ,
		{"word":"prophétiques","word_nosc":"prophetiques","lemma":"prophétique","pos":"ADJ"} ,
		{"word":"propice","word_nosc":"propice","lemma":"propice","pos":"ADJ"} ,
		{"word":"propices","word_nosc":"propices","lemma":"propice","pos":"ADJ"} ,
		{"word":"propitiatoire","word_nosc":"propitiatoire","lemma":"propitiatoire","pos":"ADJ"} ,
		{"word":"propitiatoires","word_nosc":"propitiatoires","lemma":"propitiatoire","pos":"ADJ"} ,
		{"word":"proportionnel","word_nosc":"proportionnel","lemma":"proportionnel","pos":"ADJ"} ,
		{"word":"proportionnelle","word_nosc":"proportionnelle","lemma":"proportionnel","pos":"ADJ"} ,
		{"word":"proportionnelles","word_nosc":"proportionnelles","lemma":"proportionnel","pos":"ADJ"} ,
		{"word":"proportionné","word_nosc":"proportionne","lemma":"proportionné","pos":"ADJ"} ,
		{"word":"proportionnée","word_nosc":"proportionnee","lemma":"proportionné","pos":"ADJ"} ,
		{"word":"proportionnées","word_nosc":"proportionnees","lemma":"proportionné","pos":"ADJ"} ,
		{"word":"proportionnés","word_nosc":"proportionnes","lemma":"proportionné","pos":"ADJ"} ,
		{"word":"proposé","word_nosc":"propose","lemma":"proposé","pos":"ADJ"} ,
		{"word":"proposée","word_nosc":"proposee","lemma":"proposé","pos":"ADJ"} ,
		{"word":"proposées","word_nosc":"proposees","lemma":"proposé","pos":"ADJ"} ,
		{"word":"proposés","word_nosc":"proposes","lemma":"proposé","pos":"ADJ"} ,
		{"word":"propre","word_nosc":"propre","lemma":"propre","pos":"ADJ"} ,
		{"word":"propres","word_nosc":"propres","lemma":"propre","pos":"ADJ"} ,
		{"word":"propret","word_nosc":"propret","lemma":"propret","pos":"ADJ"} ,
		{"word":"proprets","word_nosc":"proprets","lemma":"propret","pos":"ADJ"} ,
		{"word":"proprette","word_nosc":"proprette","lemma":"propret","pos":"ADJ"} ,
		{"word":"proprettes","word_nosc":"proprettes","lemma":"propret","pos":"ADJ"} ,
		{"word":"propulsif","word_nosc":"propulsif","lemma":"propulsif","pos":"ADJ"} ,
		{"word":"prosaïque","word_nosc":"prosaique","lemma":"prosaïque","pos":"ADJ"} ,
		{"word":"prosaïques","word_nosc":"prosaiques","lemma":"prosaïque","pos":"ADJ"} ,
		{"word":"proscrit","word_nosc":"proscrit","lemma":"proscrit","pos":"ADJ"} ,
		{"word":"proscrite","word_nosc":"proscrite","lemma":"proscrit","pos":"ADJ"} ,
		{"word":"proscrites","word_nosc":"proscrites","lemma":"proscrit","pos":"ADJ"} ,
		{"word":"proscrits","word_nosc":"proscrits","lemma":"proscrit","pos":"ADJ"} ,
		{"word":"prosodique","word_nosc":"prosodique","lemma":"prosodique","pos":"ADJ"} ,
		{"word":"prosoviétique","word_nosc":"prosovietique","lemma":"prosoviétique","pos":"ADJ"} ,
		{"word":"prospecteur","word_nosc":"prospecteur","lemma":"prospecteur","pos":"ADJ"} ,
		{"word":"prospecteurs","word_nosc":"prospecteurs","lemma":"prospecteur","pos":"ADJ"} ,
		{"word":"prospectif","word_nosc":"prospectif","lemma":"prospectif","pos":"ADJ"} ,
		{"word":"prospective","word_nosc":"prospective","lemma":"prospectif","pos":"ADJ"} ,
		{"word":"prospère","word_nosc":"prospere","lemma":"prospère","pos":"ADJ"} ,
		{"word":"prospères","word_nosc":"prosperes","lemma":"prospère","pos":"ADJ"} ,
		{"word":"prostatique","word_nosc":"prostatique","lemma":"prostatique","pos":"ADJ"} ,
		{"word":"prosthétique","word_nosc":"prosthetique","lemma":"prosthétique","pos":"ADJ"} ,
		{"word":"prostitutionnel","word_nosc":"prostitutionnel","lemma":"prostitutionnel","pos":"ADJ"} ,
		{"word":"prostré","word_nosc":"prostre","lemma":"prostré","pos":"ADJ"} ,
		{"word":"prostrée","word_nosc":"prostree","lemma":"prostré","pos":"ADJ"} ,
		{"word":"prostrées","word_nosc":"prostrees","lemma":"prostré","pos":"ADJ"} ,
		{"word":"prostrés","word_nosc":"prostres","lemma":"prostré","pos":"ADJ"} ,
		{"word":"prosélytique","word_nosc":"proselytique","lemma":"prosélytique","pos":"ADJ"} ,
		{"word":"protecteur","word_nosc":"protecteur","lemma":"protecteur","pos":"ADJ"} ,
		{"word":"protecteurs","word_nosc":"protecteurs","lemma":"protecteur","pos":"ADJ"} ,
		{"word":"protectrice","word_nosc":"protectrice","lemma":"protecteur","pos":"ADJ"} ,
		{"word":"protectrices","word_nosc":"protectrices","lemma":"protecteur","pos":"ADJ"} ,
		{"word":"protestant","word_nosc":"protestant","lemma":"protestant","pos":"ADJ"} ,
		{"word":"protestante","word_nosc":"protestante","lemma":"protestant","pos":"ADJ"} ,
		{"word":"protestantes","word_nosc":"protestantes","lemma":"protestant","pos":"ADJ"} ,
		{"word":"protestants","word_nosc":"protestants","lemma":"protestant","pos":"ADJ"} ,
		{"word":"protestataires","word_nosc":"protestataires","lemma":"protestataire","pos":"ADJ"} ,
		{"word":"prothétique","word_nosc":"prothetique","lemma":"prothétique","pos":"ADJ"} ,
		{"word":"protidique","word_nosc":"protidique","lemma":"protidique","pos":"ADJ"} ,
		{"word":"protocolaire","word_nosc":"protocolaire","lemma":"protocolaire","pos":"ADJ"} ,
		{"word":"protocolaires","word_nosc":"protocolaires","lemma":"protocolaire","pos":"ADJ"} ,
		{"word":"protonique","word_nosc":"protonique","lemma":"protonique","pos":"ADJ"} ,
		{"word":"protoplasmique","word_nosc":"protoplasmique","lemma":"protoplasmique","pos":"ADJ"} ,
		{"word":"protubérant","word_nosc":"protuberant","lemma":"protubérant","pos":"ADJ"} ,
		{"word":"protubérante","word_nosc":"protuberante","lemma":"protubérant","pos":"ADJ"} ,
		{"word":"protubérantes","word_nosc":"protuberantes","lemma":"protubérant","pos":"ADJ"} ,
		{"word":"protubérants","word_nosc":"protuberants","lemma":"protubérant","pos":"ADJ"} ,
		{"word":"protégé","word_nosc":"protege","lemma":"protégé","pos":"ADJ"} ,
		{"word":"protégée","word_nosc":"protegee","lemma":"protégé","pos":"ADJ"} ,
		{"word":"protégées","word_nosc":"protegees","lemma":"protégé","pos":"ADJ"} ,
		{"word":"protégés","word_nosc":"proteges","lemma":"protégé","pos":"ADJ"} ,
		{"word":"protéiforme","word_nosc":"proteiforme","lemma":"protéiforme","pos":"ADJ"} ,
		{"word":"protéinique","word_nosc":"proteinique","lemma":"protéinique","pos":"ADJ"} ,
		{"word":"protéiné","word_nosc":"proteine","lemma":"protéiné","pos":"ADJ"} ,
		{"word":"protéinée","word_nosc":"proteinee","lemma":"protéiné","pos":"ADJ"} ,
		{"word":"protéique","word_nosc":"proteique","lemma":"protéique","pos":"ADJ"} ,
		{"word":"protéolytique","word_nosc":"proteolytique","lemma":"protéolytique","pos":"ADJ"} ,
		{"word":"proustien","word_nosc":"proustien","lemma":"proustien","pos":"ADJ"} ,
		{"word":"proustienne","word_nosc":"proustienne","lemma":"proustien","pos":"ADJ"} ,
		{"word":"prouvable","word_nosc":"prouvable","lemma":"prouvable","pos":"ADJ"} ,
		{"word":"prouvé","word_nosc":"prouve","lemma":"prouvé","pos":"ADJ"} ,
		{"word":"prouvée","word_nosc":"prouvee","lemma":"prouvé","pos":"ADJ"} ,
		{"word":"prouvées","word_nosc":"prouvees","lemma":"prouvé","pos":"ADJ"} ,
		{"word":"prouvés","word_nosc":"prouves","lemma":"prouvé","pos":"ADJ"} ,
		{"word":"provençal","word_nosc":"provencal","lemma":"provençal","pos":"ADJ"} ,
		{"word":"provençale","word_nosc":"provencale","lemma":"provençal","pos":"ADJ"} ,
		{"word":"provençales","word_nosc":"provencales","lemma":"provençal","pos":"ADJ"} ,
		{"word":"provençaux","word_nosc":"provencaux","lemma":"provençal","pos":"ADJ"} ,
		{"word":"proverbial","word_nosc":"proverbial","lemma":"proverbial","pos":"ADJ"} ,
		{"word":"proverbiale","word_nosc":"proverbiale","lemma":"proverbial","pos":"ADJ"} ,
		{"word":"providentiel","word_nosc":"providentiel","lemma":"providentiel","pos":"ADJ"} ,
		{"word":"providentielle","word_nosc":"providentielle","lemma":"providentiel","pos":"ADJ"} ,
		{"word":"providentielles","word_nosc":"providentielles","lemma":"providentiel","pos":"ADJ"} ,
		{"word":"providentiels","word_nosc":"providentiels","lemma":"providentiel","pos":"ADJ"} ,
		{"word":"provietnamien","word_nosc":"provietnamien","lemma":"provietnamien","pos":"ADJ"} ,
		{"word":"provincial","word_nosc":"provincial","lemma":"provincial","pos":"ADJ"} ,
		{"word":"provinciale","word_nosc":"provinciale","lemma":"provincial","pos":"ADJ"} ,
		{"word":"provinciales","word_nosc":"provinciales","lemma":"provincial","pos":"ADJ"} ,
		{"word":"provinciaux","word_nosc":"provinciaux","lemma":"provincial","pos":"ADJ"} ,
		{"word":"provisionnel","word_nosc":"provisionnel","lemma":"provisionnel","pos":"ADJ"} ,
		{"word":"provisoire","word_nosc":"provisoire","lemma":"provisoire","pos":"ADJ"} ,
		{"word":"provisoires","word_nosc":"provisoires","lemma":"provisoire","pos":"ADJ"} ,
		{"word":"provocant","word_nosc":"provocant","lemma":"provocant","pos":"ADJ"} ,
		{"word":"provocante","word_nosc":"provocante","lemma":"provocant","pos":"ADJ"} ,
		{"word":"provocantes","word_nosc":"provocantes","lemma":"provocant","pos":"ADJ"} ,
		{"word":"provocants","word_nosc":"provocants","lemma":"provocant","pos":"ADJ"} ,
		{"word":"provocateur","word_nosc":"provocateur","lemma":"provocateur","pos":"ADJ"} ,
		{"word":"provocateurs","word_nosc":"provocateurs","lemma":"provocateur","pos":"ADJ"} ,
		{"word":"provocatrice","word_nosc":"provocatrice","lemma":"provocateur","pos":"ADJ"} ,
		{"word":"provoqué","word_nosc":"provoque","lemma":"provoqué","pos":"ADJ"} ,
		{"word":"provoquée","word_nosc":"provoquee","lemma":"provoqué","pos":"ADJ"} ,
		{"word":"provoquées","word_nosc":"provoquees","lemma":"provoqué","pos":"ADJ"} ,
		{"word":"provoqués","word_nosc":"provoques","lemma":"provoqué","pos":"ADJ"} ,
		{"word":"proximal","word_nosc":"proximal","lemma":"proximal","pos":"ADJ"} ,
		{"word":"proximale","word_nosc":"proximale","lemma":"proximal","pos":"ADJ"} ,
		{"word":"proéminent","word_nosc":"proeminent","lemma":"proéminent","pos":"ADJ"} ,
		{"word":"proéminente","word_nosc":"proeminente","lemma":"proéminent","pos":"ADJ"} ,
		{"word":"proéminentes","word_nosc":"proeminentes","lemma":"proéminent","pos":"ADJ"} ,
		{"word":"proéminents","word_nosc":"proeminents","lemma":"proéminent","pos":"ADJ"} ,
		{"word":"prude","word_nosc":"prude","lemma":"prude","pos":"ADJ"} ,
		{"word":"prudent","word_nosc":"prudent","lemma":"prudent","pos":"ADJ"} ,
		{"word":"prudente","word_nosc":"prudente","lemma":"prudent","pos":"ADJ"} ,
		{"word":"prudentes","word_nosc":"prudentes","lemma":"prudent","pos":"ADJ"} ,
		{"word":"prudents","word_nosc":"prudents","lemma":"prudent","pos":"ADJ"} ,
		{"word":"prudes","word_nosc":"prudes","lemma":"prude","pos":"ADJ"} ,
		{"word":"prudhommesque","word_nosc":"prudhommesque","lemma":"prudhommesque","pos":"ADJ"} ,
		{"word":"prudhommesques","word_nosc":"prudhommesques","lemma":"prudhommesque","pos":"ADJ"} ,
		{"word":"prune","word_nosc":"prune","lemma":"prune","pos":"ADJ"} ,
		{"word":"prussien","word_nosc":"prussien","lemma":"prussien","pos":"ADJ"} ,
		{"word":"prussienne","word_nosc":"prussienne","lemma":"prussien","pos":"ADJ"} ,
		{"word":"prussiennes","word_nosc":"prussiennes","lemma":"prussien","pos":"ADJ"} ,
		{"word":"prussiens","word_nosc":"prussiens","lemma":"prussien","pos":"ADJ"} ,
		{"word":"prussique","word_nosc":"prussique","lemma":"prussique","pos":"ADJ"} ,
		{"word":"pré-établi","word_nosc":"pre-etabli","lemma":"pré-établi","pos":"ADJ"} ,
		{"word":"préalable","word_nosc":"prealable","lemma":"préalable","pos":"ADJ"} ,
		{"word":"préalables","word_nosc":"prealables","lemma":"préalable","pos":"ADJ"} ,
		{"word":"précaire","word_nosc":"precaire","lemma":"précaire","pos":"ADJ"} ,
		{"word":"précaires","word_nosc":"precaires","lemma":"précaire","pos":"ADJ"} ,
		{"word":"précambrien","word_nosc":"precambrien","lemma":"précambrien","pos":"ADJ"} ,
		{"word":"précambrienne","word_nosc":"precambrienne","lemma":"précambrien","pos":"ADJ"} ,
		{"word":"précautionneuse","word_nosc":"precautionneuse","lemma":"précautionneux","pos":"ADJ"} ,
		{"word":"précautionneuses","word_nosc":"precautionneuses","lemma":"précautionneux","pos":"ADJ"} ,
		{"word":"précautionneux","word_nosc":"precautionneux","lemma":"précautionneux","pos":"ADJ"} ,
		{"word":"précieuse","word_nosc":"precieuse","lemma":"précieux","pos":"ADJ"} ,
		{"word":"précieuses","word_nosc":"precieuses","lemma":"précieux","pos":"ADJ"} ,
		{"word":"précieux","word_nosc":"precieux","lemma":"précieux","pos":"ADJ"} ,
		{"word":"précipité","word_nosc":"precipite","lemma":"précipité","pos":"ADJ"} ,
		{"word":"précipitée","word_nosc":"precipitee","lemma":"précipité","pos":"ADJ"} ,
		{"word":"précipitées","word_nosc":"precipitees","lemma":"précipité","pos":"ADJ"} ,
		{"word":"précipités","word_nosc":"precipites","lemma":"précipité","pos":"ADJ"} ,
		{"word":"précis","word_nosc":"precis","lemma":"précis","pos":"ADJ"} ,
		{"word":"précise","word_nosc":"precise","lemma":"précis","pos":"ADJ"} ,
		{"word":"précises","word_nosc":"precises","lemma":"précis","pos":"ADJ"} ,
		{"word":"précité","word_nosc":"precite","lemma":"précité","pos":"ADJ"} ,
		{"word":"précitée","word_nosc":"precitee","lemma":"précité","pos":"ADJ"} ,
		{"word":"précitées","word_nosc":"precitees","lemma":"précité","pos":"ADJ"} ,
		{"word":"précités","word_nosc":"precites","lemma":"précité","pos":"ADJ"} ,
		{"word":"précoce","word_nosc":"precoce","lemma":"précoce","pos":"ADJ"} ,
		{"word":"précoces","word_nosc":"precoces","lemma":"précoce","pos":"ADJ"} ,
		{"word":"précolombien","word_nosc":"precolombien","lemma":"précolombien","pos":"ADJ"} ,
		{"word":"précolombienne","word_nosc":"precolombienne","lemma":"précolombien","pos":"ADJ"} ,
		{"word":"précolombiennes","word_nosc":"precolombiennes","lemma":"précolombien","pos":"ADJ"} ,
		{"word":"préconçu","word_nosc":"preconcu","lemma":"préconçu","pos":"ADJ"} ,
		{"word":"préconçue","word_nosc":"preconcue","lemma":"préconçu","pos":"ADJ"} ,
		{"word":"préconçues","word_nosc":"preconcues","lemma":"préconçu","pos":"ADJ"} ,
		{"word":"précuit","word_nosc":"precuit","lemma":"précuit","pos":"ADJ"} ,
		{"word":"précuites","word_nosc":"precuites","lemma":"précuit","pos":"ADJ"} ,
		{"word":"précurseur","word_nosc":"precurseur","lemma":"précurseur","pos":"ADJ"} ,
		{"word":"précurseurs","word_nosc":"precurseurs","lemma":"précurseur","pos":"ADJ"} ,
		{"word":"précédent","word_nosc":"precedent","lemma":"précédent","pos":"ADJ"} ,
		{"word":"précédente","word_nosc":"precedente","lemma":"précédent","pos":"ADJ"} ,
		{"word":"précédentes","word_nosc":"precedentes","lemma":"précédent","pos":"ADJ"} ,
		{"word":"précédents","word_nosc":"precedents","lemma":"précédent","pos":"ADJ"} ,
		{"word":"prédateur","word_nosc":"predateur","lemma":"prédateur","pos":"ADJ"} ,
		{"word":"prédateurs","word_nosc":"predateurs","lemma":"prédateur","pos":"ADJ"} ,
		{"word":"prédatrice","word_nosc":"predatrice","lemma":"prédateur","pos":"ADJ"} ,
		{"word":"prédestiné","word_nosc":"predestine","lemma":"prédestiné","pos":"ADJ"} ,
		{"word":"prédestinée","word_nosc":"predestinee","lemma":"prédestiné","pos":"ADJ"} ,
		{"word":"prédestinées","word_nosc":"predestinees","lemma":"prédestiné","pos":"ADJ"} ,
		{"word":"prédestinés","word_nosc":"predestines","lemma":"prédestiné","pos":"ADJ"} ,
		{"word":"prédictible","word_nosc":"predictible","lemma":"prédictible","pos":"ADJ"} ,
		{"word":"prédictif","word_nosc":"predictif","lemma":"prédictif","pos":"ADJ"} ,
		{"word":"prédictive","word_nosc":"predictive","lemma":"prédictif","pos":"ADJ"} ,
		{"word":"prédigéré","word_nosc":"predigere","lemma":"prédigéré","pos":"ADJ"} ,
		{"word":"prédit","word_nosc":"predit","lemma":"prédit","pos":"ADJ"} ,
		{"word":"prédite","word_nosc":"predite","lemma":"prédit","pos":"ADJ"} ,
		{"word":"prédominant","word_nosc":"predominant","lemma":"prédominant","pos":"ADJ"} ,
		{"word":"prédominante","word_nosc":"predominante","lemma":"prédominant","pos":"ADJ"} ,
		{"word":"prédécoupée","word_nosc":"predecoupee","lemma":"prédécoupé","pos":"ADJ"} ,
		{"word":"préemptive","word_nosc":"preemptive","lemma":"préemptif","pos":"ADJ"} ,
		{"word":"préencollé","word_nosc":"preencolle","lemma":"préencollé","pos":"ADJ"} ,
		{"word":"préexistant","word_nosc":"preexistant","lemma":"préexistant","pos":"ADJ"} ,
		{"word":"préexistante","word_nosc":"preexistante","lemma":"préexistant","pos":"ADJ"} ,
		{"word":"préexistants","word_nosc":"preexistants","lemma":"préexistant","pos":"ADJ"} ,
		{"word":"préfabriqué","word_nosc":"prefabrique","lemma":"préfabriqué","pos":"ADJ"} ,
		{"word":"préfabriquée","word_nosc":"prefabriquee","lemma":"préfabriqué","pos":"ADJ"} ,
		{"word":"préfabriquées","word_nosc":"prefabriquees","lemma":"préfabriqué","pos":"ADJ"} ,
		{"word":"préfabriqués","word_nosc":"prefabriques","lemma":"préfabriqué","pos":"ADJ"} ,
		{"word":"préfectorale","word_nosc":"prefectorale","lemma":"préfectoral","pos":"ADJ"} ,
		{"word":"préfix","word_nosc":"prefix","lemma":"préfix","pos":"ADJ"} ,
		{"word":"préfrontal","word_nosc":"prefrontal","lemma":"préfrontal","pos":"ADJ"} ,
		{"word":"préfrontale","word_nosc":"prefrontale","lemma":"préfrontal","pos":"ADJ"} ,
		{"word":"préférable","word_nosc":"preferable","lemma":"préférable","pos":"ADJ"} ,
		{"word":"préférables","word_nosc":"preferables","lemma":"préférable","pos":"ADJ"} ,
		{"word":"préférentiel","word_nosc":"preferentiel","lemma":"préférentiel","pos":"ADJ"} ,
		{"word":"préférentielle","word_nosc":"preferentielle","lemma":"préférentiel","pos":"ADJ"} ,
		{"word":"préféré","word_nosc":"prefere","lemma":"préféré","pos":"ADJ"} ,
		{"word":"préférée","word_nosc":"preferee","lemma":"préféré","pos":"ADJ"} ,
		{"word":"préférées","word_nosc":"preferees","lemma":"préféré","pos":"ADJ"} ,
		{"word":"préférés","word_nosc":"preferes","lemma":"préféré","pos":"ADJ"} ,
		{"word":"prégnante","word_nosc":"pregnante","lemma":"prégnant","pos":"ADJ"} ,
		{"word":"préhenseur","word_nosc":"prehenseur","lemma":"préhenseur","pos":"ADJ"} ,
		{"word":"préhensile","word_nosc":"prehensile","lemma":"préhensile","pos":"ADJ"} ,
		{"word":"préhensives","word_nosc":"prehensives","lemma":"préhensif","pos":"ADJ"} ,
		{"word":"préhistorique","word_nosc":"prehistorique","lemma":"préhistorique","pos":"ADJ"} ,
		{"word":"préhistoriques","word_nosc":"prehistoriques","lemma":"préhistorique","pos":"ADJ"} ,
		{"word":"préindustriel","word_nosc":"preindustriel","lemma":"préindustriel","pos":"ADJ"} ,
		{"word":"préjudiciable","word_nosc":"prejudiciable","lemma":"préjudiciable","pos":"ADJ"} ,
		{"word":"préjudiciables","word_nosc":"prejudiciables","lemma":"préjudiciable","pos":"ADJ"} ,
		{"word":"préliminaire","word_nosc":"preliminaire","lemma":"préliminaire","pos":"ADJ"} ,
		{"word":"préliminaires","word_nosc":"preliminaires","lemma":"préliminaire","pos":"ADJ"} ,
		{"word":"prématuré","word_nosc":"premature","lemma":"prématuré","pos":"ADJ"} ,
		{"word":"prématurée","word_nosc":"prematuree","lemma":"prématuré","pos":"ADJ"} ,
		{"word":"prématurées","word_nosc":"prematurees","lemma":"prématuré","pos":"ADJ"} ,
		{"word":"prématurés","word_nosc":"prematures","lemma":"prématuré","pos":"ADJ"} ,
		{"word":"prémenstruel","word_nosc":"premenstruel","lemma":"prémenstruel","pos":"ADJ"} ,
		{"word":"prémenstruelle","word_nosc":"premenstruelle","lemma":"prémenstruel","pos":"ADJ"} ,
		{"word":"prémonitoire","word_nosc":"premonitoire","lemma":"prémonitoire","pos":"ADJ"} ,
		{"word":"prémonitoires","word_nosc":"premonitoires","lemma":"prémonitoire","pos":"ADJ"} ,
		{"word":"prémontrés","word_nosc":"premontres","lemma":"prémontré","pos":"ADJ"} ,
		{"word":"prémédité","word_nosc":"premedite","lemma":"prémédité","pos":"ADJ"} ,
		{"word":"préméditée","word_nosc":"premeditee","lemma":"prémédité","pos":"ADJ"} ,
		{"word":"préméditées","word_nosc":"premeditees","lemma":"prémédité","pos":"ADJ"} ,
		{"word":"prémédités","word_nosc":"premedites","lemma":"prémédité","pos":"ADJ"} ,
		{"word":"prénatal","word_nosc":"prenatal","lemma":"prénatal","pos":"ADJ"} ,
		{"word":"prénatale","word_nosc":"prenatale","lemma":"prénatal","pos":"ADJ"} ,
		{"word":"prénatales","word_nosc":"prenatales","lemma":"prénatal","pos":"ADJ"} ,
		{"word":"prénataux","word_nosc":"prenataux","lemma":"prénatal","pos":"ADJ"} ,
		{"word":"prénommé","word_nosc":"prenomme","lemma":"prénommé","pos":"ADJ"} ,
		{"word":"prénommée","word_nosc":"prenommee","lemma":"prénommé","pos":"ADJ"} ,
		{"word":"prénuptial","word_nosc":"prenuptial","lemma":"prénuptial","pos":"ADJ"} ,
		{"word":"prénuptiale","word_nosc":"prenuptiale","lemma":"prénuptial","pos":"ADJ"} ,
		{"word":"préoccupant","word_nosc":"preoccupant","lemma":"préoccupant","pos":"ADJ"} ,
		{"word":"préoccupante","word_nosc":"preoccupante","lemma":"préoccupant","pos":"ADJ"} ,
		{"word":"préoccupantes","word_nosc":"preoccupantes","lemma":"préoccupant","pos":"ADJ"} ,
		{"word":"préoccupé","word_nosc":"preoccupe","lemma":"préoccupé","pos":"ADJ"} ,
		{"word":"préoccupée","word_nosc":"preoccupee","lemma":"préoccupé","pos":"ADJ"} ,
		{"word":"préoccupées","word_nosc":"preoccupees","lemma":"préoccupé","pos":"ADJ"} ,
		{"word":"préoccupés","word_nosc":"preoccupes","lemma":"préoccupé","pos":"ADJ"} ,
		{"word":"préopératoire","word_nosc":"preoperatoire","lemma":"préopératoire","pos":"ADJ"} ,
		{"word":"préparatoire","word_nosc":"preparatoire","lemma":"préparatoire","pos":"ADJ"} ,
		{"word":"préparatoires","word_nosc":"preparatoires","lemma":"préparatoire","pos":"ADJ"} ,
		{"word":"prépondérant","word_nosc":"preponderant","lemma":"prépondérant","pos":"ADJ"} ,
		{"word":"prépondérante","word_nosc":"preponderante","lemma":"prépondérant","pos":"ADJ"} ,
		{"word":"prépondérants","word_nosc":"preponderants","lemma":"prépondérant","pos":"ADJ"} ,
		{"word":"préposé","word_nosc":"prepose","lemma":"préposé","pos":"ADJ"} ,
		{"word":"préposée","word_nosc":"preposee","lemma":"préposé","pos":"ADJ"} ,
		{"word":"préposées","word_nosc":"preposees","lemma":"préposé","pos":"ADJ"} ,
		{"word":"préposés","word_nosc":"preposes","lemma":"préposé","pos":"ADJ"} ,
		{"word":"préprogrammé","word_nosc":"preprogramme","lemma":"préprogrammé","pos":"ADJ"} ,
		{"word":"préprogrammée","word_nosc":"preprogrammee","lemma":"préprogrammé","pos":"ADJ"} ,
		{"word":"prépubère","word_nosc":"prepubere","lemma":"prépubère","pos":"ADJ"} ,
		{"word":"préraphaélite","word_nosc":"preraphaelite","lemma":"préraphaélite","pos":"ADJ"} ,
		{"word":"préroman","word_nosc":"preroman","lemma":"préroman","pos":"ADJ"} ,
		{"word":"prérévolutionnaire","word_nosc":"prerevolutionnaire","lemma":"prérévolutionnaire","pos":"ADJ"} ,
		{"word":"préscolaire","word_nosc":"prescolaire","lemma":"préscolaire","pos":"ADJ"} ,
		{"word":"préscolaires","word_nosc":"prescolaires","lemma":"préscolaire","pos":"ADJ"} ,
		{"word":"présent","word_nosc":"present","lemma":"présent","pos":"ADJ"} ,
		{"word":"présentable","word_nosc":"presentable","lemma":"présentable","pos":"ADJ"} ,
		{"word":"présentables","word_nosc":"presentables","lemma":"présentable","pos":"ADJ"} ,
		{"word":"présente","word_nosc":"presente","lemma":"présent","pos":"ADJ"} ,
		{"word":"présentes","word_nosc":"presentes","lemma":"présent","pos":"ADJ"} ,
		{"word":"présents","word_nosc":"presents","lemma":"présent","pos":"ADJ"} ,
		{"word":"préservatif","word_nosc":"preservatif","lemma":"préservatif","pos":"ADJ"} ,
		{"word":"préservatifs","word_nosc":"preservatifs","lemma":"préservatif","pos":"ADJ"} ,
		{"word":"présidentiable","word_nosc":"presidentiable","lemma":"présidentiable","pos":"ADJ"} ,
		{"word":"présidentiables","word_nosc":"presidentiables","lemma":"présidentiable","pos":"ADJ"} ,
		{"word":"présidentiel","word_nosc":"presidentiel","lemma":"présidentiel","pos":"ADJ"} ,
		{"word":"présidentielle","word_nosc":"presidentielle","lemma":"présidentiel","pos":"ADJ"} ,
		{"word":"présidentielles","word_nosc":"presidentielles","lemma":"présidentiel","pos":"ADJ"} ,
		{"word":"présidentiels","word_nosc":"presidentiels","lemma":"présidentiel","pos":"ADJ"} ,
		{"word":"présocratique","word_nosc":"presocratique","lemma":"présocratique","pos":"ADJ"} ,
		{"word":"présocratiques","word_nosc":"presocratiques","lemma":"présocratique","pos":"ADJ"} ,
		{"word":"présomptif","word_nosc":"presomptif","lemma":"présomptif","pos":"ADJ"} ,
		{"word":"présomptive","word_nosc":"presomptive","lemma":"présomptif","pos":"ADJ"} ,
		{"word":"présomptueuse","word_nosc":"presomptueuse","lemma":"présomptueux","pos":"ADJ"} ,
		{"word":"présomptueuses","word_nosc":"presomptueuses","lemma":"présomptueux","pos":"ADJ"} ,
		{"word":"présomptueux","word_nosc":"presomptueux","lemma":"présomptueux","pos":"ADJ"} ,
		{"word":"présumables","word_nosc":"presumables","lemma":"présumable","pos":"ADJ"} ,
		{"word":"présumé","word_nosc":"presume","lemma":"présumé","pos":"ADJ"} ,
		{"word":"présumée","word_nosc":"presumee","lemma":"présumé","pos":"ADJ"} ,
		{"word":"présumées","word_nosc":"presumees","lemma":"présumé","pos":"ADJ"} ,
		{"word":"présumés","word_nosc":"presumes","lemma":"présumé","pos":"ADJ"} ,
		{"word":"préséniles","word_nosc":"preseniles","lemma":"présénile","pos":"ADJ"} ,
		{"word":"prétendant","word_nosc":"pretendant","lemma":"prétendant","pos":"ADJ"} ,
		{"word":"prétendants","word_nosc":"pretendants","lemma":"prétendant","pos":"ADJ"} ,
		{"word":"prétendu","word_nosc":"pretendu","lemma":"prétendu","pos":"ADJ"} ,
		{"word":"prétendue","word_nosc":"pretendue","lemma":"prétendu","pos":"ADJ"} ,
		{"word":"prétendues","word_nosc":"pretendues","lemma":"prétendu","pos":"ADJ"} ,
		{"word":"prétendus","word_nosc":"pretendus","lemma":"prétendu","pos":"ADJ"} ,
		{"word":"prétentiard","word_nosc":"pretentiard","lemma":"prétentiard","pos":"ADJ"} ,
		{"word":"prétentieuse","word_nosc":"pretentieuse","lemma":"prétentieux","pos":"ADJ"} ,
		{"word":"prétentieuses","word_nosc":"pretentieuses","lemma":"prétentieux","pos":"ADJ"} ,
		{"word":"prétentieux","word_nosc":"pretentieux","lemma":"prétentieux","pos":"ADJ"} ,
		{"word":"préternaturel","word_nosc":"preternaturel","lemma":"préternaturel","pos":"ADJ"} ,
		{"word":"prétorienne","word_nosc":"pretorienne","lemma":"prétorien","pos":"ADJ"} ,
		{"word":"prétoriennes","word_nosc":"pretoriennes","lemma":"prétorien","pos":"ADJ"} ,
		{"word":"prétoriens","word_nosc":"pretoriens","lemma":"prétorien","pos":"ADJ"} ,
		{"word":"prévalent","word_nosc":"prevalent","lemma":"prévalent","pos":"ADJ"} ,
		{"word":"prévenant","word_nosc":"prevenant","lemma":"prévenant","pos":"ADJ"} ,
		{"word":"prévenante","word_nosc":"prevenante","lemma":"prévenant","pos":"ADJ"} ,
		{"word":"prévenants","word_nosc":"prevenants","lemma":"prévenant","pos":"ADJ"} ,
		{"word":"préventif","word_nosc":"preventif","lemma":"préventif","pos":"ADJ"} ,
		{"word":"préventive","word_nosc":"preventive","lemma":"préventif","pos":"ADJ"} ,
		{"word":"préventives","word_nosc":"preventives","lemma":"préventif","pos":"ADJ"} ,
		{"word":"prévenu","word_nosc":"prevenu","lemma":"prévenu","pos":"ADJ"} ,
		{"word":"prévenue","word_nosc":"prevenue","lemma":"prévenu","pos":"ADJ"} ,
		{"word":"prévenues","word_nosc":"prevenues","lemma":"prévenu","pos":"ADJ"} ,
		{"word":"prévenus","word_nosc":"prevenus","lemma":"prévenu","pos":"ADJ"} ,
		{"word":"prévisible","word_nosc":"previsible","lemma":"prévisible","pos":"ADJ"} ,
		{"word":"prévisibles","word_nosc":"previsibles","lemma":"prévisible","pos":"ADJ"} ,
		{"word":"prévisionnel","word_nosc":"previsionnel","lemma":"prévisionnel","pos":"ADJ"} ,
		{"word":"prévisionnelles","word_nosc":"previsionnelles","lemma":"prévisionnel","pos":"ADJ"} ,
		{"word":"prévisionnels","word_nosc":"previsionnels","lemma":"prévisionnel","pos":"ADJ"} ,
		{"word":"prévoyant","word_nosc":"prevoyant","lemma":"prévoyant","pos":"ADJ"} ,
		{"word":"prévoyante","word_nosc":"prevoyante","lemma":"prévoyant","pos":"ADJ"} ,
		{"word":"prévoyantes","word_nosc":"prevoyantes","lemma":"prévoyant","pos":"ADJ"} ,
		{"word":"prévoyants","word_nosc":"prevoyants","lemma":"prévoyant","pos":"ADJ"} ,
		{"word":"préélectoral","word_nosc":"preelectoral","lemma":"préélectoral","pos":"ADJ"} ,
		{"word":"préélectorale","word_nosc":"preelectorale","lemma":"préélectoral","pos":"ADJ"} ,
		{"word":"prééminente","word_nosc":"preeminente","lemma":"prééminent","pos":"ADJ"} ,
		{"word":"préétabli","word_nosc":"preetabli","lemma":"préétabli","pos":"ADJ"} ,
		{"word":"préétablie","word_nosc":"preetablie","lemma":"préétabli","pos":"ADJ"} ,
		{"word":"préétablies","word_nosc":"preetablies","lemma":"préétabli","pos":"ADJ"} ,
		{"word":"préétablis","word_nosc":"preetablis","lemma":"préétabli","pos":"ADJ"} ,
		{"word":"prêt","word_nosc":"pret","lemma":"prêt","pos":"ADJ"} ,
		{"word":"prête","word_nosc":"prete","lemma":"prêt","pos":"ADJ"} ,
		{"word":"prêtes","word_nosc":"pretes","lemma":"prêt","pos":"ADJ"} ,
		{"word":"prêteur","word_nosc":"preteur","lemma":"prêteur","pos":"ADJ"} ,
		{"word":"prêteurs","word_nosc":"preteurs","lemma":"prêteur","pos":"ADJ"} ,
		{"word":"prêteuse","word_nosc":"preteuse","lemma":"prêteur","pos":"ADJ"} ,
		{"word":"prêts","word_nosc":"prets","lemma":"prêt","pos":"ADJ"} ,
		{"word":"prêté","word_nosc":"prete","lemma":"prêté","pos":"ADJ"} ,
		{"word":"prêtée","word_nosc":"pretee","lemma":"prêté","pos":"ADJ"} ,
		{"word":"prêtées","word_nosc":"pretees","lemma":"prêté","pos":"ADJ"} ,
		{"word":"prêtés","word_nosc":"pretes","lemma":"prêté","pos":"ADJ"} ,
		{"word":"pseudobulbaire","word_nosc":"pseudobulbaire","lemma":"pseudobulbaire","pos":"ADJ"} ,
		{"word":"psychanalytique","word_nosc":"psychanalytique","lemma":"psychanalytique","pos":"ADJ"} ,
		{"word":"psychanalytiques","word_nosc":"psychanalytiques","lemma":"psychanalytique","pos":"ADJ"} ,
		{"word":"psychasthéniques","word_nosc":"psychastheniques","lemma":"psychasthénique","pos":"ADJ"} ,
		{"word":"psychiatrique","word_nosc":"psychiatrique","lemma":"psychiatrique","pos":"ADJ"} ,
		{"word":"psychiatriques","word_nosc":"psychiatriques","lemma":"psychiatrique","pos":"ADJ"} ,
		{"word":"psychique","word_nosc":"psychique","lemma":"psychique","pos":"ADJ"} ,
		{"word":"psychiques","word_nosc":"psychiques","lemma":"psychique","pos":"ADJ"} ,
		{"word":"psychodysleptique","word_nosc":"psychodysleptique","lemma":"psychodysleptique","pos":"ADJ"} ,
		{"word":"psychogène","word_nosc":"psychogene","lemma":"psychogène","pos":"ADJ"} ,
		{"word":"psychogènes","word_nosc":"psychogenes","lemma":"psychogène","pos":"ADJ"} ,
		{"word":"psycholinguistique","word_nosc":"psycholinguistique","lemma":"psycholinguistique","pos":"ADJ"} ,
		{"word":"psychologique","word_nosc":"psychologique","lemma":"psychologique","pos":"ADJ"} ,
		{"word":"psychologiques","word_nosc":"psychologiques","lemma":"psychologique","pos":"ADJ"} ,
		{"word":"psychomotrice","word_nosc":"psychomotrice","lemma":"psychomoteur","pos":"ADJ"} ,
		{"word":"psychométrique","word_nosc":"psychometrique","lemma":"psychométrique","pos":"ADJ"} ,
		{"word":"psychopathique","word_nosc":"psychopathique","lemma":"psychopathique","pos":"ADJ"} ,
		{"word":"psychopathologique","word_nosc":"psychopathologique","lemma":"psychopathologique","pos":"ADJ"} ,
		{"word":"psychophysiologique","word_nosc":"psychophysiologique","lemma":"psychophysiologique","pos":"ADJ"} ,
		{"word":"psychorigide","word_nosc":"psychorigide","lemma":"psychorigide","pos":"ADJ"} ,
		{"word":"psychosexuel","word_nosc":"psychosexuel","lemma":"psychosexuel","pos":"ADJ"} ,
		{"word":"psychosomatique","word_nosc":"psychosomatique","lemma":"psychosomatique","pos":"ADJ"} ,
		{"word":"psychosomatiques","word_nosc":"psychosomatiques","lemma":"psychosomatique","pos":"ADJ"} ,
		{"word":"psychotechnique","word_nosc":"psychotechnique","lemma":"psychotechnique","pos":"ADJ"} ,
		{"word":"psychotique","word_nosc":"psychotique","lemma":"psychotique","pos":"ADJ"} ,
		{"word":"psychotiques","word_nosc":"psychotiques","lemma":"psychotique","pos":"ADJ"} ,
		{"word":"psychotrope","word_nosc":"psychotrope","lemma":"psychotrope","pos":"ADJ"} ,
		{"word":"psychotropes","word_nosc":"psychotropes","lemma":"psychotrope","pos":"ADJ"} ,
		{"word":"psychédélique","word_nosc":"psychedelique","lemma":"psychédélique","pos":"ADJ"} ,
		{"word":"psychédéliques","word_nosc":"psychedeliques","lemma":"psychédélique","pos":"ADJ"} ,
		{"word":"puant","word_nosc":"puant","lemma":"puant","pos":"ADJ"} ,
		{"word":"puante","word_nosc":"puante","lemma":"puant","pos":"ADJ"} ,
		{"word":"puantes","word_nosc":"puantes","lemma":"puant","pos":"ADJ"} ,
		{"word":"puants","word_nosc":"puants","lemma":"puant","pos":"ADJ"} ,
		{"word":"pubertaire","word_nosc":"pubertaire","lemma":"pubertaire","pos":"ADJ"} ,
		{"word":"pubertaires","word_nosc":"pubertaires","lemma":"pubertaire","pos":"ADJ"} ,
		{"word":"pubescentes","word_nosc":"pubescentes","lemma":"pubescent","pos":"ADJ"} ,
		{"word":"pubien","word_nosc":"pubien","lemma":"pubien","pos":"ADJ"} ,
		{"word":"pubienne","word_nosc":"pubienne","lemma":"pubien","pos":"ADJ"} ,
		{"word":"pubiennes","word_nosc":"pubiennes","lemma":"pubien","pos":"ADJ"} ,
		{"word":"pubiens","word_nosc":"pubiens","lemma":"pubien","pos":"ADJ"} ,
		{"word":"publiable","word_nosc":"publiable","lemma":"publiable","pos":"ADJ"} ,
		{"word":"publiables","word_nosc":"publiables","lemma":"publiable","pos":"ADJ"} ,
		{"word":"public","word_nosc":"public","lemma":"public","pos":"ADJ"} ,
		{"word":"publicitaire","word_nosc":"publicitaire","lemma":"publicitaire","pos":"ADJ"} ,
		{"word":"publicitaires","word_nosc":"publicitaires","lemma":"publicitaire","pos":"ADJ"} ,
		{"word":"publics","word_nosc":"publics","lemma":"public","pos":"ADJ"} ,
		{"word":"publique","word_nosc":"publique","lemma":"public","pos":"ADJ"} ,
		{"word":"publiques","word_nosc":"publiques","lemma":"public","pos":"ADJ"} ,
		{"word":"pubère","word_nosc":"pubere","lemma":"pubère","pos":"ADJ"} ,
		{"word":"pubères","word_nosc":"puberes","lemma":"pubère","pos":"ADJ"} ,
		{"word":"puce","word_nosc":"puce","lemma":"puce","pos":"ADJ"} ,
		{"word":"puceau","word_nosc":"puceau","lemma":"puceau","pos":"ADJ"} ,
		{"word":"puceaux","word_nosc":"puceaux","lemma":"puceau","pos":"ADJ"} ,
		{"word":"pucelle","word_nosc":"pucelle","lemma":"puceau","pos":"ADJ"} ,
		{"word":"pucelles","word_nosc":"pucelles","lemma":"puceau","pos":"ADJ"} ,
		{"word":"pudibond","word_nosc":"pudibond","lemma":"pudibond","pos":"ADJ"} ,
		{"word":"pudibonde","word_nosc":"pudibonde","lemma":"pudibond","pos":"ADJ"} ,
		{"word":"pudibondes","word_nosc":"pudibondes","lemma":"pudibond","pos":"ADJ"} ,
		{"word":"pudibonds","word_nosc":"pudibonds","lemma":"pudibond","pos":"ADJ"} ,
		{"word":"pudique","word_nosc":"pudique","lemma":"pudique","pos":"ADJ"} ,
		{"word":"pudiques","word_nosc":"pudiques","lemma":"pudique","pos":"ADJ"} ,
		{"word":"pueblo","word_nosc":"pueblo","lemma":"pueblo","pos":"ADJ"} ,
		{"word":"puerpérale","word_nosc":"puerperale","lemma":"puerpéral","pos":"ADJ"} ,
		{"word":"pugilistique","word_nosc":"pugilistique","lemma":"pugilistique","pos":"ADJ"} ,
		{"word":"pugnace","word_nosc":"pugnace","lemma":"pugnace","pos":"ADJ"} ,
		{"word":"puissant","word_nosc":"puissant","lemma":"puissant","pos":"ADJ"} ,
		{"word":"puissante","word_nosc":"puissante","lemma":"puissant","pos":"ADJ"} ,
		{"word":"puissantes","word_nosc":"puissantes","lemma":"puissant","pos":"ADJ"} ,
		{"word":"puissants","word_nosc":"puissants","lemma":"puissant","pos":"ADJ"} ,
		{"word":"pullulantes","word_nosc":"pullulantes","lemma":"pullulant","pos":"ADJ"} ,
		{"word":"pullulants","word_nosc":"pullulants","lemma":"pullulant","pos":"ADJ"} ,
		{"word":"pulmonaire","word_nosc":"pulmonaire","lemma":"pulmonaire","pos":"ADJ"} ,
		{"word":"pulmonaires","word_nosc":"pulmonaires","lemma":"pulmonaire","pos":"ADJ"} ,
		{"word":"pulmonique","word_nosc":"pulmonique","lemma":"pulmonique","pos":"ADJ"} ,
		{"word":"pulpeuse","word_nosc":"pulpeuse","lemma":"pulpeux","pos":"ADJ"} ,
		{"word":"pulpeuses","word_nosc":"pulpeuses","lemma":"pulpeux","pos":"ADJ"} ,
		{"word":"pulpeux","word_nosc":"pulpeux","lemma":"pulpeux","pos":"ADJ"} ,
		{"word":"pulsatile","word_nosc":"pulsatile","lemma":"pulsatile","pos":"ADJ"} ,
		{"word":"pulsative","word_nosc":"pulsative","lemma":"pulsatif","pos":"ADJ"} ,
		{"word":"pulvérulentes","word_nosc":"pulverulentes","lemma":"pulvérulent","pos":"ADJ"} ,
		{"word":"punais","word_nosc":"punais","lemma":"punais","pos":"ADJ"} ,
		{"word":"punctiforme","word_nosc":"punctiforme","lemma":"punctiforme","pos":"ADJ"} ,
		{"word":"puni","word_nosc":"puni","lemma":"puni","pos":"ADJ"} ,
		{"word":"punie","word_nosc":"punie","lemma":"puni","pos":"ADJ"} ,
		{"word":"punies","word_nosc":"punies","lemma":"puni","pos":"ADJ"} ,
		{"word":"punique","word_nosc":"punique","lemma":"punique","pos":"ADJ"} ,
		{"word":"puniques","word_nosc":"puniques","lemma":"punique","pos":"ADJ"} ,
		{"word":"punis","word_nosc":"punis","lemma":"puni","pos":"ADJ"} ,
		{"word":"punissable","word_nosc":"punissable","lemma":"punissable","pos":"ADJ"} ,
		{"word":"punissables","word_nosc":"punissables","lemma":"punissable","pos":"ADJ"} ,
		{"word":"punitif","word_nosc":"punitif","lemma":"punitif","pos":"ADJ"} ,
		{"word":"punitive","word_nosc":"punitive","lemma":"punitif","pos":"ADJ"} ,
		{"word":"punitives","word_nosc":"punitives","lemma":"punitif","pos":"ADJ"} ,
		{"word":"pupillaire","word_nosc":"pupillaire","lemma":"pupillaire","pos":"ADJ"} ,
		{"word":"pupillaires","word_nosc":"pupillaires","lemma":"pupillaire","pos":"ADJ"} ,
		{"word":"pur","word_nosc":"pur","lemma":"pur","pos":"ADJ"} ,
		{"word":"pure","word_nosc":"pure","lemma":"pur","pos":"ADJ"} ,
		{"word":"pures","word_nosc":"pures","lemma":"pur","pos":"ADJ"} ,
		{"word":"purgatif","word_nosc":"purgatif","lemma":"purgatif","pos":"ADJ"} ,
		{"word":"purgative","word_nosc":"purgative","lemma":"purgatif","pos":"ADJ"} ,
		{"word":"purifiant","word_nosc":"purifiant","lemma":"purifiant","pos":"ADJ"} ,
		{"word":"purificateur","word_nosc":"purificateur","lemma":"purificateur","pos":"ADJ"} ,
		{"word":"purificateurs","word_nosc":"purificateurs","lemma":"purificateur","pos":"ADJ"} ,
		{"word":"purificatoire","word_nosc":"purificatoire","lemma":"purificatoire","pos":"ADJ"} ,
		{"word":"purificatrice","word_nosc":"purificatrice","lemma":"purificateur","pos":"ADJ"} ,
		{"word":"purificatrices","word_nosc":"purificatrices","lemma":"purificateur","pos":"ADJ"} ,
		{"word":"puriste","word_nosc":"puriste","lemma":"puriste","pos":"ADJ"} ,
		{"word":"puritain","word_nosc":"puritain","lemma":"puritain","pos":"ADJ"} ,
		{"word":"puritaine","word_nosc":"puritaine","lemma":"puritain","pos":"ADJ"} ,
		{"word":"puritaines","word_nosc":"puritaines","lemma":"puritain","pos":"ADJ"} ,
		{"word":"puritains","word_nosc":"puritains","lemma":"puritain","pos":"ADJ"} ,
		{"word":"purpurin","word_nosc":"purpurin","lemma":"purpurin","pos":"ADJ"} ,
		{"word":"purpurine","word_nosc":"purpurine","lemma":"purpurin","pos":"ADJ"} ,
		{"word":"purpurines","word_nosc":"purpurines","lemma":"purpurin","pos":"ADJ"} ,
		{"word":"purpurins","word_nosc":"purpurins","lemma":"purpurin","pos":"ADJ"} ,
		{"word":"purs","word_nosc":"purs","lemma":"pur","pos":"ADJ"} ,
		{"word":"purulent","word_nosc":"purulent","lemma":"purulent","pos":"ADJ"} ,
		{"word":"purulente","word_nosc":"purulente","lemma":"purulent","pos":"ADJ"} ,
		{"word":"purulentes","word_nosc":"purulentes","lemma":"purulent","pos":"ADJ"} ,
		{"word":"purulents","word_nosc":"purulents","lemma":"purulent","pos":"ADJ"} ,
		{"word":"pus","word_nosc":"pus","lemma":"pus","pos":"ADJ"} ,
		{"word":"pusillanime","word_nosc":"pusillanime","lemma":"pusillanime","pos":"ADJ"} ,
		{"word":"pusillanimes","word_nosc":"pusillanimes","lemma":"pusillanime","pos":"ADJ"} ,
		{"word":"pustuleuse","word_nosc":"pustuleuse","lemma":"pustuleux","pos":"ADJ"} ,
		{"word":"pustuleux","word_nosc":"pustuleux","lemma":"pustuleux","pos":"ADJ"} ,
		{"word":"putassier","word_nosc":"putassier","lemma":"putassier","pos":"ADJ"} ,
		{"word":"putassiers","word_nosc":"putassiers","lemma":"putassier","pos":"ADJ"} ,
		{"word":"putassière","word_nosc":"putassiere","lemma":"putassier","pos":"ADJ"} ,
		{"word":"putassières","word_nosc":"putassieres","lemma":"putassier","pos":"ADJ"} ,
		{"word":"putatif","word_nosc":"putatif","lemma":"putatif","pos":"ADJ"} ,
		{"word":"putatifs","word_nosc":"putatifs","lemma":"putatif","pos":"ADJ"} ,
		{"word":"putative","word_nosc":"putative","lemma":"putatif","pos":"ADJ"} ,
		{"word":"putrescent","word_nosc":"putrescent","lemma":"putrescent","pos":"ADJ"} ,
		{"word":"putrescents","word_nosc":"putrescents","lemma":"putrescent","pos":"ADJ"} ,
		{"word":"putrescible","word_nosc":"putrescible","lemma":"putrescible","pos":"ADJ"} ,
		{"word":"putride","word_nosc":"putride","lemma":"putride","pos":"ADJ"} ,
		{"word":"putrides","word_nosc":"putrides","lemma":"putride","pos":"ADJ"} ,
		{"word":"putschiste","word_nosc":"putschiste","lemma":"putschiste","pos":"ADJ"} ,
		{"word":"puéril","word_nosc":"pueril","lemma":"puéril","pos":"ADJ"} ,
		{"word":"puérile","word_nosc":"puerile","lemma":"puéril","pos":"ADJ"} ,
		{"word":"puériles","word_nosc":"pueriles","lemma":"puéril","pos":"ADJ"} ,
		{"word":"puérils","word_nosc":"puerils","lemma":"puéril","pos":"ADJ"} ,
		{"word":"puînée","word_nosc":"puinee","lemma":"puîné","pos":"ADJ"} ,
		{"word":"pygmée","word_nosc":"pygmee","lemma":"pygmée","pos":"ADJ"} ,
		{"word":"pygmées","word_nosc":"pygmees","lemma":"pygmée","pos":"ADJ"} ,
		{"word":"pylorique","word_nosc":"pylorique","lemma":"pylorique","pos":"ADJ"} ,
		{"word":"pyogène","word_nosc":"pyogene","lemma":"pyogène","pos":"ADJ"} ,
		{"word":"pyramidal","word_nosc":"pyramidal","lemma":"pyramidal","pos":"ADJ"} ,
		{"word":"pyramidale","word_nosc":"pyramidale","lemma":"pyramidal","pos":"ADJ"} ,
		{"word":"pyramidales","word_nosc":"pyramidales","lemma":"pyramidal","pos":"ADJ"} ,
		{"word":"pyramidaux","word_nosc":"pyramidaux","lemma":"pyramidal","pos":"ADJ"} ,
		{"word":"pyramidé","word_nosc":"pyramide","lemma":"pyramidé","pos":"ADJ"} ,
		{"word":"pyroclastique","word_nosc":"pyroclastique","lemma":"pyroclastique","pos":"ADJ"} ,
		{"word":"pyroclastiques","word_nosc":"pyroclastiques","lemma":"pyroclastique","pos":"ADJ"} ,
		{"word":"pyrogène","word_nosc":"pyrogene","lemma":"pyrogène","pos":"ADJ"} ,
		{"word":"pyrotechnicien","word_nosc":"pyrotechnicien","lemma":"pyrotechnicien","pos":"ADJ"} ,
		{"word":"pyrotechnique","word_nosc":"pyrotechnique","lemma":"pyrotechnique","pos":"ADJ"} ,
		{"word":"pyrrhonien","word_nosc":"pyrrhonien","lemma":"pyrrhonien","pos":"ADJ"} ,
		{"word":"pyrénéen","word_nosc":"pyreneen","lemma":"pyrénéen","pos":"ADJ"} ,
		{"word":"pyrénéenne","word_nosc":"pyreneenne","lemma":"pyrénéenne","pos":"ADJ"} ,
		{"word":"pythagoricien","word_nosc":"pythagoricien","lemma":"pythagoricien","pos":"ADJ"} ,
		{"word":"pythagoriques","word_nosc":"pythagoriques","lemma":"pythagorique","pos":"ADJ"} ,
		{"word":"pâle","word_nosc":"pale","lemma":"pâle","pos":"ADJ"} ,
		{"word":"pâles","word_nosc":"pales","lemma":"pâle","pos":"ADJ"} ,
		{"word":"pâlichon","word_nosc":"palichon","lemma":"pâlichon","pos":"ADJ"} ,
		{"word":"pâlichonne","word_nosc":"palichonne","lemma":"pâlichon","pos":"ADJ"} ,
		{"word":"pâlichons","word_nosc":"palichons","lemma":"pâlichon","pos":"ADJ"} ,
		{"word":"pâlissant","word_nosc":"palissant","lemma":"pâlissant","pos":"ADJ"} ,
		{"word":"pâlissante","word_nosc":"palissante","lemma":"pâlissant","pos":"ADJ"} ,
		{"word":"pâlissantes","word_nosc":"palissantes","lemma":"pâlissant","pos":"ADJ"} ,
		{"word":"pâlot","word_nosc":"palot","lemma":"pâlot","pos":"ADJ"} ,
		{"word":"pâlots","word_nosc":"palots","lemma":"pâlot","pos":"ADJ"} ,
		{"word":"pâlotte","word_nosc":"palotte","lemma":"pâlot","pos":"ADJ"} ,
		{"word":"pâlottes","word_nosc":"palottes","lemma":"pâlot","pos":"ADJ"} ,
		{"word":"pâteuse","word_nosc":"pateuse","lemma":"pâteux","pos":"ADJ"} ,
		{"word":"pâteuses","word_nosc":"pateuses","lemma":"pâteux","pos":"ADJ"} ,
		{"word":"pâteux","word_nosc":"pateux","lemma":"pâteux","pos":"ADJ"} ,
		{"word":"pète-sec","word_nosc":"pete-sec","lemma":"pète-sec","pos":"ADJ"} ,
		{"word":"pètesec","word_nosc":"petesec","lemma":"pètesec","pos":"ADJ"} ,
		{"word":"pécheresse","word_nosc":"pecheresse","lemma":"pécheur","pos":"ADJ"} ,
		{"word":"pécheresses","word_nosc":"pecheresses","lemma":"pécheur","pos":"ADJ"} ,
		{"word":"pécheur","word_nosc":"pecheur","lemma":"pécheur","pos":"ADJ"} ,
		{"word":"pécheurs","word_nosc":"pecheurs","lemma":"pécheur","pos":"ADJ"} ,
		{"word":"pécuniaire","word_nosc":"pecuniaire","lemma":"pécuniaire","pos":"ADJ"} ,
		{"word":"pécuniaires","word_nosc":"pecuniaires","lemma":"pécuniaire","pos":"ADJ"} ,
		{"word":"pécunieux","word_nosc":"pecunieux","lemma":"pécunieux","pos":"ADJ"} ,
		{"word":"pédagogique","word_nosc":"pedagogique","lemma":"pédagogique","pos":"ADJ"} ,
		{"word":"pédagogiques","word_nosc":"pedagogiques","lemma":"pédagogique","pos":"ADJ"} ,
		{"word":"pédant","word_nosc":"pedant","lemma":"pédant","pos":"ADJ"} ,
		{"word":"pédante","word_nosc":"pedante","lemma":"pédant","pos":"ADJ"} ,
		{"word":"pédantes","word_nosc":"pedantes","lemma":"pédant","pos":"ADJ"} ,
		{"word":"pédantesque","word_nosc":"pedantesque","lemma":"pédantesque","pos":"ADJ"} ,
		{"word":"pédants","word_nosc":"pedants","lemma":"pédant","pos":"ADJ"} ,
		{"word":"pédestre","word_nosc":"pedestre","lemma":"pédestre","pos":"ADJ"} ,
		{"word":"pédestres","word_nosc":"pedestres","lemma":"pédestre","pos":"ADJ"} ,
		{"word":"pédiatrique","word_nosc":"pediatrique","lemma":"pédiatrique","pos":"ADJ"} ,
		{"word":"pédieuse","word_nosc":"pedieuse","lemma":"pédieux","pos":"ADJ"} ,
		{"word":"pédieux","word_nosc":"pedieux","lemma":"pédieux","pos":"ADJ"} ,
		{"word":"pédonculé","word_nosc":"pedoncule","lemma":"pédonculé","pos":"ADJ"} ,
		{"word":"pédophile","word_nosc":"pedophile","lemma":"pédophile","pos":"ADJ"} ,
		{"word":"pédophiles","word_nosc":"pedophiles","lemma":"pédophile","pos":"ADJ"} ,
		{"word":"pédophiliques","word_nosc":"pedophiliques","lemma":"pédophilique","pos":"ADJ"} ,
		{"word":"pédérastique","word_nosc":"pederastique","lemma":"pédérastique","pos":"ADJ"} ,
		{"word":"péjoratif","word_nosc":"pejoratif","lemma":"péjoratif","pos":"ADJ"} ,
		{"word":"péjoratifs","word_nosc":"pejoratifs","lemma":"péjoratif","pos":"ADJ"} ,
		{"word":"péjorative","word_nosc":"pejorative","lemma":"péjoratif","pos":"ADJ"} ,
		{"word":"péjoratives","word_nosc":"pejoratives","lemma":"péjoratif","pos":"ADJ"} ,
		{"word":"pékinois","word_nosc":"pekinois","lemma":"pékinois","pos":"ADJ"} ,
		{"word":"pékinoise","word_nosc":"pekinoise","lemma":"pékinois","pos":"ADJ"} ,
		{"word":"pélagique","word_nosc":"pelagique","lemma":"pélagique","pos":"ADJ"} ,
		{"word":"pélasgiques","word_nosc":"pelasgiques","lemma":"pélasgique","pos":"ADJ"} ,
		{"word":"pénal","word_nosc":"penal","lemma":"pénal","pos":"ADJ"} ,
		{"word":"pénale","word_nosc":"penale","lemma":"pénal","pos":"ADJ"} ,
		{"word":"pénales","word_nosc":"penales","lemma":"pénal","pos":"ADJ"} ,
		{"word":"pénard","word_nosc":"penard","lemma":"pénard","pos":"ADJ"} ,
		{"word":"pénarde","word_nosc":"penarde","lemma":"pénard","pos":"ADJ"} ,
		{"word":"pénardes","word_nosc":"penardes","lemma":"pénard","pos":"ADJ"} ,
		{"word":"pénards","word_nosc":"penards","lemma":"pénard","pos":"ADJ"} ,
		{"word":"pénaux","word_nosc":"penaux","lemma":"pénal","pos":"ADJ"} ,
		{"word":"pénible","word_nosc":"penible","lemma":"pénible","pos":"ADJ"} ,
		{"word":"pénibles","word_nosc":"penibles","lemma":"pénible","pos":"ADJ"} ,
		{"word":"pénien","word_nosc":"penien","lemma":"pénien","pos":"ADJ"} ,
		{"word":"pénienne","word_nosc":"penienne","lemma":"pénien","pos":"ADJ"} ,
		{"word":"péninsulaire","word_nosc":"peninsulaire","lemma":"péninsulaire","pos":"ADJ"} ,
		{"word":"péninsulaires","word_nosc":"peninsulaires","lemma":"péninsulaire","pos":"ADJ"} ,
		{"word":"pénitent","word_nosc":"penitent","lemma":"pénitent","pos":"ADJ"} ,
		{"word":"pénitente","word_nosc":"penitente","lemma":"pénitent","pos":"ADJ"} ,
		{"word":"pénitentiaire","word_nosc":"penitentiaire","lemma":"pénitentiaire","pos":"ADJ"} ,
		{"word":"pénitentiaires","word_nosc":"penitentiaires","lemma":"pénitentiaire","pos":"ADJ"} ,
		{"word":"pénitentielle","word_nosc":"penitentielle","lemma":"pénitentiel","pos":"ADJ"} ,
		{"word":"pénitents","word_nosc":"penitents","lemma":"pénitent","pos":"ADJ"} ,
		{"word":"pénultième","word_nosc":"penultieme","lemma":"pénultième","pos":"ADJ"} ,
		{"word":"pénétrable","word_nosc":"penetrable","lemma":"pénétrable","pos":"ADJ"} ,
		{"word":"pénétrables","word_nosc":"penetrables","lemma":"pénétrable","pos":"ADJ"} ,
		{"word":"pénétrant","word_nosc":"penetrant","lemma":"pénétrant","pos":"ADJ"} ,
		{"word":"pénétrante","word_nosc":"penetrante","lemma":"pénétrant","pos":"ADJ"} ,
		{"word":"pénétrantes","word_nosc":"penetrantes","lemma":"pénétrant","pos":"ADJ"} ,
		{"word":"pénétrants","word_nosc":"penetrants","lemma":"pénétrant","pos":"ADJ"} ,
		{"word":"pénétré","word_nosc":"penetre","lemma":"pénétré","pos":"ADJ"} ,
		{"word":"pénétrée","word_nosc":"penetree","lemma":"pénétré","pos":"ADJ"} ,
		{"word":"pénétrés","word_nosc":"penetres","lemma":"pénétré","pos":"ADJ"} ,
		{"word":"pépiant","word_nosc":"pepiant","lemma":"pépiant","pos":"ADJ"} ,
		{"word":"pépère","word_nosc":"pepere","lemma":"pépère","pos":"ADJ"} ,
		{"word":"pépères","word_nosc":"peperes","lemma":"pépère","pos":"ADJ"} ,
		{"word":"péremptoire","word_nosc":"peremptoire","lemma":"péremptoire","pos":"ADJ"} ,
		{"word":"péremptoires","word_nosc":"peremptoires","lemma":"péremptoire","pos":"ADJ"} ,
		{"word":"pérenne","word_nosc":"perenne","lemma":"pérenne","pos":"ADJ"} ,
		{"word":"pérennes","word_nosc":"perennes","lemma":"pérenne","pos":"ADJ"} ,
		{"word":"péri","word_nosc":"peri","lemma":"péri","pos":"ADJ"} ,
		{"word":"péricardique","word_nosc":"pericardique","lemma":"péricardique","pos":"ADJ"} ,
		{"word":"péridurale","word_nosc":"peridurale","lemma":"péridurale","pos":"ADJ"} ,
		{"word":"périe","word_nosc":"perie","lemma":"péri","pos":"ADJ"} ,
		{"word":"périgourdin","word_nosc":"perigourdin","lemma":"périgourdin","pos":"ADJ"} ,
		{"word":"périgourdine","word_nosc":"perigourdine","lemma":"périgourdin","pos":"ADJ"} ,
		{"word":"périgourdins","word_nosc":"perigourdins","lemma":"périgourdin","pos":"ADJ"} ,
		{"word":"périlleuse","word_nosc":"perilleuse","lemma":"périlleux","pos":"ADJ"} ,
		{"word":"périlleuses","word_nosc":"perilleuses","lemma":"périlleux","pos":"ADJ"} ,
		{"word":"périlleux","word_nosc":"perilleux","lemma":"périlleux","pos":"ADJ"} ,
		{"word":"périmé","word_nosc":"perime","lemma":"périmé","pos":"ADJ"} ,
		{"word":"périmée","word_nosc":"perimee","lemma":"périmé","pos":"ADJ"} ,
		{"word":"périmées","word_nosc":"perimees","lemma":"périmé","pos":"ADJ"} ,
		{"word":"périmés","word_nosc":"perimes","lemma":"périmé","pos":"ADJ"} ,
		{"word":"périodique","word_nosc":"periodique","lemma":"périodique","pos":"ADJ"} ,
		{"word":"périodiques","word_nosc":"periodiques","lemma":"périodique","pos":"ADJ"} ,
		{"word":"périphérique","word_nosc":"peripherique","lemma":"périphérique","pos":"ADJ"} ,
		{"word":"périphériques","word_nosc":"peripheriques","lemma":"périphérique","pos":"ADJ"} ,
		{"word":"péris","word_nosc":"peris","lemma":"péri","pos":"ADJ"} ,
		{"word":"périscolaire","word_nosc":"periscolaire","lemma":"périscolaire","pos":"ADJ"} ,
		{"word":"périscopique","word_nosc":"periscopique","lemma":"périscopique","pos":"ADJ"} ,
		{"word":"périssable","word_nosc":"perissable","lemma":"périssable","pos":"ADJ"} ,
		{"word":"périssables","word_nosc":"perissables","lemma":"périssable","pos":"ADJ"} ,
		{"word":"péristaltique","word_nosc":"peristaltique","lemma":"péristaltique","pos":"ADJ"} ,
		{"word":"péristaltiques","word_nosc":"peristaltiques","lemma":"péristaltique","pos":"ADJ"} ,
		{"word":"péritonéal","word_nosc":"peritoneal","lemma":"péritonéal","pos":"ADJ"} ,
		{"word":"péritonéale","word_nosc":"peritoneale","lemma":"péritonéal","pos":"ADJ"} ,
		{"word":"périurbaines","word_nosc":"periurbaines","lemma":"périurbain","pos":"ADJ"} ,
		{"word":"péroniste","word_nosc":"peroniste","lemma":"péroniste","pos":"ADJ"} ,
		{"word":"péruvien","word_nosc":"peruvien","lemma":"péruvien","pos":"ADJ"} ,
		{"word":"péruvienne","word_nosc":"peruvienne","lemma":"péruvien","pos":"ADJ"} ,
		{"word":"péruviens","word_nosc":"peruviens","lemma":"péruvien","pos":"ADJ"} ,
		{"word":"pétainiste","word_nosc":"petainiste","lemma":"pétainiste","pos":"ADJ"} ,
		{"word":"pétainistes","word_nosc":"petainistes","lemma":"pétainiste","pos":"ADJ"} ,
		{"word":"pétaloïdes","word_nosc":"petaloides","lemma":"pétaloïde","pos":"ADJ"} ,
		{"word":"pétant","word_nosc":"petant","lemma":"pétant","pos":"ADJ"} ,
		{"word":"pétante","word_nosc":"petante","lemma":"pétant","pos":"ADJ"} ,
		{"word":"pétantes","word_nosc":"petantes","lemma":"pétant","pos":"ADJ"} ,
		{"word":"pétants","word_nosc":"petants","lemma":"pétant","pos":"ADJ"} ,
		{"word":"pétaradant","word_nosc":"petaradant","lemma":"pétaradant","pos":"ADJ"} ,
		{"word":"pétaradante","word_nosc":"petaradante","lemma":"pétaradant","pos":"ADJ"} ,
		{"word":"pétaradantes","word_nosc":"petaradantes","lemma":"pétaradant","pos":"ADJ"} ,
		{"word":"pétaradants","word_nosc":"petaradants","lemma":"pétaradant","pos":"ADJ"} ,
		{"word":"péteuse","word_nosc":"peteuse","lemma":"péteux","pos":"ADJ"} ,
		{"word":"péteux","word_nosc":"peteux","lemma":"péteux","pos":"ADJ"} ,
		{"word":"pétillant","word_nosc":"petillant","lemma":"pétillant","pos":"ADJ"} ,
		{"word":"pétillante","word_nosc":"petillante","lemma":"pétillant","pos":"ADJ"} ,
		{"word":"pétillantes","word_nosc":"petillantes","lemma":"pétillant","pos":"ADJ"} ,
		{"word":"pétillants","word_nosc":"petillants","lemma":"pétillant","pos":"ADJ"} ,
		{"word":"pétochard","word_nosc":"petochard","lemma":"pétochard","pos":"ADJ"} ,
		{"word":"pétreux","word_nosc":"petreux","lemma":"pétreux","pos":"ADJ"} ,
		{"word":"pétri","word_nosc":"petri","lemma":"pétri","pos":"ADJ"} ,
		{"word":"pétrie","word_nosc":"petrie","lemma":"pétri","pos":"ADJ"} ,
		{"word":"pétrifiante","word_nosc":"petrifiante","lemma":"pétrifiant","pos":"ADJ"} ,
		{"word":"pétrifiantes","word_nosc":"petrifiantes","lemma":"pétrifiant","pos":"ADJ"} ,
		{"word":"pétrifié","word_nosc":"petrifie","lemma":"pétrifié","pos":"ADJ"} ,
		{"word":"pétrifiée","word_nosc":"petrifiee","lemma":"pétrifié","pos":"ADJ"} ,
		{"word":"pétrifiées","word_nosc":"petrifiees","lemma":"pétrifié","pos":"ADJ"} ,
		{"word":"pétrifiés","word_nosc":"petrifies","lemma":"pétrifié","pos":"ADJ"} ,
		{"word":"pétris","word_nosc":"petris","lemma":"pétri","pos":"ADJ"} ,
		{"word":"pétrochimique","word_nosc":"petrochimique","lemma":"pétrochimique","pos":"ADJ"} ,
		{"word":"pétrolier","word_nosc":"petrolier","lemma":"pétrolier","pos":"ADJ"} ,
		{"word":"pétroliers","word_nosc":"petroliers","lemma":"pétrolier","pos":"ADJ"} ,
		{"word":"pétrolifère","word_nosc":"petrolifere","lemma":"pétrolifère","pos":"ADJ"} ,
		{"word":"pétrolifères","word_nosc":"petroliferes","lemma":"pétrolifère","pos":"ADJ"} ,
		{"word":"pétrolière","word_nosc":"petroliere","lemma":"pétrolier","pos":"ADJ"} ,
		{"word":"pétrolières","word_nosc":"petrolieres","lemma":"pétrolier","pos":"ADJ"} ,
		{"word":"pétrée","word_nosc":"petree","lemma":"pétré","pos":"ADJ"} ,
		{"word":"pétulant","word_nosc":"petulant","lemma":"pétulant","pos":"ADJ"} ,
		{"word":"pétulante","word_nosc":"petulante","lemma":"pétulant","pos":"ADJ"} ,
		{"word":"pétulantes","word_nosc":"petulantes","lemma":"pétulant","pos":"ADJ"} ,
		{"word":"pétulants","word_nosc":"petulants","lemma":"pétulant","pos":"ADJ"} ,
		{"word":"pété","word_nosc":"pete","lemma":"pété","pos":"ADJ"} ,
		{"word":"pétéchiale","word_nosc":"petechiale","lemma":"pétéchial","pos":"ADJ"} ,
		{"word":"pétée","word_nosc":"petee","lemma":"pété","pos":"ADJ"} ,
		{"word":"pétées","word_nosc":"petees","lemma":"pété","pos":"ADJ"} ,
		{"word":"pétés","word_nosc":"petes","lemma":"pété","pos":"ADJ"} ,
		{"word":"pêche","word_nosc":"peche","lemma":"pêche","pos":"ADJ"} ,
		{"word":"pêché","word_nosc":"peche","lemma":"pêché","pos":"ADJ"} ,
		{"word":"pêchés","word_nosc":"peches","lemma":"pêché","pos":"ADJ"} ,
		{"word":"quadragénaire","word_nosc":"quadragenaire","lemma":"quadragénaire","pos":"ADJ"} ,
		{"word":"quadrangulaire","word_nosc":"quadrangulaire","lemma":"quadrangulaire","pos":"ADJ"} ,
		{"word":"quadrangulaires","word_nosc":"quadrangulaires","lemma":"quadrangulaire","pos":"ADJ"} ,
		{"word":"quadratique","word_nosc":"quadratique","lemma":"quadratique","pos":"ADJ"} ,
		{"word":"quadridimensionnel","word_nosc":"quadridimensionnel","lemma":"quadridimensionnel","pos":"ADJ"} ,
		{"word":"quadriennal","word_nosc":"quadriennal","lemma":"quadriennal","pos":"ADJ"} ,
		{"word":"quadrilatère","word_nosc":"quadrilatere","lemma":"quadrilatère","pos":"ADJ"} ,
		{"word":"quadrilatères","word_nosc":"quadrilateres","lemma":"quadrilatère","pos":"ADJ"} ,
		{"word":"quadrilobé","word_nosc":"quadrilobe","lemma":"quadrilobé","pos":"ADJ"} ,
		{"word":"quadrimoteur","word_nosc":"quadrimoteur","lemma":"quadrimoteur","pos":"ADJ"} ,
		{"word":"quadripartite","word_nosc":"quadripartite","lemma":"quadripartite","pos":"ADJ"} ,
		{"word":"quadripartites","word_nosc":"quadripartites","lemma":"quadripartite","pos":"ADJ"} ,
		{"word":"quadriphonique","word_nosc":"quadriphonique","lemma":"quadriphonique","pos":"ADJ"} ,
		{"word":"quadriplégique","word_nosc":"quadriplegique","lemma":"quadriplégique","pos":"ADJ"} ,
		{"word":"quadruple","word_nosc":"quadruple","lemma":"quadruple","pos":"ADJ"} ,
		{"word":"quadrupède","word_nosc":"quadrupede","lemma":"quadrupède","pos":"ADJ"} ,
		{"word":"quadrupèdes","word_nosc":"quadrupedes","lemma":"quadrupède","pos":"ADJ"} ,
		{"word":"qualifiable","word_nosc":"qualifiable","lemma":"qualifiable","pos":"ADJ"} ,
		{"word":"qualifié","word_nosc":"qualifie","lemma":"qualifié","pos":"ADJ"} ,
		{"word":"qualifiée","word_nosc":"qualifiee","lemma":"qualifié","pos":"ADJ"} ,
		{"word":"qualifiées","word_nosc":"qualifiees","lemma":"qualifié","pos":"ADJ"} ,
		{"word":"qualifiés","word_nosc":"qualifies","lemma":"qualifié","pos":"ADJ"} ,
		{"word":"qualitatif","word_nosc":"qualitatif","lemma":"qualitatif","pos":"ADJ"} ,
		{"word":"qualitatifs","word_nosc":"qualitatifs","lemma":"qualitatif","pos":"ADJ"} ,
		{"word":"qualitative","word_nosc":"qualitative","lemma":"qualitatif","pos":"ADJ"} ,
		{"word":"quantifiable","word_nosc":"quantifiable","lemma":"quantifiable","pos":"ADJ"} ,
		{"word":"quantique","word_nosc":"quantique","lemma":"quantique","pos":"ADJ"} ,
		{"word":"quantiques","word_nosc":"quantiques","lemma":"quantique","pos":"ADJ"} ,
		{"word":"quantitatif","word_nosc":"quantitatif","lemma":"quantitatif","pos":"ADJ"} ,
		{"word":"quantitative","word_nosc":"quantitative","lemma":"quantitatif","pos":"ADJ"} ,
		{"word":"quantitatives","word_nosc":"quantitatives","lemma":"quantitatif","pos":"ADJ"} ,
		{"word":"quarante","word_nosc":"quarante","lemma":"quarante","pos":"ADJ:num"} ,
		{"word":"quarante-cinq","word_nosc":"quarante-cinq","lemma":"quarante-cinq","pos":"ADJ:num"} ,
		{"word":"quarante-cinquième","word_nosc":"quarante-cinquieme","lemma":"quarante-cinquième","pos":"ADJ"} ,
		{"word":"quarante-deux","word_nosc":"quarante-deux","lemma":"quarante-deux","pos":"ADJ:num"} ,
		{"word":"quarante-deuxième","word_nosc":"quarante-deuxieme","lemma":"quarante-deuxième","pos":"ADJ"} ,
		{"word":"quarante-huit","word_nosc":"quarante-huit","lemma":"quarante-huit","pos":"ADJ:num"} ,
		{"word":"quarante-huitième","word_nosc":"quarante-huitieme","lemma":"quarante-huitième","pos":"ADJ"} ,
		{"word":"quarante-neuf","word_nosc":"quarante-neuf","lemma":"quarante-neuf","pos":"ADJ:num"} ,
		{"word":"quarante-quatre","word_nosc":"quarante-quatre","lemma":"quarante-quatre","pos":"ADJ:num"} ,
		{"word":"quarante-sept","word_nosc":"quarante-sept","lemma":"quarante-sept","pos":"ADJ:num"} ,
		{"word":"quarante-septième","word_nosc":"quarante-septieme","lemma":"quarante-septième","pos":"ADJ"} ,
		{"word":"quarante-six","word_nosc":"quarante-six","lemma":"quarante-six","pos":"ADJ:num"} ,
		{"word":"quarante-trois","word_nosc":"quarante-trois","lemma":"quarante-trois","pos":"ADJ:num"} ,
		{"word":"quarante-troisième","word_nosc":"quarante-troisieme","lemma":"quarante-troisième","pos":"ADJ"} ,
		{"word":"quarantenaire","word_nosc":"quarantenaire","lemma":"quarantenaire","pos":"ADJ"} ,
		{"word":"quarantenaires","word_nosc":"quarantenaires","lemma":"quarantenaire","pos":"ADJ"} ,
		{"word":"quarantième","word_nosc":"quarantieme","lemma":"quarantième","pos":"ADJ"} ,
		{"word":"quarte","word_nosc":"quarte","lemma":"quarte","pos":"ADJ"} ,
		{"word":"quasi-facétieux","word_nosc":"quasi-facetieux","lemma":"quasi-facétieux","pos":"ADJ"} ,
		{"word":"quasi-futuristes","word_nosc":"quasi-futuristes","lemma":"quasi-futuriste","pos":"ADJ"} ,
		{"word":"quasi-religieux","word_nosc":"quasi-religieux","lemma":"quasi-religieux","pos":"ADJ"} ,
		{"word":"quasi-unanime","word_nosc":"quasi-unanime","lemma":"quasi-unanime","pos":"ADJ"} ,
		{"word":"quasi-équitable","word_nosc":"quasi-equitable","lemma":"quasi-équitable","pos":"ADJ"} ,
		{"word":"quaternaire","word_nosc":"quaternaire","lemma":"quaternaire","pos":"ADJ"} ,
		{"word":"quatorze","word_nosc":"quatorze","lemma":"quatorze","pos":"ADJ:num"} ,
		{"word":"quatorzième","word_nosc":"quatorzieme","lemma":"quatorzième","pos":"ADJ"} ,
		{"word":"quatre","word_nosc":"quatre","lemma":"quatre","pos":"ADJ:num"} ,
		{"word":"quatre-vingt","word_nosc":"quatre-vingt","lemma":"quatre-vingt","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-cinq","word_nosc":"quatre-vingt-cinq","lemma":"quatre-vingt-cinq","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-deux","word_nosc":"quatre-vingt-deux","lemma":"quatre-vingt-deux","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-dix","word_nosc":"quatre-vingt-dix","lemma":"quatre-vingt-dix","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-dix-huit","word_nosc":"quatre-vingt-dix-huit","lemma":"quatre-vingt-dix-huit","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-dix-neuf","word_nosc":"quatre-vingt-dix-neuf","lemma":"quatre-vingt-dix-neuf","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-dix-sept","word_nosc":"quatre-vingt-dix-sept","lemma":"quatre-vingt-dix-sept","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-dixième","word_nosc":"quatre-vingt-dixieme","lemma":"quatre-vingt-dixième","pos":"ADJ"} ,
		{"word":"quatre-vingt-douze","word_nosc":"quatre-vingt-douze","lemma":"quatre-vingt-douze","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-douzième","word_nosc":"quatre-vingt-douzieme","lemma":"quatre-vingt-douzième","pos":"ADJ"} ,
		{"word":"quatre-vingt-huit","word_nosc":"quatre-vingt-huit","lemma":"quatre-vingt-huit","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-neuf","word_nosc":"quatre-vingt-neuf","lemma":"quatre-vingt-neuf","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-onze","word_nosc":"quatre-vingt-onze","lemma":"quatre-vingt-onze","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-quatorze","word_nosc":"quatre-vingt-quatorze","lemma":"quatre-vingt-quatorze","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-quatre","word_nosc":"quatre-vingt-quatre","lemma":"quatre-vingt-quatre","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-quinze","word_nosc":"quatre-vingt-quinze","lemma":"quatre-vingt-quinze","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-seize","word_nosc":"quatre-vingt-seize","lemma":"quatre-vingt-seize","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-sept","word_nosc":"quatre-vingt-sept","lemma":"quatre-vingt-sept","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-six","word_nosc":"quatre-vingt-six","lemma":"quatre-vingt-six","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-treize","word_nosc":"quatre-vingt-treize","lemma":"quatre-vingt-treize","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-treizième","word_nosc":"quatre-vingt-treizieme","lemma":"quatre-vingt-treizième","pos":"ADJ"} ,
		{"word":"quatre-vingt-trois","word_nosc":"quatre-vingt-trois","lemma":"quatre-vingt-trois","pos":"ADJ:num"} ,
		{"word":"quatre-vingt-un","word_nosc":"quatre-vingt-un","lemma":"quatre-vingt-un","pos":"ADJ:num"} ,
		{"word":"quatre-vingtième","word_nosc":"quatre-vingtieme","lemma":"quatre-vingtième","pos":"ADJ"} ,
		{"word":"quatre-vingts","word_nosc":"quatre-vingts","lemma":"quatre-vingts","pos":"ADJ:num"} ,
		{"word":"quatrième","word_nosc":"quatrieme","lemma":"quatrième","pos":"ADJ"} ,
		{"word":"quel","word_nosc":"quel","lemma":"quel","pos":"ADJ:int"} ,
		{"word":"quelconque","word_nosc":"quelconque","lemma":"quelconque","pos":"ADJ"} ,
		{"word":"quelconques","word_nosc":"quelconques","lemma":"quelconque","pos":"ADJ"} ,
		{"word":"quelle","word_nosc":"quelle","lemma":"quelle","pos":"ADJ:int"} ,
		{"word":"quelles","word_nosc":"quelles","lemma":"quelles","pos":"ADJ:int"} ,
		{"word":"quelque","word_nosc":"quelque","lemma":"quelque","pos":"ADJ:ind"} ,
		{"word":"quelques","word_nosc":"quelques","lemma":"quelques","pos":"ADJ:ind"} ,
		{"word":"quels","word_nosc":"quels","lemma":"quels","pos":"ADJ:int"} ,
		{"word":"querelleur","word_nosc":"querelleur","lemma":"querelleur","pos":"ADJ"} ,
		{"word":"querelleurs","word_nosc":"querelleurs","lemma":"querelleur","pos":"ADJ"} ,
		{"word":"querelleuse","word_nosc":"querelleuse","lemma":"querelleux","pos":"ADJ"} ,
		{"word":"quiet","word_nosc":"quiet","lemma":"quiet","pos":"ADJ"} ,
		{"word":"quiets","word_nosc":"quiets","lemma":"quiet","pos":"ADJ"} ,
		{"word":"quinquagénaire","word_nosc":"quinquagenaire","lemma":"quinquagénaire","pos":"ADJ"} ,
		{"word":"quinquagénaires","word_nosc":"quinquagenaires","lemma":"quinquagénaire","pos":"ADJ"} ,
		{"word":"quinquennal","word_nosc":"quinquennal","lemma":"quinquennal","pos":"ADJ"} ,
		{"word":"quint","word_nosc":"quint","lemma":"quint","pos":"ADJ"} ,
		{"word":"quintessencié","word_nosc":"quintessencie","lemma":"quintessencié","pos":"ADJ"} ,
		{"word":"quintessenciée","word_nosc":"quintessenciee","lemma":"quintessencié","pos":"ADJ"} ,
		{"word":"quintessenciées","word_nosc":"quintessenciees","lemma":"quintessencié","pos":"ADJ"} ,
		{"word":"quinteux","word_nosc":"quinteux","lemma":"quinteux","pos":"ADJ"} ,
		{"word":"quintuple","word_nosc":"quintuple","lemma":"quintuple","pos":"ADJ"} ,
		{"word":"quintuples","word_nosc":"quintuples","lemma":"quintuple","pos":"ADJ"} ,
		{"word":"quinze","word_nosc":"quinze","lemma":"quinze","pos":"ADJ:num"} ,
		{"word":"quinzième","word_nosc":"quinzieme","lemma":"quinzième","pos":"ADJ"} ,
		{"word":"quitte","word_nosc":"quitte","lemma":"quitte","pos":"ADJ"} ,
		{"word":"quittes","word_nosc":"quittes","lemma":"quitte","pos":"ADJ"} ,
		{"word":"quiète","word_nosc":"quiete","lemma":"quiet","pos":"ADJ"} ,
		{"word":"quiètes","word_nosc":"quietes","lemma":"quiet","pos":"ADJ"} ,
		{"word":"quoi","word_nosc":"quoi","lemma":"quoi","pos":"ADJ"} ,
		{"word":"quotidien","word_nosc":"quotidien","lemma":"quotidien","pos":"ADJ"} ,
		{"word":"quotidienne","word_nosc":"quotidienne","lemma":"quotidien","pos":"ADJ"} ,
		{"word":"quotidiennes","word_nosc":"quotidiennes","lemma":"quotidien","pos":"ADJ"} ,
		{"word":"quotidiens","word_nosc":"quotidiens","lemma":"quotidien","pos":"ADJ"} ,
		{"word":"québécois","word_nosc":"quebecois","lemma":"québécois","pos":"ADJ"} ,
		{"word":"québécoise","word_nosc":"quebecoise","lemma":"québécois","pos":"ADJ"} ,
		{"word":"quêteur","word_nosc":"queteur","lemma":"quêteur","pos":"ADJ"} ,
		{"word":"quêteurs","word_nosc":"queteurs","lemma":"quêteur","pos":"ADJ"} ,
		{"word":"rabat-joie","word_nosc":"rabat-joie","lemma":"rabat-joie","pos":"ADJ"} ,
		{"word":"rabattable","word_nosc":"rabattable","lemma":"rabattable","pos":"ADJ"} ,
		{"word":"rabattant","word_nosc":"rabattant","lemma":"rabattant","pos":"ADJ"} ,
		{"word":"rabattu","word_nosc":"rabattu","lemma":"rabattu","pos":"ADJ"} ,
		{"word":"rabattue","word_nosc":"rabattue","lemma":"rabattu","pos":"ADJ"} ,
		{"word":"rabattues","word_nosc":"rabattues","lemma":"rabattu","pos":"ADJ"} ,
		{"word":"rabattus","word_nosc":"rabattus","lemma":"rabattu","pos":"ADJ"} ,
		{"word":"rabbinique","word_nosc":"rabbinique","lemma":"rabbinique","pos":"ADJ"} ,
		{"word":"rabbiniques","word_nosc":"rabbiniques","lemma":"rabbinique","pos":"ADJ"} ,
		{"word":"rabelaisien","word_nosc":"rabelaisien","lemma":"rabelaisien","pos":"ADJ"} ,
		{"word":"rabelaisienne","word_nosc":"rabelaisienne","lemma":"rabelaisien","pos":"ADJ"} ,
		{"word":"rabelaisiennes","word_nosc":"rabelaisiennes","lemma":"rabelaisien","pos":"ADJ"} ,
		{"word":"rabelaisiens","word_nosc":"rabelaisiens","lemma":"rabelaisien","pos":"ADJ"} ,
		{"word":"rabique","word_nosc":"rabique","lemma":"rabique","pos":"ADJ"} ,
		{"word":"raboteuse","word_nosc":"raboteuse","lemma":"raboteux","pos":"ADJ"} ,
		{"word":"raboteuses","word_nosc":"raboteuses","lemma":"raboteux","pos":"ADJ"} ,
		{"word":"raboteux","word_nosc":"raboteux","lemma":"raboteux","pos":"ADJ"} ,
		{"word":"rabougri","word_nosc":"rabougri","lemma":"rabougri","pos":"ADJ"} ,
		{"word":"rabougrie","word_nosc":"rabougrie","lemma":"rabougri","pos":"ADJ"} ,
		{"word":"rabougries","word_nosc":"rabougries","lemma":"rabougri","pos":"ADJ"} ,
		{"word":"rabougris","word_nosc":"rabougris","lemma":"rabougri","pos":"ADJ"} ,
		{"word":"rabâcheur","word_nosc":"rabacheur","lemma":"rabâcheur","pos":"ADJ"} ,
		{"word":"rac","word_nosc":"rac","lemma":"rac","pos":"ADJ"} ,
		{"word":"raccourci","word_nosc":"raccourci","lemma":"raccourci","pos":"ADJ"} ,
		{"word":"raccourcis","word_nosc":"raccourcis","lemma":"raccourci","pos":"ADJ"} ,
		{"word":"raccrocheur","word_nosc":"raccrocheur","lemma":"raccrocheur","pos":"ADJ"} ,
		{"word":"raccrocheurs","word_nosc":"raccrocheurs","lemma":"raccrocheur","pos":"ADJ"} ,
		{"word":"rachidien","word_nosc":"rachidien","lemma":"rachidien","pos":"ADJ"} ,
		{"word":"rachidienne","word_nosc":"rachidienne","lemma":"rachidien","pos":"ADJ"} ,
		{"word":"rachitique","word_nosc":"rachitique","lemma":"rachitique","pos":"ADJ"} ,
		{"word":"rachitiques","word_nosc":"rachitiques","lemma":"rachitique","pos":"ADJ"} ,
		{"word":"racho","word_nosc":"racho","lemma":"racho","pos":"ADJ"} ,
		{"word":"racial","word_nosc":"racial","lemma":"racial","pos":"ADJ"} ,
		{"word":"raciale","word_nosc":"raciale","lemma":"racial","pos":"ADJ"} ,
		{"word":"raciales","word_nosc":"raciales","lemma":"racial","pos":"ADJ"} ,
		{"word":"raciaux","word_nosc":"raciaux","lemma":"racial","pos":"ADJ"} ,
		{"word":"racinienne","word_nosc":"racinienne","lemma":"racinien","pos":"ADJ"} ,
		{"word":"raciniennes","word_nosc":"raciniennes","lemma":"racinien","pos":"ADJ"} ,
		{"word":"raciniens","word_nosc":"raciniens","lemma":"racinien","pos":"ADJ"} ,
		{"word":"raciste","word_nosc":"raciste","lemma":"raciste","pos":"ADJ"} ,
		{"word":"racistes","word_nosc":"racistes","lemma":"raciste","pos":"ADJ"} ,
		{"word":"raclant","word_nosc":"raclant","lemma":"raclant","pos":"ADJ"} ,
		{"word":"raclante","word_nosc":"raclante","lemma":"raclant","pos":"ADJ"} ,
		{"word":"racoleur","word_nosc":"racoleur","lemma":"racoleur","pos":"ADJ"} ,
		{"word":"racoleurs","word_nosc":"racoleurs","lemma":"racoleur","pos":"ADJ"} ,
		{"word":"racoleuse","word_nosc":"racoleuse","lemma":"racoleur","pos":"ADJ"} ,
		{"word":"racoleuses","word_nosc":"racoleuses","lemma":"racoleur","pos":"ADJ"} ,
		{"word":"racontable","word_nosc":"racontable","lemma":"racontable","pos":"ADJ"} ,
		{"word":"racontables","word_nosc":"racontables","lemma":"racontable","pos":"ADJ"} ,
		{"word":"racé","word_nosc":"race","lemma":"racé","pos":"ADJ"} ,
		{"word":"racée","word_nosc":"racee","lemma":"racé","pos":"ADJ"} ,
		{"word":"racés","word_nosc":"races","lemma":"racé","pos":"ADJ"} ,
		{"word":"radial","word_nosc":"radial","lemma":"radial","pos":"ADJ"} ,
		{"word":"radiale","word_nosc":"radiale","lemma":"radial","pos":"ADJ"} ,
		{"word":"radiales","word_nosc":"radiales","lemma":"radial","pos":"ADJ"} ,
		{"word":"radiant","word_nosc":"radiant","lemma":"radiant","pos":"ADJ"} ,
		{"word":"radiante","word_nosc":"radiante","lemma":"radiant","pos":"ADJ"} ,
		{"word":"radiative","word_nosc":"radiative","lemma":"radiatif","pos":"ADJ"} ,
		{"word":"radiaux","word_nosc":"radiaux","lemma":"radial","pos":"ADJ"} ,
		{"word":"radical","word_nosc":"radical","lemma":"radical","pos":"ADJ"} ,
		{"word":"radical-socialiste","word_nosc":"radical-socialiste","lemma":"radical-socialiste","pos":"ADJ"} ,
		{"word":"radicale","word_nosc":"radicale","lemma":"radical","pos":"ADJ"} ,
		{"word":"radicales","word_nosc":"radicales","lemma":"radical","pos":"ADJ"} ,
		{"word":"radicaux","word_nosc":"radicaux","lemma":"radical","pos":"ADJ"} ,
		{"word":"radiculaire","word_nosc":"radiculaire","lemma":"radiculaire","pos":"ADJ"} ,
		{"word":"radieuse","word_nosc":"radieuse","lemma":"radieux","pos":"ADJ"} ,
		{"word":"radieuses","word_nosc":"radieuses","lemma":"radieux","pos":"ADJ"} ,
		{"word":"radieux","word_nosc":"radieux","lemma":"radieux","pos":"ADJ"} ,
		{"word":"radin","word_nosc":"radin","lemma":"radin","pos":"ADJ"} ,
		{"word":"radine","word_nosc":"radine","lemma":"radin","pos":"ADJ"} ,
		{"word":"radines","word_nosc":"radines","lemma":"radin","pos":"ADJ"} ,
		{"word":"radins","word_nosc":"radins","lemma":"radin","pos":"ADJ"} ,
		{"word":"radioactif","word_nosc":"radioactif","lemma":"radioactif","pos":"ADJ"} ,
		{"word":"radioactifs","word_nosc":"radioactifs","lemma":"radioactif","pos":"ADJ"} ,
		{"word":"radioactive","word_nosc":"radioactive","lemma":"radioactif","pos":"ADJ"} ,
		{"word":"radioactives","word_nosc":"radioactives","lemma":"radioactif","pos":"ADJ"} ,
		{"word":"radiogoniométrique","word_nosc":"radiogoniometrique","lemma":"radiogoniométrique","pos":"ADJ"} ,
		{"word":"radiographique","word_nosc":"radiographique","lemma":"radiographique","pos":"ADJ"} ,
		{"word":"radiologique","word_nosc":"radiologique","lemma":"radiologique","pos":"ADJ"} ,
		{"word":"radiologiques","word_nosc":"radiologiques","lemma":"radiologique","pos":"ADJ"} ,
		{"word":"radiophonique","word_nosc":"radiophonique","lemma":"radiophonique","pos":"ADJ"} ,
		{"word":"radiophoniques","word_nosc":"radiophoniques","lemma":"radiophonique","pos":"ADJ"} ,
		{"word":"radioscopique","word_nosc":"radioscopique","lemma":"radioscopique","pos":"ADJ"} ,
		{"word":"radioscopiques","word_nosc":"radioscopiques","lemma":"radioscopique","pos":"ADJ"} ,
		{"word":"radiotéléphonique","word_nosc":"radiotelephonique","lemma":"radiotéléphonique","pos":"ADJ"} ,
		{"word":"radioélectrique","word_nosc":"radioelectrique","lemma":"radioélectrique","pos":"ADJ"} ,
		{"word":"radié","word_nosc":"radie","lemma":"radié","pos":"ADJ"} ,
		{"word":"radiée","word_nosc":"radiee","lemma":"radié","pos":"ADJ"} ,
		{"word":"radiés","word_nosc":"radies","lemma":"radié","pos":"ADJ"} ,
		{"word":"radoteur","word_nosc":"radoteur","lemma":"radoteur","pos":"ADJ"} ,
		{"word":"radoteuses","word_nosc":"radoteuses","lemma":"radoteur","pos":"ADJ"} ,
		{"word":"raffiné","word_nosc":"raffine","lemma":"raffiné","pos":"ADJ"} ,
		{"word":"raffinée","word_nosc":"raffinee","lemma":"raffiné","pos":"ADJ"} ,
		{"word":"raffinées","word_nosc":"raffinees","lemma":"raffiné","pos":"ADJ"} ,
		{"word":"raffinés","word_nosc":"raffines","lemma":"raffiné","pos":"ADJ"} ,
		{"word":"rafraîchissant","word_nosc":"rafraichissant","lemma":"rafraîchissant","pos":"ADJ"} ,
		{"word":"rafraîchissante","word_nosc":"rafraichissante","lemma":"rafraîchissant","pos":"ADJ"} ,
		{"word":"rafraîchissantes","word_nosc":"rafraichissantes","lemma":"rafraîchissant","pos":"ADJ"} ,
		{"word":"rafraîchissants","word_nosc":"rafraichissants","lemma":"rafraîchissant","pos":"ADJ"} ,
		{"word":"rageant","word_nosc":"rageant","lemma":"rageant","pos":"ADJ"} ,
		{"word":"rageur","word_nosc":"rageur","lemma":"rageur","pos":"ADJ"} ,
		{"word":"rageurs","word_nosc":"rageurs","lemma":"rageur","pos":"ADJ"} ,
		{"word":"rageuse","word_nosc":"rageuse","lemma":"rageur","pos":"ADJ"} ,
		{"word":"rageuses","word_nosc":"rageuses","lemma":"rageur","pos":"ADJ"} ,
		{"word":"ragot","word_nosc":"ragot","lemma":"ragot","pos":"ADJ"} ,
		{"word":"ragots","word_nosc":"ragots","lemma":"ragot","pos":"ADJ"} ,
		{"word":"ragoûtant","word_nosc":"ragoutant","lemma":"ragoûtant","pos":"ADJ"} ,
		{"word":"ragoûtante","word_nosc":"ragoutante","lemma":"ragoûtant","pos":"ADJ"} ,
		{"word":"ragoûtantes","word_nosc":"ragoutantes","lemma":"ragoûtant","pos":"ADJ"} ,
		{"word":"ragoûtants","word_nosc":"ragoutants","lemma":"ragoûtant","pos":"ADJ"} ,
		{"word":"raide","word_nosc":"raide","lemma":"raide","pos":"ADJ"} ,
		{"word":"raides","word_nosc":"raides","lemma":"raide","pos":"ADJ"} ,
		{"word":"railleur","word_nosc":"railleur","lemma":"railleur","pos":"ADJ"} ,
		{"word":"railleurs","word_nosc":"railleurs","lemma":"railleur","pos":"ADJ"} ,
		{"word":"railleuse","word_nosc":"railleuse","lemma":"railleur","pos":"ADJ"} ,
		{"word":"railleuses","word_nosc":"railleuses","lemma":"railleur","pos":"ADJ"} ,
		{"word":"raisonnable","word_nosc":"raisonnable","lemma":"raisonnable","pos":"ADJ"} ,
		{"word":"raisonnables","word_nosc":"raisonnables","lemma":"raisonnable","pos":"ADJ"} ,
		{"word":"raisonnante","word_nosc":"raisonnante","lemma":"raisonnant","pos":"ADJ"} ,
		{"word":"raisonneur","word_nosc":"raisonneur","lemma":"raisonneur","pos":"ADJ"} ,
		{"word":"raisonneurs","word_nosc":"raisonneurs","lemma":"raisonneur","pos":"ADJ"} ,
		{"word":"raisonneuse","word_nosc":"raisonneuse","lemma":"raisonneur","pos":"ADJ"} ,
		{"word":"raisonné","word_nosc":"raisonne","lemma":"raisonné","pos":"ADJ"} ,
		{"word":"raisonnée","word_nosc":"raisonnee","lemma":"raisonné","pos":"ADJ"} ,
		{"word":"rajeunissant","word_nosc":"rajeunissant","lemma":"rajeunissant","pos":"ADJ"} ,
		{"word":"rajeunissante","word_nosc":"rajeunissante","lemma":"rajeunissant","pos":"ADJ"} ,
		{"word":"rallié","word_nosc":"rallie","lemma":"rallié","pos":"ADJ"} ,
		{"word":"ralliés","word_nosc":"rallies","lemma":"rallié","pos":"ADJ"} ,
		{"word":"ramassé","word_nosc":"ramasse","lemma":"ramassé","pos":"ADJ"} ,
		{"word":"ramassée","word_nosc":"ramassee","lemma":"ramassé","pos":"ADJ"} ,
		{"word":"ramassées","word_nosc":"ramassees","lemma":"ramassé","pos":"ADJ"} ,
		{"word":"ramassés","word_nosc":"ramasses","lemma":"ramassé","pos":"ADJ"} ,
		{"word":"ramenard","word_nosc":"ramenard","lemma":"ramenard","pos":"ADJ"} ,
		{"word":"ramenards","word_nosc":"ramenards","lemma":"ramenard","pos":"ADJ"} ,
		{"word":"ramier","word_nosc":"ramier","lemma":"ramier","pos":"ADJ"} ,
		{"word":"ramiers","word_nosc":"ramiers","lemma":"ramier","pos":"ADJ"} ,
		{"word":"ramifiant","word_nosc":"ramifiant","lemma":"ramifiant","pos":"ADJ"} ,
		{"word":"ramifié","word_nosc":"ramifie","lemma":"ramifié","pos":"ADJ"} ,
		{"word":"ramifiée","word_nosc":"ramifiee","lemma":"ramifié","pos":"ADJ"} ,
		{"word":"ramifiées","word_nosc":"ramifiees","lemma":"ramifié","pos":"ADJ"} ,
		{"word":"ramifiés","word_nosc":"ramifies","lemma":"ramifié","pos":"ADJ"} ,
		{"word":"ramolli","word_nosc":"ramolli","lemma":"ramolli","pos":"ADJ"} ,
		{"word":"ramollie","word_nosc":"ramollie","lemma":"ramolli","pos":"ADJ"} ,
		{"word":"ramollies","word_nosc":"ramollies","lemma":"ramolli","pos":"ADJ"} ,
		{"word":"ramollis","word_nosc":"ramollis","lemma":"ramolli","pos":"ADJ"} ,
		{"word":"ramollo","word_nosc":"ramollo","lemma":"ramollo","pos":"ADJ"} ,
		{"word":"ramollos","word_nosc":"ramollos","lemma":"ramollo","pos":"ADJ"} ,
		{"word":"rampant","word_nosc":"rampant","lemma":"rampant","pos":"ADJ"} ,
		{"word":"rampante","word_nosc":"rampante","lemma":"rampant","pos":"ADJ"} ,
		{"word":"rampantes","word_nosc":"rampantes","lemma":"rampant","pos":"ADJ"} ,
		{"word":"rampants","word_nosc":"rampants","lemma":"rampant","pos":"ADJ"} ,
		{"word":"rance","word_nosc":"rance","lemma":"rance","pos":"ADJ"} ,
		{"word":"rances","word_nosc":"rances","lemma":"rance","pos":"ADJ"} ,
		{"word":"ranci","word_nosc":"ranci","lemma":"ranci","pos":"ADJ"} ,
		{"word":"rancie","word_nosc":"rancie","lemma":"ranci","pos":"ADJ"} ,
		{"word":"rancies","word_nosc":"rancies","lemma":"ranci","pos":"ADJ"} ,
		{"word":"rancis","word_nosc":"rancis","lemma":"ranci","pos":"ADJ"} ,
		{"word":"rancuneuses","word_nosc":"rancuneuses","lemma":"rancuneux","pos":"ADJ"} ,
		{"word":"rancunier","word_nosc":"rancunier","lemma":"rancunier","pos":"ADJ"} ,
		{"word":"rancuniers","word_nosc":"rancuniers","lemma":"rancunier","pos":"ADJ"} ,
		{"word":"rancunière","word_nosc":"rancuniere","lemma":"rancunier","pos":"ADJ"} ,
		{"word":"rancunières","word_nosc":"rancunieres","lemma":"rancunier","pos":"ADJ"} ,
		{"word":"rangé","word_nosc":"range","lemma":"rangé","pos":"ADJ"} ,
		{"word":"rangée","word_nosc":"rangee","lemma":"rangé","pos":"ADJ"} ,
		{"word":"rangées","word_nosc":"rangees","lemma":"rangé","pos":"ADJ"} ,
		{"word":"rangés","word_nosc":"ranges","lemma":"rangé","pos":"ADJ"} ,
		{"word":"rapace","word_nosc":"rapace","lemma":"rapace","pos":"ADJ"} ,
		{"word":"rapaces","word_nosc":"rapaces","lemma":"rapace","pos":"ADJ"} ,
		{"word":"rapatriés","word_nosc":"rapatries","lemma":"rapatrié","pos":"ADJ"} ,
		{"word":"raphaélesque","word_nosc":"raphaelesque","lemma":"raphaélesque","pos":"ADJ"} ,
		{"word":"rapiat","word_nosc":"rapiat","lemma":"rapiat","pos":"ADJ"} ,
		{"word":"rapide","word_nosc":"rapide","lemma":"rapide","pos":"ADJ"} ,
		{"word":"rapides","word_nosc":"rapides","lemma":"rapide","pos":"ADJ"} ,
		{"word":"raplapla","word_nosc":"raplapla","lemma":"raplapla","pos":"ADJ"} ,
		{"word":"rappelé","word_nosc":"rappele","lemma":"rappelé","pos":"ADJ"} ,
		{"word":"rappelée","word_nosc":"rappelee","lemma":"rappelé","pos":"ADJ"} ,
		{"word":"rappelés","word_nosc":"rappeles","lemma":"rappelé","pos":"ADJ"} ,
		{"word":"rapporteur","word_nosc":"rapporteur","lemma":"rapporteur","pos":"ADJ"} ,
		{"word":"rapporteuse","word_nosc":"rapporteuse","lemma":"rapporteur","pos":"ADJ"} ,
		{"word":"rapporteuses","word_nosc":"rapporteuses","lemma":"rapporteur","pos":"ADJ"} ,
		{"word":"rapporté","word_nosc":"rapporte","lemma":"rapporté","pos":"ADJ"} ,
		{"word":"rapportée","word_nosc":"rapportee","lemma":"rapporté","pos":"ADJ"} ,
		{"word":"rapportées","word_nosc":"rapportees","lemma":"rapporté","pos":"ADJ"} ,
		{"word":"rapportés","word_nosc":"rapportes","lemma":"rapporté","pos":"ADJ"} ,
		{"word":"rapproché","word_nosc":"rapproche","lemma":"rapproché","pos":"ADJ"} ,
		{"word":"rapprochée","word_nosc":"rapprochee","lemma":"rapproché","pos":"ADJ"} ,
		{"word":"rapprochées","word_nosc":"rapprochees","lemma":"rapproché","pos":"ADJ"} ,
		{"word":"rapprochés","word_nosc":"rapproches","lemma":"rapproché","pos":"ADJ"} ,
		{"word":"rare","word_nosc":"rare","lemma":"rare","pos":"ADJ"} ,
		{"word":"rares","word_nosc":"rares","lemma":"rare","pos":"ADJ"} ,
		{"word":"rarissime","word_nosc":"rarissime","lemma":"rarissime","pos":"ADJ"} ,
		{"word":"rarissimes","word_nosc":"rarissimes","lemma":"rarissime","pos":"ADJ"} ,
		{"word":"raréfié","word_nosc":"rarefie","lemma":"raréfié","pos":"ADJ"} ,
		{"word":"raréfiée","word_nosc":"rarefiee","lemma":"raréfié","pos":"ADJ"} ,
		{"word":"raréfiées","word_nosc":"rarefiees","lemma":"raréfié","pos":"ADJ"} ,
		{"word":"ras","word_nosc":"ras","lemma":"ras","pos":"ADJ"} ,
		{"word":"rasant","word_nosc":"rasant","lemma":"rasant","pos":"ADJ"} ,
		{"word":"rasante","word_nosc":"rasante","lemma":"rasant","pos":"ADJ"} ,
		{"word":"rasantes","word_nosc":"rasantes","lemma":"rasant","pos":"ADJ"} ,
		{"word":"rasants","word_nosc":"rasants","lemma":"rasant","pos":"ADJ"} ,
		{"word":"rase","word_nosc":"rase","lemma":"ras","pos":"ADJ"} ,
		{"word":"rases","word_nosc":"rases","lemma":"ras","pos":"ADJ"} ,
		{"word":"raseur","word_nosc":"raseur","lemma":"raseur","pos":"ADJ"} ,
		{"word":"raseurs","word_nosc":"raseurs","lemma":"raseur","pos":"ADJ"} ,
		{"word":"rasoir","word_nosc":"rasoir","lemma":"rasoir","pos":"ADJ"} ,
		{"word":"rassasiant","word_nosc":"rassasiant","lemma":"rassasiant","pos":"ADJ"} ,
		{"word":"rassasiantes","word_nosc":"rassasiantes","lemma":"rassasiant","pos":"ADJ"} ,
		{"word":"rassasié","word_nosc":"rassasie","lemma":"rassasié","pos":"ADJ"} ,
		{"word":"rassasiée","word_nosc":"rassasiee","lemma":"rassasié","pos":"ADJ"} ,
		{"word":"rassasiées","word_nosc":"rassasiees","lemma":"rassasié","pos":"ADJ"} ,
		{"word":"rassasiés","word_nosc":"rassasies","lemma":"rassasié","pos":"ADJ"} ,
		{"word":"rassis","word_nosc":"rassis","lemma":"rassis","pos":"ADJ"} ,
		{"word":"rassise","word_nosc":"rassise","lemma":"rassis","pos":"ADJ"} ,
		{"word":"rassurant","word_nosc":"rassurant","lemma":"rassurant","pos":"ADJ"} ,
		{"word":"rassurante","word_nosc":"rassurante","lemma":"rassurant","pos":"ADJ"} ,
		{"word":"rassurantes","word_nosc":"rassurantes","lemma":"rassurant","pos":"ADJ"} ,
		{"word":"rassurants","word_nosc":"rassurants","lemma":"rassurant","pos":"ADJ"} ,
		{"word":"rastafari","word_nosc":"rastafari","lemma":"rastafari","pos":"ADJ"} ,
		{"word":"ratatiné","word_nosc":"ratatine","lemma":"ratatiné","pos":"ADJ"} ,
		{"word":"ratatinée","word_nosc":"ratatinee","lemma":"ratatiné","pos":"ADJ"} ,
		{"word":"ratatinées","word_nosc":"ratatinees","lemma":"ratatiné","pos":"ADJ"} ,
		{"word":"ratatinés","word_nosc":"ratatines","lemma":"ratatiné","pos":"ADJ"} ,
		{"word":"rationaliste","word_nosc":"rationaliste","lemma":"rationaliste","pos":"ADJ"} ,
		{"word":"rationalistes","word_nosc":"rationalistes","lemma":"rationaliste","pos":"ADJ"} ,
		{"word":"rationalisée","word_nosc":"rationalisee","lemma":"rationalisé","pos":"ADJ"} ,
		{"word":"rationnel","word_nosc":"rationnel","lemma":"rationnel","pos":"ADJ"} ,
		{"word":"rationnelle","word_nosc":"rationnelle","lemma":"rationnel","pos":"ADJ"} ,
		{"word":"rationnelles","word_nosc":"rationnelles","lemma":"rationnel","pos":"ADJ"} ,
		{"word":"rationnels","word_nosc":"rationnels","lemma":"rationnel","pos":"ADJ"} ,
		{"word":"ratisseur","word_nosc":"ratisseur","lemma":"ratisseur","pos":"ADJ"} ,
		{"word":"raté","word_nosc":"rate","lemma":"raté","pos":"ADJ"} ,
		{"word":"ratée","word_nosc":"ratee","lemma":"raté","pos":"ADJ"} ,
		{"word":"ratées","word_nosc":"ratees","lemma":"raté","pos":"ADJ"} ,
		{"word":"ratés","word_nosc":"rates","lemma":"raté","pos":"ADJ"} ,
		{"word":"rauque","word_nosc":"rauque","lemma":"rauque","pos":"ADJ"} ,
		{"word":"rauques","word_nosc":"rauques","lemma":"rauque","pos":"ADJ"} ,
		{"word":"ravageant","word_nosc":"ravageant","lemma":"ravageant","pos":"ADJ"} ,
		{"word":"ravageante","word_nosc":"ravageante","lemma":"ravageant","pos":"ADJ"} ,
		{"word":"ravageur","word_nosc":"ravageur","lemma":"ravageur","pos":"ADJ"} ,
		{"word":"ravageurs","word_nosc":"ravageurs","lemma":"ravageur","pos":"ADJ"} ,
		{"word":"ravageuse","word_nosc":"ravageuse","lemma":"ravageur","pos":"ADJ"} ,
		{"word":"ravageuses","word_nosc":"ravageuses","lemma":"ravageur","pos":"ADJ"} ,
		{"word":"ravagé","word_nosc":"ravage","lemma":"ravagé","pos":"ADJ"} ,
		{"word":"ravagée","word_nosc":"ravagee","lemma":"ravagé","pos":"ADJ"} ,
		{"word":"ravagées","word_nosc":"ravagees","lemma":"ravagé","pos":"ADJ"} ,
		{"word":"ravagés","word_nosc":"ravages","lemma":"ravagé","pos":"ADJ"} ,
		{"word":"ravennate","word_nosc":"ravennate","lemma":"ravennate","pos":"ADJ"} ,
		{"word":"ravi","word_nosc":"ravi","lemma":"ravi","pos":"ADJ"} ,
		{"word":"ravie","word_nosc":"ravie","lemma":"ravi","pos":"ADJ"} ,
		{"word":"ravies","word_nosc":"ravies","lemma":"ravi","pos":"ADJ"} ,
		{"word":"ravigotant","word_nosc":"ravigotant","lemma":"ravigotant","pos":"ADJ"} ,
		{"word":"ravigotants","word_nosc":"ravigotants","lemma":"ravigotant","pos":"ADJ"} ,
		{"word":"ravis","word_nosc":"ravis","lemma":"ravi","pos":"ADJ"} ,
		{"word":"ravissant","word_nosc":"ravissant","lemma":"ravissant","pos":"ADJ"} ,
		{"word":"ravissante","word_nosc":"ravissante","lemma":"ravissant","pos":"ADJ"} ,
		{"word":"ravissantes","word_nosc":"ravissantes","lemma":"ravissant","pos":"ADJ"} ,
		{"word":"ravissants","word_nosc":"ravissants","lemma":"ravissant","pos":"ADJ"} ,
		{"word":"rayables","word_nosc":"rayables","lemma":"rayable","pos":"ADJ"} ,
		{"word":"rayonnant","word_nosc":"rayonnant","lemma":"rayonnant","pos":"ADJ"} ,
		{"word":"rayonnante","word_nosc":"rayonnante","lemma":"rayonnant","pos":"ADJ"} ,
		{"word":"rayonnantes","word_nosc":"rayonnantes","lemma":"rayonnant","pos":"ADJ"} ,
		{"word":"rayonnants","word_nosc":"rayonnants","lemma":"rayonnant","pos":"ADJ"} ,
		{"word":"rayé","word_nosc":"raye","lemma":"rayé","pos":"ADJ"} ,
		{"word":"rayée","word_nosc":"rayee","lemma":"rayé","pos":"ADJ"} ,
		{"word":"rayées","word_nosc":"rayees","lemma":"rayé","pos":"ADJ"} ,
		{"word":"rayés","word_nosc":"rayes","lemma":"rayé","pos":"ADJ"} ,
		{"word":"re-balbutiant","word_nosc":"re-balbutiant","lemma":"rebalbutiant","pos":"ADJ"} ,
		{"word":"re-café","word_nosc":"re-cafe","lemma":"recafé","pos":"ADJ"} ,
		{"word":"re-nés","word_nosc":"re-nes","lemma":"rené","pos":"ADJ"} ,
		{"word":"re-rêvé","word_nosc":"re-reve","lemma":"rerêvé","pos":"ADJ"} ,
		{"word":"re-séparés","word_nosc":"re-separes","lemma":"reséparé","pos":"ADJ"} ,
		{"word":"re-traitée","word_nosc":"re-traitee","lemma":"retraité","pos":"ADJ"} ,
		{"word":"re-tues","word_nosc":"re-tues","lemma":"retu","pos":"ADJ"} ,
		{"word":"re-vérifié","word_nosc":"re-verifie","lemma":"revérifié","pos":"ADJ"} ,
		{"word":"rebarré","word_nosc":"rebarre","lemma":"rebarré","pos":"ADJ"} ,
		{"word":"rebattu","word_nosc":"rebattu","lemma":"rebattu","pos":"ADJ"} ,
		{"word":"rebattue","word_nosc":"rebattue","lemma":"rebattu","pos":"ADJ"} ,
		{"word":"rebattues","word_nosc":"rebattues","lemma":"rebattu","pos":"ADJ"} ,
		{"word":"rebattus","word_nosc":"rebattus","lemma":"rebattu","pos":"ADJ"} ,
		{"word":"rebelle","word_nosc":"rebelle","lemma":"rebelle","pos":"ADJ"} ,
		{"word":"rebelles","word_nosc":"rebelles","lemma":"rebelle","pos":"ADJ"} ,
		{"word":"rebondi","word_nosc":"rebondi","lemma":"rebondi","pos":"ADJ"} ,
		{"word":"rebondie","word_nosc":"rebondie","lemma":"rebondi","pos":"ADJ"} ,
		{"word":"rebondies","word_nosc":"rebondies","lemma":"rebondi","pos":"ADJ"} ,
		{"word":"rebondis","word_nosc":"rebondis","lemma":"rebondi","pos":"ADJ"} ,
		{"word":"rebondissant","word_nosc":"rebondissant","lemma":"rebondissant","pos":"ADJ"} ,
		{"word":"rebondissante","word_nosc":"rebondissante","lemma":"rebondissant","pos":"ADJ"} ,
		{"word":"rebondissantes","word_nosc":"rebondissantes","lemma":"rebondissant","pos":"ADJ"} ,
		{"word":"rebondissants","word_nosc":"rebondissants","lemma":"rebondissant","pos":"ADJ"} ,
		{"word":"rebutant","word_nosc":"rebutant","lemma":"rebutant","pos":"ADJ"} ,
		{"word":"rebutante","word_nosc":"rebutante","lemma":"rebutant","pos":"ADJ"} ,
		{"word":"rebutantes","word_nosc":"rebutantes","lemma":"rebutant","pos":"ADJ"} ,
		{"word":"rebutants","word_nosc":"rebutants","lemma":"rebutant","pos":"ADJ"} ,
		{"word":"recalé","word_nosc":"recale","lemma":"recalé","pos":"ADJ"} ,
		{"word":"recalée","word_nosc":"recalee","lemma":"recalé","pos":"ADJ"} ,
		{"word":"recalés","word_nosc":"recales","lemma":"recalé","pos":"ADJ"} ,
		{"word":"recasable","word_nosc":"recasable","lemma":"recasable","pos":"ADJ"} ,
		{"word":"recevable","word_nosc":"recevable","lemma":"recevable","pos":"ADJ"} ,
		{"word":"recevables","word_nosc":"recevables","lemma":"recevable","pos":"ADJ"} ,
		{"word":"rechargeable","word_nosc":"rechargeable","lemma":"rechargeable","pos":"ADJ"} ,
		{"word":"recherché","word_nosc":"recherche","lemma":"recherché","pos":"ADJ"} ,
		{"word":"recherchée","word_nosc":"recherchee","lemma":"recherché","pos":"ADJ"} ,
		{"word":"recherchées","word_nosc":"recherchees","lemma":"recherché","pos":"ADJ"} ,
		{"word":"recherchés","word_nosc":"recherches","lemma":"recherché","pos":"ADJ"} ,
		{"word":"reclus","word_nosc":"reclus","lemma":"reclus","pos":"ADJ"} ,
		{"word":"recluse","word_nosc":"recluse","lemma":"reclus","pos":"ADJ"} ,
		{"word":"recluses","word_nosc":"recluses","lemma":"reclus","pos":"ADJ"} ,
		{"word":"recombinant","word_nosc":"recombinant","lemma":"recombinant","pos":"ADJ"} ,
		{"word":"recommandable","word_nosc":"recommandable","lemma":"recommandable","pos":"ADJ"} ,
		{"word":"recommandables","word_nosc":"recommandables","lemma":"recommandable","pos":"ADJ"} ,
		{"word":"recommandé","word_nosc":"recommande","lemma":"recommandé","pos":"ADJ"} ,
		{"word":"recommandée","word_nosc":"recommandee","lemma":"recommandé","pos":"ADJ"} ,
		{"word":"recommandées","word_nosc":"recommandees","lemma":"recommandé","pos":"ADJ"} ,
		{"word":"recommandés","word_nosc":"recommandes","lemma":"recommandé","pos":"ADJ"} ,
		{"word":"reconductible","word_nosc":"reconductible","lemma":"reconductible","pos":"ADJ"} ,
		{"word":"reconnaissable","word_nosc":"reconnaissable","lemma":"reconnaissable","pos":"ADJ"} ,
		{"word":"reconnaissables","word_nosc":"reconnaissables","lemma":"reconnaissable","pos":"ADJ"} ,
		{"word":"reconnaissant","word_nosc":"reconnaissant","lemma":"reconnaissant","pos":"ADJ"} ,
		{"word":"reconnaissante","word_nosc":"reconnaissante","lemma":"reconnaissant","pos":"ADJ"} ,
		{"word":"reconnaissantes","word_nosc":"reconnaissantes","lemma":"reconnaissant","pos":"ADJ"} ,
		{"word":"reconnaissants","word_nosc":"reconnaissants","lemma":"reconnaissant","pos":"ADJ"} ,
		{"word":"reconnu","word_nosc":"reconnu","lemma":"reconnu","pos":"ADJ"} ,
		{"word":"reconnue","word_nosc":"reconnue","lemma":"reconnu","pos":"ADJ"} ,
		{"word":"reconnues","word_nosc":"reconnues","lemma":"reconnu","pos":"ADJ"} ,
		{"word":"reconnus","word_nosc":"reconnus","lemma":"reconnu","pos":"ADJ"} ,
		{"word":"reconquis","word_nosc":"reconquis","lemma":"reconquis","pos":"ADJ"} ,
		{"word":"reconquise","word_nosc":"reconquise","lemma":"reconquis","pos":"ADJ"} ,
		{"word":"reconquises","word_nosc":"reconquises","lemma":"reconquis","pos":"ADJ"} ,
		{"word":"reconstituant","word_nosc":"reconstituant","lemma":"reconstituant","pos":"ADJ"} ,
		{"word":"reconstituante","word_nosc":"reconstituante","lemma":"reconstituant","pos":"ADJ"} ,
		{"word":"reconstituantes","word_nosc":"reconstituantes","lemma":"reconstituant","pos":"ADJ"} ,
		{"word":"reconstituants","word_nosc":"reconstituants","lemma":"reconstituant","pos":"ADJ"} ,
		{"word":"reconstructrice","word_nosc":"reconstructrice","lemma":"reconstructeur","pos":"ADJ"} ,
		{"word":"reconventionnelle","word_nosc":"reconventionnelle","lemma":"reconventionnel","pos":"ADJ"} ,
		{"word":"recourbé","word_nosc":"recourbe","lemma":"recourbé","pos":"ADJ"} ,
		{"word":"recourbée","word_nosc":"recourbee","lemma":"recourbé","pos":"ADJ"} ,
		{"word":"recourbées","word_nosc":"recourbees","lemma":"recourbé","pos":"ADJ"} ,
		{"word":"recourbés","word_nosc":"recourbes","lemma":"recourbé","pos":"ADJ"} ,
		{"word":"recouvert","word_nosc":"recouvert","lemma":"recouvert","pos":"ADJ"} ,
		{"word":"recouverte","word_nosc":"recouverte","lemma":"recouvert","pos":"ADJ"} ,
		{"word":"recouvertes","word_nosc":"recouvertes","lemma":"recouvert","pos":"ADJ"} ,
		{"word":"recouverts","word_nosc":"recouverts","lemma":"recouvert","pos":"ADJ"} ,
		{"word":"recouvrables","word_nosc":"recouvrables","lemma":"recouvrable","pos":"ADJ"} ,
		{"word":"recouvrant","word_nosc":"recouvrant","lemma":"recouvrant","pos":"ADJ"} ,
		{"word":"recroquevillé","word_nosc":"recroqueville","lemma":"recroquevillé","pos":"ADJ"} ,
		{"word":"recroquevillée","word_nosc":"recroquevillee","lemma":"recroquevillé","pos":"ADJ"} ,
		{"word":"recroquevillées","word_nosc":"recroquevillees","lemma":"recroquevillé","pos":"ADJ"} ,
		{"word":"recroquevillés","word_nosc":"recroquevilles","lemma":"recroquevillé","pos":"ADJ"} ,
		{"word":"recru","word_nosc":"recru","lemma":"recru","pos":"ADJ"} ,
		{"word":"recrudescente","word_nosc":"recrudescente","lemma":"recrudescent","pos":"ADJ"} ,
		{"word":"recrue","word_nosc":"recrue","lemma":"recru","pos":"ADJ"} ,
		{"word":"recrues","word_nosc":"recrues","lemma":"recru","pos":"ADJ"} ,
		{"word":"recrus","word_nosc":"recrus","lemma":"recru","pos":"ADJ"} ,
		{"word":"rectal","word_nosc":"rectal","lemma":"rectal","pos":"ADJ"} ,
		{"word":"rectale","word_nosc":"rectale","lemma":"rectal","pos":"ADJ"} ,
		{"word":"rectangle","word_nosc":"rectangle","lemma":"rectangle","pos":"ADJ"} ,
		{"word":"rectangles","word_nosc":"rectangles","lemma":"rectangle","pos":"ADJ"} ,
		{"word":"rectangulaire","word_nosc":"rectangulaire","lemma":"rectangulaire","pos":"ADJ"} ,
		{"word":"rectangulaires","word_nosc":"rectangulaires","lemma":"rectangulaire","pos":"ADJ"} ,
		{"word":"rectaux","word_nosc":"rectaux","lemma":"rectal","pos":"ADJ"} ,
		{"word":"recteur","word_nosc":"recteur","lemma":"recteur","pos":"ADJ"} ,
		{"word":"recteurs","word_nosc":"recteurs","lemma":"recteur","pos":"ADJ"} ,
		{"word":"rectiligne","word_nosc":"rectiligne","lemma":"rectiligne","pos":"ADJ"} ,
		{"word":"rectilignes","word_nosc":"rectilignes","lemma":"rectiligne","pos":"ADJ"} ,
		{"word":"rectilinéaire","word_nosc":"rectilineaire","lemma":"rectilinéaire","pos":"ADJ"} ,
		{"word":"recueilli","word_nosc":"recueilli","lemma":"recueilli","pos":"ADJ"} ,
		{"word":"recueillie","word_nosc":"recueillie","lemma":"recueilli","pos":"ADJ"} ,
		{"word":"recueillies","word_nosc":"recueillies","lemma":"recueilli","pos":"ADJ"} ,
		{"word":"recueillis","word_nosc":"recueillis","lemma":"recueilli","pos":"ADJ"} ,
		{"word":"recuit","word_nosc":"recuit","lemma":"recuit","pos":"ADJ"} ,
		{"word":"recuite","word_nosc":"recuite","lemma":"recuit","pos":"ADJ"} ,
		{"word":"recuites","word_nosc":"recuites","lemma":"recuit","pos":"ADJ"} ,
		{"word":"recuits","word_nosc":"recuits","lemma":"recuit","pos":"ADJ"} ,
		{"word":"reculant","word_nosc":"reculant","lemma":"reculant","pos":"ADJ"} ,
		{"word":"reculé","word_nosc":"recule","lemma":"reculé","pos":"ADJ"} ,
		{"word":"reculée","word_nosc":"reculee","lemma":"reculé","pos":"ADJ"} ,
		{"word":"reculées","word_nosc":"reculees","lemma":"reculé","pos":"ADJ"} ,
		{"word":"reculés","word_nosc":"recules","lemma":"reculé","pos":"ADJ"} ,
		{"word":"recyclable","word_nosc":"recyclable","lemma":"recyclable","pos":"ADJ"} ,
		{"word":"recyclables","word_nosc":"recyclables","lemma":"recyclable","pos":"ADJ"} ,
		{"word":"redevable","word_nosc":"redevable","lemma":"redevable","pos":"ADJ"} ,
		{"word":"redevables","word_nosc":"redevables","lemma":"redevable","pos":"ADJ"} ,
		{"word":"redondant","word_nosc":"redondant","lemma":"redondant","pos":"ADJ"} ,
		{"word":"redondante","word_nosc":"redondante","lemma":"redondant","pos":"ADJ"} ,
		{"word":"redondantes","word_nosc":"redondantes","lemma":"redondant","pos":"ADJ"} ,
		{"word":"redondants","word_nosc":"redondants","lemma":"redondant","pos":"ADJ"} ,
		{"word":"redoublant","word_nosc":"redoublant","lemma":"redoublant","pos":"ADJ"} ,
		{"word":"redoublé","word_nosc":"redouble","lemma":"redoublé","pos":"ADJ"} ,
		{"word":"redoublée","word_nosc":"redoublee","lemma":"redoublé","pos":"ADJ"} ,
		{"word":"redoublées","word_nosc":"redoublees","lemma":"redoublé","pos":"ADJ"} ,
		{"word":"redoublés","word_nosc":"redoubles","lemma":"redoublé","pos":"ADJ"} ,
		{"word":"redoutable","word_nosc":"redoutable","lemma":"redoutable","pos":"ADJ"} ,
		{"word":"redoutables","word_nosc":"redoutables","lemma":"redoutable","pos":"ADJ"} ,
		{"word":"redresseur","word_nosc":"redresseur","lemma":"redresseur","pos":"ADJ"} ,
		{"word":"redresseuse","word_nosc":"redresseuse","lemma":"redresseur","pos":"ADJ"} ,
		{"word":"redécoré","word_nosc":"redecore","lemma":"redécoré","pos":"ADJ"} ,
		{"word":"redécouverts","word_nosc":"redecouverts","lemma":"redécouvert","pos":"ADJ"} ,
		{"word":"refait","word_nosc":"refait","lemma":"refait","pos":"ADJ"} ,
		{"word":"refaite","word_nosc":"refaite","lemma":"refait","pos":"ADJ"} ,
		{"word":"refaites","word_nosc":"refaites","lemma":"refait","pos":"ADJ"} ,
		{"word":"refaits","word_nosc":"refaits","lemma":"refait","pos":"ADJ"} ,
		{"word":"reflex","word_nosc":"reflex","lemma":"reflex","pos":"ADJ"} ,
		{"word":"refoulant","word_nosc":"refoulant","lemma":"refoulant","pos":"ADJ"} ,
		{"word":"refoulante","word_nosc":"refoulante","lemma":"refoulant","pos":"ADJ"} ,
		{"word":"refoulé","word_nosc":"refoule","lemma":"refoulé","pos":"ADJ"} ,
		{"word":"refoulée","word_nosc":"refoulee","lemma":"refoulé","pos":"ADJ"} ,
		{"word":"refoulées","word_nosc":"refoulees","lemma":"refoulé","pos":"ADJ"} ,
		{"word":"refoulés","word_nosc":"refoules","lemma":"refoulé","pos":"ADJ"} ,
		{"word":"refroidi","word_nosc":"refroidi","lemma":"refroidi","pos":"ADJ"} ,
		{"word":"refroidie","word_nosc":"refroidie","lemma":"refroidi","pos":"ADJ"} ,
		{"word":"refroidies","word_nosc":"refroidies","lemma":"refroidi","pos":"ADJ"} ,
		{"word":"refroidis","word_nosc":"refroidis","lemma":"refroidi","pos":"ADJ"} ,
		{"word":"refroidissant","word_nosc":"refroidissant","lemma":"refroidissant","pos":"ADJ"} ,
		{"word":"refusé","word_nosc":"refuse","lemma":"refusé","pos":"ADJ"} ,
		{"word":"refusée","word_nosc":"refusee","lemma":"refusé","pos":"ADJ"} ,
		{"word":"refusées","word_nosc":"refusees","lemma":"refusé","pos":"ADJ"} ,
		{"word":"refusés","word_nosc":"refuses","lemma":"refusé","pos":"ADJ"} ,
		{"word":"regardable","word_nosc":"regardable","lemma":"regardable","pos":"ADJ"} ,
		{"word":"regardables","word_nosc":"regardables","lemma":"regardable","pos":"ADJ"} ,
		{"word":"regardant","word_nosc":"regardant","lemma":"regardant","pos":"ADJ"} ,
		{"word":"regardante","word_nosc":"regardante","lemma":"regardant","pos":"ADJ"} ,
		{"word":"regardants","word_nosc":"regardants","lemma":"regardant","pos":"ADJ"} ,
		{"word":"regrettable","word_nosc":"regrettable","lemma":"regrettable","pos":"ADJ"} ,
		{"word":"regrettables","word_nosc":"regrettables","lemma":"regrettable","pos":"ADJ"} ,
		{"word":"rehausseur","word_nosc":"rehausseur","lemma":"rehausseur","pos":"ADJ"} ,
		{"word":"rejetable","word_nosc":"rejetable","lemma":"rejetable","pos":"ADJ"} ,
		{"word":"rejeté","word_nosc":"rejete","lemma":"rejeté","pos":"ADJ"} ,
		{"word":"rejetée","word_nosc":"rejetee","lemma":"rejeté","pos":"ADJ"} ,
		{"word":"rejetées","word_nosc":"rejetees","lemma":"rejeté","pos":"ADJ"} ,
		{"word":"rejetés","word_nosc":"rejetes","lemma":"rejeté","pos":"ADJ"} ,
		{"word":"rejoint","word_nosc":"rejoint","lemma":"rejoint","pos":"ADJ"} ,
		{"word":"rejoints","word_nosc":"rejoints","lemma":"rejoint","pos":"ADJ"} ,
		{"word":"relaps","word_nosc":"relaps","lemma":"relaps","pos":"ADJ"} ,
		{"word":"relapse","word_nosc":"relapse","lemma":"relaps","pos":"ADJ"} ,
		{"word":"relapses","word_nosc":"relapses","lemma":"relaps","pos":"ADJ"} ,
		{"word":"relargué","word_nosc":"relargue","lemma":"relargué","pos":"ADJ"} ,
		{"word":"relatif","word_nosc":"relatif","lemma":"relatif","pos":"ADJ"} ,
		{"word":"relatifs","word_nosc":"relatifs","lemma":"relatif","pos":"ADJ"} ,
		{"word":"relationnel","word_nosc":"relationnel","lemma":"relationnel","pos":"ADJ"} ,
		{"word":"relationnelle","word_nosc":"relationnelle","lemma":"relationnel","pos":"ADJ"} ,
		{"word":"relationnelles","word_nosc":"relationnelles","lemma":"relationnel","pos":"ADJ"} ,
		{"word":"relationnels","word_nosc":"relationnels","lemma":"relationnel","pos":"ADJ"} ,
		{"word":"relative","word_nosc":"relative","lemma":"relatif","pos":"ADJ"} ,
		{"word":"relatives","word_nosc":"relatives","lemma":"relatif","pos":"ADJ"} ,
		{"word":"relativiste","word_nosc":"relativiste","lemma":"relativiste","pos":"ADJ"} ,
		{"word":"relax","word_nosc":"relax","lemma":"relax","pos":"ADJ"} ,
		{"word":"relaxant","word_nosc":"relaxant","lemma":"relaxant","pos":"ADJ"} ,
		{"word":"relaxante","word_nosc":"relaxante","lemma":"relaxant","pos":"ADJ"} ,
		{"word":"relaxantes","word_nosc":"relaxantes","lemma":"relaxant","pos":"ADJ"} ,
		{"word":"relaxants","word_nosc":"relaxants","lemma":"relaxant","pos":"ADJ"} ,
		{"word":"relaxe","word_nosc":"relaxe","lemma":"relaxe","pos":"ADJ"} ,
		{"word":"relaxes","word_nosc":"relaxes","lemma":"relaxe","pos":"ADJ"} ,
		{"word":"relevable","word_nosc":"relevable","lemma":"relevable","pos":"ADJ"} ,
		{"word":"relevant","word_nosc":"relevant","lemma":"relevant","pos":"ADJ"} ,
		{"word":"releveur","word_nosc":"releveur","lemma":"releveur","pos":"ADJ"} ,
		{"word":"releveurs","word_nosc":"releveurs","lemma":"releveur","pos":"ADJ"} ,
		{"word":"relevé","word_nosc":"releve","lemma":"relevé","pos":"ADJ"} ,
		{"word":"relevée","word_nosc":"relevee","lemma":"relevé","pos":"ADJ"} ,
		{"word":"relevées","word_nosc":"relevees","lemma":"relevé","pos":"ADJ"} ,
		{"word":"relevés","word_nosc":"releves","lemma":"relevé","pos":"ADJ"} ,
		{"word":"religieuse","word_nosc":"religieuse","lemma":"religieux","pos":"ADJ"} ,
		{"word":"religieuses","word_nosc":"religieuses","lemma":"religieux","pos":"ADJ"} ,
		{"word":"religieux","word_nosc":"religieux","lemma":"religieux","pos":"ADJ"} ,
		{"word":"relié","word_nosc":"relie","lemma":"relié","pos":"ADJ"} ,
		{"word":"reliée","word_nosc":"reliee","lemma":"relié","pos":"ADJ"} ,
		{"word":"reliées","word_nosc":"reliees","lemma":"relié","pos":"ADJ"} ,
		{"word":"reliés","word_nosc":"relies","lemma":"relié","pos":"ADJ"} ,
		{"word":"relu","word_nosc":"relu","lemma":"relu","pos":"ADJ"} ,
		{"word":"relue","word_nosc":"relue","lemma":"relu","pos":"ADJ"} ,
		{"word":"reluisant","word_nosc":"reluisant","lemma":"reluisant","pos":"ADJ"} ,
		{"word":"reluisante","word_nosc":"reluisante","lemma":"reluisant","pos":"ADJ"} ,
		{"word":"reluisantes","word_nosc":"reluisantes","lemma":"reluisant","pos":"ADJ"} ,
		{"word":"reluisants","word_nosc":"reluisants","lemma":"reluisant","pos":"ADJ"} ,
		{"word":"relus","word_nosc":"relus","lemma":"relu","pos":"ADJ"} ,
		{"word":"relâché","word_nosc":"relache","lemma":"relâché","pos":"ADJ"} ,
		{"word":"relâchée","word_nosc":"relachee","lemma":"relâché","pos":"ADJ"} ,
		{"word":"relâchées","word_nosc":"relachees","lemma":"relâché","pos":"ADJ"} ,
		{"word":"relâchés","word_nosc":"relaches","lemma":"relâché","pos":"ADJ"} ,
		{"word":"relégué","word_nosc":"relegue","lemma":"relégué","pos":"ADJ"} ,
		{"word":"reléguée","word_nosc":"releguee","lemma":"relégué","pos":"ADJ"} ,
		{"word":"reléguées","word_nosc":"releguees","lemma":"relégué","pos":"ADJ"} ,
		{"word":"relégués","word_nosc":"relegues","lemma":"relégué","pos":"ADJ"} ,
		{"word":"remarquable","word_nosc":"remarquable","lemma":"remarquable","pos":"ADJ"} ,
		{"word":"remarquables","word_nosc":"remarquables","lemma":"remarquable","pos":"ADJ"} ,
		{"word":"remboursable","word_nosc":"remboursable","lemma":"remboursable","pos":"ADJ"} ,
		{"word":"remis","word_nosc":"remis","lemma":"remis","pos":"ADJ"} ,
		{"word":"remise","word_nosc":"remise","lemma":"remis","pos":"ADJ"} ,
		{"word":"remises","word_nosc":"remises","lemma":"remis","pos":"ADJ"} ,
		{"word":"remontant","word_nosc":"remontant","lemma":"remontant","pos":"ADJ"} ,
		{"word":"remontantes","word_nosc":"remontantes","lemma":"remontant","pos":"ADJ"} ,
		{"word":"remontants","word_nosc":"remontants","lemma":"remontant","pos":"ADJ"} ,
		{"word":"remorqueur","word_nosc":"remorqueur","lemma":"remorqueur","pos":"ADJ"} ,
		{"word":"remorqueurs","word_nosc":"remorqueurs","lemma":"remorqueur","pos":"ADJ"} ,
		{"word":"remorqueuse","word_nosc":"remorqueuse","lemma":"remorqueur","pos":"ADJ"} ,
		{"word":"remplaçable","word_nosc":"remplacable","lemma":"remplaçable","pos":"ADJ"} ,
		{"word":"remplaçables","word_nosc":"remplacables","lemma":"remplaçable","pos":"ADJ"} ,
		{"word":"rempli","word_nosc":"rempli","lemma":"rempli","pos":"ADJ"} ,
		{"word":"remplie","word_nosc":"remplie","lemma":"rempli","pos":"ADJ"} ,
		{"word":"remplies","word_nosc":"remplies","lemma":"rempli","pos":"ADJ"} ,
		{"word":"remplis","word_nosc":"remplis","lemma":"rempli","pos":"ADJ"} ,
		{"word":"remuant","word_nosc":"remuant","lemma":"remuant","pos":"ADJ"} ,
		{"word":"remuante","word_nosc":"remuante","lemma":"remuant","pos":"ADJ"} ,
		{"word":"remuantes","word_nosc":"remuantes","lemma":"remuant","pos":"ADJ"} ,
		{"word":"remuants","word_nosc":"remuants","lemma":"remuant","pos":"ADJ"} ,
		{"word":"remédiable","word_nosc":"remediable","lemma":"remédiable","pos":"ADJ"} ,
		{"word":"remémorative","word_nosc":"rememorative","lemma":"remémoratif","pos":"ADJ"} ,
		{"word":"renaissant","word_nosc":"renaissant","lemma":"renaissant","pos":"ADJ"} ,
		{"word":"renaissante","word_nosc":"renaissante","lemma":"renaissant","pos":"ADJ"} ,
		{"word":"renaissantes","word_nosc":"renaissantes","lemma":"renaissant","pos":"ADJ"} ,
		{"word":"renaissants","word_nosc":"renaissants","lemma":"renaissant","pos":"ADJ"} ,
		{"word":"rencontré","word_nosc":"rencontre","lemma":"rencontré","pos":"ADJ"} ,
		{"word":"rencontrée","word_nosc":"rencontree","lemma":"rencontré","pos":"ADJ"} ,
		{"word":"rencontrées","word_nosc":"rencontrees","lemma":"rencontré","pos":"ADJ"} ,
		{"word":"rencontrés","word_nosc":"rencontres","lemma":"rencontré","pos":"ADJ"} ,
		{"word":"rendant","word_nosc":"rendant","lemma":"rendant","pos":"ADJ"} ,
		{"word":"rendu","word_nosc":"rendu","lemma":"rendu","pos":"ADJ"} ,
		{"word":"rendue","word_nosc":"rendue","lemma":"rendu","pos":"ADJ"} ,
		{"word":"rendues","word_nosc":"rendues","lemma":"rendu","pos":"ADJ"} ,
		{"word":"rendus","word_nosc":"rendus","lemma":"rendu","pos":"ADJ"} ,
		{"word":"renfermé","word_nosc":"renferme","lemma":"renfermé","pos":"ADJ"} ,
		{"word":"renfermée","word_nosc":"renfermee","lemma":"renfermé","pos":"ADJ"} ,
		{"word":"renfermés","word_nosc":"renfermes","lemma":"renfermé","pos":"ADJ"} ,
		{"word":"renflé","word_nosc":"renfle","lemma":"renflé","pos":"ADJ"} ,
		{"word":"renflée","word_nosc":"renflee","lemma":"renflé","pos":"ADJ"} ,
		{"word":"renflées","word_nosc":"renflees","lemma":"renflé","pos":"ADJ"} ,
		{"word":"renfrogné","word_nosc":"renfrogne","lemma":"renfrogné","pos":"ADJ"} ,
		{"word":"renfrognée","word_nosc":"renfrognee","lemma":"renfrogné","pos":"ADJ"} ,
		{"word":"renfrognés","word_nosc":"renfrognes","lemma":"renfrogné","pos":"ADJ"} ,
		{"word":"reniflant","word_nosc":"reniflant","lemma":"reniflant","pos":"ADJ"} ,
		{"word":"renifleur","word_nosc":"renifleur","lemma":"renifleur","pos":"ADJ"} ,
		{"word":"renifleurs","word_nosc":"renifleurs","lemma":"renifleur","pos":"ADJ"} ,
		{"word":"renifleuse","word_nosc":"renifleuse","lemma":"renifleur","pos":"ADJ"} ,
		{"word":"rennais","word_nosc":"rennais","lemma":"rennais","pos":"ADJ"} ,
		{"word":"rennaises","word_nosc":"rennaises","lemma":"rennais","pos":"ADJ"} ,
		{"word":"renommé","word_nosc":"renomme","lemma":"renommé","pos":"ADJ"} ,
		{"word":"renommée","word_nosc":"renommee","lemma":"renommé","pos":"ADJ"} ,
		{"word":"renommées","word_nosc":"renommees","lemma":"renommé","pos":"ADJ"} ,
		{"word":"renommés","word_nosc":"renommes","lemma":"renommé","pos":"ADJ"} ,
		{"word":"renouvelable","word_nosc":"renouvelable","lemma":"renouvelable","pos":"ADJ"} ,
		{"word":"rentable","word_nosc":"rentable","lemma":"rentable","pos":"ADJ"} ,
		{"word":"rentables","word_nosc":"rentables","lemma":"rentable","pos":"ADJ"} ,
		{"word":"rentrant","word_nosc":"rentrant","lemma":"rentrant","pos":"ADJ"} ,
		{"word":"rentrante","word_nosc":"rentrante","lemma":"rentrant","pos":"ADJ"} ,
		{"word":"rentrantes","word_nosc":"rentrantes","lemma":"rentrant","pos":"ADJ"} ,
		{"word":"rentrants","word_nosc":"rentrants","lemma":"rentrant","pos":"ADJ"} ,
		{"word":"rentré","word_nosc":"rentre","lemma":"rentré","pos":"ADJ"} ,
		{"word":"rentrés","word_nosc":"rentres","lemma":"rentré","pos":"ADJ"} ,
		{"word":"renversant","word_nosc":"renversant","lemma":"renversant","pos":"ADJ"} ,
		{"word":"renversante","word_nosc":"renversante","lemma":"renversant","pos":"ADJ"} ,
		{"word":"renversantes","word_nosc":"renversantes","lemma":"renversant","pos":"ADJ"} ,
		{"word":"renversé","word_nosc":"renverse","lemma":"renversé","pos":"ADJ"} ,
		{"word":"renversée","word_nosc":"renversee","lemma":"renversé","pos":"ADJ"} ,
		{"word":"renversées","word_nosc":"renversees","lemma":"renversé","pos":"ADJ"} ,
		{"word":"renversés","word_nosc":"renverses","lemma":"renversé","pos":"ADJ"} ,
		{"word":"repentant","word_nosc":"repentant","lemma":"repentant","pos":"ADJ"} ,
		{"word":"repentante","word_nosc":"repentante","lemma":"repentant","pos":"ADJ"} ,
		{"word":"repentantes","word_nosc":"repentantes","lemma":"repentant","pos":"ADJ"} ,
		{"word":"repentants","word_nosc":"repentants","lemma":"repentant","pos":"ADJ"} ,
		{"word":"repenti","word_nosc":"repenti","lemma":"repenti","pos":"ADJ"} ,
		{"word":"repentie","word_nosc":"repentie","lemma":"repenti","pos":"ADJ"} ,
		{"word":"repenties","word_nosc":"repenties","lemma":"repenti","pos":"ADJ"} ,
		{"word":"repentis","word_nosc":"repentis","lemma":"repenti","pos":"ADJ"} ,
		{"word":"replet","word_nosc":"replet","lemma":"replet","pos":"ADJ"} ,
		{"word":"replets","word_nosc":"replets","lemma":"replet","pos":"ADJ"} ,
		{"word":"replète","word_nosc":"replete","lemma":"replet","pos":"ADJ"} ,
		{"word":"replètes","word_nosc":"repletes","lemma":"replet","pos":"ADJ"} ,
		{"word":"reporter","word_nosc":"reporter","lemma":"reporter","pos":"ADJ"} ,
		{"word":"reposant","word_nosc":"reposant","lemma":"reposant","pos":"ADJ"} ,
		{"word":"reposante","word_nosc":"reposante","lemma":"reposant","pos":"ADJ"} ,
		{"word":"reposantes","word_nosc":"reposantes","lemma":"reposant","pos":"ADJ"} ,
		{"word":"reposants","word_nosc":"reposants","lemma":"reposant","pos":"ADJ"} ,
		{"word":"reposé","word_nosc":"repose","lemma":"reposé","pos":"ADJ"} ,
		{"word":"reposés","word_nosc":"reposes","lemma":"reposé","pos":"ADJ"} ,
		{"word":"repoussant","word_nosc":"repoussant","lemma":"repoussant","pos":"ADJ"} ,
		{"word":"repoussante","word_nosc":"repoussante","lemma":"repoussant","pos":"ADJ"} ,
		{"word":"repoussantes","word_nosc":"repoussantes","lemma":"repoussant","pos":"ADJ"} ,
		{"word":"repoussants","word_nosc":"repoussants","lemma":"repoussant","pos":"ADJ"} ,
		{"word":"repoussé","word_nosc":"repousse","lemma":"repoussé","pos":"ADJ"} ,
		{"word":"repoussés","word_nosc":"repousses","lemma":"repoussé","pos":"ADJ"} ,
		{"word":"repris","word_nosc":"repris","lemma":"repris","pos":"ADJ"} ,
		{"word":"reprise","word_nosc":"reprise","lemma":"repris","pos":"ADJ"} ,
		{"word":"reprises","word_nosc":"reprises","lemma":"repris","pos":"ADJ"} ,
		{"word":"reproducteur","word_nosc":"reproducteur","lemma":"reproducteur","pos":"ADJ"} ,
		{"word":"reproducteurs","word_nosc":"reproducteurs","lemma":"reproducteur","pos":"ADJ"} ,
		{"word":"reproductible","word_nosc":"reproductible","lemma":"reproductible","pos":"ADJ"} ,
		{"word":"reproductif","word_nosc":"reproductif","lemma":"reproductif","pos":"ADJ"} ,
		{"word":"reproductifs","word_nosc":"reproductifs","lemma":"reproductif","pos":"ADJ"} ,
		{"word":"reproductive","word_nosc":"reproductive","lemma":"reproductif","pos":"ADJ"} ,
		{"word":"reproductrice","word_nosc":"reproductrice","lemma":"reproducteur","pos":"ADJ"} ,
		{"word":"reproductrices","word_nosc":"reproductrices","lemma":"reproducteur","pos":"ADJ"} ,
		{"word":"représentatif","word_nosc":"representatif","lemma":"représentatif","pos":"ADJ"} ,
		{"word":"représentatifs","word_nosc":"representatifs","lemma":"représentatif","pos":"ADJ"} ,
		{"word":"représentative","word_nosc":"representative","lemma":"représentatif","pos":"ADJ"} ,
		{"word":"représentatives","word_nosc":"representatives","lemma":"représentatif","pos":"ADJ"} ,
		{"word":"représenté","word_nosc":"represente","lemma":"représenté","pos":"ADJ"} ,
		{"word":"représentée","word_nosc":"representee","lemma":"représenté","pos":"ADJ"} ,
		{"word":"représentées","word_nosc":"representees","lemma":"représenté","pos":"ADJ"} ,
		{"word":"représentés","word_nosc":"representes","lemma":"représenté","pos":"ADJ"} ,
		{"word":"reptilien","word_nosc":"reptilien","lemma":"reptilien","pos":"ADJ"} ,
		{"word":"reptilienne","word_nosc":"reptilienne","lemma":"reptilien","pos":"ADJ"} ,
		{"word":"reptiliennes","word_nosc":"reptiliennes","lemma":"reptilien","pos":"ADJ"} ,
		{"word":"reptiliens","word_nosc":"reptiliens","lemma":"reptilien","pos":"ADJ"} ,
		{"word":"repu","word_nosc":"repu","lemma":"repu","pos":"ADJ"} ,
		{"word":"repue","word_nosc":"repue","lemma":"repu","pos":"ADJ"} ,
		{"word":"repues","word_nosc":"repues","lemma":"repu","pos":"ADJ"} ,
		{"word":"repus","word_nosc":"repus","lemma":"repu","pos":"ADJ"} ,
		{"word":"repérable","word_nosc":"reperable","lemma":"repérable","pos":"ADJ"} ,
		{"word":"repérables","word_nosc":"reperables","lemma":"repérable","pos":"ADJ"} ,
		{"word":"requis","word_nosc":"requis","lemma":"requis","pos":"ADJ"} ,
		{"word":"requise","word_nosc":"requise","lemma":"requis","pos":"ADJ"} ,
		{"word":"requises","word_nosc":"requises","lemma":"requis","pos":"ADJ"} ,
		{"word":"rescapé","word_nosc":"rescape","lemma":"rescapé","pos":"ADJ"} ,
		{"word":"rescapée","word_nosc":"rescapee","lemma":"rescapé","pos":"ADJ"} ,
		{"word":"rescapées","word_nosc":"rescapees","lemma":"rescapé","pos":"ADJ"} ,
		{"word":"rescapés","word_nosc":"rescapes","lemma":"rescapé","pos":"ADJ"} ,
		{"word":"respectable","word_nosc":"respectable","lemma":"respectable","pos":"ADJ"} ,
		{"word":"respectables","word_nosc":"respectables","lemma":"respectable","pos":"ADJ"} ,
		{"word":"respectif","word_nosc":"respectif","lemma":"respectif","pos":"ADJ"} ,
		{"word":"respectifs","word_nosc":"respectifs","lemma":"respectif","pos":"ADJ"} ,
		{"word":"respective","word_nosc":"respective","lemma":"respectif","pos":"ADJ"} ,
		{"word":"respectives","word_nosc":"respectives","lemma":"respectif","pos":"ADJ"} ,
		{"word":"respectueuse","word_nosc":"respectueuse","lemma":"respectueux","pos":"ADJ"} ,
		{"word":"respectueuses","word_nosc":"respectueuses","lemma":"respectueux","pos":"ADJ"} ,
		{"word":"respectueux","word_nosc":"respectueux","lemma":"respectueux","pos":"ADJ"} ,
		{"word":"respecté","word_nosc":"respecte","lemma":"respecté","pos":"ADJ"} ,
		{"word":"respectée","word_nosc":"respectee","lemma":"respecté","pos":"ADJ"} ,
		{"word":"respectées","word_nosc":"respectees","lemma":"respecté","pos":"ADJ"} ,
		{"word":"respectés","word_nosc":"respectes","lemma":"respecté","pos":"ADJ"} ,
		{"word":"respirable","word_nosc":"respirable","lemma":"respirable","pos":"ADJ"} ,
		{"word":"respirables","word_nosc":"respirables","lemma":"respirable","pos":"ADJ"} ,
		{"word":"respiratoire","word_nosc":"respiratoire","lemma":"respiratoire","pos":"ADJ"} ,
		{"word":"respiratoires","word_nosc":"respiratoires","lemma":"respiratoire","pos":"ADJ"} ,
		{"word":"resplendissant","word_nosc":"resplendissant","lemma":"resplendissant","pos":"ADJ"} ,
		{"word":"resplendissante","word_nosc":"resplendissante","lemma":"resplendissant","pos":"ADJ"} ,
		{"word":"resplendissantes","word_nosc":"resplendissantes","lemma":"resplendissant","pos":"ADJ"} ,
		{"word":"resplendissants","word_nosc":"resplendissants","lemma":"resplendissant","pos":"ADJ"} ,
		{"word":"responsable","word_nosc":"responsable","lemma":"responsable","pos":"ADJ"} ,
		{"word":"responsables","word_nosc":"responsables","lemma":"responsable","pos":"ADJ"} ,
		{"word":"resquilleur","word_nosc":"resquilleur","lemma":"resquilleur","pos":"ADJ"} ,
		{"word":"ressemblant","word_nosc":"ressemblant","lemma":"ressemblant","pos":"ADJ"} ,
		{"word":"ressemblante","word_nosc":"ressemblante","lemma":"ressemblant","pos":"ADJ"} ,
		{"word":"ressemblantes","word_nosc":"ressemblantes","lemma":"ressemblant","pos":"ADJ"} ,
		{"word":"ressemblants","word_nosc":"ressemblants","lemma":"ressemblant","pos":"ADJ"} ,
		{"word":"ressenti","word_nosc":"ressenti","lemma":"ressenti","pos":"ADJ"} ,
		{"word":"ressentie","word_nosc":"ressentie","lemma":"ressenti","pos":"ADJ"} ,
		{"word":"ressenties","word_nosc":"ressenties","lemma":"ressenti","pos":"ADJ"} ,
		{"word":"ressentis","word_nosc":"ressentis","lemma":"ressenti","pos":"ADJ"} ,
		{"word":"resserré","word_nosc":"resserre","lemma":"resserré","pos":"ADJ"} ,
		{"word":"resserrée","word_nosc":"resserree","lemma":"resserré","pos":"ADJ"} ,
		{"word":"resserrées","word_nosc":"resserrees","lemma":"resserré","pos":"ADJ"} ,
		{"word":"resserrés","word_nosc":"resserres","lemma":"resserré","pos":"ADJ"} ,
		{"word":"restant","word_nosc":"restant","lemma":"restant","pos":"ADJ"} ,
		{"word":"restante","word_nosc":"restante","lemma":"restant","pos":"ADJ"} ,
		{"word":"restantes","word_nosc":"restantes","lemma":"restant","pos":"ADJ"} ,
		{"word":"restants","word_nosc":"restants","lemma":"restant","pos":"ADJ"} ,
		{"word":"restreint","word_nosc":"restreint","lemma":"restreint","pos":"ADJ"} ,
		{"word":"restreinte","word_nosc":"restreinte","lemma":"restreint","pos":"ADJ"} ,
		{"word":"restreintes","word_nosc":"restreintes","lemma":"restreint","pos":"ADJ"} ,
		{"word":"restreints","word_nosc":"restreints","lemma":"restreint","pos":"ADJ"} ,
		{"word":"restrictif","word_nosc":"restrictif","lemma":"restrictif","pos":"ADJ"} ,
		{"word":"restrictive","word_nosc":"restrictive","lemma":"restrictif","pos":"ADJ"} ,
		{"word":"restrictives","word_nosc":"restrictives","lemma":"restrictif","pos":"ADJ"} ,
		{"word":"retardataire","word_nosc":"retardataire","lemma":"retardataire","pos":"ADJ"} ,
		{"word":"retardataires","word_nosc":"retardataires","lemma":"retardataire","pos":"ADJ"} ,
		{"word":"retardé","word_nosc":"retarde","lemma":"retardé","pos":"ADJ"} ,
		{"word":"retardée","word_nosc":"retardee","lemma":"retardé","pos":"ADJ"} ,
		{"word":"retardées","word_nosc":"retardees","lemma":"retardé","pos":"ADJ"} ,
		{"word":"retardés","word_nosc":"retardes","lemma":"retardé","pos":"ADJ"} ,
		{"word":"retentissant","word_nosc":"retentissant","lemma":"retentissant","pos":"ADJ"} ,
		{"word":"retentissante","word_nosc":"retentissante","lemma":"retentissant","pos":"ADJ"} ,
		{"word":"retentissantes","word_nosc":"retentissantes","lemma":"retentissant","pos":"ADJ"} ,
		{"word":"retentissants","word_nosc":"retentissants","lemma":"retentissant","pos":"ADJ"} ,
		{"word":"retenu","word_nosc":"retenu","lemma":"retenu","pos":"ADJ"} ,
		{"word":"retenue","word_nosc":"retenue","lemma":"retenu","pos":"ADJ"} ,
		{"word":"retenues","word_nosc":"retenues","lemma":"retenu","pos":"ADJ"} ,
		{"word":"retenus","word_nosc":"retenus","lemma":"retenu","pos":"ADJ"} ,
		{"word":"retirable","word_nosc":"retirable","lemma":"retirable","pos":"ADJ"} ,
		{"word":"retiré","word_nosc":"retire","lemma":"retiré","pos":"ADJ"} ,
		{"word":"retirée","word_nosc":"retiree","lemma":"retiré","pos":"ADJ"} ,
		{"word":"retirées","word_nosc":"retirees","lemma":"retiré","pos":"ADJ"} ,
		{"word":"retirés","word_nosc":"retires","lemma":"retiré","pos":"ADJ"} ,
		{"word":"retombant","word_nosc":"retombant","lemma":"retombant","pos":"ADJ"} ,
		{"word":"retombante","word_nosc":"retombante","lemma":"retombant","pos":"ADJ"} ,
		{"word":"retombantes","word_nosc":"retombantes","lemma":"retombant","pos":"ADJ"} ,
		{"word":"retombée","word_nosc":"retombee","lemma":"retombée","pos":"ADJ"} ,
		{"word":"retombées","word_nosc":"retombees","lemma":"retombée","pos":"ADJ"} ,
		{"word":"retors","word_nosc":"retors","lemma":"retors","pos":"ADJ"} ,
		{"word":"retorse","word_nosc":"retorse","lemma":"retors","pos":"ADJ"} ,
		{"word":"retorses","word_nosc":"retorses","lemma":"retors","pos":"ADJ"} ,
		{"word":"retraité","word_nosc":"retraite","lemma":"retraité","pos":"ADJ"} ,
		{"word":"retraitée","word_nosc":"retraitee","lemma":"retraité","pos":"ADJ"} ,
		{"word":"retraitées","word_nosc":"retraitees","lemma":"retraité","pos":"ADJ"} ,
		{"word":"retraités","word_nosc":"retraites","lemma":"retraité","pos":"ADJ"} ,
		{"word":"retroussé","word_nosc":"retrousse","lemma":"retroussé","pos":"ADJ"} ,
		{"word":"retroussée","word_nosc":"retroussee","lemma":"retroussé","pos":"ADJ"} ,
		{"word":"retroussées","word_nosc":"retroussees","lemma":"retroussé","pos":"ADJ"} ,
		{"word":"retroussés","word_nosc":"retrousses","lemma":"retroussé","pos":"ADJ"} ,
		{"word":"revanchard","word_nosc":"revanchard","lemma":"revanchard","pos":"ADJ"} ,
		{"word":"revancharde","word_nosc":"revancharde","lemma":"revanchard","pos":"ADJ"} ,
		{"word":"revanchardes","word_nosc":"revanchardes","lemma":"revanchard","pos":"ADJ"} ,
		{"word":"revenant","word_nosc":"revenant","lemma":"revenant","pos":"ADJ"} ,
		{"word":"revenante","word_nosc":"revenante","lemma":"revenant","pos":"ADJ"} ,
		{"word":"revenants","word_nosc":"revenants","lemma":"revenant","pos":"ADJ"} ,
		{"word":"revendable","word_nosc":"revendable","lemma":"revendable","pos":"ADJ"} ,
		{"word":"revendicateur","word_nosc":"revendicateur","lemma":"revendicateur","pos":"ADJ"} ,
		{"word":"revendicatif","word_nosc":"revendicatif","lemma":"revendicatif","pos":"ADJ"} ,
		{"word":"revendicatifs","word_nosc":"revendicatifs","lemma":"revendicatif","pos":"ADJ"} ,
		{"word":"revendicative","word_nosc":"revendicative","lemma":"revendicatif","pos":"ADJ"} ,
		{"word":"revendicatives","word_nosc":"revendicatives","lemma":"revendicatif","pos":"ADJ"} ,
		{"word":"revendicatrice","word_nosc":"revendicatrice","lemma":"revendicateur","pos":"ADJ"} ,
		{"word":"revendicatrices","word_nosc":"revendicatrices","lemma":"revendicateur","pos":"ADJ"} ,
		{"word":"revenu","word_nosc":"revenu","lemma":"revenu","pos":"ADJ"} ,
		{"word":"revenue","word_nosc":"revenue","lemma":"revenu","pos":"ADJ"} ,
		{"word":"revenues","word_nosc":"revenues","lemma":"revenu","pos":"ADJ"} ,
		{"word":"revenus","word_nosc":"revenus","lemma":"revenu","pos":"ADJ"} ,
		{"word":"revigorant","word_nosc":"revigorant","lemma":"revigorant","pos":"ADJ"} ,
		{"word":"revigorante","word_nosc":"revigorante","lemma":"revigorant","pos":"ADJ"} ,
		{"word":"revigorantes","word_nosc":"revigorantes","lemma":"revigorant","pos":"ADJ"} ,
		{"word":"revitalisant","word_nosc":"revitalisant","lemma":"revitalisant","pos":"ADJ"} ,
		{"word":"revivifié","word_nosc":"revivifie","lemma":"revivifié","pos":"ADJ"} ,
		{"word":"revu","word_nosc":"revu","lemma":"revu","pos":"ADJ"} ,
		{"word":"revue","word_nosc":"revue","lemma":"revu","pos":"ADJ"} ,
		{"word":"revues","word_nosc":"revues","lemma":"revu","pos":"ADJ"} ,
		{"word":"revus","word_nosc":"revus","lemma":"revu","pos":"ADJ"} ,
		{"word":"revécues","word_nosc":"revecues","lemma":"revécu","pos":"ADJ"} ,
		{"word":"revérifié","word_nosc":"reverifie","lemma":"revérifié","pos":"ADJ"} ,
		{"word":"revêche","word_nosc":"reveche","lemma":"revêche","pos":"ADJ"} ,
		{"word":"revêches","word_nosc":"reveches","lemma":"revêche","pos":"ADJ"} ,
		{"word":"revêtu","word_nosc":"revetu","lemma":"revêtu","pos":"ADJ"} ,
		{"word":"revêtue","word_nosc":"revetue","lemma":"revêtu","pos":"ADJ"} ,
		{"word":"revêtues","word_nosc":"revetues","lemma":"revêtu","pos":"ADJ"} ,
		{"word":"revêtus","word_nosc":"revetus","lemma":"revêtu","pos":"ADJ"} ,
		{"word":"rexiste","word_nosc":"rexiste","lemma":"rexiste","pos":"ADJ"} ,
		{"word":"reçu","word_nosc":"recu","lemma":"reçu","pos":"ADJ"} ,
		{"word":"reçue","word_nosc":"recue","lemma":"reçu","pos":"ADJ"} ,
		{"word":"reçues","word_nosc":"recues","lemma":"reçu","pos":"ADJ"} ,
		{"word":"reçus","word_nosc":"recus","lemma":"reçu","pos":"ADJ"} ,
		{"word":"rhodanien","word_nosc":"rhodanien","lemma":"rhodanien","pos":"ADJ"} ,
		{"word":"rhomboïdal","word_nosc":"rhomboidal","lemma":"rhomboïdal","pos":"ADJ"} ,
		{"word":"rhumatisant","word_nosc":"rhumatisant","lemma":"rhumatisant","pos":"ADJ"} ,
		{"word":"rhumatisante","word_nosc":"rhumatisante","lemma":"rhumatisant","pos":"ADJ"} ,
		{"word":"rhumatisants","word_nosc":"rhumatisants","lemma":"rhumatisant","pos":"ADJ"} ,
		{"word":"rhumatismal","word_nosc":"rhumatismal","lemma":"rhumatismal","pos":"ADJ"} ,
		{"word":"rhumatismale","word_nosc":"rhumatismale","lemma":"rhumatismal","pos":"ADJ"} ,
		{"word":"rhumatismales","word_nosc":"rhumatismales","lemma":"rhumatismal","pos":"ADJ"} ,
		{"word":"rhumatoïde","word_nosc":"rhumatoide","lemma":"rhumatoïde","pos":"ADJ"} ,
		{"word":"rhénan","word_nosc":"rhenan","lemma":"rhénan","pos":"ADJ"} ,
		{"word":"rhénane","word_nosc":"rhenane","lemma":"rhénan","pos":"ADJ"} ,
		{"word":"rhénans","word_nosc":"rhenans","lemma":"rhénan","pos":"ADJ"} ,
		{"word":"rhétoricien","word_nosc":"rhetoricien","lemma":"rhétoricien","pos":"ADJ"} ,
		{"word":"rhétorique","word_nosc":"rhetorique","lemma":"rhétorique","pos":"ADJ"} ,
		{"word":"rhétoriques","word_nosc":"rhetoriques","lemma":"rhétorique","pos":"ADJ"} ,
		{"word":"ri","word_nosc":"ri","lemma":"ri","pos":"ADJ"} ,
		{"word":"riant","word_nosc":"riant","lemma":"riant","pos":"ADJ"} ,
		{"word":"riante","word_nosc":"riante","lemma":"riant","pos":"ADJ"} ,
		{"word":"riantes","word_nosc":"riantes","lemma":"riant","pos":"ADJ"} ,
		{"word":"riants","word_nosc":"riants","lemma":"riant","pos":"ADJ"} ,
		{"word":"ribaude","word_nosc":"ribaude","lemma":"ribaud","pos":"ADJ"} ,
		{"word":"ribaudes","word_nosc":"ribaudes","lemma":"ribaud","pos":"ADJ"} ,
		{"word":"riboulants","word_nosc":"riboulants","lemma":"riboulant","pos":"ADJ"} ,
		{"word":"ricain","word_nosc":"ricain","lemma":"ricain","pos":"ADJ"} ,
		{"word":"ricaine","word_nosc":"ricaine","lemma":"ricain","pos":"ADJ"} ,
		{"word":"ricaines","word_nosc":"ricaines","lemma":"ricain","pos":"ADJ"} ,
		{"word":"ricains","word_nosc":"ricains","lemma":"ricain","pos":"ADJ"} ,
		{"word":"ricanant","word_nosc":"ricanant","lemma":"ricanant","pos":"ADJ"} ,
		{"word":"ricanante","word_nosc":"ricanante","lemma":"ricanant","pos":"ADJ"} ,
		{"word":"ricanantes","word_nosc":"ricanantes","lemma":"ricanant","pos":"ADJ"} ,
		{"word":"ricanants","word_nosc":"ricanants","lemma":"ricanant","pos":"ADJ"} ,
		{"word":"ricaneur","word_nosc":"ricaneur","lemma":"ricaneur","pos":"ADJ"} ,
		{"word":"ricaneurs","word_nosc":"ricaneurs","lemma":"ricaneur","pos":"ADJ"} ,
		{"word":"ricaneuse","word_nosc":"ricaneuse","lemma":"ricaneur","pos":"ADJ"} ,
		{"word":"richard","word_nosc":"richard","lemma":"richard","pos":"ADJ"} ,
		{"word":"richards","word_nosc":"richards","lemma":"richard","pos":"ADJ"} ,
		{"word":"riche","word_nosc":"riche","lemma":"riche","pos":"ADJ"} ,
		{"word":"riches","word_nosc":"riches","lemma":"riche","pos":"ADJ"} ,
		{"word":"richissime","word_nosc":"richissime","lemma":"richissime","pos":"ADJ"} ,
		{"word":"richissimes","word_nosc":"richissimes","lemma":"richissime","pos":"ADJ"} ,
		{"word":"ridicule","word_nosc":"ridicule","lemma":"ridicule","pos":"ADJ"} ,
		{"word":"ridicules","word_nosc":"ridicules","lemma":"ridicule","pos":"ADJ"} ,
		{"word":"ridé","word_nosc":"ride","lemma":"ridé","pos":"ADJ"} ,
		{"word":"ridée","word_nosc":"ridee","lemma":"ridé","pos":"ADJ"} ,
		{"word":"ridées","word_nosc":"ridees","lemma":"ridé","pos":"ADJ"} ,
		{"word":"ridés","word_nosc":"rides","lemma":"ridé","pos":"ADJ"} ,
		{"word":"rieur","word_nosc":"rieur","lemma":"rieur","pos":"ADJ"} ,
		{"word":"rieurs","word_nosc":"rieurs","lemma":"rieur","pos":"ADJ"} ,
		{"word":"rieuse","word_nosc":"rieuse","lemma":"rieux","pos":"ADJ"} ,
		{"word":"rieuses","word_nosc":"rieuses","lemma":"rieux","pos":"ADJ"} ,
		{"word":"rigide","word_nosc":"rigide","lemma":"rigide","pos":"ADJ"} ,
		{"word":"rigides","word_nosc":"rigides","lemma":"rigide","pos":"ADJ"} ,
		{"word":"rigolard","word_nosc":"rigolard","lemma":"rigolard","pos":"ADJ"} ,
		{"word":"rigolarde","word_nosc":"rigolarde","lemma":"rigolard","pos":"ADJ"} ,
		{"word":"rigolardes","word_nosc":"rigolardes","lemma":"rigolard","pos":"ADJ"} ,
		{"word":"rigolards","word_nosc":"rigolards","lemma":"rigolard","pos":"ADJ"} ,
		{"word":"rigoleur","word_nosc":"rigoleur","lemma":"rigoleur","pos":"ADJ"} ,
		{"word":"rigolo","word_nosc":"rigolo","lemma":"rigolo","pos":"ADJ"} ,
		{"word":"rigolos","word_nosc":"rigolos","lemma":"rigolo","pos":"ADJ"} ,
		{"word":"rigolote","word_nosc":"rigolote","lemma":"rigolo","pos":"ADJ"} ,
		{"word":"rigolotes","word_nosc":"rigolotes","lemma":"rigolo","pos":"ADJ"} ,
		{"word":"rigoriste","word_nosc":"rigoriste","lemma":"rigoriste","pos":"ADJ"} ,
		{"word":"rigoureuse","word_nosc":"rigoureuse","lemma":"rigoureux","pos":"ADJ"} ,
		{"word":"rigoureuses","word_nosc":"rigoureuses","lemma":"rigoureux","pos":"ADJ"} ,
		{"word":"rigoureux","word_nosc":"rigoureux","lemma":"rigoureux","pos":"ADJ"} ,
		{"word":"rikiki","word_nosc":"rikiki","lemma":"rikiki","pos":"ADJ"} ,
		{"word":"rimante","word_nosc":"rimante","lemma":"rimant","pos":"ADJ"} ,
		{"word":"rimbaldien","word_nosc":"rimbaldien","lemma":"rimbaldien","pos":"ADJ"} ,
		{"word":"ringard","word_nosc":"ringard","lemma":"ringard","pos":"ADJ"} ,
		{"word":"ringarde","word_nosc":"ringarde","lemma":"ringard","pos":"ADJ"} ,
		{"word":"ringardes","word_nosc":"ringardes","lemma":"ringard","pos":"ADJ"} ,
		{"word":"ringards","word_nosc":"ringards","lemma":"ringard","pos":"ADJ"} ,
		{"word":"ripou","word_nosc":"ripou","lemma":"ripou","pos":"ADJ"} ,
		{"word":"ripuaire","word_nosc":"ripuaire","lemma":"ripuaire","pos":"ADJ"} ,
		{"word":"riquiqui","word_nosc":"riquiqui","lemma":"riquiqui","pos":"ADJ"} ,
		{"word":"risible","word_nosc":"risible","lemma":"risible","pos":"ADJ"} ,
		{"word":"risibles","word_nosc":"risibles","lemma":"risible","pos":"ADJ"} ,
		{"word":"risque-tout","word_nosc":"risque-tout","lemma":"risque-tout","pos":"ADJ"} ,
		{"word":"risqué","word_nosc":"risque","lemma":"risqué","pos":"ADJ"} ,
		{"word":"risquée","word_nosc":"risquee","lemma":"risqué","pos":"ADJ"} ,
		{"word":"risquées","word_nosc":"risquees","lemma":"risqué","pos":"ADJ"} ,
		{"word":"risqués","word_nosc":"risques","lemma":"risqué","pos":"ADJ"} ,
		{"word":"rissolée","word_nosc":"rissolee","lemma":"rissolé","pos":"ADJ"} ,
		{"word":"rissolées","word_nosc":"rissolees","lemma":"rissolé","pos":"ADJ"} ,
		{"word":"rissolés","word_nosc":"rissoles","lemma":"rissolé","pos":"ADJ"} ,
		{"word":"ritualiste","word_nosc":"ritualiste","lemma":"ritualiste","pos":"ADJ"} ,
		{"word":"ritualistes","word_nosc":"ritualistes","lemma":"ritualiste","pos":"ADJ"} ,
		{"word":"rituel","word_nosc":"rituel","lemma":"rituel","pos":"ADJ"} ,
		{"word":"rituelle","word_nosc":"rituelle","lemma":"rituel","pos":"ADJ"} ,
		{"word":"rituelles","word_nosc":"rituelles","lemma":"rituel","pos":"ADJ"} ,
		{"word":"rituels","word_nosc":"rituels","lemma":"rituel","pos":"ADJ"} ,
		{"word":"rival","word_nosc":"rival","lemma":"rival","pos":"ADJ"} ,
		{"word":"rivale","word_nosc":"rivale","lemma":"rival","pos":"ADJ"} ,
		{"word":"rivales","word_nosc":"rivales","lemma":"rival","pos":"ADJ"} ,
		{"word":"rivaux","word_nosc":"rivaux","lemma":"rival","pos":"ADJ"} ,
		{"word":"riveraines","word_nosc":"riveraines","lemma":"riverain","pos":"ADJ"} ,
		{"word":"riverains","word_nosc":"riverains","lemma":"riverain","pos":"ADJ"} ,
		{"word":"roboratif","word_nosc":"roboratif","lemma":"roboratif","pos":"ADJ"} ,
		{"word":"roborative","word_nosc":"roborative","lemma":"roboratif","pos":"ADJ"} ,
		{"word":"roboratives","word_nosc":"roboratives","lemma":"roboratif","pos":"ADJ"} ,
		{"word":"robuste","word_nosc":"robuste","lemma":"robuste","pos":"ADJ"} ,
		{"word":"robustes","word_nosc":"robustes","lemma":"robuste","pos":"ADJ"} ,
		{"word":"rocailleuse","word_nosc":"rocailleuse","lemma":"rocailleux","pos":"ADJ"} ,
		{"word":"rocailleuses","word_nosc":"rocailleuses","lemma":"rocailleux","pos":"ADJ"} ,
		{"word":"rocailleux","word_nosc":"rocailleux","lemma":"rocailleux","pos":"ADJ"} ,
		{"word":"rocambolesque","word_nosc":"rocambolesque","lemma":"rocambolesque","pos":"ADJ"} ,
		{"word":"rocambolesques","word_nosc":"rocambolesques","lemma":"rocambolesque","pos":"ADJ"} ,
		{"word":"rocheuse","word_nosc":"rocheuse","lemma":"rocheux","pos":"ADJ"} ,
		{"word":"rocheuses","word_nosc":"rocheuses","lemma":"rocheux","pos":"ADJ"} ,
		{"word":"rocheux","word_nosc":"rocheux","lemma":"rocheux","pos":"ADJ"} ,
		{"word":"rococo","word_nosc":"rococo","lemma":"rococo","pos":"ADJ"} ,
		{"word":"rogatoire","word_nosc":"rogatoire","lemma":"rogatoire","pos":"ADJ"} ,
		{"word":"rogatoires","word_nosc":"rogatoires","lemma":"rogatoire","pos":"ADJ"} ,
		{"word":"rogneux","word_nosc":"rogneux","lemma":"rogneux","pos":"ADJ"} ,
		{"word":"rogue","word_nosc":"rogue","lemma":"rogue","pos":"ADJ"} ,
		{"word":"rogues","word_nosc":"rogues","lemma":"rogue","pos":"ADJ"} ,
		{"word":"roide","word_nosc":"roide","lemma":"roide","pos":"ADJ"} ,
		{"word":"roides","word_nosc":"roides","lemma":"roide","pos":"ADJ"} ,
		{"word":"romain","word_nosc":"romain","lemma":"romain","pos":"ADJ"} ,
		{"word":"romaine","word_nosc":"romaine","lemma":"romain","pos":"ADJ"} ,
		{"word":"romaines","word_nosc":"romaines","lemma":"romain","pos":"ADJ"} ,
		{"word":"romains","word_nosc":"romains","lemma":"romain","pos":"ADJ"} ,
		{"word":"roman","word_nosc":"roman","lemma":"roman","pos":"ADJ"} ,
		{"word":"romancé","word_nosc":"romance","lemma":"romancé","pos":"ADJ"} ,
		{"word":"romancée","word_nosc":"romancee","lemma":"romancé","pos":"ADJ"} ,
		{"word":"romancées","word_nosc":"romancees","lemma":"romancé","pos":"ADJ"} ,
		{"word":"romande","word_nosc":"romande","lemma":"romand","pos":"ADJ"} ,
		{"word":"romane","word_nosc":"romane","lemma":"roman","pos":"ADJ"} ,
		{"word":"romanes","word_nosc":"romanes","lemma":"roman","pos":"ADJ"} ,
		{"word":"romanesque","word_nosc":"romanesque","lemma":"romanesque","pos":"ADJ"} ,
		{"word":"romanesques","word_nosc":"romanesques","lemma":"romanesque","pos":"ADJ"} ,
		{"word":"romans","word_nosc":"romans","lemma":"roman","pos":"ADJ"} ,
		{"word":"romantique","word_nosc":"romantique","lemma":"romantique","pos":"ADJ"} ,
		{"word":"romantiques","word_nosc":"romantiques","lemma":"romantique","pos":"ADJ"} ,
		{"word":"rompu","word_nosc":"rompu","lemma":"rompu","pos":"ADJ"} ,
		{"word":"rompue","word_nosc":"rompue","lemma":"rompu","pos":"ADJ"} ,
		{"word":"rompues","word_nosc":"rompues","lemma":"rompu","pos":"ADJ"} ,
		{"word":"rompus","word_nosc":"rompus","lemma":"rompu","pos":"ADJ"} ,
		{"word":"roms","word_nosc":"roms","lemma":"rom","pos":"ADJ"} ,
		{"word":"ronceux","word_nosc":"ronceux","lemma":"ronceux","pos":"ADJ"} ,
		{"word":"ronchon","word_nosc":"ronchon","lemma":"ronchon","pos":"ADJ"} ,
		{"word":"ronchonne","word_nosc":"ronchonne","lemma":"ronchon","pos":"ADJ"} ,
		{"word":"ronchonneuse","word_nosc":"ronchonneuse","lemma":"ronchonneur","pos":"ADJ"} ,
		{"word":"rond","word_nosc":"rond","lemma":"rond","pos":"ADJ"} ,
		{"word":"ronde","word_nosc":"ronde","lemma":"rond","pos":"ADJ"} ,
		{"word":"rondelet","word_nosc":"rondelet","lemma":"rondelet","pos":"ADJ"} ,
		{"word":"rondelette","word_nosc":"rondelette","lemma":"rondelet","pos":"ADJ"} ,
		{"word":"rondelettes","word_nosc":"rondelettes","lemma":"rondelet","pos":"ADJ"} ,
		{"word":"rondes","word_nosc":"rondes","lemma":"rond","pos":"ADJ"} ,
		{"word":"rondouillard","word_nosc":"rondouillard","lemma":"rondouillard","pos":"ADJ"} ,
		{"word":"rondouillarde","word_nosc":"rondouillarde","lemma":"rondouillard","pos":"ADJ"} ,
		{"word":"rondouillards","word_nosc":"rondouillards","lemma":"rondouillard","pos":"ADJ"} ,
		{"word":"ronds","word_nosc":"ronds","lemma":"rond","pos":"ADJ"} ,
		{"word":"ronflant","word_nosc":"ronflant","lemma":"ronflant","pos":"ADJ"} ,
		{"word":"ronflante","word_nosc":"ronflante","lemma":"ronflant","pos":"ADJ"} ,
		{"word":"ronflantes","word_nosc":"ronflantes","lemma":"ronflant","pos":"ADJ"} ,
		{"word":"ronflants","word_nosc":"ronflants","lemma":"ronflant","pos":"ADJ"} ,
		{"word":"rongeant","word_nosc":"rongeant","lemma":"rongeant","pos":"ADJ"} ,
		{"word":"rongeante","word_nosc":"rongeante","lemma":"rongeant","pos":"ADJ"} ,
		{"word":"rongeantes","word_nosc":"rongeantes","lemma":"rongeant","pos":"ADJ"} ,
		{"word":"rongeur","word_nosc":"rongeur","lemma":"rongeur","pos":"ADJ"} ,
		{"word":"rongeurs","word_nosc":"rongeurs","lemma":"rongeur","pos":"ADJ"} ,
		{"word":"rongeuse","word_nosc":"rongeuse","lemma":"rongeur","pos":"ADJ"} ,
		{"word":"rongeuses","word_nosc":"rongeuses","lemma":"rongeur","pos":"ADJ"} ,
		{"word":"rongicide","word_nosc":"rongicide","lemma":"rongicide","pos":"ADJ"} ,
		{"word":"ronronnant","word_nosc":"ronronnant","lemma":"ronronnant","pos":"ADJ"} ,
		{"word":"ronronnante","word_nosc":"ronronnante","lemma":"ronronnant","pos":"ADJ"} ,
		{"word":"ronronnantes","word_nosc":"ronronnantes","lemma":"ronronnant","pos":"ADJ"} ,
		{"word":"ronronnants","word_nosc":"ronronnants","lemma":"ronronnant","pos":"ADJ"} ,
		{"word":"rosat","word_nosc":"rosat","lemma":"rosat","pos":"ADJ"} ,
		{"word":"rose","word_nosc":"rose","lemma":"rose","pos":"ADJ"} ,
		{"word":"rose-thé","word_nosc":"rose-the","lemma":"rose-thé","pos":"ADJ"} ,
		{"word":"roses","word_nosc":"roses","lemma":"rose","pos":"ADJ"} ,
		{"word":"rosicrucien","word_nosc":"rosicrucien","lemma":"rosicrucien","pos":"ADJ"} ,
		{"word":"rosicrucienne","word_nosc":"rosicrucienne","lemma":"rosicrucienne","pos":"ADJ"} ,
		{"word":"rosicruciens","word_nosc":"rosicruciens","lemma":"rosicrucien","pos":"ADJ"} ,
		{"word":"rosse","word_nosc":"rosse","lemma":"rosse","pos":"ADJ"} ,
		{"word":"rosses","word_nosc":"rosses","lemma":"rosse","pos":"ADJ"} ,
		{"word":"rosâtre","word_nosc":"rosatre","lemma":"rosâtre","pos":"ADJ"} ,
		{"word":"rosâtres","word_nosc":"rosatres","lemma":"rosâtre","pos":"ADJ"} ,
		{"word":"rosé","word_nosc":"rose","lemma":"rosé","pos":"ADJ"} ,
		{"word":"rosés","word_nosc":"roses","lemma":"rosé","pos":"ADJ"} ,
		{"word":"rotariens","word_nosc":"rotariens","lemma":"rotarien","pos":"ADJ"} ,
		{"word":"rotateur","word_nosc":"rotateur","lemma":"rotateur","pos":"ADJ"} ,
		{"word":"rotatif","word_nosc":"rotatif","lemma":"rotatif","pos":"ADJ"} ,
		{"word":"rotatifs","word_nosc":"rotatifs","lemma":"rotatif","pos":"ADJ"} ,
		{"word":"rotationnel","word_nosc":"rotationnel","lemma":"rotationnel","pos":"ADJ"} ,
		{"word":"rotative","word_nosc":"rotative","lemma":"rotatif","pos":"ADJ"} ,
		{"word":"rotatives","word_nosc":"rotatives","lemma":"rotatif","pos":"ADJ"} ,
		{"word":"rotatoire","word_nosc":"rotatoire","lemma":"rotatoire","pos":"ADJ"} ,
		{"word":"rotulien","word_nosc":"rotulien","lemma":"rotulien","pos":"ADJ"} ,
		{"word":"roturier","word_nosc":"roturier","lemma":"roturier","pos":"ADJ"} ,
		{"word":"roturière","word_nosc":"roturiere","lemma":"roturier","pos":"ADJ"} ,
		{"word":"rouan","word_nosc":"rouan","lemma":"rouan","pos":"ADJ"} ,
		{"word":"roublard","word_nosc":"roublard","lemma":"roublard","pos":"ADJ"} ,
		{"word":"roublarde","word_nosc":"roublarde","lemma":"roublard","pos":"ADJ"} ,
		{"word":"roublardes","word_nosc":"roublardes","lemma":"roublard","pos":"ADJ"} ,
		{"word":"roucoulant","word_nosc":"roucoulant","lemma":"roucoulant","pos":"ADJ"} ,
		{"word":"roucoulante","word_nosc":"roucoulante","lemma":"roucoulant","pos":"ADJ"} ,
		{"word":"roucoulantes","word_nosc":"roucoulantes","lemma":"roucoulant","pos":"ADJ"} ,
		{"word":"roucoulants","word_nosc":"roucoulants","lemma":"roucoulant","pos":"ADJ"} ,
		{"word":"roucouleur","word_nosc":"roucouleur","lemma":"roucouleur","pos":"ADJ"} ,
		{"word":"rouennaise","word_nosc":"rouennaise","lemma":"rouennais","pos":"ADJ"} ,
		{"word":"rouergat","word_nosc":"rouergat","lemma":"rouergat","pos":"ADJ"} ,
		{"word":"rouge","word_nosc":"rouge","lemma":"rouge","pos":"ADJ"} ,
		{"word":"rouge-brun","word_nosc":"rouge-brun","lemma":"rouge-brun","pos":"ADJ"} ,
		{"word":"rouge-feu","word_nosc":"rouge-feu","lemma":"rouge-feu","pos":"ADJ"} ,
		{"word":"rougeaud","word_nosc":"rougeaud","lemma":"rougeaud","pos":"ADJ"} ,
		{"word":"rougeaude","word_nosc":"rougeaude","lemma":"rougeaud","pos":"ADJ"} ,
		{"word":"rougeaudes","word_nosc":"rougeaudes","lemma":"rougeaud","pos":"ADJ"} ,
		{"word":"rougeauds","word_nosc":"rougeauds","lemma":"rougeaud","pos":"ADJ"} ,
		{"word":"rougeoyant","word_nosc":"rougeoyant","lemma":"rougeoyant","pos":"ADJ"} ,
		{"word":"rougeoyante","word_nosc":"rougeoyante","lemma":"rougeoyant","pos":"ADJ"} ,
		{"word":"rougeoyantes","word_nosc":"rougeoyantes","lemma":"rougeoyant","pos":"ADJ"} ,
		{"word":"rougeoyants","word_nosc":"rougeoyants","lemma":"rougeoyant","pos":"ADJ"} ,
		{"word":"rouges","word_nosc":"rouges","lemma":"rouge","pos":"ADJ"} ,
		{"word":"rougeâtre","word_nosc":"rougeatre","lemma":"rougeâtre","pos":"ADJ"} ,
		{"word":"rougeâtres","word_nosc":"rougeatres","lemma":"rougeâtre","pos":"ADJ"} ,
		{"word":"rougissant","word_nosc":"rougissant","lemma":"rougissant","pos":"ADJ"} ,
		{"word":"rougissante","word_nosc":"rougissante","lemma":"rougissant","pos":"ADJ"} ,
		{"word":"rougissantes","word_nosc":"rougissantes","lemma":"rougissant","pos":"ADJ"} ,
		{"word":"rougissants","word_nosc":"rougissants","lemma":"rougissant","pos":"ADJ"} ,
		{"word":"rouille","word_nosc":"rouille","lemma":"rouille","pos":"ADJ"} ,
		{"word":"rouilleux","word_nosc":"rouilleux","lemma":"rouilleux","pos":"ADJ"} ,
		{"word":"roulant","word_nosc":"roulant","lemma":"roulant","pos":"ADJ"} ,
		{"word":"roulante","word_nosc":"roulante","lemma":"roulant","pos":"ADJ"} ,
		{"word":"roulantes","word_nosc":"roulantes","lemma":"roulant","pos":"ADJ"} ,
		{"word":"roulants","word_nosc":"roulants","lemma":"roulant","pos":"ADJ"} ,
		{"word":"rouleur","word_nosc":"rouleur","lemma":"rouleur","pos":"ADJ"} ,
		{"word":"rouleuse","word_nosc":"rouleuse","lemma":"rouleur","pos":"ADJ"} ,
		{"word":"rouleuses","word_nosc":"rouleuses","lemma":"rouleur","pos":"ADJ"} ,
		{"word":"roulé","word_nosc":"roule","lemma":"roulé","pos":"ADJ"} ,
		{"word":"roulée","word_nosc":"roulee","lemma":"roulé","pos":"ADJ"} ,
		{"word":"roulées","word_nosc":"roulees","lemma":"roulé","pos":"ADJ"} ,
		{"word":"roulés","word_nosc":"roules","lemma":"roulé","pos":"ADJ"} ,
		{"word":"roumain","word_nosc":"roumain","lemma":"roumain","pos":"ADJ"} ,
		{"word":"roumaine","word_nosc":"roumaine","lemma":"roumain","pos":"ADJ"} ,
		{"word":"roumaines","word_nosc":"roumaines","lemma":"roumain","pos":"ADJ"} ,
		{"word":"roumains","word_nosc":"roumains","lemma":"roumain","pos":"ADJ"} ,
		{"word":"rouquin","word_nosc":"rouquin","lemma":"rouquin","pos":"ADJ"} ,
		{"word":"rouquine","word_nosc":"rouquine","lemma":"rouquin","pos":"ADJ"} ,
		{"word":"rouquines","word_nosc":"rouquines","lemma":"rouquin","pos":"ADJ"} ,
		{"word":"rouquins","word_nosc":"rouquins","lemma":"rouquin","pos":"ADJ"} ,
		{"word":"rouspéteur","word_nosc":"rouspeteur","lemma":"rouspéteur","pos":"ADJ"} ,
		{"word":"rouspéteurs","word_nosc":"rouspeteurs","lemma":"rouspéteur","pos":"ADJ"} ,
		{"word":"rousse","word_nosc":"rousse","lemma":"roux","pos":"ADJ"} ,
		{"word":"rousseauiste","word_nosc":"rousseauiste","lemma":"rousseauiste","pos":"ADJ"} ,
		{"word":"rousses","word_nosc":"rousses","lemma":"roux","pos":"ADJ"} ,
		{"word":"roussi","word_nosc":"roussi","lemma":"roussi","pos":"ADJ"} ,
		{"word":"roussie","word_nosc":"roussie","lemma":"roussi","pos":"ADJ"} ,
		{"word":"roussies","word_nosc":"roussies","lemma":"roussi","pos":"ADJ"} ,
		{"word":"roussis","word_nosc":"roussis","lemma":"roussi","pos":"ADJ"} ,
		{"word":"roussâtre","word_nosc":"roussatre","lemma":"roussâtre","pos":"ADJ"} ,
		{"word":"roussâtres","word_nosc":"roussatres","lemma":"roussâtre","pos":"ADJ"} ,
		{"word":"routard","word_nosc":"routard","lemma":"routard","pos":"ADJ"} ,
		{"word":"routarde","word_nosc":"routarde","lemma":"routard","pos":"ADJ"} ,
		{"word":"routier","word_nosc":"routier","lemma":"routier","pos":"ADJ"} ,
		{"word":"routiers","word_nosc":"routiers","lemma":"routier","pos":"ADJ"} ,
		{"word":"routinier","word_nosc":"routinier","lemma":"routinier","pos":"ADJ"} ,
		{"word":"routiniers","word_nosc":"routiniers","lemma":"routinier","pos":"ADJ"} ,
		{"word":"routinière","word_nosc":"routiniere","lemma":"routinier","pos":"ADJ"} ,
		{"word":"routinières","word_nosc":"routinieres","lemma":"routinier","pos":"ADJ"} ,
		{"word":"routière","word_nosc":"routiere","lemma":"routier","pos":"ADJ"} ,
		{"word":"routières","word_nosc":"routieres","lemma":"routier","pos":"ADJ"} ,
		{"word":"rouverte","word_nosc":"rouverte","lemma":"rouvert","pos":"ADJ"} ,
		{"word":"rouvertes","word_nosc":"rouvertes","lemma":"rouvert","pos":"ADJ"} ,
		{"word":"rouverts","word_nosc":"rouverts","lemma":"rouvert","pos":"ADJ"} ,
		{"word":"roux","word_nosc":"roux","lemma":"roux","pos":"ADJ"} ,
		{"word":"roué","word_nosc":"roue","lemma":"roué","pos":"ADJ"} ,
		{"word":"rouée","word_nosc":"rouee","lemma":"roué","pos":"ADJ"} ,
		{"word":"roués","word_nosc":"roues","lemma":"roué","pos":"ADJ"} ,
		{"word":"royal","word_nosc":"royal","lemma":"royal","pos":"ADJ"} ,
		{"word":"royale","word_nosc":"royale","lemma":"royal","pos":"ADJ"} ,
		{"word":"royales","word_nosc":"royales","lemma":"royal","pos":"ADJ"} ,
		{"word":"royaliste","word_nosc":"royaliste","lemma":"royaliste","pos":"ADJ"} ,
		{"word":"royalistes","word_nosc":"royalistes","lemma":"royaliste","pos":"ADJ"} ,
		{"word":"royaux","word_nosc":"royaux","lemma":"royal","pos":"ADJ"} ,
		{"word":"rubican","word_nosc":"rubican","lemma":"rubican","pos":"ADJ"} ,
		{"word":"rubicond","word_nosc":"rubicond","lemma":"rubicond","pos":"ADJ"} ,
		{"word":"rubiconde","word_nosc":"rubiconde","lemma":"rubicond","pos":"ADJ"} ,
		{"word":"rubicondes","word_nosc":"rubicondes","lemma":"rubicond","pos":"ADJ"} ,
		{"word":"rubiconds","word_nosc":"rubiconds","lemma":"rubicond","pos":"ADJ"} ,
		{"word":"rubis","word_nosc":"rubis","lemma":"rubis","pos":"ADJ"} ,
		{"word":"rubénienne","word_nosc":"rubenienne","lemma":"rubénien","pos":"ADJ"} ,
		{"word":"rude","word_nosc":"rude","lemma":"rude","pos":"ADJ"} ,
		{"word":"rudes","word_nosc":"rudes","lemma":"rude","pos":"ADJ"} ,
		{"word":"rudimentaire","word_nosc":"rudimentaire","lemma":"rudimentaire","pos":"ADJ"} ,
		{"word":"rudimentaires","word_nosc":"rudimentaires","lemma":"rudimentaire","pos":"ADJ"} ,
		{"word":"rugissant","word_nosc":"rugissant","lemma":"rugissant","pos":"ADJ"} ,
		{"word":"rugissante","word_nosc":"rugissante","lemma":"rugissant","pos":"ADJ"} ,
		{"word":"rugissantes","word_nosc":"rugissantes","lemma":"rugissant","pos":"ADJ"} ,
		{"word":"rugissants","word_nosc":"rugissants","lemma":"rugissant","pos":"ADJ"} ,
		{"word":"rugueuse","word_nosc":"rugueuse","lemma":"rugueux","pos":"ADJ"} ,
		{"word":"rugueuses","word_nosc":"rugueuses","lemma":"rugueux","pos":"ADJ"} ,
		{"word":"rugueux","word_nosc":"rugueux","lemma":"rugueux","pos":"ADJ"} ,
		{"word":"ruineuse","word_nosc":"ruineuse","lemma":"ruineux","pos":"ADJ"} ,
		{"word":"ruineuses","word_nosc":"ruineuses","lemma":"ruineux","pos":"ADJ"} ,
		{"word":"ruineux","word_nosc":"ruineux","lemma":"ruineux","pos":"ADJ"} ,
		{"word":"ruiné","word_nosc":"ruine","lemma":"ruiné","pos":"ADJ"} ,
		{"word":"ruinée","word_nosc":"ruinee","lemma":"ruiné","pos":"ADJ"} ,
		{"word":"ruinées","word_nosc":"ruinees","lemma":"ruiné","pos":"ADJ"} ,
		{"word":"ruinés","word_nosc":"ruines","lemma":"ruiné","pos":"ADJ"} ,
		{"word":"ruisselant","word_nosc":"ruisselant","lemma":"ruisselant","pos":"ADJ"} ,
		{"word":"ruisselante","word_nosc":"ruisselante","lemma":"ruisselant","pos":"ADJ"} ,
		{"word":"ruisselantes","word_nosc":"ruisselantes","lemma":"ruisselant","pos":"ADJ"} ,
		{"word":"ruisselants","word_nosc":"ruisselants","lemma":"ruisselant","pos":"ADJ"} ,
		{"word":"ruminant","word_nosc":"ruminant","lemma":"ruminant","pos":"ADJ"} ,
		{"word":"ruminante","word_nosc":"ruminante","lemma":"ruminant","pos":"ADJ"} ,
		{"word":"ruminants","word_nosc":"ruminants","lemma":"ruminant","pos":"ADJ"} ,
		{"word":"runique","word_nosc":"runique","lemma":"runique","pos":"ADJ"} ,
		{"word":"runiques","word_nosc":"runiques","lemma":"runique","pos":"ADJ"} ,
		{"word":"rupestre","word_nosc":"rupestre","lemma":"rupestre","pos":"ADJ"} ,
		{"word":"rupestres","word_nosc":"rupestres","lemma":"rupestre","pos":"ADJ"} ,
		{"word":"rupin","word_nosc":"rupin","lemma":"rupin","pos":"ADJ"} ,
		{"word":"rupine","word_nosc":"rupine","lemma":"rupin","pos":"ADJ"} ,
		{"word":"rupins","word_nosc":"rupins","lemma":"rupin","pos":"ADJ"} ,
		{"word":"rural","word_nosc":"rural","lemma":"rural","pos":"ADJ"} ,
		{"word":"rurale","word_nosc":"rurale","lemma":"rural","pos":"ADJ"} ,
		{"word":"rurales","word_nosc":"rurales","lemma":"rural","pos":"ADJ"} ,
		{"word":"ruraux","word_nosc":"ruraux","lemma":"rural","pos":"ADJ"} ,
		{"word":"russe","word_nosc":"russe","lemma":"russe","pos":"ADJ"} ,
		{"word":"russes","word_nosc":"russes","lemma":"russe","pos":"ADJ"} ,
		{"word":"russo-allemand","word_nosc":"russo-allemand","lemma":"russo-allemand","pos":"ADJ"} ,
		{"word":"russo-allemande","word_nosc":"russo-allemande","lemma":"russo-allemand","pos":"ADJ"} ,
		{"word":"russo-américain","word_nosc":"russo-americain","lemma":"russo-américain","pos":"ADJ"} ,
		{"word":"russo-japonaise","word_nosc":"russo-japonaise","lemma":"russo-japonais","pos":"ADJ"} ,
		{"word":"russo-polonais","word_nosc":"russo-polonais","lemma":"russo-polonais","pos":"ADJ"} ,
		{"word":"russo-polonaise","word_nosc":"russo-polonaise","lemma":"russo-polonais","pos":"ADJ"} ,
		{"word":"russo-turque","word_nosc":"russo-turque","lemma":"russo-turque","pos":"ADJ"} ,
		{"word":"russophone","word_nosc":"russophone","lemma":"russophone","pos":"ADJ"} ,
		{"word":"rustaud","word_nosc":"rustaud","lemma":"rustaud","pos":"ADJ"} ,
		{"word":"rustaude","word_nosc":"rustaude","lemma":"rustaud","pos":"ADJ"} ,
		{"word":"rustaudes","word_nosc":"rustaudes","lemma":"rustaud","pos":"ADJ"} ,
		{"word":"rustauds","word_nosc":"rustauds","lemma":"rustaud","pos":"ADJ"} ,
		{"word":"rustique","word_nosc":"rustique","lemma":"rustique","pos":"ADJ"} ,
		{"word":"rustiques","word_nosc":"rustiques","lemma":"rustique","pos":"ADJ"} ,
		{"word":"rustre","word_nosc":"rustre","lemma":"rustre","pos":"ADJ"} ,
		{"word":"rustres","word_nosc":"rustres","lemma":"rustre","pos":"ADJ"} ,
		{"word":"rusé","word_nosc":"ruse","lemma":"rusé","pos":"ADJ"} ,
		{"word":"rusée","word_nosc":"rusee","lemma":"rusé","pos":"ADJ"} ,
		{"word":"rusées","word_nosc":"rusees","lemma":"rusé","pos":"ADJ"} ,
		{"word":"rusés","word_nosc":"ruses","lemma":"rusé","pos":"ADJ"} ,
		{"word":"rutilant","word_nosc":"rutilant","lemma":"rutilant","pos":"ADJ"} ,
		{"word":"rutilante","word_nosc":"rutilante","lemma":"rutilant","pos":"ADJ"} ,
		{"word":"rutilantes","word_nosc":"rutilantes","lemma":"rutilant","pos":"ADJ"} ,
		{"word":"rutilants","word_nosc":"rutilants","lemma":"rutilant","pos":"ADJ"} ,
		{"word":"rythmique","word_nosc":"rythmique","lemma":"rythmique","pos":"ADJ"} ,
		{"word":"rythmiques","word_nosc":"rythmiques","lemma":"rythmique","pos":"ADJ"} ,
		{"word":"rythmé","word_nosc":"rythme","lemma":"rythmé","pos":"ADJ"} ,
		{"word":"rythmée","word_nosc":"rythmee","lemma":"rythmé","pos":"ADJ"} ,
		{"word":"rythmées","word_nosc":"rythmees","lemma":"rythmé","pos":"ADJ"} ,
		{"word":"rythmés","word_nosc":"rythmes","lemma":"rythmé","pos":"ADJ"} ,
		{"word":"râblé","word_nosc":"rable","lemma":"râblé","pos":"ADJ"} ,
		{"word":"râblée","word_nosc":"rablee","lemma":"râblé","pos":"ADJ"} ,
		{"word":"râblés","word_nosc":"rables","lemma":"râblé","pos":"ADJ"} ,
		{"word":"râlant","word_nosc":"ralant","lemma":"râlant","pos":"ADJ"} ,
		{"word":"râlante","word_nosc":"ralante","lemma":"râlant","pos":"ADJ"} ,
		{"word":"râleur","word_nosc":"raleur","lemma":"râleur","pos":"ADJ"} ,
		{"word":"râleurs","word_nosc":"raleurs","lemma":"râleur","pos":"ADJ"} ,
		{"word":"râleuse","word_nosc":"raleuse","lemma":"râleux","pos":"ADJ"} ,
		{"word":"râpeuse","word_nosc":"rapeuse","lemma":"râpeux","pos":"ADJ"} ,
		{"word":"râpeuses","word_nosc":"rapeuses","lemma":"râpeux","pos":"ADJ"} ,
		{"word":"râpeux","word_nosc":"rapeux","lemma":"râpeux","pos":"ADJ"} ,
		{"word":"râpé","word_nosc":"rape","lemma":"râpé","pos":"ADJ"} ,
		{"word":"râpée","word_nosc":"rapee","lemma":"râpé","pos":"ADJ"} ,
		{"word":"râpées","word_nosc":"rapees","lemma":"râpé","pos":"ADJ"} ,
		{"word":"râpés","word_nosc":"rapes","lemma":"râpé","pos":"ADJ"} ,
		{"word":"réac","word_nosc":"reac","lemma":"réac","pos":"ADJ"} ,
		{"word":"réacs","word_nosc":"reacs","lemma":"réac","pos":"ADJ"} ,
		{"word":"réactif","word_nosc":"reactif","lemma":"réactif","pos":"ADJ"} ,
		{"word":"réactifs","word_nosc":"reactifs","lemma":"réactif","pos":"ADJ"} ,
		{"word":"réactionnaire","word_nosc":"reactionnaire","lemma":"réactionnaire","pos":"ADJ"} ,
		{"word":"réactionnaires","word_nosc":"reactionnaires","lemma":"réactionnaire","pos":"ADJ"} ,
		{"word":"réactionnel","word_nosc":"reactionnel","lemma":"réactionnel","pos":"ADJ"} ,
		{"word":"réactive","word_nosc":"reactive","lemma":"réactif","pos":"ADJ"} ,
		{"word":"réactives","word_nosc":"reactives","lemma":"réactif","pos":"ADJ"} ,
		{"word":"réagissante","word_nosc":"reagissante","lemma":"réagissant","pos":"ADJ"} ,
		{"word":"réalisable","word_nosc":"realisable","lemma":"réalisable","pos":"ADJ"} ,
		{"word":"réalisables","word_nosc":"realisables","lemma":"réalisable","pos":"ADJ"} ,
		{"word":"réaliste","word_nosc":"realiste","lemma":"réaliste","pos":"ADJ"} ,
		{"word":"réalistes","word_nosc":"realistes","lemma":"réaliste","pos":"ADJ"} ,
		{"word":"réalisé","word_nosc":"realise","lemma":"réalisé","pos":"ADJ"} ,
		{"word":"réalisée","word_nosc":"realisee","lemma":"réalisé","pos":"ADJ"} ,
		{"word":"réalisées","word_nosc":"realisees","lemma":"réalisé","pos":"ADJ"} ,
		{"word":"réalisés","word_nosc":"realises","lemma":"réalisé","pos":"ADJ"} ,
		{"word":"réaux","word_nosc":"reaux","lemma":"réal","pos":"ADJ"} ,
		{"word":"rébarbatif","word_nosc":"rebarbatif","lemma":"rébarbatif","pos":"ADJ"} ,
		{"word":"rébarbatifs","word_nosc":"rebarbatifs","lemma":"rébarbatif","pos":"ADJ"} ,
		{"word":"rébarbative","word_nosc":"rebarbative","lemma":"rébarbatif","pos":"ADJ"} ,
		{"word":"rébarbatives","word_nosc":"rebarbatives","lemma":"rébarbatif","pos":"ADJ"} ,
		{"word":"récalcitrant","word_nosc":"recalcitrant","lemma":"récalcitrant","pos":"ADJ"} ,
		{"word":"récalcitrante","word_nosc":"recalcitrante","lemma":"récalcitrant","pos":"ADJ"} ,
		{"word":"récalcitrantes","word_nosc":"recalcitrantes","lemma":"récalcitrant","pos":"ADJ"} ,
		{"word":"récalcitrants","word_nosc":"recalcitrants","lemma":"récalcitrant","pos":"ADJ"} ,
		{"word":"récapitulatif","word_nosc":"recapitulatif","lemma":"récapitulatif","pos":"ADJ"} ,
		{"word":"récent","word_nosc":"recent","lemma":"récent","pos":"ADJ"} ,
		{"word":"récente","word_nosc":"recente","lemma":"récent","pos":"ADJ"} ,
		{"word":"récentes","word_nosc":"recentes","lemma":"récent","pos":"ADJ"} ,
		{"word":"récents","word_nosc":"recents","lemma":"récent","pos":"ADJ"} ,
		{"word":"récepteur","word_nosc":"recepteur","lemma":"récepteur","pos":"ADJ"} ,
		{"word":"récepteurs","word_nosc":"recepteurs","lemma":"récepteur","pos":"ADJ"} ,
		{"word":"réceptif","word_nosc":"receptif","lemma":"réceptif","pos":"ADJ"} ,
		{"word":"réceptifs","word_nosc":"receptifs","lemma":"réceptif","pos":"ADJ"} ,
		{"word":"réceptive","word_nosc":"receptive","lemma":"réceptif","pos":"ADJ"} ,
		{"word":"réceptives","word_nosc":"receptives","lemma":"réceptif","pos":"ADJ"} ,
		{"word":"réceptrice","word_nosc":"receptrice","lemma":"récepteur","pos":"ADJ"} ,
		{"word":"récessif","word_nosc":"recessif","lemma":"récessif","pos":"ADJ"} ,
		{"word":"récessifs","word_nosc":"recessifs","lemma":"récessif","pos":"ADJ"} ,
		{"word":"récessive","word_nosc":"recessive","lemma":"récessif","pos":"ADJ"} ,
		{"word":"réchauffante","word_nosc":"rechauffante","lemma":"réchauffant","pos":"ADJ"} ,
		{"word":"réchauffantes","word_nosc":"rechauffantes","lemma":"réchauffant","pos":"ADJ"} ,
		{"word":"réchauffants","word_nosc":"rechauffants","lemma":"réchauffant","pos":"ADJ"} ,
		{"word":"réchauffé","word_nosc":"rechauffe","lemma":"réchauffé","pos":"ADJ"} ,
		{"word":"réchauffée","word_nosc":"rechauffee","lemma":"réchauffé","pos":"ADJ"} ,
		{"word":"réchauffées","word_nosc":"rechauffees","lemma":"réchauffé","pos":"ADJ"} ,
		{"word":"réchauffés","word_nosc":"rechauffes","lemma":"réchauffé","pos":"ADJ"} ,
		{"word":"récidivant","word_nosc":"recidivant","lemma":"récidivant","pos":"ADJ"} ,
		{"word":"récidivante","word_nosc":"recidivante","lemma":"récidivant","pos":"ADJ"} ,
		{"word":"récidiviste","word_nosc":"recidiviste","lemma":"récidiviste","pos":"ADJ"} ,
		{"word":"récidivistes","word_nosc":"recidivistes","lemma":"récidiviste","pos":"ADJ"} ,
		{"word":"récifale","word_nosc":"recifale","lemma":"récifal","pos":"ADJ"} ,
		{"word":"réciproque","word_nosc":"reciproque","lemma":"réciproque","pos":"ADJ"} ,
		{"word":"réciproques","word_nosc":"reciproques","lemma":"réciproque","pos":"ADJ"} ,
		{"word":"récitatif","word_nosc":"recitatif","lemma":"récitatif","pos":"ADJ"} ,
		{"word":"récoltable","word_nosc":"recoltable","lemma":"récoltable","pos":"ADJ"} ,
		{"word":"réconciliatrice","word_nosc":"reconciliatrice","lemma":"réconciliateur","pos":"ADJ"} ,
		{"word":"réconfortant","word_nosc":"reconfortant","lemma":"réconfortant","pos":"ADJ"} ,
		{"word":"réconfortante","word_nosc":"reconfortante","lemma":"réconfortant","pos":"ADJ"} ,
		{"word":"réconfortantes","word_nosc":"reconfortantes","lemma":"réconfortant","pos":"ADJ"} ,
		{"word":"réconfortants","word_nosc":"reconfortants","lemma":"réconfortant","pos":"ADJ"} ,
		{"word":"récréatif","word_nosc":"recreatif","lemma":"récréatif","pos":"ADJ"} ,
		{"word":"récréative","word_nosc":"recreative","lemma":"récréatif","pos":"ADJ"} ,
		{"word":"récréatives","word_nosc":"recreatives","lemma":"récréatif","pos":"ADJ"} ,
		{"word":"récupérable","word_nosc":"recuperable","lemma":"récupérable","pos":"ADJ"} ,
		{"word":"récupérables","word_nosc":"recuperables","lemma":"récupérable","pos":"ADJ"} ,
		{"word":"récupérateur","word_nosc":"recuperateur","lemma":"récupérateur","pos":"ADJ"} ,
		{"word":"récupérateurs","word_nosc":"recuperateurs","lemma":"récupérateur","pos":"ADJ"} ,
		{"word":"récurrent","word_nosc":"recurrent","lemma":"récurrent","pos":"ADJ"} ,
		{"word":"récurrente","word_nosc":"recurrente","lemma":"récurrent","pos":"ADJ"} ,
		{"word":"récurrentes","word_nosc":"recurrentes","lemma":"récurrent","pos":"ADJ"} ,
		{"word":"récurrents","word_nosc":"recurrents","lemma":"récurrent","pos":"ADJ"} ,
		{"word":"récursif","word_nosc":"recursif","lemma":"récursif","pos":"ADJ"} ,
		{"word":"récusable","word_nosc":"recusable","lemma":"récusable","pos":"ADJ"} ,
		{"word":"récusables","word_nosc":"recusables","lemma":"récusable","pos":"ADJ"} ,
		{"word":"rédactionnel","word_nosc":"redactionnel","lemma":"rédactionnel","pos":"ADJ"} ,
		{"word":"rédactionnelle","word_nosc":"redactionnelle","lemma":"rédactionnel","pos":"ADJ"} ,
		{"word":"rédempteur","word_nosc":"redempteur","lemma":"rédempteur","pos":"ADJ"} ,
		{"word":"rédemptrice","word_nosc":"redemptrice","lemma":"rédempteur","pos":"ADJ"} ,
		{"word":"rédhibitoire","word_nosc":"redhibitoire","lemma":"rédhibitoire","pos":"ADJ"} ,
		{"word":"rédhibitoires","word_nosc":"redhibitoires","lemma":"rédhibitoire","pos":"ADJ"} ,
		{"word":"réducteur","word_nosc":"reducteur","lemma":"réducteur","pos":"ADJ"} ,
		{"word":"réductionniste","word_nosc":"reductionniste","lemma":"réductionniste","pos":"ADJ"} ,
		{"word":"réduit","word_nosc":"reduit","lemma":"réduit","pos":"ADJ"} ,
		{"word":"réduite","word_nosc":"reduite","lemma":"réduit","pos":"ADJ"} ,
		{"word":"réduites","word_nosc":"reduites","lemma":"réduit","pos":"ADJ"} ,
		{"word":"réduits","word_nosc":"reduits","lemma":"réduit","pos":"ADJ"} ,
		{"word":"réel","word_nosc":"reel","lemma":"réel","pos":"ADJ"} ,
		{"word":"réelle","word_nosc":"reelle","lemma":"réel","pos":"ADJ"} ,
		{"word":"réelles","word_nosc":"reelles","lemma":"réel","pos":"ADJ"} ,
		{"word":"réels","word_nosc":"reels","lemma":"réel","pos":"ADJ"} ,
		{"word":"réflexe","word_nosc":"reflexe","lemma":"réflexe","pos":"ADJ"} ,
		{"word":"réflexes","word_nosc":"reflexes","lemma":"réflexe","pos":"ADJ"} ,
		{"word":"réflexif","word_nosc":"reflexif","lemma":"réflexif","pos":"ADJ"} ,
		{"word":"réflexive","word_nosc":"reflexive","lemma":"réflexif","pos":"ADJ"} ,
		{"word":"réfléchi","word_nosc":"reflechi","lemma":"réfléchi","pos":"ADJ"} ,
		{"word":"réfléchie","word_nosc":"reflechie","lemma":"réfléchi","pos":"ADJ"} ,
		{"word":"réfléchies","word_nosc":"reflechies","lemma":"réfléchi","pos":"ADJ"} ,
		{"word":"réfléchis","word_nosc":"reflechis","lemma":"réfléchi","pos":"ADJ"} ,
		{"word":"réfléchissant","word_nosc":"reflechissant","lemma":"réfléchissant","pos":"ADJ"} ,
		{"word":"réfléchissante","word_nosc":"reflechissante","lemma":"réfléchissant","pos":"ADJ"} ,
		{"word":"réfléchissantes","word_nosc":"reflechissantes","lemma":"réfléchissant","pos":"ADJ"} ,
		{"word":"réfléchissants","word_nosc":"reflechissants","lemma":"réfléchissant","pos":"ADJ"} ,
		{"word":"réformateur","word_nosc":"reformateur","lemma":"réformateur","pos":"ADJ"} ,
		{"word":"réformateurs","word_nosc":"reformateurs","lemma":"réformateur","pos":"ADJ"} ,
		{"word":"réformiste","word_nosc":"reformiste","lemma":"réformiste","pos":"ADJ"} ,
		{"word":"réformistes","word_nosc":"reformistes","lemma":"réformiste","pos":"ADJ"} ,
		{"word":"réformé","word_nosc":"reforme","lemma":"réformé","pos":"ADJ"} ,
		{"word":"réformée","word_nosc":"reformee","lemma":"réformé","pos":"ADJ"} ,
		{"word":"réformées","word_nosc":"reformees","lemma":"réformé","pos":"ADJ"} ,
		{"word":"réformés","word_nosc":"reformes","lemma":"réformé","pos":"ADJ"} ,
		{"word":"réfractaire","word_nosc":"refractaire","lemma":"réfractaire","pos":"ADJ"} ,
		{"word":"réfractaires","word_nosc":"refractaires","lemma":"réfractaire","pos":"ADJ"} ,
		{"word":"réfrigérant","word_nosc":"refrigerant","lemma":"réfrigérant","pos":"ADJ"} ,
		{"word":"réfrigérante","word_nosc":"refrigerante","lemma":"réfrigérant","pos":"ADJ"} ,
		{"word":"réfrigérantes","word_nosc":"refrigerantes","lemma":"réfrigérant","pos":"ADJ"} ,
		{"word":"réfrigérants","word_nosc":"refrigerants","lemma":"réfrigérant","pos":"ADJ"} ,
		{"word":"réfrigéré","word_nosc":"refrigere","lemma":"réfrigéré","pos":"ADJ"} ,
		{"word":"réfrigérée","word_nosc":"refrigeree","lemma":"réfrigéré","pos":"ADJ"} ,
		{"word":"réfrigérées","word_nosc":"refrigerees","lemma":"réfrigéré","pos":"ADJ"} ,
		{"word":"réfrigérés","word_nosc":"refrigeres","lemma":"réfrigéré","pos":"ADJ"} ,
		{"word":"réfugié","word_nosc":"refugie","lemma":"réfugié","pos":"ADJ"} ,
		{"word":"réfugiée","word_nosc":"refugiee","lemma":"réfugié","pos":"ADJ"} ,
		{"word":"réfugiées","word_nosc":"refugiees","lemma":"réfugié","pos":"ADJ"} ,
		{"word":"réfugiés","word_nosc":"refugies","lemma":"réfugié","pos":"ADJ"} ,
		{"word":"réfutable","word_nosc":"refutable","lemma":"réfutable","pos":"ADJ"} ,
		{"word":"référendaire","word_nosc":"referendaire","lemma":"référendaire","pos":"ADJ"} ,
		{"word":"régale","word_nosc":"regale","lemma":"régale","pos":"ADJ"} ,
		{"word":"régalien","word_nosc":"regalien","lemma":"régalien","pos":"ADJ"} ,
		{"word":"régicide","word_nosc":"regicide","lemma":"régicide","pos":"ADJ"} ,
		{"word":"régimentaire","word_nosc":"regimentaire","lemma":"régimentaire","pos":"ADJ"} ,
		{"word":"régimentaires","word_nosc":"regimentaires","lemma":"régimentaire","pos":"ADJ"} ,
		{"word":"régional","word_nosc":"regional","lemma":"régional","pos":"ADJ"} ,
		{"word":"régionale","word_nosc":"regionale","lemma":"régional","pos":"ADJ"} ,
		{"word":"régionales","word_nosc":"regionales","lemma":"régional","pos":"ADJ"} ,
		{"word":"régionaliste","word_nosc":"regionaliste","lemma":"régionaliste","pos":"ADJ"} ,
		{"word":"régionalistes","word_nosc":"regionalistes","lemma":"régionaliste","pos":"ADJ"} ,
		{"word":"réglable","word_nosc":"reglable","lemma":"réglable","pos":"ADJ"} ,
		{"word":"réglables","word_nosc":"reglables","lemma":"réglable","pos":"ADJ"} ,
		{"word":"réglementaire","word_nosc":"reglementaire","lemma":"réglementaire","pos":"ADJ"} ,
		{"word":"réglementaires","word_nosc":"reglementaires","lemma":"réglementaire","pos":"ADJ"} ,
		{"word":"réglisse","word_nosc":"reglisse","lemma":"réglisse","pos":"ADJ"} ,
		{"word":"réglo","word_nosc":"reglo","lemma":"réglo","pos":"ADJ"} ,
		{"word":"réglos","word_nosc":"reglos","lemma":"réglo","pos":"ADJ"} ,
		{"word":"réglé","word_nosc":"regle","lemma":"réglé","pos":"ADJ"} ,
		{"word":"réglée","word_nosc":"reglee","lemma":"réglé","pos":"ADJ"} ,
		{"word":"réglées","word_nosc":"reglees","lemma":"réglé","pos":"ADJ"} ,
		{"word":"réglés","word_nosc":"regles","lemma":"réglé","pos":"ADJ"} ,
		{"word":"régnant","word_nosc":"regnant","lemma":"régnant","pos":"ADJ"} ,
		{"word":"régnante","word_nosc":"regnante","lemma":"régnant","pos":"ADJ"} ,
		{"word":"régnants","word_nosc":"regnants","lemma":"régnant","pos":"ADJ"} ,
		{"word":"régressif","word_nosc":"regressif","lemma":"régressif","pos":"ADJ"} ,
		{"word":"régressive","word_nosc":"regressive","lemma":"régressif","pos":"ADJ"} ,
		{"word":"régul","word_nosc":"regul","lemma":"régul","pos":"ADJ"} ,
		{"word":"régulateur","word_nosc":"regulateur","lemma":"régulateur","pos":"ADJ"} ,
		{"word":"régulateurs","word_nosc":"regulateurs","lemma":"régulateur","pos":"ADJ"} ,
		{"word":"régulatrice","word_nosc":"regulatrice","lemma":"régulateur","pos":"ADJ"} ,
		{"word":"régulatrices","word_nosc":"regulatrices","lemma":"régulateur","pos":"ADJ"} ,
		{"word":"régulier","word_nosc":"regulier","lemma":"régulier","pos":"ADJ"} ,
		{"word":"réguliers","word_nosc":"reguliers","lemma":"régulier","pos":"ADJ"} ,
		{"word":"régulière","word_nosc":"reguliere","lemma":"régulier","pos":"ADJ"} ,
		{"word":"régulières","word_nosc":"regulieres","lemma":"régulier","pos":"ADJ"} ,
		{"word":"régénérateur","word_nosc":"regenerateur","lemma":"régénérateur","pos":"ADJ"} ,
		{"word":"régénérateurs","word_nosc":"regenerateurs","lemma":"régénérateur","pos":"ADJ"} ,
		{"word":"régénératrice","word_nosc":"regeneratrice","lemma":"régénérateur","pos":"ADJ"} ,
		{"word":"régénéré","word_nosc":"regenere","lemma":"régénéré","pos":"ADJ"} ,
		{"word":"régénérée","word_nosc":"regeneree","lemma":"régénéré","pos":"ADJ"} ,
		{"word":"régénérées","word_nosc":"regenerees","lemma":"régénéré","pos":"ADJ"} ,
		{"word":"régénérés","word_nosc":"regeneres","lemma":"régénéré","pos":"ADJ"} ,
		{"word":"réhabilité","word_nosc":"rehabilite","lemma":"réhabilité","pos":"ADJ"} ,
		{"word":"réhabilitée","word_nosc":"rehabilitee","lemma":"réhabilité","pos":"ADJ"} ,
		{"word":"réhabilités","word_nosc":"rehabilites","lemma":"réhabilité","pos":"ADJ"} ,
		{"word":"réjoui","word_nosc":"rejoui","lemma":"réjoui","pos":"ADJ"} ,
		{"word":"réjouie","word_nosc":"rejouie","lemma":"réjoui","pos":"ADJ"} ,
		{"word":"réjouies","word_nosc":"rejouies","lemma":"réjoui","pos":"ADJ"} ,
		{"word":"réjouis","word_nosc":"rejouis","lemma":"réjoui","pos":"ADJ"} ,
		{"word":"réjouissant","word_nosc":"rejouissant","lemma":"réjouissant","pos":"ADJ"} ,
		{"word":"réjouissante","word_nosc":"rejouissante","lemma":"réjouissant","pos":"ADJ"} ,
		{"word":"réjouissantes","word_nosc":"rejouissantes","lemma":"réjouissant","pos":"ADJ"} ,
		{"word":"réjouissants","word_nosc":"rejouissants","lemma":"réjouissant","pos":"ADJ"} ,
		{"word":"rémanent","word_nosc":"remanent","lemma":"rémanent","pos":"ADJ"} ,
		{"word":"rémanente","word_nosc":"remanente","lemma":"rémanent","pos":"ADJ"} ,
		{"word":"rémunérateur","word_nosc":"remunerateur","lemma":"rémunérateur","pos":"ADJ"} ,
		{"word":"rémunérateurs","word_nosc":"remunerateurs","lemma":"rémunérateur","pos":"ADJ"} ,
		{"word":"rémunératoires","word_nosc":"remuneratoires","lemma":"rémunératoire","pos":"ADJ"} ,
		{"word":"rémunératrice","word_nosc":"remuneratrice","lemma":"rémunérateur","pos":"ADJ"} ,
		{"word":"rénal","word_nosc":"renal","lemma":"rénal","pos":"ADJ"} ,
		{"word":"rénale","word_nosc":"renale","lemma":"rénal","pos":"ADJ"} ,
		{"word":"rénales","word_nosc":"renales","lemma":"rénal","pos":"ADJ"} ,
		{"word":"rénaux","word_nosc":"renaux","lemma":"rénal","pos":"ADJ"} ,
		{"word":"rénovateurs","word_nosc":"renovateurs","lemma":"rénovateur","pos":"ADJ"} ,
		{"word":"rénovatrice","word_nosc":"renovatrice","lemma":"rénovateur","pos":"ADJ"} ,
		{"word":"répandu","word_nosc":"repandu","lemma":"répandu","pos":"ADJ"} ,
		{"word":"répandue","word_nosc":"repandue","lemma":"répandu","pos":"ADJ"} ,
		{"word":"répandues","word_nosc":"repandues","lemma":"répandu","pos":"ADJ"} ,
		{"word":"répandus","word_nosc":"repandus","lemma":"répandu","pos":"ADJ"} ,
		{"word":"réparable","word_nosc":"reparable","lemma":"réparable","pos":"ADJ"} ,
		{"word":"réparables","word_nosc":"reparables","lemma":"réparable","pos":"ADJ"} ,
		{"word":"réparateur","word_nosc":"reparateur","lemma":"réparateur","pos":"ADJ"} ,
		{"word":"réparateurs","word_nosc":"reparateurs","lemma":"réparateur","pos":"ADJ"} ,
		{"word":"réparatrice","word_nosc":"reparatrice","lemma":"réparateur","pos":"ADJ"} ,
		{"word":"réparatrices","word_nosc":"reparatrices","lemma":"réparateur","pos":"ADJ"} ,
		{"word":"réparti","word_nosc":"reparti","lemma":"réparti","pos":"ADJ"} ,
		{"word":"répartis","word_nosc":"repartis","lemma":"réparti","pos":"ADJ"} ,
		{"word":"répondeur","word_nosc":"repondeur","lemma":"répondeur","pos":"ADJ"} ,
		{"word":"répondeuse","word_nosc":"repondeuse","lemma":"répondeur","pos":"ADJ"} ,
		{"word":"répressif","word_nosc":"repressif","lemma":"répressif","pos":"ADJ"} ,
		{"word":"répressive","word_nosc":"repressive","lemma":"répressif","pos":"ADJ"} ,
		{"word":"répressives","word_nosc":"repressives","lemma":"répressif","pos":"ADJ"} ,
		{"word":"réprobateur","word_nosc":"reprobateur","lemma":"réprobateur","pos":"ADJ"} ,
		{"word":"réprobateurs","word_nosc":"reprobateurs","lemma":"réprobateur","pos":"ADJ"} ,
		{"word":"réprobatrice","word_nosc":"reprobatrice","lemma":"réprobateur","pos":"ADJ"} ,
		{"word":"réprouvés","word_nosc":"reprouves","lemma":"réprouvé","pos":"ADJ"} ,
		{"word":"répréhensible","word_nosc":"reprehensible","lemma":"répréhensible","pos":"ADJ"} ,
		{"word":"répréhensibles","word_nosc":"reprehensibles","lemma":"répréhensible","pos":"ADJ"} ,
		{"word":"républicain","word_nosc":"republicain","lemma":"républicain","pos":"ADJ"} ,
		{"word":"républicaine","word_nosc":"republicaine","lemma":"républicain","pos":"ADJ"} ,
		{"word":"républicaines","word_nosc":"republicaines","lemma":"républicain","pos":"ADJ"} ,
		{"word":"républicains","word_nosc":"republicains","lemma":"républicain","pos":"ADJ"} ,
		{"word":"répugnant","word_nosc":"repugnant","lemma":"répugnant","pos":"ADJ"} ,
		{"word":"répugnante","word_nosc":"repugnante","lemma":"répugnant","pos":"ADJ"} ,
		{"word":"répugnantes","word_nosc":"repugnantes","lemma":"répugnant","pos":"ADJ"} ,
		{"word":"répugnants","word_nosc":"repugnants","lemma":"répugnant","pos":"ADJ"} ,
		{"word":"répulsif","word_nosc":"repulsif","lemma":"répulsif","pos":"ADJ"} ,
		{"word":"répulsive","word_nosc":"repulsive","lemma":"répulsif","pos":"ADJ"} ,
		{"word":"réputé","word_nosc":"repute","lemma":"réputé","pos":"ADJ"} ,
		{"word":"réputée","word_nosc":"reputee","lemma":"réputé","pos":"ADJ"} ,
		{"word":"réputées","word_nosc":"reputees","lemma":"réputé","pos":"ADJ"} ,
		{"word":"réputés","word_nosc":"reputes","lemma":"réputé","pos":"ADJ"} ,
		{"word":"répétitif","word_nosc":"repetitif","lemma":"répétitif","pos":"ADJ"} ,
		{"word":"répétitifs","word_nosc":"repetitifs","lemma":"répétitif","pos":"ADJ"} ,
		{"word":"répétitive","word_nosc":"repetitive","lemma":"répétitif","pos":"ADJ"} ,
		{"word":"répétitives","word_nosc":"repetitives","lemma":"répétitif","pos":"ADJ"} ,
		{"word":"répété","word_nosc":"repete","lemma":"répété","pos":"ADJ"} ,
		{"word":"répétée","word_nosc":"repetee","lemma":"répété","pos":"ADJ"} ,
		{"word":"répétées","word_nosc":"repetees","lemma":"répété","pos":"ADJ"} ,
		{"word":"répétés","word_nosc":"repetes","lemma":"répété","pos":"ADJ"} ,
		{"word":"réservataire","word_nosc":"reservataire","lemma":"réservataire","pos":"ADJ"} ,
		{"word":"réservé","word_nosc":"reserve","lemma":"réservé","pos":"ADJ"} ,
		{"word":"réservée","word_nosc":"reservee","lemma":"réservé","pos":"ADJ"} ,
		{"word":"réservées","word_nosc":"reservees","lemma":"réservé","pos":"ADJ"} ,
		{"word":"réservés","word_nosc":"reserves","lemma":"réservé","pos":"ADJ"} ,
		{"word":"résidant","word_nosc":"residant","lemma":"résidant","pos":"ADJ"} ,
		{"word":"résident","word_nosc":"resident","lemma":"résident","pos":"ADJ"} ,
		{"word":"résidente","word_nosc":"residente","lemma":"résident","pos":"ADJ"} ,
		{"word":"résidentiel","word_nosc":"residentiel","lemma":"résidentiel","pos":"ADJ"} ,
		{"word":"résidentielle","word_nosc":"residentielle","lemma":"résidentiel","pos":"ADJ"} ,
		{"word":"résidentielles","word_nosc":"residentielles","lemma":"résidentiel","pos":"ADJ"} ,
		{"word":"résidentiels","word_nosc":"residentiels","lemma":"résidentiel","pos":"ADJ"} ,
		{"word":"résidents","word_nosc":"residents","lemma":"résident","pos":"ADJ"} ,
		{"word":"résiduel","word_nosc":"residuel","lemma":"résiduel","pos":"ADJ"} ,
		{"word":"résiduelle","word_nosc":"residuelle","lemma":"résiduel","pos":"ADJ"} ,
		{"word":"résiduelles","word_nosc":"residuelles","lemma":"résiduel","pos":"ADJ"} ,
		{"word":"résiduels","word_nosc":"residuels","lemma":"résiduel","pos":"ADJ"} ,
		{"word":"résigné","word_nosc":"resigne","lemma":"résigné","pos":"ADJ"} ,
		{"word":"résignée","word_nosc":"resignee","lemma":"résigné","pos":"ADJ"} ,
		{"word":"résignées","word_nosc":"resignees","lemma":"résigné","pos":"ADJ"} ,
		{"word":"résignés","word_nosc":"resignes","lemma":"résigné","pos":"ADJ"} ,
		{"word":"résiliable","word_nosc":"resiliable","lemma":"résiliable","pos":"ADJ"} ,
		{"word":"résineuse","word_nosc":"resineuse","lemma":"résineux","pos":"ADJ"} ,
		{"word":"résineuses","word_nosc":"resineuses","lemma":"résineux","pos":"ADJ"} ,
		{"word":"résineux","word_nosc":"resineux","lemma":"résineux","pos":"ADJ"} ,
		{"word":"résiné","word_nosc":"resine","lemma":"résiné","pos":"ADJ"} ,
		{"word":"résistant","word_nosc":"resistant","lemma":"résistant","pos":"ADJ"} ,
		{"word":"résistante","word_nosc":"resistante","lemma":"résistant","pos":"ADJ"} ,
		{"word":"résistantes","word_nosc":"resistantes","lemma":"résistant","pos":"ADJ"} ,
		{"word":"résistants","word_nosc":"resistants","lemma":"résistant","pos":"ADJ"} ,
		{"word":"résistible","word_nosc":"resistible","lemma":"résistible","pos":"ADJ"} ,
		{"word":"résolu","word_nosc":"resolu","lemma":"résolu","pos":"ADJ"} ,
		{"word":"résoluble","word_nosc":"resoluble","lemma":"résoluble","pos":"ADJ"} ,
		{"word":"résolue","word_nosc":"resolue","lemma":"résolu","pos":"ADJ"} ,
		{"word":"résolues","word_nosc":"resolues","lemma":"résolu","pos":"ADJ"} ,
		{"word":"résolus","word_nosc":"resolus","lemma":"résolu","pos":"ADJ"} ,
		{"word":"résonnant","word_nosc":"resonnant","lemma":"résonnant","pos":"ADJ"} ,
		{"word":"résonnante","word_nosc":"resonnante","lemma":"résonnant","pos":"ADJ"} ,
		{"word":"résonnants","word_nosc":"resonnants","lemma":"résonnant","pos":"ADJ"} ,
		{"word":"résorbable","word_nosc":"resorbable","lemma":"résorbable","pos":"ADJ"} ,
		{"word":"résultant","word_nosc":"resultant","lemma":"résultant","pos":"ADJ"} ,
		{"word":"résultante","word_nosc":"resultante","lemma":"résultant","pos":"ADJ"} ,
		{"word":"résurgente","word_nosc":"resurgente","lemma":"résurgent","pos":"ADJ"} ,
		{"word":"réséda","word_nosc":"reseda","lemma":"réséda","pos":"ADJ"} ,
		{"word":"réticent","word_nosc":"reticent","lemma":"réticent","pos":"ADJ"} ,
		{"word":"réticente","word_nosc":"reticente","lemma":"réticent","pos":"ADJ"} ,
		{"word":"réticentes","word_nosc":"reticentes","lemma":"réticent","pos":"ADJ"} ,
		{"word":"réticents","word_nosc":"reticents","lemma":"réticent","pos":"ADJ"} ,
		{"word":"réticulaire","word_nosc":"reticulaire","lemma":"réticulaire","pos":"ADJ"} ,
		{"word":"réticulaires","word_nosc":"reticulaires","lemma":"réticulaire","pos":"ADJ"} ,
		{"word":"réticulé","word_nosc":"reticule","lemma":"réticulé","pos":"ADJ"} ,
		{"word":"réticulée","word_nosc":"reticulee","lemma":"réticulé","pos":"ADJ"} ,
		{"word":"rétif","word_nosc":"retif","lemma":"rétif","pos":"ADJ"} ,
		{"word":"rétifs","word_nosc":"retifs","lemma":"rétif","pos":"ADJ"} ,
		{"word":"rétinien","word_nosc":"retinien","lemma":"rétinien","pos":"ADJ"} ,
		{"word":"rétinienne","word_nosc":"retinienne","lemma":"rétinien","pos":"ADJ"} ,
		{"word":"rétiniennes","word_nosc":"retiniennes","lemma":"rétinien","pos":"ADJ"} ,
		{"word":"rétive","word_nosc":"retive","lemma":"rétif","pos":"ADJ"} ,
		{"word":"rétives","word_nosc":"retives","lemma":"rétif","pos":"ADJ"} ,
		{"word":"rétractable","word_nosc":"retractable","lemma":"rétractable","pos":"ADJ"} ,
		{"word":"rétractile","word_nosc":"retractile","lemma":"rétractile","pos":"ADJ"} ,
		{"word":"rétractiles","word_nosc":"retractiles","lemma":"rétractile","pos":"ADJ"} ,
		{"word":"rétribué","word_nosc":"retribue","lemma":"rétribué","pos":"ADJ"} ,
		{"word":"rétribuée","word_nosc":"retribuee","lemma":"rétribué","pos":"ADJ"} ,
		{"word":"rétro","word_nosc":"retro","lemma":"rétro","pos":"ADJ"} ,
		{"word":"rétroactif","word_nosc":"retroactif","lemma":"rétroactif","pos":"ADJ"} ,
		{"word":"rétroactive","word_nosc":"retroactive","lemma":"rétroactif","pos":"ADJ"} ,
		{"word":"rétrograde","word_nosc":"retrograde","lemma":"rétrograde","pos":"ADJ"} ,
		{"word":"rétrogrades","word_nosc":"retrogrades","lemma":"rétrograde","pos":"ADJ"} ,
		{"word":"rétrospectif","word_nosc":"retrospectif","lemma":"rétrospectif","pos":"ADJ"} ,
		{"word":"rétrospectifs","word_nosc":"retrospectifs","lemma":"rétrospectif","pos":"ADJ"} ,
		{"word":"rétrospective","word_nosc":"retrospective","lemma":"rétrospectif","pos":"ADJ"} ,
		{"word":"rétrospectives","word_nosc":"retrospectives","lemma":"rétrospectif","pos":"ADJ"} ,
		{"word":"rétrovirale","word_nosc":"retrovirale","lemma":"rétroviral","pos":"ADJ"} ,
		{"word":"rétréci","word_nosc":"retreci","lemma":"rétréci","pos":"ADJ"} ,
		{"word":"rétrécie","word_nosc":"retrecie","lemma":"rétréci","pos":"ADJ"} ,
		{"word":"rétrécies","word_nosc":"retrecies","lemma":"rétréci","pos":"ADJ"} ,
		{"word":"rétrécis","word_nosc":"retrecis","lemma":"rétréci","pos":"ADJ"} ,
		{"word":"réussi","word_nosc":"reussi","lemma":"réussi","pos":"ADJ"} ,
		{"word":"réussie","word_nosc":"reussie","lemma":"réussi","pos":"ADJ"} ,
		{"word":"réussies","word_nosc":"reussies","lemma":"réussi","pos":"ADJ"} ,
		{"word":"réussis","word_nosc":"reussis","lemma":"réussi","pos":"ADJ"} ,
		{"word":"réutilisables","word_nosc":"reutilisables","lemma":"réutilisable","pos":"ADJ"} ,
		{"word":"réverbérant","word_nosc":"reverberant","lemma":"réverbérant","pos":"ADJ"} ,
		{"word":"réversible","word_nosc":"reversible","lemma":"réversible","pos":"ADJ"} ,
		{"word":"réversibles","word_nosc":"reversibles","lemma":"réversible","pos":"ADJ"} ,
		{"word":"révisionniste","word_nosc":"revisionniste","lemma":"révisionniste","pos":"ADJ"} ,
		{"word":"révisionnistes","word_nosc":"revisionnistes","lemma":"révisionniste","pos":"ADJ"} ,
		{"word":"révocable","word_nosc":"revocable","lemma":"révocable","pos":"ADJ"} ,
		{"word":"révocables","word_nosc":"revocables","lemma":"révocable","pos":"ADJ"} ,
		{"word":"révoltant","word_nosc":"revoltant","lemma":"révoltant","pos":"ADJ"} ,
		{"word":"révoltante","word_nosc":"revoltante","lemma":"révoltant","pos":"ADJ"} ,
		{"word":"révoltantes","word_nosc":"revoltantes","lemma":"révoltant","pos":"ADJ"} ,
		{"word":"révoltants","word_nosc":"revoltants","lemma":"révoltant","pos":"ADJ"} ,
		{"word":"révolté","word_nosc":"revolte","lemma":"révolté","pos":"ADJ"} ,
		{"word":"révoltée","word_nosc":"revoltee","lemma":"révolté","pos":"ADJ"} ,
		{"word":"révoltées","word_nosc":"revoltees","lemma":"révolté","pos":"ADJ"} ,
		{"word":"révoltés","word_nosc":"revoltes","lemma":"révolté","pos":"ADJ"} ,
		{"word":"révolu","word_nosc":"revolu","lemma":"révolu","pos":"ADJ"} ,
		{"word":"révolue","word_nosc":"revolue","lemma":"révolu","pos":"ADJ"} ,
		{"word":"révolues","word_nosc":"revolues","lemma":"révolu","pos":"ADJ"} ,
		{"word":"révolus","word_nosc":"revolus","lemma":"révolu","pos":"ADJ"} ,
		{"word":"révolutionnaire","word_nosc":"revolutionnaire","lemma":"révolutionnaire","pos":"ADJ"} ,
		{"word":"révolutionnaires","word_nosc":"revolutionnaires","lemma":"révolutionnaire","pos":"ADJ"} ,
		{"word":"révulsif","word_nosc":"revulsif","lemma":"révulsif","pos":"ADJ"} ,
		{"word":"révulsifs","word_nosc":"revulsifs","lemma":"révulsif","pos":"ADJ"} ,
		{"word":"révulsive","word_nosc":"revulsive","lemma":"révulsif","pos":"ADJ"} ,
		{"word":"révulsé","word_nosc":"revulse","lemma":"révulsé","pos":"ADJ"} ,
		{"word":"révulsée","word_nosc":"revulsee","lemma":"révulsé","pos":"ADJ"} ,
		{"word":"révulsés","word_nosc":"revulses","lemma":"révulsé","pos":"ADJ"} ,
		{"word":"révélateur","word_nosc":"revelateur","lemma":"révélateur","pos":"ADJ"} ,
		{"word":"révélateurs","word_nosc":"revelateurs","lemma":"révélateur","pos":"ADJ"} ,
		{"word":"révélatrice","word_nosc":"revelatrice","lemma":"révélateur","pos":"ADJ"} ,
		{"word":"révélatrices","word_nosc":"revelatrices","lemma":"révélateur","pos":"ADJ"} ,
		{"word":"révélé","word_nosc":"revele","lemma":"révélé","pos":"ADJ"} ,
		{"word":"révélée","word_nosc":"revelee","lemma":"révélé","pos":"ADJ"} ,
		{"word":"révélées","word_nosc":"revelees","lemma":"révélé","pos":"ADJ"} ,
		{"word":"révélés","word_nosc":"reveles","lemma":"révélé","pos":"ADJ"} ,
		{"word":"révérenciel","word_nosc":"reverenciel","lemma":"révérenciel","pos":"ADJ"} ,
		{"word":"révérencieuse","word_nosc":"reverencieuse","lemma":"révérencieux","pos":"ADJ"} ,
		{"word":"révérencieux","word_nosc":"reverencieux","lemma":"révérencieux","pos":"ADJ"} ,
		{"word":"révérend","word_nosc":"reverend","lemma":"révérend","pos":"ADJ"} ,
		{"word":"révérende","word_nosc":"reverende","lemma":"révérend","pos":"ADJ"} ,
		{"word":"révérendissime","word_nosc":"reverendissime","lemma":"révérendissime","pos":"ADJ"} ,
		{"word":"révérends","word_nosc":"reverends","lemma":"révérend","pos":"ADJ"} ,
		{"word":"rééligible","word_nosc":"reeligible","lemma":"rééligible","pos":"ADJ"} ,
		{"word":"réélu","word_nosc":"reelu","lemma":"réélu","pos":"ADJ"} ,
		{"word":"rêche","word_nosc":"reche","lemma":"rêche","pos":"ADJ"} ,
		{"word":"rêches","word_nosc":"reches","lemma":"rêche","pos":"ADJ"} ,
		{"word":"rêvassant","word_nosc":"revassant","lemma":"rêvassant","pos":"ADJ"} ,
		{"word":"rêveur","word_nosc":"reveur","lemma":"rêveur","pos":"ADJ"} ,
		{"word":"rêveurs","word_nosc":"reveurs","lemma":"rêveur","pos":"ADJ"} ,
		{"word":"rêveuse","word_nosc":"reveuse","lemma":"rêveur","pos":"ADJ"} ,
		{"word":"rêveuses","word_nosc":"reveuses","lemma":"rêveur","pos":"ADJ"} ,
		{"word":"rêvé","word_nosc":"reve","lemma":"rêvé","pos":"ADJ"} ,
		{"word":"rêvée","word_nosc":"revee","lemma":"rêvé","pos":"ADJ"} ,
		{"word":"rêvées","word_nosc":"revees","lemma":"rêvé","pos":"ADJ"} ,
		{"word":"rêvés","word_nosc":"reves","lemma":"rêvé","pos":"ADJ"} ,
		{"word":"rôti","word_nosc":"roti","lemma":"rôti","pos":"ADJ"} ,
		{"word":"rôtie","word_nosc":"rotie","lemma":"rôti","pos":"ADJ"} ,
		{"word":"rôties","word_nosc":"roties","lemma":"rôti","pos":"ADJ"} ,
		{"word":"rôtis","word_nosc":"rotis","lemma":"rôti","pos":"ADJ"} ,
		{"word":"sa","word_nosc":"sa","lemma":"sa","pos":"ADJ:pos"} ,
		{"word":"sabbatique","word_nosc":"sabbatique","lemma":"sabbatique","pos":"ADJ"} ,
		{"word":"sabbatiques","word_nosc":"sabbatiques","lemma":"sabbatique","pos":"ADJ"} ,
		{"word":"sabin","word_nosc":"sabin","lemma":"sabin","pos":"ADJ"} ,
		{"word":"sabins","word_nosc":"sabins","lemma":"sabin","pos":"ADJ"} ,
		{"word":"sable","word_nosc":"sable","lemma":"sable","pos":"ADJ"} ,
		{"word":"sableuse","word_nosc":"sableuse","lemma":"sableux","pos":"ADJ"} ,
		{"word":"sableuses","word_nosc":"sableuses","lemma":"sableux","pos":"ADJ"} ,
		{"word":"sableux","word_nosc":"sableux","lemma":"sableux","pos":"ADJ"} ,
		{"word":"sablonneuse","word_nosc":"sablonneuse","lemma":"sablonneux","pos":"ADJ"} ,
		{"word":"sablonneuses","word_nosc":"sablonneuses","lemma":"sablonneux","pos":"ADJ"} ,
		{"word":"sablonneux","word_nosc":"sablonneux","lemma":"sablonneux","pos":"ADJ"} ,
		{"word":"sablé","word_nosc":"sable","lemma":"sablé","pos":"ADJ"} ,
		{"word":"sablée","word_nosc":"sablee","lemma":"sablé","pos":"ADJ"} ,
		{"word":"sablées","word_nosc":"sablees","lemma":"sablé","pos":"ADJ"} ,
		{"word":"sablés","word_nosc":"sables","lemma":"sablé","pos":"ADJ"} ,
		{"word":"sabéen","word_nosc":"sabeen","lemma":"sabéen","pos":"ADJ"} ,
		{"word":"sabéenne","word_nosc":"sabeenne","lemma":"sabéenne","pos":"ADJ"} ,
		{"word":"saccadé","word_nosc":"saccade","lemma":"saccadé","pos":"ADJ"} ,
		{"word":"saccadée","word_nosc":"saccadee","lemma":"saccadé","pos":"ADJ"} ,
		{"word":"saccadées","word_nosc":"saccadees","lemma":"saccadé","pos":"ADJ"} ,
		{"word":"saccadés","word_nosc":"saccades","lemma":"saccadé","pos":"ADJ"} ,
		{"word":"sacerdotal","word_nosc":"sacerdotal","lemma":"sacerdotal","pos":"ADJ"} ,
		{"word":"sacerdotale","word_nosc":"sacerdotale","lemma":"sacerdotal","pos":"ADJ"} ,
		{"word":"sacerdotales","word_nosc":"sacerdotales","lemma":"sacerdotal","pos":"ADJ"} ,
		{"word":"sacerdotaux","word_nosc":"sacerdotaux","lemma":"sacerdotal","pos":"ADJ"} ,
		{"word":"sacramentel","word_nosc":"sacramentel","lemma":"sacramentel","pos":"ADJ"} ,
		{"word":"sacramentelle","word_nosc":"sacramentelle","lemma":"sacramentel","pos":"ADJ"} ,
		{"word":"sacramentelles","word_nosc":"sacramentelles","lemma":"sacramentel","pos":"ADJ"} ,
		{"word":"sacrificiel","word_nosc":"sacrificiel","lemma":"sacrificiel","pos":"ADJ"} ,
		{"word":"sacrificielle","word_nosc":"sacrificielle","lemma":"sacrificiel","pos":"ADJ"} ,
		{"word":"sacrificiels","word_nosc":"sacrificiels","lemma":"sacrificiel","pos":"ADJ"} ,
		{"word":"sacrifié","word_nosc":"sacrifie","lemma":"sacrifié","pos":"ADJ"} ,
		{"word":"sacrifiée","word_nosc":"sacrifiee","lemma":"sacrifié","pos":"ADJ"} ,
		{"word":"sacrifiées","word_nosc":"sacrifiees","lemma":"sacrifié","pos":"ADJ"} ,
		{"word":"sacrifiés","word_nosc":"sacrifies","lemma":"sacrifié","pos":"ADJ"} ,
		{"word":"sacrilège","word_nosc":"sacrilege","lemma":"sacrilège","pos":"ADJ"} ,
		{"word":"sacrilèges","word_nosc":"sacrileges","lemma":"sacrilège","pos":"ADJ"} ,
		{"word":"sacro-iliaque","word_nosc":"sacro-iliaque","lemma":"sacro-iliaque","pos":"ADJ"} ,
		{"word":"sacro-iliaques","word_nosc":"sacro-iliaques","lemma":"sacro-iliaque","pos":"ADJ"} ,
		{"word":"sacro-saint","word_nosc":"sacro-saint","lemma":"sacro-saint","pos":"ADJ"} ,
		{"word":"sacro-sainte","word_nosc":"sacro-sainte","lemma":"sacro-saint","pos":"ADJ"} ,
		{"word":"sacro-saintes","word_nosc":"sacro-saintes","lemma":"sacro-saint","pos":"ADJ"} ,
		{"word":"sacro-saints","word_nosc":"sacro-saints","lemma":"sacro-saint","pos":"ADJ"} ,
		{"word":"sacré","word_nosc":"sacre","lemma":"sacré","pos":"ADJ"} ,
		{"word":"sacrée","word_nosc":"sacree","lemma":"sacré","pos":"ADJ"} ,
		{"word":"sacrées","word_nosc":"sacrees","lemma":"sacré","pos":"ADJ"} ,
		{"word":"sacrés","word_nosc":"sacres","lemma":"sacré","pos":"ADJ"} ,
		{"word":"sadienne","word_nosc":"sadienne","lemma":"sadien","pos":"ADJ"} ,
		{"word":"sadique","word_nosc":"sadique","lemma":"sadique","pos":"ADJ"} ,
		{"word":"sadiques","word_nosc":"sadiques","lemma":"sadique","pos":"ADJ"} ,
		{"word":"sado","word_nosc":"sado","lemma":"sado","pos":"ADJ"} ,
		{"word":"sadomasochiste","word_nosc":"sadomasochiste","lemma":"sadomasochiste","pos":"ADJ"} ,
		{"word":"safran","word_nosc":"safran","lemma":"safran","pos":"ADJ"} ,
		{"word":"safrané","word_nosc":"safrane","lemma":"safrané","pos":"ADJ"} ,
		{"word":"sagace","word_nosc":"sagace","lemma":"sagace","pos":"ADJ"} ,
		{"word":"sagaces","word_nosc":"sagaces","lemma":"sagace","pos":"ADJ"} ,
		{"word":"sage","word_nosc":"sage","lemma":"sage","pos":"ADJ"} ,
		{"word":"sages","word_nosc":"sages","lemma":"sage","pos":"ADJ"} ,
		{"word":"sagittal","word_nosc":"sagittal","lemma":"sagittal","pos":"ADJ"} ,
		{"word":"sagittale","word_nosc":"sagittale","lemma":"sagittal","pos":"ADJ"} ,
		{"word":"saharien","word_nosc":"saharien","lemma":"saharien","pos":"ADJ"} ,
		{"word":"saharienne","word_nosc":"saharienne","lemma":"saharien","pos":"ADJ"} ,
		{"word":"sahariennes","word_nosc":"sahariennes","lemma":"saharien","pos":"ADJ"} ,
		{"word":"sahariens","word_nosc":"sahariens","lemma":"saharien","pos":"ADJ"} ,
		{"word":"saignant","word_nosc":"saignant","lemma":"saignant","pos":"ADJ"} ,
		{"word":"saignante","word_nosc":"saignante","lemma":"saignant","pos":"ADJ"} ,
		{"word":"saignantes","word_nosc":"saignantes","lemma":"saignant","pos":"ADJ"} ,
		{"word":"saignants","word_nosc":"saignants","lemma":"saignant","pos":"ADJ"} ,
		{"word":"saillant","word_nosc":"saillant","lemma":"saillant","pos":"ADJ"} ,
		{"word":"saillante","word_nosc":"saillante","lemma":"saillant","pos":"ADJ"} ,
		{"word":"saillantes","word_nosc":"saillantes","lemma":"saillant","pos":"ADJ"} ,
		{"word":"saillants","word_nosc":"saillants","lemma":"saillant","pos":"ADJ"} ,
		{"word":"sain","word_nosc":"sain","lemma":"sain","pos":"ADJ"} ,
		{"word":"saine","word_nosc":"saine","lemma":"sain","pos":"ADJ"} ,
		{"word":"saines","word_nosc":"saines","lemma":"sain","pos":"ADJ"} ,
		{"word":"sains","word_nosc":"sains","lemma":"sain","pos":"ADJ"} ,
		{"word":"saint","word_nosc":"saint","lemma":"saint","pos":"ADJ"} ,
		{"word":"sainte","word_nosc":"sainte","lemma":"saint","pos":"ADJ"} ,
		{"word":"saintes","word_nosc":"saintes","lemma":"saint","pos":"ADJ"} ,
		{"word":"saints","word_nosc":"saints","lemma":"saint","pos":"ADJ"} ,
		{"word":"saisi","word_nosc":"saisi","lemma":"saisi","pos":"ADJ"} ,
		{"word":"saisie","word_nosc":"saisie","lemma":"saisi","pos":"ADJ"} ,
		{"word":"saisies","word_nosc":"saisies","lemma":"saisi","pos":"ADJ"} ,
		{"word":"saisis","word_nosc":"saisis","lemma":"saisi","pos":"ADJ"} ,
		{"word":"saisissable","word_nosc":"saisissable","lemma":"saisissable","pos":"ADJ"} ,
		{"word":"saisissables","word_nosc":"saisissables","lemma":"saisissable","pos":"ADJ"} ,
		{"word":"saisissant","word_nosc":"saisissant","lemma":"saisissant","pos":"ADJ"} ,
		{"word":"saisissante","word_nosc":"saisissante","lemma":"saisissant","pos":"ADJ"} ,
		{"word":"saisissantes","word_nosc":"saisissantes","lemma":"saisissant","pos":"ADJ"} ,
		{"word":"saisissants","word_nosc":"saisissants","lemma":"saisissant","pos":"ADJ"} ,
		{"word":"saisonnier","word_nosc":"saisonnier","lemma":"saisonnier","pos":"ADJ"} ,
		{"word":"saisonniers","word_nosc":"saisonniers","lemma":"saisonnier","pos":"ADJ"} ,
		{"word":"saisonnière","word_nosc":"saisonniere","lemma":"saisonnier","pos":"ADJ"} ,
		{"word":"saisonnières","word_nosc":"saisonnieres","lemma":"saisonnier","pos":"ADJ"} ,
		{"word":"salace","word_nosc":"salace","lemma":"salace","pos":"ADJ"} ,
		{"word":"salaces","word_nosc":"salaces","lemma":"salace","pos":"ADJ"} ,
		{"word":"salant","word_nosc":"salant","lemma":"salant","pos":"ADJ"} ,
		{"word":"salants","word_nosc":"salants","lemma":"salant","pos":"ADJ"} ,
		{"word":"salariale","word_nosc":"salariale","lemma":"salarial","pos":"ADJ"} ,
		{"word":"salariaux","word_nosc":"salariaux","lemma":"salarial","pos":"ADJ"} ,
		{"word":"salarié","word_nosc":"salarie","lemma":"salarié","pos":"ADJ"} ,
		{"word":"salariée","word_nosc":"salariee","lemma":"salarié","pos":"ADJ"} ,
		{"word":"salariés","word_nosc":"salaries","lemma":"salarié","pos":"ADJ"} ,
		{"word":"salaud","word_nosc":"salaud","lemma":"salaud","pos":"ADJ"} ,
		{"word":"salauds","word_nosc":"salauds","lemma":"salaud","pos":"ADJ"} ,
		{"word":"salazariste","word_nosc":"salazariste","lemma":"salazariste","pos":"ADJ"} ,
		{"word":"sale","word_nosc":"sale","lemma":"sale","pos":"ADJ"} ,
		{"word":"sales","word_nosc":"sales","lemma":"sale","pos":"ADJ"} ,
		{"word":"salicole","word_nosc":"salicole","lemma":"salicole","pos":"ADJ"} ,
		{"word":"saligaud","word_nosc":"saligaud","lemma":"saligaud","pos":"ADJ"} ,
		{"word":"saligauds","word_nosc":"saligauds","lemma":"saligaud","pos":"ADJ"} ,
		{"word":"salin","word_nosc":"salin","lemma":"salin","pos":"ADJ"} ,
		{"word":"saline","word_nosc":"saline","lemma":"salin","pos":"ADJ"} ,
		{"word":"salines","word_nosc":"salines","lemma":"salin","pos":"ADJ"} ,
		{"word":"salingue","word_nosc":"salingue","lemma":"salingue","pos":"ADJ"} ,
		{"word":"salingues","word_nosc":"salingues","lemma":"salingue","pos":"ADJ"} ,
		{"word":"salins","word_nosc":"salins","lemma":"salin","pos":"ADJ"} ,
		{"word":"salique","word_nosc":"salique","lemma":"salique","pos":"ADJ"} ,
		{"word":"salissant","word_nosc":"salissant","lemma":"salissant","pos":"ADJ"} ,
		{"word":"salissante","word_nosc":"salissante","lemma":"salissant","pos":"ADJ"} ,
		{"word":"salissantes","word_nosc":"salissantes","lemma":"salissant","pos":"ADJ"} ,
		{"word":"salissants","word_nosc":"salissants","lemma":"salissant","pos":"ADJ"} ,
		{"word":"salisseur","word_nosc":"salisseur","lemma":"salisseur","pos":"ADJ"} ,
		{"word":"salivaire","word_nosc":"salivaire","lemma":"salivaire","pos":"ADJ"} ,
		{"word":"salivaires","word_nosc":"salivaires","lemma":"salivaire","pos":"ADJ"} ,
		{"word":"salivant","word_nosc":"salivant","lemma":"salivant","pos":"ADJ"} ,
		{"word":"saliveuse","word_nosc":"saliveuse","lemma":"saliveux","pos":"ADJ"} ,
		{"word":"saliveuses","word_nosc":"saliveuses","lemma":"saliveux","pos":"ADJ"} ,
		{"word":"salpêtreux","word_nosc":"salpetreux","lemma":"salpêtreux","pos":"ADJ"} ,
		{"word":"salubre","word_nosc":"salubre","lemma":"salubre","pos":"ADJ"} ,
		{"word":"salubres","word_nosc":"salubres","lemma":"salubre","pos":"ADJ"} ,
		{"word":"salutaire","word_nosc":"salutaire","lemma":"salutaire","pos":"ADJ"} ,
		{"word":"salutaires","word_nosc":"salutaires","lemma":"salutaire","pos":"ADJ"} ,
		{"word":"salutiste","word_nosc":"salutiste","lemma":"salutiste","pos":"ADJ"} ,
		{"word":"salvadorien","word_nosc":"salvadorien","lemma":"salvadorien","pos":"ADJ"} ,
		{"word":"salvadorienne","word_nosc":"salvadorienne","lemma":"salvadorien","pos":"ADJ"} ,
		{"word":"salvadoriens","word_nosc":"salvadoriens","lemma":"salvadorien","pos":"ADJ"} ,
		{"word":"salvateur","word_nosc":"salvateur","lemma":"salvateur","pos":"ADJ"} ,
		{"word":"salvateurs","word_nosc":"salvateurs","lemma":"salvateur","pos":"ADJ"} ,
		{"word":"salvatrice","word_nosc":"salvatrice","lemma":"salvateur","pos":"ADJ"} ,
		{"word":"salvifique","word_nosc":"salvifique","lemma":"salvifique","pos":"ADJ"} ,
		{"word":"salzbourgeoises","word_nosc":"salzbourgeoises","lemma":"salzbourgeois","pos":"ADJ"} ,
		{"word":"salé","word_nosc":"sale","lemma":"salé","pos":"ADJ"} ,
		{"word":"salée","word_nosc":"salee","lemma":"salé","pos":"ADJ"} ,
		{"word":"salées","word_nosc":"salees","lemma":"salé","pos":"ADJ"} ,
		{"word":"salés","word_nosc":"sales","lemma":"salé","pos":"ADJ"} ,
		{"word":"salésienne","word_nosc":"salesienne","lemma":"salésien","pos":"ADJ"} ,
		{"word":"samaritain","word_nosc":"samaritain","lemma":"samaritain","pos":"ADJ"} ,
		{"word":"samaritaine","word_nosc":"samaritaine","lemma":"samaritaine","pos":"ADJ"} ,
		{"word":"samaritains","word_nosc":"samaritains","lemma":"samaritain","pos":"ADJ"} ,
		{"word":"samoan","word_nosc":"samoan","lemma":"samoan","pos":"ADJ"} ,
		{"word":"samoans","word_nosc":"samoans","lemma":"samoan","pos":"ADJ"} ,
		{"word":"sanctifiante","word_nosc":"sanctifiante","lemma":"sanctifiant","pos":"ADJ"} ,
		{"word":"sanctificatrice","word_nosc":"sanctificatrice","lemma":"sanctificateur","pos":"ADJ"} ,
		{"word":"sandiniste","word_nosc":"sandiniste","lemma":"sandiniste","pos":"ADJ"} ,
		{"word":"sanglant","word_nosc":"sanglant","lemma":"sanglant","pos":"ADJ"} ,
		{"word":"sanglante","word_nosc":"sanglante","lemma":"sanglant","pos":"ADJ"} ,
		{"word":"sanglantes","word_nosc":"sanglantes","lemma":"sanglant","pos":"ADJ"} ,
		{"word":"sanglants","word_nosc":"sanglants","lemma":"sanglant","pos":"ADJ"} ,
		{"word":"sanglotant","word_nosc":"sanglotant","lemma":"sanglotant","pos":"ADJ"} ,
		{"word":"sanglotante","word_nosc":"sanglotante","lemma":"sanglotant","pos":"ADJ"} ,
		{"word":"sanglotants","word_nosc":"sanglotants","lemma":"sanglotant","pos":"ADJ"} ,
		{"word":"sanguin","word_nosc":"sanguin","lemma":"sanguin","pos":"ADJ"} ,
		{"word":"sanguinaire","word_nosc":"sanguinaire","lemma":"sanguinaire","pos":"ADJ"} ,
		{"word":"sanguinaires","word_nosc":"sanguinaires","lemma":"sanguinaire","pos":"ADJ"} ,
		{"word":"sanguine","word_nosc":"sanguine","lemma":"sanguin","pos":"ADJ"} ,
		{"word":"sanguines","word_nosc":"sanguines","lemma":"sanguin","pos":"ADJ"} ,
		{"word":"sanguinolent","word_nosc":"sanguinolent","lemma":"sanguinolent","pos":"ADJ"} ,
		{"word":"sanguinolente","word_nosc":"sanguinolente","lemma":"sanguinolent","pos":"ADJ"} ,
		{"word":"sanguinolentes","word_nosc":"sanguinolentes","lemma":"sanguinolent","pos":"ADJ"} ,
		{"word":"sanguinolents","word_nosc":"sanguinolents","lemma":"sanguinolent","pos":"ADJ"} ,
		{"word":"sanguins","word_nosc":"sanguins","lemma":"sanguin","pos":"ADJ"} ,
		{"word":"sanieuses","word_nosc":"sanieuses","lemma":"sanieux","pos":"ADJ"} ,
		{"word":"sanieux","word_nosc":"sanieux","lemma":"sanieux","pos":"ADJ"} ,
		{"word":"sanitaire","word_nosc":"sanitaire","lemma":"sanitaire","pos":"ADJ"} ,
		{"word":"sanitaires","word_nosc":"sanitaires","lemma":"sanitaire","pos":"ADJ"} ,
		{"word":"sans-coeur","word_nosc":"sans-coeur","lemma":"sans-coeur","pos":"ADJ"} ,
		{"word":"sanscrit","word_nosc":"sanscrit","lemma":"sanscrit","pos":"ADJ"} ,
		{"word":"sanscrite","word_nosc":"sanscrite","lemma":"sanscrit","pos":"ADJ"} ,
		{"word":"sanscrits","word_nosc":"sanscrits","lemma":"sanscrit","pos":"ADJ"} ,
		{"word":"sanskrit","word_nosc":"sanskrit","lemma":"sanskrit","pos":"ADJ"} ,
		{"word":"saoudien","word_nosc":"saoudien","lemma":"saoudien","pos":"ADJ"} ,
		{"word":"saoudienne","word_nosc":"saoudienne","lemma":"saoudien","pos":"ADJ"} ,
		{"word":"saoudiennes","word_nosc":"saoudiennes","lemma":"saoudien","pos":"ADJ"} ,
		{"word":"saoudiens","word_nosc":"saoudiens","lemma":"saoudien","pos":"ADJ"} ,
		{"word":"saoudite","word_nosc":"saoudite","lemma":"saoudite","pos":"ADJ"} ,
		{"word":"saoul","word_nosc":"saoul","lemma":"saoul","pos":"ADJ"} ,
		{"word":"saoule","word_nosc":"saoule","lemma":"saoul","pos":"ADJ"} ,
		{"word":"saoules","word_nosc":"saoules","lemma":"saoul","pos":"ADJ"} ,
		{"word":"saouls","word_nosc":"saouls","lemma":"saoul","pos":"ADJ"} ,
		{"word":"saphir","word_nosc":"saphir","lemma":"saphir","pos":"ADJ"} ,
		{"word":"saphène","word_nosc":"saphene","lemma":"saphène","pos":"ADJ"} ,
		{"word":"sapide","word_nosc":"sapide","lemma":"sapide","pos":"ADJ"} ,
		{"word":"sapides","word_nosc":"sapides","lemma":"sapide","pos":"ADJ"} ,
		{"word":"sapien","word_nosc":"sapien","lemma":"sapiens","pos":"ADJ"} ,
		{"word":"sapiens","word_nosc":"sapiens","lemma":"sapiens","pos":"ADJ"} ,
		{"word":"sarcastique","word_nosc":"sarcastique","lemma":"sarcastique","pos":"ADJ"} ,
		{"word":"sarcastiques","word_nosc":"sarcastiques","lemma":"sarcastique","pos":"ADJ"} ,
		{"word":"sardanapalesques","word_nosc":"sardanapalesques","lemma":"sardanapalesque","pos":"ADJ"} ,
		{"word":"sarde","word_nosc":"sarde","lemma":"sarde","pos":"ADJ"} ,
		{"word":"sardonique","word_nosc":"sardonique","lemma":"sardonique","pos":"ADJ"} ,
		{"word":"sardoniques","word_nosc":"sardoniques","lemma":"sardonique","pos":"ADJ"} ,
		{"word":"sarin","word_nosc":"sarin","lemma":"sarin","pos":"ADJ"} ,
		{"word":"sarrasin","word_nosc":"sarrasin","lemma":"sarrasin","pos":"ADJ"} ,
		{"word":"sarrasine","word_nosc":"sarrasine","lemma":"sarrasin","pos":"ADJ"} ,
		{"word":"sarrasines","word_nosc":"sarrasines","lemma":"sarrasin","pos":"ADJ"} ,
		{"word":"sarrasins","word_nosc":"sarrasins","lemma":"sarrasin","pos":"ADJ"} ,
		{"word":"sarrois","word_nosc":"sarrois","lemma":"sarrois","pos":"ADJ"} ,
		{"word":"sarroise","word_nosc":"sarroise","lemma":"sarrois","pos":"ADJ"} ,
		{"word":"sassanide","word_nosc":"sassanide","lemma":"sassanide","pos":"ADJ"} ,
		{"word":"sassanides","word_nosc":"sassanides","lemma":"sassanide","pos":"ADJ"} ,
		{"word":"satanique","word_nosc":"satanique","lemma":"satanique","pos":"ADJ"} ,
		{"word":"sataniques","word_nosc":"sataniques","lemma":"satanique","pos":"ADJ"} ,
		{"word":"sataniste","word_nosc":"sataniste","lemma":"sataniste","pos":"ADJ"} ,
		{"word":"satané","word_nosc":"satane","lemma":"satané","pos":"ADJ"} ,
		{"word":"satanée","word_nosc":"satanee","lemma":"satané","pos":"ADJ"} ,
		{"word":"satanées","word_nosc":"satanees","lemma":"satané","pos":"ADJ"} ,
		{"word":"satanés","word_nosc":"satanes","lemma":"satané","pos":"ADJ"} ,
		{"word":"satellitaire","word_nosc":"satellitaire","lemma":"satellitaire","pos":"ADJ"} ,
		{"word":"satiné","word_nosc":"satine","lemma":"satiné","pos":"ADJ"} ,
		{"word":"satinée","word_nosc":"satinee","lemma":"satiné","pos":"ADJ"} ,
		{"word":"satinées","word_nosc":"satinees","lemma":"satiné","pos":"ADJ"} ,
		{"word":"satinés","word_nosc":"satines","lemma":"satiné","pos":"ADJ"} ,
		{"word":"satirique","word_nosc":"satirique","lemma":"satirique","pos":"ADJ"} ,
		{"word":"satiriques","word_nosc":"satiriques","lemma":"satirique","pos":"ADJ"} ,
		{"word":"satisfaisant","word_nosc":"satisfaisant","lemma":"satisfaisant","pos":"ADJ"} ,
		{"word":"satisfaisante","word_nosc":"satisfaisante","lemma":"satisfaisant","pos":"ADJ"} ,
		{"word":"satisfaisantes","word_nosc":"satisfaisantes","lemma":"satisfaisant","pos":"ADJ"} ,
		{"word":"satisfaisants","word_nosc":"satisfaisants","lemma":"satisfaisant","pos":"ADJ"} ,
		{"word":"satisfait","word_nosc":"satisfait","lemma":"satisfait","pos":"ADJ"} ,
		{"word":"satisfaite","word_nosc":"satisfaite","lemma":"satisfait","pos":"ADJ"} ,
		{"word":"satisfaites","word_nosc":"satisfaites","lemma":"satisfait","pos":"ADJ"} ,
		{"word":"satisfaits","word_nosc":"satisfaits","lemma":"satisfait","pos":"ADJ"} ,
		{"word":"saturnien","word_nosc":"saturnien","lemma":"saturnien","pos":"ADJ"} ,
		{"word":"saturniens","word_nosc":"saturniens","lemma":"saturnien","pos":"ADJ"} ,
		{"word":"saturé","word_nosc":"sature","lemma":"saturé","pos":"ADJ"} ,
		{"word":"saturée","word_nosc":"saturee","lemma":"saturé","pos":"ADJ"} ,
		{"word":"saturées","word_nosc":"saturees","lemma":"saturé","pos":"ADJ"} ,
		{"word":"saturés","word_nosc":"satures","lemma":"saturé","pos":"ADJ"} ,
		{"word":"satyrique","word_nosc":"satyrique","lemma":"satyrique","pos":"ADJ"} ,
		{"word":"saucée","word_nosc":"saucee","lemma":"saucé","pos":"ADJ"} ,
		{"word":"sauf","word_nosc":"sauf","lemma":"sauf","pos":"ADJ"} ,
		{"word":"saufs","word_nosc":"saufs","lemma":"sauf","pos":"ADJ"} ,
		{"word":"saugrenu","word_nosc":"saugrenu","lemma":"saugrenu","pos":"ADJ"} ,
		{"word":"saugrenue","word_nosc":"saugrenue","lemma":"saugrenu","pos":"ADJ"} ,
		{"word":"saugrenues","word_nosc":"saugrenues","lemma":"saugrenu","pos":"ADJ"} ,
		{"word":"saugrenus","word_nosc":"saugrenus","lemma":"saugrenu","pos":"ADJ"} ,
		{"word":"saumon","word_nosc":"saumon","lemma":"saumon","pos":"ADJ"} ,
		{"word":"saumoné","word_nosc":"saumone","lemma":"saumoné","pos":"ADJ"} ,
		{"word":"saumonée","word_nosc":"saumonee","lemma":"saumoné","pos":"ADJ"} ,
		{"word":"saumonées","word_nosc":"saumonees","lemma":"saumoné","pos":"ADJ"} ,
		{"word":"saumurées","word_nosc":"saumurees","lemma":"saumuré","pos":"ADJ"} ,
		{"word":"saumâtre","word_nosc":"saumatre","lemma":"saumâtre","pos":"ADJ"} ,
		{"word":"saumâtres","word_nosc":"saumatres","lemma":"saumâtre","pos":"ADJ"} ,
		{"word":"saur","word_nosc":"saur","lemma":"saur","pos":"ADJ"} ,
		{"word":"sauret","word_nosc":"sauret","lemma":"sauret","pos":"ADJ"} ,
		{"word":"saurets","word_nosc":"saurets","lemma":"sauret","pos":"ADJ"} ,
		{"word":"saurien","word_nosc":"saurien","lemma":"saurien","pos":"ADJ"} ,
		{"word":"saurs","word_nosc":"saurs","lemma":"saur","pos":"ADJ"} ,
		{"word":"sautant","word_nosc":"sautant","lemma":"sautant","pos":"ADJ"} ,
		{"word":"sautante","word_nosc":"sautante","lemma":"sautant","pos":"ADJ"} ,
		{"word":"sautillant","word_nosc":"sautillant","lemma":"sautillant","pos":"ADJ"} ,
		{"word":"sautillante","word_nosc":"sautillante","lemma":"sautillant","pos":"ADJ"} ,
		{"word":"sautillantes","word_nosc":"sautillantes","lemma":"sautillant","pos":"ADJ"} ,
		{"word":"sautillants","word_nosc":"sautillants","lemma":"sautillant","pos":"ADJ"} ,
		{"word":"sauté","word_nosc":"saute","lemma":"sauté","pos":"ADJ"} ,
		{"word":"sautée","word_nosc":"sautee","lemma":"sauté","pos":"ADJ"} ,
		{"word":"sautées","word_nosc":"sautees","lemma":"sauté","pos":"ADJ"} ,
		{"word":"sautés","word_nosc":"sautes","lemma":"sauté","pos":"ADJ"} ,
		{"word":"sauvage","word_nosc":"sauvage","lemma":"sauvage","pos":"ADJ"} ,
		{"word":"sauvages","word_nosc":"sauvages","lemma":"sauvage","pos":"ADJ"} ,
		{"word":"sauve","word_nosc":"sauve","lemma":"sauf","pos":"ADJ"} ,
		{"word":"sauves","word_nosc":"sauves","lemma":"sauf","pos":"ADJ"} ,
		{"word":"savant","word_nosc":"savant","lemma":"savant","pos":"ADJ"} ,
		{"word":"savante","word_nosc":"savante","lemma":"savant","pos":"ADJ"} ,
		{"word":"savantes","word_nosc":"savantes","lemma":"savant","pos":"ADJ"} ,
		{"word":"savantissimes","word_nosc":"savantissimes","lemma":"savantissime","pos":"ADJ"} ,
		{"word":"savants","word_nosc":"savants","lemma":"savant","pos":"ADJ"} ,
		{"word":"savonneuse","word_nosc":"savonneuse","lemma":"savonneux","pos":"ADJ"} ,
		{"word":"savonneuses","word_nosc":"savonneuses","lemma":"savonneux","pos":"ADJ"} ,
		{"word":"savonneux","word_nosc":"savonneux","lemma":"savonneux","pos":"ADJ"} ,
		{"word":"savonnier","word_nosc":"savonnier","lemma":"savonnier","pos":"ADJ"} ,
		{"word":"savoureuse","word_nosc":"savoureuse","lemma":"savoureux","pos":"ADJ"} ,
		{"word":"savoureuses","word_nosc":"savoureuses","lemma":"savoureux","pos":"ADJ"} ,
		{"word":"savoureux","word_nosc":"savoureux","lemma":"savoureux","pos":"ADJ"} ,
		{"word":"savoyard","word_nosc":"savoyard","lemma":"savoyard","pos":"ADJ"} ,
		{"word":"savoyarde","word_nosc":"savoyarde","lemma":"savoyard","pos":"ADJ"} ,
		{"word":"savoyardes","word_nosc":"savoyardes","lemma":"savoyard","pos":"ADJ"} ,
		{"word":"savoyards","word_nosc":"savoyards","lemma":"savoyard","pos":"ADJ"} ,
		{"word":"saxon","word_nosc":"saxon","lemma":"saxon","pos":"ADJ"} ,
		{"word":"saxonne","word_nosc":"saxonne","lemma":"saxon","pos":"ADJ"} ,
		{"word":"saxonnes","word_nosc":"saxonnes","lemma":"saxon","pos":"ADJ"} ,
		{"word":"saxons","word_nosc":"saxons","lemma":"saxon","pos":"ADJ"} ,
		{"word":"scabieuse","word_nosc":"scabieuse","lemma":"scabieux","pos":"ADJ"} ,
		{"word":"scabreuse","word_nosc":"scabreuse","lemma":"scabreux","pos":"ADJ"} ,
		{"word":"scabreuses","word_nosc":"scabreuses","lemma":"scabreux","pos":"ADJ"} ,
		{"word":"scabreux","word_nosc":"scabreux","lemma":"scabreux","pos":"ADJ"} ,
		{"word":"scalène","word_nosc":"scalene","lemma":"scalène","pos":"ADJ"} ,
		{"word":"scandaleuse","word_nosc":"scandaleuse","lemma":"scandaleux","pos":"ADJ"} ,
		{"word":"scandaleuses","word_nosc":"scandaleuses","lemma":"scandaleux","pos":"ADJ"} ,
		{"word":"scandaleux","word_nosc":"scandaleux","lemma":"scandaleux","pos":"ADJ"} ,
		{"word":"scandalisé","word_nosc":"scandalise","lemma":"scandalisé","pos":"ADJ"} ,
		{"word":"scandalisée","word_nosc":"scandalisee","lemma":"scandalisé","pos":"ADJ"} ,
		{"word":"scandalisées","word_nosc":"scandalisees","lemma":"scandalisé","pos":"ADJ"} ,
		{"word":"scandalisés","word_nosc":"scandalises","lemma":"scandalisé","pos":"ADJ"} ,
		{"word":"scandinave","word_nosc":"scandinave","lemma":"scandinave","pos":"ADJ"} ,
		{"word":"scandinaves","word_nosc":"scandinaves","lemma":"scandinave","pos":"ADJ"} ,
		{"word":"scaphoïde","word_nosc":"scaphoide","lemma":"scaphoïde","pos":"ADJ"} ,
		{"word":"scapulaire","word_nosc":"scapulaire","lemma":"scapulaire","pos":"ADJ"} ,
		{"word":"scarlatin","word_nosc":"scarlatin","lemma":"scarlatine","pos":"ADJ"} ,
		{"word":"scato","word_nosc":"scato","lemma":"scato","pos":"ADJ"} ,
		{"word":"scatologique","word_nosc":"scatologique","lemma":"scatologique","pos":"ADJ"} ,
		{"word":"scatologiques","word_nosc":"scatologiques","lemma":"scatologique","pos":"ADJ"} ,
		{"word":"scatophiles","word_nosc":"scatophiles","lemma":"scatophile","pos":"ADJ"} ,
		{"word":"scatos","word_nosc":"scatos","lemma":"scato","pos":"ADJ"} ,
		{"word":"scellé","word_nosc":"scelle","lemma":"scellé","pos":"ADJ"} ,
		{"word":"scellée","word_nosc":"scellee","lemma":"scellé","pos":"ADJ"} ,
		{"word":"scellées","word_nosc":"scellees","lemma":"scellé","pos":"ADJ"} ,
		{"word":"scellés","word_nosc":"scelles","lemma":"scellé","pos":"ADJ"} ,
		{"word":"sceptique","word_nosc":"sceptique","lemma":"sceptique","pos":"ADJ"} ,
		{"word":"sceptiques","word_nosc":"sceptiques","lemma":"sceptique","pos":"ADJ"} ,
		{"word":"schismatique","word_nosc":"schismatique","lemma":"schismatique","pos":"ADJ"} ,
		{"word":"schismatiques","word_nosc":"schismatiques","lemma":"schismatique","pos":"ADJ"} ,
		{"word":"schisteuses","word_nosc":"schisteuses","lemma":"schisteux","pos":"ADJ"} ,
		{"word":"schizophrène","word_nosc":"schizophrene","lemma":"schizophrène","pos":"ADJ"} ,
		{"word":"schizophrènes","word_nosc":"schizophrenes","lemma":"schizophrène","pos":"ADJ"} ,
		{"word":"schizophrénique","word_nosc":"schizophrenique","lemma":"schizophrénique","pos":"ADJ"} ,
		{"word":"schizophréniques","word_nosc":"schizophreniques","lemma":"schizophrénique","pos":"ADJ"} ,
		{"word":"schizoïde","word_nosc":"schizoide","lemma":"schizoïde","pos":"ADJ"} ,
		{"word":"schizoïdes","word_nosc":"schizoides","lemma":"schizoïde","pos":"ADJ"} ,
		{"word":"schlass","word_nosc":"schlass","lemma":"schlass","pos":"ADJ"} ,
		{"word":"schleu","word_nosc":"schleu","lemma":"schleu","pos":"ADJ"} ,
		{"word":"schnock","word_nosc":"schnock","lemma":"schnock","pos":"ADJ"} ,
		{"word":"schnoque","word_nosc":"schnoque","lemma":"schnoque","pos":"ADJ"} ,
		{"word":"schématique","word_nosc":"schematique","lemma":"schématique","pos":"ADJ"} ,
		{"word":"schématiques","word_nosc":"schematiques","lemma":"schématique","pos":"ADJ"} ,
		{"word":"scialytique","word_nosc":"scialytique","lemma":"scialytique","pos":"ADJ"} ,
		{"word":"sciant","word_nosc":"sciant","lemma":"sciant","pos":"ADJ"} ,
		{"word":"sciatique","word_nosc":"sciatique","lemma":"sciatique","pos":"ADJ"} ,
		{"word":"scientifique","word_nosc":"scientifique","lemma":"scientifique","pos":"ADJ"} ,
		{"word":"scientifiques","word_nosc":"scientifiques","lemma":"scientifique","pos":"ADJ"} ,
		{"word":"scientiste","word_nosc":"scientiste","lemma":"scientiste","pos":"ADJ"} ,
		{"word":"scintillant","word_nosc":"scintillant","lemma":"scintillant","pos":"ADJ"} ,
		{"word":"scintillante","word_nosc":"scintillante","lemma":"scintillant","pos":"ADJ"} ,
		{"word":"scintillantes","word_nosc":"scintillantes","lemma":"scintillant","pos":"ADJ"} ,
		{"word":"scintillants","word_nosc":"scintillants","lemma":"scintillant","pos":"ADJ"} ,
		{"word":"scissionniste","word_nosc":"scissionniste","lemma":"scissionniste","pos":"ADJ"} ,
		{"word":"scléreux","word_nosc":"sclereux","lemma":"scléreux","pos":"ADJ"} ,
		{"word":"sclérosante","word_nosc":"sclerosante","lemma":"sclérosant","pos":"ADJ"} ,
		{"word":"sclérosé","word_nosc":"sclerose","lemma":"sclérosé","pos":"ADJ"} ,
		{"word":"sclérosée","word_nosc":"sclerosee","lemma":"sclérosé","pos":"ADJ"} ,
		{"word":"sclérosés","word_nosc":"scleroses","lemma":"sclérosé","pos":"ADJ"} ,
		{"word":"scolaire","word_nosc":"scolaire","lemma":"scolaire","pos":"ADJ"} ,
		{"word":"scolaires","word_nosc":"scolaires","lemma":"scolaire","pos":"ADJ"} ,
		{"word":"scolastique","word_nosc":"scolastique","lemma":"scolastique","pos":"ADJ"} ,
		{"word":"scolastiques","word_nosc":"scolastiques","lemma":"scolastique","pos":"ADJ"} ,
		{"word":"scout","word_nosc":"scout","lemma":"scout","pos":"ADJ"} ,
		{"word":"scoute","word_nosc":"scoute","lemma":"scout","pos":"ADJ"} ,
		{"word":"scouts","word_nosc":"scouts","lemma":"scout","pos":"ADJ"} ,
		{"word":"scratch","word_nosc":"scratch","lemma":"scratch","pos":"ADJ"} ,
		{"word":"scrofuleux","word_nosc":"scrofuleux","lemma":"scrofuleux","pos":"ADJ"} ,
		{"word":"scrotal","word_nosc":"scrotal","lemma":"scrotal","pos":"ADJ"} ,
		{"word":"scrupuleuse","word_nosc":"scrupuleuse","lemma":"scrupuleux","pos":"ADJ"} ,
		{"word":"scrupuleuses","word_nosc":"scrupuleuses","lemma":"scrupuleux","pos":"ADJ"} ,
		{"word":"scrupuleux","word_nosc":"scrupuleux","lemma":"scrupuleux","pos":"ADJ"} ,
		{"word":"scrutateur","word_nosc":"scrutateur","lemma":"scrutateur","pos":"ADJ"} ,
		{"word":"scrutateurs","word_nosc":"scrutateurs","lemma":"scrutateur","pos":"ADJ"} ,
		{"word":"scrutatrices","word_nosc":"scrutatrices","lemma":"scrutateur","pos":"ADJ"} ,
		{"word":"sculptural","word_nosc":"sculptural","lemma":"sculptural","pos":"ADJ"} ,
		{"word":"sculpturale","word_nosc":"sculpturale","lemma":"sculptural","pos":"ADJ"} ,
		{"word":"sculpturales","word_nosc":"sculpturales","lemma":"sculptural","pos":"ADJ"} ,
		{"word":"sculpturaux","word_nosc":"sculpturaux","lemma":"sculptural","pos":"ADJ"} ,
		{"word":"sculpté","word_nosc":"sculpte","lemma":"sculpté","pos":"ADJ"} ,
		{"word":"sculptée","word_nosc":"sculptee","lemma":"sculpté","pos":"ADJ"} ,
		{"word":"sculptées","word_nosc":"sculptees","lemma":"sculpté","pos":"ADJ"} ,
		{"word":"sculptés","word_nosc":"sculptes","lemma":"sculpté","pos":"ADJ"} ,
		{"word":"scythe","word_nosc":"scythe","lemma":"scythe","pos":"ADJ"} ,
		{"word":"scythes","word_nosc":"scythes","lemma":"scythe","pos":"ADJ"} ,
		{"word":"scélérat","word_nosc":"scelerat","lemma":"scélérat","pos":"ADJ"} ,
		{"word":"scélérate","word_nosc":"scelerate","lemma":"scélérat","pos":"ADJ"} ,
		{"word":"scélérates","word_nosc":"scelerates","lemma":"scélérat","pos":"ADJ"} ,
		{"word":"scélérats","word_nosc":"scelerats","lemma":"scélérat","pos":"ADJ"} ,
		{"word":"scénaristique","word_nosc":"scenaristique","lemma":"scénaristique","pos":"ADJ"} ,
		{"word":"scénique","word_nosc":"scenique","lemma":"scénique","pos":"ADJ"} ,
		{"word":"scéniques","word_nosc":"sceniques","lemma":"scénique","pos":"ADJ"} ,
		{"word":"scénographique","word_nosc":"scenographique","lemma":"scénographique","pos":"ADJ"} ,
		{"word":"sec","word_nosc":"sec","lemma":"sec","pos":"ADJ"} ,
		{"word":"second","word_nosc":"second","lemma":"second","pos":"ADJ"} ,
		{"word":"secondaire","word_nosc":"secondaire","lemma":"secondaire","pos":"ADJ"} ,
		{"word":"secondaires","word_nosc":"secondaires","lemma":"secondaire","pos":"ADJ"} ,
		{"word":"seconde","word_nosc":"seconde","lemma":"second","pos":"ADJ"} ,
		{"word":"seconde","word_nosc":"seconde","lemma":"seconder","pos":"ADJ"} ,
		{"word":"secondes","word_nosc":"secondes","lemma":"second","pos":"ADJ"} ,
		{"word":"seconds","word_nosc":"seconds","lemma":"second","pos":"ADJ"} ,
		{"word":"secourable","word_nosc":"secourable","lemma":"secourable","pos":"ADJ"} ,
		{"word":"secourables","word_nosc":"secourables","lemma":"secourable","pos":"ADJ"} ,
		{"word":"secret","word_nosc":"secret","lemma":"secret","pos":"ADJ"} ,
		{"word":"secrets","word_nosc":"secrets","lemma":"secret","pos":"ADJ"} ,
		{"word":"secrète","word_nosc":"secrete","lemma":"secret","pos":"ADJ"} ,
		{"word":"secrètes","word_nosc":"secretes","lemma":"secret","pos":"ADJ"} ,
		{"word":"secs","word_nosc":"secs","lemma":"sec","pos":"ADJ"} ,
		{"word":"sectaire","word_nosc":"sectaire","lemma":"sectaire","pos":"ADJ"} ,
		{"word":"sectaires","word_nosc":"sectaires","lemma":"sectaire","pos":"ADJ"} ,
		{"word":"sectorielles","word_nosc":"sectorielles","lemma":"sectoriel","pos":"ADJ"} ,
		{"word":"sectoriels","word_nosc":"sectoriels","lemma":"sectoriel","pos":"ADJ"} ,
		{"word":"segmentaire","word_nosc":"segmentaire","lemma":"segmentaire","pos":"ADJ"} ,
		{"word":"segmentale","word_nosc":"segmentale","lemma":"segmental","pos":"ADJ"} ,
		{"word":"seigneurial","word_nosc":"seigneurial","lemma":"seigneurial","pos":"ADJ"} ,
		{"word":"seigneuriale","word_nosc":"seigneuriale","lemma":"seigneurial","pos":"ADJ"} ,
		{"word":"seigneuriales","word_nosc":"seigneuriales","lemma":"seigneurial","pos":"ADJ"} ,
		{"word":"seigneuriaux","word_nosc":"seigneuriaux","lemma":"seigneurial","pos":"ADJ"} ,
		{"word":"seize","word_nosc":"seize","lemma":"seize","pos":"ADJ:num"} ,
		{"word":"seizième","word_nosc":"seizieme","lemma":"seizième","pos":"ADJ"} ,
		{"word":"seldjoukide","word_nosc":"seldjoukide","lemma":"seldjoukide","pos":"ADJ"} ,
		{"word":"select","word_nosc":"select","lemma":"select","pos":"ADJ"} ,
		{"word":"selects","word_nosc":"selects","lemma":"select","pos":"ADJ"} ,
		{"word":"semblable","word_nosc":"semblable","lemma":"semblable","pos":"ADJ"} ,
		{"word":"semblables","word_nosc":"semblables","lemma":"semblable","pos":"ADJ"} ,
		{"word":"semestriel","word_nosc":"semestriel","lemma":"semestriel","pos":"ADJ"} ,
		{"word":"semestrielle","word_nosc":"semestrielle","lemma":"semestriel","pos":"ADJ"} ,
		{"word":"semi-arides","word_nosc":"semi-arides","lemma":"semi-aride","pos":"ADJ"} ,
		{"word":"semi-automatique","word_nosc":"semi-automatique","lemma":"semi-automatique","pos":"ADJ"} ,
		{"word":"semi-automatiques","word_nosc":"semi-automatiques","lemma":"semi-automatique","pos":"ADJ"} ,
		{"word":"semi-circulaire","word_nosc":"semi-circulaire","lemma":"semi-circulaire","pos":"ADJ"} ,
		{"word":"semi-lunaire","word_nosc":"semi-lunaire","lemma":"semi-lunaire","pos":"ADJ"} ,
		{"word":"semi-nomades","word_nosc":"semi-nomades","lemma":"semi-nomade","pos":"ADJ"} ,
		{"word":"semi-officiel","word_nosc":"semi-officiel","lemma":"semi-officiel","pos":"ADJ"} ,
		{"word":"semi-officielle","word_nosc":"semi-officielle","lemma":"semi-officiel","pos":"ADJ"} ,
		{"word":"semi-ouvert","word_nosc":"semi-ouvert","lemma":"semi-ouvert","pos":"ADJ"} ,
		{"word":"semi-perméable","word_nosc":"semi-permeable","lemma":"semi-perméable","pos":"ADJ"} ,
		{"word":"semi-phonétique","word_nosc":"semi-phonetique","lemma":"semi-phonétique","pos":"ADJ"} ,
		{"word":"semi-précieuse","word_nosc":"semi-precieuse","lemma":"semi-précieuse","pos":"ADJ"} ,
		{"word":"semi-précieuse","word_nosc":"semi-precieuse","lemma":"semi-précieux","pos":"ADJ"} ,
		{"word":"semi-précieuses","word_nosc":"semi-precieuses","lemma":"semi-précieux","pos":"ADJ"} ,
		{"word":"semi-public","word_nosc":"semi-public","lemma":"semi-public","pos":"ADJ"} ,
		{"word":"semi-rigide","word_nosc":"semi-rigide","lemma":"semi-rigide","pos":"ADJ"} ,
		{"word":"sempiternel","word_nosc":"sempiternel","lemma":"sempiternel","pos":"ADJ"} ,
		{"word":"sempiternelle","word_nosc":"sempiternelle","lemma":"sempiternel","pos":"ADJ"} ,
		{"word":"sempiternelles","word_nosc":"sempiternelles","lemma":"sempiternel","pos":"ADJ"} ,
		{"word":"sempiternels","word_nosc":"sempiternels","lemma":"sempiternel","pos":"ADJ"} ,
		{"word":"semée","word_nosc":"semee","lemma":"semé","pos":"ADJ"} ,
		{"word":"semés","word_nosc":"semes","lemma":"semé","pos":"ADJ"} ,
		{"word":"senestre","word_nosc":"senestre","lemma":"senestre","pos":"ADJ"} ,
		{"word":"senior","word_nosc":"senior","lemma":"senior","pos":"ADJ"} ,
		{"word":"seniors","word_nosc":"seniors","lemma":"senior","pos":"ADJ"} ,
		{"word":"sensas","word_nosc":"sensas","lemma":"sensas","pos":"ADJ"} ,
		{"word":"sensass","word_nosc":"sensass","lemma":"sensass","pos":"ADJ"} ,
		{"word":"sensationnel","word_nosc":"sensationnel","lemma":"sensationnel","pos":"ADJ"} ,
		{"word":"sensationnelle","word_nosc":"sensationnelle","lemma":"sensationnel","pos":"ADJ"} ,
		{"word":"sensationnelles","word_nosc":"sensationnelles","lemma":"sensationnel","pos":"ADJ"} ,
		{"word":"sensationnels","word_nosc":"sensationnels","lemma":"sensationnel","pos":"ADJ"} ,
		{"word":"sensible","word_nosc":"sensible","lemma":"sensible","pos":"ADJ"} ,
		{"word":"sensibles","word_nosc":"sensibles","lemma":"sensible","pos":"ADJ"} ,
		{"word":"sensitif","word_nosc":"sensitif","lemma":"sensitif","pos":"ADJ"} ,
		{"word":"sensitifs","word_nosc":"sensitifs","lemma":"sensitif","pos":"ADJ"} ,
		{"word":"sensitive","word_nosc":"sensitive","lemma":"sensitif","pos":"ADJ"} ,
		{"word":"sensitives","word_nosc":"sensitives","lemma":"sensitif","pos":"ADJ"} ,
		{"word":"sensoriel","word_nosc":"sensoriel","lemma":"sensoriel","pos":"ADJ"} ,
		{"word":"sensorielle","word_nosc":"sensorielle","lemma":"sensoriel","pos":"ADJ"} ,
		{"word":"sensorielles","word_nosc":"sensorielles","lemma":"sensoriel","pos":"ADJ"} ,
		{"word":"sensoriels","word_nosc":"sensoriels","lemma":"sensoriel","pos":"ADJ"} ,
		{"word":"sensuel","word_nosc":"sensuel","lemma":"sensuel","pos":"ADJ"} ,
		{"word":"sensuelle","word_nosc":"sensuelle","lemma":"sensuel","pos":"ADJ"} ,
		{"word":"sensuelles","word_nosc":"sensuelles","lemma":"sensuel","pos":"ADJ"} ,
		{"word":"sensuels","word_nosc":"sensuels","lemma":"sensuel","pos":"ADJ"} ,
		{"word":"sensé","word_nosc":"sense","lemma":"sensé","pos":"ADJ"} ,
		{"word":"sensée","word_nosc":"sensee","lemma":"sensé","pos":"ADJ"} ,
		{"word":"sensées","word_nosc":"sensees","lemma":"sensé","pos":"ADJ"} ,
		{"word":"sensés","word_nosc":"senses","lemma":"sensé","pos":"ADJ"} ,
		{"word":"sentant","word_nosc":"sentant","lemma":"sentant","pos":"ADJ"} ,
		{"word":"sentencieuse","word_nosc":"sentencieuse","lemma":"sentencieux","pos":"ADJ"} ,
		{"word":"sentencieuses","word_nosc":"sentencieuses","lemma":"sentencieux","pos":"ADJ"} ,
		{"word":"sentencieux","word_nosc":"sentencieux","lemma":"sentencieux","pos":"ADJ"} ,
		{"word":"senti","word_nosc":"senti","lemma":"senti","pos":"ADJ"} ,
		{"word":"sentie","word_nosc":"sentie","lemma":"senti","pos":"ADJ"} ,
		{"word":"senties","word_nosc":"senties","lemma":"senti","pos":"ADJ"} ,
		{"word":"sentimental","word_nosc":"sentimental","lemma":"sentimental","pos":"ADJ"} ,
		{"word":"sentimentale","word_nosc":"sentimentale","lemma":"sentimental","pos":"ADJ"} ,
		{"word":"sentimentales","word_nosc":"sentimentales","lemma":"sentimental","pos":"ADJ"} ,
		{"word":"sentimentaux","word_nosc":"sentimentaux","lemma":"sentimental","pos":"ADJ"} ,
		{"word":"sentis","word_nosc":"sentis","lemma":"senti","pos":"ADJ"} ,
		{"word":"sept","word_nosc":"sept","lemma":"sept","pos":"ADJ:num"} ,
		{"word":"septale","word_nosc":"septale","lemma":"septal","pos":"ADJ"} ,
		{"word":"septante","word_nosc":"septante","lemma":"septante","pos":"ADJ:num"} ,
		{"word":"septante-cinq","word_nosc":"septante-cinq","lemma":"septante-cinq","pos":"ADJ:num"} ,
		{"word":"septante-sept","word_nosc":"septante-sept","lemma":"septante-sept","pos":"ADJ:num"} ,
		{"word":"septaux","word_nosc":"septaux","lemma":"septal","pos":"ADJ"} ,
		{"word":"septentrional","word_nosc":"septentrional","lemma":"septentrional","pos":"ADJ"} ,
		{"word":"septentrionale","word_nosc":"septentrionale","lemma":"septentrional","pos":"ADJ"} ,
		{"word":"septentrionales","word_nosc":"septentrionales","lemma":"septentrional","pos":"ADJ"} ,
		{"word":"septicémique","word_nosc":"septicemique","lemma":"septicémique","pos":"ADJ"} ,
		{"word":"septique","word_nosc":"septique","lemma":"septique","pos":"ADJ"} ,
		{"word":"septième","word_nosc":"septieme","lemma":"septième","pos":"ADJ"} ,
		{"word":"septuagénaire","word_nosc":"septuagenaire","lemma":"septuagénaire","pos":"ADJ"} ,
		{"word":"septuagénaires","word_nosc":"septuagenaires","lemma":"septuagénaire","pos":"ADJ"} ,
		{"word":"septuple","word_nosc":"septuple","lemma":"septuple","pos":"ADJ"} ,
		{"word":"serbe","word_nosc":"serbe","lemma":"serbe","pos":"ADJ"} ,
		{"word":"serbes","word_nosc":"serbes","lemma":"serbe","pos":"ADJ"} ,
		{"word":"serbo-croate","word_nosc":"serbo-croate","lemma":"serbo-croate","pos":"ADJ"} ,
		{"word":"serein","word_nosc":"serein","lemma":"serein","pos":"ADJ"} ,
		{"word":"sereine","word_nosc":"sereine","lemma":"serein","pos":"ADJ"} ,
		{"word":"sereines","word_nosc":"sereines","lemma":"serein","pos":"ADJ"} ,
		{"word":"sereins","word_nosc":"sereins","lemma":"serein","pos":"ADJ"} ,
		{"word":"serf","word_nosc":"serf","lemma":"serf","pos":"ADJ"} ,
		{"word":"serfs","word_nosc":"serfs","lemma":"serf","pos":"ADJ"} ,
		{"word":"sermonneur","word_nosc":"sermonneur","lemma":"sermonneur","pos":"ADJ"} ,
		{"word":"serpentant","word_nosc":"serpentant","lemma":"serpentant","pos":"ADJ"} ,
		{"word":"serpentiforme","word_nosc":"serpentiforme","lemma":"serpentiforme","pos":"ADJ"} ,
		{"word":"serpentin","word_nosc":"serpentin","lemma":"serpentin","pos":"ADJ"} ,
		{"word":"serpentine","word_nosc":"serpentine","lemma":"serpentin","pos":"ADJ"} ,
		{"word":"serpentines","word_nosc":"serpentines","lemma":"serpentin","pos":"ADJ"} ,
		{"word":"serpentins","word_nosc":"serpentins","lemma":"serpentin","pos":"ADJ"} ,
		{"word":"serré","word_nosc":"serre","lemma":"serré","pos":"ADJ"} ,
		{"word":"serrée","word_nosc":"serree","lemma":"serré","pos":"ADJ"} ,
		{"word":"serrées","word_nosc":"serrees","lemma":"serré","pos":"ADJ"} ,
		{"word":"serrés","word_nosc":"serres","lemma":"serré","pos":"ADJ"} ,
		{"word":"servant","word_nosc":"servant","lemma":"servant","pos":"ADJ"} ,
		{"word":"servants","word_nosc":"servants","lemma":"servant","pos":"ADJ"} ,
		{"word":"serve","word_nosc":"serve","lemma":"serve","pos":"ADJ"} ,
		{"word":"serves","word_nosc":"serves","lemma":"serve","pos":"ADJ"} ,
		{"word":"servi","word_nosc":"servi","lemma":"servi","pos":"ADJ"} ,
		{"word":"serviable","word_nosc":"serviable","lemma":"serviable","pos":"ADJ"} ,
		{"word":"serviables","word_nosc":"serviables","lemma":"serviable","pos":"ADJ"} ,
		{"word":"servie","word_nosc":"servie","lemma":"servi","pos":"ADJ"} ,
		{"word":"servies","word_nosc":"servies","lemma":"servi","pos":"ADJ"} ,
		{"word":"servile","word_nosc":"servile","lemma":"servile","pos":"ADJ"} ,
		{"word":"serviles","word_nosc":"serviles","lemma":"servile","pos":"ADJ"} ,
		{"word":"servis","word_nosc":"servis","lemma":"servi","pos":"ADJ"} ,
		{"word":"ses","word_nosc":"ses","lemma":"ses","pos":"ADJ:pos"} ,
		{"word":"seul","word_nosc":"seul","lemma":"seul","pos":"ADJ"} ,
		{"word":"seulabre","word_nosc":"seulabre","lemma":"seulabre","pos":"ADJ"} ,
		{"word":"seulabres","word_nosc":"seulabres","lemma":"seulabre","pos":"ADJ"} ,
		{"word":"seule","word_nosc":"seule","lemma":"seul","pos":"ADJ"} ,
		{"word":"seules","word_nosc":"seules","lemma":"seul","pos":"ADJ"} ,
		{"word":"seulet","word_nosc":"seulet","lemma":"seulet","pos":"ADJ"} ,
		{"word":"seulette","word_nosc":"seulette","lemma":"seulet","pos":"ADJ"} ,
		{"word":"seulettes","word_nosc":"seulettes","lemma":"seulet","pos":"ADJ"} ,
		{"word":"seuls","word_nosc":"seuls","lemma":"seul","pos":"ADJ"} ,
		{"word":"sexagénaire","word_nosc":"sexagenaire","lemma":"sexagénaire","pos":"ADJ"} ,
		{"word":"sexagénaires","word_nosc":"sexagenaires","lemma":"sexagénaire","pos":"ADJ"} ,
		{"word":"sexiste","word_nosc":"sexiste","lemma":"sexiste","pos":"ADJ"} ,
		{"word":"sexistes","word_nosc":"sexistes","lemma":"sexiste","pos":"ADJ"} ,
		{"word":"sexologique","word_nosc":"sexologique","lemma":"sexologique","pos":"ADJ"} ,
		{"word":"sextile","word_nosc":"sextile","lemma":"sextil","pos":"ADJ"} ,
		{"word":"sextuple","word_nosc":"sextuple","lemma":"sextuple","pos":"ADJ"} ,
		{"word":"sexuel","word_nosc":"sexuel","lemma":"sexuel","pos":"ADJ"} ,
		{"word":"sexuelle","word_nosc":"sexuelle","lemma":"sexuel","pos":"ADJ"} ,
		{"word":"sexuelles","word_nosc":"sexuelles","lemma":"sexuel","pos":"ADJ"} ,
		{"word":"sexuels","word_nosc":"sexuels","lemma":"sexuel","pos":"ADJ"} ,
		{"word":"sexué","word_nosc":"sexue","lemma":"sexué","pos":"ADJ"} ,
		{"word":"sexués","word_nosc":"sexues","lemma":"sexué","pos":"ADJ"} ,
		{"word":"sexy","word_nosc":"sexy","lemma":"sexy","pos":"ADJ"} ,
		{"word":"seyant","word_nosc":"seyant","lemma":"seyant","pos":"ADJ"} ,
		{"word":"seyante","word_nosc":"seyante","lemma":"seyant","pos":"ADJ"} ,
		{"word":"seyantes","word_nosc":"seyantes","lemma":"seyant","pos":"ADJ"} ,
		{"word":"seyants","word_nosc":"seyants","lemma":"seyant","pos":"ADJ"} ,
		{"word":"shakespearien","word_nosc":"shakespearien","lemma":"shakespearien","pos":"ADJ"} ,
		{"word":"shakespearienne","word_nosc":"shakespearienne","lemma":"shakespearien","pos":"ADJ"} ,
		{"word":"shakespeariens","word_nosc":"shakespeariens","lemma":"shakespearien","pos":"ADJ"} ,
		{"word":"shanghaien","word_nosc":"shanghaien","lemma":"shanghaien","pos":"ADJ"} ,
		{"word":"shift","word_nosc":"shift","lemma":"shift","pos":"ADJ"} ,
		{"word":"shintoïste","word_nosc":"shintoiste","lemma":"shintoïste","pos":"ADJ"} ,
		{"word":"shocking","word_nosc":"shocking","lemma":"shocking","pos":"ADJ"} ,
		{"word":"shogunal","word_nosc":"shogunal","lemma":"shogunal","pos":"ADJ"} ,
		{"word":"shogunale","word_nosc":"shogunale","lemma":"shogunal","pos":"ADJ"} ,
		{"word":"siamois","word_nosc":"siamois","lemma":"siamois","pos":"ADJ"} ,
		{"word":"siamoise","word_nosc":"siamoise","lemma":"siamois","pos":"ADJ"} ,
		{"word":"siamoises","word_nosc":"siamoises","lemma":"siamois","pos":"ADJ"} ,
		{"word":"sibilant","word_nosc":"sibilant","lemma":"sibilant","pos":"ADJ"} ,
		{"word":"sibyllin","word_nosc":"sibyllin","lemma":"sibyllin","pos":"ADJ"} ,
		{"word":"sibylline","word_nosc":"sibylline","lemma":"sibyllin","pos":"ADJ"} ,
		{"word":"sibyllines","word_nosc":"sibyllines","lemma":"sibyllin","pos":"ADJ"} ,
		{"word":"sibyllins","word_nosc":"sibyllins","lemma":"sibyllin","pos":"ADJ"} ,
		{"word":"sibérien","word_nosc":"siberien","lemma":"sibérien","pos":"ADJ"} ,
		{"word":"sibérienne","word_nosc":"siberienne","lemma":"sibérien","pos":"ADJ"} ,
		{"word":"sibériennes","word_nosc":"siberiennes","lemma":"sibérien","pos":"ADJ"} ,
		{"word":"sibériens","word_nosc":"siberiens","lemma":"sibérien","pos":"ADJ"} ,
		{"word":"sicilien","word_nosc":"sicilien","lemma":"sicilien","pos":"ADJ"} ,
		{"word":"sicilienne","word_nosc":"sicilienne","lemma":"sicilien","pos":"ADJ"} ,
		{"word":"siciliennes","word_nosc":"siciliennes","lemma":"sicilien","pos":"ADJ"} ,
		{"word":"siciliens","word_nosc":"siciliens","lemma":"sicilien","pos":"ADJ"} ,
		{"word":"sidaïque","word_nosc":"sidaique","lemma":"sidaïque","pos":"ADJ"} ,
		{"word":"sidéenne","word_nosc":"sideenne","lemma":"sidéen","pos":"ADJ"} ,
		{"word":"sidéens","word_nosc":"sideens","lemma":"sidéen","pos":"ADJ"} ,
		{"word":"sidéral","word_nosc":"sideral","lemma":"sidéral","pos":"ADJ"} ,
		{"word":"sidérale","word_nosc":"siderale","lemma":"sidéral","pos":"ADJ"} ,
		{"word":"sidérales","word_nosc":"siderales","lemma":"sidéral","pos":"ADJ"} ,
		{"word":"sidérant","word_nosc":"siderant","lemma":"sidérant","pos":"ADJ"} ,
		{"word":"sidérante","word_nosc":"siderante","lemma":"sidérant","pos":"ADJ"} ,
		{"word":"sidéraux","word_nosc":"sideraux","lemma":"sidéral","pos":"ADJ"} ,
		{"word":"sidérurgique","word_nosc":"siderurgique","lemma":"sidérurgique","pos":"ADJ"} ,
		{"word":"sidérurgiques","word_nosc":"siderurgiques","lemma":"sidérurgique","pos":"ADJ"} ,
		{"word":"sidéré","word_nosc":"sidere","lemma":"sidéré","pos":"ADJ"} ,
		{"word":"sidérée","word_nosc":"sideree","lemma":"sidéré","pos":"ADJ"} ,
		{"word":"sidérées","word_nosc":"siderees","lemma":"sidéré","pos":"ADJ"} ,
		{"word":"sidérés","word_nosc":"sideres","lemma":"sidéré","pos":"ADJ"} ,
		{"word":"sien","word_nosc":"sien","lemma":"sien","pos":"ADJ:pos"} ,
		{"word":"sienne","word_nosc":"sienne","lemma":"sienne","pos":"ADJ"} ,
		{"word":"sienne","word_nosc":"sienne","lemma":"sienne","pos":"ADJ:pos"} ,
		{"word":"siennes","word_nosc":"siennes","lemma":"siennes","pos":"ADJ:pos"} ,
		{"word":"siennois","word_nosc":"siennois","lemma":"siennois","pos":"ADJ"} ,
		{"word":"siennoise","word_nosc":"siennoise","lemma":"siennois","pos":"ADJ"} ,
		{"word":"siennoises","word_nosc":"siennoises","lemma":"siennois","pos":"ADJ"} ,
		{"word":"siens","word_nosc":"siens","lemma":"siens","pos":"ADJ:pos"} ,
		{"word":"sifflant","word_nosc":"sifflant","lemma":"sifflant","pos":"ADJ"} ,
		{"word":"sifflante","word_nosc":"sifflante","lemma":"sifflante","pos":"ADJ"} ,
		{"word":"sifflantes","word_nosc":"sifflantes","lemma":"sifflant","pos":"ADJ"} ,
		{"word":"sifflants","word_nosc":"sifflants","lemma":"sifflant","pos":"ADJ"} ,
		{"word":"siffleur","word_nosc":"siffleur","lemma":"siffleur","pos":"ADJ"} ,
		{"word":"sigillaire","word_nosc":"sigillaire","lemma":"sigillaire","pos":"ADJ"} ,
		{"word":"sigillé","word_nosc":"sigille","lemma":"sigillé","pos":"ADJ"} ,
		{"word":"sigillée","word_nosc":"sigillee","lemma":"sigillé","pos":"ADJ"} ,
		{"word":"sigmoïde","word_nosc":"sigmoide","lemma":"sigmoïde","pos":"ADJ"} ,
		{"word":"signalé","word_nosc":"signale","lemma":"signalé","pos":"ADJ"} ,
		{"word":"signalée","word_nosc":"signalee","lemma":"signalé","pos":"ADJ"} ,
		{"word":"signalées","word_nosc":"signalees","lemma":"signalé","pos":"ADJ"} ,
		{"word":"signalés","word_nosc":"signales","lemma":"signalé","pos":"ADJ"} ,
		{"word":"signalétique","word_nosc":"signaletique","lemma":"signalétique","pos":"ADJ"} ,
		{"word":"signalétiques","word_nosc":"signaletiques","lemma":"signalétique","pos":"ADJ"} ,
		{"word":"signifiant","word_nosc":"signifiant","lemma":"signifiant","pos":"ADJ"} ,
		{"word":"signifiante","word_nosc":"signifiante","lemma":"signifiant","pos":"ADJ"} ,
		{"word":"signifiantes","word_nosc":"signifiantes","lemma":"signifiant","pos":"ADJ"} ,
		{"word":"signifiants","word_nosc":"signifiants","lemma":"signifiant","pos":"ADJ"} ,
		{"word":"significatif","word_nosc":"significatif","lemma":"significatif","pos":"ADJ"} ,
		{"word":"significatifs","word_nosc":"significatifs","lemma":"significatif","pos":"ADJ"} ,
		{"word":"significative","word_nosc":"significative","lemma":"significatif","pos":"ADJ"} ,
		{"word":"significatives","word_nosc":"significatives","lemma":"significatif","pos":"ADJ"} ,
		{"word":"signé","word_nosc":"signe","lemma":"signé","pos":"ADJ"} ,
		{"word":"signée","word_nosc":"signee","lemma":"signé","pos":"ADJ"} ,
		{"word":"signées","word_nosc":"signees","lemma":"signé","pos":"ADJ"} ,
		{"word":"signés","word_nosc":"signes","lemma":"signé","pos":"ADJ"} ,
		{"word":"silencieuse","word_nosc":"silencieuse","lemma":"silencieux","pos":"ADJ"} ,
		{"word":"silencieuses","word_nosc":"silencieuses","lemma":"silencieux","pos":"ADJ"} ,
		{"word":"silencieux","word_nosc":"silencieux","lemma":"silencieux","pos":"ADJ"} ,
		{"word":"siliceuse","word_nosc":"siliceuse","lemma":"siliceux","pos":"ADJ"} ,
		{"word":"siliceux","word_nosc":"siliceux","lemma":"siliceux","pos":"ADJ"} ,
		{"word":"silicosé","word_nosc":"silicose","lemma":"silicosé","pos":"ADJ"} ,
		{"word":"silicosée","word_nosc":"silicosee","lemma":"silicosé","pos":"ADJ"} ,
		{"word":"sillonnée","word_nosc":"sillonnee","lemma":"sillonné","pos":"ADJ"} ,
		{"word":"silésien","word_nosc":"silesien","lemma":"silésien","pos":"ADJ"} ,
		{"word":"simien","word_nosc":"simien","lemma":"simien","pos":"ADJ"} ,
		{"word":"simienne","word_nosc":"simienne","lemma":"simien","pos":"ADJ"} ,
		{"word":"simiens","word_nosc":"simiens","lemma":"simien","pos":"ADJ"} ,
		{"word":"simiesque","word_nosc":"simiesque","lemma":"simiesque","pos":"ADJ"} ,
		{"word":"simiesques","word_nosc":"simiesques","lemma":"simiesque","pos":"ADJ"} ,
		{"word":"similaire","word_nosc":"similaire","lemma":"similaire","pos":"ADJ"} ,
		{"word":"similaires","word_nosc":"similaires","lemma":"similaire","pos":"ADJ"} ,
		{"word":"simoniaque","word_nosc":"simoniaque","lemma":"simoniaque","pos":"ADJ"} ,
		{"word":"simple","word_nosc":"simple","lemma":"simple","pos":"ADJ"} ,
		{"word":"simples","word_nosc":"simples","lemma":"simple","pos":"ADJ"} ,
		{"word":"simplet","word_nosc":"simplet","lemma":"simplet","pos":"ADJ"} ,
		{"word":"simplets","word_nosc":"simplets","lemma":"simplet","pos":"ADJ"} ,
		{"word":"simplette","word_nosc":"simplette","lemma":"simplet","pos":"ADJ"} ,
		{"word":"simplettes","word_nosc":"simplettes","lemma":"simplet","pos":"ADJ"} ,
		{"word":"simplissime","word_nosc":"simplissime","lemma":"simplissime","pos":"ADJ"} ,
		{"word":"simpliste","word_nosc":"simpliste","lemma":"simpliste","pos":"ADJ"} ,
		{"word":"simplistes","word_nosc":"simplistes","lemma":"simpliste","pos":"ADJ"} ,
		{"word":"simultané","word_nosc":"simultane","lemma":"simultané","pos":"ADJ"} ,
		{"word":"simultanée","word_nosc":"simultanee","lemma":"simultané","pos":"ADJ"} ,
		{"word":"simultanées","word_nosc":"simultanees","lemma":"simultané","pos":"ADJ"} ,
		{"word":"simultanés","word_nosc":"simultanes","lemma":"simultané","pos":"ADJ"} ,
		{"word":"simulé","word_nosc":"simule","lemma":"simulé","pos":"ADJ"} ,
		{"word":"simulée","word_nosc":"simulee","lemma":"simulé","pos":"ADJ"} ,
		{"word":"simulées","word_nosc":"simulees","lemma":"simulé","pos":"ADJ"} ,
		{"word":"simulés","word_nosc":"simules","lemma":"simulé","pos":"ADJ"} ,
		{"word":"sincère","word_nosc":"sincere","lemma":"sincère","pos":"ADJ"} ,
		{"word":"sincères","word_nosc":"sinceres","lemma":"sincère","pos":"ADJ"} ,
		{"word":"singes","word_nosc":"singes","lemma":"singes","pos":"ADJ"} ,
		{"word":"single","word_nosc":"single","lemma":"single","pos":"ADJ"} ,
		{"word":"singulier","word_nosc":"singulier","lemma":"singulier","pos":"ADJ"} ,
		{"word":"singuliers","word_nosc":"singuliers","lemma":"singulier","pos":"ADJ"} ,
		{"word":"singulière","word_nosc":"singuliere","lemma":"singulier","pos":"ADJ"} ,
		{"word":"singulières","word_nosc":"singulieres","lemma":"singulier","pos":"ADJ"} ,
		{"word":"sinistre","word_nosc":"sinistre","lemma":"sinistre","pos":"ADJ"} ,
		{"word":"sinistres","word_nosc":"sinistres","lemma":"sinistre","pos":"ADJ"} ,
		{"word":"sinistré","word_nosc":"sinistre","lemma":"sinistré","pos":"ADJ"} ,
		{"word":"sinistrée","word_nosc":"sinistree","lemma":"sinistré","pos":"ADJ"} ,
		{"word":"sinistrées","word_nosc":"sinistrees","lemma":"sinistré","pos":"ADJ"} ,
		{"word":"sinistrés","word_nosc":"sinistres","lemma":"sinistré","pos":"ADJ"} ,
		{"word":"sino-américain","word_nosc":"sino-americain","lemma":"sino-américain","pos":"ADJ"} ,
		{"word":"sino-arabe","word_nosc":"sino-arabe","lemma":"sino-arabe","pos":"ADJ"} ,
		{"word":"sino-japonaise","word_nosc":"sino-japonaise","lemma":"sino-japonais","pos":"ADJ"} ,
		{"word":"sinoque","word_nosc":"sinoque","lemma":"sinoque","pos":"ADJ"} ,
		{"word":"sinoques","word_nosc":"sinoques","lemma":"sinoque","pos":"ADJ"} ,
		{"word":"sinueuse","word_nosc":"sinueuse","lemma":"sinueux","pos":"ADJ"} ,
		{"word":"sinueuses","word_nosc":"sinueuses","lemma":"sinueux","pos":"ADJ"} ,
		{"word":"sinueux","word_nosc":"sinueux","lemma":"sinueux","pos":"ADJ"} ,
		{"word":"sinusal","word_nosc":"sinusal","lemma":"sinusal","pos":"ADJ"} ,
		{"word":"sinusale","word_nosc":"sinusale","lemma":"sinusal","pos":"ADJ"} ,
		{"word":"sinusoïdal","word_nosc":"sinusoidal","lemma":"sinusoïdal","pos":"ADJ"} ,
		{"word":"sinusoïdale","word_nosc":"sinusoidale","lemma":"sinusoïdal","pos":"ADJ"} ,
		{"word":"sinusoïdaux","word_nosc":"sinusoidaux","lemma":"sinusoïdal","pos":"ADJ"} ,
		{"word":"sioniste","word_nosc":"sioniste","lemma":"sioniste","pos":"ADJ"} ,
		{"word":"sionistes","word_nosc":"sionistes","lemma":"sioniste","pos":"ADJ"} ,
		{"word":"sioux","word_nosc":"sioux","lemma":"sioux","pos":"ADJ"} ,
		{"word":"siphonné","word_nosc":"siphonne","lemma":"siphonné","pos":"ADJ"} ,
		{"word":"siphonnée","word_nosc":"siphonnee","lemma":"siphonné","pos":"ADJ"} ,
		{"word":"siphonnés","word_nosc":"siphonnes","lemma":"siphonné","pos":"ADJ"} ,
		{"word":"sirupeuse","word_nosc":"sirupeuse","lemma":"sirupeux","pos":"ADJ"} ,
		{"word":"sirupeuses","word_nosc":"sirupeuses","lemma":"sirupeux","pos":"ADJ"} ,
		{"word":"sirupeux","word_nosc":"sirupeux","lemma":"sirupeux","pos":"ADJ"} ,
		{"word":"sis","word_nosc":"sis","lemma":"sis","pos":"ADJ"} ,
		{"word":"sise","word_nosc":"sise","lemma":"sis","pos":"ADJ"} ,
		{"word":"sises","word_nosc":"sises","lemma":"sis","pos":"ADJ"} ,
		{"word":"sismique","word_nosc":"sismique","lemma":"sismique","pos":"ADJ"} ,
		{"word":"sismiques","word_nosc":"sismiques","lemma":"sismique","pos":"ADJ"} ,
		{"word":"sismologique","word_nosc":"sismologique","lemma":"sismologique","pos":"ADJ"} ,
		{"word":"situable","word_nosc":"situable","lemma":"situable","pos":"ADJ"} ,
		{"word":"situationnel","word_nosc":"situationnel","lemma":"situationnel","pos":"ADJ"} ,
		{"word":"situationnistes","word_nosc":"situationnistes","lemma":"situationniste","pos":"ADJ"} ,
		{"word":"situé","word_nosc":"situe","lemma":"situé","pos":"ADJ"} ,
		{"word":"située","word_nosc":"situee","lemma":"situé","pos":"ADJ"} ,
		{"word":"situées","word_nosc":"situees","lemma":"situé","pos":"ADJ"} ,
		{"word":"situés","word_nosc":"situes","lemma":"situé","pos":"ADJ"} ,
		{"word":"six","word_nosc":"six","lemma":"six","pos":"ADJ:num"} ,
		{"word":"sixième","word_nosc":"sixieme","lemma":"sixième","pos":"ADJ"} ,
		{"word":"sixièmes","word_nosc":"sixiemes","lemma":"sixième","pos":"ADJ"} ,
		{"word":"skiable","word_nosc":"skiable","lemma":"skiable","pos":"ADJ"} ,
		{"word":"skinhead","word_nosc":"skinhead","lemma":"skinhead","pos":"ADJ"} ,
		{"word":"skinheads","word_nosc":"skinheads","lemma":"skinhead","pos":"ADJ"} ,
		{"word":"slave","word_nosc":"slave","lemma":"slave","pos":"ADJ"} ,
		{"word":"slaves","word_nosc":"slaves","lemma":"slave","pos":"ADJ"} ,
		{"word":"slavon","word_nosc":"slavon","lemma":"slavon","pos":"ADJ"} ,
		{"word":"slavophiles","word_nosc":"slavophiles","lemma":"slavophile","pos":"ADJ"} ,
		{"word":"slovaque","word_nosc":"slovaque","lemma":"slovaque","pos":"ADJ"} ,
		{"word":"slovène","word_nosc":"slovene","lemma":"slovène","pos":"ADJ"} ,
		{"word":"smaragdin","word_nosc":"smaragdin","lemma":"smaragdin","pos":"ADJ"} ,
		{"word":"smart","word_nosc":"smart","lemma":"smart","pos":"ADJ"} ,
		{"word":"snob","word_nosc":"snob","lemma":"snob","pos":"ADJ"} ,
		{"word":"snobinard","word_nosc":"snobinard","lemma":"snobinard","pos":"ADJ"} ,
		{"word":"snobinarde","word_nosc":"snobinarde","lemma":"snobinard","pos":"ADJ"} ,
		{"word":"snobinardes","word_nosc":"snobinardes","lemma":"snobinard","pos":"ADJ"} ,
		{"word":"snobinards","word_nosc":"snobinards","lemma":"snobinard","pos":"ADJ"} ,
		{"word":"snobs","word_nosc":"snobs","lemma":"snob","pos":"ADJ"} ,
		{"word":"sobre","word_nosc":"sobre","lemma":"sobre","pos":"ADJ"} ,
		{"word":"sobres","word_nosc":"sobres","lemma":"sobre","pos":"ADJ"} ,
		{"word":"sociable","word_nosc":"sociable","lemma":"sociable","pos":"ADJ"} ,
		{"word":"sociables","word_nosc":"sociables","lemma":"sociable","pos":"ADJ"} ,
		{"word":"social","word_nosc":"social","lemma":"social","pos":"ADJ"} ,
		{"word":"social-démocrate","word_nosc":"social-democrate","lemma":"social-démocrate","pos":"ADJ"} ,
		{"word":"sociale","word_nosc":"sociale","lemma":"social","pos":"ADJ"} ,
		{"word":"sociales","word_nosc":"sociales","lemma":"social","pos":"ADJ"} ,
		{"word":"socialiste","word_nosc":"socialiste","lemma":"socialiste","pos":"ADJ"} ,
		{"word":"socialistes","word_nosc":"socialistes","lemma":"socialiste","pos":"ADJ"} ,
		{"word":"socialo","word_nosc":"socialo","lemma":"socialo","pos":"ADJ"} ,
		{"word":"socialos","word_nosc":"socialos","lemma":"socialo","pos":"ADJ"} ,
		{"word":"sociaux","word_nosc":"sociaux","lemma":"social","pos":"ADJ"} ,
		{"word":"sociaux-démocrates","word_nosc":"sociaux-democrates","lemma":"sociaux-démocrates","pos":"ADJ"} ,
		{"word":"socio-culturelles","word_nosc":"socio-culturelles","lemma":"socio-culturel","pos":"ADJ"} ,
		{"word":"socio-culturels","word_nosc":"socio-culturels","lemma":"socio-culturel","pos":"ADJ"} ,
		{"word":"socio-économique","word_nosc":"socio-economique","lemma":"socio-économique","pos":"ADJ"} ,
		{"word":"socio-économiques","word_nosc":"socio-economiques","lemma":"socio-économique","pos":"ADJ"} ,
		{"word":"socioculturel","word_nosc":"socioculturel","lemma":"socioculturel","pos":"ADJ"} ,
		{"word":"socioculturelles","word_nosc":"socioculturelles","lemma":"socioculturel","pos":"ADJ"} ,
		{"word":"sociologique","word_nosc":"sociologique","lemma":"sociologique","pos":"ADJ"} ,
		{"word":"sociologiques","word_nosc":"sociologiques","lemma":"sociologique","pos":"ADJ"} ,
		{"word":"sociopathe","word_nosc":"sociopathe","lemma":"sociopathe","pos":"ADJ"} ,
		{"word":"sociopathes","word_nosc":"sociopathes","lemma":"sociopathe","pos":"ADJ"} ,
		{"word":"sociopolitique","word_nosc":"sociopolitique","lemma":"sociopolitique","pos":"ADJ"} ,
		{"word":"sociétaire","word_nosc":"societaire","lemma":"sociétaire","pos":"ADJ"} ,
		{"word":"sociétal","word_nosc":"societal","lemma":"sociétal","pos":"ADJ"} ,
		{"word":"sociétale","word_nosc":"societale","lemma":"sociétal","pos":"ADJ"} ,
		{"word":"socratique","word_nosc":"socratique","lemma":"socratique","pos":"ADJ"} ,
		{"word":"socratiques","word_nosc":"socratiques","lemma":"socratique","pos":"ADJ"} ,
		{"word":"sodique","word_nosc":"sodique","lemma":"sodique","pos":"ADJ"} ,
		{"word":"sodomitiques","word_nosc":"sodomitiques","lemma":"sodomitique","pos":"ADJ"} ,
		{"word":"soft","word_nosc":"soft","lemma":"soft","pos":"ADJ"} ,
		{"word":"soi-disant","word_nosc":"soi-disant","lemma":"soi-disant","pos":"ADJ"} ,
		{"word":"soiffard","word_nosc":"soiffard","lemma":"soiffard","pos":"ADJ"} ,
		{"word":"soiffards","word_nosc":"soiffards","lemma":"soiffard","pos":"ADJ"} ,
		{"word":"soignable","word_nosc":"soignable","lemma":"soignable","pos":"ADJ"} ,
		{"word":"soignant","word_nosc":"soignant","lemma":"soignant","pos":"ADJ"} ,
		{"word":"soignante","word_nosc":"soignante","lemma":"soignant","pos":"ADJ"} ,
		{"word":"soignantes","word_nosc":"soignantes","lemma":"soignant","pos":"ADJ"} ,
		{"word":"soignants","word_nosc":"soignants","lemma":"soignant","pos":"ADJ"} ,
		{"word":"soigneuse","word_nosc":"soigneuse","lemma":"soigneux","pos":"ADJ"} ,
		{"word":"soigneuses","word_nosc":"soigneuses","lemma":"soigneux","pos":"ADJ"} ,
		{"word":"soigneux","word_nosc":"soigneux","lemma":"soigneux","pos":"ADJ"} ,
		{"word":"soigné","word_nosc":"soigne","lemma":"soigné","pos":"ADJ"} ,
		{"word":"soignée","word_nosc":"soignee","lemma":"soigné","pos":"ADJ"} ,
		{"word":"soignées","word_nosc":"soignees","lemma":"soigné","pos":"ADJ"} ,
		{"word":"soignés","word_nosc":"soignes","lemma":"soigné","pos":"ADJ"} ,
		{"word":"soissonnais","word_nosc":"soissonnais","lemma":"soissonnais","pos":"ADJ"} ,
		{"word":"soixante","word_nosc":"soixante","lemma":"soixante","pos":"ADJ:num"} ,
		{"word":"soixante-cinq","word_nosc":"soixante-cinq","lemma":"soixante-cinq","pos":"ADJ:num"} ,
		{"word":"soixante-deux","word_nosc":"soixante-deux","lemma":"soixante-deux","pos":"ADJ:num"} ,
		{"word":"soixante-deuxième","word_nosc":"soixante-deuxieme","lemma":"soixante-deuxième","pos":"ADJ"} ,
		{"word":"soixante-dix","word_nosc":"soixante-dix","lemma":"soixante-dix","pos":"ADJ:num"} ,
		{"word":"soixante-dix-huit","word_nosc":"soixante-dix-huit","lemma":"soixante-dix-huit","pos":"ADJ:num"} ,
		{"word":"soixante-dix-neuf","word_nosc":"soixante-dix-neuf","lemma":"soixante-dix-neuf","pos":"ADJ:num"} ,
		{"word":"soixante-dix-neuvième","word_nosc":"soixante-dix-neuvieme","lemma":"soixante-dix-neuvième","pos":"ADJ"} ,
		{"word":"soixante-dix-sept","word_nosc":"soixante-dix-sept","lemma":"soixante-dix-sept","pos":"ADJ:num"} ,
		{"word":"soixante-dixième","word_nosc":"soixante-dixieme","lemma":"soixante-dixième","pos":"ADJ"} ,
		{"word":"soixante-douze","word_nosc":"soixante-douze","lemma":"soixante-douze","pos":"ADJ:num"} ,
		{"word":"soixante-huit","word_nosc":"soixante-huit","lemma":"soixante-huit","pos":"ADJ:num"} ,
		{"word":"soixante-huitarde","word_nosc":"soixante-huitarde","lemma":"soixante-huitard","pos":"ADJ"} ,
		{"word":"soixante-neuf","word_nosc":"soixante-neuf","lemma":"soixante-neuf","pos":"ADJ:num"} ,
		{"word":"soixante-quatorze","word_nosc":"soixante-quatorze","lemma":"soixante-quatorze","pos":"ADJ:num"} ,
		{"word":"soixante-quatre","word_nosc":"soixante-quatre","lemma":"soixante-quatre","pos":"ADJ:num"} ,
		{"word":"soixante-quinze","word_nosc":"soixante-quinze","lemma":"soixante-quinze","pos":"ADJ:num"} ,
		{"word":"soixante-seize","word_nosc":"soixante-seize","lemma":"soixante-seize","pos":"ADJ:num"} ,
		{"word":"soixante-sept","word_nosc":"soixante-sept","lemma":"soixante-sept","pos":"ADJ:num"} ,
		{"word":"soixante-six","word_nosc":"soixante-six","lemma":"soixante-six","pos":"ADJ:num"} ,
		{"word":"soixante-treize","word_nosc":"soixante-treize","lemma":"soixante-treize","pos":"ADJ:num"} ,
		{"word":"soixante-trois","word_nosc":"soixante-trois","lemma":"soixante-trois","pos":"ADJ:num"} ,
		{"word":"soixantième","word_nosc":"soixantieme","lemma":"soixantième","pos":"ADJ"} ,
		{"word":"sol-air","word_nosc":"sol-air","lemma":"sol-air","pos":"ADJ"} ,
		{"word":"solaire","word_nosc":"solaire","lemma":"solaire","pos":"ADJ"} ,
		{"word":"solaires","word_nosc":"solaires","lemma":"solaire","pos":"ADJ"} ,
		{"word":"soldatesque","word_nosc":"soldatesque","lemma":"soldatesque","pos":"ADJ"} ,
		{"word":"soleilleux","word_nosc":"soleilleux","lemma":"soleilleux","pos":"ADJ"} ,
		{"word":"solennel","word_nosc":"solennel","lemma":"solennel","pos":"ADJ"} ,
		{"word":"solennelle","word_nosc":"solennelle","lemma":"solennel","pos":"ADJ"} ,
		{"word":"solennelles","word_nosc":"solennelles","lemma":"solennel","pos":"ADJ"} ,
		{"word":"solennels","word_nosc":"solennels","lemma":"solennel","pos":"ADJ"} ,
		{"word":"solidaire","word_nosc":"solidaire","lemma":"solidaire","pos":"ADJ"} ,
		{"word":"solidaires","word_nosc":"solidaires","lemma":"solidaire","pos":"ADJ"} ,
		{"word":"solide","word_nosc":"solide","lemma":"solide","pos":"ADJ"} ,
		{"word":"solides","word_nosc":"solides","lemma":"solide","pos":"ADJ"} ,
		{"word":"solipsiste","word_nosc":"solipsiste","lemma":"solipsiste","pos":"ADJ"} ,
		{"word":"solitaire","word_nosc":"solitaire","lemma":"solitaire","pos":"ADJ"} ,
		{"word":"solitaires","word_nosc":"solitaires","lemma":"solitaire","pos":"ADJ"} ,
		{"word":"solognot","word_nosc":"solognot","lemma":"solognot","pos":"ADJ"} ,
		{"word":"solognote","word_nosc":"solognote","lemma":"solognot","pos":"ADJ"} ,
		{"word":"solognots","word_nosc":"solognots","lemma":"solognot","pos":"ADJ"} ,
		{"word":"soluble","word_nosc":"soluble","lemma":"soluble","pos":"ADJ"} ,
		{"word":"solubles","word_nosc":"solubles","lemma":"soluble","pos":"ADJ"} ,
		{"word":"solvable","word_nosc":"solvable","lemma":"solvable","pos":"ADJ"} ,
		{"word":"somalien","word_nosc":"somalien","lemma":"somalien","pos":"ADJ"} ,
		{"word":"somalienne","word_nosc":"somalienne","lemma":"somalien","pos":"ADJ"} ,
		{"word":"somaliens","word_nosc":"somaliens","lemma":"somalien","pos":"ADJ"} ,
		{"word":"somalis","word_nosc":"somalis","lemma":"somali","pos":"ADJ"} ,
		{"word":"somatique","word_nosc":"somatique","lemma":"somatique","pos":"ADJ"} ,
		{"word":"somatiques","word_nosc":"somatiques","lemma":"somatique","pos":"ADJ"} ,
		{"word":"sombre","word_nosc":"sombre","lemma":"sombre","pos":"ADJ"} ,
		{"word":"sombres","word_nosc":"sombres","lemma":"sombre","pos":"ADJ"} ,
		{"word":"sommaire","word_nosc":"sommaire","lemma":"sommaire","pos":"ADJ"} ,
		{"word":"sommaires","word_nosc":"sommaires","lemma":"sommaire","pos":"ADJ"} ,
		{"word":"sommeillant","word_nosc":"sommeillant","lemma":"sommeillant","pos":"ADJ"} ,
		{"word":"sommeillante","word_nosc":"sommeillante","lemma":"sommeillant","pos":"ADJ"} ,
		{"word":"sommeillants","word_nosc":"sommeillants","lemma":"sommeillant","pos":"ADJ"} ,
		{"word":"sommeilleux","word_nosc":"sommeilleux","lemma":"sommeilleux","pos":"ADJ"} ,
		{"word":"sommitales","word_nosc":"sommitales","lemma":"sommital","pos":"ADJ"} ,
		{"word":"somnambule","word_nosc":"somnambule","lemma":"somnambule","pos":"ADJ"} ,
		{"word":"somnambules","word_nosc":"somnambules","lemma":"somnambule","pos":"ADJ"} ,
		{"word":"somnambulique","word_nosc":"somnambulique","lemma":"somnambulique","pos":"ADJ"} ,
		{"word":"somnambuliques","word_nosc":"somnambuliques","lemma":"somnambulique","pos":"ADJ"} ,
		{"word":"somnifère","word_nosc":"somnifere","lemma":"somnifère","pos":"ADJ"} ,
		{"word":"somnifères","word_nosc":"somniferes","lemma":"somnifère","pos":"ADJ"} ,
		{"word":"somnolent","word_nosc":"somnolent","lemma":"somnolent","pos":"ADJ"} ,
		{"word":"somnolente","word_nosc":"somnolente","lemma":"somnolent","pos":"ADJ"} ,
		{"word":"somnolentes","word_nosc":"somnolentes","lemma":"somnolent","pos":"ADJ"} ,
		{"word":"somnolents","word_nosc":"somnolents","lemma":"somnolent","pos":"ADJ"} ,
		{"word":"somptuaire","word_nosc":"somptuaire","lemma":"somptuaire","pos":"ADJ"} ,
		{"word":"somptuaires","word_nosc":"somptuaires","lemma":"somptuaire","pos":"ADJ"} ,
		{"word":"somptueuse","word_nosc":"somptueuse","lemma":"somptueux","pos":"ADJ"} ,
		{"word":"somptueuses","word_nosc":"somptueuses","lemma":"somptueux","pos":"ADJ"} ,
		{"word":"somptueux","word_nosc":"somptueux","lemma":"somptueux","pos":"ADJ"} ,
		{"word":"son","word_nosc":"son","lemma":"son","pos":"ADJ:pos"} ,
		{"word":"sondé","word_nosc":"sonde","lemma":"sondé","pos":"ADJ"} ,
		{"word":"sondée","word_nosc":"sondee","lemma":"sondé","pos":"ADJ"} ,
		{"word":"songeur","word_nosc":"songeur","lemma":"songeur","pos":"ADJ"} ,
		{"word":"songeurs","word_nosc":"songeurs","lemma":"songeur","pos":"ADJ"} ,
		{"word":"songeuse","word_nosc":"songeuse","lemma":"songeur","pos":"ADJ"} ,
		{"word":"songeuses","word_nosc":"songeuses","lemma":"songeur","pos":"ADJ"} ,
		{"word":"sonique","word_nosc":"sonique","lemma":"sonique","pos":"ADJ"} ,
		{"word":"soniques","word_nosc":"soniques","lemma":"sonique","pos":"ADJ"} ,
		{"word":"sonnant","word_nosc":"sonnant","lemma":"sonnant","pos":"ADJ"} ,
		{"word":"sonnante","word_nosc":"sonnante","lemma":"sonnant","pos":"ADJ"} ,
		{"word":"sonnantes","word_nosc":"sonnantes","lemma":"sonnant","pos":"ADJ"} ,
		{"word":"sonnants","word_nosc":"sonnants","lemma":"sonnant","pos":"ADJ"} ,
		{"word":"sonné","word_nosc":"sonne","lemma":"sonné","pos":"ADJ"} ,
		{"word":"sonnée","word_nosc":"sonnee","lemma":"sonné","pos":"ADJ"} ,
		{"word":"sonnées","word_nosc":"sonnees","lemma":"sonné","pos":"ADJ"} ,
		{"word":"sonnés","word_nosc":"sonnes","lemma":"sonné","pos":"ADJ"} ,
		{"word":"sonore","word_nosc":"sonore","lemma":"sonore","pos":"ADJ"} ,
		{"word":"sonores","word_nosc":"sonores","lemma":"sonore","pos":"ADJ"} ,
		{"word":"sophistique","word_nosc":"sophistique","lemma":"sophistique","pos":"ADJ"} ,
		{"word":"sophistiqué","word_nosc":"sophistique","lemma":"sophistiqué","pos":"ADJ"} ,
		{"word":"sophistiquée","word_nosc":"sophistiquee","lemma":"sophistiqué","pos":"ADJ"} ,
		{"word":"sophistiquées","word_nosc":"sophistiquees","lemma":"sophistiqué","pos":"ADJ"} ,
		{"word":"sophistiqués","word_nosc":"sophistiques","lemma":"sophistiqué","pos":"ADJ"} ,
		{"word":"soporifique","word_nosc":"soporifique","lemma":"soporifique","pos":"ADJ"} ,
		{"word":"soporifiques","word_nosc":"soporifiques","lemma":"soporifique","pos":"ADJ"} ,
		{"word":"sorcier","word_nosc":"sorcier","lemma":"sorcier","pos":"ADJ"} ,
		{"word":"sorciers","word_nosc":"sorciers","lemma":"sorcier","pos":"ADJ"} ,
		{"word":"sordide","word_nosc":"sordide","lemma":"sordide","pos":"ADJ"} ,
		{"word":"sordides","word_nosc":"sordides","lemma":"sordide","pos":"ADJ"} ,
		{"word":"sororal","word_nosc":"sororal","lemma":"sororal","pos":"ADJ"} ,
		{"word":"sororale","word_nosc":"sororale","lemma":"sororal","pos":"ADJ"} ,
		{"word":"sororales","word_nosc":"sororales","lemma":"sororal","pos":"ADJ"} ,
		{"word":"sortable","word_nosc":"sortable","lemma":"sortable","pos":"ADJ"} ,
		{"word":"sortables","word_nosc":"sortables","lemma":"sortable","pos":"ADJ"} ,
		{"word":"sortant","word_nosc":"sortant","lemma":"sortant","pos":"ADJ"} ,
		{"word":"sortante","word_nosc":"sortante","lemma":"sortant","pos":"ADJ"} ,
		{"word":"sortantes","word_nosc":"sortantes","lemma":"sortant","pos":"ADJ"} ,
		{"word":"sortants","word_nosc":"sortants","lemma":"sortant","pos":"ADJ"} ,
		{"word":"sot","word_nosc":"sot","lemma":"sot","pos":"ADJ"} ,
		{"word":"sots","word_nosc":"sots","lemma":"sot","pos":"ADJ"} ,
		{"word":"sotte","word_nosc":"sotte","lemma":"sot","pos":"ADJ"} ,
		{"word":"sottes","word_nosc":"sottes","lemma":"sot","pos":"ADJ"} ,
		{"word":"souabe","word_nosc":"souabe","lemma":"souabe","pos":"ADJ"} ,
		{"word":"souabes","word_nosc":"souabes","lemma":"souabe","pos":"ADJ"} ,
		{"word":"soucieuse","word_nosc":"soucieuse","lemma":"soucieux","pos":"ADJ"} ,
		{"word":"soucieuses","word_nosc":"soucieuses","lemma":"soucieux","pos":"ADJ"} ,
		{"word":"soucieux","word_nosc":"soucieux","lemma":"soucieux","pos":"ADJ"} ,
		{"word":"soudain","word_nosc":"soudain","lemma":"soudain","pos":"ADJ"} ,
		{"word":"soudaine","word_nosc":"soudaine","lemma":"soudain","pos":"ADJ"} ,
		{"word":"soudaines","word_nosc":"soudaines","lemma":"soudain","pos":"ADJ"} ,
		{"word":"soudains","word_nosc":"soudains","lemma":"soudain","pos":"ADJ"} ,
		{"word":"soudanais","word_nosc":"soudanais","lemma":"soudanais","pos":"ADJ"} ,
		{"word":"soudanaise","word_nosc":"soudanaise","lemma":"soudanais","pos":"ADJ"} ,
		{"word":"soudé","word_nosc":"soude","lemma":"soudé","pos":"ADJ"} ,
		{"word":"soudée","word_nosc":"soudee","lemma":"soudé","pos":"ADJ"} ,
		{"word":"soudées","word_nosc":"soudees","lemma":"soudé","pos":"ADJ"} ,
		{"word":"soudés","word_nosc":"soudes","lemma":"soudé","pos":"ADJ"} ,
		{"word":"soufflant","word_nosc":"soufflant","lemma":"soufflant","pos":"ADJ"} ,
		{"word":"soufflante","word_nosc":"soufflante","lemma":"soufflant","pos":"ADJ"} ,
		{"word":"soufflants","word_nosc":"soufflants","lemma":"soufflant","pos":"ADJ"} ,
		{"word":"soufflé","word_nosc":"souffle","lemma":"soufflé","pos":"ADJ"} ,
		{"word":"soufflée","word_nosc":"soufflee","lemma":"soufflé","pos":"ADJ"} ,
		{"word":"soufflées","word_nosc":"soufflees","lemma":"soufflé","pos":"ADJ"} ,
		{"word":"soufflés","word_nosc":"souffles","lemma":"soufflé","pos":"ADJ"} ,
		{"word":"souffrant","word_nosc":"souffrant","lemma":"souffrant","pos":"ADJ"} ,
		{"word":"souffrante","word_nosc":"souffrante","lemma":"souffrant","pos":"ADJ"} ,
		{"word":"souffrantes","word_nosc":"souffrantes","lemma":"souffrant","pos":"ADJ"} ,
		{"word":"souffrants","word_nosc":"souffrants","lemma":"souffrant","pos":"ADJ"} ,
		{"word":"souffreteuse","word_nosc":"souffreteuse","lemma":"souffreteux","pos":"ADJ"} ,
		{"word":"souffreteuses","word_nosc":"souffreteuses","lemma":"souffreteux","pos":"ADJ"} ,
		{"word":"souffreteux","word_nosc":"souffreteux","lemma":"souffreteux","pos":"ADJ"} ,
		{"word":"soufi","word_nosc":"soufi","lemma":"soufi","pos":"ADJ"} ,
		{"word":"soufis","word_nosc":"soufis","lemma":"soufi","pos":"ADJ"} ,
		{"word":"soufré","word_nosc":"soufre","lemma":"soufré","pos":"ADJ"} ,
		{"word":"soufrée","word_nosc":"soufree","lemma":"soufré","pos":"ADJ"} ,
		{"word":"soufrées","word_nosc":"soufrees","lemma":"soufré","pos":"ADJ"} ,
		{"word":"soufrés","word_nosc":"soufres","lemma":"soufré","pos":"ADJ"} ,
		{"word":"souhaitable","word_nosc":"souhaitable","lemma":"souhaitable","pos":"ADJ"} ,
		{"word":"souhaitables","word_nosc":"souhaitables","lemma":"souhaitable","pos":"ADJ"} ,
		{"word":"soulageant","word_nosc":"soulageant","lemma":"soulageant","pos":"ADJ"} ,
		{"word":"soule","word_nosc":"soule","lemma":"soule","pos":"ADJ"} ,
		{"word":"soulevé","word_nosc":"souleve","lemma":"soulevé","pos":"ADJ"} ,
		{"word":"soulevée","word_nosc":"soulevee","lemma":"soulevé","pos":"ADJ"} ,
		{"word":"soulevées","word_nosc":"soulevees","lemma":"soulevé","pos":"ADJ"} ,
		{"word":"soulevés","word_nosc":"souleves","lemma":"soulevé","pos":"ADJ"} ,
		{"word":"soumis","word_nosc":"soumis","lemma":"soumis","pos":"ADJ"} ,
		{"word":"soumise","word_nosc":"soumise","lemma":"soumis","pos":"ADJ"} ,
		{"word":"soumises","word_nosc":"soumises","lemma":"soumis","pos":"ADJ"} ,
		{"word":"soupier","word_nosc":"soupier","lemma":"soupier","pos":"ADJ"} ,
		{"word":"souple","word_nosc":"souple","lemma":"souple","pos":"ADJ"} ,
		{"word":"souples","word_nosc":"souples","lemma":"souple","pos":"ADJ"} ,
		{"word":"soupçonnable","word_nosc":"soupconnable","lemma":"soupçonnable","pos":"ADJ"} ,
		{"word":"soupçonneuse","word_nosc":"soupconneuse","lemma":"soupçonneux","pos":"ADJ"} ,
		{"word":"soupçonneuses","word_nosc":"soupconneuses","lemma":"soupçonneux","pos":"ADJ"} ,
		{"word":"soupçonneux","word_nosc":"soupconneux","lemma":"soupçonneux","pos":"ADJ"} ,
		{"word":"sourcilière","word_nosc":"sourciliere","lemma":"sourcilier","pos":"ADJ"} ,
		{"word":"sourcilières","word_nosc":"sourcilieres","lemma":"sourcilier","pos":"ADJ"} ,
		{"word":"sourcilleuse","word_nosc":"sourcilleuse","lemma":"sourcilleux","pos":"ADJ"} ,
		{"word":"sourcilleuses","word_nosc":"sourcilleuses","lemma":"sourcilleux","pos":"ADJ"} ,
		{"word":"sourcilleux","word_nosc":"sourcilleux","lemma":"sourcilleux","pos":"ADJ"} ,
		{"word":"sourd","word_nosc":"sourd","lemma":"sourd","pos":"ADJ"} ,
		{"word":"sourd-muet","word_nosc":"sourd-muet","lemma":"sourd-muet","pos":"ADJ"} ,
		{"word":"sourde","word_nosc":"sourde","lemma":"sourd","pos":"ADJ"} ,
		{"word":"sourdes","word_nosc":"sourdes","lemma":"sourd","pos":"ADJ"} ,
		{"word":"sourdingue","word_nosc":"sourdingue","lemma":"sourdingue","pos":"ADJ"} ,
		{"word":"sourdingues","word_nosc":"sourdingues","lemma":"sourdingue","pos":"ADJ"} ,
		{"word":"sourds","word_nosc":"sourds","lemma":"sourd","pos":"ADJ"} ,
		{"word":"sourds-muets","word_nosc":"sourds-muets","lemma":"sourd-muet","pos":"ADJ"} ,
		{"word":"souriant","word_nosc":"souriant","lemma":"souriant","pos":"ADJ"} ,
		{"word":"souriante","word_nosc":"souriante","lemma":"souriant","pos":"ADJ"} ,
		{"word":"souriantes","word_nosc":"souriantes","lemma":"souriant","pos":"ADJ"} ,
		{"word":"souriants","word_nosc":"souriants","lemma":"souriant","pos":"ADJ"} ,
		{"word":"souris","word_nosc":"souris","lemma":"souris","pos":"ADJ"} ,
		{"word":"sournois","word_nosc":"sournois","lemma":"sournois","pos":"ADJ"} ,
		{"word":"sournoise","word_nosc":"sournoise","lemma":"sournois","pos":"ADJ"} ,
		{"word":"sournoises","word_nosc":"sournoises","lemma":"sournois","pos":"ADJ"} ,
		{"word":"sous-alimenté","word_nosc":"sous-alimente","lemma":"sous-alimenté","pos":"ADJ"} ,
		{"word":"sous-alimentée","word_nosc":"sous-alimentee","lemma":"sous-alimenté","pos":"ADJ"} ,
		{"word":"sous-clavière","word_nosc":"sous-claviere","lemma":"sous-clavier","pos":"ADJ"} ,
		{"word":"sous-cutané","word_nosc":"sous-cutane","lemma":"sous-cutané","pos":"ADJ"} ,
		{"word":"sous-cutanée","word_nosc":"sous-cutanee","lemma":"sous-cutané","pos":"ADJ"} ,
		{"word":"sous-cutanées","word_nosc":"sous-cutanees","lemma":"sous-cutané","pos":"ADJ"} ,
		{"word":"sous-cutanés","word_nosc":"sous-cutanes","lemma":"sous-cutané","pos":"ADJ"} ,
		{"word":"sous-développé","word_nosc":"sous-developpe","lemma":"sous-développé","pos":"ADJ"} ,
		{"word":"sous-développée","word_nosc":"sous-developpee","lemma":"sous-développé","pos":"ADJ"} ,
		{"word":"sous-développées","word_nosc":"sous-developpees","lemma":"sous-développé","pos":"ADJ"} ,
		{"word":"sous-développés","word_nosc":"sous-developpes","lemma":"sous-développé","pos":"ADJ"} ,
		{"word":"sous-entendu","word_nosc":"sous-entendu","lemma":"sous-entendu","pos":"ADJ"} ,
		{"word":"sous-entendue","word_nosc":"sous-entendue","lemma":"sous-entendu","pos":"ADJ"} ,
		{"word":"sous-entendus","word_nosc":"sous-entendus","lemma":"sous-entendu","pos":"ADJ"} ,
		{"word":"sous-jacent","word_nosc":"sous-jacent","lemma":"sous-jacent","pos":"ADJ"} ,
		{"word":"sous-jacente","word_nosc":"sous-jacente","lemma":"sous-jacent","pos":"ADJ"} ,
		{"word":"sous-jacentes","word_nosc":"sous-jacentes","lemma":"sous-jacent","pos":"ADJ"} ,
		{"word":"sous-jacents","word_nosc":"sous-jacents","lemma":"sous-jacent","pos":"ADJ"} ,
		{"word":"sous-marin","word_nosc":"sous-marin","lemma":"sous-marin","pos":"ADJ"} ,
		{"word":"sous-marine","word_nosc":"sous-marine","lemma":"sous-marin","pos":"ADJ"} ,
		{"word":"sous-marines","word_nosc":"sous-marines","lemma":"sous-marin","pos":"ADJ"} ,
		{"word":"sous-marins","word_nosc":"sous-marins","lemma":"sous-marin","pos":"ADJ"} ,
		{"word":"sous-qualifié","word_nosc":"sous-qualifie","lemma":"sous-qualifié","pos":"ADJ"} ,
		{"word":"sous-qualifiée","word_nosc":"sous-qualifiee","lemma":"sous-qualifié","pos":"ADJ"} ,
		{"word":"sous-équipés","word_nosc":"sous-equipes","lemma":"sous-équipé","pos":"ADJ"} ,
		{"word":"souscrit","word_nosc":"souscrit","lemma":"souscrit","pos":"ADJ"} ,
		{"word":"souscrite","word_nosc":"souscrite","lemma":"souscrit","pos":"ADJ"} ,
		{"word":"souscrites","word_nosc":"souscrites","lemma":"souscrit","pos":"ADJ"} ,
		{"word":"souscrits","word_nosc":"souscrits","lemma":"souscrit","pos":"ADJ"} ,
		{"word":"soussigné","word_nosc":"soussigne","lemma":"soussigné","pos":"ADJ"} ,
		{"word":"soussignée","word_nosc":"soussignee","lemma":"soussigné","pos":"ADJ"} ,
		{"word":"soussignés","word_nosc":"soussignes","lemma":"soussigné","pos":"ADJ"} ,
		{"word":"soussou","word_nosc":"soussou","lemma":"soussou","pos":"ADJ"} ,
		{"word":"soutenable","word_nosc":"soutenable","lemma":"soutenable","pos":"ADJ"} ,
		{"word":"soutenu","word_nosc":"soutenu","lemma":"soutenu","pos":"ADJ"} ,
		{"word":"soutenue","word_nosc":"soutenue","lemma":"soutenu","pos":"ADJ"} ,
		{"word":"soutenues","word_nosc":"soutenues","lemma":"soutenu","pos":"ADJ"} ,
		{"word":"soutenus","word_nosc":"soutenus","lemma":"soutenu","pos":"ADJ"} ,
		{"word":"souterrain","word_nosc":"souterrain","lemma":"souterrain","pos":"ADJ"} ,
		{"word":"souterraine","word_nosc":"souterraine","lemma":"souterrain","pos":"ADJ"} ,
		{"word":"souterraines","word_nosc":"souterraines","lemma":"souterrain","pos":"ADJ"} ,
		{"word":"souterrains","word_nosc":"souterrains","lemma":"souterrain","pos":"ADJ"} ,
		{"word":"souverain","word_nosc":"souverain","lemma":"souverain","pos":"ADJ"} ,
		{"word":"souveraine","word_nosc":"souveraine","lemma":"souverain","pos":"ADJ"} ,
		{"word":"souveraines","word_nosc":"souveraines","lemma":"souverain","pos":"ADJ"} ,
		{"word":"souverains","word_nosc":"souverains","lemma":"souverain","pos":"ADJ"} ,
		{"word":"soviétique","word_nosc":"sovietique","lemma":"soviétique","pos":"ADJ"} ,
		{"word":"soviétiques","word_nosc":"sovietiques","lemma":"soviétique","pos":"ADJ"} ,
		{"word":"soyeuse","word_nosc":"soyeuse","lemma":"soyeux","pos":"ADJ"} ,
		{"word":"soyeuses","word_nosc":"soyeuses","lemma":"soyeux","pos":"ADJ"} ,
		{"word":"soyeux","word_nosc":"soyeux","lemma":"soyeux","pos":"ADJ"} ,
		{"word":"soûl","word_nosc":"soul","lemma":"soûl","pos":"ADJ"} ,
		{"word":"soûlant","word_nosc":"soulant","lemma":"soûlant","pos":"ADJ"} ,
		{"word":"soûlante","word_nosc":"soulante","lemma":"soûlant","pos":"ADJ"} ,
		{"word":"soûlard","word_nosc":"soulard","lemma":"soûlard","pos":"ADJ"} ,
		{"word":"soûlards","word_nosc":"soulards","lemma":"soûlard","pos":"ADJ"} ,
		{"word":"soûlaud","word_nosc":"soulaud","lemma":"soûlaud","pos":"ADJ"} ,
		{"word":"soûlauds","word_nosc":"soulauds","lemma":"soûlaud","pos":"ADJ"} ,
		{"word":"soûle","word_nosc":"soule","lemma":"soûl","pos":"ADJ"} ,
		{"word":"soûls","word_nosc":"souls","lemma":"soûl","pos":"ADJ"} ,
		{"word":"spacieuse","word_nosc":"spacieuse","lemma":"spacieux","pos":"ADJ"} ,
		{"word":"spacieuses","word_nosc":"spacieuses","lemma":"spacieux","pos":"ADJ"} ,
		{"word":"spacieux","word_nosc":"spacieux","lemma":"spacieux","pos":"ADJ"} ,
		{"word":"spartakistes","word_nosc":"spartakistes","lemma":"spartakiste","pos":"ADJ"} ,
		{"word":"spartiate","word_nosc":"spartiate","lemma":"spartiate","pos":"ADJ"} ,
		{"word":"spartiates","word_nosc":"spartiates","lemma":"spartiate","pos":"ADJ"} ,
		{"word":"spasmodique","word_nosc":"spasmodique","lemma":"spasmodique","pos":"ADJ"} ,
		{"word":"spasmodiques","word_nosc":"spasmodiques","lemma":"spasmodique","pos":"ADJ"} ,
		{"word":"spasmophile","word_nosc":"spasmophile","lemma":"spasmophile","pos":"ADJ"} ,
		{"word":"spastique","word_nosc":"spastique","lemma":"spastique","pos":"ADJ"} ,
		{"word":"spatial","word_nosc":"spatial","lemma":"spatial","pos":"ADJ"} ,
		{"word":"spatiale","word_nosc":"spatiale","lemma":"spatial","pos":"ADJ"} ,
		{"word":"spatiales","word_nosc":"spatiales","lemma":"spatial","pos":"ADJ"} ,
		{"word":"spatiaux","word_nosc":"spatiaux","lemma":"spatial","pos":"ADJ"} ,
		{"word":"spatio-temporel","word_nosc":"spatio-temporel","lemma":"spatio-temporel","pos":"ADJ"} ,
		{"word":"spatio-temporelle","word_nosc":"spatio-temporelle","lemma":"spatio-temporel","pos":"ADJ"} ,
		{"word":"spatiotemporel","word_nosc":"spatiotemporel","lemma":"spatiotemporel","pos":"ADJ"} ,
		{"word":"spatulée","word_nosc":"spatulee","lemma":"spatulé","pos":"ADJ"} ,
		{"word":"spatulées","word_nosc":"spatulees","lemma":"spatulé","pos":"ADJ"} ,
		{"word":"spatulés","word_nosc":"spatules","lemma":"spatulé","pos":"ADJ"} ,
		{"word":"spectaculaire","word_nosc":"spectaculaire","lemma":"spectaculaire","pos":"ADJ"} ,
		{"word":"spectaculaires","word_nosc":"spectaculaires","lemma":"spectaculaire","pos":"ADJ"} ,
		{"word":"spectral","word_nosc":"spectral","lemma":"spectral","pos":"ADJ"} ,
		{"word":"spectrale","word_nosc":"spectrale","lemma":"spectral","pos":"ADJ"} ,
		{"word":"spectrales","word_nosc":"spectrales","lemma":"spectral","pos":"ADJ"} ,
		{"word":"spectraux","word_nosc":"spectraux","lemma":"spectral","pos":"ADJ"} ,
		{"word":"spectrographique","word_nosc":"spectrographique","lemma":"spectrographique","pos":"ADJ"} ,
		{"word":"spectrométrique","word_nosc":"spectrometrique","lemma":"spectrométrique","pos":"ADJ"} ,
		{"word":"speedé","word_nosc":"speede","lemma":"speedé","pos":"ADJ"} ,
		{"word":"speedée","word_nosc":"speedee","lemma":"speedé","pos":"ADJ"} ,
		{"word":"speedés","word_nosc":"speedes","lemma":"speedé","pos":"ADJ"} ,
		{"word":"spermatique","word_nosc":"spermatique","lemma":"spermatique","pos":"ADJ"} ,
		{"word":"spermicide","word_nosc":"spermicide","lemma":"spermicide","pos":"ADJ"} ,
		{"word":"sphénoïde","word_nosc":"sphenoide","lemma":"sphénoïde","pos":"ADJ"} ,
		{"word":"sphérique","word_nosc":"spherique","lemma":"sphérique","pos":"ADJ"} ,
		{"word":"sphériques","word_nosc":"spheriques","lemma":"sphérique","pos":"ADJ"} ,
		{"word":"spinal","word_nosc":"spinal","lemma":"spinal","pos":"ADJ"} ,
		{"word":"spinale","word_nosc":"spinale","lemma":"spinal","pos":"ADJ"} ,
		{"word":"spinaux","word_nosc":"spinaux","lemma":"spinal","pos":"ADJ"} ,
		{"word":"spirale","word_nosc":"spirale","lemma":"spiral","pos":"ADJ"} ,
		{"word":"spirales","word_nosc":"spirales","lemma":"spiral","pos":"ADJ"} ,
		{"word":"spiralé","word_nosc":"spirale","lemma":"spiralé","pos":"ADJ"} ,
		{"word":"spiralées","word_nosc":"spiralees","lemma":"spiralé","pos":"ADJ"} ,
		{"word":"spiralés","word_nosc":"spirales","lemma":"spiralé","pos":"ADJ"} ,
		{"word":"spirite","word_nosc":"spirite","lemma":"spirite","pos":"ADJ"} ,
		{"word":"spirites","word_nosc":"spirites","lemma":"spirite","pos":"ADJ"} ,
		{"word":"spiritualiste","word_nosc":"spiritualiste","lemma":"spiritualiste","pos":"ADJ"} ,
		{"word":"spiritualistes","word_nosc":"spiritualistes","lemma":"spiritualiste","pos":"ADJ"} ,
		{"word":"spirituel","word_nosc":"spirituel","lemma":"spirituel","pos":"ADJ"} ,
		{"word":"spirituelle","word_nosc":"spirituelle","lemma":"spirituel","pos":"ADJ"} ,
		{"word":"spirituelles","word_nosc":"spirituelles","lemma":"spirituel","pos":"ADJ"} ,
		{"word":"spirituels","word_nosc":"spirituels","lemma":"spirituel","pos":"ADJ"} ,
		{"word":"spiritueux","word_nosc":"spiritueux","lemma":"spiritueux","pos":"ADJ"} ,
		{"word":"splendide","word_nosc":"splendide","lemma":"splendide","pos":"ADJ"} ,
		{"word":"splendides","word_nosc":"splendides","lemma":"splendide","pos":"ADJ"} ,
		{"word":"spongieuse","word_nosc":"spongieuse","lemma":"spongieux","pos":"ADJ"} ,
		{"word":"spongieuses","word_nosc":"spongieuses","lemma":"spongieux","pos":"ADJ"} ,
		{"word":"spongieux","word_nosc":"spongieux","lemma":"spongieux","pos":"ADJ"} ,
		{"word":"spongiforme","word_nosc":"spongiforme","lemma":"spongiforme","pos":"ADJ"} ,
		{"word":"sponsorisé","word_nosc":"sponsorise","lemma":"sponsorisé","pos":"ADJ"} ,
		{"word":"spontané","word_nosc":"spontane","lemma":"spontané","pos":"ADJ"} ,
		{"word":"spontanée","word_nosc":"spontanee","lemma":"spontané","pos":"ADJ"} ,
		{"word":"spontanées","word_nosc":"spontanees","lemma":"spontané","pos":"ADJ"} ,
		{"word":"spontanéistes","word_nosc":"spontaneistes","lemma":"spontanéiste","pos":"ADJ"} ,
		{"word":"spontanés","word_nosc":"spontanes","lemma":"spontané","pos":"ADJ"} ,
		{"word":"sporadique","word_nosc":"sporadique","lemma":"sporadique","pos":"ADJ"} ,
		{"word":"sporadiques","word_nosc":"sporadiques","lemma":"sporadique","pos":"ADJ"} ,
		{"word":"sportif","word_nosc":"sportif","lemma":"sportif","pos":"ADJ"} ,
		{"word":"sportifs","word_nosc":"sportifs","lemma":"sportif","pos":"ADJ"} ,
		{"word":"sportive","word_nosc":"sportive","lemma":"sportif","pos":"ADJ"} ,
		{"word":"sportives","word_nosc":"sportives","lemma":"sportif","pos":"ADJ"} ,
		{"word":"spumescent","word_nosc":"spumescent","lemma":"spumescent","pos":"ADJ"} ,
		{"word":"spécial","word_nosc":"special","lemma":"spécial","pos":"ADJ"} ,
		{"word":"spéciale","word_nosc":"speciale","lemma":"spécial","pos":"ADJ"} ,
		{"word":"spéciales","word_nosc":"speciales","lemma":"spécial","pos":"ADJ"} ,
		{"word":"spécialisé","word_nosc":"specialise","lemma":"spécialisé","pos":"ADJ"} ,
		{"word":"spécialisée","word_nosc":"specialisee","lemma":"spécialisé","pos":"ADJ"} ,
		{"word":"spécialisées","word_nosc":"specialisees","lemma":"spécialisé","pos":"ADJ"} ,
		{"word":"spécialisés","word_nosc":"specialises","lemma":"spécialisé","pos":"ADJ"} ,
		{"word":"spéciaux","word_nosc":"speciaux","lemma":"spécial","pos":"ADJ"} ,
		{"word":"spécieuse","word_nosc":"specieuse","lemma":"spécieux","pos":"ADJ"} ,
		{"word":"spécieuses","word_nosc":"specieuses","lemma":"spécieux","pos":"ADJ"} ,
		{"word":"spécieux","word_nosc":"specieux","lemma":"spécieux","pos":"ADJ"} ,
		{"word":"spécifique","word_nosc":"specifique","lemma":"spécifique","pos":"ADJ"} ,
		{"word":"spécifiques","word_nosc":"specifiques","lemma":"spécifique","pos":"ADJ"} ,
		{"word":"spécifié","word_nosc":"specifie","lemma":"spécifié","pos":"ADJ"} ,
		{"word":"spécifiée","word_nosc":"specifiee","lemma":"spécifié","pos":"ADJ"} ,
		{"word":"spécifiées","word_nosc":"specifiees","lemma":"spécifié","pos":"ADJ"} ,
		{"word":"spécifiés","word_nosc":"specifies","lemma":"spécifié","pos":"ADJ"} ,
		{"word":"spéculaire","word_nosc":"speculaire","lemma":"spéculaire","pos":"ADJ"} ,
		{"word":"spéculatif","word_nosc":"speculatif","lemma":"spéculatif","pos":"ADJ"} ,
		{"word":"spéculatifs","word_nosc":"speculatifs","lemma":"spéculatif","pos":"ADJ"} ,
		{"word":"spéculative","word_nosc":"speculative","lemma":"spéculatif","pos":"ADJ"} ,
		{"word":"spéculatives","word_nosc":"speculatives","lemma":"spéculatif","pos":"ADJ"} ,
		{"word":"squameuse","word_nosc":"squameuse","lemma":"squameux","pos":"ADJ"} ,
		{"word":"squameux","word_nosc":"squameux","lemma":"squameux","pos":"ADJ"} ,
		{"word":"squatteur","word_nosc":"squatteur","lemma":"squatteur","pos":"ADJ"} ,
		{"word":"squelettique","word_nosc":"squelettique","lemma":"squelettique","pos":"ADJ"} ,
		{"word":"squelettiques","word_nosc":"squelettiques","lemma":"squelettique","pos":"ADJ"} ,
		{"word":"sri lankais","word_nosc":"sri lankais","lemma":"sri lankais","pos":"ADJ"} ,
		{"word":"stabilisante","word_nosc":"stabilisante","lemma":"stabilisant","pos":"ADJ"} ,
		{"word":"stabilisateur","word_nosc":"stabilisateur","lemma":"stabilisateur","pos":"ADJ"} ,
		{"word":"stabilisateurs","word_nosc":"stabilisateurs","lemma":"stabilisateur","pos":"ADJ"} ,
		{"word":"stabilisatrice","word_nosc":"stabilisatrice","lemma":"stabilisateur","pos":"ADJ"} ,
		{"word":"stabilisé","word_nosc":"stabilise","lemma":"stabilisé","pos":"ADJ"} ,
		{"word":"stabilisée","word_nosc":"stabilisee","lemma":"stabilisé","pos":"ADJ"} ,
		{"word":"stabilisées","word_nosc":"stabilisees","lemma":"stabilisé","pos":"ADJ"} ,
		{"word":"stabilisés","word_nosc":"stabilises","lemma":"stabilisé","pos":"ADJ"} ,
		{"word":"stable","word_nosc":"stable","lemma":"stable","pos":"ADJ"} ,
		{"word":"stables","word_nosc":"stables","lemma":"stable","pos":"ADJ"} ,
		{"word":"stagiaire","word_nosc":"stagiaire","lemma":"stagiaire","pos":"ADJ"} ,
		{"word":"stagiaires","word_nosc":"stagiaires","lemma":"stagiaire","pos":"ADJ"} ,
		{"word":"stagnant","word_nosc":"stagnant","lemma":"stagnant","pos":"ADJ"} ,
		{"word":"stagnante","word_nosc":"stagnante","lemma":"stagnant","pos":"ADJ"} ,
		{"word":"stagnantes","word_nosc":"stagnantes","lemma":"stagnant","pos":"ADJ"} ,
		{"word":"stakhanoviste","word_nosc":"stakhanoviste","lemma":"stakhanoviste","pos":"ADJ"} ,
		{"word":"stalinien","word_nosc":"stalinien","lemma":"stalinien","pos":"ADJ"} ,
		{"word":"stalinienne","word_nosc":"stalinienne","lemma":"stalinien","pos":"ADJ"} ,
		{"word":"staliniennes","word_nosc":"staliniennes","lemma":"stalinien","pos":"ADJ"} ,
		{"word":"staliniens","word_nosc":"staliniens","lemma":"stalinien","pos":"ADJ"} ,
		{"word":"standard","word_nosc":"standard","lemma":"standard","pos":"ADJ"} ,
		{"word":"standardisé","word_nosc":"standardise","lemma":"standardisé","pos":"ADJ"} ,
		{"word":"standardisée","word_nosc":"standardisee","lemma":"standardisé","pos":"ADJ"} ,
		{"word":"standardisées","word_nosc":"standardisees","lemma":"standardisé","pos":"ADJ"} ,
		{"word":"standardisés","word_nosc":"standardises","lemma":"standardisé","pos":"ADJ"} ,
		{"word":"staphylococcique","word_nosc":"staphylococcique","lemma":"staphylococcique","pos":"ADJ"} ,
		{"word":"stat","word_nosc":"stat","lemma":"stat","pos":"ADJ"} ,
		{"word":"stationnaire","word_nosc":"stationnaire","lemma":"stationnaire","pos":"ADJ"} ,
		{"word":"statique","word_nosc":"statique","lemma":"statique","pos":"ADJ"} ,
		{"word":"statiques","word_nosc":"statiques","lemma":"statique","pos":"ADJ"} ,
		{"word":"statistique","word_nosc":"statistique","lemma":"statistique","pos":"ADJ"} ,
		{"word":"statistiques","word_nosc":"statistiques","lemma":"statistique","pos":"ADJ"} ,
		{"word":"statuaire","word_nosc":"statuaire","lemma":"statuaire","pos":"ADJ"} ,
		{"word":"staturo-pondéral","word_nosc":"staturo-ponderal","lemma":"staturo-pondéral","pos":"ADJ"} ,
		{"word":"statutaire","word_nosc":"statutaire","lemma":"statutaire","pos":"ADJ"} ,
		{"word":"stellaire","word_nosc":"stellaire","lemma":"stellaire","pos":"ADJ"} ,
		{"word":"stellaires","word_nosc":"stellaires","lemma":"stellaire","pos":"ADJ"} ,
		{"word":"stendhalien","word_nosc":"stendhalien","lemma":"stendhalien","pos":"ADJ"} ,
		{"word":"stercoraire","word_nosc":"stercoraire","lemma":"stercoraire","pos":"ADJ"} ,
		{"word":"sterling","word_nosc":"sterling","lemma":"sterling","pos":"ADJ"} ,
		{"word":"sternal","word_nosc":"sternal","lemma":"sternal","pos":"ADJ"} ,
		{"word":"sternale","word_nosc":"sternale","lemma":"sternal","pos":"ADJ"} ,
		{"word":"sterno-cléido-mastoïdien","word_nosc":"sterno-cleido-mastoidien","lemma":"sterno-cléido-mastoïdien","pos":"ADJ"} ,
		{"word":"stigmatisé","word_nosc":"stigmatise","lemma":"stigmatisé","pos":"ADJ"} ,
		{"word":"stigmatisée","word_nosc":"stigmatisee","lemma":"stigmatisé","pos":"ADJ"} ,
		{"word":"stigmatisés","word_nosc":"stigmatises","lemma":"stigmatisé","pos":"ADJ"} ,
		{"word":"stimulant","word_nosc":"stimulant","lemma":"stimulant","pos":"ADJ"} ,
		{"word":"stimulante","word_nosc":"stimulante","lemma":"stimulant","pos":"ADJ"} ,
		{"word":"stimulantes","word_nosc":"stimulantes","lemma":"stimulant","pos":"ADJ"} ,
		{"word":"stimulants","word_nosc":"stimulants","lemma":"stimulant","pos":"ADJ"} ,
		{"word":"stipendiés","word_nosc":"stipendies","lemma":"stipendié","pos":"ADJ"} ,
		{"word":"stipulant","word_nosc":"stipulant","lemma":"stipulant","pos":"ADJ"} ,
		{"word":"stipulé","word_nosc":"stipule","lemma":"stipulé","pos":"ADJ"} ,
		{"word":"stipulée","word_nosc":"stipulee","lemma":"stipulé","pos":"ADJ"} ,
		{"word":"stipulées","word_nosc":"stipulees","lemma":"stipulé","pos":"ADJ"} ,
		{"word":"stochastique","word_nosc":"stochastique","lemma":"stochastique","pos":"ADJ"} ,
		{"word":"stomacal","word_nosc":"stomacal","lemma":"stomacal","pos":"ADJ"} ,
		{"word":"stomacale","word_nosc":"stomacale","lemma":"stomacal","pos":"ADJ"} ,
		{"word":"stomacales","word_nosc":"stomacales","lemma":"stomacal","pos":"ADJ"} ,
		{"word":"stomacaux","word_nosc":"stomacaux","lemma":"stomacal","pos":"ADJ"} ,
		{"word":"stone","word_nosc":"stone","lemma":"stone","pos":"ADJ"} ,
		{"word":"stones","word_nosc":"stones","lemma":"stone","pos":"ADJ"} ,
		{"word":"stoïcien","word_nosc":"stoicien","lemma":"stoïcien","pos":"ADJ"} ,
		{"word":"stoïque","word_nosc":"stoique","lemma":"stoïque","pos":"ADJ"} ,
		{"word":"stoïques","word_nosc":"stoiques","lemma":"stoïque","pos":"ADJ"} ,
		{"word":"straight","word_nosc":"straight","lemma":"straight","pos":"ADJ"} ,
		{"word":"strasbourgeois","word_nosc":"strasbourgeois","lemma":"strasbourgeois","pos":"ADJ"} ,
		{"word":"strasbourgeoise","word_nosc":"strasbourgeoise","lemma":"strasbourgeois","pos":"ADJ"} ,
		{"word":"stratifié","word_nosc":"stratifie","lemma":"stratifié","pos":"ADJ"} ,
		{"word":"stratifiée","word_nosc":"stratifiee","lemma":"stratifié","pos":"ADJ"} ,
		{"word":"stratifiées","word_nosc":"stratifiees","lemma":"stratifié","pos":"ADJ"} ,
		{"word":"stratigraphique","word_nosc":"stratigraphique","lemma":"stratigraphique","pos":"ADJ"} ,
		{"word":"stratosphérique","word_nosc":"stratospherique","lemma":"stratosphérique","pos":"ADJ"} ,
		{"word":"stratosphériques","word_nosc":"stratospheriques","lemma":"stratosphérique","pos":"ADJ"} ,
		{"word":"stratégique","word_nosc":"strategique","lemma":"stratégique","pos":"ADJ"} ,
		{"word":"stratégiques","word_nosc":"strategiques","lemma":"stratégique","pos":"ADJ"} ,
		{"word":"streptococcique","word_nosc":"streptococcique","lemma":"streptococcique","pos":"ADJ"} ,
		{"word":"stressant","word_nosc":"stressant","lemma":"stressant","pos":"ADJ"} ,
		{"word":"stressante","word_nosc":"stressante","lemma":"stressant","pos":"ADJ"} ,
		{"word":"stressantes","word_nosc":"stressantes","lemma":"stressant","pos":"ADJ"} ,
		{"word":"stressants","word_nosc":"stressants","lemma":"stressant","pos":"ADJ"} ,
		{"word":"strict","word_nosc":"strict","lemma":"strict","pos":"ADJ"} ,
		{"word":"stricte","word_nosc":"stricte","lemma":"strict","pos":"ADJ"} ,
		{"word":"strictes","word_nosc":"strictes","lemma":"strict","pos":"ADJ"} ,
		{"word":"stricts","word_nosc":"stricts","lemma":"strict","pos":"ADJ"} ,
		{"word":"strident","word_nosc":"strident","lemma":"strident","pos":"ADJ"} ,
		{"word":"stridente","word_nosc":"stridente","lemma":"strident","pos":"ADJ"} ,
		{"word":"stridentes","word_nosc":"stridentes","lemma":"strident","pos":"ADJ"} ,
		{"word":"stridents","word_nosc":"stridents","lemma":"strident","pos":"ADJ"} ,
		{"word":"striduleuse","word_nosc":"striduleuse","lemma":"striduleux","pos":"ADJ"} ,
		{"word":"strié","word_nosc":"strie","lemma":"strié","pos":"ADJ"} ,
		{"word":"striée","word_nosc":"striee","lemma":"strié","pos":"ADJ"} ,
		{"word":"striées","word_nosc":"striees","lemma":"strié","pos":"ADJ"} ,
		{"word":"striés","word_nosc":"stries","lemma":"strié","pos":"ADJ"} ,
		{"word":"stroboscopique","word_nosc":"stroboscopique","lemma":"stroboscopique","pos":"ADJ"} ,
		{"word":"structural","word_nosc":"structural","lemma":"structural","pos":"ADJ"} ,
		{"word":"structurale","word_nosc":"structurale","lemma":"structural","pos":"ADJ"} ,
		{"word":"structurales","word_nosc":"structurales","lemma":"structural","pos":"ADJ"} ,
		{"word":"structuraliste","word_nosc":"structuraliste","lemma":"structuraliste","pos":"ADJ"} ,
		{"word":"structuralistes","word_nosc":"structuralistes","lemma":"structuraliste","pos":"ADJ"} ,
		{"word":"structuraux","word_nosc":"structuraux","lemma":"structural","pos":"ADJ"} ,
		{"word":"structurel","word_nosc":"structurel","lemma":"structurel","pos":"ADJ"} ,
		{"word":"structurelle","word_nosc":"structurelle","lemma":"structurel","pos":"ADJ"} ,
		{"word":"structurels","word_nosc":"structurels","lemma":"structurel","pos":"ADJ"} ,
		{"word":"structuré","word_nosc":"structure","lemma":"structuré","pos":"ADJ"} ,
		{"word":"structurée","word_nosc":"structuree","lemma":"structuré","pos":"ADJ"} ,
		{"word":"structurés","word_nosc":"structures","lemma":"structuré","pos":"ADJ"} ,
		{"word":"studieuse","word_nosc":"studieuse","lemma":"studieux","pos":"ADJ"} ,
		{"word":"studieuses","word_nosc":"studieuses","lemma":"studieux","pos":"ADJ"} ,
		{"word":"studieux","word_nosc":"studieux","lemma":"studieux","pos":"ADJ"} ,
		{"word":"stupide","word_nosc":"stupide","lemma":"stupide","pos":"ADJ"} ,
		{"word":"stupides","word_nosc":"stupides","lemma":"stupide","pos":"ADJ"} ,
		{"word":"stuporeuse","word_nosc":"stuporeuse","lemma":"stuporeux","pos":"ADJ"} ,
		{"word":"stups","word_nosc":"stups","lemma":"stups","pos":"ADJ"} ,
		{"word":"stupéfait","word_nosc":"stupefait","lemma":"stupéfait","pos":"ADJ"} ,
		{"word":"stupéfaite","word_nosc":"stupefaite","lemma":"stupéfait","pos":"ADJ"} ,
		{"word":"stupéfaites","word_nosc":"stupefaites","lemma":"stupéfait","pos":"ADJ"} ,
		{"word":"stupéfaits","word_nosc":"stupefaits","lemma":"stupéfait","pos":"ADJ"} ,
		{"word":"stupéfiant","word_nosc":"stupefiant","lemma":"stupéfiant","pos":"ADJ"} ,
		{"word":"stupéfiante","word_nosc":"stupefiante","lemma":"stupéfiant","pos":"ADJ"} ,
		{"word":"stupéfiantes","word_nosc":"stupefiantes","lemma":"stupéfiant","pos":"ADJ"} ,
		{"word":"stupéfiants","word_nosc":"stupefiants","lemma":"stupéfiant","pos":"ADJ"} ,
		{"word":"stygien","word_nosc":"stygien","lemma":"stygien","pos":"ADJ"} ,
		{"word":"stylistique","word_nosc":"stylistique","lemma":"stylistique","pos":"ADJ"} ,
		{"word":"stylistiques","word_nosc":"stylistiques","lemma":"stylistique","pos":"ADJ"} ,
		{"word":"styloïde","word_nosc":"styloide","lemma":"styloïde","pos":"ADJ"} ,
		{"word":"stylé","word_nosc":"style","lemma":"stylé","pos":"ADJ"} ,
		{"word":"stylée","word_nosc":"stylee","lemma":"stylé","pos":"ADJ"} ,
		{"word":"stylées","word_nosc":"stylees","lemma":"stylé","pos":"ADJ"} ,
		{"word":"stylés","word_nosc":"styles","lemma":"stylé","pos":"ADJ"} ,
		{"word":"stéarique","word_nosc":"stearique","lemma":"stéarique","pos":"ADJ"} ,
		{"word":"stéatopyges","word_nosc":"steatopyges","lemma":"stéatopyge","pos":"ADJ"} ,
		{"word":"sténographique","word_nosc":"stenographique","lemma":"sténographique","pos":"ADJ"} ,
		{"word":"sténographiques","word_nosc":"stenographiques","lemma":"sténographique","pos":"ADJ"} ,
		{"word":"stérile","word_nosc":"sterile","lemma":"stérile","pos":"ADJ"} ,
		{"word":"stériles","word_nosc":"steriles","lemma":"stérile","pos":"ADJ"} ,
		{"word":"stérilisant","word_nosc":"sterilisant","lemma":"stérilisant","pos":"ADJ"} ,
		{"word":"stérilisante","word_nosc":"sterilisante","lemma":"stérilisant","pos":"ADJ"} ,
		{"word":"stérilisantes","word_nosc":"sterilisantes","lemma":"stérilisant","pos":"ADJ"} ,
		{"word":"stérilisé","word_nosc":"sterilise","lemma":"stérilisé","pos":"ADJ"} ,
		{"word":"stérilisée","word_nosc":"sterilisee","lemma":"stérilisé","pos":"ADJ"} ,
		{"word":"stérilisées","word_nosc":"sterilisees","lemma":"stérilisé","pos":"ADJ"} ,
		{"word":"stérilisés","word_nosc":"sterilises","lemma":"stérilisé","pos":"ADJ"} ,
		{"word":"stéroïde","word_nosc":"steroide","lemma":"stéroïde","pos":"ADJ"} ,
		{"word":"stéroïdes","word_nosc":"steroides","lemma":"stéroïde","pos":"ADJ"} ,
		{"word":"stéréophonique","word_nosc":"stereophonique","lemma":"stéréophonique","pos":"ADJ"} ,
		{"word":"stéréoscopique","word_nosc":"stereoscopique","lemma":"stéréoscopique","pos":"ADJ"} ,
		{"word":"stéréotypé","word_nosc":"stereotype","lemma":"stéréotypé","pos":"ADJ"} ,
		{"word":"stéréotypée","word_nosc":"stereotypee","lemma":"stéréotypé","pos":"ADJ"} ,
		{"word":"stéréotypées","word_nosc":"stereotypees","lemma":"stéréotypé","pos":"ADJ"} ,
		{"word":"stéréotypés","word_nosc":"stereotypes","lemma":"stéréotypé","pos":"ADJ"} ,
		{"word":"su","word_nosc":"su","lemma":"su","pos":"ADJ"} ,
		{"word":"suant","word_nosc":"suant","lemma":"suant","pos":"ADJ"} ,
		{"word":"suante","word_nosc":"suante","lemma":"suant","pos":"ADJ"} ,
		{"word":"suantes","word_nosc":"suantes","lemma":"suant","pos":"ADJ"} ,
		{"word":"suants","word_nosc":"suants","lemma":"suant","pos":"ADJ"} ,
		{"word":"suave","word_nosc":"suave","lemma":"suave","pos":"ADJ"} ,
		{"word":"suaves","word_nosc":"suaves","lemma":"suave","pos":"ADJ"} ,
		{"word":"sub-aquatique","word_nosc":"sub-aquatique","lemma":"sub-aquatique","pos":"ADJ"} ,
		{"word":"sub-atomique","word_nosc":"sub-atomique","lemma":"sub-atomique","pos":"ADJ"} ,
		{"word":"sub-atomiques","word_nosc":"sub-atomiques","lemma":"sub-atomique","pos":"ADJ"} ,
		{"word":"sub-claquant","word_nosc":"sub-claquant","lemma":"sub-claquant","pos":"ADJ"} ,
		{"word":"sub-normaux","word_nosc":"sub-normaux","lemma":"sub-normaux","pos":"ADJ"} ,
		{"word":"sub-nucléaires","word_nosc":"sub-nucleaires","lemma":"sub-nucléaire","pos":"ADJ"} ,
		{"word":"sub-vocal","word_nosc":"sub-vocal","lemma":"sub-vocal","pos":"ADJ"} ,
		{"word":"sub-véhiculaire","word_nosc":"sub-vehiculaire","lemma":"sub-véhiculaire","pos":"ADJ"} ,
		{"word":"subaiguë","word_nosc":"subaigue","lemma":"subaigu","pos":"ADJ"} ,
		{"word":"subalterne","word_nosc":"subalterne","lemma":"subalterne","pos":"ADJ"} ,
		{"word":"subalternes","word_nosc":"subalternes","lemma":"subalterne","pos":"ADJ"} ,
		{"word":"subaquatique","word_nosc":"subaquatique","lemma":"subaquatique","pos":"ADJ"} ,
		{"word":"subatomique","word_nosc":"subatomique","lemma":"subatomique","pos":"ADJ"} ,
		{"word":"subatomiques","word_nosc":"subatomiques","lemma":"subatomique","pos":"ADJ"} ,
		{"word":"subcarpatique","word_nosc":"subcarpatique","lemma":"subcarpatique","pos":"ADJ"} ,
		{"word":"subcellulaire","word_nosc":"subcellulaire","lemma":"subcellulaire","pos":"ADJ"} ,
		{"word":"subconscient","word_nosc":"subconscient","lemma":"subconscient","pos":"ADJ"} ,
		{"word":"subconsciente","word_nosc":"subconsciente","lemma":"subconscient","pos":"ADJ"} ,
		{"word":"subconscientes","word_nosc":"subconscientes","lemma":"subconscient","pos":"ADJ"} ,
		{"word":"subconscients","word_nosc":"subconscients","lemma":"subconscient","pos":"ADJ"} ,
		{"word":"subit","word_nosc":"subit","lemma":"subit","pos":"ADJ"} ,
		{"word":"subite","word_nosc":"subite","lemma":"subit","pos":"ADJ"} ,
		{"word":"subites","word_nosc":"subites","lemma":"subit","pos":"ADJ"} ,
		{"word":"subits","word_nosc":"subits","lemma":"subit","pos":"ADJ"} ,
		{"word":"subjectif","word_nosc":"subjectif","lemma":"subjectif","pos":"ADJ"} ,
		{"word":"subjectifs","word_nosc":"subjectifs","lemma":"subjectif","pos":"ADJ"} ,
		{"word":"subjective","word_nosc":"subjective","lemma":"subjectif","pos":"ADJ"} ,
		{"word":"subjectives","word_nosc":"subjectives","lemma":"subjectif","pos":"ADJ"} ,
		{"word":"subjectiviste","word_nosc":"subjectiviste","lemma":"subjectiviste","pos":"ADJ"} ,
		{"word":"sublimable","word_nosc":"sublimable","lemma":"sublimable","pos":"ADJ"} ,
		{"word":"sublime","word_nosc":"sublime","lemma":"sublime","pos":"ADJ"} ,
		{"word":"sublimes","word_nosc":"sublimes","lemma":"sublime","pos":"ADJ"} ,
		{"word":"subliminal","word_nosc":"subliminal","lemma":"subliminal","pos":"ADJ"} ,
		{"word":"subliminale","word_nosc":"subliminale","lemma":"subliminal","pos":"ADJ"} ,
		{"word":"subliminales","word_nosc":"subliminales","lemma":"subliminal","pos":"ADJ"} ,
		{"word":"subliminaux","word_nosc":"subliminaux","lemma":"subliminal","pos":"ADJ"} ,
		{"word":"sublimissime","word_nosc":"sublimissime","lemma":"sublimissime","pos":"ADJ"} ,
		{"word":"sublingual","word_nosc":"sublingual","lemma":"sublingual","pos":"ADJ"} ,
		{"word":"sublinguaux","word_nosc":"sublinguaux","lemma":"sublingual","pos":"ADJ"} ,
		{"word":"sublunaire","word_nosc":"sublunaire","lemma":"sublunaire","pos":"ADJ"} ,
		{"word":"sublunaires","word_nosc":"sublunaires","lemma":"sublunaire","pos":"ADJ"} ,
		{"word":"submergé","word_nosc":"submerge","lemma":"submergé","pos":"ADJ"} ,
		{"word":"submergée","word_nosc":"submergee","lemma":"submergé","pos":"ADJ"} ,
		{"word":"submergées","word_nosc":"submergees","lemma":"submergé","pos":"ADJ"} ,
		{"word":"submergés","word_nosc":"submerges","lemma":"submergé","pos":"ADJ"} ,
		{"word":"submersible","word_nosc":"submersible","lemma":"submersible","pos":"ADJ"} ,
		{"word":"submersibles","word_nosc":"submersibles","lemma":"submersible","pos":"ADJ"} ,
		{"word":"suborbitale","word_nosc":"suborbitale","lemma":"suborbital","pos":"ADJ"} ,
		{"word":"subordonné","word_nosc":"subordonne","lemma":"subordonné","pos":"ADJ"} ,
		{"word":"subordonnée","word_nosc":"subordonnee","lemma":"subordonné","pos":"ADJ"} ,
		{"word":"subordonnées","word_nosc":"subordonnees","lemma":"subordonné","pos":"ADJ"} ,
		{"word":"subordonnés","word_nosc":"subordonnes","lemma":"subordonné","pos":"ADJ"} ,
		{"word":"subreptice","word_nosc":"subreptice","lemma":"subreptice","pos":"ADJ"} ,
		{"word":"subreptices","word_nosc":"subreptices","lemma":"subreptice","pos":"ADJ"} ,
		{"word":"subsaharienne","word_nosc":"subsaharienne","lemma":"subsaharien","pos":"ADJ"} ,
		{"word":"subsidiaire","word_nosc":"subsidiaire","lemma":"subsidiaire","pos":"ADJ"} ,
		{"word":"subsidiaires","word_nosc":"subsidiaires","lemma":"subsidiaire","pos":"ADJ"} ,
		{"word":"subsistant","word_nosc":"subsistant","lemma":"subsistant","pos":"ADJ"} ,
		{"word":"subsistantes","word_nosc":"subsistantes","lemma":"subsistant","pos":"ADJ"} ,
		{"word":"subsistants","word_nosc":"subsistants","lemma":"subsistant","pos":"ADJ"} ,
		{"word":"subsonique","word_nosc":"subsonique","lemma":"subsonique","pos":"ADJ"} ,
		{"word":"substantiel","word_nosc":"substantiel","lemma":"substantiel","pos":"ADJ"} ,
		{"word":"substantielle","word_nosc":"substantielle","lemma":"substantiel","pos":"ADJ"} ,
		{"word":"substantielles","word_nosc":"substantielles","lemma":"substantiel","pos":"ADJ"} ,
		{"word":"substantiels","word_nosc":"substantiels","lemma":"substantiel","pos":"ADJ"} ,
		{"word":"substantif","word_nosc":"substantif","lemma":"substantif","pos":"ADJ"} ,
		{"word":"substantifique","word_nosc":"substantifique","lemma":"substantifique","pos":"ADJ"} ,
		{"word":"substantifs","word_nosc":"substantifs","lemma":"substantif","pos":"ADJ"} ,
		{"word":"subséquent","word_nosc":"subsequent","lemma":"subséquent","pos":"ADJ"} ,
		{"word":"subséquente","word_nosc":"subsequente","lemma":"subséquent","pos":"ADJ"} ,
		{"word":"subséquentes","word_nosc":"subsequentes","lemma":"subséquent","pos":"ADJ"} ,
		{"word":"subséquents","word_nosc":"subsequents","lemma":"subséquent","pos":"ADJ"} ,
		{"word":"subtil","word_nosc":"subtil","lemma":"subtil","pos":"ADJ"} ,
		{"word":"subtile","word_nosc":"subtile","lemma":"subtil","pos":"ADJ"} ,
		{"word":"subtiles","word_nosc":"subtiles","lemma":"subtil","pos":"ADJ"} ,
		{"word":"subtils","word_nosc":"subtils","lemma":"subtil","pos":"ADJ"} ,
		{"word":"subtropical","word_nosc":"subtropical","lemma":"subtropical","pos":"ADJ"} ,
		{"word":"subtropicale","word_nosc":"subtropicale","lemma":"subtropical","pos":"ADJ"} ,
		{"word":"subtropicales","word_nosc":"subtropicales","lemma":"subtropical","pos":"ADJ"} ,
		{"word":"suburbain","word_nosc":"suburbain","lemma":"suburbain","pos":"ADJ"} ,
		{"word":"suburbaine","word_nosc":"suburbaine","lemma":"suburbain","pos":"ADJ"} ,
		{"word":"suburbaines","word_nosc":"suburbaines","lemma":"suburbain","pos":"ADJ"} ,
		{"word":"suburbains","word_nosc":"suburbains","lemma":"suburbain","pos":"ADJ"} ,
		{"word":"subventionné","word_nosc":"subventionne","lemma":"subventionné","pos":"ADJ"} ,
		{"word":"subventionnée","word_nosc":"subventionnee","lemma":"subventionné","pos":"ADJ"} ,
		{"word":"subventionnées","word_nosc":"subventionnees","lemma":"subventionné","pos":"ADJ"} ,
		{"word":"subventionnés","word_nosc":"subventionnes","lemma":"subventionné","pos":"ADJ"} ,
		{"word":"subversif","word_nosc":"subversif","lemma":"subversif","pos":"ADJ"} ,
		{"word":"subversifs","word_nosc":"subversifs","lemma":"subversif","pos":"ADJ"} ,
		{"word":"subversive","word_nosc":"subversive","lemma":"subversif","pos":"ADJ"} ,
		{"word":"subversives","word_nosc":"subversives","lemma":"subversif","pos":"ADJ"} ,
		{"word":"successibles","word_nosc":"successibles","lemma":"successible","pos":"ADJ"} ,
		{"word":"successif","word_nosc":"successif","lemma":"successif","pos":"ADJ"} ,
		{"word":"successifs","word_nosc":"successifs","lemma":"successif","pos":"ADJ"} ,
		{"word":"successive","word_nosc":"successive","lemma":"successif","pos":"ADJ"} ,
		{"word":"successives","word_nosc":"successives","lemma":"successif","pos":"ADJ"} ,
		{"word":"successoral","word_nosc":"successoral","lemma":"successoral","pos":"ADJ"} ,
		{"word":"successorale","word_nosc":"successorale","lemma":"successoral","pos":"ADJ"} ,
		{"word":"succinct","word_nosc":"succinct","lemma":"succinct","pos":"ADJ"} ,
		{"word":"succincte","word_nosc":"succincte","lemma":"succinct","pos":"ADJ"} ,
		{"word":"succinctes","word_nosc":"succinctes","lemma":"succinct","pos":"ADJ"} ,
		{"word":"succulent","word_nosc":"succulent","lemma":"succulent","pos":"ADJ"} ,
		{"word":"succulente","word_nosc":"succulente","lemma":"succulent","pos":"ADJ"} ,
		{"word":"succulentes","word_nosc":"succulentes","lemma":"succulent","pos":"ADJ"} ,
		{"word":"succulents","word_nosc":"succulents","lemma":"succulent","pos":"ADJ"} ,
		{"word":"suceur","word_nosc":"suceur","lemma":"suceur","pos":"ADJ"} ,
		{"word":"suceurs","word_nosc":"suceurs","lemma":"suceur","pos":"ADJ"} ,
		{"word":"suceuse","word_nosc":"suceuse","lemma":"suceur","pos":"ADJ"} ,
		{"word":"suceuses","word_nosc":"suceuses","lemma":"suceur","pos":"ADJ"} ,
		{"word":"sucrier","word_nosc":"sucrier","lemma":"sucrier","pos":"ADJ"} ,
		{"word":"sucrins","word_nosc":"sucrins","lemma":"sucrin","pos":"ADJ"} ,
		{"word":"sucrière","word_nosc":"sucriere","lemma":"sucrier","pos":"ADJ"} ,
		{"word":"sucrières","word_nosc":"sucrieres","lemma":"sucrier","pos":"ADJ"} ,
		{"word":"sucré","word_nosc":"sucre","lemma":"sucré","pos":"ADJ"} ,
		{"word":"sucrée","word_nosc":"sucree","lemma":"sucré","pos":"ADJ"} ,
		{"word":"sucrées","word_nosc":"sucrees","lemma":"sucré","pos":"ADJ"} ,
		{"word":"sucrés","word_nosc":"sucres","lemma":"sucré","pos":"ADJ"} ,
		{"word":"sud","word_nosc":"sud","lemma":"sud","pos":"ADJ"} ,
		{"word":"sud-africain","word_nosc":"sud-africain","lemma":"sud-africain","pos":"ADJ"} ,
		{"word":"sud-africaine","word_nosc":"sud-africaine","lemma":"sud-africain","pos":"ADJ"} ,
		{"word":"sud-africaines","word_nosc":"sud-africaines","lemma":"sud-africain","pos":"ADJ"} ,
		{"word":"sud-africains","word_nosc":"sud-africains","lemma":"sud-africain","pos":"ADJ"} ,
		{"word":"sud-américain","word_nosc":"sud-americain","lemma":"sud-américain","pos":"ADJ"} ,
		{"word":"sud-américaine","word_nosc":"sud-americaine","lemma":"sud-américain","pos":"ADJ"} ,
		{"word":"sud-américaines","word_nosc":"sud-americaines","lemma":"sud-américain","pos":"ADJ"} ,
		{"word":"sud-américains","word_nosc":"sud-americains","lemma":"sud-américain","pos":"ADJ"} ,
		{"word":"sud-coréen","word_nosc":"sud-coreen","lemma":"sud-coréen","pos":"ADJ"} ,
		{"word":"sud-coréenne","word_nosc":"sud-coreenne","lemma":"sud-coréen","pos":"ADJ"} ,
		{"word":"sud-coréennes","word_nosc":"sud-coreennes","lemma":"sud-coréen","pos":"ADJ"} ,
		{"word":"sud-coréens","word_nosc":"sud-coreens","lemma":"sud-coréen","pos":"ADJ"} ,
		{"word":"sud-vietnamien","word_nosc":"sud-vietnamien","lemma":"sud-vietnamien","pos":"ADJ"} ,
		{"word":"sud-vietnamienne","word_nosc":"sud-vietnamienne","lemma":"sud-vietnamien","pos":"ADJ"} ,
		{"word":"sud-vietnamiens","word_nosc":"sud-vietnamiens","lemma":"sud-vietnamien","pos":"ADJ"} ,
		{"word":"sudatoires","word_nosc":"sudatoires","lemma":"sudatoire","pos":"ADJ"} ,
		{"word":"sudoripare","word_nosc":"sudoripare","lemma":"sudoripare","pos":"ADJ"} ,
		{"word":"sudoripares","word_nosc":"sudoripares","lemma":"sudoripare","pos":"ADJ"} ,
		{"word":"sudète","word_nosc":"sudete","lemma":"sudète","pos":"ADJ"} ,
		{"word":"sudètes","word_nosc":"sudetes","lemma":"sudète","pos":"ADJ"} ,
		{"word":"sue","word_nosc":"sue","lemma":"su","pos":"ADJ"} ,
		{"word":"sues","word_nosc":"sues","lemma":"su","pos":"ADJ"} ,
		{"word":"suffisant","word_nosc":"suffisant","lemma":"suffisant","pos":"ADJ"} ,
		{"word":"suffisante","word_nosc":"suffisante","lemma":"suffisant","pos":"ADJ"} ,
		{"word":"suffisantes","word_nosc":"suffisantes","lemma":"suffisant","pos":"ADJ"} ,
		{"word":"suffisants","word_nosc":"suffisants","lemma":"suffisant","pos":"ADJ"} ,
		{"word":"suffocant","word_nosc":"suffocant","lemma":"suffocant","pos":"ADJ"} ,
		{"word":"suffocante","word_nosc":"suffocante","lemma":"suffocant","pos":"ADJ"} ,
		{"word":"suffocantes","word_nosc":"suffocantes","lemma":"suffocant","pos":"ADJ"} ,
		{"word":"suffocants","word_nosc":"suffocants","lemma":"suffocant","pos":"ADJ"} ,
		{"word":"suggestif","word_nosc":"suggestif","lemma":"suggestif","pos":"ADJ"} ,
		{"word":"suggestifs","word_nosc":"suggestifs","lemma":"suggestif","pos":"ADJ"} ,
		{"word":"suggestive","word_nosc":"suggestive","lemma":"suggestif","pos":"ADJ"} ,
		{"word":"suggestives","word_nosc":"suggestives","lemma":"suggestif","pos":"ADJ"} ,
		{"word":"sui generis","word_nosc":"sui generis","lemma":"sui generis","pos":"ADJ"} ,
		{"word":"suicidaire","word_nosc":"suicidaire","lemma":"suicidaire","pos":"ADJ"} ,
		{"word":"suicidé","word_nosc":"suicide","lemma":"suicidé","pos":"ADJ"} ,
		{"word":"suicidée","word_nosc":"suicidee","lemma":"suicidé","pos":"ADJ"} ,
		{"word":"suicidées","word_nosc":"suicidees","lemma":"suicidé","pos":"ADJ"} ,
		{"word":"suicidés","word_nosc":"suicides","lemma":"suicidé","pos":"ADJ"} ,
		{"word":"suiffeuses","word_nosc":"suiffeuses","lemma":"suiffeux","pos":"ADJ"} ,
		{"word":"suiffeux","word_nosc":"suiffeux","lemma":"suiffeux","pos":"ADJ"} ,
		{"word":"suintant","word_nosc":"suintant","lemma":"suintant","pos":"ADJ"} ,
		{"word":"suintante","word_nosc":"suintante","lemma":"suintant","pos":"ADJ"} ,
		{"word":"suintantes","word_nosc":"suintantes","lemma":"suintant","pos":"ADJ"} ,
		{"word":"suintants","word_nosc":"suintants","lemma":"suintant","pos":"ADJ"} ,
		{"word":"suisse","word_nosc":"suisse","lemma":"suisse","pos":"ADJ"} ,
		{"word":"suisses","word_nosc":"suisses","lemma":"suisse","pos":"ADJ"} ,
		{"word":"suissesse","word_nosc":"suissesse","lemma":"suisse","pos":"ADJ"} ,
		{"word":"suitée","word_nosc":"suitee","lemma":"suitée","pos":"ADJ"} ,
		{"word":"suiv","word_nosc":"suiv","lemma":"suiv","pos":"ADJ"} ,
		{"word":"suivant","word_nosc":"suivant","lemma":"suivant","pos":"ADJ"} ,
		{"word":"suivante","word_nosc":"suivante","lemma":"suivant","pos":"ADJ"} ,
		{"word":"suivantes","word_nosc":"suivantes","lemma":"suivant","pos":"ADJ"} ,
		{"word":"suivants","word_nosc":"suivants","lemma":"suivant","pos":"ADJ"} ,
		{"word":"suiveurs","word_nosc":"suiveurs","lemma":"suiveur","pos":"ADJ"} ,
		{"word":"suiveuse","word_nosc":"suiveuse","lemma":"suiveur","pos":"ADJ"} ,
		{"word":"suiveuses","word_nosc":"suiveuses","lemma":"suiveur","pos":"ADJ"} ,
		{"word":"suivi","word_nosc":"suivi","lemma":"suivi","pos":"ADJ"} ,
		{"word":"suivie","word_nosc":"suivie","lemma":"suivi","pos":"ADJ"} ,
		{"word":"suivies","word_nosc":"suivies","lemma":"suivi","pos":"ADJ"} ,
		{"word":"suivis","word_nosc":"suivis","lemma":"suivi","pos":"ADJ"} ,
		{"word":"sujet","word_nosc":"sujet","lemma":"sujet","pos":"ADJ"} ,
		{"word":"sujets","word_nosc":"sujets","lemma":"sujet","pos":"ADJ"} ,
		{"word":"sujette","word_nosc":"sujette","lemma":"sujet","pos":"ADJ"} ,
		{"word":"sujettes","word_nosc":"sujettes","lemma":"sujet","pos":"ADJ"} ,
		{"word":"sulfaté","word_nosc":"sulfate","lemma":"sulfaté","pos":"ADJ"} ,
		{"word":"sulfatées","word_nosc":"sulfatees","lemma":"sulfaté","pos":"ADJ"} ,
		{"word":"sulfhydrique","word_nosc":"sulfhydrique","lemma":"sulfhydrique","pos":"ADJ"} ,
		{"word":"sulfonique","word_nosc":"sulfonique","lemma":"sulfonique","pos":"ADJ"} ,
		{"word":"sulfureuse","word_nosc":"sulfureuse","lemma":"sulfureux","pos":"ADJ"} ,
		{"word":"sulfureuses","word_nosc":"sulfureuses","lemma":"sulfureux","pos":"ADJ"} ,
		{"word":"sulfureux","word_nosc":"sulfureux","lemma":"sulfureux","pos":"ADJ"} ,
		{"word":"sulfurique","word_nosc":"sulfurique","lemma":"sulfurique","pos":"ADJ"} ,
		{"word":"sulfurisé","word_nosc":"sulfurise","lemma":"sulfurisé","pos":"ADJ"} ,
		{"word":"sulfuré","word_nosc":"sulfure","lemma":"sulfuré","pos":"ADJ"} ,
		{"word":"sulfurée","word_nosc":"sulfuree","lemma":"sulfuré","pos":"ADJ"} ,
		{"word":"sulpicien","word_nosc":"sulpicien","lemma":"sulpicien","pos":"ADJ"} ,
		{"word":"sulpicienne","word_nosc":"sulpicienne","lemma":"sulpicien","pos":"ADJ"} ,
		{"word":"sulpiciennes","word_nosc":"sulpiciennes","lemma":"sulpicien","pos":"ADJ"} ,
		{"word":"sumérien","word_nosc":"sumerien","lemma":"sumérien","pos":"ADJ"} ,
		{"word":"sunnites","word_nosc":"sunnites","lemma":"sunnite","pos":"ADJ"} ,
		{"word":"super","word_nosc":"super","lemma":"super","pos":"ADJ"} ,
		{"word":"super-pilote","word_nosc":"super-pilote","lemma":"super-pilote","pos":"ADJ"} ,
		{"word":"superbe","word_nosc":"superbe","lemma":"superbe","pos":"ADJ"} ,
		{"word":"superbes","word_nosc":"superbes","lemma":"superbe","pos":"ADJ"} ,
		{"word":"superficiel","word_nosc":"superficiel","lemma":"superficiel","pos":"ADJ"} ,
		{"word":"superficielle","word_nosc":"superficielle","lemma":"superficiel","pos":"ADJ"} ,
		{"word":"superficielles","word_nosc":"superficielles","lemma":"superficiel","pos":"ADJ"} ,
		{"word":"superficiels","word_nosc":"superficiels","lemma":"superficiel","pos":"ADJ"} ,
		{"word":"superfin","word_nosc":"superfin","lemma":"superfin","pos":"ADJ"} ,
		{"word":"superflu","word_nosc":"superflu","lemma":"superflu","pos":"ADJ"} ,
		{"word":"superflue","word_nosc":"superflue","lemma":"superflu","pos":"ADJ"} ,
		{"word":"superflues","word_nosc":"superflues","lemma":"superflu","pos":"ADJ"} ,
		{"word":"superflus","word_nosc":"superflus","lemma":"superflu","pos":"ADJ"} ,
		{"word":"superforteresses","word_nosc":"superforteresses","lemma":"superforteresse","pos":"ADJ"} ,
		{"word":"superfétatoire","word_nosc":"superfetatoire","lemma":"superfétatoire","pos":"ADJ"} ,
		{"word":"superfétatoires","word_nosc":"superfetatoires","lemma":"superfétatoire","pos":"ADJ"} ,
		{"word":"superlatif","word_nosc":"superlatif","lemma":"superlatif","pos":"ADJ"} ,
		{"word":"superlative","word_nosc":"superlative","lemma":"superlatif","pos":"ADJ"} ,
		{"word":"superposable","word_nosc":"superposable","lemma":"superposable","pos":"ADJ"} ,
		{"word":"superposables","word_nosc":"superposables","lemma":"superposable","pos":"ADJ"} ,
		{"word":"superposé","word_nosc":"superpose","lemma":"superposé","pos":"ADJ"} ,
		{"word":"superposée","word_nosc":"superposee","lemma":"superposé","pos":"ADJ"} ,
		{"word":"superposées","word_nosc":"superposees","lemma":"superposé","pos":"ADJ"} ,
		{"word":"superposés","word_nosc":"superposes","lemma":"superposé","pos":"ADJ"} ,
		{"word":"superpuissant","word_nosc":"superpuissant","lemma":"superpuissant","pos":"ADJ"} ,
		{"word":"superpuissante","word_nosc":"superpuissante","lemma":"superpuissant","pos":"ADJ"} ,
		{"word":"superpuissants","word_nosc":"superpuissants","lemma":"superpuissant","pos":"ADJ"} ,
		{"word":"supersonique","word_nosc":"supersonique","lemma":"supersonique","pos":"ADJ"} ,
		{"word":"supersoniques","word_nosc":"supersoniques","lemma":"supersonique","pos":"ADJ"} ,
		{"word":"superstitieuse","word_nosc":"superstitieuse","lemma":"superstitieux","pos":"ADJ"} ,
		{"word":"superstitieuses","word_nosc":"superstitieuses","lemma":"superstitieux","pos":"ADJ"} ,
		{"word":"superstitieux","word_nosc":"superstitieux","lemma":"superstitieux","pos":"ADJ"} ,
		{"word":"suppliant","word_nosc":"suppliant","lemma":"suppliant","pos":"ADJ"} ,
		{"word":"suppliante","word_nosc":"suppliante","lemma":"suppliant","pos":"ADJ"} ,
		{"word":"suppliantes","word_nosc":"suppliantes","lemma":"suppliant","pos":"ADJ"} ,
		{"word":"suppliants","word_nosc":"suppliants","lemma":"suppliant","pos":"ADJ"} ,
		{"word":"suppliciant","word_nosc":"suppliciant","lemma":"suppliciant","pos":"ADJ"} ,
		{"word":"suppliciantes","word_nosc":"suppliciantes","lemma":"suppliciant","pos":"ADJ"} ,
		{"word":"supplicié","word_nosc":"supplicie","lemma":"supplicié","pos":"ADJ"} ,
		{"word":"suppliciée","word_nosc":"suppliciee","lemma":"supplicié","pos":"ADJ"} ,
		{"word":"suppliciés","word_nosc":"supplicies","lemma":"supplicié","pos":"ADJ"} ,
		{"word":"suppléant","word_nosc":"suppleant","lemma":"suppléant","pos":"ADJ"} ,
		{"word":"suppléante","word_nosc":"suppleante","lemma":"suppléant","pos":"ADJ"} ,
		{"word":"suppléants","word_nosc":"suppleants","lemma":"suppléant","pos":"ADJ"} ,
		{"word":"supplémentaire","word_nosc":"supplementaire","lemma":"supplémentaire","pos":"ADJ"} ,
		{"word":"supplémentaires","word_nosc":"supplementaires","lemma":"supplémentaire","pos":"ADJ"} ,
		{"word":"supplétive","word_nosc":"suppletive","lemma":"supplétif","pos":"ADJ"} ,
		{"word":"supportable","word_nosc":"supportable","lemma":"supportable","pos":"ADJ"} ,
		{"word":"supportables","word_nosc":"supportables","lemma":"supportable","pos":"ADJ"} ,
		{"word":"supposable","word_nosc":"supposable","lemma":"supposable","pos":"ADJ"} ,
		{"word":"supposé","word_nosc":"suppose","lemma":"supposé","pos":"ADJ"} ,
		{"word":"supposée","word_nosc":"supposee","lemma":"supposé","pos":"ADJ"} ,
		{"word":"supposées","word_nosc":"supposees","lemma":"supposé","pos":"ADJ"} ,
		{"word":"supposés","word_nosc":"supposes","lemma":"supposé","pos":"ADJ"} ,
		{"word":"suppresseur","word_nosc":"suppresseur","lemma":"suppresseur","pos":"ADJ"} ,
		{"word":"suppurant","word_nosc":"suppurant","lemma":"suppurant","pos":"ADJ"} ,
		{"word":"suppurante","word_nosc":"suppurante","lemma":"suppurant","pos":"ADJ"} ,
		{"word":"suppurantes","word_nosc":"suppurantes","lemma":"suppurant","pos":"ADJ"} ,
		{"word":"supra-humain","word_nosc":"supra-humain","lemma":"supra-humain","pos":"ADJ"} ,
		{"word":"supraconductrice","word_nosc":"supraconductrice","lemma":"supraconducteur","pos":"ADJ"} ,
		{"word":"supranational","word_nosc":"supranational","lemma":"supranational","pos":"ADJ"} ,
		{"word":"supranationales","word_nosc":"supranationales","lemma":"supranational","pos":"ADJ"} ,
		{"word":"supranaturel","word_nosc":"supranaturel","lemma":"supranaturel","pos":"ADJ"} ,
		{"word":"supraterrestre","word_nosc":"supraterrestre","lemma":"supraterrestre","pos":"ADJ"} ,
		{"word":"suprême","word_nosc":"supreme","lemma":"suprême","pos":"ADJ"} ,
		{"word":"suprêmes","word_nosc":"supremes","lemma":"suprême","pos":"ADJ"} ,
		{"word":"supérieur","word_nosc":"superieur","lemma":"supérieur","pos":"ADJ"} ,
		{"word":"supérieure","word_nosc":"superieure","lemma":"supérieur","pos":"ADJ"} ,
		{"word":"supérieures","word_nosc":"superieures","lemma":"supérieur","pos":"ADJ"} ,
		{"word":"supérieurs","word_nosc":"superieurs","lemma":"supérieur","pos":"ADJ"} ,
		{"word":"sur","word_nosc":"sur","lemma":"sur","pos":"ADJ"} ,
		{"word":"surabondant","word_nosc":"surabondant","lemma":"surabondant","pos":"ADJ"} ,
		{"word":"surabondante","word_nosc":"surabondante","lemma":"surabondant","pos":"ADJ"} ,
		{"word":"surabondantes","word_nosc":"surabondantes","lemma":"surabondant","pos":"ADJ"} ,
		{"word":"suractivé","word_nosc":"suractive","lemma":"suractivé","pos":"ADJ"} ,
		{"word":"suractivée","word_nosc":"suractivee","lemma":"suractivé","pos":"ADJ"} ,
		{"word":"suraigu","word_nosc":"suraigu","lemma":"suraigu","pos":"ADJ"} ,
		{"word":"suraigus","word_nosc":"suraigus","lemma":"suraigu","pos":"ADJ"} ,
		{"word":"suraiguë","word_nosc":"suraigue","lemma":"suraigu","pos":"ADJ"} ,
		{"word":"suraiguës","word_nosc":"suraigues","lemma":"suraigu","pos":"ADJ"} ,
		{"word":"suralimentée","word_nosc":"suralimentee","lemma":"suralimenté","pos":"ADJ"} ,
		{"word":"suralimentés","word_nosc":"suralimentes","lemma":"suralimenté","pos":"ADJ"} ,
		{"word":"suranné","word_nosc":"suranne","lemma":"suranné","pos":"ADJ"} ,
		{"word":"surannée","word_nosc":"surannee","lemma":"suranné","pos":"ADJ"} ,
		{"word":"surannées","word_nosc":"surannees","lemma":"suranné","pos":"ADJ"} ,
		{"word":"surannés","word_nosc":"surannes","lemma":"suranné","pos":"ADJ"} ,
		{"word":"surbaissé","word_nosc":"surbaisse","lemma":"surbaissé","pos":"ADJ"} ,
		{"word":"surbaissée","word_nosc":"surbaissee","lemma":"surbaissé","pos":"ADJ"} ,
		{"word":"surbaissées","word_nosc":"surbaissees","lemma":"surbaissé","pos":"ADJ"} ,
		{"word":"surbaissés","word_nosc":"surbaisses","lemma":"surbaissé","pos":"ADJ"} ,
		{"word":"surbooké","word_nosc":"surbooke","lemma":"surbooké","pos":"ADJ"} ,
		{"word":"surbookée","word_nosc":"surbookee","lemma":"surbooké","pos":"ADJ"} ,
		{"word":"surbookées","word_nosc":"surbookees","lemma":"surbooké","pos":"ADJ"} ,
		{"word":"surbrodé","word_nosc":"surbrode","lemma":"surbrodé","pos":"ADJ"} ,
		{"word":"surdimensionné","word_nosc":"surdimensionne","lemma":"surdimensionné","pos":"ADJ"} ,
		{"word":"surdimensionnée","word_nosc":"surdimensionnee","lemma":"surdimensionné","pos":"ADJ"} ,
		{"word":"surdoué","word_nosc":"surdoue","lemma":"surdoué","pos":"ADJ"} ,
		{"word":"surdouée","word_nosc":"surdouee","lemma":"surdoué","pos":"ADJ"} ,
		{"word":"surdoués","word_nosc":"surdoues","lemma":"surdoué","pos":"ADJ"} ,
		{"word":"surdéveloppé","word_nosc":"surdeveloppe","lemma":"surdéveloppé","pos":"ADJ"} ,
		{"word":"surdéveloppée","word_nosc":"surdeveloppee","lemma":"surdéveloppé","pos":"ADJ"} ,
		{"word":"surdéveloppées","word_nosc":"surdeveloppees","lemma":"surdéveloppé","pos":"ADJ"} ,
		{"word":"surdéveloppés","word_nosc":"surdeveloppes","lemma":"surdéveloppé","pos":"ADJ"} ,
		{"word":"sure","word_nosc":"sure","lemma":"sur","pos":"ADJ"} ,
		{"word":"surencombrée","word_nosc":"surencombree","lemma":"surencombré","pos":"ADJ"} ,
		{"word":"surendettés","word_nosc":"surendettes","lemma":"surendetté","pos":"ADJ"} ,
		{"word":"surentraîné","word_nosc":"surentraine","lemma":"surentraîné","pos":"ADJ"} ,
		{"word":"surentraînées","word_nosc":"surentrainees","lemma":"surentraîné","pos":"ADJ"} ,
		{"word":"surentraînés","word_nosc":"surentraines","lemma":"surentraîné","pos":"ADJ"} ,
		{"word":"sures","word_nosc":"sures","lemma":"sur","pos":"ADJ"} ,
		{"word":"suret","word_nosc":"suret","lemma":"suret","pos":"ADJ"} ,
		{"word":"surets","word_nosc":"surets","lemma":"suret","pos":"ADJ"} ,
		{"word":"surette","word_nosc":"surette","lemma":"suret","pos":"ADJ"} ,
		{"word":"surfait","word_nosc":"surfait","lemma":"surfait","pos":"ADJ"} ,
		{"word":"surfaite","word_nosc":"surfaite","lemma":"surfait","pos":"ADJ"} ,
		{"word":"surfaites","word_nosc":"surfaites","lemma":"surfait","pos":"ADJ"} ,
		{"word":"surfaits","word_nosc":"surfaits","lemma":"surfait","pos":"ADJ"} ,
		{"word":"surfin","word_nosc":"surfin","lemma":"surfin","pos":"ADJ"} ,
		{"word":"surfine","word_nosc":"surfine","lemma":"surfin","pos":"ADJ"} ,
		{"word":"surgelé","word_nosc":"surgele","lemma":"surgelé","pos":"ADJ"} ,
		{"word":"surgelée","word_nosc":"surgelee","lemma":"surgelé","pos":"ADJ"} ,
		{"word":"surgelées","word_nosc":"surgelees","lemma":"surgelé","pos":"ADJ"} ,
		{"word":"surgelés","word_nosc":"surgeles","lemma":"surgelé","pos":"ADJ"} ,
		{"word":"surhumain","word_nosc":"surhumain","lemma":"surhumain","pos":"ADJ"} ,
		{"word":"surhumaine","word_nosc":"surhumaine","lemma":"surhumain","pos":"ADJ"} ,
		{"word":"surhumaines","word_nosc":"surhumaines","lemma":"surhumain","pos":"ADJ"} ,
		{"word":"surhumains","word_nosc":"surhumains","lemma":"surhumain","pos":"ADJ"} ,
		{"word":"suri","word_nosc":"suri","lemma":"suri","pos":"ADJ"} ,
		{"word":"surie","word_nosc":"surie","lemma":"suri","pos":"ADJ"} ,
		{"word":"suries","word_nosc":"suries","lemma":"suri","pos":"ADJ"} ,
		{"word":"surmenant","word_nosc":"surmenant","lemma":"surmenant","pos":"ADJ"} ,
		{"word":"surmené","word_nosc":"surmene","lemma":"surmené","pos":"ADJ"} ,
		{"word":"surmenée","word_nosc":"surmenee","lemma":"surmené","pos":"ADJ"} ,
		{"word":"surmenées","word_nosc":"surmenees","lemma":"surmené","pos":"ADJ"} ,
		{"word":"surmenés","word_nosc":"surmenes","lemma":"surmené","pos":"ADJ"} ,
		{"word":"surmultipliée","word_nosc":"surmultipliee","lemma":"surmultiplié","pos":"ADJ"} ,
		{"word":"surmultipliées","word_nosc":"surmultipliees","lemma":"surmultiplié","pos":"ADJ"} ,
		{"word":"surnageant","word_nosc":"surnageant","lemma":"surnageant","pos":"ADJ"} ,
		{"word":"surnageants","word_nosc":"surnageants","lemma":"surnageant","pos":"ADJ"} ,
		{"word":"surnaturel","word_nosc":"surnaturel","lemma":"surnaturel","pos":"ADJ"} ,
		{"word":"surnaturelle","word_nosc":"surnaturelle","lemma":"surnaturel","pos":"ADJ"} ,
		{"word":"surnaturelles","word_nosc":"surnaturelles","lemma":"surnaturel","pos":"ADJ"} ,
		{"word":"surnaturels","word_nosc":"surnaturels","lemma":"surnaturel","pos":"ADJ"} ,
		{"word":"surnommé","word_nosc":"surnomme","lemma":"surnommé","pos":"ADJ"} ,
		{"word":"surnommée","word_nosc":"surnommee","lemma":"surnommé","pos":"ADJ"} ,
		{"word":"surnommés","word_nosc":"surnommes","lemma":"surnommé","pos":"ADJ"} ,
		{"word":"surnuméraire","word_nosc":"surnumeraire","lemma":"surnuméraire","pos":"ADJ"} ,
		{"word":"surnuméraires","word_nosc":"surnumeraires","lemma":"surnuméraire","pos":"ADJ"} ,
		{"word":"suroxygéné","word_nosc":"suroxygene","lemma":"suroxygéné","pos":"ADJ"} ,
		{"word":"surpeuplé","word_nosc":"surpeuple","lemma":"surpeuplé","pos":"ADJ"} ,
		{"word":"surpeuplée","word_nosc":"surpeuplee","lemma":"surpeuplé","pos":"ADJ"} ,
		{"word":"surpeuplées","word_nosc":"surpeuplees","lemma":"surpeuplé","pos":"ADJ"} ,
		{"word":"surpeuplés","word_nosc":"surpeuples","lemma":"surpeuplé","pos":"ADJ"} ,
		{"word":"surplombant","word_nosc":"surplombant","lemma":"surplombant","pos":"ADJ"} ,
		{"word":"surplombante","word_nosc":"surplombante","lemma":"surplombant","pos":"ADJ"} ,
		{"word":"surprenant","word_nosc":"surprenant","lemma":"surprenant","pos":"ADJ"} ,
		{"word":"surprenante","word_nosc":"surprenante","lemma":"surprenant","pos":"ADJ"} ,
		{"word":"surprenantes","word_nosc":"surprenantes","lemma":"surprenant","pos":"ADJ"} ,
		{"word":"surprenants","word_nosc":"surprenants","lemma":"surprenant","pos":"ADJ"} ,
		{"word":"surpris","word_nosc":"surpris","lemma":"surpris","pos":"ADJ"} ,
		{"word":"surprise","word_nosc":"surprise","lemma":"surpris","pos":"ADJ"} ,
		{"word":"surprises","word_nosc":"surprises","lemma":"surpris","pos":"ADJ"} ,
		{"word":"surprotecteur","word_nosc":"surprotecteur","lemma":"surprotecteur","pos":"ADJ"} ,
		{"word":"surpuissant","word_nosc":"surpuissant","lemma":"surpuissant","pos":"ADJ"} ,
		{"word":"surpuissante","word_nosc":"surpuissante","lemma":"surpuissant","pos":"ADJ"} ,
		{"word":"surpuissants","word_nosc":"surpuissants","lemma":"surpuissant","pos":"ADJ"} ,
		{"word":"surqualifié","word_nosc":"surqualifie","lemma":"surqualifié","pos":"ADJ"} ,
		{"word":"surqualifiée","word_nosc":"surqualifiee","lemma":"surqualifié","pos":"ADJ"} ,
		{"word":"surréaliste","word_nosc":"surrealiste","lemma":"surréaliste","pos":"ADJ"} ,
		{"word":"surréalistes","word_nosc":"surrealistes","lemma":"surréaliste","pos":"ADJ"} ,
		{"word":"surréel","word_nosc":"surreel","lemma":"surréel","pos":"ADJ"} ,
		{"word":"surréelle","word_nosc":"surreelle","lemma":"surréel","pos":"ADJ"} ,
		{"word":"surréelles","word_nosc":"surreelles","lemma":"surréel","pos":"ADJ"} ,
		{"word":"surrénal","word_nosc":"surrenal","lemma":"surrénal","pos":"ADJ"} ,
		{"word":"surrénale","word_nosc":"surrenale","lemma":"surrénal","pos":"ADJ"} ,
		{"word":"surrénales","word_nosc":"surrenales","lemma":"surrénal","pos":"ADJ"} ,
		{"word":"surrénalien","word_nosc":"surrenalien","lemma":"surrénalien","pos":"ADJ"} ,
		{"word":"surs","word_nosc":"surs","lemma":"sur","pos":"ADJ"} ,
		{"word":"sursaturée","word_nosc":"sursaturee","lemma":"sursaturé","pos":"ADJ"} ,
		{"word":"sursaturées","word_nosc":"sursaturees","lemma":"sursaturé","pos":"ADJ"} ,
		{"word":"sursitaire","word_nosc":"sursitaire","lemma":"sursitaire","pos":"ADJ"} ,
		{"word":"surtendu","word_nosc":"surtendu","lemma":"surtendu","pos":"ADJ"} ,
		{"word":"survivant","word_nosc":"survivant","lemma":"survivant","pos":"ADJ"} ,
		{"word":"survivante","word_nosc":"survivante","lemma":"survivant","pos":"ADJ"} ,
		{"word":"survivantes","word_nosc":"survivantes","lemma":"survivant","pos":"ADJ"} ,
		{"word":"survivants","word_nosc":"survivants","lemma":"survivant","pos":"ADJ"} ,
		{"word":"survolté","word_nosc":"survolte","lemma":"survolté","pos":"ADJ"} ,
		{"word":"survoltée","word_nosc":"survoltee","lemma":"survolté","pos":"ADJ"} ,
		{"word":"survoltées","word_nosc":"survoltees","lemma":"survolté","pos":"ADJ"} ,
		{"word":"survoltés","word_nosc":"survoltes","lemma":"survolté","pos":"ADJ"} ,
		{"word":"suréminente","word_nosc":"sureminente","lemma":"suréminent","pos":"ADJ"} ,
		{"word":"sus-orbitaire","word_nosc":"sus-orbitaire","lemma":"sus-orbitaire","pos":"ADJ"} ,
		{"word":"susceptible","word_nosc":"susceptible","lemma":"susceptible","pos":"ADJ"} ,
		{"word":"susceptibles","word_nosc":"susceptibles","lemma":"susceptible","pos":"ADJ"} ,
		{"word":"susdit","word_nosc":"susdit","lemma":"susdit","pos":"ADJ"} ,
		{"word":"susdite","word_nosc":"susdite","lemma":"susdit","pos":"ADJ"} ,
		{"word":"susdites","word_nosc":"susdites","lemma":"susdit","pos":"ADJ"} ,
		{"word":"susmentionné","word_nosc":"susmentionne","lemma":"susmentionné","pos":"ADJ"} ,
		{"word":"susmentionnée","word_nosc":"susmentionnee","lemma":"susmentionné","pos":"ADJ"} ,
		{"word":"susnommé","word_nosc":"susnomme","lemma":"susnommé","pos":"ADJ"} ,
		{"word":"susnommée","word_nosc":"susnommee","lemma":"susnommé","pos":"ADJ"} ,
		{"word":"susnommés","word_nosc":"susnommes","lemma":"susnommé","pos":"ADJ"} ,
		{"word":"suspect","word_nosc":"suspect","lemma":"suspect","pos":"ADJ"} ,
		{"word":"suspecte","word_nosc":"suspecte","lemma":"suspect","pos":"ADJ"} ,
		{"word":"suspectes","word_nosc":"suspectes","lemma":"suspect","pos":"ADJ"} ,
		{"word":"suspects","word_nosc":"suspects","lemma":"suspect","pos":"ADJ"} ,
		{"word":"suspendu","word_nosc":"suspendu","lemma":"suspendu","pos":"ADJ"} ,
		{"word":"suspendue","word_nosc":"suspendue","lemma":"suspendu","pos":"ADJ"} ,
		{"word":"suspendues","word_nosc":"suspendues","lemma":"suspendu","pos":"ADJ"} ,
		{"word":"suspendus","word_nosc":"suspendus","lemma":"suspendu","pos":"ADJ"} ,
		{"word":"suspens","word_nosc":"suspens","lemma":"suspens","pos":"ADJ"} ,
		{"word":"suspenseur","word_nosc":"suspenseur","lemma":"suspenseur","pos":"ADJ"} ,
		{"word":"suspenseurs","word_nosc":"suspenseurs","lemma":"suspenseur","pos":"ADJ"} ,
		{"word":"suspensif","word_nosc":"suspensif","lemma":"suspensif","pos":"ADJ"} ,
		{"word":"suspensive","word_nosc":"suspensive","lemma":"suspensif","pos":"ADJ"} ,
		{"word":"suspicieuse","word_nosc":"suspicieuse","lemma":"suspicieux","pos":"ADJ"} ,
		{"word":"suspicieuses","word_nosc":"suspicieuses","lemma":"suspicieux","pos":"ADJ"} ,
		{"word":"suspicieux","word_nosc":"suspicieux","lemma":"suspicieux","pos":"ADJ"} ,
		{"word":"susu","word_nosc":"susu","lemma":"susu","pos":"ADJ"} ,
		{"word":"susvisée","word_nosc":"susvisee","lemma":"susvisé","pos":"ADJ"} ,
		{"word":"suédois","word_nosc":"suedois","lemma":"suédois","pos":"ADJ"} ,
		{"word":"suédoise","word_nosc":"suedoise","lemma":"suédois","pos":"ADJ"} ,
		{"word":"suédoises","word_nosc":"suedoises","lemma":"suédois","pos":"ADJ"} ,
		{"word":"svelte","word_nosc":"svelte","lemma":"svelte","pos":"ADJ"} ,
		{"word":"sveltes","word_nosc":"sveltes","lemma":"svelte","pos":"ADJ"} ,
		{"word":"swahili","word_nosc":"swahili","lemma":"swahili","pos":"ADJ"} ,
		{"word":"swiftiennes","word_nosc":"swiftiennes","lemma":"swiftien","pos":"ADJ"} ,
		{"word":"sybarites","word_nosc":"sybarites","lemma":"sybarite","pos":"ADJ"} ,
		{"word":"syllogistique","word_nosc":"syllogistique","lemma":"syllogistique","pos":"ADJ"} ,
		{"word":"syllogistiques","word_nosc":"syllogistiques","lemma":"syllogistique","pos":"ADJ"} ,
		{"word":"sylvestre","word_nosc":"sylvestre","lemma":"sylvestre","pos":"ADJ"} ,
		{"word":"sylvestres","word_nosc":"sylvestres","lemma":"sylvestre","pos":"ADJ"} ,
		{"word":"symbiotique","word_nosc":"symbiotique","lemma":"symbiotique","pos":"ADJ"} ,
		{"word":"symbolique","word_nosc":"symbolique","lemma":"symbolique","pos":"ADJ"} ,
		{"word":"symboliques","word_nosc":"symboliques","lemma":"symbolique","pos":"ADJ"} ,
		{"word":"symbolisant","word_nosc":"symbolisant","lemma":"symbolisant","pos":"ADJ"} ,
		{"word":"symboliste","word_nosc":"symboliste","lemma":"symboliste","pos":"ADJ"} ,
		{"word":"sympa","word_nosc":"sympa","lemma":"sympa","pos":"ADJ"} ,
		{"word":"sympas","word_nosc":"sympas","lemma":"sympa","pos":"ADJ"} ,
		{"word":"sympathique","word_nosc":"sympathique","lemma":"sympathique","pos":"ADJ"} ,
		{"word":"sympathiques","word_nosc":"sympathiques","lemma":"sympathique","pos":"ADJ"} ,
		{"word":"sympathisant","word_nosc":"sympathisant","lemma":"sympathisant","pos":"ADJ"} ,
		{"word":"sympathisante","word_nosc":"sympathisante","lemma":"sympathisant","pos":"ADJ"} ,
		{"word":"sympathisantes","word_nosc":"sympathisantes","lemma":"sympathisant","pos":"ADJ"} ,
		{"word":"sympathisants","word_nosc":"sympathisants","lemma":"sympathisant","pos":"ADJ"} ,
		{"word":"sympathomimétique","word_nosc":"sympathomimetique","lemma":"sympathomimétique","pos":"ADJ"} ,
		{"word":"symphonique","word_nosc":"symphonique","lemma":"symphonique","pos":"ADJ"} ,
		{"word":"symphoniques","word_nosc":"symphoniques","lemma":"symphonique","pos":"ADJ"} ,
		{"word":"symptomatique","word_nosc":"symptomatique","lemma":"symptomatique","pos":"ADJ"} ,
		{"word":"symptomatiques","word_nosc":"symptomatiques","lemma":"symptomatique","pos":"ADJ"} ,
		{"word":"symétrique","word_nosc":"symetrique","lemma":"symétrique","pos":"ADJ"} ,
		{"word":"symétriques","word_nosc":"symetriques","lemma":"symétrique","pos":"ADJ"} ,
		{"word":"synagogale","word_nosc":"synagogale","lemma":"synagogal","pos":"ADJ"} ,
		{"word":"synagogaux","word_nosc":"synagogaux","lemma":"synagogal","pos":"ADJ"} ,
		{"word":"synaptique","word_nosc":"synaptique","lemma":"synaptique","pos":"ADJ"} ,
		{"word":"synaptiques","word_nosc":"synaptiques","lemma":"synaptique","pos":"ADJ"} ,
		{"word":"synchro","word_nosc":"synchro","lemma":"synchro","pos":"ADJ"} ,
		{"word":"synchrone","word_nosc":"synchrone","lemma":"synchrone","pos":"ADJ"} ,
		{"word":"synchrones","word_nosc":"synchrones","lemma":"synchrone","pos":"ADJ"} ,
		{"word":"synchronique","word_nosc":"synchronique","lemma":"synchronique","pos":"ADJ"} ,
		{"word":"synchros","word_nosc":"synchros","lemma":"synchro","pos":"ADJ"} ,
		{"word":"syncopal","word_nosc":"syncopal","lemma":"syncopal","pos":"ADJ"} ,
		{"word":"syncopé","word_nosc":"syncope","lemma":"syncopé","pos":"ADJ"} ,
		{"word":"syncopée","word_nosc":"syncopee","lemma":"syncopé","pos":"ADJ"} ,
		{"word":"syncopées","word_nosc":"syncopees","lemma":"syncopé","pos":"ADJ"} ,
		{"word":"syncopés","word_nosc":"syncopes","lemma":"syncopé","pos":"ADJ"} ,
		{"word":"syncrétiste","word_nosc":"syncretiste","lemma":"syncrétiste","pos":"ADJ"} ,
		{"word":"syncytial","word_nosc":"syncytial","lemma":"syncytial","pos":"ADJ"} ,
		{"word":"syndical","word_nosc":"syndical","lemma":"syndical","pos":"ADJ"} ,
		{"word":"syndicale","word_nosc":"syndicale","lemma":"syndical","pos":"ADJ"} ,
		{"word":"syndicales","word_nosc":"syndicales","lemma":"syndical","pos":"ADJ"} ,
		{"word":"syndicaliste","word_nosc":"syndicaliste","lemma":"syndicaliste","pos":"ADJ"} ,
		{"word":"syndicalistes","word_nosc":"syndicalistes","lemma":"syndicaliste","pos":"ADJ"} ,
		{"word":"syndicaux","word_nosc":"syndicaux","lemma":"syndical","pos":"ADJ"} ,
		{"word":"syndiqué","word_nosc":"syndique","lemma":"syndiqué","pos":"ADJ"} ,
		{"word":"syndiquée","word_nosc":"syndiquee","lemma":"syndiqué","pos":"ADJ"} ,
		{"word":"syndiquées","word_nosc":"syndiquees","lemma":"syndiqué","pos":"ADJ"} ,
		{"word":"syndiqués","word_nosc":"syndiques","lemma":"syndiqué","pos":"ADJ"} ,
		{"word":"synergique","word_nosc":"synergique","lemma":"synergique","pos":"ADJ"} ,
		{"word":"synergétique","word_nosc":"synergetique","lemma":"synergétique","pos":"ADJ"} ,
		{"word":"synonyme","word_nosc":"synonyme","lemma":"synonyme","pos":"ADJ"} ,
		{"word":"synonymes","word_nosc":"synonymes","lemma":"synonyme","pos":"ADJ"} ,
		{"word":"synoptique","word_nosc":"synoptique","lemma":"synoptique","pos":"ADJ"} ,
		{"word":"synoviales","word_nosc":"synoviales","lemma":"synovial","pos":"ADJ"} ,
		{"word":"syntaxique","word_nosc":"syntaxique","lemma":"syntaxique","pos":"ADJ"} ,
		{"word":"synthétique","word_nosc":"synthetique","lemma":"synthétique","pos":"ADJ"} ,
		{"word":"synthétiques","word_nosc":"synthetiques","lemma":"synthétique","pos":"ADJ"} ,
		{"word":"syphilitique","word_nosc":"syphilitique","lemma":"syphilitique","pos":"ADJ"} ,
		{"word":"syphilitiques","word_nosc":"syphilitiques","lemma":"syphilitique","pos":"ADJ"} ,
		{"word":"syriaques","word_nosc":"syriaques","lemma":"syriaque","pos":"ADJ"} ,
		{"word":"syrien","word_nosc":"syrien","lemma":"syrien","pos":"ADJ"} ,
		{"word":"syrienne","word_nosc":"syrienne","lemma":"syrien","pos":"ADJ"} ,
		{"word":"syriennes","word_nosc":"syriennes","lemma":"syrien","pos":"ADJ"} ,
		{"word":"syriens","word_nosc":"syriens","lemma":"syrien","pos":"ADJ"} ,
		{"word":"systolique","word_nosc":"systolique","lemma":"systolique","pos":"ADJ"} ,
		{"word":"systoliques","word_nosc":"systoliques","lemma":"systolique","pos":"ADJ"} ,
		{"word":"systématique","word_nosc":"systematique","lemma":"systématique","pos":"ADJ"} ,
		{"word":"systématiques","word_nosc":"systematiques","lemma":"systématique","pos":"ADJ"} ,
		{"word":"systématisé","word_nosc":"systematise","lemma":"systématisé","pos":"ADJ"} ,
		{"word":"systématisée","word_nosc":"systematisee","lemma":"systématisé","pos":"ADJ"} ,
		{"word":"systématisés","word_nosc":"systematises","lemma":"systématisé","pos":"ADJ"} ,
		{"word":"systémique","word_nosc":"systemique","lemma":"systémique","pos":"ADJ"} ,
		{"word":"sèche","word_nosc":"seche","lemma":"sec","pos":"ADJ"} ,
		{"word":"sèches","word_nosc":"seches","lemma":"sec","pos":"ADJ"} ,
		{"word":"séant","word_nosc":"seant","lemma":"séant","pos":"ADJ"} ,
		{"word":"séante","word_nosc":"seante","lemma":"séant","pos":"ADJ"} ,
		{"word":"sébacé","word_nosc":"sebace","lemma":"sébacé","pos":"ADJ"} ,
		{"word":"sébacée","word_nosc":"sebacee","lemma":"sébacé","pos":"ADJ"} ,
		{"word":"sébacées","word_nosc":"sebacees","lemma":"sébacé","pos":"ADJ"} ,
		{"word":"séborrhéique","word_nosc":"seborrheique","lemma":"séborrhéique","pos":"ADJ"} ,
		{"word":"sécessionniste","word_nosc":"secessionniste","lemma":"sécessionniste","pos":"ADJ"} ,
		{"word":"sécessionnistes","word_nosc":"secessionnistes","lemma":"sécessionniste","pos":"ADJ"} ,
		{"word":"sécréteurs","word_nosc":"secreteurs","lemma":"sécréteur","pos":"ADJ"} ,
		{"word":"séculaire","word_nosc":"seculaire","lemma":"séculaire","pos":"ADJ"} ,
		{"word":"séculaires","word_nosc":"seculaires","lemma":"séculaire","pos":"ADJ"} ,
		{"word":"séculier","word_nosc":"seculier","lemma":"séculier","pos":"ADJ"} ,
		{"word":"séculiers","word_nosc":"seculiers","lemma":"séculier","pos":"ADJ"} ,
		{"word":"séculière","word_nosc":"seculiere","lemma":"séculier","pos":"ADJ"} ,
		{"word":"sécurisant","word_nosc":"securisant","lemma":"sécurisant","pos":"ADJ"} ,
		{"word":"sécurisante","word_nosc":"securisante","lemma":"sécurisant","pos":"ADJ"} ,
		{"word":"sécuritaire","word_nosc":"securitaire","lemma":"sécuritaire","pos":"ADJ"} ,
		{"word":"sédatif","word_nosc":"sedatif","lemma":"sédatif","pos":"ADJ"} ,
		{"word":"sédatifs","word_nosc":"sedatifs","lemma":"sédatif","pos":"ADJ"} ,
		{"word":"sédative","word_nosc":"sedative","lemma":"sédatif","pos":"ADJ"} ,
		{"word":"sédatives","word_nosc":"sedatives","lemma":"sédatif","pos":"ADJ"} ,
		{"word":"sédentaire","word_nosc":"sedentaire","lemma":"sédentaire","pos":"ADJ"} ,
		{"word":"sédentaires","word_nosc":"sedentaires","lemma":"sédentaire","pos":"ADJ"} ,
		{"word":"sédimentaire","word_nosc":"sedimentaire","lemma":"sédimentaire","pos":"ADJ"} ,
		{"word":"sédimentaires","word_nosc":"sedimentaires","lemma":"sédimentaire","pos":"ADJ"} ,
		{"word":"séditieuse","word_nosc":"seditieuse","lemma":"séditieux","pos":"ADJ"} ,
		{"word":"séditieuses","word_nosc":"seditieuses","lemma":"séditieux","pos":"ADJ"} ,
		{"word":"séditieux","word_nosc":"seditieux","lemma":"séditieux","pos":"ADJ"} ,
		{"word":"séducteur","word_nosc":"seducteur","lemma":"séducteur","pos":"ADJ"} ,
		{"word":"séducteurs","word_nosc":"seducteurs","lemma":"séducteur","pos":"ADJ"} ,
		{"word":"séductrice","word_nosc":"seductrice","lemma":"séducteur","pos":"ADJ"} ,
		{"word":"séductrices","word_nosc":"seductrices","lemma":"séducteur","pos":"ADJ"} ,
		{"word":"séduisant","word_nosc":"seduisant","lemma":"séduisant","pos":"ADJ"} ,
		{"word":"séduisante","word_nosc":"seduisante","lemma":"séduisant","pos":"ADJ"} ,
		{"word":"séduisantes","word_nosc":"seduisantes","lemma":"séduisant","pos":"ADJ"} ,
		{"word":"séduisants","word_nosc":"seduisants","lemma":"séduisant","pos":"ADJ"} ,
		{"word":"séduit","word_nosc":"seduit","lemma":"séduit","pos":"ADJ"} ,
		{"word":"séduite","word_nosc":"seduite","lemma":"séduit","pos":"ADJ"} ,
		{"word":"séduites","word_nosc":"seduites","lemma":"séduit","pos":"ADJ"} ,
		{"word":"séduits","word_nosc":"seduits","lemma":"séduit","pos":"ADJ"} ,
		{"word":"séfarade","word_nosc":"sefarade","lemma":"séfarade","pos":"ADJ"} ,
		{"word":"ségrégationniste","word_nosc":"segregationniste","lemma":"ségrégationniste","pos":"ADJ"} ,
		{"word":"sélect","word_nosc":"select","lemma":"sélect","pos":"ADJ"} ,
		{"word":"sélecte","word_nosc":"selecte","lemma":"sélect","pos":"ADJ"} ,
		{"word":"sélectif","word_nosc":"selectif","lemma":"sélectif","pos":"ADJ"} ,
		{"word":"sélectifs","word_nosc":"selectifs","lemma":"sélectif","pos":"ADJ"} ,
		{"word":"sélectionné","word_nosc":"selectionne","lemma":"sélectionné","pos":"ADJ"} ,
		{"word":"sélectionnée","word_nosc":"selectionnee","lemma":"sélectionné","pos":"ADJ"} ,
		{"word":"sélectionnées","word_nosc":"selectionnees","lemma":"sélectionné","pos":"ADJ"} ,
		{"word":"sélectionnés","word_nosc":"selectionnes","lemma":"sélectionné","pos":"ADJ"} ,
		{"word":"sélective","word_nosc":"selective","lemma":"sélectif","pos":"ADJ"} ,
		{"word":"sélectives","word_nosc":"selectives","lemma":"sélectif","pos":"ADJ"} ,
		{"word":"sélects","word_nosc":"selects","lemma":"sélect","pos":"ADJ"} ,
		{"word":"sémantique","word_nosc":"semantique","lemma":"sémantique","pos":"ADJ"} ,
		{"word":"sémantiques","word_nosc":"semantiques","lemma":"sémantique","pos":"ADJ"} ,
		{"word":"sémaphoriques","word_nosc":"semaphoriques","lemma":"sémaphorique","pos":"ADJ"} ,
		{"word":"sémillant","word_nosc":"semillant","lemma":"sémillant","pos":"ADJ"} ,
		{"word":"sémillante","word_nosc":"semillante","lemma":"sémillant","pos":"ADJ"} ,
		{"word":"séminal","word_nosc":"seminal","lemma":"séminal","pos":"ADJ"} ,
		{"word":"séminale","word_nosc":"seminale","lemma":"séminal","pos":"ADJ"} ,
		{"word":"séminales","word_nosc":"seminales","lemma":"séminal","pos":"ADJ"} ,
		{"word":"séminaux","word_nosc":"seminaux","lemma":"séminal","pos":"ADJ"} ,
		{"word":"séminole","word_nosc":"seminole","lemma":"séminole","pos":"ADJ"} ,
		{"word":"séminoles","word_nosc":"seminoles","lemma":"séminole","pos":"ADJ"} ,
		{"word":"sémite","word_nosc":"semite","lemma":"sémite","pos":"ADJ"} ,
		{"word":"sémites","word_nosc":"semites","lemma":"sémite","pos":"ADJ"} ,
		{"word":"sémitique","word_nosc":"semitique","lemma":"sémitique","pos":"ADJ"} ,
		{"word":"sémitiques","word_nosc":"semitiques","lemma":"sémitique","pos":"ADJ"} ,
		{"word":"sénatorial","word_nosc":"senatorial","lemma":"sénatorial","pos":"ADJ"} ,
		{"word":"sénatoriale","word_nosc":"senatoriale","lemma":"sénatorial","pos":"ADJ"} ,
		{"word":"sénatoriales","word_nosc":"senatoriales","lemma":"sénatorial","pos":"ADJ"} ,
		{"word":"sénile","word_nosc":"senile","lemma":"sénile","pos":"ADJ"} ,
		{"word":"séniles","word_nosc":"seniles","lemma":"sénile","pos":"ADJ"} ,
		{"word":"sénégalais","word_nosc":"senegalais","lemma":"sénégalais","pos":"ADJ"} ,
		{"word":"sénégalaise","word_nosc":"senegalaise","lemma":"sénégalais","pos":"ADJ"} ,
		{"word":"sénégalaises","word_nosc":"senegalaises","lemma":"sénégalais","pos":"ADJ"} ,
		{"word":"séoudite","word_nosc":"seoudite","lemma":"séoudite","pos":"ADJ"} ,
		{"word":"séparable","word_nosc":"separable","lemma":"séparable","pos":"ADJ"} ,
		{"word":"séparateur","word_nosc":"separateur","lemma":"séparateur","pos":"ADJ"} ,
		{"word":"séparatiste","word_nosc":"separatiste","lemma":"séparatiste","pos":"ADJ"} ,
		{"word":"séparatistes","word_nosc":"separatistes","lemma":"séparatiste","pos":"ADJ"} ,
		{"word":"séparatrice","word_nosc":"separatrice","lemma":"séparateur","pos":"ADJ"} ,
		{"word":"séparé","word_nosc":"separe","lemma":"séparé","pos":"ADJ"} ,
		{"word":"séparée","word_nosc":"separee","lemma":"séparé","pos":"ADJ"} ,
		{"word":"séparées","word_nosc":"separees","lemma":"séparé","pos":"ADJ"} ,
		{"word":"séparés","word_nosc":"separes","lemma":"séparé","pos":"ADJ"} ,
		{"word":"sépharade","word_nosc":"sepharade","lemma":"sépharade","pos":"ADJ"} ,
		{"word":"sépharades","word_nosc":"sepharades","lemma":"sépharade","pos":"ADJ"} ,
		{"word":"sépulcral","word_nosc":"sepulcral","lemma":"sépulcral","pos":"ADJ"} ,
		{"word":"sépulcrale","word_nosc":"sepulcrale","lemma":"sépulcral","pos":"ADJ"} ,
		{"word":"sépulcrales","word_nosc":"sepulcrales","lemma":"sépulcral","pos":"ADJ"} ,
		{"word":"séquentiel","word_nosc":"sequentiel","lemma":"séquentiel","pos":"ADJ"} ,
		{"word":"séquentielle","word_nosc":"sequentielle","lemma":"séquentiel","pos":"ADJ"} ,
		{"word":"séraphique","word_nosc":"seraphique","lemma":"séraphique","pos":"ADJ"} ,
		{"word":"séraphiques","word_nosc":"seraphiques","lemma":"séraphique","pos":"ADJ"} ,
		{"word":"séreux","word_nosc":"sereux","lemma":"séreux","pos":"ADJ"} ,
		{"word":"sériel","word_nosc":"seriel","lemma":"sériel","pos":"ADJ"} ,
		{"word":"sérielle","word_nosc":"serielle","lemma":"sériel","pos":"ADJ"} ,
		{"word":"sérieuse","word_nosc":"serieuse","lemma":"sérieux","pos":"ADJ"} ,
		{"word":"sérieuses","word_nosc":"serieuses","lemma":"sérieux","pos":"ADJ"} ,
		{"word":"sérieux","word_nosc":"serieux","lemma":"sérieux","pos":"ADJ"} ,
		{"word":"sérique","word_nosc":"serique","lemma":"sérique","pos":"ADJ"} ,
		{"word":"séronégatif","word_nosc":"seronegatif","lemma":"séronégatif","pos":"ADJ"} ,
		{"word":"séronégatifs","word_nosc":"seronegatifs","lemma":"séronégatif","pos":"ADJ"} ,
		{"word":"séronégative","word_nosc":"seronegative","lemma":"séronégatif","pos":"ADJ"} ,
		{"word":"séropositif","word_nosc":"seropositif","lemma":"séropositif","pos":"ADJ"} ,
		{"word":"séropositifs","word_nosc":"seropositifs","lemma":"séropositif","pos":"ADJ"} ,
		{"word":"séropositive","word_nosc":"seropositive","lemma":"séropositif","pos":"ADJ"} ,
		{"word":"séropositives","word_nosc":"seropositives","lemma":"séropositif","pos":"ADJ"} ,
		{"word":"sérénissime","word_nosc":"serenissime","lemma":"sérénissime","pos":"ADJ"} ,
		{"word":"sésamoïdes","word_nosc":"sesamoides","lemma":"sésamoïde","pos":"ADJ"} ,
		{"word":"sévillan","word_nosc":"sevillan","lemma":"sévillan","pos":"ADJ"} ,
		{"word":"sévillane","word_nosc":"sevillane","lemma":"sévillan","pos":"ADJ"} ,
		{"word":"sévère","word_nosc":"severe","lemma":"sévère","pos":"ADJ"} ,
		{"word":"sévères","word_nosc":"severes","lemma":"sévère","pos":"ADJ"} ,
		{"word":"sûr","word_nosc":"sur","lemma":"sûr","pos":"ADJ"} ,
		{"word":"sûre","word_nosc":"sure","lemma":"sûr","pos":"ADJ"} ,
		{"word":"sûres","word_nosc":"sures","lemma":"sûr","pos":"ADJ"} ,
		{"word":"sûrs","word_nosc":"surs","lemma":"sûr","pos":"ADJ"} ,
		{"word":"ta","word_nosc":"ta","lemma":"ta","pos":"ADJ:pos"} ,
		{"word":"tab","word_nosc":"tab","lemma":"tab","pos":"ADJ"} ,
		{"word":"tabloïd","word_nosc":"tabloid","lemma":"tabloïd","pos":"ADJ"} ,
		{"word":"tabou","word_nosc":"tabou","lemma":"tabou","pos":"ADJ"} ,
		{"word":"taboue","word_nosc":"taboue","lemma":"tabou","pos":"ADJ"} ,
		{"word":"taboues","word_nosc":"taboues","lemma":"tabou","pos":"ADJ"} ,
		{"word":"tabous","word_nosc":"tabous","lemma":"tabou","pos":"ADJ"} ,
		{"word":"tabulaire","word_nosc":"tabulaire","lemma":"tabulaire","pos":"ADJ"} ,
		{"word":"tachiste","word_nosc":"tachiste","lemma":"tachiste","pos":"ADJ"} ,
		{"word":"tacite","word_nosc":"tacite","lemma":"tacite","pos":"ADJ"} ,
		{"word":"tacites","word_nosc":"tacites","lemma":"tacite","pos":"ADJ"} ,
		{"word":"taciturne","word_nosc":"taciturne","lemma":"taciturne","pos":"ADJ"} ,
		{"word":"taciturnes","word_nosc":"taciturnes","lemma":"taciturne","pos":"ADJ"} ,
		{"word":"tactile","word_nosc":"tactile","lemma":"tactile","pos":"ADJ"} ,
		{"word":"tactiles","word_nosc":"tactiles","lemma":"tactile","pos":"ADJ"} ,
		{"word":"tactique","word_nosc":"tactique","lemma":"tactique","pos":"ADJ"} ,
		{"word":"tactiques","word_nosc":"tactiques","lemma":"tactique","pos":"ADJ"} ,
		{"word":"tahitien","word_nosc":"tahitien","lemma":"tahitien","pos":"ADJ"} ,
		{"word":"tahitienne","word_nosc":"tahitienne","lemma":"tahitien","pos":"ADJ"} ,
		{"word":"tahitiennes","word_nosc":"tahitiennes","lemma":"tahitien","pos":"ADJ"} ,
		{"word":"taillable","word_nosc":"taillable","lemma":"taillable","pos":"ADJ"} ,
		{"word":"taillables","word_nosc":"taillables","lemma":"taillable","pos":"ADJ"} ,
		{"word":"taillé","word_nosc":"taille","lemma":"taillé","pos":"ADJ"} ,
		{"word":"taillée","word_nosc":"taillee","lemma":"taillé","pos":"ADJ"} ,
		{"word":"taillées","word_nosc":"taillees","lemma":"taillé","pos":"ADJ"} ,
		{"word":"taillés","word_nosc":"tailles","lemma":"taillé","pos":"ADJ"} ,
		{"word":"taiseux","word_nosc":"taiseux","lemma":"taiseux","pos":"ADJ"} ,
		{"word":"talentueuse","word_nosc":"talentueuse","lemma":"talentueux","pos":"ADJ"} ,
		{"word":"talentueuses","word_nosc":"talentueuses","lemma":"talentueux","pos":"ADJ"} ,
		{"word":"talentueux","word_nosc":"talentueux","lemma":"talentueux","pos":"ADJ"} ,
		{"word":"talmudique","word_nosc":"talmudique","lemma":"talmudique","pos":"ADJ"} ,
		{"word":"talmudiques","word_nosc":"talmudiques","lemma":"talmudique","pos":"ADJ"} ,
		{"word":"talée","word_nosc":"talee","lemma":"talé","pos":"ADJ"} ,
		{"word":"talées","word_nosc":"talees","lemma":"talé","pos":"ADJ"} ,
		{"word":"talés","word_nosc":"tales","lemma":"talé","pos":"ADJ"} ,
		{"word":"tamisé","word_nosc":"tamise","lemma":"tamisé","pos":"ADJ"} ,
		{"word":"tamisée","word_nosc":"tamisee","lemma":"tamisé","pos":"ADJ"} ,
		{"word":"tamisées","word_nosc":"tamisees","lemma":"tamisé","pos":"ADJ"} ,
		{"word":"tamisés","word_nosc":"tamises","lemma":"tamisé","pos":"ADJ"} ,
		{"word":"tamponneur","word_nosc":"tamponneur","lemma":"tamponneur","pos":"ADJ"} ,
		{"word":"tamponneuse","word_nosc":"tamponneuse","lemma":"tamponneur","pos":"ADJ"} ,
		{"word":"tamponneuses","word_nosc":"tamponneuses","lemma":"tamponneur","pos":"ADJ"} ,
		{"word":"tangent","word_nosc":"tangent","lemma":"tangent","pos":"ADJ"} ,
		{"word":"tangente","word_nosc":"tangente","lemma":"tangent","pos":"ADJ"} ,
		{"word":"tangentes","word_nosc":"tangentes","lemma":"tangent","pos":"ADJ"} ,
		{"word":"tangentiel","word_nosc":"tangentiel","lemma":"tangentiel","pos":"ADJ"} ,
		{"word":"tangents","word_nosc":"tangents","lemma":"tangent","pos":"ADJ"} ,
		{"word":"tangible","word_nosc":"tangible","lemma":"tangible","pos":"ADJ"} ,
		{"word":"tangibles","word_nosc":"tangibles","lemma":"tangible","pos":"ADJ"} ,
		{"word":"tango","word_nosc":"tango","lemma":"tango","pos":"ADJ"} ,
		{"word":"tangérois","word_nosc":"tangerois","lemma":"tangérois","pos":"ADJ"} ,
		{"word":"tannant","word_nosc":"tannant","lemma":"tannant","pos":"ADJ"} ,
		{"word":"tannante","word_nosc":"tannante","lemma":"tannant","pos":"ADJ"} ,
		{"word":"tannantes","word_nosc":"tannantes","lemma":"tannant","pos":"ADJ"} ,
		{"word":"tanné","word_nosc":"tanne","lemma":"tanné","pos":"ADJ"} ,
		{"word":"tannée","word_nosc":"tannee","lemma":"tanné","pos":"ADJ"} ,
		{"word":"tannées","word_nosc":"tannees","lemma":"tanné","pos":"ADJ"} ,
		{"word":"tannés","word_nosc":"tannes","lemma":"tanné","pos":"ADJ"} ,
		{"word":"tantrique","word_nosc":"tantrique","lemma":"tantrique","pos":"ADJ"} ,
		{"word":"tantriques","word_nosc":"tantriques","lemma":"tantrique","pos":"ADJ"} ,
		{"word":"taoïste","word_nosc":"taoiste","lemma":"taoïste","pos":"ADJ"} ,
		{"word":"taoïstes","word_nosc":"taoistes","lemma":"taoïste","pos":"ADJ"} ,
		{"word":"tapageur","word_nosc":"tapageur","lemma":"tapageur","pos":"ADJ"} ,
		{"word":"tapageurs","word_nosc":"tapageurs","lemma":"tapageur","pos":"ADJ"} ,
		{"word":"tapageuse","word_nosc":"tapageuse","lemma":"tapageur","pos":"ADJ"} ,
		{"word":"tapageuses","word_nosc":"tapageuses","lemma":"tapageur","pos":"ADJ"} ,
		{"word":"tapant","word_nosc":"tapant","lemma":"tapant","pos":"ADJ"} ,
		{"word":"tapante","word_nosc":"tapante","lemma":"tapant","pos":"ADJ"} ,
		{"word":"tapantes","word_nosc":"tapantes","lemma":"tapant","pos":"ADJ"} ,
		{"word":"tape-à-l'oeil","word_nosc":"tape-a-l'oeil","lemma":"tape-à-l'oeil","pos":"ADJ"} ,
		{"word":"tapi","word_nosc":"tapi","lemma":"tapi","pos":"ADJ"} ,
		{"word":"tapie","word_nosc":"tapie","lemma":"tapi","pos":"ADJ"} ,
		{"word":"tapies","word_nosc":"tapies","lemma":"tapi","pos":"ADJ"} ,
		{"word":"tapé","word_nosc":"tape","lemma":"tapé","pos":"ADJ"} ,
		{"word":"tapée","word_nosc":"tapee","lemma":"tapé","pos":"ADJ"} ,
		{"word":"tapées","word_nosc":"tapees","lemma":"tapé","pos":"ADJ"} ,
		{"word":"tapés","word_nosc":"tapes","lemma":"tapé","pos":"ADJ"} ,
		{"word":"taquin","word_nosc":"taquin","lemma":"taquin","pos":"ADJ"} ,
		{"word":"taquine","word_nosc":"taquine","lemma":"taquin","pos":"ADJ"} ,
		{"word":"taquines","word_nosc":"taquines","lemma":"taquin","pos":"ADJ"} ,
		{"word":"taquins","word_nosc":"taquins","lemma":"taquin","pos":"ADJ"} ,
		{"word":"tarabiscoté","word_nosc":"tarabiscote","lemma":"tarabiscoté","pos":"ADJ"} ,
		{"word":"tarabiscotée","word_nosc":"tarabiscotee","lemma":"tarabiscoté","pos":"ADJ"} ,
		{"word":"tarabiscotées","word_nosc":"tarabiscotees","lemma":"tarabiscoté","pos":"ADJ"} ,
		{"word":"tarabiscotés","word_nosc":"tarabiscotes","lemma":"tarabiscoté","pos":"ADJ"} ,
		{"word":"taraudant","word_nosc":"taraudant","lemma":"taraudant","pos":"ADJ"} ,
		{"word":"tarbais","word_nosc":"tarbais","lemma":"tarbais","pos":"ADJ"} ,
		{"word":"tard-venu","word_nosc":"tard-venu","lemma":"tard-venu","pos":"ADJ"} ,
		{"word":"tardif","word_nosc":"tardif","lemma":"tardif","pos":"ADJ"} ,
		{"word":"tardifs","word_nosc":"tardifs","lemma":"tardif","pos":"ADJ"} ,
		{"word":"tardive","word_nosc":"tardive","lemma":"tardif","pos":"ADJ"} ,
		{"word":"tardives","word_nosc":"tardives","lemma":"tardif","pos":"ADJ"} ,
		{"word":"targui","word_nosc":"targui","lemma":"targui","pos":"ADJ"} ,
		{"word":"tari","word_nosc":"tari","lemma":"tari","pos":"ADJ"} ,
		{"word":"tarie","word_nosc":"tarie","lemma":"tari","pos":"ADJ"} ,
		{"word":"taries","word_nosc":"taries","lemma":"tari","pos":"ADJ"} ,
		{"word":"taris","word_nosc":"taris","lemma":"tari","pos":"ADJ"} ,
		{"word":"tarsienne","word_nosc":"tarsienne","lemma":"tarsien","pos":"ADJ"} ,
		{"word":"tarsiens","word_nosc":"tarsiens","lemma":"tarsien","pos":"ADJ"} ,
		{"word":"tartare","word_nosc":"tartare","lemma":"tartare","pos":"ADJ"} ,
		{"word":"tartares","word_nosc":"tartares","lemma":"tartare","pos":"ADJ"} ,
		{"word":"tarte","word_nosc":"tarte","lemma":"tarte","pos":"ADJ"} ,
		{"word":"tarte-minute","word_nosc":"tarte-minute","lemma":"tarte-minute","pos":"ADJ"} ,
		{"word":"tartes","word_nosc":"tartes","lemma":"tarte","pos":"ADJ"} ,
		{"word":"tartignolle","word_nosc":"tartignolle","lemma":"tartignolle","pos":"ADJ"} ,
		{"word":"tartignolles","word_nosc":"tartignolles","lemma":"tartignolle","pos":"ADJ"} ,
		{"word":"taré","word_nosc":"tare","lemma":"taré","pos":"ADJ"} ,
		{"word":"tarée","word_nosc":"taree","lemma":"taré","pos":"ADJ"} ,
		{"word":"tarées","word_nosc":"tarees","lemma":"taré","pos":"ADJ"} ,
		{"word":"tarés","word_nosc":"tares","lemma":"taré","pos":"ADJ"} ,
		{"word":"tasmanien","word_nosc":"tasmanien","lemma":"tasmanien","pos":"ADJ"} ,
		{"word":"tassé","word_nosc":"tasse","lemma":"tassé","pos":"ADJ"} ,
		{"word":"tassée","word_nosc":"tassee","lemma":"tassé","pos":"ADJ"} ,
		{"word":"tassées","word_nosc":"tassees","lemma":"tassé","pos":"ADJ"} ,
		{"word":"tassés","word_nosc":"tasses","lemma":"tassé","pos":"ADJ"} ,
		{"word":"tatar","word_nosc":"tatar","lemma":"tatar","pos":"ADJ"} ,
		{"word":"tatars","word_nosc":"tatars","lemma":"tatar","pos":"ADJ"} ,
		{"word":"tatillon","word_nosc":"tatillon","lemma":"tatillon","pos":"ADJ"} ,
		{"word":"tatillonne","word_nosc":"tatillonne","lemma":"tatillon","pos":"ADJ"} ,
		{"word":"tatillonnes","word_nosc":"tatillonnes","lemma":"tatillon","pos":"ADJ"} ,
		{"word":"tatillons","word_nosc":"tatillons","lemma":"tatillon","pos":"ADJ"} ,
		{"word":"tatoué","word_nosc":"tatoue","lemma":"tatoué","pos":"ADJ"} ,
		{"word":"tatouée","word_nosc":"tatouee","lemma":"tatoué","pos":"ADJ"} ,
		{"word":"tatouées","word_nosc":"tatouees","lemma":"tatoué","pos":"ADJ"} ,
		{"word":"tatoués","word_nosc":"tatoues","lemma":"tatoué","pos":"ADJ"} ,
		{"word":"taupe","word_nosc":"taupe","lemma":"taupe","pos":"ADJ"} ,
		{"word":"taupé","word_nosc":"taupe","lemma":"taupé","pos":"ADJ"} ,
		{"word":"taupés","word_nosc":"taupes","lemma":"taupé","pos":"ADJ"} ,
		{"word":"taurin","word_nosc":"taurin","lemma":"taurin","pos":"ADJ"} ,
		{"word":"taurine","word_nosc":"taurine","lemma":"taurin","pos":"ADJ"} ,
		{"word":"taurins","word_nosc":"taurins","lemma":"taurin","pos":"ADJ"} ,
		{"word":"tauromachique","word_nosc":"tauromachique","lemma":"tauromachique","pos":"ADJ"} ,
		{"word":"tavelé","word_nosc":"tavele","lemma":"tavelé","pos":"ADJ"} ,
		{"word":"tavelée","word_nosc":"tavelee","lemma":"tavelé","pos":"ADJ"} ,
		{"word":"taxables","word_nosc":"taxables","lemma":"taxable","pos":"ADJ"} ,
		{"word":"taxinomique","word_nosc":"taxinomique","lemma":"taxinomique","pos":"ADJ"} ,
		{"word":"taxonomique","word_nosc":"taxonomique","lemma":"taxonomique","pos":"ADJ"} ,
		{"word":"taxé","word_nosc":"taxe","lemma":"taxé","pos":"ADJ"} ,
		{"word":"taxée","word_nosc":"taxee","lemma":"taxé","pos":"ADJ"} ,
		{"word":"taxés","word_nosc":"taxes","lemma":"taxé","pos":"ADJ"} ,
		{"word":"tchèque","word_nosc":"tcheque","lemma":"tchèque","pos":"ADJ"} ,
		{"word":"tchèques","word_nosc":"tcheques","lemma":"tchèque","pos":"ADJ"} ,
		{"word":"tchécoslovaque","word_nosc":"tchecoslovaque","lemma":"tchécoslovaque","pos":"ADJ"} ,
		{"word":"tchécoslovaques","word_nosc":"tchecoslovaques","lemma":"tchécoslovaque","pos":"ADJ"} ,
		{"word":"tchékhoviennes","word_nosc":"tchekhoviennes","lemma":"tchékhovien","pos":"ADJ"} ,
		{"word":"technicien","word_nosc":"technicien","lemma":"technicien","pos":"ADJ"} ,
		{"word":"technicienne","word_nosc":"technicienne","lemma":"technicien","pos":"ADJ"} ,
		{"word":"techniciens","word_nosc":"techniciens","lemma":"technicien","pos":"ADJ"} ,
		{"word":"technique","word_nosc":"technique","lemma":"technique","pos":"ADJ"} ,
		{"word":"techniques","word_nosc":"techniques","lemma":"technique","pos":"ADJ"} ,
		{"word":"techno","word_nosc":"techno","lemma":"techno","pos":"ADJ"} ,
		{"word":"technocratique","word_nosc":"technocratique","lemma":"technocratique","pos":"ADJ"} ,
		{"word":"technologique","word_nosc":"technologique","lemma":"technologique","pos":"ADJ"} ,
		{"word":"technologiques","word_nosc":"technologiques","lemma":"technologique","pos":"ADJ"} ,
		{"word":"tectonique","word_nosc":"tectonique","lemma":"tectonique","pos":"ADJ"} ,
		{"word":"tectoniques","word_nosc":"tectoniques","lemma":"tectonique","pos":"ADJ"} ,
		{"word":"teigneuse","word_nosc":"teigneuse","lemma":"teigneux","pos":"ADJ"} ,
		{"word":"teigneuses","word_nosc":"teigneuses","lemma":"teigneux","pos":"ADJ"} ,
		{"word":"teigneux","word_nosc":"teigneux","lemma":"teigneux","pos":"ADJ"} ,
		{"word":"teint","word_nosc":"teint","lemma":"teint","pos":"ADJ"} ,
		{"word":"teinte","word_nosc":"teinte","lemma":"teint","pos":"ADJ"} ,
		{"word":"teintes","word_nosc":"teintes","lemma":"teint","pos":"ADJ"} ,
		{"word":"teints","word_nosc":"teints","lemma":"teint","pos":"ADJ"} ,
		{"word":"tel","word_nosc":"tel","lemma":"tel","pos":"ADJ:ind"} ,
		{"word":"telle","word_nosc":"telle","lemma":"telle","pos":"ADJ:ind"} ,
		{"word":"telles","word_nosc":"telles","lemma":"telles","pos":"ADJ:ind"} ,
		{"word":"tellurique","word_nosc":"tellurique","lemma":"tellurique","pos":"ADJ"} ,
		{"word":"telluriques","word_nosc":"telluriques","lemma":"tellurique","pos":"ADJ"} ,
		{"word":"tels","word_nosc":"tels","lemma":"tels","pos":"ADJ:ind"} ,
		{"word":"templière","word_nosc":"templiere","lemma":"templier","pos":"ADJ"} ,
		{"word":"temporaire","word_nosc":"temporaire","lemma":"temporaire","pos":"ADJ"} ,
		{"word":"temporaires","word_nosc":"temporaires","lemma":"temporaire","pos":"ADJ"} ,
		{"word":"temporal","word_nosc":"temporal","lemma":"temporal","pos":"ADJ"} ,
		{"word":"temporale","word_nosc":"temporale","lemma":"temporal","pos":"ADJ"} ,
		{"word":"temporales","word_nosc":"temporales","lemma":"temporal","pos":"ADJ"} ,
		{"word":"temporaux","word_nosc":"temporaux","lemma":"temporal","pos":"ADJ"} ,
		{"word":"temporel","word_nosc":"temporel","lemma":"temporel","pos":"ADJ"} ,
		{"word":"temporelle","word_nosc":"temporelle","lemma":"temporel","pos":"ADJ"} ,
		{"word":"temporelles","word_nosc":"temporelles","lemma":"temporel","pos":"ADJ"} ,
		{"word":"temporels","word_nosc":"temporels","lemma":"temporel","pos":"ADJ"} ,
		{"word":"temporisateur","word_nosc":"temporisateur","lemma":"temporisateur","pos":"ADJ"} ,
		{"word":"tempéré","word_nosc":"tempere","lemma":"tempéré","pos":"ADJ"} ,
		{"word":"tempérée","word_nosc":"temperee","lemma":"tempéré","pos":"ADJ"} ,
		{"word":"tempérées","word_nosc":"temperees","lemma":"tempéré","pos":"ADJ"} ,
		{"word":"tempérés","word_nosc":"temperes","lemma":"tempéré","pos":"ADJ"} ,
		{"word":"tempétueux","word_nosc":"tempetueux","lemma":"tempétueux","pos":"ADJ"} ,
		{"word":"tenable","word_nosc":"tenable","lemma":"tenable","pos":"ADJ"} ,
		{"word":"tenace","word_nosc":"tenace","lemma":"tenace","pos":"ADJ"} ,
		{"word":"tenaces","word_nosc":"tenaces","lemma":"tenace","pos":"ADJ"} ,
		{"word":"tenant","word_nosc":"tenant","lemma":"tenant","pos":"ADJ"} ,
		{"word":"tenante","word_nosc":"tenante","lemma":"tenant","pos":"ADJ"} ,
		{"word":"tenants","word_nosc":"tenants","lemma":"tenant","pos":"ADJ"} ,
		{"word":"tendancieuse","word_nosc":"tendancieuse","lemma":"tendancieux","pos":"ADJ"} ,
		{"word":"tendancieuses","word_nosc":"tendancieuses","lemma":"tendancieux","pos":"ADJ"} ,
		{"word":"tendancieux","word_nosc":"tendancieux","lemma":"tendancieux","pos":"ADJ"} ,
		{"word":"tendineuses","word_nosc":"tendineuses","lemma":"tendineux","pos":"ADJ"} ,
		{"word":"tendre","word_nosc":"tendre","lemma":"tendre","pos":"ADJ"} ,
		{"word":"tendres","word_nosc":"tendres","lemma":"tendre","pos":"ADJ"} ,
		{"word":"tendu","word_nosc":"tendu","lemma":"tendu","pos":"ADJ"} ,
		{"word":"tendue","word_nosc":"tendue","lemma":"tendu","pos":"ADJ"} ,
		{"word":"tendues","word_nosc":"tendues","lemma":"tendu","pos":"ADJ"} ,
		{"word":"tendus","word_nosc":"tendus","lemma":"tendu","pos":"ADJ"} ,
		{"word":"tentaculaire","word_nosc":"tentaculaire","lemma":"tentaculaire","pos":"ADJ"} ,
		{"word":"tentaculaires","word_nosc":"tentaculaires","lemma":"tentaculaire","pos":"ADJ"} ,
		{"word":"tentant","word_nosc":"tentant","lemma":"tentant","pos":"ADJ"} ,
		{"word":"tentante","word_nosc":"tentante","lemma":"tentant","pos":"ADJ"} ,
		{"word":"tentantes","word_nosc":"tentantes","lemma":"tentant","pos":"ADJ"} ,
		{"word":"tentants","word_nosc":"tentants","lemma":"tentant","pos":"ADJ"} ,
		{"word":"tentateur","word_nosc":"tentateur","lemma":"tentateur","pos":"ADJ"} ,
		{"word":"tentateurs","word_nosc":"tentateurs","lemma":"tentateur","pos":"ADJ"} ,
		{"word":"tentatrice","word_nosc":"tentatrice","lemma":"tentateur","pos":"ADJ"} ,
		{"word":"tentatrices","word_nosc":"tentatrices","lemma":"tentateur","pos":"ADJ"} ,
		{"word":"tenté","word_nosc":"tente","lemma":"tenté","pos":"ADJ"} ,
		{"word":"tentée","word_nosc":"tentee","lemma":"tenté","pos":"ADJ"} ,
		{"word":"tentés","word_nosc":"tentes","lemma":"tenté","pos":"ADJ"} ,
		{"word":"tenu","word_nosc":"tenu","lemma":"tenu","pos":"ADJ"} ,
		{"word":"tenue","word_nosc":"tenue","lemma":"tenu","pos":"ADJ"} ,
		{"word":"tenues","word_nosc":"tenues","lemma":"tenu","pos":"ADJ"} ,
		{"word":"tenus","word_nosc":"tenus","lemma":"tenu","pos":"ADJ"} ,
		{"word":"terminal","word_nosc":"terminal","lemma":"terminal","pos":"ADJ"} ,
		{"word":"terminale","word_nosc":"terminale","lemma":"terminal","pos":"ADJ"} ,
		{"word":"terminales","word_nosc":"terminales","lemma":"terminal","pos":"ADJ"} ,
		{"word":"terminatrice","word_nosc":"terminatrice","lemma":"terminateur","pos":"ADJ"} ,
		{"word":"terminaux","word_nosc":"terminaux","lemma":"terminal","pos":"ADJ"} ,
		{"word":"terminologique","word_nosc":"terminologique","lemma":"terminologique","pos":"ADJ"} ,
		{"word":"ternaire","word_nosc":"ternaire","lemma":"ternaire","pos":"ADJ"} ,
		{"word":"terne","word_nosc":"terne","lemma":"terne","pos":"ADJ"} ,
		{"word":"ternes","word_nosc":"ternes","lemma":"terne","pos":"ADJ"} ,
		{"word":"terni","word_nosc":"terni","lemma":"terni","pos":"ADJ"} ,
		{"word":"ternie","word_nosc":"ternie","lemma":"terni","pos":"ADJ"} ,
		{"word":"ternies","word_nosc":"ternies","lemma":"terni","pos":"ADJ"} ,
		{"word":"ternis","word_nosc":"ternis","lemma":"terni","pos":"ADJ"} ,
		{"word":"terre-neuvien","word_nosc":"terre-neuvien","lemma":"terre-neuvien","pos":"ADJ"} ,
		{"word":"terre-à-terre","word_nosc":"terre-a-terre","lemma":"terre-à-terre","pos":"ADJ"} ,
		{"word":"terrestre","word_nosc":"terrestre","lemma":"terrestre","pos":"ADJ"} ,
		{"word":"terrestres","word_nosc":"terrestres","lemma":"terrestre","pos":"ADJ"} ,
		{"word":"terreuse","word_nosc":"terreuse","lemma":"terreux","pos":"ADJ"} ,
		{"word":"terreuses","word_nosc":"terreuses","lemma":"terreux","pos":"ADJ"} ,
		{"word":"terreux","word_nosc":"terreux","lemma":"terreux","pos":"ADJ"} ,
		{"word":"terrible","word_nosc":"terrible","lemma":"terrible","pos":"ADJ"} ,
		{"word":"terribles","word_nosc":"terribles","lemma":"terrible","pos":"ADJ"} ,
		{"word":"terrien","word_nosc":"terrien","lemma":"terrien","pos":"ADJ"} ,
		{"word":"terrienne","word_nosc":"terrienne","lemma":"terrien","pos":"ADJ"} ,
		{"word":"terriennes","word_nosc":"terriennes","lemma":"terrien","pos":"ADJ"} ,
		{"word":"terriens","word_nosc":"terriens","lemma":"terrien","pos":"ADJ"} ,
		{"word":"terrifiant","word_nosc":"terrifiant","lemma":"terrifiant","pos":"ADJ"} ,
		{"word":"terrifiante","word_nosc":"terrifiante","lemma":"terrifiant","pos":"ADJ"} ,
		{"word":"terrifiantes","word_nosc":"terrifiantes","lemma":"terrifiant","pos":"ADJ"} ,
		{"word":"terrifiants","word_nosc":"terrifiants","lemma":"terrifiant","pos":"ADJ"} ,
		{"word":"terrifique","word_nosc":"terrifique","lemma":"terrifique","pos":"ADJ"} ,
		{"word":"terrifié","word_nosc":"terrifie","lemma":"terrifié","pos":"ADJ"} ,
		{"word":"terrifiée","word_nosc":"terrifiee","lemma":"terrifié","pos":"ADJ"} ,
		{"word":"terrifiées","word_nosc":"terrifiees","lemma":"terrifié","pos":"ADJ"} ,
		{"word":"terrifiés","word_nosc":"terrifies","lemma":"terrifié","pos":"ADJ"} ,
		{"word":"territorial","word_nosc":"territorial","lemma":"territorial","pos":"ADJ"} ,
		{"word":"territoriale","word_nosc":"territoriale","lemma":"territorial","pos":"ADJ"} ,
		{"word":"territoriales","word_nosc":"territoriales","lemma":"territorial","pos":"ADJ"} ,
		{"word":"territoriaux","word_nosc":"territoriaux","lemma":"territorial","pos":"ADJ"} ,
		{"word":"terrorisant","word_nosc":"terrorisant","lemma":"terrorisant","pos":"ADJ"} ,
		{"word":"terrorisante","word_nosc":"terrorisante","lemma":"terrorisant","pos":"ADJ"} ,
		{"word":"terrorisants","word_nosc":"terrorisants","lemma":"terrorisant","pos":"ADJ"} ,
		{"word":"terroriste","word_nosc":"terroriste","lemma":"terroriste","pos":"ADJ"} ,
		{"word":"terroristes","word_nosc":"terroristes","lemma":"terroriste","pos":"ADJ"} ,
		{"word":"terrorisé","word_nosc":"terrorise","lemma":"terrorisé","pos":"ADJ"} ,
		{"word":"terrorisée","word_nosc":"terrorisee","lemma":"terrorisé","pos":"ADJ"} ,
		{"word":"terrorisées","word_nosc":"terrorisees","lemma":"terrorisé","pos":"ADJ"} ,
		{"word":"terrorisés","word_nosc":"terrorises","lemma":"terrorisé","pos":"ADJ"} ,
		{"word":"tertiaire","word_nosc":"tertiaire","lemma":"tertiaire","pos":"ADJ"} ,
		{"word":"tertiaires","word_nosc":"tertiaires","lemma":"tertiaire","pos":"ADJ"} ,
		{"word":"tes","word_nosc":"tes","lemma":"tes","pos":"ADJ:pos"} ,
		{"word":"testamentaire","word_nosc":"testamentaire","lemma":"testamentaire","pos":"ADJ"} ,
		{"word":"testamentaires","word_nosc":"testamentaires","lemma":"testamentaire","pos":"ADJ"} ,
		{"word":"testiculaire","word_nosc":"testiculaire","lemma":"testiculaire","pos":"ADJ"} ,
		{"word":"teuton","word_nosc":"teuton","lemma":"teuton","pos":"ADJ"} ,
		{"word":"teutonique","word_nosc":"teutonique","lemma":"teutonique","pos":"ADJ"} ,
		{"word":"teutoniques","word_nosc":"teutoniques","lemma":"teutonique","pos":"ADJ"} ,
		{"word":"teutonne","word_nosc":"teutonne","lemma":"teuton","pos":"ADJ"} ,
		{"word":"teutonnes","word_nosc":"teutonnes","lemma":"teuton","pos":"ADJ"} ,
		{"word":"teutons","word_nosc":"teutons","lemma":"teuton","pos":"ADJ"} ,
		{"word":"texan","word_nosc":"texan","lemma":"texan","pos":"ADJ"} ,
		{"word":"texane","word_nosc":"texane","lemma":"texan","pos":"ADJ"} ,
		{"word":"texanes","word_nosc":"texanes","lemma":"texan","pos":"ADJ"} ,
		{"word":"texans","word_nosc":"texans","lemma":"texan","pos":"ADJ"} ,
		{"word":"textile","word_nosc":"textile","lemma":"textile","pos":"ADJ"} ,
		{"word":"textiles","word_nosc":"textiles","lemma":"textile","pos":"ADJ"} ,
		{"word":"textuel","word_nosc":"textuel","lemma":"textuel","pos":"ADJ"} ,
		{"word":"textuelle","word_nosc":"textuelle","lemma":"textuel","pos":"ADJ"} ,
		{"word":"textuelles","word_nosc":"textuelles","lemma":"textuel","pos":"ADJ"} ,
		{"word":"thalamique","word_nosc":"thalamique","lemma":"thalamique","pos":"ADJ"} ,
		{"word":"thanatologique","word_nosc":"thanatologique","lemma":"thanatologique","pos":"ADJ"} ,
		{"word":"thatchériennes","word_nosc":"thatcheriennes","lemma":"thatchérien","pos":"ADJ"} ,
		{"word":"thaï","word_nosc":"thai","lemma":"thaï","pos":"ADJ"} ,
		{"word":"thaïe","word_nosc":"thaie","lemma":"thaï","pos":"ADJ"} ,
		{"word":"thaïlandais","word_nosc":"thailandais","lemma":"thaïlandais","pos":"ADJ"} ,
		{"word":"thaïlandaise","word_nosc":"thailandaise","lemma":"thaïlandais","pos":"ADJ"} ,
		{"word":"thaïlandaises","word_nosc":"thailandaises","lemma":"thaïlandais","pos":"ADJ"} ,
		{"word":"thaïs","word_nosc":"thais","lemma":"thaï","pos":"ADJ"} ,
		{"word":"thermal","word_nosc":"thermal","lemma":"thermal","pos":"ADJ"} ,
		{"word":"thermale","word_nosc":"thermale","lemma":"thermal","pos":"ADJ"} ,
		{"word":"thermales","word_nosc":"thermales","lemma":"thermal","pos":"ADJ"} ,
		{"word":"thermaux","word_nosc":"thermaux","lemma":"thermal","pos":"ADJ"} ,
		{"word":"thermidorienne","word_nosc":"thermidorienne","lemma":"thermidorien","pos":"ADJ"} ,
		{"word":"thermique","word_nosc":"thermique","lemma":"thermique","pos":"ADJ"} ,
		{"word":"thermiques","word_nosc":"thermiques","lemma":"thermique","pos":"ADJ"} ,
		{"word":"thermocollants","word_nosc":"thermocollants","lemma":"thermocollant","pos":"ADJ"} ,
		{"word":"thermodynamique","word_nosc":"thermodynamique","lemma":"thermodynamique","pos":"ADJ"} ,
		{"word":"thermogène","word_nosc":"thermogene","lemma":"thermogène","pos":"ADJ"} ,
		{"word":"thermonucléaire","word_nosc":"thermonucleaire","lemma":"thermonucléaire","pos":"ADJ"} ,
		{"word":"thermonucléaires","word_nosc":"thermonucleaires","lemma":"thermonucléaire","pos":"ADJ"} ,
		{"word":"thermorégulateur","word_nosc":"thermoregulateur","lemma":"thermorégulateur","pos":"ADJ"} ,
		{"word":"thermos","word_nosc":"thermos","lemma":"thermos","pos":"ADJ"} ,
		{"word":"thermosensible","word_nosc":"thermosensible","lemma":"thermosensible","pos":"ADJ"} ,
		{"word":"thermoélectriques","word_nosc":"thermoelectriques","lemma":"thermoélectrique","pos":"ADJ"} ,
		{"word":"thessalien","word_nosc":"thessalien","lemma":"thessalien","pos":"ADJ"} ,
		{"word":"thessaliens","word_nosc":"thessaliens","lemma":"thessalien","pos":"ADJ"} ,
		{"word":"thomiste","word_nosc":"thomiste","lemma":"thomiste","pos":"ADJ"} ,
		{"word":"thoracique","word_nosc":"thoracique","lemma":"thoracique","pos":"ADJ"} ,
		{"word":"thoraciques","word_nosc":"thoraciques","lemma":"thoracique","pos":"ADJ"} ,
		{"word":"thrace","word_nosc":"thrace","lemma":"thrace","pos":"ADJ"} ,
		{"word":"thraces","word_nosc":"thraces","lemma":"thrace","pos":"ADJ"} ,
		{"word":"thrombolytique","word_nosc":"thrombolytique","lemma":"thrombolytique","pos":"ADJ"} ,
		{"word":"thrombosé","word_nosc":"thrombose","lemma":"thrombosé","pos":"ADJ"} ,
		{"word":"thrombotique","word_nosc":"thrombotique","lemma":"thrombotique","pos":"ADJ"} ,
		{"word":"thyroïde","word_nosc":"thyroide","lemma":"thyroïde","pos":"ADJ"} ,
		{"word":"thyroïdien","word_nosc":"thyroidien","lemma":"thyroïdien","pos":"ADJ"} ,
		{"word":"thyroïdienne","word_nosc":"thyroidienne","lemma":"thyroïdien","pos":"ADJ"} ,
		{"word":"théiste","word_nosc":"theiste","lemma":"théiste","pos":"ADJ"} ,
		{"word":"thématique","word_nosc":"thematique","lemma":"thématique","pos":"ADJ"} ,
		{"word":"thématiques","word_nosc":"thematiques","lemma":"thématique","pos":"ADJ"} ,
		{"word":"théocratique","word_nosc":"theocratique","lemma":"théocratique","pos":"ADJ"} ,
		{"word":"théologales","word_nosc":"theologales","lemma":"théologal","pos":"ADJ"} ,
		{"word":"théologique","word_nosc":"theologique","lemma":"théologique","pos":"ADJ"} ,
		{"word":"théologiques","word_nosc":"theologiques","lemma":"théologique","pos":"ADJ"} ,
		{"word":"théorique","word_nosc":"theorique","lemma":"théorique","pos":"ADJ"} ,
		{"word":"théoriques","word_nosc":"theoriques","lemma":"théorique","pos":"ADJ"} ,
		{"word":"théosophique","word_nosc":"theosophique","lemma":"théosophique","pos":"ADJ"} ,
		{"word":"thérapeutique","word_nosc":"therapeutique","lemma":"thérapeutique","pos":"ADJ"} ,
		{"word":"thérapeutiques","word_nosc":"therapeutiques","lemma":"thérapeutique","pos":"ADJ"} ,
		{"word":"thésard","word_nosc":"thesard","lemma":"thésard","pos":"ADJ"} ,
		{"word":"thésards","word_nosc":"thesards","lemma":"thésard","pos":"ADJ"} ,
		{"word":"théurgiques","word_nosc":"theurgiques","lemma":"théurgique","pos":"ADJ"} ,
		{"word":"théâtral","word_nosc":"theatral","lemma":"théâtral","pos":"ADJ"} ,
		{"word":"théâtrale","word_nosc":"theatrale","lemma":"théâtral","pos":"ADJ"} ,
		{"word":"théâtrales","word_nosc":"theatrales","lemma":"théâtral","pos":"ADJ"} ,
		{"word":"théâtraux","word_nosc":"theatraux","lemma":"théâtral","pos":"ADJ"} ,
		{"word":"tibial","word_nosc":"tibial","lemma":"tibial","pos":"ADJ"} ,
		{"word":"tibiale","word_nosc":"tibiale","lemma":"tibial","pos":"ADJ"} ,
		{"word":"tibétain","word_nosc":"tibetain","lemma":"tibétain","pos":"ADJ"} ,
		{"word":"tibétaine","word_nosc":"tibetaine","lemma":"tibétain","pos":"ADJ"} ,
		{"word":"tibétaines","word_nosc":"tibetaines","lemma":"tibétain","pos":"ADJ"} ,
		{"word":"tibétains","word_nosc":"tibetains","lemma":"tibétain","pos":"ADJ"} ,
		{"word":"tien","word_nosc":"tien","lemma":"tien","pos":"ADJ:pos"} ,
		{"word":"tienne","word_nosc":"tienne","lemma":"tienne","pos":"ADJ:pos"} ,
		{"word":"tiennes","word_nosc":"tiennes","lemma":"tiennes","pos":"ADJ:pos"} ,
		{"word":"tiens","word_nosc":"tiens","lemma":"tiens","pos":"ADJ:pos"} ,
		{"word":"tiens-la-moi","word_nosc":"tiens-la-moi","lemma":"tiens-la-moi","pos":"ADJ:pos"} ,
		{"word":"tierce","word_nosc":"tierce","lemma":"tiers","pos":"ADJ"} ,
		{"word":"tierces","word_nosc":"tierces","lemma":"tiers","pos":"ADJ"} ,
		{"word":"tiercé","word_nosc":"tierce","lemma":"tiercé","pos":"ADJ"} ,
		{"word":"tiers","word_nosc":"tiers","lemma":"tiers","pos":"ADJ"} ,
		{"word":"tiers-mondiste","word_nosc":"tiers-mondiste","lemma":"tiers-mondiste","pos":"ADJ"} ,
		{"word":"tiers-mondistes","word_nosc":"tiers-mondistes","lemma":"tiers-mondiste","pos":"ADJ"} ,
		{"word":"tigré","word_nosc":"tigre","lemma":"tigré","pos":"ADJ"} ,
		{"word":"tigrée","word_nosc":"tigree","lemma":"tigré","pos":"ADJ"} ,
		{"word":"tigrées","word_nosc":"tigrees","lemma":"tigré","pos":"ADJ"} ,
		{"word":"tigrés","word_nosc":"tigres","lemma":"tigré","pos":"ADJ"} ,
		{"word":"timbré","word_nosc":"timbre","lemma":"timbré","pos":"ADJ"} ,
		{"word":"timbrée","word_nosc":"timbree","lemma":"timbré","pos":"ADJ"} ,
		{"word":"timbrées","word_nosc":"timbrees","lemma":"timbré","pos":"ADJ"} ,
		{"word":"timbrés","word_nosc":"timbres","lemma":"timbré","pos":"ADJ"} ,
		{"word":"timide","word_nosc":"timide","lemma":"timide","pos":"ADJ"} ,
		{"word":"timides","word_nosc":"timides","lemma":"timide","pos":"ADJ"} ,
		{"word":"timoré","word_nosc":"timore","lemma":"timoré","pos":"ADJ"} ,
		{"word":"timorée","word_nosc":"timoree","lemma":"timoré","pos":"ADJ"} ,
		{"word":"timorées","word_nosc":"timorees","lemma":"timoré","pos":"ADJ"} ,
		{"word":"timorés","word_nosc":"timores","lemma":"timoré","pos":"ADJ"} ,
		{"word":"tintamarresque","word_nosc":"tintamarresque","lemma":"tintamarresque","pos":"ADJ"} ,
		{"word":"tintamarresques","word_nosc":"tintamarresques","lemma":"tintamarresque","pos":"ADJ"} ,
		{"word":"tintinnabulant","word_nosc":"tintinnabulant","lemma":"tintinnabulant","pos":"ADJ"} ,
		{"word":"tintinnabulante","word_nosc":"tintinnabulante","lemma":"tintinnabulant","pos":"ADJ"} ,
		{"word":"tintinnabulantes","word_nosc":"tintinnabulantes","lemma":"tintinnabulant","pos":"ADJ"} ,
		{"word":"tintinnabulants","word_nosc":"tintinnabulants","lemma":"tintinnabulant","pos":"ADJ"} ,
		{"word":"tiqueté","word_nosc":"tiquete","lemma":"tiqueté","pos":"ADJ"} ,
		{"word":"tiraillé","word_nosc":"tiraille","lemma":"tiraillé","pos":"ADJ"} ,
		{"word":"tiré","word_nosc":"tire","lemma":"tiré","pos":"ADJ"} ,
		{"word":"tirée","word_nosc":"tiree","lemma":"tiré","pos":"ADJ"} ,
		{"word":"tirées","word_nosc":"tirees","lemma":"tiré","pos":"ADJ"} ,
		{"word":"tirés","word_nosc":"tires","lemma":"tiré","pos":"ADJ"} ,
		{"word":"tisonné","word_nosc":"tisonne","lemma":"tisonné","pos":"ADJ"} ,
		{"word":"tisonnés","word_nosc":"tisonnes","lemma":"tisonné","pos":"ADJ"} ,
		{"word":"tissu","word_nosc":"tissu","lemma":"tissu","pos":"ADJ"} ,
		{"word":"tissue","word_nosc":"tissue","lemma":"tissu","pos":"ADJ"} ,
		{"word":"tissues","word_nosc":"tissues","lemma":"tissu","pos":"ADJ"} ,
		{"word":"tissulaire","word_nosc":"tissulaire","lemma":"tissulaire","pos":"ADJ"} ,
		{"word":"tissulaires","word_nosc":"tissulaires","lemma":"tissulaire","pos":"ADJ"} ,
		{"word":"tissus","word_nosc":"tissus","lemma":"tissu","pos":"ADJ"} ,
		{"word":"tissé","word_nosc":"tisse","lemma":"tissé","pos":"ADJ"} ,
		{"word":"tissée","word_nosc":"tissee","lemma":"tissé","pos":"ADJ"} ,
		{"word":"tissées","word_nosc":"tissees","lemma":"tissé","pos":"ADJ"} ,
		{"word":"tissés","word_nosc":"tisses","lemma":"tissé","pos":"ADJ"} ,
		{"word":"titanesque","word_nosc":"titanesque","lemma":"titanesque","pos":"ADJ"} ,
		{"word":"titanesques","word_nosc":"titanesques","lemma":"titanesque","pos":"ADJ"} ,
		{"word":"titanique","word_nosc":"titanique","lemma":"titanique","pos":"ADJ"} ,
		{"word":"titré","word_nosc":"titre","lemma":"titré","pos":"ADJ"} ,
		{"word":"titrée","word_nosc":"titree","lemma":"titré","pos":"ADJ"} ,
		{"word":"titrées","word_nosc":"titrees","lemma":"titré","pos":"ADJ"} ,
		{"word":"titrés","word_nosc":"titres","lemma":"titré","pos":"ADJ"} ,
		{"word":"titubant","word_nosc":"titubant","lemma":"titubant","pos":"ADJ"} ,
		{"word":"titubante","word_nosc":"titubante","lemma":"titubant","pos":"ADJ"} ,
		{"word":"titubantes","word_nosc":"titubantes","lemma":"titubant","pos":"ADJ"} ,
		{"word":"titubants","word_nosc":"titubants","lemma":"titubant","pos":"ADJ"} ,
		{"word":"titulaire","word_nosc":"titulaire","lemma":"titulaire","pos":"ADJ"} ,
		{"word":"titulaires","word_nosc":"titulaires","lemma":"titulaire","pos":"ADJ"} ,
		{"word":"tiède","word_nosc":"tiede","lemma":"tiède","pos":"ADJ"} ,
		{"word":"tièdes","word_nosc":"tiedes","lemma":"tiède","pos":"ADJ"} ,
		{"word":"tiédasse","word_nosc":"tiedasse","lemma":"tiédasse","pos":"ADJ"} ,
		{"word":"tiédasses","word_nosc":"tiedasses","lemma":"tiédasse","pos":"ADJ"} ,
		{"word":"toc","word_nosc":"toc","lemma":"toc","pos":"ADJ"} ,
		{"word":"toc","word_nosc":"toc","lemma":"toc.","pos":"ADJ"} ,
		{"word":"tocard","word_nosc":"tocard","lemma":"tocard","pos":"ADJ"} ,
		{"word":"tocarde","word_nosc":"tocarde","lemma":"tocard","pos":"ADJ"} ,
		{"word":"tocardes","word_nosc":"tocardes","lemma":"tocard","pos":"ADJ"} ,
		{"word":"tocards","word_nosc":"tocards","lemma":"tocard","pos":"ADJ"} ,
		{"word":"togolais","word_nosc":"togolais","lemma":"togolais","pos":"ADJ"} ,
		{"word":"togolaise","word_nosc":"togolaise","lemma":"togolais","pos":"ADJ"} ,
		{"word":"togolaises","word_nosc":"togolaises","lemma":"togolais","pos":"ADJ"} ,
		{"word":"tolstoïen","word_nosc":"tolstoien","lemma":"tolstoïen","pos":"ADJ"} ,
		{"word":"toltèque","word_nosc":"tolteque","lemma":"toltèque","pos":"ADJ"} ,
		{"word":"toltèques","word_nosc":"tolteques","lemma":"toltèque","pos":"ADJ"} ,
		{"word":"tolérable","word_nosc":"tolerable","lemma":"tolérable","pos":"ADJ"} ,
		{"word":"tolérables","word_nosc":"tolerables","lemma":"tolérable","pos":"ADJ"} ,
		{"word":"tolérant","word_nosc":"tolerant","lemma":"tolérant","pos":"ADJ"} ,
		{"word":"tolérante","word_nosc":"tolerante","lemma":"tolérant","pos":"ADJ"} ,
		{"word":"tolérantes","word_nosc":"tolerantes","lemma":"tolérant","pos":"ADJ"} ,
		{"word":"tolérants","word_nosc":"tolerants","lemma":"tolérant","pos":"ADJ"} ,
		{"word":"tombal","word_nosc":"tombal","lemma":"tombal","pos":"ADJ"} ,
		{"word":"tombale","word_nosc":"tombale","lemma":"tombal","pos":"ADJ"} ,
		{"word":"tombales","word_nosc":"tombales","lemma":"tombal","pos":"ADJ"} ,
		{"word":"tombant","word_nosc":"tombant","lemma":"tombant","pos":"ADJ"} ,
		{"word":"tombante","word_nosc":"tombante","lemma":"tombant","pos":"ADJ"} ,
		{"word":"tombantes","word_nosc":"tombantes","lemma":"tombant","pos":"ADJ"} ,
		{"word":"tombants","word_nosc":"tombants","lemma":"tombant","pos":"ADJ"} ,
		{"word":"tombé","word_nosc":"tombe","lemma":"tombé","pos":"ADJ"} ,
		{"word":"tombée","word_nosc":"tombee","lemma":"tombé","pos":"ADJ"} ,
		{"word":"tombées","word_nosc":"tombees","lemma":"tombé","pos":"ADJ"} ,
		{"word":"tombés","word_nosc":"tombes","lemma":"tombé","pos":"ADJ"} ,
		{"word":"ton","word_nosc":"ton","lemma":"ton","pos":"ADJ:pos"} ,
		{"word":"tonal","word_nosc":"tonal","lemma":"tonal","pos":"ADJ"} ,
		{"word":"tonale","word_nosc":"tonale","lemma":"tonal","pos":"ADJ"} ,
		{"word":"tondu","word_nosc":"tondu","lemma":"tondu","pos":"ADJ"} ,
		{"word":"tondue","word_nosc":"tondue","lemma":"tondu","pos":"ADJ"} ,
		{"word":"tondues","word_nosc":"tondues","lemma":"tondu","pos":"ADJ"} ,
		{"word":"tondus","word_nosc":"tondus","lemma":"tondu","pos":"ADJ"} ,
		{"word":"tonifiant","word_nosc":"tonifiant","lemma":"tonifiant","pos":"ADJ"} ,
		{"word":"tonifiante","word_nosc":"tonifiante","lemma":"tonifiant","pos":"ADJ"} ,
		{"word":"tonifiants","word_nosc":"tonifiants","lemma":"tonifiant","pos":"ADJ"} ,
		{"word":"tonique","word_nosc":"tonique","lemma":"tonique","pos":"ADJ"} ,
		{"word":"toniques","word_nosc":"toniques","lemma":"tonique","pos":"ADJ"} ,
		{"word":"tonitruant","word_nosc":"tonitruant","lemma":"tonitruant","pos":"ADJ"} ,
		{"word":"tonitruante","word_nosc":"tonitruante","lemma":"tonitruant","pos":"ADJ"} ,
		{"word":"tonitruantes","word_nosc":"tonitruantes","lemma":"tonitruant","pos":"ADJ"} ,
		{"word":"tonitruants","word_nosc":"tonitruants","lemma":"tonitruant","pos":"ADJ"} ,
		{"word":"tonkinois","word_nosc":"tonkinois","lemma":"tonkinois","pos":"ADJ"} ,
		{"word":"tonnant","word_nosc":"tonnant","lemma":"tonnant","pos":"ADJ"} ,
		{"word":"tonnante","word_nosc":"tonnante","lemma":"tonnant","pos":"ADJ"} ,
		{"word":"tonnantes","word_nosc":"tonnantes","lemma":"tonnant","pos":"ADJ"} ,
		{"word":"tonnants","word_nosc":"tonnants","lemma":"tonnant","pos":"ADJ"} ,
		{"word":"too much","word_nosc":"too much","lemma":"too much","pos":"ADJ"} ,
		{"word":"topique","word_nosc":"topique","lemma":"topique","pos":"ADJ"} ,
		{"word":"topless","word_nosc":"topless","lemma":"topless","pos":"ADJ"} ,
		{"word":"topographique","word_nosc":"topographique","lemma":"topographique","pos":"ADJ"} ,
		{"word":"topographiques","word_nosc":"topographiques","lemma":"topographique","pos":"ADJ"} ,
		{"word":"topologique","word_nosc":"topologique","lemma":"topologique","pos":"ADJ"} ,
		{"word":"toquard","word_nosc":"toquard","lemma":"toquard","pos":"ADJ"} ,
		{"word":"toquarde","word_nosc":"toquarde","lemma":"toquard","pos":"ADJ"} ,
		{"word":"toquardes","word_nosc":"toquardes","lemma":"toquard","pos":"ADJ"} ,
		{"word":"toquards","word_nosc":"toquards","lemma":"toquard","pos":"ADJ"} ,
		{"word":"toqué","word_nosc":"toque","lemma":"toqué","pos":"ADJ"} ,
		{"word":"toquée","word_nosc":"toquee","lemma":"toqué","pos":"ADJ"} ,
		{"word":"toquées","word_nosc":"toquees","lemma":"toqué","pos":"ADJ"} ,
		{"word":"toqués","word_nosc":"toques","lemma":"toqué","pos":"ADJ"} ,
		{"word":"tordant","word_nosc":"tordant","lemma":"tordant","pos":"ADJ"} ,
		{"word":"tordante","word_nosc":"tordante","lemma":"tordant","pos":"ADJ"} ,
		{"word":"tordantes","word_nosc":"tordantes","lemma":"tordant","pos":"ADJ"} ,
		{"word":"tordants","word_nosc":"tordants","lemma":"tordant","pos":"ADJ"} ,
		{"word":"tordu","word_nosc":"tordu","lemma":"tordu","pos":"ADJ"} ,
		{"word":"tordue","word_nosc":"tordue","lemma":"tordu","pos":"ADJ"} ,
		{"word":"tordues","word_nosc":"tordues","lemma":"tordu","pos":"ADJ"} ,
		{"word":"tordus","word_nosc":"tordus","lemma":"tordu","pos":"ADJ"} ,
		{"word":"torpide","word_nosc":"torpide","lemma":"torpide","pos":"ADJ"} ,
		{"word":"torpillé","word_nosc":"torpille","lemma":"torpillé","pos":"ADJ"} ,
		{"word":"torrentiel","word_nosc":"torrentiel","lemma":"torrentiel","pos":"ADJ"} ,
		{"word":"torrentielle","word_nosc":"torrentielle","lemma":"torrentiel","pos":"ADJ"} ,
		{"word":"torrentielles","word_nosc":"torrentielles","lemma":"torrentiel","pos":"ADJ"} ,
		{"word":"torrentiels","word_nosc":"torrentiels","lemma":"torrentiel","pos":"ADJ"} ,
		{"word":"torrentueuses","word_nosc":"torrentueuses","lemma":"torrentueux","pos":"ADJ"} ,
		{"word":"torrentueux","word_nosc":"torrentueux","lemma":"torrentueux","pos":"ADJ"} ,
		{"word":"torride","word_nosc":"torride","lemma":"torride","pos":"ADJ"} ,
		{"word":"torrides","word_nosc":"torrides","lemma":"torride","pos":"ADJ"} ,
		{"word":"torréfié","word_nosc":"torrefie","lemma":"torréfié","pos":"ADJ"} ,
		{"word":"torréfiée","word_nosc":"torrefiee","lemma":"torréfié","pos":"ADJ"} ,
		{"word":"torréfiées","word_nosc":"torrefiees","lemma":"torréfié","pos":"ADJ"} ,
		{"word":"tors","word_nosc":"tors","lemma":"tors","pos":"ADJ"} ,
		{"word":"torse","word_nosc":"torse","lemma":"tors","pos":"ADJ"} ,
		{"word":"torses","word_nosc":"torses","lemma":"tors","pos":"ADJ"} ,
		{"word":"tortu","word_nosc":"tortu","lemma":"tortu","pos":"ADJ"} ,
		{"word":"tortue","word_nosc":"tortue","lemma":"tortu","pos":"ADJ"} ,
		{"word":"tortues","word_nosc":"tortues","lemma":"tortu","pos":"ADJ"} ,
		{"word":"tortueuse","word_nosc":"tortueuse","lemma":"tortueux","pos":"ADJ"} ,
		{"word":"tortueuses","word_nosc":"tortueuses","lemma":"tortueux","pos":"ADJ"} ,
		{"word":"tortueux","word_nosc":"tortueux","lemma":"tortueux","pos":"ADJ"} ,
		{"word":"torturant","word_nosc":"torturant","lemma":"torturant","pos":"ADJ"} ,
		{"word":"torturante","word_nosc":"torturante","lemma":"torturant","pos":"ADJ"} ,
		{"word":"torturantes","word_nosc":"torturantes","lemma":"torturant","pos":"ADJ"} ,
		{"word":"torturants","word_nosc":"torturants","lemma":"torturant","pos":"ADJ"} ,
		{"word":"torturé","word_nosc":"torture","lemma":"torturé","pos":"ADJ"} ,
		{"word":"torturée","word_nosc":"torturee","lemma":"torturé","pos":"ADJ"} ,
		{"word":"torturées","word_nosc":"torturees","lemma":"torturé","pos":"ADJ"} ,
		{"word":"torturés","word_nosc":"tortures","lemma":"torturé","pos":"ADJ"} ,
		{"word":"tortus","word_nosc":"tortus","lemma":"tortu","pos":"ADJ"} ,
		{"word":"torve","word_nosc":"torve","lemma":"torve","pos":"ADJ"} ,
		{"word":"torves","word_nosc":"torves","lemma":"torve","pos":"ADJ"} ,
		{"word":"toscan","word_nosc":"toscan","lemma":"toscan","pos":"ADJ"} ,
		{"word":"toscane","word_nosc":"toscane","lemma":"toscan","pos":"ADJ"} ,
		{"word":"toscanes","word_nosc":"toscanes","lemma":"toscan","pos":"ADJ"} ,
		{"word":"toscans","word_nosc":"toscans","lemma":"toscan","pos":"ADJ"} ,
		{"word":"total","word_nosc":"total","lemma":"total","pos":"ADJ"} ,
		{"word":"totale","word_nosc":"totale","lemma":"total","pos":"ADJ"} ,
		{"word":"totales","word_nosc":"totales","lemma":"total","pos":"ADJ"} ,
		{"word":"totalisant","word_nosc":"totalisant","lemma":"totalisant","pos":"ADJ"} ,
		{"word":"totalitaire","word_nosc":"totalitaire","lemma":"totalitaire","pos":"ADJ"} ,
		{"word":"totalitaires","word_nosc":"totalitaires","lemma":"totalitaire","pos":"ADJ"} ,
		{"word":"totaux","word_nosc":"totaux","lemma":"total","pos":"ADJ"} ,
		{"word":"totémique","word_nosc":"totemique","lemma":"totémique","pos":"ADJ"} ,
		{"word":"totémiques","word_nosc":"totemiques","lemma":"totémique","pos":"ADJ"} ,
		{"word":"touareg","word_nosc":"touareg","lemma":"touareg","pos":"ADJ"} ,
		{"word":"touaregs","word_nosc":"touaregs","lemma":"touareg","pos":"ADJ"} ,
		{"word":"touchant","word_nosc":"touchant","lemma":"touchant","pos":"ADJ"} ,
		{"word":"touchante","word_nosc":"touchante","lemma":"touchant","pos":"ADJ"} ,
		{"word":"touchantes","word_nosc":"touchantes","lemma":"touchant","pos":"ADJ"} ,
		{"word":"touchants","word_nosc":"touchants","lemma":"touchant","pos":"ADJ"} ,
		{"word":"touché","word_nosc":"touche","lemma":"touché","pos":"ADJ"} ,
		{"word":"touchée","word_nosc":"touchee","lemma":"touché","pos":"ADJ"} ,
		{"word":"touchées","word_nosc":"touchees","lemma":"touché","pos":"ADJ"} ,
		{"word":"touchés","word_nosc":"touches","lemma":"touché","pos":"ADJ"} ,
		{"word":"touffu","word_nosc":"touffu","lemma":"touffu","pos":"ADJ"} ,
		{"word":"touffue","word_nosc":"touffue","lemma":"touffu","pos":"ADJ"} ,
		{"word":"touffues","word_nosc":"touffues","lemma":"touffu","pos":"ADJ"} ,
		{"word":"touffus","word_nosc":"touffus","lemma":"touffu","pos":"ADJ"} ,
		{"word":"touillé","word_nosc":"touille","lemma":"touillé","pos":"ADJ"} ,
		{"word":"touillée","word_nosc":"touillee","lemma":"touillé","pos":"ADJ"} ,
		{"word":"touillées","word_nosc":"touillees","lemma":"touillé","pos":"ADJ"} ,
		{"word":"toulonnais","word_nosc":"toulonnais","lemma":"toulonnais","pos":"ADJ"} ,
		{"word":"toulousain","word_nosc":"toulousain","lemma":"toulousain","pos":"ADJ"} ,
		{"word":"toulousaine","word_nosc":"toulousaine","lemma":"toulousain","pos":"ADJ"} ,
		{"word":"toulousaines","word_nosc":"toulousaines","lemma":"toulousain","pos":"ADJ"} ,
		{"word":"toulousains","word_nosc":"toulousains","lemma":"toulousain","pos":"ADJ"} ,
		{"word":"toungouse","word_nosc":"toungouse","lemma":"toungouse","pos":"ADJ"} ,
		{"word":"tourangeau","word_nosc":"tourangeau","lemma":"tourangeau","pos":"ADJ"} ,
		{"word":"tourangeaux","word_nosc":"tourangeaux","lemma":"tourangeau","pos":"ADJ"} ,
		{"word":"tourangelle","word_nosc":"tourangelle","lemma":"tourangeau","pos":"ADJ"} ,
		{"word":"tourangelles","word_nosc":"tourangelles","lemma":"tourangeau","pos":"ADJ"} ,
		{"word":"tourbeuse","word_nosc":"tourbeuse","lemma":"tourbeux","pos":"ADJ"} ,
		{"word":"tourbeux","word_nosc":"tourbeux","lemma":"tourbeux","pos":"ADJ"} ,
		{"word":"tourbillonnaire","word_nosc":"tourbillonnaire","lemma":"tourbillonnaire","pos":"ADJ"} ,
		{"word":"tourbillonnant","word_nosc":"tourbillonnant","lemma":"tourbillonnant","pos":"ADJ"} ,
		{"word":"tourbillonnante","word_nosc":"tourbillonnante","lemma":"tourbillonnant","pos":"ADJ"} ,
		{"word":"tourbillonnantes","word_nosc":"tourbillonnantes","lemma":"tourbillonnant","pos":"ADJ"} ,
		{"word":"tourbillonnants","word_nosc":"tourbillonnants","lemma":"tourbillonnant","pos":"ADJ"} ,
		{"word":"touristique","word_nosc":"touristique","lemma":"touristique","pos":"ADJ"} ,
		{"word":"touristiques","word_nosc":"touristiques","lemma":"touristique","pos":"ADJ"} ,
		{"word":"tourière","word_nosc":"touriere","lemma":"tourier","pos":"ADJ"} ,
		{"word":"tourmentant","word_nosc":"tourmentant","lemma":"tourmentant","pos":"ADJ"} ,
		{"word":"tourmentante","word_nosc":"tourmentante","lemma":"tourmentant","pos":"ADJ"} ,
		{"word":"tourmentantes","word_nosc":"tourmentantes","lemma":"tourmentant","pos":"ADJ"} ,
		{"word":"tourmenté","word_nosc":"tourmente","lemma":"tourmenté","pos":"ADJ"} ,
		{"word":"tourmentée","word_nosc":"tourmentee","lemma":"tourmenté","pos":"ADJ"} ,
		{"word":"tourmentées","word_nosc":"tourmentees","lemma":"tourmenté","pos":"ADJ"} ,
		{"word":"tourmentés","word_nosc":"tourmentes","lemma":"tourmenté","pos":"ADJ"} ,
		{"word":"tournant","word_nosc":"tournant","lemma":"tournant","pos":"ADJ"} ,
		{"word":"tournante","word_nosc":"tournante","lemma":"tournant","pos":"ADJ"} ,
		{"word":"tournantes","word_nosc":"tournantes","lemma":"tournant","pos":"ADJ"} ,
		{"word":"tournants","word_nosc":"tournants","lemma":"tournant","pos":"ADJ"} ,
		{"word":"tournois","word_nosc":"tournois","lemma":"tournois","pos":"ADJ"} ,
		{"word":"tournoyant","word_nosc":"tournoyant","lemma":"tournoyant","pos":"ADJ"} ,
		{"word":"tournoyante","word_nosc":"tournoyante","lemma":"tournoyant","pos":"ADJ"} ,
		{"word":"tournoyantes","word_nosc":"tournoyantes","lemma":"tournoyant","pos":"ADJ"} ,
		{"word":"tournoyants","word_nosc":"tournoyants","lemma":"tournoyant","pos":"ADJ"} ,
		{"word":"tourné","word_nosc":"tourne","lemma":"tourné","pos":"ADJ"} ,
		{"word":"tournée","word_nosc":"tournee","lemma":"tourné","pos":"ADJ"} ,
		{"word":"tournées","word_nosc":"tournees","lemma":"tourné","pos":"ADJ"} ,
		{"word":"tournés","word_nosc":"tournes","lemma":"tourné","pos":"ADJ"} ,
		{"word":"tous","word_nosc":"tous","lemma":"tout","pos":"ADJ"} ,
		{"word":"tous-terrains","word_nosc":"tous-terrains","lemma":"tous-terrains","pos":"ADJ"} ,
		{"word":"tousseur","word_nosc":"tousseur","lemma":"tousseur","pos":"ADJ"} ,
		{"word":"tousseurs","word_nosc":"tousseurs","lemma":"tousseur","pos":"ADJ"} ,
		{"word":"tousseux","word_nosc":"tousseux","lemma":"tousseux","pos":"ADJ"} ,
		{"word":"toussoteux","word_nosc":"toussoteux","lemma":"toussoteux","pos":"ADJ"} ,
		{"word":"tout","word_nosc":"tout","lemma":"tout","pos":"ADJ"} ,
		{"word":"tout","word_nosc":"tout","lemma":"tout","pos":"ADJ:ind"} ,
		{"word":"tout-fait","word_nosc":"tout-fait","lemma":"tout-fait","pos":"ADJ"} ,
		{"word":"tout-fait","word_nosc":"tout-fait","lemma":"tout-fait","pos":"ADJ:ind"} ,
		{"word":"tout-fou","word_nosc":"tout-fou","lemma":"tout-fou","pos":"ADJ"} ,
		{"word":"tout-puissant","word_nosc":"tout-puissant","lemma":"tout-puissant","pos":"ADJ"} ,
		{"word":"tout-puissants","word_nosc":"tout-puissants","lemma":"tout-puissant","pos":"ADJ"} ,
		{"word":"tout-terrain","word_nosc":"tout-terrain","lemma":"tout-terrain","pos":"ADJ"} ,
		{"word":"toute","word_nosc":"toute","lemma":"tout","pos":"ADJ"} ,
		{"word":"toute","word_nosc":"toute","lemma":"toute","pos":"ADJ:ind"} ,
		{"word":"toute-puissante","word_nosc":"toute-puissante","lemma":"tout-puissant","pos":"ADJ"} ,
		{"word":"toutes","word_nosc":"toutes","lemma":"tout","pos":"ADJ"} ,
		{"word":"toutes","word_nosc":"toutes","lemma":"toutes","pos":"ADJ:ind"} ,
		{"word":"toutes-puissantes","word_nosc":"toutes-puissantes","lemma":"tout-puissant","pos":"ADJ"} ,
		{"word":"toxico","word_nosc":"toxico","lemma":"toxico","pos":"ADJ"} ,
		{"word":"toxicologique","word_nosc":"toxicologique","lemma":"toxicologique","pos":"ADJ"} ,
		{"word":"toxicos","word_nosc":"toxicos","lemma":"toxico","pos":"ADJ"} ,
		{"word":"toxique","word_nosc":"toxique","lemma":"toxique","pos":"ADJ"} ,
		{"word":"toxiques","word_nosc":"toxiques","lemma":"toxique","pos":"ADJ"} ,
		{"word":"tracassier","word_nosc":"tracassier","lemma":"tracassier","pos":"ADJ"} ,
		{"word":"tracassières","word_nosc":"tracassieres","lemma":"tracassier","pos":"ADJ"} ,
		{"word":"traceur","word_nosc":"traceur","lemma":"traceur","pos":"ADJ"} ,
		{"word":"traceurs","word_nosc":"traceurs","lemma":"traceur","pos":"ADJ"} ,
		{"word":"traceuse","word_nosc":"traceuse","lemma":"traceur","pos":"ADJ"} ,
		{"word":"traceuses","word_nosc":"traceuses","lemma":"traceur","pos":"ADJ"} ,
		{"word":"trachéal","word_nosc":"tracheal","lemma":"trachéal","pos":"ADJ"} ,
		{"word":"trachéale","word_nosc":"tracheale","lemma":"trachéal","pos":"ADJ"} ,
		{"word":"tracteur","word_nosc":"tracteur","lemma":"tracteur","pos":"ADJ"} ,
		{"word":"tracteurs","word_nosc":"tracteurs","lemma":"tracteur","pos":"ADJ"} ,
		{"word":"tracté","word_nosc":"tracte","lemma":"tracté","pos":"ADJ"} ,
		{"word":"tractés","word_nosc":"tractes","lemma":"tracté","pos":"ADJ"} ,
		{"word":"traditionaliste","word_nosc":"traditionaliste","lemma":"traditionaliste","pos":"ADJ"} ,
		{"word":"traditionnel","word_nosc":"traditionnel","lemma":"traditionnel","pos":"ADJ"} ,
		{"word":"traditionnelle","word_nosc":"traditionnelle","lemma":"traditionnel","pos":"ADJ"} ,
		{"word":"traditionnelles","word_nosc":"traditionnelles","lemma":"traditionnel","pos":"ADJ"} ,
		{"word":"traditionnels","word_nosc":"traditionnels","lemma":"traditionnel","pos":"ADJ"} ,
		{"word":"traduisibles","word_nosc":"traduisibles","lemma":"traduisible","pos":"ADJ"} ,
		{"word":"tragi-comique","word_nosc":"tragi-comique","lemma":"tragi-comique","pos":"ADJ"} ,
		{"word":"tragi-comiques","word_nosc":"tragi-comiques","lemma":"tragi-comique","pos":"ADJ"} ,
		{"word":"tragique","word_nosc":"tragique","lemma":"tragique","pos":"ADJ"} ,
		{"word":"tragiques","word_nosc":"tragiques","lemma":"tragique","pos":"ADJ"} ,
		{"word":"trahi","word_nosc":"trahi","lemma":"trahi","pos":"ADJ"} ,
		{"word":"trahie","word_nosc":"trahie","lemma":"trahi","pos":"ADJ"} ,
		{"word":"trahies","word_nosc":"trahies","lemma":"trahi","pos":"ADJ"} ,
		{"word":"trahis","word_nosc":"trahis","lemma":"trahi","pos":"ADJ"} ,
		{"word":"trahissant","word_nosc":"trahissant","lemma":"trahissant","pos":"ADJ"} ,
		{"word":"trait","word_nosc":"trait","lemma":"trait","pos":"ADJ"} ,
		{"word":"traitable","word_nosc":"traitable","lemma":"traitable","pos":"ADJ"} ,
		{"word":"traitables","word_nosc":"traitables","lemma":"traitable","pos":"ADJ"} ,
		{"word":"traitant","word_nosc":"traitant","lemma":"traitant","pos":"ADJ"} ,
		{"word":"traitante","word_nosc":"traitante","lemma":"traitant","pos":"ADJ"} ,
		{"word":"traitants","word_nosc":"traitants","lemma":"traitant","pos":"ADJ"} ,
		{"word":"traite","word_nosc":"traite","lemma":"trait","pos":"ADJ"} ,
		{"word":"traites","word_nosc":"traites","lemma":"trait","pos":"ADJ"} ,
		{"word":"traits","word_nosc":"traits","lemma":"trait","pos":"ADJ"} ,
		{"word":"traité","word_nosc":"traite","lemma":"traité","pos":"ADJ"} ,
		{"word":"traitée","word_nosc":"traitee","lemma":"traité","pos":"ADJ"} ,
		{"word":"traitées","word_nosc":"traitees","lemma":"traité","pos":"ADJ"} ,
		{"word":"traités","word_nosc":"traites","lemma":"traité","pos":"ADJ"} ,
		{"word":"tranchant","word_nosc":"tranchant","lemma":"tranchant","pos":"ADJ"} ,
		{"word":"tranchante","word_nosc":"tranchante","lemma":"tranchant","pos":"ADJ"} ,
		{"word":"tranchantes","word_nosc":"tranchantes","lemma":"tranchant","pos":"ADJ"} ,
		{"word":"tranchants","word_nosc":"tranchants","lemma":"tranchant","pos":"ADJ"} ,
		{"word":"tranché","word_nosc":"tranche","lemma":"tranché","pos":"ADJ"} ,
		{"word":"tranchée","word_nosc":"tranchee","lemma":"tranché","pos":"ADJ"} ,
		{"word":"tranchées","word_nosc":"tranchees","lemma":"tranché","pos":"ADJ"} ,
		{"word":"tranchés","word_nosc":"tranches","lemma":"tranché","pos":"ADJ"} ,
		{"word":"tranquille","word_nosc":"tranquille","lemma":"tranquille","pos":"ADJ"} ,
		{"word":"tranquilles","word_nosc":"tranquilles","lemma":"tranquille","pos":"ADJ"} ,
		{"word":"tranquillisant","word_nosc":"tranquillisant","lemma":"tranquillisant","pos":"ADJ"} ,
		{"word":"tranquillisante","word_nosc":"tranquillisante","lemma":"tranquillisant","pos":"ADJ"} ,
		{"word":"tranquillisantes","word_nosc":"tranquillisantes","lemma":"tranquillisant","pos":"ADJ"} ,
		{"word":"tranquillisants","word_nosc":"tranquillisants","lemma":"tranquillisant","pos":"ADJ"} ,
		{"word":"trans","word_nosc":"trans","lemma":"trans","pos":"ADJ"} ,
		{"word":"transactionnelle","word_nosc":"transactionnelle","lemma":"transactionnel","pos":"ADJ"} ,
		{"word":"transafricain","word_nosc":"transafricain","lemma":"transafricain","pos":"ADJ"} ,
		{"word":"transalpines","word_nosc":"transalpines","lemma":"transalpin","pos":"ADJ"} ,
		{"word":"transamazonienne","word_nosc":"transamazonienne","lemma":"transamazonien","pos":"ADJ"} ,
		{"word":"transatlantique","word_nosc":"transatlantique","lemma":"transatlantique","pos":"ADJ"} ,
		{"word":"transatlantiques","word_nosc":"transatlantiques","lemma":"transatlantique","pos":"ADJ"} ,
		{"word":"transcanadienne","word_nosc":"transcanadienne","lemma":"transcanadienne","pos":"ADJ"} ,
		{"word":"transcaspien","word_nosc":"transcaspien","lemma":"transcaspien","pos":"ADJ"} ,
		{"word":"transcendant","word_nosc":"transcendant","lemma":"transcendant","pos":"ADJ"} ,
		{"word":"transcendantal","word_nosc":"transcendantal","lemma":"transcendantal","pos":"ADJ"} ,
		{"word":"transcendantale","word_nosc":"transcendantale","lemma":"transcendantal","pos":"ADJ"} ,
		{"word":"transcendantales","word_nosc":"transcendantales","lemma":"transcendantal","pos":"ADJ"} ,
		{"word":"transcendante","word_nosc":"transcendante","lemma":"transcendant","pos":"ADJ"} ,
		{"word":"transcendantes","word_nosc":"transcendantes","lemma":"transcendant","pos":"ADJ"} ,
		{"word":"transcendants","word_nosc":"transcendants","lemma":"transcendant","pos":"ADJ"} ,
		{"word":"transcontinental","word_nosc":"transcontinental","lemma":"transcontinental","pos":"ADJ"} ,
		{"word":"transcontinentale","word_nosc":"transcontinentale","lemma":"transcontinental","pos":"ADJ"} ,
		{"word":"transcutané","word_nosc":"transcutane","lemma":"transcutané","pos":"ADJ"} ,
		{"word":"transdermique","word_nosc":"transdermique","lemma":"transdermique","pos":"ADJ"} ,
		{"word":"transformable","word_nosc":"transformable","lemma":"transformable","pos":"ADJ"} ,
		{"word":"transformant","word_nosc":"transformant","lemma":"transformant","pos":"ADJ"} ,
		{"word":"transformateur","word_nosc":"transformateur","lemma":"transformateur","pos":"ADJ"} ,
		{"word":"transformateurs","word_nosc":"transformateurs","lemma":"transformateur","pos":"ADJ"} ,
		{"word":"transformatrice","word_nosc":"transformatrice","lemma":"transformateur","pos":"ADJ"} ,
		{"word":"transformiste","word_nosc":"transformiste","lemma":"transformiste","pos":"ADJ"} ,
		{"word":"transformé","word_nosc":"transforme","lemma":"transformé","pos":"ADJ"} ,
		{"word":"transformée","word_nosc":"transformee","lemma":"transformé","pos":"ADJ"} ,
		{"word":"transformés","word_nosc":"transformes","lemma":"transformé","pos":"ADJ"} ,
		{"word":"transfusé","word_nosc":"transfuse","lemma":"transfusé","pos":"ADJ"} ,
		{"word":"transférable","word_nosc":"transferable","lemma":"transférable","pos":"ADJ"} ,
		{"word":"transgénique","word_nosc":"transgenique","lemma":"transgénique","pos":"ADJ"} ,
		{"word":"transgéniques","word_nosc":"transgeniques","lemma":"transgénique","pos":"ADJ"} ,
		{"word":"transhistorique","word_nosc":"transhistorique","lemma":"transhistorique","pos":"ADJ"} ,
		{"word":"transhumant","word_nosc":"transhumant","lemma":"transhumant","pos":"ADJ"} ,
		{"word":"transhumantes","word_nosc":"transhumantes","lemma":"transhumant","pos":"ADJ"} ,
		{"word":"transhumants","word_nosc":"transhumants","lemma":"transhumant","pos":"ADJ"} ,
		{"word":"transi","word_nosc":"transi","lemma":"transi","pos":"ADJ"} ,
		{"word":"transie","word_nosc":"transie","lemma":"transi","pos":"ADJ"} ,
		{"word":"transies","word_nosc":"transies","lemma":"transi","pos":"ADJ"} ,
		{"word":"transis","word_nosc":"transis","lemma":"transi","pos":"ADJ"} ,
		{"word":"transistorisées","word_nosc":"transistorisees","lemma":"transistorisé","pos":"ADJ"} ,
		{"word":"transistorisés","word_nosc":"transistorises","lemma":"transistorisé","pos":"ADJ"} ,
		{"word":"transit","word_nosc":"transit","lemma":"transit","pos":"ADJ"} ,
		{"word":"transitaire","word_nosc":"transitaire","lemma":"transitaire","pos":"ADJ"} ,
		{"word":"transitaires","word_nosc":"transitaires","lemma":"transitaire","pos":"ADJ"} ,
		{"word":"transitif","word_nosc":"transitif","lemma":"transitif","pos":"ADJ"} ,
		{"word":"transitifs","word_nosc":"transitifs","lemma":"transitif","pos":"ADJ"} ,
		{"word":"transitionnel","word_nosc":"transitionnel","lemma":"transitionnel","pos":"ADJ"} ,
		{"word":"transitionnelle","word_nosc":"transitionnelle","lemma":"transitionnel","pos":"ADJ"} ,
		{"word":"transitoire","word_nosc":"transitoire","lemma":"transitoire","pos":"ADJ"} ,
		{"word":"transitoires","word_nosc":"transitoires","lemma":"transitoire","pos":"ADJ"} ,
		{"word":"translucide","word_nosc":"translucide","lemma":"translucide","pos":"ADJ"} ,
		{"word":"translucides","word_nosc":"translucides","lemma":"translucide","pos":"ADJ"} ,
		{"word":"transmis","word_nosc":"transmis","lemma":"transmis","pos":"ADJ"} ,
		{"word":"transmise","word_nosc":"transmise","lemma":"transmis","pos":"ADJ"} ,
		{"word":"transmises","word_nosc":"transmises","lemma":"transmis","pos":"ADJ"} ,
		{"word":"transmissible","word_nosc":"transmissible","lemma":"transmissible","pos":"ADJ"} ,
		{"word":"transmissibles","word_nosc":"transmissibles","lemma":"transmissible","pos":"ADJ"} ,
		{"word":"transnational","word_nosc":"transnational","lemma":"transnational","pos":"ADJ"} ,
		{"word":"transnationaux","word_nosc":"transnationaux","lemma":"transnational","pos":"ADJ"} ,
		{"word":"transocéanique","word_nosc":"transoceanique","lemma":"transocéanique","pos":"ADJ"} ,
		{"word":"transocéaniques","word_nosc":"transoceaniques","lemma":"transocéanique","pos":"ADJ"} ,
		{"word":"transparent","word_nosc":"transparent","lemma":"transparent","pos":"ADJ"} ,
		{"word":"transparente","word_nosc":"transparente","lemma":"transparent","pos":"ADJ"} ,
		{"word":"transparentes","word_nosc":"transparentes","lemma":"transparent","pos":"ADJ"} ,
		{"word":"transparents","word_nosc":"transparents","lemma":"transparent","pos":"ADJ"} ,
		{"word":"transpirant","word_nosc":"transpirant","lemma":"transpirant","pos":"ADJ"} ,
		{"word":"transpirante","word_nosc":"transpirante","lemma":"transpirant","pos":"ADJ"} ,
		{"word":"transpirants","word_nosc":"transpirants","lemma":"transpirant","pos":"ADJ"} ,
		{"word":"transplantable","word_nosc":"transplantable","lemma":"transplantable","pos":"ADJ"} ,
		{"word":"transplanté","word_nosc":"transplante","lemma":"transplanté","pos":"ADJ"} ,
		{"word":"transplantée","word_nosc":"transplantee","lemma":"transplanté","pos":"ADJ"} ,
		{"word":"transplantées","word_nosc":"transplantees","lemma":"transplanté","pos":"ADJ"} ,
		{"word":"transplantés","word_nosc":"transplantes","lemma":"transplanté","pos":"ADJ"} ,
		{"word":"transportable","word_nosc":"transportable","lemma":"transportable","pos":"ADJ"} ,
		{"word":"transportables","word_nosc":"transportables","lemma":"transportable","pos":"ADJ"} ,
		{"word":"transporteur","word_nosc":"transporteur","lemma":"transporteur","pos":"ADJ"} ,
		{"word":"transporteurs","word_nosc":"transporteurs","lemma":"transporteur","pos":"ADJ"} ,
		{"word":"transporteuse","word_nosc":"transporteuse","lemma":"transporteur","pos":"ADJ"} ,
		{"word":"transposable","word_nosc":"transposable","lemma":"transposable","pos":"ADJ"} ,
		{"word":"transposables","word_nosc":"transposables","lemma":"transposable","pos":"ADJ"} ,
		{"word":"transposée","word_nosc":"transposee","lemma":"transposée","pos":"ADJ"} ,
		{"word":"transsaharienne","word_nosc":"transsaharienne","lemma":"transsaharien","pos":"ADJ"} ,
		{"word":"transsexuel","word_nosc":"transsexuel","lemma":"transsexuel","pos":"ADJ"} ,
		{"word":"transsexuelle","word_nosc":"transsexuelle","lemma":"transsexuel","pos":"ADJ"} ,
		{"word":"transsexuels","word_nosc":"transsexuels","lemma":"transsexuel","pos":"ADJ"} ,
		{"word":"transsibérienne","word_nosc":"transsiberienne","lemma":"transsibérien","pos":"ADJ"} ,
		{"word":"transuranienne","word_nosc":"transuranienne","lemma":"transuranien","pos":"ADJ"} ,
		{"word":"transversal","word_nosc":"transversal","lemma":"transversal","pos":"ADJ"} ,
		{"word":"transversale","word_nosc":"transversale","lemma":"transversal","pos":"ADJ"} ,
		{"word":"transversales","word_nosc":"transversales","lemma":"transversal","pos":"ADJ"} ,
		{"word":"transversaux","word_nosc":"transversaux","lemma":"transversal","pos":"ADJ"} ,
		{"word":"transverse","word_nosc":"transverse","lemma":"transverse","pos":"ADJ"} ,
		{"word":"transylvain","word_nosc":"transylvain","lemma":"transylvain","pos":"ADJ"} ,
		{"word":"transylvanienne","word_nosc":"transylvanienne","lemma":"transylvanien","pos":"ADJ"} ,
		{"word":"trapu","word_nosc":"trapu","lemma":"trapu","pos":"ADJ"} ,
		{"word":"trapue","word_nosc":"trapue","lemma":"trapu","pos":"ADJ"} ,
		{"word":"trapues","word_nosc":"trapues","lemma":"trapu","pos":"ADJ"} ,
		{"word":"trapus","word_nosc":"trapus","lemma":"trapu","pos":"ADJ"} ,
		{"word":"trapézoïdal","word_nosc":"trapezoidal","lemma":"trapézoïdal","pos":"ADJ"} ,
		{"word":"trapézoïdale","word_nosc":"trapezoidale","lemma":"trapézoïdal","pos":"ADJ"} ,
		{"word":"trapézoïdales","word_nosc":"trapezoidales","lemma":"trapézoïdal","pos":"ADJ"} ,
		{"word":"trapézoïdaux","word_nosc":"trapezoidaux","lemma":"trapézoïdal","pos":"ADJ"} ,
		{"word":"trapézoïde","word_nosc":"trapezoide","lemma":"trapézoïde","pos":"ADJ"} ,
		{"word":"traumatique","word_nosc":"traumatique","lemma":"traumatique","pos":"ADJ"} ,
		{"word":"traumatiques","word_nosc":"traumatiques","lemma":"traumatique","pos":"ADJ"} ,
		{"word":"traumatisant","word_nosc":"traumatisant","lemma":"traumatisant","pos":"ADJ"} ,
		{"word":"traumatisante","word_nosc":"traumatisante","lemma":"traumatisant","pos":"ADJ"} ,
		{"word":"traumatisantes","word_nosc":"traumatisantes","lemma":"traumatisant","pos":"ADJ"} ,
		{"word":"traumatisants","word_nosc":"traumatisants","lemma":"traumatisant","pos":"ADJ"} ,
		{"word":"traumatologique","word_nosc":"traumatologique","lemma":"traumatologique","pos":"ADJ"} ,
		{"word":"travailleur","word_nosc":"travailleur","lemma":"travailleur","pos":"ADJ"} ,
		{"word":"travailleurs","word_nosc":"travailleurs","lemma":"travailleur","pos":"ADJ"} ,
		{"word":"travailleuse","word_nosc":"travailleuse","lemma":"travailleur","pos":"ADJ"} ,
		{"word":"travailleuses","word_nosc":"travailleuses","lemma":"travailleur","pos":"ADJ"} ,
		{"word":"travailliste","word_nosc":"travailliste","lemma":"travailliste","pos":"ADJ"} ,
		{"word":"travaillistes","word_nosc":"travaillistes","lemma":"travailliste","pos":"ADJ"} ,
		{"word":"travaillé","word_nosc":"travaille","lemma":"travaillé","pos":"ADJ"} ,
		{"word":"travaillée","word_nosc":"travaillee","lemma":"travaillé","pos":"ADJ"} ,
		{"word":"travaillées","word_nosc":"travaillees","lemma":"travaillé","pos":"ADJ"} ,
		{"word":"travaillés","word_nosc":"travailles","lemma":"travaillé","pos":"ADJ"} ,
		{"word":"traversable","word_nosc":"traversable","lemma":"traversable","pos":"ADJ"} ,
		{"word":"traversables","word_nosc":"traversables","lemma":"traversable","pos":"ADJ"} ,
		{"word":"traversant","word_nosc":"traversant","lemma":"traversant","pos":"ADJ"} ,
		{"word":"traversier","word_nosc":"traversier","lemma":"traversier","pos":"ADJ"} ,
		{"word":"traversière","word_nosc":"traversiere","lemma":"traversier","pos":"ADJ"} ,
		{"word":"traversières","word_nosc":"traversieres","lemma":"traversier","pos":"ADJ"} ,
		{"word":"traversé","word_nosc":"traverse","lemma":"traversé","pos":"ADJ"} ,
		{"word":"traversés","word_nosc":"traverses","lemma":"traversé","pos":"ADJ"} ,
		{"word":"travesti","word_nosc":"travesti","lemma":"travesti","pos":"ADJ"} ,
		{"word":"travestie","word_nosc":"travestie","lemma":"travesti","pos":"ADJ"} ,
		{"word":"travestis","word_nosc":"travestis","lemma":"travesti","pos":"ADJ"} ,
		{"word":"traçante","word_nosc":"tracante","lemma":"traçant","pos":"ADJ"} ,
		{"word":"traçantes","word_nosc":"tracantes","lemma":"traçant","pos":"ADJ"} ,
		{"word":"traînant","word_nosc":"trainant","lemma":"traînant","pos":"ADJ"} ,
		{"word":"traînante","word_nosc":"trainante","lemma":"traînant","pos":"ADJ"} ,
		{"word":"traînantes","word_nosc":"trainantes","lemma":"traînant","pos":"ADJ"} ,
		{"word":"traînants","word_nosc":"trainants","lemma":"traînant","pos":"ADJ"} ,
		{"word":"traînard","word_nosc":"trainard","lemma":"traînard","pos":"ADJ"} ,
		{"word":"traînarde","word_nosc":"trainarde","lemma":"traînard","pos":"ADJ"} ,
		{"word":"traînards","word_nosc":"trainards","lemma":"traînard","pos":"ADJ"} ,
		{"word":"traînée","word_nosc":"trainee","lemma":"traînée","pos":"ADJ"} ,
		{"word":"traînées","word_nosc":"trainees","lemma":"traînée","pos":"ADJ"} ,
		{"word":"traître","word_nosc":"traitre","lemma":"traître","pos":"ADJ"} ,
		{"word":"traîtres","word_nosc":"traitres","lemma":"traître","pos":"ADJ"} ,
		{"word":"traîtresse","word_nosc":"traitresse","lemma":"traître","pos":"ADJ"} ,
		{"word":"traîtresses","word_nosc":"traitresses","lemma":"traître","pos":"ADJ"} ,
		{"word":"treize","word_nosc":"treize","lemma":"treize","pos":"ADJ:num"} ,
		{"word":"treizième","word_nosc":"treizieme","lemma":"treizième","pos":"ADJ"} ,
		{"word":"tremblant","word_nosc":"tremblant","lemma":"tremblant","pos":"ADJ"} ,
		{"word":"tremblante","word_nosc":"tremblante","lemma":"tremblant","pos":"ADJ"} ,
		{"word":"tremblantes","word_nosc":"tremblantes","lemma":"tremblant","pos":"ADJ"} ,
		{"word":"tremblants","word_nosc":"tremblants","lemma":"tremblant","pos":"ADJ"} ,
		{"word":"trembleur","word_nosc":"trembleur","lemma":"trembleur","pos":"ADJ"} ,
		{"word":"trembleurs","word_nosc":"trembleurs","lemma":"trembleur","pos":"ADJ"} ,
		{"word":"tremblotant","word_nosc":"tremblotant","lemma":"tremblotant","pos":"ADJ"} ,
		{"word":"tremblotante","word_nosc":"tremblotante","lemma":"tremblotant","pos":"ADJ"} ,
		{"word":"tremblotantes","word_nosc":"tremblotantes","lemma":"tremblotant","pos":"ADJ"} ,
		{"word":"tremblotants","word_nosc":"tremblotants","lemma":"tremblotant","pos":"ADJ"} ,
		{"word":"tremblé","word_nosc":"tremble","lemma":"tremblé","pos":"ADJ"} ,
		{"word":"tremblée","word_nosc":"tremblee","lemma":"tremblé","pos":"ADJ"} ,
		{"word":"tremblées","word_nosc":"tremblees","lemma":"tremblé","pos":"ADJ"} ,
		{"word":"tremblés","word_nosc":"trembles","lemma":"tremblé","pos":"ADJ"} ,
		{"word":"trempé","word_nosc":"trempe","lemma":"trempé","pos":"ADJ"} ,
		{"word":"trempée","word_nosc":"trempee","lemma":"trempé","pos":"ADJ"} ,
		{"word":"trempées","word_nosc":"trempees","lemma":"trempé","pos":"ADJ"} ,
		{"word":"trempés","word_nosc":"trempes","lemma":"trempé","pos":"ADJ"} ,
		{"word":"trente","word_nosc":"trente","lemma":"trente","pos":"ADJ:num"} ,
		{"word":"trente-cinq","word_nosc":"trente-cinq","lemma":"trente-cinq","pos":"ADJ:num"} ,
		{"word":"trente-cinquième","word_nosc":"trente-cinquieme","lemma":"trente-cinquième","pos":"ADJ"} ,
		{"word":"trente-deux","word_nosc":"trente-deux","lemma":"trente-deux","pos":"ADJ:num"} ,
		{"word":"trente-et-unième","word_nosc":"trente-et-unieme","lemma":"trente-et-unième","pos":"ADJ"} ,
		{"word":"trente-huit","word_nosc":"trente-huit","lemma":"trente-huit","pos":"ADJ:num"} ,
		{"word":"trente-huitième","word_nosc":"trente-huitieme","lemma":"trente-huitième","pos":"ADJ"} ,
		{"word":"trente-neuf","word_nosc":"trente-neuf","lemma":"trente-neuf","pos":"ADJ:num"} ,
		{"word":"trente-neuvième","word_nosc":"trente-neuvieme","lemma":"trente-neuvième","pos":"ADJ"} ,
		{"word":"trente-quatre","word_nosc":"trente-quatre","lemma":"trente-quatre","pos":"ADJ:num"} ,
		{"word":"trente-quatrième","word_nosc":"trente-quatrieme","lemma":"trente-quatrième","pos":"ADJ"} ,
		{"word":"trente-sept","word_nosc":"trente-sept","lemma":"trente-sept","pos":"ADJ:num"} ,
		{"word":"trente-septième","word_nosc":"trente-septieme","lemma":"trente-septième","pos":"ADJ"} ,
		{"word":"trente-six","word_nosc":"trente-six","lemma":"trente-six","pos":"ADJ:num"} ,
		{"word":"trente-sixième","word_nosc":"trente-sixieme","lemma":"trente-sixième","pos":"ADJ"} ,
		{"word":"trente-trois","word_nosc":"trente-trois","lemma":"trente-trois","pos":"ADJ:num"} ,
		{"word":"trente-troisième","word_nosc":"trente-troisieme","lemma":"trente-troisième","pos":"ADJ"} ,
		{"word":"trentenaire","word_nosc":"trentenaire","lemma":"trentenaire","pos":"ADJ"} ,
		{"word":"trentenaires","word_nosc":"trentenaires","lemma":"trentenaire","pos":"ADJ"} ,
		{"word":"trentième","word_nosc":"trentieme","lemma":"trentième","pos":"ADJ"} ,
		{"word":"tressautant","word_nosc":"tressautant","lemma":"tressautant","pos":"ADJ"} ,
		{"word":"tressautante","word_nosc":"tressautante","lemma":"tressautant","pos":"ADJ"} ,
		{"word":"triangulaire","word_nosc":"triangulaire","lemma":"triangulaire","pos":"ADJ"} ,
		{"word":"triangulaires","word_nosc":"triangulaires","lemma":"triangulaire","pos":"ADJ"} ,
		{"word":"triasique","word_nosc":"triasique","lemma":"triasique","pos":"ADJ"} ,
		{"word":"tribal","word_nosc":"tribal","lemma":"tribal","pos":"ADJ"} ,
		{"word":"tribale","word_nosc":"tribale","lemma":"tribal","pos":"ADJ"} ,
		{"word":"tribales","word_nosc":"tribales","lemma":"tribal","pos":"ADJ"} ,
		{"word":"tribaux","word_nosc":"tribaux","lemma":"tribal","pos":"ADJ"} ,
		{"word":"tributaire","word_nosc":"tributaire","lemma":"tributaire","pos":"ADJ"} ,
		{"word":"tributaires","word_nosc":"tributaires","lemma":"tributaire","pos":"ADJ"} ,
		{"word":"tricard","word_nosc":"tricard","lemma":"tricard","pos":"ADJ"} ,
		{"word":"tricarde","word_nosc":"tricarde","lemma":"tricard","pos":"ADJ"} ,
		{"word":"tricards","word_nosc":"tricards","lemma":"tricard","pos":"ADJ"} ,
		{"word":"tricheur","word_nosc":"tricheur","lemma":"tricheur","pos":"ADJ"} ,
		{"word":"tricheurs","word_nosc":"tricheurs","lemma":"tricheur","pos":"ADJ"} ,
		{"word":"tricheuse","word_nosc":"tricheuse","lemma":"tricheur","pos":"ADJ"} ,
		{"word":"tricheuses","word_nosc":"tricheuses","lemma":"tricheur","pos":"ADJ"} ,
		{"word":"trichloracétique","word_nosc":"trichloracetique","lemma":"trichloracétique","pos":"ADJ"} ,
		{"word":"tricolore","word_nosc":"tricolore","lemma":"tricolore","pos":"ADJ"} ,
		{"word":"tricolores","word_nosc":"tricolores","lemma":"tricolore","pos":"ADJ"} ,
		{"word":"tricuspide","word_nosc":"tricuspide","lemma":"tricuspide","pos":"ADJ"} ,
		{"word":"tricéphale","word_nosc":"tricephale","lemma":"tricéphale","pos":"ADJ"} ,
		{"word":"tridimensionnel","word_nosc":"tridimensionnel","lemma":"tridimensionnel","pos":"ADJ"} ,
		{"word":"tridimensionnelle","word_nosc":"tridimensionnelle","lemma":"tridimensionnel","pos":"ADJ"} ,
		{"word":"triennale","word_nosc":"triennale","lemma":"triennal","pos":"ADJ"} ,
		{"word":"triennales","word_nosc":"triennales","lemma":"triennal","pos":"ADJ"} ,
		{"word":"trilatérale","word_nosc":"trilaterale","lemma":"trilatéral","pos":"ADJ"} ,
		{"word":"trilingue","word_nosc":"trilingue","lemma":"trilingue","pos":"ADJ"} ,
		{"word":"trilingues","word_nosc":"trilingues","lemma":"trilingue","pos":"ADJ"} ,
		{"word":"trilobée","word_nosc":"trilobee","lemma":"trilobé","pos":"ADJ"} ,
		{"word":"trilobés","word_nosc":"trilobes","lemma":"trilobé","pos":"ADJ"} ,
		{"word":"trimestriel","word_nosc":"trimestriel","lemma":"trimestriel","pos":"ADJ"} ,
		{"word":"trimestrielle","word_nosc":"trimestrielle","lemma":"trimestriel","pos":"ADJ"} ,
		{"word":"trimestrielles","word_nosc":"trimestrielles","lemma":"trimestriel","pos":"ADJ"} ,
		{"word":"trimestriels","word_nosc":"trimestriels","lemma":"trimestriel","pos":"ADJ"} ,
		{"word":"trine","word_nosc":"trine","lemma":"trin","pos":"ADJ"} ,
		{"word":"trinidadienne","word_nosc":"trinidadienne","lemma":"trinidadienne","pos":"ADJ"} ,
		{"word":"trinitaire","word_nosc":"trinitaire","lemma":"trinitaire","pos":"ADJ"} ,
		{"word":"triomphal","word_nosc":"triomphal","lemma":"triomphal","pos":"ADJ"} ,
		{"word":"triomphale","word_nosc":"triomphale","lemma":"triomphal","pos":"ADJ"} ,
		{"word":"triomphales","word_nosc":"triomphales","lemma":"triomphal","pos":"ADJ"} ,
		{"word":"triomphaliste","word_nosc":"triomphaliste","lemma":"triomphaliste","pos":"ADJ"} ,
		{"word":"triomphalistes","word_nosc":"triomphalistes","lemma":"triomphaliste","pos":"ADJ"} ,
		{"word":"triomphant","word_nosc":"triomphant","lemma":"triomphant","pos":"ADJ"} ,
		{"word":"triomphante","word_nosc":"triomphante","lemma":"triomphant","pos":"ADJ"} ,
		{"word":"triomphantes","word_nosc":"triomphantes","lemma":"triomphant","pos":"ADJ"} ,
		{"word":"triomphants","word_nosc":"triomphants","lemma":"triomphant","pos":"ADJ"} ,
		{"word":"triomphateur","word_nosc":"triomphateur","lemma":"triomphateur","pos":"ADJ"} ,
		{"word":"triomphatrice","word_nosc":"triomphatrice","lemma":"triomphateur","pos":"ADJ"} ,
		{"word":"triomphaux","word_nosc":"triomphaux","lemma":"triomphal","pos":"ADJ"} ,
		{"word":"tripant","word_nosc":"tripant","lemma":"tripant","pos":"ADJ"} ,
		{"word":"tripartite","word_nosc":"tripartite","lemma":"tripartite","pos":"ADJ"} ,
		{"word":"tripartites","word_nosc":"tripartites","lemma":"tripartite","pos":"ADJ"} ,
		{"word":"triphasé","word_nosc":"triphase","lemma":"triphasé","pos":"ADJ"} ,
		{"word":"triplace","word_nosc":"triplace","lemma":"triplace","pos":"ADJ"} ,
		{"word":"triple","word_nosc":"triple","lemma":"triple","pos":"ADJ"} ,
		{"word":"triples","word_nosc":"triples","lemma":"triple","pos":"ADJ"} ,
		{"word":"triploïde","word_nosc":"triploide","lemma":"triploïde","pos":"ADJ"} ,
		{"word":"triplé","word_nosc":"triple","lemma":"triplé","pos":"ADJ"} ,
		{"word":"triplée","word_nosc":"triplee","lemma":"triplé","pos":"ADJ"} ,
		{"word":"triplées","word_nosc":"triplees","lemma":"triplé","pos":"ADJ"} ,
		{"word":"tripode","word_nosc":"tripode","lemma":"tripode","pos":"ADJ"} ,
		{"word":"trismique","word_nosc":"trismique","lemma":"trismique","pos":"ADJ"} ,
		{"word":"trismégiste","word_nosc":"trismegiste","lemma":"trismégiste","pos":"ADJ"} ,
		{"word":"trisomique","word_nosc":"trisomique","lemma":"trisomique","pos":"ADJ"} ,
		{"word":"triste","word_nosc":"triste","lemma":"triste","pos":"ADJ"} ,
		{"word":"tristes","word_nosc":"tristes","lemma":"triste","pos":"ADJ"} ,
		{"word":"tristouillard","word_nosc":"tristouillard","lemma":"tristouillard","pos":"ADJ"} ,
		{"word":"tristouillarde","word_nosc":"tristouillarde","lemma":"tristouillard","pos":"ADJ"} ,
		{"word":"tristouille","word_nosc":"tristouille","lemma":"tristouille","pos":"ADJ"} ,
		{"word":"tristouilles","word_nosc":"tristouilles","lemma":"tristouille","pos":"ADJ"} ,
		{"word":"tristounet","word_nosc":"tristounet","lemma":"tristounet","pos":"ADJ"} ,
		{"word":"tristounette","word_nosc":"tristounette","lemma":"tristounet","pos":"ADJ"} ,
		{"word":"tristounettes","word_nosc":"tristounettes","lemma":"tristounet","pos":"ADJ"} ,
		{"word":"triturant","word_nosc":"triturant","lemma":"triturant","pos":"ADJ"} ,
		{"word":"trivial","word_nosc":"trivial","lemma":"trivial","pos":"ADJ"} ,
		{"word":"triviale","word_nosc":"triviale","lemma":"trivial","pos":"ADJ"} ,
		{"word":"triviales","word_nosc":"triviales","lemma":"trivial","pos":"ADJ"} ,
		{"word":"triviaux","word_nosc":"triviaux","lemma":"trivial","pos":"ADJ"} ,
		{"word":"trochaïque","word_nosc":"trochaique","lemma":"trochaïque","pos":"ADJ"} ,
		{"word":"troglodytique","word_nosc":"troglodytique","lemma":"troglodytique","pos":"ADJ"} ,
		{"word":"troglodytiques","word_nosc":"troglodytiques","lemma":"troglodytique","pos":"ADJ"} ,
		{"word":"trois","word_nosc":"trois","lemma":"trois","pos":"ADJ:num"} ,
		{"word":"troisième","word_nosc":"troisieme","lemma":"troisième","pos":"ADJ"} ,
		{"word":"troisièmes","word_nosc":"troisiemes","lemma":"troisième","pos":"ADJ"} ,
		{"word":"trompeur","word_nosc":"trompeur","lemma":"trompeur","pos":"ADJ"} ,
		{"word":"trompeurs","word_nosc":"trompeurs","lemma":"trompeur","pos":"ADJ"} ,
		{"word":"trompeuse","word_nosc":"trompeuse","lemma":"trompeur","pos":"ADJ"} ,
		{"word":"trompeuses","word_nosc":"trompeuses","lemma":"trompeur","pos":"ADJ"} ,
		{"word":"trompé","word_nosc":"trompe","lemma":"trompé","pos":"ADJ"} ,
		{"word":"trompée","word_nosc":"trompee","lemma":"trompé","pos":"ADJ"} ,
		{"word":"trompées","word_nosc":"trompees","lemma":"trompé","pos":"ADJ"} ,
		{"word":"trompés","word_nosc":"trompes","lemma":"trompé","pos":"ADJ"} ,
		{"word":"tronconique","word_nosc":"tronconique","lemma":"tronconique","pos":"ADJ"} ,
		{"word":"tronqué","word_nosc":"tronque","lemma":"tronqué","pos":"ADJ"} ,
		{"word":"tronquée","word_nosc":"tronquee","lemma":"tronqué","pos":"ADJ"} ,
		{"word":"tronquées","word_nosc":"tronquees","lemma":"tronqué","pos":"ADJ"} ,
		{"word":"tronqués","word_nosc":"tronques","lemma":"tronqué","pos":"ADJ"} ,
		{"word":"tropical","word_nosc":"tropical","lemma":"tropical","pos":"ADJ"} ,
		{"word":"tropicale","word_nosc":"tropicale","lemma":"tropical","pos":"ADJ"} ,
		{"word":"tropicales","word_nosc":"tropicales","lemma":"tropical","pos":"ADJ"} ,
		{"word":"tropicaux","word_nosc":"tropicaux","lemma":"tropical","pos":"ADJ"} ,
		{"word":"tropique","word_nosc":"tropique","lemma":"tropique","pos":"ADJ"} ,
		{"word":"tropiques","word_nosc":"tropiques","lemma":"tropique","pos":"ADJ"} ,
		{"word":"troposphérique","word_nosc":"tropospherique","lemma":"troposphérique","pos":"ADJ"} ,
		{"word":"troposphériques","word_nosc":"tropospheriques","lemma":"troposphérique","pos":"ADJ"} ,
		{"word":"tropéziens","word_nosc":"tropeziens","lemma":"tropézien","pos":"ADJ"} ,
		{"word":"trotskiste","word_nosc":"trotskiste","lemma":"trotskiste","pos":"ADJ"} ,
		{"word":"trotskistes","word_nosc":"trotskistes","lemma":"trotskiste","pos":"ADJ"} ,
		{"word":"trotskystes","word_nosc":"trotskystes","lemma":"trotskyste","pos":"ADJ"} ,
		{"word":"trotte-menu","word_nosc":"trotte-menu","lemma":"trotte-menu","pos":"ADJ"} ,
		{"word":"trotteur","word_nosc":"trotteur","lemma":"trotteur","pos":"ADJ"} ,
		{"word":"trotteurs","word_nosc":"trotteurs","lemma":"trotteur","pos":"ADJ"} ,
		{"word":"trotteuse","word_nosc":"trotteuse","lemma":"trotteur","pos":"ADJ"} ,
		{"word":"trottinant","word_nosc":"trottinant","lemma":"trottinant","pos":"ADJ"} ,
		{"word":"troublant","word_nosc":"troublant","lemma":"troublant","pos":"ADJ"} ,
		{"word":"troublante","word_nosc":"troublante","lemma":"troublant","pos":"ADJ"} ,
		{"word":"troublantes","word_nosc":"troublantes","lemma":"troublant","pos":"ADJ"} ,
		{"word":"troublants","word_nosc":"troublants","lemma":"troublant","pos":"ADJ"} ,
		{"word":"trouble","word_nosc":"trouble","lemma":"trouble","pos":"ADJ"} ,
		{"word":"troubles","word_nosc":"troubles","lemma":"trouble","pos":"ADJ"} ,
		{"word":"troublé","word_nosc":"trouble","lemma":"troublé","pos":"ADJ"} ,
		{"word":"troublée","word_nosc":"troublee","lemma":"troublé","pos":"ADJ"} ,
		{"word":"troublées","word_nosc":"troublees","lemma":"troublé","pos":"ADJ"} ,
		{"word":"troublés","word_nosc":"troubles","lemma":"troublé","pos":"ADJ"} ,
		{"word":"trouillard","word_nosc":"trouillard","lemma":"trouillard","pos":"ADJ"} ,
		{"word":"trouillarde","word_nosc":"trouillarde","lemma":"trouillard","pos":"ADJ"} ,
		{"word":"trouillards","word_nosc":"trouillards","lemma":"trouillard","pos":"ADJ"} ,
		{"word":"troussée","word_nosc":"troussee","lemma":"troussé","pos":"ADJ"} ,
		{"word":"troussées","word_nosc":"troussees","lemma":"troussé","pos":"ADJ"} ,
		{"word":"troussés","word_nosc":"trousses","lemma":"troussé","pos":"ADJ"} ,
		{"word":"trouvable","word_nosc":"trouvable","lemma":"trouvable","pos":"ADJ"} ,
		{"word":"trouvé","word_nosc":"trouve","lemma":"trouvé","pos":"ADJ"} ,
		{"word":"trouvée","word_nosc":"trouvee","lemma":"trouvé","pos":"ADJ"} ,
		{"word":"trouvées","word_nosc":"trouvees","lemma":"trouvé","pos":"ADJ"} ,
		{"word":"trouvés","word_nosc":"trouves","lemma":"trouvé","pos":"ADJ"} ,
		{"word":"troué","word_nosc":"troue","lemma":"troué","pos":"ADJ"} ,
		{"word":"trouée","word_nosc":"trouee","lemma":"troué","pos":"ADJ"} ,
		{"word":"trouées","word_nosc":"trouees","lemma":"troué","pos":"ADJ"} ,
		{"word":"troués","word_nosc":"troues","lemma":"troué","pos":"ADJ"} ,
		{"word":"troyen","word_nosc":"troyen","lemma":"troyen","pos":"ADJ"} ,
		{"word":"troyenne","word_nosc":"troyenne","lemma":"troyen","pos":"ADJ"} ,
		{"word":"truculent","word_nosc":"truculent","lemma":"truculent","pos":"ADJ"} ,
		{"word":"truculente","word_nosc":"truculente","lemma":"truculent","pos":"ADJ"} ,
		{"word":"truqueur","word_nosc":"truqueur","lemma":"truqueur","pos":"ADJ"} ,
		{"word":"truqueuse","word_nosc":"truqueuse","lemma":"truqueur","pos":"ADJ"} ,
		{"word":"truqué","word_nosc":"truque","lemma":"truqué","pos":"ADJ"} ,
		{"word":"truquée","word_nosc":"truquee","lemma":"truqué","pos":"ADJ"} ,
		{"word":"truquées","word_nosc":"truquees","lemma":"truqué","pos":"ADJ"} ,
		{"word":"truqués","word_nosc":"truques","lemma":"truqué","pos":"ADJ"} ,
		{"word":"trébuchant","word_nosc":"trebuchant","lemma":"trébuchant","pos":"ADJ"} ,
		{"word":"trébuchante","word_nosc":"trebuchante","lemma":"trébuchant","pos":"ADJ"} ,
		{"word":"trébuchantes","word_nosc":"trebuchantes","lemma":"trébuchant","pos":"ADJ"} ,
		{"word":"trébuchants","word_nosc":"trebuchants","lemma":"trébuchant","pos":"ADJ"} ,
		{"word":"trémière","word_nosc":"tremiere","lemma":"trémière","pos":"ADJ"} ,
		{"word":"trémières","word_nosc":"tremieres","lemma":"trémière","pos":"ADJ"} ,
		{"word":"trémulant","word_nosc":"tremulant","lemma":"trémulant","pos":"ADJ"} ,
		{"word":"trémulante","word_nosc":"tremulante","lemma":"trémulant","pos":"ADJ"} ,
		{"word":"trépassé","word_nosc":"trepasse","lemma":"trépassé","pos":"ADJ"} ,
		{"word":"trépassée","word_nosc":"trepassee","lemma":"trépassé","pos":"ADJ"} ,
		{"word":"trépassées","word_nosc":"trepassees","lemma":"trépassé","pos":"ADJ"} ,
		{"word":"trépidant","word_nosc":"trepidant","lemma":"trépidant","pos":"ADJ"} ,
		{"word":"trépidante","word_nosc":"trepidante","lemma":"trépidant","pos":"ADJ"} ,
		{"word":"trépidantes","word_nosc":"trepidantes","lemma":"trépidant","pos":"ADJ"} ,
		{"word":"trépidants","word_nosc":"trepidants","lemma":"trépidant","pos":"ADJ"} ,
		{"word":"trépignant","word_nosc":"trepignant","lemma":"trépignant","pos":"ADJ"} ,
		{"word":"trépignante","word_nosc":"trepignante","lemma":"trépignant","pos":"ADJ"} ,
		{"word":"trépignants","word_nosc":"trepignants","lemma":"trépignant","pos":"ADJ"} ,
		{"word":"tsariste","word_nosc":"tsariste","lemma":"tsariste","pos":"ADJ"} ,
		{"word":"tsaristes","word_nosc":"tsaristes","lemma":"tsariste","pos":"ADJ"} ,
		{"word":"tsigane","word_nosc":"tsigane","lemma":"tsigane","pos":"ADJ"} ,
		{"word":"tsiganes","word_nosc":"tsiganes","lemma":"tsigane","pos":"ADJ"} ,
		{"word":"tu","word_nosc":"tu","lemma":"tu","pos":"ADJ"} ,
		{"word":"tuant","word_nosc":"tuant","lemma":"tuant","pos":"ADJ"} ,
		{"word":"tuante","word_nosc":"tuante","lemma":"tuant","pos":"ADJ"} ,
		{"word":"tuantes","word_nosc":"tuantes","lemma":"tuant","pos":"ADJ"} ,
		{"word":"tuants","word_nosc":"tuants","lemma":"tuant","pos":"ADJ"} ,
		{"word":"tubard","word_nosc":"tubard","lemma":"tubard","pos":"ADJ"} ,
		{"word":"tubarde","word_nosc":"tubarde","lemma":"tubard","pos":"ADJ"} ,
		{"word":"tubards","word_nosc":"tubards","lemma":"tubard","pos":"ADJ"} ,
		{"word":"tuberculeuse","word_nosc":"tuberculeuse","lemma":"tuberculeux","pos":"ADJ"} ,
		{"word":"tuberculeuses","word_nosc":"tuberculeuses","lemma":"tuberculeux","pos":"ADJ"} ,
		{"word":"tuberculeux","word_nosc":"tuberculeux","lemma":"tuberculeux","pos":"ADJ"} ,
		{"word":"tubulaire","word_nosc":"tubulaire","lemma":"tubulaire","pos":"ADJ"} ,
		{"word":"tubulaires","word_nosc":"tubulaires","lemma":"tubulaire","pos":"ADJ"} ,
		{"word":"tubuleuses","word_nosc":"tubuleuses","lemma":"tubuleux","pos":"ADJ"} ,
		{"word":"tubéreuse","word_nosc":"tubereuse","lemma":"tubéreux","pos":"ADJ"} ,
		{"word":"tubéreuses","word_nosc":"tubereuses","lemma":"tubéreux","pos":"ADJ"} ,
		{"word":"tubéreux","word_nosc":"tubereux","lemma":"tubéreux","pos":"ADJ"} ,
		{"word":"tudesque","word_nosc":"tudesque","lemma":"tudesque","pos":"ADJ"} ,
		{"word":"tudesques","word_nosc":"tudesques","lemma":"tudesque","pos":"ADJ"} ,
		{"word":"tue","word_nosc":"tue","lemma":"tu","pos":"ADJ"} ,
		{"word":"tue-mouche","word_nosc":"tue-mouche","lemma":"tue-mouche","pos":"ADJ"} ,
		{"word":"tue-mouches","word_nosc":"tue-mouches","lemma":"tue-mouche","pos":"ADJ"} ,
		{"word":"tues","word_nosc":"tues","lemma":"tu","pos":"ADJ"} ,
		{"word":"tumescent","word_nosc":"tumescent","lemma":"tumescent","pos":"ADJ"} ,
		{"word":"tumorale","word_nosc":"tumorale","lemma":"tumoral","pos":"ADJ"} ,
		{"word":"tumoraux","word_nosc":"tumoraux","lemma":"tumoral","pos":"ADJ"} ,
		{"word":"tumultuaire","word_nosc":"tumultuaire","lemma":"tumultuaire","pos":"ADJ"} ,
		{"word":"tumultueuse","word_nosc":"tumultueuse","lemma":"tumultueux","pos":"ADJ"} ,
		{"word":"tumultueuses","word_nosc":"tumultueuses","lemma":"tumultueux","pos":"ADJ"} ,
		{"word":"tumultueux","word_nosc":"tumultueux","lemma":"tumultueux","pos":"ADJ"} ,
		{"word":"tuméfié","word_nosc":"tumefie","lemma":"tuméfié","pos":"ADJ"} ,
		{"word":"tuméfiée","word_nosc":"tumefiee","lemma":"tuméfié","pos":"ADJ"} ,
		{"word":"tuméfiées","word_nosc":"tumefiees","lemma":"tuméfié","pos":"ADJ"} ,
		{"word":"tuméfiés","word_nosc":"tumefies","lemma":"tuméfié","pos":"ADJ"} ,
		{"word":"tunisien","word_nosc":"tunisien","lemma":"tunisien","pos":"ADJ"} ,
		{"word":"tunisienne","word_nosc":"tunisienne","lemma":"tunisien","pos":"ADJ"} ,
		{"word":"tunisiennes","word_nosc":"tunisiennes","lemma":"tunisien","pos":"ADJ"} ,
		{"word":"tunisiens","word_nosc":"tunisiens","lemma":"tunisien","pos":"ADJ"} ,
		{"word":"turbulent","word_nosc":"turbulent","lemma":"turbulent","pos":"ADJ"} ,
		{"word":"turbulente","word_nosc":"turbulente","lemma":"turbulent","pos":"ADJ"} ,
		{"word":"turbulentes","word_nosc":"turbulentes","lemma":"turbulent","pos":"ADJ"} ,
		{"word":"turbulents","word_nosc":"turbulents","lemma":"turbulent","pos":"ADJ"} ,
		{"word":"turc","word_nosc":"turc","lemma":"turc","pos":"ADJ"} ,
		{"word":"turcoman","word_nosc":"turcoman","lemma":"turcoman","pos":"ADJ"} ,
		{"word":"turcs","word_nosc":"turcs","lemma":"turc","pos":"ADJ"} ,
		{"word":"turgescent","word_nosc":"turgescent","lemma":"turgescent","pos":"ADJ"} ,
		{"word":"turgescente","word_nosc":"turgescente","lemma":"turgescent","pos":"ADJ"} ,
		{"word":"turgescentes","word_nosc":"turgescentes","lemma":"turgescent","pos":"ADJ"} ,
		{"word":"turgescents","word_nosc":"turgescents","lemma":"turgescent","pos":"ADJ"} ,
		{"word":"turgide","word_nosc":"turgide","lemma":"turgide","pos":"ADJ"} ,
		{"word":"turinois","word_nosc":"turinois","lemma":"turinois","pos":"ADJ"} ,
		{"word":"turinoise","word_nosc":"turinoise","lemma":"turinois","pos":"ADJ"} ,
		{"word":"turkmènes","word_nosc":"turkmenes","lemma":"turkmène","pos":"ADJ"} ,
		{"word":"turpides","word_nosc":"turpides","lemma":"turpide","pos":"ADJ"} ,
		{"word":"turque","word_nosc":"turque","lemma":"turc","pos":"ADJ"} ,
		{"word":"turques","word_nosc":"turques","lemma":"turc","pos":"ADJ"} ,
		{"word":"turquin","word_nosc":"turquin","lemma":"turquin","pos":"ADJ"} ,
		{"word":"turquoise","word_nosc":"turquoise","lemma":"turquoise","pos":"ADJ"} ,
		{"word":"tus","word_nosc":"tus","lemma":"tu","pos":"ADJ"} ,
		{"word":"tutorial","word_nosc":"tutorial","lemma":"tutorial","pos":"ADJ"} ,
		{"word":"tutoriaux","word_nosc":"tutoriaux","lemma":"tutorial","pos":"ADJ"} ,
		{"word":"tutoyeuses","word_nosc":"tutoyeuses","lemma":"tutoyeur","pos":"ADJ"} ,
		{"word":"tutélaire","word_nosc":"tutelaire","lemma":"tutélaire","pos":"ADJ"} ,
		{"word":"tutélaires","word_nosc":"tutelaires","lemma":"tutélaire","pos":"ADJ"} ,
		{"word":"tué","word_nosc":"tue","lemma":"tué","pos":"ADJ"} ,
		{"word":"tuée","word_nosc":"tuee","lemma":"tué","pos":"ADJ"} ,
		{"word":"tuées","word_nosc":"tuees","lemma":"tué","pos":"ADJ"} ,
		{"word":"tués","word_nosc":"tues","lemma":"tué","pos":"ADJ"} ,
		{"word":"tympanique","word_nosc":"tympanique","lemma":"tympanique","pos":"ADJ"} ,
		{"word":"typhique","word_nosc":"typhique","lemma":"typhique","pos":"ADJ"} ,
		{"word":"typhiques","word_nosc":"typhiques","lemma":"typhique","pos":"ADJ"} ,
		{"word":"typique","word_nosc":"typique","lemma":"typique","pos":"ADJ"} ,
		{"word":"typiques","word_nosc":"typiques","lemma":"typique","pos":"ADJ"} ,
		{"word":"typographique","word_nosc":"typographique","lemma":"typographique","pos":"ADJ"} ,
		{"word":"typographiques","word_nosc":"typographiques","lemma":"typographique","pos":"ADJ"} ,
		{"word":"typé","word_nosc":"type","lemma":"typé","pos":"ADJ"} ,
		{"word":"typée","word_nosc":"typee","lemma":"typé","pos":"ADJ"} ,
		{"word":"typés","word_nosc":"types","lemma":"typé","pos":"ADJ"} ,
		{"word":"tyrannique","word_nosc":"tyrannique","lemma":"tyrannique","pos":"ADJ"} ,
		{"word":"tyranniques","word_nosc":"tyranniques","lemma":"tyrannique","pos":"ADJ"} ,
		{"word":"tyrienne","word_nosc":"tyrienne","lemma":"tyrien","pos":"ADJ"} ,
		{"word":"tyrolien","word_nosc":"tyrolien","lemma":"tyrolien","pos":"ADJ"} ,
		{"word":"tyrolienne","word_nosc":"tyrolienne","lemma":"tyrolien","pos":"ADJ"} ,
		{"word":"tyroliennes","word_nosc":"tyroliennes","lemma":"tyrolien","pos":"ADJ"} ,
		{"word":"tyroliens","word_nosc":"tyroliens","lemma":"tyrolien","pos":"ADJ"} ,
		{"word":"tyrrhénienne","word_nosc":"tyrrhenienne","lemma":"tyrrhénien","pos":"ADJ"} ,
		{"word":"tyrrhénienne","word_nosc":"tyrrhenienne","lemma":"tyrrhénienne","pos":"ADJ"} ,
		{"word":"tzigane","word_nosc":"tzigane","lemma":"tzigane","pos":"ADJ"} ,
		{"word":"tziganes","word_nosc":"tziganes","lemma":"tzigane","pos":"ADJ"} ,
		{"word":"tâtonnant","word_nosc":"tatonnant","lemma":"tâtonnant","pos":"ADJ"} ,
		{"word":"tâtonnante","word_nosc":"tatonnante","lemma":"tâtonnant","pos":"ADJ"} ,
		{"word":"tâtonnantes","word_nosc":"tatonnantes","lemma":"tâtonnant","pos":"ADJ"} ,
		{"word":"tâtonnants","word_nosc":"tatonnants","lemma":"tâtonnant","pos":"ADJ"} ,
		{"word":"tégumentaire","word_nosc":"tegumentaire","lemma":"tégumentaire","pos":"ADJ"} ,
		{"word":"télescopique","word_nosc":"telescopique","lemma":"télescopique","pos":"ADJ"} ,
		{"word":"télescopiques","word_nosc":"telescopiques","lemma":"télescopique","pos":"ADJ"} ,
		{"word":"téléchargeables","word_nosc":"telechargeables","lemma":"téléchargeable","pos":"ADJ"} ,
		{"word":"télégraphique","word_nosc":"telegraphique","lemma":"télégraphique","pos":"ADJ"} ,
		{"word":"télégraphiques","word_nosc":"telegraphiques","lemma":"télégraphique","pos":"ADJ"} ,
		{"word":"télégénique","word_nosc":"telegenique","lemma":"télégénique","pos":"ADJ"} ,
		{"word":"télématique","word_nosc":"telematique","lemma":"télématique","pos":"ADJ"} ,
		{"word":"télémétrique","word_nosc":"telemetrique","lemma":"télémétrique","pos":"ADJ"} ,
		{"word":"télémétriques","word_nosc":"telemetriques","lemma":"télémétrique","pos":"ADJ"} ,
		{"word":"téléologique","word_nosc":"teleologique","lemma":"téléologique","pos":"ADJ"} ,
		{"word":"télépathique","word_nosc":"telepathique","lemma":"télépathique","pos":"ADJ"} ,
		{"word":"télépathiques","word_nosc":"telepathiques","lemma":"télépathique","pos":"ADJ"} ,
		{"word":"téléphonique","word_nosc":"telephonique","lemma":"téléphonique","pos":"ADJ"} ,
		{"word":"téléphoniques","word_nosc":"telephoniques","lemma":"téléphonique","pos":"ADJ"} ,
		{"word":"téléphoné","word_nosc":"telephone","lemma":"téléphoné","pos":"ADJ"} ,
		{"word":"téléphonée","word_nosc":"telephonee","lemma":"téléphoné","pos":"ADJ"} ,
		{"word":"téléphonées","word_nosc":"telephonees","lemma":"téléphoné","pos":"ADJ"} ,
		{"word":"téléphonés","word_nosc":"telephones","lemma":"téléphoné","pos":"ADJ"} ,
		{"word":"télévisuel","word_nosc":"televisuel","lemma":"télévisuel","pos":"ADJ"} ,
		{"word":"télévisuelle","word_nosc":"televisuelle","lemma":"télévisuel","pos":"ADJ"} ,
		{"word":"télévisuels","word_nosc":"televisuels","lemma":"télévisuel","pos":"ADJ"} ,
		{"word":"télévisé","word_nosc":"televise","lemma":"télévisé","pos":"ADJ"} ,
		{"word":"télévisée","word_nosc":"televisee","lemma":"télévisé","pos":"ADJ"} ,
		{"word":"télévisées","word_nosc":"televisees","lemma":"télévisé","pos":"ADJ"} ,
		{"word":"télévisés","word_nosc":"televises","lemma":"télévisé","pos":"ADJ"} ,
		{"word":"téméraire","word_nosc":"temeraire","lemma":"téméraire","pos":"ADJ"} ,
		{"word":"téméraires","word_nosc":"temeraires","lemma":"téméraire","pos":"ADJ"} ,
		{"word":"ténu","word_nosc":"tenu","lemma":"ténu","pos":"ADJ"} ,
		{"word":"ténue","word_nosc":"tenue","lemma":"ténu","pos":"ADJ"} ,
		{"word":"ténues","word_nosc":"tenues","lemma":"ténu","pos":"ADJ"} ,
		{"word":"ténus","word_nosc":"tenus","lemma":"ténu","pos":"ADJ"} ,
		{"word":"ténébreuse","word_nosc":"tenebreuse","lemma":"ténébreux","pos":"ADJ"} ,
		{"word":"ténébreuses","word_nosc":"tenebreuses","lemma":"ténébreux","pos":"ADJ"} ,
		{"word":"ténébreux","word_nosc":"tenebreux","lemma":"ténébreux","pos":"ADJ"} ,
		{"word":"tératologique","word_nosc":"teratologique","lemma":"tératologique","pos":"ADJ"} ,
		{"word":"tératologiques","word_nosc":"teratologiques","lemma":"tératologique","pos":"ADJ"} ,
		{"word":"térébrant","word_nosc":"terebrant","lemma":"térébrant","pos":"ADJ"} ,
		{"word":"térébrants","word_nosc":"terebrants","lemma":"térébrant","pos":"ADJ"} ,
		{"word":"tétanique","word_nosc":"tetanique","lemma":"tétanique","pos":"ADJ"} ,
		{"word":"tétaniques","word_nosc":"tetaniques","lemma":"tétanique","pos":"ADJ"} ,
		{"word":"tétanisé","word_nosc":"tetanise","lemma":"tétanisé","pos":"ADJ"} ,
		{"word":"tétanisée","word_nosc":"tetanisee","lemma":"tétanisé","pos":"ADJ"} ,
		{"word":"tétanisées","word_nosc":"tetanisees","lemma":"tétanisé","pos":"ADJ"} ,
		{"word":"tétanisés","word_nosc":"tetanises","lemma":"tétanisé","pos":"ADJ"} ,
		{"word":"tétramère","word_nosc":"tetramere","lemma":"tétramère","pos":"ADJ"} ,
		{"word":"tétraplégique","word_nosc":"tetraplegique","lemma":"tétraplégique","pos":"ADJ"} ,
		{"word":"tête-de-nègre","word_nosc":"tete-de-negre","lemma":"tête-de-nègre","pos":"ADJ"} ,
		{"word":"têtu","word_nosc":"tetu","lemma":"têtu","pos":"ADJ"} ,
		{"word":"têtue","word_nosc":"tetue","lemma":"têtu","pos":"ADJ"} ,
		{"word":"têtues","word_nosc":"tetues","lemma":"têtu","pos":"ADJ"} ,
		{"word":"têtus","word_nosc":"tetus","lemma":"têtu","pos":"ADJ"} ,
		{"word":"ubique","word_nosc":"ubique","lemma":"ubique","pos":"ADJ"} ,
		{"word":"ubiquiste","word_nosc":"ubiquiste","lemma":"ubiquiste","pos":"ADJ"} ,
		{"word":"ubiquitaire","word_nosc":"ubiquitaire","lemma":"ubiquitaire","pos":"ADJ"} ,
		{"word":"ubuesque","word_nosc":"ubuesque","lemma":"ubuesque","pos":"ADJ"} ,
		{"word":"uht","word_nosc":"uht","lemma":"uht","pos":"ADJ"} ,
		{"word":"ukrainien","word_nosc":"ukrainien","lemma":"ukrainien","pos":"ADJ"} ,
		{"word":"ukrainienne","word_nosc":"ukrainienne","lemma":"ukrainien","pos":"ADJ"} ,
		{"word":"ukrainiennes","word_nosc":"ukrainiennes","lemma":"ukrainien","pos":"ADJ"} ,
		{"word":"ukrainiens","word_nosc":"ukrainiens","lemma":"ukrainien","pos":"ADJ"} ,
		{"word":"ulcérative","word_nosc":"ulcerative","lemma":"ulcératif","pos":"ADJ"} ,
		{"word":"ulcéreuse","word_nosc":"ulcereuse","lemma":"ulcéreux","pos":"ADJ"} ,
		{"word":"ulcéreuses","word_nosc":"ulcereuses","lemma":"ulcéreux","pos":"ADJ"} ,
		{"word":"ulcéreux","word_nosc":"ulcereux","lemma":"ulcéreux","pos":"ADJ"} ,
		{"word":"ulcéré","word_nosc":"ulcere","lemma":"ulcéré","pos":"ADJ"} ,
		{"word":"ulcérée","word_nosc":"ulceree","lemma":"ulcéré","pos":"ADJ"} ,
		{"word":"ulcérées","word_nosc":"ulcerees","lemma":"ulcéré","pos":"ADJ"} ,
		{"word":"ulcérés","word_nosc":"ulceres","lemma":"ulcéré","pos":"ADJ"} ,
		{"word":"ulnaire","word_nosc":"ulnaire","lemma":"ulnaire","pos":"ADJ"} ,
		{"word":"ultime","word_nosc":"ultime","lemma":"ultime","pos":"ADJ"} ,
		{"word":"ultimes","word_nosc":"ultimes","lemma":"ultime","pos":"ADJ"} ,
		{"word":"ultra","word_nosc":"ultra","lemma":"ultra","pos":"ADJ"} ,
		{"word":"ultra-catholique","word_nosc":"ultra-catholique","lemma":"ultra-catholique","pos":"ADJ"} ,
		{"word":"ultra-chic","word_nosc":"ultra-chic","lemma":"ultra-chic","pos":"ADJ"} ,
		{"word":"ultra-chics","word_nosc":"ultra-chics","lemma":"ultra-chic","pos":"ADJ"} ,
		{"word":"ultra-courtes","word_nosc":"ultra-courtes","lemma":"ultra-court","pos":"ADJ"} ,
		{"word":"ultra-courts","word_nosc":"ultra-courts","lemma":"ultra-court","pos":"ADJ"} ,
		{"word":"ultra-rapide","word_nosc":"ultra-rapide","lemma":"ultra-rapide","pos":"ADJ"} ,
		{"word":"ultra-secret","word_nosc":"ultra-secret","lemma":"ultra-secret","pos":"ADJ"} ,
		{"word":"ultra-sensible","word_nosc":"ultra-sensible","lemma":"ultra-sensible","pos":"ADJ"} ,
		{"word":"ultra-violet","word_nosc":"ultra-violet","lemma":"ultra-violet","pos":"ADJ"} ,
		{"word":"ultra-violets","word_nosc":"ultra-violets","lemma":"ultra-violet","pos":"ADJ"} ,
		{"word":"ultrafin","word_nosc":"ultrafin","lemma":"ultrafin","pos":"ADJ"} ,
		{"word":"ultraléger","word_nosc":"ultraleger","lemma":"ultraléger","pos":"ADJ"} ,
		{"word":"ultramarine","word_nosc":"ultramarine","lemma":"ultramarin","pos":"ADJ"} ,
		{"word":"ultramoderne","word_nosc":"ultramoderne","lemma":"ultramoderne","pos":"ADJ"} ,
		{"word":"ultramodernes","word_nosc":"ultramodernes","lemma":"ultramoderne","pos":"ADJ"} ,
		{"word":"ultramontain","word_nosc":"ultramontain","lemma":"ultramontain","pos":"ADJ"} ,
		{"word":"ultramontains","word_nosc":"ultramontains","lemma":"ultramontain","pos":"ADJ"} ,
		{"word":"ultraperformant","word_nosc":"ultraperformant","lemma":"ultraperformant","pos":"ADJ"} ,
		{"word":"ultrarapide","word_nosc":"ultrarapide","lemma":"ultrarapide","pos":"ADJ"} ,
		{"word":"ultrarapides","word_nosc":"ultrarapides","lemma":"ultrarapide","pos":"ADJ"} ,
		{"word":"ultrasecret","word_nosc":"ultrasecret","lemma":"ultrasecret","pos":"ADJ"} ,
		{"word":"ultrasecrète","word_nosc":"ultrasecrete","lemma":"ultrasecret","pos":"ADJ"} ,
		{"word":"ultrasensible","word_nosc":"ultrasensible","lemma":"ultrasensible","pos":"ADJ"} ,
		{"word":"ultrasensibles","word_nosc":"ultrasensibles","lemma":"ultrasensible","pos":"ADJ"} ,
		{"word":"ultrasonique","word_nosc":"ultrasonique","lemma":"ultrasonique","pos":"ADJ"} ,
		{"word":"ultraviolet","word_nosc":"ultraviolet","lemma":"ultraviolet","pos":"ADJ"} ,
		{"word":"ultraviolets","word_nosc":"ultraviolets","lemma":"ultraviolet","pos":"ADJ"} ,
		{"word":"ultraviolette","word_nosc":"ultraviolette","lemma":"ultraviolet","pos":"ADJ"} ,
		{"word":"ultraviolettes","word_nosc":"ultraviolettes","lemma":"ultraviolet","pos":"ADJ"} ,
		{"word":"ultérieur","word_nosc":"ulterieur","lemma":"ultérieur","pos":"ADJ"} ,
		{"word":"ultérieure","word_nosc":"ulterieure","lemma":"ultérieur","pos":"ADJ"} ,
		{"word":"ultérieures","word_nosc":"ulterieures","lemma":"ultérieur","pos":"ADJ"} ,
		{"word":"ultérieurs","word_nosc":"ulterieurs","lemma":"ultérieur","pos":"ADJ"} ,
		{"word":"un","word_nosc":"un","lemma":"un","pos":"ADJ:num"} ,
		{"word":"unanime","word_nosc":"unanime","lemma":"unanime","pos":"ADJ"} ,
		{"word":"unanimes","word_nosc":"unanimes","lemma":"unanime","pos":"ADJ"} ,
		{"word":"underground","word_nosc":"underground","lemma":"underground","pos":"ADJ"} ,
		{"word":"une","word_nosc":"une","lemma":"une","pos":"ADJ:num"} ,
		{"word":"unguéal","word_nosc":"ungueal","lemma":"unguéal","pos":"ADJ"} ,
		{"word":"uni","word_nosc":"uni","lemma":"uni","pos":"ADJ"} ,
		{"word":"uniate","word_nosc":"uniate","lemma":"uniate","pos":"ADJ"} ,
		{"word":"unicellulaire","word_nosc":"unicellulaire","lemma":"unicellulaire","pos":"ADJ"} ,
		{"word":"unicorne","word_nosc":"unicorne","lemma":"unicorne","pos":"ADJ"} ,
		{"word":"unidimensionnel","word_nosc":"unidimensionnel","lemma":"unidimensionnel","pos":"ADJ"} ,
		{"word":"unidimensionnelle","word_nosc":"unidimensionnelle","lemma":"unidimensionnel","pos":"ADJ"} ,
		{"word":"unidirectionnel","word_nosc":"unidirectionnel","lemma":"unidirectionnel","pos":"ADJ"} ,
		{"word":"unidirectionnelle","word_nosc":"unidirectionnelle","lemma":"unidirectionnel","pos":"ADJ"} ,
		{"word":"unidirectionnels","word_nosc":"unidirectionnels","lemma":"unidirectionnel","pos":"ADJ"} ,
		{"word":"unie","word_nosc":"unie","lemma":"uni","pos":"ADJ"} ,
		{"word":"unies","word_nosc":"unies","lemma":"uni","pos":"ADJ"} ,
		{"word":"unificatrice","word_nosc":"unificatrice","lemma":"unificateur","pos":"ADJ"} ,
		{"word":"uniforme","word_nosc":"uniforme","lemma":"uniforme","pos":"ADJ"} ,
		{"word":"uniformes","word_nosc":"uniformes","lemma":"uniforme","pos":"ADJ"} ,
		{"word":"unijambiste","word_nosc":"unijambiste","lemma":"unijambiste","pos":"ADJ"} ,
		{"word":"unijambistes","word_nosc":"unijambistes","lemma":"unijambiste","pos":"ADJ"} ,
		{"word":"unilatéral","word_nosc":"unilateral","lemma":"unilatéral","pos":"ADJ"} ,
		{"word":"unilatérale","word_nosc":"unilaterale","lemma":"unilatéral","pos":"ADJ"} ,
		{"word":"unilatérales","word_nosc":"unilaterales","lemma":"unilatéral","pos":"ADJ"} ,
		{"word":"unilatéraux","word_nosc":"unilateraux","lemma":"unilatéral","pos":"ADJ"} ,
		{"word":"uninominal","word_nosc":"uninominal","lemma":"uninominal","pos":"ADJ"} ,
		{"word":"uninominales","word_nosc":"uninominales","lemma":"uninominal","pos":"ADJ"} ,
		{"word":"unioniste","word_nosc":"unioniste","lemma":"unioniste","pos":"ADJ"} ,
		{"word":"unique","word_nosc":"unique","lemma":"unique","pos":"ADJ"} ,
		{"word":"uniques","word_nosc":"uniques","lemma":"unique","pos":"ADJ"} ,
		{"word":"unis","word_nosc":"unis","lemma":"uni","pos":"ADJ"} ,
		{"word":"unisexe","word_nosc":"unisexe","lemma":"unisexe","pos":"ADJ"} ,
		{"word":"unisexes","word_nosc":"unisexes","lemma":"unisexe","pos":"ADJ"} ,
		{"word":"unitaire","word_nosc":"unitaire","lemma":"unitaire","pos":"ADJ"} ,
		{"word":"unitarien","word_nosc":"unitarien","lemma":"unitarien","pos":"ADJ"} ,
		{"word":"unitarienne","word_nosc":"unitarienne","lemma":"unitarien","pos":"ADJ"} ,
		{"word":"univalves","word_nosc":"univalves","lemma":"univalve","pos":"ADJ"} ,
		{"word":"universel","word_nosc":"universel","lemma":"universel","pos":"ADJ"} ,
		{"word":"universelle","word_nosc":"universelle","lemma":"universel","pos":"ADJ"} ,
		{"word":"universelles","word_nosc":"universelles","lemma":"universel","pos":"ADJ"} ,
		{"word":"universels","word_nosc":"universels","lemma":"universel","pos":"ADJ"} ,
		{"word":"universitaire","word_nosc":"universitaire","lemma":"universitaire","pos":"ADJ"} ,
		{"word":"universitaires","word_nosc":"universitaires","lemma":"universitaire","pos":"ADJ"} ,
		{"word":"univoque","word_nosc":"univoque","lemma":"univoque","pos":"ADJ"} ,
		{"word":"unième","word_nosc":"unieme","lemma":"unième","pos":"ADJ"} ,
		{"word":"uraniens","word_nosc":"uraniens","lemma":"uranien","pos":"ADJ"} ,
		{"word":"urbain","word_nosc":"urbain","lemma":"urbain","pos":"ADJ"} ,
		{"word":"urbaine","word_nosc":"urbaine","lemma":"urbain","pos":"ADJ"} ,
		{"word":"urbaines","word_nosc":"urbaines","lemma":"urbain","pos":"ADJ"} ,
		{"word":"urbains","word_nosc":"urbains","lemma":"urbain","pos":"ADJ"} ,
		{"word":"urbaniste","word_nosc":"urbaniste","lemma":"urbaniste","pos":"ADJ"} ,
		{"word":"urbanistes","word_nosc":"urbanistes","lemma":"urbaniste","pos":"ADJ"} ,
		{"word":"urf","word_nosc":"urf","lemma":"urf","pos":"ADJ"} ,
		{"word":"urgent","word_nosc":"urgent","lemma":"urgent","pos":"ADJ"} ,
		{"word":"urgente","word_nosc":"urgente","lemma":"urgent","pos":"ADJ"} ,
		{"word":"urgentes","word_nosc":"urgentes","lemma":"urgent","pos":"ADJ"} ,
		{"word":"urgentissime","word_nosc":"urgentissime","lemma":"urgentissime","pos":"ADJ"} ,
		{"word":"urgents","word_nosc":"urgents","lemma":"urgent","pos":"ADJ"} ,
		{"word":"urinaire","word_nosc":"urinaire","lemma":"urinaire","pos":"ADJ"} ,
		{"word":"urinaires","word_nosc":"urinaires","lemma":"urinaire","pos":"ADJ"} ,
		{"word":"urineuse","word_nosc":"urineuse","lemma":"urineux","pos":"ADJ"} ,
		{"word":"urique","word_nosc":"urique","lemma":"urique","pos":"ADJ"} ,
		{"word":"urologique","word_nosc":"urologique","lemma":"urologique","pos":"ADJ"} ,
		{"word":"urticante","word_nosc":"urticante","lemma":"urticant","pos":"ADJ"} ,
		{"word":"urticantes","word_nosc":"urticantes","lemma":"urticant","pos":"ADJ"} ,
		{"word":"urticants","word_nosc":"urticants","lemma":"urticant","pos":"ADJ"} ,
		{"word":"uruguayen","word_nosc":"uruguayen","lemma":"uruguayen","pos":"ADJ"} ,
		{"word":"urémique","word_nosc":"uremique","lemma":"urémique","pos":"ADJ"} ,
		{"word":"usagé","word_nosc":"usage","lemma":"usagé","pos":"ADJ"} ,
		{"word":"usagée","word_nosc":"usagee","lemma":"usagé","pos":"ADJ"} ,
		{"word":"usagées","word_nosc":"usagees","lemma":"usagé","pos":"ADJ"} ,
		{"word":"usagés","word_nosc":"usages","lemma":"usagé","pos":"ADJ"} ,
		{"word":"usant","word_nosc":"usant","lemma":"usant","pos":"ADJ"} ,
		{"word":"usante","word_nosc":"usante","lemma":"usant","pos":"ADJ"} ,
		{"word":"usinier","word_nosc":"usinier","lemma":"usinier","pos":"ADJ"} ,
		{"word":"usinière","word_nosc":"usiniere","lemma":"usinier","pos":"ADJ"} ,
		{"word":"usité","word_nosc":"usite","lemma":"usité","pos":"ADJ"} ,
		{"word":"usités","word_nosc":"usites","lemma":"usité","pos":"ADJ"} ,
		{"word":"usuel","word_nosc":"usuel","lemma":"usuel","pos":"ADJ"} ,
		{"word":"usuelle","word_nosc":"usuelle","lemma":"usuel","pos":"ADJ"} ,
		{"word":"usuelles","word_nosc":"usuelles","lemma":"usuel","pos":"ADJ"} ,
		{"word":"usuels","word_nosc":"usuels","lemma":"usuel","pos":"ADJ"} ,
		{"word":"usuraire","word_nosc":"usuraire","lemma":"usuraire","pos":"ADJ"} ,
		{"word":"usuraires","word_nosc":"usuraires","lemma":"usuraire","pos":"ADJ"} ,
		{"word":"usé","word_nosc":"use","lemma":"usé","pos":"ADJ"} ,
		{"word":"usée","word_nosc":"usee","lemma":"usé","pos":"ADJ"} ,
		{"word":"usées","word_nosc":"usees","lemma":"usé","pos":"ADJ"} ,
		{"word":"usés","word_nosc":"uses","lemma":"usé","pos":"ADJ"} ,
		{"word":"utile","word_nosc":"utile","lemma":"utile","pos":"ADJ"} ,
		{"word":"utiles","word_nosc":"utiles","lemma":"utile","pos":"ADJ"} ,
		{"word":"utilisable","word_nosc":"utilisable","lemma":"utilisable","pos":"ADJ"} ,
		{"word":"utilisables","word_nosc":"utilisables","lemma":"utilisable","pos":"ADJ"} ,
		{"word":"utilisé","word_nosc":"utilise","lemma":"utilisé","pos":"ADJ"} ,
		{"word":"utilisée","word_nosc":"utilisee","lemma":"utilisé","pos":"ADJ"} ,
		{"word":"utilisées","word_nosc":"utilisees","lemma":"utilisé","pos":"ADJ"} ,
		{"word":"utilisés","word_nosc":"utilises","lemma":"utilisé","pos":"ADJ"} ,
		{"word":"utilitaire","word_nosc":"utilitaire","lemma":"utilitaire","pos":"ADJ"} ,
		{"word":"utilitaires","word_nosc":"utilitaires","lemma":"utilitaire","pos":"ADJ"} ,
		{"word":"utopique","word_nosc":"utopique","lemma":"utopique","pos":"ADJ"} ,
		{"word":"utopiques","word_nosc":"utopiques","lemma":"utopique","pos":"ADJ"} ,
		{"word":"utérin","word_nosc":"uterin","lemma":"utérin","pos":"ADJ"} ,
		{"word":"utérine","word_nosc":"uterine","lemma":"utérin","pos":"ADJ"} ,
		{"word":"utérines","word_nosc":"uterines","lemma":"utérin","pos":"ADJ"} ,
		{"word":"utérins","word_nosc":"uterins","lemma":"utérin","pos":"ADJ"} ,
		{"word":"uxorilocal","word_nosc":"uxorilocal","lemma":"uxorilocal","pos":"ADJ"} ,
		{"word":"v","word_nosc":"v","lemma":"v","pos":"ADJ:num"} ,
		{"word":"vacant","word_nosc":"vacant","lemma":"vacant","pos":"ADJ"} ,
		{"word":"vacante","word_nosc":"vacante","lemma":"vacant","pos":"ADJ"} ,
		{"word":"vacantes","word_nosc":"vacantes","lemma":"vacant","pos":"ADJ"} ,
		{"word":"vacants","word_nosc":"vacants","lemma":"vacant","pos":"ADJ"} ,
		{"word":"vaccinal","word_nosc":"vaccinal","lemma":"vaccinal","pos":"ADJ"} ,
		{"word":"vaccinant","word_nosc":"vaccinant","lemma":"vaccinant","pos":"ADJ"} ,
		{"word":"vacciné","word_nosc":"vaccine","lemma":"vacciné","pos":"ADJ"} ,
		{"word":"vaccinée","word_nosc":"vaccinee","lemma":"vacciné","pos":"ADJ"} ,
		{"word":"vaccinés","word_nosc":"vaccines","lemma":"vacciné","pos":"ADJ"} ,
		{"word":"vachard","word_nosc":"vachard","lemma":"vachard","pos":"ADJ"} ,
		{"word":"vacharde","word_nosc":"vacharde","lemma":"vachard","pos":"ADJ"} ,
		{"word":"vachardes","word_nosc":"vachardes","lemma":"vachard","pos":"ADJ"} ,
		{"word":"vachards","word_nosc":"vachards","lemma":"vachard","pos":"ADJ"} ,
		{"word":"vache","word_nosc":"vache","lemma":"vache","pos":"ADJ"} ,
		{"word":"vaches","word_nosc":"vaches","lemma":"vache","pos":"ADJ"} ,
		{"word":"vacillant","word_nosc":"vacillant","lemma":"vacillant","pos":"ADJ"} ,
		{"word":"vacillante","word_nosc":"vacillante","lemma":"vacillant","pos":"ADJ"} ,
		{"word":"vacillantes","word_nosc":"vacillantes","lemma":"vacillant","pos":"ADJ"} ,
		{"word":"vacillants","word_nosc":"vacillants","lemma":"vacillant","pos":"ADJ"} ,
		{"word":"vagabond","word_nosc":"vagabond","lemma":"vagabond","pos":"ADJ"} ,
		{"word":"vagabonde","word_nosc":"vagabonde","lemma":"vagabond","pos":"ADJ"} ,
		{"word":"vagabondes","word_nosc":"vagabondes","lemma":"vagabond","pos":"ADJ"} ,
		{"word":"vagabonds","word_nosc":"vagabonds","lemma":"vagabond","pos":"ADJ"} ,
		{"word":"vagal","word_nosc":"vagal","lemma":"vagal","pos":"ADJ"} ,
		{"word":"vaginal","word_nosc":"vaginal","lemma":"vaginal","pos":"ADJ"} ,
		{"word":"vaginale","word_nosc":"vaginale","lemma":"vaginal","pos":"ADJ"} ,
		{"word":"vaginales","word_nosc":"vaginales","lemma":"vaginal","pos":"ADJ"} ,
		{"word":"vaginaux","word_nosc":"vaginaux","lemma":"vaginal","pos":"ADJ"} ,
		{"word":"vagissant","word_nosc":"vagissant","lemma":"vagissant","pos":"ADJ"} ,
		{"word":"vague","word_nosc":"vague","lemma":"vague","pos":"ADJ"} ,
		{"word":"vagues","word_nosc":"vagues","lemma":"vague","pos":"ADJ"} ,
		{"word":"vaillant","word_nosc":"vaillant","lemma":"vaillant","pos":"ADJ"} ,
		{"word":"vaillante","word_nosc":"vaillante","lemma":"vaillant","pos":"ADJ"} ,
		{"word":"vaillantes","word_nosc":"vaillantes","lemma":"vaillant","pos":"ADJ"} ,
		{"word":"vaillants","word_nosc":"vaillants","lemma":"vaillant","pos":"ADJ"} ,
		{"word":"vain","word_nosc":"vain","lemma":"vain","pos":"ADJ"} ,
		{"word":"vaincu","word_nosc":"vaincu","lemma":"vaincu","pos":"ADJ"} ,
		{"word":"vaincue","word_nosc":"vaincue","lemma":"vaincu","pos":"ADJ"} ,
		{"word":"vaincues","word_nosc":"vaincues","lemma":"vaincu","pos":"ADJ"} ,
		{"word":"vaincus","word_nosc":"vaincus","lemma":"vaincu","pos":"ADJ"} ,
		{"word":"vaine","word_nosc":"vaine","lemma":"vain","pos":"ADJ"} ,
		{"word":"vaines","word_nosc":"vaines","lemma":"vain","pos":"ADJ"} ,
		{"word":"vainqueur","word_nosc":"vainqueur","lemma":"vainqueur","pos":"ADJ"} ,
		{"word":"vainqueurs","word_nosc":"vainqueurs","lemma":"vainqueur","pos":"ADJ"} ,
		{"word":"vains","word_nosc":"vains","lemma":"vain","pos":"ADJ"} ,
		{"word":"vairon","word_nosc":"vairon","lemma":"vairon","pos":"ADJ"} ,
		{"word":"vairons","word_nosc":"vairons","lemma":"vairon","pos":"ADJ"} ,
		{"word":"valable","word_nosc":"valable","lemma":"valable","pos":"ADJ"} ,
		{"word":"valables","word_nosc":"valables","lemma":"valable","pos":"ADJ"} ,
		{"word":"valeureuse","word_nosc":"valeureuse","lemma":"valeureux","pos":"ADJ"} ,
		{"word":"valeureuses","word_nosc":"valeureuses","lemma":"valeureux","pos":"ADJ"} ,
		{"word":"valeureux","word_nosc":"valeureux","lemma":"valeureux","pos":"ADJ"} ,
		{"word":"valgus","word_nosc":"valgus","lemma":"valgus","pos":"ADJ"} ,
		{"word":"valide","word_nosc":"valide","lemma":"valide","pos":"ADJ"} ,
		{"word":"valides","word_nosc":"valides","lemma":"valide","pos":"ADJ"} ,
		{"word":"validé","word_nosc":"valide","lemma":"validé","pos":"ADJ"} ,
		{"word":"validée","word_nosc":"validee","lemma":"validé","pos":"ADJ"} ,
		{"word":"vallonné","word_nosc":"vallonne","lemma":"vallonné","pos":"ADJ"} ,
		{"word":"vallonnée","word_nosc":"vallonnee","lemma":"vallonné","pos":"ADJ"} ,
		{"word":"vallonnées","word_nosc":"vallonnees","lemma":"vallonné","pos":"ADJ"} ,
		{"word":"vallonnés","word_nosc":"vallonnes","lemma":"vallonné","pos":"ADJ"} ,
		{"word":"valorisant","word_nosc":"valorisant","lemma":"valorisant","pos":"ADJ"} ,
		{"word":"valorisante","word_nosc":"valorisante","lemma":"valorisant","pos":"ADJ"} ,
		{"word":"valvulaires","word_nosc":"valvulaires","lemma":"valvulaire","pos":"ADJ"} ,
		{"word":"valétudinaire","word_nosc":"valetudinaire","lemma":"valétudinaire","pos":"ADJ"} ,
		{"word":"vampirique","word_nosc":"vampirique","lemma":"vampirique","pos":"ADJ"} ,
		{"word":"vandale","word_nosc":"vandale","lemma":"vandale","pos":"ADJ"} ,
		{"word":"vandales","word_nosc":"vandales","lemma":"vandale","pos":"ADJ"} ,
		{"word":"vanillé","word_nosc":"vanille","lemma":"vanillé","pos":"ADJ"} ,
		{"word":"vanillée","word_nosc":"vanillee","lemma":"vanillé","pos":"ADJ"} ,
		{"word":"vanillées","word_nosc":"vanillees","lemma":"vanillé","pos":"ADJ"} ,
		{"word":"vaniteuse","word_nosc":"vaniteuse","lemma":"vaniteux","pos":"ADJ"} ,
		{"word":"vaniteuses","word_nosc":"vaniteuses","lemma":"vaniteux","pos":"ADJ"} ,
		{"word":"vaniteux","word_nosc":"vaniteux","lemma":"vaniteux","pos":"ADJ"} ,
		{"word":"vanné","word_nosc":"vanne","lemma":"vanné","pos":"ADJ"} ,
		{"word":"vannée","word_nosc":"vannee","lemma":"vanné","pos":"ADJ"} ,
		{"word":"vantard","word_nosc":"vantard","lemma":"vantard","pos":"ADJ"} ,
		{"word":"vantarde","word_nosc":"vantarde","lemma":"vantard","pos":"ADJ"} ,
		{"word":"vantardes","word_nosc":"vantardes","lemma":"vantard","pos":"ADJ"} ,
		{"word":"vantards","word_nosc":"vantards","lemma":"vantard","pos":"ADJ"} ,
		{"word":"vaporeuse","word_nosc":"vaporeuse","lemma":"vaporeux","pos":"ADJ"} ,
		{"word":"vaporeuses","word_nosc":"vaporeuses","lemma":"vaporeux","pos":"ADJ"} ,
		{"word":"vaporeux","word_nosc":"vaporeux","lemma":"vaporeux","pos":"ADJ"} ,
		{"word":"vara","word_nosc":"vara","lemma":"vara","pos":"ADJ"} ,
		{"word":"variable","word_nosc":"variable","lemma":"variable","pos":"ADJ"} ,
		{"word":"variables","word_nosc":"variables","lemma":"variable","pos":"ADJ"} ,
		{"word":"variant","word_nosc":"variant","lemma":"variant","pos":"ADJ"} ,
		{"word":"variante","word_nosc":"variante","lemma":"variant","pos":"ADJ"} ,
		{"word":"variantes","word_nosc":"variantes","lemma":"variant","pos":"ADJ"} ,
		{"word":"variqueuses","word_nosc":"variqueuses","lemma":"variqueux","pos":"ADJ"} ,
		{"word":"variqueux","word_nosc":"variqueux","lemma":"variqueux","pos":"ADJ"} ,
		{"word":"varié","word_nosc":"varie","lemma":"varié","pos":"ADJ"} ,
		{"word":"variée","word_nosc":"variee","lemma":"varié","pos":"ADJ"} ,
		{"word":"variées","word_nosc":"variees","lemma":"varié","pos":"ADJ"} ,
		{"word":"variés","word_nosc":"varies","lemma":"varié","pos":"ADJ"} ,
		{"word":"variétal","word_nosc":"varietal","lemma":"variétal","pos":"ADJ"} ,
		{"word":"vasards","word_nosc":"vasards","lemma":"vasard","pos":"ADJ"} ,
		{"word":"vasculaire","word_nosc":"vasculaire","lemma":"vasculaire","pos":"ADJ"} ,
		{"word":"vasculaires","word_nosc":"vasculaires","lemma":"vasculaire","pos":"ADJ"} ,
		{"word":"vascularisé","word_nosc":"vascularise","lemma":"vascularisé","pos":"ADJ"} ,
		{"word":"vaseuse","word_nosc":"vaseuse","lemma":"vaseux","pos":"ADJ"} ,
		{"word":"vaseuses","word_nosc":"vaseuses","lemma":"vaseux","pos":"ADJ"} ,
		{"word":"vaseux","word_nosc":"vaseux","lemma":"vaseux","pos":"ADJ"} ,
		{"word":"vasoconstricteur","word_nosc":"vasoconstricteur","lemma":"vasoconstricteur","pos":"ADJ"} ,
		{"word":"vasodilatateur","word_nosc":"vasodilatateur","lemma":"vasodilatateur","pos":"ADJ"} ,
		{"word":"vasomoteurs","word_nosc":"vasomoteurs","lemma":"vasomoteur","pos":"ADJ"} ,
		{"word":"vasouillard","word_nosc":"vasouillard","lemma":"vasouillard","pos":"ADJ"} ,
		{"word":"vasouillarde","word_nosc":"vasouillarde","lemma":"vasouillard","pos":"ADJ"} ,
		{"word":"vaste","word_nosc":"vaste","lemma":"vaste","pos":"ADJ"} ,
		{"word":"vastes","word_nosc":"vastes","lemma":"vaste","pos":"ADJ"} ,
		{"word":"vaticane","word_nosc":"vaticane","lemma":"vaticane","pos":"ADJ"} ,
		{"word":"vaticanesque","word_nosc":"vaticanesque","lemma":"vaticanesque","pos":"ADJ"} ,
		{"word":"vauclusien","word_nosc":"vauclusien","lemma":"vauclusien","pos":"ADJ"} ,
		{"word":"vauclusiennes","word_nosc":"vauclusiennes","lemma":"vauclusien","pos":"ADJ"} ,
		{"word":"vaudevillesque","word_nosc":"vaudevillesque","lemma":"vaudevillesque","pos":"ADJ"} ,
		{"word":"vaudevillesques","word_nosc":"vaudevillesques","lemma":"vaudevillesque","pos":"ADJ"} ,
		{"word":"vaudois","word_nosc":"vaudois","lemma":"vaudois","pos":"ADJ"} ,
		{"word":"vaudoise","word_nosc":"vaudoise","lemma":"vaudois","pos":"ADJ"} ,
		{"word":"vaudou","word_nosc":"vaudou","lemma":"vaudou","pos":"ADJ"} ,
		{"word":"vaudoue","word_nosc":"vaudoue","lemma":"vaudou","pos":"ADJ"} ,
		{"word":"vaudoues","word_nosc":"vaudoues","lemma":"vaudou","pos":"ADJ"} ,
		{"word":"vaudous","word_nosc":"vaudous","lemma":"vaudou","pos":"ADJ"} ,
		{"word":"vautré","word_nosc":"vautre","lemma":"vautré","pos":"ADJ"} ,
		{"word":"vautrée","word_nosc":"vautree","lemma":"vautré","pos":"ADJ"} ,
		{"word":"vautrées","word_nosc":"vautrees","lemma":"vautré","pos":"ADJ"} ,
		{"word":"vautrés","word_nosc":"vautres","lemma":"vautré","pos":"ADJ"} ,
		{"word":"vecteur","word_nosc":"vecteur","lemma":"vecteur","pos":"ADJ"} ,
		{"word":"vecteurs","word_nosc":"vecteurs","lemma":"vecteur","pos":"ADJ"} ,
		{"word":"vectoriel","word_nosc":"vectoriel","lemma":"vectoriel","pos":"ADJ"} ,
		{"word":"vectorielle","word_nosc":"vectorielle","lemma":"vectoriel","pos":"ADJ"} ,
		{"word":"veillé","word_nosc":"veille","lemma":"veillé","pos":"ADJ"} ,
		{"word":"veillée","word_nosc":"veillee","lemma":"veillé","pos":"ADJ"} ,
		{"word":"veillées","word_nosc":"veillees","lemma":"veillé","pos":"ADJ"} ,
		{"word":"veinard","word_nosc":"veinard","lemma":"veinard","pos":"ADJ"} ,
		{"word":"veinarde","word_nosc":"veinarde","lemma":"veinard","pos":"ADJ"} ,
		{"word":"veinardes","word_nosc":"veinardes","lemma":"veinard","pos":"ADJ"} ,
		{"word":"veinards","word_nosc":"veinards","lemma":"veinard","pos":"ADJ"} ,
		{"word":"veineuse","word_nosc":"veineuse","lemma":"veineux","pos":"ADJ"} ,
		{"word":"veineux","word_nosc":"veineux","lemma":"veineux","pos":"ADJ"} ,
		{"word":"veinulé","word_nosc":"veinule","lemma":"veinulé","pos":"ADJ"} ,
		{"word":"veiné","word_nosc":"veine","lemma":"veiné","pos":"ADJ"} ,
		{"word":"veinée","word_nosc":"veinee","lemma":"veiné","pos":"ADJ"} ,
		{"word":"veinées","word_nosc":"veinees","lemma":"veiné","pos":"ADJ"} ,
		{"word":"veinés","word_nosc":"veines","lemma":"veiné","pos":"ADJ"} ,
		{"word":"vellave","word_nosc":"vellave","lemma":"vellave","pos":"ADJ"} ,
		{"word":"velléitaire","word_nosc":"velleitaire","lemma":"velléitaire","pos":"ADJ"} ,
		{"word":"velouteuse","word_nosc":"velouteuse","lemma":"velouteux","pos":"ADJ"} ,
		{"word":"velouteux","word_nosc":"velouteux","lemma":"velouteux","pos":"ADJ"} ,
		{"word":"velouté","word_nosc":"veloute","lemma":"velouté","pos":"ADJ"} ,
		{"word":"veloutée","word_nosc":"veloutee","lemma":"velouté","pos":"ADJ"} ,
		{"word":"veloutées","word_nosc":"veloutees","lemma":"velouté","pos":"ADJ"} ,
		{"word":"veloutés","word_nosc":"veloutes","lemma":"velouté","pos":"ADJ"} ,
		{"word":"velu","word_nosc":"velu","lemma":"velu","pos":"ADJ"} ,
		{"word":"velue","word_nosc":"velue","lemma":"velu","pos":"ADJ"} ,
		{"word":"velues","word_nosc":"velues","lemma":"velu","pos":"ADJ"} ,
		{"word":"velus","word_nosc":"velus","lemma":"velu","pos":"ADJ"} ,
		{"word":"vendable","word_nosc":"vendable","lemma":"vendable","pos":"ADJ"} ,
		{"word":"vendables","word_nosc":"vendables","lemma":"vendable","pos":"ADJ"} ,
		{"word":"vendu","word_nosc":"vendu","lemma":"vendu","pos":"ADJ"} ,
		{"word":"vendue","word_nosc":"vendue","lemma":"vendu","pos":"ADJ"} ,
		{"word":"vendues","word_nosc":"vendues","lemma":"vendu","pos":"ADJ"} ,
		{"word":"vendus","word_nosc":"vendus","lemma":"vendu","pos":"ADJ"} ,
		{"word":"vendéen","word_nosc":"vendeen","lemma":"vendéen","pos":"ADJ"} ,
		{"word":"vendéenne","word_nosc":"vendeenne","lemma":"vendéen","pos":"ADJ"} ,
		{"word":"vendéennes","word_nosc":"vendeennes","lemma":"vendéen","pos":"ADJ"} ,
		{"word":"vendéens","word_nosc":"vendeens","lemma":"vendéen","pos":"ADJ"} ,
		{"word":"vendômois","word_nosc":"vendomois","lemma":"vendômois","pos":"ADJ"} ,
		{"word":"vengeresse","word_nosc":"vengeresse","lemma":"vengeur","pos":"ADJ"} ,
		{"word":"vengeresses","word_nosc":"vengeresses","lemma":"vengeur","pos":"ADJ"} ,
		{"word":"vengeur","word_nosc":"vengeur","lemma":"vengeur","pos":"ADJ"} ,
		{"word":"vengeurs","word_nosc":"vengeurs","lemma":"vengeur","pos":"ADJ"} ,
		{"word":"venimeuse","word_nosc":"venimeuse","lemma":"venimeux","pos":"ADJ"} ,
		{"word":"venimeuses","word_nosc":"venimeuses","lemma":"venimeux","pos":"ADJ"} ,
		{"word":"venimeux","word_nosc":"venimeux","lemma":"venimeux","pos":"ADJ"} ,
		{"word":"venteuse","word_nosc":"venteuse","lemma":"venteux","pos":"ADJ"} ,
		{"word":"venteuses","word_nosc":"venteuses","lemma":"venteux","pos":"ADJ"} ,
		{"word":"venteux","word_nosc":"venteux","lemma":"venteux","pos":"ADJ"} ,
		{"word":"ventilé","word_nosc":"ventile","lemma":"ventilé","pos":"ADJ"} ,
		{"word":"ventilée","word_nosc":"ventilee","lemma":"ventilé","pos":"ADJ"} ,
		{"word":"ventilées","word_nosc":"ventilees","lemma":"ventilé","pos":"ADJ"} ,
		{"word":"ventilés","word_nosc":"ventiles","lemma":"ventilé","pos":"ADJ"} ,
		{"word":"ventral","word_nosc":"ventral","lemma":"ventral","pos":"ADJ"} ,
		{"word":"ventrale","word_nosc":"ventrale","lemma":"ventral","pos":"ADJ"} ,
		{"word":"ventriculaire","word_nosc":"ventriculaire","lemma":"ventriculaire","pos":"ADJ"} ,
		{"word":"ventripotent","word_nosc":"ventripotent","lemma":"ventripotent","pos":"ADJ"} ,
		{"word":"ventripotents","word_nosc":"ventripotents","lemma":"ventripotent","pos":"ADJ"} ,
		{"word":"ventru","word_nosc":"ventru","lemma":"ventru","pos":"ADJ"} ,
		{"word":"ventrue","word_nosc":"ventrue","lemma":"ventru","pos":"ADJ"} ,
		{"word":"ventrues","word_nosc":"ventrues","lemma":"ventru","pos":"ADJ"} ,
		{"word":"ventrus","word_nosc":"ventrus","lemma":"ventru","pos":"ADJ"} ,
		{"word":"venté","word_nosc":"vente","lemma":"venté","pos":"ADJ"} ,
		{"word":"ventée","word_nosc":"ventee","lemma":"venté","pos":"ADJ"} ,
		{"word":"ventées","word_nosc":"ventees","lemma":"venté","pos":"ADJ"} ,
		{"word":"venu","word_nosc":"venu","lemma":"venu","pos":"ADJ"} ,
		{"word":"venue","word_nosc":"venue","lemma":"venu","pos":"ADJ"} ,
		{"word":"venues","word_nosc":"venues","lemma":"venu","pos":"ADJ"} ,
		{"word":"venus","word_nosc":"venus","lemma":"venu","pos":"ADJ"} ,
		{"word":"ver","word_nosc":"ver","lemma":"ver","pos":"ADJ"} ,
		{"word":"verbal","word_nosc":"verbal","lemma":"verbal","pos":"ADJ"} ,
		{"word":"verbale","word_nosc":"verbale","lemma":"verbal","pos":"ADJ"} ,
		{"word":"verbales","word_nosc":"verbales","lemma":"verbal","pos":"ADJ"} ,
		{"word":"verbaux","word_nosc":"verbaux","lemma":"verbal","pos":"ADJ"} ,
		{"word":"verbeuse","word_nosc":"verbeuse","lemma":"verbeux","pos":"ADJ"} ,
		{"word":"verbeuses","word_nosc":"verbeuses","lemma":"verbeux","pos":"ADJ"} ,
		{"word":"verbeux","word_nosc":"verbeux","lemma":"verbeux","pos":"ADJ"} ,
		{"word":"verdelet","word_nosc":"verdelet","lemma":"verdelet","pos":"ADJ"} ,
		{"word":"verdi","word_nosc":"verdi","lemma":"verdi","pos":"ADJ"} ,
		{"word":"verdie","word_nosc":"verdie","lemma":"verdi","pos":"ADJ"} ,
		{"word":"verdies","word_nosc":"verdies","lemma":"verdi","pos":"ADJ"} ,
		{"word":"verdis","word_nosc":"verdis","lemma":"verdi","pos":"ADJ"} ,
		{"word":"verdissant","word_nosc":"verdissant","lemma":"verdissant","pos":"ADJ"} ,
		{"word":"verdissante","word_nosc":"verdissante","lemma":"verdissant","pos":"ADJ"} ,
		{"word":"verdissants","word_nosc":"verdissants","lemma":"verdissant","pos":"ADJ"} ,
		{"word":"verdoyant","word_nosc":"verdoyant","lemma":"verdoyant","pos":"ADJ"} ,
		{"word":"verdoyante","word_nosc":"verdoyante","lemma":"verdoyant","pos":"ADJ"} ,
		{"word":"verdoyantes","word_nosc":"verdoyantes","lemma":"verdoyant","pos":"ADJ"} ,
		{"word":"verdoyants","word_nosc":"verdoyants","lemma":"verdoyant","pos":"ADJ"} ,
		{"word":"verdâtre","word_nosc":"verdatre","lemma":"verdâtre","pos":"ADJ"} ,
		{"word":"verdâtres","word_nosc":"verdatres","lemma":"verdâtre","pos":"ADJ"} ,
		{"word":"verglacé","word_nosc":"verglace","lemma":"verglacé","pos":"ADJ"} ,
		{"word":"verglacée","word_nosc":"verglacee","lemma":"verglacé","pos":"ADJ"} ,
		{"word":"verglacées","word_nosc":"verglacees","lemma":"verglacé","pos":"ADJ"} ,
		{"word":"vergogneux","word_nosc":"vergogneux","lemma":"vergogneux","pos":"ADJ"} ,
		{"word":"vergé","word_nosc":"verge","lemma":"vergé","pos":"ADJ"} ,
		{"word":"vergés","word_nosc":"verges","lemma":"vergé","pos":"ADJ"} ,
		{"word":"vermeil","word_nosc":"vermeil","lemma":"vermeil","pos":"ADJ"} ,
		{"word":"vermeille","word_nosc":"vermeille","lemma":"vermeil","pos":"ADJ"} ,
		{"word":"vermeilles","word_nosc":"vermeilles","lemma":"vermeil","pos":"ADJ"} ,
		{"word":"vermeils","word_nosc":"vermeils","lemma":"vermeil","pos":"ADJ"} ,
		{"word":"vermiculaire","word_nosc":"vermiculaire","lemma":"vermiculaire","pos":"ADJ"} ,
		{"word":"vermiculaires","word_nosc":"vermiculaires","lemma":"vermiculaire","pos":"ADJ"} ,
		{"word":"vermiculées","word_nosc":"vermiculees","lemma":"vermiculé","pos":"ADJ"} ,
		{"word":"vermifuge","word_nosc":"vermifuge","lemma":"vermifuge","pos":"ADJ"} ,
		{"word":"vermillon","word_nosc":"vermillon","lemma":"vermillon","pos":"ADJ"} ,
		{"word":"vermineux","word_nosc":"vermineux","lemma":"vermineux","pos":"ADJ"} ,
		{"word":"vermoulu","word_nosc":"vermoulu","lemma":"vermoulu","pos":"ADJ"} ,
		{"word":"vermoulue","word_nosc":"vermoulue","lemma":"vermoulu","pos":"ADJ"} ,
		{"word":"vermoulues","word_nosc":"vermoulues","lemma":"vermoulu","pos":"ADJ"} ,
		{"word":"vermoulus","word_nosc":"vermoulus","lemma":"vermoulu","pos":"ADJ"} ,
		{"word":"vernaculaire","word_nosc":"vernaculaire","lemma":"vernaculaire","pos":"ADJ"} ,
		{"word":"vernal","word_nosc":"vernal","lemma":"vernal","pos":"ADJ"} ,
		{"word":"verni","word_nosc":"verni","lemma":"verni","pos":"ADJ"} ,
		{"word":"vernie","word_nosc":"vernie","lemma":"verni","pos":"ADJ"} ,
		{"word":"vernies","word_nosc":"vernies","lemma":"verni","pos":"ADJ"} ,
		{"word":"vernissé","word_nosc":"vernisse","lemma":"vernissé","pos":"ADJ"} ,
		{"word":"vernissée","word_nosc":"vernissee","lemma":"vernissé","pos":"ADJ"} ,
		{"word":"vernissées","word_nosc":"vernissees","lemma":"vernissé","pos":"ADJ"} ,
		{"word":"vernissés","word_nosc":"vernisses","lemma":"vernissé","pos":"ADJ"} ,
		{"word":"verrier","word_nosc":"verrier","lemma":"verrier","pos":"ADJ"} ,
		{"word":"verriers","word_nosc":"verriers","lemma":"verrier","pos":"ADJ"} ,
		{"word":"verrière","word_nosc":"verriere","lemma":"verrière","pos":"ADJ"} ,
		{"word":"verrières","word_nosc":"verrieres","lemma":"verrière","pos":"ADJ"} ,
		{"word":"verrouillé","word_nosc":"verrouille","lemma":"verrouillé","pos":"ADJ"} ,
		{"word":"verrouillée","word_nosc":"verrouillee","lemma":"verrouillé","pos":"ADJ"} ,
		{"word":"verrouillées","word_nosc":"verrouillees","lemma":"verrouillé","pos":"ADJ"} ,
		{"word":"verrouillés","word_nosc":"verrouilles","lemma":"verrouillé","pos":"ADJ"} ,
		{"word":"verruqueuse","word_nosc":"verruqueuse","lemma":"verruqueux","pos":"ADJ"} ,
		{"word":"versaillais","word_nosc":"versaillais","lemma":"versaillais","pos":"ADJ"} ,
		{"word":"versaillaise","word_nosc":"versaillaise","lemma":"versaillais","pos":"ADJ"} ,
		{"word":"versant","word_nosc":"versant","lemma":"versant","pos":"ADJ"} ,
		{"word":"versants","word_nosc":"versants","lemma":"versant","pos":"ADJ"} ,
		{"word":"versatile","word_nosc":"versatile","lemma":"versatile","pos":"ADJ"} ,
		{"word":"versatiles","word_nosc":"versatiles","lemma":"versatile","pos":"ADJ"} ,
		{"word":"verseur","word_nosc":"verseur","lemma":"verseur","pos":"ADJ"} ,
		{"word":"verseurs","word_nosc":"verseurs","lemma":"verseur","pos":"ADJ"} ,
		{"word":"versicolores","word_nosc":"versicolores","lemma":"versicolore","pos":"ADJ"} ,
		{"word":"versifiées","word_nosc":"versifiees","lemma":"versifié","pos":"ADJ"} ,
		{"word":"versé","word_nosc":"verse","lemma":"versé","pos":"ADJ"} ,
		{"word":"versée","word_nosc":"versee","lemma":"versé","pos":"ADJ"} ,
		{"word":"versées","word_nosc":"versees","lemma":"versé","pos":"ADJ"} ,
		{"word":"versés","word_nosc":"verses","lemma":"versé","pos":"ADJ"} ,
		{"word":"vert","word_nosc":"vert","lemma":"vert","pos":"ADJ"} ,
		{"word":"vert-de-gris","word_nosc":"vert-de-gris","lemma":"vert-de-gris","pos":"ADJ"} ,
		{"word":"vert-de-grisé","word_nosc":"vert-de-grise","lemma":"vert-de-grisé","pos":"ADJ"} ,
		{"word":"vert-de-grisée","word_nosc":"vert-de-grisee","lemma":"vert-de-grisé","pos":"ADJ"} ,
		{"word":"vert-de-grisées","word_nosc":"vert-de-grisees","lemma":"vert-de-grisé","pos":"ADJ"} ,
		{"word":"vert-de-grisés","word_nosc":"vert-de-grises","lemma":"vert-de-grisé","pos":"ADJ"} ,
		{"word":"vert-jaune","word_nosc":"vert-jaune","lemma":"vert-jaune","pos":"ADJ"} ,
		{"word":"verte","word_nosc":"verte","lemma":"vert","pos":"ADJ"} ,
		{"word":"vertes","word_nosc":"vertes","lemma":"vert","pos":"ADJ"} ,
		{"word":"vertical","word_nosc":"vertical","lemma":"vertical","pos":"ADJ"} ,
		{"word":"verticale","word_nosc":"verticale","lemma":"vertical","pos":"ADJ"} ,
		{"word":"verticales","word_nosc":"verticales","lemma":"vertical","pos":"ADJ"} ,
		{"word":"verticaux","word_nosc":"verticaux","lemma":"vertical","pos":"ADJ"} ,
		{"word":"vertigineuse","word_nosc":"vertigineuse","lemma":"vertigineux","pos":"ADJ"} ,
		{"word":"vertigineuses","word_nosc":"vertigineuses","lemma":"vertigineux","pos":"ADJ"} ,
		{"word":"vertigineux","word_nosc":"vertigineux","lemma":"vertigineux","pos":"ADJ"} ,
		{"word":"verts","word_nosc":"verts","lemma":"vert","pos":"ADJ"} ,
		{"word":"vertueuse","word_nosc":"vertueuse","lemma":"vertueux","pos":"ADJ"} ,
		{"word":"vertueuses","word_nosc":"vertueuses","lemma":"vertueux","pos":"ADJ"} ,
		{"word":"vertueux","word_nosc":"vertueux","lemma":"vertueux","pos":"ADJ"} ,
		{"word":"vertébral","word_nosc":"vertebral","lemma":"vertébral","pos":"ADJ"} ,
		{"word":"vertébrale","word_nosc":"vertebrale","lemma":"vertébral","pos":"ADJ"} ,
		{"word":"vertébrales","word_nosc":"vertebrales","lemma":"vertébral","pos":"ADJ"} ,
		{"word":"vertébraux","word_nosc":"vertebraux","lemma":"vertébral","pos":"ADJ"} ,
		{"word":"vertébré","word_nosc":"vertebre","lemma":"vertébré","pos":"ADJ"} ,
		{"word":"vertébrés","word_nosc":"vertebres","lemma":"vertébré","pos":"ADJ"} ,
		{"word":"verveux","word_nosc":"verveux","lemma":"verveux","pos":"ADJ"} ,
		{"word":"vespéral","word_nosc":"vesperal","lemma":"vespéral","pos":"ADJ"} ,
		{"word":"vespérale","word_nosc":"vesperale","lemma":"vespéral","pos":"ADJ"} ,
		{"word":"vespérales","word_nosc":"vesperales","lemma":"vespéral","pos":"ADJ"} ,
		{"word":"vespéraux","word_nosc":"vesperaux","lemma":"vespéral","pos":"ADJ"} ,
		{"word":"vestimentaire","word_nosc":"vestimentaire","lemma":"vestimentaire","pos":"ADJ"} ,
		{"word":"vestimentaires","word_nosc":"vestimentaires","lemma":"vestimentaire","pos":"ADJ"} ,
		{"word":"veuf","word_nosc":"veuf","lemma":"veuf","pos":"ADJ"} ,
		{"word":"veufs","word_nosc":"veufs","lemma":"veuf","pos":"ADJ"} ,
		{"word":"veule","word_nosc":"veule","lemma":"veule","pos":"ADJ"} ,
		{"word":"veules","word_nosc":"veules","lemma":"veule","pos":"ADJ"} ,
		{"word":"veuve","word_nosc":"veuve","lemma":"veuf","pos":"ADJ"} ,
		{"word":"veuves","word_nosc":"veuves","lemma":"veuf","pos":"ADJ"} ,
		{"word":"vexant","word_nosc":"vexant","lemma":"vexant","pos":"ADJ"} ,
		{"word":"vexante","word_nosc":"vexante","lemma":"vexant","pos":"ADJ"} ,
		{"word":"vexantes","word_nosc":"vexantes","lemma":"vexant","pos":"ADJ"} ,
		{"word":"vexants","word_nosc":"vexants","lemma":"vexant","pos":"ADJ"} ,
		{"word":"vexatoire","word_nosc":"vexatoire","lemma":"vexatoire","pos":"ADJ"} ,
		{"word":"vexatoires","word_nosc":"vexatoires","lemma":"vexatoire","pos":"ADJ"} ,
		{"word":"vexé","word_nosc":"vexe","lemma":"vexé","pos":"ADJ"} ,
		{"word":"vexée","word_nosc":"vexee","lemma":"vexé","pos":"ADJ"} ,
		{"word":"vexées","word_nosc":"vexees","lemma":"vexé","pos":"ADJ"} ,
		{"word":"vexés","word_nosc":"vexes","lemma":"vexé","pos":"ADJ"} ,
		{"word":"viable","word_nosc":"viable","lemma":"viable","pos":"ADJ"} ,
		{"word":"viables","word_nosc":"viables","lemma":"viable","pos":"ADJ"} ,
		{"word":"viagère","word_nosc":"viagere","lemma":"viager","pos":"ADJ"} ,
		{"word":"viandeuse","word_nosc":"viandeuse","lemma":"viandeux","pos":"ADJ"} ,
		{"word":"vibrant","word_nosc":"vibrant","lemma":"vibrant","pos":"ADJ"} ,
		{"word":"vibrante","word_nosc":"vibrante","lemma":"vibrant","pos":"ADJ"} ,
		{"word":"vibrantes","word_nosc":"vibrantes","lemma":"vibrant","pos":"ADJ"} ,
		{"word":"vibrants","word_nosc":"vibrants","lemma":"vibrant","pos":"ADJ"} ,
		{"word":"vibratile","word_nosc":"vibratile","lemma":"vibratile","pos":"ADJ"} ,
		{"word":"vibratiles","word_nosc":"vibratiles","lemma":"vibratile","pos":"ADJ"} ,
		{"word":"vibratoire","word_nosc":"vibratoire","lemma":"vibratoire","pos":"ADJ"} ,
		{"word":"vibratoires","word_nosc":"vibratoires","lemma":"vibratoire","pos":"ADJ"} ,
		{"word":"vicelard","word_nosc":"vicelard","lemma":"vicelard","pos":"ADJ"} ,
		{"word":"vicelarde","word_nosc":"vicelarde","lemma":"vicelard","pos":"ADJ"} ,
		{"word":"vicelardes","word_nosc":"vicelardes","lemma":"vicelard","pos":"ADJ"} ,
		{"word":"vicelards","word_nosc":"vicelards","lemma":"vicelard","pos":"ADJ"} ,
		{"word":"vichyssois","word_nosc":"vichyssois","lemma":"vichyssois","pos":"ADJ"} ,
		{"word":"vichyssoise","word_nosc":"vichyssoise","lemma":"vichyssois","pos":"ADJ"} ,
		{"word":"vichyste","word_nosc":"vichyste","lemma":"vichyste","pos":"ADJ"} ,
		{"word":"vichystes","word_nosc":"vichystes","lemma":"vichyste","pos":"ADJ"} ,
		{"word":"vicieuse","word_nosc":"vicieuse","lemma":"vicieux","pos":"ADJ"} ,
		{"word":"vicieuses","word_nosc":"vicieuses","lemma":"vicieux","pos":"ADJ"} ,
		{"word":"vicieux","word_nosc":"vicieux","lemma":"vicieux","pos":"ADJ"} ,
		{"word":"vicinal","word_nosc":"vicinal","lemma":"vicinal","pos":"ADJ"} ,
		{"word":"vicinales","word_nosc":"vicinales","lemma":"vicinal","pos":"ADJ"} ,
		{"word":"vicinaux","word_nosc":"vicinaux","lemma":"vicinal","pos":"ADJ"} ,
		{"word":"vicié","word_nosc":"vicie","lemma":"vicié","pos":"ADJ"} ,
		{"word":"viciés","word_nosc":"vicies","lemma":"vicié","pos":"ADJ"} ,
		{"word":"victorien","word_nosc":"victorien","lemma":"victorien","pos":"ADJ"} ,
		{"word":"victorienne","word_nosc":"victorienne","lemma":"victorien","pos":"ADJ"} ,
		{"word":"victoriennes","word_nosc":"victoriennes","lemma":"victorien","pos":"ADJ"} ,
		{"word":"victoriens","word_nosc":"victoriens","lemma":"victorien","pos":"ADJ"} ,
		{"word":"victorieuse","word_nosc":"victorieuse","lemma":"victorieux","pos":"ADJ"} ,
		{"word":"victorieuses","word_nosc":"victorieuses","lemma":"victorieux","pos":"ADJ"} ,
		{"word":"victorieux","word_nosc":"victorieux","lemma":"victorieux","pos":"ADJ"} ,
		{"word":"vide","word_nosc":"vide","lemma":"vide","pos":"ADJ"} ,
		{"word":"vides","word_nosc":"vides","lemma":"vide","pos":"ADJ"} ,
		{"word":"vidé","word_nosc":"vide","lemma":"vidé","pos":"ADJ"} ,
		{"word":"vidée","word_nosc":"videe","lemma":"vidé","pos":"ADJ"} ,
		{"word":"vidées","word_nosc":"videes","lemma":"vidé","pos":"ADJ"} ,
		{"word":"vidéo","word_nosc":"video","lemma":"vidéo","pos":"ADJ"} ,
		{"word":"vidéo-club","word_nosc":"video-club","lemma":"vidéo-club","pos":"ADJ"} ,
		{"word":"vidés","word_nosc":"vides","lemma":"vidé","pos":"ADJ"} ,
		{"word":"vieil","word_nosc":"vieil","lemma":"vieil","pos":"ADJ"} ,
		{"word":"vieille","word_nosc":"vieille","lemma":"vieux","pos":"ADJ"} ,
		{"word":"vieilles","word_nosc":"vieilles","lemma":"vieux","pos":"ADJ"} ,
		{"word":"vieilli","word_nosc":"vieilli","lemma":"vieilli","pos":"ADJ"} ,
		{"word":"vieillie","word_nosc":"vieillie","lemma":"vieilli","pos":"ADJ"} ,
		{"word":"vieillies","word_nosc":"vieillies","lemma":"vieilli","pos":"ADJ"} ,
		{"word":"vieillis","word_nosc":"vieillis","lemma":"vieilli","pos":"ADJ"} ,
		{"word":"vieillissant","word_nosc":"vieillissant","lemma":"vieillissant","pos":"ADJ"} ,
		{"word":"vieillissante","word_nosc":"vieillissante","lemma":"vieillissant","pos":"ADJ"} ,
		{"word":"vieillissantes","word_nosc":"vieillissantes","lemma":"vieillissant","pos":"ADJ"} ,
		{"word":"vieillissants","word_nosc":"vieillissants","lemma":"vieillissant","pos":"ADJ"} ,
		{"word":"vieillot","word_nosc":"vieillot","lemma":"vieillot","pos":"ADJ"} ,
		{"word":"vieillots","word_nosc":"vieillots","lemma":"vieillot","pos":"ADJ"} ,
		{"word":"vieillotte","word_nosc":"vieillotte","lemma":"vieillot","pos":"ADJ"} ,
		{"word":"vieillottes","word_nosc":"vieillottes","lemma":"vieillot","pos":"ADJ"} ,
		{"word":"viennois","word_nosc":"viennois","lemma":"viennois","pos":"ADJ"} ,
		{"word":"viennoise","word_nosc":"viennoise","lemma":"viennois","pos":"ADJ"} ,
		{"word":"viennoises","word_nosc":"viennoises","lemma":"viennois","pos":"ADJ"} ,
		{"word":"vierge","word_nosc":"vierge","lemma":"vierge","pos":"ADJ"} ,
		{"word":"vierges","word_nosc":"vierges","lemma":"vierge","pos":"ADJ"} ,
		{"word":"vietnamien","word_nosc":"vietnamien","lemma":"vietnamien","pos":"ADJ"} ,
		{"word":"vietnamienne","word_nosc":"vietnamienne","lemma":"vietnamien","pos":"ADJ"} ,
		{"word":"vietnamiennes","word_nosc":"vietnamiennes","lemma":"vietnamien","pos":"ADJ"} ,
		{"word":"vietnamiens","word_nosc":"vietnamiens","lemma":"vietnamien","pos":"ADJ"} ,
		{"word":"vieux","word_nosc":"vieux","lemma":"vieux","pos":"ADJ"} ,
		{"word":"vieux-rose","word_nosc":"vieux-rose","lemma":"vieux-rose","pos":"ADJ"} ,
		{"word":"vif","word_nosc":"vif","lemma":"vif","pos":"ADJ"} ,
		{"word":"vifs","word_nosc":"vifs","lemma":"vif","pos":"ADJ"} ,
		{"word":"vigil","word_nosc":"vigil","lemma":"vigil","pos":"ADJ"} ,
		{"word":"vigilant","word_nosc":"vigilant","lemma":"vigilant","pos":"ADJ"} ,
		{"word":"vigilante","word_nosc":"vigilante","lemma":"vigilant","pos":"ADJ"} ,
		{"word":"vigilantes","word_nosc":"vigilantes","lemma":"vigilant","pos":"ADJ"} ,
		{"word":"vigilants","word_nosc":"vigilants","lemma":"vigilant","pos":"ADJ"} ,
		{"word":"vigile","word_nosc":"vigile","lemma":"vigil","pos":"ADJ"} ,
		{"word":"vigiles","word_nosc":"vigiles","lemma":"vigil","pos":"ADJ"} ,
		{"word":"vigoureuse","word_nosc":"vigoureuse","lemma":"vigoureux","pos":"ADJ"} ,
		{"word":"vigoureuses","word_nosc":"vigoureuses","lemma":"vigoureux","pos":"ADJ"} ,
		{"word":"vigoureux","word_nosc":"vigoureux","lemma":"vigoureux","pos":"ADJ"} ,
		{"word":"vigousse","word_nosc":"vigousse","lemma":"vigousse","pos":"ADJ"} ,
		{"word":"viking","word_nosc":"viking","lemma":"viking","pos":"ADJ"} ,
		{"word":"vikings","word_nosc":"vikings","lemma":"viking","pos":"ADJ"} ,
		{"word":"vil","word_nosc":"vil","lemma":"vil","pos":"ADJ"} ,
		{"word":"vilain","word_nosc":"vilain","lemma":"vilain","pos":"ADJ"} ,
		{"word":"vilaine","word_nosc":"vilaine","lemma":"vilain","pos":"ADJ"} ,
		{"word":"vilaines","word_nosc":"vilaines","lemma":"vilain","pos":"ADJ"} ,
		{"word":"vilains","word_nosc":"vilains","lemma":"vilain","pos":"ADJ"} ,
		{"word":"vile","word_nosc":"vile","lemma":"vil","pos":"ADJ"} ,
		{"word":"viles","word_nosc":"viles","lemma":"vil","pos":"ADJ"} ,
		{"word":"vilipendé","word_nosc":"vilipende","lemma":"vilipendé","pos":"ADJ"} ,
		{"word":"vilipendées","word_nosc":"vilipendees","lemma":"vilipendé","pos":"ADJ"} ,
		{"word":"villageois","word_nosc":"villageois","lemma":"villageois","pos":"ADJ"} ,
		{"word":"villageoise","word_nosc":"villageoise","lemma":"villageois","pos":"ADJ"} ,
		{"word":"villageoises","word_nosc":"villageoises","lemma":"villageois","pos":"ADJ"} ,
		{"word":"vils","word_nosc":"vils","lemma":"vil","pos":"ADJ"} ,
		{"word":"vindicatif","word_nosc":"vindicatif","lemma":"vindicatif","pos":"ADJ"} ,
		{"word":"vindicatifs","word_nosc":"vindicatifs","lemma":"vindicatif","pos":"ADJ"} ,
		{"word":"vindicative","word_nosc":"vindicative","lemma":"vindicatif","pos":"ADJ"} ,
		{"word":"vindicatives","word_nosc":"vindicatives","lemma":"vindicatif","pos":"ADJ"} ,
		{"word":"vineuse","word_nosc":"vineuse","lemma":"vineux","pos":"ADJ"} ,
		{"word":"vineuses","word_nosc":"vineuses","lemma":"vineux","pos":"ADJ"} ,
		{"word":"vineux","word_nosc":"vineux","lemma":"vineux","pos":"ADJ"} ,
		{"word":"vingt","word_nosc":"vingt","lemma":"vingt","pos":"ADJ:num"} ,
		{"word":"vingt-cinq","word_nosc":"vingt-cinq","lemma":"vingt-cinq","pos":"ADJ:num"} ,
		{"word":"vingt-cinquième","word_nosc":"vingt-cinquieme","lemma":"vingt-cinquième","pos":"ADJ"} ,
		{"word":"vingt-deux","word_nosc":"vingt-deux","lemma":"vingt-deux","pos":"ADJ:num"} ,
		{"word":"vingt-deuxième","word_nosc":"vingt-deuxieme","lemma":"vingt-deuxième","pos":"ADJ"} ,
		{"word":"vingt-et-unième","word_nosc":"vingt-et-unieme","lemma":"vingt-et-unième","pos":"ADJ"} ,
		{"word":"vingt-huit","word_nosc":"vingt-huit","lemma":"vingt-huit","pos":"ADJ:num"} ,
		{"word":"vingt-huitième","word_nosc":"vingt-huitieme","lemma":"vingt-huitième","pos":"ADJ"} ,
		{"word":"vingt-neuf","word_nosc":"vingt-neuf","lemma":"vingt-neuf","pos":"ADJ:num"} ,
		{"word":"vingt-neuvième","word_nosc":"vingt-neuvieme","lemma":"vingt-neuvième","pos":"ADJ"} ,
		{"word":"vingt-quatre","word_nosc":"vingt-quatre","lemma":"vingt-quatre","pos":"ADJ:num"} ,
		{"word":"vingt-quatrième","word_nosc":"vingt-quatrieme","lemma":"vingt-quatrième","pos":"ADJ"} ,
		{"word":"vingt-sept","word_nosc":"vingt-sept","lemma":"vingt-sept","pos":"ADJ:num"} ,
		{"word":"vingt-septième","word_nosc":"vingt-septieme","lemma":"vingt-septième","pos":"ADJ"} ,
		{"word":"vingt-six","word_nosc":"vingt-six","lemma":"vingt-six","pos":"ADJ:num"} ,
		{"word":"vingt-sixième","word_nosc":"vingt-sixieme","lemma":"vingt-sixième","pos":"ADJ"} ,
		{"word":"vingt-trois","word_nosc":"vingt-trois","lemma":"vingt-trois","pos":"ADJ:num"} ,
		{"word":"vingt-troisième","word_nosc":"vingt-troisieme","lemma":"vingt-troisième","pos":"ADJ"} ,
		{"word":"vingtième","word_nosc":"vingtieme","lemma":"vingtième","pos":"ADJ"} ,
		{"word":"vingtièmes","word_nosc":"vingtiemes","lemma":"vingtième","pos":"ADJ"} ,
		{"word":"vinicole","word_nosc":"vinicole","lemma":"vinicole","pos":"ADJ"} ,
		{"word":"vins","word_nosc":"vins","lemma":"vins","pos":"ADJ"} ,
		{"word":"vinylique","word_nosc":"vinylique","lemma":"vinylique","pos":"ADJ"} ,
		{"word":"violacé","word_nosc":"violace","lemma":"violacé","pos":"ADJ"} ,
		{"word":"violacée","word_nosc":"violacee","lemma":"violacé","pos":"ADJ"} ,
		{"word":"violacées","word_nosc":"violacees","lemma":"violacé","pos":"ADJ"} ,
		{"word":"violacés","word_nosc":"violaces","lemma":"violacé","pos":"ADJ"} ,
		{"word":"violent","word_nosc":"violent","lemma":"violent","pos":"ADJ"} ,
		{"word":"violente","word_nosc":"violente","lemma":"violent","pos":"ADJ"} ,
		{"word":"violentes","word_nosc":"violentes","lemma":"violent","pos":"ADJ"} ,
		{"word":"violents","word_nosc":"violents","lemma":"violent","pos":"ADJ"} ,
		{"word":"violet","word_nosc":"violet","lemma":"violet","pos":"ADJ"} ,
		{"word":"violets","word_nosc":"violets","lemma":"violet","pos":"ADJ"} ,
		{"word":"violette","word_nosc":"violette","lemma":"violet","pos":"ADJ"} ,
		{"word":"violettes","word_nosc":"violettes","lemma":"violet","pos":"ADJ"} ,
		{"word":"violine","word_nosc":"violine","lemma":"violine","pos":"ADJ"} ,
		{"word":"violines","word_nosc":"violines","lemma":"violine","pos":"ADJ"} ,
		{"word":"violâtre","word_nosc":"violatre","lemma":"violâtre","pos":"ADJ"} ,
		{"word":"violâtres","word_nosc":"violatres","lemma":"violâtre","pos":"ADJ"} ,
		{"word":"vioque","word_nosc":"vioque","lemma":"vioque","pos":"ADJ"} ,
		{"word":"vioques","word_nosc":"vioques","lemma":"vioque","pos":"ADJ"} ,
		{"word":"vipérine","word_nosc":"viperine","lemma":"vipérin","pos":"ADJ"} ,
		{"word":"vipérines","word_nosc":"viperines","lemma":"vipérin","pos":"ADJ"} ,
		{"word":"viral","word_nosc":"viral","lemma":"viral","pos":"ADJ"} ,
		{"word":"virale","word_nosc":"virale","lemma":"viral","pos":"ADJ"} ,
		{"word":"virales","word_nosc":"virales","lemma":"viral","pos":"ADJ"} ,
		{"word":"viraux","word_nosc":"viraux","lemma":"viral","pos":"ADJ"} ,
		{"word":"vireuse","word_nosc":"vireuse","lemma":"vireux","pos":"ADJ"} ,
		{"word":"virevoltant","word_nosc":"virevoltant","lemma":"virevoltant","pos":"ADJ"} ,
		{"word":"virevoltante","word_nosc":"virevoltante","lemma":"virevoltant","pos":"ADJ"} ,
		{"word":"virevoltantes","word_nosc":"virevoltantes","lemma":"virevoltant","pos":"ADJ"} ,
		{"word":"virgilien","word_nosc":"virgilien","lemma":"virgilien","pos":"ADJ"} ,
		{"word":"virgilienne","word_nosc":"virgilienne","lemma":"virgilien","pos":"ADJ"} ,
		{"word":"virginal","word_nosc":"virginal","lemma":"virginal","pos":"ADJ"} ,
		{"word":"virginale","word_nosc":"virginale","lemma":"virginal","pos":"ADJ"} ,
		{"word":"virginales","word_nosc":"virginales","lemma":"virginal","pos":"ADJ"} ,
		{"word":"virginaux","word_nosc":"virginaux","lemma":"virginal","pos":"ADJ"} ,
		{"word":"virginiens","word_nosc":"virginiens","lemma":"virginien","pos":"ADJ"} ,
		{"word":"viril","word_nosc":"viril","lemma":"viril","pos":"ADJ"} ,
		{"word":"virile","word_nosc":"virile","lemma":"viril","pos":"ADJ"} ,
		{"word":"viriles","word_nosc":"viriles","lemma":"viril","pos":"ADJ"} ,
		{"word":"virils","word_nosc":"virils","lemma":"viril","pos":"ADJ"} ,
		{"word":"virtuel","word_nosc":"virtuel","lemma":"virtuel","pos":"ADJ"} ,
		{"word":"virtuelle","word_nosc":"virtuelle","lemma":"virtuel","pos":"ADJ"} ,
		{"word":"virtuelles","word_nosc":"virtuelles","lemma":"virtuel","pos":"ADJ"} ,
		{"word":"virtuels","word_nosc":"virtuels","lemma":"virtuel","pos":"ADJ"} ,
		{"word":"virtuose","word_nosc":"virtuose","lemma":"virtuose","pos":"ADJ"} ,
		{"word":"virtuoses","word_nosc":"virtuoses","lemma":"virtuose","pos":"ADJ"} ,
		{"word":"virulent","word_nosc":"virulent","lemma":"virulent","pos":"ADJ"} ,
		{"word":"virulente","word_nosc":"virulente","lemma":"virulent","pos":"ADJ"} ,
		{"word":"virulentes","word_nosc":"virulentes","lemma":"virulent","pos":"ADJ"} ,
		{"word":"virulents","word_nosc":"virulents","lemma":"virulent","pos":"ADJ"} ,
		{"word":"viscéral","word_nosc":"visceral","lemma":"viscéral","pos":"ADJ"} ,
		{"word":"viscérale","word_nosc":"viscerale","lemma":"viscéral","pos":"ADJ"} ,
		{"word":"viscérales","word_nosc":"viscerales","lemma":"viscéral","pos":"ADJ"} ,
		{"word":"viscéraux","word_nosc":"visceraux","lemma":"viscéral","pos":"ADJ"} ,
		{"word":"visible","word_nosc":"visible","lemma":"visible","pos":"ADJ"} ,
		{"word":"visibles","word_nosc":"visibles","lemma":"visible","pos":"ADJ"} ,
		{"word":"visionnaire","word_nosc":"visionnaire","lemma":"visionnaire","pos":"ADJ"} ,
		{"word":"visionnaires","word_nosc":"visionnaires","lemma":"visionnaire","pos":"ADJ"} ,
		{"word":"visqueuse","word_nosc":"visqueuse","lemma":"visqueux","pos":"ADJ"} ,
		{"word":"visqueuses","word_nosc":"visqueuses","lemma":"visqueux","pos":"ADJ"} ,
		{"word":"visqueux","word_nosc":"visqueux","lemma":"visqueux","pos":"ADJ"} ,
		{"word":"vissé","word_nosc":"visse","lemma":"vissé","pos":"ADJ"} ,
		{"word":"vissée","word_nosc":"vissee","lemma":"vissé","pos":"ADJ"} ,
		{"word":"vissées","word_nosc":"vissees","lemma":"vissé","pos":"ADJ"} ,
		{"word":"vissés","word_nosc":"visses","lemma":"vissé","pos":"ADJ"} ,
		{"word":"visuel","word_nosc":"visuel","lemma":"visuel","pos":"ADJ"} ,
		{"word":"visuelle","word_nosc":"visuelle","lemma":"visuel","pos":"ADJ"} ,
		{"word":"visuelles","word_nosc":"visuelles","lemma":"visuel","pos":"ADJ"} ,
		{"word":"visuels","word_nosc":"visuels","lemma":"visuel","pos":"ADJ"} ,
		{"word":"visé","word_nosc":"vise","lemma":"visé","pos":"ADJ"} ,
		{"word":"visée","word_nosc":"visee","lemma":"visé","pos":"ADJ"} ,
		{"word":"visées","word_nosc":"visees","lemma":"visé","pos":"ADJ"} ,
		{"word":"visés","word_nosc":"vises","lemma":"visé","pos":"ADJ"} ,
		{"word":"vital","word_nosc":"vital","lemma":"vital","pos":"ADJ"} ,
		{"word":"vitale","word_nosc":"vitale","lemma":"vital","pos":"ADJ"} ,
		{"word":"vitales","word_nosc":"vitales","lemma":"vital","pos":"ADJ"} ,
		{"word":"vitaminé","word_nosc":"vitamine","lemma":"vitaminé","pos":"ADJ"} ,
		{"word":"vitaminée","word_nosc":"vitaminee","lemma":"vitaminé","pos":"ADJ"} ,
		{"word":"vitaminées","word_nosc":"vitaminees","lemma":"vitaminé","pos":"ADJ"} ,
		{"word":"vitaminés","word_nosc":"vitamines","lemma":"vitaminé","pos":"ADJ"} ,
		{"word":"vitaux","word_nosc":"vitaux","lemma":"vital","pos":"ADJ"} ,
		{"word":"vite","word_nosc":"vite","lemma":"vite","pos":"ADJ"} ,
		{"word":"vitellin","word_nosc":"vitellin","lemma":"vitellin","pos":"ADJ"} ,
		{"word":"viticole","word_nosc":"viticole","lemma":"viticole","pos":"ADJ"} ,
		{"word":"vitreuse","word_nosc":"vitreuse","lemma":"vitreux","pos":"ADJ"} ,
		{"word":"vitreuses","word_nosc":"vitreuses","lemma":"vitreux","pos":"ADJ"} ,
		{"word":"vitreux","word_nosc":"vitreux","lemma":"vitreux","pos":"ADJ"} ,
		{"word":"vitriolique","word_nosc":"vitriolique","lemma":"vitriolique","pos":"ADJ"} ,
		{"word":"vitré","word_nosc":"vitre","lemma":"vitré","pos":"ADJ"} ,
		{"word":"vitrée","word_nosc":"vitree","lemma":"vitré","pos":"ADJ"} ,
		{"word":"vitrées","word_nosc":"vitrees","lemma":"vitré","pos":"ADJ"} ,
		{"word":"vitrés","word_nosc":"vitres","lemma":"vitré","pos":"ADJ"} ,
		{"word":"vivable","word_nosc":"vivable","lemma":"vivable","pos":"ADJ"} ,
		{"word":"vivables","word_nosc":"vivables","lemma":"vivable","pos":"ADJ"} ,
		{"word":"vivace","word_nosc":"vivace","lemma":"vivace","pos":"ADJ"} ,
		{"word":"vivaces","word_nosc":"vivaces","lemma":"vivace","pos":"ADJ"} ,
		{"word":"vivant","word_nosc":"vivant","lemma":"vivant","pos":"ADJ"} ,
		{"word":"vivante","word_nosc":"vivante","lemma":"vivant","pos":"ADJ"} ,
		{"word":"vivantes","word_nosc":"vivantes","lemma":"vivant","pos":"ADJ"} ,
		{"word":"vivants","word_nosc":"vivants","lemma":"vivant","pos":"ADJ"} ,
		{"word":"vive","word_nosc":"vive","lemma":"vif","pos":"ADJ"} ,
		{"word":"vives","word_nosc":"vives","lemma":"vif","pos":"ADJ"} ,
		{"word":"vivifiant","word_nosc":"vivifiant","lemma":"vivifiant","pos":"ADJ"} ,
		{"word":"vivifiante","word_nosc":"vivifiante","lemma":"vivifiant","pos":"ADJ"} ,
		{"word":"vivifiantes","word_nosc":"vivifiantes","lemma":"vivifiant","pos":"ADJ"} ,
		{"word":"vivifiants","word_nosc":"vivifiants","lemma":"vivifiant","pos":"ADJ"} ,
		{"word":"vivriers","word_nosc":"vivriers","lemma":"vivrier","pos":"ADJ"} ,
		{"word":"vocal","word_nosc":"vocal","lemma":"vocal","pos":"ADJ"} ,
		{"word":"vocale","word_nosc":"vocale","lemma":"vocal","pos":"ADJ"} ,
		{"word":"vocales","word_nosc":"vocales","lemma":"vocal","pos":"ADJ"} ,
		{"word":"vocalique","word_nosc":"vocalique","lemma":"vocalique","pos":"ADJ"} ,
		{"word":"vocaux","word_nosc":"vocaux","lemma":"vocal","pos":"ADJ"} ,
		{"word":"vociférant","word_nosc":"vociferant","lemma":"vociférant","pos":"ADJ"} ,
		{"word":"vociférante","word_nosc":"vociferante","lemma":"vociférant","pos":"ADJ"} ,
		{"word":"vociférantes","word_nosc":"vociferantes","lemma":"vociférant","pos":"ADJ"} ,
		{"word":"vociférants","word_nosc":"vociferants","lemma":"vociférant","pos":"ADJ"} ,
		{"word":"voilé","word_nosc":"voile","lemma":"voilé","pos":"ADJ"} ,
		{"word":"voilée","word_nosc":"voilee","lemma":"voilé","pos":"ADJ"} ,
		{"word":"voilées","word_nosc":"voilees","lemma":"voilé","pos":"ADJ"} ,
		{"word":"voilés","word_nosc":"voiles","lemma":"voilé","pos":"ADJ"} ,
		{"word":"voisin","word_nosc":"voisin","lemma":"voisin","pos":"ADJ"} ,
		{"word":"voisine","word_nosc":"voisine","lemma":"voisin","pos":"ADJ"} ,
		{"word":"voisines","word_nosc":"voisines","lemma":"voisin","pos":"ADJ"} ,
		{"word":"voisins","word_nosc":"voisins","lemma":"voisin","pos":"ADJ"} ,
		{"word":"volage","word_nosc":"volage","lemma":"volage","pos":"ADJ"} ,
		{"word":"volages","word_nosc":"volages","lemma":"volage","pos":"ADJ"} ,
		{"word":"volant","word_nosc":"volant","lemma":"volant","pos":"ADJ"} ,
		{"word":"volante","word_nosc":"volante","lemma":"volant","pos":"ADJ"} ,
		{"word":"volantes","word_nosc":"volantes","lemma":"volant","pos":"ADJ"} ,
		{"word":"volants","word_nosc":"volants","lemma":"volant","pos":"ADJ"} ,
		{"word":"volatil","word_nosc":"volatil","lemma":"volatil","pos":"ADJ"} ,
		{"word":"volatile","word_nosc":"volatile","lemma":"volatil","pos":"ADJ"} ,
		{"word":"volatiles","word_nosc":"volatiles","lemma":"volatil","pos":"ADJ"} ,
		{"word":"volatils","word_nosc":"volatils","lemma":"volatil","pos":"ADJ"} ,
		{"word":"volcanique","word_nosc":"volcanique","lemma":"volcanique","pos":"ADJ"} ,
		{"word":"volcaniques","word_nosc":"volcaniques","lemma":"volcanique","pos":"ADJ"} ,
		{"word":"volcanologique","word_nosc":"volcanologique","lemma":"volcanologique","pos":"ADJ"} ,
		{"word":"voletant","word_nosc":"voletant","lemma":"voletant","pos":"ADJ"} ,
		{"word":"voletante","word_nosc":"voletante","lemma":"voletant","pos":"ADJ"} ,
		{"word":"voletantes","word_nosc":"voletantes","lemma":"voletant","pos":"ADJ"} ,
		{"word":"voleur","word_nosc":"voleur","lemma":"voleur","pos":"ADJ"} ,
		{"word":"voleurs","word_nosc":"voleurs","lemma":"voleur","pos":"ADJ"} ,
		{"word":"voleuse","word_nosc":"voleuse","lemma":"voleur","pos":"ADJ"} ,
		{"word":"voleuses","word_nosc":"voleuses","lemma":"voleur","pos":"ADJ"} ,
		{"word":"volontaire","word_nosc":"volontaire","lemma":"volontaire","pos":"ADJ"} ,
		{"word":"volontaires","word_nosc":"volontaires","lemma":"volontaire","pos":"ADJ"} ,
		{"word":"volontariste","word_nosc":"volontariste","lemma":"volontariste","pos":"ADJ"} ,
		{"word":"volontaristes","word_nosc":"volontaristes","lemma":"volontariste","pos":"ADJ"} ,
		{"word":"voltairien","word_nosc":"voltairien","lemma":"voltairien","pos":"ADJ"} ,
		{"word":"voltaïque","word_nosc":"voltaique","lemma":"voltaïque","pos":"ADJ"} ,
		{"word":"volubile","word_nosc":"volubile","lemma":"volubile","pos":"ADJ"} ,
		{"word":"volubiles","word_nosc":"volubiles","lemma":"volubile","pos":"ADJ"} ,
		{"word":"volumineuse","word_nosc":"volumineuse","lemma":"volumineux","pos":"ADJ"} ,
		{"word":"volumineuses","word_nosc":"volumineuses","lemma":"volumineux","pos":"ADJ"} ,
		{"word":"volumineux","word_nosc":"volumineux","lemma":"volumineux","pos":"ADJ"} ,
		{"word":"volumique","word_nosc":"volumique","lemma":"volumique","pos":"ADJ"} ,
		{"word":"volumétrique","word_nosc":"volumetrique","lemma":"volumétrique","pos":"ADJ"} ,
		{"word":"voluptueuse","word_nosc":"voluptueuse","lemma":"voluptueux","pos":"ADJ"} ,
		{"word":"voluptueuses","word_nosc":"voluptueuses","lemma":"voluptueux","pos":"ADJ"} ,
		{"word":"voluptueux","word_nosc":"voluptueux","lemma":"voluptueux","pos":"ADJ"} ,
		{"word":"volé","word_nosc":"vole","lemma":"volé","pos":"ADJ"} ,
		{"word":"volée","word_nosc":"volee","lemma":"volé","pos":"ADJ"} ,
		{"word":"volées","word_nosc":"volees","lemma":"volé","pos":"ADJ"} ,
		{"word":"volés","word_nosc":"voles","lemma":"volé","pos":"ADJ"} ,
		{"word":"vomique","word_nosc":"vomique","lemma":"vomique","pos":"ADJ"} ,
		{"word":"vomissant","word_nosc":"vomissant","lemma":"vomissant","pos":"ADJ"} ,
		{"word":"vomisseur","word_nosc":"vomisseur","lemma":"vomisseur","pos":"ADJ"} ,
		{"word":"vomitif","word_nosc":"vomitif","lemma":"vomitif","pos":"ADJ"} ,
		{"word":"vomitifs","word_nosc":"vomitifs","lemma":"vomitif","pos":"ADJ"} ,
		{"word":"vomitives","word_nosc":"vomitives","lemma":"vomitif","pos":"ADJ"} ,
		{"word":"vorace","word_nosc":"vorace","lemma":"vorace","pos":"ADJ"} ,
		{"word":"voraces","word_nosc":"voraces","lemma":"vorace","pos":"ADJ"} ,
		{"word":"vos","word_nosc":"vos","lemma":"vos","pos":"ADJ:pos"} ,
		{"word":"vosgienne","word_nosc":"vosgienne","lemma":"vosgien","pos":"ADJ"} ,
		{"word":"votant","word_nosc":"votant","lemma":"votant","pos":"ADJ"} ,
		{"word":"votants","word_nosc":"votants","lemma":"votant","pos":"ADJ"} ,
		{"word":"votif","word_nosc":"votif","lemma":"votif","pos":"ADJ"} ,
		{"word":"votifs","word_nosc":"votifs","lemma":"votif","pos":"ADJ"} ,
		{"word":"votive","word_nosc":"votive","lemma":"votif","pos":"ADJ"} ,
		{"word":"votives","word_nosc":"votives","lemma":"votif","pos":"ADJ"} ,
		{"word":"votre","word_nosc":"votre","lemma":"votre","pos":"ADJ:pos"} ,
		{"word":"voulu","word_nosc":"voulu","lemma":"voulu","pos":"ADJ"} ,
		{"word":"voulue","word_nosc":"voulue","lemma":"voulu","pos":"ADJ"} ,
		{"word":"voulues","word_nosc":"voulues","lemma":"voulu","pos":"ADJ"} ,
		{"word":"voulus","word_nosc":"voulus","lemma":"voulu","pos":"ADJ"} ,
		{"word":"voué","word_nosc":"voue","lemma":"voué","pos":"ADJ"} ,
		{"word":"vouée","word_nosc":"vouee","lemma":"voué","pos":"ADJ"} ,
		{"word":"vouées","word_nosc":"vouees","lemma":"voué","pos":"ADJ"} ,
		{"word":"voués","word_nosc":"voues","lemma":"voué","pos":"ADJ"} ,
		{"word":"voyageur","word_nosc":"voyageur","lemma":"voyageur","pos":"ADJ"} ,
		{"word":"voyageurs","word_nosc":"voyageurs","lemma":"voyageur","pos":"ADJ"} ,
		{"word":"voyageuse","word_nosc":"voyageuse","lemma":"voyageur","pos":"ADJ"} ,
		{"word":"voyageuses","word_nosc":"voyageuses","lemma":"voyageur","pos":"ADJ"} ,
		{"word":"voyant","word_nosc":"voyant","lemma":"voyant","pos":"ADJ"} ,
		{"word":"voyante","word_nosc":"voyante","lemma":"voyant","pos":"ADJ"} ,
		{"word":"voyantes","word_nosc":"voyantes","lemma":"voyant","pos":"ADJ"} ,
		{"word":"voyants","word_nosc":"voyants","lemma":"voyant","pos":"ADJ"} ,
		{"word":"voyer","word_nosc":"voyer","lemma":"voyer","pos":"ADJ"} ,
		{"word":"voyoute","word_nosc":"voyoute","lemma":"voyou","pos":"ADJ"} ,
		{"word":"voyoutes","word_nosc":"voyoutes","lemma":"voyou","pos":"ADJ"} ,
		{"word":"voûté","word_nosc":"voute","lemma":"voûté","pos":"ADJ"} ,
		{"word":"voûtée","word_nosc":"voutee","lemma":"voûté","pos":"ADJ"} ,
		{"word":"voûtées","word_nosc":"voutees","lemma":"voûté","pos":"ADJ"} ,
		{"word":"voûtés","word_nosc":"voutes","lemma":"voûté","pos":"ADJ"} ,
		{"word":"vrai","word_nosc":"vrai","lemma":"vrai","pos":"ADJ"} ,
		{"word":"vrai-faux","word_nosc":"vrai-faux","lemma":"vrai-faux","pos":"ADJ"} ,
		{"word":"vraie","word_nosc":"vraie","lemma":"vrai","pos":"ADJ"} ,
		{"word":"vraies","word_nosc":"vraies","lemma":"vrai","pos":"ADJ"} ,
		{"word":"vrais","word_nosc":"vrais","lemma":"vrai","pos":"ADJ"} ,
		{"word":"vraisemblable","word_nosc":"vraisemblable","lemma":"vraisemblable","pos":"ADJ"} ,
		{"word":"vraisemblables","word_nosc":"vraisemblables","lemma":"vraisemblable","pos":"ADJ"} ,
		{"word":"vrillé","word_nosc":"vrille","lemma":"vrillé","pos":"ADJ"} ,
		{"word":"vrillés","word_nosc":"vrilles","lemma":"vrillé","pos":"ADJ"} ,
		{"word":"vrombissant","word_nosc":"vrombissant","lemma":"vrombissant","pos":"ADJ"} ,
		{"word":"vrombissante","word_nosc":"vrombissante","lemma":"vrombissant","pos":"ADJ"} ,
		{"word":"vrombissantes","word_nosc":"vrombissantes","lemma":"vrombissant","pos":"ADJ"} ,
		{"word":"vu","word_nosc":"vu","lemma":"vu","pos":"ADJ"} ,
		{"word":"vue","word_nosc":"vue","lemma":"vu","pos":"ADJ"} ,
		{"word":"vues","word_nosc":"vues","lemma":"vu","pos":"ADJ"} ,
		{"word":"vulcanienne","word_nosc":"vulcanienne","lemma":"vulcanienne","pos":"ADJ"} ,
		{"word":"vulcanisé","word_nosc":"vulcanise","lemma":"vulcanisé","pos":"ADJ"} ,
		{"word":"vulgaire","word_nosc":"vulgaire","lemma":"vulgaire","pos":"ADJ"} ,
		{"word":"vulgaires","word_nosc":"vulgaires","lemma":"vulgaire","pos":"ADJ"} ,
		{"word":"vulgarisée","word_nosc":"vulgarisee","lemma":"vulgarisé","pos":"ADJ"} ,
		{"word":"vulnérable","word_nosc":"vulnerable","lemma":"vulnérable","pos":"ADJ"} ,
		{"word":"vulnérables","word_nosc":"vulnerables","lemma":"vulnérable","pos":"ADJ"} ,
		{"word":"vulnéraire","word_nosc":"vulneraire","lemma":"vulnéraire","pos":"ADJ"} ,
		{"word":"vulnérant","word_nosc":"vulnerant","lemma":"vulnérant","pos":"ADJ"} ,
		{"word":"vus","word_nosc":"vus","lemma":"vu","pos":"ADJ"} ,
		{"word":"vécu","word_nosc":"vecu","lemma":"vécu","pos":"ADJ"} ,
		{"word":"vécue","word_nosc":"vecue","lemma":"vécu","pos":"ADJ"} ,
		{"word":"vécues","word_nosc":"vecues","lemma":"vécu","pos":"ADJ"} ,
		{"word":"vécus","word_nosc":"vecus","lemma":"vécu","pos":"ADJ"} ,
		{"word":"végétal","word_nosc":"vegetal","lemma":"végétal","pos":"ADJ"} ,
		{"word":"végétale","word_nosc":"vegetale","lemma":"végétal","pos":"ADJ"} ,
		{"word":"végétales","word_nosc":"vegetales","lemma":"végétal","pos":"ADJ"} ,
		{"word":"végétalien","word_nosc":"vegetalien","lemma":"végétalien","pos":"ADJ"} ,
		{"word":"végétalienne","word_nosc":"vegetalienne","lemma":"végétalien","pos":"ADJ"} ,
		{"word":"végétarien","word_nosc":"vegetarien","lemma":"végétarien","pos":"ADJ"} ,
		{"word":"végétarienne","word_nosc":"vegetarienne","lemma":"végétarien","pos":"ADJ"} ,
		{"word":"végétariennes","word_nosc":"vegetariennes","lemma":"végétarien","pos":"ADJ"} ,
		{"word":"végétariens","word_nosc":"vegetariens","lemma":"végétarien","pos":"ADJ"} ,
		{"word":"végétatif","word_nosc":"vegetatif","lemma":"végétatif","pos":"ADJ"} ,
		{"word":"végétatifs","word_nosc":"vegetatifs","lemma":"végétatif","pos":"ADJ"} ,
		{"word":"végétative","word_nosc":"vegetative","lemma":"végétatif","pos":"ADJ"} ,
		{"word":"végétatives","word_nosc":"vegetatives","lemma":"végétatif","pos":"ADJ"} ,
		{"word":"végétaux","word_nosc":"vegetaux","lemma":"végétal","pos":"ADJ"} ,
		{"word":"véhément","word_nosc":"vehement","lemma":"véhément","pos":"ADJ"} ,
		{"word":"véhémente","word_nosc":"vehemente","lemma":"véhément","pos":"ADJ"} ,
		{"word":"véhémentes","word_nosc":"vehementes","lemma":"véhément","pos":"ADJ"} ,
		{"word":"véhéments","word_nosc":"vehements","lemma":"véhément","pos":"ADJ"} ,
		{"word":"vélin","word_nosc":"velin","lemma":"vélin","pos":"ADJ"} ,
		{"word":"vélique","word_nosc":"velique","lemma":"vélique","pos":"ADJ"} ,
		{"word":"véloce","word_nosc":"veloce","lemma":"véloce","pos":"ADJ"} ,
		{"word":"véloces","word_nosc":"veloces","lemma":"véloce","pos":"ADJ"} ,
		{"word":"vélocipédique","word_nosc":"velocipedique","lemma":"vélocipédique","pos":"ADJ"} ,
		{"word":"vélocipédiques","word_nosc":"velocipediques","lemma":"vélocipédique","pos":"ADJ"} ,
		{"word":"vénal","word_nosc":"venal","lemma":"vénal","pos":"ADJ"} ,
		{"word":"vénale","word_nosc":"venale","lemma":"vénal","pos":"ADJ"} ,
		{"word":"vénales","word_nosc":"venales","lemma":"vénal","pos":"ADJ"} ,
		{"word":"vénaux","word_nosc":"venaux","lemma":"vénal","pos":"ADJ"} ,
		{"word":"véniel","word_nosc":"veniel","lemma":"véniel","pos":"ADJ"} ,
		{"word":"vénielle","word_nosc":"venielle","lemma":"véniel","pos":"ADJ"} ,
		{"word":"vénielles","word_nosc":"venielles","lemma":"véniel","pos":"ADJ"} ,
		{"word":"véniels","word_nosc":"veniels","lemma":"véniel","pos":"ADJ"} ,
		{"word":"vénitien","word_nosc":"venitien","lemma":"vénitien","pos":"ADJ"} ,
		{"word":"vénitienne","word_nosc":"venitienne","lemma":"vénitien","pos":"ADJ"} ,
		{"word":"vénitiennes","word_nosc":"venitiennes","lemma":"vénitien","pos":"ADJ"} ,
		{"word":"vénitiens","word_nosc":"venitiens","lemma":"vénitien","pos":"ADJ"} ,
		{"word":"vénusien","word_nosc":"venusien","lemma":"vénusien","pos":"ADJ"} ,
		{"word":"vénusienne","word_nosc":"venusienne","lemma":"vénusien","pos":"ADJ"} ,
		{"word":"vénusiens","word_nosc":"venusiens","lemma":"vénusien","pos":"ADJ"} ,
		{"word":"vénéneuse","word_nosc":"veneneuse","lemma":"vénéneux","pos":"ADJ"} ,
		{"word":"vénéneuses","word_nosc":"veneneuses","lemma":"vénéneux","pos":"ADJ"} ,
		{"word":"vénéneux","word_nosc":"veneneux","lemma":"vénéneux","pos":"ADJ"} ,
		{"word":"vénérable","word_nosc":"venerable","lemma":"vénérable","pos":"ADJ"} ,
		{"word":"vénérables","word_nosc":"venerables","lemma":"vénérable","pos":"ADJ"} ,
		{"word":"vénérien","word_nosc":"venerien","lemma":"vénérien","pos":"ADJ"} ,
		{"word":"vénérienne","word_nosc":"venerienne","lemma":"vénérien","pos":"ADJ"} ,
		{"word":"vénériennes","word_nosc":"veneriennes","lemma":"vénérien","pos":"ADJ"} ,
		{"word":"vénériens","word_nosc":"veneriens","lemma":"vénérien","pos":"ADJ"} ,
		{"word":"vénéré","word_nosc":"venere","lemma":"vénére","pos":"ADJ"} ,
		{"word":"vénéré","word_nosc":"venere","lemma":"vénéré","pos":"ADJ"} ,
		{"word":"vénérée","word_nosc":"veneree","lemma":"vénéré","pos":"ADJ"} ,
		{"word":"vénérées","word_nosc":"venerees","lemma":"vénéré","pos":"ADJ"} ,
		{"word":"vénérés","word_nosc":"veneres","lemma":"vénéré","pos":"ADJ"} ,
		{"word":"vénézuélien","word_nosc":"venezuelien","lemma":"vénézuélien","pos":"ADJ"} ,
		{"word":"vénézuélienne","word_nosc":"venezuelienne","lemma":"vénézuélien","pos":"ADJ"} ,
		{"word":"vénézuéliens","word_nosc":"venezueliens","lemma":"vénézuélien","pos":"ADJ"} ,
		{"word":"vérace","word_nosc":"verace","lemma":"vérace","pos":"ADJ"} ,
		{"word":"véraces","word_nosc":"veraces","lemma":"vérace","pos":"ADJ"} ,
		{"word":"véreuse","word_nosc":"vereuse","lemma":"véreux","pos":"ADJ"} ,
		{"word":"véreuses","word_nosc":"vereuses","lemma":"véreux","pos":"ADJ"} ,
		{"word":"véreux","word_nosc":"vereux","lemma":"véreux","pos":"ADJ"} ,
		{"word":"véridique","word_nosc":"veridique","lemma":"véridique","pos":"ADJ"} ,
		{"word":"véridiques","word_nosc":"veridiques","lemma":"véridique","pos":"ADJ"} ,
		{"word":"vérifiable","word_nosc":"verifiable","lemma":"vérifiable","pos":"ADJ"} ,
		{"word":"vérifiables","word_nosc":"verifiables","lemma":"vérifiable","pos":"ADJ"} ,
		{"word":"vérifié","word_nosc":"verifie","lemma":"vérifié","pos":"ADJ"} ,
		{"word":"vérifiée","word_nosc":"verifiee","lemma":"vérifié","pos":"ADJ"} ,
		{"word":"vérifiées","word_nosc":"verifiees","lemma":"vérifié","pos":"ADJ"} ,
		{"word":"vérifiés","word_nosc":"verifies","lemma":"vérifié","pos":"ADJ"} ,
		{"word":"vériste","word_nosc":"veriste","lemma":"vériste","pos":"ADJ"} ,
		{"word":"véritable","word_nosc":"veritable","lemma":"véritable","pos":"ADJ"} ,
		{"word":"véritables","word_nosc":"veritables","lemma":"véritable","pos":"ADJ"} ,
		{"word":"vérolé","word_nosc":"verole","lemma":"vérolé","pos":"ADJ"} ,
		{"word":"vérolée","word_nosc":"verolee","lemma":"vérolé","pos":"ADJ"} ,
		{"word":"vérolées","word_nosc":"verolees","lemma":"vérolé","pos":"ADJ"} ,
		{"word":"vérolés","word_nosc":"veroles","lemma":"vérolé","pos":"ADJ"} ,
		{"word":"véronaise","word_nosc":"veronaise","lemma":"véronais","pos":"ADJ"} ,
		{"word":"vésicale","word_nosc":"vesicale","lemma":"vésical","pos":"ADJ"} ,
		{"word":"vésicante","word_nosc":"vesicante","lemma":"vésicant","pos":"ADJ"} ,
		{"word":"vésicatoire","word_nosc":"vesicatoire","lemma":"vésicatoire","pos":"ADJ"} ,
		{"word":"vésicatoires","word_nosc":"vesicatoires","lemma":"vésicatoire","pos":"ADJ"} ,
		{"word":"vésiculaire","word_nosc":"vesiculaire","lemma":"vésiculaire","pos":"ADJ"} ,
		{"word":"vésuviennes","word_nosc":"vesuviennes","lemma":"vésuvien","pos":"ADJ"} ,
		{"word":"vétilleuse","word_nosc":"vetilleuse","lemma":"vétilleux","pos":"ADJ"} ,
		{"word":"vétilleuses","word_nosc":"vetilleuses","lemma":"vétilleux","pos":"ADJ"} ,
		{"word":"vétilleux","word_nosc":"vetilleux","lemma":"vétilleux","pos":"ADJ"} ,
		{"word":"vétuste","word_nosc":"vetuste","lemma":"vétuste","pos":"ADJ"} ,
		{"word":"vétustes","word_nosc":"vetustes","lemma":"vétuste","pos":"ADJ"} ,
		{"word":"vétérinaire","word_nosc":"veterinaire","lemma":"vétérinaire","pos":"ADJ"} ,
		{"word":"vétérinaires","word_nosc":"veterinaires","lemma":"vétérinaire","pos":"ADJ"} ,
		{"word":"vêtu","word_nosc":"vetu","lemma":"vêtu","pos":"ADJ"} ,
		{"word":"vêtue","word_nosc":"vetue","lemma":"vêtu","pos":"ADJ"} ,
		{"word":"vêtues","word_nosc":"vetues","lemma":"vêtu","pos":"ADJ"} ,
		{"word":"vêtus","word_nosc":"vetus","lemma":"vêtu","pos":"ADJ"} ,
		{"word":"vôtre","word_nosc":"votre","lemma":"vôtre","pos":"ADJ:pos"} ,
		{"word":"vôtres","word_nosc":"votres","lemma":"vôtres","pos":"ADJ:pos"} ,
		{"word":"wagnérien","word_nosc":"wagnerien","lemma":"wagnérien","pos":"ADJ"} ,
		{"word":"wagnérienne","word_nosc":"wagnerienne","lemma":"wagnérien","pos":"ADJ"} ,
		{"word":"wagnériennes","word_nosc":"wagneriennes","lemma":"wagnérien","pos":"ADJ"} ,
		{"word":"wallon","word_nosc":"wallon","lemma":"wallon","pos":"ADJ"} ,
		{"word":"wallonne","word_nosc":"wallonne","lemma":"wallon","pos":"ADJ"} ,
		{"word":"walter","word_nosc":"walter","lemma":"walter","pos":"ADJ"} ,
		{"word":"waterproof","word_nosc":"waterproof","lemma":"waterproof","pos":"ADJ"} ,
		{"word":"weimarienne","word_nosc":"weimarienne","lemma":"weimarien","pos":"ADJ"} ,
		{"word":"westphalien","word_nosc":"westphalien","lemma":"westphalien","pos":"ADJ"} ,
		{"word":"westphaliennes","word_nosc":"westphaliennes","lemma":"westphalien","pos":"ADJ"} ,
		{"word":"wilhelmien","word_nosc":"wilhelmien","lemma":"wilhelmien","pos":"ADJ"} ,
		{"word":"wisigothique","word_nosc":"wisigothique","lemma":"wisigothique","pos":"ADJ"} ,
		{"word":"wisigothiques","word_nosc":"wisigothiques","lemma":"wisigothique","pos":"ADJ"} ,
		{"word":"wurtembergeois","word_nosc":"wurtembergeois","lemma":"wurtembergeois","pos":"ADJ"} ,
		{"word":"wurtembergeoise","word_nosc":"wurtembergeoise","lemma":"wurtembergeois","pos":"ADJ"} ,
		{"word":"x","word_nosc":"x","lemma":"x","pos":"ADJ:num"} ,
		{"word":"xiphoïde","word_nosc":"xiphoide","lemma":"xiphoïde","pos":"ADJ"} ,
		{"word":"xième","word_nosc":"xieme","lemma":"xième","pos":"ADJ"} ,
		{"word":"xénophobe","word_nosc":"xenophobe","lemma":"xénophobe","pos":"ADJ"} ,
		{"word":"xénophobes","word_nosc":"xenophobes","lemma":"xénophobe","pos":"ADJ"} ,
		{"word":"xérographique","word_nosc":"xerographique","lemma":"xérographique","pos":"ADJ"} ,
		{"word":"yanquis","word_nosc":"yanquis","lemma":"yanqui","pos":"ADJ"} ,
		{"word":"yiddish","word_nosc":"yiddish","lemma":"yiddish","pos":"ADJ"} ,
		{"word":"yougoslave","word_nosc":"yougoslave","lemma":"yougoslave","pos":"ADJ"} ,
		{"word":"yougoslaves","word_nosc":"yougoslaves","lemma":"yougoslave","pos":"ADJ"} ,
		{"word":"yéménites","word_nosc":"yemenites","lemma":"yéménite","pos":"ADJ"} ,
		{"word":"zain","word_nosc":"zain","lemma":"zain","pos":"ADJ"} ,
		{"word":"zarbi","word_nosc":"zarbi","lemma":"zarbi","pos":"ADJ"} ,
		{"word":"zarbis","word_nosc":"zarbis","lemma":"zarbi","pos":"ADJ"} ,
		{"word":"zazou","word_nosc":"zazou","lemma":"zazou","pos":"ADJ"} ,
		{"word":"zazous","word_nosc":"zazous","lemma":"zazou","pos":"ADJ"} ,
		{"word":"zaïroise","word_nosc":"zairoise","lemma":"zaïrois","pos":"ADJ"} ,
		{"word":"zaïroises","word_nosc":"zairoises","lemma":"zaïrois","pos":"ADJ"} ,
		{"word":"zen","word_nosc":"zen","lemma":"zen","pos":"ADJ"} ,
		{"word":"zigzagant","word_nosc":"zigzagant","lemma":"zigzagant","pos":"ADJ"} ,
		{"word":"zigzagante","word_nosc":"zigzagante","lemma":"zigzagant","pos":"ADJ"} ,
		{"word":"zigzagantes","word_nosc":"zigzagantes","lemma":"zigzagant","pos":"ADJ"} ,
		{"word":"zinzin","word_nosc":"zinzin","lemma":"zinzin","pos":"ADJ"} ,
		{"word":"zinzolin","word_nosc":"zinzolin","lemma":"zinzolin","pos":"ADJ"} ,
		{"word":"zodiacal","word_nosc":"zodiacal","lemma":"zodiacal","pos":"ADJ"} ,
		{"word":"zodiacale","word_nosc":"zodiacale","lemma":"zodiacal","pos":"ADJ"} ,
		{"word":"zonal","word_nosc":"zonal","lemma":"zonal","pos":"ADJ"} ,
		{"word":"zoologique","word_nosc":"zoologique","lemma":"zoologique","pos":"ADJ"} ,
		{"word":"zoologiques","word_nosc":"zoologiques","lemma":"zoologique","pos":"ADJ"} ,
		{"word":"zoophile","word_nosc":"zoophile","lemma":"zoophile","pos":"ADJ"} ,
		{"word":"zoophiles","word_nosc":"zoophiles","lemma":"zoophile","pos":"ADJ"} ,
		{"word":"zoroastrien","word_nosc":"zoroastrien","lemma":"zoroastrien","pos":"ADJ"} ,
		{"word":"zoulou","word_nosc":"zoulou","lemma":"zoulou","pos":"ADJ"} ,
		{"word":"zouloue","word_nosc":"zouloue","lemma":"zoulou","pos":"ADJ"} ,
		{"word":"zoulous","word_nosc":"zoulous","lemma":"zoulou","pos":"ADJ"} ,
		{"word":"zurichois","word_nosc":"zurichois","lemma":"zurichois","pos":"ADJ"} ,
		{"word":"zurichoise","word_nosc":"zurichoise","lemma":"zurichois","pos":"ADJ"} ,
		{"word":"zygomatique","word_nosc":"zygomatique","lemma":"zygomatique","pos":"ADJ"} ,
		{"word":"zygomatiques","word_nosc":"zygomatiques","lemma":"zygomatique","pos":"ADJ"} ,
		{"word":"zébré","word_nosc":"zebre","lemma":"zébré","pos":"ADJ"} ,
		{"word":"zébrée","word_nosc":"zebree","lemma":"zébré","pos":"ADJ"} ,
		{"word":"zébrées","word_nosc":"zebrees","lemma":"zébré","pos":"ADJ"} ,
		{"word":"zébrés","word_nosc":"zebres","lemma":"zébré","pos":"ADJ"} ,
		{"word":"zélé","word_nosc":"zele","lemma":"zélé","pos":"ADJ"} ,
		{"word":"zélée","word_nosc":"zelee","lemma":"zélé","pos":"ADJ"} ,
		{"word":"zélées","word_nosc":"zelees","lemma":"zélé","pos":"ADJ"} ,
		{"word":"zélés","word_nosc":"zeles","lemma":"zélé","pos":"ADJ"} ,
		{"word":"zénithale","word_nosc":"zenithale","lemma":"zénithal","pos":"ADJ"} ,
		{"word":"zénithales","word_nosc":"zenithales","lemma":"zénithal","pos":"ADJ"} ,
		{"word":"zéphire","word_nosc":"zephire","lemma":"zéphire","pos":"ADJ"} ,
		{"word":"âcre","word_nosc":"acre","lemma":"âcre","pos":"ADJ"} ,
		{"word":"âcres","word_nosc":"acres","lemma":"âcre","pos":"ADJ"} ,
		{"word":"âgé","word_nosc":"age","lemma":"âgé","pos":"ADJ"} ,
		{"word":"âgée","word_nosc":"agee","lemma":"âgé","pos":"ADJ"} ,
		{"word":"âgées","word_nosc":"agees","lemma":"âgé","pos":"ADJ"} ,
		{"word":"âgés","word_nosc":"ages","lemma":"âgé","pos":"ADJ"} ,
		{"word":"âpre","word_nosc":"apre","lemma":"âpre","pos":"ADJ"} ,
		{"word":"âpres","word_nosc":"apres","lemma":"âpre","pos":"ADJ"} ,
		{"word":"ébahi","word_nosc":"ebahi","lemma":"ébahi","pos":"ADJ"} ,
		{"word":"ébahie","word_nosc":"ebahie","lemma":"ébahi","pos":"ADJ"} ,
		{"word":"ébahies","word_nosc":"ebahies","lemma":"ébahi","pos":"ADJ"} ,
		{"word":"ébahis","word_nosc":"ebahis","lemma":"ébahi","pos":"ADJ"} ,
		{"word":"ébaubi","word_nosc":"ebaubi","lemma":"ébaubi","pos":"ADJ"} ,
		{"word":"ébaubie","word_nosc":"ebaubie","lemma":"ébaubi","pos":"ADJ"} ,
		{"word":"ébauché","word_nosc":"ebauche","lemma":"ébauché","pos":"ADJ"} ,
		{"word":"ébauchée","word_nosc":"ebauchee","lemma":"ébauché","pos":"ADJ"} ,
		{"word":"ébauchées","word_nosc":"ebauchees","lemma":"ébauché","pos":"ADJ"} ,
		{"word":"ébauchés","word_nosc":"ebauches","lemma":"ébauché","pos":"ADJ"} ,
		{"word":"éberlué","word_nosc":"eberlue","lemma":"éberlué","pos":"ADJ"} ,
		{"word":"éberluée","word_nosc":"eberluee","lemma":"éberlué","pos":"ADJ"} ,
		{"word":"éberluées","word_nosc":"eberluees","lemma":"éberlué","pos":"ADJ"} ,
		{"word":"éberlués","word_nosc":"eberlues","lemma":"éberlué","pos":"ADJ"} ,
		{"word":"ébloui","word_nosc":"ebloui","lemma":"ébloui","pos":"ADJ"} ,
		{"word":"éblouie","word_nosc":"eblouie","lemma":"ébloui","pos":"ADJ"} ,
		{"word":"éblouies","word_nosc":"eblouies","lemma":"ébloui","pos":"ADJ"} ,
		{"word":"éblouis","word_nosc":"eblouis","lemma":"ébloui","pos":"ADJ"} ,
		{"word":"éblouissant","word_nosc":"eblouissant","lemma":"éblouissant","pos":"ADJ"} ,
		{"word":"éblouissante","word_nosc":"eblouissante","lemma":"éblouissant","pos":"ADJ"} ,
		{"word":"éblouissantes","word_nosc":"eblouissantes","lemma":"éblouissant","pos":"ADJ"} ,
		{"word":"éblouissants","word_nosc":"eblouissants","lemma":"éblouissant","pos":"ADJ"} ,
		{"word":"ébouillanté","word_nosc":"ebouillante","lemma":"ébouillanté","pos":"ADJ"} ,
		{"word":"ébouillantée","word_nosc":"ebouillantee","lemma":"ébouillanté","pos":"ADJ"} ,
		{"word":"ébouillantées","word_nosc":"ebouillantees","lemma":"ébouillanté","pos":"ADJ"} ,
		{"word":"ébouillantés","word_nosc":"ebouillantes","lemma":"ébouillanté","pos":"ADJ"} ,
		{"word":"ébouleuses","word_nosc":"ebouleuses","lemma":"ébouleux","pos":"ADJ"} ,
		{"word":"ébouriffant","word_nosc":"ebouriffant","lemma":"ébouriffant","pos":"ADJ"} ,
		{"word":"ébouriffante","word_nosc":"ebouriffante","lemma":"ébouriffant","pos":"ADJ"} ,
		{"word":"ébouriffé","word_nosc":"ebouriffe","lemma":"ébouriffé","pos":"ADJ"} ,
		{"word":"ébouriffée","word_nosc":"ebouriffee","lemma":"ébouriffé","pos":"ADJ"} ,
		{"word":"ébouriffées","word_nosc":"ebouriffees","lemma":"ébouriffé","pos":"ADJ"} ,
		{"word":"ébouriffés","word_nosc":"ebouriffes","lemma":"ébouriffé","pos":"ADJ"} ,
		{"word":"ébranché","word_nosc":"ebranche","lemma":"ébranché","pos":"ADJ"} ,
		{"word":"ébréché","word_nosc":"ebreche","lemma":"ébréché","pos":"ADJ"} ,
		{"word":"ébréchée","word_nosc":"ebrechee","lemma":"ébréché","pos":"ADJ"} ,
		{"word":"ébréchées","word_nosc":"ebrechees","lemma":"ébréché","pos":"ADJ"} ,
		{"word":"ébréchés","word_nosc":"ebreches","lemma":"ébréché","pos":"ADJ"} ,
		{"word":"ébène","word_nosc":"ebene","lemma":"ébène","pos":"ADJ"} ,
		{"word":"écailleuse","word_nosc":"ecailleuse","lemma":"écailleux","pos":"ADJ"} ,
		{"word":"écailleuses","word_nosc":"ecailleuses","lemma":"écailleux","pos":"ADJ"} ,
		{"word":"écailleux","word_nosc":"ecailleux","lemma":"écailleux","pos":"ADJ"} ,
		{"word":"écaillé","word_nosc":"ecaille","lemma":"écaillé","pos":"ADJ"} ,
		{"word":"écaillée","word_nosc":"ecaillee","lemma":"écaillé","pos":"ADJ"} ,
		{"word":"écaillées","word_nosc":"ecaillees","lemma":"écaillé","pos":"ADJ"} ,
		{"word":"écaillés","word_nosc":"ecailles","lemma":"écaillé","pos":"ADJ"} ,
		{"word":"écarlate","word_nosc":"ecarlate","lemma":"écarlate","pos":"ADJ"} ,
		{"word":"écarlates","word_nosc":"ecarlates","lemma":"écarlate","pos":"ADJ"} ,
		{"word":"écartelé","word_nosc":"ecartele","lemma":"écartelé","pos":"ADJ"} ,
		{"word":"écartelée","word_nosc":"ecartelee","lemma":"écartelé","pos":"ADJ"} ,
		{"word":"écartelées","word_nosc":"ecartelees","lemma":"écartelé","pos":"ADJ"} ,
		{"word":"écartelés","word_nosc":"ecarteles","lemma":"écartelé","pos":"ADJ"} ,
		{"word":"écarté","word_nosc":"ecarte","lemma":"écarté","pos":"ADJ"} ,
		{"word":"écartée","word_nosc":"ecartee","lemma":"écarté","pos":"ADJ"} ,
		{"word":"écartées","word_nosc":"ecartees","lemma":"écarté","pos":"ADJ"} ,
		{"word":"écartés","word_nosc":"ecartes","lemma":"écarté","pos":"ADJ"} ,
		{"word":"écervelé","word_nosc":"ecervele","lemma":"écervelé","pos":"ADJ"} ,
		{"word":"écervelée","word_nosc":"ecervelee","lemma":"écervelé","pos":"ADJ"} ,
		{"word":"écervelées","word_nosc":"ecervelees","lemma":"écervelé","pos":"ADJ"} ,
		{"word":"écervelés","word_nosc":"ecerveles","lemma":"écervelé","pos":"ADJ"} ,
		{"word":"échancré","word_nosc":"echancre","lemma":"échancré","pos":"ADJ"} ,
		{"word":"échancrée","word_nosc":"echancree","lemma":"échancré","pos":"ADJ"} ,
		{"word":"échancrées","word_nosc":"echancrees","lemma":"échancré","pos":"ADJ"} ,
		{"word":"échancrés","word_nosc":"echancres","lemma":"échancré","pos":"ADJ"} ,
		{"word":"échangeables","word_nosc":"echangeables","lemma":"échangeable","pos":"ADJ"} ,
		{"word":"échappé","word_nosc":"echappe","lemma":"échappé","pos":"ADJ"} ,
		{"word":"échappée","word_nosc":"echappee","lemma":"échappé","pos":"ADJ"} ,
		{"word":"échappées","word_nosc":"echappees","lemma":"échappé","pos":"ADJ"} ,
		{"word":"échappés","word_nosc":"echappes","lemma":"échappé","pos":"ADJ"} ,
		{"word":"échassière","word_nosc":"echassiere","lemma":"échassier","pos":"ADJ"} ,
		{"word":"échassières","word_nosc":"echassieres","lemma":"échassier","pos":"ADJ"} ,
		{"word":"échaudé","word_nosc":"echaude","lemma":"échaudé","pos":"ADJ"} ,
		{"word":"échaudée","word_nosc":"echaudee","lemma":"échaudé","pos":"ADJ"} ,
		{"word":"échevelé","word_nosc":"echevele","lemma":"échevelé","pos":"ADJ"} ,
		{"word":"échevelée","word_nosc":"echevelee","lemma":"échevelé","pos":"ADJ"} ,
		{"word":"échevelées","word_nosc":"echevelees","lemma":"échevelé","pos":"ADJ"} ,
		{"word":"échevelés","word_nosc":"echeveles","lemma":"échevelé","pos":"ADJ"} ,
		{"word":"échéant","word_nosc":"echeant","lemma":"échéant","pos":"ADJ"} ,
		{"word":"éclaboussant","word_nosc":"eclaboussant","lemma":"éclaboussant","pos":"ADJ"} ,
		{"word":"éclaboussante","word_nosc":"eclaboussante","lemma":"éclaboussant","pos":"ADJ"} ,
		{"word":"éclaboussantes","word_nosc":"eclaboussantes","lemma":"éclaboussant","pos":"ADJ"} ,
		{"word":"éclaboussants","word_nosc":"eclaboussants","lemma":"éclaboussant","pos":"ADJ"} ,
		{"word":"éclair","word_nosc":"eclair","lemma":"éclair","pos":"ADJ"} ,
		{"word":"éclairant","word_nosc":"eclairant","lemma":"éclairant","pos":"ADJ"} ,
		{"word":"éclairante","word_nosc":"eclairante","lemma":"éclairant","pos":"ADJ"} ,
		{"word":"éclairantes","word_nosc":"eclairantes","lemma":"éclairant","pos":"ADJ"} ,
		{"word":"éclairants","word_nosc":"eclairants","lemma":"éclairant","pos":"ADJ"} ,
		{"word":"éclairé","word_nosc":"eclaire","lemma":"éclairé","pos":"ADJ"} ,
		{"word":"éclairée","word_nosc":"eclairee","lemma":"éclairé","pos":"ADJ"} ,
		{"word":"éclairées","word_nosc":"eclairees","lemma":"éclairé","pos":"ADJ"} ,
		{"word":"éclairés","word_nosc":"eclaires","lemma":"éclairé","pos":"ADJ"} ,
		{"word":"éclamptique","word_nosc":"eclamptique","lemma":"éclamptique","pos":"ADJ"} ,
		{"word":"éclatant","word_nosc":"eclatant","lemma":"éclatant","pos":"ADJ"} ,
		{"word":"éclatante","word_nosc":"eclatante","lemma":"éclatant","pos":"ADJ"} ,
		{"word":"éclatantes","word_nosc":"eclatantes","lemma":"éclatant","pos":"ADJ"} ,
		{"word":"éclatants","word_nosc":"eclatants","lemma":"éclatant","pos":"ADJ"} ,
		{"word":"éclaté","word_nosc":"eclate","lemma":"éclaté","pos":"ADJ"} ,
		{"word":"éclatée","word_nosc":"eclatee","lemma":"éclaté","pos":"ADJ"} ,
		{"word":"éclatées","word_nosc":"eclatees","lemma":"éclaté","pos":"ADJ"} ,
		{"word":"éclatés","word_nosc":"eclates","lemma":"éclaté","pos":"ADJ"} ,
		{"word":"éclectique","word_nosc":"eclectique","lemma":"éclectique","pos":"ADJ"} ,
		{"word":"éclectiques","word_nosc":"eclectiques","lemma":"éclectique","pos":"ADJ"} ,
		{"word":"éclopé","word_nosc":"eclope","lemma":"éclopé","pos":"ADJ"} ,
		{"word":"éclopée","word_nosc":"eclopee","lemma":"éclopé","pos":"ADJ"} ,
		{"word":"éclopés","word_nosc":"eclopes","lemma":"éclopé","pos":"ADJ"} ,
		{"word":"éclos","word_nosc":"eclos","lemma":"éclos","pos":"ADJ"} ,
		{"word":"éclose","word_nosc":"eclose","lemma":"éclos","pos":"ADJ"} ,
		{"word":"écloses","word_nosc":"ecloses","lemma":"éclos","pos":"ADJ"} ,
		{"word":"éclusier","word_nosc":"eclusier","lemma":"éclusier","pos":"ADJ"} ,
		{"word":"écoeurant","word_nosc":"ecoeurant","lemma":"écoeurant","pos":"ADJ"} ,
		{"word":"écoeurante","word_nosc":"ecoeurante","lemma":"écoeurant","pos":"ADJ"} ,
		{"word":"écoeurantes","word_nosc":"ecoeurantes","lemma":"écoeurant","pos":"ADJ"} ,
		{"word":"écoeurants","word_nosc":"ecoeurants","lemma":"écoeurant","pos":"ADJ"} ,
		{"word":"écoeuré","word_nosc":"ecoeure","lemma":"écoeuré","pos":"ADJ"} ,
		{"word":"écoeurée","word_nosc":"ecoeuree","lemma":"écoeuré","pos":"ADJ"} ,
		{"word":"écoeurés","word_nosc":"ecoeures","lemma":"écoeuré","pos":"ADJ"} ,
		{"word":"écologique","word_nosc":"ecologique","lemma":"écologique","pos":"ADJ"} ,
		{"word":"écologiques","word_nosc":"ecologiques","lemma":"écologique","pos":"ADJ"} ,
		{"word":"écologiste","word_nosc":"ecologiste","lemma":"écologiste","pos":"ADJ"} ,
		{"word":"écologistes","word_nosc":"ecologistes","lemma":"écologiste","pos":"ADJ"} ,
		{"word":"économe","word_nosc":"econome","lemma":"économe","pos":"ADJ"} ,
		{"word":"économes","word_nosc":"economes","lemma":"économe","pos":"ADJ"} ,
		{"word":"économique","word_nosc":"economique","lemma":"économique","pos":"ADJ"} ,
		{"word":"économiques","word_nosc":"economiques","lemma":"économique","pos":"ADJ"} ,
		{"word":"écorché","word_nosc":"ecorche","lemma":"écorché","pos":"ADJ"} ,
		{"word":"écorchée","word_nosc":"ecorchee","lemma":"écorché","pos":"ADJ"} ,
		{"word":"écorchées","word_nosc":"ecorchees","lemma":"écorché","pos":"ADJ"} ,
		{"word":"écorchés","word_nosc":"ecorches","lemma":"écorché","pos":"ADJ"} ,
		{"word":"écorcé","word_nosc":"ecorce","lemma":"écorcé","pos":"ADJ"} ,
		{"word":"écorcés","word_nosc":"ecorces","lemma":"écorcé","pos":"ADJ"} ,
		{"word":"écorné","word_nosc":"ecorne","lemma":"écorné","pos":"ADJ"} ,
		{"word":"écornée","word_nosc":"ecornee","lemma":"écorné","pos":"ADJ"} ,
		{"word":"écornées","word_nosc":"ecornees","lemma":"écorné","pos":"ADJ"} ,
		{"word":"écornés","word_nosc":"ecornes","lemma":"écorné","pos":"ADJ"} ,
		{"word":"écossais","word_nosc":"ecossais","lemma":"écossais","pos":"ADJ"} ,
		{"word":"écossaise","word_nosc":"ecossaise","lemma":"écossais","pos":"ADJ"} ,
		{"word":"écossaises","word_nosc":"ecossaises","lemma":"écossais","pos":"ADJ"} ,
		{"word":"écoulé","word_nosc":"ecoule","lemma":"écoulé","pos":"ADJ"} ,
		{"word":"écoulée","word_nosc":"ecoulee","lemma":"écoulé","pos":"ADJ"} ,
		{"word":"écoulées","word_nosc":"ecoulees","lemma":"écoulé","pos":"ADJ"} ,
		{"word":"écoulés","word_nosc":"ecoules","lemma":"écoulé","pos":"ADJ"} ,
		{"word":"écourté","word_nosc":"ecourte","lemma":"écourté","pos":"ADJ"} ,
		{"word":"écourtée","word_nosc":"ecourtee","lemma":"écourté","pos":"ADJ"} ,
		{"word":"écourtées","word_nosc":"ecourtees","lemma":"écourté","pos":"ADJ"} ,
		{"word":"écourtés","word_nosc":"ecourtes","lemma":"écourté","pos":"ADJ"} ,
		{"word":"écrasant","word_nosc":"ecrasant","lemma":"écrasant","pos":"ADJ"} ,
		{"word":"écrasante","word_nosc":"ecrasante","lemma":"écrasant","pos":"ADJ"} ,
		{"word":"écrasantes","word_nosc":"ecrasantes","lemma":"écrasant","pos":"ADJ"} ,
		{"word":"écrasants","word_nosc":"ecrasants","lemma":"écrasant","pos":"ADJ"} ,
		{"word":"écrasé","word_nosc":"ecrase","lemma":"écrasé","pos":"ADJ"} ,
		{"word":"écrasée","word_nosc":"ecrasee","lemma":"écrasé","pos":"ADJ"} ,
		{"word":"écrasées","word_nosc":"ecrasees","lemma":"écrasé","pos":"ADJ"} ,
		{"word":"écrasés","word_nosc":"ecrases","lemma":"écrasé","pos":"ADJ"} ,
		{"word":"écrevisse","word_nosc":"ecrevisse","lemma":"écrevisse","pos":"ADJ"} ,
		{"word":"écrit","word_nosc":"ecrit","lemma":"écrit","pos":"ADJ"} ,
		{"word":"écrite","word_nosc":"ecrite","lemma":"écrit","pos":"ADJ"} ,
		{"word":"écrites","word_nosc":"ecrites","lemma":"écrit","pos":"ADJ"} ,
		{"word":"écrits","word_nosc":"ecrits","lemma":"écrit","pos":"ADJ"} ,
		{"word":"écru","word_nosc":"ecru","lemma":"écru","pos":"ADJ"} ,
		{"word":"écrue","word_nosc":"ecrue","lemma":"écru","pos":"ADJ"} ,
		{"word":"éculé","word_nosc":"ecule","lemma":"éculé","pos":"ADJ"} ,
		{"word":"éculée","word_nosc":"eculee","lemma":"éculé","pos":"ADJ"} ,
		{"word":"éculées","word_nosc":"eculees","lemma":"éculé","pos":"ADJ"} ,
		{"word":"éculés","word_nosc":"ecules","lemma":"éculé","pos":"ADJ"} ,
		{"word":"écumant","word_nosc":"ecumant","lemma":"écumant","pos":"ADJ"} ,
		{"word":"écumante","word_nosc":"ecumante","lemma":"écumant","pos":"ADJ"} ,
		{"word":"écumantes","word_nosc":"ecumantes","lemma":"écumant","pos":"ADJ"} ,
		{"word":"écumants","word_nosc":"ecumants","lemma":"écumant","pos":"ADJ"} ,
		{"word":"écumeuse","word_nosc":"ecumeuse","lemma":"écumeux","pos":"ADJ"} ,
		{"word":"écumeuses","word_nosc":"ecumeuses","lemma":"écumeux","pos":"ADJ"} ,
		{"word":"écumeux","word_nosc":"ecumeux","lemma":"écumeux","pos":"ADJ"} ,
		{"word":"édenté","word_nosc":"edente","lemma":"édenté","pos":"ADJ"} ,
		{"word":"édentée","word_nosc":"edentee","lemma":"édenté","pos":"ADJ"} ,
		{"word":"édentées","word_nosc":"edentees","lemma":"édenté","pos":"ADJ"} ,
		{"word":"édentés","word_nosc":"edentes","lemma":"édenté","pos":"ADJ"} ,
		{"word":"édifiant","word_nosc":"edifiant","lemma":"édifiant","pos":"ADJ"} ,
		{"word":"édifiante","word_nosc":"edifiante","lemma":"édifiant","pos":"ADJ"} ,
		{"word":"édifiantes","word_nosc":"edifiantes","lemma":"édifiant","pos":"ADJ"} ,
		{"word":"édifiants","word_nosc":"edifiants","lemma":"édifiant","pos":"ADJ"} ,
		{"word":"édilitaire","word_nosc":"edilitaire","lemma":"édilitaire","pos":"ADJ"} ,
		{"word":"édilitaires","word_nosc":"edilitaires","lemma":"édilitaire","pos":"ADJ"} ,
		{"word":"éditorial","word_nosc":"editorial","lemma":"éditorial","pos":"ADJ"} ,
		{"word":"éditoriale","word_nosc":"editoriale","lemma":"éditorial","pos":"ADJ"} ,
		{"word":"éditoriaux","word_nosc":"editoriaux","lemma":"éditorial","pos":"ADJ"} ,
		{"word":"édouardienne","word_nosc":"edouardienne","lemma":"édouardien","pos":"ADJ"} ,
		{"word":"éducable","word_nosc":"educable","lemma":"éducable","pos":"ADJ"} ,
		{"word":"éducables","word_nosc":"educables","lemma":"éducable","pos":"ADJ"} ,
		{"word":"éducateur","word_nosc":"educateur","lemma":"éducateur","pos":"ADJ"} ,
		{"word":"éducateurs","word_nosc":"educateurs","lemma":"éducateur","pos":"ADJ"} ,
		{"word":"éducatif","word_nosc":"educatif","lemma":"éducatif","pos":"ADJ"} ,
		{"word":"éducatifs","word_nosc":"educatifs","lemma":"éducatif","pos":"ADJ"} ,
		{"word":"éducationnel","word_nosc":"educationnel","lemma":"éducationnel","pos":"ADJ"} ,
		{"word":"éducationnelle","word_nosc":"educationnelle","lemma":"éducationnel","pos":"ADJ"} ,
		{"word":"éducative","word_nosc":"educative","lemma":"éducatif","pos":"ADJ"} ,
		{"word":"éducatives","word_nosc":"educatives","lemma":"éducatif","pos":"ADJ"} ,
		{"word":"éducatrice","word_nosc":"educatrice","lemma":"éducateur","pos":"ADJ"} ,
		{"word":"éduen","word_nosc":"eduen","lemma":"éduen","pos":"ADJ"} ,
		{"word":"éduennes","word_nosc":"eduennes","lemma":"éduen","pos":"ADJ"} ,
		{"word":"éduens","word_nosc":"eduens","lemma":"éduen","pos":"ADJ"} ,
		{"word":"édulcorant","word_nosc":"edulcorant","lemma":"édulcorant","pos":"ADJ"} ,
		{"word":"édénique","word_nosc":"edenique","lemma":"édénique","pos":"ADJ"} ,
		{"word":"édéniques","word_nosc":"edeniques","lemma":"édénique","pos":"ADJ"} ,
		{"word":"égal","word_nosc":"egal","lemma":"égal","pos":"ADJ"} ,
		{"word":"égalable","word_nosc":"egalable","lemma":"égalable","pos":"ADJ"} ,
		{"word":"égale","word_nosc":"egale","lemma":"égal","pos":"ADJ"} ,
		{"word":"égales","word_nosc":"egales","lemma":"égal","pos":"ADJ"} ,
		{"word":"égalisateur","word_nosc":"egalisateur","lemma":"égalisateur","pos":"ADJ"} ,
		{"word":"égalisateurs","word_nosc":"egalisateurs","lemma":"égalisateur","pos":"ADJ"} ,
		{"word":"égalisatrice","word_nosc":"egalisatrice","lemma":"égalisateur","pos":"ADJ"} ,
		{"word":"égalitaire","word_nosc":"egalitaire","lemma":"égalitaire","pos":"ADJ"} ,
		{"word":"égaré","word_nosc":"egare","lemma":"égaré","pos":"ADJ"} ,
		{"word":"égarée","word_nosc":"egaree","lemma":"égaré","pos":"ADJ"} ,
		{"word":"égarées","word_nosc":"egarees","lemma":"égaré","pos":"ADJ"} ,
		{"word":"égarés","word_nosc":"egares","lemma":"égaré","pos":"ADJ"} ,
		{"word":"égaux","word_nosc":"egaux","lemma":"égal","pos":"ADJ"} ,
		{"word":"égayante","word_nosc":"egayante","lemma":"égayant","pos":"ADJ"} ,
		{"word":"égocentrique","word_nosc":"egocentrique","lemma":"égocentrique","pos":"ADJ"} ,
		{"word":"égocentriques","word_nosc":"egocentriques","lemma":"égocentrique","pos":"ADJ"} ,
		{"word":"égotiste","word_nosc":"egotiste","lemma":"égotiste","pos":"ADJ"} ,
		{"word":"égotistes","word_nosc":"egotistes","lemma":"égotiste","pos":"ADJ"} ,
		{"word":"égoïste","word_nosc":"egoiste","lemma":"égoïste","pos":"ADJ"} ,
		{"word":"égoïstes","word_nosc":"egoistes","lemma":"égoïste","pos":"ADJ"} ,
		{"word":"égrillard","word_nosc":"egrillard","lemma":"égrillard","pos":"ADJ"} ,
		{"word":"égrillarde","word_nosc":"egrillarde","lemma":"égrillard","pos":"ADJ"} ,
		{"word":"égrillardes","word_nosc":"egrillardes","lemma":"égrillard","pos":"ADJ"} ,
		{"word":"égrillards","word_nosc":"egrillards","lemma":"égrillard","pos":"ADJ"} ,
		{"word":"égrotant","word_nosc":"egrotant","lemma":"égrotant","pos":"ADJ"} ,
		{"word":"égrotantes","word_nosc":"egrotantes","lemma":"égrotant","pos":"ADJ"} ,
		{"word":"égrotants","word_nosc":"egrotants","lemma":"égrotant","pos":"ADJ"} ,
		{"word":"égyptien","word_nosc":"egyptien","lemma":"égyptien","pos":"ADJ"} ,
		{"word":"égyptienne","word_nosc":"egyptienne","lemma":"égyptien","pos":"ADJ"} ,
		{"word":"égyptiennes","word_nosc":"egyptiennes","lemma":"égyptien","pos":"ADJ"} ,
		{"word":"égyptiens","word_nosc":"egyptiens","lemma":"égyptien","pos":"ADJ"} ,
		{"word":"égéenne","word_nosc":"egeenne","lemma":"égéen","pos":"ADJ"} ,
		{"word":"éhonté","word_nosc":"ehonte","lemma":"éhonté","pos":"ADJ"} ,
		{"word":"éhontée","word_nosc":"ehontee","lemma":"éhonté","pos":"ADJ"} ,
		{"word":"éhontées","word_nosc":"ehontees","lemma":"éhonté","pos":"ADJ"} ,
		{"word":"éhontés","word_nosc":"ehontes","lemma":"éhonté","pos":"ADJ"} ,
		{"word":"éjaculateur","word_nosc":"ejaculateur","lemma":"éjaculateur","pos":"ADJ"} ,
		{"word":"éjectable","word_nosc":"ejectable","lemma":"éjectable","pos":"ADJ"} ,
		{"word":"éjectables","word_nosc":"ejectables","lemma":"éjectable","pos":"ADJ"} ,
		{"word":"élaboré","word_nosc":"elabore","lemma":"élaboré","pos":"ADJ"} ,
		{"word":"élaborée","word_nosc":"elaboree","lemma":"élaboré","pos":"ADJ"} ,
		{"word":"élaborées","word_nosc":"elaborees","lemma":"élaboré","pos":"ADJ"} ,
		{"word":"élaborés","word_nosc":"elabores","lemma":"élaboré","pos":"ADJ"} ,
		{"word":"élancé","word_nosc":"elance","lemma":"élancé","pos":"ADJ"} ,
		{"word":"élancée","word_nosc":"elancee","lemma":"élancé","pos":"ADJ"} ,
		{"word":"élancées","word_nosc":"elancees","lemma":"élancé","pos":"ADJ"} ,
		{"word":"élancés","word_nosc":"elances","lemma":"élancé","pos":"ADJ"} ,
		{"word":"élastique","word_nosc":"elastique","lemma":"élastique","pos":"ADJ"} ,
		{"word":"élastiques","word_nosc":"elastiques","lemma":"élastique","pos":"ADJ"} ,
		{"word":"électif","word_nosc":"electif","lemma":"électif","pos":"ADJ"} ,
		{"word":"élective","word_nosc":"elective","lemma":"électif","pos":"ADJ"} ,
		{"word":"électives","word_nosc":"electives","lemma":"électif","pos":"ADJ"} ,
		{"word":"électoral","word_nosc":"electoral","lemma":"électoral","pos":"ADJ"} ,
		{"word":"électorale","word_nosc":"electorale","lemma":"électoral","pos":"ADJ"} ,
		{"word":"électorales","word_nosc":"electorales","lemma":"électoral","pos":"ADJ"} ,
		{"word":"électoraux","word_nosc":"electoraux","lemma":"électoral","pos":"ADJ"} ,
		{"word":"électrique","word_nosc":"electrique","lemma":"électrique","pos":"ADJ"} ,
		{"word":"électriques","word_nosc":"electriques","lemma":"électrique","pos":"ADJ"} ,
		{"word":"électrisant","word_nosc":"electrisant","lemma":"électrisant","pos":"ADJ"} ,
		{"word":"électrisante","word_nosc":"electrisante","lemma":"électrisant","pos":"ADJ"} ,
		{"word":"électroacoustique","word_nosc":"electroacoustique","lemma":"électroacoustique","pos":"ADJ"} ,
		{"word":"électrochimique","word_nosc":"electrochimique","lemma":"électrochimique","pos":"ADJ"} ,
		{"word":"électrochimiques","word_nosc":"electrochimiques","lemma":"électrochimique","pos":"ADJ"} ,
		{"word":"électrogène","word_nosc":"electrogene","lemma":"électrogène","pos":"ADJ"} ,
		{"word":"électrolytique","word_nosc":"electrolytique","lemma":"électrolytique","pos":"ADJ"} ,
		{"word":"électromagnétique","word_nosc":"electromagnetique","lemma":"électromagnétique","pos":"ADJ"} ,
		{"word":"électromagnétiques","word_nosc":"electromagnetiques","lemma":"électromagnétique","pos":"ADJ"} ,
		{"word":"électroménager","word_nosc":"electromenager","lemma":"électroménager","pos":"ADJ"} ,
		{"word":"électronique","word_nosc":"electronique","lemma":"électronique","pos":"ADJ"} ,
		{"word":"électroniques","word_nosc":"electroniques","lemma":"électronique","pos":"ADJ"} ,
		{"word":"électronucléaire","word_nosc":"electronucleaire","lemma":"électronucléaire","pos":"ADJ"} ,
		{"word":"électrostatique","word_nosc":"electrostatique","lemma":"électrostatique","pos":"ADJ"} ,
		{"word":"électrostatiques","word_nosc":"electrostatiques","lemma":"électrostatique","pos":"ADJ"} ,
		{"word":"éleusinienne","word_nosc":"eleusinienne","lemma":"éleusinien","pos":"ADJ"} ,
		{"word":"élevé","word_nosc":"eleve","lemma":"élevé","pos":"ADJ"} ,
		{"word":"élevée","word_nosc":"elevee","lemma":"élevé","pos":"ADJ"} ,
		{"word":"élevées","word_nosc":"elevees","lemma":"élevé","pos":"ADJ"} ,
		{"word":"élevés","word_nosc":"eleves","lemma":"élevé","pos":"ADJ"} ,
		{"word":"éligible","word_nosc":"eligible","lemma":"éligible","pos":"ADJ"} ,
		{"word":"éligibles","word_nosc":"eligibles","lemma":"éligible","pos":"ADJ"} ,
		{"word":"éliminateur","word_nosc":"eliminateur","lemma":"éliminateur","pos":"ADJ"} ,
		{"word":"éliminateurs","word_nosc":"eliminateurs","lemma":"éliminateur","pos":"ADJ"} ,
		{"word":"éliminatoire","word_nosc":"eliminatoire","lemma":"éliminatoire","pos":"ADJ"} ,
		{"word":"éliminatoires","word_nosc":"eliminatoires","lemma":"éliminatoire","pos":"ADJ"} ,
		{"word":"éliminé","word_nosc":"elimine","lemma":"éliminé","pos":"ADJ"} ,
		{"word":"éliminée","word_nosc":"eliminee","lemma":"éliminé","pos":"ADJ"} ,
		{"word":"éliminées","word_nosc":"eliminees","lemma":"éliminé","pos":"ADJ"} ,
		{"word":"éliminés","word_nosc":"elimines","lemma":"éliminé","pos":"ADJ"} ,
		{"word":"élimé","word_nosc":"elime","lemma":"élimé","pos":"ADJ"} ,
		{"word":"élimée","word_nosc":"elimee","lemma":"élimé","pos":"ADJ"} ,
		{"word":"élimées","word_nosc":"elimees","lemma":"élimé","pos":"ADJ"} ,
		{"word":"élimés","word_nosc":"elimes","lemma":"élimé","pos":"ADJ"} ,
		{"word":"élisabéthain","word_nosc":"elisabethain","lemma":"élisabéthain","pos":"ADJ"} ,
		{"word":"élisabéthaine","word_nosc":"elisabethaine","lemma":"élisabéthain","pos":"ADJ"} ,
		{"word":"élitiste","word_nosc":"elitiste","lemma":"élitiste","pos":"ADJ"} ,
		{"word":"élitistes","word_nosc":"elitistes","lemma":"élitiste","pos":"ADJ"} ,
		{"word":"élogieuse","word_nosc":"elogieuse","lemma":"élogieux","pos":"ADJ"} ,
		{"word":"élogieuses","word_nosc":"elogieuses","lemma":"élogieux","pos":"ADJ"} ,
		{"word":"élogieux","word_nosc":"elogieux","lemma":"élogieux","pos":"ADJ"} ,
		{"word":"éloigné","word_nosc":"eloigne","lemma":"éloigné","pos":"ADJ"} ,
		{"word":"éloignée","word_nosc":"eloignee","lemma":"éloigné","pos":"ADJ"} ,
		{"word":"éloignées","word_nosc":"eloignees","lemma":"éloigné","pos":"ADJ"} ,
		{"word":"éloignés","word_nosc":"eloignes","lemma":"éloigné","pos":"ADJ"} ,
		{"word":"éloquent","word_nosc":"eloquent","lemma":"éloquent","pos":"ADJ"} ,
		{"word":"éloquente","word_nosc":"eloquente","lemma":"éloquent","pos":"ADJ"} ,
		{"word":"éloquentes","word_nosc":"eloquentes","lemma":"éloquent","pos":"ADJ"} ,
		{"word":"éloquents","word_nosc":"eloquents","lemma":"éloquent","pos":"ADJ"} ,
		{"word":"élu","word_nosc":"elu","lemma":"élu","pos":"ADJ"} ,
		{"word":"élue","word_nosc":"elue","lemma":"élu","pos":"ADJ"} ,
		{"word":"élues","word_nosc":"elues","lemma":"élu","pos":"ADJ"} ,
		{"word":"élus","word_nosc":"elus","lemma":"élu","pos":"ADJ"} ,
		{"word":"élusifs","word_nosc":"elusifs","lemma":"élusif","pos":"ADJ"} ,
		{"word":"élusive","word_nosc":"elusive","lemma":"élusif","pos":"ADJ"} ,
		{"word":"élyséen","word_nosc":"elyseen","lemma":"élyséen","pos":"ADJ"} ,
		{"word":"élyséenne","word_nosc":"elyseenne","lemma":"élyséen","pos":"ADJ"} ,
		{"word":"élégant","word_nosc":"elegant","lemma":"élégant","pos":"ADJ"} ,
		{"word":"élégante","word_nosc":"elegante","lemma":"élégant","pos":"ADJ"} ,
		{"word":"élégantes","word_nosc":"elegantes","lemma":"élégant","pos":"ADJ"} ,
		{"word":"élégants","word_nosc":"elegants","lemma":"élégant","pos":"ADJ"} ,
		{"word":"élégiaque","word_nosc":"elegiaque","lemma":"élégiaque","pos":"ADJ"} ,
		{"word":"élégiaques","word_nosc":"elegiaques","lemma":"élégiaque","pos":"ADJ"} ,
		{"word":"élémentaire","word_nosc":"elementaire","lemma":"élémentaire","pos":"ADJ"} ,
		{"word":"élémentaires","word_nosc":"elementaires","lemma":"élémentaire","pos":"ADJ"} ,
		{"word":"éléphantesque","word_nosc":"elephantesque","lemma":"éléphantesque","pos":"ADJ"} ,
		{"word":"éléphantesques","word_nosc":"elephantesques","lemma":"éléphantesque","pos":"ADJ"} ,
		{"word":"éléphantiasique","word_nosc":"elephantiasique","lemma":"éléphantiasique","pos":"ADJ"} ,
		{"word":"éléphantine","word_nosc":"elephantine","lemma":"éléphantin","pos":"ADJ"} ,
		{"word":"éléphantines","word_nosc":"elephantines","lemma":"éléphantin","pos":"ADJ"} ,
		{"word":"élévateur","word_nosc":"elevateur","lemma":"élévateur","pos":"ADJ"} ,
		{"word":"élévateurs","word_nosc":"elevateurs","lemma":"élévateur","pos":"ADJ"} ,
		{"word":"élévatrice","word_nosc":"elevatrice","lemma":"élévateur","pos":"ADJ"} ,
		{"word":"élévatrices","word_nosc":"elevatrices","lemma":"élévateur","pos":"ADJ"} ,
		{"word":"émacié","word_nosc":"emacie","lemma":"émacié","pos":"ADJ"} ,
		{"word":"émaciée","word_nosc":"emaciee","lemma":"émacié","pos":"ADJ"} ,
		{"word":"émaciées","word_nosc":"emaciees","lemma":"émacié","pos":"ADJ"} ,
		{"word":"émaciés","word_nosc":"emacies","lemma":"émacié","pos":"ADJ"} ,
		{"word":"émaillé","word_nosc":"emaille","lemma":"émaillé","pos":"ADJ"} ,
		{"word":"émaillée","word_nosc":"emaillee","lemma":"émaillé","pos":"ADJ"} ,
		{"word":"émaillées","word_nosc":"emaillees","lemma":"émaillé","pos":"ADJ"} ,
		{"word":"émaillés","word_nosc":"emailles","lemma":"émaillé","pos":"ADJ"} ,
		{"word":"émancipateur","word_nosc":"emancipateur","lemma":"émancipateur","pos":"ADJ"} ,
		{"word":"émancipatrice","word_nosc":"emancipatrice","lemma":"émancipateur","pos":"ADJ"} ,
		{"word":"émancipé","word_nosc":"emancipe","lemma":"émancipé","pos":"ADJ"} ,
		{"word":"émancipée","word_nosc":"emancipee","lemma":"émancipé","pos":"ADJ"} ,
		{"word":"émancipées","word_nosc":"emancipees","lemma":"émancipé","pos":"ADJ"} ,
		{"word":"émancipés","word_nosc":"emancipes","lemma":"émancipé","pos":"ADJ"} ,
		{"word":"émeraude","word_nosc":"emeraude","lemma":"émeraude","pos":"ADJ"} ,
		{"word":"émergeant","word_nosc":"emergeant","lemma":"émergeant","pos":"ADJ"} ,
		{"word":"émergent","word_nosc":"emergent","lemma":"émergent","pos":"ADJ"} ,
		{"word":"émergente","word_nosc":"emergente","lemma":"émergent","pos":"ADJ"} ,
		{"word":"émergé","word_nosc":"emerge","lemma":"émergé","pos":"ADJ"} ,
		{"word":"émergée","word_nosc":"emergee","lemma":"émergé","pos":"ADJ"} ,
		{"word":"émergées","word_nosc":"emergees","lemma":"émergé","pos":"ADJ"} ,
		{"word":"émerillonné","word_nosc":"emerillonne","lemma":"émerillonné","pos":"ADJ"} ,
		{"word":"émetteur","word_nosc":"emetteur","lemma":"émetteur","pos":"ADJ"} ,
		{"word":"émetteurs","word_nosc":"emetteurs","lemma":"émetteur","pos":"ADJ"} ,
		{"word":"émettrice","word_nosc":"emettrice","lemma":"émetteur","pos":"ADJ"} ,
		{"word":"émigrante","word_nosc":"emigrante","lemma":"émigrant","pos":"ADJ"} ,
		{"word":"émigré","word_nosc":"emigre","lemma":"émigré","pos":"ADJ"} ,
		{"word":"émigrés","word_nosc":"emigres","lemma":"émigré","pos":"ADJ"} ,
		{"word":"émilienne","word_nosc":"emilienne","lemma":"émilien","pos":"ADJ"} ,
		{"word":"éminent","word_nosc":"eminent","lemma":"éminent","pos":"ADJ"} ,
		{"word":"éminente","word_nosc":"eminente","lemma":"éminent","pos":"ADJ"} ,
		{"word":"éminentes","word_nosc":"eminentes","lemma":"éminent","pos":"ADJ"} ,
		{"word":"éminentissimes","word_nosc":"eminentissimes","lemma":"éminentissime","pos":"ADJ"} ,
		{"word":"éminents","word_nosc":"eminents","lemma":"éminent","pos":"ADJ"} ,
		{"word":"émissaire","word_nosc":"emissaire","lemma":"émissaire","pos":"ADJ"} ,
		{"word":"émissaires","word_nosc":"emissaires","lemma":"émissaire","pos":"ADJ"} ,
		{"word":"émollient","word_nosc":"emollient","lemma":"émollient","pos":"ADJ"} ,
		{"word":"émolliente","word_nosc":"emolliente","lemma":"émollient","pos":"ADJ"} ,
		{"word":"émotif","word_nosc":"emotif","lemma":"émotif","pos":"ADJ"} ,
		{"word":"émotifs","word_nosc":"emotifs","lemma":"émotif","pos":"ADJ"} ,
		{"word":"émotionnante","word_nosc":"emotionnante","lemma":"émotionnant","pos":"ADJ"} ,
		{"word":"émotionnel","word_nosc":"emotionnel","lemma":"émotionnel","pos":"ADJ"} ,
		{"word":"émotionnelle","word_nosc":"emotionnelle","lemma":"émotionnel","pos":"ADJ"} ,
		{"word":"émotionnelles","word_nosc":"emotionnelles","lemma":"émotionnel","pos":"ADJ"} ,
		{"word":"émotionnels","word_nosc":"emotionnels","lemma":"émotionnel","pos":"ADJ"} ,
		{"word":"émotive","word_nosc":"emotive","lemma":"émotif","pos":"ADJ"} ,
		{"word":"émotives","word_nosc":"emotives","lemma":"émotif","pos":"ADJ"} ,
		{"word":"émoulu","word_nosc":"emoulu","lemma":"émoulu","pos":"ADJ"} ,
		{"word":"émoulue","word_nosc":"emoulue","lemma":"émoulu","pos":"ADJ"} ,
		{"word":"émoussé","word_nosc":"emousse","lemma":"émoussé","pos":"ADJ"} ,
		{"word":"émoussée","word_nosc":"emoussee","lemma":"émoussé","pos":"ADJ"} ,
		{"word":"émoussées","word_nosc":"emoussees","lemma":"émoussé","pos":"ADJ"} ,
		{"word":"émoussés","word_nosc":"emousses","lemma":"émoussé","pos":"ADJ"} ,
		{"word":"émoustillant","word_nosc":"emoustillant","lemma":"émoustillant","pos":"ADJ"} ,
		{"word":"émoustillante","word_nosc":"emoustillante","lemma":"émoustillant","pos":"ADJ"} ,
		{"word":"émouvant","word_nosc":"emouvant","lemma":"émouvant","pos":"ADJ"} ,
		{"word":"émouvante","word_nosc":"emouvante","lemma":"émouvant","pos":"ADJ"} ,
		{"word":"émouvantes","word_nosc":"emouvantes","lemma":"émouvant","pos":"ADJ"} ,
		{"word":"émouvants","word_nosc":"emouvants","lemma":"émouvant","pos":"ADJ"} ,
		{"word":"ému","word_nosc":"emu","lemma":"ému","pos":"ADJ"} ,
		{"word":"émue","word_nosc":"emue","lemma":"ému","pos":"ADJ"} ,
		{"word":"émues","word_nosc":"emues","lemma":"ému","pos":"ADJ"} ,
		{"word":"émulsifiant","word_nosc":"emulsifiant","lemma":"émulsifiant","pos":"ADJ"} ,
		{"word":"émulsive","word_nosc":"emulsive","lemma":"émulsif","pos":"ADJ"} ,
		{"word":"émus","word_nosc":"emus","lemma":"ému","pos":"ADJ"} ,
		{"word":"éméché","word_nosc":"emeche","lemma":"éméché","pos":"ADJ"} ,
		{"word":"éméchée","word_nosc":"emechee","lemma":"éméché","pos":"ADJ"} ,
		{"word":"éméchées","word_nosc":"emechees","lemma":"éméché","pos":"ADJ"} ,
		{"word":"éméchés","word_nosc":"emeches","lemma":"éméché","pos":"ADJ"} ,
		{"word":"émérite","word_nosc":"emerite","lemma":"émérite","pos":"ADJ"} ,
		{"word":"émérites","word_nosc":"emerites","lemma":"émérite","pos":"ADJ"} ,
		{"word":"émétique","word_nosc":"emetique","lemma":"émétique","pos":"ADJ"} ,
		{"word":"énamouré","word_nosc":"enamoure","lemma":"énamouré","pos":"ADJ"} ,
		{"word":"énamourée","word_nosc":"enamouree","lemma":"énamouré","pos":"ADJ"} ,
		{"word":"énamourées","word_nosc":"enamourees","lemma":"énamouré","pos":"ADJ"} ,
		{"word":"énamourés","word_nosc":"enamoures","lemma":"énamouré","pos":"ADJ"} ,
		{"word":"énergique","word_nosc":"energique","lemma":"énergique","pos":"ADJ"} ,
		{"word":"énergiques","word_nosc":"energiques","lemma":"énergique","pos":"ADJ"} ,
		{"word":"énergisant","word_nosc":"energisant","lemma":"énergisant","pos":"ADJ"} ,
		{"word":"énergisante","word_nosc":"energisante","lemma":"énergisant","pos":"ADJ"} ,
		{"word":"énergisée","word_nosc":"energisee","lemma":"énergisé","pos":"ADJ"} ,
		{"word":"énergétique","word_nosc":"energetique","lemma":"énergétique","pos":"ADJ"} ,
		{"word":"énergétiques","word_nosc":"energetiques","lemma":"énergétique","pos":"ADJ"} ,
		{"word":"énervant","word_nosc":"enervant","lemma":"énervant","pos":"ADJ"} ,
		{"word":"énervante","word_nosc":"enervante","lemma":"énervant","pos":"ADJ"} ,
		{"word":"énervantes","word_nosc":"enervantes","lemma":"énervant","pos":"ADJ"} ,
		{"word":"énervants","word_nosc":"enervants","lemma":"énervant","pos":"ADJ"} ,
		{"word":"énervé","word_nosc":"enerve","lemma":"énervé","pos":"ADJ"} ,
		{"word":"énervée","word_nosc":"enervee","lemma":"énervé","pos":"ADJ"} ,
		{"word":"énervées","word_nosc":"enervees","lemma":"énervé","pos":"ADJ"} ,
		{"word":"énervés","word_nosc":"enerves","lemma":"énervé","pos":"ADJ"} ,
		{"word":"énigmatique","word_nosc":"enigmatique","lemma":"énigmatique","pos":"ADJ"} ,
		{"word":"énigmatiques","word_nosc":"enigmatiques","lemma":"énigmatique","pos":"ADJ"} ,
		{"word":"énième","word_nosc":"enieme","lemma":"énième","pos":"ADJ"} ,
		{"word":"énorme","word_nosc":"enorme","lemma":"énorme","pos":"ADJ"} ,
		{"word":"énormes","word_nosc":"enormes","lemma":"énorme","pos":"ADJ"} ,
		{"word":"énumérative","word_nosc":"enumerative","lemma":"énumératif","pos":"ADJ"} ,
		{"word":"éolien","word_nosc":"eolien","lemma":"éolien","pos":"ADJ"} ,
		{"word":"éolienne","word_nosc":"eolienne","lemma":"éolien","pos":"ADJ"} ,
		{"word":"éoliennes","word_nosc":"eoliennes","lemma":"éolien","pos":"ADJ"} ,
		{"word":"éoliens","word_nosc":"eoliens","lemma":"éolien","pos":"ADJ"} ,
		{"word":"épagomènes","word_nosc":"epagomenes","lemma":"épagomène","pos":"ADJ"} ,
		{"word":"épais","word_nosc":"epais","lemma":"épais","pos":"ADJ"} ,
		{"word":"épaisse","word_nosc":"epaisse","lemma":"épais","pos":"ADJ"} ,
		{"word":"épaisses","word_nosc":"epaisses","lemma":"épais","pos":"ADJ"} ,
		{"word":"épanoui","word_nosc":"epanoui","lemma":"épanoui","pos":"ADJ"} ,
		{"word":"épanouie","word_nosc":"epanouie","lemma":"épanoui","pos":"ADJ"} ,
		{"word":"épanouies","word_nosc":"epanouies","lemma":"épanoui","pos":"ADJ"} ,
		{"word":"épanouis","word_nosc":"epanouis","lemma":"épanoui","pos":"ADJ"} ,
		{"word":"épanouissant","word_nosc":"epanouissant","lemma":"épanouissant","pos":"ADJ"} ,
		{"word":"épanouissante","word_nosc":"epanouissante","lemma":"épanouissant","pos":"ADJ"} ,
		{"word":"épanouissantes","word_nosc":"epanouissantes","lemma":"épanouissant","pos":"ADJ"} ,
		{"word":"épargnant","word_nosc":"epargnant","lemma":"épargnant","pos":"ADJ"} ,
		{"word":"épargnantes","word_nosc":"epargnantes","lemma":"épargnant","pos":"ADJ"} ,
		{"word":"éparpillé","word_nosc":"eparpille","lemma":"éparpillé","pos":"ADJ"} ,
		{"word":"éparpillée","word_nosc":"eparpillee","lemma":"éparpillé","pos":"ADJ"} ,
		{"word":"éparpillées","word_nosc":"eparpillees","lemma":"éparpillé","pos":"ADJ"} ,
		{"word":"éparpillés","word_nosc":"eparpilles","lemma":"éparpillé","pos":"ADJ"} ,
		{"word":"épars","word_nosc":"epars","lemma":"épars","pos":"ADJ"} ,
		{"word":"éparse","word_nosc":"eparse","lemma":"épars","pos":"ADJ"} ,
		{"word":"éparses","word_nosc":"eparses","lemma":"épars","pos":"ADJ"} ,
		{"word":"épastrouillant","word_nosc":"epastrouillant","lemma":"épastrouillant","pos":"ADJ"} ,
		{"word":"épatant","word_nosc":"epatant","lemma":"épatant","pos":"ADJ"} ,
		{"word":"épatante","word_nosc":"epatante","lemma":"épatant","pos":"ADJ"} ,
		{"word":"épatantes","word_nosc":"epatantes","lemma":"épatant","pos":"ADJ"} ,
		{"word":"épatants","word_nosc":"epatants","lemma":"épatant","pos":"ADJ"} ,
		{"word":"épateur","word_nosc":"epateur","lemma":"épateur","pos":"ADJ"} ,
		{"word":"épateurs","word_nosc":"epateurs","lemma":"épateur","pos":"ADJ"} ,
		{"word":"épaté","word_nosc":"epate","lemma":"épaté","pos":"ADJ"} ,
		{"word":"épatée","word_nosc":"epatee","lemma":"épaté","pos":"ADJ"} ,
		{"word":"épatés","word_nosc":"epates","lemma":"épaté","pos":"ADJ"} ,
		{"word":"épaulé","word_nosc":"epaule","lemma":"épaulé","pos":"ADJ"} ,
		{"word":"épaulés","word_nosc":"epaules","lemma":"épaulé","pos":"ADJ"} ,
		{"word":"éperdu","word_nosc":"eperdu","lemma":"éperdu","pos":"ADJ"} ,
		{"word":"éperdue","word_nosc":"eperdue","lemma":"éperdu","pos":"ADJ"} ,
		{"word":"éperdues","word_nosc":"eperdues","lemma":"éperdu","pos":"ADJ"} ,
		{"word":"éperdus","word_nosc":"eperdus","lemma":"éperdu","pos":"ADJ"} ,
		{"word":"éphémère","word_nosc":"ephemere","lemma":"éphémère","pos":"ADJ"} ,
		{"word":"éphémères","word_nosc":"ephemeres","lemma":"éphémère","pos":"ADJ"} ,
		{"word":"éphésiens","word_nosc":"ephesiens","lemma":"éphésien","pos":"ADJ"} ,
		{"word":"épicrânienne","word_nosc":"epicranienne","lemma":"épicrânien","pos":"ADJ"} ,
		{"word":"épicurien","word_nosc":"epicurien","lemma":"épicurien","pos":"ADJ"} ,
		{"word":"épicurienne","word_nosc":"epicurienne","lemma":"épicurien","pos":"ADJ"} ,
		{"word":"épicuriens","word_nosc":"epicuriens","lemma":"épicurien","pos":"ADJ"} ,
		{"word":"épicé","word_nosc":"epice","lemma":"épicé","pos":"ADJ"} ,
		{"word":"épicée","word_nosc":"epicee","lemma":"épicé","pos":"ADJ"} ,
		{"word":"épicées","word_nosc":"epicees","lemma":"épicé","pos":"ADJ"} ,
		{"word":"épicés","word_nosc":"epices","lemma":"épicé","pos":"ADJ"} ,
		{"word":"épidermique","word_nosc":"epidermique","lemma":"épidermique","pos":"ADJ"} ,
		{"word":"épidermiques","word_nosc":"epidermiques","lemma":"épidermique","pos":"ADJ"} ,
		{"word":"épidural","word_nosc":"epidural","lemma":"épidural","pos":"ADJ"} ,
		{"word":"épidurale","word_nosc":"epidurale","lemma":"épidural","pos":"ADJ"} ,
		{"word":"épidémiologique","word_nosc":"epidemiologique","lemma":"épidémiologique","pos":"ADJ"} ,
		{"word":"épidémique","word_nosc":"epidemique","lemma":"épidémique","pos":"ADJ"} ,
		{"word":"épidémiques","word_nosc":"epidemiques","lemma":"épidémique","pos":"ADJ"} ,
		{"word":"épigastrique","word_nosc":"epigastrique","lemma":"épigastrique","pos":"ADJ"} ,
		{"word":"épilatoire","word_nosc":"epilatoire","lemma":"épilatoire","pos":"ADJ"} ,
		{"word":"épileptiforme","word_nosc":"epileptiforme","lemma":"épileptiforme","pos":"ADJ"} ,
		{"word":"épileptique","word_nosc":"epileptique","lemma":"épileptique","pos":"ADJ"} ,
		{"word":"épileptiques","word_nosc":"epileptiques","lemma":"épileptique","pos":"ADJ"} ,
		{"word":"épineuse","word_nosc":"epineuse","lemma":"épineux","pos":"ADJ"} ,
		{"word":"épineuses","word_nosc":"epineuses","lemma":"épineux","pos":"ADJ"} ,
		{"word":"épineux","word_nosc":"epineux","lemma":"épineux","pos":"ADJ"} ,
		{"word":"épinglé","word_nosc":"epingle","lemma":"épinglé","pos":"ADJ"} ,
		{"word":"épinglées","word_nosc":"epinglees","lemma":"épinglé","pos":"ADJ"} ,
		{"word":"épinglés","word_nosc":"epingles","lemma":"épinglé","pos":"ADJ"} ,
		{"word":"épinière","word_nosc":"epiniere","lemma":"épinier","pos":"ADJ"} ,
		{"word":"épiphane","word_nosc":"epiphane","lemma":"épiphane","pos":"ADJ"} ,
		{"word":"épiphysaire","word_nosc":"epiphysaire","lemma":"épiphysaire","pos":"ADJ"} ,
		{"word":"épique","word_nosc":"epique","lemma":"épique","pos":"ADJ"} ,
		{"word":"épiques","word_nosc":"epiques","lemma":"épique","pos":"ADJ"} ,
		{"word":"épiscopal","word_nosc":"episcopal","lemma":"épiscopal","pos":"ADJ"} ,
		{"word":"épiscopale","word_nosc":"episcopale","lemma":"épiscopal","pos":"ADJ"} ,
		{"word":"épiscopales","word_nosc":"episcopales","lemma":"épiscopal","pos":"ADJ"} ,
		{"word":"épiscopalien","word_nosc":"episcopalien","lemma":"épiscopalien","pos":"ADJ"} ,
		{"word":"épiscopalienne","word_nosc":"episcopalienne","lemma":"épiscopalien","pos":"ADJ"} ,
		{"word":"épiscopaux","word_nosc":"episcopaux","lemma":"épiscopal","pos":"ADJ"} ,
		{"word":"épisodique","word_nosc":"episodique","lemma":"épisodique","pos":"ADJ"} ,
		{"word":"épisodiques","word_nosc":"episodiques","lemma":"épisodique","pos":"ADJ"} ,
		{"word":"épistolaire","word_nosc":"epistolaire","lemma":"épistolaire","pos":"ADJ"} ,
		{"word":"épistolaires","word_nosc":"epistolaires","lemma":"épistolaire","pos":"ADJ"} ,
		{"word":"épistémologique","word_nosc":"epistemologique","lemma":"épistémologique","pos":"ADJ"} ,
		{"word":"épithète","word_nosc":"epithete","lemma":"épithète","pos":"ADJ"} ,
		{"word":"épithélial","word_nosc":"epithelial","lemma":"épithélial","pos":"ADJ"} ,
		{"word":"épithéliale","word_nosc":"epitheliale","lemma":"épithélial","pos":"ADJ"} ,
		{"word":"éploré","word_nosc":"eplore","lemma":"éploré","pos":"ADJ"} ,
		{"word":"éplorée","word_nosc":"eploree","lemma":"éploré","pos":"ADJ"} ,
		{"word":"éplorées","word_nosc":"eplorees","lemma":"éploré","pos":"ADJ"} ,
		{"word":"éplorés","word_nosc":"eplores","lemma":"éploré","pos":"ADJ"} ,
		{"word":"éponyme","word_nosc":"eponyme","lemma":"éponyme","pos":"ADJ"} ,
		{"word":"épousable","word_nosc":"epousable","lemma":"épousable","pos":"ADJ"} ,
		{"word":"époustouflant","word_nosc":"epoustouflant","lemma":"époustouflant","pos":"ADJ"} ,
		{"word":"époustouflante","word_nosc":"epoustouflante","lemma":"époustouflant","pos":"ADJ"} ,
		{"word":"époustouflantes","word_nosc":"epoustouflantes","lemma":"époustouflant","pos":"ADJ"} ,
		{"word":"époustouflants","word_nosc":"epoustouflants","lemma":"époustouflant","pos":"ADJ"} ,
		{"word":"épouvantable","word_nosc":"epouvantable","lemma":"épouvantable","pos":"ADJ"} ,
		{"word":"épouvantables","word_nosc":"epouvantables","lemma":"épouvantable","pos":"ADJ"} ,
		{"word":"épouvanté","word_nosc":"epouvante","lemma":"épouvanté","pos":"ADJ"} ,
		{"word":"épouvantée","word_nosc":"epouvantee","lemma":"épouvanté","pos":"ADJ"} ,
		{"word":"épouvantées","word_nosc":"epouvantees","lemma":"épouvanté","pos":"ADJ"} ,
		{"word":"épouvantés","word_nosc":"epouvantes","lemma":"épouvanté","pos":"ADJ"} ,
		{"word":"époxy","word_nosc":"epoxy","lemma":"époxy","pos":"ADJ"} ,
		{"word":"épris","word_nosc":"epris","lemma":"épris","pos":"ADJ"} ,
		{"word":"éprise","word_nosc":"eprise","lemma":"épris","pos":"ADJ"} ,
		{"word":"éprises","word_nosc":"eprises","lemma":"épris","pos":"ADJ"} ,
		{"word":"éprouvant","word_nosc":"eprouvant","lemma":"éprouvant","pos":"ADJ"} ,
		{"word":"éprouvante","word_nosc":"eprouvante","lemma":"éprouvant","pos":"ADJ"} ,
		{"word":"éprouvantes","word_nosc":"eprouvantes","lemma":"éprouvant","pos":"ADJ"} ,
		{"word":"éprouvants","word_nosc":"eprouvants","lemma":"éprouvant","pos":"ADJ"} ,
		{"word":"éprouvé","word_nosc":"eprouve","lemma":"éprouvé","pos":"ADJ"} ,
		{"word":"éprouvée","word_nosc":"eprouvee","lemma":"éprouvé","pos":"ADJ"} ,
		{"word":"éprouvées","word_nosc":"eprouvees","lemma":"éprouvé","pos":"ADJ"} ,
		{"word":"éprouvés","word_nosc":"eprouves","lemma":"éprouvé","pos":"ADJ"} ,
		{"word":"épuisant","word_nosc":"epuisant","lemma":"épuisant","pos":"ADJ"} ,
		{"word":"épuisante","word_nosc":"epuisante","lemma":"épuisant","pos":"ADJ"} ,
		{"word":"épuisantes","word_nosc":"epuisantes","lemma":"épuisant","pos":"ADJ"} ,
		{"word":"épuisants","word_nosc":"epuisants","lemma":"épuisant","pos":"ADJ"} ,
		{"word":"épuisé","word_nosc":"epuise","lemma":"épuisé","pos":"ADJ"} ,
		{"word":"épuisée","word_nosc":"epuisee","lemma":"épuisé","pos":"ADJ"} ,
		{"word":"épuisées","word_nosc":"epuisees","lemma":"épuisé","pos":"ADJ"} ,
		{"word":"épuisés","word_nosc":"epuises","lemma":"épuisé","pos":"ADJ"} ,
		{"word":"épurative","word_nosc":"epurative","lemma":"épuratif","pos":"ADJ"} ,
		{"word":"équarri","word_nosc":"equarri","lemma":"équarri","pos":"ADJ"} ,
		{"word":"équarrie","word_nosc":"equarrie","lemma":"équarri","pos":"ADJ"} ,
		{"word":"équarries","word_nosc":"equarries","lemma":"équarri","pos":"ADJ"} ,
		{"word":"équarris","word_nosc":"equarris","lemma":"équarri","pos":"ADJ"} ,
		{"word":"équatorial","word_nosc":"equatorial","lemma":"équatorial","pos":"ADJ"} ,
		{"word":"équatoriale","word_nosc":"equatoriale","lemma":"équatorial","pos":"ADJ"} ,
		{"word":"équatoriaux","word_nosc":"equatoriaux","lemma":"équatorial","pos":"ADJ"} ,
		{"word":"équatoriennes","word_nosc":"equatoriennes","lemma":"équatorien","pos":"ADJ"} ,
		{"word":"équestre","word_nosc":"equestre","lemma":"équestre","pos":"ADJ"} ,
		{"word":"équestres","word_nosc":"equestres","lemma":"équestre","pos":"ADJ"} ,
		{"word":"équeutées","word_nosc":"equeutees","lemma":"équeuté","pos":"ADJ"} ,
		{"word":"équidistant","word_nosc":"equidistant","lemma":"équidistant","pos":"ADJ"} ,
		{"word":"équidistante","word_nosc":"equidistante","lemma":"équidistant","pos":"ADJ"} ,
		{"word":"équidistantes","word_nosc":"equidistantes","lemma":"équidistant","pos":"ADJ"} ,
		{"word":"équidistants","word_nosc":"equidistants","lemma":"équidistant","pos":"ADJ"} ,
		{"word":"équilatéral","word_nosc":"equilateral","lemma":"équilatéral","pos":"ADJ"} ,
		{"word":"équilatéraux","word_nosc":"equilateraux","lemma":"équilatéral","pos":"ADJ"} ,
		{"word":"équilibrante","word_nosc":"equilibrante","lemma":"équilibrant","pos":"ADJ"} ,
		{"word":"équilibré","word_nosc":"equilibre","lemma":"équilibré","pos":"ADJ"} ,
		{"word":"équilibrée","word_nosc":"equilibree","lemma":"équilibré","pos":"ADJ"} ,
		{"word":"équilibrées","word_nosc":"equilibrees","lemma":"équilibré","pos":"ADJ"} ,
		{"word":"équilibrés","word_nosc":"equilibres","lemma":"équilibré","pos":"ADJ"} ,
		{"word":"équin","word_nosc":"equin","lemma":"équin","pos":"ADJ"} ,
		{"word":"équine","word_nosc":"equine","lemma":"équin","pos":"ADJ"} ,
		{"word":"équipé","word_nosc":"equipe","lemma":"équipé","pos":"ADJ"} ,
		{"word":"équipés","word_nosc":"equipes","lemma":"équipé","pos":"ADJ"} ,
		{"word":"équitable","word_nosc":"equitable","lemma":"équitable","pos":"ADJ"} ,
		{"word":"équitables","word_nosc":"equitables","lemma":"équitable","pos":"ADJ"} ,
		{"word":"équivalent","word_nosc":"equivalent","lemma":"équivalent","pos":"ADJ"} ,
		{"word":"équivalente","word_nosc":"equivalente","lemma":"équivalent","pos":"ADJ"} ,
		{"word":"équivalentes","word_nosc":"equivalentes","lemma":"équivalent","pos":"ADJ"} ,
		{"word":"équivalents","word_nosc":"equivalents","lemma":"équivalent","pos":"ADJ"} ,
		{"word":"équivoque","word_nosc":"equivoque","lemma":"équivoque","pos":"ADJ"} ,
		{"word":"équivoques","word_nosc":"equivoques","lemma":"équivoque","pos":"ADJ"} ,
		{"word":"éradicateur","word_nosc":"eradicateur","lemma":"éradicateur","pos":"ADJ"} ,
		{"word":"éraillé","word_nosc":"eraille","lemma":"éraillé","pos":"ADJ"} ,
		{"word":"éraillée","word_nosc":"eraillee","lemma":"éraillé","pos":"ADJ"} ,
		{"word":"éraillées","word_nosc":"eraillees","lemma":"éraillé","pos":"ADJ"} ,
		{"word":"éraillés","word_nosc":"erailles","lemma":"éraillé","pos":"ADJ"} ,
		{"word":"érectile","word_nosc":"erectile","lemma":"érectile","pos":"ADJ"} ,
		{"word":"érectiles","word_nosc":"erectiles","lemma":"érectile","pos":"ADJ"} ,
		{"word":"éreintant","word_nosc":"ereintant","lemma":"éreintant","pos":"ADJ"} ,
		{"word":"éreintante","word_nosc":"ereintante","lemma":"éreintant","pos":"ADJ"} ,
		{"word":"éreintants","word_nosc":"ereintants","lemma":"éreintant","pos":"ADJ"} ,
		{"word":"éreinté","word_nosc":"ereinte","lemma":"éreinté","pos":"ADJ"} ,
		{"word":"éreintée","word_nosc":"ereintee","lemma":"éreinté","pos":"ADJ"} ,
		{"word":"éreintées","word_nosc":"ereintees","lemma":"éreinté","pos":"ADJ"} ,
		{"word":"éreintés","word_nosc":"ereintes","lemma":"éreinté","pos":"ADJ"} ,
		{"word":"érodé","word_nosc":"erode","lemma":"érodé","pos":"ADJ"} ,
		{"word":"érodée","word_nosc":"erodee","lemma":"érodé","pos":"ADJ"} ,
		{"word":"érodées","word_nosc":"erodees","lemma":"érodé","pos":"ADJ"} ,
		{"word":"érodés","word_nosc":"erodes","lemma":"érodé","pos":"ADJ"} ,
		{"word":"érogène","word_nosc":"erogene","lemma":"érogène","pos":"ADJ"} ,
		{"word":"érogènes","word_nosc":"erogenes","lemma":"érogène","pos":"ADJ"} ,
		{"word":"érotique","word_nosc":"erotique","lemma":"érotique","pos":"ADJ"} ,
		{"word":"érotiquement","word_nosc":"erotiquement","lemma":"érotiquement","pos":"ADJ"} ,
		{"word":"érotiques","word_nosc":"erotiques","lemma":"érotique","pos":"ADJ"} ,
		{"word":"érotomane","word_nosc":"erotomane","lemma":"érotomane","pos":"ADJ"} ,
		{"word":"érudit","word_nosc":"erudit","lemma":"érudit","pos":"ADJ"} ,
		{"word":"érudite","word_nosc":"erudite","lemma":"érudit","pos":"ADJ"} ,
		{"word":"érudites","word_nosc":"erudites","lemma":"érudit","pos":"ADJ"} ,
		{"word":"érudits","word_nosc":"erudits","lemma":"érudit","pos":"ADJ"} ,
		{"word":"éruptive","word_nosc":"eruptive","lemma":"éruptif","pos":"ADJ"} ,
		{"word":"éruptives","word_nosc":"eruptives","lemma":"éruptif","pos":"ADJ"} ,
		{"word":"érythémateux","word_nosc":"erythemateux","lemma":"érythémateux","pos":"ADJ"} ,
		{"word":"ésotérique","word_nosc":"esoterique","lemma":"ésotérique","pos":"ADJ"} ,
		{"word":"ésotériques","word_nosc":"esoteriques","lemma":"ésotérique","pos":"ADJ"} ,
		{"word":"établi","word_nosc":"etabli","lemma":"établi","pos":"ADJ"} ,
		{"word":"établie","word_nosc":"etablie","lemma":"établi","pos":"ADJ"} ,
		{"word":"établies","word_nosc":"etablies","lemma":"établi","pos":"ADJ"} ,
		{"word":"établis","word_nosc":"etablis","lemma":"établi","pos":"ADJ"} ,
		{"word":"étagé","word_nosc":"etage","lemma":"étagé","pos":"ADJ"} ,
		{"word":"étagée","word_nosc":"etagee","lemma":"étagé","pos":"ADJ"} ,
		{"word":"étagées","word_nosc":"etagees","lemma":"étagé","pos":"ADJ"} ,
		{"word":"étagés","word_nosc":"etages","lemma":"étagé","pos":"ADJ"} ,
		{"word":"étale","word_nosc":"etale","lemma":"étale","pos":"ADJ"} ,
		{"word":"étales","word_nosc":"etales","lemma":"étale","pos":"ADJ"} ,
		{"word":"étalonnés","word_nosc":"etalonnes","lemma":"étalonné","pos":"ADJ"} ,
		{"word":"étamé","word_nosc":"etame","lemma":"étamé","pos":"ADJ"} ,
		{"word":"étanche","word_nosc":"etanche","lemma":"étanche","pos":"ADJ"} ,
		{"word":"étanches","word_nosc":"etanches","lemma":"étanche","pos":"ADJ"} ,
		{"word":"étatique","word_nosc":"etatique","lemma":"étatique","pos":"ADJ"} ,
		{"word":"étatiques","word_nosc":"etatiques","lemma":"étatique","pos":"ADJ"} ,
		{"word":"éteint","word_nosc":"eteint","lemma":"éteint","pos":"ADJ"} ,
		{"word":"éteinte","word_nosc":"eteinte","lemma":"éteint","pos":"ADJ"} ,
		{"word":"éteintes","word_nosc":"eteintes","lemma":"éteint","pos":"ADJ"} ,
		{"word":"éteints","word_nosc":"eteints","lemma":"éteint","pos":"ADJ"} ,
		{"word":"étendu","word_nosc":"etendu","lemma":"étendu","pos":"ADJ"} ,
		{"word":"étendus","word_nosc":"etendus","lemma":"étendu","pos":"ADJ"} ,
		{"word":"éternel","word_nosc":"eternel","lemma":"éternel","pos":"ADJ"} ,
		{"word":"éternelle","word_nosc":"eternelle","lemma":"éternel","pos":"ADJ"} ,
		{"word":"éternelles","word_nosc":"eternelles","lemma":"éternel","pos":"ADJ"} ,
		{"word":"éternels","word_nosc":"eternels","lemma":"éternel","pos":"ADJ"} ,
		{"word":"éthiopien","word_nosc":"ethiopien","lemma":"éthiopien","pos":"ADJ"} ,
		{"word":"éthiopienne","word_nosc":"ethiopienne","lemma":"éthiopien","pos":"ADJ"} ,
		{"word":"éthiopiennes","word_nosc":"ethiopiennes","lemma":"éthiopien","pos":"ADJ"} ,
		{"word":"éthiopiens","word_nosc":"ethiopiens","lemma":"éthiopien","pos":"ADJ"} ,
		{"word":"éthique","word_nosc":"ethique","lemma":"éthique","pos":"ADJ"} ,
		{"word":"éthiques","word_nosc":"ethiques","lemma":"éthique","pos":"ADJ"} ,
		{"word":"éthylique","word_nosc":"ethylique","lemma":"éthylique","pos":"ADJ"} ,
		{"word":"éthyliques","word_nosc":"ethyliques","lemma":"éthylique","pos":"ADJ"} ,
		{"word":"éthérique","word_nosc":"etherique","lemma":"éthérique","pos":"ADJ"} ,
		{"word":"éthéré","word_nosc":"ethere","lemma":"éthéré","pos":"ADJ"} ,
		{"word":"éthérée","word_nosc":"etheree","lemma":"éthéré","pos":"ADJ"} ,
		{"word":"éthérées","word_nosc":"etherees","lemma":"éthéré","pos":"ADJ"} ,
		{"word":"éthérés","word_nosc":"etheres","lemma":"éthéré","pos":"ADJ"} ,
		{"word":"étincelant","word_nosc":"etincelant","lemma":"étincelant","pos":"ADJ"} ,
		{"word":"étincelante","word_nosc":"etincelante","lemma":"étincelant","pos":"ADJ"} ,
		{"word":"étincelantes","word_nosc":"etincelantes","lemma":"étincelant","pos":"ADJ"} ,
		{"word":"étincelants","word_nosc":"etincelants","lemma":"étincelant","pos":"ADJ"} ,
		{"word":"étiologique","word_nosc":"etiologique","lemma":"étiologique","pos":"ADJ"} ,
		{"word":"étiologiques","word_nosc":"etiologiques","lemma":"étiologique","pos":"ADJ"} ,
		{"word":"étique","word_nosc":"etique","lemma":"étique","pos":"ADJ"} ,
		{"word":"étiques","word_nosc":"etiques","lemma":"étique","pos":"ADJ"} ,
		{"word":"étirable","word_nosc":"etirable","lemma":"étirable","pos":"ADJ"} ,
		{"word":"étoffé","word_nosc":"etoffe","lemma":"étoffé","pos":"ADJ"} ,
		{"word":"étoffée","word_nosc":"etoffee","lemma":"étoffé","pos":"ADJ"} ,
		{"word":"étoffées","word_nosc":"etoffees","lemma":"étoffé","pos":"ADJ"} ,
		{"word":"étoffés","word_nosc":"etoffes","lemma":"étoffé","pos":"ADJ"} ,
		{"word":"étoilé","word_nosc":"etoile","lemma":"étoilé","pos":"ADJ"} ,
		{"word":"étoilée","word_nosc":"etoilee","lemma":"étoilé","pos":"ADJ"} ,
		{"word":"étoilées","word_nosc":"etoilees","lemma":"étoilé","pos":"ADJ"} ,
		{"word":"étoilés","word_nosc":"etoiles","lemma":"étoilé","pos":"ADJ"} ,
		{"word":"étonnant","word_nosc":"etonnant","lemma":"étonnant","pos":"ADJ"} ,
		{"word":"étonnante","word_nosc":"etonnante","lemma":"étonnant","pos":"ADJ"} ,
		{"word":"étonnantes","word_nosc":"etonnantes","lemma":"étonnant","pos":"ADJ"} ,
		{"word":"étonnants","word_nosc":"etonnants","lemma":"étonnant","pos":"ADJ"} ,
		{"word":"étonné","word_nosc":"etonne","lemma":"étonné","pos":"ADJ"} ,
		{"word":"étonnée","word_nosc":"etonnee","lemma":"étonné","pos":"ADJ"} ,
		{"word":"étonnées","word_nosc":"etonnees","lemma":"étonné","pos":"ADJ"} ,
		{"word":"étonnés","word_nosc":"etonnes","lemma":"étonné","pos":"ADJ"} ,
		{"word":"étouffant","word_nosc":"etouffant","lemma":"étouffant","pos":"ADJ"} ,
		{"word":"étouffante","word_nosc":"etouffante","lemma":"étouffant","pos":"ADJ"} ,
		{"word":"étouffantes","word_nosc":"etouffantes","lemma":"étouffant","pos":"ADJ"} ,
		{"word":"étouffants","word_nosc":"etouffants","lemma":"étouffant","pos":"ADJ"} ,
		{"word":"étouffé","word_nosc":"etouffe","lemma":"étouffé","pos":"ADJ"} ,
		{"word":"étouffés","word_nosc":"etouffes","lemma":"étouffé","pos":"ADJ"} ,
		{"word":"étourdi","word_nosc":"etourdi","lemma":"étourdi","pos":"ADJ"} ,
		{"word":"étourdie","word_nosc":"etourdie","lemma":"étourdi","pos":"ADJ"} ,
		{"word":"étourdies","word_nosc":"etourdies","lemma":"étourdi","pos":"ADJ"} ,
		{"word":"étourdis","word_nosc":"etourdis","lemma":"étourdi","pos":"ADJ"} ,
		{"word":"étourdissant","word_nosc":"etourdissant","lemma":"étourdissant","pos":"ADJ"} ,
		{"word":"étourdissante","word_nosc":"etourdissante","lemma":"étourdissant","pos":"ADJ"} ,
		{"word":"étourdissantes","word_nosc":"etourdissantes","lemma":"étourdissant","pos":"ADJ"} ,
		{"word":"étourdissants","word_nosc":"etourdissants","lemma":"étourdissant","pos":"ADJ"} ,
		{"word":"étrange","word_nosc":"etrange","lemma":"étrange","pos":"ADJ"} ,
		{"word":"étranger","word_nosc":"etranger","lemma":"étranger","pos":"ADJ"} ,
		{"word":"étrangers","word_nosc":"etrangers","lemma":"étranger","pos":"ADJ"} ,
		{"word":"étranges","word_nosc":"etranges","lemma":"étrange","pos":"ADJ"} ,
		{"word":"étrangleur","word_nosc":"etrangleur","lemma":"étrangleur","pos":"ADJ"} ,
		{"word":"étrangleurs","word_nosc":"etrangleurs","lemma":"étrangleur","pos":"ADJ"} ,
		{"word":"étrangleuse","word_nosc":"etrangleuse","lemma":"étrangleur","pos":"ADJ"} ,
		{"word":"étrangleuses","word_nosc":"etrangleuses","lemma":"étrangleur","pos":"ADJ"} ,
		{"word":"étranglé","word_nosc":"etrangle","lemma":"étranglé","pos":"ADJ"} ,
		{"word":"étranglée","word_nosc":"etranglee","lemma":"étranglé","pos":"ADJ"} ,
		{"word":"étranglées","word_nosc":"etranglees","lemma":"étranglé","pos":"ADJ"} ,
		{"word":"étranglés","word_nosc":"etrangles","lemma":"étranglé","pos":"ADJ"} ,
		{"word":"étrangère","word_nosc":"etrangere","lemma":"étranger","pos":"ADJ"} ,
		{"word":"étrangères","word_nosc":"etrangeres","lemma":"étranger","pos":"ADJ"} ,
		{"word":"étriqué","word_nosc":"etrique","lemma":"étriqué","pos":"ADJ"} ,
		{"word":"étriquée","word_nosc":"etriquee","lemma":"étriqué","pos":"ADJ"} ,
		{"word":"étriquées","word_nosc":"etriquees","lemma":"étriqué","pos":"ADJ"} ,
		{"word":"étriqués","word_nosc":"etriques","lemma":"étriqué","pos":"ADJ"} ,
		{"word":"étroit","word_nosc":"etroit","lemma":"étroit","pos":"ADJ"} ,
		{"word":"étroite","word_nosc":"etroite","lemma":"étroit","pos":"ADJ"} ,
		{"word":"étroites","word_nosc":"etroites","lemma":"étroit","pos":"ADJ"} ,
		{"word":"étroits","word_nosc":"etroits","lemma":"étroit","pos":"ADJ"} ,
		{"word":"étrusque","word_nosc":"etrusque","lemma":"étrusque","pos":"ADJ"} ,
		{"word":"étrusques","word_nosc":"etrusques","lemma":"étrusque","pos":"ADJ"} ,
		{"word":"étudiant","word_nosc":"etudiant","lemma":"étudiant","pos":"ADJ"} ,
		{"word":"étudiante","word_nosc":"etudiante","lemma":"étudiant","pos":"ADJ"} ,
		{"word":"étudiantes","word_nosc":"etudiantes","lemma":"étudiant","pos":"ADJ"} ,
		{"word":"étudiants","word_nosc":"etudiants","lemma":"étudiant","pos":"ADJ"} ,
		{"word":"étudié","word_nosc":"etudie","lemma":"étudié","pos":"ADJ"} ,
		{"word":"étudiée","word_nosc":"etudiee","lemma":"étudié","pos":"ADJ"} ,
		{"word":"étudiées","word_nosc":"etudiees","lemma":"étudié","pos":"ADJ"} ,
		{"word":"étudiés","word_nosc":"etudies","lemma":"étudié","pos":"ADJ"} ,
		{"word":"étymologique","word_nosc":"etymologique","lemma":"étymologique","pos":"ADJ"} ,
		{"word":"étymologiques","word_nosc":"etymologiques","lemma":"étymologique","pos":"ADJ"} ,
		{"word":"évacué","word_nosc":"evacue","lemma":"évacué","pos":"ADJ"} ,
		{"word":"évacuée","word_nosc":"evacuee","lemma":"évacué","pos":"ADJ"} ,
		{"word":"évacuées","word_nosc":"evacuees","lemma":"évacué","pos":"ADJ"} ,
		{"word":"évacués","word_nosc":"evacues","lemma":"évacué","pos":"ADJ"} ,
		{"word":"évadé","word_nosc":"evade","lemma":"évadé","pos":"ADJ"} ,
		{"word":"évadée","word_nosc":"evadee","lemma":"évadé","pos":"ADJ"} ,
		{"word":"évadés","word_nosc":"evades","lemma":"évadé","pos":"ADJ"} ,
		{"word":"évaluables","word_nosc":"evaluables","lemma":"évaluable","pos":"ADJ"} ,
		{"word":"évanescent","word_nosc":"evanescent","lemma":"évanescent","pos":"ADJ"} ,
		{"word":"évanescente","word_nosc":"evanescente","lemma":"évanescent","pos":"ADJ"} ,
		{"word":"évanescentes","word_nosc":"evanescentes","lemma":"évanescent","pos":"ADJ"} ,
		{"word":"évanescents","word_nosc":"evanescents","lemma":"évanescent","pos":"ADJ"} ,
		{"word":"évangélique","word_nosc":"evangelique","lemma":"évangélique","pos":"ADJ"} ,
		{"word":"évangéliques","word_nosc":"evangeliques","lemma":"évangélique","pos":"ADJ"} ,
		{"word":"évangélisateur","word_nosc":"evangelisateur","lemma":"évangélisateur","pos":"ADJ"} ,
		{"word":"évangélisatrices","word_nosc":"evangelisatrices","lemma":"évangélisateur","pos":"ADJ"} ,
		{"word":"évanoui","word_nosc":"evanoui","lemma":"évanoui","pos":"ADJ"} ,
		{"word":"évanouie","word_nosc":"evanouie","lemma":"évanoui","pos":"ADJ"} ,
		{"word":"évanouies","word_nosc":"evanouies","lemma":"évanoui","pos":"ADJ"} ,
		{"word":"évanouis","word_nosc":"evanouis","lemma":"évanoui","pos":"ADJ"} ,
		{"word":"évaporé","word_nosc":"evapore","lemma":"évaporé","pos":"ADJ"} ,
		{"word":"évaporée","word_nosc":"evaporee","lemma":"évaporé","pos":"ADJ"} ,
		{"word":"évaporées","word_nosc":"evaporees","lemma":"évaporé","pos":"ADJ"} ,
		{"word":"évaporés","word_nosc":"evapores","lemma":"évaporé","pos":"ADJ"} ,
		{"word":"évasif","word_nosc":"evasif","lemma":"évasif","pos":"ADJ"} ,
		{"word":"évasifs","word_nosc":"evasifs","lemma":"évasif","pos":"ADJ"} ,
		{"word":"évasive","word_nosc":"evasive","lemma":"évasif","pos":"ADJ"} ,
		{"word":"évasives","word_nosc":"evasives","lemma":"évasif","pos":"ADJ"} ,
		{"word":"évasé","word_nosc":"evase","lemma":"évasé","pos":"ADJ"} ,
		{"word":"évasée","word_nosc":"evasee","lemma":"évasé","pos":"ADJ"} ,
		{"word":"évasées","word_nosc":"evasees","lemma":"évasé","pos":"ADJ"} ,
		{"word":"évasés","word_nosc":"evases","lemma":"évasé","pos":"ADJ"} ,
		{"word":"éveillant","word_nosc":"eveillant","lemma":"éveillant","pos":"ADJ"} ,
		{"word":"éveillé","word_nosc":"eveille","lemma":"éveillé","pos":"ADJ"} ,
		{"word":"éveillée","word_nosc":"eveillee","lemma":"éveillé","pos":"ADJ"} ,
		{"word":"éveillées","word_nosc":"eveillees","lemma":"éveillé","pos":"ADJ"} ,
		{"word":"éveillés","word_nosc":"eveilles","lemma":"éveillé","pos":"ADJ"} ,
		{"word":"éventré","word_nosc":"eventre","lemma":"éventré","pos":"ADJ"} ,
		{"word":"éventrée","word_nosc":"eventree","lemma":"éventré","pos":"ADJ"} ,
		{"word":"éventrées","word_nosc":"eventrees","lemma":"éventré","pos":"ADJ"} ,
		{"word":"éventrés","word_nosc":"eventres","lemma":"éventré","pos":"ADJ"} ,
		{"word":"éventuel","word_nosc":"eventuel","lemma":"éventuel","pos":"ADJ"} ,
		{"word":"éventuelle","word_nosc":"eventuelle","lemma":"éventuel","pos":"ADJ"} ,
		{"word":"éventuelles","word_nosc":"eventuelles","lemma":"éventuel","pos":"ADJ"} ,
		{"word":"éventuels","word_nosc":"eventuels","lemma":"éventuel","pos":"ADJ"} ,
		{"word":"éventé","word_nosc":"evente","lemma":"éventé","pos":"ADJ"} ,
		{"word":"éventée","word_nosc":"eventee","lemma":"éventé","pos":"ADJ"} ,
		{"word":"éventées","word_nosc":"eventees","lemma":"éventé","pos":"ADJ"} ,
		{"word":"éventés","word_nosc":"eventes","lemma":"éventé","pos":"ADJ"} ,
		{"word":"évergète","word_nosc":"evergete","lemma":"évergète","pos":"ADJ"} ,
		{"word":"éversé","word_nosc":"everse","lemma":"éversé","pos":"ADJ"} ,
		{"word":"éversées","word_nosc":"eversees","lemma":"éversé","pos":"ADJ"} ,
		{"word":"évident","word_nosc":"evident","lemma":"évident","pos":"ADJ"} ,
		{"word":"évidente","word_nosc":"evidente","lemma":"évident","pos":"ADJ"} ,
		{"word":"évidentes","word_nosc":"evidentes","lemma":"évident","pos":"ADJ"} ,
		{"word":"évidents","word_nosc":"evidents","lemma":"évident","pos":"ADJ"} ,
		{"word":"évidé","word_nosc":"evide","lemma":"évidé","pos":"ADJ"} ,
		{"word":"évidée","word_nosc":"evidee","lemma":"évidé","pos":"ADJ"} ,
		{"word":"évitable","word_nosc":"evitable","lemma":"évitable","pos":"ADJ"} ,
		{"word":"évitables","word_nosc":"evitables","lemma":"évitable","pos":"ADJ"} ,
		{"word":"évocateur","word_nosc":"evocateur","lemma":"évocateur","pos":"ADJ"} ,
		{"word":"évocateurs","word_nosc":"evocateurs","lemma":"évocateur","pos":"ADJ"} ,
		{"word":"évocatrice","word_nosc":"evocatrice","lemma":"évocateur","pos":"ADJ"} ,
		{"word":"évocatrices","word_nosc":"evocatrices","lemma":"évocateur","pos":"ADJ"} ,
		{"word":"évolutif","word_nosc":"evolutif","lemma":"évolutif","pos":"ADJ"} ,
		{"word":"évolutionniste","word_nosc":"evolutionniste","lemma":"évolutionniste","pos":"ADJ"} ,
		{"word":"évolutive","word_nosc":"evolutive","lemma":"évolutif","pos":"ADJ"} ,
		{"word":"évolutives","word_nosc":"evolutives","lemma":"évolutif","pos":"ADJ"} ,
		{"word":"évolué","word_nosc":"evolue","lemma":"évolué","pos":"ADJ"} ,
		{"word":"évoluée","word_nosc":"evoluee","lemma":"évolué","pos":"ADJ"} ,
		{"word":"évoluées","word_nosc":"evoluees","lemma":"évolué","pos":"ADJ"} ,
		{"word":"évolués","word_nosc":"evolues","lemma":"évolué","pos":"ADJ"} ,
		{"word":"évènementielle","word_nosc":"evenementielle","lemma":"évènementiel","pos":"ADJ"} ,
		{"word":"événementiel","word_nosc":"evenementiel","lemma":"événementiel","pos":"ADJ"} ,
		{"word":"éwé","word_nosc":"ewe","lemma":"éwé","pos":"ADJ"} ,
		{"word":"éwés","word_nosc":"ewes","lemma":"éwé","pos":"ADJ"} ,
		{"word":"ôté","word_nosc":"ote","lemma":"ôté","pos":"ADJ"} ,
		{"word":"ôtée","word_nosc":"otee","lemma":"ôté","pos":"ADJ"} ,
		{"word":"ôtées","word_nosc":"otees","lemma":"ôté","pos":"ADJ"} ,
		{"word":"ôtés","word_nosc":"otes","lemma":"ôté","pos":"ADJ"}
	]
}