'use strict';

module.exports = {
    lexi: [
		{"word":"a","word_nosc":"a","lemma":"a","pos":"NOM"} ,
		{"word":"a priori","word_nosc":"a priori","lemma":"a priori","pos":"NOM"} ,
		{"word":"aa","word_nosc":"aa","lemma":"aa","pos":"NOM"} ,
		{"word":"abaca","word_nosc":"abaca","lemma":"abaca","pos":"NOM"} ,
		{"word":"abaisse","word_nosc":"abaisse","lemma":"abaisse","pos":"NOM"} ,
		{"word":"abaisse-langue","word_nosc":"abaisse-langue","lemma":"abaisse-langue","pos":"NOM"} ,
		{"word":"abaissement","word_nosc":"abaissement","lemma":"abaissement","pos":"NOM"} ,
		{"word":"abalone","word_nosc":"abalone","lemma":"abalone","pos":"NOM"} ,
		{"word":"abalones","word_nosc":"abalones","lemma":"abalone","pos":"NOM"} ,
		{"word":"abandon","word_nosc":"abandon","lemma":"abandon","pos":"NOM"} ,
		{"word":"abandonneurs","word_nosc":"abandonneurs","lemma":"abandonneur","pos":"NOM"} ,
		{"word":"abandons","word_nosc":"abandons","lemma":"abandon","pos":"NOM"} ,
		{"word":"abaque","word_nosc":"abaque","lemma":"abaque","pos":"NOM"} ,
		{"word":"abat","word_nosc":"abat","lemma":"abat","pos":"NOM"} ,
		{"word":"abat-jour","word_nosc":"abat-jour","lemma":"abat-jour","pos":"NOM"} ,
		{"word":"abat-son","word_nosc":"abat-son","lemma":"abat-son","pos":"NOM"} ,
		{"word":"abatage","word_nosc":"abatage","lemma":"abatage","pos":"NOM"} ,
		{"word":"abatis","word_nosc":"abatis","lemma":"abatis","pos":"NOM"} ,
		{"word":"abats","word_nosc":"abats","lemma":"abat","pos":"NOM"} ,
		{"word":"abattage","word_nosc":"abattage","lemma":"abattage","pos":"NOM"} ,
		{"word":"abattages","word_nosc":"abattages","lemma":"abattage","pos":"NOM"} ,
		{"word":"abattant","word_nosc":"abattant","lemma":"abattant","pos":"NOM"} ,
		{"word":"abattants","word_nosc":"abattants","lemma":"abattant","pos":"NOM"} ,
		{"word":"abattement","word_nosc":"abattement","lemma":"abattement","pos":"NOM"} ,
		{"word":"abattements","word_nosc":"abattements","lemma":"abattement","pos":"NOM"} ,
		{"word":"abatteur","word_nosc":"abatteur","lemma":"abatteur","pos":"NOM"} ,
		{"word":"abatteurs","word_nosc":"abatteurs","lemma":"abatteur","pos":"NOM"} ,
		{"word":"abattis","word_nosc":"abattis","lemma":"abattis","pos":"NOM"} ,
		{"word":"abattoir","word_nosc":"abattoir","lemma":"abattoir","pos":"NOM"} ,
		{"word":"abattoirs","word_nosc":"abattoirs","lemma":"abattoir","pos":"NOM"} ,
		{"word":"abattu","word_nosc":"abattu","lemma":"abattu","pos":"NOM"} ,
		{"word":"abattures","word_nosc":"abattures","lemma":"abatture","pos":"NOM"} ,
		{"word":"abattus","word_nosc":"abattus","lemma":"abattu","pos":"NOM"} ,
		{"word":"abbatiale","word_nosc":"abbatiale","lemma":"abbatiale","pos":"NOM"} ,
		{"word":"abbaye","word_nosc":"abbaye","lemma":"abbaye","pos":"NOM"} ,
		{"word":"abbayes","word_nosc":"abbayes","lemma":"abbaye","pos":"NOM"} ,
		{"word":"abbesse","word_nosc":"abbesse","lemma":"abbé","pos":"NOM"} ,
		{"word":"abbesses","word_nosc":"abbesses","lemma":"abbé","pos":"NOM"} ,
		{"word":"abbé","word_nosc":"abbe","lemma":"abbé","pos":"NOM"} ,
		{"word":"abbés","word_nosc":"abbes","lemma":"abbé","pos":"NOM"} ,
		{"word":"abc","word_nosc":"abc","lemma":"abc","pos":"NOM"} ,
		{"word":"abcès","word_nosc":"abces","lemma":"abcès","pos":"NOM"} ,
		{"word":"abdication","word_nosc":"abdication","lemma":"abdication","pos":"NOM"} ,
		{"word":"abdications","word_nosc":"abdications","lemma":"abdication","pos":"NOM"} ,
		{"word":"abdomen","word_nosc":"abdomen","lemma":"abdomen","pos":"NOM"} ,
		{"word":"abdominaux","word_nosc":"abdominaux","lemma":"abdominal","pos":"NOM"} ,
		{"word":"abdos","word_nosc":"abdos","lemma":"abdos","pos":"NOM"} ,
		{"word":"abduction","word_nosc":"abduction","lemma":"abduction","pos":"NOM"} ,
		{"word":"abeille","word_nosc":"abeille","lemma":"abeille","pos":"NOM"} ,
		{"word":"abeilles","word_nosc":"abeilles","lemma":"abeille","pos":"NOM"} ,
		{"word":"aber","word_nosc":"aber","lemma":"aber","pos":"NOM"} ,
		{"word":"aberration","word_nosc":"aberration","lemma":"aberration","pos":"NOM"} ,
		{"word":"aberrations","word_nosc":"aberrations","lemma":"aberration","pos":"NOM"} ,
		{"word":"abjection","word_nosc":"abjection","lemma":"abjection","pos":"NOM"} ,
		{"word":"abjections","word_nosc":"abjections","lemma":"abjection","pos":"NOM"} ,
		{"word":"abjuration","word_nosc":"abjuration","lemma":"abjuration","pos":"NOM"} ,
		{"word":"abjurations","word_nosc":"abjurations","lemma":"abjuration","pos":"NOM"} ,
		{"word":"ablatif","word_nosc":"ablatif","lemma":"ablatif","pos":"NOM"} ,
		{"word":"ablation","word_nosc":"ablation","lemma":"ablation","pos":"NOM"} ,
		{"word":"ablations","word_nosc":"ablations","lemma":"ablation","pos":"NOM"} ,
		{"word":"able","word_nosc":"able","lemma":"able","pos":"NOM"} ,
		{"word":"ablette","word_nosc":"ablette","lemma":"ablette","pos":"NOM"} ,
		{"word":"ablettes","word_nosc":"ablettes","lemma":"ablette","pos":"NOM"} ,
		{"word":"ablution","word_nosc":"ablution","lemma":"ablution","pos":"NOM"} ,
		{"word":"ablutions","word_nosc":"ablutions","lemma":"ablution","pos":"NOM"} ,
		{"word":"abnégation","word_nosc":"abnegation","lemma":"abnégation","pos":"NOM"} ,
		{"word":"aboi","word_nosc":"aboi","lemma":"aboi","pos":"NOM"} ,
		{"word":"aboiement","word_nosc":"aboiement","lemma":"aboiement","pos":"NOM"} ,
		{"word":"aboiements","word_nosc":"aboiements","lemma":"aboiement","pos":"NOM"} ,
		{"word":"abois","word_nosc":"abois","lemma":"aboi","pos":"NOM"} ,
		{"word":"abolition","word_nosc":"abolition","lemma":"abolition","pos":"NOM"} ,
		{"word":"abolitionniste","word_nosc":"abolitionniste","lemma":"abolitionniste","pos":"NOM"} ,
		{"word":"abolitionnistes","word_nosc":"abolitionnistes","lemma":"abolitionniste","pos":"NOM"} ,
		{"word":"abomination","word_nosc":"abomination","lemma":"abomination","pos":"NOM"} ,
		{"word":"abominations","word_nosc":"abominations","lemma":"abomination","pos":"NOM"} ,
		{"word":"abondance","word_nosc":"abondance","lemma":"abondance","pos":"NOM"} ,
		{"word":"abonnement","word_nosc":"abonnement","lemma":"abonnement","pos":"NOM"} ,
		{"word":"abonnements","word_nosc":"abonnements","lemma":"abonnement","pos":"NOM"} ,
		{"word":"abonné","word_nosc":"abonne","lemma":"abonné","pos":"NOM"} ,
		{"word":"abonnée","word_nosc":"abonnee","lemma":"abonné","pos":"NOM"} ,
		{"word":"abonnées","word_nosc":"abonnees","lemma":"abonné","pos":"NOM"} ,
		{"word":"abonnés","word_nosc":"abonnes","lemma":"abonné","pos":"NOM"} ,
		{"word":"abord","word_nosc":"abord","lemma":"abord","pos":"NOM"} ,
		{"word":"abordage","word_nosc":"abordage","lemma":"abordage","pos":"NOM"} ,
		{"word":"abordages","word_nosc":"abordages","lemma":"abordage","pos":"NOM"} ,
		{"word":"abords","word_nosc":"abords","lemma":"abord","pos":"NOM"} ,
		{"word":"aborigène","word_nosc":"aborigene","lemma":"aborigène","pos":"NOM"} ,
		{"word":"aborigènes","word_nosc":"aborigenes","lemma":"aborigène","pos":"NOM"} ,
		{"word":"abornement","word_nosc":"abornement","lemma":"abornement","pos":"NOM"} ,
		{"word":"abouchements","word_nosc":"abouchements","lemma":"abouchement","pos":"NOM"} ,
		{"word":"aboulie","word_nosc":"aboulie","lemma":"aboulie","pos":"NOM"} ,
		{"word":"aboulique","word_nosc":"aboulique","lemma":"aboulique","pos":"NOM"} ,
		{"word":"abounas","word_nosc":"abounas","lemma":"abouna","pos":"NOM"} ,
		{"word":"about","word_nosc":"about","lemma":"about","pos":"NOM"} ,
		{"word":"aboutissant","word_nosc":"aboutissant","lemma":"aboutissant","pos":"NOM"} ,
		{"word":"aboutissants","word_nosc":"aboutissants","lemma":"aboutissant","pos":"NOM"} ,
		{"word":"aboutissement","word_nosc":"aboutissement","lemma":"aboutissement","pos":"NOM"} ,
		{"word":"aboyeur","word_nosc":"aboyeur","lemma":"aboyeur","pos":"NOM"} ,
		{"word":"aboyeurs","word_nosc":"aboyeurs","lemma":"aboyeur","pos":"NOM"} ,
		{"word":"abracadabra","word_nosc":"abracadabra","lemma":"abracadabra","pos":"NOM"} ,
		{"word":"abrasif","word_nosc":"abrasif","lemma":"abrasif","pos":"NOM"} ,
		{"word":"abrasifs","word_nosc":"abrasifs","lemma":"abrasif","pos":"NOM"} ,
		{"word":"abrasion","word_nosc":"abrasion","lemma":"abrasion","pos":"NOM"} ,
		{"word":"abraxas","word_nosc":"abraxas","lemma":"abraxas","pos":"NOM"} ,
		{"word":"abreuvage","word_nosc":"abreuvage","lemma":"abreuvage","pos":"NOM"} ,
		{"word":"abreuvoir","word_nosc":"abreuvoir","lemma":"abreuvoir","pos":"NOM"} ,
		{"word":"abreuvoirs","word_nosc":"abreuvoirs","lemma":"abreuvoir","pos":"NOM"} ,
		{"word":"abri","word_nosc":"abri","lemma":"abri","pos":"NOM"} ,
		{"word":"abri-refuge","word_nosc":"abri-refuge","lemma":"abri-refuge","pos":"NOM"} ,
		{"word":"abribus","word_nosc":"abribus","lemma":"abribus","pos":"NOM"} ,
		{"word":"abricot","word_nosc":"abricot","lemma":"abricot","pos":"NOM"} ,
		{"word":"abricotier","word_nosc":"abricotier","lemma":"abricotier","pos":"NOM"} ,
		{"word":"abricotiers","word_nosc":"abricotiers","lemma":"abricotier","pos":"NOM"} ,
		{"word":"abricotine","word_nosc":"abricotine","lemma":"abricotine","pos":"NOM"} ,
		{"word":"abricots","word_nosc":"abricots","lemma":"abricot","pos":"NOM"} ,
		{"word":"abris","word_nosc":"abris","lemma":"abri","pos":"NOM"} ,
		{"word":"abrogation","word_nosc":"abrogation","lemma":"abrogation","pos":"NOM"} ,
		{"word":"abrupt","word_nosc":"abrupt","lemma":"abrupt","pos":"NOM"} ,
		{"word":"abrupts","word_nosc":"abrupts","lemma":"abrupt","pos":"NOM"} ,
		{"word":"abruti","word_nosc":"abruti","lemma":"abruti","pos":"NOM"} ,
		{"word":"abrutie","word_nosc":"abrutie","lemma":"abruti","pos":"NOM"} ,
		{"word":"abruties","word_nosc":"abruties","lemma":"abruti","pos":"NOM"} ,
		{"word":"abrutis","word_nosc":"abrutis","lemma":"abruti","pos":"NOM"} ,
		{"word":"abrutissement","word_nosc":"abrutissement","lemma":"abrutissement","pos":"NOM"} ,
		{"word":"abrutisseur","word_nosc":"abrutisseur","lemma":"abrutisseur","pos":"NOM"} ,
		{"word":"abrégé","word_nosc":"abrege","lemma":"abrégé","pos":"NOM"} ,
		{"word":"abrégés","word_nosc":"abreges","lemma":"abrégé","pos":"NOM"} ,
		{"word":"abréviation","word_nosc":"abreviation","lemma":"abréviation","pos":"NOM"} ,
		{"word":"abréviations","word_nosc":"abreviations","lemma":"abréviation","pos":"NOM"} ,
		{"word":"abscisse","word_nosc":"abscisse","lemma":"abscisse","pos":"NOM"} ,
		{"word":"abscission","word_nosc":"abscission","lemma":"abscission","pos":"NOM"} ,
		{"word":"absence","word_nosc":"absence","lemma":"absence","pos":"NOM"} ,
		{"word":"absences","word_nosc":"absences","lemma":"absence","pos":"NOM"} ,
		{"word":"absent","word_nosc":"absent","lemma":"absent","pos":"NOM"} ,
		{"word":"absente","word_nosc":"absente","lemma":"absent","pos":"NOM"} ,
		{"word":"absentes","word_nosc":"absentes","lemma":"absent","pos":"NOM"} ,
		{"word":"absents","word_nosc":"absents","lemma":"absent","pos":"NOM"} ,
		{"word":"absentéisme","word_nosc":"absenteisme","lemma":"absentéisme","pos":"NOM"} ,
		{"word":"absentéiste","word_nosc":"absenteiste","lemma":"absentéiste","pos":"NOM"} ,
		{"word":"abside","word_nosc":"abside","lemma":"abside","pos":"NOM"} ,
		{"word":"absides","word_nosc":"absides","lemma":"abside","pos":"NOM"} ,
		{"word":"absidiole","word_nosc":"absidiole","lemma":"absidiole","pos":"NOM"} ,
		{"word":"absinthe","word_nosc":"absinthe","lemma":"absinthe","pos":"NOM"} ,
		{"word":"absolu","word_nosc":"absolu","lemma":"absolu","pos":"NOM"} ,
		{"word":"absolus","word_nosc":"absolus","lemma":"absolu","pos":"NOM"} ,
		{"word":"absolution","word_nosc":"absolution","lemma":"absolution","pos":"NOM"} ,
		{"word":"absolutisme","word_nosc":"absolutisme","lemma":"absolutisme","pos":"NOM"} ,
		{"word":"absorbant","word_nosc":"absorbant","lemma":"absorbant","pos":"NOM"} ,
		{"word":"absorbeur","word_nosc":"absorbeur","lemma":"absorbeur","pos":"NOM"} ,
		{"word":"absorption","word_nosc":"absorption","lemma":"absorption","pos":"NOM"} ,
		{"word":"absorptions","word_nosc":"absorptions","lemma":"absorption","pos":"NOM"} ,
		{"word":"absoute","word_nosc":"absoute","lemma":"absoute","pos":"NOM"} ,
		{"word":"abstention","word_nosc":"abstention","lemma":"abstention","pos":"NOM"} ,
		{"word":"abstentionniste","word_nosc":"abstentionniste","lemma":"abstentionniste","pos":"NOM"} ,
		{"word":"abstentionnistes","word_nosc":"abstentionnistes","lemma":"abstentionniste","pos":"NOM"} ,
		{"word":"abstinence","word_nosc":"abstinence","lemma":"abstinence","pos":"NOM"} ,
		{"word":"abstinences","word_nosc":"abstinences","lemma":"abstinence","pos":"NOM"} ,
		{"word":"abstraction","word_nosc":"abstraction","lemma":"abstraction","pos":"NOM"} ,
		{"word":"abstractions","word_nosc":"abstractions","lemma":"abstraction","pos":"NOM"} ,
		{"word":"abstrait","word_nosc":"abstrait","lemma":"abstrait","pos":"NOM"} ,
		{"word":"abstraits","word_nosc":"abstraits","lemma":"abstrait","pos":"NOM"} ,
		{"word":"absurde","word_nosc":"absurde","lemma":"absurde","pos":"NOM"} ,
		{"word":"absurdes","word_nosc":"absurdes","lemma":"absurde","pos":"NOM"} ,
		{"word":"absurdistes","word_nosc":"absurdistes","lemma":"absurdiste","pos":"NOM"} ,
		{"word":"absurdité","word_nosc":"absurdite","lemma":"absurdité","pos":"NOM"} ,
		{"word":"absurdités","word_nosc":"absurdites","lemma":"absurdité","pos":"NOM"} ,
		{"word":"abus","word_nosc":"abus","lemma":"abus","pos":"NOM"} ,
		{"word":"abuseur","word_nosc":"abuseur","lemma":"abuseur","pos":"NOM"} ,
		{"word":"abyme","word_nosc":"abyme","lemma":"abyme","pos":"NOM"} ,
		{"word":"abysse","word_nosc":"abysse","lemma":"abysse","pos":"NOM"} ,
		{"word":"abysses","word_nosc":"abysses","lemma":"abysse","pos":"NOM"} ,
		{"word":"abyssin","word_nosc":"abyssin","lemma":"abyssin","pos":"NOM"} ,
		{"word":"abyssinienne","word_nosc":"abyssinienne","lemma":"abyssinien","pos":"NOM"} ,
		{"word":"abécédaire","word_nosc":"abecedaire","lemma":"abécédaire","pos":"NOM"} ,
		{"word":"abêtissement","word_nosc":"abetissement","lemma":"abêtissement","pos":"NOM"} ,
		{"word":"abîme","word_nosc":"abime","lemma":"abîme","pos":"NOM"} ,
		{"word":"abîmes","word_nosc":"abimes","lemma":"abîme","pos":"NOM"} ,
		{"word":"acabit","word_nosc":"acabit","lemma":"acabit","pos":"NOM"} ,
		{"word":"acabits","word_nosc":"acabits","lemma":"acabit","pos":"NOM"} ,
		{"word":"acacia","word_nosc":"acacia","lemma":"acacia","pos":"NOM"} ,
		{"word":"acacias","word_nosc":"acacias","lemma":"acacia","pos":"NOM"} ,
		{"word":"acadien","word_nosc":"acadien","lemma":"acadien","pos":"NOM"} ,
		{"word":"acadienne","word_nosc":"acadienne","lemma":"acadienne","pos":"NOM"} ,
		{"word":"acadiens","word_nosc":"acadiens","lemma":"acadien","pos":"NOM"} ,
		{"word":"académicien","word_nosc":"academicien","lemma":"académicien","pos":"NOM"} ,
		{"word":"académiciens","word_nosc":"academiciens","lemma":"académicien","pos":"NOM"} ,
		{"word":"académie","word_nosc":"academie","lemma":"académie","pos":"NOM"} ,
		{"word":"académies","word_nosc":"academies","lemma":"académie","pos":"NOM"} ,
		{"word":"académisme","word_nosc":"academisme","lemma":"académisme","pos":"NOM"} ,
		{"word":"acajou","word_nosc":"acajou","lemma":"acajou","pos":"NOM"} ,
		{"word":"acajous","word_nosc":"acajous","lemma":"acajou","pos":"NOM"} ,
		{"word":"acanthe","word_nosc":"acanthe","lemma":"acanthe","pos":"NOM"} ,
		{"word":"acanthes","word_nosc":"acanthes","lemma":"acanthe","pos":"NOM"} ,
		{"word":"acariens","word_nosc":"acariens","lemma":"acarien","pos":"NOM"} ,
		{"word":"accablement","word_nosc":"accablement","lemma":"accablement","pos":"NOM"} ,
		{"word":"accablements","word_nosc":"accablements","lemma":"accablement","pos":"NOM"} ,
		{"word":"accalmie","word_nosc":"accalmie","lemma":"accalmie","pos":"NOM"} ,
		{"word":"accalmies","word_nosc":"accalmies","lemma":"accalmie","pos":"NOM"} ,
		{"word":"accastillage","word_nosc":"accastillage","lemma":"accastillage","pos":"NOM"} ,
		{"word":"accastillages","word_nosc":"accastillages","lemma":"accastillage","pos":"NOM"} ,
		{"word":"accent","word_nosc":"accent","lemma":"accent","pos":"NOM"} ,
		{"word":"accenteur","word_nosc":"accenteur","lemma":"accenteur","pos":"NOM"} ,
		{"word":"accents","word_nosc":"accents","lemma":"accent","pos":"NOM"} ,
		{"word":"accentuation","word_nosc":"accentuation","lemma":"accentuation","pos":"NOM"} ,
		{"word":"acceptant","word_nosc":"acceptant","lemma":"acceptant","pos":"NOM"} ,
		{"word":"acceptation","word_nosc":"acceptation","lemma":"acceptation","pos":"NOM"} ,
		{"word":"acceptations","word_nosc":"acceptations","lemma":"acceptation","pos":"NOM"} ,
		{"word":"acception","word_nosc":"acception","lemma":"acception","pos":"NOM"} ,
		{"word":"acceptions","word_nosc":"acceptions","lemma":"acception","pos":"NOM"} ,
		{"word":"accesseurs","word_nosc":"accesseurs","lemma":"accesseur","pos":"NOM"} ,
		{"word":"accessibilité","word_nosc":"accessibilite","lemma":"accessibilité","pos":"NOM"} ,
		{"word":"accession","word_nosc":"accession","lemma":"accession","pos":"NOM"} ,
		{"word":"accessit","word_nosc":"accessit","lemma":"accessit","pos":"NOM"} ,
		{"word":"accessoire","word_nosc":"accessoire","lemma":"accessoire","pos":"NOM"} ,
		{"word":"accessoires","word_nosc":"accessoires","lemma":"accessoire","pos":"NOM"} ,
		{"word":"accessoiriste","word_nosc":"accessoiriste","lemma":"accessoiriste","pos":"NOM"} ,
		{"word":"accessoiristes","word_nosc":"accessoiristes","lemma":"accessoiriste","pos":"NOM"} ,
		{"word":"accident","word_nosc":"accident","lemma":"accident","pos":"NOM"} ,
		{"word":"accidents","word_nosc":"accidents","lemma":"accident","pos":"NOM"} ,
		{"word":"accidenté","word_nosc":"accidente","lemma":"accidenté","pos":"NOM"} ,
		{"word":"accidentée","word_nosc":"accidentee","lemma":"accidenté","pos":"NOM"} ,
		{"word":"accidentés","word_nosc":"accidentes","lemma":"accidenté","pos":"NOM"} ,
		{"word":"acclamation","word_nosc":"acclamation","lemma":"acclamation","pos":"NOM"} ,
		{"word":"acclamations","word_nosc":"acclamations","lemma":"acclamation","pos":"NOM"} ,
		{"word":"acclimatation","word_nosc":"acclimatation","lemma":"acclimatation","pos":"NOM"} ,
		{"word":"acclimatement","word_nosc":"acclimatement","lemma":"acclimatement","pos":"NOM"} ,
		{"word":"accointance","word_nosc":"accointance","lemma":"accointance","pos":"NOM"} ,
		{"word":"accointances","word_nosc":"accointances","lemma":"accointance","pos":"NOM"} ,
		{"word":"accolade","word_nosc":"accolade","lemma":"accolade","pos":"NOM"} ,
		{"word":"accolades","word_nosc":"accolades","lemma":"accolade","pos":"NOM"} ,
		{"word":"accolement","word_nosc":"accolement","lemma":"accolement","pos":"NOM"} ,
		{"word":"accommodation","word_nosc":"accommodation","lemma":"accommodation","pos":"NOM"} ,
		{"word":"accommodement","word_nosc":"accommodement","lemma":"accommodement","pos":"NOM"} ,
		{"word":"accommodements","word_nosc":"accommodements","lemma":"accommodement","pos":"NOM"} ,
		{"word":"accompagnateur","word_nosc":"accompagnateur","lemma":"accompagnateur","pos":"NOM"} ,
		{"word":"accompagnateurs","word_nosc":"accompagnateurs","lemma":"accompagnateur","pos":"NOM"} ,
		{"word":"accompagnatrice","word_nosc":"accompagnatrice","lemma":"accompagnateur","pos":"NOM"} ,
		{"word":"accompagnatrices","word_nosc":"accompagnatrices","lemma":"accompagnateur","pos":"NOM"} ,
		{"word":"accompagnement","word_nosc":"accompagnement","lemma":"accompagnement","pos":"NOM"} ,
		{"word":"accompagnements","word_nosc":"accompagnements","lemma":"accompagnement","pos":"NOM"} ,
		{"word":"accompli","word_nosc":"accompli","lemma":"accompli","pos":"NOM"} ,
		{"word":"accomplissement","word_nosc":"accomplissement","lemma":"accomplissement","pos":"NOM"} ,
		{"word":"accomplissements","word_nosc":"accomplissements","lemma":"accomplissement","pos":"NOM"} ,
		{"word":"accord","word_nosc":"accord","lemma":"accord","pos":"NOM"} ,
		{"word":"accordailles","word_nosc":"accordailles","lemma":"accordailles","pos":"NOM"} ,
		{"word":"accordement","word_nosc":"accordement","lemma":"accordement","pos":"NOM"} ,
		{"word":"accordeur","word_nosc":"accordeur","lemma":"accordeur","pos":"NOM"} ,
		{"word":"accordo","word_nosc":"accordo","lemma":"accordo","pos":"NOM"} ,
		{"word":"accords","word_nosc":"accords","lemma":"accord","pos":"NOM"} ,
		{"word":"accordé","word_nosc":"accorde","lemma":"accordé","pos":"NOM"} ,
		{"word":"accordée","word_nosc":"accordee","lemma":"accordé","pos":"NOM"} ,
		{"word":"accordées","word_nosc":"accordees","lemma":"accordé","pos":"NOM"} ,
		{"word":"accordéon","word_nosc":"accordeon","lemma":"accordéon","pos":"NOM"} ,
		{"word":"accordéoniste","word_nosc":"accordeoniste","lemma":"accordéoniste","pos":"NOM"} ,
		{"word":"accordéonistes","word_nosc":"accordeonistes","lemma":"accordéoniste","pos":"NOM"} ,
		{"word":"accordéons","word_nosc":"accordeons","lemma":"accordéon","pos":"NOM"} ,
		{"word":"accordés","word_nosc":"accordes","lemma":"accordé","pos":"NOM"} ,
		{"word":"accores","word_nosc":"accores","lemma":"accore","pos":"NOM"} ,
		{"word":"accostage","word_nosc":"accostage","lemma":"accostage","pos":"NOM"} ,
		{"word":"accotement","word_nosc":"accotement","lemma":"accotement","pos":"NOM"} ,
		{"word":"accotements","word_nosc":"accotements","lemma":"accotement","pos":"NOM"} ,
		{"word":"accotoirs","word_nosc":"accotoirs","lemma":"accotoir","pos":"NOM"} ,
		{"word":"accotés","word_nosc":"accotes","lemma":"accoté","pos":"NOM"} ,
		{"word":"accouchement","word_nosc":"accouchement","lemma":"accouchement","pos":"NOM"} ,
		{"word":"accouchements","word_nosc":"accouchements","lemma":"accouchement","pos":"NOM"} ,
		{"word":"accoucheur","word_nosc":"accoucheur","lemma":"accoucheur","pos":"NOM"} ,
		{"word":"accoucheurs","word_nosc":"accoucheurs","lemma":"accoucheur","pos":"NOM"} ,
		{"word":"accoucheuse","word_nosc":"accoucheuse","lemma":"accoucheur","pos":"NOM"} ,
		{"word":"accouchée","word_nosc":"accouchee","lemma":"accouchée","pos":"NOM"} ,
		{"word":"accouchées","word_nosc":"accouchees","lemma":"accouchée","pos":"NOM"} ,
		{"word":"accoudoir","word_nosc":"accoudoir","lemma":"accoudoir","pos":"NOM"} ,
		{"word":"accoudoirs","word_nosc":"accoudoirs","lemma":"accoudoir","pos":"NOM"} ,
		{"word":"accouplement","word_nosc":"accouplement","lemma":"accouplement","pos":"NOM"} ,
		{"word":"accouplements","word_nosc":"accouplements","lemma":"accouplement","pos":"NOM"} ,
		{"word":"accoutrement","word_nosc":"accoutrement","lemma":"accoutrement","pos":"NOM"} ,
		{"word":"accoutrements","word_nosc":"accoutrements","lemma":"accoutrement","pos":"NOM"} ,
		{"word":"accoutumance","word_nosc":"accoutumance","lemma":"accoutumance","pos":"NOM"} ,
		{"word":"accoutumances","word_nosc":"accoutumances","lemma":"accoutumance","pos":"NOM"} ,
		{"word":"accras","word_nosc":"accras","lemma":"accra","pos":"NOM"} ,
		{"word":"accro","word_nosc":"accro","lemma":"accro","pos":"NOM"} ,
		{"word":"accroc","word_nosc":"accroc","lemma":"accroc","pos":"NOM"} ,
		{"word":"accrochage","word_nosc":"accrochage","lemma":"accrochage","pos":"NOM"} ,
		{"word":"accrochages","word_nosc":"accrochages","lemma":"accrochage","pos":"NOM"} ,
		{"word":"accroche","word_nosc":"accroche","lemma":"accroche","pos":"NOM"} ,
		{"word":"accroche-coeur","word_nosc":"accroche-coeur","lemma":"accroche-coeur","pos":"NOM"} ,
		{"word":"accroche-coeurs","word_nosc":"accroche-coeurs","lemma":"accroche-coeur","pos":"NOM"} ,
		{"word":"accroches","word_nosc":"accroches","lemma":"accroche","pos":"NOM"} ,
		{"word":"accrocs","word_nosc":"accrocs","lemma":"accroc","pos":"NOM"} ,
		{"word":"accroissement","word_nosc":"accroissement","lemma":"accroissement","pos":"NOM"} ,
		{"word":"accros","word_nosc":"accros","lemma":"accro","pos":"NOM"} ,
		{"word":"accroupissement","word_nosc":"accroupissement","lemma":"accroupissement","pos":"NOM"} ,
		{"word":"accrue","word_nosc":"accrue","lemma":"accrue","pos":"NOM"} ,
		{"word":"accréditation","word_nosc":"accreditation","lemma":"accréditation","pos":"NOM"} ,
		{"word":"accréditations","word_nosc":"accreditations","lemma":"accréditation","pos":"NOM"} ,
		{"word":"accréditif","word_nosc":"accreditif","lemma":"accréditif","pos":"NOM"} ,
		{"word":"accrédités","word_nosc":"accredites","lemma":"accrédité","pos":"NOM"} ,
		{"word":"accrétion","word_nosc":"accretion","lemma":"accrétion","pos":"NOM"} ,
		{"word":"accu","word_nosc":"accu","lemma":"accu","pos":"NOM"} ,
		{"word":"accueil","word_nosc":"accueil","lemma":"accueil","pos":"NOM"} ,
		{"word":"accueils","word_nosc":"accueils","lemma":"accueil","pos":"NOM"} ,
		{"word":"acculturation","word_nosc":"acculturation","lemma":"acculturation","pos":"NOM"} ,
		{"word":"accumulateur","word_nosc":"accumulateur","lemma":"accumulateur","pos":"NOM"} ,
		{"word":"accumulateurs","word_nosc":"accumulateurs","lemma":"accumulateur","pos":"NOM"} ,
		{"word":"accumulation","word_nosc":"accumulation","lemma":"accumulation","pos":"NOM"} ,
		{"word":"accumulations","word_nosc":"accumulations","lemma":"accumulation","pos":"NOM"} ,
		{"word":"accus","word_nosc":"accus","lemma":"accu","pos":"NOM"} ,
		{"word":"accusateur","word_nosc":"accusateur","lemma":"accusateur","pos":"NOM"} ,
		{"word":"accusateurs","word_nosc":"accusateurs","lemma":"accusateur","pos":"NOM"} ,
		{"word":"accusatif","word_nosc":"accusatif","lemma":"accusatif","pos":"NOM"} ,
		{"word":"accusation","word_nosc":"accusation","lemma":"accusation","pos":"NOM"} ,
		{"word":"accusations","word_nosc":"accusations","lemma":"accusation","pos":"NOM"} ,
		{"word":"accusatrices","word_nosc":"accusatrices","lemma":"accusateur","pos":"NOM"} ,
		{"word":"accusé","word_nosc":"accuse","lemma":"accusé","pos":"NOM"} ,
		{"word":"accusée","word_nosc":"accusee","lemma":"accusé","pos":"NOM"} ,
		{"word":"accusées","word_nosc":"accusees","lemma":"accusé","pos":"NOM"} ,
		{"word":"accusés","word_nosc":"accuses","lemma":"accusé","pos":"NOM"} ,
		{"word":"accès","word_nosc":"acces","lemma":"accès","pos":"NOM"} ,
		{"word":"accélérateur","word_nosc":"accelerateur","lemma":"accélérateur","pos":"NOM"} ,
		{"word":"accélérateurs","word_nosc":"accelerateurs","lemma":"accélérateur","pos":"NOM"} ,
		{"word":"accélération","word_nosc":"acceleration","lemma":"accélération","pos":"NOM"} ,
		{"word":"accélérations","word_nosc":"accelerations","lemma":"accélération","pos":"NOM"} ,
		{"word":"accéléré","word_nosc":"accelere","lemma":"accéléré","pos":"NOM"} ,
		{"word":"ace","word_nosc":"ace","lemma":"ace","pos":"NOM"} ,
		{"word":"acerbité","word_nosc":"acerbite","lemma":"acerbité","pos":"NOM"} ,
		{"word":"acetabulum","word_nosc":"acetabulum","lemma":"acetabulum","pos":"NOM"} ,
		{"word":"achalandage","word_nosc":"achalandage","lemma":"achalandage","pos":"NOM"} ,
		{"word":"achards","word_nosc":"achards","lemma":"achards","pos":"NOM"} ,
		{"word":"acharnement","word_nosc":"acharnement","lemma":"acharnement","pos":"NOM"} ,
		{"word":"acharnements","word_nosc":"acharnements","lemma":"acharnement","pos":"NOM"} ,
		{"word":"achat","word_nosc":"achat","lemma":"achat","pos":"NOM"} ,
		{"word":"achats","word_nosc":"achats","lemma":"achat","pos":"NOM"} ,
		{"word":"ache","word_nosc":"ache","lemma":"ache","pos":"NOM"} ,
		{"word":"acheminement","word_nosc":"acheminement","lemma":"acheminement","pos":"NOM"} ,
		{"word":"aches","word_nosc":"aches","lemma":"ache","pos":"NOM"} ,
		{"word":"acheteur","word_nosc":"acheteur","lemma":"acheteur","pos":"NOM"} ,
		{"word":"acheteurs","word_nosc":"acheteurs","lemma":"acheteur","pos":"NOM"} ,
		{"word":"acheteuse","word_nosc":"acheteuse","lemma":"acheteur","pos":"NOM"} ,
		{"word":"acheteuses","word_nosc":"acheteuses","lemma":"acheteur","pos":"NOM"} ,
		{"word":"achillée","word_nosc":"achillee","lemma":"achillée","pos":"NOM"} ,
		{"word":"achondroplasie","word_nosc":"achondroplasie","lemma":"achondroplasie","pos":"NOM"} ,
		{"word":"achondroplasies","word_nosc":"achondroplasies","lemma":"achondroplasie","pos":"NOM"} ,
		{"word":"achoppement","word_nosc":"achoppement","lemma":"achoppement","pos":"NOM"} ,
		{"word":"achèvement","word_nosc":"achevement","lemma":"achèvement","pos":"NOM"} ,
		{"word":"achèvements","word_nosc":"achevements","lemma":"achèvement","pos":"NOM"} ,
		{"word":"achélème","word_nosc":"acheleme","lemma":"achélème","pos":"NOM"} ,
		{"word":"achélèmes","word_nosc":"achelemes","lemma":"achélème","pos":"NOM"} ,
		{"word":"acid jazz","word_nosc":"acid jazz","lemma":"acid jazz","pos":"NOM"} ,
		{"word":"acide","word_nosc":"acide","lemma":"acide","pos":"NOM"} ,
		{"word":"acides","word_nosc":"acides","lemma":"acide","pos":"NOM"} ,
		{"word":"acidité","word_nosc":"acidite","lemma":"acidité","pos":"NOM"} ,
		{"word":"acidités","word_nosc":"acidites","lemma":"acidité","pos":"NOM"} ,
		{"word":"acidocétose","word_nosc":"acidocetose","lemma":"acidocétose","pos":"NOM"} ,
		{"word":"acidose","word_nosc":"acidose","lemma":"acidose","pos":"NOM"} ,
		{"word":"acier","word_nosc":"acier","lemma":"acier","pos":"NOM"} ,
		{"word":"aciers","word_nosc":"aciers","lemma":"acier","pos":"NOM"} ,
		{"word":"aciérie","word_nosc":"acierie","lemma":"aciérie","pos":"NOM"} ,
		{"word":"aciéries","word_nosc":"acieries","lemma":"aciérie","pos":"NOM"} ,
		{"word":"acmé","word_nosc":"acme","lemma":"acmé","pos":"NOM"} ,
		{"word":"acné","word_nosc":"acne","lemma":"acné","pos":"NOM"} ,
		{"word":"acnés","word_nosc":"acnes","lemma":"acné","pos":"NOM"} ,
		{"word":"acolyte","word_nosc":"acolyte","lemma":"acolyte","pos":"NOM"} ,
		{"word":"acolytes","word_nosc":"acolytes","lemma":"acolyte","pos":"NOM"} ,
		{"word":"acompte","word_nosc":"acompte","lemma":"acompte","pos":"NOM"} ,
		{"word":"acomptes","word_nosc":"acomptes","lemma":"acompte","pos":"NOM"} ,
		{"word":"aconiers","word_nosc":"aconiers","lemma":"aconier","pos":"NOM"} ,
		{"word":"aconit","word_nosc":"aconit","lemma":"aconit","pos":"NOM"} ,
		{"word":"acouphène","word_nosc":"acouphene","lemma":"acouphène","pos":"NOM"} ,
		{"word":"acoustique","word_nosc":"acoustique","lemma":"acoustique","pos":"NOM"} ,
		{"word":"acoustiques","word_nosc":"acoustiques","lemma":"acoustique","pos":"NOM"} ,
		{"word":"acqua-toffana","word_nosc":"acqua-toffana","lemma":"acqua-toffana","pos":"NOM"} ,
		{"word":"acquiescement","word_nosc":"acquiescement","lemma":"acquiescement","pos":"NOM"} ,
		{"word":"acquiescements","word_nosc":"acquiescements","lemma":"acquiescement","pos":"NOM"} ,
		{"word":"acquis","word_nosc":"acquis","lemma":"acquis","pos":"NOM"} ,
		{"word":"acquisition","word_nosc":"acquisition","lemma":"acquisition","pos":"NOM"} ,
		{"word":"acquisitions","word_nosc":"acquisitions","lemma":"acquisition","pos":"NOM"} ,
		{"word":"acquit","word_nosc":"acquit","lemma":"acquit","pos":"NOM"} ,
		{"word":"acquits","word_nosc":"acquits","lemma":"acquit","pos":"NOM"} ,
		{"word":"acquittement","word_nosc":"acquittement","lemma":"acquittement","pos":"NOM"} ,
		{"word":"acquitté","word_nosc":"acquitte","lemma":"acquitté","pos":"NOM"} ,
		{"word":"acquittés","word_nosc":"acquittes","lemma":"acquitté","pos":"NOM"} ,
		{"word":"acquéreur","word_nosc":"acquereur","lemma":"acquéreur","pos":"NOM"} ,
		{"word":"acquéreurs","word_nosc":"acquereurs","lemma":"acquéreur","pos":"NOM"} ,
		{"word":"acquêt","word_nosc":"acquet","lemma":"acquêt","pos":"NOM"} ,
		{"word":"acquêts","word_nosc":"acquets","lemma":"acquêt","pos":"NOM"} ,
		{"word":"acra","word_nosc":"acra","lemma":"acra","pos":"NOM"} ,
		{"word":"acre","word_nosc":"acre","lemma":"acre","pos":"NOM"} ,
		{"word":"acres","word_nosc":"acres","lemma":"acre","pos":"NOM"} ,
		{"word":"acrimonie","word_nosc":"acrimonie","lemma":"acrimonie","pos":"NOM"} ,
		{"word":"acrobate","word_nosc":"acrobate","lemma":"acrobate","pos":"NOM"} ,
		{"word":"acrobates","word_nosc":"acrobates","lemma":"acrobate","pos":"NOM"} ,
		{"word":"acrobatie","word_nosc":"acrobatie","lemma":"acrobatie","pos":"NOM"} ,
		{"word":"acrobaties","word_nosc":"acrobaties","lemma":"acrobatie","pos":"NOM"} ,
		{"word":"acromion","word_nosc":"acromion","lemma":"acromion","pos":"NOM"} ,
		{"word":"acronyme","word_nosc":"acronyme","lemma":"acronyme","pos":"NOM"} ,
		{"word":"acrophobie","word_nosc":"acrophobie","lemma":"acrophobie","pos":"NOM"} ,
		{"word":"acropole","word_nosc":"acropole","lemma":"acropole","pos":"NOM"} ,
		{"word":"acrostiche","word_nosc":"acrostiche","lemma":"acrostiche","pos":"NOM"} ,
		{"word":"acrotère","word_nosc":"acrotere","lemma":"acrotère","pos":"NOM"} ,
		{"word":"acrylique","word_nosc":"acrylique","lemma":"acrylique","pos":"NOM"} ,
		{"word":"acryliques","word_nosc":"acryliques","lemma":"acrylique","pos":"NOM"} ,
		{"word":"acrylonitrile","word_nosc":"acrylonitrile","lemma":"acrylonitrile","pos":"NOM"} ,
		{"word":"acte","word_nosc":"acte","lemma":"acte","pos":"NOM"} ,
		{"word":"actes","word_nosc":"actes","lemma":"acte","pos":"NOM"} ,
		{"word":"acteur","word_nosc":"acteur","lemma":"acteur","pos":"NOM"} ,
		{"word":"acteurs","word_nosc":"acteurs","lemma":"acteur","pos":"NOM"} ,
		{"word":"actif","word_nosc":"actif","lemma":"actif","pos":"NOM"} ,
		{"word":"actifs","word_nosc":"actifs","lemma":"actif","pos":"NOM"} ,
		{"word":"actine","word_nosc":"actine","lemma":"actine","pos":"NOM"} ,
		{"word":"actinies","word_nosc":"actinies","lemma":"actinie","pos":"NOM"} ,
		{"word":"action","word_nosc":"action","lemma":"action","pos":"NOM"} ,
		{"word":"action painting","word_nosc":"action painting","lemma":"action painting","pos":"NOM"} ,
		{"word":"actionnaire","word_nosc":"actionnaire","lemma":"actionnaire","pos":"NOM"} ,
		{"word":"actionnaires","word_nosc":"actionnaires","lemma":"actionnaire","pos":"NOM"} ,
		{"word":"actionnariat","word_nosc":"actionnariat","lemma":"actionnariat","pos":"NOM"} ,
		{"word":"actionnement","word_nosc":"actionnement","lemma":"actionnement","pos":"NOM"} ,
		{"word":"actionneur","word_nosc":"actionneur","lemma":"actionneur","pos":"NOM"} ,
		{"word":"actions","word_nosc":"actions","lemma":"action","pos":"NOM"} ,
		{"word":"activateur","word_nosc":"activateur","lemma":"activateur","pos":"NOM"} ,
		{"word":"activation","word_nosc":"activation","lemma":"activation","pos":"NOM"} ,
		{"word":"activations","word_nosc":"activations","lemma":"activation","pos":"NOM"} ,
		{"word":"activisme","word_nosc":"activisme","lemma":"activisme","pos":"NOM"} ,
		{"word":"activiste","word_nosc":"activiste","lemma":"activiste","pos":"NOM"} ,
		{"word":"activistes","word_nosc":"activistes","lemma":"activiste","pos":"NOM"} ,
		{"word":"activité","word_nosc":"activite","lemma":"activité","pos":"NOM"} ,
		{"word":"activités","word_nosc":"activites","lemma":"activité","pos":"NOM"} ,
		{"word":"actrice","word_nosc":"actrice","lemma":"acteur","pos":"NOM"} ,
		{"word":"actrices","word_nosc":"actrices","lemma":"acteur","pos":"NOM"} ,
		{"word":"actuaire","word_nosc":"actuaire","lemma":"actuaire","pos":"NOM"} ,
		{"word":"actualisation","word_nosc":"actualisation","lemma":"actualisation","pos":"NOM"} ,
		{"word":"actualité","word_nosc":"actualite","lemma":"actualité","pos":"NOM"} ,
		{"word":"actualités","word_nosc":"actualites","lemma":"actualité","pos":"NOM"} ,
		{"word":"actuation","word_nosc":"actuation","lemma":"actuation","pos":"NOM"} ,
		{"word":"acuité","word_nosc":"acuite","lemma":"acuité","pos":"NOM"} ,
		{"word":"acumen","word_nosc":"acumen","lemma":"acumen","pos":"NOM"} ,
		{"word":"acuponcteur","word_nosc":"acuponcteur","lemma":"acuponcteur","pos":"NOM"} ,
		{"word":"acuponcture","word_nosc":"acuponcture","lemma":"acuponcture","pos":"NOM"} ,
		{"word":"acupuncteur","word_nosc":"acupuncteur","lemma":"acupuncteur","pos":"NOM"} ,
		{"word":"acupuncture","word_nosc":"acupuncture","lemma":"acupuncture","pos":"NOM"} ,
		{"word":"acéphale","word_nosc":"acephale","lemma":"acéphale","pos":"NOM"} ,
		{"word":"acéphales","word_nosc":"acephales","lemma":"acéphale","pos":"NOM"} ,
		{"word":"acétate","word_nosc":"acetate","lemma":"acétate","pos":"NOM"} ,
		{"word":"acétone","word_nosc":"acetone","lemma":"acétone","pos":"NOM"} ,
		{"word":"acétylcholine","word_nosc":"acetylcholine","lemma":"acétylcholine","pos":"NOM"} ,
		{"word":"acétylène","word_nosc":"acetylene","lemma":"acétylène","pos":"NOM"} ,
		{"word":"ada","word_nosc":"ada","lemma":"ada","pos":"NOM"} ,
		{"word":"adage","word_nosc":"adage","lemma":"adage","pos":"NOM"} ,
		{"word":"adages","word_nosc":"adages","lemma":"adage","pos":"NOM"} ,
		{"word":"adagio","word_nosc":"adagio","lemma":"adagio","pos":"NOM"} ,
		{"word":"adaptabilité","word_nosc":"adaptabilite","lemma":"adaptabilité","pos":"NOM"} ,
		{"word":"adaptateur","word_nosc":"adaptateur","lemma":"adaptateur","pos":"NOM"} ,
		{"word":"adaptateurs","word_nosc":"adaptateurs","lemma":"adaptateur","pos":"NOM"} ,
		{"word":"adaptation","word_nosc":"adaptation","lemma":"adaptation","pos":"NOM"} ,
		{"word":"adaptations","word_nosc":"adaptations","lemma":"adaptation","pos":"NOM"} ,
		{"word":"addenda","word_nosc":"addenda","lemma":"addenda","pos":"NOM"} ,
		{"word":"addendum","word_nosc":"addendum","lemma":"addendum","pos":"NOM"} ,
		{"word":"addiction","word_nosc":"addiction","lemma":"addiction","pos":"NOM"} ,
		{"word":"additif","word_nosc":"additif","lemma":"additif","pos":"NOM"} ,
		{"word":"additifs","word_nosc":"additifs","lemma":"additif","pos":"NOM"} ,
		{"word":"addition","word_nosc":"addition","lemma":"addition","pos":"NOM"} ,
		{"word":"additions","word_nosc":"additions","lemma":"addition","pos":"NOM"} ,
		{"word":"adducteur","word_nosc":"adducteur","lemma":"adducteur","pos":"NOM"} ,
		{"word":"adducteurs","word_nosc":"adducteurs","lemma":"adducteur","pos":"NOM"} ,
		{"word":"adduction","word_nosc":"adduction","lemma":"adduction","pos":"NOM"} ,
		{"word":"adent","word_nosc":"adent","lemma":"adent","pos":"NOM"} ,
		{"word":"adepte","word_nosc":"adepte","lemma":"adepte","pos":"NOM"} ,
		{"word":"adeptes","word_nosc":"adeptes","lemma":"adepte","pos":"NOM"} ,
		{"word":"adhérence","word_nosc":"adherence","lemma":"adhérence","pos":"NOM"} ,
		{"word":"adhérences","word_nosc":"adherences","lemma":"adhérence","pos":"NOM"} ,
		{"word":"adhérent","word_nosc":"adherent","lemma":"adhérent","pos":"NOM"} ,
		{"word":"adhérents","word_nosc":"adherents","lemma":"adhérent","pos":"NOM"} ,
		{"word":"adhésif","word_nosc":"adhesif","lemma":"adhésif","pos":"NOM"} ,
		{"word":"adhésifs","word_nosc":"adhesifs","lemma":"adhésif","pos":"NOM"} ,
		{"word":"adhésion","word_nosc":"adhesion","lemma":"adhésion","pos":"NOM"} ,
		{"word":"adhésions","word_nosc":"adhesions","lemma":"adhésion","pos":"NOM"} ,
		{"word":"adieu","word_nosc":"adieu","lemma":"adieu","pos":"NOM"} ,
		{"word":"adieux","word_nosc":"adieux","lemma":"adieu","pos":"NOM"} ,
		{"word":"adiposité","word_nosc":"adiposite","lemma":"adiposité","pos":"NOM"} ,
		{"word":"adja","word_nosc":"adja","lemma":"adja","pos":"NOM"} ,
		{"word":"adjas","word_nosc":"adjas","lemma":"adja","pos":"NOM"} ,
		{"word":"adjectif","word_nosc":"adjectif","lemma":"adjectif","pos":"NOM"} ,
		{"word":"adjectifs","word_nosc":"adjectifs","lemma":"adjectif","pos":"NOM"} ,
		{"word":"adjoint","word_nosc":"adjoint","lemma":"adjoint","pos":"NOM"} ,
		{"word":"adjointe","word_nosc":"adjointe","lemma":"adjoint","pos":"NOM"} ,
		{"word":"adjointes","word_nosc":"adjointes","lemma":"adjoint","pos":"NOM"} ,
		{"word":"adjoints","word_nosc":"adjoints","lemma":"adjoint","pos":"NOM"} ,
		{"word":"adjonction","word_nosc":"adjonction","lemma":"adjonction","pos":"NOM"} ,
		{"word":"adjudant","word_nosc":"adjudant","lemma":"adjudant","pos":"NOM"} ,
		{"word":"adjudant-chef","word_nosc":"adjudant-chef","lemma":"adjudant-chef","pos":"NOM"} ,
		{"word":"adjudant-major","word_nosc":"adjudant-major","lemma":"adjudant-major","pos":"NOM"} ,
		{"word":"adjudants","word_nosc":"adjudants","lemma":"adjudant","pos":"NOM"} ,
		{"word":"adjudants-chefs","word_nosc":"adjudants-chefs","lemma":"adjudant-chef","pos":"NOM"} ,
		{"word":"adjudication","word_nosc":"adjudication","lemma":"adjudication","pos":"NOM"} ,
		{"word":"adjudications","word_nosc":"adjudications","lemma":"adjudication","pos":"NOM"} ,
		{"word":"adjupète","word_nosc":"adjupete","lemma":"adjupète","pos":"NOM"} ,
		{"word":"adjupètes","word_nosc":"adjupetes","lemma":"adjupète","pos":"NOM"} ,
		{"word":"adjuration","word_nosc":"adjuration","lemma":"adjuration","pos":"NOM"} ,
		{"word":"adjurations","word_nosc":"adjurations","lemma":"adjuration","pos":"NOM"} ,
		{"word":"adjutrice","word_nosc":"adjutrice","lemma":"adjutrice","pos":"NOM"} ,
		{"word":"adjuvants","word_nosc":"adjuvants","lemma":"adjuvant","pos":"NOM"} ,
		{"word":"administrateur","word_nosc":"administrateur","lemma":"administrateur","pos":"NOM"} ,
		{"word":"administrateurs","word_nosc":"administrateurs","lemma":"administrateur","pos":"NOM"} ,
		{"word":"administratif","word_nosc":"administratif","lemma":"administratif","pos":"NOM"} ,
		{"word":"administratifs","word_nosc":"administratifs","lemma":"administratif","pos":"NOM"} ,
		{"word":"administration","word_nosc":"administration","lemma":"administration","pos":"NOM"} ,
		{"word":"administrations","word_nosc":"administrations","lemma":"administration","pos":"NOM"} ,
		{"word":"administrative","word_nosc":"administrative","lemma":"administratif","pos":"NOM"} ,
		{"word":"administratrice","word_nosc":"administratrice","lemma":"administrateur","pos":"NOM"} ,
		{"word":"administré","word_nosc":"administre","lemma":"administré","pos":"NOM"} ,
		{"word":"administrée","word_nosc":"administree","lemma":"administré","pos":"NOM"} ,
		{"word":"administrées","word_nosc":"administrees","lemma":"administré","pos":"NOM"} ,
		{"word":"administrés","word_nosc":"administres","lemma":"administré","pos":"NOM"} ,
		{"word":"admirateur","word_nosc":"admirateur","lemma":"admirateur","pos":"NOM"} ,
		{"word":"admirateurs","word_nosc":"admirateurs","lemma":"admirateur","pos":"NOM"} ,
		{"word":"admiration","word_nosc":"admiration","lemma":"admiration","pos":"NOM"} ,
		{"word":"admirations","word_nosc":"admirations","lemma":"admiration","pos":"NOM"} ,
		{"word":"admiratrice","word_nosc":"admiratrice","lemma":"admirateur","pos":"NOM"} ,
		{"word":"admiratrices","word_nosc":"admiratrices","lemma":"admirateur","pos":"NOM"} ,
		{"word":"admis","word_nosc":"admis","lemma":"admis","pos":"NOM"} ,
		{"word":"admissibilité","word_nosc":"admissibilite","lemma":"admissibilité","pos":"NOM"} ,
		{"word":"admissible","word_nosc":"admissible","lemma":"admissible","pos":"NOM"} ,
		{"word":"admissibles","word_nosc":"admissibles","lemma":"admissible","pos":"NOM"} ,
		{"word":"admission","word_nosc":"admission","lemma":"admission","pos":"NOM"} ,
		{"word":"admissions","word_nosc":"admissions","lemma":"admission","pos":"NOM"} ,
		{"word":"admixtion","word_nosc":"admixtion","lemma":"admixtion","pos":"NOM"} ,
		{"word":"admonestation","word_nosc":"admonestation","lemma":"admonestation","pos":"NOM"} ,
		{"word":"admonestations","word_nosc":"admonestations","lemma":"admonestation","pos":"NOM"} ,
		{"word":"admonition","word_nosc":"admonition","lemma":"admonition","pos":"NOM"} ,
		{"word":"admonitions","word_nosc":"admonitions","lemma":"admonition","pos":"NOM"} ,
		{"word":"ado","word_nosc":"ado","lemma":"ado","pos":"NOM"} ,
		{"word":"adobe","word_nosc":"adobe","lemma":"adobe","pos":"NOM"} ,
		{"word":"adolescence","word_nosc":"adolescence","lemma":"adolescence","pos":"NOM"} ,
		{"word":"adolescences","word_nosc":"adolescences","lemma":"adolescence","pos":"NOM"} ,
		{"word":"adolescent","word_nosc":"adolescent","lemma":"adolescent","pos":"NOM"} ,
		{"word":"adolescente","word_nosc":"adolescente","lemma":"adolescent","pos":"NOM"} ,
		{"word":"adolescentes","word_nosc":"adolescentes","lemma":"adolescent","pos":"NOM"} ,
		{"word":"adolescents","word_nosc":"adolescents","lemma":"adolescent","pos":"NOM"} ,
		{"word":"adon","word_nosc":"adon","lemma":"adon","pos":"NOM"} ,
		{"word":"adonies","word_nosc":"adonies","lemma":"adonies","pos":"NOM"} ,
		{"word":"adonis","word_nosc":"adonis","lemma":"adonis","pos":"NOM"} ,
		{"word":"adoptant","word_nosc":"adoptant","lemma":"adoptant","pos":"NOM"} ,
		{"word":"adoption","word_nosc":"adoption","lemma":"adoption","pos":"NOM"} ,
		{"word":"adoptions","word_nosc":"adoptions","lemma":"adoption","pos":"NOM"} ,
		{"word":"adopté","word_nosc":"adopte","lemma":"adopté","pos":"NOM"} ,
		{"word":"adoptée","word_nosc":"adoptee","lemma":"adopté","pos":"NOM"} ,
		{"word":"adoptées","word_nosc":"adoptees","lemma":"adopté","pos":"NOM"} ,
		{"word":"adoptés","word_nosc":"adoptes","lemma":"adopté","pos":"NOM"} ,
		{"word":"adorateur","word_nosc":"adorateur","lemma":"adorateur","pos":"NOM"} ,
		{"word":"adorateurs","word_nosc":"adorateurs","lemma":"adorateur","pos":"NOM"} ,
		{"word":"adoration","word_nosc":"adoration","lemma":"adoration","pos":"NOM"} ,
		{"word":"adorations","word_nosc":"adorations","lemma":"adoration","pos":"NOM"} ,
		{"word":"adoratrice","word_nosc":"adoratrice","lemma":"adorateur","pos":"NOM"} ,
		{"word":"adoratrices","word_nosc":"adoratrices","lemma":"adorateur","pos":"NOM"} ,
		{"word":"ados","word_nosc":"ados","lemma":"ados","pos":"NOM"} ,
		{"word":"adoubement","word_nosc":"adoubement","lemma":"adoubement","pos":"NOM"} ,
		{"word":"adoucissant","word_nosc":"adoucissant","lemma":"adoucissant","pos":"NOM"} ,
		{"word":"adoucissement","word_nosc":"adoucissement","lemma":"adoucissement","pos":"NOM"} ,
		{"word":"adoucissements","word_nosc":"adoucissements","lemma":"adoucissement","pos":"NOM"} ,
		{"word":"adoucisseur","word_nosc":"adoucisseur","lemma":"adoucisseur","pos":"NOM"} ,
		{"word":"adoucisseurs","word_nosc":"adoucisseurs","lemma":"adoucisseur","pos":"NOM"} ,
		{"word":"adp","word_nosc":"adp","lemma":"adp","pos":"NOM"} ,
		{"word":"adressage","word_nosc":"adressage","lemma":"adressage","pos":"NOM"} ,
		{"word":"adresse","word_nosc":"adresse","lemma":"adresse","pos":"NOM"} ,
		{"word":"adresses","word_nosc":"adresses","lemma":"adresse","pos":"NOM"} ,
		{"word":"adret","word_nosc":"adret","lemma":"adret","pos":"NOM"} ,
		{"word":"adrénaline","word_nosc":"adrenaline","lemma":"adrénaline","pos":"NOM"} ,
		{"word":"adrénalines","word_nosc":"adrenalines","lemma":"adrénaline","pos":"NOM"} ,
		{"word":"adulateur","word_nosc":"adulateur","lemma":"adulateur","pos":"NOM"} ,
		{"word":"adulation","word_nosc":"adulation","lemma":"adulation","pos":"NOM"} ,
		{"word":"adulations","word_nosc":"adulations","lemma":"adulation","pos":"NOM"} ,
		{"word":"adultat","word_nosc":"adultat","lemma":"adultat","pos":"NOM"} ,
		{"word":"adulte","word_nosc":"adulte","lemma":"adulte","pos":"NOM"} ,
		{"word":"adultes","word_nosc":"adultes","lemma":"adulte","pos":"NOM"} ,
		{"word":"adultère","word_nosc":"adultere","lemma":"adultère","pos":"NOM"} ,
		{"word":"adultères","word_nosc":"adulteres","lemma":"adultère","pos":"NOM"} ,
		{"word":"adultération","word_nosc":"adulteration","lemma":"adultération","pos":"NOM"} ,
		{"word":"adventiste","word_nosc":"adventiste","lemma":"adventiste","pos":"NOM"} ,
		{"word":"adverbe","word_nosc":"adverbe","lemma":"adverbe","pos":"NOM"} ,
		{"word":"adverbes","word_nosc":"adverbes","lemma":"adverbe","pos":"NOM"} ,
		{"word":"adversaire","word_nosc":"adversaire","lemma":"adversaire","pos":"NOM"} ,
		{"word":"adversaires","word_nosc":"adversaires","lemma":"adversaire","pos":"NOM"} ,
		{"word":"adversité","word_nosc":"adversite","lemma":"adversité","pos":"NOM"} ,
		{"word":"adversités","word_nosc":"adversites","lemma":"adversité","pos":"NOM"} ,
		{"word":"adénine","word_nosc":"adenine","lemma":"adénine","pos":"NOM"} ,
		{"word":"adénocarcinome","word_nosc":"adenocarcinome","lemma":"adénocarcinome","pos":"NOM"} ,
		{"word":"adénome","word_nosc":"adenome","lemma":"adénome","pos":"NOM"} ,
		{"word":"adénopathie","word_nosc":"adenopathie","lemma":"adénopathie","pos":"NOM"} ,
		{"word":"adénosine","word_nosc":"adenosine","lemma":"adénosine","pos":"NOM"} ,
		{"word":"adénovirus","word_nosc":"adenovirus","lemma":"adénovirus","pos":"NOM"} ,
		{"word":"adéquation","word_nosc":"adequation","lemma":"adéquation","pos":"NOM"} ,
		{"word":"aegipans","word_nosc":"aegipans","lemma":"aegipan","pos":"NOM"} ,
		{"word":"affabilité","word_nosc":"affabilite","lemma":"affabilité","pos":"NOM"} ,
		{"word":"affabilités","word_nosc":"affabilites","lemma":"affabilité","pos":"NOM"} ,
		{"word":"affabulation","word_nosc":"affabulation","lemma":"affabulation","pos":"NOM"} ,
		{"word":"affabulations","word_nosc":"affabulations","lemma":"affabulation","pos":"NOM"} ,
		{"word":"affabulatrice","word_nosc":"affabulatrice","lemma":"affabulateur","pos":"NOM"} ,
		{"word":"affacturage","word_nosc":"affacturage","lemma":"affacturage","pos":"NOM"} ,
		{"word":"affadissement","word_nosc":"affadissement","lemma":"affadissement","pos":"NOM"} ,
		{"word":"affaiblissement","word_nosc":"affaiblissement","lemma":"affaiblissement","pos":"NOM"} ,
		{"word":"affaire","word_nosc":"affaire","lemma":"affaire","pos":"NOM"} ,
		{"word":"affairement","word_nosc":"affairement","lemma":"affairement","pos":"NOM"} ,
		{"word":"affairements","word_nosc":"affairements","lemma":"affairement","pos":"NOM"} ,
		{"word":"affaires","word_nosc":"affaires","lemma":"affaire","pos":"NOM"} ,
		{"word":"affairisme","word_nosc":"affairisme","lemma":"affairisme","pos":"NOM"} ,
		{"word":"affairiste","word_nosc":"affairiste","lemma":"affairiste","pos":"NOM"} ,
		{"word":"affairistes","word_nosc":"affairistes","lemma":"affairiste","pos":"NOM"} ,
		{"word":"affairé","word_nosc":"affaire","lemma":"affairé","pos":"NOM"} ,
		{"word":"affairée","word_nosc":"affairee","lemma":"affairé","pos":"NOM"} ,
		{"word":"affairées","word_nosc":"affairees","lemma":"affairé","pos":"NOM"} ,
		{"word":"affairés","word_nosc":"affaires","lemma":"affairé","pos":"NOM"} ,
		{"word":"affaissement","word_nosc":"affaissement","lemma":"affaissement","pos":"NOM"} ,
		{"word":"affaissements","word_nosc":"affaissements","lemma":"affaissement","pos":"NOM"} ,
		{"word":"affalement","word_nosc":"affalement","lemma":"affalement","pos":"NOM"} ,
		{"word":"affamé","word_nosc":"affame","lemma":"affamé","pos":"NOM"} ,
		{"word":"affamée","word_nosc":"affamee","lemma":"affamé","pos":"NOM"} ,
		{"word":"affamées","word_nosc":"affamees","lemma":"affamé","pos":"NOM"} ,
		{"word":"affamés","word_nosc":"affames","lemma":"affamé","pos":"NOM"} ,
		{"word":"affect","word_nosc":"affect","lemma":"affect","pos":"NOM"} ,
		{"word":"affectation","word_nosc":"affectation","lemma":"affectation","pos":"NOM"} ,
		{"word":"affectations","word_nosc":"affectations","lemma":"affectation","pos":"NOM"} ,
		{"word":"affection","word_nosc":"affection","lemma":"affection","pos":"NOM"} ,
		{"word":"affections","word_nosc":"affections","lemma":"affection","pos":"NOM"} ,
		{"word":"affectivité","word_nosc":"affectivite","lemma":"affectivité","pos":"NOM"} ,
		{"word":"affermissement","word_nosc":"affermissement","lemma":"affermissement","pos":"NOM"} ,
		{"word":"affichage","word_nosc":"affichage","lemma":"affichage","pos":"NOM"} ,
		{"word":"affichages","word_nosc":"affichages","lemma":"affichage","pos":"NOM"} ,
		{"word":"affiche","word_nosc":"affiche","lemma":"affiche","pos":"NOM"} ,
		{"word":"affiches","word_nosc":"affiches","lemma":"affiche","pos":"NOM"} ,
		{"word":"affichette","word_nosc":"affichette","lemma":"affichette","pos":"NOM"} ,
		{"word":"affichettes","word_nosc":"affichettes","lemma":"affichette","pos":"NOM"} ,
		{"word":"afficheur","word_nosc":"afficheur","lemma":"afficheur","pos":"NOM"} ,
		{"word":"afficheurs","word_nosc":"afficheurs","lemma":"afficheur","pos":"NOM"} ,
		{"word":"affichiste","word_nosc":"affichiste","lemma":"affichiste","pos":"NOM"} ,
		{"word":"affichistes","word_nosc":"affichistes","lemma":"affichiste","pos":"NOM"} ,
		{"word":"affidavit","word_nosc":"affidavit","lemma":"affidavit","pos":"NOM"} ,
		{"word":"affidé","word_nosc":"affide","lemma":"affidé","pos":"NOM"} ,
		{"word":"affidés","word_nosc":"affides","lemma":"affidé","pos":"NOM"} ,
		{"word":"affiliation","word_nosc":"affiliation","lemma":"affiliation","pos":"NOM"} ,
		{"word":"affiliations","word_nosc":"affiliations","lemma":"affiliation","pos":"NOM"} ,
		{"word":"affilié","word_nosc":"affilie","lemma":"affilié","pos":"NOM"} ,
		{"word":"affiliée","word_nosc":"affiliee","lemma":"affilié","pos":"NOM"} ,
		{"word":"affiliées","word_nosc":"affiliees","lemma":"affilié","pos":"NOM"} ,
		{"word":"affiliés","word_nosc":"affilies","lemma":"affilié","pos":"NOM"} ,
		{"word":"affinage","word_nosc":"affinage","lemma":"affinage","pos":"NOM"} ,
		{"word":"affinement","word_nosc":"affinement","lemma":"affinement","pos":"NOM"} ,
		{"word":"affinité","word_nosc":"affinite","lemma":"affinité","pos":"NOM"} ,
		{"word":"affinités","word_nosc":"affinites","lemma":"affinité","pos":"NOM"} ,
		{"word":"affiquets","word_nosc":"affiquets","lemma":"affiquet","pos":"NOM"} ,
		{"word":"affirmation","word_nosc":"affirmation","lemma":"affirmation","pos":"NOM"} ,
		{"word":"affirmations","word_nosc":"affirmations","lemma":"affirmation","pos":"NOM"} ,
		{"word":"affirmative","word_nosc":"affirmative","lemma":"affirmative","pos":"NOM"} ,
		{"word":"affleurement","word_nosc":"affleurement","lemma":"affleurement","pos":"NOM"} ,
		{"word":"affleurements","word_nosc":"affleurements","lemma":"affleurement","pos":"NOM"} ,
		{"word":"affliction","word_nosc":"affliction","lemma":"affliction","pos":"NOM"} ,
		{"word":"afflictions","word_nosc":"afflictions","lemma":"affliction","pos":"NOM"} ,
		{"word":"affluence","word_nosc":"affluence","lemma":"affluence","pos":"NOM"} ,
		{"word":"affluent","word_nosc":"affluent","lemma":"affluent","pos":"NOM"} ,
		{"word":"affluents","word_nosc":"affluents","lemma":"affluent","pos":"NOM"} ,
		{"word":"afflux","word_nosc":"afflux","lemma":"afflux","pos":"NOM"} ,
		{"word":"affolement","word_nosc":"affolement","lemma":"affolement","pos":"NOM"} ,
		{"word":"affouage","word_nosc":"affouage","lemma":"affouage","pos":"NOM"} ,
		{"word":"affouages","word_nosc":"affouages","lemma":"affouage","pos":"NOM"} ,
		{"word":"affouillement","word_nosc":"affouillement","lemma":"affouillement","pos":"NOM"} ,
		{"word":"affouillements","word_nosc":"affouillements","lemma":"affouillement","pos":"NOM"} ,
		{"word":"affranchi","word_nosc":"affranchi","lemma":"affranchi","pos":"NOM"} ,
		{"word":"affranchie","word_nosc":"affranchie","lemma":"affranchi","pos":"NOM"} ,
		{"word":"affranchies","word_nosc":"affranchies","lemma":"affranchi","pos":"NOM"} ,
		{"word":"affranchis","word_nosc":"affranchis","lemma":"affranchi","pos":"NOM"} ,
		{"word":"affranchissement","word_nosc":"affranchissement","lemma":"affranchissement","pos":"NOM"} ,
		{"word":"affres","word_nosc":"affres","lemma":"affre","pos":"NOM"} ,
		{"word":"affreux","word_nosc":"affreux","lemma":"affreux","pos":"NOM"} ,
		{"word":"affront","word_nosc":"affront","lemma":"affront","pos":"NOM"} ,
		{"word":"affrontement","word_nosc":"affrontement","lemma":"affrontement","pos":"NOM"} ,
		{"word":"affrontements","word_nosc":"affrontements","lemma":"affrontement","pos":"NOM"} ,
		{"word":"affronts","word_nosc":"affronts","lemma":"affront","pos":"NOM"} ,
		{"word":"affrètement","word_nosc":"affretement","lemma":"affrètement","pos":"NOM"} ,
		{"word":"affréteur","word_nosc":"affreteur","lemma":"affréteur","pos":"NOM"} ,
		{"word":"affublement","word_nosc":"affublement","lemma":"affublement","pos":"NOM"} ,
		{"word":"affusions","word_nosc":"affusions","lemma":"affusion","pos":"NOM"} ,
		{"word":"afféterie","word_nosc":"affeterie","lemma":"afféterie","pos":"NOM"} ,
		{"word":"afféteries","word_nosc":"affeteries","lemma":"afféterie","pos":"NOM"} ,
		{"word":"affût","word_nosc":"affut","lemma":"affût","pos":"NOM"} ,
		{"word":"affûtage","word_nosc":"affutage","lemma":"affûtage","pos":"NOM"} ,
		{"word":"affûteur","word_nosc":"affuteur","lemma":"affûteur","pos":"NOM"} ,
		{"word":"affûts","word_nosc":"affuts","lemma":"affût","pos":"NOM"} ,
		{"word":"afghan","word_nosc":"afghan","lemma":"afghan","pos":"NOM"} ,
		{"word":"afghane","word_nosc":"afghane","lemma":"afghan","pos":"NOM"} ,
		{"word":"afghanes","word_nosc":"afghanes","lemma":"afghan","pos":"NOM"} ,
		{"word":"afghans","word_nosc":"afghans","lemma":"afghan","pos":"NOM"} ,
		{"word":"aficionado","word_nosc":"aficionado","lemma":"aficionado","pos":"NOM"} ,
		{"word":"aficionados","word_nosc":"aficionados","lemma":"aficionado","pos":"NOM"} ,
		{"word":"africain","word_nosc":"africain","lemma":"africain","pos":"NOM"} ,
		{"word":"africaine","word_nosc":"africaine","lemma":"africain","pos":"NOM"} ,
		{"word":"africaines","word_nosc":"africaines","lemma":"africain","pos":"NOM"} ,
		{"word":"africains","word_nosc":"africains","lemma":"africain","pos":"NOM"} ,
		{"word":"africanisme","word_nosc":"africanisme","lemma":"africanisme","pos":"NOM"} ,
		{"word":"afrikaans","word_nosc":"afrikaans","lemma":"afrikaans","pos":"NOM"} ,
		{"word":"afrikaner","word_nosc":"afrikaner","lemma":"afrikaner","pos":"NOM"} ,
		{"word":"afrikaners","word_nosc":"afrikaners","lemma":"afrikaner","pos":"NOM"} ,
		{"word":"afro-américain","word_nosc":"afro-americain","lemma":"afro-américain","pos":"NOM"} ,
		{"word":"afro-américaine","word_nosc":"afro-americaine","lemma":"afro-américain","pos":"NOM"} ,
		{"word":"afro-américains","word_nosc":"afro-americains","lemma":"afro-américain","pos":"NOM"} ,
		{"word":"afro-cubain","word_nosc":"afro-cubain","lemma":"afro-cubain","pos":"NOM"} ,
		{"word":"afro-cubaine","word_nosc":"afro-cubaine","lemma":"afro-cubain","pos":"NOM"} ,
		{"word":"after shave","word_nosc":"after shave","lemma":"after-shave","pos":"NOM"} ,
		{"word":"after-shave","word_nosc":"after-shave","lemma":"after-shave","pos":"NOM"} ,
		{"word":"aga","word_nosc":"aga","lemma":"aga","pos":"NOM"} ,
		{"word":"agace","word_nosc":"agace","lemma":"agace","pos":"NOM"} ,
		{"word":"agacement","word_nosc":"agacement","lemma":"agacement","pos":"NOM"} ,
		{"word":"agacements","word_nosc":"agacements","lemma":"agacement","pos":"NOM"} ,
		{"word":"agacerie","word_nosc":"agacerie","lemma":"agacerie","pos":"NOM"} ,
		{"word":"agaceries","word_nosc":"agaceries","lemma":"agacerie","pos":"NOM"} ,
		{"word":"agaceur","word_nosc":"agaceur","lemma":"agaceur","pos":"NOM"} ,
		{"word":"agames","word_nosc":"agames","lemma":"agame","pos":"NOM"} ,
		{"word":"agami","word_nosc":"agami","lemma":"agami","pos":"NOM"} ,
		{"word":"agapanthe","word_nosc":"agapanthe","lemma":"agapanthe","pos":"NOM"} ,
		{"word":"agapanthes","word_nosc":"agapanthes","lemma":"agapanthe","pos":"NOM"} ,
		{"word":"agape","word_nosc":"agape","lemma":"agape","pos":"NOM"} ,
		{"word":"agapes","word_nosc":"agapes","lemma":"agape","pos":"NOM"} ,
		{"word":"agar-agar","word_nosc":"agar-agar","lemma":"agar-agar","pos":"NOM"} ,
		{"word":"agas","word_nosc":"agas","lemma":"aga","pos":"NOM"} ,
		{"word":"agasses","word_nosc":"agasses","lemma":"agasse","pos":"NOM"} ,
		{"word":"agate","word_nosc":"agate","lemma":"agate","pos":"NOM"} ,
		{"word":"agates","word_nosc":"agates","lemma":"agate","pos":"NOM"} ,
		{"word":"agathe","word_nosc":"agathe","lemma":"agathe","pos":"NOM"} ,
		{"word":"agave","word_nosc":"agave","lemma":"agave","pos":"NOM"} ,
		{"word":"agaves","word_nosc":"agaves","lemma":"agave","pos":"NOM"} ,
		{"word":"age","word_nosc":"age","lemma":"age","pos":"NOM"} ,
		{"word":"agence","word_nosc":"agence","lemma":"agence","pos":"NOM"} ,
		{"word":"agencement","word_nosc":"agencement","lemma":"agencement","pos":"NOM"} ,
		{"word":"agencements","word_nosc":"agencements","lemma":"agencement","pos":"NOM"} ,
		{"word":"agences","word_nosc":"agences","lemma":"agence","pos":"NOM"} ,
		{"word":"agenda","word_nosc":"agenda","lemma":"agenda","pos":"NOM"} ,
		{"word":"agendas","word_nosc":"agendas","lemma":"agenda","pos":"NOM"} ,
		{"word":"agenouillement","word_nosc":"agenouillement","lemma":"agenouillement","pos":"NOM"} ,
		{"word":"agent","word_nosc":"agent","lemma":"agent","pos":"NOM"} ,
		{"word":"agente","word_nosc":"agente","lemma":"agent","pos":"NOM"} ,
		{"word":"agents","word_nosc":"agents","lemma":"agent","pos":"NOM"} ,
		{"word":"ages","word_nosc":"ages","lemma":"age","pos":"NOM"} ,
		{"word":"aggiornamento","word_nosc":"aggiornamento","lemma":"aggiornamento","pos":"NOM"} ,
		{"word":"agglo","word_nosc":"agglo","lemma":"agglo","pos":"NOM"} ,
		{"word":"agglomérat","word_nosc":"agglomerat","lemma":"agglomérat","pos":"NOM"} ,
		{"word":"agglomération","word_nosc":"agglomeration","lemma":"agglomération","pos":"NOM"} ,
		{"word":"agglomérations","word_nosc":"agglomerations","lemma":"agglomération","pos":"NOM"} ,
		{"word":"aggloméré","word_nosc":"agglomere","lemma":"aggloméré","pos":"NOM"} ,
		{"word":"agglomérés","word_nosc":"agglomeres","lemma":"aggloméré","pos":"NOM"} ,
		{"word":"agglutination","word_nosc":"agglutination","lemma":"agglutination","pos":"NOM"} ,
		{"word":"agglutinement","word_nosc":"agglutinement","lemma":"agglutinement","pos":"NOM"} ,
		{"word":"agglutinogène","word_nosc":"agglutinogene","lemma":"agglutinogène","pos":"NOM"} ,
		{"word":"aggravation","word_nosc":"aggravation","lemma":"aggravation","pos":"NOM"} ,
		{"word":"aggrave","word_nosc":"aggrave","lemma":"aggrave","pos":"NOM"} ,
		{"word":"aggravée","word_nosc":"aggravee","lemma":"aggravée","pos":"NOM"} ,
		{"word":"aghas","word_nosc":"aghas","lemma":"agha","pos":"NOM"} ,
		{"word":"agilité","word_nosc":"agilite","lemma":"agilité","pos":"NOM"} ,
		{"word":"agilités","word_nosc":"agilites","lemma":"agilité","pos":"NOM"} ,
		{"word":"agios","word_nosc":"agios","lemma":"agio","pos":"NOM"} ,
		{"word":"agioteur","word_nosc":"agioteur","lemma":"agioteur","pos":"NOM"} ,
		{"word":"agissement","word_nosc":"agissement","lemma":"agissement","pos":"NOM"} ,
		{"word":"agissements","word_nosc":"agissements","lemma":"agissement","pos":"NOM"} ,
		{"word":"agit-prop","word_nosc":"agit-prop","lemma":"agit-prop","pos":"NOM"} ,
		{"word":"agitateur","word_nosc":"agitateur","lemma":"agitateur","pos":"NOM"} ,
		{"word":"agitateurs","word_nosc":"agitateurs","lemma":"agitateur","pos":"NOM"} ,
		{"word":"agitation","word_nosc":"agitation","lemma":"agitation","pos":"NOM"} ,
		{"word":"agitations","word_nosc":"agitations","lemma":"agitation","pos":"NOM"} ,
		{"word":"agitatrice","word_nosc":"agitatrice","lemma":"agitateur","pos":"NOM"} ,
		{"word":"agité","word_nosc":"agite","lemma":"agité","pos":"NOM"} ,
		{"word":"agitée","word_nosc":"agitee","lemma":"agité","pos":"NOM"} ,
		{"word":"agitées","word_nosc":"agitees","lemma":"agité","pos":"NOM"} ,
		{"word":"agités","word_nosc":"agites","lemma":"agité","pos":"NOM"} ,
		{"word":"agnat","word_nosc":"agnat","lemma":"agnat","pos":"NOM"} ,
		{"word":"agnathes","word_nosc":"agnathes","lemma":"agnathe","pos":"NOM"} ,
		{"word":"agneau","word_nosc":"agneau","lemma":"agneau","pos":"NOM"} ,
		{"word":"agneaux","word_nosc":"agneaux","lemma":"agneau","pos":"NOM"} ,
		{"word":"agnelage","word_nosc":"agnelage","lemma":"agnelage","pos":"NOM"} ,
		{"word":"agnelet","word_nosc":"agnelet","lemma":"agnelet","pos":"NOM"} ,
		{"word":"agneline","word_nosc":"agneline","lemma":"agneline","pos":"NOM"} ,
		{"word":"agnelle","word_nosc":"agnelle","lemma":"agnel","pos":"NOM"} ,
		{"word":"agnelles","word_nosc":"agnelles","lemma":"agnel","pos":"NOM"} ,
		{"word":"agnosie","word_nosc":"agnosie","lemma":"agnosie","pos":"NOM"} ,
		{"word":"agnostique","word_nosc":"agnostique","lemma":"agnostique","pos":"NOM"} ,
		{"word":"agnostiques","word_nosc":"agnostiques","lemma":"agnostique","pos":"NOM"} ,
		{"word":"agnus dei","word_nosc":"agnus dei","lemma":"agnus dei","pos":"NOM"} ,
		{"word":"agnus-dei","word_nosc":"agnus-dei","lemma":"agnus-dei","pos":"NOM"} ,
		{"word":"agonie","word_nosc":"agonie","lemma":"agonie","pos":"NOM"} ,
		{"word":"agonies","word_nosc":"agonies","lemma":"agonie","pos":"NOM"} ,
		{"word":"agonique","word_nosc":"agonique","lemma":"agonique","pos":"NOM"} ,
		{"word":"agoniques","word_nosc":"agoniques","lemma":"agonique","pos":"NOM"} ,
		{"word":"agonisant","word_nosc":"agonisant","lemma":"agonisant","pos":"NOM"} ,
		{"word":"agonisante","word_nosc":"agonisante","lemma":"agonisant","pos":"NOM"} ,
		{"word":"agonisantes","word_nosc":"agonisantes","lemma":"agonisant","pos":"NOM"} ,
		{"word":"agonisants","word_nosc":"agonisants","lemma":"agonisant","pos":"NOM"} ,
		{"word":"agora","word_nosc":"agora","lemma":"agora","pos":"NOM"} ,
		{"word":"agoraphobe","word_nosc":"agoraphobe","lemma":"agoraphobe","pos":"NOM"} ,
		{"word":"agoraphobes","word_nosc":"agoraphobes","lemma":"agoraphobe","pos":"NOM"} ,
		{"word":"agoraphobie","word_nosc":"agoraphobie","lemma":"agoraphobie","pos":"NOM"} ,
		{"word":"agouti","word_nosc":"agouti","lemma":"agouti","pos":"NOM"} ,
		{"word":"agoutis","word_nosc":"agoutis","lemma":"agouti","pos":"NOM"} ,
		{"word":"agoyate","word_nosc":"agoyate","lemma":"agoyate","pos":"NOM"} ,
		{"word":"agoyates","word_nosc":"agoyates","lemma":"agoyate","pos":"NOM"} ,
		{"word":"agrafage","word_nosc":"agrafage","lemma":"agrafage","pos":"NOM"} ,
		{"word":"agrafe","word_nosc":"agrafe","lemma":"agrafe","pos":"NOM"} ,
		{"word":"agrafes","word_nosc":"agrafes","lemma":"agrafe","pos":"NOM"} ,
		{"word":"agrafeur","word_nosc":"agrafeur","lemma":"agrafeur","pos":"NOM"} ,
		{"word":"agrafeuse","word_nosc":"agrafeuse","lemma":"agrafeuse","pos":"NOM"} ,
		{"word":"agrafeuses","word_nosc":"agrafeuses","lemma":"agrafeuse","pos":"NOM"} ,
		{"word":"agrainage","word_nosc":"agrainage","lemma":"agrainage","pos":"NOM"} ,
		{"word":"agrandissement","word_nosc":"agrandissement","lemma":"agrandissement","pos":"NOM"} ,
		{"word":"agrandissements","word_nosc":"agrandissements","lemma":"agrandissement","pos":"NOM"} ,
		{"word":"agrandisseur","word_nosc":"agrandisseur","lemma":"agrandisseur","pos":"NOM"} ,
		{"word":"agrandisseurs","word_nosc":"agrandisseurs","lemma":"agrandisseur","pos":"NOM"} ,
		{"word":"agranulocytose","word_nosc":"agranulocytose","lemma":"agranulocytose","pos":"NOM"} ,
		{"word":"agresseur","word_nosc":"agresseur","lemma":"agresseur","pos":"NOM"} ,
		{"word":"agresseurs","word_nosc":"agresseurs","lemma":"agresseur","pos":"NOM"} ,
		{"word":"agression","word_nosc":"agression","lemma":"agression","pos":"NOM"} ,
		{"word":"agressions","word_nosc":"agressions","lemma":"agression","pos":"NOM"} ,
		{"word":"agressivité","word_nosc":"agressivite","lemma":"agressivité","pos":"NOM"} ,
		{"word":"agressivités","word_nosc":"agressivites","lemma":"agressivité","pos":"NOM"} ,
		{"word":"agriculteur","word_nosc":"agriculteur","lemma":"agriculteur","pos":"NOM"} ,
		{"word":"agriculteurs","word_nosc":"agriculteurs","lemma":"agriculteur","pos":"NOM"} ,
		{"word":"agricultrice","word_nosc":"agricultrice","lemma":"agriculteur","pos":"NOM"} ,
		{"word":"agricultrices","word_nosc":"agricultrices","lemma":"agriculteur","pos":"NOM"} ,
		{"word":"agriculture","word_nosc":"agriculture","lemma":"agriculture","pos":"NOM"} ,
		{"word":"agrippement","word_nosc":"agrippement","lemma":"agrippement","pos":"NOM"} ,
		{"word":"agro","word_nosc":"agro","lemma":"agro","pos":"NOM"} ,
		{"word":"agroalimentaire","word_nosc":"agroalimentaire","lemma":"agroalimentaire","pos":"NOM"} ,
		{"word":"agronome","word_nosc":"agronome","lemma":"agronome","pos":"NOM"} ,
		{"word":"agronomes","word_nosc":"agronomes","lemma":"agronome","pos":"NOM"} ,
		{"word":"agronomie","word_nosc":"agronomie","lemma":"agronomie","pos":"NOM"} ,
		{"word":"agrovilles","word_nosc":"agrovilles","lemma":"agroville","pos":"NOM"} ,
		{"word":"agrume","word_nosc":"agrume","lemma":"agrume","pos":"NOM"} ,
		{"word":"agrumes","word_nosc":"agrumes","lemma":"agrume","pos":"NOM"} ,
		{"word":"agrès","word_nosc":"agres","lemma":"agrès","pos":"NOM"} ,
		{"word":"agréable","word_nosc":"agreable","lemma":"agréable","pos":"NOM"} ,
		{"word":"agréables","word_nosc":"agreables","lemma":"agréable","pos":"NOM"} ,
		{"word":"agrég","word_nosc":"agreg","lemma":"agrég","pos":"NOM"} ,
		{"word":"agrégat","word_nosc":"agregat","lemma":"agrégat","pos":"NOM"} ,
		{"word":"agrégatif","word_nosc":"agregatif","lemma":"agrégatif","pos":"NOM"} ,
		{"word":"agrégatifs","word_nosc":"agregatifs","lemma":"agrégatif","pos":"NOM"} ,
		{"word":"agrégation","word_nosc":"agregation","lemma":"agrégation","pos":"NOM"} ,
		{"word":"agrégative","word_nosc":"agregative","lemma":"agrégative","pos":"NOM"} ,
		{"word":"agrégats","word_nosc":"agregats","lemma":"agrégat","pos":"NOM"} ,
		{"word":"agrégé","word_nosc":"agrege","lemma":"agrégé","pos":"NOM"} ,
		{"word":"agrégée","word_nosc":"agregee","lemma":"agrégé","pos":"NOM"} ,
		{"word":"agrégées","word_nosc":"agregees","lemma":"agrégé","pos":"NOM"} ,
		{"word":"agrégés","word_nosc":"agreges","lemma":"agrégé","pos":"NOM"} ,
		{"word":"agrément","word_nosc":"agrement","lemma":"agrément","pos":"NOM"} ,
		{"word":"agréments","word_nosc":"agrements","lemma":"agrément","pos":"NOM"} ,
		{"word":"agréé","word_nosc":"agree","lemma":"agréé","pos":"NOM"} ,
		{"word":"aguardiente","word_nosc":"aguardiente","lemma":"aguardiente","pos":"NOM"} ,
		{"word":"aguichage","word_nosc":"aguichage","lemma":"aguichage","pos":"NOM"} ,
		{"word":"aguiche","word_nosc":"aguiche","lemma":"aguiche","pos":"NOM"} ,
		{"word":"aguicheuse","word_nosc":"aguicheuse","lemma":"aguicheur","pos":"NOM"} ,
		{"word":"ahan","word_nosc":"ahan","lemma":"ahan","pos":"NOM"} ,
		{"word":"ahanement","word_nosc":"ahanement","lemma":"ahanement","pos":"NOM"} ,
		{"word":"ahanements","word_nosc":"ahanements","lemma":"ahanement","pos":"NOM"} ,
		{"word":"ahans","word_nosc":"ahans","lemma":"ahan","pos":"NOM"} ,
		{"word":"ahuri","word_nosc":"ahuri","lemma":"ahuri","pos":"NOM"} ,
		{"word":"ahurie","word_nosc":"ahurie","lemma":"ahuri","pos":"NOM"} ,
		{"word":"ahuris","word_nosc":"ahuris","lemma":"ahuri","pos":"NOM"} ,
		{"word":"ahurissement","word_nosc":"ahurissement","lemma":"ahurissement","pos":"NOM"} ,
		{"word":"ahurissements","word_nosc":"ahurissements","lemma":"ahurissement","pos":"NOM"} ,
		{"word":"aide","word_nosc":"aide","lemma":"aide","pos":"NOM"} ,
		{"word":"aide-bourreau","word_nosc":"aide-bourreau","lemma":"aide-bourreau","pos":"NOM"} ,
		{"word":"aide-comptable","word_nosc":"aide-comptable","lemma":"aide-comptable","pos":"NOM"} ,
		{"word":"aide-cuisinier","word_nosc":"aide-cuisinier","lemma":"aide-cuisinier","pos":"NOM"} ,
		{"word":"aide-infirmier","word_nosc":"aide-infirmier","lemma":"aide-infirmier","pos":"NOM"} ,
		{"word":"aide-jardinier","word_nosc":"aide-jardinier","lemma":"aide-jardinier","pos":"NOM"} ,
		{"word":"aide-major","word_nosc":"aide-major","lemma":"aide-major","pos":"NOM"} ,
		{"word":"aide-mémoire","word_nosc":"aide-memoire","lemma":"aide-mémoire","pos":"NOM"} ,
		{"word":"aide-ménagère","word_nosc":"aide-menagere","lemma":"aide-ménagère","pos":"NOM"} ,
		{"word":"aide-pharmacien","word_nosc":"aide-pharmacien","lemma":"aide-pharmacien","pos":"NOM"} ,
		{"word":"aide-soignant","word_nosc":"aide-soignant","lemma":"aide-soignant","pos":"NOM"} ,
		{"word":"aide-soignante","word_nosc":"aide-soignante","lemma":"aide-soignant","pos":"NOM"} ,
		{"word":"aides","word_nosc":"aides","lemma":"aide","pos":"NOM"} ,
		{"word":"aides-soignantes","word_nosc":"aides-soignantes","lemma":"aide-soignant","pos":"NOM"} ,
		{"word":"aides-soignants","word_nosc":"aides-soignants","lemma":"aide-soignant","pos":"NOM"} ,
		{"word":"aigle","word_nosc":"aigle","lemma":"aigle","pos":"NOM"} ,
		{"word":"aiglefin","word_nosc":"aiglefin","lemma":"aiglefin","pos":"NOM"} ,
		{"word":"aigles","word_nosc":"aigles","lemma":"aigle","pos":"NOM"} ,
		{"word":"aiglon","word_nosc":"aiglon","lemma":"aiglon","pos":"NOM"} ,
		{"word":"aiglons","word_nosc":"aiglons","lemma":"aiglon","pos":"NOM"} ,
		{"word":"aigre","word_nosc":"aigre","lemma":"aigre","pos":"NOM"} ,
		{"word":"aigres","word_nosc":"aigres","lemma":"aigre","pos":"NOM"} ,
		{"word":"aigrette","word_nosc":"aigrette","lemma":"aigrette","pos":"NOM"} ,
		{"word":"aigrettes","word_nosc":"aigrettes","lemma":"aigrette","pos":"NOM"} ,
		{"word":"aigreur","word_nosc":"aigreur","lemma":"aigreur","pos":"NOM"} ,
		{"word":"aigreurs","word_nosc":"aigreurs","lemma":"aigreur","pos":"NOM"} ,
		{"word":"aigri","word_nosc":"aigri","lemma":"aigri","pos":"NOM"} ,
		{"word":"aigrie","word_nosc":"aigrie","lemma":"aigri","pos":"NOM"} ,
		{"word":"aigris","word_nosc":"aigris","lemma":"aigri","pos":"NOM"} ,
		{"word":"aigu","word_nosc":"aigu","lemma":"aigu","pos":"NOM"} ,
		{"word":"aiguade","word_nosc":"aiguade","lemma":"aiguade","pos":"NOM"} ,
		{"word":"aiguail","word_nosc":"aiguail","lemma":"aiguail","pos":"NOM"} ,
		{"word":"aigue-marine","word_nosc":"aigue-marine","lemma":"aigue-marine","pos":"NOM"} ,
		{"word":"aigues-marines","word_nosc":"aigues-marines","lemma":"aigue-marine","pos":"NOM"} ,
		{"word":"aiguillage","word_nosc":"aiguillage","lemma":"aiguillage","pos":"NOM"} ,
		{"word":"aiguillages","word_nosc":"aiguillages","lemma":"aiguillage","pos":"NOM"} ,
		{"word":"aiguillat","word_nosc":"aiguillat","lemma":"aiguillat","pos":"NOM"} ,
		{"word":"aiguille","word_nosc":"aiguille","lemma":"aiguille","pos":"NOM"} ,
		{"word":"aiguilles","word_nosc":"aiguilles","lemma":"aiguille","pos":"NOM"} ,
		{"word":"aiguillettes","word_nosc":"aiguillettes","lemma":"aiguillette","pos":"NOM"} ,
		{"word":"aiguilleur","word_nosc":"aiguilleur","lemma":"aiguilleur","pos":"NOM"} ,
		{"word":"aiguilleurs","word_nosc":"aiguilleurs","lemma":"aiguilleur","pos":"NOM"} ,
		{"word":"aiguillon","word_nosc":"aiguillon","lemma":"aiguillon","pos":"NOM"} ,
		{"word":"aiguillons","word_nosc":"aiguillons","lemma":"aiguillon","pos":"NOM"} ,
		{"word":"aiguillée","word_nosc":"aiguillee","lemma":"aiguillée","pos":"NOM"} ,
		{"word":"aiguillées","word_nosc":"aiguillees","lemma":"aiguillée","pos":"NOM"} ,
		{"word":"aiguiseur","word_nosc":"aiguiseur","lemma":"aiguiseur","pos":"NOM"} ,
		{"word":"aiguisoir","word_nosc":"aiguisoir","lemma":"aiguisoir","pos":"NOM"} ,
		{"word":"aiguière","word_nosc":"aiguiere","lemma":"aiguière","pos":"NOM"} ,
		{"word":"aigus","word_nosc":"aigus","lemma":"aigu","pos":"NOM"} ,
		{"word":"ail","word_nosc":"ail","lemma":"ail","pos":"NOM"} ,
		{"word":"aile","word_nosc":"aile","lemma":"aile","pos":"NOM"} ,
		{"word":"aileron","word_nosc":"aileron","lemma":"aileron","pos":"NOM"} ,
		{"word":"ailerons","word_nosc":"ailerons","lemma":"aileron","pos":"NOM"} ,
		{"word":"ailes","word_nosc":"ailes","lemma":"aile","pos":"NOM"} ,
		{"word":"ailette","word_nosc":"ailette","lemma":"ailette","pos":"NOM"} ,
		{"word":"ailettes","word_nosc":"ailettes","lemma":"ailette","pos":"NOM"} ,
		{"word":"ailier","word_nosc":"ailier","lemma":"ailier","pos":"NOM"} ,
		{"word":"ailiers","word_nosc":"ailiers","lemma":"ailier","pos":"NOM"} ,
		{"word":"aimant","word_nosc":"aimant","lemma":"aimant","pos":"NOM"} ,
		{"word":"aimantation","word_nosc":"aimantation","lemma":"aimantation","pos":"NOM"} ,
		{"word":"aimants","word_nosc":"aimants","lemma":"aimant","pos":"NOM"} ,
		{"word":"aimeuse","word_nosc":"aimeuse","lemma":"aimeur","pos":"NOM"} ,
		{"word":"aine","word_nosc":"aine","lemma":"ain","pos":"NOM"} ,
		{"word":"aine","word_nosc":"aine","lemma":"aine","pos":"NOM"} ,
		{"word":"aines","word_nosc":"aines","lemma":"aine","pos":"NOM"} ,
		{"word":"air","word_nosc":"air","lemma":"air","pos":"NOM"} ,
		{"word":"air bag","word_nosc":"air bag","lemma":"air bag","pos":"NOM"} ,
		{"word":"airain","word_nosc":"airain","lemma":"airain","pos":"NOM"} ,
		{"word":"airbag","word_nosc":"airbag","lemma":"airbag","pos":"NOM"} ,
		{"word":"airbags","word_nosc":"airbags","lemma":"airbag","pos":"NOM"} ,
		{"word":"airbus","word_nosc":"airbus","lemma":"airbus","pos":"NOM"} ,
		{"word":"aire","word_nosc":"aire","lemma":"aire","pos":"NOM"} ,
		{"word":"airedale","word_nosc":"airedale","lemma":"airedale","pos":"NOM"} ,
		{"word":"airelle","word_nosc":"airelle","lemma":"airelle","pos":"NOM"} ,
		{"word":"airelles","word_nosc":"airelles","lemma":"airelle","pos":"NOM"} ,
		{"word":"aires","word_nosc":"aires","lemma":"aire","pos":"NOM"} ,
		{"word":"airs","word_nosc":"airs","lemma":"air","pos":"NOM"} ,
		{"word":"ais","word_nosc":"ais","lemma":"ais","pos":"NOM"} ,
		{"word":"aisance","word_nosc":"aisance","lemma":"aisance","pos":"NOM"} ,
		{"word":"aisances","word_nosc":"aisances","lemma":"aisance","pos":"NOM"} ,
		{"word":"aise","word_nosc":"aise","lemma":"aise","pos":"NOM"} ,
		{"word":"aises","word_nosc":"aises","lemma":"aise","pos":"NOM"} ,
		{"word":"aisseau","word_nosc":"aisseau","lemma":"aisseau","pos":"NOM"} ,
		{"word":"aisselle","word_nosc":"aisselle","lemma":"aisselle","pos":"NOM"} ,
		{"word":"aisselles","word_nosc":"aisselles","lemma":"aisselle","pos":"NOM"} ,
		{"word":"aixois","word_nosc":"aixois","lemma":"aixois","pos":"NOM"} ,
		{"word":"ajaccienne","word_nosc":"ajaccienne","lemma":"ajaccienne","pos":"NOM"} ,
		{"word":"ajax","word_nosc":"ajax","lemma":"ajax","pos":"NOM"} ,
		{"word":"ajonc","word_nosc":"ajonc","lemma":"ajonc","pos":"NOM"} ,
		{"word":"ajoncs","word_nosc":"ajoncs","lemma":"ajonc","pos":"NOM"} ,
		{"word":"ajournement","word_nosc":"ajournement","lemma":"ajournement","pos":"NOM"} ,
		{"word":"ajournements","word_nosc":"ajournements","lemma":"ajournement","pos":"NOM"} ,
		{"word":"ajours","word_nosc":"ajours","lemma":"ajour","pos":"NOM"} ,
		{"word":"ajout","word_nosc":"ajout","lemma":"ajout","pos":"NOM"} ,
		{"word":"ajoute","word_nosc":"ajoute","lemma":"ajoute","pos":"NOM"} ,
		{"word":"ajoutes","word_nosc":"ajoutes","lemma":"ajoute","pos":"NOM"} ,
		{"word":"ajouts","word_nosc":"ajouts","lemma":"ajout","pos":"NOM"} ,
		{"word":"ajouté","word_nosc":"ajoute","lemma":"ajouté","pos":"NOM"} ,
		{"word":"ajoutés","word_nosc":"ajoutes","lemma":"ajouté","pos":"NOM"} ,
		{"word":"ajustage","word_nosc":"ajustage","lemma":"ajustage","pos":"NOM"} ,
		{"word":"ajustement","word_nosc":"ajustement","lemma":"ajustement","pos":"NOM"} ,
		{"word":"ajustements","word_nosc":"ajustements","lemma":"ajustement","pos":"NOM"} ,
		{"word":"ajusteur","word_nosc":"ajusteur","lemma":"ajusteur","pos":"NOM"} ,
		{"word":"ajusteurs","word_nosc":"ajusteurs","lemma":"ajusteur","pos":"NOM"} ,
		{"word":"ajusture","word_nosc":"ajusture","lemma":"ajusture","pos":"NOM"} ,
		{"word":"ajutage","word_nosc":"ajutage","lemma":"ajutage","pos":"NOM"} ,
		{"word":"akkadien","word_nosc":"akkadien","lemma":"akkadien","pos":"NOM"} ,
		{"word":"ako","word_nosc":"ako","lemma":"ako","pos":"NOM"} ,
		{"word":"akvavit","word_nosc":"akvavit","lemma":"akvavit","pos":"NOM"} ,
		{"word":"alabandines","word_nosc":"alabandines","lemma":"alabandine","pos":"NOM"} ,
		{"word":"alacrité","word_nosc":"alacrite","lemma":"alacrité","pos":"NOM"} ,
		{"word":"alain","word_nosc":"alain","lemma":"alain","pos":"NOM"} ,
		{"word":"alaise","word_nosc":"alaise","lemma":"alaise","pos":"NOM"} ,
		{"word":"alambic","word_nosc":"alambic","lemma":"alambic","pos":"NOM"} ,
		{"word":"alambics","word_nosc":"alambics","lemma":"alambic","pos":"NOM"} ,
		{"word":"alanguissement","word_nosc":"alanguissement","lemma":"alanguissement","pos":"NOM"} ,
		{"word":"alanguissements","word_nosc":"alanguissements","lemma":"alanguissement","pos":"NOM"} ,
		{"word":"alarme","word_nosc":"alarme","lemma":"alarme","pos":"NOM"} ,
		{"word":"alarmes","word_nosc":"alarmes","lemma":"alarme","pos":"NOM"} ,
		{"word":"alarmisme","word_nosc":"alarmisme","lemma":"alarmisme","pos":"NOM"} ,
		{"word":"alarmiste","word_nosc":"alarmiste","lemma":"alarmiste","pos":"NOM"} ,
		{"word":"alarmistes","word_nosc":"alarmistes","lemma":"alarmiste","pos":"NOM"} ,
		{"word":"alba","word_nosc":"alba","lemma":"alba","pos":"NOM"} ,
		{"word":"albacore","word_nosc":"albacore","lemma":"albacore","pos":"NOM"} ,
		{"word":"albanais","word_nosc":"albanais","lemma":"albanais","pos":"NOM"} ,
		{"word":"albanaise","word_nosc":"albanaise","lemma":"albanais","pos":"NOM"} ,
		{"word":"albatros","word_nosc":"albatros","lemma":"albatros","pos":"NOM"} ,
		{"word":"albe","word_nosc":"albe","lemma":"albe","pos":"NOM"} ,
		{"word":"albertine","word_nosc":"albertine","lemma":"albertine","pos":"NOM"} ,
		{"word":"albigeois","word_nosc":"albigeois","lemma":"albigeois","pos":"NOM"} ,
		{"word":"albinisme","word_nosc":"albinisme","lemma":"albinisme","pos":"NOM"} ,
		{"word":"alboche","word_nosc":"alboche","lemma":"alboche","pos":"NOM"} ,
		{"word":"albugo","word_nosc":"albugo","lemma":"albugo","pos":"NOM"} ,
		{"word":"album","word_nosc":"album","lemma":"album","pos":"NOM"} ,
		{"word":"albumine","word_nosc":"albumine","lemma":"albumine","pos":"NOM"} ,
		{"word":"albumines","word_nosc":"albumines","lemma":"albumine","pos":"NOM"} ,
		{"word":"albums","word_nosc":"albums","lemma":"album","pos":"NOM"} ,
		{"word":"albâtre","word_nosc":"albatre","lemma":"albâtre","pos":"NOM"} ,
		{"word":"albâtres","word_nosc":"albatres","lemma":"albâtre","pos":"NOM"} ,
		{"word":"albène","word_nosc":"albene","lemma":"albène","pos":"NOM"} ,
		{"word":"alcade","word_nosc":"alcade","lemma":"alcade","pos":"NOM"} ,
		{"word":"alcalde","word_nosc":"alcalde","lemma":"alcalde","pos":"NOM"} ,
		{"word":"alcali","word_nosc":"alcali","lemma":"alcali","pos":"NOM"} ,
		{"word":"alcalinité","word_nosc":"alcalinite","lemma":"alcalinité","pos":"NOM"} ,
		{"word":"alcalis","word_nosc":"alcalis","lemma":"alcali","pos":"NOM"} ,
		{"word":"alcalose","word_nosc":"alcalose","lemma":"alcalose","pos":"NOM"} ,
		{"word":"alcaloïde","word_nosc":"alcaloide","lemma":"alcaloïde","pos":"NOM"} ,
		{"word":"alcatraz","word_nosc":"alcatraz","lemma":"alcatraz","pos":"NOM"} ,
		{"word":"alcazar","word_nosc":"alcazar","lemma":"alcazar","pos":"NOM"} ,
		{"word":"alchimie","word_nosc":"alchimie","lemma":"alchimie","pos":"NOM"} ,
		{"word":"alchimies","word_nosc":"alchimies","lemma":"alchimie","pos":"NOM"} ,
		{"word":"alchimiste","word_nosc":"alchimiste","lemma":"alchimiste","pos":"NOM"} ,
		{"word":"alchimistes","word_nosc":"alchimistes","lemma":"alchimiste","pos":"NOM"} ,
		{"word":"alcibiade","word_nosc":"alcibiade","lemma":"alcibiade","pos":"NOM"} ,
		{"word":"alcool","word_nosc":"alcool","lemma":"alcool","pos":"NOM"} ,
		{"word":"alcoolique","word_nosc":"alcoolique","lemma":"alcoolique","pos":"NOM"} ,
		{"word":"alcooliques","word_nosc":"alcooliques","lemma":"alcoolique","pos":"NOM"} ,
		{"word":"alcoolisme","word_nosc":"alcoolisme","lemma":"alcoolisme","pos":"NOM"} ,
		{"word":"alcoolo","word_nosc":"alcoolo","lemma":"alcoolo","pos":"NOM"} ,
		{"word":"alcoolos","word_nosc":"alcoolos","lemma":"alcoolo","pos":"NOM"} ,
		{"word":"alcools","word_nosc":"alcools","lemma":"alcool","pos":"NOM"} ,
		{"word":"alcoolémie","word_nosc":"alcoolemie","lemma":"alcoolémie","pos":"NOM"} ,
		{"word":"alcoomètre","word_nosc":"alcoometre","lemma":"alcoomètre","pos":"NOM"} ,
		{"word":"alcootest","word_nosc":"alcootest","lemma":"alcootest","pos":"NOM"} ,
		{"word":"alcoran","word_nosc":"alcoran","lemma":"alcoran","pos":"NOM"} ,
		{"word":"alcyon","word_nosc":"alcyon","lemma":"alcyon","pos":"NOM"} ,
		{"word":"alcyons","word_nosc":"alcyons","lemma":"alcyon","pos":"NOM"} ,
		{"word":"alcées","word_nosc":"alcees","lemma":"alcée","pos":"NOM"} ,
		{"word":"alcôve","word_nosc":"alcove","lemma":"alcôve","pos":"NOM"} ,
		{"word":"alcôves","word_nosc":"alcoves","lemma":"alcôve","pos":"NOM"} ,
		{"word":"alde","word_nosc":"alde","lemma":"alde","pos":"NOM"} ,
		{"word":"alderman","word_nosc":"alderman","lemma":"alderman","pos":"NOM"} ,
		{"word":"aldol","word_nosc":"aldol","lemma":"aldol","pos":"NOM"} ,
		{"word":"aldéhyde","word_nosc":"aldehyde","lemma":"aldéhyde","pos":"NOM"} ,
		{"word":"ale","word_nosc":"ale","lemma":"ale","pos":"NOM"} ,
		{"word":"alentours","word_nosc":"alentours","lemma":"alentour","pos":"NOM"} ,
		{"word":"aleph","word_nosc":"aleph","lemma":"aleph","pos":"NOM"} ,
		{"word":"alerte","word_nosc":"alerte","lemma":"alerte","pos":"NOM"} ,
		{"word":"alertes","word_nosc":"alertes","lemma":"alerte","pos":"NOM"} ,
		{"word":"alevin","word_nosc":"alevin","lemma":"alevin","pos":"NOM"} ,
		{"word":"alevins","word_nosc":"alevins","lemma":"alevin","pos":"NOM"} ,
		{"word":"alexandra","word_nosc":"alexandra","lemma":"alexandra","pos":"NOM"} ,
		{"word":"alexandrin","word_nosc":"alexandrin","lemma":"alexandrin","pos":"NOM"} ,
		{"word":"alexandrins","word_nosc":"alexandrins","lemma":"alexandrin","pos":"NOM"} ,
		{"word":"alexie","word_nosc":"alexie","lemma":"alexie","pos":"NOM"} ,
		{"word":"alexithymie","word_nosc":"alexithymie","lemma":"alexithymie","pos":"NOM"} ,
		{"word":"alezan","word_nosc":"alezan","lemma":"alezan","pos":"NOM"} ,
		{"word":"alezans","word_nosc":"alezans","lemma":"alezan","pos":"NOM"} ,
		{"word":"alfa","word_nosc":"alfa","lemma":"alfa","pos":"NOM"} ,
		{"word":"alfas","word_nosc":"alfas","lemma":"alfa","pos":"NOM"} ,
		{"word":"alfénides","word_nosc":"alfenides","lemma":"alfénide","pos":"NOM"} ,
		{"word":"algarade","word_nosc":"algarade","lemma":"algarade","pos":"NOM"} ,
		{"word":"algarades","word_nosc":"algarades","lemma":"algarade","pos":"NOM"} ,
		{"word":"algie","word_nosc":"algie","lemma":"algie","pos":"NOM"} ,
		{"word":"algonquin","word_nosc":"algonquin","lemma":"algonquin","pos":"NOM"} ,
		{"word":"algonquines","word_nosc":"algonquines","lemma":"algonquin","pos":"NOM"} ,
		{"word":"algorithme","word_nosc":"algorithme","lemma":"algorithme","pos":"NOM"} ,
		{"word":"algorithmes","word_nosc":"algorithmes","lemma":"algorithme","pos":"NOM"} ,
		{"word":"algue","word_nosc":"algue","lemma":"algue","pos":"NOM"} ,
		{"word":"algues","word_nosc":"algues","lemma":"algue","pos":"NOM"} ,
		{"word":"algèbre","word_nosc":"algebre","lemma":"algèbre","pos":"NOM"} ,
		{"word":"algébriste","word_nosc":"algebriste","lemma":"algébriste","pos":"NOM"} ,
		{"word":"algébristes","word_nosc":"algebristes","lemma":"algébriste","pos":"NOM"} ,
		{"word":"algérien","word_nosc":"algerien","lemma":"algérien","pos":"NOM"} ,
		{"word":"algérienne","word_nosc":"algerienne","lemma":"algérien","pos":"NOM"} ,
		{"word":"algériennes","word_nosc":"algeriennes","lemma":"algérien","pos":"NOM"} ,
		{"word":"algériens","word_nosc":"algeriens","lemma":"algérien","pos":"NOM"} ,
		{"word":"alhambra","word_nosc":"alhambra","lemma":"alhambra","pos":"NOM"} ,
		{"word":"alibi","word_nosc":"alibi","lemma":"alibi","pos":"NOM"} ,
		{"word":"alibis","word_nosc":"alibis","lemma":"alibi","pos":"NOM"} ,
		{"word":"alidade","word_nosc":"alidade","lemma":"alidade","pos":"NOM"} ,
		{"word":"alignement","word_nosc":"alignement","lemma":"alignement","pos":"NOM"} ,
		{"word":"alignements","word_nosc":"alignements","lemma":"alignement","pos":"NOM"} ,
		{"word":"aligot","word_nosc":"aligot","lemma":"aligot","pos":"NOM"} ,
		{"word":"aligoté","word_nosc":"aligote","lemma":"aligoté","pos":"NOM"} ,
		{"word":"alim","word_nosc":"alim","lemma":"alim","pos":"NOM"} ,
		{"word":"aliment","word_nosc":"aliment","lemma":"aliment","pos":"NOM"} ,
		{"word":"alimentation","word_nosc":"alimentation","lemma":"alimentation","pos":"NOM"} ,
		{"word":"alimentations","word_nosc":"alimentations","lemma":"alimentation","pos":"NOM"} ,
		{"word":"aliments","word_nosc":"aliments","lemma":"aliment","pos":"NOM"} ,
		{"word":"alinéa","word_nosc":"alinea","lemma":"alinéa","pos":"NOM"} ,
		{"word":"alinéas","word_nosc":"alineas","lemma":"alinéa","pos":"NOM"} ,
		{"word":"alise","word_nosc":"alise","lemma":"alise","pos":"NOM"} ,
		{"word":"alises","word_nosc":"alises","lemma":"alise","pos":"NOM"} ,
		{"word":"alisier","word_nosc":"alisier","lemma":"alisier","pos":"NOM"} ,
		{"word":"alitement","word_nosc":"alitement","lemma":"alitement","pos":"NOM"} ,
		{"word":"alizé","word_nosc":"alize","lemma":"alizé","pos":"NOM"} ,
		{"word":"alizés","word_nosc":"alizes","lemma":"alizé","pos":"NOM"} ,
		{"word":"aliénation","word_nosc":"alienation","lemma":"aliénation","pos":"NOM"} ,
		{"word":"aliéniste","word_nosc":"alieniste","lemma":"aliéniste","pos":"NOM"} ,
		{"word":"aliéné","word_nosc":"aliene","lemma":"aliéné","pos":"NOM"} ,
		{"word":"aliénée","word_nosc":"alienee","lemma":"aliéné","pos":"NOM"} ,
		{"word":"aliénés","word_nosc":"alienes","lemma":"aliéné","pos":"NOM"} ,
		{"word":"alkali","word_nosc":"alkali","lemma":"alkali","pos":"NOM"} ,
		{"word":"allaitement","word_nosc":"allaitement","lemma":"allaitement","pos":"NOM"} ,
		{"word":"allant","word_nosc":"allant","lemma":"allant","pos":"NOM"} ,
		{"word":"allegro","word_nosc":"allegro","lemma":"allegro","pos":"NOM"} ,
		{"word":"allemagne","word_nosc":"allemagne","lemma":"allemagne","pos":"NOM"} ,
		{"word":"allemand","word_nosc":"allemand","lemma":"allemand","pos":"NOM"} ,
		{"word":"allemande","word_nosc":"allemande","lemma":"allemand","pos":"NOM"} ,
		{"word":"allemandes","word_nosc":"allemandes","lemma":"allemand","pos":"NOM"} ,
		{"word":"allemands","word_nosc":"allemands","lemma":"allemand","pos":"NOM"} ,
		{"word":"aller","word_nosc":"aller","lemma":"aller","pos":"NOM"} ,
		{"word":"aller-retour","word_nosc":"aller-retour","lemma":"aller-retour","pos":"NOM"} ,
		{"word":"allergie","word_nosc":"allergie","lemma":"allergie","pos":"NOM"} ,
		{"word":"allergies","word_nosc":"allergies","lemma":"allergie","pos":"NOM"} ,
		{"word":"allergologie","word_nosc":"allergologie","lemma":"allergologie","pos":"NOM"} ,
		{"word":"allergologue","word_nosc":"allergologue","lemma":"allergologue","pos":"NOM"} ,
		{"word":"allergène","word_nosc":"allergene","lemma":"allergène","pos":"NOM"} ,
		{"word":"allergènes","word_nosc":"allergenes","lemma":"allergène","pos":"NOM"} ,
		{"word":"allers","word_nosc":"allers","lemma":"aller","pos":"NOM"} ,
		{"word":"allers-retours","word_nosc":"allers-retours","lemma":"aller-retour","pos":"NOM"} ,
		{"word":"allez","word_nosc":"allez","lemma":"aller","pos":"NOM"} ,
		{"word":"alliage","word_nosc":"alliage","lemma":"alliage","pos":"NOM"} ,
		{"word":"alliages","word_nosc":"alliages","lemma":"alliage","pos":"NOM"} ,
		{"word":"alliance","word_nosc":"alliance","lemma":"alliance","pos":"NOM"} ,
		{"word":"alliances","word_nosc":"alliances","lemma":"alliance","pos":"NOM"} ,
		{"word":"allier","word_nosc":"allier","lemma":"allier","pos":"NOM"} ,
		{"word":"alligator","word_nosc":"alligator","lemma":"alligator","pos":"NOM"} ,
		{"word":"alligators","word_nosc":"alligators","lemma":"alligator","pos":"NOM"} ,
		{"word":"allitération","word_nosc":"alliteration","lemma":"allitération","pos":"NOM"} ,
		{"word":"allitérations","word_nosc":"alliterations","lemma":"allitération","pos":"NOM"} ,
		{"word":"allium","word_nosc":"allium","lemma":"allium","pos":"NOM"} ,
		{"word":"allié","word_nosc":"allie","lemma":"allié","pos":"NOM"} ,
		{"word":"alliée","word_nosc":"alliee","lemma":"allié","pos":"NOM"} ,
		{"word":"alliées","word_nosc":"alliees","lemma":"allié","pos":"NOM"} ,
		{"word":"alliés","word_nosc":"allies","lemma":"allié","pos":"NOM"} ,
		{"word":"allobarbital","word_nosc":"allobarbital","lemma":"allobarbital","pos":"NOM"} ,
		{"word":"alloc","word_nosc":"alloc","lemma":"alloc","pos":"NOM"} ,
		{"word":"allocataires","word_nosc":"allocataires","lemma":"allocataire","pos":"NOM"} ,
		{"word":"allocation","word_nosc":"allocation","lemma":"allocation","pos":"NOM"} ,
		{"word":"allocations","word_nosc":"allocations","lemma":"allocation","pos":"NOM"} ,
		{"word":"allochtone","word_nosc":"allochtone","lemma":"allochtone","pos":"NOM"} ,
		{"word":"allocs","word_nosc":"allocs","lemma":"alloc","pos":"NOM"} ,
		{"word":"allocution","word_nosc":"allocution","lemma":"allocution","pos":"NOM"} ,
		{"word":"allocutions","word_nosc":"allocutions","lemma":"allocution","pos":"NOM"} ,
		{"word":"allogreffe","word_nosc":"allogreffe","lemma":"allogreffe","pos":"NOM"} ,
		{"word":"allonge","word_nosc":"allonge","lemma":"allonge","pos":"NOM"} ,
		{"word":"allongement","word_nosc":"allongement","lemma":"allongement","pos":"NOM"} ,
		{"word":"allonges","word_nosc":"allonges","lemma":"allonge","pos":"NOM"} ,
		{"word":"allopathie","word_nosc":"allopathie","lemma":"allopathie","pos":"NOM"} ,
		{"word":"allumage","word_nosc":"allumage","lemma":"allumage","pos":"NOM"} ,
		{"word":"allume-cigare","word_nosc":"allume-cigare","lemma":"allume-cigare","pos":"NOM"} ,
		{"word":"allume-cigares","word_nosc":"allume-cigares","lemma":"allume-cigares","pos":"NOM"} ,
		{"word":"allume-feu","word_nosc":"allume-feu","lemma":"allume-feu","pos":"NOM"} ,
		{"word":"allume-gaz","word_nosc":"allume-gaz","lemma":"allume-gaz","pos":"NOM"} ,
		{"word":"allumette","word_nosc":"allumette","lemma":"allumette","pos":"NOM"} ,
		{"word":"allumettes","word_nosc":"allumettes","lemma":"allumette","pos":"NOM"} ,
		{"word":"allumeur","word_nosc":"allumeur","lemma":"allumeur","pos":"NOM"} ,
		{"word":"allumeurs","word_nosc":"allumeurs","lemma":"allumeur","pos":"NOM"} ,
		{"word":"allumeuse","word_nosc":"allumeuse","lemma":"allumeur","pos":"NOM"} ,
		{"word":"allumeuses","word_nosc":"allumeuses","lemma":"allumeur","pos":"NOM"} ,
		{"word":"allumoir","word_nosc":"allumoir","lemma":"allumoir","pos":"NOM"} ,
		{"word":"allumé","word_nosc":"allume","lemma":"allumé","pos":"NOM"} ,
		{"word":"allumée","word_nosc":"allumee","lemma":"allumé","pos":"NOM"} ,
		{"word":"allumées","word_nosc":"allumees","lemma":"allumé","pos":"NOM"} ,
		{"word":"allumés","word_nosc":"allumes","lemma":"allumé","pos":"NOM"} ,
		{"word":"allure","word_nosc":"allure","lemma":"allure","pos":"NOM"} ,
		{"word":"allures","word_nosc":"allures","lemma":"allure","pos":"NOM"} ,
		{"word":"allusion","word_nosc":"allusion","lemma":"allusion","pos":"NOM"} ,
		{"word":"allusions","word_nosc":"allusions","lemma":"allusion","pos":"NOM"} ,
		{"word":"alluvion","word_nosc":"alluvion","lemma":"alluvion","pos":"NOM"} ,
		{"word":"alluvions","word_nosc":"alluvions","lemma":"alluvion","pos":"NOM"} ,
		{"word":"allègement","word_nosc":"allegement","lemma":"allègement","pos":"NOM"} ,
		{"word":"allèle","word_nosc":"allele","lemma":"allèle","pos":"NOM"} ,
		{"word":"allée","word_nosc":"allee","lemma":"allée","pos":"NOM"} ,
		{"word":"allées","word_nosc":"allees","lemma":"allée","pos":"NOM"} ,
		{"word":"allégation","word_nosc":"allegation","lemma":"allégation","pos":"NOM"} ,
		{"word":"allégations","word_nosc":"allegations","lemma":"allégation","pos":"NOM"} ,
		{"word":"allégeance","word_nosc":"allegeance","lemma":"allégeance","pos":"NOM"} ,
		{"word":"allégeances","word_nosc":"allegeances","lemma":"allégeance","pos":"NOM"} ,
		{"word":"allégement","word_nosc":"allegement","lemma":"allégement","pos":"NOM"} ,
		{"word":"allégements","word_nosc":"allegements","lemma":"allégement","pos":"NOM"} ,
		{"word":"allégorie","word_nosc":"allegorie","lemma":"allégorie","pos":"NOM"} ,
		{"word":"allégories","word_nosc":"allegories","lemma":"allégorie","pos":"NOM"} ,
		{"word":"allégresse","word_nosc":"allegresse","lemma":"allégresse","pos":"NOM"} ,
		{"word":"allégresses","word_nosc":"allegresses","lemma":"allégresse","pos":"NOM"} ,
		{"word":"alléluia","word_nosc":"alleluia","lemma":"alléluia","pos":"NOM"} ,
		{"word":"alléluias","word_nosc":"alleluias","lemma":"alléluia","pos":"NOM"} ,
		{"word":"alma","word_nosc":"alma","lemma":"alma","pos":"NOM"} ,
		{"word":"almanach","word_nosc":"almanach","lemma":"almanach","pos":"NOM"} ,
		{"word":"almanachs","word_nosc":"almanachs","lemma":"almanach","pos":"NOM"} ,
		{"word":"almohade","word_nosc":"almohade","lemma":"almohade","pos":"NOM"} ,
		{"word":"almohades","word_nosc":"almohades","lemma":"almohade","pos":"NOM"} ,
		{"word":"almée","word_nosc":"almee","lemma":"almée","pos":"NOM"} ,
		{"word":"almées","word_nosc":"almees","lemma":"almée","pos":"NOM"} ,
		{"word":"aloi","word_nosc":"aloi","lemma":"aloi","pos":"NOM"} ,
		{"word":"alopécie","word_nosc":"alopecie","lemma":"alopécie","pos":"NOM"} ,
		{"word":"alose","word_nosc":"alose","lemma":"alose","pos":"NOM"} ,
		{"word":"aloses","word_nosc":"aloses","lemma":"alose","pos":"NOM"} ,
		{"word":"alouette","word_nosc":"alouette","lemma":"alouette","pos":"NOM"} ,
		{"word":"alouettes","word_nosc":"alouettes","lemma":"alouette","pos":"NOM"} ,
		{"word":"alourdissement","word_nosc":"alourdissement","lemma":"alourdissement","pos":"NOM"} ,
		{"word":"aloyau","word_nosc":"aloyau","lemma":"aloyau","pos":"NOM"} ,
		{"word":"aloès","word_nosc":"aloes","lemma":"aloès","pos":"NOM"} ,
		{"word":"alpaga","word_nosc":"alpaga","lemma":"alpaga","pos":"NOM"} ,
		{"word":"alpage","word_nosc":"alpage","lemma":"alpage","pos":"NOM"} ,
		{"word":"alpages","word_nosc":"alpages","lemma":"alpage","pos":"NOM"} ,
		{"word":"alpe","word_nosc":"alpe","lemma":"alpe","pos":"NOM"} ,
		{"word":"alpenstock","word_nosc":"alpenstock","lemma":"alpenstock","pos":"NOM"} ,
		{"word":"alpes","word_nosc":"alpes","lemma":"alpe","pos":"NOM"} ,
		{"word":"alpha","word_nosc":"alpha","lemma":"alpha","pos":"NOM"} ,
		{"word":"alphabet","word_nosc":"alphabet","lemma":"alphabet","pos":"NOM"} ,
		{"word":"alphabets","word_nosc":"alphabets","lemma":"alphabet","pos":"NOM"} ,
		{"word":"alphabétisation","word_nosc":"alphabetisation","lemma":"alphabétisation","pos":"NOM"} ,
		{"word":"alpinisme","word_nosc":"alpinisme","lemma":"alpinisme","pos":"NOM"} ,
		{"word":"alpiniste","word_nosc":"alpiniste","lemma":"alpiniste","pos":"NOM"} ,
		{"word":"alpinistes","word_nosc":"alpinistes","lemma":"alpiniste","pos":"NOM"} ,
		{"word":"alsacien","word_nosc":"alsacien","lemma":"alsacien","pos":"NOM"} ,
		{"word":"alsacienne","word_nosc":"alsacienne","lemma":"alsacien","pos":"NOM"} ,
		{"word":"alsaciennes","word_nosc":"alsaciennes","lemma":"alsacien","pos":"NOM"} ,
		{"word":"alsaciens","word_nosc":"alsaciens","lemma":"alsacien","pos":"NOM"} ,
		{"word":"alstonia","word_nosc":"alstonia","lemma":"alstonia","pos":"NOM"} ,
		{"word":"alter ego","word_nosc":"alter ego","lemma":"alter ego","pos":"NOM"} ,
		{"word":"altercation","word_nosc":"altercation","lemma":"altercation","pos":"NOM"} ,
		{"word":"altercations","word_nosc":"altercations","lemma":"altercation","pos":"NOM"} ,
		{"word":"alternance","word_nosc":"alternance","lemma":"alternance","pos":"NOM"} ,
		{"word":"alternances","word_nosc":"alternances","lemma":"alternance","pos":"NOM"} ,
		{"word":"alternateur","word_nosc":"alternateur","lemma":"alternateur","pos":"NOM"} ,
		{"word":"alternative","word_nosc":"alternative","lemma":"alternative","pos":"NOM"} ,
		{"word":"alternatives","word_nosc":"alternatives","lemma":"alternative","pos":"NOM"} ,
		{"word":"altesse","word_nosc":"altesse","lemma":"altesse","pos":"NOM"} ,
		{"word":"altesses","word_nosc":"altesses","lemma":"altesse","pos":"NOM"} ,
		{"word":"althaea","word_nosc":"althaea","lemma":"althaea","pos":"NOM"} ,
		{"word":"altimètre","word_nosc":"altimetre","lemma":"altimètre","pos":"NOM"} ,
		{"word":"altimètres","word_nosc":"altimetres","lemma":"altimètre","pos":"NOM"} ,
		{"word":"altitude","word_nosc":"altitude","lemma":"altitude","pos":"NOM"} ,
		{"word":"altitudes","word_nosc":"altitudes","lemma":"altitude","pos":"NOM"} ,
		{"word":"alto","word_nosc":"alto","lemma":"alto","pos":"NOM"} ,
		{"word":"altos","word_nosc":"altos","lemma":"alto","pos":"NOM"} ,
		{"word":"altruisme","word_nosc":"altruisme","lemma":"altruisme","pos":"NOM"} ,
		{"word":"altruiste","word_nosc":"altruiste","lemma":"altruiste","pos":"NOM"} ,
		{"word":"altuglas","word_nosc":"altuglas","lemma":"altuglas","pos":"NOM"} ,
		{"word":"altération","word_nosc":"alteration","lemma":"altération","pos":"NOM"} ,
		{"word":"altérations","word_nosc":"alterations","lemma":"altération","pos":"NOM"} ,
		{"word":"altérité","word_nosc":"alterite","lemma":"altérité","pos":"NOM"} ,
		{"word":"alu","word_nosc":"alu","lemma":"alu","pos":"NOM"} ,
		{"word":"aludes","word_nosc":"aludes","lemma":"alude","pos":"NOM"} ,
		{"word":"aluette","word_nosc":"aluette","lemma":"aluette","pos":"NOM"} ,
		{"word":"aluminium","word_nosc":"aluminium","lemma":"aluminium","pos":"NOM"} ,
		{"word":"alun","word_nosc":"alun","lemma":"alun","pos":"NOM"} ,
		{"word":"alunissage","word_nosc":"alunissage","lemma":"alunissage","pos":"NOM"} ,
		{"word":"alunissages","word_nosc":"alunissages","lemma":"alunissage","pos":"NOM"} ,
		{"word":"alvar","word_nosc":"alvar","lemma":"alvar","pos":"NOM"} ,
		{"word":"alvéole","word_nosc":"alveole","lemma":"alvéole","pos":"NOM"} ,
		{"word":"alvéoles","word_nosc":"alveoles","lemma":"alvéole","pos":"NOM"} ,
		{"word":"alysse","word_nosc":"alysse","lemma":"alysse","pos":"NOM"} ,
		{"word":"alène","word_nosc":"alene","lemma":"alène","pos":"NOM"} ,
		{"word":"alèse","word_nosc":"alese","lemma":"alèse","pos":"NOM"} ,
		{"word":"alèze","word_nosc":"aleze","lemma":"alèze","pos":"NOM"} ,
		{"word":"aléa","word_nosc":"alea","lemma":"aléa","pos":"NOM"} ,
		{"word":"aléas","word_nosc":"aleas","lemma":"aléa","pos":"NOM"} ,
		{"word":"amabilité","word_nosc":"amabilite","lemma":"amabilité","pos":"NOM"} ,
		{"word":"amabilités","word_nosc":"amabilites","lemma":"amabilité","pos":"NOM"} ,
		{"word":"amadou","word_nosc":"amadou","lemma":"amadou","pos":"NOM"} ,
		{"word":"amaigrissement","word_nosc":"amaigrissement","lemma":"amaigrissement","pos":"NOM"} ,
		{"word":"amalgame","word_nosc":"amalgame","lemma":"amalgame","pos":"NOM"} ,
		{"word":"amalgames","word_nosc":"amalgames","lemma":"amalgame","pos":"NOM"} ,
		{"word":"aman","word_nosc":"aman","lemma":"aman","pos":"NOM"} ,
		{"word":"amande","word_nosc":"amande","lemma":"amande","pos":"NOM"} ,
		{"word":"amandes","word_nosc":"amandes","lemma":"amande","pos":"NOM"} ,
		{"word":"amandier","word_nosc":"amandier","lemma":"amandier","pos":"NOM"} ,
		{"word":"amandiers","word_nosc":"amandiers","lemma":"amandier","pos":"NOM"} ,
		{"word":"amandine","word_nosc":"amandine","lemma":"amandine","pos":"NOM"} ,
		{"word":"amandines","word_nosc":"amandines","lemma":"amandine","pos":"NOM"} ,
		{"word":"amanite","word_nosc":"amanite","lemma":"amanite","pos":"NOM"} ,
		{"word":"amanites","word_nosc":"amanites","lemma":"amanite","pos":"NOM"} ,
		{"word":"amant","word_nosc":"amant","lemma":"amant","pos":"NOM"} ,
		{"word":"amante","word_nosc":"amante","lemma":"amante","pos":"NOM"} ,
		{"word":"amantes","word_nosc":"amantes","lemma":"amante","pos":"NOM"} ,
		{"word":"amants","word_nosc":"amants","lemma":"amant","pos":"NOM"} ,
		{"word":"amarante","word_nosc":"amarante","lemma":"amarante","pos":"NOM"} ,
		{"word":"amarantes","word_nosc":"amarantes","lemma":"amarante","pos":"NOM"} ,
		{"word":"amaro","word_nosc":"amaro","lemma":"amaro","pos":"NOM"} ,
		{"word":"amarrage","word_nosc":"amarrage","lemma":"amarrage","pos":"NOM"} ,
		{"word":"amarrages","word_nosc":"amarrages","lemma":"amarrage","pos":"NOM"} ,
		{"word":"amarre","word_nosc":"amarre","lemma":"amarre","pos":"NOM"} ,
		{"word":"amarres","word_nosc":"amarres","lemma":"amarre","pos":"NOM"} ,
		{"word":"amaryllis","word_nosc":"amaryllis","lemma":"amaryllis","pos":"NOM"} ,
		{"word":"amas","word_nosc":"amas","lemma":"amas","pos":"NOM"} ,
		{"word":"amateur","word_nosc":"amateur","lemma":"amateur","pos":"NOM"} ,
		{"word":"amateurisme","word_nosc":"amateurisme","lemma":"amateurisme","pos":"NOM"} ,
		{"word":"amateurs","word_nosc":"amateurs","lemma":"amateur","pos":"NOM"} ,
		{"word":"amatrice","word_nosc":"amatrice","lemma":"amateur","pos":"NOM"} ,
		{"word":"amaurose","word_nosc":"amaurose","lemma":"amaurose","pos":"NOM"} ,
		{"word":"amazone","word_nosc":"amazone","lemma":"amazone","pos":"NOM"} ,
		{"word":"amazones","word_nosc":"amazones","lemma":"amazone","pos":"NOM"} ,
		{"word":"ambages","word_nosc":"ambages","lemma":"ambages","pos":"NOM"} ,
		{"word":"ambassade","word_nosc":"ambassade","lemma":"ambassade","pos":"NOM"} ,
		{"word":"ambassades","word_nosc":"ambassades","lemma":"ambassade","pos":"NOM"} ,
		{"word":"ambassadeur","word_nosc":"ambassadeur","lemma":"ambassadeur","pos":"NOM"} ,
		{"word":"ambassadeurs","word_nosc":"ambassadeurs","lemma":"ambassadeur","pos":"NOM"} ,
		{"word":"ambassadrice","word_nosc":"ambassadrice","lemma":"ambassadeur","pos":"NOM"} ,
		{"word":"ambassadrices","word_nosc":"ambassadrices","lemma":"ambassadeur","pos":"NOM"} ,
		{"word":"ambiance","word_nosc":"ambiance","lemma":"ambiance","pos":"NOM"} ,
		{"word":"ambiances","word_nosc":"ambiances","lemma":"ambiance","pos":"NOM"} ,
		{"word":"ambidextre","word_nosc":"ambidextre","lemma":"ambidextre","pos":"NOM"} ,
		{"word":"ambiguïté","word_nosc":"ambiguite","lemma":"ambiguïté","pos":"NOM"} ,
		{"word":"ambiguïtés","word_nosc":"ambiguites","lemma":"ambiguïté","pos":"NOM"} ,
		{"word":"ambitieuse","word_nosc":"ambitieuse","lemma":"ambitieux","pos":"NOM"} ,
		{"word":"ambitieuses","word_nosc":"ambitieuses","lemma":"ambitieuse","pos":"NOM"} ,
		{"word":"ambitieux","word_nosc":"ambitieux","lemma":"ambitieux","pos":"NOM"} ,
		{"word":"ambition","word_nosc":"ambition","lemma":"ambition","pos":"NOM"} ,
		{"word":"ambitions","word_nosc":"ambitions","lemma":"ambition","pos":"NOM"} ,
		{"word":"ambivalence","word_nosc":"ambivalence","lemma":"ambivalence","pos":"NOM"} ,
		{"word":"ambivalences","word_nosc":"ambivalences","lemma":"ambivalence","pos":"NOM"} ,
		{"word":"amble","word_nosc":"amble","lemma":"amble","pos":"NOM"} ,
		{"word":"amboine","word_nosc":"amboine","lemma":"amboine","pos":"NOM"} ,
		{"word":"ambon","word_nosc":"ambon","lemma":"ambon","pos":"NOM"} ,
		{"word":"ambre","word_nosc":"ambre","lemma":"ambre","pos":"NOM"} ,
		{"word":"ambroisie","word_nosc":"ambroisie","lemma":"ambroisie","pos":"NOM"} ,
		{"word":"ambulance","word_nosc":"ambulance","lemma":"ambulance","pos":"NOM"} ,
		{"word":"ambulances","word_nosc":"ambulances","lemma":"ambulance","pos":"NOM"} ,
		{"word":"ambulancier","word_nosc":"ambulancier","lemma":"ambulancier","pos":"NOM"} ,
		{"word":"ambulanciers","word_nosc":"ambulanciers","lemma":"ambulancier","pos":"NOM"} ,
		{"word":"ambulancière","word_nosc":"ambulanciere","lemma":"ambulancier","pos":"NOM"} ,
		{"word":"ambulancières","word_nosc":"ambulancieres","lemma":"ambulancier","pos":"NOM"} ,
		{"word":"ambulant","word_nosc":"ambulant","lemma":"ambulant","pos":"NOM"} ,
		{"word":"ambulants","word_nosc":"ambulants","lemma":"ambulant","pos":"NOM"} ,
		{"word":"amen","word_nosc":"amen","lemma":"amen","pos":"NOM"} ,
		{"word":"amende","word_nosc":"amende","lemma":"amende","pos":"NOM"} ,
		{"word":"amendement","word_nosc":"amendement","lemma":"amendement","pos":"NOM"} ,
		{"word":"amendements","word_nosc":"amendements","lemma":"amendement","pos":"NOM"} ,
		{"word":"amendes","word_nosc":"amendes","lemma":"amende","pos":"NOM"} ,
		{"word":"amenuisement","word_nosc":"amenuisement","lemma":"amenuisement","pos":"NOM"} ,
		{"word":"amenée","word_nosc":"amenee","lemma":"amenée","pos":"NOM"} ,
		{"word":"amer","word_nosc":"amer","lemma":"amer","pos":"NOM"} ,
		{"word":"amerlo","word_nosc":"amerlo","lemma":"amerlo","pos":"NOM"} ,
		{"word":"amerloque","word_nosc":"amerloque","lemma":"amerloque","pos":"NOM"} ,
		{"word":"amerloques","word_nosc":"amerloques","lemma":"amerloque","pos":"NOM"} ,
		{"word":"amerlos","word_nosc":"amerlos","lemma":"amerlo","pos":"NOM"} ,
		{"word":"amerrissage","word_nosc":"amerrissage","lemma":"amerrissage","pos":"NOM"} ,
		{"word":"amers","word_nosc":"amers","lemma":"amer","pos":"NOM"} ,
		{"word":"amertume","word_nosc":"amertume","lemma":"amertume","pos":"NOM"} ,
		{"word":"amertumes","word_nosc":"amertumes","lemma":"amertume","pos":"NOM"} ,
		{"word":"ameublement","word_nosc":"ameublement","lemma":"ameublement","pos":"NOM"} ,
		{"word":"ameublements","word_nosc":"ameublements","lemma":"ameublement","pos":"NOM"} ,
		{"word":"amharique","word_nosc":"amharique","lemma":"amharique","pos":"NOM"} ,
		{"word":"ami","word_nosc":"ami","lemma":"ami","pos":"NOM"} ,
		{"word":"amiante","word_nosc":"amiante","lemma":"amiante","pos":"NOM"} ,
		{"word":"amibe","word_nosc":"amibe","lemma":"amibe","pos":"NOM"} ,
		{"word":"amibes","word_nosc":"amibes","lemma":"amibe","pos":"NOM"} ,
		{"word":"amicale","word_nosc":"amicale","lemma":"amicale","pos":"NOM"} ,
		{"word":"amicales","word_nosc":"amicales","lemma":"amical","pos":"NOM"} ,
		{"word":"amicales","word_nosc":"amicales","lemma":"amicale","pos":"NOM"} ,
		{"word":"amicalités","word_nosc":"amicalites","lemma":"amicalité","pos":"NOM"} ,
		{"word":"amidon","word_nosc":"amidon","lemma":"amidon","pos":"NOM"} ,
		{"word":"amie","word_nosc":"amie","lemma":"ami","pos":"NOM"} ,
		{"word":"amies","word_nosc":"amies","lemma":"ami","pos":"NOM"} ,
		{"word":"amigo","word_nosc":"amigo","lemma":"amigo","pos":"NOM"} ,
		{"word":"amigos","word_nosc":"amigos","lemma":"amigo","pos":"NOM"} ,
		{"word":"amincissant","word_nosc":"amincissant","lemma":"amincissant","pos":"NOM"} ,
		{"word":"amincissants","word_nosc":"amincissants","lemma":"amincissant","pos":"NOM"} ,
		{"word":"amincissement","word_nosc":"amincissement","lemma":"amincissement","pos":"NOM"} ,
		{"word":"amine","word_nosc":"amine","lemma":"amine","pos":"NOM"} ,
		{"word":"aminoacides","word_nosc":"aminoacides","lemma":"aminoacide","pos":"NOM"} ,
		{"word":"aminophylline","word_nosc":"aminophylline","lemma":"aminophylline","pos":"NOM"} ,
		{"word":"amiral","word_nosc":"amiral","lemma":"amiral","pos":"NOM"} ,
		{"word":"amirauté","word_nosc":"amiraute","lemma":"amirauté","pos":"NOM"} ,
		{"word":"amirautés","word_nosc":"amirautes","lemma":"amirauté","pos":"NOM"} ,
		{"word":"amiraux","word_nosc":"amiraux","lemma":"amiral","pos":"NOM"} ,
		{"word":"amis","word_nosc":"amis","lemma":"ami","pos":"NOM"} ,
		{"word":"amitié","word_nosc":"amitie","lemma":"amitié","pos":"NOM"} ,
		{"word":"amitiés","word_nosc":"amities","lemma":"amitié","pos":"NOM"} ,
		{"word":"ammoniac","word_nosc":"ammoniac","lemma":"ammoniac","pos":"NOM"} ,
		{"word":"ammoniaque","word_nosc":"ammoniaque","lemma":"ammoniaque","pos":"NOM"} ,
		{"word":"ammonite","word_nosc":"ammonite","lemma":"ammonite","pos":"NOM"} ,
		{"word":"ammonitrate","word_nosc":"ammonitrate","lemma":"ammonitrate","pos":"NOM"} ,
		{"word":"ammonium","word_nosc":"ammonium","lemma":"ammonium","pos":"NOM"} ,
		{"word":"amniocentèse","word_nosc":"amniocentese","lemma":"amniocentèse","pos":"NOM"} ,
		{"word":"amnios","word_nosc":"amnios","lemma":"amnios","pos":"NOM"} ,
		{"word":"amnistie","word_nosc":"amnistie","lemma":"amnistie","pos":"NOM"} ,
		{"word":"amnisties","word_nosc":"amnisties","lemma":"amnistie","pos":"NOM"} ,
		{"word":"amnistié","word_nosc":"amnistie","lemma":"amnistié","pos":"NOM"} ,
		{"word":"amnistiée","word_nosc":"amnistiee","lemma":"amnistié","pos":"NOM"} ,
		{"word":"amnésie","word_nosc":"amnesie","lemma":"amnésie","pos":"NOM"} ,
		{"word":"amnésies","word_nosc":"amnesies","lemma":"amnésie","pos":"NOM"} ,
		{"word":"amnésique","word_nosc":"amnesique","lemma":"amnésique","pos":"NOM"} ,
		{"word":"amnésiques","word_nosc":"amnesiques","lemma":"amnésique","pos":"NOM"} ,
		{"word":"amoindrissement","word_nosc":"amoindrissement","lemma":"amoindrissement","pos":"NOM"} ,
		{"word":"amok","word_nosc":"amok","lemma":"amok","pos":"NOM"} ,
		{"word":"amollissement","word_nosc":"amollissement","lemma":"amollissement","pos":"NOM"} ,
		{"word":"amoncellement","word_nosc":"amoncellement","lemma":"amoncellement","pos":"NOM"} ,
		{"word":"amoncellements","word_nosc":"amoncellements","lemma":"amoncellement","pos":"NOM"} ,
		{"word":"amont","word_nosc":"amont","lemma":"amont","pos":"NOM"} ,
		{"word":"amontillado","word_nosc":"amontillado","lemma":"amontillado","pos":"NOM"} ,
		{"word":"amoraliste","word_nosc":"amoraliste","lemma":"amoraliste","pos":"NOM"} ,
		{"word":"amoralité","word_nosc":"amoralite","lemma":"amoralité","pos":"NOM"} ,
		{"word":"amorce","word_nosc":"amorce","lemma":"amorce","pos":"NOM"} ,
		{"word":"amorces","word_nosc":"amorces","lemma":"amorce","pos":"NOM"} ,
		{"word":"amorti","word_nosc":"amorti","lemma":"amorti","pos":"NOM"} ,
		{"word":"amortis","word_nosc":"amortis","lemma":"amorti","pos":"NOM"} ,
		{"word":"amortissement","word_nosc":"amortissement","lemma":"amortissement","pos":"NOM"} ,
		{"word":"amortisseur","word_nosc":"amortisseur","lemma":"amortisseur","pos":"NOM"} ,
		{"word":"amortisseurs","word_nosc":"amortisseurs","lemma":"amortisseur","pos":"NOM"} ,
		{"word":"amorçage","word_nosc":"amorcage","lemma":"amorçage","pos":"NOM"} ,
		{"word":"amorçoir","word_nosc":"amorcoir","lemma":"amorçoir","pos":"NOM"} ,
		{"word":"amour","word_nosc":"amour","lemma":"amour","pos":"NOM"} ,
		{"word":"amour-goût","word_nosc":"amour-gout","lemma":"amour-goût","pos":"NOM"} ,
		{"word":"amour-passion","word_nosc":"amour-passion","lemma":"amour-passion","pos":"NOM"} ,
		{"word":"amour-propre","word_nosc":"amour-propre","lemma":"amour-propre","pos":"NOM"} ,
		{"word":"amourette","word_nosc":"amourette","lemma":"amourette","pos":"NOM"} ,
		{"word":"amourettes","word_nosc":"amourettes","lemma":"amourette","pos":"NOM"} ,
		{"word":"amoureuse","word_nosc":"amoureuse","lemma":"amoureux","pos":"NOM"} ,
		{"word":"amoureuses","word_nosc":"amoureuses","lemma":"amoureux","pos":"NOM"} ,
		{"word":"amoureux","word_nosc":"amoureux","lemma":"amoureux","pos":"NOM"} ,
		{"word":"amours","word_nosc":"amours","lemma":"amour","pos":"NOM"} ,
		{"word":"amours-propres","word_nosc":"amours-propres","lemma":"amour-propre","pos":"NOM"} ,
		{"word":"amphi","word_nosc":"amphi","lemma":"amphi","pos":"NOM"} ,
		{"word":"amphibie","word_nosc":"amphibie","lemma":"amphibie","pos":"NOM"} ,
		{"word":"amphibien","word_nosc":"amphibien","lemma":"amphibien","pos":"NOM"} ,
		{"word":"amphibiens","word_nosc":"amphibiens","lemma":"amphibien","pos":"NOM"} ,
		{"word":"amphibies","word_nosc":"amphibies","lemma":"amphibie","pos":"NOM"} ,
		{"word":"amphigouri","word_nosc":"amphigouri","lemma":"amphigouri","pos":"NOM"} ,
		{"word":"amphigouris","word_nosc":"amphigouris","lemma":"amphigouri","pos":"NOM"} ,
		{"word":"amphis","word_nosc":"amphis","lemma":"amphi","pos":"NOM"} ,
		{"word":"amphithéâtre","word_nosc":"amphitheatre","lemma":"amphithéâtre","pos":"NOM"} ,
		{"word":"amphithéâtres","word_nosc":"amphitheatres","lemma":"amphithéâtre","pos":"NOM"} ,
		{"word":"amphitrite","word_nosc":"amphitrite","lemma":"amphitrite","pos":"NOM"} ,
		{"word":"amphitryon","word_nosc":"amphitryon","lemma":"amphitryon","pos":"NOM"} ,
		{"word":"amphore","word_nosc":"amphore","lemma":"amphore","pos":"NOM"} ,
		{"word":"amphores","word_nosc":"amphores","lemma":"amphore","pos":"NOM"} ,
		{"word":"amphés","word_nosc":"amphes","lemma":"amphé","pos":"NOM"} ,
		{"word":"amphétamine","word_nosc":"amphetamine","lemma":"amphétamine","pos":"NOM"} ,
		{"word":"amphétamines","word_nosc":"amphetamines","lemma":"amphétamine","pos":"NOM"} ,
		{"word":"ampleur","word_nosc":"ampleur","lemma":"ampleur","pos":"NOM"} ,
		{"word":"ampleurs","word_nosc":"ampleurs","lemma":"ampleur","pos":"NOM"} ,
		{"word":"ampli","word_nosc":"ampli","lemma":"ampli","pos":"NOM"} ,
		{"word":"ampli-tuner","word_nosc":"ampli-tuner","lemma":"ampli-tuner","pos":"NOM"} ,
		{"word":"amplificateur","word_nosc":"amplificateur","lemma":"amplificateur","pos":"NOM"} ,
		{"word":"amplificateurs","word_nosc":"amplificateurs","lemma":"amplificateur","pos":"NOM"} ,
		{"word":"amplification","word_nosc":"amplification","lemma":"amplification","pos":"NOM"} ,
		{"word":"amplifications","word_nosc":"amplifications","lemma":"amplification","pos":"NOM"} ,
		{"word":"amplis","word_nosc":"amplis","lemma":"ampli","pos":"NOM"} ,
		{"word":"amplitude","word_nosc":"amplitude","lemma":"amplitude","pos":"NOM"} ,
		{"word":"ampoule","word_nosc":"ampoule","lemma":"ampoule","pos":"NOM"} ,
		{"word":"ampoules","word_nosc":"ampoules","lemma":"ampoule","pos":"NOM"} ,
		{"word":"amputation","word_nosc":"amputation","lemma":"amputation","pos":"NOM"} ,
		{"word":"amputations","word_nosc":"amputations","lemma":"amputation","pos":"NOM"} ,
		{"word":"amputé","word_nosc":"ampute","lemma":"amputé","pos":"NOM"} ,
		{"word":"amputés","word_nosc":"amputes","lemma":"amputé","pos":"NOM"} ,
		{"word":"ampère","word_nosc":"ampere","lemma":"ampère","pos":"NOM"} ,
		{"word":"ampèremètre","word_nosc":"amperemetre","lemma":"ampèremètre","pos":"NOM"} ,
		{"word":"ampères","word_nosc":"amperes","lemma":"ampère","pos":"NOM"} ,
		{"word":"ampélopsis","word_nosc":"ampelopsis","lemma":"ampélopsis","pos":"NOM"} ,
		{"word":"ampérage","word_nosc":"amperage","lemma":"ampérage","pos":"NOM"} ,
		{"word":"amulette","word_nosc":"amulette","lemma":"amulette","pos":"NOM"} ,
		{"word":"amulettes","word_nosc":"amulettes","lemma":"amulette","pos":"NOM"} ,
		{"word":"amure","word_nosc":"amure","lemma":"amure","pos":"NOM"} ,
		{"word":"amures","word_nosc":"amures","lemma":"amure","pos":"NOM"} ,
		{"word":"amuse-bouche","word_nosc":"amuse-bouche","lemma":"amuse-bouche","pos":"NOM"} ,
		{"word":"amuse-bouches","word_nosc":"amuse-bouches","lemma":"amuse-bouche","pos":"NOM"} ,
		{"word":"amuse-gueule","word_nosc":"amuse-gueule","lemma":"amuse-gueule","pos":"NOM"} ,
		{"word":"amuse-gueules","word_nosc":"amuse-gueules","lemma":"amuse-gueule","pos":"NOM"} ,
		{"word":"amusement","word_nosc":"amusement","lemma":"amusement","pos":"NOM"} ,
		{"word":"amusements","word_nosc":"amusements","lemma":"amusement","pos":"NOM"} ,
		{"word":"amusette","word_nosc":"amusette","lemma":"amusette","pos":"NOM"} ,
		{"word":"amusettes","word_nosc":"amusettes","lemma":"amusette","pos":"NOM"} ,
		{"word":"amuseur","word_nosc":"amuseur","lemma":"amuseur","pos":"NOM"} ,
		{"word":"amuseurs","word_nosc":"amuseurs","lemma":"amuseur","pos":"NOM"} ,
		{"word":"amygdale","word_nosc":"amygdale","lemma":"amygdale","pos":"NOM"} ,
		{"word":"amygdalectomie","word_nosc":"amygdalectomie","lemma":"amygdalectomie","pos":"NOM"} ,
		{"word":"amygdalectomies","word_nosc":"amygdalectomies","lemma":"amygdalectomie","pos":"NOM"} ,
		{"word":"amygdales","word_nosc":"amygdales","lemma":"amygdale","pos":"NOM"} ,
		{"word":"amygdalite","word_nosc":"amygdalite","lemma":"amygdalite","pos":"NOM"} ,
		{"word":"amylase","word_nosc":"amylase","lemma":"amylase","pos":"NOM"} ,
		{"word":"amyle","word_nosc":"amyle","lemma":"amyle","pos":"NOM"} ,
		{"word":"amé","word_nosc":"ame","lemma":"amé","pos":"NOM"} ,
		{"word":"amélioration","word_nosc":"amelioration","lemma":"amélioration","pos":"NOM"} ,
		{"word":"améliorations","word_nosc":"ameliorations","lemma":"amélioration","pos":"NOM"} ,
		{"word":"aménagement","word_nosc":"amenagement","lemma":"aménagement","pos":"NOM"} ,
		{"word":"aménagements","word_nosc":"amenagements","lemma":"aménagement","pos":"NOM"} ,
		{"word":"aménité","word_nosc":"amenite","lemma":"aménité","pos":"NOM"} ,
		{"word":"aménités","word_nosc":"amenites","lemma":"aménité","pos":"NOM"} ,
		{"word":"aménorrhée","word_nosc":"amenorrhee","lemma":"aménorrhée","pos":"NOM"} ,
		{"word":"américain","word_nosc":"americain","lemma":"américain","pos":"NOM"} ,
		{"word":"américaine","word_nosc":"americaine","lemma":"américain","pos":"NOM"} ,
		{"word":"américaines","word_nosc":"americaines","lemma":"américain","pos":"NOM"} ,
		{"word":"américains","word_nosc":"americains","lemma":"américain","pos":"NOM"} ,
		{"word":"américanisation","word_nosc":"americanisation","lemma":"américanisation","pos":"NOM"} ,
		{"word":"américanisme","word_nosc":"americanisme","lemma":"américanisme","pos":"NOM"} ,
		{"word":"américanismes","word_nosc":"americanismes","lemma":"américanisme","pos":"NOM"} ,
		{"word":"américanité","word_nosc":"americanite","lemma":"américanité","pos":"NOM"} ,
		{"word":"américano","word_nosc":"americano","lemma":"américano","pos":"NOM"} ,
		{"word":"américanos","word_nosc":"americanos","lemma":"américano","pos":"NOM"} ,
		{"word":"américium","word_nosc":"americium","lemma":"américium","pos":"NOM"} ,
		{"word":"amérindien","word_nosc":"amerindien","lemma":"amérindien","pos":"NOM"} ,
		{"word":"amérindienne","word_nosc":"amerindienne","lemma":"amérindien","pos":"NOM"} ,
		{"word":"amérindiens","word_nosc":"amerindiens","lemma":"amérindien","pos":"NOM"} ,
		{"word":"amérique","word_nosc":"amerique","lemma":"amérique","pos":"NOM"} ,
		{"word":"améthyste","word_nosc":"amethyste","lemma":"améthyste","pos":"NOM"} ,
		{"word":"améthystes","word_nosc":"amethystes","lemma":"améthyste","pos":"NOM"} ,
		{"word":"an","word_nosc":"an","lemma":"an","pos":"NOM"} ,
		{"word":"ana","word_nosc":"ana","lemma":"ana","pos":"NOM"} ,
		{"word":"anabaptistes","word_nosc":"anabaptistes","lemma":"anabaptiste","pos":"NOM"} ,
		{"word":"anabase","word_nosc":"anabase","lemma":"anabase","pos":"NOM"} ,
		{"word":"anabolisant","word_nosc":"anabolisant","lemma":"anabolisant","pos":"NOM"} ,
		{"word":"anabolisants","word_nosc":"anabolisants","lemma":"anabolisant","pos":"NOM"} ,
		{"word":"anachorète","word_nosc":"anachorete","lemma":"anachorète","pos":"NOM"} ,
		{"word":"anachorètes","word_nosc":"anachoretes","lemma":"anachorète","pos":"NOM"} ,
		{"word":"anachronisme","word_nosc":"anachronisme","lemma":"anachronisme","pos":"NOM"} ,
		{"word":"anachronismes","word_nosc":"anachronismes","lemma":"anachronisme","pos":"NOM"} ,
		{"word":"anacoluthe","word_nosc":"anacoluthe","lemma":"anacoluthe","pos":"NOM"} ,
		{"word":"anaconda","word_nosc":"anaconda","lemma":"anaconda","pos":"NOM"} ,
		{"word":"anacondas","word_nosc":"anacondas","lemma":"anaconda","pos":"NOM"} ,
		{"word":"anaglyphe","word_nosc":"anaglyphe","lemma":"anaglyphe","pos":"NOM"} ,
		{"word":"anagramme","word_nosc":"anagramme","lemma":"anagramme","pos":"NOM"} ,
		{"word":"anagrammes","word_nosc":"anagrammes","lemma":"anagramme","pos":"NOM"} ,
		{"word":"analeptiques","word_nosc":"analeptiques","lemma":"analeptique","pos":"NOM"} ,
		{"word":"analgésie","word_nosc":"analgesie","lemma":"analgésie","pos":"NOM"} ,
		{"word":"analgésique","word_nosc":"analgesique","lemma":"analgésique","pos":"NOM"} ,
		{"word":"analgésiques","word_nosc":"analgesiques","lemma":"analgésique","pos":"NOM"} ,
		{"word":"analogie","word_nosc":"analogie","lemma":"analogie","pos":"NOM"} ,
		{"word":"analogies","word_nosc":"analogies","lemma":"analogie","pos":"NOM"} ,
		{"word":"analogue","word_nosc":"analogue","lemma":"analogue","pos":"NOM"} ,
		{"word":"analogues","word_nosc":"analogues","lemma":"analogue","pos":"NOM"} ,
		{"word":"analphabète","word_nosc":"analphabete","lemma":"analphabète","pos":"NOM"} ,
		{"word":"analphabètes","word_nosc":"analphabetes","lemma":"analphabète","pos":"NOM"} ,
		{"word":"analphabétisme","word_nosc":"analphabetisme","lemma":"analphabétisme","pos":"NOM"} ,
		{"word":"analysant","word_nosc":"analysant","lemma":"analysant","pos":"NOM"} ,
		{"word":"analysants","word_nosc":"analysants","lemma":"analysant","pos":"NOM"} ,
		{"word":"analyse","word_nosc":"analyse","lemma":"analyse","pos":"NOM"} ,
		{"word":"analyses","word_nosc":"analyses","lemma":"analyse","pos":"NOM"} ,
		{"word":"analyseur","word_nosc":"analyseur","lemma":"analyseur","pos":"NOM"} ,
		{"word":"analyseurs","word_nosc":"analyseurs","lemma":"analyseur","pos":"NOM"} ,
		{"word":"analyste","word_nosc":"analyste","lemma":"analyste","pos":"NOM"} ,
		{"word":"analystes","word_nosc":"analystes","lemma":"analyste","pos":"NOM"} ,
		{"word":"analysé","word_nosc":"analyse","lemma":"analysé","pos":"NOM"} ,
		{"word":"analysée","word_nosc":"analysee","lemma":"analysé","pos":"NOM"} ,
		{"word":"analysées","word_nosc":"analysees","lemma":"analysé","pos":"NOM"} ,
		{"word":"analytique","word_nosc":"analytique","lemma":"analytique","pos":"NOM"} ,
		{"word":"anamnèse","word_nosc":"anamnese","lemma":"anamnèse","pos":"NOM"} ,
		{"word":"anamorphose","word_nosc":"anamorphose","lemma":"anamorphose","pos":"NOM"} ,
		{"word":"ananas","word_nosc":"ananas","lemma":"ananas","pos":"NOM"} ,
		{"word":"anaphase","word_nosc":"anaphase","lemma":"anaphase","pos":"NOM"} ,
		{"word":"anaphylaxie","word_nosc":"anaphylaxie","lemma":"anaphylaxie","pos":"NOM"} ,
		{"word":"anar","word_nosc":"anar","lemma":"anar","pos":"NOM"} ,
		{"word":"anarchie","word_nosc":"anarchie","lemma":"anarchie","pos":"NOM"} ,
		{"word":"anarchisme","word_nosc":"anarchisme","lemma":"anarchisme","pos":"NOM"} ,
		{"word":"anarchiste","word_nosc":"anarchiste","lemma":"anarchiste","pos":"NOM"} ,
		{"word":"anarchistes","word_nosc":"anarchistes","lemma":"anarchiste","pos":"NOM"} ,
		{"word":"anarcho-syndicalisme","word_nosc":"anarcho-syndicalisme","lemma":"anarcho-syndicalisme","pos":"NOM"} ,
		{"word":"anarcho-syndicalistes","word_nosc":"anarcho-syndicalistes","lemma":"anarcho-syndicaliste","pos":"NOM"} ,
		{"word":"anars","word_nosc":"anars","lemma":"anar","pos":"NOM"} ,
		{"word":"anas","word_nosc":"anas","lemma":"anas","pos":"NOM"} ,
		{"word":"anastomose","word_nosc":"anastomose","lemma":"anastomose","pos":"NOM"} ,
		{"word":"anastomoses","word_nosc":"anastomoses","lemma":"anastomose","pos":"NOM"} ,
		{"word":"anathème","word_nosc":"anatheme","lemma":"anathème","pos":"NOM"} ,
		{"word":"anathèmes","word_nosc":"anathemes","lemma":"anathème","pos":"NOM"} ,
		{"word":"anatidés","word_nosc":"anatides","lemma":"anatidé","pos":"NOM"} ,
		{"word":"anatifes","word_nosc":"anatifes","lemma":"anatife","pos":"NOM"} ,
		{"word":"anatomie","word_nosc":"anatomie","lemma":"anatomie","pos":"NOM"} ,
		{"word":"anatomies","word_nosc":"anatomies","lemma":"anatomie","pos":"NOM"} ,
		{"word":"anatomiste","word_nosc":"anatomiste","lemma":"anatomiste","pos":"NOM"} ,
		{"word":"anatomistes","word_nosc":"anatomistes","lemma":"anatomiste","pos":"NOM"} ,
		{"word":"ancestralité","word_nosc":"ancestralite","lemma":"ancestralité","pos":"NOM"} ,
		{"word":"anche","word_nosc":"anche","lemma":"anche","pos":"NOM"} ,
		{"word":"anchilops","word_nosc":"anchilops","lemma":"anchilops","pos":"NOM"} ,
		{"word":"anchois","word_nosc":"anchois","lemma":"anchois","pos":"NOM"} ,
		{"word":"anchoïade","word_nosc":"anchoiade","lemma":"anchoïade","pos":"NOM"} ,
		{"word":"ancien","word_nosc":"ancien","lemma":"ancien","pos":"NOM"} ,
		{"word":"ancienne","word_nosc":"ancienne","lemma":"ancien","pos":"NOM"} ,
		{"word":"anciennes","word_nosc":"anciennes","lemma":"ancien","pos":"NOM"} ,
		{"word":"ancienneté","word_nosc":"anciennete","lemma":"ancienneté","pos":"NOM"} ,
		{"word":"anciens","word_nosc":"anciens","lemma":"ancien","pos":"NOM"} ,
		{"word":"ancolie","word_nosc":"ancolie","lemma":"ancolie","pos":"NOM"} ,
		{"word":"ancrage","word_nosc":"ancrage","lemma":"ancrage","pos":"NOM"} ,
		{"word":"ancre","word_nosc":"ancre","lemma":"ancre","pos":"NOM"} ,
		{"word":"ancres","word_nosc":"ancres","lemma":"ancre","pos":"NOM"} ,
		{"word":"ancêtre","word_nosc":"ancetre","lemma":"ancêtre","pos":"NOM"} ,
		{"word":"ancêtres","word_nosc":"ancetres","lemma":"ancêtre","pos":"NOM"} ,
		{"word":"anda","word_nosc":"anda","lemma":"anda","pos":"NOM"} ,
		{"word":"andain","word_nosc":"andain","lemma":"andain","pos":"NOM"} ,
		{"word":"andains","word_nosc":"andains","lemma":"andain","pos":"NOM"} ,
		{"word":"andalou","word_nosc":"andalou","lemma":"andalou","pos":"NOM"} ,
		{"word":"andalous","word_nosc":"andalous","lemma":"andalou","pos":"NOM"} ,
		{"word":"andalouse","word_nosc":"andalouse","lemma":"andalou","pos":"NOM"} ,
		{"word":"andalouses","word_nosc":"andalouses","lemma":"andalou","pos":"NOM"} ,
		{"word":"andante","word_nosc":"andante","lemma":"andante","pos":"NOM"} ,
		{"word":"andouille","word_nosc":"andouille","lemma":"andouille","pos":"NOM"} ,
		{"word":"andouiller","word_nosc":"andouiller","lemma":"andouiller","pos":"NOM"} ,
		{"word":"andouillers","word_nosc":"andouillers","lemma":"andouiller","pos":"NOM"} ,
		{"word":"andouilles","word_nosc":"andouilles","lemma":"andouille","pos":"NOM"} ,
		{"word":"andouillette","word_nosc":"andouillette","lemma":"andouillette","pos":"NOM"} ,
		{"word":"andouillettes","word_nosc":"andouillettes","lemma":"andouillette","pos":"NOM"} ,
		{"word":"andrinople","word_nosc":"andrinople","lemma":"andrinople","pos":"NOM"} ,
		{"word":"androgyne","word_nosc":"androgyne","lemma":"androgyne","pos":"NOM"} ,
		{"word":"androgynes","word_nosc":"androgynes","lemma":"androgyne","pos":"NOM"} ,
		{"word":"androgynie","word_nosc":"androgynie","lemma":"androgynie","pos":"NOM"} ,
		{"word":"androgènes","word_nosc":"androgenes","lemma":"androgène","pos":"NOM"} ,
		{"word":"andropause","word_nosc":"andropause","lemma":"andropause","pos":"NOM"} ,
		{"word":"androïde","word_nosc":"androide","lemma":"androïde","pos":"NOM"} ,
		{"word":"androïdes","word_nosc":"androides","lemma":"androïde","pos":"NOM"} ,
		{"word":"anecdote","word_nosc":"anecdote","lemma":"anecdote","pos":"NOM"} ,
		{"word":"anecdotes","word_nosc":"anecdotes","lemma":"anecdote","pos":"NOM"} ,
		{"word":"anecdotier","word_nosc":"anecdotier","lemma":"anecdotier","pos":"NOM"} ,
		{"word":"anesthésiant","word_nosc":"anesthesiant","lemma":"anesthésiant","pos":"NOM"} ,
		{"word":"anesthésiants","word_nosc":"anesthesiants","lemma":"anesthésiant","pos":"NOM"} ,
		{"word":"anesthésie","word_nosc":"anesthesie","lemma":"anesthésie","pos":"NOM"} ,
		{"word":"anesthésies","word_nosc":"anesthesies","lemma":"anesthésie","pos":"NOM"} ,
		{"word":"anesthésique","word_nosc":"anesthesique","lemma":"anesthésique","pos":"NOM"} ,
		{"word":"anesthésiques","word_nosc":"anesthesiques","lemma":"anesthésique","pos":"NOM"} ,
		{"word":"anesthésiste","word_nosc":"anesthesiste","lemma":"anesthésiste","pos":"NOM"} ,
		{"word":"anesthésistes","word_nosc":"anesthesistes","lemma":"anesthésiste","pos":"NOM"} ,
		{"word":"aneth","word_nosc":"aneth","lemma":"aneth","pos":"NOM"} ,
		{"word":"anfractuosité","word_nosc":"anfractuosite","lemma":"anfractuosité","pos":"NOM"} ,
		{"word":"anfractuosités","word_nosc":"anfractuosites","lemma":"anfractuosité","pos":"NOM"} ,
		{"word":"ange","word_nosc":"ange","lemma":"ange","pos":"NOM"} ,
		{"word":"angelot","word_nosc":"angelot","lemma":"angelot","pos":"NOM"} ,
		{"word":"angelots","word_nosc":"angelots","lemma":"angelot","pos":"NOM"} ,
		{"word":"anges","word_nosc":"anges","lemma":"ange","pos":"NOM"} ,
		{"word":"angevine","word_nosc":"angevine","lemma":"angevin","pos":"NOM"} ,
		{"word":"angevins","word_nosc":"angevins","lemma":"angevin","pos":"NOM"} ,
		{"word":"angine","word_nosc":"angine","lemma":"angine","pos":"NOM"} ,
		{"word":"angines","word_nosc":"angines","lemma":"angine","pos":"NOM"} ,
		{"word":"angiocholite","word_nosc":"angiocholite","lemma":"angiocholite","pos":"NOM"} ,
		{"word":"angiographie","word_nosc":"angiographie","lemma":"angiographie","pos":"NOM"} ,
		{"word":"angiome","word_nosc":"angiome","lemma":"angiome","pos":"NOM"} ,
		{"word":"angioplastie","word_nosc":"angioplastie","lemma":"angioplastie","pos":"NOM"} ,
		{"word":"anglais","word_nosc":"anglais","lemma":"anglais","pos":"NOM"} ,
		{"word":"anglaise","word_nosc":"anglaise","lemma":"anglais","pos":"NOM"} ,
		{"word":"anglaises","word_nosc":"anglaises","lemma":"anglais","pos":"NOM"} ,
		{"word":"angle","word_nosc":"angle","lemma":"angle","pos":"NOM"} ,
		{"word":"angles","word_nosc":"angles","lemma":"angle","pos":"NOM"} ,
		{"word":"angleterre","word_nosc":"angleterre","lemma":"angleterre","pos":"NOM"} ,
		{"word":"anglican","word_nosc":"anglican","lemma":"anglican","pos":"NOM"} ,
		{"word":"anglicane","word_nosc":"anglicane","lemma":"anglican","pos":"NOM"} ,
		{"word":"anglicans","word_nosc":"anglicans","lemma":"anglican","pos":"NOM"} ,
		{"word":"angliche","word_nosc":"angliche","lemma":"angliche","pos":"NOM"} ,
		{"word":"angliches","word_nosc":"angliches","lemma":"angliche","pos":"NOM"} ,
		{"word":"anglicisation","word_nosc":"anglicisation","lemma":"anglicisation","pos":"NOM"} ,
		{"word":"anglicismes","word_nosc":"anglicismes","lemma":"anglicisme","pos":"NOM"} ,
		{"word":"anglo-américains","word_nosc":"anglo-americains","lemma":"anglo-américain","pos":"NOM"} ,
		{"word":"anglo-arabe","word_nosc":"anglo-arabe","lemma":"anglo-arabe","pos":"NOM"} ,
		{"word":"anglo-saxon","word_nosc":"anglo-saxon","lemma":"anglo-saxon","pos":"NOM"} ,
		{"word":"anglo-saxons","word_nosc":"anglo-saxons","lemma":"anglo-saxon","pos":"NOM"} ,
		{"word":"anglomanie","word_nosc":"anglomanie","lemma":"anglomanie","pos":"NOM"} ,
		{"word":"anglophile","word_nosc":"anglophile","lemma":"anglophile","pos":"NOM"} ,
		{"word":"anglophobes","word_nosc":"anglophobes","lemma":"anglophobe","pos":"NOM"} ,
		{"word":"anglophobie","word_nosc":"anglophobie","lemma":"anglophobie","pos":"NOM"} ,
		{"word":"anglophones","word_nosc":"anglophones","lemma":"anglophone","pos":"NOM"} ,
		{"word":"angoisse","word_nosc":"angoisse","lemma":"angoisse","pos":"NOM"} ,
		{"word":"angoisses","word_nosc":"angoisses","lemma":"angoisse","pos":"NOM"} ,
		{"word":"angoissé","word_nosc":"angoisse","lemma":"angoissé","pos":"NOM"} ,
		{"word":"angoissée","word_nosc":"angoissee","lemma":"angoissé","pos":"NOM"} ,
		{"word":"angoissées","word_nosc":"angoissees","lemma":"angoissé","pos":"NOM"} ,
		{"word":"angoissés","word_nosc":"angoisses","lemma":"angoissé","pos":"NOM"} ,
		{"word":"angolais","word_nosc":"angolais","lemma":"angolais","pos":"NOM"} ,
		{"word":"angon","word_nosc":"angon","lemma":"angon","pos":"NOM"} ,
		{"word":"angor","word_nosc":"angor","lemma":"angor","pos":"NOM"} ,
		{"word":"angora","word_nosc":"angora","lemma":"angora","pos":"NOM"} ,
		{"word":"angoras","word_nosc":"angoras","lemma":"angora","pos":"NOM"} ,
		{"word":"angström","word_nosc":"angstrom","lemma":"angström","pos":"NOM"} ,
		{"word":"anguille","word_nosc":"anguille","lemma":"anguille","pos":"NOM"} ,
		{"word":"anguilles","word_nosc":"anguilles","lemma":"anguille","pos":"NOM"} ,
		{"word":"anguillules","word_nosc":"anguillules","lemma":"anguillule","pos":"NOM"} ,
		{"word":"anguis","word_nosc":"anguis","lemma":"anguis","pos":"NOM"} ,
		{"word":"angulosité","word_nosc":"angulosite","lemma":"angulosité","pos":"NOM"} ,
		{"word":"angustura","word_nosc":"angustura","lemma":"angustura","pos":"NOM"} ,
		{"word":"angéite","word_nosc":"angeite","lemma":"angéite","pos":"NOM"} ,
		{"word":"angélique","word_nosc":"angelique","lemma":"angélique","pos":"NOM"} ,
		{"word":"angéliques","word_nosc":"angeliques","lemma":"angélique","pos":"NOM"} ,
		{"word":"angélisme","word_nosc":"angelisme","lemma":"angélisme","pos":"NOM"} ,
		{"word":"angélus","word_nosc":"angelus","lemma":"angélus","pos":"NOM"} ,
		{"word":"anhydride","word_nosc":"anhydride","lemma":"anhydride","pos":"NOM"} ,
		{"word":"anhélations","word_nosc":"anhelations","lemma":"anhélation","pos":"NOM"} ,
		{"word":"anicroche","word_nosc":"anicroche","lemma":"anicroche","pos":"NOM"} ,
		{"word":"anicroches","word_nosc":"anicroches","lemma":"anicroche","pos":"NOM"} ,
		{"word":"aniline","word_nosc":"aniline","lemma":"aniline","pos":"NOM"} ,
		{"word":"animal","word_nosc":"animal","lemma":"animal","pos":"NOM"} ,
		{"word":"animal-roi","word_nosc":"animal-roi","lemma":"animal-roi","pos":"NOM"} ,
		{"word":"animalcule","word_nosc":"animalcule","lemma":"animalcule","pos":"NOM"} ,
		{"word":"animalerie","word_nosc":"animalerie","lemma":"animalerie","pos":"NOM"} ,
		{"word":"animalier","word_nosc":"animalier","lemma":"animalier","pos":"NOM"} ,
		{"word":"animaliers","word_nosc":"animaliers","lemma":"animalier","pos":"NOM"} ,
		{"word":"animalité","word_nosc":"animalite","lemma":"animalité","pos":"NOM"} ,
		{"word":"animateur","word_nosc":"animateur","lemma":"animateur","pos":"NOM"} ,
		{"word":"animateurs","word_nosc":"animateurs","lemma":"animateur","pos":"NOM"} ,
		{"word":"animation","word_nosc":"animation","lemma":"animation","pos":"NOM"} ,
		{"word":"animations","word_nosc":"animations","lemma":"animation","pos":"NOM"} ,
		{"word":"animatrice","word_nosc":"animatrice","lemma":"animateur","pos":"NOM"} ,
		{"word":"animatrices","word_nosc":"animatrices","lemma":"animateur","pos":"NOM"} ,
		{"word":"animaux","word_nosc":"animaux","lemma":"animal","pos":"NOM"} ,
		{"word":"animaux-espions","word_nosc":"animaux-espions","lemma":"animaux-espions","pos":"NOM"} ,
		{"word":"animisme","word_nosc":"animisme","lemma":"animisme","pos":"NOM"} ,
		{"word":"animosité","word_nosc":"animosite","lemma":"animosité","pos":"NOM"} ,
		{"word":"animosités","word_nosc":"animosites","lemma":"animosité","pos":"NOM"} ,
		{"word":"animus","word_nosc":"animus","lemma":"animus","pos":"NOM"} ,
		{"word":"anis","word_nosc":"anis","lemma":"anis","pos":"NOM"} ,
		{"word":"anisette","word_nosc":"anisette","lemma":"anisette","pos":"NOM"} ,
		{"word":"anisettes","word_nosc":"anisettes","lemma":"anisette","pos":"NOM"} ,
		{"word":"anisé","word_nosc":"anise","lemma":"anisé","pos":"NOM"} ,
		{"word":"ankh","word_nosc":"ankh","lemma":"ankh","pos":"NOM"} ,
		{"word":"ankylose","word_nosc":"ankylose","lemma":"ankylose","pos":"NOM"} ,
		{"word":"ankyloses","word_nosc":"ankyloses","lemma":"ankylose","pos":"NOM"} ,
		{"word":"annales","word_nosc":"annales","lemma":"annales","pos":"NOM"} ,
		{"word":"annamite","word_nosc":"annamite","lemma":"annamite","pos":"NOM"} ,
		{"word":"annamites","word_nosc":"annamites","lemma":"annamite","pos":"NOM"} ,
		{"word":"anneau","word_nosc":"anneau","lemma":"anneau","pos":"NOM"} ,
		{"word":"anneaux","word_nosc":"anneaux","lemma":"anneau","pos":"NOM"} ,
		{"word":"annelets","word_nosc":"annelets","lemma":"annelet","pos":"NOM"} ,
		{"word":"annelures","word_nosc":"annelures","lemma":"annelure","pos":"NOM"} ,
		{"word":"annexe","word_nosc":"annexe","lemma":"annexe","pos":"NOM"} ,
		{"word":"annexes","word_nosc":"annexes","lemma":"annexe","pos":"NOM"} ,
		{"word":"annexion","word_nosc":"annexion","lemma":"annexion","pos":"NOM"} ,
		{"word":"annexions","word_nosc":"annexions","lemma":"annexion","pos":"NOM"} ,
		{"word":"annihilation","word_nosc":"annihilation","lemma":"annihilation","pos":"NOM"} ,
		{"word":"anniv","word_nosc":"anniv","lemma":"anniv","pos":"NOM"} ,
		{"word":"anniversaire","word_nosc":"anniversaire","lemma":"anniversaire","pos":"NOM"} ,
		{"word":"anniversaires","word_nosc":"anniversaires","lemma":"anniversaire","pos":"NOM"} ,
		{"word":"annonce","word_nosc":"annonce","lemma":"annonce","pos":"NOM"} ,
		{"word":"annonces","word_nosc":"annonces","lemma":"annonce","pos":"NOM"} ,
		{"word":"annonceur","word_nosc":"annonceur","lemma":"annonceur","pos":"NOM"} ,
		{"word":"annonceurs","word_nosc":"annonceurs","lemma":"annonceur","pos":"NOM"} ,
		{"word":"annonceuse","word_nosc":"annonceuse","lemma":"annonceur","pos":"NOM"} ,
		{"word":"annonciateur","word_nosc":"annonciateur","lemma":"annonciateur","pos":"NOM"} ,
		{"word":"annonciateurs","word_nosc":"annonciateurs","lemma":"annonciateur","pos":"NOM"} ,
		{"word":"annonciation","word_nosc":"annonciation","lemma":"annonciation","pos":"NOM"} ,
		{"word":"annonciations","word_nosc":"annonciations","lemma":"annonciation","pos":"NOM"} ,
		{"word":"annonciatrices","word_nosc":"annonciatrices","lemma":"annonciateur","pos":"NOM"} ,
		{"word":"annotation","word_nosc":"annotation","lemma":"annotation","pos":"NOM"} ,
		{"word":"annotations","word_nosc":"annotations","lemma":"annotation","pos":"NOM"} ,
		{"word":"annuaire","word_nosc":"annuaire","lemma":"annuaire","pos":"NOM"} ,
		{"word":"annuaires","word_nosc":"annuaires","lemma":"annuaire","pos":"NOM"} ,
		{"word":"annulaire","word_nosc":"annulaire","lemma":"annulaire","pos":"NOM"} ,
		{"word":"annulation","word_nosc":"annulation","lemma":"annulation","pos":"NOM"} ,
		{"word":"annulations","word_nosc":"annulations","lemma":"annulation","pos":"NOM"} ,
		{"word":"année","word_nosc":"annee","lemma":"année","pos":"NOM"} ,
		{"word":"année-lumière","word_nosc":"annee-lumiere","lemma":"année-lumière","pos":"NOM"} ,
		{"word":"années","word_nosc":"annees","lemma":"année","pos":"NOM"} ,
		{"word":"années-homme","word_nosc":"annees-homme","lemma":"année-homme","pos":"NOM"} ,
		{"word":"années-lumière","word_nosc":"annees-lumiere","lemma":"année-lumière","pos":"NOM"} ,
		{"word":"anoblissement","word_nosc":"anoblissement","lemma":"anoblissement","pos":"NOM"} ,
		{"word":"anode","word_nosc":"anode","lemma":"anode","pos":"NOM"} ,
		{"word":"anomalie","word_nosc":"anomalie","lemma":"anomalie","pos":"NOM"} ,
		{"word":"anomalies","word_nosc":"anomalies","lemma":"anomalie","pos":"NOM"} ,
		{"word":"anomoure","word_nosc":"anomoure","lemma":"anomoure","pos":"NOM"} ,
		{"word":"anonymat","word_nosc":"anonymat","lemma":"anonymat","pos":"NOM"} ,
		{"word":"anonyme","word_nosc":"anonyme","lemma":"anonyme","pos":"NOM"} ,
		{"word":"anonymes","word_nosc":"anonymes","lemma":"anonyme","pos":"NOM"} ,
		{"word":"anonymographe","word_nosc":"anonymographe","lemma":"anonymographe","pos":"NOM"} ,
		{"word":"anophèle","word_nosc":"anophele","lemma":"anophèle","pos":"NOM"} ,
		{"word":"anorak","word_nosc":"anorak","lemma":"anorak","pos":"NOM"} ,
		{"word":"anoraks","word_nosc":"anoraks","lemma":"anorak","pos":"NOM"} ,
		{"word":"anorexie","word_nosc":"anorexie","lemma":"anorexie","pos":"NOM"} ,
		{"word":"anorexies","word_nosc":"anorexies","lemma":"anorexie","pos":"NOM"} ,
		{"word":"anorexique","word_nosc":"anorexique","lemma":"anorexique","pos":"NOM"} ,
		{"word":"anorexiques","word_nosc":"anorexiques","lemma":"anorexique","pos":"NOM"} ,
		{"word":"anormal","word_nosc":"anormal","lemma":"anormal","pos":"NOM"} ,
		{"word":"anormale","word_nosc":"anormale","lemma":"anormal","pos":"NOM"} ,
		{"word":"anormales","word_nosc":"anormales","lemma":"anormal","pos":"NOM"} ,
		{"word":"anormalité","word_nosc":"anormalite","lemma":"anormalité","pos":"NOM"} ,
		{"word":"anormaux","word_nosc":"anormaux","lemma":"anormal","pos":"NOM"} ,
		{"word":"anosmie","word_nosc":"anosmie","lemma":"anosmie","pos":"NOM"} ,
		{"word":"anoxie","word_nosc":"anoxie","lemma":"anoxie","pos":"NOM"} ,
		{"word":"ans","word_nosc":"ans","lemma":"an","pos":"NOM"} ,
		{"word":"anse","word_nosc":"anse","lemma":"anse","pos":"NOM"} ,
		{"word":"anses","word_nosc":"anses","lemma":"anse","pos":"NOM"} ,
		{"word":"antagonisme","word_nosc":"antagonisme","lemma":"antagonisme","pos":"NOM"} ,
		{"word":"antagonismes","word_nosc":"antagonismes","lemma":"antagonisme","pos":"NOM"} ,
		{"word":"antagoniste","word_nosc":"antagoniste","lemma":"antagoniste","pos":"NOM"} ,
		{"word":"antagonistes","word_nosc":"antagonistes","lemma":"antagoniste","pos":"NOM"} ,
		{"word":"antalgique","word_nosc":"antalgique","lemma":"antalgique","pos":"NOM"} ,
		{"word":"antalgiques","word_nosc":"antalgiques","lemma":"antalgique","pos":"NOM"} ,
		{"word":"antan","word_nosc":"antan","lemma":"antan","pos":"NOM"} ,
		{"word":"ante","word_nosc":"ante","lemma":"ante","pos":"NOM"} ,
		{"word":"antenne","word_nosc":"antenne","lemma":"antenne","pos":"NOM"} ,
		{"word":"antennes","word_nosc":"antennes","lemma":"antenne","pos":"NOM"} ,
		{"word":"anthologie","word_nosc":"anthologie","lemma":"anthologie","pos":"NOM"} ,
		{"word":"anthologies","word_nosc":"anthologies","lemma":"anthologie","pos":"NOM"} ,
		{"word":"anthonome","word_nosc":"anthonome","lemma":"anthonome","pos":"NOM"} ,
		{"word":"anthracine","word_nosc":"anthracine","lemma":"anthracine","pos":"NOM"} ,
		{"word":"anthracite","word_nosc":"anthracite","lemma":"anthracite","pos":"NOM"} ,
		{"word":"anthracites","word_nosc":"anthracites","lemma":"anthracite","pos":"NOM"} ,
		{"word":"anthracose","word_nosc":"anthracose","lemma":"anthracose","pos":"NOM"} ,
		{"word":"anthracène","word_nosc":"anthracene","lemma":"anthracène","pos":"NOM"} ,
		{"word":"anthraquinone","word_nosc":"anthraquinone","lemma":"anthraquinone","pos":"NOM"} ,
		{"word":"anthrax","word_nosc":"anthrax","lemma":"anthrax","pos":"NOM"} ,
		{"word":"anthropologie","word_nosc":"anthropologie","lemma":"anthropologie","pos":"NOM"} ,
		{"word":"anthropologiste","word_nosc":"anthropologiste","lemma":"anthropologiste","pos":"NOM"} ,
		{"word":"anthropologue","word_nosc":"anthropologue","lemma":"anthropologue","pos":"NOM"} ,
		{"word":"anthropologues","word_nosc":"anthropologues","lemma":"anthropologue","pos":"NOM"} ,
		{"word":"anthropomorphisme","word_nosc":"anthropomorphisme","lemma":"anthropomorphisme","pos":"NOM"} ,
		{"word":"anthropométrie","word_nosc":"anthropometrie","lemma":"anthropométrie","pos":"NOM"} ,
		{"word":"anthropophage","word_nosc":"anthropophage","lemma":"anthropophage","pos":"NOM"} ,
		{"word":"anthropophages","word_nosc":"anthropophages","lemma":"anthropophage","pos":"NOM"} ,
		{"word":"anthropophagie","word_nosc":"anthropophagie","lemma":"anthropophagie","pos":"NOM"} ,
		{"word":"anthropopithèque","word_nosc":"anthropopitheque","lemma":"anthropopithèque","pos":"NOM"} ,
		{"word":"anthropopithèques","word_nosc":"anthropopitheques","lemma":"anthropopithèque","pos":"NOM"} ,
		{"word":"anthropoïde","word_nosc":"anthropoide","lemma":"anthropoïde","pos":"NOM"} ,
		{"word":"anthropoïdes","word_nosc":"anthropoides","lemma":"anthropoïde","pos":"NOM"} ,
		{"word":"anti-acné","word_nosc":"anti-acne","lemma":"anti-acné","pos":"NOM"} ,
		{"word":"anti-agression","word_nosc":"anti-agression","lemma":"anti-agression","pos":"NOM"} ,
		{"word":"anti-allergie","word_nosc":"anti-allergie","lemma":"anti-allergie","pos":"NOM"} ,
		{"word":"anti-apartheid","word_nosc":"anti-apartheid","lemma":"anti-apartheid","pos":"NOM"} ,
		{"word":"anti-asthmatique","word_nosc":"anti-asthmatique","lemma":"anti-asthmatique","pos":"NOM"} ,
		{"word":"anti-avortement","word_nosc":"anti-avortement","lemma":"anti-avortement","pos":"NOM"} ,
		{"word":"anti-aérien","word_nosc":"anti-aerien","lemma":"anti-aérien","pos":"NOM"} ,
		{"word":"anti-beauf","word_nosc":"anti-beauf","lemma":"anti-beauf","pos":"NOM"} ,
		{"word":"anti-bison","word_nosc":"anti-bison","lemma":"anti-bison","pos":"NOM"} ,
		{"word":"anti-blindage","word_nosc":"anti-blindage","lemma":"anti-blindage","pos":"NOM"} ,
		{"word":"anti-blouson","word_nosc":"anti-blouson","lemma":"anti-blouson","pos":"NOM"} ,
		{"word":"anti-bombe","word_nosc":"anti-bombe","lemma":"anti-bombe","pos":"NOM"} ,
		{"word":"anti-bombes","word_nosc":"anti-bombes","lemma":"anti-bombe","pos":"NOM"} ,
		{"word":"anti-bosons","word_nosc":"anti-bosons","lemma":"anti-boson","pos":"NOM"} ,
		{"word":"anti-braquage","word_nosc":"anti-braquage","lemma":"anti-braquage","pos":"NOM"} ,
		{"word":"anti-brouillard","word_nosc":"anti-brouillard","lemma":"anti-brouillard","pos":"NOM"} ,
		{"word":"anti-bruit","word_nosc":"anti-bruit","lemma":"anti-bruit","pos":"NOM"} ,
		{"word":"anti-cancer","word_nosc":"anti-cancer","lemma":"anti-cancer","pos":"NOM"} ,
		{"word":"anti-castristes","word_nosc":"anti-castristes","lemma":"anti-castriste","pos":"NOM"} ,
		{"word":"anti-cellulite","word_nosc":"anti-cellulite","lemma":"anti-cellulite","pos":"NOM"} ,
		{"word":"anti-chambre","word_nosc":"anti-chambre","lemma":"anti-chambre","pos":"NOM"} ,
		{"word":"anti-chambres","word_nosc":"anti-chambres","lemma":"anti-chambre","pos":"NOM"} ,
		{"word":"anti-char","word_nosc":"anti-char","lemma":"anti-char","pos":"NOM"} ,
		{"word":"anti-chars","word_nosc":"anti-chars","lemma":"anti-char","pos":"NOM"} ,
		{"word":"anti-chiens","word_nosc":"anti-chiens","lemma":"anti-chien","pos":"NOM"} ,
		{"word":"anti-cité","word_nosc":"anti-cite","lemma":"anti-cité","pos":"NOM"} ,
		{"word":"anti-civilisation","word_nosc":"anti-civilisation","lemma":"anti-civilisation","pos":"NOM"} ,
		{"word":"anti-club","word_nosc":"anti-club","lemma":"anti-club","pos":"NOM"} ,
		{"word":"anti-cocos","word_nosc":"anti-cocos","lemma":"anti-coco","pos":"NOM"} ,
		{"word":"anti-colère","word_nosc":"anti-colere","lemma":"anti-colère","pos":"NOM"} ,
		{"word":"anti-communisme","word_nosc":"anti-communisme","lemma":"anti-communisme","pos":"NOM"} ,
		{"word":"anti-communiste","word_nosc":"anti-communiste","lemma":"anti-communiste","pos":"NOM"} ,
		{"word":"anti-conformisme","word_nosc":"anti-conformisme","lemma":"anti-conformisme","pos":"NOM"} ,
		{"word":"anti-conformiste","word_nosc":"anti-conformiste","lemma":"anti-conformiste","pos":"NOM"} ,
		{"word":"anti-corps","word_nosc":"anti-corps","lemma":"anti-corps","pos":"NOM"} ,
		{"word":"anti-corruption","word_nosc":"anti-corruption","lemma":"anti-corruption","pos":"NOM"} ,
		{"word":"anti-crash","word_nosc":"anti-crash","lemma":"anti-crash","pos":"NOM"} ,
		{"word":"anti-crime","word_nosc":"anti-crime","lemma":"anti-crime","pos":"NOM"} ,
		{"word":"anti-criminalité","word_nosc":"anti-criminalite","lemma":"anti-criminalité","pos":"NOM"} ,
		{"word":"anti-dauphins","word_nosc":"anti-dauphins","lemma":"anti-dauphin","pos":"NOM"} ,
		{"word":"anti-desperado","word_nosc":"anti-desperado","lemma":"anti-desperado","pos":"NOM"} ,
		{"word":"anti-dieu","word_nosc":"anti-dieu","lemma":"anti-dieu","pos":"NOM"} ,
		{"word":"anti-discrimination","word_nosc":"anti-discrimination","lemma":"anti-discrimination","pos":"NOM"} ,
		{"word":"anti-dopage","word_nosc":"anti-dopage","lemma":"anti-dopage","pos":"NOM"} ,
		{"word":"anti-douleur","word_nosc":"anti-douleur","lemma":"anti-douleur","pos":"NOM"} ,
		{"word":"anti-douleurs","word_nosc":"anti-douleurs","lemma":"anti-douleur","pos":"NOM"} ,
		{"word":"anti-drague","word_nosc":"anti-drague","lemma":"anti-drague","pos":"NOM"} ,
		{"word":"anti-drogue","word_nosc":"anti-drogue","lemma":"anti-drogue","pos":"NOM"} ,
		{"word":"anti-drogues","word_nosc":"anti-drogues","lemma":"anti-drogue","pos":"NOM"} ,
		{"word":"anti-débauche","word_nosc":"anti-debauche","lemma":"anti-débauche","pos":"NOM"} ,
		{"word":"anti-démon","word_nosc":"anti-demon","lemma":"anti-démon","pos":"NOM"} ,
		{"word":"anti-espionnes","word_nosc":"anti-espionnes","lemma":"anti-espionne","pos":"NOM"} ,
		{"word":"anti-establishment","word_nosc":"anti-establishment","lemma":"anti-establishment","pos":"NOM"} ,
		{"word":"anti-existence","word_nosc":"anti-existence","lemma":"anti-existence","pos":"NOM"} ,
		{"word":"anti-explosion","word_nosc":"anti-explosion","lemma":"anti-explosion","pos":"NOM"} ,
		{"word":"anti-fantômes","word_nosc":"anti-fantomes","lemma":"anti-fantôme","pos":"NOM"} ,
		{"word":"anti-femme","word_nosc":"anti-femme","lemma":"anti-femme","pos":"NOM"} ,
		{"word":"anti-flingage","word_nosc":"anti-flingage","lemma":"anti-flingage","pos":"NOM"} ,
		{"word":"anti-flip","word_nosc":"anti-flip","lemma":"anti-flip","pos":"NOM"} ,
		{"word":"anti-frigo","word_nosc":"anti-frigo","lemma":"anti-frigo","pos":"NOM"} ,
		{"word":"anti-fusées","word_nosc":"anti-fusees","lemma":"anti-fusée","pos":"NOM"} ,
		{"word":"anti-féministe","word_nosc":"anti-feministe","lemma":"anti-féministe","pos":"NOM"} ,
		{"word":"anti-gang","word_nosc":"anti-gang","lemma":"anti-gang","pos":"NOM"} ,
		{"word":"anti-gel","word_nosc":"anti-gel","lemma":"anti-gel","pos":"NOM"} ,
		{"word":"anti-gerce","word_nosc":"anti-gerce","lemma":"anti-gerce","pos":"NOM"} ,
		{"word":"anti-gouvernement","word_nosc":"anti-gouvernement","lemma":"anti-gouvernement","pos":"NOM"} ,
		{"word":"anti-graffiti","word_nosc":"anti-graffiti","lemma":"anti-graffiti","pos":"NOM"} ,
		{"word":"anti-gravité","word_nosc":"anti-gravite","lemma":"anti-gravité","pos":"NOM"} ,
		{"word":"anti-grimaces","word_nosc":"anti-grimaces","lemma":"anti-grimace","pos":"NOM"} ,
		{"word":"anti-hannetons","word_nosc":"anti-hannetons","lemma":"anti-hanneton","pos":"NOM"} ,
		{"word":"anti-immigration","word_nosc":"anti-immigration","lemma":"anti-immigration","pos":"NOM"} ,
		{"word":"anti-incendie","word_nosc":"anti-incendie","lemma":"anti-incendie","pos":"NOM"} ,
		{"word":"anti-inertie","word_nosc":"anti-inertie","lemma":"anti-inertie","pos":"NOM"} ,
		{"word":"anti-inflammatoire","word_nosc":"anti-inflammatoire","lemma":"anti-inflammatoire","pos":"NOM"} ,
		{"word":"anti-insecte","word_nosc":"anti-insecte","lemma":"anti-insecte","pos":"NOM"} ,
		{"word":"anti-insectes","word_nosc":"anti-insectes","lemma":"anti-insecte","pos":"NOM"} ,
		{"word":"anti-instinct","word_nosc":"anti-instinct","lemma":"anti-instinct","pos":"NOM"} ,
		{"word":"anti-intimité","word_nosc":"anti-intimite","lemma":"anti-intimité","pos":"NOM"} ,
		{"word":"anti-japon","word_nosc":"anti-japon","lemma":"anti-japon","pos":"NOM"} ,
		{"word":"anti-mafia","word_nosc":"anti-mafia","lemma":"anti-mafia","pos":"NOM"} ,
		{"word":"anti-maison","word_nosc":"anti-maison","lemma":"anti-maison","pos":"NOM"} ,
		{"word":"anti-manipulation","word_nosc":"anti-manipulation","lemma":"anti-manipulation","pos":"NOM"} ,
		{"word":"anti-manoir","word_nosc":"anti-manoir","lemma":"anti-manoir","pos":"NOM"} ,
		{"word":"anti-mariage","word_nosc":"anti-mariage","lemma":"anti-mariage","pos":"NOM"} ,
		{"word":"anti-matière","word_nosc":"anti-matiere","lemma":"anti-matière","pos":"NOM"} ,
		{"word":"anti-matières","word_nosc":"anti-matieres","lemma":"anti-matière","pos":"NOM"} ,
		{"word":"anti-mecs","word_nosc":"anti-mecs","lemma":"anti-mec","pos":"NOM"} ,
		{"word":"anti-militariste","word_nosc":"anti-militariste","lemma":"anti-militariste","pos":"NOM"} ,
		{"word":"anti-missiles","word_nosc":"anti-missiles","lemma":"anti-missile","pos":"NOM"} ,
		{"word":"anti-mite","word_nosc":"anti-mite","lemma":"anti-mite","pos":"NOM"} ,
		{"word":"anti-mites","word_nosc":"anti-mites","lemma":"anti-mite","pos":"NOM"} ,
		{"word":"anti-monde","word_nosc":"anti-monde","lemma":"anti-monde","pos":"NOM"} ,
		{"word":"anti-moustique","word_nosc":"anti-moustique","lemma":"anti-moustique","pos":"NOM"} ,
		{"word":"anti-moustiques","word_nosc":"anti-moustiques","lemma":"anti-moustique","pos":"NOM"} ,
		{"word":"anti-médicaments","word_nosc":"anti-medicaments","lemma":"anti-médicament","pos":"NOM"} ,
		{"word":"anti-météorites","word_nosc":"anti-meteorites","lemma":"anti-météorite","pos":"NOM"} ,
		{"word":"anti-nausée","word_nosc":"anti-nausee","lemma":"anti-nausée","pos":"NOM"} ,
		{"word":"anti-navire","word_nosc":"anti-navire","lemma":"anti-navire","pos":"NOM"} ,
		{"word":"anti-nucléaires","word_nosc":"anti-nucleaires","lemma":"anti-nucléaire","pos":"NOM"} ,
		{"word":"anti-odeur","word_nosc":"anti-odeur","lemma":"anti-odeur","pos":"NOM"} ,
		{"word":"anti-odeurs","word_nosc":"anti-odeurs","lemma":"anti-odeur","pos":"NOM"} ,
		{"word":"anti-origine","word_nosc":"anti-origine","lemma":"anti-origine","pos":"NOM"} ,
		{"word":"anti-panache","word_nosc":"anti-panache","lemma":"anti-panache","pos":"NOM"} ,
		{"word":"anti-particule","word_nosc":"anti-particule","lemma":"anti-particule","pos":"NOM"} ,
		{"word":"anti-pasteur","word_nosc":"anti-pasteur","lemma":"anti-pasteur","pos":"NOM"} ,
		{"word":"anti-patriote","word_nosc":"anti-patriote","lemma":"anti-patriote","pos":"NOM"} ,
		{"word":"anti-piratage","word_nosc":"anti-piratage","lemma":"anti-piratage","pos":"NOM"} ,
		{"word":"anti-poison","word_nosc":"anti-poison","lemma":"anti-poison","pos":"NOM"} ,
		{"word":"anti-poisse","word_nosc":"anti-poisse","lemma":"anti-poisse","pos":"NOM"} ,
		{"word":"anti-pollution","word_nosc":"anti-pollution","lemma":"anti-pollution","pos":"NOM"} ,
		{"word":"anti-poux","word_nosc":"anti-poux","lemma":"anti-poux","pos":"NOM"} ,
		{"word":"anti-progressistes","word_nosc":"anti-progressistes","lemma":"anti-progressiste","pos":"NOM"} ,
		{"word":"anti-pub","word_nosc":"anti-pub","lemma":"anti-pub","pos":"NOM"} ,
		{"word":"anti-puces","word_nosc":"anti-puces","lemma":"anti-puce","pos":"NOM"} ,
		{"word":"anti-racket","word_nosc":"anti-racket","lemma":"anti-racket","pos":"NOM"} ,
		{"word":"anti-radiations","word_nosc":"anti-radiations","lemma":"anti-radiation","pos":"NOM"} ,
		{"word":"anti-rapt","word_nosc":"anti-rapt","lemma":"anti-rapt","pos":"NOM"} ,
		{"word":"anti-reflets","word_nosc":"anti-reflets","lemma":"anti-reflet","pos":"NOM"} ,
		{"word":"anti-rejet","word_nosc":"anti-rejet","lemma":"anti-rejet","pos":"NOM"} ,
		{"word":"anti-religion","word_nosc":"anti-religion","lemma":"anti-religion","pos":"NOM"} ,
		{"word":"anti-rhume","word_nosc":"anti-rhume","lemma":"anti-rhume","pos":"NOM"} ,
		{"word":"anti-rides","word_nosc":"anti-rides","lemma":"anti-ride","pos":"NOM"} ,
		{"word":"anti-romain","word_nosc":"anti-romain","lemma":"anti-romain","pos":"NOM"} ,
		{"word":"anti-rouille","word_nosc":"anti-rouille","lemma":"anti-rouille","pos":"NOM"} ,
		{"word":"anti-sida","word_nosc":"anti-sida","lemma":"anti-sida","pos":"NOM"} ,
		{"word":"anti-société","word_nosc":"anti-societe","lemma":"anti-société","pos":"NOM"} ,
		{"word":"anti-somnolence","word_nosc":"anti-somnolence","lemma":"anti-somnolence","pos":"NOM"} ,
		{"word":"anti-souffle","word_nosc":"anti-souffle","lemma":"anti-souffle","pos":"NOM"} ,
		{"word":"anti-stress","word_nosc":"anti-stress","lemma":"anti-stress","pos":"NOM"} ,
		{"word":"anti-sémitisme","word_nosc":"anti-semitisme","lemma":"anti-sémitisme","pos":"NOM"} ,
		{"word":"anti-taches","word_nosc":"anti-taches","lemma":"anti-tache","pos":"NOM"} ,
		{"word":"anti-tank","word_nosc":"anti-tank","lemma":"anti-tank","pos":"NOM"} ,
		{"word":"anti-tapisserie","word_nosc":"anti-tapisserie","lemma":"anti-tapisserie","pos":"NOM"} ,
		{"word":"anti-temps","word_nosc":"anti-temps","lemma":"anti-temps","pos":"NOM"} ,
		{"word":"anti-tension","word_nosc":"anti-tension","lemma":"anti-tension","pos":"NOM"} ,
		{"word":"anti-terrorisme","word_nosc":"anti-terrorisme","lemma":"anti-terrorisme","pos":"NOM"} ,
		{"word":"anti-tornades","word_nosc":"anti-tornades","lemma":"anti-tornade","pos":"NOM"} ,
		{"word":"anti-toux","word_nosc":"anti-toux","lemma":"anti-toux","pos":"NOM"} ,
		{"word":"anti-truie","word_nosc":"anti-truie","lemma":"anti-truie","pos":"NOM"} ,
		{"word":"anti-trusts","word_nosc":"anti-trusts","lemma":"anti-trust","pos":"NOM"} ,
		{"word":"anti-venin","word_nosc":"anti-venin","lemma":"anti-venin","pos":"NOM"} ,
		{"word":"anti-vieillissement","word_nosc":"anti-vieillissement","lemma":"anti-vieillissement","pos":"NOM"} ,
		{"word":"anti-viol","word_nosc":"anti-viol","lemma":"anti-viol","pos":"NOM"} ,
		{"word":"anti-violence","word_nosc":"anti-violence","lemma":"anti-violence","pos":"NOM"} ,
		{"word":"anti-virus","word_nosc":"anti-virus","lemma":"anti-virus","pos":"NOM"} ,
		{"word":"anti-vol","word_nosc":"anti-vol","lemma":"anti-vol","pos":"NOM"} ,
		{"word":"anti-vols","word_nosc":"anti-vols","lemma":"anti-vol","pos":"NOM"} ,
		{"word":"anti-évasion","word_nosc":"anti-evasion","lemma":"anti-évasion","pos":"NOM"} ,
		{"word":"antiaméricanisme","word_nosc":"antiamericanisme","lemma":"antiaméricanisme","pos":"NOM"} ,
		{"word":"antibiotique","word_nosc":"antibiotique","lemma":"antibiotique","pos":"NOM"} ,
		{"word":"antibiotiques","word_nosc":"antibiotiques","lemma":"antibiotique","pos":"NOM"} ,
		{"word":"antibois","word_nosc":"antibois","lemma":"antibois","pos":"NOM"} ,
		{"word":"antibolchevisme","word_nosc":"antibolchevisme","lemma":"antibolchevisme","pos":"NOM"} ,
		{"word":"anticastristes","word_nosc":"anticastristes","lemma":"anticastriste","pos":"NOM"} ,
		{"word":"anticatalyseurs","word_nosc":"anticatalyseurs","lemma":"anticatalyseur","pos":"NOM"} ,
		{"word":"antichambre","word_nosc":"antichambre","lemma":"antichambre","pos":"NOM"} ,
		{"word":"antichambres","word_nosc":"antichambres","lemma":"antichambre","pos":"NOM"} ,
		{"word":"antichristianisme","word_nosc":"antichristianisme","lemma":"antichristianisme","pos":"NOM"} ,
		{"word":"anticipation","word_nosc":"anticipation","lemma":"anticipation","pos":"NOM"} ,
		{"word":"anticipations","word_nosc":"anticipations","lemma":"anticipation","pos":"NOM"} ,
		{"word":"anticléricalisme","word_nosc":"anticlericalisme","lemma":"anticléricalisme","pos":"NOM"} ,
		{"word":"anticoagulant","word_nosc":"anticoagulant","lemma":"anticoagulant","pos":"NOM"} ,
		{"word":"anticoagulants","word_nosc":"anticoagulants","lemma":"anticoagulant","pos":"NOM"} ,
		{"word":"anticolonialisme","word_nosc":"anticolonialisme","lemma":"anticolonialisme","pos":"NOM"} ,
		{"word":"anticommunisme","word_nosc":"anticommunisme","lemma":"anticommunisme","pos":"NOM"} ,
		{"word":"anticommuniste","word_nosc":"anticommuniste","lemma":"anticommuniste","pos":"NOM"} ,
		{"word":"anticommunistes","word_nosc":"anticommunistes","lemma":"anticommuniste","pos":"NOM"} ,
		{"word":"anticonformisme","word_nosc":"anticonformisme","lemma":"anticonformisme","pos":"NOM"} ,
		{"word":"anticorps","word_nosc":"anticorps","lemma":"anticorps","pos":"NOM"} ,
		{"word":"anticyclone","word_nosc":"anticyclone","lemma":"anticyclone","pos":"NOM"} ,
		{"word":"antidote","word_nosc":"antidote","lemma":"antidote","pos":"NOM"} ,
		{"word":"antidotes","word_nosc":"antidotes","lemma":"antidote","pos":"NOM"} ,
		{"word":"antidreyfusard","word_nosc":"antidreyfusard","lemma":"antidreyfusard","pos":"NOM"} ,
		{"word":"antidreyfusards","word_nosc":"antidreyfusards","lemma":"antidreyfusard","pos":"NOM"} ,
		{"word":"antidreyfusisme","word_nosc":"antidreyfusisme","lemma":"antidreyfusisme","pos":"NOM"} ,
		{"word":"antidémarrage","word_nosc":"antidemarrage","lemma":"antidémarrage","pos":"NOM"} ,
		{"word":"antidépresseur","word_nosc":"antidepresseur","lemma":"antidépresseur","pos":"NOM"} ,
		{"word":"antidépresseurs","word_nosc":"antidepresseurs","lemma":"antidépresseur","pos":"NOM"} ,
		{"word":"antidérapant","word_nosc":"antiderapant","lemma":"antidérapant","pos":"NOM"} ,
		{"word":"antienne","word_nosc":"antienne","lemma":"antienne","pos":"NOM"} ,
		{"word":"antiennes","word_nosc":"antiennes","lemma":"antienne","pos":"NOM"} ,
		{"word":"antiesclavagiste","word_nosc":"antiesclavagiste","lemma":"antiesclavagiste","pos":"NOM"} ,
		{"word":"antifading","word_nosc":"antifading","lemma":"antifading","pos":"NOM"} ,
		{"word":"antifascisme","word_nosc":"antifascisme","lemma":"antifascisme","pos":"NOM"} ,
		{"word":"antifasciste","word_nosc":"antifasciste","lemma":"antifasciste","pos":"NOM"} ,
		{"word":"antifascistes","word_nosc":"antifascistes","lemma":"antifasciste","pos":"NOM"} ,
		{"word":"antifongique","word_nosc":"antifongique","lemma":"antifongique","pos":"NOM"} ,
		{"word":"antiféminisme","word_nosc":"antifeminisme","lemma":"antiféminisme","pos":"NOM"} ,
		{"word":"antigang","word_nosc":"antigang","lemma":"antigang","pos":"NOM"} ,
		{"word":"antigangs","word_nosc":"antigangs","lemma":"antigang","pos":"NOM"} ,
		{"word":"antigel","word_nosc":"antigel","lemma":"antigel","pos":"NOM"} ,
		{"word":"antigermanisme","word_nosc":"antigermanisme","lemma":"antigermanisme","pos":"NOM"} ,
		{"word":"antigravitation","word_nosc":"antigravitation","lemma":"antigravitation","pos":"NOM"} ,
		{"word":"antigravité","word_nosc":"antigravite","lemma":"antigravité","pos":"NOM"} ,
		{"word":"antigrippe","word_nosc":"antigrippe","lemma":"antigrippe","pos":"NOM"} ,
		{"word":"antigène","word_nosc":"antigene","lemma":"antigène","pos":"NOM"} ,
		{"word":"antigènes","word_nosc":"antigenes","lemma":"antigène","pos":"NOM"} ,
		{"word":"antihistaminique","word_nosc":"antihistaminique","lemma":"antihistaminique","pos":"NOM"} ,
		{"word":"antihistaminiques","word_nosc":"antihistaminiques","lemma":"antihistaminique","pos":"NOM"} ,
		{"word":"antihéros","word_nosc":"antiheros","lemma":"antihéros","pos":"NOM"} ,
		{"word":"antillais","word_nosc":"antillais","lemma":"antillais","pos":"NOM"} ,
		{"word":"antillaise","word_nosc":"antillaise","lemma":"antillais","pos":"NOM"} ,
		{"word":"antillaises","word_nosc":"antillaises","lemma":"antillais","pos":"NOM"} ,
		{"word":"antilope","word_nosc":"antilope","lemma":"antilope","pos":"NOM"} ,
		{"word":"antilopes","word_nosc":"antilopes","lemma":"antilope","pos":"NOM"} ,
		{"word":"antimatière","word_nosc":"antimatiere","lemma":"antimatière","pos":"NOM"} ,
		{"word":"antimilitarisme","word_nosc":"antimilitarisme","lemma":"antimilitarisme","pos":"NOM"} ,
		{"word":"antimilitariste","word_nosc":"antimilitariste","lemma":"antimilitariste","pos":"NOM"} ,
		{"word":"antimissiles","word_nosc":"antimissiles","lemma":"antimissile","pos":"NOM"} ,
		{"word":"antimite","word_nosc":"antimite","lemma":"antimite","pos":"NOM"} ,
		{"word":"antimites","word_nosc":"antimites","lemma":"antimite","pos":"NOM"} ,
		{"word":"antimitotiques","word_nosc":"antimitotiques","lemma":"antimitotique","pos":"NOM"} ,
		{"word":"antimoine","word_nosc":"antimoine","lemma":"antimoine","pos":"NOM"} ,
		{"word":"antinazis","word_nosc":"antinazis","lemma":"antinazi","pos":"NOM"} ,
		{"word":"antinomie","word_nosc":"antinomie","lemma":"antinomie","pos":"NOM"} ,
		{"word":"antinomies","word_nosc":"antinomies","lemma":"antinomie","pos":"NOM"} ,
		{"word":"antioxydant","word_nosc":"antioxydant","lemma":"antioxydant","pos":"NOM"} ,
		{"word":"antioxydants","word_nosc":"antioxydants","lemma":"antioxydant","pos":"NOM"} ,
		{"word":"antipape","word_nosc":"antipape","lemma":"antipape","pos":"NOM"} ,
		{"word":"antipapes","word_nosc":"antipapes","lemma":"antipape","pos":"NOM"} ,
		{"word":"antiparasite","word_nosc":"antiparasite","lemma":"antiparasite","pos":"NOM"} ,
		{"word":"antiparasites","word_nosc":"antiparasites","lemma":"antiparasite","pos":"NOM"} ,
		{"word":"antipathie","word_nosc":"antipathie","lemma":"antipathie","pos":"NOM"} ,
		{"word":"antipathies","word_nosc":"antipathies","lemma":"antipathie","pos":"NOM"} ,
		{"word":"antiphonaires","word_nosc":"antiphonaires","lemma":"antiphonaire","pos":"NOM"} ,
		{"word":"antiphrase","word_nosc":"antiphrase","lemma":"antiphrase","pos":"NOM"} ,
		{"word":"antipode","word_nosc":"antipode","lemma":"antipode","pos":"NOM"} ,
		{"word":"antipodes","word_nosc":"antipodes","lemma":"antipode","pos":"NOM"} ,
		{"word":"antipodiste","word_nosc":"antipodiste","lemma":"antipodiste","pos":"NOM"} ,
		{"word":"antipodistes","word_nosc":"antipodistes","lemma":"antipodiste","pos":"NOM"} ,
		{"word":"antipoisons","word_nosc":"antipoisons","lemma":"antipoison","pos":"NOM"} ,
		{"word":"antiproton","word_nosc":"antiproton","lemma":"antiproton","pos":"NOM"} ,
		{"word":"antiprotons","word_nosc":"antiprotons","lemma":"antiproton","pos":"NOM"} ,
		{"word":"antiprotéase","word_nosc":"antiprotease","lemma":"antiprotéase","pos":"NOM"} ,
		{"word":"antipsychiatrie","word_nosc":"antipsychiatrie","lemma":"antipsychiatrie","pos":"NOM"} ,
		{"word":"antipsychotique","word_nosc":"antipsychotique","lemma":"antipsychotique","pos":"NOM"} ,
		{"word":"antipsychotiques","word_nosc":"antipsychotiques","lemma":"antipsychotique","pos":"NOM"} ,
		{"word":"antiquaille","word_nosc":"antiquaille","lemma":"antiquaille","pos":"NOM"} ,
		{"word":"antiquailleries","word_nosc":"antiquailleries","lemma":"antiquaillerie","pos":"NOM"} ,
		{"word":"antiquailles","word_nosc":"antiquailles","lemma":"antiquaille","pos":"NOM"} ,
		{"word":"antiquaire","word_nosc":"antiquaire","lemma":"antiquaire","pos":"NOM"} ,
		{"word":"antiquaires","word_nosc":"antiquaires","lemma":"antiquaire","pos":"NOM"} ,
		{"word":"antiquark","word_nosc":"antiquark","lemma":"antiquark","pos":"NOM"} ,
		{"word":"antique","word_nosc":"antique","lemma":"antique","pos":"NOM"} ,
		{"word":"antiques","word_nosc":"antiques","lemma":"antique","pos":"NOM"} ,
		{"word":"antiquité","word_nosc":"antiquite","lemma":"antiquité","pos":"NOM"} ,
		{"word":"antiquités","word_nosc":"antiquites","lemma":"antiquité","pos":"NOM"} ,
		{"word":"antiracisme","word_nosc":"antiracisme","lemma":"antiracisme","pos":"NOM"} ,
		{"word":"antirouille","word_nosc":"antirouille","lemma":"antirouille","pos":"NOM"} ,
		{"word":"antisepsie","word_nosc":"antisepsie","lemma":"antisepsie","pos":"NOM"} ,
		{"word":"antiseptique","word_nosc":"antiseptique","lemma":"antiseptique","pos":"NOM"} ,
		{"word":"antiseptiques","word_nosc":"antiseptiques","lemma":"antiseptique","pos":"NOM"} ,
		{"word":"antisocial","word_nosc":"antisocial","lemma":"antisocial","pos":"NOM"} ,
		{"word":"antisoviétisme","word_nosc":"antisovietisme","lemma":"antisoviétisme","pos":"NOM"} ,
		{"word":"antispasmodique","word_nosc":"antispasmodique","lemma":"antispasmodique","pos":"NOM"} ,
		{"word":"antistaliniens","word_nosc":"antistaliniens","lemma":"antistalinien","pos":"NOM"} ,
		{"word":"antisèche","word_nosc":"antiseche","lemma":"antisèche","pos":"NOM"} ,
		{"word":"antisèches","word_nosc":"antiseches","lemma":"antisèche","pos":"NOM"} ,
		{"word":"antisémite","word_nosc":"antisemite","lemma":"antisémite","pos":"NOM"} ,
		{"word":"antisémites","word_nosc":"antisemites","lemma":"antisémite","pos":"NOM"} ,
		{"word":"antisémitisme","word_nosc":"antisemitisme","lemma":"antisémitisme","pos":"NOM"} ,
		{"word":"antisérum","word_nosc":"antiserum","lemma":"antisérum","pos":"NOM"} ,
		{"word":"antiterrorisme","word_nosc":"antiterrorisme","lemma":"antiterrorisme","pos":"NOM"} ,
		{"word":"antithèse","word_nosc":"antithese","lemma":"antithèse","pos":"NOM"} ,
		{"word":"antithèses","word_nosc":"antitheses","lemma":"antithèse","pos":"NOM"} ,
		{"word":"antitout","word_nosc":"antitout","lemma":"antitout","pos":"NOM"} ,
		{"word":"antitoxine","word_nosc":"antitoxine","lemma":"antitoxine","pos":"NOM"} ,
		{"word":"antituberculeux","word_nosc":"antituberculeux","lemma":"antituberculeux","pos":"NOM"} ,
		{"word":"antiviral","word_nosc":"antiviral","lemma":"antiviral","pos":"NOM"} ,
		{"word":"antiviraux","word_nosc":"antiviraux","lemma":"antiviral","pos":"NOM"} ,
		{"word":"antivirus","word_nosc":"antivirus","lemma":"antivirus","pos":"NOM"} ,
		{"word":"antivol","word_nosc":"antivol","lemma":"antivol","pos":"NOM"} ,
		{"word":"antiémétique","word_nosc":"antiemetique","lemma":"antiémétique","pos":"NOM"} ,
		{"word":"antonomase","word_nosc":"antonomase","lemma":"antonomase","pos":"NOM"} ,
		{"word":"antonyme","word_nosc":"antonyme","lemma":"antonyme","pos":"NOM"} ,
		{"word":"antonymes","word_nosc":"antonymes","lemma":"antonyme","pos":"NOM"} ,
		{"word":"antre","word_nosc":"antre","lemma":"antre","pos":"NOM"} ,
		{"word":"antres","word_nosc":"antres","lemma":"antre","pos":"NOM"} ,
		{"word":"antéchrist","word_nosc":"antechrist","lemma":"antéchrist","pos":"NOM"} ,
		{"word":"antéchrists","word_nosc":"antechrists","lemma":"antéchrist","pos":"NOM"} ,
		{"word":"antécédent","word_nosc":"antecedent","lemma":"antécédent","pos":"NOM"} ,
		{"word":"antécédents","word_nosc":"antecedents","lemma":"antécédent","pos":"NOM"} ,
		{"word":"antépénultième","word_nosc":"antepenultieme","lemma":"antépénultième","pos":"NOM"} ,
		{"word":"antépénultièmes","word_nosc":"antepenultiemes","lemma":"antépénultième","pos":"NOM"} ,
		{"word":"anus","word_nosc":"anus","lemma":"anus","pos":"NOM"} ,
		{"word":"anuscopie","word_nosc":"anuscopie","lemma":"anuscopie","pos":"NOM"} ,
		{"word":"anxieuse","word_nosc":"anxieuse","lemma":"anxieux","pos":"NOM"} ,
		{"word":"anxieux","word_nosc":"anxieux","lemma":"anxieux","pos":"NOM"} ,
		{"word":"anxiolytique","word_nosc":"anxiolytique","lemma":"anxiolytique","pos":"NOM"} ,
		{"word":"anxiolytiques","word_nosc":"anxiolytiques","lemma":"anxiolytique","pos":"NOM"} ,
		{"word":"anxiété","word_nosc":"anxiete","lemma":"anxiété","pos":"NOM"} ,
		{"word":"anxiétés","word_nosc":"anxietes","lemma":"anxiété","pos":"NOM"} ,
		{"word":"anya","word_nosc":"anya","lemma":"anya","pos":"NOM"} ,
		{"word":"anéantissement","word_nosc":"aneantissement","lemma":"anéantissement","pos":"NOM"} ,
		{"word":"anéantissements","word_nosc":"aneantissements","lemma":"anéantissement","pos":"NOM"} ,
		{"word":"anémie","word_nosc":"anemie","lemma":"anémie","pos":"NOM"} ,
		{"word":"anémomètre","word_nosc":"anemometre","lemma":"anémomètre","pos":"NOM"} ,
		{"word":"anémone","word_nosc":"anemone","lemma":"anémone","pos":"NOM"} ,
		{"word":"anémones","word_nosc":"anemones","lemma":"anémone","pos":"NOM"} ,
		{"word":"anévrisme","word_nosc":"anevrisme","lemma":"anévrisme","pos":"NOM"} ,
		{"word":"anévrismes","word_nosc":"anevrismes","lemma":"anévrisme","pos":"NOM"} ,
		{"word":"aoriste","word_nosc":"aoriste","lemma":"aoriste","pos":"NOM"} ,
		{"word":"aorte","word_nosc":"aorte","lemma":"aorte","pos":"NOM"} ,
		{"word":"aouls","word_nosc":"aouls","lemma":"aoul","pos":"NOM"} ,
		{"word":"août","word_nosc":"aout","lemma":"août","pos":"NOM"} ,
		{"word":"aoûtat","word_nosc":"aoutat","lemma":"aoûtat","pos":"NOM"} ,
		{"word":"aoûtien","word_nosc":"aoutien","lemma":"aoûtien","pos":"NOM"} ,
		{"word":"apache","word_nosc":"apache","lemma":"apache","pos":"NOM"} ,
		{"word":"apaches","word_nosc":"apaches","lemma":"apache","pos":"NOM"} ,
		{"word":"apaisement","word_nosc":"apaisement","lemma":"apaisement","pos":"NOM"} ,
		{"word":"apaisements","word_nosc":"apaisements","lemma":"apaisement","pos":"NOM"} ,
		{"word":"apanage","word_nosc":"apanage","lemma":"apanage","pos":"NOM"} ,
		{"word":"apanages","word_nosc":"apanages","lemma":"apanage","pos":"NOM"} ,
		{"word":"apartheid","word_nosc":"apartheid","lemma":"apartheid","pos":"NOM"} ,
		{"word":"aparté","word_nosc":"aparte","lemma":"aparté","pos":"NOM"} ,
		{"word":"apartés","word_nosc":"apartes","lemma":"aparté","pos":"NOM"} ,
		{"word":"apathie","word_nosc":"apathie","lemma":"apathie","pos":"NOM"} ,
		{"word":"apatride","word_nosc":"apatride","lemma":"apatride","pos":"NOM"} ,
		{"word":"apatrides","word_nosc":"apatrides","lemma":"apatride","pos":"NOM"} ,
		{"word":"apax","word_nosc":"apax","lemma":"apax","pos":"NOM"} ,
		{"word":"ape","word_nosc":"ape","lemma":"ape","pos":"NOM"} ,
		{"word":"aperceptions","word_nosc":"aperceptions","lemma":"aperception","pos":"NOM"} ,
		{"word":"aperçu","word_nosc":"apercu","lemma":"aperçu","pos":"NOM"} ,
		{"word":"aperçus","word_nosc":"apercus","lemma":"aperçu","pos":"NOM"} ,
		{"word":"apes","word_nosc":"apes","lemma":"ape","pos":"NOM"} ,
		{"word":"apesanteur","word_nosc":"apesanteur","lemma":"apesanteur","pos":"NOM"} ,
		{"word":"apex","word_nosc":"apex","lemma":"apex","pos":"NOM"} ,
		{"word":"aphaniptères","word_nosc":"aphanipteres","lemma":"aphaniptère","pos":"NOM"} ,
		{"word":"aphasie","word_nosc":"aphasie","lemma":"aphasie","pos":"NOM"} ,
		{"word":"aphonie","word_nosc":"aphonie","lemma":"aphonie","pos":"NOM"} ,
		{"word":"aphorisme","word_nosc":"aphorisme","lemma":"aphorisme","pos":"NOM"} ,
		{"word":"aphorismes","word_nosc":"aphorismes","lemma":"aphorisme","pos":"NOM"} ,
		{"word":"aphrodisiaque","word_nosc":"aphrodisiaque","lemma":"aphrodisiaque","pos":"NOM"} ,
		{"word":"aphrodisiaques","word_nosc":"aphrodisiaques","lemma":"aphrodisiaque","pos":"NOM"} ,
		{"word":"aphrodisie","word_nosc":"aphrodisie","lemma":"aphrodisie","pos":"NOM"} ,
		{"word":"aphrodite","word_nosc":"aphrodite","lemma":"aphrodite","pos":"NOM"} ,
		{"word":"aphtes","word_nosc":"aphtes","lemma":"aphte","pos":"NOM"} ,
		{"word":"aphérèse","word_nosc":"apherese","lemma":"aphérèse","pos":"NOM"} ,
		{"word":"api","word_nosc":"api","lemma":"api","pos":"NOM"} ,
		{"word":"apiculteur","word_nosc":"apiculteur","lemma":"apiculteur","pos":"NOM"} ,
		{"word":"apiculteurs","word_nosc":"apiculteurs","lemma":"apiculteur","pos":"NOM"} ,
		{"word":"apicultrice","word_nosc":"apicultrice","lemma":"apiculteur","pos":"NOM"} ,
		{"word":"apiculture","word_nosc":"apiculture","lemma":"apiculture","pos":"NOM"} ,
		{"word":"apion","word_nosc":"apion","lemma":"apion","pos":"NOM"} ,
		{"word":"apis","word_nosc":"apis","lemma":"apis","pos":"NOM"} ,
		{"word":"apitoiement","word_nosc":"apitoiement","lemma":"apitoiement","pos":"NOM"} ,
		{"word":"aplanissement","word_nosc":"aplanissement","lemma":"aplanissement","pos":"NOM"} ,
		{"word":"aplasie","word_nosc":"aplasie","lemma":"aplasie","pos":"NOM"} ,
		{"word":"aplat","word_nosc":"aplat","lemma":"aplat","pos":"NOM"} ,
		{"word":"aplatissement","word_nosc":"aplatissement","lemma":"aplatissement","pos":"NOM"} ,
		{"word":"aplatissements","word_nosc":"aplatissements","lemma":"aplatissement","pos":"NOM"} ,
		{"word":"aplats","word_nosc":"aplats","lemma":"aplat","pos":"NOM"} ,
		{"word":"aplomb","word_nosc":"aplomb","lemma":"aplomb","pos":"NOM"} ,
		{"word":"aplombs","word_nosc":"aplombs","lemma":"aplomb","pos":"NOM"} ,
		{"word":"apnée","word_nosc":"apnee","lemma":"apnée","pos":"NOM"} ,
		{"word":"apocalypse","word_nosc":"apocalypse","lemma":"apocalypse","pos":"NOM"} ,
		{"word":"apocalypses","word_nosc":"apocalypses","lemma":"apocalypse","pos":"NOM"} ,
		{"word":"apocope","word_nosc":"apocope","lemma":"apocope","pos":"NOM"} ,
		{"word":"apocryphe","word_nosc":"apocryphe","lemma":"apocryphe","pos":"NOM"} ,
		{"word":"apocryphes","word_nosc":"apocryphes","lemma":"apocryphe","pos":"NOM"} ,
		{"word":"apogée","word_nosc":"apogee","lemma":"apogée","pos":"NOM"} ,
		{"word":"apolitique","word_nosc":"apolitique","lemma":"apolitique","pos":"NOM"} ,
		{"word":"apolitiques","word_nosc":"apolitiques","lemma":"apolitique","pos":"NOM"} ,
		{"word":"apolitisme","word_nosc":"apolitisme","lemma":"apolitisme","pos":"NOM"} ,
		{"word":"apollon","word_nosc":"apollon","lemma":"apollon","pos":"NOM"} ,
		{"word":"apollons","word_nosc":"apollons","lemma":"apollon","pos":"NOM"} ,
		{"word":"apologie","word_nosc":"apologie","lemma":"apologie","pos":"NOM"} ,
		{"word":"apologiste","word_nosc":"apologiste","lemma":"apologiste","pos":"NOM"} ,
		{"word":"apologistes","word_nosc":"apologistes","lemma":"apologiste","pos":"NOM"} ,
		{"word":"apologue","word_nosc":"apologue","lemma":"apologue","pos":"NOM"} ,
		{"word":"apologétique","word_nosc":"apologetique","lemma":"apologétique","pos":"NOM"} ,
		{"word":"aponévroses","word_nosc":"aponevroses","lemma":"aponévrose","pos":"NOM"} ,
		{"word":"apophtegmes","word_nosc":"apophtegmes","lemma":"apophtegme","pos":"NOM"} ,
		{"word":"apoplectique","word_nosc":"apoplectique","lemma":"apoplectique","pos":"NOM"} ,
		{"word":"apoplectiques","word_nosc":"apoplectiques","lemma":"apoplectique","pos":"NOM"} ,
		{"word":"apoplexie","word_nosc":"apoplexie","lemma":"apoplexie","pos":"NOM"} ,
		{"word":"apoplexies","word_nosc":"apoplexies","lemma":"apoplexie","pos":"NOM"} ,
		{"word":"apoptose","word_nosc":"apoptose","lemma":"apoptose","pos":"NOM"} ,
		{"word":"apostasie","word_nosc":"apostasie","lemma":"apostasie","pos":"NOM"} ,
		{"word":"apostat","word_nosc":"apostat","lemma":"apostat","pos":"NOM"} ,
		{"word":"apostate","word_nosc":"apostate","lemma":"apostat","pos":"NOM"} ,
		{"word":"apostats","word_nosc":"apostats","lemma":"apostat","pos":"NOM"} ,
		{"word":"apostolat","word_nosc":"apostolat","lemma":"apostolat","pos":"NOM"} ,
		{"word":"apostrophe","word_nosc":"apostrophe","lemma":"apostrophe","pos":"NOM"} ,
		{"word":"apostrophes","word_nosc":"apostrophes","lemma":"apostrophe","pos":"NOM"} ,
		{"word":"apostume","word_nosc":"apostume","lemma":"apostume","pos":"NOM"} ,
		{"word":"apothicaire","word_nosc":"apothicaire","lemma":"apothicaire","pos":"NOM"} ,
		{"word":"apothicairerie","word_nosc":"apothicairerie","lemma":"apothicairerie","pos":"NOM"} ,
		{"word":"apothicaires","word_nosc":"apothicaires","lemma":"apothicaire","pos":"NOM"} ,
		{"word":"apothéose","word_nosc":"apotheose","lemma":"apothéose","pos":"NOM"} ,
		{"word":"apothéoses","word_nosc":"apotheoses","lemma":"apothéose","pos":"NOM"} ,
		{"word":"apparat","word_nosc":"apparat","lemma":"apparat","pos":"NOM"} ,
		{"word":"apparatchik","word_nosc":"apparatchik","lemma":"apparatchik","pos":"NOM"} ,
		{"word":"apparatchiks","word_nosc":"apparatchiks","lemma":"apparatchik","pos":"NOM"} ,
		{"word":"apparats","word_nosc":"apparats","lemma":"apparat","pos":"NOM"} ,
		{"word":"apparaux","word_nosc":"apparaux","lemma":"apparaux","pos":"NOM"} ,
		{"word":"appareil","word_nosc":"appareil","lemma":"appareil","pos":"NOM"} ,
		{"word":"appareil-photo","word_nosc":"appareil-photo","lemma":"appareil-photo","pos":"NOM"} ,
		{"word":"appareillage","word_nosc":"appareillage","lemma":"appareillage","pos":"NOM"} ,
		{"word":"appareillages","word_nosc":"appareillages","lemma":"appareillage","pos":"NOM"} ,
		{"word":"appareils","word_nosc":"appareils","lemma":"appareil","pos":"NOM"} ,
		{"word":"apparence","word_nosc":"apparence","lemma":"apparence","pos":"NOM"} ,
		{"word":"apparences","word_nosc":"apparences","lemma":"apparence","pos":"NOM"} ,
		{"word":"apparentement","word_nosc":"apparentement","lemma":"apparentement","pos":"NOM"} ,
		{"word":"appariteur","word_nosc":"appariteur","lemma":"appariteur","pos":"NOM"} ,
		{"word":"appariteurs","word_nosc":"appariteurs","lemma":"appariteur","pos":"NOM"} ,
		{"word":"apparition","word_nosc":"apparition","lemma":"apparition","pos":"NOM"} ,
		{"word":"apparitions","word_nosc":"apparitions","lemma":"apparition","pos":"NOM"} ,
		{"word":"appart","word_nosc":"appart","lemma":"appart","pos":"NOM"} ,
		{"word":"appartement","word_nosc":"appartement","lemma":"appartement","pos":"NOM"} ,
		{"word":"appartement-roi","word_nosc":"appartement-roi","lemma":"appartement-roi","pos":"NOM"} ,
		{"word":"appartements","word_nosc":"appartements","lemma":"appartement","pos":"NOM"} ,
		{"word":"appartenance","word_nosc":"appartenance","lemma":"appartenance","pos":"NOM"} ,
		{"word":"appartenances","word_nosc":"appartenances","lemma":"appartenance","pos":"NOM"} ,
		{"word":"apparts","word_nosc":"apparts","lemma":"appart","pos":"NOM"} ,
		{"word":"appas","word_nosc":"appas","lemma":"appas","pos":"NOM"} ,
		{"word":"appauvrissement","word_nosc":"appauvrissement","lemma":"appauvrissement","pos":"NOM"} ,
		{"word":"appeau","word_nosc":"appeau","lemma":"appeau","pos":"NOM"} ,
		{"word":"appeaux","word_nosc":"appeaux","lemma":"appeau","pos":"NOM"} ,
		{"word":"appel","word_nosc":"appel","lemma":"appel","pos":"NOM"} ,
		{"word":"appelant","word_nosc":"appelant","lemma":"appelant","pos":"NOM"} ,
		{"word":"appelants","word_nosc":"appelants","lemma":"appelant","pos":"NOM"} ,
		{"word":"appeleur","word_nosc":"appeleur","lemma":"appeleur","pos":"NOM"} ,
		{"word":"appellation","word_nosc":"appellation","lemma":"appellation","pos":"NOM"} ,
		{"word":"appellations","word_nosc":"appellations","lemma":"appellation","pos":"NOM"} ,
		{"word":"appels","word_nosc":"appels","lemma":"appel","pos":"NOM"} ,
		{"word":"appelé","word_nosc":"appele","lemma":"appelé","pos":"NOM"} ,
		{"word":"appelés","word_nosc":"appeles","lemma":"appelé","pos":"NOM"} ,
		{"word":"appendice","word_nosc":"appendice","lemma":"appendice","pos":"NOM"} ,
		{"word":"appendicectomie","word_nosc":"appendicectomie","lemma":"appendicectomie","pos":"NOM"} ,
		{"word":"appendices","word_nosc":"appendices","lemma":"appendice","pos":"NOM"} ,
		{"word":"appendicite","word_nosc":"appendicite","lemma":"appendicite","pos":"NOM"} ,
		{"word":"appentis","word_nosc":"appentis","lemma":"appentis","pos":"NOM"} ,
		{"word":"appesantissement","word_nosc":"appesantissement","lemma":"appesantissement","pos":"NOM"} ,
		{"word":"applaudimètre","word_nosc":"applaudimetre","lemma":"applaudimètre","pos":"NOM"} ,
		{"word":"applaudissement","word_nosc":"applaudissement","lemma":"applaudissement","pos":"NOM"} ,
		{"word":"applaudissements","word_nosc":"applaudissements","lemma":"applaudissement","pos":"NOM"} ,
		{"word":"applaudisseur","word_nosc":"applaudisseur","lemma":"applaudisseur","pos":"NOM"} ,
		{"word":"applicabilité","word_nosc":"applicabilite","lemma":"applicabilité","pos":"NOM"} ,
		{"word":"applicateur","word_nosc":"applicateur","lemma":"applicateur","pos":"NOM"} ,
		{"word":"application","word_nosc":"application","lemma":"application","pos":"NOM"} ,
		{"word":"applications","word_nosc":"applications","lemma":"application","pos":"NOM"} ,
		{"word":"applique","word_nosc":"applique","lemma":"applique","pos":"NOM"} ,
		{"word":"appliques","word_nosc":"appliques","lemma":"applique","pos":"NOM"} ,
		{"word":"appoggiature","word_nosc":"appoggiature","lemma":"appoggiature","pos":"NOM"} ,
		{"word":"appoggiatures","word_nosc":"appoggiatures","lemma":"appoggiature","pos":"NOM"} ,
		{"word":"appoint","word_nosc":"appoint","lemma":"appoint","pos":"NOM"} ,
		{"word":"appointements","word_nosc":"appointements","lemma":"appointement","pos":"NOM"} ,
		{"word":"appontage","word_nosc":"appontage","lemma":"appontage","pos":"NOM"} ,
		{"word":"appontement","word_nosc":"appontement","lemma":"appontement","pos":"NOM"} ,
		{"word":"apport","word_nosc":"apport","lemma":"apport","pos":"NOM"} ,
		{"word":"apports","word_nosc":"apports","lemma":"apport","pos":"NOM"} ,
		{"word":"apposition","word_nosc":"apposition","lemma":"apposition","pos":"NOM"} ,
		{"word":"apprenant","word_nosc":"apprenant","lemma":"apprenant","pos":"NOM"} ,
		{"word":"apprenti","word_nosc":"apprenti","lemma":"apprenti","pos":"NOM"} ,
		{"word":"apprenti-pilote","word_nosc":"apprenti-pilote","lemma":"apprenti-pilote","pos":"NOM"} ,
		{"word":"apprenti-sorcier","word_nosc":"apprenti-sorcier","lemma":"apprenti-sorcier","pos":"NOM"} ,
		{"word":"apprentie","word_nosc":"apprentie","lemma":"apprenti","pos":"NOM"} ,
		{"word":"apprenties","word_nosc":"apprenties","lemma":"apprenti","pos":"NOM"} ,
		{"word":"apprentis","word_nosc":"apprentis","lemma":"apprenti","pos":"NOM"} ,
		{"word":"apprentissage","word_nosc":"apprentissage","lemma":"apprentissage","pos":"NOM"} ,
		{"word":"apprentissages","word_nosc":"apprentissages","lemma":"apprentissage","pos":"NOM"} ,
		{"word":"approbation","word_nosc":"approbation","lemma":"approbation","pos":"NOM"} ,
		{"word":"approbations","word_nosc":"approbations","lemma":"approbation","pos":"NOM"} ,
		{"word":"approche","word_nosc":"approche","lemma":"approche","pos":"NOM"} ,
		{"word":"approches","word_nosc":"approches","lemma":"approche","pos":"NOM"} ,
		{"word":"approfondissement","word_nosc":"approfondissement","lemma":"approfondissement","pos":"NOM"} ,
		{"word":"appropriation","word_nosc":"appropriation","lemma":"appropriation","pos":"NOM"} ,
		{"word":"appropriations","word_nosc":"appropriations","lemma":"appropriation","pos":"NOM"} ,
		{"word":"approvisionnement","word_nosc":"approvisionnement","lemma":"approvisionnement","pos":"NOM"} ,
		{"word":"approvisionnements","word_nosc":"approvisionnements","lemma":"approvisionnement","pos":"NOM"} ,
		{"word":"approximation","word_nosc":"approximation","lemma":"approximation","pos":"NOM"} ,
		{"word":"approximations","word_nosc":"approximations","lemma":"approximation","pos":"NOM"} ,
		{"word":"appréciateur","word_nosc":"appreciateur","lemma":"appréciateur","pos":"NOM"} ,
		{"word":"appréciation","word_nosc":"appreciation","lemma":"appréciation","pos":"NOM"} ,
		{"word":"appréciations","word_nosc":"appreciations","lemma":"appréciation","pos":"NOM"} ,
		{"word":"appréciatrice","word_nosc":"appreciatrice","lemma":"appréciateur","pos":"NOM"} ,
		{"word":"appréhension","word_nosc":"apprehension","lemma":"appréhension","pos":"NOM"} ,
		{"word":"appréhensions","word_nosc":"apprehensions","lemma":"appréhension","pos":"NOM"} ,
		{"word":"apprêt","word_nosc":"appret","lemma":"apprêt","pos":"NOM"} ,
		{"word":"apprêts","word_nosc":"apprets","lemma":"apprêt","pos":"NOM"} ,
		{"word":"appui","word_nosc":"appui","lemma":"appui","pos":"NOM"} ,
		{"word":"appui-main","word_nosc":"appui-main","lemma":"appui-main","pos":"NOM"} ,
		{"word":"appui-tête","word_nosc":"appui-tete","lemma":"appui-tête","pos":"NOM"} ,
		{"word":"appuie-tête","word_nosc":"appuie-tete","lemma":"appuie-tête","pos":"NOM"} ,
		{"word":"appuis","word_nosc":"appuis","lemma":"appui","pos":"NOM"} ,
		{"word":"appât","word_nosc":"appat","lemma":"appât","pos":"NOM"} ,
		{"word":"appâts","word_nosc":"appats","lemma":"appât","pos":"NOM"} ,
		{"word":"appétence","word_nosc":"appetence","lemma":"appétence","pos":"NOM"} ,
		{"word":"appétences","word_nosc":"appetences","lemma":"appétence","pos":"NOM"} ,
		{"word":"appétit","word_nosc":"appetit","lemma":"appétit","pos":"NOM"} ,
		{"word":"appétits","word_nosc":"appetits","lemma":"appétit","pos":"NOM"} ,
		{"word":"aprèm","word_nosc":"aprem","lemma":"aprèm","pos":"NOM"} ,
		{"word":"aprème","word_nosc":"apreme","lemma":"aprèm","pos":"NOM"} ,
		{"word":"après-coup","word_nosc":"apres-coup","lemma":"après-coup","pos":"NOM"} ,
		{"word":"après-déjeuner","word_nosc":"apres-dejeuner","lemma":"après-déjeuner","pos":"NOM"} ,
		{"word":"après-dîner","word_nosc":"apres-diner","lemma":"après-dîner","pos":"NOM"} ,
		{"word":"après-dîners","word_nosc":"apres-diners","lemma":"après-dîner","pos":"NOM"} ,
		{"word":"après-guerre","word_nosc":"apres-guerre","lemma":"après-guerre","pos":"NOM"} ,
		{"word":"après-mort","word_nosc":"apres-mort","lemma":"après-mort","pos":"NOM"} ,
		{"word":"après-rasage","word_nosc":"apres-rasage","lemma":"après-rasage","pos":"NOM"} ,
		{"word":"après-repas","word_nosc":"apres-repas","lemma":"après-repas","pos":"NOM"} ,
		{"word":"après-shampoing","word_nosc":"apres-shampoing","lemma":"après-shampoing","pos":"NOM"} ,
		{"word":"après-shampooing","word_nosc":"apres-shampooing","lemma":"après-shampooing","pos":"NOM"} ,
		{"word":"après-ski","word_nosc":"apres-ski","lemma":"après-ski","pos":"NOM"} ,
		{"word":"aptitude","word_nosc":"aptitude","lemma":"aptitude","pos":"NOM"} ,
		{"word":"aptitudes","word_nosc":"aptitudes","lemma":"aptitude","pos":"NOM"} ,
		{"word":"apéritif","word_nosc":"aperitif","lemma":"apéritif","pos":"NOM"} ,
		{"word":"apéritifs","word_nosc":"aperitifs","lemma":"apéritif","pos":"NOM"} ,
		{"word":"apéro","word_nosc":"apero","lemma":"apéro","pos":"NOM"} ,
		{"word":"apéros","word_nosc":"aperos","lemma":"apéro","pos":"NOM"} ,
		{"word":"apôtre","word_nosc":"apotre","lemma":"apôtre","pos":"NOM"} ,
		{"word":"apôtres","word_nosc":"apotres","lemma":"apôtre","pos":"NOM"} ,
		{"word":"aquaculture","word_nosc":"aquaculture","lemma":"aquaculture","pos":"NOM"} ,
		{"word":"aquagym","word_nosc":"aquagym","lemma":"aquagym","pos":"NOM"} ,
		{"word":"aquaplane","word_nosc":"aquaplane","lemma":"aquaplane","pos":"NOM"} ,
		{"word":"aquarelle","word_nosc":"aquarelle","lemma":"aquarelle","pos":"NOM"} ,
		{"word":"aquarelles","word_nosc":"aquarelles","lemma":"aquarelle","pos":"NOM"} ,
		{"word":"aquarelliste","word_nosc":"aquarelliste","lemma":"aquarelliste","pos":"NOM"} ,
		{"word":"aquarellistes","word_nosc":"aquarellistes","lemma":"aquarelliste","pos":"NOM"} ,
		{"word":"aquariophilie","word_nosc":"aquariophilie","lemma":"aquariophilie","pos":"NOM"} ,
		{"word":"aquarium","word_nosc":"aquarium","lemma":"aquarium","pos":"NOM"} ,
		{"word":"aquariums","word_nosc":"aquariums","lemma":"aquarium","pos":"NOM"} ,
		{"word":"aquatinte","word_nosc":"aquatinte","lemma":"aquatinte","pos":"NOM"} ,
		{"word":"aquavit","word_nosc":"aquavit","lemma":"aquavit","pos":"NOM"} ,
		{"word":"aqueduc","word_nosc":"aqueduc","lemma":"aqueduc","pos":"NOM"} ,
		{"word":"aqueducs","word_nosc":"aqueducs","lemma":"aqueduc","pos":"NOM"} ,
		{"word":"aquilon","word_nosc":"aquilon","lemma":"aquilon","pos":"NOM"} ,
		{"word":"aquilons","word_nosc":"aquilons","lemma":"aquilon","pos":"NOM"} ,
		{"word":"ara","word_nosc":"ara","lemma":"ara","pos":"NOM"} ,
		{"word":"arabe","word_nosc":"arabe","lemma":"arabe","pos":"NOM"} ,
		{"word":"arabes","word_nosc":"arabes","lemma":"arabe","pos":"NOM"} ,
		{"word":"arabesque","word_nosc":"arabesque","lemma":"arabesque","pos":"NOM"} ,
		{"word":"arabesques","word_nosc":"arabesques","lemma":"arabesque","pos":"NOM"} ,
		{"word":"arabica","word_nosc":"arabica","lemma":"arabica","pos":"NOM"} ,
		{"word":"arabicas","word_nosc":"arabicas","lemma":"arabica","pos":"NOM"} ,
		{"word":"arabisants","word_nosc":"arabisants","lemma":"arabisant","pos":"NOM"} ,
		{"word":"arac","word_nosc":"arac","lemma":"arac","pos":"NOM"} ,
		{"word":"arachide","word_nosc":"arachide","lemma":"arachide","pos":"NOM"} ,
		{"word":"arachides","word_nosc":"arachides","lemma":"arachide","pos":"NOM"} ,
		{"word":"arachnide","word_nosc":"arachnide","lemma":"arachnide","pos":"NOM"} ,
		{"word":"arachnides","word_nosc":"arachnides","lemma":"arachnide","pos":"NOM"} ,
		{"word":"arachnoïde","word_nosc":"arachnoide","lemma":"arachnoïde","pos":"NOM"} ,
		{"word":"arachnoïdes","word_nosc":"arachnoides","lemma":"arachnoïde","pos":"NOM"} ,
		{"word":"aragonais","word_nosc":"aragonais","lemma":"aragonais","pos":"NOM"} ,
		{"word":"aragonite","word_nosc":"aragonite","lemma":"aragonite","pos":"NOM"} ,
		{"word":"araigne","word_nosc":"araigne","lemma":"araigne","pos":"NOM"} ,
		{"word":"araignée","word_nosc":"araignee","lemma":"araignée","pos":"NOM"} ,
		{"word":"araignée-crabe","word_nosc":"araignee-crabe","lemma":"araignée-crabe","pos":"NOM"} ,
		{"word":"araignées","word_nosc":"araignees","lemma":"araignée","pos":"NOM"} ,
		{"word":"araire","word_nosc":"araire","lemma":"araire","pos":"NOM"} ,
		{"word":"arak","word_nosc":"arak","lemma":"arak","pos":"NOM"} ,
		{"word":"araldite","word_nosc":"araldite","lemma":"araldite","pos":"NOM"} ,
		{"word":"aralia","word_nosc":"aralia","lemma":"aralia","pos":"NOM"} ,
		{"word":"aramon","word_nosc":"aramon","lemma":"aramon","pos":"NOM"} ,
		{"word":"araméen","word_nosc":"arameen","lemma":"araméen","pos":"NOM"} ,
		{"word":"arapèdes","word_nosc":"arapedes","lemma":"arapède","pos":"NOM"} ,
		{"word":"aras","word_nosc":"aras","lemma":"ara","pos":"NOM"} ,
		{"word":"araucan","word_nosc":"araucan","lemma":"araucan","pos":"NOM"} ,
		{"word":"araucaria","word_nosc":"araucaria","lemma":"araucaria","pos":"NOM"} ,
		{"word":"araucarias","word_nosc":"araucarias","lemma":"araucaria","pos":"NOM"} ,
		{"word":"arbalète","word_nosc":"arbalete","lemma":"arbalète","pos":"NOM"} ,
		{"word":"arbalètes","word_nosc":"arbaletes","lemma":"arbalète","pos":"NOM"} ,
		{"word":"arbalétrier","word_nosc":"arbaletrier","lemma":"arbalétrier","pos":"NOM"} ,
		{"word":"arbalétriers","word_nosc":"arbaletriers","lemma":"arbalétrier","pos":"NOM"} ,
		{"word":"arbi","word_nosc":"arbi","lemma":"arbi","pos":"NOM"} ,
		{"word":"arbis","word_nosc":"arbis","lemma":"arbi","pos":"NOM"} ,
		{"word":"arbitrage","word_nosc":"arbitrage","lemma":"arbitrage","pos":"NOM"} ,
		{"word":"arbitrages","word_nosc":"arbitrages","lemma":"arbitrage","pos":"NOM"} ,
		{"word":"arbitraire","word_nosc":"arbitraire","lemma":"arbitraire","pos":"NOM"} ,
		{"word":"arbitre","word_nosc":"arbitre","lemma":"arbitre","pos":"NOM"} ,
		{"word":"arbitres","word_nosc":"arbitres","lemma":"arbitre","pos":"NOM"} ,
		{"word":"arbois","word_nosc":"arbois","lemma":"arbois","pos":"NOM"} ,
		{"word":"arborescence","word_nosc":"arborescence","lemma":"arborescence","pos":"NOM"} ,
		{"word":"arborescences","word_nosc":"arborescences","lemma":"arborescence","pos":"NOM"} ,
		{"word":"arboretum","word_nosc":"arboretum","lemma":"arboretum","pos":"NOM"} ,
		{"word":"arboriculteur","word_nosc":"arboriculteur","lemma":"arboriculteur","pos":"NOM"} ,
		{"word":"arboriculture","word_nosc":"arboriculture","lemma":"arboriculture","pos":"NOM"} ,
		{"word":"arborisations","word_nosc":"arborisations","lemma":"arborisation","pos":"NOM"} ,
		{"word":"arbouse","word_nosc":"arbouse","lemma":"arbouse","pos":"NOM"} ,
		{"word":"arbouses","word_nosc":"arbouses","lemma":"arbouse","pos":"NOM"} ,
		{"word":"arbousiers","word_nosc":"arbousiers","lemma":"arbousier","pos":"NOM"} ,
		{"word":"arbre","word_nosc":"arbre","lemma":"arbre","pos":"NOM"} ,
		{"word":"arbres","word_nosc":"arbres","lemma":"arbre","pos":"NOM"} ,
		{"word":"arbres-refuges","word_nosc":"arbres-refuges","lemma":"arbres-refuge","pos":"NOM"} ,
		{"word":"arbrisseau","word_nosc":"arbrisseau","lemma":"arbrisseau","pos":"NOM"} ,
		{"word":"arbrisseaux","word_nosc":"arbrisseaux","lemma":"arbrisseau","pos":"NOM"} ,
		{"word":"arbuste","word_nosc":"arbuste","lemma":"arbuste","pos":"NOM"} ,
		{"word":"arbustes","word_nosc":"arbustes","lemma":"arbuste","pos":"NOM"} ,
		{"word":"arc","word_nosc":"arc","lemma":"arc","pos":"NOM"} ,
		{"word":"arc-boutant","word_nosc":"arc-boutant","lemma":"arc-boutant","pos":"NOM"} ,
		{"word":"arc-boutants","word_nosc":"arc-boutants","lemma":"arc-boutant","pos":"NOM"} ,
		{"word":"arc-boutement","word_nosc":"arc-boutement","lemma":"arc-boutement","pos":"NOM"} ,
		{"word":"arc-en-ciel","word_nosc":"arc-en-ciel","lemma":"arc-en-ciel","pos":"NOM"} ,
		{"word":"arcade","word_nosc":"arcade","lemma":"arcade","pos":"NOM"} ,
		{"word":"arcades","word_nosc":"arcades","lemma":"arcade","pos":"NOM"} ,
		{"word":"arcadiens","word_nosc":"arcadiens","lemma":"arcadien","pos":"NOM"} ,
		{"word":"arcan","word_nosc":"arcan","lemma":"arcan","pos":"NOM"} ,
		{"word":"arcane","word_nosc":"arcane","lemma":"arcane","pos":"NOM"} ,
		{"word":"arcanes","word_nosc":"arcanes","lemma":"arcane","pos":"NOM"} ,
		{"word":"arcans","word_nosc":"arcans","lemma":"arcan","pos":"NOM"} ,
		{"word":"arcatures","word_nosc":"arcatures","lemma":"arcature","pos":"NOM"} ,
		{"word":"arceau","word_nosc":"arceau","lemma":"arceau","pos":"NOM"} ,
		{"word":"arceaux","word_nosc":"arceaux","lemma":"arceau","pos":"NOM"} ,
		{"word":"archange","word_nosc":"archange","lemma":"archange","pos":"NOM"} ,
		{"word":"archanges","word_nosc":"archanges","lemma":"archange","pos":"NOM"} ,
		{"word":"archaïsant","word_nosc":"archaisant","lemma":"archaïsant","pos":"NOM"} ,
		{"word":"archaïsme","word_nosc":"archaisme","lemma":"archaïsme","pos":"NOM"} ,
		{"word":"arche","word_nosc":"arche","lemma":"arche","pos":"NOM"} ,
		{"word":"archer","word_nosc":"archer","lemma":"archer","pos":"NOM"} ,
		{"word":"archers","word_nosc":"archers","lemma":"archer","pos":"NOM"} ,
		{"word":"arches","word_nosc":"arches","lemma":"arche","pos":"NOM"} ,
		{"word":"archet","word_nosc":"archet","lemma":"archet","pos":"NOM"} ,
		{"word":"archets","word_nosc":"archets","lemma":"archet","pos":"NOM"} ,
		{"word":"archevêché","word_nosc":"archeveche","lemma":"archevêché","pos":"NOM"} ,
		{"word":"archevêchés","word_nosc":"archeveches","lemma":"archevêché","pos":"NOM"} ,
		{"word":"archevêque","word_nosc":"archeveque","lemma":"archevêque","pos":"NOM"} ,
		{"word":"archevêques","word_nosc":"archeveques","lemma":"archevêque","pos":"NOM"} ,
		{"word":"archi","word_nosc":"archi","lemma":"archi","pos":"NOM"} ,
		{"word":"archi-duchesse","word_nosc":"archi-duchesse","lemma":"archiduc","pos":"NOM"} ,
		{"word":"archi-mortels","word_nosc":"archi-mortels","lemma":"archi-mortels","pos":"NOM"} ,
		{"word":"archi-morts","word_nosc":"archi-morts","lemma":"archi-mort","pos":"NOM"} ,
		{"word":"archi-méfiance","word_nosc":"archi-mefiance","lemma":"archi-méfiance","pos":"NOM"} ,
		{"word":"archi-nul","word_nosc":"archi-nul","lemma":"archi-nul","pos":"NOM"} ,
		{"word":"archi-nuls","word_nosc":"archi-nuls","lemma":"archi-nuls","pos":"NOM"} ,
		{"word":"archi-plein","word_nosc":"archi-plein","lemma":"archi-plein","pos":"NOM"} ,
		{"word":"archi-prudent","word_nosc":"archi-prudent","lemma":"archi-prudent","pos":"NOM"} ,
		{"word":"archi-ringard","word_nosc":"archi-ringard","lemma":"archi-ringard","pos":"NOM"} ,
		{"word":"archiatre","word_nosc":"archiatre","lemma":"archiatre","pos":"NOM"} ,
		{"word":"archidiacre","word_nosc":"archidiacre","lemma":"archidiacre","pos":"NOM"} ,
		{"word":"archidiocèse","word_nosc":"archidiocese","lemma":"archidiocèse","pos":"NOM"} ,
		{"word":"archiduc","word_nosc":"archiduc","lemma":"archiduc","pos":"NOM"} ,
		{"word":"archiduchesse","word_nosc":"archiduchesse","lemma":"archiduc","pos":"NOM"} ,
		{"word":"archiducs","word_nosc":"archiducs","lemma":"archiduc","pos":"NOM"} ,
		{"word":"archie","word_nosc":"archie","lemma":"archi-e","pos":"NOM"} ,
		{"word":"archimandrite","word_nosc":"archimandrite","lemma":"archimandrite","pos":"NOM"} ,
		{"word":"archipel","word_nosc":"archipel","lemma":"archipel","pos":"NOM"} ,
		{"word":"archipels","word_nosc":"archipels","lemma":"archipel","pos":"NOM"} ,
		{"word":"archiprêtre","word_nosc":"archipretre","lemma":"archiprêtre","pos":"NOM"} ,
		{"word":"architecte","word_nosc":"architecte","lemma":"architecte","pos":"NOM"} ,
		{"word":"architectes","word_nosc":"architectes","lemma":"architecte","pos":"NOM"} ,
		{"word":"architectonie","word_nosc":"architectonie","lemma":"architectonie","pos":"NOM"} ,
		{"word":"architecture","word_nosc":"architecture","lemma":"architecture","pos":"NOM"} ,
		{"word":"architectures","word_nosc":"architectures","lemma":"architecture","pos":"NOM"} ,
		{"word":"architrave","word_nosc":"architrave","lemma":"architrave","pos":"NOM"} ,
		{"word":"archivage","word_nosc":"archivage","lemma":"archivage","pos":"NOM"} ,
		{"word":"archive","word_nosc":"archive","lemma":"archive","pos":"NOM"} ,
		{"word":"archives","word_nosc":"archives","lemma":"archive","pos":"NOM"} ,
		{"word":"archiviste","word_nosc":"archiviste","lemma":"archiviste","pos":"NOM"} ,
		{"word":"archiviste-paléographe","word_nosc":"archiviste-paleographe","lemma":"archiviste-paléographe","pos":"NOM"} ,
		{"word":"archivistes","word_nosc":"archivistes","lemma":"archiviste","pos":"NOM"} ,
		{"word":"archivistes-paléographes","word_nosc":"archivistes-paleographes","lemma":"archiviste-paléographe","pos":"NOM"} ,
		{"word":"archivolte","word_nosc":"archivolte","lemma":"archivolte","pos":"NOM"} ,
		{"word":"archivoltes","word_nosc":"archivoltes","lemma":"archivolte","pos":"NOM"} ,
		{"word":"archonte","word_nosc":"archonte","lemma":"archonte","pos":"NOM"} ,
		{"word":"archères","word_nosc":"archeres","lemma":"archer","pos":"NOM"} ,
		{"word":"archéologie","word_nosc":"archeologie","lemma":"archéologie","pos":"NOM"} ,
		{"word":"archéologue","word_nosc":"archeologue","lemma":"archéologue","pos":"NOM"} ,
		{"word":"archéologues","word_nosc":"archeologues","lemma":"archéologue","pos":"NOM"} ,
		{"word":"archéoptéryx","word_nosc":"archeopteryx","lemma":"archéoptéryx","pos":"NOM"} ,
		{"word":"archétype","word_nosc":"archetype","lemma":"archétype","pos":"NOM"} ,
		{"word":"archétypes","word_nosc":"archetypes","lemma":"archétype","pos":"NOM"} ,
		{"word":"arcs","word_nosc":"arcs","lemma":"arc","pos":"NOM"} ,
		{"word":"arcs-boutants","word_nosc":"arcs-boutants","lemma":"arc-boutant","pos":"NOM"} ,
		{"word":"arcs-en-ciel","word_nosc":"arcs-en-ciel","lemma":"arc-en-ciel","pos":"NOM"} ,
		{"word":"ardennais","word_nosc":"ardennais","lemma":"ardennais","pos":"NOM"} ,
		{"word":"ardennaise","word_nosc":"ardennaise","lemma":"ardennais","pos":"NOM"} ,
		{"word":"ardent","word_nosc":"ardent","lemma":"ardent","pos":"NOM"} ,
		{"word":"ardents","word_nosc":"ardents","lemma":"ardent","pos":"NOM"} ,
		{"word":"ardeur","word_nosc":"ardeur","lemma":"ardeur","pos":"NOM"} ,
		{"word":"ardeurs","word_nosc":"ardeurs","lemma":"ardeur","pos":"NOM"} ,
		{"word":"ardillon","word_nosc":"ardillon","lemma":"ardillon","pos":"NOM"} ,
		{"word":"ardillons","word_nosc":"ardillons","lemma":"ardillon","pos":"NOM"} ,
		{"word":"ardin","word_nosc":"ardin","lemma":"ardin","pos":"NOM"} ,
		{"word":"ardoise","word_nosc":"ardoise","lemma":"ardoise","pos":"NOM"} ,
		{"word":"ardoises","word_nosc":"ardoises","lemma":"ardoise","pos":"NOM"} ,
		{"word":"ardoisière","word_nosc":"ardoisiere","lemma":"ardoisier","pos":"NOM"} ,
		{"word":"ardéchois","word_nosc":"ardechois","lemma":"ardéchois","pos":"NOM"} ,
		{"word":"are","word_nosc":"are","lemma":"are","pos":"NOM"} ,
		{"word":"area","word_nosc":"area","lemma":"area","pos":"NOM"} ,
		{"word":"arec","word_nosc":"arec","lemma":"arec","pos":"NOM"} ,
		{"word":"ares","word_nosc":"ares","lemma":"are","pos":"NOM"} ,
		{"word":"arganier","word_nosc":"arganier","lemma":"arganier","pos":"NOM"} ,
		{"word":"arganiers","word_nosc":"arganiers","lemma":"arganier","pos":"NOM"} ,
		{"word":"argans","word_nosc":"argans","lemma":"argan","pos":"NOM"} ,
		{"word":"argent","word_nosc":"argent","lemma":"argent","pos":"NOM"} ,
		{"word":"argenterie","word_nosc":"argenterie","lemma":"argenterie","pos":"NOM"} ,
		{"word":"argenteries","word_nosc":"argenteries","lemma":"argenterie","pos":"NOM"} ,
		{"word":"argentier","word_nosc":"argentier","lemma":"argentier","pos":"NOM"} ,
		{"word":"argentin","word_nosc":"argentin","lemma":"argentin","pos":"NOM"} ,
		{"word":"argentines","word_nosc":"argentines","lemma":"argentin","pos":"NOM"} ,
		{"word":"argentins","word_nosc":"argentins","lemma":"argentin","pos":"NOM"} ,
		{"word":"argenton","word_nosc":"argenton","lemma":"argenton","pos":"NOM"} ,
		{"word":"argents","word_nosc":"argents","lemma":"argent","pos":"NOM"} ,
		{"word":"argile","word_nosc":"argile","lemma":"argile","pos":"NOM"} ,
		{"word":"argiles","word_nosc":"argiles","lemma":"argile","pos":"NOM"} ,
		{"word":"argilière","word_nosc":"argiliere","lemma":"argilière","pos":"NOM"} ,
		{"word":"argol","word_nosc":"argol","lemma":"argol","pos":"NOM"} ,
		{"word":"argon","word_nosc":"argon","lemma":"argon","pos":"NOM"} ,
		{"word":"argonaute","word_nosc":"argonaute","lemma":"argonaute","pos":"NOM"} ,
		{"word":"argot","word_nosc":"argot","lemma":"argot","pos":"NOM"} ,
		{"word":"argots","word_nosc":"argots","lemma":"argot","pos":"NOM"} ,
		{"word":"argousin","word_nosc":"argousin","lemma":"argousin","pos":"NOM"} ,
		{"word":"argousins","word_nosc":"argousins","lemma":"argousin","pos":"NOM"} ,
		{"word":"argument","word_nosc":"argument","lemma":"argument","pos":"NOM"} ,
		{"word":"argumentaire","word_nosc":"argumentaire","lemma":"argumentaire","pos":"NOM"} ,
		{"word":"argumentateur","word_nosc":"argumentateur","lemma":"argumentateur","pos":"NOM"} ,
		{"word":"argumentatif","word_nosc":"argumentatif","lemma":"argumentatif","pos":"NOM"} ,
		{"word":"argumentation","word_nosc":"argumentation","lemma":"argumentation","pos":"NOM"} ,
		{"word":"argumentations","word_nosc":"argumentations","lemma":"argumentation","pos":"NOM"} ,
		{"word":"arguments","word_nosc":"arguments","lemma":"argument","pos":"NOM"} ,
		{"word":"argus","word_nosc":"argus","lemma":"argus","pos":"NOM"} ,
		{"word":"argutie","word_nosc":"argutie","lemma":"argutie","pos":"NOM"} ,
		{"word":"arguties","word_nosc":"arguties","lemma":"argutie","pos":"NOM"} ,
		{"word":"aria","word_nosc":"aria","lemma":"aria","pos":"NOM"} ,
		{"word":"arianisme","word_nosc":"arianisme","lemma":"arianisme","pos":"NOM"} ,
		{"word":"arias","word_nosc":"arias","lemma":"aria","pos":"NOM"} ,
		{"word":"aridité","word_nosc":"aridite","lemma":"aridité","pos":"NOM"} ,
		{"word":"arien","word_nosc":"arien","lemma":"arien","pos":"NOM"} ,
		{"word":"ariens","word_nosc":"ariens","lemma":"arien","pos":"NOM"} ,
		{"word":"ariettes","word_nosc":"ariettes","lemma":"ariette","pos":"NOM"} ,
		{"word":"arioso","word_nosc":"arioso","lemma":"arioso","pos":"NOM"} ,
		{"word":"aristarque","word_nosc":"aristarque","lemma":"aristarque","pos":"NOM"} ,
		{"word":"aristarques","word_nosc":"aristarques","lemma":"aristarque","pos":"NOM"} ,
		{"word":"aristo","word_nosc":"aristo","lemma":"aristo","pos":"NOM"} ,
		{"word":"aristocrate","word_nosc":"aristocrate","lemma":"aristocrate","pos":"NOM"} ,
		{"word":"aristocrates","word_nosc":"aristocrates","lemma":"aristocrate","pos":"NOM"} ,
		{"word":"aristocratie","word_nosc":"aristocratie","lemma":"aristocratie","pos":"NOM"} ,
		{"word":"aristocratisme","word_nosc":"aristocratisme","lemma":"aristocratisme","pos":"NOM"} ,
		{"word":"aristoloche","word_nosc":"aristoloche","lemma":"aristoloche","pos":"NOM"} ,
		{"word":"aristoloches","word_nosc":"aristoloches","lemma":"aristoloche","pos":"NOM"} ,
		{"word":"aristos","word_nosc":"aristos","lemma":"aristo","pos":"NOM"} ,
		{"word":"arithmomètre","word_nosc":"arithmometre","lemma":"arithmomètre","pos":"NOM"} ,
		{"word":"arithmétique","word_nosc":"arithmetique","lemma":"arithmétique","pos":"NOM"} ,
		{"word":"ariégeois","word_nosc":"ariegeois","lemma":"ariégeois","pos":"NOM"} ,
		{"word":"arkose","word_nosc":"arkose","lemma":"arkose","pos":"NOM"} ,
		{"word":"arlequin","word_nosc":"arlequin","lemma":"arlequin","pos":"NOM"} ,
		{"word":"arlequins","word_nosc":"arlequins","lemma":"arlequin","pos":"NOM"} ,
		{"word":"arlésien","word_nosc":"arlesien","lemma":"arlésien","pos":"NOM"} ,
		{"word":"arlésienne","word_nosc":"arlesienne","lemma":"arlésienne","pos":"NOM"} ,
		{"word":"arlésiennes","word_nosc":"arlesiennes","lemma":"arlésien","pos":"NOM"} ,
		{"word":"armada","word_nosc":"armada","lemma":"armada","pos":"NOM"} ,
		{"word":"armadas","word_nosc":"armadas","lemma":"armada","pos":"NOM"} ,
		{"word":"armadille","word_nosc":"armadille","lemma":"armadille","pos":"NOM"} ,
		{"word":"armagnac","word_nosc":"armagnac","lemma":"armagnac","pos":"NOM"} ,
		{"word":"armagnacs","word_nosc":"armagnacs","lemma":"armagnac","pos":"NOM"} ,
		{"word":"armateur","word_nosc":"armateur","lemma":"armateur","pos":"NOM"} ,
		{"word":"armateurs","word_nosc":"armateurs","lemma":"armateur","pos":"NOM"} ,
		{"word":"armature","word_nosc":"armature","lemma":"armature","pos":"NOM"} ,
		{"word":"armatures","word_nosc":"armatures","lemma":"armature","pos":"NOM"} ,
		{"word":"arme","word_nosc":"arme","lemma":"arme","pos":"NOM"} ,
		{"word":"arme-miracle","word_nosc":"arme-miracle","lemma":"arme-miracle","pos":"NOM"} ,
		{"word":"armement","word_nosc":"armement","lemma":"armement","pos":"NOM"} ,
		{"word":"armements","word_nosc":"armements","lemma":"armement","pos":"NOM"} ,
		{"word":"armes","word_nosc":"armes","lemma":"arme","pos":"NOM"} ,
		{"word":"armistice","word_nosc":"armistice","lemma":"armistice","pos":"NOM"} ,
		{"word":"armistices","word_nosc":"armistices","lemma":"armistice","pos":"NOM"} ,
		{"word":"armoire","word_nosc":"armoire","lemma":"armoire","pos":"NOM"} ,
		{"word":"armoires","word_nosc":"armoires","lemma":"armoire","pos":"NOM"} ,
		{"word":"armoirie","word_nosc":"armoirie","lemma":"armoirie","pos":"NOM"} ,
		{"word":"armoiries","word_nosc":"armoiries","lemma":"armoirie","pos":"NOM"} ,
		{"word":"armoise","word_nosc":"armoise","lemma":"armoise","pos":"NOM"} ,
		{"word":"armoises","word_nosc":"armoises","lemma":"armoise","pos":"NOM"} ,
		{"word":"armon","word_nosc":"armon","lemma":"armon","pos":"NOM"} ,
		{"word":"armorial","word_nosc":"armorial","lemma":"armorial","pos":"NOM"} ,
		{"word":"armure","word_nosc":"armure","lemma":"armure","pos":"NOM"} ,
		{"word":"armurerie","word_nosc":"armurerie","lemma":"armurerie","pos":"NOM"} ,
		{"word":"armureries","word_nosc":"armureries","lemma":"armurerie","pos":"NOM"} ,
		{"word":"armures","word_nosc":"armures","lemma":"armure","pos":"NOM"} ,
		{"word":"armurier","word_nosc":"armurier","lemma":"armurier","pos":"NOM"} ,
		{"word":"armuriers","word_nosc":"armuriers","lemma":"armurier","pos":"NOM"} ,
		{"word":"armée","word_nosc":"armee","lemma":"armée","pos":"NOM"} ,
		{"word":"armées","word_nosc":"armees","lemma":"armée","pos":"NOM"} ,
		{"word":"arménien","word_nosc":"armenien","lemma":"arménien","pos":"NOM"} ,
		{"word":"arménienne","word_nosc":"armenienne","lemma":"arménien","pos":"NOM"} ,
		{"word":"arméniens","word_nosc":"armeniens","lemma":"arménien","pos":"NOM"} ,
		{"word":"arméria","word_nosc":"armeria","lemma":"arméria","pos":"NOM"} ,
		{"word":"armérias","word_nosc":"armerias","lemma":"arméria","pos":"NOM"} ,
		{"word":"arnaque","word_nosc":"arnaque","lemma":"arnaque","pos":"NOM"} ,
		{"word":"arnaques","word_nosc":"arnaques","lemma":"arnaque","pos":"NOM"} ,
		{"word":"arnaqueur","word_nosc":"arnaqueur","lemma":"arnaqueur","pos":"NOM"} ,
		{"word":"arnaqueurs","word_nosc":"arnaqueurs","lemma":"arnaqueur","pos":"NOM"} ,
		{"word":"arnaqueuse","word_nosc":"arnaqueuse","lemma":"arnaqueur","pos":"NOM"} ,
		{"word":"arnica","word_nosc":"arnica","lemma":"arnica","pos":"NOM"} ,
		{"word":"arobase","word_nosc":"arobase","lemma":"arobase","pos":"NOM"} ,
		{"word":"aromate","word_nosc":"aromate","lemma":"aromate","pos":"NOM"} ,
		{"word":"aromates","word_nosc":"aromates","lemma":"aromate","pos":"NOM"} ,
		{"word":"aromathérapie","word_nosc":"aromatherapie","lemma":"aromathérapie","pos":"NOM"} ,
		{"word":"aromatiques","word_nosc":"aromatiques","lemma":"aromatique","pos":"NOM"} ,
		{"word":"aronde","word_nosc":"aronde","lemma":"aronde","pos":"NOM"} ,
		{"word":"arousal","word_nosc":"arousal","lemma":"arousal","pos":"NOM"} ,
		{"word":"arpent","word_nosc":"arpent","lemma":"arpent","pos":"NOM"} ,
		{"word":"arpentage","word_nosc":"arpentage","lemma":"arpentage","pos":"NOM"} ,
		{"word":"arpentages","word_nosc":"arpentages","lemma":"arpentage","pos":"NOM"} ,
		{"word":"arpenteur","word_nosc":"arpenteur","lemma":"arpenteur","pos":"NOM"} ,
		{"word":"arpenteurs","word_nosc":"arpenteurs","lemma":"arpenteur","pos":"NOM"} ,
		{"word":"arpents","word_nosc":"arpents","lemma":"arpent","pos":"NOM"} ,
		{"word":"arpette","word_nosc":"arpette","lemma":"arpette","pos":"NOM"} ,
		{"word":"arpion","word_nosc":"arpion","lemma":"arpion","pos":"NOM"} ,
		{"word":"arpions","word_nosc":"arpions","lemma":"arpion","pos":"NOM"} ,
		{"word":"arpège","word_nosc":"arpege","lemma":"arpège","pos":"NOM"} ,
		{"word":"arpèges","word_nosc":"arpeges","lemma":"arpège","pos":"NOM"} ,
		{"word":"arpète","word_nosc":"arpete","lemma":"arpète","pos":"NOM"} ,
		{"word":"arpètes","word_nosc":"arpetes","lemma":"arpète","pos":"NOM"} ,
		{"word":"arquebusade","word_nosc":"arquebusade","lemma":"arquebusade","pos":"NOM"} ,
		{"word":"arquebuse","word_nosc":"arquebuse","lemma":"arquebuse","pos":"NOM"} ,
		{"word":"arquebuses","word_nosc":"arquebuses","lemma":"arquebuse","pos":"NOM"} ,
		{"word":"arquebusiers","word_nosc":"arquebusiers","lemma":"arquebusier","pos":"NOM"} ,
		{"word":"arrachage","word_nosc":"arrachage","lemma":"arrachage","pos":"NOM"} ,
		{"word":"arrache-clou","word_nosc":"arrache-clou","lemma":"arrache-clou","pos":"NOM"} ,
		{"word":"arrachement","word_nosc":"arrachement","lemma":"arrachement","pos":"NOM"} ,
		{"word":"arrachements","word_nosc":"arrachements","lemma":"arrachement","pos":"NOM"} ,
		{"word":"arracheur","word_nosc":"arracheur","lemma":"arracheur","pos":"NOM"} ,
		{"word":"arracheurs","word_nosc":"arracheurs","lemma":"arracheur","pos":"NOM"} ,
		{"word":"arracheuse","word_nosc":"arracheuse","lemma":"arracheur","pos":"NOM"} ,
		{"word":"arracheuses","word_nosc":"arracheuses","lemma":"arracheur","pos":"NOM"} ,
		{"word":"arrachures","word_nosc":"arrachures","lemma":"arrachure","pos":"NOM"} ,
		{"word":"arraché","word_nosc":"arrache","lemma":"arraché","pos":"NOM"} ,
		{"word":"arrachés","word_nosc":"arraches","lemma":"arraché","pos":"NOM"} ,
		{"word":"arrangement","word_nosc":"arrangement","lemma":"arrangement","pos":"NOM"} ,
		{"word":"arrangements","word_nosc":"arrangements","lemma":"arrangement","pos":"NOM"} ,
		{"word":"arrangeur","word_nosc":"arrangeur","lemma":"arrangeur","pos":"NOM"} ,
		{"word":"arrangeurs","word_nosc":"arrangeurs","lemma":"arrangeur","pos":"NOM"} ,
		{"word":"arrestation","word_nosc":"arrestation","lemma":"arrestation","pos":"NOM"} ,
		{"word":"arrestations","word_nosc":"arrestations","lemma":"arrestation","pos":"NOM"} ,
		{"word":"arrhes","word_nosc":"arrhes","lemma":"arrhe","pos":"NOM"} ,
		{"word":"arrimage","word_nosc":"arrimage","lemma":"arrimage","pos":"NOM"} ,
		{"word":"arrivage","word_nosc":"arrivage","lemma":"arrivage","pos":"NOM"} ,
		{"word":"arrivages","word_nosc":"arrivages","lemma":"arrivage","pos":"NOM"} ,
		{"word":"arrivant","word_nosc":"arrivant","lemma":"arrivant","pos":"NOM"} ,
		{"word":"arrivante","word_nosc":"arrivante","lemma":"arrivant","pos":"NOM"} ,
		{"word":"arrivants","word_nosc":"arrivants","lemma":"arrivant","pos":"NOM"} ,
		{"word":"arrivisme","word_nosc":"arrivisme","lemma":"arrivisme","pos":"NOM"} ,
		{"word":"arriviste","word_nosc":"arriviste","lemma":"arriviste","pos":"NOM"} ,
		{"word":"arrivistes","word_nosc":"arrivistes","lemma":"arriviste","pos":"NOM"} ,
		{"word":"arrivé","word_nosc":"arrive","lemma":"arrivé","pos":"NOM"} ,
		{"word":"arrivée","word_nosc":"arrivee","lemma":"arrivée","pos":"NOM"} ,
		{"word":"arrivées","word_nosc":"arrivees","lemma":"arrivée","pos":"NOM"} ,
		{"word":"arrivés","word_nosc":"arrives","lemma":"arrivé","pos":"NOM"} ,
		{"word":"arrière","word_nosc":"arriere","lemma":"arrière","pos":"NOM"} ,
		{"word":"arrière-automne","word_nosc":"arriere-automne","lemma":"arrière-automne","pos":"NOM"} ,
		{"word":"arrière-ban","word_nosc":"arriere-ban","lemma":"arrière-ban","pos":"NOM"} ,
		{"word":"arrière-boutique","word_nosc":"arriere-boutique","lemma":"arrière-boutique","pos":"NOM"} ,
		{"word":"arrière-boutiques","word_nosc":"arriere-boutiques","lemma":"arrière-boutique","pos":"NOM"} ,
		{"word":"arrière-cabinets","word_nosc":"arriere-cabinets","lemma":"arrière-cabinet","pos":"NOM"} ,
		{"word":"arrière-cour","word_nosc":"arriere-cour","lemma":"arrière-cour","pos":"NOM"} ,
		{"word":"arrière-cours","word_nosc":"arriere-cours","lemma":"arrière-cour","pos":"NOM"} ,
		{"word":"arrière-cousin","word_nosc":"arriere-cousin","lemma":"arrière-cousin","pos":"NOM"} ,
		{"word":"arrière-cuisine","word_nosc":"arriere-cuisine","lemma":"arrière-cuisine","pos":"NOM"} ,
		{"word":"arrière-cuisines","word_nosc":"arriere-cuisines","lemma":"arrière-cuisine","pos":"NOM"} ,
		{"word":"arrière-fond","word_nosc":"arriere-fond","lemma":"arrière-fond","pos":"NOM"} ,
		{"word":"arrière-garde","word_nosc":"arriere-garde","lemma":"arrière-garde","pos":"NOM"} ,
		{"word":"arrière-gardes","word_nosc":"arriere-gardes","lemma":"arrière-garde","pos":"NOM"} ,
		{"word":"arrière-gorge","word_nosc":"arriere-gorge","lemma":"arrière-gorge","pos":"NOM"} ,
		{"word":"arrière-goût","word_nosc":"arriere-gout","lemma":"arrière-goût","pos":"NOM"} ,
		{"word":"arrière-goûts","word_nosc":"arriere-gouts","lemma":"arrière-goût","pos":"NOM"} ,
		{"word":"arrière-grand-mère","word_nosc":"arriere-grand-mere","lemma":"arrière-grand-mère","pos":"NOM"} ,
		{"word":"arrière-grand-mères","word_nosc":"arriere-grand-meres","lemma":"arrière-grand-mère","pos":"NOM"} ,
		{"word":"arrière-grand-oncle","word_nosc":"arriere-grand-oncle","lemma":"arrière-grand-oncle","pos":"NOM"} ,
		{"word":"arrière-grand-père","word_nosc":"arriere-grand-pere","lemma":"arrière-grand-père","pos":"NOM"} ,
		{"word":"arrière-grand-tante","word_nosc":"arriere-grand-tante","lemma":"arrière-grand-tante","pos":"NOM"} ,
		{"word":"arrière-grand-tantes","word_nosc":"arriere-grand-tantes","lemma":"arrière-grand-tante","pos":"NOM"} ,
		{"word":"arrière-grands-mères","word_nosc":"arriere-grands-meres","lemma":"arrière-grand-mère","pos":"NOM"} ,
		{"word":"arrière-grands-parents","word_nosc":"arriere-grands-parents","lemma":"arrière-grand-parent","pos":"NOM"} ,
		{"word":"arrière-grands-pères","word_nosc":"arriere-grands-peres","lemma":"arrière-grand-père","pos":"NOM"} ,
		{"word":"arrière-loge","word_nosc":"arriere-loge","lemma":"arrière-loge","pos":"NOM"} ,
		{"word":"arrière-magasin","word_nosc":"arriere-magasin","lemma":"arrière-magasin","pos":"NOM"} ,
		{"word":"arrière-main","word_nosc":"arriere-main","lemma":"arrière-main","pos":"NOM"} ,
		{"word":"arrière-monde","word_nosc":"arriere-monde","lemma":"arrière-monde","pos":"NOM"} ,
		{"word":"arrière-neveux","word_nosc":"arriere-neveux","lemma":"arrière-neveux","pos":"NOM"} ,
		{"word":"arrière-pays","word_nosc":"arriere-pays","lemma":"arrière-pays","pos":"NOM"} ,
		{"word":"arrière-pensée","word_nosc":"arriere-pensee","lemma":"arrière-pensée","pos":"NOM"} ,
		{"word":"arrière-pensées","word_nosc":"arriere-pensees","lemma":"arrière-pensée","pos":"NOM"} ,
		{"word":"arrière-petit-fils","word_nosc":"arriere-petit-fils","lemma":"arrière-petit-fils","pos":"NOM"} ,
		{"word":"arrière-petit-neveu","word_nosc":"arriere-petit-neveu","lemma":"arrière-petit-neveu","pos":"NOM"} ,
		{"word":"arrière-petite-fille","word_nosc":"arriere-petite-fille","lemma":"arrière-petite-fille","pos":"NOM"} ,
		{"word":"arrière-petite-nièce","word_nosc":"arriere-petite-niece","lemma":"arrière-petite-nièce","pos":"NOM"} ,
		{"word":"arrière-petites-filles","word_nosc":"arriere-petites-filles","lemma":"arrière-petite-fille","pos":"NOM"} ,
		{"word":"arrière-petites-nièces","word_nosc":"arriere-petites-nieces","lemma":"arrière-petite-nièce","pos":"NOM"} ,
		{"word":"arrière-petits-enfants","word_nosc":"arriere-petits-enfants","lemma":"arrière-petit-enfant","pos":"NOM"} ,
		{"word":"arrière-petits-fils","word_nosc":"arriere-petits-fils","lemma":"arrière-petits-fils","pos":"NOM"} ,
		{"word":"arrière-petits-neveux","word_nosc":"arriere-petits-neveux","lemma":"arrière-petits-neveux","pos":"NOM"} ,
		{"word":"arrière-plan","word_nosc":"arriere-plan","lemma":"arrière-plan","pos":"NOM"} ,
		{"word":"arrière-plans","word_nosc":"arriere-plans","lemma":"arrière-plan","pos":"NOM"} ,
		{"word":"arrière-saison","word_nosc":"arriere-saison","lemma":"arrière-saison","pos":"NOM"} ,
		{"word":"arrière-salle","word_nosc":"arriere-salle","lemma":"arrière-salle","pos":"NOM"} ,
		{"word":"arrière-salles","word_nosc":"arriere-salles","lemma":"arrière-salle","pos":"NOM"} ,
		{"word":"arrière-train","word_nosc":"arriere-train","lemma":"arrière-train","pos":"NOM"} ,
		{"word":"arrière-trains","word_nosc":"arriere-trains","lemma":"arrière-train","pos":"NOM"} ,
		{"word":"arrières","word_nosc":"arrieres","lemma":"arrière","pos":"NOM"} ,
		{"word":"arriération","word_nosc":"arrieration","lemma":"arriération","pos":"NOM"} ,
		{"word":"arriéré","word_nosc":"arriere","lemma":"arriéré","pos":"NOM"} ,
		{"word":"arriérée","word_nosc":"arrieree","lemma":"arriéré","pos":"NOM"} ,
		{"word":"arriérés","word_nosc":"arrieres","lemma":"arriéré","pos":"NOM"} ,
		{"word":"arrogance","word_nosc":"arrogance","lemma":"arrogance","pos":"NOM"} ,
		{"word":"arrogant","word_nosc":"arrogant","lemma":"arrogant","pos":"NOM"} ,
		{"word":"arrogante","word_nosc":"arrogante","lemma":"arrogant","pos":"NOM"} ,
		{"word":"arrogantes","word_nosc":"arrogantes","lemma":"arrogant","pos":"NOM"} ,
		{"word":"arrogants","word_nosc":"arrogants","lemma":"arrogant","pos":"NOM"} ,
		{"word":"arroi","word_nosc":"arroi","lemma":"arroi","pos":"NOM"} ,
		{"word":"arrondi","word_nosc":"arrondi","lemma":"arrondi","pos":"NOM"} ,
		{"word":"arrondis","word_nosc":"arrondis","lemma":"arrondi","pos":"NOM"} ,
		{"word":"arrondissement","word_nosc":"arrondissement","lemma":"arrondissement","pos":"NOM"} ,
		{"word":"arrondissements","word_nosc":"arrondissements","lemma":"arrondissement","pos":"NOM"} ,
		{"word":"arrosage","word_nosc":"arrosage","lemma":"arrosage","pos":"NOM"} ,
		{"word":"arroseur","word_nosc":"arroseur","lemma":"arroseur","pos":"NOM"} ,
		{"word":"arroseurs","word_nosc":"arroseurs","lemma":"arroseur","pos":"NOM"} ,
		{"word":"arroseuse","word_nosc":"arroseuse","lemma":"arroseur","pos":"NOM"} ,
		{"word":"arroseuses","word_nosc":"arroseuses","lemma":"arroseur","pos":"NOM"} ,
		{"word":"arrosoir","word_nosc":"arrosoir","lemma":"arrosoir","pos":"NOM"} ,
		{"word":"arrosoirs","word_nosc":"arrosoirs","lemma":"arrosoir","pos":"NOM"} ,
		{"word":"arrérages","word_nosc":"arrerages","lemma":"arrérage","pos":"NOM"} ,
		{"word":"arrêt","word_nosc":"arret","lemma":"arrêt","pos":"NOM"} ,
		{"word":"arrêt-buffet","word_nosc":"arret-buffet","lemma":"arrêt-buffet","pos":"NOM"} ,
		{"word":"arrête-boeuf","word_nosc":"arrete-boeuf","lemma":"arrête-boeuf","pos":"NOM"} ,
		{"word":"arrêts","word_nosc":"arrets","lemma":"arrêt","pos":"NOM"} ,
		{"word":"arrêts-buffet","word_nosc":"arrets-buffet","lemma":"arrêt-buffet","pos":"NOM"} ,
		{"word":"arrêté","word_nosc":"arrete","lemma":"arrêté","pos":"NOM"} ,
		{"word":"arrêtés","word_nosc":"arretes","lemma":"arrêté","pos":"NOM"} ,
		{"word":"ars","word_nosc":"ars","lemma":"ars","pos":"NOM"} ,
		{"word":"arsacide","word_nosc":"arsacide","lemma":"arsacide","pos":"NOM"} ,
		{"word":"arsenal","word_nosc":"arsenal","lemma":"arsenal","pos":"NOM"} ,
		{"word":"arsenaux","word_nosc":"arsenaux","lemma":"arsenal","pos":"NOM"} ,
		{"word":"arsenic","word_nosc":"arsenic","lemma":"arsenic","pos":"NOM"} ,
		{"word":"arsouille","word_nosc":"arsouille","lemma":"arsouille","pos":"NOM"} ,
		{"word":"arsouilles","word_nosc":"arsouilles","lemma":"arsouille","pos":"NOM"} ,
		{"word":"art","word_nosc":"art","lemma":"art","pos":"NOM"} ,
		{"word":"artefact","word_nosc":"artefact","lemma":"artefact","pos":"NOM"} ,
		{"word":"artefacts","word_nosc":"artefacts","lemma":"artefact","pos":"NOM"} ,
		{"word":"arthrite","word_nosc":"arthrite","lemma":"arthrite","pos":"NOM"} ,
		{"word":"arthrites","word_nosc":"arthrites","lemma":"arthrite","pos":"NOM"} ,
		{"word":"arthrodèse","word_nosc":"arthrodese","lemma":"arthrodèse","pos":"NOM"} ,
		{"word":"arthropathie","word_nosc":"arthropathie","lemma":"arthropathie","pos":"NOM"} ,
		{"word":"arthroplastie","word_nosc":"arthroplastie","lemma":"arthroplastie","pos":"NOM"} ,
		{"word":"arthropodes","word_nosc":"arthropodes","lemma":"arthropode","pos":"NOM"} ,
		{"word":"arthroscopie","word_nosc":"arthroscopie","lemma":"arthroscopie","pos":"NOM"} ,
		{"word":"arthrose","word_nosc":"arthrose","lemma":"arthrose","pos":"NOM"} ,
		{"word":"arthroses","word_nosc":"arthroses","lemma":"arthrose","pos":"NOM"} ,
		{"word":"artichaut","word_nosc":"artichaut","lemma":"artichaut","pos":"NOM"} ,
		{"word":"artichauts","word_nosc":"artichauts","lemma":"artichaut","pos":"NOM"} ,
		{"word":"artiche","word_nosc":"artiche","lemma":"artiche","pos":"NOM"} ,
		{"word":"article","word_nosc":"article","lemma":"article","pos":"NOM"} ,
		{"word":"article-choc","word_nosc":"article-choc","lemma":"article-choc","pos":"NOM"} ,
		{"word":"articles","word_nosc":"articles","lemma":"article","pos":"NOM"} ,
		{"word":"articulation","word_nosc":"articulation","lemma":"articulation","pos":"NOM"} ,
		{"word":"articulations","word_nosc":"articulations","lemma":"articulation","pos":"NOM"} ,
		{"word":"articulet","word_nosc":"articulet","lemma":"articulet","pos":"NOM"} ,
		{"word":"articulé","word_nosc":"articule","lemma":"articulé","pos":"NOM"} ,
		{"word":"articulés","word_nosc":"articules","lemma":"articulé","pos":"NOM"} ,
		{"word":"artifice","word_nosc":"artifice","lemma":"artifice","pos":"NOM"} ,
		{"word":"artifices","word_nosc":"artifices","lemma":"artifice","pos":"NOM"} ,
		{"word":"artificialité","word_nosc":"artificialite","lemma":"artificialité","pos":"NOM"} ,
		{"word":"artificier","word_nosc":"artificier","lemma":"artificier","pos":"NOM"} ,
		{"word":"artificiers","word_nosc":"artificiers","lemma":"artificier","pos":"NOM"} ,
		{"word":"artiflot","word_nosc":"artiflot","lemma":"artiflot","pos":"NOM"} ,
		{"word":"artiflots","word_nosc":"artiflots","lemma":"artiflot","pos":"NOM"} ,
		{"word":"artillerie","word_nosc":"artillerie","lemma":"artillerie","pos":"NOM"} ,
		{"word":"artilleur","word_nosc":"artilleur","lemma":"artilleur","pos":"NOM"} ,
		{"word":"artilleurs","word_nosc":"artilleurs","lemma":"artilleur","pos":"NOM"} ,
		{"word":"artimon","word_nosc":"artimon","lemma":"artimon","pos":"NOM"} ,
		{"word":"artisan","word_nosc":"artisan","lemma":"artisan","pos":"NOM"} ,
		{"word":"artisanat","word_nosc":"artisanat","lemma":"artisanat","pos":"NOM"} ,
		{"word":"artisane","word_nosc":"artisane","lemma":"artisan","pos":"NOM"} ,
		{"word":"artisans","word_nosc":"artisans","lemma":"artisan","pos":"NOM"} ,
		{"word":"artison","word_nosc":"artison","lemma":"artison","pos":"NOM"} ,
		{"word":"artiste","word_nosc":"artiste","lemma":"artiste","pos":"NOM"} ,
		{"word":"artiste-peintre","word_nosc":"artiste-peintre","lemma":"artiste-peintre","pos":"NOM"} ,
		{"word":"artistes","word_nosc":"artistes","lemma":"artiste","pos":"NOM"} ,
		{"word":"arts","word_nosc":"arts","lemma":"art","pos":"NOM"} ,
		{"word":"artère","word_nosc":"artere","lemma":"artère","pos":"NOM"} ,
		{"word":"artères","word_nosc":"arteres","lemma":"artère","pos":"NOM"} ,
		{"word":"artéfact","word_nosc":"artefact","lemma":"artéfact","pos":"NOM"} ,
		{"word":"artériectomie","word_nosc":"arteriectomie","lemma":"artériectomie","pos":"NOM"} ,
		{"word":"artériographie","word_nosc":"arteriographie","lemma":"artériographie","pos":"NOM"} ,
		{"word":"artériole","word_nosc":"arteriole","lemma":"artériole","pos":"NOM"} ,
		{"word":"artérioles","word_nosc":"arterioles","lemma":"artériole","pos":"NOM"} ,
		{"word":"artériopathie","word_nosc":"arteriopathie","lemma":"artériopathie","pos":"NOM"} ,
		{"word":"artériosclérose","word_nosc":"arteriosclerose","lemma":"artériosclérose","pos":"NOM"} ,
		{"word":"artérite","word_nosc":"arterite","lemma":"artérite","pos":"NOM"} ,
		{"word":"arum","word_nosc":"arum","lemma":"arum","pos":"NOM"} ,
		{"word":"arums","word_nosc":"arums","lemma":"arum","pos":"NOM"} ,
		{"word":"aruspice","word_nosc":"aruspice","lemma":"aruspice","pos":"NOM"} ,
		{"word":"aruspices","word_nosc":"aruspices","lemma":"aruspice","pos":"NOM"} ,
		{"word":"arverne","word_nosc":"arverne","lemma":"arverne","pos":"NOM"} ,
		{"word":"arvernes","word_nosc":"arvernes","lemma":"arverne","pos":"NOM"} ,
		{"word":"aryen","word_nosc":"aryen","lemma":"aryen","pos":"NOM"} ,
		{"word":"aryenne","word_nosc":"aryenne","lemma":"aryenne","pos":"NOM"} ,
		{"word":"aryennes","word_nosc":"aryennes","lemma":"aryenne","pos":"NOM"} ,
		{"word":"aryens","word_nosc":"aryens","lemma":"aryen","pos":"NOM"} ,
		{"word":"arythmie","word_nosc":"arythmie","lemma":"arythmie","pos":"NOM"} ,
		{"word":"arzels","word_nosc":"arzels","lemma":"arzel","pos":"NOM"} ,
		{"word":"arçon","word_nosc":"arcon","lemma":"arçon","pos":"NOM"} ,
		{"word":"arçons","word_nosc":"arcons","lemma":"arçon","pos":"NOM"} ,
		{"word":"arène","word_nosc":"arene","lemma":"arène","pos":"NOM"} ,
		{"word":"arènes","word_nosc":"arenes","lemma":"arène","pos":"NOM"} ,
		{"word":"aréflexie","word_nosc":"areflexie","lemma":"aréflexie","pos":"NOM"} ,
		{"word":"aréna","word_nosc":"arena","lemma":"aréna","pos":"NOM"} ,
		{"word":"aréole","word_nosc":"areole","lemma":"aréole","pos":"NOM"} ,
		{"word":"aréoles","word_nosc":"areoles","lemma":"aréole","pos":"NOM"} ,
		{"word":"aréopage","word_nosc":"areopage","lemma":"aréopage","pos":"NOM"} ,
		{"word":"aréopages","word_nosc":"areopages","lemma":"aréopage","pos":"NOM"} ,
		{"word":"aréopagite","word_nosc":"areopagite","lemma":"aréopagite","pos":"NOM"} ,
		{"word":"aréquier","word_nosc":"arequier","lemma":"aréquier","pos":"NOM"} ,
		{"word":"arétin","word_nosc":"aretin","lemma":"arétin","pos":"NOM"} ,
		{"word":"arête","word_nosc":"arete","lemma":"arête","pos":"NOM"} ,
		{"word":"arêtes","word_nosc":"aretes","lemma":"arête","pos":"NOM"} ,
		{"word":"arôme","word_nosc":"arome","lemma":"arôme","pos":"NOM"} ,
		{"word":"arômes","word_nosc":"aromes","lemma":"arôme","pos":"NOM"} ,
		{"word":"as","word_nosc":"as","lemma":"as","pos":"NOM"} ,
		{"word":"as-rois","word_nosc":"as-rois","lemma":"as-rois","pos":"NOM"} ,
		{"word":"asa","word_nosc":"asa","lemma":"asa","pos":"NOM"} ,
		{"word":"asana","word_nosc":"asana","lemma":"asana","pos":"NOM"} ,
		{"word":"asbeste","word_nosc":"asbeste","lemma":"asbeste","pos":"NOM"} ,
		{"word":"asbestose","word_nosc":"asbestose","lemma":"asbestose","pos":"NOM"} ,
		{"word":"ascaris","word_nosc":"ascaris","lemma":"ascaris","pos":"NOM"} ,
		{"word":"ascendance","word_nosc":"ascendance","lemma":"ascendance","pos":"NOM"} ,
		{"word":"ascendances","word_nosc":"ascendances","lemma":"ascendance","pos":"NOM"} ,
		{"word":"ascendant","word_nosc":"ascendant","lemma":"ascendant","pos":"NOM"} ,
		{"word":"ascendants","word_nosc":"ascendants","lemma":"ascendant","pos":"NOM"} ,
		{"word":"ascenseur","word_nosc":"ascenseur","lemma":"ascenseur","pos":"NOM"} ,
		{"word":"ascenseurs","word_nosc":"ascenseurs","lemma":"ascenseur","pos":"NOM"} ,
		{"word":"ascension","word_nosc":"ascension","lemma":"ascension","pos":"NOM"} ,
		{"word":"ascensions","word_nosc":"ascensions","lemma":"ascension","pos":"NOM"} ,
		{"word":"ascite","word_nosc":"ascite","lemma":"ascite","pos":"NOM"} ,
		{"word":"asclépias","word_nosc":"asclepias","lemma":"asclépias","pos":"NOM"} ,
		{"word":"ascot","word_nosc":"ascot","lemma":"ascot","pos":"NOM"} ,
		{"word":"ascèse","word_nosc":"ascese","lemma":"ascèse","pos":"NOM"} ,
		{"word":"ascèses","word_nosc":"asceses","lemma":"ascèse","pos":"NOM"} ,
		{"word":"ascète","word_nosc":"ascete","lemma":"ascète","pos":"NOM"} ,
		{"word":"ascètes","word_nosc":"ascetes","lemma":"ascète","pos":"NOM"} ,
		{"word":"ascétisme","word_nosc":"ascetisme","lemma":"ascétisme","pos":"NOM"} ,
		{"word":"asdic","word_nosc":"asdic","lemma":"asdic","pos":"NOM"} ,
		{"word":"ase","word_nosc":"ase","lemma":"as","pos":"NOM"} ,
		{"word":"asepsie","word_nosc":"asepsie","lemma":"asepsie","pos":"NOM"} ,
		{"word":"aseptisation","word_nosc":"aseptisation","lemma":"aseptisation","pos":"NOM"} ,
		{"word":"asexualité","word_nosc":"asexualite","lemma":"asexualité","pos":"NOM"} ,
		{"word":"ashanti","word_nosc":"ashanti","lemma":"ashanti","pos":"NOM"} ,
		{"word":"ashkénaze","word_nosc":"ashkenaze","lemma":"ashkénaze","pos":"NOM"} ,
		{"word":"ashkénazes","word_nosc":"ashkenazes","lemma":"ashkénaze","pos":"NOM"} ,
		{"word":"ashram","word_nosc":"ashram","lemma":"ashram","pos":"NOM"} ,
		{"word":"asiate","word_nosc":"asiate","lemma":"asiate","pos":"NOM"} ,
		{"word":"asiatique","word_nosc":"asiatique","lemma":"asiatique","pos":"NOM"} ,
		{"word":"asiatiques","word_nosc":"asiatiques","lemma":"asiatique","pos":"NOM"} ,
		{"word":"asile","word_nosc":"asile","lemma":"asile","pos":"NOM"} ,
		{"word":"asiles","word_nosc":"asiles","lemma":"asile","pos":"NOM"} ,
		{"word":"asociabilité","word_nosc":"asociabilite","lemma":"asociabilité","pos":"NOM"} ,
		{"word":"asocial","word_nosc":"asocial","lemma":"asocial","pos":"NOM"} ,
		{"word":"asociale","word_nosc":"asociale","lemma":"asocial","pos":"NOM"} ,
		{"word":"asociaux","word_nosc":"asociaux","lemma":"asocial","pos":"NOM"} ,
		{"word":"asparagus","word_nosc":"asparagus","lemma":"asparagus","pos":"NOM"} ,
		{"word":"aspartam","word_nosc":"aspartam","lemma":"aspartam","pos":"NOM"} ,
		{"word":"aspartame","word_nosc":"aspartame","lemma":"aspartame","pos":"NOM"} ,
		{"word":"aspect","word_nosc":"aspect","lemma":"aspect","pos":"NOM"} ,
		{"word":"aspects","word_nosc":"aspects","lemma":"aspect","pos":"NOM"} ,
		{"word":"asperge","word_nosc":"asperge","lemma":"asperge","pos":"NOM"} ,
		{"word":"asperges","word_nosc":"asperges","lemma":"asperge","pos":"NOM"} ,
		{"word":"aspergillose","word_nosc":"aspergillose","lemma":"aspergillose","pos":"NOM"} ,
		{"word":"aspergillus","word_nosc":"aspergillus","lemma":"aspergillus","pos":"NOM"} ,
		{"word":"aspersion","word_nosc":"aspersion","lemma":"aspersion","pos":"NOM"} ,
		{"word":"aspersions","word_nosc":"aspersions","lemma":"aspersion","pos":"NOM"} ,
		{"word":"asphalte","word_nosc":"asphalte","lemma":"asphalte","pos":"NOM"} ,
		{"word":"asphodèle","word_nosc":"asphodele","lemma":"asphodèle","pos":"NOM"} ,
		{"word":"asphodèles","word_nosc":"asphodeles","lemma":"asphodèle","pos":"NOM"} ,
		{"word":"asphyxie","word_nosc":"asphyxie","lemma":"asphyxie","pos":"NOM"} ,
		{"word":"aspi","word_nosc":"aspi","lemma":"aspi","pos":"NOM"} ,
		{"word":"aspic","word_nosc":"aspic","lemma":"aspic","pos":"NOM"} ,
		{"word":"aspics","word_nosc":"aspics","lemma":"aspic","pos":"NOM"} ,
		{"word":"aspidistra","word_nosc":"aspidistra","lemma":"aspidistra","pos":"NOM"} ,
		{"word":"aspidistras","word_nosc":"aspidistras","lemma":"aspidistra","pos":"NOM"} ,
		{"word":"aspirant","word_nosc":"aspirant","lemma":"aspirant","pos":"NOM"} ,
		{"word":"aspirante","word_nosc":"aspirante","lemma":"aspirant","pos":"NOM"} ,
		{"word":"aspirants","word_nosc":"aspirants","lemma":"aspirant","pos":"NOM"} ,
		{"word":"aspirateur","word_nosc":"aspirateur","lemma":"aspirateur","pos":"NOM"} ,
		{"word":"aspirateurs","word_nosc":"aspirateurs","lemma":"aspirateur","pos":"NOM"} ,
		{"word":"aspirateurs-traîneaux","word_nosc":"aspirateurs-traineaux","lemma":"aspirateurs-traîneaux","pos":"NOM"} ,
		{"word":"aspiration","word_nosc":"aspiration","lemma":"aspiration","pos":"NOM"} ,
		{"word":"aspirations","word_nosc":"aspirations","lemma":"aspiration","pos":"NOM"} ,
		{"word":"aspirine","word_nosc":"aspirine","lemma":"aspirine","pos":"NOM"} ,
		{"word":"aspirines","word_nosc":"aspirines","lemma":"aspirine","pos":"NOM"} ,
		{"word":"aspis","word_nosc":"aspis","lemma":"aspi","pos":"NOM"} ,
		{"word":"aspre","word_nosc":"aspre","lemma":"aspre","pos":"NOM"} ,
		{"word":"aspérité","word_nosc":"asperite","lemma":"aspérité","pos":"NOM"} ,
		{"word":"aspérités","word_nosc":"asperites","lemma":"aspérité","pos":"NOM"} ,
		{"word":"assa-foetida","word_nosc":"assa-foetida","lemma":"assa-foetida","pos":"NOM"} ,
		{"word":"assaillant","word_nosc":"assaillant","lemma":"assaillant","pos":"NOM"} ,
		{"word":"assaillants","word_nosc":"assaillants","lemma":"assaillant","pos":"NOM"} ,
		{"word":"assainissement","word_nosc":"assainissement","lemma":"assainissement","pos":"NOM"} ,
		{"word":"assaisonnement","word_nosc":"assaisonnement","lemma":"assaisonnement","pos":"NOM"} ,
		{"word":"assaisonnements","word_nosc":"assaisonnements","lemma":"assaisonnement","pos":"NOM"} ,
		{"word":"assassin","word_nosc":"assassin","lemma":"assassin","pos":"NOM"} ,
		{"word":"assassinat","word_nosc":"assassinat","lemma":"assassinat","pos":"NOM"} ,
		{"word":"assassinats","word_nosc":"assassinats","lemma":"assassinat","pos":"NOM"} ,
		{"word":"assassins","word_nosc":"assassins","lemma":"assassin","pos":"NOM"} ,
		{"word":"assaut","word_nosc":"assaut","lemma":"assaut","pos":"NOM"} ,
		{"word":"assauts","word_nosc":"assauts","lemma":"assaut","pos":"NOM"} ,
		{"word":"asse","word_nosc":"asse","lemma":"asse","pos":"NOM"} ,
		{"word":"asseau","word_nosc":"asseau","lemma":"asseau","pos":"NOM"} ,
		{"word":"assemblage","word_nosc":"assemblage","lemma":"assemblage","pos":"NOM"} ,
		{"word":"assemblages","word_nosc":"assemblages","lemma":"assemblage","pos":"NOM"} ,
		{"word":"assembleur","word_nosc":"assembleur","lemma":"assembleur","pos":"NOM"} ,
		{"word":"assembleurs","word_nosc":"assembleurs","lemma":"assembleur","pos":"NOM"} ,
		{"word":"assemblée","word_nosc":"assemblee","lemma":"assemblée","pos":"NOM"} ,
		{"word":"assemblées","word_nosc":"assemblees","lemma":"assemblée","pos":"NOM"} ,
		{"word":"assentiment","word_nosc":"assentiment","lemma":"assentiment","pos":"NOM"} ,
		{"word":"assentiments","word_nosc":"assentiments","lemma":"assentiment","pos":"NOM"} ,
		{"word":"assertion","word_nosc":"assertion","lemma":"assertion","pos":"NOM"} ,
		{"word":"assertions","word_nosc":"assertions","lemma":"assertion","pos":"NOM"} ,
		{"word":"asservissement","word_nosc":"asservissement","lemma":"asservissement","pos":"NOM"} ,
		{"word":"asservissements","word_nosc":"asservissements","lemma":"asservissement","pos":"NOM"} ,
		{"word":"assesseur","word_nosc":"assesseur","lemma":"assesseur","pos":"NOM"} ,
		{"word":"assesseurs","word_nosc":"assesseurs","lemma":"assesseur","pos":"NOM"} ,
		{"word":"assiduité","word_nosc":"assiduite","lemma":"assiduité","pos":"NOM"} ,
		{"word":"assiduités","word_nosc":"assiduites","lemma":"assiduité","pos":"NOM"} ,
		{"word":"assiette","word_nosc":"assiette","lemma":"assiette","pos":"NOM"} ,
		{"word":"assiettes","word_nosc":"assiettes","lemma":"assiette","pos":"NOM"} ,
		{"word":"assiettée","word_nosc":"assiettee","lemma":"assiettée","pos":"NOM"} ,
		{"word":"assiettées","word_nosc":"assiettees","lemma":"assiettée","pos":"NOM"} ,
		{"word":"assignation","word_nosc":"assignation","lemma":"assignation","pos":"NOM"} ,
		{"word":"assignations","word_nosc":"assignations","lemma":"assignation","pos":"NOM"} ,
		{"word":"assignats","word_nosc":"assignats","lemma":"assignat","pos":"NOM"} ,
		{"word":"assignement","word_nosc":"assignement","lemma":"assignement","pos":"NOM"} ,
		{"word":"assimilation","word_nosc":"assimilation","lemma":"assimilation","pos":"NOM"} ,
		{"word":"assimilations","word_nosc":"assimilations","lemma":"assimilation","pos":"NOM"} ,
		{"word":"assimilée","word_nosc":"assimilee","lemma":"assimilé","pos":"NOM"} ,
		{"word":"assimilés","word_nosc":"assimiles","lemma":"assimilé","pos":"NOM"} ,
		{"word":"assise","word_nosc":"assise","lemma":"assise","pos":"NOM"} ,
		{"word":"assises","word_nosc":"assises","lemma":"assise","pos":"NOM"} ,
		{"word":"assistanat","word_nosc":"assistanat","lemma":"assistanat","pos":"NOM"} ,
		{"word":"assistance","word_nosc":"assistance","lemma":"assistance","pos":"NOM"} ,
		{"word":"assistances","word_nosc":"assistances","lemma":"assistance","pos":"NOM"} ,
		{"word":"assistant","word_nosc":"assistant","lemma":"assistant","pos":"NOM"} ,
		{"word":"assistante","word_nosc":"assistante","lemma":"assistant","pos":"NOM"} ,
		{"word":"assistantes","word_nosc":"assistantes","lemma":"assistant","pos":"NOM"} ,
		{"word":"assistants","word_nosc":"assistants","lemma":"assistant","pos":"NOM"} ,
		{"word":"assisté","word_nosc":"assiste","lemma":"assisté","pos":"NOM"} ,
		{"word":"assistés","word_nosc":"assistes","lemma":"assisté","pos":"NOM"} ,
		{"word":"assiégeant","word_nosc":"assiegeant","lemma":"assiégeant","pos":"NOM"} ,
		{"word":"assiégeants","word_nosc":"assiegeants","lemma":"assiégeant","pos":"NOM"} ,
		{"word":"assiégé","word_nosc":"assiege","lemma":"assiégé","pos":"NOM"} ,
		{"word":"assiégée","word_nosc":"assiegee","lemma":"assiégé","pos":"NOM"} ,
		{"word":"assiégées","word_nosc":"assiegees","lemma":"assiégé","pos":"NOM"} ,
		{"word":"assiégés","word_nosc":"assieges","lemma":"assiégé","pos":"NOM"} ,
		{"word":"association","word_nosc":"association","lemma":"association","pos":"NOM"} ,
		{"word":"associations","word_nosc":"associations","lemma":"association","pos":"NOM"} ,
		{"word":"associé","word_nosc":"associe","lemma":"associé","pos":"NOM"} ,
		{"word":"associée","word_nosc":"associee","lemma":"associé","pos":"NOM"} ,
		{"word":"associées","word_nosc":"associees","lemma":"associé","pos":"NOM"} ,
		{"word":"associés","word_nosc":"associes","lemma":"associé","pos":"NOM"} ,
		{"word":"assombrissement","word_nosc":"assombrissement","lemma":"assombrissement","pos":"NOM"} ,
		{"word":"assommeur","word_nosc":"assommeur","lemma":"assommeur","pos":"NOM"} ,
		{"word":"assommoir","word_nosc":"assommoir","lemma":"assommoir","pos":"NOM"} ,
		{"word":"assommoirs","word_nosc":"assommoirs","lemma":"assommoir","pos":"NOM"} ,
		{"word":"assomption","word_nosc":"assomption","lemma":"assomption","pos":"NOM"} ,
		{"word":"assomptions","word_nosc":"assomptions","lemma":"assomption","pos":"NOM"} ,
		{"word":"assonance","word_nosc":"assonance","lemma":"assonance","pos":"NOM"} ,
		{"word":"assonances","word_nosc":"assonances","lemma":"assonance","pos":"NOM"} ,
		{"word":"assortiment","word_nosc":"assortiment","lemma":"assortiment","pos":"NOM"} ,
		{"word":"assortiments","word_nosc":"assortiments","lemma":"assortiment","pos":"NOM"} ,
		{"word":"assoupissement","word_nosc":"assoupissement","lemma":"assoupissement","pos":"NOM"} ,
		{"word":"assouplissant","word_nosc":"assouplissant","lemma":"assouplissant","pos":"NOM"} ,
		{"word":"assouplissement","word_nosc":"assouplissement","lemma":"assouplissement","pos":"NOM"} ,
		{"word":"assouplissements","word_nosc":"assouplissements","lemma":"assouplissement","pos":"NOM"} ,
		{"word":"assourdissement","word_nosc":"assourdissement","lemma":"assourdissement","pos":"NOM"} ,
		{"word":"assouvissement","word_nosc":"assouvissement","lemma":"assouvissement","pos":"NOM"} ,
		{"word":"assujettissement","word_nosc":"assujettissement","lemma":"assujettissement","pos":"NOM"} ,
		{"word":"assurage","word_nosc":"assurage","lemma":"assurage","pos":"NOM"} ,
		{"word":"assurance","word_nosc":"assurance","lemma":"assurance","pos":"NOM"} ,
		{"word":"assurance-accidents","word_nosc":"assurance-accidents","lemma":"assurance-accidents","pos":"NOM"} ,
		{"word":"assurance-chômage","word_nosc":"assurance-chomage","lemma":"assurance-chômage","pos":"NOM"} ,
		{"word":"assurance-maladie","word_nosc":"assurance-maladie","lemma":"assurance-maladie","pos":"NOM"} ,
		{"word":"assurance-vie","word_nosc":"assurance-vie","lemma":"assurance-vie","pos":"NOM"} ,
		{"word":"assurances","word_nosc":"assurances","lemma":"assurance","pos":"NOM"} ,
		{"word":"assurances-vie","word_nosc":"assurances-vie","lemma":"assurance-vie","pos":"NOM"} ,
		{"word":"assureur","word_nosc":"assureur","lemma":"assureur","pos":"NOM"} ,
		{"word":"assureur-conseil","word_nosc":"assureur-conseil","lemma":"assureur-conseil","pos":"NOM"} ,
		{"word":"assureurs","word_nosc":"assureurs","lemma":"assureur","pos":"NOM"} ,
		{"word":"assuré","word_nosc":"assure","lemma":"assuré","pos":"NOM"} ,
		{"word":"assurée","word_nosc":"assuree","lemma":"assuré","pos":"NOM"} ,
		{"word":"assurés","word_nosc":"assures","lemma":"assuré","pos":"NOM"} ,
		{"word":"assuétude","word_nosc":"assuetude","lemma":"assuétude","pos":"NOM"} ,
		{"word":"assyrien","word_nosc":"assyrien","lemma":"assyrien","pos":"NOM"} ,
		{"word":"assyriens","word_nosc":"assyriens","lemma":"assyrien","pos":"NOM"} ,
		{"word":"assèchement","word_nosc":"assechement","lemma":"assèchement","pos":"NOM"} ,
		{"word":"astarté","word_nosc":"astarte","lemma":"astarté","pos":"NOM"} ,
		{"word":"aste","word_nosc":"aste","lemma":"aste","pos":"NOM"} ,
		{"word":"aster","word_nosc":"aster","lemma":"aster","pos":"NOM"} ,
		{"word":"asters","word_nosc":"asters","lemma":"aster","pos":"NOM"} ,
		{"word":"asthmatique","word_nosc":"asthmatique","lemma":"asthmatique","pos":"NOM"} ,
		{"word":"asthmatiques","word_nosc":"asthmatiques","lemma":"asthmatique","pos":"NOM"} ,
		{"word":"asthme","word_nosc":"asthme","lemma":"asthme","pos":"NOM"} ,
		{"word":"asthmes","word_nosc":"asthmes","lemma":"asthme","pos":"NOM"} ,
		{"word":"asthénie","word_nosc":"asthenie","lemma":"asthénie","pos":"NOM"} ,
		{"word":"asthénies","word_nosc":"asthenies","lemma":"asthénie","pos":"NOM"} ,
		{"word":"asti","word_nosc":"asti","lemma":"asti","pos":"NOM"} ,
		{"word":"astic","word_nosc":"astic","lemma":"astic","pos":"NOM"} ,
		{"word":"asticot","word_nosc":"asticot","lemma":"asticot","pos":"NOM"} ,
		{"word":"asticotages","word_nosc":"asticotages","lemma":"asticotage","pos":"NOM"} ,
		{"word":"asticots","word_nosc":"asticots","lemma":"asticot","pos":"NOM"} ,
		{"word":"astigmatisme","word_nosc":"astigmatisme","lemma":"astigmatisme","pos":"NOM"} ,
		{"word":"astiquage","word_nosc":"astiquage","lemma":"astiquage","pos":"NOM"} ,
		{"word":"astiqueur","word_nosc":"astiqueur","lemma":"astiqueur","pos":"NOM"} ,
		{"word":"astragale","word_nosc":"astragale","lemma":"astragale","pos":"NOM"} ,
		{"word":"astragales","word_nosc":"astragales","lemma":"astragale","pos":"NOM"} ,
		{"word":"astrakan","word_nosc":"astrakan","lemma":"astrakan","pos":"NOM"} ,
		{"word":"astre","word_nosc":"astre","lemma":"astre","pos":"NOM"} ,
		{"word":"astreinte","word_nosc":"astreinte","lemma":"astreinte","pos":"NOM"} ,
		{"word":"astreintes","word_nosc":"astreintes","lemma":"astreinte","pos":"NOM"} ,
		{"word":"astres","word_nosc":"astres","lemma":"astre","pos":"NOM"} ,
		{"word":"astringent","word_nosc":"astringent","lemma":"astringent","pos":"NOM"} ,
		{"word":"astrochimie","word_nosc":"astrochimie","lemma":"astrochimie","pos":"NOM"} ,
		{"word":"astrolabe","word_nosc":"astrolabe","lemma":"astrolabe","pos":"NOM"} ,
		{"word":"astrolabes","word_nosc":"astrolabes","lemma":"astrolabe","pos":"NOM"} ,
		{"word":"astrologie","word_nosc":"astrologie","lemma":"astrologie","pos":"NOM"} ,
		{"word":"astrologue","word_nosc":"astrologue","lemma":"astrologue","pos":"NOM"} ,
		{"word":"astrologues","word_nosc":"astrologues","lemma":"astrologue","pos":"NOM"} ,
		{"word":"astrolâtres","word_nosc":"astrolatres","lemma":"astrolâtre","pos":"NOM"} ,
		{"word":"astrométrie","word_nosc":"astrometrie","lemma":"astrométrie","pos":"NOM"} ,
		{"word":"astronaute","word_nosc":"astronaute","lemma":"astronaute","pos":"NOM"} ,
		{"word":"astronautes","word_nosc":"astronautes","lemma":"astronaute","pos":"NOM"} ,
		{"word":"astronautique","word_nosc":"astronautique","lemma":"astronautique","pos":"NOM"} ,
		{"word":"astronef","word_nosc":"astronef","lemma":"astronef","pos":"NOM"} ,
		{"word":"astronome","word_nosc":"astronome","lemma":"astronome","pos":"NOM"} ,
		{"word":"astronomes","word_nosc":"astronomes","lemma":"astronome","pos":"NOM"} ,
		{"word":"astronomie","word_nosc":"astronomie","lemma":"astronomie","pos":"NOM"} ,
		{"word":"astrophore","word_nosc":"astrophore","lemma":"astrophore","pos":"NOM"} ,
		{"word":"astrophysicien","word_nosc":"astrophysicien","lemma":"astrophysicien","pos":"NOM"} ,
		{"word":"astrophysicienne","word_nosc":"astrophysicienne","lemma":"astrophysicien","pos":"NOM"} ,
		{"word":"astrophysiciens","word_nosc":"astrophysiciens","lemma":"astrophysicien","pos":"NOM"} ,
		{"word":"astrophysique","word_nosc":"astrophysique","lemma":"astrophysique","pos":"NOM"} ,
		{"word":"astroport","word_nosc":"astroport","lemma":"astroport","pos":"NOM"} ,
		{"word":"astroscope","word_nosc":"astroscope","lemma":"astroscope","pos":"NOM"} ,
		{"word":"astuce","word_nosc":"astuce","lemma":"astuce","pos":"NOM"} ,
		{"word":"astuces","word_nosc":"astuces","lemma":"astuce","pos":"NOM"} ,
		{"word":"asturienne","word_nosc":"asturienne","lemma":"asturienne","pos":"NOM"} ,
		{"word":"astérisme","word_nosc":"asterisme","lemma":"astérisme","pos":"NOM"} ,
		{"word":"astérisque","word_nosc":"asterisque","lemma":"astérisque","pos":"NOM"} ,
		{"word":"astérisques","word_nosc":"asterisques","lemma":"astérisque","pos":"NOM"} ,
		{"word":"astéroïde","word_nosc":"asteroide","lemma":"astéroïde","pos":"NOM"} ,
		{"word":"astéroïdes","word_nosc":"asteroides","lemma":"astéroïde","pos":"NOM"} ,
		{"word":"asymétrie","word_nosc":"asymetrie","lemma":"asymétrie","pos":"NOM"} ,
		{"word":"asystolie","word_nosc":"asystolie","lemma":"asystolie","pos":"NOM"} ,
		{"word":"atabeg","word_nosc":"atabeg","lemma":"atabeg","pos":"NOM"} ,
		{"word":"atabegs","word_nosc":"atabegs","lemma":"atabeg","pos":"NOM"} ,
		{"word":"ataman","word_nosc":"ataman","lemma":"ataman","pos":"NOM"} ,
		{"word":"atamans","word_nosc":"atamans","lemma":"ataman","pos":"NOM"} ,
		{"word":"ataraxie","word_nosc":"ataraxie","lemma":"ataraxie","pos":"NOM"} ,
		{"word":"atavisme","word_nosc":"atavisme","lemma":"atavisme","pos":"NOM"} ,
		{"word":"atavismes","word_nosc":"atavismes","lemma":"atavisme","pos":"NOM"} ,
		{"word":"ataxie","word_nosc":"ataxie","lemma":"ataxie","pos":"NOM"} ,
		{"word":"atelier","word_nosc":"atelier","lemma":"atelier","pos":"NOM"} ,
		{"word":"ateliers","word_nosc":"ateliers","lemma":"atelier","pos":"NOM"} ,
		{"word":"atellanes","word_nosc":"atellanes","lemma":"atellane","pos":"NOM"} ,
		{"word":"atermoiement","word_nosc":"atermoiement","lemma":"atermoiement","pos":"NOM"} ,
		{"word":"atermoiements","word_nosc":"atermoiements","lemma":"atermoiement","pos":"NOM"} ,
		{"word":"athanor","word_nosc":"athanor","lemma":"athanor","pos":"NOM"} ,
		{"word":"athlète","word_nosc":"athlete","lemma":"athlète","pos":"NOM"} ,
		{"word":"athlètes","word_nosc":"athletes","lemma":"athlète","pos":"NOM"} ,
		{"word":"athlétisme","word_nosc":"athletisme","lemma":"athlétisme","pos":"NOM"} ,
		{"word":"athée","word_nosc":"athee","lemma":"athée","pos":"NOM"} ,
		{"word":"athées","word_nosc":"athees","lemma":"athée","pos":"NOM"} ,
		{"word":"athéisme","word_nosc":"atheisme","lemma":"athéisme","pos":"NOM"} ,
		{"word":"athénien","word_nosc":"athenien","lemma":"athénien","pos":"NOM"} ,
		{"word":"athénienne","word_nosc":"athenienne","lemma":"athénienne","pos":"NOM"} ,
		{"word":"athéniennes","word_nosc":"atheniennes","lemma":"athénienne","pos":"NOM"} ,
		{"word":"athéniens","word_nosc":"atheniens","lemma":"athénien","pos":"NOM"} ,
		{"word":"athénée","word_nosc":"athenee","lemma":"athénée","pos":"NOM"} ,
		{"word":"atlante","word_nosc":"atlante","lemma":"atlante","pos":"NOM"} ,
		{"word":"atlantes","word_nosc":"atlantes","lemma":"atlante","pos":"NOM"} ,
		{"word":"atlas","word_nosc":"atlas","lemma":"atlas","pos":"NOM"} ,
		{"word":"atmosphère","word_nosc":"atmosphere","lemma":"atmosphère","pos":"NOM"} ,
		{"word":"atmosphères","word_nosc":"atmospheres","lemma":"atmosphère","pos":"NOM"} ,
		{"word":"atoll","word_nosc":"atoll","lemma":"atoll","pos":"NOM"} ,
		{"word":"atolls","word_nosc":"atolls","lemma":"atoll","pos":"NOM"} ,
		{"word":"atome","word_nosc":"atome","lemma":"atome","pos":"NOM"} ,
		{"word":"atomes","word_nosc":"atomes","lemma":"atome","pos":"NOM"} ,
		{"word":"atomiseur","word_nosc":"atomiseur","lemma":"atomiseur","pos":"NOM"} ,
		{"word":"atomiste","word_nosc":"atomiste","lemma":"atomiste","pos":"NOM"} ,
		{"word":"atomistes","word_nosc":"atomistes","lemma":"atomiste","pos":"NOM"} ,
		{"word":"atomisé","word_nosc":"atomise","lemma":"atomisé","pos":"NOM"} ,
		{"word":"atonie","word_nosc":"atonie","lemma":"atonie","pos":"NOM"} ,
		{"word":"atour","word_nosc":"atour","lemma":"atour","pos":"NOM"} ,
		{"word":"atours","word_nosc":"atours","lemma":"atour","pos":"NOM"} ,
		{"word":"atout","word_nosc":"atout","lemma":"atout","pos":"NOM"} ,
		{"word":"atouts","word_nosc":"atouts","lemma":"atout","pos":"NOM"} ,
		{"word":"atrabilaire","word_nosc":"atrabilaire","lemma":"atrabilaire","pos":"NOM"} ,
		{"word":"atrium","word_nosc":"atrium","lemma":"atrium","pos":"NOM"} ,
		{"word":"atrocité","word_nosc":"atrocite","lemma":"atrocité","pos":"NOM"} ,
		{"word":"atrocités","word_nosc":"atrocites","lemma":"atrocité","pos":"NOM"} ,
		{"word":"atrophie","word_nosc":"atrophie","lemma":"atrophie","pos":"NOM"} ,
		{"word":"atropine","word_nosc":"atropine","lemma":"atropine","pos":"NOM"} ,
		{"word":"atrésie","word_nosc":"atresie","lemma":"atrésie","pos":"NOM"} ,
		{"word":"attache","word_nosc":"attache","lemma":"attache","pos":"NOM"} ,
		{"word":"attachement","word_nosc":"attachement","lemma":"attachement","pos":"NOM"} ,
		{"word":"attachements","word_nosc":"attachements","lemma":"attachement","pos":"NOM"} ,
		{"word":"attaches","word_nosc":"attaches","lemma":"attache","pos":"NOM"} ,
		{"word":"attaché","word_nosc":"attache","lemma":"attaché","pos":"NOM"} ,
		{"word":"attaché-case","word_nosc":"attache-case","lemma":"attaché-case","pos":"NOM"} ,
		{"word":"attachée","word_nosc":"attachee","lemma":"attaché","pos":"NOM"} ,
		{"word":"attachées","word_nosc":"attachees","lemma":"attaché","pos":"NOM"} ,
		{"word":"attachés","word_nosc":"attaches","lemma":"attaché","pos":"NOM"} ,
		{"word":"attachés-cases","word_nosc":"attaches-cases","lemma":"attaché-case","pos":"NOM"} ,
		{"word":"attaquant","word_nosc":"attaquant","lemma":"attaquant","pos":"NOM"} ,
		{"word":"attaquante","word_nosc":"attaquante","lemma":"attaquant","pos":"NOM"} ,
		{"word":"attaquants","word_nosc":"attaquants","lemma":"attaquant","pos":"NOM"} ,
		{"word":"attaque","word_nosc":"attaque","lemma":"attaque","pos":"NOM"} ,
		{"word":"attaques","word_nosc":"attaques","lemma":"attaque","pos":"NOM"} ,
		{"word":"attaqueur","word_nosc":"attaqueur","lemma":"attaqueur","pos":"NOM"} ,
		{"word":"attardement","word_nosc":"attardement","lemma":"attardement","pos":"NOM"} ,
		{"word":"attardé","word_nosc":"attarde","lemma":"attardé","pos":"NOM"} ,
		{"word":"attardée","word_nosc":"attardee","lemma":"attardé","pos":"NOM"} ,
		{"word":"attardés","word_nosc":"attardes","lemma":"attardé","pos":"NOM"} ,
		{"word":"atteinte","word_nosc":"atteinte","lemma":"atteinte","pos":"NOM"} ,
		{"word":"atteintes","word_nosc":"atteintes","lemma":"atteinte","pos":"NOM"} ,
		{"word":"attelage","word_nosc":"attelage","lemma":"attelage","pos":"NOM"} ,
		{"word":"attelages","word_nosc":"attelages","lemma":"attelage","pos":"NOM"} ,
		{"word":"attelle","word_nosc":"attelle","lemma":"attelle","pos":"NOM"} ,
		{"word":"attelles","word_nosc":"attelles","lemma":"attelle","pos":"NOM"} ,
		{"word":"attendrissement","word_nosc":"attendrissement","lemma":"attendrissement","pos":"NOM"} ,
		{"word":"attendrissements","word_nosc":"attendrissements","lemma":"attendrissement","pos":"NOM"} ,
		{"word":"attendrisseur","word_nosc":"attendrisseur","lemma":"attendrisseur","pos":"NOM"} ,
		{"word":"attendu","word_nosc":"attendu","lemma":"attendu","pos":"NOM"} ,
		{"word":"attendus","word_nosc":"attendus","lemma":"attendu","pos":"NOM"} ,
		{"word":"attentat","word_nosc":"attentat","lemma":"attentat","pos":"NOM"} ,
		{"word":"attentats","word_nosc":"attentats","lemma":"attentat","pos":"NOM"} ,
		{"word":"attente","word_nosc":"attente","lemma":"attente","pos":"NOM"} ,
		{"word":"attentes","word_nosc":"attentes","lemma":"attente","pos":"NOM"} ,
		{"word":"attention","word_nosc":"attention","lemma":"attention","pos":"NOM"} ,
		{"word":"attentions","word_nosc":"attentions","lemma":"attention","pos":"NOM"} ,
		{"word":"attentisme","word_nosc":"attentisme","lemma":"attentisme","pos":"NOM"} ,
		{"word":"attentistes","word_nosc":"attentistes","lemma":"attentiste","pos":"NOM"} ,
		{"word":"atterrages","word_nosc":"atterrages","lemma":"atterrage","pos":"NOM"} ,
		{"word":"atterrissage","word_nosc":"atterrissage","lemma":"atterrissage","pos":"NOM"} ,
		{"word":"atterrissages","word_nosc":"atterrissages","lemma":"atterrissage","pos":"NOM"} ,
		{"word":"attestation","word_nosc":"attestation","lemma":"attestation","pos":"NOM"} ,
		{"word":"attestations","word_nosc":"attestations","lemma":"attestation","pos":"NOM"} ,
		{"word":"attifiaux","word_nosc":"attifiaux","lemma":"attifiaux","pos":"NOM"} ,
		{"word":"attirail","word_nosc":"attirail","lemma":"attirail","pos":"NOM"} ,
		{"word":"attirance","word_nosc":"attirance","lemma":"attirance","pos":"NOM"} ,
		{"word":"attirances","word_nosc":"attirances","lemma":"attirance","pos":"NOM"} ,
		{"word":"attisement","word_nosc":"attisement","lemma":"attisement","pos":"NOM"} ,
		{"word":"attisoir","word_nosc":"attisoir","lemma":"attisoir","pos":"NOM"} ,
		{"word":"attitude","word_nosc":"attitude","lemma":"attitude","pos":"NOM"} ,
		{"word":"attitudes","word_nosc":"attitudes","lemma":"attitude","pos":"NOM"} ,
		{"word":"attorney","word_nosc":"attorney","lemma":"attorney","pos":"NOM"} ,
		{"word":"attouchement","word_nosc":"attouchement","lemma":"attouchement","pos":"NOM"} ,
		{"word":"attouchements","word_nosc":"attouchements","lemma":"attouchement","pos":"NOM"} ,
		{"word":"attraction","word_nosc":"attraction","lemma":"attraction","pos":"NOM"} ,
		{"word":"attraction-vedette","word_nosc":"attraction-vedette","lemma":"attraction-vedette","pos":"NOM"} ,
		{"word":"attractions","word_nosc":"attractions","lemma":"attraction","pos":"NOM"} ,
		{"word":"attrait","word_nosc":"attrait","lemma":"attrait","pos":"NOM"} ,
		{"word":"attraits","word_nosc":"attraits","lemma":"attrait","pos":"NOM"} ,
		{"word":"attrapage","word_nosc":"attrapage","lemma":"attrapage","pos":"NOM"} ,
		{"word":"attrape","word_nosc":"attrape","lemma":"attrape","pos":"NOM"} ,
		{"word":"attrape-couillon","word_nosc":"attrape-couillon","lemma":"attrape-couillon","pos":"NOM"} ,
		{"word":"attrape-couillons","word_nosc":"attrape-couillons","lemma":"attrape-couillon","pos":"NOM"} ,
		{"word":"attrape-mouche","word_nosc":"attrape-mouche","lemma":"attrape-mouche","pos":"NOM"} ,
		{"word":"attrape-mouches","word_nosc":"attrape-mouches","lemma":"attrape-mouche","pos":"NOM"} ,
		{"word":"attrape-nigaud","word_nosc":"attrape-nigaud","lemma":"attrape-nigaud","pos":"NOM"} ,
		{"word":"attrapes","word_nosc":"attrapes","lemma":"attrape","pos":"NOM"} ,
		{"word":"attrapeur","word_nosc":"attrapeur","lemma":"attrapeur","pos":"NOM"} ,
		{"word":"attribut","word_nosc":"attribut","lemma":"attribut","pos":"NOM"} ,
		{"word":"attribution","word_nosc":"attribution","lemma":"attribution","pos":"NOM"} ,
		{"word":"attributions","word_nosc":"attributions","lemma":"attribution","pos":"NOM"} ,
		{"word":"attributs","word_nosc":"attributs","lemma":"attribut","pos":"NOM"} ,
		{"word":"attrition","word_nosc":"attrition","lemma":"attrition","pos":"NOM"} ,
		{"word":"attritions","word_nosc":"attritions","lemma":"attrition","pos":"NOM"} ,
		{"word":"attroupement","word_nosc":"attroupement","lemma":"attroupement","pos":"NOM"} ,
		{"word":"attroupements","word_nosc":"attroupements","lemma":"attroupement","pos":"NOM"} ,
		{"word":"atténuateur","word_nosc":"attenuateur","lemma":"atténuateur","pos":"NOM"} ,
		{"word":"atténuation","word_nosc":"attenuation","lemma":"atténuation","pos":"NOM"} ,
		{"word":"atténuations","word_nosc":"attenuations","lemma":"atténuation","pos":"NOM"} ,
		{"word":"atèle","word_nosc":"atele","lemma":"atèle","pos":"NOM"} ,
		{"word":"atélectasie","word_nosc":"atelectasie","lemma":"atélectasie","pos":"NOM"} ,
		{"word":"atémi","word_nosc":"atemi","lemma":"atémi","pos":"NOM"} ,
		{"word":"au-delà","word_nosc":"au-dela","lemma":"au-delà","pos":"NOM"} ,
		{"word":"aubade","word_nosc":"aubade","lemma":"aubade","pos":"NOM"} ,
		{"word":"aubain","word_nosc":"aubain","lemma":"aubain","pos":"NOM"} ,
		{"word":"aubaine","word_nosc":"aubaine","lemma":"aubaine","pos":"NOM"} ,
		{"word":"aubaines","word_nosc":"aubaines","lemma":"aubaine","pos":"NOM"} ,
		{"word":"aube","word_nosc":"aube","lemma":"aube","pos":"NOM"} ,
		{"word":"auberge","word_nosc":"auberge","lemma":"auberge","pos":"NOM"} ,
		{"word":"auberges","word_nosc":"auberges","lemma":"auberge","pos":"NOM"} ,
		{"word":"aubergine","word_nosc":"aubergine","lemma":"aubergine","pos":"NOM"} ,
		{"word":"aubergines","word_nosc":"aubergines","lemma":"aubergine","pos":"NOM"} ,
		{"word":"aubergiste","word_nosc":"aubergiste","lemma":"aubergiste","pos":"NOM"} ,
		{"word":"aubergistes","word_nosc":"aubergistes","lemma":"aubergiste","pos":"NOM"} ,
		{"word":"aubes","word_nosc":"aubes","lemma":"aube","pos":"NOM"} ,
		{"word":"aubette","word_nosc":"aubette","lemma":"aubette","pos":"NOM"} ,
		{"word":"aubier","word_nosc":"aubier","lemma":"aubier","pos":"NOM"} ,
		{"word":"aubour","word_nosc":"aubour","lemma":"aubour","pos":"NOM"} ,
		{"word":"aubusson","word_nosc":"aubusson","lemma":"aubusson","pos":"NOM"} ,
		{"word":"aubère","word_nosc":"aubere","lemma":"aubère","pos":"NOM"} ,
		{"word":"aubères","word_nosc":"auberes","lemma":"aubère","pos":"NOM"} ,
		{"word":"aubépine","word_nosc":"aubepine","lemma":"aubépine","pos":"NOM"} ,
		{"word":"aubépines","word_nosc":"aubepines","lemma":"aubépine","pos":"NOM"} ,
		{"word":"aucubas","word_nosc":"aucubas","lemma":"aucuba","pos":"NOM"} ,
		{"word":"audace","word_nosc":"audace","lemma":"audace","pos":"NOM"} ,
		{"word":"audaces","word_nosc":"audaces","lemma":"audace","pos":"NOM"} ,
		{"word":"audience","word_nosc":"audience","lemma":"audience","pos":"NOM"} ,
		{"word":"audiences","word_nosc":"audiences","lemma":"audience","pos":"NOM"} ,
		{"word":"audiencia","word_nosc":"audiencia","lemma":"audiencia","pos":"NOM"} ,
		{"word":"audimat","word_nosc":"audimat","lemma":"audimat","pos":"NOM"} ,
		{"word":"audimats","word_nosc":"audimats","lemma":"audimat","pos":"NOM"} ,
		{"word":"audioconférence","word_nosc":"audioconference","lemma":"audioconférence","pos":"NOM"} ,
		{"word":"audiogramme","word_nosc":"audiogramme","lemma":"audiogramme","pos":"NOM"} ,
		{"word":"audiométrie","word_nosc":"audiometrie","lemma":"audiométrie","pos":"NOM"} ,
		{"word":"audiophile","word_nosc":"audiophile","lemma":"audiophile","pos":"NOM"} ,
		{"word":"audiophone","word_nosc":"audiophone","lemma":"audiophone","pos":"NOM"} ,
		{"word":"audiovisuel","word_nosc":"audiovisuel","lemma":"audiovisuel","pos":"NOM"} ,
		{"word":"audit","word_nosc":"audit","lemma":"audit","pos":"NOM"} ,
		{"word":"auditeur","word_nosc":"auditeur","lemma":"auditeur","pos":"NOM"} ,
		{"word":"auditeurs","word_nosc":"auditeurs","lemma":"auditeur","pos":"NOM"} ,
		{"word":"audition","word_nosc":"audition","lemma":"audition","pos":"NOM"} ,
		{"word":"auditions","word_nosc":"auditions","lemma":"audition","pos":"NOM"} ,
		{"word":"auditoire","word_nosc":"auditoire","lemma":"auditoire","pos":"NOM"} ,
		{"word":"auditoires","word_nosc":"auditoires","lemma":"auditoire","pos":"NOM"} ,
		{"word":"auditorium","word_nosc":"auditorium","lemma":"auditorium","pos":"NOM"} ,
		{"word":"auditoriums","word_nosc":"auditoriums","lemma":"auditorium","pos":"NOM"} ,
		{"word":"auditrice","word_nosc":"auditrice","lemma":"auditeur","pos":"NOM"} ,
		{"word":"auditrices","word_nosc":"auditrices","lemma":"auditeur","pos":"NOM"} ,
		{"word":"audits","word_nosc":"audits","lemma":"audit","pos":"NOM"} ,
		{"word":"auge","word_nosc":"auge","lemma":"auge","pos":"NOM"} ,
		{"word":"auges","word_nosc":"auges","lemma":"auge","pos":"NOM"} ,
		{"word":"augment","word_nosc":"augment","lemma":"augment","pos":"NOM"} ,
		{"word":"augmentation","word_nosc":"augmentation","lemma":"augmentation","pos":"NOM"} ,
		{"word":"augmentations","word_nosc":"augmentations","lemma":"augmentation","pos":"NOM"} ,
		{"word":"augure","word_nosc":"augure","lemma":"augure","pos":"NOM"} ,
		{"word":"augures","word_nosc":"augures","lemma":"augure","pos":"NOM"} ,
		{"word":"auguste","word_nosc":"auguste","lemma":"auguste","pos":"NOM"} ,
		{"word":"augustes","word_nosc":"augustes","lemma":"auguste","pos":"NOM"} ,
		{"word":"augustin","word_nosc":"augustin","lemma":"augustin","pos":"NOM"} ,
		{"word":"augustins","word_nosc":"augustins","lemma":"augustin","pos":"NOM"} ,
		{"word":"aula","word_nosc":"aula","lemma":"aula","pos":"NOM"} ,
		{"word":"aulnaie","word_nosc":"aulnaie","lemma":"aulnaie","pos":"NOM"} ,
		{"word":"aulne","word_nosc":"aulne","lemma":"aulne","pos":"NOM"} ,
		{"word":"aulnes","word_nosc":"aulnes","lemma":"aulne","pos":"NOM"} ,
		{"word":"auloffée","word_nosc":"auloffee","lemma":"auloffée","pos":"NOM"} ,
		{"word":"aulos","word_nosc":"aulos","lemma":"aulos","pos":"NOM"} ,
		{"word":"aulx","word_nosc":"aulx","lemma":"aulx","pos":"NOM"} ,
		{"word":"aumône","word_nosc":"aumone","lemma":"aumône","pos":"NOM"} ,
		{"word":"aumônerie","word_nosc":"aumonerie","lemma":"aumônerie","pos":"NOM"} ,
		{"word":"aumônes","word_nosc":"aumones","lemma":"aumône","pos":"NOM"} ,
		{"word":"aumônier","word_nosc":"aumonier","lemma":"aumônier","pos":"NOM"} ,
		{"word":"aumôniers","word_nosc":"aumoniers","lemma":"aumônier","pos":"NOM"} ,
		{"word":"aumônière","word_nosc":"aumoniere","lemma":"aumônier","pos":"NOM"} ,
		{"word":"aumônières","word_nosc":"aumonieres","lemma":"aumônier","pos":"NOM"} ,
		{"word":"aune","word_nosc":"aune","lemma":"aune","pos":"NOM"} ,
		{"word":"aunes","word_nosc":"aunes","lemma":"aune","pos":"NOM"} ,
		{"word":"aura","word_nosc":"aura","lemma":"aura","pos":"NOM"} ,
		{"word":"auras","word_nosc":"auras","lemma":"aura","pos":"NOM"} ,
		{"word":"aureus","word_nosc":"aureus","lemma":"aureus","pos":"NOM"} ,
		{"word":"auriculaire","word_nosc":"auriculaire","lemma":"auriculaire","pos":"NOM"} ,
		{"word":"auriculaires","word_nosc":"auriculaires","lemma":"auriculaire","pos":"NOM"} ,
		{"word":"auricule","word_nosc":"auricule","lemma":"auricule","pos":"NOM"} ,
		{"word":"aurige","word_nosc":"aurige","lemma":"aurige","pos":"NOM"} ,
		{"word":"aurochs","word_nosc":"aurochs","lemma":"aurochs","pos":"NOM"} ,
		{"word":"aurone","word_nosc":"aurone","lemma":"aurone","pos":"NOM"} ,
		{"word":"aurore","word_nosc":"aurore","lemma":"aurore","pos":"NOM"} ,
		{"word":"aurores","word_nosc":"aurores","lemma":"aurore","pos":"NOM"} ,
		{"word":"auréole","word_nosc":"aureole","lemma":"auréole","pos":"NOM"} ,
		{"word":"auréoles","word_nosc":"aureoles","lemma":"auréole","pos":"NOM"} ,
		{"word":"auréomycine","word_nosc":"aureomycine","lemma":"auréomycine","pos":"NOM"} ,
		{"word":"auscultation","word_nosc":"auscultation","lemma":"auscultation","pos":"NOM"} ,
		{"word":"auscultations","word_nosc":"auscultations","lemma":"auscultation","pos":"NOM"} ,
		{"word":"auspices","word_nosc":"auspices","lemma":"auspice","pos":"NOM"} ,
		{"word":"aussière","word_nosc":"aussiere","lemma":"aussière","pos":"NOM"} ,
		{"word":"aussières","word_nosc":"aussieres","lemma":"aussière","pos":"NOM"} ,
		{"word":"australien","word_nosc":"australien","lemma":"australien","pos":"NOM"} ,
		{"word":"australienne","word_nosc":"australienne","lemma":"australienne","pos":"NOM"} ,
		{"word":"australiennes","word_nosc":"australiennes","lemma":"australienne","pos":"NOM"} ,
		{"word":"australiens","word_nosc":"australiens","lemma":"australien","pos":"NOM"} ,
		{"word":"australopithèque","word_nosc":"australopitheque","lemma":"australopithèque","pos":"NOM"} ,
		{"word":"australopithèques","word_nosc":"australopitheques","lemma":"australopithèque","pos":"NOM"} ,
		{"word":"austro-hongrois","word_nosc":"austro-hongrois","lemma":"austro-hongrois","pos":"NOM"} ,
		{"word":"austérité","word_nosc":"austerite","lemma":"austérité","pos":"NOM"} ,
		{"word":"austérités","word_nosc":"austerites","lemma":"austérité","pos":"NOM"} ,
		{"word":"ausweis","word_nosc":"ausweis","lemma":"ausweis","pos":"NOM"} ,
		{"word":"autan","word_nosc":"autan","lemma":"autan","pos":"NOM"} ,
		{"word":"autarcie","word_nosc":"autarcie","lemma":"autarcie","pos":"NOM"} ,
		{"word":"autel","word_nosc":"autel","lemma":"autel","pos":"NOM"} ,
		{"word":"autels","word_nosc":"autels","lemma":"autel","pos":"NOM"} ,
		{"word":"auteur","word_nosc":"auteur","lemma":"auteur","pos":"NOM"} ,
		{"word":"auteure","word_nosc":"auteure","lemma":"auteur","pos":"NOM"} ,
		{"word":"auteurs","word_nosc":"auteurs","lemma":"auteur","pos":"NOM"} ,
		{"word":"authenticité","word_nosc":"authenticite","lemma":"authenticité","pos":"NOM"} ,
		{"word":"authentification","word_nosc":"authentification","lemma":"authentification","pos":"NOM"} ,
		{"word":"autisme","word_nosc":"autisme","lemma":"autisme","pos":"NOM"} ,
		{"word":"autiste","word_nosc":"autiste","lemma":"autiste","pos":"NOM"} ,
		{"word":"autistes","word_nosc":"autistes","lemma":"autiste","pos":"NOM"} ,
		{"word":"auto","word_nosc":"auto","lemma":"auto","pos":"NOM"} ,
		{"word":"auto-analyse","word_nosc":"auto-analyse","lemma":"auto-analyse","pos":"NOM"} ,
		{"word":"auto-intoxication","word_nosc":"auto-intoxication","lemma":"auto-intoxication","pos":"NOM"} ,
		{"word":"auto-pilote","word_nosc":"auto-pilote","lemma":"auto-pilote","pos":"NOM"} ,
		{"word":"auto-stop","word_nosc":"auto-stop","lemma":"auto-stop","pos":"NOM"} ,
		{"word":"auto-stoppeur","word_nosc":"auto-stoppeur","lemma":"auto-stoppeur","pos":"NOM"} ,
		{"word":"auto-stoppeurs","word_nosc":"auto-stoppeurs","lemma":"auto-stoppeur","pos":"NOM"} ,
		{"word":"auto-stoppeuse","word_nosc":"auto-stoppeuse","lemma":"auto-stoppeur","pos":"NOM"} ,
		{"word":"auto-stoppeuses","word_nosc":"auto-stoppeuses","lemma":"auto-stoppeur","pos":"NOM"} ,
		{"word":"auto-tamponneuse","word_nosc":"auto-tamponneuse","lemma":"auto-tamponneuse","pos":"NOM"} ,
		{"word":"auto-tamponneuses","word_nosc":"auto-tamponneuses","lemma":"auto-tamponneuse","pos":"NOM"} ,
		{"word":"auto-école","word_nosc":"auto-ecole","lemma":"auto-école","pos":"NOM"} ,
		{"word":"auto-érotisme","word_nosc":"auto-erotisme","lemma":"auto-érotisme","pos":"NOM"} ,
		{"word":"autobiographe","word_nosc":"autobiographe","lemma":"autobiographe","pos":"NOM"} ,
		{"word":"autobiographie","word_nosc":"autobiographie","lemma":"autobiographie","pos":"NOM"} ,
		{"word":"autobiographies","word_nosc":"autobiographies","lemma":"autobiographie","pos":"NOM"} ,
		{"word":"autobronzant","word_nosc":"autobronzant","lemma":"autobronzant","pos":"NOM"} ,
		{"word":"autobus","word_nosc":"autobus","lemma":"autobus","pos":"NOM"} ,
		{"word":"autocar","word_nosc":"autocar","lemma":"autocar","pos":"NOM"} ,
		{"word":"autocars","word_nosc":"autocars","lemma":"autocar","pos":"NOM"} ,
		{"word":"autocensure","word_nosc":"autocensure","lemma":"autocensure","pos":"NOM"} ,
		{"word":"autochenille","word_nosc":"autochenille","lemma":"autochenille","pos":"NOM"} ,
		{"word":"autochtone","word_nosc":"autochtone","lemma":"autochtone","pos":"NOM"} ,
		{"word":"autochtones","word_nosc":"autochtones","lemma":"autochtone","pos":"NOM"} ,
		{"word":"autoclave","word_nosc":"autoclave","lemma":"autoclave","pos":"NOM"} ,
		{"word":"autocollant","word_nosc":"autocollant","lemma":"autocollant","pos":"NOM"} ,
		{"word":"autocollants","word_nosc":"autocollants","lemma":"autocollant","pos":"NOM"} ,
		{"word":"autoconservation","word_nosc":"autoconservation","lemma":"autoconservation","pos":"NOM"} ,
		{"word":"autocrates","word_nosc":"autocrates","lemma":"autocrate","pos":"NOM"} ,
		{"word":"autocratie","word_nosc":"autocratie","lemma":"autocratie","pos":"NOM"} ,
		{"word":"autocritique","word_nosc":"autocritique","lemma":"autocritique","pos":"NOM"} ,
		{"word":"autocritiques","word_nosc":"autocritiques","lemma":"autocritique","pos":"NOM"} ,
		{"word":"autocréation","word_nosc":"autocreation","lemma":"autocréation","pos":"NOM"} ,
		{"word":"autocuiseur","word_nosc":"autocuiseur","lemma":"autocuiseur","pos":"NOM"} ,
		{"word":"autodafé","word_nosc":"autodafe","lemma":"autodafé","pos":"NOM"} ,
		{"word":"autodafés","word_nosc":"autodafes","lemma":"autodafé","pos":"NOM"} ,
		{"word":"autodestruction","word_nosc":"autodestruction","lemma":"autodestruction","pos":"NOM"} ,
		{"word":"autodidacte","word_nosc":"autodidacte","lemma":"autodidacte","pos":"NOM"} ,
		{"word":"autodidactes","word_nosc":"autodidactes","lemma":"autodidacte","pos":"NOM"} ,
		{"word":"autodiscipline","word_nosc":"autodiscipline","lemma":"autodiscipline","pos":"NOM"} ,
		{"word":"autodrome","word_nosc":"autodrome","lemma":"autodrome","pos":"NOM"} ,
		{"word":"autodéfense","word_nosc":"autodefense","lemma":"autodéfense","pos":"NOM"} ,
		{"word":"autodénigrement","word_nosc":"autodenigrement","lemma":"autodénigrement","pos":"NOM"} ,
		{"word":"autodérision","word_nosc":"autoderision","lemma":"autodérision","pos":"NOM"} ,
		{"word":"autodétermination","word_nosc":"autodetermination","lemma":"autodétermination","pos":"NOM"} ,
		{"word":"autofinancement","word_nosc":"autofinancement","lemma":"autofinancement","pos":"NOM"} ,
		{"word":"autofocus","word_nosc":"autofocus","lemma":"autofocus","pos":"NOM"} ,
		{"word":"autogenèse","word_nosc":"autogenese","lemma":"autogenèse","pos":"NOM"} ,
		{"word":"autogestion","word_nosc":"autogestion","lemma":"autogestion","pos":"NOM"} ,
		{"word":"autogire","word_nosc":"autogire","lemma":"autogire","pos":"NOM"} ,
		{"word":"autogires","word_nosc":"autogires","lemma":"autogire","pos":"NOM"} ,
		{"word":"autographe","word_nosc":"autographe","lemma":"autographe","pos":"NOM"} ,
		{"word":"autographes","word_nosc":"autographes","lemma":"autographe","pos":"NOM"} ,
		{"word":"autoguidage","word_nosc":"autoguidage","lemma":"autoguidage","pos":"NOM"} ,
		{"word":"autolysat","word_nosc":"autolysat","lemma":"autolysat","pos":"NOM"} ,
		{"word":"autolyse","word_nosc":"autolyse","lemma":"autolyse","pos":"NOM"} ,
		{"word":"automate","word_nosc":"automate","lemma":"automate","pos":"NOM"} ,
		{"word":"automates","word_nosc":"automates","lemma":"automate","pos":"NOM"} ,
		{"word":"automation","word_nosc":"automation","lemma":"automation","pos":"NOM"} ,
		{"word":"automatique","word_nosc":"automatique","lemma":"automatique","pos":"NOM"} ,
		{"word":"automatiques","word_nosc":"automatiques","lemma":"automatique","pos":"NOM"} ,
		{"word":"automatisation","word_nosc":"automatisation","lemma":"automatisation","pos":"NOM"} ,
		{"word":"automatisme","word_nosc":"automatisme","lemma":"automatisme","pos":"NOM"} ,
		{"word":"automatismes","word_nosc":"automatismes","lemma":"automatisme","pos":"NOM"} ,
		{"word":"automitrailleuse","word_nosc":"automitrailleuse","lemma":"automitrailleur","pos":"NOM"} ,
		{"word":"automitrailleuses","word_nosc":"automitrailleuses","lemma":"automitrailleur","pos":"NOM"} ,
		{"word":"automne","word_nosc":"automne","lemma":"automne","pos":"NOM"} ,
		{"word":"automnes","word_nosc":"automnes","lemma":"automne","pos":"NOM"} ,
		{"word":"automobile","word_nosc":"automobile","lemma":"automobile","pos":"NOM"} ,
		{"word":"automobile-club","word_nosc":"automobile-club","lemma":"automobile-club","pos":"NOM"} ,
		{"word":"automobiles","word_nosc":"automobiles","lemma":"automobile","pos":"NOM"} ,
		{"word":"automobiliste","word_nosc":"automobiliste","lemma":"automobiliste","pos":"NOM"} ,
		{"word":"automobilistes","word_nosc":"automobilistes","lemma":"automobiliste","pos":"NOM"} ,
		{"word":"automoteurs","word_nosc":"automoteurs","lemma":"automoteur","pos":"NOM"} ,
		{"word":"automutilation","word_nosc":"automutilation","lemma":"automutilation","pos":"NOM"} ,
		{"word":"automédication","word_nosc":"automedication","lemma":"automédication","pos":"NOM"} ,
		{"word":"autoneige","word_nosc":"autoneige","lemma":"autoneige","pos":"NOM"} ,
		{"word":"autoneiges","word_nosc":"autoneiges","lemma":"autoneige","pos":"NOM"} ,
		{"word":"autonome","word_nosc":"autonome","lemma":"autonome","pos":"NOM"} ,
		{"word":"autonomes","word_nosc":"autonomes","lemma":"autonome","pos":"NOM"} ,
		{"word":"autonomie","word_nosc":"autonomie","lemma":"autonomie","pos":"NOM"} ,
		{"word":"autonomistes","word_nosc":"autonomistes","lemma":"autonomiste","pos":"NOM"} ,
		{"word":"autopilote","word_nosc":"autopilote","lemma":"autopilote","pos":"NOM"} ,
		{"word":"autoplastie","word_nosc":"autoplastie","lemma":"autoplastie","pos":"NOM"} ,
		{"word":"autoportrait","word_nosc":"autoportrait","lemma":"autoportrait","pos":"NOM"} ,
		{"word":"autoportraits","word_nosc":"autoportraits","lemma":"autoportrait","pos":"NOM"} ,
		{"word":"autoproduit","word_nosc":"autoproduit","lemma":"autoproduit","pos":"NOM"} ,
		{"word":"autopropulseur","word_nosc":"autopropulseur","lemma":"autopropulseur","pos":"NOM"} ,
		{"word":"autopsie","word_nosc":"autopsie","lemma":"autopsie","pos":"NOM"} ,
		{"word":"autopsies","word_nosc":"autopsies","lemma":"autopsie","pos":"NOM"} ,
		{"word":"autopunition","word_nosc":"autopunition","lemma":"autopunition","pos":"NOM"} ,
		{"word":"autoradio","word_nosc":"autoradio","lemma":"autoradio","pos":"NOM"} ,
		{"word":"autoradios","word_nosc":"autoradios","lemma":"autoradio","pos":"NOM"} ,
		{"word":"autorail","word_nosc":"autorail","lemma":"autorail","pos":"NOM"} ,
		{"word":"autoreproduction","word_nosc":"autoreproduction","lemma":"autoreproduction","pos":"NOM"} ,
		{"word":"autorisation","word_nosc":"autorisation","lemma":"autorisation","pos":"NOM"} ,
		{"word":"autorisations","word_nosc":"autorisations","lemma":"autorisation","pos":"NOM"} ,
		{"word":"autoritaire","word_nosc":"autoritaire","lemma":"autoritaire","pos":"NOM"} ,
		{"word":"autoritarisme","word_nosc":"autoritarisme","lemma":"autoritarisme","pos":"NOM"} ,
		{"word":"autorité","word_nosc":"autorite","lemma":"autorité","pos":"NOM"} ,
		{"word":"autorités","word_nosc":"autorites","lemma":"autorité","pos":"NOM"} ,
		{"word":"autoroute","word_nosc":"autoroute","lemma":"autoroute","pos":"NOM"} ,
		{"word":"autoroutes","word_nosc":"autoroutes","lemma":"autoroute","pos":"NOM"} ,
		{"word":"autorégulation","word_nosc":"autoregulation","lemma":"autorégulation","pos":"NOM"} ,
		{"word":"autos","word_nosc":"autos","lemma":"auto","pos":"NOM"} ,
		{"word":"autosatisfaction","word_nosc":"autosatisfaction","lemma":"autosatisfaction","pos":"NOM"} ,
		{"word":"autostop","word_nosc":"autostop","lemma":"autostop","pos":"NOM"} ,
		{"word":"autostrade","word_nosc":"autostrade","lemma":"autostrade","pos":"NOM"} ,
		{"word":"autostrades","word_nosc":"autostrades","lemma":"autostrade","pos":"NOM"} ,
		{"word":"autosubsistance","word_nosc":"autosubsistance","lemma":"autosubsistance","pos":"NOM"} ,
		{"word":"autosuffisance","word_nosc":"autosuffisance","lemma":"autosuffisance","pos":"NOM"} ,
		{"word":"autosuggestion","word_nosc":"autosuggestion","lemma":"autosuggestion","pos":"NOM"} ,
		{"word":"autosurveillance","word_nosc":"autosurveillance","lemma":"autosurveillance","pos":"NOM"} ,
		{"word":"autour","word_nosc":"autour","lemma":"autour","pos":"NOM"} ,
		{"word":"autours","word_nosc":"autours","lemma":"autour","pos":"NOM"} ,
		{"word":"autre","word_nosc":"autre","lemma":"autre","pos":"NOM"} ,
		{"word":"autres","word_nosc":"autres","lemma":"autre","pos":"NOM"} ,
		{"word":"autrichien","word_nosc":"autrichien","lemma":"autrichien","pos":"NOM"} ,
		{"word":"autrichienne","word_nosc":"autrichienne","lemma":"autrichien","pos":"NOM"} ,
		{"word":"autrichiennes","word_nosc":"autrichiennes","lemma":"autrichien","pos":"NOM"} ,
		{"word":"autrichiens","word_nosc":"autrichiens","lemma":"autrichien","pos":"NOM"} ,
		{"word":"autruche","word_nosc":"autruche","lemma":"autruche","pos":"NOM"} ,
		{"word":"autruches","word_nosc":"autruches","lemma":"autruche","pos":"NOM"} ,
		{"word":"auvent","word_nosc":"auvent","lemma":"auvent","pos":"NOM"} ,
		{"word":"auvents","word_nosc":"auvents","lemma":"auvent","pos":"NOM"} ,
		{"word":"auvergnat","word_nosc":"auvergnat","lemma":"auvergnat","pos":"NOM"} ,
		{"word":"auvergnate","word_nosc":"auvergnate","lemma":"auvergnat","pos":"NOM"} ,
		{"word":"auvergnates","word_nosc":"auvergnates","lemma":"auvergnat","pos":"NOM"} ,
		{"word":"auvergnats","word_nosc":"auvergnats","lemma":"auvergnat","pos":"NOM"} ,
		{"word":"auvergne","word_nosc":"auvergne","lemma":"auvergne","pos":"NOM"} ,
		{"word":"auverpin","word_nosc":"auverpin","lemma":"auverpin","pos":"NOM"} ,
		{"word":"auverpins","word_nosc":"auverpins","lemma":"auverpin","pos":"NOM"} ,
		{"word":"auxerrois","word_nosc":"auxerrois","lemma":"auxerrois","pos":"NOM"} ,
		{"word":"auxiliaire","word_nosc":"auxiliaire","lemma":"auxiliaire","pos":"NOM"} ,
		{"word":"auxiliaires","word_nosc":"auxiliaires","lemma":"auxiliaire","pos":"NOM"} ,
		{"word":"avachissement","word_nosc":"avachissement","lemma":"avachissement","pos":"NOM"} ,
		{"word":"aval","word_nosc":"aval","lemma":"aval","pos":"NOM"} ,
		{"word":"avalage","word_nosc":"avalage","lemma":"avalage","pos":"NOM"} ,
		{"word":"avalanche","word_nosc":"avalanche","lemma":"avalanche","pos":"NOM"} ,
		{"word":"avalanches","word_nosc":"avalanches","lemma":"avalanche","pos":"NOM"} ,
		{"word":"avalement","word_nosc":"avalement","lemma":"avalement","pos":"NOM"} ,
		{"word":"avaleur","word_nosc":"avaleur","lemma":"avaleur","pos":"NOM"} ,
		{"word":"avaleurs","word_nosc":"avaleurs","lemma":"avaleur","pos":"NOM"} ,
		{"word":"avaleuse","word_nosc":"avaleuse","lemma":"avaleur","pos":"NOM"} ,
		{"word":"avaliseur","word_nosc":"avaliseur","lemma":"avaliseur","pos":"NOM"} ,
		{"word":"avaloires","word_nosc":"avaloires","lemma":"avaloire","pos":"NOM"} ,
		{"word":"avance","word_nosc":"avance","lemma":"avance","pos":"NOM"} ,
		{"word":"avancement","word_nosc":"avancement","lemma":"avancement","pos":"NOM"} ,
		{"word":"avancements","word_nosc":"avancements","lemma":"avancement","pos":"NOM"} ,
		{"word":"avances","word_nosc":"avances","lemma":"avance","pos":"NOM"} ,
		{"word":"avancée","word_nosc":"avancee","lemma":"avancée","pos":"NOM"} ,
		{"word":"avancées","word_nosc":"avancees","lemma":"avancée","pos":"NOM"} ,
		{"word":"avanie","word_nosc":"avanie","lemma":"avanie","pos":"NOM"} ,
		{"word":"avanies","word_nosc":"avanies","lemma":"avanie","pos":"NOM"} ,
		{"word":"avant","word_nosc":"avant","lemma":"avant","pos":"NOM"} ,
		{"word":"avant-avant-dernier","word_nosc":"avant-avant-dernier","lemma":"avant-avant-dernier","pos":"NOM"} ,
		{"word":"avant-bec","word_nosc":"avant-bec","lemma":"avant-bec","pos":"NOM"} ,
		{"word":"avant-bras","word_nosc":"avant-bras","lemma":"avant-bras","pos":"NOM"} ,
		{"word":"avant-centre","word_nosc":"avant-centre","lemma":"avant-centre","pos":"NOM"} ,
		{"word":"avant-clous","word_nosc":"avant-clous","lemma":"avant-clou","pos":"NOM"} ,
		{"word":"avant-corps","word_nosc":"avant-corps","lemma":"avant-corps","pos":"NOM"} ,
		{"word":"avant-coureur","word_nosc":"avant-coureur","lemma":"avant-coureur","pos":"NOM"} ,
		{"word":"avant-coureurs","word_nosc":"avant-coureurs","lemma":"avant-coureur","pos":"NOM"} ,
		{"word":"avant-courrières","word_nosc":"avant-courrieres","lemma":"avant-courrier","pos":"NOM"} ,
		{"word":"avant-cours","word_nosc":"avant-cours","lemma":"avant-cour","pos":"NOM"} ,
		{"word":"avant-dernier","word_nosc":"avant-dernier","lemma":"avant-dernier","pos":"NOM"} ,
		{"word":"avant-dernière","word_nosc":"avant-derniere","lemma":"avant-dernier","pos":"NOM"} ,
		{"word":"avant-dîner","word_nosc":"avant-diner","lemma":"avant-dîner","pos":"NOM"} ,
		{"word":"avant-garde","word_nosc":"avant-garde","lemma":"avant-garde","pos":"NOM"} ,
		{"word":"avant-gardes","word_nosc":"avant-gardes","lemma":"avant-garde","pos":"NOM"} ,
		{"word":"avant-gardiste","word_nosc":"avant-gardiste","lemma":"avant-gardiste","pos":"NOM"} ,
		{"word":"avant-gardistes","word_nosc":"avant-gardistes","lemma":"avant-gardiste","pos":"NOM"} ,
		{"word":"avant-goût","word_nosc":"avant-gout","lemma":"avant-goût","pos":"NOM"} ,
		{"word":"avant-guerre","word_nosc":"avant-guerre","lemma":"avant-guerre","pos":"NOM"} ,
		{"word":"avant-monts","word_nosc":"avant-monts","lemma":"avant-mont","pos":"NOM"} ,
		{"word":"avant-papier","word_nosc":"avant-papier","lemma":"avant-papier","pos":"NOM"} ,
		{"word":"avant-plan","word_nosc":"avant-plan","lemma":"avant-plan","pos":"NOM"} ,
		{"word":"avant-port","word_nosc":"avant-port","lemma":"avant-port","pos":"NOM"} ,
		{"word":"avant-poste","word_nosc":"avant-poste","lemma":"avant-poste","pos":"NOM"} ,
		{"word":"avant-postes","word_nosc":"avant-postes","lemma":"avant-poste","pos":"NOM"} ,
		{"word":"avant-première","word_nosc":"avant-premiere","lemma":"avant-première","pos":"NOM"} ,
		{"word":"avant-premières","word_nosc":"avant-premieres","lemma":"avant-première","pos":"NOM"} ,
		{"word":"avant-printemps","word_nosc":"avant-printemps","lemma":"avant-printemps","pos":"NOM"} ,
		{"word":"avant-projet","word_nosc":"avant-projet","lemma":"avant-projet","pos":"NOM"} ,
		{"word":"avant-propos","word_nosc":"avant-propos","lemma":"avant-propos","pos":"NOM"} ,
		{"word":"avant-scène","word_nosc":"avant-scene","lemma":"avant-scène","pos":"NOM"} ,
		{"word":"avant-scènes","word_nosc":"avant-scenes","lemma":"avant-scène","pos":"NOM"} ,
		{"word":"avant-toit","word_nosc":"avant-toit","lemma":"avant-toit","pos":"NOM"} ,
		{"word":"avant-toits","word_nosc":"avant-toits","lemma":"avant-toit","pos":"NOM"} ,
		{"word":"avant-train","word_nosc":"avant-train","lemma":"avant-train","pos":"NOM"} ,
		{"word":"avant-trains","word_nosc":"avant-trains","lemma":"avant-train","pos":"NOM"} ,
		{"word":"avant-trou","word_nosc":"avant-trou","lemma":"avant-trou","pos":"NOM"} ,
		{"word":"avant-veille","word_nosc":"avant-veille","lemma":"avant-veille","pos":"NOM"} ,
		{"word":"avantage","word_nosc":"avantage","lemma":"avantage","pos":"NOM"} ,
		{"word":"avantages","word_nosc":"avantages","lemma":"avantage","pos":"NOM"} ,
		{"word":"avants","word_nosc":"avants","lemma":"avant","pos":"NOM"} ,
		{"word":"avançons","word_nosc":"avancons","lemma":"avançon","pos":"NOM"} ,
		{"word":"avare","word_nosc":"avare","lemma":"avare","pos":"NOM"} ,
		{"word":"avares","word_nosc":"avares","lemma":"avare","pos":"NOM"} ,
		{"word":"avarice","word_nosc":"avarice","lemma":"avarice","pos":"NOM"} ,
		{"word":"avarices","word_nosc":"avarices","lemma":"avarice","pos":"NOM"} ,
		{"word":"avaricieux","word_nosc":"avaricieux","lemma":"avaricieux","pos":"NOM"} ,
		{"word":"avarie","word_nosc":"avarie","lemma":"avarie","pos":"NOM"} ,
		{"word":"avaries","word_nosc":"avaries","lemma":"avarie","pos":"NOM"} ,
		{"word":"avaro","word_nosc":"avaro","lemma":"avaro","pos":"NOM"} ,
		{"word":"avaros","word_nosc":"avaros","lemma":"avaro","pos":"NOM"} ,
		{"word":"avatar","word_nosc":"avatar","lemma":"avatar","pos":"NOM"} ,
		{"word":"avatars","word_nosc":"avatars","lemma":"avatar","pos":"NOM"} ,
		{"word":"ave","word_nosc":"ave","lemma":"ave","pos":"NOM"} ,
		{"word":"avelines","word_nosc":"avelines","lemma":"aveline","pos":"NOM"} ,
		{"word":"avenant","word_nosc":"avenant","lemma":"avenant","pos":"NOM"} ,
		{"word":"avenants","word_nosc":"avenants","lemma":"avenant","pos":"NOM"} ,
		{"word":"avenir","word_nosc":"avenir","lemma":"avenir","pos":"NOM"} ,
		{"word":"avenirs","word_nosc":"avenirs","lemma":"avenir","pos":"NOM"} ,
		{"word":"avent","word_nosc":"avent","lemma":"avent","pos":"NOM"} ,
		{"word":"aventure","word_nosc":"aventure","lemma":"aventure","pos":"NOM"} ,
		{"word":"aventures","word_nosc":"aventures","lemma":"aventure","pos":"NOM"} ,
		{"word":"aventurier","word_nosc":"aventurier","lemma":"aventurier","pos":"NOM"} ,
		{"word":"aventuriers","word_nosc":"aventuriers","lemma":"aventurier","pos":"NOM"} ,
		{"word":"aventurines","word_nosc":"aventurines","lemma":"aventurine","pos":"NOM"} ,
		{"word":"aventurière","word_nosc":"aventuriere","lemma":"aventurier","pos":"NOM"} ,
		{"word":"aventurières","word_nosc":"aventurieres","lemma":"aventurier","pos":"NOM"} ,
		{"word":"avenue","word_nosc":"avenue","lemma":"avenue","pos":"NOM"} ,
		{"word":"avenues","word_nosc":"avenues","lemma":"avenue","pos":"NOM"} ,
		{"word":"avers","word_nosc":"avers","lemma":"avers","pos":"NOM"} ,
		{"word":"averse","word_nosc":"averse","lemma":"averse","pos":"NOM"} ,
		{"word":"averses","word_nosc":"averses","lemma":"averse","pos":"NOM"} ,
		{"word":"aversion","word_nosc":"aversion","lemma":"aversion","pos":"NOM"} ,
		{"word":"aversions","word_nosc":"aversions","lemma":"aversion","pos":"NOM"} ,
		{"word":"avertissement","word_nosc":"avertissement","lemma":"avertissement","pos":"NOM"} ,
		{"word":"avertissements","word_nosc":"avertissements","lemma":"avertissement","pos":"NOM"} ,
		{"word":"avertisseur","word_nosc":"avertisseur","lemma":"avertisseur","pos":"NOM"} ,
		{"word":"avertisseurs","word_nosc":"avertisseurs","lemma":"avertisseur","pos":"NOM"} ,
		{"word":"aveu","word_nosc":"aveu","lemma":"aveu","pos":"NOM"} ,
		{"word":"aveugle","word_nosc":"aveugle","lemma":"aveugle","pos":"NOM"} ,
		{"word":"aveugle-né","word_nosc":"aveugle-ne","lemma":"aveugle-né","pos":"NOM"} ,
		{"word":"aveugle-née","word_nosc":"aveugle-nee","lemma":"aveugle-né","pos":"NOM"} ,
		{"word":"aveuglement","word_nosc":"aveuglement","lemma":"aveuglement","pos":"NOM"} ,
		{"word":"aveuglements","word_nosc":"aveuglements","lemma":"aveuglement","pos":"NOM"} ,
		{"word":"aveugles","word_nosc":"aveugles","lemma":"aveugle","pos":"NOM"} ,
		{"word":"aveux","word_nosc":"aveux","lemma":"aveu","pos":"NOM"} ,
		{"word":"aviateur","word_nosc":"aviateur","lemma":"aviateur","pos":"NOM"} ,
		{"word":"aviateurs","word_nosc":"aviateurs","lemma":"aviateur","pos":"NOM"} ,
		{"word":"aviation","word_nosc":"aviation","lemma":"aviation","pos":"NOM"} ,
		{"word":"aviations","word_nosc":"aviations","lemma":"aviation","pos":"NOM"} ,
		{"word":"aviatrice","word_nosc":"aviatrice","lemma":"aviateur","pos":"NOM"} ,
		{"word":"aviatrices","word_nosc":"aviatrices","lemma":"aviateur","pos":"NOM"} ,
		{"word":"aviculture","word_nosc":"aviculture","lemma":"aviculture","pos":"NOM"} ,
		{"word":"avidité","word_nosc":"avidite","lemma":"avidité","pos":"NOM"} ,
		{"word":"avidités","word_nosc":"avidites","lemma":"avidité","pos":"NOM"} ,
		{"word":"avignonnais","word_nosc":"avignonnais","lemma":"avignonnais","pos":"NOM"} ,
		{"word":"avilissement","word_nosc":"avilissement","lemma":"avilissement","pos":"NOM"} ,
		{"word":"avion","word_nosc":"avion","lemma":"avion","pos":"NOM"} ,
		{"word":"avion-cargo","word_nosc":"avion-cargo","lemma":"avion-cargo","pos":"NOM"} ,
		{"word":"avion-citerne","word_nosc":"avion-citerne","lemma":"avion-citerne","pos":"NOM"} ,
		{"word":"avion-suicide","word_nosc":"avion-suicide","lemma":"avion-suicide","pos":"NOM"} ,
		{"word":"avion-école","word_nosc":"avion-ecole","lemma":"avion-école","pos":"NOM"} ,
		{"word":"avionique","word_nosc":"avionique","lemma":"avionique","pos":"NOM"} ,
		{"word":"avionnettes","word_nosc":"avionnettes","lemma":"avionnette","pos":"NOM"} ,
		{"word":"avionneurs","word_nosc":"avionneurs","lemma":"avionneur","pos":"NOM"} ,
		{"word":"avions","word_nosc":"avions","lemma":"avion","pos":"NOM"} ,
		{"word":"avions-cargos","word_nosc":"avions-cargos","lemma":"avion-cargo","pos":"NOM"} ,
		{"word":"avions-espions","word_nosc":"avions-espions","lemma":"avions-espions","pos":"NOM"} ,
		{"word":"aviron","word_nosc":"aviron","lemma":"aviron","pos":"NOM"} ,
		{"word":"avirons","word_nosc":"avirons","lemma":"aviron","pos":"NOM"} ,
		{"word":"avis","word_nosc":"avis","lemma":"avis","pos":"NOM"} ,
		{"word":"aviso","word_nosc":"aviso","lemma":"aviso","pos":"NOM"} ,
		{"word":"avisos","word_nosc":"avisos","lemma":"aviso","pos":"NOM"} ,
		{"word":"avitaminose","word_nosc":"avitaminose","lemma":"avitaminose","pos":"NOM"} ,
		{"word":"avocaillon","word_nosc":"avocaillon","lemma":"avocaillon","pos":"NOM"} ,
		{"word":"avocasseries","word_nosc":"avocasseries","lemma":"avocasserie","pos":"NOM"} ,
		{"word":"avocat","word_nosc":"avocat","lemma":"avocat","pos":"NOM"} ,
		{"word":"avocat-conseil","word_nosc":"avocat-conseil","lemma":"avocat-conseil","pos":"NOM"} ,
		{"word":"avocate","word_nosc":"avocate","lemma":"avocat","pos":"NOM"} ,
		{"word":"avocates","word_nosc":"avocates","lemma":"avocat","pos":"NOM"} ,
		{"word":"avocatier","word_nosc":"avocatier","lemma":"avocatier","pos":"NOM"} ,
		{"word":"avocats","word_nosc":"avocats","lemma":"avocat","pos":"NOM"} ,
		{"word":"avocette","word_nosc":"avocette","lemma":"avocette","pos":"NOM"} ,
		{"word":"avoine","word_nosc":"avoine","lemma":"avoine","pos":"NOM"} ,
		{"word":"avoines","word_nosc":"avoines","lemma":"avoine","pos":"NOM"} ,
		{"word":"avoir","word_nosc":"avoir","lemma":"avoir","pos":"NOM"} ,
		{"word":"avoirs","word_nosc":"avoirs","lemma":"avoir","pos":"NOM"} ,
		{"word":"avortement","word_nosc":"avortement","lemma":"avortement","pos":"NOM"} ,
		{"word":"avortements","word_nosc":"avortements","lemma":"avortement","pos":"NOM"} ,
		{"word":"avorteur","word_nosc":"avorteur","lemma":"avorteur","pos":"NOM"} ,
		{"word":"avorteurs","word_nosc":"avorteurs","lemma":"avorteur","pos":"NOM"} ,
		{"word":"avorteuse","word_nosc":"avorteuse","lemma":"avorteur","pos":"NOM"} ,
		{"word":"avorteuses","word_nosc":"avorteuses","lemma":"avorteur","pos":"NOM"} ,
		{"word":"avorton","word_nosc":"avorton","lemma":"avorton","pos":"NOM"} ,
		{"word":"avortons","word_nosc":"avortons","lemma":"avorton","pos":"NOM"} ,
		{"word":"avoué","word_nosc":"avoue","lemma":"avoué","pos":"NOM"} ,
		{"word":"avoués","word_nosc":"avoues","lemma":"avoué","pos":"NOM"} ,
		{"word":"avril","word_nosc":"avril","lemma":"avril","pos":"NOM"} ,
		{"word":"avrillée","word_nosc":"avrillee","lemma":"avrillée","pos":"NOM"} ,
		{"word":"avulsion","word_nosc":"avulsion","lemma":"avulsion","pos":"NOM"} ,
		{"word":"avunculat","word_nosc":"avunculat","lemma":"avunculat","pos":"NOM"} ,
		{"word":"avènement","word_nosc":"avenement","lemma":"avènement","pos":"NOM"} ,
		{"word":"avé","word_nosc":"ave","lemma":"avé","pos":"NOM"} ,
		{"word":"awacs","word_nosc":"awacs","lemma":"awacs","pos":"NOM"} ,
		{"word":"axe","word_nosc":"axe","lemma":"axe","pos":"NOM"} ,
		{"word":"axel","word_nosc":"axel","lemma":"axel","pos":"NOM"} ,
		{"word":"axels","word_nosc":"axels","lemma":"axel","pos":"NOM"} ,
		{"word":"axes","word_nosc":"axes","lemma":"axe","pos":"NOM"} ,
		{"word":"axiomatisation","word_nosc":"axiomatisation","lemma":"axiomatisation","pos":"NOM"} ,
		{"word":"axiome","word_nosc":"axiome","lemma":"axiome","pos":"NOM"} ,
		{"word":"axiomes","word_nosc":"axiomes","lemma":"axiome","pos":"NOM"} ,
		{"word":"axis","word_nosc":"axis","lemma":"axis","pos":"NOM"} ,
		{"word":"axolotl","word_nosc":"axolotl","lemma":"axolotl","pos":"NOM"} ,
		{"word":"axolotls","word_nosc":"axolotls","lemma":"axolotl","pos":"NOM"} ,
		{"word":"axonge","word_nosc":"axonge","lemma":"axonge","pos":"NOM"} ,
		{"word":"aya","word_nosc":"aya","lemma":"aya","pos":"NOM"} ,
		{"word":"ayans","word_nosc":"ayans","lemma":"ayan","pos":"NOM"} ,
		{"word":"ayants droit","word_nosc":"ayants droit","lemma":"ayants droit","pos":"NOM"} ,
		{"word":"ayatollah","word_nosc":"ayatollah","lemma":"ayatollah","pos":"NOM"} ,
		{"word":"ayatollahs","word_nosc":"ayatollahs","lemma":"ayatollah","pos":"NOM"} ,
		{"word":"aye-aye","word_nosc":"aye-aye","lemma":"aye-aye","pos":"NOM"} ,
		{"word":"ayuntamiento","word_nosc":"ayuntamiento","lemma":"ayuntamiento","pos":"NOM"} ,
		{"word":"azalée","word_nosc":"azalee","lemma":"azalée","pos":"NOM"} ,
		{"word":"azalées","word_nosc":"azalees","lemma":"azalée","pos":"NOM"} ,
		{"word":"azerbaïdjanais","word_nosc":"azerbaidjanais","lemma":"azerbaïdjanais","pos":"NOM"} ,
		{"word":"azimut","word_nosc":"azimut","lemma":"azimut","pos":"NOM"} ,
		{"word":"azimuts","word_nosc":"azimuts","lemma":"azimut","pos":"NOM"} ,
		{"word":"azoospermie","word_nosc":"azoospermie","lemma":"azoospermie","pos":"NOM"} ,
		{"word":"azote","word_nosc":"azote","lemma":"azote","pos":"NOM"} ,
		{"word":"azoture","word_nosc":"azoture","lemma":"azoture","pos":"NOM"} ,
		{"word":"aztèque","word_nosc":"azteque","lemma":"aztèque","pos":"NOM"} ,
		{"word":"aztèques","word_nosc":"azteques","lemma":"aztèque","pos":"NOM"} ,
		{"word":"azulejo","word_nosc":"azulejo","lemma":"azulejo","pos":"NOM"} ,
		{"word":"azulejos","word_nosc":"azulejos","lemma":"azulejo","pos":"NOM"} ,
		{"word":"azur","word_nosc":"azur","lemma":"azur","pos":"NOM"} ,
		{"word":"azurs","word_nosc":"azurs","lemma":"azur","pos":"NOM"} ,
		{"word":"aède","word_nosc":"aede","lemma":"aède","pos":"NOM"} ,
		{"word":"aèdes","word_nosc":"aedes","lemma":"aède","pos":"NOM"} ,
		{"word":"aérage","word_nosc":"aerage","lemma":"aérage","pos":"NOM"} ,
		{"word":"aérateur","word_nosc":"aerateur","lemma":"aérateur","pos":"NOM"} ,
		{"word":"aérateurs","word_nosc":"aerateurs","lemma":"aérateur","pos":"NOM"} ,
		{"word":"aération","word_nosc":"aeration","lemma":"aération","pos":"NOM"} ,
		{"word":"aérations","word_nosc":"aerations","lemma":"aération","pos":"NOM"} ,
		{"word":"aérien","word_nosc":"aerien","lemma":"aérien","pos":"NOM"} ,
		{"word":"aériens","word_nosc":"aeriens","lemma":"aérien","pos":"NOM"} ,
		{"word":"aéro","word_nosc":"aero","lemma":"aéro","pos":"NOM"} ,
		{"word":"aéro-club","word_nosc":"aero-club","lemma":"aéro-club","pos":"NOM"} ,
		{"word":"aérobic","word_nosc":"aerobic","lemma":"aérobic","pos":"NOM"} ,
		{"word":"aérobics","word_nosc":"aerobics","lemma":"aérobic","pos":"NOM"} ,
		{"word":"aérodrome","word_nosc":"aerodrome","lemma":"aérodrome","pos":"NOM"} ,
		{"word":"aérodromes","word_nosc":"aerodromes","lemma":"aérodrome","pos":"NOM"} ,
		{"word":"aérodynamique","word_nosc":"aerodynamique","lemma":"aérodynamique","pos":"NOM"} ,
		{"word":"aérodynamisme","word_nosc":"aerodynamisme","lemma":"aérodynamisme","pos":"NOM"} ,
		{"word":"aérofrein","word_nosc":"aerofrein","lemma":"aérofrein","pos":"NOM"} ,
		{"word":"aérofreins","word_nosc":"aerofreins","lemma":"aérofrein","pos":"NOM"} ,
		{"word":"aérogare","word_nosc":"aerogare","lemma":"aérogare","pos":"NOM"} ,
		{"word":"aérogares","word_nosc":"aerogares","lemma":"aérogare","pos":"NOM"} ,
		{"word":"aéroglisseur","word_nosc":"aeroglisseur","lemma":"aéroglisseur","pos":"NOM"} ,
		{"word":"aéroglisseurs","word_nosc":"aeroglisseurs","lemma":"aéroglisseur","pos":"NOM"} ,
		{"word":"aérographe","word_nosc":"aerographe","lemma":"aérographe","pos":"NOM"} ,
		{"word":"aérolithe","word_nosc":"aerolithe","lemma":"aérolithe","pos":"NOM"} ,
		{"word":"aérolithes","word_nosc":"aerolithes","lemma":"aérolithe","pos":"NOM"} ,
		{"word":"aérologie","word_nosc":"aerologie","lemma":"aérologie","pos":"NOM"} ,
		{"word":"aéromodélisme","word_nosc":"aeromodelisme","lemma":"aéromodélisme","pos":"NOM"} ,
		{"word":"aéronaute","word_nosc":"aeronaute","lemma":"aéronaute","pos":"NOM"} ,
		{"word":"aéronautes","word_nosc":"aeronautes","lemma":"aéronaute","pos":"NOM"} ,
		{"word":"aéronautique","word_nosc":"aeronautique","lemma":"aéronautique","pos":"NOM"} ,
		{"word":"aéronautiques","word_nosc":"aeronautiques","lemma":"aéronautique","pos":"NOM"} ,
		{"word":"aéronavale","word_nosc":"aeronavale","lemma":"aéronavale","pos":"NOM"} ,
		{"word":"aéronef","word_nosc":"aeronef","lemma":"aéronef","pos":"NOM"} ,
		{"word":"aéronefs","word_nosc":"aeronefs","lemma":"aéronef","pos":"NOM"} ,
		{"word":"aéropathie","word_nosc":"aeropathie","lemma":"aéropathie","pos":"NOM"} ,
		{"word":"aérophagie","word_nosc":"aerophagie","lemma":"aérophagie","pos":"NOM"} ,
		{"word":"aéroplane","word_nosc":"aeroplane","lemma":"aéroplane","pos":"NOM"} ,
		{"word":"aéroplanes","word_nosc":"aeroplanes","lemma":"aéroplane","pos":"NOM"} ,
		{"word":"aéroport","word_nosc":"aeroport","lemma":"aéroport","pos":"NOM"} ,
		{"word":"aéroports","word_nosc":"aeroports","lemma":"aéroport","pos":"NOM"} ,
		{"word":"aéropostale","word_nosc":"aeropostale","lemma":"aéropostale","pos":"NOM"} ,
		{"word":"aérosol","word_nosc":"aerosol","lemma":"aérosol","pos":"NOM"} ,
		{"word":"aérosols","word_nosc":"aerosols","lemma":"aérosol","pos":"NOM"} ,
		{"word":"aérospatiale","word_nosc":"aerospatiale","lemma":"aérospatiale","pos":"NOM"} ,
		{"word":"aérostat","word_nosc":"aerostat","lemma":"aérostat","pos":"NOM"} ,
		{"word":"aérostier","word_nosc":"aerostier","lemma":"aérostier","pos":"NOM"} ,
		{"word":"aérostiers","word_nosc":"aerostiers","lemma":"aérostier","pos":"NOM"} ,
		{"word":"aérotrains","word_nosc":"aerotrains","lemma":"aérotrain","pos":"NOM"} ,
		{"word":"aînesse","word_nosc":"ainesse","lemma":"aînesse","pos":"NOM"} ,
		{"word":"aîné","word_nosc":"aine","lemma":"aîné","pos":"NOM"} ,
		{"word":"aînée","word_nosc":"ainee","lemma":"aîné","pos":"NOM"} ,
		{"word":"aînées","word_nosc":"ainees","lemma":"aîné","pos":"NOM"} ,
		{"word":"aînés","word_nosc":"aines","lemma":"aîné","pos":"NOM"} ,
		{"word":"aître","word_nosc":"aitre","lemma":"aître","pos":"NOM"} ,
		{"word":"aîtres","word_nosc":"aitres","lemma":"aître","pos":"NOM"} ,
		{"word":"aï","word_nosc":"ai","lemma":"aï","pos":"NOM"} ,
		{"word":"aïd","word_nosc":"aid","lemma":"aïd","pos":"NOM"} ,
		{"word":"aïeul","word_nosc":"aieul","lemma":"aïeul","pos":"NOM"} ,
		{"word":"aïeule","word_nosc":"aieule","lemma":"aïeul","pos":"NOM"} ,
		{"word":"aïeules","word_nosc":"aieules","lemma":"aïeul","pos":"NOM"} ,
		{"word":"aïeuls","word_nosc":"aieuls","lemma":"aïeul","pos":"NOM"} ,
		{"word":"aïeux","word_nosc":"aieux","lemma":"aïeul","pos":"NOM"} ,
		{"word":"aïkido","word_nosc":"aikido","lemma":"aïkido","pos":"NOM"} ,
		{"word":"aïoli","word_nosc":"aioli","lemma":"aïoli","pos":"NOM"} ,
		{"word":"b","word_nosc":"b","lemma":"b","pos":"NOM"} ,
		{"word":"baba","word_nosc":"baba","lemma":"baba","pos":"NOM"} ,
		{"word":"baballe","word_nosc":"baballe","lemma":"baballe","pos":"NOM"} ,
		{"word":"babas","word_nosc":"babas","lemma":"baba","pos":"NOM"} ,
		{"word":"babasse","word_nosc":"babasse","lemma":"babasse","pos":"NOM"} ,
		{"word":"babel","word_nosc":"babel","lemma":"babel","pos":"NOM"} ,
		{"word":"babeurre","word_nosc":"babeurre","lemma":"babeurre","pos":"NOM"} ,
		{"word":"babi","word_nosc":"babi","lemma":"babi","pos":"NOM"} ,
		{"word":"babil","word_nosc":"babil","lemma":"babil","pos":"NOM"} ,
		{"word":"babillage","word_nosc":"babillage","lemma":"babillage","pos":"NOM"} ,
		{"word":"babillages","word_nosc":"babillages","lemma":"babillage","pos":"NOM"} ,
		{"word":"babillard","word_nosc":"babillard","lemma":"babillard","pos":"NOM"} ,
		{"word":"babils","word_nosc":"babils","lemma":"babil","pos":"NOM"} ,
		{"word":"babine","word_nosc":"babine","lemma":"babine","pos":"NOM"} ,
		{"word":"babines","word_nosc":"babines","lemma":"babine","pos":"NOM"} ,
		{"word":"babiole","word_nosc":"babiole","lemma":"babiole","pos":"NOM"} ,
		{"word":"babioles","word_nosc":"babioles","lemma":"babiole","pos":"NOM"} ,
		{"word":"babiroussa","word_nosc":"babiroussa","lemma":"babiroussa","pos":"NOM"} ,
		{"word":"babouche","word_nosc":"babouche","lemma":"babouche","pos":"NOM"} ,
		{"word":"babouches","word_nosc":"babouches","lemma":"babouche","pos":"NOM"} ,
		{"word":"babouchka","word_nosc":"babouchka","lemma":"babouchka","pos":"NOM"} ,
		{"word":"babouchkas","word_nosc":"babouchkas","lemma":"babouchka","pos":"NOM"} ,
		{"word":"babouin","word_nosc":"babouin","lemma":"babouin","pos":"NOM"} ,
		{"word":"babouine","word_nosc":"babouine","lemma":"babouin","pos":"NOM"} ,
		{"word":"babouines","word_nosc":"babouines","lemma":"babouin","pos":"NOM"} ,
		{"word":"babouins","word_nosc":"babouins","lemma":"babouin","pos":"NOM"} ,
		{"word":"babouvistes","word_nosc":"babouvistes","lemma":"babouviste","pos":"NOM"} ,
		{"word":"baby","word_nosc":"baby","lemma":"baby","pos":"NOM"} ,
		{"word":"baby-boom","word_nosc":"baby-boom","lemma":"baby-boom","pos":"NOM"} ,
		{"word":"baby-foot","word_nosc":"baby-foot","lemma":"baby-foot","pos":"NOM"} ,
		{"word":"baby-sitter","word_nosc":"baby-sitter","lemma":"baby-sitter","pos":"NOM"} ,
		{"word":"baby-sitters","word_nosc":"baby-sitters","lemma":"baby-sitter","pos":"NOM"} ,
		{"word":"baby-sitting","word_nosc":"baby-sitting","lemma":"baby-sitting","pos":"NOM"} ,
		{"word":"babylonien","word_nosc":"babylonien","lemma":"babylonien","pos":"NOM"} ,
		{"word":"babylonienne","word_nosc":"babylonienne","lemma":"babylonien","pos":"NOM"} ,
		{"word":"babyloniens","word_nosc":"babyloniens","lemma":"babylonien","pos":"NOM"} ,
		{"word":"babys","word_nosc":"babys","lemma":"baby","pos":"NOM"} ,
		{"word":"bac","word_nosc":"bac","lemma":"bac","pos":"NOM"} ,
		{"word":"baccalauréat","word_nosc":"baccalaureat","lemma":"baccalauréat","pos":"NOM"} ,
		{"word":"baccara","word_nosc":"baccara","lemma":"baccara","pos":"NOM"} ,
		{"word":"baccarat","word_nosc":"baccarat","lemma":"baccarat","pos":"NOM"} ,
		{"word":"bacchanal","word_nosc":"bacchanal","lemma":"bacchanal","pos":"NOM"} ,
		{"word":"bacchanale","word_nosc":"bacchanale","lemma":"bacchanale","pos":"NOM"} ,
		{"word":"bacchanales","word_nosc":"bacchanales","lemma":"bacchanale","pos":"NOM"} ,
		{"word":"bacchante","word_nosc":"bacchante","lemma":"bacchante","pos":"NOM"} ,
		{"word":"bacchantes","word_nosc":"bacchantes","lemma":"bacchante","pos":"NOM"} ,
		{"word":"bachaghas","word_nosc":"bachaghas","lemma":"bachagha","pos":"NOM"} ,
		{"word":"bachelier","word_nosc":"bachelier","lemma":"bachelier","pos":"NOM"} ,
		{"word":"bacheliers","word_nosc":"bacheliers","lemma":"bachelier","pos":"NOM"} ,
		{"word":"bachelière","word_nosc":"bacheliere","lemma":"bachelier","pos":"NOM"} ,
		{"word":"bachi-bouzouk","word_nosc":"bachi-bouzouk","lemma":"bachi-bouzouk","pos":"NOM"} ,
		{"word":"bachot","word_nosc":"bachot","lemma":"bachot","pos":"NOM"} ,
		{"word":"bachotage","word_nosc":"bachotage","lemma":"bachotage","pos":"NOM"} ,
		{"word":"bachots","word_nosc":"bachots","lemma":"bachot","pos":"NOM"} ,
		{"word":"bacille","word_nosc":"bacille","lemma":"bacille","pos":"NOM"} ,
		{"word":"bacilles","word_nosc":"bacilles","lemma":"bacille","pos":"NOM"} ,
		{"word":"back","word_nosc":"back","lemma":"back","pos":"NOM"} ,
		{"word":"back up","word_nosc":"back up","lemma":"back up","pos":"NOM"} ,
		{"word":"backgammon","word_nosc":"backgammon","lemma":"backgammon","pos":"NOM"} ,
		{"word":"background","word_nosc":"background","lemma":"background","pos":"NOM"} ,
		{"word":"bacon","word_nosc":"bacon","lemma":"bacon","pos":"NOM"} ,
		{"word":"bacs","word_nosc":"bacs","lemma":"bac","pos":"NOM"} ,
		{"word":"bactérie","word_nosc":"bacterie","lemma":"bactérie","pos":"NOM"} ,
		{"word":"bactéries","word_nosc":"bacteries","lemma":"bactérie","pos":"NOM"} ,
		{"word":"bactériologie","word_nosc":"bacteriologie","lemma":"bactériologie","pos":"NOM"} ,
		{"word":"bactériologiste","word_nosc":"bacteriologiste","lemma":"bactériologiste","pos":"NOM"} ,
		{"word":"bactériophage","word_nosc":"bacteriophage","lemma":"bactériophage","pos":"NOM"} ,
		{"word":"bactériémie","word_nosc":"bacteriemie","lemma":"bactériémie","pos":"NOM"} ,
		{"word":"badamier","word_nosc":"badamier","lemma":"badamier","pos":"NOM"} ,
		{"word":"badamiers","word_nosc":"badamiers","lemma":"badamier","pos":"NOM"} ,
		{"word":"badaud","word_nosc":"badaud","lemma":"badaud","pos":"NOM"} ,
		{"word":"badauderie","word_nosc":"badauderie","lemma":"badauderie","pos":"NOM"} ,
		{"word":"badauderies","word_nosc":"badauderies","lemma":"badauderie","pos":"NOM"} ,
		{"word":"badauds","word_nosc":"badauds","lemma":"badaud","pos":"NOM"} ,
		{"word":"baderne","word_nosc":"baderne","lemma":"baderne","pos":"NOM"} ,
		{"word":"badernes","word_nosc":"badernes","lemma":"baderne","pos":"NOM"} ,
		{"word":"badge","word_nosc":"badge","lemma":"badge","pos":"NOM"} ,
		{"word":"badges","word_nosc":"badges","lemma":"badge","pos":"NOM"} ,
		{"word":"badigeon","word_nosc":"badigeon","lemma":"badigeon","pos":"NOM"} ,
		{"word":"badigeonnage","word_nosc":"badigeonnage","lemma":"badigeonnage","pos":"NOM"} ,
		{"word":"badigeonnages","word_nosc":"badigeonnages","lemma":"badigeonnage","pos":"NOM"} ,
		{"word":"badigeonneur","word_nosc":"badigeonneur","lemma":"badigeonneur","pos":"NOM"} ,
		{"word":"badigeons","word_nosc":"badigeons","lemma":"badigeon","pos":"NOM"} ,
		{"word":"badigoinces","word_nosc":"badigoinces","lemma":"badigoinces","pos":"NOM"} ,
		{"word":"badinage","word_nosc":"badinage","lemma":"badinage","pos":"NOM"} ,
		{"word":"badinages","word_nosc":"badinages","lemma":"badinage","pos":"NOM"} ,
		{"word":"badine","word_nosc":"badine","lemma":"badine","pos":"NOM"} ,
		{"word":"badines","word_nosc":"badines","lemma":"badine","pos":"NOM"} ,
		{"word":"badminton","word_nosc":"badminton","lemma":"badminton","pos":"NOM"} ,
		{"word":"badois","word_nosc":"badois","lemma":"badois","pos":"NOM"} ,
		{"word":"baffe","word_nosc":"baffe","lemma":"baffe","pos":"NOM"} ,
		{"word":"baffes","word_nosc":"baffes","lemma":"baffe","pos":"NOM"} ,
		{"word":"baffle","word_nosc":"baffle","lemma":"baffle","pos":"NOM"} ,
		{"word":"baffles","word_nosc":"baffles","lemma":"baffle","pos":"NOM"} ,
		{"word":"bafouillage","word_nosc":"bafouillage","lemma":"bafouillage","pos":"NOM"} ,
		{"word":"bafouillages","word_nosc":"bafouillages","lemma":"bafouillage","pos":"NOM"} ,
		{"word":"bafouille","word_nosc":"bafouille","lemma":"bafouille","pos":"NOM"} ,
		{"word":"bafouilles","word_nosc":"bafouilles","lemma":"bafouille","pos":"NOM"} ,
		{"word":"bafouilleur","word_nosc":"bafouilleur","lemma":"bafouilleur","pos":"NOM"} ,
		{"word":"bafouillis","word_nosc":"bafouillis","lemma":"bafouillis","pos":"NOM"} ,
		{"word":"bagage","word_nosc":"bagage","lemma":"bagage","pos":"NOM"} ,
		{"word":"bagagerie","word_nosc":"bagagerie","lemma":"bagagerie","pos":"NOM"} ,
		{"word":"bagages","word_nosc":"bagages","lemma":"bagage","pos":"NOM"} ,
		{"word":"bagagiste","word_nosc":"bagagiste","lemma":"bagagiste","pos":"NOM"} ,
		{"word":"bagagistes","word_nosc":"bagagistes","lemma":"bagagiste","pos":"NOM"} ,
		{"word":"bagarre","word_nosc":"bagarre","lemma":"bagarre","pos":"NOM"} ,
		{"word":"bagarres","word_nosc":"bagarres","lemma":"bagarre","pos":"NOM"} ,
		{"word":"bagarreur","word_nosc":"bagarreur","lemma":"bagarreur","pos":"NOM"} ,
		{"word":"bagarreurs","word_nosc":"bagarreurs","lemma":"bagarreur","pos":"NOM"} ,
		{"word":"bagarreuse","word_nosc":"bagarreuse","lemma":"bagarreur","pos":"NOM"} ,
		{"word":"bagasse","word_nosc":"bagasse","lemma":"bagasse","pos":"NOM"} ,
		{"word":"bagatelle","word_nosc":"bagatelle","lemma":"bagatelle","pos":"NOM"} ,
		{"word":"bagatelles","word_nosc":"bagatelles","lemma":"bagatelle","pos":"NOM"} ,
		{"word":"bagnard","word_nosc":"bagnard","lemma":"bagnard","pos":"NOM"} ,
		{"word":"bagnards","word_nosc":"bagnards","lemma":"bagnard","pos":"NOM"} ,
		{"word":"bagne","word_nosc":"bagne","lemma":"bagne","pos":"NOM"} ,
		{"word":"bagnes","word_nosc":"bagnes","lemma":"bagne","pos":"NOM"} ,
		{"word":"bagnole","word_nosc":"bagnole","lemma":"bagnole","pos":"NOM"} ,
		{"word":"bagnoles","word_nosc":"bagnoles","lemma":"bagnole","pos":"NOM"} ,
		{"word":"bagou","word_nosc":"bagou","lemma":"bagou","pos":"NOM"} ,
		{"word":"bagouse","word_nosc":"bagouse","lemma":"bagouse","pos":"NOM"} ,
		{"word":"bagout","word_nosc":"bagout","lemma":"bagout","pos":"NOM"} ,
		{"word":"bagouts","word_nosc":"bagouts","lemma":"bagout","pos":"NOM"} ,
		{"word":"bagouze","word_nosc":"bagouze","lemma":"bagouze","pos":"NOM"} ,
		{"word":"bagouzes","word_nosc":"bagouzes","lemma":"bagouze","pos":"NOM"} ,
		{"word":"baguage","word_nosc":"baguage","lemma":"baguage","pos":"NOM"} ,
		{"word":"bague","word_nosc":"bague","lemma":"bague","pos":"NOM"} ,
		{"word":"baguenaude","word_nosc":"baguenaude","lemma":"baguenaude","pos":"NOM"} ,
		{"word":"bagues","word_nosc":"bagues","lemma":"bague","pos":"NOM"} ,
		{"word":"baguette","word_nosc":"baguette","lemma":"baguette","pos":"NOM"} ,
		{"word":"baguettes","word_nosc":"baguettes","lemma":"baguette","pos":"NOM"} ,
		{"word":"baht","word_nosc":"baht","lemma":"baht","pos":"NOM"} ,
		{"word":"bahts","word_nosc":"bahts","lemma":"baht","pos":"NOM"} ,
		{"word":"bahut","word_nosc":"bahut","lemma":"bahut","pos":"NOM"} ,
		{"word":"bahuts","word_nosc":"bahuts","lemma":"bahut","pos":"NOM"} ,
		{"word":"baie","word_nosc":"baie","lemma":"baie","pos":"NOM"} ,
		{"word":"baies","word_nosc":"baies","lemma":"baie","pos":"NOM"} ,
		{"word":"baignade","word_nosc":"baignade","lemma":"baignade","pos":"NOM"} ,
		{"word":"baignades","word_nosc":"baignades","lemma":"baignade","pos":"NOM"} ,
		{"word":"baigneur","word_nosc":"baigneur","lemma":"baigneur","pos":"NOM"} ,
		{"word":"baigneurs","word_nosc":"baigneurs","lemma":"baigneur","pos":"NOM"} ,
		{"word":"baigneuse","word_nosc":"baigneuse","lemma":"baigneur","pos":"NOM"} ,
		{"word":"baigneuses","word_nosc":"baigneuses","lemma":"baigneur","pos":"NOM"} ,
		{"word":"baignoire","word_nosc":"baignoire","lemma":"baignoire","pos":"NOM"} ,
		{"word":"baignoires","word_nosc":"baignoires","lemma":"baignoire","pos":"NOM"} ,
		{"word":"bail","word_nosc":"bail","lemma":"bail","pos":"NOM"} ,
		{"word":"baile","word_nosc":"baile","lemma":"baile","pos":"NOM"} ,
		{"word":"bailes","word_nosc":"bailes","lemma":"baile","pos":"NOM"} ,
		{"word":"baille","word_nosc":"baille","lemma":"baille","pos":"NOM"} ,
		{"word":"bailles","word_nosc":"bailles","lemma":"baille","pos":"NOM"} ,
		{"word":"bailleur","word_nosc":"bailleur","lemma":"bailleur","pos":"NOM"} ,
		{"word":"bailleurs","word_nosc":"bailleurs","lemma":"bailleur","pos":"NOM"} ,
		{"word":"bailli","word_nosc":"bailli","lemma":"bailli","pos":"NOM"} ,
		{"word":"bailliage","word_nosc":"bailliage","lemma":"bailliage","pos":"NOM"} ,
		{"word":"baillis","word_nosc":"baillis","lemma":"bailli","pos":"NOM"} ,
		{"word":"baillive","word_nosc":"baillive","lemma":"baillive","pos":"NOM"} ,
		{"word":"bain","word_nosc":"bain","lemma":"bain","pos":"NOM"} ,
		{"word":"bain-marie","word_nosc":"bain-marie","lemma":"bain-marie","pos":"NOM"} ,
		{"word":"bains","word_nosc":"bains","lemma":"bain","pos":"NOM"} ,
		{"word":"bains-marie","word_nosc":"bains-marie","lemma":"bain-marie","pos":"NOM"} ,
		{"word":"baisade","word_nosc":"baisade","lemma":"baisade","pos":"NOM"} ,
		{"word":"baise","word_nosc":"baise","lemma":"baise","pos":"NOM"} ,
		{"word":"baise-en-ville","word_nosc":"baise-en-ville","lemma":"baise-en-ville","pos":"NOM"} ,
		{"word":"baisemain","word_nosc":"baisemain","lemma":"baisemain","pos":"NOM"} ,
		{"word":"baisemains","word_nosc":"baisemains","lemma":"baisemain","pos":"NOM"} ,
		{"word":"baisement","word_nosc":"baisement","lemma":"baisement","pos":"NOM"} ,
		{"word":"baiser","word_nosc":"baiser","lemma":"baiser","pos":"NOM"} ,
		{"word":"baisers","word_nosc":"baisers","lemma":"baiser","pos":"NOM"} ,
		{"word":"baises","word_nosc":"baises","lemma":"baise","pos":"NOM"} ,
		{"word":"baiseur","word_nosc":"baiseur","lemma":"baiseur","pos":"NOM"} ,
		{"word":"baiseurs","word_nosc":"baiseurs","lemma":"baiseur","pos":"NOM"} ,
		{"word":"baiseuse","word_nosc":"baiseuse","lemma":"baiseur","pos":"NOM"} ,
		{"word":"baisodrome","word_nosc":"baisodrome","lemma":"baisodrome","pos":"NOM"} ,
		{"word":"baisse","word_nosc":"baisse","lemma":"baisse","pos":"NOM"} ,
		{"word":"baissement","word_nosc":"baissement","lemma":"baissement","pos":"NOM"} ,
		{"word":"baisser","word_nosc":"baisser","lemma":"baisser","pos":"NOM"} ,
		{"word":"baisses","word_nosc":"baisses","lemma":"baisse","pos":"NOM"} ,
		{"word":"bajoue","word_nosc":"bajoue","lemma":"bajoue","pos":"NOM"} ,
		{"word":"bajoues","word_nosc":"bajoues","lemma":"bajoue","pos":"NOM"} ,
		{"word":"bajoyers","word_nosc":"bajoyers","lemma":"bajoyer","pos":"NOM"} ,
		{"word":"bakchich","word_nosc":"bakchich","lemma":"bakchich","pos":"NOM"} ,
		{"word":"bakchichs","word_nosc":"bakchichs","lemma":"bakchich","pos":"NOM"} ,
		{"word":"baklava","word_nosc":"baklava","lemma":"baklava","pos":"NOM"} ,
		{"word":"baklavas","word_nosc":"baklavas","lemma":"baklava","pos":"NOM"} ,
		{"word":"bakélite","word_nosc":"bakelite","lemma":"bakélite","pos":"NOM"} ,
		{"word":"bal","word_nosc":"bal","lemma":"bal","pos":"NOM"} ,
		{"word":"balade","word_nosc":"balade","lemma":"balade","pos":"NOM"} ,
		{"word":"balades","word_nosc":"balades","lemma":"balade","pos":"NOM"} ,
		{"word":"baladeur","word_nosc":"baladeur","lemma":"baladeur","pos":"NOM"} ,
		{"word":"baladeurs","word_nosc":"baladeurs","lemma":"baladeur","pos":"NOM"} ,
		{"word":"baladeuse","word_nosc":"baladeuse","lemma":"baladeur","pos":"NOM"} ,
		{"word":"baladin","word_nosc":"baladin","lemma":"baladin","pos":"NOM"} ,
		{"word":"baladins","word_nosc":"baladins","lemma":"baladin","pos":"NOM"} ,
		{"word":"balafon","word_nosc":"balafon","lemma":"balafon","pos":"NOM"} ,
		{"word":"balafons","word_nosc":"balafons","lemma":"balafon","pos":"NOM"} ,
		{"word":"balafre","word_nosc":"balafre","lemma":"balafre","pos":"NOM"} ,
		{"word":"balafres","word_nosc":"balafres","lemma":"balafre","pos":"NOM"} ,
		{"word":"balai","word_nosc":"balai","lemma":"balai","pos":"NOM"} ,
		{"word":"balai-brosse","word_nosc":"balai-brosse","lemma":"balai-brosse","pos":"NOM"} ,
		{"word":"balais","word_nosc":"balais","lemma":"balai","pos":"NOM"} ,
		{"word":"balais-brosses","word_nosc":"balais-brosses","lemma":"balai-brosse","pos":"NOM"} ,
		{"word":"balaise","word_nosc":"balaise","lemma":"balaise","pos":"NOM"} ,
		{"word":"balaises","word_nosc":"balaises","lemma":"balaise","pos":"NOM"} ,
		{"word":"balalaïka","word_nosc":"balalaika","lemma":"balalaïka","pos":"NOM"} ,
		{"word":"balalaïkas","word_nosc":"balalaikas","lemma":"balalaïka","pos":"NOM"} ,
		{"word":"balan","word_nosc":"balan","lemma":"balan","pos":"NOM"} ,
		{"word":"balance","word_nosc":"balance","lemma":"balance","pos":"NOM"} ,
		{"word":"balancelle","word_nosc":"balancelle","lemma":"balancelle","pos":"NOM"} ,
		{"word":"balancelles","word_nosc":"balancelles","lemma":"balancelle","pos":"NOM"} ,
		{"word":"balancement","word_nosc":"balancement","lemma":"balancement","pos":"NOM"} ,
		{"word":"balancements","word_nosc":"balancements","lemma":"balancement","pos":"NOM"} ,
		{"word":"balances","word_nosc":"balances","lemma":"balance","pos":"NOM"} ,
		{"word":"balancier","word_nosc":"balancier","lemma":"balancier","pos":"NOM"} ,
		{"word":"balanciers","word_nosc":"balanciers","lemma":"balancier","pos":"NOM"} ,
		{"word":"balancines","word_nosc":"balancines","lemma":"balancine","pos":"NOM"} ,
		{"word":"balandras","word_nosc":"balandras","lemma":"balandras","pos":"NOM"} ,
		{"word":"balanes","word_nosc":"balanes","lemma":"balane","pos":"NOM"} ,
		{"word":"balançage","word_nosc":"balancage","lemma":"balançage","pos":"NOM"} ,
		{"word":"balançages","word_nosc":"balancages","lemma":"balançage","pos":"NOM"} ,
		{"word":"balançoire","word_nosc":"balancoire","lemma":"balançoire","pos":"NOM"} ,
		{"word":"balançoires","word_nosc":"balancoires","lemma":"balançoire","pos":"NOM"} ,
		{"word":"balatum","word_nosc":"balatum","lemma":"balatum","pos":"NOM"} ,
		{"word":"balayage","word_nosc":"balayage","lemma":"balayage","pos":"NOM"} ,
		{"word":"balayages","word_nosc":"balayages","lemma":"balayage","pos":"NOM"} ,
		{"word":"balayette","word_nosc":"balayette","lemma":"balayette","pos":"NOM"} ,
		{"word":"balayettes","word_nosc":"balayettes","lemma":"balayette","pos":"NOM"} ,
		{"word":"balayeur","word_nosc":"balayeur","lemma":"balayeur","pos":"NOM"} ,
		{"word":"balayeurs","word_nosc":"balayeurs","lemma":"balayeur","pos":"NOM"} ,
		{"word":"balayeuse","word_nosc":"balayeuse","lemma":"balayeur","pos":"NOM"} ,
		{"word":"balayeuses","word_nosc":"balayeuses","lemma":"balayeur","pos":"NOM"} ,
		{"word":"balayure","word_nosc":"balayure","lemma":"balayure","pos":"NOM"} ,
		{"word":"balayures","word_nosc":"balayures","lemma":"balayure","pos":"NOM"} ,
		{"word":"balboa","word_nosc":"balboa","lemma":"balboa","pos":"NOM"} ,
		{"word":"balbutiement","word_nosc":"balbutiement","lemma":"balbutiement","pos":"NOM"} ,
		{"word":"balbutiements","word_nosc":"balbutiements","lemma":"balbutiement","pos":"NOM"} ,
		{"word":"balbuzard","word_nosc":"balbuzard","lemma":"balbuzard","pos":"NOM"} ,
		{"word":"balcon","word_nosc":"balcon","lemma":"balcon","pos":"NOM"} ,
		{"word":"balconnet","word_nosc":"balconnet","lemma":"balconnet","pos":"NOM"} ,
		{"word":"balconnets","word_nosc":"balconnets","lemma":"balconnet","pos":"NOM"} ,
		{"word":"balcons","word_nosc":"balcons","lemma":"balcon","pos":"NOM"} ,
		{"word":"baldaquin","word_nosc":"baldaquin","lemma":"baldaquin","pos":"NOM"} ,
		{"word":"baldaquins","word_nosc":"baldaquins","lemma":"baldaquin","pos":"NOM"} ,
		{"word":"bale","word_nosc":"bale","lemma":"bale","pos":"NOM"} ,
		{"word":"baleine","word_nosc":"baleine","lemma":"baleine","pos":"NOM"} ,
		{"word":"baleineau","word_nosc":"baleineau","lemma":"baleineau","pos":"NOM"} ,
		{"word":"baleines","word_nosc":"baleines","lemma":"baleine","pos":"NOM"} ,
		{"word":"baleinier","word_nosc":"baleinier","lemma":"baleinier","pos":"NOM"} ,
		{"word":"baleiniers","word_nosc":"baleiniers","lemma":"baleinier","pos":"NOM"} ,
		{"word":"baleinière","word_nosc":"baleiniere","lemma":"baleinier","pos":"NOM"} ,
		{"word":"baleinières","word_nosc":"baleinieres","lemma":"baleinier","pos":"NOM"} ,
		{"word":"bales","word_nosc":"bales","lemma":"bale","pos":"NOM"} ,
		{"word":"balisage","word_nosc":"balisage","lemma":"balisage","pos":"NOM"} ,
		{"word":"balise","word_nosc":"balise","lemma":"balise","pos":"NOM"} ,
		{"word":"balises","word_nosc":"balises","lemma":"balise","pos":"NOM"} ,
		{"word":"baliste","word_nosc":"baliste","lemma":"baliste","pos":"NOM"} ,
		{"word":"balistes","word_nosc":"balistes","lemma":"baliste","pos":"NOM"} ,
		{"word":"balistique","word_nosc":"balistique","lemma":"balistique","pos":"NOM"} ,
		{"word":"baliveau","word_nosc":"baliveau","lemma":"baliveau","pos":"NOM"} ,
		{"word":"baliveaux","word_nosc":"baliveaux","lemma":"baliveau","pos":"NOM"} ,
		{"word":"baliverne","word_nosc":"baliverne","lemma":"baliverne","pos":"NOM"} ,
		{"word":"balivernes","word_nosc":"balivernes","lemma":"baliverne","pos":"NOM"} ,
		{"word":"ball-trap","word_nosc":"ball-trap","lemma":"ball-trap","pos":"NOM"} ,
		{"word":"ballade","word_nosc":"ballade","lemma":"ballade","pos":"NOM"} ,
		{"word":"ballades","word_nosc":"ballades","lemma":"ballade","pos":"NOM"} ,
		{"word":"ballant","word_nosc":"ballant","lemma":"ballant","pos":"NOM"} ,
		{"word":"ballants","word_nosc":"ballants","lemma":"ballant","pos":"NOM"} ,
		{"word":"ballast","word_nosc":"ballast","lemma":"ballast","pos":"NOM"} ,
		{"word":"ballasts","word_nosc":"ballasts","lemma":"ballast","pos":"NOM"} ,
		{"word":"balle","word_nosc":"balle","lemma":"balle","pos":"NOM"} ,
		{"word":"ballerine","word_nosc":"ballerine","lemma":"ballerine","pos":"NOM"} ,
		{"word":"ballerines","word_nosc":"ballerines","lemma":"ballerine","pos":"NOM"} ,
		{"word":"balles","word_nosc":"balles","lemma":"balle","pos":"NOM"} ,
		{"word":"ballet","word_nosc":"ballet","lemma":"ballet","pos":"NOM"} ,
		{"word":"ballets","word_nosc":"ballets","lemma":"ballet","pos":"NOM"} ,
		{"word":"balloches","word_nosc":"balloches","lemma":"balloche","pos":"NOM"} ,
		{"word":"ballon","word_nosc":"ballon","lemma":"ballon","pos":"NOM"} ,
		{"word":"ballon-sonde","word_nosc":"ballon-sonde","lemma":"ballon-sonde","pos":"NOM"} ,
		{"word":"ballonnement","word_nosc":"ballonnement","lemma":"ballonnement","pos":"NOM"} ,
		{"word":"ballonnements","word_nosc":"ballonnements","lemma":"ballonnement","pos":"NOM"} ,
		{"word":"ballonnets","word_nosc":"ballonnets","lemma":"ballonnet","pos":"NOM"} ,
		{"word":"ballons","word_nosc":"ballons","lemma":"ballon","pos":"NOM"} ,
		{"word":"ballot","word_nosc":"ballot","lemma":"ballot","pos":"NOM"} ,
		{"word":"ballote","word_nosc":"ballote","lemma":"ballote","pos":"NOM"} ,
		{"word":"ballots","word_nosc":"ballots","lemma":"ballot","pos":"NOM"} ,
		{"word":"ballottage","word_nosc":"ballottage","lemma":"ballottage","pos":"NOM"} ,
		{"word":"ballottages","word_nosc":"ballottages","lemma":"ballottage","pos":"NOM"} ,
		{"word":"ballottement","word_nosc":"ballottement","lemma":"ballottement","pos":"NOM"} ,
		{"word":"ballottine","word_nosc":"ballottine","lemma":"ballottine","pos":"NOM"} ,
		{"word":"ballottines","word_nosc":"ballottines","lemma":"ballottine","pos":"NOM"} ,
		{"word":"balluche","word_nosc":"balluche","lemma":"balluche","pos":"NOM"} ,
		{"word":"balluches","word_nosc":"balluches","lemma":"balluche","pos":"NOM"} ,
		{"word":"balluchon","word_nosc":"balluchon","lemma":"balluchon","pos":"NOM"} ,
		{"word":"balluchonnage","word_nosc":"balluchonnage","lemma":"balluchonnage","pos":"NOM"} ,
		{"word":"balluchons","word_nosc":"balluchons","lemma":"balluchon","pos":"NOM"} ,
		{"word":"balnéothérapie","word_nosc":"balneotherapie","lemma":"balnéothérapie","pos":"NOM"} ,
		{"word":"balourd","word_nosc":"balourd","lemma":"balourd","pos":"NOM"} ,
		{"word":"balourdise","word_nosc":"balourdise","lemma":"balourdise","pos":"NOM"} ,
		{"word":"balourdises","word_nosc":"balourdises","lemma":"balourdise","pos":"NOM"} ,
		{"word":"balourds","word_nosc":"balourds","lemma":"balourd","pos":"NOM"} ,
		{"word":"bals","word_nosc":"bals","lemma":"bal","pos":"NOM"} ,
		{"word":"balsa","word_nosc":"balsa","lemma":"balsa","pos":"NOM"} ,
		{"word":"balsamier","word_nosc":"balsamier","lemma":"balsamier","pos":"NOM"} ,
		{"word":"balsamiers","word_nosc":"balsamiers","lemma":"balsamier","pos":"NOM"} ,
		{"word":"balsamine","word_nosc":"balsamine","lemma":"balsamine","pos":"NOM"} ,
		{"word":"balsamines","word_nosc":"balsamines","lemma":"balsamine","pos":"NOM"} ,
		{"word":"balte","word_nosc":"balte","lemma":"balte","pos":"NOM"} ,
		{"word":"baltes","word_nosc":"baltes","lemma":"balte","pos":"NOM"} ,
		{"word":"balthazar","word_nosc":"balthazar","lemma":"balthazar","pos":"NOM"} ,
		{"word":"balthazars","word_nosc":"balthazars","lemma":"balthazar","pos":"NOM"} ,
		{"word":"baluba","word_nosc":"baluba","lemma":"baluba","pos":"NOM"} ,
		{"word":"baluchon","word_nosc":"baluchon","lemma":"baluchon","pos":"NOM"} ,
		{"word":"baluchonnage","word_nosc":"baluchonnage","lemma":"baluchonnage","pos":"NOM"} ,
		{"word":"baluchonneur","word_nosc":"baluchonneur","lemma":"baluchonneur","pos":"NOM"} ,
		{"word":"baluchonneurs","word_nosc":"baluchonneurs","lemma":"baluchonneur","pos":"NOM"} ,
		{"word":"baluchons","word_nosc":"baluchons","lemma":"baluchon","pos":"NOM"} ,
		{"word":"balustrade","word_nosc":"balustrade","lemma":"balustrade","pos":"NOM"} ,
		{"word":"balustrades","word_nosc":"balustrades","lemma":"balustrade","pos":"NOM"} ,
		{"word":"balustre","word_nosc":"balustre","lemma":"balustre","pos":"NOM"} ,
		{"word":"balustres","word_nosc":"balustres","lemma":"balustre","pos":"NOM"} ,
		{"word":"balzacien","word_nosc":"balzacien","lemma":"balzacien","pos":"NOM"} ,
		{"word":"balzanes","word_nosc":"balzanes","lemma":"balzane","pos":"NOM"} ,
		{"word":"balèze","word_nosc":"baleze","lemma":"balèze","pos":"NOM"} ,
		{"word":"balèzes","word_nosc":"balezes","lemma":"balèze","pos":"NOM"} ,
		{"word":"bambara","word_nosc":"bambara","lemma":"bambara","pos":"NOM"} ,
		{"word":"bambin","word_nosc":"bambin","lemma":"bambin","pos":"NOM"} ,
		{"word":"bambine","word_nosc":"bambine","lemma":"bambin","pos":"NOM"} ,
		{"word":"bambines","word_nosc":"bambines","lemma":"bambin","pos":"NOM"} ,
		{"word":"bambinette","word_nosc":"bambinette","lemma":"bambinette","pos":"NOM"} ,
		{"word":"bambino","word_nosc":"bambino","lemma":"bambino","pos":"NOM"} ,
		{"word":"bambinos","word_nosc":"bambinos","lemma":"bambino","pos":"NOM"} ,
		{"word":"bambins","word_nosc":"bambins","lemma":"bambin","pos":"NOM"} ,
		{"word":"bamboche","word_nosc":"bamboche","lemma":"bamboche","pos":"NOM"} ,
		{"word":"bambocheurs","word_nosc":"bambocheurs","lemma":"bambocheur","pos":"NOM"} ,
		{"word":"bambou","word_nosc":"bambou","lemma":"bambou","pos":"NOM"} ,
		{"word":"bamboula","word_nosc":"bamboula","lemma":"bamboula","pos":"NOM"} ,
		{"word":"bambous","word_nosc":"bambous","lemma":"bambou","pos":"NOM"} ,
		{"word":"ban","word_nosc":"ban","lemma":"ban","pos":"NOM"} ,
		{"word":"banalisation","word_nosc":"banalisation","lemma":"banalisation","pos":"NOM"} ,
		{"word":"banalité","word_nosc":"banalite","lemma":"banalité","pos":"NOM"} ,
		{"word":"banalités","word_nosc":"banalites","lemma":"banalité","pos":"NOM"} ,
		{"word":"banane","word_nosc":"banane","lemma":"banane","pos":"NOM"} ,
		{"word":"bananeraie","word_nosc":"bananeraie","lemma":"bananeraie","pos":"NOM"} ,
		{"word":"bananes","word_nosc":"bananes","lemma":"banane","pos":"NOM"} ,
		{"word":"bananier","word_nosc":"bananier","lemma":"bananier","pos":"NOM"} ,
		{"word":"bananiers","word_nosc":"bananiers","lemma":"bananier","pos":"NOM"} ,
		{"word":"banc","word_nosc":"banc","lemma":"banc","pos":"NOM"} ,
		{"word":"banc-titre","word_nosc":"banc-titre","lemma":"banc-titre","pos":"NOM"} ,
		{"word":"banco","word_nosc":"banco","lemma":"banco","pos":"NOM"} ,
		{"word":"bancos","word_nosc":"bancos","lemma":"banco","pos":"NOM"} ,
		{"word":"bancroches","word_nosc":"bancroches","lemma":"bancroche","pos":"NOM"} ,
		{"word":"bancs","word_nosc":"bancs","lemma":"banc","pos":"NOM"} ,
		{"word":"bandage","word_nosc":"bandage","lemma":"bandage","pos":"NOM"} ,
		{"word":"bandages","word_nosc":"bandages","lemma":"bandage","pos":"NOM"} ,
		{"word":"bandagiste","word_nosc":"bandagiste","lemma":"bandagiste","pos":"NOM"} ,
		{"word":"bandaison","word_nosc":"bandaison","lemma":"bandaison","pos":"NOM"} ,
		{"word":"bandaisons","word_nosc":"bandaisons","lemma":"bandaison","pos":"NOM"} ,
		{"word":"bandana","word_nosc":"bandana","lemma":"bandana","pos":"NOM"} ,
		{"word":"bandanas","word_nosc":"bandanas","lemma":"bandana","pos":"NOM"} ,
		{"word":"bande","word_nosc":"bande","lemma":"bande","pos":"NOM"} ,
		{"word":"bande-annonce","word_nosc":"bande-annonce","lemma":"bande-annonce","pos":"NOM"} ,
		{"word":"bande-son","word_nosc":"bande-son","lemma":"bande-son","pos":"NOM"} ,
		{"word":"bandeau","word_nosc":"bandeau","lemma":"bandeau","pos":"NOM"} ,
		{"word":"bandeaux","word_nosc":"bandeaux","lemma":"bandeau","pos":"NOM"} ,
		{"word":"bandelette","word_nosc":"bandelette","lemma":"bandelette","pos":"NOM"} ,
		{"word":"bandelettes","word_nosc":"bandelettes","lemma":"bandelette","pos":"NOM"} ,
		{"word":"bandera","word_nosc":"bandera","lemma":"bandera","pos":"NOM"} ,
		{"word":"banderas","word_nosc":"banderas","lemma":"bandera","pos":"NOM"} ,
		{"word":"banderille","word_nosc":"banderille","lemma":"banderille","pos":"NOM"} ,
		{"word":"banderillero","word_nosc":"banderillero","lemma":"banderillero","pos":"NOM"} ,
		{"word":"banderilleros","word_nosc":"banderilleros","lemma":"banderillero","pos":"NOM"} ,
		{"word":"banderilles","word_nosc":"banderilles","lemma":"banderille","pos":"NOM"} ,
		{"word":"banderole","word_nosc":"banderole","lemma":"banderole","pos":"NOM"} ,
		{"word":"banderoles","word_nosc":"banderoles","lemma":"banderole","pos":"NOM"} ,
		{"word":"bandes","word_nosc":"bandes","lemma":"bande","pos":"NOM"} ,
		{"word":"bandes-annonces","word_nosc":"bandes-annonces","lemma":"bande-annonce","pos":"NOM"} ,
		{"word":"bandeur","word_nosc":"bandeur","lemma":"bandeur","pos":"NOM"} ,
		{"word":"bandeurs","word_nosc":"bandeurs","lemma":"bandeur","pos":"NOM"} ,
		{"word":"bandit","word_nosc":"bandit","lemma":"bandit","pos":"NOM"} ,
		{"word":"banditisme","word_nosc":"banditisme","lemma":"banditisme","pos":"NOM"} ,
		{"word":"bandits","word_nosc":"bandits","lemma":"bandit","pos":"NOM"} ,
		{"word":"bandoline","word_nosc":"bandoline","lemma":"bandoline","pos":"NOM"} ,
		{"word":"bandonéon","word_nosc":"bandoneon","lemma":"bandonéon","pos":"NOM"} ,
		{"word":"bandothèque","word_nosc":"bandotheque","lemma":"bandothèque","pos":"NOM"} ,
		{"word":"bandoulière","word_nosc":"bandouliere","lemma":"bandoulière","pos":"NOM"} ,
		{"word":"bang","word_nosc":"bang","lemma":"bang","pos":"NOM"} ,
		{"word":"bangladais","word_nosc":"bangladais","lemma":"bangladais","pos":"NOM"} ,
		{"word":"bangs","word_nosc":"bangs","lemma":"bang","pos":"NOM"} ,
		{"word":"banian","word_nosc":"banian","lemma":"banian","pos":"NOM"} ,
		{"word":"banians","word_nosc":"banians","lemma":"banian","pos":"NOM"} ,
		{"word":"banjo","word_nosc":"banjo","lemma":"banjo","pos":"NOM"} ,
		{"word":"banjos","word_nosc":"banjos","lemma":"banjo","pos":"NOM"} ,
		{"word":"bank-note","word_nosc":"bank-note","lemma":"bank-note","pos":"NOM"} ,
		{"word":"bank-notes","word_nosc":"bank-notes","lemma":"bank-note","pos":"NOM"} ,
		{"word":"banlieue","word_nosc":"banlieue","lemma":"banlieue","pos":"NOM"} ,
		{"word":"banlieues","word_nosc":"banlieues","lemma":"banlieue","pos":"NOM"} ,
		{"word":"banlieusard","word_nosc":"banlieusard","lemma":"banlieusard","pos":"NOM"} ,
		{"word":"banlieusarde","word_nosc":"banlieusarde","lemma":"banlieusard","pos":"NOM"} ,
		{"word":"banlieusardes","word_nosc":"banlieusardes","lemma":"banlieusard","pos":"NOM"} ,
		{"word":"banlieusards","word_nosc":"banlieusards","lemma":"banlieusard","pos":"NOM"} ,
		{"word":"bannes","word_nosc":"bannes","lemma":"banne","pos":"NOM"} ,
		{"word":"banni","word_nosc":"banni","lemma":"banni","pos":"NOM"} ,
		{"word":"bannie","word_nosc":"bannie","lemma":"banni","pos":"NOM"} ,
		{"word":"bannis","word_nosc":"bannis","lemma":"banni","pos":"NOM"} ,
		{"word":"bannissement","word_nosc":"bannissement","lemma":"bannissement","pos":"NOM"} ,
		{"word":"bannissements","word_nosc":"bannissements","lemma":"bannissement","pos":"NOM"} ,
		{"word":"bannière","word_nosc":"banniere","lemma":"bannière","pos":"NOM"} ,
		{"word":"bannières","word_nosc":"bannieres","lemma":"bannière","pos":"NOM"} ,
		{"word":"banque","word_nosc":"banque","lemma":"banque","pos":"NOM"} ,
		{"word":"banqueroute","word_nosc":"banqueroute","lemma":"banqueroute","pos":"NOM"} ,
		{"word":"banqueroutes","word_nosc":"banqueroutes","lemma":"banqueroute","pos":"NOM"} ,
		{"word":"banqueroutier","word_nosc":"banqueroutier","lemma":"banqueroutier","pos":"NOM"} ,
		{"word":"banqueroutiers","word_nosc":"banqueroutiers","lemma":"banqueroutier","pos":"NOM"} ,
		{"word":"banques","word_nosc":"banques","lemma":"banque","pos":"NOM"} ,
		{"word":"banquet","word_nosc":"banquet","lemma":"banquet","pos":"NOM"} ,
		{"word":"banquets","word_nosc":"banquets","lemma":"banquet","pos":"NOM"} ,
		{"word":"banquette","word_nosc":"banquette","lemma":"banquette","pos":"NOM"} ,
		{"word":"banquettes","word_nosc":"banquettes","lemma":"banquette","pos":"NOM"} ,
		{"word":"banquier","word_nosc":"banquier","lemma":"banquier","pos":"NOM"} ,
		{"word":"banquiers","word_nosc":"banquiers","lemma":"banquier","pos":"NOM"} ,
		{"word":"banquise","word_nosc":"banquise","lemma":"banquise","pos":"NOM"} ,
		{"word":"banquises","word_nosc":"banquises","lemma":"banquise","pos":"NOM"} ,
		{"word":"banquistes","word_nosc":"banquistes","lemma":"banquiste","pos":"NOM"} ,
		{"word":"banquière","word_nosc":"banquiere","lemma":"banquier","pos":"NOM"} ,
		{"word":"banquières","word_nosc":"banquieres","lemma":"banquier","pos":"NOM"} ,
		{"word":"bans","word_nosc":"bans","lemma":"ban","pos":"NOM"} ,
		{"word":"bantou","word_nosc":"bantou","lemma":"bantou","pos":"NOM"} ,
		{"word":"bantu","word_nosc":"bantu","lemma":"bantu","pos":"NOM"} ,
		{"word":"banyan","word_nosc":"banyan","lemma":"banyan","pos":"NOM"} ,
		{"word":"banyuls","word_nosc":"banyuls","lemma":"banyuls","pos":"NOM"} ,
		{"word":"baobab","word_nosc":"baobab","lemma":"baobab","pos":"NOM"} ,
		{"word":"baobabs","word_nosc":"baobabs","lemma":"baobab","pos":"NOM"} ,
		{"word":"baou","word_nosc":"baou","lemma":"baou","pos":"NOM"} ,
		{"word":"baptiseur","word_nosc":"baptiseur","lemma":"baptiseur","pos":"NOM"} ,
		{"word":"baptiste","word_nosc":"baptiste","lemma":"baptiste","pos":"NOM"} ,
		{"word":"baptistes","word_nosc":"baptistes","lemma":"baptiste","pos":"NOM"} ,
		{"word":"baptistère","word_nosc":"baptistere","lemma":"baptistère","pos":"NOM"} ,
		{"word":"baptême","word_nosc":"bapteme","lemma":"baptême","pos":"NOM"} ,
		{"word":"baptêmes","word_nosc":"baptemes","lemma":"baptême","pos":"NOM"} ,
		{"word":"baquet","word_nosc":"baquet","lemma":"baquet","pos":"NOM"} ,
		{"word":"baquets","word_nosc":"baquets","lemma":"baquet","pos":"NOM"} ,
		{"word":"bar","word_nosc":"bar","lemma":"bar","pos":"NOM"} ,
		{"word":"bar-hôtel","word_nosc":"bar-hotel","lemma":"bar-hôtel","pos":"NOM"} ,
		{"word":"bar-mitsva","word_nosc":"bar-mitsva","lemma":"bar-mitsva","pos":"NOM"} ,
		{"word":"bar-restaurant","word_nosc":"bar-restaurant","lemma":"bar-restaurant","pos":"NOM"} ,
		{"word":"bar-tabac","word_nosc":"bar-tabac","lemma":"bar-tabac","pos":"NOM"} ,
		{"word":"baragouin","word_nosc":"baragouin","lemma":"baragouin","pos":"NOM"} ,
		{"word":"baragouinage","word_nosc":"baragouinage","lemma":"baragouinage","pos":"NOM"} ,
		{"word":"baragouineur","word_nosc":"baragouineur","lemma":"baragouineur","pos":"NOM"} ,
		{"word":"baragouins","word_nosc":"baragouins","lemma":"baragouin","pos":"NOM"} ,
		{"word":"baraka","word_nosc":"baraka","lemma":"baraka","pos":"NOM"} ,
		{"word":"baralipton","word_nosc":"baralipton","lemma":"baralipton","pos":"NOM"} ,
		{"word":"baraque","word_nosc":"baraque","lemma":"baraque","pos":"NOM"} ,
		{"word":"baraquement","word_nosc":"baraquement","lemma":"baraquement","pos":"NOM"} ,
		{"word":"baraquements","word_nosc":"baraquements","lemma":"baraquement","pos":"NOM"} ,
		{"word":"baraques","word_nosc":"baraques","lemma":"baraque","pos":"NOM"} ,
		{"word":"baraterie","word_nosc":"baraterie","lemma":"baraterie","pos":"NOM"} ,
		{"word":"baratin","word_nosc":"baratin","lemma":"baratin","pos":"NOM"} ,
		{"word":"baratineur","word_nosc":"baratineur","lemma":"baratineur","pos":"NOM"} ,
		{"word":"baratineurs","word_nosc":"baratineurs","lemma":"baratineur","pos":"NOM"} ,
		{"word":"baratineuse","word_nosc":"baratineuse","lemma":"baratineur","pos":"NOM"} ,
		{"word":"baratins","word_nosc":"baratins","lemma":"baratin","pos":"NOM"} ,
		{"word":"barattage","word_nosc":"barattage","lemma":"barattage","pos":"NOM"} ,
		{"word":"baratte","word_nosc":"baratte","lemma":"baratte","pos":"NOM"} ,
		{"word":"barattes","word_nosc":"barattes","lemma":"baratte","pos":"NOM"} ,
		{"word":"barbacane","word_nosc":"barbacane","lemma":"barbacane","pos":"NOM"} ,
		{"word":"barbacanes","word_nosc":"barbacanes","lemma":"barbacane","pos":"NOM"} ,
		{"word":"barbaque","word_nosc":"barbaque","lemma":"barbaque","pos":"NOM"} ,
		{"word":"barbara","word_nosc":"barbara","lemma":"barbara","pos":"NOM"} ,
		{"word":"barbare","word_nosc":"barbare","lemma":"barbare","pos":"NOM"} ,
		{"word":"barbares","word_nosc":"barbares","lemma":"barbare","pos":"NOM"} ,
		{"word":"barbaresque","word_nosc":"barbaresque","lemma":"barbaresque","pos":"NOM"} ,
		{"word":"barbaresques","word_nosc":"barbaresques","lemma":"barbaresque","pos":"NOM"} ,
		{"word":"barbarie","word_nosc":"barbarie","lemma":"barbarie","pos":"NOM"} ,
		{"word":"barbaries","word_nosc":"barbaries","lemma":"barbarie","pos":"NOM"} ,
		{"word":"barbarisme","word_nosc":"barbarisme","lemma":"barbarisme","pos":"NOM"} ,
		{"word":"barbarismes","word_nosc":"barbarismes","lemma":"barbarisme","pos":"NOM"} ,
		{"word":"barbe","word_nosc":"barbe","lemma":"barbe","pos":"NOM"} ,
		{"word":"barbe-de-capucin","word_nosc":"barbe-de-capucin","lemma":"barbe-de-capucin","pos":"NOM"} ,
		{"word":"barbeau","word_nosc":"barbeau","lemma":"barbeau","pos":"NOM"} ,
		{"word":"barbeaux","word_nosc":"barbeaux","lemma":"barbeau","pos":"NOM"} ,
		{"word":"barbecue","word_nosc":"barbecue","lemma":"barbecue","pos":"NOM"} ,
		{"word":"barbecues","word_nosc":"barbecues","lemma":"barbecue","pos":"NOM"} ,
		{"word":"barbelé","word_nosc":"barbele","lemma":"barbelé","pos":"NOM"} ,
		{"word":"barbelés","word_nosc":"barbeles","lemma":"barbelé","pos":"NOM"} ,
		{"word":"barbes","word_nosc":"barbes","lemma":"barbe","pos":"NOM"} ,
		{"word":"barbet","word_nosc":"barbet","lemma":"barbet","pos":"NOM"} ,
		{"word":"barbets","word_nosc":"barbets","lemma":"barbet","pos":"NOM"} ,
		{"word":"barbette","word_nosc":"barbette","lemma":"barbette","pos":"NOM"} ,
		{"word":"barbiche","word_nosc":"barbiche","lemma":"barbiche","pos":"NOM"} ,
		{"word":"barbiches","word_nosc":"barbiches","lemma":"barbiche","pos":"NOM"} ,
		{"word":"barbichette","word_nosc":"barbichette","lemma":"barbichette","pos":"NOM"} ,
		{"word":"barbier","word_nosc":"barbier","lemma":"barbier","pos":"NOM"} ,
		{"word":"barbiers","word_nosc":"barbiers","lemma":"barbier","pos":"NOM"} ,
		{"word":"barbillon","word_nosc":"barbillon","lemma":"barbillon","pos":"NOM"} ,
		{"word":"barbillons","word_nosc":"barbillons","lemma":"barbillon","pos":"NOM"} ,
		{"word":"barbiquet","word_nosc":"barbiquet","lemma":"barbiquet","pos":"NOM"} ,
		{"word":"barbiquets","word_nosc":"barbiquets","lemma":"barbiquet","pos":"NOM"} ,
		{"word":"barbiturique","word_nosc":"barbiturique","lemma":"barbiturique","pos":"NOM"} ,
		{"word":"barbituriques","word_nosc":"barbituriques","lemma":"barbiturique","pos":"NOM"} ,
		{"word":"barbon","word_nosc":"barbon","lemma":"barbon","pos":"NOM"} ,
		{"word":"barbons","word_nosc":"barbons","lemma":"barbon","pos":"NOM"} ,
		{"word":"barbot","word_nosc":"barbot","lemma":"barbot","pos":"NOM"} ,
		{"word":"barbotages","word_nosc":"barbotages","lemma":"barbotage","pos":"NOM"} ,
		{"word":"barbote","word_nosc":"barbote","lemma":"barbote","pos":"NOM"} ,
		{"word":"barboteuse","word_nosc":"barboteuse","lemma":"barboteur","pos":"NOM"} ,
		{"word":"barboteuses","word_nosc":"barboteuses","lemma":"barboteur","pos":"NOM"} ,
		{"word":"barbotière","word_nosc":"barbotiere","lemma":"barbotière","pos":"NOM"} ,
		{"word":"barbotte","word_nosc":"barbotte","lemma":"barbotte","pos":"NOM"} ,
		{"word":"barbottes","word_nosc":"barbottes","lemma":"barbotte","pos":"NOM"} ,
		{"word":"barbouillage","word_nosc":"barbouillage","lemma":"barbouillage","pos":"NOM"} ,
		{"word":"barbouillages","word_nosc":"barbouillages","lemma":"barbouillage","pos":"NOM"} ,
		{"word":"barbouille","word_nosc":"barbouille","lemma":"barbouille","pos":"NOM"} ,
		{"word":"barbouilleur","word_nosc":"barbouilleur","lemma":"barbouilleur","pos":"NOM"} ,
		{"word":"barbouilleurs","word_nosc":"barbouilleurs","lemma":"barbouilleur","pos":"NOM"} ,
		{"word":"barbouillis","word_nosc":"barbouillis","lemma":"barbouillis","pos":"NOM"} ,
		{"word":"barbouze","word_nosc":"barbouze","lemma":"barbouze","pos":"NOM"} ,
		{"word":"barbouzes","word_nosc":"barbouzes","lemma":"barbouze","pos":"NOM"} ,
		{"word":"barbu","word_nosc":"barbu","lemma":"barbu","pos":"NOM"} ,
		{"word":"barbue","word_nosc":"barbue","lemma":"barbue","pos":"NOM"} ,
		{"word":"barbues","word_nosc":"barbues","lemma":"barbue","pos":"NOM"} ,
		{"word":"barbules","word_nosc":"barbules","lemma":"barbule","pos":"NOM"} ,
		{"word":"barbus","word_nosc":"barbus","lemma":"barbu","pos":"NOM"} ,
		{"word":"barcarolle","word_nosc":"barcarolle","lemma":"barcarolle","pos":"NOM"} ,
		{"word":"barcasse","word_nosc":"barcasse","lemma":"barcasse","pos":"NOM"} ,
		{"word":"barcasses","word_nosc":"barcasses","lemma":"barcasse","pos":"NOM"} ,
		{"word":"barda","word_nosc":"barda","lemma":"barda","pos":"NOM"} ,
		{"word":"bardage","word_nosc":"bardage","lemma":"bardage","pos":"NOM"} ,
		{"word":"bardane","word_nosc":"bardane","lemma":"bardane","pos":"NOM"} ,
		{"word":"bardanes","word_nosc":"bardanes","lemma":"bardane","pos":"NOM"} ,
		{"word":"bardas","word_nosc":"bardas","lemma":"barda","pos":"NOM"} ,
		{"word":"barde","word_nosc":"barde","lemma":"barde","pos":"NOM"} ,
		{"word":"bardeau","word_nosc":"bardeau","lemma":"bardeau","pos":"NOM"} ,
		{"word":"bardeaux","word_nosc":"bardeaux","lemma":"bardeau","pos":"NOM"} ,
		{"word":"bardes","word_nosc":"bardes","lemma":"barde","pos":"NOM"} ,
		{"word":"bardeurs","word_nosc":"bardeurs","lemma":"bardeur","pos":"NOM"} ,
		{"word":"bardo","word_nosc":"bardo","lemma":"bardo","pos":"NOM"} ,
		{"word":"bardolino","word_nosc":"bardolino","lemma":"bardolino","pos":"NOM"} ,
		{"word":"bardot","word_nosc":"bardot","lemma":"bardot","pos":"NOM"} ,
		{"word":"bardots","word_nosc":"bardots","lemma":"bardot","pos":"NOM"} ,
		{"word":"baret","word_nosc":"baret","lemma":"baret","pos":"NOM"} ,
		{"word":"barge","word_nosc":"barge","lemma":"barge","pos":"NOM"} ,
		{"word":"barges","word_nosc":"barges","lemma":"barge","pos":"NOM"} ,
		{"word":"baril","word_nosc":"baril","lemma":"baril","pos":"NOM"} ,
		{"word":"barillet","word_nosc":"barillet","lemma":"barillet","pos":"NOM"} ,
		{"word":"barillets","word_nosc":"barillets","lemma":"barillet","pos":"NOM"} ,
		{"word":"barils","word_nosc":"barils","lemma":"baril","pos":"NOM"} ,
		{"word":"barine","word_nosc":"barine","lemma":"barine","pos":"NOM"} ,
		{"word":"bariolage","word_nosc":"bariolage","lemma":"bariolage","pos":"NOM"} ,
		{"word":"bariolages","word_nosc":"bariolages","lemma":"bariolage","pos":"NOM"} ,
		{"word":"bariolures","word_nosc":"bariolures","lemma":"bariolure","pos":"NOM"} ,
		{"word":"barjo","word_nosc":"barjo","lemma":"barjo","pos":"NOM"} ,
		{"word":"barjos","word_nosc":"barjos","lemma":"barjo","pos":"NOM"} ,
		{"word":"barjot","word_nosc":"barjot","lemma":"barjot","pos":"NOM"} ,
		{"word":"barjots","word_nosc":"barjots","lemma":"barjot","pos":"NOM"} ,
		{"word":"barmaid","word_nosc":"barmaid","lemma":"barmaid","pos":"NOM"} ,
		{"word":"barmaids","word_nosc":"barmaids","lemma":"barmaid","pos":"NOM"} ,
		{"word":"barman","word_nosc":"barman","lemma":"barman","pos":"NOM"} ,
		{"word":"barmans","word_nosc":"barmans","lemma":"barman","pos":"NOM"} ,
		{"word":"barmen","word_nosc":"barmen","lemma":"barman","pos":"NOM"} ,
		{"word":"barnum","word_nosc":"barnum","lemma":"barnum","pos":"NOM"} ,
		{"word":"barolo","word_nosc":"barolo","lemma":"barolo","pos":"NOM"} ,
		{"word":"baromètre","word_nosc":"barometre","lemma":"baromètre","pos":"NOM"} ,
		{"word":"baromètres","word_nosc":"barometres","lemma":"baromètre","pos":"NOM"} ,
		{"word":"baron","word_nosc":"baron","lemma":"baron","pos":"NOM"} ,
		{"word":"baronet","word_nosc":"baronet","lemma":"baronet","pos":"NOM"} ,
		{"word":"baronne","word_nosc":"baronne","lemma":"baron","pos":"NOM"} ,
		{"word":"baronnes","word_nosc":"baronnes","lemma":"baron","pos":"NOM"} ,
		{"word":"baronnet","word_nosc":"baronnet","lemma":"baronnet","pos":"NOM"} ,
		{"word":"baronnets","word_nosc":"baronnets","lemma":"baronnet","pos":"NOM"} ,
		{"word":"baronnie","word_nosc":"baronnie","lemma":"baronnie","pos":"NOM"} ,
		{"word":"baronnies","word_nosc":"baronnies","lemma":"baronnie","pos":"NOM"} ,
		{"word":"barons","word_nosc":"barons","lemma":"baron","pos":"NOM"} ,
		{"word":"baroque","word_nosc":"baroque","lemma":"baroque","pos":"NOM"} ,
		{"word":"baroques","word_nosc":"baroques","lemma":"baroque","pos":"NOM"} ,
		{"word":"barotraumatisme","word_nosc":"barotraumatisme","lemma":"barotraumatisme","pos":"NOM"} ,
		{"word":"baroud","word_nosc":"baroud","lemma":"baroud","pos":"NOM"} ,
		{"word":"baroudeur","word_nosc":"baroudeur","lemma":"baroudeur","pos":"NOM"} ,
		{"word":"baroudeurs","word_nosc":"baroudeurs","lemma":"baroudeur","pos":"NOM"} ,
		{"word":"baroudeuse","word_nosc":"baroudeuse","lemma":"baroudeur","pos":"NOM"} ,
		{"word":"barouf","word_nosc":"barouf","lemma":"barouf","pos":"NOM"} ,
		{"word":"barque","word_nosc":"barque","lemma":"barque","pos":"NOM"} ,
		{"word":"barques","word_nosc":"barques","lemma":"barque","pos":"NOM"} ,
		{"word":"barquette","word_nosc":"barquette","lemma":"barquette","pos":"NOM"} ,
		{"word":"barquettes","word_nosc":"barquettes","lemma":"barquette","pos":"NOM"} ,
		{"word":"barracuda","word_nosc":"barracuda","lemma":"barracuda","pos":"NOM"} ,
		{"word":"barracudas","word_nosc":"barracudas","lemma":"barracuda","pos":"NOM"} ,
		{"word":"barrage","word_nosc":"barrage","lemma":"barrage","pos":"NOM"} ,
		{"word":"barrages","word_nosc":"barrages","lemma":"barrage","pos":"NOM"} ,
		{"word":"barre","word_nosc":"barre","lemma":"barre","pos":"NOM"} ,
		{"word":"barreau","word_nosc":"barreau","lemma":"barreau","pos":"NOM"} ,
		{"word":"barreaux","word_nosc":"barreaux","lemma":"barreau","pos":"NOM"} ,
		{"word":"barres","word_nosc":"barres","lemma":"barre","pos":"NOM"} ,
		{"word":"barrette","word_nosc":"barrette","lemma":"barrette","pos":"NOM"} ,
		{"word":"barrettes","word_nosc":"barrettes","lemma":"barrette","pos":"NOM"} ,
		{"word":"barreur","word_nosc":"barreur","lemma":"barreur","pos":"NOM"} ,
		{"word":"barreurs","word_nosc":"barreurs","lemma":"barreur","pos":"NOM"} ,
		{"word":"barreuse","word_nosc":"barreuse","lemma":"barreur","pos":"NOM"} ,
		{"word":"barricade","word_nosc":"barricade","lemma":"barricade","pos":"NOM"} ,
		{"word":"barricades","word_nosc":"barricades","lemma":"barricade","pos":"NOM"} ,
		{"word":"barricadier","word_nosc":"barricadier","lemma":"barricadier","pos":"NOM"} ,
		{"word":"barricadières","word_nosc":"barricadieres","lemma":"barricadier","pos":"NOM"} ,
		{"word":"barrique","word_nosc":"barrique","lemma":"barrique","pos":"NOM"} ,
		{"word":"barriques","word_nosc":"barriques","lemma":"barrique","pos":"NOM"} ,
		{"word":"barrissement","word_nosc":"barrissement","lemma":"barrissement","pos":"NOM"} ,
		{"word":"barrissements","word_nosc":"barrissements","lemma":"barrissement","pos":"NOM"} ,
		{"word":"barriste","word_nosc":"barriste","lemma":"barriste","pos":"NOM"} ,
		{"word":"barrière","word_nosc":"barriere","lemma":"barrière","pos":"NOM"} ,
		{"word":"barrières","word_nosc":"barrieres","lemma":"barrière","pos":"NOM"} ,
		{"word":"barrésien","word_nosc":"barresien","lemma":"barrésien","pos":"NOM"} ,
		{"word":"bars","word_nosc":"bars","lemma":"bar","pos":"NOM"} ,
		{"word":"bartavelle","word_nosc":"bartavelle","lemma":"bartavelle","pos":"NOM"} ,
		{"word":"bartavelles","word_nosc":"bartavelles","lemma":"bartavelle","pos":"NOM"} ,
		{"word":"barye","word_nosc":"barye","lemma":"barye","pos":"NOM"} ,
		{"word":"baryon","word_nosc":"baryon","lemma":"baryon","pos":"NOM"} ,
		{"word":"baryte","word_nosc":"baryte","lemma":"baryte","pos":"NOM"} ,
		{"word":"baryton","word_nosc":"baryton","lemma":"baryton","pos":"NOM"} ,
		{"word":"barytons","word_nosc":"barytons","lemma":"baryton","pos":"NOM"} ,
		{"word":"baryum","word_nosc":"baryum","lemma":"baryum","pos":"NOM"} ,
		{"word":"barzoï","word_nosc":"barzoi","lemma":"barzoï","pos":"NOM"} ,
		{"word":"barème","word_nosc":"bareme","lemma":"barème","pos":"NOM"} ,
		{"word":"barèmes","word_nosc":"baremes","lemma":"barème","pos":"NOM"} ,
		{"word":"bas","word_nosc":"bas","lemma":"bas","pos":"NOM"} ,
		{"word":"bas-bleu","word_nosc":"bas-bleu","lemma":"bas-bleu","pos":"NOM"} ,
		{"word":"bas-bleus","word_nosc":"bas-bleus","lemma":"bas-bleu","pos":"NOM"} ,
		{"word":"bas-côté","word_nosc":"bas-cote","lemma":"bas-côté","pos":"NOM"} ,
		{"word":"bas-côtés","word_nosc":"bas-cotes","lemma":"bas-côté","pos":"NOM"} ,
		{"word":"bas-empire","word_nosc":"bas-empire","lemma":"bas-empire","pos":"NOM"} ,
		{"word":"bas-flanc","word_nosc":"bas-flanc","lemma":"bas-flanc","pos":"NOM"} ,
		{"word":"bas-fond","word_nosc":"bas-fond","lemma":"bas-fond","pos":"NOM"} ,
		{"word":"bas-fonds","word_nosc":"bas-fonds","lemma":"bas-fond","pos":"NOM"} ,
		{"word":"bas-relief","word_nosc":"bas-relief","lemma":"bas-relief","pos":"NOM"} ,
		{"word":"bas-reliefs","word_nosc":"bas-reliefs","lemma":"bas-relief","pos":"NOM"} ,
		{"word":"bas-ventre","word_nosc":"bas-ventre","lemma":"bas-ventre","pos":"NOM"} ,
		{"word":"basalte","word_nosc":"basalte","lemma":"basalte","pos":"NOM"} ,
		{"word":"basaltes","word_nosc":"basaltes","lemma":"basalte","pos":"NOM"} ,
		{"word":"basane","word_nosc":"basane","lemma":"basane","pos":"NOM"} ,
		{"word":"basanes","word_nosc":"basanes","lemma":"basane","pos":"NOM"} ,
		{"word":"bascule","word_nosc":"bascule","lemma":"bascule","pos":"NOM"} ,
		{"word":"basculement","word_nosc":"basculement","lemma":"basculement","pos":"NOM"} ,
		{"word":"bascules","word_nosc":"bascules","lemma":"bascule","pos":"NOM"} ,
		{"word":"base","word_nosc":"base","lemma":"base","pos":"NOM"} ,
		{"word":"base-ball","word_nosc":"base-ball","lemma":"base-ball","pos":"NOM"} ,
		{"word":"baseball","word_nosc":"baseball","lemma":"baseball","pos":"NOM"} ,
		{"word":"bases","word_nosc":"bases","lemma":"base","pos":"NOM"} ,
		{"word":"basic","word_nosc":"basic","lemma":"basic","pos":"NOM"} ,
		{"word":"basileus","word_nosc":"basileus","lemma":"basileus","pos":"NOM"} ,
		{"word":"basilic","word_nosc":"basilic","lemma":"basilic","pos":"NOM"} ,
		{"word":"basilicum","word_nosc":"basilicum","lemma":"basilicum","pos":"NOM"} ,
		{"word":"basilique","word_nosc":"basilique","lemma":"basilique","pos":"NOM"} ,
		{"word":"basiliques","word_nosc":"basiliques","lemma":"basilique","pos":"NOM"} ,
		{"word":"basin","word_nosc":"basin","lemma":"basin","pos":"NOM"} ,
		{"word":"basket","word_nosc":"basket","lemma":"basket","pos":"NOM"} ,
		{"word":"basket-ball","word_nosc":"basket-ball","lemma":"basket-ball","pos":"NOM"} ,
		{"word":"baskets","word_nosc":"baskets","lemma":"basket","pos":"NOM"} ,
		{"word":"basketteur","word_nosc":"basketteur","lemma":"basketteur","pos":"NOM"} ,
		{"word":"basketteurs","word_nosc":"basketteurs","lemma":"basketteur","pos":"NOM"} ,
		{"word":"basketteuse","word_nosc":"basketteuse","lemma":"basketteur","pos":"NOM"} ,
		{"word":"basketteuses","word_nosc":"basketteuses","lemma":"basketteur","pos":"NOM"} ,
		{"word":"basmati","word_nosc":"basmati","lemma":"basmati","pos":"NOM"} ,
		{"word":"basoche","word_nosc":"basoche","lemma":"basoche","pos":"NOM"} ,
		{"word":"basque","word_nosc":"basque","lemma":"basque","pos":"NOM"} ,
		{"word":"basques","word_nosc":"basques","lemma":"basque","pos":"NOM"} ,
		{"word":"bassa","word_nosc":"bassa","lemma":"bassa","pos":"NOM"} ,
		{"word":"bassas","word_nosc":"bassas","lemma":"bassa","pos":"NOM"} ,
		{"word":"basse","word_nosc":"basse","lemma":"basse","pos":"NOM"} ,
		{"word":"basse-cour","word_nosc":"basse-cour","lemma":"basse-cour","pos":"NOM"} ,
		{"word":"basse-fosse","word_nosc":"basse-fosse","lemma":"basse-fosse","pos":"NOM"} ,
		{"word":"basse-taille","word_nosc":"basse-taille","lemma":"basse-taille","pos":"NOM"} ,
		{"word":"basses","word_nosc":"basses","lemma":"basse","pos":"NOM"} ,
		{"word":"basses-cours","word_nosc":"basses-cours","lemma":"basse-cour","pos":"NOM"} ,
		{"word":"basses-fosses","word_nosc":"basses-fosses","lemma":"basse-fosse","pos":"NOM"} ,
		{"word":"bassesse","word_nosc":"bassesse","lemma":"bassesse","pos":"NOM"} ,
		{"word":"bassesses","word_nosc":"bassesses","lemma":"bassesse","pos":"NOM"} ,
		{"word":"basset","word_nosc":"basset","lemma":"basset","pos":"NOM"} ,
		{"word":"bassets","word_nosc":"bassets","lemma":"basset","pos":"NOM"} ,
		{"word":"bassette","word_nosc":"bassette","lemma":"bassette","pos":"NOM"} ,
		{"word":"bassin","word_nosc":"bassin","lemma":"bassin","pos":"NOM"} ,
		{"word":"bassine","word_nosc":"bassine","lemma":"bassine","pos":"NOM"} ,
		{"word":"bassines","word_nosc":"bassines","lemma":"bassine","pos":"NOM"} ,
		{"word":"bassinet","word_nosc":"bassinet","lemma":"bassinet","pos":"NOM"} ,
		{"word":"bassinoire","word_nosc":"bassinoire","lemma":"bassinoire","pos":"NOM"} ,
		{"word":"bassinoires","word_nosc":"bassinoires","lemma":"bassinoire","pos":"NOM"} ,
		{"word":"bassins","word_nosc":"bassins","lemma":"bassin","pos":"NOM"} ,
		{"word":"bassiste","word_nosc":"bassiste","lemma":"bassiste","pos":"NOM"} ,
		{"word":"bassistes","word_nosc":"bassistes","lemma":"bassiste","pos":"NOM"} ,
		{"word":"basson","word_nosc":"basson","lemma":"basson","pos":"NOM"} ,
		{"word":"baste","word_nosc":"baste","lemma":"baste","pos":"NOM"} ,
		{"word":"bastide","word_nosc":"bastide","lemma":"bastide","pos":"NOM"} ,
		{"word":"bastides","word_nosc":"bastides","lemma":"bastide","pos":"NOM"} ,
		{"word":"bastille","word_nosc":"bastille","lemma":"bastille","pos":"NOM"} ,
		{"word":"bastilles","word_nosc":"bastilles","lemma":"bastille","pos":"NOM"} ,
		{"word":"bastingage","word_nosc":"bastingage","lemma":"bastingage","pos":"NOM"} ,
		{"word":"bastingages","word_nosc":"bastingages","lemma":"bastingage","pos":"NOM"} ,
		{"word":"bastion","word_nosc":"bastion","lemma":"bastion","pos":"NOM"} ,
		{"word":"bastions","word_nosc":"bastions","lemma":"bastion","pos":"NOM"} ,
		{"word":"baston","word_nosc":"baston","lemma":"baston","pos":"NOM"} ,
		{"word":"bastonnade","word_nosc":"bastonnade","lemma":"bastonnade","pos":"NOM"} ,
		{"word":"bastonnades","word_nosc":"bastonnades","lemma":"bastonnade","pos":"NOM"} ,
		{"word":"bastonneurs","word_nosc":"bastonneurs","lemma":"bastonneur","pos":"NOM"} ,
		{"word":"bastons","word_nosc":"bastons","lemma":"baston","pos":"NOM"} ,
		{"word":"bastos","word_nosc":"bastos","lemma":"bastos","pos":"NOM"} ,
		{"word":"bastringue","word_nosc":"bastringue","lemma":"bastringue","pos":"NOM"} ,
		{"word":"bastringues","word_nosc":"bastringues","lemma":"bastringue","pos":"NOM"} ,
		{"word":"bat-flanc","word_nosc":"bat-flanc","lemma":"bat-flanc","pos":"NOM"} ,
		{"word":"bat-l'eau","word_nosc":"bat-l'eau","lemma":"bat-l'eau","pos":"NOM"} ,
		{"word":"bataclan","word_nosc":"bataclan","lemma":"bataclan","pos":"NOM"} ,
		{"word":"bataille","word_nosc":"bataille","lemma":"bataille","pos":"NOM"} ,
		{"word":"batailles","word_nosc":"batailles","lemma":"bataille","pos":"NOM"} ,
		{"word":"batailleur","word_nosc":"batailleur","lemma":"batailleur","pos":"NOM"} ,
		{"word":"bataillon","word_nosc":"bataillon","lemma":"bataillon","pos":"NOM"} ,
		{"word":"bataillonnaire","word_nosc":"bataillonnaire","lemma":"bataillonnaire","pos":"NOM"} ,
		{"word":"bataillonnaires","word_nosc":"bataillonnaires","lemma":"bataillonnaire","pos":"NOM"} ,
		{"word":"bataillons","word_nosc":"bataillons","lemma":"bataillon","pos":"NOM"} ,
		{"word":"batak","word_nosc":"batak","lemma":"batak","pos":"NOM"} ,
		{"word":"batardeau","word_nosc":"batardeau","lemma":"batardeau","pos":"NOM"} ,
		{"word":"bateau","word_nosc":"bateau","lemma":"bateau","pos":"NOM"} ,
		{"word":"bateau-citerne","word_nosc":"bateau-citerne","lemma":"bateau-citerne","pos":"NOM"} ,
		{"word":"bateau-lavoir","word_nosc":"bateau-lavoir","lemma":"bateau-lavoir","pos":"NOM"} ,
		{"word":"bateau-mouche","word_nosc":"bateau-mouche","lemma":"bateau-mouche","pos":"NOM"} ,
		{"word":"bateau-pilote","word_nosc":"bateau-pilote","lemma":"bateau-pilote","pos":"NOM"} ,
		{"word":"bateau-pompe","word_nosc":"bateau-pompe","lemma":"bateau-pompe","pos":"NOM"} ,
		{"word":"bateaux","word_nosc":"bateaux","lemma":"bateau","pos":"NOM"} ,
		{"word":"bateaux-lavoirs","word_nosc":"bateaux-lavoirs","lemma":"bateau-lavoir","pos":"NOM"} ,
		{"word":"bateaux-mouches","word_nosc":"bateaux-mouches","lemma":"bateau-mouche","pos":"NOM"} ,
		{"word":"batelets","word_nosc":"batelets","lemma":"batelet","pos":"NOM"} ,
		{"word":"bateleur","word_nosc":"bateleur","lemma":"bateleur","pos":"NOM"} ,
		{"word":"bateleurs","word_nosc":"bateleurs","lemma":"bateleur","pos":"NOM"} ,
		{"word":"batelier","word_nosc":"batelier","lemma":"batelier","pos":"NOM"} ,
		{"word":"bateliers","word_nosc":"bateliers","lemma":"batelier","pos":"NOM"} ,
		{"word":"batellerie","word_nosc":"batellerie","lemma":"batellerie","pos":"NOM"} ,
		{"word":"bathymètre","word_nosc":"bathymetre","lemma":"bathymètre","pos":"NOM"} ,
		{"word":"bathyscaphe","word_nosc":"bathyscaphe","lemma":"bathyscaphe","pos":"NOM"} ,
		{"word":"bathysphère","word_nosc":"bathysphere","lemma":"bathysphère","pos":"NOM"} ,
		{"word":"batifolage","word_nosc":"batifolage","lemma":"batifolage","pos":"NOM"} ,
		{"word":"batifolages","word_nosc":"batifolages","lemma":"batifolage","pos":"NOM"} ,
		{"word":"batifoleurs","word_nosc":"batifoleurs","lemma":"batifoleur","pos":"NOM"} ,
		{"word":"batik","word_nosc":"batik","lemma":"batik","pos":"NOM"} ,
		{"word":"batiste","word_nosc":"batiste","lemma":"batiste","pos":"NOM"} ,
		{"word":"batracien","word_nosc":"batracien","lemma":"batracien","pos":"NOM"} ,
		{"word":"batraciens","word_nosc":"batraciens","lemma":"batracien","pos":"NOM"} ,
		{"word":"battage","word_nosc":"battage","lemma":"battage","pos":"NOM"} ,
		{"word":"battages","word_nosc":"battages","lemma":"battage","pos":"NOM"} ,
		{"word":"battant","word_nosc":"battant","lemma":"battant","pos":"NOM"} ,
		{"word":"battante","word_nosc":"battante","lemma":"battant","pos":"NOM"} ,
		{"word":"battants","word_nosc":"battants","lemma":"battant","pos":"NOM"} ,
		{"word":"batte","word_nosc":"batte","lemma":"batte","pos":"NOM"} ,
		{"word":"battement","word_nosc":"battement","lemma":"battement","pos":"NOM"} ,
		{"word":"battements","word_nosc":"battements","lemma":"battement","pos":"NOM"} ,
		{"word":"batterie","word_nosc":"batterie","lemma":"batterie","pos":"NOM"} ,
		{"word":"batteries","word_nosc":"batteries","lemma":"batterie","pos":"NOM"} ,
		{"word":"battes","word_nosc":"battes","lemma":"batte","pos":"NOM"} ,
		{"word":"batteur","word_nosc":"batteur","lemma":"batteur","pos":"NOM"} ,
		{"word":"batteurs","word_nosc":"batteurs","lemma":"batteur","pos":"NOM"} ,
		{"word":"batteuse","word_nosc":"batteuse","lemma":"batteur","pos":"NOM"} ,
		{"word":"batteuses","word_nosc":"batteuses","lemma":"batteur","pos":"NOM"} ,
		{"word":"battle-dress","word_nosc":"battle-dress","lemma":"battle-dress","pos":"NOM"} ,
		{"word":"battoir","word_nosc":"battoir","lemma":"battoir","pos":"NOM"} ,
		{"word":"battoires","word_nosc":"battoires","lemma":"battoire","pos":"NOM"} ,
		{"word":"battoirs","word_nosc":"battoirs","lemma":"battoir","pos":"NOM"} ,
		{"word":"battu","word_nosc":"battu","lemma":"battu","pos":"NOM"} ,
		{"word":"battue","word_nosc":"battue","lemma":"battue","pos":"NOM"} ,
		{"word":"battues","word_nosc":"battues","lemma":"battue","pos":"NOM"} ,
		{"word":"battus","word_nosc":"battus","lemma":"battu","pos":"NOM"} ,
		{"word":"batée","word_nosc":"batee","lemma":"batée","pos":"NOM"} ,
		{"word":"bau","word_nosc":"bau","lemma":"bau","pos":"NOM"} ,
		{"word":"baud","word_nosc":"baud","lemma":"baud","pos":"NOM"} ,
		{"word":"baudet","word_nosc":"baudet","lemma":"baudet","pos":"NOM"} ,
		{"word":"baudets","word_nosc":"baudets","lemma":"baudet","pos":"NOM"} ,
		{"word":"baudrier","word_nosc":"baudrier","lemma":"baudrier","pos":"NOM"} ,
		{"word":"baudriers","word_nosc":"baudriers","lemma":"baudrier","pos":"NOM"} ,
		{"word":"baudroie","word_nosc":"baudroie","lemma":"baudroie","pos":"NOM"} ,
		{"word":"baudroies","word_nosc":"baudroies","lemma":"baudroie","pos":"NOM"} ,
		{"word":"baudruche","word_nosc":"baudruche","lemma":"baudruche","pos":"NOM"} ,
		{"word":"baudruches","word_nosc":"baudruches","lemma":"baudruche","pos":"NOM"} ,
		{"word":"bauge","word_nosc":"bauge","lemma":"bauge","pos":"NOM"} ,
		{"word":"bauges","word_nosc":"bauges","lemma":"bauge","pos":"NOM"} ,
		{"word":"baume","word_nosc":"baume","lemma":"baume","pos":"NOM"} ,
		{"word":"baumes","word_nosc":"baumes","lemma":"baume","pos":"NOM"} ,
		{"word":"baumier","word_nosc":"baumier","lemma":"baumier","pos":"NOM"} ,
		{"word":"baumiers","word_nosc":"baumiers","lemma":"baumier","pos":"NOM"} ,
		{"word":"baux","word_nosc":"baux","lemma":"bail,bau","pos":"NOM"} ,
		{"word":"bauxite","word_nosc":"bauxite","lemma":"bauxite","pos":"NOM"} ,
		{"word":"bavard","word_nosc":"bavard","lemma":"bavard","pos":"NOM"} ,
		{"word":"bavardage","word_nosc":"bavardage","lemma":"bavardage","pos":"NOM"} ,
		{"word":"bavardages","word_nosc":"bavardages","lemma":"bavardage","pos":"NOM"} ,
		{"word":"bavarde","word_nosc":"bavarde","lemma":"bavard","pos":"NOM"} ,
		{"word":"bavardes","word_nosc":"bavardes","lemma":"bavard","pos":"NOM"} ,
		{"word":"bavards","word_nosc":"bavards","lemma":"bavard","pos":"NOM"} ,
		{"word":"bavarois","word_nosc":"bavarois","lemma":"bavarois","pos":"NOM"} ,
		{"word":"bavaroise","word_nosc":"bavaroise","lemma":"bavarois","pos":"NOM"} ,
		{"word":"bavaroises","word_nosc":"bavaroises","lemma":"bavarois","pos":"NOM"} ,
		{"word":"bave","word_nosc":"bave","lemma":"bave","pos":"NOM"} ,
		{"word":"baves","word_nosc":"baves","lemma":"bave","pos":"NOM"} ,
		{"word":"bavette","word_nosc":"bavette","lemma":"bavette","pos":"NOM"} ,
		{"word":"bavettes","word_nosc":"bavettes","lemma":"bavette","pos":"NOM"} ,
		{"word":"baveur","word_nosc":"baveur","lemma":"baveur","pos":"NOM"} ,
		{"word":"baveuse","word_nosc":"baveuse","lemma":"baveur","pos":"NOM"} ,
		{"word":"baveux","word_nosc":"baveux","lemma":"baveux","pos":"NOM"} ,
		{"word":"bavoir","word_nosc":"bavoir","lemma":"bavoir","pos":"NOM"} ,
		{"word":"bavoirs","word_nosc":"bavoirs","lemma":"bavoir","pos":"NOM"} ,
		{"word":"bavure","word_nosc":"bavure","lemma":"bavure","pos":"NOM"} ,
		{"word":"bavures","word_nosc":"bavures","lemma":"bavure","pos":"NOM"} ,
		{"word":"bayadère","word_nosc":"bayadere","lemma":"bayadère","pos":"NOM"} ,
		{"word":"bayadères","word_nosc":"bayaderes","lemma":"bayadère","pos":"NOM"} ,
		{"word":"bayard","word_nosc":"bayard","lemma":"bayard","pos":"NOM"} ,
		{"word":"bayou","word_nosc":"bayou","lemma":"bayou","pos":"NOM"} ,
		{"word":"bayous","word_nosc":"bayous","lemma":"bayou","pos":"NOM"} ,
		{"word":"bazar","word_nosc":"bazar","lemma":"bazar","pos":"NOM"} ,
		{"word":"bazard","word_nosc":"bazard","lemma":"bazard","pos":"NOM"} ,
		{"word":"bazardeur","word_nosc":"bazardeur","lemma":"bazardeur","pos":"NOM"} ,
		{"word":"bazars","word_nosc":"bazars","lemma":"bazar","pos":"NOM"} ,
		{"word":"bazooka","word_nosc":"bazooka","lemma":"bazooka","pos":"NOM"} ,
		{"word":"bazookas","word_nosc":"bazookas","lemma":"bazooka","pos":"NOM"} ,
		{"word":"baïonnette","word_nosc":"baionnette","lemma":"baïonnette","pos":"NOM"} ,
		{"word":"baïonnettes","word_nosc":"baionnettes","lemma":"baïonnette","pos":"NOM"} ,
		{"word":"be-bop","word_nosc":"be-bop","lemma":"be-bop","pos":"NOM"} ,
		{"word":"beagle","word_nosc":"beagle","lemma":"beagle","pos":"NOM"} ,
		{"word":"beagles","word_nosc":"beagles","lemma":"beagle","pos":"NOM"} ,
		{"word":"beat generation","word_nosc":"beat generation","lemma":"beat generation","pos":"NOM"} ,
		{"word":"beatnik","word_nosc":"beatnik","lemma":"beatnik","pos":"NOM"} ,
		{"word":"beatniks","word_nosc":"beatniks","lemma":"beatnik","pos":"NOM"} ,
		{"word":"beau","word_nosc":"beau","lemma":"beau","pos":"NOM"} ,
		{"word":"beau-fils","word_nosc":"beau-fils","lemma":"beau-fils","pos":"NOM"} ,
		{"word":"beau-frère","word_nosc":"beau-frere","lemma":"beau-frère","pos":"NOM"} ,
		{"word":"beau-papa","word_nosc":"beau-papa","lemma":"beau-papa","pos":"NOM"} ,
		{"word":"beau-parent","word_nosc":"beau-parent","lemma":"beau-parent","pos":"NOM"} ,
		{"word":"beau-père","word_nosc":"beau-pere","lemma":"beau-père","pos":"NOM"} ,
		{"word":"beauf","word_nosc":"beauf","lemma":"beauf","pos":"NOM"} ,
		{"word":"beaufs","word_nosc":"beaufs","lemma":"beauf","pos":"NOM"} ,
		{"word":"beaujolais","word_nosc":"beaujolais","lemma":"beaujolais","pos":"NOM"} ,
		{"word":"beaujolpif","word_nosc":"beaujolpif","lemma":"beaujolpif","pos":"NOM"} ,
		{"word":"beaupré","word_nosc":"beaupre","lemma":"beaupré","pos":"NOM"} ,
		{"word":"beauté","word_nosc":"beaute","lemma":"beauté","pos":"NOM"} ,
		{"word":"beautés","word_nosc":"beautes","lemma":"beauté","pos":"NOM"} ,
		{"word":"beauvais","word_nosc":"beauvais","lemma":"beauvais","pos":"NOM"} ,
		{"word":"beaux-arts","word_nosc":"beaux-arts","lemma":"beaux-arts","pos":"NOM"} ,
		{"word":"beaux-enfants","word_nosc":"beaux-enfants","lemma":"beaux-enfants","pos":"NOM"} ,
		{"word":"beaux-fils","word_nosc":"beaux-fils","lemma":"beau-fils","pos":"NOM"} ,
		{"word":"beaux-frères","word_nosc":"beaux-freres","lemma":"beau-frère","pos":"NOM"} ,
		{"word":"beaux-parents","word_nosc":"beaux-parents","lemma":"beaux-parents","pos":"NOM"} ,
		{"word":"bec","word_nosc":"bec","lemma":"bec","pos":"NOM"} ,
		{"word":"bec-de-cane","word_nosc":"bec-de-cane","lemma":"bec-de-cane","pos":"NOM"} ,
		{"word":"bec-de-lièvre","word_nosc":"bec-de-lievre","lemma":"bec-de-lièvre","pos":"NOM"} ,
		{"word":"becfigue","word_nosc":"becfigue","lemma":"becfigue","pos":"NOM"} ,
		{"word":"becfigues","word_nosc":"becfigues","lemma":"becfigue","pos":"NOM"} ,
		{"word":"becquet","word_nosc":"becquet","lemma":"becquet","pos":"NOM"} ,
		{"word":"becquetance","word_nosc":"becquetance","lemma":"becquetance","pos":"NOM"} ,
		{"word":"becquets","word_nosc":"becquets","lemma":"becquet","pos":"NOM"} ,
		{"word":"becquée","word_nosc":"becquee","lemma":"becquée","pos":"NOM"} ,
		{"word":"becs","word_nosc":"becs","lemma":"bec","pos":"NOM"} ,
		{"word":"bectance","word_nosc":"bectance","lemma":"bectance","pos":"NOM"} ,
		{"word":"bedaine","word_nosc":"bedaine","lemma":"bedaine","pos":"NOM"} ,
		{"word":"bedaines","word_nosc":"bedaines","lemma":"bedaine","pos":"NOM"} ,
		{"word":"bedeau","word_nosc":"bedeau","lemma":"bedeau","pos":"NOM"} ,
		{"word":"bedeaux","word_nosc":"bedeaux","lemma":"bedeau","pos":"NOM"} ,
		{"word":"bedon","word_nosc":"bedon","lemma":"bedon","pos":"NOM"} ,
		{"word":"bedons","word_nosc":"bedons","lemma":"bedon","pos":"NOM"} ,
		{"word":"beefsteak","word_nosc":"beefsteak","lemma":"beefsteak","pos":"NOM"} ,
		{"word":"beefsteaks","word_nosc":"beefsteaks","lemma":"beefsteak","pos":"NOM"} ,
		{"word":"beeper","word_nosc":"beeper","lemma":"beeper","pos":"NOM"} ,
		{"word":"beffroi","word_nosc":"beffroi","lemma":"beffroi","pos":"NOM"} ,
		{"word":"behavioriste","word_nosc":"behavioriste","lemma":"behavioriste","pos":"NOM"} ,
		{"word":"beige","word_nosc":"beige","lemma":"beige","pos":"NOM"} ,
		{"word":"beiges","word_nosc":"beiges","lemma":"beige","pos":"NOM"} ,
		{"word":"beigne","word_nosc":"beigne","lemma":"beigne","pos":"NOM"} ,
		{"word":"beignes","word_nosc":"beignes","lemma":"beigne","pos":"NOM"} ,
		{"word":"beignet","word_nosc":"beignet","lemma":"beignet","pos":"NOM"} ,
		{"word":"beignets","word_nosc":"beignets","lemma":"beignet","pos":"NOM"} ,
		{"word":"bel canto","word_nosc":"bel canto","lemma":"bel canto","pos":"NOM"} ,
		{"word":"belette","word_nosc":"belette","lemma":"belette","pos":"NOM"} ,
		{"word":"belettes","word_nosc":"belettes","lemma":"belette","pos":"NOM"} ,
		{"word":"belge","word_nosc":"belge","lemma":"belge","pos":"NOM"} ,
		{"word":"belges","word_nosc":"belges","lemma":"belge","pos":"NOM"} ,
		{"word":"belgicains","word_nosc":"belgicains","lemma":"belgicain","pos":"NOM"} ,
		{"word":"belgique","word_nosc":"belgique","lemma":"belgique","pos":"NOM"} ,
		{"word":"belladone","word_nosc":"belladone","lemma":"belladone","pos":"NOM"} ,
		{"word":"belladones","word_nosc":"belladones","lemma":"belladone","pos":"NOM"} ,
		{"word":"belle","word_nosc":"belle","lemma":"beau","pos":"NOM"} ,
		{"word":"belle lurette","word_nosc":"belle lurette","lemma":"belle lurette","pos":"NOM"} ,
		{"word":"belle-doche","word_nosc":"belle-doche","lemma":"belle-doche","pos":"NOM"} ,
		{"word":"belle-famille","word_nosc":"belle-famille","lemma":"belle-famille","pos":"NOM"} ,
		{"word":"belle-fille","word_nosc":"belle-fille","lemma":"belle-fille","pos":"NOM"} ,
		{"word":"belle-maman","word_nosc":"belle-maman","lemma":"belle-maman","pos":"NOM"} ,
		{"word":"belle-mère","word_nosc":"belle-mere","lemma":"beau-père","pos":"NOM"} ,
		{"word":"belle-soeur","word_nosc":"belle-soeur","lemma":"beau-frère","pos":"NOM"} ,
		{"word":"belle-à-voir","word_nosc":"belle-a-voir","lemma":"belle-à-voir","pos":"NOM"} ,
		{"word":"belles","word_nosc":"belles","lemma":"beau","pos":"NOM"} ,
		{"word":"belles-de-jour","word_nosc":"belles-de-jour","lemma":"belle-de-jour","pos":"NOM"} ,
		{"word":"belles-de-nuit","word_nosc":"belles-de-nuit","lemma":"belle-de-nuit","pos":"NOM"} ,
		{"word":"belles-familles","word_nosc":"belles-familles","lemma":"belle-famille","pos":"NOM"} ,
		{"word":"belles-filles","word_nosc":"belles-filles","lemma":"belle-fille","pos":"NOM"} ,
		{"word":"belles-lettres","word_nosc":"belles-lettres","lemma":"belle-lettre","pos":"NOM"} ,
		{"word":"belles-mères","word_nosc":"belles-meres","lemma":"beau-père","pos":"NOM"} ,
		{"word":"belles-soeurs","word_nosc":"belles-soeurs","lemma":"beau-frère","pos":"NOM"} ,
		{"word":"belliciste","word_nosc":"belliciste","lemma":"belliciste","pos":"NOM"} ,
		{"word":"bellicistes","word_nosc":"bellicistes","lemma":"belliciste","pos":"NOM"} ,
		{"word":"belligérance","word_nosc":"belligerance","lemma":"belligérance","pos":"NOM"} ,
		{"word":"belligérant","word_nosc":"belligerant","lemma":"belligérant","pos":"NOM"} ,
		{"word":"belligérante","word_nosc":"belligerante","lemma":"belligérant","pos":"NOM"} ,
		{"word":"belligérants","word_nosc":"belligerants","lemma":"belligérant","pos":"NOM"} ,
		{"word":"belluaire","word_nosc":"belluaire","lemma":"belluaire","pos":"NOM"} ,
		{"word":"bellâtre","word_nosc":"bellatre","lemma":"bellâtre","pos":"NOM"} ,
		{"word":"belon","word_nosc":"belon","lemma":"belon","pos":"NOM"} ,
		{"word":"belons","word_nosc":"belons","lemma":"belon","pos":"NOM"} ,
		{"word":"belote","word_nosc":"belote","lemma":"belote","pos":"NOM"} ,
		{"word":"belotes","word_nosc":"belotes","lemma":"belote","pos":"NOM"} ,
		{"word":"beloteurs","word_nosc":"beloteurs","lemma":"beloteur","pos":"NOM"} ,
		{"word":"beluga","word_nosc":"beluga","lemma":"beluga","pos":"NOM"} ,
		{"word":"belvédère","word_nosc":"belvedere","lemma":"belvédère","pos":"NOM"} ,
		{"word":"belvédères","word_nosc":"belvederes","lemma":"belvédère","pos":"NOM"} ,
		{"word":"ben","word_nosc":"ben","lemma":"ben","pos":"NOM"} ,
		{"word":"benedictus","word_nosc":"benedictus","lemma":"benedictus","pos":"NOM"} ,
		{"word":"bengalais","word_nosc":"bengalais","lemma":"bengalais","pos":"NOM"} ,
		{"word":"bengali","word_nosc":"bengali","lemma":"bengali","pos":"NOM"} ,
		{"word":"bengalis","word_nosc":"bengalis","lemma":"bengali","pos":"NOM"} ,
		{"word":"benjamin","word_nosc":"benjamin","lemma":"benjamin","pos":"NOM"} ,
		{"word":"benjamine","word_nosc":"benjamine","lemma":"benjamin","pos":"NOM"} ,
		{"word":"benjamins","word_nosc":"benjamins","lemma":"benjamin","pos":"NOM"} ,
		{"word":"benjoin","word_nosc":"benjoin","lemma":"benjoin","pos":"NOM"} ,
		{"word":"benne","word_nosc":"benne","lemma":"benne","pos":"NOM"} ,
		{"word":"bennes","word_nosc":"bennes","lemma":"benne","pos":"NOM"} ,
		{"word":"benoîte","word_nosc":"benoite","lemma":"benoîte","pos":"NOM"} ,
		{"word":"benzidine","word_nosc":"benzidine","lemma":"benzidine","pos":"NOM"} ,
		{"word":"benzine","word_nosc":"benzine","lemma":"benzine","pos":"NOM"} ,
		{"word":"benzoate","word_nosc":"benzoate","lemma":"benzoate","pos":"NOM"} ,
		{"word":"benzodiazépine","word_nosc":"benzodiazepine","lemma":"benzodiazépine","pos":"NOM"} ,
		{"word":"benzonaphtol","word_nosc":"benzonaphtol","lemma":"benzonaphtol","pos":"NOM"} ,
		{"word":"benzène","word_nosc":"benzene","lemma":"benzène","pos":"NOM"} ,
		{"word":"benzédrine","word_nosc":"benzedrine","lemma":"benzédrine","pos":"NOM"} ,
		{"word":"benêt","word_nosc":"benet","lemma":"benêt","pos":"NOM"} ,
		{"word":"benêts","word_nosc":"benets","lemma":"benêt","pos":"NOM"} ,
		{"word":"ber","word_nosc":"ber","lemma":"ber","pos":"NOM"} ,
		{"word":"berbère","word_nosc":"berbere","lemma":"berbère","pos":"NOM"} ,
		{"word":"berbères","word_nosc":"berberes","lemma":"berbère","pos":"NOM"} ,
		{"word":"bercail","word_nosc":"bercail","lemma":"bercail","pos":"NOM"} ,
		{"word":"berce","word_nosc":"berce","lemma":"berce","pos":"NOM"} ,
		{"word":"berceau","word_nosc":"berceau","lemma":"berceau","pos":"NOM"} ,
		{"word":"berceaux","word_nosc":"berceaux","lemma":"berceau","pos":"NOM"} ,
		{"word":"bercelonnette","word_nosc":"bercelonnette","lemma":"bercelonnette","pos":"NOM"} ,
		{"word":"bercement","word_nosc":"bercement","lemma":"bercement","pos":"NOM"} ,
		{"word":"bercements","word_nosc":"bercements","lemma":"bercement","pos":"NOM"} ,
		{"word":"berces","word_nosc":"berces","lemma":"berce","pos":"NOM"} ,
		{"word":"berceuse","word_nosc":"berceuse","lemma":"berceur","pos":"NOM"} ,
		{"word":"berceuses","word_nosc":"berceuses","lemma":"berceur","pos":"NOM"} ,
		{"word":"berdouillette","word_nosc":"berdouillette","lemma":"berdouillette","pos":"NOM"} ,
		{"word":"berg","word_nosc":"berg","lemma":"berg","pos":"NOM"} ,
		{"word":"bergamasques","word_nosc":"bergamasques","lemma":"bergamasque","pos":"NOM"} ,
		{"word":"bergamote","word_nosc":"bergamote","lemma":"bergamote","pos":"NOM"} ,
		{"word":"berge","word_nosc":"berge","lemma":"berge","pos":"NOM"} ,
		{"word":"berger","word_nosc":"berger","lemma":"berger","pos":"NOM"} ,
		{"word":"bergerie","word_nosc":"bergerie","lemma":"bergerie","pos":"NOM"} ,
		{"word":"bergeries","word_nosc":"bergeries","lemma":"bergerie","pos":"NOM"} ,
		{"word":"bergeronnette","word_nosc":"bergeronnette","lemma":"bergeronnette","pos":"NOM"} ,
		{"word":"bergeronnettes","word_nosc":"bergeronnettes","lemma":"bergeronnette","pos":"NOM"} ,
		{"word":"bergers","word_nosc":"bergers","lemma":"berger","pos":"NOM"} ,
		{"word":"berges","word_nosc":"berges","lemma":"berge","pos":"NOM"} ,
		{"word":"bergère","word_nosc":"bergere","lemma":"berger","pos":"NOM"} ,
		{"word":"bergères","word_nosc":"bergeres","lemma":"berger","pos":"NOM"} ,
		{"word":"berle","word_nosc":"berle","lemma":"berle","pos":"NOM"} ,
		{"word":"berlin","word_nosc":"berlin","lemma":"berlin","pos":"NOM"} ,
		{"word":"berline","word_nosc":"berline","lemma":"berline","pos":"NOM"} ,
		{"word":"berlines","word_nosc":"berlines","lemma":"berline","pos":"NOM"} ,
		{"word":"berlingot","word_nosc":"berlingot","lemma":"berlingot","pos":"NOM"} ,
		{"word":"berlingots","word_nosc":"berlingots","lemma":"berlingot","pos":"NOM"} ,
		{"word":"berlingue","word_nosc":"berlingue","lemma":"berlingue","pos":"NOM"} ,
		{"word":"berlinois","word_nosc":"berlinois","lemma":"berlinois","pos":"NOM"} ,
		{"word":"berlinoises","word_nosc":"berlinoises","lemma":"berlinois","pos":"NOM"} ,
		{"word":"berloque","word_nosc":"berloque","lemma":"berloque","pos":"NOM"} ,
		{"word":"berlue","word_nosc":"berlue","lemma":"berlue","pos":"NOM"} ,
		{"word":"berlues","word_nosc":"berlues","lemma":"berlue","pos":"NOM"} ,
		{"word":"berlure","word_nosc":"berlure","lemma":"berlure","pos":"NOM"} ,
		{"word":"berlures","word_nosc":"berlures","lemma":"berlure","pos":"NOM"} ,
		{"word":"berme","word_nosc":"berme","lemma":"berme","pos":"NOM"} ,
		{"word":"bermes","word_nosc":"bermes","lemma":"berme","pos":"NOM"} ,
		{"word":"bermuda","word_nosc":"bermuda","lemma":"bermuda","pos":"NOM"} ,
		{"word":"bermudas","word_nosc":"bermudas","lemma":"bermuda","pos":"NOM"} ,
		{"word":"bernache","word_nosc":"bernache","lemma":"bernache","pos":"NOM"} ,
		{"word":"bernaches","word_nosc":"bernaches","lemma":"bernache","pos":"NOM"} ,
		{"word":"bernard-l'ermite","word_nosc":"bernard-l'ermite","lemma":"bernard-l'ermite","pos":"NOM"} ,
		{"word":"bernard-l'hermite","word_nosc":"bernard-l'hermite","lemma":"bernard-l'hermite","pos":"NOM"} ,
		{"word":"bernardines","word_nosc":"bernardines","lemma":"bernardin","pos":"NOM"} ,
		{"word":"berne","word_nosc":"berne","lemma":"berne","pos":"NOM"} ,
		{"word":"bernicle","word_nosc":"bernicle","lemma":"bernicle","pos":"NOM"} ,
		{"word":"bernicles","word_nosc":"bernicles","lemma":"bernicle","pos":"NOM"} ,
		{"word":"bernique","word_nosc":"bernique","lemma":"bernique","pos":"NOM"} ,
		{"word":"berniques","word_nosc":"berniques","lemma":"bernique","pos":"NOM"} ,
		{"word":"bernois","word_nosc":"bernois","lemma":"bernois","pos":"NOM"} ,
		{"word":"bernoise","word_nosc":"bernoise","lemma":"bernois","pos":"NOM"} ,
		{"word":"bersaglier","word_nosc":"bersaglier","lemma":"bersaglier","pos":"NOM"} ,
		{"word":"bersagliers","word_nosc":"bersagliers","lemma":"bersaglier","pos":"NOM"} ,
		{"word":"bertha","word_nosc":"bertha","lemma":"bertha","pos":"NOM"} ,
		{"word":"berthas","word_nosc":"berthas","lemma":"bertha","pos":"NOM"} ,
		{"word":"berthe","word_nosc":"berthe","lemma":"berthe","pos":"NOM"} ,
		{"word":"berzingue","word_nosc":"berzingue","lemma":"berzingue","pos":"NOM"} ,
		{"word":"besace","word_nosc":"besace","lemma":"besace","pos":"NOM"} ,
		{"word":"besaces","word_nosc":"besaces","lemma":"besace","pos":"NOM"} ,
		{"word":"besaiguë","word_nosc":"besaigue","lemma":"besaiguë","pos":"NOM"} ,
		{"word":"besant","word_nosc":"besant","lemma":"besant","pos":"NOM"} ,
		{"word":"besants","word_nosc":"besants","lemma":"besant","pos":"NOM"} ,
		{"word":"besicles","word_nosc":"besicles","lemma":"besicles","pos":"NOM"} ,
		{"word":"besogne","word_nosc":"besogne","lemma":"besogne","pos":"NOM"} ,
		{"word":"besognes","word_nosc":"besognes","lemma":"besogne","pos":"NOM"} ,
		{"word":"besogneux","word_nosc":"besogneux","lemma":"besogneux","pos":"NOM"} ,
		{"word":"besoin","word_nosc":"besoin","lemma":"besoin","pos":"NOM"} ,
		{"word":"besoins","word_nosc":"besoins","lemma":"besoin","pos":"NOM"} ,
		{"word":"besson","word_nosc":"besson","lemma":"besson","pos":"NOM"} ,
		{"word":"bessons","word_nosc":"bessons","lemma":"besson","pos":"NOM"} ,
		{"word":"best","word_nosc":"best","lemma":"best","pos":"NOM"} ,
		{"word":"best of","word_nosc":"best of","lemma":"best of","pos":"NOM"} ,
		{"word":"best-seller","word_nosc":"best-seller","lemma":"best-seller","pos":"NOM"} ,
		{"word":"best-sellers","word_nosc":"best-sellers","lemma":"best-seller","pos":"NOM"} ,
		{"word":"bestiaire","word_nosc":"bestiaire","lemma":"bestiaire","pos":"NOM"} ,
		{"word":"bestiaires","word_nosc":"bestiaires","lemma":"bestiaire","pos":"NOM"} ,
		{"word":"bestialité","word_nosc":"bestialite","lemma":"bestialité","pos":"NOM"} ,
		{"word":"bestialités","word_nosc":"bestialites","lemma":"bestialité","pos":"NOM"} ,
		{"word":"bestiasse","word_nosc":"bestiasse","lemma":"bestiasse","pos":"NOM"} ,
		{"word":"bestiau","word_nosc":"bestiau","lemma":"bestiau","pos":"NOM"} ,
		{"word":"bestiaux","word_nosc":"bestiaux","lemma":"bestiau","pos":"NOM"} ,
		{"word":"bestiole","word_nosc":"bestiole","lemma":"bestiole","pos":"NOM"} ,
		{"word":"bestioles","word_nosc":"bestioles","lemma":"bestiole","pos":"NOM"} ,
		{"word":"bette","word_nosc":"bette","lemma":"bette","pos":"NOM"} ,
		{"word":"betterave","word_nosc":"betterave","lemma":"betterave","pos":"NOM"} ,
		{"word":"betteraves","word_nosc":"betteraves","lemma":"betterave","pos":"NOM"} ,
		{"word":"betteravier","word_nosc":"betteravier","lemma":"betteravier","pos":"NOM"} ,
		{"word":"bettes","word_nosc":"bettes","lemma":"bette","pos":"NOM"} ,
		{"word":"betting","word_nosc":"betting","lemma":"betting","pos":"NOM"} ,
		{"word":"beuglant","word_nosc":"beuglant","lemma":"beuglant","pos":"NOM"} ,
		{"word":"beuglante","word_nosc":"beuglante","lemma":"beuglant","pos":"NOM"} ,
		{"word":"beuglantes","word_nosc":"beuglantes","lemma":"beuglant","pos":"NOM"} ,
		{"word":"beuglants","word_nosc":"beuglants","lemma":"beuglant","pos":"NOM"} ,
		{"word":"beuglement","word_nosc":"beuglement","lemma":"beuglement","pos":"NOM"} ,
		{"word":"beuglements","word_nosc":"beuglements","lemma":"beuglement","pos":"NOM"} ,
		{"word":"beur","word_nosc":"beur","lemma":"beur","pos":"NOM"} ,
		{"word":"beurrage","word_nosc":"beurrage","lemma":"beurrage","pos":"NOM"} ,
		{"word":"beurre","word_nosc":"beurre","lemma":"beurre","pos":"NOM"} ,
		{"word":"beurres","word_nosc":"beurres","lemma":"beurre","pos":"NOM"} ,
		{"word":"beurrier","word_nosc":"beurrier","lemma":"beurrier","pos":"NOM"} ,
		{"word":"beurré","word_nosc":"beurre","lemma":"beurré","pos":"NOM"} ,
		{"word":"beurrée","word_nosc":"beurree","lemma":"beurré","pos":"NOM"} ,
		{"word":"beurrées","word_nosc":"beurrees","lemma":"beurré","pos":"NOM"} ,
		{"word":"beurrés","word_nosc":"beurres","lemma":"beurré","pos":"NOM"} ,
		{"word":"beuverie","word_nosc":"beuverie","lemma":"beuverie","pos":"NOM"} ,
		{"word":"beuveries","word_nosc":"beuveries","lemma":"beuverie","pos":"NOM"} ,
		{"word":"bey","word_nosc":"bey","lemma":"bey","pos":"NOM"} ,
		{"word":"bi","word_nosc":"bi","lemma":"bi","pos":"NOM"} ,
		{"word":"biafrais","word_nosc":"biafrais","lemma":"biafrais","pos":"NOM"} ,
		{"word":"biais","word_nosc":"biais","lemma":"biais","pos":"NOM"} ,
		{"word":"biaisements","word_nosc":"biaisements","lemma":"biaisement","pos":"NOM"} ,
		{"word":"biathlon","word_nosc":"biathlon","lemma":"biathlon","pos":"NOM"} ,
		{"word":"bib","word_nosc":"bib","lemma":"bib","pos":"NOM"} ,
		{"word":"bibard","word_nosc":"bibard","lemma":"bibard","pos":"NOM"} ,
		{"word":"bibelot","word_nosc":"bibelot","lemma":"bibelot","pos":"NOM"} ,
		{"word":"bibelots","word_nosc":"bibelots","lemma":"bibelot","pos":"NOM"} ,
		{"word":"bibendum","word_nosc":"bibendum","lemma":"bibendum","pos":"NOM"} ,
		{"word":"biberon","word_nosc":"biberon","lemma":"biberon","pos":"NOM"} ,
		{"word":"biberonneurs","word_nosc":"biberonneurs","lemma":"biberonneur","pos":"NOM"} ,
		{"word":"biberons","word_nosc":"biberons","lemma":"biberon","pos":"NOM"} ,
		{"word":"bibi","word_nosc":"bibi","lemma":"bibi","pos":"NOM"} ,
		{"word":"bibiche","word_nosc":"bibiche","lemma":"bibiche","pos":"NOM"} ,
		{"word":"bibine","word_nosc":"bibine","lemma":"bibine","pos":"NOM"} ,
		{"word":"bibines","word_nosc":"bibines","lemma":"bibine","pos":"NOM"} ,
		{"word":"bibis","word_nosc":"bibis","lemma":"bibi","pos":"NOM"} ,
		{"word":"bibite","word_nosc":"bibite","lemma":"bibite","pos":"NOM"} ,
		{"word":"bible","word_nosc":"bible","lemma":"bible","pos":"NOM"} ,
		{"word":"bibles","word_nosc":"bibles","lemma":"bible","pos":"NOM"} ,
		{"word":"bibliobus","word_nosc":"bibliobus","lemma":"bibliobus","pos":"NOM"} ,
		{"word":"bibliographe","word_nosc":"bibliographe","lemma":"bibliographe","pos":"NOM"} ,
		{"word":"bibliographie","word_nosc":"bibliographie","lemma":"bibliographie","pos":"NOM"} ,
		{"word":"bibliographies","word_nosc":"bibliographies","lemma":"bibliographie","pos":"NOM"} ,
		{"word":"bibliophile","word_nosc":"bibliophile","lemma":"bibliophile","pos":"NOM"} ,
		{"word":"bibliophiles","word_nosc":"bibliophiles","lemma":"bibliophile","pos":"NOM"} ,
		{"word":"bibliophilie","word_nosc":"bibliophilie","lemma":"bibliophilie","pos":"NOM"} ,
		{"word":"bibliothèque","word_nosc":"bibliotheque","lemma":"bibliothèque","pos":"NOM"} ,
		{"word":"bibliothèques","word_nosc":"bibliotheques","lemma":"bibliothèque","pos":"NOM"} ,
		{"word":"bibliothécaire","word_nosc":"bibliothecaire","lemma":"bibliothécaire","pos":"NOM"} ,
		{"word":"bibliothécaires","word_nosc":"bibliothecaires","lemma":"bibliothécaire","pos":"NOM"} ,
		{"word":"bic","word_nosc":"bic","lemma":"bic","pos":"NOM"} ,
		{"word":"bicarbonate","word_nosc":"bicarbonate","lemma":"bicarbonate","pos":"NOM"} ,
		{"word":"bicarbonates","word_nosc":"bicarbonates","lemma":"bicarbonate","pos":"NOM"} ,
		{"word":"bicentenaire","word_nosc":"bicentenaire","lemma":"bicentenaire","pos":"NOM"} ,
		{"word":"biceps","word_nosc":"biceps","lemma":"biceps","pos":"NOM"} ,
		{"word":"biche","word_nosc":"biche","lemma":"biche","pos":"NOM"} ,
		{"word":"biches","word_nosc":"biches","lemma":"biche","pos":"NOM"} ,
		{"word":"bichette","word_nosc":"bichette","lemma":"bichette","pos":"NOM"} ,
		{"word":"bichon","word_nosc":"bichon","lemma":"bichon","pos":"NOM"} ,
		{"word":"biclou","word_nosc":"biclou","lemma":"biclou","pos":"NOM"} ,
		{"word":"biclous","word_nosc":"biclous","lemma":"biclou","pos":"NOM"} ,
		{"word":"bicoque","word_nosc":"bicoque","lemma":"bicoque","pos":"NOM"} ,
		{"word":"bicoques","word_nosc":"bicoques","lemma":"bicoque","pos":"NOM"} ,
		{"word":"bicorne","word_nosc":"bicorne","lemma":"bicorne","pos":"NOM"} ,
		{"word":"bicornes","word_nosc":"bicornes","lemma":"bicorne","pos":"NOM"} ,
		{"word":"bicot","word_nosc":"bicot","lemma":"bicot","pos":"NOM"} ,
		{"word":"bicots","word_nosc":"bicots","lemma":"bicot","pos":"NOM"} ,
		{"word":"bicycle","word_nosc":"bicycle","lemma":"bicycle","pos":"NOM"} ,
		{"word":"bicycles","word_nosc":"bicycles","lemma":"bicycle","pos":"NOM"} ,
		{"word":"bicyclette","word_nosc":"bicyclette","lemma":"bicyclette","pos":"NOM"} ,
		{"word":"bicyclettes","word_nosc":"bicyclettes","lemma":"bicyclette","pos":"NOM"} ,
		{"word":"bicéphalie","word_nosc":"bicephalie","lemma":"bicéphalie","pos":"NOM"} ,
		{"word":"bidasse","word_nosc":"bidasse","lemma":"bidasse","pos":"NOM"} ,
		{"word":"bidasses","word_nosc":"bidasses","lemma":"bidasse","pos":"NOM"} ,
		{"word":"bide","word_nosc":"bide","lemma":"bide","pos":"NOM"} ,
		{"word":"bides","word_nosc":"bides","lemma":"bide","pos":"NOM"} ,
		{"word":"bidet","word_nosc":"bidet","lemma":"bidet","pos":"NOM"} ,
		{"word":"bidets","word_nosc":"bidets","lemma":"bidet","pos":"NOM"} ,
		{"word":"bidoche","word_nosc":"bidoche","lemma":"bidoche","pos":"NOM"} ,
		{"word":"bidoches","word_nosc":"bidoches","lemma":"bidoche","pos":"NOM"} ,
		{"word":"bidon","word_nosc":"bidon","lemma":"bidon","pos":"NOM"} ,
		{"word":"bidonnage","word_nosc":"bidonnage","lemma":"bidonnage","pos":"NOM"} ,
		{"word":"bidons","word_nosc":"bidons","lemma":"bidon","pos":"NOM"} ,
		{"word":"bidonville","word_nosc":"bidonville","lemma":"bidonville","pos":"NOM"} ,
		{"word":"bidonvilles","word_nosc":"bidonvilles","lemma":"bidonville","pos":"NOM"} ,
		{"word":"bidouillage","word_nosc":"bidouillage","lemma":"bidouillage","pos":"NOM"} ,
		{"word":"bidouille","word_nosc":"bidouille","lemma":"bidouille","pos":"NOM"} ,
		{"word":"bidule","word_nosc":"bidule","lemma":"bidule","pos":"NOM"} ,
		{"word":"bidules","word_nosc":"bidules","lemma":"bidule","pos":"NOM"} ,
		{"word":"bief","word_nosc":"bief","lemma":"bief","pos":"NOM"} ,
		{"word":"biefs","word_nosc":"biefs","lemma":"bief","pos":"NOM"} ,
		{"word":"bielle","word_nosc":"bielle","lemma":"bielle","pos":"NOM"} ,
		{"word":"bielles","word_nosc":"bielles","lemma":"bielle","pos":"NOM"} ,
		{"word":"bien","word_nosc":"bien","lemma":"bien","pos":"NOM"} ,
		{"word":"bien-aimé","word_nosc":"bien-aime","lemma":"bien-aimé","pos":"NOM"} ,
		{"word":"bien-aimée","word_nosc":"bien-aimee","lemma":"bien-aimé","pos":"NOM"} ,
		{"word":"bien-aimées","word_nosc":"bien-aimees","lemma":"bien-aimé","pos":"NOM"} ,
		{"word":"bien-aimés","word_nosc":"bien-aimes","lemma":"bien-aimé","pos":"NOM"} ,
		{"word":"bien-fonds","word_nosc":"bien-fonds","lemma":"bien-fonds","pos":"NOM"} ,
		{"word":"bien-fondé","word_nosc":"bien-fonde","lemma":"bien-fondé","pos":"NOM"} ,
		{"word":"bien-manger","word_nosc":"bien-manger","lemma":"bien-manger","pos":"NOM"} ,
		{"word":"bien-pensants","word_nosc":"bien-pensants","lemma":"bien-pensant","pos":"NOM"} ,
		{"word":"bien-être","word_nosc":"bien-etre","lemma":"bien-être","pos":"NOM"} ,
		{"word":"bienfaisance","word_nosc":"bienfaisance","lemma":"bienfaisance","pos":"NOM"} ,
		{"word":"bienfait","word_nosc":"bienfait","lemma":"bienfait","pos":"NOM"} ,
		{"word":"bienfaiteur","word_nosc":"bienfaiteur","lemma":"bienfaiteur","pos":"NOM"} ,
		{"word":"bienfaiteurs","word_nosc":"bienfaiteurs","lemma":"bienfaiteur","pos":"NOM"} ,
		{"word":"bienfaitrice","word_nosc":"bienfaitrice","lemma":"bienfaiteur","pos":"NOM"} ,
		{"word":"bienfaitrices","word_nosc":"bienfaitrices","lemma":"bienfaiteur","pos":"NOM"} ,
		{"word":"bienfaits","word_nosc":"bienfaits","lemma":"bienfait","pos":"NOM"} ,
		{"word":"bienheureuse","word_nosc":"bienheureuse","lemma":"bienheureux","pos":"NOM"} ,
		{"word":"bienheureux","word_nosc":"bienheureux","lemma":"bienheureux","pos":"NOM"} ,
		{"word":"biennale","word_nosc":"biennale","lemma":"biennale","pos":"NOM"} ,
		{"word":"biens","word_nosc":"biens","lemma":"bien","pos":"NOM"} ,
		{"word":"bienséance","word_nosc":"bienseance","lemma":"bienséance","pos":"NOM"} ,
		{"word":"bienséances","word_nosc":"bienseances","lemma":"bienséance","pos":"NOM"} ,
		{"word":"bienveillance","word_nosc":"bienveillance","lemma":"bienveillance","pos":"NOM"} ,
		{"word":"bienveillances","word_nosc":"bienveillances","lemma":"bienveillance","pos":"NOM"} ,
		{"word":"bienvenu","word_nosc":"bienvenu","lemma":"bienvenu","pos":"NOM"} ,
		{"word":"bienvenue","word_nosc":"bienvenue","lemma":"bienvenue","pos":"NOM"} ,
		{"word":"bienvenues","word_nosc":"bienvenues","lemma":"bienvenue","pos":"NOM"} ,
		{"word":"bienvenus","word_nosc":"bienvenus","lemma":"bienvenu","pos":"NOM"} ,
		{"word":"bif","word_nosc":"bif","lemma":"bif","pos":"NOM"} ,
		{"word":"biface","word_nosc":"biface","lemma":"biface","pos":"NOM"} ,
		{"word":"biffe","word_nosc":"biffe","lemma":"biffe","pos":"NOM"} ,
		{"word":"biffeton","word_nosc":"biffeton","lemma":"biffeton","pos":"NOM"} ,
		{"word":"biffetons","word_nosc":"biffetons","lemma":"biffeton","pos":"NOM"} ,
		{"word":"biffin","word_nosc":"biffin","lemma":"biffin","pos":"NOM"} ,
		{"word":"biffins","word_nosc":"biffins","lemma":"biffin","pos":"NOM"} ,
		{"word":"bifteck","word_nosc":"bifteck","lemma":"bifteck","pos":"NOM"} ,
		{"word":"biftecks","word_nosc":"biftecks","lemma":"bifteck","pos":"NOM"} ,
		{"word":"bifton","word_nosc":"bifton","lemma":"bifton","pos":"NOM"} ,
		{"word":"biftons","word_nosc":"biftons","lemma":"bifton","pos":"NOM"} ,
		{"word":"biftèque","word_nosc":"bifteque","lemma":"biftèque","pos":"NOM"} ,
		{"word":"biftèques","word_nosc":"bifteques","lemma":"biftèque","pos":"NOM"} ,
		{"word":"bifurcation","word_nosc":"bifurcation","lemma":"bifurcation","pos":"NOM"} ,
		{"word":"bifurcations","word_nosc":"bifurcations","lemma":"bifurcation","pos":"NOM"} ,
		{"word":"big band","word_nosc":"big band","lemma":"big band","pos":"NOM"} ,
		{"word":"big bang","word_nosc":"big bang","lemma":"big bang","pos":"NOM"} ,
		{"word":"big-bang","word_nosc":"big-bang","lemma":"big-bang","pos":"NOM"} ,
		{"word":"bigaille","word_nosc":"bigaille","lemma":"bigaille","pos":"NOM"} ,
		{"word":"bigame","word_nosc":"bigame","lemma":"bigame","pos":"NOM"} ,
		{"word":"bigamie","word_nosc":"bigamie","lemma":"bigamie","pos":"NOM"} ,
		{"word":"bigarreau","word_nosc":"bigarreau","lemma":"bigarreau","pos":"NOM"} ,
		{"word":"bigarrure","word_nosc":"bigarrure","lemma":"bigarrure","pos":"NOM"} ,
		{"word":"bigarrures","word_nosc":"bigarrures","lemma":"bigarrure","pos":"NOM"} ,
		{"word":"bighorn","word_nosc":"bighorn","lemma":"bighorn","pos":"NOM"} ,
		{"word":"bighorns","word_nosc":"bighorns","lemma":"bighorn","pos":"NOM"} ,
		{"word":"bigles","word_nosc":"bigles","lemma":"bigle","pos":"NOM"} ,
		{"word":"bigleuse","word_nosc":"bigleuse","lemma":"bigleux","pos":"NOM"} ,
		{"word":"bigleux","word_nosc":"bigleux","lemma":"bigleux","pos":"NOM"} ,
		{"word":"bigne","word_nosc":"bigne","lemma":"bigne","pos":"NOM"} ,
		{"word":"bignole","word_nosc":"bignole","lemma":"bignole","pos":"NOM"} ,
		{"word":"bignoles","word_nosc":"bignoles","lemma":"bignole","pos":"NOM"} ,
		{"word":"bignolle","word_nosc":"bignolle","lemma":"bignolle","pos":"NOM"} ,
		{"word":"bignon","word_nosc":"bignon","lemma":"bignon","pos":"NOM"} ,
		{"word":"bignones","word_nosc":"bignones","lemma":"bignone","pos":"NOM"} ,
		{"word":"bignonias","word_nosc":"bignonias","lemma":"bignonia","pos":"NOM"} ,
		{"word":"bigo","word_nosc":"bigo","lemma":"bigo","pos":"NOM"} ,
		{"word":"bigophone","word_nosc":"bigophone","lemma":"bigophone","pos":"NOM"} ,
		{"word":"bigorne","word_nosc":"bigorne","lemma":"bigorne","pos":"NOM"} ,
		{"word":"bigorneau","word_nosc":"bigorneau","lemma":"bigorneau","pos":"NOM"} ,
		{"word":"bigorneaux","word_nosc":"bigorneaux","lemma":"bigorneau","pos":"NOM"} ,
		{"word":"bigornes","word_nosc":"bigornes","lemma":"bigorne","pos":"NOM"} ,
		{"word":"bigot","word_nosc":"bigot","lemma":"bigot","pos":"NOM"} ,
		{"word":"bigote","word_nosc":"bigote","lemma":"bigot","pos":"NOM"} ,
		{"word":"bigoterie","word_nosc":"bigoterie","lemma":"bigoterie","pos":"NOM"} ,
		{"word":"bigotes","word_nosc":"bigotes","lemma":"bigot","pos":"NOM"} ,
		{"word":"bigots","word_nosc":"bigots","lemma":"bigot","pos":"NOM"} ,
		{"word":"bigoudens","word_nosc":"bigoudens","lemma":"bigouden","pos":"NOM"} ,
		{"word":"bigoudi","word_nosc":"bigoudi","lemma":"bigoudi","pos":"NOM"} ,
		{"word":"bigoudis","word_nosc":"bigoudis","lemma":"bigoudi","pos":"NOM"} ,
		{"word":"bigre","word_nosc":"bigre","lemma":"bigre","pos":"NOM"} ,
		{"word":"bigue","word_nosc":"bigue","lemma":"bigue","pos":"NOM"} ,
		{"word":"biguine","word_nosc":"biguine","lemma":"biguine","pos":"NOM"} ,
		{"word":"bihoreau","word_nosc":"bihoreau","lemma":"bihoreau","pos":"NOM"} ,
		{"word":"bijou","word_nosc":"bijou","lemma":"bijou","pos":"NOM"} ,
		{"word":"bijouterie","word_nosc":"bijouterie","lemma":"bijouterie","pos":"NOM"} ,
		{"word":"bijouteries","word_nosc":"bijouteries","lemma":"bijouterie","pos":"NOM"} ,
		{"word":"bijoutier","word_nosc":"bijoutier","lemma":"bijoutier","pos":"NOM"} ,
		{"word":"bijoutiers","word_nosc":"bijoutiers","lemma":"bijoutier","pos":"NOM"} ,
		{"word":"bijoutière","word_nosc":"bijoutiere","lemma":"bijoutier","pos":"NOM"} ,
		{"word":"bijoux","word_nosc":"bijoux","lemma":"bijoux","pos":"NOM"} ,
		{"word":"bikini","word_nosc":"bikini","lemma":"bikini","pos":"NOM"} ,
		{"word":"bikinis","word_nosc":"bikinis","lemma":"bikini","pos":"NOM"} ,
		{"word":"bilan","word_nosc":"bilan","lemma":"bilan","pos":"NOM"} ,
		{"word":"bilans","word_nosc":"bilans","lemma":"bilan","pos":"NOM"} ,
		{"word":"bilbergia","word_nosc":"bilbergia","lemma":"bilbergia","pos":"NOM"} ,
		{"word":"bilboquet","word_nosc":"bilboquet","lemma":"bilboquet","pos":"NOM"} ,
		{"word":"bilboquets","word_nosc":"bilboquets","lemma":"bilboquet","pos":"NOM"} ,
		{"word":"bile","word_nosc":"bile","lemma":"bile","pos":"NOM"} ,
		{"word":"biles","word_nosc":"biles","lemma":"bile","pos":"NOM"} ,
		{"word":"bilharziose","word_nosc":"bilharziose","lemma":"bilharziose","pos":"NOM"} ,
		{"word":"bilingue","word_nosc":"bilingue","lemma":"bilingue","pos":"NOM"} ,
		{"word":"bilingues","word_nosc":"bilingues","lemma":"bilingue","pos":"NOM"} ,
		{"word":"bilinguisme","word_nosc":"bilinguisme","lemma":"bilinguisme","pos":"NOM"} ,
		{"word":"bilirubine","word_nosc":"bilirubine","lemma":"bilirubine","pos":"NOM"} ,
		{"word":"bill","word_nosc":"bill","lemma":"bill","pos":"NOM"} ,
		{"word":"billard","word_nosc":"billard","lemma":"billard","pos":"NOM"} ,
		{"word":"billards","word_nosc":"billards","lemma":"billard","pos":"NOM"} ,
		{"word":"bille","word_nosc":"bille","lemma":"bille","pos":"NOM"} ,
		{"word":"billes","word_nosc":"billes","lemma":"bille","pos":"NOM"} ,
		{"word":"billet","word_nosc":"billet","lemma":"billet","pos":"NOM"} ,
		{"word":"billets","word_nosc":"billets","lemma":"billet","pos":"NOM"} ,
		{"word":"billetterie","word_nosc":"billetterie","lemma":"billetterie","pos":"NOM"} ,
		{"word":"billetteries","word_nosc":"billetteries","lemma":"billetterie","pos":"NOM"} ,
		{"word":"billettes","word_nosc":"billettes","lemma":"billette","pos":"NOM"} ,
		{"word":"billevesée","word_nosc":"billevesee","lemma":"billevesée","pos":"NOM"} ,
		{"word":"billevesées","word_nosc":"billevesees","lemma":"billevesée","pos":"NOM"} ,
		{"word":"billion","word_nosc":"billion","lemma":"billion","pos":"NOM"} ,
		{"word":"billions","word_nosc":"billions","lemma":"billion","pos":"NOM"} ,
		{"word":"billon","word_nosc":"billon","lemma":"billon","pos":"NOM"} ,
		{"word":"billot","word_nosc":"billot","lemma":"billot","pos":"NOM"} ,
		{"word":"billots","word_nosc":"billots","lemma":"billot","pos":"NOM"} ,
		{"word":"bills","word_nosc":"bills","lemma":"bill","pos":"NOM"} ,
		{"word":"bimbeloterie","word_nosc":"bimbeloterie","lemma":"bimbeloterie","pos":"NOM"} ,
		{"word":"bimbeloteries","word_nosc":"bimbeloteries","lemma":"bimbeloterie","pos":"NOM"} ,
		{"word":"bimestre","word_nosc":"bimestre","lemma":"bimestre","pos":"NOM"} ,
		{"word":"bimoteur","word_nosc":"bimoteur","lemma":"bimoteur","pos":"NOM"} ,
		{"word":"bimoteurs","word_nosc":"bimoteurs","lemma":"bimoteur","pos":"NOM"} ,
		{"word":"bimétallisme","word_nosc":"bimetallisme","lemma":"bimétallisme","pos":"NOM"} ,
		{"word":"bin's","word_nosc":"bin's","lemma":"bin's","pos":"NOM"} ,
		{"word":"binaire","word_nosc":"binaire","lemma":"binaire","pos":"NOM"} ,
		{"word":"binaires","word_nosc":"binaires","lemma":"binaire","pos":"NOM"} ,
		{"word":"binet-simon","word_nosc":"binet-simon","lemma":"binet-simon","pos":"NOM"} ,
		{"word":"binette","word_nosc":"binette","lemma":"binette","pos":"NOM"} ,
		{"word":"binettes","word_nosc":"binettes","lemma":"binette","pos":"NOM"} ,
		{"word":"bingo","word_nosc":"bingo","lemma":"bingo","pos":"NOM"} ,
		{"word":"biniou","word_nosc":"biniou","lemma":"biniou","pos":"NOM"} ,
		{"word":"binious","word_nosc":"binious","lemma":"biniou","pos":"NOM"} ,
		{"word":"binocle","word_nosc":"binocle","lemma":"binocle","pos":"NOM"} ,
		{"word":"binocles","word_nosc":"binocles","lemma":"binocle","pos":"NOM"} ,
		{"word":"bintje","word_nosc":"bintje","lemma":"bintje","pos":"NOM"} ,
		{"word":"binz","word_nosc":"binz","lemma":"binz","pos":"NOM"} ,
		{"word":"binôme","word_nosc":"binome","lemma":"binôme","pos":"NOM"} ,
		{"word":"binômes","word_nosc":"binomes","lemma":"binôme","pos":"NOM"} ,
		{"word":"biochimie","word_nosc":"biochimie","lemma":"biochimie","pos":"NOM"} ,
		{"word":"biochimiste","word_nosc":"biochimiste","lemma":"biochimiste","pos":"NOM"} ,
		{"word":"biochimistes","word_nosc":"biochimistes","lemma":"biochimiste","pos":"NOM"} ,
		{"word":"biodiversité","word_nosc":"biodiversite","lemma":"biodiversité","pos":"NOM"} ,
		{"word":"biographe","word_nosc":"biographe","lemma":"biographe","pos":"NOM"} ,
		{"word":"biographes","word_nosc":"biographes","lemma":"biographe","pos":"NOM"} ,
		{"word":"biographie","word_nosc":"biographie","lemma":"biographie","pos":"NOM"} ,
		{"word":"biographies","word_nosc":"biographies","lemma":"biographie","pos":"NOM"} ,
		{"word":"biologie","word_nosc":"biologie","lemma":"biologie","pos":"NOM"} ,
		{"word":"biologiste","word_nosc":"biologiste","lemma":"biologiste","pos":"NOM"} ,
		{"word":"biologistes","word_nosc":"biologistes","lemma":"biologiste","pos":"NOM"} ,
		{"word":"bioluminescence","word_nosc":"bioluminescence","lemma":"bioluminescence","pos":"NOM"} ,
		{"word":"biomasse","word_nosc":"biomasse","lemma":"biomasse","pos":"NOM"} ,
		{"word":"biomécanique","word_nosc":"biomecanique","lemma":"biomécanique","pos":"NOM"} ,
		{"word":"biomécanisme","word_nosc":"biomecanisme","lemma":"biomécanisme","pos":"NOM"} ,
		{"word":"biométrie","word_nosc":"biometrie","lemma":"biométrie","pos":"NOM"} ,
		{"word":"biométrique","word_nosc":"biometrique","lemma":"biométrique","pos":"NOM"} ,
		{"word":"bionique","word_nosc":"bionique","lemma":"bionique","pos":"NOM"} ,
		{"word":"biophysique","word_nosc":"biophysique","lemma":"biophysique","pos":"NOM"} ,
		{"word":"biopsie","word_nosc":"biopsie","lemma":"biopsie","pos":"NOM"} ,
		{"word":"biopsies","word_nosc":"biopsies","lemma":"biopsie","pos":"NOM"} ,
		{"word":"biorythme","word_nosc":"biorythme","lemma":"biorythme","pos":"NOM"} ,
		{"word":"biorythmes","word_nosc":"biorythmes","lemma":"biorythme","pos":"NOM"} ,
		{"word":"biosphère","word_nosc":"biosphere","lemma":"biosphère","pos":"NOM"} ,
		{"word":"biotechnique","word_nosc":"biotechnique","lemma":"biotechnique","pos":"NOM"} ,
		{"word":"biotechnologie","word_nosc":"biotechnologie","lemma":"biotechnologie","pos":"NOM"} ,
		{"word":"biotite","word_nosc":"biotite","lemma":"biotite","pos":"NOM"} ,
		{"word":"biotope","word_nosc":"biotope","lemma":"biotope","pos":"NOM"} ,
		{"word":"bioxyde","word_nosc":"bioxyde","lemma":"bioxyde","pos":"NOM"} ,
		{"word":"bip","word_nosc":"bip","lemma":"bip","pos":"NOM"} ,
		{"word":"bip-bip","word_nosc":"bip-bip","lemma":"bip-bip","pos":"NOM"} ,
		{"word":"bipartisme","word_nosc":"bipartisme","lemma":"bipartisme","pos":"NOM"} ,
		{"word":"biphényle","word_nosc":"biphenyle","lemma":"biphényle","pos":"NOM"} ,
		{"word":"biplan","word_nosc":"biplan","lemma":"biplan","pos":"NOM"} ,
		{"word":"biplans","word_nosc":"biplans","lemma":"biplan","pos":"NOM"} ,
		{"word":"bipolarité","word_nosc":"bipolarite","lemma":"bipolarité","pos":"NOM"} ,
		{"word":"bips","word_nosc":"bips","lemma":"bip","pos":"NOM"} ,
		{"word":"bipède","word_nosc":"bipede","lemma":"bipède","pos":"NOM"} ,
		{"word":"bipèdes","word_nosc":"bipedes","lemma":"bipède","pos":"NOM"} ,
		{"word":"bique","word_nosc":"bique","lemma":"bique","pos":"NOM"} ,
		{"word":"biques","word_nosc":"biques","lemma":"bique","pos":"NOM"} ,
		{"word":"biquet","word_nosc":"biquet","lemma":"biquet","pos":"NOM"} ,
		{"word":"biquets","word_nosc":"biquets","lemma":"biquet","pos":"NOM"} ,
		{"word":"biquette","word_nosc":"biquette","lemma":"biquette","pos":"NOM"} ,
		{"word":"biquettes","word_nosc":"biquettes","lemma":"biquette","pos":"NOM"} ,
		{"word":"birbe","word_nosc":"birbe","lemma":"birbe","pos":"NOM"} ,
		{"word":"birbes","word_nosc":"birbes","lemma":"birbe","pos":"NOM"} ,
		{"word":"birchers","word_nosc":"birchers","lemma":"bircher","pos":"NOM"} ,
		{"word":"bire","word_nosc":"bire","lemma":"bire","pos":"NOM"} ,
		{"word":"biribi","word_nosc":"biribi","lemma":"biribi","pos":"NOM"} ,
		{"word":"birman","word_nosc":"birman","lemma":"birman","pos":"NOM"} ,
		{"word":"birmane","word_nosc":"birmane","lemma":"birman","pos":"NOM"} ,
		{"word":"birmans","word_nosc":"birmans","lemma":"birman","pos":"NOM"} ,
		{"word":"biroute","word_nosc":"biroute","lemma":"biroute","pos":"NOM"} ,
		{"word":"biroutes","word_nosc":"biroutes","lemma":"biroute","pos":"NOM"} ,
		{"word":"birth control","word_nosc":"birth control","lemma":"birth control","pos":"NOM"} ,
		{"word":"biréfringence","word_nosc":"birefringence","lemma":"biréfringence","pos":"NOM"} ,
		{"word":"bis","word_nosc":"bis","lemma":"bis","pos":"NOM"} ,
		{"word":"bisaïeul","word_nosc":"bisaieul","lemma":"bisaïeul","pos":"NOM"} ,
		{"word":"bisaïeule","word_nosc":"bisaieule","lemma":"bisaïeul","pos":"NOM"} ,
		{"word":"bisbille","word_nosc":"bisbille","lemma":"bisbille","pos":"NOM"} ,
		{"word":"bisbilles","word_nosc":"bisbilles","lemma":"bisbille","pos":"NOM"} ,
		{"word":"biscaïen","word_nosc":"biscaien","lemma":"biscaïen","pos":"NOM"} ,
		{"word":"bischof","word_nosc":"bischof","lemma":"bischof","pos":"NOM"} ,
		{"word":"biscoteaux","word_nosc":"biscoteaux","lemma":"biscoteau","pos":"NOM"} ,
		{"word":"biscotos","word_nosc":"biscotos","lemma":"biscoto","pos":"NOM"} ,
		{"word":"biscotte","word_nosc":"biscotte","lemma":"biscotte","pos":"NOM"} ,
		{"word":"biscottes","word_nosc":"biscottes","lemma":"biscotte","pos":"NOM"} ,
		{"word":"biscuit","word_nosc":"biscuit","lemma":"biscuit","pos":"NOM"} ,
		{"word":"biscuiterie","word_nosc":"biscuiterie","lemma":"biscuiterie","pos":"NOM"} ,
		{"word":"biscuits","word_nosc":"biscuits","lemma":"biscuit","pos":"NOM"} ,
		{"word":"bise","word_nosc":"bise","lemma":"bise","pos":"NOM"} ,
		{"word":"biseau","word_nosc":"biseau","lemma":"biseau","pos":"NOM"} ,
		{"word":"biseaux","word_nosc":"biseaux","lemma":"biseau","pos":"NOM"} ,
		{"word":"biseness","word_nosc":"biseness","lemma":"biseness","pos":"NOM"} ,
		{"word":"bises","word_nosc":"bises","lemma":"bise","pos":"NOM"} ,
		{"word":"bisets","word_nosc":"bisets","lemma":"biset","pos":"NOM"} ,
		{"word":"bisette","word_nosc":"bisette","lemma":"bisette","pos":"NOM"} ,
		{"word":"bisexualité","word_nosc":"bisexualite","lemma":"bisexualité","pos":"NOM"} ,
		{"word":"bishop","word_nosc":"bishop","lemma":"bishop","pos":"NOM"} ,
		{"word":"bismuth","word_nosc":"bismuth","lemma":"bismuth","pos":"NOM"} ,
		{"word":"bisness","word_nosc":"bisness","lemma":"bisness","pos":"NOM"} ,
		{"word":"bison","word_nosc":"bison","lemma":"bison","pos":"NOM"} ,
		{"word":"bisons","word_nosc":"bisons","lemma":"bison","pos":"NOM"} ,
		{"word":"bisou","word_nosc":"bisou","lemma":"bisou","pos":"NOM"} ,
		{"word":"bisou-éclair","word_nosc":"bisou-eclair","lemma":"bisou-éclair","pos":"NOM"} ,
		{"word":"bisous","word_nosc":"bisous","lemma":"bisou","pos":"NOM"} ,
		{"word":"bisque","word_nosc":"bisque","lemma":"bisque","pos":"NOM"} ,
		{"word":"bisques","word_nosc":"bisques","lemma":"bisque","pos":"NOM"} ,
		{"word":"bissac","word_nosc":"bissac","lemma":"bissac","pos":"NOM"} ,
		{"word":"bissacs","word_nosc":"bissacs","lemma":"bissac","pos":"NOM"} ,
		{"word":"bissel","word_nosc":"bissel","lemma":"bissel","pos":"NOM"} ,
		{"word":"bistorte","word_nosc":"bistorte","lemma":"bistorte","pos":"NOM"} ,
		{"word":"bistouille","word_nosc":"bistouille","lemma":"bistouille","pos":"NOM"} ,
		{"word":"bistouquette","word_nosc":"bistouquette","lemma":"bistouquette","pos":"NOM"} ,
		{"word":"bistouri","word_nosc":"bistouri","lemma":"bistouri","pos":"NOM"} ,
		{"word":"bistouris","word_nosc":"bistouris","lemma":"bistouri","pos":"NOM"} ,
		{"word":"bistre","word_nosc":"bistre","lemma":"bistre","pos":"NOM"} ,
		{"word":"bistres","word_nosc":"bistres","lemma":"bistre","pos":"NOM"} ,
		{"word":"bistro","word_nosc":"bistro","lemma":"bistro","pos":"NOM"} ,
		{"word":"bistroquet","word_nosc":"bistroquet","lemma":"bistroquet","pos":"NOM"} ,
		{"word":"bistros","word_nosc":"bistros","lemma":"bistro","pos":"NOM"} ,
		{"word":"bistrot","word_nosc":"bistrot","lemma":"bistrot","pos":"NOM"} ,
		{"word":"bistrote","word_nosc":"bistrote","lemma":"bistrot","pos":"NOM"} ,
		{"word":"bistrotier","word_nosc":"bistrotier","lemma":"bistrotier","pos":"NOM"} ,
		{"word":"bistrotiers","word_nosc":"bistrotiers","lemma":"bistrotier","pos":"NOM"} ,
		{"word":"bistrotière","word_nosc":"bistrotiere","lemma":"bistrotier","pos":"NOM"} ,
		{"word":"bistrotières","word_nosc":"bistrotieres","lemma":"bistrotier","pos":"NOM"} ,
		{"word":"bistrots","word_nosc":"bistrots","lemma":"bistrot","pos":"NOM"} ,
		{"word":"bistrouille","word_nosc":"bistrouille","lemma":"bistrouille","pos":"NOM"} ,
		{"word":"bisulfite","word_nosc":"bisulfite","lemma":"bisulfite","pos":"NOM"} ,
		{"word":"bit","word_nosc":"bit","lemma":"bit","pos":"NOM"} ,
		{"word":"bite","word_nosc":"bite","lemma":"bite","pos":"NOM"} ,
		{"word":"bites","word_nosc":"bites","lemma":"bite","pos":"NOM"} ,
		{"word":"bithynien","word_nosc":"bithynien","lemma":"bithynien","pos":"NOM"} ,
		{"word":"bithyniens","word_nosc":"bithyniens","lemma":"bithynien","pos":"NOM"} ,
		{"word":"bitoniau","word_nosc":"bitoniau","lemma":"bitoniau","pos":"NOM"} ,
		{"word":"bitos","word_nosc":"bitos","lemma":"bitos","pos":"NOM"} ,
		{"word":"bits","word_nosc":"bits","lemma":"bit","pos":"NOM"} ,
		{"word":"bitte","word_nosc":"bitte","lemma":"bitte","pos":"NOM"} ,
		{"word":"bitter","word_nosc":"bitter","lemma":"bitter","pos":"NOM"} ,
		{"word":"bitters","word_nosc":"bitters","lemma":"bitter","pos":"NOM"} ,
		{"word":"bittes","word_nosc":"bittes","lemma":"bitte","pos":"NOM"} ,
		{"word":"bitume","word_nosc":"bitume","lemma":"bitume","pos":"NOM"} ,
		{"word":"bitumes","word_nosc":"bitumes","lemma":"bitume","pos":"NOM"} ,
		{"word":"biture","word_nosc":"biture","lemma":"biture","pos":"NOM"} ,
		{"word":"bitures","word_nosc":"bitures","lemma":"biture","pos":"NOM"} ,
		{"word":"biturin","word_nosc":"biturin","lemma":"biturin","pos":"NOM"} ,
		{"word":"biturins","word_nosc":"biturins","lemma":"biturin","pos":"NOM"} ,
		{"word":"bivalve","word_nosc":"bivalve","lemma":"bivalve","pos":"NOM"} ,
		{"word":"bivouac","word_nosc":"bivouac","lemma":"bivouac","pos":"NOM"} ,
		{"word":"bivouacs","word_nosc":"bivouacs","lemma":"bivouac","pos":"NOM"} ,
		{"word":"bizarrerie","word_nosc":"bizarrerie","lemma":"bizarrerie","pos":"NOM"} ,
		{"word":"bizarreries","word_nosc":"bizarreries","lemma":"bizarrerie","pos":"NOM"} ,
		{"word":"bizness","word_nosc":"bizness","lemma":"bizness","pos":"NOM"} ,
		{"word":"bizou","word_nosc":"bizou","lemma":"bizou","pos":"NOM"} ,
		{"word":"bizut","word_nosc":"bizut","lemma":"bizut","pos":"NOM"} ,
		{"word":"bizutage","word_nosc":"bizutage","lemma":"bizutage","pos":"NOM"} ,
		{"word":"bizutages","word_nosc":"bizutages","lemma":"bizutage","pos":"NOM"} ,
		{"word":"bizuteurs","word_nosc":"bizuteurs","lemma":"bizuteur","pos":"NOM"} ,
		{"word":"bizuth","word_nosc":"bizuth","lemma":"bizuth","pos":"NOM"} ,
		{"word":"bizuts","word_nosc":"bizuts","lemma":"bizut","pos":"NOM"} ,
		{"word":"bière","word_nosc":"biere","lemma":"bière","pos":"NOM"} ,
		{"word":"bières","word_nosc":"bieres","lemma":"bière","pos":"NOM"} ,
		{"word":"bla bla","word_nosc":"bla bla","lemma":"bla-bla","pos":"NOM"} ,
		{"word":"bla-bla","word_nosc":"bla-bla","lemma":"bla-bla","pos":"NOM"} ,
		{"word":"bla-bla-bla","word_nosc":"bla-bla-bla","lemma":"bla-bla-bla","pos":"NOM"} ,
		{"word":"blabla","word_nosc":"blabla","lemma":"blabla","pos":"NOM"} ,
		{"word":"blablabla","word_nosc":"blablabla","lemma":"blablabla","pos":"NOM"} ,
		{"word":"blablateurs","word_nosc":"blablateurs","lemma":"blablateur","pos":"NOM"} ,
		{"word":"black","word_nosc":"black","lemma":"black","pos":"NOM"} ,
		{"word":"black-bass","word_nosc":"black-bass","lemma":"black-bass","pos":"NOM"} ,
		{"word":"black-jack","word_nosc":"black-jack","lemma":"black-jack","pos":"NOM"} ,
		{"word":"black-out","word_nosc":"black-out","lemma":"black-out","pos":"NOM"} ,
		{"word":"blacks","word_nosc":"blacks","lemma":"black","pos":"NOM"} ,
		{"word":"blague","word_nosc":"blague","lemma":"blague","pos":"NOM"} ,
		{"word":"blagues","word_nosc":"blagues","lemma":"blague","pos":"NOM"} ,
		{"word":"blagueur","word_nosc":"blagueur","lemma":"blagueur","pos":"NOM"} ,
		{"word":"blagueurs","word_nosc":"blagueurs","lemma":"blagueur","pos":"NOM"} ,
		{"word":"blagueuse","word_nosc":"blagueuse","lemma":"blagueur","pos":"NOM"} ,
		{"word":"blair","word_nosc":"blair","lemma":"blair","pos":"NOM"} ,
		{"word":"blaireau","word_nosc":"blaireau","lemma":"blaireau","pos":"NOM"} ,
		{"word":"blaireaux","word_nosc":"blaireaux","lemma":"blaireau","pos":"NOM"} ,
		{"word":"blairs","word_nosc":"blairs","lemma":"blair","pos":"NOM"} ,
		{"word":"blaisois","word_nosc":"blaisois","lemma":"blaisois","pos":"NOM"} ,
		{"word":"blanc","word_nosc":"blanc","lemma":"blanc","pos":"NOM"} ,
		{"word":"blanc-bec","word_nosc":"blanc-bec","lemma":"blanc-bec","pos":"NOM"} ,
		{"word":"blanc-bleu","word_nosc":"blanc-bleu","lemma":"blanc-bleu","pos":"NOM"} ,
		{"word":"blanc-seing","word_nosc":"blanc-seing","lemma":"blanc-seing","pos":"NOM"} ,
		{"word":"blanche","word_nosc":"blanche","lemma":"blanche","pos":"NOM"} ,
		{"word":"blanchecaille","word_nosc":"blanchecaille","lemma":"blanchecaille","pos":"NOM"} ,
		{"word":"blanchecailles","word_nosc":"blanchecailles","lemma":"blanchecaille","pos":"NOM"} ,
		{"word":"blanchement","word_nosc":"blanchement","lemma":"blanchement","pos":"NOM"} ,
		{"word":"blanches","word_nosc":"blanches","lemma":"blanche","pos":"NOM"} ,
		{"word":"blanchet","word_nosc":"blanchet","lemma":"blanchet","pos":"NOM"} ,
		{"word":"blancheur","word_nosc":"blancheur","lemma":"blancheur","pos":"NOM"} ,
		{"word":"blancheurs","word_nosc":"blancheurs","lemma":"blancheur","pos":"NOM"} ,
		{"word":"blanchiment","word_nosc":"blanchiment","lemma":"blanchiment","pos":"NOM"} ,
		{"word":"blanchiments","word_nosc":"blanchiments","lemma":"blanchiment","pos":"NOM"} ,
		{"word":"blanchissage","word_nosc":"blanchissage","lemma":"blanchissage","pos":"NOM"} ,
		{"word":"blanchissement","word_nosc":"blanchissement","lemma":"blanchissement","pos":"NOM"} ,
		{"word":"blanchisserie","word_nosc":"blanchisserie","lemma":"blanchisserie","pos":"NOM"} ,
		{"word":"blanchisseur","word_nosc":"blanchisseur","lemma":"blanchisseur","pos":"NOM"} ,
		{"word":"blanchisseurs","word_nosc":"blanchisseurs","lemma":"blanchisseur","pos":"NOM"} ,
		{"word":"blanchisseuse","word_nosc":"blanchisseuse","lemma":"blanchisseur","pos":"NOM"} ,
		{"word":"blanchisseuses","word_nosc":"blanchisseuses","lemma":"blanchisseur","pos":"NOM"} ,
		{"word":"blanchoiement","word_nosc":"blanchoiement","lemma":"blanchoiement","pos":"NOM"} ,
		{"word":"blanchon","word_nosc":"blanchon","lemma":"blanchon","pos":"NOM"} ,
		{"word":"blancs","word_nosc":"blancs","lemma":"blanc","pos":"NOM"} ,
		{"word":"blancs-becs","word_nosc":"blancs-becs","lemma":"blanc-bec","pos":"NOM"} ,
		{"word":"blandices","word_nosc":"blandices","lemma":"blandice","pos":"NOM"} ,
		{"word":"blanque","word_nosc":"blanque","lemma":"blanque","pos":"NOM"} ,
		{"word":"blanquette","word_nosc":"blanquette","lemma":"blanquette","pos":"NOM"} ,
		{"word":"blanquettes","word_nosc":"blanquettes","lemma":"blanquette","pos":"NOM"} ,
		{"word":"blase","word_nosc":"blase","lemma":"blase","pos":"NOM"} ,
		{"word":"blases","word_nosc":"blases","lemma":"blase","pos":"NOM"} ,
		{"word":"blason","word_nosc":"blason","lemma":"blason","pos":"NOM"} ,
		{"word":"blasons","word_nosc":"blasons","lemma":"blason","pos":"NOM"} ,
		{"word":"blasphème","word_nosc":"blaspheme","lemma":"blasphème","pos":"NOM"} ,
		{"word":"blasphèmes","word_nosc":"blasphemes","lemma":"blasphème","pos":"NOM"} ,
		{"word":"blasphémateur","word_nosc":"blasphemateur","lemma":"blasphémateur","pos":"NOM"} ,
		{"word":"blasphémateurs","word_nosc":"blasphemateurs","lemma":"blasphémateur","pos":"NOM"} ,
		{"word":"blasphématrice","word_nosc":"blasphematrice","lemma":"blasphémateur","pos":"NOM"} ,
		{"word":"blastula","word_nosc":"blastula","lemma":"blastula","pos":"NOM"} ,
		{"word":"blatte","word_nosc":"blatte","lemma":"blatte","pos":"NOM"} ,
		{"word":"blattes","word_nosc":"blattes","lemma":"blatte","pos":"NOM"} ,
		{"word":"blaze","word_nosc":"blaze","lemma":"blaze","pos":"NOM"} ,
		{"word":"blazer","word_nosc":"blazer","lemma":"blazer","pos":"NOM"} ,
		{"word":"blazers","word_nosc":"blazers","lemma":"blazer","pos":"NOM"} ,
		{"word":"blazes","word_nosc":"blazes","lemma":"blaze","pos":"NOM"} ,
		{"word":"bled","word_nosc":"bled","lemma":"bled","pos":"NOM"} ,
		{"word":"bleds","word_nosc":"bleds","lemma":"bled","pos":"NOM"} ,
		{"word":"blennies","word_nosc":"blennies","lemma":"blennie","pos":"NOM"} ,
		{"word":"blennorragie","word_nosc":"blennorragie","lemma":"blennorragie","pos":"NOM"} ,
		{"word":"blessing","word_nosc":"blessing","lemma":"blessing","pos":"NOM"} ,
		{"word":"blessure","word_nosc":"blessure","lemma":"blessure","pos":"NOM"} ,
		{"word":"blessures","word_nosc":"blessures","lemma":"blessure","pos":"NOM"} ,
		{"word":"blessé","word_nosc":"blesse","lemma":"blessé","pos":"NOM"} ,
		{"word":"blessée","word_nosc":"blessee","lemma":"blessé","pos":"NOM"} ,
		{"word":"blessées","word_nosc":"blessees","lemma":"blessé","pos":"NOM"} ,
		{"word":"blessés","word_nosc":"blesses","lemma":"blessé","pos":"NOM"} ,
		{"word":"blettes","word_nosc":"blettes","lemma":"blette","pos":"NOM"} ,
		{"word":"blettissement","word_nosc":"blettissement","lemma":"blettissement","pos":"NOM"} ,
		{"word":"bleu","word_nosc":"bleu","lemma":"bleu","pos":"NOM"} ,
		{"word":"bleu-noir","word_nosc":"bleu-noir","lemma":"bleu-noir","pos":"NOM"} ,
		{"word":"bleu-roi","word_nosc":"bleu-roi","lemma":"bleu-roi","pos":"NOM"} ,
		{"word":"bleubite","word_nosc":"bleubite","lemma":"bleubite","pos":"NOM"} ,
		{"word":"bleubites","word_nosc":"bleubites","lemma":"bleubite","pos":"NOM"} ,
		{"word":"bleuet","word_nosc":"bleuet","lemma":"bleuet","pos":"NOM"} ,
		{"word":"bleuets","word_nosc":"bleuets","lemma":"bleuet","pos":"NOM"} ,
		{"word":"bleuette","word_nosc":"bleuette","lemma":"bleuette","pos":"NOM"} ,
		{"word":"bleus","word_nosc":"bleus","lemma":"bleu","pos":"NOM"} ,
		{"word":"bleusaille","word_nosc":"bleusaille","lemma":"bleusaille","pos":"NOM"} ,
		{"word":"bleusailles","word_nosc":"bleusailles","lemma":"bleusaille","pos":"NOM"} ,
		{"word":"bliaut","word_nosc":"bliaut","lemma":"bliaut","pos":"NOM"} ,
		{"word":"blindage","word_nosc":"blindage","lemma":"blindage","pos":"NOM"} ,
		{"word":"blindages","word_nosc":"blindages","lemma":"blindage","pos":"NOM"} ,
		{"word":"blinde","word_nosc":"blinde","lemma":"blinde","pos":"NOM"} ,
		{"word":"blindes","word_nosc":"blindes","lemma":"blinde","pos":"NOM"} ,
		{"word":"blindé","word_nosc":"blinde","lemma":"blindé","pos":"NOM"} ,
		{"word":"blindés","word_nosc":"blindes","lemma":"blindé","pos":"NOM"} ,
		{"word":"blini","word_nosc":"blini","lemma":"blini","pos":"NOM"} ,
		{"word":"blinis","word_nosc":"blinis","lemma":"blini","pos":"NOM"} ,
		{"word":"blitzkrieg","word_nosc":"blitzkrieg","lemma":"blitzkrieg","pos":"NOM"} ,
		{"word":"blizzard","word_nosc":"blizzard","lemma":"blizzard","pos":"NOM"} ,
		{"word":"blizzards","word_nosc":"blizzards","lemma":"blizzard","pos":"NOM"} ,
		{"word":"blob","word_nosc":"blob","lemma":"blob","pos":"NOM"} ,
		{"word":"bloc","word_nosc":"bloc","lemma":"bloc","pos":"NOM"} ,
		{"word":"bloc-cylindres","word_nosc":"bloc-cylindres","lemma":"bloc-cylindres","pos":"NOM"} ,
		{"word":"bloc-moteur","word_nosc":"bloc-moteur","lemma":"bloc-moteur","pos":"NOM"} ,
		{"word":"bloc-notes","word_nosc":"bloc-notes","lemma":"bloc-notes","pos":"NOM"} ,
		{"word":"blocage","word_nosc":"blocage","lemma":"blocage","pos":"NOM"} ,
		{"word":"blocages","word_nosc":"blocages","lemma":"blocage","pos":"NOM"} ,
		{"word":"blocaille","word_nosc":"blocaille","lemma":"blocaille","pos":"NOM"} ,
		{"word":"block","word_nosc":"block","lemma":"block","pos":"NOM"} ,
		{"word":"blockhaus","word_nosc":"blockhaus","lemma":"blockhaus","pos":"NOM"} ,
		{"word":"blocks","word_nosc":"blocks","lemma":"block","pos":"NOM"} ,
		{"word":"blocs","word_nosc":"blocs","lemma":"bloc","pos":"NOM"} ,
		{"word":"blocs-notes","word_nosc":"blocs-notes","lemma":"blocs-notes","pos":"NOM"} ,
		{"word":"blocus","word_nosc":"blocus","lemma":"blocus","pos":"NOM"} ,
		{"word":"blond","word_nosc":"blond","lemma":"blond","pos":"NOM"} ,
		{"word":"blonde","word_nosc":"blonde","lemma":"blond","pos":"NOM"} ,
		{"word":"blondes","word_nosc":"blondes","lemma":"blond","pos":"NOM"} ,
		{"word":"blondeur","word_nosc":"blondeur","lemma":"blondeur","pos":"NOM"} ,
		{"word":"blondeurs","word_nosc":"blondeurs","lemma":"blondeur","pos":"NOM"} ,
		{"word":"blondin","word_nosc":"blondin","lemma":"blondin","pos":"NOM"} ,
		{"word":"blondinet","word_nosc":"blondinet","lemma":"blondinet","pos":"NOM"} ,
		{"word":"blondinets","word_nosc":"blondinets","lemma":"blondinet","pos":"NOM"} ,
		{"word":"blonds","word_nosc":"blonds","lemma":"blond","pos":"NOM"} ,
		{"word":"bloody mary","word_nosc":"bloody mary","lemma":"bloody mary","pos":"NOM"} ,
		{"word":"bloom","word_nosc":"bloom","lemma":"bloom","pos":"NOM"} ,
		{"word":"bloomer","word_nosc":"bloomer","lemma":"bloomer","pos":"NOM"} ,
		{"word":"bloqueur","word_nosc":"bloqueur","lemma":"bloqueur","pos":"NOM"} ,
		{"word":"bloqueurs","word_nosc":"bloqueurs","lemma":"bloqueur","pos":"NOM"} ,
		{"word":"blot","word_nosc":"blot","lemma":"blot","pos":"NOM"} ,
		{"word":"blots","word_nosc":"blots","lemma":"blot","pos":"NOM"} ,
		{"word":"blottissement","word_nosc":"blottissement","lemma":"blottissement","pos":"NOM"} ,
		{"word":"bloum","word_nosc":"bloum","lemma":"bloum","pos":"NOM"} ,
		{"word":"blouse","word_nosc":"blouse","lemma":"blouse","pos":"NOM"} ,
		{"word":"blouses","word_nosc":"blouses","lemma":"blouse","pos":"NOM"} ,
		{"word":"blouson","word_nosc":"blouson","lemma":"blouson","pos":"NOM"} ,
		{"word":"blousons","word_nosc":"blousons","lemma":"blouson","pos":"NOM"} ,
		{"word":"blue jeans","word_nosc":"blue jeans","lemma":"blue-jean","pos":"NOM"} ,
		{"word":"blue note","word_nosc":"blue note","lemma":"blue note","pos":"NOM"} ,
		{"word":"blue-jean","word_nosc":"blue-jean","lemma":"blue-jean","pos":"NOM"} ,
		{"word":"blue-jeans","word_nosc":"blue-jeans","lemma":"blue-jean","pos":"NOM"} ,
		{"word":"blues","word_nosc":"blues","lemma":"blues","pos":"NOM"} ,
		{"word":"bluets","word_nosc":"bluets","lemma":"bluet","pos":"NOM"} ,
		{"word":"bluette","word_nosc":"bluette","lemma":"bluette","pos":"NOM"} ,
		{"word":"bluettes","word_nosc":"bluettes","lemma":"bluette","pos":"NOM"} ,
		{"word":"bluff","word_nosc":"bluff","lemma":"bluff","pos":"NOM"} ,
		{"word":"bluffeur","word_nosc":"bluffeur","lemma":"bluffeur","pos":"NOM"} ,
		{"word":"bluffeurs","word_nosc":"bluffeurs","lemma":"bluffeur","pos":"NOM"} ,
		{"word":"bluffeuses","word_nosc":"bluffeuses","lemma":"bluffeur","pos":"NOM"} ,
		{"word":"bluffs","word_nosc":"bluffs","lemma":"bluff","pos":"NOM"} ,
		{"word":"blush","word_nosc":"blush","lemma":"blush","pos":"NOM"} ,
		{"word":"blutoir","word_nosc":"blutoir","lemma":"blutoir","pos":"NOM"} ,
		{"word":"blâme","word_nosc":"blame","lemma":"blâme","pos":"NOM"} ,
		{"word":"blâmes","word_nosc":"blames","lemma":"blâme","pos":"NOM"} ,
		{"word":"blèsement","word_nosc":"blesement","lemma":"blèsement","pos":"NOM"} ,
		{"word":"blé","word_nosc":"ble","lemma":"blé","pos":"NOM"} ,
		{"word":"blédard","word_nosc":"bledard","lemma":"blédard","pos":"NOM"} ,
		{"word":"blédards","word_nosc":"bledards","lemma":"blédard","pos":"NOM"} ,
		{"word":"blépharite","word_nosc":"blepharite","lemma":"blépharite","pos":"NOM"} ,
		{"word":"blés","word_nosc":"bles","lemma":"blé","pos":"NOM"} ,
		{"word":"boa","word_nosc":"boa","lemma":"boa","pos":"NOM"} ,
		{"word":"boas","word_nosc":"boas","lemma":"boa","pos":"NOM"} ,
		{"word":"boat people","word_nosc":"boat people","lemma":"boat people","pos":"NOM"} ,
		{"word":"bob","word_nosc":"bob","lemma":"bob","pos":"NOM"} ,
		{"word":"bobard","word_nosc":"bobard","lemma":"bobard","pos":"NOM"} ,
		{"word":"bobards","word_nosc":"bobards","lemma":"bobard","pos":"NOM"} ,
		{"word":"bobbies","word_nosc":"bobbies","lemma":"bobbies","pos":"NOM"} ,
		{"word":"bobby","word_nosc":"bobby","lemma":"bobby","pos":"NOM"} ,
		{"word":"bobinage","word_nosc":"bobinage","lemma":"bobinage","pos":"NOM"} ,
		{"word":"bobinard","word_nosc":"bobinard","lemma":"bobinard","pos":"NOM"} ,
		{"word":"bobinards","word_nosc":"bobinards","lemma":"bobinard","pos":"NOM"} ,
		{"word":"bobine","word_nosc":"bobine","lemma":"bobine","pos":"NOM"} ,
		{"word":"bobineau","word_nosc":"bobineau","lemma":"bobineau","pos":"NOM"} ,
		{"word":"bobines","word_nosc":"bobines","lemma":"bobine","pos":"NOM"} ,
		{"word":"bobinette","word_nosc":"bobinette","lemma":"bobinette","pos":"NOM"} ,
		{"word":"bobinettes","word_nosc":"bobinettes","lemma":"bobinette","pos":"NOM"} ,
		{"word":"bobineuse","word_nosc":"bobineuse","lemma":"bobineur","pos":"NOM"} ,
		{"word":"bobineuses","word_nosc":"bobineuses","lemma":"bobineur","pos":"NOM"} ,
		{"word":"bobino","word_nosc":"bobino","lemma":"bobino","pos":"NOM"} ,
		{"word":"bobo","word_nosc":"bobo","lemma":"bobo","pos":"NOM"} ,
		{"word":"bobonne","word_nosc":"bobonne","lemma":"bobonne","pos":"NOM"} ,
		{"word":"bobonnes","word_nosc":"bobonnes","lemma":"bobonne","pos":"NOM"} ,
		{"word":"bobos","word_nosc":"bobos","lemma":"bobo","pos":"NOM"} ,
		{"word":"bobosse","word_nosc":"bobosse","lemma":"bobosse","pos":"NOM"} ,
		{"word":"bobs","word_nosc":"bobs","lemma":"bob","pos":"NOM"} ,
		{"word":"bobsleigh","word_nosc":"bobsleigh","lemma":"bobsleigh","pos":"NOM"} ,
		{"word":"bobèche","word_nosc":"bobeche","lemma":"bobèche","pos":"NOM"} ,
		{"word":"bobèches","word_nosc":"bobeches","lemma":"bobèche","pos":"NOM"} ,
		{"word":"boc","word_nosc":"boc","lemma":"boc","pos":"NOM"} ,
		{"word":"bocage","word_nosc":"bocage","lemma":"bocage","pos":"NOM"} ,
		{"word":"bocages","word_nosc":"bocages","lemma":"bocage","pos":"NOM"} ,
		{"word":"bocal","word_nosc":"bocal","lemma":"bocal","pos":"NOM"} ,
		{"word":"bocard","word_nosc":"bocard","lemma":"bocard","pos":"NOM"} ,
		{"word":"bocaux","word_nosc":"bocaux","lemma":"bocal","pos":"NOM"} ,
		{"word":"boche","word_nosc":"boche","lemma":"boche","pos":"NOM"} ,
		{"word":"boches","word_nosc":"boches","lemma":"boche","pos":"NOM"} ,
		{"word":"bochiman","word_nosc":"bochiman","lemma":"bochiman","pos":"NOM"} ,
		{"word":"bock","word_nosc":"bock","lemma":"bock","pos":"NOM"} ,
		{"word":"bocks","word_nosc":"bocks","lemma":"bock","pos":"NOM"} ,
		{"word":"bocson","word_nosc":"bocson","lemma":"bocson","pos":"NOM"} ,
		{"word":"bodega","word_nosc":"bodega","lemma":"bodega","pos":"NOM"} ,
		{"word":"bodegas","word_nosc":"bodegas","lemma":"bodega","pos":"NOM"} ,
		{"word":"bodhi","word_nosc":"bodhi","lemma":"bodhi","pos":"NOM"} ,
		{"word":"bodhisattva","word_nosc":"bodhisattva","lemma":"bodhisattva","pos":"NOM"} ,
		{"word":"bodo","word_nosc":"bodo","lemma":"bodo","pos":"NOM"} ,
		{"word":"body","word_nosc":"body","lemma":"body","pos":"NOM"} ,
		{"word":"body-building","word_nosc":"body-building","lemma":"body-building","pos":"NOM"} ,
		{"word":"bodybuilding","word_nosc":"bodybuilding","lemma":"bodybuilding","pos":"NOM"} ,
		{"word":"boer","word_nosc":"boer","lemma":"boer","pos":"NOM"} ,
		{"word":"boers","word_nosc":"boers","lemma":"boer","pos":"NOM"} ,
		{"word":"boeuf","word_nosc":"boeuf","lemma":"boeuf","pos":"NOM"} ,
		{"word":"boeufs","word_nosc":"boeufs","lemma":"boeuf","pos":"NOM"} ,
		{"word":"boggie","word_nosc":"boggie","lemma":"boggie","pos":"NOM"} ,
		{"word":"boggies","word_nosc":"boggies","lemma":"boggie","pos":"NOM"} ,
		{"word":"boghei","word_nosc":"boghei","lemma":"boghei","pos":"NOM"} ,
		{"word":"bogie","word_nosc":"bogie","lemma":"bogie","pos":"NOM"} ,
		{"word":"bogomiles","word_nosc":"bogomiles","lemma":"bogomile","pos":"NOM"} ,
		{"word":"bogue","word_nosc":"bogue","lemma":"bogue","pos":"NOM"} ,
		{"word":"bogues","word_nosc":"bogues","lemma":"bogue","pos":"NOM"} ,
		{"word":"boguet","word_nosc":"boguet","lemma":"boguet","pos":"NOM"} ,
		{"word":"bohème","word_nosc":"boheme","lemma":"bohème","pos":"NOM"} ,
		{"word":"bohèmes","word_nosc":"bohemes","lemma":"bohème","pos":"NOM"} ,
		{"word":"bohémien","word_nosc":"bohemien","lemma":"bohémien","pos":"NOM"} ,
		{"word":"bohémienne","word_nosc":"bohemienne","lemma":"bohémien","pos":"NOM"} ,
		{"word":"bohémiennes","word_nosc":"bohemiennes","lemma":"bohémien","pos":"NOM"} ,
		{"word":"bohémiens","word_nosc":"bohemiens","lemma":"bohémien","pos":"NOM"} ,
		{"word":"bohême","word_nosc":"boheme","lemma":"bohême","pos":"NOM"} ,
		{"word":"boire","word_nosc":"boire","lemma":"boire","pos":"NOM"} ,
		{"word":"bois","word_nosc":"bois","lemma":"bois","pos":"NOM"} ,
		{"word":"boiserie","word_nosc":"boiserie","lemma":"boiserie","pos":"NOM"} ,
		{"word":"boiseries","word_nosc":"boiseries","lemma":"boiserie","pos":"NOM"} ,
		{"word":"boiseur","word_nosc":"boiseur","lemma":"boiseur","pos":"NOM"} ,
		{"word":"boisseau","word_nosc":"boisseau","lemma":"boisseau","pos":"NOM"} ,
		{"word":"boisseaux","word_nosc":"boisseaux","lemma":"boisseau","pos":"NOM"} ,
		{"word":"boisselier","word_nosc":"boisselier","lemma":"boisselier","pos":"NOM"} ,
		{"word":"boisson","word_nosc":"boisson","lemma":"boisson","pos":"NOM"} ,
		{"word":"boissons","word_nosc":"boissons","lemma":"boisson","pos":"NOM"} ,
		{"word":"boitement","word_nosc":"boitement","lemma":"boitement","pos":"NOM"} ,
		{"word":"boiterie","word_nosc":"boiterie","lemma":"boiterie","pos":"NOM"} ,
		{"word":"boiteux","word_nosc":"boiteux","lemma":"boiteux","pos":"NOM"} ,
		{"word":"boitillement","word_nosc":"boitillement","lemma":"boitillement","pos":"NOM"} ,
		{"word":"boitillements","word_nosc":"boitillements","lemma":"boitillement","pos":"NOM"} ,
		{"word":"bol","word_nosc":"bol","lemma":"bol","pos":"NOM"} ,
		{"word":"bola","word_nosc":"bola","lemma":"bola","pos":"NOM"} ,
		{"word":"bolas","word_nosc":"bolas","lemma":"bola","pos":"NOM"} ,
		{"word":"bolchevik","word_nosc":"bolchevik","lemma":"bolchevik","pos":"NOM"} ,
		{"word":"bolcheviks","word_nosc":"bolcheviks","lemma":"bolchevik","pos":"NOM"} ,
		{"word":"bolchevisme","word_nosc":"bolchevisme","lemma":"bolchevisme","pos":"NOM"} ,
		{"word":"bolcho","word_nosc":"bolcho","lemma":"bolcho","pos":"NOM"} ,
		{"word":"boldo","word_nosc":"boldo","lemma":"boldo","pos":"NOM"} ,
		{"word":"bolduc","word_nosc":"bolduc","lemma":"bolduc","pos":"NOM"} ,
		{"word":"bolet","word_nosc":"bolet","lemma":"bolet","pos":"NOM"} ,
		{"word":"bolets","word_nosc":"bolets","lemma":"bolet","pos":"NOM"} ,
		{"word":"bolge","word_nosc":"bolge","lemma":"bolge","pos":"NOM"} ,
		{"word":"bolide","word_nosc":"bolide","lemma":"bolide","pos":"NOM"} ,
		{"word":"bolides","word_nosc":"bolides","lemma":"bolide","pos":"NOM"} ,
		{"word":"bolivar","word_nosc":"bolivar","lemma":"bolivar","pos":"NOM"} ,
		{"word":"bolivien","word_nosc":"bolivien","lemma":"bolivien","pos":"NOM"} ,
		{"word":"bolivienne","word_nosc":"bolivienne","lemma":"bolivien","pos":"NOM"} ,
		{"word":"boliviens","word_nosc":"boliviens","lemma":"bolivien","pos":"NOM"} ,
		{"word":"bolognaise","word_nosc":"bolognaise","lemma":"bolognais","pos":"NOM"} ,
		{"word":"bolonaise","word_nosc":"bolonaise","lemma":"bolonais","pos":"NOM"} ,
		{"word":"bols","word_nosc":"bols","lemma":"bol","pos":"NOM"} ,
		{"word":"bolée","word_nosc":"bolee","lemma":"bolée","pos":"NOM"} ,
		{"word":"bolées","word_nosc":"bolees","lemma":"bolée","pos":"NOM"} ,
		{"word":"boléro","word_nosc":"bolero","lemma":"boléro","pos":"NOM"} ,
		{"word":"boléros","word_nosc":"boleros","lemma":"boléro","pos":"NOM"} ,
		{"word":"bombage","word_nosc":"bombage","lemma":"bombage","pos":"NOM"} ,
		{"word":"bombance","word_nosc":"bombance","lemma":"bombance","pos":"NOM"} ,
		{"word":"bombances","word_nosc":"bombances","lemma":"bombance","pos":"NOM"} ,
		{"word":"bombarde","word_nosc":"bombarde","lemma":"bombarde","pos":"NOM"} ,
		{"word":"bombardement","word_nosc":"bombardement","lemma":"bombardement","pos":"NOM"} ,
		{"word":"bombardements","word_nosc":"bombardements","lemma":"bombardement","pos":"NOM"} ,
		{"word":"bombardes","word_nosc":"bombardes","lemma":"bombarde","pos":"NOM"} ,
		{"word":"bombardier","word_nosc":"bombardier","lemma":"bombardier","pos":"NOM"} ,
		{"word":"bombardiers","word_nosc":"bombardiers","lemma":"bombardier","pos":"NOM"} ,
		{"word":"bombardon","word_nosc":"bombardon","lemma":"bombardon","pos":"NOM"} ,
		{"word":"bombardons","word_nosc":"bombardons","lemma":"bombardon","pos":"NOM"} ,
		{"word":"bombe","word_nosc":"bombe","lemma":"bombe","pos":"NOM"} ,
		{"word":"bombements","word_nosc":"bombements","lemma":"bombement","pos":"NOM"} ,
		{"word":"bombes","word_nosc":"bombes","lemma":"bombe","pos":"NOM"} ,
		{"word":"bombes-test","word_nosc":"bombes-test","lemma":"bombe-test","pos":"NOM"} ,
		{"word":"bombeur","word_nosc":"bombeur","lemma":"bombeur","pos":"NOM"} ,
		{"word":"bombillement","word_nosc":"bombillement","lemma":"bombillement","pos":"NOM"} ,
		{"word":"bombinette","word_nosc":"bombinette","lemma":"bombinette","pos":"NOM"} ,
		{"word":"bombinettes","word_nosc":"bombinettes","lemma":"bombinette","pos":"NOM"} ,
		{"word":"bombonne","word_nosc":"bombonne","lemma":"bombonne","pos":"NOM"} ,
		{"word":"bombonnes","word_nosc":"bombonnes","lemma":"bombonne","pos":"NOM"} ,
		{"word":"bombyx","word_nosc":"bombyx","lemma":"bombyx","pos":"NOM"} ,
		{"word":"bon","word_nosc":"bon","lemma":"bon","pos":"NOM"} ,
		{"word":"bon-papa","word_nosc":"bon-papa","lemma":"bon-papa","pos":"NOM"} ,
		{"word":"bonace","word_nosc":"bonace","lemma":"bonace","pos":"NOM"} ,
		{"word":"bonaces","word_nosc":"bonaces","lemma":"bonace","pos":"NOM"} ,
		{"word":"bonapartiste","word_nosc":"bonapartiste","lemma":"bonapartiste","pos":"NOM"} ,
		{"word":"bonapartistes","word_nosc":"bonapartistes","lemma":"bonapartiste","pos":"NOM"} ,
		{"word":"bonasserie","word_nosc":"bonasserie","lemma":"bonasserie","pos":"NOM"} ,
		{"word":"bonbon","word_nosc":"bonbon","lemma":"bonbon","pos":"NOM"} ,
		{"word":"bonbonne","word_nosc":"bonbonne","lemma":"bonbonne","pos":"NOM"} ,
		{"word":"bonbonnes","word_nosc":"bonbonnes","lemma":"bonbonne","pos":"NOM"} ,
		{"word":"bonbonnière","word_nosc":"bonbonniere","lemma":"bonbonnière","pos":"NOM"} ,
		{"word":"bonbonnières","word_nosc":"bonbonnieres","lemma":"bonbonnière","pos":"NOM"} ,
		{"word":"bonbons","word_nosc":"bonbons","lemma":"bonbon","pos":"NOM"} ,
		{"word":"bond","word_nosc":"bond","lemma":"bond","pos":"NOM"} ,
		{"word":"bondage","word_nosc":"bondage","lemma":"bondage","pos":"NOM"} ,
		{"word":"bondages","word_nosc":"bondages","lemma":"bondage","pos":"NOM"} ,
		{"word":"bonde","word_nosc":"bonde","lemma":"bonde","pos":"NOM"} ,
		{"word":"bondes","word_nosc":"bondes","lemma":"bonde","pos":"NOM"} ,
		{"word":"bondieusard","word_nosc":"bondieusard","lemma":"bondieusard","pos":"NOM"} ,
		{"word":"bondieuserie","word_nosc":"bondieuserie","lemma":"bondieuserie","pos":"NOM"} ,
		{"word":"bondieuseries","word_nosc":"bondieuseries","lemma":"bondieuserie","pos":"NOM"} ,
		{"word":"bondissement","word_nosc":"bondissement","lemma":"bondissement","pos":"NOM"} ,
		{"word":"bondissements","word_nosc":"bondissements","lemma":"bondissement","pos":"NOM"} ,
		{"word":"bondrée","word_nosc":"bondree","lemma":"bondrée","pos":"NOM"} ,
		{"word":"bonds","word_nosc":"bonds","lemma":"bond","pos":"NOM"} ,
		{"word":"bongo","word_nosc":"bongo","lemma":"bongo","pos":"NOM"} ,
		{"word":"bongos","word_nosc":"bongos","lemma":"bongo","pos":"NOM"} ,
		{"word":"bonheur","word_nosc":"bonheur","lemma":"bonheur","pos":"NOM"} ,
		{"word":"bonheur-du-jour","word_nosc":"bonheur-du-jour","lemma":"bonheur-du-jour","pos":"NOM"} ,
		{"word":"bonheurs","word_nosc":"bonheurs","lemma":"bonheur","pos":"NOM"} ,
		{"word":"bonheurs-du-jour","word_nosc":"bonheurs-du-jour","lemma":"bonheur-du-jour","pos":"NOM"} ,
		{"word":"bonhomie","word_nosc":"bonhomie","lemma":"bonhomie","pos":"NOM"} ,
		{"word":"bonhomme","word_nosc":"bonhomme","lemma":"bonhomme","pos":"NOM"} ,
		{"word":"boni","word_nosc":"boni","lemma":"boni","pos":"NOM"} ,
		{"word":"boniche","word_nosc":"boniche","lemma":"boniche","pos":"NOM"} ,
		{"word":"boniches","word_nosc":"boniches","lemma":"boniche","pos":"NOM"} ,
		{"word":"bonification","word_nosc":"bonification","lemma":"bonification","pos":"NOM"} ,
		{"word":"boniment","word_nosc":"boniment","lemma":"boniment","pos":"NOM"} ,
		{"word":"bonimenteur","word_nosc":"bonimenteur","lemma":"bonimenteur","pos":"NOM"} ,
		{"word":"bonimenteurs","word_nosc":"bonimenteurs","lemma":"bonimenteur","pos":"NOM"} ,
		{"word":"boniments","word_nosc":"boniments","lemma":"boniment","pos":"NOM"} ,
		{"word":"bonis","word_nosc":"bonis","lemma":"boni","pos":"NOM"} ,
		{"word":"bonjour","word_nosc":"bonjour","lemma":"bonjour","pos":"NOM"} ,
		{"word":"bonjours","word_nosc":"bonjours","lemma":"bonjour","pos":"NOM"} ,
		{"word":"bonne","word_nosc":"bonne","lemma":"bonne","pos":"NOM"} ,
		{"word":"bonne-maman","word_nosc":"bonne-maman","lemma":"bonne-maman","pos":"NOM"} ,
		{"word":"bonnes","word_nosc":"bonnes","lemma":"bonne","pos":"NOM"} ,
		{"word":"bonnet","word_nosc":"bonnet","lemma":"bonnet","pos":"NOM"} ,
		{"word":"bonneteau","word_nosc":"bonneteau","lemma":"bonneteau","pos":"NOM"} ,
		{"word":"bonneterie","word_nosc":"bonneterie","lemma":"bonneterie","pos":"NOM"} ,
		{"word":"bonneteries","word_nosc":"bonneteries","lemma":"bonneterie","pos":"NOM"} ,
		{"word":"bonneteur","word_nosc":"bonneteur","lemma":"bonneteur","pos":"NOM"} ,
		{"word":"bonneteurs","word_nosc":"bonneteurs","lemma":"bonneteur","pos":"NOM"} ,
		{"word":"bonnets","word_nosc":"bonnets","lemma":"bonnet","pos":"NOM"} ,
		{"word":"bonnette","word_nosc":"bonnette","lemma":"bonnette","pos":"NOM"} ,
		{"word":"bonnettes","word_nosc":"bonnettes","lemma":"bonnette","pos":"NOM"} ,
		{"word":"bonniche","word_nosc":"bonniche","lemma":"bonniche","pos":"NOM"} ,
		{"word":"bonniches","word_nosc":"bonniches","lemma":"bonniche","pos":"NOM"} ,
		{"word":"bons","word_nosc":"bons","lemma":"bon","pos":"NOM"} ,
		{"word":"bons-cadeaux","word_nosc":"bons-cadeaux","lemma":"bon-cadeaux","pos":"NOM"} ,
		{"word":"bons-chrétiens","word_nosc":"bons-chretiens","lemma":"bon-chrétien","pos":"NOM"} ,
		{"word":"bonsaï","word_nosc":"bonsai","lemma":"bonsaï","pos":"NOM"} ,
		{"word":"bonsaïs","word_nosc":"bonsais","lemma":"bonsaï","pos":"NOM"} ,
		{"word":"bonshommes","word_nosc":"bonshommes","lemma":"bonhomme","pos":"NOM"} ,
		{"word":"bonsoir","word_nosc":"bonsoir","lemma":"bonsoir","pos":"NOM"} ,
		{"word":"bonsoirs","word_nosc":"bonsoirs","lemma":"bonsoir","pos":"NOM"} ,
		{"word":"bonté","word_nosc":"bonte","lemma":"bonté","pos":"NOM"} ,
		{"word":"bontés","word_nosc":"bontes","lemma":"bonté","pos":"NOM"} ,
		{"word":"bonus","word_nosc":"bonus","lemma":"bonus","pos":"NOM"} ,
		{"word":"bonzaï","word_nosc":"bonzai","lemma":"bonzaï","pos":"NOM"} ,
		{"word":"bonze","word_nosc":"bonze","lemma":"bonze","pos":"NOM"} ,
		{"word":"bonzes","word_nosc":"bonzes","lemma":"bonze","pos":"NOM"} ,
		{"word":"boogie-woogie","word_nosc":"boogie-woogie","lemma":"boogie-woogie","pos":"NOM"} ,
		{"word":"book","word_nosc":"book","lemma":"book","pos":"NOM"} ,
		{"word":"bookmaker","word_nosc":"bookmaker","lemma":"bookmaker","pos":"NOM"} ,
		{"word":"bookmakers","word_nosc":"bookmakers","lemma":"bookmaker","pos":"NOM"} ,
		{"word":"books","word_nosc":"books","lemma":"book","pos":"NOM"} ,
		{"word":"boom","word_nosc":"boom","lemma":"boom","pos":"NOM"} ,
		{"word":"boomer","word_nosc":"boomer","lemma":"boomer","pos":"NOM"} ,
		{"word":"boomerang","word_nosc":"boomerang","lemma":"boomerang","pos":"NOM"} ,
		{"word":"booms","word_nosc":"booms","lemma":"boom","pos":"NOM"} ,
		{"word":"booster","word_nosc":"booster","lemma":"booster","pos":"NOM"} ,
		{"word":"boosters","word_nosc":"boosters","lemma":"booster","pos":"NOM"} ,
		{"word":"bootlegger","word_nosc":"bootlegger","lemma":"bootlegger","pos":"NOM"} ,
		{"word":"bootleggers","word_nosc":"bootleggers","lemma":"bootlegger","pos":"NOM"} ,
		{"word":"boots","word_nosc":"boots","lemma":"boots","pos":"NOM"} ,
		{"word":"bop","word_nosc":"bop","lemma":"bop","pos":"NOM"} ,
		{"word":"boqueteau","word_nosc":"boqueteau","lemma":"boqueteau","pos":"NOM"} ,
		{"word":"boqueteaux","word_nosc":"boqueteaux","lemma":"boqueteau","pos":"NOM"} ,
		{"word":"boquillons","word_nosc":"boquillons","lemma":"boquillon","pos":"NOM"} ,
		{"word":"bora","word_nosc":"bora","lemma":"bora","pos":"NOM"} ,
		{"word":"borax","word_nosc":"borax","lemma":"borax","pos":"NOM"} ,
		{"word":"borborygme","word_nosc":"borborygme","lemma":"borborygme","pos":"NOM"} ,
		{"word":"borborygmes","word_nosc":"borborygmes","lemma":"borborygme","pos":"NOM"} ,
		{"word":"borchtch","word_nosc":"borchtch","lemma":"borchtch","pos":"NOM"} ,
		{"word":"bord","word_nosc":"bord","lemma":"bord","pos":"NOM"} ,
		{"word":"bordage","word_nosc":"bordage","lemma":"bordage","pos":"NOM"} ,
		{"word":"bordages","word_nosc":"bordages","lemma":"bordage","pos":"NOM"} ,
		{"word":"bordeau","word_nosc":"bordeau","lemma":"bordeau","pos":"NOM"} ,
		{"word":"bordeaux","word_nosc":"bordeaux","lemma":"bordeaux","pos":"NOM"} ,
		{"word":"bordel","word_nosc":"bordel","lemma":"bordel","pos":"NOM"} ,
		{"word":"bordelais","word_nosc":"bordelais","lemma":"bordelais","pos":"NOM"} ,
		{"word":"bordelaise","word_nosc":"bordelaise","lemma":"bordelais","pos":"NOM"} ,
		{"word":"bordelaises","word_nosc":"bordelaises","lemma":"bordelais","pos":"NOM"} ,
		{"word":"bordelières","word_nosc":"bordelieres","lemma":"bordelier","pos":"NOM"} ,
		{"word":"bordels","word_nosc":"bordels","lemma":"bordel","pos":"NOM"} ,
		{"word":"bordereau","word_nosc":"bordereau","lemma":"bordereau","pos":"NOM"} ,
		{"word":"bordereaux","word_nosc":"bordereaux","lemma":"bordereau","pos":"NOM"} ,
		{"word":"borderie","word_nosc":"borderie","lemma":"borderie","pos":"NOM"} ,
		{"word":"borderline","word_nosc":"borderline","lemma":"borderline","pos":"NOM"} ,
		{"word":"bordes","word_nosc":"bordes","lemma":"border","pos":"NOM"} ,
		{"word":"bordille","word_nosc":"bordille","lemma":"bordille","pos":"NOM"} ,
		{"word":"bordj","word_nosc":"bordj","lemma":"bordj","pos":"NOM"} ,
		{"word":"bords","word_nosc":"bords","lemma":"bord","pos":"NOM"} ,
		{"word":"bordure","word_nosc":"bordure","lemma":"bordure","pos":"NOM"} ,
		{"word":"bordures","word_nosc":"bordures","lemma":"bordure","pos":"NOM"} ,
		{"word":"bordé","word_nosc":"borde","lemma":"bordé","pos":"NOM"} ,
		{"word":"bordée","word_nosc":"bordee","lemma":"bordée","pos":"NOM"} ,
		{"word":"bordées","word_nosc":"bordees","lemma":"bordée","pos":"NOM"} ,
		{"word":"bordés","word_nosc":"bordes","lemma":"bordé","pos":"NOM"} ,
		{"word":"bore","word_nosc":"bore","lemma":"bore","pos":"NOM"} ,
		{"word":"borgne","word_nosc":"borgne","lemma":"borgne","pos":"NOM"} ,
		{"word":"borgnes","word_nosc":"borgnes","lemma":"borgne","pos":"NOM"} ,
		{"word":"borie","word_nosc":"borie","lemma":"borie","pos":"NOM"} ,
		{"word":"bornage","word_nosc":"bornage","lemma":"bornage","pos":"NOM"} ,
		{"word":"bornages","word_nosc":"bornages","lemma":"bornage","pos":"NOM"} ,
		{"word":"borne","word_nosc":"borne","lemma":"borne","pos":"NOM"} ,
		{"word":"borne-fontaine","word_nosc":"borne-fontaine","lemma":"borne-fontaine","pos":"NOM"} ,
		{"word":"bornes","word_nosc":"bornes","lemma":"borne","pos":"NOM"} ,
		{"word":"bornes-fontaines","word_nosc":"bornes-fontaines","lemma":"borne-fontaine","pos":"NOM"} ,
		{"word":"borough","word_nosc":"borough","lemma":"borough","pos":"NOM"} ,
		{"word":"borsalino","word_nosc":"borsalino","lemma":"borsalino","pos":"NOM"} ,
		{"word":"bortch","word_nosc":"bortch","lemma":"bortch","pos":"NOM"} ,
		{"word":"bortsch","word_nosc":"bortsch","lemma":"bortsch","pos":"NOM"} ,
		{"word":"borée","word_nosc":"boree","lemma":"borée","pos":"NOM"} ,
		{"word":"bosco","word_nosc":"bosco","lemma":"bosco","pos":"NOM"} ,
		{"word":"boskoop","word_nosc":"boskoop","lemma":"boskoop","pos":"NOM"} ,
		{"word":"bosniaque","word_nosc":"bosniaque","lemma":"bosniaque","pos":"NOM"} ,
		{"word":"bosniaques","word_nosc":"bosniaques","lemma":"bosniaque","pos":"NOM"} ,
		{"word":"bosquet","word_nosc":"bosquet","lemma":"bosquet","pos":"NOM"} ,
		{"word":"bosquets","word_nosc":"bosquets","lemma":"bosquet","pos":"NOM"} ,
		{"word":"boss","word_nosc":"boss","lemma":"boss","pos":"NOM"} ,
		{"word":"bossa-nova","word_nosc":"bossa-nova","lemma":"bossa-nova","pos":"NOM"} ,
		{"word":"bossage","word_nosc":"bossage","lemma":"bossage","pos":"NOM"} ,
		{"word":"bosse","word_nosc":"bosse","lemma":"bosse","pos":"NOM"} ,
		{"word":"bossellement","word_nosc":"bossellement","lemma":"bossellement","pos":"NOM"} ,
		{"word":"bossellements","word_nosc":"bossellements","lemma":"bossellement","pos":"NOM"} ,
		{"word":"bosselure","word_nosc":"bosselure","lemma":"bosselure","pos":"NOM"} ,
		{"word":"bosses","word_nosc":"bosses","lemma":"bosse","pos":"NOM"} ,
		{"word":"bosseur","word_nosc":"bosseur","lemma":"bosseur","pos":"NOM"} ,
		{"word":"bosseurs","word_nosc":"bosseurs","lemma":"bosseur","pos":"NOM"} ,
		{"word":"bosseuse","word_nosc":"bosseuse","lemma":"bosseur","pos":"NOM"} ,
		{"word":"bossoir","word_nosc":"bossoir","lemma":"bossoir","pos":"NOM"} ,
		{"word":"bossoirs","word_nosc":"bossoirs","lemma":"bossoir","pos":"NOM"} ,
		{"word":"bosson","word_nosc":"bosson","lemma":"bosson","pos":"NOM"} ,
		{"word":"bossons","word_nosc":"bossons","lemma":"bosson","pos":"NOM"} ,
		{"word":"bossu","word_nosc":"bossu","lemma":"bossu","pos":"NOM"} ,
		{"word":"bossus","word_nosc":"bossus","lemma":"bossu","pos":"NOM"} ,
		{"word":"boston","word_nosc":"boston","lemma":"boston","pos":"NOM"} ,
		{"word":"bostonien","word_nosc":"bostonien","lemma":"bostonien","pos":"NOM"} ,
		{"word":"bostoniens","word_nosc":"bostoniens","lemma":"bostonien","pos":"NOM"} ,
		{"word":"botanique","word_nosc":"botanique","lemma":"botanique","pos":"NOM"} ,
		{"word":"botaniste","word_nosc":"botaniste","lemma":"botaniste","pos":"NOM"} ,
		{"word":"botanistes","word_nosc":"botanistes","lemma":"botaniste","pos":"NOM"} ,
		{"word":"botte","word_nosc":"botte","lemma":"botte","pos":"NOM"} ,
		{"word":"bottes","word_nosc":"bottes","lemma":"botte","pos":"NOM"} ,
		{"word":"botteur","word_nosc":"botteur","lemma":"botteur","pos":"NOM"} ,
		{"word":"botteurs","word_nosc":"botteurs","lemma":"botteur","pos":"NOM"} ,
		{"word":"botticellienne","word_nosc":"botticellienne","lemma":"botticellien","pos":"NOM"} ,
		{"word":"bottier","word_nosc":"bottier","lemma":"bottier","pos":"NOM"} ,
		{"word":"bottiers","word_nosc":"bottiers","lemma":"bottier","pos":"NOM"} ,
		{"word":"bottillon","word_nosc":"bottillon","lemma":"bottillon","pos":"NOM"} ,
		{"word":"bottillons","word_nosc":"bottillons","lemma":"bottillon","pos":"NOM"} ,
		{"word":"bottin","word_nosc":"bottin","lemma":"bottin","pos":"NOM"} ,
		{"word":"bottine","word_nosc":"bottine","lemma":"bottine","pos":"NOM"} ,
		{"word":"bottines","word_nosc":"bottines","lemma":"bottine","pos":"NOM"} ,
		{"word":"bottins","word_nosc":"bottins","lemma":"bottin","pos":"NOM"} ,
		{"word":"botté","word_nosc":"botte","lemma":"botté","pos":"NOM"} ,
		{"word":"bottés","word_nosc":"bottes","lemma":"botté","pos":"NOM"} ,
		{"word":"botulisme","word_nosc":"botulisme","lemma":"botulisme","pos":"NOM"} ,
		{"word":"boubou","word_nosc":"boubou","lemma":"boubou","pos":"NOM"} ,
		{"word":"boubous","word_nosc":"boubous","lemma":"boubou","pos":"NOM"} ,
		{"word":"bouc","word_nosc":"bouc","lemma":"bouc","pos":"NOM"} ,
		{"word":"boucan","word_nosc":"boucan","lemma":"boucan","pos":"NOM"} ,
		{"word":"boucanerie","word_nosc":"boucanerie","lemma":"boucanerie","pos":"NOM"} ,
		{"word":"boucanier","word_nosc":"boucanier","lemma":"boucanier","pos":"NOM"} ,
		{"word":"boucaniers","word_nosc":"boucaniers","lemma":"boucanier","pos":"NOM"} ,
		{"word":"bouchage","word_nosc":"bouchage","lemma":"bouchage","pos":"NOM"} ,
		{"word":"boucharde","word_nosc":"boucharde","lemma":"boucharde","pos":"NOM"} ,
		{"word":"bouche","word_nosc":"bouche","lemma":"bouche","pos":"NOM"} ,
		{"word":"bouche-trou","word_nosc":"bouche-trou","lemma":"bouche-trou","pos":"NOM"} ,
		{"word":"bouche-trous","word_nosc":"bouche-trous","lemma":"bouche-trou","pos":"NOM"} ,
		{"word":"bouche-à-bouche","word_nosc":"bouche-a-bouche","lemma":"bouche-à-bouche","pos":"NOM"} ,
		{"word":"bouche-à-oreille","word_nosc":"bouche-a-oreille","lemma":"bouche-à-oreille","pos":"NOM"} ,
		{"word":"boucher","word_nosc":"boucher","lemma":"boucher","pos":"NOM"} ,
		{"word":"boucherie","word_nosc":"boucherie","lemma":"boucherie","pos":"NOM"} ,
		{"word":"boucheries","word_nosc":"boucheries","lemma":"boucherie","pos":"NOM"} ,
		{"word":"bouchers","word_nosc":"bouchers","lemma":"boucher","pos":"NOM"} ,
		{"word":"bouches","word_nosc":"bouches","lemma":"bouche","pos":"NOM"} ,
		{"word":"bouchon","word_nosc":"bouchon","lemma":"bouchon","pos":"NOM"} ,
		{"word":"bouchons","word_nosc":"bouchons","lemma":"bouchon","pos":"NOM"} ,
		{"word":"bouchot","word_nosc":"bouchot","lemma":"bouchot","pos":"NOM"} ,
		{"word":"bouchoteurs","word_nosc":"bouchoteurs","lemma":"bouchoteur","pos":"NOM"} ,
		{"word":"bouchots","word_nosc":"bouchots","lemma":"bouchot","pos":"NOM"} ,
		{"word":"bouchure","word_nosc":"bouchure","lemma":"bouchure","pos":"NOM"} ,
		{"word":"bouchère","word_nosc":"bouchere","lemma":"boucher","pos":"NOM"} ,
		{"word":"bouchères","word_nosc":"boucheres","lemma":"boucher","pos":"NOM"} ,
		{"word":"bouchée","word_nosc":"bouchee","lemma":"bouchée","pos":"NOM"} ,
		{"word":"bouchées","word_nosc":"bouchees","lemma":"bouchée","pos":"NOM"} ,
		{"word":"bouclage","word_nosc":"bouclage","lemma":"bouclage","pos":"NOM"} ,
		{"word":"boucle","word_nosc":"boucle","lemma":"boucle","pos":"NOM"} ,
		{"word":"boucles","word_nosc":"boucles","lemma":"boucle","pos":"NOM"} ,
		{"word":"bouclette","word_nosc":"bouclette","lemma":"bouclette","pos":"NOM"} ,
		{"word":"bouclettes","word_nosc":"bouclettes","lemma":"bouclette","pos":"NOM"} ,
		{"word":"bouclier","word_nosc":"bouclier","lemma":"bouclier","pos":"NOM"} ,
		{"word":"boucliers","word_nosc":"boucliers","lemma":"bouclier","pos":"NOM"} ,
		{"word":"boucs","word_nosc":"boucs","lemma":"bouc","pos":"NOM"} ,
		{"word":"bouddha","word_nosc":"bouddha","lemma":"bouddha","pos":"NOM"} ,
		{"word":"bouddhas","word_nosc":"bouddhas","lemma":"bouddha","pos":"NOM"} ,
		{"word":"bouddhisme","word_nosc":"bouddhisme","lemma":"bouddhisme","pos":"NOM"} ,
		{"word":"bouddhiste","word_nosc":"bouddhiste","lemma":"bouddhiste","pos":"NOM"} ,
		{"word":"bouddhistes","word_nosc":"bouddhistes","lemma":"bouddhiste","pos":"NOM"} ,
		{"word":"bouderie","word_nosc":"bouderie","lemma":"bouderie","pos":"NOM"} ,
		{"word":"bouderies","word_nosc":"bouderies","lemma":"bouderie","pos":"NOM"} ,
		{"word":"boudeur","word_nosc":"boudeur","lemma":"boudeur","pos":"NOM"} ,
		{"word":"boudeuse","word_nosc":"boudeuse","lemma":"boudeur","pos":"NOM"} ,
		{"word":"boudin","word_nosc":"boudin","lemma":"boudin","pos":"NOM"} ,
		{"word":"boudine","word_nosc":"boudine","lemma":"boudine","pos":"NOM"} ,
		{"word":"boudins","word_nosc":"boudins","lemma":"boudin","pos":"NOM"} ,
		{"word":"boudoir","word_nosc":"boudoir","lemma":"boudoir","pos":"NOM"} ,
		{"word":"boudoirs","word_nosc":"boudoirs","lemma":"boudoir","pos":"NOM"} ,
		{"word":"boue","word_nosc":"boue","lemma":"boue","pos":"NOM"} ,
		{"word":"boues","word_nosc":"boues","lemma":"boue","pos":"NOM"} ,
		{"word":"boueur","word_nosc":"boueur","lemma":"boueur","pos":"NOM"} ,
		{"word":"boueux","word_nosc":"boueux","lemma":"boueux","pos":"NOM"} ,
		{"word":"bouffant","word_nosc":"bouffant","lemma":"bouffant","pos":"NOM"} ,
		{"word":"bouffarde","word_nosc":"bouffarde","lemma":"bouffarde","pos":"NOM"} ,
		{"word":"bouffardes","word_nosc":"bouffardes","lemma":"bouffarde","pos":"NOM"} ,
		{"word":"bouffe","word_nosc":"bouffe","lemma":"bouffe","pos":"NOM"} ,
		{"word":"bouffes","word_nosc":"bouffes","lemma":"bouffe","pos":"NOM"} ,
		{"word":"bouffetance","word_nosc":"bouffetance","lemma":"bouffetance","pos":"NOM"} ,
		{"word":"bouffettes","word_nosc":"bouffettes","lemma":"bouffette","pos":"NOM"} ,
		{"word":"bouffeur","word_nosc":"bouffeur","lemma":"bouffeur","pos":"NOM"} ,
		{"word":"bouffeurs","word_nosc":"bouffeurs","lemma":"bouffeur","pos":"NOM"} ,
		{"word":"bouffeuse","word_nosc":"bouffeuse","lemma":"bouffeur","pos":"NOM"} ,
		{"word":"bouffeuses","word_nosc":"bouffeuses","lemma":"bouffeur","pos":"NOM"} ,
		{"word":"bouffissure","word_nosc":"bouffissure","lemma":"bouffissure","pos":"NOM"} ,
		{"word":"bouffissures","word_nosc":"bouffissures","lemma":"bouffissure","pos":"NOM"} ,
		{"word":"bouffon","word_nosc":"bouffon","lemma":"bouffon","pos":"NOM"} ,
		{"word":"bouffonne","word_nosc":"bouffonne","lemma":"bouffon","pos":"NOM"} ,
		{"word":"bouffonnerie","word_nosc":"bouffonnerie","lemma":"bouffonnerie","pos":"NOM"} ,
		{"word":"bouffonneries","word_nosc":"bouffonneries","lemma":"bouffonnerie","pos":"NOM"} ,
		{"word":"bouffons","word_nosc":"bouffons","lemma":"bouffon","pos":"NOM"} ,
		{"word":"bouffée","word_nosc":"bouffee","lemma":"bouffée","pos":"NOM"} ,
		{"word":"bouffées","word_nosc":"bouffees","lemma":"bouffée","pos":"NOM"} ,
		{"word":"bouftance","word_nosc":"bouftance","lemma":"bouftance","pos":"NOM"} ,
		{"word":"bougainvillier","word_nosc":"bougainvillier","lemma":"bougainvillier","pos":"NOM"} ,
		{"word":"bougainvilliers","word_nosc":"bougainvilliers","lemma":"bougainvillier","pos":"NOM"} ,
		{"word":"bougainvillée","word_nosc":"bougainvillee","lemma":"bougainvillée","pos":"NOM"} ,
		{"word":"bougainvillées","word_nosc":"bougainvillees","lemma":"bougainvillée","pos":"NOM"} ,
		{"word":"bouge","word_nosc":"bouge","lemma":"bouge","pos":"NOM"} ,
		{"word":"bougeoir","word_nosc":"bougeoir","lemma":"bougeoir","pos":"NOM"} ,
		{"word":"bougeoirs","word_nosc":"bougeoirs","lemma":"bougeoir","pos":"NOM"} ,
		{"word":"bougeotte","word_nosc":"bougeotte","lemma":"bougeotte","pos":"NOM"} ,
		{"word":"bouges","word_nosc":"bouges","lemma":"bouge","pos":"NOM"} ,
		{"word":"bougie","word_nosc":"bougie","lemma":"bougie","pos":"NOM"} ,
		{"word":"bougies","word_nosc":"bougies","lemma":"bougie","pos":"NOM"} ,
		{"word":"bougnat","word_nosc":"bougnat","lemma":"bougnat","pos":"NOM"} ,
		{"word":"bougnats","word_nosc":"bougnats","lemma":"bougnat","pos":"NOM"} ,
		{"word":"bougnoul","word_nosc":"bougnoul","lemma":"bougnoul","pos":"NOM"} ,
		{"word":"bougnoule","word_nosc":"bougnoule","lemma":"bougnoule","pos":"NOM"} ,
		{"word":"bougnoules","word_nosc":"bougnoules","lemma":"bougnoule","pos":"NOM"} ,
		{"word":"bougonnement","word_nosc":"bougonnement","lemma":"bougonnement","pos":"NOM"} ,
		{"word":"bougran","word_nosc":"bougran","lemma":"bougran","pos":"NOM"} ,
		{"word":"bougre","word_nosc":"bougre","lemma":"bougre","pos":"NOM"} ,
		{"word":"bougrerie","word_nosc":"bougrerie","lemma":"bougrerie","pos":"NOM"} ,
		{"word":"bougreries","word_nosc":"bougreries","lemma":"bougrerie","pos":"NOM"} ,
		{"word":"bougres","word_nosc":"bougres","lemma":"bougre","pos":"NOM"} ,
		{"word":"bougresse","word_nosc":"bougresse","lemma":"bougresse","pos":"NOM"} ,
		{"word":"bougé","word_nosc":"bouge","lemma":"bougé","pos":"NOM"} ,
		{"word":"boui-boui","word_nosc":"boui-boui","lemma":"boui-boui","pos":"NOM"} ,
		{"word":"bouiboui","word_nosc":"bouiboui","lemma":"bouiboui","pos":"NOM"} ,
		{"word":"bouibouis","word_nosc":"bouibouis","lemma":"bouiboui","pos":"NOM"} ,
		{"word":"bouif","word_nosc":"bouif","lemma":"bouif","pos":"NOM"} ,
		{"word":"bouillabaisse","word_nosc":"bouillabaisse","lemma":"bouillabaisse","pos":"NOM"} ,
		{"word":"bouillasse","word_nosc":"bouillasse","lemma":"bouillasse","pos":"NOM"} ,
		{"word":"bouille","word_nosc":"bouille","lemma":"bouille","pos":"NOM"} ,
		{"word":"bouilles","word_nosc":"bouilles","lemma":"bouille","pos":"NOM"} ,
		{"word":"bouilleur","word_nosc":"bouilleur","lemma":"bouilleur","pos":"NOM"} ,
		{"word":"bouilleurs","word_nosc":"bouilleurs","lemma":"bouilleur","pos":"NOM"} ,
		{"word":"bouilli","word_nosc":"bouilli","lemma":"bouilli","pos":"NOM"} ,
		{"word":"bouillie","word_nosc":"bouillie","lemma":"bouillie","pos":"NOM"} ,
		{"word":"bouillies","word_nosc":"bouillies","lemma":"bouillie","pos":"NOM"} ,
		{"word":"bouillis","word_nosc":"bouillis","lemma":"bouilli","pos":"NOM"} ,
		{"word":"bouilloire","word_nosc":"bouilloire","lemma":"bouilloire","pos":"NOM"} ,
		{"word":"bouilloires","word_nosc":"bouilloires","lemma":"bouilloire","pos":"NOM"} ,
		{"word":"bouillon","word_nosc":"bouillon","lemma":"bouillon","pos":"NOM"} ,
		{"word":"bouillonnement","word_nosc":"bouillonnement","lemma":"bouillonnement","pos":"NOM"} ,
		{"word":"bouillonnements","word_nosc":"bouillonnements","lemma":"bouillonnement","pos":"NOM"} ,
		{"word":"bouillonné","word_nosc":"bouillonne","lemma":"bouillonné","pos":"NOM"} ,
		{"word":"bouillonnés","word_nosc":"bouillonnes","lemma":"bouillonné","pos":"NOM"} ,
		{"word":"bouillons","word_nosc":"bouillons","lemma":"bouillon","pos":"NOM"} ,
		{"word":"bouillotte","word_nosc":"bouillotte","lemma":"bouillotte","pos":"NOM"} ,
		{"word":"bouillottes","word_nosc":"bouillottes","lemma":"bouillotte","pos":"NOM"} ,
		{"word":"bouis-bouis","word_nosc":"bouis-bouis","lemma":"boui-boui","pos":"NOM"} ,
		{"word":"boukha","word_nosc":"boukha","lemma":"boukha","pos":"NOM"} ,
		{"word":"boulange","word_nosc":"boulange","lemma":"boulange","pos":"NOM"} ,
		{"word":"boulanger","word_nosc":"boulanger","lemma":"boulanger","pos":"NOM"} ,
		{"word":"boulanger-pâtissier","word_nosc":"boulanger-patissier","lemma":"boulanger-pâtissier","pos":"NOM"} ,
		{"word":"boulangerie","word_nosc":"boulangerie","lemma":"boulangerie","pos":"NOM"} ,
		{"word":"boulangeries","word_nosc":"boulangeries","lemma":"boulangerie","pos":"NOM"} ,
		{"word":"boulangers","word_nosc":"boulangers","lemma":"boulanger","pos":"NOM"} ,
		{"word":"boulangisme","word_nosc":"boulangisme","lemma":"boulangisme","pos":"NOM"} ,
		{"word":"boulangère","word_nosc":"boulangere","lemma":"boulanger","pos":"NOM"} ,
		{"word":"boulangères","word_nosc":"boulangeres","lemma":"boulanger","pos":"NOM"} ,
		{"word":"boulants","word_nosc":"boulants","lemma":"boulant","pos":"NOM"} ,
		{"word":"boulder","word_nosc":"boulder","lemma":"boulder","pos":"NOM"} ,
		{"word":"boule","word_nosc":"boule","lemma":"boule","pos":"NOM"} ,
		{"word":"boule-de-neige","word_nosc":"boule-de-neige","lemma":"boule-de-neige","pos":"NOM"} ,
		{"word":"bouleau","word_nosc":"bouleau","lemma":"bouleau","pos":"NOM"} ,
		{"word":"bouleaux","word_nosc":"bouleaux","lemma":"bouleau","pos":"NOM"} ,
		{"word":"bouledogue","word_nosc":"bouledogue","lemma":"bouledogue","pos":"NOM"} ,
		{"word":"bouledogues","word_nosc":"bouledogues","lemma":"bouledogue","pos":"NOM"} ,
		{"word":"boules","word_nosc":"boules","lemma":"boule","pos":"NOM"} ,
		{"word":"boulet","word_nosc":"boulet","lemma":"boulet","pos":"NOM"} ,
		{"word":"boulets","word_nosc":"boulets","lemma":"boulet","pos":"NOM"} ,
		{"word":"boulette","word_nosc":"boulette","lemma":"boulette","pos":"NOM"} ,
		{"word":"boulettes","word_nosc":"boulettes","lemma":"boulette","pos":"NOM"} ,
		{"word":"boulevard","word_nosc":"boulevard","lemma":"boulevard","pos":"NOM"} ,
		{"word":"boulevardier","word_nosc":"boulevardier","lemma":"boulevardier","pos":"NOM"} ,
		{"word":"boulevardiers","word_nosc":"boulevardiers","lemma":"boulevardier","pos":"NOM"} ,
		{"word":"boulevards","word_nosc":"boulevards","lemma":"boulevard","pos":"NOM"} ,
		{"word":"bouleversement","word_nosc":"bouleversement","lemma":"bouleversement","pos":"NOM"} ,
		{"word":"bouleversements","word_nosc":"bouleversements","lemma":"bouleversement","pos":"NOM"} ,
		{"word":"boulgour","word_nosc":"boulgour","lemma":"boulgour","pos":"NOM"} ,
		{"word":"boulier","word_nosc":"boulier","lemma":"boulier","pos":"NOM"} ,
		{"word":"bouliers","word_nosc":"bouliers","lemma":"boulier","pos":"NOM"} ,
		{"word":"boulimie","word_nosc":"boulimie","lemma":"boulimie","pos":"NOM"} ,
		{"word":"boulimies","word_nosc":"boulimies","lemma":"boulimie","pos":"NOM"} ,
		{"word":"boulimique","word_nosc":"boulimique","lemma":"boulimique","pos":"NOM"} ,
		{"word":"boulimiques","word_nosc":"boulimiques","lemma":"boulimique","pos":"NOM"} ,
		{"word":"bouline","word_nosc":"bouline","lemma":"bouline","pos":"NOM"} ,
		{"word":"boulines","word_nosc":"boulines","lemma":"bouline","pos":"NOM"} ,
		{"word":"boulingrins","word_nosc":"boulingrins","lemma":"boulingrin","pos":"NOM"} ,
		{"word":"boulins","word_nosc":"boulins","lemma":"boulin","pos":"NOM"} ,
		{"word":"bouliste","word_nosc":"bouliste","lemma":"bouliste","pos":"NOM"} ,
		{"word":"boulistes","word_nosc":"boulistes","lemma":"bouliste","pos":"NOM"} ,
		{"word":"boulle","word_nosc":"boulle","lemma":"boulle","pos":"NOM"} ,
		{"word":"boulodrome","word_nosc":"boulodrome","lemma":"boulodrome","pos":"NOM"} ,
		{"word":"bouloir","word_nosc":"bouloir","lemma":"bouloir","pos":"NOM"} ,
		{"word":"boulon","word_nosc":"boulon","lemma":"boulon","pos":"NOM"} ,
		{"word":"boulonnage","word_nosc":"boulonnage","lemma":"boulonnage","pos":"NOM"} ,
		{"word":"boulonnerie","word_nosc":"boulonnerie","lemma":"boulonnerie","pos":"NOM"} ,
		{"word":"boulons","word_nosc":"boulons","lemma":"boulon","pos":"NOM"} ,
		{"word":"boulot","word_nosc":"boulot","lemma":"boulot","pos":"NOM"} ,
		{"word":"boulots","word_nosc":"boulots","lemma":"boulot","pos":"NOM"} ,
		{"word":"boulotte","word_nosc":"boulotte","lemma":"boulotte","pos":"NOM"} ,
		{"word":"boulottes","word_nosc":"boulottes","lemma":"boulotte","pos":"NOM"} ,
		{"word":"boum","word_nosc":"boum","lemma":"boum","pos":"NOM"} ,
		{"word":"boums","word_nosc":"boums","lemma":"boum","pos":"NOM"} ,
		{"word":"bouniouls","word_nosc":"bouniouls","lemma":"bounioul","pos":"NOM"} ,
		{"word":"bouquet","word_nosc":"bouquet","lemma":"bouquet","pos":"NOM"} ,
		{"word":"bouquetier","word_nosc":"bouquetier","lemma":"bouquetier","pos":"NOM"} ,
		{"word":"bouquetin","word_nosc":"bouquetin","lemma":"bouquetin","pos":"NOM"} ,
		{"word":"bouquetins","word_nosc":"bouquetins","lemma":"bouquetin","pos":"NOM"} ,
		{"word":"bouquetière","word_nosc":"bouquetiere","lemma":"bouquetier","pos":"NOM"} ,
		{"word":"bouquets","word_nosc":"bouquets","lemma":"bouquet","pos":"NOM"} ,
		{"word":"bouquin","word_nosc":"bouquin","lemma":"bouquin","pos":"NOM"} ,
		{"word":"bouquinage","word_nosc":"bouquinage","lemma":"bouquinage","pos":"NOM"} ,
		{"word":"bouquineur","word_nosc":"bouquineur","lemma":"bouquineur","pos":"NOM"} ,
		{"word":"bouquiniste","word_nosc":"bouquiniste","lemma":"bouquiniste","pos":"NOM"} ,
		{"word":"bouquinistes","word_nosc":"bouquinistes","lemma":"bouquiniste","pos":"NOM"} ,
		{"word":"bouquins","word_nosc":"bouquins","lemma":"bouquin","pos":"NOM"} ,
		{"word":"bour","word_nosc":"bour","lemma":"bour","pos":"NOM"} ,
		{"word":"bourbe","word_nosc":"bourbe","lemma":"bourbe","pos":"NOM"} ,
		{"word":"bourbier","word_nosc":"bourbier","lemma":"bourbier","pos":"NOM"} ,
		{"word":"bourbiers","word_nosc":"bourbiers","lemma":"bourbier","pos":"NOM"} ,
		{"word":"bourbon","word_nosc":"bourbon","lemma":"bourbon","pos":"NOM"} ,
		{"word":"bourbons","word_nosc":"bourbons","lemma":"bourbon","pos":"NOM"} ,
		{"word":"bourdaine","word_nosc":"bourdaine","lemma":"bourdaine","pos":"NOM"} ,
		{"word":"bourdaines","word_nosc":"bourdaines","lemma":"bourdaine","pos":"NOM"} ,
		{"word":"bourdalous","word_nosc":"bourdalous","lemma":"bourdalou","pos":"NOM"} ,
		{"word":"bourde","word_nosc":"bourde","lemma":"bourde","pos":"NOM"} ,
		{"word":"bourdes","word_nosc":"bourdes","lemma":"bourde","pos":"NOM"} ,
		{"word":"bourdillon","word_nosc":"bourdillon","lemma":"bourdillon","pos":"NOM"} ,
		{"word":"bourdon","word_nosc":"bourdon","lemma":"bourdon","pos":"NOM"} ,
		{"word":"bourdonnement","word_nosc":"bourdonnement","lemma":"bourdonnement","pos":"NOM"} ,
		{"word":"bourdonnements","word_nosc":"bourdonnements","lemma":"bourdonnement","pos":"NOM"} ,
		{"word":"bourdons","word_nosc":"bourdons","lemma":"bourdon","pos":"NOM"} ,
		{"word":"bourg","word_nosc":"bourg","lemma":"bourg","pos":"NOM"} ,
		{"word":"bourgade","word_nosc":"bourgade","lemma":"bourgade","pos":"NOM"} ,
		{"word":"bourgades","word_nosc":"bourgades","lemma":"bourgade","pos":"NOM"} ,
		{"word":"bourge","word_nosc":"bourge","lemma":"bourge","pos":"NOM"} ,
		{"word":"bourgeois","word_nosc":"bourgeois","lemma":"bourgeois","pos":"NOM"} ,
		{"word":"bourgeoise","word_nosc":"bourgeoise","lemma":"bourgeois","pos":"NOM"} ,
		{"word":"bourgeoises","word_nosc":"bourgeoises","lemma":"bourgeois","pos":"NOM"} ,
		{"word":"bourgeoisie","word_nosc":"bourgeoisie","lemma":"bourgeoisie","pos":"NOM"} ,
		{"word":"bourgeoisies","word_nosc":"bourgeoisies","lemma":"bourgeoisie","pos":"NOM"} ,
		{"word":"bourgeoisisme","word_nosc":"bourgeoisisme","lemma":"bourgeoisisme","pos":"NOM"} ,
		{"word":"bourgeon","word_nosc":"bourgeon","lemma":"bourgeon","pos":"NOM"} ,
		{"word":"bourgeonnement","word_nosc":"bourgeonnement","lemma":"bourgeonnement","pos":"NOM"} ,
		{"word":"bourgeonnements","word_nosc":"bourgeonnements","lemma":"bourgeonnement","pos":"NOM"} ,
		{"word":"bourgeons","word_nosc":"bourgeons","lemma":"bourgeon","pos":"NOM"} ,
		{"word":"bourgeron","word_nosc":"bourgeron","lemma":"bourgeron","pos":"NOM"} ,
		{"word":"bourgerons","word_nosc":"bourgerons","lemma":"bourgeron","pos":"NOM"} ,
		{"word":"bourges","word_nosc":"bourges","lemma":"bourge","pos":"NOM"} ,
		{"word":"bourgmestre","word_nosc":"bourgmestre","lemma":"bourgmestre","pos":"NOM"} ,
		{"word":"bourgmestres","word_nosc":"bourgmestres","lemma":"bourgmestre","pos":"NOM"} ,
		{"word":"bourgogne","word_nosc":"bourgogne","lemma":"bourgogne","pos":"NOM"} ,
		{"word":"bourgognes","word_nosc":"bourgognes","lemma":"bourgogne","pos":"NOM"} ,
		{"word":"bourgs","word_nosc":"bourgs","lemma":"bourg","pos":"NOM"} ,
		{"word":"bourgueil","word_nosc":"bourgueil","lemma":"bourgueil","pos":"NOM"} ,
		{"word":"bourgues","word_nosc":"bourgues","lemma":"bourgue","pos":"NOM"} ,
		{"word":"bourguignon","word_nosc":"bourguignon","lemma":"bourguignon","pos":"NOM"} ,
		{"word":"bourguignonne","word_nosc":"bourguignonne","lemma":"bourguignon","pos":"NOM"} ,
		{"word":"bourguignonnes","word_nosc":"bourguignonnes","lemma":"bourguignon","pos":"NOM"} ,
		{"word":"bourguignons","word_nosc":"bourguignons","lemma":"bourguignon","pos":"NOM"} ,
		{"word":"bouriates","word_nosc":"bouriates","lemma":"bouriate","pos":"NOM"} ,
		{"word":"bourlingue","word_nosc":"bourlingue","lemma":"bourlingue","pos":"NOM"} ,
		{"word":"bourlingueur","word_nosc":"bourlingueur","lemma":"bourlingueur","pos":"NOM"} ,
		{"word":"bourlingueuses","word_nosc":"bourlingueuses","lemma":"bourlingueur","pos":"NOM"} ,
		{"word":"bourrache","word_nosc":"bourrache","lemma":"bourrache","pos":"NOM"} ,
		{"word":"bourrade","word_nosc":"bourrade","lemma":"bourrade","pos":"NOM"} ,
		{"word":"bourrades","word_nosc":"bourrades","lemma":"bourrade","pos":"NOM"} ,
		{"word":"bourrage","word_nosc":"bourrage","lemma":"bourrage","pos":"NOM"} ,
		{"word":"bourrages","word_nosc":"bourrages","lemma":"bourrage","pos":"NOM"} ,
		{"word":"bourrasque","word_nosc":"bourrasque","lemma":"bourrasque","pos":"NOM"} ,
		{"word":"bourrasques","word_nosc":"bourrasques","lemma":"bourrasque","pos":"NOM"} ,
		{"word":"bourre","word_nosc":"bourre","lemma":"bourre","pos":"NOM"} ,
		{"word":"bourre-mou","word_nosc":"bourre-mou","lemma":"bourre-mou","pos":"NOM"} ,
		{"word":"bourre-pif","word_nosc":"bourre-pif","lemma":"bourre-pif","pos":"NOM"} ,
		{"word":"bourreau","word_nosc":"bourreau","lemma":"bourreau","pos":"NOM"} ,
		{"word":"bourreaux","word_nosc":"bourreaux","lemma":"bourreau","pos":"NOM"} ,
		{"word":"bourrelet","word_nosc":"bourrelet","lemma":"bourrelet","pos":"NOM"} ,
		{"word":"bourrelets","word_nosc":"bourrelets","lemma":"bourrelet","pos":"NOM"} ,
		{"word":"bourrelier","word_nosc":"bourrelier","lemma":"bourrelier","pos":"NOM"} ,
		{"word":"bourreliers","word_nosc":"bourreliers","lemma":"bourrelier","pos":"NOM"} ,
		{"word":"bourrellerie","word_nosc":"bourrellerie","lemma":"bourrellerie","pos":"NOM"} ,
		{"word":"bourres","word_nosc":"bourres","lemma":"bourre","pos":"NOM"} ,
		{"word":"bourreur","word_nosc":"bourreur","lemma":"bourreur","pos":"NOM"} ,
		{"word":"bourriche","word_nosc":"bourriche","lemma":"bourriche","pos":"NOM"} ,
		{"word":"bourriches","word_nosc":"bourriches","lemma":"bourriche","pos":"NOM"} ,
		{"word":"bourrichon","word_nosc":"bourrichon","lemma":"bourrichon","pos":"NOM"} ,
		{"word":"bourricot","word_nosc":"bourricot","lemma":"bourricot","pos":"NOM"} ,
		{"word":"bourricots","word_nosc":"bourricots","lemma":"bourricot","pos":"NOM"} ,
		{"word":"bourrier","word_nosc":"bourrier","lemma":"bourrier","pos":"NOM"} ,
		{"word":"bourrin","word_nosc":"bourrin","lemma":"bourrin","pos":"NOM"} ,
		{"word":"bourrine","word_nosc":"bourrine","lemma":"bourrine","pos":"NOM"} ,
		{"word":"bourrins","word_nosc":"bourrins","lemma":"bourrin","pos":"NOM"} ,
		{"word":"bourrique","word_nosc":"bourrique","lemma":"bourrique","pos":"NOM"} ,
		{"word":"bourriques","word_nosc":"bourriques","lemma":"bourrique","pos":"NOM"} ,
		{"word":"bourriquet","word_nosc":"bourriquet","lemma":"bourriquet","pos":"NOM"} ,
		{"word":"bourriquets","word_nosc":"bourriquets","lemma":"bourriquet","pos":"NOM"} ,
		{"word":"bourrée","word_nosc":"bourree","lemma":"bourré","pos":"NOM"} ,
		{"word":"bourrées","word_nosc":"bourrees","lemma":"bourrée","pos":"NOM"} ,
		{"word":"bourse","word_nosc":"bourse","lemma":"bourse","pos":"NOM"} ,
		{"word":"bourses","word_nosc":"bourses","lemma":"bourse","pos":"NOM"} ,
		{"word":"boursicot","word_nosc":"boursicot","lemma":"boursicot","pos":"NOM"} ,
		{"word":"boursicotage","word_nosc":"boursicotage","lemma":"boursicotage","pos":"NOM"} ,
		{"word":"boursicoteur","word_nosc":"boursicoteur","lemma":"boursicoteur","pos":"NOM"} ,
		{"word":"boursicoteurs","word_nosc":"boursicoteurs","lemma":"boursicoteur","pos":"NOM"} ,
		{"word":"boursier","word_nosc":"boursier","lemma":"boursier","pos":"NOM"} ,
		{"word":"boursiers","word_nosc":"boursiers","lemma":"boursier","pos":"NOM"} ,
		{"word":"boursière","word_nosc":"boursiere","lemma":"boursier","pos":"NOM"} ,
		{"word":"boursouflement","word_nosc":"boursouflement","lemma":"boursouflement","pos":"NOM"} ,
		{"word":"boursouflure","word_nosc":"boursouflure","lemma":"boursouflure","pos":"NOM"} ,
		{"word":"boursouflures","word_nosc":"boursouflures","lemma":"boursouflure","pos":"NOM"} ,
		{"word":"bousards","word_nosc":"bousards","lemma":"bousard","pos":"NOM"} ,
		{"word":"bousbir","word_nosc":"bousbir","lemma":"bousbir","pos":"NOM"} ,
		{"word":"bousbirs","word_nosc":"bousbirs","lemma":"bousbir","pos":"NOM"} ,
		{"word":"bousculade","word_nosc":"bousculade","lemma":"bousculade","pos":"NOM"} ,
		{"word":"bousculades","word_nosc":"bousculades","lemma":"bousculade","pos":"NOM"} ,
		{"word":"bouse","word_nosc":"bouse","lemma":"bouse","pos":"NOM"} ,
		{"word":"bouses","word_nosc":"bouses","lemma":"bouse","pos":"NOM"} ,
		{"word":"bouseuse","word_nosc":"bouseuse","lemma":"bouseux","pos":"NOM"} ,
		{"word":"bouseux","word_nosc":"bouseux","lemma":"bouseux","pos":"NOM"} ,
		{"word":"bousier","word_nosc":"bousier","lemma":"bousier","pos":"NOM"} ,
		{"word":"bousiers","word_nosc":"bousiers","lemma":"bousier","pos":"NOM"} ,
		{"word":"bousillage","word_nosc":"bousillage","lemma":"bousillage","pos":"NOM"} ,
		{"word":"bousillages","word_nosc":"bousillages","lemma":"bousillage","pos":"NOM"} ,
		{"word":"bousilleur","word_nosc":"bousilleur","lemma":"bousilleur","pos":"NOM"} ,
		{"word":"bousin","word_nosc":"bousin","lemma":"bousin","pos":"NOM"} ,
		{"word":"bousine","word_nosc":"bousine","lemma":"bousine","pos":"NOM"} ,
		{"word":"bousins","word_nosc":"bousins","lemma":"bousin","pos":"NOM"} ,
		{"word":"boussole","word_nosc":"boussole","lemma":"boussole","pos":"NOM"} ,
		{"word":"boussoles","word_nosc":"boussoles","lemma":"boussole","pos":"NOM"} ,
		{"word":"boustifaille","word_nosc":"boustifaille","lemma":"boustifaille","pos":"NOM"} ,
		{"word":"boustifailles","word_nosc":"boustifailles","lemma":"boustifaille","pos":"NOM"} ,
		{"word":"boustifailleuse","word_nosc":"boustifailleuse","lemma":"boustifailleur","pos":"NOM"} ,
		{"word":"boustrophédon","word_nosc":"boustrophedon","lemma":"boustrophédon","pos":"NOM"} ,
		{"word":"bout","word_nosc":"bout","lemma":"bout","pos":"NOM"} ,
		{"word":"bout-dehors","word_nosc":"bout-dehors","lemma":"bout-dehors","pos":"NOM"} ,
		{"word":"bout-rimé","word_nosc":"bout-rime","lemma":"bout-rimé","pos":"NOM"} ,
		{"word":"boutade","word_nosc":"boutade","lemma":"boutade","pos":"NOM"} ,
		{"word":"boutades","word_nosc":"boutades","lemma":"boutade","pos":"NOM"} ,
		{"word":"boutanche","word_nosc":"boutanche","lemma":"boutanche","pos":"NOM"} ,
		{"word":"boutanches","word_nosc":"boutanches","lemma":"boutanche","pos":"NOM"} ,
		{"word":"boutargue","word_nosc":"boutargue","lemma":"boutargue","pos":"NOM"} ,
		{"word":"boute-en-train","word_nosc":"boute-en-train","lemma":"boute-en-train","pos":"NOM"} ,
		{"word":"boutefeu","word_nosc":"boutefeu","lemma":"boutefeu","pos":"NOM"} ,
		{"word":"boutefeux","word_nosc":"boutefeux","lemma":"boutefeux","pos":"NOM"} ,
		{"word":"bouteille","word_nosc":"bouteille","lemma":"bouteille","pos":"NOM"} ,
		{"word":"bouteilles","word_nosc":"bouteilles","lemma":"bouteille","pos":"NOM"} ,
		{"word":"bouteillon","word_nosc":"bouteillon","lemma":"bouteillon","pos":"NOM"} ,
		{"word":"bouteillons","word_nosc":"bouteillons","lemma":"bouteillon","pos":"NOM"} ,
		{"word":"bouteur","word_nosc":"bouteur","lemma":"bouteur","pos":"NOM"} ,
		{"word":"bouteurs","word_nosc":"bouteurs","lemma":"bouteur","pos":"NOM"} ,
		{"word":"bouthéon","word_nosc":"boutheon","lemma":"bouthéon","pos":"NOM"} ,
		{"word":"bouthéons","word_nosc":"boutheons","lemma":"bouthéon","pos":"NOM"} ,
		{"word":"boutillier","word_nosc":"boutillier","lemma":"boutillier","pos":"NOM"} ,
		{"word":"boutique","word_nosc":"boutique","lemma":"boutique","pos":"NOM"} ,
		{"word":"boutique-cadeaux","word_nosc":"boutique-cadeaux","lemma":"boutique-cadeaux","pos":"NOM"} ,
		{"word":"boutiques","word_nosc":"boutiques","lemma":"boutique","pos":"NOM"} ,
		{"word":"boutiquier","word_nosc":"boutiquier","lemma":"boutiquier","pos":"NOM"} ,
		{"word":"boutiquiers","word_nosc":"boutiquiers","lemma":"boutiquier","pos":"NOM"} ,
		{"word":"boutiquière","word_nosc":"boutiquiere","lemma":"boutiquier","pos":"NOM"} ,
		{"word":"boutiquières","word_nosc":"boutiquieres","lemma":"boutiquier","pos":"NOM"} ,
		{"word":"boutisses","word_nosc":"boutisses","lemma":"boutisse","pos":"NOM"} ,
		{"word":"boutoir","word_nosc":"boutoir","lemma":"boutoir","pos":"NOM"} ,
		{"word":"bouton","word_nosc":"bouton","lemma":"bouton","pos":"NOM"} ,
		{"word":"bouton-d'or","word_nosc":"bouton-d'or","lemma":"bouton-d'or","pos":"NOM"} ,
		{"word":"bouton-pression","word_nosc":"bouton-pression","lemma":"bouton-pression","pos":"NOM"} ,
		{"word":"boutonnage","word_nosc":"boutonnage","lemma":"boutonnage","pos":"NOM"} ,
		{"word":"boutonnière","word_nosc":"boutonniere","lemma":"boutonnière","pos":"NOM"} ,
		{"word":"boutonnières","word_nosc":"boutonnieres","lemma":"boutonnière","pos":"NOM"} ,
		{"word":"boutons","word_nosc":"boutons","lemma":"bouton","pos":"NOM"} ,
		{"word":"boutons-d'or","word_nosc":"boutons-d'or","lemma":"bouton-d'or","pos":"NOM"} ,
		{"word":"boutons-pression","word_nosc":"boutons-pression","lemma":"bouton-pression","pos":"NOM"} ,
		{"word":"boutre","word_nosc":"boutre","lemma":"boutre","pos":"NOM"} ,
		{"word":"boutres","word_nosc":"boutres","lemma":"boutre","pos":"NOM"} ,
		{"word":"bouts","word_nosc":"bouts","lemma":"bout","pos":"NOM"} ,
		{"word":"bouts-rimés","word_nosc":"bouts-rimes","lemma":"bout-rimé","pos":"NOM"} ,
		{"word":"bouture","word_nosc":"bouture","lemma":"bouture","pos":"NOM"} ,
		{"word":"boutures","word_nosc":"boutures","lemma":"bouture","pos":"NOM"} ,
		{"word":"bouvet","word_nosc":"bouvet","lemma":"bouvet","pos":"NOM"} ,
		{"word":"bouvier","word_nosc":"bouvier","lemma":"bouvier","pos":"NOM"} ,
		{"word":"bouviers","word_nosc":"bouviers","lemma":"bouvier","pos":"NOM"} ,
		{"word":"bouvillon","word_nosc":"bouvillon","lemma":"bouvillon","pos":"NOM"} ,
		{"word":"bouvreuil","word_nosc":"bouvreuil","lemma":"bouvreuil","pos":"NOM"} ,
		{"word":"bouvreuils","word_nosc":"bouvreuils","lemma":"bouvreuil","pos":"NOM"} ,
		{"word":"bouzine","word_nosc":"bouzine","lemma":"bouzine","pos":"NOM"} ,
		{"word":"bouzouki","word_nosc":"bouzouki","lemma":"bouzouki","pos":"NOM"} ,
		{"word":"bouzy","word_nosc":"bouzy","lemma":"bouzy","pos":"NOM"} ,
		{"word":"bouée","word_nosc":"bouee","lemma":"bouée","pos":"NOM"} ,
		{"word":"bouées","word_nosc":"bouees","lemma":"bouée","pos":"NOM"} ,
		{"word":"bovidé","word_nosc":"bovide","lemma":"bovidé","pos":"NOM"} ,
		{"word":"bovidés","word_nosc":"bovides","lemma":"bovidé","pos":"NOM"} ,
		{"word":"bovin","word_nosc":"bovin","lemma":"bovin","pos":"NOM"} ,
		{"word":"bovins","word_nosc":"bovins","lemma":"bovin","pos":"NOM"} ,
		{"word":"bow-window","word_nosc":"bow-window","lemma":"bow-window","pos":"NOM"} ,
		{"word":"bow-windows","word_nosc":"bow-windows","lemma":"bow-window","pos":"NOM"} ,
		{"word":"bowling","word_nosc":"bowling","lemma":"bowling","pos":"NOM"} ,
		{"word":"box","word_nosc":"box","lemma":"box","pos":"NOM"} ,
		{"word":"box-calf","word_nosc":"box-calf","lemma":"box-calf","pos":"NOM"} ,
		{"word":"box-office","word_nosc":"box-office","lemma":"box-office","pos":"NOM"} ,
		{"word":"box-offices","word_nosc":"box-offices","lemma":"box-office","pos":"NOM"} ,
		{"word":"boxe","word_nosc":"boxe","lemma":"boxe","pos":"NOM"} ,
		{"word":"boxer","word_nosc":"boxer","lemma":"boxer","pos":"NOM"} ,
		{"word":"boxer-short","word_nosc":"boxer-short","lemma":"boxer-short","pos":"NOM"} ,
		{"word":"boxers","word_nosc":"boxers","lemma":"boxer","pos":"NOM"} ,
		{"word":"boxes","word_nosc":"boxes","lemma":"boxe","pos":"NOM"} ,
		{"word":"boxeur","word_nosc":"boxeur","lemma":"boxeur","pos":"NOM"} ,
		{"word":"boxeurs","word_nosc":"boxeurs","lemma":"boxeur","pos":"NOM"} ,
		{"word":"boxeuse","word_nosc":"boxeuse","lemma":"boxeur","pos":"NOM"} ,
		{"word":"boxon","word_nosc":"boxon","lemma":"boxon","pos":"NOM"} ,
		{"word":"boxons","word_nosc":"boxons","lemma":"boxon","pos":"NOM"} ,
		{"word":"boy","word_nosc":"boy","lemma":"boy","pos":"NOM"} ,
		{"word":"boy-friend","word_nosc":"boy-friend","lemma":"boy-friend","pos":"NOM"} ,
		{"word":"boy-scout","word_nosc":"boy-scout","lemma":"boy-scout","pos":"NOM"} ,
		{"word":"boy-scouts","word_nosc":"boy-scouts","lemma":"boy-scout","pos":"NOM"} ,
		{"word":"boyard","word_nosc":"boyard","lemma":"boyard","pos":"NOM"} ,
		{"word":"boyards","word_nosc":"boyards","lemma":"boyard","pos":"NOM"} ,
		{"word":"boyau","word_nosc":"boyau","lemma":"boyau","pos":"NOM"} ,
		{"word":"boyaux","word_nosc":"boyaux","lemma":"boyau","pos":"NOM"} ,
		{"word":"boycott","word_nosc":"boycott","lemma":"boycott","pos":"NOM"} ,
		{"word":"boys","word_nosc":"boys","lemma":"boy","pos":"NOM"} ,
		{"word":"bozo","word_nosc":"bozo","lemma":"bozo","pos":"NOM"} ,
		{"word":"boëttes","word_nosc":"boettes","lemma":"boëtte","pos":"NOM"} ,
		{"word":"boîte","word_nosc":"boite","lemma":"boîte","pos":"NOM"} ,
		{"word":"boîtes","word_nosc":"boites","lemma":"boîte","pos":"NOM"} ,
		{"word":"boîtes-repas","word_nosc":"boites-repas","lemma":"boîte-repas","pos":"NOM"} ,
		{"word":"boîtier","word_nosc":"boitier","lemma":"boîtier","pos":"NOM"} ,
		{"word":"boîtiers","word_nosc":"boitiers","lemma":"boîtier","pos":"NOM"} ,
		{"word":"brabant","word_nosc":"brabant","lemma":"brabant","pos":"NOM"} ,
		{"word":"brabançons","word_nosc":"brabancons","lemma":"brabançon","pos":"NOM"} ,
		{"word":"bracelet","word_nosc":"bracelet","lemma":"bracelet","pos":"NOM"} ,
		{"word":"bracelet-montre","word_nosc":"bracelet-montre","lemma":"bracelet-montre","pos":"NOM"} ,
		{"word":"bracelets","word_nosc":"bracelets","lemma":"bracelet","pos":"NOM"} ,
		{"word":"bracelets-montres","word_nosc":"bracelets-montres","lemma":"bracelet-montre","pos":"NOM"} ,
		{"word":"brachiosaure","word_nosc":"brachiosaure","lemma":"brachiosaure","pos":"NOM"} ,
		{"word":"brachycéphale","word_nosc":"brachycephale","lemma":"brachycéphale","pos":"NOM"} ,
		{"word":"braco","word_nosc":"braco","lemma":"braco","pos":"NOM"} ,
		{"word":"braconnage","word_nosc":"braconnage","lemma":"braconnage","pos":"NOM"} ,
		{"word":"braconnages","word_nosc":"braconnages","lemma":"braconnage","pos":"NOM"} ,
		{"word":"braconnier","word_nosc":"braconnier","lemma":"braconnier","pos":"NOM"} ,
		{"word":"braconniers","word_nosc":"braconniers","lemma":"braconnier","pos":"NOM"} ,
		{"word":"braconnière","word_nosc":"braconniere","lemma":"braconnier","pos":"NOM"} ,
		{"word":"braconnières","word_nosc":"braconnieres","lemma":"braconnier","pos":"NOM"} ,
		{"word":"bractées","word_nosc":"bractees","lemma":"bractée","pos":"NOM"} ,
		{"word":"braderie","word_nosc":"braderie","lemma":"braderie","pos":"NOM"} ,
		{"word":"braderies","word_nosc":"braderies","lemma":"braderie","pos":"NOM"} ,
		{"word":"bradillon","word_nosc":"bradillon","lemma":"bradillon","pos":"NOM"} ,
		{"word":"bradillons","word_nosc":"bradillons","lemma":"bradillon","pos":"NOM"} ,
		{"word":"bradycardie","word_nosc":"bradycardie","lemma":"bradycardie","pos":"NOM"} ,
		{"word":"bragard","word_nosc":"bragard","lemma":"bragard","pos":"NOM"} ,
		{"word":"braguette","word_nosc":"braguette","lemma":"braguette","pos":"NOM"} ,
		{"word":"braguettes","word_nosc":"braguettes","lemma":"braguette","pos":"NOM"} ,
		{"word":"brahmane","word_nosc":"brahmane","lemma":"brahmane","pos":"NOM"} ,
		{"word":"brahmanes","word_nosc":"brahmanes","lemma":"brahmane","pos":"NOM"} ,
		{"word":"brahmanisme","word_nosc":"brahmanisme","lemma":"brahmanisme","pos":"NOM"} ,
		{"word":"brahmanistes","word_nosc":"brahmanistes","lemma":"brahmaniste","pos":"NOM"} ,
		{"word":"brai","word_nosc":"brai","lemma":"brai","pos":"NOM"} ,
		{"word":"braiement","word_nosc":"braiement","lemma":"braiement","pos":"NOM"} ,
		{"word":"braiements","word_nosc":"braiements","lemma":"braiement","pos":"NOM"} ,
		{"word":"braies","word_nosc":"braies","lemma":"braie","pos":"NOM"} ,
		{"word":"braille","word_nosc":"braille","lemma":"braille","pos":"NOM"} ,
		{"word":"braillement","word_nosc":"braillement","lemma":"braillement","pos":"NOM"} ,
		{"word":"braillements","word_nosc":"braillements","lemma":"braillement","pos":"NOM"} ,
		{"word":"brailleur","word_nosc":"brailleur","lemma":"brailleur","pos":"NOM"} ,
		{"word":"brailleurs","word_nosc":"brailleurs","lemma":"brailleur","pos":"NOM"} ,
		{"word":"brailleuse","word_nosc":"brailleuse","lemma":"brailleur","pos":"NOM"} ,
		{"word":"braiment","word_nosc":"braiment","lemma":"braiment","pos":"NOM"} ,
		{"word":"braiments","word_nosc":"braiments","lemma":"braiment","pos":"NOM"} ,
		{"word":"brain-trust","word_nosc":"brain-trust","lemma":"brain-trust","pos":"NOM"} ,
		{"word":"brainstorming","word_nosc":"brainstorming","lemma":"brainstorming","pos":"NOM"} ,
		{"word":"brais","word_nosc":"brais","lemma":"brai","pos":"NOM"} ,
		{"word":"braise","word_nosc":"braise","lemma":"braise","pos":"NOM"} ,
		{"word":"braises","word_nosc":"braises","lemma":"braise","pos":"NOM"} ,
		{"word":"brame","word_nosc":"brame","lemma":"brame","pos":"NOM"} ,
		{"word":"bramement","word_nosc":"bramement","lemma":"bramement","pos":"NOM"} ,
		{"word":"bramements","word_nosc":"bramements","lemma":"bramement","pos":"NOM"} ,
		{"word":"brames","word_nosc":"brames","lemma":"brame","pos":"NOM"} ,
		{"word":"bran","word_nosc":"bran","lemma":"bran","pos":"NOM"} ,
		{"word":"brancard","word_nosc":"brancard","lemma":"brancard","pos":"NOM"} ,
		{"word":"brancardier","word_nosc":"brancardier","lemma":"brancardier","pos":"NOM"} ,
		{"word":"brancardiers","word_nosc":"brancardiers","lemma":"brancardier","pos":"NOM"} ,
		{"word":"brancards","word_nosc":"brancards","lemma":"brancard","pos":"NOM"} ,
		{"word":"branchage","word_nosc":"branchage","lemma":"branchage","pos":"NOM"} ,
		{"word":"branchages","word_nosc":"branchages","lemma":"branchage","pos":"NOM"} ,
		{"word":"branche","word_nosc":"branche","lemma":"branche","pos":"NOM"} ,
		{"word":"branchement","word_nosc":"branchement","lemma":"branchement","pos":"NOM"} ,
		{"word":"branchements","word_nosc":"branchements","lemma":"branchement","pos":"NOM"} ,
		{"word":"branches","word_nosc":"branches","lemma":"branche","pos":"NOM"} ,
		{"word":"branchette","word_nosc":"branchette","lemma":"branchette","pos":"NOM"} ,
		{"word":"branchettes","word_nosc":"branchettes","lemma":"branchette","pos":"NOM"} ,
		{"word":"branchie","word_nosc":"branchie","lemma":"branchie","pos":"NOM"} ,
		{"word":"branchies","word_nosc":"branchies","lemma":"branchie","pos":"NOM"} ,
		{"word":"brand","word_nosc":"brand","lemma":"brand","pos":"NOM"} ,
		{"word":"brandade","word_nosc":"brandade","lemma":"brandade","pos":"NOM"} ,
		{"word":"brande","word_nosc":"brande","lemma":"brand","pos":"NOM"} ,
		{"word":"brandebourgs","word_nosc":"brandebourgs","lemma":"brandebourg","pos":"NOM"} ,
		{"word":"brandevin","word_nosc":"brandevin","lemma":"brandevin","pos":"NOM"} ,
		{"word":"brandevinier","word_nosc":"brandevinier","lemma":"brandevinier","pos":"NOM"} ,
		{"word":"brandillon","word_nosc":"brandillon","lemma":"brandillon","pos":"NOM"} ,
		{"word":"brandillons","word_nosc":"brandillons","lemma":"brandillon","pos":"NOM"} ,
		{"word":"brandon","word_nosc":"brandon","lemma":"brandon","pos":"NOM"} ,
		{"word":"brandons","word_nosc":"brandons","lemma":"brandon","pos":"NOM"} ,
		{"word":"brandy","word_nosc":"brandy","lemma":"brandy","pos":"NOM"} ,
		{"word":"branlage","word_nosc":"branlage","lemma":"branlage","pos":"NOM"} ,
		{"word":"branle","word_nosc":"branle","lemma":"branle","pos":"NOM"} ,
		{"word":"branle-bas","word_nosc":"branle-bas","lemma":"branle-bas","pos":"NOM"} ,
		{"word":"branlement","word_nosc":"branlement","lemma":"branlement","pos":"NOM"} ,
		{"word":"branles","word_nosc":"branles","lemma":"branle","pos":"NOM"} ,
		{"word":"branlette","word_nosc":"branlette","lemma":"branlette","pos":"NOM"} ,
		{"word":"branlettes","word_nosc":"branlettes","lemma":"branlette","pos":"NOM"} ,
		{"word":"branleur","word_nosc":"branleur","lemma":"branleur","pos":"NOM"} ,
		{"word":"branleurs","word_nosc":"branleurs","lemma":"branleur","pos":"NOM"} ,
		{"word":"branleuse","word_nosc":"branleuse","lemma":"branleur","pos":"NOM"} ,
		{"word":"branleuses","word_nosc":"branleuses","lemma":"branleur","pos":"NOM"} ,
		{"word":"branlée","word_nosc":"branlee","lemma":"branlée","pos":"NOM"} ,
		{"word":"branquignol","word_nosc":"branquignol","lemma":"branquignol","pos":"NOM"} ,
		{"word":"branquignols","word_nosc":"branquignols","lemma":"branquignol","pos":"NOM"} ,
		{"word":"braquage","word_nosc":"braquage","lemma":"braquage","pos":"NOM"} ,
		{"word":"braquages","word_nosc":"braquages","lemma":"braquage","pos":"NOM"} ,
		{"word":"braque","word_nosc":"braque","lemma":"braque","pos":"NOM"} ,
		{"word":"braquemart","word_nosc":"braquemart","lemma":"braquemart","pos":"NOM"} ,
		{"word":"braquemarts","word_nosc":"braquemarts","lemma":"braquemart","pos":"NOM"} ,
		{"word":"braques","word_nosc":"braques","lemma":"braque","pos":"NOM"} ,
		{"word":"braquet","word_nosc":"braquet","lemma":"braquet","pos":"NOM"} ,
		{"word":"braquets","word_nosc":"braquets","lemma":"braquet","pos":"NOM"} ,
		{"word":"braqueur","word_nosc":"braqueur","lemma":"braqueur","pos":"NOM"} ,
		{"word":"braqueurs","word_nosc":"braqueurs","lemma":"braqueur","pos":"NOM"} ,
		{"word":"braqueuse","word_nosc":"braqueuse","lemma":"braqueur","pos":"NOM"} ,
		{"word":"braqueuses","word_nosc":"braqueuses","lemma":"braqueur","pos":"NOM"} ,
		{"word":"bras","word_nosc":"bras","lemma":"bras","pos":"NOM"} ,
		{"word":"brasage","word_nosc":"brasage","lemma":"brasage","pos":"NOM"} ,
		{"word":"brasero","word_nosc":"brasero","lemma":"brasero","pos":"NOM"} ,
		{"word":"braseros","word_nosc":"braseros","lemma":"brasero","pos":"NOM"} ,
		{"word":"brasier","word_nosc":"brasier","lemma":"brasier","pos":"NOM"} ,
		{"word":"brasiers","word_nosc":"brasiers","lemma":"brasier","pos":"NOM"} ,
		{"word":"brasillement","word_nosc":"brasillement","lemma":"brasillement","pos":"NOM"} ,
		{"word":"brassage","word_nosc":"brassage","lemma":"brassage","pos":"NOM"} ,
		{"word":"brassages","word_nosc":"brassages","lemma":"brassage","pos":"NOM"} ,
		{"word":"brassard","word_nosc":"brassard","lemma":"brassard","pos":"NOM"} ,
		{"word":"brassards","word_nosc":"brassards","lemma":"brassard","pos":"NOM"} ,
		{"word":"brasse","word_nosc":"brasse","lemma":"brasse","pos":"NOM"} ,
		{"word":"brasserie","word_nosc":"brasserie","lemma":"brasserie","pos":"NOM"} ,
		{"word":"brasserie-hôtel","word_nosc":"brasserie-hotel","lemma":"brasserie-hôtel","pos":"NOM"} ,
		{"word":"brasseries","word_nosc":"brasseries","lemma":"brasserie","pos":"NOM"} ,
		{"word":"brasses","word_nosc":"brasses","lemma":"brasse","pos":"NOM"} ,
		{"word":"brasseur","word_nosc":"brasseur","lemma":"brasseur","pos":"NOM"} ,
		{"word":"brasseurs","word_nosc":"brasseurs","lemma":"brasseur","pos":"NOM"} ,
		{"word":"brassière","word_nosc":"brassiere","lemma":"brassière","pos":"NOM"} ,
		{"word":"brassières","word_nosc":"brassieres","lemma":"brassière","pos":"NOM"} ,
		{"word":"brassée","word_nosc":"brassee","lemma":"brassée","pos":"NOM"} ,
		{"word":"brassées","word_nosc":"brassees","lemma":"brassée","pos":"NOM"} ,
		{"word":"brasure","word_nosc":"brasure","lemma":"brasure","pos":"NOM"} ,
		{"word":"bravache","word_nosc":"bravache","lemma":"bravache","pos":"NOM"} ,
		{"word":"bravaches","word_nosc":"bravaches","lemma":"bravache","pos":"NOM"} ,
		{"word":"bravade","word_nosc":"bravade","lemma":"bravade","pos":"NOM"} ,
		{"word":"bravades","word_nosc":"bravades","lemma":"bravade","pos":"NOM"} ,
		{"word":"brave","word_nosc":"brave","lemma":"brave","pos":"NOM"} ,
		{"word":"braveries","word_nosc":"braveries","lemma":"braverie","pos":"NOM"} ,
		{"word":"braves","word_nosc":"braves","lemma":"brave","pos":"NOM"} ,
		{"word":"bravissimo","word_nosc":"bravissimo","lemma":"bravissimo","pos":"NOM"} ,
		{"word":"bravo","word_nosc":"bravo","lemma":"bravo","pos":"NOM"} ,
		{"word":"bravos","word_nosc":"bravos","lemma":"bravo","pos":"NOM"} ,
		{"word":"bravoure","word_nosc":"bravoure","lemma":"bravoure","pos":"NOM"} ,
		{"word":"braye","word_nosc":"braye","lemma":"braye","pos":"NOM"} ,
		{"word":"brayons","word_nosc":"brayons","lemma":"brayon","pos":"NOM"} ,
		{"word":"break","word_nosc":"break","lemma":"break","pos":"NOM"} ,
		{"word":"break-down","word_nosc":"break-down","lemma":"break-down","pos":"NOM"} ,
		{"word":"breakdance","word_nosc":"breakdance","lemma":"breakdance","pos":"NOM"} ,
		{"word":"breakfast","word_nosc":"breakfast","lemma":"breakfast","pos":"NOM"} ,
		{"word":"breakfasts","word_nosc":"breakfasts","lemma":"breakfast","pos":"NOM"} ,
		{"word":"breaks","word_nosc":"breaks","lemma":"break","pos":"NOM"} ,
		{"word":"brebis","word_nosc":"brebis","lemma":"brebis","pos":"NOM"} ,
		{"word":"brechtienne","word_nosc":"brechtienne","lemma":"brechtien","pos":"NOM"} ,
		{"word":"bredin","word_nosc":"bredin","lemma":"bredin","pos":"NOM"} ,
		{"word":"bredouillage","word_nosc":"bredouillage","lemma":"bredouillage","pos":"NOM"} ,
		{"word":"bredouillement","word_nosc":"bredouillement","lemma":"bredouillement","pos":"NOM"} ,
		{"word":"bredouillements","word_nosc":"bredouillements","lemma":"bredouillement","pos":"NOM"} ,
		{"word":"bredouilleurs","word_nosc":"bredouilleurs","lemma":"bredouilleur","pos":"NOM"} ,
		{"word":"bredouillis","word_nosc":"bredouillis","lemma":"bredouillis","pos":"NOM"} ,
		{"word":"bref","word_nosc":"bref","lemma":"bref","pos":"NOM"} ,
		{"word":"brefs","word_nosc":"brefs","lemma":"bref","pos":"NOM"} ,
		{"word":"bregma","word_nosc":"bregma","lemma":"bregma","pos":"NOM"} ,
		{"word":"breitschwanz","word_nosc":"breitschwanz","lemma":"breitschwanz","pos":"NOM"} ,
		{"word":"brelan","word_nosc":"brelan","lemma":"brelan","pos":"NOM"} ,
		{"word":"brelans","word_nosc":"brelans","lemma":"brelan","pos":"NOM"} ,
		{"word":"breloque","word_nosc":"breloque","lemma":"breloque","pos":"NOM"} ,
		{"word":"breloques","word_nosc":"breloques","lemma":"breloque","pos":"NOM"} ,
		{"word":"bressanes","word_nosc":"bressanes","lemma":"bressan","pos":"NOM"} ,
		{"word":"brestois","word_nosc":"brestois","lemma":"brestois","pos":"NOM"} ,
		{"word":"brestoise","word_nosc":"brestoise","lemma":"brestois","pos":"NOM"} ,
		{"word":"bretelle","word_nosc":"bretelle","lemma":"bretelle","pos":"NOM"} ,
		{"word":"bretelles","word_nosc":"bretelles","lemma":"bretelle","pos":"NOM"} ,
		{"word":"breton","word_nosc":"breton","lemma":"breton","pos":"NOM"} ,
		{"word":"bretonne","word_nosc":"bretonne","lemma":"breton","pos":"NOM"} ,
		{"word":"bretonnes","word_nosc":"bretonnes","lemma":"breton","pos":"NOM"} ,
		{"word":"bretons","word_nosc":"bretons","lemma":"breton","pos":"NOM"} ,
		{"word":"brette","word_nosc":"brette","lemma":"brette","pos":"NOM"} ,
		{"word":"bretteur","word_nosc":"bretteur","lemma":"bretteur","pos":"NOM"} ,
		{"word":"bretteurs","word_nosc":"bretteurs","lemma":"bretteur","pos":"NOM"} ,
		{"word":"bretzel","word_nosc":"bretzel","lemma":"bretzel","pos":"NOM"} ,
		{"word":"bretzels","word_nosc":"bretzels","lemma":"bretzel","pos":"NOM"} ,
		{"word":"breuil","word_nosc":"breuil","lemma":"breuil","pos":"NOM"} ,
		{"word":"breuils","word_nosc":"breuils","lemma":"breuil","pos":"NOM"} ,
		{"word":"breuvage","word_nosc":"breuvage","lemma":"breuvage","pos":"NOM"} ,
		{"word":"breuvages","word_nosc":"breuvages","lemma":"breuvage","pos":"NOM"} ,
		{"word":"brevet","word_nosc":"brevet","lemma":"brevet","pos":"NOM"} ,
		{"word":"brevets","word_nosc":"brevets","lemma":"brevet","pos":"NOM"} ,
		{"word":"breveté","word_nosc":"brevete","lemma":"breveté","pos":"NOM"} ,
		{"word":"brevetés","word_nosc":"brevetes","lemma":"breveté","pos":"NOM"} ,
		{"word":"bri","word_nosc":"bri","lemma":"bri","pos":"NOM"} ,
		{"word":"briard","word_nosc":"briard","lemma":"briard","pos":"NOM"} ,
		{"word":"briards","word_nosc":"briards","lemma":"briard","pos":"NOM"} ,
		{"word":"bribe","word_nosc":"bribe","lemma":"bribe","pos":"NOM"} ,
		{"word":"bribes","word_nosc":"bribes","lemma":"bribe","pos":"NOM"} ,
		{"word":"bric-à-brac","word_nosc":"bric-a-brac","lemma":"bric-à-brac","pos":"NOM"} ,
		{"word":"bricheton","word_nosc":"bricheton","lemma":"bricheton","pos":"NOM"} ,
		{"word":"brick","word_nosc":"brick","lemma":"brick","pos":"NOM"} ,
		{"word":"bricks","word_nosc":"bricks","lemma":"brick","pos":"NOM"} ,
		{"word":"bricolage","word_nosc":"bricolage","lemma":"bricolage","pos":"NOM"} ,
		{"word":"bricolages","word_nosc":"bricolages","lemma":"bricolage","pos":"NOM"} ,
		{"word":"bricole","word_nosc":"bricole","lemma":"bricole","pos":"NOM"} ,
		{"word":"bricoles","word_nosc":"bricoles","lemma":"bricole","pos":"NOM"} ,
		{"word":"bricoleur","word_nosc":"bricoleur","lemma":"bricoleur","pos":"NOM"} ,
		{"word":"bricoleurs","word_nosc":"bricoleurs","lemma":"bricoleur","pos":"NOM"} ,
		{"word":"bricoleuse","word_nosc":"bricoleuse","lemma":"bricoleur","pos":"NOM"} ,
		{"word":"bricolo","word_nosc":"bricolo","lemma":"bricolo","pos":"NOM"} ,
		{"word":"bricolos","word_nosc":"bricolos","lemma":"bricolo","pos":"NOM"} ,
		{"word":"bride","word_nosc":"bride","lemma":"bride","pos":"NOM"} ,
		{"word":"brides","word_nosc":"brides","lemma":"bride","pos":"NOM"} ,
		{"word":"bridge","word_nosc":"bridge","lemma":"bridge","pos":"NOM"} ,
		{"word":"bridges","word_nosc":"bridges","lemma":"bridge","pos":"NOM"} ,
		{"word":"bridgeurs","word_nosc":"bridgeurs","lemma":"bridgeur","pos":"NOM"} ,
		{"word":"bridgeuse","word_nosc":"bridgeuse","lemma":"bridgeur","pos":"NOM"} ,
		{"word":"bridon","word_nosc":"bridon","lemma":"bridon","pos":"NOM"} ,
		{"word":"bridons","word_nosc":"bridons","lemma":"bridon","pos":"NOM"} ,
		{"word":"brie","word_nosc":"brie","lemma":"brie","pos":"NOM"} ,
		{"word":"briefing","word_nosc":"briefing","lemma":"briefing","pos":"NOM"} ,
		{"word":"briefings","word_nosc":"briefings","lemma":"briefing","pos":"NOM"} ,
		{"word":"bries","word_nosc":"bries","lemma":"brie","pos":"NOM"} ,
		{"word":"brigade","word_nosc":"brigade","lemma":"brigade","pos":"NOM"} ,
		{"word":"brigades","word_nosc":"brigades","lemma":"brigade","pos":"NOM"} ,
		{"word":"brigadier","word_nosc":"brigadier","lemma":"brigadier","pos":"NOM"} ,
		{"word":"brigadier-chef","word_nosc":"brigadier-chef","lemma":"brigadier-chef","pos":"NOM"} ,
		{"word":"brigadiers","word_nosc":"brigadiers","lemma":"brigadier","pos":"NOM"} ,
		{"word":"brigadiers-chefs","word_nosc":"brigadiers-chefs","lemma":"brigadier-chef","pos":"NOM"} ,
		{"word":"brigadistes","word_nosc":"brigadistes","lemma":"brigadiste","pos":"NOM"} ,
		{"word":"brigand","word_nosc":"brigand","lemma":"brigand","pos":"NOM"} ,
		{"word":"brigandage","word_nosc":"brigandage","lemma":"brigandage","pos":"NOM"} ,
		{"word":"brigandages","word_nosc":"brigandages","lemma":"brigandage","pos":"NOM"} ,
		{"word":"brigandeaux","word_nosc":"brigandeaux","lemma":"brigandeau","pos":"NOM"} ,
		{"word":"brigands","word_nosc":"brigands","lemma":"brigand","pos":"NOM"} ,
		{"word":"brigantin","word_nosc":"brigantin","lemma":"brigantin","pos":"NOM"} ,
		{"word":"brigantine","word_nosc":"brigantine","lemma":"brigantine","pos":"NOM"} ,
		{"word":"brignolet","word_nosc":"brignolet","lemma":"brignolet","pos":"NOM"} ,
		{"word":"brigues","word_nosc":"brigues","lemma":"brigue","pos":"NOM"} ,
		{"word":"brillance","word_nosc":"brillance","lemma":"brillance","pos":"NOM"} ,
		{"word":"brillances","word_nosc":"brillances","lemma":"brillance","pos":"NOM"} ,
		{"word":"brillant","word_nosc":"brillant","lemma":"brillant","pos":"NOM"} ,
		{"word":"brillantage","word_nosc":"brillantage","lemma":"brillantage","pos":"NOM"} ,
		{"word":"brillantine","word_nosc":"brillantine","lemma":"brillantine","pos":"NOM"} ,
		{"word":"brillants","word_nosc":"brillants","lemma":"brillant","pos":"NOM"} ,
		{"word":"brimade","word_nosc":"brimade","lemma":"brimade","pos":"NOM"} ,
		{"word":"brimades","word_nosc":"brimades","lemma":"brimade","pos":"NOM"} ,
		{"word":"brimbalement","word_nosc":"brimbalement","lemma":"brimbalement","pos":"NOM"} ,
		{"word":"brimbalements","word_nosc":"brimbalements","lemma":"brimbalement","pos":"NOM"} ,
		{"word":"brimborion","word_nosc":"brimborion","lemma":"brimborion","pos":"NOM"} ,
		{"word":"brimborions","word_nosc":"brimborions","lemma":"brimborion","pos":"NOM"} ,
		{"word":"brin","word_nosc":"brin","lemma":"brin","pos":"NOM"} ,
		{"word":"brindes","word_nosc":"brindes","lemma":"brinde","pos":"NOM"} ,
		{"word":"brindille","word_nosc":"brindille","lemma":"brindille","pos":"NOM"} ,
		{"word":"brindilles","word_nosc":"brindilles","lemma":"brindille","pos":"NOM"} ,
		{"word":"bringue","word_nosc":"bringue","lemma":"bringue","pos":"NOM"} ,
		{"word":"brins","word_nosc":"brins","lemma":"brin","pos":"NOM"} ,
		{"word":"brio","word_nosc":"brio","lemma":"brio","pos":"NOM"} ,
		{"word":"brioche","word_nosc":"brioche","lemma":"brioche","pos":"NOM"} ,
		{"word":"brioches","word_nosc":"brioches","lemma":"brioche","pos":"NOM"} ,
		{"word":"brions","word_nosc":"brions","lemma":"brion","pos":"NOM"} ,
		{"word":"briquage","word_nosc":"briquage","lemma":"briquage","pos":"NOM"} ,
		{"word":"brique","word_nosc":"brique","lemma":"brique","pos":"NOM"} ,
		{"word":"briques","word_nosc":"briques","lemma":"brique","pos":"NOM"} ,
		{"word":"briquet","word_nosc":"briquet","lemma":"briquet","pos":"NOM"} ,
		{"word":"briquetage","word_nosc":"briquetage","lemma":"briquetage","pos":"NOM"} ,
		{"word":"briqueterie","word_nosc":"briqueterie","lemma":"briqueterie","pos":"NOM"} ,
		{"word":"briqueteries","word_nosc":"briqueteries","lemma":"briqueterie","pos":"NOM"} ,
		{"word":"briquetier","word_nosc":"briquetier","lemma":"briquetier","pos":"NOM"} ,
		{"word":"briquets","word_nosc":"briquets","lemma":"briquet","pos":"NOM"} ,
		{"word":"briquette","word_nosc":"briquette","lemma":"briquette","pos":"NOM"} ,
		{"word":"briquettes","word_nosc":"briquettes","lemma":"briquette","pos":"NOM"} ,
		{"word":"bris","word_nosc":"bris","lemma":"bris","pos":"NOM"} ,
		{"word":"brisant","word_nosc":"brisant","lemma":"brisant","pos":"NOM"} ,
		{"word":"brisants","word_nosc":"brisants","lemma":"brisant","pos":"NOM"} ,
		{"word":"briscard","word_nosc":"briscard","lemma":"briscard","pos":"NOM"} ,
		{"word":"briscards","word_nosc":"briscards","lemma":"briscard","pos":"NOM"} ,
		{"word":"brise","word_nosc":"brise","lemma":"brise","pos":"NOM"} ,
		{"word":"brise-bise","word_nosc":"brise-bise","lemma":"brise-bise","pos":"NOM"} ,
		{"word":"brise-fer","word_nosc":"brise-fer","lemma":"brise-fer","pos":"NOM"} ,
		{"word":"brise-glace","word_nosc":"brise-glace","lemma":"brise-glace","pos":"NOM"} ,
		{"word":"brise-jet","word_nosc":"brise-jet","lemma":"brise-jet","pos":"NOM"} ,
		{"word":"brise-lame","word_nosc":"brise-lame","lemma":"brise-lame","pos":"NOM"} ,
		{"word":"brise-vent","word_nosc":"brise-vent","lemma":"brise-vent","pos":"NOM"} ,
		{"word":"brises","word_nosc":"brises","lemma":"brise","pos":"NOM"} ,
		{"word":"briseur","word_nosc":"briseur","lemma":"briseur","pos":"NOM"} ,
		{"word":"briseurs","word_nosc":"briseurs","lemma":"briseur","pos":"NOM"} ,
		{"word":"briseuse","word_nosc":"briseuse","lemma":"briseur","pos":"NOM"} ,
		{"word":"brisis","word_nosc":"brisis","lemma":"brisis","pos":"NOM"} ,
		{"word":"brisque","word_nosc":"brisque","lemma":"brisque","pos":"NOM"} ,
		{"word":"bristol","word_nosc":"bristol","lemma":"bristol","pos":"NOM"} ,
		{"word":"bristols","word_nosc":"bristols","lemma":"bristol","pos":"NOM"} ,
		{"word":"brisure","word_nosc":"brisure","lemma":"brisure","pos":"NOM"} ,
		{"word":"brisures","word_nosc":"brisures","lemma":"brisure","pos":"NOM"} ,
		{"word":"brisée","word_nosc":"brisee","lemma":"brisée","pos":"NOM"} ,
		{"word":"brisées","word_nosc":"brisees","lemma":"brisée","pos":"NOM"} ,
		{"word":"britannique","word_nosc":"britannique","lemma":"britannique","pos":"NOM"} ,
		{"word":"britanniques","word_nosc":"britanniques","lemma":"britannique","pos":"NOM"} ,
		{"word":"brivadois","word_nosc":"brivadois","lemma":"brivadois","pos":"NOM"} ,
		{"word":"brize","word_nosc":"brize","lemma":"brize","pos":"NOM"} ,
		{"word":"brièveté","word_nosc":"brievete","lemma":"brièveté","pos":"NOM"} ,
		{"word":"broc","word_nosc":"broc","lemma":"broc","pos":"NOM"} ,
		{"word":"brocante","word_nosc":"brocante","lemma":"brocante","pos":"NOM"} ,
		{"word":"brocantes","word_nosc":"brocantes","lemma":"brocante","pos":"NOM"} ,
		{"word":"brocanteur","word_nosc":"brocanteur","lemma":"brocanteur","pos":"NOM"} ,
		{"word":"brocanteurs","word_nosc":"brocanteurs","lemma":"brocanteur","pos":"NOM"} ,
		{"word":"brocanteuse","word_nosc":"brocanteuse","lemma":"brocanteur","pos":"NOM"} ,
		{"word":"brocard","word_nosc":"brocard","lemma":"brocard","pos":"NOM"} ,
		{"word":"brocards","word_nosc":"brocards","lemma":"brocard","pos":"NOM"} ,
		{"word":"brocart","word_nosc":"brocart","lemma":"brocart","pos":"NOM"} ,
		{"word":"brocarts","word_nosc":"brocarts","lemma":"brocart","pos":"NOM"} ,
		{"word":"brocatelle","word_nosc":"brocatelle","lemma":"brocatelle","pos":"NOM"} ,
		{"word":"brochage","word_nosc":"brochage","lemma":"brochage","pos":"NOM"} ,
		{"word":"brochages","word_nosc":"brochages","lemma":"brochage","pos":"NOM"} ,
		{"word":"broche","word_nosc":"broche","lemma":"broche","pos":"NOM"} ,
		{"word":"broches","word_nosc":"broches","lemma":"broche","pos":"NOM"} ,
		{"word":"brochet","word_nosc":"brochet","lemma":"brochet","pos":"NOM"} ,
		{"word":"brochets","word_nosc":"brochets","lemma":"brochet","pos":"NOM"} ,
		{"word":"brochette","word_nosc":"brochette","lemma":"brochette","pos":"NOM"} ,
		{"word":"brochettes","word_nosc":"brochettes","lemma":"brochette","pos":"NOM"} ,
		{"word":"brocheur","word_nosc":"brocheur","lemma":"brocheur","pos":"NOM"} ,
		{"word":"brocheurs","word_nosc":"brocheurs","lemma":"brocheur","pos":"NOM"} ,
		{"word":"brochoirs","word_nosc":"brochoirs","lemma":"brochoir","pos":"NOM"} ,
		{"word":"brochure","word_nosc":"brochure","lemma":"brochure","pos":"NOM"} ,
		{"word":"brochures","word_nosc":"brochures","lemma":"brochure","pos":"NOM"} ,
		{"word":"broché","word_nosc":"broche","lemma":"broché","pos":"NOM"} ,
		{"word":"brocoli","word_nosc":"brocoli","lemma":"brocoli","pos":"NOM"} ,
		{"word":"brocolis","word_nosc":"brocolis","lemma":"brocoli","pos":"NOM"} ,
		{"word":"brocs","word_nosc":"brocs","lemma":"broc","pos":"NOM"} ,
		{"word":"brodequin","word_nosc":"brodequin","lemma":"brodequin","pos":"NOM"} ,
		{"word":"brodequins","word_nosc":"brodequins","lemma":"brodequin","pos":"NOM"} ,
		{"word":"broderie","word_nosc":"broderie","lemma":"broderie","pos":"NOM"} ,
		{"word":"broderies","word_nosc":"broderies","lemma":"broderie","pos":"NOM"} ,
		{"word":"brodeuse","word_nosc":"brodeuse","lemma":"brodeur","pos":"NOM"} ,
		{"word":"brodeuses","word_nosc":"brodeuses","lemma":"brodeur","pos":"NOM"} ,
		{"word":"broiement","word_nosc":"broiement","lemma":"broiement","pos":"NOM"} ,
		{"word":"broiements","word_nosc":"broiements","lemma":"broiement","pos":"NOM"} ,
		{"word":"broker","word_nosc":"broker","lemma":"broker","pos":"NOM"} ,
		{"word":"brol","word_nosc":"brol","lemma":"brol","pos":"NOM"} ,
		{"word":"brome","word_nosc":"brome","lemma":"brome","pos":"NOM"} ,
		{"word":"bromure","word_nosc":"bromure","lemma":"bromure","pos":"NOM"} ,
		{"word":"bromures","word_nosc":"bromures","lemma":"bromure","pos":"NOM"} ,
		{"word":"bronche","word_nosc":"bronche","lemma":"bronche","pos":"NOM"} ,
		{"word":"bronches","word_nosc":"bronches","lemma":"bronche","pos":"NOM"} ,
		{"word":"bronchioles","word_nosc":"bronchioles","lemma":"bronchiole","pos":"NOM"} ,
		{"word":"bronchite","word_nosc":"bronchite","lemma":"bronchite","pos":"NOM"} ,
		{"word":"bronchites","word_nosc":"bronchites","lemma":"bronchite","pos":"NOM"} ,
		{"word":"broncho","word_nosc":"broncho","lemma":"broncho","pos":"NOM"} ,
		{"word":"broncho-pneumonie","word_nosc":"broncho-pneumonie","lemma":"broncho-pneumonie","pos":"NOM"} ,
		{"word":"broncho-pneumopathie","word_nosc":"broncho-pneumopathie","lemma":"broncho-pneumopathie","pos":"NOM"} ,
		{"word":"bronchoscope","word_nosc":"bronchoscope","lemma":"bronchoscope","pos":"NOM"} ,
		{"word":"bronchoscopie","word_nosc":"bronchoscopie","lemma":"bronchoscopie","pos":"NOM"} ,
		{"word":"brontosaure","word_nosc":"brontosaure","lemma":"brontosaure","pos":"NOM"} ,
		{"word":"brontosaures","word_nosc":"brontosaures","lemma":"brontosaure","pos":"NOM"} ,
		{"word":"bronzage","word_nosc":"bronzage","lemma":"bronzage","pos":"NOM"} ,
		{"word":"bronzages","word_nosc":"bronzages","lemma":"bronzage","pos":"NOM"} ,
		{"word":"bronze","word_nosc":"bronze","lemma":"bronze","pos":"NOM"} ,
		{"word":"bronzes","word_nosc":"bronzes","lemma":"bronze","pos":"NOM"} ,
		{"word":"bronzette","word_nosc":"bronzette","lemma":"bronzette","pos":"NOM"} ,
		{"word":"bronzettes","word_nosc":"bronzettes","lemma":"bronzette","pos":"NOM"} ,
		{"word":"bronzier","word_nosc":"bronzier","lemma":"bronzier","pos":"NOM"} ,
		{"word":"brook","word_nosc":"brook","lemma":"brook","pos":"NOM"} ,
		{"word":"brooks","word_nosc":"brooks","lemma":"brook","pos":"NOM"} ,
		{"word":"broquarts","word_nosc":"broquarts","lemma":"broquart","pos":"NOM"} ,
		{"word":"broque","word_nosc":"broque","lemma":"broque","pos":"NOM"} ,
		{"word":"broques","word_nosc":"broques","lemma":"broque","pos":"NOM"} ,
		{"word":"broquettes","word_nosc":"broquettes","lemma":"broquette","pos":"NOM"} ,
		{"word":"broquille","word_nosc":"broquille","lemma":"broquille","pos":"NOM"} ,
		{"word":"broquilles","word_nosc":"broquilles","lemma":"broquille","pos":"NOM"} ,
		{"word":"brossage","word_nosc":"brossage","lemma":"brossage","pos":"NOM"} ,
		{"word":"brosse","word_nosc":"brosse","lemma":"brosse","pos":"NOM"} ,
		{"word":"brosses","word_nosc":"brosses","lemma":"brosse","pos":"NOM"} ,
		{"word":"brosseur","word_nosc":"brosseur","lemma":"brosseur","pos":"NOM"} ,
		{"word":"brosseurs","word_nosc":"brosseurs","lemma":"brosseur","pos":"NOM"} ,
		{"word":"brossier","word_nosc":"brossier","lemma":"brossier","pos":"NOM"} ,
		{"word":"brou","word_nosc":"brou","lemma":"brou","pos":"NOM"} ,
		{"word":"brouet","word_nosc":"brouet","lemma":"brouet","pos":"NOM"} ,
		{"word":"brouets","word_nosc":"brouets","lemma":"brouet","pos":"NOM"} ,
		{"word":"brouette","word_nosc":"brouette","lemma":"brouette","pos":"NOM"} ,
		{"word":"brouettes","word_nosc":"brouettes","lemma":"brouette","pos":"NOM"} ,
		{"word":"brouettée","word_nosc":"brouettee","lemma":"brouettée","pos":"NOM"} ,
		{"word":"brouettées","word_nosc":"brouettees","lemma":"brouettée","pos":"NOM"} ,
		{"word":"brouhaha","word_nosc":"brouhaha","lemma":"brouhaha","pos":"NOM"} ,
		{"word":"brouhahas","word_nosc":"brouhahas","lemma":"brouhaha","pos":"NOM"} ,
		{"word":"brouillade","word_nosc":"brouillade","lemma":"brouillade","pos":"NOM"} ,
		{"word":"brouillage","word_nosc":"brouillage","lemma":"brouillage","pos":"NOM"} ,
		{"word":"brouillages","word_nosc":"brouillages","lemma":"brouillage","pos":"NOM"} ,
		{"word":"brouillamini","word_nosc":"brouillamini","lemma":"brouillamini","pos":"NOM"} ,
		{"word":"brouillard","word_nosc":"brouillard","lemma":"brouillard","pos":"NOM"} ,
		{"word":"brouillards","word_nosc":"brouillards","lemma":"brouillard","pos":"NOM"} ,
		{"word":"brouillasse","word_nosc":"brouillasse","lemma":"brouillasse","pos":"NOM"} ,
		{"word":"brouille","word_nosc":"brouille","lemma":"brouille","pos":"NOM"} ,
		{"word":"brouilles","word_nosc":"brouilles","lemma":"brouille","pos":"NOM"} ,
		{"word":"brouilleur","word_nosc":"brouilleur","lemma":"brouilleur","pos":"NOM"} ,
		{"word":"brouilleurs","word_nosc":"brouilleurs","lemma":"brouilleur","pos":"NOM"} ,
		{"word":"brouillon","word_nosc":"brouillon","lemma":"brouillon","pos":"NOM"} ,
		{"word":"brouillons","word_nosc":"brouillons","lemma":"brouillon","pos":"NOM"} ,
		{"word":"broussaille","word_nosc":"broussaille","lemma":"broussaille","pos":"NOM"} ,
		{"word":"broussailles","word_nosc":"broussailles","lemma":"broussaille","pos":"NOM"} ,
		{"word":"broussard","word_nosc":"broussard","lemma":"broussard","pos":"NOM"} ,
		{"word":"brousse","word_nosc":"brousse","lemma":"brousse","pos":"NOM"} ,
		{"word":"brousses","word_nosc":"brousses","lemma":"brousse","pos":"NOM"} ,
		{"word":"brout","word_nosc":"brout","lemma":"brout","pos":"NOM"} ,
		{"word":"broutage","word_nosc":"broutage","lemma":"broutage","pos":"NOM"} ,
		{"word":"broutard","word_nosc":"broutard","lemma":"broutard","pos":"NOM"} ,
		{"word":"broutille","word_nosc":"broutille","lemma":"broutille","pos":"NOM"} ,
		{"word":"broutilles","word_nosc":"broutilles","lemma":"broutille","pos":"NOM"} ,
		{"word":"brown sugar","word_nosc":"brown sugar","lemma":"brown sugar","pos":"NOM"} ,
		{"word":"browning","word_nosc":"browning","lemma":"browning","pos":"NOM"} ,
		{"word":"brownings","word_nosc":"brownings","lemma":"browning","pos":"NOM"} ,
		{"word":"broyage","word_nosc":"broyage","lemma":"broyage","pos":"NOM"} ,
		{"word":"broyages","word_nosc":"broyages","lemma":"broyage","pos":"NOM"} ,
		{"word":"broyeur","word_nosc":"broyeur","lemma":"broyeur","pos":"NOM"} ,
		{"word":"broyeurs","word_nosc":"broyeurs","lemma":"broyeur","pos":"NOM"} ,
		{"word":"bru","word_nosc":"bru","lemma":"bru","pos":"NOM"} ,
		{"word":"bruant","word_nosc":"bruant","lemma":"bruant","pos":"NOM"} ,
		{"word":"bruants","word_nosc":"bruants","lemma":"bruant","pos":"NOM"} ,
		{"word":"brucellose","word_nosc":"brucellose","lemma":"brucellose","pos":"NOM"} ,
		{"word":"brugeois","word_nosc":"brugeois","lemma":"brugeois","pos":"NOM"} ,
		{"word":"bruges","word_nosc":"bruges","lemma":"bruges","pos":"NOM"} ,
		{"word":"brugnon","word_nosc":"brugnon","lemma":"brugnon","pos":"NOM"} ,
		{"word":"brugnons","word_nosc":"brugnons","lemma":"brugnon","pos":"NOM"} ,
		{"word":"bruine","word_nosc":"bruine","lemma":"bruine","pos":"NOM"} ,
		{"word":"bruines","word_nosc":"bruines","lemma":"bruine","pos":"NOM"} ,
		{"word":"bruissement","word_nosc":"bruissement","lemma":"bruissement","pos":"NOM"} ,
		{"word":"bruissements","word_nosc":"bruissements","lemma":"bruissement","pos":"NOM"} ,
		{"word":"bruit","word_nosc":"bruit","lemma":"bruit","pos":"NOM"} ,
		{"word":"bruitage","word_nosc":"bruitage","lemma":"bruitage","pos":"NOM"} ,
		{"word":"bruitages","word_nosc":"bruitages","lemma":"bruitage","pos":"NOM"} ,
		{"word":"bruiteur","word_nosc":"bruiteur","lemma":"bruiteur","pos":"NOM"} ,
		{"word":"bruiteuse","word_nosc":"bruiteuse","lemma":"bruiteur","pos":"NOM"} ,
		{"word":"bruits","word_nosc":"bruits","lemma":"bruit","pos":"NOM"} ,
		{"word":"brumaille","word_nosc":"brumaille","lemma":"brumaille","pos":"NOM"} ,
		{"word":"brumaire","word_nosc":"brumaire","lemma":"brumaire","pos":"NOM"} ,
		{"word":"brume","word_nosc":"brume","lemma":"brume","pos":"NOM"} ,
		{"word":"brumes","word_nosc":"brumes","lemma":"brume","pos":"NOM"} ,
		{"word":"brumisateur","word_nosc":"brumisateur","lemma":"brumisateur","pos":"NOM"} ,
		{"word":"brun","word_nosc":"brun","lemma":"brun","pos":"NOM"} ,
		{"word":"brunch","word_nosc":"brunch","lemma":"brunch","pos":"NOM"} ,
		{"word":"brunches","word_nosc":"brunches","lemma":"brunch","pos":"NOM"} ,
		{"word":"brunchs","word_nosc":"brunchs","lemma":"brunch","pos":"NOM"} ,
		{"word":"brune","word_nosc":"brune","lemma":"brune","pos":"NOM"} ,
		{"word":"brunes","word_nosc":"brunes","lemma":"brune","pos":"NOM"} ,
		{"word":"brunette","word_nosc":"brunette","lemma":"brunette","pos":"NOM"} ,
		{"word":"brunettes","word_nosc":"brunettes","lemma":"brunette","pos":"NOM"} ,
		{"word":"bruni","word_nosc":"bruni","lemma":"bruni","pos":"NOM"} ,
		{"word":"brunissage","word_nosc":"brunissage","lemma":"brunissage","pos":"NOM"} ,
		{"word":"brunissoir","word_nosc":"brunissoir","lemma":"brunissoir","pos":"NOM"} ,
		{"word":"brunissures","word_nosc":"brunissures","lemma":"brunissure","pos":"NOM"} ,
		{"word":"bruns","word_nosc":"bruns","lemma":"brun","pos":"NOM"} ,
		{"word":"brus","word_nosc":"brus","lemma":"bru","pos":"NOM"} ,
		{"word":"brushing","word_nosc":"brushing","lemma":"brushing","pos":"NOM"} ,
		{"word":"brusquerie","word_nosc":"brusquerie","lemma":"brusquerie","pos":"NOM"} ,
		{"word":"brusqueries","word_nosc":"brusqueries","lemma":"brusquerie","pos":"NOM"} ,
		{"word":"brut","word_nosc":"brut","lemma":"brut","pos":"NOM"} ,
		{"word":"brutalité","word_nosc":"brutalite","lemma":"brutalité","pos":"NOM"} ,
		{"word":"brutalités","word_nosc":"brutalites","lemma":"brutalité","pos":"NOM"} ,
		{"word":"brute","word_nosc":"brute","lemma":"brute","pos":"NOM"} ,
		{"word":"brutes","word_nosc":"brutes","lemma":"brute","pos":"NOM"} ,
		{"word":"bruts","word_nosc":"bruts","lemma":"brut","pos":"NOM"} ,
		{"word":"bruyère","word_nosc":"bruyere","lemma":"bruyère","pos":"NOM"} ,
		{"word":"bruyères","word_nosc":"bruyeres","lemma":"bruyère","pos":"NOM"} ,
		{"word":"brèche","word_nosc":"breche","lemma":"brèche","pos":"NOM"} ,
		{"word":"brèche-dents","word_nosc":"breche-dents","lemma":"brèche-dent","pos":"NOM"} ,
		{"word":"brèches","word_nosc":"breches","lemma":"brèche","pos":"NOM"} ,
		{"word":"brème","word_nosc":"breme","lemma":"brème","pos":"NOM"} ,
		{"word":"brèmes","word_nosc":"bremes","lemma":"brème","pos":"NOM"} ,
		{"word":"brève","word_nosc":"breve","lemma":"brève","pos":"NOM"} ,
		{"word":"brèves","word_nosc":"breves","lemma":"brève","pos":"NOM"} ,
		{"word":"bréchet","word_nosc":"brechet","lemma":"bréchet","pos":"NOM"} ,
		{"word":"brésil","word_nosc":"bresil","lemma":"brésil","pos":"NOM"} ,
		{"word":"brésilien","word_nosc":"bresilien","lemma":"brésilien","pos":"NOM"} ,
		{"word":"brésilienne","word_nosc":"bresilienne","lemma":"brésilien","pos":"NOM"} ,
		{"word":"brésiliennes","word_nosc":"bresiliennes","lemma":"brésilien","pos":"NOM"} ,
		{"word":"brésiliens","word_nosc":"bresiliens","lemma":"brésilien","pos":"NOM"} ,
		{"word":"brésils","word_nosc":"bresils","lemma":"brésil","pos":"NOM"} ,
		{"word":"bréviaire","word_nosc":"breviaire","lemma":"bréviaire","pos":"NOM"} ,
		{"word":"brûlage","word_nosc":"brulage","lemma":"brûlage","pos":"NOM"} ,
		{"word":"brûle-gueule","word_nosc":"brule-gueule","lemma":"brûle-gueule","pos":"NOM"} ,
		{"word":"brûleur","word_nosc":"bruleur","lemma":"brûleur","pos":"NOM"} ,
		{"word":"brûleurs","word_nosc":"bruleurs","lemma":"brûleur","pos":"NOM"} ,
		{"word":"brûleuse","word_nosc":"bruleuse","lemma":"brûleur","pos":"NOM"} ,
		{"word":"brûlis","word_nosc":"brulis","lemma":"brûlis","pos":"NOM"} ,
		{"word":"brûloirs","word_nosc":"bruloirs","lemma":"brûloir","pos":"NOM"} ,
		{"word":"brûlot","word_nosc":"brulot","lemma":"brûlot","pos":"NOM"} ,
		{"word":"brûlots","word_nosc":"brulots","lemma":"brûlot","pos":"NOM"} ,
		{"word":"brûlure","word_nosc":"brulure","lemma":"brûlure","pos":"NOM"} ,
		{"word":"brûlures","word_nosc":"brulures","lemma":"brûlure","pos":"NOM"} ,
		{"word":"brûlé","word_nosc":"brule","lemma":"brûlé","pos":"NOM"} ,
		{"word":"brûlée","word_nosc":"brulee","lemma":"brûlé","pos":"NOM"} ,
		{"word":"brûlées","word_nosc":"brulees","lemma":"brûlé","pos":"NOM"} ,
		{"word":"brûlés","word_nosc":"brules","lemma":"brûlé","pos":"NOM"} ,
		{"word":"buanderie","word_nosc":"buanderie","lemma":"buanderie","pos":"NOM"} ,
		{"word":"buanderies","word_nosc":"buanderies","lemma":"buanderie","pos":"NOM"} ,
		{"word":"bubble gum","word_nosc":"bubble gum","lemma":"bubble-gum","pos":"NOM"} ,
		{"word":"bubble-gum","word_nosc":"bubble-gum","lemma":"bubble-gum","pos":"NOM"} ,
		{"word":"bubon","word_nosc":"bubon","lemma":"bubon","pos":"NOM"} ,
		{"word":"bubons","word_nosc":"bubons","lemma":"bubon","pos":"NOM"} ,
		{"word":"buccin","word_nosc":"buccin","lemma":"buccin","pos":"NOM"} ,
		{"word":"buccinateur","word_nosc":"buccinateur","lemma":"buccinateur","pos":"NOM"} ,
		{"word":"bucco","word_nosc":"bucco","lemma":"bucco","pos":"NOM"} ,
		{"word":"bucoliastes","word_nosc":"bucoliastes","lemma":"bucoliaste","pos":"NOM"} ,
		{"word":"bucoliques","word_nosc":"bucoliques","lemma":"bucolique","pos":"NOM"} ,
		{"word":"bucranes","word_nosc":"bucranes","lemma":"bucrane","pos":"NOM"} ,
		{"word":"bucéphales","word_nosc":"bucephales","lemma":"bucéphale","pos":"NOM"} ,
		{"word":"buddha","word_nosc":"buddha","lemma":"buddha","pos":"NOM"} ,
		{"word":"budget","word_nosc":"budget","lemma":"budget","pos":"NOM"} ,
		{"word":"budgets","word_nosc":"budgets","lemma":"budget","pos":"NOM"} ,
		{"word":"budgétisation","word_nosc":"budgetisation","lemma":"budgétisation","pos":"NOM"} ,
		{"word":"budgétivores","word_nosc":"budgetivores","lemma":"budgétivore","pos":"NOM"} ,
		{"word":"buen retiro","word_nosc":"buen retiro","lemma":"buen retiro","pos":"NOM"} ,
		{"word":"buffalo","word_nosc":"buffalo","lemma":"buffalo","pos":"NOM"} ,
		{"word":"buffet","word_nosc":"buffet","lemma":"buffet","pos":"NOM"} ,
		{"word":"buffets","word_nosc":"buffets","lemma":"buffet","pos":"NOM"} ,
		{"word":"buffle","word_nosc":"buffle","lemma":"buffle","pos":"NOM"} ,
		{"word":"buffles","word_nosc":"buffles","lemma":"buffle","pos":"NOM"} ,
		{"word":"buffleterie","word_nosc":"buffleterie","lemma":"buffleterie","pos":"NOM"} ,
		{"word":"buffleteries","word_nosc":"buffleteries","lemma":"buffleterie","pos":"NOM"} ,
		{"word":"bufflonne","word_nosc":"bufflonne","lemma":"bufflon","pos":"NOM"} ,
		{"word":"bufo","word_nosc":"bufo","lemma":"bufo","pos":"NOM"} ,
		{"word":"bug","word_nosc":"bug","lemma":"bug","pos":"NOM"} ,
		{"word":"buggy","word_nosc":"buggy","lemma":"buggy","pos":"NOM"} ,
		{"word":"bugle","word_nosc":"bugle","lemma":"bugle","pos":"NOM"} ,
		{"word":"bugles","word_nosc":"bugles","lemma":"bugle","pos":"NOM"} ,
		{"word":"bugne","word_nosc":"bugne","lemma":"bugne","pos":"NOM"} ,
		{"word":"bugnes","word_nosc":"bugnes","lemma":"bugne","pos":"NOM"} ,
		{"word":"bugs","word_nosc":"bugs","lemma":"bug","pos":"NOM"} ,
		{"word":"building","word_nosc":"building","lemma":"building","pos":"NOM"} ,
		{"word":"buildings","word_nosc":"buildings","lemma":"building","pos":"NOM"} ,
		{"word":"buires","word_nosc":"buires","lemma":"buire","pos":"NOM"} ,
		{"word":"buis","word_nosc":"buis","lemma":"buis","pos":"NOM"} ,
		{"word":"buisson","word_nosc":"buisson","lemma":"buisson","pos":"NOM"} ,
		{"word":"buisson-ardent","word_nosc":"buisson-ardent","lemma":"buisson-ardent","pos":"NOM"} ,
		{"word":"buissons","word_nosc":"buissons","lemma":"buisson","pos":"NOM"} ,
		{"word":"bulbe","word_nosc":"bulbe","lemma":"bulbe","pos":"NOM"} ,
		{"word":"bulbes","word_nosc":"bulbes","lemma":"bulbe","pos":"NOM"} ,
		{"word":"bulbul","word_nosc":"bulbul","lemma":"bulbul","pos":"NOM"} ,
		{"word":"bulgare","word_nosc":"bulgare","lemma":"bulgare","pos":"NOM"} ,
		{"word":"bulgares","word_nosc":"bulgares","lemma":"bulgare","pos":"NOM"} ,
		{"word":"bulge","word_nosc":"bulge","lemma":"bulge","pos":"NOM"} ,
		{"word":"bull","word_nosc":"bull","lemma":"bull","pos":"NOM"} ,
		{"word":"bull-dog","word_nosc":"bull-dog","lemma":"bull-dog","pos":"NOM"} ,
		{"word":"bull-dogs","word_nosc":"bull-dogs","lemma":"bull-dog","pos":"NOM"} ,
		{"word":"bull-finch","word_nosc":"bull-finch","lemma":"bull-finch","pos":"NOM"} ,
		{"word":"bull-terrier","word_nosc":"bull-terrier","lemma":"bull-terrier","pos":"NOM"} ,
		{"word":"bullaire","word_nosc":"bullaire","lemma":"bullaire","pos":"NOM"} ,
		{"word":"bulldog","word_nosc":"bulldog","lemma":"bulldog","pos":"NOM"} ,
		{"word":"bulldogs","word_nosc":"bulldogs","lemma":"bulldog","pos":"NOM"} ,
		{"word":"bulldozer","word_nosc":"bulldozer","lemma":"bulldozer","pos":"NOM"} ,
		{"word":"bulldozers","word_nosc":"bulldozers","lemma":"bulldozer","pos":"NOM"} ,
		{"word":"bulle","word_nosc":"bulle","lemma":"bulle","pos":"NOM"} ,
		{"word":"bulles","word_nosc":"bulles","lemma":"bulle","pos":"NOM"} ,
		{"word":"bulletin","word_nosc":"bulletin","lemma":"bulletin","pos":"NOM"} ,
		{"word":"bulletins","word_nosc":"bulletins","lemma":"bulletin","pos":"NOM"} ,
		{"word":"bulls","word_nosc":"bulls","lemma":"bull","pos":"NOM"} ,
		{"word":"bulot","word_nosc":"bulot","lemma":"bulot","pos":"NOM"} ,
		{"word":"bulots","word_nosc":"bulots","lemma":"bulot","pos":"NOM"} ,
		{"word":"bumper","word_nosc":"bumper","lemma":"bumper","pos":"NOM"} ,
		{"word":"buna","word_nosc":"buna","lemma":"buna","pos":"NOM"} ,
		{"word":"bungalow","word_nosc":"bungalow","lemma":"bungalow","pos":"NOM"} ,
		{"word":"bungalows","word_nosc":"bungalows","lemma":"bungalow","pos":"NOM"} ,
		{"word":"bunker","word_nosc":"bunker","lemma":"bunker","pos":"NOM"} ,
		{"word":"bunkers","word_nosc":"bunkers","lemma":"bunker","pos":"NOM"} ,
		{"word":"bunraku","word_nosc":"bunraku","lemma":"bunraku","pos":"NOM"} ,
		{"word":"bunsen","word_nosc":"bunsen","lemma":"bunsen","pos":"NOM"} ,
		{"word":"buprestes","word_nosc":"buprestes","lemma":"bupreste","pos":"NOM"} ,
		{"word":"buraliste","word_nosc":"buraliste","lemma":"buraliste","pos":"NOM"} ,
		{"word":"buralistes","word_nosc":"buralistes","lemma":"buraliste","pos":"NOM"} ,
		{"word":"bure","word_nosc":"bure","lemma":"bure","pos":"NOM"} ,
		{"word":"bureau","word_nosc":"bureau","lemma":"bureau","pos":"NOM"} ,
		{"word":"bureaucrate","word_nosc":"bureaucrate","lemma":"bureaucrate","pos":"NOM"} ,
		{"word":"bureaucrates","word_nosc":"bureaucrates","lemma":"bureaucrate","pos":"NOM"} ,
		{"word":"bureaucratie","word_nosc":"bureaucratie","lemma":"bureaucratie","pos":"NOM"} ,
		{"word":"bureaucraties","word_nosc":"bureaucraties","lemma":"bureaucratie","pos":"NOM"} ,
		{"word":"bureautique","word_nosc":"bureautique","lemma":"bureautique","pos":"NOM"} ,
		{"word":"bureaux","word_nosc":"bureaux","lemma":"bureau","pos":"NOM"} ,
		{"word":"burette","word_nosc":"burette","lemma":"burette","pos":"NOM"} ,
		{"word":"burettes","word_nosc":"burettes","lemma":"burette","pos":"NOM"} ,
		{"word":"burg","word_nosc":"burg","lemma":"burg","pos":"NOM"} ,
		{"word":"burger","word_nosc":"burger","lemma":"burger","pos":"NOM"} ,
		{"word":"burgers","word_nosc":"burgers","lemma":"burger","pos":"NOM"} ,
		{"word":"burgraviat","word_nosc":"burgraviat","lemma":"burgraviat","pos":"NOM"} ,
		{"word":"burin","word_nosc":"burin","lemma":"burin","pos":"NOM"} ,
		{"word":"burins","word_nosc":"burins","lemma":"burin","pos":"NOM"} ,
		{"word":"burlesque","word_nosc":"burlesque","lemma":"burlesque","pos":"NOM"} ,
		{"word":"burlesques","word_nosc":"burlesques","lemma":"burlesque","pos":"NOM"} ,
		{"word":"burlingue","word_nosc":"burlingue","lemma":"burlingue","pos":"NOM"} ,
		{"word":"burlingues","word_nosc":"burlingues","lemma":"burlingue","pos":"NOM"} ,
		{"word":"burne","word_nosc":"burne","lemma":"burne","pos":"NOM"} ,
		{"word":"burnes","word_nosc":"burnes","lemma":"burne","pos":"NOM"} ,
		{"word":"burnous","word_nosc":"burnous","lemma":"burnous","pos":"NOM"} ,
		{"word":"burons","word_nosc":"burons","lemma":"buron","pos":"NOM"} ,
		{"word":"bursite","word_nosc":"bursite","lemma":"bursite","pos":"NOM"} ,
		{"word":"burundais","word_nosc":"burundais","lemma":"burundais","pos":"NOM"} ,
		{"word":"bus","word_nosc":"bus","lemma":"bus","pos":"NOM"} ,
		{"word":"busard","word_nosc":"busard","lemma":"busard","pos":"NOM"} ,
		{"word":"busards","word_nosc":"busards","lemma":"busard","pos":"NOM"} ,
		{"word":"buse","word_nosc":"buse","lemma":"buse","pos":"NOM"} ,
		{"word":"buses","word_nosc":"buses","lemma":"buse","pos":"NOM"} ,
		{"word":"bush","word_nosc":"bush","lemma":"bush","pos":"NOM"} ,
		{"word":"bushi","word_nosc":"bushi","lemma":"bushi","pos":"NOM"} ,
		{"word":"bushido","word_nosc":"bushido","lemma":"bushido","pos":"NOM"} ,
		{"word":"bushman","word_nosc":"bushman","lemma":"bushman","pos":"NOM"} ,
		{"word":"business","word_nosc":"business","lemma":"business","pos":"NOM"} ,
		{"word":"businessman","word_nosc":"businessman","lemma":"businessman","pos":"NOM"} ,
		{"word":"businessmen","word_nosc":"businessmen","lemma":"businessman","pos":"NOM"} ,
		{"word":"buste","word_nosc":"buste","lemma":"buste","pos":"NOM"} ,
		{"word":"bustes","word_nosc":"bustes","lemma":"buste","pos":"NOM"} ,
		{"word":"bustier","word_nosc":"bustier","lemma":"bustier","pos":"NOM"} ,
		{"word":"but","word_nosc":"but","lemma":"but","pos":"NOM"} ,
		{"word":"butadiène","word_nosc":"butadiene","lemma":"butadiène","pos":"NOM"} ,
		{"word":"butagaz","word_nosc":"butagaz","lemma":"butagaz","pos":"NOM"} ,
		{"word":"butane","word_nosc":"butane","lemma":"butane","pos":"NOM"} ,
		{"word":"bute","word_nosc":"bute","lemma":"bute","pos":"NOM"} ,
		{"word":"buteur","word_nosc":"buteur","lemma":"buteur","pos":"NOM"} ,
		{"word":"butin","word_nosc":"butin","lemma":"butin","pos":"NOM"} ,
		{"word":"butins","word_nosc":"butins","lemma":"butin","pos":"NOM"} ,
		{"word":"butoir","word_nosc":"butoir","lemma":"butoir","pos":"NOM"} ,
		{"word":"butoirs","word_nosc":"butoirs","lemma":"butoir","pos":"NOM"} ,
		{"word":"butor","word_nosc":"butor","lemma":"butor","pos":"NOM"} ,
		{"word":"butors","word_nosc":"butors","lemma":"butor","pos":"NOM"} ,
		{"word":"buts","word_nosc":"buts","lemma":"but","pos":"NOM"} ,
		{"word":"butte","word_nosc":"butte","lemma":"butte","pos":"NOM"} ,
		{"word":"buttes","word_nosc":"buttes","lemma":"butte","pos":"NOM"} ,
		{"word":"butteur","word_nosc":"butteur","lemma":"butteur","pos":"NOM"} ,
		{"word":"buttoir","word_nosc":"buttoir","lemma":"buttoir","pos":"NOM"} ,
		{"word":"butée","word_nosc":"butee","lemma":"butée","pos":"NOM"} ,
		{"word":"buvant","word_nosc":"buvant","lemma":"buvant","pos":"NOM"} ,
		{"word":"buvard","word_nosc":"buvard","lemma":"buvard","pos":"NOM"} ,
		{"word":"buvards","word_nosc":"buvards","lemma":"buvard","pos":"NOM"} ,
		{"word":"buvette","word_nosc":"buvette","lemma":"buvette","pos":"NOM"} ,
		{"word":"buvettes","word_nosc":"buvettes","lemma":"buvette","pos":"NOM"} ,
		{"word":"buveur","word_nosc":"buveur","lemma":"buveur","pos":"NOM"} ,
		{"word":"buveurs","word_nosc":"buveurs","lemma":"buveur","pos":"NOM"} ,
		{"word":"buveuse","word_nosc":"buveuse","lemma":"buveur","pos":"NOM"} ,
		{"word":"buée","word_nosc":"buee","lemma":"buée","pos":"NOM"} ,
		{"word":"buées","word_nosc":"buees","lemma":"buée","pos":"NOM"} ,
		{"word":"by","word_nosc":"by","lemma":"by","pos":"NOM"} ,
		{"word":"by-pass","word_nosc":"by-pass","lemma":"by-pass","pos":"NOM"} ,
		{"word":"bytes","word_nosc":"bytes","lemma":"byte","pos":"NOM"} ,
		{"word":"bytures","word_nosc":"bytures","lemma":"byture","pos":"NOM"} ,
		{"word":"byzantinisme","word_nosc":"byzantinisme","lemma":"byzantinisme","pos":"NOM"} ,
		{"word":"byzantins","word_nosc":"byzantins","lemma":"byzantin","pos":"NOM"} ,
		{"word":"bâbord","word_nosc":"babord","lemma":"bâbord","pos":"NOM"} ,
		{"word":"bâche","word_nosc":"bache","lemma":"bâche","pos":"NOM"} ,
		{"word":"bâches","word_nosc":"baches","lemma":"bâche","pos":"NOM"} ,
		{"word":"bâcleurs","word_nosc":"bacleurs","lemma":"bâcleur","pos":"NOM"} ,
		{"word":"bâfrements","word_nosc":"bafrements","lemma":"bâfrement","pos":"NOM"} ,
		{"word":"bâfreur","word_nosc":"bafreur","lemma":"bâfreur","pos":"NOM"} ,
		{"word":"bâfreurs","word_nosc":"bafreurs","lemma":"bâfreur","pos":"NOM"} ,
		{"word":"bâillement","word_nosc":"baillement","lemma":"bâillement","pos":"NOM"} ,
		{"word":"bâillements","word_nosc":"baillements","lemma":"bâillement","pos":"NOM"} ,
		{"word":"bâillon","word_nosc":"baillon","lemma":"bâillon","pos":"NOM"} ,
		{"word":"bâillons","word_nosc":"baillons","lemma":"bâillon","pos":"NOM"} ,
		{"word":"bât","word_nosc":"bat","lemma":"bât","pos":"NOM"} ,
		{"word":"bâtard","word_nosc":"batard","lemma":"bâtard","pos":"NOM"} ,
		{"word":"bâtarde","word_nosc":"batarde","lemma":"bâtard","pos":"NOM"} ,
		{"word":"bâtardes","word_nosc":"batardes","lemma":"bâtard","pos":"NOM"} ,
		{"word":"bâtardise","word_nosc":"batardise","lemma":"bâtardise","pos":"NOM"} ,
		{"word":"bâtardises","word_nosc":"batardises","lemma":"bâtardise","pos":"NOM"} ,
		{"word":"bâtards","word_nosc":"batards","lemma":"bâtard","pos":"NOM"} ,
		{"word":"bâti","word_nosc":"bati","lemma":"bâti","pos":"NOM"} ,
		{"word":"bâtiment","word_nosc":"batiment","lemma":"bâtiment","pos":"NOM"} ,
		{"word":"bâtiments","word_nosc":"batiments","lemma":"bâtiment","pos":"NOM"} ,
		{"word":"bâtis","word_nosc":"batis","lemma":"bâti","pos":"NOM"} ,
		{"word":"bâtisse","word_nosc":"batisse","lemma":"bâtisse","pos":"NOM"} ,
		{"word":"bâtisses","word_nosc":"batisses","lemma":"bâtisse","pos":"NOM"} ,
		{"word":"bâtisseur","word_nosc":"batisseur","lemma":"bâtisseur","pos":"NOM"} ,
		{"word":"bâtisseurs","word_nosc":"batisseurs","lemma":"bâtisseur","pos":"NOM"} ,
		{"word":"bâtisseuse","word_nosc":"batisseuse","lemma":"bâtisseur","pos":"NOM"} ,
		{"word":"bâtière","word_nosc":"batiere","lemma":"bâtier","pos":"NOM"} ,
		{"word":"bâton","word_nosc":"baton","lemma":"bâton","pos":"NOM"} ,
		{"word":"bâtonnet","word_nosc":"batonnet","lemma":"bâtonnet","pos":"NOM"} ,
		{"word":"bâtonnets","word_nosc":"batonnets","lemma":"bâtonnet","pos":"NOM"} ,
		{"word":"bâtonnier","word_nosc":"batonnier","lemma":"bâtonnier","pos":"NOM"} ,
		{"word":"bâtons","word_nosc":"batons","lemma":"bâton","pos":"NOM"} ,
		{"word":"bâts","word_nosc":"bats","lemma":"bât","pos":"NOM"} ,
		{"word":"bègue","word_nosc":"begue","lemma":"bègue","pos":"NOM"} ,
		{"word":"bègues","word_nosc":"begues","lemma":"bègue","pos":"NOM"} ,
		{"word":"béance","word_nosc":"beance","lemma":"béance","pos":"NOM"} ,
		{"word":"béarnaise","word_nosc":"bearnaise","lemma":"béarnais","pos":"NOM"} ,
		{"word":"béat","word_nosc":"beat","lemma":"béat","pos":"NOM"} ,
		{"word":"béatification","word_nosc":"beatification","lemma":"béatification","pos":"NOM"} ,
		{"word":"béatifications","word_nosc":"beatifications","lemma":"béatification","pos":"NOM"} ,
		{"word":"béatitude","word_nosc":"beatitude","lemma":"béatitude","pos":"NOM"} ,
		{"word":"béatitudes","word_nosc":"beatitudes","lemma":"béatitude","pos":"NOM"} ,
		{"word":"béats","word_nosc":"beats","lemma":"béat","pos":"NOM"} ,
		{"word":"bébé","word_nosc":"bebe","lemma":"bébé","pos":"NOM"} ,
		{"word":"bébés","word_nosc":"bebes","lemma":"bébé","pos":"NOM"} ,
		{"word":"bébés-éprouvette","word_nosc":"bebes-eprouvette","lemma":"bébé-éprouvette","pos":"NOM"} ,
		{"word":"bécabunga","word_nosc":"becabunga","lemma":"bécabunga","pos":"NOM"} ,
		{"word":"bécane","word_nosc":"becane","lemma":"bécane","pos":"NOM"} ,
		{"word":"bécanes","word_nosc":"becanes","lemma":"bécane","pos":"NOM"} ,
		{"word":"bécard","word_nosc":"becard","lemma":"bécard","pos":"NOM"} ,
		{"word":"bécarre","word_nosc":"becarre","lemma":"bécarre","pos":"NOM"} ,
		{"word":"bécasse","word_nosc":"becasse","lemma":"bécasse","pos":"NOM"} ,
		{"word":"bécasses","word_nosc":"becasses","lemma":"bécasse","pos":"NOM"} ,
		{"word":"bécassine","word_nosc":"becassine","lemma":"bécassine","pos":"NOM"} ,
		{"word":"bécassines","word_nosc":"becassines","lemma":"bécassine","pos":"NOM"} ,
		{"word":"béchamel","word_nosc":"bechamel","lemma":"béchamel","pos":"NOM"} ,
		{"word":"bécher","word_nosc":"becher","lemma":"bécher","pos":"NOM"} ,
		{"word":"bécot","word_nosc":"becot","lemma":"bécot","pos":"NOM"} ,
		{"word":"bécoteur","word_nosc":"becoteur","lemma":"bécoteur","pos":"NOM"} ,
		{"word":"bécots","word_nosc":"becots","lemma":"bécot","pos":"NOM"} ,
		{"word":"bédane","word_nosc":"bedane","lemma":"bédane","pos":"NOM"} ,
		{"word":"bédouin","word_nosc":"bedouin","lemma":"bédouin","pos":"NOM"} ,
		{"word":"bédouine","word_nosc":"bedouine","lemma":"bédouin","pos":"NOM"} ,
		{"word":"bédouines","word_nosc":"bedouines","lemma":"bédouin","pos":"NOM"} ,
		{"word":"bédouins","word_nosc":"bedouins","lemma":"bédouin","pos":"NOM"} ,
		{"word":"bédé","word_nosc":"bede","lemma":"bédé","pos":"NOM"} ,
		{"word":"bédéphiles","word_nosc":"bedephiles","lemma":"bédéphile","pos":"NOM"} ,
		{"word":"bédés","word_nosc":"bedes","lemma":"bédé","pos":"NOM"} ,
		{"word":"bégaiement","word_nosc":"begaiement","lemma":"bégaiement","pos":"NOM"} ,
		{"word":"bégaiements","word_nosc":"begaiements","lemma":"bégaiement","pos":"NOM"} ,
		{"word":"bégonias","word_nosc":"begonias","lemma":"bégonia","pos":"NOM"} ,
		{"word":"bégueule","word_nosc":"begueule","lemma":"bégueule","pos":"NOM"} ,
		{"word":"bégueulerie","word_nosc":"begueulerie","lemma":"bégueulerie","pos":"NOM"} ,
		{"word":"bégueules","word_nosc":"begueules","lemma":"bégueule","pos":"NOM"} ,
		{"word":"béguin","word_nosc":"beguin","lemma":"béguin","pos":"NOM"} ,
		{"word":"béguinage","word_nosc":"beguinage","lemma":"béguinage","pos":"NOM"} ,
		{"word":"béguine","word_nosc":"beguine","lemma":"béguine","pos":"NOM"} ,
		{"word":"béguineuse","word_nosc":"beguineuse","lemma":"béguineuse","pos":"NOM"} ,
		{"word":"béguins","word_nosc":"beguins","lemma":"béguin","pos":"NOM"} ,
		{"word":"bégum","word_nosc":"begum","lemma":"bégum","pos":"NOM"} ,
		{"word":"béhème","word_nosc":"beheme","lemma":"béhème","pos":"NOM"} ,
		{"word":"béhémoth","word_nosc":"behemoth","lemma":"béhémoth","pos":"NOM"} ,
		{"word":"béjaune","word_nosc":"bejaune","lemma":"béjaune","pos":"NOM"} ,
		{"word":"bélier","word_nosc":"belier","lemma":"bélier","pos":"NOM"} ,
		{"word":"béliers","word_nosc":"beliers","lemma":"bélier","pos":"NOM"} ,
		{"word":"bélinogramme","word_nosc":"belinogramme","lemma":"bélinogramme","pos":"NOM"} ,
		{"word":"bélouga","word_nosc":"belouga","lemma":"bélouga","pos":"NOM"} ,
		{"word":"béluga","word_nosc":"beluga","lemma":"béluga","pos":"NOM"} ,
		{"word":"bélître","word_nosc":"belitre","lemma":"bélître","pos":"NOM"} ,
		{"word":"bémol","word_nosc":"bemol","lemma":"bémol","pos":"NOM"} ,
		{"word":"bémols","word_nosc":"bemols","lemma":"bémol","pos":"NOM"} ,
		{"word":"bénard","word_nosc":"benard","lemma":"bénard","pos":"NOM"} ,
		{"word":"bénards","word_nosc":"benards","lemma":"bénard","pos":"NOM"} ,
		{"word":"bénef","word_nosc":"benef","lemma":"bénef","pos":"NOM"} ,
		{"word":"bénefs","word_nosc":"benefs","lemma":"bénef","pos":"NOM"} ,
		{"word":"bénignité","word_nosc":"benignite","lemma":"bénignité","pos":"NOM"} ,
		{"word":"bénisseuse","word_nosc":"benisseuse","lemma":"bénisseur","pos":"NOM"} ,
		{"word":"bénitier","word_nosc":"benitier","lemma":"bénitier","pos":"NOM"} ,
		{"word":"bénitiers","word_nosc":"benitiers","lemma":"bénitier","pos":"NOM"} ,
		{"word":"bénouze","word_nosc":"benouze","lemma":"bénouze","pos":"NOM"} ,
		{"word":"bénédicité","word_nosc":"benedicite","lemma":"bénédicité","pos":"NOM"} ,
		{"word":"bénédicités","word_nosc":"benedicites","lemma":"bénédicité","pos":"NOM"} ,
		{"word":"bénédictin","word_nosc":"benedictin","lemma":"bénédictin","pos":"NOM"} ,
		{"word":"bénédictine","word_nosc":"benedictine","lemma":"bénédictin","pos":"NOM"} ,
		{"word":"bénédictines","word_nosc":"benedictines","lemma":"bénédictin","pos":"NOM"} ,
		{"word":"bénédictins","word_nosc":"benedictins","lemma":"bénédictin","pos":"NOM"} ,
		{"word":"bénédiction","word_nosc":"benediction","lemma":"bénédiction","pos":"NOM"} ,
		{"word":"bénédictions","word_nosc":"benedictions","lemma":"bénédiction","pos":"NOM"} ,
		{"word":"bénéfice","word_nosc":"benefice","lemma":"bénéfice","pos":"NOM"} ,
		{"word":"bénéfices","word_nosc":"benefices","lemma":"bénéfice","pos":"NOM"} ,
		{"word":"bénéficiaire","word_nosc":"beneficiaire","lemma":"bénéficiaire","pos":"NOM"} ,
		{"word":"bénéficiaires","word_nosc":"beneficiaires","lemma":"bénéficiaire","pos":"NOM"} ,
		{"word":"bénévolat","word_nosc":"benevolat","lemma":"bénévolat","pos":"NOM"} ,
		{"word":"bénévole","word_nosc":"benevole","lemma":"bénévole","pos":"NOM"} ,
		{"word":"bénévolence","word_nosc":"benevolence","lemma":"bénévolence","pos":"NOM"} ,
		{"word":"bénévoles","word_nosc":"benevoles","lemma":"bénévole","pos":"NOM"} ,
		{"word":"béotien","word_nosc":"beotien","lemma":"béotien","pos":"NOM"} ,
		{"word":"béotienne","word_nosc":"beotienne","lemma":"béotien","pos":"NOM"} ,
		{"word":"béotiens","word_nosc":"beotiens","lemma":"béotien","pos":"NOM"} ,
		{"word":"béquille","word_nosc":"bequille","lemma":"béquille","pos":"NOM"} ,
		{"word":"béquilles","word_nosc":"bequilles","lemma":"béquille","pos":"NOM"} ,
		{"word":"béret","word_nosc":"beret","lemma":"béret","pos":"NOM"} ,
		{"word":"bérets","word_nosc":"berets","lemma":"béret","pos":"NOM"} ,
		{"word":"béribéri","word_nosc":"beriberi","lemma":"béribéri","pos":"NOM"} ,
		{"word":"bérullienne","word_nosc":"berullienne","lemma":"bérullien","pos":"NOM"} ,
		{"word":"béryl","word_nosc":"beryl","lemma":"béryl","pos":"NOM"} ,
		{"word":"béryllium","word_nosc":"beryllium","lemma":"béryllium","pos":"NOM"} ,
		{"word":"béryls","word_nosc":"beryls","lemma":"béryl","pos":"NOM"} ,
		{"word":"bérézina","word_nosc":"berezina","lemma":"bérézina","pos":"NOM"} ,
		{"word":"bésiclard","word_nosc":"besiclard","lemma":"bésiclard","pos":"NOM"} ,
		{"word":"bésicles","word_nosc":"besicles","lemma":"bésicles","pos":"NOM"} ,
		{"word":"bésigue","word_nosc":"besigue","lemma":"bésigue","pos":"NOM"} ,
		{"word":"bétail","word_nosc":"betail","lemma":"bétail","pos":"NOM"} ,
		{"word":"bétaillère","word_nosc":"betaillere","lemma":"bétaillère","pos":"NOM"} ,
		{"word":"bétaillères","word_nosc":"betailleres","lemma":"bétaillère","pos":"NOM"} ,
		{"word":"bétails","word_nosc":"betails","lemma":"bétail","pos":"NOM"} ,
		{"word":"bétel","word_nosc":"betel","lemma":"bétel","pos":"NOM"} ,
		{"word":"bétoine","word_nosc":"betoine","lemma":"bétoine","pos":"NOM"} ,
		{"word":"béton","word_nosc":"beton","lemma":"béton","pos":"NOM"} ,
		{"word":"bétonnage","word_nosc":"betonnage","lemma":"bétonnage","pos":"NOM"} ,
		{"word":"bétonneurs","word_nosc":"betonneurs","lemma":"bétonneur","pos":"NOM"} ,
		{"word":"bétonneuse","word_nosc":"betonneuse","lemma":"bétonneur","pos":"NOM"} ,
		{"word":"bétonneuses","word_nosc":"betonneuses","lemma":"bétonneur","pos":"NOM"} ,
		{"word":"bétonnière","word_nosc":"betonniere","lemma":"bétonnière","pos":"NOM"} ,
		{"word":"bétonnières","word_nosc":"betonnieres","lemma":"bétonnière","pos":"NOM"} ,
		{"word":"bétons","word_nosc":"betons","lemma":"béton","pos":"NOM"} ,
		{"word":"bévue","word_nosc":"bevue","lemma":"bévue","pos":"NOM"} ,
		{"word":"bévues","word_nosc":"bevues","lemma":"bévue","pos":"NOM"} ,
		{"word":"bézoard","word_nosc":"bezoard","lemma":"bézoard","pos":"NOM"} ,
		{"word":"bêche","word_nosc":"beche","lemma":"bêche","pos":"NOM"} ,
		{"word":"bêches","word_nosc":"beches","lemma":"bêche","pos":"NOM"} ,
		{"word":"bêcheur","word_nosc":"becheur","lemma":"bêcheur","pos":"NOM"} ,
		{"word":"bêcheurs","word_nosc":"becheurs","lemma":"bêcheur","pos":"NOM"} ,
		{"word":"bêcheuse","word_nosc":"becheuse","lemma":"bêcheur","pos":"NOM"} ,
		{"word":"bêcheuses","word_nosc":"becheuses","lemma":"bêcheur","pos":"NOM"} ,
		{"word":"bêlement","word_nosc":"belement","lemma":"bêlement","pos":"NOM"} ,
		{"word":"bêlements","word_nosc":"belements","lemma":"bêlement","pos":"NOM"} ,
		{"word":"bêta","word_nosc":"beta","lemma":"bêta","pos":"NOM"} ,
		{"word":"bêtas","word_nosc":"betas","lemma":"bêta","pos":"NOM"} ,
		{"word":"bêtasse","word_nosc":"betasse","lemma":"bêta","pos":"NOM"} ,
		{"word":"bêtatron","word_nosc":"betatron","lemma":"bêtatron","pos":"NOM"} ,
		{"word":"bête","word_nosc":"bete","lemma":"bête","pos":"NOM"} ,
		{"word":"bêtes","word_nosc":"betes","lemma":"bête","pos":"NOM"} ,
		{"word":"bêtise","word_nosc":"betise","lemma":"bêtise","pos":"NOM"} ,
		{"word":"bêtises","word_nosc":"betises","lemma":"bêtise","pos":"NOM"} ,
		{"word":"bêtisier","word_nosc":"betisier","lemma":"bêtisier","pos":"NOM"} ,
		{"word":"bôme","word_nosc":"bome","lemma":"bôme","pos":"NOM"} ,
		{"word":"bûche","word_nosc":"buche","lemma":"bûche","pos":"NOM"} ,
		{"word":"bûcher","word_nosc":"bucher","lemma":"bûcher","pos":"NOM"} ,
		{"word":"bûcheron","word_nosc":"bucheron","lemma":"bûcheron","pos":"NOM"} ,
		{"word":"bûcherons","word_nosc":"bucherons","lemma":"bûcheron","pos":"NOM"} ,
		{"word":"bûchers","word_nosc":"buchers","lemma":"bûcher","pos":"NOM"} ,
		{"word":"bûches","word_nosc":"buches","lemma":"bûche","pos":"NOM"} ,
		{"word":"bûchette","word_nosc":"buchette","lemma":"bûchette","pos":"NOM"} ,
		{"word":"bûchettes","word_nosc":"buchettes","lemma":"bûchette","pos":"NOM"} ,
		{"word":"bûcheur","word_nosc":"bucheur","lemma":"bûcheur","pos":"NOM"} ,
		{"word":"bûcheurs","word_nosc":"bucheurs","lemma":"bûcheur","pos":"NOM"} ,
		{"word":"bûcheuse","word_nosc":"bucheuse","lemma":"bûcheur","pos":"NOM"} ,
		{"word":"bûcheuses","word_nosc":"bucheuses","lemma":"bûcheur","pos":"NOM"} ,
		{"word":"c","word_nosc":"c","lemma":"c","pos":"NOM"} ,
		{"word":"cab","word_nosc":"cab","lemma":"cab","pos":"NOM"} ,
		{"word":"cabale","word_nosc":"cabale","lemma":"cabale","pos":"NOM"} ,
		{"word":"cabales","word_nosc":"cabales","lemma":"cabale","pos":"NOM"} ,
		{"word":"cabaliste","word_nosc":"cabaliste","lemma":"cabaliste","pos":"NOM"} ,
		{"word":"cabalistes","word_nosc":"cabalistes","lemma":"cabaliste","pos":"NOM"} ,
		{"word":"caballero","word_nosc":"caballero","lemma":"caballero","pos":"NOM"} ,
		{"word":"caban","word_nosc":"caban","lemma":"caban","pos":"NOM"} ,
		{"word":"cabane","word_nosc":"cabane","lemma":"cabane","pos":"NOM"} ,
		{"word":"cabanes","word_nosc":"cabanes","lemma":"cabane","pos":"NOM"} ,
		{"word":"cabanettes","word_nosc":"cabanettes","lemma":"cabanette","pos":"NOM"} ,
		{"word":"cabanon","word_nosc":"cabanon","lemma":"cabanon","pos":"NOM"} ,
		{"word":"cabanons","word_nosc":"cabanons","lemma":"cabanon","pos":"NOM"} ,
		{"word":"cabans","word_nosc":"cabans","lemma":"caban","pos":"NOM"} ,
		{"word":"cabaret","word_nosc":"cabaret","lemma":"cabaret","pos":"NOM"} ,
		{"word":"cabaretier","word_nosc":"cabaretier","lemma":"cabaretier","pos":"NOM"} ,
		{"word":"cabaretiers","word_nosc":"cabaretiers","lemma":"cabaretier","pos":"NOM"} ,
		{"word":"cabaretière","word_nosc":"cabaretiere","lemma":"cabaretier","pos":"NOM"} ,
		{"word":"cabarets","word_nosc":"cabarets","lemma":"cabaret","pos":"NOM"} ,
		{"word":"cabas","word_nosc":"cabas","lemma":"cabas","pos":"NOM"} ,
		{"word":"caberlot","word_nosc":"caberlot","lemma":"caberlot","pos":"NOM"} ,
		{"word":"cabernet","word_nosc":"cabernet","lemma":"cabernet","pos":"NOM"} ,
		{"word":"cabestan","word_nosc":"cabestan","lemma":"cabestan","pos":"NOM"} ,
		{"word":"cabiais","word_nosc":"cabiais","lemma":"cabiai","pos":"NOM"} ,
		{"word":"cabillaud","word_nosc":"cabillaud","lemma":"cabillaud","pos":"NOM"} ,
		{"word":"cabillots","word_nosc":"cabillots","lemma":"cabillot","pos":"NOM"} ,
		{"word":"cabine","word_nosc":"cabine","lemma":"cabine","pos":"NOM"} ,
		{"word":"cabines","word_nosc":"cabines","lemma":"cabine","pos":"NOM"} ,
		{"word":"cabinet","word_nosc":"cabinet","lemma":"cabinet","pos":"NOM"} ,
		{"word":"cabinets","word_nosc":"cabinets","lemma":"cabinet","pos":"NOM"} ,
		{"word":"caboche","word_nosc":"caboche","lemma":"caboche","pos":"NOM"} ,
		{"word":"caboches","word_nosc":"caboches","lemma":"caboche","pos":"NOM"} ,
		{"word":"cabochon","word_nosc":"cabochon","lemma":"cabochon","pos":"NOM"} ,
		{"word":"cabochons","word_nosc":"cabochons","lemma":"cabochon","pos":"NOM"} ,
		{"word":"cabossages","word_nosc":"cabossages","lemma":"cabossage","pos":"NOM"} ,
		{"word":"cabosse","word_nosc":"cabosse","lemma":"cabosse","pos":"NOM"} ,
		{"word":"cabosses","word_nosc":"cabosses","lemma":"cabosse","pos":"NOM"} ,
		{"word":"cabot","word_nosc":"cabot","lemma":"cabot","pos":"NOM"} ,
		{"word":"cabotage","word_nosc":"cabotage","lemma":"cabotage","pos":"NOM"} ,
		{"word":"caboteur","word_nosc":"caboteur","lemma":"caboteur","pos":"NOM"} ,
		{"word":"cabotin","word_nosc":"cabotin","lemma":"cabotin","pos":"NOM"} ,
		{"word":"cabotinage","word_nosc":"cabotinage","lemma":"cabotinage","pos":"NOM"} ,
		{"word":"cabotine","word_nosc":"cabotine","lemma":"cabotin","pos":"NOM"} ,
		{"word":"cabotins","word_nosc":"cabotins","lemma":"cabotin","pos":"NOM"} ,
		{"word":"cabots","word_nosc":"cabots","lemma":"cabot","pos":"NOM"} ,
		{"word":"caboulot","word_nosc":"caboulot","lemma":"caboulot","pos":"NOM"} ,
		{"word":"caboulots","word_nosc":"caboulots","lemma":"caboulot","pos":"NOM"} ,
		{"word":"cabrade","word_nosc":"cabrade","lemma":"cabrade","pos":"NOM"} ,
		{"word":"cabrage","word_nosc":"cabrage","lemma":"cabrage","pos":"NOM"} ,
		{"word":"cabrette","word_nosc":"cabrette","lemma":"cabrette","pos":"NOM"} ,
		{"word":"cabri","word_nosc":"cabri","lemma":"cabri","pos":"NOM"} ,
		{"word":"cabriole","word_nosc":"cabriole","lemma":"cabriole","pos":"NOM"} ,
		{"word":"cabrioles","word_nosc":"cabrioles","lemma":"cabriole","pos":"NOM"} ,
		{"word":"cabriolet","word_nosc":"cabriolet","lemma":"cabriolet","pos":"NOM"} ,
		{"word":"cabriolets","word_nosc":"cabriolets","lemma":"cabriolet","pos":"NOM"} ,
		{"word":"cabris","word_nosc":"cabris","lemma":"cabri","pos":"NOM"} ,
		{"word":"cabs","word_nosc":"cabs","lemma":"cab","pos":"NOM"} ,
		{"word":"cabèche","word_nosc":"cabeche","lemma":"cabèche","pos":"NOM"} ,
		{"word":"cabèches","word_nosc":"cabeches","lemma":"cabèche","pos":"NOM"} ,
		{"word":"cabécous","word_nosc":"cabecous","lemma":"cabécou","pos":"NOM"} ,
		{"word":"caca","word_nosc":"caca","lemma":"caca","pos":"NOM"} ,
		{"word":"cacahouète","word_nosc":"cacahouete","lemma":"cacahouète","pos":"NOM"} ,
		{"word":"cacahouètes","word_nosc":"cacahouetes","lemma":"cacahouète","pos":"NOM"} ,
		{"word":"cacahuète","word_nosc":"cacahuete","lemma":"cacahuète","pos":"NOM"} ,
		{"word":"cacahuètes","word_nosc":"cacahuetes","lemma":"cacahuète","pos":"NOM"} ,
		{"word":"cacao","word_nosc":"cacao","lemma":"cacao","pos":"NOM"} ,
		{"word":"cacaos","word_nosc":"cacaos","lemma":"cacao","pos":"NOM"} ,
		{"word":"cacaotier","word_nosc":"cacaotier","lemma":"cacaotier","pos":"NOM"} ,
		{"word":"cacaoyer","word_nosc":"cacaoyer","lemma":"cacaoyer","pos":"NOM"} ,
		{"word":"cacas","word_nosc":"cacas","lemma":"caca","pos":"NOM"} ,
		{"word":"cacatois","word_nosc":"cacatois","lemma":"cacatois","pos":"NOM"} ,
		{"word":"cacatoès","word_nosc":"cacatoes","lemma":"cacatoès","pos":"NOM"} ,
		{"word":"cachalot","word_nosc":"cachalot","lemma":"cachalot","pos":"NOM"} ,
		{"word":"cachalots","word_nosc":"cachalots","lemma":"cachalot","pos":"NOM"} ,
		{"word":"cache","word_nosc":"cache","lemma":"cache","pos":"NOM"} ,
		{"word":"cache-brassière","word_nosc":"cache-brassiere","lemma":"cache-brassière","pos":"NOM"} ,
		{"word":"cache-cache","word_nosc":"cache-cache","lemma":"cache-cache","pos":"NOM"} ,
		{"word":"cache-coeur","word_nosc":"cache-coeur","lemma":"cache-coeur","pos":"NOM"} ,
		{"word":"cache-col","word_nosc":"cache-col","lemma":"cache-col","pos":"NOM"} ,
		{"word":"cache-corset","word_nosc":"cache-corset","lemma":"cache-corset","pos":"NOM"} ,
		{"word":"cache-nez","word_nosc":"cache-nez","lemma":"cache-nez","pos":"NOM"} ,
		{"word":"cache-pot","word_nosc":"cache-pot","lemma":"cache-pot","pos":"NOM"} ,
		{"word":"cache-pots","word_nosc":"cache-pots","lemma":"cache-pot","pos":"NOM"} ,
		{"word":"cache-poussière","word_nosc":"cache-poussiere","lemma":"cache-poussière","pos":"NOM"} ,
		{"word":"cache-sexe","word_nosc":"cache-sexe","lemma":"cache-sexe","pos":"NOM"} ,
		{"word":"cache-tampon","word_nosc":"cache-tampon","lemma":"cache-tampon","pos":"NOM"} ,
		{"word":"cachemire","word_nosc":"cachemire","lemma":"cachemire","pos":"NOM"} ,
		{"word":"cachemires","word_nosc":"cachemires","lemma":"cachemire","pos":"NOM"} ,
		{"word":"cachemiris","word_nosc":"cachemiris","lemma":"cachemiri","pos":"NOM"} ,
		{"word":"caches","word_nosc":"caches","lemma":"cache","pos":"NOM"} ,
		{"word":"cachet","word_nosc":"cachet","lemma":"cachet","pos":"NOM"} ,
		{"word":"cacheton","word_nosc":"cacheton","lemma":"cacheton","pos":"NOM"} ,
		{"word":"cachetons","word_nosc":"cachetons","lemma":"cacheton","pos":"NOM"} ,
		{"word":"cachets","word_nosc":"cachets","lemma":"cachet","pos":"NOM"} ,
		{"word":"cachette","word_nosc":"cachette","lemma":"cachette","pos":"NOM"} ,
		{"word":"cachettes","word_nosc":"cachettes","lemma":"cachette","pos":"NOM"} ,
		{"word":"cachexie","word_nosc":"cachexie","lemma":"cachexie","pos":"NOM"} ,
		{"word":"cachot","word_nosc":"cachot","lemma":"cachot","pos":"NOM"} ,
		{"word":"cachots","word_nosc":"cachots","lemma":"cachot","pos":"NOM"} ,
		{"word":"cachotterie","word_nosc":"cachotterie","lemma":"cachotterie","pos":"NOM"} ,
		{"word":"cachotteries","word_nosc":"cachotteries","lemma":"cachotterie","pos":"NOM"} ,
		{"word":"cachottier","word_nosc":"cachottier","lemma":"cachottier","pos":"NOM"} ,
		{"word":"cachottiers","word_nosc":"cachottiers","lemma":"cachottier","pos":"NOM"} ,
		{"word":"cachottière","word_nosc":"cachottiere","lemma":"cachottier","pos":"NOM"} ,
		{"word":"cachou","word_nosc":"cachou","lemma":"cachou","pos":"NOM"} ,
		{"word":"cachous","word_nosc":"cachous","lemma":"cachou","pos":"NOM"} ,
		{"word":"cacique","word_nosc":"cacique","lemma":"cacique","pos":"NOM"} ,
		{"word":"caciques","word_nosc":"caciques","lemma":"cacique","pos":"NOM"} ,
		{"word":"cacodylate","word_nosc":"cacodylate","lemma":"cacodylate","pos":"NOM"} ,
		{"word":"cacographes","word_nosc":"cacographes","lemma":"cacographe","pos":"NOM"} ,
		{"word":"cacophonie","word_nosc":"cacophonie","lemma":"cacophonie","pos":"NOM"} ,
		{"word":"cacophonies","word_nosc":"cacophonies","lemma":"cacophonie","pos":"NOM"} ,
		{"word":"cactus","word_nosc":"cactus","lemma":"cactus","pos":"NOM"} ,
		{"word":"cactées","word_nosc":"cactees","lemma":"cactée","pos":"NOM"} ,
		{"word":"cadastre","word_nosc":"cadastre","lemma":"cadastre","pos":"NOM"} ,
		{"word":"cadastres","word_nosc":"cadastres","lemma":"cadastre","pos":"NOM"} ,
		{"word":"cadavre","word_nosc":"cadavre","lemma":"cadavre","pos":"NOM"} ,
		{"word":"cadavres","word_nosc":"cadavres","lemma":"cadavre","pos":"NOM"} ,
		{"word":"caddie","word_nosc":"caddie","lemma":"caddie","pos":"NOM"} ,
		{"word":"caddies","word_nosc":"caddies","lemma":"caddie","pos":"NOM"} ,
		{"word":"caddy","word_nosc":"caddy","lemma":"caddy","pos":"NOM"} ,
		{"word":"cade","word_nosc":"cade","lemma":"cade","pos":"NOM"} ,
		{"word":"cadeau","word_nosc":"cadeau","lemma":"cadeau","pos":"NOM"} ,
		{"word":"cadeaux","word_nosc":"cadeaux","lemma":"cadeau","pos":"NOM"} ,
		{"word":"cadenas","word_nosc":"cadenas","lemma":"cadenas","pos":"NOM"} ,
		{"word":"cadence","word_nosc":"cadence","lemma":"cadence","pos":"NOM"} ,
		{"word":"cadences","word_nosc":"cadences","lemma":"cadence","pos":"NOM"} ,
		{"word":"cadet","word_nosc":"cadet","lemma":"cadet","pos":"NOM"} ,
		{"word":"cadets","word_nosc":"cadets","lemma":"cadet","pos":"NOM"} ,
		{"word":"cadette","word_nosc":"cadette","lemma":"cadet","pos":"NOM"} ,
		{"word":"cadettes","word_nosc":"cadettes","lemma":"cadet","pos":"NOM"} ,
		{"word":"cadi","word_nosc":"cadi","lemma":"cadi","pos":"NOM"} ,
		{"word":"cadmium","word_nosc":"cadmium","lemma":"cadmium","pos":"NOM"} ,
		{"word":"cadogan","word_nosc":"cadogan","lemma":"cadogan","pos":"NOM"} ,
		{"word":"cador","word_nosc":"cador","lemma":"cador","pos":"NOM"} ,
		{"word":"cadors","word_nosc":"cadors","lemma":"cador","pos":"NOM"} ,
		{"word":"cadrage","word_nosc":"cadrage","lemma":"cadrage","pos":"NOM"} ,
		{"word":"cadrages","word_nosc":"cadrages","lemma":"cadrage","pos":"NOM"} ,
		{"word":"cadran","word_nosc":"cadran","lemma":"cadran","pos":"NOM"} ,
		{"word":"cadrans","word_nosc":"cadrans","lemma":"cadran","pos":"NOM"} ,
		{"word":"cadratin","word_nosc":"cadratin","lemma":"cadratin","pos":"NOM"} ,
		{"word":"cadratins","word_nosc":"cadratins","lemma":"cadratin","pos":"NOM"} ,
		{"word":"cadre","word_nosc":"cadre","lemma":"cadre","pos":"NOM"} ,
		{"word":"cadres","word_nosc":"cadres","lemma":"cadre","pos":"NOM"} ,
		{"word":"cadreur","word_nosc":"cadreur","lemma":"cadreur","pos":"NOM"} ,
		{"word":"cadreurs","word_nosc":"cadreurs","lemma":"cadreur","pos":"NOM"} ,
		{"word":"caducité","word_nosc":"caducite","lemma":"caducité","pos":"NOM"} ,
		{"word":"caducée","word_nosc":"caducee","lemma":"caducée","pos":"NOM"} ,
		{"word":"caducées","word_nosc":"caducees","lemma":"caducée","pos":"NOM"} ,
		{"word":"caduques","word_nosc":"caduques","lemma":"caduque","pos":"NOM"} ,
		{"word":"cadènes","word_nosc":"cadenes","lemma":"cadène","pos":"NOM"} ,
		{"word":"caecum","word_nosc":"caecum","lemma":"caecum","pos":"NOM"} ,
		{"word":"caesium","word_nosc":"caesium","lemma":"caesium","pos":"NOM"} ,
		{"word":"cafard","word_nosc":"cafard","lemma":"cafard","pos":"NOM"} ,
		{"word":"cafardage","word_nosc":"cafardage","lemma":"cafardage","pos":"NOM"} ,
		{"word":"cafardeur","word_nosc":"cafardeur","lemma":"cafardeur","pos":"NOM"} ,
		{"word":"cafardeuse","word_nosc":"cafardeuse","lemma":"cafardeur","pos":"NOM"} ,
		{"word":"cafardeux","word_nosc":"cafardeux","lemma":"cafardeux","pos":"NOM"} ,
		{"word":"cafards","word_nosc":"cafards","lemma":"cafard","pos":"NOM"} ,
		{"word":"cafetan","word_nosc":"cafetan","lemma":"cafetan","pos":"NOM"} ,
		{"word":"cafetans","word_nosc":"cafetans","lemma":"cafetan","pos":"NOM"} ,
		{"word":"cafeteria","word_nosc":"cafeteria","lemma":"cafeteria","pos":"NOM"} ,
		{"word":"cafeterias","word_nosc":"cafeterias","lemma":"cafeteria","pos":"NOM"} ,
		{"word":"cafeteur","word_nosc":"cafeteur","lemma":"cafeteur","pos":"NOM"} ,
		{"word":"cafetier","word_nosc":"cafetier","lemma":"cafetier","pos":"NOM"} ,
		{"word":"cafetiers","word_nosc":"cafetiers","lemma":"cafetier","pos":"NOM"} ,
		{"word":"cafetière","word_nosc":"cafetiere","lemma":"cafetière","pos":"NOM"} ,
		{"word":"cafetières","word_nosc":"cafetieres","lemma":"cafetière","pos":"NOM"} ,
		{"word":"cafeton","word_nosc":"cafeton","lemma":"cafeton","pos":"NOM"} ,
		{"word":"cafouillage","word_nosc":"cafouillage","lemma":"cafouillage","pos":"NOM"} ,
		{"word":"cafouillages","word_nosc":"cafouillages","lemma":"cafouillage","pos":"NOM"} ,
		{"word":"cafouillis","word_nosc":"cafouillis","lemma":"cafouillis","pos":"NOM"} ,
		{"word":"cafre","word_nosc":"cafre","lemma":"cafre","pos":"NOM"} ,
		{"word":"caftage","word_nosc":"caftage","lemma":"caftage","pos":"NOM"} ,
		{"word":"caftan","word_nosc":"caftan","lemma":"caftan","pos":"NOM"} ,
		{"word":"caftans","word_nosc":"caftans","lemma":"caftan","pos":"NOM"} ,
		{"word":"cafteur","word_nosc":"cafteur","lemma":"cafteur","pos":"NOM"} ,
		{"word":"cafteurs","word_nosc":"cafteurs","lemma":"cafteur","pos":"NOM"} ,
		{"word":"cafteuse","word_nosc":"cafteuse","lemma":"cafteur","pos":"NOM"} ,
		{"word":"café","word_nosc":"cafe","lemma":"café","pos":"NOM"} ,
		{"word":"café-concert","word_nosc":"cafe-concert","lemma":"café-concert","pos":"NOM"} ,
		{"word":"café-crème","word_nosc":"cafe-creme","lemma":"café-crème","pos":"NOM"} ,
		{"word":"café-hôtel","word_nosc":"cafe-hotel","lemma":"café-hôtel","pos":"NOM"} ,
		{"word":"café-restaurant","word_nosc":"cafe-restaurant","lemma":"café-restaurant","pos":"NOM"} ,
		{"word":"café-tabac","word_nosc":"cafe-tabac","lemma":"café-tabac","pos":"NOM"} ,
		{"word":"café-théâtre","word_nosc":"cafe-theatre","lemma":"café-théâtre","pos":"NOM"} ,
		{"word":"caféier","word_nosc":"cafeier","lemma":"caféier","pos":"NOM"} ,
		{"word":"caféiers","word_nosc":"cafeiers","lemma":"caféier","pos":"NOM"} ,
		{"word":"caféine","word_nosc":"cafeine","lemma":"caféine","pos":"NOM"} ,
		{"word":"cafés","word_nosc":"cafes","lemma":"café","pos":"NOM"} ,
		{"word":"cafés-concerts","word_nosc":"cafes-concerts","lemma":"café-concert","pos":"NOM"} ,
		{"word":"cafétéria","word_nosc":"cafeteria","lemma":"cafétéria","pos":"NOM"} ,
		{"word":"cafétérias","word_nosc":"cafeterias","lemma":"cafétéria","pos":"NOM"} ,
		{"word":"cagade","word_nosc":"cagade","lemma":"cagade","pos":"NOM"} ,
		{"word":"cagades","word_nosc":"cagades","lemma":"cagade","pos":"NOM"} ,
		{"word":"cage","word_nosc":"cage","lemma":"cage","pos":"NOM"} ,
		{"word":"cageot","word_nosc":"cageot","lemma":"cageot","pos":"NOM"} ,
		{"word":"cageots","word_nosc":"cageots","lemma":"cageot","pos":"NOM"} ,
		{"word":"cages","word_nosc":"cages","lemma":"cage","pos":"NOM"} ,
		{"word":"cagette","word_nosc":"cagette","lemma":"cagette","pos":"NOM"} ,
		{"word":"cagettes","word_nosc":"cagettes","lemma":"cagette","pos":"NOM"} ,
		{"word":"cagibi","word_nosc":"cagibi","lemma":"cagibi","pos":"NOM"} ,
		{"word":"cagibis","word_nosc":"cagibis","lemma":"cagibi","pos":"NOM"} ,
		{"word":"cagna","word_nosc":"cagna","lemma":"cagna","pos":"NOM"} ,
		{"word":"cagnas","word_nosc":"cagnas","lemma":"cagna","pos":"NOM"} ,
		{"word":"cagne","word_nosc":"cagne","lemma":"cagne","pos":"NOM"} ,
		{"word":"cagnotte","word_nosc":"cagnotte","lemma":"cagnotte","pos":"NOM"} ,
		{"word":"cagot","word_nosc":"cagot","lemma":"cagot","pos":"NOM"} ,
		{"word":"cagote","word_nosc":"cagote","lemma":"cagot","pos":"NOM"} ,
		{"word":"cagots","word_nosc":"cagots","lemma":"cagot","pos":"NOM"} ,
		{"word":"cagou","word_nosc":"cagou","lemma":"cagou","pos":"NOM"} ,
		{"word":"cagoulard","word_nosc":"cagoulard","lemma":"cagoulard","pos":"NOM"} ,
		{"word":"cagoulards","word_nosc":"cagoulards","lemma":"cagoulard","pos":"NOM"} ,
		{"word":"cagoule","word_nosc":"cagoule","lemma":"cagoule","pos":"NOM"} ,
		{"word":"cagoules","word_nosc":"cagoules","lemma":"cagoule","pos":"NOM"} ,
		{"word":"cahier","word_nosc":"cahier","lemma":"cahier","pos":"NOM"} ,
		{"word":"cahiers","word_nosc":"cahiers","lemma":"cahier","pos":"NOM"} ,
		{"word":"cahors","word_nosc":"cahors","lemma":"cahors","pos":"NOM"} ,
		{"word":"cahot","word_nosc":"cahot","lemma":"cahot","pos":"NOM"} ,
		{"word":"cahotement","word_nosc":"cahotement","lemma":"cahotement","pos":"NOM"} ,
		{"word":"cahots","word_nosc":"cahots","lemma":"cahot","pos":"NOM"} ,
		{"word":"cahute","word_nosc":"cahute","lemma":"cahute","pos":"NOM"} ,
		{"word":"cahutes","word_nosc":"cahutes","lemma":"cahute","pos":"NOM"} ,
		{"word":"caillasse","word_nosc":"caillasse","lemma":"caillasse","pos":"NOM"} ,
		{"word":"caillasses","word_nosc":"caillasses","lemma":"caillasse","pos":"NOM"} ,
		{"word":"caille","word_nosc":"caille","lemma":"caille","pos":"NOM"} ,
		{"word":"caillebotis","word_nosc":"caillebotis","lemma":"caillebotis","pos":"NOM"} ,
		{"word":"caillera","word_nosc":"caillera","lemma":"caillera","pos":"NOM"} ,
		{"word":"cailles","word_nosc":"cailles","lemma":"caille","pos":"NOM"} ,
		{"word":"caillette","word_nosc":"caillette","lemma":"caillette","pos":"NOM"} ,
		{"word":"caillot","word_nosc":"caillot","lemma":"caillot","pos":"NOM"} ,
		{"word":"caillots","word_nosc":"caillots","lemma":"caillot","pos":"NOM"} ,
		{"word":"caillou","word_nosc":"caillou","lemma":"caillou","pos":"NOM"} ,
		{"word":"cailloutage","word_nosc":"cailloutage","lemma":"cailloutage","pos":"NOM"} ,
		{"word":"cailloutis","word_nosc":"cailloutis","lemma":"cailloutis","pos":"NOM"} ,
		{"word":"cailloux","word_nosc":"cailloux","lemma":"caillou","pos":"NOM"} ,
		{"word":"caillé","word_nosc":"caille","lemma":"caillé","pos":"NOM"} ,
		{"word":"cairn","word_nosc":"cairn","lemma":"cairn","pos":"NOM"} ,
		{"word":"cairns","word_nosc":"cairns","lemma":"cairn","pos":"NOM"} ,
		{"word":"cairote","word_nosc":"cairote","lemma":"cairote","pos":"NOM"} ,
		{"word":"cairotes","word_nosc":"cairotes","lemma":"cairote","pos":"NOM"} ,
		{"word":"caisse","word_nosc":"caisse","lemma":"caisse","pos":"NOM"} ,
		{"word":"caisses","word_nosc":"caisses","lemma":"caisse","pos":"NOM"} ,
		{"word":"caissette","word_nosc":"caissette","lemma":"caissette","pos":"NOM"} ,
		{"word":"caissettes","word_nosc":"caissettes","lemma":"caissette","pos":"NOM"} ,
		{"word":"caissier","word_nosc":"caissier","lemma":"caissier","pos":"NOM"} ,
		{"word":"caissiers","word_nosc":"caissiers","lemma":"caissier","pos":"NOM"} ,
		{"word":"caissière","word_nosc":"caissiere","lemma":"caissier","pos":"NOM"} ,
		{"word":"caissières","word_nosc":"caissieres","lemma":"caissier","pos":"NOM"} ,
		{"word":"caisson","word_nosc":"caisson","lemma":"caisson","pos":"NOM"} ,
		{"word":"caissons","word_nosc":"caissons","lemma":"caisson","pos":"NOM"} ,
		{"word":"cajolerie","word_nosc":"cajolerie","lemma":"cajolerie","pos":"NOM"} ,
		{"word":"cajoleries","word_nosc":"cajoleries","lemma":"cajolerie","pos":"NOM"} ,
		{"word":"cajoleur","word_nosc":"cajoleur","lemma":"cajoleur","pos":"NOM"} ,
		{"word":"cajoleurs","word_nosc":"cajoleurs","lemma":"cajoleur","pos":"NOM"} ,
		{"word":"cajoleuse","word_nosc":"cajoleuse","lemma":"cajoleur","pos":"NOM"} ,
		{"word":"cajou","word_nosc":"cajou","lemma":"cajou","pos":"NOM"} ,
		{"word":"cajous","word_nosc":"cajous","lemma":"cajou","pos":"NOM"} ,
		{"word":"cajun","word_nosc":"cajun","lemma":"cajun","pos":"NOM"} ,
		{"word":"cajuns","word_nosc":"cajuns","lemma":"cajun","pos":"NOM"} ,
		{"word":"cake","word_nosc":"cake","lemma":"cake","pos":"NOM"} ,
		{"word":"cake-walk","word_nosc":"cake-walk","lemma":"cake-walk","pos":"NOM"} ,
		{"word":"cakes","word_nosc":"cakes","lemma":"cake","pos":"NOM"} ,
		{"word":"cal","word_nosc":"cal","lemma":"cal","pos":"NOM"} ,
		{"word":"calabrais","word_nosc":"calabrais","lemma":"calabrais","pos":"NOM"} ,
		{"word":"calade","word_nosc":"calade","lemma":"calade","pos":"NOM"} ,
		{"word":"calaisiens","word_nosc":"calaisiens","lemma":"calaisien","pos":"NOM"} ,
		{"word":"calaison","word_nosc":"calaison","lemma":"calaison","pos":"NOM"} ,
		{"word":"calamar","word_nosc":"calamar","lemma":"calamar","pos":"NOM"} ,
		{"word":"calamars","word_nosc":"calamars","lemma":"calamar","pos":"NOM"} ,
		{"word":"calame","word_nosc":"calame","lemma":"calame","pos":"NOM"} ,
		{"word":"calames","word_nosc":"calames","lemma":"calame","pos":"NOM"} ,
		{"word":"calamine","word_nosc":"calamine","lemma":"calamine","pos":"NOM"} ,
		{"word":"calamines","word_nosc":"calamines","lemma":"calamine","pos":"NOM"} ,
		{"word":"calamité","word_nosc":"calamite","lemma":"calamité","pos":"NOM"} ,
		{"word":"calamités","word_nosc":"calamites","lemma":"calamité","pos":"NOM"} ,
		{"word":"calandre","word_nosc":"calandre","lemma":"calandre","pos":"NOM"} ,
		{"word":"calanque","word_nosc":"calanque","lemma":"calanque","pos":"NOM"} ,
		{"word":"calanques","word_nosc":"calanques","lemma":"calanque","pos":"NOM"} ,
		{"word":"calao","word_nosc":"calao","lemma":"calao","pos":"NOM"} ,
		{"word":"calbar","word_nosc":"calbar","lemma":"calbar","pos":"NOM"} ,
		{"word":"calbars","word_nosc":"calbars","lemma":"calbar","pos":"NOM"} ,
		{"word":"calbombe","word_nosc":"calbombe","lemma":"calbombe","pos":"NOM"} ,
		{"word":"calbombes","word_nosc":"calbombes","lemma":"calbombe","pos":"NOM"} ,
		{"word":"calcaire","word_nosc":"calcaire","lemma":"calcaire","pos":"NOM"} ,
		{"word":"calcaires","word_nosc":"calcaires","lemma":"calcaire","pos":"NOM"} ,
		{"word":"calcif","word_nosc":"calcif","lemma":"calcif","pos":"NOM"} ,
		{"word":"calcification","word_nosc":"calcification","lemma":"calcification","pos":"NOM"} ,
		{"word":"calcifs","word_nosc":"calcifs","lemma":"calcif","pos":"NOM"} ,
		{"word":"calcination","word_nosc":"calcination","lemma":"calcination","pos":"NOM"} ,
		{"word":"calcite","word_nosc":"calcite","lemma":"calcite","pos":"NOM"} ,
		{"word":"calcium","word_nosc":"calcium","lemma":"calcium","pos":"NOM"} ,
		{"word":"calcul","word_nosc":"calcul","lemma":"calcul","pos":"NOM"} ,
		{"word":"calculateur","word_nosc":"calculateur","lemma":"calculateur","pos":"NOM"} ,
		{"word":"calculateurs","word_nosc":"calculateurs","lemma":"calculateur","pos":"NOM"} ,
		{"word":"calculatrice","word_nosc":"calculatrice","lemma":"calculatrice","pos":"NOM"} ,
		{"word":"calculatrices","word_nosc":"calculatrices","lemma":"calculatrice","pos":"NOM"} ,
		{"word":"calculette","word_nosc":"calculette","lemma":"calculette","pos":"NOM"} ,
		{"word":"calculettes","word_nosc":"calculettes","lemma":"calculette","pos":"NOM"} ,
		{"word":"calculs","word_nosc":"calculs","lemma":"calcul","pos":"NOM"} ,
		{"word":"calcémie","word_nosc":"calcemie","lemma":"calcémie","pos":"NOM"} ,
		{"word":"caldarium","word_nosc":"caldarium","lemma":"caldarium","pos":"NOM"} ,
		{"word":"caldeira","word_nosc":"caldeira","lemma":"caldeira","pos":"NOM"} ,
		{"word":"cale","word_nosc":"cale","lemma":"cale","pos":"NOM"} ,
		{"word":"cale-pied","word_nosc":"cale-pied","lemma":"cale-pied","pos":"NOM"} ,
		{"word":"cale-pieds","word_nosc":"cale-pieds","lemma":"cale-pied","pos":"NOM"} ,
		{"word":"calebar","word_nosc":"calebar","lemma":"calebar","pos":"NOM"} ,
		{"word":"calebasse","word_nosc":"calebasse","lemma":"calebasse","pos":"NOM"} ,
		{"word":"calebasses","word_nosc":"calebasses","lemma":"calebasse","pos":"NOM"} ,
		{"word":"calebassier","word_nosc":"calebassier","lemma":"calebassier","pos":"NOM"} ,
		{"word":"calebombe","word_nosc":"calebombe","lemma":"calebombe","pos":"NOM"} ,
		{"word":"calecif","word_nosc":"calecif","lemma":"calecif","pos":"NOM"} ,
		{"word":"calembour","word_nosc":"calembour","lemma":"calembour","pos":"NOM"} ,
		{"word":"calembours","word_nosc":"calembours","lemma":"calembour","pos":"NOM"} ,
		{"word":"calembredaine","word_nosc":"calembredaine","lemma":"calembredaine","pos":"NOM"} ,
		{"word":"calembredaines","word_nosc":"calembredaines","lemma":"calembredaine","pos":"NOM"} ,
		{"word":"calendes","word_nosc":"calendes","lemma":"calendes","pos":"NOM"} ,
		{"word":"calendo","word_nosc":"calendo","lemma":"calendo","pos":"NOM"} ,
		{"word":"calendos","word_nosc":"calendos","lemma":"calendos","pos":"NOM"} ,
		{"word":"calendre","word_nosc":"calendre","lemma":"calendre","pos":"NOM"} ,
		{"word":"calendrier","word_nosc":"calendrier","lemma":"calendrier","pos":"NOM"} ,
		{"word":"calendriers","word_nosc":"calendriers","lemma":"calendrier","pos":"NOM"} ,
		{"word":"calendula","word_nosc":"calendula","lemma":"calendula","pos":"NOM"} ,
		{"word":"calepin","word_nosc":"calepin","lemma":"calepin","pos":"NOM"} ,
		{"word":"calepins","word_nosc":"calepins","lemma":"calepin","pos":"NOM"} ,
		{"word":"cales","word_nosc":"cales","lemma":"cale","pos":"NOM"} ,
		{"word":"caleçon","word_nosc":"calecon","lemma":"caleçon","pos":"NOM"} ,
		{"word":"caleçonnade","word_nosc":"caleconnade","lemma":"caleçonnade","pos":"NOM"} ,
		{"word":"caleçons","word_nosc":"calecons","lemma":"caleçon","pos":"NOM"} ,
		{"word":"calf","word_nosc":"calf","lemma":"calf","pos":"NOM"} ,
		{"word":"calfat","word_nosc":"calfat","lemma":"calfat","pos":"NOM"} ,
		{"word":"calfatage","word_nosc":"calfatage","lemma":"calfatage","pos":"NOM"} ,
		{"word":"calfats","word_nosc":"calfats","lemma":"calfat","pos":"NOM"} ,
		{"word":"calfeutrage","word_nosc":"calfeutrage","lemma":"calfeutrage","pos":"NOM"} ,
		{"word":"calibrage","word_nosc":"calibrage","lemma":"calibrage","pos":"NOM"} ,
		{"word":"calibrages","word_nosc":"calibrages","lemma":"calibrage","pos":"NOM"} ,
		{"word":"calibration","word_nosc":"calibration","lemma":"calibration","pos":"NOM"} ,
		{"word":"calibre","word_nosc":"calibre","lemma":"calibre","pos":"NOM"} ,
		{"word":"calibres","word_nosc":"calibres","lemma":"calibre","pos":"NOM"} ,
		{"word":"calice","word_nosc":"calice","lemma":"calice","pos":"NOM"} ,
		{"word":"calices","word_nosc":"calices","lemma":"calice","pos":"NOM"} ,
		{"word":"calicot","word_nosc":"calicot","lemma":"calicot","pos":"NOM"} ,
		{"word":"calicots","word_nosc":"calicots","lemma":"calicot","pos":"NOM"} ,
		{"word":"califat","word_nosc":"califat","lemma":"califat","pos":"NOM"} ,
		{"word":"calife","word_nosc":"calife","lemma":"calife","pos":"NOM"} ,
		{"word":"califes","word_nosc":"califes","lemma":"calife","pos":"NOM"} ,
		{"word":"californie","word_nosc":"californie","lemma":"californie","pos":"NOM"} ,
		{"word":"californien","word_nosc":"californien","lemma":"californien","pos":"NOM"} ,
		{"word":"californienne","word_nosc":"californienne","lemma":"californien","pos":"NOM"} ,
		{"word":"californiens","word_nosc":"californiens","lemma":"californien","pos":"NOM"} ,
		{"word":"californium","word_nosc":"californium","lemma":"californium","pos":"NOM"} ,
		{"word":"calisson","word_nosc":"calisson","lemma":"calisson","pos":"NOM"} ,
		{"word":"calissons","word_nosc":"calissons","lemma":"calisson","pos":"NOM"} ,
		{"word":"call girl","word_nosc":"call girl","lemma":"call-girl","pos":"NOM"} ,
		{"word":"call girls","word_nosc":"call girls","lemma":"call-girl","pos":"NOM"} ,
		{"word":"call-girl","word_nosc":"call-girl","lemma":"call-girl","pos":"NOM"} ,
		{"word":"call-girls","word_nosc":"call-girls","lemma":"call-girl","pos":"NOM"} ,
		{"word":"calla","word_nosc":"calla","lemma":"calla","pos":"NOM"} ,
		{"word":"calligrammes","word_nosc":"calligrammes","lemma":"calligramme","pos":"NOM"} ,
		{"word":"calligraphe","word_nosc":"calligraphe","lemma":"calligraphe","pos":"NOM"} ,
		{"word":"calligraphie","word_nosc":"calligraphie","lemma":"calligraphie","pos":"NOM"} ,
		{"word":"calligraphies","word_nosc":"calligraphies","lemma":"calligraphie","pos":"NOM"} ,
		{"word":"callosité","word_nosc":"callosite","lemma":"callosité","pos":"NOM"} ,
		{"word":"callosités","word_nosc":"callosites","lemma":"callosité","pos":"NOM"} ,
		{"word":"calmant","word_nosc":"calmant","lemma":"calmant","pos":"NOM"} ,
		{"word":"calmants","word_nosc":"calmants","lemma":"calmant","pos":"NOM"} ,
		{"word":"calmar","word_nosc":"calmar","lemma":"calmar","pos":"NOM"} ,
		{"word":"calmars","word_nosc":"calmars","lemma":"calmar","pos":"NOM"} ,
		{"word":"calme","word_nosc":"calme","lemma":"calme","pos":"NOM"} ,
		{"word":"calmes","word_nosc":"calmes","lemma":"calme","pos":"NOM"} ,
		{"word":"calo","word_nosc":"calo","lemma":"calo","pos":"NOM"} ,
		{"word":"calomel","word_nosc":"calomel","lemma":"calomel","pos":"NOM"} ,
		{"word":"calomniateur","word_nosc":"calomniateur","lemma":"calomniateur","pos":"NOM"} ,
		{"word":"calomniateurs","word_nosc":"calomniateurs","lemma":"calomniateur","pos":"NOM"} ,
		{"word":"calomniatrice","word_nosc":"calomniatrice","lemma":"calomniateur","pos":"NOM"} ,
		{"word":"calomnie","word_nosc":"calomnie","lemma":"calomnie","pos":"NOM"} ,
		{"word":"calomnies","word_nosc":"calomnies","lemma":"calomnie","pos":"NOM"} ,
		{"word":"caloporteur","word_nosc":"caloporteur","lemma":"caloporteur","pos":"NOM"} ,
		{"word":"calorie","word_nosc":"calorie","lemma":"calorie","pos":"NOM"} ,
		{"word":"calories","word_nosc":"calories","lemma":"calorie","pos":"NOM"} ,
		{"word":"calorifuges","word_nosc":"calorifuges","lemma":"calorifuge","pos":"NOM"} ,
		{"word":"calorifère","word_nosc":"calorifere","lemma":"calorifère","pos":"NOM"} ,
		{"word":"calorifères","word_nosc":"caloriferes","lemma":"calorifère","pos":"NOM"} ,
		{"word":"calot","word_nosc":"calot","lemma":"calot","pos":"NOM"} ,
		{"word":"calotin","word_nosc":"calotin","lemma":"calotin","pos":"NOM"} ,
		{"word":"calotins","word_nosc":"calotins","lemma":"calotin","pos":"NOM"} ,
		{"word":"calots","word_nosc":"calots","lemma":"calot","pos":"NOM"} ,
		{"word":"calotte","word_nosc":"calotte","lemma":"calotte","pos":"NOM"} ,
		{"word":"calottes","word_nosc":"calottes","lemma":"calotte","pos":"NOM"} ,
		{"word":"calque","word_nosc":"calque","lemma":"calque","pos":"NOM"} ,
		{"word":"calques","word_nosc":"calques","lemma":"calque","pos":"NOM"} ,
		{"word":"cals","word_nosc":"cals","lemma":"cal","pos":"NOM"} ,
		{"word":"calumet","word_nosc":"calumet","lemma":"calumet","pos":"NOM"} ,
		{"word":"calumets","word_nosc":"calumets","lemma":"calumet","pos":"NOM"} ,
		{"word":"calus","word_nosc":"calus","lemma":"calus","pos":"NOM"} ,
		{"word":"calva","word_nosc":"calva","lemma":"calva","pos":"NOM"} ,
		{"word":"calvados","word_nosc":"calvados","lemma":"calvados","pos":"NOM"} ,
		{"word":"calvaire","word_nosc":"calvaire","lemma":"calvaire","pos":"NOM"} ,
		{"word":"calvaires","word_nosc":"calvaires","lemma":"calvaire","pos":"NOM"} ,
		{"word":"calvas","word_nosc":"calvas","lemma":"calva","pos":"NOM"} ,
		{"word":"calville","word_nosc":"calville","lemma":"calville","pos":"NOM"} ,
		{"word":"calvilles","word_nosc":"calvilles","lemma":"calville","pos":"NOM"} ,
		{"word":"calvinisme","word_nosc":"calvinisme","lemma":"calvinisme","pos":"NOM"} ,
		{"word":"calviniste","word_nosc":"calviniste","lemma":"calviniste","pos":"NOM"} ,
		{"word":"calvinistes","word_nosc":"calvinistes","lemma":"calviniste","pos":"NOM"} ,
		{"word":"calvitie","word_nosc":"calvitie","lemma":"calvitie","pos":"NOM"} ,
		{"word":"calvities","word_nosc":"calvities","lemma":"calvitie","pos":"NOM"} ,
		{"word":"calypso","word_nosc":"calypso","lemma":"calypso","pos":"NOM"} ,
		{"word":"calèche","word_nosc":"caleche","lemma":"calèche","pos":"NOM"} ,
		{"word":"calèches","word_nosc":"caleches","lemma":"calèche","pos":"NOM"} ,
		{"word":"calédoniens","word_nosc":"caledoniens","lemma":"calédonien","pos":"NOM"} ,
		{"word":"caléidoscope","word_nosc":"caleidoscope","lemma":"caléidoscope","pos":"NOM"} ,
		{"word":"camail","word_nosc":"camail","lemma":"camail","pos":"NOM"} ,
		{"word":"camails","word_nosc":"camails","lemma":"camail","pos":"NOM"} ,
		{"word":"camarade","word_nosc":"camarade","lemma":"camarade","pos":"NOM"} ,
		{"word":"camaraderie","word_nosc":"camaraderie","lemma":"camaraderie","pos":"NOM"} ,
		{"word":"camaraderies","word_nosc":"camaraderies","lemma":"camaraderie","pos":"NOM"} ,
		{"word":"camarades","word_nosc":"camarades","lemma":"camarade","pos":"NOM"} ,
		{"word":"camarde","word_nosc":"camarde","lemma":"camard","pos":"NOM"} ,
		{"word":"camarero","word_nosc":"camarero","lemma":"camarero","pos":"NOM"} ,
		{"word":"camareros","word_nosc":"camareros","lemma":"camarero","pos":"NOM"} ,
		{"word":"camarguaises","word_nosc":"camarguaises","lemma":"camarguais","pos":"NOM"} ,
		{"word":"camarilla","word_nosc":"camarilla","lemma":"camarilla","pos":"NOM"} ,
		{"word":"camarin","word_nosc":"camarin","lemma":"camarin","pos":"NOM"} ,
		{"word":"camarins","word_nosc":"camarins","lemma":"camarin","pos":"NOM"} ,
		{"word":"camarluche","word_nosc":"camarluche","lemma":"camarluche","pos":"NOM"} ,
		{"word":"camaro","word_nosc":"camaro","lemma":"camaro","pos":"NOM"} ,
		{"word":"camaïeu","word_nosc":"camaieu","lemma":"camaïeu","pos":"NOM"} ,
		{"word":"camaïeux","word_nosc":"camaieux","lemma":"camaïeux","pos":"NOM"} ,
		{"word":"cambiste","word_nosc":"cambiste","lemma":"cambiste","pos":"NOM"} ,
		{"word":"cambodgien","word_nosc":"cambodgien","lemma":"cambodgien","pos":"NOM"} ,
		{"word":"cambodgienne","word_nosc":"cambodgienne","lemma":"cambodgien","pos":"NOM"} ,
		{"word":"cambodgiennes","word_nosc":"cambodgiennes","lemma":"cambodgien","pos":"NOM"} ,
		{"word":"cambodgiens","word_nosc":"cambodgiens","lemma":"cambodgien","pos":"NOM"} ,
		{"word":"cambouis","word_nosc":"cambouis","lemma":"cambouis","pos":"NOM"} ,
		{"word":"cambrement","word_nosc":"cambrement","lemma":"cambrement","pos":"NOM"} ,
		{"word":"cambriolage","word_nosc":"cambriolage","lemma":"cambriolage","pos":"NOM"} ,
		{"word":"cambriolages","word_nosc":"cambriolages","lemma":"cambriolage","pos":"NOM"} ,
		{"word":"cambrioleur","word_nosc":"cambrioleur","lemma":"cambrioleur","pos":"NOM"} ,
		{"word":"cambrioleurs","word_nosc":"cambrioleurs","lemma":"cambrioleur","pos":"NOM"} ,
		{"word":"cambrioleuse","word_nosc":"cambrioleuse","lemma":"cambrioleur","pos":"NOM"} ,
		{"word":"cambrousse","word_nosc":"cambrousse","lemma":"cambrousse","pos":"NOM"} ,
		{"word":"cambrousses","word_nosc":"cambrousses","lemma":"cambrousse","pos":"NOM"} ,
		{"word":"cambrure","word_nosc":"cambrure","lemma":"cambrure","pos":"NOM"} ,
		{"word":"cambrures","word_nosc":"cambrures","lemma":"cambrure","pos":"NOM"} ,
		{"word":"cambuse","word_nosc":"cambuse","lemma":"cambuse","pos":"NOM"} ,
		{"word":"cambuses","word_nosc":"cambuses","lemma":"cambuse","pos":"NOM"} ,
		{"word":"cambusier","word_nosc":"cambusier","lemma":"cambusier","pos":"NOM"} ,
		{"word":"came","word_nosc":"came","lemma":"came","pos":"NOM"} ,
		{"word":"camellia","word_nosc":"camellia","lemma":"camellia","pos":"NOM"} ,
		{"word":"camelot","word_nosc":"camelot","lemma":"camelot","pos":"NOM"} ,
		{"word":"camelote","word_nosc":"camelote","lemma":"camelote","pos":"NOM"} ,
		{"word":"camelotes","word_nosc":"camelotes","lemma":"camelote","pos":"NOM"} ,
		{"word":"camelots","word_nosc":"camelots","lemma":"camelot","pos":"NOM"} ,
		{"word":"camembert","word_nosc":"camembert","lemma":"camembert","pos":"NOM"} ,
		{"word":"camemberts","word_nosc":"camemberts","lemma":"camembert","pos":"NOM"} ,
		{"word":"cameraman","word_nosc":"cameraman","lemma":"cameraman","pos":"NOM"} ,
		{"word":"cameramen","word_nosc":"cameramen","lemma":"cameraman","pos":"NOM"} ,
		{"word":"camerounais","word_nosc":"camerounais","lemma":"camerounais","pos":"NOM"} ,
		{"word":"cames","word_nosc":"cames","lemma":"came","pos":"NOM"} ,
		{"word":"camion","word_nosc":"camion","lemma":"camion","pos":"NOM"} ,
		{"word":"camion-benne","word_nosc":"camion-benne","lemma":"camion-benne","pos":"NOM"} ,
		{"word":"camion-citerne","word_nosc":"camion-citerne","lemma":"camion-citerne","pos":"NOM"} ,
		{"word":"camion-grue","word_nosc":"camion-grue","lemma":"camion-grue","pos":"NOM"} ,
		{"word":"camionnage","word_nosc":"camionnage","lemma":"camionnage","pos":"NOM"} ,
		{"word":"camionnette","word_nosc":"camionnette","lemma":"camionnette","pos":"NOM"} ,
		{"word":"camionnettes","word_nosc":"camionnettes","lemma":"camionnette","pos":"NOM"} ,
		{"word":"camionneur","word_nosc":"camionneur","lemma":"camionneur","pos":"NOM"} ,
		{"word":"camionneurs","word_nosc":"camionneurs","lemma":"camionneur","pos":"NOM"} ,
		{"word":"camions","word_nosc":"camions","lemma":"camion","pos":"NOM"} ,
		{"word":"camions-bennes","word_nosc":"camions-bennes","lemma":"camion-benne","pos":"NOM"} ,
		{"word":"camions-citernes","word_nosc":"camions-citernes","lemma":"camion-citerne","pos":"NOM"} ,
		{"word":"camisards","word_nosc":"camisards","lemma":"camisard","pos":"NOM"} ,
		{"word":"camisole","word_nosc":"camisole","lemma":"camisole","pos":"NOM"} ,
		{"word":"camisoles","word_nosc":"camisoles","lemma":"camisole","pos":"NOM"} ,
		{"word":"camomille","word_nosc":"camomille","lemma":"camomille","pos":"NOM"} ,
		{"word":"camomilles","word_nosc":"camomilles","lemma":"camomille","pos":"NOM"} ,
		{"word":"camorra","word_nosc":"camorra","lemma":"camorra","pos":"NOM"} ,
		{"word":"camouflage","word_nosc":"camouflage","lemma":"camouflage","pos":"NOM"} ,
		{"word":"camouflages","word_nosc":"camouflages","lemma":"camouflage","pos":"NOM"} ,
		{"word":"camouflet","word_nosc":"camouflet","lemma":"camouflet","pos":"NOM"} ,
		{"word":"camouflets","word_nosc":"camouflets","lemma":"camouflet","pos":"NOM"} ,
		{"word":"camp","word_nosc":"camp","lemma":"camp","pos":"NOM"} ,
		{"word":"campagnard","word_nosc":"campagnard","lemma":"campagnard","pos":"NOM"} ,
		{"word":"campagnards","word_nosc":"campagnards","lemma":"campagnard","pos":"NOM"} ,
		{"word":"campagne","word_nosc":"campagne","lemma":"campagne","pos":"NOM"} ,
		{"word":"campagnes","word_nosc":"campagnes","lemma":"campagne","pos":"NOM"} ,
		{"word":"campagnol","word_nosc":"campagnol","lemma":"campagnol","pos":"NOM"} ,
		{"word":"campagnols","word_nosc":"campagnols","lemma":"campagnol","pos":"NOM"} ,
		{"word":"campanelles","word_nosc":"campanelles","lemma":"campanelle","pos":"NOM"} ,
		{"word":"campanile","word_nosc":"campanile","lemma":"campanile","pos":"NOM"} ,
		{"word":"campaniles","word_nosc":"campaniles","lemma":"campanile","pos":"NOM"} ,
		{"word":"campanule","word_nosc":"campanule","lemma":"campanule","pos":"NOM"} ,
		{"word":"campanules","word_nosc":"campanules","lemma":"campanule","pos":"NOM"} ,
		{"word":"campement","word_nosc":"campement","lemma":"campement","pos":"NOM"} ,
		{"word":"campements","word_nosc":"campements","lemma":"campement","pos":"NOM"} ,
		{"word":"campeur","word_nosc":"campeur","lemma":"campeur","pos":"NOM"} ,
		{"word":"campeurs","word_nosc":"campeurs","lemma":"campeur","pos":"NOM"} ,
		{"word":"campeuse","word_nosc":"campeuse","lemma":"campeur","pos":"NOM"} ,
		{"word":"campeuses","word_nosc":"campeuses","lemma":"campeur","pos":"NOM"} ,
		{"word":"camphre","word_nosc":"camphre","lemma":"camphre","pos":"NOM"} ,
		{"word":"camphrier","word_nosc":"camphrier","lemma":"camphrier","pos":"NOM"} ,
		{"word":"camping","word_nosc":"camping","lemma":"camping","pos":"NOM"} ,
		{"word":"camping-car","word_nosc":"camping-car","lemma":"camping-car","pos":"NOM"} ,
		{"word":"camping-cars","word_nosc":"camping-cars","lemma":"camping-car","pos":"NOM"} ,
		{"word":"camping-gaz","word_nosc":"camping-gaz","lemma":"camping-gaz","pos":"NOM"} ,
		{"word":"campings","word_nosc":"campings","lemma":"camping","pos":"NOM"} ,
		{"word":"campo","word_nosc":"campo","lemma":"campo","pos":"NOM"} ,
		{"word":"campos","word_nosc":"campos","lemma":"campo","pos":"NOM"} ,
		{"word":"camps","word_nosc":"camps","lemma":"camp","pos":"NOM"} ,
		{"word":"campus","word_nosc":"campus","lemma":"campus","pos":"NOM"} ,
		{"word":"campêche","word_nosc":"campeche","lemma":"campêche","pos":"NOM"} ,
		{"word":"camus","word_nosc":"camus","lemma":"camus","pos":"NOM"} ,
		{"word":"camé","word_nosc":"came","lemma":"camé","pos":"NOM"} ,
		{"word":"camée","word_nosc":"camee","lemma":"camée","pos":"NOM"} ,
		{"word":"camées","word_nosc":"camees","lemma":"camée","pos":"NOM"} ,
		{"word":"camélia","word_nosc":"camelia","lemma":"camélia","pos":"NOM"} ,
		{"word":"camélias","word_nosc":"camelias","lemma":"camélia","pos":"NOM"} ,
		{"word":"camélidé","word_nosc":"camelide","lemma":"camélidé","pos":"NOM"} ,
		{"word":"camélidés","word_nosc":"camelides","lemma":"camélidé","pos":"NOM"} ,
		{"word":"caméléon","word_nosc":"cameleon","lemma":"caméléon","pos":"NOM"} ,
		{"word":"caméléons","word_nosc":"cameleons","lemma":"caméléon","pos":"NOM"} ,
		{"word":"caméra","word_nosc":"camera","lemma":"caméra","pos":"NOM"} ,
		{"word":"caméra-espion","word_nosc":"camera-espion","lemma":"caméra-espion","pos":"NOM"} ,
		{"word":"caméraman","word_nosc":"cameraman","lemma":"caméraman","pos":"NOM"} ,
		{"word":"caméramans","word_nosc":"cameramans","lemma":"caméraman","pos":"NOM"} ,
		{"word":"caméras","word_nosc":"cameras","lemma":"caméra","pos":"NOM"} ,
		{"word":"camériste","word_nosc":"cameriste","lemma":"camériste","pos":"NOM"} ,
		{"word":"caméristes","word_nosc":"cameristes","lemma":"camériste","pos":"NOM"} ,
		{"word":"camés","word_nosc":"cames","lemma":"camé","pos":"NOM"} ,
		{"word":"caméscope","word_nosc":"camescope","lemma":"caméscope","pos":"NOM"} ,
		{"word":"caméscopes","word_nosc":"camescopes","lemma":"caméscope","pos":"NOM"} ,
		{"word":"canada","word_nosc":"canada","lemma":"canada","pos":"NOM"} ,
		{"word":"canadair","word_nosc":"canadair","lemma":"canadair","pos":"NOM"} ,
		{"word":"canadairs","word_nosc":"canadairs","lemma":"canadair","pos":"NOM"} ,
		{"word":"canadian river","word_nosc":"canadian river","lemma":"canadian river","pos":"NOM"} ,
		{"word":"canadien","word_nosc":"canadien","lemma":"canadien","pos":"NOM"} ,
		{"word":"canadienne","word_nosc":"canadienne","lemma":"canadienne","pos":"NOM"} ,
		{"word":"canadiennes","word_nosc":"canadiennes","lemma":"canadienne","pos":"NOM"} ,
		{"word":"canadiens","word_nosc":"canadiens","lemma":"canadien","pos":"NOM"} ,
		{"word":"canaille","word_nosc":"canaille","lemma":"canaille","pos":"NOM"} ,
		{"word":"canaillerie","word_nosc":"canaillerie","lemma":"canaillerie","pos":"NOM"} ,
		{"word":"canailles","word_nosc":"canailles","lemma":"canaille","pos":"NOM"} ,
		{"word":"canal","word_nosc":"canal","lemma":"canal","pos":"NOM"} ,
		{"word":"canalicules","word_nosc":"canalicules","lemma":"canalicule","pos":"NOM"} ,
		{"word":"canalisateur","word_nosc":"canalisateur","lemma":"canalisateur","pos":"NOM"} ,
		{"word":"canalisation","word_nosc":"canalisation","lemma":"canalisation","pos":"NOM"} ,
		{"word":"canalisations","word_nosc":"canalisations","lemma":"canalisation","pos":"NOM"} ,
		{"word":"canapé","word_nosc":"canape","lemma":"canapé","pos":"NOM"} ,
		{"word":"canapé-lit","word_nosc":"canape-lit","lemma":"canapé-lit","pos":"NOM"} ,
		{"word":"canapés","word_nosc":"canapes","lemma":"canapé","pos":"NOM"} ,
		{"word":"canapés-lits","word_nosc":"canapes-lits","lemma":"canapé-lit","pos":"NOM"} ,
		{"word":"canaques","word_nosc":"canaques","lemma":"canaque","pos":"NOM"} ,
		{"word":"canard","word_nosc":"canard","lemma":"canard","pos":"NOM"} ,
		{"word":"canardeaux","word_nosc":"canardeaux","lemma":"canardeau","pos":"NOM"} ,
		{"word":"canards","word_nosc":"canards","lemma":"canard","pos":"NOM"} ,
		{"word":"canari","word_nosc":"canari","lemma":"canari","pos":"NOM"} ,
		{"word":"canaris","word_nosc":"canaris","lemma":"canari","pos":"NOM"} ,
		{"word":"canasson","word_nosc":"canasson","lemma":"canasson","pos":"NOM"} ,
		{"word":"canassons","word_nosc":"canassons","lemma":"canasson","pos":"NOM"} ,
		{"word":"canasta","word_nosc":"canasta","lemma":"canasta","pos":"NOM"} ,
		{"word":"canaux","word_nosc":"canaux","lemma":"canal","pos":"NOM"} ,
		{"word":"cancan","word_nosc":"cancan","lemma":"cancan","pos":"NOM"} ,
		{"word":"cancaneries","word_nosc":"cancaneries","lemma":"cancanerie","pos":"NOM"} ,
		{"word":"cancanier","word_nosc":"cancanier","lemma":"cancanier","pos":"NOM"} ,
		{"word":"cancanières","word_nosc":"cancanieres","lemma":"cancanier","pos":"NOM"} ,
		{"word":"cancans","word_nosc":"cancans","lemma":"cancan","pos":"NOM"} ,
		{"word":"cancel","word_nosc":"cancel","lemma":"cancel","pos":"NOM"} ,
		{"word":"cancer","word_nosc":"cancer","lemma":"cancer","pos":"NOM"} ,
		{"word":"cancers","word_nosc":"cancers","lemma":"cancer","pos":"NOM"} ,
		{"word":"canche","word_nosc":"canche","lemma":"canche","pos":"NOM"} ,
		{"word":"cancoillotte","word_nosc":"cancoillotte","lemma":"cancoillotte","pos":"NOM"} ,
		{"word":"cancre","word_nosc":"cancre","lemma":"cancre","pos":"NOM"} ,
		{"word":"cancrelat","word_nosc":"cancrelat","lemma":"cancrelat","pos":"NOM"} ,
		{"word":"cancrelats","word_nosc":"cancrelats","lemma":"cancrelat","pos":"NOM"} ,
		{"word":"cancres","word_nosc":"cancres","lemma":"cancre","pos":"NOM"} ,
		{"word":"cancéreuse","word_nosc":"cancereuse","lemma":"cancéreux","pos":"NOM"} ,
		{"word":"cancéreuses","word_nosc":"cancereuses","lemma":"cancéreux","pos":"NOM"} ,
		{"word":"cancéreux","word_nosc":"cancereux","lemma":"cancéreux","pos":"NOM"} ,
		{"word":"cancérigènes","word_nosc":"cancerigenes","lemma":"cancérigène","pos":"NOM"} ,
		{"word":"cancérogène","word_nosc":"cancerogene","lemma":"cancérogène","pos":"NOM"} ,
		{"word":"cancérologie","word_nosc":"cancerologie","lemma":"cancérologie","pos":"NOM"} ,
		{"word":"cancérologue","word_nosc":"cancerologue","lemma":"cancérologue","pos":"NOM"} ,
		{"word":"cancérologues","word_nosc":"cancerologues","lemma":"cancérologue","pos":"NOM"} ,
		{"word":"candeur","word_nosc":"candeur","lemma":"candeur","pos":"NOM"} ,
		{"word":"candeurs","word_nosc":"candeurs","lemma":"candeur","pos":"NOM"} ,
		{"word":"candida","word_nosc":"candida","lemma":"candida","pos":"NOM"} ,
		{"word":"candidat","word_nosc":"candidat","lemma":"candidat","pos":"NOM"} ,
		{"word":"candidate","word_nosc":"candidate","lemma":"candidat","pos":"NOM"} ,
		{"word":"candidates","word_nosc":"candidates","lemma":"candidat","pos":"NOM"} ,
		{"word":"candidats","word_nosc":"candidats","lemma":"candidat","pos":"NOM"} ,
		{"word":"candidature","word_nosc":"candidature","lemma":"candidature","pos":"NOM"} ,
		{"word":"candidatures","word_nosc":"candidatures","lemma":"candidature","pos":"NOM"} ,
		{"word":"candidose","word_nosc":"candidose","lemma":"candidose","pos":"NOM"} ,
		{"word":"candélabre","word_nosc":"candelabre","lemma":"candélabre","pos":"NOM"} ,
		{"word":"candélabres","word_nosc":"candelabres","lemma":"candélabre","pos":"NOM"} ,
		{"word":"cane","word_nosc":"cane","lemma":"canard","pos":"NOM"} ,
		{"word":"canebière","word_nosc":"canebiere","lemma":"canebière","pos":"NOM"} ,
		{"word":"canepetière","word_nosc":"canepetiere","lemma":"canepetière","pos":"NOM"} ,
		{"word":"canes","word_nosc":"canes","lemma":"canard","pos":"NOM"} ,
		{"word":"canetille","word_nosc":"canetille","lemma":"canetille","pos":"NOM"} ,
		{"word":"caneton","word_nosc":"caneton","lemma":"caneton","pos":"NOM"} ,
		{"word":"canetons","word_nosc":"canetons","lemma":"caneton","pos":"NOM"} ,
		{"word":"canette","word_nosc":"canette","lemma":"canette","pos":"NOM"} ,
		{"word":"canettes","word_nosc":"canettes","lemma":"canette","pos":"NOM"} ,
		{"word":"canevas","word_nosc":"canevas","lemma":"canevas","pos":"NOM"} ,
		{"word":"canfouine","word_nosc":"canfouine","lemma":"canfouine","pos":"NOM"} ,
		{"word":"cangue","word_nosc":"cangue","lemma":"cangue","pos":"NOM"} ,
		{"word":"caniche","word_nosc":"caniche","lemma":"caniche","pos":"NOM"} ,
		{"word":"caniches","word_nosc":"caniches","lemma":"caniche","pos":"NOM"} ,
		{"word":"canicule","word_nosc":"canicule","lemma":"canicule","pos":"NOM"} ,
		{"word":"canicules","word_nosc":"canicules","lemma":"canicule","pos":"NOM"} ,
		{"word":"canidés","word_nosc":"canides","lemma":"canidé","pos":"NOM"} ,
		{"word":"canif","word_nosc":"canif","lemma":"canif","pos":"NOM"} ,
		{"word":"canifs","word_nosc":"canifs","lemma":"canif","pos":"NOM"} ,
		{"word":"canine","word_nosc":"canine","lemma":"canine","pos":"NOM"} ,
		{"word":"canines","word_nosc":"canines","lemma":"canine","pos":"NOM"} ,
		{"word":"caninette","word_nosc":"caninette","lemma":"caninette","pos":"NOM"} ,
		{"word":"canisses","word_nosc":"canisses","lemma":"canisse","pos":"NOM"} ,
		{"word":"caniveau","word_nosc":"caniveau","lemma":"caniveau","pos":"NOM"} ,
		{"word":"caniveaux","word_nosc":"caniveaux","lemma":"caniveau","pos":"NOM"} ,
		{"word":"canna","word_nosc":"canna","lemma":"canna","pos":"NOM"} ,
		{"word":"cannabis","word_nosc":"cannabis","lemma":"cannabis","pos":"NOM"} ,
		{"word":"cannage","word_nosc":"cannage","lemma":"cannage","pos":"NOM"} ,
		{"word":"cannas","word_nosc":"cannas","lemma":"canna","pos":"NOM"} ,
		{"word":"canne","word_nosc":"canne","lemma":"canne","pos":"NOM"} ,
		{"word":"canne-épée","word_nosc":"canne-epee","lemma":"canne-épée","pos":"NOM"} ,
		{"word":"canneberge","word_nosc":"canneberge","lemma":"canneberge","pos":"NOM"} ,
		{"word":"canneberges","word_nosc":"canneberges","lemma":"canneberge","pos":"NOM"} ,
		{"word":"canneliers","word_nosc":"canneliers","lemma":"cannelier","pos":"NOM"} ,
		{"word":"cannelle","word_nosc":"cannelle","lemma":"cannelle","pos":"NOM"} ,
		{"word":"cannelloni","word_nosc":"cannelloni","lemma":"cannelloni","pos":"NOM"} ,
		{"word":"cannellonis","word_nosc":"cannellonis","lemma":"cannelloni","pos":"NOM"} ,
		{"word":"cannelure","word_nosc":"cannelure","lemma":"cannelure","pos":"NOM"} ,
		{"word":"cannelures","word_nosc":"cannelures","lemma":"cannelure","pos":"NOM"} ,
		{"word":"cannes","word_nosc":"cannes","lemma":"canne","pos":"NOM"} ,
		{"word":"cannes-épées","word_nosc":"cannes-epees","lemma":"canne-épée","pos":"NOM"} ,
		{"word":"cannette","word_nosc":"cannette","lemma":"cannette","pos":"NOM"} ,
		{"word":"cannettes","word_nosc":"cannettes","lemma":"cannette","pos":"NOM"} ,
		{"word":"cannibale","word_nosc":"cannibale","lemma":"cannibale","pos":"NOM"} ,
		{"word":"cannibales","word_nosc":"cannibales","lemma":"cannibale","pos":"NOM"} ,
		{"word":"cannibalisme","word_nosc":"cannibalisme","lemma":"cannibalisme","pos":"NOM"} ,
		{"word":"canon","word_nosc":"canon","lemma":"canon","pos":"NOM"} ,
		{"word":"canonisation","word_nosc":"canonisation","lemma":"canonisation","pos":"NOM"} ,
		{"word":"canonisations","word_nosc":"canonisations","lemma":"canonisation","pos":"NOM"} ,
		{"word":"canonnade","word_nosc":"canonnade","lemma":"canonnade","pos":"NOM"} ,
		{"word":"canonnades","word_nosc":"canonnades","lemma":"canonnade","pos":"NOM"} ,
		{"word":"canonnage","word_nosc":"canonnage","lemma":"canonnage","pos":"NOM"} ,
		{"word":"canonnier","word_nosc":"canonnier","lemma":"canonnier","pos":"NOM"} ,
		{"word":"canonniers","word_nosc":"canonniers","lemma":"canonnier","pos":"NOM"} ,
		{"word":"canonnière","word_nosc":"canonniere","lemma":"canonnière","pos":"NOM"} ,
		{"word":"canonnières","word_nosc":"canonnieres","lemma":"canonnière","pos":"NOM"} ,
		{"word":"canons","word_nosc":"canons","lemma":"canon","pos":"NOM"} ,
		{"word":"canope","word_nosc":"canope","lemma":"canope","pos":"NOM"} ,
		{"word":"canopes","word_nosc":"canopes","lemma":"canope","pos":"NOM"} ,
		{"word":"canot","word_nosc":"canot","lemma":"canot","pos":"NOM"} ,
		{"word":"canotage","word_nosc":"canotage","lemma":"canotage","pos":"NOM"} ,
		{"word":"canoteurs","word_nosc":"canoteurs","lemma":"canoteur","pos":"NOM"} ,
		{"word":"canotier","word_nosc":"canotier","lemma":"canotier","pos":"NOM"} ,
		{"word":"canotiers","word_nosc":"canotiers","lemma":"canotier","pos":"NOM"} ,
		{"word":"canots","word_nosc":"canots","lemma":"canot","pos":"NOM"} ,
		{"word":"canoë","word_nosc":"canoe","lemma":"canoë","pos":"NOM"} ,
		{"word":"canoës","word_nosc":"canoes","lemma":"canoë","pos":"NOM"} ,
		{"word":"cantabile","word_nosc":"cantabile","lemma":"cantabile","pos":"NOM"} ,
		{"word":"cantal","word_nosc":"cantal","lemma":"cantal","pos":"NOM"} ,
		{"word":"cantaloup","word_nosc":"cantaloup","lemma":"cantaloup","pos":"NOM"} ,
		{"word":"cantaloups","word_nosc":"cantaloups","lemma":"cantaloup","pos":"NOM"} ,
		{"word":"cantate","word_nosc":"cantate","lemma":"cantate","pos":"NOM"} ,
		{"word":"cantates","word_nosc":"cantates","lemma":"cantate","pos":"NOM"} ,
		{"word":"cantatrice","word_nosc":"cantatrice","lemma":"cantatrice","pos":"NOM"} ,
		{"word":"cantatrices","word_nosc":"cantatrices","lemma":"cantatrice","pos":"NOM"} ,
		{"word":"canter","word_nosc":"canter","lemma":"canter","pos":"NOM"} ,
		{"word":"canthare","word_nosc":"canthare","lemma":"canthare","pos":"NOM"} ,
		{"word":"canthares","word_nosc":"canthares","lemma":"canthare","pos":"NOM"} ,
		{"word":"cantharide","word_nosc":"cantharide","lemma":"cantharide","pos":"NOM"} ,
		{"word":"cantharides","word_nosc":"cantharides","lemma":"cantharide","pos":"NOM"} ,
		{"word":"cantilever","word_nosc":"cantilever","lemma":"cantilever","pos":"NOM"} ,
		{"word":"cantilène","word_nosc":"cantilene","lemma":"cantilène","pos":"NOM"} ,
		{"word":"cantine","word_nosc":"cantine","lemma":"cantine","pos":"NOM"} ,
		{"word":"cantines","word_nosc":"cantines","lemma":"cantine","pos":"NOM"} ,
		{"word":"cantinier","word_nosc":"cantinier","lemma":"cantinier","pos":"NOM"} ,
		{"word":"cantiniers","word_nosc":"cantiniers","lemma":"cantinier","pos":"NOM"} ,
		{"word":"cantinière","word_nosc":"cantiniere","lemma":"cantinier","pos":"NOM"} ,
		{"word":"cantinières","word_nosc":"cantinieres","lemma":"cantinier","pos":"NOM"} ,
		{"word":"cantique","word_nosc":"cantique","lemma":"cantique","pos":"NOM"} ,
		{"word":"cantiques","word_nosc":"cantiques","lemma":"cantique","pos":"NOM"} ,
		{"word":"canton","word_nosc":"canton","lemma":"canton","pos":"NOM"} ,
		{"word":"cantonade","word_nosc":"cantonade","lemma":"cantonade","pos":"NOM"} ,
		{"word":"cantonais","word_nosc":"cantonais","lemma":"cantonais","pos":"NOM"} ,
		{"word":"cantonnement","word_nosc":"cantonnement","lemma":"cantonnement","pos":"NOM"} ,
		{"word":"cantonnements","word_nosc":"cantonnements","lemma":"cantonnement","pos":"NOM"} ,
		{"word":"cantonnier","word_nosc":"cantonnier","lemma":"cantonnier","pos":"NOM"} ,
		{"word":"cantonniers","word_nosc":"cantonniers","lemma":"cantonnier","pos":"NOM"} ,
		{"word":"cantonnières","word_nosc":"cantonnieres","lemma":"cantonnier","pos":"NOM"} ,
		{"word":"cantons","word_nosc":"cantons","lemma":"canton","pos":"NOM"} ,
		{"word":"cantre","word_nosc":"cantre","lemma":"cantre","pos":"NOM"} ,
		{"word":"canular","word_nosc":"canular","lemma":"canular","pos":"NOM"} ,
		{"word":"canulars","word_nosc":"canulars","lemma":"canular","pos":"NOM"} ,
		{"word":"canule","word_nosc":"canule","lemma":"canule","pos":"NOM"} ,
		{"word":"canut","word_nosc":"canut","lemma":"canut","pos":"NOM"} ,
		{"word":"canuts","word_nosc":"canuts","lemma":"canut","pos":"NOM"} ,
		{"word":"canyon","word_nosc":"canyon","lemma":"canyon","pos":"NOM"} ,
		{"word":"canyons","word_nosc":"canyons","lemma":"canyon","pos":"NOM"} ,
		{"word":"canzonettes","word_nosc":"canzonettes","lemma":"canzonette","pos":"NOM"} ,
		{"word":"caoua","word_nosc":"caoua","lemma":"caoua","pos":"NOM"} ,
		{"word":"caouas","word_nosc":"caouas","lemma":"caoua","pos":"NOM"} ,
		{"word":"caoutchouc","word_nosc":"caoutchouc","lemma":"caoutchouc","pos":"NOM"} ,
		{"word":"caoutchoucs","word_nosc":"caoutchoucs","lemma":"caoutchouc","pos":"NOM"} ,
		{"word":"cap","word_nosc":"cap","lemma":"cap","pos":"NOM"} ,
		{"word":"cap-hornier","word_nosc":"cap-hornier","lemma":"cap-hornier","pos":"NOM"} ,
		{"word":"capacité","word_nosc":"capacite","lemma":"capacité","pos":"NOM"} ,
		{"word":"capacités","word_nosc":"capacites","lemma":"capacité","pos":"NOM"} ,
		{"word":"capades","word_nosc":"capades","lemma":"capade","pos":"NOM"} ,
		{"word":"caparaçon","word_nosc":"caparacon","lemma":"caparaçon","pos":"NOM"} ,
		{"word":"caparaçons","word_nosc":"caparacons","lemma":"caparaçon","pos":"NOM"} ,
		{"word":"cape","word_nosc":"cape","lemma":"cape","pos":"NOM"} ,
		{"word":"capelage","word_nosc":"capelage","lemma":"capelage","pos":"NOM"} ,
		{"word":"capeline","word_nosc":"capeline","lemma":"capeline","pos":"NOM"} ,
		{"word":"capelines","word_nosc":"capelines","lemma":"capeline","pos":"NOM"} ,
		{"word":"capes","word_nosc":"capes","lemma":"cape","pos":"NOM"} ,
		{"word":"capharnaüm","word_nosc":"capharnaum","lemma":"capharnaüm","pos":"NOM"} ,
		{"word":"capillaire","word_nosc":"capillaire","lemma":"capillaire","pos":"NOM"} ,
		{"word":"capillaires","word_nosc":"capillaires","lemma":"capillaire","pos":"NOM"} ,
		{"word":"capillarité","word_nosc":"capillarite","lemma":"capillarité","pos":"NOM"} ,
		{"word":"capilliculteur","word_nosc":"capilliculteur","lemma":"capilliculteur","pos":"NOM"} ,
		{"word":"capilotade","word_nosc":"capilotade","lemma":"capilotade","pos":"NOM"} ,
		{"word":"capilotades","word_nosc":"capilotades","lemma":"capilotade","pos":"NOM"} ,
		{"word":"capisco","word_nosc":"capisco","lemma":"capisco","pos":"NOM"} ,
		{"word":"capitaine","word_nosc":"capitaine","lemma":"capitaine","pos":"NOM"} ,
		{"word":"capitainerie","word_nosc":"capitainerie","lemma":"capitainerie","pos":"NOM"} ,
		{"word":"capitaines","word_nosc":"capitaines","lemma":"capitaine","pos":"NOM"} ,
		{"word":"capital","word_nosc":"capital","lemma":"capital","pos":"NOM"} ,
		{"word":"capital-risque","word_nosc":"capital-risque","lemma":"capital-risque","pos":"NOM"} ,
		{"word":"capitale","word_nosc":"capitale","lemma":"capitale","pos":"NOM"} ,
		{"word":"capitales","word_nosc":"capitales","lemma":"capitale","pos":"NOM"} ,
		{"word":"capitalisation","word_nosc":"capitalisation","lemma":"capitalisation","pos":"NOM"} ,
		{"word":"capitalisme","word_nosc":"capitalisme","lemma":"capitalisme","pos":"NOM"} ,
		{"word":"capitaliste","word_nosc":"capitaliste","lemma":"capitaliste","pos":"NOM"} ,
		{"word":"capitalistes","word_nosc":"capitalistes","lemma":"capitaliste","pos":"NOM"} ,
		{"word":"capitan","word_nosc":"capitan","lemma":"capitan","pos":"NOM"} ,
		{"word":"capitanat","word_nosc":"capitanat","lemma":"capitanat","pos":"NOM"} ,
		{"word":"capitane","word_nosc":"capitane","lemma":"capitane","pos":"NOM"} ,
		{"word":"capitans","word_nosc":"capitans","lemma":"capitan","pos":"NOM"} ,
		{"word":"capitaux","word_nosc":"capitaux","lemma":"capital","pos":"NOM"} ,
		{"word":"capitole","word_nosc":"capitole","lemma":"capitole","pos":"NOM"} ,
		{"word":"capiton","word_nosc":"capiton","lemma":"capiton","pos":"NOM"} ,
		{"word":"capitonnage","word_nosc":"capitonnage","lemma":"capitonnage","pos":"NOM"} ,
		{"word":"capitons","word_nosc":"capitons","lemma":"capiton","pos":"NOM"} ,
		{"word":"capitouls","word_nosc":"capitouls","lemma":"capitoul","pos":"NOM"} ,
		{"word":"capitulard","word_nosc":"capitulard","lemma":"capitulard","pos":"NOM"} ,
		{"word":"capitulation","word_nosc":"capitulation","lemma":"capitulation","pos":"NOM"} ,
		{"word":"capitulations","word_nosc":"capitulations","lemma":"capitulation","pos":"NOM"} ,
		{"word":"capitule","word_nosc":"capitule","lemma":"capitule","pos":"NOM"} ,
		{"word":"capitules","word_nosc":"capitules","lemma":"capitule","pos":"NOM"} ,
		{"word":"capo","word_nosc":"capo","lemma":"capo","pos":"NOM"} ,
		{"word":"capoc","word_nosc":"capoc","lemma":"capoc","pos":"NOM"} ,
		{"word":"caporal","word_nosc":"caporal","lemma":"caporal","pos":"NOM"} ,
		{"word":"caporal-chef","word_nosc":"caporal-chef","lemma":"caporal-chef","pos":"NOM"} ,
		{"word":"caporale","word_nosc":"caporale","lemma":"caporal","pos":"NOM"} ,
		{"word":"caporaux","word_nosc":"caporaux","lemma":"caporal","pos":"NOM"} ,
		{"word":"capos","word_nosc":"capos","lemma":"capo","pos":"NOM"} ,
		{"word":"capot","word_nosc":"capot","lemma":"capot","pos":"NOM"} ,
		{"word":"capote","word_nosc":"capote","lemma":"capote","pos":"NOM"} ,
		{"word":"capotes","word_nosc":"capotes","lemma":"capote","pos":"NOM"} ,
		{"word":"capots","word_nosc":"capots","lemma":"capot","pos":"NOM"} ,
		{"word":"cappa","word_nosc":"cappa","lemma":"cappa","pos":"NOM"} ,
		{"word":"cappuccino","word_nosc":"cappuccino","lemma":"cappuccino","pos":"NOM"} ,
		{"word":"cappuccinos","word_nosc":"cappuccinos","lemma":"cappuccino","pos":"NOM"} ,
		{"word":"caprice","word_nosc":"caprice","lemma":"caprice","pos":"NOM"} ,
		{"word":"caprices","word_nosc":"caprices","lemma":"caprice","pos":"NOM"} ,
		{"word":"capricorne","word_nosc":"capricorne","lemma":"capricorne","pos":"NOM"} ,
		{"word":"capricornes","word_nosc":"capricornes","lemma":"capricorne","pos":"NOM"} ,
		{"word":"caps","word_nosc":"caps","lemma":"cap","pos":"NOM"} ,
		{"word":"capsule","word_nosc":"capsule","lemma":"capsule","pos":"NOM"} ,
		{"word":"capsules","word_nosc":"capsules","lemma":"capsule","pos":"NOM"} ,
		{"word":"captage","word_nosc":"captage","lemma":"captage","pos":"NOM"} ,
		{"word":"captation","word_nosc":"captation","lemma":"captation","pos":"NOM"} ,
		{"word":"captations","word_nosc":"captations","lemma":"captation","pos":"NOM"} ,
		{"word":"capteur","word_nosc":"capteur","lemma":"capteur","pos":"NOM"} ,
		{"word":"capteurs","word_nosc":"capteurs","lemma":"capteur","pos":"NOM"} ,
		{"word":"captif","word_nosc":"captif","lemma":"captif","pos":"NOM"} ,
		{"word":"captifs","word_nosc":"captifs","lemma":"captif","pos":"NOM"} ,
		{"word":"captive","word_nosc":"captive","lemma":"captif","pos":"NOM"} ,
		{"word":"captives","word_nosc":"captives","lemma":"captif","pos":"NOM"} ,
		{"word":"captivité","word_nosc":"captivite","lemma":"captivité","pos":"NOM"} ,
		{"word":"capture","word_nosc":"capture","lemma":"capture","pos":"NOM"} ,
		{"word":"captures","word_nosc":"captures","lemma":"capture","pos":"NOM"} ,
		{"word":"capuccino","word_nosc":"capuccino","lemma":"capuccino","pos":"NOM"} ,
		{"word":"capuche","word_nosc":"capuche","lemma":"capuche","pos":"NOM"} ,
		{"word":"capuches","word_nosc":"capuches","lemma":"capuche","pos":"NOM"} ,
		{"word":"capuchon","word_nosc":"capuchon","lemma":"capuchon","pos":"NOM"} ,
		{"word":"capuchons","word_nosc":"capuchons","lemma":"capuchon","pos":"NOM"} ,
		{"word":"capucin","word_nosc":"capucin","lemma":"capucin","pos":"NOM"} ,
		{"word":"capucinade","word_nosc":"capucinade","lemma":"capucinade","pos":"NOM"} ,
		{"word":"capucinades","word_nosc":"capucinades","lemma":"capucinade","pos":"NOM"} ,
		{"word":"capucine","word_nosc":"capucine","lemma":"capucine","pos":"NOM"} ,
		{"word":"capucines","word_nosc":"capucines","lemma":"capucine","pos":"NOM"} ,
		{"word":"capucino","word_nosc":"capucino","lemma":"capucino","pos":"NOM"} ,
		{"word":"capucins","word_nosc":"capucins","lemma":"capucin","pos":"NOM"} ,
		{"word":"capulet","word_nosc":"capulet","lemma":"capulet","pos":"NOM"} ,
		{"word":"caput mortuum","word_nosc":"caput mortuum","lemma":"caput mortuum","pos":"NOM"} ,
		{"word":"caque","word_nosc":"caque","lemma":"caque","pos":"NOM"} ,
		{"word":"caquelon","word_nosc":"caquelon","lemma":"caquelon","pos":"NOM"} ,
		{"word":"caques","word_nosc":"caques","lemma":"caque","pos":"NOM"} ,
		{"word":"caquet","word_nosc":"caquet","lemma":"caquet","pos":"NOM"} ,
		{"word":"caquetage","word_nosc":"caquetage","lemma":"caquetage","pos":"NOM"} ,
		{"word":"caquetages","word_nosc":"caquetages","lemma":"caquetage","pos":"NOM"} ,
		{"word":"caqueteuse","word_nosc":"caqueteuse","lemma":"caqueteur","pos":"NOM"} ,
		{"word":"caquets","word_nosc":"caquets","lemma":"caquet","pos":"NOM"} ,
		{"word":"caquètement","word_nosc":"caquetement","lemma":"caquètement","pos":"NOM"} ,
		{"word":"caquètements","word_nosc":"caquetements","lemma":"caquètement","pos":"NOM"} ,
		{"word":"car","word_nosc":"car","lemma":"car","pos":"NOM"} ,
		{"word":"carabas","word_nosc":"carabas","lemma":"carabas","pos":"NOM"} ,
		{"word":"carabe","word_nosc":"carabe","lemma":"carabe","pos":"NOM"} ,
		{"word":"carabes","word_nosc":"carabes","lemma":"carabe","pos":"NOM"} ,
		{"word":"carabin","word_nosc":"carabin","lemma":"carabin","pos":"NOM"} ,
		{"word":"carabine","word_nosc":"carabine","lemma":"carabine","pos":"NOM"} ,
		{"word":"carabines","word_nosc":"carabines","lemma":"carabine","pos":"NOM"} ,
		{"word":"carabinier","word_nosc":"carabinier","lemma":"carabinier","pos":"NOM"} ,
		{"word":"carabiniers","word_nosc":"carabiniers","lemma":"carabinier","pos":"NOM"} ,
		{"word":"carabins","word_nosc":"carabins","lemma":"carabin","pos":"NOM"} ,
		{"word":"carabosse","word_nosc":"carabosse","lemma":"carabosse","pos":"NOM"} ,
		{"word":"caracal","word_nosc":"caracal","lemma":"caracal","pos":"NOM"} ,
		{"word":"caraco","word_nosc":"caraco","lemma":"caraco","pos":"NOM"} ,
		{"word":"caracolade","word_nosc":"caracolade","lemma":"caracolade","pos":"NOM"} ,
		{"word":"caracole","word_nosc":"caracole","lemma":"caracole","pos":"NOM"} ,
		{"word":"caracos","word_nosc":"caracos","lemma":"caraco","pos":"NOM"} ,
		{"word":"caractère","word_nosc":"caractere","lemma":"caractère","pos":"NOM"} ,
		{"word":"caractères","word_nosc":"caracteres","lemma":"caractère","pos":"NOM"} ,
		{"word":"caractérielle","word_nosc":"caracterielle","lemma":"caractériel","pos":"NOM"} ,
		{"word":"caractérisation","word_nosc":"caracterisation","lemma":"caractérisation","pos":"NOM"} ,
		{"word":"caractéristique","word_nosc":"caracteristique","lemma":"caractéristique","pos":"NOM"} ,
		{"word":"caractéristiques","word_nosc":"caracteristiques","lemma":"caractéristique","pos":"NOM"} ,
		{"word":"caractérologie","word_nosc":"caracterologie","lemma":"caractérologie","pos":"NOM"} ,
		{"word":"caracul","word_nosc":"caracul","lemma":"caracul","pos":"NOM"} ,
		{"word":"carafe","word_nosc":"carafe","lemma":"carafe","pos":"NOM"} ,
		{"word":"carafes","word_nosc":"carafes","lemma":"carafe","pos":"NOM"} ,
		{"word":"carafon","word_nosc":"carafon","lemma":"carafon","pos":"NOM"} ,
		{"word":"carafons","word_nosc":"carafons","lemma":"carafon","pos":"NOM"} ,
		{"word":"carambar","word_nosc":"carambar","lemma":"carambar","pos":"NOM"} ,
		{"word":"carambars","word_nosc":"carambars","lemma":"carambar","pos":"NOM"} ,
		{"word":"carambolage","word_nosc":"carambolage","lemma":"carambolage","pos":"NOM"} ,
		{"word":"carambolages","word_nosc":"carambolages","lemma":"carambolage","pos":"NOM"} ,
		{"word":"carambole","word_nosc":"carambole","lemma":"carambole","pos":"NOM"} ,
		{"word":"caramboles","word_nosc":"caramboles","lemma":"carambole","pos":"NOM"} ,
		{"word":"carambouillage","word_nosc":"carambouillage","lemma":"carambouillage","pos":"NOM"} ,
		{"word":"carambouille","word_nosc":"carambouille","lemma":"carambouille","pos":"NOM"} ,
		{"word":"carambouilleurs","word_nosc":"carambouilleurs","lemma":"carambouilleur","pos":"NOM"} ,
		{"word":"caramel","word_nosc":"caramel","lemma":"caramel","pos":"NOM"} ,
		{"word":"caramels","word_nosc":"caramels","lemma":"caramel","pos":"NOM"} ,
		{"word":"carapace","word_nosc":"carapace","lemma":"carapace","pos":"NOM"} ,
		{"word":"carapaces","word_nosc":"carapaces","lemma":"carapace","pos":"NOM"} ,
		{"word":"caraque","word_nosc":"caraque","lemma":"caraque","pos":"NOM"} ,
		{"word":"carat","word_nosc":"carat","lemma":"carat","pos":"NOM"} ,
		{"word":"carats","word_nosc":"carats","lemma":"carat","pos":"NOM"} ,
		{"word":"caravane","word_nosc":"caravane","lemma":"caravane","pos":"NOM"} ,
		{"word":"caravanes","word_nosc":"caravanes","lemma":"caravane","pos":"NOM"} ,
		{"word":"caravanier","word_nosc":"caravanier","lemma":"caravanier","pos":"NOM"} ,
		{"word":"caravaniers","word_nosc":"caravaniers","lemma":"caravanier","pos":"NOM"} ,
		{"word":"caravaning","word_nosc":"caravaning","lemma":"caravaning","pos":"NOM"} ,
		{"word":"caravanings","word_nosc":"caravanings","lemma":"caravaning","pos":"NOM"} ,
		{"word":"caravanière","word_nosc":"caravaniere","lemma":"caravanier","pos":"NOM"} ,
		{"word":"caravanières","word_nosc":"caravanieres","lemma":"caravanier","pos":"NOM"} ,
		{"word":"caravansérail","word_nosc":"caravanserail","lemma":"caravansérail","pos":"NOM"} ,
		{"word":"caravansérails","word_nosc":"caravanserails","lemma":"caravansérail","pos":"NOM"} ,
		{"word":"caravelle","word_nosc":"caravelle","lemma":"caravelle","pos":"NOM"} ,
		{"word":"caravelles","word_nosc":"caravelles","lemma":"caravelle","pos":"NOM"} ,
		{"word":"caraïbe","word_nosc":"caraibe","lemma":"caraïbe","pos":"NOM"} ,
		{"word":"caraïbes","word_nosc":"caraibes","lemma":"caraïbe","pos":"NOM"} ,
		{"word":"carbets","word_nosc":"carbets","lemma":"carbet","pos":"NOM"} ,
		{"word":"carboglace","word_nosc":"carboglace","lemma":"carboglace","pos":"NOM"} ,
		{"word":"carbonade","word_nosc":"carbonade","lemma":"carbonade","pos":"NOM"} ,
		{"word":"carbonari","word_nosc":"carbonari","lemma":"carbonari","pos":"NOM"} ,
		{"word":"carbonate","word_nosc":"carbonate","lemma":"carbonate","pos":"NOM"} ,
		{"word":"carbone","word_nosc":"carbone","lemma":"carbone","pos":"NOM"} ,
		{"word":"carbones","word_nosc":"carbones","lemma":"carbone","pos":"NOM"} ,
		{"word":"carbonisation","word_nosc":"carbonisation","lemma":"carbonisation","pos":"NOM"} ,
		{"word":"carboxyhémoglobine","word_nosc":"carboxyhemoglobine","lemma":"carboxyhémoglobine","pos":"NOM"} ,
		{"word":"carburant","word_nosc":"carburant","lemma":"carburant","pos":"NOM"} ,
		{"word":"carburants","word_nosc":"carburants","lemma":"carburant","pos":"NOM"} ,
		{"word":"carburateur","word_nosc":"carburateur","lemma":"carburateur","pos":"NOM"} ,
		{"word":"carburateurs","word_nosc":"carburateurs","lemma":"carburateur","pos":"NOM"} ,
		{"word":"carburation","word_nosc":"carburation","lemma":"carburation","pos":"NOM"} ,
		{"word":"carbure","word_nosc":"carbure","lemma":"carbure","pos":"NOM"} ,
		{"word":"carcajou","word_nosc":"carcajou","lemma":"carcajou","pos":"NOM"} ,
		{"word":"carcan","word_nosc":"carcan","lemma":"carcan","pos":"NOM"} ,
		{"word":"carcans","word_nosc":"carcans","lemma":"carcan","pos":"NOM"} ,
		{"word":"carcasse","word_nosc":"carcasse","lemma":"carcasse","pos":"NOM"} ,
		{"word":"carcasses","word_nosc":"carcasses","lemma":"carcasse","pos":"NOM"} ,
		{"word":"carcharodon","word_nosc":"carcharodon","lemma":"carcharodon","pos":"NOM"} ,
		{"word":"carcinome","word_nosc":"carcinome","lemma":"carcinome","pos":"NOM"} ,
		{"word":"carcinoïde","word_nosc":"carcinoide","lemma":"carcinoïde","pos":"NOM"} ,
		{"word":"cardage","word_nosc":"cardage","lemma":"cardage","pos":"NOM"} ,
		{"word":"cardamome","word_nosc":"cardamome","lemma":"cardamome","pos":"NOM"} ,
		{"word":"cardamomes","word_nosc":"cardamomes","lemma":"cardamome","pos":"NOM"} ,
		{"word":"cardan","word_nosc":"cardan","lemma":"cardan","pos":"NOM"} ,
		{"word":"cardans","word_nosc":"cardans","lemma":"cardan","pos":"NOM"} ,
		{"word":"carde","word_nosc":"carde","lemma":"carde","pos":"NOM"} ,
		{"word":"carderie","word_nosc":"carderie","lemma":"carderie","pos":"NOM"} ,
		{"word":"cardes","word_nosc":"cardes","lemma":"carde","pos":"NOM"} ,
		{"word":"cardeur","word_nosc":"cardeur","lemma":"cardeur","pos":"NOM"} ,
		{"word":"cardeuse","word_nosc":"cardeuse","lemma":"cardeur","pos":"NOM"} ,
		{"word":"cardeuses","word_nosc":"cardeuses","lemma":"cardeur","pos":"NOM"} ,
		{"word":"cardia","word_nosc":"cardia","lemma":"cardia","pos":"NOM"} ,
		{"word":"cardiaque","word_nosc":"cardiaque","lemma":"cardiaque","pos":"NOM"} ,
		{"word":"cardiaques","word_nosc":"cardiaques","lemma":"cardiaque","pos":"NOM"} ,
		{"word":"cardigan","word_nosc":"cardigan","lemma":"cardigan","pos":"NOM"} ,
		{"word":"cardigans","word_nosc":"cardigans","lemma":"cardigan","pos":"NOM"} ,
		{"word":"cardinal","word_nosc":"cardinal","lemma":"cardinal","pos":"NOM"} ,
		{"word":"cardinal-archevêque","word_nosc":"cardinal-archeveque","lemma":"cardinal-archevêque","pos":"NOM"} ,
		{"word":"cardinal-légat","word_nosc":"cardinal-legat","lemma":"cardinal-légat","pos":"NOM"} ,
		{"word":"cardinaux","word_nosc":"cardinaux","lemma":"cardinal","pos":"NOM"} ,
		{"word":"cardiogramme","word_nosc":"cardiogramme","lemma":"cardiogramme","pos":"NOM"} ,
		{"word":"cardiographe","word_nosc":"cardiographe","lemma":"cardiographe","pos":"NOM"} ,
		{"word":"cardiographie","word_nosc":"cardiographie","lemma":"cardiographie","pos":"NOM"} ,
		{"word":"cardiologie","word_nosc":"cardiologie","lemma":"cardiologie","pos":"NOM"} ,
		{"word":"cardiologue","word_nosc":"cardiologue","lemma":"cardiologue","pos":"NOM"} ,
		{"word":"cardiologues","word_nosc":"cardiologues","lemma":"cardiologue","pos":"NOM"} ,
		{"word":"cardiomyopathie","word_nosc":"cardiomyopathie","lemma":"cardiomyopathie","pos":"NOM"} ,
		{"word":"cardiomégalie","word_nosc":"cardiomegalie","lemma":"cardiomégalie","pos":"NOM"} ,
		{"word":"cardite","word_nosc":"cardite","lemma":"cardite","pos":"NOM"} ,
		{"word":"cardium","word_nosc":"cardium","lemma":"cardium","pos":"NOM"} ,
		{"word":"cardon","word_nosc":"cardon","lemma":"cardon","pos":"NOM"} ,
		{"word":"cardons","word_nosc":"cardons","lemma":"cardon","pos":"NOM"} ,
		{"word":"cardères","word_nosc":"carderes","lemma":"carder","pos":"NOM"} ,
		{"word":"cardés","word_nosc":"cardes","lemma":"cardé","pos":"NOM"} ,
		{"word":"care","word_nosc":"care","lemma":"care","pos":"NOM"} ,
		{"word":"carence","word_nosc":"carence","lemma":"carence","pos":"NOM"} ,
		{"word":"carences","word_nosc":"carences","lemma":"carence","pos":"NOM"} ,
		{"word":"caresse","word_nosc":"caresse","lemma":"caresse","pos":"NOM"} ,
		{"word":"caresses","word_nosc":"caresses","lemma":"caresse","pos":"NOM"} ,
		{"word":"caresseuse","word_nosc":"caresseuse","lemma":"caresseur","pos":"NOM"} ,
		{"word":"caresseuses","word_nosc":"caresseuses","lemma":"caresseur","pos":"NOM"} ,
		{"word":"caret","word_nosc":"caret","lemma":"caret","pos":"NOM"} ,
		{"word":"carex","word_nosc":"carex","lemma":"carex","pos":"NOM"} ,
		{"word":"cargaison","word_nosc":"cargaison","lemma":"cargaison","pos":"NOM"} ,
		{"word":"cargaisons","word_nosc":"cargaisons","lemma":"cargaison","pos":"NOM"} ,
		{"word":"cargo","word_nosc":"cargo","lemma":"cargo","pos":"NOM"} ,
		{"word":"cargos","word_nosc":"cargos","lemma":"cargo","pos":"NOM"} ,
		{"word":"cari","word_nosc":"cari","lemma":"cari","pos":"NOM"} ,
		{"word":"cariatide","word_nosc":"cariatide","lemma":"cariatide","pos":"NOM"} ,
		{"word":"cariatides","word_nosc":"cariatides","lemma":"cariatide","pos":"NOM"} ,
		{"word":"caribou","word_nosc":"caribou","lemma":"caribou","pos":"NOM"} ,
		{"word":"caribous","word_nosc":"caribous","lemma":"caribou","pos":"NOM"} ,
		{"word":"caricature","word_nosc":"caricature","lemma":"caricature","pos":"NOM"} ,
		{"word":"caricatures","word_nosc":"caricatures","lemma":"caricature","pos":"NOM"} ,
		{"word":"caricaturiste","word_nosc":"caricaturiste","lemma":"caricaturiste","pos":"NOM"} ,
		{"word":"caricaturistes","word_nosc":"caricaturistes","lemma":"caricaturiste","pos":"NOM"} ,
		{"word":"carie","word_nosc":"carie","lemma":"carie","pos":"NOM"} ,
		{"word":"caries","word_nosc":"caries","lemma":"carie","pos":"NOM"} ,
		{"word":"carillon","word_nosc":"carillon","lemma":"carillon","pos":"NOM"} ,
		{"word":"carillonneur","word_nosc":"carillonneur","lemma":"carillonneur","pos":"NOM"} ,
		{"word":"carillonneuse","word_nosc":"carillonneuse","lemma":"carillonneur","pos":"NOM"} ,
		{"word":"carillons","word_nosc":"carillons","lemma":"carillon","pos":"NOM"} ,
		{"word":"carioca","word_nosc":"carioca","lemma":"carioca","pos":"NOM"} ,
		{"word":"caris","word_nosc":"caris","lemma":"cari","pos":"NOM"} ,
		{"word":"cariste","word_nosc":"cariste","lemma":"cariste","pos":"NOM"} ,
		{"word":"carlin","word_nosc":"carlin","lemma":"carlin","pos":"NOM"} ,
		{"word":"carlines","word_nosc":"carlines","lemma":"carline","pos":"NOM"} ,
		{"word":"carlingue","word_nosc":"carlingue","lemma":"carlingue","pos":"NOM"} ,
		{"word":"carlingues","word_nosc":"carlingues","lemma":"carlingue","pos":"NOM"} ,
		{"word":"carlins","word_nosc":"carlins","lemma":"carlin","pos":"NOM"} ,
		{"word":"carlisme","word_nosc":"carlisme","lemma":"carlisme","pos":"NOM"} ,
		{"word":"carlistes","word_nosc":"carlistes","lemma":"carliste","pos":"NOM"} ,
		{"word":"carmagnole","word_nosc":"carmagnole","lemma":"carmagnole","pos":"NOM"} ,
		{"word":"carmagnoles","word_nosc":"carmagnoles","lemma":"carmagnole","pos":"NOM"} ,
		{"word":"carme","word_nosc":"carme","lemma":"carme","pos":"NOM"} ,
		{"word":"carmel","word_nosc":"carmel","lemma":"carmel","pos":"NOM"} ,
		{"word":"carmes","word_nosc":"carmes","lemma":"carme","pos":"NOM"} ,
		{"word":"carmin","word_nosc":"carmin","lemma":"carmin","pos":"NOM"} ,
		{"word":"carmins","word_nosc":"carmins","lemma":"carmin","pos":"NOM"} ,
		{"word":"carmélite","word_nosc":"carmelite","lemma":"carmélite","pos":"NOM"} ,
		{"word":"carmélites","word_nosc":"carmelites","lemma":"carmélite","pos":"NOM"} ,
		{"word":"carnage","word_nosc":"carnage","lemma":"carnage","pos":"NOM"} ,
		{"word":"carnages","word_nosc":"carnages","lemma":"carnage","pos":"NOM"} ,
		{"word":"carnassier","word_nosc":"carnassier","lemma":"carnassier","pos":"NOM"} ,
		{"word":"carnassiers","word_nosc":"carnassiers","lemma":"carnassier","pos":"NOM"} ,
		{"word":"carnassière","word_nosc":"carnassiere","lemma":"carnassier","pos":"NOM"} ,
		{"word":"carnassières","word_nosc":"carnassieres","lemma":"carnassier","pos":"NOM"} ,
		{"word":"carnation","word_nosc":"carnation","lemma":"carnation","pos":"NOM"} ,
		{"word":"carnaval","word_nosc":"carnaval","lemma":"carnaval","pos":"NOM"} ,
		{"word":"carnavals","word_nosc":"carnavals","lemma":"carnaval","pos":"NOM"} ,
		{"word":"carne","word_nosc":"carne","lemma":"carne","pos":"NOM"} ,
		{"word":"carnes","word_nosc":"carnes","lemma":"carne","pos":"NOM"} ,
		{"word":"carnet","word_nosc":"carnet","lemma":"carnet","pos":"NOM"} ,
		{"word":"carnets","word_nosc":"carnets","lemma":"carnet","pos":"NOM"} ,
		{"word":"carnier","word_nosc":"carnier","lemma":"carnier","pos":"NOM"} ,
		{"word":"carniers","word_nosc":"carniers","lemma":"carnier","pos":"NOM"} ,
		{"word":"carnivore","word_nosc":"carnivore","lemma":"carnivore","pos":"NOM"} ,
		{"word":"carnivores","word_nosc":"carnivores","lemma":"carnivore","pos":"NOM"} ,
		{"word":"carogne","word_nosc":"carogne","lemma":"carogne","pos":"NOM"} ,
		{"word":"carole","word_nosc":"carole","lemma":"carole","pos":"NOM"} ,
		{"word":"carolus","word_nosc":"carolus","lemma":"carolus","pos":"NOM"} ,
		{"word":"caronades","word_nosc":"caronades","lemma":"caronade","pos":"NOM"} ,
		{"word":"caroncule","word_nosc":"caroncule","lemma":"caroncule","pos":"NOM"} ,
		{"word":"carotide","word_nosc":"carotide","lemma":"carotide","pos":"NOM"} ,
		{"word":"carotides","word_nosc":"carotides","lemma":"carotide","pos":"NOM"} ,
		{"word":"carottage","word_nosc":"carottage","lemma":"carottage","pos":"NOM"} ,
		{"word":"carotte","word_nosc":"carotte","lemma":"carotte","pos":"NOM"} ,
		{"word":"carottes","word_nosc":"carottes","lemma":"carotte","pos":"NOM"} ,
		{"word":"carotteur","word_nosc":"carotteur","lemma":"carotteur","pos":"NOM"} ,
		{"word":"carotène","word_nosc":"carotene","lemma":"carotène","pos":"NOM"} ,
		{"word":"caroube","word_nosc":"caroube","lemma":"caroube","pos":"NOM"} ,
		{"word":"caroubier","word_nosc":"caroubier","lemma":"caroubier","pos":"NOM"} ,
		{"word":"caroubiers","word_nosc":"caroubiers","lemma":"caroubier","pos":"NOM"} ,
		{"word":"carouble","word_nosc":"carouble","lemma":"carouble","pos":"NOM"} ,
		{"word":"caroubles","word_nosc":"caroubles","lemma":"carouble","pos":"NOM"} ,
		{"word":"caroubleur","word_nosc":"caroubleur","lemma":"caroubleur","pos":"NOM"} ,
		{"word":"carousse","word_nosc":"carousse","lemma":"carousse","pos":"NOM"} ,
		{"word":"carpaccio","word_nosc":"carpaccio","lemma":"carpaccio","pos":"NOM"} ,
		{"word":"carpe","word_nosc":"carpe","lemma":"carpe","pos":"NOM"} ,
		{"word":"carpes","word_nosc":"carpes","lemma":"carpe","pos":"NOM"} ,
		{"word":"carpette","word_nosc":"carpette","lemma":"carpette","pos":"NOM"} ,
		{"word":"carpettes","word_nosc":"carpettes","lemma":"carpette","pos":"NOM"} ,
		{"word":"carpillon","word_nosc":"carpillon","lemma":"carpillon","pos":"NOM"} ,
		{"word":"carquois","word_nosc":"carquois","lemma":"carquois","pos":"NOM"} ,
		{"word":"carrare","word_nosc":"carrare","lemma":"carrare","pos":"NOM"} ,
		{"word":"carre","word_nosc":"carre","lemma":"carre","pos":"NOM"} ,
		{"word":"carreau","word_nosc":"carreau","lemma":"carreau","pos":"NOM"} ,
		{"word":"carreaux","word_nosc":"carreaux","lemma":"carreau","pos":"NOM"} ,
		{"word":"carrefour","word_nosc":"carrefour","lemma":"carrefour","pos":"NOM"} ,
		{"word":"carrefours","word_nosc":"carrefours","lemma":"carrefour","pos":"NOM"} ,
		{"word":"carrelage","word_nosc":"carrelage","lemma":"carrelage","pos":"NOM"} ,
		{"word":"carrelages","word_nosc":"carrelages","lemma":"carrelage","pos":"NOM"} ,
		{"word":"carrelet","word_nosc":"carrelet","lemma":"carrelet","pos":"NOM"} ,
		{"word":"carrelets","word_nosc":"carrelets","lemma":"carrelet","pos":"NOM"} ,
		{"word":"carreleur","word_nosc":"carreleur","lemma":"carreleur","pos":"NOM"} ,
		{"word":"carreleurs","word_nosc":"carreleurs","lemma":"carreleur","pos":"NOM"} ,
		{"word":"carres","word_nosc":"carres","lemma":"carre","pos":"NOM"} ,
		{"word":"carrick","word_nosc":"carrick","lemma":"carrick","pos":"NOM"} ,
		{"word":"carrier","word_nosc":"carrier","lemma":"carrier","pos":"NOM"} ,
		{"word":"carriers","word_nosc":"carriers","lemma":"carrier","pos":"NOM"} ,
		{"word":"carriole","word_nosc":"carriole","lemma":"carriole","pos":"NOM"} ,
		{"word":"carrioles","word_nosc":"carrioles","lemma":"carriole","pos":"NOM"} ,
		{"word":"carrière","word_nosc":"carriere","lemma":"carrière","pos":"NOM"} ,
		{"word":"carrières","word_nosc":"carrieres","lemma":"carrière","pos":"NOM"} ,
		{"word":"carriérisme","word_nosc":"carrierisme","lemma":"carriérisme","pos":"NOM"} ,
		{"word":"carriériste","word_nosc":"carrieriste","lemma":"carriériste","pos":"NOM"} ,
		{"word":"carriéristes","word_nosc":"carrieristes","lemma":"carriériste","pos":"NOM"} ,
		{"word":"carron","word_nosc":"carron","lemma":"carron","pos":"NOM"} ,
		{"word":"carrosse","word_nosc":"carrosse","lemma":"carrosse","pos":"NOM"} ,
		{"word":"carrosserie","word_nosc":"carrosserie","lemma":"carrosserie","pos":"NOM"} ,
		{"word":"carrosseries","word_nosc":"carrosseries","lemma":"carrosserie","pos":"NOM"} ,
		{"word":"carrosses","word_nosc":"carrosses","lemma":"carrosse","pos":"NOM"} ,
		{"word":"carrossier","word_nosc":"carrossier","lemma":"carrossier","pos":"NOM"} ,
		{"word":"carrossiers","word_nosc":"carrossiers","lemma":"carrossier","pos":"NOM"} ,
		{"word":"carrousel","word_nosc":"carrousel","lemma":"carrousel","pos":"NOM"} ,
		{"word":"carrousels","word_nosc":"carrousels","lemma":"carrousel","pos":"NOM"} ,
		{"word":"carrure","word_nosc":"carrure","lemma":"carrure","pos":"NOM"} ,
		{"word":"carrures","word_nosc":"carrures","lemma":"carrure","pos":"NOM"} ,
		{"word":"carry","word_nosc":"carry","lemma":"carry","pos":"NOM"} ,
		{"word":"carré","word_nosc":"carre","lemma":"carré","pos":"NOM"} ,
		{"word":"carrée","word_nosc":"carree","lemma":"carrée","pos":"NOM"} ,
		{"word":"carrées","word_nosc":"carrees","lemma":"carrée","pos":"NOM"} ,
		{"word":"carrés","word_nosc":"carres","lemma":"carré","pos":"NOM"} ,
		{"word":"cars","word_nosc":"cars","lemma":"car","pos":"NOM"} ,
		{"word":"cartable","word_nosc":"cartable","lemma":"cartable","pos":"NOM"} ,
		{"word":"cartables","word_nosc":"cartables","lemma":"cartable","pos":"NOM"} ,
		{"word":"carte","word_nosc":"carte","lemma":"carte","pos":"NOM"} ,
		{"word":"carte-clé","word_nosc":"carte-cle","lemma":"carte-clé","pos":"NOM"} ,
		{"word":"carte-lettre","word_nosc":"carte-lettre","lemma":"carte-lettre","pos":"NOM"} ,
		{"word":"cartel","word_nosc":"cartel","lemma":"cartel","pos":"NOM"} ,
		{"word":"cartels","word_nosc":"cartels","lemma":"cartel","pos":"NOM"} ,
		{"word":"carter","word_nosc":"carter","lemma":"carter","pos":"NOM"} ,
		{"word":"carterie","word_nosc":"carterie","lemma":"carterie","pos":"NOM"} ,
		{"word":"carters","word_nosc":"carters","lemma":"carter","pos":"NOM"} ,
		{"word":"cartes","word_nosc":"cartes","lemma":"carte","pos":"NOM"} ,
		{"word":"cartes-lettres","word_nosc":"cartes-lettres","lemma":"carte-lettre","pos":"NOM"} ,
		{"word":"carthaginois","word_nosc":"carthaginois","lemma":"carthaginois","pos":"NOM"} ,
		{"word":"carthame","word_nosc":"carthame","lemma":"carthame","pos":"NOM"} ,
		{"word":"cartilage","word_nosc":"cartilage","lemma":"cartilage","pos":"NOM"} ,
		{"word":"cartilages","word_nosc":"cartilages","lemma":"cartilage","pos":"NOM"} ,
		{"word":"cartographe","word_nosc":"cartographe","lemma":"cartographe","pos":"NOM"} ,
		{"word":"cartographes","word_nosc":"cartographes","lemma":"cartographe","pos":"NOM"} ,
		{"word":"cartographie","word_nosc":"cartographie","lemma":"cartographie","pos":"NOM"} ,
		{"word":"cartomancie","word_nosc":"cartomancie","lemma":"cartomancie","pos":"NOM"} ,
		{"word":"cartomancien","word_nosc":"cartomancien","lemma":"cartomancien","pos":"NOM"} ,
		{"word":"cartomancienne","word_nosc":"cartomancienne","lemma":"cartomancien","pos":"NOM"} ,
		{"word":"cartomanciennes","word_nosc":"cartomanciennes","lemma":"cartomancien","pos":"NOM"} ,
		{"word":"carton","word_nosc":"carton","lemma":"carton","pos":"NOM"} ,
		{"word":"carton-pâte","word_nosc":"carton-pate","lemma":"carton-pâte","pos":"NOM"} ,
		{"word":"cartonnage","word_nosc":"cartonnage","lemma":"cartonnage","pos":"NOM"} ,
		{"word":"cartonnages","word_nosc":"cartonnages","lemma":"cartonnage","pos":"NOM"} ,
		{"word":"cartonneuses","word_nosc":"cartonneuses","lemma":"cartonneur","pos":"NOM"} ,
		{"word":"cartonnier","word_nosc":"cartonnier","lemma":"cartonnier","pos":"NOM"} ,
		{"word":"cartonniers","word_nosc":"cartonniers","lemma":"cartonnier","pos":"NOM"} ,
		{"word":"cartons","word_nosc":"cartons","lemma":"carton","pos":"NOM"} ,
		{"word":"cartoon","word_nosc":"cartoon","lemma":"cartoon","pos":"NOM"} ,
		{"word":"cartoons","word_nosc":"cartoons","lemma":"cartoon","pos":"NOM"} ,
		{"word":"cartothèque","word_nosc":"cartotheque","lemma":"cartothèque","pos":"NOM"} ,
		{"word":"cartouche","word_nosc":"cartouche","lemma":"cartouche","pos":"NOM"} ,
		{"word":"cartoucherie","word_nosc":"cartoucherie","lemma":"cartoucherie","pos":"NOM"} ,
		{"word":"cartouches","word_nosc":"cartouches","lemma":"cartouche","pos":"NOM"} ,
		{"word":"cartouchière","word_nosc":"cartouchiere","lemma":"cartouchière","pos":"NOM"} ,
		{"word":"cartouchières","word_nosc":"cartouchieres","lemma":"cartouchière","pos":"NOM"} ,
		{"word":"cartulaires","word_nosc":"cartulaires","lemma":"cartulaire","pos":"NOM"} ,
		{"word":"cartésianisme","word_nosc":"cartesianisme","lemma":"cartésianisme","pos":"NOM"} ,
		{"word":"cartésien","word_nosc":"cartesien","lemma":"cartésien","pos":"NOM"} ,
		{"word":"carvi","word_nosc":"carvi","lemma":"carvi","pos":"NOM"} ,
		{"word":"cary","word_nosc":"cary","lemma":"cary","pos":"NOM"} ,
		{"word":"caryatides","word_nosc":"caryatides","lemma":"caryatide","pos":"NOM"} ,
		{"word":"caryotype","word_nosc":"caryotype","lemma":"caryotype","pos":"NOM"} ,
		{"word":"carène","word_nosc":"carene","lemma":"carène","pos":"NOM"} ,
		{"word":"carènes","word_nosc":"carenes","lemma":"carène","pos":"NOM"} ,
		{"word":"carénage","word_nosc":"carenage","lemma":"carénage","pos":"NOM"} ,
		{"word":"carénages","word_nosc":"carenages","lemma":"carénage","pos":"NOM"} ,
		{"word":"carême","word_nosc":"careme","lemma":"carême","pos":"NOM"} ,
		{"word":"carême-prenant","word_nosc":"careme-prenant","lemma":"carême-prenant","pos":"NOM"} ,
		{"word":"carêmes","word_nosc":"caremes","lemma":"carême","pos":"NOM"} ,
		{"word":"cas","word_nosc":"cas","lemma":"cas","pos":"NOM"} ,
		{"word":"casa","word_nosc":"casa","lemma":"casa","pos":"NOM"} ,
		{"word":"casaniers","word_nosc":"casaniers","lemma":"casanier","pos":"NOM"} ,
		{"word":"casanière","word_nosc":"casaniere","lemma":"casanier","pos":"NOM"} ,
		{"word":"casanières","word_nosc":"casanieres","lemma":"casanier","pos":"NOM"} ,
		{"word":"casaque","word_nosc":"casaque","lemma":"casaque","pos":"NOM"} ,
		{"word":"casaques","word_nosc":"casaques","lemma":"casaque","pos":"NOM"} ,
		{"word":"casaquin","word_nosc":"casaquin","lemma":"casaquin","pos":"NOM"} ,
		{"word":"casbah","word_nosc":"casbah","lemma":"casbah","pos":"NOM"} ,
		{"word":"cascade","word_nosc":"cascade","lemma":"cascade","pos":"NOM"} ,
		{"word":"cascades","word_nosc":"cascades","lemma":"cascade","pos":"NOM"} ,
		{"word":"cascadeur","word_nosc":"cascadeur","lemma":"cascadeur","pos":"NOM"} ,
		{"word":"cascadeurs","word_nosc":"cascadeurs","lemma":"cascadeur","pos":"NOM"} ,
		{"word":"cascadeuse","word_nosc":"cascadeuse","lemma":"cascadeur","pos":"NOM"} ,
		{"word":"cascara","word_nosc":"cascara","lemma":"cascara","pos":"NOM"} ,
		{"word":"cascatelle","word_nosc":"cascatelle","lemma":"cascatelle","pos":"NOM"} ,
		{"word":"cascatelles","word_nosc":"cascatelles","lemma":"cascatelle","pos":"NOM"} ,
		{"word":"case","word_nosc":"case","lemma":"case","pos":"NOM"} ,
		{"word":"casemate","word_nosc":"casemate","lemma":"casemate","pos":"NOM"} ,
		{"word":"casemates","word_nosc":"casemates","lemma":"casemate","pos":"NOM"} ,
		{"word":"caserne","word_nosc":"caserne","lemma":"caserne","pos":"NOM"} ,
		{"word":"casernement","word_nosc":"casernement","lemma":"casernement","pos":"NOM"} ,
		{"word":"casernements","word_nosc":"casernements","lemma":"casernement","pos":"NOM"} ,
		{"word":"casernes","word_nosc":"casernes","lemma":"caserne","pos":"NOM"} ,
		{"word":"cases","word_nosc":"cases","lemma":"case","pos":"NOM"} ,
		{"word":"casette","word_nosc":"casette","lemma":"casette","pos":"NOM"} ,
		{"word":"casettes","word_nosc":"casettes","lemma":"casette","pos":"NOM"} ,
		{"word":"cash","word_nosc":"cash","lemma":"cash","pos":"NOM"} ,
		{"word":"cashmere","word_nosc":"cashmere","lemma":"cashmere","pos":"NOM"} ,
		{"word":"casier","word_nosc":"casier","lemma":"casier","pos":"NOM"} ,
		{"word":"casiers","word_nosc":"casiers","lemma":"casier","pos":"NOM"} ,
		{"word":"casimir","word_nosc":"casimir","lemma":"casimir","pos":"NOM"} ,
		{"word":"casino","word_nosc":"casino","lemma":"casino","pos":"NOM"} ,
		{"word":"casinos","word_nosc":"casinos","lemma":"casino","pos":"NOM"} ,
		{"word":"casoar","word_nosc":"casoar","lemma":"casoar","pos":"NOM"} ,
		{"word":"casoars","word_nosc":"casoars","lemma":"casoar","pos":"NOM"} ,
		{"word":"casque","word_nosc":"casque","lemma":"casque","pos":"NOM"} ,
		{"word":"casques","word_nosc":"casques","lemma":"casque","pos":"NOM"} ,
		{"word":"casquette","word_nosc":"casquette","lemma":"casquette","pos":"NOM"} ,
		{"word":"casquettes","word_nosc":"casquettes","lemma":"casquette","pos":"NOM"} ,
		{"word":"casquettiers","word_nosc":"casquettiers","lemma":"casquettier","pos":"NOM"} ,
		{"word":"cassage","word_nosc":"cassage","lemma":"cassage","pos":"NOM"} ,
		{"word":"cassages","word_nosc":"cassages","lemma":"cassage","pos":"NOM"} ,
		{"word":"cassandre","word_nosc":"cassandre","lemma":"cassandre","pos":"NOM"} ,
		{"word":"cassate","word_nosc":"cassate","lemma":"cassate","pos":"NOM"} ,
		{"word":"cassation","word_nosc":"cassation","lemma":"cassation","pos":"NOM"} ,
		{"word":"cassations","word_nosc":"cassations","lemma":"cassation","pos":"NOM"} ,
		{"word":"casse","word_nosc":"casse","lemma":"casse","pos":"NOM"} ,
		{"word":"casse-bonbons","word_nosc":"casse-bonbons","lemma":"casse-bonbon","pos":"NOM"} ,
		{"word":"casse-burnes","word_nosc":"casse-burnes","lemma":"casse-burnes","pos":"NOM"} ,
		{"word":"casse-couilles","word_nosc":"casse-couilles","lemma":"casse-couilles","pos":"NOM"} ,
		{"word":"casse-croûte","word_nosc":"casse-croute","lemma":"casse-croûte","pos":"NOM"} ,
		{"word":"casse-croûtes","word_nosc":"casse-croutes","lemma":"casse-croûte","pos":"NOM"} ,
		{"word":"casse-dalle","word_nosc":"casse-dalle","lemma":"casse-dalle","pos":"NOM"} ,
		{"word":"casse-dalles","word_nosc":"casse-dalles","lemma":"casse-dalle","pos":"NOM"} ,
		{"word":"casse-graine","word_nosc":"casse-graine","lemma":"casse-graine","pos":"NOM"} ,
		{"word":"casse-gueule","word_nosc":"casse-gueule","lemma":"casse-gueule","pos":"NOM"} ,
		{"word":"casse-noisette","word_nosc":"casse-noisette","lemma":"casse-noisette","pos":"NOM"} ,
		{"word":"casse-noisettes","word_nosc":"casse-noisettes","lemma":"casse-noisettes","pos":"NOM"} ,
		{"word":"casse-noix","word_nosc":"casse-noix","lemma":"casse-noix","pos":"NOM"} ,
		{"word":"casse-pattes","word_nosc":"casse-pattes","lemma":"casse-pattes","pos":"NOM"} ,
		{"word":"casse-pipe","word_nosc":"casse-pipe","lemma":"casse-pipe","pos":"NOM"} ,
		{"word":"casse-pipes","word_nosc":"casse-pipes","lemma":"casse-pipes","pos":"NOM"} ,
		{"word":"casse-tête","word_nosc":"casse-tete","lemma":"casse-tête","pos":"NOM"} ,
		{"word":"cassement","word_nosc":"cassement","lemma":"cassement","pos":"NOM"} ,
		{"word":"cassements","word_nosc":"cassements","lemma":"cassement","pos":"NOM"} ,
		{"word":"casserole","word_nosc":"casserole","lemma":"casserole","pos":"NOM"} ,
		{"word":"casseroles","word_nosc":"casseroles","lemma":"casserole","pos":"NOM"} ,
		{"word":"casserolée","word_nosc":"casserolee","lemma":"casserolée","pos":"NOM"} ,
		{"word":"casserolées","word_nosc":"casserolees","lemma":"casserolée","pos":"NOM"} ,
		{"word":"casses","word_nosc":"casses","lemma":"casse","pos":"NOM"} ,
		{"word":"cassetins","word_nosc":"cassetins","lemma":"cassetin","pos":"NOM"} ,
		{"word":"cassette","word_nosc":"cassette","lemma":"cassette","pos":"NOM"} ,
		{"word":"cassettes","word_nosc":"cassettes","lemma":"cassette","pos":"NOM"} ,
		{"word":"casseur","word_nosc":"casseur","lemma":"casseur","pos":"NOM"} ,
		{"word":"casseurs","word_nosc":"casseurs","lemma":"casseur","pos":"NOM"} ,
		{"word":"casseuse","word_nosc":"casseuse","lemma":"casseur","pos":"NOM"} ,
		{"word":"casseuses","word_nosc":"casseuses","lemma":"casseur","pos":"NOM"} ,
		{"word":"cassier","word_nosc":"cassier","lemma":"cassier","pos":"NOM"} ,
		{"word":"cassine","word_nosc":"cassine","lemma":"cassine","pos":"NOM"} ,
		{"word":"cassis","word_nosc":"cassis","lemma":"cassis","pos":"NOM"} ,
		{"word":"cassitérite","word_nosc":"cassiterite","lemma":"cassitérite","pos":"NOM"} ,
		{"word":"cassolette","word_nosc":"cassolette","lemma":"cassolette","pos":"NOM"} ,
		{"word":"cassolettes","word_nosc":"cassolettes","lemma":"cassolette","pos":"NOM"} ,
		{"word":"cassonade","word_nosc":"cassonade","lemma":"cassonade","pos":"NOM"} ,
		{"word":"cassons","word_nosc":"cassons","lemma":"casson","pos":"NOM"} ,
		{"word":"cassoulet","word_nosc":"cassoulet","lemma":"cassoulet","pos":"NOM"} ,
		{"word":"cassure","word_nosc":"cassure","lemma":"cassure","pos":"NOM"} ,
		{"word":"cassures","word_nosc":"cassures","lemma":"cassure","pos":"NOM"} ,
		{"word":"castagne","word_nosc":"castagne","lemma":"castagne","pos":"NOM"} ,
		{"word":"castagnette","word_nosc":"castagnette","lemma":"castagnette","pos":"NOM"} ,
		{"word":"castagnettes","word_nosc":"castagnettes","lemma":"castagnette","pos":"NOM"} ,
		{"word":"castard","word_nosc":"castard","lemma":"castard","pos":"NOM"} ,
		{"word":"caste","word_nosc":"caste","lemma":"caste","pos":"NOM"} ,
		{"word":"castel","word_nosc":"castel","lemma":"castel","pos":"NOM"} ,
		{"word":"castelet","word_nosc":"castelet","lemma":"castelet","pos":"NOM"} ,
		{"word":"castes","word_nosc":"castes","lemma":"caste","pos":"NOM"} ,
		{"word":"castillan","word_nosc":"castillan","lemma":"castillan","pos":"NOM"} ,
		{"word":"castillane","word_nosc":"castillane","lemma":"castillan","pos":"NOM"} ,
		{"word":"castillanes","word_nosc":"castillanes","lemma":"castillan","pos":"NOM"} ,
		{"word":"castillans","word_nosc":"castillans","lemma":"castillan","pos":"NOM"} ,
		{"word":"castille","word_nosc":"castille","lemma":"castille","pos":"NOM"} ,
		{"word":"castine","word_nosc":"castine","lemma":"castine","pos":"NOM"} ,
		{"word":"casting","word_nosc":"casting","lemma":"casting","pos":"NOM"} ,
		{"word":"castings","word_nosc":"castings","lemma":"casting","pos":"NOM"} ,
		{"word":"castor","word_nosc":"castor","lemma":"castor","pos":"NOM"} ,
		{"word":"castors","word_nosc":"castors","lemma":"castor","pos":"NOM"} ,
		{"word":"castrat","word_nosc":"castrat","lemma":"castrat","pos":"NOM"} ,
		{"word":"castration","word_nosc":"castration","lemma":"castration","pos":"NOM"} ,
		{"word":"castrats","word_nosc":"castrats","lemma":"castrat","pos":"NOM"} ,
		{"word":"castreur","word_nosc":"castreur","lemma":"castreur","pos":"NOM"} ,
		{"word":"castreurs","word_nosc":"castreurs","lemma":"castreur","pos":"NOM"} ,
		{"word":"castrisme","word_nosc":"castrisme","lemma":"castrisme","pos":"NOM"} ,
		{"word":"casuel","word_nosc":"casuel","lemma":"casuel","pos":"NOM"} ,
		{"word":"casuistes","word_nosc":"casuistes","lemma":"casuiste","pos":"NOM"} ,
		{"word":"casuistique","word_nosc":"casuistique","lemma":"casuistique","pos":"NOM"} ,
		{"word":"casus belli","word_nosc":"casus belli","lemma":"casus belli","pos":"NOM"} ,
		{"word":"caséine","word_nosc":"caseine","lemma":"caséine","pos":"NOM"} ,
		{"word":"cata","word_nosc":"cata","lemma":"cata","pos":"NOM"} ,
		{"word":"catachrèse","word_nosc":"catachrese","lemma":"catachrèse","pos":"NOM"} ,
		{"word":"cataclysme","word_nosc":"cataclysme","lemma":"cataclysme","pos":"NOM"} ,
		{"word":"cataclysmes","word_nosc":"cataclysmes","lemma":"cataclysme","pos":"NOM"} ,
		{"word":"catacombe","word_nosc":"catacombe","lemma":"catacombe","pos":"NOM"} ,
		{"word":"catacombes","word_nosc":"catacombes","lemma":"catacombe","pos":"NOM"} ,
		{"word":"catadioptre","word_nosc":"catadioptre","lemma":"catadioptre","pos":"NOM"} ,
		{"word":"catadioptres","word_nosc":"catadioptres","lemma":"catadioptre","pos":"NOM"} ,
		{"word":"catafalque","word_nosc":"catafalque","lemma":"catafalque","pos":"NOM"} ,
		{"word":"catafalques","word_nosc":"catafalques","lemma":"catafalque","pos":"NOM"} ,
		{"word":"catalan","word_nosc":"catalan","lemma":"catalan","pos":"NOM"} ,
		{"word":"catalanes","word_nosc":"catalanes","lemma":"catalan","pos":"NOM"} ,
		{"word":"catalans","word_nosc":"catalans","lemma":"catalan","pos":"NOM"} ,
		{"word":"catalepsie","word_nosc":"catalepsie","lemma":"catalepsie","pos":"NOM"} ,
		{"word":"catalepsies","word_nosc":"catalepsies","lemma":"catalepsie","pos":"NOM"} ,
		{"word":"cataleptique","word_nosc":"cataleptique","lemma":"cataleptique","pos":"NOM"} ,
		{"word":"cataleptiques","word_nosc":"cataleptiques","lemma":"cataleptique","pos":"NOM"} ,
		{"word":"catalogage","word_nosc":"catalogage","lemma":"catalogage","pos":"NOM"} ,
		{"word":"catalogne","word_nosc":"catalogne","lemma":"catalogne","pos":"NOM"} ,
		{"word":"catalogue","word_nosc":"catalogue","lemma":"catalogue","pos":"NOM"} ,
		{"word":"catalogues","word_nosc":"catalogues","lemma":"catalogue","pos":"NOM"} ,
		{"word":"catalpa","word_nosc":"catalpa","lemma":"catalpa","pos":"NOM"} ,
		{"word":"catalpas","word_nosc":"catalpas","lemma":"catalpa","pos":"NOM"} ,
		{"word":"catalyses","word_nosc":"catalyses","lemma":"catalyse","pos":"NOM"} ,
		{"word":"catalyseur","word_nosc":"catalyseur","lemma":"catalyseur","pos":"NOM"} ,
		{"word":"catalyseurs","word_nosc":"catalyseurs","lemma":"catalyseur","pos":"NOM"} ,
		{"word":"catamaran","word_nosc":"catamaran","lemma":"catamaran","pos":"NOM"} ,
		{"word":"cataphotes","word_nosc":"cataphotes","lemma":"cataphote","pos":"NOM"} ,
		{"word":"cataplasme","word_nosc":"cataplasme","lemma":"cataplasme","pos":"NOM"} ,
		{"word":"cataplasmes","word_nosc":"cataplasmes","lemma":"cataplasme","pos":"NOM"} ,
		{"word":"cataplexie","word_nosc":"cataplexie","lemma":"cataplexie","pos":"NOM"} ,
		{"word":"catapulte","word_nosc":"catapulte","lemma":"catapulte","pos":"NOM"} ,
		{"word":"catapultes","word_nosc":"catapultes","lemma":"catapulte","pos":"NOM"} ,
		{"word":"cataracte","word_nosc":"cataracte","lemma":"cataracte","pos":"NOM"} ,
		{"word":"cataractes","word_nosc":"cataractes","lemma":"cataracte","pos":"NOM"} ,
		{"word":"catarrhe","word_nosc":"catarrhe","lemma":"catarrhe","pos":"NOM"} ,
		{"word":"catastrophe","word_nosc":"catastrophe","lemma":"catastrophe","pos":"NOM"} ,
		{"word":"catastrophes","word_nosc":"catastrophes","lemma":"catastrophe","pos":"NOM"} ,
		{"word":"catastrophisme","word_nosc":"catastrophisme","lemma":"catastrophisme","pos":"NOM"} ,
		{"word":"catatonie","word_nosc":"catatonie","lemma":"catatonie","pos":"NOM"} ,
		{"word":"catatonique","word_nosc":"catatonique","lemma":"catatonique","pos":"NOM"} ,
		{"word":"catatoniques","word_nosc":"catatoniques","lemma":"catatonique","pos":"NOM"} ,
		{"word":"catch","word_nosc":"catch","lemma":"catch","pos":"NOM"} ,
		{"word":"catcheur","word_nosc":"catcheur","lemma":"catcheur","pos":"NOM"} ,
		{"word":"catcheurs","word_nosc":"catcheurs","lemma":"catcheur","pos":"NOM"} ,
		{"word":"catcheuse","word_nosc":"catcheuse","lemma":"catcheur","pos":"NOM"} ,
		{"word":"catcheuses","word_nosc":"catcheuses","lemma":"catcheur","pos":"NOM"} ,
		{"word":"caterpillar","word_nosc":"caterpillar","lemma":"caterpillar","pos":"NOM"} ,
		{"word":"catgut","word_nosc":"catgut","lemma":"catgut","pos":"NOM"} ,
		{"word":"catguts","word_nosc":"catguts","lemma":"catgut","pos":"NOM"} ,
		{"word":"cathare","word_nosc":"cathare","lemma":"cathare","pos":"NOM"} ,
		{"word":"cathares","word_nosc":"cathares","lemma":"cathare","pos":"NOM"} ,
		{"word":"catharsis","word_nosc":"catharsis","lemma":"catharsis","pos":"NOM"} ,
		{"word":"catherinette","word_nosc":"catherinette","lemma":"catherinette","pos":"NOM"} ,
		{"word":"cathexis","word_nosc":"cathexis","lemma":"cathexis","pos":"NOM"} ,
		{"word":"catho","word_nosc":"catho","lemma":"catho","pos":"NOM"} ,
		{"word":"cathode","word_nosc":"cathode","lemma":"cathode","pos":"NOM"} ,
		{"word":"catholicisme","word_nosc":"catholicisme","lemma":"catholicisme","pos":"NOM"} ,
		{"word":"catholicité","word_nosc":"catholicite","lemma":"catholicité","pos":"NOM"} ,
		{"word":"catholique","word_nosc":"catholique","lemma":"catholique","pos":"NOM"} ,
		{"word":"catholiques","word_nosc":"catholiques","lemma":"catholique","pos":"NOM"} ,
		{"word":"cathos","word_nosc":"cathos","lemma":"catho","pos":"NOM"} ,
		{"word":"cathèdre","word_nosc":"cathedre","lemma":"cathèdre","pos":"NOM"} ,
		{"word":"cathèdres","word_nosc":"cathedres","lemma":"cathèdre","pos":"NOM"} ,
		{"word":"cathédrale","word_nosc":"cathedrale","lemma":"cathédrale","pos":"NOM"} ,
		{"word":"cathédrales","word_nosc":"cathedrales","lemma":"cathédrale","pos":"NOM"} ,
		{"word":"cathéter","word_nosc":"catheter","lemma":"cathéter","pos":"NOM"} ,
		{"word":"cathétérisme","word_nosc":"catheterisme","lemma":"cathétérisme","pos":"NOM"} ,
		{"word":"cati","word_nosc":"cati","lemma":"cati","pos":"NOM"} ,
		{"word":"catiche","word_nosc":"catiche","lemma":"catiche","pos":"NOM"} ,
		{"word":"catin","word_nosc":"catin","lemma":"catin","pos":"NOM"} ,
		{"word":"catins","word_nosc":"catins","lemma":"catin","pos":"NOM"} ,
		{"word":"cation","word_nosc":"cation","lemma":"cation","pos":"NOM"} ,
		{"word":"cato","word_nosc":"cato","lemma":"cato","pos":"NOM"} ,
		{"word":"catoblépas","word_nosc":"catoblepas","lemma":"catoblépas","pos":"NOM"} ,
		{"word":"catogan","word_nosc":"catogan","lemma":"catogan","pos":"NOM"} ,
		{"word":"catogans","word_nosc":"catogans","lemma":"catogan","pos":"NOM"} ,
		{"word":"cattleyas","word_nosc":"cattleyas","lemma":"cattleya","pos":"NOM"} ,
		{"word":"caté","word_nosc":"cate","lemma":"caté","pos":"NOM"} ,
		{"word":"catéchisme","word_nosc":"catechisme","lemma":"catéchisme","pos":"NOM"} ,
		{"word":"catéchismes","word_nosc":"catechismes","lemma":"catéchisme","pos":"NOM"} ,
		{"word":"catéchiste","word_nosc":"catechiste","lemma":"catéchiste","pos":"NOM"} ,
		{"word":"catécholamine","word_nosc":"catecholamine","lemma":"catécholamine","pos":"NOM"} ,
		{"word":"catéchumène","word_nosc":"catechumene","lemma":"catéchumène","pos":"NOM"} ,
		{"word":"catéchumènes","word_nosc":"catechumenes","lemma":"catéchumène","pos":"NOM"} ,
		{"word":"catéchèse","word_nosc":"catechese","lemma":"catéchèse","pos":"NOM"} ,
		{"word":"catégorie","word_nosc":"categorie","lemma":"catégorie","pos":"NOM"} ,
		{"word":"catégories","word_nosc":"categories","lemma":"catégorie","pos":"NOM"} ,
		{"word":"catégorisation","word_nosc":"categorisation","lemma":"catégorisation","pos":"NOM"} ,
		{"word":"caténaire","word_nosc":"catenaire","lemma":"caténaire","pos":"NOM"} ,
		{"word":"caténaires","word_nosc":"catenaires","lemma":"caténaire","pos":"NOM"} ,
		{"word":"caucasien","word_nosc":"caucasien","lemma":"caucasien","pos":"NOM"} ,
		{"word":"caucasienne","word_nosc":"caucasienne","lemma":"caucasien","pos":"NOM"} ,
		{"word":"caucasiens","word_nosc":"caucasiens","lemma":"caucasien","pos":"NOM"} ,
		{"word":"cauchemar","word_nosc":"cauchemar","lemma":"cauchemar","pos":"NOM"} ,
		{"word":"cauchemars","word_nosc":"cauchemars","lemma":"cauchemar","pos":"NOM"} ,
		{"word":"cauchoise","word_nosc":"cauchoise","lemma":"cauchois","pos":"NOM"} ,
		{"word":"caudebec","word_nosc":"caudebec","lemma":"caudebec","pos":"NOM"} ,
		{"word":"caudillo","word_nosc":"caudillo","lemma":"caudillo","pos":"NOM"} ,
		{"word":"cauri","word_nosc":"cauri","lemma":"cauri","pos":"NOM"} ,
		{"word":"cauris","word_nosc":"cauris","lemma":"cauri","pos":"NOM"} ,
		{"word":"causa","word_nosc":"causa","lemma":"causa","pos":"NOM"} ,
		{"word":"causalité","word_nosc":"causalite","lemma":"causalité","pos":"NOM"} ,
		{"word":"causalités","word_nosc":"causalites","lemma":"causalité","pos":"NOM"} ,
		{"word":"cause","word_nosc":"cause","lemma":"cause","pos":"NOM"} ,
		{"word":"causerie","word_nosc":"causerie","lemma":"causerie","pos":"NOM"} ,
		{"word":"causeries","word_nosc":"causeries","lemma":"causerie","pos":"NOM"} ,
		{"word":"causes","word_nosc":"causes","lemma":"cause","pos":"NOM"} ,
		{"word":"causette","word_nosc":"causette","lemma":"causette","pos":"NOM"} ,
		{"word":"causettes","word_nosc":"causettes","lemma":"causette","pos":"NOM"} ,
		{"word":"causeur","word_nosc":"causeur","lemma":"causeur","pos":"NOM"} ,
		{"word":"causeurs","word_nosc":"causeurs","lemma":"causeur","pos":"NOM"} ,
		{"word":"causeuse","word_nosc":"causeuse","lemma":"causeur","pos":"NOM"} ,
		{"word":"causeuses","word_nosc":"causeuses","lemma":"causeur","pos":"NOM"} ,
		{"word":"causse","word_nosc":"causse","lemma":"causse","pos":"NOM"} ,
		{"word":"causses","word_nosc":"causses","lemma":"causse","pos":"NOM"} ,
		{"word":"causticité","word_nosc":"causticite","lemma":"causticité","pos":"NOM"} ,
		{"word":"caustique","word_nosc":"caustique","lemma":"caustique","pos":"NOM"} ,
		{"word":"caustiques","word_nosc":"caustiques","lemma":"caustique","pos":"NOM"} ,
		{"word":"caution","word_nosc":"caution","lemma":"caution","pos":"NOM"} ,
		{"word":"cautionnaire","word_nosc":"cautionnaire","lemma":"cautionnaire","pos":"NOM"} ,
		{"word":"cautionnement","word_nosc":"cautionnement","lemma":"cautionnement","pos":"NOM"} ,
		{"word":"cautions","word_nosc":"cautions","lemma":"caution","pos":"NOM"} ,
		{"word":"cautèle","word_nosc":"cautele","lemma":"cautèle","pos":"NOM"} ,
		{"word":"cautèles","word_nosc":"cauteles","lemma":"cautèle","pos":"NOM"} ,
		{"word":"cautère","word_nosc":"cautere","lemma":"cautère","pos":"NOM"} ,
		{"word":"cautères","word_nosc":"cauteres","lemma":"cautère","pos":"NOM"} ,
		{"word":"cautérisation","word_nosc":"cauterisation","lemma":"cautérisation","pos":"NOM"} ,
		{"word":"cavaillon","word_nosc":"cavaillon","lemma":"cavaillon","pos":"NOM"} ,
		{"word":"cavalcade","word_nosc":"cavalcade","lemma":"cavalcade","pos":"NOM"} ,
		{"word":"cavalcades","word_nosc":"cavalcades","lemma":"cavalcade","pos":"NOM"} ,
		{"word":"cavale","word_nosc":"cavale","lemma":"cavale","pos":"NOM"} ,
		{"word":"cavalerie","word_nosc":"cavalerie","lemma":"cavalerie","pos":"NOM"} ,
		{"word":"cavaleries","word_nosc":"cavaleries","lemma":"cavalerie","pos":"NOM"} ,
		{"word":"cavales","word_nosc":"cavales","lemma":"cavale","pos":"NOM"} ,
		{"word":"cavaleur","word_nosc":"cavaleur","lemma":"cavaleur","pos":"NOM"} ,
		{"word":"cavaleurs","word_nosc":"cavaleurs","lemma":"cavaleur","pos":"NOM"} ,
		{"word":"cavaleuse","word_nosc":"cavaleuse","lemma":"cavaleur","pos":"NOM"} ,
		{"word":"cavalier","word_nosc":"cavalier","lemma":"cavalier","pos":"NOM"} ,
		{"word":"cavaliers","word_nosc":"cavaliers","lemma":"cavalier","pos":"NOM"} ,
		{"word":"cavalière","word_nosc":"cavaliere","lemma":"cavalier","pos":"NOM"} ,
		{"word":"cavalières","word_nosc":"cavalieres","lemma":"cavalier","pos":"NOM"} ,
		{"word":"cave","word_nosc":"cave","lemma":"cave","pos":"NOM"} ,
		{"word":"caveau","word_nosc":"caveau","lemma":"caveau","pos":"NOM"} ,
		{"word":"caveaux","word_nosc":"caveaux","lemma":"caveau","pos":"NOM"} ,
		{"word":"caverne","word_nosc":"caverne","lemma":"caverne","pos":"NOM"} ,
		{"word":"cavernes","word_nosc":"cavernes","lemma":"caverne","pos":"NOM"} ,
		{"word":"caves","word_nosc":"caves","lemma":"cave","pos":"NOM"} ,
		{"word":"cavette","word_nosc":"cavette","lemma":"cavette","pos":"NOM"} ,
		{"word":"cavettes","word_nosc":"cavettes","lemma":"cavette","pos":"NOM"} ,
		{"word":"caviar","word_nosc":"caviar","lemma":"caviar","pos":"NOM"} ,
		{"word":"cavillon","word_nosc":"cavillon","lemma":"cavillon","pos":"NOM"} ,
		{"word":"cavillonne","word_nosc":"cavillonne","lemma":"cavillon","pos":"NOM"} ,
		{"word":"cavillons","word_nosc":"cavillons","lemma":"cavillon","pos":"NOM"} ,
		{"word":"caviste","word_nosc":"caviste","lemma":"caviste","pos":"NOM"} ,
		{"word":"cavitation","word_nosc":"cavitation","lemma":"cavitation","pos":"NOM"} ,
		{"word":"cavité","word_nosc":"cavite","lemma":"cavité","pos":"NOM"} ,
		{"word":"cavités","word_nosc":"cavites","lemma":"cavité","pos":"NOM"} ,
		{"word":"cavum","word_nosc":"cavum","lemma":"cavum","pos":"NOM"} ,
		{"word":"cavée","word_nosc":"cavee","lemma":"cavée","pos":"NOM"} ,
		{"word":"cavées","word_nosc":"cavees","lemma":"cavée","pos":"NOM"} ,
		{"word":"cayenne","word_nosc":"cayenne","lemma":"cayenne","pos":"NOM"} ,
		{"word":"caïd","word_nosc":"caid","lemma":"caïd","pos":"NOM"} ,
		{"word":"caïds","word_nosc":"caids","lemma":"caïd","pos":"NOM"} ,
		{"word":"caïman","word_nosc":"caiman","lemma":"caïman","pos":"NOM"} ,
		{"word":"caïmans","word_nosc":"caimans","lemma":"caïman","pos":"NOM"} ,
		{"word":"caïque","word_nosc":"caique","lemma":"caïque","pos":"NOM"} ,
		{"word":"caïques","word_nosc":"caiques","lemma":"caïque","pos":"NOM"} ,
		{"word":"cañon","word_nosc":"canon","lemma":"cañon","pos":"NOM"} ,
		{"word":"ceinture","word_nosc":"ceinture","lemma":"ceinture","pos":"NOM"} ,
		{"word":"ceintures","word_nosc":"ceintures","lemma":"ceinture","pos":"NOM"} ,
		{"word":"ceinturon","word_nosc":"ceinturon","lemma":"ceinturon","pos":"NOM"} ,
		{"word":"ceinturons","word_nosc":"ceinturons","lemma":"ceinturon","pos":"NOM"} ,
		{"word":"cela","word_nosc":"cela","lemma":"cela","pos":"NOM"} ,
		{"word":"cella","word_nosc":"cella","lemma":"cella","pos":"NOM"} ,
		{"word":"celle","word_nosc":"celle","lemma":"celle","pos":"NOM"} ,
		{"word":"cellier","word_nosc":"cellier","lemma":"cellier","pos":"NOM"} ,
		{"word":"celliers","word_nosc":"celliers","lemma":"cellier","pos":"NOM"} ,
		{"word":"cellophane","word_nosc":"cellophane","lemma":"cellophane","pos":"NOM"} ,
		{"word":"cellular","word_nosc":"cellular","lemma":"cellular","pos":"NOM"} ,
		{"word":"cellule","word_nosc":"cellule","lemma":"cellule","pos":"NOM"} ,
		{"word":"cellules","word_nosc":"cellules","lemma":"cellule","pos":"NOM"} ,
		{"word":"cellulite","word_nosc":"cellulite","lemma":"cellulite","pos":"NOM"} ,
		{"word":"cellulites","word_nosc":"cellulites","lemma":"cellulite","pos":"NOM"} ,
		{"word":"cellulose","word_nosc":"cellulose","lemma":"cellulose","pos":"NOM"} ,
		{"word":"celluloïd","word_nosc":"celluloid","lemma":"celluloïd","pos":"NOM"} ,
		{"word":"celte","word_nosc":"celte","lemma":"celte","pos":"NOM"} ,
		{"word":"celtes","word_nosc":"celtes","lemma":"celte","pos":"NOM"} ,
		{"word":"celtiques","word_nosc":"celtiques","lemma":"celtique","pos":"NOM"} ,
		{"word":"celtisme","word_nosc":"celtisme","lemma":"celtisme","pos":"NOM"} ,
		{"word":"cendre","word_nosc":"cendre","lemma":"cendre","pos":"NOM"} ,
		{"word":"cendres","word_nosc":"cendres","lemma":"cendre","pos":"NOM"} ,
		{"word":"cendrier","word_nosc":"cendrier","lemma":"cendrier","pos":"NOM"} ,
		{"word":"cendriers","word_nosc":"cendriers","lemma":"cendrier","pos":"NOM"} ,
		{"word":"cendrillon","word_nosc":"cendrillon","lemma":"cendrillon","pos":"NOM"} ,
		{"word":"cendrillons","word_nosc":"cendrillons","lemma":"cendrillon","pos":"NOM"} ,
		{"word":"cendrée","word_nosc":"cendree","lemma":"cendrée","pos":"NOM"} ,
		{"word":"cendrées","word_nosc":"cendrees","lemma":"cendrée","pos":"NOM"} ,
		{"word":"cens","word_nosc":"cens","lemma":"cens","pos":"NOM"} ,
		{"word":"censeur","word_nosc":"censeur","lemma":"censeur","pos":"NOM"} ,
		{"word":"censeurs","word_nosc":"censeurs","lemma":"censeur","pos":"NOM"} ,
		{"word":"censier","word_nosc":"censier","lemma":"censier","pos":"NOM"} ,
		{"word":"censure","word_nosc":"censure","lemma":"censure","pos":"NOM"} ,
		{"word":"censures","word_nosc":"censures","lemma":"censure","pos":"NOM"} ,
		{"word":"cent","word_nosc":"cent","lemma":"cent","pos":"NOM"} ,
		{"word":"centaine","word_nosc":"centaine","lemma":"centaine","pos":"NOM"} ,
		{"word":"centaines","word_nosc":"centaines","lemma":"centaine","pos":"NOM"} ,
		{"word":"centaure","word_nosc":"centaure","lemma":"centaure","pos":"NOM"} ,
		{"word":"centaures","word_nosc":"centaures","lemma":"centaure","pos":"NOM"} ,
		{"word":"centaurée","word_nosc":"centauree","lemma":"centaurée","pos":"NOM"} ,
		{"word":"centavo","word_nosc":"centavo","lemma":"centavo","pos":"NOM"} ,
		{"word":"centavos","word_nosc":"centavos","lemma":"centavo","pos":"NOM"} ,
		{"word":"centenaire","word_nosc":"centenaire","lemma":"centenaire","pos":"NOM"} ,
		{"word":"centenaires","word_nosc":"centenaires","lemma":"centenaire","pos":"NOM"} ,
		{"word":"centigrade","word_nosc":"centigrade","lemma":"centigrade","pos":"NOM"} ,
		{"word":"centigrades","word_nosc":"centigrades","lemma":"centigrade","pos":"NOM"} ,
		{"word":"centigrammes","word_nosc":"centigrammes","lemma":"centigramme","pos":"NOM"} ,
		{"word":"centile","word_nosc":"centile","lemma":"centile","pos":"NOM"} ,
		{"word":"centilitres","word_nosc":"centilitres","lemma":"centilitre","pos":"NOM"} ,
		{"word":"centime","word_nosc":"centime","lemma":"centime","pos":"NOM"} ,
		{"word":"centimes","word_nosc":"centimes","lemma":"centime","pos":"NOM"} ,
		{"word":"centimètre","word_nosc":"centimetre","lemma":"centimètre","pos":"NOM"} ,
		{"word":"centimètres","word_nosc":"centimetres","lemma":"centimètre","pos":"NOM"} ,
		{"word":"centième","word_nosc":"centieme","lemma":"centième","pos":"NOM"} ,
		{"word":"centièmes","word_nosc":"centiemes","lemma":"centième","pos":"NOM"} ,
		{"word":"centon","word_nosc":"centon","lemma":"centon","pos":"NOM"} ,
		{"word":"centrage","word_nosc":"centrage","lemma":"centrage","pos":"NOM"} ,
		{"word":"central","word_nosc":"central","lemma":"central","pos":"NOM"} ,
		{"word":"centrale","word_nosc":"centrale","lemma":"centrale","pos":"NOM"} ,
		{"word":"centrales","word_nosc":"centrales","lemma":"centrale","pos":"NOM"} ,
		{"word":"centralisation","word_nosc":"centralisation","lemma":"centralisation","pos":"NOM"} ,
		{"word":"centralisme","word_nosc":"centralisme","lemma":"centralisme","pos":"NOM"} ,
		{"word":"centraux","word_nosc":"centraux","lemma":"central","pos":"NOM"} ,
		{"word":"centre","word_nosc":"centre","lemma":"centre","pos":"NOM"} ,
		{"word":"centre-ville","word_nosc":"centre-ville","lemma":"centre-ville","pos":"NOM"} ,
		{"word":"centres","word_nosc":"centres","lemma":"centre","pos":"NOM"} ,
		{"word":"centrifugation","word_nosc":"centrifugation","lemma":"centrifugation","pos":"NOM"} ,
		{"word":"centrifugeur","word_nosc":"centrifugeur","lemma":"centrifugeur","pos":"NOM"} ,
		{"word":"centrifugeuse","word_nosc":"centrifugeuse","lemma":"centrifugeur","pos":"NOM"} ,
		{"word":"centrifugeuses","word_nosc":"centrifugeuses","lemma":"centrifugeur","pos":"NOM"} ,
		{"word":"centriole","word_nosc":"centriole","lemma":"centriole","pos":"NOM"} ,
		{"word":"centriste","word_nosc":"centriste","lemma":"centriste","pos":"NOM"} ,
		{"word":"centrouse","word_nosc":"centrouse","lemma":"centrouse","pos":"NOM"} ,
		{"word":"centrum","word_nosc":"centrum","lemma":"centrum","pos":"NOM"} ,
		{"word":"centuple","word_nosc":"centuple","lemma":"centuple","pos":"NOM"} ,
		{"word":"centurie","word_nosc":"centurie","lemma":"centurie","pos":"NOM"} ,
		{"word":"centuries","word_nosc":"centuries","lemma":"centurie","pos":"NOM"} ,
		{"word":"centurion","word_nosc":"centurion","lemma":"centurion","pos":"NOM"} ,
		{"word":"centurions","word_nosc":"centurions","lemma":"centurion","pos":"NOM"} ,
		{"word":"cep","word_nosc":"cep","lemma":"cep","pos":"NOM"} ,
		{"word":"ceps","word_nosc":"ceps","lemma":"cep","pos":"NOM"} ,
		{"word":"cerbère","word_nosc":"cerbere","lemma":"cerbère","pos":"NOM"} ,
		{"word":"cerbères","word_nosc":"cerberes","lemma":"cerbère","pos":"NOM"} ,
		{"word":"cerceau","word_nosc":"cerceau","lemma":"cerceau","pos":"NOM"} ,
		{"word":"cerceaux","word_nosc":"cerceaux","lemma":"cerceau","pos":"NOM"} ,
		{"word":"cerclage","word_nosc":"cerclage","lemma":"cerclage","pos":"NOM"} ,
		{"word":"cercle","word_nosc":"cercle","lemma":"cercle","pos":"NOM"} ,
		{"word":"cercles","word_nosc":"cercles","lemma":"cercle","pos":"NOM"} ,
		{"word":"cercleux","word_nosc":"cercleux","lemma":"cercleux","pos":"NOM"} ,
		{"word":"cercueil","word_nosc":"cercueil","lemma":"cercueil","pos":"NOM"} ,
		{"word":"cercueils","word_nosc":"cercueils","lemma":"cercueil","pos":"NOM"} ,
		{"word":"cerf","word_nosc":"cerf","lemma":"cerf","pos":"NOM"} ,
		{"word":"cerf-roi","word_nosc":"cerf-roi","lemma":"cerf-roi","pos":"NOM"} ,
		{"word":"cerf-volant","word_nosc":"cerf-volant","lemma":"cerf-volant","pos":"NOM"} ,
		{"word":"cerfeuil","word_nosc":"cerfeuil","lemma":"cerfeuil","pos":"NOM"} ,
		{"word":"cerfs","word_nosc":"cerfs","lemma":"cerf","pos":"NOM"} ,
		{"word":"cerfs-volants","word_nosc":"cerfs-volants","lemma":"cerf-volant","pos":"NOM"} ,
		{"word":"cerisaie","word_nosc":"cerisaie","lemma":"cerisaie","pos":"NOM"} ,
		{"word":"cerise","word_nosc":"cerise","lemma":"cerise","pos":"NOM"} ,
		{"word":"cerises","word_nosc":"cerises","lemma":"cerise","pos":"NOM"} ,
		{"word":"cerisier","word_nosc":"cerisier","lemma":"cerisier","pos":"NOM"} ,
		{"word":"cerisiers","word_nosc":"cerisiers","lemma":"cerisier","pos":"NOM"} ,
		{"word":"cerne","word_nosc":"cerne","lemma":"cerne","pos":"NOM"} ,
		{"word":"cerneau","word_nosc":"cerneau","lemma":"cerneau","pos":"NOM"} ,
		{"word":"cerneaux","word_nosc":"cerneaux","lemma":"cerneau","pos":"NOM"} ,
		{"word":"cernes","word_nosc":"cernes","lemma":"cerne","pos":"NOM"} ,
		{"word":"cerque","word_nosc":"cerque","lemma":"cerque","pos":"NOM"} ,
		{"word":"certif","word_nosc":"certif","lemma":"certif","pos":"NOM"} ,
		{"word":"certificat","word_nosc":"certificat","lemma":"certificat","pos":"NOM"} ,
		{"word":"certification","word_nosc":"certification","lemma":"certification","pos":"NOM"} ,
		{"word":"certificats","word_nosc":"certificats","lemma":"certificat","pos":"NOM"} ,
		{"word":"certifs","word_nosc":"certifs","lemma":"certif","pos":"NOM"} ,
		{"word":"certitude","word_nosc":"certitude","lemma":"certitude","pos":"NOM"} ,
		{"word":"certitudes","word_nosc":"certitudes","lemma":"certitude","pos":"NOM"} ,
		{"word":"cerveau","word_nosc":"cerveau","lemma":"cerveau","pos":"NOM"} ,
		{"word":"cerveaux","word_nosc":"cerveaux","lemma":"cerveau","pos":"NOM"} ,
		{"word":"cervelas","word_nosc":"cervelas","lemma":"cervelas","pos":"NOM"} ,
		{"word":"cervelet","word_nosc":"cervelet","lemma":"cervelet","pos":"NOM"} ,
		{"word":"cervelets","word_nosc":"cervelets","lemma":"cervelet","pos":"NOM"} ,
		{"word":"cervelle","word_nosc":"cervelle","lemma":"cervelle","pos":"NOM"} ,
		{"word":"cervelles","word_nosc":"cervelles","lemma":"cervelle","pos":"NOM"} ,
		{"word":"cervidés","word_nosc":"cervides","lemma":"cervidé","pos":"NOM"} ,
		{"word":"cervoise","word_nosc":"cervoise","lemma":"cervoise","pos":"NOM"} ,
		{"word":"cessation","word_nosc":"cessation","lemma":"cessation","pos":"NOM"} ,
		{"word":"cesse","word_nosc":"cesse","lemma":"cesse","pos":"NOM"} ,
		{"word":"cesses","word_nosc":"cesses","lemma":"cesse","pos":"NOM"} ,
		{"word":"cessez-le-feu","word_nosc":"cessez-le-feu","lemma":"cessez-le-feu","pos":"NOM"} ,
		{"word":"cession","word_nosc":"cession","lemma":"cession","pos":"NOM"} ,
		{"word":"cessionnaire","word_nosc":"cessionnaire","lemma":"cessionnaire","pos":"NOM"} ,
		{"word":"cessions","word_nosc":"cessions","lemma":"cession","pos":"NOM"} ,
		{"word":"ceste","word_nosc":"ceste","lemma":"ceste","pos":"NOM"} ,
		{"word":"ch'timi","word_nosc":"ch'timi","lemma":"ch'timi","pos":"NOM"} ,
		{"word":"ch'timis","word_nosc":"ch'timis","lemma":"ch'timi","pos":"NOM"} ,
		{"word":"cha-cha-cha","word_nosc":"cha-cha-cha","lemma":"cha-cha-cha","pos":"NOM"} ,
		{"word":"chabanais","word_nosc":"chabanais","lemma":"chabanais","pos":"NOM"} ,
		{"word":"chabichou","word_nosc":"chabichou","lemma":"chabichou","pos":"NOM"} ,
		{"word":"chablis","word_nosc":"chablis","lemma":"chablis","pos":"NOM"} ,
		{"word":"chaboisseaux","word_nosc":"chaboisseaux","lemma":"chaboisseau","pos":"NOM"} ,
		{"word":"chabot","word_nosc":"chabot","lemma":"chabot","pos":"NOM"} ,
		{"word":"chabots","word_nosc":"chabots","lemma":"chabot","pos":"NOM"} ,
		{"word":"chabraque","word_nosc":"chabraque","lemma":"chabraque","pos":"NOM"} ,
		{"word":"chabrot","word_nosc":"chabrot","lemma":"chabrot","pos":"NOM"} ,
		{"word":"chacal","word_nosc":"chacal","lemma":"chacal","pos":"NOM"} ,
		{"word":"chacals","word_nosc":"chacals","lemma":"chacal","pos":"NOM"} ,
		{"word":"chachlik","word_nosc":"chachlik","lemma":"chachlik","pos":"NOM"} ,
		{"word":"chaconne","word_nosc":"chaconne","lemma":"chaconne","pos":"NOM"} ,
		{"word":"chafaud","word_nosc":"chafaud","lemma":"chafaud","pos":"NOM"} ,
		{"word":"chafauds","word_nosc":"chafauds","lemma":"chafaud","pos":"NOM"} ,
		{"word":"chafouin","word_nosc":"chafouin","lemma":"chafouin","pos":"NOM"} ,
		{"word":"chafouins","word_nosc":"chafouins","lemma":"chafouin","pos":"NOM"} ,
		{"word":"chagatte","word_nosc":"chagatte","lemma":"chagatte","pos":"NOM"} ,
		{"word":"chagattes","word_nosc":"chagattes","lemma":"chagatte","pos":"NOM"} ,
		{"word":"chagrin","word_nosc":"chagrin","lemma":"chagrin","pos":"NOM"} ,
		{"word":"chagrins","word_nosc":"chagrins","lemma":"chagrin","pos":"NOM"} ,
		{"word":"chah","word_nosc":"chah","lemma":"chah","pos":"NOM"} ,
		{"word":"chahut","word_nosc":"chahut","lemma":"chahut","pos":"NOM"} ,
		{"word":"chahuteur","word_nosc":"chahuteur","lemma":"chahuteur","pos":"NOM"} ,
		{"word":"chahuteurs","word_nosc":"chahuteurs","lemma":"chahuteur","pos":"NOM"} ,
		{"word":"chahuteuse","word_nosc":"chahuteuse","lemma":"chahuteur","pos":"NOM"} ,
		{"word":"chahuts","word_nosc":"chahuts","lemma":"chahut","pos":"NOM"} ,
		{"word":"chai","word_nosc":"chai","lemma":"chai","pos":"NOM"} ,
		{"word":"chair","word_nosc":"chair","lemma":"chair","pos":"NOM"} ,
		{"word":"chaire","word_nosc":"chaire","lemma":"chaire","pos":"NOM"} ,
		{"word":"chaires","word_nosc":"chaires","lemma":"chaire","pos":"NOM"} ,
		{"word":"chairman","word_nosc":"chairman","lemma":"chairman","pos":"NOM"} ,
		{"word":"chairs","word_nosc":"chairs","lemma":"chair","pos":"NOM"} ,
		{"word":"chais","word_nosc":"chais","lemma":"chai","pos":"NOM"} ,
		{"word":"chaise","word_nosc":"chaise","lemma":"chaise","pos":"NOM"} ,
		{"word":"chaises","word_nosc":"chaises","lemma":"chaise","pos":"NOM"} ,
		{"word":"chaisière","word_nosc":"chaisiere","lemma":"chaisier","pos":"NOM"} ,
		{"word":"chaisières","word_nosc":"chaisieres","lemma":"chaisier","pos":"NOM"} ,
		{"word":"chaix","word_nosc":"chaix","lemma":"chaix","pos":"NOM"} ,
		{"word":"chaland","word_nosc":"chaland","lemma":"chaland","pos":"NOM"} ,
		{"word":"chalands","word_nosc":"chalands","lemma":"chaland","pos":"NOM"} ,
		{"word":"chalazions","word_nosc":"chalazions","lemma":"chalazion","pos":"NOM"} ,
		{"word":"chaldéen","word_nosc":"chaldeen","lemma":"chaldéen","pos":"NOM"} ,
		{"word":"chaldéens","word_nosc":"chaldeens","lemma":"chaldéen","pos":"NOM"} ,
		{"word":"chalet","word_nosc":"chalet","lemma":"chalet","pos":"NOM"} ,
		{"word":"chalets","word_nosc":"chalets","lemma":"chalet","pos":"NOM"} ,
		{"word":"chaleur","word_nosc":"chaleur","lemma":"chaleur","pos":"NOM"} ,
		{"word":"chaleurs","word_nosc":"chaleurs","lemma":"chaleur","pos":"NOM"} ,
		{"word":"challenge","word_nosc":"challenge","lemma":"challenge","pos":"NOM"} ,
		{"word":"challenger","word_nosc":"challenger","lemma":"challenger","pos":"NOM"} ,
		{"word":"challengers","word_nosc":"challengers","lemma":"challenger","pos":"NOM"} ,
		{"word":"challenges","word_nosc":"challenges","lemma":"challenge","pos":"NOM"} ,
		{"word":"challengeur","word_nosc":"challengeur","lemma":"challengeur","pos":"NOM"} ,
		{"word":"chalon","word_nosc":"chalon","lemma":"chalon","pos":"NOM"} ,
		{"word":"chalons","word_nosc":"chalons","lemma":"chalon","pos":"NOM"} ,
		{"word":"chaloupe","word_nosc":"chaloupe","lemma":"chaloupe","pos":"NOM"} ,
		{"word":"chaloupes","word_nosc":"chaloupes","lemma":"chaloupe","pos":"NOM"} ,
		{"word":"chalumeau","word_nosc":"chalumeau","lemma":"chalumeau","pos":"NOM"} ,
		{"word":"chalumeaux","word_nosc":"chalumeaux","lemma":"chalumeau","pos":"NOM"} ,
		{"word":"chalut","word_nosc":"chalut","lemma":"chalut","pos":"NOM"} ,
		{"word":"chalutier","word_nosc":"chalutier","lemma":"chalutier","pos":"NOM"} ,
		{"word":"chalutier-patrouilleur","word_nosc":"chalutier-patrouilleur","lemma":"chalutier-patrouilleur","pos":"NOM"} ,
		{"word":"chalutiers","word_nosc":"chalutiers","lemma":"chalutier","pos":"NOM"} ,
		{"word":"chaluts","word_nosc":"chaluts","lemma":"chalut","pos":"NOM"} ,
		{"word":"chamade","word_nosc":"chamade","lemma":"chamade","pos":"NOM"} ,
		{"word":"chamaille","word_nosc":"chamaille","lemma":"chamaille","pos":"NOM"} ,
		{"word":"chamaillerie","word_nosc":"chamaillerie","lemma":"chamaillerie","pos":"NOM"} ,
		{"word":"chamailleries","word_nosc":"chamailleries","lemma":"chamaillerie","pos":"NOM"} ,
		{"word":"chamaillis","word_nosc":"chamaillis","lemma":"chamaillis","pos":"NOM"} ,
		{"word":"chaman","word_nosc":"chaman","lemma":"chaman","pos":"NOM"} ,
		{"word":"chamane","word_nosc":"chamane","lemma":"chamane","pos":"NOM"} ,
		{"word":"chamanes","word_nosc":"chamanes","lemma":"chamane","pos":"NOM"} ,
		{"word":"chamanisme","word_nosc":"chamanisme","lemma":"chamanisme","pos":"NOM"} ,
		{"word":"chamans","word_nosc":"chamans","lemma":"chaman","pos":"NOM"} ,
		{"word":"chamarrures","word_nosc":"chamarrures","lemma":"chamarrure","pos":"NOM"} ,
		{"word":"chambard","word_nosc":"chambard","lemma":"chambard","pos":"NOM"} ,
		{"word":"chambardement","word_nosc":"chambardement","lemma":"chambardement","pos":"NOM"} ,
		{"word":"chambardements","word_nosc":"chambardements","lemma":"chambardement","pos":"NOM"} ,
		{"word":"chambellan","word_nosc":"chambellan","lemma":"chambellan","pos":"NOM"} ,
		{"word":"chambellans","word_nosc":"chambellans","lemma":"chambellan","pos":"NOM"} ,
		{"word":"chambertin","word_nosc":"chambertin","lemma":"chambertin","pos":"NOM"} ,
		{"word":"chambertins","word_nosc":"chambertins","lemma":"chambertin","pos":"NOM"} ,
		{"word":"chamboulement","word_nosc":"chamboulement","lemma":"chamboulement","pos":"NOM"} ,
		{"word":"chamboulements","word_nosc":"chamboulements","lemma":"chamboulement","pos":"NOM"} ,
		{"word":"chambranle","word_nosc":"chambranle","lemma":"chambranle","pos":"NOM"} ,
		{"word":"chambranles","word_nosc":"chambranles","lemma":"chambranle","pos":"NOM"} ,
		{"word":"chambre","word_nosc":"chambre","lemma":"chambre","pos":"NOM"} ,
		{"word":"chambre-salon","word_nosc":"chambre-salon","lemma":"chambre-salon","pos":"NOM"} ,
		{"word":"chambres","word_nosc":"chambres","lemma":"chambre","pos":"NOM"} ,
		{"word":"chambrette","word_nosc":"chambrette","lemma":"chambrette","pos":"NOM"} ,
		{"word":"chambrière","word_nosc":"chambriere","lemma":"chambrière","pos":"NOM"} ,
		{"word":"chambrières","word_nosc":"chambrieres","lemma":"chambrière","pos":"NOM"} ,
		{"word":"chambrée","word_nosc":"chambree","lemma":"chambrée","pos":"NOM"} ,
		{"word":"chambrées","word_nosc":"chambrees","lemma":"chambrée","pos":"NOM"} ,
		{"word":"chameau","word_nosc":"chameau","lemma":"chameau","pos":"NOM"} ,
		{"word":"chameaux","word_nosc":"chameaux","lemma":"chameau","pos":"NOM"} ,
		{"word":"chamelier","word_nosc":"chamelier","lemma":"chamelier","pos":"NOM"} ,
		{"word":"chameliers","word_nosc":"chameliers","lemma":"chamelier","pos":"NOM"} ,
		{"word":"chamelle","word_nosc":"chamelle","lemma":"chameau","pos":"NOM"} ,
		{"word":"chamelles","word_nosc":"chamelles","lemma":"chameau","pos":"NOM"} ,
		{"word":"chamois","word_nosc":"chamois","lemma":"chamois","pos":"NOM"} ,
		{"word":"chamoisine","word_nosc":"chamoisine","lemma":"chamoisine","pos":"NOM"} ,
		{"word":"champ","word_nosc":"champ","lemma":"champ","pos":"NOM"} ,
		{"word":"champ'","word_nosc":"champ'","lemma":"champ","pos":"NOM"} ,
		{"word":"champagne","word_nosc":"champagne","lemma":"champagne","pos":"NOM"} ,
		{"word":"champagnes","word_nosc":"champagnes","lemma":"champagne","pos":"NOM"} ,
		{"word":"champenois","word_nosc":"champenois","lemma":"champenois","pos":"NOM"} ,
		{"word":"champenoises","word_nosc":"champenoises","lemma":"champenois","pos":"NOM"} ,
		{"word":"champi","word_nosc":"champi","lemma":"champi","pos":"NOM"} ,
		{"word":"champignon","word_nosc":"champignon","lemma":"champignon","pos":"NOM"} ,
		{"word":"champignonnière","word_nosc":"champignonniere","lemma":"champignonnière","pos":"NOM"} ,
		{"word":"champignonnières","word_nosc":"champignonnieres","lemma":"champignonnière","pos":"NOM"} ,
		{"word":"champignons","word_nosc":"champignons","lemma":"champignon","pos":"NOM"} ,
		{"word":"champion","word_nosc":"champion","lemma":"champion","pos":"NOM"} ,
		{"word":"championnat","word_nosc":"championnat","lemma":"championnat","pos":"NOM"} ,
		{"word":"championnats","word_nosc":"championnats","lemma":"championnat","pos":"NOM"} ,
		{"word":"championne","word_nosc":"championne","lemma":"champion","pos":"NOM"} ,
		{"word":"championnes","word_nosc":"championnes","lemma":"champion","pos":"NOM"} ,
		{"word":"champions","word_nosc":"champions","lemma":"champion","pos":"NOM"} ,
		{"word":"champis","word_nosc":"champis","lemma":"champi","pos":"NOM"} ,
		{"word":"champs","word_nosc":"champs","lemma":"champ","pos":"NOM"} ,
		{"word":"champs élysées","word_nosc":"champs elysees","lemma":"champs élysées","pos":"NOM"} ,
		{"word":"chance","word_nosc":"chance","lemma":"chance","pos":"NOM"} ,
		{"word":"chancel","word_nosc":"chancel","lemma":"chancel","pos":"NOM"} ,
		{"word":"chancelier","word_nosc":"chancelier","lemma":"chancelier","pos":"NOM"} ,
		{"word":"chanceliers","word_nosc":"chanceliers","lemma":"chancelier","pos":"NOM"} ,
		{"word":"chancelière","word_nosc":"chanceliere","lemma":"chancelière","pos":"NOM"} ,
		{"word":"chancelières","word_nosc":"chancelieres","lemma":"chancelier","pos":"NOM"} ,
		{"word":"chancellerie","word_nosc":"chancellerie","lemma":"chancellerie","pos":"NOM"} ,
		{"word":"chancelleries","word_nosc":"chancelleries","lemma":"chancellerie","pos":"NOM"} ,
		{"word":"chances","word_nosc":"chances","lemma":"chance","pos":"NOM"} ,
		{"word":"chanci","word_nosc":"chanci","lemma":"chanci","pos":"NOM"} ,
		{"word":"chancre","word_nosc":"chancre","lemma":"chancre","pos":"NOM"} ,
		{"word":"chancres","word_nosc":"chancres","lemma":"chancre","pos":"NOM"} ,
		{"word":"chand","word_nosc":"chand","lemma":"chand","pos":"NOM"} ,
		{"word":"chandail","word_nosc":"chandail","lemma":"chandail","pos":"NOM"} ,
		{"word":"chandails","word_nosc":"chandails","lemma":"chandail","pos":"NOM"} ,
		{"word":"chandeleur","word_nosc":"chandeleur","lemma":"chandeleur","pos":"NOM"} ,
		{"word":"chandelier","word_nosc":"chandelier","lemma":"chandelier","pos":"NOM"} ,
		{"word":"chandeliers","word_nosc":"chandeliers","lemma":"chandelier","pos":"NOM"} ,
		{"word":"chandelle","word_nosc":"chandelle","lemma":"chandelle","pos":"NOM"} ,
		{"word":"chandelles","word_nosc":"chandelles","lemma":"chandelle","pos":"NOM"} ,
		{"word":"chanfrein","word_nosc":"chanfrein","lemma":"chanfrein","pos":"NOM"} ,
		{"word":"change","word_nosc":"change","lemma":"change","pos":"NOM"} ,
		{"word":"changement","word_nosc":"changement","lemma":"changement","pos":"NOM"} ,
		{"word":"changements","word_nosc":"changements","lemma":"changement","pos":"NOM"} ,
		{"word":"changes","word_nosc":"changes","lemma":"change","pos":"NOM"} ,
		{"word":"changeur","word_nosc":"changeur","lemma":"changeur","pos":"NOM"} ,
		{"word":"changeurs","word_nosc":"changeurs","lemma":"changeur","pos":"NOM"} ,
		{"word":"changeuse","word_nosc":"changeuse","lemma":"changeur","pos":"NOM"} ,
		{"word":"chanoine","word_nosc":"chanoine","lemma":"chanoine","pos":"NOM"} ,
		{"word":"chanoines","word_nosc":"chanoines","lemma":"chanoine","pos":"NOM"} ,
		{"word":"chanoinesse","word_nosc":"chanoinesse","lemma":"chanoinesse","pos":"NOM"} ,
		{"word":"chanoinesses","word_nosc":"chanoinesses","lemma":"chanoinesse","pos":"NOM"} ,
		{"word":"chanson","word_nosc":"chanson","lemma":"chanson","pos":"NOM"} ,
		{"word":"chansonnette","word_nosc":"chansonnette","lemma":"chansonnette","pos":"NOM"} ,
		{"word":"chansonnettes","word_nosc":"chansonnettes","lemma":"chansonnette","pos":"NOM"} ,
		{"word":"chansonnier","word_nosc":"chansonnier","lemma":"chansonnier","pos":"NOM"} ,
		{"word":"chansonniers","word_nosc":"chansonniers","lemma":"chansonnier","pos":"NOM"} ,
		{"word":"chansons","word_nosc":"chansons","lemma":"chanson","pos":"NOM"} ,
		{"word":"chant","word_nosc":"chant","lemma":"chant","pos":"NOM"} ,
		{"word":"chantage","word_nosc":"chantage","lemma":"chantage","pos":"NOM"} ,
		{"word":"chantages","word_nosc":"chantages","lemma":"chantage","pos":"NOM"} ,
		{"word":"chanteau","word_nosc":"chanteau","lemma":"chanteau","pos":"NOM"} ,
		{"word":"chanterelle","word_nosc":"chanterelle","lemma":"chanterelle","pos":"NOM"} ,
		{"word":"chanterelles","word_nosc":"chanterelles","lemma":"chanterelle","pos":"NOM"} ,
		{"word":"chanteur","word_nosc":"chanteur","lemma":"chanteur","pos":"NOM"} ,
		{"word":"chanteurs","word_nosc":"chanteurs","lemma":"chanteur","pos":"NOM"} ,
		{"word":"chanteuse","word_nosc":"chanteuse","lemma":"chanteur","pos":"NOM"} ,
		{"word":"chanteuse-vedette","word_nosc":"chanteuse-vedette","lemma":"chanteuse-vedette","pos":"NOM"} ,
		{"word":"chanteuses","word_nosc":"chanteuses","lemma":"chanteur","pos":"NOM"} ,
		{"word":"chantier","word_nosc":"chantier","lemma":"chantier","pos":"NOM"} ,
		{"word":"chantiers","word_nosc":"chantiers","lemma":"chantier","pos":"NOM"} ,
		{"word":"chantilly","word_nosc":"chantilly","lemma":"chantilly","pos":"NOM"} ,
		{"word":"chantonnement","word_nosc":"chantonnement","lemma":"chantonnement","pos":"NOM"} ,
		{"word":"chantoung","word_nosc":"chantoung","lemma":"chantoung","pos":"NOM"} ,
		{"word":"chantre","word_nosc":"chantre","lemma":"chantre","pos":"NOM"} ,
		{"word":"chantrerie","word_nosc":"chantrerie","lemma":"chantrerie","pos":"NOM"} ,
		{"word":"chantres","word_nosc":"chantres","lemma":"chantre","pos":"NOM"} ,
		{"word":"chants","word_nosc":"chants","lemma":"chant","pos":"NOM"} ,
		{"word":"chanvre","word_nosc":"chanvre","lemma":"chanvre","pos":"NOM"} ,
		{"word":"chanvres","word_nosc":"chanvres","lemma":"chanvre","pos":"NOM"} ,
		{"word":"chançard","word_nosc":"chancard","lemma":"chançard","pos":"NOM"} ,
		{"word":"chançarde","word_nosc":"chancarde","lemma":"chançard","pos":"NOM"} ,
		{"word":"chaos","word_nosc":"chaos","lemma":"chaos","pos":"NOM"} ,
		{"word":"chaouch","word_nosc":"chaouch","lemma":"chaouch","pos":"NOM"} ,
		{"word":"chaouchs","word_nosc":"chaouchs","lemma":"chaouch","pos":"NOM"} ,
		{"word":"chapardage","word_nosc":"chapardage","lemma":"chapardage","pos":"NOM"} ,
		{"word":"chapardages","word_nosc":"chapardages","lemma":"chapardage","pos":"NOM"} ,
		{"word":"chapardeur","word_nosc":"chapardeur","lemma":"chapardeur","pos":"NOM"} ,
		{"word":"chapardeurs","word_nosc":"chapardeurs","lemma":"chapardeur","pos":"NOM"} ,
		{"word":"chapardeuse","word_nosc":"chapardeuse","lemma":"chapardeur","pos":"NOM"} ,
		{"word":"chaparral","word_nosc":"chaparral","lemma":"chaparral","pos":"NOM"} ,
		{"word":"chaparrals","word_nosc":"chaparrals","lemma":"chaparral","pos":"NOM"} ,
		{"word":"chape","word_nosc":"chape","lemma":"chape","pos":"NOM"} ,
		{"word":"chapeau","word_nosc":"chapeau","lemma":"chapeau","pos":"NOM"} ,
		{"word":"chapeaux","word_nosc":"chapeaux","lemma":"chapeau","pos":"NOM"} ,
		{"word":"chapelain","word_nosc":"chapelain","lemma":"chapelain","pos":"NOM"} ,
		{"word":"chapelains","word_nosc":"chapelains","lemma":"chapelain","pos":"NOM"} ,
		{"word":"chapelet","word_nosc":"chapelet","lemma":"chapelet","pos":"NOM"} ,
		{"word":"chapelets","word_nosc":"chapelets","lemma":"chapelet","pos":"NOM"} ,
		{"word":"chapelier","word_nosc":"chapelier","lemma":"chapelier","pos":"NOM"} ,
		{"word":"chapeliers","word_nosc":"chapeliers","lemma":"chapelier","pos":"NOM"} ,
		{"word":"chapelle","word_nosc":"chapelle","lemma":"chapelle","pos":"NOM"} ,
		{"word":"chapellerie","word_nosc":"chapellerie","lemma":"chapellerie","pos":"NOM"} ,
		{"word":"chapelles","word_nosc":"chapelles","lemma":"chapelle","pos":"NOM"} ,
		{"word":"chapelure","word_nosc":"chapelure","lemma":"chapelure","pos":"NOM"} ,
		{"word":"chapelures","word_nosc":"chapelures","lemma":"chapelure","pos":"NOM"} ,
		{"word":"chaperon","word_nosc":"chaperon","lemma":"chaperon","pos":"NOM"} ,
		{"word":"chaperons","word_nosc":"chaperons","lemma":"chaperon","pos":"NOM"} ,
		{"word":"chapes","word_nosc":"chapes","lemma":"chape","pos":"NOM"} ,
		{"word":"chapiteau","word_nosc":"chapiteau","lemma":"chapiteau","pos":"NOM"} ,
		{"word":"chapiteaux","word_nosc":"chapiteaux","lemma":"chapiteau","pos":"NOM"} ,
		{"word":"chapiteaux-dortoirs","word_nosc":"chapiteaux-dortoirs","lemma":"chapiteau-dortoir","pos":"NOM"} ,
		{"word":"chapitre","word_nosc":"chapitre","lemma":"chapitre","pos":"NOM"} ,
		{"word":"chapitres","word_nosc":"chapitres","lemma":"chapitre","pos":"NOM"} ,
		{"word":"chapka","word_nosc":"chapka","lemma":"chapka","pos":"NOM"} ,
		{"word":"chapon","word_nosc":"chapon","lemma":"chapon","pos":"NOM"} ,
		{"word":"chapons","word_nosc":"chapons","lemma":"chapon","pos":"NOM"} ,
		{"word":"chappe","word_nosc":"chappe","lemma":"chappe","pos":"NOM"} ,
		{"word":"chapska","word_nosc":"chapska","lemma":"chapska","pos":"NOM"} ,
		{"word":"chapskas","word_nosc":"chapskas","lemma":"chapska","pos":"NOM"} ,
		{"word":"char","word_nosc":"char","lemma":"char","pos":"NOM"} ,
		{"word":"charabia","word_nosc":"charabia","lemma":"charabia","pos":"NOM"} ,
		{"word":"charabias","word_nosc":"charabias","lemma":"charabia","pos":"NOM"} ,
		{"word":"charade","word_nosc":"charade","lemma":"charade","pos":"NOM"} ,
		{"word":"charades","word_nosc":"charades","lemma":"charade","pos":"NOM"} ,
		{"word":"charale","word_nosc":"charale","lemma":"charale","pos":"NOM"} ,
		{"word":"charançon","word_nosc":"charancon","lemma":"charançon","pos":"NOM"} ,
		{"word":"charançons","word_nosc":"charancons","lemma":"charançon","pos":"NOM"} ,
		{"word":"charbon","word_nosc":"charbon","lemma":"charbon","pos":"NOM"} ,
		{"word":"charbonnages","word_nosc":"charbonnages","lemma":"charbonnage","pos":"NOM"} ,
		{"word":"charbonnette","word_nosc":"charbonnette","lemma":"charbonnette","pos":"NOM"} ,
		{"word":"charbonnier","word_nosc":"charbonnier","lemma":"charbonnier","pos":"NOM"} ,
		{"word":"charbonniers","word_nosc":"charbonniers","lemma":"charbonnier","pos":"NOM"} ,
		{"word":"charbonnière","word_nosc":"charbonniere","lemma":"charbonnière","pos":"NOM"} ,
		{"word":"charbonnières","word_nosc":"charbonnieres","lemma":"charbonnier","pos":"NOM"} ,
		{"word":"charbons","word_nosc":"charbons","lemma":"charbon","pos":"NOM"} ,
		{"word":"charca","word_nosc":"charca","lemma":"charca","pos":"NOM"} ,
		{"word":"charcutage","word_nosc":"charcutage","lemma":"charcutage","pos":"NOM"} ,
		{"word":"charcutaille","word_nosc":"charcutaille","lemma":"charcutaille","pos":"NOM"} ,
		{"word":"charcuterie","word_nosc":"charcuterie","lemma":"charcuterie","pos":"NOM"} ,
		{"word":"charcuteries","word_nosc":"charcuteries","lemma":"charcuterie","pos":"NOM"} ,
		{"word":"charcutier","word_nosc":"charcutier","lemma":"charcutier","pos":"NOM"} ,
		{"word":"charcutiers","word_nosc":"charcutiers","lemma":"charcutier","pos":"NOM"} ,
		{"word":"charcutière","word_nosc":"charcutiere","lemma":"charcutier","pos":"NOM"} ,
		{"word":"charcutières","word_nosc":"charcutieres","lemma":"charcutier","pos":"NOM"} ,
		{"word":"chardon","word_nosc":"chardon","lemma":"chardon","pos":"NOM"} ,
		{"word":"chardonay","word_nosc":"chardonay","lemma":"chardonay","pos":"NOM"} ,
		{"word":"chardonnay","word_nosc":"chardonnay","lemma":"chardonnay","pos":"NOM"} ,
		{"word":"chardonneret","word_nosc":"chardonneret","lemma":"chardonneret","pos":"NOM"} ,
		{"word":"chardonnerets","word_nosc":"chardonnerets","lemma":"chardonneret","pos":"NOM"} ,
		{"word":"chardons","word_nosc":"chardons","lemma":"chardon","pos":"NOM"} ,
		{"word":"charentaise","word_nosc":"charentaise","lemma":"charentais","pos":"NOM"} ,
		{"word":"charentaises","word_nosc":"charentaises","lemma":"charentais","pos":"NOM"} ,
		{"word":"charge","word_nosc":"charge","lemma":"charge","pos":"NOM"} ,
		{"word":"chargement","word_nosc":"chargement","lemma":"chargement","pos":"NOM"} ,
		{"word":"chargements","word_nosc":"chargements","lemma":"chargement","pos":"NOM"} ,
		{"word":"charges","word_nosc":"charges","lemma":"charge","pos":"NOM"} ,
		{"word":"chargeur","word_nosc":"chargeur","lemma":"chargeur","pos":"NOM"} ,
		{"word":"chargeurs","word_nosc":"chargeurs","lemma":"chargeur","pos":"NOM"} ,
		{"word":"charia","word_nosc":"charia","lemma":"charia","pos":"NOM"} ,
		{"word":"chariot","word_nosc":"chariot","lemma":"chariot","pos":"NOM"} ,
		{"word":"chariots","word_nosc":"chariots","lemma":"chariot","pos":"NOM"} ,
		{"word":"charisme","word_nosc":"charisme","lemma":"charisme","pos":"NOM"} ,
		{"word":"charité","word_nosc":"charite","lemma":"charité","pos":"NOM"} ,
		{"word":"charités","word_nosc":"charites","lemma":"charité","pos":"NOM"} ,
		{"word":"charivari","word_nosc":"charivari","lemma":"charivari","pos":"NOM"} ,
		{"word":"charivaris","word_nosc":"charivaris","lemma":"charivari","pos":"NOM"} ,
		{"word":"charlatan","word_nosc":"charlatan","lemma":"charlatan","pos":"NOM"} ,
		{"word":"charlatanerie","word_nosc":"charlatanerie","lemma":"charlatanerie","pos":"NOM"} ,
		{"word":"charlatanisme","word_nosc":"charlatanisme","lemma":"charlatanisme","pos":"NOM"} ,
		{"word":"charlatanismes","word_nosc":"charlatanismes","lemma":"charlatanisme","pos":"NOM"} ,
		{"word":"charlatans","word_nosc":"charlatans","lemma":"charlatan","pos":"NOM"} ,
		{"word":"charleston","word_nosc":"charleston","lemma":"charleston","pos":"NOM"} ,
		{"word":"charlot","word_nosc":"charlot","lemma":"charlot","pos":"NOM"} ,
		{"word":"charlots","word_nosc":"charlots","lemma":"charlot","pos":"NOM"} ,
		{"word":"charlotte","word_nosc":"charlotte","lemma":"charlotte","pos":"NOM"} ,
		{"word":"charlottes","word_nosc":"charlottes","lemma":"charlotte","pos":"NOM"} ,
		{"word":"charme","word_nosc":"charme","lemma":"charme","pos":"NOM"} ,
		{"word":"charmes","word_nosc":"charmes","lemma":"charme","pos":"NOM"} ,
		{"word":"charmeur","word_nosc":"charmeur","lemma":"charmeur","pos":"NOM"} ,
		{"word":"charmeurs","word_nosc":"charmeurs","lemma":"charmeur","pos":"NOM"} ,
		{"word":"charmeuse","word_nosc":"charmeuse","lemma":"charmeur","pos":"NOM"} ,
		{"word":"charmeuses","word_nosc":"charmeuses","lemma":"charmeur","pos":"NOM"} ,
		{"word":"charmille","word_nosc":"charmille","lemma":"charmille","pos":"NOM"} ,
		{"word":"charmilles","word_nosc":"charmilles","lemma":"charmille","pos":"NOM"} ,
		{"word":"charnier","word_nosc":"charnier","lemma":"charnier","pos":"NOM"} ,
		{"word":"charniers","word_nosc":"charniers","lemma":"charnier","pos":"NOM"} ,
		{"word":"charnière","word_nosc":"charniere","lemma":"charnière","pos":"NOM"} ,
		{"word":"charnières","word_nosc":"charnieres","lemma":"charnière","pos":"NOM"} ,
		{"word":"charognard","word_nosc":"charognard","lemma":"charognard","pos":"NOM"} ,
		{"word":"charognards","word_nosc":"charognards","lemma":"charognard","pos":"NOM"} ,
		{"word":"charogne","word_nosc":"charogne","lemma":"charogne","pos":"NOM"} ,
		{"word":"charognerie","word_nosc":"charognerie","lemma":"charognerie","pos":"NOM"} ,
		{"word":"charognes","word_nosc":"charognes","lemma":"charogne","pos":"NOM"} ,
		{"word":"charolaise","word_nosc":"charolaise","lemma":"charolais","pos":"NOM"} ,
		{"word":"charolaises","word_nosc":"charolaises","lemma":"charolais","pos":"NOM"} ,
		{"word":"charpente","word_nosc":"charpente","lemma":"charpente","pos":"NOM"} ,
		{"word":"charpenterie","word_nosc":"charpenterie","lemma":"charpenterie","pos":"NOM"} ,
		{"word":"charpentes","word_nosc":"charpentes","lemma":"charpente","pos":"NOM"} ,
		{"word":"charpentier","word_nosc":"charpentier","lemma":"charpentier","pos":"NOM"} ,
		{"word":"charpentiers","word_nosc":"charpentiers","lemma":"charpentier","pos":"NOM"} ,
		{"word":"charpentière","word_nosc":"charpentiere","lemma":"charpentier","pos":"NOM"} ,
		{"word":"charpie","word_nosc":"charpie","lemma":"charpie","pos":"NOM"} ,
		{"word":"charpies","word_nosc":"charpies","lemma":"charpie","pos":"NOM"} ,
		{"word":"charre","word_nosc":"charre","lemma":"charre","pos":"NOM"} ,
		{"word":"charres","word_nosc":"charres","lemma":"charre","pos":"NOM"} ,
		{"word":"charretier","word_nosc":"charretier","lemma":"charretier","pos":"NOM"} ,
		{"word":"charretiers","word_nosc":"charretiers","lemma":"charretier","pos":"NOM"} ,
		{"word":"charretière","word_nosc":"charretiere","lemma":"charretier","pos":"NOM"} ,
		{"word":"charreton","word_nosc":"charreton","lemma":"charreton","pos":"NOM"} ,
		{"word":"charretons","word_nosc":"charretons","lemma":"charreton","pos":"NOM"} ,
		{"word":"charrette","word_nosc":"charrette","lemma":"charrette","pos":"NOM"} ,
		{"word":"charrettes","word_nosc":"charrettes","lemma":"charrette","pos":"NOM"} ,
		{"word":"charretée","word_nosc":"charretee","lemma":"charretée","pos":"NOM"} ,
		{"word":"charretées","word_nosc":"charretees","lemma":"charretée","pos":"NOM"} ,
		{"word":"charriage","word_nosc":"charriage","lemma":"charriage","pos":"NOM"} ,
		{"word":"charrieurs","word_nosc":"charrieurs","lemma":"charrieur","pos":"NOM"} ,
		{"word":"charrière","word_nosc":"charriere","lemma":"charrier","pos":"NOM"} ,
		{"word":"charrières","word_nosc":"charrieres","lemma":"charrier","pos":"NOM"} ,
		{"word":"charroi","word_nosc":"charroi","lemma":"charroi","pos":"NOM"} ,
		{"word":"charrois","word_nosc":"charrois","lemma":"charroi","pos":"NOM"} ,
		{"word":"charron","word_nosc":"charron","lemma":"charron","pos":"NOM"} ,
		{"word":"charrons","word_nosc":"charrons","lemma":"charron","pos":"NOM"} ,
		{"word":"charrue","word_nosc":"charrue","lemma":"charrue","pos":"NOM"} ,
		{"word":"charrues","word_nosc":"charrues","lemma":"charrue","pos":"NOM"} ,
		{"word":"chars","word_nosc":"chars","lemma":"char","pos":"NOM"} ,
		{"word":"charte","word_nosc":"charte","lemma":"charte","pos":"NOM"} ,
		{"word":"charter","word_nosc":"charter","lemma":"charter","pos":"NOM"} ,
		{"word":"charters","word_nosc":"charters","lemma":"charter","pos":"NOM"} ,
		{"word":"chartes","word_nosc":"chartes","lemma":"charte","pos":"NOM"} ,
		{"word":"chartiste","word_nosc":"chartiste","lemma":"chartiste","pos":"NOM"} ,
		{"word":"chartistes","word_nosc":"chartistes","lemma":"chartiste","pos":"NOM"} ,
		{"word":"chartre","word_nosc":"chartre","lemma":"chartre","pos":"NOM"} ,
		{"word":"chartreuse","word_nosc":"chartreuse","lemma":"chartreux","pos":"NOM"} ,
		{"word":"chartreuses","word_nosc":"chartreuses","lemma":"chartreux","pos":"NOM"} ,
		{"word":"chartreux","word_nosc":"chartreux","lemma":"chartreux","pos":"NOM"} ,
		{"word":"charybde","word_nosc":"charybde","lemma":"charybde","pos":"NOM"} ,
		{"word":"chas","word_nosc":"chas","lemma":"chas","pos":"NOM"} ,
		{"word":"chasse","word_nosc":"chasse","lemma":"chasse","pos":"NOM"} ,
		{"word":"chasse-d'eau","word_nosc":"chasse-d'eau","lemma":"chasse-d'eau","pos":"NOM"} ,
		{"word":"chasse-goupilles","word_nosc":"chasse-goupilles","lemma":"chasse-goupille","pos":"NOM"} ,
		{"word":"chasse-mouche","word_nosc":"chasse-mouche","lemma":"chasse-mouche","pos":"NOM"} ,
		{"word":"chasse-mouches","word_nosc":"chasse-mouches","lemma":"chasse-mouches","pos":"NOM"} ,
		{"word":"chasse-neige","word_nosc":"chasse-neige","lemma":"chasse-neige","pos":"NOM"} ,
		{"word":"chasse-pierres","word_nosc":"chasse-pierres","lemma":"chasse-pierres","pos":"NOM"} ,
		{"word":"chasselas","word_nosc":"chasselas","lemma":"chasselas","pos":"NOM"} ,
		{"word":"chasseresse","word_nosc":"chasseresse","lemma":"chasseur","pos":"NOM"} ,
		{"word":"chasseresses","word_nosc":"chasseresses","lemma":"chasseur","pos":"NOM"} ,
		{"word":"chasses","word_nosc":"chasses","lemma":"chasse","pos":"NOM"} ,
		{"word":"chasseur","word_nosc":"chasseur","lemma":"chasseur","pos":"NOM"} ,
		{"word":"chasseurs","word_nosc":"chasseurs","lemma":"chasseur","pos":"NOM"} ,
		{"word":"chasseuse","word_nosc":"chasseuse","lemma":"chasseur","pos":"NOM"} ,
		{"word":"chassie","word_nosc":"chassie","lemma":"chassie","pos":"NOM"} ,
		{"word":"chassé","word_nosc":"chasse","lemma":"chassé","pos":"NOM"} ,
		{"word":"chassé-croisé","word_nosc":"chasse-croise","lemma":"chassé-croisé","pos":"NOM"} ,
		{"word":"chassés","word_nosc":"chasses","lemma":"chassé","pos":"NOM"} ,
		{"word":"chassés-croisés","word_nosc":"chasses-croises","lemma":"chassé-croisé","pos":"NOM"} ,
		{"word":"chasteté","word_nosc":"chastete","lemma":"chasteté","pos":"NOM"} ,
		{"word":"chasuble","word_nosc":"chasuble","lemma":"chasuble","pos":"NOM"} ,
		{"word":"chasubles","word_nosc":"chasubles","lemma":"chasuble","pos":"NOM"} ,
		{"word":"chat","word_nosc":"chat","lemma":"chat","pos":"NOM"} ,
		{"word":"chat-huant","word_nosc":"chat-huant","lemma":"chat-huant","pos":"NOM"} ,
		{"word":"chat-tigre","word_nosc":"chat-tigre","lemma":"chat-tigre","pos":"NOM"} ,
		{"word":"chateaubriand","word_nosc":"chateaubriand","lemma":"chateaubriand","pos":"NOM"} ,
		{"word":"chateaubriands","word_nosc":"chateaubriands","lemma":"chateaubriand","pos":"NOM"} ,
		{"word":"chatière","word_nosc":"chatiere","lemma":"chatière","pos":"NOM"} ,
		{"word":"chatoiement","word_nosc":"chatoiement","lemma":"chatoiement","pos":"NOM"} ,
		{"word":"chatoiements","word_nosc":"chatoiements","lemma":"chatoiement","pos":"NOM"} ,
		{"word":"chaton","word_nosc":"chaton","lemma":"chaton","pos":"NOM"} ,
		{"word":"chatons","word_nosc":"chatons","lemma":"chaton","pos":"NOM"} ,
		{"word":"chatouille","word_nosc":"chatouille","lemma":"chatouille","pos":"NOM"} ,
		{"word":"chatouillement","word_nosc":"chatouillement","lemma":"chatouillement","pos":"NOM"} ,
		{"word":"chatouillements","word_nosc":"chatouillements","lemma":"chatouillement","pos":"NOM"} ,
		{"word":"chatouilles","word_nosc":"chatouilles","lemma":"chatouille","pos":"NOM"} ,
		{"word":"chatouilleuse","word_nosc":"chatouilleuse","lemma":"chatouilleux","pos":"NOM"} ,
		{"word":"chatouilleuses","word_nosc":"chatouilleuses","lemma":"chatouilleux","pos":"NOM"} ,
		{"word":"chatouillis","word_nosc":"chatouillis","lemma":"chatouillis","pos":"NOM"} ,
		{"word":"chats","word_nosc":"chats","lemma":"chat","pos":"NOM"} ,
		{"word":"chatte","word_nosc":"chatte","lemma":"chat","pos":"NOM"} ,
		{"word":"chattemite","word_nosc":"chattemite","lemma":"chattemite","pos":"NOM"} ,
		{"word":"chatterie","word_nosc":"chatterie","lemma":"chatterie","pos":"NOM"} ,
		{"word":"chatteries","word_nosc":"chatteries","lemma":"chatterie","pos":"NOM"} ,
		{"word":"chatterton","word_nosc":"chatterton","lemma":"chatterton","pos":"NOM"} ,
		{"word":"chattes","word_nosc":"chattes","lemma":"chat","pos":"NOM"} ,
		{"word":"chaud","word_nosc":"chaud","lemma":"chaud","pos":"NOM"} ,
		{"word":"chaud-froid","word_nosc":"chaud-froid","lemma":"chaud-froid","pos":"NOM"} ,
		{"word":"chaude","word_nosc":"chaude","lemma":"chaud","pos":"NOM"} ,
		{"word":"chaude-pisse","word_nosc":"chaude-pisse","lemma":"chaude-pisse","pos":"NOM"} ,
		{"word":"chaudes","word_nosc":"chaudes","lemma":"chaud","pos":"NOM"} ,
		{"word":"chaudes-pisses","word_nosc":"chaudes-pisses","lemma":"chaude-pisse","pos":"NOM"} ,
		{"word":"chaudière","word_nosc":"chaudiere","lemma":"chaudière","pos":"NOM"} ,
		{"word":"chaudières","word_nosc":"chaudieres","lemma":"chaudière","pos":"NOM"} ,
		{"word":"chaudron","word_nosc":"chaudron","lemma":"chaudron","pos":"NOM"} ,
		{"word":"chaudronnerie","word_nosc":"chaudronnerie","lemma":"chaudronnerie","pos":"NOM"} ,
		{"word":"chaudronneries","word_nosc":"chaudronneries","lemma":"chaudronnerie","pos":"NOM"} ,
		{"word":"chaudronnier","word_nosc":"chaudronnier","lemma":"chaudronnier","pos":"NOM"} ,
		{"word":"chaudronniers","word_nosc":"chaudronniers","lemma":"chaudronnier","pos":"NOM"} ,
		{"word":"chaudronnée","word_nosc":"chaudronnee","lemma":"chaudronnée","pos":"NOM"} ,
		{"word":"chaudrons","word_nosc":"chaudrons","lemma":"chaudron","pos":"NOM"} ,
		{"word":"chauds","word_nosc":"chauds","lemma":"chaud","pos":"NOM"} ,
		{"word":"chauds-froids","word_nosc":"chauds-froids","lemma":"chaud-froid","pos":"NOM"} ,
		{"word":"chauffage","word_nosc":"chauffage","lemma":"chauffage","pos":"NOM"} ,
		{"word":"chauffagiste","word_nosc":"chauffagiste","lemma":"chauffagiste","pos":"NOM"} ,
		{"word":"chauffard","word_nosc":"chauffard","lemma":"chauffard","pos":"NOM"} ,
		{"word":"chauffards","word_nosc":"chauffards","lemma":"chauffard","pos":"NOM"} ,
		{"word":"chauffe","word_nosc":"chauffe","lemma":"chauffe","pos":"NOM"} ,
		{"word":"chauffe-biberon","word_nosc":"chauffe-biberon","lemma":"chauffe-biberon","pos":"NOM"} ,
		{"word":"chauffe-eau","word_nosc":"chauffe-eau","lemma":"chauffe-eau","pos":"NOM"} ,
		{"word":"chauffe-plats","word_nosc":"chauffe-plats","lemma":"chauffe-plats","pos":"NOM"} ,
		{"word":"chaufferette","word_nosc":"chaufferette","lemma":"chaufferette","pos":"NOM"} ,
		{"word":"chaufferettes","word_nosc":"chaufferettes","lemma":"chaufferette","pos":"NOM"} ,
		{"word":"chaufferie","word_nosc":"chaufferie","lemma":"chaufferie","pos":"NOM"} ,
		{"word":"chaufferies","word_nosc":"chaufferies","lemma":"chaufferie","pos":"NOM"} ,
		{"word":"chauffeur","word_nosc":"chauffeur","lemma":"chauffeur","pos":"NOM"} ,
		{"word":"chauffeur-livreur","word_nosc":"chauffeur-livreur","lemma":"chauffeur-livreur","pos":"NOM"} ,
		{"word":"chauffeurs","word_nosc":"chauffeurs","lemma":"chauffeur","pos":"NOM"} ,
		{"word":"chauffeuse","word_nosc":"chauffeuse","lemma":"chauffeur","pos":"NOM"} ,
		{"word":"chaume","word_nosc":"chaume","lemma":"chaume","pos":"NOM"} ,
		{"word":"chaumes","word_nosc":"chaumes","lemma":"chaume","pos":"NOM"} ,
		{"word":"chaumine","word_nosc":"chaumine","lemma":"chaumine","pos":"NOM"} ,
		{"word":"chaumines","word_nosc":"chaumines","lemma":"chaumine","pos":"NOM"} ,
		{"word":"chaumière","word_nosc":"chaumiere","lemma":"chaumière","pos":"NOM"} ,
		{"word":"chaumières","word_nosc":"chaumieres","lemma":"chaumière","pos":"NOM"} ,
		{"word":"chausse-pied","word_nosc":"chausse-pied","lemma":"chausse-pied","pos":"NOM"} ,
		{"word":"chausse-trape","word_nosc":"chausse-trape","lemma":"chausse-trape","pos":"NOM"} ,
		{"word":"chausse-trapes","word_nosc":"chausse-trapes","lemma":"chausse-trape","pos":"NOM"} ,
		{"word":"chausse-trappe","word_nosc":"chausse-trappe","lemma":"chausse-trappe","pos":"NOM"} ,
		{"word":"chausses","word_nosc":"chausses","lemma":"chausse","pos":"NOM"} ,
		{"word":"chaussette","word_nosc":"chaussette","lemma":"chaussette","pos":"NOM"} ,
		{"word":"chaussettes","word_nosc":"chaussettes","lemma":"chaussette","pos":"NOM"} ,
		{"word":"chausseur","word_nosc":"chausseur","lemma":"chausseur","pos":"NOM"} ,
		{"word":"chausseurs","word_nosc":"chausseurs","lemma":"chausseur","pos":"NOM"} ,
		{"word":"chausson","word_nosc":"chausson","lemma":"chausson","pos":"NOM"} ,
		{"word":"chaussons","word_nosc":"chaussons","lemma":"chausson","pos":"NOM"} ,
		{"word":"chaussure","word_nosc":"chaussure","lemma":"chaussure","pos":"NOM"} ,
		{"word":"chaussures","word_nosc":"chaussures","lemma":"chaussure","pos":"NOM"} ,
		{"word":"chaussée","word_nosc":"chaussee","lemma":"chaussée","pos":"NOM"} ,
		{"word":"chaussées","word_nosc":"chaussees","lemma":"chaussée","pos":"NOM"} ,
		{"word":"chauve","word_nosc":"chauve","lemma":"chauve","pos":"NOM"} ,
		{"word":"chauve-souris","word_nosc":"chauve-souris","lemma":"chauve-souris","pos":"NOM"} ,
		{"word":"chauves","word_nosc":"chauves","lemma":"chauve","pos":"NOM"} ,
		{"word":"chauves-souris","word_nosc":"chauves-souris","lemma":"chauve-souris","pos":"NOM"} ,
		{"word":"chauvin","word_nosc":"chauvin","lemma":"chauvin","pos":"NOM"} ,
		{"word":"chauvine","word_nosc":"chauvine","lemma":"chauvin","pos":"NOM"} ,
		{"word":"chauvinisme","word_nosc":"chauvinisme","lemma":"chauvinisme","pos":"NOM"} ,
		{"word":"chauviniste","word_nosc":"chauviniste","lemma":"chauviniste","pos":"NOM"} ,
		{"word":"chaux","word_nosc":"chaux","lemma":"chaux","pos":"NOM"} ,
		{"word":"chavignol","word_nosc":"chavignol","lemma":"chavignol","pos":"NOM"} ,
		{"word":"chavirement","word_nosc":"chavirement","lemma":"chavirement","pos":"NOM"} ,
		{"word":"chavirements","word_nosc":"chavirements","lemma":"chavirement","pos":"NOM"} ,
		{"word":"chaîne","word_nosc":"chaine","lemma":"chaîne","pos":"NOM"} ,
		{"word":"chaînes","word_nosc":"chaines","lemma":"chaîne","pos":"NOM"} ,
		{"word":"chaînette","word_nosc":"chainette","lemma":"chaînette","pos":"NOM"} ,
		{"word":"chaînettes","word_nosc":"chainettes","lemma":"chaînette","pos":"NOM"} ,
		{"word":"chaînon","word_nosc":"chainon","lemma":"chaînon","pos":"NOM"} ,
		{"word":"chaînons","word_nosc":"chainons","lemma":"chaînon","pos":"NOM"} ,
		{"word":"cheap","word_nosc":"cheap","lemma":"cheap","pos":"NOM"} ,
		{"word":"check up","word_nosc":"check up","lemma":"check-up","pos":"NOM"} ,
		{"word":"check-list","word_nosc":"check-list","lemma":"check-list","pos":"NOM"} ,
		{"word":"check-up","word_nosc":"check-up","lemma":"check-up","pos":"NOM"} ,
		{"word":"cheddar","word_nosc":"cheddar","lemma":"cheddar","pos":"NOM"} ,
		{"word":"cheddite","word_nosc":"cheddite","lemma":"cheddite","pos":"NOM"} ,
		{"word":"cheese-cake","word_nosc":"cheese-cake","lemma":"cheese-cake","pos":"NOM"} ,
		{"word":"cheese-cakes","word_nosc":"cheese-cakes","lemma":"cheese-cake","pos":"NOM"} ,
		{"word":"cheeseburger","word_nosc":"cheeseburger","lemma":"cheeseburger","pos":"NOM"} ,
		{"word":"cheeseburgers","word_nosc":"cheeseburgers","lemma":"cheeseburger","pos":"NOM"} ,
		{"word":"chef","word_nosc":"chef","lemma":"chef","pos":"NOM"} ,
		{"word":"chef-adjoint","word_nosc":"chef-adjoint","lemma":"chef-adjoint","pos":"NOM"} ,
		{"word":"chef-d'oeuvre","word_nosc":"chef-d'oeuvre","lemma":"chef-d'oeuvre","pos":"NOM"} ,
		{"word":"chef-d'oeuvres","word_nosc":"chef-d'oeuvres","lemma":"chef-d'oeuvres","pos":"NOM"} ,
		{"word":"chef-lieu","word_nosc":"chef-lieu","lemma":"chef-lieu","pos":"NOM"} ,
		{"word":"chefs","word_nosc":"chefs","lemma":"chef","pos":"NOM"} ,
		{"word":"chefs-d'oeuvre","word_nosc":"chefs-d'oeuvre","lemma":"chef-d'oeuvre","pos":"NOM"} ,
		{"word":"chefs-lieux","word_nosc":"chefs-lieux","lemma":"chefs-lieux","pos":"NOM"} ,
		{"word":"cheftaine","word_nosc":"cheftaine","lemma":"cheftaine","pos":"NOM"} ,
		{"word":"cheftaines","word_nosc":"cheftaines","lemma":"cheftaine","pos":"NOM"} ,
		{"word":"cheik","word_nosc":"cheik","lemma":"cheik","pos":"NOM"} ,
		{"word":"cheikh","word_nosc":"cheikh","lemma":"cheikh","pos":"NOM"} ,
		{"word":"cheiks","word_nosc":"cheiks","lemma":"cheik","pos":"NOM"} ,
		{"word":"chelem","word_nosc":"chelem","lemma":"chelem","pos":"NOM"} ,
		{"word":"chemin","word_nosc":"chemin","lemma":"chemin","pos":"NOM"} ,
		{"word":"chemineau","word_nosc":"chemineau","lemma":"chemineau","pos":"NOM"} ,
		{"word":"chemineaux","word_nosc":"chemineaux","lemma":"chemineau","pos":"NOM"} ,
		{"word":"cheminement","word_nosc":"cheminement","lemma":"cheminement","pos":"NOM"} ,
		{"word":"cheminements","word_nosc":"cheminements","lemma":"cheminement","pos":"NOM"} ,
		{"word":"cheminot","word_nosc":"cheminot","lemma":"cheminot","pos":"NOM"} ,
		{"word":"cheminots","word_nosc":"cheminots","lemma":"cheminot","pos":"NOM"} ,
		{"word":"chemins","word_nosc":"chemins","lemma":"chemin","pos":"NOM"} ,
		{"word":"cheminée","word_nosc":"cheminee","lemma":"cheminée","pos":"NOM"} ,
		{"word":"cheminées","word_nosc":"cheminees","lemma":"cheminée","pos":"NOM"} ,
		{"word":"chemise","word_nosc":"chemise","lemma":"chemise","pos":"NOM"} ,
		{"word":"chemiserie","word_nosc":"chemiserie","lemma":"chemiserie","pos":"NOM"} ,
		{"word":"chemises","word_nosc":"chemises","lemma":"chemise","pos":"NOM"} ,
		{"word":"chemisette","word_nosc":"chemisette","lemma":"chemisette","pos":"NOM"} ,
		{"word":"chemisettes","word_nosc":"chemisettes","lemma":"chemisette","pos":"NOM"} ,
		{"word":"chemisier","word_nosc":"chemisier","lemma":"chemisier","pos":"NOM"} ,
		{"word":"chemisiers","word_nosc":"chemisiers","lemma":"chemisier","pos":"NOM"} ,
		{"word":"chenal","word_nosc":"chenal","lemma":"chenal","pos":"NOM"} ,
		{"word":"chenapan","word_nosc":"chenapan","lemma":"chenapan","pos":"NOM"} ,
		{"word":"chenapans","word_nosc":"chenapans","lemma":"chenapan","pos":"NOM"} ,
		{"word":"chenaux","word_nosc":"chenaux","lemma":"chenal","pos":"NOM"} ,
		{"word":"chenet","word_nosc":"chenet","lemma":"chenet","pos":"NOM"} ,
		{"word":"chenets","word_nosc":"chenets","lemma":"chenet","pos":"NOM"} ,
		{"word":"chenil","word_nosc":"chenil","lemma":"chenil","pos":"NOM"} ,
		{"word":"chenille","word_nosc":"chenille","lemma":"chenille","pos":"NOM"} ,
		{"word":"chenilles","word_nosc":"chenilles","lemma":"chenille","pos":"NOM"} ,
		{"word":"chenillette","word_nosc":"chenillette","lemma":"chenillette","pos":"NOM"} ,
		{"word":"chenillettes","word_nosc":"chenillettes","lemma":"chenillette","pos":"NOM"} ,
		{"word":"chenils","word_nosc":"chenils","lemma":"chenil","pos":"NOM"} ,
		{"word":"cheptel","word_nosc":"cheptel","lemma":"cheptel","pos":"NOM"} ,
		{"word":"cher","word_nosc":"cher","lemma":"cher","pos":"NOM"} ,
		{"word":"cherche-midi","word_nosc":"cherche-midi","lemma":"cherche-midi","pos":"NOM"} ,
		{"word":"chercheur","word_nosc":"chercheur","lemma":"chercheur","pos":"NOM"} ,
		{"word":"chercheurs","word_nosc":"chercheurs","lemma":"chercheur","pos":"NOM"} ,
		{"word":"chercheuse","word_nosc":"chercheuse","lemma":"chercheur","pos":"NOM"} ,
		{"word":"chercheuses","word_nosc":"chercheuses","lemma":"chercheur","pos":"NOM"} ,
		{"word":"chergui","word_nosc":"chergui","lemma":"chergui","pos":"NOM"} ,
		{"word":"cherokee","word_nosc":"cherokee","lemma":"cherokee","pos":"NOM"} ,
		{"word":"cherokees","word_nosc":"cherokees","lemma":"cherokee","pos":"NOM"} ,
		{"word":"cherry","word_nosc":"cherry","lemma":"cherry","pos":"NOM"} ,
		{"word":"cherté","word_nosc":"cherte","lemma":"cherté","pos":"NOM"} ,
		{"word":"chester","word_nosc":"chester","lemma":"chester","pos":"NOM"} ,
		{"word":"cheval","word_nosc":"cheval","lemma":"cheval","pos":"NOM"} ,
		{"word":"cheval-vapeur","word_nosc":"cheval-vapeur","lemma":"cheval-vapeur","pos":"NOM"} ,
		{"word":"chevalerie","word_nosc":"chevalerie","lemma":"chevalerie","pos":"NOM"} ,
		{"word":"chevaleries","word_nosc":"chevaleries","lemma":"chevalerie","pos":"NOM"} ,
		{"word":"chevalet","word_nosc":"chevalet","lemma":"chevalet","pos":"NOM"} ,
		{"word":"chevalets","word_nosc":"chevalets","lemma":"chevalet","pos":"NOM"} ,
		{"word":"chevalier","word_nosc":"chevalier","lemma":"chevalier","pos":"NOM"} ,
		{"word":"chevaliers","word_nosc":"chevaliers","lemma":"chevalier","pos":"NOM"} ,
		{"word":"chevalière","word_nosc":"chevaliere","lemma":"chevalière","pos":"NOM"} ,
		{"word":"chevalières","word_nosc":"chevalieres","lemma":"chevalière","pos":"NOM"} ,
		{"word":"chevance","word_nosc":"chevance","lemma":"chevance","pos":"NOM"} ,
		{"word":"chevau-léger","word_nosc":"chevau-leger","lemma":"chevau-léger","pos":"NOM"} ,
		{"word":"chevauchement","word_nosc":"chevauchement","lemma":"chevauchement","pos":"NOM"} ,
		{"word":"chevaucheur","word_nosc":"chevaucheur","lemma":"chevaucheur","pos":"NOM"} ,
		{"word":"chevauchée","word_nosc":"chevauchee","lemma":"chevauchée","pos":"NOM"} ,
		{"word":"chevauchées","word_nosc":"chevauchees","lemma":"chevauchée","pos":"NOM"} ,
		{"word":"chevaux","word_nosc":"chevaux","lemma":"cheval","pos":"NOM"} ,
		{"word":"chevaux-vapeur","word_nosc":"chevaux-vapeur","lemma":"cheval-vapeur","pos":"NOM"} ,
		{"word":"chevelure","word_nosc":"chevelure","lemma":"chevelure","pos":"NOM"} ,
		{"word":"chevelures","word_nosc":"chevelures","lemma":"chevelure","pos":"NOM"} ,
		{"word":"chevesne","word_nosc":"chevesne","lemma":"chevesne","pos":"NOM"} ,
		{"word":"chevesnes","word_nosc":"chevesnes","lemma":"chevesne","pos":"NOM"} ,
		{"word":"chevet","word_nosc":"chevet","lemma":"chevet","pos":"NOM"} ,
		{"word":"chevets","word_nosc":"chevets","lemma":"chevet","pos":"NOM"} ,
		{"word":"cheveu","word_nosc":"cheveu","lemma":"cheveu","pos":"NOM"} ,
		{"word":"cheveux","word_nosc":"cheveux","lemma":"cheveu","pos":"NOM"} ,
		{"word":"chevillage","word_nosc":"chevillage","lemma":"chevillage","pos":"NOM"} ,
		{"word":"chevillard","word_nosc":"chevillard","lemma":"chevillard","pos":"NOM"} ,
		{"word":"chevillards","word_nosc":"chevillards","lemma":"chevillard","pos":"NOM"} ,
		{"word":"cheville","word_nosc":"cheville","lemma":"cheville","pos":"NOM"} ,
		{"word":"chevilles","word_nosc":"chevilles","lemma":"cheville","pos":"NOM"} ,
		{"word":"chevillette","word_nosc":"chevillette","lemma":"chevillette","pos":"NOM"} ,
		{"word":"cheviotte","word_nosc":"cheviotte","lemma":"cheviotte","pos":"NOM"} ,
		{"word":"chevreau","word_nosc":"chevreau","lemma":"chevreau","pos":"NOM"} ,
		{"word":"chevreaux","word_nosc":"chevreaux","lemma":"chevreau","pos":"NOM"} ,
		{"word":"chevrette","word_nosc":"chevrette","lemma":"chevrette","pos":"NOM"} ,
		{"word":"chevrettes","word_nosc":"chevrettes","lemma":"chevrette","pos":"NOM"} ,
		{"word":"chevreuil","word_nosc":"chevreuil","lemma":"chevreuil","pos":"NOM"} ,
		{"word":"chevreuils","word_nosc":"chevreuils","lemma":"chevreuil","pos":"NOM"} ,
		{"word":"chevrier","word_nosc":"chevrier","lemma":"chevrier","pos":"NOM"} ,
		{"word":"chevriers","word_nosc":"chevriers","lemma":"chevrier","pos":"NOM"} ,
		{"word":"chevrière","word_nosc":"chevriere","lemma":"chevrier","pos":"NOM"} ,
		{"word":"chevron","word_nosc":"chevron","lemma":"chevron","pos":"NOM"} ,
		{"word":"chevrons","word_nosc":"chevrons","lemma":"chevron","pos":"NOM"} ,
		{"word":"chevrotement","word_nosc":"chevrotement","lemma":"chevrotement","pos":"NOM"} ,
		{"word":"chevrotements","word_nosc":"chevrotements","lemma":"chevrotement","pos":"NOM"} ,
		{"word":"chevrotine","word_nosc":"chevrotine","lemma":"chevrotine","pos":"NOM"} ,
		{"word":"chevrotines","word_nosc":"chevrotines","lemma":"chevrotine","pos":"NOM"} ,
		{"word":"chevêche","word_nosc":"cheveche","lemma":"chevêche","pos":"NOM"} ,
		{"word":"chevêches","word_nosc":"cheveches","lemma":"chevêche","pos":"NOM"} ,
		{"word":"chewing gum","word_nosc":"chewing gum","lemma":"chewing-gum","pos":"NOM"} ,
		{"word":"chewing-gum","word_nosc":"chewing-gum","lemma":"chewing-gum","pos":"NOM"} ,
		{"word":"chewing-gums","word_nosc":"chewing-gums","lemma":"chewing-gum","pos":"NOM"} ,
		{"word":"chez-soi","word_nosc":"chez-soi","lemma":"chez-soi","pos":"NOM"} ,
		{"word":"chi","word_nosc":"chi","lemma":"chi","pos":"NOM"} ,
		{"word":"chialeries","word_nosc":"chialeries","lemma":"chialerie","pos":"NOM"} ,
		{"word":"chialeur","word_nosc":"chialeur","lemma":"chialeur","pos":"NOM"} ,
		{"word":"chialeuse","word_nosc":"chialeuse","lemma":"chialeur","pos":"NOM"} ,
		{"word":"chianti","word_nosc":"chianti","lemma":"chianti","pos":"NOM"} ,
		{"word":"chiard","word_nosc":"chiard","lemma":"chiard","pos":"NOM"} ,
		{"word":"chiards","word_nosc":"chiards","lemma":"chiard","pos":"NOM"} ,
		{"word":"chiasma","word_nosc":"chiasma","lemma":"chiasma","pos":"NOM"} ,
		{"word":"chiasme","word_nosc":"chiasme","lemma":"chiasme","pos":"NOM"} ,
		{"word":"chiasse","word_nosc":"chiasse","lemma":"chiasse","pos":"NOM"} ,
		{"word":"chiasses","word_nosc":"chiasses","lemma":"chiasse","pos":"NOM"} ,
		{"word":"chibouk","word_nosc":"chibouk","lemma":"chibouk","pos":"NOM"} ,
		{"word":"chibre","word_nosc":"chibre","lemma":"chibre","pos":"NOM"} ,
		{"word":"chic","word_nosc":"chic","lemma":"chic","pos":"NOM"} ,
		{"word":"chica","word_nosc":"chica","lemma":"chica","pos":"NOM"} ,
		{"word":"chicane","word_nosc":"chicane","lemma":"chicane","pos":"NOM"} ,
		{"word":"chicaneries","word_nosc":"chicaneries","lemma":"chicanerie","pos":"NOM"} ,
		{"word":"chicanes","word_nosc":"chicanes","lemma":"chicane","pos":"NOM"} ,
		{"word":"chicaneur","word_nosc":"chicaneur","lemma":"chicaneur","pos":"NOM"} ,
		{"word":"chicaniers","word_nosc":"chicaniers","lemma":"chicanier","pos":"NOM"} ,
		{"word":"chicano","word_nosc":"chicano","lemma":"chicano","pos":"NOM"} ,
		{"word":"chicanos","word_nosc":"chicanos","lemma":"chicano","pos":"NOM"} ,
		{"word":"chicas","word_nosc":"chicas","lemma":"chica","pos":"NOM"} ,
		{"word":"chiche-kebab","word_nosc":"chiche-kebab","lemma":"chiche-kebab","pos":"NOM"} ,
		{"word":"chichi","word_nosc":"chichi","lemma":"chichi","pos":"NOM"} ,
		{"word":"chichis","word_nosc":"chichis","lemma":"chichi","pos":"NOM"} ,
		{"word":"chicon","word_nosc":"chicon","lemma":"chicon","pos":"NOM"} ,
		{"word":"chicorée","word_nosc":"chicoree","lemma":"chicorée","pos":"NOM"} ,
		{"word":"chicorées","word_nosc":"chicorees","lemma":"chicorée","pos":"NOM"} ,
		{"word":"chicot","word_nosc":"chicot","lemma":"chicot","pos":"NOM"} ,
		{"word":"chicote","word_nosc":"chicote","lemma":"chicot","pos":"NOM"} ,
		{"word":"chicotin","word_nosc":"chicotin","lemma":"chicotin","pos":"NOM"} ,
		{"word":"chicots","word_nosc":"chicots","lemma":"chicot","pos":"NOM"} ,
		{"word":"chicotte","word_nosc":"chicotte","lemma":"chicotte","pos":"NOM"} ,
		{"word":"chics","word_nosc":"chics","lemma":"chic","pos":"NOM"} ,
		{"word":"chien","word_nosc":"chien","lemma":"chien","pos":"NOM"} ,
		{"word":"chien-assis","word_nosc":"chien-assis","lemma":"chien-assis","pos":"NOM"} ,
		{"word":"chien-loup","word_nosc":"chien-loup","lemma":"chien-loup","pos":"NOM"} ,
		{"word":"chien-robot","word_nosc":"chien-robot","lemma":"chien-robot","pos":"NOM"} ,
		{"word":"chien-étoile","word_nosc":"chien-etoile","lemma":"chien-étoile","pos":"NOM"} ,
		{"word":"chienchien","word_nosc":"chienchien","lemma":"chienchien","pos":"NOM"} ,
		{"word":"chienchiens","word_nosc":"chienchiens","lemma":"chienchien","pos":"NOM"} ,
		{"word":"chiendent","word_nosc":"chiendent","lemma":"chiendent","pos":"NOM"} ,
		{"word":"chienlit","word_nosc":"chienlit","lemma":"chienlit","pos":"NOM"} ,
		{"word":"chienne","word_nosc":"chienne","lemma":"chien","pos":"NOM"} ,
		{"word":"chiennerie","word_nosc":"chiennerie","lemma":"chiennerie","pos":"NOM"} ,
		{"word":"chienneries","word_nosc":"chienneries","lemma":"chiennerie","pos":"NOM"} ,
		{"word":"chiennes","word_nosc":"chiennes","lemma":"chien","pos":"NOM"} ,
		{"word":"chiens","word_nosc":"chiens","lemma":"chien","pos":"NOM"} ,
		{"word":"chiens-loups","word_nosc":"chiens-loups","lemma":"chien-loup","pos":"NOM"} ,
		{"word":"chierie","word_nosc":"chierie","lemma":"chierie","pos":"NOM"} ,
		{"word":"chieries","word_nosc":"chieries","lemma":"chierie","pos":"NOM"} ,
		{"word":"chieur","word_nosc":"chieur","lemma":"chieur","pos":"NOM"} ,
		{"word":"chieurs","word_nosc":"chieurs","lemma":"chieur","pos":"NOM"} ,
		{"word":"chieuse","word_nosc":"chieuse","lemma":"chieur","pos":"NOM"} ,
		{"word":"chieuses","word_nosc":"chieuses","lemma":"chieur","pos":"NOM"} ,
		{"word":"chiffe","word_nosc":"chiffe","lemma":"chiffe","pos":"NOM"} ,
		{"word":"chiffes","word_nosc":"chiffes","lemma":"chiffe","pos":"NOM"} ,
		{"word":"chiffon","word_nosc":"chiffon","lemma":"chiffon","pos":"NOM"} ,
		{"word":"chiffonnade","word_nosc":"chiffonnade","lemma":"chiffonnade","pos":"NOM"} ,
		{"word":"chiffonnades","word_nosc":"chiffonnades","lemma":"chiffonnade","pos":"NOM"} ,
		{"word":"chiffonnier","word_nosc":"chiffonnier","lemma":"chiffonnier","pos":"NOM"} ,
		{"word":"chiffonniers","word_nosc":"chiffonniers","lemma":"chiffonnier","pos":"NOM"} ,
		{"word":"chiffonnière","word_nosc":"chiffonniere","lemma":"chiffonnier","pos":"NOM"} ,
		{"word":"chiffonnières","word_nosc":"chiffonnieres","lemma":"chiffonnier","pos":"NOM"} ,
		{"word":"chiffons","word_nosc":"chiffons","lemma":"chiffon","pos":"NOM"} ,
		{"word":"chiffrage","word_nosc":"chiffrage","lemma":"chiffrage","pos":"NOM"} ,
		{"word":"chiffre","word_nosc":"chiffre","lemma":"chiffre","pos":"NOM"} ,
		{"word":"chiffrement","word_nosc":"chiffrement","lemma":"chiffrement","pos":"NOM"} ,
		{"word":"chiffres","word_nosc":"chiffres","lemma":"chiffre","pos":"NOM"} ,
		{"word":"chiffres-clés","word_nosc":"chiffres-cles","lemma":"chiffres-clé","pos":"NOM"} ,
		{"word":"chiffreur","word_nosc":"chiffreur","lemma":"chiffreur","pos":"NOM"} ,
		{"word":"chiffreuse","word_nosc":"chiffreuse","lemma":"chiffreur","pos":"NOM"} ,
		{"word":"chignole","word_nosc":"chignole","lemma":"chignole","pos":"NOM"} ,
		{"word":"chignoles","word_nosc":"chignoles","lemma":"chignole","pos":"NOM"} ,
		{"word":"chignon","word_nosc":"chignon","lemma":"chignon","pos":"NOM"} ,
		{"word":"chignons","word_nosc":"chignons","lemma":"chignon","pos":"NOM"} ,
		{"word":"chihuahua","word_nosc":"chihuahua","lemma":"chihuahua","pos":"NOM"} ,
		{"word":"chihuahuas","word_nosc":"chihuahuas","lemma":"chihuahua","pos":"NOM"} ,
		{"word":"chiites","word_nosc":"chiites","lemma":"chiite","pos":"NOM"} ,
		{"word":"chiles","word_nosc":"chiles","lemma":"chile","pos":"NOM"} ,
		{"word":"chili","word_nosc":"chili","lemma":"chili","pos":"NOM"} ,
		{"word":"chilien","word_nosc":"chilien","lemma":"chilien","pos":"NOM"} ,
		{"word":"chilienne","word_nosc":"chilienne","lemma":"chilienne","pos":"NOM"} ,
		{"word":"chiliennes","word_nosc":"chiliennes","lemma":"chilienne","pos":"NOM"} ,
		{"word":"chiliens","word_nosc":"chiliens","lemma":"chilien","pos":"NOM"} ,
		{"word":"chilis","word_nosc":"chilis","lemma":"chili","pos":"NOM"} ,
		{"word":"chilom","word_nosc":"chilom","lemma":"chilom","pos":"NOM"} ,
		{"word":"chimie","word_nosc":"chimie","lemma":"chimie","pos":"NOM"} ,
		{"word":"chimies","word_nosc":"chimies","lemma":"chimie","pos":"NOM"} ,
		{"word":"chimiothérapie","word_nosc":"chimiotherapie","lemma":"chimiothérapie","pos":"NOM"} ,
		{"word":"chimiste","word_nosc":"chimiste","lemma":"chimiste","pos":"NOM"} ,
		{"word":"chimistes","word_nosc":"chimistes","lemma":"chimiste","pos":"NOM"} ,
		{"word":"chimpanzé","word_nosc":"chimpanze","lemma":"chimpanzé","pos":"NOM"} ,
		{"word":"chimpanzés","word_nosc":"chimpanzes","lemma":"chimpanzé","pos":"NOM"} ,
		{"word":"chimère","word_nosc":"chimere","lemma":"chimère","pos":"NOM"} ,
		{"word":"chimères","word_nosc":"chimeres","lemma":"chimère","pos":"NOM"} ,
		{"word":"chinchard","word_nosc":"chinchard","lemma":"chinchard","pos":"NOM"} ,
		{"word":"chinchilla","word_nosc":"chinchilla","lemma":"chinchilla","pos":"NOM"} ,
		{"word":"chinchillas","word_nosc":"chinchillas","lemma":"chinchilla","pos":"NOM"} ,
		{"word":"chine","word_nosc":"chine","lemma":"chine","pos":"NOM"} ,
		{"word":"chinetoque","word_nosc":"chinetoque","lemma":"chinetoque","pos":"NOM"} ,
		{"word":"chinetoques","word_nosc":"chinetoques","lemma":"chinetoque","pos":"NOM"} ,
		{"word":"chineur","word_nosc":"chineur","lemma":"chineur","pos":"NOM"} ,
		{"word":"chineurs","word_nosc":"chineurs","lemma":"chineur","pos":"NOM"} ,
		{"word":"chinois","word_nosc":"chinois","lemma":"chinois","pos":"NOM"} ,
		{"word":"chinoise","word_nosc":"chinoise","lemma":"chinois","pos":"NOM"} ,
		{"word":"chinoiserie","word_nosc":"chinoiserie","lemma":"chinoiserie","pos":"NOM"} ,
		{"word":"chinoiseries","word_nosc":"chinoiseries","lemma":"chinoiserie","pos":"NOM"} ,
		{"word":"chinoises","word_nosc":"chinoises","lemma":"chinois","pos":"NOM"} ,
		{"word":"chinook","word_nosc":"chinook","lemma":"chinook","pos":"NOM"} ,
		{"word":"chintz","word_nosc":"chintz","lemma":"chintz","pos":"NOM"} ,
		{"word":"chiot","word_nosc":"chiot","lemma":"chiot","pos":"NOM"} ,
		{"word":"chiots","word_nosc":"chiots","lemma":"chiot","pos":"NOM"} ,
		{"word":"chiotte","word_nosc":"chiotte","lemma":"chiotte","pos":"NOM"} ,
		{"word":"chiottes","word_nosc":"chiottes","lemma":"chiotte","pos":"NOM"} ,
		{"word":"chiourme","word_nosc":"chiourme","lemma":"chiourme","pos":"NOM"} ,
		{"word":"chip","word_nosc":"chip","lemma":"chip","pos":"NOM"} ,
		{"word":"chipie","word_nosc":"chipie","lemma":"chipie","pos":"NOM"} ,
		{"word":"chipies","word_nosc":"chipies","lemma":"chipie","pos":"NOM"} ,
		{"word":"chipolata","word_nosc":"chipolata","lemma":"chipolata","pos":"NOM"} ,
		{"word":"chipolatas","word_nosc":"chipolatas","lemma":"chipolata","pos":"NOM"} ,
		{"word":"chipotage","word_nosc":"chipotage","lemma":"chipotage","pos":"NOM"} ,
		{"word":"chipotages","word_nosc":"chipotages","lemma":"chipotage","pos":"NOM"} ,
		{"word":"chippendale","word_nosc":"chippendale","lemma":"chippendale","pos":"NOM"} ,
		{"word":"chippendales","word_nosc":"chippendales","lemma":"chippendale","pos":"NOM"} ,
		{"word":"chips","word_nosc":"chips","lemma":"chips","pos":"NOM"} ,
		{"word":"chique","word_nosc":"chique","lemma":"chique","pos":"NOM"} ,
		{"word":"chiquenaude","word_nosc":"chiquenaude","lemma":"chiquenaude","pos":"NOM"} ,
		{"word":"chiquenaudes","word_nosc":"chiquenaudes","lemma":"chiquenaude","pos":"NOM"} ,
		{"word":"chiques","word_nosc":"chiques","lemma":"chique","pos":"NOM"} ,
		{"word":"chiqueur","word_nosc":"chiqueur","lemma":"chiqueur","pos":"NOM"} ,
		{"word":"chiqueurs","word_nosc":"chiqueurs","lemma":"chiqueur","pos":"NOM"} ,
		{"word":"chiqueuses","word_nosc":"chiqueuses","lemma":"chiqueur","pos":"NOM"} ,
		{"word":"chiqué","word_nosc":"chique","lemma":"chiqué","pos":"NOM"} ,
		{"word":"chiqués","word_nosc":"chiques","lemma":"chiqué","pos":"NOM"} ,
		{"word":"chiricahua","word_nosc":"chiricahua","lemma":"chiricahua","pos":"NOM"} ,
		{"word":"chiricahuas","word_nosc":"chiricahuas","lemma":"chiricahua","pos":"NOM"} ,
		{"word":"chiromancie","word_nosc":"chiromancie","lemma":"chiromancie","pos":"NOM"} ,
		{"word":"chiromancien","word_nosc":"chiromancien","lemma":"chiromancien","pos":"NOM"} ,
		{"word":"chiromancienne","word_nosc":"chiromancienne","lemma":"chiromancien","pos":"NOM"} ,
		{"word":"chiromanciens","word_nosc":"chiromanciens","lemma":"chiromancien","pos":"NOM"} ,
		{"word":"chiropracteur","word_nosc":"chiropracteur","lemma":"chiropracteur","pos":"NOM"} ,
		{"word":"chiropraticien","word_nosc":"chiropraticien","lemma":"chiropraticien","pos":"NOM"} ,
		{"word":"chiropraticienne","word_nosc":"chiropraticienne","lemma":"chiropraticien","pos":"NOM"} ,
		{"word":"chiropratique","word_nosc":"chiropratique","lemma":"chiropratique","pos":"NOM"} ,
		{"word":"chiropraxie","word_nosc":"chiropraxie","lemma":"chiropraxie","pos":"NOM"} ,
		{"word":"chiroptère","word_nosc":"chiroptere","lemma":"chiroptère","pos":"NOM"} ,
		{"word":"chiroubles","word_nosc":"chiroubles","lemma":"chiroubles","pos":"NOM"} ,
		{"word":"chirurgie","word_nosc":"chirurgie","lemma":"chirurgie","pos":"NOM"} ,
		{"word":"chirurgien","word_nosc":"chirurgien","lemma":"chirurgien","pos":"NOM"} ,
		{"word":"chirurgien-dentiste","word_nosc":"chirurgien-dentiste","lemma":"chirurgien-dentiste","pos":"NOM"} ,
		{"word":"chirurgienne","word_nosc":"chirurgienne","lemma":"chirurgien","pos":"NOM"} ,
		{"word":"chirurgiens","word_nosc":"chirurgiens","lemma":"chirurgien","pos":"NOM"} ,
		{"word":"chirurgies","word_nosc":"chirurgies","lemma":"chirurgie","pos":"NOM"} ,
		{"word":"chisel","word_nosc":"chisel","lemma":"chisel","pos":"NOM"} ,
		{"word":"chistera","word_nosc":"chistera","lemma":"chistera","pos":"NOM"} ,
		{"word":"chitine","word_nosc":"chitine","lemma":"chitine","pos":"NOM"} ,
		{"word":"chiton","word_nosc":"chiton","lemma":"chiton","pos":"NOM"} ,
		{"word":"chiure","word_nosc":"chiure","lemma":"chiure","pos":"NOM"} ,
		{"word":"chiures","word_nosc":"chiures","lemma":"chiure","pos":"NOM"} ,
		{"word":"chiée","word_nosc":"chiee","lemma":"chiée","pos":"NOM"} ,
		{"word":"chiées","word_nosc":"chiees","lemma":"chiée","pos":"NOM"} ,
		{"word":"chlamyde","word_nosc":"chlamyde","lemma":"chlamyde","pos":"NOM"} ,
		{"word":"chlamydia","word_nosc":"chlamydia","lemma":"chlamydia","pos":"NOM"} ,
		{"word":"chlamydiae","word_nosc":"chlamydiae","lemma":"chlamydia","pos":"NOM"} ,
		{"word":"chleuh","word_nosc":"chleuh","lemma":"chleuh","pos":"NOM"} ,
		{"word":"chleuhs","word_nosc":"chleuhs","lemma":"chleuh","pos":"NOM"} ,
		{"word":"chloral","word_nosc":"chloral","lemma":"chloral","pos":"NOM"} ,
		{"word":"chloramphénicol","word_nosc":"chloramphenicol","lemma":"chloramphénicol","pos":"NOM"} ,
		{"word":"chlorate","word_nosc":"chlorate","lemma":"chlorate","pos":"NOM"} ,
		{"word":"chlore","word_nosc":"chlore","lemma":"chlore","pos":"NOM"} ,
		{"word":"chlorhydrate","word_nosc":"chlorhydrate","lemma":"chlorhydrate","pos":"NOM"} ,
		{"word":"chloroforme","word_nosc":"chloroforme","lemma":"chloroforme","pos":"NOM"} ,
		{"word":"chloroformisation","word_nosc":"chloroformisation","lemma":"chloroformisation","pos":"NOM"} ,
		{"word":"chlorophylle","word_nosc":"chlorophylle","lemma":"chlorophylle","pos":"NOM"} ,
		{"word":"chlorophytums","word_nosc":"chlorophytums","lemma":"chlorophytum","pos":"NOM"} ,
		{"word":"chloroplaste","word_nosc":"chloroplaste","lemma":"chloroplaste","pos":"NOM"} ,
		{"word":"chloroquine","word_nosc":"chloroquine","lemma":"chloroquine","pos":"NOM"} ,
		{"word":"chlorpromazine","word_nosc":"chlorpromazine","lemma":"chlorpromazine","pos":"NOM"} ,
		{"word":"chlorure","word_nosc":"chlorure","lemma":"chlorure","pos":"NOM"} ,
		{"word":"chlorures","word_nosc":"chlorures","lemma":"chlorure","pos":"NOM"} ,
		{"word":"chnoque","word_nosc":"chnoque","lemma":"chnoque","pos":"NOM"} ,
		{"word":"chnoques","word_nosc":"chnoques","lemma":"chnoque","pos":"NOM"} ,
		{"word":"chnord","word_nosc":"chnord","lemma":"chnord","pos":"NOM"} ,
		{"word":"chnouf","word_nosc":"chnouf","lemma":"chnouf","pos":"NOM"} ,
		{"word":"choanes","word_nosc":"choanes","lemma":"choane","pos":"NOM"} ,
		{"word":"choc","word_nosc":"choc","lemma":"choc","pos":"NOM"} ,
		{"word":"chocard","word_nosc":"chocard","lemma":"chocard","pos":"NOM"} ,
		{"word":"chochotte","word_nosc":"chochotte","lemma":"chochotte","pos":"NOM"} ,
		{"word":"chochottes","word_nosc":"chochottes","lemma":"chochotte","pos":"NOM"} ,
		{"word":"chocolat","word_nosc":"chocolat","lemma":"chocolat","pos":"NOM"} ,
		{"word":"chocolaterie","word_nosc":"chocolaterie","lemma":"chocolaterie","pos":"NOM"} ,
		{"word":"chocolateries","word_nosc":"chocolateries","lemma":"chocolaterie","pos":"NOM"} ,
		{"word":"chocolatier","word_nosc":"chocolatier","lemma":"chocolatier","pos":"NOM"} ,
		{"word":"chocolatière","word_nosc":"chocolatiere","lemma":"chocolatier","pos":"NOM"} ,
		{"word":"chocolats","word_nosc":"chocolats","lemma":"chocolat","pos":"NOM"} ,
		{"word":"chocottes","word_nosc":"chocottes","lemma":"chocotte","pos":"NOM"} ,
		{"word":"chocs","word_nosc":"chocs","lemma":"choc","pos":"NOM"} ,
		{"word":"choeur","word_nosc":"choeur","lemma":"choeur","pos":"NOM"} ,
		{"word":"choeurs","word_nosc":"choeurs","lemma":"choeur","pos":"NOM"} ,
		{"word":"choix","word_nosc":"choix","lemma":"choix","pos":"NOM"} ,
		{"word":"choke","word_nosc":"choke","lemma":"choke","pos":"NOM"} ,
		{"word":"cholestérol","word_nosc":"cholesterol","lemma":"cholestérol","pos":"NOM"} ,
		{"word":"choline","word_nosc":"choline","lemma":"choline","pos":"NOM"} ,
		{"word":"cholines","word_nosc":"cholines","lemma":"choline","pos":"NOM"} ,
		{"word":"cholinestérase","word_nosc":"cholinesterase","lemma":"cholinestérase","pos":"NOM"} ,
		{"word":"cholécystectomie","word_nosc":"cholecystectomie","lemma":"cholécystectomie","pos":"NOM"} ,
		{"word":"cholécystite","word_nosc":"cholecystite","lemma":"cholécystite","pos":"NOM"} ,
		{"word":"choléra","word_nosc":"cholera","lemma":"choléra","pos":"NOM"} ,
		{"word":"choléras","word_nosc":"choleras","lemma":"choléra","pos":"NOM"} ,
		{"word":"cholériques","word_nosc":"choleriques","lemma":"cholérique","pos":"NOM"} ,
		{"word":"chop suey","word_nosc":"chop suey","lemma":"chop suey","pos":"NOM"} ,
		{"word":"chope","word_nosc":"chope","lemma":"chope","pos":"NOM"} ,
		{"word":"chopes","word_nosc":"chopes","lemma":"chope","pos":"NOM"} ,
		{"word":"chopine","word_nosc":"chopine","lemma":"chopine","pos":"NOM"} ,
		{"word":"chopines","word_nosc":"chopines","lemma":"chopine","pos":"NOM"} ,
		{"word":"chopper","word_nosc":"chopper","lemma":"chopper","pos":"NOM"} ,
		{"word":"choppers","word_nosc":"choppers","lemma":"chopper","pos":"NOM"} ,
		{"word":"choral","word_nosc":"choral","lemma":"choral","pos":"NOM"} ,
		{"word":"chorale","word_nosc":"chorale","lemma":"chorale","pos":"NOM"} ,
		{"word":"chorales","word_nosc":"chorales","lemma":"chorale","pos":"NOM"} ,
		{"word":"chorals","word_nosc":"chorals","lemma":"choral","pos":"NOM"} ,
		{"word":"chorba","word_nosc":"chorba","lemma":"chorba","pos":"NOM"} ,
		{"word":"choreutes","word_nosc":"choreutes","lemma":"choreute","pos":"NOM"} ,
		{"word":"choriste","word_nosc":"choriste","lemma":"choriste","pos":"NOM"} ,
		{"word":"choristes","word_nosc":"choristes","lemma":"choriste","pos":"NOM"} ,
		{"word":"chorizo","word_nosc":"chorizo","lemma":"chorizo","pos":"NOM"} ,
		{"word":"chorizos","word_nosc":"chorizos","lemma":"chorizo","pos":"NOM"} ,
		{"word":"chortens","word_nosc":"chortens","lemma":"chorten","pos":"NOM"} ,
		{"word":"chorus","word_nosc":"chorus","lemma":"chorus","pos":"NOM"} ,
		{"word":"chorée","word_nosc":"choree","lemma":"chorée","pos":"NOM"} ,
		{"word":"chorégraphe","word_nosc":"choregraphe","lemma":"chorégraphe","pos":"NOM"} ,
		{"word":"chorégraphes","word_nosc":"choregraphes","lemma":"chorégraphe","pos":"NOM"} ,
		{"word":"chorégraphie","word_nosc":"choregraphie","lemma":"chorégraphie","pos":"NOM"} ,
		{"word":"chorégraphies","word_nosc":"choregraphies","lemma":"chorégraphie","pos":"NOM"} ,
		{"word":"chose","word_nosc":"chose","lemma":"chose","pos":"NOM"} ,
		{"word":"choses","word_nosc":"choses","lemma":"chose","pos":"NOM"} ,
		{"word":"chosification","word_nosc":"chosification","lemma":"chosification","pos":"NOM"} ,
		{"word":"chotts","word_nosc":"chotts","lemma":"chott","pos":"NOM"} ,
		{"word":"chou","word_nosc":"chou","lemma":"chou","pos":"NOM"} ,
		{"word":"chou-fleur","word_nosc":"chou-fleur","lemma":"chou-fleur","pos":"NOM"} ,
		{"word":"chou-palmiste","word_nosc":"chou-palmiste","lemma":"chou-palmiste","pos":"NOM"} ,
		{"word":"chou-rave","word_nosc":"chou-rave","lemma":"chou-rave","pos":"NOM"} ,
		{"word":"chouan","word_nosc":"chouan","lemma":"chouan","pos":"NOM"} ,
		{"word":"chouannerie","word_nosc":"chouannerie","lemma":"chouannerie","pos":"NOM"} ,
		{"word":"chouans","word_nosc":"chouans","lemma":"chouan","pos":"NOM"} ,
		{"word":"choucas","word_nosc":"choucas","lemma":"choucas","pos":"NOM"} ,
		{"word":"chouchou","word_nosc":"chouchou","lemma":"chouchou","pos":"NOM"} ,
		{"word":"chouchous","word_nosc":"chouchous","lemma":"chouchou","pos":"NOM"} ,
		{"word":"chouchoutage","word_nosc":"chouchoutage","lemma":"chouchoutage","pos":"NOM"} ,
		{"word":"chouchoute","word_nosc":"chouchoute","lemma":"chouchoute","pos":"NOM"} ,
		{"word":"chouchoutes","word_nosc":"chouchoutes","lemma":"chouchoute","pos":"NOM"} ,
		{"word":"choucroute","word_nosc":"choucroute","lemma":"choucroute","pos":"NOM"} ,
		{"word":"choucroutes","word_nosc":"choucroutes","lemma":"choucroute","pos":"NOM"} ,
		{"word":"chouette","word_nosc":"chouette","lemma":"chouette","pos":"NOM"} ,
		{"word":"chouettes","word_nosc":"chouettes","lemma":"chouette","pos":"NOM"} ,
		{"word":"chouia","word_nosc":"chouia","lemma":"chouia","pos":"NOM"} ,
		{"word":"choupette","word_nosc":"choupette","lemma":"choupette","pos":"NOM"} ,
		{"word":"chouquet","word_nosc":"chouquet","lemma":"chouquet","pos":"NOM"} ,
		{"word":"chouquette","word_nosc":"chouquette","lemma":"chouquette","pos":"NOM"} ,
		{"word":"chouraveur","word_nosc":"chouraveur","lemma":"chouraveur","pos":"NOM"} ,
		{"word":"chouraveurs","word_nosc":"chouraveurs","lemma":"chouraveur","pos":"NOM"} ,
		{"word":"chouraveuse","word_nosc":"chouraveuse","lemma":"chouraveur","pos":"NOM"} ,
		{"word":"chouraveuses","word_nosc":"chouraveuses","lemma":"chouraveur","pos":"NOM"} ,
		{"word":"chourineurs","word_nosc":"chourineurs","lemma":"chourineur","pos":"NOM"} ,
		{"word":"choute","word_nosc":"choute","lemma":"chou","pos":"NOM"} ,
		{"word":"choutes","word_nosc":"choutes","lemma":"chou","pos":"NOM"} ,
		{"word":"choux","word_nosc":"choux","lemma":"chou","pos":"NOM"} ,
		{"word":"choux-fleurs","word_nosc":"choux-fleurs","lemma":"choux-fleurs","pos":"NOM"} ,
		{"word":"choux-raves","word_nosc":"choux-raves","lemma":"choux-raves","pos":"NOM"} ,
		{"word":"chouya","word_nosc":"chouya","lemma":"chouya","pos":"NOM"} ,
		{"word":"chouïa","word_nosc":"chouia","lemma":"chouïa","pos":"NOM"} ,
		{"word":"christ","word_nosc":"christ","lemma":"christ","pos":"NOM"} ,
		{"word":"christiania","word_nosc":"christiania","lemma":"christiania","pos":"NOM"} ,
		{"word":"christianisme","word_nosc":"christianisme","lemma":"christianisme","pos":"NOM"} ,
		{"word":"christmas","word_nosc":"christmas","lemma":"christmas","pos":"NOM"} ,
		{"word":"christocentrisme","word_nosc":"christocentrisme","lemma":"christocentrisme","pos":"NOM"} ,
		{"word":"christs","word_nosc":"christs","lemma":"christ","pos":"NOM"} ,
		{"word":"chromage","word_nosc":"chromage","lemma":"chromage","pos":"NOM"} ,
		{"word":"chromatisme","word_nosc":"chromatisme","lemma":"chromatisme","pos":"NOM"} ,
		{"word":"chromatismes","word_nosc":"chromatismes","lemma":"chromatisme","pos":"NOM"} ,
		{"word":"chromatogramme","word_nosc":"chromatogramme","lemma":"chromatogramme","pos":"NOM"} ,
		{"word":"chromatographie","word_nosc":"chromatographie","lemma":"chromatographie","pos":"NOM"} ,
		{"word":"chrome","word_nosc":"chrome","lemma":"chrome","pos":"NOM"} ,
		{"word":"chromes","word_nosc":"chromes","lemma":"chrome","pos":"NOM"} ,
		{"word":"chromo","word_nosc":"chromo","lemma":"chromo","pos":"NOM"} ,
		{"word":"chromos","word_nosc":"chromos","lemma":"chromo","pos":"NOM"} ,
		{"word":"chromosome","word_nosc":"chromosome","lemma":"chromosome","pos":"NOM"} ,
		{"word":"chromosomes","word_nosc":"chromosomes","lemma":"chromosome","pos":"NOM"} ,
		{"word":"chromosphère","word_nosc":"chromosphere","lemma":"chromosphère","pos":"NOM"} ,
		{"word":"chronique","word_nosc":"chronique","lemma":"chronique","pos":"NOM"} ,
		{"word":"chroniques","word_nosc":"chroniques","lemma":"chronique","pos":"NOM"} ,
		{"word":"chroniqueur","word_nosc":"chroniqueur","lemma":"chroniqueur","pos":"NOM"} ,
		{"word":"chroniqueurs","word_nosc":"chroniqueurs","lemma":"chroniqueur","pos":"NOM"} ,
		{"word":"chroniqueuse","word_nosc":"chroniqueuse","lemma":"chroniqueur","pos":"NOM"} ,
		{"word":"chroniqueuses","word_nosc":"chroniqueuses","lemma":"chroniqueur","pos":"NOM"} ,
		{"word":"chrono","word_nosc":"chrono","lemma":"chrono","pos":"NOM"} ,
		{"word":"chronographe","word_nosc":"chronographe","lemma":"chronographe","pos":"NOM"} ,
		{"word":"chronologie","word_nosc":"chronologie","lemma":"chronologie","pos":"NOM"} ,
		{"word":"chronomètre","word_nosc":"chronometre","lemma":"chronomètre","pos":"NOM"} ,
		{"word":"chronomètres","word_nosc":"chronometres","lemma":"chronomètre","pos":"NOM"} ,
		{"word":"chronométrage","word_nosc":"chronometrage","lemma":"chronométrage","pos":"NOM"} ,
		{"word":"chronométreur","word_nosc":"chronometreur","lemma":"chronométreur","pos":"NOM"} ,
		{"word":"chronophotographie","word_nosc":"chronophotographie","lemma":"chronophotographie","pos":"NOM"} ,
		{"word":"chronos","word_nosc":"chronos","lemma":"chrono","pos":"NOM"} ,
		{"word":"chrysalide","word_nosc":"chrysalide","lemma":"chrysalide","pos":"NOM"} ,
		{"word":"chrysalides","word_nosc":"chrysalides","lemma":"chrysalide","pos":"NOM"} ,
		{"word":"chrysanthème","word_nosc":"chrysantheme","lemma":"chrysanthème","pos":"NOM"} ,
		{"word":"chrysanthèmes","word_nosc":"chrysanthemes","lemma":"chrysanthème","pos":"NOM"} ,
		{"word":"chrysolite","word_nosc":"chrysolite","lemma":"chrysolite","pos":"NOM"} ,
		{"word":"chrysolithe","word_nosc":"chrysolithe","lemma":"chrysolithe","pos":"NOM"} ,
		{"word":"chrysostome","word_nosc":"chrysostome","lemma":"chrysostome","pos":"NOM"} ,
		{"word":"chrétien","word_nosc":"chretien","lemma":"chrétien","pos":"NOM"} ,
		{"word":"chrétienne","word_nosc":"chretienne","lemma":"chrétien","pos":"NOM"} ,
		{"word":"chrétiennes","word_nosc":"chretiennes","lemma":"chrétien","pos":"NOM"} ,
		{"word":"chrétiens","word_nosc":"chretiens","lemma":"chrétien","pos":"NOM"} ,
		{"word":"chrétienté","word_nosc":"chretiente","lemma":"chrétienté","pos":"NOM"} ,
		{"word":"chrême","word_nosc":"chreme","lemma":"chrême","pos":"NOM"} ,
		{"word":"chtar","word_nosc":"chtar","lemma":"chtar","pos":"NOM"} ,
		{"word":"chtibe","word_nosc":"chtibe","lemma":"chtibe","pos":"NOM"} ,
		{"word":"chtimi","word_nosc":"chtimi","lemma":"chtimi","pos":"NOM"} ,
		{"word":"chtimis","word_nosc":"chtimis","lemma":"chtimi","pos":"NOM"} ,
		{"word":"chtouille","word_nosc":"chtouille","lemma":"chtouille","pos":"NOM"} ,
		{"word":"chtourbe","word_nosc":"chtourbe","lemma":"chtourbe","pos":"NOM"} ,
		{"word":"chuchotement","word_nosc":"chuchotement","lemma":"chuchotement","pos":"NOM"} ,
		{"word":"chuchotements","word_nosc":"chuchotements","lemma":"chuchotement","pos":"NOM"} ,
		{"word":"chuchoteries","word_nosc":"chuchoteries","lemma":"chuchoterie","pos":"NOM"} ,
		{"word":"chuchoteuse","word_nosc":"chuchoteuse","lemma":"chuchoteur","pos":"NOM"} ,
		{"word":"chuchotis","word_nosc":"chuchotis","lemma":"chuchotis","pos":"NOM"} ,
		{"word":"chuintante","word_nosc":"chuintante","lemma":"chuintante","pos":"NOM"} ,
		{"word":"chuintement","word_nosc":"chuintement","lemma":"chuintement","pos":"NOM"} ,
		{"word":"chuintements","word_nosc":"chuintements","lemma":"chuintement","pos":"NOM"} ,
		{"word":"chulo","word_nosc":"chulo","lemma":"chulo","pos":"NOM"} ,
		{"word":"chum","word_nosc":"chum","lemma":"chum","pos":"NOM"} ,
		{"word":"churros","word_nosc":"churros","lemma":"churro","pos":"NOM"} ,
		{"word":"chute","word_nosc":"chute","lemma":"chute","pos":"NOM"} ,
		{"word":"chutes","word_nosc":"chutes","lemma":"chute","pos":"NOM"} ,
		{"word":"chutney","word_nosc":"chutney","lemma":"chutney","pos":"NOM"} ,
		{"word":"chyle","word_nosc":"chyle","lemma":"chyle","pos":"NOM"} ,
		{"word":"chypre","word_nosc":"chypre","lemma":"chypre","pos":"NOM"} ,
		{"word":"châle","word_nosc":"chale","lemma":"châle","pos":"NOM"} ,
		{"word":"châles","word_nosc":"chales","lemma":"châle","pos":"NOM"} ,
		{"word":"châlit","word_nosc":"chalit","lemma":"châlit","pos":"NOM"} ,
		{"word":"châlits","word_nosc":"chalits","lemma":"châlit","pos":"NOM"} ,
		{"word":"châlonnais","word_nosc":"chalonnais","lemma":"châlonnais","pos":"NOM"} ,
		{"word":"châsse","word_nosc":"chasse","lemma":"châsse","pos":"NOM"} ,
		{"word":"châsses","word_nosc":"chasses","lemma":"châsse","pos":"NOM"} ,
		{"word":"châssis","word_nosc":"chassis","lemma":"châssis","pos":"NOM"} ,
		{"word":"châtaigne","word_nosc":"chataigne","lemma":"châtaigne","pos":"NOM"} ,
		{"word":"châtaigneraie","word_nosc":"chataigneraie","lemma":"châtaigneraie","pos":"NOM"} ,
		{"word":"châtaigneraies","word_nosc":"chataigneraies","lemma":"châtaigneraie","pos":"NOM"} ,
		{"word":"châtaignes","word_nosc":"chataignes","lemma":"châtaigne","pos":"NOM"} ,
		{"word":"châtaignier","word_nosc":"chataignier","lemma":"châtaignier","pos":"NOM"} ,
		{"word":"châtaigniers","word_nosc":"chataigniers","lemma":"châtaignier","pos":"NOM"} ,
		{"word":"châtain","word_nosc":"chatain","lemma":"châtain","pos":"NOM"} ,
		{"word":"châtains","word_nosc":"chatains","lemma":"châtain","pos":"NOM"} ,
		{"word":"château","word_nosc":"chateau","lemma":"château","pos":"NOM"} ,
		{"word":"château-fort","word_nosc":"chateau-fort","lemma":"château-fort","pos":"NOM"} ,
		{"word":"châteaubriant","word_nosc":"chateaubriant","lemma":"châteaubriant","pos":"NOM"} ,
		{"word":"châteaux","word_nosc":"chateaux","lemma":"château","pos":"NOM"} ,
		{"word":"châtelain","word_nosc":"chatelain","lemma":"châtelain","pos":"NOM"} ,
		{"word":"châtelaine","word_nosc":"chatelaine","lemma":"châtelain","pos":"NOM"} ,
		{"word":"châtelaines","word_nosc":"chatelaines","lemma":"châtelain","pos":"NOM"} ,
		{"word":"châtelains","word_nosc":"chatelains","lemma":"châtelain","pos":"NOM"} ,
		{"word":"châtelet","word_nosc":"chatelet","lemma":"châtelet","pos":"NOM"} ,
		{"word":"châtellenie","word_nosc":"chatellenie","lemma":"châtellenie","pos":"NOM"} ,
		{"word":"châtiment","word_nosc":"chatiment","lemma":"châtiment","pos":"NOM"} ,
		{"word":"châtiments","word_nosc":"chatiments","lemma":"châtiment","pos":"NOM"} ,
		{"word":"châtrage","word_nosc":"chatrage","lemma":"châtrage","pos":"NOM"} ,
		{"word":"châtron","word_nosc":"chatron","lemma":"châtron","pos":"NOM"} ,
		{"word":"châtrons","word_nosc":"chatrons","lemma":"châtron","pos":"NOM"} ,
		{"word":"chèche","word_nosc":"cheche","lemma":"chèche","pos":"NOM"} ,
		{"word":"chèches","word_nosc":"cheches","lemma":"chèche","pos":"NOM"} ,
		{"word":"chènevis","word_nosc":"chenevis","lemma":"chènevis","pos":"NOM"} ,
		{"word":"chèque","word_nosc":"cheque","lemma":"chèque","pos":"NOM"} ,
		{"word":"chèque-cadeau","word_nosc":"cheque-cadeau","lemma":"chèque-cadeau","pos":"NOM"} ,
		{"word":"chèques","word_nosc":"cheques","lemma":"chèque","pos":"NOM"} ,
		{"word":"chère","word_nosc":"chere","lemma":"cher","pos":"NOM"} ,
		{"word":"chères","word_nosc":"cheres","lemma":"cher","pos":"NOM"} ,
		{"word":"chèvre","word_nosc":"chevre","lemma":"chèvre","pos":"NOM"} ,
		{"word":"chèvre-pied","word_nosc":"chevre-pied","lemma":"chèvre-pied","pos":"NOM"} ,
		{"word":"chèvrefeuille","word_nosc":"chevrefeuille","lemma":"chèvrefeuille","pos":"NOM"} ,
		{"word":"chèvrefeuilles","word_nosc":"chevrefeuilles","lemma":"chèvrefeuille","pos":"NOM"} ,
		{"word":"chèvres","word_nosc":"chevres","lemma":"chèvre","pos":"NOM"} ,
		{"word":"chéchia","word_nosc":"chechia","lemma":"chéchia","pos":"NOM"} ,
		{"word":"chéchias","word_nosc":"chechias","lemma":"chéchia","pos":"NOM"} ,
		{"word":"chéfesse","word_nosc":"chefesse","lemma":"chéfesse","pos":"NOM"} ,
		{"word":"chélate","word_nosc":"chelate","lemma":"chélate","pos":"NOM"} ,
		{"word":"chélidoine","word_nosc":"chelidoine","lemma":"chélidoine","pos":"NOM"} ,
		{"word":"chélidoines","word_nosc":"chelidoines","lemma":"chélidoine","pos":"NOM"} ,
		{"word":"chéloïde","word_nosc":"cheloide","lemma":"chéloïde","pos":"NOM"} ,
		{"word":"chéneau","word_nosc":"cheneau","lemma":"chéneau","pos":"NOM"} ,
		{"word":"chéneaux","word_nosc":"cheneaux","lemma":"chéneau","pos":"NOM"} ,
		{"word":"chéquier","word_nosc":"chequier","lemma":"chéquier","pos":"NOM"} ,
		{"word":"chéquiers","word_nosc":"chequiers","lemma":"chéquier","pos":"NOM"} ,
		{"word":"chéri","word_nosc":"cheri","lemma":"chéri","pos":"NOM"} ,
		{"word":"chérie","word_nosc":"cherie","lemma":"chéri","pos":"NOM"} ,
		{"word":"chéries","word_nosc":"cheries","lemma":"chéri","pos":"NOM"} ,
		{"word":"chérif","word_nosc":"cherif","lemma":"chérif","pos":"NOM"} ,
		{"word":"chéris","word_nosc":"cheris","lemma":"chéri","pos":"NOM"} ,
		{"word":"chérubin","word_nosc":"cherubin","lemma":"chérubin","pos":"NOM"} ,
		{"word":"chérubins","word_nosc":"cherubins","lemma":"chérubin","pos":"NOM"} ,
		{"word":"chétivité","word_nosc":"chetivite","lemma":"chétivité","pos":"NOM"} ,
		{"word":"chênaie","word_nosc":"chenaie","lemma":"chênaie","pos":"NOM"} ,
		{"word":"chênaies","word_nosc":"chenaies","lemma":"chênaie","pos":"NOM"} ,
		{"word":"chêne","word_nosc":"chene","lemma":"chêne","pos":"NOM"} ,
		{"word":"chêne-liège","word_nosc":"chene-liege","lemma":"chêne-liège","pos":"NOM"} ,
		{"word":"chênes","word_nosc":"chenes","lemma":"chêne","pos":"NOM"} ,
		{"word":"chênes-lièges","word_nosc":"chenes-lieges","lemma":"chêne-liège","pos":"NOM"} ,
		{"word":"chêneteau","word_nosc":"cheneteau","lemma":"chêneteau","pos":"NOM"} ,
		{"word":"chômage","word_nosc":"chomage","lemma":"chômage","pos":"NOM"} ,
		{"word":"chômedu","word_nosc":"chomedu","lemma":"chômedu","pos":"NOM"} ,
		{"word":"chômeur","word_nosc":"chomeur","lemma":"chômeur","pos":"NOM"} ,
		{"word":"chômeurs","word_nosc":"chomeurs","lemma":"chômeur","pos":"NOM"} ,
		{"word":"chômeuse","word_nosc":"chomeuse","lemma":"chômeur","pos":"NOM"} ,
		{"word":"chômeuses","word_nosc":"chomeuses","lemma":"chômeur","pos":"NOM"} ,
		{"word":"cibiche","word_nosc":"cibiche","lemma":"cibiche","pos":"NOM"} ,
		{"word":"cibiches","word_nosc":"cibiches","lemma":"cibiche","pos":"NOM"} ,
		{"word":"cibiste","word_nosc":"cibiste","lemma":"cibiste","pos":"NOM"} ,
		{"word":"cibistes","word_nosc":"cibistes","lemma":"cibiste","pos":"NOM"} ,
		{"word":"ciblage","word_nosc":"ciblage","lemma":"ciblage","pos":"NOM"} ,
		{"word":"cible","word_nosc":"cible","lemma":"cible","pos":"NOM"} ,
		{"word":"cibles","word_nosc":"cibles","lemma":"cible","pos":"NOM"} ,
		{"word":"ciboire","word_nosc":"ciboire","lemma":"ciboire","pos":"NOM"} ,
		{"word":"ciboires","word_nosc":"ciboires","lemma":"ciboire","pos":"NOM"} ,
		{"word":"ciboule","word_nosc":"ciboule","lemma":"ciboule","pos":"NOM"} ,
		{"word":"ciboulette","word_nosc":"ciboulette","lemma":"ciboulette","pos":"NOM"} ,
		{"word":"ciboulot","word_nosc":"ciboulot","lemma":"ciboulot","pos":"NOM"} ,
		{"word":"ciboulots","word_nosc":"ciboulots","lemma":"ciboulot","pos":"NOM"} ,
		{"word":"cicatrice","word_nosc":"cicatrice","lemma":"cicatrice","pos":"NOM"} ,
		{"word":"cicatrices","word_nosc":"cicatrices","lemma":"cicatrice","pos":"NOM"} ,
		{"word":"cicatrisation","word_nosc":"cicatrisation","lemma":"cicatrisation","pos":"NOM"} ,
		{"word":"cicero","word_nosc":"cicero","lemma":"cicero","pos":"NOM"} ,
		{"word":"cicindèle","word_nosc":"cicindele","lemma":"cicindèle","pos":"NOM"} ,
		{"word":"cicindèles","word_nosc":"cicindeles","lemma":"cicindèle","pos":"NOM"} ,
		{"word":"cicérone","word_nosc":"cicerone","lemma":"cicérone","pos":"NOM"} ,
		{"word":"cicérones","word_nosc":"cicerones","lemma":"cicérone","pos":"NOM"} ,
		{"word":"cidre","word_nosc":"cidre","lemma":"cidre","pos":"NOM"} ,
		{"word":"cidrerie","word_nosc":"cidrerie","lemma":"cidrerie","pos":"NOM"} ,
		{"word":"ciel","word_nosc":"ciel","lemma":"ciel","pos":"NOM"} ,
		{"word":"ciels","word_nosc":"ciels","lemma":"ciel","pos":"NOM"} ,
		{"word":"cierge","word_nosc":"cierge","lemma":"cierge","pos":"NOM"} ,
		{"word":"cierges","word_nosc":"cierges","lemma":"cierge","pos":"NOM"} ,
		{"word":"cieux","word_nosc":"cieux","lemma":"cieux","pos":"NOM"} ,
		{"word":"cigale","word_nosc":"cigale","lemma":"cigale","pos":"NOM"} ,
		{"word":"cigales","word_nosc":"cigales","lemma":"cigale","pos":"NOM"} ,
		{"word":"cigalon","word_nosc":"cigalon","lemma":"cigalon","pos":"NOM"} ,
		{"word":"cigalou","word_nosc":"cigalou","lemma":"cigalou","pos":"NOM"} ,
		{"word":"cigare","word_nosc":"cigare","lemma":"cigare","pos":"NOM"} ,
		{"word":"cigares","word_nosc":"cigares","lemma":"cigare","pos":"NOM"} ,
		{"word":"cigarette","word_nosc":"cigarette","lemma":"cigarette","pos":"NOM"} ,
		{"word":"cigarettes","word_nosc":"cigarettes","lemma":"cigarette","pos":"NOM"} ,
		{"word":"cigarettier","word_nosc":"cigarettier","lemma":"cigarettier","pos":"NOM"} ,
		{"word":"cigarillo","word_nosc":"cigarillo","lemma":"cigarillo","pos":"NOM"} ,
		{"word":"cigarillos","word_nosc":"cigarillos","lemma":"cigarillo","pos":"NOM"} ,
		{"word":"cigarière","word_nosc":"cigariere","lemma":"cigarier","pos":"NOM"} ,
		{"word":"cigarières","word_nosc":"cigarieres","lemma":"cigarier","pos":"NOM"} ,
		{"word":"cigogne","word_nosc":"cigogne","lemma":"cigogne","pos":"NOM"} ,
		{"word":"cigognes","word_nosc":"cigognes","lemma":"cigogne","pos":"NOM"} ,
		{"word":"ciguë","word_nosc":"cigue","lemma":"ciguë","pos":"NOM"} ,
		{"word":"ciguës","word_nosc":"cigues","lemma":"ciguë","pos":"NOM"} ,
		{"word":"cil","word_nosc":"cil","lemma":"cil","pos":"NOM"} ,
		{"word":"cilice","word_nosc":"cilice","lemma":"cilice","pos":"NOM"} ,
		{"word":"cillement","word_nosc":"cillement","lemma":"cillement","pos":"NOM"} ,
		{"word":"cillements","word_nosc":"cillements","lemma":"cillement","pos":"NOM"} ,
		{"word":"cils","word_nosc":"cils","lemma":"cil","pos":"NOM"} ,
		{"word":"cimaise","word_nosc":"cimaise","lemma":"cimaise","pos":"NOM"} ,
		{"word":"cimaises","word_nosc":"cimaises","lemma":"cimaise","pos":"NOM"} ,
		{"word":"cimarron","word_nosc":"cimarron","lemma":"cimarron","pos":"NOM"} ,
		{"word":"cime","word_nosc":"cime","lemma":"cime","pos":"NOM"} ,
		{"word":"ciment","word_nosc":"ciment","lemma":"ciment","pos":"NOM"} ,
		{"word":"cimenterie","word_nosc":"cimenterie","lemma":"cimenterie","pos":"NOM"} ,
		{"word":"cimenteries","word_nosc":"cimenteries","lemma":"cimenterie","pos":"NOM"} ,
		{"word":"ciments","word_nosc":"ciments","lemma":"ciment","pos":"NOM"} ,
		{"word":"cimes","word_nosc":"cimes","lemma":"cime","pos":"NOM"} ,
		{"word":"cimeterre","word_nosc":"cimeterre","lemma":"cimeterre","pos":"NOM"} ,
		{"word":"cimeterres","word_nosc":"cimeterres","lemma":"cimeterre","pos":"NOM"} ,
		{"word":"cimetière","word_nosc":"cimetiere","lemma":"cimetière","pos":"NOM"} ,
		{"word":"cimetières","word_nosc":"cimetieres","lemma":"cimetière","pos":"NOM"} ,
		{"word":"cimier","word_nosc":"cimier","lemma":"cimier","pos":"NOM"} ,
		{"word":"cimiers","word_nosc":"cimiers","lemma":"cimier","pos":"NOM"} ,
		{"word":"cinabre","word_nosc":"cinabre","lemma":"cinabre","pos":"NOM"} ,
		{"word":"cinglage","word_nosc":"cinglage","lemma":"cinglage","pos":"NOM"} ,
		{"word":"cinglement","word_nosc":"cinglement","lemma":"cinglement","pos":"NOM"} ,
		{"word":"cinglons","word_nosc":"cinglons","lemma":"cinglon","pos":"NOM"} ,
		{"word":"cinglé","word_nosc":"cingle","lemma":"cinglé","pos":"NOM"} ,
		{"word":"cinglée","word_nosc":"cinglee","lemma":"cinglé","pos":"NOM"} ,
		{"word":"cinglées","word_nosc":"cinglees","lemma":"cinglé","pos":"NOM"} ,
		{"word":"cinglés","word_nosc":"cingles","lemma":"cinglé","pos":"NOM"} ,
		{"word":"cinname","word_nosc":"cinname","lemma":"cinname","pos":"NOM"} ,
		{"word":"cinoche","word_nosc":"cinoche","lemma":"cinoche","pos":"NOM"} ,
		{"word":"cinoches","word_nosc":"cinoches","lemma":"cinoche","pos":"NOM"} ,
		{"word":"cinoques","word_nosc":"cinoques","lemma":"cinoque","pos":"NOM"} ,
		{"word":"cinquantaine","word_nosc":"cinquantaine","lemma":"cinquantaine","pos":"NOM"} ,
		{"word":"cinquantaines","word_nosc":"cinquantaines","lemma":"cinquantaine","pos":"NOM"} ,
		{"word":"cinquante","word_nosc":"cinquante","lemma":"cinquante","pos":"NOM"} ,
		{"word":"cinquantenaire","word_nosc":"cinquantenaire","lemma":"cinquantenaire","pos":"NOM"} ,
		{"word":"cinquantenaires","word_nosc":"cinquantenaires","lemma":"cinquantenaire","pos":"NOM"} ,
		{"word":"cinquantième","word_nosc":"cinquantieme","lemma":"cinquantième","pos":"NOM"} ,
		{"word":"cinquième","word_nosc":"cinquieme","lemma":"cinquième","pos":"NOM"} ,
		{"word":"cinquièmes","word_nosc":"cinquiemes","lemma":"cinquième","pos":"NOM"} ,
		{"word":"cintrage","word_nosc":"cintrage","lemma":"cintrage","pos":"NOM"} ,
		{"word":"cintre","word_nosc":"cintre","lemma":"cintre","pos":"NOM"} ,
		{"word":"cintres","word_nosc":"cintres","lemma":"cintre","pos":"NOM"} ,
		{"word":"cinzano","word_nosc":"cinzano","lemma":"cinzano","pos":"NOM"} ,
		{"word":"ciné","word_nosc":"cine","lemma":"ciné","pos":"NOM"} ,
		{"word":"ciné-club","word_nosc":"cine-club","lemma":"ciné-club","pos":"NOM"} ,
		{"word":"ciné-clubs","word_nosc":"cine-clubs","lemma":"ciné-club","pos":"NOM"} ,
		{"word":"ciné-roman","word_nosc":"cine-roman","lemma":"ciné-roman","pos":"NOM"} ,
		{"word":"cinéaste","word_nosc":"cineaste","lemma":"cinéaste","pos":"NOM"} ,
		{"word":"cinéastes","word_nosc":"cineastes","lemma":"cinéaste","pos":"NOM"} ,
		{"word":"cinéma","word_nosc":"cinema","lemma":"cinéma","pos":"NOM"} ,
		{"word":"cinéma-vérité","word_nosc":"cinema-verite","lemma":"cinéma-vérité","pos":"NOM"} ,
		{"word":"cinémas","word_nosc":"cinemas","lemma":"cinéma","pos":"NOM"} ,
		{"word":"cinémascope","word_nosc":"cinemascope","lemma":"cinémascope","pos":"NOM"} ,
		{"word":"cinémathèque","word_nosc":"cinematheque","lemma":"cinémathèque","pos":"NOM"} ,
		{"word":"cinémathèques","word_nosc":"cinematheques","lemma":"cinémathèque","pos":"NOM"} ,
		{"word":"cinématique","word_nosc":"cinematique","lemma":"cinématique","pos":"NOM"} ,
		{"word":"cinématographe","word_nosc":"cinematographe","lemma":"cinématographe","pos":"NOM"} ,
		{"word":"cinématographes","word_nosc":"cinematographes","lemma":"cinématographe","pos":"NOM"} ,
		{"word":"cinématographie","word_nosc":"cinematographie","lemma":"cinématographie","pos":"NOM"} ,
		{"word":"cinémomètres","word_nosc":"cinemometres","lemma":"cinémomètre","pos":"NOM"} ,
		{"word":"cinéphile","word_nosc":"cinephile","lemma":"cinéphile","pos":"NOM"} ,
		{"word":"cinéphiles","word_nosc":"cinephiles","lemma":"cinéphile","pos":"NOM"} ,
		{"word":"cinéraire","word_nosc":"cineraire","lemma":"cinéraire","pos":"NOM"} ,
		{"word":"cinérama","word_nosc":"cinerama","lemma":"cinérama","pos":"NOM"} ,
		{"word":"cinés","word_nosc":"cines","lemma":"ciné","pos":"NOM"} ,
		{"word":"cinétique","word_nosc":"cinetique","lemma":"cinétique","pos":"NOM"} ,
		{"word":"cipal","word_nosc":"cipal","lemma":"cipal","pos":"NOM"} ,
		{"word":"cipaye","word_nosc":"cipaye","lemma":"cipaye","pos":"NOM"} ,
		{"word":"cipayes","word_nosc":"cipayes","lemma":"cipaye","pos":"NOM"} ,
		{"word":"cipolin","word_nosc":"cipolin","lemma":"cipolin","pos":"NOM"} ,
		{"word":"cippes","word_nosc":"cippes","lemma":"cippe","pos":"NOM"} ,
		{"word":"cirage","word_nosc":"cirage","lemma":"cirage","pos":"NOM"} ,
		{"word":"circassien","word_nosc":"circassien","lemma":"circassien","pos":"NOM"} ,
		{"word":"circassienne","word_nosc":"circassienne","lemma":"circassienne","pos":"NOM"} ,
		{"word":"circassiennes","word_nosc":"circassiennes","lemma":"circassienne","pos":"NOM"} ,
		{"word":"circaète","word_nosc":"circaete","lemma":"circaète","pos":"NOM"} ,
		{"word":"circoncis","word_nosc":"circoncis","lemma":"circoncis","pos":"NOM"} ,
		{"word":"circoncision","word_nosc":"circoncision","lemma":"circoncision","pos":"NOM"} ,
		{"word":"circoncisions","word_nosc":"circoncisions","lemma":"circoncision","pos":"NOM"} ,
		{"word":"circonférence","word_nosc":"circonference","lemma":"circonférence","pos":"NOM"} ,
		{"word":"circonlocutions","word_nosc":"circonlocutions","lemma":"circonlocution","pos":"NOM"} ,
		{"word":"circonscription","word_nosc":"circonscription","lemma":"circonscription","pos":"NOM"} ,
		{"word":"circonscriptions","word_nosc":"circonscriptions","lemma":"circonscription","pos":"NOM"} ,
		{"word":"circonscriptions-clés","word_nosc":"circonscriptions-cles","lemma":"circonscriptions-clé","pos":"NOM"} ,
		{"word":"circonspection","word_nosc":"circonspection","lemma":"circonspection","pos":"NOM"} ,
		{"word":"circonspections","word_nosc":"circonspections","lemma":"circonspection","pos":"NOM"} ,
		{"word":"circonstance","word_nosc":"circonstance","lemma":"circonstance","pos":"NOM"} ,
		{"word":"circonstances","word_nosc":"circonstances","lemma":"circonstance","pos":"NOM"} ,
		{"word":"circonvallation","word_nosc":"circonvallation","lemma":"circonvallation","pos":"NOM"} ,
		{"word":"circonvallations","word_nosc":"circonvallations","lemma":"circonvallation","pos":"NOM"} ,
		{"word":"circonvolution","word_nosc":"circonvolution","lemma":"circonvolution","pos":"NOM"} ,
		{"word":"circonvolutions","word_nosc":"circonvolutions","lemma":"circonvolution","pos":"NOM"} ,
		{"word":"circuit","word_nosc":"circuit","lemma":"circuit","pos":"NOM"} ,
		{"word":"circuits","word_nosc":"circuits","lemma":"circuit","pos":"NOM"} ,
		{"word":"circulaire","word_nosc":"circulaire","lemma":"circulaire","pos":"NOM"} ,
		{"word":"circulaires","word_nosc":"circulaires","lemma":"circulaire","pos":"NOM"} ,
		{"word":"circularité","word_nosc":"circularite","lemma":"circularité","pos":"NOM"} ,
		{"word":"circulation","word_nosc":"circulation","lemma":"circulation","pos":"NOM"} ,
		{"word":"circulations","word_nosc":"circulations","lemma":"circulation","pos":"NOM"} ,
		{"word":"circumnavigation","word_nosc":"circumnavigation","lemma":"circumnavigation","pos":"NOM"} ,
		{"word":"cire","word_nosc":"cire","lemma":"cire","pos":"NOM"} ,
		{"word":"cires","word_nosc":"cires","lemma":"cire","pos":"NOM"} ,
		{"word":"cireur","word_nosc":"cireur","lemma":"cireur","pos":"NOM"} ,
		{"word":"cireurs","word_nosc":"cireurs","lemma":"cireur","pos":"NOM"} ,
		{"word":"cireuse","word_nosc":"cireuse","lemma":"cireur","pos":"NOM"} ,
		{"word":"ciron","word_nosc":"ciron","lemma":"ciron","pos":"NOM"} ,
		{"word":"cirons","word_nosc":"cirons","lemma":"ciron","pos":"NOM"} ,
		{"word":"cirque","word_nosc":"cirque","lemma":"cirque","pos":"NOM"} ,
		{"word":"cirques","word_nosc":"cirques","lemma":"cirque","pos":"NOM"} ,
		{"word":"cirrhose","word_nosc":"cirrhose","lemma":"cirrhose","pos":"NOM"} ,
		{"word":"cirrus","word_nosc":"cirrus","lemma":"cirrus","pos":"NOM"} ,
		{"word":"cirses","word_nosc":"cirses","lemma":"cirse","pos":"NOM"} ,
		{"word":"ciré","word_nosc":"cire","lemma":"ciré","pos":"NOM"} ,
		{"word":"cirés","word_nosc":"cires","lemma":"ciré","pos":"NOM"} ,
		{"word":"cisaillage","word_nosc":"cisaillage","lemma":"cisaillage","pos":"NOM"} ,
		{"word":"cisaille","word_nosc":"cisaille","lemma":"cisaille","pos":"NOM"} ,
		{"word":"cisaillement","word_nosc":"cisaillement","lemma":"cisaillement","pos":"NOM"} ,
		{"word":"cisailles","word_nosc":"cisailles","lemma":"cisaille","pos":"NOM"} ,
		{"word":"ciseau","word_nosc":"ciseau","lemma":"ciseau","pos":"NOM"} ,
		{"word":"ciseaux","word_nosc":"ciseaux","lemma":"ciseau","pos":"NOM"} ,
		{"word":"ciselet","word_nosc":"ciselet","lemma":"ciselet","pos":"NOM"} ,
		{"word":"ciseleurs","word_nosc":"ciseleurs","lemma":"ciseleur","pos":"NOM"} ,
		{"word":"ciselure","word_nosc":"ciselure","lemma":"ciselure","pos":"NOM"} ,
		{"word":"ciselures","word_nosc":"ciselures","lemma":"ciselure","pos":"NOM"} ,
		{"word":"ciste","word_nosc":"ciste","lemma":"ciste","pos":"NOM"} ,
		{"word":"cisterciennes","word_nosc":"cisterciennes","lemma":"cistercien","pos":"NOM"} ,
		{"word":"cisterciens","word_nosc":"cisterciens","lemma":"cistercien","pos":"NOM"} ,
		{"word":"cistes","word_nosc":"cistes","lemma":"ciste","pos":"NOM"} ,
		{"word":"citadelle","word_nosc":"citadelle","lemma":"citadelle","pos":"NOM"} ,
		{"word":"citadelles","word_nosc":"citadelles","lemma":"citadelle","pos":"NOM"} ,
		{"word":"citadin","word_nosc":"citadin","lemma":"citadin","pos":"NOM"} ,
		{"word":"citadine","word_nosc":"citadine","lemma":"citadin","pos":"NOM"} ,
		{"word":"citadines","word_nosc":"citadines","lemma":"citadin","pos":"NOM"} ,
		{"word":"citadins","word_nosc":"citadins","lemma":"citadin","pos":"NOM"} ,
		{"word":"citation","word_nosc":"citation","lemma":"citation","pos":"NOM"} ,
		{"word":"citations","word_nosc":"citations","lemma":"citation","pos":"NOM"} ,
		{"word":"citerne","word_nosc":"citerne","lemma":"citerne","pos":"NOM"} ,
		{"word":"citernes","word_nosc":"citernes","lemma":"citerne","pos":"NOM"} ,
		{"word":"cithare","word_nosc":"cithare","lemma":"cithare","pos":"NOM"} ,
		{"word":"cithares","word_nosc":"cithares","lemma":"cithare","pos":"NOM"} ,
		{"word":"citizen band","word_nosc":"citizen band","lemma":"citizen band","pos":"NOM"} ,
		{"word":"citoyen","word_nosc":"citoyen","lemma":"citoyen","pos":"NOM"} ,
		{"word":"citoyenne","word_nosc":"citoyenne","lemma":"citoyen","pos":"NOM"} ,
		{"word":"citoyennes","word_nosc":"citoyennes","lemma":"citoyen","pos":"NOM"} ,
		{"word":"citoyenneté","word_nosc":"citoyennete","lemma":"citoyenneté","pos":"NOM"} ,
		{"word":"citoyens","word_nosc":"citoyens","lemma":"citoyen","pos":"NOM"} ,
		{"word":"citrate","word_nosc":"citrate","lemma":"citrate","pos":"NOM"} ,
		{"word":"citrine","word_nosc":"citrine","lemma":"citrine","pos":"NOM"} ,
		{"word":"citron","word_nosc":"citron","lemma":"citron","pos":"NOM"} ,
		{"word":"citronnade","word_nosc":"citronnade","lemma":"citronnade","pos":"NOM"} ,
		{"word":"citronnades","word_nosc":"citronnades","lemma":"citronnade","pos":"NOM"} ,
		{"word":"citronnelle","word_nosc":"citronnelle","lemma":"citronnelle","pos":"NOM"} ,
		{"word":"citronnelles","word_nosc":"citronnelles","lemma":"citronnelle","pos":"NOM"} ,
		{"word":"citronnier","word_nosc":"citronnier","lemma":"citronnier","pos":"NOM"} ,
		{"word":"citronniers","word_nosc":"citronniers","lemma":"citronnier","pos":"NOM"} ,
		{"word":"citrons","word_nosc":"citrons","lemma":"citron","pos":"NOM"} ,
		{"word":"citrouille","word_nosc":"citrouille","lemma":"citrouille","pos":"NOM"} ,
		{"word":"citrouilles","word_nosc":"citrouilles","lemma":"citrouille","pos":"NOM"} ,
		{"word":"citrus","word_nosc":"citrus","lemma":"citrus","pos":"NOM"} ,
		{"word":"city","word_nosc":"city","lemma":"city","pos":"NOM"} ,
		{"word":"cité","word_nosc":"cite","lemma":"cité","pos":"NOM"} ,
		{"word":"cité-dortoir","word_nosc":"cite-dortoir","lemma":"cité-dortoir","pos":"NOM"} ,
		{"word":"cité-jardin","word_nosc":"cite-jardin","lemma":"cité-jardin","pos":"NOM"} ,
		{"word":"cités","word_nosc":"cites","lemma":"cité","pos":"NOM"} ,
		{"word":"cités-dortoirs","word_nosc":"cites-dortoirs","lemma":"cité-dortoir","pos":"NOM"} ,
		{"word":"civadière","word_nosc":"civadiere","lemma":"civadière","pos":"NOM"} ,
		{"word":"civelles","word_nosc":"civelles","lemma":"civelle","pos":"NOM"} ,
		{"word":"civelots","word_nosc":"civelots","lemma":"civelot","pos":"NOM"} ,
		{"word":"civet","word_nosc":"civet","lemma":"civet","pos":"NOM"} ,
		{"word":"civets","word_nosc":"civets","lemma":"civet","pos":"NOM"} ,
		{"word":"civette","word_nosc":"civette","lemma":"civette","pos":"NOM"} ,
		{"word":"civil","word_nosc":"civil","lemma":"civil","pos":"NOM"} ,
		{"word":"civile","word_nosc":"civile","lemma":"civil","pos":"NOM"} ,
		{"word":"civiles","word_nosc":"civiles","lemma":"civil","pos":"NOM"} ,
		{"word":"civilisation","word_nosc":"civilisation","lemma":"civilisation","pos":"NOM"} ,
		{"word":"civilisations","word_nosc":"civilisations","lemma":"civilisation","pos":"NOM"} ,
		{"word":"civilisé","word_nosc":"civilise","lemma":"civilisé","pos":"NOM"} ,
		{"word":"civilisés","word_nosc":"civilises","lemma":"civilisé","pos":"NOM"} ,
		{"word":"civilité","word_nosc":"civilite","lemma":"civilité","pos":"NOM"} ,
		{"word":"civilités","word_nosc":"civilites","lemma":"civilité","pos":"NOM"} ,
		{"word":"civils","word_nosc":"civils","lemma":"civil","pos":"NOM"} ,
		{"word":"civisme","word_nosc":"civisme","lemma":"civisme","pos":"NOM"} ,
		{"word":"civière","word_nosc":"civiere","lemma":"civière","pos":"NOM"} ,
		{"word":"civières","word_nosc":"civieres","lemma":"civière","pos":"NOM"} ,
		{"word":"clabaudage","word_nosc":"clabaudage","lemma":"clabaudage","pos":"NOM"} ,
		{"word":"clabaudages","word_nosc":"clabaudages","lemma":"clabaudage","pos":"NOM"} ,
		{"word":"clabauderies","word_nosc":"clabauderies","lemma":"clabauderie","pos":"NOM"} ,
		{"word":"clafouti","word_nosc":"clafouti","lemma":"clafouti","pos":"NOM"} ,
		{"word":"clafoutis","word_nosc":"clafoutis","lemma":"clafoutis","pos":"NOM"} ,
		{"word":"claie","word_nosc":"claie","lemma":"claie","pos":"NOM"} ,
		{"word":"claies","word_nosc":"claies","lemma":"claie","pos":"NOM"} ,
		{"word":"claim","word_nosc":"claim","lemma":"claim","pos":"NOM"} ,
		{"word":"clair","word_nosc":"clair","lemma":"clair","pos":"NOM"} ,
		{"word":"clair-obscur","word_nosc":"clair-obscur","lemma":"clair-obscur","pos":"NOM"} ,
		{"word":"claire","word_nosc":"claire","lemma":"claire","pos":"NOM"} ,
		{"word":"claire-voie","word_nosc":"claire-voie","lemma":"claire-voie","pos":"NOM"} ,
		{"word":"claires","word_nosc":"claires","lemma":"claire","pos":"NOM"} ,
		{"word":"claires-voies","word_nosc":"claires-voies","lemma":"claire-voie","pos":"NOM"} ,
		{"word":"clairet","word_nosc":"clairet","lemma":"clairet","pos":"NOM"} ,
		{"word":"clairette","word_nosc":"clairette","lemma":"clairette","pos":"NOM"} ,
		{"word":"clairière","word_nosc":"clairiere","lemma":"clairière","pos":"NOM"} ,
		{"word":"clairières","word_nosc":"clairieres","lemma":"clairière","pos":"NOM"} ,
		{"word":"clairon","word_nosc":"clairon","lemma":"clairon","pos":"NOM"} ,
		{"word":"clairons","word_nosc":"clairons","lemma":"clairon","pos":"NOM"} ,
		{"word":"clairs","word_nosc":"clairs","lemma":"clair","pos":"NOM"} ,
		{"word":"clairs-obscurs","word_nosc":"clairs-obscurs","lemma":"clair-obscur","pos":"NOM"} ,
		{"word":"clairvoyance","word_nosc":"clairvoyance","lemma":"clairvoyance","pos":"NOM"} ,
		{"word":"clairvoyances","word_nosc":"clairvoyances","lemma":"clairvoyance","pos":"NOM"} ,
		{"word":"clam","word_nosc":"clam","lemma":"clam","pos":"NOM"} ,
		{"word":"clameur","word_nosc":"clameur","lemma":"clameur","pos":"NOM"} ,
		{"word":"clameurs","word_nosc":"clameurs","lemma":"clameur","pos":"NOM"} ,
		{"word":"clamp","word_nosc":"clamp","lemma":"clamp","pos":"NOM"} ,
		{"word":"clampin","word_nosc":"clampin","lemma":"clampin","pos":"NOM"} ,
		{"word":"clampins","word_nosc":"clampins","lemma":"clampin","pos":"NOM"} ,
		{"word":"clamps","word_nosc":"clamps","lemma":"clamp","pos":"NOM"} ,
		{"word":"clams","word_nosc":"clams","lemma":"clam","pos":"NOM"} ,
		{"word":"clan","word_nosc":"clan","lemma":"clan","pos":"NOM"} ,
		{"word":"clandestin","word_nosc":"clandestin","lemma":"clandestin","pos":"NOM"} ,
		{"word":"clandestine","word_nosc":"clandestine","lemma":"clandestin","pos":"NOM"} ,
		{"word":"clandestines","word_nosc":"clandestines","lemma":"clandestin","pos":"NOM"} ,
		{"word":"clandestinité","word_nosc":"clandestinite","lemma":"clandestinité","pos":"NOM"} ,
		{"word":"clandestinités","word_nosc":"clandestinites","lemma":"clandestinité","pos":"NOM"} ,
		{"word":"clandestins","word_nosc":"clandestins","lemma":"clandestin","pos":"NOM"} ,
		{"word":"clandé","word_nosc":"clande","lemma":"clandé","pos":"NOM"} ,
		{"word":"clandés","word_nosc":"clandes","lemma":"clandé","pos":"NOM"} ,
		{"word":"clans","word_nosc":"clans","lemma":"clan","pos":"NOM"} ,
		{"word":"clap","word_nosc":"clap","lemma":"clap","pos":"NOM"} ,
		{"word":"clapet","word_nosc":"clapet","lemma":"clapet","pos":"NOM"} ,
		{"word":"clapets","word_nosc":"clapets","lemma":"clapet","pos":"NOM"} ,
		{"word":"clapette","word_nosc":"clapette","lemma":"clapette","pos":"NOM"} ,
		{"word":"clapier","word_nosc":"clapier","lemma":"clapier","pos":"NOM"} ,
		{"word":"clapiers","word_nosc":"clapiers","lemma":"clapier","pos":"NOM"} ,
		{"word":"clapot","word_nosc":"clapot","lemma":"clapot","pos":"NOM"} ,
		{"word":"clapotement","word_nosc":"clapotement","lemma":"clapotement","pos":"NOM"} ,
		{"word":"clapotements","word_nosc":"clapotements","lemma":"clapotement","pos":"NOM"} ,
		{"word":"clapotis","word_nosc":"clapotis","lemma":"clapotis","pos":"NOM"} ,
		{"word":"clappement","word_nosc":"clappement","lemma":"clappement","pos":"NOM"} ,
		{"word":"clappements","word_nosc":"clappements","lemma":"clappement","pos":"NOM"} ,
		{"word":"claquage","word_nosc":"claquage","lemma":"claquage","pos":"NOM"} ,
		{"word":"claque","word_nosc":"claque","lemma":"claque","pos":"NOM"} ,
		{"word":"claque-merde","word_nosc":"claque-merde","lemma":"claque-merde","pos":"NOM"} ,
		{"word":"claquedents","word_nosc":"claquedents","lemma":"claquedent","pos":"NOM"} ,
		{"word":"claquement","word_nosc":"claquement","lemma":"claquement","pos":"NOM"} ,
		{"word":"claquements","word_nosc":"claquements","lemma":"claquement","pos":"NOM"} ,
		{"word":"claques","word_nosc":"claques","lemma":"claque","pos":"NOM"} ,
		{"word":"claquet","word_nosc":"claquet","lemma":"claquet","pos":"NOM"} ,
		{"word":"claquette","word_nosc":"claquette","lemma":"claquette","pos":"NOM"} ,
		{"word":"claquettes","word_nosc":"claquettes","lemma":"claquette","pos":"NOM"} ,
		{"word":"claqueurs","word_nosc":"claqueurs","lemma":"claqueur","pos":"NOM"} ,
		{"word":"claquoirs","word_nosc":"claquoirs","lemma":"claquoir","pos":"NOM"} ,
		{"word":"clarification","word_nosc":"clarification","lemma":"clarification","pos":"NOM"} ,
		{"word":"clarine","word_nosc":"clarine","lemma":"clarine","pos":"NOM"} ,
		{"word":"clarines","word_nosc":"clarines","lemma":"clarine","pos":"NOM"} ,
		{"word":"clarinette","word_nosc":"clarinette","lemma":"clarinette","pos":"NOM"} ,
		{"word":"clarinettes","word_nosc":"clarinettes","lemma":"clarinette","pos":"NOM"} ,
		{"word":"clarinettiste","word_nosc":"clarinettiste","lemma":"clarinettiste","pos":"NOM"} ,
		{"word":"clarisse","word_nosc":"clarisse","lemma":"clarisse","pos":"NOM"} ,
		{"word":"clarisses","word_nosc":"clarisses","lemma":"clarisse","pos":"NOM"} ,
		{"word":"clarissimes","word_nosc":"clarissimes","lemma":"clarissime","pos":"NOM"} ,
		{"word":"clarté","word_nosc":"clarte","lemma":"clarté","pos":"NOM"} ,
		{"word":"clartés","word_nosc":"clartes","lemma":"clarté","pos":"NOM"} ,
		{"word":"clash","word_nosc":"clash","lemma":"clash","pos":"NOM"} ,
		{"word":"classe","word_nosc":"classe","lemma":"classe","pos":"NOM"} ,
		{"word":"classement","word_nosc":"classement","lemma":"classement","pos":"NOM"} ,
		{"word":"classements","word_nosc":"classements","lemma":"classement","pos":"NOM"} ,
		{"word":"classes","word_nosc":"classes","lemma":"classe","pos":"NOM"} ,
		{"word":"classeur","word_nosc":"classeur","lemma":"classeur","pos":"NOM"} ,
		{"word":"classeurs","word_nosc":"classeurs","lemma":"classeur","pos":"NOM"} ,
		{"word":"classicisme","word_nosc":"classicisme","lemma":"classicisme","pos":"NOM"} ,
		{"word":"classification","word_nosc":"classification","lemma":"classification","pos":"NOM"} ,
		{"word":"classifications","word_nosc":"classifications","lemma":"classification","pos":"NOM"} ,
		{"word":"classique","word_nosc":"classique","lemma":"classique","pos":"NOM"} ,
		{"word":"classiques","word_nosc":"classiques","lemma":"classique","pos":"NOM"} ,
		{"word":"clathres","word_nosc":"clathres","lemma":"clathre","pos":"NOM"} ,
		{"word":"claude","word_nosc":"claude","lemma":"claude","pos":"NOM"} ,
		{"word":"claudication","word_nosc":"claudication","lemma":"claudication","pos":"NOM"} ,
		{"word":"clause","word_nosc":"clause","lemma":"clause","pos":"NOM"} ,
		{"word":"clauses","word_nosc":"clauses","lemma":"clause","pos":"NOM"} ,
		{"word":"claustration","word_nosc":"claustration","lemma":"claustration","pos":"NOM"} ,
		{"word":"claustrophobe","word_nosc":"claustrophobe","lemma":"claustrophobe","pos":"NOM"} ,
		{"word":"claustrophobie","word_nosc":"claustrophobie","lemma":"claustrophobie","pos":"NOM"} ,
		{"word":"clavaire","word_nosc":"clavaire","lemma":"clavaire","pos":"NOM"} ,
		{"word":"clavaires","word_nosc":"clavaires","lemma":"clavaire","pos":"NOM"} ,
		{"word":"claveaux","word_nosc":"claveaux","lemma":"claveau","pos":"NOM"} ,
		{"word":"clavecin","word_nosc":"clavecin","lemma":"clavecin","pos":"NOM"} ,
		{"word":"claveciniste","word_nosc":"claveciniste","lemma":"claveciniste","pos":"NOM"} ,
		{"word":"clavecins","word_nosc":"clavecins","lemma":"clavecin","pos":"NOM"} ,
		{"word":"clavette","word_nosc":"clavette","lemma":"clavette","pos":"NOM"} ,
		{"word":"clavicule","word_nosc":"clavicule","lemma":"clavicule","pos":"NOM"} ,
		{"word":"clavicules","word_nosc":"clavicules","lemma":"clavicule","pos":"NOM"} ,
		{"word":"clavier","word_nosc":"clavier","lemma":"clavier","pos":"NOM"} ,
		{"word":"claviers","word_nosc":"claviers","lemma":"clavier","pos":"NOM"} ,
		{"word":"clayettes","word_nosc":"clayettes","lemma":"clayette","pos":"NOM"} ,
		{"word":"claymore","word_nosc":"claymore","lemma":"claymore","pos":"NOM"} ,
		{"word":"clayon","word_nosc":"clayon","lemma":"clayon","pos":"NOM"} ,
		{"word":"clayonnage","word_nosc":"clayonnage","lemma":"clayonnage","pos":"NOM"} ,
		{"word":"clayonnages","word_nosc":"clayonnages","lemma":"clayonnage","pos":"NOM"} ,
		{"word":"clayons","word_nosc":"clayons","lemma":"clayon","pos":"NOM"} ,
		{"word":"clearing","word_nosc":"clearing","lemma":"clearing","pos":"NOM"} ,
		{"word":"clebs","word_nosc":"clebs","lemma":"clebs","pos":"NOM"} ,
		{"word":"clef","word_nosc":"clef","lemma":"clef","pos":"NOM"} ,
		{"word":"clefs","word_nosc":"clefs","lemma":"clef","pos":"NOM"} ,
		{"word":"clenche","word_nosc":"clenche","lemma":"clenche","pos":"NOM"} ,
		{"word":"clenches","word_nosc":"clenches","lemma":"clenche","pos":"NOM"} ,
		{"word":"clepsydre","word_nosc":"clepsydre","lemma":"clepsydre","pos":"NOM"} ,
		{"word":"cleptomane","word_nosc":"cleptomane","lemma":"cleptomane","pos":"NOM"} ,
		{"word":"cleptomanie","word_nosc":"cleptomanie","lemma":"cleptomanie","pos":"NOM"} ,
		{"word":"clerc","word_nosc":"clerc","lemma":"clerc","pos":"NOM"} ,
		{"word":"clercs","word_nosc":"clercs","lemma":"clerc","pos":"NOM"} ,
		{"word":"clergeon","word_nosc":"clergeon","lemma":"clergeon","pos":"NOM"} ,
		{"word":"clergyman","word_nosc":"clergyman","lemma":"clergyman","pos":"NOM"} ,
		{"word":"clergymen","word_nosc":"clergymen","lemma":"clergyman","pos":"NOM"} ,
		{"word":"clergé","word_nosc":"clerge","lemma":"clergé","pos":"NOM"} ,
		{"word":"clergés","word_nosc":"clerges","lemma":"clergé","pos":"NOM"} ,
		{"word":"clermontois","word_nosc":"clermontois","lemma":"clermontois","pos":"NOM"} ,
		{"word":"clic","word_nosc":"clic","lemma":"clic","pos":"NOM"} ,
		{"word":"clichage","word_nosc":"clichage","lemma":"clichage","pos":"NOM"} ,
		{"word":"clicherie","word_nosc":"clicherie","lemma":"clicherie","pos":"NOM"} ,
		{"word":"clicheur","word_nosc":"clicheur","lemma":"clicheur","pos":"NOM"} ,
		{"word":"cliché","word_nosc":"cliche","lemma":"cliché","pos":"NOM"} ,
		{"word":"clichés","word_nosc":"cliches","lemma":"cliché","pos":"NOM"} ,
		{"word":"click","word_nosc":"click","lemma":"click","pos":"NOM"} ,
		{"word":"clics","word_nosc":"clics","lemma":"clic","pos":"NOM"} ,
		{"word":"client","word_nosc":"client","lemma":"client","pos":"NOM"} ,
		{"word":"client-roi","word_nosc":"client-roi","lemma":"client-roi","pos":"NOM"} ,
		{"word":"cliente","word_nosc":"cliente","lemma":"client","pos":"NOM"} ,
		{"word":"clientes","word_nosc":"clientes","lemma":"client","pos":"NOM"} ,
		{"word":"clients","word_nosc":"clients","lemma":"client","pos":"NOM"} ,
		{"word":"clientèle","word_nosc":"clientele","lemma":"clientèle","pos":"NOM"} ,
		{"word":"clientèles","word_nosc":"clienteles","lemma":"clientèle","pos":"NOM"} ,
		{"word":"clignement","word_nosc":"clignement","lemma":"clignement","pos":"NOM"} ,
		{"word":"clignements","word_nosc":"clignements","lemma":"clignement","pos":"NOM"} ,
		{"word":"clignotant","word_nosc":"clignotant","lemma":"clignotant","pos":"NOM"} ,
		{"word":"clignotants","word_nosc":"clignotants","lemma":"clignotant","pos":"NOM"} ,
		{"word":"clignotement","word_nosc":"clignotement","lemma":"clignotement","pos":"NOM"} ,
		{"word":"clignotements","word_nosc":"clignotements","lemma":"clignotement","pos":"NOM"} ,
		{"word":"clignoteur","word_nosc":"clignoteur","lemma":"clignoteur","pos":"NOM"} ,
		{"word":"clilles","word_nosc":"clilles","lemma":"clille","pos":"NOM"} ,
		{"word":"climat","word_nosc":"climat","lemma":"climat","pos":"NOM"} ,
		{"word":"climatisation","word_nosc":"climatisation","lemma":"climatisation","pos":"NOM"} ,
		{"word":"climatiseur","word_nosc":"climatiseur","lemma":"climatiseur","pos":"NOM"} ,
		{"word":"climatiseurs","word_nosc":"climatiseurs","lemma":"climatiseur","pos":"NOM"} ,
		{"word":"climatologue","word_nosc":"climatologue","lemma":"climatologue","pos":"NOM"} ,
		{"word":"climats","word_nosc":"climats","lemma":"climat","pos":"NOM"} ,
		{"word":"climax","word_nosc":"climax","lemma":"climax","pos":"NOM"} ,
		{"word":"clin","word_nosc":"clin","lemma":"clin","pos":"NOM"} ,
		{"word":"clin-d'oeil","word_nosc":"clin-d'oeil","lemma":"clin-d'oeil","pos":"NOM"} ,
		{"word":"clinche","word_nosc":"clinche","lemma":"clinche","pos":"NOM"} ,
		{"word":"clinicat","word_nosc":"clinicat","lemma":"clinicat","pos":"NOM"} ,
		{"word":"clinicien","word_nosc":"clinicien","lemma":"clinicien","pos":"NOM"} ,
		{"word":"clinicienne","word_nosc":"clinicienne","lemma":"clinicien","pos":"NOM"} ,
		{"word":"cliniciens","word_nosc":"cliniciens","lemma":"clinicien","pos":"NOM"} ,
		{"word":"clinique","word_nosc":"clinique","lemma":"clinique","pos":"NOM"} ,
		{"word":"cliniques","word_nosc":"cliniques","lemma":"clinique","pos":"NOM"} ,
		{"word":"clinker","word_nosc":"clinker","lemma":"clinker","pos":"NOM"} ,
		{"word":"clinquant","word_nosc":"clinquant","lemma":"clinquant","pos":"NOM"} ,
		{"word":"clinquants","word_nosc":"clinquants","lemma":"clinquant","pos":"NOM"} ,
		{"word":"clins","word_nosc":"clins","lemma":"clin","pos":"NOM"} ,
		{"word":"clip","word_nosc":"clip","lemma":"clip","pos":"NOM"} ,
		{"word":"clipper","word_nosc":"clipper","lemma":"clipper","pos":"NOM"} ,
		{"word":"clippers","word_nosc":"clippers","lemma":"clipper","pos":"NOM"} ,
		{"word":"clips","word_nosc":"clips","lemma":"clip","pos":"NOM"} ,
		{"word":"clique","word_nosc":"clique","lemma":"clique","pos":"NOM"} ,
		{"word":"cliques","word_nosc":"cliques","lemma":"clique","pos":"NOM"} ,
		{"word":"cliquet","word_nosc":"cliquet","lemma":"cliquet","pos":"NOM"} ,
		{"word":"cliquetis","word_nosc":"cliquetis","lemma":"cliquetis","pos":"NOM"} ,
		{"word":"cliquets","word_nosc":"cliquets","lemma":"cliquet","pos":"NOM"} ,
		{"word":"cliquettement","word_nosc":"cliquettement","lemma":"cliquettement","pos":"NOM"} ,
		{"word":"cliquettes","word_nosc":"cliquettes","lemma":"cliquette","pos":"NOM"} ,
		{"word":"cliquètement","word_nosc":"cliquetement","lemma":"cliquètement","pos":"NOM"} ,
		{"word":"cliquètements","word_nosc":"cliquetements","lemma":"cliquètement","pos":"NOM"} ,
		{"word":"clito","word_nosc":"clito","lemma":"clito","pos":"NOM"} ,
		{"word":"clitoridectomie","word_nosc":"clitoridectomie","lemma":"clitoridectomie","pos":"NOM"} ,
		{"word":"clitoris","word_nosc":"clitoris","lemma":"clitoris","pos":"NOM"} ,
		{"word":"clivage","word_nosc":"clivage","lemma":"clivage","pos":"NOM"} ,
		{"word":"clivages","word_nosc":"clivages","lemma":"clivage","pos":"NOM"} ,
		{"word":"cloaque","word_nosc":"cloaque","lemma":"cloaque","pos":"NOM"} ,
		{"word":"cloaques","word_nosc":"cloaques","lemma":"cloaque","pos":"NOM"} ,
		{"word":"cloc","word_nosc":"cloc","lemma":"cloc","pos":"NOM"} ,
		{"word":"clochard","word_nosc":"clochard","lemma":"clochard","pos":"NOM"} ,
		{"word":"clocharde","word_nosc":"clocharde","lemma":"clochard","pos":"NOM"} ,
		{"word":"clochardes","word_nosc":"clochardes","lemma":"clochard","pos":"NOM"} ,
		{"word":"clochardisation","word_nosc":"clochardisation","lemma":"clochardisation","pos":"NOM"} ,
		{"word":"clochards","word_nosc":"clochards","lemma":"clochard","pos":"NOM"} ,
		{"word":"cloche","word_nosc":"cloche","lemma":"cloche","pos":"NOM"} ,
		{"word":"cloche-pied","word_nosc":"cloche-pied","lemma":"cloche-pied","pos":"NOM"} ,
		{"word":"clocher","word_nosc":"clocher","lemma":"clocher","pos":"NOM"} ,
		{"word":"clochers","word_nosc":"clochers","lemma":"clocher","pos":"NOM"} ,
		{"word":"cloches","word_nosc":"cloches","lemma":"cloche","pos":"NOM"} ,
		{"word":"clocheton","word_nosc":"clocheton","lemma":"clocheton","pos":"NOM"} ,
		{"word":"clochetons","word_nosc":"clochetons","lemma":"clocheton","pos":"NOM"} ,
		{"word":"clochette","word_nosc":"clochette","lemma":"clochette","pos":"NOM"} ,
		{"word":"clochettes","word_nosc":"clochettes","lemma":"clochette","pos":"NOM"} ,
		{"word":"clodo","word_nosc":"clodo","lemma":"clodo","pos":"NOM"} ,
		{"word":"clodos","word_nosc":"clodos","lemma":"clodo","pos":"NOM"} ,
		{"word":"cloison","word_nosc":"cloison","lemma":"cloison","pos":"NOM"} ,
		{"word":"cloisonnement","word_nosc":"cloisonnement","lemma":"cloisonnement","pos":"NOM"} ,
		{"word":"cloisonnements","word_nosc":"cloisonnements","lemma":"cloisonnement","pos":"NOM"} ,
		{"word":"cloisons","word_nosc":"cloisons","lemma":"cloison","pos":"NOM"} ,
		{"word":"clonage","word_nosc":"clonage","lemma":"clonage","pos":"NOM"} ,
		{"word":"clone","word_nosc":"clone","lemma":"clone","pos":"NOM"} ,
		{"word":"clones","word_nosc":"clones","lemma":"clone","pos":"NOM"} ,
		{"word":"clope","word_nosc":"clope","lemma":"clope","pos":"NOM"} ,
		{"word":"clopes","word_nosc":"clopes","lemma":"clope","pos":"NOM"} ,
		{"word":"clopeur","word_nosc":"clopeur","lemma":"clopeur","pos":"NOM"} ,
		{"word":"clopinements","word_nosc":"clopinements","lemma":"clopinement","pos":"NOM"} ,
		{"word":"clopinettes","word_nosc":"clopinettes","lemma":"clopinettes","pos":"NOM"} ,
		{"word":"cloporte","word_nosc":"cloporte","lemma":"cloporte","pos":"NOM"} ,
		{"word":"cloportes","word_nosc":"cloportes","lemma":"cloporte","pos":"NOM"} ,
		{"word":"cloque","word_nosc":"cloque","lemma":"cloque","pos":"NOM"} ,
		{"word":"cloques","word_nosc":"cloques","lemma":"cloque","pos":"NOM"} ,
		{"word":"clos","word_nosc":"clos","lemma":"clos","pos":"NOM"} ,
		{"word":"close-combat","word_nosc":"close-combat","lemma":"close-combat","pos":"NOM"} ,
		{"word":"close-up","word_nosc":"close-up","lemma":"close-up","pos":"NOM"} ,
		{"word":"closerie","word_nosc":"closerie","lemma":"closerie","pos":"NOM"} ,
		{"word":"clou","word_nosc":"clou","lemma":"clou","pos":"NOM"} ,
		{"word":"clouage","word_nosc":"clouage","lemma":"clouage","pos":"NOM"} ,
		{"word":"cloueur","word_nosc":"cloueur","lemma":"cloueur","pos":"NOM"} ,
		{"word":"cloueuse","word_nosc":"cloueuse","lemma":"cloueur","pos":"NOM"} ,
		{"word":"clous","word_nosc":"clous","lemma":"clou","pos":"NOM"} ,
		{"word":"cloutage","word_nosc":"cloutage","lemma":"cloutage","pos":"NOM"} ,
		{"word":"cloutier","word_nosc":"cloutier","lemma":"cloutier","pos":"NOM"} ,
		{"word":"cloué","word_nosc":"cloue","lemma":"cloué","pos":"NOM"} ,
		{"word":"cloués","word_nosc":"cloues","lemma":"cloué","pos":"NOM"} ,
		{"word":"clown","word_nosc":"clown","lemma":"clown","pos":"NOM"} ,
		{"word":"clownerie","word_nosc":"clownerie","lemma":"clownerie","pos":"NOM"} ,
		{"word":"clowneries","word_nosc":"clowneries","lemma":"clownerie","pos":"NOM"} ,
		{"word":"clowns","word_nosc":"clowns","lemma":"clown","pos":"NOM"} ,
		{"word":"cloître","word_nosc":"cloitre","lemma":"cloître","pos":"NOM"} ,
		{"word":"cloîtres","word_nosc":"cloitres","lemma":"cloître","pos":"NOM"} ,
		{"word":"club","word_nosc":"club","lemma":"club","pos":"NOM"} ,
		{"word":"club-house","word_nosc":"club-house","lemma":"club-house","pos":"NOM"} ,
		{"word":"clubhouse","word_nosc":"clubhouse","lemma":"clubhouse","pos":"NOM"} ,
		{"word":"clubiste","word_nosc":"clubiste","lemma":"clubiste","pos":"NOM"} ,
		{"word":"clubman","word_nosc":"clubman","lemma":"clubman","pos":"NOM"} ,
		{"word":"clubs","word_nosc":"clubs","lemma":"club","pos":"NOM"} ,
		{"word":"cluse","word_nosc":"cluse","lemma":"cluse","pos":"NOM"} ,
		{"word":"cluses","word_nosc":"cluses","lemma":"cluse","pos":"NOM"} ,
		{"word":"cluster","word_nosc":"cluster","lemma":"cluster","pos":"NOM"} ,
		{"word":"clystère","word_nosc":"clystere","lemma":"clystère","pos":"NOM"} ,
		{"word":"clystères","word_nosc":"clysteres","lemma":"clystère","pos":"NOM"} ,
		{"word":"clé","word_nosc":"cle","lemma":"clé","pos":"NOM"} ,
		{"word":"clébard","word_nosc":"clebard","lemma":"clébard","pos":"NOM"} ,
		{"word":"clébards","word_nosc":"clebards","lemma":"clébard","pos":"NOM"} ,
		{"word":"clématite","word_nosc":"clematite","lemma":"clématite","pos":"NOM"} ,
		{"word":"clématites","word_nosc":"clematites","lemma":"clématite","pos":"NOM"} ,
		{"word":"clémence","word_nosc":"clemence","lemma":"clémence","pos":"NOM"} ,
		{"word":"clémentine","word_nosc":"clementine","lemma":"clémentine","pos":"NOM"} ,
		{"word":"cléricalisme","word_nosc":"clericalisme","lemma":"cléricalisme","pos":"NOM"} ,
		{"word":"cléricature","word_nosc":"clericature","lemma":"cléricature","pos":"NOM"} ,
		{"word":"clés","word_nosc":"cles","lemma":"clé","pos":"NOM"} ,
		{"word":"clôture","word_nosc":"cloture","lemma":"clôture","pos":"NOM"} ,
		{"word":"clôtures","word_nosc":"clotures","lemma":"clôture","pos":"NOM"} ,
		{"word":"cm","word_nosc":"cm","lemma":"cm","pos":"NOM"} ,
		{"word":"co-animateur","word_nosc":"co-animateur","lemma":"co-animateur","pos":"NOM"} ,
		{"word":"co-auteur","word_nosc":"co-auteur","lemma":"co-auteur","pos":"NOM"} ,
		{"word":"co-avocat","word_nosc":"co-avocat","lemma":"co-avocat","pos":"NOM"} ,
		{"word":"co-capitaine","word_nosc":"co-capitaine","lemma":"co-capitaine","pos":"NOM"} ,
		{"word":"co-dépendance","word_nosc":"co-dependance","lemma":"co-dépendance","pos":"NOM"} ,
		{"word":"co-existence","word_nosc":"co-existence","lemma":"co-existence","pos":"NOM"} ,
		{"word":"co-locataire","word_nosc":"co-locataire","lemma":"co-locataire","pos":"NOM"} ,
		{"word":"co-pilote","word_nosc":"co-pilote","lemma":"co-pilote","pos":"NOM"} ,
		{"word":"co-production","word_nosc":"co-production","lemma":"co-production","pos":"NOM"} ,
		{"word":"co-productions","word_nosc":"co-productions","lemma":"co-production","pos":"NOM"} ,
		{"word":"co-proprio","word_nosc":"co-proprio","lemma":"co-proprio","pos":"NOM"} ,
		{"word":"co-proprios","word_nosc":"co-proprios","lemma":"co-proprio","pos":"NOM"} ,
		{"word":"co-propriétaire","word_nosc":"co-proprietaire","lemma":"co-propriétaire","pos":"NOM"} ,
		{"word":"co-propriétaires","word_nosc":"co-proprietaires","lemma":"co-propriétaire","pos":"NOM"} ,
		{"word":"co-propriété","word_nosc":"co-propriete","lemma":"co-propriété","pos":"NOM"} ,
		{"word":"co-président","word_nosc":"co-president","lemma":"co-président","pos":"NOM"} ,
		{"word":"co-responsable","word_nosc":"co-responsable","lemma":"co-responsable","pos":"NOM"} ,
		{"word":"co-tuteur","word_nosc":"co-tuteur","lemma":"co-tuteur","pos":"NOM"} ,
		{"word":"co-vedette","word_nosc":"co-vedette","lemma":"co-vedette","pos":"NOM"} ,
		{"word":"co-voiturage","word_nosc":"co-voiturage","lemma":"co-voiturage","pos":"NOM"} ,
		{"word":"co-équipier","word_nosc":"co-equipier","lemma":"co-équipier","pos":"NOM"} ,
		{"word":"co-équipière","word_nosc":"co-equipiere","lemma":"coéquipier","pos":"NOM"} ,
		{"word":"coaccusé","word_nosc":"coaccuse","lemma":"coaccusé","pos":"NOM"} ,
		{"word":"coaccusés","word_nosc":"coaccuses","lemma":"coaccusé","pos":"NOM"} ,
		{"word":"coach","word_nosc":"coach","lemma":"coach","pos":"NOM"} ,
		{"word":"coaches","word_nosc":"coaches","lemma":"coache","pos":"NOM"} ,
		{"word":"coachs","word_nosc":"coachs","lemma":"coach","pos":"NOM"} ,
		{"word":"coactionnaires","word_nosc":"coactionnaires","lemma":"coactionnaire","pos":"NOM"} ,
		{"word":"coadjuteur","word_nosc":"coadjuteur","lemma":"coadjuteur","pos":"NOM"} ,
		{"word":"coagulant","word_nosc":"coagulant","lemma":"coagulant","pos":"NOM"} ,
		{"word":"coagulants","word_nosc":"coagulants","lemma":"coagulant","pos":"NOM"} ,
		{"word":"coagulation","word_nosc":"coagulation","lemma":"coagulation","pos":"NOM"} ,
		{"word":"coalisés","word_nosc":"coalises","lemma":"coalisé","pos":"NOM"} ,
		{"word":"coalition","word_nosc":"coalition","lemma":"coalition","pos":"NOM"} ,
		{"word":"coalitions","word_nosc":"coalitions","lemma":"coalition","pos":"NOM"} ,
		{"word":"coaltar","word_nosc":"coaltar","lemma":"coaltar","pos":"NOM"} ,
		{"word":"coassement","word_nosc":"coassement","lemma":"coassement","pos":"NOM"} ,
		{"word":"coassements","word_nosc":"coassements","lemma":"coassement","pos":"NOM"} ,
		{"word":"coati","word_nosc":"coati","lemma":"coati","pos":"NOM"} ,
		{"word":"coauteur","word_nosc":"coauteur","lemma":"coauteur","pos":"NOM"} ,
		{"word":"coauteurs","word_nosc":"coauteurs","lemma":"coauteur","pos":"NOM"} ,
		{"word":"coaxial","word_nosc":"coaxial","lemma":"coaxial","pos":"NOM"} ,
		{"word":"cob","word_nosc":"cob","lemma":"cob","pos":"NOM"} ,
		{"word":"cobalt","word_nosc":"cobalt","lemma":"cobalt","pos":"NOM"} ,
		{"word":"cobaye","word_nosc":"cobaye","lemma":"cobaye","pos":"NOM"} ,
		{"word":"cobayes","word_nosc":"cobayes","lemma":"cobaye","pos":"NOM"} ,
		{"word":"cobelligérants","word_nosc":"cobelligerants","lemma":"cobelligérant","pos":"NOM"} ,
		{"word":"cobol","word_nosc":"cobol","lemma":"cobol","pos":"NOM"} ,
		{"word":"cobra","word_nosc":"cobra","lemma":"cobra","pos":"NOM"} ,
		{"word":"cobras","word_nosc":"cobras","lemma":"cobra","pos":"NOM"} ,
		{"word":"coca","word_nosc":"coca","lemma":"coca","pos":"NOM"} ,
		{"word":"coca-cola","word_nosc":"coca-cola","lemma":"coca-cola","pos":"NOM"} ,
		{"word":"cocagne","word_nosc":"cocagne","lemma":"cocagne","pos":"NOM"} ,
		{"word":"cocagnes","word_nosc":"cocagnes","lemma":"cocagne","pos":"NOM"} ,
		{"word":"cocard","word_nosc":"cocard","lemma":"cocard","pos":"NOM"} ,
		{"word":"cocarde","word_nosc":"cocarde","lemma":"cocarde","pos":"NOM"} ,
		{"word":"cocardes","word_nosc":"cocardes","lemma":"cocarde","pos":"NOM"} ,
		{"word":"cocards","word_nosc":"cocards","lemma":"cocard","pos":"NOM"} ,
		{"word":"cocas","word_nosc":"cocas","lemma":"coca","pos":"NOM"} ,
		{"word":"cocasserie","word_nosc":"cocasserie","lemma":"cocasserie","pos":"NOM"} ,
		{"word":"cocaïne","word_nosc":"cocaine","lemma":"cocaïne","pos":"NOM"} ,
		{"word":"cocaïnomane","word_nosc":"cocainomane","lemma":"cocaïnomane","pos":"NOM"} ,
		{"word":"coccinelle","word_nosc":"coccinelle","lemma":"coccinelle","pos":"NOM"} ,
		{"word":"coccinelles","word_nosc":"coccinelles","lemma":"coccinelle","pos":"NOM"} ,
		{"word":"coccyx","word_nosc":"coccyx","lemma":"coccyx","pos":"NOM"} ,
		{"word":"coche","word_nosc":"coche","lemma":"coche","pos":"NOM"} ,
		{"word":"cochelet","word_nosc":"cochelet","lemma":"cochelet","pos":"NOM"} ,
		{"word":"cochenille","word_nosc":"cochenille","lemma":"cochenille","pos":"NOM"} ,
		{"word":"cochenilles","word_nosc":"cochenilles","lemma":"cochenille","pos":"NOM"} ,
		{"word":"cocher","word_nosc":"cocher","lemma":"cocher","pos":"NOM"} ,
		{"word":"cochers","word_nosc":"cochers","lemma":"cocher","pos":"NOM"} ,
		{"word":"coches","word_nosc":"coches","lemma":"coche","pos":"NOM"} ,
		{"word":"cochlée","word_nosc":"cochlee","lemma":"cochlée","pos":"NOM"} ,
		{"word":"cochon","word_nosc":"cochon","lemma":"cochon","pos":"NOM"} ,
		{"word":"cochonceté","word_nosc":"cochoncete","lemma":"cochonceté","pos":"NOM"} ,
		{"word":"cochoncetés","word_nosc":"cochoncetes","lemma":"cochonceté","pos":"NOM"} ,
		{"word":"cochonnaille","word_nosc":"cochonnaille","lemma":"cochonnaille","pos":"NOM"} ,
		{"word":"cochonnailles","word_nosc":"cochonnailles","lemma":"cochonnaille","pos":"NOM"} ,
		{"word":"cochonne","word_nosc":"cochonne","lemma":"cochon","pos":"NOM"} ,
		{"word":"cochonnerie","word_nosc":"cochonnerie","lemma":"cochonnerie","pos":"NOM"} ,
		{"word":"cochonneries","word_nosc":"cochonneries","lemma":"cochonnerie","pos":"NOM"} ,
		{"word":"cochonnes","word_nosc":"cochonnes","lemma":"cochon","pos":"NOM"} ,
		{"word":"cochonnet","word_nosc":"cochonnet","lemma":"cochonnet","pos":"NOM"} ,
		{"word":"cochonnets","word_nosc":"cochonnets","lemma":"cochonnet","pos":"NOM"} ,
		{"word":"cochonnée","word_nosc":"cochonnee","lemma":"cochonnée","pos":"NOM"} ,
		{"word":"cochons","word_nosc":"cochons","lemma":"cochon","pos":"NOM"} ,
		{"word":"cochylis","word_nosc":"cochylis","lemma":"cochylis","pos":"NOM"} ,
		{"word":"cocker","word_nosc":"cocker","lemma":"cocker","pos":"NOM"} ,
		{"word":"cockney","word_nosc":"cockney","lemma":"cockney","pos":"NOM"} ,
		{"word":"cockneys","word_nosc":"cockneys","lemma":"cockney","pos":"NOM"} ,
		{"word":"cockpit","word_nosc":"cockpit","lemma":"cockpit","pos":"NOM"} ,
		{"word":"cocktail","word_nosc":"cocktail","lemma":"cocktail","pos":"NOM"} ,
		{"word":"cocktails","word_nosc":"cocktails","lemma":"cocktail","pos":"NOM"} ,
		{"word":"coco","word_nosc":"coco","lemma":"coco","pos":"NOM"} ,
		{"word":"cocon","word_nosc":"cocon","lemma":"cocon","pos":"NOM"} ,
		{"word":"cocons","word_nosc":"cocons","lemma":"cocon","pos":"NOM"} ,
		{"word":"cocoon","word_nosc":"cocoon","lemma":"cocoon","pos":"NOM"} ,
		{"word":"cocorico","word_nosc":"cocorico","lemma":"cocorico","pos":"NOM"} ,
		{"word":"cocoricos","word_nosc":"cocoricos","lemma":"cocorico","pos":"NOM"} ,
		{"word":"cocos","word_nosc":"cocos","lemma":"coco","pos":"NOM"} ,
		{"word":"cocoteraies","word_nosc":"cocoteraies","lemma":"cocoteraie","pos":"NOM"} ,
		{"word":"cocotier","word_nosc":"cocotier","lemma":"cocotier","pos":"NOM"} ,
		{"word":"cocotiers","word_nosc":"cocotiers","lemma":"cocotier","pos":"NOM"} ,
		{"word":"cocotte","word_nosc":"cocotte","lemma":"cocotte","pos":"NOM"} ,
		{"word":"cocotte-minute","word_nosc":"cocotte-minute","lemma":"cocotte-minute","pos":"NOM"} ,
		{"word":"cocottes","word_nosc":"cocottes","lemma":"cocotte","pos":"NOM"} ,
		{"word":"coction","word_nosc":"coction","lemma":"coction","pos":"NOM"} ,
		{"word":"cocu","word_nosc":"cocu","lemma":"cocu","pos":"NOM"} ,
		{"word":"cocuage","word_nosc":"cocuage","lemma":"cocuage","pos":"NOM"} ,
		{"word":"cocuages","word_nosc":"cocuages","lemma":"cocuage","pos":"NOM"} ,
		{"word":"cocue","word_nosc":"cocue","lemma":"cocu","pos":"NOM"} ,
		{"word":"cocus","word_nosc":"cocus","lemma":"cocu","pos":"NOM"} ,
		{"word":"coda","word_nosc":"coda","lemma":"coda","pos":"NOM"} ,
		{"word":"codage","word_nosc":"codage","lemma":"codage","pos":"NOM"} ,
		{"word":"codages","word_nosc":"codages","lemma":"codage","pos":"NOM"} ,
		{"word":"code","word_nosc":"code","lemma":"code","pos":"NOM"} ,
		{"word":"code-barre","word_nosc":"code-barre","lemma":"code-barre","pos":"NOM"} ,
		{"word":"code-barres","word_nosc":"code-barres","lemma":"code-barres","pos":"NOM"} ,
		{"word":"code-clé","word_nosc":"code-cle","lemma":"code-clé","pos":"NOM"} ,
		{"word":"codes","word_nosc":"codes","lemma":"code","pos":"NOM"} ,
		{"word":"codes-clés","word_nosc":"codes-cles","lemma":"codes-clé","pos":"NOM"} ,
		{"word":"codeur","word_nosc":"codeur","lemma":"codeur","pos":"NOM"} ,
		{"word":"codex","word_nosc":"codex","lemma":"codex","pos":"NOM"} ,
		{"word":"codicille","word_nosc":"codicille","lemma":"codicille","pos":"NOM"} ,
		{"word":"codicilles","word_nosc":"codicilles","lemma":"codicille","pos":"NOM"} ,
		{"word":"codification","word_nosc":"codification","lemma":"codification","pos":"NOM"} ,
		{"word":"codon","word_nosc":"codon","lemma":"codon","pos":"NOM"} ,
		{"word":"codéine","word_nosc":"codeine","lemma":"codéine","pos":"NOM"} ,
		{"word":"codétenu","word_nosc":"codetenu","lemma":"codétenu","pos":"NOM"} ,
		{"word":"codétenus","word_nosc":"codetenus","lemma":"codétenu","pos":"NOM"} ,
		{"word":"coefficient","word_nosc":"coefficient","lemma":"coefficient","pos":"NOM"} ,
		{"word":"coefficients","word_nosc":"coefficients","lemma":"coefficient","pos":"NOM"} ,
		{"word":"coelacanthe","word_nosc":"coelacanthe","lemma":"coelacanthe","pos":"NOM"} ,
		{"word":"coentreprise","word_nosc":"coentreprise","lemma":"coentreprise","pos":"NOM"} ,
		{"word":"coenzyme","word_nosc":"coenzyme","lemma":"coenzyme","pos":"NOM"} ,
		{"word":"coercition","word_nosc":"coercition","lemma":"coercition","pos":"NOM"} ,
		{"word":"coeur","word_nosc":"coeur","lemma":"coeur","pos":"NOM"} ,
		{"word":"coeur-poumons","word_nosc":"coeur-poumons","lemma":"coeur-poumon","pos":"NOM"} ,
		{"word":"coeurs","word_nosc":"coeurs","lemma":"coeur","pos":"NOM"} ,
		{"word":"coexistence","word_nosc":"coexistence","lemma":"coexistence","pos":"NOM"} ,
		{"word":"coffee shop","word_nosc":"coffee shop","lemma":"coffee shop","pos":"NOM"} ,
		{"word":"coffin","word_nosc":"coffin","lemma":"coffin","pos":"NOM"} ,
		{"word":"coffio","word_nosc":"coffio","lemma":"coffio","pos":"NOM"} ,
		{"word":"coffios","word_nosc":"coffios","lemma":"coffio","pos":"NOM"} ,
		{"word":"coffiot","word_nosc":"coffiot","lemma":"coffiot","pos":"NOM"} ,
		{"word":"coffiots","word_nosc":"coffiots","lemma":"coffiot","pos":"NOM"} ,
		{"word":"coffrage","word_nosc":"coffrage","lemma":"coffrage","pos":"NOM"} ,
		{"word":"coffrages","word_nosc":"coffrages","lemma":"coffrage","pos":"NOM"} ,
		{"word":"coffre","word_nosc":"coffre","lemma":"coffre","pos":"NOM"} ,
		{"word":"coffre-fort","word_nosc":"coffre-fort","lemma":"coffre-fort","pos":"NOM"} ,
		{"word":"coffres","word_nosc":"coffres","lemma":"coffre","pos":"NOM"} ,
		{"word":"coffres-forts","word_nosc":"coffres-forts","lemma":"coffre-fort","pos":"NOM"} ,
		{"word":"coffret","word_nosc":"coffret","lemma":"coffret","pos":"NOM"} ,
		{"word":"coffrets","word_nosc":"coffrets","lemma":"coffret","pos":"NOM"} ,
		{"word":"cofinancement","word_nosc":"cofinancement","lemma":"cofinancement","pos":"NOM"} ,
		{"word":"cofondateur","word_nosc":"cofondateur","lemma":"cofondateur","pos":"NOM"} ,
		{"word":"cofondateurs","word_nosc":"cofondateurs","lemma":"cofondateur","pos":"NOM"} ,
		{"word":"cofondatrice","word_nosc":"cofondatrice","lemma":"cofondateur","pos":"NOM"} ,
		{"word":"cogitation","word_nosc":"cogitation","lemma":"cogitation","pos":"NOM"} ,
		{"word":"cogitations","word_nosc":"cogitations","lemma":"cogitation","pos":"NOM"} ,
		{"word":"cogito","word_nosc":"cogito","lemma":"cogito","pos":"NOM"} ,
		{"word":"cognac","word_nosc":"cognac","lemma":"cognac","pos":"NOM"} ,
		{"word":"cognacs","word_nosc":"cognacs","lemma":"cognac","pos":"NOM"} ,
		{"word":"cognassier","word_nosc":"cognassier","lemma":"cognassier","pos":"NOM"} ,
		{"word":"cognassiers","word_nosc":"cognassiers","lemma":"cognassier","pos":"NOM"} ,
		{"word":"cogne","word_nosc":"cogne","lemma":"cogne","pos":"NOM"} ,
		{"word":"cognement","word_nosc":"cognement","lemma":"cognement","pos":"NOM"} ,
		{"word":"cognements","word_nosc":"cognements","lemma":"cognement","pos":"NOM"} ,
		{"word":"cognes","word_nosc":"cognes","lemma":"cogne","pos":"NOM"} ,
		{"word":"cogneur","word_nosc":"cogneur","lemma":"cogneur","pos":"NOM"} ,
		{"word":"cogneurs","word_nosc":"cogneurs","lemma":"cogneur","pos":"NOM"} ,
		{"word":"cogneuse","word_nosc":"cogneuse","lemma":"cogneur","pos":"NOM"} ,
		{"word":"cognition","word_nosc":"cognition","lemma":"cognition","pos":"NOM"} ,
		{"word":"cognée","word_nosc":"cognee","lemma":"cognée","pos":"NOM"} ,
		{"word":"cognées","word_nosc":"cognees","lemma":"cognée","pos":"NOM"} ,
		{"word":"cogérant","word_nosc":"cogerant","lemma":"cogérant","pos":"NOM"} ,
		{"word":"cohabitation","word_nosc":"cohabitation","lemma":"cohabitation","pos":"NOM"} ,
		{"word":"cohabitations","word_nosc":"cohabitations","lemma":"cohabitation","pos":"NOM"} ,
		{"word":"cohorte","word_nosc":"cohorte","lemma":"cohorte","pos":"NOM"} ,
		{"word":"cohortes","word_nosc":"cohortes","lemma":"cohorte","pos":"NOM"} ,
		{"word":"cohue","word_nosc":"cohue","lemma":"cohue","pos":"NOM"} ,
		{"word":"cohues","word_nosc":"cohues","lemma":"cohue","pos":"NOM"} ,
		{"word":"cohérence","word_nosc":"coherence","lemma":"cohérence","pos":"NOM"} ,
		{"word":"cohérences","word_nosc":"coherences","lemma":"cohérence","pos":"NOM"} ,
		{"word":"cohéritier","word_nosc":"coheritier","lemma":"cohéritier","pos":"NOM"} ,
		{"word":"cohéritiers","word_nosc":"coheritiers","lemma":"cohéritier","pos":"NOM"} ,
		{"word":"cohésion","word_nosc":"cohesion","lemma":"cohésion","pos":"NOM"} ,
		{"word":"coiffe","word_nosc":"coiffe","lemma":"coiffe","pos":"NOM"} ,
		{"word":"coiffes","word_nosc":"coiffes","lemma":"coiffe","pos":"NOM"} ,
		{"word":"coiffeur","word_nosc":"coiffeur","lemma":"coiffeur","pos":"NOM"} ,
		{"word":"coiffeurs","word_nosc":"coiffeurs","lemma":"coiffeur","pos":"NOM"} ,
		{"word":"coiffeuse","word_nosc":"coiffeuse","lemma":"coiffeur","pos":"NOM"} ,
		{"word":"coiffeuses","word_nosc":"coiffeuses","lemma":"coiffeur","pos":"NOM"} ,
		{"word":"coiffure","word_nosc":"coiffure","lemma":"coiffure","pos":"NOM"} ,
		{"word":"coiffures","word_nosc":"coiffures","lemma":"coiffure","pos":"NOM"} ,
		{"word":"coin","word_nosc":"coin","lemma":"coin","pos":"NOM"} ,
		{"word":"coin-coin","word_nosc":"coin-coin","lemma":"coin-coin","pos":"NOM"} ,
		{"word":"coin-repas","word_nosc":"coin-repas","lemma":"coin-repas","pos":"NOM"} ,
		{"word":"coinceur","word_nosc":"coinceur","lemma":"coinceur","pos":"NOM"} ,
		{"word":"coing","word_nosc":"coing","lemma":"coing","pos":"NOM"} ,
		{"word":"coings","word_nosc":"coings","lemma":"coing","pos":"NOM"} ,
		{"word":"coins","word_nosc":"coins","lemma":"coin","pos":"NOM"} ,
		{"word":"coinstot","word_nosc":"coinstot","lemma":"coinstot","pos":"NOM"} ,
		{"word":"coinstots","word_nosc":"coinstots","lemma":"coinstot","pos":"NOM"} ,
		{"word":"coinçage","word_nosc":"coincage","lemma":"coinçage","pos":"NOM"} ,
		{"word":"coinçages","word_nosc":"coincages","lemma":"coinçage","pos":"NOM"} ,
		{"word":"coir","word_nosc":"coir","lemma":"coir","pos":"NOM"} ,
		{"word":"coke","word_nosc":"coke","lemma":"coke","pos":"NOM"} ,
		{"word":"cokes","word_nosc":"cokes","lemma":"coke","pos":"NOM"} ,
		{"word":"col","word_nosc":"col","lemma":"col","pos":"NOM"} ,
		{"word":"col-de-cygne","word_nosc":"col-de-cygne","lemma":"col-de-cygne","pos":"NOM"} ,
		{"word":"col-vert","word_nosc":"col-vert","lemma":"col-vert","pos":"NOM"} ,
		{"word":"cola","word_nosc":"cola","lemma":"cola","pos":"NOM"} ,
		{"word":"colas","word_nosc":"colas","lemma":"cola","pos":"NOM"} ,
		{"word":"colback","word_nosc":"colback","lemma":"colback","pos":"NOM"} ,
		{"word":"colbacks","word_nosc":"colbacks","lemma":"colback","pos":"NOM"} ,
		{"word":"colchicine","word_nosc":"colchicine","lemma":"colchicine","pos":"NOM"} ,
		{"word":"colchique","word_nosc":"colchique","lemma":"colchique","pos":"NOM"} ,
		{"word":"colchiques","word_nosc":"colchiques","lemma":"colchique","pos":"NOM"} ,
		{"word":"colcotar","word_nosc":"colcotar","lemma":"colcotar","pos":"NOM"} ,
		{"word":"cold-cream","word_nosc":"cold-cream","lemma":"cold-cream","pos":"NOM"} ,
		{"word":"colectomie","word_nosc":"colectomie","lemma":"colectomie","pos":"NOM"} ,
		{"word":"colibacilles","word_nosc":"colibacilles","lemma":"colibacille","pos":"NOM"} ,
		{"word":"colibri","word_nosc":"colibri","lemma":"colibri","pos":"NOM"} ,
		{"word":"colibris","word_nosc":"colibris","lemma":"colibri","pos":"NOM"} ,
		{"word":"colifichet","word_nosc":"colifichet","lemma":"colifichet","pos":"NOM"} ,
		{"word":"colifichets","word_nosc":"colifichets","lemma":"colifichet","pos":"NOM"} ,
		{"word":"colimaçon","word_nosc":"colimacon","lemma":"colimaçon","pos":"NOM"} ,
		{"word":"colimaçons","word_nosc":"colimacons","lemma":"colimaçon","pos":"NOM"} ,
		{"word":"colin","word_nosc":"colin","lemma":"colin","pos":"NOM"} ,
		{"word":"colin-maillard","word_nosc":"colin-maillard","lemma":"colin-maillard","pos":"NOM"} ,
		{"word":"colique","word_nosc":"colique","lemma":"colique","pos":"NOM"} ,
		{"word":"coliques","word_nosc":"coliques","lemma":"colique","pos":"NOM"} ,
		{"word":"colis","word_nosc":"colis","lemma":"colis","pos":"NOM"} ,
		{"word":"colis-cadeau","word_nosc":"colis-cadeau","lemma":"colis-cadeau","pos":"NOM"} ,
		{"word":"colis-repas","word_nosc":"colis-repas","lemma":"colis-repas","pos":"NOM"} ,
		{"word":"colistier","word_nosc":"colistier","lemma":"colistier","pos":"NOM"} ,
		{"word":"colite","word_nosc":"colite","lemma":"colite","pos":"NOM"} ,
		{"word":"colites","word_nosc":"colites","lemma":"colite","pos":"NOM"} ,
		{"word":"collabo","word_nosc":"collabo","lemma":"collabo","pos":"NOM"} ,
		{"word":"collaborateur","word_nosc":"collaborateur","lemma":"collaborateur","pos":"NOM"} ,
		{"word":"collaborateurs","word_nosc":"collaborateurs","lemma":"collaborateur","pos":"NOM"} ,
		{"word":"collaboration","word_nosc":"collaboration","lemma":"collaboration","pos":"NOM"} ,
		{"word":"collaborations","word_nosc":"collaborations","lemma":"collaboration","pos":"NOM"} ,
		{"word":"collaboratrice","word_nosc":"collaboratrice","lemma":"collaborateur","pos":"NOM"} ,
		{"word":"collaboratrices","word_nosc":"collaboratrices","lemma":"collaborateur","pos":"NOM"} ,
		{"word":"collabos","word_nosc":"collabos","lemma":"collabo","pos":"NOM"} ,
		{"word":"collage","word_nosc":"collage","lemma":"collage","pos":"NOM"} ,
		{"word":"collages","word_nosc":"collages","lemma":"collage","pos":"NOM"} ,
		{"word":"collagène","word_nosc":"collagene","lemma":"collagène","pos":"NOM"} ,
		{"word":"collant","word_nosc":"collant","lemma":"collant","pos":"NOM"} ,
		{"word":"collante","word_nosc":"collante","lemma":"collant","pos":"NOM"} ,
		{"word":"collantes","word_nosc":"collantes","lemma":"collant","pos":"NOM"} ,
		{"word":"collants","word_nosc":"collants","lemma":"collant","pos":"NOM"} ,
		{"word":"collapsus","word_nosc":"collapsus","lemma":"collapsus","pos":"NOM"} ,
		{"word":"collation","word_nosc":"collation","lemma":"collation","pos":"NOM"} ,
		{"word":"collationnement","word_nosc":"collationnement","lemma":"collationnement","pos":"NOM"} ,
		{"word":"collations","word_nosc":"collations","lemma":"collation","pos":"NOM"} ,
		{"word":"collatéraux","word_nosc":"collateraux","lemma":"collatéral","pos":"NOM"} ,
		{"word":"colle","word_nosc":"colle","lemma":"colle","pos":"NOM"} ,
		{"word":"collecte","word_nosc":"collecte","lemma":"collecte","pos":"NOM"} ,
		{"word":"collectes","word_nosc":"collectes","lemma":"collecte","pos":"NOM"} ,
		{"word":"collecteur","word_nosc":"collecteur","lemma":"collecteur","pos":"NOM"} ,
		{"word":"collecteurs","word_nosc":"collecteurs","lemma":"collecteur","pos":"NOM"} ,
		{"word":"collectif","word_nosc":"collectif","lemma":"collectif","pos":"NOM"} ,
		{"word":"collection","word_nosc":"collection","lemma":"collection","pos":"NOM"} ,
		{"word":"collectionneur","word_nosc":"collectionneur","lemma":"collectionneur","pos":"NOM"} ,
		{"word":"collectionneurs","word_nosc":"collectionneurs","lemma":"collectionneur","pos":"NOM"} ,
		{"word":"collectionneuse","word_nosc":"collectionneuse","lemma":"collectionneur","pos":"NOM"} ,
		{"word":"collections","word_nosc":"collections","lemma":"collection","pos":"NOM"} ,
		{"word":"collectivisation","word_nosc":"collectivisation","lemma":"collectivisation","pos":"NOM"} ,
		{"word":"collectivisme","word_nosc":"collectivisme","lemma":"collectivisme","pos":"NOM"} ,
		{"word":"collectivité","word_nosc":"collectivite","lemma":"collectivité","pos":"NOM"} ,
		{"word":"collectivités","word_nosc":"collectivites","lemma":"collectivité","pos":"NOM"} ,
		{"word":"collector","word_nosc":"collector","lemma":"collector","pos":"NOM"} ,
		{"word":"collerette","word_nosc":"collerette","lemma":"collerette","pos":"NOM"} ,
		{"word":"collerettes","word_nosc":"collerettes","lemma":"collerette","pos":"NOM"} ,
		{"word":"colles","word_nosc":"colles","lemma":"colle","pos":"NOM"} ,
		{"word":"collet","word_nosc":"collet","lemma":"collet","pos":"NOM"} ,
		{"word":"colletage","word_nosc":"colletage","lemma":"colletage","pos":"NOM"} ,
		{"word":"colletin","word_nosc":"colletin","lemma":"colletin","pos":"NOM"} ,
		{"word":"collets","word_nosc":"collets","lemma":"collet","pos":"NOM"} ,
		{"word":"colleur","word_nosc":"colleur","lemma":"colleur","pos":"NOM"} ,
		{"word":"colleurs","word_nosc":"colleurs","lemma":"colleur","pos":"NOM"} ,
		{"word":"colleuse","word_nosc":"colleuse","lemma":"colleuse","pos":"NOM"} ,
		{"word":"colley","word_nosc":"colley","lemma":"colley","pos":"NOM"} ,
		{"word":"colleys","word_nosc":"colleys","lemma":"colley","pos":"NOM"} ,
		{"word":"collier","word_nosc":"collier","lemma":"collier","pos":"NOM"} ,
		{"word":"colliers","word_nosc":"colliers","lemma":"collier","pos":"NOM"} ,
		{"word":"collignon","word_nosc":"collignon","lemma":"collignon","pos":"NOM"} ,
		{"word":"collimateur","word_nosc":"collimateur","lemma":"collimateur","pos":"NOM"} ,
		{"word":"collimation","word_nosc":"collimation","lemma":"collimation","pos":"NOM"} ,
		{"word":"colline","word_nosc":"colline","lemma":"colline","pos":"NOM"} ,
		{"word":"collines","word_nosc":"collines","lemma":"colline","pos":"NOM"} ,
		{"word":"collision","word_nosc":"collision","lemma":"collision","pos":"NOM"} ,
		{"word":"collisions","word_nosc":"collisions","lemma":"collision","pos":"NOM"} ,
		{"word":"collodion","word_nosc":"collodion","lemma":"collodion","pos":"NOM"} ,
		{"word":"colloque","word_nosc":"colloque","lemma":"colloque","pos":"NOM"} ,
		{"word":"colloques","word_nosc":"colloques","lemma":"colloque","pos":"NOM"} ,
		{"word":"colloïde","word_nosc":"colloide","lemma":"colloïde","pos":"NOM"} ,
		{"word":"collure","word_nosc":"collure","lemma":"collure","pos":"NOM"} ,
		{"word":"collusion","word_nosc":"collusion","lemma":"collusion","pos":"NOM"} ,
		{"word":"collusions","word_nosc":"collusions","lemma":"collusion","pos":"NOM"} ,
		{"word":"collutoire","word_nosc":"collutoire","lemma":"collutoire","pos":"NOM"} ,
		{"word":"collyre","word_nosc":"collyre","lemma":"collyre","pos":"NOM"} ,
		{"word":"collyres","word_nosc":"collyres","lemma":"collyre","pos":"NOM"} ,
		{"word":"collège","word_nosc":"college","lemma":"collège","pos":"NOM"} ,
		{"word":"collèges","word_nosc":"colleges","lemma":"collège","pos":"NOM"} ,
		{"word":"collègue","word_nosc":"collegue","lemma":"collègue","pos":"NOM"} ,
		{"word":"collègues","word_nosc":"collegues","lemma":"collègue","pos":"NOM"} ,
		{"word":"collégiale","word_nosc":"collegiale","lemma":"collégiale","pos":"NOM"} ,
		{"word":"collégialité","word_nosc":"collegialite","lemma":"collégialité","pos":"NOM"} ,
		{"word":"collégien","word_nosc":"collegien","lemma":"collégien","pos":"NOM"} ,
		{"word":"collégienne","word_nosc":"collegienne","lemma":"collégien","pos":"NOM"} ,
		{"word":"collégiennes","word_nosc":"collegiennes","lemma":"collégien","pos":"NOM"} ,
		{"word":"collégiens","word_nosc":"collegiens","lemma":"collégien","pos":"NOM"} ,
		{"word":"colmatage","word_nosc":"colmatage","lemma":"colmatage","pos":"NOM"} ,
		{"word":"colo","word_nosc":"colo","lemma":"colo","pos":"NOM"} ,
		{"word":"colocataire","word_nosc":"colocataire","lemma":"colocataire","pos":"NOM"} ,
		{"word":"colocataires","word_nosc":"colocataires","lemma":"colocataire","pos":"NOM"} ,
		{"word":"colocation","word_nosc":"colocation","lemma":"colocation","pos":"NOM"} ,
		{"word":"colombages","word_nosc":"colombages","lemma":"colombage","pos":"NOM"} ,
		{"word":"colombe","word_nosc":"colombe","lemma":"colombe","pos":"NOM"} ,
		{"word":"colombelle","word_nosc":"colombelle","lemma":"colombelle","pos":"NOM"} ,
		{"word":"colombes","word_nosc":"colombes","lemma":"colombe","pos":"NOM"} ,
		{"word":"colombien","word_nosc":"colombien","lemma":"colombien","pos":"NOM"} ,
		{"word":"colombienne","word_nosc":"colombienne","lemma":"colombien","pos":"NOM"} ,
		{"word":"colombiens","word_nosc":"colombiens","lemma":"colombien","pos":"NOM"} ,
		{"word":"colombier","word_nosc":"colombier","lemma":"colombier","pos":"NOM"} ,
		{"word":"colombiers","word_nosc":"colombiers","lemma":"colombier","pos":"NOM"} ,
		{"word":"colombin","word_nosc":"colombin","lemma":"colombin","pos":"NOM"} ,
		{"word":"colombins","word_nosc":"colombins","lemma":"colombin","pos":"NOM"} ,
		{"word":"colombo","word_nosc":"colombo","lemma":"colombo","pos":"NOM"} ,
		{"word":"colombophile","word_nosc":"colombophile","lemma":"colombophile","pos":"NOM"} ,
		{"word":"colombophilie","word_nosc":"colombophilie","lemma":"colombophilie","pos":"NOM"} ,
		{"word":"colon","word_nosc":"colon","lemma":"colon","pos":"NOM"} ,
		{"word":"colonel","word_nosc":"colonel","lemma":"colonel","pos":"NOM"} ,
		{"word":"colonelle","word_nosc":"colonelle","lemma":"colonel","pos":"NOM"} ,
		{"word":"colonels","word_nosc":"colonels","lemma":"colonel","pos":"NOM"} ,
		{"word":"colonial","word_nosc":"colonial","lemma":"colonial","pos":"NOM"} ,
		{"word":"colonialisme","word_nosc":"colonialisme","lemma":"colonialisme","pos":"NOM"} ,
		{"word":"colonialismes","word_nosc":"colonialismes","lemma":"colonialisme","pos":"NOM"} ,
		{"word":"colonialiste","word_nosc":"colonialiste","lemma":"colonialiste","pos":"NOM"} ,
		{"word":"colonialistes","word_nosc":"colonialistes","lemma":"colonialiste","pos":"NOM"} ,
		{"word":"coloniaux","word_nosc":"coloniaux","lemma":"colonial","pos":"NOM"} ,
		{"word":"colonie","word_nosc":"colonie","lemma":"colonie","pos":"NOM"} ,
		{"word":"colonies","word_nosc":"colonies","lemma":"colonie","pos":"NOM"} ,
		{"word":"colonisateur","word_nosc":"colonisateur","lemma":"colonisateur","pos":"NOM"} ,
		{"word":"colonisateurs","word_nosc":"colonisateurs","lemma":"colonisateur","pos":"NOM"} ,
		{"word":"colonisation","word_nosc":"colonisation","lemma":"colonisation","pos":"NOM"} ,
		{"word":"colonisations","word_nosc":"colonisations","lemma":"colonisation","pos":"NOM"} ,
		{"word":"colonisé","word_nosc":"colonise","lemma":"colonisé","pos":"NOM"} ,
		{"word":"colonisés","word_nosc":"colonises","lemma":"colonisé","pos":"NOM"} ,
		{"word":"colonnade","word_nosc":"colonnade","lemma":"colonnade","pos":"NOM"} ,
		{"word":"colonnades","word_nosc":"colonnades","lemma":"colonnade","pos":"NOM"} ,
		{"word":"colonne","word_nosc":"colonne","lemma":"colonne","pos":"NOM"} ,
		{"word":"colonnes","word_nosc":"colonnes","lemma":"colonne","pos":"NOM"} ,
		{"word":"colonnette","word_nosc":"colonnette","lemma":"colonnette","pos":"NOM"} ,
		{"word":"colonnettes","word_nosc":"colonnettes","lemma":"colonnette","pos":"NOM"} ,
		{"word":"colonoscopie","word_nosc":"colonoscopie","lemma":"colonoscopie","pos":"NOM"} ,
		{"word":"colons","word_nosc":"colons","lemma":"colon","pos":"NOM"} ,
		{"word":"colopathie","word_nosc":"colopathie","lemma":"colopathie","pos":"NOM"} ,
		{"word":"colophane","word_nosc":"colophane","lemma":"colophane","pos":"NOM"} ,
		{"word":"coloquinte","word_nosc":"coloquinte","lemma":"coloquinte","pos":"NOM"} ,
		{"word":"coloquintes","word_nosc":"coloquintes","lemma":"coloquinte","pos":"NOM"} ,
		{"word":"colorant","word_nosc":"colorant","lemma":"colorant","pos":"NOM"} ,
		{"word":"colorants","word_nosc":"colorants","lemma":"colorant","pos":"NOM"} ,
		{"word":"coloration","word_nosc":"coloration","lemma":"coloration","pos":"NOM"} ,
		{"word":"colorations","word_nosc":"colorations","lemma":"coloration","pos":"NOM"} ,
		{"word":"colorature","word_nosc":"colorature","lemma":"colorature","pos":"NOM"} ,
		{"word":"coloriage","word_nosc":"coloriage","lemma":"coloriage","pos":"NOM"} ,
		{"word":"coloriages","word_nosc":"coloriages","lemma":"coloriage","pos":"NOM"} ,
		{"word":"coloris","word_nosc":"coloris","lemma":"coloris","pos":"NOM"} ,
		{"word":"colorisation","word_nosc":"colorisation","lemma":"colorisation","pos":"NOM"} ,
		{"word":"coloriste","word_nosc":"coloriste","lemma":"coloriste","pos":"NOM"} ,
		{"word":"colos","word_nosc":"colos","lemma":"colo","pos":"NOM"} ,
		{"word":"coloscopie","word_nosc":"coloscopie","lemma":"coloscopie","pos":"NOM"} ,
		{"word":"colosse","word_nosc":"colosse","lemma":"colosse","pos":"NOM"} ,
		{"word":"colosses","word_nosc":"colosses","lemma":"colosse","pos":"NOM"} ,
		{"word":"colostomie","word_nosc":"colostomie","lemma":"colostomie","pos":"NOM"} ,
		{"word":"colostrum","word_nosc":"colostrum","lemma":"colostrum","pos":"NOM"} ,
		{"word":"colportage","word_nosc":"colportage","lemma":"colportage","pos":"NOM"} ,
		{"word":"colporteur","word_nosc":"colporteur","lemma":"colporteur","pos":"NOM"} ,
		{"word":"colporteurs","word_nosc":"colporteurs","lemma":"colporteur","pos":"NOM"} ,
		{"word":"colporteuse","word_nosc":"colporteuse","lemma":"colporteur","pos":"NOM"} ,
		{"word":"colposcopie","word_nosc":"colposcopie","lemma":"colposcopie","pos":"NOM"} ,
		{"word":"cols","word_nosc":"cols","lemma":"col","pos":"NOM"} ,
		{"word":"cols-de-cygne","word_nosc":"cols-de-cygne","lemma":"cols-de-cygne","pos":"NOM"} ,
		{"word":"cols-verts","word_nosc":"cols-verts","lemma":"col-vert","pos":"NOM"} ,
		{"word":"colt","word_nosc":"colt","lemma":"colt","pos":"NOM"} ,
		{"word":"coltin","word_nosc":"coltin","lemma":"coltin","pos":"NOM"} ,
		{"word":"coltinage","word_nosc":"coltinage","lemma":"coltinage","pos":"NOM"} ,
		{"word":"coltinages","word_nosc":"coltinages","lemma":"coltinage","pos":"NOM"} ,
		{"word":"coltineur","word_nosc":"coltineur","lemma":"coltineur","pos":"NOM"} ,
		{"word":"colts","word_nosc":"colts","lemma":"colt","pos":"NOM"} ,
		{"word":"columbarium","word_nosc":"columbarium","lemma":"columbarium","pos":"NOM"} ,
		{"word":"colvert","word_nosc":"colvert","lemma":"colvert","pos":"NOM"} ,
		{"word":"colverts","word_nosc":"colverts","lemma":"colvert","pos":"NOM"} ,
		{"word":"colza","word_nosc":"colza","lemma":"colza","pos":"NOM"} ,
		{"word":"colzas","word_nosc":"colzas","lemma":"colza","pos":"NOM"} ,
		{"word":"colère","word_nosc":"colere","lemma":"colère","pos":"NOM"} ,
		{"word":"colères","word_nosc":"coleres","lemma":"colère","pos":"NOM"} ,
		{"word":"colée","word_nosc":"colee","lemma":"colée","pos":"NOM"} ,
		{"word":"coléoptère","word_nosc":"coleoptere","lemma":"coléoptère","pos":"NOM"} ,
		{"word":"coléoptères","word_nosc":"coleopteres","lemma":"coléoptère","pos":"NOM"} ,
		{"word":"com","word_nosc":"com","lemma":"com","pos":"NOM"} ,
		{"word":"coma","word_nosc":"coma","lemma":"coma","pos":"NOM"} ,
		{"word":"comanche","word_nosc":"comanche","lemma":"comanche","pos":"NOM"} ,
		{"word":"comandant","word_nosc":"comandant","lemma":"comandant","pos":"NOM"} ,
		{"word":"comas","word_nosc":"comas","lemma":"coma","pos":"NOM"} ,
		{"word":"comateuse","word_nosc":"comateuse","lemma":"comateux","pos":"NOM"} ,
		{"word":"combat","word_nosc":"combat","lemma":"combat","pos":"NOM"} ,
		{"word":"combativité","word_nosc":"combativite","lemma":"combativité","pos":"NOM"} ,
		{"word":"combats","word_nosc":"combats","lemma":"combat","pos":"NOM"} ,
		{"word":"combattant","word_nosc":"combattant","lemma":"combattant","pos":"NOM"} ,
		{"word":"combattante","word_nosc":"combattante","lemma":"combattant","pos":"NOM"} ,
		{"word":"combattantes","word_nosc":"combattantes","lemma":"combattant","pos":"NOM"} ,
		{"word":"combattants","word_nosc":"combattants","lemma":"combattant","pos":"NOM"} ,
		{"word":"combe","word_nosc":"combe","lemma":"combe","pos":"NOM"} ,
		{"word":"combes","word_nosc":"combes","lemma":"combe","pos":"NOM"} ,
		{"word":"combette","word_nosc":"combette","lemma":"combette","pos":"NOM"} ,
		{"word":"combi","word_nosc":"combi","lemma":"combi","pos":"NOM"} ,
		{"word":"combientième","word_nosc":"combientieme","lemma":"combientième","pos":"NOM"} ,
		{"word":"combinaison","word_nosc":"combinaison","lemma":"combinaison","pos":"NOM"} ,
		{"word":"combinaisons","word_nosc":"combinaisons","lemma":"combinaison","pos":"NOM"} ,
		{"word":"combinard","word_nosc":"combinard","lemma":"combinard","pos":"NOM"} ,
		{"word":"combinards","word_nosc":"combinards","lemma":"combinard","pos":"NOM"} ,
		{"word":"combinat","word_nosc":"combinat","lemma":"combinat","pos":"NOM"} ,
		{"word":"combine","word_nosc":"combine","lemma":"combine","pos":"NOM"} ,
		{"word":"combines","word_nosc":"combines","lemma":"combine","pos":"NOM"} ,
		{"word":"combiné","word_nosc":"combine","lemma":"combiné","pos":"NOM"} ,
		{"word":"combinés","word_nosc":"combines","lemma":"combiné","pos":"NOM"} ,
		{"word":"combis","word_nosc":"combis","lemma":"combi","pos":"NOM"} ,
		{"word":"combisme","word_nosc":"combisme","lemma":"combisme","pos":"NOM"} ,
		{"word":"comble","word_nosc":"comble","lemma":"comble","pos":"NOM"} ,
		{"word":"combles","word_nosc":"combles","lemma":"comble","pos":"NOM"} ,
		{"word":"combo","word_nosc":"combo","lemma":"combo","pos":"NOM"} ,
		{"word":"combourgeois","word_nosc":"combourgeois","lemma":"combourgeois","pos":"NOM"} ,
		{"word":"combustible","word_nosc":"combustible","lemma":"combustible","pos":"NOM"} ,
		{"word":"combustibles","word_nosc":"combustibles","lemma":"combustible","pos":"NOM"} ,
		{"word":"combustion","word_nosc":"combustion","lemma":"combustion","pos":"NOM"} ,
		{"word":"combustions","word_nosc":"combustions","lemma":"combustion","pos":"NOM"} ,
		{"word":"comestibilité","word_nosc":"comestibilite","lemma":"comestibilité","pos":"NOM"} ,
		{"word":"comestible","word_nosc":"comestible","lemma":"comestible","pos":"NOM"} ,
		{"word":"comestibles","word_nosc":"comestibles","lemma":"comestible","pos":"NOM"} ,
		{"word":"comic book","word_nosc":"comic book","lemma":"comic book","pos":"NOM"} ,
		{"word":"comic books","word_nosc":"comic books","lemma":"comic book","pos":"NOM"} ,
		{"word":"comice","word_nosc":"comice","lemma":"comice","pos":"NOM"} ,
		{"word":"comices","word_nosc":"comices","lemma":"comice","pos":"NOM"} ,
		{"word":"comics","word_nosc":"comics","lemma":"comics","pos":"NOM"} ,
		{"word":"comique","word_nosc":"comique","lemma":"comique","pos":"NOM"} ,
		{"word":"comiques","word_nosc":"comiques","lemma":"comique","pos":"NOM"} ,
		{"word":"comitadji","word_nosc":"comitadji","lemma":"comitadji","pos":"NOM"} ,
		{"word":"comite","word_nosc":"comite","lemma":"comite","pos":"NOM"} ,
		{"word":"comites","word_nosc":"comites","lemma":"comite","pos":"NOM"} ,
		{"word":"comité","word_nosc":"comite","lemma":"comité","pos":"NOM"} ,
		{"word":"comités","word_nosc":"comites","lemma":"comité","pos":"NOM"} ,
		{"word":"comma","word_nosc":"comma","lemma":"comma","pos":"NOM"} ,
		{"word":"command","word_nosc":"command","lemma":"command","pos":"NOM"} ,
		{"word":"commandant","word_nosc":"commandant","lemma":"commandant","pos":"NOM"} ,
		{"word":"commandants","word_nosc":"commandants","lemma":"commandant","pos":"NOM"} ,
		{"word":"commande","word_nosc":"commande","lemma":"commande","pos":"NOM"} ,
		{"word":"commandement","word_nosc":"commandement","lemma":"commandement","pos":"NOM"} ,
		{"word":"commandements","word_nosc":"commandements","lemma":"commandement","pos":"NOM"} ,
		{"word":"commanderie","word_nosc":"commanderie","lemma":"commanderie","pos":"NOM"} ,
		{"word":"commanderies","word_nosc":"commanderies","lemma":"commanderie","pos":"NOM"} ,
		{"word":"commandes","word_nosc":"commandes","lemma":"commande","pos":"NOM"} ,
		{"word":"commandeur","word_nosc":"commandeur","lemma":"commandeur","pos":"NOM"} ,
		{"word":"commandeurs","word_nosc":"commandeurs","lemma":"commandeur","pos":"NOM"} ,
		{"word":"commanditaire","word_nosc":"commanditaire","lemma":"commanditaire","pos":"NOM"} ,
		{"word":"commanditaires","word_nosc":"commanditaires","lemma":"commanditaire","pos":"NOM"} ,
		{"word":"commandite","word_nosc":"commandite","lemma":"commandite","pos":"NOM"} ,
		{"word":"commandites","word_nosc":"commandites","lemma":"commandite","pos":"NOM"} ,
		{"word":"commandité","word_nosc":"commandite","lemma":"commandité","pos":"NOM"} ,
		{"word":"commando","word_nosc":"commando","lemma":"commando","pos":"NOM"} ,
		{"word":"commandos","word_nosc":"commandos","lemma":"commando","pos":"NOM"} ,
		{"word":"commandos-suicide","word_nosc":"commandos-suicide","lemma":"commando-suicide","pos":"NOM"} ,
		{"word":"commedia dell'arte","word_nosc":"commedia dell'arte","lemma":"commedia dell'arte","pos":"NOM"} ,
		{"word":"commencement","word_nosc":"commencement","lemma":"commencement","pos":"NOM"} ,
		{"word":"commencements","word_nosc":"commencements","lemma":"commencement","pos":"NOM"} ,
		{"word":"commensal","word_nosc":"commensal","lemma":"commensal","pos":"NOM"} ,
		{"word":"commensalisme","word_nosc":"commensalisme","lemma":"commensalisme","pos":"NOM"} ,
		{"word":"commensaux","word_nosc":"commensaux","lemma":"commensal","pos":"NOM"} ,
		{"word":"comment","word_nosc":"comment","lemma":"comment","pos":"NOM"} ,
		{"word":"commentaire","word_nosc":"commentaire","lemma":"commentaire","pos":"NOM"} ,
		{"word":"commentaires","word_nosc":"commentaires","lemma":"commentaire","pos":"NOM"} ,
		{"word":"commentateur","word_nosc":"commentateur","lemma":"commentateur","pos":"NOM"} ,
		{"word":"commentateurs","word_nosc":"commentateurs","lemma":"commentateur","pos":"NOM"} ,
		{"word":"commentatrice","word_nosc":"commentatrice","lemma":"commentateur","pos":"NOM"} ,
		{"word":"commençant","word_nosc":"commencant","lemma":"commençant","pos":"NOM"} ,
		{"word":"commerce","word_nosc":"commerce","lemma":"commerce","pos":"NOM"} ,
		{"word":"commerces","word_nosc":"commerces","lemma":"commerce","pos":"NOM"} ,
		{"word":"commercial","word_nosc":"commercial","lemma":"commercial","pos":"NOM"} ,
		{"word":"commerciale","word_nosc":"commerciale","lemma":"commercial","pos":"NOM"} ,
		{"word":"commerciales","word_nosc":"commerciales","lemma":"commercial","pos":"NOM"} ,
		{"word":"commercialisation","word_nosc":"commercialisation","lemma":"commercialisation","pos":"NOM"} ,
		{"word":"commerciaux","word_nosc":"commerciaux","lemma":"commercial","pos":"NOM"} ,
		{"word":"commerçant","word_nosc":"commercant","lemma":"commerçant","pos":"NOM"} ,
		{"word":"commerçante","word_nosc":"commercante","lemma":"commerçant","pos":"NOM"} ,
		{"word":"commerçants","word_nosc":"commercants","lemma":"commerçant","pos":"NOM"} ,
		{"word":"commettant","word_nosc":"commettant","lemma":"commettant","pos":"NOM"} ,
		{"word":"commettants","word_nosc":"commettants","lemma":"commettant","pos":"NOM"} ,
		{"word":"commis","word_nosc":"commis","lemma":"commis","pos":"NOM"} ,
		{"word":"commis-voyageur","word_nosc":"commis-voyageur","lemma":"commis-voyageur","pos":"NOM"} ,
		{"word":"commis-voyageurs","word_nosc":"commis-voyageurs","lemma":"commis-voyageur","pos":"NOM"} ,
		{"word":"commissaire","word_nosc":"commissaire","lemma":"commissaire","pos":"NOM"} ,
		{"word":"commissaire-adjoint","word_nosc":"commissaire-adjoint","lemma":"commissaire-adjoint","pos":"NOM"} ,
		{"word":"commissaire-priseur","word_nosc":"commissaire-priseur","lemma":"commissaire-priseur","pos":"NOM"} ,
		{"word":"commissaires","word_nosc":"commissaires","lemma":"commissaire","pos":"NOM"} ,
		{"word":"commissaires-priseurs","word_nosc":"commissaires-priseurs","lemma":"commissaire-priseur","pos":"NOM"} ,
		{"word":"commissariat","word_nosc":"commissariat","lemma":"commissariat","pos":"NOM"} ,
		{"word":"commissariats","word_nosc":"commissariats","lemma":"commissariat","pos":"NOM"} ,
		{"word":"commission","word_nosc":"commission","lemma":"commission","pos":"NOM"} ,
		{"word":"commissionnaire","word_nosc":"commissionnaire","lemma":"commissionnaire","pos":"NOM"} ,
		{"word":"commissionnaires","word_nosc":"commissionnaires","lemma":"commissionnaire","pos":"NOM"} ,
		{"word":"commissions","word_nosc":"commissions","lemma":"commission","pos":"NOM"} ,
		{"word":"commissure","word_nosc":"commissure","lemma":"commissure","pos":"NOM"} ,
		{"word":"commissures","word_nosc":"commissures","lemma":"commissure","pos":"NOM"} ,
		{"word":"commisération","word_nosc":"commiseration","lemma":"commisération","pos":"NOM"} ,
		{"word":"commode","word_nosc":"commode","lemma":"commode","pos":"NOM"} ,
		{"word":"commode-toilette","word_nosc":"commode-toilette","lemma":"commode-toilette","pos":"NOM"} ,
		{"word":"commodes","word_nosc":"commodes","lemma":"commode","pos":"NOM"} ,
		{"word":"commodité","word_nosc":"commodite","lemma":"commodité","pos":"NOM"} ,
		{"word":"commodités","word_nosc":"commodites","lemma":"commodité","pos":"NOM"} ,
		{"word":"commodore","word_nosc":"commodore","lemma":"commodore","pos":"NOM"} ,
		{"word":"commodores","word_nosc":"commodores","lemma":"commodore","pos":"NOM"} ,
		{"word":"commotion","word_nosc":"commotion","lemma":"commotion","pos":"NOM"} ,
		{"word":"commotions","word_nosc":"commotions","lemma":"commotion","pos":"NOM"} ,
		{"word":"commun","word_nosc":"commun","lemma":"commun","pos":"NOM"} ,
		{"word":"communal","word_nosc":"communal","lemma":"communal","pos":"NOM"} ,
		{"word":"communale","word_nosc":"communale","lemma":"communal","pos":"NOM"} ,
		{"word":"communard","word_nosc":"communard","lemma":"communard","pos":"NOM"} ,
		{"word":"communards","word_nosc":"communards","lemma":"communard","pos":"NOM"} ,
		{"word":"communauté","word_nosc":"communaute","lemma":"communauté","pos":"NOM"} ,
		{"word":"communautés","word_nosc":"communautes","lemma":"communauté","pos":"NOM"} ,
		{"word":"communaux","word_nosc":"communaux","lemma":"communal","pos":"NOM"} ,
		{"word":"commune","word_nosc":"commune","lemma":"commune","pos":"NOM"} ,
		{"word":"communes","word_nosc":"communes","lemma":"commune","pos":"NOM"} ,
		{"word":"communiant","word_nosc":"communiant","lemma":"communiant","pos":"NOM"} ,
		{"word":"communiante","word_nosc":"communiante","lemma":"communiant","pos":"NOM"} ,
		{"word":"communiantes","word_nosc":"communiantes","lemma":"communiant","pos":"NOM"} ,
		{"word":"communiants","word_nosc":"communiants","lemma":"communiant","pos":"NOM"} ,
		{"word":"communicabilité","word_nosc":"communicabilite","lemma":"communicabilité","pos":"NOM"} ,
		{"word":"communicant","word_nosc":"communicant","lemma":"communicant","pos":"NOM"} ,
		{"word":"communicantes","word_nosc":"communicantes","lemma":"communicant","pos":"NOM"} ,
		{"word":"communicateur","word_nosc":"communicateur","lemma":"communicateur","pos":"NOM"} ,
		{"word":"communicateurs","word_nosc":"communicateurs","lemma":"communicateur","pos":"NOM"} ,
		{"word":"communication","word_nosc":"communication","lemma":"communication","pos":"NOM"} ,
		{"word":"communications","word_nosc":"communications","lemma":"communication","pos":"NOM"} ,
		{"word":"communion","word_nosc":"communion","lemma":"communion","pos":"NOM"} ,
		{"word":"communions","word_nosc":"communions","lemma":"communion","pos":"NOM"} ,
		{"word":"communiqué","word_nosc":"communique","lemma":"communiqué","pos":"NOM"} ,
		{"word":"communiqués","word_nosc":"communiques","lemma":"communiqué","pos":"NOM"} ,
		{"word":"communisme","word_nosc":"communisme","lemma":"communisme","pos":"NOM"} ,
		{"word":"communiste","word_nosc":"communiste","lemma":"communiste","pos":"NOM"} ,
		{"word":"communistes","word_nosc":"communistes","lemma":"communiste","pos":"NOM"} ,
		{"word":"communs","word_nosc":"communs","lemma":"commun","pos":"NOM"} ,
		{"word":"commutateur","word_nosc":"commutateur","lemma":"commutateur","pos":"NOM"} ,
		{"word":"commutateurs","word_nosc":"commutateurs","lemma":"commutateur","pos":"NOM"} ,
		{"word":"commutation","word_nosc":"commutation","lemma":"commutation","pos":"NOM"} ,
		{"word":"commère","word_nosc":"commere","lemma":"commère","pos":"NOM"} ,
		{"word":"commères","word_nosc":"commeres","lemma":"commère","pos":"NOM"} ,
		{"word":"commémoration","word_nosc":"commemoration","lemma":"commémoration","pos":"NOM"} ,
		{"word":"commémorations","word_nosc":"commemorations","lemma":"commémoration","pos":"NOM"} ,
		{"word":"commérage","word_nosc":"commerage","lemma":"commérage","pos":"NOM"} ,
		{"word":"commérages","word_nosc":"commerages","lemma":"commérage","pos":"NOM"} ,
		{"word":"compacité","word_nosc":"compacite","lemma":"compacité","pos":"NOM"} ,
		{"word":"compact","word_nosc":"compact","lemma":"compact","pos":"NOM"} ,
		{"word":"compactage","word_nosc":"compactage","lemma":"compactage","pos":"NOM"} ,
		{"word":"compacteur","word_nosc":"compacteur","lemma":"compacteur","pos":"NOM"} ,
		{"word":"compacteurs","word_nosc":"compacteurs","lemma":"compacteur","pos":"NOM"} ,
		{"word":"compacts","word_nosc":"compacts","lemma":"compact","pos":"NOM"} ,
		{"word":"compadre","word_nosc":"compadre","lemma":"compadre","pos":"NOM"} ,
		{"word":"compadres","word_nosc":"compadres","lemma":"compadre","pos":"NOM"} ,
		{"word":"compagne","word_nosc":"compagne","lemma":"compagnon","pos":"NOM"} ,
		{"word":"compagnes","word_nosc":"compagnes","lemma":"compagnon","pos":"NOM"} ,
		{"word":"compagnie","word_nosc":"compagnie","lemma":"compagnie","pos":"NOM"} ,
		{"word":"compagnies","word_nosc":"compagnies","lemma":"compagnie","pos":"NOM"} ,
		{"word":"compagnon","word_nosc":"compagnon","lemma":"compagnon","pos":"NOM"} ,
		{"word":"compagnonnage","word_nosc":"compagnonnage","lemma":"compagnonnage","pos":"NOM"} ,
		{"word":"compagnonnages","word_nosc":"compagnonnages","lemma":"compagnonnage","pos":"NOM"} ,
		{"word":"compagnonnes","word_nosc":"compagnonnes","lemma":"compagnon","pos":"NOM"} ,
		{"word":"compagnons","word_nosc":"compagnons","lemma":"compagnon","pos":"NOM"} ,
		{"word":"comparabilité","word_nosc":"comparabilite","lemma":"comparabilité","pos":"NOM"} ,
		{"word":"comparaison","word_nosc":"comparaison","lemma":"comparaison","pos":"NOM"} ,
		{"word":"comparaisons","word_nosc":"comparaisons","lemma":"comparaison","pos":"NOM"} ,
		{"word":"comparateur","word_nosc":"comparateur","lemma":"comparateur","pos":"NOM"} ,
		{"word":"comparatif","word_nosc":"comparatif","lemma":"comparatif","pos":"NOM"} ,
		{"word":"comparatifs","word_nosc":"comparatifs","lemma":"comparatif","pos":"NOM"} ,
		{"word":"comparse","word_nosc":"comparse","lemma":"comparse","pos":"NOM"} ,
		{"word":"comparses","word_nosc":"comparses","lemma":"comparse","pos":"NOM"} ,
		{"word":"compartiment","word_nosc":"compartiment","lemma":"compartiment","pos":"NOM"} ,
		{"word":"compartimentage","word_nosc":"compartimentage","lemma":"compartimentage","pos":"NOM"} ,
		{"word":"compartiments","word_nosc":"compartiments","lemma":"compartiment","pos":"NOM"} ,
		{"word":"comparution","word_nosc":"comparution","lemma":"comparution","pos":"NOM"} ,
		{"word":"compas","word_nosc":"compas","lemma":"compas","pos":"NOM"} ,
		{"word":"compassion","word_nosc":"compassion","lemma":"compassion","pos":"NOM"} ,
		{"word":"compatibilité","word_nosc":"compatibilite","lemma":"compatibilité","pos":"NOM"} ,
		{"word":"compatible","word_nosc":"compatible","lemma":"compatible","pos":"NOM"} ,
		{"word":"compatibles","word_nosc":"compatibles","lemma":"compatible","pos":"NOM"} ,
		{"word":"compatriote","word_nosc":"compatriote","lemma":"compatriote","pos":"NOM"} ,
		{"word":"compatriotes","word_nosc":"compatriotes","lemma":"compatriote","pos":"NOM"} ,
		{"word":"compendium","word_nosc":"compendium","lemma":"compendium","pos":"NOM"} ,
		{"word":"compendiums","word_nosc":"compendiums","lemma":"compendium","pos":"NOM"} ,
		{"word":"compensation","word_nosc":"compensation","lemma":"compensation","pos":"NOM"} ,
		{"word":"compensations","word_nosc":"compensations","lemma":"compensation","pos":"NOM"} ,
		{"word":"compil","word_nosc":"compil","lemma":"compil","pos":"NOM"} ,
		{"word":"compilateur","word_nosc":"compilateur","lemma":"compilateur","pos":"NOM"} ,
		{"word":"compilation","word_nosc":"compilation","lemma":"compilation","pos":"NOM"} ,
		{"word":"compilations","word_nosc":"compilations","lemma":"compilation","pos":"NOM"} ,
		{"word":"complainte","word_nosc":"complainte","lemma":"complainte","pos":"NOM"} ,
		{"word":"complaintes","word_nosc":"complaintes","lemma":"complainte","pos":"NOM"} ,
		{"word":"complaisance","word_nosc":"complaisance","lemma":"complaisance","pos":"NOM"} ,
		{"word":"complaisances","word_nosc":"complaisances","lemma":"complaisance","pos":"NOM"} ,
		{"word":"complet","word_nosc":"complet","lemma":"complet","pos":"NOM"} ,
		{"word":"complet-veston","word_nosc":"complet-veston","lemma":"complet-veston","pos":"NOM"} ,
		{"word":"complets","word_nosc":"complets","lemma":"complet","pos":"NOM"} ,
		{"word":"complexe","word_nosc":"complexe","lemma":"complexe","pos":"NOM"} ,
		{"word":"complexes","word_nosc":"complexes","lemma":"complexe","pos":"NOM"} ,
		{"word":"complexion","word_nosc":"complexion","lemma":"complexion","pos":"NOM"} ,
		{"word":"complexité","word_nosc":"complexite","lemma":"complexité","pos":"NOM"} ,
		{"word":"complexités","word_nosc":"complexites","lemma":"complexité","pos":"NOM"} ,
		{"word":"complication","word_nosc":"complication","lemma":"complication","pos":"NOM"} ,
		{"word":"complications","word_nosc":"complications","lemma":"complication","pos":"NOM"} ,
		{"word":"complice","word_nosc":"complice","lemma":"complice","pos":"NOM"} ,
		{"word":"complices","word_nosc":"complices","lemma":"complice","pos":"NOM"} ,
		{"word":"complicité","word_nosc":"complicite","lemma":"complicité","pos":"NOM"} ,
		{"word":"complicités","word_nosc":"complicites","lemma":"complicité","pos":"NOM"} ,
		{"word":"complies","word_nosc":"complies","lemma":"complies","pos":"NOM"} ,
		{"word":"compliment","word_nosc":"compliment","lemma":"compliment","pos":"NOM"} ,
		{"word":"compliments","word_nosc":"compliments","lemma":"compliment","pos":"NOM"} ,
		{"word":"complot","word_nosc":"complot","lemma":"complot","pos":"NOM"} ,
		{"word":"comploteur","word_nosc":"comploteur","lemma":"comploteur","pos":"NOM"} ,
		{"word":"comploteurs","word_nosc":"comploteurs","lemma":"comploteur","pos":"NOM"} ,
		{"word":"comploteuse","word_nosc":"comploteuse","lemma":"comploteur","pos":"NOM"} ,
		{"word":"complots","word_nosc":"complots","lemma":"complot","pos":"NOM"} ,
		{"word":"complètement","word_nosc":"completement","lemma":"complètement","pos":"NOM"} ,
		{"word":"complètements","word_nosc":"completements","lemma":"complètement","pos":"NOM"} ,
		{"word":"complément","word_nosc":"complement","lemma":"complément","pos":"NOM"} ,
		{"word":"complémentarité","word_nosc":"complementarite","lemma":"complémentarité","pos":"NOM"} ,
		{"word":"compléments","word_nosc":"complements","lemma":"complément","pos":"NOM"} ,
		{"word":"complétude","word_nosc":"completude","lemma":"complétude","pos":"NOM"} ,
		{"word":"compo","word_nosc":"compo","lemma":"compo","pos":"NOM"} ,
		{"word":"componction","word_nosc":"componction","lemma":"componction","pos":"NOM"} ,
		{"word":"components","word_nosc":"components","lemma":"component","pos":"NOM"} ,
		{"word":"comporte","word_nosc":"comporte","lemma":"comporte","pos":"NOM"} ,
		{"word":"comportement","word_nosc":"comportement","lemma":"comportement","pos":"NOM"} ,
		{"word":"comportementalisme","word_nosc":"comportementalisme","lemma":"comportementalisme","pos":"NOM"} ,
		{"word":"comportements","word_nosc":"comportements","lemma":"comportement","pos":"NOM"} ,
		{"word":"comportes","word_nosc":"comportes","lemma":"comporte","pos":"NOM"} ,
		{"word":"composant","word_nosc":"composant","lemma":"composant","pos":"NOM"} ,
		{"word":"composante","word_nosc":"composante","lemma":"composant","pos":"NOM"} ,
		{"word":"composantes","word_nosc":"composantes","lemma":"composant","pos":"NOM"} ,
		{"word":"composants","word_nosc":"composants","lemma":"composant","pos":"NOM"} ,
		{"word":"composeur","word_nosc":"composeur","lemma":"composeur","pos":"NOM"} ,
		{"word":"composite","word_nosc":"composite","lemma":"composite","pos":"NOM"} ,
		{"word":"composites","word_nosc":"composites","lemma":"composite","pos":"NOM"} ,
		{"word":"compositeur","word_nosc":"compositeur","lemma":"compositeur","pos":"NOM"} ,
		{"word":"compositeurs","word_nosc":"compositeurs","lemma":"compositeur","pos":"NOM"} ,
		{"word":"composition","word_nosc":"composition","lemma":"composition","pos":"NOM"} ,
		{"word":"compositions","word_nosc":"compositions","lemma":"composition","pos":"NOM"} ,
		{"word":"compositrice","word_nosc":"compositrice","lemma":"compositeur","pos":"NOM"} ,
		{"word":"compost","word_nosc":"compost","lemma":"compost","pos":"NOM"} ,
		{"word":"composteur","word_nosc":"composteur","lemma":"composteur","pos":"NOM"} ,
		{"word":"composteurs","word_nosc":"composteurs","lemma":"composteur","pos":"NOM"} ,
		{"word":"composé","word_nosc":"compose","lemma":"composé","pos":"NOM"} ,
		{"word":"composée","word_nosc":"composee","lemma":"composé","pos":"NOM"} ,
		{"word":"composés","word_nosc":"composes","lemma":"composé","pos":"NOM"} ,
		{"word":"compote","word_nosc":"compote","lemma":"compote","pos":"NOM"} ,
		{"word":"compotes","word_nosc":"compotes","lemma":"compote","pos":"NOM"} ,
		{"word":"compotier","word_nosc":"compotier","lemma":"compotier","pos":"NOM"} ,
		{"word":"compotiers","word_nosc":"compotiers","lemma":"compotier","pos":"NOM"} ,
		{"word":"compound","word_nosc":"compound","lemma":"compound","pos":"NOM"} ,
		{"word":"comprador","word_nosc":"comprador","lemma":"comprador","pos":"NOM"} ,
		{"word":"comprenette","word_nosc":"comprenette","lemma":"comprenette","pos":"NOM"} ,
		{"word":"compresse","word_nosc":"compresse","lemma":"compresse","pos":"NOM"} ,
		{"word":"compresses","word_nosc":"compresses","lemma":"compresse","pos":"NOM"} ,
		{"word":"compresseur","word_nosc":"compresseur","lemma":"compresseur","pos":"NOM"} ,
		{"word":"compresseurs","word_nosc":"compresseurs","lemma":"compresseur","pos":"NOM"} ,
		{"word":"compression","word_nosc":"compression","lemma":"compression","pos":"NOM"} ,
		{"word":"compressions","word_nosc":"compressions","lemma":"compression","pos":"NOM"} ,
		{"word":"comprimé","word_nosc":"comprime","lemma":"comprimé","pos":"NOM"} ,
		{"word":"comprimés","word_nosc":"comprimes","lemma":"comprimé","pos":"NOM"} ,
		{"word":"compromis","word_nosc":"compromis","lemma":"compromis","pos":"NOM"} ,
		{"word":"compromission","word_nosc":"compromission","lemma":"compromission","pos":"NOM"} ,
		{"word":"compromissions","word_nosc":"compromissions","lemma":"compromission","pos":"NOM"} ,
		{"word":"compréhension","word_nosc":"comprehension","lemma":"compréhension","pos":"NOM"} ,
		{"word":"compréhensions","word_nosc":"comprehensions","lemma":"compréhension","pos":"NOM"} ,
		{"word":"comptabilisation","word_nosc":"comptabilisation","lemma":"comptabilisation","pos":"NOM"} ,
		{"word":"comptabilité","word_nosc":"comptabilite","lemma":"comptabilité","pos":"NOM"} ,
		{"word":"comptabilités","word_nosc":"comptabilites","lemma":"comptabilité","pos":"NOM"} ,
		{"word":"comptable","word_nosc":"comptable","lemma":"comptable","pos":"NOM"} ,
		{"word":"comptables","word_nosc":"comptables","lemma":"comptable","pos":"NOM"} ,
		{"word":"comptage","word_nosc":"comptage","lemma":"comptage","pos":"NOM"} ,
		{"word":"comptant","word_nosc":"comptant","lemma":"comptant","pos":"NOM"} ,
		{"word":"compte","word_nosc":"compte","lemma":"compte","pos":"NOM"} ,
		{"word":"compte-fils","word_nosc":"compte-fils","lemma":"compte-fils","pos":"NOM"} ,
		{"word":"compte-gouttes","word_nosc":"compte-gouttes","lemma":"compte-gouttes","pos":"NOM"} ,
		{"word":"compte-rendu","word_nosc":"compte-rendu","lemma":"compte-rendu","pos":"NOM"} ,
		{"word":"compte-tours","word_nosc":"compte-tours","lemma":"compte-tours","pos":"NOM"} ,
		{"word":"comptes","word_nosc":"comptes","lemma":"compte","pos":"NOM"} ,
		{"word":"comptes-rendus","word_nosc":"comptes-rendus","lemma":"compte-rendu","pos":"NOM"} ,
		{"word":"compteur","word_nosc":"compteur","lemma":"compteur","pos":"NOM"} ,
		{"word":"compteurs","word_nosc":"compteurs","lemma":"compteur","pos":"NOM"} ,
		{"word":"comptine","word_nosc":"comptine","lemma":"comptine","pos":"NOM"} ,
		{"word":"comptines","word_nosc":"comptines","lemma":"comptine","pos":"NOM"} ,
		{"word":"comptoir","word_nosc":"comptoir","lemma":"comptoir","pos":"NOM"} ,
		{"word":"comptoirs","word_nosc":"comptoirs","lemma":"comptoir","pos":"NOM"} ,
		{"word":"compulsion","word_nosc":"compulsion","lemma":"compulsion","pos":"NOM"} ,
		{"word":"compulsoire","word_nosc":"compulsoire","lemma":"compulsoire","pos":"NOM"} ,
		{"word":"comput","word_nosc":"comput","lemma":"comput","pos":"NOM"} ,
		{"word":"computations","word_nosc":"computations","lemma":"computation","pos":"NOM"} ,
		{"word":"computer","word_nosc":"computer","lemma":"computer","pos":"NOM"} ,
		{"word":"computers","word_nosc":"computers","lemma":"computer","pos":"NOM"} ,
		{"word":"computeur","word_nosc":"computeur","lemma":"computeur","pos":"NOM"} ,
		{"word":"computeurs","word_nosc":"computeurs","lemma":"computeur","pos":"NOM"} ,
		{"word":"compère","word_nosc":"compere","lemma":"compère","pos":"NOM"} ,
		{"word":"compères","word_nosc":"comperes","lemma":"compère","pos":"NOM"} ,
		{"word":"compétence","word_nosc":"competence","lemma":"compétence","pos":"NOM"} ,
		{"word":"compétences","word_nosc":"competences","lemma":"compétence","pos":"NOM"} ,
		{"word":"compétiteur","word_nosc":"competiteur","lemma":"compétiteur","pos":"NOM"} ,
		{"word":"compétiteurs","word_nosc":"competiteurs","lemma":"compétiteur","pos":"NOM"} ,
		{"word":"compétition","word_nosc":"competition","lemma":"compétition","pos":"NOM"} ,
		{"word":"compétitions","word_nosc":"competitions","lemma":"compétition","pos":"NOM"} ,
		{"word":"compétitivité","word_nosc":"competitivite","lemma":"compétitivité","pos":"NOM"} ,
		{"word":"compétitrice","word_nosc":"competitrice","lemma":"compétiteur","pos":"NOM"} ,
		{"word":"comte","word_nosc":"comte","lemma":"comte","pos":"NOM"} ,
		{"word":"comtes","word_nosc":"comtes","lemma":"comte","pos":"NOM"} ,
		{"word":"comtesse","word_nosc":"comtesse","lemma":"comte","pos":"NOM"} ,
		{"word":"comtesses","word_nosc":"comtesses","lemma":"comte","pos":"NOM"} ,
		{"word":"comtois","word_nosc":"comtois","lemma":"comtois","pos":"NOM"} ,
		{"word":"comtoise","word_nosc":"comtoise","lemma":"comtois","pos":"NOM"} ,
		{"word":"comté","word_nosc":"comte","lemma":"comté","pos":"NOM"} ,
		{"word":"comtés","word_nosc":"comtes","lemma":"comté","pos":"NOM"} ,
		{"word":"comète","word_nosc":"comete","lemma":"comète","pos":"NOM"} ,
		{"word":"comètes","word_nosc":"cometes","lemma":"comète","pos":"NOM"} ,
		{"word":"comédie","word_nosc":"comedie","lemma":"comédie","pos":"NOM"} ,
		{"word":"comédien","word_nosc":"comedien","lemma":"comédien","pos":"NOM"} ,
		{"word":"comédienne","word_nosc":"comedienne","lemma":"comédien","pos":"NOM"} ,
		{"word":"comédiennes","word_nosc":"comediennes","lemma":"comédien","pos":"NOM"} ,
		{"word":"comédiens","word_nosc":"comediens","lemma":"comédien","pos":"NOM"} ,
		{"word":"comédies","word_nosc":"comedies","lemma":"comédie","pos":"NOM"} ,
		{"word":"comédons","word_nosc":"comedons","lemma":"comédon","pos":"NOM"} ,
		{"word":"con","word_nosc":"con","lemma":"con","pos":"NOM"} ,
		{"word":"conard","word_nosc":"conard","lemma":"conard","pos":"NOM"} ,
		{"word":"conarde","word_nosc":"conarde","lemma":"conard","pos":"NOM"} ,
		{"word":"conards","word_nosc":"conards","lemma":"conard","pos":"NOM"} ,
		{"word":"conasse","word_nosc":"conasse","lemma":"conasse","pos":"NOM"} ,
		{"word":"conasses","word_nosc":"conasses","lemma":"conasse","pos":"NOM"} ,
		{"word":"concassage","word_nosc":"concassage","lemma":"concassage","pos":"NOM"} ,
		{"word":"concasseur","word_nosc":"concasseur","lemma":"concasseur","pos":"NOM"} ,
		{"word":"concasseurs","word_nosc":"concasseurs","lemma":"concasseur","pos":"NOM"} ,
		{"word":"concaténation","word_nosc":"concatenation","lemma":"concaténation","pos":"NOM"} ,
		{"word":"concavité","word_nosc":"concavite","lemma":"concavité","pos":"NOM"} ,
		{"word":"concentrateur","word_nosc":"concentrateur","lemma":"concentrateur","pos":"NOM"} ,
		{"word":"concentration","word_nosc":"concentration","lemma":"concentration","pos":"NOM"} ,
		{"word":"concentrations","word_nosc":"concentrations","lemma":"concentration","pos":"NOM"} ,
		{"word":"concentré","word_nosc":"concentre","lemma":"concentré","pos":"NOM"} ,
		{"word":"concentrés","word_nosc":"concentres","lemma":"concentré","pos":"NOM"} ,
		{"word":"concept","word_nosc":"concept","lemma":"concept","pos":"NOM"} ,
		{"word":"concepteur","word_nosc":"concepteur","lemma":"concepteur","pos":"NOM"} ,
		{"word":"concepteurs","word_nosc":"concepteurs","lemma":"concepteur","pos":"NOM"} ,
		{"word":"conception","word_nosc":"conception","lemma":"conception","pos":"NOM"} ,
		{"word":"conceptions","word_nosc":"conceptions","lemma":"conception","pos":"NOM"} ,
		{"word":"conceptrice","word_nosc":"conceptrice","lemma":"concepteur","pos":"NOM"} ,
		{"word":"concepts","word_nosc":"concepts","lemma":"concept","pos":"NOM"} ,
		{"word":"concert","word_nosc":"concert","lemma":"concert","pos":"NOM"} ,
		{"word":"concertation","word_nosc":"concertation","lemma":"concertation","pos":"NOM"} ,
		{"word":"concertations","word_nosc":"concertations","lemma":"concertation","pos":"NOM"} ,
		{"word":"concertina","word_nosc":"concertina","lemma":"concertina","pos":"NOM"} ,
		{"word":"concertiste","word_nosc":"concertiste","lemma":"concertiste","pos":"NOM"} ,
		{"word":"concerto","word_nosc":"concerto","lemma":"concerto","pos":"NOM"} ,
		{"word":"concertos","word_nosc":"concertos","lemma":"concerto","pos":"NOM"} ,
		{"word":"concerts","word_nosc":"concerts","lemma":"concert","pos":"NOM"} ,
		{"word":"concession","word_nosc":"concession","lemma":"concession","pos":"NOM"} ,
		{"word":"concessionnaire","word_nosc":"concessionnaire","lemma":"concessionnaire","pos":"NOM"} ,
		{"word":"concessionnaires","word_nosc":"concessionnaires","lemma":"concessionnaire","pos":"NOM"} ,
		{"word":"concessions","word_nosc":"concessions","lemma":"concession","pos":"NOM"} ,
		{"word":"concetto","word_nosc":"concetto","lemma":"concetto","pos":"NOM"} ,
		{"word":"concierge","word_nosc":"concierge","lemma":"concierge","pos":"NOM"} ,
		{"word":"conciergerie","word_nosc":"conciergerie","lemma":"conciergerie","pos":"NOM"} ,
		{"word":"conciergeries","word_nosc":"conciergeries","lemma":"conciergerie","pos":"NOM"} ,
		{"word":"concierges","word_nosc":"concierges","lemma":"concierge","pos":"NOM"} ,
		{"word":"concile","word_nosc":"concile","lemma":"concile","pos":"NOM"} ,
		{"word":"conciles","word_nosc":"conciles","lemma":"concile","pos":"NOM"} ,
		{"word":"conciliabule","word_nosc":"conciliabule","lemma":"conciliabule","pos":"NOM"} ,
		{"word":"conciliabules","word_nosc":"conciliabules","lemma":"conciliabule","pos":"NOM"} ,
		{"word":"conciliateur","word_nosc":"conciliateur","lemma":"conciliateur","pos":"NOM"} ,
		{"word":"conciliateurs","word_nosc":"conciliateurs","lemma":"conciliateur","pos":"NOM"} ,
		{"word":"conciliation","word_nosc":"conciliation","lemma":"conciliation","pos":"NOM"} ,
		{"word":"conciliations","word_nosc":"conciliations","lemma":"conciliation","pos":"NOM"} ,
		{"word":"conciliatrice","word_nosc":"conciliatrice","lemma":"conciliateur","pos":"NOM"} ,
		{"word":"concision","word_nosc":"concision","lemma":"concision","pos":"NOM"} ,
		{"word":"concitoyen","word_nosc":"concitoyen","lemma":"concitoyen","pos":"NOM"} ,
		{"word":"concitoyenne","word_nosc":"concitoyenne","lemma":"concitoyen","pos":"NOM"} ,
		{"word":"concitoyennes","word_nosc":"concitoyennes","lemma":"concitoyen","pos":"NOM"} ,
		{"word":"concitoyens","word_nosc":"concitoyens","lemma":"concitoyen","pos":"NOM"} ,
		{"word":"conclave","word_nosc":"conclave","lemma":"conclave","pos":"NOM"} ,
		{"word":"conclaviste","word_nosc":"conclaviste","lemma":"conclaviste","pos":"NOM"} ,
		{"word":"conclusion","word_nosc":"conclusion","lemma":"conclusion","pos":"NOM"} ,
		{"word":"conclusions","word_nosc":"conclusions","lemma":"conclusion","pos":"NOM"} ,
		{"word":"concoction","word_nosc":"concoction","lemma":"concoction","pos":"NOM"} ,
		{"word":"concombre","word_nosc":"concombre","lemma":"concombre","pos":"NOM"} ,
		{"word":"concombres","word_nosc":"concombres","lemma":"concombre","pos":"NOM"} ,
		{"word":"concomitance","word_nosc":"concomitance","lemma":"concomitance","pos":"NOM"} ,
		{"word":"concordance","word_nosc":"concordance","lemma":"concordance","pos":"NOM"} ,
		{"word":"concordat","word_nosc":"concordat","lemma":"concordat","pos":"NOM"} ,
		{"word":"concorde","word_nosc":"concorde","lemma":"concorde","pos":"NOM"} ,
		{"word":"concordistes","word_nosc":"concordistes","lemma":"concordiste","pos":"NOM"} ,
		{"word":"concours","word_nosc":"concours","lemma":"concours","pos":"NOM"} ,
		{"word":"concret","word_nosc":"concret","lemma":"concret","pos":"NOM"} ,
		{"word":"concrets","word_nosc":"concrets","lemma":"concret","pos":"NOM"} ,
		{"word":"concrétion","word_nosc":"concretion","lemma":"concrétion","pos":"NOM"} ,
		{"word":"concrétions","word_nosc":"concretions","lemma":"concrétion","pos":"NOM"} ,
		{"word":"concrétisation","word_nosc":"concretisation","lemma":"concrétisation","pos":"NOM"} ,
		{"word":"concubin","word_nosc":"concubin","lemma":"concubin","pos":"NOM"} ,
		{"word":"concubinage","word_nosc":"concubinage","lemma":"concubinage","pos":"NOM"} ,
		{"word":"concubinages","word_nosc":"concubinages","lemma":"concubinage","pos":"NOM"} ,
		{"word":"concubine","word_nosc":"concubine","lemma":"concubin","pos":"NOM"} ,
		{"word":"concubines","word_nosc":"concubines","lemma":"concubin","pos":"NOM"} ,
		{"word":"concubins","word_nosc":"concubins","lemma":"concubin","pos":"NOM"} ,
		{"word":"concupiscence","word_nosc":"concupiscence","lemma":"concupiscence","pos":"NOM"} ,
		{"word":"concupiscences","word_nosc":"concupiscences","lemma":"concupiscence","pos":"NOM"} ,
		{"word":"concurrence","word_nosc":"concurrence","lemma":"concurrence","pos":"NOM"} ,
		{"word":"concurrences","word_nosc":"concurrences","lemma":"concurrence","pos":"NOM"} ,
		{"word":"concurrent","word_nosc":"concurrent","lemma":"concurrent","pos":"NOM"} ,
		{"word":"concurrents","word_nosc":"concurrents","lemma":"concurrent","pos":"NOM"} ,
		{"word":"concussion","word_nosc":"concussion","lemma":"concussion","pos":"NOM"} ,
		{"word":"concédant","word_nosc":"concedant","lemma":"concédant","pos":"NOM"} ,
		{"word":"condamnation","word_nosc":"condamnation","lemma":"condamnation","pos":"NOM"} ,
		{"word":"condamnations","word_nosc":"condamnations","lemma":"condamnation","pos":"NOM"} ,
		{"word":"condamné","word_nosc":"condamne","lemma":"condamné","pos":"NOM"} ,
		{"word":"condamnée","word_nosc":"condamnee","lemma":"condamné","pos":"NOM"} ,
		{"word":"condamnées","word_nosc":"condamnees","lemma":"condamné","pos":"NOM"} ,
		{"word":"condamnés","word_nosc":"condamnes","lemma":"condamné","pos":"NOM"} ,
		{"word":"condensateur","word_nosc":"condensateur","lemma":"condensateur","pos":"NOM"} ,
		{"word":"condensateurs","word_nosc":"condensateurs","lemma":"condensateur","pos":"NOM"} ,
		{"word":"condensation","word_nosc":"condensation","lemma":"condensation","pos":"NOM"} ,
		{"word":"condenseur","word_nosc":"condenseur","lemma":"condenseur","pos":"NOM"} ,
		{"word":"condensé","word_nosc":"condense","lemma":"condensé","pos":"NOM"} ,
		{"word":"condescendance","word_nosc":"condescendance","lemma":"condescendance","pos":"NOM"} ,
		{"word":"condiment","word_nosc":"condiment","lemma":"condiment","pos":"NOM"} ,
		{"word":"condiments","word_nosc":"condiments","lemma":"condiment","pos":"NOM"} ,
		{"word":"condisciple","word_nosc":"condisciple","lemma":"condisciple","pos":"NOM"} ,
		{"word":"condisciples","word_nosc":"condisciples","lemma":"condisciple","pos":"NOM"} ,
		{"word":"condition","word_nosc":"condition","lemma":"condition","pos":"NOM"} ,
		{"word":"conditionnel","word_nosc":"conditionnel","lemma":"conditionnel","pos":"NOM"} ,
		{"word":"conditionnels","word_nosc":"conditionnels","lemma":"conditionnel","pos":"NOM"} ,
		{"word":"conditionnement","word_nosc":"conditionnement","lemma":"conditionnement","pos":"NOM"} ,
		{"word":"conditionnements","word_nosc":"conditionnements","lemma":"conditionnement","pos":"NOM"} ,
		{"word":"conditionneur","word_nosc":"conditionneur","lemma":"conditionneur","pos":"NOM"} ,
		{"word":"conditionneurs","word_nosc":"conditionneurs","lemma":"conditionneur","pos":"NOM"} ,
		{"word":"conditions","word_nosc":"conditions","lemma":"condition","pos":"NOM"} ,
		{"word":"condo","word_nosc":"condo","lemma":"condo","pos":"NOM"} ,
		{"word":"condoléance","word_nosc":"condoleance","lemma":"condoléance","pos":"NOM"} ,
		{"word":"condoléances","word_nosc":"condoleances","lemma":"condoléance","pos":"NOM"} ,
		{"word":"condom","word_nosc":"condom","lemma":"condom","pos":"NOM"} ,
		{"word":"condominium","word_nosc":"condominium","lemma":"condominium","pos":"NOM"} ,
		{"word":"condor","word_nosc":"condor","lemma":"condor","pos":"NOM"} ,
		{"word":"condors","word_nosc":"condors","lemma":"condor","pos":"NOM"} ,
		{"word":"condos","word_nosc":"condos","lemma":"condo","pos":"NOM"} ,
		{"word":"condottiere","word_nosc":"condottiere","lemma":"condottiere","pos":"NOM"} ,
		{"word":"condottieres","word_nosc":"condottieres","lemma":"condottiere","pos":"NOM"} ,
		{"word":"condottieri","word_nosc":"condottieri","lemma":"condottieri","pos":"NOM"} ,
		{"word":"conductance","word_nosc":"conductance","lemma":"conductance","pos":"NOM"} ,
		{"word":"conducteur","word_nosc":"conducteur","lemma":"conducteur","pos":"NOM"} ,
		{"word":"conducteurs","word_nosc":"conducteurs","lemma":"conducteur","pos":"NOM"} ,
		{"word":"conductibilité","word_nosc":"conductibilite","lemma":"conductibilité","pos":"NOM"} ,
		{"word":"conduction","word_nosc":"conduction","lemma":"conduction","pos":"NOM"} ,
		{"word":"conductivité","word_nosc":"conductivite","lemma":"conductivité","pos":"NOM"} ,
		{"word":"conductrice","word_nosc":"conductrice","lemma":"conducteur","pos":"NOM"} ,
		{"word":"conductrices","word_nosc":"conductrices","lemma":"conducteur","pos":"NOM"} ,
		{"word":"conduit","word_nosc":"conduit","lemma":"conduit","pos":"NOM"} ,
		{"word":"conduite","word_nosc":"conduite","lemma":"conduite","pos":"NOM"} ,
		{"word":"conduite-intérieure","word_nosc":"conduite-interieure","lemma":"conduite-intérieure","pos":"NOM"} ,
		{"word":"conduites","word_nosc":"conduites","lemma":"conduite","pos":"NOM"} ,
		{"word":"conduits","word_nosc":"conduits","lemma":"conduit","pos":"NOM"} ,
		{"word":"condyle","word_nosc":"condyle","lemma":"condyle","pos":"NOM"} ,
		{"word":"condyles","word_nosc":"condyles","lemma":"condyle","pos":"NOM"} ,
		{"word":"condé","word_nosc":"conde","lemma":"condé","pos":"NOM"} ,
		{"word":"condés","word_nosc":"condes","lemma":"condé","pos":"NOM"} ,
		{"word":"confection","word_nosc":"confection","lemma":"confection","pos":"NOM"} ,
		{"word":"confectionneur","word_nosc":"confectionneur","lemma":"confectionneur","pos":"NOM"} ,
		{"word":"confectionneurs","word_nosc":"confectionneurs","lemma":"confectionneur","pos":"NOM"} ,
		{"word":"confectionneuse","word_nosc":"confectionneuse","lemma":"confectionneur","pos":"NOM"} ,
		{"word":"confectionneuses","word_nosc":"confectionneuses","lemma":"confectionneur","pos":"NOM"} ,
		{"word":"confesse","word_nosc":"confesse","lemma":"confesse","pos":"NOM"} ,
		{"word":"confesseur","word_nosc":"confesseur","lemma":"confesseur","pos":"NOM"} ,
		{"word":"confesseurs","word_nosc":"confesseurs","lemma":"confesseur","pos":"NOM"} ,
		{"word":"confession","word_nosc":"confession","lemma":"confession","pos":"NOM"} ,
		{"word":"confessionnal","word_nosc":"confessionnal","lemma":"confessionnal","pos":"NOM"} ,
		{"word":"confessionnaux","word_nosc":"confessionnaux","lemma":"confessionnal","pos":"NOM"} ,
		{"word":"confessions","word_nosc":"confessions","lemma":"confession","pos":"NOM"} ,
		{"word":"confetti","word_nosc":"confetti","lemma":"confetti","pos":"NOM"} ,
		{"word":"confettis","word_nosc":"confettis","lemma":"confetti","pos":"NOM"} ,
		{"word":"confiance","word_nosc":"confiance","lemma":"confiance","pos":"NOM"} ,
		{"word":"confiances","word_nosc":"confiances","lemma":"confiance","pos":"NOM"} ,
		{"word":"confidence","word_nosc":"confidence","lemma":"confidence","pos":"NOM"} ,
		{"word":"confidences","word_nosc":"confidences","lemma":"confidence","pos":"NOM"} ,
		{"word":"confident","word_nosc":"confident","lemma":"confident","pos":"NOM"} ,
		{"word":"confidente","word_nosc":"confidente","lemma":"confident","pos":"NOM"} ,
		{"word":"confidentes","word_nosc":"confidentes","lemma":"confident","pos":"NOM"} ,
		{"word":"confidentialité","word_nosc":"confidentialite","lemma":"confidentialité","pos":"NOM"} ,
		{"word":"confidents","word_nosc":"confidents","lemma":"confident","pos":"NOM"} ,
		{"word":"configuration","word_nosc":"configuration","lemma":"configuration","pos":"NOM"} ,
		{"word":"configurations","word_nosc":"configurations","lemma":"configuration","pos":"NOM"} ,
		{"word":"confinement","word_nosc":"confinement","lemma":"confinement","pos":"NOM"} ,
		{"word":"confins","word_nosc":"confins","lemma":"confins","pos":"NOM"} ,
		{"word":"confiote","word_nosc":"confiote","lemma":"confiote","pos":"NOM"} ,
		{"word":"confiotes","word_nosc":"confiotes","lemma":"confiote","pos":"NOM"} ,
		{"word":"confirmation","word_nosc":"confirmation","lemma":"confirmation","pos":"NOM"} ,
		{"word":"confirmations","word_nosc":"confirmations","lemma":"confirmation","pos":"NOM"} ,
		{"word":"confiscation","word_nosc":"confiscation","lemma":"confiscation","pos":"NOM"} ,
		{"word":"confiscations","word_nosc":"confiscations","lemma":"confiscation","pos":"NOM"} ,
		{"word":"confiserie","word_nosc":"confiserie","lemma":"confiserie","pos":"NOM"} ,
		{"word":"confiseries","word_nosc":"confiseries","lemma":"confiserie","pos":"NOM"} ,
		{"word":"confiseur","word_nosc":"confiseur","lemma":"confiseur","pos":"NOM"} ,
		{"word":"confiseurs","word_nosc":"confiseurs","lemma":"confiseur","pos":"NOM"} ,
		{"word":"confiseuse","word_nosc":"confiseuse","lemma":"confiseur","pos":"NOM"} ,
		{"word":"confit","word_nosc":"confit","lemma":"confit","pos":"NOM"} ,
		{"word":"confiteor","word_nosc":"confiteor","lemma":"confiteor","pos":"NOM"} ,
		{"word":"confits","word_nosc":"confits","lemma":"confit","pos":"NOM"} ,
		{"word":"confiture","word_nosc":"confiture","lemma":"confiture","pos":"NOM"} ,
		{"word":"confitures","word_nosc":"confitures","lemma":"confiture","pos":"NOM"} ,
		{"word":"confituriers","word_nosc":"confituriers","lemma":"confiturier","pos":"NOM"} ,
		{"word":"confiturière","word_nosc":"confituriere","lemma":"confiturier","pos":"NOM"} ,
		{"word":"conflagration","word_nosc":"conflagration","lemma":"conflagration","pos":"NOM"} ,
		{"word":"conflit","word_nosc":"conflit","lemma":"conflit","pos":"NOM"} ,
		{"word":"conflits","word_nosc":"conflits","lemma":"conflit","pos":"NOM"} ,
		{"word":"confluence","word_nosc":"confluence","lemma":"confluence","pos":"NOM"} ,
		{"word":"confluent","word_nosc":"confluent","lemma":"confluent","pos":"NOM"} ,
		{"word":"confluents","word_nosc":"confluents","lemma":"confluent","pos":"NOM"} ,
		{"word":"conformation","word_nosc":"conformation","lemma":"conformation","pos":"NOM"} ,
		{"word":"conformisme","word_nosc":"conformisme","lemma":"conformisme","pos":"NOM"} ,
		{"word":"conformismes","word_nosc":"conformismes","lemma":"conformisme","pos":"NOM"} ,
		{"word":"conformiste","word_nosc":"conformiste","lemma":"conformiste","pos":"NOM"} ,
		{"word":"conformistes","word_nosc":"conformistes","lemma":"conformiste","pos":"NOM"} ,
		{"word":"conformité","word_nosc":"conformite","lemma":"conformité","pos":"NOM"} ,
		{"word":"confort","word_nosc":"confort","lemma":"confort","pos":"NOM"} ,
		{"word":"conforts","word_nosc":"conforts","lemma":"confort","pos":"NOM"} ,
		{"word":"confraternité","word_nosc":"confraternite","lemma":"confraternité","pos":"NOM"} ,
		{"word":"confrontation","word_nosc":"confrontation","lemma":"confrontation","pos":"NOM"} ,
		{"word":"confrontations","word_nosc":"confrontations","lemma":"confrontation","pos":"NOM"} ,
		{"word":"confrère","word_nosc":"confrere","lemma":"confrère","pos":"NOM"} ,
		{"word":"confrères","word_nosc":"confreres","lemma":"confrère","pos":"NOM"} ,
		{"word":"confrérie","word_nosc":"confrerie","lemma":"confrérie","pos":"NOM"} ,
		{"word":"confréries","word_nosc":"confreries","lemma":"confrérie","pos":"NOM"} ,
		{"word":"confucianisme","word_nosc":"confucianisme","lemma":"confucianisme","pos":"NOM"} ,
		{"word":"confusion","word_nosc":"confusion","lemma":"confusion","pos":"NOM"} ,
		{"word":"confusionnisme","word_nosc":"confusionnisme","lemma":"confusionnisme","pos":"NOM"} ,
		{"word":"confusions","word_nosc":"confusions","lemma":"confusion","pos":"NOM"} ,
		{"word":"confédération","word_nosc":"confederation","lemma":"confédération","pos":"NOM"} ,
		{"word":"confédéré","word_nosc":"confedere","lemma":"confédéré","pos":"NOM"} ,
		{"word":"confédérés","word_nosc":"confederes","lemma":"confédéré","pos":"NOM"} ,
		{"word":"conférence","word_nosc":"conference","lemma":"conférence","pos":"NOM"} ,
		{"word":"conférences","word_nosc":"conferences","lemma":"conférence","pos":"NOM"} ,
		{"word":"conférences-débats","word_nosc":"conferences-debats","lemma":"conférence-débat","pos":"NOM"} ,
		{"word":"conférencier","word_nosc":"conferencier","lemma":"conférencier","pos":"NOM"} ,
		{"word":"conférenciers","word_nosc":"conferenciers","lemma":"conférencier","pos":"NOM"} ,
		{"word":"conférencière","word_nosc":"conferenciere","lemma":"conférencier","pos":"NOM"} ,
		{"word":"conférents","word_nosc":"conferents","lemma":"conférent","pos":"NOM"} ,
		{"word":"conga","word_nosc":"conga","lemma":"conga","pos":"NOM"} ,
		{"word":"congas","word_nosc":"congas","lemma":"conga","pos":"NOM"} ,
		{"word":"congayes","word_nosc":"congayes","lemma":"congaye","pos":"NOM"} ,
		{"word":"congaïs","word_nosc":"congais","lemma":"congaï","pos":"NOM"} ,
		{"word":"congestion","word_nosc":"congestion","lemma":"congestion","pos":"NOM"} ,
		{"word":"congestions","word_nosc":"congestions","lemma":"congestion","pos":"NOM"} ,
		{"word":"conglobation","word_nosc":"conglobation","lemma":"conglobation","pos":"NOM"} ,
		{"word":"conglomérat","word_nosc":"conglomerat","lemma":"conglomérat","pos":"NOM"} ,
		{"word":"conglomérats","word_nosc":"conglomerats","lemma":"conglomérat","pos":"NOM"} ,
		{"word":"congolais","word_nosc":"congolais","lemma":"congolais","pos":"NOM"} ,
		{"word":"congratulations","word_nosc":"congratulations","lemma":"congratulation","pos":"NOM"} ,
		{"word":"congre","word_nosc":"congre","lemma":"congre","pos":"NOM"} ,
		{"word":"congres","word_nosc":"congres","lemma":"congre","pos":"NOM"} ,
		{"word":"congressistes","word_nosc":"congressistes","lemma":"congressiste","pos":"NOM"} ,
		{"word":"congrès","word_nosc":"congres","lemma":"congrès","pos":"NOM"} ,
		{"word":"congréganiste","word_nosc":"congreganiste","lemma":"congréganiste","pos":"NOM"} ,
		{"word":"congrégation","word_nosc":"congregation","lemma":"congrégation","pos":"NOM"} ,
		{"word":"congrégations","word_nosc":"congregations","lemma":"congrégation","pos":"NOM"} ,
		{"word":"congère","word_nosc":"congere","lemma":"congère","pos":"NOM"} ,
		{"word":"congères","word_nosc":"congeres","lemma":"congère","pos":"NOM"} ,
		{"word":"congé","word_nosc":"conge","lemma":"congé","pos":"NOM"} ,
		{"word":"congédiement","word_nosc":"congediement","lemma":"congédiement","pos":"NOM"} ,
		{"word":"congélateur","word_nosc":"congelateur","lemma":"congélateur","pos":"NOM"} ,
		{"word":"congélateurs","word_nosc":"congelateurs","lemma":"congélateur","pos":"NOM"} ,
		{"word":"congélation","word_nosc":"congelation","lemma":"congélation","pos":"NOM"} ,
		{"word":"congénère","word_nosc":"congenere","lemma":"congénère","pos":"NOM"} ,
		{"word":"congénères","word_nosc":"congeneres","lemma":"congénère","pos":"NOM"} ,
		{"word":"congés","word_nosc":"conges","lemma":"congé","pos":"NOM"} ,
		{"word":"conifère","word_nosc":"conifere","lemma":"conifère","pos":"NOM"} ,
		{"word":"conifères","word_nosc":"coniferes","lemma":"conifère","pos":"NOM"} ,
		{"word":"conjecture","word_nosc":"conjecture","lemma":"conjecture","pos":"NOM"} ,
		{"word":"conjectures","word_nosc":"conjectures","lemma":"conjecture","pos":"NOM"} ,
		{"word":"conjoint","word_nosc":"conjoint","lemma":"conjoint","pos":"NOM"} ,
		{"word":"conjointe","word_nosc":"conjointe","lemma":"conjoint","pos":"NOM"} ,
		{"word":"conjoints","word_nosc":"conjoints","lemma":"conjoint","pos":"NOM"} ,
		{"word":"conjoncteur","word_nosc":"conjoncteur","lemma":"conjoncteur","pos":"NOM"} ,
		{"word":"conjonctif","word_nosc":"conjonctif","lemma":"conjonctif","pos":"NOM"} ,
		{"word":"conjonction","word_nosc":"conjonction","lemma":"conjonction","pos":"NOM"} ,
		{"word":"conjonctions","word_nosc":"conjonctions","lemma":"conjonction","pos":"NOM"} ,
		{"word":"conjonctive","word_nosc":"conjonctive","lemma":"conjonctive","pos":"NOM"} ,
		{"word":"conjonctives","word_nosc":"conjonctives","lemma":"conjonctive","pos":"NOM"} ,
		{"word":"conjonctivite","word_nosc":"conjonctivite","lemma":"conjonctivite","pos":"NOM"} ,
		{"word":"conjonctivites","word_nosc":"conjonctivites","lemma":"conjonctivite","pos":"NOM"} ,
		{"word":"conjoncture","word_nosc":"conjoncture","lemma":"conjoncture","pos":"NOM"} ,
		{"word":"conjonctures","word_nosc":"conjonctures","lemma":"conjoncture","pos":"NOM"} ,
		{"word":"conjugaison","word_nosc":"conjugaison","lemma":"conjugaison","pos":"NOM"} ,
		{"word":"conjugaisons","word_nosc":"conjugaisons","lemma":"conjugaison","pos":"NOM"} ,
		{"word":"conjugalité","word_nosc":"conjugalite","lemma":"conjugalité","pos":"NOM"} ,
		{"word":"conjungo","word_nosc":"conjungo","lemma":"conjungo","pos":"NOM"} ,
		{"word":"conjungos","word_nosc":"conjungos","lemma":"conjungo","pos":"NOM"} ,
		{"word":"conjurateur","word_nosc":"conjurateur","lemma":"conjurateur","pos":"NOM"} ,
		{"word":"conjuration","word_nosc":"conjuration","lemma":"conjuration","pos":"NOM"} ,
		{"word":"conjurations","word_nosc":"conjurations","lemma":"conjuration","pos":"NOM"} ,
		{"word":"conjuré","word_nosc":"conjure","lemma":"conjuré","pos":"NOM"} ,
		{"word":"conjurée","word_nosc":"conjuree","lemma":"conjuré","pos":"NOM"} ,
		{"word":"conjurés","word_nosc":"conjures","lemma":"conjuré","pos":"NOM"} ,
		{"word":"connaissance","word_nosc":"connaissance","lemma":"connaissance","pos":"NOM"} ,
		{"word":"connaissances","word_nosc":"connaissances","lemma":"connaissance","pos":"NOM"} ,
		{"word":"connaissement","word_nosc":"connaissement","lemma":"connaissement","pos":"NOM"} ,
		{"word":"connaissements","word_nosc":"connaissements","lemma":"connaissement","pos":"NOM"} ,
		{"word":"connaisseur","word_nosc":"connaisseur","lemma":"connaisseur","pos":"NOM"} ,
		{"word":"connaisseurs","word_nosc":"connaisseurs","lemma":"connaisseur","pos":"NOM"} ,
		{"word":"connaisseuse","word_nosc":"connaisseuse","lemma":"connaisseur","pos":"NOM"} ,
		{"word":"connaisseuses","word_nosc":"connaisseuses","lemma":"connaisseur","pos":"NOM"} ,
		{"word":"connard","word_nosc":"connard","lemma":"connard","pos":"NOM"} ,
		{"word":"connards","word_nosc":"connards","lemma":"connard","pos":"NOM"} ,
		{"word":"connasse","word_nosc":"connasse","lemma":"connasse","pos":"NOM"} ,
		{"word":"connasses","word_nosc":"connasses","lemma":"connasse","pos":"NOM"} ,
		{"word":"conne","word_nosc":"conne","lemma":"con","pos":"NOM"} ,
		{"word":"conneau","word_nosc":"conneau","lemma":"conneau","pos":"NOM"} ,
		{"word":"conneaux","word_nosc":"conneaux","lemma":"conneau","pos":"NOM"} ,
		{"word":"connecteur","word_nosc":"connecteur","lemma":"connecteur","pos":"NOM"} ,
		{"word":"connectivité","word_nosc":"connectivite","lemma":"connectivité","pos":"NOM"} ,
		{"word":"connerie","word_nosc":"connerie","lemma":"connerie","pos":"NOM"} ,
		{"word":"conneries","word_nosc":"conneries","lemma":"connerie","pos":"NOM"} ,
		{"word":"connes","word_nosc":"connes","lemma":"con","pos":"NOM"} ,
		{"word":"connexion","word_nosc":"connexion","lemma":"connexion","pos":"NOM"} ,
		{"word":"connexions","word_nosc":"connexions","lemma":"connexion","pos":"NOM"} ,
		{"word":"connils","word_nosc":"connils","lemma":"connil","pos":"NOM"} ,
		{"word":"connivence","word_nosc":"connivence","lemma":"connivence","pos":"NOM"} ,
		{"word":"connivences","word_nosc":"connivences","lemma":"connivence","pos":"NOM"} ,
		{"word":"connotation","word_nosc":"connotation","lemma":"connotation","pos":"NOM"} ,
		{"word":"connotations","word_nosc":"connotations","lemma":"connotation","pos":"NOM"} ,
		{"word":"connétable","word_nosc":"connetable","lemma":"connétable","pos":"NOM"} ,
		{"word":"connétables","word_nosc":"connetables","lemma":"connétable","pos":"NOM"} ,
		{"word":"conque","word_nosc":"conque","lemma":"conque","pos":"NOM"} ,
		{"word":"conques","word_nosc":"conques","lemma":"conque","pos":"NOM"} ,
		{"word":"conquistador","word_nosc":"conquistador","lemma":"conquistador","pos":"NOM"} ,
		{"word":"conquistadores","word_nosc":"conquistadores","lemma":"conquistador","pos":"NOM"} ,
		{"word":"conquistadors","word_nosc":"conquistadors","lemma":"conquistador","pos":"NOM"} ,
		{"word":"conquérant","word_nosc":"conquerant","lemma":"conquérant","pos":"NOM"} ,
		{"word":"conquérante","word_nosc":"conquerante","lemma":"conquérant","pos":"NOM"} ,
		{"word":"conquérantes","word_nosc":"conquerantes","lemma":"conquérant","pos":"NOM"} ,
		{"word":"conquérants","word_nosc":"conquerants","lemma":"conquérant","pos":"NOM"} ,
		{"word":"conquête","word_nosc":"conquete","lemma":"conquête","pos":"NOM"} ,
		{"word":"conquêtes","word_nosc":"conquetes","lemma":"conquête","pos":"NOM"} ,
		{"word":"cons","word_nosc":"cons","lemma":"con","pos":"NOM"} ,
		{"word":"consacrant","word_nosc":"consacrant","lemma":"consacrant","pos":"NOM"} ,
		{"word":"consanguine","word_nosc":"consanguine","lemma":"consanguin","pos":"NOM"} ,
		{"word":"consanguinité","word_nosc":"consanguinite","lemma":"consanguinité","pos":"NOM"} ,
		{"word":"consanguinités","word_nosc":"consanguinites","lemma":"consanguinité","pos":"NOM"} ,
		{"word":"consanguins","word_nosc":"consanguins","lemma":"consanguin","pos":"NOM"} ,
		{"word":"conscience","word_nosc":"conscience","lemma":"conscience","pos":"NOM"} ,
		{"word":"consciences","word_nosc":"consciences","lemma":"conscience","pos":"NOM"} ,
		{"word":"consciencieuse","word_nosc":"consciencieuse","lemma":"consciencieux","pos":"NOM"} ,
		{"word":"consciencieux","word_nosc":"consciencieux","lemma":"consciencieux","pos":"NOM"} ,
		{"word":"conscient","word_nosc":"conscient","lemma":"conscient","pos":"NOM"} ,
		{"word":"conscients","word_nosc":"conscients","lemma":"conscient","pos":"NOM"} ,
		{"word":"conscription","word_nosc":"conscription","lemma":"conscription","pos":"NOM"} ,
		{"word":"conscrit","word_nosc":"conscrit","lemma":"conscrit","pos":"NOM"} ,
		{"word":"conscrits","word_nosc":"conscrits","lemma":"conscrit","pos":"NOM"} ,
		{"word":"conseil","word_nosc":"conseil","lemma":"conseil","pos":"NOM"} ,
		{"word":"conseiller","word_nosc":"conseiller","lemma":"conseiller","pos":"NOM"} ,
		{"word":"conseillers","word_nosc":"conseillers","lemma":"conseiller","pos":"NOM"} ,
		{"word":"conseilleurs","word_nosc":"conseilleurs","lemma":"conseilleur","pos":"NOM"} ,
		{"word":"conseillère","word_nosc":"conseillere","lemma":"conseiller","pos":"NOM"} ,
		{"word":"conseillères","word_nosc":"conseilleres","lemma":"conseiller","pos":"NOM"} ,
		{"word":"conseils","word_nosc":"conseils","lemma":"conseil","pos":"NOM"} ,
		{"word":"consensus","word_nosc":"consensus","lemma":"consensus","pos":"NOM"} ,
		{"word":"consentement","word_nosc":"consentement","lemma":"consentement","pos":"NOM"} ,
		{"word":"consentements","word_nosc":"consentements","lemma":"consentement","pos":"NOM"} ,
		{"word":"conservateur","word_nosc":"conservateur","lemma":"conservateur","pos":"NOM"} ,
		{"word":"conservateurs","word_nosc":"conservateurs","lemma":"conservateur","pos":"NOM"} ,
		{"word":"conservation","word_nosc":"conservation","lemma":"conservation","pos":"NOM"} ,
		{"word":"conservatisme","word_nosc":"conservatisme","lemma":"conservatisme","pos":"NOM"} ,
		{"word":"conservatoire","word_nosc":"conservatoire","lemma":"conservatoire","pos":"NOM"} ,
		{"word":"conservatoires","word_nosc":"conservatoires","lemma":"conservatoire","pos":"NOM"} ,
		{"word":"conservatrice","word_nosc":"conservatrice","lemma":"conservatrice","pos":"NOM"} ,
		{"word":"conservatrices","word_nosc":"conservatrices","lemma":"conservatrice","pos":"NOM"} ,
		{"word":"conserve","word_nosc":"conserve","lemma":"conserve","pos":"NOM"} ,
		{"word":"conserverie","word_nosc":"conserverie","lemma":"conserverie","pos":"NOM"} ,
		{"word":"conserveries","word_nosc":"conserveries","lemma":"conserverie","pos":"NOM"} ,
		{"word":"conserves","word_nosc":"conserves","lemma":"conserve","pos":"NOM"} ,
		{"word":"considérant","word_nosc":"considerant","lemma":"considérant","pos":"NOM"} ,
		{"word":"considérants","word_nosc":"considerants","lemma":"considérant","pos":"NOM"} ,
		{"word":"considération","word_nosc":"consideration","lemma":"considération","pos":"NOM"} ,
		{"word":"considérations","word_nosc":"considerations","lemma":"considération","pos":"NOM"} ,
		{"word":"consignataire","word_nosc":"consignataire","lemma":"consignataire","pos":"NOM"} ,
		{"word":"consignation","word_nosc":"consignation","lemma":"consignation","pos":"NOM"} ,
		{"word":"consignations","word_nosc":"consignations","lemma":"consignation","pos":"NOM"} ,
		{"word":"consigne","word_nosc":"consigne","lemma":"consigne","pos":"NOM"} ,
		{"word":"consignes","word_nosc":"consignes","lemma":"consigne","pos":"NOM"} ,
		{"word":"consistance","word_nosc":"consistance","lemma":"consistance","pos":"NOM"} ,
		{"word":"consistances","word_nosc":"consistances","lemma":"consistance","pos":"NOM"} ,
		{"word":"consistoire","word_nosc":"consistoire","lemma":"consistoire","pos":"NOM"} ,
		{"word":"conso","word_nosc":"conso","lemma":"conso","pos":"NOM"} ,
		{"word":"consoeur","word_nosc":"consoeur","lemma":"consoeur","pos":"NOM"} ,
		{"word":"consoeurs","word_nosc":"consoeurs","lemma":"consoeur","pos":"NOM"} ,
		{"word":"consolateur","word_nosc":"consolateur","lemma":"consolateur","pos":"NOM"} ,
		{"word":"consolateurs","word_nosc":"consolateurs","lemma":"consolateur","pos":"NOM"} ,
		{"word":"consolation","word_nosc":"consolation","lemma":"consolation","pos":"NOM"} ,
		{"word":"consolations","word_nosc":"consolations","lemma":"consolation","pos":"NOM"} ,
		{"word":"consolatrice","word_nosc":"consolatrice","lemma":"consolateur","pos":"NOM"} ,
		{"word":"console","word_nosc":"console","lemma":"console","pos":"NOM"} ,
		{"word":"consoles","word_nosc":"consoles","lemma":"console","pos":"NOM"} ,
		{"word":"consolidation","word_nosc":"consolidation","lemma":"consolidation","pos":"NOM"} ,
		{"word":"consommateur","word_nosc":"consommateur","lemma":"consommateur","pos":"NOM"} ,
		{"word":"consommateurs","word_nosc":"consommateurs","lemma":"consommateur","pos":"NOM"} ,
		{"word":"consommation","word_nosc":"consommation","lemma":"consommation","pos":"NOM"} ,
		{"word":"consommations","word_nosc":"consommations","lemma":"consommation","pos":"NOM"} ,
		{"word":"consommatrice","word_nosc":"consommatrice","lemma":"consommateur","pos":"NOM"} ,
		{"word":"consommatrices","word_nosc":"consommatrices","lemma":"consommateur","pos":"NOM"} ,
		{"word":"consommé","word_nosc":"consomme","lemma":"consommé","pos":"NOM"} ,
		{"word":"consommés","word_nosc":"consommes","lemma":"consommé","pos":"NOM"} ,
		{"word":"consomption","word_nosc":"consomption","lemma":"consomption","pos":"NOM"} ,
		{"word":"consonance","word_nosc":"consonance","lemma":"consonance","pos":"NOM"} ,
		{"word":"consonances","word_nosc":"consonances","lemma":"consonance","pos":"NOM"} ,
		{"word":"consonne","word_nosc":"consonne","lemma":"consonne","pos":"NOM"} ,
		{"word":"consonnes","word_nosc":"consonnes","lemma":"consonne","pos":"NOM"} ,
		{"word":"consort","word_nosc":"consort","lemma":"consort","pos":"NOM"} ,
		{"word":"consortium","word_nosc":"consortium","lemma":"consortium","pos":"NOM"} ,
		{"word":"consortiums","word_nosc":"consortiums","lemma":"consortium","pos":"NOM"} ,
		{"word":"consorts","word_nosc":"consorts","lemma":"consort","pos":"NOM"} ,
		{"word":"consos","word_nosc":"consos","lemma":"conso","pos":"NOM"} ,
		{"word":"consoude","word_nosc":"consoude","lemma":"consoude","pos":"NOM"} ,
		{"word":"conspirateur","word_nosc":"conspirateur","lemma":"conspirateur","pos":"NOM"} ,
		{"word":"conspirateurs","word_nosc":"conspirateurs","lemma":"conspirateur","pos":"NOM"} ,
		{"word":"conspiration","word_nosc":"conspiration","lemma":"conspiration","pos":"NOM"} ,
		{"word":"conspirations","word_nosc":"conspirations","lemma":"conspiration","pos":"NOM"} ,
		{"word":"conspiratrice","word_nosc":"conspiratrice","lemma":"conspirateur","pos":"NOM"} ,
		{"word":"conspiratrices","word_nosc":"conspiratrices","lemma":"conspirateur","pos":"NOM"} ,
		{"word":"constable","word_nosc":"constable","lemma":"constable","pos":"NOM"} ,
		{"word":"constance","word_nosc":"constance","lemma":"constance","pos":"NOM"} ,
		{"word":"constances","word_nosc":"constances","lemma":"constance","pos":"NOM"} ,
		{"word":"constante","word_nosc":"constante","lemma":"constante","pos":"NOM"} ,
		{"word":"constantes","word_nosc":"constantes","lemma":"constante","pos":"NOM"} ,
		{"word":"constat","word_nosc":"constat","lemma":"constat","pos":"NOM"} ,
		{"word":"constatation","word_nosc":"constatation","lemma":"constatation","pos":"NOM"} ,
		{"word":"constatations","word_nosc":"constatations","lemma":"constatation","pos":"NOM"} ,
		{"word":"constats","word_nosc":"constats","lemma":"constat","pos":"NOM"} ,
		{"word":"constellation","word_nosc":"constellation","lemma":"constellation","pos":"NOM"} ,
		{"word":"constellations","word_nosc":"constellations","lemma":"constellation","pos":"NOM"} ,
		{"word":"consternation","word_nosc":"consternation","lemma":"consternation","pos":"NOM"} ,
		{"word":"constipation","word_nosc":"constipation","lemma":"constipation","pos":"NOM"} ,
		{"word":"constipations","word_nosc":"constipations","lemma":"constipation","pos":"NOM"} ,
		{"word":"constipé","word_nosc":"constipe","lemma":"constipé","pos":"NOM"} ,
		{"word":"constipée","word_nosc":"constipee","lemma":"constipé","pos":"NOM"} ,
		{"word":"constipées","word_nosc":"constipees","lemma":"constipé","pos":"NOM"} ,
		{"word":"constipés","word_nosc":"constipes","lemma":"constipé","pos":"NOM"} ,
		{"word":"constituant","word_nosc":"constituant","lemma":"constituant","pos":"NOM"} ,
		{"word":"constituante","word_nosc":"constituante","lemma":"constituant","pos":"NOM"} ,
		{"word":"constituants","word_nosc":"constituants","lemma":"constituant","pos":"NOM"} ,
		{"word":"constitution","word_nosc":"constitution","lemma":"constitution","pos":"NOM"} ,
		{"word":"constitutionnaliste","word_nosc":"constitutionnaliste","lemma":"constitutionnaliste","pos":"NOM"} ,
		{"word":"constitutionnalité","word_nosc":"constitutionnalite","lemma":"constitutionnalité","pos":"NOM"} ,
		{"word":"constitutions","word_nosc":"constitutions","lemma":"constitution","pos":"NOM"} ,
		{"word":"constricteur","word_nosc":"constricteur","lemma":"constricteur","pos":"NOM"} ,
		{"word":"constricteurs","word_nosc":"constricteurs","lemma":"constricteur","pos":"NOM"} ,
		{"word":"constriction","word_nosc":"constriction","lemma":"constriction","pos":"NOM"} ,
		{"word":"constrictor","word_nosc":"constrictor","lemma":"constrictor","pos":"NOM"} ,
		{"word":"constrictors","word_nosc":"constrictors","lemma":"constrictor","pos":"NOM"} ,
		{"word":"constructeur","word_nosc":"constructeur","lemma":"constructeur","pos":"NOM"} ,
		{"word":"constructeurs","word_nosc":"constructeurs","lemma":"constructeur","pos":"NOM"} ,
		{"word":"construction","word_nosc":"construction","lemma":"construction","pos":"NOM"} ,
		{"word":"constructions","word_nosc":"constructions","lemma":"construction","pos":"NOM"} ,
		{"word":"constructivisme","word_nosc":"constructivisme","lemma":"constructivisme","pos":"NOM"} ,
		{"word":"consul","word_nosc":"consul","lemma":"consul","pos":"NOM"} ,
		{"word":"consulat","word_nosc":"consulat","lemma":"consulat","pos":"NOM"} ,
		{"word":"consulats","word_nosc":"consulats","lemma":"consulat","pos":"NOM"} ,
		{"word":"consuls","word_nosc":"consuls","lemma":"consul","pos":"NOM"} ,
		{"word":"consultant","word_nosc":"consultant","lemma":"consultant","pos":"NOM"} ,
		{"word":"consultante","word_nosc":"consultante","lemma":"consultant","pos":"NOM"} ,
		{"word":"consultants","word_nosc":"consultants","lemma":"consultant","pos":"NOM"} ,
		{"word":"consultation","word_nosc":"consultation","lemma":"consultation","pos":"NOM"} ,
		{"word":"consultations","word_nosc":"consultations","lemma":"consultation","pos":"NOM"} ,
		{"word":"consulte","word_nosc":"consulte","lemma":"consulte","pos":"NOM"} ,
		{"word":"consumation","word_nosc":"consumation","lemma":"consumation","pos":"NOM"} ,
		{"word":"consumérisme","word_nosc":"consumerisme","lemma":"consumérisme","pos":"NOM"} ,
		{"word":"consécration","word_nosc":"consecration","lemma":"consécration","pos":"NOM"} ,
		{"word":"consécution","word_nosc":"consecution","lemma":"consécution","pos":"NOM"} ,
		{"word":"conséquence","word_nosc":"consequence","lemma":"conséquence","pos":"NOM"} ,
		{"word":"conséquences","word_nosc":"consequences","lemma":"conséquence","pos":"NOM"} ,
		{"word":"contact","word_nosc":"contact","lemma":"contact","pos":"NOM"} ,
		{"word":"contacteur","word_nosc":"contacteur","lemma":"contacteur","pos":"NOM"} ,
		{"word":"contacts","word_nosc":"contacts","lemma":"contact","pos":"NOM"} ,
		{"word":"contagieuse","word_nosc":"contagieuse","lemma":"contagieux","pos":"NOM"} ,
		{"word":"contagieux","word_nosc":"contagieux","lemma":"contagieux","pos":"NOM"} ,
		{"word":"contagion","word_nosc":"contagion","lemma":"contagion","pos":"NOM"} ,
		{"word":"container","word_nosc":"container","lemma":"container","pos":"NOM"} ,
		{"word":"containers","word_nosc":"containers","lemma":"container","pos":"NOM"} ,
		{"word":"containeur","word_nosc":"containeur","lemma":"containeur","pos":"NOM"} ,
		{"word":"contaminateur","word_nosc":"contaminateur","lemma":"contaminateur","pos":"NOM"} ,
		{"word":"contamination","word_nosc":"contamination","lemma":"contamination","pos":"NOM"} ,
		{"word":"contaminations","word_nosc":"contaminations","lemma":"contamination","pos":"NOM"} ,
		{"word":"conte","word_nosc":"conte","lemma":"conte","pos":"NOM"} ,
		{"word":"contemplateur","word_nosc":"contemplateur","lemma":"contemplateur","pos":"NOM"} ,
		{"word":"contemplatif","word_nosc":"contemplatif","lemma":"contemplatif","pos":"NOM"} ,
		{"word":"contemplatifs","word_nosc":"contemplatifs","lemma":"contemplatif","pos":"NOM"} ,
		{"word":"contemplation","word_nosc":"contemplation","lemma":"contemplation","pos":"NOM"} ,
		{"word":"contemplations","word_nosc":"contemplations","lemma":"contemplation","pos":"NOM"} ,
		{"word":"contemplatrice","word_nosc":"contemplatrice","lemma":"contemplateur","pos":"NOM"} ,
		{"word":"contemporain","word_nosc":"contemporain","lemma":"contemporain","pos":"NOM"} ,
		{"word":"contemporaine","word_nosc":"contemporaine","lemma":"contemporain","pos":"NOM"} ,
		{"word":"contemporaines","word_nosc":"contemporaines","lemma":"contemporain","pos":"NOM"} ,
		{"word":"contemporains","word_nosc":"contemporains","lemma":"contemporain","pos":"NOM"} ,
		{"word":"contempteur","word_nosc":"contempteur","lemma":"contempteur","pos":"NOM"} ,
		{"word":"contempteurs","word_nosc":"contempteurs","lemma":"contempteur","pos":"NOM"} ,
		{"word":"contenance","word_nosc":"contenance","lemma":"contenance","pos":"NOM"} ,
		{"word":"contenances","word_nosc":"contenances","lemma":"contenance","pos":"NOM"} ,
		{"word":"contenant","word_nosc":"contenant","lemma":"contenant","pos":"NOM"} ,
		{"word":"contenants","word_nosc":"contenants","lemma":"contenant","pos":"NOM"} ,
		{"word":"conteneur","word_nosc":"conteneur","lemma":"conteneur","pos":"NOM"} ,
		{"word":"conteneurs","word_nosc":"conteneurs","lemma":"conteneur","pos":"NOM"} ,
		{"word":"content","word_nosc":"content","lemma":"content","pos":"NOM"} ,
		{"word":"contentement","word_nosc":"contentement","lemma":"contentement","pos":"NOM"} ,
		{"word":"contentements","word_nosc":"contentements","lemma":"contentement","pos":"NOM"} ,
		{"word":"contentieux","word_nosc":"contentieux","lemma":"contentieux","pos":"NOM"} ,
		{"word":"contention","word_nosc":"contention","lemma":"contention","pos":"NOM"} ,
		{"word":"contentions","word_nosc":"contentions","lemma":"contention","pos":"NOM"} ,
		{"word":"contents","word_nosc":"contents","lemma":"content","pos":"NOM"} ,
		{"word":"contenu","word_nosc":"contenu","lemma":"contenu","pos":"NOM"} ,
		{"word":"contenus","word_nosc":"contenus","lemma":"contenu","pos":"NOM"} ,
		{"word":"contes","word_nosc":"contes","lemma":"conte","pos":"NOM"} ,
		{"word":"contestataire","word_nosc":"contestataire","lemma":"contestataire","pos":"NOM"} ,
		{"word":"contestataires","word_nosc":"contestataires","lemma":"contestataire","pos":"NOM"} ,
		{"word":"contestation","word_nosc":"contestation","lemma":"contestation","pos":"NOM"} ,
		{"word":"contestations","word_nosc":"contestations","lemma":"contestation","pos":"NOM"} ,
		{"word":"conteste","word_nosc":"conteste","lemma":"conteste","pos":"NOM"} ,
		{"word":"conteur","word_nosc":"conteur","lemma":"conteur","pos":"NOM"} ,
		{"word":"conteurs","word_nosc":"conteurs","lemma":"conteur","pos":"NOM"} ,
		{"word":"conteuse","word_nosc":"conteuse","lemma":"conteur","pos":"NOM"} ,
		{"word":"contexte","word_nosc":"contexte","lemma":"contexte","pos":"NOM"} ,
		{"word":"contextes","word_nosc":"contextes","lemma":"contexte","pos":"NOM"} ,
		{"word":"contiguïté","word_nosc":"contiguite","lemma":"contiguïté","pos":"NOM"} ,
		{"word":"continence","word_nosc":"continence","lemma":"continence","pos":"NOM"} ,
		{"word":"continent","word_nosc":"continent","lemma":"continent","pos":"NOM"} ,
		{"word":"continental","word_nosc":"continental","lemma":"continental","pos":"NOM"} ,
		{"word":"continentale","word_nosc":"continentale","lemma":"continental","pos":"NOM"} ,
		{"word":"continentaux","word_nosc":"continentaux","lemma":"continental","pos":"NOM"} ,
		{"word":"continents","word_nosc":"continents","lemma":"continent","pos":"NOM"} ,
		{"word":"contingence","word_nosc":"contingence","lemma":"contingence","pos":"NOM"} ,
		{"word":"contingences","word_nosc":"contingences","lemma":"contingence","pos":"NOM"} ,
		{"word":"contingent","word_nosc":"contingent","lemma":"contingent","pos":"NOM"} ,
		{"word":"contingentement","word_nosc":"contingentement","lemma":"contingentement","pos":"NOM"} ,
		{"word":"contingents","word_nosc":"contingents","lemma":"contingent","pos":"NOM"} ,
		{"word":"continu","word_nosc":"continu","lemma":"continu","pos":"NOM"} ,
		{"word":"continuateur","word_nosc":"continuateur","lemma":"continuateur","pos":"NOM"} ,
		{"word":"continuateurs","word_nosc":"continuateurs","lemma":"continuateur","pos":"NOM"} ,
		{"word":"continuation","word_nosc":"continuation","lemma":"continuation","pos":"NOM"} ,
		{"word":"continuité","word_nosc":"continuite","lemma":"continuité","pos":"NOM"} ,
		{"word":"continus","word_nosc":"continus","lemma":"continu","pos":"NOM"} ,
		{"word":"continuum","word_nosc":"continuum","lemma":"continuum","pos":"NOM"} ,
		{"word":"contorsion","word_nosc":"contorsion","lemma":"contorsion","pos":"NOM"} ,
		{"word":"contorsionniste","word_nosc":"contorsionniste","lemma":"contorsionniste","pos":"NOM"} ,
		{"word":"contorsionnistes","word_nosc":"contorsionnistes","lemma":"contorsionniste","pos":"NOM"} ,
		{"word":"contorsions","word_nosc":"contorsions","lemma":"contorsion","pos":"NOM"} ,
		{"word":"contour","word_nosc":"contour","lemma":"contour","pos":"NOM"} ,
		{"word":"contournement","word_nosc":"contournement","lemma":"contournement","pos":"NOM"} ,
		{"word":"contournements","word_nosc":"contournements","lemma":"contournement","pos":"NOM"} ,
		{"word":"contours","word_nosc":"contours","lemma":"contour","pos":"NOM"} ,
		{"word":"contra","word_nosc":"contra","lemma":"contra","pos":"NOM"} ,
		{"word":"contraceptif","word_nosc":"contraceptif","lemma":"contraceptif","pos":"NOM"} ,
		{"word":"contraceptifs","word_nosc":"contraceptifs","lemma":"contraceptif","pos":"NOM"} ,
		{"word":"contraception","word_nosc":"contraception","lemma":"contraception","pos":"NOM"} ,
		{"word":"contractant","word_nosc":"contractant","lemma":"contractant","pos":"NOM"} ,
		{"word":"contractants","word_nosc":"contractants","lemma":"contractant","pos":"NOM"} ,
		{"word":"contraction","word_nosc":"contraction","lemma":"contraction","pos":"NOM"} ,
		{"word":"contractions","word_nosc":"contractions","lemma":"contraction","pos":"NOM"} ,
		{"word":"contractuel","word_nosc":"contractuel","lemma":"contractuel","pos":"NOM"} ,
		{"word":"contractuelle","word_nosc":"contractuelle","lemma":"contractuel","pos":"NOM"} ,
		{"word":"contractuelles","word_nosc":"contractuelles","lemma":"contractuel","pos":"NOM"} ,
		{"word":"contractuels","word_nosc":"contractuels","lemma":"contractuel","pos":"NOM"} ,
		{"word":"contracture","word_nosc":"contracture","lemma":"contracture","pos":"NOM"} ,
		{"word":"contractures","word_nosc":"contractures","lemma":"contracture","pos":"NOM"} ,
		{"word":"contradicteur","word_nosc":"contradicteur","lemma":"contradicteur","pos":"NOM"} ,
		{"word":"contradicteurs","word_nosc":"contradicteurs","lemma":"contradicteur","pos":"NOM"} ,
		{"word":"contradiction","word_nosc":"contradiction","lemma":"contradiction","pos":"NOM"} ,
		{"word":"contradictions","word_nosc":"contradictions","lemma":"contradiction","pos":"NOM"} ,
		{"word":"contrainte","word_nosc":"contrainte","lemma":"contrainte","pos":"NOM"} ,
		{"word":"contraintes","word_nosc":"contraintes","lemma":"contraint","pos":"NOM"} ,
		{"word":"contraire","word_nosc":"contraire","lemma":"contraire","pos":"NOM"} ,
		{"word":"contraires","word_nosc":"contraires","lemma":"contraire","pos":"NOM"} ,
		{"word":"contralto","word_nosc":"contralto","lemma":"contralto","pos":"NOM"} ,
		{"word":"contraltos","word_nosc":"contraltos","lemma":"contralto","pos":"NOM"} ,
		{"word":"contrariété","word_nosc":"contrariete","lemma":"contrariété","pos":"NOM"} ,
		{"word":"contrariétés","word_nosc":"contrarietes","lemma":"contrariété","pos":"NOM"} ,
		{"word":"contras","word_nosc":"contras","lemma":"contra","pos":"NOM"} ,
		{"word":"contraste","word_nosc":"contraste","lemma":"contraste","pos":"NOM"} ,
		{"word":"contrastes","word_nosc":"contrastes","lemma":"contraste","pos":"NOM"} ,
		{"word":"contrat","word_nosc":"contrat","lemma":"contrat","pos":"NOM"} ,
		{"word":"contrat-type","word_nosc":"contrat-type","lemma":"contrat-type","pos":"NOM"} ,
		{"word":"contrats","word_nosc":"contrats","lemma":"contrat","pos":"NOM"} ,
		{"word":"contravention","word_nosc":"contravention","lemma":"contravention","pos":"NOM"} ,
		{"word":"contraventions","word_nosc":"contraventions","lemma":"contravention","pos":"NOM"} ,
		{"word":"contre","word_nosc":"contre","lemma":"contre","pos":"NOM"} ,
		{"word":"contre-accusations","word_nosc":"contre-accusations","lemma":"contre-accusation","pos":"NOM"} ,
		{"word":"contre-alizés","word_nosc":"contre-alizes","lemma":"contre-alizé","pos":"NOM"} ,
		{"word":"contre-allée","word_nosc":"contre-allee","lemma":"contre-allée","pos":"NOM"} ,
		{"word":"contre-allées","word_nosc":"contre-allees","lemma":"contre-allée","pos":"NOM"} ,
		{"word":"contre-amiral","word_nosc":"contre-amiral","lemma":"contre-amiral","pos":"NOM"} ,
		{"word":"contre-appel","word_nosc":"contre-appel","lemma":"contre-appel","pos":"NOM"} ,
		{"word":"contre-assurance","word_nosc":"contre-assurance","lemma":"contre-assurance","pos":"NOM"} ,
		{"word":"contre-attaque","word_nosc":"contre-attaque","lemma":"contre-attaque","pos":"NOM"} ,
		{"word":"contre-attaques","word_nosc":"contre-attaques","lemma":"contre-attaque","pos":"NOM"} ,
		{"word":"contre-champ","word_nosc":"contre-champ","lemma":"contre-champ","pos":"NOM"} ,
		{"word":"contre-chant","word_nosc":"contre-chant","lemma":"contre-chant","pos":"NOM"} ,
		{"word":"contre-chocs","word_nosc":"contre-chocs","lemma":"contre-choc","pos":"NOM"} ,
		{"word":"contre-clés","word_nosc":"contre-cles","lemma":"contre-clé","pos":"NOM"} ,
		{"word":"contre-courant","word_nosc":"contre-courant","lemma":"contre-courant","pos":"NOM"} ,
		{"word":"contre-culture","word_nosc":"contre-culture","lemma":"contre-culture","pos":"NOM"} ,
		{"word":"contre-emploi","word_nosc":"contre-emploi","lemma":"contre-emploi","pos":"NOM"} ,
		{"word":"contre-espionnage","word_nosc":"contre-espionnage","lemma":"contre-espionnage","pos":"NOM"} ,
		{"word":"contre-exemple","word_nosc":"contre-exemple","lemma":"contre-exemple","pos":"NOM"} ,
		{"word":"contre-expertise","word_nosc":"contre-expertise","lemma":"contre-expertise","pos":"NOM"} ,
		{"word":"contre-expertises","word_nosc":"contre-expertises","lemma":"contre-expertise","pos":"NOM"} ,
		{"word":"contre-feu","word_nosc":"contre-feu","lemma":"contre-feu","pos":"NOM"} ,
		{"word":"contre-feux","word_nosc":"contre-feux","lemma":"contre-feux","pos":"NOM"} ,
		{"word":"contre-fiches","word_nosc":"contre-fiches","lemma":"contre-fiche","pos":"NOM"} ,
		{"word":"contre-fil","word_nosc":"contre-fil","lemma":"contre-fil","pos":"NOM"} ,
		{"word":"contre-gré","word_nosc":"contre-gre","lemma":"contre-gré","pos":"NOM"} ,
		{"word":"contre-indication","word_nosc":"contre-indication","lemma":"contre-indication","pos":"NOM"} ,
		{"word":"contre-indications","word_nosc":"contre-indications","lemma":"contre-indication","pos":"NOM"} ,
		{"word":"contre-interrogatoire","word_nosc":"contre-interrogatoire","lemma":"contre-interrogatoire","pos":"NOM"} ,
		{"word":"contre-interrogatoires","word_nosc":"contre-interrogatoires","lemma":"contre-interrogatoire","pos":"NOM"} ,
		{"word":"contre-jour","word_nosc":"contre-jour","lemma":"contre-jour","pos":"NOM"} ,
		{"word":"contre-la-montre","word_nosc":"contre-la-montre","lemma":"contre-la-montre","pos":"NOM"} ,
		{"word":"contre-lame","word_nosc":"contre-lame","lemma":"contre-lame","pos":"NOM"} ,
		{"word":"contre-lettre","word_nosc":"contre-lettre","lemma":"contre-lettre","pos":"NOM"} ,
		{"word":"contre-manifestants","word_nosc":"contre-manifestants","lemma":"contre-manifestant","pos":"NOM"} ,
		{"word":"contre-mesure","word_nosc":"contre-mesure","lemma":"contre-mesure","pos":"NOM"} ,
		{"word":"contre-mesures","word_nosc":"contre-mesures","lemma":"contre-mesure","pos":"NOM"} ,
		{"word":"contre-mine","word_nosc":"contre-mine","lemma":"contre-mine","pos":"NOM"} ,
		{"word":"contre-mines","word_nosc":"contre-mines","lemma":"contre-mine","pos":"NOM"} ,
		{"word":"contre-offensive","word_nosc":"contre-offensive","lemma":"contre-offensive","pos":"NOM"} ,
		{"word":"contre-offensives","word_nosc":"contre-offensives","lemma":"contre-offensive","pos":"NOM"} ,
		{"word":"contre-ordre","word_nosc":"contre-ordre","lemma":"contre-ordre","pos":"NOM"} ,
		{"word":"contre-ordres","word_nosc":"contre-ordres","lemma":"contre-ordre","pos":"NOM"} ,
		{"word":"contre-pente","word_nosc":"contre-pente","lemma":"contre-pente","pos":"NOM"} ,
		{"word":"contre-performance","word_nosc":"contre-performance","lemma":"contre-performance","pos":"NOM"} ,
		{"word":"contre-performances","word_nosc":"contre-performances","lemma":"contre-performance","pos":"NOM"} ,
		{"word":"contre-pied","word_nosc":"contre-pied","lemma":"contre-pied","pos":"NOM"} ,
		{"word":"contre-pieds","word_nosc":"contre-pieds","lemma":"contre-pied","pos":"NOM"} ,
		{"word":"contre-plaqué","word_nosc":"contre-plaque","lemma":"contre-plaqué","pos":"NOM"} ,
		{"word":"contre-plongée","word_nosc":"contre-plongee","lemma":"contre-plongée","pos":"NOM"} ,
		{"word":"contre-pouvoirs","word_nosc":"contre-pouvoirs","lemma":"contre-pouvoir","pos":"NOM"} ,
		{"word":"contre-pression","word_nosc":"contre-pression","lemma":"contre-pression","pos":"NOM"} ,
		{"word":"contre-propagande","word_nosc":"contre-propagande","lemma":"contre-propagande","pos":"NOM"} ,
		{"word":"contre-proposition","word_nosc":"contre-proposition","lemma":"contre-proposition","pos":"NOM"} ,
		{"word":"contre-propositions","word_nosc":"contre-propositions","lemma":"contre-proposition","pos":"NOM"} ,
		{"word":"contre-réaction","word_nosc":"contre-reaction","lemma":"contre-réaction","pos":"NOM"} ,
		{"word":"contre-réforme","word_nosc":"contre-reforme","lemma":"contre-réforme","pos":"NOM"} ,
		{"word":"contre-révolution","word_nosc":"contre-revolution","lemma":"contre-révolution","pos":"NOM"} ,
		{"word":"contre-révolutionnaire","word_nosc":"contre-revolutionnaire","lemma":"contre-révolutionnaire","pos":"NOM"} ,
		{"word":"contre-révolutionnaires","word_nosc":"contre-revolutionnaires","lemma":"contre-révolutionnaire","pos":"NOM"} ,
		{"word":"contre-terrorisme","word_nosc":"contre-terrorisme","lemma":"contre-terrorisme","pos":"NOM"} ,
		{"word":"contre-test","word_nosc":"contre-test","lemma":"contre-test","pos":"NOM"} ,
		{"word":"contre-torpilleur","word_nosc":"contre-torpilleur","lemma":"contre-torpilleur","pos":"NOM"} ,
		{"word":"contre-torpilleurs","word_nosc":"contre-torpilleurs","lemma":"contre-torpilleur","pos":"NOM"} ,
		{"word":"contre-transfert","word_nosc":"contre-transfert","lemma":"contre-transfert","pos":"NOM"} ,
		{"word":"contre-ut","word_nosc":"contre-ut","lemma":"contre-ut","pos":"NOM"} ,
		{"word":"contre-voie","word_nosc":"contre-voie","lemma":"contre-voie","pos":"NOM"} ,
		{"word":"contre-vérité","word_nosc":"contre-verite","lemma":"contre-vérité","pos":"NOM"} ,
		{"word":"contre-épreuve","word_nosc":"contre-epreuve","lemma":"contre-épreuve","pos":"NOM"} ,
		{"word":"contre-épreuves","word_nosc":"contre-epreuves","lemma":"contre-épreuve","pos":"NOM"} ,
		{"word":"contrebande","word_nosc":"contrebande","lemma":"contrebande","pos":"NOM"} ,
		{"word":"contrebandier","word_nosc":"contrebandier","lemma":"contrebandier","pos":"NOM"} ,
		{"word":"contrebandiers","word_nosc":"contrebandiers","lemma":"contrebandier","pos":"NOM"} ,
		{"word":"contrebandière","word_nosc":"contrebandiere","lemma":"contrebandier","pos":"NOM"} ,
		{"word":"contrebasse","word_nosc":"contrebasse","lemma":"contrebasse","pos":"NOM"} ,
		{"word":"contrebasses","word_nosc":"contrebasses","lemma":"contrebasse","pos":"NOM"} ,
		{"word":"contrebassiste","word_nosc":"contrebassiste","lemma":"contrebassiste","pos":"NOM"} ,
		{"word":"contrebassistes","word_nosc":"contrebassistes","lemma":"contrebassiste","pos":"NOM"} ,
		{"word":"contrechamp","word_nosc":"contrechamp","lemma":"contrechamp","pos":"NOM"} ,
		{"word":"contrechamps","word_nosc":"contrechamps","lemma":"contrechamp","pos":"NOM"} ,
		{"word":"contrecoeur","word_nosc":"contrecoeur","lemma":"contrecoeur","pos":"NOM"} ,
		{"word":"contrecoup","word_nosc":"contrecoup","lemma":"contrecoup","pos":"NOM"} ,
		{"word":"contrecoups","word_nosc":"contrecoups","lemma":"contrecoup","pos":"NOM"} ,
		{"word":"contredanse","word_nosc":"contredanse","lemma":"contredanse","pos":"NOM"} ,
		{"word":"contredanses","word_nosc":"contredanses","lemma":"contredanse","pos":"NOM"} ,
		{"word":"contredit","word_nosc":"contredit","lemma":"contredit","pos":"NOM"} ,
		{"word":"contredits","word_nosc":"contredits","lemma":"contredit","pos":"NOM"} ,
		{"word":"contrefacteurs","word_nosc":"contrefacteurs","lemma":"contrefacteur","pos":"NOM"} ,
		{"word":"contrefaçon","word_nosc":"contrefacon","lemma":"contrefaçon","pos":"NOM"} ,
		{"word":"contrefaçons","word_nosc":"contrefacons","lemma":"contrefaçon","pos":"NOM"} ,
		{"word":"contrefiche","word_nosc":"contrefiche","lemma":"contrefiche","pos":"NOM"} ,
		{"word":"contrefort","word_nosc":"contrefort","lemma":"contrefort","pos":"NOM"} ,
		{"word":"contreforts","word_nosc":"contreforts","lemma":"contrefort","pos":"NOM"} ,
		{"word":"contremarches","word_nosc":"contremarches","lemma":"contremarche","pos":"NOM"} ,
		{"word":"contremarques","word_nosc":"contremarques","lemma":"contremarque","pos":"NOM"} ,
		{"word":"contremaître","word_nosc":"contremaitre","lemma":"contremaître","pos":"NOM"} ,
		{"word":"contremaîtres","word_nosc":"contremaitres","lemma":"contremaître","pos":"NOM"} ,
		{"word":"contremaîtresse","word_nosc":"contremaitresse","lemma":"contremaîtresse","pos":"NOM"} ,
		{"word":"contrepartie","word_nosc":"contrepartie","lemma":"contrepartie","pos":"NOM"} ,
		{"word":"contreplaqué","word_nosc":"contreplaque","lemma":"contreplaqué","pos":"NOM"} ,
		{"word":"contrepoids","word_nosc":"contrepoids","lemma":"contrepoids","pos":"NOM"} ,
		{"word":"contrepoint","word_nosc":"contrepoint","lemma":"contrepoint","pos":"NOM"} ,
		{"word":"contrepoison","word_nosc":"contrepoison","lemma":"contrepoison","pos":"NOM"} ,
		{"word":"contrepoisons","word_nosc":"contrepoisons","lemma":"contrepoison","pos":"NOM"} ,
		{"word":"contreproposition","word_nosc":"contreproposition","lemma":"contreproposition","pos":"NOM"} ,
		{"word":"contrepèterie","word_nosc":"contrepeterie","lemma":"contrepèterie","pos":"NOM"} ,
		{"word":"contrepèteries","word_nosc":"contrepeteries","lemma":"contrepèterie","pos":"NOM"} ,
		{"word":"contres","word_nosc":"contres","lemma":"contre","pos":"NOM"} ,
		{"word":"contrescarpe","word_nosc":"contrescarpe","lemma":"contrescarpe","pos":"NOM"} ,
		{"word":"contreseing","word_nosc":"contreseing","lemma":"contreseing","pos":"NOM"} ,
		{"word":"contresens","word_nosc":"contresens","lemma":"contresens","pos":"NOM"} ,
		{"word":"contretemps","word_nosc":"contretemps","lemma":"contretemps","pos":"NOM"} ,
		{"word":"contrevallation","word_nosc":"contrevallation","lemma":"contrevallation","pos":"NOM"} ,
		{"word":"contrevenant","word_nosc":"contrevenant","lemma":"contrevenant","pos":"NOM"} ,
		{"word":"contrevenants","word_nosc":"contrevenants","lemma":"contrevenant","pos":"NOM"} ,
		{"word":"contrevent","word_nosc":"contrevent","lemma":"contrevent","pos":"NOM"} ,
		{"word":"contrevents","word_nosc":"contrevents","lemma":"contrevent","pos":"NOM"} ,
		{"word":"contrevérité","word_nosc":"contreverite","lemma":"contrevérité","pos":"NOM"} ,
		{"word":"contrevérités","word_nosc":"contreverites","lemma":"contrevérité","pos":"NOM"} ,
		{"word":"contribuable","word_nosc":"contribuable","lemma":"contribuable","pos":"NOM"} ,
		{"word":"contribuables","word_nosc":"contribuables","lemma":"contribuable","pos":"NOM"} ,
		{"word":"contributeur","word_nosc":"contributeur","lemma":"contributeur","pos":"NOM"} ,
		{"word":"contribution","word_nosc":"contribution","lemma":"contribution","pos":"NOM"} ,
		{"word":"contributions","word_nosc":"contributions","lemma":"contribution","pos":"NOM"} ,
		{"word":"contrition","word_nosc":"contrition","lemma":"contrition","pos":"NOM"} ,
		{"word":"contritions","word_nosc":"contritions","lemma":"contrition","pos":"NOM"} ,
		{"word":"contrordre","word_nosc":"contrordre","lemma":"contrordre","pos":"NOM"} ,
		{"word":"contrordres","word_nosc":"contrordres","lemma":"contrordre","pos":"NOM"} ,
		{"word":"controverse","word_nosc":"controverse","lemma":"controverse","pos":"NOM"} ,
		{"word":"controverses","word_nosc":"controverses","lemma":"controverse","pos":"NOM"} ,
		{"word":"contrée","word_nosc":"contree","lemma":"contrée","pos":"NOM"} ,
		{"word":"contrées","word_nosc":"contrees","lemma":"contrée","pos":"NOM"} ,
		{"word":"contrôle","word_nosc":"controle","lemma":"contrôle","pos":"NOM"} ,
		{"word":"contrôles","word_nosc":"controles","lemma":"contrôle","pos":"NOM"} ,
		{"word":"contrôleur","word_nosc":"controleur","lemma":"contrôleur","pos":"NOM"} ,
		{"word":"contrôleurs","word_nosc":"controleurs","lemma":"contrôleur","pos":"NOM"} ,
		{"word":"contrôleuse","word_nosc":"controleuse","lemma":"contrôleur","pos":"NOM"} ,
		{"word":"contumace","word_nosc":"contumace","lemma":"contumace","pos":"NOM"} ,
		{"word":"contumaces","word_nosc":"contumaces","lemma":"contumace","pos":"NOM"} ,
		{"word":"contusion","word_nosc":"contusion","lemma":"contusion","pos":"NOM"} ,
		{"word":"contusions","word_nosc":"contusions","lemma":"contusion","pos":"NOM"} ,
		{"word":"convaincu","word_nosc":"convaincu","lemma":"convaincu","pos":"NOM"} ,
		{"word":"convaincus","word_nosc":"convaincus","lemma":"convaincu","pos":"NOM"} ,
		{"word":"convalescence","word_nosc":"convalescence","lemma":"convalescence","pos":"NOM"} ,
		{"word":"convalescences","word_nosc":"convalescences","lemma":"convalescence","pos":"NOM"} ,
		{"word":"convalescent","word_nosc":"convalescent","lemma":"convalescent","pos":"NOM"} ,
		{"word":"convalescents","word_nosc":"convalescents","lemma":"convalescent","pos":"NOM"} ,
		{"word":"convalo","word_nosc":"convalo","lemma":"convalo","pos":"NOM"} ,
		{"word":"convalos","word_nosc":"convalos","lemma":"convalo","pos":"NOM"} ,
		{"word":"convecteur","word_nosc":"convecteur","lemma":"convecteur","pos":"NOM"} ,
		{"word":"convection","word_nosc":"convection","lemma":"convection","pos":"NOM"} ,
		{"word":"convenance","word_nosc":"convenance","lemma":"convenance","pos":"NOM"} ,
		{"word":"convenances","word_nosc":"convenances","lemma":"convenance","pos":"NOM"} ,
		{"word":"convenant","word_nosc":"convenant","lemma":"convenant","pos":"NOM"} ,
		{"word":"convent","word_nosc":"convent","lemma":"convent","pos":"NOM"} ,
		{"word":"conventicule","word_nosc":"conventicule","lemma":"conventicule","pos":"NOM"} ,
		{"word":"convention","word_nosc":"convention","lemma":"convention","pos":"NOM"} ,
		{"word":"conventionnel","word_nosc":"conventionnel","lemma":"conventionnel","pos":"NOM"} ,
		{"word":"conventionnels","word_nosc":"conventionnels","lemma":"conventionnel","pos":"NOM"} ,
		{"word":"conventions","word_nosc":"conventions","lemma":"convention","pos":"NOM"} ,
		{"word":"convergence","word_nosc":"convergence","lemma":"convergence","pos":"NOM"} ,
		{"word":"conversation","word_nosc":"conversation","lemma":"conversation","pos":"NOM"} ,
		{"word":"conversations","word_nosc":"conversations","lemma":"conversation","pos":"NOM"} ,
		{"word":"converse","word_nosc":"converse","lemma":"converse","pos":"NOM"} ,
		{"word":"conversion","word_nosc":"conversion","lemma":"conversion","pos":"NOM"} ,
		{"word":"conversions","word_nosc":"conversions","lemma":"conversion","pos":"NOM"} ,
		{"word":"converti","word_nosc":"converti","lemma":"converti","pos":"NOM"} ,
		{"word":"convertie","word_nosc":"convertie","lemma":"converti","pos":"NOM"} ,
		{"word":"converties","word_nosc":"converties","lemma":"converti","pos":"NOM"} ,
		{"word":"convertis","word_nosc":"convertis","lemma":"converti","pos":"NOM"} ,
		{"word":"convertisseur","word_nosc":"convertisseur","lemma":"convertisseur","pos":"NOM"} ,
		{"word":"convertisseurs","word_nosc":"convertisseurs","lemma":"convertisseur","pos":"NOM"} ,
		{"word":"convexité","word_nosc":"convexite","lemma":"convexité","pos":"NOM"} ,
		{"word":"convict","word_nosc":"convict","lemma":"convict","pos":"NOM"} ,
		{"word":"conviction","word_nosc":"conviction","lemma":"conviction","pos":"NOM"} ,
		{"word":"convictions","word_nosc":"convictions","lemma":"conviction","pos":"NOM"} ,
		{"word":"convicts","word_nosc":"convicts","lemma":"convict","pos":"NOM"} ,
		{"word":"convive","word_nosc":"convive","lemma":"convive","pos":"NOM"} ,
		{"word":"convives","word_nosc":"convives","lemma":"convive","pos":"NOM"} ,
		{"word":"convivialité","word_nosc":"convivialite","lemma":"convivialité","pos":"NOM"} ,
		{"word":"convocation","word_nosc":"convocation","lemma":"convocation","pos":"NOM"} ,
		{"word":"convocations","word_nosc":"convocations","lemma":"convocation","pos":"NOM"} ,
		{"word":"convoi","word_nosc":"convoi","lemma":"convoi","pos":"NOM"} ,
		{"word":"convois","word_nosc":"convois","lemma":"convoi","pos":"NOM"} ,
		{"word":"convoitise","word_nosc":"convoitise","lemma":"convoitise","pos":"NOM"} ,
		{"word":"convoitises","word_nosc":"convoitises","lemma":"convoitise","pos":"NOM"} ,
		{"word":"convoyage","word_nosc":"convoyage","lemma":"convoyage","pos":"NOM"} ,
		{"word":"convoyages","word_nosc":"convoyages","lemma":"convoyage","pos":"NOM"} ,
		{"word":"convoyeur","word_nosc":"convoyeur","lemma":"convoyeur","pos":"NOM"} ,
		{"word":"convoyeurs","word_nosc":"convoyeurs","lemma":"convoyeur","pos":"NOM"} ,
		{"word":"convoyeuse","word_nosc":"convoyeuse","lemma":"convoyeur","pos":"NOM"} ,
		{"word":"convulsion","word_nosc":"convulsion","lemma":"convulsion","pos":"NOM"} ,
		{"word":"convulsionnaire","word_nosc":"convulsionnaire","lemma":"convulsionnaire","pos":"NOM"} ,
		{"word":"convulsions","word_nosc":"convulsions","lemma":"convulsion","pos":"NOM"} ,
		{"word":"cookie","word_nosc":"cookie","lemma":"cookie","pos":"NOM"} ,
		{"word":"cookies","word_nosc":"cookies","lemma":"cookie","pos":"NOM"} ,
		{"word":"coolie","word_nosc":"coolie","lemma":"coolie","pos":"NOM"} ,
		{"word":"coolie-pousse","word_nosc":"coolie-pousse","lemma":"coolie-pousse","pos":"NOM"} ,
		{"word":"coolies","word_nosc":"coolies","lemma":"coolie","pos":"NOM"} ,
		{"word":"cooptation","word_nosc":"cooptation","lemma":"cooptation","pos":"NOM"} ,
		{"word":"coopé","word_nosc":"coope","lemma":"coopé","pos":"NOM"} ,
		{"word":"coopérant","word_nosc":"cooperant","lemma":"coopérant","pos":"NOM"} ,
		{"word":"coopérante","word_nosc":"cooperante","lemma":"coopérant","pos":"NOM"} ,
		{"word":"coopérants","word_nosc":"cooperants","lemma":"coopérant","pos":"NOM"} ,
		{"word":"coopérateur","word_nosc":"cooperateur","lemma":"coopérateur","pos":"NOM"} ,
		{"word":"coopération","word_nosc":"cooperation","lemma":"coopération","pos":"NOM"} ,
		{"word":"coopératisme","word_nosc":"cooperatisme","lemma":"coopératisme","pos":"NOM"} ,
		{"word":"coopérative","word_nosc":"cooperative","lemma":"coopérative","pos":"NOM"} ,
		{"word":"coopératives","word_nosc":"cooperatives","lemma":"coopérative","pos":"NOM"} ,
		{"word":"coordinateur","word_nosc":"coordinateur","lemma":"coordinateur","pos":"NOM"} ,
		{"word":"coordinateurs","word_nosc":"coordinateurs","lemma":"coordinateur","pos":"NOM"} ,
		{"word":"coordination","word_nosc":"coordination","lemma":"coordination","pos":"NOM"} ,
		{"word":"coordinatrice","word_nosc":"coordinatrice","lemma":"coordinateur","pos":"NOM"} ,
		{"word":"coordonnateur","word_nosc":"coordonnateur","lemma":"coordonnateur","pos":"NOM"} ,
		{"word":"coordonnée","word_nosc":"coordonnee","lemma":"coordonnée","pos":"NOM"} ,
		{"word":"coordonnées","word_nosc":"coordonnees","lemma":"coordonnée","pos":"NOM"} ,
		{"word":"coordonnés","word_nosc":"coordonnes","lemma":"coordonné","pos":"NOM"} ,
		{"word":"cop","word_nosc":"cop","lemma":"cop","pos":"NOM"} ,
		{"word":"copain","word_nosc":"copain","lemma":"copain","pos":"NOM"} ,
		{"word":"copains","word_nosc":"copains","lemma":"copain","pos":"NOM"} ,
		{"word":"copal","word_nosc":"copal","lemma":"copal","pos":"NOM"} ,
		{"word":"copaïba","word_nosc":"copaiba","lemma":"copaïba","pos":"NOM"} ,
		{"word":"copeau","word_nosc":"copeau","lemma":"copeau","pos":"NOM"} ,
		{"word":"copeaux","word_nosc":"copeaux","lemma":"copeau","pos":"NOM"} ,
		{"word":"copiage","word_nosc":"copiage","lemma":"copiage","pos":"NOM"} ,
		{"word":"copie","word_nosc":"copie","lemma":"copie","pos":"NOM"} ,
		{"word":"copies","word_nosc":"copies","lemma":"copie","pos":"NOM"} ,
		{"word":"copieur","word_nosc":"copieur","lemma":"copieur","pos":"NOM"} ,
		{"word":"copieurs","word_nosc":"copieurs","lemma":"copieur","pos":"NOM"} ,
		{"word":"copieuse","word_nosc":"copieuse","lemma":"copieur","pos":"NOM"} ,
		{"word":"copieuses","word_nosc":"copieuses","lemma":"copieur","pos":"NOM"} ,
		{"word":"copilote","word_nosc":"copilote","lemma":"copilote","pos":"NOM"} ,
		{"word":"copinage","word_nosc":"copinage","lemma":"copinage","pos":"NOM"} ,
		{"word":"copine","word_nosc":"copine","lemma":"copain","pos":"NOM"} ,
		{"word":"copines","word_nosc":"copines","lemma":"copain","pos":"NOM"} ,
		{"word":"copiste","word_nosc":"copiste","lemma":"copiste","pos":"NOM"} ,
		{"word":"copistes","word_nosc":"copistes","lemma":"copiste","pos":"NOM"} ,
		{"word":"copla","word_nosc":"copla","lemma":"copla","pos":"NOM"} ,
		{"word":"coppa","word_nosc":"coppa","lemma":"coppa","pos":"NOM"} ,
		{"word":"copra","word_nosc":"copra","lemma":"copra","pos":"NOM"} ,
		{"word":"coprins","word_nosc":"coprins","lemma":"coprin","pos":"NOM"} ,
		{"word":"coproculture","word_nosc":"coproculture","lemma":"coproculture","pos":"NOM"} ,
		{"word":"coproducteur","word_nosc":"coproducteur","lemma":"coproducteur","pos":"NOM"} ,
		{"word":"coproducteurs","word_nosc":"coproducteurs","lemma":"coproducteur","pos":"NOM"} ,
		{"word":"coproduction","word_nosc":"coproduction","lemma":"coproduction","pos":"NOM"} ,
		{"word":"coprologie","word_nosc":"coprologie","lemma":"coprologie","pos":"NOM"} ,
		{"word":"coprophage","word_nosc":"coprophage","lemma":"coprophage","pos":"NOM"} ,
		{"word":"coprophagie","word_nosc":"coprophagie","lemma":"coprophagie","pos":"NOM"} ,
		{"word":"coprophile","word_nosc":"coprophile","lemma":"coprophile","pos":"NOM"} ,
		{"word":"coprophilie","word_nosc":"coprophilie","lemma":"coprophilie","pos":"NOM"} ,
		{"word":"copropriétaire","word_nosc":"coproprietaire","lemma":"copropriétaire","pos":"NOM"} ,
		{"word":"copropriétaires","word_nosc":"coproprietaires","lemma":"copropriétaire","pos":"NOM"} ,
		{"word":"copropriété","word_nosc":"copropriete","lemma":"copropriété","pos":"NOM"} ,
		{"word":"coprésidence","word_nosc":"copresidence","lemma":"coprésidence","pos":"NOM"} ,
		{"word":"cops","word_nosc":"cops","lemma":"cops","pos":"NOM"} ,
		{"word":"copte","word_nosc":"copte","lemma":"copte","pos":"NOM"} ,
		{"word":"coptes","word_nosc":"coptes","lemma":"copte","pos":"NOM"} ,
		{"word":"copulant","word_nosc":"copulant","lemma":"copulant","pos":"NOM"} ,
		{"word":"copulation","word_nosc":"copulation","lemma":"copulation","pos":"NOM"} ,
		{"word":"copulations","word_nosc":"copulations","lemma":"copulation","pos":"NOM"} ,
		{"word":"copule","word_nosc":"copule","lemma":"copule","pos":"NOM"} ,
		{"word":"copyright","word_nosc":"copyright","lemma":"copyright","pos":"NOM"} ,
		{"word":"coq","word_nosc":"coq","lemma":"coq","pos":"NOM"} ,
		{"word":"coq-à-l'âne","word_nosc":"coq-a-l'ane","lemma":"coq-à-l'âne","pos":"NOM"} ,
		{"word":"coqs","word_nosc":"coqs","lemma":"coq","pos":"NOM"} ,
		{"word":"coquard","word_nosc":"coquard","lemma":"coquard","pos":"NOM"} ,
		{"word":"coquards","word_nosc":"coquards","lemma":"coquard","pos":"NOM"} ,
		{"word":"coquart","word_nosc":"coquart","lemma":"coquart","pos":"NOM"} ,
		{"word":"coquarts","word_nosc":"coquarts","lemma":"coquart","pos":"NOM"} ,
		{"word":"coque","word_nosc":"coque","lemma":"coque","pos":"NOM"} ,
		{"word":"coquebin","word_nosc":"coquebin","lemma":"coquebin","pos":"NOM"} ,
		{"word":"coquelet","word_nosc":"coquelet","lemma":"coquelet","pos":"NOM"} ,
		{"word":"coquelicot","word_nosc":"coquelicot","lemma":"coquelicot","pos":"NOM"} ,
		{"word":"coquelicots","word_nosc":"coquelicots","lemma":"coquelicot","pos":"NOM"} ,
		{"word":"coquelle","word_nosc":"coquelle","lemma":"coquelle","pos":"NOM"} ,
		{"word":"coqueluche","word_nosc":"coqueluche","lemma":"coqueluche","pos":"NOM"} ,
		{"word":"coqueluches","word_nosc":"coqueluches","lemma":"coqueluche","pos":"NOM"} ,
		{"word":"coquemar","word_nosc":"coquemar","lemma":"coquemar","pos":"NOM"} ,
		{"word":"coquerelle","word_nosc":"coquerelle","lemma":"coquerelle","pos":"NOM"} ,
		{"word":"coquerie","word_nosc":"coquerie","lemma":"coquerie","pos":"NOM"} ,
		{"word":"coqueron","word_nosc":"coqueron","lemma":"coqueron","pos":"NOM"} ,
		{"word":"coques","word_nosc":"coques","lemma":"coque","pos":"NOM"} ,
		{"word":"coquetel","word_nosc":"coquetel","lemma":"coquetel","pos":"NOM"} ,
		{"word":"coquetier","word_nosc":"coquetier","lemma":"coquetier","pos":"NOM"} ,
		{"word":"coquetiers","word_nosc":"coquetiers","lemma":"coquetier","pos":"NOM"} ,
		{"word":"coquette","word_nosc":"coquette","lemma":"coquette","pos":"NOM"} ,
		{"word":"coquetterie","word_nosc":"coquetterie","lemma":"coquetterie","pos":"NOM"} ,
		{"word":"coquetteries","word_nosc":"coquetteries","lemma":"coquetterie","pos":"NOM"} ,
		{"word":"coquettes","word_nosc":"coquettes","lemma":"coquette","pos":"NOM"} ,
		{"word":"coquillage","word_nosc":"coquillage","lemma":"coquillage","pos":"NOM"} ,
		{"word":"coquillages","word_nosc":"coquillages","lemma":"coquillage","pos":"NOM"} ,
		{"word":"coquillard","word_nosc":"coquillard","lemma":"coquillard","pos":"NOM"} ,
		{"word":"coquillards","word_nosc":"coquillards","lemma":"coquillard","pos":"NOM"} ,
		{"word":"coquillart","word_nosc":"coquillart","lemma":"coquillart","pos":"NOM"} ,
		{"word":"coquille","word_nosc":"coquille","lemma":"coquille","pos":"NOM"} ,
		{"word":"coquilles","word_nosc":"coquilles","lemma":"coquille","pos":"NOM"} ,
		{"word":"coquillettes","word_nosc":"coquillettes","lemma":"coquillette","pos":"NOM"} ,
		{"word":"coquin","word_nosc":"coquin","lemma":"coquin","pos":"NOM"} ,
		{"word":"coquine","word_nosc":"coquine","lemma":"coquin","pos":"NOM"} ,
		{"word":"coquinerie","word_nosc":"coquinerie","lemma":"coquinerie","pos":"NOM"} ,
		{"word":"coquineries","word_nosc":"coquineries","lemma":"coquinerie","pos":"NOM"} ,
		{"word":"coquines","word_nosc":"coquines","lemma":"coquin","pos":"NOM"} ,
		{"word":"coquins","word_nosc":"coquins","lemma":"coquin","pos":"NOM"} ,
		{"word":"cor","word_nosc":"cor","lemma":"cor","pos":"NOM"} ,
		{"word":"cora","word_nosc":"cora","lemma":"cora","pos":"NOM"} ,
		{"word":"corail","word_nosc":"corail","lemma":"corail","pos":"NOM"} ,
		{"word":"coralliaires","word_nosc":"coralliaires","lemma":"coralliaire","pos":"NOM"} ,
		{"word":"coramine","word_nosc":"coramine","lemma":"coramine","pos":"NOM"} ,
		{"word":"coran","word_nosc":"coran","lemma":"coran","pos":"NOM"} ,
		{"word":"coras","word_nosc":"coras","lemma":"cora","pos":"NOM"} ,
		{"word":"coraux","word_nosc":"coraux","lemma":"corail","pos":"NOM"} ,
		{"word":"corbeau","word_nosc":"corbeau","lemma":"corbeau","pos":"NOM"} ,
		{"word":"corbeaux","word_nosc":"corbeaux","lemma":"corbeau","pos":"NOM"} ,
		{"word":"corbeille","word_nosc":"corbeille","lemma":"corbeille","pos":"NOM"} ,
		{"word":"corbeilles","word_nosc":"corbeilles","lemma":"corbeille","pos":"NOM"} ,
		{"word":"corbillard","word_nosc":"corbillard","lemma":"corbillard","pos":"NOM"} ,
		{"word":"corbillards","word_nosc":"corbillards","lemma":"corbillard","pos":"NOM"} ,
		{"word":"corbin","word_nosc":"corbin","lemma":"corbin","pos":"NOM"} ,
		{"word":"corbières","word_nosc":"corbieres","lemma":"corbières","pos":"NOM"} ,
		{"word":"cordage","word_nosc":"cordage","lemma":"cordage","pos":"NOM"} ,
		{"word":"cordages","word_nosc":"cordages","lemma":"cordage","pos":"NOM"} ,
		{"word":"corde","word_nosc":"corde","lemma":"corde","pos":"NOM"} ,
		{"word":"cordeau","word_nosc":"cordeau","lemma":"cordeau","pos":"NOM"} ,
		{"word":"cordeaux","word_nosc":"cordeaux","lemma":"cordeau","pos":"NOM"} ,
		{"word":"cordelette","word_nosc":"cordelette","lemma":"cordelette","pos":"NOM"} ,
		{"word":"cordelettes","word_nosc":"cordelettes","lemma":"cordelette","pos":"NOM"} ,
		{"word":"cordelier","word_nosc":"cordelier","lemma":"cordelier","pos":"NOM"} ,
		{"word":"cordeliers","word_nosc":"cordeliers","lemma":"cordelier","pos":"NOM"} ,
		{"word":"cordelière","word_nosc":"cordeliere","lemma":"cordelier","pos":"NOM"} ,
		{"word":"cordelières","word_nosc":"cordelieres","lemma":"cordelier","pos":"NOM"} ,
		{"word":"cordelle","word_nosc":"cordelle","lemma":"cordeau","pos":"NOM"} ,
		{"word":"corderie","word_nosc":"corderie","lemma":"corderie","pos":"NOM"} ,
		{"word":"cordes","word_nosc":"cordes","lemma":"corde","pos":"NOM"} ,
		{"word":"cordial","word_nosc":"cordial","lemma":"cordial","pos":"NOM"} ,
		{"word":"cordialité","word_nosc":"cordialite","lemma":"cordialité","pos":"NOM"} ,
		{"word":"cordialités","word_nosc":"cordialites","lemma":"cordialité","pos":"NOM"} ,
		{"word":"cordiers","word_nosc":"cordiers","lemma":"cordier","pos":"NOM"} ,
		{"word":"cordillère","word_nosc":"cordillere","lemma":"cordillère","pos":"NOM"} ,
		{"word":"cordite","word_nosc":"cordite","lemma":"cordite","pos":"NOM"} ,
		{"word":"cordobas","word_nosc":"cordobas","lemma":"cordoba","pos":"NOM"} ,
		{"word":"cordon","word_nosc":"cordon","lemma":"cordon","pos":"NOM"} ,
		{"word":"cordon-bleu","word_nosc":"cordon-bleu","lemma":"cordon-bleu","pos":"NOM"} ,
		{"word":"cordonnerie","word_nosc":"cordonnerie","lemma":"cordonnerie","pos":"NOM"} ,
		{"word":"cordonnet","word_nosc":"cordonnet","lemma":"cordonnet","pos":"NOM"} ,
		{"word":"cordonnets","word_nosc":"cordonnets","lemma":"cordonnet","pos":"NOM"} ,
		{"word":"cordonnier","word_nosc":"cordonnier","lemma":"cordonnier","pos":"NOM"} ,
		{"word":"cordonniers","word_nosc":"cordonniers","lemma":"cordonnier","pos":"NOM"} ,
		{"word":"cordonnière","word_nosc":"cordonniere","lemma":"cordonnier","pos":"NOM"} ,
		{"word":"cordons","word_nosc":"cordons","lemma":"cordon","pos":"NOM"} ,
		{"word":"cordons-bleus","word_nosc":"cordons-bleus","lemma":"cordon-bleu","pos":"NOM"} ,
		{"word":"cordée","word_nosc":"cordee","lemma":"cordée","pos":"NOM"} ,
		{"word":"cordées","word_nosc":"cordees","lemma":"cordée","pos":"NOM"} ,
		{"word":"coreligionnaires","word_nosc":"coreligionnaires","lemma":"coreligionnaire","pos":"NOM"} ,
		{"word":"coriacité","word_nosc":"coriacite","lemma":"coriacité","pos":"NOM"} ,
		{"word":"coriandre","word_nosc":"coriandre","lemma":"coriandre","pos":"NOM"} ,
		{"word":"corindon","word_nosc":"corindon","lemma":"corindon","pos":"NOM"} ,
		{"word":"corinthe","word_nosc":"corinthe","lemma":"corinthe","pos":"NOM"} ,
		{"word":"corinthien","word_nosc":"corinthien","lemma":"corinthien","pos":"NOM"} ,
		{"word":"corinthiens","word_nosc":"corinthiens","lemma":"corinthien","pos":"NOM"} ,
		{"word":"cormier","word_nosc":"cormier","lemma":"cormier","pos":"NOM"} ,
		{"word":"cormoran","word_nosc":"cormoran","lemma":"cormoran","pos":"NOM"} ,
		{"word":"cormorans","word_nosc":"cormorans","lemma":"cormoran","pos":"NOM"} ,
		{"word":"corn flakes","word_nosc":"corn flakes","lemma":"corn flakes","pos":"NOM"} ,
		{"word":"corn-flakes","word_nosc":"corn-flakes","lemma":"corn-flakes","pos":"NOM"} ,
		{"word":"cornac","word_nosc":"cornac","lemma":"cornac","pos":"NOM"} ,
		{"word":"cornaline","word_nosc":"cornaline","lemma":"cornaline","pos":"NOM"} ,
		{"word":"cornalines","word_nosc":"cornalines","lemma":"cornaline","pos":"NOM"} ,
		{"word":"cornard","word_nosc":"cornard","lemma":"cornard","pos":"NOM"} ,
		{"word":"cornards","word_nosc":"cornards","lemma":"cornard","pos":"NOM"} ,
		{"word":"corne","word_nosc":"corne","lemma":"corne","pos":"NOM"} ,
		{"word":"cornecul","word_nosc":"cornecul","lemma":"cornecul","pos":"NOM"} ,
		{"word":"corneille","word_nosc":"corneille","lemma":"corneille","pos":"NOM"} ,
		{"word":"corneilles","word_nosc":"corneilles","lemma":"corneille","pos":"NOM"} ,
		{"word":"cornemuse","word_nosc":"cornemuse","lemma":"cornemuse","pos":"NOM"} ,
		{"word":"cornemuses","word_nosc":"cornemuses","lemma":"cornemuse","pos":"NOM"} ,
		{"word":"cornemuseur","word_nosc":"cornemuseur","lemma":"cornemuseur","pos":"NOM"} ,
		{"word":"corner","word_nosc":"corner","lemma":"corner","pos":"NOM"} ,
		{"word":"corners","word_nosc":"corners","lemma":"corner","pos":"NOM"} ,
		{"word":"cornes","word_nosc":"cornes","lemma":"corne","pos":"NOM"} ,
		{"word":"cornet","word_nosc":"cornet","lemma":"cornet","pos":"NOM"} ,
		{"word":"cornets","word_nosc":"cornets","lemma":"cornet","pos":"NOM"} ,
		{"word":"cornette","word_nosc":"cornette","lemma":"cornette","pos":"NOM"} ,
		{"word":"cornettes","word_nosc":"cornettes","lemma":"cornette","pos":"NOM"} ,
		{"word":"corniaud","word_nosc":"corniaud","lemma":"corniaud","pos":"NOM"} ,
		{"word":"corniauds","word_nosc":"corniauds","lemma":"corniaud","pos":"NOM"} ,
		{"word":"corniche","word_nosc":"corniche","lemma":"corniche","pos":"NOM"} ,
		{"word":"corniches","word_nosc":"corniches","lemma":"corniche","pos":"NOM"} ,
		{"word":"cornichon","word_nosc":"cornichon","lemma":"cornichon","pos":"NOM"} ,
		{"word":"cornichons","word_nosc":"cornichons","lemma":"cornichon","pos":"NOM"} ,
		{"word":"cornillon","word_nosc":"cornillon","lemma":"cornillon","pos":"NOM"} ,
		{"word":"cornistes","word_nosc":"cornistes","lemma":"corniste","pos":"NOM"} ,
		{"word":"cornières","word_nosc":"cornieres","lemma":"cornière","pos":"NOM"} ,
		{"word":"cornouaillais","word_nosc":"cornouaillais","lemma":"cornouaillais","pos":"NOM"} ,
		{"word":"cornouille","word_nosc":"cornouille","lemma":"cornouille","pos":"NOM"} ,
		{"word":"cornouiller","word_nosc":"cornouiller","lemma":"cornouiller","pos":"NOM"} ,
		{"word":"cornouillers","word_nosc":"cornouillers","lemma":"cornouiller","pos":"NOM"} ,
		{"word":"cornouilles","word_nosc":"cornouilles","lemma":"cornouille","pos":"NOM"} ,
		{"word":"cornue","word_nosc":"cornue","lemma":"cornue","pos":"NOM"} ,
		{"word":"cornues","word_nosc":"cornues","lemma":"cornue","pos":"NOM"} ,
		{"word":"cornée","word_nosc":"cornee","lemma":"cornée","pos":"NOM"} ,
		{"word":"cornées","word_nosc":"cornees","lemma":"cornée","pos":"NOM"} ,
		{"word":"corollaire","word_nosc":"corollaire","lemma":"corollaire","pos":"NOM"} ,
		{"word":"corollaires","word_nosc":"corollaires","lemma":"corollaire","pos":"NOM"} ,
		{"word":"corolle","word_nosc":"corolle","lemma":"corolle","pos":"NOM"} ,
		{"word":"corolles","word_nosc":"corolles","lemma":"corolle","pos":"NOM"} ,
		{"word":"coron","word_nosc":"coron","lemma":"coron","pos":"NOM"} ,
		{"word":"corona","word_nosc":"corona","lemma":"corona","pos":"NOM"} ,
		{"word":"coronarien","word_nosc":"coronarien","lemma":"coronarien","pos":"NOM"} ,
		{"word":"coroner","word_nosc":"coroner","lemma":"coroner","pos":"NOM"} ,
		{"word":"coroners","word_nosc":"coroners","lemma":"coroner","pos":"NOM"} ,
		{"word":"coronilles","word_nosc":"coronilles","lemma":"coronille","pos":"NOM"} ,
		{"word":"corons","word_nosc":"corons","lemma":"coron","pos":"NOM"} ,
		{"word":"corozo","word_nosc":"corozo","lemma":"corozo","pos":"NOM"} ,
		{"word":"corporal","word_nosc":"corporal","lemma":"corporal","pos":"NOM"} ,
		{"word":"corporalité","word_nosc":"corporalite","lemma":"corporalité","pos":"NOM"} ,
		{"word":"corporation","word_nosc":"corporation","lemma":"corporation","pos":"NOM"} ,
		{"word":"corporations","word_nosc":"corporations","lemma":"corporation","pos":"NOM"} ,
		{"word":"corporatisme","word_nosc":"corporatisme","lemma":"corporatisme","pos":"NOM"} ,
		{"word":"corps","word_nosc":"corps","lemma":"corps","pos":"NOM"} ,
		{"word":"corps-mort","word_nosc":"corps-mort","lemma":"corps-mort","pos":"NOM"} ,
		{"word":"corpsard","word_nosc":"corpsard","lemma":"corpsard","pos":"NOM"} ,
		{"word":"corpulence","word_nosc":"corpulence","lemma":"corpulence","pos":"NOM"} ,
		{"word":"corpus","word_nosc":"corpus","lemma":"corpus","pos":"NOM"} ,
		{"word":"corpus delicti","word_nosc":"corpus delicti","lemma":"corpus delicti","pos":"NOM"} ,
		{"word":"corpuscule","word_nosc":"corpuscule","lemma":"corpuscule","pos":"NOM"} ,
		{"word":"corpuscules","word_nosc":"corpuscules","lemma":"corpuscule","pos":"NOM"} ,
		{"word":"corral","word_nosc":"corral","lemma":"corral","pos":"NOM"} ,
		{"word":"corrals","word_nosc":"corrals","lemma":"corral","pos":"NOM"} ,
		{"word":"correcteur","word_nosc":"correcteur","lemma":"correcteur","pos":"NOM"} ,
		{"word":"correcteurs","word_nosc":"correcteurs","lemma":"correcteur","pos":"NOM"} ,
		{"word":"correctif","word_nosc":"correctif","lemma":"correctif","pos":"NOM"} ,
		{"word":"correction","word_nosc":"correction","lemma":"correction","pos":"NOM"} ,
		{"word":"correctionnelle","word_nosc":"correctionnelle","lemma":"correctionnel","pos":"NOM"} ,
		{"word":"correctionnelles","word_nosc":"correctionnelles","lemma":"correctionnel","pos":"NOM"} ,
		{"word":"corrections","word_nosc":"corrections","lemma":"correction","pos":"NOM"} ,
		{"word":"corrector","word_nosc":"corrector","lemma":"corrector","pos":"NOM"} ,
		{"word":"corregidor","word_nosc":"corregidor","lemma":"corregidor","pos":"NOM"} ,
		{"word":"correspondance","word_nosc":"correspondance","lemma":"correspondance","pos":"NOM"} ,
		{"word":"correspondances","word_nosc":"correspondances","lemma":"correspondance","pos":"NOM"} ,
		{"word":"correspondancier","word_nosc":"correspondancier","lemma":"correspondancier","pos":"NOM"} ,
		{"word":"correspondant","word_nosc":"correspondant","lemma":"correspondant","pos":"NOM"} ,
		{"word":"correspondante","word_nosc":"correspondante","lemma":"correspondant","pos":"NOM"} ,
		{"word":"correspondantes","word_nosc":"correspondantes","lemma":"correspondant","pos":"NOM"} ,
		{"word":"correspondants","word_nosc":"correspondants","lemma":"correspondant","pos":"NOM"} ,
		{"word":"corrida","word_nosc":"corrida","lemma":"corrida","pos":"NOM"} ,
		{"word":"corridas","word_nosc":"corridas","lemma":"corrida","pos":"NOM"} ,
		{"word":"corridor","word_nosc":"corridor","lemma":"corridor","pos":"NOM"} ,
		{"word":"corridors","word_nosc":"corridors","lemma":"corridor","pos":"NOM"} ,
		{"word":"corrigé","word_nosc":"corrige","lemma":"corrigé","pos":"NOM"} ,
		{"word":"corrigés","word_nosc":"corriges","lemma":"corrigé","pos":"NOM"} ,
		{"word":"corroboration","word_nosc":"corroboration","lemma":"corroboration","pos":"NOM"} ,
		{"word":"corroierie","word_nosc":"corroierie","lemma":"corroierie","pos":"NOM"} ,
		{"word":"corrompu","word_nosc":"corrompu","lemma":"corrompu","pos":"NOM"} ,
		{"word":"corrompus","word_nosc":"corrompus","lemma":"corrompu","pos":"NOM"} ,
		{"word":"corrosion","word_nosc":"corrosion","lemma":"corrosion","pos":"NOM"} ,
		{"word":"corrupteur","word_nosc":"corrupteur","lemma":"corrupteur","pos":"NOM"} ,
		{"word":"corrupteurs","word_nosc":"corrupteurs","lemma":"corrupteur","pos":"NOM"} ,
		{"word":"corruption","word_nosc":"corruption","lemma":"corruption","pos":"NOM"} ,
		{"word":"corruptions","word_nosc":"corruptions","lemma":"corruption","pos":"NOM"} ,
		{"word":"corruptrice","word_nosc":"corruptrice","lemma":"corrupteur","pos":"NOM"} ,
		{"word":"corrélation","word_nosc":"correlation","lemma":"corrélation","pos":"NOM"} ,
		{"word":"cors","word_nosc":"cors","lemma":"cor","pos":"NOM"} ,
		{"word":"corsage","word_nosc":"corsage","lemma":"corsage","pos":"NOM"} ,
		{"word":"corsages","word_nosc":"corsages","lemma":"corsage","pos":"NOM"} ,
		{"word":"corsaire","word_nosc":"corsaire","lemma":"corsaire","pos":"NOM"} ,
		{"word":"corsaires","word_nosc":"corsaires","lemma":"corsaire","pos":"NOM"} ,
		{"word":"corse","word_nosc":"corse","lemma":"corse","pos":"NOM"} ,
		{"word":"corselet","word_nosc":"corselet","lemma":"corselet","pos":"NOM"} ,
		{"word":"corselets","word_nosc":"corselets","lemma":"corselet","pos":"NOM"} ,
		{"word":"corses","word_nosc":"corses","lemma":"corse","pos":"NOM"} ,
		{"word":"corset","word_nosc":"corset","lemma":"corset","pos":"NOM"} ,
		{"word":"corsetière","word_nosc":"corsetiere","lemma":"corsetier","pos":"NOM"} ,
		{"word":"corsets","word_nosc":"corsets","lemma":"corset","pos":"NOM"} ,
		{"word":"corso","word_nosc":"corso","lemma":"corso","pos":"NOM"} ,
		{"word":"corsos","word_nosc":"corsos","lemma":"corso","pos":"NOM"} ,
		{"word":"cortes","word_nosc":"cortes","lemma":"corte","pos":"NOM"} ,
		{"word":"cortex","word_nosc":"cortex","lemma":"cortex","pos":"NOM"} ,
		{"word":"cortine","word_nosc":"cortine","lemma":"cortine","pos":"NOM"} ,
		{"word":"cortisol","word_nosc":"cortisol","lemma":"cortisol","pos":"NOM"} ,
		{"word":"cortisone","word_nosc":"cortisone","lemma":"cortisone","pos":"NOM"} ,
		{"word":"corton","word_nosc":"corton","lemma":"corton","pos":"NOM"} ,
		{"word":"cortège","word_nosc":"cortege","lemma":"cortège","pos":"NOM"} ,
		{"word":"cortèges","word_nosc":"corteges","lemma":"cortège","pos":"NOM"} ,
		{"word":"cortès","word_nosc":"cortes","lemma":"cortès","pos":"NOM"} ,
		{"word":"coruscation","word_nosc":"coruscation","lemma":"coruscation","pos":"NOM"} ,
		{"word":"corvette","word_nosc":"corvette","lemma":"corvette","pos":"NOM"} ,
		{"word":"corvettes","word_nosc":"corvettes","lemma":"corvette","pos":"NOM"} ,
		{"word":"corvée","word_nosc":"corvee","lemma":"corvée","pos":"NOM"} ,
		{"word":"corvées","word_nosc":"corvees","lemma":"corvée","pos":"NOM"} ,
		{"word":"corybantes","word_nosc":"corybantes","lemma":"corybante","pos":"NOM"} ,
		{"word":"coryphène","word_nosc":"coryphene","lemma":"coryphène","pos":"NOM"} ,
		{"word":"coryphée","word_nosc":"coryphee","lemma":"coryphée","pos":"NOM"} ,
		{"word":"coryste","word_nosc":"coryste","lemma":"coryste","pos":"NOM"} ,
		{"word":"coryza","word_nosc":"coryza","lemma":"coryza","pos":"NOM"} ,
		{"word":"coré","word_nosc":"core","lemma":"coré","pos":"NOM"} ,
		{"word":"coréen","word_nosc":"coreen","lemma":"coréen","pos":"NOM"} ,
		{"word":"coréenne","word_nosc":"coreenne","lemma":"coréen","pos":"NOM"} ,
		{"word":"coréennes","word_nosc":"coreennes","lemma":"coréen","pos":"NOM"} ,
		{"word":"coréens","word_nosc":"coreens","lemma":"coréen","pos":"NOM"} ,
		{"word":"cosaque","word_nosc":"cosaque","lemma":"cosaque","pos":"NOM"} ,
		{"word":"cosaques","word_nosc":"cosaques","lemma":"cosaque","pos":"NOM"} ,
		{"word":"coseigneurs","word_nosc":"coseigneurs","lemma":"coseigneur","pos":"NOM"} ,
		{"word":"cosinus","word_nosc":"cosinus","lemma":"cosinus","pos":"NOM"} ,
		{"word":"cosmodromes","word_nosc":"cosmodromes","lemma":"cosmodrome","pos":"NOM"} ,
		{"word":"cosmogonie","word_nosc":"cosmogonie","lemma":"cosmogonie","pos":"NOM"} ,
		{"word":"cosmogonies","word_nosc":"cosmogonies","lemma":"cosmogonie","pos":"NOM"} ,
		{"word":"cosmographie","word_nosc":"cosmographie","lemma":"cosmographie","pos":"NOM"} ,
		{"word":"cosmologie","word_nosc":"cosmologie","lemma":"cosmologie","pos":"NOM"} ,
		{"word":"cosmologue","word_nosc":"cosmologue","lemma":"cosmologue","pos":"NOM"} ,
		{"word":"cosmonaute","word_nosc":"cosmonaute","lemma":"cosmonaute","pos":"NOM"} ,
		{"word":"cosmonautes","word_nosc":"cosmonautes","lemma":"cosmonaute","pos":"NOM"} ,
		{"word":"cosmopolite","word_nosc":"cosmopolite","lemma":"cosmopolite","pos":"NOM"} ,
		{"word":"cosmopolites","word_nosc":"cosmopolites","lemma":"cosmopolite","pos":"NOM"} ,
		{"word":"cosmopolitisme","word_nosc":"cosmopolitisme","lemma":"cosmopolitisme","pos":"NOM"} ,
		{"word":"cosmos","word_nosc":"cosmos","lemma":"cosmos","pos":"NOM"} ,
		{"word":"cosméticienne","word_nosc":"cosmeticienne","lemma":"cosméticien","pos":"NOM"} ,
		{"word":"cosmétique","word_nosc":"cosmetique","lemma":"cosmétique","pos":"NOM"} ,
		{"word":"cosmétiques","word_nosc":"cosmetiques","lemma":"cosmétique","pos":"NOM"} ,
		{"word":"cosmétologie","word_nosc":"cosmetologie","lemma":"cosmétologie","pos":"NOM"} ,
		{"word":"cosmétologue","word_nosc":"cosmetologue","lemma":"cosmétologue","pos":"NOM"} ,
		{"word":"cossard","word_nosc":"cossard","lemma":"cossard","pos":"NOM"} ,
		{"word":"cossarde","word_nosc":"cossarde","lemma":"cossard","pos":"NOM"} ,
		{"word":"cossards","word_nosc":"cossards","lemma":"cossard","pos":"NOM"} ,
		{"word":"cosse","word_nosc":"cosse","lemma":"cosse","pos":"NOM"} ,
		{"word":"cosses","word_nosc":"cosses","lemma":"cosse","pos":"NOM"} ,
		{"word":"cossus","word_nosc":"cossus","lemma":"cossus","pos":"NOM"} ,
		{"word":"costar","word_nosc":"costar","lemma":"costar","pos":"NOM"} ,
		{"word":"costard","word_nosc":"costard","lemma":"costard","pos":"NOM"} ,
		{"word":"costards","word_nosc":"costards","lemma":"costard","pos":"NOM"} ,
		{"word":"costaud","word_nosc":"costaud","lemma":"costaud","pos":"NOM"} ,
		{"word":"costauds","word_nosc":"costauds","lemma":"costaud","pos":"NOM"} ,
		{"word":"costume","word_nosc":"costume","lemma":"costume","pos":"NOM"} ,
		{"word":"costumes","word_nosc":"costumes","lemma":"costume","pos":"NOM"} ,
		{"word":"costumier","word_nosc":"costumier","lemma":"costumier","pos":"NOM"} ,
		{"word":"costumiers","word_nosc":"costumiers","lemma":"costumier","pos":"NOM"} ,
		{"word":"costumière","word_nosc":"costumiere","lemma":"costumier","pos":"NOM"} ,
		{"word":"cosy","word_nosc":"cosy","lemma":"cosy","pos":"NOM"} ,
		{"word":"cosy-corner","word_nosc":"cosy-corner","lemma":"cosy-corner","pos":"NOM"} ,
		{"word":"cosy-corners","word_nosc":"cosy-corners","lemma":"cosy-corner","pos":"NOM"} ,
		{"word":"cosys","word_nosc":"cosys","lemma":"cosy","pos":"NOM"} ,
		{"word":"cotation","word_nosc":"cotation","lemma":"cotation","pos":"NOM"} ,
		{"word":"cotations","word_nosc":"cotations","lemma":"cotation","pos":"NOM"} ,
		{"word":"cote","word_nosc":"cote","lemma":"cote","pos":"NOM"} ,
		{"word":"coteau","word_nosc":"coteau","lemma":"coteau","pos":"NOM"} ,
		{"word":"coteaux","word_nosc":"coteaux","lemma":"coteau","pos":"NOM"} ,
		{"word":"coterie","word_nosc":"coterie","lemma":"coterie","pos":"NOM"} ,
		{"word":"coteries","word_nosc":"coteries","lemma":"coterie","pos":"NOM"} ,
		{"word":"cotes","word_nosc":"cotes","lemma":"cote","pos":"NOM"} ,
		{"word":"cothurne","word_nosc":"cothurne","lemma":"cothurne","pos":"NOM"} ,
		{"word":"cothurnes","word_nosc":"cothurnes","lemma":"cothurne","pos":"NOM"} ,
		{"word":"cotillon","word_nosc":"cotillon","lemma":"cotillon","pos":"NOM"} ,
		{"word":"cotillons","word_nosc":"cotillons","lemma":"cotillon","pos":"NOM"} ,
		{"word":"cotisant","word_nosc":"cotisant","lemma":"cotisant","pos":"NOM"} ,
		{"word":"cotisants","word_nosc":"cotisants","lemma":"cotisant","pos":"NOM"} ,
		{"word":"cotisation","word_nosc":"cotisation","lemma":"cotisation","pos":"NOM"} ,
		{"word":"cotisations","word_nosc":"cotisations","lemma":"cotisation","pos":"NOM"} ,
		{"word":"coton","word_nosc":"coton","lemma":"coton","pos":"NOM"} ,
		{"word":"coton-tige","word_nosc":"coton-tige","lemma":"coton-tige","pos":"NOM"} ,
		{"word":"cotonnade","word_nosc":"cotonnade","lemma":"cotonnade","pos":"NOM"} ,
		{"word":"cotonnades","word_nosc":"cotonnades","lemma":"cotonnade","pos":"NOM"} ,
		{"word":"cotonnerie","word_nosc":"cotonnerie","lemma":"cotonnerie","pos":"NOM"} ,
		{"word":"cotonnier","word_nosc":"cotonnier","lemma":"cotonnier","pos":"NOM"} ,
		{"word":"cotonnière","word_nosc":"cotonniere","lemma":"cotonnière","pos":"NOM"} ,
		{"word":"cotons","word_nosc":"cotons","lemma":"coton","pos":"NOM"} ,
		{"word":"cotons-tiges","word_nosc":"cotons-tiges","lemma":"coton-tige","pos":"NOM"} ,
		{"word":"cotonéasters","word_nosc":"cotoneasters","lemma":"cotonéaster","pos":"NOM"} ,
		{"word":"cotre","word_nosc":"cotre","lemma":"cotre","pos":"NOM"} ,
		{"word":"cotres","word_nosc":"cotres","lemma":"cotre","pos":"NOM"} ,
		{"word":"cotrets","word_nosc":"cotrets","lemma":"cotret","pos":"NOM"} ,
		{"word":"cotriade","word_nosc":"cotriade","lemma":"cotriade","pos":"NOM"} ,
		{"word":"cottage","word_nosc":"cottage","lemma":"cottage","pos":"NOM"} ,
		{"word":"cottages","word_nosc":"cottages","lemma":"cottage","pos":"NOM"} ,
		{"word":"cotte","word_nosc":"cotte","lemma":"cotte","pos":"NOM"} ,
		{"word":"cottes","word_nosc":"cottes","lemma":"cotte","pos":"NOM"} ,
		{"word":"coturne","word_nosc":"coturne","lemma":"coturne","pos":"NOM"} ,
		{"word":"cotylédons","word_nosc":"cotyledons","lemma":"cotylédon","pos":"NOM"} ,
		{"word":"cou","word_nosc":"cou","lemma":"cou","pos":"NOM"} ,
		{"word":"cou-de-pied","word_nosc":"cou-de-pied","lemma":"cou-de-pied","pos":"NOM"} ,
		{"word":"couac","word_nosc":"couac","lemma":"couac","pos":"NOM"} ,
		{"word":"couacs","word_nosc":"couacs","lemma":"couac","pos":"NOM"} ,
		{"word":"couaille","word_nosc":"couaille","lemma":"couaille","pos":"NOM"} ,
		{"word":"couard","word_nosc":"couard","lemma":"couard","pos":"NOM"} ,
		{"word":"couardise","word_nosc":"couardise","lemma":"couardise","pos":"NOM"} ,
		{"word":"couards","word_nosc":"couards","lemma":"couard","pos":"NOM"} ,
		{"word":"couchage","word_nosc":"couchage","lemma":"couchage","pos":"NOM"} ,
		{"word":"couchant","word_nosc":"couchant","lemma":"couchant","pos":"NOM"} ,
		{"word":"couchants","word_nosc":"couchants","lemma":"couchant","pos":"NOM"} ,
		{"word":"couche","word_nosc":"couche","lemma":"couche","pos":"NOM"} ,
		{"word":"couche-culotte","word_nosc":"couche-culotte","lemma":"couche-culotte","pos":"NOM"} ,
		{"word":"coucher","word_nosc":"coucher","lemma":"coucher","pos":"NOM"} ,
		{"word":"coucherie","word_nosc":"coucherie","lemma":"coucherie","pos":"NOM"} ,
		{"word":"coucheries","word_nosc":"coucheries","lemma":"coucherie","pos":"NOM"} ,
		{"word":"couchers","word_nosc":"couchers","lemma":"coucher","pos":"NOM"} ,
		{"word":"couches","word_nosc":"couches","lemma":"couche","pos":"NOM"} ,
		{"word":"couches-culottes","word_nosc":"couches-culottes","lemma":"couche-culotte","pos":"NOM"} ,
		{"word":"couchette","word_nosc":"couchette","lemma":"couchette","pos":"NOM"} ,
		{"word":"couchettes","word_nosc":"couchettes","lemma":"couchette","pos":"NOM"} ,
		{"word":"coucheur","word_nosc":"coucheur","lemma":"coucheur","pos":"NOM"} ,
		{"word":"coucheurs","word_nosc":"coucheurs","lemma":"coucheur","pos":"NOM"} ,
		{"word":"coucheuse","word_nosc":"coucheuse","lemma":"coucheur","pos":"NOM"} ,
		{"word":"couchoir","word_nosc":"couchoir","lemma":"couchoir","pos":"NOM"} ,
		{"word":"couché","word_nosc":"couche","lemma":"couché","pos":"NOM"} ,
		{"word":"coucou","word_nosc":"coucou","lemma":"coucou","pos":"NOM"} ,
		{"word":"coucous","word_nosc":"coucous","lemma":"coucou","pos":"NOM"} ,
		{"word":"coude","word_nosc":"coude","lemma":"coude","pos":"NOM"} ,
		{"word":"coudes","word_nosc":"coudes","lemma":"coude","pos":"NOM"} ,
		{"word":"coudreuse","word_nosc":"coudreuse","lemma":"coudreuse","pos":"NOM"} ,
		{"word":"coudrier","word_nosc":"coudrier","lemma":"coudrier","pos":"NOM"} ,
		{"word":"coudriers","word_nosc":"coudriers","lemma":"coudrier","pos":"NOM"} ,
		{"word":"coudée","word_nosc":"coudee","lemma":"coudée","pos":"NOM"} ,
		{"word":"coudées","word_nosc":"coudees","lemma":"coudée","pos":"NOM"} ,
		{"word":"couenne","word_nosc":"couenne","lemma":"couenne","pos":"NOM"} ,
		{"word":"couennes","word_nosc":"couennes","lemma":"couenne","pos":"NOM"} ,
		{"word":"couette","word_nosc":"couette","lemma":"couette","pos":"NOM"} ,
		{"word":"couettes","word_nosc":"couettes","lemma":"couette","pos":"NOM"} ,
		{"word":"couffin","word_nosc":"couffin","lemma":"couffin","pos":"NOM"} ,
		{"word":"couffins","word_nosc":"couffins","lemma":"couffin","pos":"NOM"} ,
		{"word":"couguar","word_nosc":"couguar","lemma":"couguar","pos":"NOM"} ,
		{"word":"couguars","word_nosc":"couguars","lemma":"couguar","pos":"NOM"} ,
		{"word":"couic","word_nosc":"couic","lemma":"couic","pos":"NOM"} ,
		{"word":"couillard","word_nosc":"couillard","lemma":"couillard","pos":"NOM"} ,
		{"word":"couille","word_nosc":"couille","lemma":"couille","pos":"NOM"} ,
		{"word":"couilles","word_nosc":"couilles","lemma":"couille","pos":"NOM"} ,
		{"word":"couillettes","word_nosc":"couillettes","lemma":"couillette","pos":"NOM"} ,
		{"word":"couillon","word_nosc":"couillon","lemma":"couillon","pos":"NOM"} ,
		{"word":"couillonnade","word_nosc":"couillonnade","lemma":"couillonnade","pos":"NOM"} ,
		{"word":"couillonnades","word_nosc":"couillonnades","lemma":"couillonnade","pos":"NOM"} ,
		{"word":"couillons","word_nosc":"couillons","lemma":"couillon","pos":"NOM"} ,
		{"word":"couinement","word_nosc":"couinement","lemma":"couinement","pos":"NOM"} ,
		{"word":"couinements","word_nosc":"couinements","lemma":"couinement","pos":"NOM"} ,
		{"word":"coulage","word_nosc":"coulage","lemma":"coulage","pos":"NOM"} ,
		{"word":"coulant","word_nosc":"coulant","lemma":"coulant","pos":"NOM"} ,
		{"word":"coulants","word_nosc":"coulants","lemma":"coulant","pos":"NOM"} ,
		{"word":"coule","word_nosc":"coule","lemma":"coule","pos":"NOM"} ,
		{"word":"coulemelle","word_nosc":"coulemelle","lemma":"coulemelle","pos":"NOM"} ,
		{"word":"couleur","word_nosc":"couleur","lemma":"couleur","pos":"NOM"} ,
		{"word":"couleurs","word_nosc":"couleurs","lemma":"couleur","pos":"NOM"} ,
		{"word":"couleuvre","word_nosc":"couleuvre","lemma":"couleuvre","pos":"NOM"} ,
		{"word":"couleuvres","word_nosc":"couleuvres","lemma":"couleuvre","pos":"NOM"} ,
		{"word":"couleuvrine","word_nosc":"couleuvrine","lemma":"couleuvrine","pos":"NOM"} ,
		{"word":"couleuvrines","word_nosc":"couleuvrines","lemma":"couleuvrine","pos":"NOM"} ,
		{"word":"coulis","word_nosc":"coulis","lemma":"coulis","pos":"NOM"} ,
		{"word":"coulisse","word_nosc":"coulisse","lemma":"coulisse","pos":"NOM"} ,
		{"word":"coulissements","word_nosc":"coulissements","lemma":"coulissement","pos":"NOM"} ,
		{"word":"coulisses","word_nosc":"coulisses","lemma":"coulisse","pos":"NOM"} ,
		{"word":"coulissier","word_nosc":"coulissier","lemma":"coulissier","pos":"NOM"} ,
		{"word":"couloir","word_nosc":"couloir","lemma":"couloir","pos":"NOM"} ,
		{"word":"couloir-symbole","word_nosc":"couloir-symbole","lemma":"couloir-symbole","pos":"NOM"} ,
		{"word":"couloirs","word_nosc":"couloirs","lemma":"couloir","pos":"NOM"} ,
		{"word":"coulombs","word_nosc":"coulombs","lemma":"coulomb","pos":"NOM"} ,
		{"word":"coulpe","word_nosc":"coulpe","lemma":"coulpe","pos":"NOM"} ,
		{"word":"coulure","word_nosc":"coulure","lemma":"coulure","pos":"NOM"} ,
		{"word":"coulures","word_nosc":"coulures","lemma":"coulure","pos":"NOM"} ,
		{"word":"coulé","word_nosc":"coule","lemma":"coulé","pos":"NOM"} ,
		{"word":"coulée","word_nosc":"coulee","lemma":"coulée","pos":"NOM"} ,
		{"word":"coulées","word_nosc":"coulees","lemma":"coulée","pos":"NOM"} ,
		{"word":"coulés","word_nosc":"coules","lemma":"coulé","pos":"NOM"} ,
		{"word":"coumarine","word_nosc":"coumarine","lemma":"coumarine","pos":"NOM"} ,
		{"word":"countries","word_nosc":"countries","lemma":"countries","pos":"NOM"} ,
		{"word":"coup","word_nosc":"coup","lemma":"coup","pos":"NOM"} ,
		{"word":"coup-de-poing","word_nosc":"coup-de-poing","lemma":"coup-de-poing","pos":"NOM"} ,
		{"word":"coupable","word_nosc":"coupable","lemma":"coupable","pos":"NOM"} ,
		{"word":"coupables","word_nosc":"coupables","lemma":"coupable","pos":"NOM"} ,
		{"word":"coupage","word_nosc":"coupage","lemma":"coupage","pos":"NOM"} ,
		{"word":"coupages","word_nosc":"coupages","lemma":"coupage","pos":"NOM"} ,
		{"word":"coupe","word_nosc":"coupe","lemma":"coupe","pos":"NOM"} ,
		{"word":"coupe-chou","word_nosc":"coupe-chou","lemma":"coupe-chou","pos":"NOM"} ,
		{"word":"coupe-choux","word_nosc":"coupe-choux","lemma":"coupe-choux","pos":"NOM"} ,
		{"word":"coupe-cigare","word_nosc":"coupe-cigare","lemma":"coupe-cigare","pos":"NOM"} ,
		{"word":"coupe-cigares","word_nosc":"coupe-cigares","lemma":"coupe-cigare","pos":"NOM"} ,
		{"word":"coupe-circuit","word_nosc":"coupe-circuit","lemma":"coupe-circuit","pos":"NOM"} ,
		{"word":"coupe-circuits","word_nosc":"coupe-circuits","lemma":"coupe-circuit","pos":"NOM"} ,
		{"word":"coupe-coupe","word_nosc":"coupe-coupe","lemma":"coupe-coupe","pos":"NOM"} ,
		{"word":"coupe-faim","word_nosc":"coupe-faim","lemma":"coupe-faim","pos":"NOM"} ,
		{"word":"coupe-faims","word_nosc":"coupe-faims","lemma":"coupe-faim","pos":"NOM"} ,
		{"word":"coupe-feu","word_nosc":"coupe-feu","lemma":"coupe-feu","pos":"NOM"} ,
		{"word":"coupe-file","word_nosc":"coupe-file","lemma":"coupe-file","pos":"NOM"} ,
		{"word":"coupe-gorge","word_nosc":"coupe-gorge","lemma":"coupe-gorge","pos":"NOM"} ,
		{"word":"coupe-jarret","word_nosc":"coupe-jarret","lemma":"coupe-jarret","pos":"NOM"} ,
		{"word":"coupe-jarrets","word_nosc":"coupe-jarrets","lemma":"coupe-jarret","pos":"NOM"} ,
		{"word":"coupe-ongle","word_nosc":"coupe-ongle","lemma":"coupe-ongle","pos":"NOM"} ,
		{"word":"coupe-ongles","word_nosc":"coupe-ongles","lemma":"coupe-ongles","pos":"NOM"} ,
		{"word":"coupe-papier","word_nosc":"coupe-papier","lemma":"coupe-papier","pos":"NOM"} ,
		{"word":"coupe-pâte","word_nosc":"coupe-pate","lemma":"coupe-pâte","pos":"NOM"} ,
		{"word":"coupe-racines","word_nosc":"coupe-racines","lemma":"coupe-racine","pos":"NOM"} ,
		{"word":"coupe-vent","word_nosc":"coupe-vent","lemma":"coupe-vent","pos":"NOM"} ,
		{"word":"coupelle","word_nosc":"coupelle","lemma":"coupelle","pos":"NOM"} ,
		{"word":"coupelles","word_nosc":"coupelles","lemma":"coupelle","pos":"NOM"} ,
		{"word":"couperet","word_nosc":"couperet","lemma":"couperet","pos":"NOM"} ,
		{"word":"couperets","word_nosc":"couperets","lemma":"couperet","pos":"NOM"} ,
		{"word":"couperose","word_nosc":"couperose","lemma":"couperose","pos":"NOM"} ,
		{"word":"coupes","word_nosc":"coupes","lemma":"coupe","pos":"NOM"} ,
		{"word":"coupeur","word_nosc":"coupeur","lemma":"coupeur","pos":"NOM"} ,
		{"word":"coupeurs","word_nosc":"coupeurs","lemma":"coupeur","pos":"NOM"} ,
		{"word":"coupeuse","word_nosc":"coupeuse","lemma":"coupeur","pos":"NOM"} ,
		{"word":"couplage","word_nosc":"couplage","lemma":"couplage","pos":"NOM"} ,
		{"word":"couplages","word_nosc":"couplages","lemma":"couplage","pos":"NOM"} ,
		{"word":"couple","word_nosc":"couple","lemma":"couple","pos":"NOM"} ,
		{"word":"couples","word_nosc":"couples","lemma":"couple","pos":"NOM"} ,
		{"word":"couplet","word_nosc":"couplet","lemma":"couplet","pos":"NOM"} ,
		{"word":"couplets","word_nosc":"couplets","lemma":"couplet","pos":"NOM"} ,
		{"word":"coupleur","word_nosc":"coupleur","lemma":"coupleur","pos":"NOM"} ,
		{"word":"coupleurs","word_nosc":"coupleurs","lemma":"coupleur","pos":"NOM"} ,
		{"word":"couplé","word_nosc":"couple","lemma":"couplé","pos":"NOM"} ,
		{"word":"coupole","word_nosc":"coupole","lemma":"coupole","pos":"NOM"} ,
		{"word":"coupoles","word_nosc":"coupoles","lemma":"coupole","pos":"NOM"} ,
		{"word":"coupon","word_nosc":"coupon","lemma":"coupon","pos":"NOM"} ,
		{"word":"coupon-cadeau","word_nosc":"coupon-cadeau","lemma":"coupon-cadeau","pos":"NOM"} ,
		{"word":"coupons","word_nosc":"coupons","lemma":"coupon","pos":"NOM"} ,
		{"word":"coups","word_nosc":"coups","lemma":"coup","pos":"NOM"} ,
		{"word":"coups-de-poing","word_nosc":"coups-de-poing","lemma":"coups-de-poing","pos":"NOM"} ,
		{"word":"coupure","word_nosc":"coupure","lemma":"coupure","pos":"NOM"} ,
		{"word":"coupures","word_nosc":"coupures","lemma":"coupure","pos":"NOM"} ,
		{"word":"coupé","word_nosc":"coupe","lemma":"coupé","pos":"NOM"} ,
		{"word":"coupée","word_nosc":"coupee","lemma":"coupée","pos":"NOM"} ,
		{"word":"coupées","word_nosc":"coupees","lemma":"coupée","pos":"NOM"} ,
		{"word":"coupés","word_nosc":"coupes","lemma":"coupé","pos":"NOM"} ,
		{"word":"couques","word_nosc":"couques","lemma":"couque","pos":"NOM"} ,
		{"word":"cour","word_nosc":"cour","lemma":"cour","pos":"NOM"} ,
		{"word":"cour-jardin","word_nosc":"cour-jardin","lemma":"cour-jardin","pos":"NOM"} ,
		{"word":"courage","word_nosc":"courage","lemma":"courage","pos":"NOM"} ,
		{"word":"courages","word_nosc":"courages","lemma":"courage","pos":"NOM"} ,
		{"word":"courant","word_nosc":"courant","lemma":"courant","pos":"NOM"} ,
		{"word":"courante","word_nosc":"courante","lemma":"courante","pos":"NOM"} ,
		{"word":"courantes","word_nosc":"courantes","lemma":"courante","pos":"NOM"} ,
		{"word":"courants","word_nosc":"courants","lemma":"courant","pos":"NOM"} ,
		{"word":"courbature","word_nosc":"courbature","lemma":"courbature","pos":"NOM"} ,
		{"word":"courbatures","word_nosc":"courbatures","lemma":"courbature","pos":"NOM"} ,
		{"word":"courbe","word_nosc":"courbe","lemma":"courbe","pos":"NOM"} ,
		{"word":"courbes","word_nosc":"courbes","lemma":"courbe","pos":"NOM"} ,
		{"word":"courbette","word_nosc":"courbette","lemma":"courbette","pos":"NOM"} ,
		{"word":"courbettes","word_nosc":"courbettes","lemma":"courbette","pos":"NOM"} ,
		{"word":"courbure","word_nosc":"courbure","lemma":"courbure","pos":"NOM"} ,
		{"word":"courbures","word_nosc":"courbures","lemma":"courbure","pos":"NOM"} ,
		{"word":"courette","word_nosc":"courette","lemma":"courette","pos":"NOM"} ,
		{"word":"courettes","word_nosc":"courettes","lemma":"courette","pos":"NOM"} ,
		{"word":"coureur","word_nosc":"coureur","lemma":"coureur","pos":"NOM"} ,
		{"word":"coureurs","word_nosc":"coureurs","lemma":"coureur","pos":"NOM"} ,
		{"word":"coureuse","word_nosc":"coureuse","lemma":"coureur","pos":"NOM"} ,
		{"word":"coureuses","word_nosc":"coureuses","lemma":"coureur","pos":"NOM"} ,
		{"word":"courge","word_nosc":"courge","lemma":"courge","pos":"NOM"} ,
		{"word":"courges","word_nosc":"courges","lemma":"courge","pos":"NOM"} ,
		{"word":"courgette","word_nosc":"courgette","lemma":"courgette","pos":"NOM"} ,
		{"word":"courgettes","word_nosc":"courgettes","lemma":"courgette","pos":"NOM"} ,
		{"word":"courlis","word_nosc":"courlis","lemma":"courlis","pos":"NOM"} ,
		{"word":"couronne","word_nosc":"couronne","lemma":"couronne","pos":"NOM"} ,
		{"word":"couronnement","word_nosc":"couronnement","lemma":"couronnement","pos":"NOM"} ,
		{"word":"couronnements","word_nosc":"couronnements","lemma":"couronnement","pos":"NOM"} ,
		{"word":"couronnes","word_nosc":"couronnes","lemma":"couronne","pos":"NOM"} ,
		{"word":"courriel","word_nosc":"courriel","lemma":"courriel","pos":"NOM"} ,
		{"word":"courrier","word_nosc":"courrier","lemma":"courrier","pos":"NOM"} ,
		{"word":"courriers","word_nosc":"courriers","lemma":"courrier","pos":"NOM"} ,
		{"word":"courriériste","word_nosc":"courrieriste","lemma":"courriériste","pos":"NOM"} ,
		{"word":"courroie","word_nosc":"courroie","lemma":"courroie","pos":"NOM"} ,
		{"word":"courroies","word_nosc":"courroies","lemma":"courroie","pos":"NOM"} ,
		{"word":"courroux","word_nosc":"courroux","lemma":"courroux","pos":"NOM"} ,
		{"word":"cours","word_nosc":"cours","lemma":"cours","pos":"NOM"} ,
		{"word":"course","word_nosc":"course","lemma":"course","pos":"NOM"} ,
		{"word":"course-poursuite","word_nosc":"course-poursuite","lemma":"course-poursuite","pos":"NOM"} ,
		{"word":"courses","word_nosc":"courses","lemma":"course","pos":"NOM"} ,
		{"word":"coursier","word_nosc":"coursier","lemma":"coursier","pos":"NOM"} ,
		{"word":"coursiers","word_nosc":"coursiers","lemma":"coursier","pos":"NOM"} ,
		{"word":"coursive","word_nosc":"coursive","lemma":"coursive","pos":"NOM"} ,
		{"word":"coursives","word_nosc":"coursives","lemma":"coursive","pos":"NOM"} ,
		{"word":"coursière","word_nosc":"coursiere","lemma":"coursier","pos":"NOM"} ,
		{"word":"court","word_nosc":"court","lemma":"court","pos":"NOM"} ,
		{"word":"court-bouillon","word_nosc":"court-bouillon","lemma":"court-bouillon","pos":"NOM"} ,
		{"word":"court-circuit","word_nosc":"court-circuit","lemma":"court-circuit","pos":"NOM"} ,
		{"word":"court-circuits","word_nosc":"court-circuits","lemma":"court-circuits","pos":"NOM"} ,
		{"word":"court-courrier","word_nosc":"court-courrier","lemma":"court-courrier","pos":"NOM"} ,
		{"word":"court-jus","word_nosc":"court-jus","lemma":"court-jus","pos":"NOM"} ,
		{"word":"court-métrage","word_nosc":"court-metrage","lemma":"court-métrage","pos":"NOM"} ,
		{"word":"courtage","word_nosc":"courtage","lemma":"courtage","pos":"NOM"} ,
		{"word":"courtages","word_nosc":"courtages","lemma":"courtage","pos":"NOM"} ,
		{"word":"courtaud","word_nosc":"courtaud","lemma":"courtaud","pos":"NOM"} ,
		{"word":"courtaude","word_nosc":"courtaude","lemma":"courtaud","pos":"NOM"} ,
		{"word":"courtepointe","word_nosc":"courtepointe","lemma":"courtepointe","pos":"NOM"} ,
		{"word":"courtepointes","word_nosc":"courtepointes","lemma":"courtepointe","pos":"NOM"} ,
		{"word":"courtier","word_nosc":"courtier","lemma":"courtier","pos":"NOM"} ,
		{"word":"courtiers","word_nosc":"courtiers","lemma":"courtier","pos":"NOM"} ,
		{"word":"courtilière","word_nosc":"courtiliere","lemma":"courtilière","pos":"NOM"} ,
		{"word":"courtilières","word_nosc":"courtilieres","lemma":"courtilière","pos":"NOM"} ,
		{"word":"courtils","word_nosc":"courtils","lemma":"courtil","pos":"NOM"} ,
		{"word":"courtine","word_nosc":"courtine","lemma":"courtine","pos":"NOM"} ,
		{"word":"courtines","word_nosc":"courtines","lemma":"courtine","pos":"NOM"} ,
		{"word":"courtisan","word_nosc":"courtisan","lemma":"courtisan","pos":"NOM"} ,
		{"word":"courtisane","word_nosc":"courtisane","lemma":"courtisan","pos":"NOM"} ,
		{"word":"courtisanerie","word_nosc":"courtisanerie","lemma":"courtisanerie","pos":"NOM"} ,
		{"word":"courtisanes","word_nosc":"courtisanes","lemma":"courtisan","pos":"NOM"} ,
		{"word":"courtisans","word_nosc":"courtisans","lemma":"courtisan","pos":"NOM"} ,
		{"word":"courtière","word_nosc":"courtiere","lemma":"courtier","pos":"NOM"} ,
		{"word":"courtières","word_nosc":"courtieres","lemma":"courtier","pos":"NOM"} ,
		{"word":"courtoisie","word_nosc":"courtoisie","lemma":"courtoisie","pos":"NOM"} ,
		{"word":"courtoisies","word_nosc":"courtoisies","lemma":"courtoisie","pos":"NOM"} ,
		{"word":"courts","word_nosc":"courts","lemma":"court","pos":"NOM"} ,
		{"word":"courts-circuits","word_nosc":"courts-circuits","lemma":"court-circuit","pos":"NOM"} ,
		{"word":"cous","word_nosc":"cous","lemma":"cou","pos":"NOM"} ,
		{"word":"cous-de-pied","word_nosc":"cous-de-pied","lemma":"cous-de-pied","pos":"NOM"} ,
		{"word":"couscous","word_nosc":"couscous","lemma":"couscous","pos":"NOM"} ,
		{"word":"couscoussier","word_nosc":"couscoussier","lemma":"couscoussier","pos":"NOM"} ,
		{"word":"couscoussiers","word_nosc":"couscoussiers","lemma":"couscoussier","pos":"NOM"} ,
		{"word":"cousette","word_nosc":"cousette","lemma":"cousette","pos":"NOM"} ,
		{"word":"cousettes","word_nosc":"cousettes","lemma":"cousette","pos":"NOM"} ,
		{"word":"couseuse","word_nosc":"couseuse","lemma":"couseur","pos":"NOM"} ,
		{"word":"cousin","word_nosc":"cousin","lemma":"cousin","pos":"NOM"} ,
		{"word":"cousinage","word_nosc":"cousinage","lemma":"cousinage","pos":"NOM"} ,
		{"word":"cousinages","word_nosc":"cousinages","lemma":"cousinage","pos":"NOM"} ,
		{"word":"cousine","word_nosc":"cousine","lemma":"cousin","pos":"NOM"} ,
		{"word":"cousines","word_nosc":"cousines","lemma":"cousin","pos":"NOM"} ,
		{"word":"cousins","word_nosc":"cousins","lemma":"cousin","pos":"NOM"} ,
		{"word":"coussin","word_nosc":"coussin","lemma":"coussin","pos":"NOM"} ,
		{"word":"coussinet","word_nosc":"coussinet","lemma":"coussinet","pos":"NOM"} ,
		{"word":"coussinets","word_nosc":"coussinets","lemma":"coussinet","pos":"NOM"} ,
		{"word":"coussins","word_nosc":"coussins","lemma":"coussin","pos":"NOM"} ,
		{"word":"couteau","word_nosc":"couteau","lemma":"couteau","pos":"NOM"} ,
		{"word":"couteau-scie","word_nosc":"couteau-scie","lemma":"couteau-scie","pos":"NOM"} ,
		{"word":"couteaux","word_nosc":"couteaux","lemma":"couteau","pos":"NOM"} ,
		{"word":"coutelas","word_nosc":"coutelas","lemma":"coutelas","pos":"NOM"} ,
		{"word":"coutelier","word_nosc":"coutelier","lemma":"coutelier","pos":"NOM"} ,
		{"word":"coutellerie","word_nosc":"coutellerie","lemma":"coutellerie","pos":"NOM"} ,
		{"word":"coutelleries","word_nosc":"coutelleries","lemma":"coutellerie","pos":"NOM"} ,
		{"word":"coutil","word_nosc":"coutil","lemma":"coutil","pos":"NOM"} ,
		{"word":"coutume","word_nosc":"coutume","lemma":"coutume","pos":"NOM"} ,
		{"word":"coutumes","word_nosc":"coutumes","lemma":"coutume","pos":"NOM"} ,
		{"word":"coutumier","word_nosc":"coutumier","lemma":"coutumier","pos":"NOM"} ,
		{"word":"coutumiers","word_nosc":"coutumiers","lemma":"coutumier","pos":"NOM"} ,
		{"word":"couture","word_nosc":"couture","lemma":"couture","pos":"NOM"} ,
		{"word":"coutures","word_nosc":"coutures","lemma":"couture","pos":"NOM"} ,
		{"word":"couturier","word_nosc":"couturier","lemma":"couturier","pos":"NOM"} ,
		{"word":"couturiers","word_nosc":"couturiers","lemma":"couturier","pos":"NOM"} ,
		{"word":"couturière","word_nosc":"couturiere","lemma":"couturier","pos":"NOM"} ,
		{"word":"couturières","word_nosc":"couturieres","lemma":"couturier","pos":"NOM"} ,
		{"word":"couvade","word_nosc":"couvade","lemma":"couvade","pos":"NOM"} ,
		{"word":"couvaison","word_nosc":"couvaison","lemma":"couvaison","pos":"NOM"} ,
		{"word":"couvaisons","word_nosc":"couvaisons","lemma":"couvaison","pos":"NOM"} ,
		{"word":"couvent","word_nosc":"couvent","lemma":"couvent","pos":"NOM"} ,
		{"word":"couventine","word_nosc":"couventine","lemma":"couventine","pos":"NOM"} ,
		{"word":"couventines","word_nosc":"couventines","lemma":"couventine","pos":"NOM"} ,
		{"word":"couvents","word_nosc":"couvents","lemma":"couvent","pos":"NOM"} ,
		{"word":"couvercle","word_nosc":"couvercle","lemma":"couvercle","pos":"NOM"} ,
		{"word":"couvercles","word_nosc":"couvercles","lemma":"couvercle","pos":"NOM"} ,
		{"word":"couvert","word_nosc":"couvert","lemma":"couvert","pos":"NOM"} ,
		{"word":"couverts","word_nosc":"couverts","lemma":"couvert","pos":"NOM"} ,
		{"word":"couverture","word_nosc":"couverture","lemma":"couverture","pos":"NOM"} ,
		{"word":"couvertures","word_nosc":"couvertures","lemma":"couverture","pos":"NOM"} ,
		{"word":"couvet","word_nosc":"couvet","lemma":"couvet","pos":"NOM"} ,
		{"word":"couveuse","word_nosc":"couveuse","lemma":"couveuse","pos":"NOM"} ,
		{"word":"couveuses","word_nosc":"couveuses","lemma":"couveuse","pos":"NOM"} ,
		{"word":"couvre-chef","word_nosc":"couvre-chef","lemma":"couvre-chef","pos":"NOM"} ,
		{"word":"couvre-chefs","word_nosc":"couvre-chefs","lemma":"couvre-chef","pos":"NOM"} ,
		{"word":"couvre-feu","word_nosc":"couvre-feu","lemma":"couvre-feu","pos":"NOM"} ,
		{"word":"couvre-feux","word_nosc":"couvre-feux","lemma":"couvre-feux","pos":"NOM"} ,
		{"word":"couvre-lit","word_nosc":"couvre-lit","lemma":"couvre-lit","pos":"NOM"} ,
		{"word":"couvre-lits","word_nosc":"couvre-lits","lemma":"couvre-lit","pos":"NOM"} ,
		{"word":"couvre-pied","word_nosc":"couvre-pied","lemma":"couvre-pied","pos":"NOM"} ,
		{"word":"couvre-pieds","word_nosc":"couvre-pieds","lemma":"couvre-pieds","pos":"NOM"} ,
		{"word":"couvreur","word_nosc":"couvreur","lemma":"couvreur","pos":"NOM"} ,
		{"word":"couvreurs","word_nosc":"couvreurs","lemma":"couvreur","pos":"NOM"} ,
		{"word":"couvée","word_nosc":"couvee","lemma":"couvée","pos":"NOM"} ,
		{"word":"couvées","word_nosc":"couvees","lemma":"couvée","pos":"NOM"} ,
		{"word":"covenant","word_nosc":"covenant","lemma":"covenant","pos":"NOM"} ,
		{"word":"cover-girl","word_nosc":"cover-girl","lemma":"cover-girl","pos":"NOM"} ,
		{"word":"cover-girls","word_nosc":"cover-girls","lemma":"cover-girl","pos":"NOM"} ,
		{"word":"covoiturage","word_nosc":"covoiturage","lemma":"covoiturage","pos":"NOM"} ,
		{"word":"cow-boy","word_nosc":"cow-boy","lemma":"cow-boy","pos":"NOM"} ,
		{"word":"cow-boys","word_nosc":"cow-boys","lemma":"cow-boy","pos":"NOM"} ,
		{"word":"coxalgie","word_nosc":"coxalgie","lemma":"coxalgie","pos":"NOM"} ,
		{"word":"coyote","word_nosc":"coyote","lemma":"coyote","pos":"NOM"} ,
		{"word":"coyotes","word_nosc":"coyotes","lemma":"coyote","pos":"NOM"} ,
		{"word":"coéquipier","word_nosc":"coequipier","lemma":"coéquipier","pos":"NOM"} ,
		{"word":"coéquipiers","word_nosc":"coequipiers","lemma":"coéquipier","pos":"NOM"} ,
		{"word":"coéquipière","word_nosc":"coequipiere","lemma":"coéquipier","pos":"NOM"} ,
		{"word":"coéquipières","word_nosc":"coequipieres","lemma":"coéquipier","pos":"NOM"} ,
		{"word":"coïncidence","word_nosc":"coincidence","lemma":"coïncidence","pos":"NOM"} ,
		{"word":"coïncidences","word_nosc":"coincidences","lemma":"coïncidence","pos":"NOM"} ,
		{"word":"coït","word_nosc":"coit","lemma":"coït","pos":"NOM"} ,
		{"word":"coïts","word_nosc":"coits","lemma":"coït","pos":"NOM"} ,
		{"word":"coût","word_nosc":"cout","lemma":"coût","pos":"NOM"} ,
		{"word":"coûts","word_nosc":"couts","lemma":"coût","pos":"NOM"} ,
		{"word":"crabe","word_nosc":"crabe","lemma":"crabe","pos":"NOM"} ,
		{"word":"crabes","word_nosc":"crabes","lemma":"crabe","pos":"NOM"} ,
		{"word":"crabillon","word_nosc":"crabillon","lemma":"crabillon","pos":"NOM"} ,
		{"word":"crabillons","word_nosc":"crabillons","lemma":"crabillon","pos":"NOM"} ,
		{"word":"crabs","word_nosc":"crabs","lemma":"crabs","pos":"NOM"} ,
		{"word":"crac","word_nosc":"crac","lemma":"crac","pos":"NOM"} ,
		{"word":"crachat","word_nosc":"crachat","lemma":"crachat","pos":"NOM"} ,
		{"word":"crachats","word_nosc":"crachats","lemma":"crachat","pos":"NOM"} ,
		{"word":"crachement","word_nosc":"crachement","lemma":"crachement","pos":"NOM"} ,
		{"word":"crachements","word_nosc":"crachements","lemma":"crachement","pos":"NOM"} ,
		{"word":"cracheur","word_nosc":"cracheur","lemma":"cracheur","pos":"NOM"} ,
		{"word":"cracheurs","word_nosc":"cracheurs","lemma":"cracheur","pos":"NOM"} ,
		{"word":"cracheuse","word_nosc":"cracheuse","lemma":"cracheur","pos":"NOM"} ,
		{"word":"cracheuses","word_nosc":"cracheuses","lemma":"cracheur","pos":"NOM"} ,
		{"word":"crachin","word_nosc":"crachin","lemma":"crachin","pos":"NOM"} ,
		{"word":"crachins","word_nosc":"crachins","lemma":"crachin","pos":"NOM"} ,
		{"word":"crachoir","word_nosc":"crachoir","lemma":"crachoir","pos":"NOM"} ,
		{"word":"crachoirs","word_nosc":"crachoirs","lemma":"crachoir","pos":"NOM"} ,
		{"word":"crachotement","word_nosc":"crachotement","lemma":"crachotement","pos":"NOM"} ,
		{"word":"crachotements","word_nosc":"crachotements","lemma":"crachotement","pos":"NOM"} ,
		{"word":"crachouillis","word_nosc":"crachouillis","lemma":"crachouillis","pos":"NOM"} ,
		{"word":"crack","word_nosc":"crack","lemma":"crack","pos":"NOM"} ,
		{"word":"cracker","word_nosc":"cracker","lemma":"cracker","pos":"NOM"} ,
		{"word":"crackers","word_nosc":"crackers","lemma":"cracker","pos":"NOM"} ,
		{"word":"cracking","word_nosc":"cracking","lemma":"cracking","pos":"NOM"} ,
		{"word":"cracks","word_nosc":"cracks","lemma":"crack","pos":"NOM"} ,
		{"word":"craie","word_nosc":"craie","lemma":"craie","pos":"NOM"} ,
		{"word":"craies","word_nosc":"craies","lemma":"craie","pos":"NOM"} ,
		{"word":"crainte","word_nosc":"crainte","lemma":"crainte","pos":"NOM"} ,
		{"word":"craintes","word_nosc":"craintes","lemma":"crainte","pos":"NOM"} ,
		{"word":"cramouille","word_nosc":"cramouille","lemma":"cramouille","pos":"NOM"} ,
		{"word":"cramouilles","word_nosc":"cramouilles","lemma":"cramouille","pos":"NOM"} ,
		{"word":"crampe","word_nosc":"crampe","lemma":"crampe","pos":"NOM"} ,
		{"word":"crampes","word_nosc":"crampes","lemma":"crampe","pos":"NOM"} ,
		{"word":"crampette","word_nosc":"crampette","lemma":"crampette","pos":"NOM"} ,
		{"word":"crampettes","word_nosc":"crampettes","lemma":"crampette","pos":"NOM"} ,
		{"word":"crampon","word_nosc":"crampon","lemma":"crampon","pos":"NOM"} ,
		{"word":"crampons","word_nosc":"crampons","lemma":"crampon","pos":"NOM"} ,
		{"word":"cran","word_nosc":"cran","lemma":"cran","pos":"NOM"} ,
		{"word":"crane","word_nosc":"crane","lemma":"crane","pos":"NOM"} ,
		{"word":"craniométrie","word_nosc":"craniometrie","lemma":"craniométrie","pos":"NOM"} ,
		{"word":"craniotomie","word_nosc":"craniotomie","lemma":"craniotomie","pos":"NOM"} ,
		{"word":"crans","word_nosc":"crans","lemma":"cran","pos":"NOM"} ,
		{"word":"crapaud","word_nosc":"crapaud","lemma":"crapaud","pos":"NOM"} ,
		{"word":"crapaud-buffle","word_nosc":"crapaud-buffle","lemma":"crapaud-buffle","pos":"NOM"} ,
		{"word":"crapauds","word_nosc":"crapauds","lemma":"crapaud","pos":"NOM"} ,
		{"word":"crapauds-buffles","word_nosc":"crapauds-buffles","lemma":"crapaud-buffle","pos":"NOM"} ,
		{"word":"crapouillot","word_nosc":"crapouillot","lemma":"crapouillot","pos":"NOM"} ,
		{"word":"crapouillots","word_nosc":"crapouillots","lemma":"crapouillot","pos":"NOM"} ,
		{"word":"craps","word_nosc":"craps","lemma":"craps","pos":"NOM"} ,
		{"word":"crapule","word_nosc":"crapule","lemma":"crapule","pos":"NOM"} ,
		{"word":"crapulerie","word_nosc":"crapulerie","lemma":"crapulerie","pos":"NOM"} ,
		{"word":"crapules","word_nosc":"crapules","lemma":"crapule","pos":"NOM"} ,
		{"word":"craquage","word_nosc":"craquage","lemma":"craquage","pos":"NOM"} ,
		{"word":"craque","word_nosc":"craque","lemma":"craque","pos":"NOM"} ,
		{"word":"craquelin","word_nosc":"craquelin","lemma":"craquelin","pos":"NOM"} ,
		{"word":"craquelins","word_nosc":"craquelins","lemma":"craquelin","pos":"NOM"} ,
		{"word":"craquelure","word_nosc":"craquelure","lemma":"craquelure","pos":"NOM"} ,
		{"word":"craquelures","word_nosc":"craquelures","lemma":"craquelure","pos":"NOM"} ,
		{"word":"craquement","word_nosc":"craquement","lemma":"craquement","pos":"NOM"} ,
		{"word":"craquements","word_nosc":"craquements","lemma":"craquement","pos":"NOM"} ,
		{"word":"craques","word_nosc":"craques","lemma":"craque","pos":"NOM"} ,
		{"word":"craquèlement","word_nosc":"craquelement","lemma":"craquèlement","pos":"NOM"} ,
		{"word":"craquée","word_nosc":"craquee","lemma":"craquée","pos":"NOM"} ,
		{"word":"crase","word_nosc":"crase","lemma":"crase","pos":"NOM"} ,
		{"word":"crash","word_nosc":"crash","lemma":"crash","pos":"NOM"} ,
		{"word":"crash-test","word_nosc":"crash-test","lemma":"crash-test","pos":"NOM"} ,
		{"word":"crashes","word_nosc":"crashes","lemma":"crashe","pos":"NOM"} ,
		{"word":"crasse","word_nosc":"crasse","lemma":"crasse","pos":"NOM"} ,
		{"word":"crasses","word_nosc":"crasses","lemma":"crasse","pos":"NOM"} ,
		{"word":"crassier","word_nosc":"crassier","lemma":"crassier","pos":"NOM"} ,
		{"word":"crassiers","word_nosc":"crassiers","lemma":"crassier","pos":"NOM"} ,
		{"word":"crassula","word_nosc":"crassula","lemma":"crassula","pos":"NOM"} ,
		{"word":"crataegus","word_nosc":"crataegus","lemma":"crataegus","pos":"NOM"} ,
		{"word":"cratère","word_nosc":"cratere","lemma":"cratère","pos":"NOM"} ,
		{"word":"cratères","word_nosc":"crateres","lemma":"cratère","pos":"NOM"} ,
		{"word":"cravache","word_nosc":"cravache","lemma":"cravache","pos":"NOM"} ,
		{"word":"cravaches","word_nosc":"cravaches","lemma":"cravache","pos":"NOM"} ,
		{"word":"cravant","word_nosc":"cravant","lemma":"cravant","pos":"NOM"} ,
		{"word":"cravants","word_nosc":"cravants","lemma":"cravant","pos":"NOM"} ,
		{"word":"cravatage","word_nosc":"cravatage","lemma":"cravatage","pos":"NOM"} ,
		{"word":"cravate","word_nosc":"cravate","lemma":"cravate","pos":"NOM"} ,
		{"word":"cravates","word_nosc":"cravates","lemma":"cravate","pos":"NOM"} ,
		{"word":"cravateur","word_nosc":"cravateur","lemma":"cravateur","pos":"NOM"} ,
		{"word":"cravatière","word_nosc":"cravatiere","lemma":"cravatier","pos":"NOM"} ,
		{"word":"crawl","word_nosc":"crawl","lemma":"crawl","pos":"NOM"} ,
		{"word":"crayon","word_nosc":"crayon","lemma":"crayon","pos":"NOM"} ,
		{"word":"crayon-encre","word_nosc":"crayon-encre","lemma":"crayon-encre","pos":"NOM"} ,
		{"word":"crayonnage","word_nosc":"crayonnage","lemma":"crayonnage","pos":"NOM"} ,
		{"word":"crayonnages","word_nosc":"crayonnages","lemma":"crayonnage","pos":"NOM"} ,
		{"word":"crayonneur","word_nosc":"crayonneur","lemma":"crayonneur","pos":"NOM"} ,
		{"word":"crayonneurs","word_nosc":"crayonneurs","lemma":"crayonneur","pos":"NOM"} ,
		{"word":"crayons","word_nosc":"crayons","lemma":"crayon","pos":"NOM"} ,
		{"word":"crayons-feutres","word_nosc":"crayons-feutres","lemma":"crayon-feutre","pos":"NOM"} ,
		{"word":"credo","word_nosc":"credo","lemma":"credo","pos":"NOM"} ,
		{"word":"creek","word_nosc":"creek","lemma":"creek","pos":"NOM"} ,
		{"word":"creeks","word_nosc":"creeks","lemma":"creek","pos":"NOM"} ,
		{"word":"crematorium","word_nosc":"crematorium","lemma":"crematorium","pos":"NOM"} ,
		{"word":"crescendo","word_nosc":"crescendo","lemma":"crescendo","pos":"NOM"} ,
		{"word":"cresson","word_nosc":"cresson","lemma":"cresson","pos":"NOM"} ,
		{"word":"cressonnette","word_nosc":"cressonnette","lemma":"cressonnette","pos":"NOM"} ,
		{"word":"cressonnière","word_nosc":"cressonniere","lemma":"cressonnière","pos":"NOM"} ,
		{"word":"cressonnières","word_nosc":"cressonnieres","lemma":"cressonnière","pos":"NOM"} ,
		{"word":"cressons","word_nosc":"cressons","lemma":"cresson","pos":"NOM"} ,
		{"word":"creton","word_nosc":"creton","lemma":"creton","pos":"NOM"} ,
		{"word":"cretonne","word_nosc":"cretonne","lemma":"creton","pos":"NOM"} ,
		{"word":"cretonnes","word_nosc":"cretonnes","lemma":"creton","pos":"NOM"} ,
		{"word":"cretons","word_nosc":"cretons","lemma":"creton","pos":"NOM"} ,
		{"word":"creusement","word_nosc":"creusement","lemma":"creusement","pos":"NOM"} ,
		{"word":"creusements","word_nosc":"creusements","lemma":"creusement","pos":"NOM"} ,
		{"word":"creuset","word_nosc":"creuset","lemma":"creuset","pos":"NOM"} ,
		{"word":"creusets","word_nosc":"creusets","lemma":"creuset","pos":"NOM"} ,
		{"word":"creuseur","word_nosc":"creuseur","lemma":"creuseur","pos":"NOM"} ,
		{"word":"creux","word_nosc":"creux","lemma":"creux","pos":"NOM"} ,
		{"word":"crevage","word_nosc":"crevage","lemma":"crevage","pos":"NOM"} ,
		{"word":"crevaison","word_nosc":"crevaison","lemma":"crevaison","pos":"NOM"} ,
		{"word":"crevaisons","word_nosc":"crevaisons","lemma":"crevaison","pos":"NOM"} ,
		{"word":"crevard","word_nosc":"crevard","lemma":"crevard","pos":"NOM"} ,
		{"word":"crevarde","word_nosc":"crevarde","lemma":"crevard","pos":"NOM"} ,
		{"word":"crevards","word_nosc":"crevards","lemma":"crevard","pos":"NOM"} ,
		{"word":"crevasse","word_nosc":"crevasse","lemma":"crevasse","pos":"NOM"} ,
		{"word":"crevasses","word_nosc":"crevasses","lemma":"crevasse","pos":"NOM"} ,
		{"word":"crevette","word_nosc":"crevette","lemma":"crevette","pos":"NOM"} ,
		{"word":"crevettes","word_nosc":"crevettes","lemma":"crevette","pos":"NOM"} ,
		{"word":"crevettier","word_nosc":"crevettier","lemma":"crevettier","pos":"NOM"} ,
		{"word":"crevettiers","word_nosc":"crevettiers","lemma":"crevettier","pos":"NOM"} ,
		{"word":"crevure","word_nosc":"crevure","lemma":"crevure","pos":"NOM"} ,
		{"word":"crevures","word_nosc":"crevures","lemma":"crevure","pos":"NOM"} ,
		{"word":"cri","word_nosc":"cri","lemma":"cri","pos":"NOM"} ,
		{"word":"criaillement","word_nosc":"criaillement","lemma":"criaillement","pos":"NOM"} ,
		{"word":"criaillerie","word_nosc":"criaillerie","lemma":"criaillerie","pos":"NOM"} ,
		{"word":"criailleries","word_nosc":"criailleries","lemma":"criaillerie","pos":"NOM"} ,
		{"word":"crib","word_nosc":"crib","lemma":"crib","pos":"NOM"} ,
		{"word":"criblage","word_nosc":"criblage","lemma":"criblage","pos":"NOM"} ,
		{"word":"crible","word_nosc":"crible","lemma":"crible","pos":"NOM"} ,
		{"word":"cribles","word_nosc":"cribles","lemma":"crible","pos":"NOM"} ,
		{"word":"cric","word_nosc":"cric","lemma":"cric","pos":"NOM"} ,
		{"word":"cricket","word_nosc":"cricket","lemma":"cricket","pos":"NOM"} ,
		{"word":"crickets","word_nosc":"crickets","lemma":"cricket","pos":"NOM"} ,
		{"word":"cricri","word_nosc":"cricri","lemma":"cricri","pos":"NOM"} ,
		{"word":"cricris","word_nosc":"cricris","lemma":"cricri","pos":"NOM"} ,
		{"word":"crics","word_nosc":"crics","lemma":"cric","pos":"NOM"} ,
		{"word":"crieur","word_nosc":"crieur","lemma":"crieur","pos":"NOM"} ,
		{"word":"crieurs","word_nosc":"crieurs","lemma":"crieur","pos":"NOM"} ,
		{"word":"crieuse","word_nosc":"crieuse","lemma":"crieur","pos":"NOM"} ,
		{"word":"crime","word_nosc":"crime","lemma":"crime","pos":"NOM"} ,
		{"word":"crimes","word_nosc":"crimes","lemma":"crime","pos":"NOM"} ,
		{"word":"criminaliste","word_nosc":"criminaliste","lemma":"criminaliste","pos":"NOM"} ,
		{"word":"criminalité","word_nosc":"criminalite","lemma":"criminalité","pos":"NOM"} ,
		{"word":"criminel","word_nosc":"criminel","lemma":"criminel","pos":"NOM"} ,
		{"word":"criminelle","word_nosc":"criminelle","lemma":"criminel","pos":"NOM"} ,
		{"word":"criminelles","word_nosc":"criminelles","lemma":"criminel","pos":"NOM"} ,
		{"word":"criminels","word_nosc":"criminels","lemma":"criminel","pos":"NOM"} ,
		{"word":"criminologie","word_nosc":"criminologie","lemma":"criminologie","pos":"NOM"} ,
		{"word":"criminologiste","word_nosc":"criminologiste","lemma":"criminologiste","pos":"NOM"} ,
		{"word":"criminologue","word_nosc":"criminologue","lemma":"criminologue","pos":"NOM"} ,
		{"word":"criminologues","word_nosc":"criminologues","lemma":"criminologue","pos":"NOM"} ,
		{"word":"crin","word_nosc":"crin","lemma":"crin","pos":"NOM"} ,
		{"word":"crincrin","word_nosc":"crincrin","lemma":"crincrin","pos":"NOM"} ,
		{"word":"crincrins","word_nosc":"crincrins","lemma":"crincrin","pos":"NOM"} ,
		{"word":"crinière","word_nosc":"criniere","lemma":"crinière","pos":"NOM"} ,
		{"word":"crinières","word_nosc":"crinieres","lemma":"crinière","pos":"NOM"} ,
		{"word":"crinoline","word_nosc":"crinoline","lemma":"crinoline","pos":"NOM"} ,
		{"word":"crinolines","word_nosc":"crinolines","lemma":"crinoline","pos":"NOM"} ,
		{"word":"crins","word_nosc":"crins","lemma":"crin","pos":"NOM"} ,
		{"word":"crique","word_nosc":"crique","lemma":"crique","pos":"NOM"} ,
		{"word":"criques","word_nosc":"criques","lemma":"crique","pos":"NOM"} ,
		{"word":"criquet","word_nosc":"criquet","lemma":"criquet","pos":"NOM"} ,
		{"word":"criquets","word_nosc":"criquets","lemma":"criquet","pos":"NOM"} ,
		{"word":"cris","word_nosc":"cris","lemma":"cri","pos":"NOM"} ,
		{"word":"cris-craft","word_nosc":"cris-craft","lemma":"cris-craft","pos":"NOM"} ,
		{"word":"crise","word_nosc":"crise","lemma":"crise","pos":"NOM"} ,
		{"word":"crises","word_nosc":"crises","lemma":"crise","pos":"NOM"} ,
		{"word":"crispation","word_nosc":"crispation","lemma":"crispation","pos":"NOM"} ,
		{"word":"crispations","word_nosc":"crispations","lemma":"crispation","pos":"NOM"} ,
		{"word":"crispin","word_nosc":"crispin","lemma":"crispin","pos":"NOM"} ,
		{"word":"crissement","word_nosc":"crissement","lemma":"crissement","pos":"NOM"} ,
		{"word":"crissements","word_nosc":"crissements","lemma":"crissement","pos":"NOM"} ,
		{"word":"cristal","word_nosc":"cristal","lemma":"cristal","pos":"NOM"} ,
		{"word":"cristallerie","word_nosc":"cristallerie","lemma":"cristallerie","pos":"NOM"} ,
		{"word":"cristalleries","word_nosc":"cristalleries","lemma":"cristallerie","pos":"NOM"} ,
		{"word":"cristallin","word_nosc":"cristallin","lemma":"cristallin","pos":"NOM"} ,
		{"word":"cristallins","word_nosc":"cristallins","lemma":"cristallin","pos":"NOM"} ,
		{"word":"cristallisation","word_nosc":"cristallisation","lemma":"cristallisation","pos":"NOM"} ,
		{"word":"cristallographie","word_nosc":"cristallographie","lemma":"cristallographie","pos":"NOM"} ,
		{"word":"cristallomancie","word_nosc":"cristallomancie","lemma":"cristallomancie","pos":"NOM"} ,
		{"word":"cristalloïde","word_nosc":"cristalloide","lemma":"cristalloïde","pos":"NOM"} ,
		{"word":"cristaux","word_nosc":"cristaux","lemma":"cristal","pos":"NOM"} ,
		{"word":"critique","word_nosc":"critique","lemma":"critique","pos":"NOM"} ,
		{"word":"critiques","word_nosc":"critiques","lemma":"critique","pos":"NOM"} ,
		{"word":"critère","word_nosc":"critere","lemma":"critère","pos":"NOM"} ,
		{"word":"critères","word_nosc":"criteres","lemma":"critère","pos":"NOM"} ,
		{"word":"critérium","word_nosc":"criterium","lemma":"critérium","pos":"NOM"} ,
		{"word":"critériums","word_nosc":"criteriums","lemma":"critérium","pos":"NOM"} ,
		{"word":"criée","word_nosc":"criee","lemma":"criée","pos":"NOM"} ,
		{"word":"criées","word_nosc":"criees","lemma":"criée","pos":"NOM"} ,
		{"word":"croassement","word_nosc":"croassement","lemma":"croassement","pos":"NOM"} ,
		{"word":"croassements","word_nosc":"croassements","lemma":"croassement","pos":"NOM"} ,
		{"word":"croate","word_nosc":"croate","lemma":"croate","pos":"NOM"} ,
		{"word":"croates","word_nosc":"croates","lemma":"croate","pos":"NOM"} ,
		{"word":"crobard","word_nosc":"crobard","lemma":"crobard","pos":"NOM"} ,
		{"word":"croc","word_nosc":"croc","lemma":"croc","pos":"NOM"} ,
		{"word":"croc-en-jambe","word_nosc":"croc-en-jambe","lemma":"croc-en-jambe","pos":"NOM"} ,
		{"word":"croche","word_nosc":"croche","lemma":"croche","pos":"NOM"} ,
		{"word":"croche-patte","word_nosc":"croche-patte","lemma":"croche-patte","pos":"NOM"} ,
		{"word":"croche-pied","word_nosc":"croche-pied","lemma":"croche-pied","pos":"NOM"} ,
		{"word":"croche-pieds","word_nosc":"croche-pieds","lemma":"croche-pied","pos":"NOM"} ,
		{"word":"croches","word_nosc":"croches","lemma":"croche","pos":"NOM"} ,
		{"word":"crochet","word_nosc":"crochet","lemma":"crochet","pos":"NOM"} ,
		{"word":"crochetage","word_nosc":"crochetage","lemma":"crochetage","pos":"NOM"} ,
		{"word":"crocheteur","word_nosc":"crocheteur","lemma":"crocheteur","pos":"NOM"} ,
		{"word":"crocheteurs","word_nosc":"crocheteurs","lemma":"crocheteur","pos":"NOM"} ,
		{"word":"crochets","word_nosc":"crochets","lemma":"crochet","pos":"NOM"} ,
		{"word":"croco","word_nosc":"croco","lemma":"croco","pos":"NOM"} ,
		{"word":"crocodile","word_nosc":"crocodile","lemma":"crocodile","pos":"NOM"} ,
		{"word":"crocodiles","word_nosc":"crocodiles","lemma":"crocodile","pos":"NOM"} ,
		{"word":"crocos","word_nosc":"crocos","lemma":"croco","pos":"NOM"} ,
		{"word":"crocs","word_nosc":"crocs","lemma":"croc","pos":"NOM"} ,
		{"word":"crocs-en-jambe","word_nosc":"crocs-en-jambe","lemma":"crocs-en-jambe","pos":"NOM"} ,
		{"word":"crocus","word_nosc":"crocus","lemma":"crocus","pos":"NOM"} ,
		{"word":"croisade","word_nosc":"croisade","lemma":"croisade","pos":"NOM"} ,
		{"word":"croisades","word_nosc":"croisades","lemma":"croisade","pos":"NOM"} ,
		{"word":"croisement","word_nosc":"croisement","lemma":"croisement","pos":"NOM"} ,
		{"word":"croisements","word_nosc":"croisements","lemma":"croisement","pos":"NOM"} ,
		{"word":"croisette","word_nosc":"croisette","lemma":"croisette","pos":"NOM"} ,
		{"word":"croiseur","word_nosc":"croiseur","lemma":"croiseur","pos":"NOM"} ,
		{"word":"croiseurs","word_nosc":"croiseurs","lemma":"croiseur","pos":"NOM"} ,
		{"word":"croisillon","word_nosc":"croisillon","lemma":"croisillon","pos":"NOM"} ,
		{"word":"croisillons","word_nosc":"croisillons","lemma":"croisillon","pos":"NOM"} ,
		{"word":"croisière","word_nosc":"croisiere","lemma":"croisière","pos":"NOM"} ,
		{"word":"croisières","word_nosc":"croisieres","lemma":"croisière","pos":"NOM"} ,
		{"word":"croissance","word_nosc":"croissance","lemma":"croissance","pos":"NOM"} ,
		{"word":"croissances","word_nosc":"croissances","lemma":"croissance","pos":"NOM"} ,
		{"word":"croissant","word_nosc":"croissant","lemma":"croissant","pos":"NOM"} ,
		{"word":"croissants","word_nosc":"croissants","lemma":"croissant","pos":"NOM"} ,
		{"word":"croisé","word_nosc":"croise","lemma":"croisé","pos":"NOM"} ,
		{"word":"croisée","word_nosc":"croisee","lemma":"croisée","pos":"NOM"} ,
		{"word":"croisées","word_nosc":"croisees","lemma":"croisée","pos":"NOM"} ,
		{"word":"croisés","word_nosc":"croises","lemma":"croisé","pos":"NOM"} ,
		{"word":"croix","word_nosc":"croix","lemma":"croix","pos":"NOM"} ,
		{"word":"cromlech","word_nosc":"cromlech","lemma":"cromlech","pos":"NOM"} ,
		{"word":"cromlechs","word_nosc":"cromlechs","lemma":"cromlech","pos":"NOM"} ,
		{"word":"cromorne","word_nosc":"cromorne","lemma":"cromorne","pos":"NOM"} ,
		{"word":"cronstadt","word_nosc":"cronstadt","lemma":"cronstadt","pos":"NOM"} ,
		{"word":"crooner","word_nosc":"crooner","lemma":"crooner","pos":"NOM"} ,
		{"word":"crooners","word_nosc":"crooners","lemma":"crooner","pos":"NOM"} ,
		{"word":"croquant","word_nosc":"croquant","lemma":"croquant","pos":"NOM"} ,
		{"word":"croquants","word_nosc":"croquants","lemma":"croquant","pos":"NOM"} ,
		{"word":"croque-madame","word_nosc":"croque-madame","lemma":"croque-madame","pos":"NOM"} ,
		{"word":"croque-mitaine","word_nosc":"croque-mitaine","lemma":"croque-mitaine","pos":"NOM"} ,
		{"word":"croque-monsieur","word_nosc":"croque-monsieur","lemma":"croque-monsieur","pos":"NOM"} ,
		{"word":"croque-mort","word_nosc":"croque-mort","lemma":"croque-mort","pos":"NOM"} ,
		{"word":"croque-morts","word_nosc":"croque-morts","lemma":"croque-mort","pos":"NOM"} ,
		{"word":"croquemitaine","word_nosc":"croquemitaine","lemma":"croquemitaine","pos":"NOM"} ,
		{"word":"croquemitaines","word_nosc":"croquemitaines","lemma":"croquemitaine","pos":"NOM"} ,
		{"word":"croquemort","word_nosc":"croquemort","lemma":"croquemort","pos":"NOM"} ,
		{"word":"croquemorts","word_nosc":"croquemorts","lemma":"croquemort","pos":"NOM"} ,
		{"word":"croquenots","word_nosc":"croquenots","lemma":"croquenot","pos":"NOM"} ,
		{"word":"croquet","word_nosc":"croquet","lemma":"croquet","pos":"NOM"} ,
		{"word":"croqueton","word_nosc":"croqueton","lemma":"croqueton","pos":"NOM"} ,
		{"word":"croquetons","word_nosc":"croquetons","lemma":"croqueton","pos":"NOM"} ,
		{"word":"croquette","word_nosc":"croquette","lemma":"croquette","pos":"NOM"} ,
		{"word":"croquettes","word_nosc":"croquettes","lemma":"croquette","pos":"NOM"} ,
		{"word":"croqueur","word_nosc":"croqueur","lemma":"croqueur","pos":"NOM"} ,
		{"word":"croqueuse","word_nosc":"croqueuse","lemma":"croqueur","pos":"NOM"} ,
		{"word":"croquis","word_nosc":"croquis","lemma":"croquis","pos":"NOM"} ,
		{"word":"croquis-minute","word_nosc":"croquis-minute","lemma":"croquis-minute","pos":"NOM"} ,
		{"word":"crosne","word_nosc":"crosne","lemma":"crosne","pos":"NOM"} ,
		{"word":"crosnes","word_nosc":"crosnes","lemma":"crosne","pos":"NOM"} ,
		{"word":"cross","word_nosc":"cross","lemma":"cross","pos":"NOM"} ,
		{"word":"cross-country","word_nosc":"cross-country","lemma":"cross-country","pos":"NOM"} ,
		{"word":"crosse","word_nosc":"crosse","lemma":"crosse","pos":"NOM"} ,
		{"word":"crosses","word_nosc":"crosses","lemma":"crosse","pos":"NOM"} ,
		{"word":"crossman","word_nosc":"crossman","lemma":"crossman","pos":"NOM"} ,
		{"word":"crotale","word_nosc":"crotale","lemma":"crotale","pos":"NOM"} ,
		{"word":"crotales","word_nosc":"crotales","lemma":"crotale","pos":"NOM"} ,
		{"word":"croton","word_nosc":"croton","lemma":"croton","pos":"NOM"} ,
		{"word":"crotte","word_nosc":"crotte","lemma":"crotte","pos":"NOM"} ,
		{"word":"crottes","word_nosc":"crottes","lemma":"crotte","pos":"NOM"} ,
		{"word":"crottin","word_nosc":"crottin","lemma":"crottin","pos":"NOM"} ,
		{"word":"crottins","word_nosc":"crottins","lemma":"crottin","pos":"NOM"} ,
		{"word":"crouillat","word_nosc":"crouillat","lemma":"crouillat","pos":"NOM"} ,
		{"word":"crouillats","word_nosc":"crouillats","lemma":"crouillat","pos":"NOM"} ,
		{"word":"croulant","word_nosc":"croulant","lemma":"croulant","pos":"NOM"} ,
		{"word":"croulante","word_nosc":"croulante","lemma":"croulant","pos":"NOM"} ,
		{"word":"croulantes","word_nosc":"croulantes","lemma":"croulant","pos":"NOM"} ,
		{"word":"croulants","word_nosc":"croulants","lemma":"croulant","pos":"NOM"} ,
		{"word":"croule","word_nosc":"croule","lemma":"croule","pos":"NOM"} ,
		{"word":"croulement","word_nosc":"croulement","lemma":"croulement","pos":"NOM"} ,
		{"word":"croup","word_nosc":"croup","lemma":"croup","pos":"NOM"} ,
		{"word":"croupade","word_nosc":"croupade","lemma":"croupade","pos":"NOM"} ,
		{"word":"croupe","word_nosc":"croupe","lemma":"croupe","pos":"NOM"} ,
		{"word":"croupes","word_nosc":"croupes","lemma":"croupe","pos":"NOM"} ,
		{"word":"croupier","word_nosc":"croupier","lemma":"croupier","pos":"NOM"} ,
		{"word":"croupiers","word_nosc":"croupiers","lemma":"croupier","pos":"NOM"} ,
		{"word":"croupion","word_nosc":"croupion","lemma":"croupion","pos":"NOM"} ,
		{"word":"croupions","word_nosc":"croupions","lemma":"croupion","pos":"NOM"} ,
		{"word":"croupissoir","word_nosc":"croupissoir","lemma":"croupissoir","pos":"NOM"} ,
		{"word":"croupissure","word_nosc":"croupissure","lemma":"croupissure","pos":"NOM"} ,
		{"word":"croupière","word_nosc":"croupiere","lemma":"croupière","pos":"NOM"} ,
		{"word":"croupières","word_nosc":"croupieres","lemma":"croupière","pos":"NOM"} ,
		{"word":"croupon","word_nosc":"croupon","lemma":"croupon","pos":"NOM"} ,
		{"word":"croustade","word_nosc":"croustade","lemma":"croustade","pos":"NOM"} ,
		{"word":"croustades","word_nosc":"croustades","lemma":"croustade","pos":"NOM"} ,
		{"word":"croustillance","word_nosc":"croustillance","lemma":"croustillance","pos":"NOM"} ,
		{"word":"croustille","word_nosc":"croustille","lemma":"croustille","pos":"NOM"} ,
		{"word":"croustilles","word_nosc":"croustilles","lemma":"croustille","pos":"NOM"} ,
		{"word":"croustillon","word_nosc":"croustillon","lemma":"croustillon","pos":"NOM"} ,
		{"word":"croustillons","word_nosc":"croustillons","lemma":"croustillon","pos":"NOM"} ,
		{"word":"crown","word_nosc":"crown","lemma":"crown","pos":"NOM"} ,
		{"word":"croyance","word_nosc":"croyance","lemma":"croyance","pos":"NOM"} ,
		{"word":"croyances","word_nosc":"croyances","lemma":"croyance","pos":"NOM"} ,
		{"word":"croyant","word_nosc":"croyant","lemma":"croyant","pos":"NOM"} ,
		{"word":"croyante","word_nosc":"croyante","lemma":"croyant","pos":"NOM"} ,
		{"word":"croyantes","word_nosc":"croyantes","lemma":"croyant","pos":"NOM"} ,
		{"word":"croyants","word_nosc":"croyants","lemma":"croyant","pos":"NOM"} ,
		{"word":"croît","word_nosc":"croit","lemma":"croît","pos":"NOM"} ,
		{"word":"croûte","word_nosc":"croute","lemma":"croûte","pos":"NOM"} ,
		{"word":"croûtes","word_nosc":"croutes","lemma":"croûte","pos":"NOM"} ,
		{"word":"croûton","word_nosc":"crouton","lemma":"croûton","pos":"NOM"} ,
		{"word":"croûtons","word_nosc":"croutons","lemma":"croûton","pos":"NOM"} ,
		{"word":"cru","word_nosc":"cru","lemma":"cru","pos":"NOM"} ,
		{"word":"cruauté","word_nosc":"cruaute","lemma":"cruauté","pos":"NOM"} ,
		{"word":"cruautés","word_nosc":"cruautes","lemma":"cruauté","pos":"NOM"} ,
		{"word":"cruche","word_nosc":"cruche","lemma":"cruche","pos":"NOM"} ,
		{"word":"cruches","word_nosc":"cruches","lemma":"cruche","pos":"NOM"} ,
		{"word":"cruchon","word_nosc":"cruchon","lemma":"cruchon","pos":"NOM"} ,
		{"word":"cruchons","word_nosc":"cruchons","lemma":"cruchon","pos":"NOM"} ,
		{"word":"crucifiement","word_nosc":"crucifiement","lemma":"crucifiement","pos":"NOM"} ,
		{"word":"crucifix","word_nosc":"crucifix","lemma":"crucifix","pos":"NOM"} ,
		{"word":"crucifixion","word_nosc":"crucifixion","lemma":"crucifixion","pos":"NOM"} ,
		{"word":"crucifixions","word_nosc":"crucifixions","lemma":"crucifixion","pos":"NOM"} ,
		{"word":"crucifié","word_nosc":"crucifie","lemma":"crucifié","pos":"NOM"} ,
		{"word":"crucifiés","word_nosc":"crucifies","lemma":"crucifié","pos":"NOM"} ,
		{"word":"crucifère","word_nosc":"crucifere","lemma":"crucifère","pos":"NOM"} ,
		{"word":"crucifères","word_nosc":"cruciferes","lemma":"crucifère","pos":"NOM"} ,
		{"word":"cruciverbiste","word_nosc":"cruciverbiste","lemma":"cruciverbiste","pos":"NOM"} ,
		{"word":"cruciverbistes","word_nosc":"cruciverbistes","lemma":"cruciverbiste","pos":"NOM"} ,
		{"word":"crudité","word_nosc":"crudite","lemma":"crudité","pos":"NOM"} ,
		{"word":"crudités","word_nosc":"crudites","lemma":"crudité","pos":"NOM"} ,
		{"word":"crue","word_nosc":"crue","lemma":"crue","pos":"NOM"} ,
		{"word":"crues","word_nosc":"crues","lemma":"crue","pos":"NOM"} ,
		{"word":"cruiser","word_nosc":"cruiser","lemma":"cruiser","pos":"NOM"} ,
		{"word":"crumble","word_nosc":"crumble","lemma":"crumble","pos":"NOM"} ,
		{"word":"crus","word_nosc":"crus","lemma":"cru","pos":"NOM"} ,
		{"word":"crustacé","word_nosc":"crustace","lemma":"crustacé","pos":"NOM"} ,
		{"word":"crustacés","word_nosc":"crustaces","lemma":"crustacé","pos":"NOM"} ,
		{"word":"cruzeiro","word_nosc":"cruzeiro","lemma":"cruzeiro","pos":"NOM"} ,
		{"word":"cruzeiros","word_nosc":"cruzeiros","lemma":"cruzeiro","pos":"NOM"} ,
		{"word":"cryofracture","word_nosc":"cryofracture","lemma":"cryofracture","pos":"NOM"} ,
		{"word":"cryogénie","word_nosc":"cryogenie","lemma":"cryogénie","pos":"NOM"} ,
		{"word":"cryogénisation","word_nosc":"cryogenisation","lemma":"cryogénisation","pos":"NOM"} ,
		{"word":"cryotechnique","word_nosc":"cryotechnique","lemma":"cryotechnique","pos":"NOM"} ,
		{"word":"cryptage","word_nosc":"cryptage","lemma":"cryptage","pos":"NOM"} ,
		{"word":"crypte","word_nosc":"crypte","lemma":"crypte","pos":"NOM"} ,
		{"word":"cryptes","word_nosc":"cryptes","lemma":"crypte","pos":"NOM"} ,
		{"word":"crypto","word_nosc":"crypto","lemma":"crypto","pos":"NOM"} ,
		{"word":"cryptocommunistes","word_nosc":"cryptocommunistes","lemma":"cryptocommuniste","pos":"NOM"} ,
		{"word":"cryptogames","word_nosc":"cryptogames","lemma":"cryptogame","pos":"NOM"} ,
		{"word":"cryptogramme","word_nosc":"cryptogramme","lemma":"cryptogramme","pos":"NOM"} ,
		{"word":"cryptogrammes","word_nosc":"cryptogrammes","lemma":"cryptogramme","pos":"NOM"} ,
		{"word":"cryptographe","word_nosc":"cryptographe","lemma":"cryptographe","pos":"NOM"} ,
		{"word":"cryptographie","word_nosc":"cryptographie","lemma":"cryptographie","pos":"NOM"} ,
		{"word":"cryptologie","word_nosc":"cryptologie","lemma":"cryptologie","pos":"NOM"} ,
		{"word":"cryptomère","word_nosc":"cryptomere","lemma":"cryptomère","pos":"NOM"} ,
		{"word":"crâne","word_nosc":"crane","lemma":"crâne","pos":"NOM"} ,
		{"word":"crânerie","word_nosc":"cranerie","lemma":"crânerie","pos":"NOM"} ,
		{"word":"crânes","word_nosc":"cranes","lemma":"crâne","pos":"NOM"} ,
		{"word":"crâneur","word_nosc":"craneur","lemma":"crâneur","pos":"NOM"} ,
		{"word":"crâneurs","word_nosc":"craneurs","lemma":"crâneur","pos":"NOM"} ,
		{"word":"crâneuse","word_nosc":"craneuse","lemma":"crâneur","pos":"NOM"} ,
		{"word":"crâneuses","word_nosc":"craneuses","lemma":"crâneur","pos":"NOM"} ,
		{"word":"crèche","word_nosc":"creche","lemma":"crèche","pos":"NOM"} ,
		{"word":"crèches","word_nosc":"creches","lemma":"crèche","pos":"NOM"} ,
		{"word":"crème","word_nosc":"creme","lemma":"crème","pos":"NOM"} ,
		{"word":"crèmes","word_nosc":"cremes","lemma":"crème","pos":"NOM"} ,
		{"word":"crève","word_nosc":"creve","lemma":"crève","pos":"NOM"} ,
		{"word":"crèves","word_nosc":"creves","lemma":"crève","pos":"NOM"} ,
		{"word":"créance","word_nosc":"creance","lemma":"créance","pos":"NOM"} ,
		{"word":"créances","word_nosc":"creances","lemma":"créance","pos":"NOM"} ,
		{"word":"créancier","word_nosc":"creancier","lemma":"créancier","pos":"NOM"} ,
		{"word":"créanciers","word_nosc":"creanciers","lemma":"créancier","pos":"NOM"} ,
		{"word":"créateur","word_nosc":"createur","lemma":"créateur","pos":"NOM"} ,
		{"word":"créateurs","word_nosc":"createurs","lemma":"créateur","pos":"NOM"} ,
		{"word":"créatif","word_nosc":"creatif","lemma":"créatif","pos":"NOM"} ,
		{"word":"créatifs","word_nosc":"creatifs","lemma":"créatif","pos":"NOM"} ,
		{"word":"créatine","word_nosc":"creatine","lemma":"créatine","pos":"NOM"} ,
		{"word":"créatinine","word_nosc":"creatinine","lemma":"créatinine","pos":"NOM"} ,
		{"word":"création","word_nosc":"creation","lemma":"création","pos":"NOM"} ,
		{"word":"créationnisme","word_nosc":"creationnisme","lemma":"créationnisme","pos":"NOM"} ,
		{"word":"créations","word_nosc":"creations","lemma":"création","pos":"NOM"} ,
		{"word":"créative","word_nosc":"creative","lemma":"créative","pos":"NOM"} ,
		{"word":"créativité","word_nosc":"creativite","lemma":"créativité","pos":"NOM"} ,
		{"word":"créatrice","word_nosc":"creatrice","lemma":"créateur","pos":"NOM"} ,
		{"word":"créatrices","word_nosc":"creatrices","lemma":"créateur","pos":"NOM"} ,
		{"word":"créature","word_nosc":"creature","lemma":"créature","pos":"NOM"} ,
		{"word":"créatures","word_nosc":"creatures","lemma":"créature","pos":"NOM"} ,
		{"word":"crécelle","word_nosc":"crecelle","lemma":"crécelle","pos":"NOM"} ,
		{"word":"crécelles","word_nosc":"crecelles","lemma":"crécelle","pos":"NOM"} ,
		{"word":"crécerelle","word_nosc":"crecerelle","lemma":"crécerelle","pos":"NOM"} ,
		{"word":"crécerelles","word_nosc":"crecerelles","lemma":"crécerelle","pos":"NOM"} ,
		{"word":"crécy","word_nosc":"crecy","lemma":"crécy","pos":"NOM"} ,
		{"word":"crédence","word_nosc":"credence","lemma":"crédence","pos":"NOM"} ,
		{"word":"crédences","word_nosc":"credences","lemma":"crédence","pos":"NOM"} ,
		{"word":"crédibilité","word_nosc":"credibilite","lemma":"crédibilité","pos":"NOM"} ,
		{"word":"crédit","word_nosc":"credit","lemma":"crédit","pos":"NOM"} ,
		{"word":"crédit-bail","word_nosc":"credit-bail","lemma":"crédit-bail","pos":"NOM"} ,
		{"word":"créditeur","word_nosc":"crediteur","lemma":"créditeur","pos":"NOM"} ,
		{"word":"créditeurs","word_nosc":"crediteurs","lemma":"créditeur","pos":"NOM"} ,
		{"word":"crédits","word_nosc":"credits","lemma":"crédit","pos":"NOM"} ,
		{"word":"crédulité","word_nosc":"credulite","lemma":"crédulité","pos":"NOM"} ,
		{"word":"crédulités","word_nosc":"credulites","lemma":"crédulité","pos":"NOM"} ,
		{"word":"crémaillère","word_nosc":"cremaillere","lemma":"crémaillère","pos":"NOM"} ,
		{"word":"crémaillères","word_nosc":"cremailleres","lemma":"crémaillère","pos":"NOM"} ,
		{"word":"crémant","word_nosc":"cremant","lemma":"crémant","pos":"NOM"} ,
		{"word":"crémateurs","word_nosc":"cremateurs","lemma":"crémateur","pos":"NOM"} ,
		{"word":"crémation","word_nosc":"cremation","lemma":"crémation","pos":"NOM"} ,
		{"word":"crémations","word_nosc":"cremations","lemma":"crémation","pos":"NOM"} ,
		{"word":"crématoire","word_nosc":"crematoire","lemma":"crématoire","pos":"NOM"} ,
		{"word":"crématoires","word_nosc":"crematoires","lemma":"crématoire","pos":"NOM"} ,
		{"word":"crématorium","word_nosc":"crematorium","lemma":"crématorium","pos":"NOM"} ,
		{"word":"crémerie","word_nosc":"cremerie","lemma":"crémerie","pos":"NOM"} ,
		{"word":"crémeries","word_nosc":"cremeries","lemma":"crémerie","pos":"NOM"} ,
		{"word":"crémier","word_nosc":"cremier","lemma":"crémier","pos":"NOM"} ,
		{"word":"crémiers","word_nosc":"cremiers","lemma":"crémier","pos":"NOM"} ,
		{"word":"crémière","word_nosc":"cremiere","lemma":"crémier","pos":"NOM"} ,
		{"word":"crémières","word_nosc":"cremieres","lemma":"crémier","pos":"NOM"} ,
		{"word":"crémone","word_nosc":"cremone","lemma":"crémone","pos":"NOM"} ,
		{"word":"créneau","word_nosc":"creneau","lemma":"créneau","pos":"NOM"} ,
		{"word":"créneaux","word_nosc":"creneaux","lemma":"créneau","pos":"NOM"} ,
		{"word":"créole","word_nosc":"creole","lemma":"créole","pos":"NOM"} ,
		{"word":"créoles","word_nosc":"creoles","lemma":"créole","pos":"NOM"} ,
		{"word":"créosote","word_nosc":"creosote","lemma":"créosote","pos":"NOM"} ,
		{"word":"crépi","word_nosc":"crepi","lemma":"crépi","pos":"NOM"} ,
		{"word":"crépine","word_nosc":"crepine","lemma":"crépine","pos":"NOM"} ,
		{"word":"crépinette","word_nosc":"crepinette","lemma":"crépinette","pos":"NOM"} ,
		{"word":"crépinettes","word_nosc":"crepinettes","lemma":"crépinette","pos":"NOM"} ,
		{"word":"crépins","word_nosc":"crepins","lemma":"crépin","pos":"NOM"} ,
		{"word":"crépis","word_nosc":"crepis","lemma":"crépi","pos":"NOM"} ,
		{"word":"crépissage","word_nosc":"crepissage","lemma":"crépissage","pos":"NOM"} ,
		{"word":"crépitation","word_nosc":"crepitation","lemma":"crépitation","pos":"NOM"} ,
		{"word":"crépitement","word_nosc":"crepitement","lemma":"crépitement","pos":"NOM"} ,
		{"word":"crépitements","word_nosc":"crepitements","lemma":"crépitement","pos":"NOM"} ,
		{"word":"crépon","word_nosc":"crepon","lemma":"crépon","pos":"NOM"} ,
		{"word":"crépons","word_nosc":"crepons","lemma":"crépon","pos":"NOM"} ,
		{"word":"crépuscule","word_nosc":"crepuscule","lemma":"crépuscule","pos":"NOM"} ,
		{"word":"crépuscules","word_nosc":"crepuscules","lemma":"crépuscule","pos":"NOM"} ,
		{"word":"créquier","word_nosc":"crequier","lemma":"créquier","pos":"NOM"} ,
		{"word":"crésol","word_nosc":"cresol","lemma":"crésol","pos":"NOM"} ,
		{"word":"crésus","word_nosc":"cresus","lemma":"crésus","pos":"NOM"} ,
		{"word":"crésyl","word_nosc":"cresyl","lemma":"crésyl","pos":"NOM"} ,
		{"word":"crétacé","word_nosc":"cretace","lemma":"crétacé","pos":"NOM"} ,
		{"word":"crételle","word_nosc":"cretelle","lemma":"crételle","pos":"NOM"} ,
		{"word":"crétin","word_nosc":"cretin","lemma":"crétin","pos":"NOM"} ,
		{"word":"crétine","word_nosc":"cretine","lemma":"crétin","pos":"NOM"} ,
		{"word":"crétinerie","word_nosc":"cretinerie","lemma":"crétinerie","pos":"NOM"} ,
		{"word":"crétineries","word_nosc":"cretineries","lemma":"crétinerie","pos":"NOM"} ,
		{"word":"crétinisation","word_nosc":"cretinisation","lemma":"crétinisation","pos":"NOM"} ,
		{"word":"crétinisme","word_nosc":"cretinisme","lemma":"crétinisme","pos":"NOM"} ,
		{"word":"crétins","word_nosc":"cretins","lemma":"crétin","pos":"NOM"} ,
		{"word":"crétois","word_nosc":"cretois","lemma":"crétois","pos":"NOM"} ,
		{"word":"crétoise","word_nosc":"cretoise","lemma":"crétois","pos":"NOM"} ,
		{"word":"crêpage","word_nosc":"crepage","lemma":"crêpage","pos":"NOM"} ,
		{"word":"crêpages","word_nosc":"crepages","lemma":"crêpage","pos":"NOM"} ,
		{"word":"crêpe","word_nosc":"crepe","lemma":"crêpe","pos":"NOM"} ,
		{"word":"crêpelure","word_nosc":"crepelure","lemma":"crêpelure","pos":"NOM"} ,
		{"word":"crêpes","word_nosc":"crepes","lemma":"crêpe","pos":"NOM"} ,
		{"word":"crêt","word_nosc":"cret","lemma":"crêt","pos":"NOM"} ,
		{"word":"crête","word_nosc":"crete","lemma":"crête","pos":"NOM"} ,
		{"word":"crêtes","word_nosc":"cretes","lemma":"crête","pos":"NOM"} ,
		{"word":"crêtes-de-coq","word_nosc":"cretes-de-coq","lemma":"crêtes-de-coq","pos":"NOM"} ,
		{"word":"csardas","word_nosc":"csardas","lemma":"csardas","pos":"NOM"} ,
		{"word":"cuadrilla","word_nosc":"cuadrilla","lemma":"cuadrilla","pos":"NOM"} ,
		{"word":"cuadrillas","word_nosc":"cuadrillas","lemma":"cuadrilla","pos":"NOM"} ,
		{"word":"cubage","word_nosc":"cubage","lemma":"cubage","pos":"NOM"} ,
		{"word":"cubages","word_nosc":"cubages","lemma":"cubage","pos":"NOM"} ,
		{"word":"cubain","word_nosc":"cubain","lemma":"cubain","pos":"NOM"} ,
		{"word":"cubaine","word_nosc":"cubaine","lemma":"cubain","pos":"NOM"} ,
		{"word":"cubaines","word_nosc":"cubaines","lemma":"cubain","pos":"NOM"} ,
		{"word":"cubains","word_nosc":"cubains","lemma":"cubain","pos":"NOM"} ,
		{"word":"cube","word_nosc":"cube","lemma":"cube","pos":"NOM"} ,
		{"word":"cubes","word_nosc":"cubes","lemma":"cube","pos":"NOM"} ,
		{"word":"cubisme","word_nosc":"cubisme","lemma":"cubisme","pos":"NOM"} ,
		{"word":"cubiste","word_nosc":"cubiste","lemma":"cubiste","pos":"NOM"} ,
		{"word":"cubistes","word_nosc":"cubistes","lemma":"cubiste","pos":"NOM"} ,
		{"word":"cubitainer","word_nosc":"cubitainer","lemma":"cubitainer","pos":"NOM"} ,
		{"word":"cubitières","word_nosc":"cubitieres","lemma":"cubitière","pos":"NOM"} ,
		{"word":"cubitus","word_nosc":"cubitus","lemma":"cubitus","pos":"NOM"} ,
		{"word":"cucaracha","word_nosc":"cucaracha","lemma":"cucaracha","pos":"NOM"} ,
		{"word":"cucurbitacée","word_nosc":"cucurbitacee","lemma":"cucurbitacée","pos":"NOM"} ,
		{"word":"cucurbite","word_nosc":"cucurbite","lemma":"cucurbite","pos":"NOM"} ,
		{"word":"cucurbites","word_nosc":"cucurbites","lemma":"cucurbite","pos":"NOM"} ,
		{"word":"cucuterie","word_nosc":"cucuterie","lemma":"cucuterie","pos":"NOM"} ,
		{"word":"cueillaison","word_nosc":"cueillaison","lemma":"cueillaison","pos":"NOM"} ,
		{"word":"cueillette","word_nosc":"cueillette","lemma":"cueillette","pos":"NOM"} ,
		{"word":"cueillettes","word_nosc":"cueillettes","lemma":"cueillette","pos":"NOM"} ,
		{"word":"cueilleur","word_nosc":"cueilleur","lemma":"cueilleur","pos":"NOM"} ,
		{"word":"cueilleurs","word_nosc":"cueilleurs","lemma":"cueilleur","pos":"NOM"} ,
		{"word":"cueilleuse","word_nosc":"cueilleuse","lemma":"cueilleur","pos":"NOM"} ,
		{"word":"cueilleuses","word_nosc":"cueilleuses","lemma":"cueilleur","pos":"NOM"} ,
		{"word":"cuesta","word_nosc":"cuesta","lemma":"cuesta","pos":"NOM"} ,
		{"word":"cueva","word_nosc":"cueva","lemma":"cueva","pos":"NOM"} ,
		{"word":"cuevas","word_nosc":"cuevas","lemma":"cueva","pos":"NOM"} ,
		{"word":"cui-cui","word_nosc":"cui-cui","lemma":"cui-cui","pos":"NOM"} ,
		{"word":"cuiller","word_nosc":"cuiller","lemma":"cuiller","pos":"NOM"} ,
		{"word":"cuilleron","word_nosc":"cuilleron","lemma":"cuilleron","pos":"NOM"} ,
		{"word":"cuillers","word_nosc":"cuillers","lemma":"cuiller","pos":"NOM"} ,
		{"word":"cuillerée","word_nosc":"cuilleree","lemma":"cuillerée","pos":"NOM"} ,
		{"word":"cuillerées","word_nosc":"cuillerees","lemma":"cuillerée","pos":"NOM"} ,
		{"word":"cuillère","word_nosc":"cuillere","lemma":"cuillère","pos":"NOM"} ,
		{"word":"cuillères","word_nosc":"cuilleres","lemma":"cuillère","pos":"NOM"} ,
		{"word":"cuir","word_nosc":"cuir","lemma":"cuir","pos":"NOM"} ,
		{"word":"cuirasse","word_nosc":"cuirasse","lemma":"cuirasse","pos":"NOM"} ,
		{"word":"cuirassements","word_nosc":"cuirassements","lemma":"cuirassement","pos":"NOM"} ,
		{"word":"cuirasses","word_nosc":"cuirasses","lemma":"cuirasse","pos":"NOM"} ,
		{"word":"cuirassier","word_nosc":"cuirassier","lemma":"cuirassier","pos":"NOM"} ,
		{"word":"cuirassiers","word_nosc":"cuirassiers","lemma":"cuirassier","pos":"NOM"} ,
		{"word":"cuirassé","word_nosc":"cuirasse","lemma":"cuirassé","pos":"NOM"} ,
		{"word":"cuirassés","word_nosc":"cuirasses","lemma":"cuirassé","pos":"NOM"} ,
		{"word":"cuirs","word_nosc":"cuirs","lemma":"cuir","pos":"NOM"} ,
		{"word":"cuiseurs","word_nosc":"cuiseurs","lemma":"cuiseur","pos":"NOM"} ,
		{"word":"cuisine","word_nosc":"cuisine","lemma":"cuisine","pos":"NOM"} ,
		{"word":"cuisines","word_nosc":"cuisines","lemma":"cuisine","pos":"NOM"} ,
		{"word":"cuisinette","word_nosc":"cuisinette","lemma":"cuisinette","pos":"NOM"} ,
		{"word":"cuisinier","word_nosc":"cuisinier","lemma":"cuisinier","pos":"NOM"} ,
		{"word":"cuisiniers","word_nosc":"cuisiniers","lemma":"cuisinier","pos":"NOM"} ,
		{"word":"cuisinière","word_nosc":"cuisiniere","lemma":"cuisinier","pos":"NOM"} ,
		{"word":"cuisinières","word_nosc":"cuisinieres","lemma":"cuisinier","pos":"NOM"} ,
		{"word":"cuissage","word_nosc":"cuissage","lemma":"cuissage","pos":"NOM"} ,
		{"word":"cuissard","word_nosc":"cuissard","lemma":"cuissard","pos":"NOM"} ,
		{"word":"cuissardes","word_nosc":"cuissardes","lemma":"cuissard","pos":"NOM"} ,
		{"word":"cuissardes","word_nosc":"cuissardes","lemma":"cuissarde","pos":"NOM"} ,
		{"word":"cuissards","word_nosc":"cuissards","lemma":"cuissard","pos":"NOM"} ,
		{"word":"cuisse","word_nosc":"cuisse","lemma":"cuisse","pos":"NOM"} ,
		{"word":"cuisse-madame","word_nosc":"cuisse-madame","lemma":"cuisse-madame","pos":"NOM"} ,
		{"word":"cuisseau","word_nosc":"cuisseau","lemma":"cuisseau","pos":"NOM"} ,
		{"word":"cuisses","word_nosc":"cuisses","lemma":"cuisse","pos":"NOM"} ,
		{"word":"cuissette","word_nosc":"cuissette","lemma":"cuissette","pos":"NOM"} ,
		{"word":"cuissettes","word_nosc":"cuissettes","lemma":"cuissette","pos":"NOM"} ,
		{"word":"cuisson","word_nosc":"cuisson","lemma":"cuisson","pos":"NOM"} ,
		{"word":"cuissons","word_nosc":"cuissons","lemma":"cuisson","pos":"NOM"} ,
		{"word":"cuissot","word_nosc":"cuissot","lemma":"cuissot","pos":"NOM"} ,
		{"word":"cuissots","word_nosc":"cuissots","lemma":"cuissot","pos":"NOM"} ,
		{"word":"cuistance","word_nosc":"cuistance","lemma":"cuistance","pos":"NOM"} ,
		{"word":"cuistances","word_nosc":"cuistances","lemma":"cuistance","pos":"NOM"} ,
		{"word":"cuistot","word_nosc":"cuistot","lemma":"cuistot","pos":"NOM"} ,
		{"word":"cuistots","word_nosc":"cuistots","lemma":"cuistot","pos":"NOM"} ,
		{"word":"cuistre","word_nosc":"cuistre","lemma":"cuistre","pos":"NOM"} ,
		{"word":"cuistrerie","word_nosc":"cuistrerie","lemma":"cuistrerie","pos":"NOM"} ,
		{"word":"cuistreries","word_nosc":"cuistreries","lemma":"cuistrerie","pos":"NOM"} ,
		{"word":"cuistres","word_nosc":"cuistres","lemma":"cuistre","pos":"NOM"} ,
		{"word":"cuite","word_nosc":"cuite","lemma":"cuite","pos":"NOM"} ,
		{"word":"cuites","word_nosc":"cuites","lemma":"cuite","pos":"NOM"} ,
		{"word":"cuivre","word_nosc":"cuivre","lemma":"cuivre","pos":"NOM"} ,
		{"word":"cuivres","word_nosc":"cuivres","lemma":"cuivre","pos":"NOM"} ,
		{"word":"cul","word_nosc":"cul","lemma":"cul","pos":"NOM"} ,
		{"word":"cul-blanc","word_nosc":"cul-blanc","lemma":"cul-blanc","pos":"NOM"} ,
		{"word":"cul-bénit","word_nosc":"cul-benit","lemma":"cul-bénit","pos":"NOM"} ,
		{"word":"cul-de-basse-fosse","word_nosc":"cul-de-basse-fosse","lemma":"cul-de-basse-fosse","pos":"NOM"} ,
		{"word":"cul-de-four","word_nosc":"cul-de-four","lemma":"cul-de-four","pos":"NOM"} ,
		{"word":"cul-de-jatte","word_nosc":"cul-de-jatte","lemma":"cul-de-jatte","pos":"NOM"} ,
		{"word":"cul-de-lampe","word_nosc":"cul-de-lampe","lemma":"cul-de-lampe","pos":"NOM"} ,
		{"word":"cul-de-plomb","word_nosc":"cul-de-plomb","lemma":"cul-de-plomb","pos":"NOM"} ,
		{"word":"cul-de-porc","word_nosc":"cul-de-porc","lemma":"cul-de-porc","pos":"NOM"} ,
		{"word":"cul-de-poule","word_nosc":"cul-de-poule","lemma":"cul-de-poule","pos":"NOM"} ,
		{"word":"cul-de-sac","word_nosc":"cul-de-sac","lemma":"cul-de-sac","pos":"NOM"} ,
		{"word":"cul-terreux","word_nosc":"cul-terreux","lemma":"cul-terreux","pos":"NOM"} ,
		{"word":"culasse","word_nosc":"culasse","lemma":"culasse","pos":"NOM"} ,
		{"word":"culasses","word_nosc":"culasses","lemma":"culasse","pos":"NOM"} ,
		{"word":"culbutages","word_nosc":"culbutages","lemma":"culbutage","pos":"NOM"} ,
		{"word":"culbute","word_nosc":"culbute","lemma":"culbute","pos":"NOM"} ,
		{"word":"culbutes","word_nosc":"culbutes","lemma":"culbute","pos":"NOM"} ,
		{"word":"culbuteur","word_nosc":"culbuteur","lemma":"culbuteur","pos":"NOM"} ,
		{"word":"culbuteurs","word_nosc":"culbuteurs","lemma":"culbuteur","pos":"NOM"} ,
		{"word":"culbuto","word_nosc":"culbuto","lemma":"culbuto","pos":"NOM"} ,
		{"word":"culex","word_nosc":"culex","lemma":"culex","pos":"NOM"} ,
		{"word":"culminance","word_nosc":"culminance","lemma":"culminance","pos":"NOM"} ,
		{"word":"culmination","word_nosc":"culmination","lemma":"culmination","pos":"NOM"} ,
		{"word":"culot","word_nosc":"culot","lemma":"culot","pos":"NOM"} ,
		{"word":"culots","word_nosc":"culots","lemma":"culot","pos":"NOM"} ,
		{"word":"culotte","word_nosc":"culotte","lemma":"culotte","pos":"NOM"} ,
		{"word":"culottes","word_nosc":"culottes","lemma":"culotte","pos":"NOM"} ,
		{"word":"culottier","word_nosc":"culottier","lemma":"culottier","pos":"NOM"} ,
		{"word":"culottiers","word_nosc":"culottiers","lemma":"culottier","pos":"NOM"} ,
		{"word":"culottière","word_nosc":"culottiere","lemma":"culottier","pos":"NOM"} ,
		{"word":"culottières","word_nosc":"culottieres","lemma":"culottier","pos":"NOM"} ,
		{"word":"culpabilisation","word_nosc":"culpabilisation","lemma":"culpabilisation","pos":"NOM"} ,
		{"word":"culpabilité","word_nosc":"culpabilite","lemma":"culpabilité","pos":"NOM"} ,
		{"word":"culs","word_nosc":"culs","lemma":"cul","pos":"NOM"} ,
		{"word":"culs-blancs","word_nosc":"culs-blancs","lemma":"cul-blanc","pos":"NOM"} ,
		{"word":"culs-bénits","word_nosc":"culs-benits","lemma":"cul-bénit","pos":"NOM"} ,
		{"word":"culs-de-basse-fosse","word_nosc":"culs-de-basse-fosse","lemma":"culs-de-basse-fosse","pos":"NOM"} ,
		{"word":"culs-de-jatte","word_nosc":"culs-de-jatte","lemma":"cul-de-jatte","pos":"NOM"} ,
		{"word":"culs-de-lampe","word_nosc":"culs-de-lampe","lemma":"cul-de-lampe","pos":"NOM"} ,
		{"word":"culs-de-sac","word_nosc":"culs-de-sac","lemma":"cul-de-sac","pos":"NOM"} ,
		{"word":"culs-terreux","word_nosc":"culs-terreux","lemma":"cul-terreux","pos":"NOM"} ,
		{"word":"culte","word_nosc":"culte","lemma":"culte","pos":"NOM"} ,
		{"word":"cultes","word_nosc":"cultes","lemma":"culte","pos":"NOM"} ,
		{"word":"cultivateur","word_nosc":"cultivateur","lemma":"cultivateur","pos":"NOM"} ,
		{"word":"cultivateurs","word_nosc":"cultivateurs","lemma":"cultivateur","pos":"NOM"} ,
		{"word":"cultivatrice","word_nosc":"cultivatrice","lemma":"cultivateur","pos":"NOM"} ,
		{"word":"cultivatrices","word_nosc":"cultivatrices","lemma":"cultivateur","pos":"NOM"} ,
		{"word":"culturalismes","word_nosc":"culturalismes","lemma":"culturalisme","pos":"NOM"} ,
		{"word":"culture","word_nosc":"culture","lemma":"culture","pos":"NOM"} ,
		{"word":"cultures","word_nosc":"cultures","lemma":"culture","pos":"NOM"} ,
		{"word":"culturisme","word_nosc":"culturisme","lemma":"culturisme","pos":"NOM"} ,
		{"word":"culturiste","word_nosc":"culturiste","lemma":"culturiste","pos":"NOM"} ,
		{"word":"culturistes","word_nosc":"culturistes","lemma":"culturiste","pos":"NOM"} ,
		{"word":"culée","word_nosc":"culee","lemma":"culée","pos":"NOM"} ,
		{"word":"cumin","word_nosc":"cumin","lemma":"cumin","pos":"NOM"} ,
		{"word":"cumul","word_nosc":"cumul","lemma":"cumul","pos":"NOM"} ,
		{"word":"cumulard","word_nosc":"cumulard","lemma":"cumulard","pos":"NOM"} ,
		{"word":"cumulation","word_nosc":"cumulation","lemma":"cumulation","pos":"NOM"} ,
		{"word":"cumulo-nimbus","word_nosc":"cumulo-nimbus","lemma":"cumulo-nimbus","pos":"NOM"} ,
		{"word":"cumulus","word_nosc":"cumulus","lemma":"cumulus","pos":"NOM"} ,
		{"word":"cunnilinctus","word_nosc":"cunnilinctus","lemma":"cunnilinctus","pos":"NOM"} ,
		{"word":"cunnilingus","word_nosc":"cunnilingus","lemma":"cunnilingus","pos":"NOM"} ,
		{"word":"cupidité","word_nosc":"cupidite","lemma":"cupidité","pos":"NOM"} ,
		{"word":"cupidon","word_nosc":"cupidon","lemma":"cupidon","pos":"NOM"} ,
		{"word":"cupidons","word_nosc":"cupidons","lemma":"cupidon","pos":"NOM"} ,
		{"word":"cupressus","word_nosc":"cupressus","lemma":"cupressus","pos":"NOM"} ,
		{"word":"cupronickel","word_nosc":"cupronickel","lemma":"cupronickel","pos":"NOM"} ,
		{"word":"cupule","word_nosc":"cupule","lemma":"cupule","pos":"NOM"} ,
		{"word":"cupules","word_nosc":"cupules","lemma":"cupule","pos":"NOM"} ,
		{"word":"curage","word_nosc":"curage","lemma":"curage","pos":"NOM"} ,
		{"word":"curaille","word_nosc":"curaille","lemma":"curaille","pos":"NOM"} ,
		{"word":"curaillon","word_nosc":"curaillon","lemma":"curaillon","pos":"NOM"} ,
		{"word":"curare","word_nosc":"curare","lemma":"curare","pos":"NOM"} ,
		{"word":"curateur","word_nosc":"curateur","lemma":"curateur","pos":"NOM"} ,
		{"word":"curatif","word_nosc":"curatif","lemma":"curatif","pos":"NOM"} ,
		{"word":"curatrice","word_nosc":"curatrice","lemma":"curateur","pos":"NOM"} ,
		{"word":"curaçao","word_nosc":"curacao","lemma":"curaçao","pos":"NOM"} ,
		{"word":"curaçaos","word_nosc":"curacaos","lemma":"curaçao","pos":"NOM"} ,
		{"word":"curcuma","word_nosc":"curcuma","lemma":"curcuma","pos":"NOM"} ,
		{"word":"cure","word_nosc":"cure","lemma":"cure","pos":"NOM"} ,
		{"word":"cure-dent","word_nosc":"cure-dent","lemma":"cure-dent","pos":"NOM"} ,
		{"word":"cure-dents","word_nosc":"cure-dents","lemma":"cure-dent","pos":"NOM"} ,
		{"word":"cure-pipe","word_nosc":"cure-pipe","lemma":"cure-pipe","pos":"NOM"} ,
		{"word":"cure-pipes","word_nosc":"cure-pipes","lemma":"cure-pipe","pos":"NOM"} ,
		{"word":"cures","word_nosc":"cures","lemma":"cure","pos":"NOM"} ,
		{"word":"curetage","word_nosc":"curetage","lemma":"curetage","pos":"NOM"} ,
		{"word":"cureton","word_nosc":"cureton","lemma":"cureton","pos":"NOM"} ,
		{"word":"curetons","word_nosc":"curetons","lemma":"cureton","pos":"NOM"} ,
		{"word":"curette","word_nosc":"curette","lemma":"curette","pos":"NOM"} ,
		{"word":"curettes","word_nosc":"curettes","lemma":"curette","pos":"NOM"} ,
		{"word":"curie","word_nosc":"curie","lemma":"curie","pos":"NOM"} ,
		{"word":"curieuse","word_nosc":"curieuse","lemma":"curieux","pos":"NOM"} ,
		{"word":"curieuses","word_nosc":"curieuses","lemma":"curieux","pos":"NOM"} ,
		{"word":"curieux","word_nosc":"curieux","lemma":"curieux","pos":"NOM"} ,
		{"word":"curiosité","word_nosc":"curiosite","lemma":"curiosité","pos":"NOM"} ,
		{"word":"curiosités","word_nosc":"curiosites","lemma":"curiosité","pos":"NOM"} ,
		{"word":"curiste","word_nosc":"curiste","lemma":"curiste","pos":"NOM"} ,
		{"word":"curistes","word_nosc":"curistes","lemma":"curiste","pos":"NOM"} ,
		{"word":"curling","word_nosc":"curling","lemma":"curling","pos":"NOM"} ,
		{"word":"curricula","word_nosc":"curricula","lemma":"curriculum","pos":"NOM"} ,
		{"word":"curriculum","word_nosc":"curriculum","lemma":"curriculum","pos":"NOM"} ,
		{"word":"curriculum vitae","word_nosc":"curriculum vitae","lemma":"curriculum vitae","pos":"NOM"} ,
		{"word":"curry","word_nosc":"curry","lemma":"curry","pos":"NOM"} ,
		{"word":"curseur","word_nosc":"curseur","lemma":"curseur","pos":"NOM"} ,
		{"word":"cursive","word_nosc":"cursive","lemma":"cursive","pos":"NOM"} ,
		{"word":"cursus","word_nosc":"cursus","lemma":"cursus","pos":"NOM"} ,
		{"word":"curvimètres","word_nosc":"curvimetres","lemma":"curvimètre","pos":"NOM"} ,
		{"word":"curé","word_nosc":"cure","lemma":"curé","pos":"NOM"} ,
		{"word":"curée","word_nosc":"curee","lemma":"curée","pos":"NOM"} ,
		{"word":"curées","word_nosc":"curees","lemma":"curée","pos":"NOM"} ,
		{"word":"curés","word_nosc":"cures","lemma":"curé","pos":"NOM"} ,
		{"word":"custode","word_nosc":"custode","lemma":"custode","pos":"NOM"} ,
		{"word":"custom","word_nosc":"custom","lemma":"custom","pos":"NOM"} ,
		{"word":"cuti","word_nosc":"cuti","lemma":"cuti","pos":"NOM"} ,
		{"word":"cuti-réaction","word_nosc":"cuti-reaction","lemma":"cuti-réaction","pos":"NOM"} ,
		{"word":"cuticule","word_nosc":"cuticule","lemma":"cuticule","pos":"NOM"} ,
		{"word":"cuticules","word_nosc":"cuticules","lemma":"cuticule","pos":"NOM"} ,
		{"word":"cutis","word_nosc":"cutis","lemma":"cuti","pos":"NOM"} ,
		{"word":"cutter","word_nosc":"cutter","lemma":"cutter","pos":"NOM"} ,
		{"word":"cutters","word_nosc":"cutters","lemma":"cutter","pos":"NOM"} ,
		{"word":"cuve","word_nosc":"cuve","lemma":"cuve","pos":"NOM"} ,
		{"word":"cuveau","word_nosc":"cuveau","lemma":"cuveau","pos":"NOM"} ,
		{"word":"cuveaux","word_nosc":"cuveaux","lemma":"cuveau","pos":"NOM"} ,
		{"word":"cuves","word_nosc":"cuves","lemma":"cuve","pos":"NOM"} ,
		{"word":"cuvette","word_nosc":"cuvette","lemma":"cuvette","pos":"NOM"} ,
		{"word":"cuvettes","word_nosc":"cuvettes","lemma":"cuvette","pos":"NOM"} ,
		{"word":"cuvier","word_nosc":"cuvier","lemma":"cuvier","pos":"NOM"} ,
		{"word":"cuviers","word_nosc":"cuviers","lemma":"cuvier","pos":"NOM"} ,
		{"word":"cuvée","word_nosc":"cuvee","lemma":"cuvée","pos":"NOM"} ,
		{"word":"cuvées","word_nosc":"cuvees","lemma":"cuvée","pos":"NOM"} ,
		{"word":"cyan","word_nosc":"cyan","lemma":"cyan","pos":"NOM"} ,
		{"word":"cyanoacrylate","word_nosc":"cyanoacrylate","lemma":"cyanoacrylate","pos":"NOM"} ,
		{"word":"cyanobactérie","word_nosc":"cyanobacterie","lemma":"cyanobactérie","pos":"NOM"} ,
		{"word":"cyanogène","word_nosc":"cyanogene","lemma":"cyanogène","pos":"NOM"} ,
		{"word":"cyanose","word_nosc":"cyanose","lemma":"cyanose","pos":"NOM"} ,
		{"word":"cyanure","word_nosc":"cyanure","lemma":"cyanure","pos":"NOM"} ,
		{"word":"cybercafé","word_nosc":"cybercafe","lemma":"cybercafé","pos":"NOM"} ,
		{"word":"cyberespace","word_nosc":"cyberespace","lemma":"cyberespace","pos":"NOM"} ,
		{"word":"cybermonde","word_nosc":"cybermonde","lemma":"cybermonde","pos":"NOM"} ,
		{"word":"cybernautes","word_nosc":"cybernautes","lemma":"cybernaute","pos":"NOM"} ,
		{"word":"cybernéticien","word_nosc":"cyberneticien","lemma":"cybernéticien","pos":"NOM"} ,
		{"word":"cybernétique","word_nosc":"cybernetique","lemma":"cybernétique","pos":"NOM"} ,
		{"word":"cybernétiques","word_nosc":"cybernetiques","lemma":"cybernétique","pos":"NOM"} ,
		{"word":"cyberspace","word_nosc":"cyberspace","lemma":"cyberspace","pos":"NOM"} ,
		{"word":"cycas","word_nosc":"cycas","lemma":"cycas","pos":"NOM"} ,
		{"word":"cyclamen","word_nosc":"cyclamen","lemma":"cyclamen","pos":"NOM"} ,
		{"word":"cyclamens","word_nosc":"cyclamens","lemma":"cyclamen","pos":"NOM"} ,
		{"word":"cycle","word_nosc":"cycle","lemma":"cycle","pos":"NOM"} ,
		{"word":"cycles","word_nosc":"cycles","lemma":"cycle","pos":"NOM"} ,
		{"word":"cyclisme","word_nosc":"cyclisme","lemma":"cyclisme","pos":"NOM"} ,
		{"word":"cycliste","word_nosc":"cycliste","lemma":"cycliste","pos":"NOM"} ,
		{"word":"cyclistes","word_nosc":"cyclistes","lemma":"cycliste","pos":"NOM"} ,
		{"word":"cyclo","word_nosc":"cyclo","lemma":"cyclo","pos":"NOM"} ,
		{"word":"cyclo-cross","word_nosc":"cyclo-cross","lemma":"cyclo-cross","pos":"NOM"} ,
		{"word":"cyclo-pousse","word_nosc":"cyclo-pousse","lemma":"cyclo-pousse","pos":"NOM"} ,
		{"word":"cyclomoteur","word_nosc":"cyclomoteur","lemma":"cyclomoteur","pos":"NOM"} ,
		{"word":"cyclomoteurs","word_nosc":"cyclomoteurs","lemma":"cyclomoteur","pos":"NOM"} ,
		{"word":"cyclone","word_nosc":"cyclone","lemma":"cyclone","pos":"NOM"} ,
		{"word":"cyclones","word_nosc":"cyclones","lemma":"cyclone","pos":"NOM"} ,
		{"word":"cyclope","word_nosc":"cyclope","lemma":"cyclope","pos":"NOM"} ,
		{"word":"cyclopes","word_nosc":"cyclopes","lemma":"cyclope","pos":"NOM"} ,
		{"word":"cyclopousses","word_nosc":"cyclopousses","lemma":"cyclopousse","pos":"NOM"} ,
		{"word":"cyclorama","word_nosc":"cyclorama","lemma":"cyclorama","pos":"NOM"} ,
		{"word":"cyclosporine","word_nosc":"cyclosporine","lemma":"cyclosporine","pos":"NOM"} ,
		{"word":"cyclostomes","word_nosc":"cyclostomes","lemma":"cyclostome","pos":"NOM"} ,
		{"word":"cyclothymie","word_nosc":"cyclothymie","lemma":"cyclothymie","pos":"NOM"} ,
		{"word":"cyclotron","word_nosc":"cyclotron","lemma":"cyclotron","pos":"NOM"} ,
		{"word":"cygne","word_nosc":"cygne","lemma":"cygne","pos":"NOM"} ,
		{"word":"cygnes","word_nosc":"cygnes","lemma":"cygne","pos":"NOM"} ,
		{"word":"cylindre","word_nosc":"cylindre","lemma":"cylindre","pos":"NOM"} ,
		{"word":"cylindres","word_nosc":"cylindres","lemma":"cylindre","pos":"NOM"} ,
		{"word":"cylindrée","word_nosc":"cylindree","lemma":"cylindrée","pos":"NOM"} ,
		{"word":"cylindrées","word_nosc":"cylindrees","lemma":"cylindrée","pos":"NOM"} ,
		{"word":"cymbale","word_nosc":"cymbale","lemma":"cymbale","pos":"NOM"} ,
		{"word":"cymbales","word_nosc":"cymbales","lemma":"cymbale","pos":"NOM"} ,
		{"word":"cymbaliste","word_nosc":"cymbaliste","lemma":"cymbaliste","pos":"NOM"} ,
		{"word":"cymbalistes","word_nosc":"cymbalistes","lemma":"cymbaliste","pos":"NOM"} ,
		{"word":"cymbalum","word_nosc":"cymbalum","lemma":"cymbalum","pos":"NOM"} ,
		{"word":"cymes","word_nosc":"cymes","lemma":"cyme","pos":"NOM"} ,
		{"word":"cynips","word_nosc":"cynips","lemma":"cynips","pos":"NOM"} ,
		{"word":"cynique","word_nosc":"cynique","lemma":"cynique","pos":"NOM"} ,
		{"word":"cyniques","word_nosc":"cyniques","lemma":"cynique","pos":"NOM"} ,
		{"word":"cynisme","word_nosc":"cynisme","lemma":"cynisme","pos":"NOM"} ,
		{"word":"cynismes","word_nosc":"cynismes","lemma":"cynisme","pos":"NOM"} ,
		{"word":"cynocéphale","word_nosc":"cynocephale","lemma":"cynocéphale","pos":"NOM"} ,
		{"word":"cynocéphales","word_nosc":"cynocephales","lemma":"cynocéphale","pos":"NOM"} ,
		{"word":"cynodrome","word_nosc":"cynodrome","lemma":"cynodrome","pos":"NOM"} ,
		{"word":"cynos","word_nosc":"cynos","lemma":"cyno","pos":"NOM"} ,
		{"word":"cynégétique","word_nosc":"cynegetique","lemma":"cynégétique","pos":"NOM"} ,
		{"word":"cyphoscoliose","word_nosc":"cyphoscoliose","lemma":"cyphoscoliose","pos":"NOM"} ,
		{"word":"cyphose","word_nosc":"cyphose","lemma":"cyphose","pos":"NOM"} ,
		{"word":"cyprin","word_nosc":"cyprin","lemma":"cyprin","pos":"NOM"} ,
		{"word":"cyprinidé","word_nosc":"cyprinide","lemma":"cyprinidé","pos":"NOM"} ,
		{"word":"cyprinidés","word_nosc":"cyprinides","lemma":"cyprinidé","pos":"NOM"} ,
		{"word":"cyprins","word_nosc":"cyprins","lemma":"cyprin","pos":"NOM"} ,
		{"word":"cypriote","word_nosc":"cypriote","lemma":"cypriote","pos":"NOM"} ,
		{"word":"cypriotes","word_nosc":"cypriotes","lemma":"cypriote","pos":"NOM"} ,
		{"word":"cyprès","word_nosc":"cypres","lemma":"cyprès","pos":"NOM"} ,
		{"word":"cyrard","word_nosc":"cyrard","lemma":"cyrard","pos":"NOM"} ,
		{"word":"cyrards","word_nosc":"cyrards","lemma":"cyrard","pos":"NOM"} ,
		{"word":"cystite","word_nosc":"cystite","lemma":"cystite","pos":"NOM"} ,
		{"word":"cystotomie","word_nosc":"cystotomie","lemma":"cystotomie","pos":"NOM"} ,
		{"word":"cytises","word_nosc":"cytises","lemma":"cytise","pos":"NOM"} ,
		{"word":"cytologie","word_nosc":"cytologie","lemma":"cytologie","pos":"NOM"} ,
		{"word":"cytomégalovirus","word_nosc":"cytomegalovirus","lemma":"cytomégalovirus","pos":"NOM"} ,
		{"word":"cytoplasme","word_nosc":"cytoplasme","lemma":"cytoplasme","pos":"NOM"} ,
		{"word":"cytosine","word_nosc":"cytosine","lemma":"cytosine","pos":"NOM"} ,
		{"word":"czar","word_nosc":"czar","lemma":"czar","pos":"NOM"} ,
		{"word":"czardas","word_nosc":"czardas","lemma":"czardas","pos":"NOM"} ,
		{"word":"czars","word_nosc":"czars","lemma":"czar","pos":"NOM"} ,
		{"word":"câblage","word_nosc":"cablage","lemma":"câblage","pos":"NOM"} ,
		{"word":"câblages","word_nosc":"cablages","lemma":"câblage","pos":"NOM"} ,
		{"word":"câble","word_nosc":"cable","lemma":"câble","pos":"NOM"} ,
		{"word":"câblerie","word_nosc":"cablerie","lemma":"câblerie","pos":"NOM"} ,
		{"word":"câbles","word_nosc":"cables","lemma":"câble","pos":"NOM"} ,
		{"word":"câbleur","word_nosc":"cableur","lemma":"câbleur","pos":"NOM"} ,
		{"word":"câbleurs","word_nosc":"cableurs","lemma":"câbleur","pos":"NOM"} ,
		{"word":"câblier","word_nosc":"cablier","lemma":"câblier","pos":"NOM"} ,
		{"word":"câblogramme","word_nosc":"cablogramme","lemma":"câblogramme","pos":"NOM"} ,
		{"word":"câlin","word_nosc":"calin","lemma":"câlin","pos":"NOM"} ,
		{"word":"câline","word_nosc":"caline","lemma":"câlin","pos":"NOM"} ,
		{"word":"câlinerie","word_nosc":"calinerie","lemma":"câlinerie","pos":"NOM"} ,
		{"word":"câlineries","word_nosc":"calineries","lemma":"câlinerie","pos":"NOM"} ,
		{"word":"câlins","word_nosc":"calins","lemma":"câlin","pos":"NOM"} ,
		{"word":"câpre","word_nosc":"capre","lemma":"câpre","pos":"NOM"} ,
		{"word":"câpres","word_nosc":"capres","lemma":"câpre","pos":"NOM"} ,
		{"word":"câpriers","word_nosc":"capriers","lemma":"câprier","pos":"NOM"} ,
		{"word":"cèdre","word_nosc":"cedre","lemma":"cèdre","pos":"NOM"} ,
		{"word":"cèdres","word_nosc":"cedres","lemma":"cèdre","pos":"NOM"} ,
		{"word":"cène","word_nosc":"cene","lemma":"cène","pos":"NOM"} ,
		{"word":"cèpe","word_nosc":"cepe","lemma":"cèpe","pos":"NOM"} ,
		{"word":"cèpes","word_nosc":"cepes","lemma":"cèpe","pos":"NOM"} ,
		{"word":"céara","word_nosc":"ceara","lemma":"céara","pos":"NOM"} ,
		{"word":"cécidomyies","word_nosc":"cecidomyies","lemma":"cécidomyie","pos":"NOM"} ,
		{"word":"cécité","word_nosc":"cecite","lemma":"cécité","pos":"NOM"} ,
		{"word":"cédant","word_nosc":"cedant","lemma":"cédant","pos":"NOM"} ,
		{"word":"cédrat","word_nosc":"cedrat","lemma":"cédrat","pos":"NOM"} ,
		{"word":"cédratier","word_nosc":"cedratier","lemma":"cédratier","pos":"NOM"} ,
		{"word":"cédrats","word_nosc":"cedrats","lemma":"cédrat","pos":"NOM"} ,
		{"word":"cédule","word_nosc":"cedule","lemma":"cédule","pos":"NOM"} ,
		{"word":"cégep","word_nosc":"cegep","lemma":"cégep","pos":"NOM"} ,
		{"word":"cégétiste","word_nosc":"cegetiste","lemma":"cégétiste","pos":"NOM"} ,
		{"word":"cégétistes","word_nosc":"cegetistes","lemma":"cégétiste","pos":"NOM"} ,
		{"word":"céladon","word_nosc":"celadon","lemma":"céladon","pos":"NOM"} ,
		{"word":"céleri","word_nosc":"celeri","lemma":"céleri","pos":"NOM"} ,
		{"word":"céleris","word_nosc":"celeris","lemma":"céleri","pos":"NOM"} ,
		{"word":"célestin","word_nosc":"celestin","lemma":"célestin","pos":"NOM"} ,
		{"word":"célestins","word_nosc":"celestins","lemma":"célestin","pos":"NOM"} ,
		{"word":"célibat","word_nosc":"celibat","lemma":"célibat","pos":"NOM"} ,
		{"word":"célibataire","word_nosc":"celibataire","lemma":"célibataire","pos":"NOM"} ,
		{"word":"célibataires","word_nosc":"celibataires","lemma":"célibataire","pos":"NOM"} ,
		{"word":"célimène","word_nosc":"celimene","lemma":"célimène","pos":"NOM"} ,
		{"word":"célébrant","word_nosc":"celebrant","lemma":"célébrant","pos":"NOM"} ,
		{"word":"célébrants","word_nosc":"celebrants","lemma":"célébrant","pos":"NOM"} ,
		{"word":"célébration","word_nosc":"celebration","lemma":"célébration","pos":"NOM"} ,
		{"word":"célébrations","word_nosc":"celebrations","lemma":"célébration","pos":"NOM"} ,
		{"word":"célébrité","word_nosc":"celebrite","lemma":"célébrité","pos":"NOM"} ,
		{"word":"célébrités","word_nosc":"celebrites","lemma":"célébrité","pos":"NOM"} ,
		{"word":"célérifère","word_nosc":"celerifere","lemma":"célérifère","pos":"NOM"} ,
		{"word":"célérité","word_nosc":"celerite","lemma":"célérité","pos":"NOM"} ,
		{"word":"cénacle","word_nosc":"cenacle","lemma":"cénacle","pos":"NOM"} ,
		{"word":"cénacles","word_nosc":"cenacles","lemma":"cénacle","pos":"NOM"} ,
		{"word":"cénobite","word_nosc":"cenobite","lemma":"cénobite","pos":"NOM"} ,
		{"word":"cénobites","word_nosc":"cenobites","lemma":"cénobite","pos":"NOM"} ,
		{"word":"cénotaphe","word_nosc":"cenotaphe","lemma":"cénotaphe","pos":"NOM"} ,
		{"word":"cépage","word_nosc":"cepage","lemma":"cépage","pos":"NOM"} ,
		{"word":"céphaline","word_nosc":"cephaline","lemma":"céphaline","pos":"NOM"} ,
		{"word":"céphalopode","word_nosc":"cephalopode","lemma":"céphalopode","pos":"NOM"} ,
		{"word":"céphalopodes","word_nosc":"cephalopodes","lemma":"céphalopode","pos":"NOM"} ,
		{"word":"céphalosporine","word_nosc":"cephalosporine","lemma":"céphalosporine","pos":"NOM"} ,
		{"word":"céphalée","word_nosc":"cephalee","lemma":"céphalé","pos":"NOM"} ,
		{"word":"céphalées","word_nosc":"cephalees","lemma":"céphalé","pos":"NOM"} ,
		{"word":"céphalées","word_nosc":"cephalees","lemma":"céphalée","pos":"NOM"} ,
		{"word":"cépée","word_nosc":"cepee","lemma":"cépée","pos":"NOM"} ,
		{"word":"cépées","word_nosc":"cepees","lemma":"cépée","pos":"NOM"} ,
		{"word":"céramique","word_nosc":"ceramique","lemma":"céramique","pos":"NOM"} ,
		{"word":"céramiques","word_nosc":"ceramiques","lemma":"céramique","pos":"NOM"} ,
		{"word":"céramiste","word_nosc":"ceramiste","lemma":"céramiste","pos":"NOM"} ,
		{"word":"cérumen","word_nosc":"cerumen","lemma":"cérumen","pos":"NOM"} ,
		{"word":"céruse","word_nosc":"ceruse","lemma":"céruse","pos":"NOM"} ,
		{"word":"céréale","word_nosc":"cereale","lemma":"céréale","pos":"NOM"} ,
		{"word":"céréales","word_nosc":"cereales","lemma":"céréale","pos":"NOM"} ,
		{"word":"cérébral","word_nosc":"cerebral","lemma":"cérébral","pos":"NOM"} ,
		{"word":"cérébralité","word_nosc":"cerebralite","lemma":"cérébralité","pos":"NOM"} ,
		{"word":"cérémoniaires","word_nosc":"ceremoniaires","lemma":"cérémoniaire","pos":"NOM"} ,
		{"word":"cérémonial","word_nosc":"ceremonial","lemma":"cérémonial","pos":"NOM"} ,
		{"word":"cérémonials","word_nosc":"ceremonials","lemma":"cérémonial","pos":"NOM"} ,
		{"word":"cérémonie","word_nosc":"ceremonie","lemma":"cérémonie","pos":"NOM"} ,
		{"word":"cérémonies","word_nosc":"ceremonies","lemma":"cérémonie","pos":"NOM"} ,
		{"word":"césar","word_nosc":"cesar","lemma":"césar","pos":"NOM"} ,
		{"word":"césarienne","word_nosc":"cesarienne","lemma":"césarienne","pos":"NOM"} ,
		{"word":"césariennes","word_nosc":"cesariennes","lemma":"césarienne","pos":"NOM"} ,
		{"word":"césars","word_nosc":"cesars","lemma":"césar","pos":"NOM"} ,
		{"word":"césium","word_nosc":"cesium","lemma":"césium","pos":"NOM"} ,
		{"word":"césure","word_nosc":"cesure","lemma":"césure","pos":"NOM"} ,
		{"word":"césures","word_nosc":"cesures","lemma":"césure","pos":"NOM"} ,
		{"word":"cétacé","word_nosc":"cetace","lemma":"cétacé","pos":"NOM"} ,
		{"word":"cétacés","word_nosc":"cetaces","lemma":"cétacé","pos":"NOM"} ,
		{"word":"cétoine","word_nosc":"cetoine","lemma":"cétoine","pos":"NOM"} ,
		{"word":"cétoines","word_nosc":"cetoines","lemma":"cétoine","pos":"NOM"} ,
		{"word":"cétone","word_nosc":"cetone","lemma":"cétone","pos":"NOM"} ,
		{"word":"cévadille","word_nosc":"cevadille","lemma":"cévadille","pos":"NOM"} ,
		{"word":"cévenol","word_nosc":"cevenol","lemma":"cévenol","pos":"NOM"} ,
		{"word":"cézigue","word_nosc":"cezigue","lemma":"cézigue","pos":"NOM"} ,
		{"word":"côlon","word_nosc":"colon","lemma":"côlon","pos":"NOM"} ,
		{"word":"cône","word_nosc":"cone","lemma":"cône","pos":"NOM"} ,
		{"word":"cônes","word_nosc":"cones","lemma":"cône","pos":"NOM"} ,
		{"word":"côte","word_nosc":"cote","lemma":"côte","pos":"NOM"} ,
		{"word":"côtelette","word_nosc":"cotelette","lemma":"côtelette","pos":"NOM"} ,
		{"word":"côtelettes","word_nosc":"cotelettes","lemma":"côtelette","pos":"NOM"} ,
		{"word":"côtes","word_nosc":"cotes","lemma":"côte","pos":"NOM"} ,
		{"word":"côtes-du-rhône","word_nosc":"cotes-du-rhone","lemma":"côtes-du-rhône","pos":"NOM"} ,
		{"word":"côtoiements","word_nosc":"cotoiements","lemma":"côtoiement","pos":"NOM"} ,
		{"word":"côté","word_nosc":"cote","lemma":"côté","pos":"NOM"} ,
		{"word":"côtés","word_nosc":"cotes","lemma":"côté","pos":"NOM"} ,
		{"word":"d","word_nosc":"d","lemma":"d","pos":"NOM"} ,
		{"word":"d'","word_nosc":"d'","lemma":"d","pos":"NOM"} ,
		{"word":"dab","word_nosc":"dab","lemma":"dab","pos":"NOM"} ,
		{"word":"daba","word_nosc":"daba","lemma":"daba","pos":"NOM"} ,
		{"word":"dabe","word_nosc":"dabe","lemma":"dabe","pos":"NOM"} ,
		{"word":"dabs","word_nosc":"dabs","lemma":"dab","pos":"NOM"} ,
		{"word":"dabuche","word_nosc":"dabuche","lemma":"dabuche","pos":"NOM"} ,
		{"word":"dabuches","word_nosc":"dabuches","lemma":"dabuche","pos":"NOM"} ,
		{"word":"daces","word_nosc":"daces","lemma":"dace","pos":"NOM"} ,
		{"word":"dache","word_nosc":"dache","lemma":"dache","pos":"NOM"} ,
		{"word":"dacron","word_nosc":"dacron","lemma":"dacron","pos":"NOM"} ,
		{"word":"dactyle","word_nosc":"dactyle","lemma":"dactyle","pos":"NOM"} ,
		{"word":"dactyles","word_nosc":"dactyles","lemma":"dactyle","pos":"NOM"} ,
		{"word":"dactylo","word_nosc":"dactylo","lemma":"dactylo","pos":"NOM"} ,
		{"word":"dactylographe","word_nosc":"dactylographe","lemma":"dactylographe","pos":"NOM"} ,
		{"word":"dactylographes","word_nosc":"dactylographes","lemma":"dactylographe","pos":"NOM"} ,
		{"word":"dactylographie","word_nosc":"dactylographie","lemma":"dactylographie","pos":"NOM"} ,
		{"word":"dactyloptères","word_nosc":"dactylopteres","lemma":"dactyloptère","pos":"NOM"} ,
		{"word":"dactylos","word_nosc":"dactylos","lemma":"dactylo","pos":"NOM"} ,
		{"word":"dactyloscopie","word_nosc":"dactyloscopie","lemma":"dactyloscopie","pos":"NOM"} ,
		{"word":"dada","word_nosc":"dada","lemma":"dada","pos":"NOM"} ,
		{"word":"dadais","word_nosc":"dadais","lemma":"dadais","pos":"NOM"} ,
		{"word":"dadas","word_nosc":"dadas","lemma":"dada","pos":"NOM"} ,
		{"word":"dadaïsme","word_nosc":"dadaisme","lemma":"dadaïsme","pos":"NOM"} ,
		{"word":"dadaïstes","word_nosc":"dadaistes","lemma":"dadaïste","pos":"NOM"} ,
		{"word":"dague","word_nosc":"dague","lemma":"dague","pos":"NOM"} ,
		{"word":"daguerréotype","word_nosc":"daguerreotype","lemma":"daguerréotype","pos":"NOM"} ,
		{"word":"daguerréotypes","word_nosc":"daguerreotypes","lemma":"daguerréotype","pos":"NOM"} ,
		{"word":"dagues","word_nosc":"dagues","lemma":"dague","pos":"NOM"} ,
		{"word":"daguet","word_nosc":"daguet","lemma":"daguet","pos":"NOM"} ,
		{"word":"daguets","word_nosc":"daguets","lemma":"daguet","pos":"NOM"} ,
		{"word":"dahlia","word_nosc":"dahlia","lemma":"dahlia","pos":"NOM"} ,
		{"word":"dahlias","word_nosc":"dahlias","lemma":"dahlia","pos":"NOM"} ,
		{"word":"dahu","word_nosc":"dahu","lemma":"dahu","pos":"NOM"} ,
		{"word":"dahus","word_nosc":"dahus","lemma":"dahu","pos":"NOM"} ,
		{"word":"daim","word_nosc":"daim","lemma":"daim","pos":"NOM"} ,
		{"word":"daims","word_nosc":"daims","lemma":"daim","pos":"NOM"} ,
		{"word":"daine","word_nosc":"daine","lemma":"daine","pos":"NOM"} ,
		{"word":"daiquiri","word_nosc":"daiquiri","lemma":"daiquiri","pos":"NOM"} ,
		{"word":"daiquiris","word_nosc":"daiquiris","lemma":"daiquiri","pos":"NOM"} ,
		{"word":"dais","word_nosc":"dais","lemma":"dais","pos":"NOM"} ,
		{"word":"dakin","word_nosc":"dakin","lemma":"dakin","pos":"NOM"} ,
		{"word":"dakotas","word_nosc":"dakotas","lemma":"dakota","pos":"NOM"} ,
		{"word":"dal","word_nosc":"dal","lemma":"dal","pos":"NOM"} ,
		{"word":"dalaï-lama","word_nosc":"dalai-lama","lemma":"dalaï-lama","pos":"NOM"} ,
		{"word":"dale","word_nosc":"dale","lemma":"dale","pos":"NOM"} ,
		{"word":"dallage","word_nosc":"dallage","lemma":"dallage","pos":"NOM"} ,
		{"word":"dallages","word_nosc":"dallages","lemma":"dallage","pos":"NOM"} ,
		{"word":"dalle","word_nosc":"dalle","lemma":"dalle","pos":"NOM"} ,
		{"word":"dalles","word_nosc":"dalles","lemma":"dalle","pos":"NOM"} ,
		{"word":"dalmatien","word_nosc":"dalmatien","lemma":"dalmatien","pos":"NOM"} ,
		{"word":"dalmatienne","word_nosc":"dalmatienne","lemma":"dalmatien","pos":"NOM"} ,
		{"word":"dalmatiens","word_nosc":"dalmatiens","lemma":"dalmatien","pos":"NOM"} ,
		{"word":"dalmatique","word_nosc":"dalmatique","lemma":"dalmatique","pos":"NOM"} ,
		{"word":"dalmatiques","word_nosc":"dalmatiques","lemma":"dalmatique","pos":"NOM"} ,
		{"word":"daltonien","word_nosc":"daltonien","lemma":"daltonien","pos":"NOM"} ,
		{"word":"daltoniens","word_nosc":"daltoniens","lemma":"daltonien","pos":"NOM"} ,
		{"word":"daltonisme","word_nosc":"daltonisme","lemma":"daltonisme","pos":"NOM"} ,
		{"word":"daltons","word_nosc":"daltons","lemma":"dalton","pos":"NOM"} ,
		{"word":"dam","word_nosc":"dam","lemma":"dam","pos":"NOM"} ,
		{"word":"damage","word_nosc":"damage","lemma":"damage","pos":"NOM"} ,
		{"word":"damas","word_nosc":"damas","lemma":"damas","pos":"NOM"} ,
		{"word":"damasquin","word_nosc":"damasquin","lemma":"damasquin","pos":"NOM"} ,
		{"word":"damasquinage","word_nosc":"damasquinage","lemma":"damasquinage","pos":"NOM"} ,
		{"word":"damasquinerie","word_nosc":"damasquinerie","lemma":"damasquinerie","pos":"NOM"} ,
		{"word":"damasquins","word_nosc":"damasquins","lemma":"damasquin","pos":"NOM"} ,
		{"word":"damasquinure","word_nosc":"damasquinure","lemma":"damasquinure","pos":"NOM"} ,
		{"word":"dame","word_nosc":"dame","lemma":"dame","pos":"NOM"} ,
		{"word":"dame-jeanne","word_nosc":"dame-jeanne","lemma":"dame-jeanne","pos":"NOM"} ,
		{"word":"dameret","word_nosc":"dameret","lemma":"dameret","pos":"NOM"} ,
		{"word":"dames","word_nosc":"dames","lemma":"dame","pos":"NOM"} ,
		{"word":"dames","word_nosc":"dames","lemma":"dames","pos":"NOM"} ,
		{"word":"dames-jeannes","word_nosc":"dames-jeannes","lemma":"dame-jeanne","pos":"NOM"} ,
		{"word":"damier","word_nosc":"damier","lemma":"damier","pos":"NOM"} ,
		{"word":"damiers","word_nosc":"damiers","lemma":"damier","pos":"NOM"} ,
		{"word":"damnation","word_nosc":"damnation","lemma":"damnation","pos":"NOM"} ,
		{"word":"damnations","word_nosc":"damnations","lemma":"damnation","pos":"NOM"} ,
		{"word":"damné","word_nosc":"damne","lemma":"damné","pos":"NOM"} ,
		{"word":"damnée","word_nosc":"damnee","lemma":"damné","pos":"NOM"} ,
		{"word":"damnés","word_nosc":"damnes","lemma":"damné","pos":"NOM"} ,
		{"word":"damoiseau","word_nosc":"damoiseau","lemma":"damoiseau","pos":"NOM"} ,
		{"word":"damoiselle","word_nosc":"damoiselle","lemma":"damoiselle","pos":"NOM"} ,
		{"word":"damoiselles","word_nosc":"damoiselles","lemma":"damoiselle","pos":"NOM"} ,
		{"word":"dams","word_nosc":"dams","lemma":"dam","pos":"NOM"} ,
		{"word":"dan","word_nosc":"dan","lemma":"dan","pos":"NOM"} ,
		{"word":"danaïde","word_nosc":"danaide","lemma":"danaïde","pos":"NOM"} ,
		{"word":"dancing","word_nosc":"dancing","lemma":"dancing","pos":"NOM"} ,
		{"word":"dancings","word_nosc":"dancings","lemma":"dancing","pos":"NOM"} ,
		{"word":"dandinement","word_nosc":"dandinement","lemma":"dandinement","pos":"NOM"} ,
		{"word":"dandinements","word_nosc":"dandinements","lemma":"dandinement","pos":"NOM"} ,
		{"word":"dandy","word_nosc":"dandy","lemma":"dandy","pos":"NOM"} ,
		{"word":"dandys","word_nosc":"dandys","lemma":"dandy","pos":"NOM"} ,
		{"word":"dandysme","word_nosc":"dandysme","lemma":"dandysme","pos":"NOM"} ,
		{"word":"danger","word_nosc":"danger","lemma":"danger","pos":"NOM"} ,
		{"word":"dangerosité","word_nosc":"dangerosite","lemma":"dangerosité","pos":"NOM"} ,
		{"word":"dangers","word_nosc":"dangers","lemma":"danger","pos":"NOM"} ,
		{"word":"dano","word_nosc":"dano","lemma":"dano","pos":"NOM"} ,
		{"word":"danois","word_nosc":"danois","lemma":"danois","pos":"NOM"} ,
		{"word":"danoise","word_nosc":"danoise","lemma":"danois","pos":"NOM"} ,
		{"word":"danse","word_nosc":"danse","lemma":"danse","pos":"NOM"} ,
		{"word":"danses","word_nosc":"danses","lemma":"danse","pos":"NOM"} ,
		{"word":"danseur","word_nosc":"danseur","lemma":"danseur","pos":"NOM"} ,
		{"word":"danseurs","word_nosc":"danseurs","lemma":"danseur","pos":"NOM"} ,
		{"word":"danseuse","word_nosc":"danseuse","lemma":"danseur","pos":"NOM"} ,
		{"word":"danseuses","word_nosc":"danseuses","lemma":"danseur","pos":"NOM"} ,
		{"word":"dao","word_nosc":"dao","lemma":"dao","pos":"NOM"} ,
		{"word":"daphné","word_nosc":"daphne","lemma":"daphné","pos":"NOM"} ,
		{"word":"darce","word_nosc":"darce","lemma":"darce","pos":"NOM"} ,
		{"word":"dard","word_nosc":"dard","lemma":"dard","pos":"NOM"} ,
		{"word":"dards","word_nosc":"dards","lemma":"dard","pos":"NOM"} ,
		{"word":"darioles","word_nosc":"darioles","lemma":"dariole","pos":"NOM"} ,
		{"word":"darne","word_nosc":"darne","lemma":"darne","pos":"NOM"} ,
		{"word":"daron","word_nosc":"daron","lemma":"daron","pos":"NOM"} ,
		{"word":"daronne","word_nosc":"daronne","lemma":"daron","pos":"NOM"} ,
		{"word":"daronnes","word_nosc":"daronnes","lemma":"daron","pos":"NOM"} ,
		{"word":"darons","word_nosc":"darons","lemma":"daron","pos":"NOM"} ,
		{"word":"darse","word_nosc":"darse","lemma":"darse","pos":"NOM"} ,
		{"word":"darses","word_nosc":"darses","lemma":"darse","pos":"NOM"} ,
		{"word":"dartres","word_nosc":"dartres","lemma":"dartre","pos":"NOM"} ,
		{"word":"dasein","word_nosc":"dasein","lemma":"dasein","pos":"NOM"} ,
		{"word":"datage","word_nosc":"datage","lemma":"datage","pos":"NOM"} ,
		{"word":"datation","word_nosc":"datation","lemma":"datation","pos":"NOM"} ,
		{"word":"datcha","word_nosc":"datcha","lemma":"datcha","pos":"NOM"} ,
		{"word":"datchas","word_nosc":"datchas","lemma":"datcha","pos":"NOM"} ,
		{"word":"date","word_nosc":"date","lemma":"date","pos":"NOM"} ,
		{"word":"dates","word_nosc":"dates","lemma":"date","pos":"NOM"} ,
		{"word":"dateur","word_nosc":"dateur","lemma":"dateur","pos":"NOM"} ,
		{"word":"datif","word_nosc":"datif","lemma":"datif","pos":"NOM"} ,
		{"word":"datte","word_nosc":"datte","lemma":"datte","pos":"NOM"} ,
		{"word":"dattes","word_nosc":"dattes","lemma":"datte","pos":"NOM"} ,
		{"word":"dattier","word_nosc":"dattier","lemma":"dattier","pos":"NOM"} ,
		{"word":"dattiers","word_nosc":"dattiers","lemma":"dattier","pos":"NOM"} ,
		{"word":"datura","word_nosc":"datura","lemma":"datura","pos":"NOM"} ,
		{"word":"daturas","word_nosc":"daturas","lemma":"datura","pos":"NOM"} ,
		{"word":"daube","word_nosc":"daube","lemma":"daube","pos":"NOM"} ,
		{"word":"daubes","word_nosc":"daubes","lemma":"daube","pos":"NOM"} ,
		{"word":"daubière","word_nosc":"daubiere","lemma":"daubière","pos":"NOM"} ,
		{"word":"dauphin","word_nosc":"dauphin","lemma":"dauphin","pos":"NOM"} ,
		{"word":"dauphinat","word_nosc":"dauphinat","lemma":"dauphinat","pos":"NOM"} ,
		{"word":"dauphine","word_nosc":"dauphine","lemma":"dauphin","pos":"NOM"} ,
		{"word":"dauphines","word_nosc":"dauphines","lemma":"dauphin","pos":"NOM"} ,
		{"word":"dauphins","word_nosc":"dauphins","lemma":"dauphin","pos":"NOM"} ,
		{"word":"daurade","word_nosc":"daurade","lemma":"daurade","pos":"NOM"} ,
		{"word":"daurades","word_nosc":"daurades","lemma":"daurade","pos":"NOM"} ,
		{"word":"davier","word_nosc":"davier","lemma":"davier","pos":"NOM"} ,
		{"word":"daya","word_nosc":"daya","lemma":"daya","pos":"NOM"} ,
		{"word":"daïmios","word_nosc":"daimios","lemma":"daïmio","pos":"NOM"} ,
		{"word":"daïquiri","word_nosc":"daiquiri","lemma":"daïquiri","pos":"NOM"} ,
		{"word":"daïquiris","word_nosc":"daiquiris","lemma":"daïquiri","pos":"NOM"} ,
		{"word":"dc","word_nosc":"dc","lemma":"dc","pos":"NOM"} ,
		{"word":"de amicis","word_nosc":"de amicis","lemma":"de amicis","pos":"NOM"} ,
		{"word":"de profundis","word_nosc":"de profundis","lemma":"de profundis","pos":"NOM"} ,
		{"word":"de santis","word_nosc":"de santis","lemma":"de santis","pos":"NOM"} ,
		{"word":"deadline","word_nosc":"deadline","lemma":"deadline","pos":"NOM"} ,
		{"word":"deal","word_nosc":"deal","lemma":"deal","pos":"NOM"} ,
		{"word":"dealer","word_nosc":"dealer","lemma":"dealer","pos":"NOM"} ,
		{"word":"dealers","word_nosc":"dealers","lemma":"dealer","pos":"NOM"} ,
		{"word":"deals","word_nosc":"deals","lemma":"deal","pos":"NOM"} ,
		{"word":"debater","word_nosc":"debater","lemma":"debater","pos":"NOM"} ,
		{"word":"decca","word_nosc":"decca","lemma":"decca","pos":"NOM"} ,
		{"word":"deck","word_nosc":"deck","lemma":"deck","pos":"NOM"} ,
		{"word":"decrescendo","word_nosc":"decrescendo","lemma":"decrescendo","pos":"NOM"} ,
		{"word":"dedans","word_nosc":"dedans","lemma":"dedans","pos":"NOM"} ,
		{"word":"degré","word_nosc":"degre","lemma":"degré","pos":"NOM"} ,
		{"word":"degrés","word_nosc":"degres","lemma":"degré","pos":"NOM"} ,
		{"word":"dehors","word_nosc":"dehors","lemma":"dehors","pos":"NOM"} ,
		{"word":"delco","word_nosc":"delco","lemma":"delco","pos":"NOM"} ,
		{"word":"delirium","word_nosc":"delirium","lemma":"delirium","pos":"NOM"} ,
		{"word":"delirium tremens","word_nosc":"delirium tremens","lemma":"delirium tremens","pos":"NOM"} ,
		{"word":"della francesca","word_nosc":"della francesca","lemma":"della francesca","pos":"NOM"} ,
		{"word":"della porta","word_nosc":"della porta","lemma":"della porta","pos":"NOM"} ,
		{"word":"della robbia","word_nosc":"della robbia","lemma":"della robbia","pos":"NOM"} ,
		{"word":"delphinidés","word_nosc":"delphinides","lemma":"delphinidé","pos":"NOM"} ,
		{"word":"delphinium","word_nosc":"delphinium","lemma":"delphinium","pos":"NOM"} ,
		{"word":"delphiniums","word_nosc":"delphiniums","lemma":"delphinium","pos":"NOM"} ,
		{"word":"delta","word_nosc":"delta","lemma":"delta","pos":"NOM"} ,
		{"word":"delta-plane","word_nosc":"delta-plane","lemma":"delta-plane","pos":"NOM"} ,
		{"word":"deltaplane","word_nosc":"deltaplane","lemma":"deltaplane","pos":"NOM"} ,
		{"word":"deltas","word_nosc":"deltas","lemma":"delta","pos":"NOM"} ,
		{"word":"deltoïde","word_nosc":"deltoide","lemma":"deltoïde","pos":"NOM"} ,
		{"word":"deltoïdes","word_nosc":"deltoides","lemma":"deltoïde","pos":"NOM"} ,
		{"word":"demain","word_nosc":"demain","lemma":"demain","pos":"NOM"} ,
		{"word":"demains","word_nosc":"demains","lemma":"demain","pos":"NOM"} ,
		{"word":"demande","word_nosc":"demande","lemma":"demande","pos":"NOM"} ,
		{"word":"demanderesse","word_nosc":"demanderesse","lemma":"demandeur","pos":"NOM"} ,
		{"word":"demandes","word_nosc":"demandes","lemma":"demande","pos":"NOM"} ,
		{"word":"demandeur","word_nosc":"demandeur","lemma":"demandeur","pos":"NOM"} ,
		{"word":"demandeurs","word_nosc":"demandeurs","lemma":"demandeur","pos":"NOM"} ,
		{"word":"demandeuse","word_nosc":"demandeuse","lemma":"demandeur","pos":"NOM"} ,
		{"word":"demeure","word_nosc":"demeure","lemma":"demeure","pos":"NOM"} ,
		{"word":"demeures","word_nosc":"demeures","lemma":"demeure","pos":"NOM"} ,
		{"word":"demeuré","word_nosc":"demeure","lemma":"demeuré","pos":"NOM"} ,
		{"word":"demeurée","word_nosc":"demeuree","lemma":"demeuré","pos":"NOM"} ,
		{"word":"demeurées","word_nosc":"demeurees","lemma":"demeuré","pos":"NOM"} ,
		{"word":"demeurés","word_nosc":"demeures","lemma":"demeuré","pos":"NOM"} ,
		{"word":"demi","word_nosc":"demi","lemma":"demi","pos":"NOM"} ,
		{"word":"demi-barbare","word_nosc":"demi-barbare","lemma":"demi-barbare","pos":"NOM"} ,
		{"word":"demi-bas","word_nosc":"demi-bas","lemma":"demi-bas","pos":"NOM"} ,
		{"word":"demi-bonheur","word_nosc":"demi-bonheur","lemma":"demi-bonheur","pos":"NOM"} ,
		{"word":"demi-bottes","word_nosc":"demi-bottes","lemma":"demi-botte","pos":"NOM"} ,
		{"word":"demi-bouteille","word_nosc":"demi-bouteille","lemma":"demi-bouteille","pos":"NOM"} ,
		{"word":"demi-brigade","word_nosc":"demi-brigade","lemma":"demi-brigade","pos":"NOM"} ,
		{"word":"demi-brigades","word_nosc":"demi-brigades","lemma":"demi-brigade","pos":"NOM"} ,
		{"word":"demi-cent","word_nosc":"demi-cent","lemma":"demi-cent","pos":"NOM"} ,
		{"word":"demi-centimètre","word_nosc":"demi-centimetre","lemma":"demi-centimètre","pos":"NOM"} ,
		{"word":"demi-centre","word_nosc":"demi-centre","lemma":"demi-centre","pos":"NOM"} ,
		{"word":"demi-cercle","word_nosc":"demi-cercle","lemma":"demi-cercle","pos":"NOM"} ,
		{"word":"demi-cercles","word_nosc":"demi-cercles","lemma":"demi-cercle","pos":"NOM"} ,
		{"word":"demi-chagrin","word_nosc":"demi-chagrin","lemma":"demi-chagrin","pos":"NOM"} ,
		{"word":"demi-clair","word_nosc":"demi-clair","lemma":"demi-clair","pos":"NOM"} ,
		{"word":"demi-clarté","word_nosc":"demi-clarte","lemma":"demi-clarté","pos":"NOM"} ,
		{"word":"demi-cloison","word_nosc":"demi-cloison","lemma":"demi-cloison","pos":"NOM"} ,
		{"word":"demi-coma","word_nosc":"demi-coma","lemma":"demi-coma","pos":"NOM"} ,
		{"word":"demi-confidence","word_nosc":"demi-confidence","lemma":"demi-confidence","pos":"NOM"} ,
		{"word":"demi-conscience","word_nosc":"demi-conscience","lemma":"demi-conscience","pos":"NOM"} ,
		{"word":"demi-cylindre","word_nosc":"demi-cylindre","lemma":"demi-cylindre","pos":"NOM"} ,
		{"word":"demi-degré","word_nosc":"demi-degre","lemma":"demi-degré","pos":"NOM"} ,
		{"word":"demi-deuil","word_nosc":"demi-deuil","lemma":"demi-deuil","pos":"NOM"} ,
		{"word":"demi-dieu","word_nosc":"demi-dieu","lemma":"demi-dieu","pos":"NOM"} ,
		{"word":"demi-dieux","word_nosc":"demi-dieux","lemma":"demi-dieux","pos":"NOM"} ,
		{"word":"demi-dose","word_nosc":"demi-dose","lemma":"demi-dose","pos":"NOM"} ,
		{"word":"demi-douzaine","word_nosc":"demi-douzaine","lemma":"demi-douzaine","pos":"NOM"} ,
		{"word":"demi-figure","word_nosc":"demi-figure","lemma":"demi-figure","pos":"NOM"} ,
		{"word":"demi-finale","word_nosc":"demi-finale","lemma":"demi-finale","pos":"NOM"} ,
		{"word":"demi-finales","word_nosc":"demi-finales","lemma":"demi-finale","pos":"NOM"} ,
		{"word":"demi-finaliste","word_nosc":"demi-finaliste","lemma":"demi-finaliste","pos":"NOM"} ,
		{"word":"demi-fond","word_nosc":"demi-fond","lemma":"demi-fond","pos":"NOM"} ,
		{"word":"demi-fou","word_nosc":"demi-fou","lemma":"demi-fou","pos":"NOM"} ,
		{"word":"demi-fous","word_nosc":"demi-fous","lemma":"demi-fou","pos":"NOM"} ,
		{"word":"demi-frère","word_nosc":"demi-frere","lemma":"demi-frère","pos":"NOM"} ,
		{"word":"demi-frères","word_nosc":"demi-freres","lemma":"demi-frère","pos":"NOM"} ,
		{"word":"demi-gros","word_nosc":"demi-gros","lemma":"demi-gros","pos":"NOM"} ,
		{"word":"demi-heure","word_nosc":"demi-heure","lemma":"demi-heure","pos":"NOM"} ,
		{"word":"demi-heure","word_nosc":"demi-heure","lemma":"heur","pos":"NOM"} ,
		{"word":"demi-heures","word_nosc":"demi-heures","lemma":"demi-heure","pos":"NOM"} ,
		{"word":"demi-heures","word_nosc":"demi-heures","lemma":"heur","pos":"NOM"} ,
		{"word":"demi-jour","word_nosc":"demi-jour","lemma":"demi-jour","pos":"NOM"} ,
		{"word":"demi-journée","word_nosc":"demi-journee","lemma":"demi-journée","pos":"NOM"} ,
		{"word":"demi-journées","word_nosc":"demi-journees","lemma":"demi-journée","pos":"NOM"} ,
		{"word":"demi-juif","word_nosc":"demi-juif","lemma":"demi-juif","pos":"NOM"} ,
		{"word":"demi-kilomètre","word_nosc":"demi-kilometre","lemma":"demi-kilomètre","pos":"NOM"} ,
		{"word":"demi-liberté","word_nosc":"demi-liberte","lemma":"demi-liberté","pos":"NOM"} ,
		{"word":"demi-lieue","word_nosc":"demi-lieue","lemma":"demi-lieue","pos":"NOM"} ,
		{"word":"demi-litre","word_nosc":"demi-litre","lemma":"demi-litre","pos":"NOM"} ,
		{"word":"demi-livre","word_nosc":"demi-livre","lemma":"demi-livre","pos":"NOM"} ,
		{"word":"demi-longueur","word_nosc":"demi-longueur","lemma":"demi-longueur","pos":"NOM"} ,
		{"word":"demi-louis","word_nosc":"demi-louis","lemma":"demi-louis","pos":"NOM"} ,
		{"word":"demi-lueur","word_nosc":"demi-lueur","lemma":"demi-lueur","pos":"NOM"} ,
		{"word":"demi-lumière","word_nosc":"demi-lumiere","lemma":"demi-lumière","pos":"NOM"} ,
		{"word":"demi-lune","word_nosc":"demi-lune","lemma":"demi-lune","pos":"NOM"} ,
		{"word":"demi-lunes","word_nosc":"demi-lunes","lemma":"demi-lune","pos":"NOM"} ,
		{"word":"demi-luxe","word_nosc":"demi-luxe","lemma":"demi-luxe","pos":"NOM"} ,
		{"word":"demi-mal","word_nosc":"demi-mal","lemma":"demi-mal","pos":"NOM"} ,
		{"word":"demi-mensonge","word_nosc":"demi-mensonge","lemma":"demi-mensonge","pos":"NOM"} ,
		{"word":"demi-mesure","word_nosc":"demi-mesure","lemma":"demi-mesure","pos":"NOM"} ,
		{"word":"demi-mesures","word_nosc":"demi-mesures","lemma":"demi-mesure","pos":"NOM"} ,
		{"word":"demi-mille","word_nosc":"demi-mille","lemma":"demi-mille","pos":"NOM"} ,
		{"word":"demi-milliard","word_nosc":"demi-milliard","lemma":"demi-milliard","pos":"NOM"} ,
		{"word":"demi-millimètre","word_nosc":"demi-millimetre","lemma":"demi-millimètre","pos":"NOM"} ,
		{"word":"demi-million","word_nosc":"demi-million","lemma":"demi-million","pos":"NOM"} ,
		{"word":"demi-minute","word_nosc":"demi-minute","lemma":"demi-minute","pos":"NOM"} ,
		{"word":"demi-mois","word_nosc":"demi-mois","lemma":"demi-mois","pos":"NOM"} ,
		{"word":"demi-mondaine","word_nosc":"demi-mondaine","lemma":"demi-mondain","pos":"NOM"} ,
		{"word":"demi-mondaines","word_nosc":"demi-mondaines","lemma":"demi-mondain","pos":"NOM"} ,
		{"word":"demi-monde","word_nosc":"demi-monde","lemma":"demi-monde","pos":"NOM"} ,
		{"word":"demi-mort","word_nosc":"demi-mort","lemma":"demi-mort","pos":"NOM"} ,
		{"word":"demi-morte","word_nosc":"demi-morte","lemma":"demi-mort","pos":"NOM"} ,
		{"word":"demi-morts","word_nosc":"demi-morts","lemma":"demi-mort","pos":"NOM"} ,
		{"word":"demi-mot","word_nosc":"demi-mot","lemma":"demi-mot","pos":"NOM"} ,
		{"word":"demi-muid","word_nosc":"demi-muid","lemma":"demi-muid","pos":"NOM"} ,
		{"word":"demi-mètre","word_nosc":"demi-metre","lemma":"demi-mètre","pos":"NOM"} ,
		{"word":"demi-nu","word_nosc":"demi-nu","lemma":"demi-nu","pos":"NOM"} ,
		{"word":"demi-nuit","word_nosc":"demi-nuit","lemma":"demi-nuit","pos":"NOM"} ,
		{"word":"demi-obscurité","word_nosc":"demi-obscurite","lemma":"demi-obscurité","pos":"NOM"} ,
		{"word":"demi-ouvrier","word_nosc":"demi-ouvrier","lemma":"demi-ouvrier","pos":"NOM"} ,
		{"word":"demi-page","word_nosc":"demi-page","lemma":"demi-page","pos":"NOM"} ,
		{"word":"demi-part","word_nosc":"demi-part","lemma":"demi-part","pos":"NOM"} ,
		{"word":"demi-pas","word_nosc":"demi-pas","lemma":"demi-pas","pos":"NOM"} ,
		{"word":"demi-pension","word_nosc":"demi-pension","lemma":"demi-pension","pos":"NOM"} ,
		{"word":"demi-pensionnaire","word_nosc":"demi-pensionnaire","lemma":"demi-pensionnaire","pos":"NOM"} ,
		{"word":"demi-pensionnaires","word_nosc":"demi-pensionnaires","lemma":"demi-pensionnaire","pos":"NOM"} ,
		{"word":"demi-pied","word_nosc":"demi-pied","lemma":"demi-pied","pos":"NOM"} ,
		{"word":"demi-pinte","word_nosc":"demi-pinte","lemma":"demi-pinte","pos":"NOM"} ,
		{"word":"demi-pièce","word_nosc":"demi-piece","lemma":"demi-pièce","pos":"NOM"} ,
		{"word":"demi-place","word_nosc":"demi-place","lemma":"demi-place","pos":"NOM"} ,
		{"word":"demi-point","word_nosc":"demi-point","lemma":"demi-point","pos":"NOM"} ,
		{"word":"demi-pointes","word_nosc":"demi-pointes","lemma":"demi-pointe","pos":"NOM"} ,
		{"word":"demi-porte","word_nosc":"demi-porte","lemma":"demi-porte","pos":"NOM"} ,
		{"word":"demi-portion","word_nosc":"demi-portion","lemma":"demi-portion","pos":"NOM"} ,
		{"word":"demi-portions","word_nosc":"demi-portions","lemma":"demi-portion","pos":"NOM"} ,
		{"word":"demi-pouce","word_nosc":"demi-pouce","lemma":"demi-pouce","pos":"NOM"} ,
		{"word":"demi-quart","word_nosc":"demi-quart","lemma":"demi-quart","pos":"NOM"} ,
		{"word":"demi-queue","word_nosc":"demi-queue","lemma":"demi-queue","pos":"NOM"} ,
		{"word":"demi-ronde","word_nosc":"demi-ronde","lemma":"demi-rond","pos":"NOM"} ,
		{"word":"demi-réussite","word_nosc":"demi-reussite","lemma":"demi-réussite","pos":"NOM"} ,
		{"word":"demi-révérence","word_nosc":"demi-reverence","lemma":"demi-révérence","pos":"NOM"} ,
		{"word":"demi-rêve","word_nosc":"demi-reve","lemma":"demi-rêve","pos":"NOM"} ,
		{"word":"demi-saison","word_nosc":"demi-saison","lemma":"demi-saison","pos":"NOM"} ,
		{"word":"demi-saisons","word_nosc":"demi-saisons","lemma":"demi-saison","pos":"NOM"} ,
		{"word":"demi-sang","word_nosc":"demi-sang","lemma":"demi-sang","pos":"NOM"} ,
		{"word":"demi-sangs","word_nosc":"demi-sangs","lemma":"demi-sang","pos":"NOM"} ,
		{"word":"demi-seconde","word_nosc":"demi-seconde","lemma":"demi-seconde","pos":"NOM"} ,
		{"word":"demi-section","word_nosc":"demi-section","lemma":"demi-section","pos":"NOM"} ,
		{"word":"demi-sel","word_nosc":"demi-sel","lemma":"demi-sel","pos":"NOM"} ,
		{"word":"demi-sels","word_nosc":"demi-sels","lemma":"demi-sel","pos":"NOM"} ,
		{"word":"demi-siècle","word_nosc":"demi-siecle","lemma":"demi-siècle","pos":"NOM"} ,
		{"word":"demi-siècles","word_nosc":"demi-siecles","lemma":"demi-siècle","pos":"NOM"} ,
		{"word":"demi-soeur","word_nosc":"demi-soeur","lemma":"demi-soeur","pos":"NOM"} ,
		{"word":"demi-soeurs","word_nosc":"demi-soeurs","lemma":"demi-soeur","pos":"NOM"} ,
		{"word":"demi-solde","word_nosc":"demi-solde","lemma":"demi-solde","pos":"NOM"} ,
		{"word":"demi-soldes","word_nosc":"demi-soldes","lemma":"demi-solde","pos":"NOM"} ,
		{"word":"demi-sommeil","word_nosc":"demi-sommeil","lemma":"demi-sommeil","pos":"NOM"} ,
		{"word":"demi-sommeils","word_nosc":"demi-sommeils","lemma":"demi-sommeil","pos":"NOM"} ,
		{"word":"demi-somnolence","word_nosc":"demi-somnolence","lemma":"demi-somnolence","pos":"NOM"} ,
		{"word":"demi-sourire","word_nosc":"demi-sourire","lemma":"demi-sourire","pos":"NOM"} ,
		{"word":"demi-sourires","word_nosc":"demi-sourires","lemma":"demi-sourire","pos":"NOM"} ,
		{"word":"demi-succès","word_nosc":"demi-succes","lemma":"demi-succès","pos":"NOM"} ,
		{"word":"demi-suicide","word_nosc":"demi-suicide","lemma":"demi-suicide","pos":"NOM"} ,
		{"word":"demi-talent","word_nosc":"demi-talent","lemma":"demi-talent","pos":"NOM"} ,
		{"word":"demi-tarif","word_nosc":"demi-tarif","lemma":"demi-tarif","pos":"NOM"} ,
		{"word":"demi-tasse","word_nosc":"demi-tasse","lemma":"demi-tasse","pos":"NOM"} ,
		{"word":"demi-teinte","word_nosc":"demi-teinte","lemma":"demi-teinte","pos":"NOM"} ,
		{"word":"demi-teintes","word_nosc":"demi-teintes","lemma":"demi-teinte","pos":"NOM"} ,
		{"word":"demi-ton","word_nosc":"demi-ton","lemma":"demi-ton","pos":"NOM"} ,
		{"word":"demi-tonne","word_nosc":"demi-tonne","lemma":"demi-ton","pos":"NOM"} ,
		{"word":"demi-tonneau","word_nosc":"demi-tonneau","lemma":"demi-tonneau","pos":"NOM"} ,
		{"word":"demi-tons","word_nosc":"demi-tons","lemma":"demi-ton","pos":"NOM"} ,
		{"word":"demi-tour","word_nosc":"demi-tour","lemma":"demi-tour","pos":"NOM"} ,
		{"word":"demi-tours","word_nosc":"demi-tours","lemma":"demi-tour","pos":"NOM"} ,
		{"word":"demi-tête","word_nosc":"demi-tete","lemma":"demi-tête","pos":"NOM"} ,
		{"word":"demi-verre","word_nosc":"demi-verre","lemma":"demi-verre","pos":"NOM"} ,
		{"word":"demi-vertu","word_nosc":"demi-vertu","lemma":"demi-vertu","pos":"NOM"} ,
		{"word":"demi-vie","word_nosc":"demi-vie","lemma":"demi-vie","pos":"NOM"} ,
		{"word":"demi-vierge","word_nosc":"demi-vierge","lemma":"demi-vierge","pos":"NOM"} ,
		{"word":"demi-vierges","word_nosc":"demi-vierges","lemma":"demi-vierge","pos":"NOM"} ,
		{"word":"demi-vies","word_nosc":"demi-vies","lemma":"demi-vie","pos":"NOM"} ,
		{"word":"demi-volte","word_nosc":"demi-volte","lemma":"demi-volte","pos":"NOM"} ,
		{"word":"demi-voltes","word_nosc":"demi-voltes","lemma":"demi-volte","pos":"NOM"} ,
		{"word":"demi-volume","word_nosc":"demi-volume","lemma":"demi-volume","pos":"NOM"} ,
		{"word":"demi-volée","word_nosc":"demi-volee","lemma":"demi-volée","pos":"NOM"} ,
		{"word":"demi-vérité","word_nosc":"demi-verite","lemma":"demi-vérité","pos":"NOM"} ,
		{"word":"demi-échec","word_nosc":"demi-echec","lemma":"demi-échec","pos":"NOM"} ,
		{"word":"demie","word_nosc":"demie","lemma":"demi","pos":"NOM"} ,
		{"word":"demies","word_nosc":"demies","lemma":"demi","pos":"NOM"} ,
		{"word":"demis","word_nosc":"demis","lemma":"demi","pos":"NOM"} ,
		{"word":"demoiselle","word_nosc":"demoiselle","lemma":"damoiseau","pos":"NOM"} ,
		{"word":"demoiselles","word_nosc":"demoiselles","lemma":"damoiseau","pos":"NOM"} ,
		{"word":"dendrite","word_nosc":"dendrite","lemma":"dendrite","pos":"NOM"} ,
		{"word":"dengue","word_nosc":"dengue","lemma":"dengue","pos":"NOM"} ,
		{"word":"denier","word_nosc":"denier","lemma":"denier","pos":"NOM"} ,
		{"word":"deniers","word_nosc":"deniers","lemma":"denier","pos":"NOM"} ,
		{"word":"denim","word_nosc":"denim","lemma":"denim","pos":"NOM"} ,
		{"word":"denims","word_nosc":"denims","lemma":"denim","pos":"NOM"} ,
		{"word":"denrée","word_nosc":"denree","lemma":"denrée","pos":"NOM"} ,
		{"word":"denrées","word_nosc":"denrees","lemma":"denrée","pos":"NOM"} ,
		{"word":"densimètre","word_nosc":"densimetre","lemma":"densimètre","pos":"NOM"} ,
		{"word":"densité","word_nosc":"densite","lemma":"densité","pos":"NOM"} ,
		{"word":"densités","word_nosc":"densites","lemma":"densité","pos":"NOM"} ,
		{"word":"dent","word_nosc":"dent","lemma":"dent","pos":"NOM"} ,
		{"word":"dent-de-lion","word_nosc":"dent-de-lion","lemma":"dent-de-lion","pos":"NOM"} ,
		{"word":"dentaire","word_nosc":"dentaire","lemma":"dentaire","pos":"NOM"} ,
		{"word":"dentaires","word_nosc":"dentaires","lemma":"dentaire","pos":"NOM"} ,
		{"word":"dentelle","word_nosc":"dentelle","lemma":"dentelle","pos":"NOM"} ,
		{"word":"dentelles","word_nosc":"dentelles","lemma":"dentelle","pos":"NOM"} ,
		{"word":"dentellière","word_nosc":"dentelliere","lemma":"dentellière","pos":"NOM"} ,
		{"word":"dentelure","word_nosc":"dentelure","lemma":"dentelure","pos":"NOM"} ,
		{"word":"dentelures","word_nosc":"dentelures","lemma":"dentelure","pos":"NOM"} ,
		{"word":"dentier","word_nosc":"dentier","lemma":"dentier","pos":"NOM"} ,
		{"word":"dentiers","word_nosc":"dentiers","lemma":"dentier","pos":"NOM"} ,
		{"word":"dentifrice","word_nosc":"dentifrice","lemma":"dentifrice","pos":"NOM"} ,
		{"word":"dentifrices","word_nosc":"dentifrices","lemma":"dentifrice","pos":"NOM"} ,
		{"word":"dentiste","word_nosc":"dentiste","lemma":"dentiste","pos":"NOM"} ,
		{"word":"dentisterie","word_nosc":"dentisterie","lemma":"dentisterie","pos":"NOM"} ,
		{"word":"dentistes","word_nosc":"dentistes","lemma":"dentiste","pos":"NOM"} ,
		{"word":"dentition","word_nosc":"dentition","lemma":"dentition","pos":"NOM"} ,
		{"word":"dentitions","word_nosc":"dentitions","lemma":"dentition","pos":"NOM"} ,
		{"word":"dents","word_nosc":"dents","lemma":"dent","pos":"NOM"} ,
		{"word":"denture","word_nosc":"denture","lemma":"denture","pos":"NOM"} ,
		{"word":"dentures","word_nosc":"dentures","lemma":"denture","pos":"NOM"} ,
		{"word":"deo gratias","word_nosc":"deo gratias","lemma":"deo gratias","pos":"NOM"} ,
		{"word":"der","word_nosc":"der","lemma":"der","pos":"NOM"} ,
		{"word":"derby","word_nosc":"derby","lemma":"derby","pos":"NOM"} ,
		{"word":"derbys","word_nosc":"derbys","lemma":"derby","pos":"NOM"} ,
		{"word":"derche","word_nosc":"derche","lemma":"derche","pos":"NOM"} ,
		{"word":"derches","word_nosc":"derches","lemma":"derche","pos":"NOM"} ,
		{"word":"dermatite","word_nosc":"dermatite","lemma":"dermatite","pos":"NOM"} ,
		{"word":"dermatoglyphes","word_nosc":"dermatoglyphes","lemma":"dermatoglyphe","pos":"NOM"} ,
		{"word":"dermatologie","word_nosc":"dermatologie","lemma":"dermatologie","pos":"NOM"} ,
		{"word":"dermatologiste","word_nosc":"dermatologiste","lemma":"dermatologiste","pos":"NOM"} ,
		{"word":"dermatologue","word_nosc":"dermatologue","lemma":"dermatologue","pos":"NOM"} ,
		{"word":"dermatose","word_nosc":"dermatose","lemma":"dermatose","pos":"NOM"} ,
		{"word":"dermatoses","word_nosc":"dermatoses","lemma":"dermatose","pos":"NOM"} ,
		{"word":"derme","word_nosc":"derme","lemma":"derme","pos":"NOM"} ,
		{"word":"dermeste","word_nosc":"dermeste","lemma":"dermeste","pos":"NOM"} ,
		{"word":"dermite","word_nosc":"dermite","lemma":"dermite","pos":"NOM"} ,
		{"word":"dermites","word_nosc":"dermites","lemma":"dermite","pos":"NOM"} ,
		{"word":"dermographie","word_nosc":"dermographie","lemma":"dermographie","pos":"NOM"} ,
		{"word":"dernier","word_nosc":"dernier","lemma":"dernier","pos":"NOM"} ,
		{"word":"dernier-né","word_nosc":"dernier-ne","lemma":"dernier-né","pos":"NOM"} ,
		{"word":"derniers","word_nosc":"derniers","lemma":"dernier","pos":"NOM"} ,
		{"word":"derniers-nés","word_nosc":"derniers-nes","lemma":"dernier-né","pos":"NOM"} ,
		{"word":"dernière","word_nosc":"derniere","lemma":"dernier","pos":"NOM"} ,
		{"word":"dernière-née","word_nosc":"derniere-nee","lemma":"dernière-née","pos":"NOM"} ,
		{"word":"dernières","word_nosc":"dernieres","lemma":"dernier","pos":"NOM"} ,
		{"word":"derrick","word_nosc":"derrick","lemma":"derrick","pos":"NOM"} ,
		{"word":"derricks","word_nosc":"derricks","lemma":"derrick","pos":"NOM"} ,
		{"word":"derrière","word_nosc":"derriere","lemma":"derrière","pos":"NOM"} ,
		{"word":"derrières","word_nosc":"derrieres","lemma":"derrière","pos":"NOM"} ,
		{"word":"ders","word_nosc":"ders","lemma":"der","pos":"NOM"} ,
		{"word":"derviche","word_nosc":"derviche","lemma":"derviche","pos":"NOM"} ,
		{"word":"derviches","word_nosc":"derviches","lemma":"derviche","pos":"NOM"} ,
		{"word":"descamisados","word_nosc":"descamisados","lemma":"descamisado","pos":"NOM"} ,
		{"word":"descendance","word_nosc":"descendance","lemma":"descendance","pos":"NOM"} ,
		{"word":"descendances","word_nosc":"descendances","lemma":"descendance","pos":"NOM"} ,
		{"word":"descendant","word_nosc":"descendant","lemma":"descendant","pos":"NOM"} ,
		{"word":"descendante","word_nosc":"descendante","lemma":"descendant","pos":"NOM"} ,
		{"word":"descendantes","word_nosc":"descendantes","lemma":"descendant","pos":"NOM"} ,
		{"word":"descendants","word_nosc":"descendants","lemma":"descendant","pos":"NOM"} ,
		{"word":"descendeur","word_nosc":"descendeur","lemma":"descendeur","pos":"NOM"} ,
		{"word":"descendeurs","word_nosc":"descendeurs","lemma":"descendeur","pos":"NOM"} ,
		{"word":"descenseur","word_nosc":"descenseur","lemma":"descenseur","pos":"NOM"} ,
		{"word":"descente","word_nosc":"descente","lemma":"descente","pos":"NOM"} ,
		{"word":"descentes","word_nosc":"descentes","lemma":"descente","pos":"NOM"} ,
		{"word":"descriptif","word_nosc":"descriptif","lemma":"descriptif","pos":"NOM"} ,
		{"word":"descriptifs","word_nosc":"descriptifs","lemma":"descriptif","pos":"NOM"} ,
		{"word":"description","word_nosc":"description","lemma":"description","pos":"NOM"} ,
		{"word":"descriptions","word_nosc":"descriptions","lemma":"description","pos":"NOM"} ,
		{"word":"desiderata","word_nosc":"desiderata","lemma":"desiderata","pos":"NOM"} ,
		{"word":"design","word_nosc":"design","lemma":"design","pos":"NOM"} ,
		{"word":"designer","word_nosc":"designer","lemma":"designer","pos":"NOM"} ,
		{"word":"designers","word_nosc":"designers","lemma":"designer","pos":"NOM"} ,
		{"word":"designs","word_nosc":"designs","lemma":"design","pos":"NOM"} ,
		{"word":"desk","word_nosc":"desk","lemma":"desk","pos":"NOM"} ,
		{"word":"desperado","word_nosc":"desperado","lemma":"desperado","pos":"NOM"} ,
		{"word":"desperados","word_nosc":"desperados","lemma":"desperado","pos":"NOM"} ,
		{"word":"despote","word_nosc":"despote","lemma":"despote","pos":"NOM"} ,
		{"word":"despotes","word_nosc":"despotes","lemma":"despote","pos":"NOM"} ,
		{"word":"despotisme","word_nosc":"despotisme","lemma":"despotisme","pos":"NOM"} ,
		{"word":"desquamation","word_nosc":"desquamation","lemma":"desquamation","pos":"NOM"} ,
		{"word":"dessaisissement","word_nosc":"dessaisissement","lemma":"dessaisissement","pos":"NOM"} ,
		{"word":"dessein","word_nosc":"dessein","lemma":"dessein","pos":"NOM"} ,
		{"word":"desseins","word_nosc":"desseins","lemma":"dessein","pos":"NOM"} ,
		{"word":"desserrage","word_nosc":"desserrage","lemma":"desserrage","pos":"NOM"} ,
		{"word":"desserre","word_nosc":"desserre","lemma":"desserre","pos":"NOM"} ,
		{"word":"dessert","word_nosc":"dessert","lemma":"dessert","pos":"NOM"} ,
		{"word":"desserte","word_nosc":"desserte","lemma":"desserte","pos":"NOM"} ,
		{"word":"dessertes","word_nosc":"dessertes","lemma":"desserte","pos":"NOM"} ,
		{"word":"desserts","word_nosc":"desserts","lemma":"dessert","pos":"NOM"} ,
		{"word":"desservant","word_nosc":"desservant","lemma":"desservant","pos":"NOM"} ,
		{"word":"dessiccation","word_nosc":"dessiccation","lemma":"dessiccation","pos":"NOM"} ,
		{"word":"dessin","word_nosc":"dessin","lemma":"dessin","pos":"NOM"} ,
		{"word":"dessinateur","word_nosc":"dessinateur","lemma":"dessinateur","pos":"NOM"} ,
		{"word":"dessinateurs","word_nosc":"dessinateurs","lemma":"dessinateur","pos":"NOM"} ,
		{"word":"dessinatrice","word_nosc":"dessinatrice","lemma":"dessinateur","pos":"NOM"} ,
		{"word":"dessins","word_nosc":"dessins","lemma":"dessin","pos":"NOM"} ,
		{"word":"dessiné","word_nosc":"dessine","lemma":"dessiné","pos":"NOM"} ,
		{"word":"dessinés","word_nosc":"dessines","lemma":"dessiné","pos":"NOM"} ,
		{"word":"dessous","word_nosc":"dessous","lemma":"dessous","pos":"NOM"} ,
		{"word":"dessous-de-bras","word_nosc":"dessous-de-bras","lemma":"dessous-de-bras","pos":"NOM"} ,
		{"word":"dessous-de-plat","word_nosc":"dessous-de-plat","lemma":"dessous-de-plat","pos":"NOM"} ,
		{"word":"dessous-de-table","word_nosc":"dessous-de-table","lemma":"dessous-de-table","pos":"NOM"} ,
		{"word":"dessous-de-verre","word_nosc":"dessous-de-verre","lemma":"dessous-de-verre","pos":"NOM"} ,
		{"word":"dessus","word_nosc":"dessus","lemma":"dessus","pos":"NOM"} ,
		{"word":"dessus-de-lit","word_nosc":"dessus-de-lit","lemma":"dessus-de-lit","pos":"NOM"} ,
		{"word":"dessèchement","word_nosc":"dessechement","lemma":"dessèchement","pos":"NOM"} ,
		{"word":"destin","word_nosc":"destin","lemma":"destin","pos":"NOM"} ,
		{"word":"destinataire","word_nosc":"destinataire","lemma":"destinataire","pos":"NOM"} ,
		{"word":"destinataires","word_nosc":"destinataires","lemma":"destinataire","pos":"NOM"} ,
		{"word":"destination","word_nosc":"destination","lemma":"destination","pos":"NOM"} ,
		{"word":"destinations","word_nosc":"destinations","lemma":"destination","pos":"NOM"} ,
		{"word":"destinatrice","word_nosc":"destinatrice","lemma":"destinateur","pos":"NOM"} ,
		{"word":"destins","word_nosc":"destins","lemma":"destin","pos":"NOM"} ,
		{"word":"destinée","word_nosc":"destinee","lemma":"destinée","pos":"NOM"} ,
		{"word":"destinées","word_nosc":"destinees","lemma":"destinée","pos":"NOM"} ,
		{"word":"destitution","word_nosc":"destitution","lemma":"destitution","pos":"NOM"} ,
		{"word":"destrier","word_nosc":"destrier","lemma":"destrier","pos":"NOM"} ,
		{"word":"destriers","word_nosc":"destriers","lemma":"destrier","pos":"NOM"} ,
		{"word":"destroyer","word_nosc":"destroyer","lemma":"destroyer","pos":"NOM"} ,
		{"word":"destroyers","word_nosc":"destroyers","lemma":"destroyer","pos":"NOM"} ,
		{"word":"destructeur","word_nosc":"destructeur","lemma":"destructeur","pos":"NOM"} ,
		{"word":"destructeurs","word_nosc":"destructeurs","lemma":"destructeur","pos":"NOM"} ,
		{"word":"destruction","word_nosc":"destruction","lemma":"destruction","pos":"NOM"} ,
		{"word":"destructions","word_nosc":"destructions","lemma":"destruction","pos":"NOM"} ,
		{"word":"destructrice","word_nosc":"destructrice","lemma":"destructeur","pos":"NOM"} ,
		{"word":"destructrices","word_nosc":"destructrices","lemma":"destructeur","pos":"NOM"} ,
		{"word":"dette","word_nosc":"dette","lemma":"dette","pos":"NOM"} ,
		{"word":"dettes","word_nosc":"dettes","lemma":"dette","pos":"NOM"} ,
		{"word":"deuche","word_nosc":"deuche","lemma":"deuche","pos":"NOM"} ,
		{"word":"deuil","word_nosc":"deuil","lemma":"deuil","pos":"NOM"} ,
		{"word":"deuils","word_nosc":"deuils","lemma":"deuil","pos":"NOM"} ,
		{"word":"deus ex machina","word_nosc":"deus ex machina","lemma":"deus ex machina","pos":"NOM"} ,
		{"word":"deutérium","word_nosc":"deuterium","lemma":"deutérium","pos":"NOM"} ,
		{"word":"deutéronome","word_nosc":"deuteronome","lemma":"deutéronome","pos":"NOM"} ,
		{"word":"deux-chevaux","word_nosc":"deux-chevaux","lemma":"deux-chevaux","pos":"NOM"} ,
		{"word":"deux-deux","word_nosc":"deux-deux","lemma":"deux-deux","pos":"NOM"} ,
		{"word":"deux-mâts","word_nosc":"deux-mats","lemma":"deux-mâts","pos":"NOM"} ,
		{"word":"deux-pièces","word_nosc":"deux-pieces","lemma":"deux-pièces","pos":"NOM"} ,
		{"word":"deux-points","word_nosc":"deux-points","lemma":"deux-points","pos":"NOM"} ,
		{"word":"deux-ponts","word_nosc":"deux-ponts","lemma":"deux-ponts","pos":"NOM"} ,
		{"word":"deux-quatre","word_nosc":"deux-quatre","lemma":"deux-quatre","pos":"NOM"} ,
		{"word":"deux-roues","word_nosc":"deux-roues","lemma":"deux-roues","pos":"NOM"} ,
		{"word":"deuxième","word_nosc":"deuxieme","lemma":"deuxième","pos":"NOM"} ,
		{"word":"deuxièmes","word_nosc":"deuxiemes","lemma":"deuxième","pos":"NOM"} ,
		{"word":"devanciers","word_nosc":"devanciers","lemma":"devancier","pos":"NOM"} ,
		{"word":"devant","word_nosc":"devant","lemma":"devant","pos":"NOM"} ,
		{"word":"devants","word_nosc":"devants","lemma":"devant","pos":"NOM"} ,
		{"word":"devanture","word_nosc":"devanture","lemma":"devanture","pos":"NOM"} ,
		{"word":"devantures","word_nosc":"devantures","lemma":"devanture","pos":"NOM"} ,
		{"word":"devenir","word_nosc":"devenir","lemma":"devenir","pos":"NOM"} ,
		{"word":"devin","word_nosc":"devin","lemma":"devin","pos":"NOM"} ,
		{"word":"devineresse","word_nosc":"devineresse","lemma":"devineur","pos":"NOM"} ,
		{"word":"devineresses","word_nosc":"devineresses","lemma":"devineur","pos":"NOM"} ,
		{"word":"devinette","word_nosc":"devinette","lemma":"devinette","pos":"NOM"} ,
		{"word":"devinettes","word_nosc":"devinettes","lemma":"devinette","pos":"NOM"} ,
		{"word":"devineur","word_nosc":"devineur","lemma":"devineur","pos":"NOM"} ,
		{"word":"devins","word_nosc":"devins","lemma":"devin","pos":"NOM"} ,
		{"word":"devis","word_nosc":"devis","lemma":"devis","pos":"NOM"} ,
		{"word":"devise","word_nosc":"devise","lemma":"devise","pos":"NOM"} ,
		{"word":"devises","word_nosc":"devises","lemma":"devise","pos":"NOM"} ,
		{"word":"devoir","word_nosc":"devoir","lemma":"devoir","pos":"NOM"} ,
		{"word":"devoirs","word_nosc":"devoirs","lemma":"devoir","pos":"NOM"} ,
		{"word":"devon","word_nosc":"devon","lemma":"devon","pos":"NOM"} ,
		{"word":"devons","word_nosc":"devons","lemma":"devon","pos":"NOM"} ,
		{"word":"dexaméthasone","word_nosc":"dexamethasone","lemma":"dexaméthasone","pos":"NOM"} ,
		{"word":"dextre","word_nosc":"dextre","lemma":"dextre","pos":"NOM"} ,
		{"word":"dextrose","word_nosc":"dextrose","lemma":"dextrose","pos":"NOM"} ,
		{"word":"dextérité","word_nosc":"dexterite","lemma":"dextérité","pos":"NOM"} ,
		{"word":"dey","word_nosc":"dey","lemma":"dey","pos":"NOM"} ,
		{"word":"dharma","word_nosc":"dharma","lemma":"dharma","pos":"NOM"} ,
		{"word":"dhole","word_nosc":"dhole","lemma":"dhole","pos":"NOM"} ,
		{"word":"diable","word_nosc":"diable","lemma":"diable","pos":"NOM"} ,
		{"word":"diablerie","word_nosc":"diablerie","lemma":"diablerie","pos":"NOM"} ,
		{"word":"diableries","word_nosc":"diableries","lemma":"diablerie","pos":"NOM"} ,
		{"word":"diables","word_nosc":"diables","lemma":"diable","pos":"NOM"} ,
		{"word":"diablesse","word_nosc":"diablesse","lemma":"diablesse","pos":"NOM"} ,
		{"word":"diablesses","word_nosc":"diablesses","lemma":"diablesse","pos":"NOM"} ,
		{"word":"diablotin","word_nosc":"diablotin","lemma":"diablotin","pos":"NOM"} ,
		{"word":"diablotins","word_nosc":"diablotins","lemma":"diablotin","pos":"NOM"} ,
		{"word":"diabolique","word_nosc":"diabolique","lemma":"diabolique","pos":"NOM"} ,
		{"word":"diaboliques","word_nosc":"diaboliques","lemma":"diabolique","pos":"NOM"} ,
		{"word":"diabolisme","word_nosc":"diabolisme","lemma":"diabolisme","pos":"NOM"} ,
		{"word":"diabolo","word_nosc":"diabolo","lemma":"diabolo","pos":"NOM"} ,
		{"word":"diabolos","word_nosc":"diabolos","lemma":"diabolo","pos":"NOM"} ,
		{"word":"diabète","word_nosc":"diabete","lemma":"diabète","pos":"NOM"} ,
		{"word":"diabètes","word_nosc":"diabetes","lemma":"diabète","pos":"NOM"} ,
		{"word":"diabétique","word_nosc":"diabetique","lemma":"diabétique","pos":"NOM"} ,
		{"word":"diabétiques","word_nosc":"diabetiques","lemma":"diabétique","pos":"NOM"} ,
		{"word":"diaclase","word_nosc":"diaclase","lemma":"diaclase","pos":"NOM"} ,
		{"word":"diaconesse","word_nosc":"diaconesse","lemma":"diaconesse","pos":"NOM"} ,
		{"word":"diaconesses","word_nosc":"diaconesses","lemma":"diaconesse","pos":"NOM"} ,
		{"word":"diaconie","word_nosc":"diaconie","lemma":"diaconie","pos":"NOM"} ,
		{"word":"diacre","word_nosc":"diacre","lemma":"diacre","pos":"NOM"} ,
		{"word":"diacres","word_nosc":"diacres","lemma":"diacre","pos":"NOM"} ,
		{"word":"diacétylmorphine","word_nosc":"diacetylmorphine","lemma":"diacétylmorphine","pos":"NOM"} ,
		{"word":"diadème","word_nosc":"diademe","lemma":"diadème","pos":"NOM"} ,
		{"word":"diadèmes","word_nosc":"diademes","lemma":"diadème","pos":"NOM"} ,
		{"word":"diagnose","word_nosc":"diagnose","lemma":"diagnose","pos":"NOM"} ,
		{"word":"diagnostic","word_nosc":"diagnostic","lemma":"diagnostic","pos":"NOM"} ,
		{"word":"diagnostics","word_nosc":"diagnostics","lemma":"diagnostic","pos":"NOM"} ,
		{"word":"diagnostiqueur","word_nosc":"diagnostiqueur","lemma":"diagnostiqueur","pos":"NOM"} ,
		{"word":"diagonale","word_nosc":"diagonale","lemma":"diagonale","pos":"NOM"} ,
		{"word":"diagonales","word_nosc":"diagonales","lemma":"diagonale","pos":"NOM"} ,
		{"word":"diagramme","word_nosc":"diagramme","lemma":"diagramme","pos":"NOM"} ,
		{"word":"diagrammes","word_nosc":"diagrammes","lemma":"diagramme","pos":"NOM"} ,
		{"word":"dialecte","word_nosc":"dialecte","lemma":"dialecte","pos":"NOM"} ,
		{"word":"dialectes","word_nosc":"dialectes","lemma":"dialecte","pos":"NOM"} ,
		{"word":"dialecticien","word_nosc":"dialecticien","lemma":"dialecticien","pos":"NOM"} ,
		{"word":"dialecticienne","word_nosc":"dialecticienne","lemma":"dialecticien","pos":"NOM"} ,
		{"word":"dialecticiens","word_nosc":"dialecticiens","lemma":"dialecticien","pos":"NOM"} ,
		{"word":"dialectique","word_nosc":"dialectique","lemma":"dialectique","pos":"NOM"} ,
		{"word":"dialectiques","word_nosc":"dialectiques","lemma":"dialectique","pos":"NOM"} ,
		{"word":"dialogue","word_nosc":"dialogue","lemma":"dialogue","pos":"NOM"} ,
		{"word":"dialogues","word_nosc":"dialogues","lemma":"dialogue","pos":"NOM"} ,
		{"word":"dialoguiste","word_nosc":"dialoguiste","lemma":"dialoguiste","pos":"NOM"} ,
		{"word":"dialoguistes","word_nosc":"dialoguistes","lemma":"dialoguiste","pos":"NOM"} ,
		{"word":"dialyse","word_nosc":"dialyse","lemma":"dialyse","pos":"NOM"} ,
		{"word":"dialyses","word_nosc":"dialyses","lemma":"dialyse","pos":"NOM"} ,
		{"word":"diam","word_nosc":"diam","lemma":"diam","pos":"NOM"} ,
		{"word":"diamant","word_nosc":"diamant","lemma":"diamant","pos":"NOM"} ,
		{"word":"diamantaire","word_nosc":"diamantaire","lemma":"diamantaire","pos":"NOM"} ,
		{"word":"diamantaires","word_nosc":"diamantaires","lemma":"diamantaire","pos":"NOM"} ,
		{"word":"diamants","word_nosc":"diamants","lemma":"diamant","pos":"NOM"} ,
		{"word":"diamine","word_nosc":"diamine","lemma":"diamine","pos":"NOM"} ,
		{"word":"diamorphine","word_nosc":"diamorphine","lemma":"diamorphine","pos":"NOM"} ,
		{"word":"diams","word_nosc":"diams","lemma":"diam","pos":"NOM"} ,
		{"word":"diamètre","word_nosc":"diametre","lemma":"diamètre","pos":"NOM"} ,
		{"word":"diane","word_nosc":"diane","lemma":"diane","pos":"NOM"} ,
		{"word":"dianes","word_nosc":"dianes","lemma":"diane","pos":"NOM"} ,
		{"word":"diantre","word_nosc":"diantre","lemma":"diantre","pos":"NOM"} ,
		{"word":"diapason","word_nosc":"diapason","lemma":"diapason","pos":"NOM"} ,
		{"word":"diapasons","word_nosc":"diapasons","lemma":"diapason","pos":"NOM"} ,
		{"word":"diaphragme","word_nosc":"diaphragme","lemma":"diaphragme","pos":"NOM"} ,
		{"word":"diaphragmes","word_nosc":"diaphragmes","lemma":"diaphragme","pos":"NOM"} ,
		{"word":"diapo","word_nosc":"diapo","lemma":"diapo","pos":"NOM"} ,
		{"word":"diaporama","word_nosc":"diaporama","lemma":"diaporama","pos":"NOM"} ,
		{"word":"diaporamas","word_nosc":"diaporamas","lemma":"diaporama","pos":"NOM"} ,
		{"word":"diapos","word_nosc":"diapos","lemma":"diapo","pos":"NOM"} ,
		{"word":"diapositive","word_nosc":"diapositive","lemma":"diapositive","pos":"NOM"} ,
		{"word":"diapositives","word_nosc":"diapositives","lemma":"diapositive","pos":"NOM"} ,
		{"word":"diaprures","word_nosc":"diaprures","lemma":"diaprure","pos":"NOM"} ,
		{"word":"diarrhée","word_nosc":"diarrhee","lemma":"diarrhée","pos":"NOM"} ,
		{"word":"diarrhées","word_nosc":"diarrhees","lemma":"diarrhée","pos":"NOM"} ,
		{"word":"diaspora","word_nosc":"diaspora","lemma":"diaspora","pos":"NOM"} ,
		{"word":"diasporas","word_nosc":"diasporas","lemma":"diaspora","pos":"NOM"} ,
		{"word":"diastole","word_nosc":"diastole","lemma":"diastole","pos":"NOM"} ,
		{"word":"diastoles","word_nosc":"diastoles","lemma":"diastole","pos":"NOM"} ,
		{"word":"diatomée","word_nosc":"diatomee","lemma":"diatomée","pos":"NOM"} ,
		{"word":"diatomées","word_nosc":"diatomees","lemma":"diatomée","pos":"NOM"} ,
		{"word":"diatribe","word_nosc":"diatribe","lemma":"diatribe","pos":"NOM"} ,
		{"word":"diatribes","word_nosc":"diatribes","lemma":"diatribe","pos":"NOM"} ,
		{"word":"dicastères","word_nosc":"dicasteres","lemma":"dicastère","pos":"NOM"} ,
		{"word":"dichotomie","word_nosc":"dichotomie","lemma":"dichotomie","pos":"NOM"} ,
		{"word":"dichroïsme","word_nosc":"dichroisme","lemma":"dichroïsme","pos":"NOM"} ,
		{"word":"dico","word_nosc":"dico","lemma":"dico","pos":"NOM"} ,
		{"word":"dicos","word_nosc":"dicos","lemma":"dico","pos":"NOM"} ,
		{"word":"dictames","word_nosc":"dictames","lemma":"dictame","pos":"NOM"} ,
		{"word":"dictaphone","word_nosc":"dictaphone","lemma":"dictaphone","pos":"NOM"} ,
		{"word":"dictaphones","word_nosc":"dictaphones","lemma":"dictaphone","pos":"NOM"} ,
		{"word":"dictateur","word_nosc":"dictateur","lemma":"dictateur","pos":"NOM"} ,
		{"word":"dictateurs","word_nosc":"dictateurs","lemma":"dictateur","pos":"NOM"} ,
		{"word":"dictatrice","word_nosc":"dictatrice","lemma":"dictateur","pos":"NOM"} ,
		{"word":"dictats","word_nosc":"dictats","lemma":"dictat","pos":"NOM"} ,
		{"word":"dictature","word_nosc":"dictature","lemma":"dictature","pos":"NOM"} ,
		{"word":"dictatures","word_nosc":"dictatures","lemma":"dictature","pos":"NOM"} ,
		{"word":"diction","word_nosc":"diction","lemma":"diction","pos":"NOM"} ,
		{"word":"dictionnaire","word_nosc":"dictionnaire","lemma":"dictionnaire","pos":"NOM"} ,
		{"word":"dictionnaires","word_nosc":"dictionnaires","lemma":"dictionnaire","pos":"NOM"} ,
		{"word":"dicton","word_nosc":"dicton","lemma":"dicton","pos":"NOM"} ,
		{"word":"dictons","word_nosc":"dictons","lemma":"dicton","pos":"NOM"} ,
		{"word":"dictée","word_nosc":"dictee","lemma":"dictée","pos":"NOM"} ,
		{"word":"dictées","word_nosc":"dictees","lemma":"dictée","pos":"NOM"} ,
		{"word":"didactique","word_nosc":"didactique","lemma":"didactique","pos":"NOM"} ,
		{"word":"die","word_nosc":"die","lemma":"die","pos":"NOM"} ,
		{"word":"dieffenbachia","word_nosc":"dieffenbachia","lemma":"dieffenbachia","pos":"NOM"} ,
		{"word":"diem","word_nosc":"diem","lemma":"diem","pos":"NOM"} ,
		{"word":"dies irae","word_nosc":"dies irae","lemma":"dies irae","pos":"NOM"} ,
		{"word":"diesel","word_nosc":"diesel","lemma":"diesel","pos":"NOM"} ,
		{"word":"diesels","word_nosc":"diesels","lemma":"diesel","pos":"NOM"} ,
		{"word":"dieu","word_nosc":"dieu","lemma":"dieu","pos":"NOM"} ,
		{"word":"dieu-roi","word_nosc":"dieu-roi","lemma":"dieu-roi","pos":"NOM"} ,
		{"word":"dieux","word_nosc":"dieux","lemma":"dieu","pos":"NOM"} ,
		{"word":"diffamateur","word_nosc":"diffamateur","lemma":"diffamateur","pos":"NOM"} ,
		{"word":"diffamateurs","word_nosc":"diffamateurs","lemma":"diffamateur","pos":"NOM"} ,
		{"word":"diffamation","word_nosc":"diffamation","lemma":"diffamation","pos":"NOM"} ,
		{"word":"diffamations","word_nosc":"diffamations","lemma":"diffamation","pos":"NOM"} ,
		{"word":"diffamatrice","word_nosc":"diffamatrice","lemma":"diffamateur","pos":"NOM"} ,
		{"word":"difficulté","word_nosc":"difficulte","lemma":"difficulté","pos":"NOM"} ,
		{"word":"difficultés","word_nosc":"difficultes","lemma":"difficulté","pos":"NOM"} ,
		{"word":"difformité","word_nosc":"difformite","lemma":"difformité","pos":"NOM"} ,
		{"word":"difformités","word_nosc":"difformites","lemma":"difformité","pos":"NOM"} ,
		{"word":"diffraction","word_nosc":"diffraction","lemma":"diffraction","pos":"NOM"} ,
		{"word":"diffuseur","word_nosc":"diffuseur","lemma":"diffuseur","pos":"NOM"} ,
		{"word":"diffuseurs","word_nosc":"diffuseurs","lemma":"diffuseur","pos":"NOM"} ,
		{"word":"diffusion","word_nosc":"diffusion","lemma":"diffusion","pos":"NOM"} ,
		{"word":"diffusions","word_nosc":"diffusions","lemma":"diffusion","pos":"NOM"} ,
		{"word":"différence","word_nosc":"difference","lemma":"différence","pos":"NOM"} ,
		{"word":"différences","word_nosc":"differences","lemma":"différence","pos":"NOM"} ,
		{"word":"différenciation","word_nosc":"differenciation","lemma":"différenciation","pos":"NOM"} ,
		{"word":"différenciations","word_nosc":"differenciations","lemma":"différenciation","pos":"NOM"} ,
		{"word":"différend","word_nosc":"differend","lemma":"différend","pos":"NOM"} ,
		{"word":"différends","word_nosc":"differends","lemma":"différend","pos":"NOM"} ,
		{"word":"différentiel","word_nosc":"differentiel","lemma":"différentiel","pos":"NOM"} ,
		{"word":"différé","word_nosc":"differe","lemma":"différé","pos":"NOM"} ,
		{"word":"digest","word_nosc":"digest","lemma":"digest","pos":"NOM"} ,
		{"word":"digeste","word_nosc":"digeste","lemma":"digeste","pos":"NOM"} ,
		{"word":"digestif","word_nosc":"digestif","lemma":"digestif","pos":"NOM"} ,
		{"word":"digestifs","word_nosc":"digestifs","lemma":"digestif","pos":"NOM"} ,
		{"word":"digestion","word_nosc":"digestion","lemma":"digestion","pos":"NOM"} ,
		{"word":"digestions","word_nosc":"digestions","lemma":"digestion","pos":"NOM"} ,
		{"word":"digests","word_nosc":"digests","lemma":"digest","pos":"NOM"} ,
		{"word":"digicode","word_nosc":"digicode","lemma":"digicode","pos":"NOM"} ,
		{"word":"digit","word_nosc":"digit","lemma":"digit","pos":"NOM"} ,
		{"word":"digitale","word_nosc":"digitale","lemma":"digitale","pos":"NOM"} ,
		{"word":"digitales","word_nosc":"digitales","lemma":"digitale","pos":"NOM"} ,
		{"word":"digitaline","word_nosc":"digitaline","lemma":"digitaline","pos":"NOM"} ,
		{"word":"digitaliseur","word_nosc":"digitaliseur","lemma":"digitaliseur","pos":"NOM"} ,
		{"word":"dignitaire","word_nosc":"dignitaire","lemma":"dignitaire","pos":"NOM"} ,
		{"word":"dignitaires","word_nosc":"dignitaires","lemma":"dignitaire","pos":"NOM"} ,
		{"word":"dignité","word_nosc":"dignite","lemma":"dignité","pos":"NOM"} ,
		{"word":"dignités","word_nosc":"dignites","lemma":"dignité","pos":"NOM"} ,
		{"word":"digression","word_nosc":"digression","lemma":"digression","pos":"NOM"} ,
		{"word":"digressions","word_nosc":"digressions","lemma":"digression","pos":"NOM"} ,
		{"word":"digue","word_nosc":"digue","lemma":"digue","pos":"NOM"} ,
		{"word":"digues","word_nosc":"digues","lemma":"digue","pos":"NOM"} ,
		{"word":"diktat","word_nosc":"diktat","lemma":"diktat","pos":"NOM"} ,
		{"word":"diktats","word_nosc":"diktats","lemma":"diktat","pos":"NOM"} ,
		{"word":"dilapidation","word_nosc":"dilapidation","lemma":"dilapidation","pos":"NOM"} ,
		{"word":"dilatateur","word_nosc":"dilatateur","lemma":"dilatateur","pos":"NOM"} ,
		{"word":"dilatateurs","word_nosc":"dilatateurs","lemma":"dilatateur","pos":"NOM"} ,
		{"word":"dilatation","word_nosc":"dilatation","lemma":"dilatation","pos":"NOM"} ,
		{"word":"dilatations","word_nosc":"dilatations","lemma":"dilatation","pos":"NOM"} ,
		{"word":"dilation","word_nosc":"dilation","lemma":"dilation","pos":"NOM"} ,
		{"word":"dilection","word_nosc":"dilection","lemma":"dilection","pos":"NOM"} ,
		{"word":"dilemme","word_nosc":"dilemme","lemma":"dilemme","pos":"NOM"} ,
		{"word":"dilemmes","word_nosc":"dilemmes","lemma":"dilemme","pos":"NOM"} ,
		{"word":"dilettante","word_nosc":"dilettante","lemma":"dilettante","pos":"NOM"} ,
		{"word":"dilettantes","word_nosc":"dilettantes","lemma":"dilettante","pos":"NOM"} ,
		{"word":"dilettantisme","word_nosc":"dilettantisme","lemma":"dilettantisme","pos":"NOM"} ,
		{"word":"dilettantismes","word_nosc":"dilettantismes","lemma":"dilettantisme","pos":"NOM"} ,
		{"word":"diligence","word_nosc":"diligence","lemma":"diligence","pos":"NOM"} ,
		{"word":"diligences","word_nosc":"diligences","lemma":"diligence","pos":"NOM"} ,
		{"word":"diluant","word_nosc":"diluant","lemma":"diluant","pos":"NOM"} ,
		{"word":"dilution","word_nosc":"dilution","lemma":"dilution","pos":"NOM"} ,
		{"word":"dimanche","word_nosc":"dimanche","lemma":"dimanche","pos":"NOM"} ,
		{"word":"dimanches","word_nosc":"dimanches","lemma":"dimanche","pos":"NOM"} ,
		{"word":"dimension","word_nosc":"dimension","lemma":"dimension","pos":"NOM"} ,
		{"word":"dimensionnement","word_nosc":"dimensionnement","lemma":"dimensionnement","pos":"NOM"} ,
		{"word":"dimensions","word_nosc":"dimensions","lemma":"dimension","pos":"NOM"} ,
		{"word":"diminutif","word_nosc":"diminutif","lemma":"diminutif","pos":"NOM"} ,
		{"word":"diminutifs","word_nosc":"diminutifs","lemma":"diminutif","pos":"NOM"} ,
		{"word":"diminution","word_nosc":"diminution","lemma":"diminution","pos":"NOM"} ,
		{"word":"diminutions","word_nosc":"diminutions","lemma":"diminution","pos":"NOM"} ,
		{"word":"dinamiteros","word_nosc":"dinamiteros","lemma":"dinamitero","pos":"NOM"} ,
		{"word":"dinanderie","word_nosc":"dinanderie","lemma":"dinanderie","pos":"NOM"} ,
		{"word":"dinar","word_nosc":"dinar","lemma":"dinar","pos":"NOM"} ,
		{"word":"dinars","word_nosc":"dinars","lemma":"dinar","pos":"NOM"} ,
		{"word":"dinde","word_nosc":"dinde","lemma":"dinde","pos":"NOM"} ,
		{"word":"dindes","word_nosc":"dindes","lemma":"dinde","pos":"NOM"} ,
		{"word":"dindon","word_nosc":"dindon","lemma":"dindon","pos":"NOM"} ,
		{"word":"dindonneau","word_nosc":"dindonneau","lemma":"dindonneau","pos":"NOM"} ,
		{"word":"dindonneaux","word_nosc":"dindonneaux","lemma":"dindonneau","pos":"NOM"} ,
		{"word":"dindons","word_nosc":"dindons","lemma":"dindon","pos":"NOM"} ,
		{"word":"dine","word_nosc":"dine","lemma":"dine","pos":"NOM"} ,
		{"word":"dingo","word_nosc":"dingo","lemma":"dingo","pos":"NOM"} ,
		{"word":"dingos","word_nosc":"dingos","lemma":"dingo","pos":"NOM"} ,
		{"word":"dingue","word_nosc":"dingue","lemma":"dingue","pos":"NOM"} ,
		{"word":"dinguerie","word_nosc":"dinguerie","lemma":"dinguerie","pos":"NOM"} ,
		{"word":"dingueries","word_nosc":"dingueries","lemma":"dinguerie","pos":"NOM"} ,
		{"word":"dingues","word_nosc":"dingues","lemma":"dingue","pos":"NOM"} ,
		{"word":"dinosaure","word_nosc":"dinosaure","lemma":"dinosaure","pos":"NOM"} ,
		{"word":"dinosaures","word_nosc":"dinosaures","lemma":"dinosaure","pos":"NOM"} ,
		{"word":"diocèse","word_nosc":"diocese","lemma":"diocèse","pos":"NOM"} ,
		{"word":"diocèses","word_nosc":"dioceses","lemma":"diocèse","pos":"NOM"} ,
		{"word":"diode","word_nosc":"diode","lemma":"diode","pos":"NOM"} ,
		{"word":"diodon","word_nosc":"diodon","lemma":"diodon","pos":"NOM"} ,
		{"word":"dionée","word_nosc":"dionee","lemma":"dionée","pos":"NOM"} ,
		{"word":"dioptre","word_nosc":"dioptre","lemma":"dioptre","pos":"NOM"} ,
		{"word":"dioptries","word_nosc":"dioptries","lemma":"dioptrie","pos":"NOM"} ,
		{"word":"diorama","word_nosc":"diorama","lemma":"diorama","pos":"NOM"} ,
		{"word":"dioramas","word_nosc":"dioramas","lemma":"diorama","pos":"NOM"} ,
		{"word":"diorite","word_nosc":"diorite","lemma":"diorite","pos":"NOM"} ,
		{"word":"dioxine","word_nosc":"dioxine","lemma":"dioxine","pos":"NOM"} ,
		{"word":"dioxines","word_nosc":"dioxines","lemma":"dioxine","pos":"NOM"} ,
		{"word":"dioxyde","word_nosc":"dioxyde","lemma":"dioxyde","pos":"NOM"} ,
		{"word":"diphtongue","word_nosc":"diphtongue","lemma":"diphtongue","pos":"NOM"} ,
		{"word":"diphtongues","word_nosc":"diphtongues","lemma":"diphtongue","pos":"NOM"} ,
		{"word":"diphtérie","word_nosc":"diphterie","lemma":"diphtérie","pos":"NOM"} ,
		{"word":"diphényle","word_nosc":"diphenyle","lemma":"diphényle","pos":"NOM"} ,
		{"word":"diplodocus","word_nosc":"diplodocus","lemma":"diplodocus","pos":"NOM"} ,
		{"word":"diplomate","word_nosc":"diplomate","lemma":"diplomate","pos":"NOM"} ,
		{"word":"diplomates","word_nosc":"diplomates","lemma":"diplomate","pos":"NOM"} ,
		{"word":"diplomatie","word_nosc":"diplomatie","lemma":"diplomatie","pos":"NOM"} ,
		{"word":"diplopie","word_nosc":"diplopie","lemma":"diplopie","pos":"NOM"} ,
		{"word":"diplôme","word_nosc":"diplome","lemma":"diplôme","pos":"NOM"} ,
		{"word":"diplômes","word_nosc":"diplomes","lemma":"diplôme","pos":"NOM"} ,
		{"word":"diplômé","word_nosc":"diplome","lemma":"diplômé","pos":"NOM"} ,
		{"word":"diplômée","word_nosc":"diplomee","lemma":"diplômé","pos":"NOM"} ,
		{"word":"diplômées","word_nosc":"diplomees","lemma":"diplômé","pos":"NOM"} ,
		{"word":"diplômés","word_nosc":"diplomes","lemma":"diplômé","pos":"NOM"} ,
		{"word":"dipneuste","word_nosc":"dipneuste","lemma":"dipneuste","pos":"NOM"} ,
		{"word":"dipneustes","word_nosc":"dipneustes","lemma":"dipneuste","pos":"NOM"} ,
		{"word":"dipsomane","word_nosc":"dipsomane","lemma":"dipsomane","pos":"NOM"} ,
		{"word":"dipsomanes","word_nosc":"dipsomanes","lemma":"dipsomane","pos":"NOM"} ,
		{"word":"dipsomanie","word_nosc":"dipsomanie","lemma":"dipsomanie","pos":"NOM"} ,
		{"word":"diptyque","word_nosc":"diptyque","lemma":"diptyque","pos":"NOM"} ,
		{"word":"dipôle","word_nosc":"dipole","lemma":"dipôle","pos":"NOM"} ,
		{"word":"dire","word_nosc":"dire","lemma":"dire","pos":"NOM"} ,
		{"word":"direct","word_nosc":"direct","lemma":"direct","pos":"NOM"} ,
		{"word":"directeur","word_nosc":"directeur","lemma":"directeur","pos":"NOM"} ,
		{"word":"directeur-adjoint","word_nosc":"directeur-adjoint","lemma":"directeur-adjoint","pos":"NOM"} ,
		{"word":"directeurs","word_nosc":"directeurs","lemma":"directeur","pos":"NOM"} ,
		{"word":"direction","word_nosc":"direction","lemma":"direction","pos":"NOM"} ,
		{"word":"directions","word_nosc":"directions","lemma":"direction","pos":"NOM"} ,
		{"word":"directive","word_nosc":"directive","lemma":"directive","pos":"NOM"} ,
		{"word":"directives","word_nosc":"directives","lemma":"directive","pos":"NOM"} ,
		{"word":"directoire","word_nosc":"directoire","lemma":"directoire","pos":"NOM"} ,
		{"word":"directorat","word_nosc":"directorat","lemma":"directorat","pos":"NOM"} ,
		{"word":"directrice","word_nosc":"directrice","lemma":"directeur","pos":"NOM"} ,
		{"word":"directrices","word_nosc":"directrices","lemma":"directeur","pos":"NOM"} ,
		{"word":"directs","word_nosc":"directs","lemma":"direct","pos":"NOM"} ,
		{"word":"dires","word_nosc":"dires","lemma":"dire","pos":"NOM"} ,
		{"word":"dirham","word_nosc":"dirham","lemma":"dirham","pos":"NOM"} ,
		{"word":"dirhams","word_nosc":"dirhams","lemma":"dirham","pos":"NOM"} ,
		{"word":"dirigeable","word_nosc":"dirigeable","lemma":"dirigeable","pos":"NOM"} ,
		{"word":"dirigeables","word_nosc":"dirigeables","lemma":"dirigeable","pos":"NOM"} ,
		{"word":"dirigeant","word_nosc":"dirigeant","lemma":"dirigeant","pos":"NOM"} ,
		{"word":"dirigeante","word_nosc":"dirigeante","lemma":"dirigeant","pos":"NOM"} ,
		{"word":"dirigeants","word_nosc":"dirigeants","lemma":"dirigeant","pos":"NOM"} ,
		{"word":"dirlo","word_nosc":"dirlo","lemma":"dirlo","pos":"NOM"} ,
		{"word":"dirlos","word_nosc":"dirlos","lemma":"dirlo","pos":"NOM"} ,
		{"word":"disc-jockey","word_nosc":"disc-jockey","lemma":"disc-jockey","pos":"NOM"} ,
		{"word":"disc-jockeys","word_nosc":"disc-jockeys","lemma":"disc-jockey","pos":"NOM"} ,
		{"word":"discernement","word_nosc":"discernement","lemma":"discernement","pos":"NOM"} ,
		{"word":"disciple","word_nosc":"disciple","lemma":"disciple","pos":"NOM"} ,
		{"word":"disciple-roi","word_nosc":"disciple-roi","lemma":"disciple-roi","pos":"NOM"} ,
		{"word":"disciples","word_nosc":"disciples","lemma":"disciple","pos":"NOM"} ,
		{"word":"discipline","word_nosc":"discipline","lemma":"discipline","pos":"NOM"} ,
		{"word":"disciplines","word_nosc":"disciplines","lemma":"discipline","pos":"NOM"} ,
		{"word":"disco","word_nosc":"disco","lemma":"disco","pos":"NOM"} ,
		{"word":"discobole","word_nosc":"discobole","lemma":"discobole","pos":"NOM"} ,
		{"word":"discoboles","word_nosc":"discoboles","lemma":"discobole","pos":"NOM"} ,
		{"word":"discographie","word_nosc":"discographie","lemma":"discographie","pos":"NOM"} ,
		{"word":"discontinuité","word_nosc":"discontinuite","lemma":"discontinuité","pos":"NOM"} ,
		{"word":"discordance","word_nosc":"discordance","lemma":"discordance","pos":"NOM"} ,
		{"word":"discordances","word_nosc":"discordances","lemma":"discordance","pos":"NOM"} ,
		{"word":"discorde","word_nosc":"discorde","lemma":"discorde","pos":"NOM"} ,
		{"word":"discordes","word_nosc":"discordes","lemma":"discord","pos":"NOM"} ,
		{"word":"discordes","word_nosc":"discordes","lemma":"discorde","pos":"NOM"} ,
		{"word":"discos","word_nosc":"discos","lemma":"disco","pos":"NOM"} ,
		{"word":"discothèque","word_nosc":"discotheque","lemma":"discothèque","pos":"NOM"} ,
		{"word":"discothèques","word_nosc":"discotheques","lemma":"discothèque","pos":"NOM"} ,
		{"word":"discount","word_nosc":"discount","lemma":"discount","pos":"NOM"} ,
		{"word":"discoureur","word_nosc":"discoureur","lemma":"discoureur","pos":"NOM"} ,
		{"word":"discoureurs","word_nosc":"discoureurs","lemma":"discoureur","pos":"NOM"} ,
		{"word":"discours","word_nosc":"discours","lemma":"discours","pos":"NOM"} ,
		{"word":"discrimination","word_nosc":"discrimination","lemma":"discrimination","pos":"NOM"} ,
		{"word":"discriminations","word_nosc":"discriminations","lemma":"discrimination","pos":"NOM"} ,
		{"word":"discrédit","word_nosc":"discredit","lemma":"discrédit","pos":"NOM"} ,
		{"word":"discrétion","word_nosc":"discretion","lemma":"discrétion","pos":"NOM"} ,
		{"word":"disculpation","word_nosc":"disculpation","lemma":"disculpation","pos":"NOM"} ,
		{"word":"discussion","word_nosc":"discussion","lemma":"discussion","pos":"NOM"} ,
		{"word":"discussions","word_nosc":"discussions","lemma":"discussion","pos":"NOM"} ,
		{"word":"discutailleries","word_nosc":"discutailleries","lemma":"discutaillerie","pos":"NOM"} ,
		{"word":"discutailleur","word_nosc":"discutailleur","lemma":"discutailleur","pos":"NOM"} ,
		{"word":"disette","word_nosc":"disette","lemma":"disette","pos":"NOM"} ,
		{"word":"disettes","word_nosc":"disettes","lemma":"disette","pos":"NOM"} ,
		{"word":"diseur","word_nosc":"diseur","lemma":"diseur","pos":"NOM"} ,
		{"word":"diseurs","word_nosc":"diseurs","lemma":"diseur","pos":"NOM"} ,
		{"word":"diseuse","word_nosc":"diseuse","lemma":"diseur","pos":"NOM"} ,
		{"word":"diseuses","word_nosc":"diseuses","lemma":"diseur","pos":"NOM"} ,
		{"word":"disfonctionnement","word_nosc":"disfonctionnement","lemma":"disfonctionnement","pos":"NOM"} ,
		{"word":"disgrâce","word_nosc":"disgrace","lemma":"disgrâce","pos":"NOM"} ,
		{"word":"disgrâces","word_nosc":"disgraces","lemma":"disgrâce","pos":"NOM"} ,
		{"word":"disharmonie","word_nosc":"disharmonie","lemma":"disharmonie","pos":"NOM"} ,
		{"word":"disjoncteur","word_nosc":"disjoncteur","lemma":"disjoncteur","pos":"NOM"} ,
		{"word":"disjoncteurs","word_nosc":"disjoncteurs","lemma":"disjoncteur","pos":"NOM"} ,
		{"word":"disjonction","word_nosc":"disjonction","lemma":"disjonction","pos":"NOM"} ,
		{"word":"disjonctions","word_nosc":"disjonctions","lemma":"disjonction","pos":"NOM"} ,
		{"word":"diskette","word_nosc":"diskette","lemma":"diskette","pos":"NOM"} ,
		{"word":"dislocation","word_nosc":"dislocation","lemma":"dislocation","pos":"NOM"} ,
		{"word":"dislocations","word_nosc":"dislocations","lemma":"dislocation","pos":"NOM"} ,
		{"word":"disparate","word_nosc":"disparate","lemma":"disparate","pos":"NOM"} ,
		{"word":"disparates","word_nosc":"disparates","lemma":"disparate","pos":"NOM"} ,
		{"word":"disparition","word_nosc":"disparition","lemma":"disparition","pos":"NOM"} ,
		{"word":"disparitions","word_nosc":"disparitions","lemma":"disparition","pos":"NOM"} ,
		{"word":"disparité","word_nosc":"disparite","lemma":"disparité","pos":"NOM"} ,
		{"word":"disparu","word_nosc":"disparu","lemma":"disparu","pos":"NOM"} ,
		{"word":"disparue","word_nosc":"disparue","lemma":"disparu","pos":"NOM"} ,
		{"word":"disparues","word_nosc":"disparues","lemma":"disparu","pos":"NOM"} ,
		{"word":"disparus","word_nosc":"disparus","lemma":"disparu","pos":"NOM"} ,
		{"word":"dispatching","word_nosc":"dispatching","lemma":"dispatching","pos":"NOM"} ,
		{"word":"dispensaire","word_nosc":"dispensaire","lemma":"dispensaire","pos":"NOM"} ,
		{"word":"dispensaires","word_nosc":"dispensaires","lemma":"dispensaire","pos":"NOM"} ,
		{"word":"dispensateur","word_nosc":"dispensateur","lemma":"dispensateur","pos":"NOM"} ,
		{"word":"dispensateurs","word_nosc":"dispensateurs","lemma":"dispensateur","pos":"NOM"} ,
		{"word":"dispensation","word_nosc":"dispensation","lemma":"dispensation","pos":"NOM"} ,
		{"word":"dispensatrice","word_nosc":"dispensatrice","lemma":"dispensateur","pos":"NOM"} ,
		{"word":"dispensatrices","word_nosc":"dispensatrices","lemma":"dispensateur","pos":"NOM"} ,
		{"word":"dispense","word_nosc":"dispense","lemma":"dispense","pos":"NOM"} ,
		{"word":"dispenses","word_nosc":"dispenses","lemma":"dispense","pos":"NOM"} ,
		{"word":"dispersement","word_nosc":"dispersement","lemma":"dispersement","pos":"NOM"} ,
		{"word":"dispersion","word_nosc":"dispersion","lemma":"dispersion","pos":"NOM"} ,
		{"word":"dispersions","word_nosc":"dispersions","lemma":"dispersion","pos":"NOM"} ,
		{"word":"disponibilité","word_nosc":"disponibilite","lemma":"disponibilité","pos":"NOM"} ,
		{"word":"disponibilités","word_nosc":"disponibilites","lemma":"disponibilité","pos":"NOM"} ,
		{"word":"disposant","word_nosc":"disposant","lemma":"disposant","pos":"NOM"} ,
		{"word":"dispositif","word_nosc":"dispositif","lemma":"dispositif","pos":"NOM"} ,
		{"word":"dispositifs","word_nosc":"dispositifs","lemma":"dispositif","pos":"NOM"} ,
		{"word":"disposition","word_nosc":"disposition","lemma":"disposition","pos":"NOM"} ,
		{"word":"dispositions","word_nosc":"dispositions","lemma":"disposition","pos":"NOM"} ,
		{"word":"disproportion","word_nosc":"disproportion","lemma":"disproportion","pos":"NOM"} ,
		{"word":"disproportions","word_nosc":"disproportions","lemma":"disproportion","pos":"NOM"} ,
		{"word":"dispute","word_nosc":"dispute","lemma":"dispute","pos":"NOM"} ,
		{"word":"disputes","word_nosc":"disputes","lemma":"dispute","pos":"NOM"} ,
		{"word":"disputeur","word_nosc":"disputeur","lemma":"disputeur","pos":"NOM"} ,
		{"word":"disquaire","word_nosc":"disquaire","lemma":"disquaire","pos":"NOM"} ,
		{"word":"disquaires","word_nosc":"disquaires","lemma":"disquaire","pos":"NOM"} ,
		{"word":"disqualification","word_nosc":"disqualification","lemma":"disqualification","pos":"NOM"} ,
		{"word":"disqualifications","word_nosc":"disqualifications","lemma":"disqualification","pos":"NOM"} ,
		{"word":"disque","word_nosc":"disque","lemma":"disque","pos":"NOM"} ,
		{"word":"disque-jockey","word_nosc":"disque-jockey","lemma":"disque-jockey","pos":"NOM"} ,
		{"word":"disques","word_nosc":"disques","lemma":"disque","pos":"NOM"} ,
		{"word":"disquette","word_nosc":"disquette","lemma":"disquette","pos":"NOM"} ,
		{"word":"disquettes","word_nosc":"disquettes","lemma":"disquette","pos":"NOM"} ,
		{"word":"disruption","word_nosc":"disruption","lemma":"disruption","pos":"NOM"} ,
		{"word":"dissection","word_nosc":"dissection","lemma":"dissection","pos":"NOM"} ,
		{"word":"dissections","word_nosc":"dissections","lemma":"dissection","pos":"NOM"} ,
		{"word":"dissemblance","word_nosc":"dissemblance","lemma":"dissemblance","pos":"NOM"} ,
		{"word":"dissension","word_nosc":"dissension","lemma":"dissension","pos":"NOM"} ,
		{"word":"dissensions","word_nosc":"dissensions","lemma":"dissension","pos":"NOM"} ,
		{"word":"dissentiment","word_nosc":"dissentiment","lemma":"dissentiment","pos":"NOM"} ,
		{"word":"dissentiments","word_nosc":"dissentiments","lemma":"dissentiment","pos":"NOM"} ,
		{"word":"dissertation","word_nosc":"dissertation","lemma":"dissertation","pos":"NOM"} ,
		{"word":"dissertations","word_nosc":"dissertations","lemma":"dissertation","pos":"NOM"} ,
		{"word":"dissidence","word_nosc":"dissidence","lemma":"dissidence","pos":"NOM"} ,
		{"word":"dissidences","word_nosc":"dissidences","lemma":"dissidence","pos":"NOM"} ,
		{"word":"dissident","word_nosc":"dissident","lemma":"dissident","pos":"NOM"} ,
		{"word":"dissidentes","word_nosc":"dissidentes","lemma":"dissident","pos":"NOM"} ,
		{"word":"dissidents","word_nosc":"dissidents","lemma":"dissident","pos":"NOM"} ,
		{"word":"dissimulation","word_nosc":"dissimulation","lemma":"dissimulation","pos":"NOM"} ,
		{"word":"dissimulations","word_nosc":"dissimulations","lemma":"dissimulation","pos":"NOM"} ,
		{"word":"dissipateur","word_nosc":"dissipateur","lemma":"dissipateur","pos":"NOM"} ,
		{"word":"dissipateurs","word_nosc":"dissipateurs","lemma":"dissipateur","pos":"NOM"} ,
		{"word":"dissipation","word_nosc":"dissipation","lemma":"dissipation","pos":"NOM"} ,
		{"word":"dissipations","word_nosc":"dissipations","lemma":"dissipation","pos":"NOM"} ,
		{"word":"dissociation","word_nosc":"dissociation","lemma":"dissociation","pos":"NOM"} ,
		{"word":"dissociations","word_nosc":"dissociations","lemma":"dissociation","pos":"NOM"} ,
		{"word":"dissolution","word_nosc":"dissolution","lemma":"dissolution","pos":"NOM"} ,
		{"word":"dissolvant","word_nosc":"dissolvant","lemma":"dissolvant","pos":"NOM"} ,
		{"word":"dissolvants","word_nosc":"dissolvants","lemma":"dissolvant","pos":"NOM"} ,
		{"word":"dissonance","word_nosc":"dissonance","lemma":"dissonance","pos":"NOM"} ,
		{"word":"dissonances","word_nosc":"dissonances","lemma":"dissonance","pos":"NOM"} ,
		{"word":"dissuasion","word_nosc":"dissuasion","lemma":"dissuasion","pos":"NOM"} ,
		{"word":"dissymétrie","word_nosc":"dissymetrie","lemma":"dissymétrie","pos":"NOM"} ,
		{"word":"dissémination","word_nosc":"dissemination","lemma":"dissémination","pos":"NOM"} ,
		{"word":"disséqueur","word_nosc":"dissequeur","lemma":"disséqueur","pos":"NOM"} ,
		{"word":"distance","word_nosc":"distance","lemma":"distance","pos":"NOM"} ,
		{"word":"distances","word_nosc":"distances","lemma":"distance","pos":"NOM"} ,
		{"word":"distanciation","word_nosc":"distanciation","lemma":"distanciation","pos":"NOM"} ,
		{"word":"distension","word_nosc":"distension","lemma":"distension","pos":"NOM"} ,
		{"word":"distillat","word_nosc":"distillat","lemma":"distillat","pos":"NOM"} ,
		{"word":"distillateur","word_nosc":"distillateur","lemma":"distillateur","pos":"NOM"} ,
		{"word":"distillateurs","word_nosc":"distillateurs","lemma":"distillateur","pos":"NOM"} ,
		{"word":"distillation","word_nosc":"distillation","lemma":"distillation","pos":"NOM"} ,
		{"word":"distillations","word_nosc":"distillations","lemma":"distillation","pos":"NOM"} ,
		{"word":"distillerie","word_nosc":"distillerie","lemma":"distillerie","pos":"NOM"} ,
		{"word":"distilleries","word_nosc":"distilleries","lemma":"distillerie","pos":"NOM"} ,
		{"word":"distinction","word_nosc":"distinction","lemma":"distinction","pos":"NOM"} ,
		{"word":"distinctions","word_nosc":"distinctions","lemma":"distinction","pos":"NOM"} ,
		{"word":"distinguo","word_nosc":"distinguo","lemma":"distinguo","pos":"NOM"} ,
		{"word":"distinguos","word_nosc":"distinguos","lemma":"distinguo","pos":"NOM"} ,
		{"word":"distorsion","word_nosc":"distorsion","lemma":"distorsion","pos":"NOM"} ,
		{"word":"distorsions","word_nosc":"distorsions","lemma":"distorsion","pos":"NOM"} ,
		{"word":"distraction","word_nosc":"distraction","lemma":"distraction","pos":"NOM"} ,
		{"word":"distractions","word_nosc":"distractions","lemma":"distraction","pos":"NOM"} ,
		{"word":"distrait","word_nosc":"distrait","lemma":"distrait","pos":"NOM"} ,
		{"word":"distraits","word_nosc":"distraits","lemma":"distrait","pos":"NOM"} ,
		{"word":"distributeur","word_nosc":"distributeur","lemma":"distributeur","pos":"NOM"} ,
		{"word":"distributeurs","word_nosc":"distributeurs","lemma":"distributeur","pos":"NOM"} ,
		{"word":"distribution","word_nosc":"distribution","lemma":"distribution","pos":"NOM"} ,
		{"word":"distributions","word_nosc":"distributions","lemma":"distribution","pos":"NOM"} ,
		{"word":"distributrice","word_nosc":"distributrice","lemma":"distributeur","pos":"NOM"} ,
		{"word":"district","word_nosc":"district","lemma":"district","pos":"NOM"} ,
		{"word":"districts","word_nosc":"districts","lemma":"district","pos":"NOM"} ,
		{"word":"dit","word_nosc":"dit","lemma":"dit","pos":"NOM"} ,
		{"word":"dithyrambe","word_nosc":"dithyrambe","lemma":"dithyrambe","pos":"NOM"} ,
		{"word":"dithyrambes","word_nosc":"dithyrambes","lemma":"dithyrambe","pos":"NOM"} ,
		{"word":"dits","word_nosc":"dits","lemma":"dit","pos":"NOM"} ,
		{"word":"diurèse","word_nosc":"diurese","lemma":"diurèse","pos":"NOM"} ,
		{"word":"diurétique","word_nosc":"diuretique","lemma":"diurétique","pos":"NOM"} ,
		{"word":"diurétiques","word_nosc":"diuretiques","lemma":"diurétique","pos":"NOM"} ,
		{"word":"diva","word_nosc":"diva","lemma":"diva","pos":"NOM"} ,
		{"word":"divagation","word_nosc":"divagation","lemma":"divagation","pos":"NOM"} ,
		{"word":"divagations","word_nosc":"divagations","lemma":"divagation","pos":"NOM"} ,
		{"word":"divan","word_nosc":"divan","lemma":"divan","pos":"NOM"} ,
		{"word":"divans","word_nosc":"divans","lemma":"divan","pos":"NOM"} ,
		{"word":"divas","word_nosc":"divas","lemma":"diva","pos":"NOM"} ,
		{"word":"divergence","word_nosc":"divergence","lemma":"divergence","pos":"NOM"} ,
		{"word":"divergences","word_nosc":"divergences","lemma":"divergence","pos":"NOM"} ,
		{"word":"diversification","word_nosc":"diversification","lemma":"diversification","pos":"NOM"} ,
		{"word":"diversion","word_nosc":"diversion","lemma":"diversion","pos":"NOM"} ,
		{"word":"diversions","word_nosc":"diversions","lemma":"diversion","pos":"NOM"} ,
		{"word":"diversité","word_nosc":"diversite","lemma":"diversité","pos":"NOM"} ,
		{"word":"diversités","word_nosc":"diversites","lemma":"diversité","pos":"NOM"} ,
		{"word":"diverticule","word_nosc":"diverticule","lemma":"diverticule","pos":"NOM"} ,
		{"word":"diverticulose","word_nosc":"diverticulose","lemma":"diverticulose","pos":"NOM"} ,
		{"word":"divertimento","word_nosc":"divertimento","lemma":"divertimento","pos":"NOM"} ,
		{"word":"divertissement","word_nosc":"divertissement","lemma":"divertissement","pos":"NOM"} ,
		{"word":"divertissements","word_nosc":"divertissements","lemma":"divertissement","pos":"NOM"} ,
		{"word":"dividende","word_nosc":"dividende","lemma":"dividende","pos":"NOM"} ,
		{"word":"dividendes","word_nosc":"dividendes","lemma":"dividende","pos":"NOM"} ,
		{"word":"divination","word_nosc":"divination","lemma":"divination","pos":"NOM"} ,
		{"word":"divinations","word_nosc":"divinations","lemma":"divination","pos":"NOM"} ,
		{"word":"divinité","word_nosc":"divinite","lemma":"divinité","pos":"NOM"} ,
		{"word":"divinités","word_nosc":"divinites","lemma":"divinité","pos":"NOM"} ,
		{"word":"diviseur","word_nosc":"diviseur","lemma":"diviseur","pos":"NOM"} ,
		{"word":"diviseuse","word_nosc":"diviseuse","lemma":"diviseur","pos":"NOM"} ,
		{"word":"division","word_nosc":"division","lemma":"division","pos":"NOM"} ,
		{"word":"divisionnaire","word_nosc":"divisionnaire","lemma":"divisionnaire","pos":"NOM"} ,
		{"word":"divisionnaires","word_nosc":"divisionnaires","lemma":"divisionnaire","pos":"NOM"} ,
		{"word":"divisionnisme","word_nosc":"divisionnisme","lemma":"divisionnisme","pos":"NOM"} ,
		{"word":"divisions","word_nosc":"divisions","lemma":"division","pos":"NOM"} ,
		{"word":"divorce","word_nosc":"divorce","lemma":"divorce","pos":"NOM"} ,
		{"word":"divorces","word_nosc":"divorces","lemma":"divorce","pos":"NOM"} ,
		{"word":"divorcé","word_nosc":"divorce","lemma":"divorcé","pos":"NOM"} ,
		{"word":"divorcée","word_nosc":"divorcee","lemma":"divorcé","pos":"NOM"} ,
		{"word":"divorcées","word_nosc":"divorcees","lemma":"divorcé","pos":"NOM"} ,
		{"word":"divorcés","word_nosc":"divorces","lemma":"divorcé","pos":"NOM"} ,
		{"word":"divulgation","word_nosc":"divulgation","lemma":"divulgation","pos":"NOM"} ,
		{"word":"dix-cors","word_nosc":"dix-cors","lemma":"dix-cors","pos":"NOM"} ,
		{"word":"dix-huitième","word_nosc":"dix-huitieme","lemma":"dix-huitième","pos":"NOM"} ,
		{"word":"dix-neuvième","word_nosc":"dix-neuvieme","lemma":"dix-neuvième","pos":"NOM"} ,
		{"word":"dix-septième","word_nosc":"dix-septieme","lemma":"dix-septième","pos":"NOM"} ,
		{"word":"dixie","word_nosc":"dixie","lemma":"dixie","pos":"NOM"} ,
		{"word":"dixieland","word_nosc":"dixieland","lemma":"dixieland","pos":"NOM"} ,
		{"word":"dixième","word_nosc":"dixieme","lemma":"dixième","pos":"NOM"} ,
		{"word":"dixièmes","word_nosc":"dixiemes","lemma":"dixième","pos":"NOM"} ,
		{"word":"dizain","word_nosc":"dizain","lemma":"dizain","pos":"NOM"} ,
		{"word":"dizaine","word_nosc":"dizaine","lemma":"dizaine","pos":"NOM"} ,
		{"word":"dizaines","word_nosc":"dizaines","lemma":"dizaine","pos":"NOM"} ,
		{"word":"dièdre","word_nosc":"diedre","lemma":"dièdre","pos":"NOM"} ,
		{"word":"dièdres","word_nosc":"diedres","lemma":"dièdre","pos":"NOM"} ,
		{"word":"dièse","word_nosc":"diese","lemma":"dièse","pos":"NOM"} ,
		{"word":"dièses","word_nosc":"dieses","lemma":"dièse","pos":"NOM"} ,
		{"word":"diète","word_nosc":"diete","lemma":"diète","pos":"NOM"} ,
		{"word":"diérèses","word_nosc":"diereses","lemma":"diérèse","pos":"NOM"} ,
		{"word":"diététicien","word_nosc":"dieteticien","lemma":"diététicien","pos":"NOM"} ,
		{"word":"diététicienne","word_nosc":"dieteticienne","lemma":"diététicien","pos":"NOM"} ,
		{"word":"diététiciens","word_nosc":"dieteticiens","lemma":"diététicien","pos":"NOM"} ,
		{"word":"diététique","word_nosc":"dietetique","lemma":"diététique","pos":"NOM"} ,
		{"word":"diététiste","word_nosc":"dietetiste","lemma":"diététiste","pos":"NOM"} ,
		{"word":"djebel","word_nosc":"djebel","lemma":"djebel","pos":"NOM"} ,
		{"word":"djebels","word_nosc":"djebels","lemma":"djebel","pos":"NOM"} ,
		{"word":"djellaba","word_nosc":"djellaba","lemma":"djellaba","pos":"NOM"} ,
		{"word":"djellabas","word_nosc":"djellabas","lemma":"djellaba","pos":"NOM"} ,
		{"word":"djemââ","word_nosc":"djemaa","lemma":"djemââ","pos":"NOM"} ,
		{"word":"djihad","word_nosc":"djihad","lemma":"djihad","pos":"NOM"} ,
		{"word":"djihads","word_nosc":"djihads","lemma":"djihad","pos":"NOM"} ,
		{"word":"djinn","word_nosc":"djinn","lemma":"djinn","pos":"NOM"} ,
		{"word":"djinns","word_nosc":"djinns","lemma":"djinn","pos":"NOM"} ,
		{"word":"dna","word_nosc":"dna","lemma":"dna","pos":"NOM"} ,
		{"word":"do","word_nosc":"do","lemma":"do","pos":"NOM"} ,
		{"word":"doberman","word_nosc":"doberman","lemma":"doberman","pos":"NOM"} ,
		{"word":"dobermans","word_nosc":"dobermans","lemma":"doberman","pos":"NOM"} ,
		{"word":"doc","word_nosc":"doc","lemma":"doc","pos":"NOM"} ,
		{"word":"doche","word_nosc":"doche","lemma":"doche","pos":"NOM"} ,
		{"word":"docilité","word_nosc":"docilite","lemma":"docilité","pos":"NOM"} ,
		{"word":"dock","word_nosc":"dock","lemma":"dock","pos":"NOM"} ,
		{"word":"docker","word_nosc":"docker","lemma":"docker","pos":"NOM"} ,
		{"word":"dockers","word_nosc":"dockers","lemma":"docker","pos":"NOM"} ,
		{"word":"docks","word_nosc":"docks","lemma":"dock","pos":"NOM"} ,
		{"word":"docte","word_nosc":"docte","lemma":"docte","pos":"NOM"} ,
		{"word":"doctes","word_nosc":"doctes","lemma":"docte","pos":"NOM"} ,
		{"word":"docteur","word_nosc":"docteur","lemma":"docteur","pos":"NOM"} ,
		{"word":"docteurs","word_nosc":"docteurs","lemma":"docteur","pos":"NOM"} ,
		{"word":"doctorat","word_nosc":"doctorat","lemma":"doctorat","pos":"NOM"} ,
		{"word":"doctorats","word_nosc":"doctorats","lemma":"doctorat","pos":"NOM"} ,
		{"word":"doctoresse","word_nosc":"doctoresse","lemma":"docteur","pos":"NOM"} ,
		{"word":"doctrinaire","word_nosc":"doctrinaire","lemma":"doctrinaire","pos":"NOM"} ,
		{"word":"doctrinaires","word_nosc":"doctrinaires","lemma":"doctrinaire","pos":"NOM"} ,
		{"word":"doctrine","word_nosc":"doctrine","lemma":"doctrine","pos":"NOM"} ,
		{"word":"doctrines","word_nosc":"doctrines","lemma":"doctrine","pos":"NOM"} ,
		{"word":"docudrame","word_nosc":"docudrame","lemma":"docudrame","pos":"NOM"} ,
		{"word":"document","word_nosc":"document","lemma":"document","pos":"NOM"} ,
		{"word":"documentaire","word_nosc":"documentaire","lemma":"documentaire","pos":"NOM"} ,
		{"word":"documentaires","word_nosc":"documentaires","lemma":"documentaire","pos":"NOM"} ,
		{"word":"documentaliste","word_nosc":"documentaliste","lemma":"documentaliste","pos":"NOM"} ,
		{"word":"documentalistes","word_nosc":"documentalistes","lemma":"documentaliste","pos":"NOM"} ,
		{"word":"documentariste","word_nosc":"documentariste","lemma":"documentariste","pos":"NOM"} ,
		{"word":"documentaristes","word_nosc":"documentaristes","lemma":"documentariste","pos":"NOM"} ,
		{"word":"documentation","word_nosc":"documentation","lemma":"documentation","pos":"NOM"} ,
		{"word":"documentations","word_nosc":"documentations","lemma":"documentation","pos":"NOM"} ,
		{"word":"documents","word_nosc":"documents","lemma":"document","pos":"NOM"} ,
		{"word":"dodo","word_nosc":"dodo","lemma":"dodo","pos":"NOM"} ,
		{"word":"dodos","word_nosc":"dodos","lemma":"dodo","pos":"NOM"} ,
		{"word":"dodécagone","word_nosc":"dodecagone","lemma":"dodécagone","pos":"NOM"} ,
		{"word":"dodécaphoniste","word_nosc":"dodecaphoniste","lemma":"dodécaphoniste","pos":"NOM"} ,
		{"word":"dodécaèdre","word_nosc":"dodecaedre","lemma":"dodécaèdre","pos":"NOM"} ,
		{"word":"dog-cart","word_nosc":"dog-cart","lemma":"dog-cart","pos":"NOM"} ,
		{"word":"dogaresse","word_nosc":"dogaresse","lemma":"dogaresse","pos":"NOM"} ,
		{"word":"dogaresses","word_nosc":"dogaresses","lemma":"dogaresse","pos":"NOM"} ,
		{"word":"doge","word_nosc":"doge","lemma":"doge","pos":"NOM"} ,
		{"word":"doges","word_nosc":"doges","lemma":"doge","pos":"NOM"} ,
		{"word":"dogger","word_nosc":"dogger","lemma":"dogger","pos":"NOM"} ,
		{"word":"dogmatisme","word_nosc":"dogmatisme","lemma":"dogmatisme","pos":"NOM"} ,
		{"word":"dogme","word_nosc":"dogme","lemma":"dogme","pos":"NOM"} ,
		{"word":"dogmes","word_nosc":"dogmes","lemma":"dogme","pos":"NOM"} ,
		{"word":"dogue","word_nosc":"dogue","lemma":"dogue","pos":"NOM"} ,
		{"word":"dogues","word_nosc":"dogues","lemma":"dogue","pos":"NOM"} ,
		{"word":"doguin","word_nosc":"doguin","lemma":"doguin","pos":"NOM"} ,
		{"word":"doigt","word_nosc":"doigt","lemma":"doigt","pos":"NOM"} ,
		{"word":"doigtier","word_nosc":"doigtier","lemma":"doigtier","pos":"NOM"} ,
		{"word":"doigts","word_nosc":"doigts","lemma":"doigt","pos":"NOM"} ,
		{"word":"doigté","word_nosc":"doigte","lemma":"doigté","pos":"NOM"} ,
		{"word":"doit","word_nosc":"doit","lemma":"doit","pos":"NOM"} ,
		{"word":"dojo","word_nosc":"dojo","lemma":"dojo","pos":"NOM"} ,
		{"word":"dol","word_nosc":"dol","lemma":"dol","pos":"NOM"} ,
		{"word":"dolby","word_nosc":"dolby","lemma":"dolby","pos":"NOM"} ,
		{"word":"dolichocéphalie","word_nosc":"dolichocephalie","lemma":"dolichocéphalie","pos":"NOM"} ,
		{"word":"doline","word_nosc":"doline","lemma":"doline","pos":"NOM"} ,
		{"word":"dolique","word_nosc":"dolique","lemma":"dolique","pos":"NOM"} ,
		{"word":"dollar","word_nosc":"dollar","lemma":"dollar","pos":"NOM"} ,
		{"word":"dollars","word_nosc":"dollars","lemma":"dollar","pos":"NOM"} ,
		{"word":"dolman","word_nosc":"dolman","lemma":"dolman","pos":"NOM"} ,
		{"word":"dolmans","word_nosc":"dolmans","lemma":"dolman","pos":"NOM"} ,
		{"word":"dolmen","word_nosc":"dolmen","lemma":"dolmen","pos":"NOM"} ,
		{"word":"dolmens","word_nosc":"dolmens","lemma":"dolmen","pos":"NOM"} ,
		{"word":"dolomies","word_nosc":"dolomies","lemma":"dolomie","pos":"NOM"} ,
		{"word":"dolomite","word_nosc":"dolomite","lemma":"dolomite","pos":"NOM"} ,
		{"word":"dolorisme","word_nosc":"dolorisme","lemma":"dolorisme","pos":"NOM"} ,
		{"word":"doloriste","word_nosc":"doloriste","lemma":"doloriste","pos":"NOM"} ,
		{"word":"doléance","word_nosc":"doleance","lemma":"doléance","pos":"NOM"} ,
		{"word":"doléances","word_nosc":"doleances","lemma":"doléance","pos":"NOM"} ,
		{"word":"dom","word_nosc":"dom","lemma":"dom","pos":"NOM"} ,
		{"word":"domaine","word_nosc":"domaine","lemma":"domaine","pos":"NOM"} ,
		{"word":"domaines","word_nosc":"domaines","lemma":"domaine","pos":"NOM"} ,
		{"word":"domestication","word_nosc":"domestication","lemma":"domestication","pos":"NOM"} ,
		{"word":"domesticité","word_nosc":"domesticite","lemma":"domesticité","pos":"NOM"} ,
		{"word":"domestique","word_nosc":"domestique","lemma":"domestique","pos":"NOM"} ,
		{"word":"domestiques","word_nosc":"domestiques","lemma":"domestique","pos":"NOM"} ,
		{"word":"domicile","word_nosc":"domicile","lemma":"domicile","pos":"NOM"} ,
		{"word":"domiciles","word_nosc":"domiciles","lemma":"domicile","pos":"NOM"} ,
		{"word":"domiciliation","word_nosc":"domiciliation","lemma":"domiciliation","pos":"NOM"} ,
		{"word":"dominance","word_nosc":"dominance","lemma":"dominance","pos":"NOM"} ,
		{"word":"dominant","word_nosc":"dominant","lemma":"dominant","pos":"NOM"} ,
		{"word":"dominante","word_nosc":"dominante","lemma":"dominante","pos":"NOM"} ,
		{"word":"dominantes","word_nosc":"dominantes","lemma":"dominante","pos":"NOM"} ,
		{"word":"dominants","word_nosc":"dominants","lemma":"dominant","pos":"NOM"} ,
		{"word":"dominateur","word_nosc":"dominateur","lemma":"dominateur","pos":"NOM"} ,
		{"word":"dominateurs","word_nosc":"dominateurs","lemma":"dominateur","pos":"NOM"} ,
		{"word":"domination","word_nosc":"domination","lemma":"domination","pos":"NOM"} ,
		{"word":"dominations","word_nosc":"dominations","lemma":"domination","pos":"NOM"} ,
		{"word":"dominatrice","word_nosc":"dominatrice","lemma":"dominateur","pos":"NOM"} ,
		{"word":"dominatrices","word_nosc":"dominatrices","lemma":"dominateur","pos":"NOM"} ,
		{"word":"dominicain","word_nosc":"dominicain","lemma":"dominicain","pos":"NOM"} ,
		{"word":"dominicaine","word_nosc":"dominicaine","lemma":"dominicain","pos":"NOM"} ,
		{"word":"dominicaines","word_nosc":"dominicaines","lemma":"dominicain","pos":"NOM"} ,
		{"word":"dominicains","word_nosc":"dominicains","lemma":"dominicain","pos":"NOM"} ,
		{"word":"dominion","word_nosc":"dominion","lemma":"dominion","pos":"NOM"} ,
		{"word":"dominions","word_nosc":"dominions","lemma":"dominion","pos":"NOM"} ,
		{"word":"domino","word_nosc":"domino","lemma":"domino","pos":"NOM"} ,
		{"word":"dominos","word_nosc":"dominos","lemma":"domino","pos":"NOM"} ,
		{"word":"dommage","word_nosc":"dommage","lemma":"dommage","pos":"NOM"} ,
		{"word":"dommages","word_nosc":"dommages","lemma":"dommage","pos":"NOM"} ,
		{"word":"dommages-intérêts","word_nosc":"dommages-interets","lemma":"dommages-intérêts","pos":"NOM"} ,
		{"word":"domotique","word_nosc":"domotique","lemma":"domotique","pos":"NOM"} ,
		{"word":"domptage","word_nosc":"domptage","lemma":"domptage","pos":"NOM"} ,
		{"word":"dompteur","word_nosc":"dompteur","lemma":"dompteur","pos":"NOM"} ,
		{"word":"dompteurs","word_nosc":"dompteurs","lemma":"dompteur","pos":"NOM"} ,
		{"word":"dompteuse","word_nosc":"dompteuse","lemma":"dompteur","pos":"NOM"} ,
		{"word":"dompteuses","word_nosc":"dompteuses","lemma":"dompteur","pos":"NOM"} ,
		{"word":"don","word_nosc":"don","lemma":"don","pos":"NOM"} ,
		{"word":"dona","word_nosc":"dona","lemma":"dona","pos":"NOM"} ,
		{"word":"donateur","word_nosc":"donateur","lemma":"donateur","pos":"NOM"} ,
		{"word":"donateurs","word_nosc":"donateurs","lemma":"donateur","pos":"NOM"} ,
		{"word":"donation","word_nosc":"donation","lemma":"donation","pos":"NOM"} ,
		{"word":"donations","word_nosc":"donations","lemma":"donation","pos":"NOM"} ,
		{"word":"donatrice","word_nosc":"donatrice","lemma":"donateur","pos":"NOM"} ,
		{"word":"dondaine","word_nosc":"dondaine","lemma":"dondaine","pos":"NOM"} ,
		{"word":"dondaines","word_nosc":"dondaines","lemma":"dondaine","pos":"NOM"} ,
		{"word":"dondon","word_nosc":"dondon","lemma":"dondon","pos":"NOM"} ,
		{"word":"dondons","word_nosc":"dondons","lemma":"dondon","pos":"NOM"} ,
		{"word":"donjon","word_nosc":"donjon","lemma":"donjon","pos":"NOM"} ,
		{"word":"donjons","word_nosc":"donjons","lemma":"donjon","pos":"NOM"} ,
		{"word":"donjuanisme","word_nosc":"donjuanisme","lemma":"donjuanisme","pos":"NOM"} ,
		{"word":"donne","word_nosc":"donne","lemma":"donne","pos":"NOM"} ,
		{"word":"donner","word_nosc":"donner","lemma":"donner","pos":"NOM"} ,
		{"word":"donnes","word_nosc":"donnes","lemma":"donne","pos":"NOM"} ,
		{"word":"donneur","word_nosc":"donneur","lemma":"donneur","pos":"NOM"} ,
		{"word":"donneurs","word_nosc":"donneurs","lemma":"donneur","pos":"NOM"} ,
		{"word":"donneuse","word_nosc":"donneuse","lemma":"donneur","pos":"NOM"} ,
		{"word":"donneuses","word_nosc":"donneuses","lemma":"donneur","pos":"NOM"} ,
		{"word":"donné","word_nosc":"donne","lemma":"donné","pos":"NOM"} ,
		{"word":"donnée","word_nosc":"donnee","lemma":"donnée","pos":"NOM"} ,
		{"word":"données","word_nosc":"donnees","lemma":"donnée","pos":"NOM"} ,
		{"word":"donnés","word_nosc":"donnes","lemma":"donné","pos":"NOM"} ,
		{"word":"donquichottisme","word_nosc":"donquichottisme","lemma":"donquichottisme","pos":"NOM"} ,
		{"word":"dons","word_nosc":"dons","lemma":"don","pos":"NOM"} ,
		{"word":"donzelle","word_nosc":"donzelle","lemma":"donzelle","pos":"NOM"} ,
		{"word":"donzelles","word_nosc":"donzelles","lemma":"donzelle","pos":"NOM"} ,
		{"word":"dopage","word_nosc":"dopage","lemma":"dopage","pos":"NOM"} ,
		{"word":"dopamine","word_nosc":"dopamine","lemma":"dopamine","pos":"NOM"} ,
		{"word":"dope","word_nosc":"dope","lemma":"dope","pos":"NOM"} ,
		{"word":"dopes","word_nosc":"dopes","lemma":"dope","pos":"NOM"} ,
		{"word":"doping","word_nosc":"doping","lemma":"doping","pos":"NOM"} ,
		{"word":"doppler","word_nosc":"doppler","lemma":"doppler","pos":"NOM"} ,
		{"word":"dorade","word_nosc":"dorade","lemma":"dorade","pos":"NOM"} ,
		{"word":"dorades","word_nosc":"dorades","lemma":"dorade","pos":"NOM"} ,
		{"word":"dorage","word_nosc":"dorage","lemma":"dorage","pos":"NOM"} ,
		{"word":"dorcades","word_nosc":"dorcades","lemma":"dorcade","pos":"NOM"} ,
		{"word":"doreurs","word_nosc":"doreurs","lemma":"doreur","pos":"NOM"} ,
		{"word":"dormance","word_nosc":"dormance","lemma":"dormance","pos":"NOM"} ,
		{"word":"dormant","word_nosc":"dormant","lemma":"dormant","pos":"NOM"} ,
		{"word":"dormants","word_nosc":"dormants","lemma":"dormant","pos":"NOM"} ,
		{"word":"dormeur","word_nosc":"dormeur","lemma":"dormeur","pos":"NOM"} ,
		{"word":"dormeurs","word_nosc":"dormeurs","lemma":"dormeur","pos":"NOM"} ,
		{"word":"dormeuse","word_nosc":"dormeuse","lemma":"dormeur","pos":"NOM"} ,
		{"word":"dormeuses","word_nosc":"dormeuses","lemma":"dormeur","pos":"NOM"} ,
		{"word":"dormition","word_nosc":"dormition","lemma":"dormition","pos":"NOM"} ,
		{"word":"dorsale","word_nosc":"dorsale","lemma":"dorsale","pos":"NOM"} ,
		{"word":"dortoir","word_nosc":"dortoir","lemma":"dortoir","pos":"NOM"} ,
		{"word":"dortoirs","word_nosc":"dortoirs","lemma":"dortoir","pos":"NOM"} ,
		{"word":"dorure","word_nosc":"dorure","lemma":"dorure","pos":"NOM"} ,
		{"word":"dorures","word_nosc":"dorures","lemma":"dorure","pos":"NOM"} ,
		{"word":"doryphore","word_nosc":"doryphore","lemma":"doryphore","pos":"NOM"} ,
		{"word":"doryphores","word_nosc":"doryphores","lemma":"doryphore","pos":"NOM"} ,
		{"word":"dorée","word_nosc":"doree","lemma":"dorée","pos":"NOM"} ,
		{"word":"dorées","word_nosc":"dorees","lemma":"dorée","pos":"NOM"} ,
		{"word":"dos","word_nosc":"dos","lemma":"dos","pos":"NOM"} ,
		{"word":"dos-d'âne","word_nosc":"dos-d'ane","lemma":"dos-d'âne","pos":"NOM"} ,
		{"word":"dosage","word_nosc":"dosage","lemma":"dosage","pos":"NOM"} ,
		{"word":"dosages","word_nosc":"dosages","lemma":"dosage","pos":"NOM"} ,
		{"word":"dose","word_nosc":"dose","lemma":"dose","pos":"NOM"} ,
		{"word":"doses","word_nosc":"doses","lemma":"dose","pos":"NOM"} ,
		{"word":"doseur","word_nosc":"doseur","lemma":"doseur","pos":"NOM"} ,
		{"word":"doseurs","word_nosc":"doseurs","lemma":"doseur","pos":"NOM"} ,
		{"word":"dosimètre","word_nosc":"dosimetre","lemma":"dosimètre","pos":"NOM"} ,
		{"word":"dossard","word_nosc":"dossard","lemma":"dossard","pos":"NOM"} ,
		{"word":"dossards","word_nosc":"dossards","lemma":"dossard","pos":"NOM"} ,
		{"word":"dosseret","word_nosc":"dosseret","lemma":"dosseret","pos":"NOM"} ,
		{"word":"dossier","word_nosc":"dossier","lemma":"dossier","pos":"NOM"} ,
		{"word":"dossiers","word_nosc":"dossiers","lemma":"dossier","pos":"NOM"} ,
		{"word":"dossière","word_nosc":"dossiere","lemma":"dossière","pos":"NOM"} ,
		{"word":"dossières","word_nosc":"dossieres","lemma":"dossière","pos":"NOM"} ,
		{"word":"dot","word_nosc":"dot","lemma":"dot","pos":"NOM"} ,
		{"word":"dotation","word_nosc":"dotation","lemma":"dotation","pos":"NOM"} ,
		{"word":"dots","word_nosc":"dots","lemma":"dot","pos":"NOM"} ,
		{"word":"douaire","word_nosc":"douaire","lemma":"douaire","pos":"NOM"} ,
		{"word":"douairière","word_nosc":"douairiere","lemma":"douairier","pos":"NOM"} ,
		{"word":"douairières","word_nosc":"douairieres","lemma":"douairier","pos":"NOM"} ,
		{"word":"douane","word_nosc":"douane","lemma":"douane","pos":"NOM"} ,
		{"word":"douanes","word_nosc":"douanes","lemma":"douane","pos":"NOM"} ,
		{"word":"douanier","word_nosc":"douanier","lemma":"douanier","pos":"NOM"} ,
		{"word":"douaniers","word_nosc":"douaniers","lemma":"douanier","pos":"NOM"} ,
		{"word":"douar","word_nosc":"douar","lemma":"douar","pos":"NOM"} ,
		{"word":"douars","word_nosc":"douars","lemma":"douar","pos":"NOM"} ,
		{"word":"doublage","word_nosc":"doublage","lemma":"doublage","pos":"NOM"} ,
		{"word":"doublages","word_nosc":"doublages","lemma":"doublage","pos":"NOM"} ,
		{"word":"doublard","word_nosc":"doublard","lemma":"doublard","pos":"NOM"} ,
		{"word":"doublards","word_nosc":"doublards","lemma":"doublard","pos":"NOM"} ,
		{"word":"double","word_nosc":"double","lemma":"double","pos":"NOM"} ,
		{"word":"double-croche","word_nosc":"double-croche","lemma":"double-croche","pos":"NOM"} ,
		{"word":"double-décimètre","word_nosc":"double-decimetre","lemma":"double-décimètre","pos":"NOM"} ,
		{"word":"double-fond","word_nosc":"double-fond","lemma":"double-fond","pos":"NOM"} ,
		{"word":"double-six","word_nosc":"double-six","lemma":"double-six","pos":"NOM"} ,
		{"word":"doubleau","word_nosc":"doubleau","lemma":"doubleau","pos":"NOM"} ,
		{"word":"doubleaux","word_nosc":"doubleaux","lemma":"doubleau","pos":"NOM"} ,
		{"word":"doublement","word_nosc":"doublement","lemma":"doublement","pos":"NOM"} ,
		{"word":"doubler","word_nosc":"doubler","lemma":"doubler","pos":"NOM"} ,
		{"word":"doubles","word_nosc":"doubles","lemma":"double","pos":"NOM"} ,
		{"word":"doublet","word_nosc":"doublet","lemma":"doublet","pos":"NOM"} ,
		{"word":"doublets","word_nosc":"doublets","lemma":"doublet","pos":"NOM"} ,
		{"word":"doublette","word_nosc":"doublette","lemma":"doublette","pos":"NOM"} ,
		{"word":"doubleur","word_nosc":"doubleur","lemma":"doubleur","pos":"NOM"} ,
		{"word":"doubleuse","word_nosc":"doubleuse","lemma":"doubleur","pos":"NOM"} ,
		{"word":"doubleuses","word_nosc":"doubleuses","lemma":"doubleur","pos":"NOM"} ,
		{"word":"doublon","word_nosc":"doublon","lemma":"doublon","pos":"NOM"} ,
		{"word":"doublons","word_nosc":"doublons","lemma":"doublon","pos":"NOM"} ,
		{"word":"doublure","word_nosc":"doublure","lemma":"doublure","pos":"NOM"} ,
		{"word":"doublures","word_nosc":"doublures","lemma":"doublure","pos":"NOM"} ,
		{"word":"doublé","word_nosc":"double","lemma":"doublé","pos":"NOM"} ,
		{"word":"doublés","word_nosc":"doubles","lemma":"doublé","pos":"NOM"} ,
		{"word":"douce","word_nosc":"douce","lemma":"douce","pos":"NOM"} ,
		{"word":"douce-amère","word_nosc":"douce-amere","lemma":"douce-amère","pos":"NOM"} ,
		{"word":"douces-amères","word_nosc":"douces-ameres","lemma":"douce-amère","pos":"NOM"} ,
		{"word":"doucette","word_nosc":"doucette","lemma":"doucette","pos":"NOM"} ,
		{"word":"douceur","word_nosc":"douceur","lemma":"douceur","pos":"NOM"} ,
		{"word":"douceurs","word_nosc":"douceurs","lemma":"douceur","pos":"NOM"} ,
		{"word":"douche","word_nosc":"douche","lemma":"douche","pos":"NOM"} ,
		{"word":"douches","word_nosc":"douches","lemma":"douche","pos":"NOM"} ,
		{"word":"doucheur","word_nosc":"doucheur","lemma":"doucheur","pos":"NOM"} ,
		{"word":"doucin","word_nosc":"doucin","lemma":"doucin","pos":"NOM"} ,
		{"word":"doucine","word_nosc":"doucine","lemma":"doucine","pos":"NOM"} ,
		{"word":"doucins","word_nosc":"doucins","lemma":"doucin","pos":"NOM"} ,
		{"word":"doudou","word_nosc":"doudou","lemma":"doudou","pos":"NOM"} ,
		{"word":"doudoune","word_nosc":"doudoune","lemma":"doudoune","pos":"NOM"} ,
		{"word":"doudounes","word_nosc":"doudounes","lemma":"doudoune","pos":"NOM"} ,
		{"word":"douelle","word_nosc":"douelle","lemma":"douelle","pos":"NOM"} ,
		{"word":"douelles","word_nosc":"douelles","lemma":"douelle","pos":"NOM"} ,
		{"word":"douglas","word_nosc":"douglas","lemma":"douglas","pos":"NOM"} ,
		{"word":"douille","word_nosc":"douille","lemma":"douille","pos":"NOM"} ,
		{"word":"douilles","word_nosc":"douilles","lemma":"douille","pos":"NOM"} ,
		{"word":"douillette","word_nosc":"douillette","lemma":"douillette","pos":"NOM"} ,
		{"word":"douillettes","word_nosc":"douillettes","lemma":"douillette","pos":"NOM"} ,
		{"word":"douillons","word_nosc":"douillons","lemma":"douillon","pos":"NOM"} ,
		{"word":"douleur","word_nosc":"douleur","lemma":"douleur","pos":"NOM"} ,
		{"word":"douleurs","word_nosc":"douleurs","lemma":"douleur","pos":"NOM"} ,
		{"word":"douma","word_nosc":"douma","lemma":"douma","pos":"NOM"} ,
		{"word":"doura","word_nosc":"doura","lemma":"doura","pos":"NOM"} ,
		{"word":"douro","word_nosc":"douro","lemma":"douro","pos":"NOM"} ,
		{"word":"douros","word_nosc":"douros","lemma":"douro","pos":"NOM"} ,
		{"word":"doutance","word_nosc":"doutance","lemma":"doutance","pos":"NOM"} ,
		{"word":"doutances","word_nosc":"doutances","lemma":"doutance","pos":"NOM"} ,
		{"word":"doute","word_nosc":"doute","lemma":"doute","pos":"NOM"} ,
		{"word":"doutes","word_nosc":"doutes","lemma":"doute","pos":"NOM"} ,
		{"word":"douve","word_nosc":"douve","lemma":"douve","pos":"NOM"} ,
		{"word":"douves","word_nosc":"douves","lemma":"douve","pos":"NOM"} ,
		{"word":"doux","word_nosc":"doux","lemma":"doux","pos":"NOM"} ,
		{"word":"douzaine","word_nosc":"douzaine","lemma":"douzaine","pos":"NOM"} ,
		{"word":"douzaines","word_nosc":"douzaines","lemma":"douzaine","pos":"NOM"} ,
		{"word":"douzième","word_nosc":"douzieme","lemma":"douzième","pos":"NOM"} ,
		{"word":"douzièmes","word_nosc":"douziemes","lemma":"douzième","pos":"NOM"} ,
		{"word":"downing street","word_nosc":"downing street","lemma":"downing street","pos":"NOM"} ,
		{"word":"doyen","word_nosc":"doyen","lemma":"doyen","pos":"NOM"} ,
		{"word":"doyenne","word_nosc":"doyenne","lemma":"doyen","pos":"NOM"} ,
		{"word":"doyenné","word_nosc":"doyenne","lemma":"doyenné","pos":"NOM"} ,
		{"word":"doyens","word_nosc":"doyens","lemma":"doyen","pos":"NOM"} ,
		{"word":"doña","word_nosc":"dona","lemma":"doña","pos":"NOM"} ,
		{"word":"drachme","word_nosc":"drachme","lemma":"drachme","pos":"NOM"} ,
		{"word":"drachmes","word_nosc":"drachmes","lemma":"drachme","pos":"NOM"} ,
		{"word":"drag","word_nosc":"drag","lemma":"drag","pos":"NOM"} ,
		{"word":"dragage","word_nosc":"dragage","lemma":"dragage","pos":"NOM"} ,
		{"word":"drageoir","word_nosc":"drageoir","lemma":"drageoir","pos":"NOM"} ,
		{"word":"drageoirs","word_nosc":"drageoirs","lemma":"drageoir","pos":"NOM"} ,
		{"word":"dragon","word_nosc":"dragon","lemma":"dragon","pos":"NOM"} ,
		{"word":"dragonnades","word_nosc":"dragonnades","lemma":"dragonnade","pos":"NOM"} ,
		{"word":"dragonne","word_nosc":"dragonne","lemma":"dragon","pos":"NOM"} ,
		{"word":"dragonnier","word_nosc":"dragonnier","lemma":"dragonnier","pos":"NOM"} ,
		{"word":"dragons","word_nosc":"dragons","lemma":"dragon","pos":"NOM"} ,
		{"word":"drags","word_nosc":"drags","lemma":"drag","pos":"NOM"} ,
		{"word":"dragster","word_nosc":"dragster","lemma":"dragster","pos":"NOM"} ,
		{"word":"drague","word_nosc":"drague","lemma":"drague","pos":"NOM"} ,
		{"word":"dragues","word_nosc":"dragues","lemma":"drague","pos":"NOM"} ,
		{"word":"dragueur","word_nosc":"dragueur","lemma":"dragueur","pos":"NOM"} ,
		{"word":"dragueurs","word_nosc":"dragueurs","lemma":"dragueur","pos":"NOM"} ,
		{"word":"dragueuse","word_nosc":"dragueuse","lemma":"dragueur","pos":"NOM"} ,
		{"word":"dragée","word_nosc":"dragee","lemma":"dragée","pos":"NOM"} ,
		{"word":"dragées","word_nosc":"dragees","lemma":"dragée","pos":"NOM"} ,
		{"word":"drailles","word_nosc":"drailles","lemma":"draille","pos":"NOM"} ,
		{"word":"drain","word_nosc":"drain","lemma":"drain","pos":"NOM"} ,
		{"word":"drainage","word_nosc":"drainage","lemma":"drainage","pos":"NOM"} ,
		{"word":"drainages","word_nosc":"drainages","lemma":"drainage","pos":"NOM"} ,
		{"word":"drains","word_nosc":"drains","lemma":"drain","pos":"NOM"} ,
		{"word":"draisienne","word_nosc":"draisienne","lemma":"draisienne","pos":"NOM"} ,
		{"word":"draisine","word_nosc":"draisine","lemma":"draisine","pos":"NOM"} ,
		{"word":"drakkar","word_nosc":"drakkar","lemma":"drakkar","pos":"NOM"} ,
		{"word":"drakkars","word_nosc":"drakkars","lemma":"drakkar","pos":"NOM"} ,
		{"word":"dramatique","word_nosc":"dramatique","lemma":"dramatique","pos":"NOM"} ,
		{"word":"dramatiques","word_nosc":"dramatiques","lemma":"dramatique","pos":"NOM"} ,
		{"word":"dramatisation","word_nosc":"dramatisation","lemma":"dramatisation","pos":"NOM"} ,
		{"word":"dramaturge","word_nosc":"dramaturge","lemma":"dramaturge","pos":"NOM"} ,
		{"word":"dramaturges","word_nosc":"dramaturges","lemma":"dramaturge","pos":"NOM"} ,
		{"word":"dramaturgie","word_nosc":"dramaturgie","lemma":"dramaturgie","pos":"NOM"} ,
		{"word":"drame","word_nosc":"drame","lemma":"drame","pos":"NOM"} ,
		{"word":"drames","word_nosc":"drames","lemma":"drame","pos":"NOM"} ,
		{"word":"drap","word_nosc":"drap","lemma":"drap","pos":"NOM"} ,
		{"word":"drapeau","word_nosc":"drapeau","lemma":"drapeau","pos":"NOM"} ,
		{"word":"drapeaux","word_nosc":"drapeaux","lemma":"drapeau","pos":"NOM"} ,
		{"word":"drapelets","word_nosc":"drapelets","lemma":"drapelet","pos":"NOM"} ,
		{"word":"draperie","word_nosc":"draperie","lemma":"draperie","pos":"NOM"} ,
		{"word":"draperies","word_nosc":"draperies","lemma":"draperie","pos":"NOM"} ,
		{"word":"drapier","word_nosc":"drapier","lemma":"drapier","pos":"NOM"} ,
		{"word":"drapiers","word_nosc":"drapiers","lemma":"drapier","pos":"NOM"} ,
		{"word":"draps","word_nosc":"draps","lemma":"drap","pos":"NOM"} ,
		{"word":"drapé","word_nosc":"drape","lemma":"drapé","pos":"NOM"} ,
		{"word":"drapés","word_nosc":"drapes","lemma":"drapé","pos":"NOM"} ,
		{"word":"drave","word_nosc":"drave","lemma":"drave","pos":"NOM"} ,
		{"word":"draveurs","word_nosc":"draveurs","lemma":"draveur","pos":"NOM"} ,
		{"word":"dreadlocks","word_nosc":"dreadlocks","lemma":"dreadlocks","pos":"NOM"} ,
		{"word":"dream team","word_nosc":"dream team","lemma":"dream team","pos":"NOM"} ,
		{"word":"drelin","word_nosc":"drelin","lemma":"drelin","pos":"NOM"} ,
		{"word":"drepou","word_nosc":"drepou","lemma":"drepou","pos":"NOM"} ,
		{"word":"dressage","word_nosc":"dressage","lemma":"dressage","pos":"NOM"} ,
		{"word":"dressages","word_nosc":"dressages","lemma":"dressage","pos":"NOM"} ,
		{"word":"dressement","word_nosc":"dressement","lemma":"dressement","pos":"NOM"} ,
		{"word":"dresseur","word_nosc":"dresseur","lemma":"dresseur","pos":"NOM"} ,
		{"word":"dresseurs","word_nosc":"dresseurs","lemma":"dresseur","pos":"NOM"} ,
		{"word":"dresseuse","word_nosc":"dresseuse","lemma":"dresseur","pos":"NOM"} ,
		{"word":"dressing","word_nosc":"dressing","lemma":"dressing","pos":"NOM"} ,
		{"word":"dressing-room","word_nosc":"dressing-room","lemma":"dressing-room","pos":"NOM"} ,
		{"word":"dressoir","word_nosc":"dressoir","lemma":"dressoir","pos":"NOM"} ,
		{"word":"dressoirs","word_nosc":"dressoirs","lemma":"dressoir","pos":"NOM"} ,
		{"word":"dreyfusard","word_nosc":"dreyfusard","lemma":"dreyfusard","pos":"NOM"} ,
		{"word":"dreyfusards","word_nosc":"dreyfusards","lemma":"dreyfusard","pos":"NOM"} ,
		{"word":"dreyfusisme","word_nosc":"dreyfusisme","lemma":"dreyfusisme","pos":"NOM"} ,
		{"word":"dribble","word_nosc":"dribble","lemma":"dribble","pos":"NOM"} ,
		{"word":"dribbles","word_nosc":"dribbles","lemma":"dribble","pos":"NOM"} ,
		{"word":"dribbleur","word_nosc":"dribbleur","lemma":"dribbleur","pos":"NOM"} ,
		{"word":"drift","word_nosc":"drift","lemma":"drift","pos":"NOM"} ,
		{"word":"drifter","word_nosc":"drifter","lemma":"drifter","pos":"NOM"} ,
		{"word":"drifters","word_nosc":"drifters","lemma":"drifter","pos":"NOM"} ,
		{"word":"drill","word_nosc":"drill","lemma":"drill","pos":"NOM"} ,
		{"word":"drille","word_nosc":"drille","lemma":"drille","pos":"NOM"} ,
		{"word":"drilles","word_nosc":"drilles","lemma":"drille","pos":"NOM"} ,
		{"word":"drink","word_nosc":"drink","lemma":"drink","pos":"NOM"} ,
		{"word":"drinks","word_nosc":"drinks","lemma":"drink","pos":"NOM"} ,
		{"word":"drisse","word_nosc":"drisse","lemma":"drisse","pos":"NOM"} ,
		{"word":"drisses","word_nosc":"drisses","lemma":"drisse","pos":"NOM"} ,
		{"word":"drive","word_nosc":"drive","lemma":"drive","pos":"NOM"} ,
		{"word":"drive-in","word_nosc":"drive-in","lemma":"drive-in","pos":"NOM"} ,
		{"word":"driver","word_nosc":"driver","lemma":"driver","pos":"NOM"} ,
		{"word":"drivers","word_nosc":"drivers","lemma":"driver","pos":"NOM"} ,
		{"word":"drives","word_nosc":"drives","lemma":"drive","pos":"NOM"} ,
		{"word":"drogman","word_nosc":"drogman","lemma":"drogman","pos":"NOM"} ,
		{"word":"drogmans","word_nosc":"drogmans","lemma":"drogman","pos":"NOM"} ,
		{"word":"drogue","word_nosc":"drogue","lemma":"drogue","pos":"NOM"} ,
		{"word":"droguerie","word_nosc":"droguerie","lemma":"droguerie","pos":"NOM"} ,
		{"word":"drogueries","word_nosc":"drogueries","lemma":"droguerie","pos":"NOM"} ,
		{"word":"drogues","word_nosc":"drogues","lemma":"drogue","pos":"NOM"} ,
		{"word":"droguet","word_nosc":"droguet","lemma":"droguet","pos":"NOM"} ,
		{"word":"droguets","word_nosc":"droguets","lemma":"droguet","pos":"NOM"} ,
		{"word":"droguiste","word_nosc":"droguiste","lemma":"droguiste","pos":"NOM"} ,
		{"word":"droguistes","word_nosc":"droguistes","lemma":"droguiste","pos":"NOM"} ,
		{"word":"drogué","word_nosc":"drogue","lemma":"drogué","pos":"NOM"} ,
		{"word":"droguée","word_nosc":"droguee","lemma":"drogué","pos":"NOM"} ,
		{"word":"droguées","word_nosc":"droguees","lemma":"drogué","pos":"NOM"} ,
		{"word":"drogués","word_nosc":"drogues","lemma":"drogué","pos":"NOM"} ,
		{"word":"droicos","word_nosc":"droicos","lemma":"droico","pos":"NOM"} ,
		{"word":"droit","word_nosc":"droit","lemma":"droit","pos":"NOM"} ,
		{"word":"droit-fil","word_nosc":"droit-fil","lemma":"droit-fil","pos":"NOM"} ,
		{"word":"droite","word_nosc":"droite","lemma":"droite","pos":"NOM"} ,
		{"word":"droites","word_nosc":"droites","lemma":"droite","pos":"NOM"} ,
		{"word":"droitier","word_nosc":"droitier","lemma":"droitier","pos":"NOM"} ,
		{"word":"droitiers","word_nosc":"droitiers","lemma":"droitier","pos":"NOM"} ,
		{"word":"droits","word_nosc":"droits","lemma":"droit","pos":"NOM"} ,
		{"word":"droiture","word_nosc":"droiture","lemma":"droiture","pos":"NOM"} ,
		{"word":"dromadaire","word_nosc":"dromadaire","lemma":"dromadaire","pos":"NOM"} ,
		{"word":"dromadaires","word_nosc":"dromadaires","lemma":"dromadaire","pos":"NOM"} ,
		{"word":"drome","word_nosc":"drome","lemma":"drome","pos":"NOM"} ,
		{"word":"dromes","word_nosc":"dromes","lemma":"drome","pos":"NOM"} ,
		{"word":"drone","word_nosc":"drone","lemma":"drone","pos":"NOM"} ,
		{"word":"drone-espion","word_nosc":"drone-espion","lemma":"drone-espion","pos":"NOM"} ,
		{"word":"drones","word_nosc":"drones","lemma":"drone","pos":"NOM"} ,
		{"word":"dronte","word_nosc":"dronte","lemma":"dronte","pos":"NOM"} ,
		{"word":"drop","word_nosc":"drop","lemma":"drop","pos":"NOM"} ,
		{"word":"droppage","word_nosc":"droppage","lemma":"droppage","pos":"NOM"} ,
		{"word":"drosophile","word_nosc":"drosophile","lemma":"drosophile","pos":"NOM"} ,
		{"word":"drosophiles","word_nosc":"drosophiles","lemma":"drosophile","pos":"NOM"} ,
		{"word":"dross","word_nosc":"dross","lemma":"dross","pos":"NOM"} ,
		{"word":"drossart","word_nosc":"drossart","lemma":"drossart","pos":"NOM"} ,
		{"word":"droséra","word_nosc":"drosera","lemma":"droséra","pos":"NOM"} ,
		{"word":"drugstore","word_nosc":"drugstore","lemma":"drugstore","pos":"NOM"} ,
		{"word":"drugstores","word_nosc":"drugstores","lemma":"drugstore","pos":"NOM"} ,
		{"word":"druide","word_nosc":"druide","lemma":"druide","pos":"NOM"} ,
		{"word":"druides","word_nosc":"druides","lemma":"druide","pos":"NOM"} ,
		{"word":"druidesse","word_nosc":"druidesse","lemma":"druidesse","pos":"NOM"} ,
		{"word":"druidesses","word_nosc":"druidesses","lemma":"druidesse","pos":"NOM"} ,
		{"word":"druidisme","word_nosc":"druidisme","lemma":"druidisme","pos":"NOM"} ,
		{"word":"drumlin","word_nosc":"drumlin","lemma":"drumlin","pos":"NOM"} ,
		{"word":"drummer","word_nosc":"drummer","lemma":"drummer","pos":"NOM"} ,
		{"word":"drums","word_nosc":"drums","lemma":"drums","pos":"NOM"} ,
		{"word":"dryade","word_nosc":"dryade","lemma":"dryade","pos":"NOM"} ,
		{"word":"dryades","word_nosc":"dryades","lemma":"dryade","pos":"NOM"} ,
		{"word":"drège","word_nosc":"drege","lemma":"drège","pos":"NOM"} ,
		{"word":"drépanocytose","word_nosc":"drepanocytose","lemma":"drépanocytose","pos":"NOM"} ,
		{"word":"drôle","word_nosc":"drole","lemma":"drôle","pos":"NOM"} ,
		{"word":"drôlerie","word_nosc":"drolerie","lemma":"drôlerie","pos":"NOM"} ,
		{"word":"drôleries","word_nosc":"droleries","lemma":"drôlerie","pos":"NOM"} ,
		{"word":"drôles","word_nosc":"droles","lemma":"drôle","pos":"NOM"} ,
		{"word":"drôlesse","word_nosc":"drolesse","lemma":"drôlesse","pos":"NOM"} ,
		{"word":"drôlesses","word_nosc":"drolesses","lemma":"drôlesse","pos":"NOM"} ,
		{"word":"drôlet","word_nosc":"drolet","lemma":"drôlet","pos":"NOM"} ,
		{"word":"dualisme","word_nosc":"dualisme","lemma":"dualisme","pos":"NOM"} ,
		{"word":"dualité","word_nosc":"dualite","lemma":"dualité","pos":"NOM"} ,
		{"word":"dubitation","word_nosc":"dubitation","lemma":"dubitation","pos":"NOM"} ,
		{"word":"dubitations","word_nosc":"dubitations","lemma":"dubitation","pos":"NOM"} ,
		{"word":"duc","word_nosc":"duc","lemma":"duc","pos":"NOM"} ,
		{"word":"ducasse","word_nosc":"ducasse","lemma":"ducasse","pos":"NOM"} ,
		{"word":"ducasses","word_nosc":"ducasses","lemma":"ducasse","pos":"NOM"} ,
		{"word":"ducat","word_nosc":"ducat","lemma":"ducat","pos":"NOM"} ,
		{"word":"ducaton","word_nosc":"ducaton","lemma":"ducaton","pos":"NOM"} ,
		{"word":"ducats","word_nosc":"ducats","lemma":"ducat","pos":"NOM"} ,
		{"word":"duce","word_nosc":"duce","lemma":"duce","pos":"NOM"} ,
		{"word":"duces","word_nosc":"duces","lemma":"duce","pos":"NOM"} ,
		{"word":"duchesse","word_nosc":"duchesse","lemma":"duc","pos":"NOM"} ,
		{"word":"duchesses","word_nosc":"duchesses","lemma":"duc","pos":"NOM"} ,
		{"word":"duchnoque","word_nosc":"duchnoque","lemma":"duchnoque","pos":"NOM"} ,
		{"word":"duché","word_nosc":"duche","lemma":"duché","pos":"NOM"} ,
		{"word":"ducon","word_nosc":"ducon","lemma":"ducon","pos":"NOM"} ,
		{"word":"ducs","word_nosc":"ducs","lemma":"duc","pos":"NOM"} ,
		{"word":"ducs-d'albe","word_nosc":"ducs-d'albe","lemma":"duc-d'albe","pos":"NOM"} ,
		{"word":"duel","word_nosc":"duel","lemma":"duel","pos":"NOM"} ,
		{"word":"duelliste","word_nosc":"duelliste","lemma":"duelliste","pos":"NOM"} ,
		{"word":"duellistes","word_nosc":"duellistes","lemma":"duelliste","pos":"NOM"} ,
		{"word":"duels","word_nosc":"duels","lemma":"duel","pos":"NOM"} ,
		{"word":"duettistes","word_nosc":"duettistes","lemma":"duettiste","pos":"NOM"} ,
		{"word":"duffel-coat","word_nosc":"duffel-coat","lemma":"duffel-coat","pos":"NOM"} ,
		{"word":"duffle-coat","word_nosc":"duffle-coat","lemma":"duffle-coat","pos":"NOM"} ,
		{"word":"duffle-coats","word_nosc":"duffle-coats","lemma":"duffle-coat","pos":"NOM"} ,
		{"word":"dugong","word_nosc":"dugong","lemma":"dugong","pos":"NOM"} ,
		{"word":"dugongs","word_nosc":"dugongs","lemma":"dugong","pos":"NOM"} ,
		{"word":"duit","word_nosc":"duit","lemma":"duit","pos":"NOM"} ,
		{"word":"dulcinée","word_nosc":"dulcinee","lemma":"dulcinée","pos":"NOM"} ,
		{"word":"dumper","word_nosc":"dumper","lemma":"dumper","pos":"NOM"} ,
		{"word":"dumping","word_nosc":"dumping","lemma":"dumping","pos":"NOM"} ,
		{"word":"dundee","word_nosc":"dundee","lemma":"dundee","pos":"NOM"} ,
		{"word":"dune","word_nosc":"dune","lemma":"dune","pos":"NOM"} ,
		{"word":"dunes","word_nosc":"dunes","lemma":"dune","pos":"NOM"} ,
		{"word":"dunette","word_nosc":"dunette","lemma":"dunette","pos":"NOM"} ,
		{"word":"dunettes","word_nosc":"dunettes","lemma":"dunette","pos":"NOM"} ,
		{"word":"dunkerque","word_nosc":"dunkerque","lemma":"dunkerque","pos":"NOM"} ,
		{"word":"duo","word_nosc":"duo","lemma":"duo","pos":"NOM"} ,
		{"word":"duodi","word_nosc":"duodi","lemma":"duodi","pos":"NOM"} ,
		{"word":"duodénum","word_nosc":"duodenum","lemma":"duodénum","pos":"NOM"} ,
		{"word":"duopole","word_nosc":"duopole","lemma":"duopole","pos":"NOM"} ,
		{"word":"duos","word_nosc":"duos","lemma":"duo","pos":"NOM"} ,
		{"word":"dupe","word_nosc":"dupe","lemma":"dupe","pos":"NOM"} ,
		{"word":"duperie","word_nosc":"duperie","lemma":"duperie","pos":"NOM"} ,
		{"word":"duperies","word_nosc":"duperies","lemma":"duperie","pos":"NOM"} ,
		{"word":"dupes","word_nosc":"dupes","lemma":"dupe","pos":"NOM"} ,
		{"word":"dupeur","word_nosc":"dupeur","lemma":"dupeur","pos":"NOM"} ,
		{"word":"duplex","word_nosc":"duplex","lemma":"duplex","pos":"NOM"} ,
		{"word":"duplicata","word_nosc":"duplicata","lemma":"duplicata","pos":"NOM"} ,
		{"word":"duplicate","word_nosc":"duplicate","lemma":"duplicate","pos":"NOM"} ,
		{"word":"duplicateur","word_nosc":"duplicateur","lemma":"duplicateur","pos":"NOM"} ,
		{"word":"duplicateurs","word_nosc":"duplicateurs","lemma":"duplicateur","pos":"NOM"} ,
		{"word":"duplication","word_nosc":"duplication","lemma":"duplication","pos":"NOM"} ,
		{"word":"duplice","word_nosc":"duplice","lemma":"duplice","pos":"NOM"} ,
		{"word":"duplicité","word_nosc":"duplicite","lemma":"duplicité","pos":"NOM"} ,
		{"word":"dur","word_nosc":"dur","lemma":"dur","pos":"NOM"} ,
		{"word":"durabilité","word_nosc":"durabilite","lemma":"durabilité","pos":"NOM"} ,
		{"word":"duralumin","word_nosc":"duralumin","lemma":"duralumin","pos":"NOM"} ,
		{"word":"durant","word_nosc":"durant","lemma":"durant","pos":"NOM"} ,
		{"word":"durcissement","word_nosc":"durcissement","lemma":"durcissement","pos":"NOM"} ,
		{"word":"durcisseur","word_nosc":"durcisseur","lemma":"durcisseur","pos":"NOM"} ,
		{"word":"dure","word_nosc":"dure","lemma":"dure","pos":"NOM"} ,
		{"word":"dure-mère","word_nosc":"dure-mere","lemma":"dure-mère","pos":"NOM"} ,
		{"word":"dures","word_nosc":"dures","lemma":"dure","pos":"NOM"} ,
		{"word":"dureté","word_nosc":"durete","lemma":"dureté","pos":"NOM"} ,
		{"word":"duretés","word_nosc":"duretes","lemma":"dureté","pos":"NOM"} ,
		{"word":"durham","word_nosc":"durham","lemma":"durham","pos":"NOM"} ,
		{"word":"durian","word_nosc":"durian","lemma":"durian","pos":"NOM"} ,
		{"word":"durillon","word_nosc":"durillon","lemma":"durillon","pos":"NOM"} ,
		{"word":"durillons","word_nosc":"durillons","lemma":"durillon","pos":"NOM"} ,
		{"word":"durit","word_nosc":"durit","lemma":"durit","pos":"NOM"} ,
		{"word":"durite","word_nosc":"durite","lemma":"durite","pos":"NOM"} ,
		{"word":"durites","word_nosc":"durites","lemma":"durite","pos":"NOM"} ,
		{"word":"durs","word_nosc":"durs","lemma":"dur","pos":"NOM"} ,
		{"word":"durée","word_nosc":"duree","lemma":"durée","pos":"NOM"} ,
		{"word":"durées","word_nosc":"durees","lemma":"durée","pos":"NOM"} ,
		{"word":"duvet","word_nosc":"duvet","lemma":"duvet","pos":"NOM"} ,
		{"word":"duvets","word_nosc":"duvets","lemma":"duvet","pos":"NOM"} ,
		{"word":"duègne","word_nosc":"duegne","lemma":"duègne","pos":"NOM"} ,
		{"word":"dyke","word_nosc":"dyke","lemma":"dyke","pos":"NOM"} ,
		{"word":"dykes","word_nosc":"dykes","lemma":"dyke","pos":"NOM"} ,
		{"word":"dynamique","word_nosc":"dynamique","lemma":"dynamique","pos":"NOM"} ,
		{"word":"dynamiques","word_nosc":"dynamiques","lemma":"dynamique","pos":"NOM"} ,
		{"word":"dynamisme","word_nosc":"dynamisme","lemma":"dynamisme","pos":"NOM"} ,
		{"word":"dynamitage","word_nosc":"dynamitage","lemma":"dynamitage","pos":"NOM"} ,
		{"word":"dynamite","word_nosc":"dynamite","lemma":"dynamite","pos":"NOM"} ,
		{"word":"dynamiteur","word_nosc":"dynamiteur","lemma":"dynamiteur","pos":"NOM"} ,
		{"word":"dynamiteurs","word_nosc":"dynamiteurs","lemma":"dynamiteur","pos":"NOM"} ,
		{"word":"dynamo","word_nosc":"dynamo","lemma":"dynamo","pos":"NOM"} ,
		{"word":"dynamomètre","word_nosc":"dynamometre","lemma":"dynamomètre","pos":"NOM"} ,
		{"word":"dynamos","word_nosc":"dynamos","lemma":"dynamo","pos":"NOM"} ,
		{"word":"dynastie","word_nosc":"dynastie","lemma":"dynastie","pos":"NOM"} ,
		{"word":"dynasties","word_nosc":"dynasties","lemma":"dynastie","pos":"NOM"} ,
		{"word":"dynes","word_nosc":"dynes","lemma":"dyne","pos":"NOM"} ,
		{"word":"dyscinésie","word_nosc":"dyscinesie","lemma":"dyscinésie","pos":"NOM"} ,
		{"word":"dyscrasie","word_nosc":"dyscrasie","lemma":"dyscrasie","pos":"NOM"} ,
		{"word":"dysenterie","word_nosc":"dysenterie","lemma":"dysenterie","pos":"NOM"} ,
		{"word":"dysenteries","word_nosc":"dysenteries","lemma":"dysenterie","pos":"NOM"} ,
		{"word":"dysfonction","word_nosc":"dysfonction","lemma":"dysfonction","pos":"NOM"} ,
		{"word":"dysfonctionnement","word_nosc":"dysfonctionnement","lemma":"dysfonctionnement","pos":"NOM"} ,
		{"word":"dysfonctionnements","word_nosc":"dysfonctionnements","lemma":"dysfonctionnement","pos":"NOM"} ,
		{"word":"dyslexie","word_nosc":"dyslexie","lemma":"dyslexie","pos":"NOM"} ,
		{"word":"dyslexique","word_nosc":"dyslexique","lemma":"dyslexique","pos":"NOM"} ,
		{"word":"dysménorrhée","word_nosc":"dysmenorrhee","lemma":"dysménorrhée","pos":"NOM"} ,
		{"word":"dyspepsie","word_nosc":"dyspepsie","lemma":"dyspepsie","pos":"NOM"} ,
		{"word":"dysphonie","word_nosc":"dysphonie","lemma":"dysphonie","pos":"NOM"} ,
		{"word":"dysphorie","word_nosc":"dysphorie","lemma":"dysphorie","pos":"NOM"} ,
		{"word":"dysplasie","word_nosc":"dysplasie","lemma":"dysplasie","pos":"NOM"} ,
		{"word":"dyspnée","word_nosc":"dyspnee","lemma":"dyspnée","pos":"NOM"} ,
		{"word":"dysthymie","word_nosc":"dysthymie","lemma":"dysthymie","pos":"NOM"} ,
		{"word":"dystocie","word_nosc":"dystocie","lemma":"dystocie","pos":"NOM"} ,
		{"word":"dystrophie","word_nosc":"dystrophie","lemma":"dystrophie","pos":"NOM"} ,
		{"word":"dysurie","word_nosc":"dysurie","lemma":"dysurie","pos":"NOM"} ,
		{"word":"dytique","word_nosc":"dytique","lemma":"dytique","pos":"NOM"} ,
		{"word":"dèche","word_nosc":"deche","lemma":"dèche","pos":"NOM"} ,
		{"word":"dèches","word_nosc":"deches","lemma":"dèche","pos":"NOM"} ,
		{"word":"dèmes","word_nosc":"demes","lemma":"dème","pos":"NOM"} ,
		{"word":"dé","word_nosc":"de","lemma":"dé","pos":"NOM"} ,
		{"word":"déambulateur","word_nosc":"deambulateur","lemma":"déambulateur","pos":"NOM"} ,
		{"word":"déambulation","word_nosc":"deambulation","lemma":"déambulation","pos":"NOM"} ,
		{"word":"déambulations","word_nosc":"deambulations","lemma":"déambulation","pos":"NOM"} ,
		{"word":"déambulatoire","word_nosc":"deambulatoire","lemma":"déambulatoire","pos":"NOM"} ,
		{"word":"débagoulage","word_nosc":"debagoulage","lemma":"débagoulage","pos":"NOM"} ,
		{"word":"déballage","word_nosc":"deballage","lemma":"déballage","pos":"NOM"} ,
		{"word":"déballages","word_nosc":"deballages","lemma":"déballage","pos":"NOM"} ,
		{"word":"déballonnage","word_nosc":"deballonnage","lemma":"déballonnage","pos":"NOM"} ,
		{"word":"débandade","word_nosc":"debandade","lemma":"débandade","pos":"NOM"} ,
		{"word":"débarbot","word_nosc":"debarbot","lemma":"débarbot","pos":"NOM"} ,
		{"word":"débarbots","word_nosc":"debarbots","lemma":"débarbot","pos":"NOM"} ,
		{"word":"débarbouillette","word_nosc":"debarbouillette","lemma":"débarbouillette","pos":"NOM"} ,
		{"word":"débarcadère","word_nosc":"debarcadere","lemma":"débarcadère","pos":"NOM"} ,
		{"word":"débarcadères","word_nosc":"debarcaderes","lemma":"débarcadère","pos":"NOM"} ,
		{"word":"débardage","word_nosc":"debardage","lemma":"débardage","pos":"NOM"} ,
		{"word":"débardages","word_nosc":"debardages","lemma":"débardage","pos":"NOM"} ,
		{"word":"débardeur","word_nosc":"debardeur","lemma":"débardeur","pos":"NOM"} ,
		{"word":"débardeurs","word_nosc":"debardeurs","lemma":"débardeur","pos":"NOM"} ,
		{"word":"débarquement","word_nosc":"debarquement","lemma":"débarquement","pos":"NOM"} ,
		{"word":"débarquements","word_nosc":"debarquements","lemma":"débarquement","pos":"NOM"} ,
		{"word":"débarras","word_nosc":"debarras","lemma":"débarras","pos":"NOM"} ,
		{"word":"débat","word_nosc":"debat","lemma":"débat","pos":"NOM"} ,
		{"word":"débats","word_nosc":"debats","lemma":"débat","pos":"NOM"} ,
		{"word":"débatteur","word_nosc":"debatteur","lemma":"débatteur","pos":"NOM"} ,
		{"word":"débattue","word_nosc":"debattue","lemma":"débattue","pos":"NOM"} ,
		{"word":"débauche","word_nosc":"debauche","lemma":"débauche","pos":"NOM"} ,
		{"word":"débauches","word_nosc":"debauches","lemma":"débauche","pos":"NOM"} ,
		{"word":"débaucheur","word_nosc":"debaucheur","lemma":"débaucheur","pos":"NOM"} ,
		{"word":"débaucheurs","word_nosc":"debaucheurs","lemma":"débaucheur","pos":"NOM"} ,
		{"word":"débauché","word_nosc":"debauche","lemma":"débauché","pos":"NOM"} ,
		{"word":"débauchée","word_nosc":"debauchee","lemma":"débauché","pos":"NOM"} ,
		{"word":"débauchées","word_nosc":"debauchees","lemma":"débauché","pos":"NOM"} ,
		{"word":"débauchés","word_nosc":"debauches","lemma":"débauché","pos":"NOM"} ,
		{"word":"débectage","word_nosc":"debectage","lemma":"débectage","pos":"NOM"} ,
		{"word":"débectance","word_nosc":"debectance","lemma":"débectance","pos":"NOM"} ,
		{"word":"débile","word_nosc":"debile","lemma":"débile","pos":"NOM"} ,
		{"word":"débiles","word_nosc":"debiles","lemma":"débile","pos":"NOM"} ,
		{"word":"débilitation","word_nosc":"debilitation","lemma":"débilitation","pos":"NOM"} ,
		{"word":"débilité","word_nosc":"debilite","lemma":"débilité","pos":"NOM"} ,
		{"word":"débilités","word_nosc":"debilites","lemma":"débilité","pos":"NOM"} ,
		{"word":"débine","word_nosc":"debine","lemma":"débine","pos":"NOM"} ,
		{"word":"débineurs","word_nosc":"debineurs","lemma":"débineur","pos":"NOM"} ,
		{"word":"débineuses","word_nosc":"debineuses","lemma":"débineur","pos":"NOM"} ,
		{"word":"débit","word_nosc":"debit","lemma":"débit","pos":"NOM"} ,
		{"word":"débitage","word_nosc":"debitage","lemma":"débitage","pos":"NOM"} ,
		{"word":"débitant","word_nosc":"debitant","lemma":"débitant","pos":"NOM"} ,
		{"word":"débitants","word_nosc":"debitants","lemma":"débitant","pos":"NOM"} ,
		{"word":"débite","word_nosc":"debite","lemma":"débite","pos":"NOM"} ,
		{"word":"débiteur","word_nosc":"debiteur","lemma":"débiteur","pos":"NOM"} ,
		{"word":"débiteurs","word_nosc":"debiteurs","lemma":"débiteur","pos":"NOM"} ,
		{"word":"débits","word_nosc":"debits","lemma":"débit","pos":"NOM"} ,
		{"word":"déblai","word_nosc":"deblai","lemma":"déblai","pos":"NOM"} ,
		{"word":"déblaiement","word_nosc":"deblaiement","lemma":"déblaiement","pos":"NOM"} ,
		{"word":"déblais","word_nosc":"deblais","lemma":"déblai","pos":"NOM"} ,
		{"word":"déblatérations","word_nosc":"deblaterations","lemma":"déblatération","pos":"NOM"} ,
		{"word":"déblayage","word_nosc":"deblayage","lemma":"déblayage","pos":"NOM"} ,
		{"word":"déblayeurs","word_nosc":"deblayeurs","lemma":"déblayeur","pos":"NOM"} ,
		{"word":"déblocage","word_nosc":"deblocage","lemma":"déblocage","pos":"NOM"} ,
		{"word":"déblocages","word_nosc":"deblocages","lemma":"déblocage","pos":"NOM"} ,
		{"word":"débobinage","word_nosc":"debobinage","lemma":"débobinage","pos":"NOM"} ,
		{"word":"débogage","word_nosc":"debogage","lemma":"débogage","pos":"NOM"} ,
		{"word":"déboire","word_nosc":"deboire","lemma":"déboire","pos":"NOM"} ,
		{"word":"déboires","word_nosc":"deboires","lemma":"déboire","pos":"NOM"} ,
		{"word":"déboisement","word_nosc":"deboisement","lemma":"déboisement","pos":"NOM"} ,
		{"word":"débord","word_nosc":"debord","lemma":"débord","pos":"NOM"} ,
		{"word":"débordement","word_nosc":"debordement","lemma":"débordement","pos":"NOM"} ,
		{"word":"débordements","word_nosc":"debordements","lemma":"débordement","pos":"NOM"} ,
		{"word":"débotté","word_nosc":"debotte","lemma":"débotté","pos":"NOM"} ,
		{"word":"débouchage","word_nosc":"debouchage","lemma":"débouchage","pos":"NOM"} ,
		{"word":"déboucheur","word_nosc":"deboucheur","lemma":"déboucheur","pos":"NOM"} ,
		{"word":"déboucheurs","word_nosc":"deboucheurs","lemma":"déboucheur","pos":"NOM"} ,
		{"word":"débouchoir","word_nosc":"debouchoir","lemma":"débouchoir","pos":"NOM"} ,
		{"word":"débouchoirs","word_nosc":"debouchoirs","lemma":"débouchoir","pos":"NOM"} ,
		{"word":"débouché","word_nosc":"debouche","lemma":"débouché","pos":"NOM"} ,
		{"word":"débouchés","word_nosc":"debouches","lemma":"débouché","pos":"NOM"} ,
		{"word":"déboulé","word_nosc":"deboule","lemma":"déboulé","pos":"NOM"} ,
		{"word":"déboulés","word_nosc":"deboules","lemma":"déboulé","pos":"NOM"} ,
		{"word":"débourrage","word_nosc":"debourrage","lemma":"débourrage","pos":"NOM"} ,
		{"word":"débours","word_nosc":"debours","lemma":"débours","pos":"NOM"} ,
		{"word":"déboursement","word_nosc":"deboursement","lemma":"déboursement","pos":"NOM"} ,
		{"word":"déboutonnage","word_nosc":"deboutonnage","lemma":"déboutonnage","pos":"NOM"} ,
		{"word":"déboîtement","word_nosc":"deboitement","lemma":"déboîtement","pos":"NOM"} ,
		{"word":"débraillé","word_nosc":"debraille","lemma":"débraillé","pos":"NOM"} ,
		{"word":"débraillée","word_nosc":"debraillee","lemma":"débraillé","pos":"NOM"} ,
		{"word":"débraillés","word_nosc":"debrailles","lemma":"débraillé","pos":"NOM"} ,
		{"word":"débranchement","word_nosc":"debranchement","lemma":"débranchement","pos":"NOM"} ,
		{"word":"débrayage","word_nosc":"debrayage","lemma":"débrayage","pos":"NOM"} ,
		{"word":"débrayages","word_nosc":"debrayages","lemma":"débrayage","pos":"NOM"} ,
		{"word":"débridement","word_nosc":"debridement","lemma":"débridement","pos":"NOM"} ,
		{"word":"débriefing","word_nosc":"debriefing","lemma":"débriefing","pos":"NOM"} ,
		{"word":"débris","word_nosc":"debris","lemma":"débris","pos":"NOM"} ,
		{"word":"débrouillage","word_nosc":"debrouillage","lemma":"débrouillage","pos":"NOM"} ,
		{"word":"débrouillard","word_nosc":"debrouillard","lemma":"débrouillard","pos":"NOM"} ,
		{"word":"débrouillarde","word_nosc":"debrouillarde","lemma":"débrouillard","pos":"NOM"} ,
		{"word":"débrouillardise","word_nosc":"debrouillardise","lemma":"débrouillardise","pos":"NOM"} ,
		{"word":"débrouillards","word_nosc":"debrouillards","lemma":"débrouillard","pos":"NOM"} ,
		{"word":"débrouille","word_nosc":"debrouille","lemma":"débrouille","pos":"NOM"} ,
		{"word":"débrouilles","word_nosc":"debrouilles","lemma":"débrouille","pos":"NOM"} ,
		{"word":"débrouilleurs","word_nosc":"debrouilleurs","lemma":"débrouilleur","pos":"NOM"} ,
		{"word":"débroussaillage","word_nosc":"debroussaillage","lemma":"débroussaillage","pos":"NOM"} ,
		{"word":"débroussailleuse","word_nosc":"debroussailleuse","lemma":"débroussailleur","pos":"NOM"} ,
		{"word":"débucher","word_nosc":"debucher","lemma":"débucher","pos":"NOM"} ,
		{"word":"début","word_nosc":"debut","lemma":"début","pos":"NOM"} ,
		{"word":"débutant","word_nosc":"debutant","lemma":"débutant","pos":"NOM"} ,
		{"word":"débutante","word_nosc":"debutante","lemma":"débutant","pos":"NOM"} ,
		{"word":"débutantes","word_nosc":"debutantes","lemma":"débutant","pos":"NOM"} ,
		{"word":"débutants","word_nosc":"debutants","lemma":"débutant","pos":"NOM"} ,
		{"word":"débuts","word_nosc":"debuts","lemma":"début","pos":"NOM"} ,
		{"word":"débâcle","word_nosc":"debacle","lemma":"débâcle","pos":"NOM"} ,
		{"word":"débâcles","word_nosc":"debacles","lemma":"débâcle","pos":"NOM"} ,
		{"word":"déc","word_nosc":"dec","lemma":"déc","pos":"NOM"} ,
		{"word":"déca","word_nosc":"deca","lemma":"déca","pos":"NOM"} ,
		{"word":"décachetage","word_nosc":"decachetage","lemma":"décachetage","pos":"NOM"} ,
		{"word":"décade","word_nosc":"decade","lemma":"décade","pos":"NOM"} ,
		{"word":"décadence","word_nosc":"decadence","lemma":"décadence","pos":"NOM"} ,
		{"word":"décadent","word_nosc":"decadent","lemma":"décadent","pos":"NOM"} ,
		{"word":"décadente","word_nosc":"decadente","lemma":"décadent","pos":"NOM"} ,
		{"word":"décadentes","word_nosc":"decadentes","lemma":"décadent","pos":"NOM"} ,
		{"word":"décadentisme","word_nosc":"decadentisme","lemma":"décadentisme","pos":"NOM"} ,
		{"word":"décadents","word_nosc":"decadents","lemma":"décadent","pos":"NOM"} ,
		{"word":"décades","word_nosc":"decades","lemma":"décade","pos":"NOM"} ,
		{"word":"décadi","word_nosc":"decadi","lemma":"décadi","pos":"NOM"} ,
		{"word":"décadis","word_nosc":"decadis","lemma":"décadi","pos":"NOM"} ,
		{"word":"décaféiné","word_nosc":"decafeine","lemma":"décaféiné","pos":"NOM"} ,
		{"word":"décaissement","word_nosc":"decaissement","lemma":"décaissement","pos":"NOM"} ,
		{"word":"décalage","word_nosc":"decalage","lemma":"décalage","pos":"NOM"} ,
		{"word":"décalages","word_nosc":"decalages","lemma":"décalage","pos":"NOM"} ,
		{"word":"décalcification","word_nosc":"decalcification","lemma":"décalcification","pos":"NOM"} ,
		{"word":"décalcomanie","word_nosc":"decalcomanie","lemma":"décalcomanie","pos":"NOM"} ,
		{"word":"décalcomanies","word_nosc":"decalcomanies","lemma":"décalcomanie","pos":"NOM"} ,
		{"word":"décalitre","word_nosc":"decalitre","lemma":"décalitre","pos":"NOM"} ,
		{"word":"décalitres","word_nosc":"decalitres","lemma":"décalitre","pos":"NOM"} ,
		{"word":"décalogue","word_nosc":"decalogue","lemma":"décalogue","pos":"NOM"} ,
		{"word":"décalque","word_nosc":"decalque","lemma":"décalque","pos":"NOM"} ,
		{"word":"décalques","word_nosc":"decalques","lemma":"décalque","pos":"NOM"} ,
		{"word":"décamètre","word_nosc":"decametre","lemma":"décamètre","pos":"NOM"} ,
		{"word":"décamètres","word_nosc":"decametres","lemma":"décamètre","pos":"NOM"} ,
		{"word":"décan","word_nosc":"decan","lemma":"décan","pos":"NOM"} ,
		{"word":"décantation","word_nosc":"decantation","lemma":"décantation","pos":"NOM"} ,
		{"word":"décanteur","word_nosc":"decanteur","lemma":"décanteur","pos":"NOM"} ,
		{"word":"décapage","word_nosc":"decapage","lemma":"décapage","pos":"NOM"} ,
		{"word":"décapant","word_nosc":"decapant","lemma":"décapant","pos":"NOM"} ,
		{"word":"décapants","word_nosc":"decapants","lemma":"décapant","pos":"NOM"} ,
		{"word":"décapeur","word_nosc":"decapeur","lemma":"décapeur","pos":"NOM"} ,
		{"word":"décapitation","word_nosc":"decapitation","lemma":"décapitation","pos":"NOM"} ,
		{"word":"décapitations","word_nosc":"decapitations","lemma":"décapitation","pos":"NOM"} ,
		{"word":"décapode","word_nosc":"decapode","lemma":"décapode","pos":"NOM"} ,
		{"word":"décapotable","word_nosc":"decapotable","lemma":"décapotable","pos":"NOM"} ,
		{"word":"décapotables","word_nosc":"decapotables","lemma":"décapotable","pos":"NOM"} ,
		{"word":"décapotage","word_nosc":"decapotage","lemma":"décapotage","pos":"NOM"} ,
		{"word":"décapsuleur","word_nosc":"decapsuleur","lemma":"décapsuleur","pos":"NOM"} ,
		{"word":"décarpillage","word_nosc":"decarpillage","lemma":"décarpillage","pos":"NOM"} ,
		{"word":"décarrade","word_nosc":"decarrade","lemma":"décarrade","pos":"NOM"} ,
		{"word":"décarrades","word_nosc":"decarrades","lemma":"décarrade","pos":"NOM"} ,
		{"word":"décathlon","word_nosc":"decathlon","lemma":"décathlon","pos":"NOM"} ,
		{"word":"décathlons","word_nosc":"decathlons","lemma":"décathlon","pos":"NOM"} ,
		{"word":"décavé","word_nosc":"decave","lemma":"décavé","pos":"NOM"} ,
		{"word":"décembre","word_nosc":"decembre","lemma":"décembre","pos":"NOM"} ,
		{"word":"décembres","word_nosc":"decembres","lemma":"décembre","pos":"NOM"} ,
		{"word":"décembriste","word_nosc":"decembriste","lemma":"décembriste","pos":"NOM"} ,
		{"word":"décembristes","word_nosc":"decembristes","lemma":"décembriste","pos":"NOM"} ,
		{"word":"décence","word_nosc":"decence","lemma":"décence","pos":"NOM"} ,
		{"word":"décences","word_nosc":"decences","lemma":"décence","pos":"NOM"} ,
		{"word":"décennie","word_nosc":"decennie","lemma":"décennie","pos":"NOM"} ,
		{"word":"décennies","word_nosc":"decennies","lemma":"décennie","pos":"NOM"} ,
		{"word":"décentralisation","word_nosc":"decentralisation","lemma":"décentralisation","pos":"NOM"} ,
		{"word":"décentrement","word_nosc":"decentrement","lemma":"décentrement","pos":"NOM"} ,
		{"word":"déception","word_nosc":"deception","lemma":"déception","pos":"NOM"} ,
		{"word":"déceptions","word_nosc":"deceptions","lemma":"déception","pos":"NOM"} ,
		{"word":"décervelage","word_nosc":"decervelage","lemma":"décervelage","pos":"NOM"} ,
		{"word":"déchard","word_nosc":"dechard","lemma":"déchard","pos":"NOM"} ,
		{"word":"déchards","word_nosc":"dechards","lemma":"déchard","pos":"NOM"} ,
		{"word":"décharge","word_nosc":"decharge","lemma":"décharge","pos":"NOM"} ,
		{"word":"déchargement","word_nosc":"dechargement","lemma":"déchargement","pos":"NOM"} ,
		{"word":"décharges","word_nosc":"decharges","lemma":"décharge","pos":"NOM"} ,
		{"word":"déchargeur","word_nosc":"dechargeur","lemma":"déchargeur","pos":"NOM"} ,
		{"word":"décharnement","word_nosc":"decharnement","lemma":"décharnement","pos":"NOM"} ,
		{"word":"déchaînement","word_nosc":"dechainement","lemma":"déchaînement","pos":"NOM"} ,
		{"word":"déchaînements","word_nosc":"dechainements","lemma":"déchaînement","pos":"NOM"} ,
		{"word":"déchaîné","word_nosc":"dechaine","lemma":"déchaîné","pos":"NOM"} ,
		{"word":"déchaînée","word_nosc":"dechainee","lemma":"déchaîné","pos":"NOM"} ,
		{"word":"déchaînées","word_nosc":"dechainees","lemma":"déchaîné","pos":"NOM"} ,
		{"word":"déchaînés","word_nosc":"dechaines","lemma":"déchaîné","pos":"NOM"} ,
		{"word":"déchet","word_nosc":"dechet","lemma":"déchet","pos":"NOM"} ,
		{"word":"déchets","word_nosc":"dechets","lemma":"déchet","pos":"NOM"} ,
		{"word":"déchetterie","word_nosc":"dechetterie","lemma":"déchetterie","pos":"NOM"} ,
		{"word":"déchiffrage","word_nosc":"dechiffrage","lemma":"déchiffrage","pos":"NOM"} ,
		{"word":"déchiffrement","word_nosc":"dechiffrement","lemma":"déchiffrement","pos":"NOM"} ,
		{"word":"déchiffreur","word_nosc":"dechiffreur","lemma":"déchiffreur","pos":"NOM"} ,
		{"word":"déchiquetage","word_nosc":"dechiquetage","lemma":"déchiquetage","pos":"NOM"} ,
		{"word":"déchiqueteur","word_nosc":"dechiqueteur","lemma":"déchiqueteur","pos":"NOM"} ,
		{"word":"déchiqueteurs","word_nosc":"dechiqueteurs","lemma":"déchiqueteur","pos":"NOM"} ,
		{"word":"déchiqueteuse","word_nosc":"dechiqueteuse","lemma":"déchiqueteur","pos":"NOM"} ,
		{"word":"déchiqueteuses","word_nosc":"dechiqueteuses","lemma":"déchiqueteur","pos":"NOM"} ,
		{"word":"déchirage","word_nosc":"dechirage","lemma":"déchirage","pos":"NOM"} ,
		{"word":"déchirages","word_nosc":"dechirages","lemma":"déchirage","pos":"NOM"} ,
		{"word":"déchirement","word_nosc":"dechirement","lemma":"déchirement","pos":"NOM"} ,
		{"word":"déchirements","word_nosc":"dechirements","lemma":"déchirement","pos":"NOM"} ,
		{"word":"déchireur","word_nosc":"dechireur","lemma":"déchireur","pos":"NOM"} ,
		{"word":"déchirure","word_nosc":"dechirure","lemma":"déchirure","pos":"NOM"} ,
		{"word":"déchirures","word_nosc":"dechirures","lemma":"déchirure","pos":"NOM"} ,
		{"word":"déchéance","word_nosc":"decheance","lemma":"déchéance","pos":"NOM"} ,
		{"word":"déchéances","word_nosc":"decheances","lemma":"déchéance","pos":"NOM"} ,
		{"word":"déci","word_nosc":"deci","lemma":"déci","pos":"NOM"} ,
		{"word":"décibel","word_nosc":"decibel","lemma":"décibel","pos":"NOM"} ,
		{"word":"décibels","word_nosc":"decibels","lemma":"décibel","pos":"NOM"} ,
		{"word":"décideur","word_nosc":"decideur","lemma":"décideur","pos":"NOM"} ,
		{"word":"décideurs","word_nosc":"decideurs","lemma":"décideur","pos":"NOM"} ,
		{"word":"décigramme","word_nosc":"decigramme","lemma":"décigramme","pos":"NOM"} ,
		{"word":"décigrammes","word_nosc":"decigrammes","lemma":"décigramme","pos":"NOM"} ,
		{"word":"décile","word_nosc":"decile","lemma":"décile","pos":"NOM"} ,
		{"word":"décilitre","word_nosc":"decilitre","lemma":"décilitre","pos":"NOM"} ,
		{"word":"décimalisation","word_nosc":"decimalisation","lemma":"décimalisation","pos":"NOM"} ,
		{"word":"décimalité","word_nosc":"decimalite","lemma":"décimalité","pos":"NOM"} ,
		{"word":"décimateur","word_nosc":"decimateur","lemma":"décimateur","pos":"NOM"} ,
		{"word":"décimation","word_nosc":"decimation","lemma":"décimation","pos":"NOM"} ,
		{"word":"décimes","word_nosc":"decimes","lemma":"décime","pos":"NOM"} ,
		{"word":"décimètre","word_nosc":"decimetre","lemma":"décimètre","pos":"NOM"} ,
		{"word":"décimètres","word_nosc":"decimetres","lemma":"décimètre","pos":"NOM"} ,
		{"word":"décision","word_nosc":"decision","lemma":"décision","pos":"NOM"} ,
		{"word":"décision-clé","word_nosc":"decision-cle","lemma":"décision-clé","pos":"NOM"} ,
		{"word":"décisionnaire","word_nosc":"decisionnaire","lemma":"décisionnaire","pos":"NOM"} ,
		{"word":"décisions","word_nosc":"decisions","lemma":"décision","pos":"NOM"} ,
		{"word":"déclamation","word_nosc":"declamation","lemma":"déclamation","pos":"NOM"} ,
		{"word":"déclamations","word_nosc":"declamations","lemma":"déclamation","pos":"NOM"} ,
		{"word":"déclamatrice","word_nosc":"declamatrice","lemma":"déclamateur","pos":"NOM"} ,
		{"word":"déclaration","word_nosc":"declaration","lemma":"déclaration","pos":"NOM"} ,
		{"word":"déclarations","word_nosc":"declarations","lemma":"déclaration","pos":"NOM"} ,
		{"word":"déclassement","word_nosc":"declassement","lemma":"déclassement","pos":"NOM"} ,
		{"word":"déclassé","word_nosc":"declasse","lemma":"déclassé","pos":"NOM"} ,
		{"word":"déclassée","word_nosc":"declassee","lemma":"déclassé","pos":"NOM"} ,
		{"word":"déclassées","word_nosc":"declassees","lemma":"déclassé","pos":"NOM"} ,
		{"word":"déclassés","word_nosc":"declasses","lemma":"déclassé","pos":"NOM"} ,
		{"word":"déclenche","word_nosc":"declenche","lemma":"déclenche","pos":"NOM"} ,
		{"word":"déclenchement","word_nosc":"declenchement","lemma":"déclenchement","pos":"NOM"} ,
		{"word":"déclencheur","word_nosc":"declencheur","lemma":"déclencheur","pos":"NOM"} ,
		{"word":"déclencheurs","word_nosc":"declencheurs","lemma":"déclencheur","pos":"NOM"} ,
		{"word":"déclic","word_nosc":"declic","lemma":"déclic","pos":"NOM"} ,
		{"word":"déclics","word_nosc":"declics","lemma":"déclic","pos":"NOM"} ,
		{"word":"déclin","word_nosc":"declin","lemma":"déclin","pos":"NOM"} ,
		{"word":"déclinaison","word_nosc":"declinaison","lemma":"déclinaison","pos":"NOM"} ,
		{"word":"déclinaisons","word_nosc":"declinaisons","lemma":"déclinaison","pos":"NOM"} ,
		{"word":"déclins","word_nosc":"declins","lemma":"déclin","pos":"NOM"} ,
		{"word":"déclivité","word_nosc":"declivite","lemma":"déclivité","pos":"NOM"} ,
		{"word":"déclivités","word_nosc":"declivites","lemma":"déclivité","pos":"NOM"} ,
		{"word":"décocheuses","word_nosc":"decocheuses","lemma":"décocheur","pos":"NOM"} ,
		{"word":"décoction","word_nosc":"decoction","lemma":"décoction","pos":"NOM"} ,
		{"word":"décoctions","word_nosc":"decoctions","lemma":"décoction","pos":"NOM"} ,
		{"word":"décodage","word_nosc":"decodage","lemma":"décodage","pos":"NOM"} ,
		{"word":"décodages","word_nosc":"decodages","lemma":"décodage","pos":"NOM"} ,
		{"word":"décodeur","word_nosc":"decodeur","lemma":"décodeur","pos":"NOM"} ,
		{"word":"décodeurs","word_nosc":"decodeurs","lemma":"décodeur","pos":"NOM"} ,
		{"word":"décodeuse","word_nosc":"decodeuse","lemma":"décodeur","pos":"NOM"} ,
		{"word":"décoffrage","word_nosc":"decoffrage","lemma":"décoffrage","pos":"NOM"} ,
		{"word":"décoinçage","word_nosc":"decoincage","lemma":"décoinçage","pos":"NOM"} ,
		{"word":"décollage","word_nosc":"decollage","lemma":"décollage","pos":"NOM"} ,
		{"word":"décollages","word_nosc":"decollages","lemma":"décollage","pos":"NOM"} ,
		{"word":"décollation","word_nosc":"decollation","lemma":"décollation","pos":"NOM"} ,
		{"word":"décollement","word_nosc":"decollement","lemma":"décollement","pos":"NOM"} ,
		{"word":"décollements","word_nosc":"decollements","lemma":"décollement","pos":"NOM"} ,
		{"word":"décolleté","word_nosc":"decollete","lemma":"décolleté","pos":"NOM"} ,
		{"word":"décolletés","word_nosc":"decolletes","lemma":"décolleté","pos":"NOM"} ,
		{"word":"décolonisation","word_nosc":"decolonisation","lemma":"décolonisation","pos":"NOM"} ,
		{"word":"décolorant","word_nosc":"decolorant","lemma":"décolorant","pos":"NOM"} ,
		{"word":"décoloration","word_nosc":"decoloration","lemma":"décoloration","pos":"NOM"} ,
		{"word":"décolorations","word_nosc":"decolorations","lemma":"décoloration","pos":"NOM"} ,
		{"word":"décombres","word_nosc":"decombres","lemma":"décombre","pos":"NOM"} ,
		{"word":"décompensation","word_nosc":"decompensation","lemma":"décompensation","pos":"NOM"} ,
		{"word":"décomposeur","word_nosc":"decomposeur","lemma":"décomposeur","pos":"NOM"} ,
		{"word":"décomposition","word_nosc":"decomposition","lemma":"décomposition","pos":"NOM"} ,
		{"word":"décompositions","word_nosc":"decompositions","lemma":"décomposition","pos":"NOM"} ,
		{"word":"décompression","word_nosc":"decompression","lemma":"décompression","pos":"NOM"} ,
		{"word":"décompressions","word_nosc":"decompressions","lemma":"décompression","pos":"NOM"} ,
		{"word":"décompte","word_nosc":"decompte","lemma":"décompte","pos":"NOM"} ,
		{"word":"décomptes","word_nosc":"decomptes","lemma":"décompte","pos":"NOM"} ,
		{"word":"déconcentration","word_nosc":"deconcentration","lemma":"déconcentration","pos":"NOM"} ,
		{"word":"déconditionnement","word_nosc":"deconditionnement","lemma":"déconditionnement","pos":"NOM"} ,
		{"word":"déconfiture","word_nosc":"deconfiture","lemma":"déconfiture","pos":"NOM"} ,
		{"word":"déconfitures","word_nosc":"deconfitures","lemma":"déconfiture","pos":"NOM"} ,
		{"word":"décongestif","word_nosc":"decongestif","lemma":"décongestif","pos":"NOM"} ,
		{"word":"décongestion","word_nosc":"decongestion","lemma":"décongestion","pos":"NOM"} ,
		{"word":"décongélation","word_nosc":"decongelation","lemma":"décongélation","pos":"NOM"} ,
		{"word":"déconnage","word_nosc":"deconnage","lemma":"déconnage","pos":"NOM"} ,
		{"word":"déconnages","word_nosc":"deconnages","lemma":"déconnage","pos":"NOM"} ,
		{"word":"déconnection","word_nosc":"deconnection","lemma":"déconnection","pos":"NOM"} ,
		{"word":"déconneur","word_nosc":"deconneur","lemma":"déconneur","pos":"NOM"} ,
		{"word":"déconneurs","word_nosc":"deconneurs","lemma":"déconneur","pos":"NOM"} ,
		{"word":"déconneuse","word_nosc":"deconneuse","lemma":"déconneur","pos":"NOM"} ,
		{"word":"déconnexion","word_nosc":"deconnexion","lemma":"déconnexion","pos":"NOM"} ,
		{"word":"déconomètre","word_nosc":"deconometre","lemma":"déconomètre","pos":"NOM"} ,
		{"word":"déconophone","word_nosc":"deconophone","lemma":"déconophone","pos":"NOM"} ,
		{"word":"déconophones","word_nosc":"deconophones","lemma":"déconophone","pos":"NOM"} ,
		{"word":"déconsidération","word_nosc":"deconsideration","lemma":"déconsidération","pos":"NOM"} ,
		{"word":"déconstruction","word_nosc":"deconstruction","lemma":"déconstruction","pos":"NOM"} ,
		{"word":"décontamination","word_nosc":"decontamination","lemma":"décontamination","pos":"NOM"} ,
		{"word":"décontraction","word_nosc":"decontraction","lemma":"décontraction","pos":"NOM"} ,
		{"word":"décontracté","word_nosc":"decontracte","lemma":"décontracté","pos":"NOM"} ,
		{"word":"décontractée","word_nosc":"decontractee","lemma":"décontracté","pos":"NOM"} ,
		{"word":"décontractées","word_nosc":"decontractees","lemma":"décontracté","pos":"NOM"} ,
		{"word":"décontractés","word_nosc":"decontractes","lemma":"décontracté","pos":"NOM"} ,
		{"word":"déconvenue","word_nosc":"deconvenue","lemma":"déconvenue","pos":"NOM"} ,
		{"word":"déconvenues","word_nosc":"deconvenues","lemma":"déconvenue","pos":"NOM"} ,
		{"word":"décor","word_nosc":"decor","lemma":"décor","pos":"NOM"} ,
		{"word":"décorateur","word_nosc":"decorateur","lemma":"décorateur","pos":"NOM"} ,
		{"word":"décorateurs","word_nosc":"decorateurs","lemma":"décorateur","pos":"NOM"} ,
		{"word":"décoration","word_nosc":"decoration","lemma":"décoration","pos":"NOM"} ,
		{"word":"décorations","word_nosc":"decorations","lemma":"décoration","pos":"NOM"} ,
		{"word":"décoratrice","word_nosc":"decoratrice","lemma":"décorateur","pos":"NOM"} ,
		{"word":"décoratrices","word_nosc":"decoratrices","lemma":"décorateur","pos":"NOM"} ,
		{"word":"décors","word_nosc":"decors","lemma":"décor","pos":"NOM"} ,
		{"word":"décorticage","word_nosc":"decorticage","lemma":"décorticage","pos":"NOM"} ,
		{"word":"décortication","word_nosc":"decortication","lemma":"décortication","pos":"NOM"} ,
		{"word":"décorum","word_nosc":"decorum","lemma":"décorum","pos":"NOM"} ,
		{"word":"découpage","word_nosc":"decoupage","lemma":"découpage","pos":"NOM"} ,
		{"word":"découpages","word_nosc":"decoupages","lemma":"découpage","pos":"NOM"} ,
		{"word":"découpe","word_nosc":"decoupe","lemma":"découpe","pos":"NOM"} ,
		{"word":"découpes","word_nosc":"decoupes","lemma":"découpe","pos":"NOM"} ,
		{"word":"découpeur","word_nosc":"decoupeur","lemma":"découpeur","pos":"NOM"} ,
		{"word":"découpeurs","word_nosc":"decoupeurs","lemma":"découpeur","pos":"NOM"} ,
		{"word":"découpeuse","word_nosc":"decoupeuse","lemma":"découpeur","pos":"NOM"} ,
		{"word":"découplage","word_nosc":"decouplage","lemma":"découplage","pos":"NOM"} ,
		{"word":"découpure","word_nosc":"decoupure","lemma":"découpure","pos":"NOM"} ,
		{"word":"découpures","word_nosc":"decoupures","lemma":"découpure","pos":"NOM"} ,
		{"word":"découragement","word_nosc":"decouragement","lemma":"découragement","pos":"NOM"} ,
		{"word":"découragements","word_nosc":"decouragements","lemma":"découragement","pos":"NOM"} ,
		{"word":"décours","word_nosc":"decours","lemma":"décours","pos":"NOM"} ,
		{"word":"découvert","word_nosc":"decouvert","lemma":"découvert","pos":"NOM"} ,
		{"word":"découverte","word_nosc":"decouverte","lemma":"découverte","pos":"NOM"} ,
		{"word":"découvertes","word_nosc":"decouvertes","lemma":"découverte","pos":"NOM"} ,
		{"word":"découverts","word_nosc":"decouverts","lemma":"découvert","pos":"NOM"} ,
		{"word":"découvreur","word_nosc":"decouvreur","lemma":"découvreur","pos":"NOM"} ,
		{"word":"découvreurs","word_nosc":"decouvreurs","lemma":"découvreur","pos":"NOM"} ,
		{"word":"découvreuse","word_nosc":"decouvreuse","lemma":"découvreur","pos":"NOM"} ,
		{"word":"décrassage","word_nosc":"decrassage","lemma":"décrassage","pos":"NOM"} ,
		{"word":"décret","word_nosc":"decret","lemma":"décret","pos":"NOM"} ,
		{"word":"décret-loi","word_nosc":"decret-loi","lemma":"décret-loi","pos":"NOM"} ,
		{"word":"décrets","word_nosc":"decrets","lemma":"décret","pos":"NOM"} ,
		{"word":"décris","word_nosc":"decris","lemma":"décri","pos":"NOM"} ,
		{"word":"décrochage","word_nosc":"decrochage","lemma":"décrochage","pos":"NOM"} ,
		{"word":"décrochages","word_nosc":"decrochages","lemma":"décrochage","pos":"NOM"} ,
		{"word":"décrochement","word_nosc":"decrochement","lemma":"décrochement","pos":"NOM"} ,
		{"word":"décrochements","word_nosc":"decrochements","lemma":"décrochement","pos":"NOM"} ,
		{"word":"décrochez-moi-ça","word_nosc":"decrochez-moi-ca","lemma":"décrochez-moi-ça","pos":"NOM"} ,
		{"word":"décroissance","word_nosc":"decroissance","lemma":"décroissance","pos":"NOM"} ,
		{"word":"décrottages","word_nosc":"decrottages","lemma":"décrottage","pos":"NOM"} ,
		{"word":"décrottoir","word_nosc":"decrottoir","lemma":"décrottoir","pos":"NOM"} ,
		{"word":"décroît","word_nosc":"decroit","lemma":"décroît","pos":"NOM"} ,
		{"word":"décrue","word_nosc":"decrue","lemma":"décrue","pos":"NOM"} ,
		{"word":"décryptage","word_nosc":"decryptage","lemma":"décryptage","pos":"NOM"} ,
		{"word":"décrypteur","word_nosc":"decrypteur","lemma":"décrypteur","pos":"NOM"} ,
		{"word":"décrypteurs","word_nosc":"decrypteurs","lemma":"décrypteur","pos":"NOM"} ,
		{"word":"décrépitude","word_nosc":"decrepitude","lemma":"décrépitude","pos":"NOM"} ,
		{"word":"décrêpage","word_nosc":"decrepage","lemma":"décrêpage","pos":"NOM"} ,
		{"word":"déculottée","word_nosc":"deculottee","lemma":"déculottée","pos":"NOM"} ,
		{"word":"déculottées","word_nosc":"deculottees","lemma":"déculottée","pos":"NOM"} ,
		{"word":"déculottés","word_nosc":"deculottes","lemma":"déculotté","pos":"NOM"} ,
		{"word":"décès","word_nosc":"deces","lemma":"décès","pos":"NOM"} ,
		{"word":"décélération","word_nosc":"deceleration","lemma":"décélération","pos":"NOM"} ,
		{"word":"décérébration","word_nosc":"decerebration","lemma":"décérébration","pos":"NOM"} ,
		{"word":"dédaigneuse","word_nosc":"dedaigneuse","lemma":"dédaigneuse","pos":"NOM"} ,
		{"word":"dédaigneuse","word_nosc":"dedaigneuse","lemma":"dédaigneux","pos":"NOM"} ,
		{"word":"dédain","word_nosc":"dedain","lemma":"dédain","pos":"NOM"} ,
		{"word":"dédains","word_nosc":"dedains","lemma":"dédain","pos":"NOM"} ,
		{"word":"dédale","word_nosc":"dedale","lemma":"dédale","pos":"NOM"} ,
		{"word":"dédales","word_nosc":"dedales","lemma":"dédale","pos":"NOM"} ,
		{"word":"dédicace","word_nosc":"dedicace","lemma":"dédicace","pos":"NOM"} ,
		{"word":"dédicaces","word_nosc":"dedicaces","lemma":"dédicace","pos":"NOM"} ,
		{"word":"dédit","word_nosc":"dedit","lemma":"dédit","pos":"NOM"} ,
		{"word":"dédommagement","word_nosc":"dedommagement","lemma":"dédommagement","pos":"NOM"} ,
		{"word":"dédommagements","word_nosc":"dedommagements","lemma":"dédommagement","pos":"NOM"} ,
		{"word":"dédouanement","word_nosc":"dedouanement","lemma":"dédouanement","pos":"NOM"} ,
		{"word":"dédoublement","word_nosc":"dedoublement","lemma":"dédoublement","pos":"NOM"} ,
		{"word":"déducteur","word_nosc":"deducteur","lemma":"déducteur","pos":"NOM"} ,
		{"word":"déductibilité","word_nosc":"deductibilite","lemma":"déductibilité","pos":"NOM"} ,
		{"word":"déduction","word_nosc":"deduction","lemma":"déduction","pos":"NOM"} ,
		{"word":"déductions","word_nosc":"deductions","lemma":"déduction","pos":"NOM"} ,
		{"word":"déduit","word_nosc":"deduit","lemma":"déduit","pos":"NOM"} ,
		{"word":"déesse","word_nosc":"deesse","lemma":"déesse","pos":"NOM"} ,
		{"word":"déesses","word_nosc":"deesses","lemma":"déesse","pos":"NOM"} ,
		{"word":"défaillance","word_nosc":"defaillance","lemma":"défaillance","pos":"NOM"} ,
		{"word":"défaillances","word_nosc":"defaillances","lemma":"défaillance","pos":"NOM"} ,
		{"word":"défaite","word_nosc":"defaite","lemma":"défaite","pos":"NOM"} ,
		{"word":"défaites","word_nosc":"defaites","lemma":"défaite","pos":"NOM"} ,
		{"word":"défaitisme","word_nosc":"defaitisme","lemma":"défaitisme","pos":"NOM"} ,
		{"word":"défaitiste","word_nosc":"defaitiste","lemma":"défaitiste","pos":"NOM"} ,
		{"word":"défaitistes","word_nosc":"defaitistes","lemma":"défaitiste","pos":"NOM"} ,
		{"word":"défaut","word_nosc":"defaut","lemma":"défaut","pos":"NOM"} ,
		{"word":"défauts","word_nosc":"defauts","lemma":"défaut","pos":"NOM"} ,
		{"word":"défaveur","word_nosc":"defaveur","lemma":"défaveur","pos":"NOM"} ,
		{"word":"défavorisé","word_nosc":"defavorise","lemma":"défavorisé","pos":"NOM"} ,
		{"word":"défavorisées","word_nosc":"defavorisees","lemma":"défavorisé","pos":"NOM"} ,
		{"word":"défavorisés","word_nosc":"defavorises","lemma":"défavorisé","pos":"NOM"} ,
		{"word":"défection","word_nosc":"defection","lemma":"défection","pos":"NOM"} ,
		{"word":"défections","word_nosc":"defections","lemma":"défection","pos":"NOM"} ,
		{"word":"défectuosité","word_nosc":"defectuosite","lemma":"défectuosité","pos":"NOM"} ,
		{"word":"défenderesse","word_nosc":"defenderesse","lemma":"défendeur","pos":"NOM"} ,
		{"word":"défendeur","word_nosc":"defendeur","lemma":"défendeur","pos":"NOM"} ,
		{"word":"défendeurs","word_nosc":"defendeurs","lemma":"défendeur","pos":"NOM"} ,
		{"word":"défends","word_nosc":"defends","lemma":"défends","pos":"NOM"} ,
		{"word":"défenestration","word_nosc":"defenestration","lemma":"défenestration","pos":"NOM"} ,
		{"word":"défens","word_nosc":"defens","lemma":"défens","pos":"NOM"} ,
		{"word":"défense","word_nosc":"defense","lemma":"défense","pos":"NOM"} ,
		{"word":"défenses","word_nosc":"defenses","lemma":"défense","pos":"NOM"} ,
		{"word":"défenseur","word_nosc":"defenseur","lemma":"défenseur","pos":"NOM"} ,
		{"word":"défenseurs","word_nosc":"defenseurs","lemma":"défenseur","pos":"NOM"} ,
		{"word":"défensive","word_nosc":"defensive","lemma":"défensive","pos":"NOM"} ,
		{"word":"déferlante","word_nosc":"deferlante","lemma":"déferlante","pos":"NOM"} ,
		{"word":"déferlantes","word_nosc":"deferlantes","lemma":"déferlante","pos":"NOM"} ,
		{"word":"déferlement","word_nosc":"deferlement","lemma":"déferlement","pos":"NOM"} ,
		{"word":"déferlements","word_nosc":"deferlements","lemma":"déferlement","pos":"NOM"} ,
		{"word":"défi","word_nosc":"defi","lemma":"défi","pos":"NOM"} ,
		{"word":"défiance","word_nosc":"defiance","lemma":"défiance","pos":"NOM"} ,
		{"word":"défiances","word_nosc":"defiances","lemma":"défiance","pos":"NOM"} ,
		{"word":"défibreur","word_nosc":"defibreur","lemma":"défibreur","pos":"NOM"} ,
		{"word":"défibrillateur","word_nosc":"defibrillateur","lemma":"défibrillateur","pos":"NOM"} ,
		{"word":"défibrillateurs","word_nosc":"defibrillateurs","lemma":"défibrillateur","pos":"NOM"} ,
		{"word":"défibrillation","word_nosc":"defibrillation","lemma":"défibrillation","pos":"NOM"} ,
		{"word":"déficience","word_nosc":"deficience","lemma":"déficience","pos":"NOM"} ,
		{"word":"déficiences","word_nosc":"deficiences","lemma":"déficience","pos":"NOM"} ,
		{"word":"déficient","word_nosc":"deficient","lemma":"déficient","pos":"NOM"} ,
		{"word":"déficients","word_nosc":"deficients","lemma":"déficient","pos":"NOM"} ,
		{"word":"déficit","word_nosc":"deficit","lemma":"déficit","pos":"NOM"} ,
		{"word":"déficits","word_nosc":"deficits","lemma":"déficit","pos":"NOM"} ,
		{"word":"défiguration","word_nosc":"defiguration","lemma":"défiguration","pos":"NOM"} ,
		{"word":"défigurations","word_nosc":"defigurations","lemma":"défiguration","pos":"NOM"} ,
		{"word":"défigurement","word_nosc":"defigurement","lemma":"défigurement","pos":"NOM"} ,
		{"word":"défilement","word_nosc":"defilement","lemma":"défilement","pos":"NOM"} ,
		{"word":"défilements","word_nosc":"defilements","lemma":"défilement","pos":"NOM"} ,
		{"word":"défilé","word_nosc":"defile","lemma":"défilé","pos":"NOM"} ,
		{"word":"défilés","word_nosc":"defiles","lemma":"défilé","pos":"NOM"} ,
		{"word":"défini","word_nosc":"defini","lemma":"défini","pos":"NOM"} ,
		{"word":"définis","word_nosc":"definis","lemma":"défini","pos":"NOM"} ,
		{"word":"définition","word_nosc":"definition","lemma":"définition","pos":"NOM"} ,
		{"word":"définitions","word_nosc":"definitions","lemma":"définition","pos":"NOM"} ,
		{"word":"défis","word_nosc":"defis","lemma":"défi","pos":"NOM"} ,
		{"word":"défiscalisations","word_nosc":"defiscalisations","lemma":"défiscalisation","pos":"NOM"} ,
		{"word":"déflagration","word_nosc":"deflagration","lemma":"déflagration","pos":"NOM"} ,
		{"word":"déflagrations","word_nosc":"deflagrations","lemma":"déflagration","pos":"NOM"} ,
		{"word":"déflecteur","word_nosc":"deflecteur","lemma":"déflecteur","pos":"NOM"} ,
		{"word":"déflecteurs","word_nosc":"deflecteurs","lemma":"déflecteur","pos":"NOM"} ,
		{"word":"déflexion","word_nosc":"deflexion","lemma":"déflexion","pos":"NOM"} ,
		{"word":"défloraison","word_nosc":"defloraison","lemma":"défloraison","pos":"NOM"} ,
		{"word":"défloration","word_nosc":"defloration","lemma":"défloration","pos":"NOM"} ,
		{"word":"défoliant","word_nosc":"defoliant","lemma":"défoliant","pos":"NOM"} ,
		{"word":"défonce","word_nosc":"defonce","lemma":"défonce","pos":"NOM"} ,
		{"word":"défoncement","word_nosc":"defoncement","lemma":"défoncement","pos":"NOM"} ,
		{"word":"défoncements","word_nosc":"defoncements","lemma":"défoncement","pos":"NOM"} ,
		{"word":"défonceuse","word_nosc":"defonceuse","lemma":"défonceuse","pos":"NOM"} ,
		{"word":"déforestation","word_nosc":"deforestation","lemma":"déforestation","pos":"NOM"} ,
		{"word":"déformation","word_nosc":"deformation","lemma":"déformation","pos":"NOM"} ,
		{"word":"déformations","word_nosc":"deformations","lemma":"déformation","pos":"NOM"} ,
		{"word":"défoulement","word_nosc":"defoulement","lemma":"défoulement","pos":"NOM"} ,
		{"word":"défouloir","word_nosc":"defouloir","lemma":"défouloir","pos":"NOM"} ,
		{"word":"défragmenteur","word_nosc":"defragmenteur","lemma":"défragmenteur","pos":"NOM"} ,
		{"word":"défraiement","word_nosc":"defraiement","lemma":"défraiement","pos":"NOM"} ,
		{"word":"défraiements","word_nosc":"defraiements","lemma":"défraiement","pos":"NOM"} ,
		{"word":"défrichage","word_nosc":"defrichage","lemma":"défrichage","pos":"NOM"} ,
		{"word":"défrichement","word_nosc":"defrichement","lemma":"défrichement","pos":"NOM"} ,
		{"word":"défrichements","word_nosc":"defrichements","lemma":"défrichement","pos":"NOM"} ,
		{"word":"défricheurs","word_nosc":"defricheurs","lemma":"défricheur","pos":"NOM"} ,
		{"word":"défrisage","word_nosc":"defrisage","lemma":"défrisage","pos":"NOM"} ,
		{"word":"défroque","word_nosc":"defroque","lemma":"défroque","pos":"NOM"} ,
		{"word":"défroques","word_nosc":"defroques","lemma":"défroque","pos":"NOM"} ,
		{"word":"défroqué","word_nosc":"defroque","lemma":"défroqué","pos":"NOM"} ,
		{"word":"défunt","word_nosc":"defunt","lemma":"défunt","pos":"NOM"} ,
		{"word":"défunte","word_nosc":"defunte","lemma":"défunt","pos":"NOM"} ,
		{"word":"défuntes","word_nosc":"defuntes","lemma":"défunt","pos":"NOM"} ,
		{"word":"défunts","word_nosc":"defunts","lemma":"défunt","pos":"NOM"} ,
		{"word":"défécation","word_nosc":"defecation","lemma":"défécation","pos":"NOM"} ,
		{"word":"défécations","word_nosc":"defecations","lemma":"défécation","pos":"NOM"} ,
		{"word":"déférence","word_nosc":"deference","lemma":"déférence","pos":"NOM"} ,
		{"word":"dégagement","word_nosc":"degagement","lemma":"dégagement","pos":"NOM"} ,
		{"word":"dégagements","word_nosc":"degagements","lemma":"dégagement","pos":"NOM"} ,
		{"word":"dégagé","word_nosc":"degage","lemma":"dégagé","pos":"NOM"} ,
		{"word":"dégagés","word_nosc":"degages","lemma":"dégagé","pos":"NOM"} ,
		{"word":"dégaine","word_nosc":"degaine","lemma":"dégaine","pos":"NOM"} ,
		{"word":"dégaines","word_nosc":"degaines","lemma":"dégaine","pos":"NOM"} ,
		{"word":"dégauchisseuse","word_nosc":"degauchisseuse","lemma":"dégauchisseuse","pos":"NOM"} ,
		{"word":"dégazage","word_nosc":"degazage","lemma":"dégazage","pos":"NOM"} ,
		{"word":"dégel","word_nosc":"degel","lemma":"dégel","pos":"NOM"} ,
		{"word":"dégelée","word_nosc":"degelee","lemma":"dégelée","pos":"NOM"} ,
		{"word":"dégelées","word_nosc":"degelees","lemma":"dégelée","pos":"NOM"} ,
		{"word":"dégivrage","word_nosc":"degivrage","lemma":"dégivrage","pos":"NOM"} ,
		{"word":"déglingue","word_nosc":"deglingue","lemma":"déglingue","pos":"NOM"} ,
		{"word":"déglutition","word_nosc":"deglutition","lemma":"déglutition","pos":"NOM"} ,
		{"word":"déglutitions","word_nosc":"deglutitions","lemma":"déglutition","pos":"NOM"} ,
		{"word":"dégobillage","word_nosc":"degobillage","lemma":"dégobillage","pos":"NOM"} ,
		{"word":"dégommage","word_nosc":"degommage","lemma":"dégommage","pos":"NOM"} ,
		{"word":"dégonflage","word_nosc":"degonflage","lemma":"dégonflage","pos":"NOM"} ,
		{"word":"dégonflard","word_nosc":"degonflard","lemma":"dégonflard","pos":"NOM"} ,
		{"word":"dégonflement","word_nosc":"degonflement","lemma":"dégonflement","pos":"NOM"} ,
		{"word":"dégonflé","word_nosc":"degonfle","lemma":"dégonflé","pos":"NOM"} ,
		{"word":"dégonflée","word_nosc":"degonflee","lemma":"dégonflé","pos":"NOM"} ,
		{"word":"dégonflées","word_nosc":"degonflees","lemma":"dégonflé","pos":"NOM"} ,
		{"word":"dégonflés","word_nosc":"degonfles","lemma":"dégonflé","pos":"NOM"} ,
		{"word":"dégorgement","word_nosc":"degorgement","lemma":"dégorgement","pos":"NOM"} ,
		{"word":"dégoulinade","word_nosc":"degoulinade","lemma":"dégoulinade","pos":"NOM"} ,
		{"word":"dégoulinades","word_nosc":"degoulinades","lemma":"dégoulinade","pos":"NOM"} ,
		{"word":"dégoulinement","word_nosc":"degoulinement","lemma":"dégoulinement","pos":"NOM"} ,
		{"word":"dégoulinements","word_nosc":"degoulinements","lemma":"dégoulinement","pos":"NOM"} ,
		{"word":"dégoulinures","word_nosc":"degoulinures","lemma":"dégoulinure","pos":"NOM"} ,
		{"word":"dégourdi","word_nosc":"degourdi","lemma":"dégourdi","pos":"NOM"} ,
		{"word":"dégourdie","word_nosc":"degourdie","lemma":"dégourdi","pos":"NOM"} ,
		{"word":"dégourdis","word_nosc":"degourdis","lemma":"dégourdi","pos":"NOM"} ,
		{"word":"dégouttante","word_nosc":"degouttante","lemma":"dégouttant","pos":"NOM"} ,
		{"word":"dégoût","word_nosc":"degout","lemma":"dégoût","pos":"NOM"} ,
		{"word":"dégoûtant","word_nosc":"degoutant","lemma":"dégoûtant","pos":"NOM"} ,
		{"word":"dégoûtante","word_nosc":"degoutante","lemma":"dégoûtant","pos":"NOM"} ,
		{"word":"dégoûtantes","word_nosc":"degoutantes","lemma":"dégoûtant","pos":"NOM"} ,
		{"word":"dégoûtants","word_nosc":"degoutants","lemma":"dégoûtant","pos":"NOM"} ,
		{"word":"dégoûtation","word_nosc":"degoutation","lemma":"dégoûtation","pos":"NOM"} ,
		{"word":"dégoûtations","word_nosc":"degoutations","lemma":"dégoûtation","pos":"NOM"} ,
		{"word":"dégoûts","word_nosc":"degouts","lemma":"dégoût","pos":"NOM"} ,
		{"word":"dégoûté","word_nosc":"degoute","lemma":"dégoûté","pos":"NOM"} ,
		{"word":"dégoûtée","word_nosc":"degoutee","lemma":"dégoûté","pos":"NOM"} ,
		{"word":"dégoûtées","word_nosc":"degoutees","lemma":"dégoûté","pos":"NOM"} ,
		{"word":"dégoûtés","word_nosc":"degoutes","lemma":"dégoûté","pos":"NOM"} ,
		{"word":"dégradation","word_nosc":"degradation","lemma":"dégradation","pos":"NOM"} ,
		{"word":"dégradations","word_nosc":"degradations","lemma":"dégradation","pos":"NOM"} ,
		{"word":"dégradé","word_nosc":"degrade","lemma":"dégradé","pos":"NOM"} ,
		{"word":"dégradés","word_nosc":"degrades","lemma":"dégradé","pos":"NOM"} ,
		{"word":"dégrafeur","word_nosc":"degrafeur","lemma":"dégrafeur","pos":"NOM"} ,
		{"word":"dégraissage","word_nosc":"degraissage","lemma":"dégraissage","pos":"NOM"} ,
		{"word":"dégraissant","word_nosc":"degraissant","lemma":"dégraissant","pos":"NOM"} ,
		{"word":"dégriffeur","word_nosc":"degriffeur","lemma":"dégriffeur","pos":"NOM"} ,
		{"word":"dégringolade","word_nosc":"degringolade","lemma":"dégringolade","pos":"NOM"} ,
		{"word":"dégringolades","word_nosc":"degringolades","lemma":"dégringolade","pos":"NOM"} ,
		{"word":"dégrisement","word_nosc":"degrisement","lemma":"dégrisement","pos":"NOM"} ,
		{"word":"dégrossissage","word_nosc":"degrossissage","lemma":"dégrossissage","pos":"NOM"} ,
		{"word":"dégrèvement","word_nosc":"degrevement","lemma":"dégrèvement","pos":"NOM"} ,
		{"word":"dégrèvements","word_nosc":"degrevements","lemma":"dégrèvement","pos":"NOM"} ,
		{"word":"déguenillée","word_nosc":"deguenillee","lemma":"déguenillé","pos":"NOM"} ,
		{"word":"dégueulando","word_nosc":"degueulando","lemma":"dégueulando","pos":"NOM"} ,
		{"word":"dégueulasserie","word_nosc":"degueulasserie","lemma":"dégueulasserie","pos":"NOM"} ,
		{"word":"dégueulasseries","word_nosc":"degueulasseries","lemma":"dégueulasserie","pos":"NOM"} ,
		{"word":"dégueulis","word_nosc":"degueulis","lemma":"dégueulis","pos":"NOM"} ,
		{"word":"déguisement","word_nosc":"deguisement","lemma":"déguisement","pos":"NOM"} ,
		{"word":"déguisements","word_nosc":"deguisements","lemma":"déguisement","pos":"NOM"} ,
		{"word":"déguisé","word_nosc":"deguise","lemma":"déguisé","pos":"NOM"} ,
		{"word":"déguisée","word_nosc":"deguisee","lemma":"déguisé","pos":"NOM"} ,
		{"word":"déguisés","word_nosc":"deguises","lemma":"déguisé","pos":"NOM"} ,
		{"word":"dégustateur","word_nosc":"degustateur","lemma":"dégustateur","pos":"NOM"} ,
		{"word":"dégustateurs","word_nosc":"degustateurs","lemma":"dégustateur","pos":"NOM"} ,
		{"word":"dégustation","word_nosc":"degustation","lemma":"dégustation","pos":"NOM"} ,
		{"word":"dégustations","word_nosc":"degustations","lemma":"dégustation","pos":"NOM"} ,
		{"word":"dégustatrice","word_nosc":"degustatrice","lemma":"dégustateur","pos":"NOM"} ,
		{"word":"dégât","word_nosc":"degat","lemma":"dégât","pos":"NOM"} ,
		{"word":"dégâts","word_nosc":"degats","lemma":"dégât","pos":"NOM"} ,
		{"word":"dégénération","word_nosc":"degeneration","lemma":"dégénération","pos":"NOM"} ,
		{"word":"dégénérescence","word_nosc":"degenerescence","lemma":"dégénérescence","pos":"NOM"} ,
		{"word":"dégénérescences","word_nosc":"degenerescences","lemma":"dégénérescence","pos":"NOM"} ,
		{"word":"dégénéré","word_nosc":"degenere","lemma":"dégénéré","pos":"NOM"} ,
		{"word":"dégénérée","word_nosc":"degeneree","lemma":"dégénéré","pos":"NOM"} ,
		{"word":"dégénérés","word_nosc":"degeneres","lemma":"dégénéré","pos":"NOM"} ,
		{"word":"déhanchement","word_nosc":"dehanchement","lemma":"déhanchement","pos":"NOM"} ,
		{"word":"déhanchements","word_nosc":"dehanchements","lemma":"déhanchement","pos":"NOM"} ,
		{"word":"déhiscence","word_nosc":"dehiscence","lemma":"déhiscence","pos":"NOM"} ,
		{"word":"déification","word_nosc":"deification","lemma":"déification","pos":"NOM"} ,
		{"word":"déisme","word_nosc":"deisme","lemma":"déisme","pos":"NOM"} ,
		{"word":"déité","word_nosc":"deite","lemma":"déité","pos":"NOM"} ,
		{"word":"déités","word_nosc":"deites","lemma":"déité","pos":"NOM"} ,
		{"word":"déjection","word_nosc":"dejection","lemma":"déjection","pos":"NOM"} ,
		{"word":"déjections","word_nosc":"dejections","lemma":"déjection","pos":"NOM"} ,
		{"word":"déjeuner","word_nosc":"dejeuner","lemma":"déjeuner","pos":"NOM"} ,
		{"word":"déjeuners","word_nosc":"dejeuners","lemma":"déjeuner","pos":"NOM"} ,
		{"word":"déjà-vu","word_nosc":"deja-vu","lemma":"déjà-vu","pos":"NOM"} ,
		{"word":"dékoulakisation","word_nosc":"dekoulakisation","lemma":"dékoulakisation","pos":"NOM"} ,
		{"word":"délabrement","word_nosc":"delabrement","lemma":"délabrement","pos":"NOM"} ,
		{"word":"délai","word_nosc":"delai","lemma":"délai","pos":"NOM"} ,
		{"word":"délais","word_nosc":"delais","lemma":"délai","pos":"NOM"} ,
		{"word":"délaissement","word_nosc":"delaissement","lemma":"délaissement","pos":"NOM"} ,
		{"word":"délaissements","word_nosc":"delaissements","lemma":"délaissement","pos":"NOM"} ,
		{"word":"délaissé","word_nosc":"delaisse","lemma":"délaissé","pos":"NOM"} ,
		{"word":"délaissée","word_nosc":"delaissee","lemma":"délaissé","pos":"NOM"} ,
		{"word":"délaissées","word_nosc":"delaissees","lemma":"délaissé","pos":"NOM"} ,
		{"word":"délaissés","word_nosc":"delaisses","lemma":"délaissé","pos":"NOM"} ,
		{"word":"délassement","word_nosc":"delassement","lemma":"délassement","pos":"NOM"} ,
		{"word":"délateur","word_nosc":"delateur","lemma":"délateur","pos":"NOM"} ,
		{"word":"délateurs","word_nosc":"delateurs","lemma":"délateur","pos":"NOM"} ,
		{"word":"délation","word_nosc":"delation","lemma":"délation","pos":"NOM"} ,
		{"word":"délations","word_nosc":"delations","lemma":"délation","pos":"NOM"} ,
		{"word":"délatrice","word_nosc":"delatrice","lemma":"délateur","pos":"NOM"} ,
		{"word":"délayage","word_nosc":"delayage","lemma":"délayage","pos":"NOM"} ,
		{"word":"délectation","word_nosc":"delectation","lemma":"délectation","pos":"NOM"} ,
		{"word":"délectations","word_nosc":"delectations","lemma":"délectation","pos":"NOM"} ,
		{"word":"délestage","word_nosc":"delestage","lemma":"délestage","pos":"NOM"} ,
		{"word":"délibération","word_nosc":"deliberation","lemma":"délibération","pos":"NOM"} ,
		{"word":"délibérations","word_nosc":"deliberations","lemma":"délibération","pos":"NOM"} ,
		{"word":"délibéré","word_nosc":"delibere","lemma":"délibéré","pos":"NOM"} ,
		{"word":"délibérés","word_nosc":"deliberes","lemma":"délibéré","pos":"NOM"} ,
		{"word":"délicat","word_nosc":"delicat","lemma":"délicat","pos":"NOM"} ,
		{"word":"délicatesse","word_nosc":"delicatesse","lemma":"délicatesse","pos":"NOM"} ,
		{"word":"délicatesses","word_nosc":"delicatesses","lemma":"délicatesse","pos":"NOM"} ,
		{"word":"délicats","word_nosc":"delicats","lemma":"délicat","pos":"NOM"} ,
		{"word":"délice","word_nosc":"delice","lemma":"délice","pos":"NOM"} ,
		{"word":"délices","word_nosc":"delices","lemma":"délice","pos":"NOM"} ,
		{"word":"délimitation","word_nosc":"delimitation","lemma":"délimitation","pos":"NOM"} ,
		{"word":"délimitations","word_nosc":"delimitations","lemma":"délimitation","pos":"NOM"} ,
		{"word":"délinquance","word_nosc":"delinquance","lemma":"délinquance","pos":"NOM"} ,
		{"word":"délinquances","word_nosc":"delinquances","lemma":"délinquance","pos":"NOM"} ,
		{"word":"délinquant","word_nosc":"delinquant","lemma":"délinquant","pos":"NOM"} ,
		{"word":"délinquante","word_nosc":"delinquante","lemma":"délinquant","pos":"NOM"} ,
		{"word":"délinquantes","word_nosc":"delinquantes","lemma":"délinquant","pos":"NOM"} ,
		{"word":"délinquants","word_nosc":"delinquants","lemma":"délinquant","pos":"NOM"} ,
		{"word":"délinéaments","word_nosc":"delineaments","lemma":"délinéament","pos":"NOM"} ,
		{"word":"déliquescence","word_nosc":"deliquescence","lemma":"déliquescence","pos":"NOM"} ,
		{"word":"délire","word_nosc":"delire","lemma":"délire","pos":"NOM"} ,
		{"word":"délires","word_nosc":"delires","lemma":"délire","pos":"NOM"} ,
		{"word":"délit","word_nosc":"delit","lemma":"délit","pos":"NOM"} ,
		{"word":"délits","word_nosc":"delits","lemma":"délit","pos":"NOM"} ,
		{"word":"délivrance","word_nosc":"delivrance","lemma":"délivrance","pos":"NOM"} ,
		{"word":"délivrances","word_nosc":"delivrances","lemma":"délivrance","pos":"NOM"} ,
		{"word":"délivre","word_nosc":"delivre","lemma":"délivre","pos":"NOM"} ,
		{"word":"délivreurs","word_nosc":"delivreurs","lemma":"délivreur","pos":"NOM"} ,
		{"word":"délié","word_nosc":"delie","lemma":"délié","pos":"NOM"} ,
		{"word":"déliés","word_nosc":"delies","lemma":"délié","pos":"NOM"} ,
		{"word":"délocalisation","word_nosc":"delocalisation","lemma":"délocalisation","pos":"NOM"} ,
		{"word":"déloyauté","word_nosc":"deloyaute","lemma":"déloyauté","pos":"NOM"} ,
		{"word":"déloyautés","word_nosc":"deloyautes","lemma":"déloyauté","pos":"NOM"} ,
		{"word":"déluge","word_nosc":"deluge","lemma":"déluge","pos":"NOM"} ,
		{"word":"déluges","word_nosc":"deluges","lemma":"déluge","pos":"NOM"} ,
		{"word":"délégation","word_nosc":"delegation","lemma":"délégation","pos":"NOM"} ,
		{"word":"délégations","word_nosc":"delegations","lemma":"délégation","pos":"NOM"} ,
		{"word":"délégué","word_nosc":"delegue","lemma":"délégué","pos":"NOM"} ,
		{"word":"délégué-général","word_nosc":"delegue-general","lemma":"délégué-général","pos":"NOM"} ,
		{"word":"déléguée","word_nosc":"deleguee","lemma":"délégué","pos":"NOM"} ,
		{"word":"délégués","word_nosc":"delegues","lemma":"délégué","pos":"NOM"} ,
		{"word":"démagogie","word_nosc":"demagogie","lemma":"démagogie","pos":"NOM"} ,
		{"word":"démagogue","word_nosc":"demagogue","lemma":"démagogue","pos":"NOM"} ,
		{"word":"démagogues","word_nosc":"demagogues","lemma":"démagogue","pos":"NOM"} ,
		{"word":"démangeaison","word_nosc":"demangeaison","lemma":"démangeaison","pos":"NOM"} ,
		{"word":"démangeaisons","word_nosc":"demangeaisons","lemma":"démangeaison","pos":"NOM"} ,
		{"word":"démantèlement","word_nosc":"demantelement","lemma":"démantèlement","pos":"NOM"} ,
		{"word":"démantèlements","word_nosc":"demantelements","lemma":"démantèlement","pos":"NOM"} ,
		{"word":"démaquillage","word_nosc":"demaquillage","lemma":"démaquillage","pos":"NOM"} ,
		{"word":"démaquillant","word_nosc":"demaquillant","lemma":"démaquillant","pos":"NOM"} ,
		{"word":"démarcation","word_nosc":"demarcation","lemma":"démarcation","pos":"NOM"} ,
		{"word":"démarchage","word_nosc":"demarchage","lemma":"démarchage","pos":"NOM"} ,
		{"word":"démarche","word_nosc":"demarche","lemma":"démarche","pos":"NOM"} ,
		{"word":"démarches","word_nosc":"demarches","lemma":"démarche","pos":"NOM"} ,
		{"word":"démarcheur","word_nosc":"demarcheur","lemma":"démarcheur","pos":"NOM"} ,
		{"word":"démarcheurs","word_nosc":"demarcheurs","lemma":"démarcheur","pos":"NOM"} ,
		{"word":"démarcheuse","word_nosc":"demarcheuse","lemma":"démarcheur","pos":"NOM"} ,
		{"word":"démarrage","word_nosc":"demarrage","lemma":"démarrage","pos":"NOM"} ,
		{"word":"démarrages","word_nosc":"demarrages","lemma":"démarrage","pos":"NOM"} ,
		{"word":"démarreur","word_nosc":"demarreur","lemma":"démarreur","pos":"NOM"} ,
		{"word":"démarreurs","word_nosc":"demarreurs","lemma":"démarreur","pos":"NOM"} ,
		{"word":"démasquage","word_nosc":"demasquage","lemma":"démasquage","pos":"NOM"} ,
		{"word":"dématérialisation","word_nosc":"dematerialisation","lemma":"dématérialisation","pos":"NOM"} ,
		{"word":"démembrement","word_nosc":"demembrement","lemma":"démembrement","pos":"NOM"} ,
		{"word":"démembrements","word_nosc":"demembrements","lemma":"démembrement","pos":"NOM"} ,
		{"word":"démence","word_nosc":"demence","lemma":"démence","pos":"NOM"} ,
		{"word":"dément","word_nosc":"dement","lemma":"dément","pos":"NOM"} ,
		{"word":"démente","word_nosc":"demente","lemma":"dément","pos":"NOM"} ,
		{"word":"démentes","word_nosc":"dementes","lemma":"dément","pos":"NOM"} ,
		{"word":"démenti","word_nosc":"dementi","lemma":"démenti","pos":"NOM"} ,
		{"word":"démentis","word_nosc":"dementis","lemma":"démenti","pos":"NOM"} ,
		{"word":"déments","word_nosc":"dements","lemma":"dément","pos":"NOM"} ,
		{"word":"démerdard","word_nosc":"demerdard","lemma":"démerdard","pos":"NOM"} ,
		{"word":"démerdards","word_nosc":"demerdards","lemma":"démerdard","pos":"NOM"} ,
		{"word":"démerde","word_nosc":"demerde","lemma":"démerde","pos":"NOM"} ,
		{"word":"démesure","word_nosc":"demesure","lemma":"démesure","pos":"NOM"} ,
		{"word":"démilitarisation","word_nosc":"demilitarisation","lemma":"démilitarisation","pos":"NOM"} ,
		{"word":"déminage","word_nosc":"deminage","lemma":"déminage","pos":"NOM"} ,
		{"word":"démineur","word_nosc":"demineur","lemma":"démineur","pos":"NOM"} ,
		{"word":"démineurs","word_nosc":"demineurs","lemma":"démineur","pos":"NOM"} ,
		{"word":"démission","word_nosc":"demission","lemma":"démission","pos":"NOM"} ,
		{"word":"démissionnaire","word_nosc":"demissionnaire","lemma":"démissionnaire","pos":"NOM"} ,
		{"word":"démissionnaires","word_nosc":"demissionnaires","lemma":"démissionnaire","pos":"NOM"} ,
		{"word":"démissions","word_nosc":"demissions","lemma":"démission","pos":"NOM"} ,
		{"word":"démiurge","word_nosc":"demiurge","lemma":"démiurge","pos":"NOM"} ,
		{"word":"démiurges","word_nosc":"demiurges","lemma":"démiurge","pos":"NOM"} ,
		{"word":"démobilisation","word_nosc":"demobilisation","lemma":"démobilisation","pos":"NOM"} ,
		{"word":"démobilisé","word_nosc":"demobilise","lemma":"démobilisé","pos":"NOM"} ,
		{"word":"démobilisés","word_nosc":"demobilises","lemma":"démobilisé","pos":"NOM"} ,
		{"word":"démocrate","word_nosc":"democrate","lemma":"démocrate","pos":"NOM"} ,
		{"word":"démocrates","word_nosc":"democrates","lemma":"démocrate","pos":"NOM"} ,
		{"word":"démocrates-chrétiens","word_nosc":"democrates-chretiens","lemma":"démocrate-chrétien","pos":"NOM"} ,
		{"word":"démocratie","word_nosc":"democratie","lemma":"démocratie","pos":"NOM"} ,
		{"word":"démocraties","word_nosc":"democraties","lemma":"démocratie","pos":"NOM"} ,
		{"word":"démocratisation","word_nosc":"democratisation","lemma":"démocratisation","pos":"NOM"} ,
		{"word":"démocratisations","word_nosc":"democratisations","lemma":"démocratisation","pos":"NOM"} ,
		{"word":"démodulateur","word_nosc":"demodulateur","lemma":"démodulateur","pos":"NOM"} ,
		{"word":"démodulateurs","word_nosc":"demodulateurs","lemma":"démodulateur","pos":"NOM"} ,
		{"word":"démographie","word_nosc":"demographie","lemma":"démographie","pos":"NOM"} ,
		{"word":"démolisseur","word_nosc":"demolisseur","lemma":"démolisseur","pos":"NOM"} ,
		{"word":"démolisseurs","word_nosc":"demolisseurs","lemma":"démolisseur","pos":"NOM"} ,
		{"word":"démolisseuse","word_nosc":"demolisseuse","lemma":"démolisseur","pos":"NOM"} ,
		{"word":"démolition","word_nosc":"demolition","lemma":"démolition","pos":"NOM"} ,
		{"word":"démolitions","word_nosc":"demolitions","lemma":"démolition","pos":"NOM"} ,
		{"word":"démon","word_nosc":"demon","lemma":"démon","pos":"NOM"} ,
		{"word":"démone","word_nosc":"demone","lemma":"démon","pos":"NOM"} ,
		{"word":"démones","word_nosc":"demones","lemma":"démon","pos":"NOM"} ,
		{"word":"démonologie","word_nosc":"demonologie","lemma":"démonologie","pos":"NOM"} ,
		{"word":"démonomanie","word_nosc":"demonomanie","lemma":"démonomanie","pos":"NOM"} ,
		{"word":"démons","word_nosc":"demons","lemma":"démon","pos":"NOM"} ,
		{"word":"démonstrateur","word_nosc":"demonstrateur","lemma":"démonstrateur","pos":"NOM"} ,
		{"word":"démonstrateurs","word_nosc":"demonstrateurs","lemma":"démonstrateur","pos":"NOM"} ,
		{"word":"démonstratif","word_nosc":"demonstratif","lemma":"démonstratif","pos":"NOM"} ,
		{"word":"démonstratifs","word_nosc":"demonstratifs","lemma":"démonstratif","pos":"NOM"} ,
		{"word":"démonstration","word_nosc":"demonstration","lemma":"démonstration","pos":"NOM"} ,
		{"word":"démonstrations","word_nosc":"demonstrations","lemma":"démonstration","pos":"NOM"} ,
		{"word":"démonstratrice","word_nosc":"demonstratrice","lemma":"démonstrateur","pos":"NOM"} ,
		{"word":"démontage","word_nosc":"demontage","lemma":"démontage","pos":"NOM"} ,
		{"word":"démontages","word_nosc":"demontages","lemma":"démontage","pos":"NOM"} ,
		{"word":"démonte-pneu","word_nosc":"demonte-pneu","lemma":"démonte-pneu","pos":"NOM"} ,
		{"word":"démonte-pneus","word_nosc":"demonte-pneus","lemma":"démonte-pneu","pos":"NOM"} ,
		{"word":"démoralisateur","word_nosc":"demoralisateur","lemma":"démoralisateur","pos":"NOM"} ,
		{"word":"démoralisateurs","word_nosc":"demoralisateurs","lemma":"démoralisateur","pos":"NOM"} ,
		{"word":"démoralisation","word_nosc":"demoralisation","lemma":"démoralisation","pos":"NOM"} ,
		{"word":"démotivation","word_nosc":"demotivation","lemma":"démotivation","pos":"NOM"} ,
		{"word":"démultiplicateur","word_nosc":"demultiplicateur","lemma":"démultiplicateur","pos":"NOM"} ,
		{"word":"démultiplication","word_nosc":"demultiplication","lemma":"démultiplication","pos":"NOM"} ,
		{"word":"démuni","word_nosc":"demuni","lemma":"démuni","pos":"NOM"} ,
		{"word":"démunie","word_nosc":"demunie","lemma":"démuni","pos":"NOM"} ,
		{"word":"démunis","word_nosc":"demunis","lemma":"démuni","pos":"NOM"} ,
		{"word":"démystificateur","word_nosc":"demystificateur","lemma":"démystificateur","pos":"NOM"} ,
		{"word":"démystification","word_nosc":"demystification","lemma":"démystification","pos":"NOM"} ,
		{"word":"démystifications","word_nosc":"demystifications","lemma":"démystification","pos":"NOM"} ,
		{"word":"déménagement","word_nosc":"demenagement","lemma":"déménagement","pos":"NOM"} ,
		{"word":"déménagements","word_nosc":"demenagements","lemma":"déménagement","pos":"NOM"} ,
		{"word":"déménageur","word_nosc":"demenageur","lemma":"déménageur","pos":"NOM"} ,
		{"word":"déménageurs","word_nosc":"demenageurs","lemma":"déménageur","pos":"NOM"} ,
		{"word":"déménageuse","word_nosc":"demenageuse","lemma":"déménageur","pos":"NOM"} ,
		{"word":"démérite","word_nosc":"demerite","lemma":"démérite","pos":"NOM"} ,
		{"word":"démérites","word_nosc":"demerites","lemma":"démérite","pos":"NOM"} ,
		{"word":"démêlage","word_nosc":"demelage","lemma":"démêlage","pos":"NOM"} ,
		{"word":"démêlant","word_nosc":"demelant","lemma":"démêlant","pos":"NOM"} ,
		{"word":"démêloir","word_nosc":"demeloir","lemma":"démêloir","pos":"NOM"} ,
		{"word":"démêlés","word_nosc":"demeles","lemma":"démêlé","pos":"NOM"} ,
		{"word":"dénatalité","word_nosc":"denatalite","lemma":"dénatalité","pos":"NOM"} ,
		{"word":"dénaturation","word_nosc":"denaturation","lemma":"dénaturation","pos":"NOM"} ,
		{"word":"dénaturé","word_nosc":"denature","lemma":"dénaturé","pos":"NOM"} ,
		{"word":"dénaturée","word_nosc":"denaturee","lemma":"dénaturé","pos":"NOM"} ,
		{"word":"dénaturées","word_nosc":"denaturees","lemma":"dénaturé","pos":"NOM"} ,
		{"word":"dénaturés","word_nosc":"denatures","lemma":"dénaturé","pos":"NOM"} ,
		{"word":"dénazification","word_nosc":"denazification","lemma":"dénazification","pos":"NOM"} ,
		{"word":"déneigement","word_nosc":"deneigement","lemma":"déneigement","pos":"NOM"} ,
		{"word":"dénervation","word_nosc":"denervation","lemma":"dénervation","pos":"NOM"} ,
		{"word":"déni","word_nosc":"deni","lemma":"déni","pos":"NOM"} ,
		{"word":"déniaisement","word_nosc":"deniaisement","lemma":"déniaisement","pos":"NOM"} ,
		{"word":"dénicheur","word_nosc":"denicheur","lemma":"dénicheur","pos":"NOM"} ,
		{"word":"dénicheurs","word_nosc":"denicheurs","lemma":"dénicheur","pos":"NOM"} ,
		{"word":"dénicheuse","word_nosc":"denicheuse","lemma":"dénicheur","pos":"NOM"} ,
		{"word":"dénigrement","word_nosc":"denigrement","lemma":"dénigrement","pos":"NOM"} ,
		{"word":"dénigrements","word_nosc":"denigrements","lemma":"dénigrement","pos":"NOM"} ,
		{"word":"dénigreuses","word_nosc":"denigreuses","lemma":"dénigreur","pos":"NOM"} ,
		{"word":"dénis","word_nosc":"denis","lemma":"déni","pos":"NOM"} ,
		{"word":"dénivellation","word_nosc":"denivellation","lemma":"dénivellation","pos":"NOM"} ,
		{"word":"dénivellations","word_nosc":"denivellations","lemma":"dénivellation","pos":"NOM"} ,
		{"word":"dénivelé","word_nosc":"denivele","lemma":"dénivelé","pos":"NOM"} ,
		{"word":"dénivelés","word_nosc":"deniveles","lemma":"dénivelé","pos":"NOM"} ,
		{"word":"dénombrement","word_nosc":"denombrement","lemma":"dénombrement","pos":"NOM"} ,
		{"word":"dénombrements","word_nosc":"denombrements","lemma":"dénombrement","pos":"NOM"} ,
		{"word":"dénominateur","word_nosc":"denominateur","lemma":"dénominateur","pos":"NOM"} ,
		{"word":"dénomination","word_nosc":"denomination","lemma":"dénomination","pos":"NOM"} ,
		{"word":"dénominations","word_nosc":"denominations","lemma":"dénomination","pos":"NOM"} ,
		{"word":"dénommé","word_nosc":"denomme","lemma":"dénommé","pos":"NOM"} ,
		{"word":"dénonciateur","word_nosc":"denonciateur","lemma":"dénonciateur","pos":"NOM"} ,
		{"word":"dénonciation","word_nosc":"denonciation","lemma":"dénonciation","pos":"NOM"} ,
		{"word":"dénonciations","word_nosc":"denonciations","lemma":"dénonciation","pos":"NOM"} ,
		{"word":"dénonciatrice","word_nosc":"denonciatrice","lemma":"dénonciateur","pos":"NOM"} ,
		{"word":"dénouement","word_nosc":"denouement","lemma":"dénouement","pos":"NOM"} ,
		{"word":"dénouements","word_nosc":"denouements","lemma":"dénouement","pos":"NOM"} ,
		{"word":"dénoyauteur","word_nosc":"denoyauteur","lemma":"dénoyauteur","pos":"NOM"} ,
		{"word":"dénudation","word_nosc":"denudation","lemma":"dénudation","pos":"NOM"} ,
		{"word":"dénuement","word_nosc":"denuement","lemma":"dénuement","pos":"NOM"} ,
		{"word":"dénégation","word_nosc":"denegation","lemma":"dénégation","pos":"NOM"} ,
		{"word":"dénégations","word_nosc":"denegations","lemma":"dénégation","pos":"NOM"} ,
		{"word":"déodorant","word_nosc":"deodorant","lemma":"déodorant","pos":"NOM"} ,
		{"word":"déodorants","word_nosc":"deodorants","lemma":"déodorant","pos":"NOM"} ,
		{"word":"déontologie","word_nosc":"deontologie","lemma":"déontologie","pos":"NOM"} ,
		{"word":"dépannage","word_nosc":"depannage","lemma":"dépannage","pos":"NOM"} ,
		{"word":"dépannages","word_nosc":"depannages","lemma":"dépannage","pos":"NOM"} ,
		{"word":"dépanneur","word_nosc":"depanneur","lemma":"dépanneur","pos":"NOM"} ,
		{"word":"dépanneurs","word_nosc":"depanneurs","lemma":"dépanneur","pos":"NOM"} ,
		{"word":"dépanneuse","word_nosc":"depanneuse","lemma":"dépanneur","pos":"NOM"} ,
		{"word":"dépanneuses","word_nosc":"depanneuses","lemma":"dépanneur","pos":"NOM"} ,
		{"word":"départ","word_nosc":"depart","lemma":"départ","pos":"NOM"} ,
		{"word":"département","word_nosc":"departement","lemma":"département","pos":"NOM"} ,
		{"word":"départementale","word_nosc":"departementale","lemma":"départementale","pos":"NOM"} ,
		{"word":"départementales","word_nosc":"departementales","lemma":"départementale","pos":"NOM"} ,
		{"word":"départements","word_nosc":"departements","lemma":"département","pos":"NOM"} ,
		{"word":"départs","word_nosc":"departs","lemma":"départ","pos":"NOM"} ,
		{"word":"dépassant","word_nosc":"depassant","lemma":"dépassant","pos":"NOM"} ,
		{"word":"dépassement","word_nosc":"depassement","lemma":"dépassement","pos":"NOM"} ,
		{"word":"dépassements","word_nosc":"depassements","lemma":"dépassement","pos":"NOM"} ,
		{"word":"dépaysement","word_nosc":"depaysement","lemma":"dépaysement","pos":"NOM"} ,
		{"word":"dépaysements","word_nosc":"depaysements","lemma":"dépaysement","pos":"NOM"} ,
		{"word":"dépeceur","word_nosc":"depeceur","lemma":"dépeceur","pos":"NOM"} ,
		{"word":"dépeceurs","word_nosc":"depeceurs","lemma":"dépeceur","pos":"NOM"} ,
		{"word":"dépenaillement","word_nosc":"depenaillement","lemma":"dépenaillement","pos":"NOM"} ,
		{"word":"dépenaillements","word_nosc":"depenaillements","lemma":"dépenaillement","pos":"NOM"} ,
		{"word":"dépenaillé","word_nosc":"depenaille","lemma":"dépenaillé","pos":"NOM"} ,
		{"word":"dépenaillée","word_nosc":"depenaillee","lemma":"dépenaillé","pos":"NOM"} ,
		{"word":"dépenaillées","word_nosc":"depenaillees","lemma":"dépenaillé","pos":"NOM"} ,
		{"word":"dépenaillés","word_nosc":"depenailles","lemma":"dépenaillé","pos":"NOM"} ,
		{"word":"dépendance","word_nosc":"dependance","lemma":"dépendance","pos":"NOM"} ,
		{"word":"dépendances","word_nosc":"dependances","lemma":"dépendance","pos":"NOM"} ,
		{"word":"dépendeur","word_nosc":"dependeur","lemma":"dépendeur","pos":"NOM"} ,
		{"word":"dépendeurs","word_nosc":"dependeurs","lemma":"dépendeur","pos":"NOM"} ,
		{"word":"dépens","word_nosc":"depens","lemma":"dépens","pos":"NOM"} ,
		{"word":"dépense","word_nosc":"depense","lemma":"dépense","pos":"NOM"} ,
		{"word":"dépenses","word_nosc":"depenses","lemma":"dépense","pos":"NOM"} ,
		{"word":"dépensier","word_nosc":"depensier","lemma":"dépensier","pos":"NOM"} ,
		{"word":"dépensiers","word_nosc":"depensiers","lemma":"dépensier","pos":"NOM"} ,
		{"word":"dépensière","word_nosc":"depensiere","lemma":"dépensier","pos":"NOM"} ,
		{"word":"déperdition","word_nosc":"deperdition","lemma":"déperdition","pos":"NOM"} ,
		{"word":"déperditions","word_nosc":"deperditions","lemma":"déperdition","pos":"NOM"} ,
		{"word":"dépersonnalisation","word_nosc":"depersonnalisation","lemma":"dépersonnalisation","pos":"NOM"} ,
		{"word":"dépeçage","word_nosc":"depecage","lemma":"dépeçage","pos":"NOM"} ,
		{"word":"déphasage","word_nosc":"dephasage","lemma":"déphasage","pos":"NOM"} ,
		{"word":"déphaseurs","word_nosc":"dephaseurs","lemma":"déphaseur","pos":"NOM"} ,
		{"word":"dépigmentation","word_nosc":"depigmentation","lemma":"dépigmentation","pos":"NOM"} ,
		{"word":"dépiquage","word_nosc":"depiquage","lemma":"dépiquage","pos":"NOM"} ,
		{"word":"dépistage","word_nosc":"depistage","lemma":"dépistage","pos":"NOM"} ,
		{"word":"dépistages","word_nosc":"depistages","lemma":"dépistage","pos":"NOM"} ,
		{"word":"dépit","word_nosc":"depit","lemma":"dépit","pos":"NOM"} ,
		{"word":"déplacement","word_nosc":"deplacement","lemma":"déplacement","pos":"NOM"} ,
		{"word":"déplacements","word_nosc":"deplacements","lemma":"déplacement","pos":"NOM"} ,
		{"word":"déplacé","word_nosc":"deplace","lemma":"déplacé","pos":"NOM"} ,
		{"word":"déplacée","word_nosc":"deplacee","lemma":"déplacé","pos":"NOM"} ,
		{"word":"déplacées","word_nosc":"deplacees","lemma":"déplacé","pos":"NOM"} ,
		{"word":"déplacés","word_nosc":"deplaces","lemma":"déplacé","pos":"NOM"} ,
		{"word":"déplaisir","word_nosc":"deplaisir","lemma":"déplaisir","pos":"NOM"} ,
		{"word":"déplaisirs","word_nosc":"deplaisirs","lemma":"déplaisir","pos":"NOM"} ,
		{"word":"déplantoir","word_nosc":"deplantoir","lemma":"déplantoir","pos":"NOM"} ,
		{"word":"dépliant","word_nosc":"depliant","lemma":"dépliant","pos":"NOM"} ,
		{"word":"dépliants","word_nosc":"depliants","lemma":"dépliant","pos":"NOM"} ,
		{"word":"dépliement","word_nosc":"depliement","lemma":"dépliement","pos":"NOM"} ,
		{"word":"déploiement","word_nosc":"deploiement","lemma":"déploiement","pos":"NOM"} ,
		{"word":"déploiements","word_nosc":"deploiements","lemma":"déploiement","pos":"NOM"} ,
		{"word":"déploration","word_nosc":"deploration","lemma":"déploration","pos":"NOM"} ,
		{"word":"dépollution","word_nosc":"depollution","lemma":"dépollution","pos":"NOM"} ,
		{"word":"dépopulation","word_nosc":"depopulation","lemma":"dépopulation","pos":"NOM"} ,
		{"word":"déport","word_nosc":"deport","lemma":"déport","pos":"NOM"} ,
		{"word":"déportation","word_nosc":"deportation","lemma":"déportation","pos":"NOM"} ,
		{"word":"déportations","word_nosc":"deportations","lemma":"déportation","pos":"NOM"} ,
		{"word":"déportements","word_nosc":"deportements","lemma":"déportement","pos":"NOM"} ,
		{"word":"déporté","word_nosc":"deporte","lemma":"déporté","pos":"NOM"} ,
		{"word":"déportée","word_nosc":"deportee","lemma":"déporté","pos":"NOM"} ,
		{"word":"déportés","word_nosc":"deportes","lemma":"déporté","pos":"NOM"} ,
		{"word":"déposant","word_nosc":"deposant","lemma":"déposant","pos":"NOM"} ,
		{"word":"dépose","word_nosc":"depose","lemma":"dépose","pos":"NOM"} ,
		{"word":"déposes","word_nosc":"deposes","lemma":"dépose","pos":"NOM"} ,
		{"word":"dépositaire","word_nosc":"depositaire","lemma":"dépositaire","pos":"NOM"} ,
		{"word":"dépositaires","word_nosc":"depositaires","lemma":"dépositaire","pos":"NOM"} ,
		{"word":"déposition","word_nosc":"deposition","lemma":"déposition","pos":"NOM"} ,
		{"word":"dépositions","word_nosc":"depositions","lemma":"déposition","pos":"NOM"} ,
		{"word":"dépossession","word_nosc":"depossession","lemma":"dépossession","pos":"NOM"} ,
		{"word":"dépotoir","word_nosc":"depotoir","lemma":"dépotoir","pos":"NOM"} ,
		{"word":"dépouillage","word_nosc":"depouillage","lemma":"dépouillage","pos":"NOM"} ,
		{"word":"dépouille","word_nosc":"depouille","lemma":"dépouille","pos":"NOM"} ,
		{"word":"dépouillement","word_nosc":"depouillement","lemma":"dépouillement","pos":"NOM"} ,
		{"word":"dépouillements","word_nosc":"depouillements","lemma":"dépouillement","pos":"NOM"} ,
		{"word":"dépouilles","word_nosc":"depouilles","lemma":"dépouille","pos":"NOM"} ,
		{"word":"dépoussiérage","word_nosc":"depoussierage","lemma":"dépoussiérage","pos":"NOM"} ,
		{"word":"dépravation","word_nosc":"depravation","lemma":"dépravation","pos":"NOM"} ,
		{"word":"dépravations","word_nosc":"depravations","lemma":"dépravation","pos":"NOM"} ,
		{"word":"dépravé","word_nosc":"deprave","lemma":"dépravé","pos":"NOM"} ,
		{"word":"dépravée","word_nosc":"depravee","lemma":"dépravé","pos":"NOM"} ,
		{"word":"dépravés","word_nosc":"depraves","lemma":"dépravé","pos":"NOM"} ,
		{"word":"dépressif","word_nosc":"depressif","lemma":"dépressif","pos":"NOM"} ,
		{"word":"dépressifs","word_nosc":"depressifs","lemma":"dépressif","pos":"NOM"} ,
		{"word":"dépression","word_nosc":"depression","lemma":"dépression","pos":"NOM"} ,
		{"word":"dépressions","word_nosc":"depressions","lemma":"dépression","pos":"NOM"} ,
		{"word":"dépressurisation","word_nosc":"depressurisation","lemma":"dépressurisation","pos":"NOM"} ,
		{"word":"déprime","word_nosc":"deprime","lemma":"déprime","pos":"NOM"} ,
		{"word":"déprimes","word_nosc":"deprimes","lemma":"déprime","pos":"NOM"} ,
		{"word":"déprimé","word_nosc":"deprime","lemma":"déprimé","pos":"NOM"} ,
		{"word":"déprimée","word_nosc":"deprimee","lemma":"déprimé","pos":"NOM"} ,
		{"word":"déprimées","word_nosc":"deprimees","lemma":"déprimé","pos":"NOM"} ,
		{"word":"déprimés","word_nosc":"deprimes","lemma":"déprimé","pos":"NOM"} ,
		{"word":"déprogrammation","word_nosc":"deprogrammation","lemma":"déprogrammation","pos":"NOM"} ,
		{"word":"dépréciation","word_nosc":"depreciation","lemma":"dépréciation","pos":"NOM"} ,
		{"word":"déprédateurs","word_nosc":"depredateurs","lemma":"déprédateur","pos":"NOM"} ,
		{"word":"déprédation","word_nosc":"depredation","lemma":"déprédation","pos":"NOM"} ,
		{"word":"déprédations","word_nosc":"depredations","lemma":"déprédation","pos":"NOM"} ,
		{"word":"dépucelage","word_nosc":"depucelage","lemma":"dépucelage","pos":"NOM"} ,
		{"word":"dépucelages","word_nosc":"depucelages","lemma":"dépucelage","pos":"NOM"} ,
		{"word":"dépuceleur","word_nosc":"depuceleur","lemma":"dépuceleur","pos":"NOM"} ,
		{"word":"dépuceleurs","word_nosc":"depuceleurs","lemma":"dépuceleur","pos":"NOM"} ,
		{"word":"dépuratifs","word_nosc":"depuratifs","lemma":"dépuratif","pos":"NOM"} ,
		{"word":"députation","word_nosc":"deputation","lemma":"députation","pos":"NOM"} ,
		{"word":"députations","word_nosc":"deputations","lemma":"députation","pos":"NOM"} ,
		{"word":"député","word_nosc":"depute","lemma":"député","pos":"NOM"} ,
		{"word":"député-maire","word_nosc":"depute-maire","lemma":"député-maire","pos":"NOM"} ,
		{"word":"députée","word_nosc":"deputee","lemma":"député","pos":"NOM"} ,
		{"word":"députés","word_nosc":"deputes","lemma":"député","pos":"NOM"} ,
		{"word":"dépècements","word_nosc":"depecements","lemma":"dépècement","pos":"NOM"} ,
		{"word":"dépérissement","word_nosc":"deperissement","lemma":"dépérissement","pos":"NOM"} ,
		{"word":"dépêche","word_nosc":"depeche","lemma":"dépêche","pos":"NOM"} ,
		{"word":"dépêches","word_nosc":"depeches","lemma":"dépêche","pos":"NOM"} ,
		{"word":"dépôt","word_nosc":"depot","lemma":"dépôt","pos":"NOM"} ,
		{"word":"dépôt-vente","word_nosc":"depot-vente","lemma":"dépôt-vente","pos":"NOM"} ,
		{"word":"dépôts","word_nosc":"depots","lemma":"dépôt","pos":"NOM"} ,
		{"word":"déracinement","word_nosc":"deracinement","lemma":"déracinement","pos":"NOM"} ,
		{"word":"déraciné","word_nosc":"deracine","lemma":"déraciné","pos":"NOM"} ,
		{"word":"déracinée","word_nosc":"deracinee","lemma":"déraciné","pos":"NOM"} ,
		{"word":"déracinées","word_nosc":"deracinees","lemma":"déraciné","pos":"NOM"} ,
		{"word":"déracinés","word_nosc":"deracines","lemma":"déraciné","pos":"NOM"} ,
		{"word":"déraillement","word_nosc":"deraillement","lemma":"déraillement","pos":"NOM"} ,
		{"word":"déraillements","word_nosc":"deraillements","lemma":"déraillement","pos":"NOM"} ,
		{"word":"dérailleur","word_nosc":"derailleur","lemma":"dérailleur","pos":"NOM"} ,
		{"word":"déraison","word_nosc":"deraison","lemma":"déraison","pos":"NOM"} ,
		{"word":"déraisons","word_nosc":"deraisons","lemma":"déraison","pos":"NOM"} ,
		{"word":"dérangement","word_nosc":"derangement","lemma":"dérangement","pos":"NOM"} ,
		{"word":"dérangements","word_nosc":"derangements","lemma":"dérangement","pos":"NOM"} ,
		{"word":"dérangé","word_nosc":"derange","lemma":"dérangé","pos":"NOM"} ,
		{"word":"dérangés","word_nosc":"deranges","lemma":"dérangé","pos":"NOM"} ,
		{"word":"dérapage","word_nosc":"derapage","lemma":"dérapage","pos":"NOM"} ,
		{"word":"dérapages","word_nosc":"derapages","lemma":"dérapage","pos":"NOM"} ,
		{"word":"dératisation","word_nosc":"deratisation","lemma":"dératisation","pos":"NOM"} ,
		{"word":"dératisations","word_nosc":"deratisations","lemma":"dératisation","pos":"NOM"} ,
		{"word":"dératé","word_nosc":"derate","lemma":"dératé","pos":"NOM"} ,
		{"word":"dératées","word_nosc":"deratees","lemma":"dératé","pos":"NOM"} ,
		{"word":"dératés","word_nosc":"derates","lemma":"dératé","pos":"NOM"} ,
		{"word":"déresponsabilisation","word_nosc":"deresponsabilisation","lemma":"déresponsabilisation","pos":"NOM"} ,
		{"word":"dérision","word_nosc":"derision","lemma":"dérision","pos":"NOM"} ,
		{"word":"dérivatif","word_nosc":"derivatif","lemma":"dérivatif","pos":"NOM"} ,
		{"word":"dérivatifs","word_nosc":"derivatifs","lemma":"dérivatif","pos":"NOM"} ,
		{"word":"dérivation","word_nosc":"derivation","lemma":"dérivation","pos":"NOM"} ,
		{"word":"dérivations","word_nosc":"derivations","lemma":"dérivation","pos":"NOM"} ,
		{"word":"dérive","word_nosc":"derive","lemma":"dérive","pos":"NOM"} ,
		{"word":"dérives","word_nosc":"derives","lemma":"dérive","pos":"NOM"} ,
		{"word":"dériveur","word_nosc":"deriveur","lemma":"dériveur","pos":"NOM"} ,
		{"word":"dériveurs","word_nosc":"deriveurs","lemma":"dériveur","pos":"NOM"} ,
		{"word":"dérivé","word_nosc":"derive","lemma":"dérivé","pos":"NOM"} ,
		{"word":"dérivée","word_nosc":"derivee","lemma":"dérivé","pos":"NOM"} ,
		{"word":"dérivés","word_nosc":"derives","lemma":"dérivé","pos":"NOM"} ,
		{"word":"dérobade","word_nosc":"derobade","lemma":"dérobade","pos":"NOM"} ,
		{"word":"dérobades","word_nosc":"derobades","lemma":"dérobade","pos":"NOM"} ,
		{"word":"dérogation","word_nosc":"derogation","lemma":"dérogation","pos":"NOM"} ,
		{"word":"dérogations","word_nosc":"derogations","lemma":"dérogation","pos":"NOM"} ,
		{"word":"dérouillement","word_nosc":"derouillement","lemma":"dérouillement","pos":"NOM"} ,
		{"word":"dérouillée","word_nosc":"derouillee","lemma":"dérouillée","pos":"NOM"} ,
		{"word":"dérouillées","word_nosc":"derouillees","lemma":"dérouillée","pos":"NOM"} ,
		{"word":"déroulement","word_nosc":"deroulement","lemma":"déroulement","pos":"NOM"} ,
		{"word":"déroulements","word_nosc":"deroulements","lemma":"déroulement","pos":"NOM"} ,
		{"word":"dérouleur","word_nosc":"derouleur","lemma":"dérouleur","pos":"NOM"} ,
		{"word":"dérouleurs","word_nosc":"derouleurs","lemma":"dérouleur","pos":"NOM"} ,
		{"word":"dérouleuse","word_nosc":"derouleuse","lemma":"dérouleur","pos":"NOM"} ,
		{"word":"déroutage","word_nosc":"deroutage","lemma":"déroutage","pos":"NOM"} ,
		{"word":"déroute","word_nosc":"deroute","lemma":"déroute","pos":"NOM"} ,
		{"word":"déroutement","word_nosc":"deroutement","lemma":"déroutement","pos":"NOM"} ,
		{"word":"déroutes","word_nosc":"deroutes","lemma":"déroute","pos":"NOM"} ,
		{"word":"dérèglement","word_nosc":"dereglement","lemma":"dérèglement","pos":"NOM"} ,
		{"word":"dérèglements","word_nosc":"dereglements","lemma":"dérèglement","pos":"NOM"} ,
		{"word":"déréglage","word_nosc":"dereglage","lemma":"déréglage","pos":"NOM"} ,
		{"word":"déréglementation","word_nosc":"dereglementation","lemma":"déréglementation","pos":"NOM"} ,
		{"word":"dérégulation","word_nosc":"deregulation","lemma":"dérégulation","pos":"NOM"} ,
		{"word":"déréliction","word_nosc":"dereliction","lemma":"déréliction","pos":"NOM"} ,
		{"word":"dés","word_nosc":"des","lemma":"dé","pos":"NOM"} ,
		{"word":"désabonnements","word_nosc":"desabonnements","lemma":"désabonnement","pos":"NOM"} ,
		{"word":"désabusement","word_nosc":"desabusement","lemma":"désabusement","pos":"NOM"} ,
		{"word":"désabusé","word_nosc":"desabuse","lemma":"désabusé","pos":"NOM"} ,
		{"word":"désabusée","word_nosc":"desabusee","lemma":"désabusé","pos":"NOM"} ,
		{"word":"désabusées","word_nosc":"desabusees","lemma":"désabusé","pos":"NOM"} ,
		{"word":"désabusés","word_nosc":"desabuses","lemma":"désabusé","pos":"NOM"} ,
		{"word":"désaccord","word_nosc":"desaccord","lemma":"désaccord","pos":"NOM"} ,
		{"word":"désaccords","word_nosc":"desaccords","lemma":"désaccord","pos":"NOM"} ,
		{"word":"désacralisation","word_nosc":"desacralisation","lemma":"désacralisation","pos":"NOM"} ,
		{"word":"désactivation","word_nosc":"desactivation","lemma":"désactivation","pos":"NOM"} ,
		{"word":"désaffectation","word_nosc":"desaffectation","lemma":"désaffectation","pos":"NOM"} ,
		{"word":"désaffection","word_nosc":"desaffection","lemma":"désaffection","pos":"NOM"} ,
		{"word":"désagrégation","word_nosc":"desagregation","lemma":"désagrégation","pos":"NOM"} ,
		{"word":"désagrément","word_nosc":"desagrement","lemma":"désagrément","pos":"NOM"} ,
		{"word":"désagréments","word_nosc":"desagrements","lemma":"désagrément","pos":"NOM"} ,
		{"word":"désalinisation","word_nosc":"desalinisation","lemma":"désalinisation","pos":"NOM"} ,
		{"word":"désaliénation","word_nosc":"desalienation","lemma":"désaliénation","pos":"NOM"} ,
		{"word":"désamiantage","word_nosc":"desamiantage","lemma":"désamiantage","pos":"NOM"} ,
		{"word":"désaminase","word_nosc":"desaminase","lemma":"désaminase","pos":"NOM"} ,
		{"word":"désamorçage","word_nosc":"desamorcage","lemma":"désamorçage","pos":"NOM"} ,
		{"word":"désamour","word_nosc":"desamour","lemma":"désamour","pos":"NOM"} ,
		{"word":"désappointement","word_nosc":"desappointement","lemma":"désappointement","pos":"NOM"} ,
		{"word":"désappointements","word_nosc":"desappointements","lemma":"désappointement","pos":"NOM"} ,
		{"word":"désappointé","word_nosc":"desappointe","lemma":"désappointé","pos":"NOM"} ,
		{"word":"désappointée","word_nosc":"desappointee","lemma":"désappointé","pos":"NOM"} ,
		{"word":"désappointés","word_nosc":"desappointes","lemma":"désappointé","pos":"NOM"} ,
		{"word":"désapprobation","word_nosc":"desapprobation","lemma":"désapprobation","pos":"NOM"} ,
		{"word":"désargentés","word_nosc":"desargentes","lemma":"désargenté","pos":"NOM"} ,
		{"word":"désarmement","word_nosc":"desarmement","lemma":"désarmement","pos":"NOM"} ,
		{"word":"désarrimage","word_nosc":"desarrimage","lemma":"désarrimage","pos":"NOM"} ,
		{"word":"désarroi","word_nosc":"desarroi","lemma":"désarroi","pos":"NOM"} ,
		{"word":"désarrois","word_nosc":"desarrois","lemma":"désarroi","pos":"NOM"} ,
		{"word":"désarticulation","word_nosc":"desarticulation","lemma":"désarticulation","pos":"NOM"} ,
		{"word":"désastre","word_nosc":"desastre","lemma":"désastre","pos":"NOM"} ,
		{"word":"désastres","word_nosc":"desastres","lemma":"désastre","pos":"NOM"} ,
		{"word":"désavantage","word_nosc":"desavantage","lemma":"désavantage","pos":"NOM"} ,
		{"word":"désavantages","word_nosc":"desavantages","lemma":"désavantage","pos":"NOM"} ,
		{"word":"désaveu","word_nosc":"desaveu","lemma":"désaveu","pos":"NOM"} ,
		{"word":"désaveux","word_nosc":"desaveux","lemma":"désaveux","pos":"NOM"} ,
		{"word":"désaxement","word_nosc":"desaxement","lemma":"désaxement","pos":"NOM"} ,
		{"word":"désaxé","word_nosc":"desaxe","lemma":"désaxé","pos":"NOM"} ,
		{"word":"désaxées","word_nosc":"desaxees","lemma":"désaxé","pos":"NOM"} ,
		{"word":"désaxés","word_nosc":"desaxes","lemma":"désaxé","pos":"NOM"} ,
		{"word":"désemparé","word_nosc":"desempare","lemma":"désemparé","pos":"NOM"} ,
		{"word":"désemparée","word_nosc":"desemparee","lemma":"désemparé","pos":"NOM"} ,
		{"word":"désemparés","word_nosc":"desempares","lemma":"désemparé","pos":"NOM"} ,
		{"word":"désenchantement","word_nosc":"desenchantement","lemma":"désenchantement","pos":"NOM"} ,
		{"word":"désenchantements","word_nosc":"desenchantements","lemma":"désenchantement","pos":"NOM"} ,
		{"word":"désenchanté","word_nosc":"desenchante","lemma":"désenchanté","pos":"NOM"} ,
		{"word":"désenchantée","word_nosc":"desenchantee","lemma":"désenchanté","pos":"NOM"} ,
		{"word":"désenchantés","word_nosc":"desenchantes","lemma":"désenchanté","pos":"NOM"} ,
		{"word":"désenchaînement","word_nosc":"desenchainement","lemma":"désenchaînement","pos":"NOM"} ,
		{"word":"désenclavement","word_nosc":"desenclavement","lemma":"désenclavement","pos":"NOM"} ,
		{"word":"désengagement","word_nosc":"desengagement","lemma":"désengagement","pos":"NOM"} ,
		{"word":"désensibilisation","word_nosc":"desensibilisation","lemma":"désensibilisation","pos":"NOM"} ,
		{"word":"désenvoûteurs","word_nosc":"desenvouteurs","lemma":"désenvoûteur","pos":"NOM"} ,
		{"word":"désert","word_nosc":"desert","lemma":"désert","pos":"NOM"} ,
		{"word":"déserteur","word_nosc":"deserteur","lemma":"déserteur","pos":"NOM"} ,
		{"word":"déserteurs","word_nosc":"deserteurs","lemma":"déserteur","pos":"NOM"} ,
		{"word":"désertion","word_nosc":"desertion","lemma":"désertion","pos":"NOM"} ,
		{"word":"désertions","word_nosc":"desertions","lemma":"désertion","pos":"NOM"} ,
		{"word":"déserts","word_nosc":"deserts","lemma":"désert","pos":"NOM"} ,
		{"word":"désespoir","word_nosc":"desespoir","lemma":"désespoir","pos":"NOM"} ,
		{"word":"désespoirs","word_nosc":"desespoirs","lemma":"désespoir","pos":"NOM"} ,
		{"word":"désespérance","word_nosc":"desesperance","lemma":"désespérance","pos":"NOM"} ,
		{"word":"désespéré","word_nosc":"desespere","lemma":"désespéré","pos":"NOM"} ,
		{"word":"désespérée","word_nosc":"desesperee","lemma":"désespéré","pos":"NOM"} ,
		{"word":"désespérées","word_nosc":"desesperees","lemma":"désespéré","pos":"NOM"} ,
		{"word":"désespérés","word_nosc":"desesperes","lemma":"désespéré","pos":"NOM"} ,
		{"word":"déshabillage","word_nosc":"deshabillage","lemma":"déshabillage","pos":"NOM"} ,
		{"word":"déshabillage-éclair","word_nosc":"deshabillage-eclair","lemma":"déshabillage-éclair","pos":"NOM"} ,
		{"word":"déshabillages","word_nosc":"deshabillages","lemma":"déshabillage","pos":"NOM"} ,
		{"word":"déshabillé","word_nosc":"deshabille","lemma":"déshabillé","pos":"NOM"} ,
		{"word":"déshabillés","word_nosc":"deshabilles","lemma":"déshabillé","pos":"NOM"} ,
		{"word":"désharmonie","word_nosc":"desharmonie","lemma":"désharmonie","pos":"NOM"} ,
		{"word":"désherbage","word_nosc":"desherbage","lemma":"désherbage","pos":"NOM"} ,
		{"word":"désherbant","word_nosc":"desherbant","lemma":"désherbant","pos":"NOM"} ,
		{"word":"désherbants","word_nosc":"desherbants","lemma":"désherbant","pos":"NOM"} ,
		{"word":"déshonneur","word_nosc":"deshonneur","lemma":"déshonneur","pos":"NOM"} ,
		{"word":"déshumanisation","word_nosc":"deshumanisation","lemma":"déshumanisation","pos":"NOM"} ,
		{"word":"déshumidification","word_nosc":"deshumidification","lemma":"déshumidification","pos":"NOM"} ,
		{"word":"déshydratation","word_nosc":"deshydratation","lemma":"déshydratation","pos":"NOM"} ,
		{"word":"déshérence","word_nosc":"desherence","lemma":"déshérence","pos":"NOM"} ,
		{"word":"déshéritée","word_nosc":"desheritee","lemma":"déshérité","pos":"NOM"} ,
		{"word":"déshéritées","word_nosc":"desheritees","lemma":"déshérité","pos":"NOM"} ,
		{"word":"déshérités","word_nosc":"desherites","lemma":"déshérité","pos":"NOM"} ,
		{"word":"désignation","word_nosc":"designation","lemma":"désignation","pos":"NOM"} ,
		{"word":"désignations","word_nosc":"designations","lemma":"désignation","pos":"NOM"} ,
		{"word":"désillusion","word_nosc":"desillusion","lemma":"désillusion","pos":"NOM"} ,
		{"word":"désillusionnement","word_nosc":"desillusionnement","lemma":"désillusionnement","pos":"NOM"} ,
		{"word":"désillusions","word_nosc":"desillusions","lemma":"désillusion","pos":"NOM"} ,
		{"word":"désincarcération","word_nosc":"desincarceration","lemma":"désincarcération","pos":"NOM"} ,
		{"word":"désincarnation","word_nosc":"desincarnation","lemma":"désincarnation","pos":"NOM"} ,
		{"word":"désincarné","word_nosc":"desincarne","lemma":"désincarné","pos":"NOM"} ,
		{"word":"désincarnée","word_nosc":"desincarnee","lemma":"désincarné","pos":"NOM"} ,
		{"word":"désincarnés","word_nosc":"desincarnes","lemma":"désincarné","pos":"NOM"} ,
		{"word":"désindividualisation","word_nosc":"desindividualisation","lemma":"désindividualisation","pos":"NOM"} ,
		{"word":"désinence","word_nosc":"desinence","lemma":"désinence","pos":"NOM"} ,
		{"word":"désinences","word_nosc":"desinences","lemma":"désinence","pos":"NOM"} ,
		{"word":"désinfectant","word_nosc":"desinfectant","lemma":"désinfectant","pos":"NOM"} ,
		{"word":"désinfectants","word_nosc":"desinfectants","lemma":"désinfectant","pos":"NOM"} ,
		{"word":"désinfection","word_nosc":"desinfection","lemma":"désinfection","pos":"NOM"} ,
		{"word":"désinfections","word_nosc":"desinfections","lemma":"désinfection","pos":"NOM"} ,
		{"word":"désinformation","word_nosc":"desinformation","lemma":"désinformation","pos":"NOM"} ,
		{"word":"désinformations","word_nosc":"desinformations","lemma":"désinformation","pos":"NOM"} ,
		{"word":"désinsectisation","word_nosc":"desinsectisation","lemma":"désinsectisation","pos":"NOM"} ,
		{"word":"désintoxication","word_nosc":"desintoxication","lemma":"désintoxication","pos":"NOM"} ,
		{"word":"désintégrateur","word_nosc":"desintegrateur","lemma":"désintégrateur","pos":"NOM"} ,
		{"word":"désintégration","word_nosc":"desintegration","lemma":"désintégration","pos":"NOM"} ,
		{"word":"désintégrations","word_nosc":"desintegrations","lemma":"désintégration","pos":"NOM"} ,
		{"word":"désintéressement","word_nosc":"desinteressement","lemma":"désintéressement","pos":"NOM"} ,
		{"word":"désintérêt","word_nosc":"desinteret","lemma":"désintérêt","pos":"NOM"} ,
		{"word":"désinvolture","word_nosc":"desinvolture","lemma":"désinvolture","pos":"NOM"} ,
		{"word":"désinvoltures","word_nosc":"desinvoltures","lemma":"désinvolture","pos":"NOM"} ,
		{"word":"désir","word_nosc":"desir","lemma":"désir","pos":"NOM"} ,
		{"word":"désirabilité","word_nosc":"desirabilite","lemma":"désirabilité","pos":"NOM"} ,
		{"word":"désirs","word_nosc":"desirs","lemma":"désir","pos":"NOM"} ,
		{"word":"désiré","word_nosc":"desire","lemma":"désiré","pos":"NOM"} ,
		{"word":"désirés","word_nosc":"desires","lemma":"désiré","pos":"NOM"} ,
		{"word":"désistement","word_nosc":"desistement","lemma":"désistement","pos":"NOM"} ,
		{"word":"désobligeance","word_nosc":"desobligeance","lemma":"désobligeance","pos":"NOM"} ,
		{"word":"désobéissance","word_nosc":"desobeissance","lemma":"désobéissance","pos":"NOM"} ,
		{"word":"désobéissances","word_nosc":"desobeissances","lemma":"désobéissance","pos":"NOM"} ,
		{"word":"désobéissante","word_nosc":"desobeissante","lemma":"désobéissant","pos":"NOM"} ,
		{"word":"désodorisant","word_nosc":"desodorisant","lemma":"désodorisant","pos":"NOM"} ,
		{"word":"désodorisants","word_nosc":"desodorisants","lemma":"désodorisant","pos":"NOM"} ,
		{"word":"désoeuvre","word_nosc":"desoeuvre","lemma":"désoeuvre","pos":"NOM"} ,
		{"word":"désoeuvrement","word_nosc":"desoeuvrement","lemma":"désoeuvrement","pos":"NOM"} ,
		{"word":"désoeuvrements","word_nosc":"desoeuvrements","lemma":"désoeuvrement","pos":"NOM"} ,
		{"word":"désoeuvré","word_nosc":"desoeuvre","lemma":"désoeuvré","pos":"NOM"} ,
		{"word":"désoeuvrée","word_nosc":"desoeuvree","lemma":"désoeuvré","pos":"NOM"} ,
		{"word":"désoeuvrées","word_nosc":"desoeuvrees","lemma":"désoeuvré","pos":"NOM"} ,
		{"word":"désoeuvrés","word_nosc":"desoeuvres","lemma":"désoeuvré","pos":"NOM"} ,
		{"word":"désolation","word_nosc":"desolation","lemma":"désolation","pos":"NOM"} ,
		{"word":"désolations","word_nosc":"desolations","lemma":"désolation","pos":"NOM"} ,
		{"word":"désorbitation","word_nosc":"desorbitation","lemma":"désorbitation","pos":"NOM"} ,
		{"word":"désordonné","word_nosc":"desordonne","lemma":"désordonné","pos":"NOM"} ,
		{"word":"désordonnée","word_nosc":"desordonnee","lemma":"désordonné","pos":"NOM"} ,
		{"word":"désordonnées","word_nosc":"desordonnees","lemma":"désordonné","pos":"NOM"} ,
		{"word":"désordonnés","word_nosc":"desordonnes","lemma":"désordonné","pos":"NOM"} ,
		{"word":"désordre","word_nosc":"desordre","lemma":"désordre","pos":"NOM"} ,
		{"word":"désordres","word_nosc":"desordres","lemma":"désordre","pos":"NOM"} ,
		{"word":"désorganisateur","word_nosc":"desorganisateur","lemma":"désorganisateur","pos":"NOM"} ,
		{"word":"désorganisation","word_nosc":"desorganisation","lemma":"désorganisation","pos":"NOM"} ,
		{"word":"désorientation","word_nosc":"desorientation","lemma":"désorientation","pos":"NOM"} ,
		{"word":"désossage","word_nosc":"desossage","lemma":"désossage","pos":"NOM"} ,
		{"word":"désossement","word_nosc":"desossement","lemma":"désossement","pos":"NOM"} ,
		{"word":"désosseur","word_nosc":"desosseur","lemma":"désosseur","pos":"NOM"} ,
		{"word":"désoxygénation","word_nosc":"desoxygenation","lemma":"désoxygénation","pos":"NOM"} ,
		{"word":"déspiritualisation","word_nosc":"despiritualisation","lemma":"déspiritualisation","pos":"NOM"} ,
		{"word":"déstabilisation","word_nosc":"destabilisation","lemma":"déstabilisation","pos":"NOM"} ,
		{"word":"déstalinisation","word_nosc":"destalinisation","lemma":"déstalinisation","pos":"NOM"} ,
		{"word":"déstockage","word_nosc":"destockage","lemma":"déstockage","pos":"NOM"} ,
		{"word":"déstructuration","word_nosc":"destructuration","lemma":"déstructuration","pos":"NOM"} ,
		{"word":"désunion","word_nosc":"desunion","lemma":"désunion","pos":"NOM"} ,
		{"word":"désuétude","word_nosc":"desuetude","lemma":"désuétude","pos":"NOM"} ,
		{"word":"désynchronisation","word_nosc":"desynchronisation","lemma":"désynchronisation","pos":"NOM"} ,
		{"word":"déségrégation","word_nosc":"desegregation","lemma":"déségrégation","pos":"NOM"} ,
		{"word":"déséquilibre","word_nosc":"desequilibre","lemma":"déséquilibre","pos":"NOM"} ,
		{"word":"déséquilibres","word_nosc":"desequilibres","lemma":"déséquilibre","pos":"NOM"} ,
		{"word":"déséquilibré","word_nosc":"desequilibre","lemma":"déséquilibré","pos":"NOM"} ,
		{"word":"déséquilibrée","word_nosc":"desequilibree","lemma":"déséquilibré","pos":"NOM"} ,
		{"word":"déséquilibrées","word_nosc":"desequilibrees","lemma":"déséquilibré","pos":"NOM"} ,
		{"word":"déséquilibrés","word_nosc":"desequilibres","lemma":"déséquilibré","pos":"NOM"} ,
		{"word":"détachant","word_nosc":"detachant","lemma":"détachant","pos":"NOM"} ,
		{"word":"détachants","word_nosc":"detachants","lemma":"détachant","pos":"NOM"} ,
		{"word":"détachement","word_nosc":"detachement","lemma":"détachement","pos":"NOM"} ,
		{"word":"détachements","word_nosc":"detachements","lemma":"détachement","pos":"NOM"} ,
		{"word":"détacheur","word_nosc":"detacheur","lemma":"détacheur","pos":"NOM"} ,
		{"word":"détaché","word_nosc":"detache","lemma":"détaché","pos":"NOM"} ,
		{"word":"détachée","word_nosc":"detachee","lemma":"détaché","pos":"NOM"} ,
		{"word":"détachées","word_nosc":"detachees","lemma":"détaché","pos":"NOM"} ,
		{"word":"détachés","word_nosc":"detaches","lemma":"détaché","pos":"NOM"} ,
		{"word":"détail","word_nosc":"detail","lemma":"détail","pos":"NOM"} ,
		{"word":"détaillant","word_nosc":"detaillant","lemma":"détaillant","pos":"NOM"} ,
		{"word":"détaillants","word_nosc":"detaillants","lemma":"détaillant","pos":"NOM"} ,
		{"word":"détails","word_nosc":"details","lemma":"détail","pos":"NOM"} ,
		{"word":"détartrage","word_nosc":"detartrage","lemma":"détartrage","pos":"NOM"} ,
		{"word":"détartrant","word_nosc":"detartrant","lemma":"détartrant","pos":"NOM"} ,
		{"word":"détaxe","word_nosc":"detaxe","lemma":"détaxe","pos":"NOM"} ,
		{"word":"détaxes","word_nosc":"detaxes","lemma":"détaxe","pos":"NOM"} ,
		{"word":"détecteur","word_nosc":"detecteur","lemma":"détecteur","pos":"NOM"} ,
		{"word":"détecteurs","word_nosc":"detecteurs","lemma":"détecteur","pos":"NOM"} ,
		{"word":"détection","word_nosc":"detection","lemma":"détection","pos":"NOM"} ,
		{"word":"détections","word_nosc":"detections","lemma":"détection","pos":"NOM"} ,
		{"word":"détective","word_nosc":"detective","lemma":"détective","pos":"NOM"} ,
		{"word":"détective-conseil","word_nosc":"detective-conseil","lemma":"détective-conseil","pos":"NOM"} ,
		{"word":"détectives","word_nosc":"detectives","lemma":"détective","pos":"NOM"} ,
		{"word":"détectives-conseils","word_nosc":"detectives-conseils","lemma":"détective-conseil","pos":"NOM"} ,
		{"word":"dételage","word_nosc":"detelage","lemma":"dételage","pos":"NOM"} ,
		{"word":"détendeur","word_nosc":"detendeur","lemma":"détendeur","pos":"NOM"} ,
		{"word":"détente","word_nosc":"detente","lemma":"détente","pos":"NOM"} ,
		{"word":"détentes","word_nosc":"detentes","lemma":"détente","pos":"NOM"} ,
		{"word":"détenteur","word_nosc":"detenteur","lemma":"détenteur","pos":"NOM"} ,
		{"word":"détenteurs","word_nosc":"detenteurs","lemma":"détenteur","pos":"NOM"} ,
		{"word":"détention","word_nosc":"detention","lemma":"détention","pos":"NOM"} ,
		{"word":"détentions","word_nosc":"detentions","lemma":"détention","pos":"NOM"} ,
		{"word":"détentrice","word_nosc":"detentrice","lemma":"détenteur","pos":"NOM"} ,
		{"word":"détenu","word_nosc":"detenu","lemma":"détenu","pos":"NOM"} ,
		{"word":"détenue","word_nosc":"detenue","lemma":"détenu","pos":"NOM"} ,
		{"word":"détenues","word_nosc":"detenues","lemma":"détenu","pos":"NOM"} ,
		{"word":"détenus","word_nosc":"detenus","lemma":"détenu","pos":"NOM"} ,
		{"word":"détergent","word_nosc":"detergent","lemma":"détergent","pos":"NOM"} ,
		{"word":"détergents","word_nosc":"detergents","lemma":"détergent","pos":"NOM"} ,
		{"word":"déterminant","word_nosc":"determinant","lemma":"déterminant","pos":"NOM"} ,
		{"word":"déterminants","word_nosc":"determinants","lemma":"déterminant","pos":"NOM"} ,
		{"word":"détermination","word_nosc":"determination","lemma":"détermination","pos":"NOM"} ,
		{"word":"déterminations","word_nosc":"determinations","lemma":"détermination","pos":"NOM"} ,
		{"word":"déterminisme","word_nosc":"determinisme","lemma":"déterminisme","pos":"NOM"} ,
		{"word":"déterministe","word_nosc":"deterministe","lemma":"déterministe","pos":"NOM"} ,
		{"word":"déterrage","word_nosc":"deterrage","lemma":"déterrage","pos":"NOM"} ,
		{"word":"déterreur","word_nosc":"deterreur","lemma":"déterreur","pos":"NOM"} ,
		{"word":"déterreurs","word_nosc":"deterreurs","lemma":"déterreur","pos":"NOM"} ,
		{"word":"déterreuse","word_nosc":"deterreuse","lemma":"déterreur","pos":"NOM"} ,
		{"word":"déterré","word_nosc":"deterre","lemma":"déterré","pos":"NOM"} ,
		{"word":"déterrée","word_nosc":"deterree","lemma":"déterré","pos":"NOM"} ,
		{"word":"déterrés","word_nosc":"deterres","lemma":"déterré","pos":"NOM"} ,
		{"word":"détestation","word_nosc":"detestation","lemma":"détestation","pos":"NOM"} ,
		{"word":"détestations","word_nosc":"detestations","lemma":"détestation","pos":"NOM"} ,
		{"word":"détonateur","word_nosc":"detonateur","lemma":"détonateur","pos":"NOM"} ,
		{"word":"détonateurs","word_nosc":"detonateurs","lemma":"détonateur","pos":"NOM"} ,
		{"word":"détonation","word_nosc":"detonation","lemma":"détonation","pos":"NOM"} ,
		{"word":"détonations","word_nosc":"detonations","lemma":"détonation","pos":"NOM"} ,
		{"word":"détour","word_nosc":"detour","lemma":"détour","pos":"NOM"} ,
		{"word":"détourage","word_nosc":"detourage","lemma":"détourage","pos":"NOM"} ,
		{"word":"détournement","word_nosc":"detournement","lemma":"détournement","pos":"NOM"} ,
		{"word":"détournements","word_nosc":"detournements","lemma":"détournement","pos":"NOM"} ,
		{"word":"détourneur","word_nosc":"detourneur","lemma":"détourneur","pos":"NOM"} ,
		{"word":"détours","word_nosc":"detours","lemma":"détour","pos":"NOM"} ,
		{"word":"détracteur","word_nosc":"detracteur","lemma":"détracteur","pos":"NOM"} ,
		{"word":"détracteurs","word_nosc":"detracteurs","lemma":"détracteur","pos":"NOM"} ,
		{"word":"détraquement","word_nosc":"detraquement","lemma":"détraquement","pos":"NOM"} ,
		{"word":"détraqué","word_nosc":"detraque","lemma":"détraqué","pos":"NOM"} ,
		{"word":"détraquée","word_nosc":"detraquee","lemma":"détraqué","pos":"NOM"} ,
		{"word":"détraquées","word_nosc":"detraquees","lemma":"détraqué","pos":"NOM"} ,
		{"word":"détraqués","word_nosc":"detraques","lemma":"détraqué","pos":"NOM"} ,
		{"word":"détrempe","word_nosc":"detrempe","lemma":"détrempe","pos":"NOM"} ,
		{"word":"détresse","word_nosc":"detresse","lemma":"détresse","pos":"NOM"} ,
		{"word":"détresses","word_nosc":"detresses","lemma":"détresse","pos":"NOM"} ,
		{"word":"détriment","word_nosc":"detriment","lemma":"détriment","pos":"NOM"} ,
		{"word":"détriments","word_nosc":"detriments","lemma":"détriment","pos":"NOM"} ,
		{"word":"détritus","word_nosc":"detritus","lemma":"détritus","pos":"NOM"} ,
		{"word":"détroit","word_nosc":"detroit","lemma":"détroit","pos":"NOM"} ,
		{"word":"détroits","word_nosc":"detroits","lemma":"détroit","pos":"NOM"} ,
		{"word":"détrousseur","word_nosc":"detrousseur","lemma":"détrousseur","pos":"NOM"} ,
		{"word":"détrousseurs","word_nosc":"detrousseurs","lemma":"détrousseur","pos":"NOM"} ,
		{"word":"détrônement","word_nosc":"detronement","lemma":"détrônement","pos":"NOM"} ,
		{"word":"détérioration","word_nosc":"deterioration","lemma":"détérioration","pos":"NOM"} ,
		{"word":"détériorations","word_nosc":"deteriorations","lemma":"détérioration","pos":"NOM"} ,
		{"word":"dévalement","word_nosc":"devalement","lemma":"dévalement","pos":"NOM"} ,
		{"word":"dévalorisation","word_nosc":"devalorisation","lemma":"dévalorisation","pos":"NOM"} ,
		{"word":"dévaluation","word_nosc":"devaluation","lemma":"dévaluation","pos":"NOM"} ,
		{"word":"dévaluations","word_nosc":"devaluations","lemma":"dévaluation","pos":"NOM"} ,
		{"word":"dévastateur","word_nosc":"devastateur","lemma":"dévastateur","pos":"NOM"} ,
		{"word":"dévastateurs","word_nosc":"devastateurs","lemma":"dévastateur","pos":"NOM"} ,
		{"word":"dévastation","word_nosc":"devastation","lemma":"dévastation","pos":"NOM"} ,
		{"word":"dévastations","word_nosc":"devastations","lemma":"dévastation","pos":"NOM"} ,
		{"word":"déveinards","word_nosc":"deveinards","lemma":"déveinard","pos":"NOM"} ,
		{"word":"déveine","word_nosc":"deveine","lemma":"déveine","pos":"NOM"} ,
		{"word":"déveines","word_nosc":"deveines","lemma":"déveine","pos":"NOM"} ,
		{"word":"développement","word_nosc":"developpement","lemma":"développement","pos":"NOM"} ,
		{"word":"développements","word_nosc":"developpements","lemma":"développement","pos":"NOM"} ,
		{"word":"développeur","word_nosc":"developpeur","lemma":"développeur","pos":"NOM"} ,
		{"word":"développée","word_nosc":"developpee","lemma":"développée","pos":"NOM"} ,
		{"word":"développées","word_nosc":"developpees","lemma":"développée","pos":"NOM"} ,
		{"word":"dévergondage","word_nosc":"devergondage","lemma":"dévergondage","pos":"NOM"} ,
		{"word":"dévergondages","word_nosc":"devergondages","lemma":"dévergondage","pos":"NOM"} ,
		{"word":"dévergondé","word_nosc":"devergonde","lemma":"dévergondé","pos":"NOM"} ,
		{"word":"dévergondée","word_nosc":"devergondee","lemma":"dévergondé","pos":"NOM"} ,
		{"word":"dévergondées","word_nosc":"devergondees","lemma":"dévergondé","pos":"NOM"} ,
		{"word":"déverrouillage","word_nosc":"deverrouillage","lemma":"déverrouillage","pos":"NOM"} ,
		{"word":"déversement","word_nosc":"deversement","lemma":"déversement","pos":"NOM"} ,
		{"word":"déversements","word_nosc":"deversements","lemma":"déversement","pos":"NOM"} ,
		{"word":"déversoir","word_nosc":"deversoir","lemma":"déversoir","pos":"NOM"} ,
		{"word":"déviance","word_nosc":"deviance","lemma":"déviance","pos":"NOM"} ,
		{"word":"déviances","word_nosc":"deviances","lemma":"déviance","pos":"NOM"} ,
		{"word":"déviant","word_nosc":"deviant","lemma":"déviant","pos":"NOM"} ,
		{"word":"déviante","word_nosc":"deviante","lemma":"déviant","pos":"NOM"} ,
		{"word":"déviants","word_nosc":"deviants","lemma":"déviant","pos":"NOM"} ,
		{"word":"déviation","word_nosc":"deviation","lemma":"déviation","pos":"NOM"} ,
		{"word":"déviationnisme","word_nosc":"deviationnisme","lemma":"déviationnisme","pos":"NOM"} ,
		{"word":"déviations","word_nosc":"deviations","lemma":"déviation","pos":"NOM"} ,
		{"word":"dévideur","word_nosc":"devideur","lemma":"dévideur","pos":"NOM"} ,
		{"word":"dévidoir","word_nosc":"devidoir","lemma":"dévidoir","pos":"NOM"} ,
		{"word":"dévidoirs","word_nosc":"devidoirs","lemma":"dévidoir","pos":"NOM"} ,
		{"word":"dévirilisation","word_nosc":"devirilisation","lemma":"dévirilisation","pos":"NOM"} ,
		{"word":"dévissage","word_nosc":"devissage","lemma":"dévissage","pos":"NOM"} ,
		{"word":"dévissé","word_nosc":"devisse","lemma":"dévissé","pos":"NOM"} ,
		{"word":"dévitalisation","word_nosc":"devitalisation","lemma":"dévitalisation","pos":"NOM"} ,
		{"word":"dévoiement","word_nosc":"devoiement","lemma":"dévoiement","pos":"NOM"} ,
		{"word":"dévoilement","word_nosc":"devoilement","lemma":"dévoilement","pos":"NOM"} ,
		{"word":"dévoilements","word_nosc":"devoilements","lemma":"dévoilement","pos":"NOM"} ,
		{"word":"dévolu","word_nosc":"devolu","lemma":"dévolu","pos":"NOM"} ,
		{"word":"dévolus","word_nosc":"devolus","lemma":"dévolu","pos":"NOM"} ,
		{"word":"dévolution","word_nosc":"devolution","lemma":"dévolution","pos":"NOM"} ,
		{"word":"dévonien","word_nosc":"devonien","lemma":"dévonien","pos":"NOM"} ,
		{"word":"dévoration","word_nosc":"devoration","lemma":"dévoration","pos":"NOM"} ,
		{"word":"dévoreur","word_nosc":"devoreur","lemma":"dévoreur","pos":"NOM"} ,
		{"word":"dévoreurs","word_nosc":"devoreurs","lemma":"dévoreur","pos":"NOM"} ,
		{"word":"dévoreuse","word_nosc":"devoreuse","lemma":"dévoreur","pos":"NOM"} ,
		{"word":"dévoreuses","word_nosc":"devoreuses","lemma":"dévoreur","pos":"NOM"} ,
		{"word":"dévot","word_nosc":"devot","lemma":"dévot","pos":"NOM"} ,
		{"word":"dévote","word_nosc":"devote","lemma":"dévot","pos":"NOM"} ,
		{"word":"dévotes","word_nosc":"devotes","lemma":"dévot","pos":"NOM"} ,
		{"word":"dévotion","word_nosc":"devotion","lemma":"dévotion","pos":"NOM"} ,
		{"word":"dévotions","word_nosc":"devotions","lemma":"dévotion","pos":"NOM"} ,
		{"word":"dévots","word_nosc":"devots","lemma":"dévot","pos":"NOM"} ,
		{"word":"dévouement","word_nosc":"devouement","lemma":"dévouement","pos":"NOM"} ,
		{"word":"dévouements","word_nosc":"devouements","lemma":"dévouement","pos":"NOM"} ,
		{"word":"dévoyé","word_nosc":"devoye","lemma":"dévoyé","pos":"NOM"} ,
		{"word":"dévoyée","word_nosc":"devoyee","lemma":"dévoyé","pos":"NOM"} ,
		{"word":"dévoyés","word_nosc":"devoyes","lemma":"dévoyé","pos":"NOM"} ,
		{"word":"déçu","word_nosc":"decu","lemma":"déçu","pos":"NOM"} ,
		{"word":"déçue","word_nosc":"decue","lemma":"déçu","pos":"NOM"} ,
		{"word":"déçues","word_nosc":"decues","lemma":"déçu","pos":"NOM"} ,
		{"word":"déçus","word_nosc":"decus","lemma":"déçu","pos":"NOM"} ,
		{"word":"dîme","word_nosc":"dime","lemma":"dîme","pos":"NOM"} ,
		{"word":"dîmes","word_nosc":"dimes","lemma":"dîme","pos":"NOM"} ,
		{"word":"dîner","word_nosc":"diner","lemma":"dîner","pos":"NOM"} ,
		{"word":"dîners","word_nosc":"diners","lemma":"dîner","pos":"NOM"} ,
		{"word":"dînette","word_nosc":"dinette","lemma":"dînette","pos":"NOM"} ,
		{"word":"dînettes","word_nosc":"dinettes","lemma":"dînette","pos":"NOM"} ,
		{"word":"dîneur","word_nosc":"dineur","lemma":"dîneur","pos":"NOM"} ,
		{"word":"dîneurs","word_nosc":"dineurs","lemma":"dîneur","pos":"NOM"} ,
		{"word":"dîneuse","word_nosc":"dineuse","lemma":"dîneur","pos":"NOM"} ,
		{"word":"dôle","word_nosc":"dole","lemma":"dôle","pos":"NOM"} ,
		{"word":"dôme","word_nosc":"dome","lemma":"dôme","pos":"NOM"} ,
		{"word":"dômes","word_nosc":"domes","lemma":"dôme","pos":"NOM"} ,
		{"word":"dôngs","word_nosc":"dongs","lemma":"dông","pos":"NOM"} ,
		{"word":"dû","word_nosc":"du","lemma":"dû","pos":"NOM"} ,
		{"word":"e","word_nosc":"e","lemma":"e","pos":"NOM"} ,
		{"word":"e-commerce","word_nosc":"e-commerce","lemma":"e-commerce","pos":"NOM"} ,
		{"word":"e-mail","word_nosc":"e-mail","lemma":"e-mail","pos":"NOM"} ,
		{"word":"e-mails","word_nosc":"e-mails","lemma":"e-mail","pos":"NOM"} ,
		{"word":"east river","word_nosc":"east river","lemma":"east river","pos":"NOM"} ,
		{"word":"eau","word_nosc":"eau","lemma":"eau","pos":"NOM"} ,
		{"word":"eau-de-vie","word_nosc":"eau-de-vie","lemma":"eau-de-vie","pos":"NOM"} ,
		{"word":"eau-forte","word_nosc":"eau-forte","lemma":"eau-forte","pos":"NOM"} ,
		{"word":"eau-minute","word_nosc":"eau-minute","lemma":"eau-minute","pos":"NOM"} ,
		{"word":"eaux","word_nosc":"eaux","lemma":"eau","pos":"NOM"} ,
		{"word":"eaux-de-vie","word_nosc":"eaux-de-vie","lemma":"eau-de-vie","pos":"NOM"} ,
		{"word":"eaux-fortes","word_nosc":"eaux-fortes","lemma":"eaux-fortes","pos":"NOM"} ,
		{"word":"ecchymose","word_nosc":"ecchymose","lemma":"ecchymose","pos":"NOM"} ,
		{"word":"ecchymoses","word_nosc":"ecchymoses","lemma":"ecchymose","pos":"NOM"} ,
		{"word":"ecclésiastique","word_nosc":"ecclesiastique","lemma":"ecclésiastique","pos":"NOM"} ,
		{"word":"ecclésiastiques","word_nosc":"ecclesiastiques","lemma":"ecclésiastique","pos":"NOM"} ,
		{"word":"ecstasy","word_nosc":"ecstasy","lemma":"ecstasy","pos":"NOM"} ,
		{"word":"ectasie","word_nosc":"ectasie","lemma":"ectasie","pos":"NOM"} ,
		{"word":"ecthyma","word_nosc":"ecthyma","lemma":"ecthyma","pos":"NOM"} ,
		{"word":"ectopie","word_nosc":"ectopie","lemma":"ectopie","pos":"NOM"} ,
		{"word":"ectoplasme","word_nosc":"ectoplasme","lemma":"ectoplasme","pos":"NOM"} ,
		{"word":"ectoplasmes","word_nosc":"ectoplasmes","lemma":"ectoplasme","pos":"NOM"} ,
		{"word":"eczéma","word_nosc":"eczema","lemma":"eczéma","pos":"NOM"} ,
		{"word":"eczémas","word_nosc":"eczemas","lemma":"eczéma","pos":"NOM"} ,
		{"word":"eczémateux","word_nosc":"eczemateux","lemma":"eczémateux","pos":"NOM"} ,
		{"word":"edelweiss","word_nosc":"edelweiss","lemma":"edelweiss","pos":"NOM"} ,
		{"word":"effacement","word_nosc":"effacement","lemma":"effacement","pos":"NOM"} ,
		{"word":"effacements","word_nosc":"effacements","lemma":"effacement","pos":"NOM"} ,
		{"word":"effaceur","word_nosc":"effaceur","lemma":"effaceur","pos":"NOM"} ,
		{"word":"effarement","word_nosc":"effarement","lemma":"effarement","pos":"NOM"} ,
		{"word":"effarouchement","word_nosc":"effarouchement","lemma":"effarouchement","pos":"NOM"} ,
		{"word":"effarouchements","word_nosc":"effarouchements","lemma":"effarouchement","pos":"NOM"} ,
		{"word":"effarvatte","word_nosc":"effarvatte","lemma":"effarvatte","pos":"NOM"} ,
		{"word":"effarvattes","word_nosc":"effarvattes","lemma":"effarvatte","pos":"NOM"} ,
		{"word":"effaçage","word_nosc":"effacage","lemma":"effaçage","pos":"NOM"} ,
		{"word":"effectif","word_nosc":"effectif","lemma":"effectif","pos":"NOM"} ,
		{"word":"effectifs","word_nosc":"effectifs","lemma":"effectif","pos":"NOM"} ,
		{"word":"effectivité","word_nosc":"effectivite","lemma":"effectivité","pos":"NOM"} ,
		{"word":"effendi","word_nosc":"effendi","lemma":"effendi","pos":"NOM"} ,
		{"word":"effervescence","word_nosc":"effervescence","lemma":"effervescence","pos":"NOM"} ,
		{"word":"effervescences","word_nosc":"effervescences","lemma":"effervescence","pos":"NOM"} ,
		{"word":"effet","word_nosc":"effet","lemma":"effet","pos":"NOM"} ,
		{"word":"effets","word_nosc":"effets","lemma":"effet","pos":"NOM"} ,
		{"word":"effeuillage","word_nosc":"effeuillage","lemma":"effeuillage","pos":"NOM"} ,
		{"word":"effeuilleuse","word_nosc":"effeuilleuse","lemma":"effeuilleur","pos":"NOM"} ,
		{"word":"effeuilleuses","word_nosc":"effeuilleuses","lemma":"effeuilleur","pos":"NOM"} ,
		{"word":"efficacité","word_nosc":"efficacite","lemma":"efficacité","pos":"NOM"} ,
		{"word":"efficience","word_nosc":"efficience","lemma":"efficience","pos":"NOM"} ,
		{"word":"effigie","word_nosc":"effigie","lemma":"effigie","pos":"NOM"} ,
		{"word":"effigies","word_nosc":"effigies","lemma":"effigie","pos":"NOM"} ,
		{"word":"effilement","word_nosc":"effilement","lemma":"effilement","pos":"NOM"} ,
		{"word":"effilochage","word_nosc":"effilochage","lemma":"effilochage","pos":"NOM"} ,
		{"word":"effilochure","word_nosc":"effilochure","lemma":"effilochure","pos":"NOM"} ,
		{"word":"effilochures","word_nosc":"effilochures","lemma":"effilochure","pos":"NOM"} ,
		{"word":"effilure","word_nosc":"effilure","lemma":"effilure","pos":"NOM"} ,
		{"word":"effilures","word_nosc":"effilures","lemma":"effilure","pos":"NOM"} ,
		{"word":"effilé","word_nosc":"effile","lemma":"effilé","pos":"NOM"} ,
		{"word":"effilés","word_nosc":"effiles","lemma":"effilé","pos":"NOM"} ,
		{"word":"effleurage","word_nosc":"effleurage","lemma":"effleurage","pos":"NOM"} ,
		{"word":"effleurement","word_nosc":"effleurement","lemma":"effleurement","pos":"NOM"} ,
		{"word":"effleurements","word_nosc":"effleurements","lemma":"effleurement","pos":"NOM"} ,
		{"word":"efflorescence","word_nosc":"efflorescence","lemma":"efflorescence","pos":"NOM"} ,
		{"word":"effluent","word_nosc":"effluent","lemma":"effluent","pos":"NOM"} ,
		{"word":"effluve","word_nosc":"effluve","lemma":"effluve","pos":"NOM"} ,
		{"word":"effluves","word_nosc":"effluves","lemma":"effluve","pos":"NOM"} ,
		{"word":"effondrement","word_nosc":"effondrement","lemma":"effondrement","pos":"NOM"} ,
		{"word":"effondrements","word_nosc":"effondrements","lemma":"effondrement","pos":"NOM"} ,
		{"word":"effort","word_nosc":"effort","lemma":"effort","pos":"NOM"} ,
		{"word":"efforts","word_nosc":"efforts","lemma":"effort","pos":"NOM"} ,
		{"word":"effraction","word_nosc":"effraction","lemma":"effraction","pos":"NOM"} ,
		{"word":"effractions","word_nosc":"effractions","lemma":"effraction","pos":"NOM"} ,
		{"word":"effraie","word_nosc":"effraie","lemma":"effraie","pos":"NOM"} ,
		{"word":"effraies","word_nosc":"effraies","lemma":"effraie","pos":"NOM"} ,
		{"word":"effritement","word_nosc":"effritement","lemma":"effritement","pos":"NOM"} ,
		{"word":"effritements","word_nosc":"effritements","lemma":"effritement","pos":"NOM"} ,
		{"word":"effroi","word_nosc":"effroi","lemma":"effroi","pos":"NOM"} ,
		{"word":"effronterie","word_nosc":"effronterie","lemma":"effronterie","pos":"NOM"} ,
		{"word":"effronteries","word_nosc":"effronteries","lemma":"effronterie","pos":"NOM"} ,
		{"word":"effronté","word_nosc":"effronte","lemma":"effronté","pos":"NOM"} ,
		{"word":"effrontée","word_nosc":"effrontee","lemma":"effronté","pos":"NOM"} ,
		{"word":"effrontées","word_nosc":"effrontees","lemma":"effronté","pos":"NOM"} ,
		{"word":"effrontés","word_nosc":"effrontes","lemma":"effronté","pos":"NOM"} ,
		{"word":"effusion","word_nosc":"effusion","lemma":"effusion","pos":"NOM"} ,
		{"word":"effusions","word_nosc":"effusions","lemma":"effusion","pos":"NOM"} ,
		{"word":"efrit","word_nosc":"efrit","lemma":"efrit","pos":"NOM"} ,
		{"word":"ego","word_nosc":"ego","lemma":"ego","pos":"NOM"} ,
		{"word":"eider","word_nosc":"eider","lemma":"eider","pos":"NOM"} ,
		{"word":"eiders","word_nosc":"eiders","lemma":"eider","pos":"NOM"} ,
		{"word":"ektachromes","word_nosc":"ektachromes","lemma":"ektachrome","pos":"NOM"} ,
		{"word":"eldorado","word_nosc":"eldorado","lemma":"eldorado","pos":"NOM"} ,
		{"word":"elfe","word_nosc":"elfe","lemma":"elfe","pos":"NOM"} ,
		{"word":"elfes","word_nosc":"elfes","lemma":"elfe","pos":"NOM"} ,
		{"word":"ellipse","word_nosc":"ellipse","lemma":"ellipse","pos":"NOM"} ,
		{"word":"ellipses","word_nosc":"ellipses","lemma":"ellipse","pos":"NOM"} ,
		{"word":"elliptique","word_nosc":"elliptique","lemma":"elliptique","pos":"NOM"} ,
		{"word":"ellébore","word_nosc":"ellebore","lemma":"ellébore","pos":"NOM"} ,
		{"word":"elzévir","word_nosc":"elzevir","lemma":"elzévir","pos":"NOM"} ,
		{"word":"emails","word_nosc":"emails","lemma":"email","pos":"NOM"} ,
		{"word":"emballage","word_nosc":"emballage","lemma":"emballage","pos":"NOM"} ,
		{"word":"emballages","word_nosc":"emballages","lemma":"emballage","pos":"NOM"} ,
		{"word":"emballement","word_nosc":"emballement","lemma":"emballement","pos":"NOM"} ,
		{"word":"emballements","word_nosc":"emballements","lemma":"emballement","pos":"NOM"} ,
		{"word":"emballeur","word_nosc":"emballeur","lemma":"emballeur","pos":"NOM"} ,
		{"word":"emballeurs","word_nosc":"emballeurs","lemma":"emballeur","pos":"NOM"} ,
		{"word":"emballeuse","word_nosc":"emballeuse","lemma":"emballeur","pos":"NOM"} ,
		{"word":"emballeuses","word_nosc":"emballeuses","lemma":"emballeur","pos":"NOM"} ,
		{"word":"embarcadère","word_nosc":"embarcadere","lemma":"embarcadère","pos":"NOM"} ,
		{"word":"embarcadères","word_nosc":"embarcaderes","lemma":"embarcadère","pos":"NOM"} ,
		{"word":"embarcation","word_nosc":"embarcation","lemma":"embarcation","pos":"NOM"} ,
		{"word":"embarcations","word_nosc":"embarcations","lemma":"embarcation","pos":"NOM"} ,
		{"word":"embardée","word_nosc":"embardee","lemma":"embardée","pos":"NOM"} ,
		{"word":"embardées","word_nosc":"embardees","lemma":"embardée","pos":"NOM"} ,
		{"word":"embargo","word_nosc":"embargo","lemma":"embargo","pos":"NOM"} ,
		{"word":"embargos","word_nosc":"embargos","lemma":"embargo","pos":"NOM"} ,
		{"word":"embarquement","word_nosc":"embarquement","lemma":"embarquement","pos":"NOM"} ,
		{"word":"embarquements","word_nosc":"embarquements","lemma":"embarquement","pos":"NOM"} ,
		{"word":"embarras","word_nosc":"embarras","lemma":"embarras","pos":"NOM"} ,
		{"word":"embarrure","word_nosc":"embarrure","lemma":"embarrure","pos":"NOM"} ,
		{"word":"embase","word_nosc":"embase","lemma":"embase","pos":"NOM"} ,
		{"word":"embases","word_nosc":"embases","lemma":"embase","pos":"NOM"} ,
		{"word":"embauchage","word_nosc":"embauchage","lemma":"embauchage","pos":"NOM"} ,
		{"word":"embauche","word_nosc":"embauche","lemma":"embauche","pos":"NOM"} ,
		{"word":"embauches","word_nosc":"embauches","lemma":"embauche","pos":"NOM"} ,
		{"word":"embauchoir","word_nosc":"embauchoir","lemma":"embauchoir","pos":"NOM"} ,
		{"word":"embauchoirs","word_nosc":"embauchoirs","lemma":"embauchoir","pos":"NOM"} ,
		{"word":"embaumement","word_nosc":"embaumement","lemma":"embaumement","pos":"NOM"} ,
		{"word":"embaumements","word_nosc":"embaumements","lemma":"embaumement","pos":"NOM"} ,
		{"word":"embaumeur","word_nosc":"embaumeur","lemma":"embaumeur","pos":"NOM"} ,
		{"word":"embaumeurs","word_nosc":"embaumeurs","lemma":"embaumeur","pos":"NOM"} ,
		{"word":"embaumeuse","word_nosc":"embaumeuse","lemma":"embaumeur","pos":"NOM"} ,
		{"word":"embellie","word_nosc":"embellie","lemma":"embellie","pos":"NOM"} ,
		{"word":"embellies","word_nosc":"embellies","lemma":"embellie","pos":"NOM"} ,
		{"word":"embellissement","word_nosc":"embellissement","lemma":"embellissement","pos":"NOM"} ,
		{"word":"embellissements","word_nosc":"embellissements","lemma":"embellissement","pos":"NOM"} ,
		{"word":"emblavages","word_nosc":"emblavages","lemma":"emblavage","pos":"NOM"} ,
		{"word":"emblavure","word_nosc":"emblavure","lemma":"emblavure","pos":"NOM"} ,
		{"word":"emblavures","word_nosc":"emblavures","lemma":"emblavure","pos":"NOM"} ,
		{"word":"emblème","word_nosc":"embleme","lemma":"emblème","pos":"NOM"} ,
		{"word":"emblèmes","word_nosc":"emblemes","lemma":"emblème","pos":"NOM"} ,
		{"word":"embole","word_nosc":"embole","lemma":"embole","pos":"NOM"} ,
		{"word":"embolie","word_nosc":"embolie","lemma":"embolie","pos":"NOM"} ,
		{"word":"embolies","word_nosc":"embolies","lemma":"embolie","pos":"NOM"} ,
		{"word":"embonpoint","word_nosc":"embonpoint","lemma":"embonpoint","pos":"NOM"} ,
		{"word":"embouche","word_nosc":"embouche","lemma":"embouche","pos":"NOM"} ,
		{"word":"embouchoir","word_nosc":"embouchoir","lemma":"embouchoir","pos":"NOM"} ,
		{"word":"embouchoirs","word_nosc":"embouchoirs","lemma":"embouchoir","pos":"NOM"} ,
		{"word":"embouchure","word_nosc":"embouchure","lemma":"embouchure","pos":"NOM"} ,
		{"word":"embouchures","word_nosc":"embouchures","lemma":"embouchure","pos":"NOM"} ,
		{"word":"embourgeoisement","word_nosc":"embourgeoisement","lemma":"embourgeoisement","pos":"NOM"} ,
		{"word":"embourgeoisé","word_nosc":"embourgeoise","lemma":"embourgeoisé","pos":"NOM"} ,
		{"word":"embourgeoisés","word_nosc":"embourgeoises","lemma":"embourgeoisé","pos":"NOM"} ,
		{"word":"embout","word_nosc":"embout","lemma":"embout","pos":"NOM"} ,
		{"word":"embouteillage","word_nosc":"embouteillage","lemma":"embouteillage","pos":"NOM"} ,
		{"word":"embouteillages","word_nosc":"embouteillages","lemma":"embouteillage","pos":"NOM"} ,
		{"word":"emboutisseur","word_nosc":"emboutisseur","lemma":"emboutisseur","pos":"NOM"} ,
		{"word":"emboutisseuse","word_nosc":"emboutisseuse","lemma":"emboutisseur","pos":"NOM"} ,
		{"word":"embouts","word_nosc":"embouts","lemma":"embout","pos":"NOM"} ,
		{"word":"emboîtage","word_nosc":"emboitage","lemma":"emboîtage","pos":"NOM"} ,
		{"word":"emboîtages","word_nosc":"emboitages","lemma":"emboîtage","pos":"NOM"} ,
		{"word":"emboîtement","word_nosc":"emboitement","lemma":"emboîtement","pos":"NOM"} ,
		{"word":"emboîtures","word_nosc":"emboitures","lemma":"emboîture","pos":"NOM"} ,
		{"word":"embranchement","word_nosc":"embranchement","lemma":"embranchement","pos":"NOM"} ,
		{"word":"embranchements","word_nosc":"embranchements","lemma":"embranchement","pos":"NOM"} ,
		{"word":"embrasement","word_nosc":"embrasement","lemma":"embrasement","pos":"NOM"} ,
		{"word":"embrasements","word_nosc":"embrasements","lemma":"embrasement","pos":"NOM"} ,
		{"word":"embrassade","word_nosc":"embrassade","lemma":"embrassade","pos":"NOM"} ,
		{"word":"embrassades","word_nosc":"embrassades","lemma":"embrassade","pos":"NOM"} ,
		{"word":"embrasse","word_nosc":"embrasse","lemma":"embrasse","pos":"NOM"} ,
		{"word":"embrassement","word_nosc":"embrassement","lemma":"embrassement","pos":"NOM"} ,
		{"word":"embrassements","word_nosc":"embrassements","lemma":"embrassement","pos":"NOM"} ,
		{"word":"embrasses","word_nosc":"embrasses","lemma":"embrasse","pos":"NOM"} ,
		{"word":"embrasseur","word_nosc":"embrasseur","lemma":"embrasseur","pos":"NOM"} ,
		{"word":"embrasseurs","word_nosc":"embrasseurs","lemma":"embrasseur","pos":"NOM"} ,
		{"word":"embrasseuse","word_nosc":"embrasseuse","lemma":"embrasseur","pos":"NOM"} ,
		{"word":"embrasseuses","word_nosc":"embrasseuses","lemma":"embrasseur","pos":"NOM"} ,
		{"word":"embrasure","word_nosc":"embrasure","lemma":"embrasure","pos":"NOM"} ,
		{"word":"embrasures","word_nosc":"embrasures","lemma":"embrasure","pos":"NOM"} ,
		{"word":"embrayage","word_nosc":"embrayage","lemma":"embrayage","pos":"NOM"} ,
		{"word":"embrayages","word_nosc":"embrayages","lemma":"embrayage","pos":"NOM"} ,
		{"word":"embrocation","word_nosc":"embrocation","lemma":"embrocation","pos":"NOM"} ,
		{"word":"embrocations","word_nosc":"embrocations","lemma":"embrocation","pos":"NOM"} ,
		{"word":"embrouillage","word_nosc":"embrouillage","lemma":"embrouillage","pos":"NOM"} ,
		{"word":"embrouillamini","word_nosc":"embrouillamini","lemma":"embrouillamini","pos":"NOM"} ,
		{"word":"embrouillaminis","word_nosc":"embrouillaminis","lemma":"embrouillamini","pos":"NOM"} ,
		{"word":"embrouille","word_nosc":"embrouille","lemma":"embrouille","pos":"NOM"} ,
		{"word":"embrouillement","word_nosc":"embrouillement","lemma":"embrouillement","pos":"NOM"} ,
		{"word":"embrouillements","word_nosc":"embrouillements","lemma":"embrouillement","pos":"NOM"} ,
		{"word":"embrouilles","word_nosc":"embrouilles","lemma":"embrouille","pos":"NOM"} ,
		{"word":"embrouilleur","word_nosc":"embrouilleur","lemma":"embrouilleur","pos":"NOM"} ,
		{"word":"embrouilleurs","word_nosc":"embrouilleurs","lemma":"embrouilleur","pos":"NOM"} ,
		{"word":"embrouilleuses","word_nosc":"embrouilleuses","lemma":"embrouilleur","pos":"NOM"} ,
		{"word":"embrun","word_nosc":"embrun","lemma":"embrun","pos":"NOM"} ,
		{"word":"embruns","word_nosc":"embruns","lemma":"embrun","pos":"NOM"} ,
		{"word":"embryologie","word_nosc":"embryologie","lemma":"embryologie","pos":"NOM"} ,
		{"word":"embryologiste","word_nosc":"embryologiste","lemma":"embryologiste","pos":"NOM"} ,
		{"word":"embryon","word_nosc":"embryon","lemma":"embryon","pos":"NOM"} ,
		{"word":"embryons","word_nosc":"embryons","lemma":"embryon","pos":"NOM"} ,
		{"word":"embu","word_nosc":"embu","lemma":"embu","pos":"NOM"} ,
		{"word":"embus","word_nosc":"embus","lemma":"embu","pos":"NOM"} ,
		{"word":"embuscade","word_nosc":"embuscade","lemma":"embuscade","pos":"NOM"} ,
		{"word":"embuscades","word_nosc":"embuscades","lemma":"embuscade","pos":"NOM"} ,
		{"word":"embusqué","word_nosc":"embusque","lemma":"embusqué","pos":"NOM"} ,
		{"word":"embusqués","word_nosc":"embusques","lemma":"embusqué","pos":"NOM"} ,
		{"word":"embâcle","word_nosc":"embacle","lemma":"embâcle","pos":"NOM"} ,
		{"word":"embêtement","word_nosc":"embetement","lemma":"embêtement","pos":"NOM"} ,
		{"word":"embêtements","word_nosc":"embetements","lemma":"embêtement","pos":"NOM"} ,
		{"word":"embûche","word_nosc":"embuche","lemma":"embûche","pos":"NOM"} ,
		{"word":"embûches","word_nosc":"embuches","lemma":"embûche","pos":"NOM"} ,
		{"word":"emergency","word_nosc":"emergency","lemma":"emergency","pos":"NOM"} ,
		{"word":"emmaillotement","word_nosc":"emmaillotement","lemma":"emmaillotement","pos":"NOM"} ,
		{"word":"emmanchage","word_nosc":"emmanchage","lemma":"emmanchage","pos":"NOM"} ,
		{"word":"emmanchure","word_nosc":"emmanchure","lemma":"emmanchure","pos":"NOM"} ,
		{"word":"emmanchures","word_nosc":"emmanchures","lemma":"emmanchure","pos":"NOM"} ,
		{"word":"emmental","word_nosc":"emmental","lemma":"emmental","pos":"NOM"} ,
		{"word":"emmenthal","word_nosc":"emmenthal","lemma":"emmenthal","pos":"NOM"} ,
		{"word":"emmerde","word_nosc":"emmerde","lemma":"emmerde","pos":"NOM"} ,
		{"word":"emmerdement","word_nosc":"emmerdement","lemma":"emmerdement","pos":"NOM"} ,
		{"word":"emmerdements","word_nosc":"emmerdements","lemma":"emmerdement","pos":"NOM"} ,
		{"word":"emmerdes","word_nosc":"emmerdes","lemma":"emmerde","pos":"NOM"} ,
		{"word":"emmerdeur","word_nosc":"emmerdeur","lemma":"emmerdeur","pos":"NOM"} ,
		{"word":"emmerdeurs","word_nosc":"emmerdeurs","lemma":"emmerdeur","pos":"NOM"} ,
		{"word":"emmerdeuse","word_nosc":"emmerdeuse","lemma":"emmerdeur","pos":"NOM"} ,
		{"word":"emmerdeuses","word_nosc":"emmerdeuses","lemma":"emmerdeur","pos":"NOM"} ,
		{"word":"emmouscaillements","word_nosc":"emmouscaillements","lemma":"emmouscaillement","pos":"NOM"} ,
		{"word":"emmurement","word_nosc":"emmurement","lemma":"emmurement","pos":"NOM"} ,
		{"word":"emménagement","word_nosc":"emmenagement","lemma":"emménagement","pos":"NOM"} ,
		{"word":"emmêlement","word_nosc":"emmelement","lemma":"emmêlement","pos":"NOM"} ,
		{"word":"emmêlements","word_nosc":"emmelements","lemma":"emmêlement","pos":"NOM"} ,
		{"word":"empaffé","word_nosc":"empaffe","lemma":"empaffé","pos":"NOM"} ,
		{"word":"empaffés","word_nosc":"empaffes","lemma":"empaffé","pos":"NOM"} ,
		{"word":"empailleur","word_nosc":"empailleur","lemma":"empailleur","pos":"NOM"} ,
		{"word":"empailleurs","word_nosc":"empailleurs","lemma":"empailleur","pos":"NOM"} ,
		{"word":"empalement","word_nosc":"empalement","lemma":"empalement","pos":"NOM"} ,
		{"word":"empan","word_nosc":"empan","lemma":"empan","pos":"NOM"} ,
		{"word":"empaquetage","word_nosc":"empaquetage","lemma":"empaquetage","pos":"NOM"} ,
		{"word":"empaqueteur","word_nosc":"empaqueteur","lemma":"empaqueteur","pos":"NOM"} ,
		{"word":"empathie","word_nosc":"empathie","lemma":"empathie","pos":"NOM"} ,
		{"word":"empattement","word_nosc":"empattement","lemma":"empattement","pos":"NOM"} ,
		{"word":"empattements","word_nosc":"empattements","lemma":"empattement","pos":"NOM"} ,
		{"word":"empaumure","word_nosc":"empaumure","lemma":"empaumure","pos":"NOM"} ,
		{"word":"empaumures","word_nosc":"empaumures","lemma":"empaumure","pos":"NOM"} ,
		{"word":"empeigne","word_nosc":"empeigne","lemma":"empeigne","pos":"NOM"} ,
		{"word":"empennage","word_nosc":"empennage","lemma":"empennage","pos":"NOM"} ,
		{"word":"empenne","word_nosc":"empenne","lemma":"empenne","pos":"NOM"} ,
		{"word":"empereur","word_nosc":"empereur","lemma":"empereur","pos":"NOM"} ,
		{"word":"empereurs","word_nosc":"empereurs","lemma":"empereur","pos":"NOM"} ,
		{"word":"empesage","word_nosc":"empesage","lemma":"empesage","pos":"NOM"} ,
		{"word":"emphase","word_nosc":"emphase","lemma":"emphase","pos":"NOM"} ,
		{"word":"emphases","word_nosc":"emphases","lemma":"emphase","pos":"NOM"} ,
		{"word":"emphysème","word_nosc":"emphyseme","lemma":"emphysème","pos":"NOM"} ,
		{"word":"emphysèmes","word_nosc":"emphysemes","lemma":"emphysème","pos":"NOM"} ,
		{"word":"empierrage","word_nosc":"empierrage","lemma":"empierrage","pos":"NOM"} ,
		{"word":"empierrement","word_nosc":"empierrement","lemma":"empierrement","pos":"NOM"} ,
		{"word":"empilage","word_nosc":"empilage","lemma":"empilage","pos":"NOM"} ,
		{"word":"empilages","word_nosc":"empilages","lemma":"empilage","pos":"NOM"} ,
		{"word":"empilement","word_nosc":"empilement","lemma":"empilement","pos":"NOM"} ,
		{"word":"empilements","word_nosc":"empilements","lemma":"empilement","pos":"NOM"} ,
		{"word":"empire","word_nosc":"empire","lemma":"empire","pos":"NOM"} ,
		{"word":"empires","word_nosc":"empires","lemma":"empire","pos":"NOM"} ,
		{"word":"empirisme","word_nosc":"empirisme","lemma":"empirisme","pos":"NOM"} ,
		{"word":"empirismes","word_nosc":"empirismes","lemma":"empirisme","pos":"NOM"} ,
		{"word":"empiècement","word_nosc":"empiecement","lemma":"empiècement","pos":"NOM"} ,
		{"word":"empiècements","word_nosc":"empiecements","lemma":"empiècement","pos":"NOM"} ,
		{"word":"empiètements","word_nosc":"empietements","lemma":"empiètement","pos":"NOM"} ,
		{"word":"empiétement","word_nosc":"empietement","lemma":"empiétement","pos":"NOM"} ,
		{"word":"empiétements","word_nosc":"empietements","lemma":"empiétement","pos":"NOM"} ,
		{"word":"emplacement","word_nosc":"emplacement","lemma":"emplacement","pos":"NOM"} ,
		{"word":"emplacements","word_nosc":"emplacements","lemma":"emplacement","pos":"NOM"} ,
		{"word":"emplanture","word_nosc":"emplanture","lemma":"emplanture","pos":"NOM"} ,
		{"word":"emplette","word_nosc":"emplette","lemma":"emplette","pos":"NOM"} ,
		{"word":"emplettes","word_nosc":"emplettes","lemma":"emplette","pos":"NOM"} ,
		{"word":"emploi","word_nosc":"emploi","lemma":"emploi","pos":"NOM"} ,
		{"word":"emplois","word_nosc":"emplois","lemma":"emploi","pos":"NOM"} ,
		{"word":"employeur","word_nosc":"employeur","lemma":"employeur","pos":"NOM"} ,
		{"word":"employeurs","word_nosc":"employeurs","lemma":"employeur","pos":"NOM"} ,
		{"word":"employé","word_nosc":"employe","lemma":"employé","pos":"NOM"} ,
		{"word":"employée","word_nosc":"employee","lemma":"employé","pos":"NOM"} ,
		{"word":"employées","word_nosc":"employees","lemma":"employé","pos":"NOM"} ,
		{"word":"employés","word_nosc":"employes","lemma":"employé","pos":"NOM"} ,
		{"word":"emplâtrage","word_nosc":"emplatrage","lemma":"emplâtrage","pos":"NOM"} ,
		{"word":"emplâtre","word_nosc":"emplatre","lemma":"emplâtre","pos":"NOM"} ,
		{"word":"emplâtres","word_nosc":"emplatres","lemma":"emplâtre","pos":"NOM"} ,
		{"word":"empoignade","word_nosc":"empoignade","lemma":"empoignade","pos":"NOM"} ,
		{"word":"empoignades","word_nosc":"empoignades","lemma":"empoignade","pos":"NOM"} ,
		{"word":"empoigne","word_nosc":"empoigne","lemma":"empoigne","pos":"NOM"} ,
		{"word":"empois","word_nosc":"empois","lemma":"empois","pos":"NOM"} ,
		{"word":"empoisonnement","word_nosc":"empoisonnement","lemma":"empoisonnement","pos":"NOM"} ,
		{"word":"empoisonnements","word_nosc":"empoisonnements","lemma":"empoisonnement","pos":"NOM"} ,
		{"word":"empoisonneur","word_nosc":"empoisonneur","lemma":"empoisonneur","pos":"NOM"} ,
		{"word":"empoisonneurs","word_nosc":"empoisonneurs","lemma":"empoisonneur","pos":"NOM"} ,
		{"word":"empoisonneuse","word_nosc":"empoisonneuse","lemma":"empoisonneur","pos":"NOM"} ,
		{"word":"empoisonneuses","word_nosc":"empoisonneuses","lemma":"empoisonneur","pos":"NOM"} ,
		{"word":"emporium","word_nosc":"emporium","lemma":"emporium","pos":"NOM"} ,
		{"word":"emport","word_nosc":"emport","lemma":"emport","pos":"NOM"} ,
		{"word":"emporte-pièce","word_nosc":"emporte-piece","lemma":"emporte-pièce","pos":"NOM"} ,
		{"word":"emportement","word_nosc":"emportement","lemma":"emportement","pos":"NOM"} ,
		{"word":"emportements","word_nosc":"emportements","lemma":"emportement","pos":"NOM"} ,
		{"word":"emporté","word_nosc":"emporte","lemma":"emporté","pos":"NOM"} ,
		{"word":"emportée","word_nosc":"emportee","lemma":"emporté","pos":"NOM"} ,
		{"word":"emportées","word_nosc":"emportees","lemma":"emporté","pos":"NOM"} ,
		{"word":"emportés","word_nosc":"emportes","lemma":"emporté","pos":"NOM"} ,
		{"word":"empoté","word_nosc":"empote","lemma":"empoté","pos":"NOM"} ,
		{"word":"empotés","word_nosc":"empotes","lemma":"empoté","pos":"NOM"} ,
		{"word":"empreinte","word_nosc":"empreinte","lemma":"empreinte","pos":"NOM"} ,
		{"word":"empreintes","word_nosc":"empreintes","lemma":"empreinte","pos":"NOM"} ,
		{"word":"empressement","word_nosc":"empressement","lemma":"empressement","pos":"NOM"} ,
		{"word":"empressements","word_nosc":"empressements","lemma":"empressement","pos":"NOM"} ,
		{"word":"empressé","word_nosc":"empresse","lemma":"empressé","pos":"NOM"} ,
		{"word":"empressée","word_nosc":"empressee","lemma":"empressé","pos":"NOM"} ,
		{"word":"empressés","word_nosc":"empresses","lemma":"empressé","pos":"NOM"} ,
		{"word":"emprise","word_nosc":"emprise","lemma":"emprise","pos":"NOM"} ,
		{"word":"emprises","word_nosc":"emprises","lemma":"emprise","pos":"NOM"} ,
		{"word":"emprisonnement","word_nosc":"emprisonnement","lemma":"emprisonnement","pos":"NOM"} ,
		{"word":"emprisonnements","word_nosc":"emprisonnements","lemma":"emprisonnement","pos":"NOM"} ,
		{"word":"emprunt","word_nosc":"emprunt","lemma":"emprunt","pos":"NOM"} ,
		{"word":"emprunteur","word_nosc":"emprunteur","lemma":"emprunteur","pos":"NOM"} ,
		{"word":"emprunts","word_nosc":"emprunts","lemma":"emprunt","pos":"NOM"} ,
		{"word":"empyrée","word_nosc":"empyree","lemma":"empyrée","pos":"NOM"} ,
		{"word":"empyrées","word_nosc":"empyrees","lemma":"empyrée","pos":"NOM"} ,
		{"word":"empâtement","word_nosc":"empatement","lemma":"empâtement","pos":"NOM"} ,
		{"word":"empâtements","word_nosc":"empatements","lemma":"empâtement","pos":"NOM"} ,
		{"word":"empêchement","word_nosc":"empechement","lemma":"empêchement","pos":"NOM"} ,
		{"word":"empêchements","word_nosc":"empechements","lemma":"empêchement","pos":"NOM"} ,
		{"word":"empêcheur","word_nosc":"empecheur","lemma":"empêcheur","pos":"NOM"} ,
		{"word":"empêcheurs","word_nosc":"empecheurs","lemma":"empêcheur","pos":"NOM"} ,
		{"word":"empêcheuse","word_nosc":"empecheuse","lemma":"empêcheur","pos":"NOM"} ,
		{"word":"en-avant","word_nosc":"en-avant","lemma":"en-avant","pos":"NOM"} ,
		{"word":"en-but","word_nosc":"en-but","lemma":"en-but","pos":"NOM"} ,
		{"word":"en-cas","word_nosc":"en-cas","lemma":"en-cas","pos":"NOM"} ,
		{"word":"en-cours","word_nosc":"en-cours","lemma":"en-cours","pos":"NOM"} ,
		{"word":"en-dehors","word_nosc":"en-dehors","lemma":"en-dehors","pos":"NOM"} ,
		{"word":"en-tête","word_nosc":"en-tete","lemma":"en-tête","pos":"NOM"} ,
		{"word":"en-têtes","word_nosc":"en-tetes","lemma":"en-tête","pos":"NOM"} ,
		{"word":"encablure","word_nosc":"encablure","lemma":"encablure","pos":"NOM"} ,
		{"word":"encablures","word_nosc":"encablures","lemma":"encablure","pos":"NOM"} ,
		{"word":"encadrant","word_nosc":"encadrant","lemma":"encadrant","pos":"NOM"} ,
		{"word":"encadrement","word_nosc":"encadrement","lemma":"encadrement","pos":"NOM"} ,
		{"word":"encadrements","word_nosc":"encadrements","lemma":"encadrement","pos":"NOM"} ,
		{"word":"encadreur","word_nosc":"encadreur","lemma":"encadreur","pos":"NOM"} ,
		{"word":"encadré","word_nosc":"encadre","lemma":"encadré","pos":"NOM"} ,
		{"word":"encadrés","word_nosc":"encadres","lemma":"encadré","pos":"NOM"} ,
		{"word":"encagoulés","word_nosc":"encagoules","lemma":"encagoulé","pos":"NOM"} ,
		{"word":"encaisse","word_nosc":"encaisse","lemma":"encaisse","pos":"NOM"} ,
		{"word":"encaissement","word_nosc":"encaissement","lemma":"encaissement","pos":"NOM"} ,
		{"word":"encaissements","word_nosc":"encaissements","lemma":"encaissement","pos":"NOM"} ,
		{"word":"encaisseur","word_nosc":"encaisseur","lemma":"encaisseur","pos":"NOM"} ,
		{"word":"encaisseurs","word_nosc":"encaisseurs","lemma":"encaisseur","pos":"NOM"} ,
		{"word":"encanaillement","word_nosc":"encanaillement","lemma":"encanaillement","pos":"NOM"} ,
		{"word":"encart","word_nosc":"encart","lemma":"encart","pos":"NOM"} ,
		{"word":"encartage","word_nosc":"encartage","lemma":"encartage","pos":"NOM"} ,
		{"word":"encarts","word_nosc":"encarts","lemma":"encart","pos":"NOM"} ,
		{"word":"encas","word_nosc":"encas","lemma":"encas","pos":"NOM"} ,
		{"word":"encastrement","word_nosc":"encastrement","lemma":"encastrement","pos":"NOM"} ,
		{"word":"encaustique","word_nosc":"encaustique","lemma":"encaustique","pos":"NOM"} ,
		{"word":"enceinte","word_nosc":"enceinte","lemma":"enceinte","pos":"NOM"} ,
		{"word":"enceintes","word_nosc":"enceintes","lemma":"enceinte","pos":"NOM"} ,
		{"word":"encellulement","word_nosc":"encellulement","lemma":"encellulement","pos":"NOM"} ,
		{"word":"encens","word_nosc":"encens","lemma":"encens","pos":"NOM"} ,
		{"word":"encensement","word_nosc":"encensement","lemma":"encensement","pos":"NOM"} ,
		{"word":"encensoir","word_nosc":"encensoir","lemma":"encensoir","pos":"NOM"} ,
		{"word":"encensoirs","word_nosc":"encensoirs","lemma":"encensoir","pos":"NOM"} ,
		{"word":"encerclement","word_nosc":"encerclement","lemma":"encerclement","pos":"NOM"} ,
		{"word":"enchantement","word_nosc":"enchantement","lemma":"enchantement","pos":"NOM"} ,
		{"word":"enchantements","word_nosc":"enchantements","lemma":"enchantement","pos":"NOM"} ,
		{"word":"enchanteresse","word_nosc":"enchanteresse","lemma":"enchanteur","pos":"NOM"} ,
		{"word":"enchanteur","word_nosc":"enchanteur","lemma":"enchanteur","pos":"NOM"} ,
		{"word":"enchanteurs","word_nosc":"enchanteurs","lemma":"enchanteur","pos":"NOM"} ,
		{"word":"enchaînement","word_nosc":"enchainement","lemma":"enchaînement","pos":"NOM"} ,
		{"word":"enchaînements","word_nosc":"enchainements","lemma":"enchaînement","pos":"NOM"} ,
		{"word":"enchevêtrement","word_nosc":"enchevetrement","lemma":"enchevêtrement","pos":"NOM"} ,
		{"word":"enchevêtrements","word_nosc":"enchevetrements","lemma":"enchevêtrement","pos":"NOM"} ,
		{"word":"enchère","word_nosc":"enchere","lemma":"enchère","pos":"NOM"} ,
		{"word":"enchères","word_nosc":"encheres","lemma":"enchère","pos":"NOM"} ,
		{"word":"enchérisseur","word_nosc":"encherisseur","lemma":"enchérisseur","pos":"NOM"} ,
		{"word":"enchérisseurs","word_nosc":"encherisseurs","lemma":"enchérisseur","pos":"NOM"} ,
		{"word":"enchérisseuse","word_nosc":"encherisseuse","lemma":"enchérisseur","pos":"NOM"} ,
		{"word":"enclave","word_nosc":"enclave","lemma":"enclave","pos":"NOM"} ,
		{"word":"enclaves","word_nosc":"enclaves","lemma":"enclave","pos":"NOM"} ,
		{"word":"enclenche","word_nosc":"enclenche","lemma":"enclenche","pos":"NOM"} ,
		{"word":"enclenchement","word_nosc":"enclenchement","lemma":"enclenchement","pos":"NOM"} ,
		{"word":"enclos","word_nosc":"enclos","lemma":"enclos","pos":"NOM"} ,
		{"word":"enclosure","word_nosc":"enclosure","lemma":"enclosure","pos":"NOM"} ,
		{"word":"enclume","word_nosc":"enclume","lemma":"enclume","pos":"NOM"} ,
		{"word":"enclumes","word_nosc":"enclumes","lemma":"enclume","pos":"NOM"} ,
		{"word":"encoche","word_nosc":"encoche","lemma":"encoche","pos":"NOM"} ,
		{"word":"encoches","word_nosc":"encoches","lemma":"encoche","pos":"NOM"} ,
		{"word":"encodage","word_nosc":"encodage","lemma":"encodage","pos":"NOM"} ,
		{"word":"encodeur","word_nosc":"encodeur","lemma":"encodeur","pos":"NOM"} ,
		{"word":"encoignure","word_nosc":"encoignure","lemma":"encoignure","pos":"NOM"} ,
		{"word":"encoignures","word_nosc":"encoignures","lemma":"encoignure","pos":"NOM"} ,
		{"word":"encollage","word_nosc":"encollage","lemma":"encollage","pos":"NOM"} ,
		{"word":"encolleuses","word_nosc":"encolleuses","lemma":"encolleur","pos":"NOM"} ,
		{"word":"encolure","word_nosc":"encolure","lemma":"encolure","pos":"NOM"} ,
		{"word":"encolures","word_nosc":"encolures","lemma":"encolure","pos":"NOM"} ,
		{"word":"encombre","word_nosc":"encombre","lemma":"encombre","pos":"NOM"} ,
		{"word":"encombrement","word_nosc":"encombrement","lemma":"encombrement","pos":"NOM"} ,
		{"word":"encombrements","word_nosc":"encombrements","lemma":"encombrement","pos":"NOM"} ,
		{"word":"encombres","word_nosc":"encombres","lemma":"encombre","pos":"NOM"} ,
		{"word":"encorbellement","word_nosc":"encorbellement","lemma":"encorbellement","pos":"NOM"} ,
		{"word":"encorbellements","word_nosc":"encorbellements","lemma":"encorbellement","pos":"NOM"} ,
		{"word":"encornet","word_nosc":"encornet","lemma":"encornet","pos":"NOM"} ,
		{"word":"encornets","word_nosc":"encornets","lemma":"encornet","pos":"NOM"} ,
		{"word":"encouragement","word_nosc":"encouragement","lemma":"encouragement","pos":"NOM"} ,
		{"word":"encouragements","word_nosc":"encouragements","lemma":"encouragement","pos":"NOM"} ,
		{"word":"encours","word_nosc":"encours","lemma":"encours","pos":"NOM"} ,
		{"word":"encrage","word_nosc":"encrage","lemma":"encrage","pos":"NOM"} ,
		{"word":"encre","word_nosc":"encre","lemma":"encre","pos":"NOM"} ,
		{"word":"encres","word_nosc":"encres","lemma":"encre","pos":"NOM"} ,
		{"word":"encreur","word_nosc":"encreur","lemma":"encreur","pos":"NOM"} ,
		{"word":"encrier","word_nosc":"encrier","lemma":"encrier","pos":"NOM"} ,
		{"word":"encriers","word_nosc":"encriers","lemma":"encrier","pos":"NOM"} ,
		{"word":"encroûtement","word_nosc":"encroutement","lemma":"encroûtement","pos":"NOM"} ,
		{"word":"encroûté","word_nosc":"encroute","lemma":"encroûté","pos":"NOM"} ,
		{"word":"encroûtés","word_nosc":"encroutes","lemma":"encroûté","pos":"NOM"} ,
		{"word":"encryptage","word_nosc":"encryptage","lemma":"encryptage","pos":"NOM"} ,
		{"word":"enculade","word_nosc":"enculade","lemma":"enculade","pos":"NOM"} ,
		{"word":"enculades","word_nosc":"enculades","lemma":"enculade","pos":"NOM"} ,
		{"word":"enculage","word_nosc":"enculage","lemma":"enculage","pos":"NOM"} ,
		{"word":"enculerie","word_nosc":"enculerie","lemma":"enculerie","pos":"NOM"} ,
		{"word":"enculeries","word_nosc":"enculeries","lemma":"enculerie","pos":"NOM"} ,
		{"word":"enculeur","word_nosc":"enculeur","lemma":"enculeur","pos":"NOM"} ,
		{"word":"enculeurs","word_nosc":"enculeurs","lemma":"enculeur","pos":"NOM"} ,
		{"word":"encyclique","word_nosc":"encyclique","lemma":"encyclique","pos":"NOM"} ,
		{"word":"encycliques","word_nosc":"encycliques","lemma":"encyclique","pos":"NOM"} ,
		{"word":"encyclopédie","word_nosc":"encyclopedie","lemma":"encyclopédie","pos":"NOM"} ,
		{"word":"encyclopédies","word_nosc":"encyclopedies","lemma":"encyclopédie","pos":"NOM"} ,
		{"word":"encyclopédistes","word_nosc":"encyclopedistes","lemma":"encyclopédiste","pos":"NOM"} ,
		{"word":"encéphale","word_nosc":"encephale","lemma":"encéphale","pos":"NOM"} ,
		{"word":"encéphalite","word_nosc":"encephalite","lemma":"encéphalite","pos":"NOM"} ,
		{"word":"encéphalogramme","word_nosc":"encephalogramme","lemma":"encéphalogramme","pos":"NOM"} ,
		{"word":"encéphalographie","word_nosc":"encephalographie","lemma":"encéphalographie","pos":"NOM"} ,
		{"word":"encéphalopathie","word_nosc":"encephalopathie","lemma":"encéphalopathie","pos":"NOM"} ,
		{"word":"endettement","word_nosc":"endettement","lemma":"endettement","pos":"NOM"} ,
		{"word":"endiablé","word_nosc":"endiable","lemma":"endiablé","pos":"NOM"} ,
		{"word":"endiguement","word_nosc":"endiguement","lemma":"endiguement","pos":"NOM"} ,
		{"word":"endimanchement","word_nosc":"endimanchement","lemma":"endimanchement","pos":"NOM"} ,
		{"word":"endive","word_nosc":"endive","lemma":"endive","pos":"NOM"} ,
		{"word":"endives","word_nosc":"endives","lemma":"endive","pos":"NOM"} ,
		{"word":"endocarde","word_nosc":"endocarde","lemma":"endocarde","pos":"NOM"} ,
		{"word":"endocardite","word_nosc":"endocardite","lemma":"endocardite","pos":"NOM"} ,
		{"word":"endocrinologie","word_nosc":"endocrinologie","lemma":"endocrinologie","pos":"NOM"} ,
		{"word":"endocrinologiste","word_nosc":"endocrinologiste","lemma":"endocrinologiste","pos":"NOM"} ,
		{"word":"endocrinologue","word_nosc":"endocrinologue","lemma":"endocrinologue","pos":"NOM"} ,
		{"word":"endoctrinement","word_nosc":"endoctrinement","lemma":"endoctrinement","pos":"NOM"} ,
		{"word":"endogamie","word_nosc":"endogamie","lemma":"endogamie","pos":"NOM"} ,
		{"word":"endolorissement","word_nosc":"endolorissement","lemma":"endolorissement","pos":"NOM"} ,
		{"word":"endommagement","word_nosc":"endommagement","lemma":"endommagement","pos":"NOM"} ,
		{"word":"endommagements","word_nosc":"endommagements","lemma":"endommagement","pos":"NOM"} ,
		{"word":"endomorphine","word_nosc":"endomorphine","lemma":"endomorphine","pos":"NOM"} ,
		{"word":"endométriose","word_nosc":"endometriose","lemma":"endométriose","pos":"NOM"} ,
		{"word":"endométrite","word_nosc":"endometrite","lemma":"endométrite","pos":"NOM"} ,
		{"word":"endormeur","word_nosc":"endormeur","lemma":"endormeur","pos":"NOM"} ,
		{"word":"endormeurs","word_nosc":"endormeurs","lemma":"endormeur","pos":"NOM"} ,
		{"word":"endormissement","word_nosc":"endormissement","lemma":"endormissement","pos":"NOM"} ,
		{"word":"endormissements","word_nosc":"endormissements","lemma":"endormissement","pos":"NOM"} ,
		{"word":"endorphine","word_nosc":"endorphine","lemma":"endorphine","pos":"NOM"} ,
		{"word":"endorphines","word_nosc":"endorphines","lemma":"endorphine","pos":"NOM"} ,
		{"word":"endoscope","word_nosc":"endoscope","lemma":"endoscope","pos":"NOM"} ,
		{"word":"endoscopie","word_nosc":"endoscopie","lemma":"endoscopie","pos":"NOM"} ,
		{"word":"endosquelette","word_nosc":"endosquelette","lemma":"endosquelette","pos":"NOM"} ,
		{"word":"endosse","word_nosc":"endosse","lemma":"endosse","pos":"NOM"} ,
		{"word":"endossement","word_nosc":"endossement","lemma":"endossement","pos":"NOM"} ,
		{"word":"endosses","word_nosc":"endosses","lemma":"endosse","pos":"NOM"} ,
		{"word":"endosseur","word_nosc":"endosseur","lemma":"endosseur","pos":"NOM"} ,
		{"word":"endroit","word_nosc":"endroit","lemma":"endroit","pos":"NOM"} ,
		{"word":"endroits","word_nosc":"endroits","lemma":"endroit","pos":"NOM"} ,
		{"word":"enduit","word_nosc":"enduit","lemma":"enduit","pos":"NOM"} ,
		{"word":"enduits","word_nosc":"enduits","lemma":"enduit","pos":"NOM"} ,
		{"word":"endurance","word_nosc":"endurance","lemma":"endurance","pos":"NOM"} ,
		{"word":"endurcissement","word_nosc":"endurcissement","lemma":"endurcissement","pos":"NOM"} ,
		{"word":"enfance","word_nosc":"enfance","lemma":"enfance","pos":"NOM"} ,
		{"word":"enfances","word_nosc":"enfances","lemma":"enfance","pos":"NOM"} ,
		{"word":"enfant","word_nosc":"enfant","lemma":"enfant","pos":"NOM"} ,
		{"word":"enfant-robot","word_nosc":"enfant-robot","lemma":"enfant-robot","pos":"NOM"} ,
		{"word":"enfant-roi","word_nosc":"enfant-roi","lemma":"enfant-roi","pos":"NOM"} ,
		{"word":"enfantelets","word_nosc":"enfantelets","lemma":"enfantelet","pos":"NOM"} ,
		{"word":"enfantement","word_nosc":"enfantement","lemma":"enfantement","pos":"NOM"} ,
		{"word":"enfantements","word_nosc":"enfantements","lemma":"enfantement","pos":"NOM"} ,
		{"word":"enfantillage","word_nosc":"enfantillage","lemma":"enfantillage","pos":"NOM"} ,
		{"word":"enfantillages","word_nosc":"enfantillages","lemma":"enfantillage","pos":"NOM"} ,
		{"word":"enfants","word_nosc":"enfants","lemma":"enfant","pos":"NOM"} ,
		{"word":"enfants-robots","word_nosc":"enfants-robots","lemma":"enfant-robot","pos":"NOM"} ,
		{"word":"enfants-rois","word_nosc":"enfants-rois","lemma":"enfant-roi","pos":"NOM"} ,
		{"word":"enfançon","word_nosc":"enfancon","lemma":"enfançon","pos":"NOM"} ,
		{"word":"enfançons","word_nosc":"enfancons","lemma":"enfançon","pos":"NOM"} ,
		{"word":"enfer","word_nosc":"enfer","lemma":"enfer","pos":"NOM"} ,
		{"word":"enfermement","word_nosc":"enfermement","lemma":"enfermement","pos":"NOM"} ,
		{"word":"enfers","word_nosc":"enfers","lemma":"enfer","pos":"NOM"} ,
		{"word":"enfilade","word_nosc":"enfilade","lemma":"enfilade","pos":"NOM"} ,
		{"word":"enfilades","word_nosc":"enfilades","lemma":"enfilade","pos":"NOM"} ,
		{"word":"enfilage","word_nosc":"enfilage","lemma":"enfilage","pos":"NOM"} ,
		{"word":"enfileur","word_nosc":"enfileur","lemma":"enfileur","pos":"NOM"} ,
		{"word":"enflure","word_nosc":"enflure","lemma":"enflure","pos":"NOM"} ,
		{"word":"enflures","word_nosc":"enflures","lemma":"enflure","pos":"NOM"} ,
		{"word":"enflé","word_nosc":"enfle","lemma":"enflé","pos":"NOM"} ,
		{"word":"enflée","word_nosc":"enflee","lemma":"enflé","pos":"NOM"} ,
		{"word":"enflées","word_nosc":"enflees","lemma":"enflé","pos":"NOM"} ,
		{"word":"enflés","word_nosc":"enfles","lemma":"enflé","pos":"NOM"} ,
		{"word":"enfoiré","word_nosc":"enfoire","lemma":"enfoiré","pos":"NOM"} ,
		{"word":"enfoirée","word_nosc":"enfoiree","lemma":"enfoiré","pos":"NOM"} ,
		{"word":"enfoirés","word_nosc":"enfoires","lemma":"enfoiré","pos":"NOM"} ,
		{"word":"enfoncement","word_nosc":"enfoncement","lemma":"enfoncement","pos":"NOM"} ,
		{"word":"enfoncements","word_nosc":"enfoncements","lemma":"enfoncement","pos":"NOM"} ,
		{"word":"enfonceur","word_nosc":"enfonceur","lemma":"enfonceur","pos":"NOM"} ,
		{"word":"enfonçage","word_nosc":"enfoncage","lemma":"enfonçage","pos":"NOM"} ,
		{"word":"enfouissement","word_nosc":"enfouissement","lemma":"enfouissement","pos":"NOM"} ,
		{"word":"enfouissements","word_nosc":"enfouissements","lemma":"enfouissement","pos":"NOM"} ,
		{"word":"enfourchement","word_nosc":"enfourchement","lemma":"enfourchement","pos":"NOM"} ,
		{"word":"enfourchure","word_nosc":"enfourchure","lemma":"enfourchure","pos":"NOM"} ,
		{"word":"enfournage","word_nosc":"enfournage","lemma":"enfournage","pos":"NOM"} ,
		{"word":"engageable","word_nosc":"engageable","lemma":"engageable","pos":"NOM"} ,
		{"word":"engagement","word_nosc":"engagement","lemma":"engagement","pos":"NOM"} ,
		{"word":"engagements","word_nosc":"engagements","lemma":"engagement","pos":"NOM"} ,
		{"word":"engagé","word_nosc":"engage","lemma":"engagé","pos":"NOM"} ,
		{"word":"engagés","word_nosc":"engages","lemma":"engagé","pos":"NOM"} ,
		{"word":"engeance","word_nosc":"engeance","lemma":"engeance","pos":"NOM"} ,
		{"word":"engeances","word_nosc":"engeances","lemma":"engeance","pos":"NOM"} ,
		{"word":"engelure","word_nosc":"engelure","lemma":"engelure","pos":"NOM"} ,
		{"word":"engelures","word_nosc":"engelures","lemma":"engelure","pos":"NOM"} ,
		{"word":"engendrement","word_nosc":"engendrement","lemma":"engendrement","pos":"NOM"} ,
		{"word":"engin","word_nosc":"engin","lemma":"engin","pos":"NOM"} ,
		{"word":"engineering","word_nosc":"engineering","lemma":"engineering","pos":"NOM"} ,
		{"word":"engins","word_nosc":"engins","lemma":"engin","pos":"NOM"} ,
		{"word":"engliche","word_nosc":"engliche","lemma":"engliche","pos":"NOM"} ,
		{"word":"engloutissement","word_nosc":"engloutissement","lemma":"engloutissement","pos":"NOM"} ,
		{"word":"engoncement","word_nosc":"engoncement","lemma":"engoncement","pos":"NOM"} ,
		{"word":"engorgement","word_nosc":"engorgement","lemma":"engorgement","pos":"NOM"} ,
		{"word":"engorgements","word_nosc":"engorgements","lemma":"engorgement","pos":"NOM"} ,
		{"word":"engouement","word_nosc":"engouement","lemma":"engouement","pos":"NOM"} ,
		{"word":"engouements","word_nosc":"engouements","lemma":"engouement","pos":"NOM"} ,
		{"word":"engouffrement","word_nosc":"engouffrement","lemma":"engouffrement","pos":"NOM"} ,
		{"word":"engoulevent","word_nosc":"engoulevent","lemma":"engoulevent","pos":"NOM"} ,
		{"word":"engourdissant","word_nosc":"engourdissant","lemma":"engourdissant","pos":"NOM"} ,
		{"word":"engourdissement","word_nosc":"engourdissement","lemma":"engourdissement","pos":"NOM"} ,
		{"word":"engourdissements","word_nosc":"engourdissements","lemma":"engourdissement","pos":"NOM"} ,
		{"word":"engrais","word_nosc":"engrais","lemma":"engrais","pos":"NOM"} ,
		{"word":"engraissement","word_nosc":"engraissement","lemma":"engraissement","pos":"NOM"} ,
		{"word":"engrangement","word_nosc":"engrangement","lemma":"engrangement","pos":"NOM"} ,
		{"word":"engravement","word_nosc":"engravement","lemma":"engravement","pos":"NOM"} ,
		{"word":"engrenage","word_nosc":"engrenage","lemma":"engrenage","pos":"NOM"} ,
		{"word":"engrenages","word_nosc":"engrenages","lemma":"engrenage","pos":"NOM"} ,
		{"word":"engueulade","word_nosc":"engueulade","lemma":"engueulade","pos":"NOM"} ,
		{"word":"engueulades","word_nosc":"engueulades","lemma":"engueulade","pos":"NOM"} ,
		{"word":"enhardissement","word_nosc":"enhardissement","lemma":"enhardissement","pos":"NOM"} ,
		{"word":"enivrement","word_nosc":"enivrement","lemma":"enivrement","pos":"NOM"} ,
		{"word":"enjambements","word_nosc":"enjambements","lemma":"enjambement","pos":"NOM"} ,
		{"word":"enjambeur","word_nosc":"enjambeur","lemma":"enjambeur","pos":"NOM"} ,
		{"word":"enjambeurs","word_nosc":"enjambeurs","lemma":"enjambeur","pos":"NOM"} ,
		{"word":"enjambée","word_nosc":"enjambee","lemma":"enjambée","pos":"NOM"} ,
		{"word":"enjambées","word_nosc":"enjambees","lemma":"enjambée","pos":"NOM"} ,
		{"word":"enjeu","word_nosc":"enjeu","lemma":"enjeu","pos":"NOM"} ,
		{"word":"enjeux","word_nosc":"enjeux","lemma":"enjeu","pos":"NOM"} ,
		{"word":"enjolivements","word_nosc":"enjolivements","lemma":"enjolivement","pos":"NOM"} ,
		{"word":"enjoliveur","word_nosc":"enjoliveur","lemma":"enjoliveur","pos":"NOM"} ,
		{"word":"enjoliveurs","word_nosc":"enjoliveurs","lemma":"enjoliveur","pos":"NOM"} ,
		{"word":"enjouement","word_nosc":"enjouement","lemma":"enjouement","pos":"NOM"} ,
		{"word":"enjôlement","word_nosc":"enjolement","lemma":"enjôlement","pos":"NOM"} ,
		{"word":"enjôleur","word_nosc":"enjoleur","lemma":"enjôleur","pos":"NOM"} ,
		{"word":"enjôleurs","word_nosc":"enjoleurs","lemma":"enjôleur","pos":"NOM"} ,
		{"word":"enjôleuse","word_nosc":"enjoleuse","lemma":"enjôleur","pos":"NOM"} ,
		{"word":"enlacement","word_nosc":"enlacement","lemma":"enlacement","pos":"NOM"} ,
		{"word":"enlacements","word_nosc":"enlacements","lemma":"enlacement","pos":"NOM"} ,
		{"word":"enlaidissement","word_nosc":"enlaidissement","lemma":"enlaidissement","pos":"NOM"} ,
		{"word":"enlisement","word_nosc":"enlisement","lemma":"enlisement","pos":"NOM"} ,
		{"word":"enlumineur","word_nosc":"enlumineur","lemma":"enlumineur","pos":"NOM"} ,
		{"word":"enlumineurs","word_nosc":"enlumineurs","lemma":"enlumineur","pos":"NOM"} ,
		{"word":"enluminure","word_nosc":"enluminure","lemma":"enluminure","pos":"NOM"} ,
		{"word":"enluminures","word_nosc":"enluminures","lemma":"enluminure","pos":"NOM"} ,
		{"word":"enlèvement","word_nosc":"enlevement","lemma":"enlèvement","pos":"NOM"} ,
		{"word":"enlèvements","word_nosc":"enlevements","lemma":"enlèvement","pos":"NOM"} ,
		{"word":"enneigement","word_nosc":"enneigement","lemma":"enneigement","pos":"NOM"} ,
		{"word":"ennemi","word_nosc":"ennemi","lemma":"ennemi","pos":"NOM"} ,
		{"word":"ennemie","word_nosc":"ennemie","lemma":"ennemi","pos":"NOM"} ,
		{"word":"ennemies","word_nosc":"ennemies","lemma":"ennemi","pos":"NOM"} ,
		{"word":"ennemis","word_nosc":"ennemis","lemma":"ennemi","pos":"NOM"} ,
		{"word":"ennoblissement","word_nosc":"ennoblissement","lemma":"ennoblissement","pos":"NOM"} ,
		{"word":"ennui","word_nosc":"ennui","lemma":"ennui","pos":"NOM"} ,
		{"word":"ennuis","word_nosc":"ennuis","lemma":"ennui","pos":"NOM"} ,
		{"word":"enquiquinement","word_nosc":"enquiquinement","lemma":"enquiquinement","pos":"NOM"} ,
		{"word":"enquiquineur","word_nosc":"enquiquineur","lemma":"enquiquineur","pos":"NOM"} ,
		{"word":"enquiquineurs","word_nosc":"enquiquineurs","lemma":"enquiquineur","pos":"NOM"} ,
		{"word":"enquiquineuse","word_nosc":"enquiquineuse","lemma":"enquiquineur","pos":"NOM"} ,
		{"word":"enquête","word_nosc":"enquete","lemma":"enquête","pos":"NOM"} ,
		{"word":"enquêtes","word_nosc":"enquetes","lemma":"enquête","pos":"NOM"} ,
		{"word":"enquêtes-minute","word_nosc":"enquetes-minute","lemma":"enquête-minute","pos":"NOM"} ,
		{"word":"enquêteur","word_nosc":"enqueteur","lemma":"enquêteur","pos":"NOM"} ,
		{"word":"enquêteurs","word_nosc":"enqueteurs","lemma":"enquêteur","pos":"NOM"} ,
		{"word":"enquêteuse","word_nosc":"enqueteuse","lemma":"enquêteur","pos":"NOM"} ,
		{"word":"enquêtrices","word_nosc":"enquetrices","lemma":"enquêteur","pos":"NOM"} ,
		{"word":"enracinement","word_nosc":"enracinement","lemma":"enracinement","pos":"NOM"} ,
		{"word":"enracinements","word_nosc":"enracinements","lemma":"enracinement","pos":"NOM"} ,
		{"word":"enragement","word_nosc":"enragement","lemma":"enragement","pos":"NOM"} ,
		{"word":"enragé","word_nosc":"enrage","lemma":"enragé","pos":"NOM"} ,
		{"word":"enragée","word_nosc":"enragee","lemma":"enragé","pos":"NOM"} ,
		{"word":"enragées","word_nosc":"enragees","lemma":"enragé","pos":"NOM"} ,
		{"word":"enragés","word_nosc":"enrages","lemma":"enragé","pos":"NOM"} ,
		{"word":"enrayage","word_nosc":"enrayage","lemma":"enrayage","pos":"NOM"} ,
		{"word":"enrayement","word_nosc":"enrayement","lemma":"enrayement","pos":"NOM"} ,
		{"word":"enregistrement","word_nosc":"enregistrement","lemma":"enregistrement","pos":"NOM"} ,
		{"word":"enregistrements","word_nosc":"enregistrements","lemma":"enregistrement","pos":"NOM"} ,
		{"word":"enregistreur","word_nosc":"enregistreur","lemma":"enregistreur","pos":"NOM"} ,
		{"word":"enregistreurs","word_nosc":"enregistreurs","lemma":"enregistreur","pos":"NOM"} ,
		{"word":"enregistreuse","word_nosc":"enregistreuse","lemma":"enregistreur","pos":"NOM"} ,
		{"word":"enrichissement","word_nosc":"enrichissement","lemma":"enrichissement","pos":"NOM"} ,
		{"word":"enrichissements","word_nosc":"enrichissements","lemma":"enrichissement","pos":"NOM"} ,
		{"word":"enrobage","word_nosc":"enrobage","lemma":"enrobage","pos":"NOM"} ,
		{"word":"enrobé","word_nosc":"enrobe","lemma":"enrobé","pos":"NOM"} ,
		{"word":"enrobés","word_nosc":"enrobes","lemma":"enrobé","pos":"NOM"} ,
		{"word":"enrochements","word_nosc":"enrochements","lemma":"enrochement","pos":"NOM"} ,
		{"word":"enrouement","word_nosc":"enrouement","lemma":"enrouement","pos":"NOM"} ,
		{"word":"enrouements","word_nosc":"enrouements","lemma":"enrouement","pos":"NOM"} ,
		{"word":"enroulage","word_nosc":"enroulage","lemma":"enroulage","pos":"NOM"} ,
		{"word":"enroulement","word_nosc":"enroulement","lemma":"enroulement","pos":"NOM"} ,
		{"word":"enroulements","word_nosc":"enroulements","lemma":"enroulement","pos":"NOM"} ,
		{"word":"enrôlement","word_nosc":"enrolement","lemma":"enrôlement","pos":"NOM"} ,
		{"word":"enrôlements","word_nosc":"enrolements","lemma":"enrôlement","pos":"NOM"} ,
		{"word":"enrôlé","word_nosc":"enrole","lemma":"enrôlé","pos":"NOM"} ,
		{"word":"enrôlés","word_nosc":"enroles","lemma":"enrôlé","pos":"NOM"} ,
		{"word":"ensablement","word_nosc":"ensablement","lemma":"ensablement","pos":"NOM"} ,
		{"word":"ensanglantement","word_nosc":"ensanglantement","lemma":"ensanglantement","pos":"NOM"} ,
		{"word":"ensauvagement","word_nosc":"ensauvagement","lemma":"ensauvagement","pos":"NOM"} ,
		{"word":"enseignant","word_nosc":"enseignant","lemma":"enseignant","pos":"NOM"} ,
		{"word":"enseignant-robot","word_nosc":"enseignant-robot","lemma":"enseignant-robot","pos":"NOM"} ,
		{"word":"enseignante","word_nosc":"enseignante","lemma":"enseignant","pos":"NOM"} ,
		{"word":"enseignantes","word_nosc":"enseignantes","lemma":"enseignant","pos":"NOM"} ,
		{"word":"enseignants","word_nosc":"enseignants","lemma":"enseignant","pos":"NOM"} ,
		{"word":"enseigne","word_nosc":"enseigne","lemma":"enseigne","pos":"NOM"} ,
		{"word":"enseignement","word_nosc":"enseignement","lemma":"enseignement","pos":"NOM"} ,
		{"word":"enseignements","word_nosc":"enseignements","lemma":"enseignement","pos":"NOM"} ,
		{"word":"enseignes","word_nosc":"enseignes","lemma":"enseigne","pos":"NOM"} ,
		{"word":"enseigneur","word_nosc":"enseigneur","lemma":"enseigneur","pos":"NOM"} ,
		{"word":"enseigné","word_nosc":"enseigne","lemma":"enseigné","pos":"NOM"} ,
		{"word":"ensellement","word_nosc":"ensellement","lemma":"ensellement","pos":"NOM"} ,
		{"word":"ensellure","word_nosc":"ensellure","lemma":"ensellure","pos":"NOM"} ,
		{"word":"ensemble","word_nosc":"ensemble","lemma":"ensemble","pos":"NOM"} ,
		{"word":"ensembles","word_nosc":"ensembles","lemma":"ensemble","pos":"NOM"} ,
		{"word":"ensemencement","word_nosc":"ensemencement","lemma":"ensemencement","pos":"NOM"} ,
		{"word":"ensemencements","word_nosc":"ensemencements","lemma":"ensemencement","pos":"NOM"} ,
		{"word":"ensevelissement","word_nosc":"ensevelissement","lemma":"ensevelissement","pos":"NOM"} ,
		{"word":"ensevelisseuse","word_nosc":"ensevelisseuse","lemma":"ensevelisseur","pos":"NOM"} ,
		{"word":"ensoleillement","word_nosc":"ensoleillement","lemma":"ensoleillement","pos":"NOM"} ,
		{"word":"ensorceleur","word_nosc":"ensorceleur","lemma":"ensorceleur","pos":"NOM"} ,
		{"word":"ensorceleuse","word_nosc":"ensorceleuse","lemma":"ensorceleur","pos":"NOM"} ,
		{"word":"ensorceleuses","word_nosc":"ensorceleuses","lemma":"ensorceleur","pos":"NOM"} ,
		{"word":"ensorcellement","word_nosc":"ensorcellement","lemma":"ensorcellement","pos":"NOM"} ,
		{"word":"ensouple","word_nosc":"ensouple","lemma":"ensouple","pos":"NOM"} ,
		{"word":"entablement","word_nosc":"entablement","lemma":"entablement","pos":"NOM"} ,
		{"word":"entaille","word_nosc":"entaille","lemma":"entaille","pos":"NOM"} ,
		{"word":"entailles","word_nosc":"entailles","lemma":"entaille","pos":"NOM"} ,
		{"word":"entame","word_nosc":"entame","lemma":"entame","pos":"NOM"} ,
		{"word":"entames","word_nosc":"entames","lemma":"entame","pos":"NOM"} ,
		{"word":"entassement","word_nosc":"entassement","lemma":"entassement","pos":"NOM"} ,
		{"word":"entassements","word_nosc":"entassements","lemma":"entassement","pos":"NOM"} ,
		{"word":"ente","word_nosc":"ente","lemma":"ente","pos":"NOM"} ,
		{"word":"entement","word_nosc":"entement","lemma":"entement","pos":"NOM"} ,
		{"word":"entendant","word_nosc":"entendant","lemma":"entendant","pos":"NOM"} ,
		{"word":"entendement","word_nosc":"entendement","lemma":"entendement","pos":"NOM"} ,
		{"word":"entendeur","word_nosc":"entendeur","lemma":"entendeur","pos":"NOM"} ,
		{"word":"entendeurs","word_nosc":"entendeurs","lemma":"entendeur","pos":"NOM"} ,
		{"word":"entente","word_nosc":"entente","lemma":"entente","pos":"NOM"} ,
		{"word":"ententes","word_nosc":"ententes","lemma":"entente","pos":"NOM"} ,
		{"word":"enterrement","word_nosc":"enterrement","lemma":"enterrement","pos":"NOM"} ,
		{"word":"enterrements","word_nosc":"enterrements","lemma":"enterrement","pos":"NOM"} ,
		{"word":"enterreur","word_nosc":"enterreur","lemma":"enterreur","pos":"NOM"} ,
		{"word":"enthalpie","word_nosc":"enthalpie","lemma":"enthalpie","pos":"NOM"} ,
		{"word":"enthousiasme","word_nosc":"enthousiasme","lemma":"enthousiasme","pos":"NOM"} ,
		{"word":"enthousiasmes","word_nosc":"enthousiasmes","lemma":"enthousiasme","pos":"NOM"} ,
		{"word":"enthousiaste","word_nosc":"enthousiaste","lemma":"enthousiaste","pos":"NOM"} ,
		{"word":"enthousiastes","word_nosc":"enthousiastes","lemma":"enthousiaste","pos":"NOM"} ,
		{"word":"entichement","word_nosc":"entichement","lemma":"entichement","pos":"NOM"} ,
		{"word":"entier","word_nosc":"entier","lemma":"entier","pos":"NOM"} ,
		{"word":"entiers","word_nosc":"entiers","lemma":"entier","pos":"NOM"} ,
		{"word":"entité","word_nosc":"entite","lemma":"entité","pos":"NOM"} ,
		{"word":"entités","word_nosc":"entites","lemma":"entité","pos":"NOM"} ,
		{"word":"entièreté","word_nosc":"entierete","lemma":"entièreté","pos":"NOM"} ,
		{"word":"entoilage","word_nosc":"entoilage","lemma":"entoilage","pos":"NOM"} ,
		{"word":"entolome","word_nosc":"entolome","lemma":"entolome","pos":"NOM"} ,
		{"word":"entolomes","word_nosc":"entolomes","lemma":"entolome","pos":"NOM"} ,
		{"word":"entomologie","word_nosc":"entomologie","lemma":"entomologie","pos":"NOM"} ,
		{"word":"entomologiste","word_nosc":"entomologiste","lemma":"entomologiste","pos":"NOM"} ,
		{"word":"entomologistes","word_nosc":"entomologistes","lemma":"entomologiste","pos":"NOM"} ,
		{"word":"entonnoir","word_nosc":"entonnoir","lemma":"entonnoir","pos":"NOM"} ,
		{"word":"entonnoirs","word_nosc":"entonnoirs","lemma":"entonnoir","pos":"NOM"} ,
		{"word":"entorse","word_nosc":"entorse","lemma":"entorse","pos":"NOM"} ,
		{"word":"entorses","word_nosc":"entorses","lemma":"entorse","pos":"NOM"} ,
		{"word":"entortillage","word_nosc":"entortillage","lemma":"entortillage","pos":"NOM"} ,
		{"word":"entortillages","word_nosc":"entortillages","lemma":"entortillage","pos":"NOM"} ,
		{"word":"entortillement","word_nosc":"entortillement","lemma":"entortillement","pos":"NOM"} ,
		{"word":"entour","word_nosc":"entour","lemma":"entour","pos":"NOM"} ,
		{"word":"entourage","word_nosc":"entourage","lemma":"entourage","pos":"NOM"} ,
		{"word":"entourages","word_nosc":"entourages","lemma":"entourage","pos":"NOM"} ,
		{"word":"entourloupe","word_nosc":"entourloupe","lemma":"entourloupe","pos":"NOM"} ,
		{"word":"entourloupes","word_nosc":"entourloupes","lemma":"entourloupe","pos":"NOM"} ,
		{"word":"entourloupette","word_nosc":"entourloupette","lemma":"entourloupette","pos":"NOM"} ,
		{"word":"entourloupettes","word_nosc":"entourloupettes","lemma":"entourloupette","pos":"NOM"} ,
		{"word":"entournures","word_nosc":"entournures","lemma":"entournure","pos":"NOM"} ,
		{"word":"entours","word_nosc":"entours","lemma":"entour","pos":"NOM"} ,
		{"word":"entr'acte","word_nosc":"entr'acte","lemma":"entr'acte","pos":"NOM"} ,
		{"word":"entr'actes","word_nosc":"entr'actes","lemma":"entr'acte","pos":"NOM"} ,
		{"word":"entracte","word_nosc":"entracte","lemma":"entracte","pos":"NOM"} ,
		{"word":"entractes","word_nosc":"entractes","lemma":"entracte","pos":"NOM"} ,
		{"word":"entraide","word_nosc":"entraide","lemma":"entraide","pos":"NOM"} ,
		{"word":"entrailles","word_nosc":"entrailles","lemma":"entrailles","pos":"NOM"} ,
		{"word":"entrain","word_nosc":"entrain","lemma":"entrain","pos":"NOM"} ,
		{"word":"entrant","word_nosc":"entrant","lemma":"entrant","pos":"NOM"} ,
		{"word":"entrants","word_nosc":"entrants","lemma":"entrant","pos":"NOM"} ,
		{"word":"entrave","word_nosc":"entrave","lemma":"entrave","pos":"NOM"} ,
		{"word":"entraves","word_nosc":"entraves","lemma":"entrave","pos":"NOM"} ,
		{"word":"entraînement","word_nosc":"entrainement","lemma":"entraînement","pos":"NOM"} ,
		{"word":"entraînements","word_nosc":"entrainements","lemma":"entraînement","pos":"NOM"} ,
		{"word":"entraîneur","word_nosc":"entraineur","lemma":"entraîneur","pos":"NOM"} ,
		{"word":"entraîneurs","word_nosc":"entraineurs","lemma":"entraîneur","pos":"NOM"} ,
		{"word":"entraîneuse","word_nosc":"entraineuse","lemma":"entraîneur","pos":"NOM"} ,
		{"word":"entraîneuses","word_nosc":"entraineuses","lemma":"entraîneur","pos":"NOM"} ,
		{"word":"entre-deux","word_nosc":"entre-deux","lemma":"entre-deux","pos":"NOM"} ,
		{"word":"entre-deux-guerres","word_nosc":"entre-deux-guerres","lemma":"entre-deux-guerres","pos":"NOM"} ,
		{"word":"entre-jambe","word_nosc":"entre-jambe","lemma":"entre-jambe","pos":"NOM"} ,
		{"word":"entre-jambes","word_nosc":"entre-jambes","lemma":"entre-jambes","pos":"NOM"} ,
		{"word":"entre-rail","word_nosc":"entre-rail","lemma":"entre-rail","pos":"NOM"} ,
		{"word":"entrebâillement","word_nosc":"entrebaillement","lemma":"entrebâillement","pos":"NOM"} ,
		{"word":"entrebâilleur","word_nosc":"entrebailleur","lemma":"entrebâilleur","pos":"NOM"} ,
		{"word":"entrechat","word_nosc":"entrechat","lemma":"entrechat","pos":"NOM"} ,
		{"word":"entrechats","word_nosc":"entrechats","lemma":"entrechat","pos":"NOM"} ,
		{"word":"entrechoc","word_nosc":"entrechoc","lemma":"entrechoc","pos":"NOM"} ,
		{"word":"entrechocs","word_nosc":"entrechocs","lemma":"entrechoc","pos":"NOM"} ,
		{"word":"entrechoquement","word_nosc":"entrechoquement","lemma":"entrechoquement","pos":"NOM"} ,
		{"word":"entrecroisement","word_nosc":"entrecroisement","lemma":"entrecroisement","pos":"NOM"} ,
		{"word":"entrecroisements","word_nosc":"entrecroisements","lemma":"entrecroisement","pos":"NOM"} ,
		{"word":"entrecuisse","word_nosc":"entrecuisse","lemma":"entrecuisse","pos":"NOM"} ,
		{"word":"entrecôte","word_nosc":"entrecote","lemma":"entrecôte","pos":"NOM"} ,
		{"word":"entrecôtes","word_nosc":"entrecotes","lemma":"entrecôte","pos":"NOM"} ,
		{"word":"entrefaites","word_nosc":"entrefaites","lemma":"entrefaite","pos":"NOM"} ,
		{"word":"entrefilet","word_nosc":"entrefilet","lemma":"entrefilet","pos":"NOM"} ,
		{"word":"entrefilets","word_nosc":"entrefilets","lemma":"entrefilet","pos":"NOM"} ,
		{"word":"entregent","word_nosc":"entregent","lemma":"entregent","pos":"NOM"} ,
		{"word":"entrejambe","word_nosc":"entrejambe","lemma":"entrejambe","pos":"NOM"} ,
		{"word":"entrejambes","word_nosc":"entrejambes","lemma":"entrejambe","pos":"NOM"} ,
		{"word":"entrelacement","word_nosc":"entrelacement","lemma":"entrelacement","pos":"NOM"} ,
		{"word":"entrelacs","word_nosc":"entrelacs","lemma":"entrelacs","pos":"NOM"} ,
		{"word":"entremets","word_nosc":"entremets","lemma":"entremets","pos":"NOM"} ,
		{"word":"entremetteur","word_nosc":"entremetteur","lemma":"entremetteur","pos":"NOM"} ,
		{"word":"entremetteurs","word_nosc":"entremetteurs","lemma":"entremetteur","pos":"NOM"} ,
		{"word":"entremetteuse","word_nosc":"entremetteuse","lemma":"entremetteur","pos":"NOM"} ,
		{"word":"entremise","word_nosc":"entremise","lemma":"entremise","pos":"NOM"} ,
		{"word":"entremêlement","word_nosc":"entremelement","lemma":"entremêlement","pos":"NOM"} ,
		{"word":"entrepont","word_nosc":"entrepont","lemma":"entrepont","pos":"NOM"} ,
		{"word":"entreponts","word_nosc":"entreponts","lemma":"entrepont","pos":"NOM"} ,
		{"word":"entreposage","word_nosc":"entreposage","lemma":"entreposage","pos":"NOM"} ,
		{"word":"entrepreneur","word_nosc":"entrepreneur","lemma":"entrepreneur","pos":"NOM"} ,
		{"word":"entrepreneurs","word_nosc":"entrepreneurs","lemma":"entrepreneur","pos":"NOM"} ,
		{"word":"entrepreneuse","word_nosc":"entrepreneuse","lemma":"entrepreneur","pos":"NOM"} ,
		{"word":"entrepreneuses","word_nosc":"entrepreneuses","lemma":"entrepreneur","pos":"NOM"} ,
		{"word":"entreprise","word_nosc":"entreprise","lemma":"entreprise","pos":"NOM"} ,
		{"word":"entreprises","word_nosc":"entreprises","lemma":"entreprise","pos":"NOM"} ,
		{"word":"entrepôt","word_nosc":"entrepot","lemma":"entrepôt","pos":"NOM"} ,
		{"word":"entrepôts","word_nosc":"entrepots","lemma":"entrepôt","pos":"NOM"} ,
		{"word":"entresol","word_nosc":"entresol","lemma":"entresol","pos":"NOM"} ,
		{"word":"entresols","word_nosc":"entresols","lemma":"entresol","pos":"NOM"} ,
		{"word":"entreteneur","word_nosc":"entreteneur","lemma":"entreteneur","pos":"NOM"} ,
		{"word":"entreteneuse","word_nosc":"entreteneuse","lemma":"entreteneur","pos":"NOM"} ,
		{"word":"entretien","word_nosc":"entretien","lemma":"entretien","pos":"NOM"} ,
		{"word":"entretiens","word_nosc":"entretiens","lemma":"entretien","pos":"NOM"} ,
		{"word":"entretoise","word_nosc":"entretoise","lemma":"entretoise","pos":"NOM"} ,
		{"word":"entretoisement","word_nosc":"entretoisement","lemma":"entretoisement","pos":"NOM"} ,
		{"word":"entrevous","word_nosc":"entrevous","lemma":"entrevous","pos":"NOM"} ,
		{"word":"entrevue","word_nosc":"entrevue","lemma":"entrevue","pos":"NOM"} ,
		{"word":"entrevues","word_nosc":"entrevues","lemma":"entrevue","pos":"NOM"} ,
		{"word":"entrisme","word_nosc":"entrisme","lemma":"entrisme","pos":"NOM"} ,
		{"word":"entropie","word_nosc":"entropie","lemma":"entropie","pos":"NOM"} ,
		{"word":"entrouverture","word_nosc":"entrouverture","lemma":"entrouverture","pos":"NOM"} ,
		{"word":"entrée","word_nosc":"entree","lemma":"entrée","pos":"NOM"} ,
		{"word":"entrée-sortie","word_nosc":"entree-sortie","lemma":"entrée-sortie","pos":"NOM"} ,
		{"word":"entrées","word_nosc":"entrees","lemma":"entrée","pos":"NOM"} ,
		{"word":"enture","word_nosc":"enture","lemma":"enture","pos":"NOM"} ,
		{"word":"entéléchies","word_nosc":"entelechies","lemma":"entéléchie","pos":"NOM"} ,
		{"word":"entérinement","word_nosc":"enterinement","lemma":"entérinement","pos":"NOM"} ,
		{"word":"entérite","word_nosc":"enterite","lemma":"entérite","pos":"NOM"} ,
		{"word":"entérocolite","word_nosc":"enterocolite","lemma":"entérocolite","pos":"NOM"} ,
		{"word":"entérovirus","word_nosc":"enterovirus","lemma":"entérovirus","pos":"NOM"} ,
		{"word":"entêtement","word_nosc":"entetement","lemma":"entêtement","pos":"NOM"} ,
		{"word":"entêtements","word_nosc":"entetements","lemma":"entêtement","pos":"NOM"} ,
		{"word":"entêté","word_nosc":"entete","lemma":"entêté","pos":"NOM"} ,
		{"word":"entêtée","word_nosc":"entetee","lemma":"entêté","pos":"NOM"} ,
		{"word":"entêtés","word_nosc":"entetes","lemma":"entêté","pos":"NOM"} ,
		{"word":"entôlage","word_nosc":"entolage","lemma":"entôlage","pos":"NOM"} ,
		{"word":"entôleur","word_nosc":"entoleur","lemma":"entôleur","pos":"NOM"} ,
		{"word":"envahissement","word_nosc":"envahissement","lemma":"envahissement","pos":"NOM"} ,
		{"word":"envahissements","word_nosc":"envahissements","lemma":"envahissement","pos":"NOM"} ,
		{"word":"envahisseur","word_nosc":"envahisseur","lemma":"envahisseur","pos":"NOM"} ,
		{"word":"envahisseurs","word_nosc":"envahisseurs","lemma":"envahisseur","pos":"NOM"} ,
		{"word":"envasement","word_nosc":"envasement","lemma":"envasement","pos":"NOM"} ,
		{"word":"enveloppe","word_nosc":"enveloppe","lemma":"enveloppe","pos":"NOM"} ,
		{"word":"enveloppement","word_nosc":"enveloppement","lemma":"enveloppement","pos":"NOM"} ,
		{"word":"enveloppements","word_nosc":"enveloppements","lemma":"enveloppement","pos":"NOM"} ,
		{"word":"enveloppes","word_nosc":"enveloppes","lemma":"enveloppe","pos":"NOM"} ,
		{"word":"enveloppeur","word_nosc":"enveloppeur","lemma":"enveloppeur","pos":"NOM"} ,
		{"word":"enveloppé","word_nosc":"enveloppe","lemma":"enveloppé","pos":"NOM"} ,
		{"word":"enveloppée","word_nosc":"enveloppee","lemma":"enveloppé","pos":"NOM"} ,
		{"word":"enveloppés","word_nosc":"enveloppes","lemma":"enveloppé","pos":"NOM"} ,
		{"word":"envergure","word_nosc":"envergure","lemma":"envergure","pos":"NOM"} ,
		{"word":"envers","word_nosc":"envers","lemma":"envers","pos":"NOM"} ,
		{"word":"enviandé","word_nosc":"enviande","lemma":"enviandé","pos":"NOM"} ,
		{"word":"enviandés","word_nosc":"enviandes","lemma":"enviandé","pos":"NOM"} ,
		{"word":"envie","word_nosc":"envie","lemma":"envie","pos":"NOM"} ,
		{"word":"envies","word_nosc":"envies","lemma":"envie","pos":"NOM"} ,
		{"word":"envieuse","word_nosc":"envieuse","lemma":"envieux","pos":"NOM"} ,
		{"word":"envieuses","word_nosc":"envieuses","lemma":"envieux","pos":"NOM"} ,
		{"word":"envieux","word_nosc":"envieux","lemma":"envieux","pos":"NOM"} ,
		{"word":"environnement","word_nosc":"environnement","lemma":"environnement","pos":"NOM"} ,
		{"word":"environnements","word_nosc":"environnements","lemma":"environnement","pos":"NOM"} ,
		{"word":"environs","word_nosc":"environs","lemma":"environ","pos":"NOM"} ,
		{"word":"envoi","word_nosc":"envoi","lemma":"envoi","pos":"NOM"} ,
		{"word":"envois","word_nosc":"envois","lemma":"envoi","pos":"NOM"} ,
		{"word":"envol","word_nosc":"envol","lemma":"envol","pos":"NOM"} ,
		{"word":"envols","word_nosc":"envols","lemma":"envol","pos":"NOM"} ,
		{"word":"envolée","word_nosc":"envolee","lemma":"envolée","pos":"NOM"} ,
		{"word":"envolées","word_nosc":"envolees","lemma":"envolée","pos":"NOM"} ,
		{"word":"envoyeur","word_nosc":"envoyeur","lemma":"envoyeur","pos":"NOM"} ,
		{"word":"envoyeurs","word_nosc":"envoyeurs","lemma":"envoyeur","pos":"NOM"} ,
		{"word":"envoyé","word_nosc":"envoye","lemma":"envoyé","pos":"NOM"} ,
		{"word":"envoyée","word_nosc":"envoyee","lemma":"envoyé","pos":"NOM"} ,
		{"word":"envoyées","word_nosc":"envoyees","lemma":"envoyé","pos":"NOM"} ,
		{"word":"envoyés","word_nosc":"envoyes","lemma":"envoyé","pos":"NOM"} ,
		{"word":"envoûtement","word_nosc":"envoutement","lemma":"envoûtement","pos":"NOM"} ,
		{"word":"envoûtements","word_nosc":"envoutements","lemma":"envoûtement","pos":"NOM"} ,
		{"word":"envoûteur","word_nosc":"envouteur","lemma":"envoûteur","pos":"NOM"} ,
		{"word":"envoûteurs","word_nosc":"envouteurs","lemma":"envoûteur","pos":"NOM"} ,
		{"word":"enzyme","word_nosc":"enzyme","lemma":"enzyme","pos":"NOM"} ,
		{"word":"enzymes","word_nosc":"enzymes","lemma":"enzyme","pos":"NOM"} ,
		{"word":"epsilon","word_nosc":"epsilon","lemma":"epsilon","pos":"NOM"} ,
		{"word":"erg","word_nosc":"erg","lemma":"erg","pos":"NOM"} ,
		{"word":"ergastule","word_nosc":"ergastule","lemma":"ergastule","pos":"NOM"} ,
		{"word":"ergastules","word_nosc":"ergastules","lemma":"ergastule","pos":"NOM"} ,
		{"word":"ergol","word_nosc":"ergol","lemma":"ergol","pos":"NOM"} ,
		{"word":"ergonomie","word_nosc":"ergonomie","lemma":"ergonomie","pos":"NOM"} ,
		{"word":"ergot","word_nosc":"ergot","lemma":"ergot","pos":"NOM"} ,
		{"word":"ergotage","word_nosc":"ergotage","lemma":"ergotage","pos":"NOM"} ,
		{"word":"ergotamine","word_nosc":"ergotamine","lemma":"ergotamine","pos":"NOM"} ,
		{"word":"ergoteur","word_nosc":"ergoteur","lemma":"ergoteur","pos":"NOM"} ,
		{"word":"ergothérapeute","word_nosc":"ergotherapeute","lemma":"ergothérapeute","pos":"NOM"} ,
		{"word":"ergothérapie","word_nosc":"ergotherapie","lemma":"ergothérapie","pos":"NOM"} ,
		{"word":"ergots","word_nosc":"ergots","lemma":"ergot","pos":"NOM"} ,
		{"word":"ergs","word_nosc":"ergs","lemma":"erg","pos":"NOM"} ,
		{"word":"ermitage","word_nosc":"ermitage","lemma":"ermitage","pos":"NOM"} ,
		{"word":"ermitages","word_nosc":"ermitages","lemma":"ermitage","pos":"NOM"} ,
		{"word":"ermite","word_nosc":"ermite","lemma":"ermite","pos":"NOM"} ,
		{"word":"ermites","word_nosc":"ermites","lemma":"ermite","pos":"NOM"} ,
		{"word":"erpétologiste","word_nosc":"erpetologiste","lemma":"erpétologiste","pos":"NOM"} ,
		{"word":"errance","word_nosc":"errance","lemma":"errance","pos":"NOM"} ,
		{"word":"errances","word_nosc":"errances","lemma":"errance","pos":"NOM"} ,
		{"word":"errata","word_nosc":"errata","lemma":"erratum","pos":"NOM"} ,
		{"word":"erratum","word_nosc":"erratum","lemma":"erratum","pos":"NOM"} ,
		{"word":"erre","word_nosc":"erre","lemma":"erre","pos":"NOM"} ,
		{"word":"errements","word_nosc":"errements","lemma":"errements","pos":"NOM"} ,
		{"word":"erreur","word_nosc":"erreur","lemma":"erreur","pos":"NOM"} ,
		{"word":"erreurs","word_nosc":"erreurs","lemma":"erreur","pos":"NOM"} ,
		{"word":"ers","word_nosc":"ers","lemma":"ers","pos":"NOM"} ,
		{"word":"ersatz","word_nosc":"ersatz","lemma":"ersatz","pos":"NOM"} ,
		{"word":"esbroufe","word_nosc":"esbroufe","lemma":"esbroufe","pos":"NOM"} ,
		{"word":"esbroufeur","word_nosc":"esbroufeur","lemma":"esbroufeur","pos":"NOM"} ,
		{"word":"esbroufeurs","word_nosc":"esbroufeurs","lemma":"esbroufeur","pos":"NOM"} ,
		{"word":"escabeau","word_nosc":"escabeau","lemma":"escabeau","pos":"NOM"} ,
		{"word":"escabeaux","word_nosc":"escabeaux","lemma":"escabeau","pos":"NOM"} ,
		{"word":"escabèches","word_nosc":"escabeches","lemma":"escabèche","pos":"NOM"} ,
		{"word":"escadre","word_nosc":"escadre","lemma":"escadre","pos":"NOM"} ,
		{"word":"escadres","word_nosc":"escadres","lemma":"escadre","pos":"NOM"} ,
		{"word":"escadrille","word_nosc":"escadrille","lemma":"escadrille","pos":"NOM"} ,
		{"word":"escadrilles","word_nosc":"escadrilles","lemma":"escadrille","pos":"NOM"} ,
		{"word":"escadrin","word_nosc":"escadrin","lemma":"escadrin","pos":"NOM"} ,
		{"word":"escadron","word_nosc":"escadron","lemma":"escadron","pos":"NOM"} ,
		{"word":"escadrons","word_nosc":"escadrons","lemma":"escadron","pos":"NOM"} ,
		{"word":"escalade","word_nosc":"escalade","lemma":"escalade","pos":"NOM"} ,
		{"word":"escalades","word_nosc":"escalades","lemma":"escalade","pos":"NOM"} ,
		{"word":"escaladeur","word_nosc":"escaladeur","lemma":"escaladeur","pos":"NOM"} ,
		{"word":"escaladeuse","word_nosc":"escaladeuse","lemma":"escaladeur","pos":"NOM"} ,
		{"word":"escaladeuses","word_nosc":"escaladeuses","lemma":"escaladeur","pos":"NOM"} ,
		{"word":"escalator","word_nosc":"escalator","lemma":"escalator","pos":"NOM"} ,
		{"word":"escalators","word_nosc":"escalators","lemma":"escalator","pos":"NOM"} ,
		{"word":"escale","word_nosc":"escale","lemma":"escale","pos":"NOM"} ,
		{"word":"escales","word_nosc":"escales","lemma":"escale","pos":"NOM"} ,
		{"word":"escalier","word_nosc":"escalier","lemma":"escalier","pos":"NOM"} ,
		{"word":"escaliers","word_nosc":"escaliers","lemma":"escalier","pos":"NOM"} ,
		{"word":"escalope","word_nosc":"escalope","lemma":"escalope","pos":"NOM"} ,
		{"word":"escalopes","word_nosc":"escalopes","lemma":"escalope","pos":"NOM"} ,
		{"word":"escamotage","word_nosc":"escamotage","lemma":"escamotage","pos":"NOM"} ,
		{"word":"escamotages","word_nosc":"escamotages","lemma":"escamotage","pos":"NOM"} ,
		{"word":"escamoteur","word_nosc":"escamoteur","lemma":"escamoteur","pos":"NOM"} ,
		{"word":"escampette","word_nosc":"escampette","lemma":"escampette","pos":"NOM"} ,
		{"word":"escapade","word_nosc":"escapade","lemma":"escapade","pos":"NOM"} ,
		{"word":"escapades","word_nosc":"escapades","lemma":"escapade","pos":"NOM"} ,
		{"word":"escape","word_nosc":"escape","lemma":"escape","pos":"NOM"} ,
		{"word":"escarbille","word_nosc":"escarbille","lemma":"escarbille","pos":"NOM"} ,
		{"word":"escarbilles","word_nosc":"escarbilles","lemma":"escarbille","pos":"NOM"} ,
		{"word":"escarboucle","word_nosc":"escarboucle","lemma":"escarboucle","pos":"NOM"} ,
		{"word":"escarboucles","word_nosc":"escarboucles","lemma":"escarboucle","pos":"NOM"} ,
		{"word":"escarcelle","word_nosc":"escarcelle","lemma":"escarcelle","pos":"NOM"} ,
		{"word":"escargot","word_nosc":"escargot","lemma":"escargot","pos":"NOM"} ,
		{"word":"escargots","word_nosc":"escargots","lemma":"escargot","pos":"NOM"} ,
		{"word":"escarmouche","word_nosc":"escarmouche","lemma":"escarmouche","pos":"NOM"} ,
		{"word":"escarmouches","word_nosc":"escarmouches","lemma":"escarmouche","pos":"NOM"} ,
		{"word":"escarpement","word_nosc":"escarpement","lemma":"escarpement","pos":"NOM"} ,
		{"word":"escarpements","word_nosc":"escarpements","lemma":"escarpement","pos":"NOM"} ,
		{"word":"escarpes","word_nosc":"escarpes","lemma":"escarpe","pos":"NOM"} ,
		{"word":"escarpin","word_nosc":"escarpin","lemma":"escarpin","pos":"NOM"} ,
		{"word":"escarpins","word_nosc":"escarpins","lemma":"escarpin","pos":"NOM"} ,
		{"word":"escarpolette","word_nosc":"escarpolette","lemma":"escarpolette","pos":"NOM"} ,
		{"word":"escarpolettes","word_nosc":"escarpolettes","lemma":"escarpolette","pos":"NOM"} ,
		{"word":"escarre","word_nosc":"escarre","lemma":"escarre","pos":"NOM"} ,
		{"word":"escarres","word_nosc":"escarres","lemma":"escarre","pos":"NOM"} ,
		{"word":"eschatologie","word_nosc":"eschatologie","lemma":"eschatologie","pos":"NOM"} ,
		{"word":"esche","word_nosc":"esche","lemma":"esche","pos":"NOM"} ,
		{"word":"escient","word_nosc":"escient","lemma":"escient","pos":"NOM"} ,
		{"word":"esclaffement","word_nosc":"esclaffement","lemma":"esclaffement","pos":"NOM"} ,
		{"word":"esclaffements","word_nosc":"esclaffements","lemma":"esclaffement","pos":"NOM"} ,
		{"word":"esclandre","word_nosc":"esclandre","lemma":"esclandre","pos":"NOM"} ,
		{"word":"esclandres","word_nosc":"esclandres","lemma":"esclandre","pos":"NOM"} ,
		{"word":"esclavage","word_nosc":"esclavage","lemma":"esclavage","pos":"NOM"} ,
		{"word":"esclavagisme","word_nosc":"esclavagisme","lemma":"esclavagisme","pos":"NOM"} ,
		{"word":"esclavagiste","word_nosc":"esclavagiste","lemma":"esclavagiste","pos":"NOM"} ,
		{"word":"esclavagistes","word_nosc":"esclavagistes","lemma":"esclavagiste","pos":"NOM"} ,
		{"word":"esclave","word_nosc":"esclave","lemma":"esclave","pos":"NOM"} ,
		{"word":"esclaves","word_nosc":"esclaves","lemma":"esclave","pos":"NOM"} ,
		{"word":"esclavons","word_nosc":"esclavons","lemma":"esclavon","pos":"NOM"} ,
		{"word":"escogriffe","word_nosc":"escogriffe","lemma":"escogriffe","pos":"NOM"} ,
		{"word":"escogriffes","word_nosc":"escogriffes","lemma":"escogriffe","pos":"NOM"} ,
		{"word":"escompte","word_nosc":"escompte","lemma":"escompte","pos":"NOM"} ,
		{"word":"escopette","word_nosc":"escopette","lemma":"escopette","pos":"NOM"} ,
		{"word":"escorte","word_nosc":"escorte","lemma":"escorte","pos":"NOM"} ,
		{"word":"escortes","word_nosc":"escortes","lemma":"escorte","pos":"NOM"} ,
		{"word":"escorteur","word_nosc":"escorteur","lemma":"escorteur","pos":"NOM"} ,
		{"word":"escorteurs","word_nosc":"escorteurs","lemma":"escorteur","pos":"NOM"} ,
		{"word":"escot","word_nosc":"escot","lemma":"escot","pos":"NOM"} ,
		{"word":"escouade","word_nosc":"escouade","lemma":"escouade","pos":"NOM"} ,
		{"word":"escouades","word_nosc":"escouades","lemma":"escouade","pos":"NOM"} ,
		{"word":"escrime","word_nosc":"escrime","lemma":"escrime","pos":"NOM"} ,
		{"word":"escrimeur","word_nosc":"escrimeur","lemma":"escrimeur","pos":"NOM"} ,
		{"word":"escrimeurs","word_nosc":"escrimeurs","lemma":"escrimeur","pos":"NOM"} ,
		{"word":"escrimeuses","word_nosc":"escrimeuses","lemma":"escrimeur","pos":"NOM"} ,
		{"word":"escroc","word_nosc":"escroc","lemma":"escroc","pos":"NOM"} ,
		{"word":"escrocs","word_nosc":"escrocs","lemma":"escroc","pos":"NOM"} ,
		{"word":"escroquerie","word_nosc":"escroquerie","lemma":"escroquerie","pos":"NOM"} ,
		{"word":"escroqueries","word_nosc":"escroqueries","lemma":"escroquerie","pos":"NOM"} ,
		{"word":"escroqueuse","word_nosc":"escroqueuse","lemma":"escroqueur","pos":"NOM"} ,
		{"word":"escudo","word_nosc":"escudo","lemma":"escudo","pos":"NOM"} ,
		{"word":"escudos","word_nosc":"escudos","lemma":"escudo","pos":"NOM"} ,
		{"word":"esgourde","word_nosc":"esgourde","lemma":"esgourde","pos":"NOM"} ,
		{"word":"esgourdes","word_nosc":"esgourdes","lemma":"esgourde","pos":"NOM"} ,
		{"word":"eskimo","word_nosc":"eskimo","lemma":"eskimo","pos":"NOM"} ,
		{"word":"eskimos","word_nosc":"eskimos","lemma":"eskimo","pos":"NOM"} ,
		{"word":"espace","word_nosc":"espace","lemma":"espace","pos":"NOM"} ,
		{"word":"espace-temps","word_nosc":"espace-temps","lemma":"espace-temps","pos":"NOM"} ,
		{"word":"espacement","word_nosc":"espacement","lemma":"espacement","pos":"NOM"} ,
		{"word":"espacements","word_nosc":"espacements","lemma":"espacement","pos":"NOM"} ,
		{"word":"espaces","word_nosc":"espaces","lemma":"espace","pos":"NOM"} ,
		{"word":"espada","word_nosc":"espada","lemma":"espada","pos":"NOM"} ,
		{"word":"espadas","word_nosc":"espadas","lemma":"espada","pos":"NOM"} ,
		{"word":"espadon","word_nosc":"espadon","lemma":"espadon","pos":"NOM"} ,
		{"word":"espadons","word_nosc":"espadons","lemma":"espadon","pos":"NOM"} ,
		{"word":"espadrille","word_nosc":"espadrille","lemma":"espadrille","pos":"NOM"} ,
		{"word":"espadrilles","word_nosc":"espadrilles","lemma":"espadrille","pos":"NOM"} ,
		{"word":"espagnol","word_nosc":"espagnol","lemma":"espagnol","pos":"NOM"} ,
		{"word":"espagnole","word_nosc":"espagnole","lemma":"espagnol","pos":"NOM"} ,
		{"word":"espagnoles","word_nosc":"espagnoles","lemma":"espagnol","pos":"NOM"} ,
		{"word":"espagnolette","word_nosc":"espagnolette","lemma":"espagnolette","pos":"NOM"} ,
		{"word":"espagnols","word_nosc":"espagnols","lemma":"espagnol","pos":"NOM"} ,
		{"word":"espalier","word_nosc":"espalier","lemma":"espalier","pos":"NOM"} ,
		{"word":"espaliers","word_nosc":"espaliers","lemma":"espalier","pos":"NOM"} ,
		{"word":"espar","word_nosc":"espar","lemma":"espar","pos":"NOM"} ,
		{"word":"espars","word_nosc":"espars","lemma":"espars","pos":"NOM"} ,
		{"word":"esperanto","word_nosc":"esperanto","lemma":"esperanto","pos":"NOM"} ,
		{"word":"espingo","word_nosc":"espingo","lemma":"espingo","pos":"NOM"} ,
		{"word":"espingole","word_nosc":"espingole","lemma":"espingole","pos":"NOM"} ,
		{"word":"espingoles","word_nosc":"espingoles","lemma":"espingole","pos":"NOM"} ,
		{"word":"espingos","word_nosc":"espingos","lemma":"espingo","pos":"NOM"} ,
		{"word":"espingouin","word_nosc":"espingouin","lemma":"espingouin","pos":"NOM"} ,
		{"word":"espingouins","word_nosc":"espingouins","lemma":"espingouin","pos":"NOM"} ,
		{"word":"espion","word_nosc":"espion","lemma":"espion","pos":"NOM"} ,
		{"word":"espionite","word_nosc":"espionite","lemma":"espionite","pos":"NOM"} ,
		{"word":"espionnage","word_nosc":"espionnage","lemma":"espionnage","pos":"NOM"} ,
		{"word":"espionne","word_nosc":"espionne","lemma":"espion","pos":"NOM"} ,
		{"word":"espionnes","word_nosc":"espionnes","lemma":"espion","pos":"NOM"} ,
		{"word":"espionnite","word_nosc":"espionnite","lemma":"espionnite","pos":"NOM"} ,
		{"word":"espions","word_nosc":"espions","lemma":"espion","pos":"NOM"} ,
		{"word":"espiègle","word_nosc":"espiegle","lemma":"espiègle","pos":"NOM"} ,
		{"word":"espièglerie","word_nosc":"espieglerie","lemma":"espièglerie","pos":"NOM"} ,
		{"word":"espiègleries","word_nosc":"espiegleries","lemma":"espièglerie","pos":"NOM"} ,
		{"word":"espiègles","word_nosc":"espiegles","lemma":"espiègle","pos":"NOM"} ,
		{"word":"esplanade","word_nosc":"esplanade","lemma":"esplanade","pos":"NOM"} ,
		{"word":"esplanades","word_nosc":"esplanades","lemma":"esplanade","pos":"NOM"} ,
		{"word":"espoir","word_nosc":"espoir","lemma":"espoir","pos":"NOM"} ,
		{"word":"espoirs","word_nosc":"espoirs","lemma":"espoir","pos":"NOM"} ,
		{"word":"esprit","word_nosc":"esprit","lemma":"esprit","pos":"NOM"} ,
		{"word":"esprit-de-sel","word_nosc":"esprit-de-sel","lemma":"esprit-de-sel","pos":"NOM"} ,
		{"word":"esprit-de-vin","word_nosc":"esprit-de-vin","lemma":"esprit-de-vin","pos":"NOM"} ,
		{"word":"esprits","word_nosc":"esprits","lemma":"esprit","pos":"NOM"} ,
		{"word":"espèce","word_nosc":"espece","lemma":"espèce","pos":"NOM"} ,
		{"word":"espèces","word_nosc":"especes","lemma":"espèce","pos":"NOM"} ,
		{"word":"espérance","word_nosc":"esperance","lemma":"espérance","pos":"NOM"} ,
		{"word":"espérances","word_nosc":"esperances","lemma":"espérance","pos":"NOM"} ,
		{"word":"espérantiste","word_nosc":"esperantiste","lemma":"espérantiste","pos":"NOM"} ,
		{"word":"espéranto","word_nosc":"esperanto","lemma":"espéranto","pos":"NOM"} ,
		{"word":"esquif","word_nosc":"esquif","lemma":"esquif","pos":"NOM"} ,
		{"word":"esquifs","word_nosc":"esquifs","lemma":"esquif","pos":"NOM"} ,
		{"word":"esquille","word_nosc":"esquille","lemma":"esquille","pos":"NOM"} ,
		{"word":"esquilles","word_nosc":"esquilles","lemma":"esquille","pos":"NOM"} ,
		{"word":"esquimau","word_nosc":"esquimau","lemma":"esquimau","pos":"NOM"} ,
		{"word":"esquimaux","word_nosc":"esquimaux","lemma":"esquimau","pos":"NOM"} ,
		{"word":"esquintement","word_nosc":"esquintement","lemma":"esquintement","pos":"NOM"} ,
		{"word":"esquire","word_nosc":"esquire","lemma":"esquire","pos":"NOM"} ,
		{"word":"esquisse","word_nosc":"esquisse","lemma":"esquisse","pos":"NOM"} ,
		{"word":"esquisses","word_nosc":"esquisses","lemma":"esquisse","pos":"NOM"} ,
		{"word":"esquive","word_nosc":"esquive","lemma":"esquive","pos":"NOM"} ,
		{"word":"esquives","word_nosc":"esquives","lemma":"esquive","pos":"NOM"} ,
		{"word":"essai","word_nosc":"essai","lemma":"essai","pos":"NOM"} ,
		{"word":"essaim","word_nosc":"essaim","lemma":"essaim","pos":"NOM"} ,
		{"word":"essaims","word_nosc":"essaims","lemma":"essaim","pos":"NOM"} ,
		{"word":"essais","word_nosc":"essais","lemma":"essai","pos":"NOM"} ,
		{"word":"essartage","word_nosc":"essartage","lemma":"essartage","pos":"NOM"} ,
		{"word":"essarts","word_nosc":"essarts","lemma":"essart","pos":"NOM"} ,
		{"word":"essayage","word_nosc":"essayage","lemma":"essayage","pos":"NOM"} ,
		{"word":"essayages","word_nosc":"essayages","lemma":"essayage","pos":"NOM"} ,
		{"word":"essayeur","word_nosc":"essayeur","lemma":"essayeur","pos":"NOM"} ,
		{"word":"essayeuses","word_nosc":"essayeuses","lemma":"essayeur","pos":"NOM"} ,
		{"word":"essayiste","word_nosc":"essayiste","lemma":"essayiste","pos":"NOM"} ,
		{"word":"essayistes","word_nosc":"essayistes","lemma":"essayiste","pos":"NOM"} ,
		{"word":"esse","word_nosc":"esse","lemma":"esse","pos":"NOM"} ,
		{"word":"essence","word_nosc":"essence","lemma":"essence","pos":"NOM"} ,
		{"word":"essences","word_nosc":"essences","lemma":"essence","pos":"NOM"} ,
		{"word":"essentialisme","word_nosc":"essentialisme","lemma":"essentialisme","pos":"NOM"} ,
		{"word":"essentiel","word_nosc":"essentiel","lemma":"essentiel","pos":"NOM"} ,
		{"word":"essentiels","word_nosc":"essentiels","lemma":"essentiel","pos":"NOM"} ,
		{"word":"esseulement","word_nosc":"esseulement","lemma":"esseulement","pos":"NOM"} ,
		{"word":"essieu","word_nosc":"essieu","lemma":"essieu","pos":"NOM"} ,
		{"word":"essieux","word_nosc":"essieux","lemma":"essieux","pos":"NOM"} ,
		{"word":"essor","word_nosc":"essor","lemma":"essor","pos":"NOM"} ,
		{"word":"essorage","word_nosc":"essorage","lemma":"essorage","pos":"NOM"} ,
		{"word":"essoreuse","word_nosc":"essoreuse","lemma":"essoreuse","pos":"NOM"} ,
		{"word":"essoufflement","word_nosc":"essoufflement","lemma":"essoufflement","pos":"NOM"} ,
		{"word":"essoufflements","word_nosc":"essoufflements","lemma":"essoufflement","pos":"NOM"} ,
		{"word":"essuie","word_nosc":"essuie","lemma":"essuie","pos":"NOM"} ,
		{"word":"essuie-glace","word_nosc":"essuie-glace","lemma":"essuie-glace","pos":"NOM"} ,
		{"word":"essuie-glaces","word_nosc":"essuie-glaces","lemma":"essuie-glace","pos":"NOM"} ,
		{"word":"essuie-mains","word_nosc":"essuie-mains","lemma":"essuie-mains","pos":"NOM"} ,
		{"word":"essuie-tout","word_nosc":"essuie-tout","lemma":"essuie-tout","pos":"NOM"} ,
		{"word":"essuies","word_nosc":"essuies","lemma":"essuie","pos":"NOM"} ,
		{"word":"essuyage","word_nosc":"essuyage","lemma":"essuyage","pos":"NOM"} ,
		{"word":"essuyages","word_nosc":"essuyages","lemma":"essuyage","pos":"NOM"} ,
		{"word":"essuyeur","word_nosc":"essuyeur","lemma":"essuyeur","pos":"NOM"} ,
		{"word":"est","word_nosc":"est","lemma":"est","pos":"NOM"} ,
		{"word":"est-africain","word_nosc":"est-africain","lemma":"est-africain","pos":"NOM"} ,
		{"word":"establishment","word_nosc":"establishment","lemma":"establishment","pos":"NOM"} ,
		{"word":"estacade","word_nosc":"estacade","lemma":"estacade","pos":"NOM"} ,
		{"word":"estacades","word_nosc":"estacades","lemma":"estacade","pos":"NOM"} ,
		{"word":"estafette","word_nosc":"estafette","lemma":"estafette","pos":"NOM"} ,
		{"word":"estafettes","word_nosc":"estafettes","lemma":"estafette","pos":"NOM"} ,
		{"word":"estafier","word_nosc":"estafier","lemma":"estafier","pos":"NOM"} ,
		{"word":"estafiers","word_nosc":"estafiers","lemma":"estafier","pos":"NOM"} ,
		{"word":"estafilade","word_nosc":"estafilade","lemma":"estafilade","pos":"NOM"} ,
		{"word":"estafilades","word_nosc":"estafilades","lemma":"estafilade","pos":"NOM"} ,
		{"word":"estagnon","word_nosc":"estagnon","lemma":"estagnon","pos":"NOM"} ,
		{"word":"estaminet","word_nosc":"estaminet","lemma":"estaminet","pos":"NOM"} ,
		{"word":"estaminets","word_nosc":"estaminets","lemma":"estaminet","pos":"NOM"} ,
		{"word":"estampage","word_nosc":"estampage","lemma":"estampage","pos":"NOM"} ,
		{"word":"estampe","word_nosc":"estampe","lemma":"estampe","pos":"NOM"} ,
		{"word":"estampes","word_nosc":"estampes","lemma":"estampe","pos":"NOM"} ,
		{"word":"estampille","word_nosc":"estampille","lemma":"estampille","pos":"NOM"} ,
		{"word":"estancia","word_nosc":"estancia","lemma":"estancia","pos":"NOM"} ,
		{"word":"estancias","word_nosc":"estancias","lemma":"estancia","pos":"NOM"} ,
		{"word":"este","word_nosc":"este","lemma":"este","pos":"NOM"} ,
		{"word":"ester","word_nosc":"ester","lemma":"ester","pos":"NOM"} ,
		{"word":"estes","word_nosc":"estes","lemma":"este","pos":"NOM"} ,
		{"word":"esthète","word_nosc":"esthete","lemma":"esthète","pos":"NOM"} ,
		{"word":"esthètes","word_nosc":"esthetes","lemma":"esthète","pos":"NOM"} ,
		{"word":"esthésiomètre","word_nosc":"esthesiometre","lemma":"esthésiomètre","pos":"NOM"} ,
		{"word":"esthéticien","word_nosc":"estheticien","lemma":"esthéticien","pos":"NOM"} ,
		{"word":"esthéticienne","word_nosc":"estheticienne","lemma":"esthéticien","pos":"NOM"} ,
		{"word":"esthéticiennes","word_nosc":"estheticiennes","lemma":"esthéticien","pos":"NOM"} ,
		{"word":"esthétique","word_nosc":"esthetique","lemma":"esthétique","pos":"NOM"} ,
		{"word":"esthétisme","word_nosc":"esthetisme","lemma":"esthétisme","pos":"NOM"} ,
		{"word":"estimation","word_nosc":"estimation","lemma":"estimation","pos":"NOM"} ,
		{"word":"estimations","word_nosc":"estimations","lemma":"estimation","pos":"NOM"} ,
		{"word":"estime","word_nosc":"estime","lemma":"estime","pos":"NOM"} ,
		{"word":"estimes","word_nosc":"estimes","lemma":"estime","pos":"NOM"} ,
		{"word":"estivant","word_nosc":"estivant","lemma":"estivant","pos":"NOM"} ,
		{"word":"estivante","word_nosc":"estivante","lemma":"estivant","pos":"NOM"} ,
		{"word":"estivantes","word_nosc":"estivantes","lemma":"estivant","pos":"NOM"} ,
		{"word":"estivants","word_nosc":"estivants","lemma":"estivant","pos":"NOM"} ,
		{"word":"estoc","word_nosc":"estoc","lemma":"estoc","pos":"NOM"} ,
		{"word":"estocade","word_nosc":"estocade","lemma":"estocade","pos":"NOM"} ,
		{"word":"estocades","word_nosc":"estocades","lemma":"estocade","pos":"NOM"} ,
		{"word":"estocs","word_nosc":"estocs","lemma":"estoc","pos":"NOM"} ,
		{"word":"estom","word_nosc":"estom","lemma":"estom","pos":"NOM"} ,
		{"word":"estomac","word_nosc":"estomac","lemma":"estomac","pos":"NOM"} ,
		{"word":"estomacs","word_nosc":"estomacs","lemma":"estomac","pos":"NOM"} ,
		{"word":"estompe","word_nosc":"estompe","lemma":"estompe","pos":"NOM"} ,
		{"word":"estonien","word_nosc":"estonien","lemma":"estonien","pos":"NOM"} ,
		{"word":"estonienne","word_nosc":"estonienne","lemma":"estonienne","pos":"NOM"} ,
		{"word":"estoniens","word_nosc":"estoniens","lemma":"estonien","pos":"NOM"} ,
		{"word":"estouffade","word_nosc":"estouffade","lemma":"estouffade","pos":"NOM"} ,
		{"word":"estrade","word_nosc":"estrade","lemma":"estrade","pos":"NOM"} ,
		{"word":"estrades","word_nosc":"estrades","lemma":"estrade","pos":"NOM"} ,
		{"word":"estragon","word_nosc":"estragon","lemma":"estragon","pos":"NOM"} ,
		{"word":"estrangers","word_nosc":"estrangers","lemma":"estranger","pos":"NOM"} ,
		{"word":"estrapade","word_nosc":"estrapade","lemma":"estrapade","pos":"NOM"} ,
		{"word":"estrapades","word_nosc":"estrapades","lemma":"estrapade","pos":"NOM"} ,
		{"word":"estrogène","word_nosc":"estrogene","lemma":"estrogène","pos":"NOM"} ,
		{"word":"estuaire","word_nosc":"estuaire","lemma":"estuaire","pos":"NOM"} ,
		{"word":"estuaires","word_nosc":"estuaires","lemma":"estuaire","pos":"NOM"} ,
		{"word":"esturgeon","word_nosc":"esturgeon","lemma":"esturgeon","pos":"NOM"} ,
		{"word":"esturgeons","word_nosc":"esturgeons","lemma":"esturgeon","pos":"NOM"} ,
		{"word":"ethnicité","word_nosc":"ethnicite","lemma":"ethnicité","pos":"NOM"} ,
		{"word":"ethnie","word_nosc":"ethnie","lemma":"ethnie","pos":"NOM"} ,
		{"word":"ethnies","word_nosc":"ethnies","lemma":"ethnie","pos":"NOM"} ,
		{"word":"ethnobotaniste","word_nosc":"ethnobotaniste","lemma":"ethnobotaniste","pos":"NOM"} ,
		{"word":"ethnographe","word_nosc":"ethnographe","lemma":"ethnographe","pos":"NOM"} ,
		{"word":"ethnographie","word_nosc":"ethnographie","lemma":"ethnographie","pos":"NOM"} ,
		{"word":"ethnologie","word_nosc":"ethnologie","lemma":"ethnologie","pos":"NOM"} ,
		{"word":"ethnologue","word_nosc":"ethnologue","lemma":"ethnologue","pos":"NOM"} ,
		{"word":"ethnologues","word_nosc":"ethnologues","lemma":"ethnologue","pos":"NOM"} ,
		{"word":"eubage","word_nosc":"eubage","lemma":"eubage","pos":"NOM"} ,
		{"word":"eubine","word_nosc":"eubine","lemma":"eubine","pos":"NOM"} ,
		{"word":"eucalyptus","word_nosc":"eucalyptus","lemma":"eucalyptus","pos":"NOM"} ,
		{"word":"eucharistie","word_nosc":"eucharistie","lemma":"eucharistie","pos":"NOM"} ,
		{"word":"eudistes","word_nosc":"eudistes","lemma":"eudiste","pos":"NOM"} ,
		{"word":"eugénique","word_nosc":"eugenique","lemma":"eugénique","pos":"NOM"} ,
		{"word":"eugénisme","word_nosc":"eugenisme","lemma":"eugénisme","pos":"NOM"} ,
		{"word":"eulogie","word_nosc":"eulogie","lemma":"eulogie","pos":"NOM"} ,
		{"word":"eunuque","word_nosc":"eunuque","lemma":"eunuque","pos":"NOM"} ,
		{"word":"eunuques","word_nosc":"eunuques","lemma":"eunuque","pos":"NOM"} ,
		{"word":"eunuques-espions","word_nosc":"eunuques-espions","lemma":"eunuques-espion","pos":"NOM"} ,
		{"word":"euphonie","word_nosc":"euphonie","lemma":"euphonie","pos":"NOM"} ,
		{"word":"euphorbe","word_nosc":"euphorbe","lemma":"euphorbe","pos":"NOM"} ,
		{"word":"euphorbes","word_nosc":"euphorbes","lemma":"euphorbe","pos":"NOM"} ,
		{"word":"euphorie","word_nosc":"euphorie","lemma":"euphorie","pos":"NOM"} ,
		{"word":"euphorisant","word_nosc":"euphorisant","lemma":"euphorisant","pos":"NOM"} ,
		{"word":"euphorisants","word_nosc":"euphorisants","lemma":"euphorisant","pos":"NOM"} ,
		{"word":"euphémisme","word_nosc":"euphemisme","lemma":"euphémisme","pos":"NOM"} ,
		{"word":"euphémismes","word_nosc":"euphemismes","lemma":"euphémisme","pos":"NOM"} ,
		{"word":"eurasien","word_nosc":"eurasien","lemma":"eurasien","pos":"NOM"} ,
		{"word":"eurasienne","word_nosc":"eurasienne","lemma":"eurasien","pos":"NOM"} ,
		{"word":"euro","word_nosc":"euro","lemma":"euro","pos":"NOM"} ,
		{"word":"eurodollar","word_nosc":"eurodollar","lemma":"eurodollar","pos":"NOM"} ,
		{"word":"euromarché","word_nosc":"euromarche","lemma":"euromarché","pos":"NOM"} ,
		{"word":"européen","word_nosc":"europeen","lemma":"européen","pos":"NOM"} ,
		{"word":"européenne","word_nosc":"europeenne","lemma":"européen","pos":"NOM"} ,
		{"word":"européennes","word_nosc":"europeennes","lemma":"européen","pos":"NOM"} ,
		{"word":"européens","word_nosc":"europeens","lemma":"européen","pos":"NOM"} ,
		{"word":"euros","word_nosc":"euros","lemma":"euro","pos":"NOM"} ,
		{"word":"euskera","word_nosc":"euskera","lemma":"euskera","pos":"NOM"} ,
		{"word":"eustache","word_nosc":"eustache","lemma":"eustache","pos":"NOM"} ,
		{"word":"eustaches","word_nosc":"eustaches","lemma":"eustache","pos":"NOM"} ,
		{"word":"euthanasie","word_nosc":"euthanasie","lemma":"euthanasie","pos":"NOM"} ,
		{"word":"euthanasies","word_nosc":"euthanasies","lemma":"euthanasie","pos":"NOM"} ,
		{"word":"event","word_nosc":"event","lemma":"event","pos":"NOM"} ,
		{"word":"evzones","word_nosc":"evzones","lemma":"evzone","pos":"NOM"} ,
		{"word":"ex-abbé","word_nosc":"ex-abbe","lemma":"ex-abbé","pos":"NOM"} ,
		{"word":"ex-acteur","word_nosc":"ex-acteur","lemma":"ex-acteur","pos":"NOM"} ,
		{"word":"ex-adjudant","word_nosc":"ex-adjudant","lemma":"ex-adjudant","pos":"NOM"} ,
		{"word":"ex-agent","word_nosc":"ex-agent","lemma":"ex-agent","pos":"NOM"} ,
		{"word":"ex-alcoolo","word_nosc":"ex-alcoolo","lemma":"ex-alcoolo","pos":"NOM"} ,
		{"word":"ex-allée","word_nosc":"ex-allee","lemma":"ex-allée","pos":"NOM"} ,
		{"word":"ex-amant","word_nosc":"ex-amant","lemma":"ex-amant","pos":"NOM"} ,
		{"word":"ex-amante","word_nosc":"ex-amante","lemma":"ex-amant","pos":"NOM"} ,
		{"word":"ex-amants","word_nosc":"ex-amants","lemma":"ex-amant","pos":"NOM"} ,
		{"word":"ex-ambassadeur","word_nosc":"ex-ambassadeur","lemma":"ex-ambassadeur","pos":"NOM"} ,
		{"word":"ex-amour","word_nosc":"ex-amour","lemma":"ex-amour","pos":"NOM"} ,
		{"word":"ex-appartement","word_nosc":"ex-appartement","lemma":"ex-appartement","pos":"NOM"} ,
		{"word":"ex-apprenti","word_nosc":"ex-apprenti","lemma":"ex-apprenti","pos":"NOM"} ,
		{"word":"ex-arriviste","word_nosc":"ex-arriviste","lemma":"ex-arriviste","pos":"NOM"} ,
		{"word":"ex-artiste","word_nosc":"ex-artiste","lemma":"ex-artiste","pos":"NOM"} ,
		{"word":"ex-assistant","word_nosc":"ex-assistant","lemma":"ex-assistant","pos":"NOM"} ,
		{"word":"ex-assistante","word_nosc":"ex-assistante","lemma":"ex-assistant","pos":"NOM"} ,
		{"word":"ex-associé","word_nosc":"ex-associe","lemma":"ex-associé","pos":"NOM"} ,
		{"word":"ex-associés","word_nosc":"ex-associes","lemma":"ex-associé","pos":"NOM"} ,
		{"word":"ex-ballerine","word_nosc":"ex-ballerine","lemma":"ex-ballerine","pos":"NOM"} ,
		{"word":"ex-banquier","word_nosc":"ex-banquier","lemma":"ex-banquier","pos":"NOM"} ,
		{"word":"ex-barman","word_nosc":"ex-barman","lemma":"ex-barman","pos":"NOM"} ,
		{"word":"ex-basketteur","word_nosc":"ex-basketteur","lemma":"ex-basketteur","pos":"NOM"} ,
		{"word":"ex-batteur","word_nosc":"ex-batteur","lemma":"ex-batteur","pos":"NOM"} ,
		{"word":"ex-beatnik","word_nosc":"ex-beatnik","lemma":"ex-beatnik","pos":"NOM"} ,
		{"word":"ex-belle-mère","word_nosc":"ex-belle-mere","lemma":"ex-belle-mère","pos":"NOM"} ,
		{"word":"ex-bibliothécaire","word_nosc":"ex-bibliothecaire","lemma":"ex-bibliothécaire","pos":"NOM"} ,
		{"word":"ex-boss","word_nosc":"ex-boss","lemma":"ex-boss","pos":"NOM"} ,
		{"word":"ex-boxeur","word_nosc":"ex-boxeur","lemma":"ex-boxeur","pos":"NOM"} ,
		{"word":"ex-branleurs","word_nosc":"ex-branleurs","lemma":"ex-branleur","pos":"NOM"} ,
		{"word":"ex-buteur","word_nosc":"ex-buteur","lemma":"ex-buteur","pos":"NOM"} ,
		{"word":"ex-camarades","word_nosc":"ex-camarades","lemma":"ex-camarade","pos":"NOM"} ,
		{"word":"ex-capitaine","word_nosc":"ex-capitaine","lemma":"ex-capitaine","pos":"NOM"} ,
		{"word":"ex-caïd","word_nosc":"ex-caid","lemma":"ex-caïd","pos":"NOM"} ,
		{"word":"ex-champion","word_nosc":"ex-champion","lemma":"ex-champion","pos":"NOM"} ,
		{"word":"ex-championne","word_nosc":"ex-championne","lemma":"ex-champion","pos":"NOM"} ,
		{"word":"ex-champions","word_nosc":"ex-champions","lemma":"ex-champion","pos":"NOM"} ,
		{"word":"ex-chanteur","word_nosc":"ex-chanteur","lemma":"ex-chanteur","pos":"NOM"} ,
		{"word":"ex-chanteuse","word_nosc":"ex-chanteuse","lemma":"ex-chanteur","pos":"NOM"} ,
		{"word":"ex-chauffeur","word_nosc":"ex-chauffeur","lemma":"ex-chauffeur","pos":"NOM"} ,
		{"word":"ex-chef","word_nosc":"ex-chef","lemma":"ex-chef","pos":"NOM"} ,
		{"word":"ex-chiropracteur","word_nosc":"ex-chiropracteur","lemma":"ex-chiropracteur","pos":"NOM"} ,
		{"word":"ex-citation","word_nosc":"ex-citation","lemma":"ex-citation","pos":"NOM"} ,
		{"word":"ex-citoyen","word_nosc":"ex-citoyen","lemma":"ex-citoyen","pos":"NOM"} ,
		{"word":"ex-civils","word_nosc":"ex-civils","lemma":"ex-civil","pos":"NOM"} ,
		{"word":"ex-collabo","word_nosc":"ex-collabo","lemma":"ex-collabo","pos":"NOM"} ,
		{"word":"ex-collègue","word_nosc":"ex-collegue","lemma":"ex-collègue","pos":"NOM"} ,
		{"word":"ex-collègues","word_nosc":"ex-collegues","lemma":"ex-collègue","pos":"NOM"} ,
		{"word":"ex-colonel","word_nosc":"ex-colonel","lemma":"ex-colonel","pos":"NOM"} ,
		{"word":"ex-commando","word_nosc":"ex-commando","lemma":"ex-commando","pos":"NOM"} ,
		{"word":"ex-concierge","word_nosc":"ex-concierge","lemma":"ex-concierge","pos":"NOM"} ,
		{"word":"ex-contrebandier","word_nosc":"ex-contrebandier","lemma":"ex-contrebandier","pos":"NOM"} ,
		{"word":"ex-copain","word_nosc":"ex-copain","lemma":"ex-copain","pos":"NOM"} ,
		{"word":"ex-copains","word_nosc":"ex-copains","lemma":"ex-copain","pos":"NOM"} ,
		{"word":"ex-copine","word_nosc":"ex-copine","lemma":"ex-copine","pos":"NOM"} ,
		{"word":"ex-copines","word_nosc":"ex-copines","lemma":"ex-copine","pos":"NOM"} ,
		{"word":"ex-corps","word_nosc":"ex-corps","lemma":"ex-corps","pos":"NOM"} ,
		{"word":"ex-couple","word_nosc":"ex-couple","lemma":"ex-couple","pos":"NOM"} ,
		{"word":"ex-danseuse","word_nosc":"ex-danseuse","lemma":"ex-danseur","pos":"NOM"} ,
		{"word":"ex-dealer","word_nosc":"ex-dealer","lemma":"ex-dealer","pos":"NOM"} ,
		{"word":"ex-dieu","word_nosc":"ex-dieu","lemma":"ex-dieu","pos":"NOM"} ,
		{"word":"ex-diva","word_nosc":"ex-diva","lemma":"ex-diva","pos":"NOM"} ,
		{"word":"ex-drifter","word_nosc":"ex-drifter","lemma":"ex-drifter","pos":"NOM"} ,
		{"word":"ex-drôlesse","word_nosc":"ex-drolesse","lemma":"ex-drôlesse","pos":"NOM"} ,
		{"word":"ex-dulcinée","word_nosc":"ex-dulcinee","lemma":"ex-dulcinée","pos":"NOM"} ,
		{"word":"ex-démon","word_nosc":"ex-demon","lemma":"ex-démon","pos":"NOM"} ,
		{"word":"ex-démons","word_nosc":"ex-demons","lemma":"ex-démon","pos":"NOM"} ,
		{"word":"ex-enseigne","word_nosc":"ex-enseigne","lemma":"ex-enseigne","pos":"NOM"} ,
		{"word":"ex-enzymes","word_nosc":"ex-enzymes","lemma":"ex-enzyme","pos":"NOM"} ,
		{"word":"ex-esclavagistes","word_nosc":"ex-esclavagistes","lemma":"ex-esclavagiste","pos":"NOM"} ,
		{"word":"ex-femme","word_nosc":"ex-femme","lemma":"ex-femme","pos":"NOM"} ,
		{"word":"ex-femmes","word_nosc":"ex-femmes","lemma":"ex-femme","pos":"NOM"} ,
		{"word":"ex-fiancé","word_nosc":"ex-fiance","lemma":"ex-fiancé","pos":"NOM"} ,
		{"word":"ex-fiancée","word_nosc":"ex-fiancee","lemma":"ex-fiancé","pos":"NOM"} ,
		{"word":"ex-fils","word_nosc":"ex-fils","lemma":"ex-fils","pos":"NOM"} ,
		{"word":"ex-flic","word_nosc":"ex-flic","lemma":"ex-flic","pos":"NOM"} ,
		{"word":"ex-flics","word_nosc":"ex-flics","lemma":"ex-flic","pos":"NOM"} ,
		{"word":"ex-forçats","word_nosc":"ex-forcats","lemma":"ex-forçat","pos":"NOM"} ,
		{"word":"ex-fusilleurs","word_nosc":"ex-fusilleurs","lemma":"ex-fusilleur","pos":"NOM"} ,
		{"word":"ex-gang","word_nosc":"ex-gang","lemma":"ex-gang","pos":"NOM"} ,
		{"word":"ex-garde","word_nosc":"ex-garde","lemma":"ex-garde","pos":"NOM"} ,
		{"word":"ex-gardes","word_nosc":"ex-gardes","lemma":"ex-garde","pos":"NOM"} ,
		{"word":"ex-gauchiste","word_nosc":"ex-gauchiste","lemma":"ex-gauchiste","pos":"NOM"} ,
		{"word":"ex-gendre","word_nosc":"ex-gendre","lemma":"ex-gendre","pos":"NOM"} ,
		{"word":"ex-gonzesse","word_nosc":"ex-gonzesse","lemma":"ex-gonzesse","pos":"NOM"} ,
		{"word":"ex-gouverneur","word_nosc":"ex-gouverneur","lemma":"ex-gouverneur","pos":"NOM"} ,
		{"word":"ex-gravosse","word_nosc":"ex-gravosse","lemma":"ex-gravosse","pos":"NOM"} ,
		{"word":"ex-griot","word_nosc":"ex-griot","lemma":"ex-griot","pos":"NOM"} ,
		{"word":"ex-griveton","word_nosc":"ex-griveton","lemma":"ex-griveton","pos":"NOM"} ,
		{"word":"ex-grognasse","word_nosc":"ex-grognasse","lemma":"ex-grognasse","pos":"NOM"} ,
		{"word":"ex-groupe","word_nosc":"ex-groupe","lemma":"ex-groupe","pos":"NOM"} ,
		{"word":"ex-guenilles","word_nosc":"ex-guenilles","lemma":"ex-guenille","pos":"NOM"} ,
		{"word":"ex-guitariste","word_nosc":"ex-guitariste","lemma":"ex-guitariste","pos":"NOM"} ,
		{"word":"ex-génie","word_nosc":"ex-genie","lemma":"ex-génie","pos":"NOM"} ,
		{"word":"ex-hippies","word_nosc":"ex-hippies","lemma":"ex-hippie","pos":"NOM"} ,
		{"word":"ex-homme","word_nosc":"ex-homme","lemma":"ex-homme","pos":"NOM"} ,
		{"word":"ex-homme-grenouille","word_nosc":"ex-homme-grenouille","lemma":"ex-homme-grenouille","pos":"NOM"} ,
		{"word":"ex-hôpital","word_nosc":"ex-hopital","lemma":"ex-hôpital","pos":"NOM"} ,
		{"word":"ex-immeuble","word_nosc":"ex-immeuble","lemma":"ex-immeuble","pos":"NOM"} ,
		{"word":"ex-inspecteur","word_nosc":"ex-inspecteur","lemma":"ex-inspecteur","pos":"NOM"} ,
		{"word":"ex-inspecteurs","word_nosc":"ex-inspecteurs","lemma":"ex-inspecteur","pos":"NOM"} ,
		{"word":"ex-journaliste","word_nosc":"ex-journaliste","lemma":"ex-journaliste","pos":"NOM"} ,
		{"word":"ex-junkie","word_nosc":"ex-junkie","lemma":"ex-junkie","pos":"NOM"} ,
		{"word":"ex-kid","word_nosc":"ex-kid","lemma":"ex-kid","pos":"NOM"} ,
		{"word":"ex-leader","word_nosc":"ex-leader","lemma":"ex-leader","pos":"NOM"} ,
		{"word":"ex-libris","word_nosc":"ex-libris","lemma":"ex-libris","pos":"NOM"} ,
		{"word":"ex-lieutenant","word_nosc":"ex-lieutenant","lemma":"ex-lieutenant","pos":"NOM"} ,
		{"word":"ex-lit","word_nosc":"ex-lit","lemma":"ex-lit","pos":"NOM"} ,
		{"word":"ex-lycée","word_nosc":"ex-lycee","lemma":"ex-lycée","pos":"NOM"} ,
		{"word":"ex-légionnaire","word_nosc":"ex-legionnaire","lemma":"ex-légionnaire","pos":"NOM"} ,
		{"word":"ex-madame","word_nosc":"ex-madame","lemma":"ex-madame","pos":"NOM"} ,
		{"word":"ex-maire","word_nosc":"ex-maire","lemma":"ex-maire","pos":"NOM"} ,
		{"word":"ex-mari","word_nosc":"ex-mari","lemma":"ex-mari","pos":"NOM"} ,
		{"word":"ex-marine","word_nosc":"ex-marine","lemma":"ex-marine","pos":"NOM"} ,
		{"word":"ex-maris","word_nosc":"ex-maris","lemma":"ex-mari","pos":"NOM"} ,
		{"word":"ex-maître","word_nosc":"ex-maitre","lemma":"ex-maître","pos":"NOM"} ,
		{"word":"ex-mecs","word_nosc":"ex-mecs","lemma":"ex-mec","pos":"NOM"} ,
		{"word":"ex-membre","word_nosc":"ex-membre","lemma":"ex-membre","pos":"NOM"} ,
		{"word":"ex-mercenaires","word_nosc":"ex-mercenaires","lemma":"ex-mercenaire","pos":"NOM"} ,
		{"word":"ex-militaire","word_nosc":"ex-militaire","lemma":"ex-militaire","pos":"NOM"} ,
		{"word":"ex-militaires","word_nosc":"ex-militaires","lemma":"ex-militaire","pos":"NOM"} ,
		{"word":"ex-ministre","word_nosc":"ex-ministre","lemma":"ex-ministre","pos":"NOM"} ,
		{"word":"ex-ministres","word_nosc":"ex-ministres","lemma":"ex-ministre","pos":"NOM"} ,
		{"word":"ex-miss","word_nosc":"ex-miss","lemma":"ex-miss","pos":"NOM"} ,
		{"word":"ex-monteur","word_nosc":"ex-monteur","lemma":"ex-monteur","pos":"NOM"} ,
		{"word":"ex-motard","word_nosc":"ex-motard","lemma":"ex-motard","pos":"NOM"} ,
		{"word":"ex-nana","word_nosc":"ex-nana","lemma":"ex-nana","pos":"NOM"} ,
		{"word":"ex-officier","word_nosc":"ex-officier","lemma":"ex-officier","pos":"NOM"} ,
		{"word":"ex-opérateur","word_nosc":"ex-operateur","lemma":"ex-opérateur","pos":"NOM"} ,
		{"word":"ex-palmes","word_nosc":"ex-palmes","lemma":"ex-palme","pos":"NOM"} ,
		{"word":"ex-para","word_nosc":"ex-para","lemma":"ex-para","pos":"NOM"} ,
		{"word":"ex-partenaire","word_nosc":"ex-partenaire","lemma":"ex-partenaire","pos":"NOM"} ,
		{"word":"ex-patron","word_nosc":"ex-patron","lemma":"ex-patron","pos":"NOM"} ,
		{"word":"ex-pensionnaires","word_nosc":"ex-pensionnaires","lemma":"ex-pensionnaire","pos":"NOM"} ,
		{"word":"ex-perroquet","word_nosc":"ex-perroquet","lemma":"ex-perroquet","pos":"NOM"} ,
		{"word":"ex-petit","word_nosc":"ex-petit","lemma":"ex-petit","pos":"NOM"} ,
		{"word":"ex-petite","word_nosc":"ex-petite","lemma":"ex-petit","pos":"NOM"} ,
		{"word":"ex-petits","word_nosc":"ex-petits","lemma":"ex-petit","pos":"NOM"} ,
		{"word":"ex-pharmaciennes","word_nosc":"ex-pharmaciennes","lemma":"ex-pharmacien","pos":"NOM"} ,
		{"word":"ex-planton","word_nosc":"ex-planton","lemma":"ex-planton","pos":"NOM"} ,
		{"word":"ex-planète","word_nosc":"ex-planete","lemma":"ex-planète","pos":"NOM"} ,
		{"word":"ex-plâtrier","word_nosc":"ex-platrier","lemma":"ex-plâtrier","pos":"NOM"} ,
		{"word":"ex-poivrots","word_nosc":"ex-poivrots","lemma":"ex-poivrot","pos":"NOM"} ,
		{"word":"ex-premier","word_nosc":"ex-premier","lemma":"ex-premier","pos":"NOM"} ,
		{"word":"ex-primaire","word_nosc":"ex-primaire","lemma":"ex-primaire","pos":"NOM"} ,
		{"word":"ex-prison","word_nosc":"ex-prison","lemma":"ex-prison","pos":"NOM"} ,
		{"word":"ex-procureur","word_nosc":"ex-procureur","lemma":"ex-procureur","pos":"NOM"} ,
		{"word":"ex-profession","word_nosc":"ex-profession","lemma":"ex-profession","pos":"NOM"} ,
		{"word":"ex-profileur","word_nosc":"ex-profileur","lemma":"ex-profileur","pos":"NOM"} ,
		{"word":"ex-promoteur","word_nosc":"ex-promoteur","lemma":"ex-promoteur","pos":"NOM"} ,
		{"word":"ex-propriétaire","word_nosc":"ex-proprietaire","lemma":"ex-propriétaire","pos":"NOM"} ,
		{"word":"ex-présentateur","word_nosc":"ex-presentateur","lemma":"ex-présentateur","pos":"NOM"} ,
		{"word":"ex-président","word_nosc":"ex-president","lemma":"ex-président","pos":"NOM"} ,
		{"word":"ex-présidents","word_nosc":"ex-presidents","lemma":"ex-président","pos":"NOM"} ,
		{"word":"ex-prêtre","word_nosc":"ex-pretre","lemma":"ex-prêtre","pos":"NOM"} ,
		{"word":"ex-putain","word_nosc":"ex-putain","lemma":"ex-putain","pos":"NOM"} ,
		{"word":"ex-putes","word_nosc":"ex-putes","lemma":"ex-pute","pos":"NOM"} ,
		{"word":"ex-quincaillerie","word_nosc":"ex-quincaillerie","lemma":"ex-quincaillerie","pos":"NOM"} ,
		{"word":"ex-quincaillier","word_nosc":"ex-quincaillier","lemma":"ex-quincaillier","pos":"NOM"} ,
		{"word":"ex-reine","word_nosc":"ex-reine","lemma":"ex-reine","pos":"NOM"} ,
		{"word":"ex-roi","word_nosc":"ex-roi","lemma":"ex-roi","pos":"NOM"} ,
		{"word":"ex-républiques","word_nosc":"ex-republiques","lemma":"ex-république","pos":"NOM"} ,
		{"word":"ex-résidence","word_nosc":"ex-residence","lemma":"ex-résidence","pos":"NOM"} ,
		{"word":"ex-révolutionnaire","word_nosc":"ex-revolutionnaire","lemma":"ex-révolutionnaire","pos":"NOM"} ,
		{"word":"ex-sacs","word_nosc":"ex-sacs","lemma":"ex-sac","pos":"NOM"} ,
		{"word":"ex-secrétaire","word_nosc":"ex-secretaire","lemma":"ex-secrétaire","pos":"NOM"} ,
		{"word":"ex-sergent","word_nosc":"ex-sergent","lemma":"ex-sergent","pos":"NOM"} ,
		{"word":"ex-sergents","word_nosc":"ex-sergents","lemma":"ex-sergent","pos":"NOM"} ,
		{"word":"ex-soldats","word_nosc":"ex-soldats","lemma":"ex-soldat","pos":"NOM"} ,
		{"word":"ex-solistes","word_nosc":"ex-solistes","lemma":"ex-soliste","pos":"NOM"} ,
		{"word":"ex-standardiste","word_nosc":"ex-standardiste","lemma":"ex-standardiste","pos":"NOM"} ,
		{"word":"ex-star","word_nosc":"ex-star","lemma":"ex-star","pos":"NOM"} ,
		{"word":"ex-strip-teaseuse","word_nosc":"ex-strip-teaseuse","lemma":"ex-strip-teaseur","pos":"NOM"} ,
		{"word":"ex-séminariste","word_nosc":"ex-seminariste","lemma":"ex-séminariste","pos":"NOM"} ,
		{"word":"ex-sénateur","word_nosc":"ex-senateur","lemma":"ex-sénateur","pos":"NOM"} ,
		{"word":"ex-taulard","word_nosc":"ex-taulard","lemma":"ex-taulard","pos":"NOM"} ,
		{"word":"ex-taulards","word_nosc":"ex-taulards","lemma":"ex-taulard","pos":"NOM"} ,
		{"word":"ex-teinturier","word_nosc":"ex-teinturier","lemma":"ex-teinturier","pos":"NOM"} ,
		{"word":"ex-tirailleur","word_nosc":"ex-tirailleur","lemma":"ex-tirailleur","pos":"NOM"} ,
		{"word":"ex-tueur","word_nosc":"ex-tueur","lemma":"ex-tueur","pos":"NOM"} ,
		{"word":"ex-tuteur","word_nosc":"ex-tuteur","lemma":"ex-tuteur","pos":"NOM"} ,
		{"word":"ex-union","word_nosc":"ex-union","lemma":"ex-union","pos":"NOM"} ,
		{"word":"ex-usine","word_nosc":"ex-usine","lemma":"ex-usine","pos":"NOM"} ,
		{"word":"ex-vedette","word_nosc":"ex-vedette","lemma":"ex-vedette","pos":"NOM"} ,
		{"word":"ex-violeurs","word_nosc":"ex-violeurs","lemma":"ex-violeur","pos":"NOM"} ,
		{"word":"ex-voto","word_nosc":"ex-voto","lemma":"ex-voto","pos":"NOM"} ,
		{"word":"ex-élève","word_nosc":"ex-eleve","lemma":"ex-élève","pos":"NOM"} ,
		{"word":"ex-épouse","word_nosc":"ex-epouse","lemma":"ex-épouse","pos":"NOM"} ,
		{"word":"ex-épouses","word_nosc":"ex-epouses","lemma":"ex-épouse","pos":"NOM"} ,
		{"word":"ex-équipier","word_nosc":"ex-equipier","lemma":"ex-équipier","pos":"NOM"} ,
		{"word":"exacerbation","word_nosc":"exacerbation","lemma":"exacerbation","pos":"NOM"} ,
		{"word":"exaction","word_nosc":"exaction","lemma":"exaction","pos":"NOM"} ,
		{"word":"exactions","word_nosc":"exactions","lemma":"exaction","pos":"NOM"} ,
		{"word":"exactitude","word_nosc":"exactitude","lemma":"exactitude","pos":"NOM"} ,
		{"word":"exactitudes","word_nosc":"exactitudes","lemma":"exactitude","pos":"NOM"} ,
		{"word":"exagération","word_nosc":"exageration","lemma":"exagération","pos":"NOM"} ,
		{"word":"exagérations","word_nosc":"exagerations","lemma":"exagération","pos":"NOM"} ,
		{"word":"exaltation","word_nosc":"exaltation","lemma":"exaltation","pos":"NOM"} ,
		{"word":"exaltations","word_nosc":"exaltations","lemma":"exaltation","pos":"NOM"} ,
		{"word":"exalté","word_nosc":"exalte","lemma":"exalté","pos":"NOM"} ,
		{"word":"exaltée","word_nosc":"exaltee","lemma":"exalté","pos":"NOM"} ,
		{"word":"exaltées","word_nosc":"exaltees","lemma":"exalté","pos":"NOM"} ,
		{"word":"exaltés","word_nosc":"exaltes","lemma":"exalté","pos":"NOM"} ,
		{"word":"exam","word_nosc":"exam","lemma":"exam","pos":"NOM"} ,
		{"word":"examen","word_nosc":"examen","lemma":"examen","pos":"NOM"} ,
		{"word":"examens","word_nosc":"examens","lemma":"examen","pos":"NOM"} ,
		{"word":"examinateur","word_nosc":"examinateur","lemma":"examinateur","pos":"NOM"} ,
		{"word":"examinateurs","word_nosc":"examinateurs","lemma":"examinateur","pos":"NOM"} ,
		{"word":"examinatrice","word_nosc":"examinatrice","lemma":"examinateur","pos":"NOM"} ,
		{"word":"exams","word_nosc":"exams","lemma":"exam","pos":"NOM"} ,
		{"word":"exarque","word_nosc":"exarque","lemma":"exarque","pos":"NOM"} ,
		{"word":"exaspération","word_nosc":"exasperation","lemma":"exaspération","pos":"NOM"} ,
		{"word":"exaspérations","word_nosc":"exasperations","lemma":"exaspération","pos":"NOM"} ,
		{"word":"exaucement","word_nosc":"exaucement","lemma":"exaucement","pos":"NOM"} ,
		{"word":"excavateur","word_nosc":"excavateur","lemma":"excavateur","pos":"NOM"} ,
		{"word":"excavateurs","word_nosc":"excavateurs","lemma":"excavateur","pos":"NOM"} ,
		{"word":"excavation","word_nosc":"excavation","lemma":"excavation","pos":"NOM"} ,
		{"word":"excavations","word_nosc":"excavations","lemma":"excavation","pos":"NOM"} ,
		{"word":"excavatrice","word_nosc":"excavatrice","lemma":"excavateur","pos":"NOM"} ,
		{"word":"excellence","word_nosc":"excellence","lemma":"excellence","pos":"NOM"} ,
		{"word":"excellences","word_nosc":"excellences","lemma":"excellence","pos":"NOM"} ,
		{"word":"excentricité","word_nosc":"excentricite","lemma":"excentricité","pos":"NOM"} ,
		{"word":"excentricités","word_nosc":"excentricites","lemma":"excentricité","pos":"NOM"} ,
		{"word":"excentrique","word_nosc":"excentrique","lemma":"excentrique","pos":"NOM"} ,
		{"word":"excentriques","word_nosc":"excentriques","lemma":"excentrique","pos":"NOM"} ,
		{"word":"exception","word_nosc":"exception","lemma":"exception","pos":"NOM"} ,
		{"word":"exceptions","word_nosc":"exceptions","lemma":"exception","pos":"NOM"} ,
		{"word":"exciseuse","word_nosc":"exciseuse","lemma":"exciseur","pos":"NOM"} ,
		{"word":"exciseuses","word_nosc":"exciseuses","lemma":"exciseur","pos":"NOM"} ,
		{"word":"excision","word_nosc":"excision","lemma":"excision","pos":"NOM"} ,
		{"word":"excitant","word_nosc":"excitant","lemma":"excitant","pos":"NOM"} ,
		{"word":"excitants","word_nosc":"excitants","lemma":"excitant","pos":"NOM"} ,
		{"word":"excitation","word_nosc":"excitation","lemma":"excitation","pos":"NOM"} ,
		{"word":"excitations","word_nosc":"excitations","lemma":"excitation","pos":"NOM"} ,
		{"word":"exciteur","word_nosc":"exciteur","lemma":"exciteur","pos":"NOM"} ,
		{"word":"excité","word_nosc":"excite","lemma":"excité","pos":"NOM"} ,
		{"word":"excitée","word_nosc":"excitee","lemma":"excité","pos":"NOM"} ,
		{"word":"excitées","word_nosc":"excitees","lemma":"excité","pos":"NOM"} ,
		{"word":"excités","word_nosc":"excites","lemma":"excité","pos":"NOM"} ,
		{"word":"exclamation","word_nosc":"exclamation","lemma":"exclamation","pos":"NOM"} ,
		{"word":"exclamations","word_nosc":"exclamations","lemma":"exclamation","pos":"NOM"} ,
		{"word":"exclu","word_nosc":"exclu","lemma":"exclu","pos":"NOM"} ,
		{"word":"exclue","word_nosc":"exclue","lemma":"exclu","pos":"NOM"} ,
		{"word":"exclues","word_nosc":"exclues","lemma":"exclu","pos":"NOM"} ,
		{"word":"exclus","word_nosc":"exclus","lemma":"exclu","pos":"NOM"} ,
		{"word":"exclusion","word_nosc":"exclusion","lemma":"exclusion","pos":"NOM"} ,
		{"word":"exclusions","word_nosc":"exclusions","lemma":"exclusion","pos":"NOM"} ,
		{"word":"exclusive","word_nosc":"exclusive","lemma":"exclusif","pos":"NOM"} ,
		{"word":"exclusives","word_nosc":"exclusives","lemma":"exclusif","pos":"NOM"} ,
		{"word":"exclusivité","word_nosc":"exclusivite","lemma":"exclusivité","pos":"NOM"} ,
		{"word":"exclusivités","word_nosc":"exclusivites","lemma":"exclusivité","pos":"NOM"} ,
		{"word":"excommunication","word_nosc":"excommunication","lemma":"excommunication","pos":"NOM"} ,
		{"word":"excommunications","word_nosc":"excommunications","lemma":"excommunication","pos":"NOM"} ,
		{"word":"excommunié","word_nosc":"excommunie","lemma":"excommunié","pos":"NOM"} ,
		{"word":"excommuniés","word_nosc":"excommunies","lemma":"excommunié","pos":"NOM"} ,
		{"word":"excroissance","word_nosc":"excroissance","lemma":"excroissance","pos":"NOM"} ,
		{"word":"excroissances","word_nosc":"excroissances","lemma":"excroissance","pos":"NOM"} ,
		{"word":"excrément","word_nosc":"excrement","lemma":"excrément","pos":"NOM"} ,
		{"word":"excréments","word_nosc":"excrements","lemma":"excrément","pos":"NOM"} ,
		{"word":"excrétion","word_nosc":"excretion","lemma":"excrétion","pos":"NOM"} ,
		{"word":"excrétions","word_nosc":"excretions","lemma":"excrétion","pos":"NOM"} ,
		{"word":"excursion","word_nosc":"excursion","lemma":"excursion","pos":"NOM"} ,
		{"word":"excursionniste","word_nosc":"excursionniste","lemma":"excursionniste","pos":"NOM"} ,
		{"word":"excursionnistes","word_nosc":"excursionnistes","lemma":"excursionniste","pos":"NOM"} ,
		{"word":"excursions","word_nosc":"excursions","lemma":"excursion","pos":"NOM"} ,
		{"word":"excuse","word_nosc":"excuse","lemma":"excuse","pos":"NOM"} ,
		{"word":"excuses","word_nosc":"excuses","lemma":"excuse","pos":"NOM"} ,
		{"word":"excès","word_nosc":"exces","lemma":"excès","pos":"NOM"} ,
		{"word":"excédent","word_nosc":"excedent","lemma":"excédent","pos":"NOM"} ,
		{"word":"excédents","word_nosc":"excedents","lemma":"excédent","pos":"NOM"} ,
		{"word":"exeat","word_nosc":"exeat","lemma":"exeat","pos":"NOM"} ,
		{"word":"exemplaire","word_nosc":"exemplaire","lemma":"exemplaire","pos":"NOM"} ,
		{"word":"exemplaires","word_nosc":"exemplaires","lemma":"exemplaire","pos":"NOM"} ,
		{"word":"exemplarité","word_nosc":"exemplarite","lemma":"exemplarité","pos":"NOM"} ,
		{"word":"exemple","word_nosc":"exemple","lemma":"exemple","pos":"NOM"} ,
		{"word":"exemples","word_nosc":"exemples","lemma":"exemple","pos":"NOM"} ,
		{"word":"exempt","word_nosc":"exempt","lemma":"exempt","pos":"NOM"} ,
		{"word":"exemption","word_nosc":"exemption","lemma":"exemption","pos":"NOM"} ,
		{"word":"exemptions","word_nosc":"exemptions","lemma":"exemption","pos":"NOM"} ,
		{"word":"exempts","word_nosc":"exempts","lemma":"exempt","pos":"NOM"} ,
		{"word":"exempté","word_nosc":"exempte","lemma":"exempté","pos":"NOM"} ,
		{"word":"exequatur","word_nosc":"exequatur","lemma":"exequatur","pos":"NOM"} ,
		{"word":"exercice","word_nosc":"exercice","lemma":"exercice","pos":"NOM"} ,
		{"word":"exercices","word_nosc":"exercices","lemma":"exercice","pos":"NOM"} ,
		{"word":"exergue","word_nosc":"exergue","lemma":"exergue","pos":"NOM"} ,
		{"word":"exfiltration","word_nosc":"exfiltration","lemma":"exfiltration","pos":"NOM"} ,
		{"word":"exhalaison","word_nosc":"exhalaison","lemma":"exhalaison","pos":"NOM"} ,
		{"word":"exhalaisons","word_nosc":"exhalaisons","lemma":"exhalaison","pos":"NOM"} ,
		{"word":"exhalation","word_nosc":"exhalation","lemma":"exhalation","pos":"NOM"} ,
		{"word":"exhaussement","word_nosc":"exhaussement","lemma":"exhaussement","pos":"NOM"} ,
		{"word":"exhaustion","word_nosc":"exhaustion","lemma":"exhaustion","pos":"NOM"} ,
		{"word":"exhaustivité","word_nosc":"exhaustivite","lemma":"exhaustivité","pos":"NOM"} ,
		{"word":"exhibition","word_nosc":"exhibition","lemma":"exhibition","pos":"NOM"} ,
		{"word":"exhibitionnisme","word_nosc":"exhibitionnisme","lemma":"exhibitionnisme","pos":"NOM"} ,
		{"word":"exhibitionniste","word_nosc":"exhibitionniste","lemma":"exhibitionniste","pos":"NOM"} ,
		{"word":"exhibitionnistes","word_nosc":"exhibitionnistes","lemma":"exhibitionniste","pos":"NOM"} ,
		{"word":"exhibitions","word_nosc":"exhibitions","lemma":"exhibition","pos":"NOM"} ,
		{"word":"exhortation","word_nosc":"exhortation","lemma":"exhortation","pos":"NOM"} ,
		{"word":"exhortations","word_nosc":"exhortations","lemma":"exhortation","pos":"NOM"} ,
		{"word":"exhumation","word_nosc":"exhumation","lemma":"exhumation","pos":"NOM"} ,
		{"word":"exigence","word_nosc":"exigence","lemma":"exigence","pos":"NOM"} ,
		{"word":"exigences","word_nosc":"exigences","lemma":"exigence","pos":"NOM"} ,
		{"word":"exiguïté","word_nosc":"exiguite","lemma":"exiguïté","pos":"NOM"} ,
		{"word":"exil","word_nosc":"exil","lemma":"exil","pos":"NOM"} ,
		{"word":"exils","word_nosc":"exils","lemma":"exil","pos":"NOM"} ,
		{"word":"exilé","word_nosc":"exile","lemma":"exilé","pos":"NOM"} ,
		{"word":"exilée","word_nosc":"exilee","lemma":"exilé","pos":"NOM"} ,
		{"word":"exilées","word_nosc":"exilees","lemma":"exilé","pos":"NOM"} ,
		{"word":"exilés","word_nosc":"exiles","lemma":"exilé","pos":"NOM"} ,
		{"word":"existence","word_nosc":"existence","lemma":"existence","pos":"NOM"} ,
		{"word":"existences","word_nosc":"existences","lemma":"existence","pos":"NOM"} ,
		{"word":"existentialisme","word_nosc":"existentialisme","lemma":"existentialisme","pos":"NOM"} ,
		{"word":"existentialiste","word_nosc":"existentialiste","lemma":"existentialiste","pos":"NOM"} ,
		{"word":"existentialistes","word_nosc":"existentialistes","lemma":"existentialiste","pos":"NOM"} ,
		{"word":"exit","word_nosc":"exit","lemma":"exit","pos":"NOM"} ,
		{"word":"exo","word_nosc":"exo","lemma":"exo","pos":"NOM"} ,
		{"word":"exobiologie","word_nosc":"exobiologie","lemma":"exobiologie","pos":"NOM"} ,
		{"word":"exocet","word_nosc":"exocet","lemma":"exocet","pos":"NOM"} ,
		{"word":"exode","word_nosc":"exode","lemma":"exode","pos":"NOM"} ,
		{"word":"exodes","word_nosc":"exodes","lemma":"exode","pos":"NOM"} ,
		{"word":"exogamie","word_nosc":"exogamie","lemma":"exogamie","pos":"NOM"} ,
		{"word":"exonération","word_nosc":"exoneration","lemma":"exonération","pos":"NOM"} ,
		{"word":"exorcisme","word_nosc":"exorcisme","lemma":"exorcisme","pos":"NOM"} ,
		{"word":"exorcismes","word_nosc":"exorcismes","lemma":"exorcisme","pos":"NOM"} ,
		{"word":"exorciste","word_nosc":"exorciste","lemma":"exorciste","pos":"NOM"} ,
		{"word":"exorcistes","word_nosc":"exorcistes","lemma":"exorciste","pos":"NOM"} ,
		{"word":"exorde","word_nosc":"exorde","lemma":"exorde","pos":"NOM"} ,
		{"word":"exordes","word_nosc":"exordes","lemma":"exorde","pos":"NOM"} ,
		{"word":"exos","word_nosc":"exos","lemma":"exo","pos":"NOM"} ,
		{"word":"exosphère","word_nosc":"exosphere","lemma":"exosphère","pos":"NOM"} ,
		{"word":"exosquelette","word_nosc":"exosquelette","lemma":"exosquelette","pos":"NOM"} ,
		{"word":"exostose","word_nosc":"exostose","lemma":"exostose","pos":"NOM"} ,
		{"word":"exostoses","word_nosc":"exostoses","lemma":"exostose","pos":"NOM"} ,
		{"word":"exotisme","word_nosc":"exotisme","lemma":"exotisme","pos":"NOM"} ,
		{"word":"expansion","word_nosc":"expansion","lemma":"expansion","pos":"NOM"} ,
		{"word":"expansionnisme","word_nosc":"expansionnisme","lemma":"expansionnisme","pos":"NOM"} ,
		{"word":"expansionniste","word_nosc":"expansionniste","lemma":"expansionniste","pos":"NOM"} ,
		{"word":"expansionnistes","word_nosc":"expansionnistes","lemma":"expansionniste","pos":"NOM"} ,
		{"word":"expatriation","word_nosc":"expatriation","lemma":"expatriation","pos":"NOM"} ,
		{"word":"expatrié","word_nosc":"expatrie","lemma":"expatrié","pos":"NOM"} ,
		{"word":"expatriés","word_nosc":"expatries","lemma":"expatrié","pos":"NOM"} ,
		{"word":"expectation","word_nosc":"expectation","lemma":"expectation","pos":"NOM"} ,
		{"word":"expectative","word_nosc":"expectative","lemma":"expectative","pos":"NOM"} ,
		{"word":"expectatives","word_nosc":"expectatives","lemma":"expectative","pos":"NOM"} ,
		{"word":"expectorant","word_nosc":"expectorant","lemma":"expectorant","pos":"NOM"} ,
		{"word":"expectoration","word_nosc":"expectoration","lemma":"expectoration","pos":"NOM"} ,
		{"word":"expert","word_nosc":"expert","lemma":"expert","pos":"NOM"} ,
		{"word":"expert-comptable","word_nosc":"expert-comptable","lemma":"expert-comptable","pos":"NOM"} ,
		{"word":"expertise","word_nosc":"expertise","lemma":"expertise","pos":"NOM"} ,
		{"word":"expertises","word_nosc":"expertises","lemma":"expertise","pos":"NOM"} ,
		{"word":"experts","word_nosc":"experts","lemma":"expert","pos":"NOM"} ,
		{"word":"expiation","word_nosc":"expiation","lemma":"expiation","pos":"NOM"} ,
		{"word":"expiration","word_nosc":"expiration","lemma":"expiration","pos":"NOM"} ,
		{"word":"expirations","word_nosc":"expirations","lemma":"expiration","pos":"NOM"} ,
		{"word":"explication","word_nosc":"explication","lemma":"explication","pos":"NOM"} ,
		{"word":"explications","word_nosc":"explications","lemma":"explication","pos":"NOM"} ,
		{"word":"exploit","word_nosc":"exploit","lemma":"exploit","pos":"NOM"} ,
		{"word":"exploitant","word_nosc":"exploitant","lemma":"exploitant","pos":"NOM"} ,
		{"word":"exploitante","word_nosc":"exploitante","lemma":"exploitant","pos":"NOM"} ,
		{"word":"exploitants","word_nosc":"exploitants","lemma":"exploitant","pos":"NOM"} ,
		{"word":"exploitation","word_nosc":"exploitation","lemma":"exploitation","pos":"NOM"} ,
		{"word":"exploitations","word_nosc":"exploitations","lemma":"exploitation","pos":"NOM"} ,
		{"word":"exploiteur","word_nosc":"exploiteur","lemma":"exploiteur","pos":"NOM"} ,
		{"word":"exploiteurs","word_nosc":"exploiteurs","lemma":"exploiteur","pos":"NOM"} ,
		{"word":"exploiteuses","word_nosc":"exploiteuses","lemma":"exploiteur","pos":"NOM"} ,
		{"word":"exploits","word_nosc":"exploits","lemma":"exploit","pos":"NOM"} ,
		{"word":"exploité","word_nosc":"exploite","lemma":"exploité","pos":"NOM"} ,
		{"word":"exploitées","word_nosc":"exploitees","lemma":"exploité","pos":"NOM"} ,
		{"word":"exploités","word_nosc":"exploites","lemma":"exploité","pos":"NOM"} ,
		{"word":"explorateur","word_nosc":"explorateur","lemma":"explorateur","pos":"NOM"} ,
		{"word":"explorateurs","word_nosc":"explorateurs","lemma":"explorateur","pos":"NOM"} ,
		{"word":"exploration","word_nosc":"exploration","lemma":"exploration","pos":"NOM"} ,
		{"word":"explorations","word_nosc":"explorations","lemma":"exploration","pos":"NOM"} ,
		{"word":"exploratrice","word_nosc":"exploratrice","lemma":"explorateur","pos":"NOM"} ,
		{"word":"exploreur","word_nosc":"exploreur","lemma":"exploreur","pos":"NOM"} ,
		{"word":"exploseur","word_nosc":"exploseur","lemma":"exploseur","pos":"NOM"} ,
		{"word":"explosif","word_nosc":"explosif","lemma":"explosif","pos":"NOM"} ,
		{"word":"explosifs","word_nosc":"explosifs","lemma":"explosif","pos":"NOM"} ,
		{"word":"explosion","word_nosc":"explosion","lemma":"explosion","pos":"NOM"} ,
		{"word":"explosions","word_nosc":"explosions","lemma":"explosion","pos":"NOM"} ,
		{"word":"explosive","word_nosc":"explosive","lemma":"explosif","pos":"NOM"} ,
		{"word":"explétif","word_nosc":"expletif","lemma":"explétif","pos":"NOM"} ,
		{"word":"expo","word_nosc":"expo","lemma":"expo","pos":"NOM"} ,
		{"word":"export","word_nosc":"export","lemma":"export","pos":"NOM"} ,
		{"word":"exportateur","word_nosc":"exportateur","lemma":"exportateur","pos":"NOM"} ,
		{"word":"exportateurs","word_nosc":"exportateurs","lemma":"exportateur","pos":"NOM"} ,
		{"word":"exportation","word_nosc":"exportation","lemma":"exportation","pos":"NOM"} ,
		{"word":"exportations","word_nosc":"exportations","lemma":"exportation","pos":"NOM"} ,
		{"word":"exports","word_nosc":"exports","lemma":"export","pos":"NOM"} ,
		{"word":"expos","word_nosc":"expos","lemma":"expo","pos":"NOM"} ,
		{"word":"exposant","word_nosc":"exposant","lemma":"exposant","pos":"NOM"} ,
		{"word":"exposition","word_nosc":"exposition","lemma":"exposition","pos":"NOM"} ,
		{"word":"expositions","word_nosc":"expositions","lemma":"exposition","pos":"NOM"} ,
		{"word":"exposé","word_nosc":"expose","lemma":"exposé","pos":"NOM"} ,
		{"word":"exposés","word_nosc":"exposes","lemma":"exposé","pos":"NOM"} ,
		{"word":"express","word_nosc":"express","lemma":"express","pos":"NOM"} ,
		{"word":"expression","word_nosc":"expression","lemma":"expression","pos":"NOM"} ,
		{"word":"expressionnisme","word_nosc":"expressionnisme","lemma":"expressionnisme","pos":"NOM"} ,
		{"word":"expressionniste","word_nosc":"expressionniste","lemma":"expressionniste","pos":"NOM"} ,
		{"word":"expressionnistes","word_nosc":"expressionnistes","lemma":"expressionniste","pos":"NOM"} ,
		{"word":"expressions","word_nosc":"expressions","lemma":"expression","pos":"NOM"} ,
		{"word":"expropriation","word_nosc":"expropriation","lemma":"expropriation","pos":"NOM"} ,
		{"word":"expropriations","word_nosc":"expropriations","lemma":"expropriation","pos":"NOM"} ,
		{"word":"expropriée","word_nosc":"expropriee","lemma":"exproprié","pos":"NOM"} ,
		{"word":"exprès","word_nosc":"expres","lemma":"exprès","pos":"NOM"} ,
		{"word":"expulsion","word_nosc":"expulsion","lemma":"expulsion","pos":"NOM"} ,
		{"word":"expulsions","word_nosc":"expulsions","lemma":"expulsion","pos":"NOM"} ,
		{"word":"expulsé","word_nosc":"expulse","lemma":"expulsé","pos":"NOM"} ,
		{"word":"expulsés","word_nosc":"expulses","lemma":"expulsé","pos":"NOM"} ,
		{"word":"expédient","word_nosc":"expedient","lemma":"expédient","pos":"NOM"} ,
		{"word":"expédients","word_nosc":"expedients","lemma":"expédient","pos":"NOM"} ,
		{"word":"expéditeur","word_nosc":"expediteur","lemma":"expéditeur","pos":"NOM"} ,
		{"word":"expéditeurs","word_nosc":"expediteurs","lemma":"expéditeur","pos":"NOM"} ,
		{"word":"expédition","word_nosc":"expedition","lemma":"expédition","pos":"NOM"} ,
		{"word":"expéditionnaire","word_nosc":"expeditionnaire","lemma":"expéditionnaire","pos":"NOM"} ,
		{"word":"expéditions","word_nosc":"expeditions","lemma":"expédition","pos":"NOM"} ,
		{"word":"expéditrice","word_nosc":"expeditrice","lemma":"expéditeur","pos":"NOM"} ,
		{"word":"expédiée","word_nosc":"expediee","lemma":"expédiée","pos":"NOM"} ,
		{"word":"expédiées","word_nosc":"expediees","lemma":"expédiée","pos":"NOM"} ,
		{"word":"expérience","word_nosc":"experience","lemma":"expérience","pos":"NOM"} ,
		{"word":"expériences","word_nosc":"experiences","lemma":"expérience","pos":"NOM"} ,
		{"word":"expérimentateur","word_nosc":"experimentateur","lemma":"expérimentateur","pos":"NOM"} ,
		{"word":"expérimentation","word_nosc":"experimentation","lemma":"expérimentation","pos":"NOM"} ,
		{"word":"expérimentations","word_nosc":"experimentations","lemma":"expérimentation","pos":"NOM"} ,
		{"word":"exsudat","word_nosc":"exsudat","lemma":"exsudat","pos":"NOM"} ,
		{"word":"exsudation","word_nosc":"exsudation","lemma":"exsudation","pos":"NOM"} ,
		{"word":"extase","word_nosc":"extase","lemma":"extase","pos":"NOM"} ,
		{"word":"extases","word_nosc":"extases","lemma":"extase","pos":"NOM"} ,
		{"word":"extatique","word_nosc":"extatique","lemma":"extatique","pos":"NOM"} ,
		{"word":"extenseur","word_nosc":"extenseur","lemma":"extenseur","pos":"NOM"} ,
		{"word":"extenseurs","word_nosc":"extenseurs","lemma":"extenseur","pos":"NOM"} ,
		{"word":"extension","word_nosc":"extension","lemma":"extension","pos":"NOM"} ,
		{"word":"extensions","word_nosc":"extensions","lemma":"extension","pos":"NOM"} ,
		{"word":"exterminateur","word_nosc":"exterminateur","lemma":"exterminateur","pos":"NOM"} ,
		{"word":"exterminateurs","word_nosc":"exterminateurs","lemma":"exterminateur","pos":"NOM"} ,
		{"word":"extermination","word_nosc":"extermination","lemma":"extermination","pos":"NOM"} ,
		{"word":"exterminations","word_nosc":"exterminations","lemma":"extermination","pos":"NOM"} ,
		{"word":"externalité","word_nosc":"externalite","lemma":"externalité","pos":"NOM"} ,
		{"word":"externat","word_nosc":"externat","lemma":"externat","pos":"NOM"} ,
		{"word":"externe","word_nosc":"externe","lemma":"externe","pos":"NOM"} ,
		{"word":"externes","word_nosc":"externes","lemma":"externe","pos":"NOM"} ,
		{"word":"exterritorialité","word_nosc":"exterritorialite","lemma":"exterritorialité","pos":"NOM"} ,
		{"word":"extincteur","word_nosc":"extincteur","lemma":"extincteur","pos":"NOM"} ,
		{"word":"extincteurs","word_nosc":"extincteurs","lemma":"extincteur","pos":"NOM"} ,
		{"word":"extinction","word_nosc":"extinction","lemma":"extinction","pos":"NOM"} ,
		{"word":"extirpation","word_nosc":"extirpation","lemma":"extirpation","pos":"NOM"} ,
		{"word":"extorqueur","word_nosc":"extorqueur","lemma":"extorqueur","pos":"NOM"} ,
		{"word":"extorsion","word_nosc":"extorsion","lemma":"extorsion","pos":"NOM"} ,
		{"word":"extorsions","word_nosc":"extorsions","lemma":"extorsion","pos":"NOM"} ,
		{"word":"extra","word_nosc":"extra","lemma":"extra","pos":"NOM"} ,
		{"word":"extra-dry","word_nosc":"extra-dry","lemma":"extra-dry","pos":"NOM"} ,
		{"word":"extra-fort","word_nosc":"extra-fort","lemma":"extra-fort","pos":"NOM"} ,
		{"word":"extra-terrestre","word_nosc":"extra-terrestre","lemma":"extra-terrestre","pos":"NOM"} ,
		{"word":"extra-terrestres","word_nosc":"extra-terrestres","lemma":"extra-terrestre","pos":"NOM"} ,
		{"word":"extracteur","word_nosc":"extracteur","lemma":"extracteur","pos":"NOM"} ,
		{"word":"extracteurs","word_nosc":"extracteurs","lemma":"extracteur","pos":"NOM"} ,
		{"word":"extraction","word_nosc":"extraction","lemma":"extraction","pos":"NOM"} ,
		{"word":"extractions","word_nosc":"extractions","lemma":"extraction","pos":"NOM"} ,
		{"word":"extradition","word_nosc":"extradition","lemma":"extradition","pos":"NOM"} ,
		{"word":"extrait","word_nosc":"extrait","lemma":"extrait","pos":"NOM"} ,
		{"word":"extraits","word_nosc":"extraits","lemma":"extrait","pos":"NOM"} ,
		{"word":"extralucide","word_nosc":"extralucide","lemma":"extralucide","pos":"NOM"} ,
		{"word":"extralucides","word_nosc":"extralucides","lemma":"extralucide","pos":"NOM"} ,
		{"word":"extraordinaire","word_nosc":"extraordinaire","lemma":"extraordinaire","pos":"NOM"} ,
		{"word":"extraordinaires","word_nosc":"extraordinaires","lemma":"extraordinaire","pos":"NOM"} ,
		{"word":"extrapolation","word_nosc":"extrapolation","lemma":"extrapolation","pos":"NOM"} ,
		{"word":"extrapolations","word_nosc":"extrapolations","lemma":"extrapolation","pos":"NOM"} ,
		{"word":"extras","word_nosc":"extras","lemma":"extra","pos":"NOM"} ,
		{"word":"extrasystole","word_nosc":"extrasystole","lemma":"extrasystole","pos":"NOM"} ,
		{"word":"extraterrestre","word_nosc":"extraterrestre","lemma":"extraterrestre","pos":"NOM"} ,
		{"word":"extraterrestres","word_nosc":"extraterrestres","lemma":"extraterrestre","pos":"NOM"} ,
		{"word":"extravagance","word_nosc":"extravagance","lemma":"extravagance","pos":"NOM"} ,
		{"word":"extravagances","word_nosc":"extravagances","lemma":"extravagance","pos":"NOM"} ,
		{"word":"extravagant","word_nosc":"extravagant","lemma":"extravagant","pos":"NOM"} ,
		{"word":"extravagante","word_nosc":"extravagante","lemma":"extravagant","pos":"NOM"} ,
		{"word":"extravagantes","word_nosc":"extravagantes","lemma":"extravagant","pos":"NOM"} ,
		{"word":"extravagants","word_nosc":"extravagants","lemma":"extravagant","pos":"NOM"} ,
		{"word":"extravasation","word_nosc":"extravasation","lemma":"extravasation","pos":"NOM"} ,
		{"word":"extraverti","word_nosc":"extraverti","lemma":"extraverti","pos":"NOM"} ,
		{"word":"extravertie","word_nosc":"extravertie","lemma":"extraverti","pos":"NOM"} ,
		{"word":"extrusion","word_nosc":"extrusion","lemma":"extrusion","pos":"NOM"} ,
		{"word":"extrémisme","word_nosc":"extremisme","lemma":"extrémisme","pos":"NOM"} ,
		{"word":"extrémiste","word_nosc":"extremiste","lemma":"extrémiste","pos":"NOM"} ,
		{"word":"extrémistes","word_nosc":"extremistes","lemma":"extrémiste","pos":"NOM"} ,
		{"word":"extrémité","word_nosc":"extremite","lemma":"extrémité","pos":"NOM"} ,
		{"word":"extrémités","word_nosc":"extremites","lemma":"extrémité","pos":"NOM"} ,
		{"word":"extrême","word_nosc":"extreme","lemma":"extrême","pos":"NOM"} ,
		{"word":"extrême-onction","word_nosc":"extreme-onction","lemma":"extrême-onction","pos":"NOM"} ,
		{"word":"extrême-orient","word_nosc":"extreme-orient","lemma":"extrême-orient","pos":"NOM"} ,
		{"word":"extrêmes","word_nosc":"extremes","lemma":"extrême","pos":"NOM"} ,
		{"word":"extrêmes-onctions","word_nosc":"extremes-onctions","lemma":"extrême-onction","pos":"NOM"} ,
		{"word":"exténuation","word_nosc":"extenuation","lemma":"exténuation","pos":"NOM"} ,
		{"word":"exténuement","word_nosc":"extenuement","lemma":"exténuement","pos":"NOM"} ,
		{"word":"extérieur","word_nosc":"exterieur","lemma":"extérieur","pos":"NOM"} ,
		{"word":"extérieurs","word_nosc":"exterieurs","lemma":"extérieur","pos":"NOM"} ,
		{"word":"extériorisation","word_nosc":"exteriorisation","lemma":"extériorisation","pos":"NOM"} ,
		{"word":"extériorité","word_nosc":"exteriorite","lemma":"extériorité","pos":"NOM"} ,
		{"word":"exubérance","word_nosc":"exuberance","lemma":"exubérance","pos":"NOM"} ,
		{"word":"exubérances","word_nosc":"exuberances","lemma":"exubérance","pos":"NOM"} ,
		{"word":"exultation","word_nosc":"exultation","lemma":"exultation","pos":"NOM"} ,
		{"word":"exutoire","word_nosc":"exutoire","lemma":"exutoire","pos":"NOM"} ,
		{"word":"exutoires","word_nosc":"exutoires","lemma":"exutoire","pos":"NOM"} ,
		{"word":"exécration","word_nosc":"execration","lemma":"exécration","pos":"NOM"} ,
		{"word":"exécrations","word_nosc":"execrations","lemma":"exécration","pos":"NOM"} ,
		{"word":"exécutant","word_nosc":"executant","lemma":"exécutant","pos":"NOM"} ,
		{"word":"exécutants","word_nosc":"executants","lemma":"exécutant","pos":"NOM"} ,
		{"word":"exécuteur","word_nosc":"executeur","lemma":"exécuteur","pos":"NOM"} ,
		{"word":"exécuteurs","word_nosc":"executeurs","lemma":"exécuteur","pos":"NOM"} ,
		{"word":"exécutif","word_nosc":"executif","lemma":"exécutif","pos":"NOM"} ,
		{"word":"exécutifs","word_nosc":"executifs","lemma":"exécutif","pos":"NOM"} ,
		{"word":"exécution","word_nosc":"execution","lemma":"exécution","pos":"NOM"} ,
		{"word":"exécutions","word_nosc":"executions","lemma":"exécution","pos":"NOM"} ,
		{"word":"exécutrice","word_nosc":"executrice","lemma":"exécuteur","pos":"NOM"} ,
		{"word":"exégèse","word_nosc":"exegese","lemma":"exégèse","pos":"NOM"} ,
		{"word":"exégèses","word_nosc":"exegeses","lemma":"exégèse","pos":"NOM"} ,
		{"word":"exégète","word_nosc":"exegete","lemma":"exégète","pos":"NOM"} ,
		{"word":"exégètes","word_nosc":"exegetes","lemma":"exégète","pos":"NOM"} ,
		{"word":"eye-liner","word_nosc":"eye-liner","lemma":"eye-liner","pos":"NOM"} ,
		{"word":"eyeliner","word_nosc":"eyeliner","lemma":"eyeliner","pos":"NOM"} ,
		{"word":"f","word_nosc":"f","lemma":"f","pos":"NOM"} ,
		{"word":"fa","word_nosc":"fa","lemma":"fa","pos":"NOM"} ,
		{"word":"fabiens","word_nosc":"fabiens","lemma":"fabien","pos":"NOM"} ,
		{"word":"fable","word_nosc":"fable","lemma":"fable","pos":"NOM"} ,
		{"word":"fables","word_nosc":"fables","lemma":"fable","pos":"NOM"} ,
		{"word":"fabliau","word_nosc":"fabliau","lemma":"fabliau","pos":"NOM"} ,
		{"word":"fabliaux","word_nosc":"fabliaux","lemma":"fabliau","pos":"NOM"} ,
		{"word":"fabricant","word_nosc":"fabricant","lemma":"fabricant","pos":"NOM"} ,
		{"word":"fabricante","word_nosc":"fabricante","lemma":"fabricant","pos":"NOM"} ,
		{"word":"fabricantes","word_nosc":"fabricantes","lemma":"fabricant","pos":"NOM"} ,
		{"word":"fabricants","word_nosc":"fabricants","lemma":"fabricant","pos":"NOM"} ,
		{"word":"fabrication","word_nosc":"fabrication","lemma":"fabrication","pos":"NOM"} ,
		{"word":"fabrications","word_nosc":"fabrications","lemma":"fabrication","pos":"NOM"} ,
		{"word":"fabrique","word_nosc":"fabrique","lemma":"fabrique","pos":"NOM"} ,
		{"word":"fabriques","word_nosc":"fabriques","lemma":"fabrique","pos":"NOM"} ,
		{"word":"fabulation","word_nosc":"fabulation","lemma":"fabulation","pos":"NOM"} ,
		{"word":"fabulations","word_nosc":"fabulations","lemma":"fabulation","pos":"NOM"} ,
		{"word":"fabulatrice","word_nosc":"fabulatrice","lemma":"fabulateur","pos":"NOM"} ,
		{"word":"fabuliste","word_nosc":"fabuliste","lemma":"fabuliste","pos":"NOM"} ,
		{"word":"fac","word_nosc":"fac","lemma":"fac","pos":"NOM"} ,
		{"word":"fac-similé","word_nosc":"fac-simile","lemma":"fac-similé","pos":"NOM"} ,
		{"word":"fac-similés","word_nosc":"fac-similes","lemma":"fac-similé","pos":"NOM"} ,
		{"word":"face","word_nosc":"face","lemma":"face","pos":"NOM"} ,
		{"word":"face-à-face","word_nosc":"face-a-face","lemma":"face-à-face","pos":"NOM"} ,
		{"word":"face-à-main","word_nosc":"face-a-main","lemma":"face-à-main","pos":"NOM"} ,
		{"word":"faces","word_nosc":"faces","lemma":"face","pos":"NOM"} ,
		{"word":"faces-à-main","word_nosc":"faces-a-main","lemma":"faces-à-main","pos":"NOM"} ,
		{"word":"facette","word_nosc":"facette","lemma":"facette","pos":"NOM"} ,
		{"word":"facettes","word_nosc":"facettes","lemma":"facette","pos":"NOM"} ,
		{"word":"facho","word_nosc":"facho","lemma":"facho","pos":"NOM"} ,
		{"word":"fachos","word_nosc":"fachos","lemma":"facho","pos":"NOM"} ,
		{"word":"facilitateur","word_nosc":"facilitateur","lemma":"facilitateur","pos":"NOM"} ,
		{"word":"facilitation","word_nosc":"facilitation","lemma":"facilitation","pos":"NOM"} ,
		{"word":"facilité","word_nosc":"facilite","lemma":"facilité","pos":"NOM"} ,
		{"word":"facilités","word_nosc":"facilites","lemma":"facilité","pos":"NOM"} ,
		{"word":"faciès","word_nosc":"facies","lemma":"faciès","pos":"NOM"} ,
		{"word":"faconde","word_nosc":"faconde","lemma":"faconde","pos":"NOM"} ,
		{"word":"facs","word_nosc":"facs","lemma":"fac","pos":"NOM"} ,
		{"word":"facteur","word_nosc":"facteur","lemma":"facteur","pos":"NOM"} ,
		{"word":"facteurs","word_nosc":"facteurs","lemma":"facteur","pos":"NOM"} ,
		{"word":"factieux","word_nosc":"factieux","lemma":"factieux","pos":"NOM"} ,
		{"word":"faction","word_nosc":"faction","lemma":"faction","pos":"NOM"} ,
		{"word":"factionnaire","word_nosc":"factionnaire","lemma":"factionnaire","pos":"NOM"} ,
		{"word":"factionnaires","word_nosc":"factionnaires","lemma":"factionnaire","pos":"NOM"} ,
		{"word":"factions","word_nosc":"factions","lemma":"faction","pos":"NOM"} ,
		{"word":"factor","word_nosc":"factor","lemma":"factor","pos":"NOM"} ,
		{"word":"factorielle","word_nosc":"factorielle","lemma":"factoriel","pos":"NOM"} ,
		{"word":"factorielles","word_nosc":"factorielles","lemma":"factoriel","pos":"NOM"} ,
		{"word":"factorisation","word_nosc":"factorisation","lemma":"factorisation","pos":"NOM"} ,
		{"word":"factotum","word_nosc":"factotum","lemma":"factotum","pos":"NOM"} ,
		{"word":"factotums","word_nosc":"factotums","lemma":"factotum","pos":"NOM"} ,
		{"word":"factrice","word_nosc":"factrice","lemma":"facteur","pos":"NOM"} ,
		{"word":"factum","word_nosc":"factum","lemma":"factum","pos":"NOM"} ,
		{"word":"factums","word_nosc":"factums","lemma":"factum","pos":"NOM"} ,
		{"word":"facturation","word_nosc":"facturation","lemma":"facturation","pos":"NOM"} ,
		{"word":"facture","word_nosc":"facture","lemma":"facture","pos":"NOM"} ,
		{"word":"factures","word_nosc":"factures","lemma":"facture","pos":"NOM"} ,
		{"word":"facturette","word_nosc":"facturette","lemma":"facturette","pos":"NOM"} ,
		{"word":"facturier","word_nosc":"facturier","lemma":"facturier","pos":"NOM"} ,
		{"word":"facturière","word_nosc":"facturiere","lemma":"facturier","pos":"NOM"} ,
		{"word":"faculté","word_nosc":"faculte","lemma":"faculté","pos":"NOM"} ,
		{"word":"facultés","word_nosc":"facultes","lemma":"faculté","pos":"NOM"} ,
		{"word":"facétie","word_nosc":"facetie","lemma":"facétie","pos":"NOM"} ,
		{"word":"facéties","word_nosc":"faceties","lemma":"facétie","pos":"NOM"} ,
		{"word":"fada","word_nosc":"fada","lemma":"fada","pos":"NOM"} ,
		{"word":"fadaise","word_nosc":"fadaise","lemma":"fadaise","pos":"NOM"} ,
		{"word":"fadaises","word_nosc":"fadaises","lemma":"fadaise","pos":"NOM"} ,
		{"word":"fadas","word_nosc":"fadas","lemma":"fada","pos":"NOM"} ,
		{"word":"fades","word_nosc":"fades","lemma":"fade","pos":"NOM"} ,
		{"word":"fadette","word_nosc":"fadette","lemma":"fadette","pos":"NOM"} ,
		{"word":"fadeur","word_nosc":"fadeur","lemma":"fadeur","pos":"NOM"} ,
		{"word":"fadeurs","word_nosc":"fadeurs","lemma":"fadeur","pos":"NOM"} ,
		{"word":"fading","word_nosc":"fading","lemma":"fading","pos":"NOM"} ,
		{"word":"fado","word_nosc":"fado","lemma":"fado","pos":"NOM"} ,
		{"word":"faena","word_nosc":"faena","lemma":"faena","pos":"NOM"} ,
		{"word":"faf","word_nosc":"faf","lemma":"faf","pos":"NOM"} ,
		{"word":"fafiot","word_nosc":"fafiot","lemma":"fafiot","pos":"NOM"} ,
		{"word":"fafiots","word_nosc":"fafiots","lemma":"fafiot","pos":"NOM"} ,
		{"word":"fafs","word_nosc":"fafs","lemma":"faf","pos":"NOM"} ,
		{"word":"fagne","word_nosc":"fagne","lemma":"fagne","pos":"NOM"} ,
		{"word":"fagnes","word_nosc":"fagnes","lemma":"fagne","pos":"NOM"} ,
		{"word":"fagot","word_nosc":"fagot","lemma":"fagot","pos":"NOM"} ,
		{"word":"fagotage","word_nosc":"fagotage","lemma":"fagotage","pos":"NOM"} ,
		{"word":"fagotiers","word_nosc":"fagotiers","lemma":"fagotier","pos":"NOM"} ,
		{"word":"fagotin","word_nosc":"fagotin","lemma":"fagotin","pos":"NOM"} ,
		{"word":"fagotins","word_nosc":"fagotins","lemma":"fagotin","pos":"NOM"} ,
		{"word":"fagots","word_nosc":"fagots","lemma":"fagot","pos":"NOM"} ,
		{"word":"faible","word_nosc":"faible","lemma":"faible","pos":"NOM"} ,
		{"word":"faibles","word_nosc":"faibles","lemma":"faible","pos":"NOM"} ,
		{"word":"faiblesse","word_nosc":"faiblesse","lemma":"faiblesse","pos":"NOM"} ,
		{"word":"faiblesses","word_nosc":"faiblesses","lemma":"faiblesse","pos":"NOM"} ,
		{"word":"faignant","word_nosc":"faignant","lemma":"faignant","pos":"NOM"} ,
		{"word":"faignants","word_nosc":"faignants","lemma":"faignant","pos":"NOM"} ,
		{"word":"faille","word_nosc":"faille","lemma":"faille","pos":"NOM"} ,
		{"word":"failles","word_nosc":"failles","lemma":"faille","pos":"NOM"} ,
		{"word":"failli","word_nosc":"failli","lemma":"failli","pos":"NOM"} ,
		{"word":"faillibilité","word_nosc":"faillibilite","lemma":"faillibilité","pos":"NOM"} ,
		{"word":"faillibilités","word_nosc":"faillibilites","lemma":"faillibilité","pos":"NOM"} ,
		{"word":"faillis","word_nosc":"faillis","lemma":"failli","pos":"NOM"} ,
		{"word":"faillite","word_nosc":"faillite","lemma":"faillite","pos":"NOM"} ,
		{"word":"faillites","word_nosc":"faillites","lemma":"faillite","pos":"NOM"} ,
		{"word":"faim","word_nosc":"faim","lemma":"faim","pos":"NOM"} ,
		{"word":"faims","word_nosc":"faims","lemma":"faim","pos":"NOM"} ,
		{"word":"faine","word_nosc":"faine","lemma":"faine","pos":"NOM"} ,
		{"word":"fainéant","word_nosc":"faineant","lemma":"fainéant","pos":"NOM"} ,
		{"word":"fainéante","word_nosc":"faineante","lemma":"fainéant","pos":"NOM"} ,
		{"word":"fainéantes","word_nosc":"faineantes","lemma":"fainéant","pos":"NOM"} ,
		{"word":"fainéantise","word_nosc":"faineantise","lemma":"fainéantise","pos":"NOM"} ,
		{"word":"fainéants","word_nosc":"faineants","lemma":"fainéant","pos":"NOM"} ,
		{"word":"fair-play","word_nosc":"fair-play","lemma":"fair-play","pos":"NOM"} ,
		{"word":"faire-part","word_nosc":"faire-part","lemma":"faire-part","pos":"NOM"} ,
		{"word":"faire-valoir","word_nosc":"faire-valoir","lemma":"faire-valoir","pos":"NOM"} ,
		{"word":"faisabilité","word_nosc":"faisabilite","lemma":"faisabilité","pos":"NOM"} ,
		{"word":"faisan","word_nosc":"faisan","lemma":"faisan","pos":"NOM"} ,
		{"word":"faisanderie","word_nosc":"faisanderie","lemma":"faisanderie","pos":"NOM"} ,
		{"word":"faisane","word_nosc":"faisane","lemma":"faisan","pos":"NOM"} ,
		{"word":"faisans","word_nosc":"faisans","lemma":"faisan","pos":"NOM"} ,
		{"word":"faisceau","word_nosc":"faisceau","lemma":"faisceau","pos":"NOM"} ,
		{"word":"faisceaux","word_nosc":"faisceaux","lemma":"faisceau","pos":"NOM"} ,
		{"word":"faiseur","word_nosc":"faiseur","lemma":"faiseur","pos":"NOM"} ,
		{"word":"faiseurs","word_nosc":"faiseurs","lemma":"faiseur","pos":"NOM"} ,
		{"word":"faiseuse","word_nosc":"faiseuse","lemma":"faiseur","pos":"NOM"} ,
		{"word":"faiseuses","word_nosc":"faiseuses","lemma":"faiseur","pos":"NOM"} ,
		{"word":"fait","word_nosc":"fait","lemma":"fait","pos":"NOM"} ,
		{"word":"fait-divers","word_nosc":"fait-divers","lemma":"fait-divers","pos":"NOM"} ,
		{"word":"fait-tout","word_nosc":"fait-tout","lemma":"fait-tout","pos":"NOM"} ,
		{"word":"faitout","word_nosc":"faitout","lemma":"faitout","pos":"NOM"} ,
		{"word":"faitouts","word_nosc":"faitouts","lemma":"faitout","pos":"NOM"} ,
		{"word":"faits","word_nosc":"faits","lemma":"fait","pos":"NOM"} ,
		{"word":"faits-divers","word_nosc":"faits-divers","lemma":"faits-divers","pos":"NOM"} ,
		{"word":"faix","word_nosc":"faix","lemma":"faix","pos":"NOM"} ,
		{"word":"fakir","word_nosc":"fakir","lemma":"fakir","pos":"NOM"} ,
		{"word":"fakirs","word_nosc":"fakirs","lemma":"fakir","pos":"NOM"} ,
		{"word":"falaise","word_nosc":"falaise","lemma":"falaise","pos":"NOM"} ,
		{"word":"falaises","word_nosc":"falaises","lemma":"falaise","pos":"NOM"} ,
		{"word":"falbala","word_nosc":"falbala","lemma":"falbala","pos":"NOM"} ,
		{"word":"falbalas","word_nosc":"falbalas","lemma":"falbala","pos":"NOM"} ,
		{"word":"falerne","word_nosc":"falerne","lemma":"falerne","pos":"NOM"} ,
		{"word":"falot","word_nosc":"falot","lemma":"falot","pos":"NOM"} ,
		{"word":"falots","word_nosc":"falots","lemma":"falot","pos":"NOM"} ,
		{"word":"falsification","word_nosc":"falsification","lemma":"falsification","pos":"NOM"} ,
		{"word":"faluche","word_nosc":"faluche","lemma":"faluche","pos":"NOM"} ,
		{"word":"falzar","word_nosc":"falzar","lemma":"falzar","pos":"NOM"} ,
		{"word":"falzars","word_nosc":"falzars","lemma":"falzar","pos":"NOM"} ,
		{"word":"familiale","word_nosc":"familiale","lemma":"familiale","pos":"NOM"} ,
		{"word":"familiales","word_nosc":"familiales","lemma":"familiale","pos":"NOM"} ,
		{"word":"familialiste","word_nosc":"familialiste","lemma":"familialiste","pos":"NOM"} ,
		{"word":"familiarité","word_nosc":"familiarite","lemma":"familiarité","pos":"NOM"} ,
		{"word":"familiarités","word_nosc":"familiarites","lemma":"familiarité","pos":"NOM"} ,
		{"word":"familier","word_nosc":"familier","lemma":"familier","pos":"NOM"} ,
		{"word":"familiers","word_nosc":"familiers","lemma":"familier","pos":"NOM"} ,
		{"word":"familistère","word_nosc":"familistere","lemma":"familistère","pos":"NOM"} ,
		{"word":"famille","word_nosc":"famille","lemma":"famille","pos":"NOM"} ,
		{"word":"familles","word_nosc":"familles","lemma":"famille","pos":"NOM"} ,
		{"word":"famine","word_nosc":"famine","lemma":"famine","pos":"NOM"} ,
		{"word":"famines","word_nosc":"famines","lemma":"famine","pos":"NOM"} ,
		{"word":"famulus","word_nosc":"famulus","lemma":"famulus","pos":"NOM"} ,
		{"word":"fan","word_nosc":"fan","lemma":"fan","pos":"NOM"} ,
		{"word":"fan-club","word_nosc":"fan-club","lemma":"fan-club","pos":"NOM"} ,
		{"word":"fana","word_nosc":"fana","lemma":"fana","pos":"NOM"} ,
		{"word":"fanal","word_nosc":"fanal","lemma":"fanal","pos":"NOM"} ,
		{"word":"fanas","word_nosc":"fanas","lemma":"fana","pos":"NOM"} ,
		{"word":"fanatique","word_nosc":"fanatique","lemma":"fanatique","pos":"NOM"} ,
		{"word":"fanatiques","word_nosc":"fanatiques","lemma":"fanatique","pos":"NOM"} ,
		{"word":"fanatisme","word_nosc":"fanatisme","lemma":"fanatisme","pos":"NOM"} ,
		{"word":"fanatismes","word_nosc":"fanatismes","lemma":"fanatisme","pos":"NOM"} ,
		{"word":"fanaux","word_nosc":"fanaux","lemma":"fanal","pos":"NOM"} ,
		{"word":"fanchon","word_nosc":"fanchon","lemma":"fanchon","pos":"NOM"} ,
		{"word":"fandango","word_nosc":"fandango","lemma":"fandango","pos":"NOM"} ,
		{"word":"fane","word_nosc":"fane","lemma":"fane","pos":"NOM"} ,
		{"word":"fanes","word_nosc":"fanes","lemma":"fane","pos":"NOM"} ,
		{"word":"faneuse","word_nosc":"faneuse","lemma":"faneur","pos":"NOM"} ,
		{"word":"faneuses","word_nosc":"faneuses","lemma":"faneur","pos":"NOM"} ,
		{"word":"fanfan","word_nosc":"fanfan","lemma":"fanfan","pos":"NOM"} ,
		{"word":"fanfare","word_nosc":"fanfare","lemma":"fanfare","pos":"NOM"} ,
		{"word":"fanfares","word_nosc":"fanfares","lemma":"fanfare","pos":"NOM"} ,
		{"word":"fanfaron","word_nosc":"fanfaron","lemma":"fanfaron","pos":"NOM"} ,
		{"word":"fanfaronnade","word_nosc":"fanfaronnade","lemma":"fanfaronnade","pos":"NOM"} ,
		{"word":"fanfaronnades","word_nosc":"fanfaronnades","lemma":"fanfaronnade","pos":"NOM"} ,
		{"word":"fanfaronne","word_nosc":"fanfaronne","lemma":"fanfaron","pos":"NOM"} ,
		{"word":"fanfarons","word_nosc":"fanfarons","lemma":"fanfaron","pos":"NOM"} ,
		{"word":"fanfreluche","word_nosc":"fanfreluche","lemma":"fanfreluche","pos":"NOM"} ,
		{"word":"fanfreluches","word_nosc":"fanfreluches","lemma":"fanfreluche","pos":"NOM"} ,
		{"word":"fange","word_nosc":"fange","lemma":"fange","pos":"NOM"} ,
		{"word":"fanges","word_nosc":"fanges","lemma":"fange","pos":"NOM"} ,
		{"word":"fanion","word_nosc":"fanion","lemma":"fanion","pos":"NOM"} ,
		{"word":"fanions","word_nosc":"fanions","lemma":"fanion","pos":"NOM"} ,
		{"word":"fanon","word_nosc":"fanon","lemma":"fanon","pos":"NOM"} ,
		{"word":"fanons","word_nosc":"fanons","lemma":"fanon","pos":"NOM"} ,
		{"word":"fans","word_nosc":"fans","lemma":"fan","pos":"NOM"} ,
		{"word":"fantaisie","word_nosc":"fantaisie","lemma":"fantaisie","pos":"NOM"} ,
		{"word":"fantaisies","word_nosc":"fantaisies","lemma":"fantaisie","pos":"NOM"} ,
		{"word":"fantaisiste","word_nosc":"fantaisiste","lemma":"fantaisiste","pos":"NOM"} ,
		{"word":"fantaisistes","word_nosc":"fantaisistes","lemma":"fantaisiste","pos":"NOM"} ,
		{"word":"fantasia","word_nosc":"fantasia","lemma":"fantasia","pos":"NOM"} ,
		{"word":"fantasmagorie","word_nosc":"fantasmagorie","lemma":"fantasmagorie","pos":"NOM"} ,
		{"word":"fantasmagories","word_nosc":"fantasmagories","lemma":"fantasmagorie","pos":"NOM"} ,
		{"word":"fantasme","word_nosc":"fantasme","lemma":"fantasme","pos":"NOM"} ,
		{"word":"fantasmes","word_nosc":"fantasmes","lemma":"fantasme","pos":"NOM"} ,
		{"word":"fantassin","word_nosc":"fantassin","lemma":"fantassin","pos":"NOM"} ,
		{"word":"fantassins","word_nosc":"fantassins","lemma":"fantassin","pos":"NOM"} ,
		{"word":"fantastique","word_nosc":"fantastique","lemma":"fantastique","pos":"NOM"} ,
		{"word":"fantastiques","word_nosc":"fantastiques","lemma":"fantastique","pos":"NOM"} ,
		{"word":"fanti","word_nosc":"fanti","lemma":"fanti","pos":"NOM"} ,
		{"word":"fantoche","word_nosc":"fantoche","lemma":"fantoche","pos":"NOM"} ,
		{"word":"fantoches","word_nosc":"fantoches","lemma":"fantoche","pos":"NOM"} ,
		{"word":"fantôme","word_nosc":"fantome","lemma":"fantôme","pos":"NOM"} ,
		{"word":"fantômes","word_nosc":"fantomes","lemma":"fantôme","pos":"NOM"} ,
		{"word":"fanzine","word_nosc":"fanzine","lemma":"fanzine","pos":"NOM"} ,
		{"word":"fanzines","word_nosc":"fanzines","lemma":"fanzine","pos":"NOM"} ,
		{"word":"faon","word_nosc":"faon","lemma":"faon","pos":"NOM"} ,
		{"word":"faons","word_nosc":"faons","lemma":"faon","pos":"NOM"} ,
		{"word":"faquin","word_nosc":"faquin","lemma":"faquin","pos":"NOM"} ,
		{"word":"faquins","word_nosc":"faquins","lemma":"faquin","pos":"NOM"} ,
		{"word":"far","word_nosc":"far","lemma":"far","pos":"NOM"} ,
		{"word":"far-west","word_nosc":"far-west","lemma":"far-west","pos":"NOM"} ,
		{"word":"farad","word_nosc":"farad","lemma":"farad","pos":"NOM"} ,
		{"word":"farads","word_nosc":"farads","lemma":"farad","pos":"NOM"} ,
		{"word":"farandole","word_nosc":"farandole","lemma":"farandole","pos":"NOM"} ,
		{"word":"farandoles","word_nosc":"farandoles","lemma":"farandole","pos":"NOM"} ,
		{"word":"faraud","word_nosc":"faraud","lemma":"faraud","pos":"NOM"} ,
		{"word":"faraude","word_nosc":"faraude","lemma":"faraud","pos":"NOM"} ,
		{"word":"faraudes","word_nosc":"faraudes","lemma":"faraud","pos":"NOM"} ,
		{"word":"farauds","word_nosc":"farauds","lemma":"faraud","pos":"NOM"} ,
		{"word":"farce","word_nosc":"farce","lemma":"farce","pos":"NOM"} ,
		{"word":"farces","word_nosc":"farces","lemma":"farce","pos":"NOM"} ,
		{"word":"farceur","word_nosc":"farceur","lemma":"farceur","pos":"NOM"} ,
		{"word":"farceurs","word_nosc":"farceurs","lemma":"farceur","pos":"NOM"} ,
		{"word":"farceuse","word_nosc":"farceuse","lemma":"farceur","pos":"NOM"} ,
		{"word":"farceuses","word_nosc":"farceuses","lemma":"farceur","pos":"NOM"} ,
		{"word":"fard","word_nosc":"fard","lemma":"fard","pos":"NOM"} ,
		{"word":"fardeau","word_nosc":"fardeau","lemma":"fardeau","pos":"NOM"} ,
		{"word":"fardeaux","word_nosc":"fardeaux","lemma":"fardeau","pos":"NOM"} ,
		{"word":"fardier","word_nosc":"fardier","lemma":"fardier","pos":"NOM"} ,
		{"word":"fardiers","word_nosc":"fardiers","lemma":"fardier","pos":"NOM"} ,
		{"word":"fards","word_nosc":"fards","lemma":"fard","pos":"NOM"} ,
		{"word":"fare","word_nosc":"fare","lemma":"fare","pos":"NOM"} ,
		{"word":"farfadet","word_nosc":"farfadet","lemma":"farfadet","pos":"NOM"} ,
		{"word":"farfadets","word_nosc":"farfadets","lemma":"farfadet","pos":"NOM"} ,
		{"word":"faribole","word_nosc":"faribole","lemma":"faribole","pos":"NOM"} ,
		{"word":"fariboles","word_nosc":"fariboles","lemma":"faribole","pos":"NOM"} ,
		{"word":"farigoule","word_nosc":"farigoule","lemma":"farigoule","pos":"NOM"} ,
		{"word":"farine","word_nosc":"farine","lemma":"farine","pos":"NOM"} ,
		{"word":"farines","word_nosc":"farines","lemma":"farine","pos":"NOM"} ,
		{"word":"farniente","word_nosc":"farniente","lemma":"farniente","pos":"NOM"} ,
		{"word":"faro","word_nosc":"faro","lemma":"faro","pos":"NOM"} ,
		{"word":"farsi","word_nosc":"farsi","lemma":"farsi","pos":"NOM"} ,
		{"word":"fart","word_nosc":"fart","lemma":"fart","pos":"NOM"} ,
		{"word":"fascia","word_nosc":"fascia","lemma":"fascia","pos":"NOM"} ,
		{"word":"fascicule","word_nosc":"fascicule","lemma":"fascicule","pos":"NOM"} ,
		{"word":"fascicules","word_nosc":"fascicules","lemma":"fascicule","pos":"NOM"} ,
		{"word":"fascination","word_nosc":"fascination","lemma":"fascination","pos":"NOM"} ,
		{"word":"fascinations","word_nosc":"fascinations","lemma":"fascination","pos":"NOM"} ,
		{"word":"fascine","word_nosc":"fascine","lemma":"fascine","pos":"NOM"} ,
		{"word":"fascines","word_nosc":"fascines","lemma":"fascine","pos":"NOM"} ,
		{"word":"fascisme","word_nosc":"fascisme","lemma":"fascisme","pos":"NOM"} ,
		{"word":"fasciste","word_nosc":"fasciste","lemma":"fasciste","pos":"NOM"} ,
		{"word":"fascistes","word_nosc":"fascistes","lemma":"fasciste","pos":"NOM"} ,
		{"word":"fashion","word_nosc":"fashion","lemma":"fashion","pos":"NOM"} ,
		{"word":"fast food","word_nosc":"fast food","lemma":"fast-food","pos":"NOM"} ,
		{"word":"fast-food","word_nosc":"fast-food","lemma":"fast-food","pos":"NOM"} ,
		{"word":"fast-foods","word_nosc":"fast-foods","lemma":"fast-food","pos":"NOM"} ,
		{"word":"faste","word_nosc":"faste","lemma":"faste","pos":"NOM"} ,
		{"word":"fastes","word_nosc":"fastes","lemma":"faste","pos":"NOM"} ,
		{"word":"fat","word_nosc":"fat","lemma":"fat","pos":"NOM"} ,
		{"word":"fatalisme","word_nosc":"fatalisme","lemma":"fatalisme","pos":"NOM"} ,
		{"word":"fataliste","word_nosc":"fataliste","lemma":"fataliste","pos":"NOM"} ,
		{"word":"fatalité","word_nosc":"fatalite","lemma":"fatalité","pos":"NOM"} ,
		{"word":"fatalités","word_nosc":"fatalites","lemma":"fatalité","pos":"NOM"} ,
		{"word":"fathma","word_nosc":"fathma","lemma":"fathma","pos":"NOM"} ,
		{"word":"fathom","word_nosc":"fathom","lemma":"fathom","pos":"NOM"} ,
		{"word":"fatigue","word_nosc":"fatigue","lemma":"fatigue","pos":"NOM"} ,
		{"word":"fatigues","word_nosc":"fatigues","lemma":"fatigue","pos":"NOM"} ,
		{"word":"fatma","word_nosc":"fatma","lemma":"fatma","pos":"NOM"} ,
		{"word":"fatmas","word_nosc":"fatmas","lemma":"fatma","pos":"NOM"} ,
		{"word":"fatras","word_nosc":"fatras","lemma":"fatras","pos":"NOM"} ,
		{"word":"fats","word_nosc":"fats","lemma":"fat","pos":"NOM"} ,
		{"word":"fatuité","word_nosc":"fatuite","lemma":"fatuité","pos":"NOM"} ,
		{"word":"fatum","word_nosc":"fatum","lemma":"fatum","pos":"NOM"} ,
		{"word":"fau","word_nosc":"fau","lemma":"fau","pos":"NOM"} ,
		{"word":"faubert","word_nosc":"faubert","lemma":"faubert","pos":"NOM"} ,
		{"word":"faubourg","word_nosc":"faubourg","lemma":"faubourg","pos":"NOM"} ,
		{"word":"faubourgs","word_nosc":"faubourgs","lemma":"faubourg","pos":"NOM"} ,
		{"word":"fauchage","word_nosc":"fauchage","lemma":"fauchage","pos":"NOM"} ,
		{"word":"fauchaison","word_nosc":"fauchaison","lemma":"fauchaison","pos":"NOM"} ,
		{"word":"fauchard","word_nosc":"fauchard","lemma":"fauchard","pos":"NOM"} ,
		{"word":"fauche","word_nosc":"fauche","lemma":"fauche","pos":"NOM"} ,
		{"word":"fauches","word_nosc":"fauches","lemma":"fauche","pos":"NOM"} ,
		{"word":"faucheur","word_nosc":"faucheur","lemma":"faucheur","pos":"NOM"} ,
		{"word":"faucheurs","word_nosc":"faucheurs","lemma":"faucheur","pos":"NOM"} ,
		{"word":"faucheuse","word_nosc":"faucheuse","lemma":"faucheur","pos":"NOM"} ,
		{"word":"faucheuses","word_nosc":"faucheuses","lemma":"faucheur","pos":"NOM"} ,
		{"word":"faucheux","word_nosc":"faucheux","lemma":"faucheux","pos":"NOM"} ,
		{"word":"fauchon","word_nosc":"fauchon","lemma":"fauchon","pos":"NOM"} ,
		{"word":"faucille","word_nosc":"faucille","lemma":"faucille","pos":"NOM"} ,
		{"word":"faucilles","word_nosc":"faucilles","lemma":"faucille","pos":"NOM"} ,
		{"word":"faucillon","word_nosc":"faucillon","lemma":"faucillon","pos":"NOM"} ,
		{"word":"faucon","word_nosc":"faucon","lemma":"faucon","pos":"NOM"} ,
		{"word":"fauconneau","word_nosc":"fauconneau","lemma":"fauconneau","pos":"NOM"} ,
		{"word":"fauconnerie","word_nosc":"fauconnerie","lemma":"fauconnerie","pos":"NOM"} ,
		{"word":"fauconnier","word_nosc":"fauconnier","lemma":"fauconnier","pos":"NOM"} ,
		{"word":"fauconniers","word_nosc":"fauconniers","lemma":"fauconnier","pos":"NOM"} ,
		{"word":"faucons","word_nosc":"faucons","lemma":"faucon","pos":"NOM"} ,
		{"word":"faufil","word_nosc":"faufil","lemma":"faufil","pos":"NOM"} ,
		{"word":"faune","word_nosc":"faune","lemma":"faune","pos":"NOM"} ,
		{"word":"faunes","word_nosc":"faunes","lemma":"faune","pos":"NOM"} ,
		{"word":"faunesse","word_nosc":"faunesse","lemma":"faunesse","pos":"NOM"} ,
		{"word":"faussaire","word_nosc":"faussaire","lemma":"faussaire","pos":"NOM"} ,
		{"word":"faussaires","word_nosc":"faussaires","lemma":"faussaire","pos":"NOM"} ,
		{"word":"fausse-couche","word_nosc":"fausse-couche","lemma":"fausse-couche","pos":"NOM"} ,
		{"word":"fausset","word_nosc":"fausset","lemma":"fausset","pos":"NOM"} ,
		{"word":"faussets","word_nosc":"faussets","lemma":"fausset","pos":"NOM"} ,
		{"word":"fausseté","word_nosc":"faussete","lemma":"fausseté","pos":"NOM"} ,
		{"word":"faute","word_nosc":"faute","lemma":"faute","pos":"NOM"} ,
		{"word":"fautes","word_nosc":"fautes","lemma":"faute","pos":"NOM"} ,
		{"word":"fauteuil","word_nosc":"fauteuil","lemma":"fauteuil","pos":"NOM"} ,
		{"word":"fauteuil-club","word_nosc":"fauteuil-club","lemma":"fauteuil-club","pos":"NOM"} ,
		{"word":"fauteuils","word_nosc":"fauteuils","lemma":"fauteuil","pos":"NOM"} ,
		{"word":"fauteuils-club","word_nosc":"fauteuils-club","lemma":"fauteuils-club","pos":"NOM"} ,
		{"word":"fauteur","word_nosc":"fauteur","lemma":"fauteur","pos":"NOM"} ,
		{"word":"fauteurs","word_nosc":"fauteurs","lemma":"fauteur","pos":"NOM"} ,
		{"word":"fauteuse","word_nosc":"fauteuse","lemma":"fauteur","pos":"NOM"} ,
		{"word":"fautrice","word_nosc":"fautrice","lemma":"fauteur","pos":"NOM"} ,
		{"word":"fauve","word_nosc":"fauve","lemma":"fauve","pos":"NOM"} ,
		{"word":"fauverie","word_nosc":"fauverie","lemma":"fauverie","pos":"NOM"} ,
		{"word":"fauves","word_nosc":"fauves","lemma":"fauve","pos":"NOM"} ,
		{"word":"fauvette","word_nosc":"fauvette","lemma":"fauvette","pos":"NOM"} ,
		{"word":"fauvettes","word_nosc":"fauvettes","lemma":"fauvette","pos":"NOM"} ,
		{"word":"fauvisme","word_nosc":"fauvisme","lemma":"fauvisme","pos":"NOM"} ,
		{"word":"faux","word_nosc":"faux","lemma":"faux","pos":"NOM"} ,
		{"word":"faux-bond","word_nosc":"faux-bond","lemma":"faux-bond","pos":"NOM"} ,
		{"word":"faux-bourdon","word_nosc":"faux-bourdon","lemma":"faux-bourdon","pos":"NOM"} ,
		{"word":"faux-col","word_nosc":"faux-col","lemma":"faux-col","pos":"NOM"} ,
		{"word":"faux-cul","word_nosc":"faux-cul","lemma":"faux-cul","pos":"NOM"} ,
		{"word":"faux-filet","word_nosc":"faux-filet","lemma":"faux-filet","pos":"NOM"} ,
		{"word":"faux-fuyant","word_nosc":"faux-fuyant","lemma":"faux-fuyant","pos":"NOM"} ,
		{"word":"faux-fuyants","word_nosc":"faux-fuyants","lemma":"faux-fuyant","pos":"NOM"} ,
		{"word":"faux-monnayeur","word_nosc":"faux-monnayeur","lemma":"faux-monnayeur","pos":"NOM"} ,
		{"word":"faux-monnayeurs","word_nosc":"faux-monnayeurs","lemma":"faux-monnayeur","pos":"NOM"} ,
		{"word":"faux-pas","word_nosc":"faux-pas","lemma":"faux-pas","pos":"NOM"} ,
		{"word":"faux-pont","word_nosc":"faux-pont","lemma":"faux-pont","pos":"NOM"} ,
		{"word":"faux-sauniers","word_nosc":"faux-sauniers","lemma":"faux-saunier","pos":"NOM"} ,
		{"word":"faux-semblant","word_nosc":"faux-semblant","lemma":"faux-semblant","pos":"NOM"} ,
		{"word":"faux-semblants","word_nosc":"faux-semblants","lemma":"faux-semblant","pos":"NOM"} ,
		{"word":"favela","word_nosc":"favela","lemma":"favela","pos":"NOM"} ,
		{"word":"favelas","word_nosc":"favelas","lemma":"favela","pos":"NOM"} ,
		{"word":"faveur","word_nosc":"faveur","lemma":"faveur","pos":"NOM"} ,
		{"word":"faveurs","word_nosc":"faveurs","lemma":"faveur","pos":"NOM"} ,
		{"word":"favori","word_nosc":"favori","lemma":"favori","pos":"NOM"} ,
		{"word":"favoris","word_nosc":"favoris","lemma":"favori","pos":"NOM"} ,
		{"word":"favorite","word_nosc":"favorite","lemma":"favorite","pos":"NOM"} ,
		{"word":"favorites","word_nosc":"favorites","lemma":"favorite","pos":"NOM"} ,
		{"word":"favoritisme","word_nosc":"favoritisme","lemma":"favoritisme","pos":"NOM"} ,
		{"word":"fax","word_nosc":"fax","lemma":"fax","pos":"NOM"} ,
		{"word":"fayard","word_nosc":"fayard","lemma":"fayard","pos":"NOM"} ,
		{"word":"fayards","word_nosc":"fayards","lemma":"fayard","pos":"NOM"} ,
		{"word":"fayot","word_nosc":"fayot","lemma":"fayot","pos":"NOM"} ,
		{"word":"fayotage","word_nosc":"fayotage","lemma":"fayotage","pos":"NOM"} ,
		{"word":"fayots","word_nosc":"fayots","lemma":"fayot","pos":"NOM"} ,
		{"word":"fazenda","word_nosc":"fazenda","lemma":"fazenda","pos":"NOM"} ,
		{"word":"façade","word_nosc":"facade","lemma":"façade","pos":"NOM"} ,
		{"word":"façades","word_nosc":"facades","lemma":"façade","pos":"NOM"} ,
		{"word":"façon","word_nosc":"facon","lemma":"façon","pos":"NOM"} ,
		{"word":"façonnière","word_nosc":"faconniere","lemma":"façonnier","pos":"NOM"} ,
		{"word":"façonné","word_nosc":"faconne","lemma":"façonné","pos":"NOM"} ,
		{"word":"façonnés","word_nosc":"faconnes","lemma":"façonné","pos":"NOM"} ,
		{"word":"façons","word_nosc":"facons","lemma":"façon","pos":"NOM"} ,
		{"word":"faînes","word_nosc":"faines","lemma":"faîne","pos":"NOM"} ,
		{"word":"faîtage","word_nosc":"faitage","lemma":"faîtage","pos":"NOM"} ,
		{"word":"faîtages","word_nosc":"faitages","lemma":"faîtage","pos":"NOM"} ,
		{"word":"faîte","word_nosc":"faite","lemma":"faîte","pos":"NOM"} ,
		{"word":"faîtes-la-moi","word_nosc":"faites-la-moi","lemma":"faîtes-la-moi","pos":"NOM"} ,
		{"word":"faîtière","word_nosc":"faitiere","lemma":"faîtière","pos":"NOM"} ,
		{"word":"faïence","word_nosc":"faience","lemma":"faïence","pos":"NOM"} ,
		{"word":"faïencerie","word_nosc":"faiencerie","lemma":"faïencerie","pos":"NOM"} ,
		{"word":"faïences","word_nosc":"faiences","lemma":"faïence","pos":"NOM"} ,
		{"word":"faïencier","word_nosc":"faiencier","lemma":"faïencier","pos":"NOM"} ,
		{"word":"faïenciers","word_nosc":"faienciers","lemma":"faïencier","pos":"NOM"} ,
		{"word":"fedayin","word_nosc":"fedayin","lemma":"fedayin","pos":"NOM"} ,
		{"word":"feed-back","word_nosc":"feed-back","lemma":"feed-back","pos":"NOM"} ,
		{"word":"feedback","word_nosc":"feedback","lemma":"feedback","pos":"NOM"} ,
		{"word":"feeder","word_nosc":"feeder","lemma":"feeder","pos":"NOM"} ,
		{"word":"feeling","word_nosc":"feeling","lemma":"feeling","pos":"NOM"} ,
		{"word":"feelings","word_nosc":"feelings","lemma":"feeling","pos":"NOM"} ,
		{"word":"feignant","word_nosc":"feignant","lemma":"feignant","pos":"NOM"} ,
		{"word":"feignante","word_nosc":"feignante","lemma":"feignant","pos":"NOM"} ,
		{"word":"feignantes","word_nosc":"feignantes","lemma":"feignant","pos":"NOM"} ,
		{"word":"feignants","word_nosc":"feignants","lemma":"feignant","pos":"NOM"} ,
		{"word":"feignasse","word_nosc":"feignasse","lemma":"feignasse","pos":"NOM"} ,
		{"word":"feignasses","word_nosc":"feignasses","lemma":"feignasse","pos":"NOM"} ,
		{"word":"feinte","word_nosc":"feinte","lemma":"feinte","pos":"NOM"} ,
		{"word":"feintes","word_nosc":"feintes","lemma":"feinte","pos":"NOM"} ,
		{"word":"feinteur","word_nosc":"feinteur","lemma":"feinteur","pos":"NOM"} ,
		{"word":"feintise","word_nosc":"feintise","lemma":"feintise","pos":"NOM"} ,
		{"word":"feld-maréchal","word_nosc":"feld-marechal","lemma":"feld-maréchal","pos":"NOM"} ,
		{"word":"feldgrau","word_nosc":"feldgrau","lemma":"feldgrau","pos":"NOM"} ,
		{"word":"feldspath","word_nosc":"feldspath","lemma":"feldspath","pos":"NOM"} ,
		{"word":"feldwebel","word_nosc":"feldwebel","lemma":"feldwebel","pos":"NOM"} ,
		{"word":"feldwebels","word_nosc":"feldwebels","lemma":"feldwebel","pos":"NOM"} ,
		{"word":"fellaga","word_nosc":"fellaga","lemma":"fellaga","pos":"NOM"} ,
		{"word":"fellagas","word_nosc":"fellagas","lemma":"fellaga","pos":"NOM"} ,
		{"word":"fellagha","word_nosc":"fellagha","lemma":"fellagha","pos":"NOM"} ,
		{"word":"fellaghas","word_nosc":"fellaghas","lemma":"fellagha","pos":"NOM"} ,
		{"word":"fellah","word_nosc":"fellah","lemma":"fellah","pos":"NOM"} ,
		{"word":"fellahs","word_nosc":"fellahs","lemma":"fellah","pos":"NOM"} ,
		{"word":"fellation","word_nosc":"fellation","lemma":"fellation","pos":"NOM"} ,
		{"word":"fellations","word_nosc":"fellations","lemma":"fellation","pos":"NOM"} ,
		{"word":"fellinien","word_nosc":"fellinien","lemma":"fellinien","pos":"NOM"} ,
		{"word":"felouque","word_nosc":"felouque","lemma":"felouque","pos":"NOM"} ,
		{"word":"felouques","word_nosc":"felouques","lemma":"felouque","pos":"NOM"} ,
		{"word":"femelle","word_nosc":"femelle","lemma":"femelle","pos":"NOM"} ,
		{"word":"femelles","word_nosc":"femelles","lemma":"femelle","pos":"NOM"} ,
		{"word":"femme","word_nosc":"femme","lemma":"femme","pos":"NOM"} ,
		{"word":"femme-enfant","word_nosc":"femme-enfant","lemma":"femme-enfant","pos":"NOM"} ,
		{"word":"femme-fleur","word_nosc":"femme-fleur","lemma":"femme-fleur","pos":"NOM"} ,
		{"word":"femme-objet","word_nosc":"femme-objet","lemma":"femme-objet","pos":"NOM"} ,
		{"word":"femme-refuge","word_nosc":"femme-refuge","lemma":"femme-refuge","pos":"NOM"} ,
		{"word":"femme-robot","word_nosc":"femme-robot","lemma":"femme-robot","pos":"NOM"} ,
		{"word":"femmelette","word_nosc":"femmelette","lemma":"femmelette","pos":"NOM"} ,
		{"word":"femmelettes","word_nosc":"femmelettes","lemma":"femmelette","pos":"NOM"} ,
		{"word":"femmes","word_nosc":"femmes","lemma":"femme","pos":"NOM"} ,
		{"word":"fenaison","word_nosc":"fenaison","lemma":"fenaison","pos":"NOM"} ,
		{"word":"fenaisons","word_nosc":"fenaisons","lemma":"fenaison","pos":"NOM"} ,
		{"word":"fendant","word_nosc":"fendant","lemma":"fendant","pos":"NOM"} ,
		{"word":"fendard","word_nosc":"fendard","lemma":"fendard","pos":"NOM"} ,
		{"word":"fendeur","word_nosc":"fendeur","lemma":"fendeur","pos":"NOM"} ,
		{"word":"fendeurs","word_nosc":"fendeurs","lemma":"fendeur","pos":"NOM"} ,
		{"word":"fendillement","word_nosc":"fendillement","lemma":"fendillement","pos":"NOM"} ,
		{"word":"fendillements","word_nosc":"fendillements","lemma":"fendillement","pos":"NOM"} ,
		{"word":"fendoir","word_nosc":"fendoir","lemma":"fendoir","pos":"NOM"} ,
		{"word":"fenestrages","word_nosc":"fenestrages","lemma":"fenestrage","pos":"NOM"} ,
		{"word":"fenestrelle","word_nosc":"fenestrelle","lemma":"fenestrelle","pos":"NOM"} ,
		{"word":"fenians","word_nosc":"fenians","lemma":"fenian","pos":"NOM"} ,
		{"word":"fenil","word_nosc":"fenil","lemma":"fenil","pos":"NOM"} ,
		{"word":"fenils","word_nosc":"fenils","lemma":"fenil","pos":"NOM"} ,
		{"word":"fennec","word_nosc":"fennec","lemma":"fennec","pos":"NOM"} ,
		{"word":"fennecs","word_nosc":"fennecs","lemma":"fennec","pos":"NOM"} ,
		{"word":"fenouil","word_nosc":"fenouil","lemma":"fenouil","pos":"NOM"} ,
		{"word":"fente","word_nosc":"fente","lemma":"fente","pos":"NOM"} ,
		{"word":"fentes","word_nosc":"fentes","lemma":"fente","pos":"NOM"} ,
		{"word":"fenton","word_nosc":"fenton","lemma":"fenton","pos":"NOM"} ,
		{"word":"fenugrec","word_nosc":"fenugrec","lemma":"fenugrec","pos":"NOM"} ,
		{"word":"fenêtre","word_nosc":"fenetre","lemma":"fenêtre","pos":"NOM"} ,
		{"word":"fenêtres","word_nosc":"fenetres","lemma":"fenêtre","pos":"NOM"} ,
		{"word":"fer","word_nosc":"fer","lemma":"fer","pos":"NOM"} ,
		{"word":"fer-blanc","word_nosc":"fer-blanc","lemma":"fer-blanc","pos":"NOM"} ,
		{"word":"ferblanterie","word_nosc":"ferblanterie","lemma":"ferblanterie","pos":"NOM"} ,
		{"word":"ferblantier","word_nosc":"ferblantier","lemma":"ferblantier","pos":"NOM"} ,
		{"word":"ferblantiers","word_nosc":"ferblantiers","lemma":"ferblantier","pos":"NOM"} ,
		{"word":"feria","word_nosc":"feria","lemma":"feria","pos":"NOM"} ,
		{"word":"ferias","word_nosc":"ferias","lemma":"feria","pos":"NOM"} ,
		{"word":"ferlage","word_nosc":"ferlage","lemma":"ferlage","pos":"NOM"} ,
		{"word":"fermage","word_nosc":"fermage","lemma":"fermage","pos":"NOM"} ,
		{"word":"fermages","word_nosc":"fermages","lemma":"fermage","pos":"NOM"} ,
		{"word":"ferme","word_nosc":"ferme","lemma":"ferme","pos":"NOM"} ,
		{"word":"ferment","word_nosc":"ferment","lemma":"ferment","pos":"NOM"} ,
		{"word":"fermentation","word_nosc":"fermentation","lemma":"fermentation","pos":"NOM"} ,
		{"word":"fermentations","word_nosc":"fermentations","lemma":"fermentation","pos":"NOM"} ,
		{"word":"ferments","word_nosc":"ferments","lemma":"ferment","pos":"NOM"} ,
		{"word":"fermes","word_nosc":"fermes","lemma":"ferme","pos":"NOM"} ,
		{"word":"fermes-hôtels","word_nosc":"fermes-hotels","lemma":"fermes-hôtel","pos":"NOM"} ,
		{"word":"fermette","word_nosc":"fermette","lemma":"fermette","pos":"NOM"} ,
		{"word":"fermeture","word_nosc":"fermeture","lemma":"fermeture","pos":"NOM"} ,
		{"word":"fermetures","word_nosc":"fermetures","lemma":"fermeture","pos":"NOM"} ,
		{"word":"fermeté","word_nosc":"fermete","lemma":"fermeté","pos":"NOM"} ,
		{"word":"fermi","word_nosc":"fermi","lemma":"fermi","pos":"NOM"} ,
		{"word":"fermier","word_nosc":"fermier","lemma":"fermier","pos":"NOM"} ,
		{"word":"fermiers","word_nosc":"fermiers","lemma":"fermier","pos":"NOM"} ,
		{"word":"fermions","word_nosc":"fermions","lemma":"fermion","pos":"NOM"} ,
		{"word":"fermium","word_nosc":"fermium","lemma":"fermium","pos":"NOM"} ,
		{"word":"fermière","word_nosc":"fermiere","lemma":"fermier","pos":"NOM"} ,
		{"word":"fermières","word_nosc":"fermieres","lemma":"fermier","pos":"NOM"} ,
		{"word":"fermoir","word_nosc":"fermoir","lemma":"fermoir","pos":"NOM"} ,
		{"word":"fermoirs","word_nosc":"fermoirs","lemma":"fermoir","pos":"NOM"} ,
		{"word":"ferrage","word_nosc":"ferrage","lemma":"ferrage","pos":"NOM"} ,
		{"word":"ferrages","word_nosc":"ferrages","lemma":"ferrage","pos":"NOM"} ,
		{"word":"ferraille","word_nosc":"ferraille","lemma":"ferraille","pos":"NOM"} ,
		{"word":"ferraillement","word_nosc":"ferraillement","lemma":"ferraillement","pos":"NOM"} ,
		{"word":"ferrailles","word_nosc":"ferrailles","lemma":"ferraille","pos":"NOM"} ,
		{"word":"ferrailleur","word_nosc":"ferrailleur","lemma":"ferrailleur","pos":"NOM"} ,
		{"word":"ferrailleurs","word_nosc":"ferrailleurs","lemma":"ferrailleur","pos":"NOM"} ,
		{"word":"ferrets","word_nosc":"ferrets","lemma":"ferret","pos":"NOM"} ,
		{"word":"ferries","word_nosc":"ferries","lemma":"ferry","pos":"NOM"} ,
		{"word":"ferrite","word_nosc":"ferrite","lemma":"ferrite","pos":"NOM"} ,
		{"word":"ferro","word_nosc":"ferro","lemma":"ferro","pos":"NOM"} ,
		{"word":"ferrocyanure","word_nosc":"ferrocyanure","lemma":"ferrocyanure","pos":"NOM"} ,
		{"word":"ferrocérium","word_nosc":"ferrocerium","lemma":"ferrocérium","pos":"NOM"} ,
		{"word":"ferronnerie","word_nosc":"ferronnerie","lemma":"ferronnerie","pos":"NOM"} ,
		{"word":"ferronneries","word_nosc":"ferronneries","lemma":"ferronnerie","pos":"NOM"} ,
		{"word":"ferronnier","word_nosc":"ferronnier","lemma":"ferronnier","pos":"NOM"} ,
		{"word":"ferronniers","word_nosc":"ferronniers","lemma":"ferronnier","pos":"NOM"} ,
		{"word":"ferronnière","word_nosc":"ferronniere","lemma":"ferronnier","pos":"NOM"} ,
		{"word":"ferrure","word_nosc":"ferrure","lemma":"ferrure","pos":"NOM"} ,
		{"word":"ferrures","word_nosc":"ferrures","lemma":"ferrure","pos":"NOM"} ,
		{"word":"ferry","word_nosc":"ferry","lemma":"ferry","pos":"NOM"} ,
		{"word":"ferry-boat","word_nosc":"ferry-boat","lemma":"ferry-boat","pos":"NOM"} ,
		{"word":"fers","word_nosc":"fers","lemma":"fer","pos":"NOM"} ,
		{"word":"fertilisant","word_nosc":"fertilisant","lemma":"fertilisant","pos":"NOM"} ,
		{"word":"fertilisation","word_nosc":"fertilisation","lemma":"fertilisation","pos":"NOM"} ,
		{"word":"fertilité","word_nosc":"fertilite","lemma":"fertilité","pos":"NOM"} ,
		{"word":"ferté","word_nosc":"ferte","lemma":"ferté","pos":"NOM"} ,
		{"word":"ferveur","word_nosc":"ferveur","lemma":"ferveur","pos":"NOM"} ,
		{"word":"ferveurs","word_nosc":"ferveurs","lemma":"ferveur","pos":"NOM"} ,
		{"word":"fesse","word_nosc":"fesse","lemma":"fesse","pos":"NOM"} ,
		{"word":"fesse-mathieu","word_nosc":"fesse-mathieu","lemma":"fesse-mathieu","pos":"NOM"} ,
		{"word":"fesses","word_nosc":"fesses","lemma":"fesse","pos":"NOM"} ,
		{"word":"fesseur","word_nosc":"fesseur","lemma":"fesseur","pos":"NOM"} ,
		{"word":"fesseuse","word_nosc":"fesseuse","lemma":"fesseur","pos":"NOM"} ,
		{"word":"fessier","word_nosc":"fessier","lemma":"fessier","pos":"NOM"} ,
		{"word":"fessiers","word_nosc":"fessiers","lemma":"fessier","pos":"NOM"} ,
		{"word":"fessée","word_nosc":"fessee","lemma":"fessée","pos":"NOM"} ,
		{"word":"fessées","word_nosc":"fessees","lemma":"fessée","pos":"NOM"} ,
		{"word":"festin","word_nosc":"festin","lemma":"festin","pos":"NOM"} ,
		{"word":"festins","word_nosc":"festins","lemma":"festin","pos":"NOM"} ,
		{"word":"festival","word_nosc":"festival","lemma":"festival","pos":"NOM"} ,
		{"word":"festivalier","word_nosc":"festivalier","lemma":"festivalier","pos":"NOM"} ,
		{"word":"festivals","word_nosc":"festivals","lemma":"festival","pos":"NOM"} ,
		{"word":"festivité","word_nosc":"festivite","lemma":"festivité","pos":"NOM"} ,
		{"word":"festivités","word_nosc":"festivites","lemma":"festivité","pos":"NOM"} ,
		{"word":"feston","word_nosc":"feston","lemma":"feston","pos":"NOM"} ,
		{"word":"festonnements","word_nosc":"festonnements","lemma":"festonnement","pos":"NOM"} ,
		{"word":"festons","word_nosc":"festons","lemma":"feston","pos":"NOM"} ,
		{"word":"feta","word_nosc":"feta","lemma":"feta","pos":"NOM"} ,
		{"word":"fettucine","word_nosc":"fettucine","lemma":"fettucine","pos":"NOM"} ,
		{"word":"feu","word_nosc":"feu","lemma":"feu","pos":"NOM"} ,
		{"word":"feudataire","word_nosc":"feudataire","lemma":"feudataire","pos":"NOM"} ,
		{"word":"feuillage","word_nosc":"feuillage","lemma":"feuillage","pos":"NOM"} ,
		{"word":"feuillages","word_nosc":"feuillages","lemma":"feuillage","pos":"NOM"} ,
		{"word":"feuillaison","word_nosc":"feuillaison","lemma":"feuillaison","pos":"NOM"} ,
		{"word":"feuillantines","word_nosc":"feuillantines","lemma":"feuillantine","pos":"NOM"} ,
		{"word":"feuille","word_nosc":"feuille","lemma":"feuille","pos":"NOM"} ,
		{"word":"feuilles","word_nosc":"feuilles","lemma":"feuille","pos":"NOM"} ,
		{"word":"feuillet","word_nosc":"feuillet","lemma":"feuillet","pos":"NOM"} ,
		{"word":"feuilletage","word_nosc":"feuilletage","lemma":"feuilletage","pos":"NOM"} ,
		{"word":"feuilleton","word_nosc":"feuilleton","lemma":"feuilleton","pos":"NOM"} ,
		{"word":"feuilletoniste","word_nosc":"feuilletoniste","lemma":"feuilletoniste","pos":"NOM"} ,
		{"word":"feuilletons","word_nosc":"feuilletons","lemma":"feuilleton","pos":"NOM"} ,
		{"word":"feuillets","word_nosc":"feuillets","lemma":"feuillet","pos":"NOM"} ,
		{"word":"feuillette","word_nosc":"feuillette","lemma":"feuillette","pos":"NOM"} ,
		{"word":"feuillettement","word_nosc":"feuillettement","lemma":"feuillettement","pos":"NOM"} ,
		{"word":"feuillettes","word_nosc":"feuillettes","lemma":"feuillette","pos":"NOM"} ,
		{"word":"feuilleté","word_nosc":"feuillete","lemma":"feuilleté","pos":"NOM"} ,
		{"word":"feuilletés","word_nosc":"feuilletes","lemma":"feuilleté","pos":"NOM"} ,
		{"word":"feuillu","word_nosc":"feuillu","lemma":"feuillu","pos":"NOM"} ,
		{"word":"feuillure","word_nosc":"feuillure","lemma":"feuillure","pos":"NOM"} ,
		{"word":"feuillures","word_nosc":"feuillures","lemma":"feuillure","pos":"NOM"} ,
		{"word":"feuillus","word_nosc":"feuillus","lemma":"feuillu","pos":"NOM"} ,
		{"word":"feuillée","word_nosc":"feuillee","lemma":"feuillée","pos":"NOM"} ,
		{"word":"feuillées","word_nosc":"feuillees","lemma":"feuillée","pos":"NOM"} ,
		{"word":"feuj","word_nosc":"feuj","lemma":"feuj","pos":"NOM"} ,
		{"word":"feulement","word_nosc":"feulement","lemma":"feulement","pos":"NOM"} ,
		{"word":"feulements","word_nosc":"feulements","lemma":"feulement","pos":"NOM"} ,
		{"word":"feurre","word_nosc":"feurre","lemma":"feurre","pos":"NOM"} ,
		{"word":"feutrage","word_nosc":"feutrage","lemma":"feutrage","pos":"NOM"} ,
		{"word":"feutrages","word_nosc":"feutrages","lemma":"feutrage","pos":"NOM"} ,
		{"word":"feutre","word_nosc":"feutre","lemma":"feutre","pos":"NOM"} ,
		{"word":"feutres","word_nosc":"feutres","lemma":"feutre","pos":"NOM"} ,
		{"word":"feutrine","word_nosc":"feutrine","lemma":"feutrine","pos":"NOM"} ,
		{"word":"feux","word_nosc":"feux","lemma":"feu","pos":"NOM"} ,
		{"word":"fez","word_nosc":"fez","lemma":"fez","pos":"NOM"} ,
		{"word":"fiabilité","word_nosc":"fiabilite","lemma":"fiabilité","pos":"NOM"} ,
		{"word":"fiacre","word_nosc":"fiacre","lemma":"fiacre","pos":"NOM"} ,
		{"word":"fiacres","word_nosc":"fiacres","lemma":"fiacre","pos":"NOM"} ,
		{"word":"fiancé","word_nosc":"fiance","lemma":"fiancé","pos":"NOM"} ,
		{"word":"fiancée","word_nosc":"fiancee","lemma":"fiancé","pos":"NOM"} ,
		{"word":"fiancées","word_nosc":"fiancees","lemma":"fiancé","pos":"NOM"} ,
		{"word":"fiancés","word_nosc":"fiances","lemma":"fiancé","pos":"NOM"} ,
		{"word":"fiançailles","word_nosc":"fiancailles","lemma":"fiançailles","pos":"NOM"} ,
		{"word":"fiasco","word_nosc":"fiasco","lemma":"fiasco","pos":"NOM"} ,
		{"word":"fiascos","word_nosc":"fiascos","lemma":"fiasco","pos":"NOM"} ,
		{"word":"fiasque","word_nosc":"fiasque","lemma":"fiasque","pos":"NOM"} ,
		{"word":"fiasques","word_nosc":"fiasques","lemma":"fiasque","pos":"NOM"} ,
		{"word":"fiat","word_nosc":"fiat","lemma":"fiat","pos":"NOM"} ,
		{"word":"fibre","word_nosc":"fibre","lemma":"fibre","pos":"NOM"} ,
		{"word":"fibres","word_nosc":"fibres","lemma":"fibre","pos":"NOM"} ,
		{"word":"fibrillation","word_nosc":"fibrillation","lemma":"fibrillation","pos":"NOM"} ,
		{"word":"fibrille","word_nosc":"fibrille","lemma":"fibrille","pos":"NOM"} ,
		{"word":"fibrilles","word_nosc":"fibrilles","lemma":"fibrille","pos":"NOM"} ,
		{"word":"fibrillé","word_nosc":"fibrille","lemma":"fibrillé","pos":"NOM"} ,
		{"word":"fibrine","word_nosc":"fibrine","lemma":"fibrine","pos":"NOM"} ,
		{"word":"fibrinogène","word_nosc":"fibrinogene","lemma":"fibrinogène","pos":"NOM"} ,
		{"word":"fibrociment","word_nosc":"fibrociment","lemma":"fibrociment","pos":"NOM"} ,
		{"word":"fibrome","word_nosc":"fibrome","lemma":"fibrome","pos":"NOM"} ,
		{"word":"fibromes","word_nosc":"fibromes","lemma":"fibrome","pos":"NOM"} ,
		{"word":"fibroscope","word_nosc":"fibroscope","lemma":"fibroscope","pos":"NOM"} ,
		{"word":"fibroscopie","word_nosc":"fibroscopie","lemma":"fibroscopie","pos":"NOM"} ,
		{"word":"fibrose","word_nosc":"fibrose","lemma":"fibrose","pos":"NOM"} ,
		{"word":"fibrotoxine","word_nosc":"fibrotoxine","lemma":"fibrotoxine","pos":"NOM"} ,
		{"word":"fibule","word_nosc":"fibule","lemma":"fibule","pos":"NOM"} ,
		{"word":"fic","word_nosc":"fic","lemma":"fic","pos":"NOM"} ,
		{"word":"ficaires","word_nosc":"ficaires","lemma":"ficaire","pos":"NOM"} ,
		{"word":"ficelle","word_nosc":"ficelle","lemma":"ficelle","pos":"NOM"} ,
		{"word":"ficelles","word_nosc":"ficelles","lemma":"ficelle","pos":"NOM"} ,
		{"word":"fichage","word_nosc":"fichage","lemma":"fichage","pos":"NOM"} ,
		{"word":"fichaise","word_nosc":"fichaise","lemma":"fichaise","pos":"NOM"} ,
		{"word":"fichaises","word_nosc":"fichaises","lemma":"fichaise","pos":"NOM"} ,
		{"word":"fiche","word_nosc":"fiche","lemma":"fiche","pos":"NOM"} ,
		{"word":"fiches","word_nosc":"fiches","lemma":"fiche","pos":"NOM"} ,
		{"word":"fichier","word_nosc":"fichier","lemma":"fichier","pos":"NOM"} ,
		{"word":"fichiers","word_nosc":"fichiers","lemma":"fichier","pos":"NOM"} ,
		{"word":"fichu","word_nosc":"fichu","lemma":"fichu","pos":"NOM"} ,
		{"word":"fichus","word_nosc":"fichus","lemma":"fichu","pos":"NOM"} ,
		{"word":"fiction","word_nosc":"fiction","lemma":"fiction","pos":"NOM"} ,
		{"word":"fictions","word_nosc":"fictions","lemma":"fiction","pos":"NOM"} ,
		{"word":"ficus","word_nosc":"ficus","lemma":"ficus","pos":"NOM"} ,
		{"word":"fiducie","word_nosc":"fiducie","lemma":"fiducie","pos":"NOM"} ,
		{"word":"fidèle","word_nosc":"fidele","lemma":"fidèle","pos":"NOM"} ,
		{"word":"fidèles","word_nosc":"fideles","lemma":"fidèle","pos":"NOM"} ,
		{"word":"fidéicommis","word_nosc":"fideicommis","lemma":"fidéicommis","pos":"NOM"} ,
		{"word":"fidélité","word_nosc":"fidelite","lemma":"fidélité","pos":"NOM"} ,
		{"word":"fidélités","word_nosc":"fidelites","lemma":"fidélité","pos":"NOM"} ,
		{"word":"fief","word_nosc":"fief","lemma":"fief","pos":"NOM"} ,
		{"word":"fiefs","word_nosc":"fiefs","lemma":"fief","pos":"NOM"} ,
		{"word":"fiel","word_nosc":"fiel","lemma":"fiel","pos":"NOM"} ,
		{"word":"field","word_nosc":"field","lemma":"field","pos":"NOM"} ,
		{"word":"fiente","word_nosc":"fiente","lemma":"fiente","pos":"NOM"} ,
		{"word":"fientes","word_nosc":"fientes","lemma":"fiente","pos":"NOM"} ,
		{"word":"fier-à-bras","word_nosc":"fier-a-bras","lemma":"fier-à-bras","pos":"NOM"} ,
		{"word":"fiers-à-bras","word_nosc":"fiers-a-bras","lemma":"fiers-à-bras","pos":"NOM"} ,
		{"word":"fierté","word_nosc":"fierte","lemma":"fierté","pos":"NOM"} ,
		{"word":"fiertés","word_nosc":"fiertes","lemma":"fierté","pos":"NOM"} ,
		{"word":"fiesta","word_nosc":"fiesta","lemma":"fiesta","pos":"NOM"} ,
		{"word":"fiestas","word_nosc":"fiestas","lemma":"fiesta","pos":"NOM"} ,
		{"word":"fieu","word_nosc":"fieu","lemma":"fieu","pos":"NOM"} ,
		{"word":"fieux","word_nosc":"fieux","lemma":"fieux","pos":"NOM"} ,
		{"word":"fifi","word_nosc":"fifi","lemma":"fifi","pos":"NOM"} ,
		{"word":"fifille","word_nosc":"fifille","lemma":"fifille","pos":"NOM"} ,
		{"word":"fifis","word_nosc":"fifis","lemma":"fifi","pos":"NOM"} ,
		{"word":"fifre","word_nosc":"fifre","lemma":"fifre","pos":"NOM"} ,
		{"word":"fifrelin","word_nosc":"fifrelin","lemma":"fifrelin","pos":"NOM"} ,
		{"word":"fifrelins","word_nosc":"fifrelins","lemma":"fifrelin","pos":"NOM"} ,
		{"word":"fifres","word_nosc":"fifres","lemma":"fifre","pos":"NOM"} ,
		{"word":"fifties","word_nosc":"fifties","lemma":"fifties","pos":"NOM"} ,
		{"word":"fifty","word_nosc":"fifty","lemma":"fifty","pos":"NOM"} ,
		{"word":"fifty-fifty","word_nosc":"fifty-fifty","lemma":"fifty-fifty","pos":"NOM"} ,
		{"word":"figaro","word_nosc":"figaro","lemma":"figaro","pos":"NOM"} ,
		{"word":"figaros","word_nosc":"figaros","lemma":"figaro","pos":"NOM"} ,
		{"word":"fignard","word_nosc":"fignard","lemma":"fignard","pos":"NOM"} ,
		{"word":"fignolage","word_nosc":"fignolage","lemma":"fignolage","pos":"NOM"} ,
		{"word":"fignolages","word_nosc":"fignolages","lemma":"fignolage","pos":"NOM"} ,
		{"word":"fignoleur","word_nosc":"fignoleur","lemma":"fignoleur","pos":"NOM"} ,
		{"word":"figue","word_nosc":"figue","lemma":"figue","pos":"NOM"} ,
		{"word":"figues","word_nosc":"figues","lemma":"figue","pos":"NOM"} ,
		{"word":"figuier","word_nosc":"figuier","lemma":"figuier","pos":"NOM"} ,
		{"word":"figuiers","word_nosc":"figuiers","lemma":"figuier","pos":"NOM"} ,
		{"word":"figurant","word_nosc":"figurant","lemma":"figurant","pos":"NOM"} ,
		{"word":"figurante","word_nosc":"figurante","lemma":"figurant","pos":"NOM"} ,
		{"word":"figurantes","word_nosc":"figurantes","lemma":"figurant","pos":"NOM"} ,
		{"word":"figurants","word_nosc":"figurants","lemma":"figurant","pos":"NOM"} ,
		{"word":"figuration","word_nosc":"figuration","lemma":"figuration","pos":"NOM"} ,
		{"word":"figurations","word_nosc":"figurations","lemma":"figuration","pos":"NOM"} ,
		{"word":"figure","word_nosc":"figure","lemma":"figure","pos":"NOM"} ,
		{"word":"figures","word_nosc":"figures","lemma":"figure","pos":"NOM"} ,
		{"word":"figurine","word_nosc":"figurine","lemma":"figurine","pos":"NOM"} ,
		{"word":"figurines","word_nosc":"figurines","lemma":"figurine","pos":"NOM"} ,
		{"word":"fil","word_nosc":"fil","lemma":"fil","pos":"NOM"} ,
		{"word":"fil-à-fil","word_nosc":"fil-a-fil","lemma":"fil-à-fil","pos":"NOM"} ,
		{"word":"filage","word_nosc":"filage","lemma":"filage","pos":"NOM"} ,
		{"word":"filament","word_nosc":"filament","lemma":"filament","pos":"NOM"} ,
		{"word":"filaments","word_nosc":"filaments","lemma":"filament","pos":"NOM"} ,
		{"word":"filandres","word_nosc":"filandres","lemma":"filandre","pos":"NOM"} ,
		{"word":"filaos","word_nosc":"filaos","lemma":"filao","pos":"NOM"} ,
		{"word":"filariose","word_nosc":"filariose","lemma":"filariose","pos":"NOM"} ,
		{"word":"filasse","word_nosc":"filasse","lemma":"filasse","pos":"NOM"} ,
		{"word":"filasses","word_nosc":"filasses","lemma":"filasse","pos":"NOM"} ,
		{"word":"filateur","word_nosc":"filateur","lemma":"filateur","pos":"NOM"} ,
		{"word":"filateurs","word_nosc":"filateurs","lemma":"filateur","pos":"NOM"} ,
		{"word":"filature","word_nosc":"filature","lemma":"filature","pos":"NOM"} ,
		{"word":"filatures","word_nosc":"filatures","lemma":"filature","pos":"NOM"} ,
		{"word":"file","word_nosc":"file","lemma":"file","pos":"NOM"} ,
		{"word":"file-la-moi","word_nosc":"file-la-moi","lemma":"file-la-moi","pos":"NOM"} ,
		{"word":"files","word_nosc":"files","lemma":"file","pos":"NOM"} ,
		{"word":"filet","word_nosc":"filet","lemma":"filet","pos":"NOM"} ,
		{"word":"filetage","word_nosc":"filetage","lemma":"filetage","pos":"NOM"} ,
		{"word":"filets","word_nosc":"filets","lemma":"filet","pos":"NOM"} ,
		{"word":"fileur","word_nosc":"fileur","lemma":"fileur","pos":"NOM"} ,
		{"word":"fileuse","word_nosc":"fileuse","lemma":"fileur","pos":"NOM"} ,
		{"word":"filiale","word_nosc":"filiale","lemma":"filiale","pos":"NOM"} ,
		{"word":"filiales","word_nosc":"filiales","lemma":"filiale","pos":"NOM"} ,
		{"word":"filiation","word_nosc":"filiation","lemma":"filiation","pos":"NOM"} ,
		{"word":"filiations","word_nosc":"filiations","lemma":"filiation","pos":"NOM"} ,
		{"word":"filigrane","word_nosc":"filigrane","lemma":"filigrane","pos":"NOM"} ,
		{"word":"filigranes","word_nosc":"filigranes","lemma":"filigrane","pos":"NOM"} ,
		{"word":"filin","word_nosc":"filin","lemma":"filin","pos":"NOM"} ,
		{"word":"filins","word_nosc":"filins","lemma":"filin","pos":"NOM"} ,
		{"word":"filière","word_nosc":"filiere","lemma":"filière","pos":"NOM"} ,
		{"word":"filières","word_nosc":"filieres","lemma":"filière","pos":"NOM"} ,
		{"word":"fillasses","word_nosc":"fillasses","lemma":"fillasse","pos":"NOM"} ,
		{"word":"fille","word_nosc":"fille","lemma":"fille","pos":"NOM"} ,
		{"word":"fille-mère","word_nosc":"fille-mere","lemma":"fille-mère","pos":"NOM"} ,
		{"word":"filles","word_nosc":"filles","lemma":"fille","pos":"NOM"} ,
		{"word":"fillette","word_nosc":"fillette","lemma":"fillette","pos":"NOM"} ,
		{"word":"fillettes","word_nosc":"fillettes","lemma":"fillette","pos":"NOM"} ,
		{"word":"filleul","word_nosc":"filleul","lemma":"filleul","pos":"NOM"} ,
		{"word":"filleule","word_nosc":"filleule","lemma":"filleul","pos":"NOM"} ,
		{"word":"filleuls","word_nosc":"filleuls","lemma":"filleul","pos":"NOM"} ,
		{"word":"fillér","word_nosc":"filler","lemma":"fillér","pos":"NOM"} ,
		{"word":"film","word_nosc":"film","lemma":"film","pos":"NOM"} ,
		{"word":"film-livre","word_nosc":"film-livre","lemma":"film-livre","pos":"NOM"} ,
		{"word":"filmage","word_nosc":"filmage","lemma":"filmage","pos":"NOM"} ,
		{"word":"filmographie","word_nosc":"filmographie","lemma":"filmographie","pos":"NOM"} ,
		{"word":"filmothèque","word_nosc":"filmotheque","lemma":"filmothèque","pos":"NOM"} ,
		{"word":"films","word_nosc":"films","lemma":"film","pos":"NOM"} ,
		{"word":"films-annonces","word_nosc":"films-annonces","lemma":"films-annonce","pos":"NOM"} ,
		{"word":"filochard","word_nosc":"filochard","lemma":"filochard","pos":"NOM"} ,
		{"word":"filoche","word_nosc":"filoche","lemma":"filoche","pos":"NOM"} ,
		{"word":"filon","word_nosc":"filon","lemma":"filon","pos":"NOM"} ,
		{"word":"filons","word_nosc":"filons","lemma":"filon","pos":"NOM"} ,
		{"word":"filoselle","word_nosc":"filoselle","lemma":"filoselle","pos":"NOM"} ,
		{"word":"filou","word_nosc":"filou","lemma":"filou","pos":"NOM"} ,
		{"word":"filous","word_nosc":"filous","lemma":"filou","pos":"NOM"} ,
		{"word":"filouterie","word_nosc":"filouterie","lemma":"filouterie","pos":"NOM"} ,
		{"word":"filouteries","word_nosc":"filouteries","lemma":"filouterie","pos":"NOM"} ,
		{"word":"fils","word_nosc":"fils","lemma":"fil","pos":"NOM"} ,
		{"word":"fils","word_nosc":"fils","lemma":"fils","pos":"NOM"} ,
		{"word":"filtrage","word_nosc":"filtrage","lemma":"filtrage","pos":"NOM"} ,
		{"word":"filtration","word_nosc":"filtration","lemma":"filtration","pos":"NOM"} ,
		{"word":"filtrations","word_nosc":"filtrations","lemma":"filtration","pos":"NOM"} ,
		{"word":"filtre","word_nosc":"filtre","lemma":"filtre","pos":"NOM"} ,
		{"word":"filtres","word_nosc":"filtres","lemma":"filtre","pos":"NOM"} ,
		{"word":"filé","word_nosc":"file","lemma":"filé","pos":"NOM"} ,
		{"word":"filés","word_nosc":"files","lemma":"filé","pos":"NOM"} ,
		{"word":"fin","word_nosc":"fin","lemma":"fin","pos":"NOM"} ,
		{"word":"finage","word_nosc":"finage","lemma":"finage","pos":"NOM"} ,
		{"word":"final","word_nosc":"final","lemma":"final","pos":"NOM"} ,
		{"word":"finale","word_nosc":"finale","lemma":"finale","pos":"NOM"} ,
		{"word":"finales","word_nosc":"finales","lemma":"finale","pos":"NOM"} ,
		{"word":"finalisation","word_nosc":"finalisation","lemma":"finalisation","pos":"NOM"} ,
		{"word":"finaliste","word_nosc":"finaliste","lemma":"finaliste","pos":"NOM"} ,
		{"word":"finalistes","word_nosc":"finalistes","lemma":"finaliste","pos":"NOM"} ,
		{"word":"finalité","word_nosc":"finalite","lemma":"finalité","pos":"NOM"} ,
		{"word":"finalités","word_nosc":"finalites","lemma":"finalité","pos":"NOM"} ,
		{"word":"finals","word_nosc":"finals","lemma":"final","pos":"NOM"} ,
		{"word":"finance","word_nosc":"finance","lemma":"finance","pos":"NOM"} ,
		{"word":"financement","word_nosc":"financement","lemma":"financement","pos":"NOM"} ,
		{"word":"financements","word_nosc":"financements","lemma":"financement","pos":"NOM"} ,
		{"word":"finances","word_nosc":"finances","lemma":"finance","pos":"NOM"} ,
		{"word":"financier","word_nosc":"financier","lemma":"financier","pos":"NOM"} ,
		{"word":"financiers","word_nosc":"financiers","lemma":"financier","pos":"NOM"} ,
		{"word":"financière","word_nosc":"financiere","lemma":"financier","pos":"NOM"} ,
		{"word":"financières","word_nosc":"financieres","lemma":"financier","pos":"NOM"} ,
		{"word":"finasserie","word_nosc":"finasserie","lemma":"finasserie","pos":"NOM"} ,
		{"word":"finasseries","word_nosc":"finasseries","lemma":"finasserie","pos":"NOM"} ,
		{"word":"finaud","word_nosc":"finaud","lemma":"finaud","pos":"NOM"} ,
		{"word":"finaude","word_nosc":"finaude","lemma":"finaud","pos":"NOM"} ,
		{"word":"finauds","word_nosc":"finauds","lemma":"finaud","pos":"NOM"} ,
		{"word":"fine","word_nosc":"fine","lemma":"fine","pos":"NOM"} ,
		{"word":"fines","word_nosc":"fines","lemma":"fine","pos":"NOM"} ,
		{"word":"finesse","word_nosc":"finesse","lemma":"finesse","pos":"NOM"} ,
		{"word":"finesses","word_nosc":"finesses","lemma":"finesse","pos":"NOM"} ,
		{"word":"finette","word_nosc":"finette","lemma":"finette","pos":"NOM"} ,
		{"word":"finish","word_nosc":"finish","lemma":"finish","pos":"NOM"} ,
		{"word":"finisseur","word_nosc":"finisseur","lemma":"finisseur","pos":"NOM"} ,
		{"word":"finisseuse","word_nosc":"finisseuse","lemma":"finisseur","pos":"NOM"} ,
		{"word":"finition","word_nosc":"finition","lemma":"finition","pos":"NOM"} ,
		{"word":"finitions","word_nosc":"finitions","lemma":"finition","pos":"NOM"} ,
		{"word":"finitude","word_nosc":"finitude","lemma":"finitude","pos":"NOM"} ,
		{"word":"finlandais","word_nosc":"finlandais","lemma":"finlandais","pos":"NOM"} ,
		{"word":"finlandaise","word_nosc":"finlandaise","lemma":"finlandais","pos":"NOM"} ,
		{"word":"finlandisation","word_nosc":"finlandisation","lemma":"finlandisation","pos":"NOM"} ,
		{"word":"finnois","word_nosc":"finnois","lemma":"finnois","pos":"NOM"} ,
		{"word":"fins","word_nosc":"fins","lemma":"fin","pos":"NOM"} ,
		{"word":"fiole","word_nosc":"fiole","lemma":"fiole","pos":"NOM"} ,
		{"word":"fioles","word_nosc":"fioles","lemma":"fiole","pos":"NOM"} ,
		{"word":"fion","word_nosc":"fion","lemma":"fion","pos":"NOM"} ,
		{"word":"fions","word_nosc":"fions","lemma":"fion","pos":"NOM"} ,
		{"word":"fiord","word_nosc":"fiord","lemma":"fiord","pos":"NOM"} ,
		{"word":"fioriture","word_nosc":"fioriture","lemma":"fioriture","pos":"NOM"} ,
		{"word":"fioritures","word_nosc":"fioritures","lemma":"fioriture","pos":"NOM"} ,
		{"word":"fioul","word_nosc":"fioul","lemma":"fioul","pos":"NOM"} ,
		{"word":"firmament","word_nosc":"firmament","lemma":"firmament","pos":"NOM"} ,
		{"word":"firmaments","word_nosc":"firmaments","lemma":"firmament","pos":"NOM"} ,
		{"word":"firman","word_nosc":"firman","lemma":"firman","pos":"NOM"} ,
		{"word":"firmans","word_nosc":"firmans","lemma":"firman","pos":"NOM"} ,
		{"word":"firme","word_nosc":"firme","lemma":"firme","pos":"NOM"} ,
		{"word":"firmes","word_nosc":"firmes","lemma":"firme","pos":"NOM"} ,
		{"word":"fisc","word_nosc":"fisc","lemma":"fisc","pos":"NOM"} ,
		{"word":"fiscaliste","word_nosc":"fiscaliste","lemma":"fiscaliste","pos":"NOM"} ,
		{"word":"fiscalité","word_nosc":"fiscalite","lemma":"fiscalité","pos":"NOM"} ,
		{"word":"fission","word_nosc":"fission","lemma":"fission","pos":"NOM"} ,
		{"word":"fissure","word_nosc":"fissure","lemma":"fissure","pos":"NOM"} ,
		{"word":"fissures","word_nosc":"fissures","lemma":"fissure","pos":"NOM"} ,
		{"word":"fiston","word_nosc":"fiston","lemma":"fiston","pos":"NOM"} ,
		{"word":"fistons","word_nosc":"fistons","lemma":"fiston","pos":"NOM"} ,
		{"word":"fistule","word_nosc":"fistule","lemma":"fistule","pos":"NOM"} ,
		{"word":"fistules","word_nosc":"fistules","lemma":"fistule","pos":"NOM"} ,
		{"word":"fitness","word_nosc":"fitness","lemma":"fitness","pos":"NOM"} ,
		{"word":"five o'clock","word_nosc":"five o'clock","lemma":"five o'clock","pos":"NOM"} ,
		{"word":"fivete","word_nosc":"fivete","lemma":"fivete","pos":"NOM"} ,
		{"word":"fixage","word_nosc":"fixage","lemma":"fixage","pos":"NOM"} ,
		{"word":"fixateur","word_nosc":"fixateur","lemma":"fixateur","pos":"NOM"} ,
		{"word":"fixatif","word_nosc":"fixatif","lemma":"fixatif","pos":"NOM"} ,
		{"word":"fixation","word_nosc":"fixation","lemma":"fixation","pos":"NOM"} ,
		{"word":"fixations","word_nosc":"fixations","lemma":"fixation","pos":"NOM"} ,
		{"word":"fixe-chaussettes","word_nosc":"fixe-chaussettes","lemma":"fixe-chaussette","pos":"NOM"} ,
		{"word":"fixette","word_nosc":"fixette","lemma":"fixette","pos":"NOM"} ,
		{"word":"fixing","word_nosc":"fixing","lemma":"fixing","pos":"NOM"} ,
		{"word":"fixité","word_nosc":"fixite","lemma":"fixité","pos":"NOM"} ,
		{"word":"fièvre","word_nosc":"fievre","lemma":"fièvre","pos":"NOM"} ,
		{"word":"fièvres","word_nosc":"fievres","lemma":"fièvre","pos":"NOM"} ,
		{"word":"fiérot","word_nosc":"fierot","lemma":"fiérot","pos":"NOM"} ,
		{"word":"fjord","word_nosc":"fjord","lemma":"fjord","pos":"NOM"} ,
		{"word":"fjords","word_nosc":"fjords","lemma":"fjord","pos":"NOM"} ,
		{"word":"fla","word_nosc":"fla","lemma":"fla","pos":"NOM"} ,
		{"word":"flaccidité","word_nosc":"flaccidite","lemma":"flaccidité","pos":"NOM"} ,
		{"word":"flache","word_nosc":"flache","lemma":"flache","pos":"NOM"} ,
		{"word":"flaches","word_nosc":"flaches","lemma":"flache","pos":"NOM"} ,
		{"word":"flacon","word_nosc":"flacon","lemma":"flacon","pos":"NOM"} ,
		{"word":"flacons","word_nosc":"flacons","lemma":"flacon","pos":"NOM"} ,
		{"word":"flafla","word_nosc":"flafla","lemma":"flafla","pos":"NOM"} ,
		{"word":"flag","word_nosc":"flag","lemma":"flag","pos":"NOM"} ,
		{"word":"flagellant","word_nosc":"flagellant","lemma":"flagellant","pos":"NOM"} ,
		{"word":"flagellants","word_nosc":"flagellants","lemma":"flagellant","pos":"NOM"} ,
		{"word":"flagellateur","word_nosc":"flagellateur","lemma":"flagellateur","pos":"NOM"} ,
		{"word":"flagellation","word_nosc":"flagellation","lemma":"flagellation","pos":"NOM"} ,
		{"word":"flagellations","word_nosc":"flagellations","lemma":"flagellation","pos":"NOM"} ,
		{"word":"flagelle","word_nosc":"flagelle","lemma":"flagelle","pos":"NOM"} ,
		{"word":"flagellé","word_nosc":"flagelle","lemma":"flagellé","pos":"NOM"} ,
		{"word":"flageolet","word_nosc":"flageolet","lemma":"flageolet","pos":"NOM"} ,
		{"word":"flageolets","word_nosc":"flageolets","lemma":"flageolet","pos":"NOM"} ,
		{"word":"flagornerie","word_nosc":"flagornerie","lemma":"flagornerie","pos":"NOM"} ,
		{"word":"flagorneries","word_nosc":"flagorneries","lemma":"flagornerie","pos":"NOM"} ,
		{"word":"flagorneur","word_nosc":"flagorneur","lemma":"flagorneur","pos":"NOM"} ,
		{"word":"flagorneurs","word_nosc":"flagorneurs","lemma":"flagorneur","pos":"NOM"} ,
		{"word":"flagrance","word_nosc":"flagrance","lemma":"flagrance","pos":"NOM"} ,
		{"word":"flahutes","word_nosc":"flahutes","lemma":"flahute","pos":"NOM"} ,
		{"word":"flair","word_nosc":"flair","lemma":"flair","pos":"NOM"} ,
		{"word":"flaireur","word_nosc":"flaireur","lemma":"flaireur","pos":"NOM"} ,
		{"word":"flaireurs","word_nosc":"flaireurs","lemma":"flaireur","pos":"NOM"} ,
		{"word":"flaireuse","word_nosc":"flaireuse","lemma":"flaireur","pos":"NOM"} ,
		{"word":"flamand","word_nosc":"flamand","lemma":"flamand","pos":"NOM"} ,
		{"word":"flamande","word_nosc":"flamande","lemma":"flamand","pos":"NOM"} ,
		{"word":"flamandes","word_nosc":"flamandes","lemma":"flamand","pos":"NOM"} ,
		{"word":"flamands","word_nosc":"flamands","lemma":"flamand","pos":"NOM"} ,
		{"word":"flamant","word_nosc":"flamant","lemma":"flamant","pos":"NOM"} ,
		{"word":"flamants","word_nosc":"flamants","lemma":"flamant","pos":"NOM"} ,
		{"word":"flambard","word_nosc":"flambard","lemma":"flambard","pos":"NOM"} ,
		{"word":"flambe","word_nosc":"flambe","lemma":"flambe","pos":"NOM"} ,
		{"word":"flambeau","word_nosc":"flambeau","lemma":"flambeau","pos":"NOM"} ,
		{"word":"flambeaux","word_nosc":"flambeaux","lemma":"flambeau","pos":"NOM"} ,
		{"word":"flamberge","word_nosc":"flamberge","lemma":"flamberge","pos":"NOM"} ,
		{"word":"flambeur","word_nosc":"flambeur","lemma":"flambeur","pos":"NOM"} ,
		{"word":"flambeurs","word_nosc":"flambeurs","lemma":"flambeur","pos":"NOM"} ,
		{"word":"flambeuse","word_nosc":"flambeuse","lemma":"flambeur","pos":"NOM"} ,
		{"word":"flamboiement","word_nosc":"flamboiement","lemma":"flamboiement","pos":"NOM"} ,
		{"word":"flamboiements","word_nosc":"flamboiements","lemma":"flamboiement","pos":"NOM"} ,
		{"word":"flamboyance","word_nosc":"flamboyance","lemma":"flamboyance","pos":"NOM"} ,
		{"word":"flambée","word_nosc":"flambee","lemma":"flambée","pos":"NOM"} ,
		{"word":"flambées","word_nosc":"flambees","lemma":"flambée","pos":"NOM"} ,
		{"word":"flamenco","word_nosc":"flamenco","lemma":"flamenco","pos":"NOM"} ,
		{"word":"flamine","word_nosc":"flamine","lemma":"flamine","pos":"NOM"} ,
		{"word":"flamme","word_nosc":"flamme","lemma":"flamme","pos":"NOM"} ,
		{"word":"flammes","word_nosc":"flammes","lemma":"flamme","pos":"NOM"} ,
		{"word":"flammèche","word_nosc":"flammeche","lemma":"flammèche","pos":"NOM"} ,
		{"word":"flammèches","word_nosc":"flammeches","lemma":"flammèche","pos":"NOM"} ,
		{"word":"flan","word_nosc":"flan","lemma":"flan","pos":"NOM"} ,
		{"word":"flanc","word_nosc":"flanc","lemma":"flanc","pos":"NOM"} ,
		{"word":"flanc-garde","word_nosc":"flanc-garde","lemma":"flanc-garde","pos":"NOM"} ,
		{"word":"flanchet","word_nosc":"flanchet","lemma":"flanchet","pos":"NOM"} ,
		{"word":"flancs","word_nosc":"flancs","lemma":"flanc","pos":"NOM"} ,
		{"word":"flancs-gardes","word_nosc":"flancs-gardes","lemma":"flanc-garde","pos":"NOM"} ,
		{"word":"flandrin","word_nosc":"flandrin","lemma":"flandrin","pos":"NOM"} ,
		{"word":"flanelle","word_nosc":"flanelle","lemma":"flanelle","pos":"NOM"} ,
		{"word":"flanelles","word_nosc":"flanelles","lemma":"flanelle","pos":"NOM"} ,
		{"word":"flanquement","word_nosc":"flanquement","lemma":"flanquement","pos":"NOM"} ,
		{"word":"flans","word_nosc":"flans","lemma":"flan","pos":"NOM"} ,
		{"word":"flaque","word_nosc":"flaque","lemma":"flaque","pos":"NOM"} ,
		{"word":"flaques","word_nosc":"flaques","lemma":"flaque","pos":"NOM"} ,
		{"word":"flash","word_nosc":"flash","lemma":"flash","pos":"NOM"} ,
		{"word":"flash-back","word_nosc":"flash-back","lemma":"flash-back","pos":"NOM"} ,
		{"word":"flashage","word_nosc":"flashage","lemma":"flashage","pos":"NOM"} ,
		{"word":"flashes","word_nosc":"flashes","lemma":"flashe","pos":"NOM"} ,
		{"word":"flasheur","word_nosc":"flasheur","lemma":"flasheur","pos":"NOM"} ,
		{"word":"flashs","word_nosc":"flashs","lemma":"flash","pos":"NOM"} ,
		{"word":"flask","word_nosc":"flask","lemma":"flask","pos":"NOM"} ,
		{"word":"flasque","word_nosc":"flasque","lemma":"flasque","pos":"NOM"} ,
		{"word":"flasques","word_nosc":"flasques","lemma":"flasque","pos":"NOM"} ,
		{"word":"flat","word_nosc":"flat","lemma":"flat","pos":"NOM"} ,
		{"word":"flats","word_nosc":"flats","lemma":"flat","pos":"NOM"} ,
		{"word":"flatterie","word_nosc":"flatterie","lemma":"flatterie","pos":"NOM"} ,
		{"word":"flatteries","word_nosc":"flatteries","lemma":"flatterie","pos":"NOM"} ,
		{"word":"flatteur","word_nosc":"flatteur","lemma":"flatteur","pos":"NOM"} ,
		{"word":"flatteurs","word_nosc":"flatteurs","lemma":"flatteur","pos":"NOM"} ,
		{"word":"flatulence","word_nosc":"flatulence","lemma":"flatulence","pos":"NOM"} ,
		{"word":"flatulences","word_nosc":"flatulences","lemma":"flatulence","pos":"NOM"} ,
		{"word":"flatuosité","word_nosc":"flatuosite","lemma":"flatuosité","pos":"NOM"} ,
		{"word":"flatus vocis","word_nosc":"flatus vocis","lemma":"flatus vocis","pos":"NOM"} ,
		{"word":"flaveur","word_nosc":"flaveur","lemma":"flaveur","pos":"NOM"} ,
		{"word":"flegmatisme","word_nosc":"flegmatisme","lemma":"flegmatisme","pos":"NOM"} ,
		{"word":"flegme","word_nosc":"flegme","lemma":"flegme","pos":"NOM"} ,
		{"word":"flemmard","word_nosc":"flemmard","lemma":"flemmard","pos":"NOM"} ,
		{"word":"flemmarde","word_nosc":"flemmarde","lemma":"flemmard","pos":"NOM"} ,
		{"word":"flemmardise","word_nosc":"flemmardise","lemma":"flemmardise","pos":"NOM"} ,
		{"word":"flemmards","word_nosc":"flemmards","lemma":"flemmard","pos":"NOM"} ,
		{"word":"flemme","word_nosc":"flemme","lemma":"flemme","pos":"NOM"} ,
		{"word":"fleur","word_nosc":"fleur","lemma":"fleur","pos":"NOM"} ,
		{"word":"fleurage","word_nosc":"fleurage","lemma":"fleurage","pos":"NOM"} ,
		{"word":"fleuret","word_nosc":"fleuret","lemma":"fleuret","pos":"NOM"} ,
		{"word":"fleurets","word_nosc":"fleurets","lemma":"fleuret","pos":"NOM"} ,
		{"word":"fleurette","word_nosc":"fleurette","lemma":"fleurette","pos":"NOM"} ,
		{"word":"fleurettes","word_nosc":"fleurettes","lemma":"fleurette","pos":"NOM"} ,
		{"word":"fleurissement","word_nosc":"fleurissement","lemma":"fleurissement","pos":"NOM"} ,
		{"word":"fleuriste","word_nosc":"fleuriste","lemma":"fleuriste","pos":"NOM"} ,
		{"word":"fleuristes","word_nosc":"fleuristes","lemma":"fleuriste","pos":"NOM"} ,
		{"word":"fleuron","word_nosc":"fleuron","lemma":"fleuron","pos":"NOM"} ,
		{"word":"fleurons","word_nosc":"fleurons","lemma":"fleuron","pos":"NOM"} ,
		{"word":"fleurs","word_nosc":"fleurs","lemma":"fleur","pos":"NOM"} ,
		{"word":"fleuve","word_nosc":"fleuve","lemma":"fleuve","pos":"NOM"} ,
		{"word":"fleuves","word_nosc":"fleuves","lemma":"fleuve","pos":"NOM"} ,
		{"word":"flexibilité","word_nosc":"flexibilite","lemma":"flexibilité","pos":"NOM"} ,
		{"word":"flexion","word_nosc":"flexion","lemma":"flexion","pos":"NOM"} ,
		{"word":"flexions","word_nosc":"flexions","lemma":"flexion","pos":"NOM"} ,
		{"word":"flexuosité","word_nosc":"flexuosite","lemma":"flexuosité","pos":"NOM"} ,
		{"word":"flibuste","word_nosc":"flibuste","lemma":"flibuste","pos":"NOM"} ,
		{"word":"flibustier","word_nosc":"flibustier","lemma":"flibustier","pos":"NOM"} ,
		{"word":"flibustiers","word_nosc":"flibustiers","lemma":"flibustier","pos":"NOM"} ,
		{"word":"flic","word_nosc":"flic","lemma":"flic","pos":"NOM"} ,
		{"word":"flicage","word_nosc":"flicage","lemma":"flicage","pos":"NOM"} ,
		{"word":"flicaille","word_nosc":"flicaille","lemma":"flicaille","pos":"NOM"} ,
		{"word":"flicailles","word_nosc":"flicailles","lemma":"flicaille","pos":"NOM"} ,
		{"word":"flicard","word_nosc":"flicard","lemma":"flicard","pos":"NOM"} ,
		{"word":"flicards","word_nosc":"flicards","lemma":"flicard","pos":"NOM"} ,
		{"word":"flics","word_nosc":"flics","lemma":"flic","pos":"NOM"} ,
		{"word":"flingage","word_nosc":"flingage","lemma":"flingage","pos":"NOM"} ,
		{"word":"flingot","word_nosc":"flingot","lemma":"flingot","pos":"NOM"} ,
		{"word":"flingots","word_nosc":"flingots","lemma":"flingot","pos":"NOM"} ,
		{"word":"flingue","word_nosc":"flingue","lemma":"flingue","pos":"NOM"} ,
		{"word":"flingues","word_nosc":"flingues","lemma":"flingue","pos":"NOM"} ,
		{"word":"flingueur","word_nosc":"flingueur","lemma":"flingueur","pos":"NOM"} ,
		{"word":"flingueurs","word_nosc":"flingueurs","lemma":"flingueur","pos":"NOM"} ,
		{"word":"flingueuse","word_nosc":"flingueuse","lemma":"flingueur","pos":"NOM"} ,
		{"word":"flingueuses","word_nosc":"flingueuses","lemma":"flingueur","pos":"NOM"} ,
		{"word":"flint","word_nosc":"flint","lemma":"flint","pos":"NOM"} ,
		{"word":"flip","word_nosc":"flip","lemma":"flip","pos":"NOM"} ,
		{"word":"flip-flap","word_nosc":"flip-flap","lemma":"flip-flap","pos":"NOM"} ,
		{"word":"flip-flop","word_nosc":"flip-flop","lemma":"flip-flop","pos":"NOM"} ,
		{"word":"flipper","word_nosc":"flipper","lemma":"flipper","pos":"NOM"} ,
		{"word":"flippers","word_nosc":"flippers","lemma":"flipper","pos":"NOM"} ,
		{"word":"flippé","word_nosc":"flippe","lemma":"flippé","pos":"NOM"} ,
		{"word":"flippée","word_nosc":"flippee","lemma":"flippé","pos":"NOM"} ,
		{"word":"flippés","word_nosc":"flippes","lemma":"flippé","pos":"NOM"} ,
		{"word":"flips","word_nosc":"flips","lemma":"flip","pos":"NOM"} ,
		{"word":"fliquesse","word_nosc":"fliquesse","lemma":"fliquesse","pos":"NOM"} ,
		{"word":"flirt","word_nosc":"flirt","lemma":"flirt","pos":"NOM"} ,
		{"word":"flirtation","word_nosc":"flirtation","lemma":"flirtation","pos":"NOM"} ,
		{"word":"flirteur","word_nosc":"flirteur","lemma":"flirteur","pos":"NOM"} ,
		{"word":"flirteuse","word_nosc":"flirteuse","lemma":"flirteur","pos":"NOM"} ,
		{"word":"flirts","word_nosc":"flirts","lemma":"flirt","pos":"NOM"} ,
		{"word":"floche","word_nosc":"floche","lemma":"floche","pos":"NOM"} ,
		{"word":"floches","word_nosc":"floches","lemma":"floche","pos":"NOM"} ,
		{"word":"flocon","word_nosc":"flocon","lemma":"flocon","pos":"NOM"} ,
		{"word":"flocons","word_nosc":"flocons","lemma":"flocon","pos":"NOM"} ,
		{"word":"floculation","word_nosc":"floculation","lemma":"floculation","pos":"NOM"} ,
		{"word":"flonflon","word_nosc":"flonflon","lemma":"flonflon","pos":"NOM"} ,
		{"word":"flonflons","word_nosc":"flonflons","lemma":"flonflon","pos":"NOM"} ,
		{"word":"flood","word_nosc":"flood","lemma":"flood","pos":"NOM"} ,
		{"word":"flop","word_nosc":"flop","lemma":"flop","pos":"NOM"} ,
		{"word":"flops","word_nosc":"flops","lemma":"flop","pos":"NOM"} ,
		{"word":"flopée","word_nosc":"flopee","lemma":"flopée","pos":"NOM"} ,
		{"word":"flopées","word_nosc":"flopees","lemma":"flopée","pos":"NOM"} ,
		{"word":"floraison","word_nosc":"floraison","lemma":"floraison","pos":"NOM"} ,
		{"word":"floraisons","word_nosc":"floraisons","lemma":"floraison","pos":"NOM"} ,
		{"word":"floralies","word_nosc":"floralies","lemma":"floralies","pos":"NOM"} ,
		{"word":"flore","word_nosc":"flore","lemma":"flore","pos":"NOM"} ,
		{"word":"florence","word_nosc":"florence","lemma":"florence","pos":"NOM"} ,
		{"word":"flores","word_nosc":"flores","lemma":"flore","pos":"NOM"} ,
		{"word":"florilège","word_nosc":"florilege","lemma":"florilège","pos":"NOM"} ,
		{"word":"florin","word_nosc":"florin","lemma":"florin","pos":"NOM"} ,
		{"word":"florins","word_nosc":"florins","lemma":"florin","pos":"NOM"} ,
		{"word":"floréal","word_nosc":"floreal","lemma":"floréal","pos":"NOM"} ,
		{"word":"flot","word_nosc":"flot","lemma":"flot","pos":"NOM"} ,
		{"word":"flots","word_nosc":"flots","lemma":"flot","pos":"NOM"} ,
		{"word":"flottabilité","word_nosc":"flottabilite","lemma":"flottabilité","pos":"NOM"} ,
		{"word":"flottage","word_nosc":"flottage","lemma":"flottage","pos":"NOM"} ,
		{"word":"flottaison","word_nosc":"flottaison","lemma":"flottaison","pos":"NOM"} ,
		{"word":"flottaisons","word_nosc":"flottaisons","lemma":"flottaison","pos":"NOM"} ,
		{"word":"flottant","word_nosc":"flottant","lemma":"flottant","pos":"NOM"} ,
		{"word":"flottants","word_nosc":"flottants","lemma":"flottant","pos":"NOM"} ,
		{"word":"flottation","word_nosc":"flottation","lemma":"flottation","pos":"NOM"} ,
		{"word":"flotte","word_nosc":"flotte","lemma":"flotte","pos":"NOM"} ,
		{"word":"flottement","word_nosc":"flottement","lemma":"flottement","pos":"NOM"} ,
		{"word":"flottements","word_nosc":"flottements","lemma":"flottement","pos":"NOM"} ,
		{"word":"flottes","word_nosc":"flottes","lemma":"flotte","pos":"NOM"} ,
		{"word":"flotteur","word_nosc":"flotteur","lemma":"flotteur","pos":"NOM"} ,
		{"word":"flotteurs","word_nosc":"flotteurs","lemma":"flotteur","pos":"NOM"} ,
		{"word":"flottille","word_nosc":"flottille","lemma":"flottille","pos":"NOM"} ,
		{"word":"flottilles","word_nosc":"flottilles","lemma":"flottille","pos":"NOM"} ,
		{"word":"flou","word_nosc":"flou","lemma":"flou","pos":"NOM"} ,
		{"word":"flouerie","word_nosc":"flouerie","lemma":"flouerie","pos":"NOM"} ,
		{"word":"flous","word_nosc":"flous","lemma":"flou","pos":"NOM"} ,
		{"word":"flouse","word_nosc":"flouse","lemma":"flouse","pos":"NOM"} ,
		{"word":"flouve","word_nosc":"flouve","lemma":"flouve","pos":"NOM"} ,
		{"word":"flouzaille","word_nosc":"flouzaille","lemma":"flouzaille","pos":"NOM"} ,
		{"word":"flouze","word_nosc":"flouze","lemma":"flouze","pos":"NOM"} ,
		{"word":"fluctuation","word_nosc":"fluctuation","lemma":"fluctuation","pos":"NOM"} ,
		{"word":"fluctuations","word_nosc":"fluctuations","lemma":"fluctuation","pos":"NOM"} ,
		{"word":"fluence","word_nosc":"fluence","lemma":"fluence","pos":"NOM"} ,
		{"word":"fluide","word_nosc":"fluide","lemma":"fluide","pos":"NOM"} ,
		{"word":"fluides","word_nosc":"fluides","lemma":"fluide","pos":"NOM"} ,
		{"word":"fluidifiant","word_nosc":"fluidifiant","lemma":"fluidifiant","pos":"NOM"} ,
		{"word":"fluidité","word_nosc":"fluidite","lemma":"fluidité","pos":"NOM"} ,
		{"word":"fluor","word_nosc":"fluor","lemma":"fluor","pos":"NOM"} ,
		{"word":"fluorescence","word_nosc":"fluorescence","lemma":"fluorescence","pos":"NOM"} ,
		{"word":"fluorescéine","word_nosc":"fluoresceine","lemma":"fluorescéine","pos":"NOM"} ,
		{"word":"fluorite","word_nosc":"fluorite","lemma":"fluorite","pos":"NOM"} ,
		{"word":"fluorure","word_nosc":"fluorure","lemma":"fluorure","pos":"NOM"} ,
		{"word":"flush","word_nosc":"flush","lemma":"flush","pos":"NOM"} ,
		{"word":"flux","word_nosc":"flux","lemma":"flux","pos":"NOM"} ,
		{"word":"fluxion","word_nosc":"fluxion","lemma":"fluxion","pos":"NOM"} ,
		{"word":"flâne","word_nosc":"flane","lemma":"flâne","pos":"NOM"} ,
		{"word":"flânerie","word_nosc":"flanerie","lemma":"flânerie","pos":"NOM"} ,
		{"word":"flâneries","word_nosc":"flaneries","lemma":"flânerie","pos":"NOM"} ,
		{"word":"flâneur","word_nosc":"flaneur","lemma":"flâneur","pos":"NOM"} ,
		{"word":"flâneurs","word_nosc":"flaneurs","lemma":"flâneur","pos":"NOM"} ,
		{"word":"flâneuse","word_nosc":"flaneuse","lemma":"flâneuse","pos":"NOM"} ,
		{"word":"flâneuses","word_nosc":"flaneuses","lemma":"flâneur","pos":"NOM"} ,
		{"word":"flèche","word_nosc":"fleche","lemma":"flèche","pos":"NOM"} ,
		{"word":"flèches","word_nosc":"fleches","lemma":"flèche","pos":"NOM"} ,
		{"word":"fléau","word_nosc":"fleau","lemma":"fléau","pos":"NOM"} ,
		{"word":"fléaux","word_nosc":"fleaux","lemma":"fléau","pos":"NOM"} ,
		{"word":"fléchette","word_nosc":"flechette","lemma":"fléchette","pos":"NOM"} ,
		{"word":"fléchettes","word_nosc":"flechettes","lemma":"fléchette","pos":"NOM"} ,
		{"word":"fléchissement","word_nosc":"flechissement","lemma":"fléchissement","pos":"NOM"} ,
		{"word":"fléchissements","word_nosc":"flechissements","lemma":"fléchissement","pos":"NOM"} ,
		{"word":"fléole","word_nosc":"fleole","lemma":"fléole","pos":"NOM"} ,
		{"word":"flétan","word_nosc":"fletan","lemma":"flétan","pos":"NOM"} ,
		{"word":"flétans","word_nosc":"fletans","lemma":"flétan","pos":"NOM"} ,
		{"word":"flétrissement","word_nosc":"fletrissement","lemma":"flétrissement","pos":"NOM"} ,
		{"word":"flétrissure","word_nosc":"fletrissure","lemma":"flétrissure","pos":"NOM"} ,
		{"word":"flétrissures","word_nosc":"fletrissures","lemma":"flétrissure","pos":"NOM"} ,
		{"word":"flûte","word_nosc":"flute","lemma":"flûte","pos":"NOM"} ,
		{"word":"flûtes","word_nosc":"flutes","lemma":"flûte","pos":"NOM"} ,
		{"word":"flûtiau","word_nosc":"flutiau","lemma":"flûtiau","pos":"NOM"} ,
		{"word":"flûtiste","word_nosc":"flutiste","lemma":"flûtiste","pos":"NOM"} ,
		{"word":"flûtistes","word_nosc":"flutistes","lemma":"flûtiste","pos":"NOM"} ,
		{"word":"foc","word_nosc":"foc","lemma":"foc","pos":"NOM"} ,
		{"word":"focale","word_nosc":"focale","lemma":"focale","pos":"NOM"} ,
		{"word":"focalisation","word_nosc":"focalisation","lemma":"focalisation","pos":"NOM"} ,
		{"word":"focs","word_nosc":"focs","lemma":"foc","pos":"NOM"} ,
		{"word":"foehn","word_nosc":"foehn","lemma":"foehn","pos":"NOM"} ,
		{"word":"foetus","word_nosc":"foetus","lemma":"foetus","pos":"NOM"} ,
		{"word":"fofolle","word_nosc":"fofolle","lemma":"fofolle","pos":"NOM"} ,
		{"word":"fofolles","word_nosc":"fofolles","lemma":"fofolle","pos":"NOM"} ,
		{"word":"foi","word_nosc":"foi","lemma":"foi","pos":"NOM"} ,
		{"word":"foie","word_nosc":"foie","lemma":"foie","pos":"NOM"} ,
		{"word":"foies","word_nosc":"foies","lemma":"foie","pos":"NOM"} ,
		{"word":"foil","word_nosc":"foil","lemma":"foil","pos":"NOM"} ,
		{"word":"foin","word_nosc":"foin","lemma":"foin","pos":"NOM"} ,
		{"word":"foins","word_nosc":"foins","lemma":"foin","pos":"NOM"} ,
		{"word":"foirade","word_nosc":"foirade","lemma":"foirade","pos":"NOM"} ,
		{"word":"foirades","word_nosc":"foirades","lemma":"foirade","pos":"NOM"} ,
		{"word":"foirail","word_nosc":"foirail","lemma":"foirail","pos":"NOM"} ,
		{"word":"foirails","word_nosc":"foirails","lemma":"foirail","pos":"NOM"} ,
		{"word":"foire","word_nosc":"foire","lemma":"foire","pos":"NOM"} ,
		{"word":"foires","word_nosc":"foires","lemma":"foire","pos":"NOM"} ,
		{"word":"foiridon","word_nosc":"foiridon","lemma":"foiridon","pos":"NOM"} ,
		{"word":"fois","word_nosc":"fois","lemma":"fois","pos":"NOM"} ,
		{"word":"foison","word_nosc":"foison","lemma":"foison","pos":"NOM"} ,
		{"word":"foisonnement","word_nosc":"foisonnement","lemma":"foisonnement","pos":"NOM"} ,
		{"word":"folasse","word_nosc":"folasse","lemma":"folasse","pos":"NOM"} ,
		{"word":"foldingue","word_nosc":"foldingue","lemma":"foldingue","pos":"NOM"} ,
		{"word":"foldingues","word_nosc":"foldingues","lemma":"foldingue","pos":"NOM"} ,
		{"word":"folichonneries","word_nosc":"folichonneries","lemma":"folichonnerie","pos":"NOM"} ,
		{"word":"folie","word_nosc":"folie","lemma":"folie","pos":"NOM"} ,
		{"word":"folies","word_nosc":"folies","lemma":"folie","pos":"NOM"} ,
		{"word":"folio","word_nosc":"folio","lemma":"folio","pos":"NOM"} ,
		{"word":"folioles","word_nosc":"folioles","lemma":"foliole","pos":"NOM"} ,
		{"word":"folios","word_nosc":"folios","lemma":"folio","pos":"NOM"} ,
		{"word":"folk","word_nosc":"folk","lemma":"folk","pos":"NOM"} ,
		{"word":"folklore","word_nosc":"folklore","lemma":"folklore","pos":"NOM"} ,
		{"word":"folklores","word_nosc":"folklores","lemma":"folklore","pos":"NOM"} ,
		{"word":"folkloriste","word_nosc":"folkloriste","lemma":"folkloriste","pos":"NOM"} ,
		{"word":"folkloristes","word_nosc":"folkloristes","lemma":"folkloriste","pos":"NOM"} ,
		{"word":"folks","word_nosc":"folks","lemma":"folk","pos":"NOM"} ,
		{"word":"folle","word_nosc":"folle","lemma":"fou","pos":"NOM"} ,
		{"word":"folledingue","word_nosc":"folledingue","lemma":"folledingue","pos":"NOM"} ,
		{"word":"folles","word_nosc":"folles","lemma":"fou","pos":"NOM"} ,
		{"word":"follet","word_nosc":"follet","lemma":"follet","pos":"NOM"} ,
		{"word":"folliculaires","word_nosc":"folliculaires","lemma":"folliculaire","pos":"NOM"} ,
		{"word":"follicule","word_nosc":"follicule","lemma":"follicule","pos":"NOM"} ,
		{"word":"follicules","word_nosc":"follicules","lemma":"follicule","pos":"NOM"} ,
		{"word":"folliculite","word_nosc":"folliculite","lemma":"folliculite","pos":"NOM"} ,
		{"word":"folâtreries","word_nosc":"folatreries","lemma":"folâtrerie","pos":"NOM"} ,
		{"word":"fomentateurs","word_nosc":"fomentateurs","lemma":"fomentateur","pos":"NOM"} ,
		{"word":"fonceur","word_nosc":"fonceur","lemma":"fonceur","pos":"NOM"} ,
		{"word":"fonceurs","word_nosc":"fonceurs","lemma":"fonceur","pos":"NOM"} ,
		{"word":"fonceuse","word_nosc":"fonceuse","lemma":"fonceur","pos":"NOM"} ,
		{"word":"foncier","word_nosc":"foncier","lemma":"foncier","pos":"NOM"} ,
		{"word":"foncteur","word_nosc":"foncteur","lemma":"foncteur","pos":"NOM"} ,
		{"word":"fonction","word_nosc":"fonction","lemma":"fonction","pos":"NOM"} ,
		{"word":"fonctionnaire","word_nosc":"fonctionnaire","lemma":"fonctionnaire","pos":"NOM"} ,
		{"word":"fonctionnaires","word_nosc":"fonctionnaires","lemma":"fonctionnaire","pos":"NOM"} ,
		{"word":"fonctionnalité","word_nosc":"fonctionnalite","lemma":"fonctionnalité","pos":"NOM"} ,
		{"word":"fonctionnement","word_nosc":"fonctionnement","lemma":"fonctionnement","pos":"NOM"} ,
		{"word":"fonctions","word_nosc":"fonctions","lemma":"fonction","pos":"NOM"} ,
		{"word":"fond","word_nosc":"fond","lemma":"fond","pos":"NOM"} ,
		{"word":"fondamentalisme","word_nosc":"fondamentalisme","lemma":"fondamentalisme","pos":"NOM"} ,
		{"word":"fondamentaliste","word_nosc":"fondamentaliste","lemma":"fondamentaliste","pos":"NOM"} ,
		{"word":"fondamentalistes","word_nosc":"fondamentalistes","lemma":"fondamentaliste","pos":"NOM"} ,
		{"word":"fondant","word_nosc":"fondant","lemma":"fondant","pos":"NOM"} ,
		{"word":"fondants","word_nosc":"fondants","lemma":"fondant","pos":"NOM"} ,
		{"word":"fondateur","word_nosc":"fondateur","lemma":"fondateur","pos":"NOM"} ,
		{"word":"fondateurs","word_nosc":"fondateurs","lemma":"fondateur","pos":"NOM"} ,
		{"word":"fondation","word_nosc":"fondation","lemma":"fondation","pos":"NOM"} ,
		{"word":"fondations","word_nosc":"fondations","lemma":"fondation","pos":"NOM"} ,
		{"word":"fondatrice","word_nosc":"fondatrice","lemma":"fondateur","pos":"NOM"} ,
		{"word":"fondatrices","word_nosc":"fondatrices","lemma":"fondateur","pos":"NOM"} ,
		{"word":"fondement","word_nosc":"fondement","lemma":"fondement","pos":"NOM"} ,
		{"word":"fondements","word_nosc":"fondements","lemma":"fondement","pos":"NOM"} ,
		{"word":"fonderie","word_nosc":"fonderie","lemma":"fonderie","pos":"NOM"} ,
		{"word":"fonderies","word_nosc":"fonderies","lemma":"fonderie","pos":"NOM"} ,
		{"word":"fondeur","word_nosc":"fondeur","lemma":"fondeur","pos":"NOM"} ,
		{"word":"fondouk","word_nosc":"fondouk","lemma":"fondouk","pos":"NOM"} ,
		{"word":"fondrière","word_nosc":"fondriere","lemma":"fondrière","pos":"NOM"} ,
		{"word":"fondrières","word_nosc":"fondrieres","lemma":"fondrière","pos":"NOM"} ,
		{"word":"fonds","word_nosc":"fonds","lemma":"fonds","pos":"NOM"} ,
		{"word":"fondu","word_nosc":"fondu","lemma":"fondu","pos":"NOM"} ,
		{"word":"fondue","word_nosc":"fondue","lemma":"fondue","pos":"NOM"} ,
		{"word":"fondues","word_nosc":"fondues","lemma":"fondue","pos":"NOM"} ,
		{"word":"fondus","word_nosc":"fondus","lemma":"fondu","pos":"NOM"} ,
		{"word":"fongicide","word_nosc":"fongicide","lemma":"fongicide","pos":"NOM"} ,
		{"word":"fongus","word_nosc":"fongus","lemma":"fongus","pos":"NOM"} ,
		{"word":"fontaine","word_nosc":"fontaine","lemma":"fontaine","pos":"NOM"} ,
		{"word":"fontaines","word_nosc":"fontaines","lemma":"fontaine","pos":"NOM"} ,
		{"word":"fontainette","word_nosc":"fontainette","lemma":"fontainette","pos":"NOM"} ,
		{"word":"fontainier","word_nosc":"fontainier","lemma":"fontainier","pos":"NOM"} ,
		{"word":"fontanelle","word_nosc":"fontanelle","lemma":"fontanel","pos":"NOM"} ,
		{"word":"fontanelle","word_nosc":"fontanelle","lemma":"fontanelle","pos":"NOM"} ,
		{"word":"fonte","word_nosc":"fonte","lemma":"fonte","pos":"NOM"} ,
		{"word":"fontes","word_nosc":"fontes","lemma":"fonte","pos":"NOM"} ,
		{"word":"fonts","word_nosc":"fonts","lemma":"fonts","pos":"NOM"} ,
		{"word":"foot","word_nosc":"foot","lemma":"foot","pos":"NOM"} ,
		{"word":"football","word_nosc":"football","lemma":"football","pos":"NOM"} ,
		{"word":"footballeur","word_nosc":"footballeur","lemma":"footballeur","pos":"NOM"} ,
		{"word":"footballeur-vedette","word_nosc":"footballeur-vedette","lemma":"footballeur-vedette","pos":"NOM"} ,
		{"word":"footballeurs","word_nosc":"footballeurs","lemma":"footballeur","pos":"NOM"} ,
		{"word":"footballeuse","word_nosc":"footballeuse","lemma":"footballeur","pos":"NOM"} ,
		{"word":"footeux","word_nosc":"footeux","lemma":"footeux","pos":"NOM"} ,
		{"word":"footing","word_nosc":"footing","lemma":"footing","pos":"NOM"} ,
		{"word":"for","word_nosc":"for","lemma":"for","pos":"NOM"} ,
		{"word":"forage","word_nosc":"forage","lemma":"forage","pos":"NOM"} ,
		{"word":"forages","word_nosc":"forages","lemma":"forage","pos":"NOM"} ,
		{"word":"forain","word_nosc":"forain","lemma":"forain","pos":"NOM"} ,
		{"word":"foraine","word_nosc":"foraine","lemma":"forain","pos":"NOM"} ,
		{"word":"foraines","word_nosc":"foraines","lemma":"forain","pos":"NOM"} ,
		{"word":"forains","word_nosc":"forains","lemma":"forain","pos":"NOM"} ,
		{"word":"foramen","word_nosc":"foramen","lemma":"foramen","pos":"NOM"} ,
		{"word":"foraminifères","word_nosc":"foraminiferes","lemma":"foraminifère","pos":"NOM"} ,
		{"word":"forban","word_nosc":"forban","lemma":"forban","pos":"NOM"} ,
		{"word":"forbans","word_nosc":"forbans","lemma":"forban","pos":"NOM"} ,
		{"word":"force","word_nosc":"force","lemma":"force","pos":"NOM"} ,
		{"word":"forcement","word_nosc":"forcement","lemma":"forcement","pos":"NOM"} ,
		{"word":"forcené","word_nosc":"forcene","lemma":"forcené","pos":"NOM"} ,
		{"word":"forcenée","word_nosc":"forcenee","lemma":"forcené","pos":"NOM"} ,
		{"word":"forcenées","word_nosc":"forcenees","lemma":"forcené","pos":"NOM"} ,
		{"word":"forcenés","word_nosc":"forcenes","lemma":"forcené","pos":"NOM"} ,
		{"word":"forceps","word_nosc":"forceps","lemma":"forceps","pos":"NOM"} ,
		{"word":"forces","word_nosc":"forces","lemma":"force","pos":"NOM"} ,
		{"word":"forceur","word_nosc":"forceur","lemma":"forceur","pos":"NOM"} ,
		{"word":"forceurs","word_nosc":"forceurs","lemma":"forceur","pos":"NOM"} ,
		{"word":"forcing","word_nosc":"forcing","lemma":"forcing","pos":"NOM"} ,
		{"word":"foreign office","word_nosc":"foreign office","lemma":"foreign office","pos":"NOM"} ,
		{"word":"forestier","word_nosc":"forestier","lemma":"forestier","pos":"NOM"} ,
		{"word":"forestiers","word_nosc":"forestiers","lemma":"forestier","pos":"NOM"} ,
		{"word":"foret","word_nosc":"foret","lemma":"foret","pos":"NOM"} ,
		{"word":"forets","word_nosc":"forets","lemma":"foret","pos":"NOM"} ,
		{"word":"foreur","word_nosc":"foreur","lemma":"foreur","pos":"NOM"} ,
		{"word":"foreurs","word_nosc":"foreurs","lemma":"foreur","pos":"NOM"} ,
		{"word":"foreuse","word_nosc":"foreuse","lemma":"foreur","pos":"NOM"} ,
		{"word":"foreuses","word_nosc":"foreuses","lemma":"foreur","pos":"NOM"} ,
		{"word":"forfait","word_nosc":"forfait","lemma":"forfait","pos":"NOM"} ,
		{"word":"forfaits","word_nosc":"forfaits","lemma":"forfait","pos":"NOM"} ,
		{"word":"forfaiture","word_nosc":"forfaiture","lemma":"forfaiture","pos":"NOM"} ,
		{"word":"forfanterie","word_nosc":"forfanterie","lemma":"forfanterie","pos":"NOM"} ,
		{"word":"forfanteries","word_nosc":"forfanteries","lemma":"forfanterie","pos":"NOM"} ,
		{"word":"forficules","word_nosc":"forficules","lemma":"forficule","pos":"NOM"} ,
		{"word":"forge","word_nosc":"forge","lemma":"forge","pos":"NOM"} ,
		{"word":"forgeage","word_nosc":"forgeage","lemma":"forgeage","pos":"NOM"} ,
		{"word":"forgeries","word_nosc":"forgeries","lemma":"forgerie","pos":"NOM"} ,
		{"word":"forgeron","word_nosc":"forgeron","lemma":"forgeron","pos":"NOM"} ,
		{"word":"forgeronne","word_nosc":"forgeronne","lemma":"forgeron","pos":"NOM"} ,
		{"word":"forgerons","word_nosc":"forgerons","lemma":"forgeron","pos":"NOM"} ,
		{"word":"forges","word_nosc":"forges","lemma":"forge","pos":"NOM"} ,
		{"word":"forgeur","word_nosc":"forgeur","lemma":"forgeur","pos":"NOM"} ,
		{"word":"forints","word_nosc":"forints","lemma":"forint","pos":"NOM"} ,
		{"word":"forlane","word_nosc":"forlane","lemma":"forlane","pos":"NOM"} ,
		{"word":"formage","word_nosc":"formage","lemma":"formage","pos":"NOM"} ,
		{"word":"formaldéhyde","word_nosc":"formaldehyde","lemma":"formaldéhyde","pos":"NOM"} ,
		{"word":"formalisme","word_nosc":"formalisme","lemma":"formalisme","pos":"NOM"} ,
		{"word":"formaliste","word_nosc":"formaliste","lemma":"formaliste","pos":"NOM"} ,
		{"word":"formalistes","word_nosc":"formalistes","lemma":"formaliste","pos":"NOM"} ,
		{"word":"formalité","word_nosc":"formalite","lemma":"formalité","pos":"NOM"} ,
		{"word":"formalités","word_nosc":"formalites","lemma":"formalité","pos":"NOM"} ,
		{"word":"formant","word_nosc":"formant","lemma":"formant","pos":"NOM"} ,
		{"word":"format","word_nosc":"format","lemma":"format","pos":"NOM"} ,
		{"word":"formatage","word_nosc":"formatage","lemma":"formatage","pos":"NOM"} ,
		{"word":"formateur","word_nosc":"formateur","lemma":"formateur","pos":"NOM"} ,
		{"word":"formation","word_nosc":"formation","lemma":"formation","pos":"NOM"} ,
		{"word":"formations","word_nosc":"formations","lemma":"formation","pos":"NOM"} ,
		{"word":"formatrice","word_nosc":"formatrice","lemma":"formateur","pos":"NOM"} ,
		{"word":"formats","word_nosc":"formats","lemma":"format","pos":"NOM"} ,
		{"word":"forme","word_nosc":"forme","lemma":"forme","pos":"NOM"} ,
		{"word":"formes","word_nosc":"formes","lemma":"forme","pos":"NOM"} ,
		{"word":"formica","word_nosc":"formica","lemma":"formica","pos":"NOM"} ,
		{"word":"formicas","word_nosc":"formicas","lemma":"formica","pos":"NOM"} ,
		{"word":"formol","word_nosc":"formol","lemma":"formol","pos":"NOM"} ,
		{"word":"formulaire","word_nosc":"formulaire","lemma":"formulaire","pos":"NOM"} ,
		{"word":"formulaires","word_nosc":"formulaires","lemma":"formulaire","pos":"NOM"} ,
		{"word":"formulation","word_nosc":"formulation","lemma":"formulation","pos":"NOM"} ,
		{"word":"formulations","word_nosc":"formulations","lemma":"formulation","pos":"NOM"} ,
		{"word":"formule","word_nosc":"formule","lemma":"formule","pos":"NOM"} ,
		{"word":"formules","word_nosc":"formules","lemma":"formule","pos":"NOM"} ,
		{"word":"formulettes","word_nosc":"formulettes","lemma":"formulette","pos":"NOM"} ,
		{"word":"fornicateur","word_nosc":"fornicateur","lemma":"fornicateur","pos":"NOM"} ,
		{"word":"fornicateurs","word_nosc":"fornicateurs","lemma":"fornicateur","pos":"NOM"} ,
		{"word":"fornication","word_nosc":"fornication","lemma":"fornication","pos":"NOM"} ,
		{"word":"fornications","word_nosc":"fornications","lemma":"fornication","pos":"NOM"} ,
		{"word":"fornicatrice","word_nosc":"fornicatrice","lemma":"fornicateur","pos":"NOM"} ,
		{"word":"forsythias","word_nosc":"forsythias","lemma":"forsythia","pos":"NOM"} ,
		{"word":"fort","word_nosc":"fort","lemma":"fort","pos":"NOM"} ,
		{"word":"forteresse","word_nosc":"forteresse","lemma":"forteresse","pos":"NOM"} ,
		{"word":"forteresses","word_nosc":"forteresses","lemma":"forteresse","pos":"NOM"} ,
		{"word":"fortifiant","word_nosc":"fortifiant","lemma":"fortifiant","pos":"NOM"} ,
		{"word":"fortifiants","word_nosc":"fortifiants","lemma":"fortifiant","pos":"NOM"} ,
		{"word":"fortification","word_nosc":"fortification","lemma":"fortification","pos":"NOM"} ,
		{"word":"fortifications","word_nosc":"fortifications","lemma":"fortification","pos":"NOM"} ,
		{"word":"fortifs","word_nosc":"fortifs","lemma":"fortif","pos":"NOM"} ,
		{"word":"fortin","word_nosc":"fortin","lemma":"fortin","pos":"NOM"} ,
		{"word":"fortins","word_nosc":"fortins","lemma":"fortin","pos":"NOM"} ,
		{"word":"fortissimo","word_nosc":"fortissimo","lemma":"fortissimo","pos":"NOM"} ,
		{"word":"fortitude","word_nosc":"fortitude","lemma":"fortitude","pos":"NOM"} ,
		{"word":"fortraiture","word_nosc":"fortraiture","lemma":"fortraiture","pos":"NOM"} ,
		{"word":"forts","word_nosc":"forts","lemma":"fort","pos":"NOM"} ,
		{"word":"fortune","word_nosc":"fortune","lemma":"fortune","pos":"NOM"} ,
		{"word":"fortunes","word_nosc":"fortunes","lemma":"fortune","pos":"NOM"} ,
		{"word":"forum","word_nosc":"forum","lemma":"forum","pos":"NOM"} ,
		{"word":"forums","word_nosc":"forums","lemma":"forum","pos":"NOM"} ,
		{"word":"forçage","word_nosc":"forcage","lemma":"forçage","pos":"NOM"} ,
		{"word":"forçat","word_nosc":"forcat","lemma":"forçat","pos":"NOM"} ,
		{"word":"forçats","word_nosc":"forcats","lemma":"forçat","pos":"NOM"} ,
		{"word":"forêt","word_nosc":"foret","lemma":"forêt","pos":"NOM"} ,
		{"word":"forêts","word_nosc":"forets","lemma":"forêt","pos":"NOM"} ,
		{"word":"fosse","word_nosc":"fosse","lemma":"fosse","pos":"NOM"} ,
		{"word":"fosses","word_nosc":"fosses","lemma":"fosse","pos":"NOM"} ,
		{"word":"fossette","word_nosc":"fossette","lemma":"fossette","pos":"NOM"} ,
		{"word":"fossettes","word_nosc":"fossettes","lemma":"fossette","pos":"NOM"} ,
		{"word":"fossile","word_nosc":"fossile","lemma":"fossile","pos":"NOM"} ,
		{"word":"fossiles","word_nosc":"fossiles","lemma":"fossile","pos":"NOM"} ,
		{"word":"fossilisation","word_nosc":"fossilisation","lemma":"fossilisation","pos":"NOM"} ,
		{"word":"fossoyeur","word_nosc":"fossoyeur","lemma":"fossoyeur","pos":"NOM"} ,
		{"word":"fossoyeurs","word_nosc":"fossoyeurs","lemma":"fossoyeur","pos":"NOM"} ,
		{"word":"fossoyeuse","word_nosc":"fossoyeuse","lemma":"fossoyeuse","pos":"NOM"} ,
		{"word":"fossé","word_nosc":"fosse","lemma":"fossé","pos":"NOM"} ,
		{"word":"fossés","word_nosc":"fosses","lemma":"fossé","pos":"NOM"} ,
		{"word":"fou","word_nosc":"fou","lemma":"fou","pos":"NOM"} ,
		{"word":"fou-fou","word_nosc":"fou-fou","lemma":"fou-fou","pos":"NOM"} ,
		{"word":"fou-rire","word_nosc":"fou-rire","lemma":"fou-rire","pos":"NOM"} ,
		{"word":"fouaces","word_nosc":"fouaces","lemma":"fouace","pos":"NOM"} ,
		{"word":"fouaille","word_nosc":"fouaille","lemma":"fouaille","pos":"NOM"} ,
		{"word":"foucade","word_nosc":"foucade","lemma":"foucade","pos":"NOM"} ,
		{"word":"foucades","word_nosc":"foucades","lemma":"foucade","pos":"NOM"} ,
		{"word":"foudre","word_nosc":"foudre","lemma":"foudre","pos":"NOM"} ,
		{"word":"foudres","word_nosc":"foudres","lemma":"foudre","pos":"NOM"} ,
		{"word":"foudroiement","word_nosc":"foudroiement","lemma":"foudroiement","pos":"NOM"} ,
		{"word":"fouet","word_nosc":"fouet","lemma":"fouet","pos":"NOM"} ,
		{"word":"fouets","word_nosc":"fouets","lemma":"fouet","pos":"NOM"} ,
		{"word":"fouettement","word_nosc":"fouettement","lemma":"fouettement","pos":"NOM"} ,
		{"word":"fouettements","word_nosc":"fouettements","lemma":"fouettement","pos":"NOM"} ,
		{"word":"fouetteur","word_nosc":"fouetteur","lemma":"fouetteur","pos":"NOM"} ,
		{"word":"fouetteurs","word_nosc":"fouetteurs","lemma":"fouetteur","pos":"NOM"} ,
		{"word":"fouetteuse","word_nosc":"fouetteuse","lemma":"fouetteur","pos":"NOM"} ,
		{"word":"fouetté","word_nosc":"fouette","lemma":"fouetté","pos":"NOM"} ,
		{"word":"fouettés","word_nosc":"fouettes","lemma":"fouetté","pos":"NOM"} ,
		{"word":"foufoune","word_nosc":"foufoune","lemma":"foufoune","pos":"NOM"} ,
		{"word":"foufounes","word_nosc":"foufounes","lemma":"foufoune","pos":"NOM"} ,
		{"word":"foufounette","word_nosc":"foufounette","lemma":"foufounette","pos":"NOM"} ,
		{"word":"fougasse","word_nosc":"fougasse","lemma":"fougasse","pos":"NOM"} ,
		{"word":"fougeraie","word_nosc":"fougeraie","lemma":"fougeraie","pos":"NOM"} ,
		{"word":"fougeraies","word_nosc":"fougeraies","lemma":"fougeraie","pos":"NOM"} ,
		{"word":"fougue","word_nosc":"fougue","lemma":"fougue","pos":"NOM"} ,
		{"word":"fougues","word_nosc":"fougues","lemma":"fougue","pos":"NOM"} ,
		{"word":"fougère","word_nosc":"fougere","lemma":"fougère","pos":"NOM"} ,
		{"word":"fougères","word_nosc":"fougeres","lemma":"fougère","pos":"NOM"} ,
		{"word":"fouille","word_nosc":"fouille","lemma":"fouille","pos":"NOM"} ,
		{"word":"fouilles","word_nosc":"fouilles","lemma":"fouille","pos":"NOM"} ,
		{"word":"fouillette","word_nosc":"fouillette","lemma":"fouillette","pos":"NOM"} ,
		{"word":"fouilleur","word_nosc":"fouilleur","lemma":"fouilleur","pos":"NOM"} ,
		{"word":"fouilleurs","word_nosc":"fouilleurs","lemma":"fouilleur","pos":"NOM"} ,
		{"word":"fouilleuse","word_nosc":"fouilleuse","lemma":"fouilleur","pos":"NOM"} ,
		{"word":"fouillis","word_nosc":"fouillis","lemma":"fouillis","pos":"NOM"} ,
		{"word":"fouinard","word_nosc":"fouinard","lemma":"fouinard","pos":"NOM"} ,
		{"word":"fouinards","word_nosc":"fouinards","lemma":"fouinard","pos":"NOM"} ,
		{"word":"fouine","word_nosc":"fouine","lemma":"fouine","pos":"NOM"} ,
		{"word":"fouines","word_nosc":"fouines","lemma":"fouine","pos":"NOM"} ,
		{"word":"fouineur","word_nosc":"fouineur","lemma":"fouineur","pos":"NOM"} ,
		{"word":"fouineurs","word_nosc":"fouineurs","lemma":"fouineur","pos":"NOM"} ,
		{"word":"fouineuse","word_nosc":"fouineuse","lemma":"fouineur","pos":"NOM"} ,
		{"word":"fouisseur","word_nosc":"fouisseur","lemma":"fouisseur","pos":"NOM"} ,
		{"word":"fouisseurs","word_nosc":"fouisseurs","lemma":"fouisseur","pos":"NOM"} ,
		{"word":"foulage","word_nosc":"foulage","lemma":"foulage","pos":"NOM"} ,
		{"word":"foulard","word_nosc":"foulard","lemma":"foulard","pos":"NOM"} ,
		{"word":"foulards","word_nosc":"foulards","lemma":"foulard","pos":"NOM"} ,
		{"word":"foule","word_nosc":"foule","lemma":"foule","pos":"NOM"} ,
		{"word":"foules","word_nosc":"foules","lemma":"foule","pos":"NOM"} ,
		{"word":"fouleur","word_nosc":"fouleur","lemma":"fouleur","pos":"NOM"} ,
		{"word":"fouloir","word_nosc":"fouloir","lemma":"fouloir","pos":"NOM"} ,
		{"word":"foulon","word_nosc":"foulon","lemma":"foulon","pos":"NOM"} ,
		{"word":"foulque","word_nosc":"foulque","lemma":"foulque","pos":"NOM"} ,
		{"word":"foulques","word_nosc":"foulques","lemma":"foulque","pos":"NOM"} ,
		{"word":"foulure","word_nosc":"foulure","lemma":"foulure","pos":"NOM"} ,
		{"word":"foulures","word_nosc":"foulures","lemma":"foulure","pos":"NOM"} ,
		{"word":"foulée","word_nosc":"foulee","lemma":"foulée","pos":"NOM"} ,
		{"word":"foulées","word_nosc":"foulees","lemma":"foulée","pos":"NOM"} ,
		{"word":"four","word_nosc":"four","lemma":"four","pos":"NOM"} ,
		{"word":"fourbe","word_nosc":"fourbe","lemma":"fourbe","pos":"NOM"} ,
		{"word":"fourberie","word_nosc":"fourberie","lemma":"fourberie","pos":"NOM"} ,
		{"word":"fourberies","word_nosc":"fourberies","lemma":"fourberie","pos":"NOM"} ,
		{"word":"fourbes","word_nosc":"fourbes","lemma":"fourbe","pos":"NOM"} ,
		{"word":"fourbi","word_nosc":"fourbi","lemma":"fourbi","pos":"NOM"} ,
		{"word":"fourbis","word_nosc":"fourbis","lemma":"fourbi","pos":"NOM"} ,
		{"word":"fourche","word_nosc":"fourche","lemma":"fourche","pos":"NOM"} ,
		{"word":"fourches","word_nosc":"fourches","lemma":"fourche","pos":"NOM"} ,
		{"word":"fourchet","word_nosc":"fourchet","lemma":"fourchet","pos":"NOM"} ,
		{"word":"fourchets","word_nosc":"fourchets","lemma":"fourchet","pos":"NOM"} ,
		{"word":"fourchette","word_nosc":"fourchette","lemma":"fourchette","pos":"NOM"} ,
		{"word":"fourchettes","word_nosc":"fourchettes","lemma":"fourchette","pos":"NOM"} ,
		{"word":"fourchures","word_nosc":"fourchures","lemma":"fourchure","pos":"NOM"} ,
		{"word":"fourchée","word_nosc":"fourchee","lemma":"fourchée","pos":"NOM"} ,
		{"word":"fourgon","word_nosc":"fourgon","lemma":"fourgon","pos":"NOM"} ,
		{"word":"fourgonnette","word_nosc":"fourgonnette","lemma":"fourgonnette","pos":"NOM"} ,
		{"word":"fourgonnettes","word_nosc":"fourgonnettes","lemma":"fourgonnette","pos":"NOM"} ,
		{"word":"fourgons","word_nosc":"fourgons","lemma":"fourgon","pos":"NOM"} ,
		{"word":"fourgue","word_nosc":"fourgue","lemma":"fourgue","pos":"NOM"} ,
		{"word":"fourgues","word_nosc":"fourgues","lemma":"fourgue","pos":"NOM"} ,
		{"word":"fourme","word_nosc":"fourme","lemma":"fourme","pos":"NOM"} ,
		{"word":"fourmes","word_nosc":"fourmes","lemma":"fourme","pos":"NOM"} ,
		{"word":"fourmi","word_nosc":"fourmi","lemma":"fourmi","pos":"NOM"} ,
		{"word":"fourmi-lion","word_nosc":"fourmi-lion","lemma":"fourmi-lion","pos":"NOM"} ,
		{"word":"fourmilier","word_nosc":"fourmilier","lemma":"fourmilier","pos":"NOM"} ,
		{"word":"fourmilion","word_nosc":"fourmilion","lemma":"fourmilion","pos":"NOM"} ,
		{"word":"fourmilière","word_nosc":"fourmiliere","lemma":"fourmilier","pos":"NOM"} ,
		{"word":"fourmilières","word_nosc":"fourmilieres","lemma":"fourmilière","pos":"NOM"} ,
		{"word":"fourmillement","word_nosc":"fourmillement","lemma":"fourmillement","pos":"NOM"} ,
		{"word":"fourmillements","word_nosc":"fourmillements","lemma":"fourmillement","pos":"NOM"} ,
		{"word":"fourmis","word_nosc":"fourmis","lemma":"fourmi","pos":"NOM"} ,
		{"word":"fourmis-lions","word_nosc":"fourmis-lions","lemma":"fourmi-lion","pos":"NOM"} ,
		{"word":"fournaise","word_nosc":"fournaise","lemma":"fournaise","pos":"NOM"} ,
		{"word":"fournaises","word_nosc":"fournaises","lemma":"fournaise","pos":"NOM"} ,
		{"word":"fourneau","word_nosc":"fourneau","lemma":"fourneau","pos":"NOM"} ,
		{"word":"fourneaux","word_nosc":"fourneaux","lemma":"fourneau","pos":"NOM"} ,
		{"word":"fournier","word_nosc":"fournier","lemma":"fournier","pos":"NOM"} ,
		{"word":"fournil","word_nosc":"fournil","lemma":"fournil","pos":"NOM"} ,
		{"word":"fourniment","word_nosc":"fourniment","lemma":"fourniment","pos":"NOM"} ,
		{"word":"fourniments","word_nosc":"fourniments","lemma":"fourniment","pos":"NOM"} ,
		{"word":"fournisseur","word_nosc":"fournisseur","lemma":"fournisseur","pos":"NOM"} ,
		{"word":"fournisseurs","word_nosc":"fournisseurs","lemma":"fournisseur","pos":"NOM"} ,
		{"word":"fournisseuses","word_nosc":"fournisseuses","lemma":"fournisseur","pos":"NOM"} ,
		{"word":"fourniture","word_nosc":"fourniture","lemma":"fourniture","pos":"NOM"} ,
		{"word":"fournitures","word_nosc":"fournitures","lemma":"fourniture","pos":"NOM"} ,
		{"word":"fournée","word_nosc":"fournee","lemma":"fournée","pos":"NOM"} ,
		{"word":"fournées","word_nosc":"fournees","lemma":"fournée","pos":"NOM"} ,
		{"word":"fourrage","word_nosc":"fourrage","lemma":"fourrage","pos":"NOM"} ,
		{"word":"fourragement","word_nosc":"fourragement","lemma":"fourragement","pos":"NOM"} ,
		{"word":"fourrages","word_nosc":"fourrages","lemma":"fourrage","pos":"NOM"} ,
		{"word":"fourrageurs","word_nosc":"fourrageurs","lemma":"fourrageur","pos":"NOM"} ,
		{"word":"fourragère","word_nosc":"fourragere","lemma":"fourrager","pos":"NOM"} ,
		{"word":"fourragères","word_nosc":"fourrageres","lemma":"fourrager","pos":"NOM"} ,
		{"word":"fourre","word_nosc":"fourre","lemma":"fourre","pos":"NOM"} ,
		{"word":"fourre-tout","word_nosc":"fourre-tout","lemma":"fourre-tout","pos":"NOM"} ,
		{"word":"fourreau","word_nosc":"fourreau","lemma":"fourreau","pos":"NOM"} ,
		{"word":"fourreaux","word_nosc":"fourreaux","lemma":"fourreau","pos":"NOM"} ,
		{"word":"fourreur","word_nosc":"fourreur","lemma":"fourreur","pos":"NOM"} ,
		{"word":"fourreurs","word_nosc":"fourreurs","lemma":"fourreur","pos":"NOM"} ,
		{"word":"fourrier","word_nosc":"fourrier","lemma":"fourrier","pos":"NOM"} ,
		{"word":"fourriers","word_nosc":"fourriers","lemma":"fourrier","pos":"NOM"} ,
		{"word":"fourrière","word_nosc":"fourriere","lemma":"fourrière","pos":"NOM"} ,
		{"word":"fourrure","word_nosc":"fourrure","lemma":"fourrure","pos":"NOM"} ,
		{"word":"fourrures","word_nosc":"fourrures","lemma":"fourrure","pos":"NOM"} ,
		{"word":"fourré","word_nosc":"fourre","lemma":"fourré","pos":"NOM"} ,
		{"word":"fourrés","word_nosc":"fourres","lemma":"fourré","pos":"NOM"} ,
		{"word":"fours","word_nosc":"fours","lemma":"four","pos":"NOM"} ,
		{"word":"fourvoiement","word_nosc":"fourvoiement","lemma":"fourvoiement","pos":"NOM"} ,
		{"word":"fourvoiements","word_nosc":"fourvoiements","lemma":"fourvoiement","pos":"NOM"} ,
		{"word":"fous","word_nosc":"fous","lemma":"fou","pos":"NOM"} ,
		{"word":"fouta","word_nosc":"fouta","lemma":"fouta","pos":"NOM"} ,
		{"word":"foutaise","word_nosc":"foutaise","lemma":"foutaise","pos":"NOM"} ,
		{"word":"foutaises","word_nosc":"foutaises","lemma":"foutaise","pos":"NOM"} ,
		{"word":"fouterie","word_nosc":"fouterie","lemma":"fouterie","pos":"NOM"} ,
		{"word":"fouteries","word_nosc":"fouteries","lemma":"fouterie","pos":"NOM"} ,
		{"word":"fouteur","word_nosc":"fouteur","lemma":"fouteur","pos":"NOM"} ,
		{"word":"fouteurs","word_nosc":"fouteurs","lemma":"fouteur","pos":"NOM"} ,
		{"word":"foutoir","word_nosc":"foutoir","lemma":"foutoir","pos":"NOM"} ,
		{"word":"foutoirs","word_nosc":"foutoirs","lemma":"foutoir","pos":"NOM"} ,
		{"word":"foutre","word_nosc":"foutre","lemma":"foutre","pos":"NOM"} ,
		{"word":"foutrerie","word_nosc":"foutrerie","lemma":"foutrerie","pos":"NOM"} ,
		{"word":"foutriquet","word_nosc":"foutriquet","lemma":"foutriquet","pos":"NOM"} ,
		{"word":"foutriquets","word_nosc":"foutriquets","lemma":"foutriquet","pos":"NOM"} ,
		{"word":"fox","word_nosc":"fox","lemma":"fox","pos":"NOM"} ,
		{"word":"fox-terrier","word_nosc":"fox-terrier","lemma":"fox-terrier","pos":"NOM"} ,
		{"word":"fox-terriers","word_nosc":"fox-terriers","lemma":"fox-terrier","pos":"NOM"} ,
		{"word":"fox-trot","word_nosc":"fox-trot","lemma":"fox-trot","pos":"NOM"} ,
		{"word":"foyard","word_nosc":"foyard","lemma":"foyard","pos":"NOM"} ,
		{"word":"foyards","word_nosc":"foyards","lemma":"foyard","pos":"NOM"} ,
		{"word":"foyer","word_nosc":"foyer","lemma":"foyer","pos":"NOM"} ,
		{"word":"foyers","word_nosc":"foyers","lemma":"foyer","pos":"NOM"} ,
		{"word":"foëne","word_nosc":"foene","lemma":"foëne","pos":"NOM"} ,
		{"word":"fra","word_nosc":"fra","lemma":"fra","pos":"NOM"} ,
		{"word":"frac","word_nosc":"frac","lemma":"frac","pos":"NOM"} ,
		{"word":"fracas","word_nosc":"fracas","lemma":"fracas","pos":"NOM"} ,
		{"word":"fracs","word_nosc":"fracs","lemma":"frac","pos":"NOM"} ,
		{"word":"fraction","word_nosc":"fraction","lemma":"fraction","pos":"NOM"} ,
		{"word":"fractionnement","word_nosc":"fractionnement","lemma":"fractionnement","pos":"NOM"} ,
		{"word":"fractions","word_nosc":"fractions","lemma":"fraction","pos":"NOM"} ,
		{"word":"fracture","word_nosc":"fracture","lemma":"fracture","pos":"NOM"} ,
		{"word":"fractures","word_nosc":"fractures","lemma":"fracture","pos":"NOM"} ,
		{"word":"fractus","word_nosc":"fractus","lemma":"fractus","pos":"NOM"} ,
		{"word":"fragilité","word_nosc":"fragilite","lemma":"fragilité","pos":"NOM"} ,
		{"word":"fragilités","word_nosc":"fragilites","lemma":"fragilité","pos":"NOM"} ,
		{"word":"fragment","word_nosc":"fragment","lemma":"fragment","pos":"NOM"} ,
		{"word":"fragmentation","word_nosc":"fragmentation","lemma":"fragmentation","pos":"NOM"} ,
		{"word":"fragments","word_nosc":"fragments","lemma":"fragment","pos":"NOM"} ,
		{"word":"fragrance","word_nosc":"fragrance","lemma":"fragrance","pos":"NOM"} ,
		{"word":"fragrances","word_nosc":"fragrances","lemma":"fragrance","pos":"NOM"} ,
		{"word":"frai","word_nosc":"frai","lemma":"frai","pos":"NOM"} ,
		{"word":"frairie","word_nosc":"frairie","lemma":"frairie","pos":"NOM"} ,
		{"word":"frais","word_nosc":"frais","lemma":"frais","pos":"NOM"} ,
		{"word":"fraise","word_nosc":"fraise","lemma":"fraise","pos":"NOM"} ,
		{"word":"fraises","word_nosc":"fraises","lemma":"fraise","pos":"NOM"} ,
		{"word":"fraiseur","word_nosc":"fraiseur","lemma":"fraiseur","pos":"NOM"} ,
		{"word":"fraiseur-outilleur","word_nosc":"fraiseur-outilleur","lemma":"fraiseur-outilleur","pos":"NOM"} ,
		{"word":"fraiseurs","word_nosc":"fraiseurs","lemma":"fraiseur","pos":"NOM"} ,
		{"word":"fraiseuse","word_nosc":"fraiseuse","lemma":"fraiseur","pos":"NOM"} ,
		{"word":"fraisier","word_nosc":"fraisier","lemma":"fraisier","pos":"NOM"} ,
		{"word":"fraisiers","word_nosc":"fraisiers","lemma":"fraisier","pos":"NOM"} ,
		{"word":"fraisil","word_nosc":"fraisil","lemma":"fraisil","pos":"NOM"} ,
		{"word":"framboise","word_nosc":"framboise","lemma":"framboise","pos":"NOM"} ,
		{"word":"framboises","word_nosc":"framboises","lemma":"framboise","pos":"NOM"} ,
		{"word":"framboisier","word_nosc":"framboisier","lemma":"framboisier","pos":"NOM"} ,
		{"word":"framboisiers","word_nosc":"framboisiers","lemma":"framboisier","pos":"NOM"} ,
		{"word":"franc","word_nosc":"franc","lemma":"franc","pos":"NOM"} ,
		{"word":"franc-comtois","word_nosc":"franc-comtois","lemma":"franc-comtois","pos":"NOM"} ,
		{"word":"franc-jeu","word_nosc":"franc-jeu","lemma":"franc-jeu","pos":"NOM"} ,
		{"word":"franc-maçon","word_nosc":"franc-macon","lemma":"franc-maçon","pos":"NOM"} ,
		{"word":"franc-maçonne","word_nosc":"franc-maconne","lemma":"franc-maçon","pos":"NOM"} ,
		{"word":"franc-maçonnerie","word_nosc":"franc-maconnerie","lemma":"franc-maçonnerie","pos":"NOM"} ,
		{"word":"franc-parler","word_nosc":"franc-parler","lemma":"franc-parler","pos":"NOM"} ,
		{"word":"franc-tireur","word_nosc":"franc-tireur","lemma":"franc-tireur","pos":"NOM"} ,
		{"word":"francatu","word_nosc":"francatu","lemma":"francatu","pos":"NOM"} ,
		{"word":"francfort","word_nosc":"francfort","lemma":"francfort","pos":"NOM"} ,
		{"word":"francforts","word_nosc":"francforts","lemma":"francfort","pos":"NOM"} ,
		{"word":"franchisage","word_nosc":"franchisage","lemma":"franchisage","pos":"NOM"} ,
		{"word":"franchise","word_nosc":"franchise","lemma":"franchise","pos":"NOM"} ,
		{"word":"franchises","word_nosc":"franchises","lemma":"franchise","pos":"NOM"} ,
		{"word":"franchissement","word_nosc":"franchissement","lemma":"franchissement","pos":"NOM"} ,
		{"word":"franchissements","word_nosc":"franchissements","lemma":"franchissement","pos":"NOM"} ,
		{"word":"franchouillard","word_nosc":"franchouillard","lemma":"franchouillard","pos":"NOM"} ,
		{"word":"franchouillards","word_nosc":"franchouillards","lemma":"franchouillard","pos":"NOM"} ,
		{"word":"francisation","word_nosc":"francisation","lemma":"francisation","pos":"NOM"} ,
		{"word":"franciscain","word_nosc":"franciscain","lemma":"franciscain","pos":"NOM"} ,
		{"word":"franciscains","word_nosc":"franciscains","lemma":"franciscain","pos":"NOM"} ,
		{"word":"francisque","word_nosc":"francisque","lemma":"francisque","pos":"NOM"} ,
		{"word":"francisques","word_nosc":"francisques","lemma":"francisque","pos":"NOM"} ,
		{"word":"francité","word_nosc":"francite","lemma":"francité","pos":"NOM"} ,
		{"word":"franco-anglaises","word_nosc":"franco-anglaises","lemma":"franco-anglais","pos":"NOM"} ,
		{"word":"franco-britannique","word_nosc":"franco-britannique","lemma":"franco-britannique","pos":"NOM"} ,
		{"word":"franco-canadien","word_nosc":"franco-canadien","lemma":"franco-canadien","pos":"NOM"} ,
		{"word":"franco-russe","word_nosc":"franco-russe","lemma":"franco-russe","pos":"NOM"} ,
		{"word":"franco-russes","word_nosc":"franco-russes","lemma":"franco-russe","pos":"NOM"} ,
		{"word":"francomanie","word_nosc":"francomanie","lemma":"francomanie","pos":"NOM"} ,
		{"word":"francophile","word_nosc":"francophile","lemma":"francophile","pos":"NOM"} ,
		{"word":"francophilie","word_nosc":"francophilie","lemma":"francophilie","pos":"NOM"} ,
		{"word":"francophobie","word_nosc":"francophobie","lemma":"francophobie","pos":"NOM"} ,
		{"word":"francophone","word_nosc":"francophone","lemma":"francophone","pos":"NOM"} ,
		{"word":"francophones","word_nosc":"francophones","lemma":"francophone","pos":"NOM"} ,
		{"word":"francophonie","word_nosc":"francophonie","lemma":"francophonie","pos":"NOM"} ,
		{"word":"francs","word_nosc":"francs","lemma":"franc","pos":"NOM"} ,
		{"word":"francs-bourgeois","word_nosc":"francs-bourgeois","lemma":"francs-bourgeois","pos":"NOM"} ,
		{"word":"francs-maçons","word_nosc":"francs-macons","lemma":"franc-maçon","pos":"NOM"} ,
		{"word":"francs-tireurs","word_nosc":"francs-tireurs","lemma":"franc-tireur","pos":"NOM"} ,
		{"word":"frange","word_nosc":"frange","lemma":"frange","pos":"NOM"} ,
		{"word":"franges","word_nosc":"franges","lemma":"frange","pos":"NOM"} ,
		{"word":"frangin","word_nosc":"frangin","lemma":"frangin","pos":"NOM"} ,
		{"word":"frangine","word_nosc":"frangine","lemma":"frangin","pos":"NOM"} ,
		{"word":"frangines","word_nosc":"frangines","lemma":"frangin","pos":"NOM"} ,
		{"word":"frangins","word_nosc":"frangins","lemma":"frangin","pos":"NOM"} ,
		{"word":"frangipane","word_nosc":"frangipane","lemma":"frangipane","pos":"NOM"} ,
		{"word":"frangipanes","word_nosc":"frangipanes","lemma":"frangipane","pos":"NOM"} ,
		{"word":"frangipaniers","word_nosc":"frangipaniers","lemma":"frangipanier","pos":"NOM"} ,
		{"word":"franglais","word_nosc":"franglais","lemma":"franglais","pos":"NOM"} ,
		{"word":"frankaoui","word_nosc":"frankaoui","lemma":"frankaoui","pos":"NOM"} ,
		{"word":"franklin","word_nosc":"franklin","lemma":"franklin","pos":"NOM"} ,
		{"word":"franquette","word_nosc":"franquette","lemma":"franquette","pos":"NOM"} ,
		{"word":"franquisme","word_nosc":"franquisme","lemma":"franquisme","pos":"NOM"} ,
		{"word":"franquiste","word_nosc":"franquiste","lemma":"franquiste","pos":"NOM"} ,
		{"word":"franquistes","word_nosc":"franquistes","lemma":"franquiste","pos":"NOM"} ,
		{"word":"français","word_nosc":"francais","lemma":"français","pos":"NOM"} ,
		{"word":"française","word_nosc":"francaise","lemma":"français","pos":"NOM"} ,
		{"word":"françaises","word_nosc":"francaises","lemma":"français","pos":"NOM"} ,
		{"word":"frappadingue","word_nosc":"frappadingue","lemma":"frappadingue","pos":"NOM"} ,
		{"word":"frappe","word_nosc":"frappe","lemma":"frappe","pos":"NOM"} ,
		{"word":"frappement","word_nosc":"frappement","lemma":"frappement","pos":"NOM"} ,
		{"word":"frappements","word_nosc":"frappements","lemma":"frappement","pos":"NOM"} ,
		{"word":"frappes","word_nosc":"frappes","lemma":"frappe","pos":"NOM"} ,
		{"word":"frappeur","word_nosc":"frappeur","lemma":"frappeur","pos":"NOM"} ,
		{"word":"frappeurs","word_nosc":"frappeurs","lemma":"frappeur","pos":"NOM"} ,
		{"word":"frasque","word_nosc":"frasque","lemma":"frasque","pos":"NOM"} ,
		{"word":"frasques","word_nosc":"frasques","lemma":"frasque","pos":"NOM"} ,
		{"word":"frater","word_nosc":"frater","lemma":"frater","pos":"NOM"} ,
		{"word":"fraternisation","word_nosc":"fraternisation","lemma":"fraternisation","pos":"NOM"} ,
		{"word":"fraternité","word_nosc":"fraternite","lemma":"fraternité","pos":"NOM"} ,
		{"word":"fraternités","word_nosc":"fraternites","lemma":"fraternité","pos":"NOM"} ,
		{"word":"fratricide","word_nosc":"fratricide","lemma":"fratricide","pos":"NOM"} ,
		{"word":"fratricides","word_nosc":"fratricides","lemma":"fratricide","pos":"NOM"} ,
		{"word":"fratrie","word_nosc":"fratrie","lemma":"fratrie","pos":"NOM"} ,
		{"word":"fratries","word_nosc":"fratries","lemma":"fratrie","pos":"NOM"} ,
		{"word":"fraude","word_nosc":"fraude","lemma":"fraude","pos":"NOM"} ,
		{"word":"fraudes","word_nosc":"fraudes","lemma":"fraude","pos":"NOM"} ,
		{"word":"fraudeur","word_nosc":"fraudeur","lemma":"fraudeur","pos":"NOM"} ,
		{"word":"fraudeurs","word_nosc":"fraudeurs","lemma":"fraudeur","pos":"NOM"} ,
		{"word":"fraudeuse","word_nosc":"fraudeuse","lemma":"fraudeur","pos":"NOM"} ,
		{"word":"frayeur","word_nosc":"frayeur","lemma":"frayeur","pos":"NOM"} ,
		{"word":"frayeurs","word_nosc":"frayeurs","lemma":"frayeur","pos":"NOM"} ,
		{"word":"frayée","word_nosc":"frayee","lemma":"frayée","pos":"NOM"} ,
		{"word":"fraîche","word_nosc":"fraiche","lemma":"frais","pos":"NOM"} ,
		{"word":"fraîches","word_nosc":"fraiches","lemma":"frais","pos":"NOM"} ,
		{"word":"fraîcheur","word_nosc":"fraicheur","lemma":"fraîcheur","pos":"NOM"} ,
		{"word":"fraîcheurs","word_nosc":"fraicheurs","lemma":"fraîcheur","pos":"NOM"} ,
		{"word":"freak","word_nosc":"freak","lemma":"freak","pos":"NOM"} ,
		{"word":"freaks","word_nosc":"freaks","lemma":"freak","pos":"NOM"} ,
		{"word":"fredaines","word_nosc":"fredaines","lemma":"fredaine","pos":"NOM"} ,
		{"word":"fredonnement","word_nosc":"fredonnement","lemma":"fredonnement","pos":"NOM"} ,
		{"word":"free jazz","word_nosc":"free jazz","lemma":"free jazz","pos":"NOM"} ,
		{"word":"free-jazz","word_nosc":"free-jazz","lemma":"free-jazz","pos":"NOM"} ,
		{"word":"free-lance","word_nosc":"free-lance","lemma":"free-lance","pos":"NOM"} ,
		{"word":"freelance","word_nosc":"freelance","lemma":"freelance","pos":"NOM"} ,
		{"word":"freesia","word_nosc":"freesia","lemma":"freesia","pos":"NOM"} ,
		{"word":"freesias","word_nosc":"freesias","lemma":"freesia","pos":"NOM"} ,
		{"word":"freezer","word_nosc":"freezer","lemma":"freezer","pos":"NOM"} ,
		{"word":"frein","word_nosc":"frein","lemma":"frein","pos":"NOM"} ,
		{"word":"freinage","word_nosc":"freinage","lemma":"freinage","pos":"NOM"} ,
		{"word":"freinages","word_nosc":"freinages","lemma":"freinage","pos":"NOM"} ,
		{"word":"freineurs","word_nosc":"freineurs","lemma":"freineur","pos":"NOM"} ,
		{"word":"freins","word_nosc":"freins","lemma":"frein","pos":"NOM"} ,
		{"word":"frelon","word_nosc":"frelon","lemma":"frelon","pos":"NOM"} ,
		{"word":"frelons","word_nosc":"frelons","lemma":"frelon","pos":"NOM"} ,
		{"word":"freluquet","word_nosc":"freluquet","lemma":"freluquet","pos":"NOM"} ,
		{"word":"freluquets","word_nosc":"freluquets","lemma":"freluquet","pos":"NOM"} ,
		{"word":"french","word_nosc":"french","lemma":"french","pos":"NOM"} ,
		{"word":"fresque","word_nosc":"fresque","lemma":"fresque","pos":"NOM"} ,
		{"word":"fresques","word_nosc":"fresques","lemma":"fresque","pos":"NOM"} ,
		{"word":"fresquiste","word_nosc":"fresquiste","lemma":"fresquiste","pos":"NOM"} ,
		{"word":"fret","word_nosc":"fret","lemma":"fret","pos":"NOM"} ,
		{"word":"fretin","word_nosc":"fretin","lemma":"fretin","pos":"NOM"} ,
		{"word":"fretins","word_nosc":"fretins","lemma":"fretin","pos":"NOM"} ,
		{"word":"frets","word_nosc":"frets","lemma":"fret","pos":"NOM"} ,
		{"word":"frette","word_nosc":"frette","lemma":"frette","pos":"NOM"} ,
		{"word":"freudien","word_nosc":"freudien","lemma":"freudien","pos":"NOM"} ,
		{"word":"freudiens","word_nosc":"freudiens","lemma":"freudien","pos":"NOM"} ,
		{"word":"freudisme","word_nosc":"freudisme","lemma":"freudisme","pos":"NOM"} ,
		{"word":"freux","word_nosc":"freux","lemma":"freux","pos":"NOM"} ,
		{"word":"friabilité","word_nosc":"friabilite","lemma":"friabilité","pos":"NOM"} ,
		{"word":"friand","word_nosc":"friand","lemma":"friand","pos":"NOM"} ,
		{"word":"friandise","word_nosc":"friandise","lemma":"friandise","pos":"NOM"} ,
		{"word":"friandises","word_nosc":"friandises","lemma":"friandise","pos":"NOM"} ,
		{"word":"friands","word_nosc":"friands","lemma":"friand","pos":"NOM"} ,
		{"word":"fribourgeoise","word_nosc":"fribourgeoise","lemma":"fribourgeois","pos":"NOM"} ,
		{"word":"fric","word_nosc":"fric","lemma":"fric","pos":"NOM"} ,
		{"word":"fric-frac","word_nosc":"fric-frac","lemma":"fric-frac","pos":"NOM"} ,
		{"word":"fricadelle","word_nosc":"fricadelle","lemma":"fricadelle","pos":"NOM"} ,
		{"word":"fricadelles","word_nosc":"fricadelles","lemma":"fricadelle","pos":"NOM"} ,
		{"word":"fricandeau","word_nosc":"fricandeau","lemma":"fricandeau","pos":"NOM"} ,
		{"word":"fricandeaux","word_nosc":"fricandeaux","lemma":"fricandeau","pos":"NOM"} ,
		{"word":"fricasse","word_nosc":"fricasse","lemma":"fricasse","pos":"NOM"} ,
		{"word":"fricassée","word_nosc":"fricassee","lemma":"fricassée","pos":"NOM"} ,
		{"word":"fricassées","word_nosc":"fricassees","lemma":"fricassée","pos":"NOM"} ,
		{"word":"fricatives","word_nosc":"fricatives","lemma":"fricative","pos":"NOM"} ,
		{"word":"friche","word_nosc":"friche","lemma":"friche","pos":"NOM"} ,
		{"word":"friches","word_nosc":"friches","lemma":"friche","pos":"NOM"} ,
		{"word":"frichti","word_nosc":"frichti","lemma":"frichti","pos":"NOM"} ,
		{"word":"frichtis","word_nosc":"frichtis","lemma":"frichti","pos":"NOM"} ,
		{"word":"fricot","word_nosc":"fricot","lemma":"fricot","pos":"NOM"} ,
		{"word":"fricotage","word_nosc":"fricotage","lemma":"fricotage","pos":"NOM"} ,
		{"word":"fricotages","word_nosc":"fricotages","lemma":"fricotage","pos":"NOM"} ,
		{"word":"fricoteur","word_nosc":"fricoteur","lemma":"fricoteur","pos":"NOM"} ,
		{"word":"fricoteurs","word_nosc":"fricoteurs","lemma":"fricoteur","pos":"NOM"} ,
		{"word":"fricots","word_nosc":"fricots","lemma":"fricot","pos":"NOM"} ,
		{"word":"frics","word_nosc":"frics","lemma":"fric","pos":"NOM"} ,
		{"word":"friction","word_nosc":"friction","lemma":"friction","pos":"NOM"} ,
		{"word":"frictions","word_nosc":"frictions","lemma":"friction","pos":"NOM"} ,
		{"word":"fridolin","word_nosc":"fridolin","lemma":"fridolin","pos":"NOM"} ,
		{"word":"fridolins","word_nosc":"fridolins","lemma":"fridolin","pos":"NOM"} ,
		{"word":"frigidaire","word_nosc":"frigidaire","lemma":"frigidaire","pos":"NOM"} ,
		{"word":"frigidaires","word_nosc":"frigidaires","lemma":"frigidaire","pos":"NOM"} ,
		{"word":"frigide","word_nosc":"frigide","lemma":"frigide","pos":"NOM"} ,
		{"word":"frigides","word_nosc":"frigides","lemma":"frigide","pos":"NOM"} ,
		{"word":"frigidité","word_nosc":"frigidite","lemma":"frigidité","pos":"NOM"} ,
		{"word":"frigo","word_nosc":"frigo","lemma":"frigo","pos":"NOM"} ,
		{"word":"frigorifiques","word_nosc":"frigorifiques","lemma":"frigorifique","pos":"NOM"} ,
		{"word":"frigorigène","word_nosc":"frigorigene","lemma":"frigorigène","pos":"NOM"} ,
		{"word":"frigos","word_nosc":"frigos","lemma":"frigo","pos":"NOM"} ,
		{"word":"frileuse","word_nosc":"frileuse","lemma":"frileux","pos":"NOM"} ,
		{"word":"frileux","word_nosc":"frileux","lemma":"frileux","pos":"NOM"} ,
		{"word":"frilosité","word_nosc":"frilosite","lemma":"frilosité","pos":"NOM"} ,
		{"word":"frimaire","word_nosc":"frimaire","lemma":"frimaire","pos":"NOM"} ,
		{"word":"frimas","word_nosc":"frimas","lemma":"frimas","pos":"NOM"} ,
		{"word":"frime","word_nosc":"frime","lemma":"frime","pos":"NOM"} ,
		{"word":"frimes","word_nosc":"frimes","lemma":"frime","pos":"NOM"} ,
		{"word":"frimeur","word_nosc":"frimeur","lemma":"frimeur","pos":"NOM"} ,
		{"word":"frimeurs","word_nosc":"frimeurs","lemma":"frimeur","pos":"NOM"} ,
		{"word":"frimeuse","word_nosc":"frimeuse","lemma":"frimeur","pos":"NOM"} ,
		{"word":"frimousse","word_nosc":"frimousse","lemma":"frimousse","pos":"NOM"} ,
		{"word":"frimousses","word_nosc":"frimousses","lemma":"frimousse","pos":"NOM"} ,
		{"word":"fringale","word_nosc":"fringale","lemma":"fringale","pos":"NOM"} ,
		{"word":"fringales","word_nosc":"fringales","lemma":"fringale","pos":"NOM"} ,
		{"word":"fringillidé","word_nosc":"fringillide","lemma":"fringillidé","pos":"NOM"} ,
		{"word":"fringue","word_nosc":"fringue","lemma":"fringue","pos":"NOM"} ,
		{"word":"fringues","word_nosc":"fringues","lemma":"fringue","pos":"NOM"} ,
		{"word":"fripe","word_nosc":"fripe","lemma":"fripe","pos":"NOM"} ,
		{"word":"friperie","word_nosc":"friperie","lemma":"friperie","pos":"NOM"} ,
		{"word":"fripes","word_nosc":"fripes","lemma":"fripe","pos":"NOM"} ,
		{"word":"fripier","word_nosc":"fripier","lemma":"fripier","pos":"NOM"} ,
		{"word":"fripiers","word_nosc":"fripiers","lemma":"fripier","pos":"NOM"} ,
		{"word":"fripon","word_nosc":"fripon","lemma":"fripon","pos":"NOM"} ,
		{"word":"friponne","word_nosc":"friponne","lemma":"fripon","pos":"NOM"} ,
		{"word":"friponnerie","word_nosc":"friponnerie","lemma":"friponnerie","pos":"NOM"} ,
		{"word":"friponnes","word_nosc":"friponnes","lemma":"fripon","pos":"NOM"} ,
		{"word":"fripons","word_nosc":"fripons","lemma":"fripon","pos":"NOM"} ,
		{"word":"fripouille","word_nosc":"fripouille","lemma":"fripouille","pos":"NOM"} ,
		{"word":"fripouillerie","word_nosc":"fripouillerie","lemma":"fripouillerie","pos":"NOM"} ,
		{"word":"fripouilleries","word_nosc":"fripouilleries","lemma":"fripouillerie","pos":"NOM"} ,
		{"word":"fripouilles","word_nosc":"fripouilles","lemma":"fripouille","pos":"NOM"} ,
		{"word":"frippe","word_nosc":"frippe","lemma":"frippe","pos":"NOM"} ,
		{"word":"frisbee","word_nosc":"frisbee","lemma":"frisbee","pos":"NOM"} ,
		{"word":"frise","word_nosc":"frise","lemma":"frise","pos":"NOM"} ,
		{"word":"frise-poulet","word_nosc":"frise-poulet","lemma":"frise-poulet","pos":"NOM"} ,
		{"word":"friselis","word_nosc":"friselis","lemma":"friselis","pos":"NOM"} ,
		{"word":"friselée","word_nosc":"friselee","lemma":"friselée","pos":"NOM"} ,
		{"word":"frises","word_nosc":"frises","lemma":"frise","pos":"NOM"} ,
		{"word":"frisette","word_nosc":"frisette","lemma":"frisette","pos":"NOM"} ,
		{"word":"frisettes","word_nosc":"frisettes","lemma":"frisette","pos":"NOM"} ,
		{"word":"friseur","word_nosc":"friseur","lemma":"friseur","pos":"NOM"} ,
		{"word":"frison","word_nosc":"frison","lemma":"frison","pos":"NOM"} ,
		{"word":"frisons","word_nosc":"frisons","lemma":"frison","pos":"NOM"} ,
		{"word":"frisottement","word_nosc":"frisottement","lemma":"frisottement","pos":"NOM"} ,
		{"word":"frisottis","word_nosc":"frisottis","lemma":"frisottis","pos":"NOM"} ,
		{"word":"frisous","word_nosc":"frisous","lemma":"frisou","pos":"NOM"} ,
		{"word":"frisselis","word_nosc":"frisselis","lemma":"frisselis","pos":"NOM"} ,
		{"word":"frisson","word_nosc":"frisson","lemma":"frisson","pos":"NOM"} ,
		{"word":"frissonnement","word_nosc":"frissonnement","lemma":"frissonnement","pos":"NOM"} ,
		{"word":"frissonnements","word_nosc":"frissonnements","lemma":"frissonnement","pos":"NOM"} ,
		{"word":"frissons","word_nosc":"frissons","lemma":"frisson","pos":"NOM"} ,
		{"word":"frisure","word_nosc":"frisure","lemma":"frisure","pos":"NOM"} ,
		{"word":"frisures","word_nosc":"frisures","lemma":"frisure","pos":"NOM"} ,
		{"word":"frite","word_nosc":"frite","lemma":"frite","pos":"NOM"} ,
		{"word":"friterie","word_nosc":"friterie","lemma":"friterie","pos":"NOM"} ,
		{"word":"friteries","word_nosc":"friteries","lemma":"friterie","pos":"NOM"} ,
		{"word":"frites","word_nosc":"frites","lemma":"frite","pos":"NOM"} ,
		{"word":"friteuse","word_nosc":"friteuse","lemma":"friteur","pos":"NOM"} ,
		{"word":"friteuses","word_nosc":"friteuses","lemma":"friteur","pos":"NOM"} ,
		{"word":"fritillaires","word_nosc":"fritillaires","lemma":"fritillaire","pos":"NOM"} ,
		{"word":"fritons","word_nosc":"fritons","lemma":"friton","pos":"NOM"} ,
		{"word":"fritte","word_nosc":"fritte","lemma":"fritte","pos":"NOM"} ,
		{"word":"friture","word_nosc":"friture","lemma":"friture","pos":"NOM"} ,
		{"word":"fritures","word_nosc":"fritures","lemma":"friture","pos":"NOM"} ,
		{"word":"friturier","word_nosc":"friturier","lemma":"friturier","pos":"NOM"} ,
		{"word":"fritz","word_nosc":"fritz","lemma":"fritz","pos":"NOM"} ,
		{"word":"frivolité","word_nosc":"frivolite","lemma":"frivolité","pos":"NOM"} ,
		{"word":"frivolités","word_nosc":"frivolites","lemma":"frivolité","pos":"NOM"} ,
		{"word":"froc","word_nosc":"froc","lemma":"froc","pos":"NOM"} ,
		{"word":"frocard","word_nosc":"frocard","lemma":"frocard","pos":"NOM"} ,
		{"word":"frocards","word_nosc":"frocards","lemma":"frocard","pos":"NOM"} ,
		{"word":"frocs","word_nosc":"frocs","lemma":"froc","pos":"NOM"} ,
		{"word":"froid","word_nosc":"froid","lemma":"froid","pos":"NOM"} ,
		{"word":"froideur","word_nosc":"froideur","lemma":"froideur","pos":"NOM"} ,
		{"word":"froideurs","word_nosc":"froideurs","lemma":"froideur","pos":"NOM"} ,
		{"word":"froids","word_nosc":"froids","lemma":"froid","pos":"NOM"} ,
		{"word":"froidure","word_nosc":"froidure","lemma":"froidure","pos":"NOM"} ,
		{"word":"froidures","word_nosc":"froidures","lemma":"froidure","pos":"NOM"} ,
		{"word":"froissement","word_nosc":"froissement","lemma":"froissement","pos":"NOM"} ,
		{"word":"froissements","word_nosc":"froissements","lemma":"froissement","pos":"NOM"} ,
		{"word":"fromage","word_nosc":"fromage","lemma":"fromage","pos":"NOM"} ,
		{"word":"fromager","word_nosc":"fromager","lemma":"fromager","pos":"NOM"} ,
		{"word":"fromagerie","word_nosc":"fromagerie","lemma":"fromagerie","pos":"NOM"} ,
		{"word":"fromagers","word_nosc":"fromagers","lemma":"fromager","pos":"NOM"} ,
		{"word":"fromages","word_nosc":"fromages","lemma":"fromage","pos":"NOM"} ,
		{"word":"fromegi","word_nosc":"fromegi","lemma":"fromegi","pos":"NOM"} ,
		{"word":"froment","word_nosc":"froment","lemma":"froment","pos":"NOM"} ,
		{"word":"fromental","word_nosc":"fromental","lemma":"fromental","pos":"NOM"} ,
		{"word":"frometon","word_nosc":"frometon","lemma":"frometon","pos":"NOM"} ,
		{"word":"frometons","word_nosc":"frometons","lemma":"frometon","pos":"NOM"} ,
		{"word":"fronce","word_nosc":"fronce","lemma":"fronce","pos":"NOM"} ,
		{"word":"froncement","word_nosc":"froncement","lemma":"froncement","pos":"NOM"} ,
		{"word":"froncements","word_nosc":"froncements","lemma":"froncement","pos":"NOM"} ,
		{"word":"fronces","word_nosc":"fronces","lemma":"fronce","pos":"NOM"} ,
		{"word":"frondaison","word_nosc":"frondaison","lemma":"frondaison","pos":"NOM"} ,
		{"word":"frondaisons","word_nosc":"frondaisons","lemma":"frondaison","pos":"NOM"} ,
		{"word":"fronde","word_nosc":"fronde","lemma":"fronde","pos":"NOM"} ,
		{"word":"frondes","word_nosc":"frondes","lemma":"fronde","pos":"NOM"} ,
		{"word":"frondeurs","word_nosc":"frondeurs","lemma":"frondeur","pos":"NOM"} ,
		{"word":"front","word_nosc":"front","lemma":"front","pos":"NOM"} ,
		{"word":"frontal","word_nosc":"frontal","lemma":"frontal","pos":"NOM"} ,
		{"word":"frontalier","word_nosc":"frontalier","lemma":"frontalier","pos":"NOM"} ,
		{"word":"frontaliers","word_nosc":"frontaliers","lemma":"frontalier","pos":"NOM"} ,
		{"word":"fronteau","word_nosc":"fronteau","lemma":"fronteau","pos":"NOM"} ,
		{"word":"frontispice","word_nosc":"frontispice","lemma":"frontispice","pos":"NOM"} ,
		{"word":"frontispices","word_nosc":"frontispices","lemma":"frontispice","pos":"NOM"} ,
		{"word":"frontière","word_nosc":"frontiere","lemma":"frontière","pos":"NOM"} ,
		{"word":"frontières","word_nosc":"frontieres","lemma":"frontière","pos":"NOM"} ,
		{"word":"fronton","word_nosc":"fronton","lemma":"fronton","pos":"NOM"} ,
		{"word":"frontons","word_nosc":"frontons","lemma":"fronton","pos":"NOM"} ,
		{"word":"fronts","word_nosc":"fronts","lemma":"front","pos":"NOM"} ,
		{"word":"frottage","word_nosc":"frottage","lemma":"frottage","pos":"NOM"} ,
		{"word":"frottages","word_nosc":"frottages","lemma":"frottage","pos":"NOM"} ,
		{"word":"frottement","word_nosc":"frottement","lemma":"frottement","pos":"NOM"} ,
		{"word":"frottements","word_nosc":"frottements","lemma":"frottement","pos":"NOM"} ,
		{"word":"frotteur","word_nosc":"frotteur","lemma":"frotteur","pos":"NOM"} ,
		{"word":"frotteurs","word_nosc":"frotteurs","lemma":"frotteur","pos":"NOM"} ,
		{"word":"frotteuse","word_nosc":"frotteuse","lemma":"frotteur","pos":"NOM"} ,
		{"word":"frotteuses","word_nosc":"frotteuses","lemma":"frotteur","pos":"NOM"} ,
		{"word":"frotti-frotta","word_nosc":"frotti-frotta","lemma":"frotti-frotta","pos":"NOM"} ,
		{"word":"frottin","word_nosc":"frottin","lemma":"frottin","pos":"NOM"} ,
		{"word":"frottis","word_nosc":"frottis","lemma":"frottis","pos":"NOM"} ,
		{"word":"frottoir","word_nosc":"frottoir","lemma":"frottoir","pos":"NOM"} ,
		{"word":"frottoirs","word_nosc":"frottoirs","lemma":"frottoir","pos":"NOM"} ,
		{"word":"frottons","word_nosc":"frottons","lemma":"frotton","pos":"NOM"} ,
		{"word":"frottées","word_nosc":"frottees","lemma":"frottée","pos":"NOM"} ,
		{"word":"frou-frou","word_nosc":"frou-frou","lemma":"frou-frou","pos":"NOM"} ,
		{"word":"frouement","word_nosc":"frouement","lemma":"frouement","pos":"NOM"} ,
		{"word":"frouements","word_nosc":"frouements","lemma":"frouement","pos":"NOM"} ,
		{"word":"froufrou","word_nosc":"froufrou","lemma":"froufrou","pos":"NOM"} ,
		{"word":"froufrous","word_nosc":"froufrous","lemma":"froufrou","pos":"NOM"} ,
		{"word":"froufroutement","word_nosc":"froufroutement","lemma":"froufroutement","pos":"NOM"} ,
		{"word":"frous-frous","word_nosc":"frous-frous","lemma":"frou-frou","pos":"NOM"} ,
		{"word":"froussard","word_nosc":"froussard","lemma":"froussard","pos":"NOM"} ,
		{"word":"froussarde","word_nosc":"froussarde","lemma":"froussard","pos":"NOM"} ,
		{"word":"froussards","word_nosc":"froussards","lemma":"froussard","pos":"NOM"} ,
		{"word":"frousse","word_nosc":"frousse","lemma":"frousse","pos":"NOM"} ,
		{"word":"frousses","word_nosc":"frousses","lemma":"frousse","pos":"NOM"} ,
		{"word":"fructidor","word_nosc":"fructidor","lemma":"fructidor","pos":"NOM"} ,
		{"word":"fructification","word_nosc":"fructification","lemma":"fructification","pos":"NOM"} ,
		{"word":"fructose","word_nosc":"fructose","lemma":"fructose","pos":"NOM"} ,
		{"word":"frugalité","word_nosc":"frugalite","lemma":"frugalité","pos":"NOM"} ,
		{"word":"fruit","word_nosc":"fruit","lemma":"fruit","pos":"NOM"} ,
		{"word":"fruiteries","word_nosc":"fruiteries","lemma":"fruiterie","pos":"NOM"} ,
		{"word":"fruitier","word_nosc":"fruitier","lemma":"fruitier","pos":"NOM"} ,
		{"word":"fruitiers","word_nosc":"fruitiers","lemma":"fruitier","pos":"NOM"} ,
		{"word":"fruitière","word_nosc":"fruitiere","lemma":"fruitier","pos":"NOM"} ,
		{"word":"fruits","word_nosc":"fruits","lemma":"fruit","pos":"NOM"} ,
		{"word":"frusques","word_nosc":"frusques","lemma":"frusque","pos":"NOM"} ,
		{"word":"frustration","word_nosc":"frustration","lemma":"frustration","pos":"NOM"} ,
		{"word":"frustrations","word_nosc":"frustrations","lemma":"frustration","pos":"NOM"} ,
		{"word":"frustré","word_nosc":"frustre","lemma":"frustré","pos":"NOM"} ,
		{"word":"frustrée","word_nosc":"frustree","lemma":"frustré","pos":"NOM"} ,
		{"word":"frustrées","word_nosc":"frustrees","lemma":"frustré","pos":"NOM"} ,
		{"word":"frustrés","word_nosc":"frustres","lemma":"frustré","pos":"NOM"} ,
		{"word":"frère","word_nosc":"frere","lemma":"frère","pos":"NOM"} ,
		{"word":"frères","word_nosc":"freres","lemma":"frère","pos":"NOM"} ,
		{"word":"frégate","word_nosc":"fregate","lemma":"frégate","pos":"NOM"} ,
		{"word":"frégates","word_nosc":"fregates","lemma":"frégate","pos":"NOM"} ,
		{"word":"frémissement","word_nosc":"fremissement","lemma":"frémissement","pos":"NOM"} ,
		{"word":"frémissements","word_nosc":"fremissements","lemma":"frémissement","pos":"NOM"} ,
		{"word":"frénésie","word_nosc":"frenesie","lemma":"frénésie","pos":"NOM"} ,
		{"word":"frénésies","word_nosc":"frenesies","lemma":"frénésie","pos":"NOM"} ,
		{"word":"fréon","word_nosc":"freon","lemma":"fréon","pos":"NOM"} ,
		{"word":"fréquence","word_nosc":"frequence","lemma":"fréquence","pos":"NOM"} ,
		{"word":"fréquences","word_nosc":"frequences","lemma":"fréquence","pos":"NOM"} ,
		{"word":"fréquentation","word_nosc":"frequentation","lemma":"fréquentation","pos":"NOM"} ,
		{"word":"fréquentations","word_nosc":"frequentations","lemma":"fréquentation","pos":"NOM"} ,
		{"word":"frérot","word_nosc":"frerot","lemma":"frérot","pos":"NOM"} ,
		{"word":"frérots","word_nosc":"frerots","lemma":"frérot","pos":"NOM"} ,
		{"word":"frétillement","word_nosc":"fretillement","lemma":"frétillement","pos":"NOM"} ,
		{"word":"frétillements","word_nosc":"fretillements","lemma":"frétillement","pos":"NOM"} ,
		{"word":"frêne","word_nosc":"frene","lemma":"frêne","pos":"NOM"} ,
		{"word":"frênes","word_nosc":"frenes","lemma":"frêne","pos":"NOM"} ,
		{"word":"frôlement","word_nosc":"frolement","lemma":"frôlement","pos":"NOM"} ,
		{"word":"frôlements","word_nosc":"frolements","lemma":"frôlement","pos":"NOM"} ,
		{"word":"frôleurs","word_nosc":"froleurs","lemma":"frôleur","pos":"NOM"} ,
		{"word":"fuchsia","word_nosc":"fuchsia","lemma":"fuchsia","pos":"NOM"} ,
		{"word":"fuchsias","word_nosc":"fuchsias","lemma":"fuchsia","pos":"NOM"} ,
		{"word":"fuel","word_nosc":"fuel","lemma":"fuel","pos":"NOM"} ,
		{"word":"fuel-oil","word_nosc":"fuel-oil","lemma":"fuel-oil","pos":"NOM"} ,
		{"word":"fugacité","word_nosc":"fugacite","lemma":"fugacité","pos":"NOM"} ,
		{"word":"fugitif","word_nosc":"fugitif","lemma":"fugitif","pos":"NOM"} ,
		{"word":"fugitifs","word_nosc":"fugitifs","lemma":"fugitif","pos":"NOM"} ,
		{"word":"fugitive","word_nosc":"fugitive","lemma":"fugitif","pos":"NOM"} ,
		{"word":"fugitives","word_nosc":"fugitives","lemma":"fugitif","pos":"NOM"} ,
		{"word":"fugu","word_nosc":"fugu","lemma":"fugu","pos":"NOM"} ,
		{"word":"fugue","word_nosc":"fugue","lemma":"fugue","pos":"NOM"} ,
		{"word":"fugues","word_nosc":"fugues","lemma":"fugue","pos":"NOM"} ,
		{"word":"fugueur","word_nosc":"fugueur","lemma":"fugueur","pos":"NOM"} ,
		{"word":"fugueurs","word_nosc":"fugueurs","lemma":"fugueur","pos":"NOM"} ,
		{"word":"fugueuse","word_nosc":"fugueuse","lemma":"fugueur","pos":"NOM"} ,
		{"word":"fugueuses","word_nosc":"fugueuses","lemma":"fugueur","pos":"NOM"} ,
		{"word":"fuie","word_nosc":"fuie","lemma":"fuie","pos":"NOM"} ,
		{"word":"fuite","word_nosc":"fuite","lemma":"fuite","pos":"NOM"} ,
		{"word":"fuites","word_nosc":"fuites","lemma":"fuite","pos":"NOM"} ,
		{"word":"fulgores","word_nosc":"fulgores","lemma":"fulgore","pos":"NOM"} ,
		{"word":"fulgurance","word_nosc":"fulgurance","lemma":"fulgurance","pos":"NOM"} ,
		{"word":"fulgurances","word_nosc":"fulgurances","lemma":"fulgurance","pos":"NOM"} ,
		{"word":"fulguration","word_nosc":"fulguration","lemma":"fulguration","pos":"NOM"} ,
		{"word":"fulgurations","word_nosc":"fulgurations","lemma":"fulguration","pos":"NOM"} ,
		{"word":"fuligule","word_nosc":"fuligule","lemma":"fuligule","pos":"NOM"} ,
		{"word":"full","word_nosc":"full","lemma":"full","pos":"NOM"} ,
		{"word":"full-contact","word_nosc":"full-contact","lemma":"full-contact","pos":"NOM"} ,
		{"word":"fullerène","word_nosc":"fullerene","lemma":"fullerène","pos":"NOM"} ,
		{"word":"fulls","word_nosc":"fulls","lemma":"full","pos":"NOM"} ,
		{"word":"fulmicoton","word_nosc":"fulmicoton","lemma":"fulmicoton","pos":"NOM"} ,
		{"word":"fulminate","word_nosc":"fulminate","lemma":"fulminate","pos":"NOM"} ,
		{"word":"fulmination","word_nosc":"fulmination","lemma":"fulmination","pos":"NOM"} ,
		{"word":"fulminations","word_nosc":"fulminations","lemma":"fulmination","pos":"NOM"} ,
		{"word":"fulminement","word_nosc":"fulminement","lemma":"fulminement","pos":"NOM"} ,
		{"word":"fumaga","word_nosc":"fumaga","lemma":"fumaga","pos":"NOM"} ,
		{"word":"fumage","word_nosc":"fumage","lemma":"fumage","pos":"NOM"} ,
		{"word":"fumaison","word_nosc":"fumaison","lemma":"fumaison","pos":"NOM"} ,
		{"word":"fume-cigarette","word_nosc":"fume-cigarette","lemma":"fume-cigarette","pos":"NOM"} ,
		{"word":"fume-cigarettes","word_nosc":"fume-cigarettes","lemma":"fume-cigarette","pos":"NOM"} ,
		{"word":"fumerie","word_nosc":"fumerie","lemma":"fumerie","pos":"NOM"} ,
		{"word":"fumeries","word_nosc":"fumeries","lemma":"fumerie","pos":"NOM"} ,
		{"word":"fumerolles","word_nosc":"fumerolles","lemma":"fumerolle","pos":"NOM"} ,
		{"word":"fumeron","word_nosc":"fumeron","lemma":"fumeron","pos":"NOM"} ,
		{"word":"fumerons","word_nosc":"fumerons","lemma":"fumeron","pos":"NOM"} ,
		{"word":"fumet","word_nosc":"fumet","lemma":"fumet","pos":"NOM"} ,
		{"word":"fumets","word_nosc":"fumets","lemma":"fumet","pos":"NOM"} ,
		{"word":"fumette","word_nosc":"fumette","lemma":"fumette","pos":"NOM"} ,
		{"word":"fumeur","word_nosc":"fumeur","lemma":"fumeur","pos":"NOM"} ,
		{"word":"fumeurs","word_nosc":"fumeurs","lemma":"fumeur","pos":"NOM"} ,
		{"word":"fumeuse","word_nosc":"fumeuse","lemma":"fumeur","pos":"NOM"} ,
		{"word":"fumeuses","word_nosc":"fumeuses","lemma":"fumeur","pos":"NOM"} ,
		{"word":"fumier","word_nosc":"fumier","lemma":"fumier","pos":"NOM"} ,
		{"word":"fumiers","word_nosc":"fumiers","lemma":"fumier","pos":"NOM"} ,
		{"word":"fumigateur","word_nosc":"fumigateur","lemma":"fumigateur","pos":"NOM"} ,
		{"word":"fumigation","word_nosc":"fumigation","lemma":"fumigation","pos":"NOM"} ,
		{"word":"fumigations","word_nosc":"fumigations","lemma":"fumigation","pos":"NOM"} ,
		{"word":"fumiste","word_nosc":"fumiste","lemma":"fumiste","pos":"NOM"} ,
		{"word":"fumisterie","word_nosc":"fumisterie","lemma":"fumisterie","pos":"NOM"} ,
		{"word":"fumisteries","word_nosc":"fumisteries","lemma":"fumisterie","pos":"NOM"} ,
		{"word":"fumistes","word_nosc":"fumistes","lemma":"fumiste","pos":"NOM"} ,
		{"word":"fumière","word_nosc":"fumiere","lemma":"fumier","pos":"NOM"} ,
		{"word":"fumoir","word_nosc":"fumoir","lemma":"fumoir","pos":"NOM"} ,
		{"word":"fumure","word_nosc":"fumure","lemma":"fumure","pos":"NOM"} ,
		{"word":"fumée","word_nosc":"fumee","lemma":"fumée","pos":"NOM"} ,
		{"word":"fumées","word_nosc":"fumees","lemma":"fumée","pos":"NOM"} ,
		{"word":"fun","word_nosc":"fun","lemma":"fun","pos":"NOM"} ,
		{"word":"funambule","word_nosc":"funambule","lemma":"funambule","pos":"NOM"} ,
		{"word":"funambules","word_nosc":"funambules","lemma":"funambule","pos":"NOM"} ,
		{"word":"funiculaire","word_nosc":"funiculaire","lemma":"funiculaire","pos":"NOM"} ,
		{"word":"funiculaires","word_nosc":"funiculaires","lemma":"funiculaire","pos":"NOM"} ,
		{"word":"funérailles","word_nosc":"funerailles","lemma":"funérailles","pos":"NOM"} ,
		{"word":"funérarium","word_nosc":"funerarium","lemma":"funérarium","pos":"NOM"} ,
		{"word":"funérariums","word_nosc":"funerariums","lemma":"funérarium","pos":"NOM"} ,
		{"word":"fur et à mesure","word_nosc":"fur et a mesure","lemma":"fur et à mesure","pos":"NOM"} ,
		{"word":"furet","word_nosc":"furet","lemma":"furet","pos":"NOM"} ,
		{"word":"furetage","word_nosc":"furetage","lemma":"furetage","pos":"NOM"} ,
		{"word":"fureteurs","word_nosc":"fureteurs","lemma":"fureteur","pos":"NOM"} ,
		{"word":"furets","word_nosc":"furets","lemma":"furet","pos":"NOM"} ,
		{"word":"furette","word_nosc":"furette","lemma":"furette","pos":"NOM"} ,
		{"word":"fureur","word_nosc":"fureur","lemma":"fureur","pos":"NOM"} ,
		{"word":"fureurs","word_nosc":"fureurs","lemma":"fureur","pos":"NOM"} ,
		{"word":"furia","word_nosc":"furia","lemma":"furia","pos":"NOM"} ,
		{"word":"furie","word_nosc":"furie","lemma":"furie","pos":"NOM"} ,
		{"word":"furies","word_nosc":"furies","lemma":"furie","pos":"NOM"} ,
		{"word":"furieux","word_nosc":"furieux","lemma":"furieux","pos":"NOM"} ,
		{"word":"furoncle","word_nosc":"furoncle","lemma":"furoncle","pos":"NOM"} ,
		{"word":"furoncles","word_nosc":"furoncles","lemma":"furoncle","pos":"NOM"} ,
		{"word":"furonculose","word_nosc":"furonculose","lemma":"furonculose","pos":"NOM"} ,
		{"word":"furonculoses","word_nosc":"furonculoses","lemma":"furonculose","pos":"NOM"} ,
		{"word":"furtivité","word_nosc":"furtivite","lemma":"furtivité","pos":"NOM"} ,
		{"word":"fusain","word_nosc":"fusain","lemma":"fusain","pos":"NOM"} ,
		{"word":"fusains","word_nosc":"fusains","lemma":"fusain","pos":"NOM"} ,
		{"word":"fusant","word_nosc":"fusant","lemma":"fusant","pos":"NOM"} ,
		{"word":"fusants","word_nosc":"fusants","lemma":"fusant","pos":"NOM"} ,
		{"word":"fuseau","word_nosc":"fuseau","lemma":"fuseau","pos":"NOM"} ,
		{"word":"fuseaux","word_nosc":"fuseaux","lemma":"fuseau","pos":"NOM"} ,
		{"word":"fuselage","word_nosc":"fuselage","lemma":"fuselage","pos":"NOM"} ,
		{"word":"fuselages","word_nosc":"fuselages","lemma":"fuselage","pos":"NOM"} ,
		{"word":"fusible","word_nosc":"fusible","lemma":"fusible","pos":"NOM"} ,
		{"word":"fusibles","word_nosc":"fusibles","lemma":"fusible","pos":"NOM"} ,
		{"word":"fusil","word_nosc":"fusil","lemma":"fusil","pos":"NOM"} ,
		{"word":"fusil-mitrailleur","word_nosc":"fusil-mitrailleur","lemma":"fusil-mitrailleur","pos":"NOM"} ,
		{"word":"fusilier","word_nosc":"fusilier","lemma":"fusilier","pos":"NOM"} ,
		{"word":"fusiliers","word_nosc":"fusiliers","lemma":"fusilier","pos":"NOM"} ,
		{"word":"fusiliers-marins","word_nosc":"fusiliers-marins","lemma":"fusilier-marin","pos":"NOM"} ,
		{"word":"fusillade","word_nosc":"fusillade","lemma":"fusillade","pos":"NOM"} ,
		{"word":"fusillades","word_nosc":"fusillades","lemma":"fusillade","pos":"NOM"} ,
		{"word":"fusilleurs","word_nosc":"fusilleurs","lemma":"fusilleur","pos":"NOM"} ,
		{"word":"fusils","word_nosc":"fusils","lemma":"fusil","pos":"NOM"} ,
		{"word":"fusils-mitrailleurs","word_nosc":"fusils-mitrailleurs","lemma":"fusil-mitrailleur","pos":"NOM"} ,
		{"word":"fusion","word_nosc":"fusion","lemma":"fusion","pos":"NOM"} ,
		{"word":"fusionnement","word_nosc":"fusionnement","lemma":"fusionnement","pos":"NOM"} ,
		{"word":"fusions","word_nosc":"fusions","lemma":"fusion","pos":"NOM"} ,
		{"word":"fustanelle","word_nosc":"fustanelle","lemma":"fustanelle","pos":"NOM"} ,
		{"word":"fustigation","word_nosc":"fustigation","lemma":"fustigation","pos":"NOM"} ,
		{"word":"fustigations","word_nosc":"fustigations","lemma":"fustigation","pos":"NOM"} ,
		{"word":"fusée","word_nosc":"fusee","lemma":"fusée","pos":"NOM"} ,
		{"word":"fusées","word_nosc":"fusees","lemma":"fusée","pos":"NOM"} ,
		{"word":"futaie","word_nosc":"futaie","lemma":"futaie","pos":"NOM"} ,
		{"word":"futaies","word_nosc":"futaies","lemma":"futaie","pos":"NOM"} ,
		{"word":"futaille","word_nosc":"futaille","lemma":"futaille","pos":"NOM"} ,
		{"word":"futailles","word_nosc":"futailles","lemma":"futaille","pos":"NOM"} ,
		{"word":"futaine","word_nosc":"futaine","lemma":"futaine","pos":"NOM"} ,
		{"word":"futal","word_nosc":"futal","lemma":"futal","pos":"NOM"} ,
		{"word":"futals","word_nosc":"futals","lemma":"futal","pos":"NOM"} ,
		{"word":"futilité","word_nosc":"futilite","lemma":"futilité","pos":"NOM"} ,
		{"word":"futilités","word_nosc":"futilites","lemma":"futilité","pos":"NOM"} ,
		{"word":"futon","word_nosc":"futon","lemma":"futon","pos":"NOM"} ,
		{"word":"futons","word_nosc":"futons","lemma":"futon","pos":"NOM"} ,
		{"word":"futur","word_nosc":"futur","lemma":"futur","pos":"NOM"} ,
		{"word":"futurisme","word_nosc":"futurisme","lemma":"futurisme","pos":"NOM"} ,
		{"word":"futuriste","word_nosc":"futuriste","lemma":"futuriste","pos":"NOM"} ,
		{"word":"futuristes","word_nosc":"futuristes","lemma":"futuriste","pos":"NOM"} ,
		{"word":"futurologue","word_nosc":"futurologue","lemma":"futurologue","pos":"NOM"} ,
		{"word":"futurs","word_nosc":"futurs","lemma":"futur","pos":"NOM"} ,
		{"word":"futé","word_nosc":"fute","lemma":"futé","pos":"NOM"} ,
		{"word":"futée","word_nosc":"futee","lemma":"futé","pos":"NOM"} ,
		{"word":"futés","word_nosc":"futes","lemma":"futé","pos":"NOM"} ,
		{"word":"fuyant","word_nosc":"fuyant","lemma":"fuyant","pos":"NOM"} ,
		{"word":"fuyants","word_nosc":"fuyants","lemma":"fuyant","pos":"NOM"} ,
		{"word":"fuyard","word_nosc":"fuyard","lemma":"fuyard","pos":"NOM"} ,
		{"word":"fuyarde","word_nosc":"fuyarde","lemma":"fuyard","pos":"NOM"} ,
		{"word":"fuyards","word_nosc":"fuyards","lemma":"fuyard","pos":"NOM"} ,
		{"word":"fuégien","word_nosc":"fuegien","lemma":"fuégien","pos":"NOM"} ,
		{"word":"fy","word_nosc":"fy","lemma":"fy","pos":"NOM"} ,
		{"word":"fâcherie","word_nosc":"facherie","lemma":"fâcherie","pos":"NOM"} ,
		{"word":"fâcheries","word_nosc":"facheries","lemma":"fâcherie","pos":"NOM"} ,
		{"word":"fâcheuse","word_nosc":"facheuse","lemma":"fâcheux","pos":"NOM"} ,
		{"word":"fâcheux","word_nosc":"facheux","lemma":"fâcheux","pos":"NOM"} ,
		{"word":"fèces","word_nosc":"feces","lemma":"fèces","pos":"NOM"} ,
		{"word":"fère","word_nosc":"fere","lemma":"fer","pos":"NOM"} ,
		{"word":"fève","word_nosc":"feve","lemma":"fève","pos":"NOM"} ,
		{"word":"fèves","word_nosc":"feves","lemma":"fève","pos":"NOM"} ,
		{"word":"fébrilité","word_nosc":"febrilite","lemma":"fébrilité","pos":"NOM"} ,
		{"word":"fécalome","word_nosc":"fecalome","lemma":"fécalome","pos":"NOM"} ,
		{"word":"fécondateur","word_nosc":"fecondateur","lemma":"fécondateur","pos":"NOM"} ,
		{"word":"fécondation","word_nosc":"fecondation","lemma":"fécondation","pos":"NOM"} ,
		{"word":"fécondations","word_nosc":"fecondations","lemma":"fécondation","pos":"NOM"} ,
		{"word":"fécondité","word_nosc":"fecondite","lemma":"fécondité","pos":"NOM"} ,
		{"word":"fécule","word_nosc":"fecule","lemma":"fécule","pos":"NOM"} ,
		{"word":"féculent","word_nosc":"feculent","lemma":"féculent","pos":"NOM"} ,
		{"word":"féculents","word_nosc":"feculents","lemma":"féculent","pos":"NOM"} ,
		{"word":"fédéral","word_nosc":"federal","lemma":"fédéral","pos":"NOM"} ,
		{"word":"fédéralisme","word_nosc":"federalisme","lemma":"fédéralisme","pos":"NOM"} ,
		{"word":"fédérateur","word_nosc":"federateur","lemma":"fédérateur","pos":"NOM"} ,
		{"word":"fédération","word_nosc":"federation","lemma":"fédération","pos":"NOM"} ,
		{"word":"fédérations","word_nosc":"federations","lemma":"fédération","pos":"NOM"} ,
		{"word":"fédératrice","word_nosc":"federatrice","lemma":"fédérateur","pos":"NOM"} ,
		{"word":"fédéraux","word_nosc":"federaux","lemma":"fédéral","pos":"NOM"} ,
		{"word":"fédéré","word_nosc":"federe","lemma":"fédéré","pos":"NOM"} ,
		{"word":"fédérés","word_nosc":"federes","lemma":"fédéré","pos":"NOM"} ,
		{"word":"fée","word_nosc":"fee","lemma":"fée","pos":"NOM"} ,
		{"word":"féerie","word_nosc":"feerie","lemma":"féerie","pos":"NOM"} ,
		{"word":"féeries","word_nosc":"feeries","lemma":"féerie","pos":"NOM"} ,
		{"word":"fées","word_nosc":"fees","lemma":"fée","pos":"NOM"} ,
		{"word":"félibre","word_nosc":"felibre","lemma":"félibre","pos":"NOM"} ,
		{"word":"félibres","word_nosc":"felibres","lemma":"félibre","pos":"NOM"} ,
		{"word":"félibrige","word_nosc":"felibrige","lemma":"félibrige","pos":"NOM"} ,
		{"word":"félicitation","word_nosc":"felicitation","lemma":"félicitation","pos":"NOM"} ,
		{"word":"félicitations","word_nosc":"felicitations","lemma":"félicitation","pos":"NOM"} ,
		{"word":"félicité","word_nosc":"felicite","lemma":"félicité","pos":"NOM"} ,
		{"word":"félicités","word_nosc":"felicites","lemma":"félicité","pos":"NOM"} ,
		{"word":"félidé","word_nosc":"felide","lemma":"félidé","pos":"NOM"} ,
		{"word":"félin","word_nosc":"felin","lemma":"félin","pos":"NOM"} ,
		{"word":"félinité","word_nosc":"felinite","lemma":"félinité","pos":"NOM"} ,
		{"word":"félins","word_nosc":"felins","lemma":"félin","pos":"NOM"} ,
		{"word":"félon","word_nosc":"felon","lemma":"félon","pos":"NOM"} ,
		{"word":"félonie","word_nosc":"felonie","lemma":"félonie","pos":"NOM"} ,
		{"word":"félons","word_nosc":"felons","lemma":"félon","pos":"NOM"} ,
		{"word":"féminin","word_nosc":"feminin","lemma":"féminin","pos":"NOM"} ,
		{"word":"féminins","word_nosc":"feminins","lemma":"féminin","pos":"NOM"} ,
		{"word":"féminisation","word_nosc":"feminisation","lemma":"féminisation","pos":"NOM"} ,
		{"word":"féminisme","word_nosc":"feminisme","lemma":"féminisme","pos":"NOM"} ,
		{"word":"féministe","word_nosc":"feministe","lemma":"féministe","pos":"NOM"} ,
		{"word":"féministes","word_nosc":"feministes","lemma":"féministe","pos":"NOM"} ,
		{"word":"féminitude","word_nosc":"feminitude","lemma":"féminitude","pos":"NOM"} ,
		{"word":"féminité","word_nosc":"feminite","lemma":"féminité","pos":"NOM"} ,
		{"word":"féminités","word_nosc":"feminites","lemma":"féminité","pos":"NOM"} ,
		{"word":"fémur","word_nosc":"femur","lemma":"fémur","pos":"NOM"} ,
		{"word":"fémurs","word_nosc":"femurs","lemma":"fémur","pos":"NOM"} ,
		{"word":"féodal","word_nosc":"feodal","lemma":"féodal","pos":"NOM"} ,
		{"word":"féodalisme","word_nosc":"feodalisme","lemma":"féodalisme","pos":"NOM"} ,
		{"word":"féodalité","word_nosc":"feodalite","lemma":"féodalité","pos":"NOM"} ,
		{"word":"féra","word_nosc":"fera","lemma":"féra","pos":"NOM"} ,
		{"word":"féras","word_nosc":"feras","lemma":"féra","pos":"NOM"} ,
		{"word":"féria","word_nosc":"feria","lemma":"féria","pos":"NOM"} ,
		{"word":"férias","word_nosc":"ferias","lemma":"féria","pos":"NOM"} ,
		{"word":"férocité","word_nosc":"ferocite","lemma":"férocité","pos":"NOM"} ,
		{"word":"férocités","word_nosc":"ferocites","lemma":"férocité","pos":"NOM"} ,
		{"word":"férule","word_nosc":"ferule","lemma":"férule","pos":"NOM"} ,
		{"word":"fétiche","word_nosc":"fetiche","lemma":"fétiche","pos":"NOM"} ,
		{"word":"fétiches","word_nosc":"fetiches","lemma":"fétiche","pos":"NOM"} ,
		{"word":"féticheur","word_nosc":"feticheur","lemma":"féticheur","pos":"NOM"} ,
		{"word":"féticheurs","word_nosc":"feticheurs","lemma":"féticheur","pos":"NOM"} ,
		{"word":"fétichisations","word_nosc":"fetichisations","lemma":"fétichisation","pos":"NOM"} ,
		{"word":"fétichisme","word_nosc":"fetichisme","lemma":"fétichisme","pos":"NOM"} ,
		{"word":"fétichismes","word_nosc":"fetichismes","lemma":"fétichisme","pos":"NOM"} ,
		{"word":"fétichiste","word_nosc":"fetichiste","lemma":"fétichiste","pos":"NOM"} ,
		{"word":"fétichistes","word_nosc":"fetichistes","lemma":"fétichiste","pos":"NOM"} ,
		{"word":"fétidité","word_nosc":"fetidite","lemma":"fétidité","pos":"NOM"} ,
		{"word":"fétu","word_nosc":"fetu","lemma":"fétu","pos":"NOM"} ,
		{"word":"fétuque","word_nosc":"fetuque","lemma":"fétuque","pos":"NOM"} ,
		{"word":"fétus","word_nosc":"fetus","lemma":"fétu","pos":"NOM"} ,
		{"word":"féveroles","word_nosc":"feveroles","lemma":"féverole","pos":"NOM"} ,
		{"word":"février","word_nosc":"fevrier","lemma":"février","pos":"NOM"} ,
		{"word":"fêle","word_nosc":"fele","lemma":"fêle","pos":"NOM"} ,
		{"word":"fêlure","word_nosc":"felure","lemma":"fêlure","pos":"NOM"} ,
		{"word":"fêlures","word_nosc":"felures","lemma":"fêlure","pos":"NOM"} ,
		{"word":"fêlé","word_nosc":"fele","lemma":"fêlé","pos":"NOM"} ,
		{"word":"fêlée","word_nosc":"felee","lemma":"fêlé","pos":"NOM"} ,
		{"word":"fêlées","word_nosc":"felees","lemma":"fêlé","pos":"NOM"} ,
		{"word":"fêlés","word_nosc":"feles","lemma":"fêlé","pos":"NOM"} ,
		{"word":"fêtard","word_nosc":"fetard","lemma":"fêtard","pos":"NOM"} ,
		{"word":"fêtards","word_nosc":"fetards","lemma":"fêtard","pos":"NOM"} ,
		{"word":"fête","word_nosc":"fete","lemma":"fête","pos":"NOM"} ,
		{"word":"fête-dieu","word_nosc":"fete-dieu","lemma":"fête-dieu","pos":"NOM"} ,
		{"word":"fêtes","word_nosc":"fetes","lemma":"fête","pos":"NOM"} ,
		{"word":"fût","word_nosc":"fut","lemma":"fût","pos":"NOM"} ,
		{"word":"fûts","word_nosc":"futs","lemma":"fût","pos":"NOM"} ,
		{"word":"führer","word_nosc":"fuhrer","lemma":"führer","pos":"NOM"} ,
		{"word":"führers","word_nosc":"fuhrers","lemma":"führer","pos":"NOM"} ,
		{"word":"g","word_nosc":"g","lemma":"g","pos":"NOM"} ,
		{"word":"gaba","word_nosc":"gaba","lemma":"gaba","pos":"NOM"} ,
		{"word":"gabardine","word_nosc":"gabardine","lemma":"gabardine","pos":"NOM"} ,
		{"word":"gabardines","word_nosc":"gabardines","lemma":"gabardine","pos":"NOM"} ,
		{"word":"gabare","word_nosc":"gabare","lemma":"gabare","pos":"NOM"} ,
		{"word":"gabariers","word_nosc":"gabariers","lemma":"gabarier","pos":"NOM"} ,
		{"word":"gabarit","word_nosc":"gabarit","lemma":"gabarit","pos":"NOM"} ,
		{"word":"gabarits","word_nosc":"gabarits","lemma":"gabarit","pos":"NOM"} ,
		{"word":"gabarres","word_nosc":"gabarres","lemma":"gabarre","pos":"NOM"} ,
		{"word":"gabbro","word_nosc":"gabbro","lemma":"gabbro","pos":"NOM"} ,
		{"word":"gabegie","word_nosc":"gabegie","lemma":"gabegie","pos":"NOM"} ,
		{"word":"gabelle","word_nosc":"gabelle","lemma":"gabelle","pos":"NOM"} ,
		{"word":"gabelou","word_nosc":"gabelou","lemma":"gabelou","pos":"NOM"} ,
		{"word":"gabier","word_nosc":"gabier","lemma":"gabier","pos":"NOM"} ,
		{"word":"gabiers","word_nosc":"gabiers","lemma":"gabier","pos":"NOM"} ,
		{"word":"gabion","word_nosc":"gabion","lemma":"gabion","pos":"NOM"} ,
		{"word":"gabions","word_nosc":"gabions","lemma":"gabion","pos":"NOM"} ,
		{"word":"gable","word_nosc":"gable","lemma":"gable","pos":"NOM"} ,
		{"word":"gables","word_nosc":"gables","lemma":"gable","pos":"NOM"} ,
		{"word":"gabonais","word_nosc":"gabonais","lemma":"gabonais","pos":"NOM"} ,
		{"word":"gade","word_nosc":"gade","lemma":"gade","pos":"NOM"} ,
		{"word":"gadget","word_nosc":"gadget","lemma":"gadget","pos":"NOM"} ,
		{"word":"gadgets","word_nosc":"gadgets","lemma":"gadget","pos":"NOM"} ,
		{"word":"gadin","word_nosc":"gadin","lemma":"gadin","pos":"NOM"} ,
		{"word":"gadins","word_nosc":"gadins","lemma":"gadin","pos":"NOM"} ,
		{"word":"gadjo","word_nosc":"gadjo","lemma":"gadjo","pos":"NOM"} ,
		{"word":"gadolinium","word_nosc":"gadolinium","lemma":"gadolinium","pos":"NOM"} ,
		{"word":"gadoue","word_nosc":"gadoue","lemma":"gadoue","pos":"NOM"} ,
		{"word":"gadoues","word_nosc":"gadoues","lemma":"gadoue","pos":"NOM"} ,
		{"word":"gadouille","word_nosc":"gadouille","lemma":"gadouille","pos":"NOM"} ,
		{"word":"gaffe","word_nosc":"gaffe","lemma":"gaffe","pos":"NOM"} ,
		{"word":"gaffes","word_nosc":"gaffes","lemma":"gaffe","pos":"NOM"} ,
		{"word":"gaffeur","word_nosc":"gaffeur","lemma":"gaffeur","pos":"NOM"} ,
		{"word":"gaffeurs","word_nosc":"gaffeurs","lemma":"gaffeur","pos":"NOM"} ,
		{"word":"gaffeuse","word_nosc":"gaffeuse","lemma":"gaffeur","pos":"NOM"} ,
		{"word":"gag","word_nosc":"gag","lemma":"gag","pos":"NOM"} ,
		{"word":"gaga","word_nosc":"gaga","lemma":"gaga","pos":"NOM"} ,
		{"word":"gagas","word_nosc":"gagas","lemma":"gaga","pos":"NOM"} ,
		{"word":"gage","word_nosc":"gage","lemma":"gage","pos":"NOM"} ,
		{"word":"gages","word_nosc":"gages","lemma":"gage","pos":"NOM"} ,
		{"word":"gageure","word_nosc":"gageure","lemma":"gageure","pos":"NOM"} ,
		{"word":"gageures","word_nosc":"gageures","lemma":"gageure","pos":"NOM"} ,
		{"word":"gagnage","word_nosc":"gagnage","lemma":"gagnage","pos":"NOM"} ,
		{"word":"gagnages","word_nosc":"gagnages","lemma":"gagnage","pos":"NOM"} ,
		{"word":"gagnant","word_nosc":"gagnant","lemma":"gagnant","pos":"NOM"} ,
		{"word":"gagnante","word_nosc":"gagnante","lemma":"gagnant","pos":"NOM"} ,
		{"word":"gagnantes","word_nosc":"gagnantes","lemma":"gagnant","pos":"NOM"} ,
		{"word":"gagnants","word_nosc":"gagnants","lemma":"gagnant","pos":"NOM"} ,
		{"word":"gagne-pain","word_nosc":"gagne-pain","lemma":"gagne-pain","pos":"NOM"} ,
		{"word":"gagne-petit","word_nosc":"gagne-petit","lemma":"gagne-petit","pos":"NOM"} ,
		{"word":"gagneur","word_nosc":"gagneur","lemma":"gagneur","pos":"NOM"} ,
		{"word":"gagneurs","word_nosc":"gagneurs","lemma":"gagneur","pos":"NOM"} ,
		{"word":"gagneuse","word_nosc":"gagneuse","lemma":"gagneur","pos":"NOM"} ,
		{"word":"gagneuses","word_nosc":"gagneuses","lemma":"gagneur","pos":"NOM"} ,
		{"word":"gags","word_nosc":"gags","lemma":"gag","pos":"NOM"} ,
		{"word":"gaieté","word_nosc":"gaiete","lemma":"gaieté","pos":"NOM"} ,
		{"word":"gaietés","word_nosc":"gaietes","lemma":"gaieté","pos":"NOM"} ,
		{"word":"gail","word_nosc":"gail","lemma":"gail","pos":"NOM"} ,
		{"word":"gaillard","word_nosc":"gaillard","lemma":"gaillard","pos":"NOM"} ,
		{"word":"gaillarde","word_nosc":"gaillarde","lemma":"gaillard","pos":"NOM"} ,
		{"word":"gaillardes","word_nosc":"gaillardes","lemma":"gaillard","pos":"NOM"} ,
		{"word":"gaillardise","word_nosc":"gaillardise","lemma":"gaillardise","pos":"NOM"} ,
		{"word":"gaillards","word_nosc":"gaillards","lemma":"gaillard","pos":"NOM"} ,
		{"word":"gaillet","word_nosc":"gaillet","lemma":"gaillet","pos":"NOM"} ,
		{"word":"gaillette","word_nosc":"gaillette","lemma":"gaillette","pos":"NOM"} ,
		{"word":"gaillettes","word_nosc":"gaillettes","lemma":"gaillette","pos":"NOM"} ,
		{"word":"gain","word_nosc":"gain","lemma":"gain","pos":"NOM"} ,
		{"word":"gaine","word_nosc":"gaine","lemma":"gaine","pos":"NOM"} ,
		{"word":"gaine-culotte","word_nosc":"gaine-culotte","lemma":"gaine-culotte","pos":"NOM"} ,
		{"word":"gaines","word_nosc":"gaines","lemma":"gaine","pos":"NOM"} ,
		{"word":"gainiers","word_nosc":"gainiers","lemma":"gainier","pos":"NOM"} ,
		{"word":"gains","word_nosc":"gains","lemma":"gain","pos":"NOM"} ,
		{"word":"gal","word_nosc":"gal","lemma":"gal","pos":"NOM"} ,
		{"word":"gala","word_nosc":"gala","lemma":"gala","pos":"NOM"} ,
		{"word":"galago","word_nosc":"galago","lemma":"galago","pos":"NOM"} ,
		{"word":"galalithe","word_nosc":"galalithe","lemma":"galalithe","pos":"NOM"} ,
		{"word":"galant","word_nosc":"galant","lemma":"galant","pos":"NOM"} ,
		{"word":"galante","word_nosc":"galante","lemma":"galant","pos":"NOM"} ,
		{"word":"galanterie","word_nosc":"galanterie","lemma":"galanterie","pos":"NOM"} ,
		{"word":"galanteries","word_nosc":"galanteries","lemma":"galanterie","pos":"NOM"} ,
		{"word":"galantes","word_nosc":"galantes","lemma":"galant","pos":"NOM"} ,
		{"word":"galantine","word_nosc":"galantine","lemma":"galantine","pos":"NOM"} ,
		{"word":"galantines","word_nosc":"galantines","lemma":"galantine","pos":"NOM"} ,
		{"word":"galantins","word_nosc":"galantins","lemma":"galantin","pos":"NOM"} ,
		{"word":"galants","word_nosc":"galants","lemma":"galant","pos":"NOM"} ,
		{"word":"galapiat","word_nosc":"galapiat","lemma":"galapiat","pos":"NOM"} ,
		{"word":"galapiats","word_nosc":"galapiats","lemma":"galapiat","pos":"NOM"} ,
		{"word":"galas","word_nosc":"galas","lemma":"gala","pos":"NOM"} ,
		{"word":"galate","word_nosc":"galate","lemma":"galate","pos":"NOM"} ,
		{"word":"galates","word_nosc":"galates","lemma":"galate","pos":"NOM"} ,
		{"word":"galathée","word_nosc":"galathee","lemma":"galathée","pos":"NOM"} ,
		{"word":"galaxie","word_nosc":"galaxie","lemma":"galaxie","pos":"NOM"} ,
		{"word":"galaxies","word_nosc":"galaxies","lemma":"galaxie","pos":"NOM"} ,
		{"word":"galbe","word_nosc":"galbe","lemma":"galbe","pos":"NOM"} ,
		{"word":"galbes","word_nosc":"galbes","lemma":"galbe","pos":"NOM"} ,
		{"word":"gale","word_nosc":"gale","lemma":"gale","pos":"NOM"} ,
		{"word":"galerie","word_nosc":"galerie","lemma":"galerie","pos":"NOM"} ,
		{"word":"galerie-refuge","word_nosc":"galerie-refuge","lemma":"galerie-refuge","pos":"NOM"} ,
		{"word":"galeries","word_nosc":"galeries","lemma":"galerie","pos":"NOM"} ,
		{"word":"galeries-refuges","word_nosc":"galeries-refuges","lemma":"galerie-refuge","pos":"NOM"} ,
		{"word":"galeriste","word_nosc":"galeriste","lemma":"galeriste","pos":"NOM"} ,
		{"word":"galernes","word_nosc":"galernes","lemma":"galerne","pos":"NOM"} ,
		{"word":"gales","word_nosc":"gales","lemma":"gale","pos":"NOM"} ,
		{"word":"galet","word_nosc":"galet","lemma":"galet","pos":"NOM"} ,
		{"word":"galetas","word_nosc":"galetas","lemma":"galetas","pos":"NOM"} ,
		{"word":"galetouse","word_nosc":"galetouse","lemma":"galetouse","pos":"NOM"} ,
		{"word":"galets","word_nosc":"galets","lemma":"galet","pos":"NOM"} ,
		{"word":"galette","word_nosc":"galette","lemma":"galette","pos":"NOM"} ,
		{"word":"galettes","word_nosc":"galettes","lemma":"galette","pos":"NOM"} ,
		{"word":"galeuse","word_nosc":"galeuse","lemma":"galeux","pos":"NOM"} ,
		{"word":"galeuses","word_nosc":"galeuses","lemma":"galeux","pos":"NOM"} ,
		{"word":"galeux","word_nosc":"galeux","lemma":"galeux","pos":"NOM"} ,
		{"word":"galibot","word_nosc":"galibot","lemma":"galibot","pos":"NOM"} ,
		{"word":"galibots","word_nosc":"galibots","lemma":"galibot","pos":"NOM"} ,
		{"word":"galicien","word_nosc":"galicien","lemma":"galicien","pos":"NOM"} ,
		{"word":"galiciens","word_nosc":"galiciens","lemma":"galicien","pos":"NOM"} ,
		{"word":"galiléen","word_nosc":"galileen","lemma":"galiléen","pos":"NOM"} ,
		{"word":"galimatias","word_nosc":"galimatias","lemma":"galimatias","pos":"NOM"} ,
		{"word":"galion","word_nosc":"galion","lemma":"galion","pos":"NOM"} ,
		{"word":"galions","word_nosc":"galions","lemma":"galion","pos":"NOM"} ,
		{"word":"galiote","word_nosc":"galiote","lemma":"galiote","pos":"NOM"} ,
		{"word":"galiotes","word_nosc":"galiotes","lemma":"galiote","pos":"NOM"} ,
		{"word":"galipette","word_nosc":"galipette","lemma":"galipette","pos":"NOM"} ,
		{"word":"galipettes","word_nosc":"galipettes","lemma":"galipette","pos":"NOM"} ,
		{"word":"galle","word_nosc":"galle","lemma":"galle","pos":"NOM"} ,
		{"word":"galles","word_nosc":"galles","lemma":"galle","pos":"NOM"} ,
		{"word":"gallicanisme","word_nosc":"gallicanisme","lemma":"gallicanisme","pos":"NOM"} ,
		{"word":"gallinacé","word_nosc":"gallinace","lemma":"gallinacé","pos":"NOM"} ,
		{"word":"gallium","word_nosc":"gallium","lemma":"gallium","pos":"NOM"} ,
		{"word":"gallo","word_nosc":"gallo","lemma":"gallo","pos":"NOM"} ,
		{"word":"gallo-romain","word_nosc":"gallo-romain","lemma":"gallo-romain","pos":"NOM"} ,
		{"word":"gallois","word_nosc":"gallois","lemma":"gallois","pos":"NOM"} ,
		{"word":"galloise","word_nosc":"galloise","lemma":"galloise","pos":"NOM"} ,
		{"word":"gallon","word_nosc":"gallon","lemma":"gallon","pos":"NOM"} ,
		{"word":"gallons","word_nosc":"gallons","lemma":"gallon","pos":"NOM"} ,
		{"word":"gallos","word_nosc":"gallos","lemma":"gallo","pos":"NOM"} ,
		{"word":"galoche","word_nosc":"galoche","lemma":"galoche","pos":"NOM"} ,
		{"word":"galoches","word_nosc":"galoches","lemma":"galoche","pos":"NOM"} ,
		{"word":"galon","word_nosc":"galon","lemma":"galon","pos":"NOM"} ,
		{"word":"galonnage","word_nosc":"galonnage","lemma":"galonnage","pos":"NOM"} ,
		{"word":"galons","word_nosc":"galons","lemma":"galon","pos":"NOM"} ,
		{"word":"galop","word_nosc":"galop","lemma":"galop","pos":"NOM"} ,
		{"word":"galopade","word_nosc":"galopade","lemma":"galopade","pos":"NOM"} ,
		{"word":"galopades","word_nosc":"galopades","lemma":"galopade","pos":"NOM"} ,
		{"word":"galope","word_nosc":"galope","lemma":"galope","pos":"NOM"} ,
		{"word":"galopin","word_nosc":"galopin","lemma":"galopin","pos":"NOM"} ,
		{"word":"galopine","word_nosc":"galopine","lemma":"galopin","pos":"NOM"} ,
		{"word":"galopines","word_nosc":"galopines","lemma":"galopin","pos":"NOM"} ,
		{"word":"galopins","word_nosc":"galopins","lemma":"galopin","pos":"NOM"} ,
		{"word":"galops","word_nosc":"galops","lemma":"galop","pos":"NOM"} ,
		{"word":"galoubet","word_nosc":"galoubet","lemma":"galoubet","pos":"NOM"} ,
		{"word":"galoup","word_nosc":"galoup","lemma":"galoup","pos":"NOM"} ,
		{"word":"galtouse","word_nosc":"galtouse","lemma":"galtouse","pos":"NOM"} ,
		{"word":"galtouses","word_nosc":"galtouses","lemma":"galtouse","pos":"NOM"} ,
		{"word":"galuchat","word_nosc":"galuchat","lemma":"galuchat","pos":"NOM"} ,
		{"word":"galure","word_nosc":"galure","lemma":"galure","pos":"NOM"} ,
		{"word":"galures","word_nosc":"galures","lemma":"galure","pos":"NOM"} ,
		{"word":"galurin","word_nosc":"galurin","lemma":"galurin","pos":"NOM"} ,
		{"word":"galvanisme","word_nosc":"galvanisme","lemma":"galvanisme","pos":"NOM"} ,
		{"word":"galvaudeux","word_nosc":"galvaudeux","lemma":"galvaudeux","pos":"NOM"} ,
		{"word":"galène","word_nosc":"galene","lemma":"galène","pos":"NOM"} ,
		{"word":"galère","word_nosc":"galere","lemma":"galer","pos":"NOM"} ,
		{"word":"galères","word_nosc":"galeres","lemma":"galer","pos":"NOM"} ,
		{"word":"galères","word_nosc":"galeres","lemma":"galère","pos":"NOM"} ,
		{"word":"galéasses","word_nosc":"galeasses","lemma":"galéasse","pos":"NOM"} ,
		{"word":"galée","word_nosc":"galee","lemma":"galée","pos":"NOM"} ,
		{"word":"galées","word_nosc":"galees","lemma":"galée","pos":"NOM"} ,
		{"word":"galéjade","word_nosc":"galejade","lemma":"galéjade","pos":"NOM"} ,
		{"word":"galéjades","word_nosc":"galejades","lemma":"galéjade","pos":"NOM"} ,
		{"word":"galéniste","word_nosc":"galeniste","lemma":"galéniste","pos":"NOM"} ,
		{"word":"galérien","word_nosc":"galerien","lemma":"galérien","pos":"NOM"} ,
		{"word":"galériens","word_nosc":"galeriens","lemma":"galérien","pos":"NOM"} ,
		{"word":"gamay","word_nosc":"gamay","lemma":"gamay","pos":"NOM"} ,
		{"word":"gambade","word_nosc":"gambade","lemma":"gambade","pos":"NOM"} ,
		{"word":"gambades","word_nosc":"gambades","lemma":"gambade","pos":"NOM"} ,
		{"word":"gambadeurs","word_nosc":"gambadeurs","lemma":"gambadeur","pos":"NOM"} ,
		{"word":"gambas","word_nosc":"gambas","lemma":"gamba","pos":"NOM"} ,
		{"word":"gambe","word_nosc":"gambe","lemma":"gambe","pos":"NOM"} ,
		{"word":"gamberge","word_nosc":"gamberge","lemma":"gamberge","pos":"NOM"} ,
		{"word":"gamberges","word_nosc":"gamberges","lemma":"gamberge","pos":"NOM"} ,
		{"word":"gambette","word_nosc":"gambette","lemma":"gambette","pos":"NOM"} ,
		{"word":"gambettes","word_nosc":"gambettes","lemma":"gambette","pos":"NOM"} ,
		{"word":"gambilleurs","word_nosc":"gambilleurs","lemma":"gambilleur","pos":"NOM"} ,
		{"word":"gambilleuses","word_nosc":"gambilleuses","lemma":"gambilleur","pos":"NOM"} ,
		{"word":"gambit","word_nosc":"gambit","lemma":"gambit","pos":"NOM"} ,
		{"word":"game","word_nosc":"game","lemma":"game","pos":"NOM"} ,
		{"word":"gamelan","word_nosc":"gamelan","lemma":"gamelan","pos":"NOM"} ,
		{"word":"gamelle","word_nosc":"gamelle","lemma":"gamelle","pos":"NOM"} ,
		{"word":"gamelles","word_nosc":"gamelles","lemma":"gamelle","pos":"NOM"} ,
		{"word":"gamin","word_nosc":"gamin","lemma":"gamin","pos":"NOM"} ,
		{"word":"gamine","word_nosc":"gamine","lemma":"gamin","pos":"NOM"} ,
		{"word":"gaminement","word_nosc":"gaminement","lemma":"gaminement","pos":"NOM"} ,
		{"word":"gaminerie","word_nosc":"gaminerie","lemma":"gaminerie","pos":"NOM"} ,
		{"word":"gamineries","word_nosc":"gamineries","lemma":"gaminerie","pos":"NOM"} ,
		{"word":"gamines","word_nosc":"gamines","lemma":"gamin","pos":"NOM"} ,
		{"word":"gamins","word_nosc":"gamins","lemma":"gamin","pos":"NOM"} ,
		{"word":"gamma","word_nosc":"gamma","lemma":"gamma","pos":"NOM"} ,
		{"word":"gammaglobuline","word_nosc":"gammaglobuline","lemma":"gammaglobuline","pos":"NOM"} ,
		{"word":"gammaglobulines","word_nosc":"gammaglobulines","lemma":"gammaglobuline","pos":"NOM"} ,
		{"word":"gammas","word_nosc":"gammas","lemma":"gammas","pos":"NOM"} ,
		{"word":"gamme","word_nosc":"gamme","lemma":"gamme","pos":"NOM"} ,
		{"word":"gammes","word_nosc":"gammes","lemma":"gamme","pos":"NOM"} ,
		{"word":"gamète","word_nosc":"gamete","lemma":"gamète","pos":"NOM"} ,
		{"word":"gamètes","word_nosc":"gametes","lemma":"gamète","pos":"NOM"} ,
		{"word":"gan","word_nosc":"gan","lemma":"gan","pos":"NOM"} ,
		{"word":"ganache","word_nosc":"ganache","lemma":"ganache","pos":"NOM"} ,
		{"word":"ganaderia","word_nosc":"ganaderia","lemma":"ganaderia","pos":"NOM"} ,
		{"word":"ganaderias","word_nosc":"ganaderias","lemma":"ganaderia","pos":"NOM"} ,
		{"word":"gandin","word_nosc":"gandin","lemma":"gandin","pos":"NOM"} ,
		{"word":"gandins","word_nosc":"gandins","lemma":"gandin","pos":"NOM"} ,
		{"word":"gandoura","word_nosc":"gandoura","lemma":"gandoura","pos":"NOM"} ,
		{"word":"gandourah","word_nosc":"gandourah","lemma":"gandourah","pos":"NOM"} ,
		{"word":"gandouras","word_nosc":"gandouras","lemma":"gandoura","pos":"NOM"} ,
		{"word":"gang","word_nosc":"gang","lemma":"gang","pos":"NOM"} ,
		{"word":"ganglion","word_nosc":"ganglion","lemma":"ganglion","pos":"NOM"} ,
		{"word":"ganglions","word_nosc":"ganglions","lemma":"ganglion","pos":"NOM"} ,
		{"word":"gangrène","word_nosc":"gangrene","lemma":"gangrène","pos":"NOM"} ,
		{"word":"gangrènes","word_nosc":"gangrenes","lemma":"gangrène","pos":"NOM"} ,
		{"word":"gangs","word_nosc":"gangs","lemma":"gang","pos":"NOM"} ,
		{"word":"gangster","word_nosc":"gangster","lemma":"gangster","pos":"NOM"} ,
		{"word":"gangsters","word_nosc":"gangsters","lemma":"gangster","pos":"NOM"} ,
		{"word":"gangstérisme","word_nosc":"gangsterisme","lemma":"gangstérisme","pos":"NOM"} ,
		{"word":"gangue","word_nosc":"gangue","lemma":"gangue","pos":"NOM"} ,
		{"word":"gangues","word_nosc":"gangues","lemma":"gangue","pos":"NOM"} ,
		{"word":"ganja","word_nosc":"ganja","lemma":"ganja","pos":"NOM"} ,
		{"word":"ganse","word_nosc":"ganse","lemma":"ganse","pos":"NOM"} ,
		{"word":"ganses","word_nosc":"ganses","lemma":"ganse","pos":"NOM"} ,
		{"word":"gant","word_nosc":"gant","lemma":"gant","pos":"NOM"} ,
		{"word":"gantelet","word_nosc":"gantelet","lemma":"gantelet","pos":"NOM"} ,
		{"word":"gantelets","word_nosc":"gantelets","lemma":"gantelet","pos":"NOM"} ,
		{"word":"ganterie","word_nosc":"ganterie","lemma":"ganterie","pos":"NOM"} ,
		{"word":"gantier","word_nosc":"gantier","lemma":"gantier","pos":"NOM"} ,
		{"word":"gantières","word_nosc":"gantieres","lemma":"gantier","pos":"NOM"} ,
		{"word":"gants","word_nosc":"gants","lemma":"gant","pos":"NOM"} ,
		{"word":"ganymèdes","word_nosc":"ganymedes","lemma":"ganymède","pos":"NOM"} ,
		{"word":"gap","word_nosc":"gap","lemma":"gap","pos":"NOM"} ,
		{"word":"gaperon","word_nosc":"gaperon","lemma":"gaperon","pos":"NOM"} ,
		{"word":"gapette","word_nosc":"gapette","lemma":"gapette","pos":"NOM"} ,
		{"word":"gapettes","word_nosc":"gapettes","lemma":"gapette","pos":"NOM"} ,
		{"word":"garage","word_nosc":"garage","lemma":"garage","pos":"NOM"} ,
		{"word":"garages","word_nosc":"garages","lemma":"garage","pos":"NOM"} ,
		{"word":"garagiste","word_nosc":"garagiste","lemma":"garagiste","pos":"NOM"} ,
		{"word":"garagistes","word_nosc":"garagistes","lemma":"garagiste","pos":"NOM"} ,
		{"word":"garance","word_nosc":"garance","lemma":"garance","pos":"NOM"} ,
		{"word":"garancière","word_nosc":"garanciere","lemma":"garancière","pos":"NOM"} ,
		{"word":"garant","word_nosc":"garant","lemma":"garant","pos":"NOM"} ,
		{"word":"garante","word_nosc":"garante","lemma":"garant","pos":"NOM"} ,
		{"word":"garantie","word_nosc":"garantie","lemma":"garantie","pos":"NOM"} ,
		{"word":"garanties","word_nosc":"garanties","lemma":"garantie","pos":"NOM"} ,
		{"word":"garants","word_nosc":"garants","lemma":"garant","pos":"NOM"} ,
		{"word":"garbure","word_nosc":"garbure","lemma":"garbure","pos":"NOM"} ,
		{"word":"garce","word_nosc":"garce","lemma":"garce","pos":"NOM"} ,
		{"word":"garces","word_nosc":"garces","lemma":"garce","pos":"NOM"} ,
		{"word":"garcette","word_nosc":"garcette","lemma":"garcette","pos":"NOM"} ,
		{"word":"garcettes","word_nosc":"garcettes","lemma":"garcette","pos":"NOM"} ,
		{"word":"garde","word_nosc":"garde","lemma":"garde","pos":"NOM"} ,
		{"word":"garde-barrière","word_nosc":"garde-barriere","lemma":"garde-barrière","pos":"NOM"} ,
		{"word":"garde-boue","word_nosc":"garde-boue","lemma":"garde-boue","pos":"NOM"} ,
		{"word":"garde-champêtre","word_nosc":"garde-champetre","lemma":"garde-champêtre","pos":"NOM"} ,
		{"word":"garde-chasse","word_nosc":"garde-chasse","lemma":"garde-chasse","pos":"NOM"} ,
		{"word":"garde-chiourme","word_nosc":"garde-chiourme","lemma":"garde-chiourme","pos":"NOM"} ,
		{"word":"garde-corps","word_nosc":"garde-corps","lemma":"garde-corps","pos":"NOM"} ,
		{"word":"garde-côte","word_nosc":"garde-cote","lemma":"garde-côte","pos":"NOM"} ,
		{"word":"garde-côtes","word_nosc":"garde-cotes","lemma":"garde-côte","pos":"NOM"} ,
		{"word":"garde-feu","word_nosc":"garde-feu","lemma":"garde-feu","pos":"NOM"} ,
		{"word":"garde-forestier","word_nosc":"garde-forestier","lemma":"garde-forestier","pos":"NOM"} ,
		{"word":"garde-fou","word_nosc":"garde-fou","lemma":"garde-fou","pos":"NOM"} ,
		{"word":"garde-fous","word_nosc":"garde-fous","lemma":"garde-fou","pos":"NOM"} ,
		{"word":"garde-frontière","word_nosc":"garde-frontiere","lemma":"garde-frontière","pos":"NOM"} ,
		{"word":"garde-malade","word_nosc":"garde-malade","lemma":"garde-malade","pos":"NOM"} ,
		{"word":"garde-manger","word_nosc":"garde-manger","lemma":"garde-manger","pos":"NOM"} ,
		{"word":"garde-meuble","word_nosc":"garde-meuble","lemma":"garde-meuble","pos":"NOM"} ,
		{"word":"garde-meubles","word_nosc":"garde-meubles","lemma":"garde-meubles","pos":"NOM"} ,
		{"word":"garde-pêche","word_nosc":"garde-peche","lemma":"garde-pêche","pos":"NOM"} ,
		{"word":"garde-robe","word_nosc":"garde-robe","lemma":"garde-robe","pos":"NOM"} ,
		{"word":"garde-robes","word_nosc":"garde-robes","lemma":"garde-robe","pos":"NOM"} ,
		{"word":"garde-voie","word_nosc":"garde-voie","lemma":"garde-voie","pos":"NOM"} ,
		{"word":"garde-vue","word_nosc":"garde-vue","lemma":"garde-vue","pos":"NOM"} ,
		{"word":"garde-à-vous","word_nosc":"garde-a-vous","lemma":"garde-à-vous","pos":"NOM"} ,
		{"word":"garden-party","word_nosc":"garden-party","lemma":"garden-party","pos":"NOM"} ,
		{"word":"garderie","word_nosc":"garderie","lemma":"garderie","pos":"NOM"} ,
		{"word":"garderies","word_nosc":"garderies","lemma":"garderie","pos":"NOM"} ,
		{"word":"gardes","word_nosc":"gardes","lemma":"garde","pos":"NOM"} ,
		{"word":"gardes-barrières","word_nosc":"gardes-barrieres","lemma":"garde-barrières","pos":"NOM"} ,
		{"word":"gardes-champêtres","word_nosc":"gardes-champetres","lemma":"garde-champêtre","pos":"NOM"} ,
		{"word":"gardes-chasse","word_nosc":"gardes-chasse","lemma":"gardes-chasse","pos":"NOM"} ,
		{"word":"gardes-chiourme","word_nosc":"gardes-chiourme","lemma":"garde-chiourme","pos":"NOM"} ,
		{"word":"gardes-chiourmes","word_nosc":"gardes-chiourmes","lemma":"garde-chiourme","pos":"NOM"} ,
		{"word":"gardes-côtes","word_nosc":"gardes-cotes","lemma":"garde-côtes","pos":"NOM"} ,
		{"word":"gardes-françaises","word_nosc":"gardes-francaises","lemma":"garde-française","pos":"NOM"} ,
		{"word":"gardes-frontières","word_nosc":"gardes-frontieres","lemma":"garde-frontières","pos":"NOM"} ,
		{"word":"gardes-voies","word_nosc":"gardes-voies","lemma":"garde-voie","pos":"NOM"} ,
		{"word":"gardeur","word_nosc":"gardeur","lemma":"gardeur","pos":"NOM"} ,
		{"word":"gardeurs","word_nosc":"gardeurs","lemma":"gardeur","pos":"NOM"} ,
		{"word":"gardeuse","word_nosc":"gardeuse","lemma":"gardeur","pos":"NOM"} ,
		{"word":"gardeuses","word_nosc":"gardeuses","lemma":"gardeur","pos":"NOM"} ,
		{"word":"gardian","word_nosc":"gardian","lemma":"gardian","pos":"NOM"} ,
		{"word":"gardians","word_nosc":"gardians","lemma":"gardian","pos":"NOM"} ,
		{"word":"gardien","word_nosc":"gardien","lemma":"gardien","pos":"NOM"} ,
		{"word":"gardien-chef","word_nosc":"gardien-chef","lemma":"gardien-chef","pos":"NOM"} ,
		{"word":"gardiennage","word_nosc":"gardiennage","lemma":"gardiennage","pos":"NOM"} ,
		{"word":"gardienne","word_nosc":"gardienne","lemma":"gardien","pos":"NOM"} ,
		{"word":"gardiennes","word_nosc":"gardiennes","lemma":"gardien","pos":"NOM"} ,
		{"word":"gardiens","word_nosc":"gardiens","lemma":"gardien","pos":"NOM"} ,
		{"word":"gardon","word_nosc":"gardon","lemma":"gardon","pos":"NOM"} ,
		{"word":"gardons","word_nosc":"gardons","lemma":"gardon","pos":"NOM"} ,
		{"word":"gardénal","word_nosc":"gardenal","lemma":"gardénal","pos":"NOM"} ,
		{"word":"gardénia","word_nosc":"gardenia","lemma":"gardénia","pos":"NOM"} ,
		{"word":"gardénias","word_nosc":"gardenias","lemma":"gardénia","pos":"NOM"} ,
		{"word":"gare","word_nosc":"gare","lemma":"gare","pos":"NOM"} ,
		{"word":"garenne","word_nosc":"garenne","lemma":"garenne","pos":"NOM"} ,
		{"word":"garennes","word_nosc":"garennes","lemma":"garenne","pos":"NOM"} ,
		{"word":"gares","word_nosc":"gares","lemma":"gare","pos":"NOM"} ,
		{"word":"gargamelle","word_nosc":"gargamelle","lemma":"gargamelle","pos":"NOM"} ,
		{"word":"gargantua","word_nosc":"gargantua","lemma":"gargantua","pos":"NOM"} ,
		{"word":"gargarisme","word_nosc":"gargarisme","lemma":"gargarisme","pos":"NOM"} ,
		{"word":"gargarismes","word_nosc":"gargarismes","lemma":"gargarisme","pos":"NOM"} ,
		{"word":"gargote","word_nosc":"gargote","lemma":"gargote","pos":"NOM"} ,
		{"word":"gargotes","word_nosc":"gargotes","lemma":"gargote","pos":"NOM"} ,
		{"word":"gargotier","word_nosc":"gargotier","lemma":"gargotier","pos":"NOM"} ,
		{"word":"gargotiers","word_nosc":"gargotiers","lemma":"gargotier","pos":"NOM"} ,
		{"word":"gargouille","word_nosc":"gargouille","lemma":"gargouille","pos":"NOM"} ,
		{"word":"gargouillement","word_nosc":"gargouillement","lemma":"gargouillement","pos":"NOM"} ,
		{"word":"gargouillements","word_nosc":"gargouillements","lemma":"gargouillement","pos":"NOM"} ,
		{"word":"gargouilles","word_nosc":"gargouilles","lemma":"gargouille","pos":"NOM"} ,
		{"word":"gargouillette","word_nosc":"gargouillette","lemma":"gargouillette","pos":"NOM"} ,
		{"word":"gargouillis","word_nosc":"gargouillis","lemma":"gargouillis","pos":"NOM"} ,
		{"word":"gargoulette","word_nosc":"gargoulette","lemma":"gargoulette","pos":"NOM"} ,
		{"word":"gargoulettes","word_nosc":"gargoulettes","lemma":"gargoulette","pos":"NOM"} ,
		{"word":"gargousses","word_nosc":"gargousses","lemma":"gargousse","pos":"NOM"} ,
		{"word":"gari","word_nosc":"gari","lemma":"gari","pos":"NOM"} ,
		{"word":"garibaldiens","word_nosc":"garibaldiens","lemma":"garibaldien","pos":"NOM"} ,
		{"word":"garnement","word_nosc":"garnement","lemma":"garnement","pos":"NOM"} ,
		{"word":"garnements","word_nosc":"garnements","lemma":"garnement","pos":"NOM"} ,
		{"word":"garni","word_nosc":"garni","lemma":"garni","pos":"NOM"} ,
		{"word":"garnis","word_nosc":"garnis","lemma":"garni","pos":"NOM"} ,
		{"word":"garnison","word_nosc":"garnison","lemma":"garnison","pos":"NOM"} ,
		{"word":"garnisons","word_nosc":"garnisons","lemma":"garnison","pos":"NOM"} ,
		{"word":"garnissage","word_nosc":"garnissage","lemma":"garnissage","pos":"NOM"} ,
		{"word":"garniture","word_nosc":"garniture","lemma":"garniture","pos":"NOM"} ,
		{"word":"garnitures","word_nosc":"garnitures","lemma":"garniture","pos":"NOM"} ,
		{"word":"garno","word_nosc":"garno","lemma":"garno","pos":"NOM"} ,
		{"word":"garou","word_nosc":"garou","lemma":"garou","pos":"NOM"} ,
		{"word":"garrick","word_nosc":"garrick","lemma":"garrick","pos":"NOM"} ,
		{"word":"garrigue","word_nosc":"garrigue","lemma":"garrigue","pos":"NOM"} ,
		{"word":"garrigues","word_nosc":"garrigues","lemma":"garrigue","pos":"NOM"} ,
		{"word":"garrot","word_nosc":"garrot","lemma":"garrot","pos":"NOM"} ,
		{"word":"garrots","word_nosc":"garrots","lemma":"garrot","pos":"NOM"} ,
		{"word":"garrotte","word_nosc":"garrotte","lemma":"garrotte","pos":"NOM"} ,
		{"word":"gars","word_nosc":"gars","lemma":"gars","pos":"NOM"} ,
		{"word":"garçon","word_nosc":"garcon","lemma":"garçon","pos":"NOM"} ,
		{"word":"garçonne","word_nosc":"garconne","lemma":"garçonne","pos":"NOM"} ,
		{"word":"garçonnet","word_nosc":"garconnet","lemma":"garçonnet","pos":"NOM"} ,
		{"word":"garçonnets","word_nosc":"garconnets","lemma":"garçonnet","pos":"NOM"} ,
		{"word":"garçonnière","word_nosc":"garconniere","lemma":"garçonnière","pos":"NOM"} ,
		{"word":"garçonnières","word_nosc":"garconnieres","lemma":"garçonnière","pos":"NOM"} ,
		{"word":"garçons","word_nosc":"garcons","lemma":"garçon","pos":"NOM"} ,
		{"word":"gas-oil","word_nosc":"gas-oil","lemma":"gas-oil","pos":"NOM"} ,
		{"word":"gascon","word_nosc":"gascon","lemma":"gascon","pos":"NOM"} ,
		{"word":"gascons","word_nosc":"gascons","lemma":"gascon","pos":"NOM"} ,
		{"word":"gasoil","word_nosc":"gasoil","lemma":"gasoil","pos":"NOM"} ,
		{"word":"gaspacho","word_nosc":"gaspacho","lemma":"gaspacho","pos":"NOM"} ,
		{"word":"gaspard","word_nosc":"gaspard","lemma":"gaspard","pos":"NOM"} ,
		{"word":"gaspards","word_nosc":"gaspards","lemma":"gaspard","pos":"NOM"} ,
		{"word":"gaspillage","word_nosc":"gaspillage","lemma":"gaspillage","pos":"NOM"} ,
		{"word":"gaspillages","word_nosc":"gaspillages","lemma":"gaspillage","pos":"NOM"} ,
		{"word":"gaspilleurs","word_nosc":"gaspilleurs","lemma":"gaspilleur","pos":"NOM"} ,
		{"word":"gaspilleuses","word_nosc":"gaspilleuses","lemma":"gaspilleur","pos":"NOM"} ,
		{"word":"gaster","word_nosc":"gaster","lemma":"gaster","pos":"NOM"} ,
		{"word":"gastralgie","word_nosc":"gastralgie","lemma":"gastralgie","pos":"NOM"} ,
		{"word":"gastralgies","word_nosc":"gastralgies","lemma":"gastralgie","pos":"NOM"} ,
		{"word":"gastrectomie","word_nosc":"gastrectomie","lemma":"gastrectomie","pos":"NOM"} ,
		{"word":"gastrite","word_nosc":"gastrite","lemma":"gastrite","pos":"NOM"} ,
		{"word":"gastrites","word_nosc":"gastrites","lemma":"gastrite","pos":"NOM"} ,
		{"word":"gastro","word_nosc":"gastro","lemma":"gastro","pos":"NOM"} ,
		{"word":"gastro-entérite","word_nosc":"gastro-enterite","lemma":"gastro-entérite","pos":"NOM"} ,
		{"word":"gastro-entérologue","word_nosc":"gastro-enterologue","lemma":"gastro-entérologue","pos":"NOM"} ,
		{"word":"gastro-entérologues","word_nosc":"gastro-enterologues","lemma":"gastro-entérologue","pos":"NOM"} ,
		{"word":"gastroentérologue","word_nosc":"gastroenterologue","lemma":"gastroentérologue","pos":"NOM"} ,
		{"word":"gastrolâtre","word_nosc":"gastrolatre","lemma":"gastrolâtre","pos":"NOM"} ,
		{"word":"gastronome","word_nosc":"gastronome","lemma":"gastronome","pos":"NOM"} ,
		{"word":"gastronomes","word_nosc":"gastronomes","lemma":"gastronome","pos":"NOM"} ,
		{"word":"gastronomie","word_nosc":"gastronomie","lemma":"gastronomie","pos":"NOM"} ,
		{"word":"gastrula","word_nosc":"gastrula","lemma":"gastrula","pos":"NOM"} ,
		{"word":"gastéropode","word_nosc":"gasteropode","lemma":"gastéropode","pos":"NOM"} ,
		{"word":"gastéropodes","word_nosc":"gasteropodes","lemma":"gastéropode","pos":"NOM"} ,
		{"word":"gatte","word_nosc":"gatte","lemma":"gatte","pos":"NOM"} ,
		{"word":"gauche","word_nosc":"gauche","lemma":"gauche","pos":"NOM"} ,
		{"word":"gaucher","word_nosc":"gaucher","lemma":"gaucher","pos":"NOM"} ,
		{"word":"gaucherie","word_nosc":"gaucherie","lemma":"gaucherie","pos":"NOM"} ,
		{"word":"gaucheries","word_nosc":"gaucheries","lemma":"gaucherie","pos":"NOM"} ,
		{"word":"gauchers","word_nosc":"gauchers","lemma":"gaucher","pos":"NOM"} ,
		{"word":"gauches","word_nosc":"gauches","lemma":"gauche","pos":"NOM"} ,
		{"word":"gauchisme","word_nosc":"gauchisme","lemma":"gauchisme","pos":"NOM"} ,
		{"word":"gauchismes","word_nosc":"gauchismes","lemma":"gauchisme","pos":"NOM"} ,
		{"word":"gauchissement","word_nosc":"gauchissement","lemma":"gauchissement","pos":"NOM"} ,
		{"word":"gauchiste","word_nosc":"gauchiste","lemma":"gauchiste","pos":"NOM"} ,
		{"word":"gauchistes","word_nosc":"gauchistes","lemma":"gauchiste","pos":"NOM"} ,
		{"word":"gaucho","word_nosc":"gaucho","lemma":"gaucho","pos":"NOM"} ,
		{"word":"gauchos","word_nosc":"gauchos","lemma":"gaucho","pos":"NOM"} ,
		{"word":"gauchère","word_nosc":"gauchere","lemma":"gaucher","pos":"NOM"} ,
		{"word":"gaudriole","word_nosc":"gaudriole","lemma":"gaudriole","pos":"NOM"} ,
		{"word":"gaudrioles","word_nosc":"gaudrioles","lemma":"gaudriole","pos":"NOM"} ,
		{"word":"gaufre","word_nosc":"gaufre","lemma":"gaufre","pos":"NOM"} ,
		{"word":"gaufres","word_nosc":"gaufres","lemma":"gaufre","pos":"NOM"} ,
		{"word":"gaufrette","word_nosc":"gaufrette","lemma":"gaufrette","pos":"NOM"} ,
		{"word":"gaufrettes","word_nosc":"gaufrettes","lemma":"gaufrette","pos":"NOM"} ,
		{"word":"gaufrier","word_nosc":"gaufrier","lemma":"gaufrier","pos":"NOM"} ,
		{"word":"gauldo","word_nosc":"gauldo","lemma":"gauldo","pos":"NOM"} ,
		{"word":"gauldos","word_nosc":"gauldos","lemma":"gauldo","pos":"NOM"} ,
		{"word":"gaule","word_nosc":"gaule","lemma":"gaule","pos":"NOM"} ,
		{"word":"gauleiter","word_nosc":"gauleiter","lemma":"gauleiter","pos":"NOM"} ,
		{"word":"gaules","word_nosc":"gaules","lemma":"gaul","pos":"NOM"} ,
		{"word":"gaules","word_nosc":"gaules","lemma":"gaule","pos":"NOM"} ,
		{"word":"gaulis","word_nosc":"gaulis","lemma":"gaulis","pos":"NOM"} ,
		{"word":"gaulle","word_nosc":"gaulle","lemma":"gaulle","pos":"NOM"} ,
		{"word":"gaullisme","word_nosc":"gaullisme","lemma":"gaullisme","pos":"NOM"} ,
		{"word":"gaulliste","word_nosc":"gaulliste","lemma":"gaulliste","pos":"NOM"} ,
		{"word":"gaullistes","word_nosc":"gaullistes","lemma":"gaulliste","pos":"NOM"} ,
		{"word":"gaulois","word_nosc":"gaulois","lemma":"gaulois","pos":"NOM"} ,
		{"word":"gauloise","word_nosc":"gauloise","lemma":"gaulois","pos":"NOM"} ,
		{"word":"gauloiseries","word_nosc":"gauloiseries","lemma":"gauloiserie","pos":"NOM"} ,
		{"word":"gauloises","word_nosc":"gauloises","lemma":"gaulois","pos":"NOM"} ,
		{"word":"gaulthérie","word_nosc":"gaultherie","lemma":"gaulthérie","pos":"NOM"} ,
		{"word":"gaulée","word_nosc":"gaulee","lemma":"gaulée","pos":"NOM"} ,
		{"word":"gaupe","word_nosc":"gaupe","lemma":"gaupe","pos":"NOM"} ,
		{"word":"gauss","word_nosc":"gauss","lemma":"gauss","pos":"NOM"} ,
		{"word":"gavage","word_nosc":"gavage","lemma":"gavage","pos":"NOM"} ,
		{"word":"gave","word_nosc":"gave","lemma":"gave","pos":"NOM"} ,
		{"word":"gaves","word_nosc":"gaves","lemma":"gave","pos":"NOM"} ,
		{"word":"gavial","word_nosc":"gavial","lemma":"gavial","pos":"NOM"} ,
		{"word":"gaviot","word_nosc":"gaviot","lemma":"gaviot","pos":"NOM"} ,
		{"word":"gavroche","word_nosc":"gavroche","lemma":"gavroche","pos":"NOM"} ,
		{"word":"gavroches","word_nosc":"gavroches","lemma":"gavroche","pos":"NOM"} ,
		{"word":"gay","word_nosc":"gay","lemma":"gay","pos":"NOM"} ,
		{"word":"gayac","word_nosc":"gayac","lemma":"gayac","pos":"NOM"} ,
		{"word":"gays","word_nosc":"gays","lemma":"gay","pos":"NOM"} ,
		{"word":"gaz","word_nosc":"gaz","lemma":"gaz","pos":"NOM"} ,
		{"word":"gazage","word_nosc":"gazage","lemma":"gazage","pos":"NOM"} ,
		{"word":"gaze","word_nosc":"gaze","lemma":"gaze","pos":"NOM"} ,
		{"word":"gazelle","word_nosc":"gazelle","lemma":"gazelle","pos":"NOM"} ,
		{"word":"gazelles","word_nosc":"gazelles","lemma":"gazelle","pos":"NOM"} ,
		{"word":"gazes","word_nosc":"gazes","lemma":"gaze","pos":"NOM"} ,
		{"word":"gazette","word_nosc":"gazette","lemma":"gazette","pos":"NOM"} ,
		{"word":"gazettes","word_nosc":"gazettes","lemma":"gazette","pos":"NOM"} ,
		{"word":"gazier","word_nosc":"gazier","lemma":"gazier","pos":"NOM"} ,
		{"word":"gaziers","word_nosc":"gaziers","lemma":"gazier","pos":"NOM"} ,
		{"word":"gazinière","word_nosc":"gaziniere","lemma":"gazinière","pos":"NOM"} ,
		{"word":"gazinières","word_nosc":"gazinieres","lemma":"gazinière","pos":"NOM"} ,
		{"word":"gazoduc","word_nosc":"gazoduc","lemma":"gazoduc","pos":"NOM"} ,
		{"word":"gazogène","word_nosc":"gazogene","lemma":"gazogène","pos":"NOM"} ,
		{"word":"gazogènes","word_nosc":"gazogenes","lemma":"gazogène","pos":"NOM"} ,
		{"word":"gazole","word_nosc":"gazole","lemma":"gazole","pos":"NOM"} ,
		{"word":"gazoline","word_nosc":"gazoline","lemma":"gazoline","pos":"NOM"} ,
		{"word":"gazomètre","word_nosc":"gazometre","lemma":"gazomètre","pos":"NOM"} ,
		{"word":"gazométrie","word_nosc":"gazometrie","lemma":"gazométrie","pos":"NOM"} ,
		{"word":"gazon","word_nosc":"gazon","lemma":"gazon","pos":"NOM"} ,
		{"word":"gazons","word_nosc":"gazons","lemma":"gazon","pos":"NOM"} ,
		{"word":"gazouillement","word_nosc":"gazouillement","lemma":"gazouillement","pos":"NOM"} ,
		{"word":"gazouillements","word_nosc":"gazouillements","lemma":"gazouillement","pos":"NOM"} ,
		{"word":"gazouillis","word_nosc":"gazouillis","lemma":"gazouillis","pos":"NOM"} ,
		{"word":"gazpacho","word_nosc":"gazpacho","lemma":"gazpacho","pos":"NOM"} ,
		{"word":"gazé","word_nosc":"gaze","lemma":"gazé","pos":"NOM"} ,
		{"word":"gazés","word_nosc":"gazes","lemma":"gazé","pos":"NOM"} ,
		{"word":"gaélique","word_nosc":"gaelique","lemma":"gaélique","pos":"NOM"} ,
		{"word":"gaîté","word_nosc":"gaite","lemma":"gaîté","pos":"NOM"} ,
		{"word":"gaîtés","word_nosc":"gaites","lemma":"gaîté","pos":"NOM"} ,
		{"word":"geai","word_nosc":"geai","lemma":"geai","pos":"NOM"} ,
		{"word":"geais","word_nosc":"geais","lemma":"geai","pos":"NOM"} ,
		{"word":"gecko","word_nosc":"gecko","lemma":"gecko","pos":"NOM"} ,
		{"word":"geckos","word_nosc":"geckos","lemma":"gecko","pos":"NOM"} ,
		{"word":"geez","word_nosc":"geez","lemma":"geez","pos":"NOM"} ,
		{"word":"geignard","word_nosc":"geignard","lemma":"geignard","pos":"NOM"} ,
		{"word":"geignarde","word_nosc":"geignarde","lemma":"geignard","pos":"NOM"} ,
		{"word":"geignardises","word_nosc":"geignardises","lemma":"geignardise","pos":"NOM"} ,
		{"word":"geignards","word_nosc":"geignards","lemma":"geignard","pos":"NOM"} ,
		{"word":"geignement","word_nosc":"geignement","lemma":"geignement","pos":"NOM"} ,
		{"word":"geignements","word_nosc":"geignements","lemma":"geignement","pos":"NOM"} ,
		{"word":"geindre","word_nosc":"geindre","lemma":"geindre","pos":"NOM"} ,
		{"word":"geisha","word_nosc":"geisha","lemma":"geisha","pos":"NOM"} ,
		{"word":"geishas","word_nosc":"geishas","lemma":"geisha","pos":"NOM"} ,
		{"word":"gel","word_nosc":"gel","lemma":"gel","pos":"NOM"} ,
		{"word":"geline","word_nosc":"geline","lemma":"geline","pos":"NOM"} ,
		{"word":"gelinotte","word_nosc":"gelinotte","lemma":"gelinotte","pos":"NOM"} ,
		{"word":"gels","word_nosc":"gels","lemma":"gel","pos":"NOM"} ,
		{"word":"gelure","word_nosc":"gelure","lemma":"gelure","pos":"NOM"} ,
		{"word":"gelures","word_nosc":"gelures","lemma":"gelure","pos":"NOM"} ,
		{"word":"gelée","word_nosc":"gelee","lemma":"gelée","pos":"NOM"} ,
		{"word":"gelées","word_nosc":"gelees","lemma":"gelée","pos":"NOM"} ,
		{"word":"gemme","word_nosc":"gemme","lemma":"gemme","pos":"NOM"} ,
		{"word":"gemmes","word_nosc":"gemmes","lemma":"gemme","pos":"NOM"} ,
		{"word":"gemmologie","word_nosc":"gemmologie","lemma":"gemmologie","pos":"NOM"} ,
		{"word":"gencive","word_nosc":"gencive","lemma":"gencive","pos":"NOM"} ,
		{"word":"gencives","word_nosc":"gencives","lemma":"gencive","pos":"NOM"} ,
		{"word":"gendarme","word_nosc":"gendarme","lemma":"gendarme","pos":"NOM"} ,
		{"word":"gendarmerie","word_nosc":"gendarmerie","lemma":"gendarmerie","pos":"NOM"} ,
		{"word":"gendarmeries","word_nosc":"gendarmeries","lemma":"gendarmerie","pos":"NOM"} ,
		{"word":"gendarmes","word_nosc":"gendarmes","lemma":"gendarme","pos":"NOM"} ,
		{"word":"gendelettres","word_nosc":"gendelettres","lemma":"gendelettre","pos":"NOM"} ,
		{"word":"gendre","word_nosc":"gendre","lemma":"gendre","pos":"NOM"} ,
		{"word":"gendres","word_nosc":"gendres","lemma":"gendre","pos":"NOM"} ,
		{"word":"genet","word_nosc":"genet","lemma":"genet","pos":"NOM"} ,
		{"word":"genevois","word_nosc":"genevois","lemma":"genevois","pos":"NOM"} ,
		{"word":"genevoises","word_nosc":"genevoises","lemma":"genevois","pos":"NOM"} ,
		{"word":"gengis khan","word_nosc":"gengis khan","lemma":"gengis khan","pos":"NOM"} ,
		{"word":"genièvre","word_nosc":"genievre","lemma":"genièvre","pos":"NOM"} ,
		{"word":"genou","word_nosc":"genou","lemma":"genou","pos":"NOM"} ,
		{"word":"genouillère","word_nosc":"genouillere","lemma":"genouillère","pos":"NOM"} ,
		{"word":"genouillères","word_nosc":"genouilleres","lemma":"genouillère","pos":"NOM"} ,
		{"word":"genoux","word_nosc":"genoux","lemma":"genou","pos":"NOM"} ,
		{"word":"genre","word_nosc":"genre","lemma":"genre","pos":"NOM"} ,
		{"word":"genres","word_nosc":"genres","lemma":"genre","pos":"NOM"} ,
		{"word":"gens","word_nosc":"gens","lemma":"gens","pos":"NOM"} ,
		{"word":"gent","word_nosc":"gent","lemma":"gent","pos":"NOM"} ,
		{"word":"gentamicine","word_nosc":"gentamicine","lemma":"gentamicine","pos":"NOM"} ,
		{"word":"gentiane","word_nosc":"gentiane","lemma":"gentiane","pos":"NOM"} ,
		{"word":"gentianes","word_nosc":"gentianes","lemma":"gentiane","pos":"NOM"} ,
		{"word":"gentil","word_nosc":"gentil","lemma":"gentil","pos":"NOM"} ,
		{"word":"gentilhomme","word_nosc":"gentilhomme","lemma":"gentilhomme","pos":"NOM"} ,
		{"word":"gentilhommière","word_nosc":"gentilhommiere","lemma":"gentilhommier","pos":"NOM"} ,
		{"word":"gentilhommières","word_nosc":"gentilhommieres","lemma":"gentilhommier","pos":"NOM"} ,
		{"word":"gentillesse","word_nosc":"gentillesse","lemma":"gentillesse","pos":"NOM"} ,
		{"word":"gentillesses","word_nosc":"gentillesses","lemma":"gentillesse","pos":"NOM"} ,
		{"word":"gentils","word_nosc":"gentils","lemma":"gentil","pos":"NOM"} ,
		{"word":"gentilshommes","word_nosc":"gentilshommes","lemma":"gentilhomme","pos":"NOM"} ,
		{"word":"gentleman","word_nosc":"gentleman","lemma":"gentleman","pos":"NOM"} ,
		{"word":"gentleman-farmer","word_nosc":"gentleman-farmer","lemma":"gentleman-farmer","pos":"NOM"} ,
		{"word":"gentlemen","word_nosc":"gentlemen","lemma":"gentleman","pos":"NOM"} ,
		{"word":"gentlemen-farmers","word_nosc":"gentlemen-farmers","lemma":"gentleman-farmer","pos":"NOM"} ,
		{"word":"gentry","word_nosc":"gentry","lemma":"gentry","pos":"NOM"} ,
		{"word":"gents","word_nosc":"gents","lemma":"gent","pos":"NOM"} ,
		{"word":"genèse","word_nosc":"genese","lemma":"genèse","pos":"NOM"} ,
		{"word":"genévrier","word_nosc":"genevrier","lemma":"genévrier","pos":"NOM"} ,
		{"word":"genévriers","word_nosc":"genevriers","lemma":"genévrier","pos":"NOM"} ,
		{"word":"genêt","word_nosc":"genet","lemma":"genêt","pos":"NOM"} ,
		{"word":"genêtière","word_nosc":"genetiere","lemma":"genêtière","pos":"NOM"} ,
		{"word":"genêts","word_nosc":"genets","lemma":"genêt","pos":"NOM"} ,
		{"word":"gerbe","word_nosc":"gerbe","lemma":"gerbe","pos":"NOM"} ,
		{"word":"gerberas","word_nosc":"gerberas","lemma":"gerbera","pos":"NOM"} ,
		{"word":"gerbes","word_nosc":"gerbes","lemma":"gerbe","pos":"NOM"} ,
		{"word":"gerbeuse","word_nosc":"gerbeuse","lemma":"gerbeur","pos":"NOM"} ,
		{"word":"gerbeuses","word_nosc":"gerbeuses","lemma":"gerbeur","pos":"NOM"} ,
		{"word":"gerbier","word_nosc":"gerbier","lemma":"gerbier","pos":"NOM"} ,
		{"word":"gerbille","word_nosc":"gerbille","lemma":"gerbille","pos":"NOM"} ,
		{"word":"gerbière","word_nosc":"gerbiere","lemma":"gerbier","pos":"NOM"} ,
		{"word":"gerboise","word_nosc":"gerboise","lemma":"gerboise","pos":"NOM"} ,
		{"word":"gerboises","word_nosc":"gerboises","lemma":"gerboise","pos":"NOM"} ,
		{"word":"gerces","word_nosc":"gerces","lemma":"gerce","pos":"NOM"} ,
		{"word":"gerfaut","word_nosc":"gerfaut","lemma":"gerfaut","pos":"NOM"} ,
		{"word":"gerfauts","word_nosc":"gerfauts","lemma":"gerfaut","pos":"NOM"} ,
		{"word":"germains","word_nosc":"germains","lemma":"germain","pos":"NOM"} ,
		{"word":"germanique","word_nosc":"germanique","lemma":"germanique","pos":"NOM"} ,
		{"word":"germaniques","word_nosc":"germaniques","lemma":"germanique","pos":"NOM"} ,
		{"word":"germanisation","word_nosc":"germanisation","lemma":"germanisation","pos":"NOM"} ,
		{"word":"germanisme","word_nosc":"germanisme","lemma":"germanisme","pos":"NOM"} ,
		{"word":"germaniste","word_nosc":"germaniste","lemma":"germaniste","pos":"NOM"} ,
		{"word":"germanité","word_nosc":"germanite","lemma":"germanité","pos":"NOM"} ,
		{"word":"germanium","word_nosc":"germanium","lemma":"germanium","pos":"NOM"} ,
		{"word":"germanophile","word_nosc":"germanophile","lemma":"germanophile","pos":"NOM"} ,
		{"word":"germanophilie","word_nosc":"germanophilie","lemma":"germanophilie","pos":"NOM"} ,
		{"word":"germe","word_nosc":"germe","lemma":"germe","pos":"NOM"} ,
		{"word":"germen","word_nosc":"germen","lemma":"germen","pos":"NOM"} ,
		{"word":"germes","word_nosc":"germes","lemma":"germe","pos":"NOM"} ,
		{"word":"germinal","word_nosc":"germinal","lemma":"germinal","pos":"NOM"} ,
		{"word":"germination","word_nosc":"germination","lemma":"germination","pos":"NOM"} ,
		{"word":"germinations","word_nosc":"germinations","lemma":"germination","pos":"NOM"} ,
		{"word":"gerris","word_nosc":"gerris","lemma":"gerris","pos":"NOM"} ,
		{"word":"gerçures","word_nosc":"gercures","lemma":"gerçure","pos":"NOM"} ,
		{"word":"gestapiste","word_nosc":"gestapiste","lemma":"gestapiste","pos":"NOM"} ,
		{"word":"gestapo","word_nosc":"gestapo","lemma":"gestapo","pos":"NOM"} ,
		{"word":"gestation","word_nosc":"gestation","lemma":"gestation","pos":"NOM"} ,
		{"word":"gestations","word_nosc":"gestations","lemma":"gestation","pos":"NOM"} ,
		{"word":"geste","word_nosc":"geste","lemma":"geste","pos":"NOM"} ,
		{"word":"gestes","word_nosc":"gestes","lemma":"geste","pos":"NOM"} ,
		{"word":"gesticulateurs","word_nosc":"gesticulateurs","lemma":"gesticulateur","pos":"NOM"} ,
		{"word":"gesticulation","word_nosc":"gesticulation","lemma":"gesticulation","pos":"NOM"} ,
		{"word":"gesticulations","word_nosc":"gesticulations","lemma":"gesticulation","pos":"NOM"} ,
		{"word":"gestion","word_nosc":"gestion","lemma":"gestion","pos":"NOM"} ,
		{"word":"gestionnaire","word_nosc":"gestionnaire","lemma":"gestionnaire","pos":"NOM"} ,
		{"word":"gestionnaires","word_nosc":"gestionnaires","lemma":"gestionnaire","pos":"NOM"} ,
		{"word":"gestuelle","word_nosc":"gestuelle","lemma":"gestuel","pos":"NOM"} ,
		{"word":"geyser","word_nosc":"geyser","lemma":"geyser","pos":"NOM"} ,
		{"word":"geysers","word_nosc":"geysers","lemma":"geyser","pos":"NOM"} ,
		{"word":"geôle","word_nosc":"geole","lemma":"geôle","pos":"NOM"} ,
		{"word":"geôles","word_nosc":"geoles","lemma":"geôle","pos":"NOM"} ,
		{"word":"geôlier","word_nosc":"geolier","lemma":"geôlier","pos":"NOM"} ,
		{"word":"geôliers","word_nosc":"geoliers","lemma":"geôlier","pos":"NOM"} ,
		{"word":"geôlière","word_nosc":"geoliere","lemma":"geôlier","pos":"NOM"} ,
		{"word":"geôlières","word_nosc":"geolieres","lemma":"geôlier","pos":"NOM"} ,
		{"word":"ghetto","word_nosc":"ghetto","lemma":"ghetto","pos":"NOM"} ,
		{"word":"ghettos","word_nosc":"ghettos","lemma":"ghetto","pos":"NOM"} ,
		{"word":"ghât","word_nosc":"ghat","lemma":"ghât","pos":"NOM"} ,
		{"word":"giaour","word_nosc":"giaour","lemma":"giaour","pos":"NOM"} ,
		{"word":"giaours","word_nosc":"giaours","lemma":"giaour","pos":"NOM"} ,
		{"word":"gibbon","word_nosc":"gibbon","lemma":"gibbon","pos":"NOM"} ,
		{"word":"gibbons","word_nosc":"gibbons","lemma":"gibbon","pos":"NOM"} ,
		{"word":"gibbosité","word_nosc":"gibbosite","lemma":"gibbosité","pos":"NOM"} ,
		{"word":"gibecière","word_nosc":"gibeciere","lemma":"gibecière","pos":"NOM"} ,
		{"word":"gibecières","word_nosc":"gibecieres","lemma":"gibecière","pos":"NOM"} ,
		{"word":"gibelet","word_nosc":"gibelet","lemma":"gibelet","pos":"NOM"} ,
		{"word":"gibelin","word_nosc":"gibelin","lemma":"gibelin","pos":"NOM"} ,
		{"word":"gibelins","word_nosc":"gibelins","lemma":"gibelin","pos":"NOM"} ,
		{"word":"gibelotte","word_nosc":"gibelotte","lemma":"gibelotte","pos":"NOM"} ,
		{"word":"gibelottes","word_nosc":"gibelottes","lemma":"gibelotte","pos":"NOM"} ,
		{"word":"giberne","word_nosc":"giberne","lemma":"giberne","pos":"NOM"} ,
		{"word":"gibernes","word_nosc":"gibernes","lemma":"giberne","pos":"NOM"} ,
		{"word":"gibet","word_nosc":"gibet","lemma":"gibet","pos":"NOM"} ,
		{"word":"gibets","word_nosc":"gibets","lemma":"gibet","pos":"NOM"} ,
		{"word":"gibier","word_nosc":"gibier","lemma":"gibier","pos":"NOM"} ,
		{"word":"gibiers","word_nosc":"gibiers","lemma":"gibier","pos":"NOM"} ,
		{"word":"giboulée","word_nosc":"giboulee","lemma":"giboulée","pos":"NOM"} ,
		{"word":"giboulées","word_nosc":"giboulees","lemma":"giboulée","pos":"NOM"} ,
		{"word":"gibus","word_nosc":"gibus","lemma":"gibus","pos":"NOM"} ,
		{"word":"giclement","word_nosc":"giclement","lemma":"giclement","pos":"NOM"} ,
		{"word":"giclements","word_nosc":"giclements","lemma":"giclement","pos":"NOM"} ,
		{"word":"gicleur","word_nosc":"gicleur","lemma":"gicleur","pos":"NOM"} ,
		{"word":"giclée","word_nosc":"giclee","lemma":"giclée","pos":"NOM"} ,
		{"word":"giclées","word_nosc":"giclees","lemma":"giclée","pos":"NOM"} ,
		{"word":"gidouille","word_nosc":"gidouille","lemma":"gidouille","pos":"NOM"} ,
		{"word":"gifle","word_nosc":"gifle","lemma":"gifle","pos":"NOM"} ,
		{"word":"gifles","word_nosc":"gifles","lemma":"gifle","pos":"NOM"} ,
		{"word":"gifleuse","word_nosc":"gifleuse","lemma":"gifleur","pos":"NOM"} ,
		{"word":"gifts","word_nosc":"gifts","lemma":"gift","pos":"NOM"} ,
		{"word":"gig","word_nosc":"gig","lemma":"gig","pos":"NOM"} ,
		{"word":"gigabits","word_nosc":"gigabits","lemma":"gigabit","pos":"NOM"} ,
		{"word":"gigahertz","word_nosc":"gigahertz","lemma":"gigahertz","pos":"NOM"} ,
		{"word":"gigantisme","word_nosc":"gigantisme","lemma":"gigantisme","pos":"NOM"} ,
		{"word":"gigantismes","word_nosc":"gigantismes","lemma":"gigantisme","pos":"NOM"} ,
		{"word":"gigantomachie","word_nosc":"gigantomachie","lemma":"gigantomachie","pos":"NOM"} ,
		{"word":"gigaoctets","word_nosc":"gigaoctets","lemma":"gigaoctet","pos":"NOM"} ,
		{"word":"gigolette","word_nosc":"gigolette","lemma":"gigolette","pos":"NOM"} ,
		{"word":"gigolo","word_nosc":"gigolo","lemma":"gigolo","pos":"NOM"} ,
		{"word":"gigolos","word_nosc":"gigolos","lemma":"gigolo","pos":"NOM"} ,
		{"word":"gigolpince","word_nosc":"gigolpince","lemma":"gigolpince","pos":"NOM"} ,
		{"word":"gigolpinces","word_nosc":"gigolpinces","lemma":"gigolpince","pos":"NOM"} ,
		{"word":"gigot","word_nosc":"gigot","lemma":"gigot","pos":"NOM"} ,
		{"word":"gigotements","word_nosc":"gigotements","lemma":"gigotement","pos":"NOM"} ,
		{"word":"gigots","word_nosc":"gigots","lemma":"gigot","pos":"NOM"} ,
		{"word":"gigue","word_nosc":"gigue","lemma":"gigue","pos":"NOM"} ,
		{"word":"gilbert","word_nosc":"gilbert","lemma":"gilbert","pos":"NOM"} ,
		{"word":"gilet","word_nosc":"gilet","lemma":"gilet","pos":"NOM"} ,
		{"word":"giletières","word_nosc":"giletieres","lemma":"giletier","pos":"NOM"} ,
		{"word":"gilets","word_nosc":"gilets","lemma":"gilet","pos":"NOM"} ,
		{"word":"gilles","word_nosc":"gilles","lemma":"gille","pos":"NOM"} ,
		{"word":"gimmick","word_nosc":"gimmick","lemma":"gimmick","pos":"NOM"} ,
		{"word":"gimmicks","word_nosc":"gimmicks","lemma":"gimmick","pos":"NOM"} ,
		{"word":"gin","word_nosc":"gin","lemma":"gin","pos":"NOM"} ,
		{"word":"gin-fizz","word_nosc":"gin-fizz","lemma":"gin-fizz","pos":"NOM"} ,
		{"word":"gin-rami","word_nosc":"gin-rami","lemma":"gin-rami","pos":"NOM"} ,
		{"word":"gin-rummy","word_nosc":"gin-rummy","lemma":"gin-rummy","pos":"NOM"} ,
		{"word":"gineste","word_nosc":"gineste","lemma":"gineste","pos":"NOM"} ,
		{"word":"gingembre","word_nosc":"gingembre","lemma":"gingembre","pos":"NOM"} ,
		{"word":"gingin","word_nosc":"gingin","lemma":"gingin","pos":"NOM"} ,
		{"word":"gingivite","word_nosc":"gingivite","lemma":"gingivite","pos":"NOM"} ,
		{"word":"ginkgo","word_nosc":"ginkgo","lemma":"ginkgo","pos":"NOM"} ,
		{"word":"gins","word_nosc":"gins","lemma":"gin","pos":"NOM"} ,
		{"word":"ginseng","word_nosc":"ginseng","lemma":"ginseng","pos":"NOM"} ,
		{"word":"gipsy","word_nosc":"gipsy","lemma":"gipsy","pos":"NOM"} ,
		{"word":"girafe","word_nosc":"girafe","lemma":"girafe","pos":"NOM"} ,
		{"word":"girafeau","word_nosc":"girafeau","lemma":"girafeau","pos":"NOM"} ,
		{"word":"girafes","word_nosc":"girafes","lemma":"girafe","pos":"NOM"} ,
		{"word":"girafon","word_nosc":"girafon","lemma":"girafon","pos":"NOM"} ,
		{"word":"girandes","word_nosc":"girandes","lemma":"girande","pos":"NOM"} ,
		{"word":"girandole","word_nosc":"girandole","lemma":"girandole","pos":"NOM"} ,
		{"word":"girandoles","word_nosc":"girandoles","lemma":"girandole","pos":"NOM"} ,
		{"word":"giration","word_nosc":"giration","lemma":"giration","pos":"NOM"} ,
		{"word":"girations","word_nosc":"girations","lemma":"giration","pos":"NOM"} ,
		{"word":"giraudistes","word_nosc":"giraudistes","lemma":"giraudiste","pos":"NOM"} ,
		{"word":"gire","word_nosc":"gire","lemma":"gire","pos":"NOM"} ,
		{"word":"girelles","word_nosc":"girelles","lemma":"girel","pos":"NOM"} ,
		{"word":"gires","word_nosc":"gires","lemma":"gire","pos":"NOM"} ,
		{"word":"girie","word_nosc":"girie","lemma":"girie","pos":"NOM"} ,
		{"word":"giries","word_nosc":"giries","lemma":"girie","pos":"NOM"} ,
		{"word":"girl","word_nosc":"girl","lemma":"girl","pos":"NOM"} ,
		{"word":"girl friend","word_nosc":"girl friend","lemma":"girl friend","pos":"NOM"} ,
		{"word":"girl-scout","word_nosc":"girl-scout","lemma":"girl-scout","pos":"NOM"} ,
		{"word":"girls","word_nosc":"girls","lemma":"girl","pos":"NOM"} ,
		{"word":"girofle","word_nosc":"girofle","lemma":"girofle","pos":"NOM"} ,
		{"word":"girofles","word_nosc":"girofles","lemma":"girofle","pos":"NOM"} ,
		{"word":"giroflier","word_nosc":"giroflier","lemma":"giroflier","pos":"NOM"} ,
		{"word":"giroflée","word_nosc":"giroflee","lemma":"giroflée","pos":"NOM"} ,
		{"word":"giroflées","word_nosc":"giroflees","lemma":"giroflée","pos":"NOM"} ,
		{"word":"girolle","word_nosc":"girolle","lemma":"girolle","pos":"NOM"} ,
		{"word":"girolles","word_nosc":"girolles","lemma":"girolle","pos":"NOM"} ,
		{"word":"giron","word_nosc":"giron","lemma":"giron","pos":"NOM"} ,
		{"word":"girondin","word_nosc":"girondin","lemma":"girondin","pos":"NOM"} ,
		{"word":"girouette","word_nosc":"girouette","lemma":"girouette","pos":"NOM"} ,
		{"word":"girouettes","word_nosc":"girouettes","lemma":"girouette","pos":"NOM"} ,
		{"word":"gisant","word_nosc":"gisant","lemma":"gisant","pos":"NOM"} ,
		{"word":"gisants","word_nosc":"gisants","lemma":"gisant","pos":"NOM"} ,
		{"word":"gisement","word_nosc":"gisement","lemma":"gisement","pos":"NOM"} ,
		{"word":"gisements","word_nosc":"gisements","lemma":"gisement","pos":"NOM"} ,
		{"word":"gisquette","word_nosc":"gisquette","lemma":"gisquette","pos":"NOM"} ,
		{"word":"gisquettes","word_nosc":"gisquettes","lemma":"gisquette","pos":"NOM"} ,
		{"word":"gitan","word_nosc":"gitan","lemma":"gitan","pos":"NOM"} ,
		{"word":"gitane","word_nosc":"gitane","lemma":"gitan","pos":"NOM"} ,
		{"word":"gitanes","word_nosc":"gitanes","lemma":"gitan","pos":"NOM"} ,
		{"word":"gitans","word_nosc":"gitans","lemma":"gitan","pos":"NOM"} ,
		{"word":"giton","word_nosc":"giton","lemma":"giton","pos":"NOM"} ,
		{"word":"gitons","word_nosc":"gitons","lemma":"giton","pos":"NOM"} ,
		{"word":"givrage","word_nosc":"givrage","lemma":"givrage","pos":"NOM"} ,
		{"word":"givre","word_nosc":"givre","lemma":"givre","pos":"NOM"} ,
		{"word":"givres","word_nosc":"givres","lemma":"givre","pos":"NOM"} ,
		{"word":"glabelle","word_nosc":"glabelle","lemma":"glabelle","pos":"NOM"} ,
		{"word":"glace","word_nosc":"glace","lemma":"glace","pos":"NOM"} ,
		{"word":"glaces","word_nosc":"glaces","lemma":"glace","pos":"NOM"} ,
		{"word":"glaciation","word_nosc":"glaciation","lemma":"glaciation","pos":"NOM"} ,
		{"word":"glaciations","word_nosc":"glaciations","lemma":"glaciation","pos":"NOM"} ,
		{"word":"glacier","word_nosc":"glacier","lemma":"glacier","pos":"NOM"} ,
		{"word":"glaciers","word_nosc":"glaciers","lemma":"glacier","pos":"NOM"} ,
		{"word":"glaciologue","word_nosc":"glaciologue","lemma":"glaciologue","pos":"NOM"} ,
		{"word":"glacis","word_nosc":"glacis","lemma":"glacis","pos":"NOM"} ,
		{"word":"glacière","word_nosc":"glaciere","lemma":"glacière","pos":"NOM"} ,
		{"word":"glacières","word_nosc":"glacieres","lemma":"glacière","pos":"NOM"} ,
		{"word":"gladiateur","word_nosc":"gladiateur","lemma":"gladiateur","pos":"NOM"} ,
		{"word":"gladiateurs","word_nosc":"gladiateurs","lemma":"gladiateur","pos":"NOM"} ,
		{"word":"gladiatrices","word_nosc":"gladiatrices","lemma":"gladiatrice","pos":"NOM"} ,
		{"word":"glaire","word_nosc":"glaire","lemma":"glaire","pos":"NOM"} ,
		{"word":"glaires","word_nosc":"glaires","lemma":"glaire","pos":"NOM"} ,
		{"word":"glaise","word_nosc":"glaise","lemma":"glaise","pos":"NOM"} ,
		{"word":"glaises","word_nosc":"glaises","lemma":"glaise","pos":"NOM"} ,
		{"word":"glaive","word_nosc":"glaive","lemma":"glaive","pos":"NOM"} ,
		{"word":"glaives","word_nosc":"glaives","lemma":"glaive","pos":"NOM"} ,
		{"word":"glamour","word_nosc":"glamour","lemma":"glamour","pos":"NOM"} ,
		{"word":"glanage","word_nosc":"glanage","lemma":"glanage","pos":"NOM"} ,
		{"word":"gland","word_nosc":"gland","lemma":"gland","pos":"NOM"} ,
		{"word":"glandage","word_nosc":"glandage","lemma":"glandage","pos":"NOM"} ,
		{"word":"glande","word_nosc":"glande","lemma":"glande","pos":"NOM"} ,
		{"word":"glandes","word_nosc":"glandes","lemma":"glande","pos":"NOM"} ,
		{"word":"glandeur","word_nosc":"glandeur","lemma":"glandeur","pos":"NOM"} ,
		{"word":"glandeurs","word_nosc":"glandeurs","lemma":"glandeur","pos":"NOM"} ,
		{"word":"glandouilleur","word_nosc":"glandouilleur","lemma":"glandouilleur","pos":"NOM"} ,
		{"word":"glands","word_nosc":"glands","lemma":"gland","pos":"NOM"} ,
		{"word":"glandu","word_nosc":"glandu","lemma":"glandu","pos":"NOM"} ,
		{"word":"glandus","word_nosc":"glandus","lemma":"glandu","pos":"NOM"} ,
		{"word":"glane","word_nosc":"glane","lemma":"glane","pos":"NOM"} ,
		{"word":"glanes","word_nosc":"glanes","lemma":"glane","pos":"NOM"} ,
		{"word":"glaneur","word_nosc":"glaneur","lemma":"glaneur","pos":"NOM"} ,
		{"word":"glaneuse","word_nosc":"glaneuse","lemma":"glaneur","pos":"NOM"} ,
		{"word":"glanure","word_nosc":"glanure","lemma":"glanure","pos":"NOM"} ,
		{"word":"glapissement","word_nosc":"glapissement","lemma":"glapissement","pos":"NOM"} ,
		{"word":"glapissements","word_nosc":"glapissements","lemma":"glapissement","pos":"NOM"} ,
		{"word":"glaréole","word_nosc":"glareole","lemma":"glaréole","pos":"NOM"} ,
		{"word":"glas","word_nosc":"glas","lemma":"glas","pos":"NOM"} ,
		{"word":"glasnost","word_nosc":"glasnost","lemma":"glasnost","pos":"NOM"} ,
		{"word":"glass","word_nosc":"glass","lemma":"glass","pos":"NOM"} ,
		{"word":"glasses","word_nosc":"glasses","lemma":"glass","pos":"NOM"} ,
		{"word":"glaucome","word_nosc":"glaucome","lemma":"glaucome","pos":"NOM"} ,
		{"word":"glaviot","word_nosc":"glaviot","lemma":"glaviot","pos":"NOM"} ,
		{"word":"glavioteurs","word_nosc":"glavioteurs","lemma":"glavioteur","pos":"NOM"} ,
		{"word":"glaviots","word_nosc":"glaviots","lemma":"glaviot","pos":"NOM"} ,
		{"word":"glaçage","word_nosc":"glacage","lemma":"glaçage","pos":"NOM"} ,
		{"word":"glaçages","word_nosc":"glacages","lemma":"glaçage","pos":"NOM"} ,
		{"word":"glaçon","word_nosc":"glacon","lemma":"glaçon","pos":"NOM"} ,
		{"word":"glaçons","word_nosc":"glacons","lemma":"glaçon","pos":"NOM"} ,
		{"word":"glaçure","word_nosc":"glacure","lemma":"glaçure","pos":"NOM"} ,
		{"word":"glaçures","word_nosc":"glacures","lemma":"glaçure","pos":"NOM"} ,
		{"word":"glaïeul","word_nosc":"glaieul","lemma":"glaïeul","pos":"NOM"} ,
		{"word":"glaïeuls","word_nosc":"glaieuls","lemma":"glaïeul","pos":"NOM"} ,
		{"word":"glide","word_nosc":"glide","lemma":"glide","pos":"NOM"} ,
		{"word":"glioblastome","word_nosc":"glioblastome","lemma":"glioblastome","pos":"NOM"} ,
		{"word":"gliome","word_nosc":"gliome","lemma":"gliome","pos":"NOM"} ,
		{"word":"glissade","word_nosc":"glissade","lemma":"glissade","pos":"NOM"} ,
		{"word":"glissades","word_nosc":"glissades","lemma":"glissade","pos":"NOM"} ,
		{"word":"glissandi","word_nosc":"glissandi","lemma":"glissando","pos":"NOM"} ,
		{"word":"glissando","word_nosc":"glissando","lemma":"glissando","pos":"NOM"} ,
		{"word":"glissandos","word_nosc":"glissandos","lemma":"glissando","pos":"NOM"} ,
		{"word":"glisse","word_nosc":"glisse","lemma":"glisse","pos":"NOM"} ,
		{"word":"glisse-la-moi","word_nosc":"glisse-la-moi","lemma":"glisse-la-moi","pos":"NOM"} ,
		{"word":"glissement","word_nosc":"glissement","lemma":"glissement","pos":"NOM"} ,
		{"word":"glissements","word_nosc":"glissements","lemma":"glissement","pos":"NOM"} ,
		{"word":"glisses","word_nosc":"glisses","lemma":"glisse","pos":"NOM"} ,
		{"word":"glissière","word_nosc":"glissiere","lemma":"glissière","pos":"NOM"} ,
		{"word":"glissières","word_nosc":"glissieres","lemma":"glissière","pos":"NOM"} ,
		{"word":"glissoire","word_nosc":"glissoire","lemma":"glissoire","pos":"NOM"} ,
		{"word":"globalisation","word_nosc":"globalisation","lemma":"globalisation","pos":"NOM"} ,
		{"word":"globalité","word_nosc":"globalite","lemma":"globalité","pos":"NOM"} ,
		{"word":"globe","word_nosc":"globe","lemma":"globe","pos":"NOM"} ,
		{"word":"globe-trotter","word_nosc":"globe-trotter","lemma":"globe-trotter","pos":"NOM"} ,
		{"word":"globe-trotters","word_nosc":"globe-trotters","lemma":"globe-trotter","pos":"NOM"} ,
		{"word":"globes","word_nosc":"globes","lemma":"globe","pos":"NOM"} ,
		{"word":"globule","word_nosc":"globule","lemma":"globule","pos":"NOM"} ,
		{"word":"globules","word_nosc":"globules","lemma":"globule","pos":"NOM"} ,
		{"word":"globuline","word_nosc":"globuline","lemma":"globuline","pos":"NOM"} ,
		{"word":"glockenspiel","word_nosc":"glockenspiel","lemma":"glockenspiel","pos":"NOM"} ,
		{"word":"gloire","word_nosc":"gloire","lemma":"gloire","pos":"NOM"} ,
		{"word":"gloires","word_nosc":"gloires","lemma":"gloire","pos":"NOM"} ,
		{"word":"glomérule","word_nosc":"glomerule","lemma":"glomérule","pos":"NOM"} ,
		{"word":"glop","word_nosc":"glop","lemma":"glop","pos":"NOM"} ,
		{"word":"gloria","word_nosc":"gloria","lemma":"gloria","pos":"NOM"} ,
		{"word":"gloriette","word_nosc":"gloriette","lemma":"gloriette","pos":"NOM"} ,
		{"word":"gloriettes","word_nosc":"gloriettes","lemma":"gloriette","pos":"NOM"} ,
		{"word":"glorieuse","word_nosc":"glorieuse","lemma":"glorieuse","pos":"NOM"} ,
		{"word":"glorieuses","word_nosc":"glorieuses","lemma":"glorieux","pos":"NOM"} ,
		{"word":"glorification","word_nosc":"glorification","lemma":"glorification","pos":"NOM"} ,
		{"word":"gloriole","word_nosc":"gloriole","lemma":"gloriole","pos":"NOM"} ,
		{"word":"glose","word_nosc":"glose","lemma":"glose","pos":"NOM"} ,
		{"word":"gloses","word_nosc":"gloses","lemma":"glose","pos":"NOM"} ,
		{"word":"glossaire","word_nosc":"glossaire","lemma":"glossaire","pos":"NOM"} ,
		{"word":"glossateurs","word_nosc":"glossateurs","lemma":"glossateur","pos":"NOM"} ,
		{"word":"glossématique","word_nosc":"glossematique","lemma":"glossématique","pos":"NOM"} ,
		{"word":"glotte","word_nosc":"glotte","lemma":"glotte","pos":"NOM"} ,
		{"word":"glouglou","word_nosc":"glouglou","lemma":"glouglou","pos":"NOM"} ,
		{"word":"glouglous","word_nosc":"glouglous","lemma":"glouglou","pos":"NOM"} ,
		{"word":"glougloutement","word_nosc":"glougloutement","lemma":"glougloutement","pos":"NOM"} ,
		{"word":"gloussement","word_nosc":"gloussement","lemma":"gloussement","pos":"NOM"} ,
		{"word":"gloussements","word_nosc":"gloussements","lemma":"gloussement","pos":"NOM"} ,
		{"word":"glouton","word_nosc":"glouton","lemma":"glouton","pos":"NOM"} ,
		{"word":"gloutonne","word_nosc":"gloutonne","lemma":"glouton","pos":"NOM"} ,
		{"word":"gloutonnerie","word_nosc":"gloutonnerie","lemma":"gloutonnerie","pos":"NOM"} ,
		{"word":"gloutons","word_nosc":"gloutons","lemma":"glouton","pos":"NOM"} ,
		{"word":"gloxinias","word_nosc":"gloxinias","lemma":"gloxinia","pos":"NOM"} ,
		{"word":"glu","word_nosc":"glu","lemma":"glu","pos":"NOM"} ,
		{"word":"glucagon","word_nosc":"glucagon","lemma":"glucagon","pos":"NOM"} ,
		{"word":"glucide","word_nosc":"glucide","lemma":"glucide","pos":"NOM"} ,
		{"word":"glucides","word_nosc":"glucides","lemma":"glucide","pos":"NOM"} ,
		{"word":"glucose","word_nosc":"glucose","lemma":"glucose","pos":"NOM"} ,
		{"word":"gluon","word_nosc":"gluon","lemma":"gluon","pos":"NOM"} ,
		{"word":"glus","word_nosc":"glus","lemma":"glu","pos":"NOM"} ,
		{"word":"glutamate","word_nosc":"glutamate","lemma":"glutamate","pos":"NOM"} ,
		{"word":"gluten","word_nosc":"gluten","lemma":"gluten","pos":"NOM"} ,
		{"word":"glycine","word_nosc":"glycine","lemma":"glycine","pos":"NOM"} ,
		{"word":"glycines","word_nosc":"glycines","lemma":"glycine","pos":"NOM"} ,
		{"word":"glycol","word_nosc":"glycol","lemma":"glycol","pos":"NOM"} ,
		{"word":"glycoprotéine","word_nosc":"glycoproteine","lemma":"glycoprotéine","pos":"NOM"} ,
		{"word":"glycémie","word_nosc":"glycemie","lemma":"glycémie","pos":"NOM"} ,
		{"word":"glycérine","word_nosc":"glycerine","lemma":"glycérine","pos":"NOM"} ,
		{"word":"glycérol","word_nosc":"glycerol","lemma":"glycérol","pos":"NOM"} ,
		{"word":"glyphe","word_nosc":"glyphe","lemma":"glyphe","pos":"NOM"} ,
		{"word":"glyphes","word_nosc":"glyphes","lemma":"glyphe","pos":"NOM"} ,
		{"word":"glèbe","word_nosc":"glebe","lemma":"glèbe","pos":"NOM"} ,
		{"word":"glèbes","word_nosc":"glebes","lemma":"glèbe","pos":"NOM"} ,
		{"word":"glène","word_nosc":"glene","lemma":"glène","pos":"NOM"} ,
		{"word":"gnafron","word_nosc":"gnafron","lemma":"gnafron","pos":"NOM"} ,
		{"word":"gnafrons","word_nosc":"gnafrons","lemma":"gnafron","pos":"NOM"} ,
		{"word":"gnard","word_nosc":"gnard","lemma":"gnard","pos":"NOM"} ,
		{"word":"gnards","word_nosc":"gnards","lemma":"gnard","pos":"NOM"} ,
		{"word":"gnaule","word_nosc":"gnaule","lemma":"gnaule","pos":"NOM"} ,
		{"word":"gnian-gnian","word_nosc":"gnian-gnian","lemma":"gnian-gnian","pos":"NOM"} ,
		{"word":"gniard","word_nosc":"gniard","lemma":"gniard","pos":"NOM"} ,
		{"word":"gniards","word_nosc":"gniards","lemma":"gniard","pos":"NOM"} ,
		{"word":"gniouf","word_nosc":"gniouf","lemma":"gniouf","pos":"NOM"} ,
		{"word":"gnocchi","word_nosc":"gnocchi","lemma":"gnocchi","pos":"NOM"} ,
		{"word":"gnocchis","word_nosc":"gnocchis","lemma":"gnocchi","pos":"NOM"} ,
		{"word":"gnognote","word_nosc":"gnognote","lemma":"gnognote","pos":"NOM"} ,
		{"word":"gnognotte","word_nosc":"gnognotte","lemma":"gnognotte","pos":"NOM"} ,
		{"word":"gnole","word_nosc":"gnole","lemma":"gnole","pos":"NOM"} ,
		{"word":"gnome","word_nosc":"gnome","lemma":"gnome","pos":"NOM"} ,
		{"word":"gnomes","word_nosc":"gnomes","lemma":"gnome","pos":"NOM"} ,
		{"word":"gnon","word_nosc":"gnon","lemma":"gnon","pos":"NOM"} ,
		{"word":"gnons","word_nosc":"gnons","lemma":"gnon","pos":"NOM"} ,
		{"word":"gnose","word_nosc":"gnose","lemma":"gnose","pos":"NOM"} ,
		{"word":"gnosticisme","word_nosc":"gnosticisme","lemma":"gnosticisme","pos":"NOM"} ,
		{"word":"gnostique","word_nosc":"gnostique","lemma":"gnostique","pos":"NOM"} ,
		{"word":"gnostiques","word_nosc":"gnostiques","lemma":"gnostique","pos":"NOM"} ,
		{"word":"gnou","word_nosc":"gnou","lemma":"gnou","pos":"NOM"} ,
		{"word":"gnouf","word_nosc":"gnouf","lemma":"gnouf","pos":"NOM"} ,
		{"word":"gnous","word_nosc":"gnous","lemma":"gnou","pos":"NOM"} ,
		{"word":"gnôle","word_nosc":"gnole","lemma":"gnôle","pos":"NOM"} ,
		{"word":"go","word_nosc":"go","lemma":"go","pos":"NOM"} ,
		{"word":"goal","word_nosc":"goal","lemma":"goal","pos":"NOM"} ,
		{"word":"goals","word_nosc":"goals","lemma":"goal","pos":"NOM"} ,
		{"word":"gobage","word_nosc":"gobage","lemma":"gobage","pos":"NOM"} ,
		{"word":"gobages","word_nosc":"gobages","lemma":"gobage","pos":"NOM"} ,
		{"word":"gobe-mouches","word_nosc":"gobe-mouches","lemma":"gobe-mouches","pos":"NOM"} ,
		{"word":"gobelet","word_nosc":"gobelet","lemma":"gobelet","pos":"NOM"} ,
		{"word":"gobelets","word_nosc":"gobelets","lemma":"gobelet","pos":"NOM"} ,
		{"word":"gobelin","word_nosc":"gobelin","lemma":"gobelin","pos":"NOM"} ,
		{"word":"gobelins","word_nosc":"gobelins","lemma":"gobelin","pos":"NOM"} ,
		{"word":"gobet","word_nosc":"gobet","lemma":"gobet","pos":"NOM"} ,
		{"word":"gobets","word_nosc":"gobets","lemma":"gobet","pos":"NOM"} ,
		{"word":"gobeur","word_nosc":"gobeur","lemma":"gobeur","pos":"NOM"} ,
		{"word":"gobeurs","word_nosc":"gobeurs","lemma":"gobeur","pos":"NOM"} ,
		{"word":"gobeuse","word_nosc":"gobeuse","lemma":"gobeur","pos":"NOM"} ,
		{"word":"godasse","word_nosc":"godasse","lemma":"godasse","pos":"NOM"} ,
		{"word":"godasses","word_nosc":"godasses","lemma":"godasse","pos":"NOM"} ,
		{"word":"goddam","word_nosc":"goddam","lemma":"goddam","pos":"NOM"} ,
		{"word":"godelureau","word_nosc":"godelureau","lemma":"godelureau","pos":"NOM"} ,
		{"word":"godelureaux","word_nosc":"godelureaux","lemma":"godelureau","pos":"NOM"} ,
		{"word":"godemiché","word_nosc":"godemiche","lemma":"godemiché","pos":"NOM"} ,
		{"word":"godemichés","word_nosc":"godemiches","lemma":"godemiché","pos":"NOM"} ,
		{"word":"godet","word_nosc":"godet","lemma":"godet","pos":"NOM"} ,
		{"word":"godets","word_nosc":"godets","lemma":"godet","pos":"NOM"} ,
		{"word":"godiche","word_nosc":"godiche","lemma":"godiche","pos":"NOM"} ,
		{"word":"godiches","word_nosc":"godiches","lemma":"godiche","pos":"NOM"} ,
		{"word":"godille","word_nosc":"godille","lemma":"godille","pos":"NOM"} ,
		{"word":"godilles","word_nosc":"godilles","lemma":"godille","pos":"NOM"} ,
		{"word":"godilleur","word_nosc":"godilleur","lemma":"godilleur","pos":"NOM"} ,
		{"word":"godillot","word_nosc":"godillot","lemma":"godillot","pos":"NOM"} ,
		{"word":"godillots","word_nosc":"godillots","lemma":"godillot","pos":"NOM"} ,
		{"word":"godrons","word_nosc":"godrons","lemma":"godron","pos":"NOM"} ,
		{"word":"goglu","word_nosc":"goglu","lemma":"goglu","pos":"NOM"} ,
		{"word":"gogo","word_nosc":"gogo","lemma":"gogo","pos":"NOM"} ,
		{"word":"gogos","word_nosc":"gogos","lemma":"gogo","pos":"NOM"} ,
		{"word":"gogs","word_nosc":"gogs","lemma":"gog","pos":"NOM"} ,
		{"word":"goguenots","word_nosc":"goguenots","lemma":"goguenot","pos":"NOM"} ,
		{"word":"gogues","word_nosc":"gogues","lemma":"gogues","pos":"NOM"} ,
		{"word":"goguette","word_nosc":"goguette","lemma":"goguette","pos":"NOM"} ,
		{"word":"goguettes","word_nosc":"goguettes","lemma":"goguette","pos":"NOM"} ,
		{"word":"goinfre","word_nosc":"goinfre","lemma":"goinfre","pos":"NOM"} ,
		{"word":"goinfrerie","word_nosc":"goinfrerie","lemma":"goinfrerie","pos":"NOM"} ,
		{"word":"goinfres","word_nosc":"goinfres","lemma":"goinfre","pos":"NOM"} ,
		{"word":"goitre","word_nosc":"goitre","lemma":"goitre","pos":"NOM"} ,
		{"word":"goitres","word_nosc":"goitres","lemma":"goitre","pos":"NOM"} ,
		{"word":"goitreux","word_nosc":"goitreux","lemma":"goitreux","pos":"NOM"} ,
		{"word":"golden","word_nosc":"golden","lemma":"golden","pos":"NOM"} ,
		{"word":"goldens","word_nosc":"goldens","lemma":"golden","pos":"NOM"} ,
		{"word":"goldo","word_nosc":"goldo","lemma":"goldo","pos":"NOM"} ,
		{"word":"goldos","word_nosc":"goldos","lemma":"goldo","pos":"NOM"} ,
		{"word":"golem","word_nosc":"golem","lemma":"golem","pos":"NOM"} ,
		{"word":"golf","word_nosc":"golf","lemma":"golf","pos":"NOM"} ,
		{"word":"golf-club","word_nosc":"golf-club","lemma":"golf-club","pos":"NOM"} ,
		{"word":"golfe","word_nosc":"golfe","lemma":"golfe","pos":"NOM"} ,
		{"word":"golfes","word_nosc":"golfes","lemma":"golfe","pos":"NOM"} ,
		{"word":"golfeur","word_nosc":"golfeur","lemma":"golfeur","pos":"NOM"} ,
		{"word":"golfeurs","word_nosc":"golfeurs","lemma":"golfeur","pos":"NOM"} ,
		{"word":"golfeuse","word_nosc":"golfeuse","lemma":"golfeur","pos":"NOM"} ,
		{"word":"golfeuses","word_nosc":"golfeuses","lemma":"golfeur","pos":"NOM"} ,
		{"word":"golfs","word_nosc":"golfs","lemma":"golf","pos":"NOM"} ,
		{"word":"golgotha","word_nosc":"golgotha","lemma":"golgotha","pos":"NOM"} ,
		{"word":"goliath","word_nosc":"goliath","lemma":"goliath","pos":"NOM"} ,
		{"word":"gombo","word_nosc":"gombo","lemma":"gombo","pos":"NOM"} ,
		{"word":"gombos","word_nosc":"gombos","lemma":"gombo","pos":"NOM"} ,
		{"word":"gomina","word_nosc":"gomina","lemma":"gomina","pos":"NOM"} ,
		{"word":"gommage","word_nosc":"gommage","lemma":"gommage","pos":"NOM"} ,
		{"word":"gomme","word_nosc":"gomme","lemma":"gomme","pos":"NOM"} ,
		{"word":"gommes","word_nosc":"gommes","lemma":"gomme","pos":"NOM"} ,
		{"word":"gommette","word_nosc":"gommette","lemma":"gommette","pos":"NOM"} ,
		{"word":"gommeux","word_nosc":"gommeux","lemma":"gommeux","pos":"NOM"} ,
		{"word":"gommier","word_nosc":"gommier","lemma":"gommier","pos":"NOM"} ,
		{"word":"gommiers","word_nosc":"gommiers","lemma":"gommier","pos":"NOM"} ,
		{"word":"goménol","word_nosc":"gomenol","lemma":"goménol","pos":"NOM"} ,
		{"word":"gon","word_nosc":"gon","lemma":"gon","pos":"NOM"} ,
		{"word":"gonade","word_nosc":"gonade","lemma":"gonade","pos":"NOM"} ,
		{"word":"gonades","word_nosc":"gonades","lemma":"gonade","pos":"NOM"} ,
		{"word":"gonadotrophine","word_nosc":"gonadotrophine","lemma":"gonadotrophine","pos":"NOM"} ,
		{"word":"goncier","word_nosc":"goncier","lemma":"goncier","pos":"NOM"} ,
		{"word":"gond","word_nosc":"gond","lemma":"gond","pos":"NOM"} ,
		{"word":"gondole","word_nosc":"gondole","lemma":"gondole","pos":"NOM"} ,
		{"word":"gondolement","word_nosc":"gondolement","lemma":"gondolement","pos":"NOM"} ,
		{"word":"gondoles","word_nosc":"gondoles","lemma":"gondole","pos":"NOM"} ,
		{"word":"gondolier","word_nosc":"gondolier","lemma":"gondolier","pos":"NOM"} ,
		{"word":"gondoliers","word_nosc":"gondoliers","lemma":"gondolier","pos":"NOM"} ,
		{"word":"gondolière","word_nosc":"gondoliere","lemma":"gondolier","pos":"NOM"} ,
		{"word":"gonds","word_nosc":"gonds","lemma":"gond","pos":"NOM"} ,
		{"word":"gone","word_nosc":"gone","lemma":"gone","pos":"NOM"} ,
		{"word":"gonelle","word_nosc":"gonelle","lemma":"gonelle","pos":"NOM"} ,
		{"word":"gonfalon","word_nosc":"gonfalon","lemma":"gonfalon","pos":"NOM"} ,
		{"word":"gonfaloniers","word_nosc":"gonfaloniers","lemma":"gonfalonier","pos":"NOM"} ,
		{"word":"gonfalons","word_nosc":"gonfalons","lemma":"gonfalon","pos":"NOM"} ,
		{"word":"gonfanon","word_nosc":"gonfanon","lemma":"gonfanon","pos":"NOM"} ,
		{"word":"gonfanons","word_nosc":"gonfanons","lemma":"gonfanon","pos":"NOM"} ,
		{"word":"gonflage","word_nosc":"gonflage","lemma":"gonflage","pos":"NOM"} ,
		{"word":"gonfle","word_nosc":"gonfle","lemma":"gonfle","pos":"NOM"} ,
		{"word":"gonflement","word_nosc":"gonflement","lemma":"gonflement","pos":"NOM"} ,
		{"word":"gonflements","word_nosc":"gonflements","lemma":"gonflement","pos":"NOM"} ,
		{"word":"gonfles","word_nosc":"gonfles","lemma":"gonfle","pos":"NOM"} ,
		{"word":"gonflette","word_nosc":"gonflette","lemma":"gonflette","pos":"NOM"} ,
		{"word":"gonfleur","word_nosc":"gonfleur","lemma":"gonfleur","pos":"NOM"} ,
		{"word":"gong","word_nosc":"gong","lemma":"gong","pos":"NOM"} ,
		{"word":"gongs","word_nosc":"gongs","lemma":"gong","pos":"NOM"} ,
		{"word":"goniomètre","word_nosc":"goniometre","lemma":"goniomètre","pos":"NOM"} ,
		{"word":"gonococcie","word_nosc":"gonococcie","lemma":"gonococcie","pos":"NOM"} ,
		{"word":"gonocoque","word_nosc":"gonocoque","lemma":"gonocoque","pos":"NOM"} ,
		{"word":"gonocoques","word_nosc":"gonocoques","lemma":"gonocoque","pos":"NOM"} ,
		{"word":"gonorrhée","word_nosc":"gonorrhee","lemma":"gonorrhée","pos":"NOM"} ,
		{"word":"gonze","word_nosc":"gonze","lemma":"gonze","pos":"NOM"} ,
		{"word":"gonzes","word_nosc":"gonzes","lemma":"gonze","pos":"NOM"} ,
		{"word":"gonzesse","word_nosc":"gonzesse","lemma":"gonzesse","pos":"NOM"} ,
		{"word":"gonzesses","word_nosc":"gonzesses","lemma":"gonzesse","pos":"NOM"} ,
		{"word":"gopak","word_nosc":"gopak","lemma":"gopak","pos":"NOM"} ,
		{"word":"gopher","word_nosc":"gopher","lemma":"gopher","pos":"NOM"} ,
		{"word":"gord","word_nosc":"gord","lemma":"gord","pos":"NOM"} ,
		{"word":"gore","word_nosc":"gore","lemma":"gore","pos":"NOM"} ,
		{"word":"goret","word_nosc":"goret","lemma":"goret","pos":"NOM"} ,
		{"word":"gorets","word_nosc":"gorets","lemma":"goret","pos":"NOM"} ,
		{"word":"gorge","word_nosc":"gorge","lemma":"gorge","pos":"NOM"} ,
		{"word":"gorgeon","word_nosc":"gorgeon","lemma":"gorgeon","pos":"NOM"} ,
		{"word":"gorgeons","word_nosc":"gorgeons","lemma":"gorgeon","pos":"NOM"} ,
		{"word":"gorgerette","word_nosc":"gorgerette","lemma":"gorgerette","pos":"NOM"} ,
		{"word":"gorgerin","word_nosc":"gorgerin","lemma":"gorgerin","pos":"NOM"} ,
		{"word":"gorges","word_nosc":"gorges","lemma":"gorge","pos":"NOM"} ,
		{"word":"gorget","word_nosc":"gorget","lemma":"gorget","pos":"NOM"} ,
		{"word":"gorgone","word_nosc":"gorgone","lemma":"gorgone","pos":"NOM"} ,
		{"word":"gorgonzola","word_nosc":"gorgonzola","lemma":"gorgonzola","pos":"NOM"} ,
		{"word":"gorgée","word_nosc":"gorgee","lemma":"gorgée","pos":"NOM"} ,
		{"word":"gorgées","word_nosc":"gorgees","lemma":"gorgée","pos":"NOM"} ,
		{"word":"gorille","word_nosc":"gorille","lemma":"gorille","pos":"NOM"} ,
		{"word":"gorilles","word_nosc":"gorilles","lemma":"gorille","pos":"NOM"} ,
		{"word":"gosier","word_nosc":"gosier","lemma":"gosier","pos":"NOM"} ,
		{"word":"gosiers","word_nosc":"gosiers","lemma":"gosier","pos":"NOM"} ,
		{"word":"gospel","word_nosc":"gospel","lemma":"gospel","pos":"NOM"} ,
		{"word":"gosplan","word_nosc":"gosplan","lemma":"gosplan","pos":"NOM"} ,
		{"word":"gosse","word_nosc":"gosse","lemma":"gosse","pos":"NOM"} ,
		{"word":"gosseline","word_nosc":"gosseline","lemma":"gosseline","pos":"NOM"} ,
		{"word":"gosselines","word_nosc":"gosselines","lemma":"gosseline","pos":"NOM"} ,
		{"word":"gosses","word_nosc":"gosses","lemma":"gosse","pos":"NOM"} ,
		{"word":"goth","word_nosc":"goth","lemma":"goth","pos":"NOM"} ,
		{"word":"gotha","word_nosc":"gotha","lemma":"gotha","pos":"NOM"} ,
		{"word":"gothas","word_nosc":"gothas","lemma":"gotha","pos":"NOM"} ,
		{"word":"gothique","word_nosc":"gothique","lemma":"gothique","pos":"NOM"} ,
		{"word":"gothiques","word_nosc":"gothiques","lemma":"gothique","pos":"NOM"} ,
		{"word":"goths","word_nosc":"goths","lemma":"goth","pos":"NOM"} ,
		{"word":"goton","word_nosc":"goton","lemma":"goton","pos":"NOM"} ,
		{"word":"gouache","word_nosc":"gouache","lemma":"gouache","pos":"NOM"} ,
		{"word":"gouaches","word_nosc":"gouaches","lemma":"gouache","pos":"NOM"} ,
		{"word":"gouaille","word_nosc":"gouaille","lemma":"gouaille","pos":"NOM"} ,
		{"word":"goualante","word_nosc":"goualante","lemma":"goualante","pos":"NOM"} ,
		{"word":"goualantes","word_nosc":"goualantes","lemma":"goualante","pos":"NOM"} ,
		{"word":"gouape","word_nosc":"gouape","lemma":"gouape","pos":"NOM"} ,
		{"word":"gouapes","word_nosc":"gouapes","lemma":"gouape","pos":"NOM"} ,
		{"word":"gouda","word_nosc":"gouda","lemma":"gouda","pos":"NOM"} ,
		{"word":"goudou","word_nosc":"goudou","lemma":"goudou","pos":"NOM"} ,
		{"word":"goudous","word_nosc":"goudous","lemma":"goudou","pos":"NOM"} ,
		{"word":"goudron","word_nosc":"goudron","lemma":"goudron","pos":"NOM"} ,
		{"word":"goudronnage","word_nosc":"goudronnage","lemma":"goudronnage","pos":"NOM"} ,
		{"word":"goudrons","word_nosc":"goudrons","lemma":"goudron","pos":"NOM"} ,
		{"word":"gouet","word_nosc":"gouet","lemma":"gouet","pos":"NOM"} ,
		{"word":"gouffre","word_nosc":"gouffre","lemma":"gouffre","pos":"NOM"} ,
		{"word":"gouffres","word_nosc":"gouffres","lemma":"gouffre","pos":"NOM"} ,
		{"word":"gouge","word_nosc":"gouge","lemma":"gouge","pos":"NOM"} ,
		{"word":"gouges","word_nosc":"gouges","lemma":"gouge","pos":"NOM"} ,
		{"word":"gougnafier","word_nosc":"gougnafier","lemma":"gougnafier","pos":"NOM"} ,
		{"word":"gougnafiers","word_nosc":"gougnafiers","lemma":"gougnafier","pos":"NOM"} ,
		{"word":"gougoutte","word_nosc":"gougoutte","lemma":"gougoutte","pos":"NOM"} ,
		{"word":"gougouttes","word_nosc":"gougouttes","lemma":"gougoutte","pos":"NOM"} ,
		{"word":"gougère","word_nosc":"gougere","lemma":"gougère","pos":"NOM"} ,
		{"word":"gougères","word_nosc":"gougeres","lemma":"gougère","pos":"NOM"} ,
		{"word":"gouine","word_nosc":"gouine","lemma":"gouin","pos":"NOM"} ,
		{"word":"gouine","word_nosc":"gouine","lemma":"gouine","pos":"NOM"} ,
		{"word":"gouines","word_nosc":"gouines","lemma":"gouine","pos":"NOM"} ,
		{"word":"goujat","word_nosc":"goujat","lemma":"goujat","pos":"NOM"} ,
		{"word":"goujaterie","word_nosc":"goujaterie","lemma":"goujaterie","pos":"NOM"} ,
		{"word":"goujats","word_nosc":"goujats","lemma":"goujat","pos":"NOM"} ,
		{"word":"goujon","word_nosc":"goujon","lemma":"goujon","pos":"NOM"} ,
		{"word":"goujons","word_nosc":"goujons","lemma":"goujon","pos":"NOM"} ,
		{"word":"goulache","word_nosc":"goulache","lemma":"goulache","pos":"NOM"} ,
		{"word":"goulafre","word_nosc":"goulafre","lemma":"goulafre","pos":"NOM"} ,
		{"word":"goulafres","word_nosc":"goulafres","lemma":"goulafre","pos":"NOM"} ,
		{"word":"goulag","word_nosc":"goulag","lemma":"goulag","pos":"NOM"} ,
		{"word":"goulags","word_nosc":"goulags","lemma":"goulag","pos":"NOM"} ,
		{"word":"goulasch","word_nosc":"goulasch","lemma":"goulasch","pos":"NOM"} ,
		{"word":"goule","word_nosc":"goule","lemma":"goule","pos":"NOM"} ,
		{"word":"goules","word_nosc":"goules","lemma":"goule","pos":"NOM"} ,
		{"word":"goulet","word_nosc":"goulet","lemma":"goulet","pos":"NOM"} ,
		{"word":"goulette","word_nosc":"goulette","lemma":"goulette","pos":"NOM"} ,
		{"word":"goulot","word_nosc":"goulot","lemma":"goulot","pos":"NOM"} ,
		{"word":"goulots","word_nosc":"goulots","lemma":"goulot","pos":"NOM"} ,
		{"word":"goulotte","word_nosc":"goulotte","lemma":"goulotte","pos":"NOM"} ,
		{"word":"goulu","word_nosc":"goulu","lemma":"goulu","pos":"NOM"} ,
		{"word":"goulues","word_nosc":"goulues","lemma":"goulu","pos":"NOM"} ,
		{"word":"goulus","word_nosc":"goulus","lemma":"goulu","pos":"NOM"} ,
		{"word":"goulée","word_nosc":"goulee","lemma":"goulée","pos":"NOM"} ,
		{"word":"goulées","word_nosc":"goulees","lemma":"goulée","pos":"NOM"} ,
		{"word":"goum","word_nosc":"goum","lemma":"goum","pos":"NOM"} ,
		{"word":"goumi","word_nosc":"goumi","lemma":"goumi","pos":"NOM"} ,
		{"word":"goumier","word_nosc":"goumier","lemma":"goumier","pos":"NOM"} ,
		{"word":"goumiers","word_nosc":"goumiers","lemma":"goumier","pos":"NOM"} ,
		{"word":"goums","word_nosc":"goums","lemma":"goum","pos":"NOM"} ,
		{"word":"goupil","word_nosc":"goupil","lemma":"goupil","pos":"NOM"} ,
		{"word":"goupille","word_nosc":"goupille","lemma":"goupille","pos":"NOM"} ,
		{"word":"goupilles","word_nosc":"goupilles","lemma":"goupille","pos":"NOM"} ,
		{"word":"goupillon","word_nosc":"goupillon","lemma":"goupillon","pos":"NOM"} ,
		{"word":"goupillons","word_nosc":"goupillons","lemma":"goupillon","pos":"NOM"} ,
		{"word":"gour","word_nosc":"gour","lemma":"gour","pos":"NOM"} ,
		{"word":"goura","word_nosc":"goura","lemma":"goura","pos":"NOM"} ,
		{"word":"gourance","word_nosc":"gourance","lemma":"gourance","pos":"NOM"} ,
		{"word":"gourances","word_nosc":"gourances","lemma":"gourance","pos":"NOM"} ,
		{"word":"gourbi","word_nosc":"gourbi","lemma":"gourbi","pos":"NOM"} ,
		{"word":"gourbis","word_nosc":"gourbis","lemma":"gourbi","pos":"NOM"} ,
		{"word":"gourde","word_nosc":"gourde","lemma":"gourde","pos":"NOM"} ,
		{"word":"gourdes","word_nosc":"gourdes","lemma":"gourd","pos":"NOM"} ,
		{"word":"gourdes","word_nosc":"gourdes","lemma":"gourde","pos":"NOM"} ,
		{"word":"gourdin","word_nosc":"gourdin","lemma":"gourdin","pos":"NOM"} ,
		{"word":"gourdins","word_nosc":"gourdins","lemma":"gourdin","pos":"NOM"} ,
		{"word":"gourgandine","word_nosc":"gourgandine","lemma":"gourgandine","pos":"NOM"} ,
		{"word":"gourgandines","word_nosc":"gourgandines","lemma":"gourgandine","pos":"NOM"} ,
		{"word":"gourmand","word_nosc":"gourmand","lemma":"gourmand","pos":"NOM"} ,
		{"word":"gourmande","word_nosc":"gourmande","lemma":"gourmand","pos":"NOM"} ,
		{"word":"gourmandes","word_nosc":"gourmandes","lemma":"gourmand","pos":"NOM"} ,
		{"word":"gourmandise","word_nosc":"gourmandise","lemma":"gourmandise","pos":"NOM"} ,
		{"word":"gourmandises","word_nosc":"gourmandises","lemma":"gourmandise","pos":"NOM"} ,
		{"word":"gourmands","word_nosc":"gourmands","lemma":"gourmand","pos":"NOM"} ,
		{"word":"gourme","word_nosc":"gourme","lemma":"gourme","pos":"NOM"} ,
		{"word":"gourmet","word_nosc":"gourmet","lemma":"gourmet","pos":"NOM"} ,
		{"word":"gourmets","word_nosc":"gourmets","lemma":"gourmet","pos":"NOM"} ,
		{"word":"gourmette","word_nosc":"gourmette","lemma":"gourmette","pos":"NOM"} ,
		{"word":"gourmettes","word_nosc":"gourmettes","lemma":"gourmette","pos":"NOM"} ,
		{"word":"gourou","word_nosc":"gourou","lemma":"gourou","pos":"NOM"} ,
		{"word":"gourous","word_nosc":"gourous","lemma":"gourou","pos":"NOM"} ,
		{"word":"gours","word_nosc":"gours","lemma":"gour","pos":"NOM"} ,
		{"word":"gousse","word_nosc":"gousse","lemma":"gousse","pos":"NOM"} ,
		{"word":"gousses","word_nosc":"gousses","lemma":"gousse","pos":"NOM"} ,
		{"word":"gousset","word_nosc":"gousset","lemma":"gousset","pos":"NOM"} ,
		{"word":"goussets","word_nosc":"goussets","lemma":"gousset","pos":"NOM"} ,
		{"word":"goutte","word_nosc":"goutte","lemma":"goutte","pos":"NOM"} ,
		{"word":"gouttelette","word_nosc":"gouttelette","lemma":"gouttelette","pos":"NOM"} ,
		{"word":"gouttelettes","word_nosc":"gouttelettes","lemma":"gouttelette","pos":"NOM"} ,
		{"word":"gouttes","word_nosc":"gouttes","lemma":"goutte","pos":"NOM"} ,
		{"word":"goutteuse","word_nosc":"goutteuse","lemma":"goutteur","pos":"NOM"} ,
		{"word":"goutteux","word_nosc":"goutteux","lemma":"goutteux","pos":"NOM"} ,
		{"word":"gouttière","word_nosc":"gouttiere","lemma":"gouttière","pos":"NOM"} ,
		{"word":"gouttières","word_nosc":"gouttieres","lemma":"gouttière","pos":"NOM"} ,
		{"word":"gouvernail","word_nosc":"gouvernail","lemma":"gouvernail","pos":"NOM"} ,
		{"word":"gouvernails","word_nosc":"gouvernails","lemma":"gouvernail","pos":"NOM"} ,
		{"word":"gouvernance","word_nosc":"gouvernance","lemma":"gouvernance","pos":"NOM"} ,
		{"word":"gouvernant","word_nosc":"gouvernant","lemma":"gouvernant","pos":"NOM"} ,
		{"word":"gouvernante","word_nosc":"gouvernante","lemma":"gouvernante","pos":"NOM"} ,
		{"word":"gouvernantes","word_nosc":"gouvernantes","lemma":"gouvernante","pos":"NOM"} ,
		{"word":"gouvernants","word_nosc":"gouvernants","lemma":"gouvernant","pos":"NOM"} ,
		{"word":"gouverne","word_nosc":"gouverne","lemma":"gouverne","pos":"NOM"} ,
		{"word":"gouvernement","word_nosc":"gouvernement","lemma":"gouvernement","pos":"NOM"} ,
		{"word":"gouvernements","word_nosc":"gouvernements","lemma":"gouvernement","pos":"NOM"} ,
		{"word":"gouvernes","word_nosc":"gouvernes","lemma":"gouverne","pos":"NOM"} ,
		{"word":"gouverneur","word_nosc":"gouverneur","lemma":"gouverneur","pos":"NOM"} ,
		{"word":"gouverneurs","word_nosc":"gouverneurs","lemma":"gouverneur","pos":"NOM"} ,
		{"word":"gouvernorat","word_nosc":"gouvernorat","lemma":"gouvernorat","pos":"NOM"} ,
		{"word":"gouvernorats","word_nosc":"gouvernorats","lemma":"gouvernorat","pos":"NOM"} ,
		{"word":"gouvernés","word_nosc":"gouvernes","lemma":"gouverné","pos":"NOM"} ,
		{"word":"gouzi-gouzi","word_nosc":"gouzi-gouzi","lemma":"gouzi-gouzi","pos":"NOM"} ,
		{"word":"goy","word_nosc":"goy","lemma":"goy","pos":"NOM"} ,
		{"word":"goyave","word_nosc":"goyave","lemma":"goyave","pos":"NOM"} ,
		{"word":"goyesque","word_nosc":"goyesque","lemma":"goyesque","pos":"NOM"} ,
		{"word":"goyesques","word_nosc":"goyesques","lemma":"goyesque","pos":"NOM"} ,
		{"word":"goéland","word_nosc":"goeland","lemma":"goéland","pos":"NOM"} ,
		{"word":"goélands","word_nosc":"goelands","lemma":"goéland","pos":"NOM"} ,
		{"word":"goélette","word_nosc":"goelette","lemma":"goélette","pos":"NOM"} ,
		{"word":"goélettes","word_nosc":"goelettes","lemma":"goélette","pos":"NOM"} ,
		{"word":"goémon","word_nosc":"goemon","lemma":"goémon","pos":"NOM"} ,
		{"word":"goémons","word_nosc":"goemons","lemma":"goémon","pos":"NOM"} ,
		{"word":"goétie","word_nosc":"goetie","lemma":"goétie","pos":"NOM"} ,
		{"word":"goût","word_nosc":"gout","lemma":"goût","pos":"NOM"} ,
		{"word":"goûter","word_nosc":"gouter","lemma":"goûter","pos":"NOM"} ,
		{"word":"goûters","word_nosc":"gouters","lemma":"goûter","pos":"NOM"} ,
		{"word":"goûteur","word_nosc":"gouteur","lemma":"goûteur","pos":"NOM"} ,
		{"word":"goûteuse","word_nosc":"gouteuse","lemma":"goûteur","pos":"NOM"} ,
		{"word":"goûts","word_nosc":"gouts","lemma":"goût","pos":"NOM"} ,
		{"word":"graal","word_nosc":"graal","lemma":"graal","pos":"NOM"} ,
		{"word":"grabat","word_nosc":"grabat","lemma":"grabat","pos":"NOM"} ,
		{"word":"grabataire","word_nosc":"grabataire","lemma":"grabataire","pos":"NOM"} ,
		{"word":"grabataires","word_nosc":"grabataires","lemma":"grabataire","pos":"NOM"} ,
		{"word":"grabats","word_nosc":"grabats","lemma":"grabat","pos":"NOM"} ,
		{"word":"graben","word_nosc":"graben","lemma":"graben","pos":"NOM"} ,
		{"word":"grabuge","word_nosc":"grabuge","lemma":"grabuge","pos":"NOM"} ,
		{"word":"gracieuseté","word_nosc":"gracieusete","lemma":"gracieuseté","pos":"NOM"} ,
		{"word":"gracieusetés","word_nosc":"gracieusetes","lemma":"gracieuseté","pos":"NOM"} ,
		{"word":"gracilité","word_nosc":"gracilite","lemma":"gracilité","pos":"NOM"} ,
		{"word":"gradaille","word_nosc":"gradaille","lemma":"gradaille","pos":"NOM"} ,
		{"word":"gradation","word_nosc":"gradation","lemma":"gradation","pos":"NOM"} ,
		{"word":"gradations","word_nosc":"gradations","lemma":"gradation","pos":"NOM"} ,
		{"word":"grade","word_nosc":"grade","lemma":"grade","pos":"NOM"} ,
		{"word":"grader","word_nosc":"grader","lemma":"grader","pos":"NOM"} ,
		{"word":"grades","word_nosc":"grades","lemma":"grade","pos":"NOM"} ,
		{"word":"gradient","word_nosc":"gradient","lemma":"gradient","pos":"NOM"} ,
		{"word":"gradients","word_nosc":"gradients","lemma":"gradient","pos":"NOM"} ,
		{"word":"gradin","word_nosc":"gradin","lemma":"gradin","pos":"NOM"} ,
		{"word":"gradins","word_nosc":"gradins","lemma":"gradin","pos":"NOM"} ,
		{"word":"graduation","word_nosc":"graduation","lemma":"graduation","pos":"NOM"} ,
		{"word":"graduations","word_nosc":"graduations","lemma":"graduation","pos":"NOM"} ,
		{"word":"gradus","word_nosc":"gradus","lemma":"gradus","pos":"NOM"} ,
		{"word":"gradués","word_nosc":"gradues","lemma":"gradué","pos":"NOM"} ,
		{"word":"gradé","word_nosc":"grade","lemma":"gradé","pos":"NOM"} ,
		{"word":"gradés","word_nosc":"grades","lemma":"gradé","pos":"NOM"} ,
		{"word":"graff","word_nosc":"graff","lemma":"graff","pos":"NOM"} ,
		{"word":"graffeur","word_nosc":"graffeur","lemma":"graffeur","pos":"NOM"} ,
		{"word":"graffiteur","word_nosc":"graffiteur","lemma":"graffiteur","pos":"NOM"} ,
		{"word":"graffiteurs","word_nosc":"graffiteurs","lemma":"graffiteur","pos":"NOM"} ,
		{"word":"graffiti","word_nosc":"graffiti","lemma":"graffiti","pos":"NOM"} ,
		{"word":"graffitis","word_nosc":"graffitis","lemma":"graffiti","pos":"NOM"} ,
		{"word":"graffito","word_nosc":"graffito","lemma":"graffito","pos":"NOM"} ,
		{"word":"graillon","word_nosc":"graillon","lemma":"graillon","pos":"NOM"} ,
		{"word":"graillonnements","word_nosc":"graillonnements","lemma":"graillonnement","pos":"NOM"} ,
		{"word":"graillonneuse","word_nosc":"graillonneuse","lemma":"graillonneur","pos":"NOM"} ,
		{"word":"graillonneuses","word_nosc":"graillonneuses","lemma":"graillonneur","pos":"NOM"} ,
		{"word":"graillons","word_nosc":"graillons","lemma":"graillon","pos":"NOM"} ,
		{"word":"grain","word_nosc":"grain","lemma":"grain","pos":"NOM"} ,
		{"word":"graine","word_nosc":"graine","lemma":"graine","pos":"NOM"} ,
		{"word":"graines","word_nosc":"graines","lemma":"graine","pos":"NOM"} ,
		{"word":"graineterie","word_nosc":"graineterie","lemma":"graineterie","pos":"NOM"} ,
		{"word":"grainetier","word_nosc":"grainetier","lemma":"grainetier","pos":"NOM"} ,
		{"word":"grainetiers","word_nosc":"grainetiers","lemma":"grainetier","pos":"NOM"} ,
		{"word":"grains","word_nosc":"grains","lemma":"grain","pos":"NOM"} ,
		{"word":"graissage","word_nosc":"graissage","lemma":"graissage","pos":"NOM"} ,
		{"word":"graissages","word_nosc":"graissages","lemma":"graissage","pos":"NOM"} ,
		{"word":"graisse","word_nosc":"graisse","lemma":"graisse","pos":"NOM"} ,
		{"word":"graisses","word_nosc":"graisses","lemma":"graisse","pos":"NOM"} ,
		{"word":"gram","word_nosc":"gram","lemma":"gram","pos":"NOM"} ,
		{"word":"gramen","word_nosc":"gramen","lemma":"gramen","pos":"NOM"} ,
		{"word":"gramens","word_nosc":"gramens","lemma":"gramen","pos":"NOM"} ,
		{"word":"graminée","word_nosc":"graminee","lemma":"graminée","pos":"NOM"} ,
		{"word":"graminées","word_nosc":"graminees","lemma":"graminée","pos":"NOM"} ,
		{"word":"grammage","word_nosc":"grammage","lemma":"grammage","pos":"NOM"} ,
		{"word":"grammages","word_nosc":"grammages","lemma":"grammage","pos":"NOM"} ,
		{"word":"grammaire","word_nosc":"grammaire","lemma":"grammaire","pos":"NOM"} ,
		{"word":"grammaires","word_nosc":"grammaires","lemma":"grammaire","pos":"NOM"} ,
		{"word":"grammairien","word_nosc":"grammairien","lemma":"grammairien","pos":"NOM"} ,
		{"word":"grammairienne","word_nosc":"grammairienne","lemma":"grammairien","pos":"NOM"} ,
		{"word":"grammairiennes","word_nosc":"grammairiennes","lemma":"grammairien","pos":"NOM"} ,
		{"word":"grammairiens","word_nosc":"grammairiens","lemma":"grammairien","pos":"NOM"} ,
		{"word":"gramme","word_nosc":"gramme","lemma":"gramme","pos":"NOM"} ,
		{"word":"grammes","word_nosc":"grammes","lemma":"gramme","pos":"NOM"} ,
		{"word":"gramophone","word_nosc":"gramophone","lemma":"gramophone","pos":"NOM"} ,
		{"word":"gramophones","word_nosc":"gramophones","lemma":"gramophone","pos":"NOM"} ,
		{"word":"grana","word_nosc":"grana","lemma":"grana","pos":"NOM"} ,
		{"word":"granas","word_nosc":"granas","lemma":"grana","pos":"NOM"} ,
		{"word":"grand","word_nosc":"grand","lemma":"grand","pos":"NOM"} ,
		{"word":"grand-angle","word_nosc":"grand-angle","lemma":"grand-angle","pos":"NOM"} ,
		{"word":"grand-duc","word_nosc":"grand-duc","lemma":"grand-duc","pos":"NOM"} ,
		{"word":"grand-duché","word_nosc":"grand-duche","lemma":"grand-duché","pos":"NOM"} ,
		{"word":"grand-guignol","word_nosc":"grand-guignol","lemma":"grand-guignol","pos":"NOM"} ,
		{"word":"grand-hôtel","word_nosc":"grand-hotel","lemma":"grand-hôtel","pos":"NOM"} ,
		{"word":"grand-maman","word_nosc":"grand-maman","lemma":"grand-papa","pos":"NOM"} ,
		{"word":"grand-maître","word_nosc":"grand-maitre","lemma":"grand-maître","pos":"NOM"} ,
		{"word":"grand-messe","word_nosc":"grand-messe","lemma":"grand-messe","pos":"NOM"} ,
		{"word":"grand-mère","word_nosc":"grand-mere","lemma":"grand-mère","pos":"NOM"} ,
		{"word":"grand-mères","word_nosc":"grand-meres","lemma":"grand-mère","pos":"NOM"} ,
		{"word":"grand-neige","word_nosc":"grand-neige","lemma":"grand-neige","pos":"NOM"} ,
		{"word":"grand-officier","word_nosc":"grand-officier","lemma":"grand-officier","pos":"NOM"} ,
		{"word":"grand-oncle","word_nosc":"grand-oncle","lemma":"grand-oncle","pos":"NOM"} ,
		{"word":"grand-papa","word_nosc":"grand-papa","lemma":"grand-papa","pos":"NOM"} ,
		{"word":"grand-place","word_nosc":"grand-place","lemma":"grand-place","pos":"NOM"} ,
		{"word":"grand-prêtre","word_nosc":"grand-pretre","lemma":"grand-prêtre","pos":"NOM"} ,
		{"word":"grand-père","word_nosc":"grand-pere","lemma":"grand-père","pos":"NOM"} ,
		{"word":"grand-quartier","word_nosc":"grand-quartier","lemma":"grand-quartier","pos":"NOM"} ,
		{"word":"grand-route","word_nosc":"grand-route","lemma":"grand-route","pos":"NOM"} ,
		{"word":"grand-routes","word_nosc":"grand-routes","lemma":"grand-route","pos":"NOM"} ,
		{"word":"grand-rue","word_nosc":"grand-rue","lemma":"grand-rue","pos":"NOM"} ,
		{"word":"grand-salle","word_nosc":"grand-salle","lemma":"grand-salle","pos":"NOM"} ,
		{"word":"grand-tante","word_nosc":"grand-tante","lemma":"grand-tante","pos":"NOM"} ,
		{"word":"grand-tantes","word_nosc":"grand-tantes","lemma":"grand-tante","pos":"NOM"} ,
		{"word":"grand-vergue","word_nosc":"grand-vergue","lemma":"grand-vergue","pos":"NOM"} ,
		{"word":"grand-voile","word_nosc":"grand-voile","lemma":"grand-voile","pos":"NOM"} ,
		{"word":"grande","word_nosc":"grande","lemma":"grand","pos":"NOM"} ,
		{"word":"grande-duchesse","word_nosc":"grande-duchesse","lemma":"grand-duc","pos":"NOM"} ,
		{"word":"grandes","word_nosc":"grandes","lemma":"grand","pos":"NOM"} ,
		{"word":"grandes-duchesses","word_nosc":"grandes-duchesses","lemma":"grand-duc","pos":"NOM"} ,
		{"word":"grandesse","word_nosc":"grandesse","lemma":"grandesse","pos":"NOM"} ,
		{"word":"grandeur","word_nosc":"grandeur","lemma":"grandeur","pos":"NOM"} ,
		{"word":"grandeurs","word_nosc":"grandeurs","lemma":"grandeur","pos":"NOM"} ,
		{"word":"grandiloquence","word_nosc":"grandiloquence","lemma":"grandiloquence","pos":"NOM"} ,
		{"word":"grandissement","word_nosc":"grandissement","lemma":"grandissement","pos":"NOM"} ,
		{"word":"grands","word_nosc":"grands","lemma":"grand","pos":"NOM"} ,
		{"word":"grands-croix","word_nosc":"grands-croix","lemma":"grand-croix","pos":"NOM"} ,
		{"word":"grands-ducs","word_nosc":"grands-ducs","lemma":"grand-duc","pos":"NOM"} ,
		{"word":"grands-mères","word_nosc":"grands-meres","lemma":"grand-mère","pos":"NOM"} ,
		{"word":"grands-oncles","word_nosc":"grands-oncles","lemma":"grand-oncle","pos":"NOM"} ,
		{"word":"grands-parents","word_nosc":"grands-parents","lemma":"grands-parents","pos":"NOM"} ,
		{"word":"grands-pères","word_nosc":"grands-peres","lemma":"grand-père","pos":"NOM"} ,
		{"word":"grands-tantes","word_nosc":"grands-tantes","lemma":"grand-tante","pos":"NOM"} ,
		{"word":"grange","word_nosc":"grange","lemma":"grange","pos":"NOM"} ,
		{"word":"granges","word_nosc":"granges","lemma":"grange","pos":"NOM"} ,
		{"word":"grangette","word_nosc":"grangette","lemma":"grangette","pos":"NOM"} ,
		{"word":"granit","word_nosc":"granit","lemma":"granit","pos":"NOM"} ,
		{"word":"granite","word_nosc":"granite","lemma":"granite","pos":"NOM"} ,
		{"word":"granito","word_nosc":"granito","lemma":"granito","pos":"NOM"} ,
		{"word":"granits","word_nosc":"granits","lemma":"granit","pos":"NOM"} ,
		{"word":"granité","word_nosc":"granite","lemma":"granité","pos":"NOM"} ,
		{"word":"granités","word_nosc":"granites","lemma":"granité","pos":"NOM"} ,
		{"word":"granulation","word_nosc":"granulation","lemma":"granulation","pos":"NOM"} ,
		{"word":"granulations","word_nosc":"granulations","lemma":"granulation","pos":"NOM"} ,
		{"word":"granules","word_nosc":"granules","lemma":"granule","pos":"NOM"} ,
		{"word":"granulie","word_nosc":"granulie","lemma":"granulie","pos":"NOM"} ,
		{"word":"granulome","word_nosc":"granulome","lemma":"granulome","pos":"NOM"} ,
		{"word":"granulé","word_nosc":"granule","lemma":"granulé","pos":"NOM"} ,
		{"word":"granulés","word_nosc":"granules","lemma":"granulé","pos":"NOM"} ,
		{"word":"grape-fruit","word_nosc":"grape-fruit","lemma":"grape-fruit","pos":"NOM"} ,
		{"word":"graphe","word_nosc":"graphe","lemma":"graphe","pos":"NOM"} ,
		{"word":"graphes","word_nosc":"graphes","lemma":"graphe","pos":"NOM"} ,
		{"word":"graphie","word_nosc":"graphie","lemma":"graphie","pos":"NOM"} ,
		{"word":"graphies","word_nosc":"graphies","lemma":"graphie","pos":"NOM"} ,
		{"word":"graphique","word_nosc":"graphique","lemma":"graphique","pos":"NOM"} ,
		{"word":"graphiques","word_nosc":"graphiques","lemma":"graphique","pos":"NOM"} ,
		{"word":"graphisme","word_nosc":"graphisme","lemma":"graphisme","pos":"NOM"} ,
		{"word":"graphismes","word_nosc":"graphismes","lemma":"graphisme","pos":"NOM"} ,
		{"word":"graphiste","word_nosc":"graphiste","lemma":"graphiste","pos":"NOM"} ,
		{"word":"graphite","word_nosc":"graphite","lemma":"graphite","pos":"NOM"} ,
		{"word":"graphites","word_nosc":"graphites","lemma":"graphite","pos":"NOM"} ,
		{"word":"graphologie","word_nosc":"graphologie","lemma":"graphologie","pos":"NOM"} ,
		{"word":"graphologue","word_nosc":"graphologue","lemma":"graphologue","pos":"NOM"} ,
		{"word":"graphologues","word_nosc":"graphologues","lemma":"graphologue","pos":"NOM"} ,
		{"word":"graphomane","word_nosc":"graphomane","lemma":"graphomane","pos":"NOM"} ,
		{"word":"graphomanes","word_nosc":"graphomanes","lemma":"graphomane","pos":"NOM"} ,
		{"word":"graphomanie","word_nosc":"graphomanie","lemma":"graphomanie","pos":"NOM"} ,
		{"word":"graphomètre","word_nosc":"graphometre","lemma":"graphomètre","pos":"NOM"} ,
		{"word":"graphophone","word_nosc":"graphophone","lemma":"graphophone","pos":"NOM"} ,
		{"word":"grappa","word_nosc":"grappa","lemma":"grappa","pos":"NOM"} ,
		{"word":"grappe","word_nosc":"grappe","lemma":"grappe","pos":"NOM"} ,
		{"word":"grappes","word_nosc":"grappes","lemma":"grappe","pos":"NOM"} ,
		{"word":"grappillage","word_nosc":"grappillage","lemma":"grappillage","pos":"NOM"} ,
		{"word":"grappilleur","word_nosc":"grappilleur","lemma":"grappilleur","pos":"NOM"} ,
		{"word":"grappillon","word_nosc":"grappillon","lemma":"grappillon","pos":"NOM"} ,
		{"word":"grappillons","word_nosc":"grappillons","lemma":"grappillon","pos":"NOM"} ,
		{"word":"grappin","word_nosc":"grappin","lemma":"grappin","pos":"NOM"} ,
		{"word":"grappins","word_nosc":"grappins","lemma":"grappin","pos":"NOM"} ,
		{"word":"gras","word_nosc":"gras","lemma":"gras","pos":"NOM"} ,
		{"word":"gras-double","word_nosc":"gras-double","lemma":"gras-double","pos":"NOM"} ,
		{"word":"gras-doubles","word_nosc":"gras-doubles","lemma":"gras-double","pos":"NOM"} ,
		{"word":"grasseyement","word_nosc":"grasseyement","lemma":"grasseyement","pos":"NOM"} ,
		{"word":"grasseyements","word_nosc":"grasseyements","lemma":"grasseyement","pos":"NOM"} ,
		{"word":"gratification","word_nosc":"gratification","lemma":"gratification","pos":"NOM"} ,
		{"word":"gratifications","word_nosc":"gratifications","lemma":"gratification","pos":"NOM"} ,
		{"word":"gratin","word_nosc":"gratin","lemma":"gratin","pos":"NOM"} ,
		{"word":"gratins","word_nosc":"gratins","lemma":"gratin","pos":"NOM"} ,
		{"word":"gratinée","word_nosc":"gratinee","lemma":"gratinée","pos":"NOM"} ,
		{"word":"gratitude","word_nosc":"gratitude","lemma":"gratitude","pos":"NOM"} ,
		{"word":"gratitudes","word_nosc":"gratitudes","lemma":"gratitude","pos":"NOM"} ,
		{"word":"gratouillis","word_nosc":"gratouillis","lemma":"gratouillis","pos":"NOM"} ,
		{"word":"grattage","word_nosc":"grattage","lemma":"grattage","pos":"NOM"} ,
		{"word":"grattages","word_nosc":"grattages","lemma":"grattage","pos":"NOM"} ,
		{"word":"gratte","word_nosc":"gratte","lemma":"gratte","pos":"NOM"} ,
		{"word":"gratte-ciel","word_nosc":"gratte-ciel","lemma":"gratte-ciel","pos":"NOM"} ,
		{"word":"gratte-ciels","word_nosc":"gratte-ciels","lemma":"gratte-ciel","pos":"NOM"} ,
		{"word":"gratte-cul","word_nosc":"gratte-cul","lemma":"gratte-cul","pos":"NOM"} ,
		{"word":"gratte-dos","word_nosc":"gratte-dos","lemma":"gratte-dos","pos":"NOM"} ,
		{"word":"gratte-papier","word_nosc":"gratte-papier","lemma":"gratte-papier","pos":"NOM"} ,
		{"word":"gratte-papiers","word_nosc":"gratte-papiers","lemma":"gratte-papier","pos":"NOM"} ,
		{"word":"gratte-pieds","word_nosc":"gratte-pieds","lemma":"gratte-pieds","pos":"NOM"} ,
		{"word":"grattement","word_nosc":"grattement","lemma":"grattement","pos":"NOM"} ,
		{"word":"grattements","word_nosc":"grattements","lemma":"grattement","pos":"NOM"} ,
		{"word":"gratterons","word_nosc":"gratterons","lemma":"gratteron","pos":"NOM"} ,
		{"word":"grattes","word_nosc":"grattes","lemma":"gratte","pos":"NOM"} ,
		{"word":"gratteur","word_nosc":"gratteur","lemma":"gratteur","pos":"NOM"} ,
		{"word":"gratteurs","word_nosc":"gratteurs","lemma":"gratteur","pos":"NOM"} ,
		{"word":"grattoir","word_nosc":"grattoir","lemma":"grattoir","pos":"NOM"} ,
		{"word":"grattoirs","word_nosc":"grattoirs","lemma":"grattoir","pos":"NOM"} ,
		{"word":"grattons","word_nosc":"grattons","lemma":"gratton","pos":"NOM"} ,
		{"word":"grattouillis","word_nosc":"grattouillis","lemma":"grattouillis","pos":"NOM"} ,
		{"word":"gratture","word_nosc":"gratture","lemma":"gratture","pos":"NOM"} ,
		{"word":"gratuité","word_nosc":"gratuite","lemma":"gratuité","pos":"NOM"} ,
		{"word":"grau","word_nosc":"grau","lemma":"grau","pos":"NOM"} ,
		{"word":"gravats","word_nosc":"gravats","lemma":"gravats","pos":"NOM"} ,
		{"word":"grave","word_nosc":"grave","lemma":"grave","pos":"NOM"} ,
		{"word":"gravelle","word_nosc":"gravelle","lemma":"gravelle","pos":"NOM"} ,
		{"word":"gravelles","word_nosc":"gravelles","lemma":"gravelle","pos":"NOM"} ,
		{"word":"graves","word_nosc":"graves","lemma":"grave","pos":"NOM"} ,
		{"word":"graveur","word_nosc":"graveur","lemma":"graveur","pos":"NOM"} ,
		{"word":"graveurs","word_nosc":"graveurs","lemma":"graveur","pos":"NOM"} ,
		{"word":"gravidité","word_nosc":"gravidite","lemma":"gravidité","pos":"NOM"} ,
		{"word":"gravier","word_nosc":"gravier","lemma":"gravier","pos":"NOM"} ,
		{"word":"graviers","word_nosc":"graviers","lemma":"gravier","pos":"NOM"} ,
		{"word":"gravillon","word_nosc":"gravillon","lemma":"gravillon","pos":"NOM"} ,
		{"word":"gravillons","word_nosc":"gravillons","lemma":"gravillon","pos":"NOM"} ,
		{"word":"gravitation","word_nosc":"gravitation","lemma":"gravitation","pos":"NOM"} ,
		{"word":"gravitons","word_nosc":"gravitons","lemma":"graviton","pos":"NOM"} ,
		{"word":"gravité","word_nosc":"gravite","lemma":"gravité","pos":"NOM"} ,
		{"word":"gravières","word_nosc":"gravieres","lemma":"gravière","pos":"NOM"} ,
		{"word":"gravos","word_nosc":"gravos","lemma":"gravos","pos":"NOM"} ,
		{"word":"gravosse","word_nosc":"gravosse","lemma":"gravosse","pos":"NOM"} ,
		{"word":"gravosses","word_nosc":"gravosses","lemma":"gravosse","pos":"NOM"} ,
		{"word":"gravure","word_nosc":"gravure","lemma":"gravure","pos":"NOM"} ,
		{"word":"gravures","word_nosc":"gravures","lemma":"gravure","pos":"NOM"} ,
		{"word":"gray","word_nosc":"gray","lemma":"gray","pos":"NOM"} ,
		{"word":"grays","word_nosc":"grays","lemma":"gray","pos":"NOM"} ,
		{"word":"grec","word_nosc":"grec","lemma":"grec","pos":"NOM"} ,
		{"word":"grecque","word_nosc":"grecque","lemma":"grec","pos":"NOM"} ,
		{"word":"grecques","word_nosc":"grecques","lemma":"grec","pos":"NOM"} ,
		{"word":"grecs","word_nosc":"grecs","lemma":"grec","pos":"NOM"} ,
		{"word":"gredin","word_nosc":"gredin","lemma":"gredin","pos":"NOM"} ,
		{"word":"gredine","word_nosc":"gredine","lemma":"gredin","pos":"NOM"} ,
		{"word":"gredins","word_nosc":"gredins","lemma":"gredin","pos":"NOM"} ,
		{"word":"green","word_nosc":"green","lemma":"green","pos":"NOM"} ,
		{"word":"greens","word_nosc":"greens","lemma":"green","pos":"NOM"} ,
		{"word":"greffage","word_nosc":"greffage","lemma":"greffage","pos":"NOM"} ,
		{"word":"greffe","word_nosc":"greffe","lemma":"greffe","pos":"NOM"} ,
		{"word":"greffes","word_nosc":"greffes","lemma":"greffe","pos":"NOM"} ,
		{"word":"greffier","word_nosc":"greffier","lemma":"greffier","pos":"NOM"} ,
		{"word":"greffiers","word_nosc":"greffiers","lemma":"greffier","pos":"NOM"} ,
		{"word":"greffière","word_nosc":"greffiere","lemma":"greffier","pos":"NOM"} ,
		{"word":"greffon","word_nosc":"greffon","lemma":"greffon","pos":"NOM"} ,
		{"word":"greffons","word_nosc":"greffons","lemma":"greffon","pos":"NOM"} ,
		{"word":"greffé","word_nosc":"greffe","lemma":"greffé","pos":"NOM"} ,
		{"word":"greffées","word_nosc":"greffees","lemma":"greffé","pos":"NOM"} ,
		{"word":"greffés","word_nosc":"greffes","lemma":"greffé","pos":"NOM"} ,
		{"word":"grelot","word_nosc":"grelot","lemma":"grelot","pos":"NOM"} ,
		{"word":"grelots","word_nosc":"grelots","lemma":"grelot","pos":"NOM"} ,
		{"word":"grelottement","word_nosc":"grelottement","lemma":"grelottement","pos":"NOM"} ,
		{"word":"grelottements","word_nosc":"grelottements","lemma":"grelottement","pos":"NOM"} ,
		{"word":"grelotteux","word_nosc":"grelotteux","lemma":"grelotteux","pos":"NOM"} ,
		{"word":"greluche","word_nosc":"greluche","lemma":"greluche","pos":"NOM"} ,
		{"word":"greluches","word_nosc":"greluches","lemma":"greluche","pos":"NOM"} ,
		{"word":"greluchon","word_nosc":"greluchon","lemma":"greluchon","pos":"NOM"} ,
		{"word":"grenache","word_nosc":"grenache","lemma":"grenache","pos":"NOM"} ,
		{"word":"grenadage","word_nosc":"grenadage","lemma":"grenadage","pos":"NOM"} ,
		{"word":"grenade","word_nosc":"grenade","lemma":"grenade","pos":"NOM"} ,
		{"word":"grenades","word_nosc":"grenades","lemma":"grenade","pos":"NOM"} ,
		{"word":"grenadeur","word_nosc":"grenadeur","lemma":"grenadeur","pos":"NOM"} ,
		{"word":"grenadier","word_nosc":"grenadier","lemma":"grenadier","pos":"NOM"} ,
		{"word":"grenadiers","word_nosc":"grenadiers","lemma":"grenadier","pos":"NOM"} ,
		{"word":"grenadine","word_nosc":"grenadine","lemma":"grenadine","pos":"NOM"} ,
		{"word":"grenadines","word_nosc":"grenadines","lemma":"grenadine","pos":"NOM"} ,
		{"word":"grenaille","word_nosc":"grenaille","lemma":"grenaille","pos":"NOM"} ,
		{"word":"grenailles","word_nosc":"grenailles","lemma":"grenaille","pos":"NOM"} ,
		{"word":"grenat","word_nosc":"grenat","lemma":"grenat","pos":"NOM"} ,
		{"word":"grenats","word_nosc":"grenats","lemma":"grenat","pos":"NOM"} ,
		{"word":"greneuse","word_nosc":"greneuse","lemma":"greneur","pos":"NOM"} ,
		{"word":"grenier","word_nosc":"grenier","lemma":"grenier","pos":"NOM"} ,
		{"word":"greniers","word_nosc":"greniers","lemma":"grenier","pos":"NOM"} ,
		{"word":"grenoblois","word_nosc":"grenoblois","lemma":"grenoblois","pos":"NOM"} ,
		{"word":"grenouillage","word_nosc":"grenouillage","lemma":"grenouillage","pos":"NOM"} ,
		{"word":"grenouillard","word_nosc":"grenouillard","lemma":"grenouillard","pos":"NOM"} ,
		{"word":"grenouille","word_nosc":"grenouille","lemma":"grenouille","pos":"NOM"} ,
		{"word":"grenouille-taureau","word_nosc":"grenouille-taureau","lemma":"grenouille-taureau","pos":"NOM"} ,
		{"word":"grenouilles","word_nosc":"grenouilles","lemma":"grenouille","pos":"NOM"} ,
		{"word":"grenouillettes","word_nosc":"grenouillettes","lemma":"grenouillette","pos":"NOM"} ,
		{"word":"grenouillère","word_nosc":"grenouillere","lemma":"grenouillère","pos":"NOM"} ,
		{"word":"grenouillères","word_nosc":"grenouilleres","lemma":"grenouillère","pos":"NOM"} ,
		{"word":"grenures","word_nosc":"grenures","lemma":"grenure","pos":"NOM"} ,
		{"word":"gressin","word_nosc":"gressin","lemma":"gressin","pos":"NOM"} ,
		{"word":"gressins","word_nosc":"gressins","lemma":"gressin","pos":"NOM"} ,
		{"word":"gretchen","word_nosc":"gretchen","lemma":"gretchen","pos":"NOM"} ,
		{"word":"gretchens","word_nosc":"gretchens","lemma":"gretchen","pos":"NOM"} ,
		{"word":"gri-gri","word_nosc":"gri-gri","lemma":"gri-gri","pos":"NOM"} ,
		{"word":"gribiche","word_nosc":"gribiche","lemma":"gribiche","pos":"NOM"} ,
		{"word":"gribouillage","word_nosc":"gribouillage","lemma":"gribouillage","pos":"NOM"} ,
		{"word":"gribouillages","word_nosc":"gribouillages","lemma":"gribouillage","pos":"NOM"} ,
		{"word":"gribouille","word_nosc":"gribouille","lemma":"gribouille","pos":"NOM"} ,
		{"word":"gribouilleur","word_nosc":"gribouilleur","lemma":"gribouilleur","pos":"NOM"} ,
		{"word":"gribouillis","word_nosc":"gribouillis","lemma":"gribouillis","pos":"NOM"} ,
		{"word":"grief","word_nosc":"grief","lemma":"grief","pos":"NOM"} ,
		{"word":"griefs","word_nosc":"griefs","lemma":"grief","pos":"NOM"} ,
		{"word":"griffage","word_nosc":"griffage","lemma":"griffage","pos":"NOM"} ,
		{"word":"griffe","word_nosc":"griffe","lemma":"griffe","pos":"NOM"} ,
		{"word":"griffes","word_nosc":"griffes","lemma":"griffe","pos":"NOM"} ,
		{"word":"griffeur","word_nosc":"griffeur","lemma":"griffeur","pos":"NOM"} ,
		{"word":"griffon","word_nosc":"griffon","lemma":"griffon","pos":"NOM"} ,
		{"word":"griffonnage","word_nosc":"griffonnage","lemma":"griffonnage","pos":"NOM"} ,
		{"word":"griffonnages","word_nosc":"griffonnages","lemma":"griffonnage","pos":"NOM"} ,
		{"word":"griffonneur","word_nosc":"griffonneur","lemma":"griffonneur","pos":"NOM"} ,
		{"word":"griffons","word_nosc":"griffons","lemma":"griffon","pos":"NOM"} ,
		{"word":"grifftons","word_nosc":"grifftons","lemma":"griffton","pos":"NOM"} ,
		{"word":"griffure","word_nosc":"griffure","lemma":"griffure","pos":"NOM"} ,
		{"word":"griffures","word_nosc":"griffures","lemma":"griffure","pos":"NOM"} ,
		{"word":"grifton","word_nosc":"grifton","lemma":"grifton","pos":"NOM"} ,
		{"word":"grignotage","word_nosc":"grignotage","lemma":"grignotage","pos":"NOM"} ,
		{"word":"grignotages","word_nosc":"grignotages","lemma":"grignotage","pos":"NOM"} ,
		{"word":"grignotement","word_nosc":"grignotement","lemma":"grignotement","pos":"NOM"} ,
		{"word":"grignoteurs","word_nosc":"grignoteurs","lemma":"grignoteur","pos":"NOM"} ,
		{"word":"grignotis","word_nosc":"grignotis","lemma":"grignotis","pos":"NOM"} ,
		{"word":"grigou","word_nosc":"grigou","lemma":"grigou","pos":"NOM"} ,
		{"word":"grigri","word_nosc":"grigri","lemma":"grigri","pos":"NOM"} ,
		{"word":"grigris","word_nosc":"grigris","lemma":"grigri","pos":"NOM"} ,
		{"word":"gril","word_nosc":"gril","lemma":"gril","pos":"NOM"} ,
		{"word":"grill","word_nosc":"grill","lemma":"grill","pos":"NOM"} ,
		{"word":"grill-room","word_nosc":"grill-room","lemma":"grill-room","pos":"NOM"} ,
		{"word":"grillade","word_nosc":"grillade","lemma":"grillade","pos":"NOM"} ,
		{"word":"grilladerie","word_nosc":"grilladerie","lemma":"grilladerie","pos":"NOM"} ,
		{"word":"grillades","word_nosc":"grillades","lemma":"grillade","pos":"NOM"} ,
		{"word":"grillage","word_nosc":"grillage","lemma":"grillage","pos":"NOM"} ,
		{"word":"grillages","word_nosc":"grillages","lemma":"grillage","pos":"NOM"} ,
		{"word":"grille","word_nosc":"grille","lemma":"grille","pos":"NOM"} ,
		{"word":"grille-pain","word_nosc":"grille-pain","lemma":"grille-pain","pos":"NOM"} ,
		{"word":"grilles","word_nosc":"grilles","lemma":"grille","pos":"NOM"} ,
		{"word":"grilloirs","word_nosc":"grilloirs","lemma":"grilloir","pos":"NOM"} ,
		{"word":"grillon","word_nosc":"grillon","lemma":"grillon","pos":"NOM"} ,
		{"word":"grillons","word_nosc":"grillons","lemma":"grillon","pos":"NOM"} ,
		{"word":"grillots","word_nosc":"grillots","lemma":"grillot","pos":"NOM"} ,
		{"word":"grils","word_nosc":"grils","lemma":"gril","pos":"NOM"} ,
		{"word":"grimace","word_nosc":"grimace","lemma":"grimace","pos":"NOM"} ,
		{"word":"grimace-éclair","word_nosc":"grimace-eclair","lemma":"grimace-éclair","pos":"NOM"} ,
		{"word":"grimacement","word_nosc":"grimacement","lemma":"grimacement","pos":"NOM"} ,
		{"word":"grimaceries","word_nosc":"grimaceries","lemma":"grimacerie","pos":"NOM"} ,
		{"word":"grimaces","word_nosc":"grimaces","lemma":"grimace","pos":"NOM"} ,
		{"word":"grimacier","word_nosc":"grimacier","lemma":"grimacier","pos":"NOM"} ,
		{"word":"grimaciers","word_nosc":"grimaciers","lemma":"grimacier","pos":"NOM"} ,
		{"word":"grimage","word_nosc":"grimage","lemma":"grimage","pos":"NOM"} ,
		{"word":"grimauds","word_nosc":"grimauds","lemma":"grimaud","pos":"NOM"} ,
		{"word":"grimes","word_nosc":"grimes","lemma":"grime","pos":"NOM"} ,
		{"word":"grimoire","word_nosc":"grimoire","lemma":"grimoire","pos":"NOM"} ,
		{"word":"grimoires","word_nosc":"grimoires","lemma":"grimoire","pos":"NOM"} ,
		{"word":"grimpant","word_nosc":"grimpant","lemma":"grimpant","pos":"NOM"} ,
		{"word":"grimpants","word_nosc":"grimpants","lemma":"grimpant","pos":"NOM"} ,
		{"word":"grimpe","word_nosc":"grimpe","lemma":"grimpe","pos":"NOM"} ,
		{"word":"grimper","word_nosc":"grimper","lemma":"grimper","pos":"NOM"} ,
		{"word":"grimpette","word_nosc":"grimpette","lemma":"grimpette","pos":"NOM"} ,
		{"word":"grimpettes","word_nosc":"grimpettes","lemma":"grimpette","pos":"NOM"} ,
		{"word":"grimpeur","word_nosc":"grimpeur","lemma":"grimpeur","pos":"NOM"} ,
		{"word":"grimpeurs","word_nosc":"grimpeurs","lemma":"grimpeur","pos":"NOM"} ,
		{"word":"grimpeuse","word_nosc":"grimpeuse","lemma":"grimpeur","pos":"NOM"} ,
		{"word":"grimpée","word_nosc":"grimpee","lemma":"grimpée","pos":"NOM"} ,
		{"word":"grincement","word_nosc":"grincement","lemma":"grincement","pos":"NOM"} ,
		{"word":"grincements","word_nosc":"grincements","lemma":"grincement","pos":"NOM"} ,
		{"word":"grincheuse","word_nosc":"grincheuse","lemma":"grincheux","pos":"NOM"} ,
		{"word":"grincheuses","word_nosc":"grincheuses","lemma":"grincheux","pos":"NOM"} ,
		{"word":"grincheux","word_nosc":"grincheux","lemma":"grincheux","pos":"NOM"} ,
		{"word":"gringalet","word_nosc":"gringalet","lemma":"gringalet","pos":"NOM"} ,
		{"word":"gringalets","word_nosc":"gringalets","lemma":"gringalet","pos":"NOM"} ,
		{"word":"gringo","word_nosc":"gringo","lemma":"gringo","pos":"NOM"} ,
		{"word":"gringos","word_nosc":"gringos","lemma":"gringo","pos":"NOM"} ,
		{"word":"gringue","word_nosc":"gringue","lemma":"gringue","pos":"NOM"} ,
		{"word":"griot","word_nosc":"griot","lemma":"griot","pos":"NOM"} ,
		{"word":"griots","word_nosc":"griots","lemma":"griot","pos":"NOM"} ,
		{"word":"griotte","word_nosc":"griotte","lemma":"griotte","pos":"NOM"} ,
		{"word":"griottes","word_nosc":"griottes","lemma":"griotte","pos":"NOM"} ,
		{"word":"grip","word_nosc":"grip","lemma":"grip","pos":"NOM"} ,
		{"word":"grippe","word_nosc":"grippe","lemma":"grippe","pos":"NOM"} ,
		{"word":"grippe-sou","word_nosc":"grippe-sou","lemma":"grippe-sou","pos":"NOM"} ,
		{"word":"grippe-sous","word_nosc":"grippe-sous","lemma":"grippe-sou","pos":"NOM"} ,
		{"word":"grippements","word_nosc":"grippements","lemma":"grippement","pos":"NOM"} ,
		{"word":"grippes","word_nosc":"grippes","lemma":"grippe","pos":"NOM"} ,
		{"word":"grippé","word_nosc":"grippe","lemma":"grippé","pos":"NOM"} ,
		{"word":"grippée","word_nosc":"grippee","lemma":"grippé","pos":"NOM"} ,
		{"word":"gris","word_nosc":"gris","lemma":"gris","pos":"NOM"} ,
		{"word":"gris-bleu","word_nosc":"gris-bleu","lemma":"gris-bleu","pos":"NOM"} ,
		{"word":"gris-gris","word_nosc":"gris-gris","lemma":"gris-gris","pos":"NOM"} ,
		{"word":"grisaille","word_nosc":"grisaille","lemma":"grisaille","pos":"NOM"} ,
		{"word":"grisailles","word_nosc":"grisailles","lemma":"grisaille","pos":"NOM"} ,
		{"word":"grisbi","word_nosc":"grisbi","lemma":"grisbi","pos":"NOM"} ,
		{"word":"griserie","word_nosc":"griserie","lemma":"griserie","pos":"NOM"} ,
		{"word":"griseries","word_nosc":"griseries","lemma":"griserie","pos":"NOM"} ,
		{"word":"grisette","word_nosc":"grisette","lemma":"grisette","pos":"NOM"} ,
		{"word":"grisettes","word_nosc":"grisettes","lemma":"grisette","pos":"NOM"} ,
		{"word":"grison","word_nosc":"grison","lemma":"grison","pos":"NOM"} ,
		{"word":"grisotte","word_nosc":"grisotte","lemma":"grisotte","pos":"NOM"} ,
		{"word":"grisou","word_nosc":"grisou","lemma":"grisou","pos":"NOM"} ,
		{"word":"grisé","word_nosc":"grise","lemma":"grisé","pos":"NOM"} ,
		{"word":"grisés","word_nosc":"grises","lemma":"grisé","pos":"NOM"} ,
		{"word":"grive","word_nosc":"grive","lemma":"grive","pos":"NOM"} ,
		{"word":"grivelures","word_nosc":"grivelures","lemma":"grivelure","pos":"NOM"} ,
		{"word":"grives","word_nosc":"grives","lemma":"grive","pos":"NOM"} ,
		{"word":"grivet","word_nosc":"grivet","lemma":"grivet","pos":"NOM"} ,
		{"word":"griveton","word_nosc":"griveton","lemma":"griveton","pos":"NOM"} ,
		{"word":"grivetons","word_nosc":"grivetons","lemma":"griveton","pos":"NOM"} ,
		{"word":"grivois","word_nosc":"grivois","lemma":"grivois","pos":"NOM"} ,
		{"word":"grivoiserie","word_nosc":"grivoiserie","lemma":"grivoiserie","pos":"NOM"} ,
		{"word":"grivoiseries","word_nosc":"grivoiseries","lemma":"grivoiserie","pos":"NOM"} ,
		{"word":"grivèlerie","word_nosc":"grivelerie","lemma":"grivèlerie","pos":"NOM"} ,
		{"word":"grizzli","word_nosc":"grizzli","lemma":"grizzli","pos":"NOM"} ,
		{"word":"grizzlis","word_nosc":"grizzlis","lemma":"grizzli","pos":"NOM"} ,
		{"word":"grizzly","word_nosc":"grizzly","lemma":"grizzly","pos":"NOM"} ,
		{"word":"grizzlys","word_nosc":"grizzlys","lemma":"grizzly","pos":"NOM"} ,
		{"word":"groenlandais","word_nosc":"groenlandais","lemma":"groenlandais","pos":"NOM"} ,
		{"word":"grog","word_nosc":"grog","lemma":"grog","pos":"NOM"} ,
		{"word":"grognard","word_nosc":"grognard","lemma":"grognard","pos":"NOM"} ,
		{"word":"grognards","word_nosc":"grognards","lemma":"grognard","pos":"NOM"} ,
		{"word":"grognasse","word_nosc":"grognasse","lemma":"grognasse","pos":"NOM"} ,
		{"word":"grognasses","word_nosc":"grognasses","lemma":"grognasse","pos":"NOM"} ,
		{"word":"grogne","word_nosc":"grogne","lemma":"grogne","pos":"NOM"} ,
		{"word":"grognement","word_nosc":"grognement","lemma":"grognement","pos":"NOM"} ,
		{"word":"grognements","word_nosc":"grognements","lemma":"grognement","pos":"NOM"} ,
		{"word":"grognes","word_nosc":"grognes","lemma":"grogne","pos":"NOM"} ,
		{"word":"grognon","word_nosc":"grognon","lemma":"grognon","pos":"NOM"} ,
		{"word":"grognons","word_nosc":"grognons","lemma":"grognon","pos":"NOM"} ,
		{"word":"grogs","word_nosc":"grogs","lemma":"grog","pos":"NOM"} ,
		{"word":"groin","word_nosc":"groin","lemma":"groin","pos":"NOM"} ,
		{"word":"groins","word_nosc":"groins","lemma":"groin","pos":"NOM"} ,
		{"word":"grole","word_nosc":"grole","lemma":"grole","pos":"NOM"} ,
		{"word":"groles","word_nosc":"groles","lemma":"grole","pos":"NOM"} ,
		{"word":"grolle","word_nosc":"grolle","lemma":"grolle","pos":"NOM"} ,
		{"word":"grolles","word_nosc":"grolles","lemma":"grolle","pos":"NOM"} ,
		{"word":"grommellement","word_nosc":"grommellement","lemma":"grommellement","pos":"NOM"} ,
		{"word":"grommellements","word_nosc":"grommellements","lemma":"grommellement","pos":"NOM"} ,
		{"word":"grondement","word_nosc":"grondement","lemma":"grondement","pos":"NOM"} ,
		{"word":"grondements","word_nosc":"grondements","lemma":"grondement","pos":"NOM"} ,
		{"word":"gronderies","word_nosc":"gronderies","lemma":"gronderie","pos":"NOM"} ,
		{"word":"grondin","word_nosc":"grondin","lemma":"grondin","pos":"NOM"} ,
		{"word":"grondins","word_nosc":"grondins","lemma":"grondin","pos":"NOM"} ,
		{"word":"groom","word_nosc":"groom","lemma":"groom","pos":"NOM"} ,
		{"word":"grooms","word_nosc":"grooms","lemma":"groom","pos":"NOM"} ,
		{"word":"gros","word_nosc":"gros","lemma":"gros","pos":"NOM"} ,
		{"word":"gros-bec","word_nosc":"gros-bec","lemma":"gros-bec","pos":"NOM"} ,
		{"word":"gros-becs","word_nosc":"gros-becs","lemma":"gros-bec","pos":"NOM"} ,
		{"word":"gros-cul","word_nosc":"gros-cul","lemma":"gros-cul","pos":"NOM"} ,
		{"word":"gros-grain","word_nosc":"gros-grain","lemma":"gros-grain","pos":"NOM"} ,
		{"word":"gros-porteur","word_nosc":"gros-porteur","lemma":"gros-porteur","pos":"NOM"} ,
		{"word":"groschen","word_nosc":"groschen","lemma":"groschen","pos":"NOM"} ,
		{"word":"groseille","word_nosc":"groseille","lemma":"groseille","pos":"NOM"} ,
		{"word":"groseilles","word_nosc":"groseilles","lemma":"groseille","pos":"NOM"} ,
		{"word":"groseillier","word_nosc":"groseillier","lemma":"groseillier","pos":"NOM"} ,
		{"word":"groseilliers","word_nosc":"groseilliers","lemma":"groseillier","pos":"NOM"} ,
		{"word":"grosse","word_nosc":"grosse","lemma":"gros","pos":"NOM"} ,
		{"word":"grosses","word_nosc":"grosses","lemma":"gros","pos":"NOM"} ,
		{"word":"grossesse","word_nosc":"grossesse","lemma":"grossesse","pos":"NOM"} ,
		{"word":"grossesses","word_nosc":"grossesses","lemma":"grossesse","pos":"NOM"} ,
		{"word":"grosseur","word_nosc":"grosseur","lemma":"grosseur","pos":"NOM"} ,
		{"word":"grosseurs","word_nosc":"grosseurs","lemma":"grosseur","pos":"NOM"} ,
		{"word":"grossier","word_nosc":"grossier","lemma":"grossier","pos":"NOM"} ,
		{"word":"grossiers","word_nosc":"grossiers","lemma":"grossier","pos":"NOM"} ,
		{"word":"grossissement","word_nosc":"grossissement","lemma":"grossissement","pos":"NOM"} ,
		{"word":"grossiste","word_nosc":"grossiste","lemma":"grossiste","pos":"NOM"} ,
		{"word":"grossistes","word_nosc":"grossistes","lemma":"grossiste","pos":"NOM"} ,
		{"word":"grossium","word_nosc":"grossium","lemma":"grossium","pos":"NOM"} ,
		{"word":"grossiums","word_nosc":"grossiums","lemma":"grossium","pos":"NOM"} ,
		{"word":"grossièreté","word_nosc":"grossierete","lemma":"grossièreté","pos":"NOM"} ,
		{"word":"grossièretés","word_nosc":"grossieretes","lemma":"grossièreté","pos":"NOM"} ,
		{"word":"grotesque","word_nosc":"grotesque","lemma":"grotesque","pos":"NOM"} ,
		{"word":"grotesques","word_nosc":"grotesques","lemma":"grotesque","pos":"NOM"} ,
		{"word":"grotte","word_nosc":"grotte","lemma":"grotte","pos":"NOM"} ,
		{"word":"grottes","word_nosc":"grottes","lemma":"grotte","pos":"NOM"} ,
		{"word":"grouillement","word_nosc":"grouillement","lemma":"grouillement","pos":"NOM"} ,
		{"word":"grouillements","word_nosc":"grouillements","lemma":"grouillement","pos":"NOM"} ,
		{"word":"grouillis","word_nosc":"grouillis","lemma":"grouillis","pos":"NOM"} ,
		{"word":"grouillot","word_nosc":"grouillot","lemma":"grouillot","pos":"NOM"} ,
		{"word":"grouillots","word_nosc":"grouillots","lemma":"grouillot","pos":"NOM"} ,
		{"word":"ground","word_nosc":"ground","lemma":"ground","pos":"NOM"} ,
		{"word":"group","word_nosc":"group","lemma":"group","pos":"NOM"} ,
		{"word":"groupage","word_nosc":"groupage","lemma":"groupage","pos":"NOM"} ,
		{"word":"groupe","word_nosc":"groupe","lemma":"groupe","pos":"NOM"} ,
		{"word":"groupement","word_nosc":"groupement","lemma":"groupement","pos":"NOM"} ,
		{"word":"groupements","word_nosc":"groupements","lemma":"groupement","pos":"NOM"} ,
		{"word":"groupes","word_nosc":"groupes","lemma":"groupe","pos":"NOM"} ,
		{"word":"groupie","word_nosc":"groupie","lemma":"groupie","pos":"NOM"} ,
		{"word":"groupies","word_nosc":"groupies","lemma":"groupie","pos":"NOM"} ,
		{"word":"groups","word_nosc":"groups","lemma":"group","pos":"NOM"} ,
		{"word":"groupuscule","word_nosc":"groupuscule","lemma":"groupuscule","pos":"NOM"} ,
		{"word":"groupuscules","word_nosc":"groupuscules","lemma":"groupuscule","pos":"NOM"} ,
		{"word":"grouse","word_nosc":"grouse","lemma":"grouse","pos":"NOM"} ,
		{"word":"grouses","word_nosc":"grouses","lemma":"grouse","pos":"NOM"} ,
		{"word":"gruau","word_nosc":"gruau","lemma":"gruau","pos":"NOM"} ,
		{"word":"gruaux","word_nosc":"gruaux","lemma":"gruau","pos":"NOM"} ,
		{"word":"grue","word_nosc":"grue","lemma":"grue","pos":"NOM"} ,
		{"word":"grues","word_nosc":"grues","lemma":"grue","pos":"NOM"} ,
		{"word":"grume","word_nosc":"grume","lemma":"grume","pos":"NOM"} ,
		{"word":"grumeau","word_nosc":"grumeau","lemma":"grumeau","pos":"NOM"} ,
		{"word":"grumeaux","word_nosc":"grumeaux","lemma":"grumeau","pos":"NOM"} ,
		{"word":"grumes","word_nosc":"grumes","lemma":"grume","pos":"NOM"} ,
		{"word":"grumier","word_nosc":"grumier","lemma":"grumier","pos":"NOM"} ,
		{"word":"grunge","word_nosc":"grunge","lemma":"grunge","pos":"NOM"} ,
		{"word":"gruppetto","word_nosc":"gruppetto","lemma":"gruppetto","pos":"NOM"} ,
		{"word":"grutier","word_nosc":"grutier","lemma":"grutier","pos":"NOM"} ,
		{"word":"grutiers","word_nosc":"grutiers","lemma":"grutier","pos":"NOM"} ,
		{"word":"grutière","word_nosc":"grutiere","lemma":"grutier","pos":"NOM"} ,
		{"word":"gruyère","word_nosc":"gruyere","lemma":"gruyère","pos":"NOM"} ,
		{"word":"grâce","word_nosc":"grace","lemma":"grâce","pos":"NOM"} ,
		{"word":"grâces","word_nosc":"graces","lemma":"grâce","pos":"NOM"} ,
		{"word":"grèbe","word_nosc":"grebe","lemma":"grèbe","pos":"NOM"} ,
		{"word":"grèbes","word_nosc":"grebes","lemma":"grèbe","pos":"NOM"} ,
		{"word":"grège","word_nosc":"grege","lemma":"grège","pos":"NOM"} ,
		{"word":"grègues","word_nosc":"gregues","lemma":"grègues","pos":"NOM"} ,
		{"word":"grès","word_nosc":"gres","lemma":"grès","pos":"NOM"} ,
		{"word":"grève","word_nosc":"greve","lemma":"grève","pos":"NOM"} ,
		{"word":"grèves","word_nosc":"greves","lemma":"grève","pos":"NOM"} ,
		{"word":"gré","word_nosc":"gre","lemma":"gré","pos":"NOM"} ,
		{"word":"gréage","word_nosc":"greage","lemma":"gréage","pos":"NOM"} ,
		{"word":"grébiche","word_nosc":"grebiche","lemma":"grébiche","pos":"NOM"} ,
		{"word":"gréement","word_nosc":"greement","lemma":"gréement","pos":"NOM"} ,
		{"word":"gréements","word_nosc":"greements","lemma":"gréement","pos":"NOM"} ,
		{"word":"grésil","word_nosc":"gresil","lemma":"grésil","pos":"NOM"} ,
		{"word":"grésillement","word_nosc":"gresillement","lemma":"grésillement","pos":"NOM"} ,
		{"word":"grésillements","word_nosc":"gresillements","lemma":"grésillement","pos":"NOM"} ,
		{"word":"gréviste","word_nosc":"greviste","lemma":"gréviste","pos":"NOM"} ,
		{"word":"grévistes","word_nosc":"grevistes","lemma":"gréviste","pos":"NOM"} ,
		{"word":"grêle","word_nosc":"grele","lemma":"grêle","pos":"NOM"} ,
		{"word":"grêles","word_nosc":"greles","lemma":"grêle","pos":"NOM"} ,
		{"word":"grêlon","word_nosc":"grelon","lemma":"grêlon","pos":"NOM"} ,
		{"word":"grêlons","word_nosc":"grelons","lemma":"grêlon","pos":"NOM"} ,
		{"word":"guacamole","word_nosc":"guacamole","lemma":"guacamole","pos":"NOM"} ,
		{"word":"guadeloupéenne","word_nosc":"guadeloupeenne","lemma":"guadeloupéenne","pos":"NOM"} ,
		{"word":"guanaco","word_nosc":"guanaco","lemma":"guanaco","pos":"NOM"} ,
		{"word":"guanine","word_nosc":"guanine","lemma":"guanine","pos":"NOM"} ,
		{"word":"guano","word_nosc":"guano","lemma":"guano","pos":"NOM"} ,
		{"word":"guaracha","word_nosc":"guaracha","lemma":"guaracha","pos":"NOM"} ,
		{"word":"guatémaltèque","word_nosc":"guatemalteque","lemma":"guatémaltèque","pos":"NOM"} ,
		{"word":"guelfe","word_nosc":"guelfe","lemma":"guelfe","pos":"NOM"} ,
		{"word":"guelfes","word_nosc":"guelfes","lemma":"guelfe","pos":"NOM"} ,
		{"word":"guenille","word_nosc":"guenille","lemma":"guenille","pos":"NOM"} ,
		{"word":"guenilles","word_nosc":"guenilles","lemma":"guenille","pos":"NOM"} ,
		{"word":"guenillons","word_nosc":"guenillons","lemma":"guenillon","pos":"NOM"} ,
		{"word":"guenipe","word_nosc":"guenipe","lemma":"guenipe","pos":"NOM"} ,
		{"word":"guenon","word_nosc":"guenon","lemma":"guenon","pos":"NOM"} ,
		{"word":"guenons","word_nosc":"guenons","lemma":"guenon","pos":"NOM"} ,
		{"word":"guerre","word_nosc":"guerre","lemma":"guerre","pos":"NOM"} ,
		{"word":"guerre-éclair","word_nosc":"guerre-eclair","lemma":"guerre-éclair","pos":"NOM"} ,
		{"word":"guerres","word_nosc":"guerres","lemma":"guerre","pos":"NOM"} ,
		{"word":"guerrier","word_nosc":"guerrier","lemma":"guerrier","pos":"NOM"} ,
		{"word":"guerriers","word_nosc":"guerriers","lemma":"guerrier","pos":"NOM"} ,
		{"word":"guerroyeur","word_nosc":"guerroyeur","lemma":"guerroyeur","pos":"NOM"} ,
		{"word":"guet","word_nosc":"guet","lemma":"guet","pos":"NOM"} ,
		{"word":"guet-apens","word_nosc":"guet-apens","lemma":"guet-apens","pos":"NOM"} ,
		{"word":"guets","word_nosc":"guets","lemma":"guet","pos":"NOM"} ,
		{"word":"guets-apens","word_nosc":"guets-apens","lemma":"guets-apens","pos":"NOM"} ,
		{"word":"guette","word_nosc":"guette","lemma":"guette","pos":"NOM"} ,
		{"word":"guettes","word_nosc":"guettes","lemma":"guette","pos":"NOM"} ,
		{"word":"guetteur","word_nosc":"guetteur","lemma":"guetteur","pos":"NOM"} ,
		{"word":"guetteurs","word_nosc":"guetteurs","lemma":"guetteur","pos":"NOM"} ,
		{"word":"guetteuse","word_nosc":"guetteuse","lemma":"guetteur","pos":"NOM"} ,
		{"word":"guetteuses","word_nosc":"guetteuses","lemma":"guetteur","pos":"NOM"} ,
		{"word":"gueugueule","word_nosc":"gueugueule","lemma":"gueugueule","pos":"NOM"} ,
		{"word":"gueulante","word_nosc":"gueulante","lemma":"gueulante","pos":"NOM"} ,
		{"word":"gueulantes","word_nosc":"gueulantes","lemma":"gueulante","pos":"NOM"} ,
		{"word":"gueulard","word_nosc":"gueulard","lemma":"gueulard","pos":"NOM"} ,
		{"word":"gueularde","word_nosc":"gueularde","lemma":"gueulard","pos":"NOM"} ,
		{"word":"gueulards","word_nosc":"gueulards","lemma":"gueulard","pos":"NOM"} ,
		{"word":"gueule","word_nosc":"gueule","lemma":"gueule","pos":"NOM"} ,
		{"word":"gueule-de-loup","word_nosc":"gueule-de-loup","lemma":"gueule-de-loup","pos":"NOM"} ,
		{"word":"gueulement","word_nosc":"gueulement","lemma":"gueulement","pos":"NOM"} ,
		{"word":"gueulements","word_nosc":"gueulements","lemma":"gueulement","pos":"NOM"} ,
		{"word":"gueules","word_nosc":"gueules","lemma":"gueule","pos":"NOM"} ,
		{"word":"gueules-de-loup","word_nosc":"gueules-de-loup","lemma":"gueules-de-loup","pos":"NOM"} ,
		{"word":"gueuleton","word_nosc":"gueuleton","lemma":"gueuleton","pos":"NOM"} ,
		{"word":"gueuletons","word_nosc":"gueuletons","lemma":"gueuleton","pos":"NOM"} ,
		{"word":"gueulette","word_nosc":"gueulette","lemma":"gueulette","pos":"NOM"} ,
		{"word":"gueulettes","word_nosc":"gueulettes","lemma":"gueulette","pos":"NOM"} ,
		{"word":"gueuloir","word_nosc":"gueuloir","lemma":"gueuloir","pos":"NOM"} ,
		{"word":"gueusards","word_nosc":"gueusards","lemma":"gueusard","pos":"NOM"} ,
		{"word":"gueuse","word_nosc":"gueuse","lemma":"gueux","pos":"NOM"} ,
		{"word":"gueuserie","word_nosc":"gueuserie","lemma":"gueuserie","pos":"NOM"} ,
		{"word":"gueuses","word_nosc":"gueuses","lemma":"gueuse","pos":"NOM"} ,
		{"word":"gueuses","word_nosc":"gueuses","lemma":"gueux","pos":"NOM"} ,
		{"word":"gueux","word_nosc":"gueux","lemma":"gueux","pos":"NOM"} ,
		{"word":"gueuze","word_nosc":"gueuze","lemma":"gueuze","pos":"NOM"} ,
		{"word":"gugusse","word_nosc":"gugusse","lemma":"gugusse","pos":"NOM"} ,
		{"word":"gugusses","word_nosc":"gugusses","lemma":"gugusse","pos":"NOM"} ,
		{"word":"gui","word_nosc":"gui","lemma":"gui","pos":"NOM"} ,
		{"word":"guibole","word_nosc":"guibole","lemma":"guibole","pos":"NOM"} ,
		{"word":"guiboles","word_nosc":"guiboles","lemma":"guibole","pos":"NOM"} ,
		{"word":"guibolle","word_nosc":"guibolle","lemma":"guibolle","pos":"NOM"} ,
		{"word":"guibolles","word_nosc":"guibolles","lemma":"guibolle","pos":"NOM"} ,
		{"word":"guiche","word_nosc":"guiche","lemma":"guiche","pos":"NOM"} ,
		{"word":"guiches","word_nosc":"guiches","lemma":"guiche","pos":"NOM"} ,
		{"word":"guichet","word_nosc":"guichet","lemma":"guichet","pos":"NOM"} ,
		{"word":"guichetier","word_nosc":"guichetier","lemma":"guichetier","pos":"NOM"} ,
		{"word":"guichetiers","word_nosc":"guichetiers","lemma":"guichetier","pos":"NOM"} ,
		{"word":"guichetière","word_nosc":"guichetiere","lemma":"guichetier","pos":"NOM"} ,
		{"word":"guichets","word_nosc":"guichets","lemma":"guichet","pos":"NOM"} ,
		{"word":"guidage","word_nosc":"guidage","lemma":"guidage","pos":"NOM"} ,
		{"word":"guidance","word_nosc":"guidance","lemma":"guidance","pos":"NOM"} ,
		{"word":"guide","word_nosc":"guide","lemma":"guide","pos":"NOM"} ,
		{"word":"guide-interprète","word_nosc":"guide-interprete","lemma":"guide-interprète","pos":"NOM"} ,
		{"word":"guides","word_nosc":"guides","lemma":"guide","pos":"NOM"} ,
		{"word":"guidon","word_nosc":"guidon","lemma":"guidon","pos":"NOM"} ,
		{"word":"guidons","word_nosc":"guidons","lemma":"guidon","pos":"NOM"} ,
		{"word":"guigne","word_nosc":"guigne","lemma":"guigne","pos":"NOM"} ,
		{"word":"guignes","word_nosc":"guignes","lemma":"guigne","pos":"NOM"} ,
		{"word":"guignol","word_nosc":"guignol","lemma":"guignol","pos":"NOM"} ,
		{"word":"guignolades","word_nosc":"guignolades","lemma":"guignolade","pos":"NOM"} ,
		{"word":"guignolet","word_nosc":"guignolet","lemma":"guignolet","pos":"NOM"} ,
		{"word":"guignols","word_nosc":"guignols","lemma":"guignol","pos":"NOM"} ,
		{"word":"guignon","word_nosc":"guignon","lemma":"guignon","pos":"NOM"} ,
		{"word":"guilde","word_nosc":"guilde","lemma":"guilde","pos":"NOM"} ,
		{"word":"guildes","word_nosc":"guildes","lemma":"guilde","pos":"NOM"} ,
		{"word":"guili-guili","word_nosc":"guili-guili","lemma":"guili-guili","pos":"NOM"} ,
		{"word":"guillaume","word_nosc":"guillaume","lemma":"guillaume","pos":"NOM"} ,
		{"word":"guillaumes","word_nosc":"guillaumes","lemma":"guillaume","pos":"NOM"} ,
		{"word":"guilledou","word_nosc":"guilledou","lemma":"guilledou","pos":"NOM"} ,
		{"word":"guillemet","word_nosc":"guillemet","lemma":"guillemet","pos":"NOM"} ,
		{"word":"guillemets","word_nosc":"guillemets","lemma":"guillemet","pos":"NOM"} ,
		{"word":"guillemot","word_nosc":"guillemot","lemma":"guillemot","pos":"NOM"} ,
		{"word":"guillochures","word_nosc":"guillochures","lemma":"guillochure","pos":"NOM"} ,
		{"word":"guillotine","word_nosc":"guillotine","lemma":"guillotine","pos":"NOM"} ,
		{"word":"guillotines","word_nosc":"guillotines","lemma":"guillotin","pos":"NOM"} ,
		{"word":"guillotines","word_nosc":"guillotines","lemma":"guillotine","pos":"NOM"} ,
		{"word":"guillotineur","word_nosc":"guillotineur","lemma":"guillotineur","pos":"NOM"} ,
		{"word":"guillotineurs","word_nosc":"guillotineurs","lemma":"guillotineur","pos":"NOM"} ,
		{"word":"guimauve","word_nosc":"guimauve","lemma":"guimauve","pos":"NOM"} ,
		{"word":"guimauves","word_nosc":"guimauves","lemma":"guimauve","pos":"NOM"} ,
		{"word":"guimbarde","word_nosc":"guimbarde","lemma":"guimbarde","pos":"NOM"} ,
		{"word":"guimbardes","word_nosc":"guimbardes","lemma":"guimbarde","pos":"NOM"} ,
		{"word":"guimpe","word_nosc":"guimpe","lemma":"guimpe","pos":"NOM"} ,
		{"word":"guimpes","word_nosc":"guimpes","lemma":"guimpe","pos":"NOM"} ,
		{"word":"guinche","word_nosc":"guinche","lemma":"guinche","pos":"NOM"} ,
		{"word":"guinches","word_nosc":"guinches","lemma":"guinche","pos":"NOM"} ,
		{"word":"guindal","word_nosc":"guindal","lemma":"guindal","pos":"NOM"} ,
		{"word":"guindals","word_nosc":"guindals","lemma":"guindal","pos":"NOM"} ,
		{"word":"guindant","word_nosc":"guindant","lemma":"guindant","pos":"NOM"} ,
		{"word":"guindeau","word_nosc":"guindeau","lemma":"guindeau","pos":"NOM"} ,
		{"word":"guinguette","word_nosc":"guinguette","lemma":"guinguette","pos":"NOM"} ,
		{"word":"guinguettes","word_nosc":"guinguettes","lemma":"guinguette","pos":"NOM"} ,
		{"word":"guinée","word_nosc":"guinee","lemma":"guinée","pos":"NOM"} ,
		{"word":"guinées","word_nosc":"guinees","lemma":"guinée","pos":"NOM"} ,
		{"word":"guipure","word_nosc":"guipure","lemma":"guipure","pos":"NOM"} ,
		{"word":"guipures","word_nosc":"guipures","lemma":"guipure","pos":"NOM"} ,
		{"word":"guirlande","word_nosc":"guirlande","lemma":"guirlande","pos":"NOM"} ,
		{"word":"guirlandes","word_nosc":"guirlandes","lemma":"guirlande","pos":"NOM"} ,
		{"word":"guise","word_nosc":"guise","lemma":"guise","pos":"NOM"} ,
		{"word":"guises","word_nosc":"guises","lemma":"guis","pos":"NOM"} ,
		{"word":"guitare","word_nosc":"guitare","lemma":"guitare","pos":"NOM"} ,
		{"word":"guitares","word_nosc":"guitares","lemma":"guitare","pos":"NOM"} ,
		{"word":"guitariste","word_nosc":"guitariste","lemma":"guitariste","pos":"NOM"} ,
		{"word":"guitaristes","word_nosc":"guitaristes","lemma":"guitariste","pos":"NOM"} ,
		{"word":"guitoune","word_nosc":"guitoune","lemma":"guitoune","pos":"NOM"} ,
		{"word":"guitounes","word_nosc":"guitounes","lemma":"guitoune","pos":"NOM"} ,
		{"word":"gulden","word_nosc":"gulden","lemma":"gulden","pos":"NOM"} ,
		{"word":"gun","word_nosc":"gun","lemma":"gun","pos":"NOM"} ,
		{"word":"guna","word_nosc":"guna","lemma":"guna","pos":"NOM"} ,
		{"word":"guns","word_nosc":"guns","lemma":"gun","pos":"NOM"} ,
		{"word":"guppy","word_nosc":"guppy","lemma":"guppy","pos":"NOM"} ,
		{"word":"guru","word_nosc":"guru","lemma":"guru","pos":"NOM"} ,
		{"word":"gus","word_nosc":"gus","lemma":"gus","pos":"NOM"} ,
		{"word":"gusse","word_nosc":"gusse","lemma":"gusse","pos":"NOM"} ,
		{"word":"gustation","word_nosc":"gustation","lemma":"gustation","pos":"NOM"} ,
		{"word":"gut","word_nosc":"gut","lemma":"gut","pos":"NOM"} ,
		{"word":"gutta-percha","word_nosc":"gutta-percha","lemma":"gutta-percha","pos":"NOM"} ,
		{"word":"gutturales","word_nosc":"gutturales","lemma":"guttural","pos":"NOM"} ,
		{"word":"guzla","word_nosc":"guzla","lemma":"guzla","pos":"NOM"} ,
		{"word":"guède","word_nosc":"guede","lemma":"guède","pos":"NOM"} ,
		{"word":"gué","word_nosc":"gue","lemma":"gué","pos":"NOM"} ,
		{"word":"guéguerre","word_nosc":"gueguerre","lemma":"guéguerre","pos":"NOM"} ,
		{"word":"guéguerres","word_nosc":"gueguerres","lemma":"guéguerre","pos":"NOM"} ,
		{"word":"guépard","word_nosc":"guepard","lemma":"guépard","pos":"NOM"} ,
		{"word":"guépards","word_nosc":"guepards","lemma":"guépard","pos":"NOM"} ,
		{"word":"guéret","word_nosc":"gueret","lemma":"guéret","pos":"NOM"} ,
		{"word":"guérets","word_nosc":"guerets","lemma":"guéret","pos":"NOM"} ,
		{"word":"guéridon","word_nosc":"gueridon","lemma":"guéridon","pos":"NOM"} ,
		{"word":"guéridons","word_nosc":"gueridons","lemma":"guéridon","pos":"NOM"} ,
		{"word":"guérilla","word_nosc":"guerilla","lemma":"guérilla","pos":"NOM"} ,
		{"word":"guérillas","word_nosc":"guerillas","lemma":"guérilla","pos":"NOM"} ,
		{"word":"guérillero","word_nosc":"guerillero","lemma":"guérillero","pos":"NOM"} ,
		{"word":"guérilleros","word_nosc":"guerilleros","lemma":"guérillero","pos":"NOM"} ,
		{"word":"guérison","word_nosc":"guerison","lemma":"guérison","pos":"NOM"} ,
		{"word":"guérisons","word_nosc":"guerisons","lemma":"guérison","pos":"NOM"} ,
		{"word":"guérisseur","word_nosc":"guerisseur","lemma":"guérisseur","pos":"NOM"} ,
		{"word":"guérisseurs","word_nosc":"guerisseurs","lemma":"guérisseur","pos":"NOM"} ,
		{"word":"guérisseuse","word_nosc":"guerisseuse","lemma":"guérisseur","pos":"NOM"} ,
		{"word":"guérisseuses","word_nosc":"guerisseuses","lemma":"guérisseur","pos":"NOM"} ,
		{"word":"guérite","word_nosc":"guerite","lemma":"guérite","pos":"NOM"} ,
		{"word":"guérites","word_nosc":"guerites","lemma":"guérite","pos":"NOM"} ,
		{"word":"guévariste","word_nosc":"guevariste","lemma":"guévariste","pos":"NOM"} ,
		{"word":"guêpe","word_nosc":"guepe","lemma":"guêpe","pos":"NOM"} ,
		{"word":"guêpes","word_nosc":"guepes","lemma":"guêpe","pos":"NOM"} ,
		{"word":"guêpier","word_nosc":"guepier","lemma":"guêpier","pos":"NOM"} ,
		{"word":"guêpière","word_nosc":"guepiere","lemma":"guêpière","pos":"NOM"} ,
		{"word":"guêpières","word_nosc":"guepieres","lemma":"guêpière","pos":"NOM"} ,
		{"word":"guêtre","word_nosc":"guetre","lemma":"guêtre","pos":"NOM"} ,
		{"word":"guêtres","word_nosc":"guetres","lemma":"guêtre","pos":"NOM"} ,
		{"word":"gym","word_nosc":"gym","lemma":"gym","pos":"NOM"} ,
		{"word":"gymkhana","word_nosc":"gymkhana","lemma":"gymkhana","pos":"NOM"} ,
		{"word":"gymnase","word_nosc":"gymnase","lemma":"gymnase","pos":"NOM"} ,
		{"word":"gymnases","word_nosc":"gymnases","lemma":"gymnase","pos":"NOM"} ,
		{"word":"gymnasium","word_nosc":"gymnasium","lemma":"gymnasium","pos":"NOM"} ,
		{"word":"gymnaste","word_nosc":"gymnaste","lemma":"gymnaste","pos":"NOM"} ,
		{"word":"gymnastes","word_nosc":"gymnastes","lemma":"gymnaste","pos":"NOM"} ,
		{"word":"gymnastique","word_nosc":"gymnastique","lemma":"gymnastique","pos":"NOM"} ,
		{"word":"gymnastiques","word_nosc":"gymnastiques","lemma":"gymnastique","pos":"NOM"} ,
		{"word":"gymnopédie","word_nosc":"gymnopedie","lemma":"gymnopédie","pos":"NOM"} ,
		{"word":"gymnosophiste","word_nosc":"gymnosophiste","lemma":"gymnosophiste","pos":"NOM"} ,
		{"word":"gymnosophistes","word_nosc":"gymnosophistes","lemma":"gymnosophiste","pos":"NOM"} ,
		{"word":"gynéco","word_nosc":"gyneco","lemma":"gynéco","pos":"NOM"} ,
		{"word":"gynécologie","word_nosc":"gynecologie","lemma":"gynécologie","pos":"NOM"} ,
		{"word":"gynécologue","word_nosc":"gynecologue","lemma":"gynécologue","pos":"NOM"} ,
		{"word":"gynécologues","word_nosc":"gynecologues","lemma":"gynécologue","pos":"NOM"} ,
		{"word":"gynécomastie","word_nosc":"gynecomastie","lemma":"gynécomastie","pos":"NOM"} ,
		{"word":"gynécée","word_nosc":"gynecee","lemma":"gynécée","pos":"NOM"} ,
		{"word":"gynécées","word_nosc":"gynecees","lemma":"gynécée","pos":"NOM"} ,
		{"word":"gypaète","word_nosc":"gypaete","lemma":"gypaète","pos":"NOM"} ,
		{"word":"gypse","word_nosc":"gypse","lemma":"gypse","pos":"NOM"} ,
		{"word":"gypses","word_nosc":"gypses","lemma":"gypse","pos":"NOM"} ,
		{"word":"gypsophile","word_nosc":"gypsophile","lemma":"gypsophile","pos":"NOM"} ,
		{"word":"gypsophiles","word_nosc":"gypsophiles","lemma":"gypsophile","pos":"NOM"} ,
		{"word":"gyrins","word_nosc":"gyrins","lemma":"gyrin","pos":"NOM"} ,
		{"word":"gyrocompas","word_nosc":"gyrocompas","lemma":"gyrocompas","pos":"NOM"} ,
		{"word":"gyrophare","word_nosc":"gyrophare","lemma":"gyrophare","pos":"NOM"} ,
		{"word":"gyrophares","word_nosc":"gyrophares","lemma":"gyrophare","pos":"NOM"} ,
		{"word":"gyroscope","word_nosc":"gyroscope","lemma":"gyroscope","pos":"NOM"} ,
		{"word":"gyroscopes","word_nosc":"gyroscopes","lemma":"gyroscope","pos":"NOM"} ,
		{"word":"gyrus","word_nosc":"gyrus","lemma":"gyrus","pos":"NOM"} ,
		{"word":"gâchage","word_nosc":"gachage","lemma":"gâchage","pos":"NOM"} ,
		{"word":"gâche","word_nosc":"gache","lemma":"gâche","pos":"NOM"} ,
		{"word":"gâches","word_nosc":"gaches","lemma":"gâche","pos":"NOM"} ,
		{"word":"gâchette","word_nosc":"gachette","lemma":"gâchette","pos":"NOM"} ,
		{"word":"gâchettes","word_nosc":"gachettes","lemma":"gâchette","pos":"NOM"} ,
		{"word":"gâcheur","word_nosc":"gacheur","lemma":"gâcheur","pos":"NOM"} ,
		{"word":"gâcheurs","word_nosc":"gacheurs","lemma":"gâcheur","pos":"NOM"} ,
		{"word":"gâchis","word_nosc":"gachis","lemma":"gâchis","pos":"NOM"} ,
		{"word":"gâpette","word_nosc":"gapette","lemma":"gâpette","pos":"NOM"} ,
		{"word":"gâte-bois","word_nosc":"gate-bois","lemma":"gâte-bois","pos":"NOM"} ,
		{"word":"gâte-sauce","word_nosc":"gate-sauce","lemma":"gâte-sauce","pos":"NOM"} ,
		{"word":"gâteau","word_nosc":"gateau","lemma":"gâteau","pos":"NOM"} ,
		{"word":"gâteaux","word_nosc":"gateaux","lemma":"gâteau","pos":"NOM"} ,
		{"word":"gâterie","word_nosc":"gaterie","lemma":"gâterie","pos":"NOM"} ,
		{"word":"gâteries","word_nosc":"gateries","lemma":"gâterie","pos":"NOM"} ,
		{"word":"gâteuse","word_nosc":"gateuse","lemma":"gâteur","pos":"NOM"} ,
		{"word":"gâteux","word_nosc":"gateux","lemma":"gâteux","pos":"NOM"} ,
		{"word":"gâtine","word_nosc":"gatine","lemma":"gâtine","pos":"NOM"} ,
		{"word":"gâtisme","word_nosc":"gatisme","lemma":"gâtisme","pos":"NOM"} ,
		{"word":"gène","word_nosc":"gene","lemma":"gène","pos":"NOM"} ,
		{"word":"gènes","word_nosc":"genes","lemma":"gène","pos":"NOM"} ,
		{"word":"géant","word_nosc":"geant","lemma":"géant","pos":"NOM"} ,
		{"word":"géante","word_nosc":"geante","lemma":"géant","pos":"NOM"} ,
		{"word":"géantes","word_nosc":"geantes","lemma":"géant","pos":"NOM"} ,
		{"word":"géants","word_nosc":"geants","lemma":"géant","pos":"NOM"} ,
		{"word":"gégène","word_nosc":"gegene","lemma":"gégène","pos":"NOM"} ,
		{"word":"géhenne","word_nosc":"gehenne","lemma":"géhenne","pos":"NOM"} ,
		{"word":"géhennes","word_nosc":"gehennes","lemma":"géhenne","pos":"NOM"} ,
		{"word":"gélatine","word_nosc":"gelatine","lemma":"gélatine","pos":"NOM"} ,
		{"word":"gélatines","word_nosc":"gelatines","lemma":"gélatine","pos":"NOM"} ,
		{"word":"gélatino-bromure","word_nosc":"gelatino-bromure","lemma":"gélatino-bromure","pos":"NOM"} ,
		{"word":"gélifiant","word_nosc":"gelifiant","lemma":"gélifiant","pos":"NOM"} ,
		{"word":"gélification","word_nosc":"gelification","lemma":"gélification","pos":"NOM"} ,
		{"word":"géline","word_nosc":"geline","lemma":"géline","pos":"NOM"} ,
		{"word":"gélines","word_nosc":"gelines","lemma":"géline","pos":"NOM"} ,
		{"word":"gélinotte","word_nosc":"gelinotte","lemma":"gélinotte","pos":"NOM"} ,
		{"word":"gélolevure","word_nosc":"gelolevure","lemma":"gélolevure","pos":"NOM"} ,
		{"word":"gélose","word_nosc":"gelose","lemma":"gélose","pos":"NOM"} ,
		{"word":"gélule","word_nosc":"gelule","lemma":"gélule","pos":"NOM"} ,
		{"word":"gélules","word_nosc":"gelules","lemma":"gélule","pos":"NOM"} ,
		{"word":"gémeau","word_nosc":"gemeau","lemma":"gémeau","pos":"NOM"} ,
		{"word":"gémeaux","word_nosc":"gemeaux","lemma":"gémeau","pos":"NOM"} ,
		{"word":"gémellité","word_nosc":"gemellite","lemma":"gémellité","pos":"NOM"} ,
		{"word":"gémissement","word_nosc":"gemissement","lemma":"gémissement","pos":"NOM"} ,
		{"word":"gémissements","word_nosc":"gemissements","lemma":"gémissement","pos":"NOM"} ,
		{"word":"gémonies","word_nosc":"gemonies","lemma":"gémonies","pos":"NOM"} ,
		{"word":"génialité","word_nosc":"genialite","lemma":"génialité","pos":"NOM"} ,
		{"word":"génie","word_nosc":"genie","lemma":"génie","pos":"NOM"} ,
		{"word":"génies","word_nosc":"genies","lemma":"génie","pos":"NOM"} ,
		{"word":"génisse","word_nosc":"genisse","lemma":"génisse","pos":"NOM"} ,
		{"word":"génisses","word_nosc":"genisses","lemma":"génisse","pos":"NOM"} ,
		{"word":"génitalité","word_nosc":"genitalite","lemma":"génitalité","pos":"NOM"} ,
		{"word":"géniteur","word_nosc":"geniteur","lemma":"géniteur","pos":"NOM"} ,
		{"word":"géniteurs","word_nosc":"geniteurs","lemma":"géniteur","pos":"NOM"} ,
		{"word":"génitoires","word_nosc":"genitoires","lemma":"génitoire","pos":"NOM"} ,
		{"word":"génitrice","word_nosc":"genitrice","lemma":"géniteur","pos":"NOM"} ,
		{"word":"génitrices","word_nosc":"genitrices","lemma":"géniteur","pos":"NOM"} ,
		{"word":"génocide","word_nosc":"genocide","lemma":"génocide","pos":"NOM"} ,
		{"word":"génocides","word_nosc":"genocides","lemma":"génocide","pos":"NOM"} ,
		{"word":"génois","word_nosc":"genois","lemma":"génois","pos":"NOM"} ,
		{"word":"génoise","word_nosc":"genoise","lemma":"génois","pos":"NOM"} ,
		{"word":"génoises","word_nosc":"genoises","lemma":"génois","pos":"NOM"} ,
		{"word":"génome","word_nosc":"genome","lemma":"génome","pos":"NOM"} ,
		{"word":"génomique","word_nosc":"genomique","lemma":"génomique","pos":"NOM"} ,
		{"word":"génotype","word_nosc":"genotype","lemma":"génotype","pos":"NOM"} ,
		{"word":"génotypes","word_nosc":"genotypes","lemma":"génotype","pos":"NOM"} ,
		{"word":"génovéfains","word_nosc":"genovefains","lemma":"génovéfain","pos":"NOM"} ,
		{"word":"génuflexion","word_nosc":"genuflexion","lemma":"génuflexion","pos":"NOM"} ,
		{"word":"génuflexions","word_nosc":"genuflexions","lemma":"génuflexion","pos":"NOM"} ,
		{"word":"généalogie","word_nosc":"genealogie","lemma":"généalogie","pos":"NOM"} ,
		{"word":"généalogies","word_nosc":"genealogies","lemma":"généalogie","pos":"NOM"} ,
		{"word":"généalogiste","word_nosc":"genealogiste","lemma":"généalogiste","pos":"NOM"} ,
		{"word":"généalogistes","word_nosc":"genealogistes","lemma":"généalogiste","pos":"NOM"} ,
		{"word":"général","word_nosc":"general","lemma":"général","pos":"NOM"} ,
		{"word":"générale","word_nosc":"generale","lemma":"général","pos":"NOM"} ,
		{"word":"générales","word_nosc":"generales","lemma":"général","pos":"NOM"} ,
		{"word":"généralife","word_nosc":"generalife","lemma":"généralife","pos":"NOM"} ,
		{"word":"généralisation","word_nosc":"generalisation","lemma":"généralisation","pos":"NOM"} ,
		{"word":"généralisations","word_nosc":"generalisations","lemma":"généralisation","pos":"NOM"} ,
		{"word":"généralissime","word_nosc":"generalissime","lemma":"généralissime","pos":"NOM"} ,
		{"word":"généraliste","word_nosc":"generaliste","lemma":"généraliste","pos":"NOM"} ,
		{"word":"généralistes","word_nosc":"generalistes","lemma":"généraliste","pos":"NOM"} ,
		{"word":"généralité","word_nosc":"generalite","lemma":"généralité","pos":"NOM"} ,
		{"word":"généralités","word_nosc":"generalites","lemma":"généralité","pos":"NOM"} ,
		{"word":"générateur","word_nosc":"generateur","lemma":"générateur","pos":"NOM"} ,
		{"word":"générateurs","word_nosc":"generateurs","lemma":"générateur","pos":"NOM"} ,
		{"word":"génération","word_nosc":"generation","lemma":"génération","pos":"NOM"} ,
		{"word":"générations","word_nosc":"generations","lemma":"génération","pos":"NOM"} ,
		{"word":"génératrice","word_nosc":"generatrice","lemma":"générateur","pos":"NOM"} ,
		{"word":"génératrices","word_nosc":"generatrices","lemma":"générateur","pos":"NOM"} ,
		{"word":"généraux","word_nosc":"generaux","lemma":"général","pos":"NOM"} ,
		{"word":"générique","word_nosc":"generique","lemma":"générique","pos":"NOM"} ,
		{"word":"génériques","word_nosc":"generiques","lemma":"générique","pos":"NOM"} ,
		{"word":"générosité","word_nosc":"generosite","lemma":"générosité","pos":"NOM"} ,
		{"word":"générosités","word_nosc":"generosites","lemma":"générosité","pos":"NOM"} ,
		{"word":"généticien","word_nosc":"geneticien","lemma":"généticien","pos":"NOM"} ,
		{"word":"généticienne","word_nosc":"geneticienne","lemma":"généticien","pos":"NOM"} ,
		{"word":"généticiens","word_nosc":"geneticiens","lemma":"généticien","pos":"NOM"} ,
		{"word":"génétique","word_nosc":"genetique","lemma":"génétique","pos":"NOM"} ,
		{"word":"génétiques","word_nosc":"genetiques","lemma":"génétique","pos":"NOM"} ,
		{"word":"géo","word_nosc":"geo","lemma":"géo","pos":"NOM"} ,
		{"word":"géocorises","word_nosc":"geocorises","lemma":"géocorise","pos":"NOM"} ,
		{"word":"géode","word_nosc":"geode","lemma":"géode","pos":"NOM"} ,
		{"word":"géodésigraphe","word_nosc":"geodesigraphe","lemma":"géodésigraphe","pos":"NOM"} ,
		{"word":"géographe","word_nosc":"geographe","lemma":"géographe","pos":"NOM"} ,
		{"word":"géographes","word_nosc":"geographes","lemma":"géographe","pos":"NOM"} ,
		{"word":"géographie","word_nosc":"geographie","lemma":"géographie","pos":"NOM"} ,
		{"word":"géographies","word_nosc":"geographies","lemma":"géographie","pos":"NOM"} ,
		{"word":"géologie","word_nosc":"geologie","lemma":"géologie","pos":"NOM"} ,
		{"word":"géologue","word_nosc":"geologue","lemma":"géologue","pos":"NOM"} ,
		{"word":"géologues","word_nosc":"geologues","lemma":"géologue","pos":"NOM"} ,
		{"word":"géomancie","word_nosc":"geomancie","lemma":"géomancie","pos":"NOM"} ,
		{"word":"géomètre","word_nosc":"geometre","lemma":"géomètre","pos":"NOM"} ,
		{"word":"géomètres","word_nosc":"geometres","lemma":"géomètre","pos":"NOM"} ,
		{"word":"géométrie","word_nosc":"geometrie","lemma":"géométrie","pos":"NOM"} ,
		{"word":"géométries","word_nosc":"geometries","lemma":"géométrie","pos":"NOM"} ,
		{"word":"géophysiciens","word_nosc":"geophysiciens","lemma":"géophysicien","pos":"NOM"} ,
		{"word":"géophysique","word_nosc":"geophysique","lemma":"géophysique","pos":"NOM"} ,
		{"word":"géopolitique","word_nosc":"geopolitique","lemma":"géopolitique","pos":"NOM"} ,
		{"word":"géorgienne","word_nosc":"georgienne","lemma":"géorgien","pos":"NOM"} ,
		{"word":"géorgiens","word_nosc":"georgiens","lemma":"géorgien","pos":"NOM"} ,
		{"word":"géosciences","word_nosc":"geosciences","lemma":"géoscience","pos":"NOM"} ,
		{"word":"géostratégie","word_nosc":"geostrategie","lemma":"géostratégie","pos":"NOM"} ,
		{"word":"géothermie","word_nosc":"geothermie","lemma":"géothermie","pos":"NOM"} ,
		{"word":"gérance","word_nosc":"gerance","lemma":"gérance","pos":"NOM"} ,
		{"word":"géranium","word_nosc":"geranium","lemma":"géranium","pos":"NOM"} ,
		{"word":"géraniums","word_nosc":"geraniums","lemma":"géranium","pos":"NOM"} ,
		{"word":"gérant","word_nosc":"gerant","lemma":"gérant","pos":"NOM"} ,
		{"word":"gérante","word_nosc":"gerante","lemma":"gérant","pos":"NOM"} ,
		{"word":"gérants","word_nosc":"gerants","lemma":"gérant","pos":"NOM"} ,
		{"word":"gériatrie","word_nosc":"geriatrie","lemma":"gériatrie","pos":"NOM"} ,
		{"word":"géronte","word_nosc":"geronte","lemma":"géronte","pos":"NOM"} ,
		{"word":"gérontes","word_nosc":"gerontes","lemma":"géronte","pos":"NOM"} ,
		{"word":"gérontisme","word_nosc":"gerontisme","lemma":"gérontisme","pos":"NOM"} ,
		{"word":"gérontologie","word_nosc":"gerontologie","lemma":"gérontologie","pos":"NOM"} ,
		{"word":"gérontologue","word_nosc":"gerontologue","lemma":"gérontologue","pos":"NOM"} ,
		{"word":"gérontophiles","word_nosc":"gerontophiles","lemma":"gérontophile","pos":"NOM"} ,
		{"word":"géré","word_nosc":"gere","lemma":"géré","pos":"NOM"} ,
		{"word":"gésier","word_nosc":"gesier","lemma":"gésier","pos":"NOM"} ,
		{"word":"gésiers","word_nosc":"gesiers","lemma":"gésier","pos":"NOM"} ,
		{"word":"gésine","word_nosc":"gesine","lemma":"gésine","pos":"NOM"} ,
		{"word":"gêne","word_nosc":"gene","lemma":"gêne","pos":"NOM"} ,
		{"word":"gênes","word_nosc":"genes","lemma":"gêne","pos":"NOM"} ,
		{"word":"gêneur","word_nosc":"geneur","lemma":"gêneur","pos":"NOM"} ,
		{"word":"gêneurs","word_nosc":"geneurs","lemma":"gêneur","pos":"NOM"} ,
		{"word":"gêneuse","word_nosc":"geneuse","lemma":"gêneur","pos":"NOM"} ,
		{"word":"gêneuses","word_nosc":"geneuses","lemma":"gêneur","pos":"NOM"} ,
		{"word":"gîte","word_nosc":"gite","lemma":"gîte","pos":"NOM"} ,
		{"word":"gîtes","word_nosc":"gites","lemma":"gîte","pos":"NOM"} ,
		{"word":"ha","word_nosc":"ha","lemma":"ha","pos":"NOM"} ,
		{"word":"habanera","word_nosc":"habanera","lemma":"habanera","pos":"NOM"} ,
		{"word":"habileté","word_nosc":"habilete","lemma":"habileté","pos":"NOM"} ,
		{"word":"habiletés","word_nosc":"habiletes","lemma":"habileté","pos":"NOM"} ,
		{"word":"habilitation","word_nosc":"habilitation","lemma":"habilitation","pos":"NOM"} ,
		{"word":"habilitations","word_nosc":"habilitations","lemma":"habilitation","pos":"NOM"} ,
		{"word":"habilité","word_nosc":"habilite","lemma":"habilité","pos":"NOM"} ,
		{"word":"habilités","word_nosc":"habilites","lemma":"habilité","pos":"NOM"} ,
		{"word":"habillage","word_nosc":"habillage","lemma":"habillage","pos":"NOM"} ,
		{"word":"habillement","word_nosc":"habillement","lemma":"habillement","pos":"NOM"} ,
		{"word":"habilleur","word_nosc":"habilleur","lemma":"habilleur","pos":"NOM"} ,
		{"word":"habilleurs","word_nosc":"habilleurs","lemma":"habilleur","pos":"NOM"} ,
		{"word":"habilleuse","word_nosc":"habilleuse","lemma":"habilleur","pos":"NOM"} ,
		{"word":"habilleuses","word_nosc":"habilleuses","lemma":"habilleur","pos":"NOM"} ,
		{"word":"habit","word_nosc":"habit","lemma":"habit","pos":"NOM"} ,
		{"word":"habitabilité","word_nosc":"habitabilite","lemma":"habitabilité","pos":"NOM"} ,
		{"word":"habitacle","word_nosc":"habitacle","lemma":"habitacle","pos":"NOM"} ,
		{"word":"habitacles","word_nosc":"habitacles","lemma":"habitacle","pos":"NOM"} ,
		{"word":"habitant","word_nosc":"habitant","lemma":"habitant","pos":"NOM"} ,
		{"word":"habitante","word_nosc":"habitante","lemma":"habitant","pos":"NOM"} ,
		{"word":"habitants","word_nosc":"habitants","lemma":"habitant","pos":"NOM"} ,
		{"word":"habitat","word_nosc":"habitat","lemma":"habitat","pos":"NOM"} ,
		{"word":"habitation","word_nosc":"habitation","lemma":"habitation","pos":"NOM"} ,
		{"word":"habitations","word_nosc":"habitations","lemma":"habitation","pos":"NOM"} ,
		{"word":"habitats","word_nosc":"habitats","lemma":"habitat","pos":"NOM"} ,
		{"word":"habits","word_nosc":"habits","lemma":"habit","pos":"NOM"} ,
		{"word":"habitude","word_nosc":"habitude","lemma":"habitude","pos":"NOM"} ,
		{"word":"habitudes","word_nosc":"habitudes","lemma":"habitude","pos":"NOM"} ,
		{"word":"habitus","word_nosc":"habitus","lemma":"habitus","pos":"NOM"} ,
		{"word":"habitué","word_nosc":"habitue","lemma":"habitué","pos":"NOM"} ,
		{"word":"habituée","word_nosc":"habituee","lemma":"habitué","pos":"NOM"} ,
		{"word":"habituées","word_nosc":"habituees","lemma":"habitué","pos":"NOM"} ,
		{"word":"habitués","word_nosc":"habitues","lemma":"habitué","pos":"NOM"} ,
		{"word":"hachage","word_nosc":"hachage","lemma":"hachage","pos":"NOM"} ,
		{"word":"hachard","word_nosc":"hachard","lemma":"hachard","pos":"NOM"} ,
		{"word":"hache","word_nosc":"hache","lemma":"hache","pos":"NOM"} ,
		{"word":"hache-paille","word_nosc":"hache-paille","lemma":"hache-paille","pos":"NOM"} ,
		{"word":"haches","word_nosc":"haches","lemma":"hache","pos":"NOM"} ,
		{"word":"hachette","word_nosc":"hachette","lemma":"hachette","pos":"NOM"} ,
		{"word":"hachettes","word_nosc":"hachettes","lemma":"hachette","pos":"NOM"} ,
		{"word":"hacheur","word_nosc":"hacheur","lemma":"hacheur","pos":"NOM"} ,
		{"word":"hacheurs","word_nosc":"hacheurs","lemma":"hacheur","pos":"NOM"} ,
		{"word":"hachis","word_nosc":"hachis","lemma":"hachis","pos":"NOM"} ,
		{"word":"hachisch","word_nosc":"hachisch","lemma":"hachisch","pos":"NOM"} ,
		{"word":"hachoir","word_nosc":"hachoir","lemma":"hachoir","pos":"NOM"} ,
		{"word":"hachoirs","word_nosc":"hachoirs","lemma":"hachoir","pos":"NOM"} ,
		{"word":"hachure","word_nosc":"hachure","lemma":"hachure","pos":"NOM"} ,
		{"word":"hachures","word_nosc":"hachures","lemma":"hachure","pos":"NOM"} ,
		{"word":"hacienda","word_nosc":"hacienda","lemma":"hacienda","pos":"NOM"} ,
		{"word":"haciendas","word_nosc":"haciendas","lemma":"hacienda","pos":"NOM"} ,
		{"word":"hack","word_nosc":"hack","lemma":"hack","pos":"NOM"} ,
		{"word":"hacker","word_nosc":"hacker","lemma":"hacker","pos":"NOM"} ,
		{"word":"hackers","word_nosc":"hackers","lemma":"hacker","pos":"NOM"} ,
		{"word":"haddock","word_nosc":"haddock","lemma":"haddock","pos":"NOM"} ,
		{"word":"hadith","word_nosc":"hadith","lemma":"hadith","pos":"NOM"} ,
		{"word":"hadj","word_nosc":"hadj","lemma":"hadj","pos":"NOM"} ,
		{"word":"hadji","word_nosc":"hadji","lemma":"hadji","pos":"NOM"} ,
		{"word":"haggadah","word_nosc":"haggadah","lemma":"haggadah","pos":"NOM"} ,
		{"word":"haggis","word_nosc":"haggis","lemma":"haggis","pos":"NOM"} ,
		{"word":"hagiographe","word_nosc":"hagiographe","lemma":"hagiographe","pos":"NOM"} ,
		{"word":"hagiographie","word_nosc":"hagiographie","lemma":"hagiographie","pos":"NOM"} ,
		{"word":"haie","word_nosc":"haie","lemma":"haie","pos":"NOM"} ,
		{"word":"haies","word_nosc":"haies","lemma":"haie","pos":"NOM"} ,
		{"word":"haillon","word_nosc":"haillon","lemma":"haillon","pos":"NOM"} ,
		{"word":"haillons","word_nosc":"haillons","lemma":"haillon","pos":"NOM"} ,
		{"word":"haine","word_nosc":"haine","lemma":"haine","pos":"NOM"} ,
		{"word":"haines","word_nosc":"haines","lemma":"haine","pos":"NOM"} ,
		{"word":"haire","word_nosc":"haire","lemma":"haire","pos":"NOM"} ,
		{"word":"haires","word_nosc":"haires","lemma":"haire","pos":"NOM"} ,
		{"word":"haka","word_nosc":"haka","lemma":"haka","pos":"NOM"} ,
		{"word":"hakka","word_nosc":"hakka","lemma":"hakka","pos":"NOM"} ,
		{"word":"halage","word_nosc":"halage","lemma":"halage","pos":"NOM"} ,
		{"word":"halages","word_nosc":"halages","lemma":"halage","pos":"NOM"} ,
		{"word":"halcyon","word_nosc":"halcyon","lemma":"halcyon","pos":"NOM"} ,
		{"word":"hale","word_nosc":"hale","lemma":"hale","pos":"NOM"} ,
		{"word":"haleine","word_nosc":"haleine","lemma":"haleine","pos":"NOM"} ,
		{"word":"haleines","word_nosc":"haleines","lemma":"haleine","pos":"NOM"} ,
		{"word":"haleur","word_nosc":"haleur","lemma":"haleur","pos":"NOM"} ,
		{"word":"haleurs","word_nosc":"haleurs","lemma":"haleur","pos":"NOM"} ,
		{"word":"half-track","word_nosc":"half-track","lemma":"half-track","pos":"NOM"} ,
		{"word":"half-tracks","word_nosc":"half-tracks","lemma":"half-track","pos":"NOM"} ,
		{"word":"hall","word_nosc":"hall","lemma":"hall","pos":"NOM"} ,
		{"word":"hallali","word_nosc":"hallali","lemma":"hallali","pos":"NOM"} ,
		{"word":"halle","word_nosc":"halle","lemma":"halle","pos":"NOM"} ,
		{"word":"hallebarde","word_nosc":"hallebarde","lemma":"hallebarde","pos":"NOM"} ,
		{"word":"hallebardes","word_nosc":"hallebardes","lemma":"hallebarde","pos":"NOM"} ,
		{"word":"hallebardier","word_nosc":"hallebardier","lemma":"hallebardier","pos":"NOM"} ,
		{"word":"hallebardiers","word_nosc":"hallebardiers","lemma":"hallebardier","pos":"NOM"} ,
		{"word":"halles","word_nosc":"halles","lemma":"halle","pos":"NOM"} ,
		{"word":"hallier","word_nosc":"hallier","lemma":"hallier","pos":"NOM"} ,
		{"word":"halliers","word_nosc":"halliers","lemma":"hallier","pos":"NOM"} ,
		{"word":"halloween","word_nosc":"halloween","lemma":"halloween","pos":"NOM"} ,
		{"word":"halls","word_nosc":"halls","lemma":"hall","pos":"NOM"} ,
		{"word":"hallucination","word_nosc":"hallucination","lemma":"hallucination","pos":"NOM"} ,
		{"word":"hallucinations","word_nosc":"hallucinations","lemma":"hallucination","pos":"NOM"} ,
		{"word":"hallucinogène","word_nosc":"hallucinogene","lemma":"hallucinogène","pos":"NOM"} ,
		{"word":"hallucinogènes","word_nosc":"hallucinogenes","lemma":"hallucinogène","pos":"NOM"} ,
		{"word":"halluciné","word_nosc":"hallucine","lemma":"halluciné","pos":"NOM"} ,
		{"word":"hallucinée","word_nosc":"hallucinee","lemma":"halluciné","pos":"NOM"} ,
		{"word":"hallucinés","word_nosc":"hallucines","lemma":"halluciné","pos":"NOM"} ,
		{"word":"halo","word_nosc":"halo","lemma":"halo","pos":"NOM"} ,
		{"word":"halogène","word_nosc":"halogene","lemma":"halogène","pos":"NOM"} ,
		{"word":"halogènes","word_nosc":"halogenes","lemma":"halogène","pos":"NOM"} ,
		{"word":"halogénure","word_nosc":"halogenure","lemma":"halogénure","pos":"NOM"} ,
		{"word":"halon","word_nosc":"halon","lemma":"halon","pos":"NOM"} ,
		{"word":"halons","word_nosc":"halons","lemma":"halon","pos":"NOM"} ,
		{"word":"halopéridol","word_nosc":"haloperidol","lemma":"halopéridol","pos":"NOM"} ,
		{"word":"halos","word_nosc":"halos","lemma":"halo","pos":"NOM"} ,
		{"word":"halothane","word_nosc":"halothane","lemma":"halothane","pos":"NOM"} ,
		{"word":"halte","word_nosc":"halte","lemma":"halte","pos":"NOM"} ,
		{"word":"halte-garderie","word_nosc":"halte-garderie","lemma":"halte-garderie","pos":"NOM"} ,
		{"word":"haltes","word_nosc":"haltes","lemma":"halte","pos":"NOM"} ,
		{"word":"haltère","word_nosc":"haltere","lemma":"haltère","pos":"NOM"} ,
		{"word":"haltères","word_nosc":"halteres","lemma":"haltère","pos":"NOM"} ,
		{"word":"haltérophile","word_nosc":"halterophile","lemma":"haltérophile","pos":"NOM"} ,
		{"word":"haltérophiles","word_nosc":"halterophiles","lemma":"haltérophile","pos":"NOM"} ,
		{"word":"haltérophilie","word_nosc":"halterophilie","lemma":"haltérophilie","pos":"NOM"} ,
		{"word":"halva","word_nosc":"halva","lemma":"halva","pos":"NOM"} ,
		{"word":"halètement","word_nosc":"haletement","lemma":"halètement","pos":"NOM"} ,
		{"word":"halètements","word_nosc":"haletements","lemma":"halètement","pos":"NOM"} ,
		{"word":"hamac","word_nosc":"hamac","lemma":"hamac","pos":"NOM"} ,
		{"word":"hamacs","word_nosc":"hamacs","lemma":"hamac","pos":"NOM"} ,
		{"word":"hamada","word_nosc":"hamada","lemma":"hamada","pos":"NOM"} ,
		{"word":"hamadryade","word_nosc":"hamadryade","lemma":"hamadryade","pos":"NOM"} ,
		{"word":"hamadryas","word_nosc":"hamadryas","lemma":"hamadryas","pos":"NOM"} ,
		{"word":"hamamélis","word_nosc":"hamamelis","lemma":"hamamélis","pos":"NOM"} ,
		{"word":"hambourgeois","word_nosc":"hambourgeois","lemma":"hambourgeois","pos":"NOM"} ,
		{"word":"hambourgeoises","word_nosc":"hambourgeoises","lemma":"hambourgeois","pos":"NOM"} ,
		{"word":"hamburger","word_nosc":"hamburger","lemma":"hamburger","pos":"NOM"} ,
		{"word":"hamburgers","word_nosc":"hamburgers","lemma":"hamburger","pos":"NOM"} ,
		{"word":"hameau","word_nosc":"hameau","lemma":"hameau","pos":"NOM"} ,
		{"word":"hameaux","word_nosc":"hameaux","lemma":"hameau","pos":"NOM"} ,
		{"word":"hameçon","word_nosc":"hamecon","lemma":"hameçon","pos":"NOM"} ,
		{"word":"hameçons","word_nosc":"hamecons","lemma":"hameçon","pos":"NOM"} ,
		{"word":"hammam","word_nosc":"hammam","lemma":"hammam","pos":"NOM"} ,
		{"word":"hammams","word_nosc":"hammams","lemma":"hammam","pos":"NOM"} ,
		{"word":"hammerless","word_nosc":"hammerless","lemma":"hammerless","pos":"NOM"} ,
		{"word":"hampe","word_nosc":"hampe","lemma":"hampe","pos":"NOM"} ,
		{"word":"hampes","word_nosc":"hampes","lemma":"hampe","pos":"NOM"} ,
		{"word":"hamster","word_nosc":"hamster","lemma":"hamster","pos":"NOM"} ,
		{"word":"hamsters","word_nosc":"hamsters","lemma":"hamster","pos":"NOM"} ,
		{"word":"han","word_nosc":"han","lemma":"han","pos":"NOM"} ,
		{"word":"hanap","word_nosc":"hanap","lemma":"hanap","pos":"NOM"} ,
		{"word":"hanaps","word_nosc":"hanaps","lemma":"hanap","pos":"NOM"} ,
		{"word":"hanche","word_nosc":"hanche","lemma":"hanche","pos":"NOM"} ,
		{"word":"hanches","word_nosc":"hanches","lemma":"hanche","pos":"NOM"} ,
		{"word":"hand-ball","word_nosc":"hand-ball","lemma":"hand-ball","pos":"NOM"} ,
		{"word":"handball","word_nosc":"handball","lemma":"handball","pos":"NOM"} ,
		{"word":"handicap","word_nosc":"handicap","lemma":"handicap","pos":"NOM"} ,
		{"word":"handicapeur","word_nosc":"handicapeur","lemma":"handicapeur","pos":"NOM"} ,
		{"word":"handicaps","word_nosc":"handicaps","lemma":"handicap","pos":"NOM"} ,
		{"word":"handicapé","word_nosc":"handicape","lemma":"handicapé","pos":"NOM"} ,
		{"word":"handicapée","word_nosc":"handicapee","lemma":"handicapé","pos":"NOM"} ,
		{"word":"handicapées","word_nosc":"handicapees","lemma":"handicapé","pos":"NOM"} ,
		{"word":"handicapés","word_nosc":"handicapes","lemma":"handicapé","pos":"NOM"} ,
		{"word":"hangar","word_nosc":"hangar","lemma":"hangar","pos":"NOM"} ,
		{"word":"hangars","word_nosc":"hangars","lemma":"hangar","pos":"NOM"} ,
		{"word":"hanneton","word_nosc":"hanneton","lemma":"hanneton","pos":"NOM"} ,
		{"word":"hannetons","word_nosc":"hannetons","lemma":"hanneton","pos":"NOM"} ,
		{"word":"hanoukka","word_nosc":"hanoukka","lemma":"hanoukka","pos":"NOM"} ,
		{"word":"hanovriens","word_nosc":"hanovriens","lemma":"hanovrien","pos":"NOM"} ,
		{"word":"hantavirus","word_nosc":"hantavirus","lemma":"hantavirus","pos":"NOM"} ,
		{"word":"hantise","word_nosc":"hantise","lemma":"hantise","pos":"NOM"} ,
		{"word":"hantises","word_nosc":"hantises","lemma":"hantise","pos":"NOM"} ,
		{"word":"happe","word_nosc":"happe","lemma":"happe","pos":"NOM"} ,
		{"word":"happement","word_nosc":"happement","lemma":"happement","pos":"NOM"} ,
		{"word":"happening","word_nosc":"happening","lemma":"happening","pos":"NOM"} ,
		{"word":"happenings","word_nosc":"happenings","lemma":"happening","pos":"NOM"} ,
		{"word":"happeurs","word_nosc":"happeurs","lemma":"happeur","pos":"NOM"} ,
		{"word":"happy end","word_nosc":"happy end","lemma":"happy end","pos":"NOM"} ,
		{"word":"happy ends","word_nosc":"happy ends","lemma":"happy end","pos":"NOM"} ,
		{"word":"happy few","word_nosc":"happy few","lemma":"happy few","pos":"NOM"} ,
		{"word":"haptoglobine","word_nosc":"haptoglobine","lemma":"haptoglobine","pos":"NOM"} ,
		{"word":"haque","word_nosc":"haque","lemma":"haque","pos":"NOM"} ,
		{"word":"haquenée","word_nosc":"haquenee","lemma":"haquenée","pos":"NOM"} ,
		{"word":"haquet","word_nosc":"haquet","lemma":"haquet","pos":"NOM"} ,
		{"word":"haquets","word_nosc":"haquets","lemma":"haquet","pos":"NOM"} ,
		{"word":"hara-kiri","word_nosc":"hara-kiri","lemma":"hara-kiri","pos":"NOM"} ,
		{"word":"harangue","word_nosc":"harangue","lemma":"harangue","pos":"NOM"} ,
		{"word":"harangues","word_nosc":"harangues","lemma":"harangue","pos":"NOM"} ,
		{"word":"harari","word_nosc":"harari","lemma":"harari","pos":"NOM"} ,
		{"word":"haras","word_nosc":"haras","lemma":"haras","pos":"NOM"} ,
		{"word":"harassement","word_nosc":"harassement","lemma":"harassement","pos":"NOM"} ,
		{"word":"harceleur","word_nosc":"harceleur","lemma":"harceleur","pos":"NOM"} ,
		{"word":"harceleuse","word_nosc":"harceleuse","lemma":"harceleur","pos":"NOM"} ,
		{"word":"harcèlement","word_nosc":"harcelement","lemma":"harcèlement","pos":"NOM"} ,
		{"word":"harcèlements","word_nosc":"harcelements","lemma":"harcèlement","pos":"NOM"} ,
		{"word":"hard","word_nosc":"hard","lemma":"hard","pos":"NOM"} ,
		{"word":"hard edge","word_nosc":"hard edge","lemma":"hard edge","pos":"NOM"} ,
		{"word":"hard-top","word_nosc":"hard-top","lemma":"hard-top","pos":"NOM"} ,
		{"word":"harde","word_nosc":"harde","lemma":"harde","pos":"NOM"} ,
		{"word":"hardes","word_nosc":"hardes","lemma":"harde","pos":"NOM"} ,
		{"word":"hardiesse","word_nosc":"hardiesse","lemma":"hardiesse","pos":"NOM"} ,
		{"word":"hardiesses","word_nosc":"hardiesses","lemma":"hardiesse","pos":"NOM"} ,
		{"word":"harem","word_nosc":"harem","lemma":"harem","pos":"NOM"} ,
		{"word":"harems","word_nosc":"harems","lemma":"harem","pos":"NOM"} ,
		{"word":"hareng","word_nosc":"hareng","lemma":"hareng","pos":"NOM"} ,
		{"word":"harengs","word_nosc":"harengs","lemma":"hareng","pos":"NOM"} ,
		{"word":"harenguet","word_nosc":"harenguet","lemma":"harenguet","pos":"NOM"} ,
		{"word":"harenguier","word_nosc":"harenguier","lemma":"harenguier","pos":"NOM"} ,
		{"word":"harengère","word_nosc":"harengere","lemma":"harengère","pos":"NOM"} ,
		{"word":"harfang","word_nosc":"harfang","lemma":"harfang","pos":"NOM"} ,
		{"word":"hargne","word_nosc":"hargne","lemma":"hargne","pos":"NOM"} ,
		{"word":"hargnes","word_nosc":"hargnes","lemma":"hargne","pos":"NOM"} ,
		{"word":"haricot","word_nosc":"haricot","lemma":"haricot","pos":"NOM"} ,
		{"word":"haricots","word_nosc":"haricots","lemma":"haricot","pos":"NOM"} ,
		{"word":"haridelle","word_nosc":"haridelle","lemma":"haridelle","pos":"NOM"} ,
		{"word":"haridelles","word_nosc":"haridelles","lemma":"haridelle","pos":"NOM"} ,
		{"word":"harissa","word_nosc":"harissa","lemma":"harissa","pos":"NOM"} ,
		{"word":"harki","word_nosc":"harki","lemma":"harki","pos":"NOM"} ,
		{"word":"harkis","word_nosc":"harkis","lemma":"harki","pos":"NOM"} ,
		{"word":"harmattan","word_nosc":"harmattan","lemma":"harmattan","pos":"NOM"} ,
		{"word":"harmonica","word_nosc":"harmonica","lemma":"harmonica","pos":"NOM"} ,
		{"word":"harmonicas","word_nosc":"harmonicas","lemma":"harmonica","pos":"NOM"} ,
		{"word":"harmoniciste","word_nosc":"harmoniciste","lemma":"harmoniciste","pos":"NOM"} ,
		{"word":"harmonie","word_nosc":"harmonie","lemma":"harmonie","pos":"NOM"} ,
		{"word":"harmonies","word_nosc":"harmonies","lemma":"harmonie","pos":"NOM"} ,
		{"word":"harmonique","word_nosc":"harmonique","lemma":"harmonique","pos":"NOM"} ,
		{"word":"harmoniques","word_nosc":"harmoniques","lemma":"harmonique","pos":"NOM"} ,
		{"word":"harmonisateurs","word_nosc":"harmonisateurs","lemma":"harmonisateur","pos":"NOM"} ,
		{"word":"harmonisation","word_nosc":"harmonisation","lemma":"harmonisation","pos":"NOM"} ,
		{"word":"harmonium","word_nosc":"harmonium","lemma":"harmonium","pos":"NOM"} ,
		{"word":"harmoniums","word_nosc":"harmoniums","lemma":"harmonium","pos":"NOM"} ,
		{"word":"harnachement","word_nosc":"harnachement","lemma":"harnachement","pos":"NOM"} ,
		{"word":"harnachements","word_nosc":"harnachements","lemma":"harnachement","pos":"NOM"} ,
		{"word":"harnais","word_nosc":"harnais","lemma":"harnais","pos":"NOM"} ,
		{"word":"harnois","word_nosc":"harnois","lemma":"harnois","pos":"NOM"} ,
		{"word":"haro","word_nosc":"haro","lemma":"haro","pos":"NOM"} ,
		{"word":"harpagon","word_nosc":"harpagon","lemma":"harpagon","pos":"NOM"} ,
		{"word":"harpe","word_nosc":"harpe","lemma":"harpe","pos":"NOM"} ,
		{"word":"harpes","word_nosc":"harpes","lemma":"harpe","pos":"NOM"} ,
		{"word":"harpie","word_nosc":"harpie","lemma":"harpie","pos":"NOM"} ,
		{"word":"harpies","word_nosc":"harpies","lemma":"harpie","pos":"NOM"} ,
		{"word":"harpin","word_nosc":"harpin","lemma":"harpin","pos":"NOM"} ,
		{"word":"harpiste","word_nosc":"harpiste","lemma":"harpiste","pos":"NOM"} ,
		{"word":"harpon","word_nosc":"harpon","lemma":"harpon","pos":"NOM"} ,
		{"word":"harponnage","word_nosc":"harponnage","lemma":"harponnage","pos":"NOM"} ,
		{"word":"harponneur","word_nosc":"harponneur","lemma":"harponneur","pos":"NOM"} ,
		{"word":"harponneurs","word_nosc":"harponneurs","lemma":"harponneur","pos":"NOM"} ,
		{"word":"harpons","word_nosc":"harpons","lemma":"harpon","pos":"NOM"} ,
		{"word":"harpyes","word_nosc":"harpyes","lemma":"harpye","pos":"NOM"} ,
		{"word":"hart","word_nosc":"hart","lemma":"hart","pos":"NOM"} ,
		{"word":"harts","word_nosc":"harts","lemma":"hart","pos":"NOM"} ,
		{"word":"haruspices","word_nosc":"haruspices","lemma":"haruspice","pos":"NOM"} ,
		{"word":"has been","word_nosc":"has been","lemma":"has been","pos":"NOM"} ,
		{"word":"hasard","word_nosc":"hasard","lemma":"hasard","pos":"NOM"} ,
		{"word":"hasards","word_nosc":"hasards","lemma":"hasard","pos":"NOM"} ,
		{"word":"hasch","word_nosc":"hasch","lemma":"hasch","pos":"NOM"} ,
		{"word":"haschich","word_nosc":"haschich","lemma":"haschich","pos":"NOM"} ,
		{"word":"haschisch","word_nosc":"haschisch","lemma":"haschisch","pos":"NOM"} ,
		{"word":"hase","word_nosc":"hase","lemma":"hase","pos":"NOM"} ,
		{"word":"haseki","word_nosc":"haseki","lemma":"haseki","pos":"NOM"} ,
		{"word":"hasekis","word_nosc":"hasekis","lemma":"haseki","pos":"NOM"} ,
		{"word":"hases","word_nosc":"hases","lemma":"hase","pos":"NOM"} ,
		{"word":"hassidim","word_nosc":"hassidim","lemma":"hassidim","pos":"NOM"} ,
		{"word":"hassidisme","word_nosc":"hassidisme","lemma":"hassidisme","pos":"NOM"} ,
		{"word":"hauban","word_nosc":"hauban","lemma":"hauban","pos":"NOM"} ,
		{"word":"haubans","word_nosc":"haubans","lemma":"hauban","pos":"NOM"} ,
		{"word":"haubert","word_nosc":"haubert","lemma":"haubert","pos":"NOM"} ,
		{"word":"hausse","word_nosc":"hausse","lemma":"hausse","pos":"NOM"} ,
		{"word":"haussement","word_nosc":"haussement","lemma":"haussement","pos":"NOM"} ,
		{"word":"haussements","word_nosc":"haussements","lemma":"haussement","pos":"NOM"} ,
		{"word":"hausses","word_nosc":"hausses","lemma":"hausse","pos":"NOM"} ,
		{"word":"haussière","word_nosc":"haussiere","lemma":"haussière","pos":"NOM"} ,
		{"word":"haussières","word_nosc":"haussieres","lemma":"haussière","pos":"NOM"} ,
		{"word":"haut","word_nosc":"haut","lemma":"haut","pos":"NOM"} ,
		{"word":"haut-commandement","word_nosc":"haut-commandement","lemma":"haut-commandement","pos":"NOM"} ,
		{"word":"haut-commissaire","word_nosc":"haut-commissaire","lemma":"haut-commissaire","pos":"NOM"} ,
		{"word":"haut-commissariat","word_nosc":"haut-commissariat","lemma":"haut-commissariat","pos":"NOM"} ,
		{"word":"haut-de-chausse","word_nosc":"haut-de-chausse","lemma":"haut-de-chausse","pos":"NOM"} ,
		{"word":"haut-de-chausses","word_nosc":"haut-de-chausses","lemma":"haut-de-chausses","pos":"NOM"} ,
		{"word":"haut-de-forme","word_nosc":"haut-de-forme","lemma":"haut-de-forme","pos":"NOM"} ,
		{"word":"haut-fond","word_nosc":"haut-fond","lemma":"haut-fond","pos":"NOM"} ,
		{"word":"haut-le-coeur","word_nosc":"haut-le-coeur","lemma":"haut-le-coeur","pos":"NOM"} ,
		{"word":"haut-le-corps","word_nosc":"haut-le-corps","lemma":"haut-le-corps","pos":"NOM"} ,
		{"word":"haut-parleur","word_nosc":"haut-parleur","lemma":"haut-parleur","pos":"NOM"} ,
		{"word":"haut-parleurs","word_nosc":"haut-parleurs","lemma":"haut-parleur","pos":"NOM"} ,
		{"word":"haut-relief","word_nosc":"haut-relief","lemma":"haut-relief","pos":"NOM"} ,
		{"word":"hautain","word_nosc":"hautain","lemma":"hautain","pos":"NOM"} ,
		{"word":"hautains","word_nosc":"hautains","lemma":"hautain","pos":"NOM"} ,
		{"word":"hautbois","word_nosc":"hautbois","lemma":"hautbois","pos":"NOM"} ,
		{"word":"haute","word_nosc":"haute","lemma":"haute","pos":"NOM"} ,
		{"word":"haute-fidélité","word_nosc":"haute-fidelite","lemma":"haute-fidélité","pos":"NOM"} ,
		{"word":"hautesse","word_nosc":"hautesse","lemma":"hautesse","pos":"NOM"} ,
		{"word":"hauteur","word_nosc":"hauteur","lemma":"hauteur","pos":"NOM"} ,
		{"word":"hauteurs","word_nosc":"hauteurs","lemma":"hauteur","pos":"NOM"} ,
		{"word":"hautin","word_nosc":"hautin","lemma":"hautin","pos":"NOM"} ,
		{"word":"hautins","word_nosc":"hautins","lemma":"hautin","pos":"NOM"} ,
		{"word":"hauts","word_nosc":"hauts","lemma":"haut","pos":"NOM"} ,
		{"word":"hauts-commissaires","word_nosc":"hauts-commissaires","lemma":"haut-commissaire","pos":"NOM"} ,
		{"word":"hauts-de-chausses","word_nosc":"hauts-de-chausses","lemma":"haut-de-chausse","pos":"NOM"} ,
		{"word":"hauts-de-forme","word_nosc":"hauts-de-forme","lemma":"haut-de-forme","pos":"NOM"} ,
		{"word":"hauts-fonds","word_nosc":"hauts-fonds","lemma":"haut-fond","pos":"NOM"} ,
		{"word":"hauts-fourneaux","word_nosc":"hauts-fourneaux","lemma":"haut-fourneau","pos":"NOM"} ,
		{"word":"havanais","word_nosc":"havanais","lemma":"havanais","pos":"NOM"} ,
		{"word":"havanaise","word_nosc":"havanaise","lemma":"havanaise","pos":"NOM"} ,
		{"word":"havane","word_nosc":"havane","lemma":"havane","pos":"NOM"} ,
		{"word":"havanes","word_nosc":"havanes","lemma":"havane","pos":"NOM"} ,
		{"word":"haveneau","word_nosc":"haveneau","lemma":"haveneau","pos":"NOM"} ,
		{"word":"haveneaux","word_nosc":"haveneaux","lemma":"haveneau","pos":"NOM"} ,
		{"word":"havre","word_nosc":"havre","lemma":"havre","pos":"NOM"} ,
		{"word":"havres","word_nosc":"havres","lemma":"havre","pos":"NOM"} ,
		{"word":"havresac","word_nosc":"havresac","lemma":"havresac","pos":"NOM"} ,
		{"word":"havresacs","word_nosc":"havresacs","lemma":"havresac","pos":"NOM"} ,
		{"word":"hayon","word_nosc":"hayon","lemma":"hayon","pos":"NOM"} ,
		{"word":"hayons","word_nosc":"hayons","lemma":"hayon","pos":"NOM"} ,
		{"word":"haïk","word_nosc":"haik","lemma":"haïk","pos":"NOM"} ,
		{"word":"haïkaï","word_nosc":"haikai","lemma":"haïkaï","pos":"NOM"} ,
		{"word":"haïks","word_nosc":"haiks","lemma":"haïk","pos":"NOM"} ,
		{"word":"haïku","word_nosc":"haiku","lemma":"haïku","pos":"NOM"} ,
		{"word":"haïkus","word_nosc":"haikus","lemma":"haïku","pos":"NOM"} ,
		{"word":"haïtien","word_nosc":"haitien","lemma":"haïtien","pos":"NOM"} ,
		{"word":"haïtienne","word_nosc":"haitienne","lemma":"haïtien","pos":"NOM"} ,
		{"word":"haïtiens","word_nosc":"haitiens","lemma":"haïtien","pos":"NOM"} ,
		{"word":"heaume","word_nosc":"heaume","lemma":"heaume","pos":"NOM"} ,
		{"word":"heaumes","word_nosc":"heaumes","lemma":"heaume","pos":"NOM"} ,
		{"word":"hebdo","word_nosc":"hebdo","lemma":"hebdo","pos":"NOM"} ,
		{"word":"hebdomadaire","word_nosc":"hebdomadaire","lemma":"hebdomadaire","pos":"NOM"} ,
		{"word":"hebdomadaires","word_nosc":"hebdomadaires","lemma":"hebdomadaire","pos":"NOM"} ,
		{"word":"hebdos","word_nosc":"hebdos","lemma":"hebdo","pos":"NOM"} ,
		{"word":"hectare","word_nosc":"hectare","lemma":"hectare","pos":"NOM"} ,
		{"word":"hectares","word_nosc":"hectares","lemma":"hectare","pos":"NOM"} ,
		{"word":"hecto","word_nosc":"hecto","lemma":"hecto","pos":"NOM"} ,
		{"word":"hectolitres","word_nosc":"hectolitres","lemma":"hectolitre","pos":"NOM"} ,
		{"word":"hectomètres","word_nosc":"hectometres","lemma":"hectomètre","pos":"NOM"} ,
		{"word":"hellène","word_nosc":"hellene","lemma":"hellène","pos":"NOM"} ,
		{"word":"hellènes","word_nosc":"hellenes","lemma":"hellène","pos":"NOM"} ,
		{"word":"hellénisme","word_nosc":"hellenisme","lemma":"hellénisme","pos":"NOM"} ,
		{"word":"helléniste","word_nosc":"helleniste","lemma":"helléniste","pos":"NOM"} ,
		{"word":"hellénistes","word_nosc":"hellenistes","lemma":"helléniste","pos":"NOM"} ,
		{"word":"helminthe","word_nosc":"helminthe","lemma":"helminthe","pos":"NOM"} ,
		{"word":"helvelles","word_nosc":"helvelles","lemma":"helvelle","pos":"NOM"} ,
		{"word":"helvètes","word_nosc":"helvetes","lemma":"helvète","pos":"NOM"} ,
		{"word":"hemlock","word_nosc":"hemlock","lemma":"hemlock","pos":"NOM"} ,
		{"word":"hennin","word_nosc":"hennin","lemma":"hennin","pos":"NOM"} ,
		{"word":"hennissement","word_nosc":"hennissement","lemma":"hennissement","pos":"NOM"} ,
		{"word":"hennissements","word_nosc":"hennissements","lemma":"hennissement","pos":"NOM"} ,
		{"word":"henné","word_nosc":"henne","lemma":"henné","pos":"NOM"} ,
		{"word":"henry","word_nosc":"henry","lemma":"henry","pos":"NOM"} ,
		{"word":"heptagone","word_nosc":"heptagone","lemma":"heptagone","pos":"NOM"} ,
		{"word":"heptaméron","word_nosc":"heptameron","lemma":"heptaméron","pos":"NOM"} ,
		{"word":"heptane","word_nosc":"heptane","lemma":"heptane","pos":"NOM"} ,
		{"word":"herbage","word_nosc":"herbage","lemma":"herbage","pos":"NOM"} ,
		{"word":"herbagers","word_nosc":"herbagers","lemma":"herbager","pos":"NOM"} ,
		{"word":"herbages","word_nosc":"herbages","lemma":"herbage","pos":"NOM"} ,
		{"word":"herbe","word_nosc":"herbe","lemma":"herbe","pos":"NOM"} ,
		{"word":"herbes","word_nosc":"herbes","lemma":"herbe","pos":"NOM"} ,
		{"word":"herbette","word_nosc":"herbette","lemma":"herbette","pos":"NOM"} ,
		{"word":"herbettes","word_nosc":"herbettes","lemma":"herbette","pos":"NOM"} ,
		{"word":"herbicide","word_nosc":"herbicide","lemma":"herbicide","pos":"NOM"} ,
		{"word":"herbier","word_nosc":"herbier","lemma":"herbier","pos":"NOM"} ,
		{"word":"herbiers","word_nosc":"herbiers","lemma":"herbier","pos":"NOM"} ,
		{"word":"herbivore","word_nosc":"herbivore","lemma":"herbivore","pos":"NOM"} ,
		{"word":"herbivores","word_nosc":"herbivores","lemma":"herbivore","pos":"NOM"} ,
		{"word":"herboriste","word_nosc":"herboriste","lemma":"herboriste","pos":"NOM"} ,
		{"word":"herboristerie","word_nosc":"herboristerie","lemma":"herboristerie","pos":"NOM"} ,
		{"word":"herboristeries","word_nosc":"herboristeries","lemma":"herboristerie","pos":"NOM"} ,
		{"word":"herboristes","word_nosc":"herboristes","lemma":"herboriste","pos":"NOM"} ,
		{"word":"hercule","word_nosc":"hercule","lemma":"hercule","pos":"NOM"} ,
		{"word":"hercules","word_nosc":"hercules","lemma":"hercule","pos":"NOM"} ,
		{"word":"hermaphrodisme","word_nosc":"hermaphrodisme","lemma":"hermaphrodisme","pos":"NOM"} ,
		{"word":"hermaphrodite","word_nosc":"hermaphrodite","lemma":"hermaphrodite","pos":"NOM"} ,
		{"word":"hermaphrodites","word_nosc":"hermaphrodites","lemma":"hermaphrodite","pos":"NOM"} ,
		{"word":"hermaphroditisme","word_nosc":"hermaphroditisme","lemma":"hermaphroditisme","pos":"NOM"} ,
		{"word":"hermine","word_nosc":"hermine","lemma":"hermine","pos":"NOM"} ,
		{"word":"hermines","word_nosc":"hermines","lemma":"hermine","pos":"NOM"} ,
		{"word":"herminette","word_nosc":"herminette","lemma":"herminette","pos":"NOM"} ,
		{"word":"herminettes","word_nosc":"herminettes","lemma":"herminette","pos":"NOM"} ,
		{"word":"hermès","word_nosc":"hermes","lemma":"hermès","pos":"NOM"} ,
		{"word":"hermétisme","word_nosc":"hermetisme","lemma":"hermétisme","pos":"NOM"} ,
		{"word":"hermétistes","word_nosc":"hermetistes","lemma":"hermétiste","pos":"NOM"} ,
		{"word":"hernie","word_nosc":"hernie","lemma":"hernie","pos":"NOM"} ,
		{"word":"hernies","word_nosc":"hernies","lemma":"hernie","pos":"NOM"} ,
		{"word":"herpès","word_nosc":"herpes","lemma":"herpès","pos":"NOM"} ,
		{"word":"herpétologie","word_nosc":"herpetologie","lemma":"herpétologie","pos":"NOM"} ,
		{"word":"herpétologiste","word_nosc":"herpetologiste","lemma":"herpétologiste","pos":"NOM"} ,
		{"word":"herse","word_nosc":"herse","lemma":"herse","pos":"NOM"} ,
		{"word":"herses","word_nosc":"herses","lemma":"herse","pos":"NOM"} ,
		{"word":"hertz","word_nosc":"hertz","lemma":"hertz","pos":"NOM"} ,
		{"word":"hessois","word_nosc":"hessois","lemma":"hessois","pos":"NOM"} ,
		{"word":"hetman","word_nosc":"hetman","lemma":"hetman","pos":"NOM"} ,
		{"word":"heur","word_nosc":"heur","lemma":"heur","pos":"NOM"} ,
		{"word":"heure","word_nosc":"heure","lemma":"heure","pos":"NOM"} ,
		{"word":"heures","word_nosc":"heures","lemma":"heure","pos":"NOM"} ,
		{"word":"heureuse","word_nosc":"heureuse","lemma":"heureux","pos":"NOM"} ,
		{"word":"heureuses","word_nosc":"heureuses","lemma":"heureux","pos":"NOM"} ,
		{"word":"heureux","word_nosc":"heureux","lemma":"heureux","pos":"NOM"} ,
		{"word":"heuristique","word_nosc":"heuristique","lemma":"heuristique","pos":"NOM"} ,
		{"word":"heurs","word_nosc":"heurs","lemma":"heur","pos":"NOM"} ,
		{"word":"heurt","word_nosc":"heurt","lemma":"heurt","pos":"NOM"} ,
		{"word":"heurtoir","word_nosc":"heurtoir","lemma":"heurtoir","pos":"NOM"} ,
		{"word":"heurtoirs","word_nosc":"heurtoirs","lemma":"heurtoir","pos":"NOM"} ,
		{"word":"heurts","word_nosc":"heurts","lemma":"heurt","pos":"NOM"} ,
		{"word":"hexagone","word_nosc":"hexagone","lemma":"hexagone","pos":"NOM"} ,
		{"word":"hexagones","word_nosc":"hexagones","lemma":"hexagone","pos":"NOM"} ,
		{"word":"hexamètres","word_nosc":"hexametres","lemma":"hexamètre","pos":"NOM"} ,
		{"word":"hi-fi","word_nosc":"hi-fi","lemma":"hi-fi","pos":"NOM"} ,
		{"word":"hi-han","word_nosc":"hi-han","lemma":"hi-han","pos":"NOM"} ,
		{"word":"hiatus","word_nosc":"hiatus","lemma":"hiatus","pos":"NOM"} ,
		{"word":"hibernation","word_nosc":"hibernation","lemma":"hibernation","pos":"NOM"} ,
		{"word":"hibiscus","word_nosc":"hibiscus","lemma":"hibiscus","pos":"NOM"} ,
		{"word":"hibou","word_nosc":"hibou","lemma":"hibou","pos":"NOM"} ,
		{"word":"hiboux","word_nosc":"hiboux","lemma":"hibou","pos":"NOM"} ,
		{"word":"hic","word_nosc":"hic","lemma":"hic","pos":"NOM"} ,
		{"word":"hickory","word_nosc":"hickory","lemma":"hickory","pos":"NOM"} ,
		{"word":"hidalgo","word_nosc":"hidalgo","lemma":"hidalgo","pos":"NOM"} ,
		{"word":"hidalgos","word_nosc":"hidalgos","lemma":"hidalgo","pos":"NOM"} ,
		{"word":"hideur","word_nosc":"hideur","lemma":"hideur","pos":"NOM"} ,
		{"word":"hideurs","word_nosc":"hideurs","lemma":"hideur","pos":"NOM"} ,
		{"word":"high life","word_nosc":"high life","lemma":"high life","pos":"NOM"} ,
		{"word":"high-life","word_nosc":"high-life","lemma":"high-life","pos":"NOM"} ,
		{"word":"high-tech","word_nosc":"high-tech","lemma":"high-tech","pos":"NOM"} ,
		{"word":"highlander","word_nosc":"highlander","lemma":"highlander","pos":"NOM"} ,
		{"word":"highlanders","word_nosc":"highlanders","lemma":"highlander","pos":"NOM"} ,
		{"word":"higoumène","word_nosc":"higoumene","lemma":"higoumène","pos":"NOM"} ,
		{"word":"hilarité","word_nosc":"hilarite","lemma":"hilarité","pos":"NOM"} ,
		{"word":"hilarités","word_nosc":"hilarites","lemma":"hilarité","pos":"NOM"} ,
		{"word":"hile","word_nosc":"hile","lemma":"hile","pos":"NOM"} ,
		{"word":"hiloire","word_nosc":"hiloire","lemma":"hiloire","pos":"NOM"} ,
		{"word":"hilotes","word_nosc":"hilotes","lemma":"hilote","pos":"NOM"} ,
		{"word":"himalaya","word_nosc":"himalaya","lemma":"himalaya","pos":"NOM"} ,
		{"word":"himalayenne","word_nosc":"himalayenne","lemma":"himalayenne","pos":"NOM"} ,
		{"word":"hindi","word_nosc":"hindi","lemma":"hindi","pos":"NOM"} ,
		{"word":"hindou","word_nosc":"hindou","lemma":"hindou","pos":"NOM"} ,
		{"word":"hindoue","word_nosc":"hindoue","lemma":"hindou","pos":"NOM"} ,
		{"word":"hindoues","word_nosc":"hindoues","lemma":"hindou","pos":"NOM"} ,
		{"word":"hindouisme","word_nosc":"hindouisme","lemma":"hindouisme","pos":"NOM"} ,
		{"word":"hindous","word_nosc":"hindous","lemma":"hindou","pos":"NOM"} ,
		{"word":"hindoustani","word_nosc":"hindoustani","lemma":"hindoustani","pos":"NOM"} ,
		{"word":"hip-hop","word_nosc":"hip-hop","lemma":"hip-hop","pos":"NOM"} ,
		{"word":"hippie","word_nosc":"hippie","lemma":"hippie","pos":"NOM"} ,
		{"word":"hippies","word_nosc":"hippies","lemma":"hippie","pos":"NOM"} ,
		{"word":"hippisme","word_nosc":"hippisme","lemma":"hippisme","pos":"NOM"} ,
		{"word":"hippo","word_nosc":"hippo","lemma":"hippo","pos":"NOM"} ,
		{"word":"hippocampe","word_nosc":"hippocampe","lemma":"hippocampe","pos":"NOM"} ,
		{"word":"hippocampes","word_nosc":"hippocampes","lemma":"hippocampe","pos":"NOM"} ,
		{"word":"hippodrome","word_nosc":"hippodrome","lemma":"hippodrome","pos":"NOM"} ,
		{"word":"hippodromes","word_nosc":"hippodromes","lemma":"hippodrome","pos":"NOM"} ,
		{"word":"hippogriffe","word_nosc":"hippogriffe","lemma":"hippogriffe","pos":"NOM"} ,
		{"word":"hippopotame","word_nosc":"hippopotame","lemma":"hippopotame","pos":"NOM"} ,
		{"word":"hippopotames","word_nosc":"hippopotames","lemma":"hippopotame","pos":"NOM"} ,
		{"word":"hippy","word_nosc":"hippy","lemma":"hippy","pos":"NOM"} ,
		{"word":"hirondelle","word_nosc":"hirondelle","lemma":"hirondeau","pos":"NOM"} ,
		{"word":"hirondelles","word_nosc":"hirondelles","lemma":"hirondelle","pos":"NOM"} ,
		{"word":"hirsutisme","word_nosc":"hirsutisme","lemma":"hirsutisme","pos":"NOM"} ,
		{"word":"hirudine","word_nosc":"hirudine","lemma":"hirudine","pos":"NOM"} ,
		{"word":"hispanique","word_nosc":"hispanique","lemma":"hispanique","pos":"NOM"} ,
		{"word":"hispaniques","word_nosc":"hispaniques","lemma":"hispanique","pos":"NOM"} ,
		{"word":"hispano-américain","word_nosc":"hispano-americain","lemma":"hispano-américain","pos":"NOM"} ,
		{"word":"hispanophone","word_nosc":"hispanophone","lemma":"hispanophone","pos":"NOM"} ,
		{"word":"histamine","word_nosc":"histamine","lemma":"histamine","pos":"NOM"} ,
		{"word":"hister","word_nosc":"hister","lemma":"hister","pos":"NOM"} ,
		{"word":"histocompatibilité","word_nosc":"histocompatibilite","lemma":"histocompatibilité","pos":"NOM"} ,
		{"word":"histoire","word_nosc":"histoire","lemma":"histoire","pos":"NOM"} ,
		{"word":"histoires","word_nosc":"histoires","lemma":"histoire","pos":"NOM"} ,
		{"word":"histologie","word_nosc":"histologie","lemma":"histologie","pos":"NOM"} ,
		{"word":"histopathologie","word_nosc":"histopathologie","lemma":"histopathologie","pos":"NOM"} ,
		{"word":"histoplasmose","word_nosc":"histoplasmose","lemma":"histoplasmose","pos":"NOM"} ,
		{"word":"historicité","word_nosc":"historicite","lemma":"historicité","pos":"NOM"} ,
		{"word":"historien","word_nosc":"historien","lemma":"historien","pos":"NOM"} ,
		{"word":"historienne","word_nosc":"historienne","lemma":"historien","pos":"NOM"} ,
		{"word":"historiens","word_nosc":"historiens","lemma":"historien","pos":"NOM"} ,
		{"word":"historiette","word_nosc":"historiette","lemma":"historiette","pos":"NOM"} ,
		{"word":"historiettes","word_nosc":"historiettes","lemma":"historiette","pos":"NOM"} ,
		{"word":"historiographe","word_nosc":"historiographe","lemma":"historiographe","pos":"NOM"} ,
		{"word":"historiographie","word_nosc":"historiographie","lemma":"historiographie","pos":"NOM"} ,
		{"word":"historique","word_nosc":"historique","lemma":"historique","pos":"NOM"} ,
		{"word":"historiques","word_nosc":"historiques","lemma":"historique","pos":"NOM"} ,
		{"word":"histrion","word_nosc":"histrion","lemma":"histrion","pos":"NOM"} ,
		{"word":"histrions","word_nosc":"histrions","lemma":"histrion","pos":"NOM"} ,
		{"word":"hit","word_nosc":"hit","lemma":"hit","pos":"NOM"} ,
		{"word":"hit-parade","word_nosc":"hit-parade","lemma":"hit-parade","pos":"NOM"} ,
		{"word":"hit-parades","word_nosc":"hit-parades","lemma":"hit-parade","pos":"NOM"} ,
		{"word":"hitlérien","word_nosc":"hitlerien","lemma":"hitlérien","pos":"NOM"} ,
		{"word":"hitlérienne","word_nosc":"hitlerienne","lemma":"hitlérien","pos":"NOM"} ,
		{"word":"hitlériennes","word_nosc":"hitleriennes","lemma":"hitlérien","pos":"NOM"} ,
		{"word":"hitlériens","word_nosc":"hitleriens","lemma":"hitlérien","pos":"NOM"} ,
		{"word":"hitlérisme","word_nosc":"hitlerisme","lemma":"hitlérisme","pos":"NOM"} ,
		{"word":"hits","word_nosc":"hits","lemma":"hit","pos":"NOM"} ,
		{"word":"hittite","word_nosc":"hittite","lemma":"hittite","pos":"NOM"} ,
		{"word":"hiver","word_nosc":"hiver","lemma":"hiver","pos":"NOM"} ,
		{"word":"hivernage","word_nosc":"hivernage","lemma":"hivernage","pos":"NOM"} ,
		{"word":"hivernants","word_nosc":"hivernants","lemma":"hivernant","pos":"NOM"} ,
		{"word":"hivers","word_nosc":"hivers","lemma":"hiver","pos":"NOM"} ,
		{"word":"hiérarchie","word_nosc":"hierarchie","lemma":"hiérarchie","pos":"NOM"} ,
		{"word":"hiérarchies","word_nosc":"hierarchies","lemma":"hiérarchie","pos":"NOM"} ,
		{"word":"hiérarchisation","word_nosc":"hierarchisation","lemma":"hiérarchisation","pos":"NOM"} ,
		{"word":"hiératisme","word_nosc":"hieratisme","lemma":"hiératisme","pos":"NOM"} ,
		{"word":"hiéroglyphe","word_nosc":"hieroglyphe","lemma":"hiéroglyphe","pos":"NOM"} ,
		{"word":"hiéroglyphes","word_nosc":"hieroglyphes","lemma":"hiéroglyphe","pos":"NOM"} ,
		{"word":"hiéronymites","word_nosc":"hieronymites","lemma":"hiéronymite","pos":"NOM"} ,
		{"word":"hiérophante","word_nosc":"hierophante","lemma":"hiérophante","pos":"NOM"} ,
		{"word":"hobbies","word_nosc":"hobbies","lemma":"hobby","pos":"NOM"} ,
		{"word":"hobby","word_nosc":"hobby","lemma":"hobby","pos":"NOM"} ,
		{"word":"hobereau","word_nosc":"hobereau","lemma":"hobereau","pos":"NOM"} ,
		{"word":"hobereaux","word_nosc":"hobereaux","lemma":"hobereau","pos":"NOM"} ,
		{"word":"hochement","word_nosc":"hochement","lemma":"hochement","pos":"NOM"} ,
		{"word":"hochements","word_nosc":"hochements","lemma":"hochement","pos":"NOM"} ,
		{"word":"hochepot","word_nosc":"hochepot","lemma":"hochepot","pos":"NOM"} ,
		{"word":"hochet","word_nosc":"hochet","lemma":"hochet","pos":"NOM"} ,
		{"word":"hochets","word_nosc":"hochets","lemma":"hochet","pos":"NOM"} ,
		{"word":"hocheurs","word_nosc":"hocheurs","lemma":"hocheur","pos":"NOM"} ,
		{"word":"hockey","word_nosc":"hockey","lemma":"hockey","pos":"NOM"} ,
		{"word":"hockeyeur","word_nosc":"hockeyeur","lemma":"hockeyeur","pos":"NOM"} ,
		{"word":"hockeyeurs","word_nosc":"hockeyeurs","lemma":"hockeyeur","pos":"NOM"} ,
		{"word":"hodja","word_nosc":"hodja","lemma":"hodja","pos":"NOM"} ,
		{"word":"hoirie","word_nosc":"hoirie","lemma":"hoirie","pos":"NOM"} ,
		{"word":"hoiries","word_nosc":"hoiries","lemma":"hoirie","pos":"NOM"} ,
		{"word":"hold up","word_nosc":"hold up","lemma":"hold-up","pos":"NOM"} ,
		{"word":"hold-up","word_nosc":"hold-up","lemma":"hold-up","pos":"NOM"} ,
		{"word":"holding","word_nosc":"holding","lemma":"holding","pos":"NOM"} ,
		{"word":"holdings","word_nosc":"holdings","lemma":"holding","pos":"NOM"} ,
		{"word":"hollandais","word_nosc":"hollandais","lemma":"hollandais","pos":"NOM"} ,
		{"word":"hollandaise","word_nosc":"hollandaise","lemma":"hollandaise","pos":"NOM"} ,
		{"word":"hollandaises","word_nosc":"hollandaises","lemma":"hollandais","pos":"NOM"} ,
		{"word":"hollande","word_nosc":"hollande","lemma":"hollande","pos":"NOM"} ,
		{"word":"holocauste","word_nosc":"holocauste","lemma":"holocauste","pos":"NOM"} ,
		{"word":"holocaustes","word_nosc":"holocaustes","lemma":"holocauste","pos":"NOM"} ,
		{"word":"hologramme","word_nosc":"hologramme","lemma":"hologramme","pos":"NOM"} ,
		{"word":"hologrammes","word_nosc":"hologrammes","lemma":"hologramme","pos":"NOM"} ,
		{"word":"holographie","word_nosc":"holographie","lemma":"holographie","pos":"NOM"} ,
		{"word":"holothuries","word_nosc":"holothuries","lemma":"holothurie","pos":"NOM"} ,
		{"word":"holster","word_nosc":"holster","lemma":"holster","pos":"NOM"} ,
		{"word":"holà","word_nosc":"hola","lemma":"holà","pos":"NOM"} ,
		{"word":"homard","word_nosc":"homard","lemma":"homard","pos":"NOM"} ,
		{"word":"homardiers","word_nosc":"homardiers","lemma":"homardier","pos":"NOM"} ,
		{"word":"homards","word_nosc":"homards","lemma":"homard","pos":"NOM"} ,
		{"word":"hombre","word_nosc":"hombre","lemma":"hombre","pos":"NOM"} ,
		{"word":"home","word_nosc":"home","lemma":"home","pos":"NOM"} ,
		{"word":"home-trainer","word_nosc":"home-trainer","lemma":"home-trainer","pos":"NOM"} ,
		{"word":"homeland","word_nosc":"homeland","lemma":"homeland","pos":"NOM"} ,
		{"word":"homes","word_nosc":"homes","lemma":"home","pos":"NOM"} ,
		{"word":"homicide","word_nosc":"homicide","lemma":"homicide","pos":"NOM"} ,
		{"word":"homicides","word_nosc":"homicides","lemma":"homicide","pos":"NOM"} ,
		{"word":"hominien","word_nosc":"hominien","lemma":"hominien","pos":"NOM"} ,
		{"word":"hominiens","word_nosc":"hominiens","lemma":"hominien","pos":"NOM"} ,
		{"word":"hominisation","word_nosc":"hominisation","lemma":"hominisation","pos":"NOM"} ,
		{"word":"hommage","word_nosc":"hommage","lemma":"hommage","pos":"NOM"} ,
		{"word":"hommages","word_nosc":"hommages","lemma":"hommage","pos":"NOM"} ,
		{"word":"homme","word_nosc":"homme","lemma":"homme","pos":"NOM"} ,
		{"word":"homme-chien","word_nosc":"homme-chien","lemma":"homme-chien","pos":"NOM"} ,
		{"word":"homme-clé","word_nosc":"homme-cle","lemma":"homme-clé","pos":"NOM"} ,
		{"word":"homme-femme","word_nosc":"homme-femme","lemma":"homme-femme","pos":"NOM"} ,
		{"word":"homme-grenouille","word_nosc":"homme-grenouille","lemma":"homme-grenouille","pos":"NOM"} ,
		{"word":"homme-loup","word_nosc":"homme-loup","lemma":"homme-loup","pos":"NOM"} ,
		{"word":"homme-machine","word_nosc":"homme-machine","lemma":"homme-machine","pos":"NOM"} ,
		{"word":"homme-oiseau","word_nosc":"homme-oiseau","lemma":"homme-oiseau","pos":"NOM"} ,
		{"word":"homme-orchestre","word_nosc":"homme-orchestre","lemma":"homme-orchestre","pos":"NOM"} ,
		{"word":"homme-poisson","word_nosc":"homme-poisson","lemma":"homme-poisson","pos":"NOM"} ,
		{"word":"homme-robot","word_nosc":"homme-robot","lemma":"homme-robot","pos":"NOM"} ,
		{"word":"homme-sandwich","word_nosc":"homme-sandwich","lemma":"homme-sandwich","pos":"NOM"} ,
		{"word":"homme-serpent","word_nosc":"homme-serpent","lemma":"homme-serpent","pos":"NOM"} ,
		{"word":"hommes","word_nosc":"hommes","lemma":"homme","pos":"NOM"} ,
		{"word":"hommes-grenouilles","word_nosc":"hommes-grenouilles","lemma":"homme-grenouille","pos":"NOM"} ,
		{"word":"homo","word_nosc":"homo","lemma":"homo","pos":"NOM"} ,
		{"word":"homo erectus","word_nosc":"homo erectus","lemma":"homo erectus","pos":"NOM"} ,
		{"word":"homo habilis","word_nosc":"homo habilis","lemma":"homo habilis","pos":"NOM"} ,
		{"word":"homogénéisateur","word_nosc":"homogeneisateur","lemma":"homogénéisateur","pos":"NOM"} ,
		{"word":"homogénéisation","word_nosc":"homogeneisation","lemma":"homogénéisation","pos":"NOM"} ,
		{"word":"homogénéité","word_nosc":"homogeneite","lemma":"homogénéité","pos":"NOM"} ,
		{"word":"homologation","word_nosc":"homologation","lemma":"homologation","pos":"NOM"} ,
		{"word":"homologue","word_nosc":"homologue","lemma":"homologue","pos":"NOM"} ,
		{"word":"homologues","word_nosc":"homologues","lemma":"homologue","pos":"NOM"} ,
		{"word":"homoncule","word_nosc":"homoncule","lemma":"homoncule","pos":"NOM"} ,
		{"word":"homonyme","word_nosc":"homonyme","lemma":"homonyme","pos":"NOM"} ,
		{"word":"homonymes","word_nosc":"homonymes","lemma":"homonyme","pos":"NOM"} ,
		{"word":"homonymie","word_nosc":"homonymie","lemma":"homonymie","pos":"NOM"} ,
		{"word":"homophile","word_nosc":"homophile","lemma":"homophile","pos":"NOM"} ,
		{"word":"homophilie","word_nosc":"homophilie","lemma":"homophilie","pos":"NOM"} ,
		{"word":"homophone","word_nosc":"homophone","lemma":"homophone","pos":"NOM"} ,
		{"word":"homophonie","word_nosc":"homophonie","lemma":"homophonie","pos":"NOM"} ,
		{"word":"homos","word_nosc":"homos","lemma":"homo","pos":"NOM"} ,
		{"word":"homosexualité","word_nosc":"homosexualite","lemma":"homosexualité","pos":"NOM"} ,
		{"word":"homosexuel","word_nosc":"homosexuel","lemma":"homosexuel","pos":"NOM"} ,
		{"word":"homosexuelle","word_nosc":"homosexuelle","lemma":"homosexuelle","pos":"NOM"} ,
		{"word":"homosexuelles","word_nosc":"homosexuelles","lemma":"homosexuelle","pos":"NOM"} ,
		{"word":"homosexuels","word_nosc":"homosexuels","lemma":"homosexuel","pos":"NOM"} ,
		{"word":"homuncule","word_nosc":"homuncule","lemma":"homuncule","pos":"NOM"} ,
		{"word":"homélie","word_nosc":"homelie","lemma":"homélie","pos":"NOM"} ,
		{"word":"homélies","word_nosc":"homelies","lemma":"homélie","pos":"NOM"} ,
		{"word":"homéopathe","word_nosc":"homeopathe","lemma":"homéopathe","pos":"NOM"} ,
		{"word":"homéopathie","word_nosc":"homeopathie","lemma":"homéopathie","pos":"NOM"} ,
		{"word":"homéothermie","word_nosc":"homeothermie","lemma":"homéothermie","pos":"NOM"} ,
		{"word":"hondurien","word_nosc":"hondurien","lemma":"hondurien","pos":"NOM"} ,
		{"word":"hong kong","word_nosc":"hong kong","lemma":"hong kong","pos":"NOM"} ,
		{"word":"hongkongais","word_nosc":"hongkongais","lemma":"hongkongais","pos":"NOM"} ,
		{"word":"hongre","word_nosc":"hongre","lemma":"hongre","pos":"NOM"} ,
		{"word":"hongres","word_nosc":"hongres","lemma":"hongre","pos":"NOM"} ,
		{"word":"hongrois","word_nosc":"hongrois","lemma":"hongrois","pos":"NOM"} ,
		{"word":"hongroise","word_nosc":"hongroise","lemma":"hongroise","pos":"NOM"} ,
		{"word":"hongroises","word_nosc":"hongroises","lemma":"hongroise","pos":"NOM"} ,
		{"word":"honneur","word_nosc":"honneur","lemma":"honneur","pos":"NOM"} ,
		{"word":"honneurs","word_nosc":"honneurs","lemma":"honneur","pos":"NOM"} ,
		{"word":"honnêteté","word_nosc":"honnetete","lemma":"honnêteté","pos":"NOM"} ,
		{"word":"honorabilité","word_nosc":"honorabilite","lemma":"honorabilité","pos":"NOM"} ,
		{"word":"honoraires","word_nosc":"honoraires","lemma":"honoraire","pos":"NOM"} ,
		{"word":"honte","word_nosc":"honte","lemma":"honte","pos":"NOM"} ,
		{"word":"hontes","word_nosc":"hontes","lemma":"honte","pos":"NOM"} ,
		{"word":"hooligan","word_nosc":"hooligan","lemma":"hooligan","pos":"NOM"} ,
		{"word":"hooligans","word_nosc":"hooligans","lemma":"hooligan","pos":"NOM"} ,
		{"word":"hopak","word_nosc":"hopak","lemma":"hopak","pos":"NOM"} ,
		{"word":"hopi","word_nosc":"hopi","lemma":"hopi","pos":"NOM"} ,
		{"word":"hoplite","word_nosc":"hoplite","lemma":"hoplite","pos":"NOM"} ,
		{"word":"hoquet","word_nosc":"hoquet","lemma":"hoquet","pos":"NOM"} ,
		{"word":"hoquets","word_nosc":"hoquets","lemma":"hoquet","pos":"NOM"} ,
		{"word":"horaire","word_nosc":"horaire","lemma":"horaire","pos":"NOM"} ,
		{"word":"horaires","word_nosc":"horaires","lemma":"horaire","pos":"NOM"} ,
		{"word":"horde","word_nosc":"horde","lemma":"horde","pos":"NOM"} ,
		{"word":"hordes","word_nosc":"hordes","lemma":"horde","pos":"NOM"} ,
		{"word":"horion","word_nosc":"horion","lemma":"horion","pos":"NOM"} ,
		{"word":"horions","word_nosc":"horions","lemma":"horion","pos":"NOM"} ,
		{"word":"horizon","word_nosc":"horizon","lemma":"horizon","pos":"NOM"} ,
		{"word":"horizons","word_nosc":"horizons","lemma":"horizon","pos":"NOM"} ,
		{"word":"horizontale","word_nosc":"horizontale","lemma":"horizontale","pos":"NOM"} ,
		{"word":"horizontales","word_nosc":"horizontales","lemma":"horizontale","pos":"NOM"} ,
		{"word":"horizontalité","word_nosc":"horizontalite","lemma":"horizontalité","pos":"NOM"} ,
		{"word":"horloge","word_nosc":"horloge","lemma":"horloge","pos":"NOM"} ,
		{"word":"horloger","word_nosc":"horloger","lemma":"horloger","pos":"NOM"} ,
		{"word":"horlogerie","word_nosc":"horlogerie","lemma":"horlogerie","pos":"NOM"} ,
		{"word":"horlogers","word_nosc":"horlogers","lemma":"horloger","pos":"NOM"} ,
		{"word":"horloges","word_nosc":"horloges","lemma":"horloge","pos":"NOM"} ,
		{"word":"horlogère","word_nosc":"horlogere","lemma":"horloger","pos":"NOM"} ,
		{"word":"hormone","word_nosc":"hormone","lemma":"hormone","pos":"NOM"} ,
		{"word":"hormones","word_nosc":"hormones","lemma":"hormone","pos":"NOM"} ,
		{"word":"hornblende","word_nosc":"hornblende","lemma":"hornblende","pos":"NOM"} ,
		{"word":"horoscope","word_nosc":"horoscope","lemma":"horoscope","pos":"NOM"} ,
		{"word":"horoscopes","word_nosc":"horoscopes","lemma":"horoscope","pos":"NOM"} ,
		{"word":"horreur","word_nosc":"horreur","lemma":"horreur","pos":"NOM"} ,
		{"word":"horreurs","word_nosc":"horreurs","lemma":"horreur","pos":"NOM"} ,
		{"word":"horripilation","word_nosc":"horripilation","lemma":"horripilation","pos":"NOM"} ,
		{"word":"horripilations","word_nosc":"horripilations","lemma":"horripilation","pos":"NOM"} ,
		{"word":"hors-bord","word_nosc":"hors-bord","lemma":"hors-bord","pos":"NOM"} ,
		{"word":"hors-d'oeuvre","word_nosc":"hors-d'oeuvre","lemma":"hors-d'oeuvre","pos":"NOM"} ,
		{"word":"hors-jeu","word_nosc":"hors-jeu","lemma":"hors-jeu","pos":"NOM"} ,
		{"word":"hors-la-loi","word_nosc":"hors-la-loi","lemma":"hors-la-loi","pos":"NOM"} ,
		{"word":"hors-piste","word_nosc":"hors-piste","lemma":"hors-piste","pos":"NOM"} ,
		{"word":"horse-guard","word_nosc":"horse-guard","lemma":"horse-guard","pos":"NOM"} ,
		{"word":"horse-guards","word_nosc":"horse-guards","lemma":"horse-guard","pos":"NOM"} ,
		{"word":"hortensia","word_nosc":"hortensia","lemma":"hortensia","pos":"NOM"} ,
		{"word":"hortensias","word_nosc":"hortensias","lemma":"hortensia","pos":"NOM"} ,
		{"word":"horticulteur","word_nosc":"horticulteur","lemma":"horticulteur","pos":"NOM"} ,
		{"word":"horticulteurs","word_nosc":"horticulteurs","lemma":"horticulteur","pos":"NOM"} ,
		{"word":"horticultrice","word_nosc":"horticultrice","lemma":"horticultrice","pos":"NOM"} ,
		{"word":"horticulture","word_nosc":"horticulture","lemma":"horticulture","pos":"NOM"} ,
		{"word":"hortillonnage","word_nosc":"hortillonnage","lemma":"hortillonnage","pos":"NOM"} ,
		{"word":"hosanna","word_nosc":"hosanna","lemma":"hosanna","pos":"NOM"} ,
		{"word":"hosannah","word_nosc":"hosannah","lemma":"hosannah","pos":"NOM"} ,
		{"word":"hosannas","word_nosc":"hosannas","lemma":"hosanna","pos":"NOM"} ,
		{"word":"hospice","word_nosc":"hospice","lemma":"hospice","pos":"NOM"} ,
		{"word":"hospices","word_nosc":"hospices","lemma":"hospice","pos":"NOM"} ,
		{"word":"hospitalier","word_nosc":"hospitalier","lemma":"hospitalier","pos":"NOM"} ,
		{"word":"hospitaliers","word_nosc":"hospitaliers","lemma":"hospitalier","pos":"NOM"} ,
		{"word":"hospitalisation","word_nosc":"hospitalisation","lemma":"hospitalisation","pos":"NOM"} ,
		{"word":"hospitalisations","word_nosc":"hospitalisations","lemma":"hospitalisation","pos":"NOM"} ,
		{"word":"hospitalité","word_nosc":"hospitalite","lemma":"hospitalité","pos":"NOM"} ,
		{"word":"hospodar","word_nosc":"hospodar","lemma":"hospodar","pos":"NOM"} ,
		{"word":"hospodars","word_nosc":"hospodars","lemma":"hospodar","pos":"NOM"} ,
		{"word":"hostau","word_nosc":"hostau","lemma":"hostau","pos":"NOM"} ,
		{"word":"hostellerie","word_nosc":"hostellerie","lemma":"hostellerie","pos":"NOM"} ,
		{"word":"hostelleries","word_nosc":"hostelleries","lemma":"hostellerie","pos":"NOM"} ,
		{"word":"hostie","word_nosc":"hostie","lemma":"hostie","pos":"NOM"} ,
		{"word":"hosties","word_nosc":"hosties","lemma":"hostie","pos":"NOM"} ,
		{"word":"hostilité","word_nosc":"hostilite","lemma":"hostilité","pos":"NOM"} ,
		{"word":"hostilités","word_nosc":"hostilites","lemma":"hostilité","pos":"NOM"} ,
		{"word":"hosto","word_nosc":"hosto","lemma":"hosto","pos":"NOM"} ,
		{"word":"hostos","word_nosc":"hostos","lemma":"hosto","pos":"NOM"} ,
		{"word":"hot","word_nosc":"hot","lemma":"hot","pos":"NOM"} ,
		{"word":"hot dog","word_nosc":"hot dog","lemma":"hot dog","pos":"NOM"} ,
		{"word":"hot dogs","word_nosc":"hot dogs","lemma":"hot dog","pos":"NOM"} ,
		{"word":"hot-dog","word_nosc":"hot-dog","lemma":"hot-dog","pos":"NOM"} ,
		{"word":"hot-dogs","word_nosc":"hot-dogs","lemma":"hot-dog","pos":"NOM"} ,
		{"word":"hotline","word_nosc":"hotline","lemma":"hotline","pos":"NOM"} ,
		{"word":"hotte","word_nosc":"hotte","lemma":"hotte","pos":"NOM"} ,
		{"word":"hottes","word_nosc":"hottes","lemma":"hotte","pos":"NOM"} ,
		{"word":"hottée","word_nosc":"hottee","lemma":"hottée","pos":"NOM"} ,
		{"word":"hotu","word_nosc":"hotu","lemma":"hotu","pos":"NOM"} ,
		{"word":"hotus","word_nosc":"hotus","lemma":"hotu","pos":"NOM"} ,
		{"word":"houari","word_nosc":"houari","lemma":"houari","pos":"NOM"} ,
		{"word":"houblon","word_nosc":"houblon","lemma":"houblon","pos":"NOM"} ,
		{"word":"houblons","word_nosc":"houblons","lemma":"houblon","pos":"NOM"} ,
		{"word":"houe","word_nosc":"houe","lemma":"houe","pos":"NOM"} ,
		{"word":"houille","word_nosc":"houille","lemma":"houille","pos":"NOM"} ,
		{"word":"houilles","word_nosc":"houilles","lemma":"houille","pos":"NOM"} ,
		{"word":"houillère","word_nosc":"houillere","lemma":"houillère","pos":"NOM"} ,
		{"word":"houillères","word_nosc":"houilleres","lemma":"houillère","pos":"NOM"} ,
		{"word":"houle","word_nosc":"houle","lemma":"houle","pos":"NOM"} ,
		{"word":"houles","word_nosc":"houles","lemma":"houle","pos":"NOM"} ,
		{"word":"houlette","word_nosc":"houlette","lemma":"houlette","pos":"NOM"} ,
		{"word":"houligan","word_nosc":"houligan","lemma":"houligan","pos":"NOM"} ,
		{"word":"houliganisme","word_nosc":"houliganisme","lemma":"houliganisme","pos":"NOM"} ,
		{"word":"houlque","word_nosc":"houlque","lemma":"houlque","pos":"NOM"} ,
		{"word":"houppe","word_nosc":"houppe","lemma":"houppe","pos":"NOM"} ,
		{"word":"houppelande","word_nosc":"houppelande","lemma":"houppelande","pos":"NOM"} ,
		{"word":"houppelandes","word_nosc":"houppelandes","lemma":"houppelande","pos":"NOM"} ,
		{"word":"houppes","word_nosc":"houppes","lemma":"houppe","pos":"NOM"} ,
		{"word":"houppette","word_nosc":"houppette","lemma":"houppette","pos":"NOM"} ,
		{"word":"houppettes","word_nosc":"houppettes","lemma":"houppette","pos":"NOM"} ,
		{"word":"hourdis","word_nosc":"hourdis","lemma":"hourdis","pos":"NOM"} ,
		{"word":"hourds","word_nosc":"hourds","lemma":"hourd","pos":"NOM"} ,
		{"word":"houri","word_nosc":"houri","lemma":"houri","pos":"NOM"} ,
		{"word":"houris","word_nosc":"houris","lemma":"houri","pos":"NOM"} ,
		{"word":"hourra","word_nosc":"hourra","lemma":"hourra","pos":"NOM"} ,
		{"word":"hourrah","word_nosc":"hourrah","lemma":"hourrah","pos":"NOM"} ,
		{"word":"hourrahs","word_nosc":"hourrahs","lemma":"hourrah","pos":"NOM"} ,
		{"word":"hourras","word_nosc":"hourras","lemma":"hourra","pos":"NOM"} ,
		{"word":"hourvari","word_nosc":"hourvari","lemma":"hourvari","pos":"NOM"} ,
		{"word":"hourvaris","word_nosc":"hourvaris","lemma":"hourvari","pos":"NOM"} ,
		{"word":"housards","word_nosc":"housards","lemma":"housard","pos":"NOM"} ,
		{"word":"house","word_nosc":"house","lemma":"house","pos":"NOM"} ,
		{"word":"house music","word_nosc":"house music","lemma":"house music","pos":"NOM"} ,
		{"word":"house-boat","word_nosc":"house-boat","lemma":"house-boat","pos":"NOM"} ,
		{"word":"house-boats","word_nosc":"house-boats","lemma":"house-boat","pos":"NOM"} ,
		{"word":"houseau","word_nosc":"houseau","lemma":"houseau","pos":"NOM"} ,
		{"word":"houseaux","word_nosc":"houseaux","lemma":"houseau","pos":"NOM"} ,
		{"word":"housse","word_nosc":"housse","lemma":"housse","pos":"NOM"} ,
		{"word":"housses","word_nosc":"housses","lemma":"housse","pos":"NOM"} ,
		{"word":"houx","word_nosc":"houx","lemma":"houx","pos":"NOM"} ,
		{"word":"hovercraft","word_nosc":"hovercraft","lemma":"hovercraft","pos":"NOM"} ,
		{"word":"hoyau","word_nosc":"hoyau","lemma":"hoyau","pos":"NOM"} ,
		{"word":"huard","word_nosc":"huard","lemma":"huard","pos":"NOM"} ,
		{"word":"huards","word_nosc":"huards","lemma":"huard","pos":"NOM"} ,
		{"word":"huart","word_nosc":"huart","lemma":"huart","pos":"NOM"} ,
		{"word":"hublot","word_nosc":"hublot","lemma":"hublot","pos":"NOM"} ,
		{"word":"hublots","word_nosc":"hublots","lemma":"hublot","pos":"NOM"} ,
		{"word":"huche","word_nosc":"huche","lemma":"huche","pos":"NOM"} ,
		{"word":"huches","word_nosc":"huches","lemma":"huche","pos":"NOM"} ,
		{"word":"huchet","word_nosc":"huchet","lemma":"huchet","pos":"NOM"} ,
		{"word":"huerta","word_nosc":"huerta","lemma":"huerta","pos":"NOM"} ,
		{"word":"huguenot","word_nosc":"huguenot","lemma":"huguenot","pos":"NOM"} ,
		{"word":"huguenote","word_nosc":"huguenote","lemma":"huguenot","pos":"NOM"} ,
		{"word":"huguenots","word_nosc":"huguenots","lemma":"huguenot","pos":"NOM"} ,
		{"word":"hui","word_nosc":"hui","lemma":"hui","pos":"NOM"} ,
		{"word":"huile","word_nosc":"huile","lemma":"huile","pos":"NOM"} ,
		{"word":"huiles","word_nosc":"huiles","lemma":"huile","pos":"NOM"} ,
		{"word":"huilier","word_nosc":"huilier","lemma":"huilier","pos":"NOM"} ,
		{"word":"huipil","word_nosc":"huipil","lemma":"huipil","pos":"NOM"} ,
		{"word":"huipils","word_nosc":"huipils","lemma":"huipil","pos":"NOM"} ,
		{"word":"huis","word_nosc":"huis","lemma":"huis","pos":"NOM"} ,
		{"word":"huisserie","word_nosc":"huisserie","lemma":"huisserie","pos":"NOM"} ,
		{"word":"huisseries","word_nosc":"huisseries","lemma":"huisserie","pos":"NOM"} ,
		{"word":"huissier","word_nosc":"huissier","lemma":"huissier","pos":"NOM"} ,
		{"word":"huissiers","word_nosc":"huissiers","lemma":"huissier","pos":"NOM"} ,
		{"word":"huit-reflets","word_nosc":"huit-reflets","lemma":"huit-reflets","pos":"NOM"} ,
		{"word":"huitaine","word_nosc":"huitaine","lemma":"huitaine","pos":"NOM"} ,
		{"word":"huitième","word_nosc":"huitieme","lemma":"huitième","pos":"NOM"} ,
		{"word":"huitièmes","word_nosc":"huitiemes","lemma":"huitième","pos":"NOM"} ,
		{"word":"hulotte","word_nosc":"hulotte","lemma":"hulotte","pos":"NOM"} ,
		{"word":"hulottes","word_nosc":"hulottes","lemma":"hulotte","pos":"NOM"} ,
		{"word":"hululement","word_nosc":"hululement","lemma":"hululement","pos":"NOM"} ,
		{"word":"hululements","word_nosc":"hululements","lemma":"hululement","pos":"NOM"} ,
		{"word":"humain","word_nosc":"humain","lemma":"humain","pos":"NOM"} ,
		{"word":"humaine","word_nosc":"humaine","lemma":"humain","pos":"NOM"} ,
		{"word":"humaines","word_nosc":"humaines","lemma":"humain","pos":"NOM"} ,
		{"word":"humains","word_nosc":"humains","lemma":"humain","pos":"NOM"} ,
		{"word":"humanisation","word_nosc":"humanisation","lemma":"humanisation","pos":"NOM"} ,
		{"word":"humanisme","word_nosc":"humanisme","lemma":"humanisme","pos":"NOM"} ,
		{"word":"humaniste","word_nosc":"humaniste","lemma":"humaniste","pos":"NOM"} ,
		{"word":"humanistes","word_nosc":"humanistes","lemma":"humaniste","pos":"NOM"} ,
		{"word":"humanitaire","word_nosc":"humanitaire","lemma":"humanitaire","pos":"NOM"} ,
		{"word":"humanitarisme","word_nosc":"humanitarisme","lemma":"humanitarisme","pos":"NOM"} ,
		{"word":"humanité","word_nosc":"humanite","lemma":"humanité","pos":"NOM"} ,
		{"word":"humanités","word_nosc":"humanites","lemma":"humanité","pos":"NOM"} ,
		{"word":"humanoïde","word_nosc":"humanoide","lemma":"humanoïde","pos":"NOM"} ,
		{"word":"humanoïdes","word_nosc":"humanoides","lemma":"humanoïde","pos":"NOM"} ,
		{"word":"humectage","word_nosc":"humectage","lemma":"humectage","pos":"NOM"} ,
		{"word":"humeur","word_nosc":"humeur","lemma":"humeur","pos":"NOM"} ,
		{"word":"humeurs","word_nosc":"humeurs","lemma":"humeur","pos":"NOM"} ,
		{"word":"humidificateur","word_nosc":"humidificateur","lemma":"humidificateur","pos":"NOM"} ,
		{"word":"humidification","word_nosc":"humidification","lemma":"humidification","pos":"NOM"} ,
		{"word":"humidité","word_nosc":"humidite","lemma":"humidité","pos":"NOM"} ,
		{"word":"humiliation","word_nosc":"humiliation","lemma":"humiliation","pos":"NOM"} ,
		{"word":"humiliations","word_nosc":"humiliations","lemma":"humiliation","pos":"NOM"} ,
		{"word":"humilité","word_nosc":"humilite","lemma":"humilité","pos":"NOM"} ,
		{"word":"humilités","word_nosc":"humilites","lemma":"humilité","pos":"NOM"} ,
		{"word":"humoriste","word_nosc":"humoriste","lemma":"humoriste","pos":"NOM"} ,
		{"word":"humoristes","word_nosc":"humoristes","lemma":"humoriste","pos":"NOM"} ,
		{"word":"humour","word_nosc":"humour","lemma":"humour","pos":"NOM"} ,
		{"word":"humus","word_nosc":"humus","lemma":"humus","pos":"NOM"} ,
		{"word":"humérus","word_nosc":"humerus","lemma":"humérus","pos":"NOM"} ,
		{"word":"hun","word_nosc":"hun","lemma":"hun","pos":"NOM"} ,
		{"word":"hune","word_nosc":"hune","lemma":"hune","pos":"NOM"} ,
		{"word":"hunes","word_nosc":"hunes","lemma":"hune","pos":"NOM"} ,
		{"word":"hunier","word_nosc":"hunier","lemma":"hunier","pos":"NOM"} ,
		{"word":"huniers","word_nosc":"huniers","lemma":"hunier","pos":"NOM"} ,
		{"word":"huns","word_nosc":"huns","lemma":"hun","pos":"NOM"} ,
		{"word":"hunter","word_nosc":"hunter","lemma":"hunter","pos":"NOM"} ,
		{"word":"huppe","word_nosc":"huppe","lemma":"huppe","pos":"NOM"} ,
		{"word":"hure","word_nosc":"hure","lemma":"hure","pos":"NOM"} ,
		{"word":"hures","word_nosc":"hures","lemma":"hure","pos":"NOM"} ,
		{"word":"hurlement","word_nosc":"hurlement","lemma":"hurlement","pos":"NOM"} ,
		{"word":"hurlements","word_nosc":"hurlements","lemma":"hurlement","pos":"NOM"} ,
		{"word":"hurleur","word_nosc":"hurleur","lemma":"hurleur","pos":"NOM"} ,
		{"word":"hurleurs","word_nosc":"hurleurs","lemma":"hurleur","pos":"NOM"} ,
		{"word":"hurluberlu","word_nosc":"hurluberlu","lemma":"hurluberlu","pos":"NOM"} ,
		{"word":"hurluberlus","word_nosc":"hurluberlus","lemma":"hurluberlu","pos":"NOM"} ,
		{"word":"huron","word_nosc":"huron","lemma":"huron","pos":"NOM"} ,
		{"word":"huronne","word_nosc":"huronne","lemma":"huron","pos":"NOM"} ,
		{"word":"hurrah","word_nosc":"hurrah","lemma":"hurrah","pos":"NOM"} ,
		{"word":"hurrahs","word_nosc":"hurrahs","lemma":"hurrah","pos":"NOM"} ,
		{"word":"hurricane","word_nosc":"hurricane","lemma":"hurricane","pos":"NOM"} ,
		{"word":"hurricanes","word_nosc":"hurricanes","lemma":"hurricane","pos":"NOM"} ,
		{"word":"huskies","word_nosc":"huskies","lemma":"huskies","pos":"NOM"} ,
		{"word":"husky","word_nosc":"husky","lemma":"husky","pos":"NOM"} ,
		{"word":"hussard","word_nosc":"hussard","lemma":"hussard","pos":"NOM"} ,
		{"word":"hussarde","word_nosc":"hussarde","lemma":"hussard","pos":"NOM"} ,
		{"word":"hussards","word_nosc":"hussards","lemma":"hussard","pos":"NOM"} ,
		{"word":"husseinites","word_nosc":"husseinites","lemma":"husseinite","pos":"NOM"} ,
		{"word":"hussite","word_nosc":"hussite","lemma":"hussite","pos":"NOM"} ,
		{"word":"hussites","word_nosc":"hussites","lemma":"hussite","pos":"NOM"} ,
		{"word":"hutte","word_nosc":"hutte","lemma":"hutte","pos":"NOM"} ,
		{"word":"huttes","word_nosc":"huttes","lemma":"hutte","pos":"NOM"} ,
		{"word":"huée","word_nosc":"huee","lemma":"huée","pos":"NOM"} ,
		{"word":"huées","word_nosc":"huees","lemma":"huée","pos":"NOM"} ,
		{"word":"huître","word_nosc":"huitre","lemma":"huître","pos":"NOM"} ,
		{"word":"huîtres","word_nosc":"huitres","lemma":"huître","pos":"NOM"} ,
		{"word":"huîtrier","word_nosc":"huitrier","lemma":"huîtrier","pos":"NOM"} ,
		{"word":"huîtriers","word_nosc":"huitriers","lemma":"huîtrier","pos":"NOM"} ,
		{"word":"huîtrière","word_nosc":"huitriere","lemma":"huîtrière","pos":"NOM"} ,
		{"word":"hyacinthe","word_nosc":"hyacinthe","lemma":"hyacinthe","pos":"NOM"} ,
		{"word":"hyades","word_nosc":"hyades","lemma":"hyade","pos":"NOM"} ,
		{"word":"hybridation","word_nosc":"hybridation","lemma":"hybridation","pos":"NOM"} ,
		{"word":"hybride","word_nosc":"hybride","lemma":"hybride","pos":"NOM"} ,
		{"word":"hybrides","word_nosc":"hybrides","lemma":"hybride","pos":"NOM"} ,
		{"word":"hydne","word_nosc":"hydne","lemma":"hydne","pos":"NOM"} ,
		{"word":"hydratation","word_nosc":"hydratation","lemma":"hydratation","pos":"NOM"} ,
		{"word":"hydrate","word_nosc":"hydrate","lemma":"hydrate","pos":"NOM"} ,
		{"word":"hydrates","word_nosc":"hydrates","lemma":"hydrate","pos":"NOM"} ,
		{"word":"hydraulicien","word_nosc":"hydraulicien","lemma":"hydraulicien","pos":"NOM"} ,
		{"word":"hydraulique","word_nosc":"hydraulique","lemma":"hydraulique","pos":"NOM"} ,
		{"word":"hydrauliques","word_nosc":"hydrauliques","lemma":"hydraulique","pos":"NOM"} ,
		{"word":"hydravion","word_nosc":"hydravion","lemma":"hydravion","pos":"NOM"} ,
		{"word":"hydravions","word_nosc":"hydravions","lemma":"hydravion","pos":"NOM"} ,
		{"word":"hydre","word_nosc":"hydre","lemma":"hydre","pos":"NOM"} ,
		{"word":"hydrocarbure","word_nosc":"hydrocarbure","lemma":"hydrocarbure","pos":"NOM"} ,
		{"word":"hydrocarbures","word_nosc":"hydrocarbures","lemma":"hydrocarbure","pos":"NOM"} ,
		{"word":"hydrocortisone","word_nosc":"hydrocortisone","lemma":"hydrocortisone","pos":"NOM"} ,
		{"word":"hydrocution","word_nosc":"hydrocution","lemma":"hydrocution","pos":"NOM"} ,
		{"word":"hydrocèle","word_nosc":"hydrocele","lemma":"hydrocèle","pos":"NOM"} ,
		{"word":"hydrocéphale","word_nosc":"hydrocephale","lemma":"hydrocéphale","pos":"NOM"} ,
		{"word":"hydrocéphalie","word_nosc":"hydrocephalie","lemma":"hydrocéphalie","pos":"NOM"} ,
		{"word":"hydrofoil","word_nosc":"hydrofoil","lemma":"hydrofoil","pos":"NOM"} ,
		{"word":"hydroglisseur","word_nosc":"hydroglisseur","lemma":"hydroglisseur","pos":"NOM"} ,
		{"word":"hydrographie","word_nosc":"hydrographie","lemma":"hydrographie","pos":"NOM"} ,
		{"word":"hydrogène","word_nosc":"hydrogene","lemma":"hydrogène","pos":"NOM"} ,
		{"word":"hydrogènes","word_nosc":"hydrogenes","lemma":"hydrogène","pos":"NOM"} ,
		{"word":"hydrogénisation","word_nosc":"hydrogenisation","lemma":"hydrogénisation","pos":"NOM"} ,
		{"word":"hydrolase","word_nosc":"hydrolase","lemma":"hydrolase","pos":"NOM"} ,
		{"word":"hydrologie","word_nosc":"hydrologie","lemma":"hydrologie","pos":"NOM"} ,
		{"word":"hydrolyse","word_nosc":"hydrolyse","lemma":"hydrolyse","pos":"NOM"} ,
		{"word":"hydromel","word_nosc":"hydromel","lemma":"hydromel","pos":"NOM"} ,
		{"word":"hydromètres","word_nosc":"hydrometres","lemma":"hydromètre","pos":"NOM"} ,
		{"word":"hydrophile","word_nosc":"hydrophile","lemma":"hydrophile","pos":"NOM"} ,
		{"word":"hydrophobie","word_nosc":"hydrophobie","lemma":"hydrophobie","pos":"NOM"} ,
		{"word":"hydrophone","word_nosc":"hydrophone","lemma":"hydrophone","pos":"NOM"} ,
		{"word":"hydrophyte","word_nosc":"hydrophyte","lemma":"hydrophyte","pos":"NOM"} ,
		{"word":"hydropique","word_nosc":"hydropique","lemma":"hydropique","pos":"NOM"} ,
		{"word":"hydropisie","word_nosc":"hydropisie","lemma":"hydropisie","pos":"NOM"} ,
		{"word":"hydroptère","word_nosc":"hydroptere","lemma":"hydroptère","pos":"NOM"} ,
		{"word":"hydroquinone","word_nosc":"hydroquinone","lemma":"hydroquinone","pos":"NOM"} ,
		{"word":"hydrostatique","word_nosc":"hydrostatique","lemma":"hydrostatique","pos":"NOM"} ,
		{"word":"hydrothérapie","word_nosc":"hydrotherapie","lemma":"hydrothérapie","pos":"NOM"} ,
		{"word":"hydrox","word_nosc":"hydrox","lemma":"hydrox","pos":"NOM"} ,
		{"word":"hydroxyde","word_nosc":"hydroxyde","lemma":"hydroxyde","pos":"NOM"} ,
		{"word":"hygiaphone","word_nosc":"hygiaphone","lemma":"hygiaphone","pos":"NOM"} ,
		{"word":"hygiène","word_nosc":"hygiene","lemma":"hygiène","pos":"NOM"} ,
		{"word":"hygiènes","word_nosc":"hygienes","lemma":"hygiène","pos":"NOM"} ,
		{"word":"hygiéniste","word_nosc":"hygieniste","lemma":"hygiéniste","pos":"NOM"} ,
		{"word":"hygiénistes","word_nosc":"hygienistes","lemma":"hygiéniste","pos":"NOM"} ,
		{"word":"hygroma","word_nosc":"hygroma","lemma":"hygroma","pos":"NOM"} ,
		{"word":"hygromètre","word_nosc":"hygrometre","lemma":"hygromètre","pos":"NOM"} ,
		{"word":"hygromètres","word_nosc":"hygrometres","lemma":"hygromètre","pos":"NOM"} ,
		{"word":"hygrométrie","word_nosc":"hygrometrie","lemma":"hygrométrie","pos":"NOM"} ,
		{"word":"hymen","word_nosc":"hymen","lemma":"hymen","pos":"NOM"} ,
		{"word":"hymne","word_nosc":"hymne","lemma":"hymne","pos":"NOM"} ,
		{"word":"hymnes","word_nosc":"hymnes","lemma":"hymne","pos":"NOM"} ,
		{"word":"hyménoptère","word_nosc":"hymenoptere","lemma":"hyménoptère","pos":"NOM"} ,
		{"word":"hyménoptères","word_nosc":"hymenopteres","lemma":"hyménoptère","pos":"NOM"} ,
		{"word":"hyménée","word_nosc":"hymenee","lemma":"hyménée","pos":"NOM"} ,
		{"word":"hyménées","word_nosc":"hymenees","lemma":"hyménée","pos":"NOM"} ,
		{"word":"hyoïde","word_nosc":"hyoide","lemma":"hyoïde","pos":"NOM"} ,
		{"word":"hypallage","word_nosc":"hypallage","lemma":"hypallage","pos":"NOM"} ,
		{"word":"hyperacidité","word_nosc":"hyperacidite","lemma":"hyperacidité","pos":"NOM"} ,
		{"word":"hyperactivité","word_nosc":"hyperactivite","lemma":"hyperactivité","pos":"NOM"} ,
		{"word":"hyperbole","word_nosc":"hyperbole","lemma":"hyperbole","pos":"NOM"} ,
		{"word":"hyperboles","word_nosc":"hyperboles","lemma":"hyperbole","pos":"NOM"} ,
		{"word":"hyperborée","word_nosc":"hyperboree","lemma":"hyperborée","pos":"NOM"} ,
		{"word":"hypercalcémie","word_nosc":"hypercalcemie","lemma":"hypercalcémie","pos":"NOM"} ,
		{"word":"hypercholestérolémie","word_nosc":"hypercholesterolemie","lemma":"hypercholestérolémie","pos":"NOM"} ,
		{"word":"hypercoagulabilité","word_nosc":"hypercoagulabilite","lemma":"hypercoagulabilité","pos":"NOM"} ,
		{"word":"hyperespace","word_nosc":"hyperespace","lemma":"hyperespace","pos":"NOM"} ,
		{"word":"hyperesthésie","word_nosc":"hyperesthesie","lemma":"hyperesthésie","pos":"NOM"} ,
		{"word":"hyperglycémies","word_nosc":"hyperglycemies","lemma":"hyperglycémie","pos":"NOM"} ,
		{"word":"hyperkaliémie","word_nosc":"hyperkaliemie","lemma":"hyperkaliémie","pos":"NOM"} ,
		{"word":"hyperlipidémie","word_nosc":"hyperlipidemie","lemma":"hyperlipidémie","pos":"NOM"} ,
		{"word":"hyperlipémie","word_nosc":"hyperlipemie","lemma":"hyperlipémie","pos":"NOM"} ,
		{"word":"hypermarché","word_nosc":"hypermarche","lemma":"hypermarché","pos":"NOM"} ,
		{"word":"hypermarchés","word_nosc":"hypermarches","lemma":"hypermarché","pos":"NOM"} ,
		{"word":"hypermnésie","word_nosc":"hypermnesie","lemma":"hypermnésie","pos":"NOM"} ,
		{"word":"hyperplan","word_nosc":"hyperplan","lemma":"hyperplan","pos":"NOM"} ,
		{"word":"hyperplasie","word_nosc":"hyperplasie","lemma":"hyperplasie","pos":"NOM"} ,
		{"word":"hyperréactivité","word_nosc":"hyperreactivite","lemma":"hyperréactivité","pos":"NOM"} ,
		{"word":"hyperréalisme","word_nosc":"hyperrealisme","lemma":"hyperréalisme","pos":"NOM"} ,
		{"word":"hyperréaliste","word_nosc":"hyperrealiste","lemma":"hyperréaliste","pos":"NOM"} ,
		{"word":"hyperréalistes","word_nosc":"hyperrealistes","lemma":"hyperréaliste","pos":"NOM"} ,
		{"word":"hypersensibilité","word_nosc":"hypersensibilite","lemma":"hypersensibilité","pos":"NOM"} ,
		{"word":"hypersensible","word_nosc":"hypersensible","lemma":"hypersensible","pos":"NOM"} ,
		{"word":"hypersensibles","word_nosc":"hypersensibles","lemma":"hypersensible","pos":"NOM"} ,
		{"word":"hypersomnie","word_nosc":"hypersomnie","lemma":"hypersomnie","pos":"NOM"} ,
		{"word":"hypersustentateur","word_nosc":"hypersustentateur","lemma":"hypersustentateur","pos":"NOM"} ,
		{"word":"hypertendus","word_nosc":"hypertendus","lemma":"hypertendu","pos":"NOM"} ,
		{"word":"hypertension","word_nosc":"hypertension","lemma":"hypertension","pos":"NOM"} ,
		{"word":"hypertensions","word_nosc":"hypertensions","lemma":"hypertension","pos":"NOM"} ,
		{"word":"hyperthermie","word_nosc":"hyperthermie","lemma":"hyperthermie","pos":"NOM"} ,
		{"word":"hypertrichose","word_nosc":"hypertrichose","lemma":"hypertrichose","pos":"NOM"} ,
		{"word":"hypertrophie","word_nosc":"hypertrophie","lemma":"hypertrophie","pos":"NOM"} ,
		{"word":"hyperventilation","word_nosc":"hyperventilation","lemma":"hyperventilation","pos":"NOM"} ,
		{"word":"hyperémotivité","word_nosc":"hyperemotivite","lemma":"hyperémotivité","pos":"NOM"} ,
		{"word":"hypholomes","word_nosc":"hypholomes","lemma":"hypholome","pos":"NOM"} ,
		{"word":"hypnogène","word_nosc":"hypnogene","lemma":"hypnogène","pos":"NOM"} ,
		{"word":"hypnogènes","word_nosc":"hypnogenes","lemma":"hypnogène","pos":"NOM"} ,
		{"word":"hypnose","word_nosc":"hypnose","lemma":"hypnose","pos":"NOM"} ,
		{"word":"hypnoses","word_nosc":"hypnoses","lemma":"hypnose","pos":"NOM"} ,
		{"word":"hypnotique","word_nosc":"hypnotique","lemma":"hypnotique","pos":"NOM"} ,
		{"word":"hypnotiques","word_nosc":"hypnotiques","lemma":"hypnotique","pos":"NOM"} ,
		{"word":"hypnotiseur","word_nosc":"hypnotiseur","lemma":"hypnotiseur","pos":"NOM"} ,
		{"word":"hypnotiseurs","word_nosc":"hypnotiseurs","lemma":"hypnotiseur","pos":"NOM"} ,
		{"word":"hypnotiseuse","word_nosc":"hypnotiseuse","lemma":"hypnotiseur","pos":"NOM"} ,
		{"word":"hypnotisme","word_nosc":"hypnotisme","lemma":"hypnotisme","pos":"NOM"} ,
		{"word":"hypocalcémie","word_nosc":"hypocalcemie","lemma":"hypocalcémie","pos":"NOM"} ,
		{"word":"hypocapnie","word_nosc":"hypocapnie","lemma":"hypocapnie","pos":"NOM"} ,
		{"word":"hypocauste","word_nosc":"hypocauste","lemma":"hypocauste","pos":"NOM"} ,
		{"word":"hypocaustes","word_nosc":"hypocaustes","lemma":"hypocauste","pos":"NOM"} ,
		{"word":"hypocentre","word_nosc":"hypocentre","lemma":"hypocentre","pos":"NOM"} ,
		{"word":"hypochlorite","word_nosc":"hypochlorite","lemma":"hypochlorite","pos":"NOM"} ,
		{"word":"hypochondriaque","word_nosc":"hypochondriaque","lemma":"hypochondriaque","pos":"NOM"} ,
		{"word":"hypochondriaques","word_nosc":"hypochondriaques","lemma":"hypochondriaque","pos":"NOM"} ,
		{"word":"hypocondre","word_nosc":"hypocondre","lemma":"hypocondre","pos":"NOM"} ,
		{"word":"hypocondriaque","word_nosc":"hypocondriaque","lemma":"hypocondriaque","pos":"NOM"} ,
		{"word":"hypocondriaques","word_nosc":"hypocondriaques","lemma":"hypocondriaque","pos":"NOM"} ,
		{"word":"hypocondrie","word_nosc":"hypocondrie","lemma":"hypocondrie","pos":"NOM"} ,
		{"word":"hypocras","word_nosc":"hypocras","lemma":"hypocras","pos":"NOM"} ,
		{"word":"hypocrisie","word_nosc":"hypocrisie","lemma":"hypocrisie","pos":"NOM"} ,
		{"word":"hypocrisies","word_nosc":"hypocrisies","lemma":"hypocrisie","pos":"NOM"} ,
		{"word":"hypocrite","word_nosc":"hypocrite","lemma":"hypocrite","pos":"NOM"} ,
		{"word":"hypocrites","word_nosc":"hypocrites","lemma":"hypocrite","pos":"NOM"} ,
		{"word":"hypoderme","word_nosc":"hypoderme","lemma":"hypoderme","pos":"NOM"} ,
		{"word":"hypoglycémie","word_nosc":"hypoglycemie","lemma":"hypoglycémie","pos":"NOM"} ,
		{"word":"hypogonadisme","word_nosc":"hypogonadisme","lemma":"hypogonadisme","pos":"NOM"} ,
		{"word":"hypogée","word_nosc":"hypogee","lemma":"hypogée","pos":"NOM"} ,
		{"word":"hypogées","word_nosc":"hypogees","lemma":"hypogée","pos":"NOM"} ,
		{"word":"hypokhâgne","word_nosc":"hypokhagne","lemma":"hypokhâgne","pos":"NOM"} ,
		{"word":"hypokhâgnes","word_nosc":"hypokhagnes","lemma":"hypokhâgne","pos":"NOM"} ,
		{"word":"hypomaniaques","word_nosc":"hypomaniaques","lemma":"hypomaniaque","pos":"NOM"} ,
		{"word":"hyponatrémie","word_nosc":"hyponatremie","lemma":"hyponatrémie","pos":"NOM"} ,
		{"word":"hyponomeutes","word_nosc":"hyponomeutes","lemma":"hyponomeute","pos":"NOM"} ,
		{"word":"hypophyse","word_nosc":"hypophyse","lemma":"hypophyse","pos":"NOM"} ,
		{"word":"hypophyses","word_nosc":"hypophyses","lemma":"hypophyse","pos":"NOM"} ,
		{"word":"hypoplasie","word_nosc":"hypoplasie","lemma":"hypoplasie","pos":"NOM"} ,
		{"word":"hypospadias","word_nosc":"hypospadias","lemma":"hypospadias","pos":"NOM"} ,
		{"word":"hypostase","word_nosc":"hypostase","lemma":"hypostase","pos":"NOM"} ,
		{"word":"hypostases","word_nosc":"hypostases","lemma":"hypostase","pos":"NOM"} ,
		{"word":"hyposulfite","word_nosc":"hyposulfite","lemma":"hyposulfite","pos":"NOM"} ,
		{"word":"hypotaupe","word_nosc":"hypotaupe","lemma":"hypotaupe","pos":"NOM"} ,
		{"word":"hypotendu","word_nosc":"hypotendu","lemma":"hypotendu","pos":"NOM"} ,
		{"word":"hypotension","word_nosc":"hypotension","lemma":"hypotension","pos":"NOM"} ,
		{"word":"hypothalamus","word_nosc":"hypothalamus","lemma":"hypothalamus","pos":"NOM"} ,
		{"word":"hypothermie","word_nosc":"hypothermie","lemma":"hypothermie","pos":"NOM"} ,
		{"word":"hypothyroïdie","word_nosc":"hypothyroidie","lemma":"hypothyroïdie","pos":"NOM"} ,
		{"word":"hypothèque","word_nosc":"hypotheque","lemma":"hypothèque","pos":"NOM"} ,
		{"word":"hypothèques","word_nosc":"hypotheques","lemma":"hypothèque","pos":"NOM"} ,
		{"word":"hypothèse","word_nosc":"hypothese","lemma":"hypothèse","pos":"NOM"} ,
		{"word":"hypothèses","word_nosc":"hypotheses","lemma":"hypothèse","pos":"NOM"} ,
		{"word":"hypotonie","word_nosc":"hypotonie","lemma":"hypotonie","pos":"NOM"} ,
		{"word":"hypoténuse","word_nosc":"hypotenuse","lemma":"hypoténuse","pos":"NOM"} ,
		{"word":"hypoxie","word_nosc":"hypoxie","lemma":"hypoxie","pos":"NOM"} ,
		{"word":"hysope","word_nosc":"hysope","lemma":"hysope","pos":"NOM"} ,
		{"word":"hysopes","word_nosc":"hysopes","lemma":"hysope","pos":"NOM"} ,
		{"word":"hystérectomie","word_nosc":"hysterectomie","lemma":"hystérectomie","pos":"NOM"} ,
		{"word":"hystérie","word_nosc":"hysterie","lemma":"hystérie","pos":"NOM"} ,
		{"word":"hystéries","word_nosc":"hysteries","lemma":"hystérie","pos":"NOM"} ,
		{"word":"hystérique","word_nosc":"hysterique","lemma":"hystérique","pos":"NOM"} ,
		{"word":"hystériques","word_nosc":"hysteriques","lemma":"hystérique","pos":"NOM"} ,
		{"word":"hystéro","word_nosc":"hystero","lemma":"hystéro","pos":"NOM"} ,
		{"word":"hyène","word_nosc":"hyene","lemma":"hyène","pos":"NOM"} ,
		{"word":"hyènes","word_nosc":"hyenes","lemma":"hyène","pos":"NOM"} ,
		{"word":"hâblerie","word_nosc":"hablerie","lemma":"hâblerie","pos":"NOM"} ,
		{"word":"hâbleries","word_nosc":"hableries","lemma":"hâblerie","pos":"NOM"} ,
		{"word":"hâbleur","word_nosc":"hableur","lemma":"hâbleur","pos":"NOM"} ,
		{"word":"hâbleurs","word_nosc":"hableurs","lemma":"hâbleur","pos":"NOM"} ,
		{"word":"hâle","word_nosc":"hale","lemma":"hâle","pos":"NOM"} ,
		{"word":"hâles","word_nosc":"hales","lemma":"hâle","pos":"NOM"} ,
		{"word":"hâte","word_nosc":"hate","lemma":"hâte","pos":"NOM"} ,
		{"word":"hâtes","word_nosc":"hates","lemma":"hâte","pos":"NOM"} ,
		{"word":"hère","word_nosc":"here","lemma":"hère","pos":"NOM"} ,
		{"word":"hères","word_nosc":"heres","lemma":"hère","pos":"NOM"} ,
		{"word":"héberge","word_nosc":"heberge","lemma":"héberge","pos":"NOM"} ,
		{"word":"hébergement","word_nosc":"hebergement","lemma":"hébergement","pos":"NOM"} ,
		{"word":"hébertisme","word_nosc":"hebertisme","lemma":"hébertisme","pos":"NOM"} ,
		{"word":"hébreu","word_nosc":"hebreu","lemma":"hébreu","pos":"NOM"} ,
		{"word":"hébreux","word_nosc":"hebreux","lemma":"hébreux","pos":"NOM"} ,
		{"word":"hébétement","word_nosc":"hebetement","lemma":"hébétement","pos":"NOM"} ,
		{"word":"hébétude","word_nosc":"hebetude","lemma":"hébétude","pos":"NOM"} ,
		{"word":"hébété","word_nosc":"hebete","lemma":"hébété","pos":"NOM"} ,
		{"word":"hébétée","word_nosc":"hebetee","lemma":"hébété","pos":"NOM"} ,
		{"word":"hébétés","word_nosc":"hebetes","lemma":"hébété","pos":"NOM"} ,
		{"word":"hécatombe","word_nosc":"hecatombe","lemma":"hécatombe","pos":"NOM"} ,
		{"word":"hécatombes","word_nosc":"hecatombes","lemma":"hécatombe","pos":"NOM"} ,
		{"word":"hédonisme","word_nosc":"hedonisme","lemma":"hédonisme","pos":"NOM"} ,
		{"word":"hédoniste","word_nosc":"hedoniste","lemma":"hédoniste","pos":"NOM"} ,
		{"word":"hégire","word_nosc":"hegire","lemma":"hégire","pos":"NOM"} ,
		{"word":"hégélianisme","word_nosc":"hegelianisme","lemma":"hégélianisme","pos":"NOM"} ,
		{"word":"hégémonie","word_nosc":"hegemonie","lemma":"hégémonie","pos":"NOM"} ,
		{"word":"hélianthes","word_nosc":"helianthes","lemma":"hélianthe","pos":"NOM"} ,
		{"word":"hélice","word_nosc":"helice","lemma":"hélice","pos":"NOM"} ,
		{"word":"hélices","word_nosc":"helices","lemma":"hélice","pos":"NOM"} ,
		{"word":"hélicoptère","word_nosc":"helicoptere","lemma":"hélicoptère","pos":"NOM"} ,
		{"word":"hélicoptères","word_nosc":"helicopteres","lemma":"hélicoptère","pos":"NOM"} ,
		{"word":"hélio","word_nosc":"helio","lemma":"hélio","pos":"NOM"} ,
		{"word":"héliographe","word_nosc":"heliographe","lemma":"héliographe","pos":"NOM"} ,
		{"word":"héliogravure","word_nosc":"heliogravure","lemma":"héliogravure","pos":"NOM"} ,
		{"word":"héliophanie","word_nosc":"heliophanie","lemma":"héliophanie","pos":"NOM"} ,
		{"word":"héliotrope","word_nosc":"heliotrope","lemma":"héliotrope","pos":"NOM"} ,
		{"word":"héliotropes","word_nosc":"heliotropes","lemma":"héliotrope","pos":"NOM"} ,
		{"word":"héliport","word_nosc":"heliport","lemma":"héliport","pos":"NOM"} ,
		{"word":"hélium","word_nosc":"helium","lemma":"hélium","pos":"NOM"} ,
		{"word":"hélix","word_nosc":"helix","lemma":"hélix","pos":"NOM"} ,
		{"word":"hématie","word_nosc":"hematie","lemma":"hématie","pos":"NOM"} ,
		{"word":"hématies","word_nosc":"hematies","lemma":"hématie","pos":"NOM"} ,
		{"word":"hématine","word_nosc":"hematine","lemma":"hématine","pos":"NOM"} ,
		{"word":"hématite","word_nosc":"hematite","lemma":"hématite","pos":"NOM"} ,
		{"word":"hématocrite","word_nosc":"hematocrite","lemma":"hématocrite","pos":"NOM"} ,
		{"word":"hématocèle","word_nosc":"hematocele","lemma":"hématocèle","pos":"NOM"} ,
		{"word":"hématologie","word_nosc":"hematologie","lemma":"hématologie","pos":"NOM"} ,
		{"word":"hématologue","word_nosc":"hematologue","lemma":"hématologue","pos":"NOM"} ,
		{"word":"hématome","word_nosc":"hematome","lemma":"hématome","pos":"NOM"} ,
		{"word":"hématomes","word_nosc":"hematomes","lemma":"hématome","pos":"NOM"} ,
		{"word":"hématopoïèse","word_nosc":"hematopoiese","lemma":"hématopoïèse","pos":"NOM"} ,
		{"word":"hématose","word_nosc":"hematose","lemma":"hématose","pos":"NOM"} ,
		{"word":"hématurie","word_nosc":"hematurie","lemma":"hématurie","pos":"NOM"} ,
		{"word":"hémicycle","word_nosc":"hemicycle","lemma":"hémicycle","pos":"NOM"} ,
		{"word":"hémiplégie","word_nosc":"hemiplegie","lemma":"hémiplégie","pos":"NOM"} ,
		{"word":"hémiplégique","word_nosc":"hemiplegique","lemma":"hémiplégique","pos":"NOM"} ,
		{"word":"hémiptère","word_nosc":"hemiptere","lemma":"hémiptère","pos":"NOM"} ,
		{"word":"hémisphère","word_nosc":"hemisphere","lemma":"hémisphère","pos":"NOM"} ,
		{"word":"hémisphères","word_nosc":"hemispheres","lemma":"hémisphère","pos":"NOM"} ,
		{"word":"hémistiche","word_nosc":"hemistiche","lemma":"hémistiche","pos":"NOM"} ,
		{"word":"hémistiches","word_nosc":"hemistiches","lemma":"hémistiche","pos":"NOM"} ,
		{"word":"hémoculture","word_nosc":"hemoculture","lemma":"hémoculture","pos":"NOM"} ,
		{"word":"hémocultures","word_nosc":"hemocultures","lemma":"hémoculture","pos":"NOM"} ,
		{"word":"hémodialyse","word_nosc":"hemodialyse","lemma":"hémodialyse","pos":"NOM"} ,
		{"word":"hémodynamique","word_nosc":"hemodynamique","lemma":"hémodynamique","pos":"NOM"} ,
		{"word":"hémoglobine","word_nosc":"hemoglobine","lemma":"hémoglobine","pos":"NOM"} ,
		{"word":"hémoglobines","word_nosc":"hemoglobines","lemma":"hémoglobine","pos":"NOM"} ,
		{"word":"hémogramme","word_nosc":"hemogramme","lemma":"hémogramme","pos":"NOM"} ,
		{"word":"hémolyse","word_nosc":"hemolyse","lemma":"hémolyse","pos":"NOM"} ,
		{"word":"hémophile","word_nosc":"hemophile","lemma":"hémophile","pos":"NOM"} ,
		{"word":"hémophiles","word_nosc":"hemophiles","lemma":"hémophile","pos":"NOM"} ,
		{"word":"hémophilie","word_nosc":"hemophilie","lemma":"hémophilie","pos":"NOM"} ,
		{"word":"hémoptysie","word_nosc":"hemoptysie","lemma":"hémoptysie","pos":"NOM"} ,
		{"word":"hémoptysies","word_nosc":"hemoptysies","lemma":"hémoptysie","pos":"NOM"} ,
		{"word":"hémorragie","word_nosc":"hemorragie","lemma":"hémorragie","pos":"NOM"} ,
		{"word":"hémorragies","word_nosc":"hemorragies","lemma":"hémorragie","pos":"NOM"} ,
		{"word":"hémorroïde","word_nosc":"hemorroide","lemma":"hémorroïde","pos":"NOM"} ,
		{"word":"hémorroïdes","word_nosc":"hemorroides","lemma":"hémorroïde","pos":"NOM"} ,
		{"word":"hémostase","word_nosc":"hemostase","lemma":"hémostase","pos":"NOM"} ,
		{"word":"héparine","word_nosc":"heparine","lemma":"héparine","pos":"NOM"} ,
		{"word":"hépatique","word_nosc":"hepatique","lemma":"hépatique","pos":"NOM"} ,
		{"word":"hépatiques","word_nosc":"hepatiques","lemma":"hépatique","pos":"NOM"} ,
		{"word":"hépatite","word_nosc":"hepatite","lemma":"hépatite","pos":"NOM"} ,
		{"word":"hépatites","word_nosc":"hepatites","lemma":"hépatite","pos":"NOM"} ,
		{"word":"hépatomégalie","word_nosc":"hepatomegalie","lemma":"hépatomégalie","pos":"NOM"} ,
		{"word":"héraldique","word_nosc":"heraldique","lemma":"héraldique","pos":"NOM"} ,
		{"word":"héraut","word_nosc":"heraut","lemma":"héraut","pos":"NOM"} ,
		{"word":"hérauts","word_nosc":"herauts","lemma":"héraut","pos":"NOM"} ,
		{"word":"hérissement","word_nosc":"herissement","lemma":"hérissement","pos":"NOM"} ,
		{"word":"hérissements","word_nosc":"herissements","lemma":"hérissement","pos":"NOM"} ,
		{"word":"hérisson","word_nosc":"herisson","lemma":"hérisson","pos":"NOM"} ,
		{"word":"hérissons","word_nosc":"herissons","lemma":"hérisson","pos":"NOM"} ,
		{"word":"héritage","word_nosc":"heritage","lemma":"héritage","pos":"NOM"} ,
		{"word":"héritages","word_nosc":"heritages","lemma":"héritage","pos":"NOM"} ,
		{"word":"héritier","word_nosc":"heritier","lemma":"héritier","pos":"NOM"} ,
		{"word":"héritiers","word_nosc":"heritiers","lemma":"héritier","pos":"NOM"} ,
		{"word":"héritière","word_nosc":"heritiere","lemma":"héritier","pos":"NOM"} ,
		{"word":"héritières","word_nosc":"heritieres","lemma":"héritier","pos":"NOM"} ,
		{"word":"héro","word_nosc":"hero","lemma":"héro","pos":"NOM"} ,
		{"word":"héron","word_nosc":"heron","lemma":"héron","pos":"NOM"} ,
		{"word":"hérons","word_nosc":"herons","lemma":"héron","pos":"NOM"} ,
		{"word":"héros","word_nosc":"heros","lemma":"héros","pos":"NOM"} ,
		{"word":"héroïde","word_nosc":"heroide","lemma":"héroïde","pos":"NOM"} ,
		{"word":"héroïne","word_nosc":"heroine","lemma":"héros","pos":"NOM"} ,
		{"word":"héroïnes","word_nosc":"heroines","lemma":"héros","pos":"NOM"} ,
		{"word":"héroïnomane","word_nosc":"heroinomane","lemma":"héroïnomane","pos":"NOM"} ,
		{"word":"héroïsme","word_nosc":"heroisme","lemma":"héroïsme","pos":"NOM"} ,
		{"word":"hérédité","word_nosc":"heredite","lemma":"hérédité","pos":"NOM"} ,
		{"word":"hérésiarque","word_nosc":"heresiarque","lemma":"hérésiarque","pos":"NOM"} ,
		{"word":"hérésiarques","word_nosc":"heresiarques","lemma":"hérésiarque","pos":"NOM"} ,
		{"word":"hérésie","word_nosc":"heresie","lemma":"hérésie","pos":"NOM"} ,
		{"word":"hérésies","word_nosc":"heresies","lemma":"hérésie","pos":"NOM"} ,
		{"word":"hérétique","word_nosc":"heretique","lemma":"hérétique","pos":"NOM"} ,
		{"word":"hérétiques","word_nosc":"heretiques","lemma":"hérétique","pos":"NOM"} ,
		{"word":"hésitation","word_nosc":"hesitation","lemma":"hésitation","pos":"NOM"} ,
		{"word":"hésitations","word_nosc":"hesitations","lemma":"hésitation","pos":"NOM"} ,
		{"word":"hétaïre","word_nosc":"hetaire","lemma":"hétaïre","pos":"NOM"} ,
		{"word":"hétaïres","word_nosc":"hetaires","lemma":"hétaïre","pos":"NOM"} ,
		{"word":"hétéro","word_nosc":"hetero","lemma":"hétéro","pos":"NOM"} ,
		{"word":"hétérochromie","word_nosc":"heterochromie","lemma":"hétérochromie","pos":"NOM"} ,
		{"word":"hétérodoxes","word_nosc":"heterodoxes","lemma":"hétérodoxe","pos":"NOM"} ,
		{"word":"hétérodoxie","word_nosc":"heterodoxie","lemma":"hétérodoxie","pos":"NOM"} ,
		{"word":"hétérogénéité","word_nosc":"heterogeneite","lemma":"hétérogénéité","pos":"NOM"} ,
		{"word":"hétéroptère","word_nosc":"heteroptere","lemma":"hétéroptère","pos":"NOM"} ,
		{"word":"hétéros","word_nosc":"heteros","lemma":"hétéro","pos":"NOM"} ,
		{"word":"hétérosexualité","word_nosc":"heterosexualite","lemma":"hétérosexualité","pos":"NOM"} ,
		{"word":"hévéa","word_nosc":"hevea","lemma":"hévéa","pos":"NOM"} ,
		{"word":"hévéas","word_nosc":"heveas","lemma":"hévéa","pos":"NOM"} ,
		{"word":"hêtraie","word_nosc":"hetraie","lemma":"hêtraie","pos":"NOM"} ,
		{"word":"hêtraies","word_nosc":"hetraies","lemma":"hêtraie","pos":"NOM"} ,
		{"word":"hêtre","word_nosc":"hetre","lemma":"hêtre","pos":"NOM"} ,
		{"word":"hêtres","word_nosc":"hetres","lemma":"hêtre","pos":"NOM"} ,
		{"word":"hôpital","word_nosc":"hopital","lemma":"hôpital","pos":"NOM"} ,
		{"word":"hôpitaux","word_nosc":"hopitaux","lemma":"hôpital","pos":"NOM"} ,
		{"word":"hôte","word_nosc":"hote","lemma":"hôte","pos":"NOM"} ,
		{"word":"hôtel","word_nosc":"hotel","lemma":"hôtel","pos":"NOM"} ,
		{"word":"hôtel-dieu","word_nosc":"hotel-dieu","lemma":"hôtel-dieu","pos":"NOM"} ,
		{"word":"hôtel-restaurant","word_nosc":"hotel-restaurant","lemma":"hôtel-restaurant","pos":"NOM"} ,
		{"word":"hôtelier","word_nosc":"hotelier","lemma":"hôtelier","pos":"NOM"} ,
		{"word":"hôteliers","word_nosc":"hoteliers","lemma":"hôtelier","pos":"NOM"} ,
		{"word":"hôtelière","word_nosc":"hoteliere","lemma":"hôtelier","pos":"NOM"} ,
		{"word":"hôtellerie","word_nosc":"hotellerie","lemma":"hôtellerie","pos":"NOM"} ,
		{"word":"hôtelleries","word_nosc":"hotelleries","lemma":"hôtellerie","pos":"NOM"} ,
		{"word":"hôtels","word_nosc":"hotels","lemma":"hôtel","pos":"NOM"} ,
		{"word":"hôtes","word_nosc":"hotes","lemma":"hôte","pos":"NOM"} ,
		{"word":"hôtesse","word_nosc":"hotesse","lemma":"hôtesse","pos":"NOM"} ,
		{"word":"hôtesses","word_nosc":"hotesses","lemma":"hôtesse","pos":"NOM"} ,
		{"word":"i","word_nosc":"i","lemma":"i","pos":"NOM"} ,
		{"word":"i","word_nosc":"i","lemma":"ie","pos":"NOM"} ,
		{"word":"i.e.","word_nosc":"i.e.","lemma":"i.e.","pos":"NOM"} ,
		{"word":"iambe","word_nosc":"iambe","lemma":"iambe","pos":"NOM"} ,
		{"word":"ibex","word_nosc":"ibex","lemma":"ibex","pos":"NOM"} ,
		{"word":"ibis","word_nosc":"ibis","lemma":"ibis","pos":"NOM"} ,
		{"word":"ibm","word_nosc":"ibm","lemma":"ibm","pos":"NOM"} ,
		{"word":"iboga","word_nosc":"iboga","lemma":"iboga","pos":"NOM"} ,
		{"word":"ibère","word_nosc":"ibere","lemma":"ibère","pos":"NOM"} ,
		{"word":"ice cream","word_nosc":"ice cream","lemma":"ice-cream","pos":"NOM"} ,
		{"word":"ice-cream","word_nosc":"ice-cream","lemma":"ice-cream","pos":"NOM"} ,
		{"word":"ice-creams","word_nosc":"ice-creams","lemma":"ice-cream","pos":"NOM"} ,
		{"word":"iceberg","word_nosc":"iceberg","lemma":"iceberg","pos":"NOM"} ,
		{"word":"icebergs","word_nosc":"icebergs","lemma":"iceberg","pos":"NOM"} ,
		{"word":"ichtyologie","word_nosc":"ichtyologie","lemma":"ichtyologie","pos":"NOM"} ,
		{"word":"ichtyologiste","word_nosc":"ichtyologiste","lemma":"ichtyologiste","pos":"NOM"} ,
		{"word":"icoglan","word_nosc":"icoglan","lemma":"icoglan","pos":"NOM"} ,
		{"word":"icoglans","word_nosc":"icoglans","lemma":"icoglan","pos":"NOM"} ,
		{"word":"iconoclaste","word_nosc":"iconoclaste","lemma":"iconoclaste","pos":"NOM"} ,
		{"word":"iconoclastes","word_nosc":"iconoclastes","lemma":"iconoclaste","pos":"NOM"} ,
		{"word":"iconographe","word_nosc":"iconographe","lemma":"iconographe","pos":"NOM"} ,
		{"word":"iconographie","word_nosc":"iconographie","lemma":"iconographie","pos":"NOM"} ,
		{"word":"iconographies","word_nosc":"iconographies","lemma":"iconographie","pos":"NOM"} ,
		{"word":"iconostase","word_nosc":"iconostase","lemma":"iconostase","pos":"NOM"} ,
		{"word":"icosaèdre","word_nosc":"icosaedre","lemma":"icosaèdre","pos":"NOM"} ,
		{"word":"ictus","word_nosc":"ictus","lemma":"ictus","pos":"NOM"} ,
		{"word":"ictère","word_nosc":"ictere","lemma":"ictère","pos":"NOM"} ,
		{"word":"icône","word_nosc":"icone","lemma":"icône","pos":"NOM"} ,
		{"word":"icônes","word_nosc":"icones","lemma":"icône","pos":"NOM"} ,
		{"word":"ide","word_nosc":"ide","lemma":"ide","pos":"NOM"} ,
		{"word":"identificateur","word_nosc":"identificateur","lemma":"identificateur","pos":"NOM"} ,
		{"word":"identification","word_nosc":"identification","lemma":"identification","pos":"NOM"} ,
		{"word":"identifications","word_nosc":"identifications","lemma":"identification","pos":"NOM"} ,
		{"word":"identitarisme","word_nosc":"identitarisme","lemma":"identitarisme","pos":"NOM"} ,
		{"word":"identité","word_nosc":"identite","lemma":"identité","pos":"NOM"} ,
		{"word":"identités","word_nosc":"identites","lemma":"identité","pos":"NOM"} ,
		{"word":"ides","word_nosc":"ides","lemma":"ides","pos":"NOM"} ,
		{"word":"idiolectes","word_nosc":"idiolectes","lemma":"idiolecte","pos":"NOM"} ,
		{"word":"idiome","word_nosc":"idiome","lemma":"idiome","pos":"NOM"} ,
		{"word":"idiomes","word_nosc":"idiomes","lemma":"idiome","pos":"NOM"} ,
		{"word":"idiosyncrasie","word_nosc":"idiosyncrasie","lemma":"idiosyncrasie","pos":"NOM"} ,
		{"word":"idiosyncrasies","word_nosc":"idiosyncrasies","lemma":"idiosyncrasie","pos":"NOM"} ,
		{"word":"idiot","word_nosc":"idiot","lemma":"idiot","pos":"NOM"} ,
		{"word":"idiote","word_nosc":"idiote","lemma":"idiot","pos":"NOM"} ,
		{"word":"idiotes","word_nosc":"idiotes","lemma":"idiot","pos":"NOM"} ,
		{"word":"idiotie","word_nosc":"idiotie","lemma":"idiotie","pos":"NOM"} ,
		{"word":"idioties","word_nosc":"idioties","lemma":"idiotie","pos":"NOM"} ,
		{"word":"idiotisme","word_nosc":"idiotisme","lemma":"idiotisme","pos":"NOM"} ,
		{"word":"idiotismes","word_nosc":"idiotismes","lemma":"idiotisme","pos":"NOM"} ,
		{"word":"idiots","word_nosc":"idiots","lemma":"idiot","pos":"NOM"} ,
		{"word":"idole","word_nosc":"idole","lemma":"idole","pos":"NOM"} ,
		{"word":"idoles","word_nosc":"idoles","lemma":"idole","pos":"NOM"} ,
		{"word":"idolâtre","word_nosc":"idolatre","lemma":"idolâtre","pos":"NOM"} ,
		{"word":"idolâtres","word_nosc":"idolatres","lemma":"idolâtre","pos":"NOM"} ,
		{"word":"idolâtrie","word_nosc":"idolatrie","lemma":"idolâtrie","pos":"NOM"} ,
		{"word":"idolâtries","word_nosc":"idolatries","lemma":"idolâtrie","pos":"NOM"} ,
		{"word":"idylle","word_nosc":"idylle","lemma":"idylle","pos":"NOM"} ,
		{"word":"idylles","word_nosc":"idylles","lemma":"idylle","pos":"NOM"} ,
		{"word":"idéal","word_nosc":"ideal","lemma":"idéal","pos":"NOM"} ,
		{"word":"idéalisation","word_nosc":"idealisation","lemma":"idéalisation","pos":"NOM"} ,
		{"word":"idéalisme","word_nosc":"idealisme","lemma":"idéalisme","pos":"NOM"} ,
		{"word":"idéalismes","word_nosc":"idealismes","lemma":"idéalisme","pos":"NOM"} ,
		{"word":"idéaliste","word_nosc":"idealiste","lemma":"idéaliste","pos":"NOM"} ,
		{"word":"idéalistes","word_nosc":"idealistes","lemma":"idéaliste","pos":"NOM"} ,
		{"word":"idéals","word_nosc":"ideals","lemma":"idéal","pos":"NOM"} ,
		{"word":"idéation","word_nosc":"ideation","lemma":"idéation","pos":"NOM"} ,
		{"word":"idéaux","word_nosc":"ideaux","lemma":"idéal","pos":"NOM"} ,
		{"word":"idée","word_nosc":"idee","lemma":"idée","pos":"NOM"} ,
		{"word":"idée-clé","word_nosc":"idee-cle","lemma":"idée-clé","pos":"NOM"} ,
		{"word":"idée-force","word_nosc":"idee-force","lemma":"idée-force","pos":"NOM"} ,
		{"word":"idées","word_nosc":"idees","lemma":"idée","pos":"NOM"} ,
		{"word":"idées-forces","word_nosc":"idees-forces","lemma":"idée-force","pos":"NOM"} ,
		{"word":"idéogramme","word_nosc":"ideogramme","lemma":"idéogramme","pos":"NOM"} ,
		{"word":"idéogrammes","word_nosc":"ideogrammes","lemma":"idéogramme","pos":"NOM"} ,
		{"word":"idéographie","word_nosc":"ideographie","lemma":"idéographie","pos":"NOM"} ,
		{"word":"idéologie","word_nosc":"ideologie","lemma":"idéologie","pos":"NOM"} ,
		{"word":"idéologies","word_nosc":"ideologies","lemma":"idéologie","pos":"NOM"} ,
		{"word":"idéologue","word_nosc":"ideologue","lemma":"idéologue","pos":"NOM"} ,
		{"word":"idéologues","word_nosc":"ideologues","lemma":"idéologue","pos":"NOM"} ,
		{"word":"if","word_nosc":"if","lemma":"if","pos":"NOM"} ,
		{"word":"ifs","word_nosc":"ifs","lemma":"if","pos":"NOM"} ,
		{"word":"iftar","word_nosc":"iftar","lemma":"iftar","pos":"NOM"} ,
		{"word":"igloo","word_nosc":"igloo","lemma":"igloo","pos":"NOM"} ,
		{"word":"igloos","word_nosc":"igloos","lemma":"igloo","pos":"NOM"} ,
		{"word":"igname","word_nosc":"igname","lemma":"igname","pos":"NOM"} ,
		{"word":"ignames","word_nosc":"ignames","lemma":"igname","pos":"NOM"} ,
		{"word":"ignare","word_nosc":"ignare","lemma":"ignare","pos":"NOM"} ,
		{"word":"ignares","word_nosc":"ignares","lemma":"ignare","pos":"NOM"} ,
		{"word":"ignition","word_nosc":"ignition","lemma":"ignition","pos":"NOM"} ,
		{"word":"ignominie","word_nosc":"ignominie","lemma":"ignominie","pos":"NOM"} ,
		{"word":"ignominies","word_nosc":"ignominies","lemma":"ignominie","pos":"NOM"} ,
		{"word":"ignorance","word_nosc":"ignorance","lemma":"ignorance","pos":"NOM"} ,
		{"word":"ignorances","word_nosc":"ignorances","lemma":"ignorance","pos":"NOM"} ,
		{"word":"ignorant","word_nosc":"ignorant","lemma":"ignorant","pos":"NOM"} ,
		{"word":"ignorante","word_nosc":"ignorante","lemma":"ignorant","pos":"NOM"} ,
		{"word":"ignorantes","word_nosc":"ignorantes","lemma":"ignorant","pos":"NOM"} ,
		{"word":"ignorants","word_nosc":"ignorants","lemma":"ignorant","pos":"NOM"} ,
		{"word":"iguane","word_nosc":"iguane","lemma":"iguane","pos":"NOM"} ,
		{"word":"iguanes","word_nosc":"iguanes","lemma":"iguane","pos":"NOM"} ,
		{"word":"iguanodon","word_nosc":"iguanodon","lemma":"iguanodon","pos":"NOM"} ,
		{"word":"iguanodons","word_nosc":"iguanodons","lemma":"iguanodon","pos":"NOM"} ,
		{"word":"igue","word_nosc":"igue","lemma":"igue","pos":"NOM"} ,
		{"word":"igues","word_nosc":"igues","lemma":"igue","pos":"NOM"} ,
		{"word":"ikebana","word_nosc":"ikebana","lemma":"ikebana","pos":"NOM"} ,
		{"word":"iles","word_nosc":"iles","lemma":"ile","pos":"NOM"} ,
		{"word":"ilium","word_nosc":"ilium","lemma":"ilium","pos":"NOM"} ,
		{"word":"illettrisme","word_nosc":"illettrisme","lemma":"illettrisme","pos":"NOM"} ,
		{"word":"illettré","word_nosc":"illettre","lemma":"illettré","pos":"NOM"} ,
		{"word":"illettrée","word_nosc":"illettree","lemma":"illettré","pos":"NOM"} ,
		{"word":"illettrés","word_nosc":"illettres","lemma":"illettré","pos":"NOM"} ,
		{"word":"illogisme","word_nosc":"illogisme","lemma":"illogisme","pos":"NOM"} ,
		{"word":"illogismes","word_nosc":"illogismes","lemma":"illogisme","pos":"NOM"} ,
		{"word":"illuminateurs","word_nosc":"illuminateurs","lemma":"illuminateur","pos":"NOM"} ,
		{"word":"illumination","word_nosc":"illumination","lemma":"illumination","pos":"NOM"} ,
		{"word":"illuminations","word_nosc":"illuminations","lemma":"illumination","pos":"NOM"} ,
		{"word":"illuminé","word_nosc":"illumine","lemma":"illuminé","pos":"NOM"} ,
		{"word":"illuminée","word_nosc":"illuminee","lemma":"illuminé","pos":"NOM"} ,
		{"word":"illuminés","word_nosc":"illumines","lemma":"illuminé","pos":"NOM"} ,
		{"word":"illusion","word_nosc":"illusion","lemma":"illusion","pos":"NOM"} ,
		{"word":"illusionnisme","word_nosc":"illusionnisme","lemma":"illusionnisme","pos":"NOM"} ,
		{"word":"illusionniste","word_nosc":"illusionniste","lemma":"illusionniste","pos":"NOM"} ,
		{"word":"illusionnistes","word_nosc":"illusionnistes","lemma":"illusionniste","pos":"NOM"} ,
		{"word":"illusions","word_nosc":"illusions","lemma":"illusion","pos":"NOM"} ,
		{"word":"illustrateur","word_nosc":"illustrateur","lemma":"illustrateur","pos":"NOM"} ,
		{"word":"illustration","word_nosc":"illustration","lemma":"illustration","pos":"NOM"} ,
		{"word":"illustrations","word_nosc":"illustrations","lemma":"illustration","pos":"NOM"} ,
		{"word":"illustratrice","word_nosc":"illustratrice","lemma":"illustrateur","pos":"NOM"} ,
		{"word":"illustré","word_nosc":"illustre","lemma":"illustré","pos":"NOM"} ,
		{"word":"illustrés","word_nosc":"illustres","lemma":"illustré","pos":"NOM"} ,
		{"word":"illégalistes","word_nosc":"illegalistes","lemma":"illégaliste","pos":"NOM"} ,
		{"word":"illégalité","word_nosc":"illegalite","lemma":"illégalité","pos":"NOM"} ,
		{"word":"illégitimité","word_nosc":"illegitimite","lemma":"illégitimité","pos":"NOM"} ,
		{"word":"ilote","word_nosc":"ilote","lemma":"ilote","pos":"NOM"} ,
		{"word":"ilotes","word_nosc":"ilotes","lemma":"ilote","pos":"NOM"} ,
		{"word":"ilotisme","word_nosc":"ilotisme","lemma":"ilotisme","pos":"NOM"} ,
		{"word":"iléus","word_nosc":"ileus","lemma":"iléus","pos":"NOM"} ,
		{"word":"image","word_nosc":"image","lemma":"image","pos":"NOM"} ,
		{"word":"imagerie","word_nosc":"imagerie","lemma":"imagerie","pos":"NOM"} ,
		{"word":"imageries","word_nosc":"imageries","lemma":"imagerie","pos":"NOM"} ,
		{"word":"images","word_nosc":"images","lemma":"image","pos":"NOM"} ,
		{"word":"imageur","word_nosc":"imageur","lemma":"imageur","pos":"NOM"} ,
		{"word":"imageurs","word_nosc":"imageurs","lemma":"imageur","pos":"NOM"} ,
		{"word":"imagier","word_nosc":"imagier","lemma":"imagier","pos":"NOM"} ,
		{"word":"imagiers","word_nosc":"imagiers","lemma":"imagier","pos":"NOM"} ,
		{"word":"imaginaire","word_nosc":"imaginaire","lemma":"imaginaire","pos":"NOM"} ,
		{"word":"imaginaires","word_nosc":"imaginaires","lemma":"imaginaire","pos":"NOM"} ,
		{"word":"imaginatif","word_nosc":"imaginatif","lemma":"imaginatif","pos":"NOM"} ,
		{"word":"imaginatifs","word_nosc":"imaginatifs","lemma":"imaginatif","pos":"NOM"} ,
		{"word":"imagination","word_nosc":"imagination","lemma":"imagination","pos":"NOM"} ,
		{"word":"imaginations","word_nosc":"imaginations","lemma":"imagination","pos":"NOM"} ,
		{"word":"imaginative","word_nosc":"imaginative","lemma":"imaginative","pos":"NOM"} ,
		{"word":"imago","word_nosc":"imago","lemma":"imago","pos":"NOM"} ,
		{"word":"imam","word_nosc":"imam","lemma":"imam","pos":"NOM"} ,
		{"word":"imams","word_nosc":"imams","lemma":"imam","pos":"NOM"} ,
		{"word":"iman","word_nosc":"iman","lemma":"iman","pos":"NOM"} ,
		{"word":"imbibition","word_nosc":"imbibition","lemma":"imbibition","pos":"NOM"} ,
		{"word":"imbrication","word_nosc":"imbrication","lemma":"imbrication","pos":"NOM"} ,
		{"word":"imbrications","word_nosc":"imbrications","lemma":"imbrication","pos":"NOM"} ,
		{"word":"imbroglio","word_nosc":"imbroglio","lemma":"imbroglio","pos":"NOM"} ,
		{"word":"imbroglios","word_nosc":"imbroglios","lemma":"imbroglio","pos":"NOM"} ,
		{"word":"imbécile","word_nosc":"imbecile","lemma":"imbécile","pos":"NOM"} ,
		{"word":"imbéciles","word_nosc":"imbeciles","lemma":"imbécile","pos":"NOM"} ,
		{"word":"imbécillité","word_nosc":"imbecillite","lemma":"imbécillité","pos":"NOM"} ,
		{"word":"imbécillités","word_nosc":"imbecillites","lemma":"imbécillité","pos":"NOM"} ,
		{"word":"imidazole","word_nosc":"imidazole","lemma":"imidazole","pos":"NOM"} ,
		{"word":"imitateur","word_nosc":"imitateur","lemma":"imitateur","pos":"NOM"} ,
		{"word":"imitateurs","word_nosc":"imitateurs","lemma":"imitateur","pos":"NOM"} ,
		{"word":"imitation","word_nosc":"imitation","lemma":"imitation","pos":"NOM"} ,
		{"word":"imitations","word_nosc":"imitations","lemma":"imitation","pos":"NOM"} ,
		{"word":"imitatrice","word_nosc":"imitatrice","lemma":"imitateur","pos":"NOM"} ,
		{"word":"immaculation","word_nosc":"immaculation","lemma":"immaculation","pos":"NOM"} ,
		{"word":"immanence","word_nosc":"immanence","lemma":"immanence","pos":"NOM"} ,
		{"word":"immanentisme","word_nosc":"immanentisme","lemma":"immanentisme","pos":"NOM"} ,
		{"word":"immatriculation","word_nosc":"immatriculation","lemma":"immatriculation","pos":"NOM"} ,
		{"word":"immatriculations","word_nosc":"immatriculations","lemma":"immatriculation","pos":"NOM"} ,
		{"word":"immaturité","word_nosc":"immaturite","lemma":"immaturité","pos":"NOM"} ,
		{"word":"immatérialité","word_nosc":"immaterialite","lemma":"immatérialité","pos":"NOM"} ,
		{"word":"immensité","word_nosc":"immensite","lemma":"immensité","pos":"NOM"} ,
		{"word":"immensités","word_nosc":"immensites","lemma":"immensité","pos":"NOM"} ,
		{"word":"immersion","word_nosc":"immersion","lemma":"immersion","pos":"NOM"} ,
		{"word":"immeuble","word_nosc":"immeuble","lemma":"immeuble","pos":"NOM"} ,
		{"word":"immeubles","word_nosc":"immeubles","lemma":"immeuble","pos":"NOM"} ,
		{"word":"immigrant","word_nosc":"immigrant","lemma":"immigrant","pos":"NOM"} ,
		{"word":"immigrante","word_nosc":"immigrante","lemma":"immigrant","pos":"NOM"} ,
		{"word":"immigrants","word_nosc":"immigrants","lemma":"immigrant","pos":"NOM"} ,
		{"word":"immigration","word_nosc":"immigration","lemma":"immigration","pos":"NOM"} ,
		{"word":"immigré","word_nosc":"immigre","lemma":"immigré","pos":"NOM"} ,
		{"word":"immigrée","word_nosc":"immigree","lemma":"immigré","pos":"NOM"} ,
		{"word":"immigrées","word_nosc":"immigrees","lemma":"immigré","pos":"NOM"} ,
		{"word":"immigrés","word_nosc":"immigres","lemma":"immigré","pos":"NOM"} ,
		{"word":"imminence","word_nosc":"imminence","lemma":"imminence","pos":"NOM"} ,
		{"word":"immixtion","word_nosc":"immixtion","lemma":"immixtion","pos":"NOM"} ,
		{"word":"immixtions","word_nosc":"immixtions","lemma":"immixtion","pos":"NOM"} ,
		{"word":"immobilier","word_nosc":"immobilier","lemma":"immobilier","pos":"NOM"} ,
		{"word":"immobiliers","word_nosc":"immobiliers","lemma":"immobilier","pos":"NOM"} ,
		{"word":"immobilisation","word_nosc":"immobilisation","lemma":"immobilisation","pos":"NOM"} ,
		{"word":"immobilisations","word_nosc":"immobilisations","lemma":"immobilisation","pos":"NOM"} ,
		{"word":"immobilisme","word_nosc":"immobilisme","lemma":"immobilisme","pos":"NOM"} ,
		{"word":"immobilité","word_nosc":"immobilite","lemma":"immobilité","pos":"NOM"} ,
		{"word":"immobilités","word_nosc":"immobilites","lemma":"immobilité","pos":"NOM"} ,
		{"word":"immodestie","word_nosc":"immodestie","lemma":"immodestie","pos":"NOM"} ,
		{"word":"immolation","word_nosc":"immolation","lemma":"immolation","pos":"NOM"} ,
		{"word":"immolations","word_nosc":"immolations","lemma":"immolation","pos":"NOM"} ,
		{"word":"immondice","word_nosc":"immondice","lemma":"immondice","pos":"NOM"} ,
		{"word":"immondices","word_nosc":"immondices","lemma":"immondice","pos":"NOM"} ,
		{"word":"immoralisme","word_nosc":"immoralisme","lemma":"immoralisme","pos":"NOM"} ,
		{"word":"immoraliste","word_nosc":"immoraliste","lemma":"immoraliste","pos":"NOM"} ,
		{"word":"immoralité","word_nosc":"immoralite","lemma":"immoralité","pos":"NOM"} ,
		{"word":"immortalisation","word_nosc":"immortalisation","lemma":"immortalisation","pos":"NOM"} ,
		{"word":"immortalité","word_nosc":"immortalite","lemma":"immortalité","pos":"NOM"} ,
		{"word":"immortalités","word_nosc":"immortalites","lemma":"immortalité","pos":"NOM"} ,
		{"word":"immortel","word_nosc":"immortel","lemma":"immortel","pos":"NOM"} ,
		{"word":"immortelle","word_nosc":"immortelle","lemma":"immortel","pos":"NOM"} ,
		{"word":"immortelles","word_nosc":"immortelles","lemma":"immortel","pos":"NOM"} ,
		{"word":"immortels","word_nosc":"immortels","lemma":"immortel","pos":"NOM"} ,
		{"word":"immuabilité","word_nosc":"immuabilite","lemma":"immuabilité","pos":"NOM"} ,
		{"word":"immunisation","word_nosc":"immunisation","lemma":"immunisation","pos":"NOM"} ,
		{"word":"immunité","word_nosc":"immunite","lemma":"immunité","pos":"NOM"} ,
		{"word":"immunités","word_nosc":"immunites","lemma":"immunité","pos":"NOM"} ,
		{"word":"immunodéficience","word_nosc":"immunodeficience","lemma":"immunodéficience","pos":"NOM"} ,
		{"word":"immunodéprimé","word_nosc":"immunodeprime","lemma":"immunodéprimé","pos":"NOM"} ,
		{"word":"immunologie","word_nosc":"immunologie","lemma":"immunologie","pos":"NOM"} ,
		{"word":"immunologiste","word_nosc":"immunologiste","lemma":"immunologiste","pos":"NOM"} ,
		{"word":"immunostimulant","word_nosc":"immunostimulant","lemma":"immunostimulant","pos":"NOM"} ,
		{"word":"immunothérapie","word_nosc":"immunotherapie","lemma":"immunothérapie","pos":"NOM"} ,
		{"word":"immutabilité","word_nosc":"immutabilite","lemma":"immutabilité","pos":"NOM"} ,
		{"word":"immédiat","word_nosc":"immediat","lemma":"immédiat","pos":"NOM"} ,
		{"word":"immédiateté","word_nosc":"immediatete","lemma":"immédiateté","pos":"NOM"} ,
		{"word":"impact","word_nosc":"impact","lemma":"impact","pos":"NOM"} ,
		{"word":"impacts","word_nosc":"impacts","lemma":"impact","pos":"NOM"} ,
		{"word":"impair","word_nosc":"impair","lemma":"impair","pos":"NOM"} ,
		{"word":"impairs","word_nosc":"impairs","lemma":"impair","pos":"NOM"} ,
		{"word":"impala","word_nosc":"impala","lemma":"impala","pos":"NOM"} ,
		{"word":"impalas","word_nosc":"impalas","lemma":"impala","pos":"NOM"} ,
		{"word":"impalpabilité","word_nosc":"impalpabilite","lemma":"impalpabilité","pos":"NOM"} ,
		{"word":"imparfait","word_nosc":"imparfait","lemma":"imparfait","pos":"NOM"} ,
		{"word":"imparfaits","word_nosc":"imparfaits","lemma":"imparfait","pos":"NOM"} ,
		{"word":"impartialité","word_nosc":"impartialite","lemma":"impartialité","pos":"NOM"} ,
		{"word":"impasse","word_nosc":"impasse","lemma":"impasse","pos":"NOM"} ,
		{"word":"impasses","word_nosc":"impasses","lemma":"impasse","pos":"NOM"} ,
		{"word":"impassibilité","word_nosc":"impassibilite","lemma":"impassibilité","pos":"NOM"} ,
		{"word":"impatience","word_nosc":"impatience","lemma":"impatience","pos":"NOM"} ,
		{"word":"impatiences","word_nosc":"impatiences","lemma":"impatience","pos":"NOM"} ,
		{"word":"impatiens","word_nosc":"impatiens","lemma":"impatiens","pos":"NOM"} ,
		{"word":"impatient","word_nosc":"impatient","lemma":"impatient","pos":"NOM"} ,
		{"word":"impatiente","word_nosc":"impatiente","lemma":"impatient","pos":"NOM"} ,
		{"word":"impatientes","word_nosc":"impatientes","lemma":"impatient","pos":"NOM"} ,
		{"word":"impatients","word_nosc":"impatients","lemma":"impatient","pos":"NOM"} ,
		{"word":"impayé","word_nosc":"impaye","lemma":"impayé","pos":"NOM"} ,
		{"word":"impayés","word_nosc":"impayes","lemma":"impayé","pos":"NOM"} ,
		{"word":"impeachment","word_nosc":"impeachment","lemma":"impeachment","pos":"NOM"} ,
		{"word":"impeccabilité","word_nosc":"impeccabilite","lemma":"impeccabilité","pos":"NOM"} ,
		{"word":"impedimenta","word_nosc":"impedimenta","lemma":"impedimenta","pos":"NOM"} ,
		{"word":"imper","word_nosc":"imper","lemma":"imper","pos":"NOM"} ,
		{"word":"imperator","word_nosc":"imperator","lemma":"imperator","pos":"NOM"} ,
		{"word":"imperfection","word_nosc":"imperfection","lemma":"imperfection","pos":"NOM"} ,
		{"word":"imperfections","word_nosc":"imperfections","lemma":"imperfection","pos":"NOM"} ,
		{"word":"imperium","word_nosc":"imperium","lemma":"imperium","pos":"NOM"} ,
		{"word":"imperméabilisant","word_nosc":"impermeabilisant","lemma":"imperméabilisant","pos":"NOM"} ,
		{"word":"imperméabilité","word_nosc":"impermeabilite","lemma":"imperméabilité","pos":"NOM"} ,
		{"word":"imperméable","word_nosc":"impermeable","lemma":"imperméable","pos":"NOM"} ,
		{"word":"imperméables","word_nosc":"impermeables","lemma":"imperméable","pos":"NOM"} ,
		{"word":"impers","word_nosc":"impers","lemma":"imper","pos":"NOM"} ,
		{"word":"impersonnalité","word_nosc":"impersonnalite","lemma":"impersonnalité","pos":"NOM"} ,
		{"word":"impertinence","word_nosc":"impertinence","lemma":"impertinence","pos":"NOM"} ,
		{"word":"impertinences","word_nosc":"impertinences","lemma":"impertinence","pos":"NOM"} ,
		{"word":"impertinent","word_nosc":"impertinent","lemma":"impertinent","pos":"NOM"} ,
		{"word":"impertinente","word_nosc":"impertinente","lemma":"impertinent","pos":"NOM"} ,
		{"word":"impertinents","word_nosc":"impertinents","lemma":"impertinent","pos":"NOM"} ,
		{"word":"impie","word_nosc":"impie","lemma":"impie","pos":"NOM"} ,
		{"word":"impies","word_nosc":"impies","lemma":"impie","pos":"NOM"} ,
		{"word":"impiété","word_nosc":"impiete","lemma":"impiété","pos":"NOM"} ,
		{"word":"impiétés","word_nosc":"impietes","lemma":"impiété","pos":"NOM"} ,
		{"word":"implacabilité","word_nosc":"implacabilite","lemma":"implacabilité","pos":"NOM"} ,
		{"word":"implant","word_nosc":"implant","lemma":"implant","pos":"NOM"} ,
		{"word":"implantation","word_nosc":"implantation","lemma":"implantation","pos":"NOM"} ,
		{"word":"implantations","word_nosc":"implantations","lemma":"implantation","pos":"NOM"} ,
		{"word":"implants","word_nosc":"implants","lemma":"implant","pos":"NOM"} ,
		{"word":"implication","word_nosc":"implication","lemma":"implication","pos":"NOM"} ,
		{"word":"implications","word_nosc":"implications","lemma":"implication","pos":"NOM"} ,
		{"word":"imploration","word_nosc":"imploration","lemma":"imploration","pos":"NOM"} ,
		{"word":"implorations","word_nosc":"implorations","lemma":"imploration","pos":"NOM"} ,
		{"word":"implosion","word_nosc":"implosion","lemma":"implosion","pos":"NOM"} ,
		{"word":"implosive","word_nosc":"implosive","lemma":"implosive","pos":"NOM"} ,
		{"word":"implémentation","word_nosc":"implementation","lemma":"implémentation","pos":"NOM"} ,
		{"word":"impoli","word_nosc":"impoli","lemma":"impoli","pos":"NOM"} ,
		{"word":"impolie","word_nosc":"impolie","lemma":"impoli","pos":"NOM"} ,
		{"word":"impolis","word_nosc":"impolis","lemma":"impoli","pos":"NOM"} ,
		{"word":"impolitesse","word_nosc":"impolitesse","lemma":"impolitesse","pos":"NOM"} ,
		{"word":"impolitesses","word_nosc":"impolitesses","lemma":"impolitesse","pos":"NOM"} ,
		{"word":"impondérable","word_nosc":"imponderable","lemma":"impondérable","pos":"NOM"} ,
		{"word":"impondérables","word_nosc":"imponderables","lemma":"impondérable","pos":"NOM"} ,
		{"word":"impopularité","word_nosc":"impopularite","lemma":"impopularité","pos":"NOM"} ,
		{"word":"import","word_nosc":"import","lemma":"import","pos":"NOM"} ,
		{"word":"import-export","word_nosc":"import-export","lemma":"import-export","pos":"NOM"} ,
		{"word":"importance","word_nosc":"importance","lemma":"importance","pos":"NOM"} ,
		{"word":"importances","word_nosc":"importances","lemma":"importance","pos":"NOM"} ,
		{"word":"important","word_nosc":"important","lemma":"important","pos":"NOM"} ,
		{"word":"importants","word_nosc":"importants","lemma":"important","pos":"NOM"} ,
		{"word":"importateur","word_nosc":"importateur","lemma":"importateur","pos":"NOM"} ,
		{"word":"importateurs","word_nosc":"importateurs","lemma":"importateur","pos":"NOM"} ,
		{"word":"importation","word_nosc":"importation","lemma":"importation","pos":"NOM"} ,
		{"word":"importations","word_nosc":"importations","lemma":"importation","pos":"NOM"} ,
		{"word":"imports","word_nosc":"imports","lemma":"import","pos":"NOM"} ,
		{"word":"importun","word_nosc":"importun","lemma":"importun","pos":"NOM"} ,
		{"word":"importune","word_nosc":"importune","lemma":"importun","pos":"NOM"} ,
		{"word":"importunes","word_nosc":"importunes","lemma":"importun","pos":"NOM"} ,
		{"word":"importunités","word_nosc":"importunites","lemma":"importunité","pos":"NOM"} ,
		{"word":"importuns","word_nosc":"importuns","lemma":"importun","pos":"NOM"} ,
		{"word":"imposition","word_nosc":"imposition","lemma":"imposition","pos":"NOM"} ,
		{"word":"impositions","word_nosc":"impositions","lemma":"imposition","pos":"NOM"} ,
		{"word":"impossibilité","word_nosc":"impossibilite","lemma":"impossibilité","pos":"NOM"} ,
		{"word":"impossibilités","word_nosc":"impossibilites","lemma":"impossibilité","pos":"NOM"} ,
		{"word":"impossible","word_nosc":"impossible","lemma":"impossible","pos":"NOM"} ,
		{"word":"impossibles","word_nosc":"impossibles","lemma":"impossible","pos":"NOM"} ,
		{"word":"imposte","word_nosc":"imposte","lemma":"imposte","pos":"NOM"} ,
		{"word":"imposteur","word_nosc":"imposteur","lemma":"imposteur","pos":"NOM"} ,
		{"word":"imposteurs","word_nosc":"imposteurs","lemma":"imposteur","pos":"NOM"} ,
		{"word":"imposture","word_nosc":"imposture","lemma":"imposture","pos":"NOM"} ,
		{"word":"impostures","word_nosc":"impostures","lemma":"imposture","pos":"NOM"} ,
		{"word":"imposé","word_nosc":"impose","lemma":"imposé","pos":"NOM"} ,
		{"word":"imposée","word_nosc":"imposee","lemma":"imposé","pos":"NOM"} ,
		{"word":"impotence","word_nosc":"impotence","lemma":"impotence","pos":"NOM"} ,
		{"word":"impotent","word_nosc":"impotent","lemma":"impotent","pos":"NOM"} ,
		{"word":"impotente","word_nosc":"impotente","lemma":"impotent","pos":"NOM"} ,
		{"word":"impotents","word_nosc":"impotents","lemma":"impotent","pos":"NOM"} ,
		{"word":"impraticabilité","word_nosc":"impraticabilite","lemma":"impraticabilité","pos":"NOM"} ,
		{"word":"impresarii","word_nosc":"impresarii","lemma":"impresario","pos":"NOM"} ,
		{"word":"impresario","word_nosc":"impresario","lemma":"impresario","pos":"NOM"} ,
		{"word":"impresarios","word_nosc":"impresarios","lemma":"impresario","pos":"NOM"} ,
		{"word":"impression","word_nosc":"impression","lemma":"impression","pos":"NOM"} ,
		{"word":"impressionnisme","word_nosc":"impressionnisme","lemma":"impressionnisme","pos":"NOM"} ,
		{"word":"impressionniste","word_nosc":"impressionniste","lemma":"impressionniste","pos":"NOM"} ,
		{"word":"impressionnistes","word_nosc":"impressionnistes","lemma":"impressionniste","pos":"NOM"} ,
		{"word":"impressions","word_nosc":"impressions","lemma":"impression","pos":"NOM"} ,
		{"word":"imprimante","word_nosc":"imprimante","lemma":"imprimante","pos":"NOM"} ,
		{"word":"imprimatur","word_nosc":"imprimatur","lemma":"imprimatur","pos":"NOM"} ,
		{"word":"imprimerie","word_nosc":"imprimerie","lemma":"imprimerie","pos":"NOM"} ,
		{"word":"imprimeries","word_nosc":"imprimeries","lemma":"imprimerie","pos":"NOM"} ,
		{"word":"imprimeur","word_nosc":"imprimeur","lemma":"imprimeur","pos":"NOM"} ,
		{"word":"imprimeurs","word_nosc":"imprimeurs","lemma":"imprimeur","pos":"NOM"} ,
		{"word":"imprimé","word_nosc":"imprime","lemma":"imprimé","pos":"NOM"} ,
		{"word":"imprimés","word_nosc":"imprimes","lemma":"imprimé","pos":"NOM"} ,
		{"word":"impro","word_nosc":"impro","lemma":"impro","pos":"NOM"} ,
		{"word":"improbabilité","word_nosc":"improbabilite","lemma":"improbabilité","pos":"NOM"} ,
		{"word":"impromptu","word_nosc":"impromptu","lemma":"impromptu","pos":"NOM"} ,
		{"word":"impromptus","word_nosc":"impromptus","lemma":"impromptu","pos":"NOM"} ,
		{"word":"impropriétés","word_nosc":"improprietes","lemma":"impropriété","pos":"NOM"} ,
		{"word":"improvisade","word_nosc":"improvisade","lemma":"improvisade","pos":"NOM"} ,
		{"word":"improvisateur","word_nosc":"improvisateur","lemma":"improvisateur","pos":"NOM"} ,
		{"word":"improvisateurs","word_nosc":"improvisateurs","lemma":"improvisateur","pos":"NOM"} ,
		{"word":"improvisation","word_nosc":"improvisation","lemma":"improvisation","pos":"NOM"} ,
		{"word":"improvisations","word_nosc":"improvisations","lemma":"improvisation","pos":"NOM"} ,
		{"word":"imprudence","word_nosc":"imprudence","lemma":"imprudence","pos":"NOM"} ,
		{"word":"imprudences","word_nosc":"imprudences","lemma":"imprudence","pos":"NOM"} ,
		{"word":"imprudent","word_nosc":"imprudent","lemma":"imprudent","pos":"NOM"} ,
		{"word":"imprudents","word_nosc":"imprudents","lemma":"imprudent","pos":"NOM"} ,
		{"word":"imprécateur","word_nosc":"imprecateur","lemma":"imprécateur","pos":"NOM"} ,
		{"word":"imprécation","word_nosc":"imprecation","lemma":"imprécation","pos":"NOM"} ,
		{"word":"imprécations","word_nosc":"imprecations","lemma":"imprécation","pos":"NOM"} ,
		{"word":"imprécisable","word_nosc":"imprecisable","lemma":"imprécisable","pos":"NOM"} ,
		{"word":"imprécision","word_nosc":"imprecision","lemma":"imprécision","pos":"NOM"} ,
		{"word":"imprécisions","word_nosc":"imprecisions","lemma":"imprécision","pos":"NOM"} ,
		{"word":"imprégnation","word_nosc":"impregnation","lemma":"imprégnation","pos":"NOM"} ,
		{"word":"imprégnations","word_nosc":"impregnations","lemma":"imprégnation","pos":"NOM"} ,
		{"word":"impréparation","word_nosc":"impreparation","lemma":"impréparation","pos":"NOM"} ,
		{"word":"imprésario","word_nosc":"impresario","lemma":"imprésario","pos":"NOM"} ,
		{"word":"imprésarios","word_nosc":"impresarios","lemma":"imprésario","pos":"NOM"} ,
		{"word":"imprévisibilité","word_nosc":"imprevisibilite","lemma":"imprévisibilité","pos":"NOM"} ,
		{"word":"imprévision","word_nosc":"imprevision","lemma":"imprévision","pos":"NOM"} ,
		{"word":"imprévoyance","word_nosc":"imprevoyance","lemma":"imprévoyance","pos":"NOM"} ,
		{"word":"imprévu","word_nosc":"imprevu","lemma":"imprévu","pos":"NOM"} ,
		{"word":"imprévus","word_nosc":"imprevus","lemma":"imprévu","pos":"NOM"} ,
		{"word":"impudence","word_nosc":"impudence","lemma":"impudence","pos":"NOM"} ,
		{"word":"impudences","word_nosc":"impudences","lemma":"impudence","pos":"NOM"} ,
		{"word":"impudeur","word_nosc":"impudeur","lemma":"impudeur","pos":"NOM"} ,
		{"word":"impudeurs","word_nosc":"impudeurs","lemma":"impudeur","pos":"NOM"} ,
		{"word":"impudicité","word_nosc":"impudicite","lemma":"impudicité","pos":"NOM"} ,
		{"word":"impuissance","word_nosc":"impuissance","lemma":"impuissance","pos":"NOM"} ,
		{"word":"impuissances","word_nosc":"impuissances","lemma":"impuissance","pos":"NOM"} ,
		{"word":"impuissant","word_nosc":"impuissant","lemma":"impuissant","pos":"NOM"} ,
		{"word":"impuissants","word_nosc":"impuissants","lemma":"impuissant","pos":"NOM"} ,
		{"word":"impulsif","word_nosc":"impulsif","lemma":"impulsif","pos":"NOM"} ,
		{"word":"impulsifs","word_nosc":"impulsifs","lemma":"impulsif","pos":"NOM"} ,
		{"word":"impulsion","word_nosc":"impulsion","lemma":"impulsion","pos":"NOM"} ,
		{"word":"impulsions","word_nosc":"impulsions","lemma":"impulsion","pos":"NOM"} ,
		{"word":"impulsive","word_nosc":"impulsive","lemma":"impulsive","pos":"NOM"} ,
		{"word":"impulsivité","word_nosc":"impulsivite","lemma":"impulsivité","pos":"NOM"} ,
		{"word":"impunité","word_nosc":"impunite","lemma":"impunité","pos":"NOM"} ,
		{"word":"impureté","word_nosc":"impurete","lemma":"impureté","pos":"NOM"} ,
		{"word":"impuretés","word_nosc":"impuretes","lemma":"impureté","pos":"NOM"} ,
		{"word":"imputation","word_nosc":"imputation","lemma":"imputation","pos":"NOM"} ,
		{"word":"imputations","word_nosc":"imputations","lemma":"imputation","pos":"NOM"} ,
		{"word":"impécuniosité","word_nosc":"impecuniosite","lemma":"impécuniosité","pos":"NOM"} ,
		{"word":"impédance","word_nosc":"impedance","lemma":"impédance","pos":"NOM"} ,
		{"word":"impénétrabilité","word_nosc":"impenetrabilite","lemma":"impénétrabilité","pos":"NOM"} ,
		{"word":"impératif","word_nosc":"imperatif","lemma":"impératif","pos":"NOM"} ,
		{"word":"impératifs","word_nosc":"imperatifs","lemma":"impératif","pos":"NOM"} ,
		{"word":"impératrice","word_nosc":"imperatrice","lemma":"impératrice","pos":"NOM"} ,
		{"word":"impératrices","word_nosc":"imperatrices","lemma":"impératrice","pos":"NOM"} ,
		{"word":"impériale","word_nosc":"imperiale","lemma":"impériale","pos":"NOM"} ,
		{"word":"impériales","word_nosc":"imperiales","lemma":"impériale","pos":"NOM"} ,
		{"word":"impérialisation","word_nosc":"imperialisation","lemma":"impérialisation","pos":"NOM"} ,
		{"word":"impérialisme","word_nosc":"imperialisme","lemma":"impérialisme","pos":"NOM"} ,
		{"word":"impérialiste","word_nosc":"imperialiste","lemma":"impérialiste","pos":"NOM"} ,
		{"word":"impérialistes","word_nosc":"imperialistes","lemma":"impérialiste","pos":"NOM"} ,
		{"word":"impéritie","word_nosc":"imperitie","lemma":"impéritie","pos":"NOM"} ,
		{"word":"impétigo","word_nosc":"impetigo","lemma":"impétigo","pos":"NOM"} ,
		{"word":"impétrant","word_nosc":"impetrant","lemma":"impétrant","pos":"NOM"} ,
		{"word":"impétrante","word_nosc":"impetrante","lemma":"impétrant","pos":"NOM"} ,
		{"word":"impétrants","word_nosc":"impetrants","lemma":"impétrant","pos":"NOM"} ,
		{"word":"impétuosité","word_nosc":"impetuosite","lemma":"impétuosité","pos":"NOM"} ,
		{"word":"impôt","word_nosc":"impot","lemma":"impôt","pos":"NOM"} ,
		{"word":"impôts","word_nosc":"impots","lemma":"impôt","pos":"NOM"} ,
		{"word":"in pace","word_nosc":"in pace","lemma":"in pace","pos":"NOM"} ,
		{"word":"in-bord","word_nosc":"in-bord","lemma":"in-bord","pos":"NOM"} ,
		{"word":"in-folio","word_nosc":"in-folio","lemma":"in-folio","pos":"NOM"} ,
		{"word":"in-folios","word_nosc":"in-folios","lemma":"in-folios","pos":"NOM"} ,
		{"word":"in-octavo","word_nosc":"in-octavo","lemma":"in-octavo","pos":"NOM"} ,
		{"word":"in-octavos","word_nosc":"in-octavos","lemma":"in-octavos","pos":"NOM"} ,
		{"word":"in-quarto","word_nosc":"in-quarto","lemma":"in-quarto","pos":"NOM"} ,
		{"word":"in-seize","word_nosc":"in-seize","lemma":"in-seize","pos":"NOM"} ,
		{"word":"inaccessibilité","word_nosc":"inaccessibilite","lemma":"inaccessibilité","pos":"NOM"} ,
		{"word":"inaccomplissement","word_nosc":"inaccomplissement","lemma":"inaccomplissement","pos":"NOM"} ,
		{"word":"inachèvement","word_nosc":"inachevement","lemma":"inachèvement","pos":"NOM"} ,
		{"word":"inactif","word_nosc":"inactif","lemma":"inactif","pos":"NOM"} ,
		{"word":"inactifs","word_nosc":"inactifs","lemma":"inactif","pos":"NOM"} ,
		{"word":"inaction","word_nosc":"inaction","lemma":"inaction","pos":"NOM"} ,
		{"word":"inactivation","word_nosc":"inactivation","lemma":"inactivation","pos":"NOM"} ,
		{"word":"inactivité","word_nosc":"inactivite","lemma":"inactivité","pos":"NOM"} ,
		{"word":"inadaptation","word_nosc":"inadaptation","lemma":"inadaptation","pos":"NOM"} ,
		{"word":"inadapté","word_nosc":"inadapte","lemma":"inadapté","pos":"NOM"} ,
		{"word":"inadaptée","word_nosc":"inadaptee","lemma":"inadapté","pos":"NOM"} ,
		{"word":"inadaptés","word_nosc":"inadaptes","lemma":"inadapté","pos":"NOM"} ,
		{"word":"inadvertance","word_nosc":"inadvertance","lemma":"inadvertance","pos":"NOM"} ,
		{"word":"inadvertances","word_nosc":"inadvertances","lemma":"inadvertance","pos":"NOM"} ,
		{"word":"inadéquation","word_nosc":"inadequation","lemma":"inadéquation","pos":"NOM"} ,
		{"word":"inanimation","word_nosc":"inanimation","lemma":"inanimation","pos":"NOM"} ,
		{"word":"inanition","word_nosc":"inanition","lemma":"inanition","pos":"NOM"} ,
		{"word":"inanité","word_nosc":"inanite","lemma":"inanité","pos":"NOM"} ,
		{"word":"inapplication","word_nosc":"inapplication","lemma":"inapplication","pos":"NOM"} ,
		{"word":"inappétence","word_nosc":"inappetence","lemma":"inappétence","pos":"NOM"} ,
		{"word":"inaptitude","word_nosc":"inaptitude","lemma":"inaptitude","pos":"NOM"} ,
		{"word":"inaptitudes","word_nosc":"inaptitudes","lemma":"inaptitude","pos":"NOM"} ,
		{"word":"inarticulé","word_nosc":"inarticule","lemma":"inarticulé","pos":"NOM"} ,
		{"word":"inassouvissement","word_nosc":"inassouvissement","lemma":"inassouvissement","pos":"NOM"} ,
		{"word":"inattention","word_nosc":"inattention","lemma":"inattention","pos":"NOM"} ,
		{"word":"inauguration","word_nosc":"inauguration","lemma":"inauguration","pos":"NOM"} ,
		{"word":"inaugurations","word_nosc":"inaugurations","lemma":"inauguration","pos":"NOM"} ,
		{"word":"inauthenticité","word_nosc":"inauthenticite","lemma":"inauthenticité","pos":"NOM"} ,
		{"word":"incandescence","word_nosc":"incandescence","lemma":"incandescence","pos":"NOM"} ,
		{"word":"incandescences","word_nosc":"incandescences","lemma":"incandescence","pos":"NOM"} ,
		{"word":"incantation","word_nosc":"incantation","lemma":"incantation","pos":"NOM"} ,
		{"word":"incantations","word_nosc":"incantations","lemma":"incantation","pos":"NOM"} ,
		{"word":"incantatrice","word_nosc":"incantatrice","lemma":"incantateur","pos":"NOM"} ,
		{"word":"incapable","word_nosc":"incapable","lemma":"incapable","pos":"NOM"} ,
		{"word":"incapables","word_nosc":"incapables","lemma":"incapable","pos":"NOM"} ,
		{"word":"incapacité","word_nosc":"incapacite","lemma":"incapacité","pos":"NOM"} ,
		{"word":"incapacités","word_nosc":"incapacites","lemma":"incapacité","pos":"NOM"} ,
		{"word":"incarcération","word_nosc":"incarceration","lemma":"incarcération","pos":"NOM"} ,
		{"word":"incarnat","word_nosc":"incarnat","lemma":"incarnat","pos":"NOM"} ,
		{"word":"incarnation","word_nosc":"incarnation","lemma":"incarnation","pos":"NOM"} ,
		{"word":"incarnations","word_nosc":"incarnations","lemma":"incarnation","pos":"NOM"} ,
		{"word":"incartade","word_nosc":"incartade","lemma":"incartade","pos":"NOM"} ,
		{"word":"incartades","word_nosc":"incartades","lemma":"incartade","pos":"NOM"} ,
		{"word":"incendiaire","word_nosc":"incendiaire","lemma":"incendiaire","pos":"NOM"} ,
		{"word":"incendiaires","word_nosc":"incendiaires","lemma":"incendiaire","pos":"NOM"} ,
		{"word":"incendie","word_nosc":"incendie","lemma":"incendie","pos":"NOM"} ,
		{"word":"incendies","word_nosc":"incendies","lemma":"incendie","pos":"NOM"} ,
		{"word":"incendié","word_nosc":"incendie","lemma":"incendié","pos":"NOM"} ,
		{"word":"incendiée","word_nosc":"incendiee","lemma":"incendié","pos":"NOM"} ,
		{"word":"incendiés","word_nosc":"incendies","lemma":"incendié","pos":"NOM"} ,
		{"word":"incertitude","word_nosc":"incertitude","lemma":"incertitude","pos":"NOM"} ,
		{"word":"incertitudes","word_nosc":"incertitudes","lemma":"incertitude","pos":"NOM"} ,
		{"word":"inceste","word_nosc":"inceste","lemma":"inceste","pos":"NOM"} ,
		{"word":"incestes","word_nosc":"incestes","lemma":"inceste","pos":"NOM"} ,
		{"word":"incestueuse","word_nosc":"incestueuse","lemma":"incestueux","pos":"NOM"} ,
		{"word":"incestueuses","word_nosc":"incestueuses","lemma":"incestueux","pos":"NOM"} ,
		{"word":"incidence","word_nosc":"incidence","lemma":"incidence","pos":"NOM"} ,
		{"word":"incidences","word_nosc":"incidences","lemma":"incidence","pos":"NOM"} ,
		{"word":"incident","word_nosc":"incident","lemma":"incident","pos":"NOM"} ,
		{"word":"incidents","word_nosc":"incidents","lemma":"incident","pos":"NOM"} ,
		{"word":"incinérateur","word_nosc":"incinerateur","lemma":"incinérateur","pos":"NOM"} ,
		{"word":"incinérateurs","word_nosc":"incinerateurs","lemma":"incinérateur","pos":"NOM"} ,
		{"word":"incinération","word_nosc":"incineration","lemma":"incinération","pos":"NOM"} ,
		{"word":"incise","word_nosc":"incise","lemma":"incise","pos":"NOM"} ,
		{"word":"incises","word_nosc":"incises","lemma":"incise","pos":"NOM"} ,
		{"word":"incision","word_nosc":"incision","lemma":"incision","pos":"NOM"} ,
		{"word":"incisions","word_nosc":"incisions","lemma":"incision","pos":"NOM"} ,
		{"word":"incisive","word_nosc":"incisive","lemma":"incisive","pos":"NOM"} ,
		{"word":"incisives","word_nosc":"incisives","lemma":"incisive","pos":"NOM"} ,
		{"word":"incitateur","word_nosc":"incitateur","lemma":"incitateur","pos":"NOM"} ,
		{"word":"incitation","word_nosc":"incitation","lemma":"incitation","pos":"NOM"} ,
		{"word":"incitatrice","word_nosc":"incitatrice","lemma":"incitateur","pos":"NOM"} ,
		{"word":"inclinaison","word_nosc":"inclinaison","lemma":"inclinaison","pos":"NOM"} ,
		{"word":"inclinaisons","word_nosc":"inclinaisons","lemma":"inclinaison","pos":"NOM"} ,
		{"word":"inclination","word_nosc":"inclination","lemma":"inclination","pos":"NOM"} ,
		{"word":"inclinations","word_nosc":"inclinations","lemma":"inclination","pos":"NOM"} ,
		{"word":"inclinomètre","word_nosc":"inclinometre","lemma":"inclinomètre","pos":"NOM"} ,
		{"word":"inclusion","word_nosc":"inclusion","lemma":"inclusion","pos":"NOM"} ,
		{"word":"inclémence","word_nosc":"inclemence","lemma":"inclémence","pos":"NOM"} ,
		{"word":"inclémences","word_nosc":"inclemences","lemma":"inclémence","pos":"NOM"} ,
		{"word":"incognito","word_nosc":"incognito","lemma":"incognito","pos":"NOM"} ,
		{"word":"incognitos","word_nosc":"incognitos","lemma":"incognito","pos":"NOM"} ,
		{"word":"incohérence","word_nosc":"incoherence","lemma":"incohérence","pos":"NOM"} ,
		{"word":"incohérences","word_nosc":"incoherences","lemma":"incohérence","pos":"NOM"} ,
		{"word":"incommodité","word_nosc":"incommodite","lemma":"incommodité","pos":"NOM"} ,
		{"word":"incommodités","word_nosc":"incommodites","lemma":"incommodité","pos":"NOM"} ,
		{"word":"incommunicabilité","word_nosc":"incommunicabilite","lemma":"incommunicabilité","pos":"NOM"} ,
		{"word":"incompatibilité","word_nosc":"incompatibilite","lemma":"incompatibilité","pos":"NOM"} ,
		{"word":"incompatibilités","word_nosc":"incompatibilites","lemma":"incompatibilité","pos":"NOM"} ,
		{"word":"incomplétude","word_nosc":"incompletude","lemma":"incomplétude","pos":"NOM"} ,
		{"word":"incompris","word_nosc":"incompris","lemma":"incompris","pos":"NOM"} ,
		{"word":"incomprise","word_nosc":"incomprise","lemma":"incompris","pos":"NOM"} ,
		{"word":"incomprises","word_nosc":"incomprises","lemma":"incompris","pos":"NOM"} ,
		{"word":"incompréhension","word_nosc":"incomprehension","lemma":"incompréhension","pos":"NOM"} ,
		{"word":"incompréhensions","word_nosc":"incomprehensions","lemma":"incompréhension","pos":"NOM"} ,
		{"word":"incompétence","word_nosc":"incompetence","lemma":"incompétence","pos":"NOM"} ,
		{"word":"incompétences","word_nosc":"incompetences","lemma":"incompétence","pos":"NOM"} ,
		{"word":"inconditionnel","word_nosc":"inconditionnel","lemma":"inconditionnel","pos":"NOM"} ,
		{"word":"inconditionnelle","word_nosc":"inconditionnelle","lemma":"inconditionnel","pos":"NOM"} ,
		{"word":"inconditionnelles","word_nosc":"inconditionnelles","lemma":"inconditionnel","pos":"NOM"} ,
		{"word":"inconditionnels","word_nosc":"inconditionnels","lemma":"inconditionnel","pos":"NOM"} ,
		{"word":"inconduite","word_nosc":"inconduite","lemma":"inconduite","pos":"NOM"} ,
		{"word":"inconfiance","word_nosc":"inconfiance","lemma":"inconfiance","pos":"NOM"} ,
		{"word":"inconfort","word_nosc":"inconfort","lemma":"inconfort","pos":"NOM"} ,
		{"word":"incongruité","word_nosc":"incongruite","lemma":"incongruité","pos":"NOM"} ,
		{"word":"incongruités","word_nosc":"incongruites","lemma":"incongruité","pos":"NOM"} ,
		{"word":"inconnaissable","word_nosc":"inconnaissable","lemma":"inconnaissable","pos":"NOM"} ,
		{"word":"inconnaissance","word_nosc":"inconnaissance","lemma":"inconnaissance","pos":"NOM"} ,
		{"word":"inconnu","word_nosc":"inconnu","lemma":"inconnu","pos":"NOM"} ,
		{"word":"inconnue","word_nosc":"inconnue","lemma":"inconnu","pos":"NOM"} ,
		{"word":"inconnues","word_nosc":"inconnues","lemma":"inconnu","pos":"NOM"} ,
		{"word":"inconnus","word_nosc":"inconnus","lemma":"inconnu","pos":"NOM"} ,
		{"word":"inconscience","word_nosc":"inconscience","lemma":"inconscience","pos":"NOM"} ,
		{"word":"inconsciences","word_nosc":"inconsciences","lemma":"inconscience","pos":"NOM"} ,
		{"word":"inconscient","word_nosc":"inconscient","lemma":"inconscient","pos":"NOM"} ,
		{"word":"inconscients","word_nosc":"inconscients","lemma":"inconscient","pos":"NOM"} ,
		{"word":"inconsistance","word_nosc":"inconsistance","lemma":"inconsistance","pos":"NOM"} ,
		{"word":"inconstance","word_nosc":"inconstance","lemma":"inconstance","pos":"NOM"} ,
		{"word":"inconstant","word_nosc":"inconstant","lemma":"inconstant","pos":"NOM"} ,
		{"word":"inconstante","word_nosc":"inconstante","lemma":"inconstant","pos":"NOM"} ,
		{"word":"inconstants","word_nosc":"inconstants","lemma":"inconstant","pos":"NOM"} ,
		{"word":"inconséquence","word_nosc":"inconsequence","lemma":"inconséquence","pos":"NOM"} ,
		{"word":"inconséquences","word_nosc":"inconsequences","lemma":"inconséquence","pos":"NOM"} ,
		{"word":"incontinence","word_nosc":"incontinence","lemma":"incontinence","pos":"NOM"} ,
		{"word":"incontinences","word_nosc":"incontinences","lemma":"incontinence","pos":"NOM"} ,
		{"word":"inconvenance","word_nosc":"inconvenance","lemma":"inconvenance","pos":"NOM"} ,
		{"word":"inconvenances","word_nosc":"inconvenances","lemma":"inconvenance","pos":"NOM"} ,
		{"word":"inconvénient","word_nosc":"inconvenient","lemma":"inconvénient","pos":"NOM"} ,
		{"word":"inconvénients","word_nosc":"inconvenients","lemma":"inconvénient","pos":"NOM"} ,
		{"word":"incoordination","word_nosc":"incoordination","lemma":"incoordination","pos":"NOM"} ,
		{"word":"incorporation","word_nosc":"incorporation","lemma":"incorporation","pos":"NOM"} ,
		{"word":"incorporations","word_nosc":"incorporations","lemma":"incorporation","pos":"NOM"} ,
		{"word":"incorrection","word_nosc":"incorrection","lemma":"incorrection","pos":"NOM"} ,
		{"word":"incorruptibilité","word_nosc":"incorruptibilite","lemma":"incorruptibilité","pos":"NOM"} ,
		{"word":"incrimination","word_nosc":"incrimination","lemma":"incrimination","pos":"NOM"} ,
		{"word":"incroyable","word_nosc":"incroyable","lemma":"incroyable","pos":"NOM"} ,
		{"word":"incroyables","word_nosc":"incroyables","lemma":"incroyable","pos":"NOM"} ,
		{"word":"incroyance","word_nosc":"incroyance","lemma":"incroyance","pos":"NOM"} ,
		{"word":"incroyant","word_nosc":"incroyant","lemma":"incroyant","pos":"NOM"} ,
		{"word":"incroyante","word_nosc":"incroyante","lemma":"incroyant","pos":"NOM"} ,
		{"word":"incroyants","word_nosc":"incroyants","lemma":"incroyant","pos":"NOM"} ,
		{"word":"incrustation","word_nosc":"incrustation","lemma":"incrustation","pos":"NOM"} ,
		{"word":"incrustations","word_nosc":"incrustations","lemma":"incrustation","pos":"NOM"} ,
		{"word":"incrédibilité","word_nosc":"incredibilite","lemma":"incrédibilité","pos":"NOM"} ,
		{"word":"incrédule","word_nosc":"incredule","lemma":"incrédule","pos":"NOM"} ,
		{"word":"incrédules","word_nosc":"incredules","lemma":"incrédule","pos":"NOM"} ,
		{"word":"incrédulité","word_nosc":"incredulite","lemma":"incrédulité","pos":"NOM"} ,
		{"word":"incubateur","word_nosc":"incubateur","lemma":"incubateur","pos":"NOM"} ,
		{"word":"incubateurs","word_nosc":"incubateurs","lemma":"incubateur","pos":"NOM"} ,
		{"word":"incubation","word_nosc":"incubation","lemma":"incubation","pos":"NOM"} ,
		{"word":"incube","word_nosc":"incube","lemma":"incube","pos":"NOM"} ,
		{"word":"incubes","word_nosc":"incubes","lemma":"incube","pos":"NOM"} ,
		{"word":"inculcation","word_nosc":"inculcation","lemma":"inculcation","pos":"NOM"} ,
		{"word":"inculpation","word_nosc":"inculpation","lemma":"inculpation","pos":"NOM"} ,
		{"word":"inculpations","word_nosc":"inculpations","lemma":"inculpation","pos":"NOM"} ,
		{"word":"inculpé","word_nosc":"inculpe","lemma":"inculpé","pos":"NOM"} ,
		{"word":"inculpée","word_nosc":"inculpee","lemma":"inculpé","pos":"NOM"} ,
		{"word":"inculpés","word_nosc":"inculpes","lemma":"inculpé","pos":"NOM"} ,
		{"word":"inculture","word_nosc":"inculture","lemma":"inculture","pos":"NOM"} ,
		{"word":"incunables","word_nosc":"incunables","lemma":"incunable","pos":"NOM"} ,
		{"word":"incurable","word_nosc":"incurable","lemma":"incurable","pos":"NOM"} ,
		{"word":"incurables","word_nosc":"incurables","lemma":"incurable","pos":"NOM"} ,
		{"word":"incurie","word_nosc":"incurie","lemma":"incurie","pos":"NOM"} ,
		{"word":"incuriosité","word_nosc":"incuriosite","lemma":"incuriosité","pos":"NOM"} ,
		{"word":"incursion","word_nosc":"incursion","lemma":"incursion","pos":"NOM"} ,
		{"word":"incursions","word_nosc":"incursions","lemma":"incursion","pos":"NOM"} ,
		{"word":"incurvation","word_nosc":"incurvation","lemma":"incurvation","pos":"NOM"} ,
		{"word":"inde","word_nosc":"inde","lemma":"inde","pos":"NOM"} ,
		{"word":"indemnisation","word_nosc":"indemnisation","lemma":"indemnisation","pos":"NOM"} ,
		{"word":"indemnisations","word_nosc":"indemnisations","lemma":"indemnisation","pos":"NOM"} ,
		{"word":"indemnité","word_nosc":"indemnite","lemma":"indemnité","pos":"NOM"} ,
		{"word":"indemnités","word_nosc":"indemnites","lemma":"indemnité","pos":"NOM"} ,
		{"word":"indemnités-repas","word_nosc":"indemnites-repas","lemma":"indemnités-repa","pos":"NOM"} ,
		{"word":"indentation","word_nosc":"indentation","lemma":"indentation","pos":"NOM"} ,
		{"word":"indentification","word_nosc":"indentification","lemma":"indentification","pos":"NOM"} ,
		{"word":"indes","word_nosc":"indes","lemma":"inde","pos":"NOM"} ,
		{"word":"indestructibilité","word_nosc":"indestructibilite","lemma":"indestructibilité","pos":"NOM"} ,
		{"word":"index","word_nosc":"index","lemma":"index","pos":"NOM"} ,
		{"word":"indexation","word_nosc":"indexation","lemma":"indexation","pos":"NOM"} ,
		{"word":"indexeur","word_nosc":"indexeur","lemma":"indexeur","pos":"NOM"} ,
		{"word":"indic","word_nosc":"indic","lemma":"indic","pos":"NOM"} ,
		{"word":"indicateur","word_nosc":"indicateur","lemma":"indicateur","pos":"NOM"} ,
		{"word":"indicateurs","word_nosc":"indicateurs","lemma":"indicateur","pos":"NOM"} ,
		{"word":"indicatif","word_nosc":"indicatif","lemma":"indicatif","pos":"NOM"} ,
		{"word":"indicatifs","word_nosc":"indicatifs","lemma":"indicatif","pos":"NOM"} ,
		{"word":"indication","word_nosc":"indication","lemma":"indication","pos":"NOM"} ,
		{"word":"indications","word_nosc":"indications","lemma":"indication","pos":"NOM"} ,
		{"word":"indice","word_nosc":"indice","lemma":"indice","pos":"NOM"} ,
		{"word":"indices","word_nosc":"indices","lemma":"indice","pos":"NOM"} ,
		{"word":"indics","word_nosc":"indics","lemma":"indic","pos":"NOM"} ,
		{"word":"indien","word_nosc":"indien","lemma":"indien","pos":"NOM"} ,
		{"word":"indienne","word_nosc":"indienne","lemma":"indien","pos":"NOM"} ,
		{"word":"indienneries","word_nosc":"indienneries","lemma":"indiennerie","pos":"NOM"} ,
		{"word":"indiennes","word_nosc":"indiennes","lemma":"indien","pos":"NOM"} ,
		{"word":"indiens","word_nosc":"indiens","lemma":"indien","pos":"NOM"} ,
		{"word":"indifférence","word_nosc":"indifference","lemma":"indifférence","pos":"NOM"} ,
		{"word":"indifférences","word_nosc":"indifferences","lemma":"indifférence","pos":"NOM"} ,
		{"word":"indifférenciation","word_nosc":"indifferenciation","lemma":"indifférenciation","pos":"NOM"} ,
		{"word":"indifférent","word_nosc":"indifferent","lemma":"indifférent","pos":"NOM"} ,
		{"word":"indifférente","word_nosc":"indifferente","lemma":"indifférent","pos":"NOM"} ,
		{"word":"indifférentes","word_nosc":"indifferentes","lemma":"indifférent","pos":"NOM"} ,
		{"word":"indifférentisme","word_nosc":"indifferentisme","lemma":"indifférentisme","pos":"NOM"} ,
		{"word":"indifférents","word_nosc":"indifferents","lemma":"indifférent","pos":"NOM"} ,
		{"word":"indigence","word_nosc":"indigence","lemma":"indigence","pos":"NOM"} ,
		{"word":"indigent","word_nosc":"indigent","lemma":"indigent","pos":"NOM"} ,
		{"word":"indigente","word_nosc":"indigente","lemma":"indigent","pos":"NOM"} ,
		{"word":"indigentes","word_nosc":"indigentes","lemma":"indigent","pos":"NOM"} ,
		{"word":"indigents","word_nosc":"indigents","lemma":"indigent","pos":"NOM"} ,
		{"word":"indigestion","word_nosc":"indigestion","lemma":"indigestion","pos":"NOM"} ,
		{"word":"indigestions","word_nosc":"indigestions","lemma":"indigestion","pos":"NOM"} ,
		{"word":"indignation","word_nosc":"indignation","lemma":"indignation","pos":"NOM"} ,
		{"word":"indignations","word_nosc":"indignations","lemma":"indignation","pos":"NOM"} ,
		{"word":"indignité","word_nosc":"indignite","lemma":"indignité","pos":"NOM"} ,
		{"word":"indignités","word_nosc":"indignites","lemma":"indignité","pos":"NOM"} ,
		{"word":"indigo","word_nosc":"indigo","lemma":"indigo","pos":"NOM"} ,
		{"word":"indigène","word_nosc":"indigene","lemma":"indigène","pos":"NOM"} ,
		{"word":"indigènes","word_nosc":"indigenes","lemma":"indigène","pos":"NOM"} ,
		{"word":"indiscipline","word_nosc":"indiscipline","lemma":"indiscipline","pos":"NOM"} ,
		{"word":"indiscret","word_nosc":"indiscret","lemma":"indiscret","pos":"NOM"} ,
		{"word":"indiscrets","word_nosc":"indiscrets","lemma":"indiscret","pos":"NOM"} ,
		{"word":"indiscrète","word_nosc":"indiscrete","lemma":"indiscrète","pos":"NOM"} ,
		{"word":"indiscrétion","word_nosc":"indiscretion","lemma":"indiscrétion","pos":"NOM"} ,
		{"word":"indiscrétions","word_nosc":"indiscretions","lemma":"indiscrétion","pos":"NOM"} ,
		{"word":"indispensable","word_nosc":"indispensable","lemma":"indispensable","pos":"NOM"} ,
		{"word":"indispensables","word_nosc":"indispensables","lemma":"indispensable","pos":"NOM"} ,
		{"word":"indisponibilité","word_nosc":"indisponibilite","lemma":"indisponibilité","pos":"NOM"} ,
		{"word":"indisposition","word_nosc":"indisposition","lemma":"indisposition","pos":"NOM"} ,
		{"word":"indistinction","word_nosc":"indistinction","lemma":"indistinction","pos":"NOM"} ,
		{"word":"individu","word_nosc":"individu","lemma":"individu","pos":"NOM"} ,
		{"word":"individualisme","word_nosc":"individualisme","lemma":"individualisme","pos":"NOM"} ,
		{"word":"individualiste","word_nosc":"individualiste","lemma":"individualiste","pos":"NOM"} ,
		{"word":"individualistes","word_nosc":"individualistes","lemma":"individualiste","pos":"NOM"} ,
		{"word":"individualité","word_nosc":"individualite","lemma":"individualité","pos":"NOM"} ,
		{"word":"individualités","word_nosc":"individualites","lemma":"individualité","pos":"NOM"} ,
		{"word":"individus","word_nosc":"individus","lemma":"individu","pos":"NOM"} ,
		{"word":"indivisibilité","word_nosc":"indivisibilite","lemma":"indivisibilité","pos":"NOM"} ,
		{"word":"indivision","word_nosc":"indivision","lemma":"indivision","pos":"NOM"} ,
		{"word":"indo-européens","word_nosc":"indo-europeens","lemma":"indo-européen","pos":"NOM"} ,
		{"word":"indochinois","word_nosc":"indochinois","lemma":"indochinois","pos":"NOM"} ,
		{"word":"indocilité","word_nosc":"indocilite","lemma":"indocilité","pos":"NOM"} ,
		{"word":"indole","word_nosc":"indole","lemma":"indole","pos":"NOM"} ,
		{"word":"indolence","word_nosc":"indolence","lemma":"indolence","pos":"NOM"} ,
		{"word":"indolences","word_nosc":"indolences","lemma":"indolence","pos":"NOM"} ,
		{"word":"indonésien","word_nosc":"indonesien","lemma":"indonésien","pos":"NOM"} ,
		{"word":"indonésienne","word_nosc":"indonesienne","lemma":"indonésien","pos":"NOM"} ,
		{"word":"indonésiens","word_nosc":"indonesiens","lemma":"indonésien","pos":"NOM"} ,
		{"word":"inducteur","word_nosc":"inducteur","lemma":"inducteur","pos":"NOM"} ,
		{"word":"induction","word_nosc":"induction","lemma":"induction","pos":"NOM"} ,
		{"word":"inductions","word_nosc":"inductions","lemma":"induction","pos":"NOM"} ,
		{"word":"induit","word_nosc":"induit","lemma":"induit","pos":"NOM"} ,
		{"word":"indulgence","word_nosc":"indulgence","lemma":"indulgence","pos":"NOM"} ,
		{"word":"indulgences","word_nosc":"indulgences","lemma":"indulgence","pos":"NOM"} ,
		{"word":"industrialisation","word_nosc":"industrialisation","lemma":"industrialisation","pos":"NOM"} ,
		{"word":"industrie","word_nosc":"industrie","lemma":"industrie","pos":"NOM"} ,
		{"word":"industrie-clé","word_nosc":"industrie-cle","lemma":"industrie-clé","pos":"NOM"} ,
		{"word":"industriel","word_nosc":"industriel","lemma":"industriel","pos":"NOM"} ,
		{"word":"industrielle","word_nosc":"industrielle","lemma":"industriel","pos":"NOM"} ,
		{"word":"industrielles","word_nosc":"industrielles","lemma":"industriel","pos":"NOM"} ,
		{"word":"industriels","word_nosc":"industriels","lemma":"industriel","pos":"NOM"} ,
		{"word":"industries","word_nosc":"industries","lemma":"industrie","pos":"NOM"} ,
		{"word":"indécence","word_nosc":"indecence","lemma":"indécence","pos":"NOM"} ,
		{"word":"indécences","word_nosc":"indecences","lemma":"indécence","pos":"NOM"} ,
		{"word":"indécis","word_nosc":"indecis","lemma":"indécis","pos":"NOM"} ,
		{"word":"indécise","word_nosc":"indecise","lemma":"indécise","pos":"NOM"} ,
		{"word":"indécises","word_nosc":"indecises","lemma":"indécises","pos":"NOM"} ,
		{"word":"indécision","word_nosc":"indecision","lemma":"indécision","pos":"NOM"} ,
		{"word":"indécisions","word_nosc":"indecisions","lemma":"indécision","pos":"NOM"} ,
		{"word":"indéfini","word_nosc":"indefini","lemma":"indéfini","pos":"NOM"} ,
		{"word":"indéfinition","word_nosc":"indefinition","lemma":"indéfinition","pos":"NOM"} ,
		{"word":"indéfrisable","word_nosc":"indefrisable","lemma":"indéfrisable","pos":"NOM"} ,
		{"word":"indéfrisables","word_nosc":"indefrisables","lemma":"indéfrisable","pos":"NOM"} ,
		{"word":"indélicatesse","word_nosc":"indelicatesse","lemma":"indélicatesse","pos":"NOM"} ,
		{"word":"indélicatesses","word_nosc":"indelicatesses","lemma":"indélicatesse","pos":"NOM"} ,
		{"word":"indépendance","word_nosc":"independance","lemma":"indépendance","pos":"NOM"} ,
		{"word":"indépendant","word_nosc":"independant","lemma":"indépendant","pos":"NOM"} ,
		{"word":"indépendante","word_nosc":"independante","lemma":"indépendant","pos":"NOM"} ,
		{"word":"indépendantes","word_nosc":"independantes","lemma":"indépendant","pos":"NOM"} ,
		{"word":"indépendantisme","word_nosc":"independantisme","lemma":"indépendantisme","pos":"NOM"} ,
		{"word":"indépendantistes","word_nosc":"independantistes","lemma":"indépendantiste","pos":"NOM"} ,
		{"word":"indépendants","word_nosc":"independants","lemma":"indépendant","pos":"NOM"} ,
		{"word":"indésirable","word_nosc":"indesirable","lemma":"indésirable","pos":"NOM"} ,
		{"word":"indésirables","word_nosc":"indesirables","lemma":"indésirable","pos":"NOM"} ,
		{"word":"indétermination","word_nosc":"indetermination","lemma":"indétermination","pos":"NOM"} ,
		{"word":"inefficacité","word_nosc":"inefficacite","lemma":"inefficacité","pos":"NOM"} ,
		{"word":"inemploi","word_nosc":"inemploi","lemma":"inemploi","pos":"NOM"} ,
		{"word":"ineptie","word_nosc":"ineptie","lemma":"ineptie","pos":"NOM"} ,
		{"word":"inepties","word_nosc":"inepties","lemma":"ineptie","pos":"NOM"} ,
		{"word":"inertie","word_nosc":"inertie","lemma":"inertie","pos":"NOM"} ,
		{"word":"inerties","word_nosc":"inerties","lemma":"inertie","pos":"NOM"} ,
		{"word":"inespoir","word_nosc":"inespoir","lemma":"inespoir","pos":"NOM"} ,
		{"word":"inexactitude","word_nosc":"inexactitude","lemma":"inexactitude","pos":"NOM"} ,
		{"word":"inexactitudes","word_nosc":"inexactitudes","lemma":"inexactitude","pos":"NOM"} ,
		{"word":"inexistence","word_nosc":"inexistence","lemma":"inexistence","pos":"NOM"} ,
		{"word":"inexorabilité","word_nosc":"inexorabilite","lemma":"inexorabilité","pos":"NOM"} ,
		{"word":"inexpression","word_nosc":"inexpression","lemma":"inexpression","pos":"NOM"} ,
		{"word":"inexpérience","word_nosc":"inexperience","lemma":"inexpérience","pos":"NOM"} ,
		{"word":"inexpériences","word_nosc":"inexperiences","lemma":"inexpérience","pos":"NOM"} ,
		{"word":"infaillibilité","word_nosc":"infaillibilite","lemma":"infaillibilité","pos":"NOM"} ,
		{"word":"infamie","word_nosc":"infamie","lemma":"infamie","pos":"NOM"} ,
		{"word":"infamies","word_nosc":"infamies","lemma":"infamie","pos":"NOM"} ,
		{"word":"infant","word_nosc":"infant","lemma":"infant","pos":"NOM"} ,
		{"word":"infante","word_nosc":"infante","lemma":"infant","pos":"NOM"} ,
		{"word":"infanterie","word_nosc":"infanterie","lemma":"infanterie","pos":"NOM"} ,
		{"word":"infanteries","word_nosc":"infanteries","lemma":"infanterie","pos":"NOM"} ,
		{"word":"infantes","word_nosc":"infantes","lemma":"infant","pos":"NOM"} ,
		{"word":"infanticide","word_nosc":"infanticide","lemma":"infanticide","pos":"NOM"} ,
		{"word":"infanticides","word_nosc":"infanticides","lemma":"infanticide","pos":"NOM"} ,
		{"word":"infantilisme","word_nosc":"infantilisme","lemma":"infantilisme","pos":"NOM"} ,
		{"word":"infantilismes","word_nosc":"infantilismes","lemma":"infantilisme","pos":"NOM"} ,
		{"word":"infants","word_nosc":"infants","lemma":"infant","pos":"NOM"} ,
		{"word":"infarctus","word_nosc":"infarctus","lemma":"infarctus","pos":"NOM"} ,
		{"word":"infatuation","word_nosc":"infatuation","lemma":"infatuation","pos":"NOM"} ,
		{"word":"infectiologie","word_nosc":"infectiologie","lemma":"infectiologie","pos":"NOM"} ,
		{"word":"infection","word_nosc":"infection","lemma":"infection","pos":"NOM"} ,
		{"word":"infections","word_nosc":"infections","lemma":"infection","pos":"NOM"} ,
		{"word":"infertilité","word_nosc":"infertilite","lemma":"infertilité","pos":"NOM"} ,
		{"word":"infestation","word_nosc":"infestation","lemma":"infestation","pos":"NOM"} ,
		{"word":"infibulation","word_nosc":"infibulation","lemma":"infibulation","pos":"NOM"} ,
		{"word":"infidèle","word_nosc":"infidele","lemma":"infidèle","pos":"NOM"} ,
		{"word":"infidèles","word_nosc":"infideles","lemma":"infidèle","pos":"NOM"} ,
		{"word":"infidélité","word_nosc":"infidelite","lemma":"infidélité","pos":"NOM"} ,
		{"word":"infidélités","word_nosc":"infidelites","lemma":"infidélité","pos":"NOM"} ,
		{"word":"infiltrat","word_nosc":"infiltrat","lemma":"infiltrat","pos":"NOM"} ,
		{"word":"infiltration","word_nosc":"infiltration","lemma":"infiltration","pos":"NOM"} ,
		{"word":"infiltrations","word_nosc":"infiltrations","lemma":"infiltration","pos":"NOM"} ,
		{"word":"infini","word_nosc":"infini","lemma":"infini","pos":"NOM"} ,
		{"word":"infinis","word_nosc":"infinis","lemma":"infini","pos":"NOM"} ,
		{"word":"infinitif","word_nosc":"infinitif","lemma":"infinitif","pos":"NOM"} ,
		{"word":"infinitifs","word_nosc":"infinitifs","lemma":"infinitif","pos":"NOM"} ,
		{"word":"infinitude","word_nosc":"infinitude","lemma":"infinitude","pos":"NOM"} ,
		{"word":"infinité","word_nosc":"infinite","lemma":"infinité","pos":"NOM"} ,
		{"word":"infinités","word_nosc":"infinites","lemma":"infinité","pos":"NOM"} ,
		{"word":"infirme","word_nosc":"infirme","lemma":"infirme","pos":"NOM"} ,
		{"word":"infirmerie","word_nosc":"infirmerie","lemma":"infirmerie","pos":"NOM"} ,
		{"word":"infirmeries","word_nosc":"infirmeries","lemma":"infirmerie","pos":"NOM"} ,
		{"word":"infirmes","word_nosc":"infirmes","lemma":"infirme","pos":"NOM"} ,
		{"word":"infirmier","word_nosc":"infirmier","lemma":"infirmier","pos":"NOM"} ,
		{"word":"infirmiers","word_nosc":"infirmiers","lemma":"infirmier","pos":"NOM"} ,
		{"word":"infirmité","word_nosc":"infirmite","lemma":"infirmité","pos":"NOM"} ,
		{"word":"infirmités","word_nosc":"infirmites","lemma":"infirmité","pos":"NOM"} ,
		{"word":"infirmière","word_nosc":"infirmiere","lemma":"infirmier","pos":"NOM"} ,
		{"word":"infirmière-major","word_nosc":"infirmiere-major","lemma":"infirmière-major","pos":"NOM"} ,
		{"word":"infirmières","word_nosc":"infirmieres","lemma":"infirmier","pos":"NOM"} ,
		{"word":"inflammabilité","word_nosc":"inflammabilite","lemma":"inflammabilité","pos":"NOM"} ,
		{"word":"inflammation","word_nosc":"inflammation","lemma":"inflammation","pos":"NOM"} ,
		{"word":"inflation","word_nosc":"inflation","lemma":"inflation","pos":"NOM"} ,
		{"word":"inflexibilité","word_nosc":"inflexibilite","lemma":"inflexibilité","pos":"NOM"} ,
		{"word":"inflexion","word_nosc":"inflexion","lemma":"inflexion","pos":"NOM"} ,
		{"word":"inflexions","word_nosc":"inflexions","lemma":"inflexion","pos":"NOM"} ,
		{"word":"infliction","word_nosc":"infliction","lemma":"infliction","pos":"NOM"} ,
		{"word":"inflorescence","word_nosc":"inflorescence","lemma":"inflorescence","pos":"NOM"} ,
		{"word":"inflorescences","word_nosc":"inflorescences","lemma":"inflorescence","pos":"NOM"} ,
		{"word":"influence","word_nosc":"influence","lemma":"influence","pos":"NOM"} ,
		{"word":"influences","word_nosc":"influences","lemma":"influence","pos":"NOM"} ,
		{"word":"influenza","word_nosc":"influenza","lemma":"influenza","pos":"NOM"} ,
		{"word":"influx","word_nosc":"influx","lemma":"influx","pos":"NOM"} ,
		{"word":"infléchissement","word_nosc":"inflechissement","lemma":"infléchissement","pos":"NOM"} ,
		{"word":"info","word_nosc":"info","lemma":"info","pos":"NOM"} ,
		{"word":"infographie","word_nosc":"infographie","lemma":"infographie","pos":"NOM"} ,
		{"word":"informateur","word_nosc":"informateur","lemma":"informateur","pos":"NOM"} ,
		{"word":"informateurs","word_nosc":"informateurs","lemma":"informateur","pos":"NOM"} ,
		{"word":"informaticien","word_nosc":"informaticien","lemma":"informaticien","pos":"NOM"} ,
		{"word":"informaticienne","word_nosc":"informaticienne","lemma":"informaticien","pos":"NOM"} ,
		{"word":"informaticiens","word_nosc":"informaticiens","lemma":"informaticien","pos":"NOM"} ,
		{"word":"information","word_nosc":"information","lemma":"information","pos":"NOM"} ,
		{"word":"informations","word_nosc":"informations","lemma":"information","pos":"NOM"} ,
		{"word":"informatique","word_nosc":"informatique","lemma":"informatique","pos":"NOM"} ,
		{"word":"informatrice","word_nosc":"informatrice","lemma":"informateur","pos":"NOM"} ,
		{"word":"informé","word_nosc":"informe","lemma":"informé","pos":"NOM"} ,
		{"word":"informés","word_nosc":"informes","lemma":"informé","pos":"NOM"} ,
		{"word":"infortune","word_nosc":"infortune","lemma":"infortune","pos":"NOM"} ,
		{"word":"infortunes","word_nosc":"infortunes","lemma":"infortune","pos":"NOM"} ,
		{"word":"infos","word_nosc":"infos","lemma":"info","pos":"NOM"} ,
		{"word":"infraction","word_nosc":"infraction","lemma":"infraction","pos":"NOM"} ,
		{"word":"infractions","word_nosc":"infractions","lemma":"infraction","pos":"NOM"} ,
		{"word":"infrarouge","word_nosc":"infrarouge","lemma":"infrarouge","pos":"NOM"} ,
		{"word":"infrarouges","word_nosc":"infrarouges","lemma":"infrarouge","pos":"NOM"} ,
		{"word":"infrason","word_nosc":"infrason","lemma":"infrason","pos":"NOM"} ,
		{"word":"infrastructure","word_nosc":"infrastructure","lemma":"infrastructure","pos":"NOM"} ,
		{"word":"infrastructures","word_nosc":"infrastructures","lemma":"infrastructure","pos":"NOM"} ,
		{"word":"infusion","word_nosc":"infusion","lemma":"infusion","pos":"NOM"} ,
		{"word":"infusions","word_nosc":"infusions","lemma":"infusion","pos":"NOM"} ,
		{"word":"infusoires","word_nosc":"infusoires","lemma":"infusoire","pos":"NOM"} ,
		{"word":"infécondité","word_nosc":"infecondite","lemma":"infécondité","pos":"NOM"} ,
		{"word":"inférieur","word_nosc":"inferieur","lemma":"inférieur","pos":"NOM"} ,
		{"word":"inférieure","word_nosc":"inferieure","lemma":"inférieur","pos":"NOM"} ,
		{"word":"inférieures","word_nosc":"inferieures","lemma":"inférieur","pos":"NOM"} ,
		{"word":"inférieurs","word_nosc":"inferieurs","lemma":"inférieur","pos":"NOM"} ,
		{"word":"infériorité","word_nosc":"inferiorite","lemma":"infériorité","pos":"NOM"} ,
		{"word":"infériorités","word_nosc":"inferiorites","lemma":"infériorité","pos":"NOM"} ,
		{"word":"ingestion","word_nosc":"ingestion","lemma":"ingestion","pos":"NOM"} ,
		{"word":"ingrat","word_nosc":"ingrat","lemma":"ingrat","pos":"NOM"} ,
		{"word":"ingrate","word_nosc":"ingrate","lemma":"ingrat","pos":"NOM"} ,
		{"word":"ingrates","word_nosc":"ingrates","lemma":"ingrat","pos":"NOM"} ,
		{"word":"ingratitude","word_nosc":"ingratitude","lemma":"ingratitude","pos":"NOM"} ,
		{"word":"ingrats","word_nosc":"ingrats","lemma":"ingrat","pos":"NOM"} ,
		{"word":"ingrédient","word_nosc":"ingredient","lemma":"ingrédient","pos":"NOM"} ,
		{"word":"ingrédients","word_nosc":"ingredients","lemma":"ingrédient","pos":"NOM"} ,
		{"word":"ingurgitation","word_nosc":"ingurgitation","lemma":"ingurgitation","pos":"NOM"} ,
		{"word":"ingénierie","word_nosc":"ingenierie","lemma":"ingénierie","pos":"NOM"} ,
		{"word":"ingénieur","word_nosc":"ingenieur","lemma":"ingénieur","pos":"NOM"} ,
		{"word":"ingénieur-chimiste","word_nosc":"ingenieur-chimiste","lemma":"ingénieur-chimiste","pos":"NOM"} ,
		{"word":"ingénieur-conseil","word_nosc":"ingenieur-conseil","lemma":"ingénieur-conseil","pos":"NOM"} ,
		{"word":"ingénieurs","word_nosc":"ingenieurs","lemma":"ingénieur","pos":"NOM"} ,
		{"word":"ingéniosité","word_nosc":"ingeniosite","lemma":"ingéniosité","pos":"NOM"} ,
		{"word":"ingéniosités","word_nosc":"ingeniosites","lemma":"ingéniosité","pos":"NOM"} ,
		{"word":"ingénu","word_nosc":"ingenu","lemma":"ingénu","pos":"NOM"} ,
		{"word":"ingénue","word_nosc":"ingenue","lemma":"ingénu","pos":"NOM"} ,
		{"word":"ingénues","word_nosc":"ingenues","lemma":"ingénu","pos":"NOM"} ,
		{"word":"ingénuité","word_nosc":"ingenuite","lemma":"ingénuité","pos":"NOM"} ,
		{"word":"ingénuités","word_nosc":"ingenuites","lemma":"ingénuité","pos":"NOM"} ,
		{"word":"ingénus","word_nosc":"ingenus","lemma":"ingénu","pos":"NOM"} ,
		{"word":"ingérence","word_nosc":"ingerence","lemma":"ingérence","pos":"NOM"} ,
		{"word":"ingérences","word_nosc":"ingerences","lemma":"ingérence","pos":"NOM"} ,
		{"word":"inhalateur","word_nosc":"inhalateur","lemma":"inhalateur","pos":"NOM"} ,
		{"word":"inhalateurs","word_nosc":"inhalateurs","lemma":"inhalateur","pos":"NOM"} ,
		{"word":"inhalation","word_nosc":"inhalation","lemma":"inhalation","pos":"NOM"} ,
		{"word":"inhalations","word_nosc":"inhalations","lemma":"inhalation","pos":"NOM"} ,
		{"word":"inhibiteur","word_nosc":"inhibiteur","lemma":"inhibiteur","pos":"NOM"} ,
		{"word":"inhibiteurs","word_nosc":"inhibiteurs","lemma":"inhibiteur","pos":"NOM"} ,
		{"word":"inhibition","word_nosc":"inhibition","lemma":"inhibition","pos":"NOM"} ,
		{"word":"inhibitions","word_nosc":"inhibitions","lemma":"inhibition","pos":"NOM"} ,
		{"word":"inhospitalité","word_nosc":"inhospitalite","lemma":"inhospitalité","pos":"NOM"} ,
		{"word":"inhumanité","word_nosc":"inhumanite","lemma":"inhumanité","pos":"NOM"} ,
		{"word":"inhumation","word_nosc":"inhumation","lemma":"inhumation","pos":"NOM"} ,
		{"word":"inhumations","word_nosc":"inhumations","lemma":"inhumation","pos":"NOM"} ,
		{"word":"inimitié","word_nosc":"inimitie","lemma":"inimitié","pos":"NOM"} ,
		{"word":"inimitiés","word_nosc":"inimities","lemma":"inimitié","pos":"NOM"} ,
		{"word":"inintelligence","word_nosc":"inintelligence","lemma":"inintelligence","pos":"NOM"} ,
		{"word":"inintérêt","word_nosc":"ininteret","lemma":"inintérêt","pos":"NOM"} ,
		{"word":"iniquité","word_nosc":"iniquite","lemma":"iniquité","pos":"NOM"} ,
		{"word":"iniquités","word_nosc":"iniquites","lemma":"iniquité","pos":"NOM"} ,
		{"word":"initiale","word_nosc":"initiale","lemma":"initiale","pos":"NOM"} ,
		{"word":"initiales","word_nosc":"initiales","lemma":"initiale","pos":"NOM"} ,
		{"word":"initialisation","word_nosc":"initialisation","lemma":"initialisation","pos":"NOM"} ,
		{"word":"initiateur","word_nosc":"initiateur","lemma":"initiateur","pos":"NOM"} ,
		{"word":"initiateurs","word_nosc":"initiateurs","lemma":"initiateur","pos":"NOM"} ,
		{"word":"initiation","word_nosc":"initiation","lemma":"initiation","pos":"NOM"} ,
		{"word":"initiations","word_nosc":"initiations","lemma":"initiation","pos":"NOM"} ,
		{"word":"initiative","word_nosc":"initiative","lemma":"initiative","pos":"NOM"} ,
		{"word":"initiatives","word_nosc":"initiatives","lemma":"initiative","pos":"NOM"} ,
		{"word":"initiatrice","word_nosc":"initiatrice","lemma":"initiateur","pos":"NOM"} ,
		{"word":"initiatrices","word_nosc":"initiatrices","lemma":"initiateur","pos":"NOM"} ,
		{"word":"initié","word_nosc":"initie","lemma":"initié","pos":"NOM"} ,
		{"word":"initiée","word_nosc":"initiee","lemma":"initié","pos":"NOM"} ,
		{"word":"initiées","word_nosc":"initiees","lemma":"initié","pos":"NOM"} ,
		{"word":"initiés","word_nosc":"inities","lemma":"initié","pos":"NOM"} ,
		{"word":"injecteur","word_nosc":"injecteur","lemma":"injecteur","pos":"NOM"} ,
		{"word":"injecteurs","word_nosc":"injecteurs","lemma":"injecteur","pos":"NOM"} ,
		{"word":"injection","word_nosc":"injection","lemma":"injection","pos":"NOM"} ,
		{"word":"injections","word_nosc":"injections","lemma":"injection","pos":"NOM"} ,
		{"word":"injonction","word_nosc":"injonction","lemma":"injonction","pos":"NOM"} ,
		{"word":"injonctions","word_nosc":"injonctions","lemma":"injonction","pos":"NOM"} ,
		{"word":"injure","word_nosc":"injure","lemma":"injure","pos":"NOM"} ,
		{"word":"injures","word_nosc":"injures","lemma":"injure","pos":"NOM"} ,
		{"word":"injustice","word_nosc":"injustice","lemma":"injustice","pos":"NOM"} ,
		{"word":"injustices","word_nosc":"injustices","lemma":"injustice","pos":"NOM"} ,
		{"word":"innervation","word_nosc":"innervation","lemma":"innervation","pos":"NOM"} ,
		{"word":"innocence","word_nosc":"innocence","lemma":"innocence","pos":"NOM"} ,
		{"word":"innocences","word_nosc":"innocences","lemma":"innocence","pos":"NOM"} ,
		{"word":"innocent","word_nosc":"innocent","lemma":"innocent","pos":"NOM"} ,
		{"word":"innocente","word_nosc":"innocente","lemma":"innocent","pos":"NOM"} ,
		{"word":"innocentes","word_nosc":"innocentes","lemma":"innocent","pos":"NOM"} ,
		{"word":"innocents","word_nosc":"innocents","lemma":"innocent","pos":"NOM"} ,
		{"word":"innocuité","word_nosc":"innocuite","lemma":"innocuité","pos":"NOM"} ,
		{"word":"innovateur","word_nosc":"innovateur","lemma":"innovateur","pos":"NOM"} ,
		{"word":"innovation","word_nosc":"innovation","lemma":"innovation","pos":"NOM"} ,
		{"word":"innovations","word_nosc":"innovations","lemma":"innovation","pos":"NOM"} ,
		{"word":"inoccupations","word_nosc":"inoccupations","lemma":"inoccupation","pos":"NOM"} ,
		{"word":"inoculation","word_nosc":"inoculation","lemma":"inoculation","pos":"NOM"} ,
		{"word":"inondation","word_nosc":"inondation","lemma":"inondation","pos":"NOM"} ,
		{"word":"inondations","word_nosc":"inondations","lemma":"inondation","pos":"NOM"} ,
		{"word":"inondé","word_nosc":"inonde","lemma":"inondé","pos":"NOM"} ,
		{"word":"inondée","word_nosc":"inondee","lemma":"inondé","pos":"NOM"} ,
		{"word":"inopportunité","word_nosc":"inopportunite","lemma":"inopportunité","pos":"NOM"} ,
		{"word":"inox","word_nosc":"inox","lemma":"inox","pos":"NOM"} ,
		{"word":"inoxydable","word_nosc":"inoxydable","lemma":"inoxydable","pos":"NOM"} ,
		{"word":"input","word_nosc":"input","lemma":"input","pos":"NOM"} ,
		{"word":"inquiet","word_nosc":"inquiet","lemma":"inquiet","pos":"NOM"} ,
		{"word":"inquiets","word_nosc":"inquiets","lemma":"inquiet","pos":"NOM"} ,
		{"word":"inquilisme","word_nosc":"inquilisme","lemma":"inquilisme","pos":"NOM"} ,
		{"word":"inquisiteur","word_nosc":"inquisiteur","lemma":"inquisiteur","pos":"NOM"} ,
		{"word":"inquisiteurs","word_nosc":"inquisiteurs","lemma":"inquisiteur","pos":"NOM"} ,
		{"word":"inquisition","word_nosc":"inquisition","lemma":"inquisition","pos":"NOM"} ,
		{"word":"inquisitions","word_nosc":"inquisitions","lemma":"inquisition","pos":"NOM"} ,
		{"word":"inquiéteur","word_nosc":"inquieteur","lemma":"inquiéteur","pos":"NOM"} ,
		{"word":"inquiétude","word_nosc":"inquietude","lemma":"inquiétude","pos":"NOM"} ,
		{"word":"inquiétudes","word_nosc":"inquietudes","lemma":"inquiétude","pos":"NOM"} ,
		{"word":"insalubrité","word_nosc":"insalubrite","lemma":"insalubrité","pos":"NOM"} ,
		{"word":"insanité","word_nosc":"insanite","lemma":"insanité","pos":"NOM"} ,
		{"word":"insanités","word_nosc":"insanites","lemma":"insanité","pos":"NOM"} ,
		{"word":"insatisfaction","word_nosc":"insatisfaction","lemma":"insatisfaction","pos":"NOM"} ,
		{"word":"insatisfactions","word_nosc":"insatisfactions","lemma":"insatisfaction","pos":"NOM"} ,
		{"word":"inscription","word_nosc":"inscription","lemma":"inscription","pos":"NOM"} ,
		{"word":"inscriptions","word_nosc":"inscriptions","lemma":"inscription","pos":"NOM"} ,
		{"word":"inscrit","word_nosc":"inscrit","lemma":"inscrit","pos":"NOM"} ,
		{"word":"inscrite","word_nosc":"inscrite","lemma":"inscrit","pos":"NOM"} ,
		{"word":"inscrites","word_nosc":"inscrites","lemma":"inscrit","pos":"NOM"} ,
		{"word":"inscrits","word_nosc":"inscrits","lemma":"inscrit","pos":"NOM"} ,
		{"word":"inscrivant","word_nosc":"inscrivant","lemma":"inscrivant","pos":"NOM"} ,
		{"word":"insecte","word_nosc":"insecte","lemma":"insecte","pos":"NOM"} ,
		{"word":"insectes","word_nosc":"insectes","lemma":"insecte","pos":"NOM"} ,
		{"word":"insecticide","word_nosc":"insecticide","lemma":"insecticide","pos":"NOM"} ,
		{"word":"insecticides","word_nosc":"insecticides","lemma":"insecticide","pos":"NOM"} ,
		{"word":"insensibilité","word_nosc":"insensibilite","lemma":"insensibilité","pos":"NOM"} ,
		{"word":"insensé","word_nosc":"insense","lemma":"insensé","pos":"NOM"} ,
		{"word":"insensée","word_nosc":"insensee","lemma":"insensé","pos":"NOM"} ,
		{"word":"insensées","word_nosc":"insensees","lemma":"insensé","pos":"NOM"} ,
		{"word":"insensés","word_nosc":"insenses","lemma":"insensé","pos":"NOM"} ,
		{"word":"insert","word_nosc":"insert","lemma":"insert","pos":"NOM"} ,
		{"word":"insertion","word_nosc":"insertion","lemma":"insertion","pos":"NOM"} ,
		{"word":"inserts","word_nosc":"inserts","lemma":"insert","pos":"NOM"} ,
		{"word":"insight","word_nosc":"insight","lemma":"insight","pos":"NOM"} ,
		{"word":"insights","word_nosc":"insights","lemma":"insight","pos":"NOM"} ,
		{"word":"insigne","word_nosc":"insigne","lemma":"insigne","pos":"NOM"} ,
		{"word":"insignes","word_nosc":"insignes","lemma":"insigne","pos":"NOM"} ,
		{"word":"insignifiance","word_nosc":"insignifiance","lemma":"insignifiance","pos":"NOM"} ,
		{"word":"insignifiances","word_nosc":"insignifiances","lemma":"insignifiance","pos":"NOM"} ,
		{"word":"insincérité","word_nosc":"insincerite","lemma":"insincérité","pos":"NOM"} ,
		{"word":"insinuation","word_nosc":"insinuation","lemma":"insinuation","pos":"NOM"} ,
		{"word":"insinuations","word_nosc":"insinuations","lemma":"insinuation","pos":"NOM"} ,
		{"word":"insipidité","word_nosc":"insipidite","lemma":"insipidité","pos":"NOM"} ,
		{"word":"insistance","word_nosc":"insistance","lemma":"insistance","pos":"NOM"} ,
		{"word":"insistances","word_nosc":"insistances","lemma":"insistance","pos":"NOM"} ,
		{"word":"insolation","word_nosc":"insolation","lemma":"insolation","pos":"NOM"} ,
		{"word":"insolations","word_nosc":"insolations","lemma":"insolation","pos":"NOM"} ,
		{"word":"insolence","word_nosc":"insolence","lemma":"insolence","pos":"NOM"} ,
		{"word":"insolences","word_nosc":"insolences","lemma":"insolence","pos":"NOM"} ,
		{"word":"insolent","word_nosc":"insolent","lemma":"insolent","pos":"NOM"} ,
		{"word":"insolents","word_nosc":"insolents","lemma":"insolent","pos":"NOM"} ,
		{"word":"insomniaque","word_nosc":"insomniaque","lemma":"insomniaque","pos":"NOM"} ,
		{"word":"insomniaques","word_nosc":"insomniaques","lemma":"insomniaque","pos":"NOM"} ,
		{"word":"insomnie","word_nosc":"insomnie","lemma":"insomnie","pos":"NOM"} ,
		{"word":"insomnies","word_nosc":"insomnies","lemma":"insomnie","pos":"NOM"} ,
		{"word":"insomnieuse","word_nosc":"insomnieuse","lemma":"insomnieux","pos":"NOM"} ,
		{"word":"insomnieux","word_nosc":"insomnieux","lemma":"insomnieux","pos":"NOM"} ,
		{"word":"insonorisation","word_nosc":"insonorisation","lemma":"insonorisation","pos":"NOM"} ,
		{"word":"insonorité","word_nosc":"insonorite","lemma":"insonorité","pos":"NOM"} ,
		{"word":"insouciance","word_nosc":"insouciance","lemma":"insouciance","pos":"NOM"} ,
		{"word":"insouciant","word_nosc":"insouciant","lemma":"insouciant","pos":"NOM"} ,
		{"word":"insouciants","word_nosc":"insouciants","lemma":"insouciant","pos":"NOM"} ,
		{"word":"insoumis","word_nosc":"insoumis","lemma":"insoumis","pos":"NOM"} ,
		{"word":"insoumise","word_nosc":"insoumise","lemma":"insoumis","pos":"NOM"} ,
		{"word":"insoumission","word_nosc":"insoumission","lemma":"insoumission","pos":"NOM"} ,
		{"word":"inspecteur","word_nosc":"inspecteur","lemma":"inspecteur","pos":"NOM"} ,
		{"word":"inspecteurs","word_nosc":"inspecteurs","lemma":"inspecteur","pos":"NOM"} ,
		{"word":"inspection","word_nosc":"inspection","lemma":"inspection","pos":"NOM"} ,
		{"word":"inspections","word_nosc":"inspections","lemma":"inspection","pos":"NOM"} ,
		{"word":"inspectrice","word_nosc":"inspectrice","lemma":"inspecteur","pos":"NOM"} ,
		{"word":"inspirateur","word_nosc":"inspirateur","lemma":"inspirateur","pos":"NOM"} ,
		{"word":"inspirateurs","word_nosc":"inspirateurs","lemma":"inspirateur","pos":"NOM"} ,
		{"word":"inspiration","word_nosc":"inspiration","lemma":"inspiration","pos":"NOM"} ,
		{"word":"inspirations","word_nosc":"inspirations","lemma":"inspiration","pos":"NOM"} ,
		{"word":"inspiratrice","word_nosc":"inspiratrice","lemma":"inspirateur","pos":"NOM"} ,
		{"word":"inspiratrices","word_nosc":"inspiratrices","lemma":"inspirateur","pos":"NOM"} ,
		{"word":"inspiré","word_nosc":"inspire","lemma":"inspiré","pos":"NOM"} ,
		{"word":"inspirée","word_nosc":"inspiree","lemma":"inspiré","pos":"NOM"} ,
		{"word":"inspirées","word_nosc":"inspirees","lemma":"inspiré","pos":"NOM"} ,
		{"word":"inspirés","word_nosc":"inspires","lemma":"inspiré","pos":"NOM"} ,
		{"word":"instabilité","word_nosc":"instabilite","lemma":"instabilité","pos":"NOM"} ,
		{"word":"instable","word_nosc":"instable","lemma":"instable","pos":"NOM"} ,
		{"word":"instables","word_nosc":"instables","lemma":"instable","pos":"NOM"} ,
		{"word":"installateur","word_nosc":"installateur","lemma":"installateur","pos":"NOM"} ,
		{"word":"installation","word_nosc":"installation","lemma":"installation","pos":"NOM"} ,
		{"word":"installations","word_nosc":"installations","lemma":"installation","pos":"NOM"} ,
		{"word":"installeur","word_nosc":"installeur","lemma":"installeur","pos":"NOM"} ,
		{"word":"instance","word_nosc":"instance","lemma":"instance","pos":"NOM"} ,
		{"word":"instances","word_nosc":"instances","lemma":"instance","pos":"NOM"} ,
		{"word":"instant","word_nosc":"instant","lemma":"instant","pos":"NOM"} ,
		{"word":"instantané","word_nosc":"instantane","lemma":"instantané","pos":"NOM"} ,
		{"word":"instantanéité","word_nosc":"instantaneite","lemma":"instantanéité","pos":"NOM"} ,
		{"word":"instantanés","word_nosc":"instantanes","lemma":"instantané","pos":"NOM"} ,
		{"word":"instants","word_nosc":"instants","lemma":"instant","pos":"NOM"} ,
		{"word":"instauration","word_nosc":"instauration","lemma":"instauration","pos":"NOM"} ,
		{"word":"instauratrice","word_nosc":"instauratrice","lemma":"instaurateur","pos":"NOM"} ,
		{"word":"instigateur","word_nosc":"instigateur","lemma":"instigateur","pos":"NOM"} ,
		{"word":"instigateurs","word_nosc":"instigateurs","lemma":"instigateur","pos":"NOM"} ,
		{"word":"instigation","word_nosc":"instigation","lemma":"instigation","pos":"NOM"} ,
		{"word":"instigatrice","word_nosc":"instigatrice","lemma":"instigateur","pos":"NOM"} ,
		{"word":"instinct","word_nosc":"instinct","lemma":"instinct","pos":"NOM"} ,
		{"word":"instincts","word_nosc":"instincts","lemma":"instinct","pos":"NOM"} ,
		{"word":"instit","word_nosc":"instit","lemma":"instit","pos":"NOM"} ,
		{"word":"instits","word_nosc":"instits","lemma":"instit","pos":"NOM"} ,
		{"word":"institut","word_nosc":"institut","lemma":"institut","pos":"NOM"} ,
		{"word":"instituteur","word_nosc":"instituteur","lemma":"instituteur","pos":"NOM"} ,
		{"word":"instituteurs","word_nosc":"instituteurs","lemma":"instituteur","pos":"NOM"} ,
		{"word":"institution","word_nosc":"institution","lemma":"institution","pos":"NOM"} ,
		{"word":"institutions","word_nosc":"institutions","lemma":"institution","pos":"NOM"} ,
		{"word":"institutrice","word_nosc":"institutrice","lemma":"instituteur","pos":"NOM"} ,
		{"word":"institutrices","word_nosc":"institutrices","lemma":"instituteur","pos":"NOM"} ,
		{"word":"instituts","word_nosc":"instituts","lemma":"institut","pos":"NOM"} ,
		{"word":"instructeur","word_nosc":"instructeur","lemma":"instructeur","pos":"NOM"} ,
		{"word":"instructeurs","word_nosc":"instructeurs","lemma":"instructeur","pos":"NOM"} ,
		{"word":"instruction","word_nosc":"instruction","lemma":"instruction","pos":"NOM"} ,
		{"word":"instructions","word_nosc":"instructions","lemma":"instruction","pos":"NOM"} ,
		{"word":"instrument","word_nosc":"instrument","lemma":"instrument","pos":"NOM"} ,
		{"word":"instrumental","word_nosc":"instrumental","lemma":"instrumental","pos":"NOM"} ,
		{"word":"instrumentation","word_nosc":"instrumentation","lemma":"instrumentation","pos":"NOM"} ,
		{"word":"instrumentiste","word_nosc":"instrumentiste","lemma":"instrumentiste","pos":"NOM"} ,
		{"word":"instrumentistes","word_nosc":"instrumentistes","lemma":"instrumentiste","pos":"NOM"} ,
		{"word":"instruments","word_nosc":"instruments","lemma":"instrument","pos":"NOM"} ,
		{"word":"insu","word_nosc":"insu","lemma":"insu","pos":"NOM"} ,
		{"word":"insubordination","word_nosc":"insubordination","lemma":"insubordination","pos":"NOM"} ,
		{"word":"insubordinations","word_nosc":"insubordinations","lemma":"insubordination","pos":"NOM"} ,
		{"word":"insuccès","word_nosc":"insucces","lemma":"insuccès","pos":"NOM"} ,
		{"word":"insuffisance","word_nosc":"insuffisance","lemma":"insuffisance","pos":"NOM"} ,
		{"word":"insuffisances","word_nosc":"insuffisances","lemma":"insuffisance","pos":"NOM"} ,
		{"word":"insufflateur","word_nosc":"insufflateur","lemma":"insufflateur","pos":"NOM"} ,
		{"word":"insula","word_nosc":"insula","lemma":"insula","pos":"NOM"} ,
		{"word":"insulaire","word_nosc":"insulaire","lemma":"insulaire","pos":"NOM"} ,
		{"word":"insulaires","word_nosc":"insulaires","lemma":"insulaire","pos":"NOM"} ,
		{"word":"insularité","word_nosc":"insularite","lemma":"insularité","pos":"NOM"} ,
		{"word":"insulation","word_nosc":"insulation","lemma":"insulation","pos":"NOM"} ,
		{"word":"insuline","word_nosc":"insuline","lemma":"insuline","pos":"NOM"} ,
		{"word":"insulte","word_nosc":"insulte","lemma":"insulte","pos":"NOM"} ,
		{"word":"insultes","word_nosc":"insultes","lemma":"insulte","pos":"NOM"} ,
		{"word":"insulteur","word_nosc":"insulteur","lemma":"insulteur","pos":"NOM"} ,
		{"word":"insulteurs","word_nosc":"insulteurs","lemma":"insulteur","pos":"NOM"} ,
		{"word":"insulté","word_nosc":"insulte","lemma":"insulté","pos":"NOM"} ,
		{"word":"insultée","word_nosc":"insultee","lemma":"insulté","pos":"NOM"} ,
		{"word":"insultés","word_nosc":"insultes","lemma":"insulté","pos":"NOM"} ,
		{"word":"insurgé","word_nosc":"insurge","lemma":"insurgé","pos":"NOM"} ,
		{"word":"insurgée","word_nosc":"insurgee","lemma":"insurgé","pos":"NOM"} ,
		{"word":"insurgés","word_nosc":"insurges","lemma":"insurgé","pos":"NOM"} ,
		{"word":"insurrection","word_nosc":"insurrection","lemma":"insurrection","pos":"NOM"} ,
		{"word":"insurrections","word_nosc":"insurrections","lemma":"insurrection","pos":"NOM"} ,
		{"word":"insécurité","word_nosc":"insecurite","lemma":"insécurité","pos":"NOM"} ,
		{"word":"insécurités","word_nosc":"insecurites","lemma":"insécurité","pos":"NOM"} ,
		{"word":"inséminateur","word_nosc":"inseminateur","lemma":"inséminateur","pos":"NOM"} ,
		{"word":"insémination","word_nosc":"insemination","lemma":"insémination","pos":"NOM"} ,
		{"word":"inséminations","word_nosc":"inseminations","lemma":"insémination","pos":"NOM"} ,
		{"word":"inséparabilité","word_nosc":"inseparabilite","lemma":"inséparabilité","pos":"NOM"} ,
		{"word":"inséparable","word_nosc":"inseparable","lemma":"inséparable","pos":"NOM"} ,
		{"word":"inséparables","word_nosc":"inseparables","lemma":"inséparable","pos":"NOM"} ,
		{"word":"intaille","word_nosc":"intaille","lemma":"intaille","pos":"NOM"} ,
		{"word":"intailles","word_nosc":"intailles","lemma":"intaille","pos":"NOM"} ,
		{"word":"intangibilité","word_nosc":"intangibilite","lemma":"intangibilité","pos":"NOM"} ,
		{"word":"intellect","word_nosc":"intellect","lemma":"intellect","pos":"NOM"} ,
		{"word":"intellectualisation","word_nosc":"intellectualisation","lemma":"intellectualisation","pos":"NOM"} ,
		{"word":"intellectualisme","word_nosc":"intellectualisme","lemma":"intellectualisme","pos":"NOM"} ,
		{"word":"intellectualiste","word_nosc":"intellectualiste","lemma":"intellectualiste","pos":"NOM"} ,
		{"word":"intellectuel","word_nosc":"intellectuel","lemma":"intellectuel","pos":"NOM"} ,
		{"word":"intellectuel-phare","word_nosc":"intellectuel-phare","lemma":"intellectuel-phare","pos":"NOM"} ,
		{"word":"intellectuelle","word_nosc":"intellectuelle","lemma":"intellectuel","pos":"NOM"} ,
		{"word":"intellectuelles","word_nosc":"intellectuelles","lemma":"intellectuel","pos":"NOM"} ,
		{"word":"intellectuels","word_nosc":"intellectuels","lemma":"intellectuel","pos":"NOM"} ,
		{"word":"intelligence","word_nosc":"intelligence","lemma":"intelligence","pos":"NOM"} ,
		{"word":"intelligences","word_nosc":"intelligences","lemma":"intelligence","pos":"NOM"} ,
		{"word":"intelligentsia","word_nosc":"intelligentsia","lemma":"intelligentsia","pos":"NOM"} ,
		{"word":"intelligibilité","word_nosc":"intelligibilite","lemma":"intelligibilité","pos":"NOM"} ,
		{"word":"intello","word_nosc":"intello","lemma":"intello","pos":"NOM"} ,
		{"word":"intellos","word_nosc":"intellos","lemma":"intello","pos":"NOM"} ,
		{"word":"intemporalité","word_nosc":"intemporalite","lemma":"intemporalité","pos":"NOM"} ,
		{"word":"intempérance","word_nosc":"intemperance","lemma":"intempérance","pos":"NOM"} ,
		{"word":"intempérances","word_nosc":"intemperances","lemma":"intempérance","pos":"NOM"} ,
		{"word":"intempéries","word_nosc":"intemperies","lemma":"intempérie","pos":"NOM"} ,
		{"word":"intendance","word_nosc":"intendance","lemma":"intendance","pos":"NOM"} ,
		{"word":"intendances","word_nosc":"intendances","lemma":"intendance","pos":"NOM"} ,
		{"word":"intendant","word_nosc":"intendant","lemma":"intendant","pos":"NOM"} ,
		{"word":"intendante","word_nosc":"intendante","lemma":"intendant","pos":"NOM"} ,
		{"word":"intendants","word_nosc":"intendants","lemma":"intendant","pos":"NOM"} ,
		{"word":"intensification","word_nosc":"intensification","lemma":"intensification","pos":"NOM"} ,
		{"word":"intension","word_nosc":"intension","lemma":"intension","pos":"NOM"} ,
		{"word":"intensité","word_nosc":"intensite","lemma":"intensité","pos":"NOM"} ,
		{"word":"intensités","word_nosc":"intensites","lemma":"intensité","pos":"NOM"} ,
		{"word":"intention","word_nosc":"intention","lemma":"intention","pos":"NOM"} ,
		{"word":"intentionnalité","word_nosc":"intentionnalite","lemma":"intentionnalité","pos":"NOM"} ,
		{"word":"intentions","word_nosc":"intentions","lemma":"intention","pos":"NOM"} ,
		{"word":"inter","word_nosc":"inter","lemma":"inter","pos":"NOM"} ,
		{"word":"inter-écoles","word_nosc":"inter-ecoles","lemma":"inter-école","pos":"NOM"} ,
		{"word":"interaction","word_nosc":"interaction","lemma":"interaction","pos":"NOM"} ,
		{"word":"interactions","word_nosc":"interactions","lemma":"interaction","pos":"NOM"} ,
		{"word":"interactivité","word_nosc":"interactivite","lemma":"interactivité","pos":"NOM"} ,
		{"word":"intercepteur","word_nosc":"intercepteur","lemma":"intercepteur","pos":"NOM"} ,
		{"word":"intercepteurs","word_nosc":"intercepteurs","lemma":"intercepteur","pos":"NOM"} ,
		{"word":"interception","word_nosc":"interception","lemma":"interception","pos":"NOM"} ,
		{"word":"interceptions","word_nosc":"interceptions","lemma":"interception","pos":"NOM"} ,
		{"word":"intercesseur","word_nosc":"intercesseur","lemma":"intercesseur","pos":"NOM"} ,
		{"word":"intercesseurs","word_nosc":"intercesseurs","lemma":"intercesseur","pos":"NOM"} ,
		{"word":"intercession","word_nosc":"intercession","lemma":"intercession","pos":"NOM"} ,
		{"word":"interclasse","word_nosc":"interclasse","lemma":"interclasse","pos":"NOM"} ,
		{"word":"interclasses","word_nosc":"interclasses","lemma":"interclasse","pos":"NOM"} ,
		{"word":"intercommunication","word_nosc":"intercommunication","lemma":"intercommunication","pos":"NOM"} ,
		{"word":"interconnexion","word_nosc":"interconnexion","lemma":"interconnexion","pos":"NOM"} ,
		{"word":"intercours","word_nosc":"intercours","lemma":"intercours","pos":"NOM"} ,
		{"word":"intercourse","word_nosc":"intercourse","lemma":"intercourse","pos":"NOM"} ,
		{"word":"interdiction","word_nosc":"interdiction","lemma":"interdiction","pos":"NOM"} ,
		{"word":"interdictions","word_nosc":"interdictions","lemma":"interdiction","pos":"NOM"} ,
		{"word":"interdit","word_nosc":"interdit","lemma":"interdit","pos":"NOM"} ,
		{"word":"interdite","word_nosc":"interdite","lemma":"interdit","pos":"NOM"} ,
		{"word":"interdites","word_nosc":"interdites","lemma":"interdit","pos":"NOM"} ,
		{"word":"interdits","word_nosc":"interdits","lemma":"interdit","pos":"NOM"} ,
		{"word":"interdépendance","word_nosc":"interdependance","lemma":"interdépendance","pos":"NOM"} ,
		{"word":"interface","word_nosc":"interface","lemma":"interface","pos":"NOM"} ,
		{"word":"interfaces","word_nosc":"interfaces","lemma":"interface","pos":"NOM"} ,
		{"word":"interférence","word_nosc":"interference","lemma":"interférence","pos":"NOM"} ,
		{"word":"interférences","word_nosc":"interferences","lemma":"interférence","pos":"NOM"} ,
		{"word":"interférométrie","word_nosc":"interferometrie","lemma":"interférométrie","pos":"NOM"} ,
		{"word":"interféron","word_nosc":"interferon","lemma":"interféron","pos":"NOM"} ,
		{"word":"interjection","word_nosc":"interjection","lemma":"interjection","pos":"NOM"} ,
		{"word":"interjections","word_nosc":"interjections","lemma":"interjection","pos":"NOM"} ,
		{"word":"interligne","word_nosc":"interligne","lemma":"interligne","pos":"NOM"} ,
		{"word":"interlignes","word_nosc":"interlignes","lemma":"interligne","pos":"NOM"} ,
		{"word":"interlocuteur","word_nosc":"interlocuteur","lemma":"interlocuteur","pos":"NOM"} ,
		{"word":"interlocuteurs","word_nosc":"interlocuteurs","lemma":"interlocuteur","pos":"NOM"} ,
		{"word":"interlocutrice","word_nosc":"interlocutrice","lemma":"interlocuteur","pos":"NOM"} ,
		{"word":"interlocutrices","word_nosc":"interlocutrices","lemma":"interlocuteur","pos":"NOM"} ,
		{"word":"interlope","word_nosc":"interlope","lemma":"interlope","pos":"NOM"} ,
		{"word":"interlude","word_nosc":"interlude","lemma":"interlude","pos":"NOM"} ,
		{"word":"interludes","word_nosc":"interludes","lemma":"interlude","pos":"NOM"} ,
		{"word":"intermezzo","word_nosc":"intermezzo","lemma":"intermezzo","pos":"NOM"} ,
		{"word":"intermission","word_nosc":"intermission","lemma":"intermission","pos":"NOM"} ,
		{"word":"intermittence","word_nosc":"intermittence","lemma":"intermittence","pos":"NOM"} ,
		{"word":"intermittences","word_nosc":"intermittences","lemma":"intermittence","pos":"NOM"} ,
		{"word":"intermittent","word_nosc":"intermittent","lemma":"intermittent","pos":"NOM"} ,
		{"word":"intermittente","word_nosc":"intermittente","lemma":"intermittent","pos":"NOM"} ,
		{"word":"intermittents","word_nosc":"intermittents","lemma":"intermittent","pos":"NOM"} ,
		{"word":"intermède","word_nosc":"intermede","lemma":"intermède","pos":"NOM"} ,
		{"word":"intermèdes","word_nosc":"intermedes","lemma":"intermède","pos":"NOM"} ,
		{"word":"intermédiaire","word_nosc":"intermediaire","lemma":"intermédiaire","pos":"NOM"} ,
		{"word":"intermédiaires","word_nosc":"intermediaires","lemma":"intermédiaire","pos":"NOM"} ,
		{"word":"internat","word_nosc":"internat","lemma":"internat","pos":"NOM"} ,
		{"word":"international","word_nosc":"international","lemma":"international","pos":"NOM"} ,
		{"word":"internationale","word_nosc":"internationale","lemma":"international","pos":"NOM"} ,
		{"word":"internationalisation","word_nosc":"internationalisation","lemma":"internationalisation","pos":"NOM"} ,
		{"word":"internationalisme","word_nosc":"internationalisme","lemma":"internationalisme","pos":"NOM"} ,
		{"word":"internationalistes","word_nosc":"internationalistes","lemma":"internationaliste","pos":"NOM"} ,
		{"word":"internationaux","word_nosc":"internationaux","lemma":"international","pos":"NOM"} ,
		{"word":"internats","word_nosc":"internats","lemma":"internat","pos":"NOM"} ,
		{"word":"internaute","word_nosc":"internaute","lemma":"internaute","pos":"NOM"} ,
		{"word":"internautes","word_nosc":"internautes","lemma":"internaute","pos":"NOM"} ,
		{"word":"interne","word_nosc":"interne","lemma":"interne","pos":"NOM"} ,
		{"word":"internement","word_nosc":"internement","lemma":"internement","pos":"NOM"} ,
		{"word":"internes","word_nosc":"internes","lemma":"interne","pos":"NOM"} ,
		{"word":"internet","word_nosc":"internet","lemma":"internet","pos":"NOM"} ,
		{"word":"interniste","word_nosc":"interniste","lemma":"interniste","pos":"NOM"} ,
		{"word":"interné","word_nosc":"interne","lemma":"interné","pos":"NOM"} ,
		{"word":"internés","word_nosc":"internes","lemma":"interné","pos":"NOM"} ,
		{"word":"interpellateur","word_nosc":"interpellateur","lemma":"interpellateur","pos":"NOM"} ,
		{"word":"interpellateurs","word_nosc":"interpellateurs","lemma":"interpellateur","pos":"NOM"} ,
		{"word":"interpellation","word_nosc":"interpellation","lemma":"interpellation","pos":"NOM"} ,
		{"word":"interpellations","word_nosc":"interpellations","lemma":"interpellation","pos":"NOM"} ,
		{"word":"interphase","word_nosc":"interphase","lemma":"interphase","pos":"NOM"} ,
		{"word":"interphone","word_nosc":"interphone","lemma":"interphone","pos":"NOM"} ,
		{"word":"interphones","word_nosc":"interphones","lemma":"interphone","pos":"NOM"} ,
		{"word":"interpolateur","word_nosc":"interpolateur","lemma":"interpolateur","pos":"NOM"} ,
		{"word":"interpolation","word_nosc":"interpolation","lemma":"interpolation","pos":"NOM"} ,
		{"word":"interpolations","word_nosc":"interpolations","lemma":"interpolation","pos":"NOM"} ,
		{"word":"interposition","word_nosc":"interposition","lemma":"interposition","pos":"NOM"} ,
		{"word":"interprète","word_nosc":"interprete","lemma":"interprète","pos":"NOM"} ,
		{"word":"interprètes","word_nosc":"interpretes","lemma":"interprète","pos":"NOM"} ,
		{"word":"interprétation","word_nosc":"interpretation","lemma":"interprétation","pos":"NOM"} ,
		{"word":"interprétations","word_nosc":"interpretations","lemma":"interprétation","pos":"NOM"} ,
		{"word":"interpréteur","word_nosc":"interpreteur","lemma":"interpréteur","pos":"NOM"} ,
		{"word":"interpénétration","word_nosc":"interpenetration","lemma":"interpénétration","pos":"NOM"} ,
		{"word":"interpénétrations","word_nosc":"interpenetrations","lemma":"interpénétration","pos":"NOM"} ,
		{"word":"interrelations","word_nosc":"interrelations","lemma":"interrelation","pos":"NOM"} ,
		{"word":"interrogateur","word_nosc":"interrogateur","lemma":"interrogateur","pos":"NOM"} ,
		{"word":"interrogateurs","word_nosc":"interrogateurs","lemma":"interrogateur","pos":"NOM"} ,
		{"word":"interrogatif","word_nosc":"interrogatif","lemma":"interrogatif","pos":"NOM"} ,
		{"word":"interrogation","word_nosc":"interrogation","lemma":"interrogation","pos":"NOM"} ,
		{"word":"interrogations","word_nosc":"interrogations","lemma":"interrogation","pos":"NOM"} ,
		{"word":"interrogative","word_nosc":"interrogative","lemma":"interrogative","pos":"NOM"} ,
		{"word":"interrogatoire","word_nosc":"interrogatoire","lemma":"interrogatoire","pos":"NOM"} ,
		{"word":"interrogatoires","word_nosc":"interrogatoires","lemma":"interrogatoire","pos":"NOM"} ,
		{"word":"interrogatrice","word_nosc":"interrogatrice","lemma":"interrogateur","pos":"NOM"} ,
		{"word":"interrupteur","word_nosc":"interrupteur","lemma":"interrupteur","pos":"NOM"} ,
		{"word":"interrupteurs","word_nosc":"interrupteurs","lemma":"interrupteur","pos":"NOM"} ,
		{"word":"interruption","word_nosc":"interruption","lemma":"interruption","pos":"NOM"} ,
		{"word":"interruptions","word_nosc":"interruptions","lemma":"interruption","pos":"NOM"} ,
		{"word":"interrègne","word_nosc":"interregne","lemma":"interrègne","pos":"NOM"} ,
		{"word":"inters","word_nosc":"inters","lemma":"inter","pos":"NOM"} ,
		{"word":"intersaison","word_nosc":"intersaison","lemma":"intersaison","pos":"NOM"} ,
		{"word":"intersection","word_nosc":"intersection","lemma":"intersection","pos":"NOM"} ,
		{"word":"intersections","word_nosc":"intersections","lemma":"intersection","pos":"NOM"} ,
		{"word":"interstice","word_nosc":"interstice","lemma":"interstice","pos":"NOM"} ,
		{"word":"interstices","word_nosc":"interstices","lemma":"interstice","pos":"NOM"} ,
		{"word":"intertitre","word_nosc":"intertitre","lemma":"intertitre","pos":"NOM"} ,
		{"word":"intervalle","word_nosc":"intervalle","lemma":"intervalle","pos":"NOM"} ,
		{"word":"intervalles","word_nosc":"intervalles","lemma":"intervalle","pos":"NOM"} ,
		{"word":"intervenant","word_nosc":"intervenant","lemma":"intervenant","pos":"NOM"} ,
		{"word":"intervenante","word_nosc":"intervenante","lemma":"intervenant","pos":"NOM"} ,
		{"word":"intervenants","word_nosc":"intervenants","lemma":"intervenant","pos":"NOM"} ,
		{"word":"intervention","word_nosc":"intervention","lemma":"intervention","pos":"NOM"} ,
		{"word":"interventionnisme","word_nosc":"interventionnisme","lemma":"interventionnisme","pos":"NOM"} ,
		{"word":"interventionniste","word_nosc":"interventionniste","lemma":"interventionniste","pos":"NOM"} ,
		{"word":"interventionnistes","word_nosc":"interventionnistes","lemma":"interventionniste","pos":"NOM"} ,
		{"word":"interventions","word_nosc":"interventions","lemma":"intervention","pos":"NOM"} ,
		{"word":"interversion","word_nosc":"interversion","lemma":"interversion","pos":"NOM"} ,
		{"word":"interversions","word_nosc":"interversions","lemma":"interversion","pos":"NOM"} ,
		{"word":"interview","word_nosc":"interview","lemma":"interview","pos":"NOM"} ,
		{"word":"interviewer","word_nosc":"interviewer","lemma":"interviewer","pos":"NOM"} ,
		{"word":"interviewers","word_nosc":"interviewers","lemma":"interviewer","pos":"NOM"} ,
		{"word":"intervieweur","word_nosc":"intervieweur","lemma":"intervieweur","pos":"NOM"} ,
		{"word":"interviews","word_nosc":"interviews","lemma":"interview","pos":"NOM"} ,
		{"word":"interviewé","word_nosc":"interviewe","lemma":"interviewé","pos":"NOM"} ,
		{"word":"interviewés","word_nosc":"interviewes","lemma":"interviewé","pos":"NOM"} ,
		{"word":"intestats","word_nosc":"intestats","lemma":"intestat","pos":"NOM"} ,
		{"word":"intestin","word_nosc":"intestin","lemma":"intestin","pos":"NOM"} ,
		{"word":"intestins","word_nosc":"intestins","lemma":"intestin","pos":"NOM"} ,
		{"word":"inti","word_nosc":"inti","lemma":"inti","pos":"NOM"} ,
		{"word":"intifada","word_nosc":"intifada","lemma":"intifada","pos":"NOM"} ,
		{"word":"intimation","word_nosc":"intimation","lemma":"intimation","pos":"NOM"} ,
		{"word":"intime","word_nosc":"intime","lemma":"intime","pos":"NOM"} ,
		{"word":"intimes","word_nosc":"intimes","lemma":"intime","pos":"NOM"} ,
		{"word":"intimidateur","word_nosc":"intimidateur","lemma":"intimidateur","pos":"NOM"} ,
		{"word":"intimidation","word_nosc":"intimidation","lemma":"intimidation","pos":"NOM"} ,
		{"word":"intimidations","word_nosc":"intimidations","lemma":"intimidation","pos":"NOM"} ,
		{"word":"intimistes","word_nosc":"intimistes","lemma":"intimiste","pos":"NOM"} ,
		{"word":"intimité","word_nosc":"intimite","lemma":"intimité","pos":"NOM"} ,
		{"word":"intimités","word_nosc":"intimites","lemma":"intimité","pos":"NOM"} ,
		{"word":"intimé","word_nosc":"intime","lemma":"intimé","pos":"NOM"} ,
		{"word":"intitulé","word_nosc":"intitule","lemma":"intitulé","pos":"NOM"} ,
		{"word":"intitulés","word_nosc":"intitules","lemma":"intitulé","pos":"NOM"} ,
		{"word":"intolérance","word_nosc":"intolerance","lemma":"intolérance","pos":"NOM"} ,
		{"word":"intonation","word_nosc":"intonation","lemma":"intonation","pos":"NOM"} ,
		{"word":"intonations","word_nosc":"intonations","lemma":"intonation","pos":"NOM"} ,
		{"word":"intouchable","word_nosc":"intouchable","lemma":"intouchable","pos":"NOM"} ,
		{"word":"intouchables","word_nosc":"intouchables","lemma":"intouchable","pos":"NOM"} ,
		{"word":"intox","word_nosc":"intox","lemma":"intox","pos":"NOM"} ,
		{"word":"intoxe","word_nosc":"intoxe","lemma":"intox","pos":"NOM"} ,
		{"word":"intoxication","word_nosc":"intoxication","lemma":"intoxication","pos":"NOM"} ,
		{"word":"intoxications","word_nosc":"intoxications","lemma":"intoxication","pos":"NOM"} ,
		{"word":"intoxiqué","word_nosc":"intoxique","lemma":"intoxiqué","pos":"NOM"} ,
		{"word":"intoxiquée","word_nosc":"intoxiquee","lemma":"intoxiqué","pos":"NOM"} ,
		{"word":"intoxiquées","word_nosc":"intoxiquees","lemma":"intoxiqué","pos":"NOM"} ,
		{"word":"intoxiqués","word_nosc":"intoxiques","lemma":"intoxiqué","pos":"NOM"} ,
		{"word":"intrados","word_nosc":"intrados","lemma":"intrados","pos":"NOM"} ,
		{"word":"intrait","word_nosc":"intrait","lemma":"intrait","pos":"NOM"} ,
		{"word":"intranet","word_nosc":"intranet","lemma":"intranet","pos":"NOM"} ,
		{"word":"intransigeance","word_nosc":"intransigeance","lemma":"intransigeance","pos":"NOM"} ,
		{"word":"intransigeances","word_nosc":"intransigeances","lemma":"intransigeance","pos":"NOM"} ,
		{"word":"intransitif","word_nosc":"intransitif","lemma":"intransitif","pos":"NOM"} ,
		{"word":"intrigant","word_nosc":"intrigant","lemma":"intrigant","pos":"NOM"} ,
		{"word":"intrigante","word_nosc":"intrigante","lemma":"intrigant","pos":"NOM"} ,
		{"word":"intrigantes","word_nosc":"intrigantes","lemma":"intrigant","pos":"NOM"} ,
		{"word":"intrigants","word_nosc":"intrigants","lemma":"intrigant","pos":"NOM"} ,
		{"word":"intrigue","word_nosc":"intrigue","lemma":"intrigue","pos":"NOM"} ,
		{"word":"intrigues","word_nosc":"intrigues","lemma":"intrigue","pos":"NOM"} ,
		{"word":"introducteur","word_nosc":"introducteur","lemma":"introducteur","pos":"NOM"} ,
		{"word":"introduction","word_nosc":"introduction","lemma":"introduction","pos":"NOM"} ,
		{"word":"introductions","word_nosc":"introductions","lemma":"introduction","pos":"NOM"} ,
		{"word":"introductrice","word_nosc":"introductrice","lemma":"introducteur","pos":"NOM"} ,
		{"word":"intromission","word_nosc":"intromission","lemma":"intromission","pos":"NOM"} ,
		{"word":"intronisation","word_nosc":"intronisation","lemma":"intronisation","pos":"NOM"} ,
		{"word":"introspection","word_nosc":"introspection","lemma":"introspection","pos":"NOM"} ,
		{"word":"introspections","word_nosc":"introspections","lemma":"introspection","pos":"NOM"} ,
		{"word":"introversions","word_nosc":"introversions","lemma":"introversion","pos":"NOM"} ,
		{"word":"introverti","word_nosc":"introverti","lemma":"introverti","pos":"NOM"} ,
		{"word":"introvertie","word_nosc":"introvertie","lemma":"introverti","pos":"NOM"} ,
		{"word":"introvertis","word_nosc":"introvertis","lemma":"introverti","pos":"NOM"} ,
		{"word":"introït","word_nosc":"introit","lemma":"introït","pos":"NOM"} ,
		{"word":"intrus","word_nosc":"intrus","lemma":"intrus","pos":"NOM"} ,
		{"word":"intruse","word_nosc":"intruse","lemma":"intrus","pos":"NOM"} ,
		{"word":"intruses","word_nosc":"intruses","lemma":"intrus","pos":"NOM"} ,
		{"word":"intrusion","word_nosc":"intrusion","lemma":"intrusion","pos":"NOM"} ,
		{"word":"intrusions","word_nosc":"intrusions","lemma":"intrusion","pos":"NOM"} ,
		{"word":"intrépidité","word_nosc":"intrepidite","lemma":"intrépidité","pos":"NOM"} ,
		{"word":"intubation","word_nosc":"intubation","lemma":"intubation","pos":"NOM"} ,
		{"word":"intuitif","word_nosc":"intuitif","lemma":"intuitif","pos":"NOM"} ,
		{"word":"intuition","word_nosc":"intuition","lemma":"intuition","pos":"NOM"} ,
		{"word":"intuitions","word_nosc":"intuitions","lemma":"intuition","pos":"NOM"} ,
		{"word":"intuitive","word_nosc":"intuitive","lemma":"intuitive","pos":"NOM"} ,
		{"word":"intumescence","word_nosc":"intumescence","lemma":"intumescence","pos":"NOM"} ,
		{"word":"intussusception","word_nosc":"intussusception","lemma":"intussusception","pos":"NOM"} ,
		{"word":"intégrale","word_nosc":"integrale","lemma":"intégrale","pos":"NOM"} ,
		{"word":"intégrales","word_nosc":"integrales","lemma":"intégrale","pos":"NOM"} ,
		{"word":"intégralisme","word_nosc":"integralisme","lemma":"intégralisme","pos":"NOM"} ,
		{"word":"intégralité","word_nosc":"integralite","lemma":"intégralité","pos":"NOM"} ,
		{"word":"intégrateur","word_nosc":"integrateur","lemma":"intégrateur","pos":"NOM"} ,
		{"word":"intégration","word_nosc":"integration","lemma":"intégration","pos":"NOM"} ,
		{"word":"intégrisme","word_nosc":"integrisme","lemma":"intégrisme","pos":"NOM"} ,
		{"word":"intégrismes","word_nosc":"integrismes","lemma":"intégrisme","pos":"NOM"} ,
		{"word":"intégriste","word_nosc":"integriste","lemma":"intégriste","pos":"NOM"} ,
		{"word":"intégristes","word_nosc":"integristes","lemma":"intégriste","pos":"NOM"} ,
		{"word":"intégrité","word_nosc":"integrite","lemma":"intégrité","pos":"NOM"} ,
		{"word":"intégrités","word_nosc":"integrites","lemma":"intégrité","pos":"NOM"} ,
		{"word":"intéressant","word_nosc":"interessant","lemma":"intéressant","pos":"NOM"} ,
		{"word":"intéressante","word_nosc":"interessante","lemma":"intéressant","pos":"NOM"} ,
		{"word":"intéressantes","word_nosc":"interessantes","lemma":"intéressant","pos":"NOM"} ,
		{"word":"intéressants","word_nosc":"interessants","lemma":"intéressant","pos":"NOM"} ,
		{"word":"intéressement","word_nosc":"interessement","lemma":"intéressement","pos":"NOM"} ,
		{"word":"intéressé","word_nosc":"interesse","lemma":"intéressé","pos":"NOM"} ,
		{"word":"intéressée","word_nosc":"interessee","lemma":"intéressé","pos":"NOM"} ,
		{"word":"intéressées","word_nosc":"interessees","lemma":"intéressé","pos":"NOM"} ,
		{"word":"intéressés","word_nosc":"interesses","lemma":"intéressé","pos":"NOM"} ,
		{"word":"intérieur","word_nosc":"interieur","lemma":"intérieur","pos":"NOM"} ,
		{"word":"intérieurs","word_nosc":"interieurs","lemma":"intérieur","pos":"NOM"} ,
		{"word":"intérim","word_nosc":"interim","lemma":"intérim","pos":"NOM"} ,
		{"word":"intérimaire","word_nosc":"interimaire","lemma":"intérimaire","pos":"NOM"} ,
		{"word":"intérimaires","word_nosc":"interimaires","lemma":"intérimaire","pos":"NOM"} ,
		{"word":"intérims","word_nosc":"interims","lemma":"intérim","pos":"NOM"} ,
		{"word":"intériorisation","word_nosc":"interiorisation","lemma":"intériorisation","pos":"NOM"} ,
		{"word":"intériorité","word_nosc":"interiorite","lemma":"intériorité","pos":"NOM"} ,
		{"word":"intérêt","word_nosc":"interet","lemma":"intérêt","pos":"NOM"} ,
		{"word":"intérêts","word_nosc":"interets","lemma":"intérêt","pos":"NOM"} ,
		{"word":"inuit","word_nosc":"inuit","lemma":"inuit","pos":"NOM"} ,
		{"word":"inupik","word_nosc":"inupik","lemma":"inupik","pos":"NOM"} ,
		{"word":"inusabilité","word_nosc":"inusabilite","lemma":"inusabilité","pos":"NOM"} ,
		{"word":"inutile","word_nosc":"inutile","lemma":"inutile","pos":"NOM"} ,
		{"word":"inutiles","word_nosc":"inutiles","lemma":"inutile","pos":"NOM"} ,
		{"word":"inutilisation","word_nosc":"inutilisation","lemma":"inutilisation","pos":"NOM"} ,
		{"word":"inutilité","word_nosc":"inutilite","lemma":"inutilité","pos":"NOM"} ,
		{"word":"inutilités","word_nosc":"inutilites","lemma":"inutilité","pos":"NOM"} ,
		{"word":"invagination","word_nosc":"invagination","lemma":"invagination","pos":"NOM"} ,
		{"word":"invalidation","word_nosc":"invalidation","lemma":"invalidation","pos":"NOM"} ,
		{"word":"invalide","word_nosc":"invalide","lemma":"invalide","pos":"NOM"} ,
		{"word":"invalides","word_nosc":"invalides","lemma":"invalide","pos":"NOM"} ,
		{"word":"invalidité","word_nosc":"invalidite","lemma":"invalidité","pos":"NOM"} ,
		{"word":"invalidités","word_nosc":"invalidites","lemma":"invalidité","pos":"NOM"} ,
		{"word":"invariance","word_nosc":"invariance","lemma":"invariance","pos":"NOM"} ,
		{"word":"invasion","word_nosc":"invasion","lemma":"invasion","pos":"NOM"} ,
		{"word":"invasions","word_nosc":"invasions","lemma":"invasion","pos":"NOM"} ,
		{"word":"invective","word_nosc":"invective","lemma":"invective","pos":"NOM"} ,
		{"word":"invectives","word_nosc":"invectives","lemma":"invective","pos":"NOM"} ,
		{"word":"invendu","word_nosc":"invendu","lemma":"invendu","pos":"NOM"} ,
		{"word":"invendus","word_nosc":"invendus","lemma":"invendu","pos":"NOM"} ,
		{"word":"inventaire","word_nosc":"inventaire","lemma":"inventaire","pos":"NOM"} ,
		{"word":"inventaires","word_nosc":"inventaires","lemma":"inventaire","pos":"NOM"} ,
		{"word":"inventeur","word_nosc":"inventeur","lemma":"inventeur","pos":"NOM"} ,
		{"word":"inventeurs","word_nosc":"inventeurs","lemma":"inventeur","pos":"NOM"} ,
		{"word":"invention","word_nosc":"invention","lemma":"invention","pos":"NOM"} ,
		{"word":"inventions","word_nosc":"inventions","lemma":"invention","pos":"NOM"} ,
		{"word":"inventivité","word_nosc":"inventivite","lemma":"inventivité","pos":"NOM"} ,
		{"word":"inventivités","word_nosc":"inventivites","lemma":"inventivité","pos":"NOM"} ,
		{"word":"inverse","word_nosc":"inverse","lemma":"inverse","pos":"NOM"} ,
		{"word":"inverseur","word_nosc":"inverseur","lemma":"inverseur","pos":"NOM"} ,
		{"word":"inversion","word_nosc":"inversion","lemma":"inversion","pos":"NOM"} ,
		{"word":"inversions","word_nosc":"inversions","lemma":"inversion","pos":"NOM"} ,
		{"word":"inverti","word_nosc":"inverti","lemma":"inverti","pos":"NOM"} ,
		{"word":"invertis","word_nosc":"invertis","lemma":"inverti","pos":"NOM"} ,
		{"word":"invertébré","word_nosc":"invertebre","lemma":"invertébré","pos":"NOM"} ,
		{"word":"invertébrés","word_nosc":"invertebres","lemma":"invertébré","pos":"NOM"} ,
		{"word":"investigateur","word_nosc":"investigateur","lemma":"investigateur","pos":"NOM"} ,
		{"word":"investigateurs","word_nosc":"investigateurs","lemma":"investigateur","pos":"NOM"} ,
		{"word":"investigation","word_nosc":"investigation","lemma":"investigation","pos":"NOM"} ,
		{"word":"investigations","word_nosc":"investigations","lemma":"investigation","pos":"NOM"} ,
		{"word":"investigatrice","word_nosc":"investigatrice","lemma":"investigateur","pos":"NOM"} ,
		{"word":"investissement","word_nosc":"investissement","lemma":"investissement","pos":"NOM"} ,
		{"word":"investissements","word_nosc":"investissements","lemma":"investissement","pos":"NOM"} ,
		{"word":"investisseur","word_nosc":"investisseur","lemma":"investisseur","pos":"NOM"} ,
		{"word":"investisseurs","word_nosc":"investisseurs","lemma":"investisseur","pos":"NOM"} ,
		{"word":"investiture","word_nosc":"investiture","lemma":"investiture","pos":"NOM"} ,
		{"word":"investitures","word_nosc":"investitures","lemma":"investiture","pos":"NOM"} ,
		{"word":"invincibilité","word_nosc":"invincibilite","lemma":"invincibilité","pos":"NOM"} ,
		{"word":"inviolabilité","word_nosc":"inviolabilite","lemma":"inviolabilité","pos":"NOM"} ,
		{"word":"invisibilité","word_nosc":"invisibilite","lemma":"invisibilité","pos":"NOM"} ,
		{"word":"invisible","word_nosc":"invisible","lemma":"invisible","pos":"NOM"} ,
		{"word":"invisible piston","word_nosc":"invisible piston","lemma":"invisible-piston","pos":"NOM"} ,
		{"word":"invisibles","word_nosc":"invisibles","lemma":"invisible","pos":"NOM"} ,
		{"word":"invitation","word_nosc":"invitation","lemma":"invitation","pos":"NOM"} ,
		{"word":"invitations","word_nosc":"invitations","lemma":"invitation","pos":"NOM"} ,
		{"word":"invite","word_nosc":"invite","lemma":"invite","pos":"NOM"} ,
		{"word":"invites","word_nosc":"invites","lemma":"invite","pos":"NOM"} ,
		{"word":"inviteur","word_nosc":"inviteur","lemma":"inviteur","pos":"NOM"} ,
		{"word":"inviteurs","word_nosc":"inviteurs","lemma":"inviteur","pos":"NOM"} ,
		{"word":"invité","word_nosc":"invite","lemma":"invité","pos":"NOM"} ,
		{"word":"invitée","word_nosc":"invitee","lemma":"invité","pos":"NOM"} ,
		{"word":"invitées","word_nosc":"invitees","lemma":"invité","pos":"NOM"} ,
		{"word":"invités","word_nosc":"invites","lemma":"invité","pos":"NOM"} ,
		{"word":"invocation","word_nosc":"invocation","lemma":"invocation","pos":"NOM"} ,
		{"word":"invocations","word_nosc":"invocations","lemma":"invocation","pos":"NOM"} ,
		{"word":"involution","word_nosc":"involution","lemma":"involution","pos":"NOM"} ,
		{"word":"invraisemblance","word_nosc":"invraisemblance","lemma":"invraisemblance","pos":"NOM"} ,
		{"word":"invraisemblances","word_nosc":"invraisemblances","lemma":"invraisemblance","pos":"NOM"} ,
		{"word":"invulnérabilité","word_nosc":"invulnerabilite","lemma":"invulnérabilité","pos":"NOM"} ,
		{"word":"inédit","word_nosc":"inedit","lemma":"inédit","pos":"NOM"} ,
		{"word":"inédits","word_nosc":"inedits","lemma":"inédit","pos":"NOM"} ,
		{"word":"inégalité","word_nosc":"inegalite","lemma":"inégalité","pos":"NOM"} ,
		{"word":"inégalités","word_nosc":"inegalites","lemma":"inégalité","pos":"NOM"} ,
		{"word":"inéligibilité","word_nosc":"ineligibilite","lemma":"inéligibilité","pos":"NOM"} ,
		{"word":"inéluctabilité","word_nosc":"ineluctabilite","lemma":"inéluctabilité","pos":"NOM"} ,
		{"word":"inélégance","word_nosc":"inelegance","lemma":"inélégance","pos":"NOM"} ,
		{"word":"inévitabilité","word_nosc":"inevitabilite","lemma":"inévitabilité","pos":"NOM"} ,
		{"word":"iode","word_nosc":"iode","lemma":"iode","pos":"NOM"} ,
		{"word":"iodoforme","word_nosc":"iodoforme","lemma":"iodoforme","pos":"NOM"} ,
		{"word":"iodure","word_nosc":"iodure","lemma":"iodure","pos":"NOM"} ,
		{"word":"ion","word_nosc":"ion","lemma":"ion","pos":"NOM"} ,
		{"word":"ionisation","word_nosc":"ionisation","lemma":"ionisation","pos":"NOM"} ,
		{"word":"ionosphère","word_nosc":"ionosphere","lemma":"ionosphère","pos":"NOM"} ,
		{"word":"ions","word_nosc":"ions","lemma":"ion","pos":"NOM"} ,
		{"word":"iota","word_nosc":"iota","lemma":"iota","pos":"NOM"} ,
		{"word":"ipomée","word_nosc":"ipomee","lemma":"ipomée","pos":"NOM"} ,
		{"word":"ippon","word_nosc":"ippon","lemma":"ippon","pos":"NOM"} ,
		{"word":"ipse","word_nosc":"ipse","lemma":"ipse","pos":"NOM"} ,
		{"word":"ipéca","word_nosc":"ipeca","lemma":"ipéca","pos":"NOM"} ,
		{"word":"ipécas","word_nosc":"ipecas","lemma":"ipéca","pos":"NOM"} ,
		{"word":"ira","word_nosc":"ira","lemma":"ira","pos":"NOM"} ,
		{"word":"irakien","word_nosc":"irakien","lemma":"irakien","pos":"NOM"} ,
		{"word":"irakiens","word_nosc":"irakiens","lemma":"irakien","pos":"NOM"} ,
		{"word":"iranien","word_nosc":"iranien","lemma":"iranien","pos":"NOM"} ,
		{"word":"iranienne","word_nosc":"iranienne","lemma":"iranien","pos":"NOM"} ,
		{"word":"iraniennes","word_nosc":"iraniennes","lemma":"iranien","pos":"NOM"} ,
		{"word":"iraniens","word_nosc":"iraniens","lemma":"iranien","pos":"NOM"} ,
		{"word":"iraquien","word_nosc":"iraquien","lemma":"iraquien","pos":"NOM"} ,
		{"word":"iraquiens","word_nosc":"iraquiens","lemma":"iraquien","pos":"NOM"} ,
		{"word":"ire","word_nosc":"ire","lemma":"ire","pos":"NOM"} ,
		{"word":"ires","word_nosc":"ires","lemma":"ire","pos":"NOM"} ,
		{"word":"iridescence","word_nosc":"iridescence","lemma":"iridescence","pos":"NOM"} ,
		{"word":"iridium","word_nosc":"iridium","lemma":"iridium","pos":"NOM"} ,
		{"word":"iridologie","word_nosc":"iridologie","lemma":"iridologie","pos":"NOM"} ,
		{"word":"iris","word_nosc":"iris","lemma":"iris","pos":"NOM"} ,
		{"word":"irisation","word_nosc":"irisation","lemma":"irisation","pos":"NOM"} ,
		{"word":"irisations","word_nosc":"irisations","lemma":"irisation","pos":"NOM"} ,
		{"word":"irish coffee","word_nosc":"irish coffee","lemma":"irish coffee","pos":"NOM"} ,
		{"word":"irlandais","word_nosc":"irlandais","lemma":"irlandais","pos":"NOM"} ,
		{"word":"irlandaise","word_nosc":"irlandaise","lemma":"irlandais","pos":"NOM"} ,
		{"word":"irlandaises","word_nosc":"irlandaises","lemma":"irlandais","pos":"NOM"} ,
		{"word":"ironie","word_nosc":"ironie","lemma":"ironie","pos":"NOM"} ,
		{"word":"ironies","word_nosc":"ironies","lemma":"ironie","pos":"NOM"} ,
		{"word":"ironisme","word_nosc":"ironisme","lemma":"ironisme","pos":"NOM"} ,
		{"word":"ironiste","word_nosc":"ironiste","lemma":"ironiste","pos":"NOM"} ,
		{"word":"iroquois","word_nosc":"iroquois","lemma":"iroquois","pos":"NOM"} ,
		{"word":"irradiation","word_nosc":"irradiation","lemma":"irradiation","pos":"NOM"} ,
		{"word":"irradiations","word_nosc":"irradiations","lemma":"irradiation","pos":"NOM"} ,
		{"word":"irrationalité","word_nosc":"irrationalite","lemma":"irrationalité","pos":"NOM"} ,
		{"word":"irrecevabilité","word_nosc":"irrecevabilite","lemma":"irrecevabilité","pos":"NOM"} ,
		{"word":"irrespect","word_nosc":"irrespect","lemma":"irrespect","pos":"NOM"} ,
		{"word":"irresponsabilité","word_nosc":"irresponsabilite","lemma":"irresponsabilité","pos":"NOM"} ,
		{"word":"irrigateurs","word_nosc":"irrigateurs","lemma":"irrigateur","pos":"NOM"} ,
		{"word":"irrigation","word_nosc":"irrigation","lemma":"irrigation","pos":"NOM"} ,
		{"word":"irritabilité","word_nosc":"irritabilite","lemma":"irritabilité","pos":"NOM"} ,
		{"word":"irritant","word_nosc":"irritant","lemma":"irritant","pos":"NOM"} ,
		{"word":"irritants","word_nosc":"irritants","lemma":"irritant","pos":"NOM"} ,
		{"word":"irritation","word_nosc":"irritation","lemma":"irritation","pos":"NOM"} ,
		{"word":"irritations","word_nosc":"irritations","lemma":"irritation","pos":"NOM"} ,
		{"word":"irroration","word_nosc":"irroration","lemma":"irroration","pos":"NOM"} ,
		{"word":"irruption","word_nosc":"irruption","lemma":"irruption","pos":"NOM"} ,
		{"word":"irruptions","word_nosc":"irruptions","lemma":"irruption","pos":"NOM"} ,
		{"word":"irréalisme","word_nosc":"irrealisme","lemma":"irréalisme","pos":"NOM"} ,
		{"word":"irréalité","word_nosc":"irrealite","lemma":"irréalité","pos":"NOM"} ,
		{"word":"irréel","word_nosc":"irreel","lemma":"irréel","pos":"NOM"} ,
		{"word":"irrégularité","word_nosc":"irregularite","lemma":"irrégularité","pos":"NOM"} ,
		{"word":"irrégularités","word_nosc":"irregularites","lemma":"irrégularité","pos":"NOM"} ,
		{"word":"irrégulier","word_nosc":"irregulier","lemma":"irrégulier","pos":"NOM"} ,
		{"word":"irréguliers","word_nosc":"irreguliers","lemma":"irrégulier","pos":"NOM"} ,
		{"word":"irrégulière","word_nosc":"irreguliere","lemma":"irrégulier","pos":"NOM"} ,
		{"word":"irréligiosité","word_nosc":"irreligiosite","lemma":"irréligiosité","pos":"NOM"} ,
		{"word":"irrésolution","word_nosc":"irresolution","lemma":"irrésolution","pos":"NOM"} ,
		{"word":"irréversibilité","word_nosc":"irreversibilite","lemma":"irréversibilité","pos":"NOM"} ,
		{"word":"irrévocabilité","word_nosc":"irrevocabilite","lemma":"irrévocabilité","pos":"NOM"} ,
		{"word":"irrévérence","word_nosc":"irreverence","lemma":"irrévérence","pos":"NOM"} ,
		{"word":"isabelle","word_nosc":"isabelle","lemma":"isabelle","pos":"NOM"} ,
		{"word":"isard","word_nosc":"isard","lemma":"isard","pos":"NOM"} ,
		{"word":"isards","word_nosc":"isards","lemma":"isard","pos":"NOM"} ,
		{"word":"isatis","word_nosc":"isatis","lemma":"isatis","pos":"NOM"} ,
		{"word":"isba","word_nosc":"isba","lemma":"isba","pos":"NOM"} ,
		{"word":"isbas","word_nosc":"isbas","lemma":"isba","pos":"NOM"} ,
		{"word":"ischion","word_nosc":"ischion","lemma":"ischion","pos":"NOM"} ,
		{"word":"ischémie","word_nosc":"ischemie","lemma":"ischémie","pos":"NOM"} ,
		{"word":"islam","word_nosc":"islam","lemma":"islam","pos":"NOM"} ,
		{"word":"islamisme","word_nosc":"islamisme","lemma":"islamisme","pos":"NOM"} ,
		{"word":"islamistes","word_nosc":"islamistes","lemma":"islamiste","pos":"NOM"} ,
		{"word":"islandais","word_nosc":"islandais","lemma":"islandais","pos":"NOM"} ,
		{"word":"ismaélien","word_nosc":"ismaelien","lemma":"ismaélien","pos":"NOM"} ,
		{"word":"ismaéliens","word_nosc":"ismaeliens","lemma":"ismaélien","pos":"NOM"} ,
		{"word":"ismaïliens","word_nosc":"ismailiens","lemma":"ismaïlien","pos":"NOM"} ,
		{"word":"isme","word_nosc":"isme","lemma":"isme","pos":"NOM"} ,
		{"word":"isochronisme","word_nosc":"isochronisme","lemma":"isochronisme","pos":"NOM"} ,
		{"word":"isolant","word_nosc":"isolant","lemma":"isolant","pos":"NOM"} ,
		{"word":"isolateur","word_nosc":"isolateur","lemma":"isolateur","pos":"NOM"} ,
		{"word":"isolateurs","word_nosc":"isolateurs","lemma":"isolateur","pos":"NOM"} ,
		{"word":"isolation","word_nosc":"isolation","lemma":"isolation","pos":"NOM"} ,
		{"word":"isolationnisme","word_nosc":"isolationnisme","lemma":"isolationnisme","pos":"NOM"} ,
		{"word":"isolations","word_nosc":"isolations","lemma":"isolation","pos":"NOM"} ,
		{"word":"isolement","word_nosc":"isolement","lemma":"isolement","pos":"NOM"} ,
		{"word":"isolements","word_nosc":"isolements","lemma":"isolement","pos":"NOM"} ,
		{"word":"isoloir","word_nosc":"isoloir","lemma":"isoloir","pos":"NOM"} ,
		{"word":"isoloirs","word_nosc":"isoloirs","lemma":"isoloir","pos":"NOM"} ,
		{"word":"isolé","word_nosc":"isole","lemma":"isolé","pos":"NOM"} ,
		{"word":"isolés","word_nosc":"isoles","lemma":"isolé","pos":"NOM"} ,
		{"word":"isoniazide","word_nosc":"isoniazide","lemma":"isoniazide","pos":"NOM"} ,
		{"word":"isorel","word_nosc":"isorel","lemma":"isorel","pos":"NOM"} ,
		{"word":"isotope","word_nosc":"isotope","lemma":"isotope","pos":"NOM"} ,
		{"word":"isotopes","word_nosc":"isotopes","lemma":"isotope","pos":"NOM"} ,
		{"word":"israélien","word_nosc":"israelien","lemma":"israélien","pos":"NOM"} ,
		{"word":"israélienne","word_nosc":"israelienne","lemma":"israélien","pos":"NOM"} ,
		{"word":"israéliennes","word_nosc":"israeliennes","lemma":"israélien","pos":"NOM"} ,
		{"word":"israéliens","word_nosc":"israeliens","lemma":"israélien","pos":"NOM"} ,
		{"word":"israélite","word_nosc":"israelite","lemma":"israélite","pos":"NOM"} ,
		{"word":"israélites","word_nosc":"israelites","lemma":"israélite","pos":"NOM"} ,
		{"word":"issue","word_nosc":"issue","lemma":"issue","pos":"NOM"} ,
		{"word":"issues","word_nosc":"issues","lemma":"issue","pos":"NOM"} ,
		{"word":"isthme","word_nosc":"isthme","lemma":"isthme","pos":"NOM"} ,
		{"word":"isthmes","word_nosc":"isthmes","lemma":"isthme","pos":"NOM"} ,
		{"word":"italianisme","word_nosc":"italianisme","lemma":"italianisme","pos":"NOM"} ,
		{"word":"italiano","word_nosc":"italiano","lemma":"italiano","pos":"NOM"} ,
		{"word":"italien","word_nosc":"italien","lemma":"italien","pos":"NOM"} ,
		{"word":"italienne","word_nosc":"italienne","lemma":"italien","pos":"NOM"} ,
		{"word":"italiennes","word_nosc":"italiennes","lemma":"italien","pos":"NOM"} ,
		{"word":"italiens","word_nosc":"italiens","lemma":"italien","pos":"NOM"} ,
		{"word":"ite missa est","word_nosc":"ite missa est","lemma":"ite missa est","pos":"NOM"} ,
		{"word":"item","word_nosc":"item","lemma":"item","pos":"NOM"} ,
		{"word":"ithos","word_nosc":"ithos","lemma":"ithos","pos":"NOM"} ,
		{"word":"itinéraire","word_nosc":"itineraire","lemma":"itinéraire","pos":"NOM"} ,
		{"word":"itinéraires","word_nosc":"itineraires","lemma":"itinéraire","pos":"NOM"} ,
		{"word":"itinérant","word_nosc":"itinerant","lemma":"itinérant","pos":"NOM"} ,
		{"word":"itinérants","word_nosc":"itinerants","lemma":"itinérant","pos":"NOM"} ,
		{"word":"iules","word_nosc":"iules","lemma":"iule","pos":"NOM"} ,
		{"word":"ive","word_nosc":"ive","lemma":"ive","pos":"NOM"} ,
		{"word":"ives","word_nosc":"ives","lemma":"ive","pos":"NOM"} ,
		{"word":"ivoire","word_nosc":"ivoire","lemma":"ivoire","pos":"NOM"} ,
		{"word":"ivoires","word_nosc":"ivoires","lemma":"ivoire","pos":"NOM"} ,
		{"word":"ivoirienne","word_nosc":"ivoirienne","lemma":"ivoirienne","pos":"NOM"} ,
		{"word":"ivoirine","word_nosc":"ivoirine","lemma":"ivoirin","pos":"NOM"} ,
		{"word":"ivoirines","word_nosc":"ivoirines","lemma":"ivoirin","pos":"NOM"} ,
		{"word":"ivraie","word_nosc":"ivraie","lemma":"ivraie","pos":"NOM"} ,
		{"word":"ivresse","word_nosc":"ivresse","lemma":"ivresse","pos":"NOM"} ,
		{"word":"ivresses","word_nosc":"ivresses","lemma":"ivresse","pos":"NOM"} ,
		{"word":"ivrogne","word_nosc":"ivrogne","lemma":"ivrogne","pos":"NOM"} ,
		{"word":"ivrognerie","word_nosc":"ivrognerie","lemma":"ivrognerie","pos":"NOM"} ,
		{"word":"ivrognes","word_nosc":"ivrognes","lemma":"ivrogne","pos":"NOM"} ,
		{"word":"ivrognesse","word_nosc":"ivrognesse","lemma":"ivrognesse","pos":"NOM"} ,
		{"word":"j","word_nosc":"j","lemma":"j","pos":"NOM"} ,
		{"word":"jabadao","word_nosc":"jabadao","lemma":"jabadao","pos":"NOM"} ,
		{"word":"jabot","word_nosc":"jabot","lemma":"jabot","pos":"NOM"} ,
		{"word":"jabots","word_nosc":"jabots","lemma":"jabot","pos":"NOM"} ,
		{"word":"jacarandas","word_nosc":"jacarandas","lemma":"jacaranda","pos":"NOM"} ,
		{"word":"jacasse","word_nosc":"jacasse","lemma":"jacasse","pos":"NOM"} ,
		{"word":"jacassement","word_nosc":"jacassement","lemma":"jacassement","pos":"NOM"} ,
		{"word":"jacassements","word_nosc":"jacassements","lemma":"jacassement","pos":"NOM"} ,
		{"word":"jacasserie","word_nosc":"jacasserie","lemma":"jacasserie","pos":"NOM"} ,
		{"word":"jacasseries","word_nosc":"jacasseries","lemma":"jacasserie","pos":"NOM"} ,
		{"word":"jacasses","word_nosc":"jacasses","lemma":"jacasse","pos":"NOM"} ,
		{"word":"jacasseur","word_nosc":"jacasseur","lemma":"jacasseur","pos":"NOM"} ,
		{"word":"jacasseurs","word_nosc":"jacasseurs","lemma":"jacasseur","pos":"NOM"} ,
		{"word":"jacasseuse","word_nosc":"jacasseuse","lemma":"jacasseur","pos":"NOM"} ,
		{"word":"jacassiers","word_nosc":"jacassiers","lemma":"jacassier","pos":"NOM"} ,
		{"word":"jacassière","word_nosc":"jacassiere","lemma":"jacassier","pos":"NOM"} ,
		{"word":"jachère","word_nosc":"jachere","lemma":"jachère","pos":"NOM"} ,
		{"word":"jachères","word_nosc":"jacheres","lemma":"jachère","pos":"NOM"} ,
		{"word":"jacinthe","word_nosc":"jacinthe","lemma":"jacinthe","pos":"NOM"} ,
		{"word":"jacinthes","word_nosc":"jacinthes","lemma":"jacinthe","pos":"NOM"} ,
		{"word":"jack","word_nosc":"jack","lemma":"jack","pos":"NOM"} ,
		{"word":"jacket","word_nosc":"jacket","lemma":"jacket","pos":"NOM"} ,
		{"word":"jackets","word_nosc":"jackets","lemma":"jacket","pos":"NOM"} ,
		{"word":"jackpot","word_nosc":"jackpot","lemma":"jackpot","pos":"NOM"} ,
		{"word":"jacks","word_nosc":"jacks","lemma":"jack","pos":"NOM"} ,
		{"word":"jacob","word_nosc":"jacob","lemma":"jacob","pos":"NOM"} ,
		{"word":"jacobin","word_nosc":"jacobin","lemma":"jacobin","pos":"NOM"} ,
		{"word":"jacobins","word_nosc":"jacobins","lemma":"jacobin","pos":"NOM"} ,
		{"word":"jacobite","word_nosc":"jacobite","lemma":"jacobite","pos":"NOM"} ,
		{"word":"jacobites","word_nosc":"jacobites","lemma":"jacobite","pos":"NOM"} ,
		{"word":"jacobées","word_nosc":"jacobees","lemma":"jacobée","pos":"NOM"} ,
		{"word":"jacot","word_nosc":"jacot","lemma":"jacot","pos":"NOM"} ,
		{"word":"jacquard","word_nosc":"jacquard","lemma":"jacquard","pos":"NOM"} ,
		{"word":"jacqueline","word_nosc":"jacqueline","lemma":"jacqueline","pos":"NOM"} ,
		{"word":"jacquemart","word_nosc":"jacquemart","lemma":"jacquemart","pos":"NOM"} ,
		{"word":"jacquemarts","word_nosc":"jacquemarts","lemma":"jacquemart","pos":"NOM"} ,
		{"word":"jacqueries","word_nosc":"jacqueries","lemma":"jacquerie","pos":"NOM"} ,
		{"word":"jacques","word_nosc":"jacques","lemma":"jacques","pos":"NOM"} ,
		{"word":"jacquet","word_nosc":"jacquet","lemma":"jacquet","pos":"NOM"} ,
		{"word":"jacquier","word_nosc":"jacquier","lemma":"jacquier","pos":"NOM"} ,
		{"word":"jacquot","word_nosc":"jacquot","lemma":"jacquot","pos":"NOM"} ,
		{"word":"jactance","word_nosc":"jactance","lemma":"jactance","pos":"NOM"} ,
		{"word":"jacteur","word_nosc":"jacteur","lemma":"jacteur","pos":"NOM"} ,
		{"word":"jacuzzi","word_nosc":"jacuzzi","lemma":"jacuzzi","pos":"NOM"} ,
		{"word":"jacuzzis","word_nosc":"jacuzzis","lemma":"jacuzzi","pos":"NOM"} ,
		{"word":"jade","word_nosc":"jade","lemma":"jade","pos":"NOM"} ,
		{"word":"jades","word_nosc":"jades","lemma":"jade","pos":"NOM"} ,
		{"word":"jaffe","word_nosc":"jaffe","lemma":"jaffe","pos":"NOM"} ,
		{"word":"jaguar","word_nosc":"jaguar","lemma":"jaguar","pos":"NOM"} ,
		{"word":"jaguarondi","word_nosc":"jaguarondi","lemma":"jaguarondi","pos":"NOM"} ,
		{"word":"jaguars","word_nosc":"jaguars","lemma":"jaguar","pos":"NOM"} ,
		{"word":"jaillissement","word_nosc":"jaillissement","lemma":"jaillissement","pos":"NOM"} ,
		{"word":"jaillissements","word_nosc":"jaillissements","lemma":"jaillissement","pos":"NOM"} ,
		{"word":"jais","word_nosc":"jais","lemma":"jais","pos":"NOM"} ,
		{"word":"jaja","word_nosc":"jaja","lemma":"jaja","pos":"NOM"} ,
		{"word":"jalon","word_nosc":"jalon","lemma":"jalon","pos":"NOM"} ,
		{"word":"jalons","word_nosc":"jalons","lemma":"jalon","pos":"NOM"} ,
		{"word":"jalouse","word_nosc":"jalouse","lemma":"jaloux","pos":"NOM"} ,
		{"word":"jalouses","word_nosc":"jalouses","lemma":"jaloux","pos":"NOM"} ,
		{"word":"jalousie","word_nosc":"jalousie","lemma":"jalousie","pos":"NOM"} ,
		{"word":"jalousies","word_nosc":"jalousies","lemma":"jalousie","pos":"NOM"} ,
		{"word":"jaloux","word_nosc":"jaloux","lemma":"jaloux","pos":"NOM"} ,
		{"word":"jam","word_nosc":"jam","lemma":"jam","pos":"NOM"} ,
		{"word":"jam-session","word_nosc":"jam-session","lemma":"jam-session","pos":"NOM"} ,
		{"word":"jam-sessions","word_nosc":"jam-sessions","lemma":"jam-session","pos":"NOM"} ,
		{"word":"jamais-vu","word_nosc":"jamais-vu","lemma":"jamais-vu","pos":"NOM"} ,
		{"word":"jamaïcain","word_nosc":"jamaicain","lemma":"jamaïcain","pos":"NOM"} ,
		{"word":"jamaïcaine","word_nosc":"jamaicaine","lemma":"jamaïcain","pos":"NOM"} ,
		{"word":"jamaïcains","word_nosc":"jamaicains","lemma":"jamaïcain","pos":"NOM"} ,
		{"word":"jamaïquain","word_nosc":"jamaiquain","lemma":"jamaïquain","pos":"NOM"} ,
		{"word":"jamaïquains","word_nosc":"jamaiquains","lemma":"jamaïquain","pos":"NOM"} ,
		{"word":"jambage","word_nosc":"jambage","lemma":"jambage","pos":"NOM"} ,
		{"word":"jambages","word_nosc":"jambages","lemma":"jambage","pos":"NOM"} ,
		{"word":"jambart","word_nosc":"jambart","lemma":"jambart","pos":"NOM"} ,
		{"word":"jambe","word_nosc":"jambe","lemma":"jambe","pos":"NOM"} ,
		{"word":"jambes","word_nosc":"jambes","lemma":"jambe","pos":"NOM"} ,
		{"word":"jambier","word_nosc":"jambier","lemma":"jambier","pos":"NOM"} ,
		{"word":"jambière","word_nosc":"jambiere","lemma":"jambière","pos":"NOM"} ,
		{"word":"jambières","word_nosc":"jambieres","lemma":"jambière","pos":"NOM"} ,
		{"word":"jambon","word_nosc":"jambon","lemma":"jambon","pos":"NOM"} ,
		{"word":"jambonneau","word_nosc":"jambonneau","lemma":"jambonneau","pos":"NOM"} ,
		{"word":"jambonneaux","word_nosc":"jambonneaux","lemma":"jambonneau","pos":"NOM"} ,
		{"word":"jambons","word_nosc":"jambons","lemma":"jambon","pos":"NOM"} ,
		{"word":"jamboree","word_nosc":"jamboree","lemma":"jamboree","pos":"NOM"} ,
		{"word":"janissaire","word_nosc":"janissaire","lemma":"janissaire","pos":"NOM"} ,
		{"word":"janissaires","word_nosc":"janissaires","lemma":"janissaire","pos":"NOM"} ,
		{"word":"jans","word_nosc":"jans","lemma":"jan","pos":"NOM"} ,
		{"word":"jansénisme","word_nosc":"jansenisme","lemma":"jansénisme","pos":"NOM"} ,
		{"word":"janséniste","word_nosc":"janseniste","lemma":"janséniste","pos":"NOM"} ,
		{"word":"jansénistes","word_nosc":"jansenistes","lemma":"janséniste","pos":"NOM"} ,
		{"word":"jante","word_nosc":"jante","lemma":"jante","pos":"NOM"} ,
		{"word":"jantes","word_nosc":"jantes","lemma":"jante","pos":"NOM"} ,
		{"word":"janvier","word_nosc":"janvier","lemma":"janvier","pos":"NOM"} ,
		{"word":"jap","word_nosc":"jap","lemma":"jap","pos":"NOM"} ,
		{"word":"japon","word_nosc":"japon","lemma":"japon","pos":"NOM"} ,
		{"word":"japonais","word_nosc":"japonais","lemma":"japonais","pos":"NOM"} ,
		{"word":"japonaise","word_nosc":"japonaise","lemma":"japonais","pos":"NOM"} ,
		{"word":"japonaiserie","word_nosc":"japonaiserie","lemma":"japonaiserie","pos":"NOM"} ,
		{"word":"japonaises","word_nosc":"japonaises","lemma":"japonais","pos":"NOM"} ,
		{"word":"japonerie","word_nosc":"japonerie","lemma":"japonerie","pos":"NOM"} ,
		{"word":"japoneries","word_nosc":"japoneries","lemma":"japonerie","pos":"NOM"} ,
		{"word":"japonisants","word_nosc":"japonisants","lemma":"japonisant","pos":"NOM"} ,
		{"word":"japons","word_nosc":"japons","lemma":"japon","pos":"NOM"} ,
		{"word":"jappement","word_nosc":"jappement","lemma":"jappement","pos":"NOM"} ,
		{"word":"jappements","word_nosc":"jappements","lemma":"jappement","pos":"NOM"} ,
		{"word":"jappeur","word_nosc":"jappeur","lemma":"jappeur","pos":"NOM"} ,
		{"word":"japs","word_nosc":"japs","lemma":"jap","pos":"NOM"} ,
		{"word":"jaquelin","word_nosc":"jaquelin","lemma":"jaquelin","pos":"NOM"} ,
		{"word":"jaquemart","word_nosc":"jaquemart","lemma":"jaquemart","pos":"NOM"} ,
		{"word":"jaquemarts","word_nosc":"jaquemarts","lemma":"jaquemart","pos":"NOM"} ,
		{"word":"jaquet","word_nosc":"jaquet","lemma":"jaquet","pos":"NOM"} ,
		{"word":"jaquette","word_nosc":"jaquette","lemma":"jaquette","pos":"NOM"} ,
		{"word":"jaquettes","word_nosc":"jaquettes","lemma":"jaquette","pos":"NOM"} ,
		{"word":"jar","word_nosc":"jar","lemma":"jar","pos":"NOM"} ,
		{"word":"jardin","word_nosc":"jardin","lemma":"jardin","pos":"NOM"} ,
		{"word":"jardinage","word_nosc":"jardinage","lemma":"jardinage","pos":"NOM"} ,
		{"word":"jardinerie","word_nosc":"jardinerie","lemma":"jardinerie","pos":"NOM"} ,
		{"word":"jardinet","word_nosc":"jardinet","lemma":"jardinet","pos":"NOM"} ,
		{"word":"jardinets","word_nosc":"jardinets","lemma":"jardinet","pos":"NOM"} ,
		{"word":"jardinier","word_nosc":"jardinier","lemma":"jardinier","pos":"NOM"} ,
		{"word":"jardiniers","word_nosc":"jardiniers","lemma":"jardinier","pos":"NOM"} ,
		{"word":"jardinière","word_nosc":"jardiniere","lemma":"jardinière","pos":"NOM"} ,
		{"word":"jardinières","word_nosc":"jardinieres","lemma":"jardinière","pos":"NOM"} ,
		{"word":"jardins","word_nosc":"jardins","lemma":"jardin","pos":"NOM"} ,
		{"word":"jargon","word_nosc":"jargon","lemma":"jargon","pos":"NOM"} ,
		{"word":"jargons","word_nosc":"jargons","lemma":"jargon","pos":"NOM"} ,
		{"word":"jarre","word_nosc":"jarre","lemma":"jarre","pos":"NOM"} ,
		{"word":"jarres","word_nosc":"jarres","lemma":"jarre","pos":"NOM"} ,
		{"word":"jarret","word_nosc":"jarret","lemma":"jarret","pos":"NOM"} ,
		{"word":"jarretelle","word_nosc":"jarretelle","lemma":"jarretelle","pos":"NOM"} ,
		{"word":"jarretelles","word_nosc":"jarretelles","lemma":"jarretelle","pos":"NOM"} ,
		{"word":"jarretière","word_nosc":"jarretiere","lemma":"jarretière","pos":"NOM"} ,
		{"word":"jarretières","word_nosc":"jarretieres","lemma":"jarretière","pos":"NOM"} ,
		{"word":"jarrets","word_nosc":"jarrets","lemma":"jarret","pos":"NOM"} ,
		{"word":"jars","word_nosc":"jars","lemma":"jars","pos":"NOM"} ,
		{"word":"jas","word_nosc":"jas","lemma":"jas","pos":"NOM"} ,
		{"word":"jaseur","word_nosc":"jaseur","lemma":"jaseur","pos":"NOM"} ,
		{"word":"jasmin","word_nosc":"jasmin","lemma":"jasmin","pos":"NOM"} ,
		{"word":"jasmins","word_nosc":"jasmins","lemma":"jasmin","pos":"NOM"} ,
		{"word":"jaspe","word_nosc":"jaspe","lemma":"jaspe","pos":"NOM"} ,
		{"word":"jaspin","word_nosc":"jaspin","lemma":"jaspin","pos":"NOM"} ,
		{"word":"jatte","word_nosc":"jatte","lemma":"jatte","pos":"NOM"} ,
		{"word":"jattes","word_nosc":"jattes","lemma":"jatte","pos":"NOM"} ,
		{"word":"jauge","word_nosc":"jauge","lemma":"jauge","pos":"NOM"} ,
		{"word":"jauges","word_nosc":"jauges","lemma":"jauge","pos":"NOM"} ,
		{"word":"jaune","word_nosc":"jaune","lemma":"jaune","pos":"NOM"} ,
		{"word":"jaunes","word_nosc":"jaunes","lemma":"jaune","pos":"NOM"} ,
		{"word":"jaunets","word_nosc":"jaunets","lemma":"jaunet","pos":"NOM"} ,
		{"word":"jaunisse","word_nosc":"jaunisse","lemma":"jaunisse","pos":"NOM"} ,
		{"word":"jaunissement","word_nosc":"jaunissement","lemma":"jaunissement","pos":"NOM"} ,
		{"word":"jaunisses","word_nosc":"jaunisses","lemma":"jaunisse","pos":"NOM"} ,
		{"word":"java","word_nosc":"java","lemma":"java","pos":"NOM"} ,
		{"word":"javanais","word_nosc":"javanais","lemma":"javanais","pos":"NOM"} ,
		{"word":"javas","word_nosc":"javas","lemma":"java","pos":"NOM"} ,
		{"word":"javel","word_nosc":"javel","lemma":"javel","pos":"NOM"} ,
		{"word":"javeline","word_nosc":"javeline","lemma":"javeline","pos":"NOM"} ,
		{"word":"javelle","word_nosc":"javelle","lemma":"javelle","pos":"NOM"} ,
		{"word":"javelles","word_nosc":"javelles","lemma":"javelle","pos":"NOM"} ,
		{"word":"javelot","word_nosc":"javelot","lemma":"javelot","pos":"NOM"} ,
		{"word":"javelots","word_nosc":"javelots","lemma":"javelot","pos":"NOM"} ,
		{"word":"javotte","word_nosc":"javotte","lemma":"javotte","pos":"NOM"} ,
		{"word":"jazz","word_nosc":"jazz","lemma":"jazz","pos":"NOM"} ,
		{"word":"jazz-band","word_nosc":"jazz-band","lemma":"jazz-band","pos":"NOM"} ,
		{"word":"jazz-rock","word_nosc":"jazz-rock","lemma":"jazz-rock","pos":"NOM"} ,
		{"word":"jazzman","word_nosc":"jazzman","lemma":"jazzman","pos":"NOM"} ,
		{"word":"jazzmen","word_nosc":"jazzmen","lemma":"jazzman","pos":"NOM"} ,
		{"word":"jaïnistes","word_nosc":"jainistes","lemma":"jaïniste","pos":"NOM"} ,
		{"word":"je","word_nosc":"je","lemma":"je","pos":"NOM"} ,
		{"word":"je-m'en-foutisme","word_nosc":"je-m'en-foutisme","lemma":"je-m'en-foutisme","pos":"NOM"} ,
		{"word":"je-m'en-foutistes","word_nosc":"je-m'en-foutistes","lemma":"je-m'en-foutiste","pos":"NOM"} ,
		{"word":"je-ne-sais-quoi","word_nosc":"je-ne-sais-quoi","lemma":"je-ne-sais-quoi","pos":"NOM"} ,
		{"word":"jean","word_nosc":"jean","lemma":"jean","pos":"NOM"} ,
		{"word":"jean-foutre","word_nosc":"jean-foutre","lemma":"jean-foutre","pos":"NOM"} ,
		{"word":"jean-le-blanc","word_nosc":"jean-le-blanc","lemma":"jean-le-blanc","pos":"NOM"} ,
		{"word":"jeanneton","word_nosc":"jeanneton","lemma":"jeanneton","pos":"NOM"} ,
		{"word":"jeannette","word_nosc":"jeannette","lemma":"jeannette","pos":"NOM"} ,
		{"word":"jeannettes","word_nosc":"jeannettes","lemma":"jeannette","pos":"NOM"} ,
		{"word":"jeannot","word_nosc":"jeannot","lemma":"jeannot","pos":"NOM"} ,
		{"word":"jeans","word_nosc":"jeans","lemma":"jean","pos":"NOM"} ,
		{"word":"jeep","word_nosc":"jeep","lemma":"jeep","pos":"NOM"} ,
		{"word":"jeeps","word_nosc":"jeeps","lemma":"jeep","pos":"NOM"} ,
		{"word":"jellaba","word_nosc":"jellaba","lemma":"jellaba","pos":"NOM"} ,
		{"word":"jenny","word_nosc":"jenny","lemma":"jenny","pos":"NOM"} ,
		{"word":"jerez","word_nosc":"jerez","lemma":"jerez","pos":"NOM"} ,
		{"word":"jerk","word_nosc":"jerk","lemma":"jerk","pos":"NOM"} ,
		{"word":"jerrican","word_nosc":"jerrican","lemma":"jerrican","pos":"NOM"} ,
		{"word":"jerricane","word_nosc":"jerricane","lemma":"jerricane","pos":"NOM"} ,
		{"word":"jerricanes","word_nosc":"jerricanes","lemma":"jerricane","pos":"NOM"} ,
		{"word":"jerricans","word_nosc":"jerricans","lemma":"jerrican","pos":"NOM"} ,
		{"word":"jerrycan","word_nosc":"jerrycan","lemma":"jerrycan","pos":"NOM"} ,
		{"word":"jerrycans","word_nosc":"jerrycans","lemma":"jerrycan","pos":"NOM"} ,
		{"word":"jersey","word_nosc":"jersey","lemma":"jersey","pos":"NOM"} ,
		{"word":"jerseys","word_nosc":"jerseys","lemma":"jersey","pos":"NOM"} ,
		{"word":"jet","word_nosc":"jet","lemma":"jet","pos":"NOM"} ,
		{"word":"jet society","word_nosc":"jet society","lemma":"jet society","pos":"NOM"} ,
		{"word":"jet-set","word_nosc":"jet-set","lemma":"jet-set","pos":"NOM"} ,
		{"word":"jet-stream","word_nosc":"jet-stream","lemma":"jet-stream","pos":"NOM"} ,
		{"word":"jeter","word_nosc":"jeter","lemma":"jeter","pos":"NOM"} ,
		{"word":"jeteur","word_nosc":"jeteur","lemma":"jeteur","pos":"NOM"} ,
		{"word":"jeteurs","word_nosc":"jeteurs","lemma":"jeteur","pos":"NOM"} ,
		{"word":"jeteuse","word_nosc":"jeteuse","lemma":"jeteur","pos":"NOM"} ,
		{"word":"jeteuses","word_nosc":"jeteuses","lemma":"jeteur","pos":"NOM"} ,
		{"word":"jeton","word_nosc":"jeton","lemma":"jeton","pos":"NOM"} ,
		{"word":"jetons","word_nosc":"jetons","lemma":"jeton","pos":"NOM"} ,
		{"word":"jets","word_nosc":"jets","lemma":"jet","pos":"NOM"} ,
		{"word":"jette","word_nosc":"jette","lemma":"jette","pos":"NOM"} ,
		{"word":"jeté","word_nosc":"jete","lemma":"jeté","pos":"NOM"} ,
		{"word":"jetée","word_nosc":"jetee","lemma":"jetée","pos":"NOM"} ,
		{"word":"jetées","word_nosc":"jetees","lemma":"jetée","pos":"NOM"} ,
		{"word":"jetés","word_nosc":"jetes","lemma":"jeté","pos":"NOM"} ,
		{"word":"jeu","word_nosc":"jeu","lemma":"jeu","pos":"NOM"} ,
		{"word":"jeu-concours","word_nosc":"jeu-concours","lemma":"jeu-concours","pos":"NOM"} ,
		{"word":"jeudi","word_nosc":"jeudi","lemma":"jeudi","pos":"NOM"} ,
		{"word":"jeudis","word_nosc":"jeudis","lemma":"jeudi","pos":"NOM"} ,
		{"word":"jeune","word_nosc":"jeune","lemma":"jeune","pos":"NOM"} ,
		{"word":"jeune-homme","word_nosc":"jeune-homme","lemma":"jeune-homme","pos":"NOM"} ,
		{"word":"jeunes","word_nosc":"jeunes","lemma":"jeune","pos":"NOM"} ,
		{"word":"jeunesse","word_nosc":"jeunesse","lemma":"jeunesse","pos":"NOM"} ,
		{"word":"jeunesses","word_nosc":"jeunesses","lemma":"jeunesse","pos":"NOM"} ,
		{"word":"jeunet","word_nosc":"jeunet","lemma":"jeunet","pos":"NOM"} ,
		{"word":"jeunette","word_nosc":"jeunette","lemma":"jeunette","pos":"NOM"} ,
		{"word":"jeunettes","word_nosc":"jeunettes","lemma":"jeunette","pos":"NOM"} ,
		{"word":"jeunot","word_nosc":"jeunot","lemma":"jeunot","pos":"NOM"} ,
		{"word":"jeunots","word_nosc":"jeunots","lemma":"jeunot","pos":"NOM"} ,
		{"word":"jeux","word_nosc":"jeux","lemma":"jeu","pos":"NOM"} ,
		{"word":"jeûne","word_nosc":"jeune","lemma":"jeûne","pos":"NOM"} ,
		{"word":"jeûnes","word_nosc":"jeunes","lemma":"jeûne","pos":"NOM"} ,
		{"word":"jeûneurs","word_nosc":"jeuneurs","lemma":"jeûneur","pos":"NOM"} ,
		{"word":"jigger","word_nosc":"jigger","lemma":"jigger","pos":"NOM"} ,
		{"word":"jihad","word_nosc":"jihad","lemma":"jihad","pos":"NOM"} ,
		{"word":"jingle","word_nosc":"jingle","lemma":"jingle","pos":"NOM"} ,
		{"word":"jingles","word_nosc":"jingles","lemma":"jingle","pos":"NOM"} ,
		{"word":"jinjin","word_nosc":"jinjin","lemma":"jinjin","pos":"NOM"} ,
		{"word":"jiu-jitsu","word_nosc":"jiu-jitsu","lemma":"jiu-jitsu","pos":"NOM"} ,
		{"word":"joaillerie","word_nosc":"joaillerie","lemma":"joaillerie","pos":"NOM"} ,
		{"word":"joaillier","word_nosc":"joaillier","lemma":"joaillier","pos":"NOM"} ,
		{"word":"joailliers","word_nosc":"joailliers","lemma":"joaillier","pos":"NOM"} ,
		{"word":"job","word_nosc":"job","lemma":"job","pos":"NOM"} ,
		{"word":"jobard","word_nosc":"jobard","lemma":"jobard","pos":"NOM"} ,
		{"word":"jobardise","word_nosc":"jobardise","lemma":"jobardise","pos":"NOM"} ,
		{"word":"jobards","word_nosc":"jobards","lemma":"jobard","pos":"NOM"} ,
		{"word":"jobs","word_nosc":"jobs","lemma":"job","pos":"NOM"} ,
		{"word":"jociste","word_nosc":"jociste","lemma":"jociste","pos":"NOM"} ,
		{"word":"jocistes","word_nosc":"jocistes","lemma":"jociste","pos":"NOM"} ,
		{"word":"jockey","word_nosc":"jockey","lemma":"jockey","pos":"NOM"} ,
		{"word":"jockeys","word_nosc":"jockeys","lemma":"jockey","pos":"NOM"} ,
		{"word":"jocko","word_nosc":"jocko","lemma":"jocko","pos":"NOM"} ,
		{"word":"jocrisse","word_nosc":"jocrisse","lemma":"jocrisse","pos":"NOM"} ,
		{"word":"jocrisses","word_nosc":"jocrisses","lemma":"jocrisse","pos":"NOM"} ,
		{"word":"jodhpurs","word_nosc":"jodhpurs","lemma":"jodhpur","pos":"NOM"} ,
		{"word":"jogger","word_nosc":"jogger","lemma":"jogger","pos":"NOM"} ,
		{"word":"joggers","word_nosc":"joggers","lemma":"jogger","pos":"NOM"} ,
		{"word":"joggeur","word_nosc":"joggeur","lemma":"joggeur","pos":"NOM"} ,
		{"word":"joggeurs","word_nosc":"joggeurs","lemma":"joggeur","pos":"NOM"} ,
		{"word":"joggeuse","word_nosc":"joggeuse","lemma":"joggeur","pos":"NOM"} ,
		{"word":"jogging","word_nosc":"jogging","lemma":"jogging","pos":"NOM"} ,
		{"word":"joggings","word_nosc":"joggings","lemma":"jogging","pos":"NOM"} ,
		{"word":"johannisberg","word_nosc":"johannisberg","lemma":"johannisberg","pos":"NOM"} ,
		{"word":"joie","word_nosc":"joie","lemma":"joie","pos":"NOM"} ,
		{"word":"joies","word_nosc":"joies","lemma":"joie","pos":"NOM"} ,
		{"word":"joint","word_nosc":"joint","lemma":"joint","pos":"NOM"} ,
		{"word":"joint-venture","word_nosc":"joint-venture","lemma":"joint-venture","pos":"NOM"} ,
		{"word":"joints","word_nosc":"joints","lemma":"joint","pos":"NOM"} ,
		{"word":"jointure","word_nosc":"jointure","lemma":"jointure","pos":"NOM"} ,
		{"word":"jointures","word_nosc":"jointures","lemma":"jointure","pos":"NOM"} ,
		{"word":"jojo","word_nosc":"jojo","lemma":"jojo","pos":"NOM"} ,
		{"word":"jojoba","word_nosc":"jojoba","lemma":"jojoba","pos":"NOM"} ,
		{"word":"jojos","word_nosc":"jojos","lemma":"jojo","pos":"NOM"} ,
		{"word":"jokari","word_nosc":"jokari","lemma":"jokari","pos":"NOM"} ,
		{"word":"joker","word_nosc":"joker","lemma":"joker","pos":"NOM"} ,
		{"word":"jokers","word_nosc":"jokers","lemma":"joker","pos":"NOM"} ,
		{"word":"joli-coeur","word_nosc":"joli-coeur","lemma":"joli-coeur","pos":"NOM"} ,
		{"word":"joli-joli","word_nosc":"joli-joli","lemma":"joli-joli","pos":"NOM"} ,
		{"word":"jolibois","word_nosc":"jolibois","lemma":"jolibois","pos":"NOM"} ,
		{"word":"joliesse","word_nosc":"joliesse","lemma":"joliesse","pos":"NOM"} ,
		{"word":"joliesses","word_nosc":"joliesses","lemma":"joliesse","pos":"NOM"} ,
		{"word":"jolis","word_nosc":"jolis","lemma":"joli","pos":"NOM"} ,
		{"word":"jonc","word_nosc":"jonc","lemma":"jonc","pos":"NOM"} ,
		{"word":"joncaille","word_nosc":"joncaille","lemma":"joncaille","pos":"NOM"} ,
		{"word":"jonchaie","word_nosc":"jonchaie","lemma":"jonchaie","pos":"NOM"} ,
		{"word":"jonchaies","word_nosc":"jonchaies","lemma":"jonchaie","pos":"NOM"} ,
		{"word":"joncheraies","word_nosc":"joncheraies","lemma":"joncheraie","pos":"NOM"} ,
		{"word":"jonchet","word_nosc":"jonchet","lemma":"jonchet","pos":"NOM"} ,
		{"word":"jonchets","word_nosc":"jonchets","lemma":"jonchet","pos":"NOM"} ,
		{"word":"jonchère","word_nosc":"jonchere","lemma":"jonchère","pos":"NOM"} ,
		{"word":"jonchée","word_nosc":"jonchee","lemma":"jonchée","pos":"NOM"} ,
		{"word":"jonchées","word_nosc":"jonchees","lemma":"jonchée","pos":"NOM"} ,
		{"word":"joncs","word_nosc":"joncs","lemma":"jonc","pos":"NOM"} ,
		{"word":"jonction","word_nosc":"jonction","lemma":"jonction","pos":"NOM"} ,
		{"word":"jonctions","word_nosc":"jonctions","lemma":"jonction","pos":"NOM"} ,
		{"word":"jonglage","word_nosc":"jonglage","lemma":"jonglage","pos":"NOM"} ,
		{"word":"jonglerie","word_nosc":"jonglerie","lemma":"jonglerie","pos":"NOM"} ,
		{"word":"jongleries","word_nosc":"jongleries","lemma":"jonglerie","pos":"NOM"} ,
		{"word":"jongleur","word_nosc":"jongleur","lemma":"jongleur","pos":"NOM"} ,
		{"word":"jongleurs","word_nosc":"jongleurs","lemma":"jongleur","pos":"NOM"} ,
		{"word":"jongleuse","word_nosc":"jongleuse","lemma":"jongleur","pos":"NOM"} ,
		{"word":"jongleuses","word_nosc":"jongleuses","lemma":"jongleur","pos":"NOM"} ,
		{"word":"jonkheer","word_nosc":"jonkheer","lemma":"jonkheer","pos":"NOM"} ,
		{"word":"jonque","word_nosc":"jonque","lemma":"jonque","pos":"NOM"} ,
		{"word":"jonques","word_nosc":"jonques","lemma":"jonque","pos":"NOM"} ,
		{"word":"jonquille","word_nosc":"jonquille","lemma":"jonquille","pos":"NOM"} ,
		{"word":"jonquilles","word_nosc":"jonquilles","lemma":"jonquille","pos":"NOM"} ,
		{"word":"jordanienne","word_nosc":"jordanienne","lemma":"jordanienne","pos":"NOM"} ,
		{"word":"jordaniens","word_nosc":"jordaniens","lemma":"jordanien","pos":"NOM"} ,
		{"word":"joseph","word_nosc":"joseph","lemma":"joseph","pos":"NOM"} ,
		{"word":"joubarbe","word_nosc":"joubarbe","lemma":"joubarbe","pos":"NOM"} ,
		{"word":"joue","word_nosc":"joue","lemma":"joue","pos":"NOM"} ,
		{"word":"joues","word_nosc":"joues","lemma":"joue","pos":"NOM"} ,
		{"word":"jouet","word_nosc":"jouet","lemma":"jouet","pos":"NOM"} ,
		{"word":"jouets","word_nosc":"jouets","lemma":"jouet","pos":"NOM"} ,
		{"word":"joueur","word_nosc":"joueur","lemma":"joueur","pos":"NOM"} ,
		{"word":"joueurs","word_nosc":"joueurs","lemma":"joueur","pos":"NOM"} ,
		{"word":"joueuse","word_nosc":"joueuse","lemma":"joueur","pos":"NOM"} ,
		{"word":"joueuses","word_nosc":"joueuses","lemma":"joueur","pos":"NOM"} ,
		{"word":"joufflu","word_nosc":"joufflu","lemma":"joufflu","pos":"NOM"} ,
		{"word":"joufflus","word_nosc":"joufflus","lemma":"joufflu","pos":"NOM"} ,
		{"word":"joug","word_nosc":"joug","lemma":"joug","pos":"NOM"} ,
		{"word":"jougs","word_nosc":"jougs","lemma":"joug","pos":"NOM"} ,
		{"word":"jouissance","word_nosc":"jouissance","lemma":"jouissance","pos":"NOM"} ,
		{"word":"jouissances","word_nosc":"jouissances","lemma":"jouissance","pos":"NOM"} ,
		{"word":"jouisseur","word_nosc":"jouisseur","lemma":"jouisseur","pos":"NOM"} ,
		{"word":"jouisseurs","word_nosc":"jouisseurs","lemma":"jouisseur","pos":"NOM"} ,
		{"word":"jouisseuse","word_nosc":"jouisseuse","lemma":"jouisseur","pos":"NOM"} ,
		{"word":"joujou","word_nosc":"joujou","lemma":"joujou","pos":"NOM"} ,
		{"word":"joujoux","word_nosc":"joujoux","lemma":"joujoux","pos":"NOM"} ,
		{"word":"joules","word_nosc":"joules","lemma":"joule","pos":"NOM"} ,
		{"word":"jour","word_nosc":"jour","lemma":"jour","pos":"NOM"} ,
		{"word":"journal","word_nosc":"journal","lemma":"journal","pos":"NOM"} ,
		{"word":"journaleuse","word_nosc":"journaleuse","lemma":"journaleux","pos":"NOM"} ,
		{"word":"journaleux","word_nosc":"journaleux","lemma":"journaleux","pos":"NOM"} ,
		{"word":"journalier","word_nosc":"journalier","lemma":"journalier","pos":"NOM"} ,
		{"word":"journaliers","word_nosc":"journaliers","lemma":"journalier","pos":"NOM"} ,
		{"word":"journalisme","word_nosc":"journalisme","lemma":"journalisme","pos":"NOM"} ,
		{"word":"journaliste","word_nosc":"journaliste","lemma":"journaliste","pos":"NOM"} ,
		{"word":"journalistes","word_nosc":"journalistes","lemma":"journaliste","pos":"NOM"} ,
		{"word":"journalières","word_nosc":"journalieres","lemma":"journalière","pos":"NOM"} ,
		{"word":"journaux","word_nosc":"journaux","lemma":"journal","pos":"NOM"} ,
		{"word":"journée","word_nosc":"journee","lemma":"journée","pos":"NOM"} ,
		{"word":"journées","word_nosc":"journees","lemma":"journée","pos":"NOM"} ,
		{"word":"jours","word_nosc":"jours","lemma":"jour","pos":"NOM"} ,
		{"word":"joute","word_nosc":"joute","lemma":"joute","pos":"NOM"} ,
		{"word":"joutes","word_nosc":"joutes","lemma":"joute","pos":"NOM"} ,
		{"word":"jouteur","word_nosc":"jouteur","lemma":"jouteur","pos":"NOM"} ,
		{"word":"jouteurs","word_nosc":"jouteurs","lemma":"jouteur","pos":"NOM"} ,
		{"word":"jouteuse","word_nosc":"jouteuse","lemma":"jouteur","pos":"NOM"} ,
		{"word":"jouvence","word_nosc":"jouvence","lemma":"jouvence","pos":"NOM"} ,
		{"word":"jouvenceau","word_nosc":"jouvenceau","lemma":"jouvenceau","pos":"NOM"} ,
		{"word":"jouvenceaux","word_nosc":"jouvenceaux","lemma":"jouvenceau","pos":"NOM"} ,
		{"word":"jouvencelle","word_nosc":"jouvencelle","lemma":"jouvenceau","pos":"NOM"} ,
		{"word":"jouvencelles","word_nosc":"jouvencelles","lemma":"jouvenceau","pos":"NOM"} ,
		{"word":"jouées","word_nosc":"jouees","lemma":"joué","pos":"NOM"} ,
		{"word":"jovialité","word_nosc":"jovialite","lemma":"jovialité","pos":"NOM"} ,
		{"word":"joyau","word_nosc":"joyau","lemma":"joyau","pos":"NOM"} ,
		{"word":"joyaux","word_nosc":"joyaux","lemma":"joyau","pos":"NOM"} ,
		{"word":"joyeuseté","word_nosc":"joyeusete","lemma":"joyeuseté","pos":"NOM"} ,
		{"word":"joyeusetés","word_nosc":"joyeusetes","lemma":"joyeuseté","pos":"NOM"} ,
		{"word":"joystick","word_nosc":"joystick","lemma":"joystick","pos":"NOM"} ,
		{"word":"juan","word_nosc":"juan","lemma":"juan","pos":"NOM"} ,
		{"word":"jubilation","word_nosc":"jubilation","lemma":"jubilation","pos":"NOM"} ,
		{"word":"jubilations","word_nosc":"jubilations","lemma":"jubilation","pos":"NOM"} ,
		{"word":"jubilé","word_nosc":"jubile","lemma":"jubilé","pos":"NOM"} ,
		{"word":"jubé","word_nosc":"jube","lemma":"jubé","pos":"NOM"} ,
		{"word":"juchée","word_nosc":"juchee","lemma":"juchée","pos":"NOM"} ,
		{"word":"judas","word_nosc":"judas","lemma":"judas","pos":"NOM"} ,
		{"word":"judaïcité","word_nosc":"judaicite","lemma":"judaïcité","pos":"NOM"} ,
		{"word":"judaïsant","word_nosc":"judaisant","lemma":"judaïsant","pos":"NOM"} ,
		{"word":"judaïsme","word_nosc":"judaisme","lemma":"judaïsme","pos":"NOM"} ,
		{"word":"judaïté","word_nosc":"judaite","lemma":"judaïté","pos":"NOM"} ,
		{"word":"judo","word_nosc":"judo","lemma":"judo","pos":"NOM"} ,
		{"word":"judoka","word_nosc":"judoka","lemma":"judoka","pos":"NOM"} ,
		{"word":"judéen","word_nosc":"judeen","lemma":"judéen","pos":"NOM"} ,
		{"word":"judéenne","word_nosc":"judeenne","lemma":"judéenne","pos":"NOM"} ,
		{"word":"judéité","word_nosc":"judeite","lemma":"judéité","pos":"NOM"} ,
		{"word":"judéo-christianisme","word_nosc":"judeo-christianisme","lemma":"judéo-christianisme","pos":"NOM"} ,
		{"word":"juge","word_nosc":"juge","lemma":"juge","pos":"NOM"} ,
		{"word":"jugement","word_nosc":"jugement","lemma":"jugement","pos":"NOM"} ,
		{"word":"jugements","word_nosc":"jugements","lemma":"jugement","pos":"NOM"} ,
		{"word":"jugeote","word_nosc":"jugeote","lemma":"jugeote","pos":"NOM"} ,
		{"word":"juges","word_nosc":"juges","lemma":"juge","pos":"NOM"} ,
		{"word":"jugeur","word_nosc":"jugeur","lemma":"jugeur","pos":"NOM"} ,
		{"word":"jugulaire","word_nosc":"jugulaire","lemma":"jugulaire","pos":"NOM"} ,
		{"word":"jugulaires","word_nosc":"jugulaires","lemma":"jugulaire","pos":"NOM"} ,
		{"word":"jugé","word_nosc":"juge","lemma":"jugé","pos":"NOM"} ,
		{"word":"jugés","word_nosc":"juges","lemma":"jugé","pos":"NOM"} ,
		{"word":"juif","word_nosc":"juif","lemma":"juif","pos":"NOM"} ,
		{"word":"juifs","word_nosc":"juifs","lemma":"juif","pos":"NOM"} ,
		{"word":"juillet","word_nosc":"juillet","lemma":"juillet","pos":"NOM"} ,
		{"word":"juillets","word_nosc":"juillets","lemma":"juillet","pos":"NOM"} ,
		{"word":"juin","word_nosc":"juin","lemma":"juin","pos":"NOM"} ,
		{"word":"juive","word_nosc":"juive","lemma":"juif","pos":"NOM"} ,
		{"word":"juiverie","word_nosc":"juiverie","lemma":"juiverie","pos":"NOM"} ,
		{"word":"juiveries","word_nosc":"juiveries","lemma":"juiverie","pos":"NOM"} ,
		{"word":"juives","word_nosc":"juives","lemma":"juif","pos":"NOM"} ,
		{"word":"jujube","word_nosc":"jujube","lemma":"jujube","pos":"NOM"} ,
		{"word":"jujubes","word_nosc":"jujubes","lemma":"jujube","pos":"NOM"} ,
		{"word":"jujubiers","word_nosc":"jujubiers","lemma":"jujubier","pos":"NOM"} ,
		{"word":"juke-box","word_nosc":"juke-box","lemma":"juke-box","pos":"NOM"} ,
		{"word":"juke-boxes","word_nosc":"juke-boxes","lemma":"juke-box","pos":"NOM"} ,
		{"word":"julep","word_nosc":"julep","lemma":"julep","pos":"NOM"} ,
		{"word":"juleps","word_nosc":"juleps","lemma":"julep","pos":"NOM"} ,
		{"word":"jules","word_nosc":"jules","lemma":"jules","pos":"NOM"} ,
		{"word":"julienne","word_nosc":"julienne","lemma":"julienne","pos":"NOM"} ,
		{"word":"juliennes","word_nosc":"juliennes","lemma":"julienne","pos":"NOM"} ,
		{"word":"julot","word_nosc":"julot","lemma":"julot","pos":"NOM"} ,
		{"word":"julots","word_nosc":"julots","lemma":"julot","pos":"NOM"} ,
		{"word":"jumbo","word_nosc":"jumbo","lemma":"jumbo","pos":"NOM"} ,
		{"word":"jumeau","word_nosc":"jumeau","lemma":"jumeau","pos":"NOM"} ,
		{"word":"jumeaux","word_nosc":"jumeaux","lemma":"jumeau","pos":"NOM"} ,
		{"word":"jumelage","word_nosc":"jumelage","lemma":"jumelage","pos":"NOM"} ,
		{"word":"jumelle","word_nosc":"jumelle","lemma":"jumelle","pos":"NOM"} ,
		{"word":"jumelles","word_nosc":"jumelles","lemma":"jumelle","pos":"NOM"} ,
		{"word":"jument","word_nosc":"jument","lemma":"jument","pos":"NOM"} ,
		{"word":"jumenterie","word_nosc":"jumenterie","lemma":"jumenterie","pos":"NOM"} ,
		{"word":"juments","word_nosc":"juments","lemma":"jument","pos":"NOM"} ,
		{"word":"jumper","word_nosc":"jumper","lemma":"jumper","pos":"NOM"} ,
		{"word":"jumping","word_nosc":"jumping","lemma":"jumping","pos":"NOM"} ,
		{"word":"jungien","word_nosc":"jungien","lemma":"jungien","pos":"NOM"} ,
		{"word":"jungiens","word_nosc":"jungiens","lemma":"jungien","pos":"NOM"} ,
		{"word":"jungle","word_nosc":"jungle","lemma":"jungle","pos":"NOM"} ,
		{"word":"jungles","word_nosc":"jungles","lemma":"jungle","pos":"NOM"} ,
		{"word":"junior","word_nosc":"junior","lemma":"junior","pos":"NOM"} ,
		{"word":"juniors","word_nosc":"juniors","lemma":"junior","pos":"NOM"} ,
		{"word":"junker","word_nosc":"junker","lemma":"junker","pos":"NOM"} ,
		{"word":"junkers","word_nosc":"junkers","lemma":"junker","pos":"NOM"} ,
		{"word":"junkie","word_nosc":"junkie","lemma":"junkie","pos":"NOM"} ,
		{"word":"junkies","word_nosc":"junkies","lemma":"junkie","pos":"NOM"} ,
		{"word":"junky","word_nosc":"junky","lemma":"junky","pos":"NOM"} ,
		{"word":"junte","word_nosc":"junte","lemma":"junte","pos":"NOM"} ,
		{"word":"jupe","word_nosc":"jupe","lemma":"jupe","pos":"NOM"} ,
		{"word":"jupe-culotte","word_nosc":"jupe-culotte","lemma":"jupe-culotte","pos":"NOM"} ,
		{"word":"jupes","word_nosc":"jupes","lemma":"jupe","pos":"NOM"} ,
		{"word":"jupes-culottes","word_nosc":"jupes-culottes","lemma":"jupe-culotte","pos":"NOM"} ,
		{"word":"jupette","word_nosc":"jupette","lemma":"jupette","pos":"NOM"} ,
		{"word":"jupettes","word_nosc":"jupettes","lemma":"jupette","pos":"NOM"} ,
		{"word":"jupiers","word_nosc":"jupiers","lemma":"jupier","pos":"NOM"} ,
		{"word":"jupon","word_nosc":"jupon","lemma":"jupon","pos":"NOM"} ,
		{"word":"jupons","word_nosc":"jupons","lemma":"jupon","pos":"NOM"} ,
		{"word":"jurançon","word_nosc":"jurancon","lemma":"jurançon","pos":"NOM"} ,
		{"word":"jurassien","word_nosc":"jurassien","lemma":"jurassien","pos":"NOM"} ,
		{"word":"jurassiens","word_nosc":"jurassiens","lemma":"jurassien","pos":"NOM"} ,
		{"word":"jurassique","word_nosc":"jurassique","lemma":"jurassique","pos":"NOM"} ,
		{"word":"juridiction","word_nosc":"juridiction","lemma":"juridiction","pos":"NOM"} ,
		{"word":"juridictions","word_nosc":"juridictions","lemma":"juridiction","pos":"NOM"} ,
		{"word":"jurisprudence","word_nosc":"jurisprudence","lemma":"jurisprudence","pos":"NOM"} ,
		{"word":"juriste","word_nosc":"juriste","lemma":"juriste","pos":"NOM"} ,
		{"word":"juristes","word_nosc":"juristes","lemma":"juriste","pos":"NOM"} ,
		{"word":"juron","word_nosc":"juron","lemma":"juron","pos":"NOM"} ,
		{"word":"jurons","word_nosc":"jurons","lemma":"juron","pos":"NOM"} ,
		{"word":"jury","word_nosc":"jury","lemma":"jury","pos":"NOM"} ,
		{"word":"jurys","word_nosc":"jurys","lemma":"jury","pos":"NOM"} ,
		{"word":"juré","word_nosc":"jure","lemma":"juré","pos":"NOM"} ,
		{"word":"jurée","word_nosc":"juree","lemma":"juré","pos":"NOM"} ,
		{"word":"jurés","word_nosc":"jures","lemma":"juré","pos":"NOM"} ,
		{"word":"jus","word_nosc":"jus","lemma":"jus","pos":"NOM"} ,
		{"word":"jusant","word_nosc":"jusant","lemma":"jusant","pos":"NOM"} ,
		{"word":"jusquiame","word_nosc":"jusquiame","lemma":"jusquiame","pos":"NOM"} ,
		{"word":"justaucorps","word_nosc":"justaucorps","lemma":"justaucorps","pos":"NOM"} ,
		{"word":"juste","word_nosc":"juste","lemma":"juste","pos":"NOM"} ,
		{"word":"juste-milieu","word_nosc":"juste-milieu","lemma":"juste-milieu","pos":"NOM"} ,
		{"word":"justes","word_nosc":"justes","lemma":"juste","pos":"NOM"} ,
		{"word":"justesse","word_nosc":"justesse","lemma":"justesse","pos":"NOM"} ,
		{"word":"justice","word_nosc":"justice","lemma":"justice","pos":"NOM"} ,
		{"word":"justices","word_nosc":"justices","lemma":"justice","pos":"NOM"} ,
		{"word":"justicier","word_nosc":"justicier","lemma":"justicier","pos":"NOM"} ,
		{"word":"justiciers","word_nosc":"justiciers","lemma":"justicier","pos":"NOM"} ,
		{"word":"justicière","word_nosc":"justiciere","lemma":"justicier","pos":"NOM"} ,
		{"word":"justificatif","word_nosc":"justificatif","lemma":"justificatif","pos":"NOM"} ,
		{"word":"justificatifs","word_nosc":"justificatifs","lemma":"justificatif","pos":"NOM"} ,
		{"word":"justification","word_nosc":"justification","lemma":"justification","pos":"NOM"} ,
		{"word":"justifications","word_nosc":"justifications","lemma":"justification","pos":"NOM"} ,
		{"word":"jute","word_nosc":"jute","lemma":"jute","pos":"NOM"} ,
		{"word":"juvénilité","word_nosc":"juvenilite","lemma":"juvénilité","pos":"NOM"} ,
		{"word":"juxtaposition","word_nosc":"juxtaposition","lemma":"juxtaposition","pos":"NOM"} ,
		{"word":"juxtapositions","word_nosc":"juxtapositions","lemma":"juxtaposition","pos":"NOM"} ,
		{"word":"jèzes","word_nosc":"jezes","lemma":"jèze","pos":"NOM"} ,
		{"word":"jéjunum","word_nosc":"jejunum","lemma":"jéjunum","pos":"NOM"} ,
		{"word":"jéroboam","word_nosc":"jeroboam","lemma":"jéroboam","pos":"NOM"} ,
		{"word":"jéroboams","word_nosc":"jeroboams","lemma":"jéroboam","pos":"NOM"} ,
		{"word":"jérémiade","word_nosc":"jeremiade","lemma":"jérémiade","pos":"NOM"} ,
		{"word":"jérémiades","word_nosc":"jeremiades","lemma":"jérémiade","pos":"NOM"} ,
		{"word":"jésuite","word_nosc":"jesuite","lemma":"jésuite","pos":"NOM"} ,
		{"word":"jésuites","word_nosc":"jesuites","lemma":"jésuite","pos":"NOM"} ,
		{"word":"jésuitière","word_nosc":"jesuitiere","lemma":"jésuitière","pos":"NOM"} ,
		{"word":"jésus","word_nosc":"jesus","lemma":"jésus","pos":"NOM"} ,
		{"word":"k","word_nosc":"k","lemma":"k","pos":"NOM"} ,
		{"word":"k-way","word_nosc":"k-way","lemma":"k-way","pos":"NOM"} ,
		{"word":"ka","word_nosc":"ka","lemma":"ka","pos":"NOM"} ,
		{"word":"kabbale","word_nosc":"kabbale","lemma":"kabbale","pos":"NOM"} ,
		{"word":"kabuki","word_nosc":"kabuki","lemma":"kabuki","pos":"NOM"} ,
		{"word":"kabyle","word_nosc":"kabyle","lemma":"kabyle","pos":"NOM"} ,
		{"word":"kacha","word_nosc":"kacha","lemma":"kacha","pos":"NOM"} ,
		{"word":"kaddisch","word_nosc":"kaddisch","lemma":"kaddisch","pos":"NOM"} ,
		{"word":"kaddish","word_nosc":"kaddish","lemma":"kaddish","pos":"NOM"} ,
		{"word":"kadi","word_nosc":"kadi","lemma":"kadi","pos":"NOM"} ,
		{"word":"kafir","word_nosc":"kafir","lemma":"kafir","pos":"NOM"} ,
		{"word":"kaiser","word_nosc":"kaiser","lemma":"kaiser","pos":"NOM"} ,
		{"word":"kaki","word_nosc":"kaki","lemma":"kaki","pos":"NOM"} ,
		{"word":"kakis","word_nosc":"kakis","lemma":"kaki","pos":"NOM"} ,
		{"word":"kala-azar","word_nosc":"kala-azar","lemma":"kala-azar","pos":"NOM"} ,
		{"word":"kalachnikovs","word_nosc":"kalachnikovs","lemma":"kalachnikov","pos":"NOM"} ,
		{"word":"kali","word_nosc":"kali","lemma":"kali","pos":"NOM"} ,
		{"word":"kaliémie","word_nosc":"kaliemie","lemma":"kaliémie","pos":"NOM"} ,
		{"word":"kalpa","word_nosc":"kalpa","lemma":"kalpa","pos":"NOM"} ,
		{"word":"kaléidoscope","word_nosc":"kaleidoscope","lemma":"kaléidoscope","pos":"NOM"} ,
		{"word":"kaléidoscopes","word_nosc":"kaleidoscopes","lemma":"kaléidoscope","pos":"NOM"} ,
		{"word":"kama","word_nosc":"kama","lemma":"kama","pos":"NOM"} ,
		{"word":"kamala","word_nosc":"kamala","lemma":"kamala","pos":"NOM"} ,
		{"word":"kami","word_nosc":"kami","lemma":"kami","pos":"NOM"} ,
		{"word":"kamikaze","word_nosc":"kamikaze","lemma":"kamikaze","pos":"NOM"} ,
		{"word":"kamikazes","word_nosc":"kamikazes","lemma":"kamikaze","pos":"NOM"} ,
		{"word":"kan","word_nosc":"kan","lemma":"kan","pos":"NOM"} ,
		{"word":"kana","word_nosc":"kana","lemma":"kana","pos":"NOM"} ,
		{"word":"kandjar","word_nosc":"kandjar","lemma":"kandjar","pos":"NOM"} ,
		{"word":"kangourou","word_nosc":"kangourou","lemma":"kangourou","pos":"NOM"} ,
		{"word":"kangourous","word_nosc":"kangourous","lemma":"kangourou","pos":"NOM"} ,
		{"word":"kanji","word_nosc":"kanji","lemma":"kanji","pos":"NOM"} ,
		{"word":"kantisme","word_nosc":"kantisme","lemma":"kantisme","pos":"NOM"} ,
		{"word":"kaolin","word_nosc":"kaolin","lemma":"kaolin","pos":"NOM"} ,
		{"word":"kaori","word_nosc":"kaori","lemma":"kaori","pos":"NOM"} ,
		{"word":"kapo","word_nosc":"kapo","lemma":"kapo","pos":"NOM"} ,
		{"word":"kapok","word_nosc":"kapok","lemma":"kapok","pos":"NOM"} ,
		{"word":"kapokier","word_nosc":"kapokier","lemma":"kapokier","pos":"NOM"} ,
		{"word":"kapos","word_nosc":"kapos","lemma":"kapo","pos":"NOM"} ,
		{"word":"kaposi","word_nosc":"kaposi","lemma":"kaposi","pos":"NOM"} ,
		{"word":"kapout","word_nosc":"kapout","lemma":"kapout","pos":"NOM"} ,
		{"word":"kappa","word_nosc":"kappa","lemma":"kappa","pos":"NOM"} ,
		{"word":"karaoké","word_nosc":"karaoke","lemma":"karaoké","pos":"NOM"} ,
		{"word":"karaokés","word_nosc":"karaokes","lemma":"karaoké","pos":"NOM"} ,
		{"word":"karaté","word_nosc":"karate","lemma":"karaté","pos":"NOM"} ,
		{"word":"karatéka","word_nosc":"karateka","lemma":"karatéka","pos":"NOM"} ,
		{"word":"karatékas","word_nosc":"karatekas","lemma":"karatéka","pos":"NOM"} ,
		{"word":"kari","word_nosc":"kari","lemma":"kari","pos":"NOM"} ,
		{"word":"karité","word_nosc":"karite","lemma":"karité","pos":"NOM"} ,
		{"word":"karma","word_nosc":"karma","lemma":"karma","pos":"NOM"} ,
		{"word":"kart","word_nosc":"kart","lemma":"kart","pos":"NOM"} ,
		{"word":"karting","word_nosc":"karting","lemma":"karting","pos":"NOM"} ,
		{"word":"kasbah","word_nosc":"kasbah","lemma":"kasbah","pos":"NOM"} ,
		{"word":"kastro","word_nosc":"kastro","lemma":"kastro","pos":"NOM"} ,
		{"word":"kastros","word_nosc":"kastros","lemma":"kastro","pos":"NOM"} ,
		{"word":"kata","word_nosc":"kata","lemma":"kata","pos":"NOM"} ,
		{"word":"katal","word_nosc":"katal","lemma":"katal","pos":"NOM"} ,
		{"word":"katangais","word_nosc":"katangais","lemma":"katangais","pos":"NOM"} ,
		{"word":"katas","word_nosc":"katas","lemma":"kata","pos":"NOM"} ,
		{"word":"katchinas","word_nosc":"katchinas","lemma":"katchina","pos":"NOM"} ,
		{"word":"katiba","word_nosc":"katiba","lemma":"katiba","pos":"NOM"} ,
		{"word":"kava","word_nosc":"kava","lemma":"kava","pos":"NOM"} ,
		{"word":"kawa","word_nosc":"kawa","lemma":"kawa","pos":"NOM"} ,
		{"word":"kayac","word_nosc":"kayac","lemma":"kayac","pos":"NOM"} ,
		{"word":"kayak","word_nosc":"kayak","lemma":"kayak","pos":"NOM"} ,
		{"word":"kayakiste","word_nosc":"kayakiste","lemma":"kayakiste","pos":"NOM"} ,
		{"word":"kazakh","word_nosc":"kazakh","lemma":"kazakh","pos":"NOM"} ,
		{"word":"kebab","word_nosc":"kebab","lemma":"kebab","pos":"NOM"} ,
		{"word":"kebabs","word_nosc":"kebabs","lemma":"kebab","pos":"NOM"} ,
		{"word":"keepsake","word_nosc":"keepsake","lemma":"keepsake","pos":"NOM"} ,
		{"word":"keffieh","word_nosc":"keffieh","lemma":"keffieh","pos":"NOM"} ,
		{"word":"keiretsu","word_nosc":"keiretsu","lemma":"keiretsu","pos":"NOM"} ,
		{"word":"kekchose","word_nosc":"kekchose","lemma":"kekchose","pos":"NOM"} ,
		{"word":"kelvins","word_nosc":"kelvins","lemma":"kelvin","pos":"NOM"} ,
		{"word":"kendo","word_nosc":"kendo","lemma":"kendo","pos":"NOM"} ,
		{"word":"kermesse","word_nosc":"kermesse","lemma":"kermesse","pos":"NOM"} ,
		{"word":"kermesses","word_nosc":"kermesses","lemma":"kermesse","pos":"NOM"} ,
		{"word":"kerrie","word_nosc":"kerrie","lemma":"kerrie","pos":"NOM"} ,
		{"word":"ket","word_nosc":"ket","lemma":"ket","pos":"NOM"} ,
		{"word":"ketch","word_nosc":"ketch","lemma":"ketch","pos":"NOM"} ,
		{"word":"ketchup","word_nosc":"ketchup","lemma":"ketchup","pos":"NOM"} ,
		{"word":"keuf","word_nosc":"keuf","lemma":"keuf","pos":"NOM"} ,
		{"word":"keufs","word_nosc":"keufs","lemma":"keuf","pos":"NOM"} ,
		{"word":"keum","word_nosc":"keum","lemma":"keum","pos":"NOM"} ,
		{"word":"keums","word_nosc":"keums","lemma":"keum","pos":"NOM"} ,
		{"word":"kevlar","word_nosc":"kevlar","lemma":"kevlar","pos":"NOM"} ,
		{"word":"khalife","word_nosc":"khalife","lemma":"khalife","pos":"NOM"} ,
		{"word":"khamsin","word_nosc":"khamsin","lemma":"khamsin","pos":"NOM"} ,
		{"word":"khan","word_nosc":"khan","lemma":"khan","pos":"NOM"} ,
		{"word":"khanat","word_nosc":"khanat","lemma":"khanat","pos":"NOM"} ,
		{"word":"khans","word_nosc":"khans","lemma":"khan","pos":"NOM"} ,
		{"word":"khat","word_nosc":"khat","lemma":"khat","pos":"NOM"} ,
		{"word":"khâgne","word_nosc":"khagne","lemma":"khâgne","pos":"NOM"} ,
		{"word":"khâgnes","word_nosc":"khagnes","lemma":"khâgne","pos":"NOM"} ,
		{"word":"khâgneux","word_nosc":"khagneux","lemma":"khâgneux","pos":"NOM"} ,
		{"word":"khédive","word_nosc":"khedive","lemma":"khédive","pos":"NOM"} ,
		{"word":"khôl","word_nosc":"khol","lemma":"khôl","pos":"NOM"} ,
		{"word":"kibboutz","word_nosc":"kibboutz","lemma":"kibboutz","pos":"NOM"} ,
		{"word":"kibboutzim","word_nosc":"kibboutzim","lemma":"kibboutzim","pos":"NOM"} ,
		{"word":"kick","word_nosc":"kick","lemma":"kick","pos":"NOM"} ,
		{"word":"kicker","word_nosc":"kicker","lemma":"kicker","pos":"NOM"} ,
		{"word":"kicks","word_nosc":"kicks","lemma":"kick","pos":"NOM"} ,
		{"word":"kid","word_nosc":"kid","lemma":"kid","pos":"NOM"} ,
		{"word":"kidnappeur","word_nosc":"kidnappeur","lemma":"kidnappeur","pos":"NOM"} ,
		{"word":"kidnappeurs","word_nosc":"kidnappeurs","lemma":"kidnappeur","pos":"NOM"} ,
		{"word":"kidnapping","word_nosc":"kidnapping","lemma":"kidnapping","pos":"NOM"} ,
		{"word":"kidnappings","word_nosc":"kidnappings","lemma":"kidnapping","pos":"NOM"} ,
		{"word":"kids","word_nosc":"kids","lemma":"kid","pos":"NOM"} ,
		{"word":"kief","word_nosc":"kief","lemma":"kief","pos":"NOM"} ,
		{"word":"kierkegaardienne","word_nosc":"kierkegaardienne","lemma":"kierkegaardien","pos":"NOM"} ,
		{"word":"kif","word_nosc":"kif","lemma":"kif","pos":"NOM"} ,
		{"word":"kiki","word_nosc":"kiki","lemma":"kiki","pos":"NOM"} ,
		{"word":"kil","word_nosc":"kil","lemma":"kil","pos":"NOM"} ,
		{"word":"kilbus","word_nosc":"kilbus","lemma":"kilbus","pos":"NOM"} ,
		{"word":"kilim","word_nosc":"kilim","lemma":"kilim","pos":"NOM"} ,
		{"word":"killer","word_nosc":"killer","lemma":"killer","pos":"NOM"} ,
		{"word":"kilo","word_nosc":"kilo","lemma":"kilo","pos":"NOM"} ,
		{"word":"kilogramme","word_nosc":"kilogramme","lemma":"kilogramme","pos":"NOM"} ,
		{"word":"kilogrammes","word_nosc":"kilogrammes","lemma":"kilogramme","pos":"NOM"} ,
		{"word":"kilohertz","word_nosc":"kilohertz","lemma":"kilohertz","pos":"NOM"} ,
		{"word":"kilomètre","word_nosc":"kilometre","lemma":"kilomètre","pos":"NOM"} ,
		{"word":"kilomètres","word_nosc":"kilometres","lemma":"kilomètre","pos":"NOM"} ,
		{"word":"kilomètres-heure","word_nosc":"kilometres-heure","lemma":"kilomètre-heure","pos":"NOM"} ,
		{"word":"kilométrage","word_nosc":"kilometrage","lemma":"kilométrage","pos":"NOM"} ,
		{"word":"kilométrages","word_nosc":"kilometrages","lemma":"kilométrage","pos":"NOM"} ,
		{"word":"kilos","word_nosc":"kilos","lemma":"kilo","pos":"NOM"} ,
		{"word":"kilotonnes","word_nosc":"kilotonnes","lemma":"kilotonne","pos":"NOM"} ,
		{"word":"kilowattheure","word_nosc":"kilowattheure","lemma":"kilowattheure","pos":"NOM"} ,
		{"word":"kilowatts","word_nosc":"kilowatts","lemma":"kilowatt","pos":"NOM"} ,
		{"word":"kils","word_nosc":"kils","lemma":"kil","pos":"NOM"} ,
		{"word":"kilt","word_nosc":"kilt","lemma":"kilt","pos":"NOM"} ,
		{"word":"kilts","word_nosc":"kilts","lemma":"kilt","pos":"NOM"} ,
		{"word":"kimono","word_nosc":"kimono","lemma":"kimono","pos":"NOM"} ,
		{"word":"kimonos","word_nosc":"kimonos","lemma":"kimono","pos":"NOM"} ,
		{"word":"kinase","word_nosc":"kinase","lemma":"kinase","pos":"NOM"} ,
		{"word":"kinescope","word_nosc":"kinescope","lemma":"kinescope","pos":"NOM"} ,
		{"word":"kinesthésie","word_nosc":"kinesthesie","lemma":"kinesthésie","pos":"NOM"} ,
		{"word":"king-charles","word_nosc":"king-charles","lemma":"king-charles","pos":"NOM"} ,
		{"word":"kinnor","word_nosc":"kinnor","lemma":"kinnor","pos":"NOM"} ,
		{"word":"kino","word_nosc":"kino","lemma":"kino","pos":"NOM"} ,
		{"word":"kiné","word_nosc":"kine","lemma":"kiné","pos":"NOM"} ,
		{"word":"kinésiques","word_nosc":"kinesiques","lemma":"kinésique","pos":"NOM"} ,
		{"word":"kinésithérapeute","word_nosc":"kinesitherapeute","lemma":"kinésithérapeute","pos":"NOM"} ,
		{"word":"kinésithérapie","word_nosc":"kinesitherapie","lemma":"kinésithérapie","pos":"NOM"} ,
		{"word":"kiosk","word_nosc":"kiosk","lemma":"kiosk","pos":"NOM"} ,
		{"word":"kiosks","word_nosc":"kiosks","lemma":"kiosk","pos":"NOM"} ,
		{"word":"kiosque","word_nosc":"kiosque","lemma":"kiosque","pos":"NOM"} ,
		{"word":"kiosques","word_nosc":"kiosques","lemma":"kiosque","pos":"NOM"} ,
		{"word":"kip","word_nosc":"kip","lemma":"kip","pos":"NOM"} ,
		{"word":"kipa","word_nosc":"kipa","lemma":"kipa","pos":"NOM"} ,
		{"word":"kippa","word_nosc":"kippa","lemma":"kippa","pos":"NOM"} ,
		{"word":"kipper","word_nosc":"kipper","lemma":"kipper","pos":"NOM"} ,
		{"word":"kippers","word_nosc":"kippers","lemma":"kipper","pos":"NOM"} ,
		{"word":"kips","word_nosc":"kips","lemma":"kip","pos":"NOM"} ,
		{"word":"kir","word_nosc":"kir","lemma":"kir","pos":"NOM"} ,
		{"word":"kirghiz","word_nosc":"kirghiz","lemma":"kirghiz","pos":"NOM"} ,
		{"word":"kirghize","word_nosc":"kirghize","lemma":"kirghize","pos":"NOM"} ,
		{"word":"kirs","word_nosc":"kirs","lemma":"kir","pos":"NOM"} ,
		{"word":"kirsch","word_nosc":"kirsch","lemma":"kirsch","pos":"NOM"} ,
		{"word":"kit","word_nosc":"kit","lemma":"kit","pos":"NOM"} ,
		{"word":"kitchenette","word_nosc":"kitchenette","lemma":"kitchenette","pos":"NOM"} ,
		{"word":"kits","word_nosc":"kits","lemma":"kit","pos":"NOM"} ,
		{"word":"kiva","word_nosc":"kiva","lemma":"kiva","pos":"NOM"} ,
		{"word":"kiwi","word_nosc":"kiwi","lemma":"kiwi","pos":"NOM"} ,
		{"word":"kiwis","word_nosc":"kiwis","lemma":"kiwi","pos":"NOM"} ,
		{"word":"klaxon","word_nosc":"klaxon","lemma":"klaxon","pos":"NOM"} ,
		{"word":"klaxons","word_nosc":"klaxons","lemma":"klaxon","pos":"NOM"} ,
		{"word":"klebs","word_nosc":"klebs","lemma":"klebs","pos":"NOM"} ,
		{"word":"kleenex","word_nosc":"kleenex","lemma":"kleenex","pos":"NOM"} ,
		{"word":"kleptomane","word_nosc":"kleptomane","lemma":"kleptomane","pos":"NOM"} ,
		{"word":"kleptomanes","word_nosc":"kleptomanes","lemma":"kleptomane","pos":"NOM"} ,
		{"word":"kleptomanie","word_nosc":"kleptomanie","lemma":"kleptomanie","pos":"NOM"} ,
		{"word":"klystron","word_nosc":"klystron","lemma":"klystron","pos":"NOM"} ,
		{"word":"knickerbockers","word_nosc":"knickerbockers","lemma":"knickerbocker","pos":"NOM"} ,
		{"word":"knickers","word_nosc":"knickers","lemma":"knicker","pos":"NOM"} ,
		{"word":"knock-out","word_nosc":"knock-out","lemma":"knock-out","pos":"NOM"} ,
		{"word":"knout","word_nosc":"knout","lemma":"knout","pos":"NOM"} ,
		{"word":"koala","word_nosc":"koala","lemma":"koala","pos":"NOM"} ,
		{"word":"koalas","word_nosc":"koalas","lemma":"koala","pos":"NOM"} ,
		{"word":"kobold","word_nosc":"kobold","lemma":"kobold","pos":"NOM"} ,
		{"word":"kobolds","word_nosc":"kobolds","lemma":"kobold","pos":"NOM"} ,
		{"word":"kodak","word_nosc":"kodak","lemma":"kodak","pos":"NOM"} ,
		{"word":"kodiak","word_nosc":"kodiak","lemma":"kodiak","pos":"NOM"} ,
		{"word":"kohl","word_nosc":"kohl","lemma":"kohl","pos":"NOM"} ,
		{"word":"kola","word_nosc":"kola","lemma":"kola","pos":"NOM"} ,
		{"word":"kolatiers","word_nosc":"kolatiers","lemma":"kolatier","pos":"NOM"} ,
		{"word":"kolkhoze","word_nosc":"kolkhoze","lemma":"kolkhoze","pos":"NOM"} ,
		{"word":"kolkhozien","word_nosc":"kolkhozien","lemma":"kolkhozien","pos":"NOM"} ,
		{"word":"kolkhozienne","word_nosc":"kolkhozienne","lemma":"kolkhozien","pos":"NOM"} ,
		{"word":"kolkhoziennes","word_nosc":"kolkhoziennes","lemma":"kolkhozien","pos":"NOM"} ,
		{"word":"kolkhoziens","word_nosc":"kolkhoziens","lemma":"kolkhozien","pos":"NOM"} ,
		{"word":"komi","word_nosc":"komi","lemma":"komi","pos":"NOM"} ,
		{"word":"komintern","word_nosc":"komintern","lemma":"komintern","pos":"NOM"} ,
		{"word":"kommandantur","word_nosc":"kommandantur","lemma":"kommandantur","pos":"NOM"} ,
		{"word":"kommando","word_nosc":"kommando","lemma":"kommando","pos":"NOM"} ,
		{"word":"komsomol","word_nosc":"komsomol","lemma":"komsomol","pos":"NOM"} ,
		{"word":"komsomols","word_nosc":"komsomols","lemma":"komsomol","pos":"NOM"} ,
		{"word":"kondo","word_nosc":"kondo","lemma":"kondo","pos":"NOM"} ,
		{"word":"kopeck","word_nosc":"kopeck","lemma":"kopeck","pos":"NOM"} ,
		{"word":"kopecks","word_nosc":"kopecks","lemma":"kopeck","pos":"NOM"} ,
		{"word":"kopeks","word_nosc":"kopeks","lemma":"kopek","pos":"NOM"} ,
		{"word":"kora","word_nosc":"kora","lemma":"kora","pos":"NOM"} ,
		{"word":"koran","word_nosc":"koran","lemma":"koran","pos":"NOM"} ,
		{"word":"korrigan","word_nosc":"korrigan","lemma":"korrigan","pos":"NOM"} ,
		{"word":"korrigans","word_nosc":"korrigans","lemma":"korrigan","pos":"NOM"} ,
		{"word":"korè","word_nosc":"kore","lemma":"korè","pos":"NOM"} ,
		{"word":"koré","word_nosc":"kore","lemma":"koré","pos":"NOM"} ,
		{"word":"kosovar","word_nosc":"kosovar","lemma":"kosovar","pos":"NOM"} ,
		{"word":"kot","word_nosc":"kot","lemma":"kot","pos":"NOM"} ,
		{"word":"koto","word_nosc":"koto","lemma":"koto","pos":"NOM"} ,
		{"word":"kouglof","word_nosc":"kouglof","lemma":"kouglof","pos":"NOM"} ,
		{"word":"koulak","word_nosc":"koulak","lemma":"koulak","pos":"NOM"} ,
		{"word":"koulaks","word_nosc":"koulaks","lemma":"koulak","pos":"NOM"} ,
		{"word":"kouros","word_nosc":"kouros","lemma":"kouros","pos":"NOM"} ,
		{"word":"kraal","word_nosc":"kraal","lemma":"kraal","pos":"NOM"} ,
		{"word":"krach","word_nosc":"krach","lemma":"krach","pos":"NOM"} ,
		{"word":"krachs","word_nosc":"krachs","lemma":"krach","pos":"NOM"} ,
		{"word":"kraft","word_nosc":"kraft","lemma":"kraft","pos":"NOM"} ,
		{"word":"krak","word_nosc":"krak","lemma":"krak","pos":"NOM"} ,
		{"word":"kraken","word_nosc":"kraken","lemma":"kraken","pos":"NOM"} ,
		{"word":"kremlin","word_nosc":"kremlin","lemma":"kremlin","pos":"NOM"} ,
		{"word":"kreutzer","word_nosc":"kreutzer","lemma":"kreutzer","pos":"NOM"} ,
		{"word":"kriegspiel","word_nosc":"kriegspiel","lemma":"kriegspiel","pos":"NOM"} ,
		{"word":"krill","word_nosc":"krill","lemma":"krill","pos":"NOM"} ,
		{"word":"kriss","word_nosc":"kriss","lemma":"kriss","pos":"NOM"} ,
		{"word":"kroumir","word_nosc":"kroumir","lemma":"kroumir","pos":"NOM"} ,
		{"word":"kroumirs","word_nosc":"kroumirs","lemma":"kroumir","pos":"NOM"} ,
		{"word":"krypton","word_nosc":"krypton","lemma":"krypton","pos":"NOM"} ,
		{"word":"ksar","word_nosc":"ksar","lemma":"ksar","pos":"NOM"} ,
		{"word":"kugelhof","word_nosc":"kugelhof","lemma":"kugelhof","pos":"NOM"} ,
		{"word":"kula","word_nosc":"kula","lemma":"kula","pos":"NOM"} ,
		{"word":"kummel","word_nosc":"kummel","lemma":"kummel","pos":"NOM"} ,
		{"word":"kumquat","word_nosc":"kumquat","lemma":"kumquat","pos":"NOM"} ,
		{"word":"kumquats","word_nosc":"kumquats","lemma":"kumquat","pos":"NOM"} ,
		{"word":"kung fu","word_nosc":"kung fu","lemma":"kung-fu","pos":"NOM"} ,
		{"word":"kung-fu","word_nosc":"kung-fu","lemma":"kung-fu","pos":"NOM"} ,
		{"word":"kurde","word_nosc":"kurde","lemma":"kurde","pos":"NOM"} ,
		{"word":"kurdes","word_nosc":"kurdes","lemma":"kurde","pos":"NOM"} ,
		{"word":"kursaal","word_nosc":"kursaal","lemma":"kursaal","pos":"NOM"} ,
		{"word":"kurus","word_nosc":"kurus","lemma":"kuru","pos":"NOM"} ,
		{"word":"kvas","word_nosc":"kvas","lemma":"kvas","pos":"NOM"} ,
		{"word":"kyrie","word_nosc":"kyrie","lemma":"kyrie","pos":"NOM"} ,
		{"word":"kyrie eleison","word_nosc":"kyrie eleison","lemma":"kyrie eleison","pos":"NOM"} ,
		{"word":"kyrielle","word_nosc":"kyrielle","lemma":"kyrielle","pos":"NOM"} ,
		{"word":"kyrielles","word_nosc":"kyrielles","lemma":"kyrielle","pos":"NOM"} ,
		{"word":"kyste","word_nosc":"kyste","lemma":"kyste","pos":"NOM"} ,
		{"word":"kystes","word_nosc":"kystes","lemma":"kyste","pos":"NOM"} ,
		{"word":"kébour","word_nosc":"kebour","lemma":"kébour","pos":"NOM"} ,
		{"word":"kébours","word_nosc":"kebours","lemma":"kébour","pos":"NOM"} ,
		{"word":"kéfir","word_nosc":"kefir","lemma":"kéfir","pos":"NOM"} ,
		{"word":"kéfirs","word_nosc":"kefirs","lemma":"kéfir","pos":"NOM"} ,
		{"word":"képi","word_nosc":"kepi","lemma":"képi","pos":"NOM"} ,
		{"word":"képis","word_nosc":"kepis","lemma":"képi","pos":"NOM"} ,
		{"word":"kérabau","word_nosc":"kerabau","lemma":"kérabau","pos":"NOM"} ,
		{"word":"kératoplastie","word_nosc":"keratoplastie","lemma":"kératoplastie","pos":"NOM"} ,
		{"word":"kératose","word_nosc":"keratose","lemma":"kératose","pos":"NOM"} ,
		{"word":"kérosène","word_nosc":"kerosene","lemma":"kérosène","pos":"NOM"} ,
		{"word":"kérosènes","word_nosc":"kerosenes","lemma":"kérosène","pos":"NOM"} ,
		{"word":"l","word_nosc":"l","lemma":"l","pos":"NOM"} ,
		{"word":"l'","word_nosc":"l'","lemma":"l","pos":"NOM"} ,
		{"word":"l-dopa","word_nosc":"l-dopa","lemma":"l-dopa","pos":"NOM"} ,
		{"word":"la","word_nosc":"la","lemma":"la","pos":"NOM"} ,
		{"word":"la plata","word_nosc":"la plata","lemma":"la plata","pos":"NOM"} ,
		{"word":"labbe","word_nosc":"labbe","lemma":"labbe","pos":"NOM"} ,
		{"word":"labbes","word_nosc":"labbes","lemma":"labbe","pos":"NOM"} ,
		{"word":"label","word_nosc":"label","lemma":"label","pos":"NOM"} ,
		{"word":"labelle","word_nosc":"labelle","lemma":"labelle","pos":"NOM"} ,
		{"word":"labels","word_nosc":"labels","lemma":"label","pos":"NOM"} ,
		{"word":"labeur","word_nosc":"labeur","lemma":"labeur","pos":"NOM"} ,
		{"word":"labeurs","word_nosc":"labeurs","lemma":"labeur","pos":"NOM"} ,
		{"word":"labo","word_nosc":"labo","lemma":"labo","pos":"NOM"} ,
		{"word":"laborantin","word_nosc":"laborantin","lemma":"laborantin","pos":"NOM"} ,
		{"word":"laborantine","word_nosc":"laborantine","lemma":"laborantin","pos":"NOM"} ,
		{"word":"laborantins","word_nosc":"laborantins","lemma":"laborantin","pos":"NOM"} ,
		{"word":"laboratoire","word_nosc":"laboratoire","lemma":"laboratoire","pos":"NOM"} ,
		{"word":"laboratoires","word_nosc":"laboratoires","lemma":"laboratoire","pos":"NOM"} ,
		{"word":"labos","word_nosc":"labos","lemma":"labo","pos":"NOM"} ,
		{"word":"labour","word_nosc":"labour","lemma":"labour","pos":"NOM"} ,
		{"word":"labourage","word_nosc":"labourage","lemma":"labourage","pos":"NOM"} ,
		{"word":"laboureur","word_nosc":"laboureur","lemma":"laboureur","pos":"NOM"} ,
		{"word":"laboureurs","word_nosc":"laboureurs","lemma":"laboureur","pos":"NOM"} ,
		{"word":"labours","word_nosc":"labours","lemma":"labour","pos":"NOM"} ,
		{"word":"labrador","word_nosc":"labrador","lemma":"labrador","pos":"NOM"} ,
		{"word":"labradors","word_nosc":"labradors","lemma":"labrador","pos":"NOM"} ,
		{"word":"labre","word_nosc":"labre","lemma":"labre","pos":"NOM"} ,
		{"word":"labres","word_nosc":"labres","lemma":"labre","pos":"NOM"} ,
		{"word":"labri","word_nosc":"labri","lemma":"labri","pos":"NOM"} ,
		{"word":"labris","word_nosc":"labris","lemma":"labri","pos":"NOM"} ,
		{"word":"labyrinthe","word_nosc":"labyrinthe","lemma":"labyrinthe","pos":"NOM"} ,
		{"word":"labyrinthes","word_nosc":"labyrinthes","lemma":"labyrinthe","pos":"NOM"} ,
		{"word":"lac","word_nosc":"lac","lemma":"lac","pos":"NOM"} ,
		{"word":"lacanien","word_nosc":"lacanien","lemma":"lacanien","pos":"NOM"} ,
		{"word":"lacaniens","word_nosc":"lacaniens","lemma":"lacanien","pos":"NOM"} ,
		{"word":"lacet","word_nosc":"lacet","lemma":"lacet","pos":"NOM"} ,
		{"word":"lacets","word_nosc":"lacets","lemma":"lacet","pos":"NOM"} ,
		{"word":"lacis","word_nosc":"lacis","lemma":"lacis","pos":"NOM"} ,
		{"word":"lack","word_nosc":"lack","lemma":"lack","pos":"NOM"} ,
		{"word":"laconisme","word_nosc":"laconisme","lemma":"laconisme","pos":"NOM"} ,
		{"word":"lacrima-christi","word_nosc":"lacrima-christi","lemma":"lacrima-christi","pos":"NOM"} ,
		{"word":"lacryma christi","word_nosc":"lacryma christi","lemma":"lacryma-christi","pos":"NOM"} ,
		{"word":"lacryma-christi","word_nosc":"lacryma-christi","lemma":"lacryma-christi","pos":"NOM"} ,
		{"word":"lacs","word_nosc":"lacs","lemma":"lacs","pos":"NOM"} ,
		{"word":"lactaires","word_nosc":"lactaires","lemma":"lactaire","pos":"NOM"} ,
		{"word":"lactate","word_nosc":"lactate","lemma":"lactate","pos":"NOM"} ,
		{"word":"lactation","word_nosc":"lactation","lemma":"lactation","pos":"NOM"} ,
		{"word":"lactose","word_nosc":"lactose","lemma":"lactose","pos":"NOM"} ,
		{"word":"lacune","word_nosc":"lacune","lemma":"lacune","pos":"NOM"} ,
		{"word":"lacunes","word_nosc":"lacunes","lemma":"lacune","pos":"NOM"} ,
		{"word":"lacédémoniens","word_nosc":"lacedemoniens","lemma":"lacédémonien","pos":"NOM"} ,
		{"word":"lacération","word_nosc":"laceration","lemma":"lacération","pos":"NOM"} ,
		{"word":"lacérations","word_nosc":"lacerations","lemma":"lacération","pos":"NOM"} ,
		{"word":"lad","word_nosc":"lad","lemma":"lad","pos":"NOM"} ,
		{"word":"ladies","word_nosc":"ladies","lemma":"ladies","pos":"NOM"} ,
		{"word":"ladino","word_nosc":"ladino","lemma":"ladino","pos":"NOM"} ,
		{"word":"ladre","word_nosc":"ladre","lemma":"ladre","pos":"NOM"} ,
		{"word":"ladrerie","word_nosc":"ladrerie","lemma":"ladrerie","pos":"NOM"} ,
		{"word":"ladreries","word_nosc":"ladreries","lemma":"ladrerie","pos":"NOM"} ,
		{"word":"ladres","word_nosc":"ladres","lemma":"ladre","pos":"NOM"} ,
		{"word":"lads","word_nosc":"lads","lemma":"lad","pos":"NOM"} ,
		{"word":"lady","word_nosc":"lady","lemma":"lady","pos":"NOM"} ,
		{"word":"ladys","word_nosc":"ladys","lemma":"lady","pos":"NOM"} ,
		{"word":"lagan","word_nosc":"lagan","lemma":"lagan","pos":"NOM"} ,
		{"word":"lagon","word_nosc":"lagon","lemma":"lagon","pos":"NOM"} ,
		{"word":"lagons","word_nosc":"lagons","lemma":"lagon","pos":"NOM"} ,
		{"word":"laguiole","word_nosc":"laguiole","lemma":"laguiole","pos":"NOM"} ,
		{"word":"lagune","word_nosc":"lagune","lemma":"lagune","pos":"NOM"} ,
		{"word":"lagunes","word_nosc":"lagunes","lemma":"lagune","pos":"NOM"} ,
		{"word":"lai","word_nosc":"lai","lemma":"lai","pos":"NOM"} ,
		{"word":"laid","word_nosc":"laid","lemma":"laid","pos":"NOM"} ,
		{"word":"laide","word_nosc":"laide","lemma":"laid","pos":"NOM"} ,
		{"word":"laideron","word_nosc":"laideron","lemma":"laideron","pos":"NOM"} ,
		{"word":"laiderons","word_nosc":"laiderons","lemma":"laideron","pos":"NOM"} ,
		{"word":"laides","word_nosc":"laides","lemma":"laid","pos":"NOM"} ,
		{"word":"laideur","word_nosc":"laideur","lemma":"laideur","pos":"NOM"} ,
		{"word":"laideurs","word_nosc":"laideurs","lemma":"laideur","pos":"NOM"} ,
		{"word":"laids","word_nosc":"laids","lemma":"laid","pos":"NOM"} ,
		{"word":"laie","word_nosc":"laie","lemma":"laie","pos":"NOM"} ,
		{"word":"laies","word_nosc":"laies","lemma":"laie","pos":"NOM"} ,
		{"word":"lainage","word_nosc":"lainage","lemma":"lainage","pos":"NOM"} ,
		{"word":"lainages","word_nosc":"lainages","lemma":"lainage","pos":"NOM"} ,
		{"word":"laine","word_nosc":"laine","lemma":"laine","pos":"NOM"} ,
		{"word":"laines","word_nosc":"laines","lemma":"laine","pos":"NOM"} ,
		{"word":"laineurs","word_nosc":"laineurs","lemma":"laineur","pos":"NOM"} ,
		{"word":"laineuses","word_nosc":"laineuses","lemma":"laineur","pos":"NOM"} ,
		{"word":"lainier","word_nosc":"lainier","lemma":"lainier","pos":"NOM"} ,
		{"word":"lainière","word_nosc":"lainiere","lemma":"lainière","pos":"NOM"} ,
		{"word":"laird","word_nosc":"laird","lemma":"laird","pos":"NOM"} ,
		{"word":"lais","word_nosc":"lais","lemma":"lai","pos":"NOM"} ,
		{"word":"laisse","word_nosc":"laisse","lemma":"laisse","pos":"NOM"} ,
		{"word":"laisse-la-moi","word_nosc":"laisse-la-moi","lemma":"laisse-la-moi","pos":"NOM"} ,
		{"word":"laisser-aller","word_nosc":"laisser-aller","lemma":"laisser-aller","pos":"NOM"} ,
		{"word":"laisser-faire","word_nosc":"laisser-faire","lemma":"laisser-faire","pos":"NOM"} ,
		{"word":"laisses","word_nosc":"laisses","lemma":"laisse","pos":"NOM"} ,
		{"word":"laissez-faire","word_nosc":"laissez-faire","lemma":"laissez-faire","pos":"NOM"} ,
		{"word":"laissez-passer","word_nosc":"laissez-passer","lemma":"laissez-passer","pos":"NOM"} ,
		{"word":"laissé","word_nosc":"laisse","lemma":"laissé","pos":"NOM"} ,
		{"word":"laissé-pour-compte","word_nosc":"laisse-pour-compte","lemma":"laissé-pour-compte","pos":"NOM"} ,
		{"word":"laissées","word_nosc":"laissees","lemma":"laissée","pos":"NOM"} ,
		{"word":"laissés","word_nosc":"laisses","lemma":"laissé","pos":"NOM"} ,
		{"word":"laissés-pour-compte","word_nosc":"laisses-pour-compte","lemma":"laissés-pour-compte","pos":"NOM"} ,
		{"word":"lait","word_nosc":"lait","lemma":"lait","pos":"NOM"} ,
		{"word":"laitage","word_nosc":"laitage","lemma":"laitage","pos":"NOM"} ,
		{"word":"laitages","word_nosc":"laitages","lemma":"laitage","pos":"NOM"} ,
		{"word":"laitance","word_nosc":"laitance","lemma":"laitance","pos":"NOM"} ,
		{"word":"laitances","word_nosc":"laitances","lemma":"laitance","pos":"NOM"} ,
		{"word":"laiterie","word_nosc":"laiterie","lemma":"laiterie","pos":"NOM"} ,
		{"word":"laiteries","word_nosc":"laiteries","lemma":"laiterie","pos":"NOM"} ,
		{"word":"laites","word_nosc":"laites","lemma":"laite","pos":"NOM"} ,
		{"word":"laitier","word_nosc":"laitier","lemma":"laitier","pos":"NOM"} ,
		{"word":"laitiers","word_nosc":"laitiers","lemma":"laitier","pos":"NOM"} ,
		{"word":"laitière","word_nosc":"laitiere","lemma":"laitier","pos":"NOM"} ,
		{"word":"laitières","word_nosc":"laitieres","lemma":"laitier","pos":"NOM"} ,
		{"word":"laiton","word_nosc":"laiton","lemma":"laiton","pos":"NOM"} ,
		{"word":"laits","word_nosc":"laits","lemma":"lait","pos":"NOM"} ,
		{"word":"laitue","word_nosc":"laitue","lemma":"laitue","pos":"NOM"} ,
		{"word":"laitues","word_nosc":"laitues","lemma":"laitue","pos":"NOM"} ,
		{"word":"laizes","word_nosc":"laizes","lemma":"laize","pos":"NOM"} ,
		{"word":"lama","word_nosc":"lama","lemma":"lama","pos":"NOM"} ,
		{"word":"lamantin","word_nosc":"lamantin","lemma":"lamantin","pos":"NOM"} ,
		{"word":"lamantins","word_nosc":"lamantins","lemma":"lamantin","pos":"NOM"} ,
		{"word":"lamartiniennes","word_nosc":"lamartiniennes","lemma":"lamartinien","pos":"NOM"} ,
		{"word":"lamas","word_nosc":"lamas","lemma":"lama","pos":"NOM"} ,
		{"word":"lamaïsme","word_nosc":"lamaisme","lemma":"lamaïsme","pos":"NOM"} ,
		{"word":"lambada","word_nosc":"lambada","lemma":"lambada","pos":"NOM"} ,
		{"word":"lambda","word_nosc":"lambda","lemma":"lambda","pos":"NOM"} ,
		{"word":"lambdas","word_nosc":"lambdas","lemma":"lambda","pos":"NOM"} ,
		{"word":"lambeau","word_nosc":"lambeau","lemma":"lambeau","pos":"NOM"} ,
		{"word":"lambeaux","word_nosc":"lambeaux","lemma":"lambeau","pos":"NOM"} ,
		{"word":"lambel","word_nosc":"lambel","lemma":"lambel","pos":"NOM"} ,
		{"word":"lambi","word_nosc":"lambi","lemma":"lambi","pos":"NOM"} ,
		{"word":"lambic","word_nosc":"lambic","lemma":"lambic","pos":"NOM"} ,
		{"word":"lambin","word_nosc":"lambin","lemma":"lambin","pos":"NOM"} ,
		{"word":"lambins","word_nosc":"lambins","lemma":"lambin","pos":"NOM"} ,
		{"word":"lambrequins","word_nosc":"lambrequins","lemma":"lambrequin","pos":"NOM"} ,
		{"word":"lambris","word_nosc":"lambris","lemma":"lambris","pos":"NOM"} ,
		{"word":"lambrissage","word_nosc":"lambrissage","lemma":"lambrissage","pos":"NOM"} ,
		{"word":"lambrusques","word_nosc":"lambrusques","lemma":"lambrusque","pos":"NOM"} ,
		{"word":"lambswool","word_nosc":"lambswool","lemma":"lambswool","pos":"NOM"} ,
		{"word":"lame","word_nosc":"lame","lemma":"lame","pos":"NOM"} ,
		{"word":"lamedu","word_nosc":"lamedu","lemma":"lamedu","pos":"NOM"} ,
		{"word":"lamedé","word_nosc":"lamede","lemma":"lamedé","pos":"NOM"} ,
		{"word":"lamedés","word_nosc":"lamedes","lemma":"lamedé","pos":"NOM"} ,
		{"word":"lamelle","word_nosc":"lamelle","lemma":"lamelle","pos":"NOM"} ,
		{"word":"lamelles","word_nosc":"lamelles","lemma":"lamelle","pos":"NOM"} ,
		{"word":"lamellibranches","word_nosc":"lamellibranches","lemma":"lamellibranche","pos":"NOM"} ,
		{"word":"lamentation","word_nosc":"lamentation","lemma":"lamentation","pos":"NOM"} ,
		{"word":"lamentations","word_nosc":"lamentations","lemma":"lamentation","pos":"NOM"} ,
		{"word":"lamento","word_nosc":"lamento","lemma":"lamento","pos":"NOM"} ,
		{"word":"lames","word_nosc":"lames","lemma":"lame","pos":"NOM"} ,
		{"word":"lamie","word_nosc":"lamie","lemma":"lamie","pos":"NOM"} ,
		{"word":"laminage","word_nosc":"laminage","lemma":"laminage","pos":"NOM"} ,
		{"word":"laminages","word_nosc":"laminages","lemma":"laminage","pos":"NOM"} ,
		{"word":"laminaire","word_nosc":"laminaire","lemma":"laminaire","pos":"NOM"} ,
		{"word":"laminaires","word_nosc":"laminaires","lemma":"laminaire","pos":"NOM"} ,
		{"word":"laminectomie","word_nosc":"laminectomie","lemma":"laminectomie","pos":"NOM"} ,
		{"word":"laminoir","word_nosc":"laminoir","lemma":"laminoir","pos":"NOM"} ,
		{"word":"laminoirs","word_nosc":"laminoirs","lemma":"laminoir","pos":"NOM"} ,
		{"word":"lampadaire","word_nosc":"lampadaire","lemma":"lampadaire","pos":"NOM"} ,
		{"word":"lampadaires","word_nosc":"lampadaires","lemma":"lampadaire","pos":"NOM"} ,
		{"word":"lamparos","word_nosc":"lamparos","lemma":"lamparo","pos":"NOM"} ,
		{"word":"lampas","word_nosc":"lampas","lemma":"lampas","pos":"NOM"} ,
		{"word":"lampe","word_nosc":"lampe","lemma":"lampe","pos":"NOM"} ,
		{"word":"lampe-phare","word_nosc":"lampe-phare","lemma":"lampe-phare","pos":"NOM"} ,
		{"word":"lampe-tempête","word_nosc":"lampe-tempete","lemma":"lampe-tempête","pos":"NOM"} ,
		{"word":"lampes","word_nosc":"lampes","lemma":"lampe","pos":"NOM"} ,
		{"word":"lampes-tempête","word_nosc":"lampes-tempete","lemma":"lampe-tempête","pos":"NOM"} ,
		{"word":"lampion","word_nosc":"lampion","lemma":"lampion","pos":"NOM"} ,
		{"word":"lampions","word_nosc":"lampions","lemma":"lampion","pos":"NOM"} ,
		{"word":"lampiste","word_nosc":"lampiste","lemma":"lampiste","pos":"NOM"} ,
		{"word":"lampisterie","word_nosc":"lampisterie","lemma":"lampisterie","pos":"NOM"} ,
		{"word":"lampisteries","word_nosc":"lampisteries","lemma":"lampisterie","pos":"NOM"} ,
		{"word":"lampistes","word_nosc":"lampistes","lemma":"lampiste","pos":"NOM"} ,
		{"word":"lampons","word_nosc":"lampons","lemma":"lampon","pos":"NOM"} ,
		{"word":"lamproie","word_nosc":"lamproie","lemma":"lamproie","pos":"NOM"} ,
		{"word":"lamproies","word_nosc":"lamproies","lemma":"lamproie","pos":"NOM"} ,
		{"word":"lampyres","word_nosc":"lampyres","lemma":"lampyre","pos":"NOM"} ,
		{"word":"lampée","word_nosc":"lampee","lemma":"lampée","pos":"NOM"} ,
		{"word":"lampées","word_nosc":"lampees","lemma":"lampée","pos":"NOM"} ,
		{"word":"lance","word_nosc":"lance","lemma":"lanc","pos":"NOM"} ,
		{"word":"lance","word_nosc":"lance","lemma":"lance","pos":"NOM"} ,
		{"word":"lance-engins","word_nosc":"lance-engins","lemma":"lance-engins","pos":"NOM"} ,
		{"word":"lance-flamme","word_nosc":"lance-flamme","lemma":"lance-flamme","pos":"NOM"} ,
		{"word":"lance-flammes","word_nosc":"lance-flammes","lemma":"lance-flammes","pos":"NOM"} ,
		{"word":"lance-fusée","word_nosc":"lance-fusee","lemma":"lance-fusée","pos":"NOM"} ,
		{"word":"lance-fusées","word_nosc":"lance-fusees","lemma":"lance-fusées","pos":"NOM"} ,
		{"word":"lance-grenade","word_nosc":"lance-grenade","lemma":"lance-grenade","pos":"NOM"} ,
		{"word":"lance-grenades","word_nosc":"lance-grenades","lemma":"lance-grenades","pos":"NOM"} ,
		{"word":"lance-missiles","word_nosc":"lance-missiles","lemma":"lance-missiles","pos":"NOM"} ,
		{"word":"lance-pierre","word_nosc":"lance-pierre","lemma":"lance-pierre","pos":"NOM"} ,
		{"word":"lance-pierres","word_nosc":"lance-pierres","lemma":"lance-pierres","pos":"NOM"} ,
		{"word":"lance-roquette","word_nosc":"lance-roquette","lemma":"lance-roquette","pos":"NOM"} ,
		{"word":"lance-roquettes","word_nosc":"lance-roquettes","lemma":"lance-roquettes","pos":"NOM"} ,
		{"word":"lance-torpille","word_nosc":"lance-torpille","lemma":"lance-torpille","pos":"NOM"} ,
		{"word":"lance-torpilles","word_nosc":"lance-torpilles","lemma":"lance-torpilles","pos":"NOM"} ,
		{"word":"lancelot","word_nosc":"lancelot","lemma":"lancelot","pos":"NOM"} ,
		{"word":"lancement","word_nosc":"lancement","lemma":"lancement","pos":"NOM"} ,
		{"word":"lancements","word_nosc":"lancements","lemma":"lancement","pos":"NOM"} ,
		{"word":"lancer","word_nosc":"lancer","lemma":"lancer","pos":"NOM"} ,
		{"word":"lancerons","word_nosc":"lancerons","lemma":"lanceron","pos":"NOM"} ,
		{"word":"lancers","word_nosc":"lancers","lemma":"lancer","pos":"NOM"} ,
		{"word":"lances","word_nosc":"lances","lemma":"lanc","pos":"NOM"} ,
		{"word":"lances","word_nosc":"lances","lemma":"lance","pos":"NOM"} ,
		{"word":"lancette","word_nosc":"lancette","lemma":"lancette","pos":"NOM"} ,
		{"word":"lanceur","word_nosc":"lanceur","lemma":"lanceur","pos":"NOM"} ,
		{"word":"lanceurs","word_nosc":"lanceurs","lemma":"lanceur","pos":"NOM"} ,
		{"word":"lanceuse","word_nosc":"lanceuse","lemma":"lanceur","pos":"NOM"} ,
		{"word":"lancier","word_nosc":"lancier","lemma":"lancier","pos":"NOM"} ,
		{"word":"lanciers","word_nosc":"lanciers","lemma":"lancier","pos":"NOM"} ,
		{"word":"lancinance","word_nosc":"lancinance","lemma":"lancinance","pos":"NOM"} ,
		{"word":"lancée","word_nosc":"lancee","lemma":"lancée","pos":"NOM"} ,
		{"word":"lancées","word_nosc":"lancees","lemma":"lancée","pos":"NOM"} ,
		{"word":"land","word_nosc":"land","lemma":"land","pos":"NOM"} ,
		{"word":"landaise","word_nosc":"landaise","lemma":"landais","pos":"NOM"} ,
		{"word":"landaises","word_nosc":"landaises","lemma":"landais","pos":"NOM"} ,
		{"word":"landau","word_nosc":"landau","lemma":"landau","pos":"NOM"} ,
		{"word":"landaulet","word_nosc":"landaulet","lemma":"landaulet","pos":"NOM"} ,
		{"word":"landaus","word_nosc":"landaus","lemma":"landau","pos":"NOM"} ,
		{"word":"lande","word_nosc":"lande","lemma":"lande","pos":"NOM"} ,
		{"word":"landerneau","word_nosc":"landerneau","lemma":"landerneau","pos":"NOM"} ,
		{"word":"landes","word_nosc":"landes","lemma":"lande","pos":"NOM"} ,
		{"word":"landiers","word_nosc":"landiers","lemma":"landier","pos":"NOM"} ,
		{"word":"landing","word_nosc":"landing","lemma":"landing","pos":"NOM"} ,
		{"word":"landlord","word_nosc":"landlord","lemma":"landlord","pos":"NOM"} ,
		{"word":"lands","word_nosc":"lands","lemma":"land","pos":"NOM"} ,
		{"word":"landsturm","word_nosc":"landsturm","lemma":"landsturm","pos":"NOM"} ,
		{"word":"landwehr","word_nosc":"landwehr","lemma":"landwehr","pos":"NOM"} ,
		{"word":"langage","word_nosc":"langage","lemma":"langage","pos":"NOM"} ,
		{"word":"langages","word_nosc":"langages","lemma":"langage","pos":"NOM"} ,
		{"word":"lange","word_nosc":"lange","lemma":"lange","pos":"NOM"} ,
		{"word":"langes","word_nosc":"langes","lemma":"lange","pos":"NOM"} ,
		{"word":"langouste","word_nosc":"langouste","lemma":"langouste","pos":"NOM"} ,
		{"word":"langoustes","word_nosc":"langoustes","lemma":"langouste","pos":"NOM"} ,
		{"word":"langoustine","word_nosc":"langoustine","lemma":"langoustine","pos":"NOM"} ,
		{"word":"langoustines","word_nosc":"langoustines","lemma":"langoustine","pos":"NOM"} ,
		{"word":"langue","word_nosc":"langue","lemma":"langue","pos":"NOM"} ,
		{"word":"languedociens","word_nosc":"languedociens","lemma":"languedocien","pos":"NOM"} ,
		{"word":"langues","word_nosc":"langues","lemma":"langue","pos":"NOM"} ,
		{"word":"languette","word_nosc":"languette","lemma":"languette","pos":"NOM"} ,
		{"word":"languettes","word_nosc":"languettes","lemma":"languette","pos":"NOM"} ,
		{"word":"langueur","word_nosc":"langueur","lemma":"langueur","pos":"NOM"} ,
		{"word":"langueurs","word_nosc":"langueurs","lemma":"langueur","pos":"NOM"} ,
		{"word":"lanière","word_nosc":"laniere","lemma":"lanière","pos":"NOM"} ,
		{"word":"lanières","word_nosc":"lanieres","lemma":"lanière","pos":"NOM"} ,
		{"word":"lanoline","word_nosc":"lanoline","lemma":"lanoline","pos":"NOM"} ,
		{"word":"lansquenet","word_nosc":"lansquenet","lemma":"lansquenet","pos":"NOM"} ,
		{"word":"lansquenets","word_nosc":"lansquenets","lemma":"lansquenet","pos":"NOM"} ,
		{"word":"lansquine","word_nosc":"lansquine","lemma":"lansquine","pos":"NOM"} ,
		{"word":"lanterne","word_nosc":"lanterne","lemma":"lanterne","pos":"NOM"} ,
		{"word":"lanterne-tempête","word_nosc":"lanterne-tempete","lemma":"lanterne-tempête","pos":"NOM"} ,
		{"word":"lanternes","word_nosc":"lanternes","lemma":"lanterne","pos":"NOM"} ,
		{"word":"lanternier","word_nosc":"lanternier","lemma":"lanternier","pos":"NOM"} ,
		{"word":"lanthane","word_nosc":"lanthane","lemma":"lanthane","pos":"NOM"} ,
		{"word":"lançons","word_nosc":"lancons","lemma":"lançon","pos":"NOM"} ,
		{"word":"lao","word_nosc":"lao","lemma":"lao","pos":"NOM"} ,
		{"word":"laos","word_nosc":"laos","lemma":"lao","pos":"NOM"} ,
		{"word":"laotiens","word_nosc":"laotiens","lemma":"laotien","pos":"NOM"} ,
		{"word":"lap","word_nosc":"lap","lemma":"lap","pos":"NOM"} ,
		{"word":"lapalissade","word_nosc":"lapalissade","lemma":"lapalissade","pos":"NOM"} ,
		{"word":"laparoscopie","word_nosc":"laparoscopie","lemma":"laparoscopie","pos":"NOM"} ,
		{"word":"laparotomie","word_nosc":"laparotomie","lemma":"laparotomie","pos":"NOM"} ,
		{"word":"lapement","word_nosc":"lapement","lemma":"lapement","pos":"NOM"} ,
		{"word":"lapements","word_nosc":"lapements","lemma":"lapement","pos":"NOM"} ,
		{"word":"lapereau","word_nosc":"lapereau","lemma":"lapereau","pos":"NOM"} ,
		{"word":"lapereaux","word_nosc":"lapereaux","lemma":"lapereau","pos":"NOM"} ,
		{"word":"lapidaire","word_nosc":"lapidaire","lemma":"lapidaire","pos":"NOM"} ,
		{"word":"lapidation","word_nosc":"lapidation","lemma":"lapidation","pos":"NOM"} ,
		{"word":"lapidations","word_nosc":"lapidations","lemma":"lapidation","pos":"NOM"} ,
		{"word":"lapidification","word_nosc":"lapidification","lemma":"lapidification","pos":"NOM"} ,
		{"word":"lapin","word_nosc":"lapin","lemma":"lapin","pos":"NOM"} ,
		{"word":"lapine","word_nosc":"lapine","lemma":"lapin","pos":"NOM"} ,
		{"word":"lapines","word_nosc":"lapines","lemma":"lapin","pos":"NOM"} ,
		{"word":"lapinière","word_nosc":"lapiniere","lemma":"lapinière","pos":"NOM"} ,
		{"word":"lapins","word_nosc":"lapins","lemma":"lapin","pos":"NOM"} ,
		{"word":"lapis","word_nosc":"lapis","lemma":"lapis","pos":"NOM"} ,
		{"word":"lapis-lazuli","word_nosc":"lapis-lazuli","lemma":"lapis-lazuli","pos":"NOM"} ,
		{"word":"lapon","word_nosc":"lapon","lemma":"lapon","pos":"NOM"} ,
		{"word":"laponne","word_nosc":"laponne","lemma":"lapon","pos":"NOM"} ,
		{"word":"lapons","word_nosc":"lapons","lemma":"lapon","pos":"NOM"} ,
		{"word":"laps","word_nosc":"laps","lemma":"laps","pos":"NOM"} ,
		{"word":"lapsus","word_nosc":"lapsus","lemma":"lapsus","pos":"NOM"} ,
		{"word":"laquais","word_nosc":"laquais","lemma":"laquais","pos":"NOM"} ,
		{"word":"laque","word_nosc":"laque","lemma":"laque","pos":"NOM"} ,
		{"word":"laques","word_nosc":"laques","lemma":"laque","pos":"NOM"} ,
		{"word":"larbin","word_nosc":"larbin","lemma":"larbin","pos":"NOM"} ,
		{"word":"larbineries","word_nosc":"larbineries","lemma":"larbinerie","pos":"NOM"} ,
		{"word":"larbins","word_nosc":"larbins","lemma":"larbin","pos":"NOM"} ,
		{"word":"larcin","word_nosc":"larcin","lemma":"larcin","pos":"NOM"} ,
		{"word":"larcins","word_nosc":"larcins","lemma":"larcin","pos":"NOM"} ,
		{"word":"lard","word_nosc":"lard","lemma":"lard","pos":"NOM"} ,
		{"word":"lardage","word_nosc":"lardage","lemma":"lardage","pos":"NOM"} ,
		{"word":"lardeuss","word_nosc":"lardeuss","lemma":"lardeuss","pos":"NOM"} ,
		{"word":"lardon","word_nosc":"lardon","lemma":"lardon","pos":"NOM"} ,
		{"word":"lardons","word_nosc":"lardons","lemma":"lardon","pos":"NOM"} ,
		{"word":"lards","word_nosc":"lards","lemma":"lard","pos":"NOM"} ,
		{"word":"lardu","word_nosc":"lardu","lemma":"lardu","pos":"NOM"} ,
		{"word":"lardus","word_nosc":"lardus","lemma":"lardu","pos":"NOM"} ,
		{"word":"lares","word_nosc":"lares","lemma":"lare","pos":"NOM"} ,
		{"word":"larfeuil","word_nosc":"larfeuil","lemma":"larfeuil","pos":"NOM"} ,
		{"word":"larfeuille","word_nosc":"larfeuille","lemma":"larfeuille","pos":"NOM"} ,
		{"word":"larfeuilles","word_nosc":"larfeuilles","lemma":"larfeuille","pos":"NOM"} ,
		{"word":"larfeuils","word_nosc":"larfeuils","lemma":"larfeuil","pos":"NOM"} ,
		{"word":"largage","word_nosc":"largage","lemma":"largage","pos":"NOM"} ,
		{"word":"largages","word_nosc":"largages","lemma":"largage","pos":"NOM"} ,
		{"word":"large","word_nosc":"large","lemma":"large","pos":"NOM"} ,
		{"word":"larges","word_nosc":"larges","lemma":"large","pos":"NOM"} ,
		{"word":"largesse","word_nosc":"largesse","lemma":"largesse","pos":"NOM"} ,
		{"word":"largesses","word_nosc":"largesses","lemma":"largesse","pos":"NOM"} ,
		{"word":"largeur","word_nosc":"largeur","lemma":"largeur","pos":"NOM"} ,
		{"word":"largeurs","word_nosc":"largeurs","lemma":"largeur","pos":"NOM"} ,
		{"word":"larghetto","word_nosc":"larghetto","lemma":"larghetto","pos":"NOM"} ,
		{"word":"largo","word_nosc":"largo","lemma":"largo","pos":"NOM"} ,
		{"word":"largue","word_nosc":"largue","lemma":"largue","pos":"NOM"} ,
		{"word":"largues","word_nosc":"largues","lemma":"largue","pos":"NOM"} ,
		{"word":"largueur","word_nosc":"largueur","lemma":"largueur","pos":"NOM"} ,
		{"word":"larigot","word_nosc":"larigot","lemma":"larigot","pos":"NOM"} ,
		{"word":"larme","word_nosc":"larme","lemma":"larme","pos":"NOM"} ,
		{"word":"larmes","word_nosc":"larmes","lemma":"larme","pos":"NOM"} ,
		{"word":"larmichette","word_nosc":"larmichette","lemma":"larmichette","pos":"NOM"} ,
		{"word":"larmichettes","word_nosc":"larmichettes","lemma":"larmichette","pos":"NOM"} ,
		{"word":"larmiers","word_nosc":"larmiers","lemma":"larmier","pos":"NOM"} ,
		{"word":"larmoiement","word_nosc":"larmoiement","lemma":"larmoiement","pos":"NOM"} ,
		{"word":"larmoiements","word_nosc":"larmoiements","lemma":"larmoiement","pos":"NOM"} ,
		{"word":"larron","word_nosc":"larron","lemma":"larron","pos":"NOM"} ,
		{"word":"larrons","word_nosc":"larrons","lemma":"larron","pos":"NOM"} ,
		{"word":"larsen","word_nosc":"larsen","lemma":"larsen","pos":"NOM"} ,
		{"word":"larve","word_nosc":"larve","lemma":"larve","pos":"NOM"} ,
		{"word":"larves","word_nosc":"larves","lemma":"larve","pos":"NOM"} ,
		{"word":"larvicide","word_nosc":"larvicide","lemma":"larvicide","pos":"NOM"} ,
		{"word":"laryngite","word_nosc":"laryngite","lemma":"laryngite","pos":"NOM"} ,
		{"word":"laryngologiste","word_nosc":"laryngologiste","lemma":"laryngologiste","pos":"NOM"} ,
		{"word":"laryngoscope","word_nosc":"laryngoscope","lemma":"laryngoscope","pos":"NOM"} ,
		{"word":"laryngoscopes","word_nosc":"laryngoscopes","lemma":"laryngoscope","pos":"NOM"} ,
		{"word":"laryngoscopie","word_nosc":"laryngoscopie","lemma":"laryngoscopie","pos":"NOM"} ,
		{"word":"larynx","word_nosc":"larynx","lemma":"larynx","pos":"NOM"} ,
		{"word":"lasagne","word_nosc":"lasagne","lemma":"lasagne","pos":"NOM"} ,
		{"word":"lasagnes","word_nosc":"lasagnes","lemma":"lasagne","pos":"NOM"} ,
		{"word":"lascar","word_nosc":"lascar","lemma":"lascar","pos":"NOM"} ,
		{"word":"lascars","word_nosc":"lascars","lemma":"lascar","pos":"NOM"} ,
		{"word":"lascivité","word_nosc":"lascivite","lemma":"lascivité","pos":"NOM"} ,
		{"word":"lascivités","word_nosc":"lascivites","lemma":"lascivité","pos":"NOM"} ,
		{"word":"laser","word_nosc":"laser","lemma":"laser","pos":"NOM"} ,
		{"word":"lasers","word_nosc":"lasers","lemma":"laser","pos":"NOM"} ,
		{"word":"lassitude","word_nosc":"lassitude","lemma":"lassitude","pos":"NOM"} ,
		{"word":"lassitudes","word_nosc":"lassitudes","lemma":"lassitude","pos":"NOM"} ,
		{"word":"lasso","word_nosc":"lasso","lemma":"lasso","pos":"NOM"} ,
		{"word":"lassos","word_nosc":"lassos","lemma":"lasso","pos":"NOM"} ,
		{"word":"lasure","word_nosc":"lasure","lemma":"lasure","pos":"NOM"} ,
		{"word":"latence","word_nosc":"latence","lemma":"latence","pos":"NOM"} ,
		{"word":"latences","word_nosc":"latences","lemma":"latence","pos":"NOM"} ,
		{"word":"latex","word_nosc":"latex","lemma":"latex","pos":"NOM"} ,
		{"word":"laticlave","word_nosc":"laticlave","lemma":"laticlave","pos":"NOM"} ,
		{"word":"latifundistes","word_nosc":"latifundistes","lemma":"latifundiste","pos":"NOM"} ,
		{"word":"latin","word_nosc":"latin","lemma":"latin","pos":"NOM"} ,
		{"word":"latine","word_nosc":"latine","lemma":"latin","pos":"NOM"} ,
		{"word":"latines","word_nosc":"latines","lemma":"latin","pos":"NOM"} ,
		{"word":"latinismes","word_nosc":"latinismes","lemma":"latinisme","pos":"NOM"} ,
		{"word":"latiniste","word_nosc":"latiniste","lemma":"latiniste","pos":"NOM"} ,
		{"word":"latinistes","word_nosc":"latinistes","lemma":"latiniste","pos":"NOM"} ,
		{"word":"latinité","word_nosc":"latinite","lemma":"latinité","pos":"NOM"} ,
		{"word":"latinités","word_nosc":"latinites","lemma":"latinité","pos":"NOM"} ,
		{"word":"latino","word_nosc":"latino","lemma":"latino","pos":"NOM"} ,
		{"word":"latino-américain","word_nosc":"latino-americain","lemma":"latino-américain","pos":"NOM"} ,
		{"word":"latino-américaine","word_nosc":"latino-americaine","lemma":"latino-américain","pos":"NOM"} ,
		{"word":"latino-américains","word_nosc":"latino-americains","lemma":"latino-américain","pos":"NOM"} ,
		{"word":"latinos","word_nosc":"latinos","lemma":"latino","pos":"NOM"} ,
		{"word":"latins","word_nosc":"latins","lemma":"latin","pos":"NOM"} ,
		{"word":"latitude","word_nosc":"latitude","lemma":"latitude","pos":"NOM"} ,
		{"word":"latitudes","word_nosc":"latitudes","lemma":"latitude","pos":"NOM"} ,
		{"word":"latrine","word_nosc":"latrine","lemma":"latrine","pos":"NOM"} ,
		{"word":"latrines","word_nosc":"latrines","lemma":"latrine","pos":"NOM"} ,
		{"word":"lats","word_nosc":"lats","lemma":"lats","pos":"NOM"} ,
		{"word":"latte","word_nosc":"latte","lemma":"latte","pos":"NOM"} ,
		{"word":"lattes","word_nosc":"lattes","lemma":"latte","pos":"NOM"} ,
		{"word":"lattis","word_nosc":"lattis","lemma":"lattis","pos":"NOM"} ,
		{"word":"latviens","word_nosc":"latviens","lemma":"latvien","pos":"NOM"} ,
		{"word":"latéralité","word_nosc":"lateralite","lemma":"latéralité","pos":"NOM"} ,
		{"word":"latérite","word_nosc":"laterite","lemma":"latérite","pos":"NOM"} ,
		{"word":"laubé","word_nosc":"laube","lemma":"laubé","pos":"NOM"} ,
		{"word":"laudanum","word_nosc":"laudanum","lemma":"laudanum","pos":"NOM"} ,
		{"word":"laudes","word_nosc":"laudes","lemma":"laude","pos":"NOM"} ,
		{"word":"laurier","word_nosc":"laurier","lemma":"laurier","pos":"NOM"} ,
		{"word":"laurier-cerise","word_nosc":"laurier-cerise","lemma":"laurier-cerise","pos":"NOM"} ,
		{"word":"laurier-rose","word_nosc":"laurier-rose","lemma":"laurier-rose","pos":"NOM"} ,
		{"word":"lauriers","word_nosc":"lauriers","lemma":"laurier","pos":"NOM"} ,
		{"word":"lauriers-cerises","word_nosc":"lauriers-cerises","lemma":"laurier-cerise","pos":"NOM"} ,
		{"word":"lauriers-roses","word_nosc":"lauriers-roses","lemma":"laurier-rose","pos":"NOM"} ,
		{"word":"lauréat","word_nosc":"laureat","lemma":"lauréat","pos":"NOM"} ,
		{"word":"lauréats","word_nosc":"laureats","lemma":"lauréat","pos":"NOM"} ,
		{"word":"lauzes","word_nosc":"lauzes","lemma":"lauze","pos":"NOM"} ,
		{"word":"lav","word_nosc":"lav","lemma":"lav","pos":"NOM"} ,
		{"word":"lavabo","word_nosc":"lavabo","lemma":"lavabo","pos":"NOM"} ,
		{"word":"lavabos","word_nosc":"lavabos","lemma":"lavabo","pos":"NOM"} ,
		{"word":"lavage","word_nosc":"lavage","lemma":"lavage","pos":"NOM"} ,
		{"word":"lavages","word_nosc":"lavages","lemma":"lavage","pos":"NOM"} ,
		{"word":"lavallière","word_nosc":"lavalliere","lemma":"lavallière","pos":"NOM"} ,
		{"word":"lavallières","word_nosc":"lavallieres","lemma":"lavallière","pos":"NOM"} ,
		{"word":"lavande","word_nosc":"lavande","lemma":"lavande","pos":"NOM"} ,
		{"word":"lavandes","word_nosc":"lavandes","lemma":"lavande","pos":"NOM"} ,
		{"word":"lavandin","word_nosc":"lavandin","lemma":"lavandin","pos":"NOM"} ,
		{"word":"lavandière","word_nosc":"lavandiere","lemma":"lavandière","pos":"NOM"} ,
		{"word":"lavandières","word_nosc":"lavandieres","lemma":"lavandière","pos":"NOM"} ,
		{"word":"lavasse","word_nosc":"lavasse","lemma":"lavasse","pos":"NOM"} ,
		{"word":"lave","word_nosc":"lave","lemma":"lave","pos":"NOM"} ,
		{"word":"lave-auto","word_nosc":"lave-auto","lemma":"lave-auto","pos":"NOM"} ,
		{"word":"lave-glace","word_nosc":"lave-glace","lemma":"lave-glace","pos":"NOM"} ,
		{"word":"lave-linge","word_nosc":"lave-linge","lemma":"lave-linge","pos":"NOM"} ,
		{"word":"lave-mains","word_nosc":"lave-mains","lemma":"lave-mains","pos":"NOM"} ,
		{"word":"lave-pont","word_nosc":"lave-pont","lemma":"lave-pont","pos":"NOM"} ,
		{"word":"lave-ponts","word_nosc":"lave-ponts","lemma":"lave-pont","pos":"NOM"} ,
		{"word":"lave-vaisselle","word_nosc":"lave-vaisselle","lemma":"lave-vaisselle","pos":"NOM"} ,
		{"word":"lavedu","word_nosc":"lavedu","lemma":"lavedu","pos":"NOM"} ,
		{"word":"lavement","word_nosc":"lavement","lemma":"lavement","pos":"NOM"} ,
		{"word":"lavements","word_nosc":"lavements","lemma":"lavement","pos":"NOM"} ,
		{"word":"laverie","word_nosc":"laverie","lemma":"laverie","pos":"NOM"} ,
		{"word":"laveries","word_nosc":"laveries","lemma":"laverie","pos":"NOM"} ,
		{"word":"laves","word_nosc":"laves","lemma":"lave","pos":"NOM"} ,
		{"word":"lavette","word_nosc":"lavette","lemma":"lavette","pos":"NOM"} ,
		{"word":"lavettes","word_nosc":"lavettes","lemma":"lavette","pos":"NOM"} ,
		{"word":"laveur","word_nosc":"laveur","lemma":"laveur","pos":"NOM"} ,
		{"word":"laveurs","word_nosc":"laveurs","lemma":"laveur","pos":"NOM"} ,
		{"word":"laveuse","word_nosc":"laveuse","lemma":"laveur","pos":"NOM"} ,
		{"word":"laveuses","word_nosc":"laveuses","lemma":"laveur","pos":"NOM"} ,
		{"word":"lavis","word_nosc":"lavis","lemma":"lavis","pos":"NOM"} ,
		{"word":"lavoir","word_nosc":"lavoir","lemma":"lavoir","pos":"NOM"} ,
		{"word":"lavoirs","word_nosc":"lavoirs","lemma":"lavoir","pos":"NOM"} ,
		{"word":"lavure","word_nosc":"lavure","lemma":"lavure","pos":"NOM"} ,
		{"word":"lavures","word_nosc":"lavures","lemma":"lavure","pos":"NOM"} ,
		{"word":"laxatif","word_nosc":"laxatif","lemma":"laxatif","pos":"NOM"} ,
		{"word":"laxatifs","word_nosc":"laxatifs","lemma":"laxatif","pos":"NOM"} ,
		{"word":"laxisme","word_nosc":"laxisme","lemma":"laxisme","pos":"NOM"} ,
		{"word":"laxistes","word_nosc":"laxistes","lemma":"laxiste","pos":"NOM"} ,
		{"word":"laxité","word_nosc":"laxite","lemma":"laxité","pos":"NOM"} ,
		{"word":"layette","word_nosc":"layette","lemma":"layette","pos":"NOM"} ,
		{"word":"layettes","word_nosc":"layettes","lemma":"layette","pos":"NOM"} ,
		{"word":"layon","word_nosc":"layon","lemma":"layon","pos":"NOM"} ,
		{"word":"layons","word_nosc":"layons","lemma":"layon","pos":"NOM"} ,
		{"word":"lazaret","word_nosc":"lazaret","lemma":"lazaret","pos":"NOM"} ,
		{"word":"lazarets","word_nosc":"lazarets","lemma":"lazaret","pos":"NOM"} ,
		{"word":"lazaro","word_nosc":"lazaro","lemma":"lazaro","pos":"NOM"} ,
		{"word":"lazingue","word_nosc":"lazingue","lemma":"lazingue","pos":"NOM"} ,
		{"word":"lazzi","word_nosc":"lazzi","lemma":"lazzi","pos":"NOM"} ,
		{"word":"lazzis","word_nosc":"lazzis","lemma":"lazzi","pos":"NOM"} ,
		{"word":"laçage","word_nosc":"lacage","lemma":"laçage","pos":"NOM"} ,
		{"word":"laîche","word_nosc":"laiche","lemma":"laîche","pos":"NOM"} ,
		{"word":"laîches","word_nosc":"laiches","lemma":"laîche","pos":"NOM"} ,
		{"word":"laïc","word_nosc":"laic","lemma":"laïc","pos":"NOM"} ,
		{"word":"laïcité","word_nosc":"laicite","lemma":"laïcité","pos":"NOM"} ,
		{"word":"laïcs","word_nosc":"laics","lemma":"laïc","pos":"NOM"} ,
		{"word":"laïque","word_nosc":"laique","lemma":"laïque","pos":"NOM"} ,
		{"word":"laïques","word_nosc":"laiques","lemma":"laïque","pos":"NOM"} ,
		{"word":"laïus","word_nosc":"laius","lemma":"laïus","pos":"NOM"} ,
		{"word":"leader","word_nosc":"leader","lemma":"leader","pos":"NOM"} ,
		{"word":"leaders","word_nosc":"leaders","lemma":"leader","pos":"NOM"} ,
		{"word":"leadership","word_nosc":"leadership","lemma":"leadership","pos":"NOM"} ,
		{"word":"leasing","word_nosc":"leasing","lemma":"leasing","pos":"NOM"} ,
		{"word":"lebel","word_nosc":"lebel","lemma":"lebel","pos":"NOM"} ,
		{"word":"lebels","word_nosc":"lebels","lemma":"lebel","pos":"NOM"} ,
		{"word":"leben","word_nosc":"leben","lemma":"leben","pos":"NOM"} ,
		{"word":"lecteur","word_nosc":"lecteur","lemma":"lecteur","pos":"NOM"} ,
		{"word":"lecteurs","word_nosc":"lecteurs","lemma":"lecteur","pos":"NOM"} ,
		{"word":"lectorat","word_nosc":"lectorat","lemma":"lectorat","pos":"NOM"} ,
		{"word":"lectrice","word_nosc":"lectrice","lemma":"lecteur","pos":"NOM"} ,
		{"word":"lectrices","word_nosc":"lectrices","lemma":"lecteur","pos":"NOM"} ,
		{"word":"lecture","word_nosc":"lecture","lemma":"lecture","pos":"NOM"} ,
		{"word":"lectures","word_nosc":"lectures","lemma":"lecture","pos":"NOM"} ,
		{"word":"leggins","word_nosc":"leggins","lemma":"leggins","pos":"NOM"} ,
		{"word":"leghorns","word_nosc":"leghorns","lemma":"leghorn","pos":"NOM"} ,
		{"word":"lego","word_nosc":"lego","lemma":"lego","pos":"NOM"} ,
		{"word":"legos","word_nosc":"legos","lemma":"lego","pos":"NOM"} ,
		{"word":"legs","word_nosc":"legs","lemma":"legs","pos":"NOM"} ,
		{"word":"lei","word_nosc":"lei","lemma":"lei","pos":"NOM"} ,
		{"word":"leishmaniose","word_nosc":"leishmaniose","lemma":"leishmaniose","pos":"NOM"} ,
		{"word":"leitmotiv","word_nosc":"leitmotiv","lemma":"leitmotiv","pos":"NOM"} ,
		{"word":"leitmotive","word_nosc":"leitmotive","lemma":"leitmotive","pos":"NOM"} ,
		{"word":"lek","word_nosc":"lek","lemma":"lek","pos":"NOM"} ,
		{"word":"lem","word_nosc":"lem","lemma":"lem","pos":"NOM"} ,
		{"word":"lemmes","word_nosc":"lemmes","lemma":"lemme","pos":"NOM"} ,
		{"word":"lemming","word_nosc":"lemming","lemma":"lemming","pos":"NOM"} ,
		{"word":"lemmings","word_nosc":"lemmings","lemma":"lemming","pos":"NOM"} ,
		{"word":"lendemain","word_nosc":"lendemain","lemma":"lendemain","pos":"NOM"} ,
		{"word":"lendemains","word_nosc":"lendemains","lemma":"lendemain","pos":"NOM"} ,
		{"word":"lente","word_nosc":"lente","lemma":"lente","pos":"NOM"} ,
		{"word":"lentes","word_nosc":"lentes","lemma":"lente","pos":"NOM"} ,
		{"word":"lenteur","word_nosc":"lenteur","lemma":"lenteur","pos":"NOM"} ,
		{"word":"lenteurs","word_nosc":"lenteurs","lemma":"lenteur","pos":"NOM"} ,
		{"word":"lenticules","word_nosc":"lenticules","lemma":"lenticule","pos":"NOM"} ,
		{"word":"lentille","word_nosc":"lentille","lemma":"lentille","pos":"NOM"} ,
		{"word":"lentilles","word_nosc":"lentilles","lemma":"lentille","pos":"NOM"} ,
		{"word":"lentisque","word_nosc":"lentisque","lemma":"lentisque","pos":"NOM"} ,
		{"word":"lentisques","word_nosc":"lentisques","lemma":"lentisque","pos":"NOM"} ,
		{"word":"lepton","word_nosc":"lepton","lemma":"lepton","pos":"NOM"} ,
		{"word":"leptons","word_nosc":"leptons","lemma":"lepton","pos":"NOM"} ,
		{"word":"lesbianisme","word_nosc":"lesbianisme","lemma":"lesbianisme","pos":"NOM"} ,
		{"word":"lesbien","word_nosc":"lesbien","lemma":"lesbien","pos":"NOM"} ,
		{"word":"lesbienne","word_nosc":"lesbienne","lemma":"lesbien","pos":"NOM"} ,
		{"word":"lesbiennes","word_nosc":"lesbiennes","lemma":"lesbien","pos":"NOM"} ,
		{"word":"lessivage","word_nosc":"lessivage","lemma":"lessivage","pos":"NOM"} ,
		{"word":"lessive","word_nosc":"lessive","lemma":"lessive","pos":"NOM"} ,
		{"word":"lessives","word_nosc":"lessives","lemma":"lessive","pos":"NOM"} ,
		{"word":"lessiveuse","word_nosc":"lessiveuse","lemma":"lessiveur","pos":"NOM"} ,
		{"word":"lessiveuses","word_nosc":"lessiveuses","lemma":"lessiveur","pos":"NOM"} ,
		{"word":"lest","word_nosc":"lest","lemma":"lest","pos":"NOM"} ,
		{"word":"letchis","word_nosc":"letchis","lemma":"letchi","pos":"NOM"} ,
		{"word":"lette","word_nosc":"lette","lemma":"lette","pos":"NOM"} ,
		{"word":"lettes","word_nosc":"lettes","lemma":"lette","pos":"NOM"} ,
		{"word":"letton","word_nosc":"letton","lemma":"letton","pos":"NOM"} ,
		{"word":"lettone","word_nosc":"lettone","lemma":"lettone","pos":"NOM"} ,
		{"word":"lettons","word_nosc":"lettons","lemma":"letton","pos":"NOM"} ,
		{"word":"lettrage","word_nosc":"lettrage","lemma":"lettrage","pos":"NOM"} ,
		{"word":"lettre","word_nosc":"lettre","lemma":"lettre","pos":"NOM"} ,
		{"word":"lettre-clé","word_nosc":"lettre-cle","lemma":"lettre-clé","pos":"NOM"} ,
		{"word":"lettres","word_nosc":"lettres","lemma":"lettre","pos":"NOM"} ,
		{"word":"lettreur","word_nosc":"lettreur","lemma":"lettreur","pos":"NOM"} ,
		{"word":"lettrine","word_nosc":"lettrine","lemma":"lettrine","pos":"NOM"} ,
		{"word":"lettrines","word_nosc":"lettrines","lemma":"lettrine","pos":"NOM"} ,
		{"word":"lettré","word_nosc":"lettre","lemma":"lettré","pos":"NOM"} ,
		{"word":"lettrés","word_nosc":"lettres","lemma":"lettré","pos":"NOM"} ,
		{"word":"leu","word_nosc":"leu","lemma":"leu","pos":"NOM"} ,
		{"word":"leucocyte","word_nosc":"leucocyte","lemma":"leucocyte","pos":"NOM"} ,
		{"word":"leucocytes","word_nosc":"leucocytes","lemma":"leucocyte","pos":"NOM"} ,
		{"word":"leucocytose","word_nosc":"leucocytose","lemma":"leucocytose","pos":"NOM"} ,
		{"word":"leucoplasie","word_nosc":"leucoplasie","lemma":"leucoplasie","pos":"NOM"} ,
		{"word":"leucopénie","word_nosc":"leucopenie","lemma":"leucopénie","pos":"NOM"} ,
		{"word":"leucose","word_nosc":"leucose","lemma":"leucose","pos":"NOM"} ,
		{"word":"leucotomie","word_nosc":"leucotomie","lemma":"leucotomie","pos":"NOM"} ,
		{"word":"leucémie","word_nosc":"leucemie","lemma":"leucémie","pos":"NOM"} ,
		{"word":"leucémies","word_nosc":"leucemies","lemma":"leucémie","pos":"NOM"} ,
		{"word":"leucémique","word_nosc":"leucemique","lemma":"leucémique","pos":"NOM"} ,
		{"word":"leurre","word_nosc":"leurre","lemma":"leurre","pos":"NOM"} ,
		{"word":"leurres","word_nosc":"leurres","lemma":"leurre","pos":"NOM"} ,
		{"word":"lev","word_nosc":"lev","lemma":"lev","pos":"NOM"} ,
		{"word":"leva","word_nosc":"leva","lemma":"leva","pos":"NOM"} ,
		{"word":"levage","word_nosc":"levage","lemma":"levage","pos":"NOM"} ,
		{"word":"levain","word_nosc":"levain","lemma":"levain","pos":"NOM"} ,
		{"word":"levant","word_nosc":"levant","lemma":"levant","pos":"NOM"} ,
		{"word":"levantin","word_nosc":"levantin","lemma":"levantin","pos":"NOM"} ,
		{"word":"levantines","word_nosc":"levantines","lemma":"levantin","pos":"NOM"} ,
		{"word":"levantins","word_nosc":"levantins","lemma":"levantin","pos":"NOM"} ,
		{"word":"lever","word_nosc":"lever","lemma":"lever","pos":"NOM"} ,
		{"word":"levers","word_nosc":"levers","lemma":"lever","pos":"NOM"} ,
		{"word":"leveur","word_nosc":"leveur","lemma":"leveur","pos":"NOM"} ,
		{"word":"leveurs","word_nosc":"leveurs","lemma":"leveur","pos":"NOM"} ,
		{"word":"leveuse","word_nosc":"leveuse","lemma":"leveur","pos":"NOM"} ,
		{"word":"levier","word_nosc":"levier","lemma":"levier","pos":"NOM"} ,
		{"word":"leviers","word_nosc":"leviers","lemma":"levier","pos":"NOM"} ,
		{"word":"levreau","word_nosc":"levreau","lemma":"levreau","pos":"NOM"} ,
		{"word":"levreaux","word_nosc":"levreaux","lemma":"levreau","pos":"NOM"} ,
		{"word":"levrette","word_nosc":"levrette","lemma":"levrette","pos":"NOM"} ,
		{"word":"levrettes","word_nosc":"levrettes","lemma":"levrette","pos":"NOM"} ,
		{"word":"levure","word_nosc":"levure","lemma":"levure","pos":"NOM"} ,
		{"word":"levures","word_nosc":"levures","lemma":"levure","pos":"NOM"} ,
		{"word":"levé","word_nosc":"leve","lemma":"levé","pos":"NOM"} ,
		{"word":"levée","word_nosc":"levee","lemma":"levée","pos":"NOM"} ,
		{"word":"levées","word_nosc":"levees","lemma":"levée","pos":"NOM"} ,
		{"word":"levés","word_nosc":"leves","lemma":"levé","pos":"NOM"} ,
		{"word":"lexicographes","word_nosc":"lexicographes","lemma":"lexicographe","pos":"NOM"} ,
		{"word":"lexie","word_nosc":"lexie","lemma":"lexie","pos":"NOM"} ,
		{"word":"lexique","word_nosc":"lexique","lemma":"lexique","pos":"NOM"} ,
		{"word":"lexiques","word_nosc":"lexiques","lemma":"lexique","pos":"NOM"} ,
		{"word":"leçon","word_nosc":"lecon","lemma":"leçon","pos":"NOM"} ,
		{"word":"leçons","word_nosc":"lecons","lemma":"leçon","pos":"NOM"} ,
		{"word":"li","word_nosc":"li","lemma":"li","pos":"NOM"} ,
		{"word":"liage","word_nosc":"liage","lemma":"liage","pos":"NOM"} ,
		{"word":"liaison","word_nosc":"liaison","lemma":"liaison","pos":"NOM"} ,
		{"word":"liaisons","word_nosc":"liaisons","lemma":"liaison","pos":"NOM"} ,
		{"word":"liane","word_nosc":"liane","lemma":"liane","pos":"NOM"} ,
		{"word":"lianes","word_nosc":"lianes","lemma":"liane","pos":"NOM"} ,
		{"word":"liant","word_nosc":"liant","lemma":"liant","pos":"NOM"} ,
		{"word":"liard","word_nosc":"liard","lemma":"liard","pos":"NOM"} ,
		{"word":"liards","word_nosc":"liards","lemma":"liard","pos":"NOM"} ,
		{"word":"liasse","word_nosc":"liasse","lemma":"liasse","pos":"NOM"} ,
		{"word":"liasses","word_nosc":"liasses","lemma":"liasse","pos":"NOM"} ,
		{"word":"libanais","word_nosc":"libanais","lemma":"libanais","pos":"NOM"} ,
		{"word":"libanaise","word_nosc":"libanaise","lemma":"libanais","pos":"NOM"} ,
		{"word":"libanaises","word_nosc":"libanaises","lemma":"libanais","pos":"NOM"} ,
		{"word":"libation","word_nosc":"libation","lemma":"libation","pos":"NOM"} ,
		{"word":"libations","word_nosc":"libations","lemma":"libation","pos":"NOM"} ,
		{"word":"libeccio","word_nosc":"libeccio","lemma":"libeccio","pos":"NOM"} ,
		{"word":"libelle","word_nosc":"libelle","lemma":"libelle","pos":"NOM"} ,
		{"word":"libelles","word_nosc":"libelles","lemma":"libelle","pos":"NOM"} ,
		{"word":"libellule","word_nosc":"libellule","lemma":"libellule","pos":"NOM"} ,
		{"word":"libellules","word_nosc":"libellules","lemma":"libellule","pos":"NOM"} ,
		{"word":"libellé","word_nosc":"libelle","lemma":"libellé","pos":"NOM"} ,
		{"word":"liber","word_nosc":"liber","lemma":"liber","pos":"NOM"} ,
		{"word":"libera","word_nosc":"libera","lemma":"libera","pos":"NOM"} ,
		{"word":"libero","word_nosc":"libero","lemma":"libero","pos":"NOM"} ,
		{"word":"libertaire","word_nosc":"libertaire","lemma":"libertaire","pos":"NOM"} ,
		{"word":"libertaires","word_nosc":"libertaires","lemma":"libertaire","pos":"NOM"} ,
		{"word":"libertin","word_nosc":"libertin","lemma":"libertin","pos":"NOM"} ,
		{"word":"libertinage","word_nosc":"libertinage","lemma":"libertinage","pos":"NOM"} ,
		{"word":"libertine","word_nosc":"libertine","lemma":"libertin","pos":"NOM"} ,
		{"word":"libertines","word_nosc":"libertines","lemma":"libertin","pos":"NOM"} ,
		{"word":"libertins","word_nosc":"libertins","lemma":"libertin","pos":"NOM"} ,
		{"word":"liberté","word_nosc":"liberte","lemma":"liberté","pos":"NOM"} ,
		{"word":"libertés","word_nosc":"libertes","lemma":"liberté","pos":"NOM"} ,
		{"word":"libido","word_nosc":"libido","lemma":"libido","pos":"NOM"} ,
		{"word":"libraire","word_nosc":"libraire","lemma":"libraire","pos":"NOM"} ,
		{"word":"libraires","word_nosc":"libraires","lemma":"libraire","pos":"NOM"} ,
		{"word":"librairie","word_nosc":"librairie","lemma":"librairie","pos":"NOM"} ,
		{"word":"librairie-papeterie","word_nosc":"librairie-papeterie","lemma":"librairie-papeterie","pos":"NOM"} ,
		{"word":"librairies","word_nosc":"librairies","lemma":"librairie","pos":"NOM"} ,
		{"word":"libre-arbitre","word_nosc":"libre-arbitre","lemma":"libre-arbitre","pos":"NOM"} ,
		{"word":"libre-penseur","word_nosc":"libre-penseur","lemma":"libre-penseur","pos":"NOM"} ,
		{"word":"libre-penseuse","word_nosc":"libre-penseuse","lemma":"libre-penseur","pos":"NOM"} ,
		{"word":"libre-service","word_nosc":"libre-service","lemma":"libre-service","pos":"NOM"} ,
		{"word":"libre-échange","word_nosc":"libre-echange","lemma":"libre-échange","pos":"NOM"} ,
		{"word":"libre-échangiste","word_nosc":"libre-echangiste","lemma":"libre-échangiste","pos":"NOM"} ,
		{"word":"librettiste","word_nosc":"librettiste","lemma":"librettiste","pos":"NOM"} ,
		{"word":"librettistes","word_nosc":"librettistes","lemma":"librettiste","pos":"NOM"} ,
		{"word":"libretto","word_nosc":"libretto","lemma":"libretto","pos":"NOM"} ,
		{"word":"libyen","word_nosc":"libyen","lemma":"libyen","pos":"NOM"} ,
		{"word":"libyens","word_nosc":"libyens","lemma":"libyen","pos":"NOM"} ,
		{"word":"libérable","word_nosc":"liberable","lemma":"libérable","pos":"NOM"} ,
		{"word":"libérables","word_nosc":"liberables","lemma":"libérable","pos":"NOM"} ,
		{"word":"libéral","word_nosc":"liberal","lemma":"libéral","pos":"NOM"} ,
		{"word":"libérale","word_nosc":"liberale","lemma":"libéral","pos":"NOM"} ,
		{"word":"libérales","word_nosc":"liberales","lemma":"libéral","pos":"NOM"} ,
		{"word":"libéralisation","word_nosc":"liberalisation","lemma":"libéralisation","pos":"NOM"} ,
		{"word":"libéralisme","word_nosc":"liberalisme","lemma":"libéralisme","pos":"NOM"} ,
		{"word":"libéralité","word_nosc":"liberalite","lemma":"libéralité","pos":"NOM"} ,
		{"word":"libéralités","word_nosc":"liberalites","lemma":"libéralité","pos":"NOM"} ,
		{"word":"libérateur","word_nosc":"liberateur","lemma":"libérateur","pos":"NOM"} ,
		{"word":"libérateurs","word_nosc":"liberateurs","lemma":"libérateur","pos":"NOM"} ,
		{"word":"libération","word_nosc":"liberation","lemma":"libération","pos":"NOM"} ,
		{"word":"libérations","word_nosc":"liberations","lemma":"libération","pos":"NOM"} ,
		{"word":"libératrice","word_nosc":"liberatrice","lemma":"libérateur","pos":"NOM"} ,
		{"word":"libératrices","word_nosc":"liberatrices","lemma":"libérateur","pos":"NOM"} ,
		{"word":"libéraux","word_nosc":"liberaux","lemma":"libéral","pos":"NOM"} ,
		{"word":"libéro","word_nosc":"libero","lemma":"libéro","pos":"NOM"} ,
		{"word":"lice","word_nosc":"lice","lemma":"lice","pos":"NOM"} ,
		{"word":"licence","word_nosc":"licence","lemma":"licence","pos":"NOM"} ,
		{"word":"licences","word_nosc":"licences","lemma":"licence","pos":"NOM"} ,
		{"word":"licenciement","word_nosc":"licenciement","lemma":"licenciement","pos":"NOM"} ,
		{"word":"licenciements","word_nosc":"licenciements","lemma":"licenciement","pos":"NOM"} ,
		{"word":"licencié","word_nosc":"licencie","lemma":"licencié","pos":"NOM"} ,
		{"word":"licenciée","word_nosc":"licenciee","lemma":"licencié","pos":"NOM"} ,
		{"word":"licenciées","word_nosc":"licenciees","lemma":"licencié","pos":"NOM"} ,
		{"word":"licenciés","word_nosc":"licencies","lemma":"licencié","pos":"NOM"} ,
		{"word":"lices","word_nosc":"lices","lemma":"lice","pos":"NOM"} ,
		{"word":"liche","word_nosc":"liche","lemma":"liche","pos":"NOM"} ,
		{"word":"lichen","word_nosc":"lichen","lemma":"lichen","pos":"NOM"} ,
		{"word":"lichens","word_nosc":"lichens","lemma":"lichen","pos":"NOM"} ,
		{"word":"lichette","word_nosc":"lichette","lemma":"lichette","pos":"NOM"} ,
		{"word":"lichettes","word_nosc":"lichettes","lemma":"lichette","pos":"NOM"} ,
		{"word":"liciers","word_nosc":"liciers","lemma":"licier","pos":"NOM"} ,
		{"word":"licol","word_nosc":"licol","lemma":"licol","pos":"NOM"} ,
		{"word":"licols","word_nosc":"licols","lemma":"licol","pos":"NOM"} ,
		{"word":"licorne","word_nosc":"licorne","lemma":"licorne","pos":"NOM"} ,
		{"word":"licornes","word_nosc":"licornes","lemma":"licorne","pos":"NOM"} ,
		{"word":"licou","word_nosc":"licou","lemma":"licou","pos":"NOM"} ,
		{"word":"licous","word_nosc":"licous","lemma":"licou","pos":"NOM"} ,
		{"word":"licteur","word_nosc":"licteur","lemma":"licteur","pos":"NOM"} ,
		{"word":"licteurs","word_nosc":"licteurs","lemma":"licteur","pos":"NOM"} ,
		{"word":"licéité","word_nosc":"liceite","lemma":"licéité","pos":"NOM"} ,
		{"word":"lido","word_nosc":"lido","lemma":"lido","pos":"NOM"} ,
		{"word":"lidocaïne","word_nosc":"lidocaine","lemma":"lidocaïne","pos":"NOM"} ,
		{"word":"lie","word_nosc":"lie","lemma":"lie","pos":"NOM"} ,
		{"word":"lied","word_nosc":"lied","lemma":"lied","pos":"NOM"} ,
		{"word":"lieder","word_nosc":"lieder","lemma":"lied","pos":"NOM"} ,
		{"word":"lien","word_nosc":"lien","lemma":"lien","pos":"NOM"} ,
		{"word":"liens","word_nosc":"liens","lemma":"lien","pos":"NOM"} ,
		{"word":"lierre","word_nosc":"lierre","lemma":"lierre","pos":"NOM"} ,
		{"word":"lierres","word_nosc":"lierres","lemma":"lierre","pos":"NOM"} ,
		{"word":"lies","word_nosc":"lies","lemma":"lie","pos":"NOM"} ,
		{"word":"liesse","word_nosc":"liesse","lemma":"liesse","pos":"NOM"} ,
		{"word":"liesses","word_nosc":"liesses","lemma":"liesse","pos":"NOM"} ,
		{"word":"lieu","word_nosc":"lieu","lemma":"lieu","pos":"NOM"} ,
		{"word":"lieu-dit","word_nosc":"lieu-dit","lemma":"lieu-dit","pos":"NOM"} ,
		{"word":"lieudit","word_nosc":"lieudit","lemma":"lieudit","pos":"NOM"} ,
		{"word":"lieudits","word_nosc":"lieudits","lemma":"lieudit","pos":"NOM"} ,
		{"word":"lieue","word_nosc":"lieue","lemma":"lieue","pos":"NOM"} ,
		{"word":"lieues","word_nosc":"lieues","lemma":"lieue","pos":"NOM"} ,
		{"word":"lieur","word_nosc":"lieur","lemma":"lieur","pos":"NOM"} ,
		{"word":"lieus","word_nosc":"lieus","lemma":"lieu","pos":"NOM"} ,
		{"word":"lieuse","word_nosc":"lieuse","lemma":"lieur","pos":"NOM"} ,
		{"word":"lieutenant","word_nosc":"lieutenant","lemma":"lieutenant","pos":"NOM"} ,
		{"word":"lieutenant-colonel","word_nosc":"lieutenant-colonel","lemma":"lieutenant-colonel","pos":"NOM"} ,
		{"word":"lieutenant-pilote","word_nosc":"lieutenant-pilote","lemma":"lieutenant-pilote","pos":"NOM"} ,
		{"word":"lieutenants","word_nosc":"lieutenants","lemma":"lieutenant","pos":"NOM"} ,
		{"word":"lieutenants-colonels","word_nosc":"lieutenants-colonels","lemma":"lieutenant-colonel","pos":"NOM"} ,
		{"word":"lieux","word_nosc":"lieux","lemma":"lieu","pos":"NOM"} ,
		{"word":"lieux-dits","word_nosc":"lieux-dits","lemma":"lieux-dits","pos":"NOM"} ,
		{"word":"lift","word_nosc":"lift","lemma":"lift","pos":"NOM"} ,
		{"word":"liftier","word_nosc":"liftier","lemma":"liftier","pos":"NOM"} ,
		{"word":"liftiers","word_nosc":"liftiers","lemma":"liftier","pos":"NOM"} ,
		{"word":"lifting","word_nosc":"lifting","lemma":"lifting","pos":"NOM"} ,
		{"word":"liftings","word_nosc":"liftings","lemma":"lifting","pos":"NOM"} ,
		{"word":"liftière","word_nosc":"liftiere","lemma":"liftier","pos":"NOM"} ,
		{"word":"lifts","word_nosc":"lifts","lemma":"lift","pos":"NOM"} ,
		{"word":"ligament","word_nosc":"ligament","lemma":"ligament","pos":"NOM"} ,
		{"word":"ligaments","word_nosc":"ligaments","lemma":"ligament","pos":"NOM"} ,
		{"word":"ligature","word_nosc":"ligature","lemma":"ligature","pos":"NOM"} ,
		{"word":"ligatures","word_nosc":"ligatures","lemma":"ligature","pos":"NOM"} ,
		{"word":"lignage","word_nosc":"lignage","lemma":"lignage","pos":"NOM"} ,
		{"word":"lignages","word_nosc":"lignages","lemma":"lignage","pos":"NOM"} ,
		{"word":"lignards","word_nosc":"lignards","lemma":"lignard","pos":"NOM"} ,
		{"word":"ligne","word_nosc":"ligne","lemma":"ligne","pos":"NOM"} ,
		{"word":"lignes","word_nosc":"lignes","lemma":"ligne","pos":"NOM"} ,
		{"word":"lignite","word_nosc":"lignite","lemma":"lignite","pos":"NOM"} ,
		{"word":"lignites","word_nosc":"lignites","lemma":"lignite","pos":"NOM"} ,
		{"word":"lignée","word_nosc":"lignee","lemma":"lignée","pos":"NOM"} ,
		{"word":"lignées","word_nosc":"lignees","lemma":"lignée","pos":"NOM"} ,
		{"word":"ligot","word_nosc":"ligot","lemma":"ligot","pos":"NOM"} ,
		{"word":"ligotage","word_nosc":"ligotage","lemma":"ligotage","pos":"NOM"} ,
		{"word":"ligots","word_nosc":"ligots","lemma":"ligot","pos":"NOM"} ,
		{"word":"ligue","word_nosc":"ligue","lemma":"ligue","pos":"NOM"} ,
		{"word":"ligues","word_nosc":"ligues","lemma":"ligue","pos":"NOM"} ,
		{"word":"ligueur","word_nosc":"ligueur","lemma":"ligueur","pos":"NOM"} ,
		{"word":"ligueurs","word_nosc":"ligueurs","lemma":"ligueur","pos":"NOM"} ,
		{"word":"lilas","word_nosc":"lilas","lemma":"lilas","pos":"NOM"} ,
		{"word":"liliacées","word_nosc":"liliacees","lemma":"liliacé","pos":"NOM"} ,
		{"word":"liliacées","word_nosc":"liliacees","lemma":"liliacée","pos":"NOM"} ,
		{"word":"lilium","word_nosc":"lilium","lemma":"lilium","pos":"NOM"} ,
		{"word":"lillois","word_nosc":"lillois","lemma":"lillois","pos":"NOM"} ,
		{"word":"lilloise","word_nosc":"lilloise","lemma":"lillois","pos":"NOM"} ,
		{"word":"limace","word_nosc":"limace","lemma":"limace","pos":"NOM"} ,
		{"word":"limaces","word_nosc":"limaces","lemma":"limace","pos":"NOM"} ,
		{"word":"limage","word_nosc":"limage","lemma":"limage","pos":"NOM"} ,
		{"word":"limaille","word_nosc":"limaille","lemma":"limaille","pos":"NOM"} ,
		{"word":"liman","word_nosc":"liman","lemma":"liman","pos":"NOM"} ,
		{"word":"limande","word_nosc":"limande","lemma":"limande","pos":"NOM"} ,
		{"word":"limandes","word_nosc":"limandes","lemma":"limande","pos":"NOM"} ,
		{"word":"limaçon","word_nosc":"limacon","lemma":"limaçon","pos":"NOM"} ,
		{"word":"limaçons","word_nosc":"limacons","lemma":"limaçon","pos":"NOM"} ,
		{"word":"limbe","word_nosc":"limbe","lemma":"limbe","pos":"NOM"} ,
		{"word":"limbes","word_nosc":"limbes","lemma":"limbe","pos":"NOM"} ,
		{"word":"limbo","word_nosc":"limbo","lemma":"limbo","pos":"NOM"} ,
		{"word":"lime","word_nosc":"lime","lemma":"lime","pos":"NOM"} ,
		{"word":"limerick","word_nosc":"limerick","lemma":"limerick","pos":"NOM"} ,
		{"word":"limericks","word_nosc":"limericks","lemma":"limerick","pos":"NOM"} ,
		{"word":"limes","word_nosc":"limes","lemma":"lime","pos":"NOM"} ,
		{"word":"limette","word_nosc":"limette","lemma":"limette","pos":"NOM"} ,
		{"word":"limier","word_nosc":"limier","lemma":"limier","pos":"NOM"} ,
		{"word":"limiers","word_nosc":"limiers","lemma":"limier","pos":"NOM"} ,
		{"word":"limitation","word_nosc":"limitation","lemma":"limitation","pos":"NOM"} ,
		{"word":"limitations","word_nosc":"limitations","lemma":"limitation","pos":"NOM"} ,
		{"word":"limite","word_nosc":"limite","lemma":"limite","pos":"NOM"} ,
		{"word":"limites","word_nosc":"limites","lemma":"limite","pos":"NOM"} ,
		{"word":"limiteur","word_nosc":"limiteur","lemma":"limiteur","pos":"NOM"} ,
		{"word":"limogeage","word_nosc":"limogeage","lemma":"limogeage","pos":"NOM"} ,
		{"word":"limon","word_nosc":"limon","lemma":"limon","pos":"NOM"} ,
		{"word":"limonade","word_nosc":"limonade","lemma":"limonade","pos":"NOM"} ,
		{"word":"limonades","word_nosc":"limonades","lemma":"limonade","pos":"NOM"} ,
		{"word":"limonadier","word_nosc":"limonadier","lemma":"limonadier","pos":"NOM"} ,
		{"word":"limonadiers","word_nosc":"limonadiers","lemma":"limonadier","pos":"NOM"} ,
		{"word":"limonadière","word_nosc":"limonadiere","lemma":"limonadier","pos":"NOM"} ,
		{"word":"limonaire","word_nosc":"limonaire","lemma":"limonaire","pos":"NOM"} ,
		{"word":"limonier","word_nosc":"limonier","lemma":"limonier","pos":"NOM"} ,
		{"word":"limonière","word_nosc":"limoniere","lemma":"limonier","pos":"NOM"} ,
		{"word":"limons","word_nosc":"limons","lemma":"limon","pos":"NOM"} ,
		{"word":"limoselle","word_nosc":"limoselle","lemma":"limoselle","pos":"NOM"} ,
		{"word":"limousin","word_nosc":"limousin","lemma":"limousin","pos":"NOM"} ,
		{"word":"limousine","word_nosc":"limousine","lemma":"limousin","pos":"NOM"} ,
		{"word":"limousines","word_nosc":"limousines","lemma":"limousin","pos":"NOM"} ,
		{"word":"limpidité","word_nosc":"limpidite","lemma":"limpidité","pos":"NOM"} ,
		{"word":"lin","word_nosc":"lin","lemma":"lin","pos":"NOM"} ,
		{"word":"linaire","word_nosc":"linaire","lemma":"linaire","pos":"NOM"} ,
		{"word":"linceul","word_nosc":"linceul","lemma":"linceul","pos":"NOM"} ,
		{"word":"linceuls","word_nosc":"linceuls","lemma":"linceul","pos":"NOM"} ,
		{"word":"lindor","word_nosc":"lindor","lemma":"lindor","pos":"NOM"} ,
		{"word":"line","word_nosc":"line","lemma":"line","pos":"NOM"} ,
		{"word":"linga","word_nosc":"linga","lemma":"linga","pos":"NOM"} ,
		{"word":"lingala","word_nosc":"lingala","lemma":"lingala","pos":"NOM"} ,
		{"word":"lingam","word_nosc":"lingam","lemma":"lingam","pos":"NOM"} ,
		{"word":"linge","word_nosc":"linge","lemma":"linge","pos":"NOM"} ,
		{"word":"lingerie","word_nosc":"lingerie","lemma":"lingerie","pos":"NOM"} ,
		{"word":"lingeries","word_nosc":"lingeries","lemma":"lingerie","pos":"NOM"} ,
		{"word":"linges","word_nosc":"linges","lemma":"linge","pos":"NOM"} ,
		{"word":"lingette","word_nosc":"lingette","lemma":"lingette","pos":"NOM"} ,
		{"word":"lingettes","word_nosc":"lingettes","lemma":"lingette","pos":"NOM"} ,
		{"word":"lingot","word_nosc":"lingot","lemma":"lingot","pos":"NOM"} ,
		{"word":"lingotière","word_nosc":"lingotiere","lemma":"lingotier","pos":"NOM"} ,
		{"word":"lingots","word_nosc":"lingots","lemma":"lingot","pos":"NOM"} ,
		{"word":"linguiste","word_nosc":"linguiste","lemma":"linguiste","pos":"NOM"} ,
		{"word":"linguistes","word_nosc":"linguistes","lemma":"linguiste","pos":"NOM"} ,
		{"word":"linguistique","word_nosc":"linguistique","lemma":"linguistique","pos":"NOM"} ,
		{"word":"lingère","word_nosc":"lingere","lemma":"lingère","pos":"NOM"} ,
		{"word":"lingères","word_nosc":"lingeres","lemma":"lingère","pos":"NOM"} ,
		{"word":"liniment","word_nosc":"liniment","lemma":"liniment","pos":"NOM"} ,
		{"word":"lino","word_nosc":"lino","lemma":"lino","pos":"NOM"} ,
		{"word":"linoléum","word_nosc":"linoleum","lemma":"linoléum","pos":"NOM"} ,
		{"word":"linoléums","word_nosc":"linoleums","lemma":"linoléum","pos":"NOM"} ,
		{"word":"linon","word_nosc":"linon","lemma":"linon","pos":"NOM"} ,
		{"word":"linons","word_nosc":"linons","lemma":"linon","pos":"NOM"} ,
		{"word":"linos","word_nosc":"linos","lemma":"lino","pos":"NOM"} ,
		{"word":"linotte","word_nosc":"linotte","lemma":"linotte","pos":"NOM"} ,
		{"word":"linottes","word_nosc":"linottes","lemma":"linotte","pos":"NOM"} ,
		{"word":"linotype","word_nosc":"linotype","lemma":"linotype","pos":"NOM"} ,
		{"word":"linotypes","word_nosc":"linotypes","lemma":"linotype","pos":"NOM"} ,
		{"word":"linotypistes","word_nosc":"linotypistes","lemma":"linotypiste","pos":"NOM"} ,
		{"word":"lins","word_nosc":"lins","lemma":"lin","pos":"NOM"} ,
		{"word":"linteau","word_nosc":"linteau","lemma":"linteau","pos":"NOM"} ,
		{"word":"linteaux","word_nosc":"linteaux","lemma":"linteau","pos":"NOM"} ,
		{"word":"linter","word_nosc":"linter","lemma":"linter","pos":"NOM"} ,
		{"word":"linéaire","word_nosc":"lineaire","lemma":"linéaire","pos":"NOM"} ,
		{"word":"linéaments","word_nosc":"lineaments","lemma":"linéament","pos":"NOM"} ,
		{"word":"lion","word_nosc":"lion","lemma":"lion","pos":"NOM"} ,
		{"word":"lionceau","word_nosc":"lionceau","lemma":"lionceau","pos":"NOM"} ,
		{"word":"lionceaux","word_nosc":"lionceaux","lemma":"lionceau","pos":"NOM"} ,
		{"word":"lionne","word_nosc":"lionne","lemma":"lion","pos":"NOM"} ,
		{"word":"lionnes","word_nosc":"lionnes","lemma":"lion","pos":"NOM"} ,
		{"word":"lions","word_nosc":"lions","lemma":"lion","pos":"NOM"} ,
		{"word":"lipase","word_nosc":"lipase","lemma":"lipase","pos":"NOM"} ,
		{"word":"lipide","word_nosc":"lipide","lemma":"lipide","pos":"NOM"} ,
		{"word":"lipides","word_nosc":"lipides","lemma":"lipide","pos":"NOM"} ,
		{"word":"lipiodol","word_nosc":"lipiodol","lemma":"lipiodol","pos":"NOM"} ,
		{"word":"lipome","word_nosc":"lipome","lemma":"lipome","pos":"NOM"} ,
		{"word":"liposome","word_nosc":"liposome","lemma":"liposome","pos":"NOM"} ,
		{"word":"liposuccion","word_nosc":"liposuccion","lemma":"liposuccion","pos":"NOM"} ,
		{"word":"liposuccions","word_nosc":"liposuccions","lemma":"liposuccion","pos":"NOM"} ,
		{"word":"lipothymie","word_nosc":"lipothymie","lemma":"lipothymie","pos":"NOM"} ,
		{"word":"lippe","word_nosc":"lippe","lemma":"lippe","pos":"NOM"} ,
		{"word":"lippes","word_nosc":"lippes","lemma":"lippe","pos":"NOM"} ,
		{"word":"lippées","word_nosc":"lippees","lemma":"lippée","pos":"NOM"} ,
		{"word":"liquette","word_nosc":"liquette","lemma":"liquette","pos":"NOM"} ,
		{"word":"liquettes","word_nosc":"liquettes","lemma":"liquette","pos":"NOM"} ,
		{"word":"liqueur","word_nosc":"liqueur","lemma":"liqueur","pos":"NOM"} ,
		{"word":"liqueurs","word_nosc":"liqueurs","lemma":"liqueur","pos":"NOM"} ,
		{"word":"liquidambars","word_nosc":"liquidambars","lemma":"liquidambar","pos":"NOM"} ,
		{"word":"liquidateur","word_nosc":"liquidateur","lemma":"liquidateur","pos":"NOM"} ,
		{"word":"liquidateurs","word_nosc":"liquidateurs","lemma":"liquidateur","pos":"NOM"} ,
		{"word":"liquidation","word_nosc":"liquidation","lemma":"liquidation","pos":"NOM"} ,
		{"word":"liquidations","word_nosc":"liquidations","lemma":"liquidation","pos":"NOM"} ,
		{"word":"liquide","word_nosc":"liquide","lemma":"liquide","pos":"NOM"} ,
		{"word":"liquides","word_nosc":"liquides","lemma":"liquide","pos":"NOM"} ,
		{"word":"liquidité","word_nosc":"liquidite","lemma":"liquidité","pos":"NOM"} ,
		{"word":"liquidités","word_nosc":"liquidites","lemma":"liquidité","pos":"NOM"} ,
		{"word":"liquor","word_nosc":"liquor","lemma":"liquor","pos":"NOM"} ,
		{"word":"liquoriste","word_nosc":"liquoriste","lemma":"liquoriste","pos":"NOM"} ,
		{"word":"liquéfaction","word_nosc":"liquefaction","lemma":"liquéfaction","pos":"NOM"} ,
		{"word":"lire","word_nosc":"lire","lemma":"lire","pos":"NOM"} ,
		{"word":"lires","word_nosc":"lires","lemma":"lire","pos":"NOM"} ,
		{"word":"lirette","word_nosc":"lirette","lemma":"lirette","pos":"NOM"} ,
		{"word":"liron","word_nosc":"liron","lemma":"liron","pos":"NOM"} ,
		{"word":"lirons","word_nosc":"lirons","lemma":"liron","pos":"NOM"} ,
		{"word":"lis","word_nosc":"lis","lemma":"lis","pos":"NOM"} ,
		{"word":"lisant","word_nosc":"lisant","lemma":"lisant","pos":"NOM"} ,
		{"word":"lise","word_nosc":"lise","lemma":"lise","pos":"NOM"} ,
		{"word":"liseron","word_nosc":"liseron","lemma":"liseron","pos":"NOM"} ,
		{"word":"liserons","word_nosc":"liserons","lemma":"liseron","pos":"NOM"} ,
		{"word":"liseré","word_nosc":"lisere","lemma":"liseré","pos":"NOM"} ,
		{"word":"liserés","word_nosc":"liseres","lemma":"liseré","pos":"NOM"} ,
		{"word":"lises","word_nosc":"lises","lemma":"lise","pos":"NOM"} ,
		{"word":"liseur","word_nosc":"liseur","lemma":"liseur","pos":"NOM"} ,
		{"word":"liseuse","word_nosc":"liseuse","lemma":"liseur","pos":"NOM"} ,
		{"word":"liseuses","word_nosc":"liseuses","lemma":"liseur","pos":"NOM"} ,
		{"word":"lisibilité","word_nosc":"lisibilite","lemma":"lisibilité","pos":"NOM"} ,
		{"word":"lisier","word_nosc":"lisier","lemma":"lisier","pos":"NOM"} ,
		{"word":"lisière","word_nosc":"lisiere","lemma":"lisière","pos":"NOM"} ,
		{"word":"lisières","word_nosc":"lisieres","lemma":"lisière","pos":"NOM"} ,
		{"word":"lissage","word_nosc":"lissage","lemma":"lissage","pos":"NOM"} ,
		{"word":"lisse","word_nosc":"lisse","lemma":"lisse","pos":"NOM"} ,
		{"word":"lisses","word_nosc":"lisses","lemma":"lisse","pos":"NOM"} ,
		{"word":"lisseur","word_nosc":"lisseur","lemma":"lisseur","pos":"NOM"} ,
		{"word":"lisseurs","word_nosc":"lisseurs","lemma":"lisseur","pos":"NOM"} ,
		{"word":"lissé","word_nosc":"lisse","lemma":"lissé","pos":"NOM"} ,
		{"word":"lissés","word_nosc":"lisses","lemma":"lissé","pos":"NOM"} ,
		{"word":"liste","word_nosc":"liste","lemma":"liste","pos":"NOM"} ,
		{"word":"listel","word_nosc":"listel","lemma":"listel","pos":"NOM"} ,
		{"word":"listeria","word_nosc":"listeria","lemma":"listeria","pos":"NOM"} ,
		{"word":"listes","word_nosc":"listes","lemma":"liste","pos":"NOM"} ,
		{"word":"listing","word_nosc":"listing","lemma":"listing","pos":"NOM"} ,
		{"word":"listings","word_nosc":"listings","lemma":"listing","pos":"NOM"} ,
		{"word":"liston","word_nosc":"liston","lemma":"liston","pos":"NOM"} ,
		{"word":"liséré","word_nosc":"lisere","lemma":"liséré","pos":"NOM"} ,
		{"word":"lisérés","word_nosc":"liseres","lemma":"liséré","pos":"NOM"} ,
		{"word":"lit","word_nosc":"lit","lemma":"lit","pos":"NOM"} ,
		{"word":"lit-bateau","word_nosc":"lit-bateau","lemma":"lit-bateau","pos":"NOM"} ,
		{"word":"lit-cage","word_nosc":"lit-cage","lemma":"lit-cage","pos":"NOM"} ,
		{"word":"lit-divan","word_nosc":"lit-divan","lemma":"lit-divan","pos":"NOM"} ,
		{"word":"litanie","word_nosc":"litanie","lemma":"litanie","pos":"NOM"} ,
		{"word":"litanies","word_nosc":"litanies","lemma":"litanie","pos":"NOM"} ,
		{"word":"liteaux","word_nosc":"liteaux","lemma":"liteau","pos":"NOM"} ,
		{"word":"literie","word_nosc":"literie","lemma":"literie","pos":"NOM"} ,
		{"word":"literies","word_nosc":"literies","lemma":"literie","pos":"NOM"} ,
		{"word":"lithiase","word_nosc":"lithiase","lemma":"lithiase","pos":"NOM"} ,
		{"word":"lithium","word_nosc":"lithium","lemma":"lithium","pos":"NOM"} ,
		{"word":"litho","word_nosc":"litho","lemma":"litho","pos":"NOM"} ,
		{"word":"lithographe","word_nosc":"lithographe","lemma":"lithographe","pos":"NOM"} ,
		{"word":"lithographie","word_nosc":"lithographie","lemma":"lithographie","pos":"NOM"} ,
		{"word":"lithographies","word_nosc":"lithographies","lemma":"lithographie","pos":"NOM"} ,
		{"word":"lithos","word_nosc":"lithos","lemma":"litho","pos":"NOM"} ,
		{"word":"lithosphère","word_nosc":"lithosphere","lemma":"lithosphère","pos":"NOM"} ,
		{"word":"lithotripsie","word_nosc":"lithotripsie","lemma":"lithotripsie","pos":"NOM"} ,
		{"word":"lithotriteur","word_nosc":"lithotriteur","lemma":"lithotriteur","pos":"NOM"} ,
		{"word":"lithuanien","word_nosc":"lithuanien","lemma":"lithuanien","pos":"NOM"} ,
		{"word":"litige","word_nosc":"litige","lemma":"litige","pos":"NOM"} ,
		{"word":"litiges","word_nosc":"litiges","lemma":"litige","pos":"NOM"} ,
		{"word":"litière","word_nosc":"litiere","lemma":"litière","pos":"NOM"} ,
		{"word":"litières","word_nosc":"litieres","lemma":"litière","pos":"NOM"} ,
		{"word":"litorne","word_nosc":"litorne","lemma":"litorne","pos":"NOM"} ,
		{"word":"litote","word_nosc":"litote","lemma":"litote","pos":"NOM"} ,
		{"word":"litotes","word_nosc":"litotes","lemma":"litote","pos":"NOM"} ,
		{"word":"litre","word_nosc":"litre","lemma":"litre","pos":"NOM"} ,
		{"word":"litres","word_nosc":"litres","lemma":"litre","pos":"NOM"} ,
		{"word":"litron","word_nosc":"litron","lemma":"litron","pos":"NOM"} ,
		{"word":"litrons","word_nosc":"litrons","lemma":"litron","pos":"NOM"} ,
		{"word":"lits","word_nosc":"lits","lemma":"lit","pos":"NOM"} ,
		{"word":"lits-cages","word_nosc":"lits-cages","lemma":"lit-cage","pos":"NOM"} ,
		{"word":"littoral","word_nosc":"littoral","lemma":"littoral","pos":"NOM"} ,
		{"word":"littoraux","word_nosc":"littoraux","lemma":"littoral","pos":"NOM"} ,
		{"word":"littéraire","word_nosc":"litteraire","lemma":"littéraire","pos":"NOM"} ,
		{"word":"littéraires","word_nosc":"litteraires","lemma":"littéraire","pos":"NOM"} ,
		{"word":"littéralité","word_nosc":"litteralite","lemma":"littéralité","pos":"NOM"} ,
		{"word":"littérateur","word_nosc":"litterateur","lemma":"littérateur","pos":"NOM"} ,
		{"word":"littérateurs","word_nosc":"litterateurs","lemma":"littérateur","pos":"NOM"} ,
		{"word":"littérature","word_nosc":"litterature","lemma":"littérature","pos":"NOM"} ,
		{"word":"littératures","word_nosc":"litteratures","lemma":"littérature","pos":"NOM"} ,
		{"word":"lituanien","word_nosc":"lituanien","lemma":"lituanien","pos":"NOM"} ,
		{"word":"lituaniens","word_nosc":"lituaniens","lemma":"lituanien","pos":"NOM"} ,
		{"word":"liturgie","word_nosc":"liturgie","lemma":"liturgie","pos":"NOM"} ,
		{"word":"liturgies","word_nosc":"liturgies","lemma":"liturgie","pos":"NOM"} ,
		{"word":"litée","word_nosc":"litee","lemma":"litée","pos":"NOM"} ,
		{"word":"livarot","word_nosc":"livarot","lemma":"livarot","pos":"NOM"} ,
		{"word":"lividité","word_nosc":"lividite","lemma":"lividité","pos":"NOM"} ,
		{"word":"living","word_nosc":"living","lemma":"living","pos":"NOM"} ,
		{"word":"living-room","word_nosc":"living-room","lemma":"living-room","pos":"NOM"} ,
		{"word":"livings","word_nosc":"livings","lemma":"living","pos":"NOM"} ,
		{"word":"livoniennes","word_nosc":"livoniennes","lemma":"livonienne","pos":"NOM"} ,
		{"word":"livraison","word_nosc":"livraison","lemma":"livraison","pos":"NOM"} ,
		{"word":"livraisons","word_nosc":"livraisons","lemma":"livraison","pos":"NOM"} ,
		{"word":"livre","word_nosc":"livre","lemma":"livre","pos":"NOM"} ,
		{"word":"livre-cassette","word_nosc":"livre-cassette","lemma":"livre-cassette","pos":"NOM"} ,
		{"word":"livres","word_nosc":"livres","lemma":"livre","pos":"NOM"} ,
		{"word":"livres-club","word_nosc":"livres-club","lemma":"livres-club","pos":"NOM"} ,
		{"word":"livret","word_nosc":"livret","lemma":"livret","pos":"NOM"} ,
		{"word":"livrets","word_nosc":"livrets","lemma":"livret","pos":"NOM"} ,
		{"word":"livreur","word_nosc":"livreur","lemma":"livreur","pos":"NOM"} ,
		{"word":"livreurs","word_nosc":"livreurs","lemma":"livreur","pos":"NOM"} ,
		{"word":"livreuse","word_nosc":"livreuse","lemma":"livreur","pos":"NOM"} ,
		{"word":"livrée","word_nosc":"livree","lemma":"livrée","pos":"NOM"} ,
		{"word":"livrées","word_nosc":"livrees","lemma":"livrée","pos":"NOM"} ,
		{"word":"livèche","word_nosc":"liveche","lemma":"livèche","pos":"NOM"} ,
		{"word":"liège","word_nosc":"liege","lemma":"liège","pos":"NOM"} ,
		{"word":"lièges","word_nosc":"lieges","lemma":"liège","pos":"NOM"} ,
		{"word":"lièvre","word_nosc":"lievre","lemma":"lièvre","pos":"NOM"} ,
		{"word":"lièvres","word_nosc":"lievres","lemma":"lièvre","pos":"NOM"} ,
		{"word":"llanos","word_nosc":"llanos","lemma":"llano","pos":"NOM"} ,
		{"word":"lloyd","word_nosc":"lloyd","lemma":"lloyd","pos":"NOM"} ,
		{"word":"loader","word_nosc":"loader","lemma":"loader","pos":"NOM"} ,
		{"word":"lob","word_nosc":"lob","lemma":"lob","pos":"NOM"} ,
		{"word":"lobbies","word_nosc":"lobbies","lemma":"lobbies","pos":"NOM"} ,
		{"word":"lobby","word_nosc":"lobby","lemma":"lobby","pos":"NOM"} ,
		{"word":"lobbying","word_nosc":"lobbying","lemma":"lobbying","pos":"NOM"} ,
		{"word":"lobe","word_nosc":"lobe","lemma":"lobe","pos":"NOM"} ,
		{"word":"lobectomie","word_nosc":"lobectomie","lemma":"lobectomie","pos":"NOM"} ,
		{"word":"lobes","word_nosc":"lobes","lemma":"lobe","pos":"NOM"} ,
		{"word":"lobotomie","word_nosc":"lobotomie","lemma":"lobotomie","pos":"NOM"} ,
		{"word":"lobs","word_nosc":"lobs","lemma":"lob","pos":"NOM"} ,
		{"word":"local","word_nosc":"local","lemma":"local","pos":"NOM"} ,
		{"word":"localisation","word_nosc":"localisation","lemma":"localisation","pos":"NOM"} ,
		{"word":"localisations","word_nosc":"localisations","lemma":"localisation","pos":"NOM"} ,
		{"word":"localité","word_nosc":"localite","lemma":"localité","pos":"NOM"} ,
		{"word":"localités","word_nosc":"localites","lemma":"localité","pos":"NOM"} ,
		{"word":"locataire","word_nosc":"locataire","lemma":"locataire","pos":"NOM"} ,
		{"word":"locataires","word_nosc":"locataires","lemma":"locataire","pos":"NOM"} ,
		{"word":"locateurs","word_nosc":"locateurs","lemma":"locateur","pos":"NOM"} ,
		{"word":"location","word_nosc":"location","lemma":"location","pos":"NOM"} ,
		{"word":"locations","word_nosc":"locations","lemma":"location","pos":"NOM"} ,
		{"word":"locature","word_nosc":"locature","lemma":"locature","pos":"NOM"} ,
		{"word":"locaux","word_nosc":"locaux","lemma":"local","pos":"NOM"} ,
		{"word":"loch","word_nosc":"loch","lemma":"loch","pos":"NOM"} ,
		{"word":"loche","word_nosc":"loche","lemma":"loche","pos":"NOM"} ,
		{"word":"loches","word_nosc":"loches","lemma":"loche","pos":"NOM"} ,
		{"word":"lock-out","word_nosc":"lock-out","lemma":"lock-out","pos":"NOM"} ,
		{"word":"loco","word_nosc":"loco","lemma":"loco","pos":"NOM"} ,
		{"word":"locomobile","word_nosc":"locomobile","lemma":"locomobile","pos":"NOM"} ,
		{"word":"locomotion","word_nosc":"locomotion","lemma":"locomotion","pos":"NOM"} ,
		{"word":"locomotive","word_nosc":"locomotive","lemma":"locomotive","pos":"NOM"} ,
		{"word":"locomotives","word_nosc":"locomotives","lemma":"locomotive","pos":"NOM"} ,
		{"word":"locomotrice","word_nosc":"locomotrice","lemma":"locomoteur","pos":"NOM"} ,
		{"word":"locos","word_nosc":"locos","lemma":"loco","pos":"NOM"} ,
		{"word":"locus","word_nosc":"locus","lemma":"locus","pos":"NOM"} ,
		{"word":"locuste","word_nosc":"locuste","lemma":"locuste","pos":"NOM"} ,
		{"word":"locustes","word_nosc":"locustes","lemma":"locuste","pos":"NOM"} ,
		{"word":"locuteur","word_nosc":"locuteur","lemma":"locuteur","pos":"NOM"} ,
		{"word":"locution","word_nosc":"locution","lemma":"locution","pos":"NOM"} ,
		{"word":"locutions","word_nosc":"locutions","lemma":"locution","pos":"NOM"} ,
		{"word":"locutrice","word_nosc":"locutrice","lemma":"locuteur","pos":"NOM"} ,
		{"word":"loden","word_nosc":"loden","lemma":"loden","pos":"NOM"} ,
		{"word":"lodens","word_nosc":"lodens","lemma":"loden","pos":"NOM"} ,
		{"word":"lof","word_nosc":"lof","lemma":"lof","pos":"NOM"} ,
		{"word":"loft","word_nosc":"loft","lemma":"loft","pos":"NOM"} ,
		{"word":"lofts","word_nosc":"lofts","lemma":"loft","pos":"NOM"} ,
		{"word":"logarithme","word_nosc":"logarithme","lemma":"logarithme","pos":"NOM"} ,
		{"word":"logarithmes","word_nosc":"logarithmes","lemma":"logarithme","pos":"NOM"} ,
		{"word":"loge","word_nosc":"loge","lemma":"loge","pos":"NOM"} ,
		{"word":"logement","word_nosc":"logement","lemma":"logement","pos":"NOM"} ,
		{"word":"logements","word_nosc":"logements","lemma":"logement","pos":"NOM"} ,
		{"word":"loges","word_nosc":"loges","lemma":"loge","pos":"NOM"} ,
		{"word":"logettes","word_nosc":"logettes","lemma":"logette","pos":"NOM"} ,
		{"word":"logeur","word_nosc":"logeur","lemma":"logeur","pos":"NOM"} ,
		{"word":"logeurs","word_nosc":"logeurs","lemma":"logeur","pos":"NOM"} ,
		{"word":"logeuse","word_nosc":"logeuse","lemma":"logeur","pos":"NOM"} ,
		{"word":"logeuses","word_nosc":"logeuses","lemma":"logeur","pos":"NOM"} ,
		{"word":"loggia","word_nosc":"loggia","lemma":"loggia","pos":"NOM"} ,
		{"word":"loggias","word_nosc":"loggias","lemma":"loggia","pos":"NOM"} ,
		{"word":"logiciel","word_nosc":"logiciel","lemma":"logiciel","pos":"NOM"} ,
		{"word":"logiciels","word_nosc":"logiciels","lemma":"logiciel","pos":"NOM"} ,
		{"word":"logicien","word_nosc":"logicien","lemma":"logicien","pos":"NOM"} ,
		{"word":"logicienne","word_nosc":"logicienne","lemma":"logicien","pos":"NOM"} ,
		{"word":"logiciens","word_nosc":"logiciens","lemma":"logicien","pos":"NOM"} ,
		{"word":"logique","word_nosc":"logique","lemma":"logique","pos":"NOM"} ,
		{"word":"logiques","word_nosc":"logiques","lemma":"logique","pos":"NOM"} ,
		{"word":"logis","word_nosc":"logis","lemma":"logis","pos":"NOM"} ,
		{"word":"logisticien","word_nosc":"logisticien","lemma":"logisticien","pos":"NOM"} ,
		{"word":"logistique","word_nosc":"logistique","lemma":"logistique","pos":"NOM"} ,
		{"word":"logo","word_nosc":"logo","lemma":"logo","pos":"NOM"} ,
		{"word":"logogriphe","word_nosc":"logogriphe","lemma":"logogriphe","pos":"NOM"} ,
		{"word":"logogriphes","word_nosc":"logogriphes","lemma":"logogriphe","pos":"NOM"} ,
		{"word":"logomachie","word_nosc":"logomachie","lemma":"logomachie","pos":"NOM"} ,
		{"word":"logorrhée","word_nosc":"logorrhee","lemma":"logorrhée","pos":"NOM"} ,
		{"word":"logos","word_nosc":"logos","lemma":"logos","pos":"NOM"} ,
		{"word":"logosphère","word_nosc":"logosphere","lemma":"logosphère","pos":"NOM"} ,
		{"word":"logothètes","word_nosc":"logothetes","lemma":"logothète","pos":"NOM"} ,
		{"word":"loi","word_nosc":"loi","lemma":"loi","pos":"NOM"} ,
		{"word":"lointain","word_nosc":"lointain","lemma":"lointain","pos":"NOM"} ,
		{"word":"lointains","word_nosc":"lointains","lemma":"lointain","pos":"NOM"} ,
		{"word":"loir","word_nosc":"loir","lemma":"loir","pos":"NOM"} ,
		{"word":"loirs","word_nosc":"loirs","lemma":"loir","pos":"NOM"} ,
		{"word":"lois","word_nosc":"lois","lemma":"loi","pos":"NOM"} ,
		{"word":"loisir","word_nosc":"loisir","lemma":"loisir","pos":"NOM"} ,
		{"word":"loisirs","word_nosc":"loisirs","lemma":"loisir","pos":"NOM"} ,
		{"word":"lokoum","word_nosc":"lokoum","lemma":"lokoum","pos":"NOM"} ,
		{"word":"lolita","word_nosc":"lolita","lemma":"lolita","pos":"NOM"} ,
		{"word":"lolitas","word_nosc":"lolitas","lemma":"lolita","pos":"NOM"} ,
		{"word":"lolo","word_nosc":"lolo","lemma":"lolo","pos":"NOM"} ,
		{"word":"lolos","word_nosc":"lolos","lemma":"lolo","pos":"NOM"} ,
		{"word":"lombalgie","word_nosc":"lombalgie","lemma":"lombalgie","pos":"NOM"} ,
		{"word":"lombarde","word_nosc":"lombarde","lemma":"lombard","pos":"NOM"} ,
		{"word":"lombardo","word_nosc":"lombardo","lemma":"lombardo","pos":"NOM"} ,
		{"word":"lombes","word_nosc":"lombes","lemma":"lombes","pos":"NOM"} ,
		{"word":"lombostats","word_nosc":"lombostats","lemma":"lombostat","pos":"NOM"} ,
		{"word":"lombric","word_nosc":"lombric","lemma":"lombric","pos":"NOM"} ,
		{"word":"lombrics","word_nosc":"lombrics","lemma":"lombric","pos":"NOM"} ,
		{"word":"lompe","word_nosc":"lompe","lemma":"lompe","pos":"NOM"} ,
		{"word":"londrès","word_nosc":"londres","lemma":"londrès","pos":"NOM"} ,
		{"word":"long","word_nosc":"long","lemma":"long","pos":"NOM"} ,
		{"word":"long-courrier","word_nosc":"long-courrier","lemma":"long-courrier","pos":"NOM"} ,
		{"word":"long-courriers","word_nosc":"long-courriers","lemma":"long-courrier","pos":"NOM"} ,
		{"word":"long-métrage","word_nosc":"long-metrage","lemma":"long-métrage","pos":"NOM"} ,
		{"word":"longane","word_nosc":"longane","lemma":"longane","pos":"NOM"} ,
		{"word":"longanimité","word_nosc":"longanimite","lemma":"longanimité","pos":"NOM"} ,
		{"word":"longe","word_nosc":"longe","lemma":"longe","pos":"NOM"} ,
		{"word":"longeron","word_nosc":"longeron","lemma":"longeron","pos":"NOM"} ,
		{"word":"longerons","word_nosc":"longerons","lemma":"longeron","pos":"NOM"} ,
		{"word":"longes","word_nosc":"longes","lemma":"longe","pos":"NOM"} ,
		{"word":"longicornes","word_nosc":"longicornes","lemma":"longicorne","pos":"NOM"} ,
		{"word":"longitude","word_nosc":"longitude","lemma":"longitude","pos":"NOM"} ,
		{"word":"longrines","word_nosc":"longrines","lemma":"longrine","pos":"NOM"} ,
		{"word":"longs","word_nosc":"longs","lemma":"long","pos":"NOM"} ,
		{"word":"longue","word_nosc":"longue","lemma":"longue","pos":"NOM"} ,
		{"word":"longue-vue","word_nosc":"longue-vue","lemma":"longue-vue","pos":"NOM"} ,
		{"word":"longues","word_nosc":"longues","lemma":"longue","pos":"NOM"} ,
		{"word":"longues-vues","word_nosc":"longues-vues","lemma":"longue-vue","pos":"NOM"} ,
		{"word":"longuet","word_nosc":"longuet","lemma":"longuet","pos":"NOM"} ,
		{"word":"longueur","word_nosc":"longueur","lemma":"longueur","pos":"NOM"} ,
		{"word":"longueurs","word_nosc":"longueurs","lemma":"longueur","pos":"NOM"} ,
		{"word":"longévité","word_nosc":"longevite","lemma":"longévité","pos":"NOM"} ,
		{"word":"look","word_nosc":"look","lemma":"look","pos":"NOM"} ,
		{"word":"looks","word_nosc":"looks","lemma":"look","pos":"NOM"} ,
		{"word":"looping","word_nosc":"looping","lemma":"looping","pos":"NOM"} ,
		{"word":"loopings","word_nosc":"loopings","lemma":"looping","pos":"NOM"} ,
		{"word":"looser","word_nosc":"looser","lemma":"looser","pos":"NOM"} ,
		{"word":"loosers","word_nosc":"loosers","lemma":"looser","pos":"NOM"} ,
		{"word":"lopaille","word_nosc":"lopaille","lemma":"lopaille","pos":"NOM"} ,
		{"word":"lope","word_nosc":"lope","lemma":"lope","pos":"NOM"} ,
		{"word":"lopes","word_nosc":"lopes","lemma":"lope","pos":"NOM"} ,
		{"word":"lopette","word_nosc":"lopette","lemma":"lopette","pos":"NOM"} ,
		{"word":"lopettes","word_nosc":"lopettes","lemma":"lopette","pos":"NOM"} ,
		{"word":"lopin","word_nosc":"lopin","lemma":"lopin","pos":"NOM"} ,
		{"word":"lopins","word_nosc":"lopins","lemma":"lopin","pos":"NOM"} ,
		{"word":"loquacité","word_nosc":"loquacite","lemma":"loquacité","pos":"NOM"} ,
		{"word":"loque","word_nosc":"loque","lemma":"loque","pos":"NOM"} ,
		{"word":"loquedu","word_nosc":"loquedu","lemma":"loquedu","pos":"NOM"} ,
		{"word":"loquedus","word_nosc":"loquedus","lemma":"loquedu","pos":"NOM"} ,
		{"word":"loques","word_nosc":"loques","lemma":"loque","pos":"NOM"} ,
		{"word":"loquet","word_nosc":"loquet","lemma":"loquet","pos":"NOM"} ,
		{"word":"loqueteau","word_nosc":"loqueteau","lemma":"loqueteau","pos":"NOM"} ,
		{"word":"loquets","word_nosc":"loquets","lemma":"loquet","pos":"NOM"} ,
		{"word":"loran","word_nosc":"loran","lemma":"loran","pos":"NOM"} ,
		{"word":"lord","word_nosc":"lord","lemma":"lord","pos":"NOM"} ,
		{"word":"lord-maire","word_nosc":"lord-maire","lemma":"lord-maire","pos":"NOM"} ,
		{"word":"lordose","word_nosc":"lordose","lemma":"lordose","pos":"NOM"} ,
		{"word":"lords","word_nosc":"lords","lemma":"lord","pos":"NOM"} ,
		{"word":"lorette","word_nosc":"lorette","lemma":"lorette","pos":"NOM"} ,
		{"word":"lorettes","word_nosc":"lorettes","lemma":"lorette","pos":"NOM"} ,
		{"word":"lorgnette","word_nosc":"lorgnette","lemma":"lorgnette","pos":"NOM"} ,
		{"word":"lorgnettes","word_nosc":"lorgnettes","lemma":"lorgnette","pos":"NOM"} ,
		{"word":"lorgnon","word_nosc":"lorgnon","lemma":"lorgnon","pos":"NOM"} ,
		{"word":"lorgnons","word_nosc":"lorgnons","lemma":"lorgnon","pos":"NOM"} ,
		{"word":"lori","word_nosc":"lori","lemma":"lori","pos":"NOM"} ,
		{"word":"loriot","word_nosc":"loriot","lemma":"loriot","pos":"NOM"} ,
		{"word":"loriots","word_nosc":"loriots","lemma":"loriot","pos":"NOM"} ,
		{"word":"loris","word_nosc":"loris","lemma":"loris","pos":"NOM"} ,
		{"word":"lorrain","word_nosc":"lorrain","lemma":"lorrain","pos":"NOM"} ,
		{"word":"lorraine","word_nosc":"lorraine","lemma":"lorrain","pos":"NOM"} ,
		{"word":"lorrains","word_nosc":"lorrains","lemma":"lorrain","pos":"NOM"} ,
		{"word":"los","word_nosc":"los","lemma":"los","pos":"NOM"} ,
		{"word":"losange","word_nosc":"losange","lemma":"losange","pos":"NOM"} ,
		{"word":"losanges","word_nosc":"losanges","lemma":"losange","pos":"NOM"} ,
		{"word":"loser","word_nosc":"loser","lemma":"loser","pos":"NOM"} ,
		{"word":"losers","word_nosc":"losers","lemma":"loser","pos":"NOM"} ,
		{"word":"lot","word_nosc":"lot","lemma":"lot","pos":"NOM"} ,
		{"word":"loterie","word_nosc":"loterie","lemma":"loterie","pos":"NOM"} ,
		{"word":"loteries","word_nosc":"loteries","lemma":"loterie","pos":"NOM"} ,
		{"word":"lotier","word_nosc":"lotier","lemma":"lotier","pos":"NOM"} ,
		{"word":"lotion","word_nosc":"lotion","lemma":"lotion","pos":"NOM"} ,
		{"word":"lotions","word_nosc":"lotions","lemma":"lotion","pos":"NOM"} ,
		{"word":"lotissement","word_nosc":"lotissement","lemma":"lotissement","pos":"NOM"} ,
		{"word":"lotissements","word_nosc":"lotissements","lemma":"lotissement","pos":"NOM"} ,
		{"word":"loto","word_nosc":"loto","lemma":"loto","pos":"NOM"} ,
		{"word":"lotos","word_nosc":"lotos","lemma":"loto","pos":"NOM"} ,
		{"word":"lots","word_nosc":"lots","lemma":"lot","pos":"NOM"} ,
		{"word":"lotta","word_nosc":"lotta","lemma":"lotta","pos":"NOM"} ,
		{"word":"lotte","word_nosc":"lotte","lemma":"lotte","pos":"NOM"} ,
		{"word":"lottes","word_nosc":"lottes","lemma":"lotte","pos":"NOM"} ,
		{"word":"lotus","word_nosc":"lotus","lemma":"lotus","pos":"NOM"} ,
		{"word":"louage","word_nosc":"louage","lemma":"louage","pos":"NOM"} ,
		{"word":"louange","word_nosc":"louange","lemma":"louange","pos":"NOM"} ,
		{"word":"louanges","word_nosc":"louanges","lemma":"louange","pos":"NOM"} ,
		{"word":"louangeur","word_nosc":"louangeur","lemma":"louangeur","pos":"NOM"} ,
		{"word":"loubard","word_nosc":"loubard","lemma":"loubard","pos":"NOM"} ,
		{"word":"loubarde","word_nosc":"loubarde","lemma":"loubard","pos":"NOM"} ,
		{"word":"loubards","word_nosc":"loubards","lemma":"loubard","pos":"NOM"} ,
		{"word":"louche","word_nosc":"louche","lemma":"louche","pos":"NOM"} ,
		{"word":"louchebem","word_nosc":"louchebem","lemma":"louchebem","pos":"NOM"} ,
		{"word":"louchement","word_nosc":"louchement","lemma":"louchement","pos":"NOM"} ,
		{"word":"louches","word_nosc":"louches","lemma":"louche","pos":"NOM"} ,
		{"word":"louchet","word_nosc":"louchet","lemma":"louchet","pos":"NOM"} ,
		{"word":"loucheur","word_nosc":"loucheur","lemma":"loucheur","pos":"NOM"} ,
		{"word":"loucheuse","word_nosc":"loucheuse","lemma":"loucheur","pos":"NOM"} ,
		{"word":"louchon","word_nosc":"louchon","lemma":"louchon","pos":"NOM"} ,
		{"word":"louchons","word_nosc":"louchons","lemma":"louchon","pos":"NOM"} ,
		{"word":"louchébème","word_nosc":"louchebeme","lemma":"louchébème","pos":"NOM"} ,
		{"word":"loueur","word_nosc":"loueur","lemma":"loueur","pos":"NOM"} ,
		{"word":"loueurs","word_nosc":"loueurs","lemma":"loueur","pos":"NOM"} ,
		{"word":"loueuse","word_nosc":"loueuse","lemma":"loueur","pos":"NOM"} ,
		{"word":"loufiat","word_nosc":"loufiat","lemma":"loufiat","pos":"NOM"} ,
		{"word":"loufiats","word_nosc":"loufiats","lemma":"loufiat","pos":"NOM"} ,
		{"word":"loufoquerie","word_nosc":"loufoquerie","lemma":"loufoquerie","pos":"NOM"} ,
		{"word":"loufoqueries","word_nosc":"loufoqueries","lemma":"loufoquerie","pos":"NOM"} ,
		{"word":"louis","word_nosc":"louis","lemma":"louis","pos":"NOM"} ,
		{"word":"louise-bonne","word_nosc":"louise-bonne","lemma":"louise-bonne","pos":"NOM"} ,
		{"word":"louisianais","word_nosc":"louisianais","lemma":"louisianais","pos":"NOM"} ,
		{"word":"loukoum","word_nosc":"loukoum","lemma":"loukoum","pos":"NOM"} ,
		{"word":"loukoums","word_nosc":"loukoums","lemma":"loukoum","pos":"NOM"} ,
		{"word":"loulou","word_nosc":"loulou","lemma":"loulou","pos":"NOM"} ,
		{"word":"loulous","word_nosc":"loulous","lemma":"loulou","pos":"NOM"} ,
		{"word":"louloute","word_nosc":"louloute","lemma":"louloute","pos":"NOM"} ,
		{"word":"louloutes","word_nosc":"louloutes","lemma":"louloute","pos":"NOM"} ,
		{"word":"louloutte","word_nosc":"louloutte","lemma":"louloutte","pos":"NOM"} ,
		{"word":"loup","word_nosc":"loup","lemma":"loup","pos":"NOM"} ,
		{"word":"loup-cervier","word_nosc":"loup-cervier","lemma":"loup-cervier","pos":"NOM"} ,
		{"word":"loup-garou","word_nosc":"loup-garou","lemma":"loup-garou","pos":"NOM"} ,
		{"word":"loupe","word_nosc":"loupe","lemma":"loupe","pos":"NOM"} ,
		{"word":"loupes","word_nosc":"loupes","lemma":"loupe","pos":"NOM"} ,
		{"word":"loupiat","word_nosc":"loupiat","lemma":"loupiat","pos":"NOM"} ,
		{"word":"loupiot","word_nosc":"loupiot","lemma":"loupiot","pos":"NOM"} ,
		{"word":"loupiote","word_nosc":"loupiote","lemma":"loupiote","pos":"NOM"} ,
		{"word":"loupiotes","word_nosc":"loupiotes","lemma":"loupiote","pos":"NOM"} ,
		{"word":"loupiots","word_nosc":"loupiots","lemma":"loupiot","pos":"NOM"} ,
		{"word":"loupiotte","word_nosc":"loupiotte","lemma":"loupiotte","pos":"NOM"} ,
		{"word":"loupiottes","word_nosc":"loupiottes","lemma":"loupiotte","pos":"NOM"} ,
		{"word":"loups","word_nosc":"loups","lemma":"loup","pos":"NOM"} ,
		{"word":"loups-cerviers","word_nosc":"loups-cerviers","lemma":"loup-cervier","pos":"NOM"} ,
		{"word":"loups-garous","word_nosc":"loups-garous","lemma":"loup-garou","pos":"NOM"} ,
		{"word":"loupé","word_nosc":"loupe","lemma":"loupé","pos":"NOM"} ,
		{"word":"loupés","word_nosc":"loupes","lemma":"loupé","pos":"NOM"} ,
		{"word":"lourd","word_nosc":"lourd","lemma":"lourd","pos":"NOM"} ,
		{"word":"lourdaud","word_nosc":"lourdaud","lemma":"lourdaud","pos":"NOM"} ,
		{"word":"lourdauds","word_nosc":"lourdauds","lemma":"lourdaud","pos":"NOM"} ,
		{"word":"lourde","word_nosc":"lourde","lemma":"lourd","pos":"NOM"} ,
		{"word":"lourderie","word_nosc":"lourderie","lemma":"lourderie","pos":"NOM"} ,
		{"word":"lourdes","word_nosc":"lourdes","lemma":"lourd","pos":"NOM"} ,
		{"word":"lourdeur","word_nosc":"lourdeur","lemma":"lourdeur","pos":"NOM"} ,
		{"word":"lourdeurs","word_nosc":"lourdeurs","lemma":"lourdeur","pos":"NOM"} ,
		{"word":"lourds","word_nosc":"lourds","lemma":"lourd","pos":"NOM"} ,
		{"word":"loustic","word_nosc":"loustic","lemma":"loustic","pos":"NOM"} ,
		{"word":"loustics","word_nosc":"loustics","lemma":"loustic","pos":"NOM"} ,
		{"word":"loute","word_nosc":"loute","lemma":"loute","pos":"NOM"} ,
		{"word":"loutre","word_nosc":"loutre","lemma":"loutre","pos":"NOM"} ,
		{"word":"loutres","word_nosc":"loutres","lemma":"loutre","pos":"NOM"} ,
		{"word":"louve","word_nosc":"louve","lemma":"loup","pos":"NOM"} ,
		{"word":"louves","word_nosc":"louves","lemma":"loup","pos":"NOM"} ,
		{"word":"louveteau","word_nosc":"louveteau","lemma":"louveteau","pos":"NOM"} ,
		{"word":"louveteaux","word_nosc":"louveteaux","lemma":"louveteau","pos":"NOM"} ,
		{"word":"louvetiers","word_nosc":"louvetiers","lemma":"louvetier","pos":"NOM"} ,
		{"word":"louvoiements","word_nosc":"louvoiements","lemma":"louvoiement","pos":"NOM"} ,
		{"word":"louée","word_nosc":"louee","lemma":"louée","pos":"NOM"} ,
		{"word":"louées","word_nosc":"louees","lemma":"louée","pos":"NOM"} ,
		{"word":"loyalisme","word_nosc":"loyalisme","lemma":"loyalisme","pos":"NOM"} ,
		{"word":"loyaliste","word_nosc":"loyaliste","lemma":"loyaliste","pos":"NOM"} ,
		{"word":"loyalistes","word_nosc":"loyalistes","lemma":"loyaliste","pos":"NOM"} ,
		{"word":"loyauté","word_nosc":"loyaute","lemma":"loyauté","pos":"NOM"} ,
		{"word":"loyautés","word_nosc":"loyautes","lemma":"loyauté","pos":"NOM"} ,
		{"word":"loyer","word_nosc":"loyer","lemma":"loyer","pos":"NOM"} ,
		{"word":"loyers","word_nosc":"loyers","lemma":"loyer","pos":"NOM"} ,
		{"word":"lsd","word_nosc":"lsd","lemma":"lsd","pos":"NOM"} ,
		{"word":"lubie","word_nosc":"lubie","lemma":"lubie","pos":"NOM"} ,
		{"word":"lubies","word_nosc":"lubies","lemma":"lubie","pos":"NOM"} ,
		{"word":"lubricité","word_nosc":"lubricite","lemma":"lubricité","pos":"NOM"} ,
		{"word":"lubrifiant","word_nosc":"lubrifiant","lemma":"lubrifiant","pos":"NOM"} ,
		{"word":"lubrifiants","word_nosc":"lubrifiants","lemma":"lubrifiant","pos":"NOM"} ,
		{"word":"lubrification","word_nosc":"lubrification","lemma":"lubrification","pos":"NOM"} ,
		{"word":"lucarne","word_nosc":"lucarne","lemma":"lucarne","pos":"NOM"} ,
		{"word":"lucarnes","word_nosc":"lucarnes","lemma":"lucarne","pos":"NOM"} ,
		{"word":"luce","word_nosc":"luce","lemma":"luc","pos":"NOM"} ,
		{"word":"luce","word_nosc":"luce","lemma":"luce","pos":"NOM"} ,
		{"word":"lucet","word_nosc":"lucet","lemma":"lucet","pos":"NOM"} ,
		{"word":"lucidité","word_nosc":"lucidite","lemma":"lucidité","pos":"NOM"} ,
		{"word":"lucidités","word_nosc":"lucidites","lemma":"lucidité","pos":"NOM"} ,
		{"word":"lucilie","word_nosc":"lucilie","lemma":"lucilie","pos":"NOM"} ,
		{"word":"luciole","word_nosc":"luciole","lemma":"luciole","pos":"NOM"} ,
		{"word":"lucioles","word_nosc":"lucioles","lemma":"luciole","pos":"NOM"} ,
		{"word":"lucite","word_nosc":"lucite","lemma":"lucite","pos":"NOM"} ,
		{"word":"lucre","word_nosc":"lucre","lemma":"lucre","pos":"NOM"} ,
		{"word":"luddite","word_nosc":"luddite","lemma":"luddite","pos":"NOM"} ,
		{"word":"ludion","word_nosc":"ludion","lemma":"ludion","pos":"NOM"} ,
		{"word":"ludions","word_nosc":"ludions","lemma":"ludion","pos":"NOM"} ,
		{"word":"luette","word_nosc":"luette","lemma":"luette","pos":"NOM"} ,
		{"word":"luettes","word_nosc":"luettes","lemma":"luette","pos":"NOM"} ,
		{"word":"lueur","word_nosc":"lueur","lemma":"lueur","pos":"NOM"} ,
		{"word":"lueurs","word_nosc":"lueurs","lemma":"lueur","pos":"NOM"} ,
		{"word":"luffa","word_nosc":"luffa","lemma":"luffa","pos":"NOM"} ,
		{"word":"luge","word_nosc":"luge","lemma":"luge","pos":"NOM"} ,
		{"word":"luger","word_nosc":"luger","lemma":"luger","pos":"NOM"} ,
		{"word":"lugers","word_nosc":"lugers","lemma":"luger","pos":"NOM"} ,
		{"word":"luges","word_nosc":"luges","lemma":"luge","pos":"NOM"} ,
		{"word":"luisance","word_nosc":"luisance","lemma":"luisance","pos":"NOM"} ,
		{"word":"luisances","word_nosc":"luisances","lemma":"luisance","pos":"NOM"} ,
		{"word":"luisant","word_nosc":"luisant","lemma":"luisant","pos":"NOM"} ,
		{"word":"luisants","word_nosc":"luisants","lemma":"luisant","pos":"NOM"} ,
		{"word":"lulu","word_nosc":"lulu","lemma":"lulu","pos":"NOM"} ,
		{"word":"lulus","word_nosc":"lulus","lemma":"lulu","pos":"NOM"} ,
		{"word":"lumbago","word_nosc":"lumbago","lemma":"lumbago","pos":"NOM"} ,
		{"word":"lumbagos","word_nosc":"lumbagos","lemma":"lumbago","pos":"NOM"} ,
		{"word":"lumen","word_nosc":"lumen","lemma":"lumen","pos":"NOM"} ,
		{"word":"lumignon","word_nosc":"lumignon","lemma":"lumignon","pos":"NOM"} ,
		{"word":"lumignons","word_nosc":"lumignons","lemma":"lumignon","pos":"NOM"} ,
		{"word":"luminaire","word_nosc":"luminaire","lemma":"luminaire","pos":"NOM"} ,
		{"word":"luminaires","word_nosc":"luminaires","lemma":"luminaire","pos":"NOM"} ,
		{"word":"luminescence","word_nosc":"luminescence","lemma":"luminescence","pos":"NOM"} ,
		{"word":"luminosité","word_nosc":"luminosite","lemma":"luminosité","pos":"NOM"} ,
		{"word":"luminosités","word_nosc":"luminosites","lemma":"luminosité","pos":"NOM"} ,
		{"word":"lumière","word_nosc":"lumiere","lemma":"lumière","pos":"NOM"} ,
		{"word":"lumières","word_nosc":"lumieres","lemma":"lumière","pos":"NOM"} ,
		{"word":"lump","word_nosc":"lump","lemma":"lump","pos":"NOM"} ,
		{"word":"lunaire","word_nosc":"lunaire","lemma":"lunaire","pos":"NOM"} ,
		{"word":"lunaison","word_nosc":"lunaison","lemma":"lunaison","pos":"NOM"} ,
		{"word":"lunaisons","word_nosc":"lunaisons","lemma":"lunaison","pos":"NOM"} ,
		{"word":"lunch","word_nosc":"lunch","lemma":"lunch","pos":"NOM"} ,
		{"word":"lunches","word_nosc":"lunches","lemma":"lunche","pos":"NOM"} ,
		{"word":"lundi","word_nosc":"lundi","lemma":"lundi","pos":"NOM"} ,
		{"word":"lundis","word_nosc":"lundis","lemma":"lundi","pos":"NOM"} ,
		{"word":"lune","word_nosc":"lune","lemma":"lune","pos":"NOM"} ,
		{"word":"lunes","word_nosc":"lunes","lemma":"lune","pos":"NOM"} ,
		{"word":"lunetier","word_nosc":"lunetier","lemma":"lunetier","pos":"NOM"} ,
		{"word":"lunette","word_nosc":"lunette","lemma":"lunette","pos":"NOM"} ,
		{"word":"lunetteries","word_nosc":"lunetteries","lemma":"lunetterie","pos":"NOM"} ,
		{"word":"lunettes","word_nosc":"lunettes","lemma":"lunette","pos":"NOM"} ,
		{"word":"lunule","word_nosc":"lunule","lemma":"lunule","pos":"NOM"} ,
		{"word":"lunules","word_nosc":"lunules","lemma":"lunule","pos":"NOM"} ,
		{"word":"lupanar","word_nosc":"lupanar","lemma":"lupanar","pos":"NOM"} ,
		{"word":"lupanars","word_nosc":"lupanars","lemma":"lupanar","pos":"NOM"} ,
		{"word":"lupin","word_nosc":"lupin","lemma":"lupin","pos":"NOM"} ,
		{"word":"lupins","word_nosc":"lupins","lemma":"lupin","pos":"NOM"} ,
		{"word":"lupus","word_nosc":"lupus","lemma":"lupus","pos":"NOM"} ,
		{"word":"lur","word_nosc":"lur","lemma":"lur","pos":"NOM"} ,
		{"word":"lurex","word_nosc":"lurex","lemma":"lurex","pos":"NOM"} ,
		{"word":"luron","word_nosc":"luron","lemma":"luron","pos":"NOM"} ,
		{"word":"luronne","word_nosc":"luronne","lemma":"luron","pos":"NOM"} ,
		{"word":"luronnes","word_nosc":"luronnes","lemma":"luron","pos":"NOM"} ,
		{"word":"lurons","word_nosc":"lurons","lemma":"luron","pos":"NOM"} ,
		{"word":"lustrage","word_nosc":"lustrage","lemma":"lustrage","pos":"NOM"} ,
		{"word":"lustre","word_nosc":"lustre","lemma":"lustre","pos":"NOM"} ,
		{"word":"lustres","word_nosc":"lustres","lemma":"lustre","pos":"NOM"} ,
		{"word":"lustreur","word_nosc":"lustreur","lemma":"lustreur","pos":"NOM"} ,
		{"word":"lustreuse","word_nosc":"lustreuse","lemma":"lustreur","pos":"NOM"} ,
		{"word":"lustrine","word_nosc":"lustrine","lemma":"lustrine","pos":"NOM"} ,
		{"word":"lustucru","word_nosc":"lustucru","lemma":"lustucru","pos":"NOM"} ,
		{"word":"lut","word_nosc":"lut","lemma":"lut","pos":"NOM"} ,
		{"word":"luth","word_nosc":"luth","lemma":"luth","pos":"NOM"} ,
		{"word":"lutherie","word_nosc":"lutherie","lemma":"lutherie","pos":"NOM"} ,
		{"word":"luthier","word_nosc":"luthier","lemma":"luthier","pos":"NOM"} ,
		{"word":"luths","word_nosc":"luths","lemma":"luth","pos":"NOM"} ,
		{"word":"luthéranisme","word_nosc":"lutheranisme","lemma":"luthéranisme","pos":"NOM"} ,
		{"word":"luthérianisme","word_nosc":"lutherianisme","lemma":"luthérianisme","pos":"NOM"} ,
		{"word":"luthérien","word_nosc":"lutherien","lemma":"luthérien","pos":"NOM"} ,
		{"word":"luthérienne","word_nosc":"lutherienne","lemma":"luthérien","pos":"NOM"} ,
		{"word":"luthériens","word_nosc":"lutheriens","lemma":"luthérien","pos":"NOM"} ,
		{"word":"lutin","word_nosc":"lutin","lemma":"lutin","pos":"NOM"} ,
		{"word":"lutins","word_nosc":"lutins","lemma":"lutin","pos":"NOM"} ,
		{"word":"lutrin","word_nosc":"lutrin","lemma":"lutrin","pos":"NOM"} ,
		{"word":"lutrins","word_nosc":"lutrins","lemma":"lutrin","pos":"NOM"} ,
		{"word":"lutte","word_nosc":"lutte","lemma":"lutte","pos":"NOM"} ,
		{"word":"luttes","word_nosc":"luttes","lemma":"lutte","pos":"NOM"} ,
		{"word":"lutteur","word_nosc":"lutteur","lemma":"lutteur","pos":"NOM"} ,
		{"word":"lutteurs","word_nosc":"lutteurs","lemma":"lutteur","pos":"NOM"} ,
		{"word":"lutteuse","word_nosc":"lutteuse","lemma":"lutteur","pos":"NOM"} ,
		{"word":"lutteuses","word_nosc":"lutteuses","lemma":"lutteur","pos":"NOM"} ,
		{"word":"lutz","word_nosc":"lutz","lemma":"lutz","pos":"NOM"} ,
		{"word":"lux","word_nosc":"lux","lemma":"lux","pos":"NOM"} ,
		{"word":"luxation","word_nosc":"luxation","lemma":"luxation","pos":"NOM"} ,
		{"word":"luxations","word_nosc":"luxations","lemma":"luxation","pos":"NOM"} ,
		{"word":"luxe","word_nosc":"luxe","lemma":"luxe","pos":"NOM"} ,
		{"word":"luxembourgeois","word_nosc":"luxembourgeois","lemma":"luxembourgeois","pos":"NOM"} ,
		{"word":"luxes","word_nosc":"luxes","lemma":"luxe","pos":"NOM"} ,
		{"word":"luxure","word_nosc":"luxure","lemma":"luxure","pos":"NOM"} ,
		{"word":"luxuriance","word_nosc":"luxuriance","lemma":"luxuriance","pos":"NOM"} ,
		{"word":"luzerne","word_nosc":"luzerne","lemma":"luzerne","pos":"NOM"} ,
		{"word":"luzernes","word_nosc":"luzernes","lemma":"luzerne","pos":"NOM"} ,
		{"word":"luzernières","word_nosc":"luzernieres","lemma":"luzernière","pos":"NOM"} ,
		{"word":"lycanthrope","word_nosc":"lycanthrope","lemma":"lycanthrope","pos":"NOM"} ,
		{"word":"lycanthropes","word_nosc":"lycanthropes","lemma":"lycanthrope","pos":"NOM"} ,
		{"word":"lycanthropie","word_nosc":"lycanthropie","lemma":"lycanthropie","pos":"NOM"} ,
		{"word":"lycaons","word_nosc":"lycaons","lemma":"lycaon","pos":"NOM"} ,
		{"word":"lychee","word_nosc":"lychee","lemma":"lychee","pos":"NOM"} ,
		{"word":"lychees","word_nosc":"lychees","lemma":"lychee","pos":"NOM"} ,
		{"word":"lychnis","word_nosc":"lychnis","lemma":"lychnis","pos":"NOM"} ,
		{"word":"lycien","word_nosc":"lycien","lemma":"lycien","pos":"NOM"} ,
		{"word":"lyciennes","word_nosc":"lyciennes","lemma":"lycien","pos":"NOM"} ,
		{"word":"lycoperdon","word_nosc":"lycoperdon","lemma":"lycoperdon","pos":"NOM"} ,
		{"word":"lycoperdons","word_nosc":"lycoperdons","lemma":"lycoperdon","pos":"NOM"} ,
		{"word":"lycra","word_nosc":"lycra","lemma":"lycra","pos":"NOM"} ,
		{"word":"lycée","word_nosc":"lycee","lemma":"lycée","pos":"NOM"} ,
		{"word":"lycéen","word_nosc":"lyceen","lemma":"lycéen","pos":"NOM"} ,
		{"word":"lycéenne","word_nosc":"lyceenne","lemma":"lycéen","pos":"NOM"} ,
		{"word":"lycéennes","word_nosc":"lyceennes","lemma":"lycéen","pos":"NOM"} ,
		{"word":"lycéens","word_nosc":"lyceens","lemma":"lycéen","pos":"NOM"} ,
		{"word":"lycées","word_nosc":"lycees","lemma":"lycée","pos":"NOM"} ,
		{"word":"lymphangite","word_nosc":"lymphangite","lemma":"lymphangite","pos":"NOM"} ,
		{"word":"lymphatique","word_nosc":"lymphatique","lemma":"lymphatique","pos":"NOM"} ,
		{"word":"lymphatisme","word_nosc":"lymphatisme","lemma":"lymphatisme","pos":"NOM"} ,
		{"word":"lymphe","word_nosc":"lymphe","lemma":"lymphe","pos":"NOM"} ,
		{"word":"lymphes","word_nosc":"lymphes","lemma":"lymphe","pos":"NOM"} ,
		{"word":"lymphocyte","word_nosc":"lymphocyte","lemma":"lymphocyte","pos":"NOM"} ,
		{"word":"lymphocytes","word_nosc":"lymphocytes","lemma":"lymphocyte","pos":"NOM"} ,
		{"word":"lymphocytose","word_nosc":"lymphocytose","lemma":"lymphocytose","pos":"NOM"} ,
		{"word":"lymphome","word_nosc":"lymphome","lemma":"lymphome","pos":"NOM"} ,
		{"word":"lymphomes","word_nosc":"lymphomes","lemma":"lymphome","pos":"NOM"} ,
		{"word":"lymphopénie","word_nosc":"lymphopenie","lemma":"lymphopénie","pos":"NOM"} ,
		{"word":"lymphosarcome","word_nosc":"lymphosarcome","lemma":"lymphosarcome","pos":"NOM"} ,
		{"word":"lynch","word_nosc":"lynch","lemma":"lynch","pos":"NOM"} ,
		{"word":"lynchage","word_nosc":"lynchage","lemma":"lynchage","pos":"NOM"} ,
		{"word":"lynchages","word_nosc":"lynchages","lemma":"lynchage","pos":"NOM"} ,
		{"word":"lyncheurs","word_nosc":"lyncheurs","lemma":"lyncheur","pos":"NOM"} ,
		{"word":"lynx","word_nosc":"lynx","lemma":"lynx","pos":"NOM"} ,
		{"word":"lyonnais","word_nosc":"lyonnais","lemma":"lyonnais","pos":"NOM"} ,
		{"word":"lyre","word_nosc":"lyre","lemma":"lyre","pos":"NOM"} ,
		{"word":"lyres","word_nosc":"lyres","lemma":"lyre","pos":"NOM"} ,
		{"word":"lyrisme","word_nosc":"lyrisme","lemma":"lyrisme","pos":"NOM"} ,
		{"word":"lys","word_nosc":"lys","lemma":"lys","pos":"NOM"} ,
		{"word":"lyse","word_nosc":"lyse","lemma":"lyse","pos":"NOM"} ,
		{"word":"lysimaque","word_nosc":"lysimaque","lemma":"lysimaque","pos":"NOM"} ,
		{"word":"lysine","word_nosc":"lysine","lemma":"lysine","pos":"NOM"} ,
		{"word":"lysosomes","word_nosc":"lysosomes","lemma":"lysosome","pos":"NOM"} ,
		{"word":"lâchage","word_nosc":"lachage","lemma":"lâchage","pos":"NOM"} ,
		{"word":"lâche","word_nosc":"lache","lemma":"lâche","pos":"NOM"} ,
		{"word":"lâcher","word_nosc":"lacher","lemma":"lâcher","pos":"NOM"} ,
		{"word":"lâchers","word_nosc":"lachers","lemma":"lâcher","pos":"NOM"} ,
		{"word":"lâches","word_nosc":"laches","lemma":"lâche","pos":"NOM"} ,
		{"word":"lâcheté","word_nosc":"lachete","lemma":"lâcheté","pos":"NOM"} ,
		{"word":"lâchetés","word_nosc":"lachetes","lemma":"lâcheté","pos":"NOM"} ,
		{"word":"lâcheur","word_nosc":"lacheur","lemma":"lâcheur","pos":"NOM"} ,
		{"word":"lâcheurs","word_nosc":"lacheurs","lemma":"lâcheur","pos":"NOM"} ,
		{"word":"lâcheuse","word_nosc":"lacheuse","lemma":"lâcheur","pos":"NOM"} ,
		{"word":"lèche","word_nosc":"leche","lemma":"lèche","pos":"NOM"} ,
		{"word":"lèche-botte","word_nosc":"leche-botte","lemma":"lèche-botte","pos":"NOM"} ,
		{"word":"lèche-bottes","word_nosc":"leche-bottes","lemma":"lèche-bottes","pos":"NOM"} ,
		{"word":"lèche-cul","word_nosc":"leche-cul","lemma":"lèche-cul","pos":"NOM"} ,
		{"word":"lèche-vitrine","word_nosc":"leche-vitrine","lemma":"lèche-vitrine","pos":"NOM"} ,
		{"word":"lèche-vitrines","word_nosc":"leche-vitrines","lemma":"lèche-vitrines","pos":"NOM"} ,
		{"word":"lèchefrite","word_nosc":"lechefrite","lemma":"lèchefrite","pos":"NOM"} ,
		{"word":"lècheries","word_nosc":"lecheries","lemma":"lècherie","pos":"NOM"} ,
		{"word":"lèches","word_nosc":"leches","lemma":"lèche","pos":"NOM"} ,
		{"word":"lèpre","word_nosc":"lepre","lemma":"lèpre","pos":"NOM"} ,
		{"word":"lèpres","word_nosc":"lepres","lemma":"lèpre","pos":"NOM"} ,
		{"word":"lèse-majesté","word_nosc":"lese-majeste","lemma":"lèse-majesté","pos":"NOM"} ,
		{"word":"lèvre","word_nosc":"levre","lemma":"lèvre","pos":"NOM"} ,
		{"word":"lèvres","word_nosc":"levres","lemma":"lèvre","pos":"NOM"} ,
		{"word":"lé","word_nosc":"le","lemma":"lé","pos":"NOM"} ,
		{"word":"léchage","word_nosc":"lechage","lemma":"léchage","pos":"NOM"} ,
		{"word":"léchages","word_nosc":"lechages","lemma":"léchage","pos":"NOM"} ,
		{"word":"lécheur","word_nosc":"lecheur","lemma":"lécheur","pos":"NOM"} ,
		{"word":"lécheurs","word_nosc":"lecheurs","lemma":"lécheur","pos":"NOM"} ,
		{"word":"lécheuse","word_nosc":"lecheuse","lemma":"lécheur","pos":"NOM"} ,
		{"word":"lécheuses","word_nosc":"lecheuses","lemma":"lécheur","pos":"NOM"} ,
		{"word":"lécithine","word_nosc":"lecithine","lemma":"lécithine","pos":"NOM"} ,
		{"word":"légalisation","word_nosc":"legalisation","lemma":"légalisation","pos":"NOM"} ,
		{"word":"légalité","word_nosc":"legalite","lemma":"légalité","pos":"NOM"} ,
		{"word":"légat","word_nosc":"legat","lemma":"légat","pos":"NOM"} ,
		{"word":"légataire","word_nosc":"legataire","lemma":"légataire","pos":"NOM"} ,
		{"word":"légataires","word_nosc":"legataires","lemma":"légataire","pos":"NOM"} ,
		{"word":"légation","word_nosc":"legation","lemma":"légation","pos":"NOM"} ,
		{"word":"légations","word_nosc":"legations","lemma":"légation","pos":"NOM"} ,
		{"word":"légats","word_nosc":"legats","lemma":"légat","pos":"NOM"} ,
		{"word":"légende","word_nosc":"legende","lemma":"légende","pos":"NOM"} ,
		{"word":"légendes","word_nosc":"legendes","lemma":"légende","pos":"NOM"} ,
		{"word":"légion","word_nosc":"legion","lemma":"légion","pos":"NOM"} ,
		{"word":"légionellose","word_nosc":"legionellose","lemma":"légionellose","pos":"NOM"} ,
		{"word":"légionnaire","word_nosc":"legionnaire","lemma":"légionnaire","pos":"NOM"} ,
		{"word":"légionnaires","word_nosc":"legionnaires","lemma":"légionnaire","pos":"NOM"} ,
		{"word":"légions","word_nosc":"legions","lemma":"légion","pos":"NOM"} ,
		{"word":"législateur","word_nosc":"legislateur","lemma":"législateur","pos":"NOM"} ,
		{"word":"législateurs","word_nosc":"legislateurs","lemma":"législateur","pos":"NOM"} ,
		{"word":"législatif","word_nosc":"legislatif","lemma":"législatif","pos":"NOM"} ,
		{"word":"législatifs","word_nosc":"legislatifs","lemma":"législatif","pos":"NOM"} ,
		{"word":"législation","word_nosc":"legislation","lemma":"législation","pos":"NOM"} ,
		{"word":"législative","word_nosc":"legislative","lemma":"législatif","pos":"NOM"} ,
		{"word":"législatives","word_nosc":"legislatives","lemma":"législatif","pos":"NOM"} ,
		{"word":"législature","word_nosc":"legislature","lemma":"législature","pos":"NOM"} ,
		{"word":"légiste","word_nosc":"legiste","lemma":"légiste","pos":"NOM"} ,
		{"word":"légistes","word_nosc":"legistes","lemma":"légiste","pos":"NOM"} ,
		{"word":"légitimation","word_nosc":"legitimation","lemma":"légitimation","pos":"NOM"} ,
		{"word":"légitimations","word_nosc":"legitimations","lemma":"légitimation","pos":"NOM"} ,
		{"word":"légitimistes","word_nosc":"legitimistes","lemma":"légitimiste","pos":"NOM"} ,
		{"word":"légitimité","word_nosc":"legitimite","lemma":"légitimité","pos":"NOM"} ,
		{"word":"légume","word_nosc":"legume","lemma":"légume","pos":"NOM"} ,
		{"word":"légumes","word_nosc":"legumes","lemma":"légume","pos":"NOM"} ,
		{"word":"légumier","word_nosc":"legumier","lemma":"légumier","pos":"NOM"} ,
		{"word":"légumineuse","word_nosc":"legumineuse","lemma":"légumineux","pos":"NOM"} ,
		{"word":"légèreté","word_nosc":"legerete","lemma":"légèreté","pos":"NOM"} ,
		{"word":"légèretés","word_nosc":"legeretes","lemma":"légèreté","pos":"NOM"} ,
		{"word":"lémur","word_nosc":"lemur","lemma":"lémur","pos":"NOM"} ,
		{"word":"lémure","word_nosc":"lemure","lemma":"lémure","pos":"NOM"} ,
		{"word":"lémures","word_nosc":"lemures","lemma":"lémure","pos":"NOM"} ,
		{"word":"lémurien","word_nosc":"lemurien","lemma":"lémurien","pos":"NOM"} ,
		{"word":"lémuriens","word_nosc":"lemuriens","lemma":"lémurien","pos":"NOM"} ,
		{"word":"lénification","word_nosc":"lenification","lemma":"lénification","pos":"NOM"} ,
		{"word":"léninisme","word_nosc":"leninisme","lemma":"léninisme","pos":"NOM"} ,
		{"word":"léninistes","word_nosc":"leninistes","lemma":"léniniste","pos":"NOM"} ,
		{"word":"léopard","word_nosc":"leopard","lemma":"léopard","pos":"NOM"} ,
		{"word":"léopards","word_nosc":"leopards","lemma":"léopard","pos":"NOM"} ,
		{"word":"lépidoptère","word_nosc":"lepidoptere","lemma":"lépidoptère","pos":"NOM"} ,
		{"word":"lépidoptères","word_nosc":"lepidopteres","lemma":"lépidoptère","pos":"NOM"} ,
		{"word":"lépiotes","word_nosc":"lepiotes","lemma":"lépiote","pos":"NOM"} ,
		{"word":"lépontique","word_nosc":"lepontique","lemma":"lépontique","pos":"NOM"} ,
		{"word":"lépreuse","word_nosc":"lepreuse","lemma":"lépreux","pos":"NOM"} ,
		{"word":"lépreuses","word_nosc":"lepreuses","lemma":"lépreux","pos":"NOM"} ,
		{"word":"lépreux","word_nosc":"lepreux","lemma":"lépreux","pos":"NOM"} ,
		{"word":"léprologie","word_nosc":"leprologie","lemma":"léprologie","pos":"NOM"} ,
		{"word":"léproserie","word_nosc":"leproserie","lemma":"léproserie","pos":"NOM"} ,
		{"word":"léproseries","word_nosc":"leproseries","lemma":"léproserie","pos":"NOM"} ,
		{"word":"lérot","word_nosc":"lerot","lemma":"lérot","pos":"NOM"} ,
		{"word":"lérots","word_nosc":"lerots","lemma":"lérot","pos":"NOM"} ,
		{"word":"lés","word_nosc":"les","lemma":"lé","pos":"NOM"} ,
		{"word":"lésine","word_nosc":"lesine","lemma":"lésine","pos":"NOM"} ,
		{"word":"lésinerie","word_nosc":"lesinerie","lemma":"lésinerie","pos":"NOM"} ,
		{"word":"lésines","word_nosc":"lesines","lemma":"lésine","pos":"NOM"} ,
		{"word":"lésion","word_nosc":"lesion","lemma":"lésion","pos":"NOM"} ,
		{"word":"lésions","word_nosc":"lesions","lemma":"lésion","pos":"NOM"} ,
		{"word":"létalité","word_nosc":"letalite","lemma":"létalité","pos":"NOM"} ,
		{"word":"léthargie","word_nosc":"lethargie","lemma":"léthargie","pos":"NOM"} ,
		{"word":"léthargies","word_nosc":"lethargies","lemma":"léthargie","pos":"NOM"} ,
		{"word":"léviathan","word_nosc":"leviathan","lemma":"léviathan","pos":"NOM"} ,
		{"word":"lévitation","word_nosc":"levitation","lemma":"lévitation","pos":"NOM"} ,
		{"word":"lévite","word_nosc":"levite","lemma":"lévite","pos":"NOM"} ,
		{"word":"lévites","word_nosc":"levites","lemma":"lévite","pos":"NOM"} ,
		{"word":"lévitique","word_nosc":"levitique","lemma":"lévitique","pos":"NOM"} ,
		{"word":"lévrier","word_nosc":"levrier","lemma":"lévrier","pos":"NOM"} ,
		{"word":"lévriers","word_nosc":"levriers","lemma":"lévrier","pos":"NOM"} ,
		{"word":"lézard","word_nosc":"lezard","lemma":"lézard","pos":"NOM"} ,
		{"word":"lézarde","word_nosc":"lezarde","lemma":"lézard","pos":"NOM"} ,
		{"word":"lézardes","word_nosc":"lezardes","lemma":"lézard","pos":"NOM"} ,
		{"word":"lézards","word_nosc":"lezards","lemma":"lézard","pos":"NOM"} ,
		{"word":"m","word_nosc":"m","lemma":"m","pos":"NOM"} ,
		{"word":"ma","word_nosc":"ma","lemma":"ma","pos":"NOM"} ,
		{"word":"ma-jong","word_nosc":"ma-jong","lemma":"ma-jong","pos":"NOM"} ,
		{"word":"maboul","word_nosc":"maboul","lemma":"maboul","pos":"NOM"} ,
		{"word":"maboule","word_nosc":"maboule","lemma":"maboul","pos":"NOM"} ,
		{"word":"maboules","word_nosc":"maboules","lemma":"maboul","pos":"NOM"} ,
		{"word":"mabouls","word_nosc":"mabouls","lemma":"maboul","pos":"NOM"} ,
		{"word":"mac","word_nosc":"mac","lemma":"mac","pos":"NOM"} ,
		{"word":"macadam","word_nosc":"macadam","lemma":"macadam","pos":"NOM"} ,
		{"word":"macadamia","word_nosc":"macadamia","lemma":"macadamia","pos":"NOM"} ,
		{"word":"macaque","word_nosc":"macaque","lemma":"macaque","pos":"NOM"} ,
		{"word":"macaques","word_nosc":"macaques","lemma":"macaque","pos":"NOM"} ,
		{"word":"macareux","word_nosc":"macareux","lemma":"macareux","pos":"NOM"} ,
		{"word":"macaron","word_nosc":"macaron","lemma":"macaron","pos":"NOM"} ,
		{"word":"macaroni","word_nosc":"macaroni","lemma":"macaroni","pos":"NOM"} ,
		{"word":"macaronis","word_nosc":"macaronis","lemma":"macaroni","pos":"NOM"} ,
		{"word":"macarons","word_nosc":"macarons","lemma":"macaron","pos":"NOM"} ,
		{"word":"macassar","word_nosc":"macassar","lemma":"macassar","pos":"NOM"} ,
		{"word":"maccarthysme","word_nosc":"maccarthysme","lemma":"maccarthysme","pos":"NOM"} ,
		{"word":"macchab","word_nosc":"macchab","lemma":"macchab","pos":"NOM"} ,
		{"word":"macchabs","word_nosc":"macchabs","lemma":"macchab","pos":"NOM"} ,
		{"word":"macchabée","word_nosc":"macchabee","lemma":"macchabée","pos":"NOM"} ,
		{"word":"macchabées","word_nosc":"macchabees","lemma":"macchabée","pos":"NOM"} ,
		{"word":"macfarlane","word_nosc":"macfarlane","lemma":"macfarlane","pos":"NOM"} ,
		{"word":"mach","word_nosc":"mach","lemma":"mach","pos":"NOM"} ,
		{"word":"machaon","word_nosc":"machaon","lemma":"machaon","pos":"NOM"} ,
		{"word":"machette","word_nosc":"machette","lemma":"machette","pos":"NOM"} ,
		{"word":"machettes","word_nosc":"machettes","lemma":"machette","pos":"NOM"} ,
		{"word":"machiavélisme","word_nosc":"machiavelisme","lemma":"machiavélisme","pos":"NOM"} ,
		{"word":"machiavélismes","word_nosc":"machiavelismes","lemma":"machiavélisme","pos":"NOM"} ,
		{"word":"machin","word_nosc":"machin","lemma":"machin","pos":"NOM"} ,
		{"word":"machination","word_nosc":"machination","lemma":"machination","pos":"NOM"} ,
		{"word":"machinations","word_nosc":"machinations","lemma":"machination","pos":"NOM"} ,
		{"word":"machinchouette","word_nosc":"machinchouette","lemma":"machinchouette","pos":"NOM"} ,
		{"word":"machine","word_nosc":"machine","lemma":"machine","pos":"NOM"} ,
		{"word":"machine-outil","word_nosc":"machine-outil","lemma":"machine-outil","pos":"NOM"} ,
		{"word":"machinerie","word_nosc":"machinerie","lemma":"machinerie","pos":"NOM"} ,
		{"word":"machineries","word_nosc":"machineries","lemma":"machinerie","pos":"NOM"} ,
		{"word":"machines","word_nosc":"machines","lemma":"machine","pos":"NOM"} ,
		{"word":"machines-outils","word_nosc":"machines-outils","lemma":"machine-outil","pos":"NOM"} ,
		{"word":"machinette","word_nosc":"machinette","lemma":"machinette","pos":"NOM"} ,
		{"word":"machinisme","word_nosc":"machinisme","lemma":"machinisme","pos":"NOM"} ,
		{"word":"machiniste","word_nosc":"machiniste","lemma":"machiniste","pos":"NOM"} ,
		{"word":"machinistes","word_nosc":"machinistes","lemma":"machiniste","pos":"NOM"} ,
		{"word":"machino","word_nosc":"machino","lemma":"machino","pos":"NOM"} ,
		{"word":"machinos","word_nosc":"machinos","lemma":"machino","pos":"NOM"} ,
		{"word":"machins","word_nosc":"machins","lemma":"machin","pos":"NOM"} ,
		{"word":"machisme","word_nosc":"machisme","lemma":"machisme","pos":"NOM"} ,
		{"word":"machmètre","word_nosc":"machmetre","lemma":"machmètre","pos":"NOM"} ,
		{"word":"macho","word_nosc":"macho","lemma":"macho","pos":"NOM"} ,
		{"word":"machos","word_nosc":"machos","lemma":"macho","pos":"NOM"} ,
		{"word":"macintosh","word_nosc":"macintosh","lemma":"macintosh","pos":"NOM"} ,
		{"word":"macis","word_nosc":"macis","lemma":"macis","pos":"NOM"} ,
		{"word":"mackintosh","word_nosc":"mackintosh","lemma":"mackintosh","pos":"NOM"} ,
		{"word":"macramé","word_nosc":"macrame","lemma":"macramé","pos":"NOM"} ,
		{"word":"macres","word_nosc":"macres","lemma":"macre","pos":"NOM"} ,
		{"word":"macreuse","word_nosc":"macreuse","lemma":"macreuse","pos":"NOM"} ,
		{"word":"macreuses","word_nosc":"macreuses","lemma":"macreuse","pos":"NOM"} ,
		{"word":"macro","word_nosc":"macro","lemma":"macro","pos":"NOM"} ,
		{"word":"macrobiotique","word_nosc":"macrobiotique","lemma":"macrobiotique","pos":"NOM"} ,
		{"word":"macroclimat","word_nosc":"macroclimat","lemma":"macroclimat","pos":"NOM"} ,
		{"word":"macrocosme","word_nosc":"macrocosme","lemma":"macrocosme","pos":"NOM"} ,
		{"word":"macrolide","word_nosc":"macrolide","lemma":"macrolide","pos":"NOM"} ,
		{"word":"macrophage","word_nosc":"macrophage","lemma":"macrophage","pos":"NOM"} ,
		{"word":"macroéconomie","word_nosc":"macroeconomie","lemma":"macroéconomie","pos":"NOM"} ,
		{"word":"macs","word_nosc":"macs","lemma":"mac","pos":"NOM"} ,
		{"word":"macula","word_nosc":"macula","lemma":"macula","pos":"NOM"} ,
		{"word":"maculage","word_nosc":"maculage","lemma":"maculage","pos":"NOM"} ,
		{"word":"maculation","word_nosc":"maculation","lemma":"maculation","pos":"NOM"} ,
		{"word":"maculature","word_nosc":"maculature","lemma":"maculature","pos":"NOM"} ,
		{"word":"macule","word_nosc":"macule","lemma":"macule","pos":"NOM"} ,
		{"word":"macules","word_nosc":"macules","lemma":"macule","pos":"NOM"} ,
		{"word":"macumba","word_nosc":"macumba","lemma":"macumba","pos":"NOM"} ,
		{"word":"macédoine","word_nosc":"macedoine","lemma":"macédoine","pos":"NOM"} ,
		{"word":"macédoines","word_nosc":"macedoines","lemma":"macédoine","pos":"NOM"} ,
		{"word":"macédonien","word_nosc":"macedonien","lemma":"macédonien","pos":"NOM"} ,
		{"word":"macédonienne","word_nosc":"macedonienne","lemma":"macédonienne","pos":"NOM"} ,
		{"word":"macération","word_nosc":"maceration","lemma":"macération","pos":"NOM"} ,
		{"word":"macérations","word_nosc":"macerations","lemma":"macération","pos":"NOM"} ,
		{"word":"madame","word_nosc":"madame","lemma":"madame","pos":"NOM"} ,
		{"word":"madapolam","word_nosc":"madapolam","lemma":"madapolam","pos":"NOM"} ,
		{"word":"madeleine","word_nosc":"madeleine","lemma":"madeleine","pos":"NOM"} ,
		{"word":"madeleines","word_nosc":"madeleines","lemma":"madeleine","pos":"NOM"} ,
		{"word":"mademoiselle","word_nosc":"mademoiselle","lemma":"mademoiselle","pos":"NOM"} ,
		{"word":"mademoiselles","word_nosc":"mademoiselles","lemma":"mademoiselle","pos":"NOM"} ,
		{"word":"madone","word_nosc":"madone","lemma":"madone","pos":"NOM"} ,
		{"word":"madones","word_nosc":"madones","lemma":"madone","pos":"NOM"} ,
		{"word":"madrague","word_nosc":"madrague","lemma":"madrague","pos":"NOM"} ,
		{"word":"madras","word_nosc":"madras","lemma":"madras","pos":"NOM"} ,
		{"word":"madre","word_nosc":"madre","lemma":"madre","pos":"NOM"} ,
		{"word":"madre de dios","word_nosc":"madre de dios","lemma":"madre de dios","pos":"NOM"} ,
		{"word":"madrier","word_nosc":"madrier","lemma":"madrier","pos":"NOM"} ,
		{"word":"madriers","word_nosc":"madriers","lemma":"madrier","pos":"NOM"} ,
		{"word":"madrigal","word_nosc":"madrigal","lemma":"madrigal","pos":"NOM"} ,
		{"word":"madrigaux","word_nosc":"madrigaux","lemma":"madrigal","pos":"NOM"} ,
		{"word":"madrilène","word_nosc":"madrilene","lemma":"madrilène","pos":"NOM"} ,
		{"word":"madrilènes","word_nosc":"madrilenes","lemma":"madrilène","pos":"NOM"} ,
		{"word":"madrépore","word_nosc":"madrepore","lemma":"madrépore","pos":"NOM"} ,
		{"word":"madrépores","word_nosc":"madrepores","lemma":"madrépore","pos":"NOM"} ,
		{"word":"madrés","word_nosc":"madres","lemma":"madré","pos":"NOM"} ,
		{"word":"madère","word_nosc":"madere","lemma":"madère","pos":"NOM"} ,
		{"word":"maelstrom","word_nosc":"maelstrom","lemma":"maelstrom","pos":"NOM"} ,
		{"word":"maelström","word_nosc":"maelstrom","lemma":"maelström","pos":"NOM"} ,
		{"word":"maestria","word_nosc":"maestria","lemma":"maestria","pos":"NOM"} ,
		{"word":"maestro","word_nosc":"maestro","lemma":"maestro","pos":"NOM"} ,
		{"word":"maffia","word_nosc":"maffia","lemma":"maffia","pos":"NOM"} ,
		{"word":"maffieux","word_nosc":"maffieux","lemma":"maffieux","pos":"NOM"} ,
		{"word":"maffiosi","word_nosc":"maffiosi","lemma":"maffioso","pos":"NOM"} ,
		{"word":"mafia","word_nosc":"mafia","lemma":"mafia","pos":"NOM"} ,
		{"word":"mafias","word_nosc":"mafias","lemma":"mafia","pos":"NOM"} ,
		{"word":"mafieux","word_nosc":"mafieux","lemma":"mafieux","pos":"NOM"} ,
		{"word":"mafiosi","word_nosc":"mafiosi","lemma":"mafioso","pos":"NOM"} ,
		{"word":"mafioso","word_nosc":"mafioso","lemma":"mafioso","pos":"NOM"} ,
		{"word":"magasin","word_nosc":"magasin","lemma":"magasin","pos":"NOM"} ,
		{"word":"magasinage","word_nosc":"magasinage","lemma":"magasinage","pos":"NOM"} ,
		{"word":"magasinier","word_nosc":"magasinier","lemma":"magasinier","pos":"NOM"} ,
		{"word":"magasiniers","word_nosc":"magasiniers","lemma":"magasinier","pos":"NOM"} ,
		{"word":"magasinière","word_nosc":"magasiniere","lemma":"magasinier","pos":"NOM"} ,
		{"word":"magasins","word_nosc":"magasins","lemma":"magasin","pos":"NOM"} ,
		{"word":"magazine","word_nosc":"magazine","lemma":"magazine","pos":"NOM"} ,
		{"word":"magazines","word_nosc":"magazines","lemma":"magazine","pos":"NOM"} ,
		{"word":"mage","word_nosc":"mage","lemma":"mage","pos":"NOM"} ,
		{"word":"magenta","word_nosc":"magenta","lemma":"magenta","pos":"NOM"} ,
		{"word":"mages","word_nosc":"mages","lemma":"mage","pos":"NOM"} ,
		{"word":"maghrébin","word_nosc":"maghrebin","lemma":"maghrébin","pos":"NOM"} ,
		{"word":"maghrébine","word_nosc":"maghrebine","lemma":"maghrébin","pos":"NOM"} ,
		{"word":"maghrébines","word_nosc":"maghrebines","lemma":"maghrébin","pos":"NOM"} ,
		{"word":"maghrébins","word_nosc":"maghrebins","lemma":"maghrébin","pos":"NOM"} ,
		{"word":"maghzen","word_nosc":"maghzen","lemma":"maghzen","pos":"NOM"} ,
		{"word":"magicien","word_nosc":"magicien","lemma":"magicien","pos":"NOM"} ,
		{"word":"magicienne","word_nosc":"magicienne","lemma":"magicien","pos":"NOM"} ,
		{"word":"magiciens","word_nosc":"magiciens","lemma":"magicien","pos":"NOM"} ,
		{"word":"magie","word_nosc":"magie","lemma":"magie","pos":"NOM"} ,
		{"word":"magies","word_nosc":"magies","lemma":"magie","pos":"NOM"} ,
		{"word":"magister","word_nosc":"magister","lemma":"magister","pos":"NOM"} ,
		{"word":"magisters","word_nosc":"magisters","lemma":"magister","pos":"NOM"} ,
		{"word":"magistrat","word_nosc":"magistrat","lemma":"magistrat","pos":"NOM"} ,
		{"word":"magistrate","word_nosc":"magistrate","lemma":"magistrat","pos":"NOM"} ,
		{"word":"magistrats","word_nosc":"magistrats","lemma":"magistrat","pos":"NOM"} ,
		{"word":"magistrature","word_nosc":"magistrature","lemma":"magistrature","pos":"NOM"} ,
		{"word":"magistratures","word_nosc":"magistratures","lemma":"magistrature","pos":"NOM"} ,
		{"word":"magistère","word_nosc":"magistere","lemma":"magistère","pos":"NOM"} ,
		{"word":"magma","word_nosc":"magma","lemma":"magma","pos":"NOM"} ,
		{"word":"magmas","word_nosc":"magmas","lemma":"magma","pos":"NOM"} ,
		{"word":"magnaneries","word_nosc":"magnaneries","lemma":"magnanerie","pos":"NOM"} ,
		{"word":"magnanimité","word_nosc":"magnanimite","lemma":"magnanimité","pos":"NOM"} ,
		{"word":"magnans","word_nosc":"magnans","lemma":"magnan","pos":"NOM"} ,
		{"word":"magnat","word_nosc":"magnat","lemma":"magnat","pos":"NOM"} ,
		{"word":"magnats","word_nosc":"magnats","lemma":"magnat","pos":"NOM"} ,
		{"word":"magnificat","word_nosc":"magnificat","lemma":"magnificat","pos":"NOM"} ,
		{"word":"magnificence","word_nosc":"magnificence","lemma":"magnificence","pos":"NOM"} ,
		{"word":"magnificences","word_nosc":"magnificences","lemma":"magnificence","pos":"NOM"} ,
		{"word":"magnitude","word_nosc":"magnitude","lemma":"magnitude","pos":"NOM"} ,
		{"word":"magnolia","word_nosc":"magnolia","lemma":"magnolia","pos":"NOM"} ,
		{"word":"magnolias","word_nosc":"magnolias","lemma":"magnolia","pos":"NOM"} ,
		{"word":"magnum","word_nosc":"magnum","lemma":"magnum","pos":"NOM"} ,
		{"word":"magnums","word_nosc":"magnums","lemma":"magnum","pos":"NOM"} ,
		{"word":"magnésie","word_nosc":"magnesie","lemma":"magnésie","pos":"NOM"} ,
		{"word":"magnésium","word_nosc":"magnesium","lemma":"magnésium","pos":"NOM"} ,
		{"word":"magnétiseur","word_nosc":"magnetiseur","lemma":"magnétiseur","pos":"NOM"} ,
		{"word":"magnétiseuses","word_nosc":"magnetiseuses","lemma":"magnétiseur","pos":"NOM"} ,
		{"word":"magnétisme","word_nosc":"magnetisme","lemma":"magnétisme","pos":"NOM"} ,
		{"word":"magnétismes","word_nosc":"magnetismes","lemma":"magnétisme","pos":"NOM"} ,
		{"word":"magnétite","word_nosc":"magnetite","lemma":"magnétite","pos":"NOM"} ,
		{"word":"magnéto","word_nosc":"magneto","lemma":"magnéto","pos":"NOM"} ,
		{"word":"magnétomètre","word_nosc":"magnetometre","lemma":"magnétomètre","pos":"NOM"} ,
		{"word":"magnétophone","word_nosc":"magnetophone","lemma":"magnétophone","pos":"NOM"} ,
		{"word":"magnétophones","word_nosc":"magnetophones","lemma":"magnétophone","pos":"NOM"} ,
		{"word":"magnétos","word_nosc":"magnetos","lemma":"magnéto","pos":"NOM"} ,
		{"word":"magnétoscope","word_nosc":"magnetoscope","lemma":"magnétoscope","pos":"NOM"} ,
		{"word":"magnétoscopes","word_nosc":"magnetoscopes","lemma":"magnétoscope","pos":"NOM"} ,
		{"word":"magnétron","word_nosc":"magnetron","lemma":"magnétron","pos":"NOM"} ,
		{"word":"magot","word_nosc":"magot","lemma":"magot","pos":"NOM"} ,
		{"word":"magots","word_nosc":"magots","lemma":"magot","pos":"NOM"} ,
		{"word":"magouille","word_nosc":"magouille","lemma":"magouille","pos":"NOM"} ,
		{"word":"magouilles","word_nosc":"magouilles","lemma":"magouille","pos":"NOM"} ,
		{"word":"magouilleur","word_nosc":"magouilleur","lemma":"magouilleur","pos":"NOM"} ,
		{"word":"magouilleurs","word_nosc":"magouilleurs","lemma":"magouilleur","pos":"NOM"} ,
		{"word":"magret","word_nosc":"magret","lemma":"magret","pos":"NOM"} ,
		{"word":"magyars","word_nosc":"magyars","lemma":"magyar","pos":"NOM"} ,
		{"word":"mah-jong","word_nosc":"mah-jong","lemma":"mah-jong","pos":"NOM"} ,
		{"word":"maharadja","word_nosc":"maharadja","lemma":"maharadja","pos":"NOM"} ,
		{"word":"maharadjah","word_nosc":"maharadjah","lemma":"maharadjah","pos":"NOM"} ,
		{"word":"maharadjahs","word_nosc":"maharadjahs","lemma":"maharadjah","pos":"NOM"} ,
		{"word":"maharadjas","word_nosc":"maharadjas","lemma":"maharadja","pos":"NOM"} ,
		{"word":"maharaja","word_nosc":"maharaja","lemma":"maharaja","pos":"NOM"} ,
		{"word":"maharajah","word_nosc":"maharajah","lemma":"maharajah","pos":"NOM"} ,
		{"word":"maharajahs","word_nosc":"maharajahs","lemma":"maharajah","pos":"NOM"} ,
		{"word":"maharajas","word_nosc":"maharajas","lemma":"maharaja","pos":"NOM"} ,
		{"word":"maharani","word_nosc":"maharani","lemma":"maharani","pos":"NOM"} ,
		{"word":"mahatma","word_nosc":"mahatma","lemma":"mahatma","pos":"NOM"} ,
		{"word":"mahatmas","word_nosc":"mahatmas","lemma":"mahatma","pos":"NOM"} ,
		{"word":"mahométan","word_nosc":"mahometan","lemma":"mahométan","pos":"NOM"} ,
		{"word":"mahométans","word_nosc":"mahometans","lemma":"mahométan","pos":"NOM"} ,
		{"word":"mahonia","word_nosc":"mahonia","lemma":"mahonia","pos":"NOM"} ,
		{"word":"mahonias","word_nosc":"mahonias","lemma":"mahonia","pos":"NOM"} ,
		{"word":"mai","word_nosc":"mai","lemma":"mai","pos":"NOM"} ,
		{"word":"maid","word_nosc":"maid","lemma":"maid","pos":"NOM"} ,
		{"word":"maie","word_nosc":"maie","lemma":"maie","pos":"NOM"} ,
		{"word":"maies","word_nosc":"maies","lemma":"maie","pos":"NOM"} ,
		{"word":"maigre","word_nosc":"maigre","lemma":"maigre","pos":"NOM"} ,
		{"word":"maigrelet","word_nosc":"maigrelet","lemma":"maigrelet","pos":"NOM"} ,
		{"word":"maigrelettes","word_nosc":"maigrelettes","lemma":"maigrelette","pos":"NOM"} ,
		{"word":"maigres","word_nosc":"maigres","lemma":"maigre","pos":"NOM"} ,
		{"word":"maigreur","word_nosc":"maigreur","lemma":"maigreur","pos":"NOM"} ,
		{"word":"maigrichon","word_nosc":"maigrichon","lemma":"maigrichon","pos":"NOM"} ,
		{"word":"maigrichonne","word_nosc":"maigrichonne","lemma":"maigrichon","pos":"NOM"} ,
		{"word":"maigrichonnes","word_nosc":"maigrichonnes","lemma":"maigrichon","pos":"NOM"} ,
		{"word":"maigrichons","word_nosc":"maigrichons","lemma":"maigrichon","pos":"NOM"} ,
		{"word":"maigriot","word_nosc":"maigriot","lemma":"maigriot","pos":"NOM"} ,
		{"word":"mail","word_nosc":"mail","lemma":"mail","pos":"NOM"} ,
		{"word":"mail-coach","word_nosc":"mail-coach","lemma":"mail-coach","pos":"NOM"} ,
		{"word":"mailing","word_nosc":"mailing","lemma":"mailing","pos":"NOM"} ,
		{"word":"maille","word_nosc":"maille","lemma":"maille","pos":"NOM"} ,
		{"word":"maillechort","word_nosc":"maillechort","lemma":"maillechort","pos":"NOM"} ,
		{"word":"mailles","word_nosc":"mailles","lemma":"maille","pos":"NOM"} ,
		{"word":"maillet","word_nosc":"maillet","lemma":"maillet","pos":"NOM"} ,
		{"word":"maillets","word_nosc":"maillets","lemma":"maillet","pos":"NOM"} ,
		{"word":"mailloche","word_nosc":"mailloche","lemma":"mailloche","pos":"NOM"} ,
		{"word":"maillon","word_nosc":"maillon","lemma":"maillon","pos":"NOM"} ,
		{"word":"maillons","word_nosc":"maillons","lemma":"maillon","pos":"NOM"} ,
		{"word":"maillot","word_nosc":"maillot","lemma":"maillot","pos":"NOM"} ,
		{"word":"maillotin","word_nosc":"maillotin","lemma":"maillotin","pos":"NOM"} ,
		{"word":"maillotins","word_nosc":"maillotins","lemma":"maillotin","pos":"NOM"} ,
		{"word":"maillots","word_nosc":"maillots","lemma":"maillot","pos":"NOM"} ,
		{"word":"mails","word_nosc":"mails","lemma":"mail","pos":"NOM"} ,
		{"word":"main","word_nosc":"main","lemma":"main","pos":"NOM"} ,
		{"word":"main-courante","word_nosc":"main-courante","lemma":"main-courante","pos":"NOM"} ,
		{"word":"main-d'oeuvre","word_nosc":"main-d'oeuvre","lemma":"main-d'oeuvre","pos":"NOM"} ,
		{"word":"main-forte","word_nosc":"main-forte","lemma":"main-forte","pos":"NOM"} ,
		{"word":"mainate","word_nosc":"mainate","lemma":"mainate","pos":"NOM"} ,
		{"word":"maincourantier","word_nosc":"maincourantier","lemma":"maincourantier","pos":"NOM"} ,
		{"word":"mainlevée","word_nosc":"mainlevee","lemma":"mainlevée","pos":"NOM"} ,
		{"word":"mainmise","word_nosc":"mainmise","lemma":"mainmise","pos":"NOM"} ,
		{"word":"mains","word_nosc":"mains","lemma":"main","pos":"NOM"} ,
		{"word":"maintenance","word_nosc":"maintenance","lemma":"maintenance","pos":"NOM"} ,
		{"word":"mainteneur","word_nosc":"mainteneur","lemma":"mainteneur","pos":"NOM"} ,
		{"word":"mainteneurs","word_nosc":"mainteneurs","lemma":"mainteneur","pos":"NOM"} ,
		{"word":"maintenue","word_nosc":"maintenue","lemma":"maintenue","pos":"NOM"} ,
		{"word":"maintien","word_nosc":"maintien","lemma":"maintien","pos":"NOM"} ,
		{"word":"maintiens","word_nosc":"maintiens","lemma":"maintien","pos":"NOM"} ,
		{"word":"maire","word_nosc":"maire","lemma":"maire","pos":"NOM"} ,
		{"word":"maires","word_nosc":"maires","lemma":"maire","pos":"NOM"} ,
		{"word":"mairesse","word_nosc":"mairesse","lemma":"maire","pos":"NOM"} ,
		{"word":"mairie","word_nosc":"mairie","lemma":"mairie","pos":"NOM"} ,
		{"word":"mairies","word_nosc":"mairies","lemma":"mairie","pos":"NOM"} ,
		{"word":"maison","word_nosc":"maison","lemma":"maison","pos":"NOM"} ,
		{"word":"maison-mère","word_nosc":"maison-mere","lemma":"maison-mère","pos":"NOM"} ,
		{"word":"maisonnette","word_nosc":"maisonnette","lemma":"maisonnette","pos":"NOM"} ,
		{"word":"maisonnettes","word_nosc":"maisonnettes","lemma":"maisonnette","pos":"NOM"} ,
		{"word":"maisonnée","word_nosc":"maisonnee","lemma":"maisonnée","pos":"NOM"} ,
		{"word":"maisonnées","word_nosc":"maisonnees","lemma":"maisonnée","pos":"NOM"} ,
		{"word":"maisons","word_nosc":"maisons","lemma":"maison","pos":"NOM"} ,
		{"word":"maja","word_nosc":"maja","lemma":"maja","pos":"NOM"} ,
		{"word":"majesté","word_nosc":"majeste","lemma":"majesté","pos":"NOM"} ,
		{"word":"majestés","word_nosc":"majestes","lemma":"majesté","pos":"NOM"} ,
		{"word":"majeur","word_nosc":"majeur","lemma":"majeur","pos":"NOM"} ,
		{"word":"majeure","word_nosc":"majeure","lemma":"majeur","pos":"NOM"} ,
		{"word":"majeures","word_nosc":"majeures","lemma":"majeur","pos":"NOM"} ,
		{"word":"majeurs","word_nosc":"majeurs","lemma":"majeur","pos":"NOM"} ,
		{"word":"majolique","word_nosc":"majolique","lemma":"majolique","pos":"NOM"} ,
		{"word":"major","word_nosc":"major","lemma":"major","pos":"NOM"} ,
		{"word":"major-général","word_nosc":"major-general","lemma":"major-général","pos":"NOM"} ,
		{"word":"majoral","word_nosc":"majoral","lemma":"majoral","pos":"NOM"} ,
		{"word":"majorat","word_nosc":"majorat","lemma":"majorat","pos":"NOM"} ,
		{"word":"majoration","word_nosc":"majoration","lemma":"majoration","pos":"NOM"} ,
		{"word":"majordome","word_nosc":"majordome","lemma":"majordome","pos":"NOM"} ,
		{"word":"majordomes","word_nosc":"majordomes","lemma":"majordome","pos":"NOM"} ,
		{"word":"majorette","word_nosc":"majorette","lemma":"majorette","pos":"NOM"} ,
		{"word":"majorettes","word_nosc":"majorettes","lemma":"majorette","pos":"NOM"} ,
		{"word":"majorité","word_nosc":"majorite","lemma":"majorité","pos":"NOM"} ,
		{"word":"majorités","word_nosc":"majorites","lemma":"majorité","pos":"NOM"} ,
		{"word":"majors","word_nosc":"majors","lemma":"major","pos":"NOM"} ,
		{"word":"majuscule","word_nosc":"majuscule","lemma":"majuscule","pos":"NOM"} ,
		{"word":"majuscules","word_nosc":"majuscules","lemma":"majuscule","pos":"NOM"} ,
		{"word":"maki","word_nosc":"maki","lemma":"maki","pos":"NOM"} ,
		{"word":"mal","word_nosc":"mal","lemma":"mal","pos":"NOM"} ,
		{"word":"mal-aimé","word_nosc":"mal-aime","lemma":"mal-aimé","pos":"NOM"} ,
		{"word":"mal-aimée","word_nosc":"mal-aimee","lemma":"mal-aimé","pos":"NOM"} ,
		{"word":"mal-aimés","word_nosc":"mal-aimes","lemma":"mal-aimé","pos":"NOM"} ,
		{"word":"mal-baisé","word_nosc":"mal-baise","lemma":"mal-baisé","pos":"NOM"} ,
		{"word":"mal-baisée","word_nosc":"mal-baisee","lemma":"mal-baisé","pos":"NOM"} ,
		{"word":"mal-pensants","word_nosc":"mal-pensants","lemma":"mal-pensant","pos":"NOM"} ,
		{"word":"mal-être","word_nosc":"mal-etre","lemma":"mal-être","pos":"NOM"} ,
		{"word":"malabar","word_nosc":"malabar","lemma":"malabar","pos":"NOM"} ,
		{"word":"malabars","word_nosc":"malabars","lemma":"malabar","pos":"NOM"} ,
		{"word":"malachite","word_nosc":"malachite","lemma":"malachite","pos":"NOM"} ,
		{"word":"malade","word_nosc":"malade","lemma":"malade","pos":"NOM"} ,
		{"word":"malades","word_nosc":"malades","lemma":"malade","pos":"NOM"} ,
		{"word":"maladie","word_nosc":"maladie","lemma":"maladie","pos":"NOM"} ,
		{"word":"maladies","word_nosc":"maladies","lemma":"maladie","pos":"NOM"} ,
		{"word":"maladrerie","word_nosc":"maladrerie","lemma":"maladrerie","pos":"NOM"} ,
		{"word":"maladresse","word_nosc":"maladresse","lemma":"maladresse","pos":"NOM"} ,
		{"word":"maladresses","word_nosc":"maladresses","lemma":"maladresse","pos":"NOM"} ,
		{"word":"maladroit","word_nosc":"maladroit","lemma":"maladroit","pos":"NOM"} ,
		{"word":"maladroite","word_nosc":"maladroite","lemma":"maladroit","pos":"NOM"} ,
		{"word":"maladroites","word_nosc":"maladroites","lemma":"maladroit","pos":"NOM"} ,
		{"word":"maladroits","word_nosc":"maladroits","lemma":"maladroit","pos":"NOM"} ,
		{"word":"malaga","word_nosc":"malaga","lemma":"malaga","pos":"NOM"} ,
		{"word":"malais","word_nosc":"malais","lemma":"malais","pos":"NOM"} ,
		{"word":"malaise","word_nosc":"malaise","lemma":"malaise","pos":"NOM"} ,
		{"word":"malaises","word_nosc":"malaises","lemma":"malaise","pos":"NOM"} ,
		{"word":"malaisien","word_nosc":"malaisien","lemma":"malaisien","pos":"NOM"} ,
		{"word":"malaisiens","word_nosc":"malaisiens","lemma":"malaisien","pos":"NOM"} ,
		{"word":"malandrin","word_nosc":"malandrin","lemma":"malandrin","pos":"NOM"} ,
		{"word":"malandrins","word_nosc":"malandrins","lemma":"malandrin","pos":"NOM"} ,
		{"word":"malappris","word_nosc":"malappris","lemma":"malappris","pos":"NOM"} ,
		{"word":"malapprise","word_nosc":"malapprise","lemma":"malappris","pos":"NOM"} ,
		{"word":"malard","word_nosc":"malard","lemma":"malard","pos":"NOM"} ,
		{"word":"malards","word_nosc":"malards","lemma":"malard","pos":"NOM"} ,
		{"word":"malaria","word_nosc":"malaria","lemma":"malaria","pos":"NOM"} ,
		{"word":"malarias","word_nosc":"malarias","lemma":"malaria","pos":"NOM"} ,
		{"word":"malaventures","word_nosc":"malaventures","lemma":"malaventure","pos":"NOM"} ,
		{"word":"malaxeur","word_nosc":"malaxeur","lemma":"malaxeur","pos":"NOM"} ,
		{"word":"malchance","word_nosc":"malchance","lemma":"malchance","pos":"NOM"} ,
		{"word":"malchances","word_nosc":"malchances","lemma":"malchance","pos":"NOM"} ,
		{"word":"malchanceuse","word_nosc":"malchanceuse","lemma":"malchanceux","pos":"NOM"} ,
		{"word":"malchanceux","word_nosc":"malchanceux","lemma":"malchanceux","pos":"NOM"} ,
		{"word":"maldonne","word_nosc":"maldonne","lemma":"maldonne","pos":"NOM"} ,
		{"word":"maldonnes","word_nosc":"maldonnes","lemma":"maldonne","pos":"NOM"} ,
		{"word":"malencontre","word_nosc":"malencontre","lemma":"malencontre","pos":"NOM"} ,
		{"word":"malencontres","word_nosc":"malencontres","lemma":"malencontre","pos":"NOM"} ,
		{"word":"malentendant","word_nosc":"malentendant","lemma":"malentendant","pos":"NOM"} ,
		{"word":"malentendants","word_nosc":"malentendants","lemma":"malentendant","pos":"NOM"} ,
		{"word":"malentendu","word_nosc":"malentendu","lemma":"malentendu","pos":"NOM"} ,
		{"word":"malentendus","word_nosc":"malentendus","lemma":"malentendu","pos":"NOM"} ,
		{"word":"malfaisance","word_nosc":"malfaisance","lemma":"malfaisance","pos":"NOM"} ,
		{"word":"malfaisances","word_nosc":"malfaisances","lemma":"malfaisance","pos":"NOM"} ,
		{"word":"malfaiteur","word_nosc":"malfaiteur","lemma":"malfaiteur","pos":"NOM"} ,
		{"word":"malfaiteurs","word_nosc":"malfaiteurs","lemma":"malfaiteur","pos":"NOM"} ,
		{"word":"malfaçon","word_nosc":"malfacon","lemma":"malfaçon","pos":"NOM"} ,
		{"word":"malfaçons","word_nosc":"malfacons","lemma":"malfaçon","pos":"NOM"} ,
		{"word":"malformation","word_nosc":"malformation","lemma":"malformation","pos":"NOM"} ,
		{"word":"malfrat","word_nosc":"malfrat","lemma":"malfrat","pos":"NOM"} ,
		{"word":"malfrats","word_nosc":"malfrats","lemma":"malfrat","pos":"NOM"} ,
		{"word":"malgaches","word_nosc":"malgaches","lemma":"malgache","pos":"NOM"} ,
		{"word":"malherbe","word_nosc":"malherbe","lemma":"malherbe","pos":"NOM"} ,
		{"word":"malheur","word_nosc":"malheur","lemma":"malheur","pos":"NOM"} ,
		{"word":"malheureuse","word_nosc":"malheureuse","lemma":"malheureux","pos":"NOM"} ,
		{"word":"malheureuses","word_nosc":"malheureuses","lemma":"malheureux","pos":"NOM"} ,
		{"word":"malheureux","word_nosc":"malheureux","lemma":"malheureux","pos":"NOM"} ,
		{"word":"malheurs","word_nosc":"malheurs","lemma":"malheur","pos":"NOM"} ,
		{"word":"malhonnête","word_nosc":"malhonnete","lemma":"malhonnête","pos":"NOM"} ,
		{"word":"malhonnêtes","word_nosc":"malhonnetes","lemma":"malhonnête","pos":"NOM"} ,
		{"word":"malhonnêteté","word_nosc":"malhonnetete","lemma":"malhonnêteté","pos":"NOM"} ,
		{"word":"malhonnêtetés","word_nosc":"malhonnetetes","lemma":"malhonnêteté","pos":"NOM"} ,
		{"word":"mali","word_nosc":"mali","lemma":"mali","pos":"NOM"} ,
		{"word":"malice","word_nosc":"malice","lemma":"malice","pos":"NOM"} ,
		{"word":"malices","word_nosc":"malices","lemma":"malice","pos":"NOM"} ,
		{"word":"malien","word_nosc":"malien","lemma":"malien","pos":"NOM"} ,
		{"word":"malienne","word_nosc":"malienne","lemma":"malienne","pos":"NOM"} ,
		{"word":"maliens","word_nosc":"maliens","lemma":"malien","pos":"NOM"} ,
		{"word":"maligne","word_nosc":"maligne","lemma":"malin","pos":"NOM"} ,
		{"word":"malignes","word_nosc":"malignes","lemma":"malin","pos":"NOM"} ,
		{"word":"malignité","word_nosc":"malignite","lemma":"malignité","pos":"NOM"} ,
		{"word":"malignités","word_nosc":"malignites","lemma":"malignité","pos":"NOM"} ,
		{"word":"malin","word_nosc":"malin","lemma":"malin","pos":"NOM"} ,
		{"word":"maline","word_nosc":"maline","lemma":"malin","pos":"NOM"} ,
		{"word":"malines","word_nosc":"malines","lemma":"malin","pos":"NOM"} ,
		{"word":"malinké","word_nosc":"malinke","lemma":"malinké","pos":"NOM"} ,
		{"word":"malinois","word_nosc":"malinois","lemma":"malinois","pos":"NOM"} ,
		{"word":"malins","word_nosc":"malins","lemma":"malin","pos":"NOM"} ,
		{"word":"malle","word_nosc":"malle","lemma":"malle","pos":"NOM"} ,
		{"word":"malles","word_nosc":"malles","lemma":"malle","pos":"NOM"} ,
		{"word":"mallette","word_nosc":"mallette","lemma":"mallette","pos":"NOM"} ,
		{"word":"mallettes","word_nosc":"mallettes","lemma":"mallette","pos":"NOM"} ,
		{"word":"mallophages","word_nosc":"mallophages","lemma":"mallophage","pos":"NOM"} ,
		{"word":"malléabilité","word_nosc":"malleabilite","lemma":"malléabilité","pos":"NOM"} ,
		{"word":"malléole","word_nosc":"malleole","lemma":"malléole","pos":"NOM"} ,
		{"word":"malm","word_nosc":"malm","lemma":"malm","pos":"NOM"} ,
		{"word":"malmenage","word_nosc":"malmenage","lemma":"malmenage","pos":"NOM"} ,
		{"word":"malnutrition","word_nosc":"malnutrition","lemma":"malnutrition","pos":"NOM"} ,
		{"word":"malotru","word_nosc":"malotru","lemma":"malotru","pos":"NOM"} ,
		{"word":"malotrus","word_nosc":"malotrus","lemma":"malotru","pos":"NOM"} ,
		{"word":"malouin","word_nosc":"malouin","lemma":"malouin","pos":"NOM"} ,
		{"word":"malpoli","word_nosc":"malpoli","lemma":"malpoli","pos":"NOM"} ,
		{"word":"malpropre","word_nosc":"malpropre","lemma":"malpropre","pos":"NOM"} ,
		{"word":"malpropres","word_nosc":"malpropres","lemma":"malpropre","pos":"NOM"} ,
		{"word":"malpropreté","word_nosc":"malproprete","lemma":"malpropreté","pos":"NOM"} ,
		{"word":"malséance","word_nosc":"malseance","lemma":"malséance","pos":"NOM"} ,
		{"word":"malt","word_nosc":"malt","lemma":"malt","pos":"NOM"} ,
		{"word":"maltais","word_nosc":"maltais","lemma":"maltais","pos":"NOM"} ,
		{"word":"maltraitance","word_nosc":"maltraitance","lemma":"maltraitance","pos":"NOM"} ,
		{"word":"maltraitances","word_nosc":"maltraitances","lemma":"maltraitance","pos":"NOM"} ,
		{"word":"malus","word_nosc":"malus","lemma":"malus","pos":"NOM"} ,
		{"word":"malveillance","word_nosc":"malveillance","lemma":"malveillance","pos":"NOM"} ,
		{"word":"malveillances","word_nosc":"malveillances","lemma":"malveillance","pos":"NOM"} ,
		{"word":"malveillant","word_nosc":"malveillant","lemma":"malveillant","pos":"NOM"} ,
		{"word":"malveillants","word_nosc":"malveillants","lemma":"malveillant","pos":"NOM"} ,
		{"word":"malversation","word_nosc":"malversation","lemma":"malversation","pos":"NOM"} ,
		{"word":"malversations","word_nosc":"malversations","lemma":"malversation","pos":"NOM"} ,
		{"word":"malvoisie","word_nosc":"malvoisie","lemma":"malvoisie","pos":"NOM"} ,
		{"word":"malvoisies","word_nosc":"malvoisies","lemma":"malvoisie","pos":"NOM"} ,
		{"word":"malédiction","word_nosc":"malediction","lemma":"malédiction","pos":"NOM"} ,
		{"word":"malédictions","word_nosc":"maledictions","lemma":"malédiction","pos":"NOM"} ,
		{"word":"maléfice","word_nosc":"malefice","lemma":"maléfice","pos":"NOM"} ,
		{"word":"maléfices","word_nosc":"malefices","lemma":"maléfice","pos":"NOM"} ,
		{"word":"mam","word_nosc":"mam","lemma":"mam","pos":"NOM"} ,
		{"word":"mam'selle","word_nosc":"mam'selle","lemma":"mam'selle","pos":"NOM"} ,
		{"word":"mam'zelle","word_nosc":"mam'zelle","lemma":"mam'zelle","pos":"NOM"} ,
		{"word":"mamaliga","word_nosc":"mamaliga","lemma":"mamaliga","pos":"NOM"} ,
		{"word":"mamamouchi","word_nosc":"mamamouchi","lemma":"mamamouchi","pos":"NOM"} ,
		{"word":"maman","word_nosc":"maman","lemma":"maman","pos":"NOM"} ,
		{"word":"mamans","word_nosc":"mamans","lemma":"maman","pos":"NOM"} ,
		{"word":"mamba","word_nosc":"mamba","lemma":"mamba","pos":"NOM"} ,
		{"word":"mambo","word_nosc":"mambo","lemma":"mambo","pos":"NOM"} ,
		{"word":"mambos","word_nosc":"mambos","lemma":"mambo","pos":"NOM"} ,
		{"word":"mame","word_nosc":"mame","lemma":"mame","pos":"NOM"} ,
		{"word":"mamelle","word_nosc":"mamelle","lemma":"mamelle","pos":"NOM"} ,
		{"word":"mamelles","word_nosc":"mamelles","lemma":"mamelle","pos":"NOM"} ,
		{"word":"mamelon","word_nosc":"mamelon","lemma":"mamelon","pos":"NOM"} ,
		{"word":"mamelons","word_nosc":"mamelons","lemma":"mamelon","pos":"NOM"} ,
		{"word":"mamelouk","word_nosc":"mamelouk","lemma":"mamelouk","pos":"NOM"} ,
		{"word":"mamelouks","word_nosc":"mamelouks","lemma":"mamelouk","pos":"NOM"} ,
		{"word":"mameluk","word_nosc":"mameluk","lemma":"mameluk","pos":"NOM"} ,
		{"word":"mameluks","word_nosc":"mameluks","lemma":"mameluk","pos":"NOM"} ,
		{"word":"mamie","word_nosc":"mamie","lemma":"mamie","pos":"NOM"} ,
		{"word":"mamies","word_nosc":"mamies","lemma":"mamie","pos":"NOM"} ,
		{"word":"mamma","word_nosc":"mamma","lemma":"mamma","pos":"NOM"} ,
		{"word":"mammectomie","word_nosc":"mammectomie","lemma":"mammectomie","pos":"NOM"} ,
		{"word":"mammifère","word_nosc":"mammifere","lemma":"mammifère","pos":"NOM"} ,
		{"word":"mammifères","word_nosc":"mammiferes","lemma":"mammifère","pos":"NOM"} ,
		{"word":"mammographie","word_nosc":"mammographie","lemma":"mammographie","pos":"NOM"} ,
		{"word":"mammoplastie","word_nosc":"mammoplastie","lemma":"mammoplastie","pos":"NOM"} ,
		{"word":"mammouth","word_nosc":"mammouth","lemma":"mammouth","pos":"NOM"} ,
		{"word":"mammouths","word_nosc":"mammouths","lemma":"mammouth","pos":"NOM"} ,
		{"word":"mammy","word_nosc":"mammy","lemma":"mammy","pos":"NOM"} ,
		{"word":"mamours","word_nosc":"mamours","lemma":"mamours","pos":"NOM"} ,
		{"word":"mamy","word_nosc":"mamy","lemma":"mamy","pos":"NOM"} ,
		{"word":"mamys","word_nosc":"mamys","lemma":"mamy","pos":"NOM"} ,
		{"word":"mamzelle","word_nosc":"mamzelle","lemma":"mamzelle","pos":"NOM"} ,
		{"word":"man","word_nosc":"man","lemma":"man","pos":"NOM"} ,
		{"word":"mana","word_nosc":"mana","lemma":"mana","pos":"NOM"} ,
		{"word":"manade","word_nosc":"manade","lemma":"manade","pos":"NOM"} ,
		{"word":"manades","word_nosc":"manades","lemma":"manade","pos":"NOM"} ,
		{"word":"management","word_nosc":"management","lemma":"management","pos":"NOM"} ,
		{"word":"manager","word_nosc":"manager","lemma":"manager","pos":"NOM"} ,
		{"word":"managers","word_nosc":"managers","lemma":"manager","pos":"NOM"} ,
		{"word":"manageur","word_nosc":"manageur","lemma":"manageur","pos":"NOM"} ,
		{"word":"manant","word_nosc":"manant","lemma":"manant","pos":"NOM"} ,
		{"word":"manants","word_nosc":"manants","lemma":"manant","pos":"NOM"} ,
		{"word":"mancenillier","word_nosc":"mancenillier","lemma":"mancenillier","pos":"NOM"} ,
		{"word":"manche","word_nosc":"manche","lemma":"manche","pos":"NOM"} ,
		{"word":"mancheron","word_nosc":"mancheron","lemma":"mancheron","pos":"NOM"} ,
		{"word":"mancherons","word_nosc":"mancherons","lemma":"mancheron","pos":"NOM"} ,
		{"word":"manches","word_nosc":"manches","lemma":"manche","pos":"NOM"} ,
		{"word":"manchette","word_nosc":"manchette","lemma":"manchette","pos":"NOM"} ,
		{"word":"manchettes","word_nosc":"manchettes","lemma":"manchette","pos":"NOM"} ,
		{"word":"manchon","word_nosc":"manchon","lemma":"manchon","pos":"NOM"} ,
		{"word":"manchons","word_nosc":"manchons","lemma":"manchon","pos":"NOM"} ,
		{"word":"manchot","word_nosc":"manchot","lemma":"manchot","pos":"NOM"} ,
		{"word":"manchote","word_nosc":"manchote","lemma":"manchot","pos":"NOM"} ,
		{"word":"manchotes","word_nosc":"manchotes","lemma":"manchot","pos":"NOM"} ,
		{"word":"manchots","word_nosc":"manchots","lemma":"manchot","pos":"NOM"} ,
		{"word":"mandala","word_nosc":"mandala","lemma":"mandala","pos":"NOM"} ,
		{"word":"mandalas","word_nosc":"mandalas","lemma":"mandala","pos":"NOM"} ,
		{"word":"mandale","word_nosc":"mandale","lemma":"mandale","pos":"NOM"} ,
		{"word":"mandales","word_nosc":"mandales","lemma":"mandale","pos":"NOM"} ,
		{"word":"mandant","word_nosc":"mandant","lemma":"mandant","pos":"NOM"} ,
		{"word":"mandants","word_nosc":"mandants","lemma":"mandant","pos":"NOM"} ,
		{"word":"mandarin","word_nosc":"mandarin","lemma":"mandarin","pos":"NOM"} ,
		{"word":"mandarine","word_nosc":"mandarine","lemma":"mandarine","pos":"NOM"} ,
		{"word":"mandarines","word_nosc":"mandarines","lemma":"mandarine","pos":"NOM"} ,
		{"word":"mandarinier","word_nosc":"mandarinier","lemma":"mandarinier","pos":"NOM"} ,
		{"word":"mandariniers","word_nosc":"mandariniers","lemma":"mandarinier","pos":"NOM"} ,
		{"word":"mandarins","word_nosc":"mandarins","lemma":"mandarin","pos":"NOM"} ,
		{"word":"mandat","word_nosc":"mandat","lemma":"mandat","pos":"NOM"} ,
		{"word":"mandat-carte","word_nosc":"mandat-carte","lemma":"mandat-carte","pos":"NOM"} ,
		{"word":"mandat-lettre","word_nosc":"mandat-lettre","lemma":"mandat-lettre","pos":"NOM"} ,
		{"word":"mandat-poste","word_nosc":"mandat-poste","lemma":"mandat-poste","pos":"NOM"} ,
		{"word":"mandataire","word_nosc":"mandataire","lemma":"mandataire","pos":"NOM"} ,
		{"word":"mandataires","word_nosc":"mandataires","lemma":"mandataire","pos":"NOM"} ,
		{"word":"mandatement","word_nosc":"mandatement","lemma":"mandatement","pos":"NOM"} ,
		{"word":"mandats","word_nosc":"mandats","lemma":"mandat","pos":"NOM"} ,
		{"word":"mandchous","word_nosc":"mandchous","lemma":"mandchou","pos":"NOM"} ,
		{"word":"mandement","word_nosc":"mandement","lemma":"mandement","pos":"NOM"} ,
		{"word":"mandements","word_nosc":"mandements","lemma":"mandement","pos":"NOM"} ,
		{"word":"mandibule","word_nosc":"mandibule","lemma":"mandibule","pos":"NOM"} ,
		{"word":"mandibules","word_nosc":"mandibules","lemma":"mandibule","pos":"NOM"} ,
		{"word":"mandingues","word_nosc":"mandingues","lemma":"mandingue","pos":"NOM"} ,
		{"word":"mandoline","word_nosc":"mandoline","lemma":"mandoline","pos":"NOM"} ,
		{"word":"mandolines","word_nosc":"mandolines","lemma":"mandoline","pos":"NOM"} ,
		{"word":"mandore","word_nosc":"mandore","lemma":"mandore","pos":"NOM"} ,
		{"word":"mandorle","word_nosc":"mandorle","lemma":"mandorle","pos":"NOM"} ,
		{"word":"mandorles","word_nosc":"mandorles","lemma":"mandorle","pos":"NOM"} ,
		{"word":"mandragore","word_nosc":"mandragore","lemma":"mandragore","pos":"NOM"} ,
		{"word":"mandragores","word_nosc":"mandragores","lemma":"mandragore","pos":"NOM"} ,
		{"word":"mandrill","word_nosc":"mandrill","lemma":"mandrill","pos":"NOM"} ,
		{"word":"mandrin","word_nosc":"mandrin","lemma":"mandrin","pos":"NOM"} ,
		{"word":"mandrins","word_nosc":"mandrins","lemma":"mandrin","pos":"NOM"} ,
		{"word":"manducation","word_nosc":"manducation","lemma":"manducation","pos":"NOM"} ,
		{"word":"mandé","word_nosc":"mande","lemma":"mandé","pos":"NOM"} ,
		{"word":"manette","word_nosc":"manette","lemma":"manette","pos":"NOM"} ,
		{"word":"manettes","word_nosc":"manettes","lemma":"manette","pos":"NOM"} ,
		{"word":"manga","word_nosc":"manga","lemma":"manga","pos":"NOM"} ,
		{"word":"manganite","word_nosc":"manganite","lemma":"manganite","pos":"NOM"} ,
		{"word":"manganèse","word_nosc":"manganese","lemma":"manganèse","pos":"NOM"} ,
		{"word":"mangas","word_nosc":"mangas","lemma":"manga","pos":"NOM"} ,
		{"word":"mange-disque","word_nosc":"mange-disque","lemma":"mange-disque","pos":"NOM"} ,
		{"word":"mange-disques","word_nosc":"mange-disques","lemma":"mange-disque","pos":"NOM"} ,
		{"word":"mange-tout","word_nosc":"mange-tout","lemma":"mange-tout","pos":"NOM"} ,
		{"word":"mangeaille","word_nosc":"mangeaille","lemma":"mangeaille","pos":"NOM"} ,
		{"word":"mangeailles","word_nosc":"mangeailles","lemma":"mangeaille","pos":"NOM"} ,
		{"word":"mangeoire","word_nosc":"mangeoire","lemma":"mangeoire","pos":"NOM"} ,
		{"word":"mangeoires","word_nosc":"mangeoires","lemma":"mangeoire","pos":"NOM"} ,
		{"word":"manger","word_nosc":"manger","lemma":"manger","pos":"NOM"} ,
		{"word":"mangerie","word_nosc":"mangerie","lemma":"mangerie","pos":"NOM"} ,
		{"word":"mangetout","word_nosc":"mangetout","lemma":"mangetout","pos":"NOM"} ,
		{"word":"mangeur","word_nosc":"mangeur","lemma":"mangeur","pos":"NOM"} ,
		{"word":"mangeurs","word_nosc":"mangeurs","lemma":"mangeur","pos":"NOM"} ,
		{"word":"mangeuse","word_nosc":"mangeuse","lemma":"mangeur","pos":"NOM"} ,
		{"word":"mangeuses","word_nosc":"mangeuses","lemma":"mangeur","pos":"NOM"} ,
		{"word":"mango","word_nosc":"mango","lemma":"mango","pos":"NOM"} ,
		{"word":"mangonneaux","word_nosc":"mangonneaux","lemma":"mangonneau","pos":"NOM"} ,
		{"word":"mangouste","word_nosc":"mangouste","lemma":"mangouste","pos":"NOM"} ,
		{"word":"mangrove","word_nosc":"mangrove","lemma":"mangrove","pos":"NOM"} ,
		{"word":"mangroves","word_nosc":"mangroves","lemma":"mangrove","pos":"NOM"} ,
		{"word":"mangue","word_nosc":"mangue","lemma":"mangue","pos":"NOM"} ,
		{"word":"mangues","word_nosc":"mangues","lemma":"mangue","pos":"NOM"} ,
		{"word":"manguier","word_nosc":"manguier","lemma":"manguier","pos":"NOM"} ,
		{"word":"manguiers","word_nosc":"manguiers","lemma":"manguier","pos":"NOM"} ,
		{"word":"manhattan","word_nosc":"manhattan","lemma":"manhattan","pos":"NOM"} ,
		{"word":"maniabilité","word_nosc":"maniabilite","lemma":"maniabilité","pos":"NOM"} ,
		{"word":"maniacodépressif","word_nosc":"maniacodepressif","lemma":"maniacodépressif","pos":"NOM"} ,
		{"word":"maniaque","word_nosc":"maniaque","lemma":"maniaque","pos":"NOM"} ,
		{"word":"maniaquerie","word_nosc":"maniaquerie","lemma":"maniaquerie","pos":"NOM"} ,
		{"word":"maniaqueries","word_nosc":"maniaqueries","lemma":"maniaquerie","pos":"NOM"} ,
		{"word":"maniaques","word_nosc":"maniaques","lemma":"maniaque","pos":"NOM"} ,
		{"word":"manichéisme","word_nosc":"manicheisme","lemma":"manichéisme","pos":"NOM"} ,
		{"word":"manichéiste","word_nosc":"manicheiste","lemma":"manichéiste","pos":"NOM"} ,
		{"word":"manie","word_nosc":"manie","lemma":"manie","pos":"NOM"} ,
		{"word":"maniement","word_nosc":"maniement","lemma":"maniement","pos":"NOM"} ,
		{"word":"maniements","word_nosc":"maniements","lemma":"maniement","pos":"NOM"} ,
		{"word":"manies","word_nosc":"manies","lemma":"manie","pos":"NOM"} ,
		{"word":"manieur","word_nosc":"manieur","lemma":"manieur","pos":"NOM"} ,
		{"word":"manieurs","word_nosc":"manieurs","lemma":"manieur","pos":"NOM"} ,
		{"word":"manieuse","word_nosc":"manieuse","lemma":"manieur","pos":"NOM"} ,
		{"word":"manif","word_nosc":"manif","lemma":"manif","pos":"NOM"} ,
		{"word":"manifestant","word_nosc":"manifestant","lemma":"manifestant","pos":"NOM"} ,
		{"word":"manifestante","word_nosc":"manifestante","lemma":"manifestant","pos":"NOM"} ,
		{"word":"manifestantes","word_nosc":"manifestantes","lemma":"manifestant","pos":"NOM"} ,
		{"word":"manifestants","word_nosc":"manifestants","lemma":"manifestant","pos":"NOM"} ,
		{"word":"manifestation","word_nosc":"manifestation","lemma":"manifestation","pos":"NOM"} ,
		{"word":"manifestations","word_nosc":"manifestations","lemma":"manifestation","pos":"NOM"} ,
		{"word":"manifeste","word_nosc":"manifeste","lemma":"manifeste","pos":"NOM"} ,
		{"word":"manifestes","word_nosc":"manifestes","lemma":"manifeste","pos":"NOM"} ,
		{"word":"manifold","word_nosc":"manifold","lemma":"manifold","pos":"NOM"} ,
		{"word":"manifolds","word_nosc":"manifolds","lemma":"manifold","pos":"NOM"} ,
		{"word":"manifs","word_nosc":"manifs","lemma":"manif","pos":"NOM"} ,
		{"word":"manigance","word_nosc":"manigance","lemma":"manigance","pos":"NOM"} ,
		{"word":"manigances","word_nosc":"manigances","lemma":"manigance","pos":"NOM"} ,
		{"word":"manille","word_nosc":"manille","lemma":"manille","pos":"NOM"} ,
		{"word":"manilles","word_nosc":"manilles","lemma":"manille","pos":"NOM"} ,
		{"word":"manilleurs","word_nosc":"manilleurs","lemma":"manilleur","pos":"NOM"} ,
		{"word":"manillon","word_nosc":"manillon","lemma":"manillon","pos":"NOM"} ,
		{"word":"manioc","word_nosc":"manioc","lemma":"manioc","pos":"NOM"} ,
		{"word":"manip","word_nosc":"manip","lemma":"manip","pos":"NOM"} ,
		{"word":"manipe","word_nosc":"manipe","lemma":"manip","pos":"NOM"} ,
		{"word":"manipulateur","word_nosc":"manipulateur","lemma":"manipulateur","pos":"NOM"} ,
		{"word":"manipulateurs","word_nosc":"manipulateurs","lemma":"manipulateur","pos":"NOM"} ,
		{"word":"manipulation","word_nosc":"manipulation","lemma":"manipulation","pos":"NOM"} ,
		{"word":"manipulations","word_nosc":"manipulations","lemma":"manipulation","pos":"NOM"} ,
		{"word":"manipulatrice","word_nosc":"manipulatrice","lemma":"manipulateur","pos":"NOM"} ,
		{"word":"manipulatrices","word_nosc":"manipulatrices","lemma":"manipulateur","pos":"NOM"} ,
		{"word":"manipule","word_nosc":"manipule","lemma":"manipule","pos":"NOM"} ,
		{"word":"manipules","word_nosc":"manipules","lemma":"manipule","pos":"NOM"} ,
		{"word":"manitoba","word_nosc":"manitoba","lemma":"manitoba","pos":"NOM"} ,
		{"word":"manitou","word_nosc":"manitou","lemma":"manitou","pos":"NOM"} ,
		{"word":"manitous","word_nosc":"manitous","lemma":"manitou","pos":"NOM"} ,
		{"word":"manivelle","word_nosc":"manivelle","lemma":"manivelle","pos":"NOM"} ,
		{"word":"manivelles","word_nosc":"manivelles","lemma":"manivelle","pos":"NOM"} ,
		{"word":"manière","word_nosc":"maniere","lemma":"manière","pos":"NOM"} ,
		{"word":"manières","word_nosc":"manieres","lemma":"manière","pos":"NOM"} ,
		{"word":"maniérisme","word_nosc":"manierisme","lemma":"maniérisme","pos":"NOM"} ,
		{"word":"maniérismes","word_nosc":"manierismes","lemma":"maniérisme","pos":"NOM"} ,
		{"word":"maniéristes","word_nosc":"manieristes","lemma":"maniériste","pos":"NOM"} ,
		{"word":"manne","word_nosc":"manne","lemma":"manne","pos":"NOM"} ,
		{"word":"mannequin","word_nosc":"mannequin","lemma":"mannequin","pos":"NOM"} ,
		{"word":"mannequins","word_nosc":"mannequins","lemma":"mannequin","pos":"NOM"} ,
		{"word":"mannes","word_nosc":"mannes","lemma":"manne","pos":"NOM"} ,
		{"word":"mannezingue","word_nosc":"mannezingue","lemma":"mannezingue","pos":"NOM"} ,
		{"word":"mannitol","word_nosc":"mannitol","lemma":"mannitol","pos":"NOM"} ,
		{"word":"manoeuvrabilité","word_nosc":"manoeuvrabilite","lemma":"manoeuvrabilité","pos":"NOM"} ,
		{"word":"manoeuvre","word_nosc":"manoeuvre","lemma":"manoeuvre","pos":"NOM"} ,
		{"word":"manoeuvres","word_nosc":"manoeuvres","lemma":"manoeuvre","pos":"NOM"} ,
		{"word":"manoeuvriers","word_nosc":"manoeuvriers","lemma":"manoeuvrier","pos":"NOM"} ,
		{"word":"manoeuvrière","word_nosc":"manoeuvriere","lemma":"manoeuvrier","pos":"NOM"} ,
		{"word":"manoir","word_nosc":"manoir","lemma":"manoir","pos":"NOM"} ,
		{"word":"manoirs","word_nosc":"manoirs","lemma":"manoir","pos":"NOM"} ,
		{"word":"manomètre","word_nosc":"manometre","lemma":"manomètre","pos":"NOM"} ,
		{"word":"manomètres","word_nosc":"manometres","lemma":"manomètre","pos":"NOM"} ,
		{"word":"manouche","word_nosc":"manouche","lemma":"manouche","pos":"NOM"} ,
		{"word":"manouches","word_nosc":"manouches","lemma":"manouche","pos":"NOM"} ,
		{"word":"manouvrier","word_nosc":"manouvrier","lemma":"manouvrier","pos":"NOM"} ,
		{"word":"manquant","word_nosc":"manquant","lemma":"manquant","pos":"NOM"} ,
		{"word":"manquants","word_nosc":"manquants","lemma":"manquant","pos":"NOM"} ,
		{"word":"manque","word_nosc":"manque","lemma":"manque","pos":"NOM"} ,
		{"word":"manquement","word_nosc":"manquement","lemma":"manquement","pos":"NOM"} ,
		{"word":"manquements","word_nosc":"manquements","lemma":"manquement","pos":"NOM"} ,
		{"word":"manques","word_nosc":"manques","lemma":"manque","pos":"NOM"} ,
		{"word":"mansard","word_nosc":"mansard","lemma":"mansard","pos":"NOM"} ,
		{"word":"mansarde","word_nosc":"mansarde","lemma":"mansarde","pos":"NOM"} ,
		{"word":"mansardes","word_nosc":"mansardes","lemma":"mansarde","pos":"NOM"} ,
		{"word":"mansion","word_nosc":"mansion","lemma":"mansion","pos":"NOM"} ,
		{"word":"mansions","word_nosc":"mansions","lemma":"mansion","pos":"NOM"} ,
		{"word":"mansuétude","word_nosc":"mansuetude","lemma":"mansuétude","pos":"NOM"} ,
		{"word":"mansuétudes","word_nosc":"mansuetudes","lemma":"mansuétude","pos":"NOM"} ,
		{"word":"manta","word_nosc":"manta","lemma":"manta","pos":"NOM"} ,
		{"word":"mante","word_nosc":"mante","lemma":"mante","pos":"NOM"} ,
		{"word":"manteau","word_nosc":"manteau","lemma":"manteau","pos":"NOM"} ,
		{"word":"manteaux","word_nosc":"manteaux","lemma":"manteau","pos":"NOM"} ,
		{"word":"mantelet","word_nosc":"mantelet","lemma":"mantelet","pos":"NOM"} ,
		{"word":"mantelets","word_nosc":"mantelets","lemma":"mantelet","pos":"NOM"} ,
		{"word":"mantelure","word_nosc":"mantelure","lemma":"mantelure","pos":"NOM"} ,
		{"word":"mantes","word_nosc":"mantes","lemma":"mante","pos":"NOM"} ,
		{"word":"mantille","word_nosc":"mantille","lemma":"mantille","pos":"NOM"} ,
		{"word":"mantilles","word_nosc":"mantilles","lemma":"mantille","pos":"NOM"} ,
		{"word":"mantique","word_nosc":"mantique","lemma":"mantique","pos":"NOM"} ,
		{"word":"mantra","word_nosc":"mantra","lemma":"mantra","pos":"NOM"} ,
		{"word":"manubrium","word_nosc":"manubrium","lemma":"manubrium","pos":"NOM"} ,
		{"word":"manucure","word_nosc":"manucure","lemma":"manucure","pos":"NOM"} ,
		{"word":"manucures","word_nosc":"manucures","lemma":"manucure","pos":"NOM"} ,
		{"word":"manuel","word_nosc":"manuel","lemma":"manuel","pos":"NOM"} ,
		{"word":"manuelle","word_nosc":"manuelle","lemma":"manuel","pos":"NOM"} ,
		{"word":"manuelles","word_nosc":"manuelles","lemma":"manuel","pos":"NOM"} ,
		{"word":"manuels","word_nosc":"manuels","lemma":"manuel","pos":"NOM"} ,
		{"word":"manufacture","word_nosc":"manufacture","lemma":"manufacture","pos":"NOM"} ,
		{"word":"manufactures","word_nosc":"manufactures","lemma":"manufacture","pos":"NOM"} ,
		{"word":"manufacturiers","word_nosc":"manufacturiers","lemma":"manufacturier","pos":"NOM"} ,
		{"word":"manuscrit","word_nosc":"manuscrit","lemma":"manuscrit","pos":"NOM"} ,
		{"word":"manuscrits","word_nosc":"manuscrits","lemma":"manuscrit","pos":"NOM"} ,
		{"word":"manutention","word_nosc":"manutention","lemma":"manutention","pos":"NOM"} ,
		{"word":"manutentionnaire","word_nosc":"manutentionnaire","lemma":"manutentionnaire","pos":"NOM"} ,
		{"word":"manutentionnaires","word_nosc":"manutentionnaires","lemma":"manutentionnaire","pos":"NOM"} ,
		{"word":"manutentions","word_nosc":"manutentions","lemma":"manutention","pos":"NOM"} ,
		{"word":"manzanilla","word_nosc":"manzanilla","lemma":"manzanilla","pos":"NOM"} ,
		{"word":"manège","word_nosc":"manege","lemma":"manège","pos":"NOM"} ,
		{"word":"manèges","word_nosc":"maneges","lemma":"manège","pos":"NOM"} ,
		{"word":"manécanterie","word_nosc":"manecanterie","lemma":"manécanterie","pos":"NOM"} ,
		{"word":"mao","word_nosc":"mao","lemma":"mao","pos":"NOM"} ,
		{"word":"maori","word_nosc":"maori","lemma":"maori","pos":"NOM"} ,
		{"word":"maorie","word_nosc":"maorie","lemma":"maori","pos":"NOM"} ,
		{"word":"maos","word_nosc":"maos","lemma":"mao","pos":"NOM"} ,
		{"word":"maoïsme","word_nosc":"maoisme","lemma":"maoïsme","pos":"NOM"} ,
		{"word":"mappemonde","word_nosc":"mappemonde","lemma":"mappemonde","pos":"NOM"} ,
		{"word":"mappemondes","word_nosc":"mappemondes","lemma":"mappemonde","pos":"NOM"} ,
		{"word":"maque","word_nosc":"maque","lemma":"maque","pos":"NOM"} ,
		{"word":"maquereau","word_nosc":"maquereau","lemma":"maquereau","pos":"NOM"} ,
		{"word":"maquereautage","word_nosc":"maquereautage","lemma":"maquereautage","pos":"NOM"} ,
		{"word":"maquereautins","word_nosc":"maquereautins","lemma":"maquereautin","pos":"NOM"} ,
		{"word":"maquereaux","word_nosc":"maquereaux","lemma":"maquereau","pos":"NOM"} ,
		{"word":"maquerellage","word_nosc":"maquerellage","lemma":"maquerellage","pos":"NOM"} ,
		{"word":"maquerelle","word_nosc":"maquerelle","lemma":"maquereau","pos":"NOM"} ,
		{"word":"maquerelles","word_nosc":"maquerelles","lemma":"maquereau","pos":"NOM"} ,
		{"word":"maques","word_nosc":"maques","lemma":"maque","pos":"NOM"} ,
		{"word":"maquette","word_nosc":"maquette","lemma":"maquette","pos":"NOM"} ,
		{"word":"maquettes","word_nosc":"maquettes","lemma":"maquette","pos":"NOM"} ,
		{"word":"maquettiste","word_nosc":"maquettiste","lemma":"maquettiste","pos":"NOM"} ,
		{"word":"maquettistes","word_nosc":"maquettistes","lemma":"maquettiste","pos":"NOM"} ,
		{"word":"maquignon","word_nosc":"maquignon","lemma":"maquignon","pos":"NOM"} ,
		{"word":"maquignonnage","word_nosc":"maquignonnage","lemma":"maquignonnage","pos":"NOM"} ,
		{"word":"maquignonnages","word_nosc":"maquignonnages","lemma":"maquignonnage","pos":"NOM"} ,
		{"word":"maquignons","word_nosc":"maquignons","lemma":"maquignon","pos":"NOM"} ,
		{"word":"maquillage","word_nosc":"maquillage","lemma":"maquillage","pos":"NOM"} ,
		{"word":"maquillages","word_nosc":"maquillages","lemma":"maquillage","pos":"NOM"} ,
		{"word":"maquilleur","word_nosc":"maquilleur","lemma":"maquilleur","pos":"NOM"} ,
		{"word":"maquilleurs","word_nosc":"maquilleurs","lemma":"maquilleur","pos":"NOM"} ,
		{"word":"maquilleuse","word_nosc":"maquilleuse","lemma":"maquilleur","pos":"NOM"} ,
		{"word":"maquilleuses","word_nosc":"maquilleuses","lemma":"maquilleur","pos":"NOM"} ,
		{"word":"maquis","word_nosc":"maquis","lemma":"maquis","pos":"NOM"} ,
		{"word":"maquisard","word_nosc":"maquisard","lemma":"maquisard","pos":"NOM"} ,
		{"word":"maquisarde","word_nosc":"maquisarde","lemma":"maquisard","pos":"NOM"} ,
		{"word":"maquisardes","word_nosc":"maquisardes","lemma":"maquisard","pos":"NOM"} ,
		{"word":"maquisards","word_nosc":"maquisards","lemma":"maquisard","pos":"NOM"} ,
		{"word":"mara","word_nosc":"mara","lemma":"mara","pos":"NOM"} ,
		{"word":"marabout","word_nosc":"marabout","lemma":"marabout","pos":"NOM"} ,
		{"word":"marabouts","word_nosc":"marabouts","lemma":"marabout","pos":"NOM"} ,
		{"word":"maracas","word_nosc":"maracas","lemma":"maraca","pos":"NOM"} ,
		{"word":"marae","word_nosc":"marae","lemma":"marae","pos":"NOM"} ,
		{"word":"marais","word_nosc":"marais","lemma":"marais","pos":"NOM"} ,
		{"word":"maranta","word_nosc":"maranta","lemma":"maranta","pos":"NOM"} ,
		{"word":"marante","word_nosc":"marante","lemma":"marante","pos":"NOM"} ,
		{"word":"marasme","word_nosc":"marasme","lemma":"marasme","pos":"NOM"} ,
		{"word":"marasmes","word_nosc":"marasmes","lemma":"marasme","pos":"NOM"} ,
		{"word":"marasquin","word_nosc":"marasquin","lemma":"marasquin","pos":"NOM"} ,
		{"word":"marathon","word_nosc":"marathon","lemma":"marathon","pos":"NOM"} ,
		{"word":"marathonien","word_nosc":"marathonien","lemma":"marathonien","pos":"NOM"} ,
		{"word":"marathonienne","word_nosc":"marathonienne","lemma":"marathonien","pos":"NOM"} ,
		{"word":"marathons","word_nosc":"marathons","lemma":"marathon","pos":"NOM"} ,
		{"word":"maraud","word_nosc":"maraud","lemma":"maraud","pos":"NOM"} ,
		{"word":"maraude","word_nosc":"maraude","lemma":"maraud","pos":"NOM"} ,
		{"word":"maraudes","word_nosc":"maraudes","lemma":"maraud","pos":"NOM"} ,
		{"word":"maraudeur","word_nosc":"maraudeur","lemma":"maraudeur","pos":"NOM"} ,
		{"word":"maraudeurs","word_nosc":"maraudeurs","lemma":"maraudeur","pos":"NOM"} ,
		{"word":"maraudeuse","word_nosc":"maraudeuse","lemma":"maraudeur","pos":"NOM"} ,
		{"word":"marauds","word_nosc":"marauds","lemma":"maraud","pos":"NOM"} ,
		{"word":"maravédis","word_nosc":"maravedis","lemma":"maravédis","pos":"NOM"} ,
		{"word":"maraîcher","word_nosc":"maraicher","lemma":"maraîcher","pos":"NOM"} ,
		{"word":"maraîchers","word_nosc":"maraichers","lemma":"maraîcher","pos":"NOM"} ,
		{"word":"maraîchère","word_nosc":"maraichere","lemma":"maraîcher","pos":"NOM"} ,
		{"word":"maraîchères","word_nosc":"maraicheres","lemma":"maraîcher","pos":"NOM"} ,
		{"word":"marbre","word_nosc":"marbre","lemma":"marbre","pos":"NOM"} ,
		{"word":"marbrerie","word_nosc":"marbrerie","lemma":"marbrerie","pos":"NOM"} ,
		{"word":"marbreries","word_nosc":"marbreries","lemma":"marbrerie","pos":"NOM"} ,
		{"word":"marbres","word_nosc":"marbres","lemma":"marbre","pos":"NOM"} ,
		{"word":"marbrure","word_nosc":"marbrure","lemma":"marbrure","pos":"NOM"} ,
		{"word":"marbrures","word_nosc":"marbrures","lemma":"marbrure","pos":"NOM"} ,
		{"word":"marc","word_nosc":"marc","lemma":"marc","pos":"NOM"} ,
		{"word":"marcassin","word_nosc":"marcassin","lemma":"marcassin","pos":"NOM"} ,
		{"word":"marcassins","word_nosc":"marcassins","lemma":"marcassin","pos":"NOM"} ,
		{"word":"marcel","word_nosc":"marcel","lemma":"marcel","pos":"NOM"} ,
		{"word":"marceline","word_nosc":"marceline","lemma":"marceline","pos":"NOM"} ,
		{"word":"marchand","word_nosc":"marchand","lemma":"marchand","pos":"NOM"} ,
		{"word":"marchandage","word_nosc":"marchandage","lemma":"marchandage","pos":"NOM"} ,
		{"word":"marchandages","word_nosc":"marchandages","lemma":"marchandage","pos":"NOM"} ,
		{"word":"marchande","word_nosc":"marchande","lemma":"marchand","pos":"NOM"} ,
		{"word":"marchandes","word_nosc":"marchandes","lemma":"marchand","pos":"NOM"} ,
		{"word":"marchandeuse","word_nosc":"marchandeuse","lemma":"marchandeur","pos":"NOM"} ,
		{"word":"marchandise","word_nosc":"marchandise","lemma":"marchandise","pos":"NOM"} ,
		{"word":"marchandises","word_nosc":"marchandises","lemma":"marchandise","pos":"NOM"} ,
		{"word":"marchandiseur","word_nosc":"marchandiseur","lemma":"marchandiseur","pos":"NOM"} ,
		{"word":"marchands","word_nosc":"marchands","lemma":"marchand","pos":"NOM"} ,
		{"word":"marche","word_nosc":"marche","lemma":"marche","pos":"NOM"} ,
		{"word":"marchepied","word_nosc":"marchepied","lemma":"marchepied","pos":"NOM"} ,
		{"word":"marchepieds","word_nosc":"marchepieds","lemma":"marchepied","pos":"NOM"} ,
		{"word":"marches","word_nosc":"marches","lemma":"marche","pos":"NOM"} ,
		{"word":"marcheur","word_nosc":"marcheur","lemma":"marcheur","pos":"NOM"} ,
		{"word":"marcheurs","word_nosc":"marcheurs","lemma":"marcheur","pos":"NOM"} ,
		{"word":"marcheuse","word_nosc":"marcheuse","lemma":"marcheur","pos":"NOM"} ,
		{"word":"marcheuses","word_nosc":"marcheuses","lemma":"marcheur","pos":"NOM"} ,
		{"word":"marché","word_nosc":"marche","lemma":"marché","pos":"NOM"} ,
		{"word":"marchés","word_nosc":"marches","lemma":"marché","pos":"NOM"} ,
		{"word":"marcotin","word_nosc":"marcotin","lemma":"marcotin","pos":"NOM"} ,
		{"word":"marcotte","word_nosc":"marcotte","lemma":"marcotte","pos":"NOM"} ,
		{"word":"marcs","word_nosc":"marcs","lemma":"marc","pos":"NOM"} ,
		{"word":"mardi","word_nosc":"mardi","lemma":"mardi","pos":"NOM"} ,
		{"word":"mardis","word_nosc":"mardis","lemma":"mardi","pos":"NOM"} ,
		{"word":"mare","word_nosc":"mare","lemma":"mar","pos":"NOM"} ,
		{"word":"mare","word_nosc":"mare","lemma":"mare","pos":"NOM"} ,
		{"word":"marelle","word_nosc":"marelle","lemma":"marelle","pos":"NOM"} ,
		{"word":"marelles","word_nosc":"marelles","lemma":"marelle","pos":"NOM"} ,
		{"word":"marengo","word_nosc":"marengo","lemma":"marengo","pos":"NOM"} ,
		{"word":"marennes","word_nosc":"marennes","lemma":"marennes","pos":"NOM"} ,
		{"word":"mares","word_nosc":"mares","lemma":"mare","pos":"NOM"} ,
		{"word":"mareyeur","word_nosc":"mareyeur","lemma":"mareyeur","pos":"NOM"} ,
		{"word":"mareyeurs","word_nosc":"mareyeurs","lemma":"mareyeur","pos":"NOM"} ,
		{"word":"mareyeuse","word_nosc":"mareyeuse","lemma":"mareyeur","pos":"NOM"} ,
		{"word":"mareyeuses","word_nosc":"mareyeuses","lemma":"mareyeur","pos":"NOM"} ,
		{"word":"margarine","word_nosc":"margarine","lemma":"margarine","pos":"NOM"} ,
		{"word":"margaux","word_nosc":"margaux","lemma":"margaux","pos":"NOM"} ,
		{"word":"margay","word_nosc":"margay","lemma":"margay","pos":"NOM"} ,
		{"word":"marge","word_nosc":"marge","lemma":"marge","pos":"NOM"} ,
		{"word":"margelle","word_nosc":"margelle","lemma":"margelle","pos":"NOM"} ,
		{"word":"margelles","word_nosc":"margelles","lemma":"margelle","pos":"NOM"} ,
		{"word":"marges","word_nosc":"marges","lemma":"marge","pos":"NOM"} ,
		{"word":"margeuse","word_nosc":"margeuse","lemma":"margeur","pos":"NOM"} ,
		{"word":"marginal","word_nosc":"marginal","lemma":"marginal","pos":"NOM"} ,
		{"word":"marginales","word_nosc":"marginales","lemma":"marginal","pos":"NOM"} ,
		{"word":"marginalité","word_nosc":"marginalite","lemma":"marginalité","pos":"NOM"} ,
		{"word":"marginaux","word_nosc":"marginaux","lemma":"marginal","pos":"NOM"} ,
		{"word":"margis","word_nosc":"margis","lemma":"margis","pos":"NOM"} ,
		{"word":"margot","word_nosc":"margot","lemma":"margot","pos":"NOM"} ,
		{"word":"margotin","word_nosc":"margotin","lemma":"margotin","pos":"NOM"} ,
		{"word":"margotins","word_nosc":"margotins","lemma":"margotin","pos":"NOM"} ,
		{"word":"margoton","word_nosc":"margoton","lemma":"margoton","pos":"NOM"} ,
		{"word":"margouillat","word_nosc":"margouillat","lemma":"margouillat","pos":"NOM"} ,
		{"word":"margouillats","word_nosc":"margouillats","lemma":"margouillat","pos":"NOM"} ,
		{"word":"margouillis","word_nosc":"margouillis","lemma":"margouillis","pos":"NOM"} ,
		{"word":"margoulette","word_nosc":"margoulette","lemma":"margoulette","pos":"NOM"} ,
		{"word":"margoulin","word_nosc":"margoulin","lemma":"margoulin","pos":"NOM"} ,
		{"word":"margoulins","word_nosc":"margoulins","lemma":"margoulin","pos":"NOM"} ,
		{"word":"margrave","word_nosc":"margrave","lemma":"margrave","pos":"NOM"} ,
		{"word":"margraviat","word_nosc":"margraviat","lemma":"margraviat","pos":"NOM"} ,
		{"word":"marguerite","word_nosc":"marguerite","lemma":"marguerite","pos":"NOM"} ,
		{"word":"marguerites","word_nosc":"marguerites","lemma":"marguerite","pos":"NOM"} ,
		{"word":"marguillier","word_nosc":"marguillier","lemma":"marguillier","pos":"NOM"} ,
		{"word":"marguilliers","word_nosc":"marguilliers","lemma":"marguillier","pos":"NOM"} ,
		{"word":"mari","word_nosc":"mari","lemma":"mari","pos":"NOM"} ,
		{"word":"mariachi","word_nosc":"mariachi","lemma":"mariachi","pos":"NOM"} ,
		{"word":"mariachis","word_nosc":"mariachis","lemma":"mariachi","pos":"NOM"} ,
		{"word":"mariage","word_nosc":"mariage","lemma":"mariage","pos":"NOM"} ,
		{"word":"mariages","word_nosc":"mariages","lemma":"mariage","pos":"NOM"} ,
		{"word":"marianne","word_nosc":"marianne","lemma":"marian","pos":"NOM"} ,
		{"word":"marida","word_nosc":"marida","lemma":"marida","pos":"NOM"} ,
		{"word":"maridas","word_nosc":"maridas","lemma":"marida","pos":"NOM"} ,
		{"word":"marie-couche-toi-là","word_nosc":"marie-couche-toi-la","lemma":"marie-couche-toi-là","pos":"NOM"} ,
		{"word":"marie-jeanne","word_nosc":"marie-jeanne","lemma":"marie-jeanne","pos":"NOM"} ,
		{"word":"marie-louise","word_nosc":"marie-louise","lemma":"marie-louise","pos":"NOM"} ,
		{"word":"marie-salope","word_nosc":"marie-salope","lemma":"marie-salope","pos":"NOM"} ,
		{"word":"marieur","word_nosc":"marieur","lemma":"marieur","pos":"NOM"} ,
		{"word":"marieuse","word_nosc":"marieuse","lemma":"marieur","pos":"NOM"} ,
		{"word":"marieuses","word_nosc":"marieuses","lemma":"marieur","pos":"NOM"} ,
		{"word":"marigot","word_nosc":"marigot","lemma":"marigot","pos":"NOM"} ,
		{"word":"marigots","word_nosc":"marigots","lemma":"marigot","pos":"NOM"} ,
		{"word":"marihuana","word_nosc":"marihuana","lemma":"marihuana","pos":"NOM"} ,
		{"word":"marijuana","word_nosc":"marijuana","lemma":"marijuana","pos":"NOM"} ,
		{"word":"marimba","word_nosc":"marimba","lemma":"marimba","pos":"NOM"} ,
		{"word":"marin","word_nosc":"marin","lemma":"marin","pos":"NOM"} ,
		{"word":"marin-pêcheur","word_nosc":"marin-pecheur","lemma":"marin-pêcheur","pos":"NOM"} ,
		{"word":"marina","word_nosc":"marina","lemma":"marina","pos":"NOM"} ,
		{"word":"marinade","word_nosc":"marinade","lemma":"marinade","pos":"NOM"} ,
		{"word":"marinades","word_nosc":"marinades","lemma":"marinade","pos":"NOM"} ,
		{"word":"marinage","word_nosc":"marinage","lemma":"marinage","pos":"NOM"} ,
		{"word":"marinas","word_nosc":"marinas","lemma":"marina","pos":"NOM"} ,
		{"word":"marine","word_nosc":"marine","lemma":"marine","pos":"NOM"} ,
		{"word":"marines","word_nosc":"marines","lemma":"marine","pos":"NOM"} ,
		{"word":"maringoins","word_nosc":"maringoins","lemma":"maringoin","pos":"NOM"} ,
		{"word":"marinier","word_nosc":"marinier","lemma":"marinier","pos":"NOM"} ,
		{"word":"mariniers","word_nosc":"mariniers","lemma":"marinier","pos":"NOM"} ,
		{"word":"marinière","word_nosc":"mariniere","lemma":"marinière","pos":"NOM"} ,
		{"word":"marinières","word_nosc":"marinieres","lemma":"marinière","pos":"NOM"} ,
		{"word":"marins","word_nosc":"marins","lemma":"marin","pos":"NOM"} ,
		{"word":"mariol","word_nosc":"mariol","lemma":"mariol","pos":"NOM"} ,
		{"word":"mariole","word_nosc":"mariole","lemma":"mariole","pos":"NOM"} ,
		{"word":"marioles","word_nosc":"marioles","lemma":"mariole","pos":"NOM"} ,
		{"word":"mariolle","word_nosc":"mariolle","lemma":"mariolle","pos":"NOM"} ,
		{"word":"mariolles","word_nosc":"mariolles","lemma":"mariolle","pos":"NOM"} ,
		{"word":"marionnette","word_nosc":"marionnette","lemma":"marionnette","pos":"NOM"} ,
		{"word":"marionnettes","word_nosc":"marionnettes","lemma":"marionnette","pos":"NOM"} ,
		{"word":"marionnettiste","word_nosc":"marionnettiste","lemma":"marionnettiste","pos":"NOM"} ,
		{"word":"maris","word_nosc":"maris","lemma":"mari","pos":"NOM"} ,
		{"word":"maristes","word_nosc":"maristes","lemma":"mariste","pos":"NOM"} ,
		{"word":"maritorne","word_nosc":"maritorne","lemma":"maritorne","pos":"NOM"} ,
		{"word":"marivaudage","word_nosc":"marivaudage","lemma":"marivaudage","pos":"NOM"} ,
		{"word":"marivaudages","word_nosc":"marivaudages","lemma":"marivaudage","pos":"NOM"} ,
		{"word":"marié","word_nosc":"marie","lemma":"marié","pos":"NOM"} ,
		{"word":"mariée","word_nosc":"mariee","lemma":"marié","pos":"NOM"} ,
		{"word":"mariées","word_nosc":"mariees","lemma":"marié","pos":"NOM"} ,
		{"word":"mariés","word_nosc":"maries","lemma":"marié","pos":"NOM"} ,
		{"word":"marjolaine","word_nosc":"marjolaine","lemma":"marjolaine","pos":"NOM"} ,
		{"word":"mark","word_nosc":"mark","lemma":"mark","pos":"NOM"} ,
		{"word":"marker","word_nosc":"marker","lemma":"marker","pos":"NOM"} ,
		{"word":"marketing","word_nosc":"marketing","lemma":"marketing","pos":"NOM"} ,
		{"word":"marketings","word_nosc":"marketings","lemma":"marketing","pos":"NOM"} ,
		{"word":"marks","word_nosc":"marks","lemma":"mark","pos":"NOM"} ,
		{"word":"marlin","word_nosc":"marlin","lemma":"marlin","pos":"NOM"} ,
		{"word":"marlou","word_nosc":"marlou","lemma":"marlou","pos":"NOM"} ,
		{"word":"marloupin","word_nosc":"marloupin","lemma":"marloupin","pos":"NOM"} ,
		{"word":"marloupinerie","word_nosc":"marloupinerie","lemma":"marloupinerie","pos":"NOM"} ,
		{"word":"marloupineries","word_nosc":"marloupineries","lemma":"marloupinerie","pos":"NOM"} ,
		{"word":"marloupins","word_nosc":"marloupins","lemma":"marloupin","pos":"NOM"} ,
		{"word":"marlous","word_nosc":"marlous","lemma":"marlou","pos":"NOM"} ,
		{"word":"marmaille","word_nosc":"marmaille","lemma":"marmaille","pos":"NOM"} ,
		{"word":"marmailles","word_nosc":"marmailles","lemma":"marmaille","pos":"NOM"} ,
		{"word":"marmelade","word_nosc":"marmelade","lemma":"marmelade","pos":"NOM"} ,
		{"word":"marmelades","word_nosc":"marmelades","lemma":"marmelade","pos":"NOM"} ,
		{"word":"marmitage","word_nosc":"marmitage","lemma":"marmitage","pos":"NOM"} ,
		{"word":"marmitages","word_nosc":"marmitages","lemma":"marmitage","pos":"NOM"} ,
		{"word":"marmite","word_nosc":"marmite","lemma":"marmite","pos":"NOM"} ,
		{"word":"marmites","word_nosc":"marmites","lemma":"marmite","pos":"NOM"} ,
		{"word":"marmiton","word_nosc":"marmiton","lemma":"marmiton","pos":"NOM"} ,
		{"word":"marmitons","word_nosc":"marmitons","lemma":"marmiton","pos":"NOM"} ,
		{"word":"marmitées","word_nosc":"marmitees","lemma":"marmitée","pos":"NOM"} ,
		{"word":"marmonnement","word_nosc":"marmonnement","lemma":"marmonnement","pos":"NOM"} ,
		{"word":"marmonnements","word_nosc":"marmonnements","lemma":"marmonnement","pos":"NOM"} ,
		{"word":"marmot","word_nosc":"marmot","lemma":"marmot","pos":"NOM"} ,
		{"word":"marmots","word_nosc":"marmots","lemma":"marmot","pos":"NOM"} ,
		{"word":"marmottages","word_nosc":"marmottages","lemma":"marmottage","pos":"NOM"} ,
		{"word":"marmotte","word_nosc":"marmotte","lemma":"marmotte","pos":"NOM"} ,
		{"word":"marmottement","word_nosc":"marmottement","lemma":"marmottement","pos":"NOM"} ,
		{"word":"marmottes","word_nosc":"marmottes","lemma":"marmotte","pos":"NOM"} ,
		{"word":"marmouset","word_nosc":"marmouset","lemma":"marmouset","pos":"NOM"} ,
		{"word":"marmousets","word_nosc":"marmousets","lemma":"marmouset","pos":"NOM"} ,
		{"word":"marne","word_nosc":"marne","lemma":"marne","pos":"NOM"} ,
		{"word":"marnes","word_nosc":"marnes","lemma":"marne","pos":"NOM"} ,
		{"word":"marnières","word_nosc":"marnieres","lemma":"marnière","pos":"NOM"} ,
		{"word":"marocain","word_nosc":"marocain","lemma":"marocain","pos":"NOM"} ,
		{"word":"marocaine","word_nosc":"marocaine","lemma":"marocain","pos":"NOM"} ,
		{"word":"marocaines","word_nosc":"marocaines","lemma":"marocain","pos":"NOM"} ,
		{"word":"marocains","word_nosc":"marocains","lemma":"marocain","pos":"NOM"} ,
		{"word":"maronites","word_nosc":"maronites","lemma":"maronite","pos":"NOM"} ,
		{"word":"maroquin","word_nosc":"maroquin","lemma":"maroquin","pos":"NOM"} ,
		{"word":"maroquinerie","word_nosc":"maroquinerie","lemma":"maroquinerie","pos":"NOM"} ,
		{"word":"maroquineries","word_nosc":"maroquineries","lemma":"maroquinerie","pos":"NOM"} ,
		{"word":"maroquinier","word_nosc":"maroquinier","lemma":"maroquinier","pos":"NOM"} ,
		{"word":"maroquiniers","word_nosc":"maroquiniers","lemma":"maroquinier","pos":"NOM"} ,
		{"word":"maroquins","word_nosc":"maroquins","lemma":"maroquin","pos":"NOM"} ,
		{"word":"marotte","word_nosc":"marotte","lemma":"marotte","pos":"NOM"} ,
		{"word":"marottes","word_nosc":"marottes","lemma":"marotte","pos":"NOM"} ,
		{"word":"marouette","word_nosc":"marouette","lemma":"marouette","pos":"NOM"} ,
		{"word":"maroufle","word_nosc":"maroufle","lemma":"maroufle","pos":"NOM"} ,
		{"word":"maroufles","word_nosc":"maroufles","lemma":"maroufle","pos":"NOM"} ,
		{"word":"marquage","word_nosc":"marquage","lemma":"marquage","pos":"NOM"} ,
		{"word":"marque","word_nosc":"marque","lemma":"marque","pos":"NOM"} ,
		{"word":"marque-page","word_nosc":"marque-page","lemma":"marque-page","pos":"NOM"} ,
		{"word":"marque-pages","word_nosc":"marque-pages","lemma":"marque-page","pos":"NOM"} ,
		{"word":"marques","word_nosc":"marques","lemma":"marque","pos":"NOM"} ,
		{"word":"marqueterie","word_nosc":"marqueterie","lemma":"marqueterie","pos":"NOM"} ,
		{"word":"marqueteries","word_nosc":"marqueteries","lemma":"marqueterie","pos":"NOM"} ,
		{"word":"marqueur","word_nosc":"marqueur","lemma":"marqueur","pos":"NOM"} ,
		{"word":"marqueurs","word_nosc":"marqueurs","lemma":"marqueur","pos":"NOM"} ,
		{"word":"marquis","word_nosc":"marquis","lemma":"marquis","pos":"NOM"} ,
		{"word":"marquisat","word_nosc":"marquisat","lemma":"marquisat","pos":"NOM"} ,
		{"word":"marquisats","word_nosc":"marquisats","lemma":"marquisat","pos":"NOM"} ,
		{"word":"marquise","word_nosc":"marquise","lemma":"marquis","pos":"NOM"} ,
		{"word":"marquises","word_nosc":"marquises","lemma":"marquis","pos":"NOM"} ,
		{"word":"marrade","word_nosc":"marrade","lemma":"marrade","pos":"NOM"} ,
		{"word":"marraine","word_nosc":"marraine","lemma":"marraine","pos":"NOM"} ,
		{"word":"marraines","word_nosc":"marraines","lemma":"marraine","pos":"NOM"} ,
		{"word":"marrane","word_nosc":"marrane","lemma":"marrane","pos":"NOM"} ,
		{"word":"marranes","word_nosc":"marranes","lemma":"marrane","pos":"NOM"} ,
		{"word":"marrant","word_nosc":"marrant","lemma":"marrant","pos":"NOM"} ,
		{"word":"marrante","word_nosc":"marrante","lemma":"marrant","pos":"NOM"} ,
		{"word":"marrantes","word_nosc":"marrantes","lemma":"marrant","pos":"NOM"} ,
		{"word":"marrants","word_nosc":"marrants","lemma":"marrant","pos":"NOM"} ,
		{"word":"marron","word_nosc":"marron","lemma":"marron","pos":"NOM"} ,
		{"word":"marronnier","word_nosc":"marronnier","lemma":"marronnier","pos":"NOM"} ,
		{"word":"marronniers","word_nosc":"marronniers","lemma":"marronnier","pos":"NOM"} ,
		{"word":"marrons","word_nosc":"marrons","lemma":"marron","pos":"NOM"} ,
		{"word":"mars","word_nosc":"mars","lemma":"mars","pos":"NOM"} ,
		{"word":"marsala","word_nosc":"marsala","lemma":"marsala","pos":"NOM"} ,
		{"word":"marsaules","word_nosc":"marsaules","lemma":"marsaule","pos":"NOM"} ,
		{"word":"marseillais","word_nosc":"marseillais","lemma":"marseillais","pos":"NOM"} ,
		{"word":"marseillaise","word_nosc":"marseillaise","lemma":"marseillais","pos":"NOM"} ,
		{"word":"marshal","word_nosc":"marshal","lemma":"marshal","pos":"NOM"} ,
		{"word":"marshals","word_nosc":"marshals","lemma":"marshal","pos":"NOM"} ,
		{"word":"marsouin","word_nosc":"marsouin","lemma":"marsouin","pos":"NOM"} ,
		{"word":"marsouins","word_nosc":"marsouins","lemma":"marsouin","pos":"NOM"} ,
		{"word":"marsupial","word_nosc":"marsupial","lemma":"marsupial","pos":"NOM"} ,
		{"word":"marsupiaux","word_nosc":"marsupiaux","lemma":"marsupial","pos":"NOM"} ,
		{"word":"marsupilami","word_nosc":"marsupilami","lemma":"marsupilami","pos":"NOM"} ,
		{"word":"marsupilamis","word_nosc":"marsupilamis","lemma":"marsupilami","pos":"NOM"} ,
		{"word":"martagon","word_nosc":"martagon","lemma":"martagon","pos":"NOM"} ,
		{"word":"marteau","word_nosc":"marteau","lemma":"marteau","pos":"NOM"} ,
		{"word":"marteau-pilon","word_nosc":"marteau-pilon","lemma":"marteau-pilon","pos":"NOM"} ,
		{"word":"marteau-piqueur","word_nosc":"marteau-piqueur","lemma":"marteau-piqueur","pos":"NOM"} ,
		{"word":"marteaux","word_nosc":"marteaux","lemma":"marteau","pos":"NOM"} ,
		{"word":"marteaux-pilons","word_nosc":"marteaux-pilons","lemma":"marteau-pilon","pos":"NOM"} ,
		{"word":"martel","word_nosc":"martel","lemma":"martel","pos":"NOM"} ,
		{"word":"martelage","word_nosc":"martelage","lemma":"martelage","pos":"NOM"} ,
		{"word":"martelet","word_nosc":"martelet","lemma":"martelet","pos":"NOM"} ,
		{"word":"marteleur","word_nosc":"marteleur","lemma":"marteleur","pos":"NOM"} ,
		{"word":"martels","word_nosc":"martels","lemma":"martel","pos":"NOM"} ,
		{"word":"martialités","word_nosc":"martialites","lemma":"martialité","pos":"NOM"} ,
		{"word":"martien","word_nosc":"martien","lemma":"martien","pos":"NOM"} ,
		{"word":"martienne","word_nosc":"martienne","lemma":"martienne","pos":"NOM"} ,
		{"word":"martiennes","word_nosc":"martiennes","lemma":"martienne","pos":"NOM"} ,
		{"word":"martiens","word_nosc":"martiens","lemma":"martien","pos":"NOM"} ,
		{"word":"martin","word_nosc":"martin","lemma":"martin","pos":"NOM"} ,
		{"word":"martin-pêcheur","word_nosc":"martin-pecheur","lemma":"martin-pêcheur","pos":"NOM"} ,
		{"word":"martinet","word_nosc":"martinet","lemma":"martinet","pos":"NOM"} ,
		{"word":"martinets","word_nosc":"martinets","lemma":"martinet","pos":"NOM"} ,
		{"word":"martingale","word_nosc":"martingale","lemma":"martingale","pos":"NOM"} ,
		{"word":"martingales","word_nosc":"martingales","lemma":"martingale","pos":"NOM"} ,
		{"word":"martini","word_nosc":"martini","lemma":"martini","pos":"NOM"} ,
		{"word":"martiniquais","word_nosc":"martiniquais","lemma":"martiniquais","pos":"NOM"} ,
		{"word":"martiniquaise","word_nosc":"martiniquaise","lemma":"martiniquais","pos":"NOM"} ,
		{"word":"martiniquaises","word_nosc":"martiniquaises","lemma":"martiniquais","pos":"NOM"} ,
		{"word":"martinis","word_nosc":"martinis","lemma":"martini","pos":"NOM"} ,
		{"word":"martins","word_nosc":"martins","lemma":"martin","pos":"NOM"} ,
		{"word":"martins-pêcheurs","word_nosc":"martins-pecheurs","lemma":"martin-pêcheur","pos":"NOM"} ,
		{"word":"martre","word_nosc":"martre","lemma":"martre","pos":"NOM"} ,
		{"word":"martres","word_nosc":"martres","lemma":"martre","pos":"NOM"} ,
		{"word":"martyr","word_nosc":"martyr","lemma":"martyr","pos":"NOM"} ,
		{"word":"martyre","word_nosc":"martyre","lemma":"martyre","pos":"NOM"} ,
		{"word":"martyres","word_nosc":"martyres","lemma":"martyre","pos":"NOM"} ,
		{"word":"martyrologe","word_nosc":"martyrologe","lemma":"martyrologe","pos":"NOM"} ,
		{"word":"martyrologes","word_nosc":"martyrologes","lemma":"martyrologe","pos":"NOM"} ,
		{"word":"martyrs","word_nosc":"martyrs","lemma":"martyr","pos":"NOM"} ,
		{"word":"martèlement","word_nosc":"martelement","lemma":"martèlement","pos":"NOM"} ,
		{"word":"martèlements","word_nosc":"martelements","lemma":"martèlement","pos":"NOM"} ,
		{"word":"marxisme","word_nosc":"marxisme","lemma":"marxisme","pos":"NOM"} ,
		{"word":"marxisme-léninisme","word_nosc":"marxisme-leninisme","lemma":"marxisme-léninisme","pos":"NOM"} ,
		{"word":"marxiste","word_nosc":"marxiste","lemma":"marxiste","pos":"NOM"} ,
		{"word":"marxiste-léniniste","word_nosc":"marxiste-leniniste","lemma":"marxiste-léniniste","pos":"NOM"} ,
		{"word":"marxistes","word_nosc":"marxistes","lemma":"marxiste","pos":"NOM"} ,
		{"word":"marxistes-léninistes","word_nosc":"marxistes-leninistes","lemma":"marxiste-léniniste","pos":"NOM"} ,
		{"word":"mary","word_nosc":"mary","lemma":"mary","pos":"NOM"} ,
		{"word":"maryland","word_nosc":"maryland","lemma":"maryland","pos":"NOM"} ,
		{"word":"marâtre","word_nosc":"maratre","lemma":"marâtre","pos":"NOM"} ,
		{"word":"marâtres","word_nosc":"maratres","lemma":"marâtre","pos":"NOM"} ,
		{"word":"marécage","word_nosc":"marecage","lemma":"marécage","pos":"NOM"} ,
		{"word":"marécages","word_nosc":"marecages","lemma":"marécage","pos":"NOM"} ,
		{"word":"maréchal","word_nosc":"marechal","lemma":"maréchal","pos":"NOM"} ,
		{"word":"maréchal-ferrant","word_nosc":"marechal-ferrant","lemma":"maréchal-ferrant","pos":"NOM"} ,
		{"word":"maréchalat","word_nosc":"marechalat","lemma":"maréchalat","pos":"NOM"} ,
		{"word":"maréchale","word_nosc":"marechale","lemma":"maréchal","pos":"NOM"} ,
		{"word":"maréchalerie","word_nosc":"marechalerie","lemma":"maréchalerie","pos":"NOM"} ,
		{"word":"maréchalistes","word_nosc":"marechalistes","lemma":"maréchaliste","pos":"NOM"} ,
		{"word":"maréchaussée","word_nosc":"marechaussee","lemma":"maréchaussée","pos":"NOM"} ,
		{"word":"maréchaux","word_nosc":"marechaux","lemma":"maréchal","pos":"NOM"} ,
		{"word":"maréchaux-ferrants","word_nosc":"marechaux-ferrants","lemma":"maréchaux-ferrants","pos":"NOM"} ,
		{"word":"marée","word_nosc":"maree","lemma":"marée","pos":"NOM"} ,
		{"word":"marées","word_nosc":"marees","lemma":"marée","pos":"NOM"} ,
		{"word":"mas","word_nosc":"mas","lemma":"mas","pos":"NOM"} ,
		{"word":"masaï","word_nosc":"masai","lemma":"masaï","pos":"NOM"} ,
		{"word":"mascara","word_nosc":"mascara","lemma":"mascara","pos":"NOM"} ,
		{"word":"mascarade","word_nosc":"mascarade","lemma":"mascarade","pos":"NOM"} ,
		{"word":"mascarades","word_nosc":"mascarades","lemma":"mascarade","pos":"NOM"} ,
		{"word":"mascaret","word_nosc":"mascaret","lemma":"mascaret","pos":"NOM"} ,
		{"word":"mascaron","word_nosc":"mascaron","lemma":"mascaron","pos":"NOM"} ,
		{"word":"mascarons","word_nosc":"mascarons","lemma":"mascaron","pos":"NOM"} ,
		{"word":"mascarpone","word_nosc":"mascarpone","lemma":"mascarpone","pos":"NOM"} ,
		{"word":"mascotte","word_nosc":"mascotte","lemma":"mascotte","pos":"NOM"} ,
		{"word":"mascottes","word_nosc":"mascottes","lemma":"mascotte","pos":"NOM"} ,
		{"word":"masculin","word_nosc":"masculin","lemma":"masculin","pos":"NOM"} ,
		{"word":"masculinité","word_nosc":"masculinite","lemma":"masculinité","pos":"NOM"} ,
		{"word":"masculins","word_nosc":"masculins","lemma":"masculin","pos":"NOM"} ,
		{"word":"maser","word_nosc":"maser","lemma":"maser","pos":"NOM"} ,
		{"word":"maskinongé","word_nosc":"maskinonge","lemma":"maskinongé","pos":"NOM"} ,
		{"word":"maso","word_nosc":"maso","lemma":"maso","pos":"NOM"} ,
		{"word":"masochisme","word_nosc":"masochisme","lemma":"masochisme","pos":"NOM"} ,
		{"word":"masochiste","word_nosc":"masochiste","lemma":"masochiste","pos":"NOM"} ,
		{"word":"masochistes","word_nosc":"masochistes","lemma":"masochiste","pos":"NOM"} ,
		{"word":"masos","word_nosc":"masos","lemma":"maso","pos":"NOM"} ,
		{"word":"masquage","word_nosc":"masquage","lemma":"masquage","pos":"NOM"} ,
		{"word":"masquages","word_nosc":"masquages","lemma":"masquage","pos":"NOM"} ,
		{"word":"masque","word_nosc":"masque","lemma":"masque","pos":"NOM"} ,
		{"word":"masque-espion","word_nosc":"masque-espion","lemma":"masque-espion","pos":"NOM"} ,
		{"word":"masques","word_nosc":"masques","lemma":"masque","pos":"NOM"} ,
		{"word":"mass media","word_nosc":"mass media","lemma":"mass media","pos":"NOM"} ,
		{"word":"massacre","word_nosc":"massacre","lemma":"massacre","pos":"NOM"} ,
		{"word":"massacres","word_nosc":"massacres","lemma":"massacre","pos":"NOM"} ,
		{"word":"massacreur","word_nosc":"massacreur","lemma":"massacreur","pos":"NOM"} ,
		{"word":"massacreurs","word_nosc":"massacreurs","lemma":"massacreur","pos":"NOM"} ,
		{"word":"massage","word_nosc":"massage","lemma":"massage","pos":"NOM"} ,
		{"word":"massages","word_nosc":"massages","lemma":"massage","pos":"NOM"} ,
		{"word":"masse","word_nosc":"masse","lemma":"masse","pos":"NOM"} ,
		{"word":"massepain","word_nosc":"massepain","lemma":"massepain","pos":"NOM"} ,
		{"word":"massepains","word_nosc":"massepains","lemma":"massepain","pos":"NOM"} ,
		{"word":"masses","word_nosc":"masses","lemma":"masse","pos":"NOM"} ,
		{"word":"massettes","word_nosc":"massettes","lemma":"massette","pos":"NOM"} ,
		{"word":"masseur","word_nosc":"masseur","lemma":"masseur","pos":"NOM"} ,
		{"word":"masseurs","word_nosc":"masseurs","lemma":"masseur","pos":"NOM"} ,
		{"word":"masseuse","word_nosc":"masseuse","lemma":"masseur","pos":"NOM"} ,
		{"word":"masseuses","word_nosc":"masseuses","lemma":"masseur","pos":"NOM"} ,
		{"word":"massicot","word_nosc":"massicot","lemma":"massicot","pos":"NOM"} ,
		{"word":"massicotier","word_nosc":"massicotier","lemma":"massicotier","pos":"NOM"} ,
		{"word":"massicots","word_nosc":"massicots","lemma":"massicot","pos":"NOM"} ,
		{"word":"massif","word_nosc":"massif","lemma":"massif","pos":"NOM"} ,
		{"word":"massifs","word_nosc":"massifs","lemma":"massif","pos":"NOM"} ,
		{"word":"massivité","word_nosc":"massivite","lemma":"massivité","pos":"NOM"} ,
		{"word":"massue","word_nosc":"massue","lemma":"massue","pos":"NOM"} ,
		{"word":"massues","word_nosc":"massues","lemma":"massue","pos":"NOM"} ,
		{"word":"massé","word_nosc":"masse","lemma":"massé","pos":"NOM"} ,
		{"word":"massés","word_nosc":"masses","lemma":"massé","pos":"NOM"} ,
		{"word":"mastaba","word_nosc":"mastaba","lemma":"mastaba","pos":"NOM"} ,
		{"word":"mastabas","word_nosc":"mastabas","lemma":"mastaba","pos":"NOM"} ,
		{"word":"mastar","word_nosc":"mastar","lemma":"mastar","pos":"NOM"} ,
		{"word":"mastard","word_nosc":"mastard","lemma":"mastard","pos":"NOM"} ,
		{"word":"mastards","word_nosc":"mastards","lemma":"mastard","pos":"NOM"} ,
		{"word":"mastectomie","word_nosc":"mastectomie","lemma":"mastectomie","pos":"NOM"} ,
		{"word":"master","word_nosc":"master","lemma":"master","pos":"NOM"} ,
		{"word":"masters","word_nosc":"masters","lemma":"master","pos":"NOM"} ,
		{"word":"mastic","word_nosc":"mastic","lemma":"mastic","pos":"NOM"} ,
		{"word":"masticage","word_nosc":"masticage","lemma":"masticage","pos":"NOM"} ,
		{"word":"mastication","word_nosc":"mastication","lemma":"mastication","pos":"NOM"} ,
		{"word":"mastications","word_nosc":"mastications","lemma":"mastication","pos":"NOM"} ,
		{"word":"mastiff","word_nosc":"mastiff","lemma":"mastiff","pos":"NOM"} ,
		{"word":"mastite","word_nosc":"mastite","lemma":"mastite","pos":"NOM"} ,
		{"word":"mastodonte","word_nosc":"mastodonte","lemma":"mastodonte","pos":"NOM"} ,
		{"word":"mastodontes","word_nosc":"mastodontes","lemma":"mastodonte","pos":"NOM"} ,
		{"word":"mastroquet","word_nosc":"mastroquet","lemma":"mastroquet","pos":"NOM"} ,
		{"word":"mastroquets","word_nosc":"mastroquets","lemma":"mastroquet","pos":"NOM"} ,
		{"word":"masturbation","word_nosc":"masturbation","lemma":"masturbation","pos":"NOM"} ,
		{"word":"masturbations","word_nosc":"masturbations","lemma":"masturbation","pos":"NOM"} ,
		{"word":"mastère","word_nosc":"mastere","lemma":"mastère","pos":"NOM"} ,
		{"word":"masure","word_nosc":"masure","lemma":"masure","pos":"NOM"} ,
		{"word":"masures","word_nosc":"masures","lemma":"masure","pos":"NOM"} ,
		{"word":"mat","word_nosc":"mat","lemma":"mat","pos":"NOM"} ,
		{"word":"mat'","word_nosc":"mat'","lemma":"mat","pos":"NOM"} ,
		{"word":"matador","word_nosc":"matador","lemma":"matador","pos":"NOM"} ,
		{"word":"matadors","word_nosc":"matadors","lemma":"matador","pos":"NOM"} ,
		{"word":"mataf","word_nosc":"mataf","lemma":"mataf","pos":"NOM"} ,
		{"word":"matafs","word_nosc":"matafs","lemma":"mataf","pos":"NOM"} ,
		{"word":"matamore","word_nosc":"matamore","lemma":"matamore","pos":"NOM"} ,
		{"word":"matamores","word_nosc":"matamores","lemma":"matamore","pos":"NOM"} ,
		{"word":"match","word_nosc":"match","lemma":"match","pos":"NOM"} ,
		{"word":"matches","word_nosc":"matches","lemma":"matche","pos":"NOM"} ,
		{"word":"matchiche","word_nosc":"matchiche","lemma":"matchiche","pos":"NOM"} ,
		{"word":"matchs","word_nosc":"matchs","lemma":"match","pos":"NOM"} ,
		{"word":"matelas","word_nosc":"matelas","lemma":"matelas","pos":"NOM"} ,
		{"word":"matelassier","word_nosc":"matelassier","lemma":"matelassier","pos":"NOM"} ,
		{"word":"matelassiers","word_nosc":"matelassiers","lemma":"matelassier","pos":"NOM"} ,
		{"word":"matelassière","word_nosc":"matelassiere","lemma":"matelassier","pos":"NOM"} ,
		{"word":"matelassières","word_nosc":"matelassieres","lemma":"matelassier","pos":"NOM"} ,
		{"word":"matelot","word_nosc":"matelot","lemma":"matelot","pos":"NOM"} ,
		{"word":"matelote","word_nosc":"matelote","lemma":"matelote","pos":"NOM"} ,
		{"word":"matelots","word_nosc":"matelots","lemma":"matelot","pos":"NOM"} ,
		{"word":"mater dolorosa","word_nosc":"mater dolorosa","lemma":"mater dolorosa","pos":"NOM"} ,
		{"word":"maternage","word_nosc":"maternage","lemma":"maternage","pos":"NOM"} ,
		{"word":"maternalisme","word_nosc":"maternalisme","lemma":"maternalisme","pos":"NOM"} ,
		{"word":"maternelle","word_nosc":"maternelle","lemma":"maternel","pos":"NOM"} ,
		{"word":"maternelles","word_nosc":"maternelles","lemma":"maternel","pos":"NOM"} ,
		{"word":"maternité","word_nosc":"maternite","lemma":"maternité","pos":"NOM"} ,
		{"word":"maternités","word_nosc":"maternites","lemma":"maternité","pos":"NOM"} ,
		{"word":"mateur","word_nosc":"mateur","lemma":"mateur","pos":"NOM"} ,
		{"word":"mateurs","word_nosc":"mateurs","lemma":"mateur","pos":"NOM"} ,
		{"word":"mateuse","word_nosc":"mateuse","lemma":"mateur","pos":"NOM"} ,
		{"word":"math","word_nosc":"math","lemma":"math","pos":"NOM"} ,
		{"word":"matheuse","word_nosc":"matheuse","lemma":"matheux","pos":"NOM"} ,
		{"word":"matheux","word_nosc":"matheux","lemma":"matheux","pos":"NOM"} ,
		{"word":"maths","word_nosc":"maths","lemma":"maths","pos":"NOM"} ,
		{"word":"mathurins","word_nosc":"mathurins","lemma":"mathurin","pos":"NOM"} ,
		{"word":"mathusalems","word_nosc":"mathusalems","lemma":"mathusalem","pos":"NOM"} ,
		{"word":"mathématicien","word_nosc":"mathematicien","lemma":"mathématicien","pos":"NOM"} ,
		{"word":"mathématicienne","word_nosc":"mathematicienne","lemma":"mathématicien","pos":"NOM"} ,
		{"word":"mathématiciens","word_nosc":"mathematiciens","lemma":"mathématicien","pos":"NOM"} ,
		{"word":"mathématique","word_nosc":"mathematique","lemma":"mathématique","pos":"NOM"} ,
		{"word":"mathématiques","word_nosc":"mathematiques","lemma":"mathématique","pos":"NOM"} ,
		{"word":"matin","word_nosc":"matin","lemma":"matin","pos":"NOM"} ,
		{"word":"matines","word_nosc":"matines","lemma":"mâtine","pos":"NOM"} ,
		{"word":"matins","word_nosc":"matins","lemma":"matin","pos":"NOM"} ,
		{"word":"matinée","word_nosc":"matinee","lemma":"matinée","pos":"NOM"} ,
		{"word":"matinées","word_nosc":"matinees","lemma":"matinée","pos":"NOM"} ,
		{"word":"matité","word_nosc":"matite","lemma":"matité","pos":"NOM"} ,
		{"word":"matière","word_nosc":"matiere","lemma":"matière","pos":"NOM"} ,
		{"word":"matières","word_nosc":"matieres","lemma":"matière","pos":"NOM"} ,
		{"word":"matois","word_nosc":"matois","lemma":"matois","pos":"NOM"} ,
		{"word":"matoiserie","word_nosc":"matoiserie","lemma":"matoiserie","pos":"NOM"} ,
		{"word":"maton","word_nosc":"maton","lemma":"maton","pos":"NOM"} ,
		{"word":"matonne","word_nosc":"matonne","lemma":"maton","pos":"NOM"} ,
		{"word":"matonnes","word_nosc":"matonnes","lemma":"maton","pos":"NOM"} ,
		{"word":"matons","word_nosc":"matons","lemma":"maton","pos":"NOM"} ,
		{"word":"matos","word_nosc":"matos","lemma":"matos","pos":"NOM"} ,
		{"word":"matou","word_nosc":"matou","lemma":"matou","pos":"NOM"} ,
		{"word":"matous","word_nosc":"matous","lemma":"matou","pos":"NOM"} ,
		{"word":"matraquage","word_nosc":"matraquage","lemma":"matraquage","pos":"NOM"} ,
		{"word":"matraquages","word_nosc":"matraquages","lemma":"matraquage","pos":"NOM"} ,
		{"word":"matraque","word_nosc":"matraque","lemma":"matraque","pos":"NOM"} ,
		{"word":"matraques","word_nosc":"matraques","lemma":"matraque","pos":"NOM"} ,
		{"word":"matraqueur","word_nosc":"matraqueur","lemma":"matraqueur","pos":"NOM"} ,
		{"word":"matraqueurs","word_nosc":"matraqueurs","lemma":"matraqueur","pos":"NOM"} ,
		{"word":"matras","word_nosc":"matras","lemma":"matras","pos":"NOM"} ,
		{"word":"matriarcat","word_nosc":"matriarcat","lemma":"matriarcat","pos":"NOM"} ,
		{"word":"matriarche","word_nosc":"matriarche","lemma":"matriarche","pos":"NOM"} ,
		{"word":"matrice","word_nosc":"matrice","lemma":"matrice","pos":"NOM"} ,
		{"word":"matrices","word_nosc":"matrices","lemma":"matrice","pos":"NOM"} ,
		{"word":"matricide","word_nosc":"matricide","lemma":"matricide","pos":"NOM"} ,
		{"word":"matriculaire","word_nosc":"matriculaire","lemma":"matriculaire","pos":"NOM"} ,
		{"word":"matricule","word_nosc":"matricule","lemma":"matricule","pos":"NOM"} ,
		{"word":"matricules","word_nosc":"matricules","lemma":"matricule","pos":"NOM"} ,
		{"word":"matriochka","word_nosc":"matriochka","lemma":"matriochka","pos":"NOM"} ,
		{"word":"matrone","word_nosc":"matrone","lemma":"matrone","pos":"NOM"} ,
		{"word":"matrones","word_nosc":"matrones","lemma":"matrone","pos":"NOM"} ,
		{"word":"mats","word_nosc":"mats","lemma":"mat","pos":"NOM"} ,
		{"word":"matte","word_nosc":"matte","lemma":"matte","pos":"NOM"} ,
		{"word":"mattes","word_nosc":"mattes","lemma":"matte","pos":"NOM"} ,
		{"word":"matuche","word_nosc":"matuche","lemma":"matuche","pos":"NOM"} ,
		{"word":"maturation","word_nosc":"maturation","lemma":"maturation","pos":"NOM"} ,
		{"word":"maturations","word_nosc":"maturations","lemma":"maturation","pos":"NOM"} ,
		{"word":"maturité","word_nosc":"maturite","lemma":"maturité","pos":"NOM"} ,
		{"word":"maté","word_nosc":"mate","lemma":"maté","pos":"NOM"} ,
		{"word":"matérialisation","word_nosc":"materialisation","lemma":"matérialisation","pos":"NOM"} ,
		{"word":"matérialisations","word_nosc":"materialisations","lemma":"matérialisation","pos":"NOM"} ,
		{"word":"matérialisme","word_nosc":"materialisme","lemma":"matérialisme","pos":"NOM"} ,
		{"word":"matérialiste","word_nosc":"materialiste","lemma":"matérialiste","pos":"NOM"} ,
		{"word":"matérialistes","word_nosc":"materialistes","lemma":"matérialiste","pos":"NOM"} ,
		{"word":"matérialité","word_nosc":"materialite","lemma":"matérialité","pos":"NOM"} ,
		{"word":"matériau","word_nosc":"materiau","lemma":"matériau","pos":"NOM"} ,
		{"word":"matériaux","word_nosc":"materiaux","lemma":"matériau","pos":"NOM"} ,
		{"word":"matériel","word_nosc":"materiel","lemma":"matériel","pos":"NOM"} ,
		{"word":"matériels","word_nosc":"materiels","lemma":"matériel","pos":"NOM"} ,
		{"word":"matés","word_nosc":"mates","lemma":"maté","pos":"NOM"} ,
		{"word":"maudit","word_nosc":"maudit","lemma":"maudit","pos":"NOM"} ,
		{"word":"maudits","word_nosc":"maudits","lemma":"maudit","pos":"NOM"} ,
		{"word":"maure","word_nosc":"maure","lemma":"maure","pos":"NOM"} ,
		{"word":"maures","word_nosc":"maures","lemma":"maure","pos":"NOM"} ,
		{"word":"mauresque","word_nosc":"mauresque","lemma":"mauresque","pos":"NOM"} ,
		{"word":"mauresques","word_nosc":"mauresques","lemma":"mauresque","pos":"NOM"} ,
		{"word":"maurrassien","word_nosc":"maurrassien","lemma":"maurrassien","pos":"NOM"} ,
		{"word":"maurrassisme","word_nosc":"maurrassisme","lemma":"maurrassisme","pos":"NOM"} ,
		{"word":"mauser","word_nosc":"mauser","lemma":"mauser","pos":"NOM"} ,
		{"word":"mausers","word_nosc":"mausers","lemma":"mauser","pos":"NOM"} ,
		{"word":"mausolée","word_nosc":"mausolee","lemma":"mausolée","pos":"NOM"} ,
		{"word":"mausolées","word_nosc":"mausolees","lemma":"mausolée","pos":"NOM"} ,
		{"word":"maussaderie","word_nosc":"maussaderie","lemma":"maussaderie","pos":"NOM"} ,
		{"word":"maussaderies","word_nosc":"maussaderies","lemma":"maussaderie","pos":"NOM"} ,
		{"word":"mauvais","word_nosc":"mauvais","lemma":"mauvais","pos":"NOM"} ,
		{"word":"mauvaiseté","word_nosc":"mauvaisete","lemma":"mauvaiseté","pos":"NOM"} ,
		{"word":"mauve","word_nosc":"mauve","lemma":"mauve","pos":"NOM"} ,
		{"word":"mauves","word_nosc":"mauves","lemma":"mauve","pos":"NOM"} ,
		{"word":"mauviette","word_nosc":"mauviette","lemma":"mauviette","pos":"NOM"} ,
		{"word":"mauviettes","word_nosc":"mauviettes","lemma":"mauviette","pos":"NOM"} ,
		{"word":"maux","word_nosc":"maux","lemma":"mal","pos":"NOM"} ,
		{"word":"max","word_nosc":"max","lemma":"max","pos":"NOM"} ,
		{"word":"maxi","word_nosc":"maxi","lemma":"maxi","pos":"NOM"} ,
		{"word":"maxillaire","word_nosc":"maxillaire","lemma":"maxillaire","pos":"NOM"} ,
		{"word":"maxillaires","word_nosc":"maxillaires","lemma":"maxillaire","pos":"NOM"} ,
		{"word":"maxima","word_nosc":"maxima","lemma":"maximum","pos":"NOM"} ,
		{"word":"maximalisme","word_nosc":"maximalisme","lemma":"maximalisme","pos":"NOM"} ,
		{"word":"maxime","word_nosc":"maxime","lemma":"maxime","pos":"NOM"} ,
		{"word":"maximes","word_nosc":"maximes","lemma":"maxime","pos":"NOM"} ,
		{"word":"maximisation","word_nosc":"maximisation","lemma":"maximisation","pos":"NOM"} ,
		{"word":"maximum","word_nosc":"maximum","lemma":"maximum","pos":"NOM"} ,
		{"word":"maximums","word_nosc":"maximums","lemma":"maximum","pos":"NOM"} ,
		{"word":"maxis","word_nosc":"maxis","lemma":"maxi","pos":"NOM"} ,
		{"word":"maxiton","word_nosc":"maxiton","lemma":"maxiton","pos":"NOM"} ,
		{"word":"maya","word_nosc":"maya","lemma":"maya","pos":"NOM"} ,
		{"word":"mayas","word_nosc":"mayas","lemma":"maya","pos":"NOM"} ,
		{"word":"mayo","word_nosc":"mayo","lemma":"mayo","pos":"NOM"} ,
		{"word":"mayonnaise","word_nosc":"mayonnaise","lemma":"mayonnaise","pos":"NOM"} ,
		{"word":"mayonnaises","word_nosc":"mayonnaises","lemma":"mayonnaise","pos":"NOM"} ,
		{"word":"mazagran","word_nosc":"mazagran","lemma":"mazagran","pos":"NOM"} ,
		{"word":"mazagrans","word_nosc":"mazagrans","lemma":"mazagran","pos":"NOM"} ,
		{"word":"mazet","word_nosc":"mazet","lemma":"mazet","pos":"NOM"} ,
		{"word":"mazette","word_nosc":"mazette","lemma":"mazette","pos":"NOM"} ,
		{"word":"mazettes","word_nosc":"mazettes","lemma":"mazette","pos":"NOM"} ,
		{"word":"mazout","word_nosc":"mazout","lemma":"mazout","pos":"NOM"} ,
		{"word":"mazurka","word_nosc":"mazurka","lemma":"mazurka","pos":"NOM"} ,
		{"word":"mazurkas","word_nosc":"mazurkas","lemma":"mazurka","pos":"NOM"} ,
		{"word":"maçon","word_nosc":"macon","lemma":"maçon","pos":"NOM"} ,
		{"word":"maçonne","word_nosc":"maconne","lemma":"maçon","pos":"NOM"} ,
		{"word":"maçonnerie","word_nosc":"maconnerie","lemma":"maçonnerie","pos":"NOM"} ,
		{"word":"maçonneries","word_nosc":"maconneries","lemma":"maçonnerie","pos":"NOM"} ,
		{"word":"maçons","word_nosc":"macons","lemma":"maçon","pos":"NOM"} ,
		{"word":"maître","word_nosc":"maitre","lemma":"maître","pos":"NOM"} ,
		{"word":"maître queux","word_nosc":"maitre queux","lemma":"maître queux","pos":"NOM"} ,
		{"word":"maître-assistant","word_nosc":"maitre-assistant","lemma":"maître-assistant","pos":"NOM"} ,
		{"word":"maître-autel","word_nosc":"maitre-autel","lemma":"maître-autel","pos":"NOM"} ,
		{"word":"maître-chanteur","word_nosc":"maitre-chanteur","lemma":"maître-chanteur","pos":"NOM"} ,
		{"word":"maître-chien","word_nosc":"maitre-chien","lemma":"maître-chien","pos":"NOM"} ,
		{"word":"maître-coq","word_nosc":"maitre-coq","lemma":"maître-coq","pos":"NOM"} ,
		{"word":"maître-nageur","word_nosc":"maitre-nageur","lemma":"maître-nageur","pos":"NOM"} ,
		{"word":"maître-à-danser","word_nosc":"maitre-a-danser","lemma":"maître-à-danser","pos":"NOM"} ,
		{"word":"maîtres","word_nosc":"maitres","lemma":"maître","pos":"NOM"} ,
		{"word":"maîtresse","word_nosc":"maitresse","lemma":"maîtresse","pos":"NOM"} ,
		{"word":"maîtresses","word_nosc":"maitresses","lemma":"maîtresse","pos":"NOM"} ,
		{"word":"maîtrise","word_nosc":"maitrise","lemma":"maîtrise","pos":"NOM"} ,
		{"word":"maîtrises","word_nosc":"maitrises","lemma":"maîtrise","pos":"NOM"} ,
		{"word":"maïa","word_nosc":"maia","lemma":"maïa","pos":"NOM"} ,
		{"word":"maïs","word_nosc":"mais","lemma":"maïs","pos":"NOM"} ,
		{"word":"maïzena","word_nosc":"maizena","lemma":"maïzena","pos":"NOM"} ,
		{"word":"me","word_nosc":"me","lemma":"me","pos":"NOM"} ,
		{"word":"mea culpa","word_nosc":"mea culpa","lemma":"mea culpa","pos":"NOM"} ,
		{"word":"mea-culpa","word_nosc":"mea-culpa","lemma":"mea-culpa","pos":"NOM"} ,
		{"word":"mec","word_nosc":"mec","lemma":"mec","pos":"NOM"} ,
		{"word":"meccano","word_nosc":"meccano","lemma":"meccano","pos":"NOM"} ,
		{"word":"meccanos","word_nosc":"meccanos","lemma":"meccano","pos":"NOM"} ,
		{"word":"mechta","word_nosc":"mechta","lemma":"mechta","pos":"NOM"} ,
		{"word":"mechtas","word_nosc":"mechtas","lemma":"mechta","pos":"NOM"} ,
		{"word":"mecklembourgeois","word_nosc":"mecklembourgeois","lemma":"mecklembourgeois","pos":"NOM"} ,
		{"word":"mecs","word_nosc":"mecs","lemma":"mec","pos":"NOM"} ,
		{"word":"mecton","word_nosc":"mecton","lemma":"mecton","pos":"NOM"} ,
		{"word":"mectons","word_nosc":"mectons","lemma":"mecton","pos":"NOM"} ,
		{"word":"media","word_nosc":"media","lemma":"media","pos":"NOM"} ,
		{"word":"medicine-ball","word_nosc":"medicine-ball","lemma":"medicine-ball","pos":"NOM"} ,
		{"word":"medium","word_nosc":"medium","lemma":"medium","pos":"NOM"} ,
		{"word":"meeting","word_nosc":"meeting","lemma":"meeting","pos":"NOM"} ,
		{"word":"meetings","word_nosc":"meetings","lemma":"meeting","pos":"NOM"} ,
		{"word":"meilleur","word_nosc":"meilleur","lemma":"meilleur","pos":"NOM"} ,
		{"word":"meilleure","word_nosc":"meilleure","lemma":"meilleur","pos":"NOM"} ,
		{"word":"meilleures","word_nosc":"meilleures","lemma":"meilleur","pos":"NOM"} ,
		{"word":"meilleurs","word_nosc":"meilleurs","lemma":"meilleur","pos":"NOM"} ,
		{"word":"melkites","word_nosc":"melkites","lemma":"melkite","pos":"NOM"} ,
		{"word":"mellifères","word_nosc":"melliferes","lemma":"mellifère","pos":"NOM"} ,
		{"word":"melon","word_nosc":"melon","lemma":"melon","pos":"NOM"} ,
		{"word":"melons","word_nosc":"melons","lemma":"melon","pos":"NOM"} ,
		{"word":"melting pot","word_nosc":"melting pot","lemma":"melting-pot","pos":"NOM"} ,
		{"word":"melting-pot","word_nosc":"melting-pot","lemma":"melting-pot","pos":"NOM"} ,
		{"word":"membrane","word_nosc":"membrane","lemma":"membrane","pos":"NOM"} ,
		{"word":"membranes","word_nosc":"membranes","lemma":"membrane","pos":"NOM"} ,
		{"word":"membre","word_nosc":"membre","lemma":"membre","pos":"NOM"} ,
		{"word":"membres","word_nosc":"membres","lemma":"membre","pos":"NOM"} ,
		{"word":"membrure","word_nosc":"membrure","lemma":"membrure","pos":"NOM"} ,
		{"word":"membrures","word_nosc":"membrures","lemma":"membrure","pos":"NOM"} ,
		{"word":"menace","word_nosc":"menace","lemma":"menace","pos":"NOM"} ,
		{"word":"menaces","word_nosc":"menaces","lemma":"menace","pos":"NOM"} ,
		{"word":"mencheviks","word_nosc":"mencheviks","lemma":"menchevik","pos":"NOM"} ,
		{"word":"mendiant","word_nosc":"mendiant","lemma":"mendiant","pos":"NOM"} ,
		{"word":"mendiante","word_nosc":"mendiante","lemma":"mendiant","pos":"NOM"} ,
		{"word":"mendiantes","word_nosc":"mendiantes","lemma":"mendiant","pos":"NOM"} ,
		{"word":"mendiants","word_nosc":"mendiants","lemma":"mendiant","pos":"NOM"} ,
		{"word":"mendicité","word_nosc":"mendicite","lemma":"mendicité","pos":"NOM"} ,
		{"word":"mendicités","word_nosc":"mendicites","lemma":"mendicité","pos":"NOM"} ,
		{"word":"mendigot","word_nosc":"mendigot","lemma":"mendigot","pos":"NOM"} ,
		{"word":"mendigote","word_nosc":"mendigote","lemma":"mendigot","pos":"NOM"} ,
		{"word":"mendigots","word_nosc":"mendigots","lemma":"mendigot","pos":"NOM"} ,
		{"word":"meneau","word_nosc":"meneau","lemma":"meneau","pos":"NOM"} ,
		{"word":"meneaux","word_nosc":"meneaux","lemma":"meneau","pos":"NOM"} ,
		{"word":"meneur","word_nosc":"meneur","lemma":"meneur","pos":"NOM"} ,
		{"word":"meneurs","word_nosc":"meneurs","lemma":"meneur","pos":"NOM"} ,
		{"word":"meneuse","word_nosc":"meneuse","lemma":"meneur","pos":"NOM"} ,
		{"word":"menez","word_nosc":"menez","lemma":"menez","pos":"NOM"} ,
		{"word":"menhaden","word_nosc":"menhaden","lemma":"menhaden","pos":"NOM"} ,
		{"word":"menhir","word_nosc":"menhir","lemma":"menhir","pos":"NOM"} ,
		{"word":"menhirs","word_nosc":"menhirs","lemma":"menhir","pos":"NOM"} ,
		{"word":"mennonite","word_nosc":"mennonite","lemma":"mennonite","pos":"NOM"} ,
		{"word":"mennonites","word_nosc":"mennonites","lemma":"mennonite","pos":"NOM"} ,
		{"word":"menon","word_nosc":"menon","lemma":"menon","pos":"NOM"} ,
		{"word":"menons","word_nosc":"menons","lemma":"menon","pos":"NOM"} ,
		{"word":"menora","word_nosc":"menora","lemma":"menora","pos":"NOM"} ,
		{"word":"menotte","word_nosc":"menotte","lemma":"menotte","pos":"NOM"} ,
		{"word":"menottes","word_nosc":"menottes","lemma":"menotte","pos":"NOM"} ,
		{"word":"mens","word_nosc":"mens","lemma":"mens","pos":"NOM"} ,
		{"word":"mensonge","word_nosc":"mensonge","lemma":"mensonge","pos":"NOM"} ,
		{"word":"mensonges","word_nosc":"mensonges","lemma":"mensonge","pos":"NOM"} ,
		{"word":"menstruation","word_nosc":"menstruation","lemma":"menstruation","pos":"NOM"} ,
		{"word":"menstruations","word_nosc":"menstruations","lemma":"menstruation","pos":"NOM"} ,
		{"word":"menstrues","word_nosc":"menstrues","lemma":"menstrues","pos":"NOM"} ,
		{"word":"mensualité","word_nosc":"mensualite","lemma":"mensualité","pos":"NOM"} ,
		{"word":"mensualités","word_nosc":"mensualites","lemma":"mensualité","pos":"NOM"} ,
		{"word":"mensuel","word_nosc":"mensuel","lemma":"mensuel","pos":"NOM"} ,
		{"word":"mensuels","word_nosc":"mensuels","lemma":"mensuel","pos":"NOM"} ,
		{"word":"mensuration","word_nosc":"mensuration","lemma":"mensuration","pos":"NOM"} ,
		{"word":"mensurations","word_nosc":"mensurations","lemma":"mensuration","pos":"NOM"} ,
		{"word":"mental","word_nosc":"mental","lemma":"mental","pos":"NOM"} ,
		{"word":"mentalité","word_nosc":"mentalite","lemma":"mentalité","pos":"NOM"} ,
		{"word":"mentalités","word_nosc":"mentalites","lemma":"mentalité","pos":"NOM"} ,
		{"word":"menterie","word_nosc":"menterie","lemma":"menterie","pos":"NOM"} ,
		{"word":"menteries","word_nosc":"menteries","lemma":"menterie","pos":"NOM"} ,
		{"word":"menteur","word_nosc":"menteur","lemma":"menteur","pos":"NOM"} ,
		{"word":"menteurs","word_nosc":"menteurs","lemma":"menteur","pos":"NOM"} ,
		{"word":"menteuse","word_nosc":"menteuse","lemma":"menteur","pos":"NOM"} ,
		{"word":"menteuses","word_nosc":"menteuses","lemma":"menteur","pos":"NOM"} ,
		{"word":"menthe","word_nosc":"menthe","lemma":"menthe","pos":"NOM"} ,
		{"word":"menthes","word_nosc":"menthes","lemma":"menthe","pos":"NOM"} ,
		{"word":"menthol","word_nosc":"menthol","lemma":"menthol","pos":"NOM"} ,
		{"word":"menthols","word_nosc":"menthols","lemma":"menthol","pos":"NOM"} ,
		{"word":"mentholé","word_nosc":"menthole","lemma":"mentholé","pos":"NOM"} ,
		{"word":"mentholés","word_nosc":"mentholes","lemma":"mentholé","pos":"NOM"} ,
		{"word":"mention","word_nosc":"mention","lemma":"mention","pos":"NOM"} ,
		{"word":"mentions","word_nosc":"mentions","lemma":"mention","pos":"NOM"} ,
		{"word":"menton","word_nosc":"menton","lemma":"menton","pos":"NOM"} ,
		{"word":"mentonnet","word_nosc":"mentonnet","lemma":"mentonnet","pos":"NOM"} ,
		{"word":"mentonnière","word_nosc":"mentonniere","lemma":"mentonnier","pos":"NOM"} ,
		{"word":"mentons","word_nosc":"mentons","lemma":"menton","pos":"NOM"} ,
		{"word":"mentor","word_nosc":"mentor","lemma":"mentor","pos":"NOM"} ,
		{"word":"mentors","word_nosc":"mentors","lemma":"mentor","pos":"NOM"} ,
		{"word":"mentule","word_nosc":"mentule","lemma":"mentule","pos":"NOM"} ,
		{"word":"menu","word_nosc":"menu","lemma":"menu","pos":"NOM"} ,
		{"word":"menuet","word_nosc":"menuet","lemma":"menuet","pos":"NOM"} ,
		{"word":"menuets","word_nosc":"menuets","lemma":"menuet","pos":"NOM"} ,
		{"word":"menuise","word_nosc":"menuise","lemma":"menuise","pos":"NOM"} ,
		{"word":"menuiserie","word_nosc":"menuiserie","lemma":"menuiserie","pos":"NOM"} ,
		{"word":"menuisier","word_nosc":"menuisier","lemma":"menuisier","pos":"NOM"} ,
		{"word":"menuisiers","word_nosc":"menuisiers","lemma":"menuisier","pos":"NOM"} ,
		{"word":"menus","word_nosc":"menus","lemma":"menu","pos":"NOM"} ,
		{"word":"menée","word_nosc":"menee","lemma":"menée","pos":"NOM"} ,
		{"word":"menées","word_nosc":"menees","lemma":"menée","pos":"NOM"} ,
		{"word":"mer","word_nosc":"mer","lemma":"mer","pos":"NOM"} ,
		{"word":"mercanti","word_nosc":"mercanti","lemma":"mercanti","pos":"NOM"} ,
		{"word":"mercantilisme","word_nosc":"mercantilisme","lemma":"mercantilisme","pos":"NOM"} ,
		{"word":"mercantis","word_nosc":"mercantis","lemma":"mercanti","pos":"NOM"} ,
		{"word":"mercaptan","word_nosc":"mercaptan","lemma":"mercaptan","pos":"NOM"} ,
		{"word":"mercenaire","word_nosc":"mercenaire","lemma":"mercenaire","pos":"NOM"} ,
		{"word":"mercenaires","word_nosc":"mercenaires","lemma":"mercenaire","pos":"NOM"} ,
		{"word":"mercerie","word_nosc":"mercerie","lemma":"mercerie","pos":"NOM"} ,
		{"word":"merceries","word_nosc":"merceries","lemma":"mercerie","pos":"NOM"} ,
		{"word":"merchandising","word_nosc":"merchandising","lemma":"merchandising","pos":"NOM"} ,
		{"word":"merci","word_nosc":"merci","lemma":"merci","pos":"NOM"} ,
		{"word":"mercier","word_nosc":"mercier","lemma":"mercier","pos":"NOM"} ,
		{"word":"merciers","word_nosc":"merciers","lemma":"mercier","pos":"NOM"} ,
		{"word":"mercis","word_nosc":"mercis","lemma":"merci","pos":"NOM"} ,
		{"word":"mercière","word_nosc":"merciere","lemma":"mercier","pos":"NOM"} ,
		{"word":"mercières","word_nosc":"mercieres","lemma":"mercier","pos":"NOM"} ,
		{"word":"mercredi","word_nosc":"mercredi","lemma":"mercredi","pos":"NOM"} ,
		{"word":"mercredis","word_nosc":"mercredis","lemma":"mercredi","pos":"NOM"} ,
		{"word":"mercure","word_nosc":"mercure","lemma":"mercure","pos":"NOM"} ,
		{"word":"mercurey","word_nosc":"mercurey","lemma":"mercurey","pos":"NOM"} ,
		{"word":"mercuriale","word_nosc":"mercuriale","lemma":"mercuriale","pos":"NOM"} ,
		{"word":"mercuriales","word_nosc":"mercuriales","lemma":"mercuriale","pos":"NOM"} ,
		{"word":"mercurochrome","word_nosc":"mercurochrome","lemma":"mercurochrome","pos":"NOM"} ,
		{"word":"merde","word_nosc":"merde","lemma":"merde","pos":"NOM"} ,
		{"word":"merdes","word_nosc":"merdes","lemma":"merde","pos":"NOM"} ,
		{"word":"merdeux","word_nosc":"merdeux","lemma":"merdeux","pos":"NOM"} ,
		{"word":"merdier","word_nosc":"merdier","lemma":"merdier","pos":"NOM"} ,
		{"word":"merdiers","word_nosc":"merdiers","lemma":"merdier","pos":"NOM"} ,
		{"word":"merdouille","word_nosc":"merdouille","lemma":"merdouille","pos":"NOM"} ,
		{"word":"merguez","word_nosc":"merguez","lemma":"merguez","pos":"NOM"} ,
		{"word":"meringue","word_nosc":"meringue","lemma":"meringue","pos":"NOM"} ,
		{"word":"meringues","word_nosc":"meringues","lemma":"meringue","pos":"NOM"} ,
		{"word":"merise","word_nosc":"merise","lemma":"merise","pos":"NOM"} ,
		{"word":"merises","word_nosc":"merises","lemma":"merise","pos":"NOM"} ,
		{"word":"merisier","word_nosc":"merisier","lemma":"merisier","pos":"NOM"} ,
		{"word":"merisiers","word_nosc":"merisiers","lemma":"merisier","pos":"NOM"} ,
		{"word":"merl","word_nosc":"merl","lemma":"merl","pos":"NOM"} ,
		{"word":"merlan","word_nosc":"merlan","lemma":"merlan","pos":"NOM"} ,
		{"word":"merlans","word_nosc":"merlans","lemma":"merlan","pos":"NOM"} ,
		{"word":"merle","word_nosc":"merle","lemma":"merle","pos":"NOM"} ,
		{"word":"merleau","word_nosc":"merleau","lemma":"merleau","pos":"NOM"} ,
		{"word":"merles","word_nosc":"merles","lemma":"merle","pos":"NOM"} ,
		{"word":"merlette","word_nosc":"merlette","lemma":"merlette","pos":"NOM"} ,
		{"word":"merlin","word_nosc":"merlin","lemma":"merlin","pos":"NOM"} ,
		{"word":"merlot","word_nosc":"merlot","lemma":"merlot","pos":"NOM"} ,
		{"word":"merlu","word_nosc":"merlu","lemma":"merlu","pos":"NOM"} ,
		{"word":"merluche","word_nosc":"merluche","lemma":"merluche","pos":"NOM"} ,
		{"word":"merluches","word_nosc":"merluches","lemma":"merluche","pos":"NOM"} ,
		{"word":"merrain","word_nosc":"merrain","lemma":"merrain","pos":"NOM"} ,
		{"word":"merrains","word_nosc":"merrains","lemma":"merrain","pos":"NOM"} ,
		{"word":"mers","word_nosc":"mers","lemma":"mer","pos":"NOM"} ,
		{"word":"merveille","word_nosc":"merveille","lemma":"merveille","pos":"NOM"} ,
		{"word":"merveilles","word_nosc":"merveilles","lemma":"merveille","pos":"NOM"} ,
		{"word":"merveilleuse","word_nosc":"merveilleuse","lemma":"merveilleux","pos":"NOM"} ,
		{"word":"merveilleuses","word_nosc":"merveilleuses","lemma":"merveilleux","pos":"NOM"} ,
		{"word":"merveilleux","word_nosc":"merveilleux","lemma":"merveilleux","pos":"NOM"} ,
		{"word":"merveillosité","word_nosc":"merveillosite","lemma":"merveillosité","pos":"NOM"} ,
		{"word":"mes","word_nosc":"mes","lemma":"me","pos":"NOM"} ,
		{"word":"mesa","word_nosc":"mesa","lemma":"mesa","pos":"NOM"} ,
		{"word":"mescal","word_nosc":"mescal","lemma":"mescal","pos":"NOM"} ,
		{"word":"mescaline","word_nosc":"mescaline","lemma":"mescaline","pos":"NOM"} ,
		{"word":"mesclun","word_nosc":"mesclun","lemma":"mesclun","pos":"NOM"} ,
		{"word":"mesdames","word_nosc":"mesdames","lemma":"madame","pos":"NOM"} ,
		{"word":"mesdemoiselles","word_nosc":"mesdemoiselles","lemma":"mesdemoiselles","pos":"NOM"} ,
		{"word":"mesmérisme","word_nosc":"mesmerisme","lemma":"mesmérisme","pos":"NOM"} ,
		{"word":"mesnils","word_nosc":"mesnils","lemma":"mesnil","pos":"NOM"} ,
		{"word":"mesquinerie","word_nosc":"mesquinerie","lemma":"mesquinerie","pos":"NOM"} ,
		{"word":"mesquineries","word_nosc":"mesquineries","lemma":"mesquinerie","pos":"NOM"} ,
		{"word":"mess","word_nosc":"mess","lemma":"mess","pos":"NOM"} ,
		{"word":"message","word_nosc":"message","lemma":"message","pos":"NOM"} ,
		{"word":"messager","word_nosc":"messager","lemma":"messager","pos":"NOM"} ,
		{"word":"messagerie","word_nosc":"messagerie","lemma":"messagerie","pos":"NOM"} ,
		{"word":"messageries","word_nosc":"messageries","lemma":"messagerie","pos":"NOM"} ,
		{"word":"messagers","word_nosc":"messagers","lemma":"messager","pos":"NOM"} ,
		{"word":"messages","word_nosc":"messages","lemma":"message","pos":"NOM"} ,
		{"word":"messagère","word_nosc":"messagere","lemma":"messager","pos":"NOM"} ,
		{"word":"messagères","word_nosc":"messageres","lemma":"messager","pos":"NOM"} ,
		{"word":"messaliste","word_nosc":"messaliste","lemma":"messaliste","pos":"NOM"} ,
		{"word":"messalistes","word_nosc":"messalistes","lemma":"messaliste","pos":"NOM"} ,
		{"word":"messe","word_nosc":"messe","lemma":"messe","pos":"NOM"} ,
		{"word":"messeigneurs","word_nosc":"messeigneurs","lemma":"messeigneurs","pos":"NOM"} ,
		{"word":"messer","word_nosc":"messer","lemma":"messer","pos":"NOM"} ,
		{"word":"messes","word_nosc":"messes","lemma":"messe","pos":"NOM"} ,
		{"word":"messianisme","word_nosc":"messianisme","lemma":"messianisme","pos":"NOM"} ,
		{"word":"messidor","word_nosc":"messidor","lemma":"messidor","pos":"NOM"} ,
		{"word":"messie","word_nosc":"messie","lemma":"messie","pos":"NOM"} ,
		{"word":"messies","word_nosc":"messies","lemma":"messie","pos":"NOM"} ,
		{"word":"messieurs","word_nosc":"messieurs","lemma":"monsieur","pos":"NOM"} ,
		{"word":"messieurs-dames","word_nosc":"messieurs-dames","lemma":"messieurs-dames","pos":"NOM"} ,
		{"word":"messire","word_nosc":"messire","lemma":"messire","pos":"NOM"} ,
		{"word":"messires","word_nosc":"messires","lemma":"messire","pos":"NOM"} ,
		{"word":"messéance","word_nosc":"messeance","lemma":"messéance","pos":"NOM"} ,
		{"word":"mestre","word_nosc":"mestre","lemma":"mestre","pos":"NOM"} ,
		{"word":"mesure","word_nosc":"mesure","lemma":"mesure","pos":"NOM"} ,
		{"word":"mesures","word_nosc":"mesures","lemma":"mesure","pos":"NOM"} ,
		{"word":"mesurette","word_nosc":"mesurette","lemma":"mesurette","pos":"NOM"} ,
		{"word":"mesureur","word_nosc":"mesureur","lemma":"mesureur","pos":"NOM"} ,
		{"word":"mesurons","word_nosc":"mesurons","lemma":"mesuron","pos":"NOM"} ,
		{"word":"mets","word_nosc":"mets","lemma":"mets","pos":"NOM"} ,
		{"word":"mets-la-toi","word_nosc":"mets-la-toi","lemma":"mets-la-toi","pos":"NOM"} ,
		{"word":"metteur","word_nosc":"metteur","lemma":"metteur","pos":"NOM"} ,
		{"word":"metteurs","word_nosc":"metteurs","lemma":"metteur","pos":"NOM"} ,
		{"word":"metteuse","word_nosc":"metteuse","lemma":"metteur","pos":"NOM"} ,
		{"word":"meuble","word_nosc":"meuble","lemma":"meuble","pos":"NOM"} ,
		{"word":"meubles","word_nosc":"meubles","lemma":"meuble","pos":"NOM"} ,
		{"word":"meublé","word_nosc":"meuble","lemma":"meublé","pos":"NOM"} ,
		{"word":"meublés","word_nosc":"meubles","lemma":"meublé","pos":"NOM"} ,
		{"word":"meuf","word_nosc":"meuf","lemma":"meuf","pos":"NOM"} ,
		{"word":"meufs","word_nosc":"meufs","lemma":"meuf","pos":"NOM"} ,
		{"word":"meuglement","word_nosc":"meuglement","lemma":"meuglement","pos":"NOM"} ,
		{"word":"meuglements","word_nosc":"meuglements","lemma":"meuglement","pos":"NOM"} ,
		{"word":"meulage","word_nosc":"meulage","lemma":"meulage","pos":"NOM"} ,
		{"word":"meule","word_nosc":"meule","lemma":"meule","pos":"NOM"} ,
		{"word":"meules","word_nosc":"meules","lemma":"meule","pos":"NOM"} ,
		{"word":"meuleuse","word_nosc":"meuleuse","lemma":"meuleuse","pos":"NOM"} ,
		{"word":"meulière","word_nosc":"meuliere","lemma":"meulier","pos":"NOM"} ,
		{"word":"meulières","word_nosc":"meulieres","lemma":"meulier","pos":"NOM"} ,
		{"word":"meunier","word_nosc":"meunier","lemma":"meunier","pos":"NOM"} ,
		{"word":"meuniers","word_nosc":"meuniers","lemma":"meunier","pos":"NOM"} ,
		{"word":"meunière","word_nosc":"meuniere","lemma":"meunier","pos":"NOM"} ,
		{"word":"meurette","word_nosc":"meurette","lemma":"meurette","pos":"NOM"} ,
		{"word":"meursault","word_nosc":"meursault","lemma":"meursault","pos":"NOM"} ,
		{"word":"meurtre","word_nosc":"meurtre","lemma":"meurtre","pos":"NOM"} ,
		{"word":"meurtre-suicide","word_nosc":"meurtre-suicide","lemma":"meurtre-suicide","pos":"NOM"} ,
		{"word":"meurtres","word_nosc":"meurtres","lemma":"meurtre","pos":"NOM"} ,
		{"word":"meurtrier","word_nosc":"meurtrier","lemma":"meurtrier","pos":"NOM"} ,
		{"word":"meurtriers","word_nosc":"meurtriers","lemma":"meurtrier","pos":"NOM"} ,
		{"word":"meurtrissure","word_nosc":"meurtrissure","lemma":"meurtrissure","pos":"NOM"} ,
		{"word":"meurtrissures","word_nosc":"meurtrissures","lemma":"meurtrissure","pos":"NOM"} ,
		{"word":"meurtrière","word_nosc":"meurtriere","lemma":"meurtrier","pos":"NOM"} ,
		{"word":"meurtrières","word_nosc":"meurtrieres","lemma":"meurtrier","pos":"NOM"} ,
		{"word":"meusienne","word_nosc":"meusienne","lemma":"meusienne","pos":"NOM"} ,
		{"word":"meusiennes","word_nosc":"meusiennes","lemma":"meusienne","pos":"NOM"} ,
		{"word":"meute","word_nosc":"meute","lemma":"meute","pos":"NOM"} ,
		{"word":"meutes","word_nosc":"meutes","lemma":"meute","pos":"NOM"} ,
		{"word":"mexicain","word_nosc":"mexicain","lemma":"mexicain","pos":"NOM"} ,
		{"word":"mexicaine","word_nosc":"mexicaine","lemma":"mexicain","pos":"NOM"} ,
		{"word":"mexicaines","word_nosc":"mexicaines","lemma":"mexicain","pos":"NOM"} ,
		{"word":"mexicains","word_nosc":"mexicains","lemma":"mexicain","pos":"NOM"} ,
		{"word":"mezcal","word_nosc":"mezcal","lemma":"mezcal","pos":"NOM"} ,
		{"word":"mezzanine","word_nosc":"mezzanine","lemma":"mezzanine","pos":"NOM"} ,
		{"word":"mezzanines","word_nosc":"mezzanines","lemma":"mezzanine","pos":"NOM"} ,
		{"word":"mezzo","word_nosc":"mezzo","lemma":"mezzo","pos":"NOM"} ,
		{"word":"mezzo-soprano","word_nosc":"mezzo-soprano","lemma":"mezzo-soprano","pos":"NOM"} ,
		{"word":"mezzos","word_nosc":"mezzos","lemma":"mezzo","pos":"NOM"} ,
		{"word":"mi","word_nosc":"mi","lemma":"mi","pos":"NOM"} ,
		{"word":"mi-africains","word_nosc":"mi-africains","lemma":"mi-africains","pos":"NOM"} ,
		{"word":"mi-airain","word_nosc":"mi-airain","lemma":"mi-airain","pos":"NOM"} ,
		{"word":"mi-allemand","word_nosc":"mi-allemand","lemma":"mi-allemand","pos":"NOM"} ,
		{"word":"mi-américaine","word_nosc":"mi-americaine","lemma":"mi-américaine","pos":"NOM"} ,
		{"word":"mi-ange","word_nosc":"mi-ange","lemma":"mi-ange","pos":"NOM"} ,
		{"word":"mi-angevins","word_nosc":"mi-angevins","lemma":"mi-angevins","pos":"NOM"} ,
		{"word":"mi-août","word_nosc":"mi-aout","lemma":"mi-août","pos":"NOM"} ,
		{"word":"mi-appréhension","word_nosc":"mi-apprehension","lemma":"mi-appréhension","pos":"NOM"} ,
		{"word":"mi-artisan","word_nosc":"mi-artisan","lemma":"mi-artisan","pos":"NOM"} ,
		{"word":"mi-automne","word_nosc":"mi-automne","lemma":"mi-automne","pos":"NOM"} ,
		{"word":"mi-bandit","word_nosc":"mi-bandit","lemma":"mi-bandit","pos":"NOM"} ,
		{"word":"mi-bas","word_nosc":"mi-bas","lemma":"mi-bas","pos":"NOM"} ,
		{"word":"mi-blanc","word_nosc":"mi-blanc","lemma":"mi-blanc","pos":"NOM"} ,
		{"word":"mi-blancs","word_nosc":"mi-blancs","lemma":"mi-blancs","pos":"NOM"} ,
		{"word":"mi-bois","word_nosc":"mi-bois","lemma":"mi-bois","pos":"NOM"} ,
		{"word":"mi-bordel","word_nosc":"mi-bordel","lemma":"mi-bordel","pos":"NOM"} ,
		{"word":"mi-bovins","word_nosc":"mi-bovins","lemma":"mi-bovins","pos":"NOM"} ,
		{"word":"mi-braconniers","word_nosc":"mi-braconniers","lemma":"mi-braconnier","pos":"NOM"} ,
		{"word":"mi-bras","word_nosc":"mi-bras","lemma":"mi-bras","pos":"NOM"} ,
		{"word":"mi-building","word_nosc":"mi-building","lemma":"mi-building","pos":"NOM"} ,
		{"word":"mi-café","word_nosc":"mi-cafe","lemma":"mi-café","pos":"NOM"} ,
		{"word":"mi-campagnarde","word_nosc":"mi-campagnarde","lemma":"mi-campagnarde","pos":"NOM"} ,
		{"word":"mi-canard","word_nosc":"mi-canard","lemma":"mi-canard","pos":"NOM"} ,
		{"word":"mi-capacité","word_nosc":"mi-capacite","lemma":"mi-capacité","pos":"NOM"} ,
		{"word":"mi-carrière","word_nosc":"mi-carriere","lemma":"mi-carrier","pos":"NOM"} ,
		{"word":"mi-carême","word_nosc":"mi-careme","lemma":"mi-carême","pos":"NOM"} ,
		{"word":"mi-chat","word_nosc":"mi-chat","lemma":"mi-chat","pos":"NOM"} ,
		{"word":"mi-chatte","word_nosc":"mi-chatte","lemma":"mi-chatte","pos":"NOM"} ,
		{"word":"mi-chemin","word_nosc":"mi-chemin","lemma":"mi-chemin","pos":"NOM"} ,
		{"word":"mi-chou","word_nosc":"mi-chou","lemma":"mi-chou","pos":"NOM"} ,
		{"word":"mi-chourineur","word_nosc":"mi-chourineur","lemma":"mi-chourineur","pos":"NOM"} ,
		{"word":"mi-châtaigniers","word_nosc":"mi-chataigniers","lemma":"mi-châtaignier","pos":"NOM"} ,
		{"word":"mi-chèvre","word_nosc":"mi-chevre","lemma":"mi-chèvre","pos":"NOM"} ,
		{"word":"mi-chênes","word_nosc":"mi-chenes","lemma":"mi-chêne","pos":"NOM"} ,
		{"word":"mi-cloître","word_nosc":"mi-cloitre","lemma":"mi-cloître","pos":"NOM"} ,
		{"word":"mi-compote","word_nosc":"mi-compote","lemma":"mi-compote","pos":"NOM"} ,
		{"word":"mi-confit","word_nosc":"mi-confit","lemma":"mi-confit","pos":"NOM"} ,
		{"word":"mi-corbeaux","word_nosc":"mi-corbeaux","lemma":"mi-corbeaux","pos":"NOM"} ,
		{"word":"mi-corps","word_nosc":"mi-corps","lemma":"mi-corps","pos":"NOM"} ,
		{"word":"mi-course","word_nosc":"mi-course","lemma":"mi-course","pos":"NOM"} ,
		{"word":"mi-cousine","word_nosc":"mi-cousine","lemma":"mi-cousin","pos":"NOM"} ,
		{"word":"mi-cousins","word_nosc":"mi-cousins","lemma":"mi-cousin","pos":"NOM"} ,
		{"word":"mi-coutume","word_nosc":"mi-coutume","lemma":"mi-coutume","pos":"NOM"} ,
		{"word":"mi-crabe","word_nosc":"mi-crabe","lemma":"mi-crabe","pos":"NOM"} ,
		{"word":"mi-croyant","word_nosc":"mi-croyant","lemma":"mi-croyant","pos":"NOM"} ,
		{"word":"mi-créature","word_nosc":"mi-creature","lemma":"mi-créature","pos":"NOM"} ,
		{"word":"mi-cuisse","word_nosc":"mi-cuisse","lemma":"mi-cuisse","pos":"NOM"} ,
		{"word":"mi-cuisses","word_nosc":"mi-cuisses","lemma":"mi-cuisse","pos":"NOM"} ,
		{"word":"mi-côte","word_nosc":"mi-cote","lemma":"mi-côte","pos":"NOM"} ,
		{"word":"mi-dentelle","word_nosc":"mi-dentelle","lemma":"mi-dentelle","pos":"NOM"} ,
		{"word":"mi-distance","word_nosc":"mi-distance","lemma":"mi-distance","pos":"NOM"} ,
		{"word":"mi-décadents","word_nosc":"mi-decadents","lemma":"mi-décadents","pos":"NOM"} ,
		{"word":"mi-décembre","word_nosc":"mi-decembre","lemma":"mi-décembre","pos":"NOM"} ,
		{"word":"mi-démon","word_nosc":"mi-demon","lemma":"mi-démon","pos":"NOM"} ,
		{"word":"mi-désir","word_nosc":"mi-desir","lemma":"mi-désir","pos":"NOM"} ,
		{"word":"mi-effrontée","word_nosc":"mi-effrontee","lemma":"mi-effrontée","pos":"NOM"} ,
		{"word":"mi-enfant","word_nosc":"mi-enfant","lemma":"mi-enfant","pos":"NOM"} ,
		{"word":"mi-espagnol","word_nosc":"mi-espagnol","lemma":"mi-espagnol","pos":"NOM"} ,
		{"word":"mi-européen","word_nosc":"mi-europeen","lemma":"mi-européen","pos":"NOM"} ,
		{"word":"mi-excitation","word_nosc":"mi-excitation","lemma":"mi-excitation","pos":"NOM"} ,
		{"word":"mi-farceur","word_nosc":"mi-farceur","lemma":"mi-farceur","pos":"NOM"} ,
		{"word":"mi-femme","word_nosc":"mi-femme","lemma":"mi-femme","pos":"NOM"} ,
		{"word":"mi-fermiers","word_nosc":"mi-fermiers","lemma":"mi-fermiers","pos":"NOM"} ,
		{"word":"mi-fiel","word_nosc":"mi-fiel","lemma":"mi-fiel","pos":"NOM"} ,
		{"word":"mi-figue","word_nosc":"mi-figue","lemma":"mi-figue","pos":"NOM"} ,
		{"word":"mi-flanc","word_nosc":"mi-flanc","lemma":"mi-flanc","pos":"NOM"} ,
		{"word":"mi-fleuve","word_nosc":"mi-fleuve","lemma":"mi-fleuve","pos":"NOM"} ,
		{"word":"mi-français","word_nosc":"mi-francais","lemma":"mi-français","pos":"NOM"} ,
		{"word":"mi-février","word_nosc":"mi-fevrier","lemma":"mi-février","pos":"NOM"} ,
		{"word":"mi-garçon","word_nosc":"mi-garcon","lemma":"mi-garçon","pos":"NOM"} ,
		{"word":"mi-geste","word_nosc":"mi-geste","lemma":"mi-geste","pos":"NOM"} ,
		{"word":"mi-gigolpince","word_nosc":"mi-gigolpince","lemma":"mi-gigolpince","pos":"NOM"} ,
		{"word":"mi-gnon","word_nosc":"mi-gnon","lemma":"mi-gnon","pos":"NOM"} ,
		{"word":"mi-gonzesse","word_nosc":"mi-gonzesse","lemma":"mi-gonzesse","pos":"NOM"} ,
		{"word":"mi-goélands","word_nosc":"mi-goelands","lemma":"mi-goéland","pos":"NOM"} ,
		{"word":"mi-grenier","word_nosc":"mi-grenier","lemma":"mi-grenier","pos":"NOM"} ,
		{"word":"mi-grognon","word_nosc":"mi-grognon","lemma":"mi-grognon","pos":"NOM"} ,
		{"word":"mi-hauteur","word_nosc":"mi-hauteur","lemma":"mi-hauteur","pos":"NOM"} ,
		{"word":"mi-homme","word_nosc":"mi-homme","lemma":"mi-homme","pos":"NOM"} ,
		{"word":"mi-humain","word_nosc":"mi-humain","lemma":"mi-humain","pos":"NOM"} ,
		{"word":"mi-humaine","word_nosc":"mi-humaine","lemma":"mi-humaine","pos":"NOM"} ,
		{"word":"mi-humains","word_nosc":"mi-humains","lemma":"mi-humains","pos":"NOM"} ,
		{"word":"mi-idiotes","word_nosc":"mi-idiotes","lemma":"mi-idiotes","pos":"NOM"} ,
		{"word":"mi-images","word_nosc":"mi-images","lemma":"mi-image","pos":"NOM"} ,
		{"word":"mi-indien","word_nosc":"mi-indien","lemma":"mi-indien","pos":"NOM"} ,
		{"word":"mi-indifférente","word_nosc":"mi-indifferente","lemma":"mi-indifférente","pos":"NOM"} ,
		{"word":"mi-jambe","word_nosc":"mi-jambe","lemma":"mi-jambe","pos":"NOM"} ,
		{"word":"mi-jambes","word_nosc":"mi-jambes","lemma":"mi-jambe","pos":"NOM"} ,
		{"word":"mi-janvier","word_nosc":"mi-janvier","lemma":"mi-janvier","pos":"NOM"} ,
		{"word":"mi-jersey","word_nosc":"mi-jersey","lemma":"mi-jersey","pos":"NOM"} ,
		{"word":"mi-joue","word_nosc":"mi-joue","lemma":"mi-joue","pos":"NOM"} ,
		{"word":"mi-jour","word_nosc":"mi-jour","lemma":"mi-jour","pos":"NOM"} ,
		{"word":"mi-journée","word_nosc":"mi-journee","lemma":"mi-journée","pos":"NOM"} ,
		{"word":"mi-juif","word_nosc":"mi-juif","lemma":"mi-juif","pos":"NOM"} ,
		{"word":"mi-juillet","word_nosc":"mi-juillet","lemma":"mi-juillet","pos":"NOM"} ,
		{"word":"mi-juin","word_nosc":"mi-juin","lemma":"mi-juin","pos":"NOM"} ,
		{"word":"mi-laiton","word_nosc":"mi-laiton","lemma":"mi-laiton","pos":"NOM"} ,
		{"word":"mi-londrès","word_nosc":"mi-londres","lemma":"mi-londrès","pos":"NOM"} ,
		{"word":"mi-longs","word_nosc":"mi-longs","lemma":"mi-longs","pos":"NOM"} ,
		{"word":"mi-longueur","word_nosc":"mi-longueur","lemma":"mi-longueur","pos":"NOM"} ,
		{"word":"mi-lourd","word_nosc":"mi-lourd","lemma":"mi-lourd","pos":"NOM"} ,
		{"word":"mi-machine","word_nosc":"mi-machine","lemma":"mi-machine","pos":"NOM"} ,
		{"word":"mi-machines","word_nosc":"mi-machines","lemma":"mi-machine","pos":"NOM"} ,
		{"word":"mi-mai","word_nosc":"mi-mai","lemma":"mi-mai","pos":"NOM"} ,
		{"word":"mi-mao","word_nosc":"mi-mao","lemma":"mi-mao","pos":"NOM"} ,
		{"word":"mi-marchande","word_nosc":"mi-marchande","lemma":"mi-marchande","pos":"NOM"} ,
		{"word":"mi-mars","word_nosc":"mi-mars","lemma":"mi-mars","pos":"NOM"} ,
		{"word":"mi-marécages","word_nosc":"mi-marecages","lemma":"mi-marécage","pos":"NOM"} ,
		{"word":"mi-meublé","word_nosc":"mi-meuble","lemma":"mi-meublé","pos":"NOM"} ,
		{"word":"mi-mexicain","word_nosc":"mi-mexicain","lemma":"mi-mexicain","pos":"NOM"} ,
		{"word":"mi-mollet","word_nosc":"mi-mollet","lemma":"mi-mollet","pos":"NOM"} ,
		{"word":"mi-mollets","word_nosc":"mi-mollets","lemma":"mi-mollets","pos":"NOM"} ,
		{"word":"mi-mondaine","word_nosc":"mi-mondaine","lemma":"mi-mondaine","pos":"NOM"} ,
		{"word":"mi-moqueur","word_nosc":"mi-moqueur","lemma":"mi-moqueur","pos":"NOM"} ,
		{"word":"mi-mot","word_nosc":"mi-mot","lemma":"mi-mot","pos":"NOM"} ,
		{"word":"mi-mots","word_nosc":"mi-mots","lemma":"mi-mot","pos":"NOM"} ,
		{"word":"mi-mouton","word_nosc":"mi-mouton","lemma":"mi-mouton","pos":"NOM"} ,
		{"word":"mi-moyen","word_nosc":"mi-moyen","lemma":"mi-moyen","pos":"NOM"} ,
		{"word":"mi-métal","word_nosc":"mi-metal","lemma":"mi-métal","pos":"NOM"} ,
		{"word":"mi-noir","word_nosc":"mi-noir","lemma":"mi-noir","pos":"NOM"} ,
		{"word":"mi-novembre","word_nosc":"mi-novembre","lemma":"mi-novembre","pos":"NOM"} ,
		{"word":"mi-nuit","word_nosc":"mi-nuit","lemma":"mi-nuit","pos":"NOM"} ,
		{"word":"mi-octobre","word_nosc":"mi-octobre","lemma":"mi-octobre","pos":"NOM"} ,
		{"word":"mi-officiel","word_nosc":"mi-officiel","lemma":"mi-officiel","pos":"NOM"} ,
		{"word":"mi-ogre","word_nosc":"mi-ogre","lemma":"mi-ogre","pos":"NOM"} ,
		{"word":"mi-oiseau","word_nosc":"mi-oiseau","lemma":"mi-oiseau","pos":"NOM"} ,
		{"word":"mi-oriental","word_nosc":"mi-oriental","lemma":"mi-oriental","pos":"NOM"} ,
		{"word":"mi-parcours","word_nosc":"mi-parcours","lemma":"mi-parcours","pos":"NOM"} ,
		{"word":"mi-patio","word_nosc":"mi-patio","lemma":"mi-patio","pos":"NOM"} ,
		{"word":"mi-patois","word_nosc":"mi-patois","lemma":"mi-patois","pos":"NOM"} ,
		{"word":"mi-peau","word_nosc":"mi-peau","lemma":"mi-peau","pos":"NOM"} ,
		{"word":"mi-pelouse","word_nosc":"mi-pelouse","lemma":"mi-pelouse","pos":"NOM"} ,
		{"word":"mi-pensées","word_nosc":"mi-pensees","lemma":"mi-pensée","pos":"NOM"} ,
		{"word":"mi-pente","word_nosc":"mi-pente","lemma":"mi-pente","pos":"NOM"} ,
		{"word":"mi-pierre","word_nosc":"mi-pierre","lemma":"mi-pierre","pos":"NOM"} ,
		{"word":"mi-plaisant","word_nosc":"mi-plaisant","lemma":"mi-plaisant","pos":"NOM"} ,
		{"word":"mi-pleurant","word_nosc":"mi-pleurant","lemma":"mi-pleurant","pos":"NOM"} ,
		{"word":"mi-pleurnichard","word_nosc":"mi-pleurnichard","lemma":"mi-pleurnichard","pos":"NOM"} ,
		{"word":"mi-plombiers","word_nosc":"mi-plombiers","lemma":"mi-plombier","pos":"NOM"} ,
		{"word":"mi-poisson","word_nosc":"mi-poisson","lemma":"mi-poisson","pos":"NOM"} ,
		{"word":"mi-poitrine","word_nosc":"mi-poitrine","lemma":"mi-poitrine","pos":"NOM"} ,
		{"word":"mi-porcine","word_nosc":"mi-porcine","lemma":"mi-porcine","pos":"NOM"} ,
		{"word":"mi-portugaise","word_nosc":"mi-portugaise","lemma":"mi-portugaise","pos":"NOM"} ,
		{"word":"mi-porté","word_nosc":"mi-porte","lemma":"mi-porté","pos":"NOM"} ,
		{"word":"mi-poucet","word_nosc":"mi-poucet","lemma":"mi-poucet","pos":"NOM"} ,
		{"word":"mi-poulet","word_nosc":"mi-poulet","lemma":"mi-poulet","pos":"NOM"} ,
		{"word":"mi-prestidigitateur","word_nosc":"mi-prestidigitateur","lemma":"mi-prestidigitateur","pos":"NOM"} ,
		{"word":"mi-promenoir","word_nosc":"mi-promenoir","lemma":"mi-promenoir","pos":"NOM"} ,
		{"word":"mi-protecteur","word_nosc":"mi-protecteur","lemma":"mi-protecteur","pos":"NOM"} ,
		{"word":"mi-putain","word_nosc":"mi-putain","lemma":"mi-putain","pos":"NOM"} ,
		{"word":"mi-pêcheurs","word_nosc":"mi-pecheurs","lemma":"mi-pêcheur","pos":"NOM"} ,
		{"word":"mi-railleur","word_nosc":"mi-railleur","lemma":"mi-railleur","pos":"NOM"} ,
		{"word":"mi-raisin","word_nosc":"mi-raisin","lemma":"mi-raisin","pos":"NOM"} ,
		{"word":"mi-renaissance","word_nosc":"mi-renaissance","lemma":"mi-renaissance","pos":"NOM"} ,
		{"word":"mi-rose","word_nosc":"mi-rose","lemma":"mi-rose","pos":"NOM"} ,
		{"word":"mi-roulotte","word_nosc":"mi-roulotte","lemma":"mi-roulotte","pos":"NOM"} ,
		{"word":"mi-route","word_nosc":"mi-route","lemma":"mi-route","pos":"NOM"} ,
		{"word":"mi-réalité","word_nosc":"mi-realite","lemma":"mi-réalité","pos":"NOM"} ,
		{"word":"mi-régime","word_nosc":"mi-regime","lemma":"mi-régime","pos":"NOM"} ,
		{"word":"mi-résigné","word_nosc":"mi-resigne","lemma":"mi-résigné","pos":"NOM"} ,
		{"word":"mi-saison","word_nosc":"mi-saison","lemma":"mi-saison","pos":"NOM"} ,
		{"word":"mi-salade","word_nosc":"mi-salade","lemma":"mi-salade","pos":"NOM"} ,
		{"word":"mi-salaire","word_nosc":"mi-salaire","lemma":"mi-salaire","pos":"NOM"} ,
		{"word":"mi-satin","word_nosc":"mi-satin","lemma":"mi-satin","pos":"NOM"} ,
		{"word":"mi-secours","word_nosc":"mi-secours","lemma":"mi-secours","pos":"NOM"} ,
		{"word":"mi-seigneur","word_nosc":"mi-seigneur","lemma":"mi-seigneur","pos":"NOM"} ,
		{"word":"mi-sel","word_nosc":"mi-sel","lemma":"mi-sel","pos":"NOM"} ,
		{"word":"mi-septembre","word_nosc":"mi-septembre","lemma":"mi-septembre","pos":"NOM"} ,
		{"word":"mi-singe","word_nosc":"mi-singe","lemma":"mi-singe","pos":"NOM"} ,
		{"word":"mi-sommet","word_nosc":"mi-sommet","lemma":"mi-sommet","pos":"NOM"} ,
		{"word":"mi-songe","word_nosc":"mi-songe","lemma":"mi-songe","pos":"NOM"} ,
		{"word":"mi-série","word_nosc":"mi-serie","lemma":"mi-série","pos":"NOM"} ,
		{"word":"mi-tantouse","word_nosc":"mi-tantouse","lemma":"mi-tantouse","pos":"NOM"} ,
		{"word":"mi-tendre","word_nosc":"mi-tendre","lemma":"mi-tendre","pos":"NOM"} ,
		{"word":"mi-tour","word_nosc":"mi-tour","lemma":"mi-tour","pos":"NOM"} ,
		{"word":"mi-tout","word_nosc":"mi-tout","lemma":"mi-tout","pos":"NOM"} ,
		{"word":"mi-trimestre","word_nosc":"mi-trimestre","lemma":"mi-trimestre","pos":"NOM"} ,
		{"word":"mi-velours","word_nosc":"mi-velours","lemma":"mi-velours","pos":"NOM"} ,
		{"word":"mi-verre","word_nosc":"mi-verre","lemma":"mi-verre","pos":"NOM"} ,
		{"word":"mi-vie","word_nosc":"mi-vie","lemma":"mi-vie","pos":"NOM"} ,
		{"word":"mi-voix","word_nosc":"mi-voix","lemma":"mi-voix","pos":"NOM"} ,
		{"word":"mi-vrais","word_nosc":"mi-vrais","lemma":"mi-vrais","pos":"NOM"} ,
		{"word":"mi-végétal","word_nosc":"mi-vegetal","lemma":"mi-végétal","pos":"NOM"} ,
		{"word":"mi-vénitien","word_nosc":"mi-venitien","lemma":"mi-vénitien","pos":"NOM"} ,
		{"word":"mi-épaule","word_nosc":"mi-epaule","lemma":"mi-épaule","pos":"NOM"} ,
		{"word":"mi-étage","word_nosc":"mi-etage","lemma":"mi-étage","pos":"NOM"} ,
		{"word":"mi-étendue","word_nosc":"mi-etendue","lemma":"mi-étendue","pos":"NOM"} ,
		{"word":"mi-étudiant","word_nosc":"mi-etudiant","lemma":"mi-étudiant","pos":"NOM"} ,
		{"word":"mi-été","word_nosc":"mi-ete","lemma":"mi-été","pos":"NOM"} ,
		{"word":"miaou","word_nosc":"miaou","lemma":"miaou","pos":"NOM"} ,
		{"word":"miaous","word_nosc":"miaous","lemma":"miaou","pos":"NOM"} ,
		{"word":"miasme","word_nosc":"miasme","lemma":"miasme","pos":"NOM"} ,
		{"word":"miasmes","word_nosc":"miasmes","lemma":"miasme","pos":"NOM"} ,
		{"word":"miaulement","word_nosc":"miaulement","lemma":"miaulement","pos":"NOM"} ,
		{"word":"miaulements","word_nosc":"miaulements","lemma":"miaulement","pos":"NOM"} ,
		{"word":"mica","word_nosc":"mica","lemma":"mica","pos":"NOM"} ,
		{"word":"micas","word_nosc":"micas","lemma":"mica","pos":"NOM"} ,
		{"word":"miche","word_nosc":"miche","lemma":"miche","pos":"NOM"} ,
		{"word":"micheline","word_nosc":"micheline","lemma":"micheline","pos":"NOM"} ,
		{"word":"miches","word_nosc":"miches","lemma":"miche","pos":"NOM"} ,
		{"word":"micheton","word_nosc":"micheton","lemma":"micheton","pos":"NOM"} ,
		{"word":"michetonneuse","word_nosc":"michetonneuse","lemma":"michetonneuse","pos":"NOM"} ,
		{"word":"michetonneuses","word_nosc":"michetonneuses","lemma":"michetonneuse","pos":"NOM"} ,
		{"word":"michetons","word_nosc":"michetons","lemma":"micheton","pos":"NOM"} ,
		{"word":"miché","word_nosc":"miche","lemma":"miché","pos":"NOM"} ,
		{"word":"michés","word_nosc":"miches","lemma":"miché","pos":"NOM"} ,
		{"word":"mickeys","word_nosc":"mickeys","lemma":"mickey","pos":"NOM"} ,
		{"word":"micmac","word_nosc":"micmac","lemma":"micmac","pos":"NOM"} ,
		{"word":"micmacs","word_nosc":"micmacs","lemma":"micmac","pos":"NOM"} ,
		{"word":"micocoulier","word_nosc":"micocoulier","lemma":"micocoulier","pos":"NOM"} ,
		{"word":"micocouliers","word_nosc":"micocouliers","lemma":"micocoulier","pos":"NOM"} ,
		{"word":"micro","word_nosc":"micro","lemma":"micro","pos":"NOM"} ,
		{"word":"micro-cravate","word_nosc":"micro-cravate","lemma":"micro-cravate","pos":"NOM"} ,
		{"word":"micro-onde","word_nosc":"micro-onde","lemma":"micro-onde","pos":"NOM"} ,
		{"word":"micro-ondes","word_nosc":"micro-ondes","lemma":"micro-ondes","pos":"NOM"} ,
		{"word":"micro-ordinateur","word_nosc":"micro-ordinateur","lemma":"micro-ordinateur","pos":"NOM"} ,
		{"word":"micro-ordinateurs","word_nosc":"micro-ordinateurs","lemma":"micro-ordinateur","pos":"NOM"} ,
		{"word":"micro-organisme","word_nosc":"micro-organisme","lemma":"micro-organisme","pos":"NOM"} ,
		{"word":"micro-trottoir","word_nosc":"micro-trottoir","lemma":"micro-trottoir","pos":"NOM"} ,
		{"word":"microanalyse","word_nosc":"microanalyse","lemma":"microanalyse","pos":"NOM"} ,
		{"word":"microbe","word_nosc":"microbe","lemma":"microbe","pos":"NOM"} ,
		{"word":"microbes","word_nosc":"microbes","lemma":"microbe","pos":"NOM"} ,
		{"word":"microbiologie","word_nosc":"microbiologie","lemma":"microbiologie","pos":"NOM"} ,
		{"word":"microbiologiste","word_nosc":"microbiologiste","lemma":"microbiologiste","pos":"NOM"} ,
		{"word":"microbus","word_nosc":"microbus","lemma":"microbus","pos":"NOM"} ,
		{"word":"microcassette","word_nosc":"microcassette","lemma":"microcassette","pos":"NOM"} ,
		{"word":"microchimie","word_nosc":"microchimie","lemma":"microchimie","pos":"NOM"} ,
		{"word":"microchirurgie","word_nosc":"microchirurgie","lemma":"microchirurgie","pos":"NOM"} ,
		{"word":"microcircuit","word_nosc":"microcircuit","lemma":"microcircuit","pos":"NOM"} ,
		{"word":"microcircuits","word_nosc":"microcircuits","lemma":"microcircuit","pos":"NOM"} ,
		{"word":"microclimat","word_nosc":"microclimat","lemma":"microclimat","pos":"NOM"} ,
		{"word":"microcomposants","word_nosc":"microcomposants","lemma":"microcomposant","pos":"NOM"} ,
		{"word":"microcopie","word_nosc":"microcopie","lemma":"microcopie","pos":"NOM"} ,
		{"word":"microcosme","word_nosc":"microcosme","lemma":"microcosme","pos":"NOM"} ,
		{"word":"microcycles","word_nosc":"microcycles","lemma":"microcycle","pos":"NOM"} ,
		{"word":"microcéphale","word_nosc":"microcephale","lemma":"microcéphale","pos":"NOM"} ,
		{"word":"microcéphales","word_nosc":"microcephales","lemma":"microcéphale","pos":"NOM"} ,
		{"word":"microfiches","word_nosc":"microfiches","lemma":"microfiche","pos":"NOM"} ,
		{"word":"microfilm","word_nosc":"microfilm","lemma":"microfilm","pos":"NOM"} ,
		{"word":"microfilms","word_nosc":"microfilms","lemma":"microfilm","pos":"NOM"} ,
		{"word":"microgramme","word_nosc":"microgramme","lemma":"microgramme","pos":"NOM"} ,
		{"word":"microgravité","word_nosc":"microgravite","lemma":"microgravité","pos":"NOM"} ,
		{"word":"microlithes","word_nosc":"microlithes","lemma":"microlithe","pos":"NOM"} ,
		{"word":"micromètre","word_nosc":"micrometre","lemma":"micromètre","pos":"NOM"} ,
		{"word":"micromètres","word_nosc":"micrometres","lemma":"micromètre","pos":"NOM"} ,
		{"word":"micron","word_nosc":"micron","lemma":"micron","pos":"NOM"} ,
		{"word":"microns","word_nosc":"microns","lemma":"micron","pos":"NOM"} ,
		{"word":"microphone","word_nosc":"microphone","lemma":"microphone","pos":"NOM"} ,
		{"word":"microphones","word_nosc":"microphones","lemma":"microphone","pos":"NOM"} ,
		{"word":"microprocesseur","word_nosc":"microprocesseur","lemma":"microprocesseur","pos":"NOM"} ,
		{"word":"microprocesseurs","word_nosc":"microprocesseurs","lemma":"microprocesseur","pos":"NOM"} ,
		{"word":"micros","word_nosc":"micros","lemma":"micro","pos":"NOM"} ,
		{"word":"microscope","word_nosc":"microscope","lemma":"microscope","pos":"NOM"} ,
		{"word":"microscopes","word_nosc":"microscopes","lemma":"microscope","pos":"NOM"} ,
		{"word":"microscopie","word_nosc":"microscopie","lemma":"microscopie","pos":"NOM"} ,
		{"word":"microseconde","word_nosc":"microseconde","lemma":"microseconde","pos":"NOM"} ,
		{"word":"microsecondes","word_nosc":"microsecondes","lemma":"microseconde","pos":"NOM"} ,
		{"word":"microsillon","word_nosc":"microsillon","lemma":"microsillon","pos":"NOM"} ,
		{"word":"microsillons","word_nosc":"microsillons","lemma":"microsillon","pos":"NOM"} ,
		{"word":"microsociologie","word_nosc":"microsociologie","lemma":"microsociologie","pos":"NOM"} ,
		{"word":"microsonde","word_nosc":"microsonde","lemma":"microsonde","pos":"NOM"} ,
		{"word":"microstructure","word_nosc":"microstructure","lemma":"microstructure","pos":"NOM"} ,
		{"word":"microstructures","word_nosc":"microstructures","lemma":"microstructure","pos":"NOM"} ,
		{"word":"microédition","word_nosc":"microedition","lemma":"microédition","pos":"NOM"} ,
		{"word":"miction","word_nosc":"miction","lemma":"miction","pos":"NOM"} ,
		{"word":"mictions","word_nosc":"mictions","lemma":"miction","pos":"NOM"} ,
		{"word":"middle class","word_nosc":"middle class","lemma":"middle class","pos":"NOM"} ,
		{"word":"middle-west","word_nosc":"middle-west","lemma":"middle-west","pos":"NOM"} ,
		{"word":"midi","word_nosc":"midi","lemma":"midi","pos":"NOM"} ,
		{"word":"midinette","word_nosc":"midinette","lemma":"midinette","pos":"NOM"} ,
		{"word":"midinettes","word_nosc":"midinettes","lemma":"midinette","pos":"NOM"} ,
		{"word":"midis","word_nosc":"midis","lemma":"midi","pos":"NOM"} ,
		{"word":"midrash","word_nosc":"midrash","lemma":"midrash","pos":"NOM"} ,
		{"word":"midship","word_nosc":"midship","lemma":"midship","pos":"NOM"} ,
		{"word":"mie","word_nosc":"mie","lemma":"mie","pos":"NOM"} ,
		{"word":"miel","word_nosc":"miel","lemma":"miel","pos":"NOM"} ,
		{"word":"miellat","word_nosc":"miellat","lemma":"miellat","pos":"NOM"} ,
		{"word":"mielle","word_nosc":"mielle","lemma":"mielle","pos":"NOM"} ,
		{"word":"miellée","word_nosc":"miellee","lemma":"miellé","pos":"NOM"} ,
		{"word":"miellées","word_nosc":"miellees","lemma":"miellé","pos":"NOM"} ,
		{"word":"miels","word_nosc":"miels","lemma":"miel","pos":"NOM"} ,
		{"word":"mies","word_nosc":"mies","lemma":"mie","pos":"NOM"} ,
		{"word":"miette","word_nosc":"miette","lemma":"miette","pos":"NOM"} ,
		{"word":"miettes","word_nosc":"miettes","lemma":"miette","pos":"NOM"} ,
		{"word":"mieux","word_nosc":"mieux","lemma":"mieux","pos":"NOM"} ,
		{"word":"mieux-vivre","word_nosc":"mieux-vivre","lemma":"mieux-vivre","pos":"NOM"} ,
		{"word":"mieux-être","word_nosc":"mieux-etre","lemma":"mieux-être","pos":"NOM"} ,
		{"word":"mignardise","word_nosc":"mignardise","lemma":"mignardise","pos":"NOM"} ,
		{"word":"mignardises","word_nosc":"mignardises","lemma":"mignardise","pos":"NOM"} ,
		{"word":"mignards","word_nosc":"mignards","lemma":"mignard","pos":"NOM"} ,
		{"word":"mignon","word_nosc":"mignon","lemma":"mignon","pos":"NOM"} ,
		{"word":"mignonne","word_nosc":"mignonne","lemma":"mignon","pos":"NOM"} ,
		{"word":"mignonnes","word_nosc":"mignonnes","lemma":"mignon","pos":"NOM"} ,
		{"word":"mignonnette","word_nosc":"mignonnette","lemma":"mignonnette","pos":"NOM"} ,
		{"word":"mignonnettes","word_nosc":"mignonnettes","lemma":"mignonnette","pos":"NOM"} ,
		{"word":"mignons","word_nosc":"mignons","lemma":"mignon","pos":"NOM"} ,
		{"word":"migraine","word_nosc":"migraine","lemma":"migraine","pos":"NOM"} ,
		{"word":"migraines","word_nosc":"migraines","lemma":"migraine","pos":"NOM"} ,
		{"word":"migraineuse","word_nosc":"migraineuse","lemma":"migraineuse","pos":"NOM"} ,
		{"word":"migraineux","word_nosc":"migraineux","lemma":"migraineux","pos":"NOM"} ,
		{"word":"migrant","word_nosc":"migrant","lemma":"migrant","pos":"NOM"} ,
		{"word":"migrants","word_nosc":"migrants","lemma":"migrant","pos":"NOM"} ,
		{"word":"migrateur","word_nosc":"migrateur","lemma":"migrateur","pos":"NOM"} ,
		{"word":"migrateurs","word_nosc":"migrateurs","lemma":"migrateur","pos":"NOM"} ,
		{"word":"migration","word_nosc":"migration","lemma":"migration","pos":"NOM"} ,
		{"word":"migrations","word_nosc":"migrations","lemma":"migration","pos":"NOM"} ,
		{"word":"mijaurée","word_nosc":"mijauree","lemma":"mijaurée","pos":"NOM"} ,
		{"word":"mijaurées","word_nosc":"mijaurees","lemma":"mijaurée","pos":"NOM"} ,
		{"word":"mijoteuse","word_nosc":"mijoteuse","lemma":"mijoteuse","pos":"NOM"} ,
		{"word":"mikado","word_nosc":"mikado","lemma":"mikado","pos":"NOM"} ,
		{"word":"mil","word_nosc":"mil","lemma":"mil","pos":"NOM"} ,
		{"word":"milady","word_nosc":"milady","lemma":"milady","pos":"NOM"} ,
		{"word":"milan","word_nosc":"milan","lemma":"milan","pos":"NOM"} ,
		{"word":"milanais","word_nosc":"milanais","lemma":"milanais","pos":"NOM"} ,
		{"word":"milanaise","word_nosc":"milanaise","lemma":"milanais","pos":"NOM"} ,
		{"word":"milanaises","word_nosc":"milanaises","lemma":"milanais","pos":"NOM"} ,
		{"word":"milans","word_nosc":"milans","lemma":"milan","pos":"NOM"} ,
		{"word":"mildiou","word_nosc":"mildiou","lemma":"mildiou","pos":"NOM"} ,
		{"word":"mile","word_nosc":"mile","lemma":"mile","pos":"NOM"} ,
		{"word":"miles","word_nosc":"miles","lemma":"mile","pos":"NOM"} ,
		{"word":"milice","word_nosc":"milice","lemma":"milice","pos":"NOM"} ,
		{"word":"milices","word_nosc":"milices","lemma":"milice","pos":"NOM"} ,
		{"word":"milicien","word_nosc":"milicien","lemma":"milicien","pos":"NOM"} ,
		{"word":"miliciennes","word_nosc":"miliciennes","lemma":"milicien","pos":"NOM"} ,
		{"word":"miliciennes","word_nosc":"miliciennes","lemma":"milicienne","pos":"NOM"} ,
		{"word":"miliciens","word_nosc":"miliciens","lemma":"milicien","pos":"NOM"} ,
		{"word":"milieu","word_nosc":"milieu","lemma":"milieu","pos":"NOM"} ,
		{"word":"milieux","word_nosc":"milieux","lemma":"milieu","pos":"NOM"} ,
		{"word":"militaire","word_nosc":"militaire","lemma":"militaire","pos":"NOM"} ,
		{"word":"militaires","word_nosc":"militaires","lemma":"militaire","pos":"NOM"} ,
		{"word":"militant","word_nosc":"militant","lemma":"militant","pos":"NOM"} ,
		{"word":"militante","word_nosc":"militante","lemma":"militant","pos":"NOM"} ,
		{"word":"militantes","word_nosc":"militantes","lemma":"militant","pos":"NOM"} ,
		{"word":"militantisme","word_nosc":"militantisme","lemma":"militantisme","pos":"NOM"} ,
		{"word":"militants","word_nosc":"militants","lemma":"militant","pos":"NOM"} ,
		{"word":"militarisation","word_nosc":"militarisation","lemma":"militarisation","pos":"NOM"} ,
		{"word":"militarisme","word_nosc":"militarisme","lemma":"militarisme","pos":"NOM"} ,
		{"word":"militariste","word_nosc":"militariste","lemma":"militariste","pos":"NOM"} ,
		{"word":"militaristes","word_nosc":"militaristes","lemma":"militariste","pos":"NOM"} ,
		{"word":"milk shake","word_nosc":"milk shake","lemma":"milk-shake","pos":"NOM"} ,
		{"word":"milk-bar","word_nosc":"milk-bar","lemma":"milk-bar","pos":"NOM"} ,
		{"word":"milk-bars","word_nosc":"milk-bars","lemma":"milk-bar","pos":"NOM"} ,
		{"word":"milk-shake","word_nosc":"milk-shake","lemma":"milk-shake","pos":"NOM"} ,
		{"word":"milk-shakes","word_nosc":"milk-shakes","lemma":"milk-shake","pos":"NOM"} ,
		{"word":"mille","word_nosc":"mille","lemma":"mille","pos":"NOM"} ,
		{"word":"mille-feuille","word_nosc":"mille-feuille","lemma":"mille-feuille","pos":"NOM"} ,
		{"word":"mille-feuilles","word_nosc":"mille-feuilles","lemma":"mille-feuille","pos":"NOM"} ,
		{"word":"mille-pattes","word_nosc":"mille-pattes","lemma":"mille-pattes","pos":"NOM"} ,
		{"word":"millefeuille","word_nosc":"millefeuille","lemma":"millefeuille","pos":"NOM"} ,
		{"word":"millefeuilles","word_nosc":"millefeuilles","lemma":"millefeuille","pos":"NOM"} ,
		{"word":"millenium","word_nosc":"millenium","lemma":"millenium","pos":"NOM"} ,
		{"word":"milles","word_nosc":"milles","lemma":"mille","pos":"NOM"} ,
		{"word":"millet","word_nosc":"millet","lemma":"millet","pos":"NOM"} ,
		{"word":"milliard","word_nosc":"milliard","lemma":"milliard","pos":"NOM"} ,
		{"word":"milliardaire","word_nosc":"milliardaire","lemma":"milliardaire","pos":"NOM"} ,
		{"word":"milliardaires","word_nosc":"milliardaires","lemma":"milliardaire","pos":"NOM"} ,
		{"word":"milliardième","word_nosc":"milliardieme","lemma":"milliardième","pos":"NOM"} ,
		{"word":"milliards","word_nosc":"milliards","lemma":"milliard","pos":"NOM"} ,
		{"word":"millier","word_nosc":"millier","lemma":"millier","pos":"NOM"} ,
		{"word":"milliers","word_nosc":"milliers","lemma":"millier","pos":"NOM"} ,
		{"word":"milligramme","word_nosc":"milligramme","lemma":"milligramme","pos":"NOM"} ,
		{"word":"milligrammes","word_nosc":"milligrammes","lemma":"milligramme","pos":"NOM"} ,
		{"word":"millilitre","word_nosc":"millilitre","lemma":"millilitre","pos":"NOM"} ,
		{"word":"millimètre","word_nosc":"millimetre","lemma":"millimètre","pos":"NOM"} ,
		{"word":"millimètres","word_nosc":"millimetres","lemma":"millimètre","pos":"NOM"} ,
		{"word":"million","word_nosc":"million","lemma":"million","pos":"NOM"} ,
		{"word":"millionième","word_nosc":"millionieme","lemma":"millionième","pos":"NOM"} ,
		{"word":"millionnaire","word_nosc":"millionnaire","lemma":"millionnaire","pos":"NOM"} ,
		{"word":"millionnaires","word_nosc":"millionnaires","lemma":"millionnaire","pos":"NOM"} ,
		{"word":"millions","word_nosc":"millions","lemma":"million","pos":"NOM"} ,
		{"word":"milliseconde","word_nosc":"milliseconde","lemma":"milliseconde","pos":"NOM"} ,
		{"word":"millisecondes","word_nosc":"millisecondes","lemma":"milliseconde","pos":"NOM"} ,
		{"word":"millième","word_nosc":"millieme","lemma":"millième","pos":"NOM"} ,
		{"word":"millièmes","word_nosc":"milliemes","lemma":"millième","pos":"NOM"} ,
		{"word":"millénaire","word_nosc":"millenaire","lemma":"millénaire","pos":"NOM"} ,
		{"word":"millénaires","word_nosc":"millenaires","lemma":"millénaire","pos":"NOM"} ,
		{"word":"millénarisme","word_nosc":"millenarisme","lemma":"millénarisme","pos":"NOM"} ,
		{"word":"millénium","word_nosc":"millenium","lemma":"millénium","pos":"NOM"} ,
		{"word":"millésime","word_nosc":"millesime","lemma":"millésime","pos":"NOM"} ,
		{"word":"millésimes","word_nosc":"millesimes","lemma":"millésime","pos":"NOM"} ,
		{"word":"milonga","word_nosc":"milonga","lemma":"milonga","pos":"NOM"} ,
		{"word":"milord","word_nosc":"milord","lemma":"milord","pos":"NOM"} ,
		{"word":"milords","word_nosc":"milords","lemma":"milord","pos":"NOM"} ,
		{"word":"milouin","word_nosc":"milouin","lemma":"milouin","pos":"NOM"} ,
		{"word":"mime","word_nosc":"mime","lemma":"mime","pos":"NOM"} ,
		{"word":"mimes","word_nosc":"mimes","lemma":"mime","pos":"NOM"} ,
		{"word":"mimi","word_nosc":"mimi","lemma":"mimi","pos":"NOM"} ,
		{"word":"mimique","word_nosc":"mimique","lemma":"mimique","pos":"NOM"} ,
		{"word":"mimiques","word_nosc":"mimiques","lemma":"mimique","pos":"NOM"} ,
		{"word":"mimis","word_nosc":"mimis","lemma":"mimi","pos":"NOM"} ,
		{"word":"mimodrames","word_nosc":"mimodrames","lemma":"mimodrame","pos":"NOM"} ,
		{"word":"mimolette","word_nosc":"mimolette","lemma":"mimolette","pos":"NOM"} ,
		{"word":"mimosa","word_nosc":"mimosa","lemma":"mimosa","pos":"NOM"} ,
		{"word":"mimosas","word_nosc":"mimosas","lemma":"mimosa","pos":"NOM"} ,
		{"word":"mimosées","word_nosc":"mimosees","lemma":"mimosée","pos":"NOM"} ,
		{"word":"mimétisme","word_nosc":"mimetisme","lemma":"mimétisme","pos":"NOM"} ,
		{"word":"min","word_nosc":"min","lemma":"min","pos":"NOM"} ,
		{"word":"minable","word_nosc":"minable","lemma":"minable","pos":"NOM"} ,
		{"word":"minables","word_nosc":"minables","lemma":"minable","pos":"NOM"} ,
		{"word":"minage","word_nosc":"minage","lemma":"minage","pos":"NOM"} ,
		{"word":"minaret","word_nosc":"minaret","lemma":"minaret","pos":"NOM"} ,
		{"word":"minarets","word_nosc":"minarets","lemma":"minaret","pos":"NOM"} ,
		{"word":"minauderie","word_nosc":"minauderie","lemma":"minauderie","pos":"NOM"} ,
		{"word":"minauderies","word_nosc":"minauderies","lemma":"minauderie","pos":"NOM"} ,
		{"word":"minaudière","word_nosc":"minaudiere","lemma":"minaudière","pos":"NOM"} ,
		{"word":"minbar","word_nosc":"minbar","lemma":"minbar","pos":"NOM"} ,
		{"word":"minceur","word_nosc":"minceur","lemma":"minceur","pos":"NOM"} ,
		{"word":"minceurs","word_nosc":"minceurs","lemma":"minceur","pos":"NOM"} ,
		{"word":"mine","word_nosc":"mine","lemma":"mine","pos":"NOM"} ,
		{"word":"minerai","word_nosc":"minerai","lemma":"minerai","pos":"NOM"} ,
		{"word":"minerais","word_nosc":"minerais","lemma":"minerai","pos":"NOM"} ,
		{"word":"minerval","word_nosc":"minerval","lemma":"minerval","pos":"NOM"} ,
		{"word":"minerve","word_nosc":"minerve","lemma":"minerve","pos":"NOM"} ,
		{"word":"mines","word_nosc":"mines","lemma":"mine","pos":"NOM"} ,
		{"word":"minestrone","word_nosc":"minestrone","lemma":"minestrone","pos":"NOM"} ,
		{"word":"minet","word_nosc":"minet","lemma":"minet","pos":"NOM"} ,
		{"word":"minets","word_nosc":"minets","lemma":"minet","pos":"NOM"} ,
		{"word":"minette","word_nosc":"minette","lemma":"minet","pos":"NOM"} ,
		{"word":"minettes","word_nosc":"minettes","lemma":"minet","pos":"NOM"} ,
		{"word":"mineur","word_nosc":"mineur","lemma":"mineur","pos":"NOM"} ,
		{"word":"mineure","word_nosc":"mineure","lemma":"mineur","pos":"NOM"} ,
		{"word":"mineures","word_nosc":"mineures","lemma":"mineur","pos":"NOM"} ,
		{"word":"mineurs","word_nosc":"mineurs","lemma":"mineur","pos":"NOM"} ,
		{"word":"mini","word_nosc":"mini","lemma":"mini","pos":"NOM"} ,
		{"word":"mini-chaîne","word_nosc":"mini-chaine","lemma":"mini-chaîne","pos":"NOM"} ,
		{"word":"mini-jupe","word_nosc":"mini-jupe","lemma":"mini-jupe","pos":"NOM"} ,
		{"word":"mini-jupes","word_nosc":"mini-jupes","lemma":"mini-jupe","pos":"NOM"} ,
		{"word":"mini-ordinateur","word_nosc":"mini-ordinateur","lemma":"mini-ordinateur","pos":"NOM"} ,
		{"word":"miniature","word_nosc":"miniature","lemma":"miniature","pos":"NOM"} ,
		{"word":"miniatures","word_nosc":"miniatures","lemma":"miniature","pos":"NOM"} ,
		{"word":"miniaturisation","word_nosc":"miniaturisation","lemma":"miniaturisation","pos":"NOM"} ,
		{"word":"miniaturiste","word_nosc":"miniaturiste","lemma":"miniaturiste","pos":"NOM"} ,
		{"word":"minibar","word_nosc":"minibar","lemma":"minibar","pos":"NOM"} ,
		{"word":"minibus","word_nosc":"minibus","lemma":"minibus","pos":"NOM"} ,
		{"word":"minicassette","word_nosc":"minicassette","lemma":"minicassette","pos":"NOM"} ,
		{"word":"minicassettes","word_nosc":"minicassettes","lemma":"minicassette","pos":"NOM"} ,
		{"word":"minigolf","word_nosc":"minigolf","lemma":"minigolf","pos":"NOM"} ,
		{"word":"minijupe","word_nosc":"minijupe","lemma":"minijupe","pos":"NOM"} ,
		{"word":"minijupes","word_nosc":"minijupes","lemma":"minijupe","pos":"NOM"} ,
		{"word":"minima","word_nosc":"minima","lemma":"minimum","pos":"NOM"} ,
		{"word":"minimalisme","word_nosc":"minimalisme","lemma":"minimalisme","pos":"NOM"} ,
		{"word":"minimax","word_nosc":"minimax","lemma":"minimax","pos":"NOM"} ,
		{"word":"minime","word_nosc":"minime","lemma":"minime","pos":"NOM"} ,
		{"word":"minimes","word_nosc":"minimes","lemma":"minime","pos":"NOM"} ,
		{"word":"minimisation","word_nosc":"minimisation","lemma":"minimisation","pos":"NOM"} ,
		{"word":"minimum","word_nosc":"minimum","lemma":"minimum","pos":"NOM"} ,
		{"word":"minis","word_nosc":"minis","lemma":"mini","pos":"NOM"} ,
		{"word":"ministre","word_nosc":"ministre","lemma":"ministre","pos":"NOM"} ,
		{"word":"ministres","word_nosc":"ministres","lemma":"ministre","pos":"NOM"} ,
		{"word":"ministère","word_nosc":"ministere","lemma":"ministère","pos":"NOM"} ,
		{"word":"ministères","word_nosc":"ministeres","lemma":"ministère","pos":"NOM"} ,
		{"word":"minitel","word_nosc":"minitel","lemma":"minitel","pos":"NOM"} ,
		{"word":"minium","word_nosc":"minium","lemma":"minium","pos":"NOM"} ,
		{"word":"minoen","word_nosc":"minoen","lemma":"minoen","pos":"NOM"} ,
		{"word":"minoens","word_nosc":"minoens","lemma":"minoen","pos":"NOM"} ,
		{"word":"minois","word_nosc":"minois","lemma":"minois","pos":"NOM"} ,
		{"word":"minorité","word_nosc":"minorite","lemma":"minorité","pos":"NOM"} ,
		{"word":"minorités","word_nosc":"minorites","lemma":"minorité","pos":"NOM"} ,
		{"word":"minot","word_nosc":"minot","lemma":"minot","pos":"NOM"} ,
		{"word":"minotaure","word_nosc":"minotaure","lemma":"minotaure","pos":"NOM"} ,
		{"word":"minotaures","word_nosc":"minotaures","lemma":"minotaure","pos":"NOM"} ,
		{"word":"minoteries","word_nosc":"minoteries","lemma":"minoterie","pos":"NOM"} ,
		{"word":"minotier","word_nosc":"minotier","lemma":"minotier","pos":"NOM"} ,
		{"word":"minots","word_nosc":"minots","lemma":"minot","pos":"NOM"} ,
		{"word":"minou","word_nosc":"minou","lemma":"minou","pos":"NOM"} ,
		{"word":"minous","word_nosc":"minous","lemma":"minou","pos":"NOM"} ,
		{"word":"minuit","word_nosc":"minuit","lemma":"minuit","pos":"NOM"} ,
		{"word":"minuits","word_nosc":"minuits","lemma":"minuit","pos":"NOM"} ,
		{"word":"minus","word_nosc":"minus","lemma":"minus","pos":"NOM"} ,
		{"word":"minus habens","word_nosc":"minus habens","lemma":"minus habens","pos":"NOM"} ,
		{"word":"minuscule","word_nosc":"minuscule","lemma":"minuscule","pos":"NOM"} ,
		{"word":"minuscules","word_nosc":"minuscules","lemma":"minuscule","pos":"NOM"} ,
		{"word":"minutage","word_nosc":"minutage","lemma":"minutage","pos":"NOM"} ,
		{"word":"minute","word_nosc":"minute","lemma":"minute","pos":"NOM"} ,
		{"word":"minuterie","word_nosc":"minuterie","lemma":"minuterie","pos":"NOM"} ,
		{"word":"minuteries","word_nosc":"minuteries","lemma":"minuterie","pos":"NOM"} ,
		{"word":"minutes","word_nosc":"minutes","lemma":"minute","pos":"NOM"} ,
		{"word":"minuteur","word_nosc":"minuteur","lemma":"minuteur","pos":"NOM"} ,
		{"word":"minuteurs","word_nosc":"minuteurs","lemma":"minuteur","pos":"NOM"} ,
		{"word":"minutie","word_nosc":"minutie","lemma":"minutie","pos":"NOM"} ,
		{"word":"minuties","word_nosc":"minuties","lemma":"minutie","pos":"NOM"} ,
		{"word":"minéral","word_nosc":"mineral","lemma":"minéral","pos":"NOM"} ,
		{"word":"minéraliers","word_nosc":"mineraliers","lemma":"minéralier","pos":"NOM"} ,
		{"word":"minéralisation","word_nosc":"mineralisation","lemma":"minéralisation","pos":"NOM"} ,
		{"word":"minéraux","word_nosc":"mineraux","lemma":"minéral","pos":"NOM"} ,
		{"word":"mioche","word_nosc":"mioche","lemma":"mioche","pos":"NOM"} ,
		{"word":"mioches","word_nosc":"mioches","lemma":"mioche","pos":"NOM"} ,
		{"word":"miquette","word_nosc":"miquette","lemma":"miquette","pos":"NOM"} ,
		{"word":"mir","word_nosc":"mir","lemma":"mir","pos":"NOM"} ,
		{"word":"mirabelle","word_nosc":"mirabelle","lemma":"mirabelle","pos":"NOM"} ,
		{"word":"mirabelles","word_nosc":"mirabelles","lemma":"mirabelle","pos":"NOM"} ,
		{"word":"mirabellier","word_nosc":"mirabellier","lemma":"mirabellier","pos":"NOM"} ,
		{"word":"mirabelliers","word_nosc":"mirabelliers","lemma":"mirabellier","pos":"NOM"} ,
		{"word":"mirabilis","word_nosc":"mirabilis","lemma":"mirabilis","pos":"NOM"} ,
		{"word":"miracle","word_nosc":"miracle","lemma":"miracle","pos":"NOM"} ,
		{"word":"miracles","word_nosc":"miracles","lemma":"miracle","pos":"NOM"} ,
		{"word":"miraculé","word_nosc":"miracule","lemma":"miraculé","pos":"NOM"} ,
		{"word":"miraculée","word_nosc":"miraculee","lemma":"miraculé","pos":"NOM"} ,
		{"word":"miraculés","word_nosc":"miracules","lemma":"miraculé","pos":"NOM"} ,
		{"word":"mirador","word_nosc":"mirador","lemma":"mirador","pos":"NOM"} ,
		{"word":"miradors","word_nosc":"miradors","lemma":"mirador","pos":"NOM"} ,
		{"word":"mirage","word_nosc":"mirage","lemma":"mirage","pos":"NOM"} ,
		{"word":"mirages","word_nosc":"mirages","lemma":"mirage","pos":"NOM"} ,
		{"word":"mire","word_nosc":"mire","lemma":"mire","pos":"NOM"} ,
		{"word":"mirepoix","word_nosc":"mirepoix","lemma":"mirepoix","pos":"NOM"} ,
		{"word":"mires","word_nosc":"mires","lemma":"mire","pos":"NOM"} ,
		{"word":"mirette","word_nosc":"mirette","lemma":"mirette","pos":"NOM"} ,
		{"word":"mirettes","word_nosc":"mirettes","lemma":"mirette","pos":"NOM"} ,
		{"word":"mirliflore","word_nosc":"mirliflore","lemma":"mirliflore","pos":"NOM"} ,
		{"word":"mirliton","word_nosc":"mirliton","lemma":"mirliton","pos":"NOM"} ,
		{"word":"mirlitons","word_nosc":"mirlitons","lemma":"mirliton","pos":"NOM"} ,
		{"word":"mirmidon","word_nosc":"mirmidon","lemma":"mirmidon","pos":"NOM"} ,
		{"word":"miro","word_nosc":"miro","lemma":"miro","pos":"NOM"} ,
		{"word":"miroir","word_nosc":"miroir","lemma":"miroir","pos":"NOM"} ,
		{"word":"miroirs","word_nosc":"miroirs","lemma":"miroir","pos":"NOM"} ,
		{"word":"miroitement","word_nosc":"miroitement","lemma":"miroitement","pos":"NOM"} ,
		{"word":"miroitements","word_nosc":"miroitements","lemma":"miroitement","pos":"NOM"} ,
		{"word":"miroiterie","word_nosc":"miroiterie","lemma":"miroiterie","pos":"NOM"} ,
		{"word":"miroitier","word_nosc":"miroitier","lemma":"miroitier","pos":"NOM"} ,
		{"word":"miroitiers","word_nosc":"miroitiers","lemma":"miroitier","pos":"NOM"} ,
		{"word":"mironton","word_nosc":"mironton","lemma":"mironton","pos":"NOM"} ,
		{"word":"mirontons","word_nosc":"mirontons","lemma":"mironton","pos":"NOM"} ,
		{"word":"miroton","word_nosc":"miroton","lemma":"miroton","pos":"NOM"} ,
		{"word":"mirus","word_nosc":"mirus","lemma":"mirus","pos":"NOM"} ,
		{"word":"misaine","word_nosc":"misaine","lemma":"misaine","pos":"NOM"} ,
		{"word":"misaines","word_nosc":"misaines","lemma":"misaine","pos":"NOM"} ,
		{"word":"misanthrope","word_nosc":"misanthrope","lemma":"misanthrope","pos":"NOM"} ,
		{"word":"misanthropie","word_nosc":"misanthropie","lemma":"misanthropie","pos":"NOM"} ,
		{"word":"mise","word_nosc":"mise","lemma":"mise","pos":"NOM"} ,
		{"word":"miserere","word_nosc":"miserere","lemma":"miserere","pos":"NOM"} ,
		{"word":"mises","word_nosc":"mises","lemma":"mise","pos":"NOM"} ,
		{"word":"misogyne","word_nosc":"misogyne","lemma":"misogyne","pos":"NOM"} ,
		{"word":"misogynes","word_nosc":"misogynes","lemma":"misogyne","pos":"NOM"} ,
		{"word":"misogynie","word_nosc":"misogynie","lemma":"misogynie","pos":"NOM"} ,
		{"word":"miss","word_nosc":"miss","lemma":"miss","pos":"NOM"} ,
		{"word":"missel","word_nosc":"missel","lemma":"missel","pos":"NOM"} ,
		{"word":"missels","word_nosc":"missels","lemma":"missel","pos":"NOM"} ,
		{"word":"missile","word_nosc":"missile","lemma":"missile","pos":"NOM"} ,
		{"word":"missiles","word_nosc":"missiles","lemma":"missile","pos":"NOM"} ,
		{"word":"missilier","word_nosc":"missilier","lemma":"missilier","pos":"NOM"} ,
		{"word":"mission","word_nosc":"mission","lemma":"mission","pos":"NOM"} ,
		{"word":"mission-suicide","word_nosc":"mission-suicide","lemma":"mission-suicide","pos":"NOM"} ,
		{"word":"missionnaire","word_nosc":"missionnaire","lemma":"missionnaire","pos":"NOM"} ,
		{"word":"missionnaires","word_nosc":"missionnaires","lemma":"missionnaire","pos":"NOM"} ,
		{"word":"missions","word_nosc":"missions","lemma":"mission","pos":"NOM"} ,
		{"word":"missive","word_nosc":"missive","lemma":"missive","pos":"NOM"} ,
		{"word":"missives","word_nosc":"missives","lemma":"missive","pos":"NOM"} ,
		{"word":"mister","word_nosc":"mister","lemma":"mister","pos":"NOM"} ,
		{"word":"mistigri","word_nosc":"mistigri","lemma":"mistigri","pos":"NOM"} ,
		{"word":"mistigris","word_nosc":"mistigris","lemma":"mistigri","pos":"NOM"} ,
		{"word":"mistonne","word_nosc":"mistonne","lemma":"miston","pos":"NOM"} ,
		{"word":"mistonnes","word_nosc":"mistonnes","lemma":"miston","pos":"NOM"} ,
		{"word":"mistoufle","word_nosc":"mistoufle","lemma":"mistoufle","pos":"NOM"} ,
		{"word":"mistoufles","word_nosc":"mistoufles","lemma":"mistoufle","pos":"NOM"} ,
		{"word":"mistral","word_nosc":"mistral","lemma":"mistral","pos":"NOM"} ,
		{"word":"mistrals","word_nosc":"mistrals","lemma":"mistral","pos":"NOM"} ,
		{"word":"misère","word_nosc":"misere","lemma":"misère","pos":"NOM"} ,
		{"word":"misères","word_nosc":"miseres","lemma":"misère","pos":"NOM"} ,
		{"word":"misérabilisme","word_nosc":"miserabilisme","lemma":"misérabilisme","pos":"NOM"} ,
		{"word":"misérable","word_nosc":"miserable","lemma":"misérable","pos":"NOM"} ,
		{"word":"misérables","word_nosc":"miserables","lemma":"misérable","pos":"NOM"} ,
		{"word":"miséreuse","word_nosc":"misereuse","lemma":"miséreux","pos":"NOM"} ,
		{"word":"miséreuses","word_nosc":"misereuses","lemma":"miséreux","pos":"NOM"} ,
		{"word":"miséreux","word_nosc":"misereux","lemma":"miséreux","pos":"NOM"} ,
		{"word":"miséricorde","word_nosc":"misericorde","lemma":"miséricorde","pos":"NOM"} ,
		{"word":"miséricordes","word_nosc":"misericordes","lemma":"miséricorde","pos":"NOM"} ,
		{"word":"mita","word_nosc":"mita","lemma":"mita","pos":"NOM"} ,
		{"word":"mitaine","word_nosc":"mitaine","lemma":"mitaine","pos":"NOM"} ,
		{"word":"mitaines","word_nosc":"mitaines","lemma":"mitaine","pos":"NOM"} ,
		{"word":"mitan","word_nosc":"mitan","lemma":"mitan","pos":"NOM"} ,
		{"word":"mitard","word_nosc":"mitard","lemma":"mitard","pos":"NOM"} ,
		{"word":"mitards","word_nosc":"mitards","lemma":"mitard","pos":"NOM"} ,
		{"word":"mite","word_nosc":"mite","lemma":"mite","pos":"NOM"} ,
		{"word":"mites","word_nosc":"mites","lemma":"mite","pos":"NOM"} ,
		{"word":"miteuse","word_nosc":"miteuse","lemma":"miteux","pos":"NOM"} ,
		{"word":"miteuserie","word_nosc":"miteuserie","lemma":"miteuserie","pos":"NOM"} ,
		{"word":"miteux","word_nosc":"miteux","lemma":"miteux","pos":"NOM"} ,
		{"word":"mithridatisation","word_nosc":"mithridatisation","lemma":"mithridatisation","pos":"NOM"} ,
		{"word":"mitigeurs","word_nosc":"mitigeurs","lemma":"mitigeur","pos":"NOM"} ,
		{"word":"mitochondrie","word_nosc":"mitochondrie","lemma":"mitochondrie","pos":"NOM"} ,
		{"word":"mitochondries","word_nosc":"mitochondries","lemma":"mitochondrie","pos":"NOM"} ,
		{"word":"miton","word_nosc":"miton","lemma":"miton","pos":"NOM"} ,
		{"word":"mitose","word_nosc":"mitose","lemma":"mitose","pos":"NOM"} ,
		{"word":"mitoufle","word_nosc":"mitoufle","lemma":"mitoufle","pos":"NOM"} ,
		{"word":"mitoyenneté","word_nosc":"mitoyennete","lemma":"mitoyenneté","pos":"NOM"} ,
		{"word":"mitraillade","word_nosc":"mitraillade","lemma":"mitraillade","pos":"NOM"} ,
		{"word":"mitraillades","word_nosc":"mitraillades","lemma":"mitraillade","pos":"NOM"} ,
		{"word":"mitraillage","word_nosc":"mitraillage","lemma":"mitraillage","pos":"NOM"} ,
		{"word":"mitraillages","word_nosc":"mitraillages","lemma":"mitraillage","pos":"NOM"} ,
		{"word":"mitraille","word_nosc":"mitraille","lemma":"mitraille","pos":"NOM"} ,
		{"word":"mitrailles","word_nosc":"mitrailles","lemma":"mitraille","pos":"NOM"} ,
		{"word":"mitraillette","word_nosc":"mitraillette","lemma":"mitraillette","pos":"NOM"} ,
		{"word":"mitraillettes","word_nosc":"mitraillettes","lemma":"mitraillette","pos":"NOM"} ,
		{"word":"mitrailleur","word_nosc":"mitrailleur","lemma":"mitrailleur","pos":"NOM"} ,
		{"word":"mitrailleurs","word_nosc":"mitrailleurs","lemma":"mitrailleur","pos":"NOM"} ,
		{"word":"mitrailleuse","word_nosc":"mitrailleuse","lemma":"mitrailleur","pos":"NOM"} ,
		{"word":"mitrailleuses","word_nosc":"mitrailleuses","lemma":"mitrailleur","pos":"NOM"} ,
		{"word":"mitre","word_nosc":"mitre","lemma":"mitre","pos":"NOM"} ,
		{"word":"mitres","word_nosc":"mitres","lemma":"mitre","pos":"NOM"} ,
		{"word":"mitron","word_nosc":"mitron","lemma":"mitron","pos":"NOM"} ,
		{"word":"mitrons","word_nosc":"mitrons","lemma":"mitron","pos":"NOM"} ,
		{"word":"mixage","word_nosc":"mixage","lemma":"mixage","pos":"NOM"} ,
		{"word":"mixer","word_nosc":"mixer","lemma":"mixer","pos":"NOM"} ,
		{"word":"mixers","word_nosc":"mixers","lemma":"mixer","pos":"NOM"} ,
		{"word":"mixeur","word_nosc":"mixeur","lemma":"mixeur","pos":"NOM"} ,
		{"word":"mixeurs","word_nosc":"mixeurs","lemma":"mixeur","pos":"NOM"} ,
		{"word":"mixité","word_nosc":"mixite","lemma":"mixité","pos":"NOM"} ,
		{"word":"mixture","word_nosc":"mixture","lemma":"mixture","pos":"NOM"} ,
		{"word":"mixtures","word_nosc":"mixtures","lemma":"mixture","pos":"NOM"} ,
		{"word":"mièvrerie","word_nosc":"mievrerie","lemma":"mièvrerie","pos":"NOM"} ,
		{"word":"mièvreries","word_nosc":"mievreries","lemma":"mièvrerie","pos":"NOM"} ,
		{"word":"ml","word_nosc":"ml","lemma":"ml","pos":"NOM"} ,
		{"word":"mlle","word_nosc":"mlle","lemma":"mlle","pos":"NOM"} ,
		{"word":"mm","word_nosc":"mm","lemma":"mm","pos":"NOM"} ,
		{"word":"mme","word_nosc":"mme","lemma":"mme","pos":"NOM"} ,
		{"word":"mn","word_nosc":"mn","lemma":"mn","pos":"NOM"} ,
		{"word":"mnémotechnie","word_nosc":"mnemotechnie","lemma":"mnémotechnie","pos":"NOM"} ,
		{"word":"mnémotechnique","word_nosc":"mnemotechnique","lemma":"mnémotechnique","pos":"NOM"} ,
		{"word":"mob","word_nosc":"mob","lemma":"mob","pos":"NOM"} ,
		{"word":"mobil-home","word_nosc":"mobil-home","lemma":"mobil-home","pos":"NOM"} ,
		{"word":"mobile","word_nosc":"mobile","lemma":"mobile","pos":"NOM"} ,
		{"word":"mobiles","word_nosc":"mobiles","lemma":"mobile","pos":"NOM"} ,
		{"word":"mobilier","word_nosc":"mobilier","lemma":"mobilier","pos":"NOM"} ,
		{"word":"mobiliers","word_nosc":"mobiliers","lemma":"mobilier","pos":"NOM"} ,
		{"word":"mobilisation","word_nosc":"mobilisation","lemma":"mobilisation","pos":"NOM"} ,
		{"word":"mobilité","word_nosc":"mobilite","lemma":"mobilité","pos":"NOM"} ,
		{"word":"mobylette","word_nosc":"mobylette","lemma":"mobylette","pos":"NOM"} ,
		{"word":"mobylettes","word_nosc":"mobylettes","lemma":"mobylette","pos":"NOM"} ,
		{"word":"mocassin","word_nosc":"mocassin","lemma":"mocassin","pos":"NOM"} ,
		{"word":"mocassins","word_nosc":"mocassins","lemma":"mocassin","pos":"NOM"} ,
		{"word":"mocheté","word_nosc":"mochete","lemma":"mocheté","pos":"NOM"} ,
		{"word":"mochetés","word_nosc":"mochetes","lemma":"mocheté","pos":"NOM"} ,
		{"word":"moco","word_nosc":"moco","lemma":"moco","pos":"NOM"} ,
		{"word":"mod","word_nosc":"mod","lemma":"mod","pos":"NOM"} ,
		{"word":"modalité","word_nosc":"modalite","lemma":"modalité","pos":"NOM"} ,
		{"word":"modalités","word_nosc":"modalites","lemma":"modalité","pos":"NOM"} ,
		{"word":"mode","word_nosc":"mode","lemma":"mode","pos":"NOM"} ,
		{"word":"model","word_nosc":"model","lemma":"model","pos":"NOM"} ,
		{"word":"modelage","word_nosc":"modelage","lemma":"modelage","pos":"NOM"} ,
		{"word":"modeleur","word_nosc":"modeleur","lemma":"modeleur","pos":"NOM"} ,
		{"word":"modelé","word_nosc":"modele","lemma":"modelé","pos":"NOM"} ,
		{"word":"modelés","word_nosc":"modeles","lemma":"modelé","pos":"NOM"} ,
		{"word":"modem","word_nosc":"modem","lemma":"modem","pos":"NOM"} ,
		{"word":"modems","word_nosc":"modems","lemma":"modem","pos":"NOM"} ,
		{"word":"modern style","word_nosc":"modern style","lemma":"modern style","pos":"NOM"} ,
		{"word":"modern-style","word_nosc":"modern-style","lemma":"modern-style","pos":"NOM"} ,
		{"word":"moderne","word_nosc":"moderne","lemma":"moderne","pos":"NOM"} ,
		{"word":"modernes","word_nosc":"modernes","lemma":"moderne","pos":"NOM"} ,
		{"word":"modernisation","word_nosc":"modernisation","lemma":"modernisation","pos":"NOM"} ,
		{"word":"modernisations","word_nosc":"modernisations","lemma":"modernisation","pos":"NOM"} ,
		{"word":"modernisme","word_nosc":"modernisme","lemma":"modernisme","pos":"NOM"} ,
		{"word":"moderniste","word_nosc":"moderniste","lemma":"moderniste","pos":"NOM"} ,
		{"word":"modernistes","word_nosc":"modernistes","lemma":"moderniste","pos":"NOM"} ,
		{"word":"modernité","word_nosc":"modernite","lemma":"modernité","pos":"NOM"} ,
		{"word":"modernités","word_nosc":"modernites","lemma":"modernité","pos":"NOM"} ,
		{"word":"modes","word_nosc":"modes","lemma":"mode","pos":"NOM"} ,
		{"word":"modestie","word_nosc":"modestie","lemma":"modestie","pos":"NOM"} ,
		{"word":"modesties","word_nosc":"modesties","lemma":"modestie","pos":"NOM"} ,
		{"word":"modicité","word_nosc":"modicite","lemma":"modicité","pos":"NOM"} ,
		{"word":"modification","word_nosc":"modification","lemma":"modification","pos":"NOM"} ,
		{"word":"modifications","word_nosc":"modifications","lemma":"modification","pos":"NOM"} ,
		{"word":"modillon","word_nosc":"modillon","lemma":"modillon","pos":"NOM"} ,
		{"word":"modillons","word_nosc":"modillons","lemma":"modillon","pos":"NOM"} ,
		{"word":"modiste","word_nosc":"modiste","lemma":"modiste","pos":"NOM"} ,
		{"word":"modistes","word_nosc":"modistes","lemma":"modiste","pos":"NOM"} ,
		{"word":"modulation","word_nosc":"modulation","lemma":"modulation","pos":"NOM"} ,
		{"word":"modulations","word_nosc":"modulations","lemma":"modulation","pos":"NOM"} ,
		{"word":"module","word_nosc":"module","lemma":"module","pos":"NOM"} ,
		{"word":"modules","word_nosc":"modules","lemma":"module","pos":"NOM"} ,
		{"word":"modus operandi","word_nosc":"modus operandi","lemma":"modus operandi","pos":"NOM"} ,
		{"word":"modus vivendi","word_nosc":"modus vivendi","lemma":"modus vivendi","pos":"NOM"} ,
		{"word":"modèle","word_nosc":"modele","lemma":"modèle","pos":"NOM"} ,
		{"word":"modèles","word_nosc":"modeles","lemma":"modèle","pos":"NOM"} ,
		{"word":"modélisation","word_nosc":"modelisation","lemma":"modélisation","pos":"NOM"} ,
		{"word":"modélisme","word_nosc":"modelisme","lemma":"modélisme","pos":"NOM"} ,
		{"word":"modéliste","word_nosc":"modeliste","lemma":"modéliste","pos":"NOM"} ,
		{"word":"modérateur","word_nosc":"moderateur","lemma":"modérateur","pos":"NOM"} ,
		{"word":"modérateurs","word_nosc":"moderateurs","lemma":"modérateur","pos":"NOM"} ,
		{"word":"modération","word_nosc":"moderation","lemma":"modération","pos":"NOM"} ,
		{"word":"modérations","word_nosc":"moderations","lemma":"modération","pos":"NOM"} ,
		{"word":"modéré","word_nosc":"modere","lemma":"modéré","pos":"NOM"} ,
		{"word":"modérée","word_nosc":"moderee","lemma":"modéré","pos":"NOM"} ,
		{"word":"modérés","word_nosc":"moderes","lemma":"modéré","pos":"NOM"} ,
		{"word":"moelle","word_nosc":"moelle","lemma":"moelle","pos":"NOM"} ,
		{"word":"moelles","word_nosc":"moelles","lemma":"moelle","pos":"NOM"} ,
		{"word":"moellon","word_nosc":"moellon","lemma":"moellon","pos":"NOM"} ,
		{"word":"moellons","word_nosc":"moellons","lemma":"moellon","pos":"NOM"} ,
		{"word":"moeurs","word_nosc":"moeurs","lemma":"moeurs","pos":"NOM"} ,
		{"word":"moghol","word_nosc":"moghol","lemma":"moghol","pos":"NOM"} ,
		{"word":"mohair","word_nosc":"mohair","lemma":"mohair","pos":"NOM"} ,
		{"word":"mohairs","word_nosc":"mohairs","lemma":"mohair","pos":"NOM"} ,
		{"word":"mohican","word_nosc":"mohican","lemma":"mohican","pos":"NOM"} ,
		{"word":"moho","word_nosc":"moho","lemma":"moho","pos":"NOM"} ,
		{"word":"moi","word_nosc":"moi","lemma":"moi","pos":"NOM"} ,
		{"word":"moi-moi-moi","word_nosc":"moi-moi-moi","lemma":"moi-moi-moi","pos":"NOM"} ,
		{"word":"moignon","word_nosc":"moignon","lemma":"moignon","pos":"NOM"} ,
		{"word":"moignons","word_nosc":"moignons","lemma":"moignon","pos":"NOM"} ,
		{"word":"moine","word_nosc":"moine","lemma":"moine","pos":"NOM"} ,
		{"word":"moineau","word_nosc":"moineau","lemma":"moineau","pos":"NOM"} ,
		{"word":"moineaux","word_nosc":"moineaux","lemma":"moineau","pos":"NOM"} ,
		{"word":"moines","word_nosc":"moines","lemma":"moine","pos":"NOM"} ,
		{"word":"moinillon","word_nosc":"moinillon","lemma":"moinillon","pos":"NOM"} ,
		{"word":"moinillons","word_nosc":"moinillons","lemma":"moinillon","pos":"NOM"} ,
		{"word":"moins","word_nosc":"moins","lemma":"moins","pos":"NOM"} ,
		{"word":"moire","word_nosc":"moire","lemma":"moire","pos":"NOM"} ,
		{"word":"moires","word_nosc":"moires","lemma":"moire","pos":"NOM"} ,
		{"word":"moirure","word_nosc":"moirure","lemma":"moirure","pos":"NOM"} ,
		{"word":"moirures","word_nosc":"moirures","lemma":"moirure","pos":"NOM"} ,
		{"word":"moiré","word_nosc":"moire","lemma":"moiré","pos":"NOM"} ,
		{"word":"moirés","word_nosc":"moires","lemma":"moiré","pos":"NOM"} ,
		{"word":"mois","word_nosc":"mois","lemma":"mois","pos":"NOM"} ,
		{"word":"moise","word_nosc":"moise","lemma":"moise","pos":"NOM"} ,
		{"word":"moises","word_nosc":"moises","lemma":"moise","pos":"NOM"} ,
		{"word":"moisi","word_nosc":"moisi","lemma":"moisi","pos":"NOM"} ,
		{"word":"moisis","word_nosc":"moisis","lemma":"moisi","pos":"NOM"} ,
		{"word":"moisissure","word_nosc":"moisissure","lemma":"moisissure","pos":"NOM"} ,
		{"word":"moisissures","word_nosc":"moisissures","lemma":"moisissure","pos":"NOM"} ,
		{"word":"moisson","word_nosc":"moisson","lemma":"moisson","pos":"NOM"} ,
		{"word":"moissonneur","word_nosc":"moissonneur","lemma":"moissonneur","pos":"NOM"} ,
		{"word":"moissonneurs","word_nosc":"moissonneurs","lemma":"moissonneur","pos":"NOM"} ,
		{"word":"moissonneuse","word_nosc":"moissonneuse","lemma":"moissonneur","pos":"NOM"} ,
		{"word":"moissonneuse-batteuse","word_nosc":"moissonneuse-batteuse","lemma":"moissonneuse-batteuse","pos":"NOM"} ,
		{"word":"moissonneuse-lieuse","word_nosc":"moissonneuse-lieuse","lemma":"moissonneuse-lieuse","pos":"NOM"} ,
		{"word":"moissonneuses","word_nosc":"moissonneuses","lemma":"moissonneur","pos":"NOM"} ,
		{"word":"moissonneuses-batteuses","word_nosc":"moissonneuses-batteuses","lemma":"moissonneuse-batteuse","pos":"NOM"} ,
		{"word":"moissonneuses-lieuses","word_nosc":"moissonneuses-lieuses","lemma":"moissonneuse-lieuse","pos":"NOM"} ,
		{"word":"moissons","word_nosc":"moissons","lemma":"moisson","pos":"NOM"} ,
		{"word":"moiteur","word_nosc":"moiteur","lemma":"moiteur","pos":"NOM"} ,
		{"word":"moiteurs","word_nosc":"moiteurs","lemma":"moiteur","pos":"NOM"} ,
		{"word":"moitié","word_nosc":"moitie","lemma":"moitié","pos":"NOM"} ,
		{"word":"moitiés","word_nosc":"moities","lemma":"moitié","pos":"NOM"} ,
		{"word":"moka","word_nosc":"moka","lemma":"moka","pos":"NOM"} ,
		{"word":"mokas","word_nosc":"mokas","lemma":"moka","pos":"NOM"} ,
		{"word":"moko","word_nosc":"moko","lemma":"moko","pos":"NOM"} ,
		{"word":"molaire","word_nosc":"molaire","lemma":"molaire","pos":"NOM"} ,
		{"word":"molaires","word_nosc":"molaires","lemma":"molaire","pos":"NOM"} ,
		{"word":"molard","word_nosc":"molard","lemma":"molard","pos":"NOM"} ,
		{"word":"molasse","word_nosc":"molasse","lemma":"molasse","pos":"NOM"} ,
		{"word":"moldave","word_nosc":"moldave","lemma":"moldave","pos":"NOM"} ,
		{"word":"mole","word_nosc":"mole","lemma":"mole","pos":"NOM"} ,
		{"word":"moles","word_nosc":"moles","lemma":"mole","pos":"NOM"} ,
		{"word":"moleskine","word_nosc":"moleskine","lemma":"moleskine","pos":"NOM"} ,
		{"word":"molesquine","word_nosc":"molesquine","lemma":"molesquine","pos":"NOM"} ,
		{"word":"molestation","word_nosc":"molestation","lemma":"molestation","pos":"NOM"} ,
		{"word":"molette","word_nosc":"molette","lemma":"molette","pos":"NOM"} ,
		{"word":"molettes","word_nosc":"molettes","lemma":"molette","pos":"NOM"} ,
		{"word":"mollah","word_nosc":"mollah","lemma":"mollah","pos":"NOM"} ,
		{"word":"mollahs","word_nosc":"mollahs","lemma":"mollah","pos":"NOM"} ,
		{"word":"mollard","word_nosc":"mollard","lemma":"mollard","pos":"NOM"} ,
		{"word":"mollards","word_nosc":"mollards","lemma":"mollard","pos":"NOM"} ,
		{"word":"mollasson","word_nosc":"mollasson","lemma":"mollasson","pos":"NOM"} ,
		{"word":"mollassonne","word_nosc":"mollassonne","lemma":"mollasson","pos":"NOM"} ,
		{"word":"mollassons","word_nosc":"mollassons","lemma":"mollasson","pos":"NOM"} ,
		{"word":"mollesse","word_nosc":"mollesse","lemma":"mollesse","pos":"NOM"} ,
		{"word":"mollesses","word_nosc":"mollesses","lemma":"mollesse","pos":"NOM"} ,
		{"word":"mollet","word_nosc":"mollet","lemma":"mollet","pos":"NOM"} ,
		{"word":"molletière","word_nosc":"molletiere","lemma":"molletière","pos":"NOM"} ,
		{"word":"molletières","word_nosc":"molletieres","lemma":"molletière","pos":"NOM"} ,
		{"word":"molleton","word_nosc":"molleton","lemma":"molleton","pos":"NOM"} ,
		{"word":"molletons","word_nosc":"molletons","lemma":"molleton","pos":"NOM"} ,
		{"word":"mollets","word_nosc":"mollets","lemma":"mollet","pos":"NOM"} ,
		{"word":"mollusque","word_nosc":"mollusque","lemma":"mollusque","pos":"NOM"} ,
		{"word":"mollusques","word_nosc":"mollusques","lemma":"mollusque","pos":"NOM"} ,
		{"word":"moloch","word_nosc":"moloch","lemma":"moloch","pos":"NOM"} ,
		{"word":"molosse","word_nosc":"molosse","lemma":"molosse","pos":"NOM"} ,
		{"word":"molosses","word_nosc":"molosses","lemma":"molosse","pos":"NOM"} ,
		{"word":"moly","word_nosc":"moly","lemma":"moly","pos":"NOM"} ,
		{"word":"molybdène","word_nosc":"molybdene","lemma":"molybdène","pos":"NOM"} ,
		{"word":"molènes","word_nosc":"molenes","lemma":"molène","pos":"NOM"} ,
		{"word":"molécule","word_nosc":"molecule","lemma":"molécule","pos":"NOM"} ,
		{"word":"molécules","word_nosc":"molecules","lemma":"molécule","pos":"NOM"} ,
		{"word":"moman","word_nosc":"moman","lemma":"moman","pos":"NOM"} ,
		{"word":"mombin","word_nosc":"mombin","lemma":"mombin","pos":"NOM"} ,
		{"word":"moment","word_nosc":"moment","lemma":"moment","pos":"NOM"} ,
		{"word":"moment-clé","word_nosc":"moment-cle","lemma":"moment-clé","pos":"NOM"} ,
		{"word":"moments","word_nosc":"moments","lemma":"moment","pos":"NOM"} ,
		{"word":"moments-clés","word_nosc":"moments-cles","lemma":"moments-clé","pos":"NOM"} ,
		{"word":"momerie","word_nosc":"momerie","lemma":"momerie","pos":"NOM"} ,
		{"word":"momeries","word_nosc":"momeries","lemma":"momerie","pos":"NOM"} ,
		{"word":"momichons","word_nosc":"momichons","lemma":"momichon","pos":"NOM"} ,
		{"word":"momie","word_nosc":"momie","lemma":"momi","pos":"NOM"} ,
		{"word":"momie","word_nosc":"momie","lemma":"momie","pos":"NOM"} ,
		{"word":"momies","word_nosc":"momies","lemma":"momi","pos":"NOM"} ,
		{"word":"momies","word_nosc":"momies","lemma":"momie","pos":"NOM"} ,
		{"word":"momification","word_nosc":"momification","lemma":"momification","pos":"NOM"} ,
		{"word":"mominette","word_nosc":"mominette","lemma":"mominette","pos":"NOM"} ,
		{"word":"monachisme","word_nosc":"monachisme","lemma":"monachisme","pos":"NOM"} ,
		{"word":"monades","word_nosc":"monades","lemma":"monade","pos":"NOM"} ,
		{"word":"monadologie","word_nosc":"monadologie","lemma":"monadologie","pos":"NOM"} ,
		{"word":"monarchie","word_nosc":"monarchie","lemma":"monarchie","pos":"NOM"} ,
		{"word":"monarchies","word_nosc":"monarchies","lemma":"monarchie","pos":"NOM"} ,
		{"word":"monarchiste","word_nosc":"monarchiste","lemma":"monarchiste","pos":"NOM"} ,
		{"word":"monarchistes","word_nosc":"monarchistes","lemma":"monarchiste","pos":"NOM"} ,
		{"word":"monarque","word_nosc":"monarque","lemma":"monarque","pos":"NOM"} ,
		{"word":"monarques","word_nosc":"monarques","lemma":"monarque","pos":"NOM"} ,
		{"word":"monastère","word_nosc":"monastere","lemma":"monastère","pos":"NOM"} ,
		{"word":"monastères","word_nosc":"monasteres","lemma":"monastère","pos":"NOM"} ,
		{"word":"monbazillac","word_nosc":"monbazillac","lemma":"monbazillac","pos":"NOM"} ,
		{"word":"monceau","word_nosc":"monceau","lemma":"monceau","pos":"NOM"} ,
		{"word":"monceaux","word_nosc":"monceaux","lemma":"monceau","pos":"NOM"} ,
		{"word":"mondain","word_nosc":"mondain","lemma":"mondain","pos":"NOM"} ,
		{"word":"mondaine","word_nosc":"mondaine","lemma":"mondain","pos":"NOM"} ,
		{"word":"mondaines","word_nosc":"mondaines","lemma":"mondain","pos":"NOM"} ,
		{"word":"mondains","word_nosc":"mondains","lemma":"mondain","pos":"NOM"} ,
		{"word":"mondanité","word_nosc":"mondanite","lemma":"mondanité","pos":"NOM"} ,
		{"word":"mondanités","word_nosc":"mondanites","lemma":"mondanité","pos":"NOM"} ,
		{"word":"monde","word_nosc":"monde","lemma":"monde","pos":"NOM"} ,
		{"word":"mondes","word_nosc":"mondes","lemma":"monde","pos":"NOM"} ,
		{"word":"mondiale","word_nosc":"mondiale","lemma":"mondiale","pos":"NOM"} ,
		{"word":"mondialisation","word_nosc":"mondialisation","lemma":"mondialisation","pos":"NOM"} ,
		{"word":"mondovision","word_nosc":"mondovision","lemma":"mondovision","pos":"NOM"} ,
		{"word":"mongo","word_nosc":"mongo","lemma":"mongo","pos":"NOM"} ,
		{"word":"mongol","word_nosc":"mongol","lemma":"mongol","pos":"NOM"} ,
		{"word":"mongole","word_nosc":"mongole","lemma":"mongol","pos":"NOM"} ,
		{"word":"mongoles","word_nosc":"mongoles","lemma":"mongol","pos":"NOM"} ,
		{"word":"mongolie","word_nosc":"mongolie","lemma":"mongolie","pos":"NOM"} ,
		{"word":"mongolien","word_nosc":"mongolien","lemma":"mongolien","pos":"NOM"} ,
		{"word":"mongolienne","word_nosc":"mongolienne","lemma":"mongolien","pos":"NOM"} ,
		{"word":"mongoliennes","word_nosc":"mongoliennes","lemma":"mongolien","pos":"NOM"} ,
		{"word":"mongoliens","word_nosc":"mongoliens","lemma":"mongolien","pos":"NOM"} ,
		{"word":"mongols","word_nosc":"mongols","lemma":"mongol","pos":"NOM"} ,
		{"word":"moniale","word_nosc":"moniale","lemma":"moniale","pos":"NOM"} ,
		{"word":"moniteur","word_nosc":"moniteur","lemma":"moniteur","pos":"NOM"} ,
		{"word":"moniteurs","word_nosc":"moniteurs","lemma":"moniteur","pos":"NOM"} ,
		{"word":"monition","word_nosc":"monition","lemma":"monition","pos":"NOM"} ,
		{"word":"monitor","word_nosc":"monitor","lemma":"monitor","pos":"NOM"} ,
		{"word":"monitorage","word_nosc":"monitorage","lemma":"monitorage","pos":"NOM"} ,
		{"word":"monitoring","word_nosc":"monitoring","lemma":"monitoring","pos":"NOM"} ,
		{"word":"monitrice","word_nosc":"monitrice","lemma":"moniteur","pos":"NOM"} ,
		{"word":"monitrices","word_nosc":"monitrices","lemma":"moniteur","pos":"NOM"} ,
		{"word":"monnaie","word_nosc":"monnaie","lemma":"monnaie","pos":"NOM"} ,
		{"word":"monnaie-du-pape","word_nosc":"monnaie-du-pape","lemma":"monnaie-du-pape","pos":"NOM"} ,
		{"word":"monnaies","word_nosc":"monnaies","lemma":"monnaie","pos":"NOM"} ,
		{"word":"monnaies-du-pape","word_nosc":"monnaies-du-pape","lemma":"monnaies-du-pape","pos":"NOM"} ,
		{"word":"monnayeur","word_nosc":"monnayeur","lemma":"monnayeur","pos":"NOM"} ,
		{"word":"monnayeurs","word_nosc":"monnayeurs","lemma":"monnayeur","pos":"NOM"} ,
		{"word":"mono","word_nosc":"mono","lemma":"mono","pos":"NOM"} ,
		{"word":"monoamine","word_nosc":"monoamine","lemma":"monoamine","pos":"NOM"} ,
		{"word":"monobloc","word_nosc":"monobloc","lemma":"monobloc","pos":"NOM"} ,
		{"word":"monochromie","word_nosc":"monochromie","lemma":"monochromie","pos":"NOM"} ,
		{"word":"monocle","word_nosc":"monocle","lemma":"monocle","pos":"NOM"} ,
		{"word":"monocles","word_nosc":"monocles","lemma":"monocle","pos":"NOM"} ,
		{"word":"monocoque","word_nosc":"monocoque","lemma":"monocoque","pos":"NOM"} ,
		{"word":"monocratie","word_nosc":"monocratie","lemma":"monocratie","pos":"NOM"} ,
		{"word":"monoculture","word_nosc":"monoculture","lemma":"monoculture","pos":"NOM"} ,
		{"word":"monocycle","word_nosc":"monocycle","lemma":"monocycle","pos":"NOM"} ,
		{"word":"monocylindre","word_nosc":"monocylindre","lemma":"monocylindre","pos":"NOM"} ,
		{"word":"monodie","word_nosc":"monodie","lemma":"monodie","pos":"NOM"} ,
		{"word":"monofilament","word_nosc":"monofilament","lemma":"monofilament","pos":"NOM"} ,
		{"word":"monogame","word_nosc":"monogame","lemma":"monogame","pos":"NOM"} ,
		{"word":"monogames","word_nosc":"monogames","lemma":"monogame","pos":"NOM"} ,
		{"word":"monogamie","word_nosc":"monogamie","lemma":"monogamie","pos":"NOM"} ,
		{"word":"monogramme","word_nosc":"monogramme","lemma":"monogramme","pos":"NOM"} ,
		{"word":"monogrammes","word_nosc":"monogrammes","lemma":"monogramme","pos":"NOM"} ,
		{"word":"monographie","word_nosc":"monographie","lemma":"monographie","pos":"NOM"} ,
		{"word":"monographies","word_nosc":"monographies","lemma":"monographie","pos":"NOM"} ,
		{"word":"monokini","word_nosc":"monokini","lemma":"monokini","pos":"NOM"} ,
		{"word":"monolinguisme","word_nosc":"monolinguisme","lemma":"monolinguisme","pos":"NOM"} ,
		{"word":"monolithe","word_nosc":"monolithe","lemma":"monolithe","pos":"NOM"} ,
		{"word":"monolithes","word_nosc":"monolithes","lemma":"monolithe","pos":"NOM"} ,
		{"word":"monolithisme","word_nosc":"monolithisme","lemma":"monolithisme","pos":"NOM"} ,
		{"word":"monologue","word_nosc":"monologue","lemma":"monologue","pos":"NOM"} ,
		{"word":"monologues","word_nosc":"monologues","lemma":"monologue","pos":"NOM"} ,
		{"word":"monomane","word_nosc":"monomane","lemma":"monomane","pos":"NOM"} ,
		{"word":"monomanie","word_nosc":"monomanie","lemma":"monomanie","pos":"NOM"} ,
		{"word":"monomoteur","word_nosc":"monomoteur","lemma":"monomoteur","pos":"NOM"} ,
		{"word":"mononucléose","word_nosc":"mononucleose","lemma":"mononucléose","pos":"NOM"} ,
		{"word":"monoplace","word_nosc":"monoplace","lemma":"monoplace","pos":"NOM"} ,
		{"word":"monoplaces","word_nosc":"monoplaces","lemma":"monoplace","pos":"NOM"} ,
		{"word":"monoplan","word_nosc":"monoplan","lemma":"monoplan","pos":"NOM"} ,
		{"word":"monoplans","word_nosc":"monoplans","lemma":"monoplan","pos":"NOM"} ,
		{"word":"monopole","word_nosc":"monopole","lemma":"monopole","pos":"NOM"} ,
		{"word":"monopoles","word_nosc":"monopoles","lemma":"monopole","pos":"NOM"} ,
		{"word":"monopoly","word_nosc":"monopoly","lemma":"monopoly","pos":"NOM"} ,
		{"word":"monoprix","word_nosc":"monoprix","lemma":"monoprix","pos":"NOM"} ,
		{"word":"monorail","word_nosc":"monorail","lemma":"monorail","pos":"NOM"} ,
		{"word":"monorails","word_nosc":"monorails","lemma":"monorail","pos":"NOM"} ,
		{"word":"monospace","word_nosc":"monospace","lemma":"monospace","pos":"NOM"} ,
		{"word":"monosyllabe","word_nosc":"monosyllabe","lemma":"monosyllabe","pos":"NOM"} ,
		{"word":"monosyllabes","word_nosc":"monosyllabes","lemma":"monosyllabe","pos":"NOM"} ,
		{"word":"monothéisme","word_nosc":"monotheisme","lemma":"monothéisme","pos":"NOM"} ,
		{"word":"monothéismes","word_nosc":"monotheismes","lemma":"monothéisme","pos":"NOM"} ,
		{"word":"monothéiste","word_nosc":"monotheiste","lemma":"monothéiste","pos":"NOM"} ,
		{"word":"monotonie","word_nosc":"monotonie","lemma":"monotonie","pos":"NOM"} ,
		{"word":"monotype","word_nosc":"monotype","lemma":"monotype","pos":"NOM"} ,
		{"word":"monoxyde","word_nosc":"monoxyde","lemma":"monoxyde","pos":"NOM"} ,
		{"word":"mons","word_nosc":"mons","lemma":"mons","pos":"NOM"} ,
		{"word":"monseigneur","word_nosc":"monseigneur","lemma":"monseigneur","pos":"NOM"} ,
		{"word":"monsieur","word_nosc":"monsieur","lemma":"monsieur","pos":"NOM"} ,
		{"word":"monsignor","word_nosc":"monsignor","lemma":"monsignor","pos":"NOM"} ,
		{"word":"monsignore","word_nosc":"monsignore","lemma":"monsignore","pos":"NOM"} ,
		{"word":"monstrance","word_nosc":"monstrance","lemma":"monstrance","pos":"NOM"} ,
		{"word":"monstrances","word_nosc":"monstrances","lemma":"monstrance","pos":"NOM"} ,
		{"word":"monstre","word_nosc":"monstre","lemma":"monstre","pos":"NOM"} ,
		{"word":"monstres","word_nosc":"monstres","lemma":"monstre","pos":"NOM"} ,
		{"word":"monstruosité","word_nosc":"monstruosite","lemma":"monstruosité","pos":"NOM"} ,
		{"word":"monstruosités","word_nosc":"monstruosites","lemma":"monstruosité","pos":"NOM"} ,
		{"word":"mont","word_nosc":"mont","lemma":"mont","pos":"NOM"} ,
		{"word":"mont-blanc","word_nosc":"mont-blanc","lemma":"mont-blanc","pos":"NOM"} ,
		{"word":"mont-de-piété","word_nosc":"mont-de-piete","lemma":"mont-de-piété","pos":"NOM"} ,
		{"word":"montage","word_nosc":"montage","lemma":"montage","pos":"NOM"} ,
		{"word":"montages","word_nosc":"montages","lemma":"montage","pos":"NOM"} ,
		{"word":"montagnard","word_nosc":"montagnard","lemma":"montagnard","pos":"NOM"} ,
		{"word":"montagnarde","word_nosc":"montagnarde","lemma":"montagnard","pos":"NOM"} ,
		{"word":"montagnards","word_nosc":"montagnards","lemma":"montagnard","pos":"NOM"} ,
		{"word":"montagne","word_nosc":"montagne","lemma":"montagne","pos":"NOM"} ,
		{"word":"montagnes","word_nosc":"montagnes","lemma":"montagne","pos":"NOM"} ,
		{"word":"montaison","word_nosc":"montaison","lemma":"montaison","pos":"NOM"} ,
		{"word":"montant","word_nosc":"montant","lemma":"montant","pos":"NOM"} ,
		{"word":"montants","word_nosc":"montants","lemma":"montant","pos":"NOM"} ,
		{"word":"monte-charge","word_nosc":"monte-charge","lemma":"monte-charge","pos":"NOM"} ,
		{"word":"monte-charges","word_nosc":"monte-charges","lemma":"monte-charges","pos":"NOM"} ,
		{"word":"monte-en-l'air","word_nosc":"monte-en-l'air","lemma":"monte-en-l'air","pos":"NOM"} ,
		{"word":"monte-plat","word_nosc":"monte-plat","lemma":"monte-plat","pos":"NOM"} ,
		{"word":"monte-plats","word_nosc":"monte-plats","lemma":"monte-plats","pos":"NOM"} ,
		{"word":"montes","word_nosc":"montes","lemma":"monte","pos":"NOM"} ,
		{"word":"monteur","word_nosc":"monteur","lemma":"monteur","pos":"NOM"} ,
		{"word":"monteurs","word_nosc":"monteurs","lemma":"monteur","pos":"NOM"} ,
		{"word":"monteuse","word_nosc":"monteuse","lemma":"monteur","pos":"NOM"} ,
		{"word":"montgolfière","word_nosc":"montgolfiere","lemma":"montgolfière","pos":"NOM"} ,
		{"word":"montgolfières","word_nosc":"montgolfieres","lemma":"montgolfière","pos":"NOM"} ,
		{"word":"monticule","word_nosc":"monticule","lemma":"monticule","pos":"NOM"} ,
		{"word":"monticules","word_nosc":"monticules","lemma":"monticule","pos":"NOM"} ,
		{"word":"montjoie","word_nosc":"montjoie","lemma":"montjoie","pos":"NOM"} ,
		{"word":"montmartrois","word_nosc":"montmartrois","lemma":"montmartrois","pos":"NOM"} ,
		{"word":"montparno","word_nosc":"montparno","lemma":"montparno","pos":"NOM"} ,
		{"word":"montpelliéraine","word_nosc":"montpellieraine","lemma":"montpelliérain","pos":"NOM"} ,
		{"word":"montre","word_nosc":"montre","lemma":"montre","pos":"NOM"} ,
		{"word":"montre-bracelet","word_nosc":"montre-bracelet","lemma":"montre-bracelet","pos":"NOM"} ,
		{"word":"montre-gousset","word_nosc":"montre-gousset","lemma":"montre-gousset","pos":"NOM"} ,
		{"word":"montre-la-moi","word_nosc":"montre-la-moi","lemma":"montre-la-moi","pos":"NOM"} ,
		{"word":"montres","word_nosc":"montres","lemma":"montre","pos":"NOM"} ,
		{"word":"montres-bracelets","word_nosc":"montres-bracelets","lemma":"montre-bracelet","pos":"NOM"} ,
		{"word":"montreur","word_nosc":"montreur","lemma":"montreur","pos":"NOM"} ,
		{"word":"montreurs","word_nosc":"montreurs","lemma":"montreur","pos":"NOM"} ,
		{"word":"montreuse","word_nosc":"montreuse","lemma":"montreur","pos":"NOM"} ,
		{"word":"montreuses","word_nosc":"montreuses","lemma":"montreur","pos":"NOM"} ,
		{"word":"monts","word_nosc":"monts","lemma":"mont","pos":"NOM"} ,
		{"word":"monts-blancs","word_nosc":"monts-blancs","lemma":"mont-blanc","pos":"NOM"} ,
		{"word":"monture","word_nosc":"monture","lemma":"monture","pos":"NOM"} ,
		{"word":"montures","word_nosc":"montures","lemma":"monture","pos":"NOM"} ,
		{"word":"montée","word_nosc":"montee","lemma":"montée","pos":"NOM"} ,
		{"word":"montées","word_nosc":"montees","lemma":"montée","pos":"NOM"} ,
		{"word":"monténégrin","word_nosc":"montenegrin","lemma":"monténégrin","pos":"NOM"} ,
		{"word":"monument","word_nosc":"monument","lemma":"monument","pos":"NOM"} ,
		{"word":"monumentalité","word_nosc":"monumentalite","lemma":"monumentalité","pos":"NOM"} ,
		{"word":"monuments","word_nosc":"monuments","lemma":"monument","pos":"NOM"} ,
		{"word":"monômes","word_nosc":"monomes","lemma":"monôme","pos":"NOM"} ,
		{"word":"moonisme","word_nosc":"moonisme","lemma":"moonisme","pos":"NOM"} ,
		{"word":"moonistes","word_nosc":"moonistes","lemma":"mooniste","pos":"NOM"} ,
		{"word":"mooré","word_nosc":"moore","lemma":"mooré","pos":"NOM"} ,
		{"word":"moos","word_nosc":"moos","lemma":"moos","pos":"NOM"} ,
		{"word":"moque","word_nosc":"moque","lemma":"moque","pos":"NOM"} ,
		{"word":"moquerie","word_nosc":"moquerie","lemma":"moquerie","pos":"NOM"} ,
		{"word":"moqueries","word_nosc":"moqueries","lemma":"moquerie","pos":"NOM"} ,
		{"word":"moques","word_nosc":"moques","lemma":"moque","pos":"NOM"} ,
		{"word":"moquette","word_nosc":"moquette","lemma":"moquette","pos":"NOM"} ,
		{"word":"moquettes","word_nosc":"moquettes","lemma":"moquette","pos":"NOM"} ,
		{"word":"moqueur","word_nosc":"moqueur","lemma":"moqueur","pos":"NOM"} ,
		{"word":"moqueurs","word_nosc":"moqueurs","lemma":"moqueur","pos":"NOM"} ,
		{"word":"moqueuse","word_nosc":"moqueuse","lemma":"moqueur","pos":"NOM"} ,
		{"word":"moqueuses","word_nosc":"moqueuses","lemma":"moqueur","pos":"NOM"} ,
		{"word":"moraillon","word_nosc":"moraillon","lemma":"moraillon","pos":"NOM"} ,
		{"word":"moraine","word_nosc":"moraine","lemma":"moraine","pos":"NOM"} ,
		{"word":"moraines","word_nosc":"moraines","lemma":"moraine","pos":"NOM"} ,
		{"word":"moral","word_nosc":"moral","lemma":"moral","pos":"NOM"} ,
		{"word":"morale","word_nosc":"morale","lemma":"morale","pos":"NOM"} ,
		{"word":"morales","word_nosc":"morales","lemma":"morale","pos":"NOM"} ,
		{"word":"moralisateur","word_nosc":"moralisateur","lemma":"moralisateur","pos":"NOM"} ,
		{"word":"moralisateurs","word_nosc":"moralisateurs","lemma":"moralisateur","pos":"NOM"} ,
		{"word":"moralisation","word_nosc":"moralisation","lemma":"moralisation","pos":"NOM"} ,
		{"word":"moralisme","word_nosc":"moralisme","lemma":"moralisme","pos":"NOM"} ,
		{"word":"moraliste","word_nosc":"moraliste","lemma":"moraliste","pos":"NOM"} ,
		{"word":"moralistes","word_nosc":"moralistes","lemma":"moraliste","pos":"NOM"} ,
		{"word":"moralité","word_nosc":"moralite","lemma":"moralité","pos":"NOM"} ,
		{"word":"moralités","word_nosc":"moralites","lemma":"moralité","pos":"NOM"} ,
		{"word":"morasse","word_nosc":"morasse","lemma":"morasse","pos":"NOM"} ,
		{"word":"morasses","word_nosc":"morasses","lemma":"morasse","pos":"NOM"} ,
		{"word":"moratoire","word_nosc":"moratoire","lemma":"moratoire","pos":"NOM"} ,
		{"word":"moraux","word_nosc":"moraux","lemma":"moral","pos":"NOM"} ,
		{"word":"moraves","word_nosc":"moraves","lemma":"morave","pos":"NOM"} ,
		{"word":"morbac","word_nosc":"morbac","lemma":"morbac","pos":"NOM"} ,
		{"word":"morbacs","word_nosc":"morbacs","lemma":"morbac","pos":"NOM"} ,
		{"word":"morbaque","word_nosc":"morbaque","lemma":"morbaque","pos":"NOM"} ,
		{"word":"morbaques","word_nosc":"morbaques","lemma":"morbaque","pos":"NOM"} ,
		{"word":"morbidesse","word_nosc":"morbidesse","lemma":"morbidesse","pos":"NOM"} ,
		{"word":"morbidité","word_nosc":"morbidite","lemma":"morbidité","pos":"NOM"} ,
		{"word":"morbier","word_nosc":"morbier","lemma":"morbier","pos":"NOM"} ,
		{"word":"morceau","word_nosc":"morceau","lemma":"morceau","pos":"NOM"} ,
		{"word":"morceaux","word_nosc":"morceaux","lemma":"morceau","pos":"NOM"} ,
		{"word":"morcellement","word_nosc":"morcellement","lemma":"morcellement","pos":"NOM"} ,
		{"word":"morcellements","word_nosc":"morcellements","lemma":"morcellement","pos":"NOM"} ,
		{"word":"morcif","word_nosc":"morcif","lemma":"morcif","pos":"NOM"} ,
		{"word":"morcifs","word_nosc":"morcifs","lemma":"morcif","pos":"NOM"} ,
		{"word":"mordant","word_nosc":"mordant","lemma":"mordant","pos":"NOM"} ,
		{"word":"mordançage","word_nosc":"mordancage","lemma":"mordançage","pos":"NOM"} ,
		{"word":"mordeur","word_nosc":"mordeur","lemma":"mordeur","pos":"NOM"} ,
		{"word":"mordeurs","word_nosc":"mordeurs","lemma":"mordeur","pos":"NOM"} ,
		{"word":"mordeuse","word_nosc":"mordeuse","lemma":"mordeuse","pos":"NOM"} ,
		{"word":"mordillage","word_nosc":"mordillage","lemma":"mordillage","pos":"NOM"} ,
		{"word":"mordillement","word_nosc":"mordillement","lemma":"mordillement","pos":"NOM"} ,
		{"word":"mordillements","word_nosc":"mordillements","lemma":"mordillement","pos":"NOM"} ,
		{"word":"more","word_nosc":"more","lemma":"more","pos":"NOM"} ,
		{"word":"morelle","word_nosc":"morelle","lemma":"morelle","pos":"NOM"} ,
		{"word":"mores","word_nosc":"mores","lemma":"more","pos":"NOM"} ,
		{"word":"moresque","word_nosc":"moresque","lemma":"moresque","pos":"NOM"} ,
		{"word":"morfalou","word_nosc":"morfalou","lemma":"morfalou","pos":"NOM"} ,
		{"word":"morfil","word_nosc":"morfil","lemma":"morfil","pos":"NOM"} ,
		{"word":"morgeline","word_nosc":"morgeline","lemma":"morgeline","pos":"NOM"} ,
		{"word":"morgon","word_nosc":"morgon","lemma":"morgon","pos":"NOM"} ,
		{"word":"morgue","word_nosc":"morgue","lemma":"morgue","pos":"NOM"} ,
		{"word":"morgues","word_nosc":"morgues","lemma":"morgue","pos":"NOM"} ,
		{"word":"moria","word_nosc":"moria","lemma":"moria","pos":"NOM"} ,
		{"word":"moribond","word_nosc":"moribond","lemma":"moribond","pos":"NOM"} ,
		{"word":"moribonde","word_nosc":"moribonde","lemma":"moribond","pos":"NOM"} ,
		{"word":"moribonds","word_nosc":"moribonds","lemma":"moribond","pos":"NOM"} ,
		{"word":"moricaud","word_nosc":"moricaud","lemma":"moricaud","pos":"NOM"} ,
		{"word":"moricaude","word_nosc":"moricaude","lemma":"moricaud","pos":"NOM"} ,
		{"word":"moricaudes","word_nosc":"moricaudes","lemma":"moricaud","pos":"NOM"} ,
		{"word":"moricauds","word_nosc":"moricauds","lemma":"moricaud","pos":"NOM"} ,
		{"word":"morille","word_nosc":"morille","lemma":"morille","pos":"NOM"} ,
		{"word":"morilles","word_nosc":"morilles","lemma":"morille","pos":"NOM"} ,
		{"word":"morillon","word_nosc":"morillon","lemma":"morillon","pos":"NOM"} ,
		{"word":"morillons","word_nosc":"morillons","lemma":"morillon","pos":"NOM"} ,
		{"word":"morlingue","word_nosc":"morlingue","lemma":"morlingue","pos":"NOM"} ,
		{"word":"morlingues","word_nosc":"morlingues","lemma":"morlingue","pos":"NOM"} ,
		{"word":"mormon","word_nosc":"mormon","lemma":"mormon","pos":"NOM"} ,
		{"word":"mormone","word_nosc":"mormone","lemma":"mormon","pos":"NOM"} ,
		{"word":"mormons","word_nosc":"mormons","lemma":"mormon","pos":"NOM"} ,
		{"word":"morne","word_nosc":"morne","lemma":"morne","pos":"NOM"} ,
		{"word":"mornes","word_nosc":"mornes","lemma":"morne","pos":"NOM"} ,
		{"word":"mornifle","word_nosc":"mornifle","lemma":"mornifle","pos":"NOM"} ,
		{"word":"morosité","word_nosc":"morosite","lemma":"morosité","pos":"NOM"} ,
		{"word":"morosités","word_nosc":"morosites","lemma":"morosité","pos":"NOM"} ,
		{"word":"morphine","word_nosc":"morphine","lemma":"morphine","pos":"NOM"} ,
		{"word":"morphing","word_nosc":"morphing","lemma":"morphing","pos":"NOM"} ,
		{"word":"morphinomane","word_nosc":"morphinomane","lemma":"morphinomane","pos":"NOM"} ,
		{"word":"morphogenèse","word_nosc":"morphogenese","lemma":"morphogenèse","pos":"NOM"} ,
		{"word":"morphologie","word_nosc":"morphologie","lemma":"morphologie","pos":"NOM"} ,
		{"word":"morpion","word_nosc":"morpion","lemma":"morpion","pos":"NOM"} ,
		{"word":"morpions","word_nosc":"morpions","lemma":"morpion","pos":"NOM"} ,
		{"word":"mors","word_nosc":"mors","lemma":"mors","pos":"NOM"} ,
		{"word":"morse","word_nosc":"morse","lemma":"morse","pos":"NOM"} ,
		{"word":"morsure","word_nosc":"morsure","lemma":"morsure","pos":"NOM"} ,
		{"word":"morsures","word_nosc":"morsures","lemma":"morsure","pos":"NOM"} ,
		{"word":"mort","word_nosc":"mort","lemma":"mort","pos":"NOM"} ,
		{"word":"mort-aux-rats","word_nosc":"mort-aux-rats","lemma":"mort-aux-rats","pos":"NOM"} ,
		{"word":"mort-né","word_nosc":"mort-ne","lemma":"mort-né","pos":"NOM"} ,
		{"word":"mort-vivant","word_nosc":"mort-vivant","lemma":"mort-vivant","pos":"NOM"} ,
		{"word":"mortadelle","word_nosc":"mortadelle","lemma":"mortadelle","pos":"NOM"} ,
		{"word":"mortaise","word_nosc":"mortaise","lemma":"mortaise","pos":"NOM"} ,
		{"word":"mortaises","word_nosc":"mortaises","lemma":"mortaise","pos":"NOM"} ,
		{"word":"mortaiseuse","word_nosc":"mortaiseuse","lemma":"mortaiseur","pos":"NOM"} ,
		{"word":"mortalité","word_nosc":"mortalite","lemma":"mortalité","pos":"NOM"} ,
		{"word":"morte","word_nosc":"morte","lemma":"mort","pos":"NOM"} ,
		{"word":"morte-saison","word_nosc":"morte-saison","lemma":"morte-saison","pos":"NOM"} ,
		{"word":"mortel","word_nosc":"mortel","lemma":"mortel","pos":"NOM"} ,
		{"word":"mortelle","word_nosc":"mortelle","lemma":"mortel","pos":"NOM"} ,
		{"word":"mortelles","word_nosc":"mortelles","lemma":"mortel","pos":"NOM"} ,
		{"word":"mortels","word_nosc":"mortels","lemma":"mortel","pos":"NOM"} ,
		{"word":"mortes","word_nosc":"mortes","lemma":"mort","pos":"NOM"} ,
		{"word":"mortes-eaux","word_nosc":"mortes-eaux","lemma":"mortes-eaux","pos":"NOM"} ,
		{"word":"mortes-saisons","word_nosc":"mortes-saisons","lemma":"morte-saison","pos":"NOM"} ,
		{"word":"mortier","word_nosc":"mortier","lemma":"mortier","pos":"NOM"} ,
		{"word":"mortiers","word_nosc":"mortiers","lemma":"mortier","pos":"NOM"} ,
		{"word":"mortification","word_nosc":"mortification","lemma":"mortification","pos":"NOM"} ,
		{"word":"mortifications","word_nosc":"mortifications","lemma":"mortification","pos":"NOM"} ,
		{"word":"morts","word_nosc":"morts","lemma":"mort","pos":"NOM"} ,
		{"word":"morts-vivants","word_nosc":"morts-vivants","lemma":"mort-vivant","pos":"NOM"} ,
		{"word":"morue","word_nosc":"morue","lemma":"morue","pos":"NOM"} ,
		{"word":"morues","word_nosc":"morues","lemma":"morue","pos":"NOM"} ,
		{"word":"morula","word_nosc":"morula","lemma":"morula","pos":"NOM"} ,
		{"word":"morve","word_nosc":"morve","lemma":"morve","pos":"NOM"} ,
		{"word":"morves","word_nosc":"morves","lemma":"morve","pos":"NOM"} ,
		{"word":"morveuse","word_nosc":"morveuse","lemma":"morveux","pos":"NOM"} ,
		{"word":"morveux","word_nosc":"morveux","lemma":"morveux","pos":"NOM"} ,
		{"word":"mos","word_nosc":"mos","lemma":"mos","pos":"NOM"} ,
		{"word":"mosaïque","word_nosc":"mosaique","lemma":"mosaïque","pos":"NOM"} ,
		{"word":"mosaïques","word_nosc":"mosaiques","lemma":"mosaïque","pos":"NOM"} ,
		{"word":"mosaïste","word_nosc":"mosaiste","lemma":"mosaïste","pos":"NOM"} ,
		{"word":"moscoutaire","word_nosc":"moscoutaire","lemma":"moscoutaire","pos":"NOM"} ,
		{"word":"moscovite","word_nosc":"moscovite","lemma":"moscovite","pos":"NOM"} ,
		{"word":"moscovites","word_nosc":"moscovites","lemma":"moscovite","pos":"NOM"} ,
		{"word":"mosquée","word_nosc":"mosquee","lemma":"mosquée","pos":"NOM"} ,
		{"word":"mosquées","word_nosc":"mosquees","lemma":"mosquée","pos":"NOM"} ,
		{"word":"mot","word_nosc":"mot","lemma":"mot","pos":"NOM"} ,
		{"word":"mot-clé","word_nosc":"mot-cle","lemma":"mot-clé","pos":"NOM"} ,
		{"word":"mot-valise","word_nosc":"mot-valise","lemma":"mot-valise","pos":"NOM"} ,
		{"word":"motard","word_nosc":"motard","lemma":"motard","pos":"NOM"} ,
		{"word":"motarde","word_nosc":"motarde","lemma":"motard","pos":"NOM"} ,
		{"word":"motardes","word_nosc":"motardes","lemma":"motard","pos":"NOM"} ,
		{"word":"motards","word_nosc":"motards","lemma":"motard","pos":"NOM"} ,
		{"word":"motel","word_nosc":"motel","lemma":"motel","pos":"NOM"} ,
		{"word":"motels","word_nosc":"motels","lemma":"motel","pos":"NOM"} ,
		{"word":"motesse","word_nosc":"motesse","lemma":"motesse","pos":"NOM"} ,
		{"word":"motet","word_nosc":"motet","lemma":"motet","pos":"NOM"} ,
		{"word":"motets","word_nosc":"motets","lemma":"motet","pos":"NOM"} ,
		{"word":"moteur","word_nosc":"moteur","lemma":"moteur","pos":"NOM"} ,
		{"word":"moteur-fusée","word_nosc":"moteur-fusee","lemma":"moteur-fusée","pos":"NOM"} ,
		{"word":"moteurs","word_nosc":"moteurs","lemma":"moteur","pos":"NOM"} ,
		{"word":"moteurs-fusées","word_nosc":"moteurs-fusees","lemma":"moteur-fusée","pos":"NOM"} ,
		{"word":"motif","word_nosc":"motif","lemma":"motif","pos":"NOM"} ,
		{"word":"motifs","word_nosc":"motifs","lemma":"motif","pos":"NOM"} ,
		{"word":"motilité","word_nosc":"motilite","lemma":"motilité","pos":"NOM"} ,
		{"word":"motion","word_nosc":"motion","lemma":"motion","pos":"NOM"} ,
		{"word":"motions","word_nosc":"motions","lemma":"motion","pos":"NOM"} ,
		{"word":"motivateur","word_nosc":"motivateur","lemma":"motivateur","pos":"NOM"} ,
		{"word":"motivation","word_nosc":"motivation","lemma":"motivation","pos":"NOM"} ,
		{"word":"motivations","word_nosc":"motivations","lemma":"motivation","pos":"NOM"} ,
		{"word":"moto","word_nosc":"moto","lemma":"moto","pos":"NOM"} ,
		{"word":"moto-club","word_nosc":"moto-club","lemma":"moto-club","pos":"NOM"} ,
		{"word":"moto-cross","word_nosc":"moto-cross","lemma":"moto-cross","pos":"NOM"} ,
		{"word":"motocross","word_nosc":"motocross","lemma":"motocross","pos":"NOM"} ,
		{"word":"motoculteur","word_nosc":"motoculteur","lemma":"motoculteur","pos":"NOM"} ,
		{"word":"motoculture","word_nosc":"motoculture","lemma":"motoculture","pos":"NOM"} ,
		{"word":"motocycles","word_nosc":"motocycles","lemma":"motocycle","pos":"NOM"} ,
		{"word":"motocyclette","word_nosc":"motocyclette","lemma":"motocyclette","pos":"NOM"} ,
		{"word":"motocyclettes","word_nosc":"motocyclettes","lemma":"motocyclette","pos":"NOM"} ,
		{"word":"motocyclisme","word_nosc":"motocyclisme","lemma":"motocyclisme","pos":"NOM"} ,
		{"word":"motocycliste","word_nosc":"motocycliste","lemma":"motocycliste","pos":"NOM"} ,
		{"word":"motocyclistes","word_nosc":"motocyclistes","lemma":"motocycliste","pos":"NOM"} ,
		{"word":"motoneige","word_nosc":"motoneige","lemma":"motoneige","pos":"NOM"} ,
		{"word":"motoneiges","word_nosc":"motoneiges","lemma":"motoneige","pos":"NOM"} ,
		{"word":"motoneurone","word_nosc":"motoneurone","lemma":"motoneurone","pos":"NOM"} ,
		{"word":"motorisation","word_nosc":"motorisation","lemma":"motorisation","pos":"NOM"} ,
		{"word":"motos","word_nosc":"motos","lemma":"moto","pos":"NOM"} ,
		{"word":"motrice","word_nosc":"motrice","lemma":"motrice","pos":"NOM"} ,
		{"word":"motrices","word_nosc":"motrices","lemma":"motrice","pos":"NOM"} ,
		{"word":"motricité","word_nosc":"motricite","lemma":"motricité","pos":"NOM"} ,
		{"word":"mots","word_nosc":"mots","lemma":"mot","pos":"NOM"} ,
		{"word":"mots-clefs","word_nosc":"mots-clefs","lemma":"mot-clef","pos":"NOM"} ,
		{"word":"mots-clés","word_nosc":"mots-cles","lemma":"mot-clé","pos":"NOM"} ,
		{"word":"mots-croisés","word_nosc":"mots-croises","lemma":"mots-croisés","pos":"NOM"} ,
		{"word":"motte","word_nosc":"motte","lemma":"motte","pos":"NOM"} ,
		{"word":"mottereau","word_nosc":"mottereau","lemma":"mottereau","pos":"NOM"} ,
		{"word":"mottes","word_nosc":"mottes","lemma":"motte","pos":"NOM"} ,
		{"word":"motteux","word_nosc":"motteux","lemma":"motteux","pos":"NOM"} ,
		{"word":"mou","word_nosc":"mou","lemma":"mou","pos":"NOM"} ,
		{"word":"mouchachou","word_nosc":"mouchachou","lemma":"mouchachou","pos":"NOM"} ,
		{"word":"mouchage","word_nosc":"mouchage","lemma":"mouchage","pos":"NOM"} ,
		{"word":"mouchard","word_nosc":"mouchard","lemma":"mouchard","pos":"NOM"} ,
		{"word":"mouchardage","word_nosc":"mouchardage","lemma":"mouchardage","pos":"NOM"} ,
		{"word":"moucharde","word_nosc":"moucharde","lemma":"mouchard","pos":"NOM"} ,
		{"word":"mouchards","word_nosc":"mouchards","lemma":"mouchard","pos":"NOM"} ,
		{"word":"mouche","word_nosc":"mouche","lemma":"mouche","pos":"NOM"} ,
		{"word":"moucheron","word_nosc":"moucheron","lemma":"moucheron","pos":"NOM"} ,
		{"word":"moucherons","word_nosc":"moucherons","lemma":"moucheron","pos":"NOM"} ,
		{"word":"mouches","word_nosc":"mouches","lemma":"mouche","pos":"NOM"} ,
		{"word":"mouchette","word_nosc":"mouchette","lemma":"mouchette","pos":"NOM"} ,
		{"word":"moucheture","word_nosc":"moucheture","lemma":"moucheture","pos":"NOM"} ,
		{"word":"mouchetures","word_nosc":"mouchetures","lemma":"moucheture","pos":"NOM"} ,
		{"word":"moucheur","word_nosc":"moucheur","lemma":"moucheur","pos":"NOM"} ,
		{"word":"mouchoir","word_nosc":"mouchoir","lemma":"mouchoir","pos":"NOM"} ,
		{"word":"mouchoirs","word_nosc":"mouchoirs","lemma":"mouchoir","pos":"NOM"} ,
		{"word":"moudjahiddine","word_nosc":"moudjahiddine","lemma":"moudjahiddine","pos":"NOM"} ,
		{"word":"moudjahidin","word_nosc":"moudjahidin","lemma":"moudjahidin","pos":"NOM"} ,
		{"word":"moudjahidine","word_nosc":"moudjahidine","lemma":"moudjahidine","pos":"NOM"} ,
		{"word":"moue","word_nosc":"moue","lemma":"moue","pos":"NOM"} ,
		{"word":"moues","word_nosc":"moues","lemma":"moue","pos":"NOM"} ,
		{"word":"mouette","word_nosc":"mouette","lemma":"mouette","pos":"NOM"} ,
		{"word":"mouettes","word_nosc":"mouettes","lemma":"mouette","pos":"NOM"} ,
		{"word":"moufette","word_nosc":"moufette","lemma":"moufette","pos":"NOM"} ,
		{"word":"mouffette","word_nosc":"mouffette","lemma":"mouffette","pos":"NOM"} ,
		{"word":"moufle","word_nosc":"moufle","lemma":"moufle","pos":"NOM"} ,
		{"word":"moufles","word_nosc":"moufles","lemma":"moufle","pos":"NOM"} ,
		{"word":"mouflet","word_nosc":"mouflet","lemma":"mouflet","pos":"NOM"} ,
		{"word":"mouflets","word_nosc":"mouflets","lemma":"mouflet","pos":"NOM"} ,
		{"word":"mouflette","word_nosc":"mouflette","lemma":"mouflette","pos":"NOM"} ,
		{"word":"mouflettes","word_nosc":"mouflettes","lemma":"mouflette","pos":"NOM"} ,
		{"word":"mouflon","word_nosc":"mouflon","lemma":"mouflon","pos":"NOM"} ,
		{"word":"mouflons","word_nosc":"mouflons","lemma":"mouflon","pos":"NOM"} ,
		{"word":"mouillage","word_nosc":"mouillage","lemma":"mouillage","pos":"NOM"} ,
		{"word":"mouillages","word_nosc":"mouillages","lemma":"mouillage","pos":"NOM"} ,
		{"word":"mouille","word_nosc":"mouille","lemma":"mouille","pos":"NOM"} ,
		{"word":"mouilles","word_nosc":"mouilles","lemma":"mouille","pos":"NOM"} ,
		{"word":"mouillette","word_nosc":"mouillette","lemma":"mouillette","pos":"NOM"} ,
		{"word":"mouillettes","word_nosc":"mouillettes","lemma":"mouillette","pos":"NOM"} ,
		{"word":"mouilleur","word_nosc":"mouilleur","lemma":"mouilleur","pos":"NOM"} ,
		{"word":"mouillon","word_nosc":"mouillon","lemma":"mouillon","pos":"NOM"} ,
		{"word":"mouillons","word_nosc":"mouillons","lemma":"mouillon","pos":"NOM"} ,
		{"word":"mouillure","word_nosc":"mouillure","lemma":"mouillure","pos":"NOM"} ,
		{"word":"mouillures","word_nosc":"mouillures","lemma":"mouillure","pos":"NOM"} ,
		{"word":"mouise","word_nosc":"mouise","lemma":"mouise","pos":"NOM"} ,
		{"word":"moujahid","word_nosc":"moujahid","lemma":"moujahid","pos":"NOM"} ,
		{"word":"moujik","word_nosc":"moujik","lemma":"moujik","pos":"NOM"} ,
		{"word":"moujiks","word_nosc":"moujiks","lemma":"moujik","pos":"NOM"} ,
		{"word":"moujingue","word_nosc":"moujingue","lemma":"moujingue","pos":"NOM"} ,
		{"word":"moujingues","word_nosc":"moujingues","lemma":"moujingue","pos":"NOM"} ,
		{"word":"moukère","word_nosc":"moukere","lemma":"moukère","pos":"NOM"} ,
		{"word":"moukères","word_nosc":"moukeres","lemma":"moukère","pos":"NOM"} ,
		{"word":"moulage","word_nosc":"moulage","lemma":"moulage","pos":"NOM"} ,
		{"word":"moulages","word_nosc":"moulages","lemma":"moulage","pos":"NOM"} ,
		{"word":"moule","word_nosc":"moule","lemma":"moule","pos":"NOM"} ,
		{"word":"moules","word_nosc":"moules","lemma":"moule","pos":"NOM"} ,
		{"word":"mouleur","word_nosc":"mouleur","lemma":"mouleur","pos":"NOM"} ,
		{"word":"moulin","word_nosc":"moulin","lemma":"moulin","pos":"NOM"} ,
		{"word":"moulin-à-vent","word_nosc":"moulin-a-vent","lemma":"moulin-à-vent","pos":"NOM"} ,
		{"word":"moulinage","word_nosc":"moulinage","lemma":"moulinage","pos":"NOM"} ,
		{"word":"moulinet","word_nosc":"moulinet","lemma":"moulinet","pos":"NOM"} ,
		{"word":"moulinets","word_nosc":"moulinets","lemma":"moulinet","pos":"NOM"} ,
		{"word":"moulinette","word_nosc":"moulinette","lemma":"moulinette","pos":"NOM"} ,
		{"word":"moulinettes","word_nosc":"moulinettes","lemma":"moulinette","pos":"NOM"} ,
		{"word":"moulins","word_nosc":"moulins","lemma":"moulin","pos":"NOM"} ,
		{"word":"moulurations","word_nosc":"moulurations","lemma":"mouluration","pos":"NOM"} ,
		{"word":"moulure","word_nosc":"moulure","lemma":"moulure","pos":"NOM"} ,
		{"word":"moulures","word_nosc":"moulures","lemma":"moulure","pos":"NOM"} ,
		{"word":"moumounes","word_nosc":"moumounes","lemma":"moumoune","pos":"NOM"} ,
		{"word":"moumoute","word_nosc":"moumoute","lemma":"moumoute","pos":"NOM"} ,
		{"word":"moumoutes","word_nosc":"moumoutes","lemma":"moumoute","pos":"NOM"} ,
		{"word":"mound","word_nosc":"mound","lemma":"mound","pos":"NOM"} ,
		{"word":"mouquère","word_nosc":"mouquere","lemma":"mouquère","pos":"NOM"} ,
		{"word":"mourant","word_nosc":"mourant","lemma":"mourant","pos":"NOM"} ,
		{"word":"mourante","word_nosc":"mourante","lemma":"mourant","pos":"NOM"} ,
		{"word":"mourantes","word_nosc":"mourantes","lemma":"mourant","pos":"NOM"} ,
		{"word":"mourants","word_nosc":"mourants","lemma":"mourant","pos":"NOM"} ,
		{"word":"mouroir","word_nosc":"mouroir","lemma":"mouroir","pos":"NOM"} ,
		{"word":"mouron","word_nosc":"mouron","lemma":"mouron","pos":"NOM"} ,
		{"word":"mourons","word_nosc":"mourons","lemma":"mouron","pos":"NOM"} ,
		{"word":"mourre","word_nosc":"mourre","lemma":"mourre","pos":"NOM"} ,
		{"word":"mous","word_nosc":"mous","lemma":"mou","pos":"NOM"} ,
		{"word":"mouscaille","word_nosc":"mouscaille","lemma":"mouscaille","pos":"NOM"} ,
		{"word":"mouscailles","word_nosc":"mouscailles","lemma":"mouscaille","pos":"NOM"} ,
		{"word":"mousmé","word_nosc":"mousme","lemma":"mousmé","pos":"NOM"} ,
		{"word":"mousmés","word_nosc":"mousmes","lemma":"mousmé","pos":"NOM"} ,
		{"word":"mousquet","word_nosc":"mousquet","lemma":"mousquet","pos":"NOM"} ,
		{"word":"mousquetades","word_nosc":"mousquetades","lemma":"mousquetade","pos":"NOM"} ,
		{"word":"mousquetaire","word_nosc":"mousquetaire","lemma":"mousquetaire","pos":"NOM"} ,
		{"word":"mousquetaires","word_nosc":"mousquetaires","lemma":"mousquetaire","pos":"NOM"} ,
		{"word":"mousqueterie","word_nosc":"mousqueterie","lemma":"mousqueterie","pos":"NOM"} ,
		{"word":"mousqueton","word_nosc":"mousqueton","lemma":"mousqueton","pos":"NOM"} ,
		{"word":"mousquetons","word_nosc":"mousquetons","lemma":"mousqueton","pos":"NOM"} ,
		{"word":"mousquets","word_nosc":"mousquets","lemma":"mousquet","pos":"NOM"} ,
		{"word":"moussage","word_nosc":"moussage","lemma":"moussage","pos":"NOM"} ,
		{"word":"moussaillon","word_nosc":"moussaillon","lemma":"moussaillon","pos":"NOM"} ,
		{"word":"moussaillons","word_nosc":"moussaillons","lemma":"moussaillon","pos":"NOM"} ,
		{"word":"moussaka","word_nosc":"moussaka","lemma":"moussaka","pos":"NOM"} ,
		{"word":"moussakas","word_nosc":"moussakas","lemma":"moussaka","pos":"NOM"} ,
		{"word":"mousse","word_nosc":"mousse","lemma":"mousse","pos":"NOM"} ,
		{"word":"mousseline","word_nosc":"mousseline","lemma":"mousseline","pos":"NOM"} ,
		{"word":"mousselines","word_nosc":"mousselines","lemma":"mousseline","pos":"NOM"} ,
		{"word":"mousseron","word_nosc":"mousseron","lemma":"mousseron","pos":"NOM"} ,
		{"word":"mousserons","word_nosc":"mousserons","lemma":"mousseron","pos":"NOM"} ,
		{"word":"mousses","word_nosc":"mousses","lemma":"mousse","pos":"NOM"} ,
		{"word":"mousseux","word_nosc":"mousseux","lemma":"mousseux","pos":"NOM"} ,
		{"word":"mousson","word_nosc":"mousson","lemma":"mousson","pos":"NOM"} ,
		{"word":"moussons","word_nosc":"moussons","lemma":"mousson","pos":"NOM"} ,
		{"word":"moustache","word_nosc":"moustache","lemma":"moustache","pos":"NOM"} ,
		{"word":"moustaches","word_nosc":"moustaches","lemma":"moustache","pos":"NOM"} ,
		{"word":"moustagache","word_nosc":"moustagache","lemma":"moustagache","pos":"NOM"} ,
		{"word":"moustagaches","word_nosc":"moustagaches","lemma":"moustagache","pos":"NOM"} ,
		{"word":"moustiquaire","word_nosc":"moustiquaire","lemma":"moustiquaire","pos":"NOM"} ,
		{"word":"moustiquaires","word_nosc":"moustiquaires","lemma":"moustiquaire","pos":"NOM"} ,
		{"word":"moustique","word_nosc":"moustique","lemma":"moustique","pos":"NOM"} ,
		{"word":"moustiques","word_nosc":"moustiques","lemma":"moustique","pos":"NOM"} ,
		{"word":"moutard","word_nosc":"moutard","lemma":"moutard","pos":"NOM"} ,
		{"word":"moutarde","word_nosc":"moutarde","lemma":"moutarde","pos":"NOM"} ,
		{"word":"moutardes","word_nosc":"moutardes","lemma":"moutarde","pos":"NOM"} ,
		{"word":"moutardier","word_nosc":"moutardier","lemma":"moutardier","pos":"NOM"} ,
		{"word":"moutards","word_nosc":"moutards","lemma":"moutard","pos":"NOM"} ,
		{"word":"moutier","word_nosc":"moutier","lemma":"moutier","pos":"NOM"} ,
		{"word":"mouton","word_nosc":"mouton","lemma":"mouton","pos":"NOM"} ,
		{"word":"moutonnement","word_nosc":"moutonnement","lemma":"moutonnement","pos":"NOM"} ,
		{"word":"moutonnements","word_nosc":"moutonnements","lemma":"moutonnement","pos":"NOM"} ,
		{"word":"moutonnerie","word_nosc":"moutonnerie","lemma":"moutonnerie","pos":"NOM"} ,
		{"word":"moutons","word_nosc":"moutons","lemma":"mouton","pos":"NOM"} ,
		{"word":"mouture","word_nosc":"mouture","lemma":"mouture","pos":"NOM"} ,
		{"word":"moutures","word_nosc":"moutures","lemma":"mouture","pos":"NOM"} ,
		{"word":"mouvance","word_nosc":"mouvance","lemma":"mouvance","pos":"NOM"} ,
		{"word":"mouvances","word_nosc":"mouvances","lemma":"mouvance","pos":"NOM"} ,
		{"word":"mouvement","word_nosc":"mouvement","lemma":"mouvement","pos":"NOM"} ,
		{"word":"mouvements","word_nosc":"mouvements","lemma":"mouvement","pos":"NOM"} ,
		{"word":"moyen","word_nosc":"moyen","lemma":"moyen","pos":"NOM"} ,
		{"word":"moyen-oriental","word_nosc":"moyen-oriental","lemma":"moyen-oriental","pos":"NOM"} ,
		{"word":"moyen-âge","word_nosc":"moyen-age","lemma":"moyen-âge","pos":"NOM"} ,
		{"word":"moyenne","word_nosc":"moyenne","lemma":"moyenne","pos":"NOM"} ,
		{"word":"moyennes","word_nosc":"moyennes","lemma":"moyenne","pos":"NOM"} ,
		{"word":"moyens","word_nosc":"moyens","lemma":"moyen","pos":"NOM"} ,
		{"word":"moyeu","word_nosc":"moyeu","lemma":"moyeu","pos":"NOM"} ,
		{"word":"moyeux","word_nosc":"moyeux","lemma":"moyeux","pos":"NOM"} ,
		{"word":"mozarabe","word_nosc":"mozarabe","lemma":"mozarabe","pos":"NOM"} ,
		{"word":"mozzarella","word_nosc":"mozzarella","lemma":"mozzarella","pos":"NOM"} ,
		{"word":"mozzarelle","word_nosc":"mozzarelle","lemma":"mozzarelle","pos":"NOM"} ,
		{"word":"moïse","word_nosc":"moise","lemma":"moïse","pos":"NOM"} ,
		{"word":"moût","word_nosc":"mout","lemma":"moût","pos":"NOM"} ,
		{"word":"moûts","word_nosc":"mouts","lemma":"moût","pos":"NOM"} ,
		{"word":"muchacho","word_nosc":"muchacho","lemma":"muchacho","pos":"NOM"} ,
		{"word":"muchachos","word_nosc":"muchachos","lemma":"muchacho","pos":"NOM"} ,
		{"word":"mucilage","word_nosc":"mucilage","lemma":"mucilage","pos":"NOM"} ,
		{"word":"mucor","word_nosc":"mucor","lemma":"mucor","pos":"NOM"} ,
		{"word":"mucosité","word_nosc":"mucosite","lemma":"mucosité","pos":"NOM"} ,
		{"word":"mucosités","word_nosc":"mucosites","lemma":"mucosité","pos":"NOM"} ,
		{"word":"mucoviscidose","word_nosc":"mucoviscidose","lemma":"mucoviscidose","pos":"NOM"} ,
		{"word":"mucus","word_nosc":"mucus","lemma":"mucus","pos":"NOM"} ,
		{"word":"mudéjar","word_nosc":"mudejar","lemma":"mudéjar","pos":"NOM"} ,
		{"word":"mue","word_nosc":"mue","lemma":"mue","pos":"NOM"} ,
		{"word":"mues","word_nosc":"mues","lemma":"mue","pos":"NOM"} ,
		{"word":"muesli","word_nosc":"muesli","lemma":"muesli","pos":"NOM"} ,
		{"word":"muet","word_nosc":"muet","lemma":"muet","pos":"NOM"} ,
		{"word":"muets","word_nosc":"muets","lemma":"muet","pos":"NOM"} ,
		{"word":"muette","word_nosc":"muette","lemma":"muette","pos":"NOM"} ,
		{"word":"muettes","word_nosc":"muettes","lemma":"muette","pos":"NOM"} ,
		{"word":"muezzin","word_nosc":"muezzin","lemma":"muezzin","pos":"NOM"} ,
		{"word":"muezzins","word_nosc":"muezzins","lemma":"muezzin","pos":"NOM"} ,
		{"word":"muffin","word_nosc":"muffin","lemma":"muffin","pos":"NOM"} ,
		{"word":"muffins","word_nosc":"muffins","lemma":"muffin","pos":"NOM"} ,
		{"word":"mufle","word_nosc":"mufle","lemma":"mufle","pos":"NOM"} ,
		{"word":"muflerie","word_nosc":"muflerie","lemma":"muflerie","pos":"NOM"} ,
		{"word":"mufleries","word_nosc":"mufleries","lemma":"muflerie","pos":"NOM"} ,
		{"word":"mufles","word_nosc":"mufles","lemma":"mufle","pos":"NOM"} ,
		{"word":"muflier","word_nosc":"muflier","lemma":"muflier","pos":"NOM"} ,
		{"word":"mufliers","word_nosc":"mufliers","lemma":"muflier","pos":"NOM"} ,
		{"word":"muflée","word_nosc":"muflee","lemma":"muflée","pos":"NOM"} ,
		{"word":"mufti","word_nosc":"mufti","lemma":"mufti","pos":"NOM"} ,
		{"word":"muftis","word_nosc":"muftis","lemma":"mufti","pos":"NOM"} ,
		{"word":"muges","word_nosc":"muges","lemma":"muge","pos":"NOM"} ,
		{"word":"mugissement","word_nosc":"mugissement","lemma":"mugissement","pos":"NOM"} ,
		{"word":"mugissements","word_nosc":"mugissements","lemma":"mugissement","pos":"NOM"} ,
		{"word":"muguet","word_nosc":"muguet","lemma":"muguet","pos":"NOM"} ,
		{"word":"muguets","word_nosc":"muguets","lemma":"muguet","pos":"NOM"} ,
		{"word":"muids","word_nosc":"muids","lemma":"muid","pos":"NOM"} ,
		{"word":"mule","word_nosc":"mule","lemma":"mule","pos":"NOM"} ,
		{"word":"mules","word_nosc":"mules","lemma":"mule","pos":"NOM"} ,
		{"word":"mulet","word_nosc":"mulet","lemma":"mulet","pos":"NOM"} ,
		{"word":"muleta","word_nosc":"muleta","lemma":"muleta","pos":"NOM"} ,
		{"word":"muletier","word_nosc":"muletier","lemma":"muletier","pos":"NOM"} ,
		{"word":"muletiers","word_nosc":"muletiers","lemma":"muletier","pos":"NOM"} ,
		{"word":"mulets","word_nosc":"mulets","lemma":"mulet","pos":"NOM"} ,
		{"word":"mulettes","word_nosc":"mulettes","lemma":"mulette","pos":"NOM"} ,
		{"word":"mullah","word_nosc":"mullah","lemma":"mullah","pos":"NOM"} ,
		{"word":"mulon","word_nosc":"mulon","lemma":"mulon","pos":"NOM"} ,
		{"word":"mulot","word_nosc":"mulot","lemma":"mulot","pos":"NOM"} ,
		{"word":"mulots","word_nosc":"mulots","lemma":"mulot","pos":"NOM"} ,
		{"word":"multiculturalisme","word_nosc":"multiculturalisme","lemma":"multiculturalisme","pos":"NOM"} ,
		{"word":"multimilliardaire","word_nosc":"multimilliardaire","lemma":"multimilliardaire","pos":"NOM"} ,
		{"word":"multimillionnaire","word_nosc":"multimillionnaire","lemma":"multimillionnaire","pos":"NOM"} ,
		{"word":"multimillionnaires","word_nosc":"multimillionnaires","lemma":"multimillionnaire","pos":"NOM"} ,
		{"word":"multinationale","word_nosc":"multinationale","lemma":"multinationale","pos":"NOM"} ,
		{"word":"multinationales","word_nosc":"multinationales","lemma":"multinationale","pos":"NOM"} ,
		{"word":"multipare","word_nosc":"multipare","lemma":"multipare","pos":"NOM"} ,
		{"word":"multiple","word_nosc":"multiple","lemma":"multiple","pos":"NOM"} ,
		{"word":"multiples","word_nosc":"multiples","lemma":"multiple","pos":"NOM"} ,
		{"word":"multiplex","word_nosc":"multiplex","lemma":"multiplex","pos":"NOM"} ,
		{"word":"multiplexe","word_nosc":"multiplexe","lemma":"multiplexe","pos":"NOM"} ,
		{"word":"multiplexes","word_nosc":"multiplexes","lemma":"multiplexe","pos":"NOM"} ,
		{"word":"multiplication","word_nosc":"multiplication","lemma":"multiplication","pos":"NOM"} ,
		{"word":"multiplications","word_nosc":"multiplications","lemma":"multiplication","pos":"NOM"} ,
		{"word":"multiplicité","word_nosc":"multiplicite","lemma":"multiplicité","pos":"NOM"} ,
		{"word":"multiplicités","word_nosc":"multiplicites","lemma":"multiplicité","pos":"NOM"} ,
		{"word":"multiprise","word_nosc":"multiprise","lemma":"multiprise","pos":"NOM"} ,
		{"word":"multipropriété","word_nosc":"multipropriete","lemma":"multipropriété","pos":"NOM"} ,
		{"word":"multirécidiviste","word_nosc":"multirecidiviste","lemma":"multirécidiviste","pos":"NOM"} ,
		{"word":"multitude","word_nosc":"multitude","lemma":"multitude","pos":"NOM"} ,
		{"word":"multitudes","word_nosc":"multitudes","lemma":"multitude","pos":"NOM"} ,
		{"word":"multitâche","word_nosc":"multitache","lemma":"multitâche","pos":"NOM"} ,
		{"word":"multitâches","word_nosc":"multitaches","lemma":"multitâche","pos":"NOM"} ,
		{"word":"mulâtre","word_nosc":"mulatre","lemma":"mulâtre","pos":"NOM"} ,
		{"word":"mulâtres","word_nosc":"mulatres","lemma":"mulâtre","pos":"NOM"} ,
		{"word":"mulâtresse","word_nosc":"mulatresse","lemma":"mulâtresse","pos":"NOM"} ,
		{"word":"mulâtresses","word_nosc":"mulatresses","lemma":"mulâtresse","pos":"NOM"} ,
		{"word":"mungo","word_nosc":"mungo","lemma":"mungo","pos":"NOM"} ,
		{"word":"munich","word_nosc":"munich","lemma":"munich","pos":"NOM"} ,
		{"word":"munichois","word_nosc":"munichois","lemma":"munichois","pos":"NOM"} ,
		{"word":"municipalité","word_nosc":"municipalite","lemma":"municipalité","pos":"NOM"} ,
		{"word":"municipalités","word_nosc":"municipalites","lemma":"municipalité","pos":"NOM"} ,
		{"word":"municipe","word_nosc":"municipe","lemma":"municipe","pos":"NOM"} ,
		{"word":"municipes","word_nosc":"municipes","lemma":"municipe","pos":"NOM"} ,
		{"word":"munificence","word_nosc":"munificence","lemma":"munificence","pos":"NOM"} ,
		{"word":"munificences","word_nosc":"munificences","lemma":"munificence","pos":"NOM"} ,
		{"word":"munition","word_nosc":"munition","lemma":"munition","pos":"NOM"} ,
		{"word":"munitions","word_nosc":"munitions","lemma":"munition","pos":"NOM"} ,
		{"word":"munster","word_nosc":"munster","lemma":"munster","pos":"NOM"} ,
		{"word":"muon","word_nosc":"muon","lemma":"muon","pos":"NOM"} ,
		{"word":"muphti","word_nosc":"muphti","lemma":"muphti","pos":"NOM"} ,
		{"word":"muqueuse","word_nosc":"muqueuse","lemma":"muqueux","pos":"NOM"} ,
		{"word":"muqueuses","word_nosc":"muqueuses","lemma":"muqueuse","pos":"NOM"} ,
		{"word":"muqueuses","word_nosc":"muqueuses","lemma":"muqueux","pos":"NOM"} ,
		{"word":"mur","word_nosc":"mur","lemma":"mur","pos":"NOM"} ,
		{"word":"murage","word_nosc":"murage","lemma":"murage","pos":"NOM"} ,
		{"word":"muraille","word_nosc":"muraille","lemma":"muraille","pos":"NOM"} ,
		{"word":"murailles","word_nosc":"murailles","lemma":"muraille","pos":"NOM"} ,
		{"word":"muret","word_nosc":"muret","lemma":"muret","pos":"NOM"} ,
		{"word":"muretin","word_nosc":"muretin","lemma":"muretin","pos":"NOM"} ,
		{"word":"muretins","word_nosc":"muretins","lemma":"muretin","pos":"NOM"} ,
		{"word":"murets","word_nosc":"murets","lemma":"muret","pos":"NOM"} ,
		{"word":"murette","word_nosc":"murette","lemma":"murette","pos":"NOM"} ,
		{"word":"murettes","word_nosc":"murettes","lemma":"murette","pos":"NOM"} ,
		{"word":"murex","word_nosc":"murex","lemma":"murex","pos":"NOM"} ,
		{"word":"muridés","word_nosc":"murides","lemma":"muridé","pos":"NOM"} ,
		{"word":"murin","word_nosc":"murin","lemma":"murin","pos":"NOM"} ,
		{"word":"murmel","word_nosc":"murmel","lemma":"murmel","pos":"NOM"} ,
		{"word":"murmure","word_nosc":"murmure","lemma":"murmure","pos":"NOM"} ,
		{"word":"murmures","word_nosc":"murmures","lemma":"murmure","pos":"NOM"} ,
		{"word":"murs","word_nosc":"murs","lemma":"mur","pos":"NOM"} ,
		{"word":"murène","word_nosc":"murene","lemma":"murène","pos":"NOM"} ,
		{"word":"murènes","word_nosc":"murenes","lemma":"murène","pos":"NOM"} ,
		{"word":"musaraigne","word_nosc":"musaraigne","lemma":"musaraigne","pos":"NOM"} ,
		{"word":"musaraignes","word_nosc":"musaraignes","lemma":"musaraigne","pos":"NOM"} ,
		{"word":"musard","word_nosc":"musard","lemma":"musard","pos":"NOM"} ,
		{"word":"musardise","word_nosc":"musardise","lemma":"musardise","pos":"NOM"} ,
		{"word":"musards","word_nosc":"musards","lemma":"musard","pos":"NOM"} ,
		{"word":"musc","word_nosc":"musc","lemma":"musc","pos":"NOM"} ,
		{"word":"muscade","word_nosc":"muscade","lemma":"muscade","pos":"NOM"} ,
		{"word":"muscadelle","word_nosc":"muscadelle","lemma":"muscadelle","pos":"NOM"} ,
		{"word":"muscadet","word_nosc":"muscadet","lemma":"muscadet","pos":"NOM"} ,
		{"word":"muscadin","word_nosc":"muscadin","lemma":"muscadin","pos":"NOM"} ,
		{"word":"muscadine","word_nosc":"muscadine","lemma":"muscadine","pos":"NOM"} ,
		{"word":"muscat","word_nosc":"muscat","lemma":"muscat","pos":"NOM"} ,
		{"word":"muscats","word_nosc":"muscats","lemma":"muscat","pos":"NOM"} ,
		{"word":"muscle","word_nosc":"muscle","lemma":"muscle","pos":"NOM"} ,
		{"word":"muscles","word_nosc":"muscles","lemma":"muscle","pos":"NOM"} ,
		{"word":"muscovite","word_nosc":"muscovite","lemma":"muscovite","pos":"NOM"} ,
		{"word":"musculation","word_nosc":"musculation","lemma":"musculation","pos":"NOM"} ,
		{"word":"musculature","word_nosc":"musculature","lemma":"musculature","pos":"NOM"} ,
		{"word":"muse","word_nosc":"muse","lemma":"muse","pos":"NOM"} ,
		{"word":"museau","word_nosc":"museau","lemma":"museau","pos":"NOM"} ,
		{"word":"museaux","word_nosc":"museaux","lemma":"museau","pos":"NOM"} ,
		{"word":"muselière","word_nosc":"museliere","lemma":"muselière","pos":"NOM"} ,
		{"word":"muselières","word_nosc":"muselieres","lemma":"muselière","pos":"NOM"} ,
		{"word":"muserolle","word_nosc":"muserolle","lemma":"muserolle","pos":"NOM"} ,
		{"word":"muses","word_nosc":"muses","lemma":"muse","pos":"NOM"} ,
		{"word":"musette","word_nosc":"musette","lemma":"musette","pos":"NOM"} ,
		{"word":"musettes","word_nosc":"musettes","lemma":"musette","pos":"NOM"} ,
		{"word":"musettes-repas","word_nosc":"musettes-repas","lemma":"musette-repas","pos":"NOM"} ,
		{"word":"music hall","word_nosc":"music hall","lemma":"music-hall","pos":"NOM"} ,
		{"word":"music-hall","word_nosc":"music-hall","lemma":"music-hall","pos":"NOM"} ,
		{"word":"music-halls","word_nosc":"music-halls","lemma":"music-hall","pos":"NOM"} ,
		{"word":"musicalité","word_nosc":"musicalite","lemma":"musicalité","pos":"NOM"} ,
		{"word":"musicien","word_nosc":"musicien","lemma":"musicien","pos":"NOM"} ,
		{"word":"musicienne","word_nosc":"musicienne","lemma":"musicien","pos":"NOM"} ,
		{"word":"musiciennes","word_nosc":"musiciennes","lemma":"musicien","pos":"NOM"} ,
		{"word":"musiciens","word_nosc":"musiciens","lemma":"musicien","pos":"NOM"} ,
		{"word":"musicographes","word_nosc":"musicographes","lemma":"musicographe","pos":"NOM"} ,
		{"word":"musicologie","word_nosc":"musicologie","lemma":"musicologie","pos":"NOM"} ,
		{"word":"musicologue","word_nosc":"musicologue","lemma":"musicologue","pos":"NOM"} ,
		{"word":"musicologues","word_nosc":"musicologues","lemma":"musicologue","pos":"NOM"} ,
		{"word":"musicothérapie","word_nosc":"musicotherapie","lemma":"musicothérapie","pos":"NOM"} ,
		{"word":"musique","word_nosc":"musique","lemma":"musique","pos":"NOM"} ,
		{"word":"musiques","word_nosc":"musiques","lemma":"musique","pos":"NOM"} ,
		{"word":"musiquette","word_nosc":"musiquette","lemma":"musiquette","pos":"NOM"} ,
		{"word":"musiquettes","word_nosc":"musiquettes","lemma":"musiquette","pos":"NOM"} ,
		{"word":"muskogee","word_nosc":"muskogee","lemma":"muskogee","pos":"NOM"} ,
		{"word":"musoir","word_nosc":"musoir","lemma":"musoir","pos":"NOM"} ,
		{"word":"musqué","word_nosc":"musque","lemma":"musqué","pos":"NOM"} ,
		{"word":"must","word_nosc":"must","lemma":"must","pos":"NOM"} ,
		{"word":"mustang","word_nosc":"mustang","lemma":"mustang","pos":"NOM"} ,
		{"word":"mustangs","word_nosc":"mustangs","lemma":"mustang","pos":"NOM"} ,
		{"word":"mustélidés","word_nosc":"mustelides","lemma":"mustélidé","pos":"NOM"} ,
		{"word":"musulman","word_nosc":"musulman","lemma":"musulman","pos":"NOM"} ,
		{"word":"musulmane","word_nosc":"musulmane","lemma":"musulman","pos":"NOM"} ,
		{"word":"musulmanes","word_nosc":"musulmanes","lemma":"musulman","pos":"NOM"} ,
		{"word":"musulmans","word_nosc":"musulmans","lemma":"musulman","pos":"NOM"} ,
		{"word":"musée","word_nosc":"musee","lemma":"musée","pos":"NOM"} ,
		{"word":"musées","word_nosc":"musees","lemma":"musée","pos":"NOM"} ,
		{"word":"muséologie","word_nosc":"museologie","lemma":"muséologie","pos":"NOM"} ,
		{"word":"muséologue","word_nosc":"museologue","lemma":"muséologue","pos":"NOM"} ,
		{"word":"muséum","word_nosc":"museum","lemma":"muséum","pos":"NOM"} ,
		{"word":"mutabilité","word_nosc":"mutabilite","lemma":"mutabilité","pos":"NOM"} ,
		{"word":"mutant","word_nosc":"mutant","lemma":"mutant","pos":"NOM"} ,
		{"word":"mutante","word_nosc":"mutante","lemma":"mutant","pos":"NOM"} ,
		{"word":"mutantes","word_nosc":"mutantes","lemma":"mutant","pos":"NOM"} ,
		{"word":"mutants","word_nosc":"mutants","lemma":"mutant","pos":"NOM"} ,
		{"word":"mutation","word_nosc":"mutation","lemma":"mutation","pos":"NOM"} ,
		{"word":"mutations","word_nosc":"mutations","lemma":"mutation","pos":"NOM"} ,
		{"word":"mutilation","word_nosc":"mutilation","lemma":"mutilation","pos":"NOM"} ,
		{"word":"mutilations","word_nosc":"mutilations","lemma":"mutilation","pos":"NOM"} ,
		{"word":"mutilé","word_nosc":"mutile","lemma":"mutilé","pos":"NOM"} ,
		{"word":"mutilés","word_nosc":"mutiles","lemma":"mutilé","pos":"NOM"} ,
		{"word":"mutin","word_nosc":"mutin","lemma":"mutin","pos":"NOM"} ,
		{"word":"mutine","word_nosc":"mutine","lemma":"mutin","pos":"NOM"} ,
		{"word":"mutinerie","word_nosc":"mutinerie","lemma":"mutinerie","pos":"NOM"} ,
		{"word":"mutineries","word_nosc":"mutineries","lemma":"mutinerie","pos":"NOM"} ,
		{"word":"mutins","word_nosc":"mutins","lemma":"mutin","pos":"NOM"} ,
		{"word":"mutisme","word_nosc":"mutisme","lemma":"mutisme","pos":"NOM"} ,
		{"word":"mutismes","word_nosc":"mutismes","lemma":"mutisme","pos":"NOM"} ,
		{"word":"mutité","word_nosc":"mutite","lemma":"mutité","pos":"NOM"} ,
		{"word":"mutualisme","word_nosc":"mutualisme","lemma":"mutualisme","pos":"NOM"} ,
		{"word":"mutualité","word_nosc":"mutualite","lemma":"mutualité","pos":"NOM"} ,
		{"word":"mutualités","word_nosc":"mutualites","lemma":"mutualité","pos":"NOM"} ,
		{"word":"mutuelle","word_nosc":"mutuelle","lemma":"mutuel","pos":"NOM"} ,
		{"word":"mutuelles","word_nosc":"mutuelles","lemma":"mutuel","pos":"NOM"} ,
		{"word":"myasthénie","word_nosc":"myasthenie","lemma":"myasthénie","pos":"NOM"} ,
		{"word":"mycologie","word_nosc":"mycologie","lemma":"mycologie","pos":"NOM"} ,
		{"word":"mycologue","word_nosc":"mycologue","lemma":"mycologue","pos":"NOM"} ,
		{"word":"mycologues","word_nosc":"mycologues","lemma":"mycologue","pos":"NOM"} ,
		{"word":"mycose","word_nosc":"mycose","lemma":"mycose","pos":"NOM"} ,
		{"word":"mycène","word_nosc":"mycene","lemma":"mycène","pos":"NOM"} ,
		{"word":"mycéniens","word_nosc":"myceniens","lemma":"mycénien","pos":"NOM"} ,
		{"word":"mygale","word_nosc":"mygale","lemma":"mygale","pos":"NOM"} ,
		{"word":"mygales","word_nosc":"mygales","lemma":"mygale","pos":"NOM"} ,
		{"word":"mylord","word_nosc":"mylord","lemma":"mylord","pos":"NOM"} ,
		{"word":"myocarde","word_nosc":"myocarde","lemma":"myocarde","pos":"NOM"} ,
		{"word":"myocardiopathie","word_nosc":"myocardiopathie","lemma":"myocardiopathie","pos":"NOM"} ,
		{"word":"myocardite","word_nosc":"myocardite","lemma":"myocardite","pos":"NOM"} ,
		{"word":"myoclonie","word_nosc":"myoclonie","lemma":"myoclonie","pos":"NOM"} ,
		{"word":"myoglobine","word_nosc":"myoglobine","lemma":"myoglobine","pos":"NOM"} ,
		{"word":"myographe","word_nosc":"myographe","lemma":"myographe","pos":"NOM"} ,
		{"word":"myopathes","word_nosc":"myopathes","lemma":"myopathe","pos":"NOM"} ,
		{"word":"myopathie","word_nosc":"myopathie","lemma":"myopathie","pos":"NOM"} ,
		{"word":"myope","word_nosc":"myope","lemma":"myope","pos":"NOM"} ,
		{"word":"myopes","word_nosc":"myopes","lemma":"myope","pos":"NOM"} ,
		{"word":"myopie","word_nosc":"myopie","lemma":"myopie","pos":"NOM"} ,
		{"word":"myopies","word_nosc":"myopies","lemma":"myopie","pos":"NOM"} ,
		{"word":"myosine","word_nosc":"myosine","lemma":"myosine","pos":"NOM"} ,
		{"word":"myosis","word_nosc":"myosis","lemma":"myosis","pos":"NOM"} ,
		{"word":"myosotis","word_nosc":"myosotis","lemma":"myosotis","pos":"NOM"} ,
		{"word":"myriade","word_nosc":"myriade","lemma":"myriade","pos":"NOM"} ,
		{"word":"myriades","word_nosc":"myriades","lemma":"myriade","pos":"NOM"} ,
		{"word":"myriophylle","word_nosc":"myriophylle","lemma":"myriophylle","pos":"NOM"} ,
		{"word":"myrmidon","word_nosc":"myrmidon","lemma":"myrmidon","pos":"NOM"} ,
		{"word":"myrmidons","word_nosc":"myrmidons","lemma":"myrmidon","pos":"NOM"} ,
		{"word":"myrrhe","word_nosc":"myrrhe","lemma":"myrrhe","pos":"NOM"} ,
		{"word":"myrte","word_nosc":"myrte","lemma":"myrte","pos":"NOM"} ,
		{"word":"myrtes","word_nosc":"myrtes","lemma":"myrte","pos":"NOM"} ,
		{"word":"myrtille","word_nosc":"myrtille","lemma":"myrtille","pos":"NOM"} ,
		{"word":"myrtilles","word_nosc":"myrtilles","lemma":"myrtille","pos":"NOM"} ,
		{"word":"mystagogie","word_nosc":"mystagogie","lemma":"mystagogie","pos":"NOM"} ,
		{"word":"mystagogue","word_nosc":"mystagogue","lemma":"mystagogue","pos":"NOM"} ,
		{"word":"myste","word_nosc":"myste","lemma":"myste","pos":"NOM"} ,
		{"word":"mysticisme","word_nosc":"mysticisme","lemma":"mysticisme","pos":"NOM"} ,
		{"word":"mysticismes","word_nosc":"mysticismes","lemma":"mysticisme","pos":"NOM"} ,
		{"word":"mystificateur","word_nosc":"mystificateur","lemma":"mystificateur","pos":"NOM"} ,
		{"word":"mystificateurs","word_nosc":"mystificateurs","lemma":"mystificateur","pos":"NOM"} ,
		{"word":"mystification","word_nosc":"mystification","lemma":"mystification","pos":"NOM"} ,
		{"word":"mystifications","word_nosc":"mystifications","lemma":"mystification","pos":"NOM"} ,
		{"word":"mystificatrice","word_nosc":"mystificatrice","lemma":"mystificateur","pos":"NOM"} ,
		{"word":"mystique","word_nosc":"mystique","lemma":"mystique","pos":"NOM"} ,
		{"word":"mystiques","word_nosc":"mystiques","lemma":"mystique","pos":"NOM"} ,
		{"word":"mystère","word_nosc":"mystere","lemma":"mystère","pos":"NOM"} ,
		{"word":"mystères","word_nosc":"mysteres","lemma":"mystère","pos":"NOM"} ,
		{"word":"mythe","word_nosc":"mythe","lemma":"mythe","pos":"NOM"} ,
		{"word":"mythes","word_nosc":"mythes","lemma":"mythe","pos":"NOM"} ,
		{"word":"mythisation","word_nosc":"mythisation","lemma":"mythisation","pos":"NOM"} ,
		{"word":"mythologie","word_nosc":"mythologie","lemma":"mythologie","pos":"NOM"} ,
		{"word":"mythologies","word_nosc":"mythologies","lemma":"mythologie","pos":"NOM"} ,
		{"word":"mythologue","word_nosc":"mythologue","lemma":"mythologue","pos":"NOM"} ,
		{"word":"mythologues","word_nosc":"mythologues","lemma":"mythologue","pos":"NOM"} ,
		{"word":"mythomane","word_nosc":"mythomane","lemma":"mythomane","pos":"NOM"} ,
		{"word":"mythomanes","word_nosc":"mythomanes","lemma":"mythomane","pos":"NOM"} ,
		{"word":"mythomanie","word_nosc":"mythomanie","lemma":"mythomanie","pos":"NOM"} ,
		{"word":"myxoedémateuse","word_nosc":"myxoedemateuse","lemma":"myxoedémateux","pos":"NOM"} ,
		{"word":"myxomatose","word_nosc":"myxomatose","lemma":"myxomatose","pos":"NOM"} ,
		{"word":"myéline","word_nosc":"myeline","lemma":"myéline","pos":"NOM"} ,
		{"word":"myélite","word_nosc":"myelite","lemma":"myélite","pos":"NOM"} ,
		{"word":"myéloblaste","word_nosc":"myeloblaste","lemma":"myéloblaste","pos":"NOM"} ,
		{"word":"myéloblastes","word_nosc":"myeloblastes","lemma":"myéloblaste","pos":"NOM"} ,
		{"word":"mâchage","word_nosc":"machage","lemma":"mâchage","pos":"NOM"} ,
		{"word":"mâche","word_nosc":"mache","lemma":"mâche","pos":"NOM"} ,
		{"word":"mâchefer","word_nosc":"machefer","lemma":"mâchefer","pos":"NOM"} ,
		{"word":"mâcheur","word_nosc":"macheur","lemma":"mâcheur","pos":"NOM"} ,
		{"word":"mâcheurs","word_nosc":"macheurs","lemma":"mâcheur","pos":"NOM"} ,
		{"word":"mâcheuse","word_nosc":"macheuse","lemma":"mâcheur","pos":"NOM"} ,
		{"word":"mâchicoulis","word_nosc":"machicoulis","lemma":"mâchicoulis","pos":"NOM"} ,
		{"word":"mâchoire","word_nosc":"machoire","lemma":"mâchoire","pos":"NOM"} ,
		{"word":"mâchoires","word_nosc":"machoires","lemma":"mâchoire","pos":"NOM"} ,
		{"word":"mâchonnements","word_nosc":"machonnements","lemma":"mâchonnement","pos":"NOM"} ,
		{"word":"mâchouilleur","word_nosc":"machouilleur","lemma":"mâchouilleur","pos":"NOM"} ,
		{"word":"mâle","word_nosc":"male","lemma":"mâle","pos":"NOM"} ,
		{"word":"mâles","word_nosc":"males","lemma":"mâle","pos":"NOM"} ,
		{"word":"mânes","word_nosc":"manes","lemma":"mânes","pos":"NOM"} ,
		{"word":"mât","word_nosc":"mat","lemma":"mât","pos":"NOM"} ,
		{"word":"mâtin","word_nosc":"matin","lemma":"mâtin","pos":"NOM"} ,
		{"word":"mâtine","word_nosc":"matine","lemma":"mâtine","pos":"NOM"} ,
		{"word":"mâtines","word_nosc":"matines","lemma":"mâtine","pos":"NOM"} ,
		{"word":"mâtins","word_nosc":"matins","lemma":"mâtin","pos":"NOM"} ,
		{"word":"mâts","word_nosc":"mats","lemma":"mât","pos":"NOM"} ,
		{"word":"mâture","word_nosc":"mature","lemma":"mâture","pos":"NOM"} ,
		{"word":"mâtures","word_nosc":"matures","lemma":"mâture","pos":"NOM"} ,
		{"word":"mèche","word_nosc":"meche","lemma":"mèche","pos":"NOM"} ,
		{"word":"mèches","word_nosc":"meches","lemma":"mèche","pos":"NOM"} ,
		{"word":"mère","word_nosc":"mere","lemma":"mère","pos":"NOM"} ,
		{"word":"mère-grand","word_nosc":"mere-grand","lemma":"mère-grand","pos":"NOM"} ,
		{"word":"mère-patrie","word_nosc":"mere-patrie","lemma":"mère-patrie","pos":"NOM"} ,
		{"word":"mères","word_nosc":"meres","lemma":"mère","pos":"NOM"} ,
		{"word":"mètre","word_nosc":"metre","lemma":"mètre","pos":"NOM"} ,
		{"word":"mètres","word_nosc":"metres","lemma":"mètre","pos":"NOM"} ,
		{"word":"méandre","word_nosc":"meandre","lemma":"méandre","pos":"NOM"} ,
		{"word":"méandres","word_nosc":"meandres","lemma":"méandre","pos":"NOM"} ,
		{"word":"méandrine","word_nosc":"meandrine","lemma":"méandrine","pos":"NOM"} ,
		{"word":"méat","word_nosc":"meat","lemma":"méat","pos":"NOM"} ,
		{"word":"mécanicien","word_nosc":"mecanicien","lemma":"mécanicien","pos":"NOM"} ,
		{"word":"mécanicienne","word_nosc":"mecanicienne","lemma":"mécanicien","pos":"NOM"} ,
		{"word":"mécaniciennes","word_nosc":"mecaniciennes","lemma":"mécanicien","pos":"NOM"} ,
		{"word":"mécaniciens","word_nosc":"mecaniciens","lemma":"mécanicien","pos":"NOM"} ,
		{"word":"mécanique","word_nosc":"mecanique","lemma":"mécanique","pos":"NOM"} ,
		{"word":"mécaniques","word_nosc":"mecaniques","lemma":"mécanique","pos":"NOM"} ,
		{"word":"mécanisation","word_nosc":"mecanisation","lemma":"mécanisation","pos":"NOM"} ,
		{"word":"mécanisme","word_nosc":"mecanisme","lemma":"mécanisme","pos":"NOM"} ,
		{"word":"mécanismes","word_nosc":"mecanismes","lemma":"mécanisme","pos":"NOM"} ,
		{"word":"mécano","word_nosc":"mecano","lemma":"mécano","pos":"NOM"} ,
		{"word":"mécanos","word_nosc":"mecanos","lemma":"mécano","pos":"NOM"} ,
		{"word":"méchanceté","word_nosc":"mechancete","lemma":"méchanceté","pos":"NOM"} ,
		{"word":"méchancetés","word_nosc":"mechancetes","lemma":"méchanceté","pos":"NOM"} ,
		{"word":"méchant","word_nosc":"mechant","lemma":"méchant","pos":"NOM"} ,
		{"word":"méchants","word_nosc":"mechants","lemma":"méchant","pos":"NOM"} ,
		{"word":"méchoui","word_nosc":"mechoui","lemma":"méchoui","pos":"NOM"} ,
		{"word":"méchouis","word_nosc":"mechouis","lemma":"méchoui","pos":"NOM"} ,
		{"word":"mécompte","word_nosc":"mecompte","lemma":"mécompte","pos":"NOM"} ,
		{"word":"mécomptes","word_nosc":"mecomptes","lemma":"mécompte","pos":"NOM"} ,
		{"word":"méconduite","word_nosc":"meconduite","lemma":"méconduite","pos":"NOM"} ,
		{"word":"méconium","word_nosc":"meconium","lemma":"méconium","pos":"NOM"} ,
		{"word":"méconnaissance","word_nosc":"meconnaissance","lemma":"méconnaissance","pos":"NOM"} ,
		{"word":"méconnu","word_nosc":"meconnu","lemma":"méconnu","pos":"NOM"} ,
		{"word":"méconnus","word_nosc":"meconnus","lemma":"méconnu","pos":"NOM"} ,
		{"word":"mécontent","word_nosc":"mecontent","lemma":"mécontent","pos":"NOM"} ,
		{"word":"mécontente","word_nosc":"mecontente","lemma":"mécontent","pos":"NOM"} ,
		{"word":"mécontentement","word_nosc":"mecontentement","lemma":"mécontentement","pos":"NOM"} ,
		{"word":"mécontentements","word_nosc":"mecontentements","lemma":"mécontentement","pos":"NOM"} ,
		{"word":"mécontents","word_nosc":"mecontents","lemma":"mécontent","pos":"NOM"} ,
		{"word":"mécréance","word_nosc":"mecreance","lemma":"mécréance","pos":"NOM"} ,
		{"word":"mécréant","word_nosc":"mecreant","lemma":"mécréant","pos":"NOM"} ,
		{"word":"mécréants","word_nosc":"mecreants","lemma":"mécréant","pos":"NOM"} ,
		{"word":"mécène","word_nosc":"mecene","lemma":"mécène","pos":"NOM"} ,
		{"word":"mécènes","word_nosc":"mecenes","lemma":"mécène","pos":"NOM"} ,
		{"word":"mécénat","word_nosc":"mecenat","lemma":"mécénat","pos":"NOM"} ,
		{"word":"médaille","word_nosc":"medaille","lemma":"médaille","pos":"NOM"} ,
		{"word":"médailles","word_nosc":"medailles","lemma":"médaille","pos":"NOM"} ,
		{"word":"médailleurs","word_nosc":"medailleurs","lemma":"médailleur","pos":"NOM"} ,
		{"word":"médaillon","word_nosc":"medaillon","lemma":"médaillon","pos":"NOM"} ,
		{"word":"médaillons","word_nosc":"medaillons","lemma":"médaillon","pos":"NOM"} ,
		{"word":"médaillé","word_nosc":"medaille","lemma":"médaillé","pos":"NOM"} ,
		{"word":"médaillée","word_nosc":"medaillee","lemma":"médaillé","pos":"NOM"} ,
		{"word":"médecin","word_nosc":"medecin","lemma":"médecin","pos":"NOM"} ,
		{"word":"médecin-chef","word_nosc":"medecin-chef","lemma":"médecin-chef","pos":"NOM"} ,
		{"word":"médecin-conseil","word_nosc":"medecin-conseil","lemma":"médecin-conseil","pos":"NOM"} ,
		{"word":"médecin-général","word_nosc":"medecin-general","lemma":"médecin-général","pos":"NOM"} ,
		{"word":"médecin-major","word_nosc":"medecin-major","lemma":"médecin-major","pos":"NOM"} ,
		{"word":"médecine","word_nosc":"medecine","lemma":"médecine","pos":"NOM"} ,
		{"word":"médecine-ball","word_nosc":"medecine-ball","lemma":"médecine-ball","pos":"NOM"} ,
		{"word":"médecines","word_nosc":"medecines","lemma":"médecine","pos":"NOM"} ,
		{"word":"médecins","word_nosc":"medecins","lemma":"médecin","pos":"NOM"} ,
		{"word":"média","word_nosc":"media","lemma":"média","pos":"NOM"} ,
		{"word":"médiane","word_nosc":"mediane","lemma":"médiane","pos":"NOM"} ,
		{"word":"médianoche","word_nosc":"medianoche","lemma":"médianoche","pos":"NOM"} ,
		{"word":"médias","word_nosc":"medias","lemma":"média","pos":"NOM"} ,
		{"word":"médiascope","word_nosc":"mediascope","lemma":"médiascope","pos":"NOM"} ,
		{"word":"médiastin","word_nosc":"mediastin","lemma":"médiastin","pos":"NOM"} ,
		{"word":"médiateur","word_nosc":"mediateur","lemma":"médiateur","pos":"NOM"} ,
		{"word":"médiateurs","word_nosc":"mediateurs","lemma":"médiateur","pos":"NOM"} ,
		{"word":"médiation","word_nosc":"mediation","lemma":"médiation","pos":"NOM"} ,
		{"word":"médiatisation","word_nosc":"mediatisation","lemma":"médiatisation","pos":"NOM"} ,
		{"word":"médiator","word_nosc":"mediator","lemma":"médiator","pos":"NOM"} ,
		{"word":"médiatrice","word_nosc":"mediatrice","lemma":"médiateur","pos":"NOM"} ,
		{"word":"médiatrices","word_nosc":"mediatrices","lemma":"médiateur","pos":"NOM"} ,
		{"word":"médicalisation","word_nosc":"medicalisation","lemma":"médicalisation","pos":"NOM"} ,
		{"word":"médicament","word_nosc":"medicament","lemma":"médicament","pos":"NOM"} ,
		{"word":"médicamentation","word_nosc":"medicamentation","lemma":"médicamentation","pos":"NOM"} ,
		{"word":"médicaments","word_nosc":"medicaments","lemma":"médicament","pos":"NOM"} ,
		{"word":"médicastre","word_nosc":"medicastre","lemma":"médicastre","pos":"NOM"} ,
		{"word":"médicastres","word_nosc":"medicastres","lemma":"médicastre","pos":"NOM"} ,
		{"word":"médication","word_nosc":"medication","lemma":"médication","pos":"NOM"} ,
		{"word":"médications","word_nosc":"medications","lemma":"médication","pos":"NOM"} ,
		{"word":"médina","word_nosc":"medina","lemma":"médina","pos":"NOM"} ,
		{"word":"médinas","word_nosc":"medinas","lemma":"médina","pos":"NOM"} ,
		{"word":"médiocre","word_nosc":"mediocre","lemma":"médiocre","pos":"NOM"} ,
		{"word":"médiocres","word_nosc":"mediocres","lemma":"médiocre","pos":"NOM"} ,
		{"word":"médiocrité","word_nosc":"mediocrite","lemma":"médiocrité","pos":"NOM"} ,
		{"word":"médiocrités","word_nosc":"mediocrites","lemma":"médiocrité","pos":"NOM"} ,
		{"word":"médisance","word_nosc":"medisance","lemma":"médisance","pos":"NOM"} ,
		{"word":"médisances","word_nosc":"medisances","lemma":"médisance","pos":"NOM"} ,
		{"word":"médisant","word_nosc":"medisant","lemma":"médisant","pos":"NOM"} ,
		{"word":"médisante","word_nosc":"medisante","lemma":"médisant","pos":"NOM"} ,
		{"word":"médisants","word_nosc":"medisants","lemma":"médisant","pos":"NOM"} ,
		{"word":"méditant","word_nosc":"meditant","lemma":"méditant","pos":"NOM"} ,
		{"word":"méditatifs","word_nosc":"meditatifs","lemma":"méditatif","pos":"NOM"} ,
		{"word":"méditation","word_nosc":"meditation","lemma":"méditation","pos":"NOM"} ,
		{"word":"méditations","word_nosc":"meditations","lemma":"méditation","pos":"NOM"} ,
		{"word":"méditerranéen","word_nosc":"mediterraneen","lemma":"méditerranéen","pos":"NOM"} ,
		{"word":"méditerranéennes","word_nosc":"mediterraneennes","lemma":"méditerranéenne","pos":"NOM"} ,
		{"word":"méditerranéens","word_nosc":"mediterraneens","lemma":"méditerranéen","pos":"NOM"} ,
		{"word":"médium","word_nosc":"medium","lemma":"médium","pos":"NOM"} ,
		{"word":"médiumnité","word_nosc":"mediumnite","lemma":"médiumnité","pos":"NOM"} ,
		{"word":"médiums","word_nosc":"mediums","lemma":"médium","pos":"NOM"} ,
		{"word":"médius","word_nosc":"medius","lemma":"médius","pos":"NOM"} ,
		{"word":"médiéviste","word_nosc":"medieviste","lemma":"médiéviste","pos":"NOM"} ,
		{"word":"médiévistes","word_nosc":"medievistes","lemma":"médiéviste","pos":"NOM"} ,
		{"word":"médoc","word_nosc":"medoc","lemma":"médoc","pos":"NOM"} ,
		{"word":"médocs","word_nosc":"medocs","lemma":"médoc","pos":"NOM"} ,
		{"word":"médulla","word_nosc":"medulla","lemma":"médulla","pos":"NOM"} ,
		{"word":"méduse","word_nosc":"meduse","lemma":"méduse","pos":"NOM"} ,
		{"word":"méduses","word_nosc":"meduses","lemma":"méduse","pos":"NOM"} ,
		{"word":"méfait","word_nosc":"mefait","lemma":"méfait","pos":"NOM"} ,
		{"word":"méfaits","word_nosc":"mefaits","lemma":"méfait","pos":"NOM"} ,
		{"word":"méfiance","word_nosc":"mefiance","lemma":"méfiance","pos":"NOM"} ,
		{"word":"méfiances","word_nosc":"mefiances","lemma":"méfiance","pos":"NOM"} ,
		{"word":"méfiant","word_nosc":"mefiant","lemma":"méfiant","pos":"NOM"} ,
		{"word":"méfiants","word_nosc":"mefiants","lemma":"méfiant","pos":"NOM"} ,
		{"word":"méforme","word_nosc":"meforme","lemma":"méforme","pos":"NOM"} ,
		{"word":"mégacôlon","word_nosc":"megacolon","lemma":"mégacôlon","pos":"NOM"} ,
		{"word":"mégahertz","word_nosc":"megahertz","lemma":"mégahertz","pos":"NOM"} ,
		{"word":"mégajoules","word_nosc":"megajoules","lemma":"mégajoule","pos":"NOM"} ,
		{"word":"mégalithe","word_nosc":"megalithe","lemma":"mégalithe","pos":"NOM"} ,
		{"word":"mégalo","word_nosc":"megalo","lemma":"mégalo","pos":"NOM"} ,
		{"word":"mégalomane","word_nosc":"megalomane","lemma":"mégalomane","pos":"NOM"} ,
		{"word":"mégalomanie","word_nosc":"megalomanie","lemma":"mégalomanie","pos":"NOM"} ,
		{"word":"mégalopole","word_nosc":"megalopole","lemma":"mégalopole","pos":"NOM"} ,
		{"word":"mégalos","word_nosc":"megalos","lemma":"mégalo","pos":"NOM"} ,
		{"word":"mégalosaure","word_nosc":"megalosaure","lemma":"mégalosaure","pos":"NOM"} ,
		{"word":"mégaphone","word_nosc":"megaphone","lemma":"mégaphone","pos":"NOM"} ,
		{"word":"mégaphones","word_nosc":"megaphones","lemma":"mégaphone","pos":"NOM"} ,
		{"word":"mégapole","word_nosc":"megapole","lemma":"mégapole","pos":"NOM"} ,
		{"word":"mégaron","word_nosc":"megaron","lemma":"mégaron","pos":"NOM"} ,
		{"word":"mégathériums","word_nosc":"megatheriums","lemma":"mégathérium","pos":"NOM"} ,
		{"word":"mégatonne","word_nosc":"megatonne","lemma":"mégatonne","pos":"NOM"} ,
		{"word":"mégatonnes","word_nosc":"megatonnes","lemma":"mégatonne","pos":"NOM"} ,
		{"word":"mégawatts","word_nosc":"megawatts","lemma":"mégawatt","pos":"NOM"} ,
		{"word":"mégisserie","word_nosc":"megisserie","lemma":"mégisserie","pos":"NOM"} ,
		{"word":"mégissiers","word_nosc":"megissiers","lemma":"mégissier","pos":"NOM"} ,
		{"word":"mégot","word_nosc":"megot","lemma":"mégot","pos":"NOM"} ,
		{"word":"mégotage","word_nosc":"megotage","lemma":"mégotage","pos":"NOM"} ,
		{"word":"mégotages","word_nosc":"megotages","lemma":"mégotage","pos":"NOM"} ,
		{"word":"mégots","word_nosc":"megots","lemma":"mégot","pos":"NOM"} ,
		{"word":"mégère","word_nosc":"megere","lemma":"méger","pos":"NOM"} ,
		{"word":"mégères","word_nosc":"megeres","lemma":"méger","pos":"NOM"} ,
		{"word":"mégères","word_nosc":"megeres","lemma":"mégère","pos":"NOM"} ,
		{"word":"méhari","word_nosc":"mehari","lemma":"méhari","pos":"NOM"} ,
		{"word":"méhariste","word_nosc":"mehariste","lemma":"méhariste","pos":"NOM"} ,
		{"word":"méharistes","word_nosc":"meharistes","lemma":"méhariste","pos":"NOM"} ,
		{"word":"méiose","word_nosc":"meiose","lemma":"méiose","pos":"NOM"} ,
		{"word":"méjugement","word_nosc":"mejugement","lemma":"méjugement","pos":"NOM"} ,
		{"word":"mélancolie","word_nosc":"melancolie","lemma":"mélancolie","pos":"NOM"} ,
		{"word":"mélancolies","word_nosc":"melancolies","lemma":"mélancolie","pos":"NOM"} ,
		{"word":"mélancolique","word_nosc":"melancolique","lemma":"mélancolique","pos":"NOM"} ,
		{"word":"mélancoliques","word_nosc":"melancoliques","lemma":"mélancolique","pos":"NOM"} ,
		{"word":"mélange","word_nosc":"melange","lemma":"mélange","pos":"NOM"} ,
		{"word":"mélanges","word_nosc":"melanges","lemma":"mélange","pos":"NOM"} ,
		{"word":"mélangeur","word_nosc":"melangeur","lemma":"mélangeur","pos":"NOM"} ,
		{"word":"mélangeurs","word_nosc":"melangeurs","lemma":"mélangeur","pos":"NOM"} ,
		{"word":"mélanine","word_nosc":"melanine","lemma":"mélanine","pos":"NOM"} ,
		{"word":"mélanome","word_nosc":"melanome","lemma":"mélanome","pos":"NOM"} ,
		{"word":"mélanomes","word_nosc":"melanomes","lemma":"mélanome","pos":"NOM"} ,
		{"word":"mélanésiennes","word_nosc":"melanesiennes","lemma":"mélanésienne","pos":"NOM"} ,
		{"word":"mélasse","word_nosc":"melasse","lemma":"mélasse","pos":"NOM"} ,
		{"word":"mélatonine","word_nosc":"melatonine","lemma":"mélatonine","pos":"NOM"} ,
		{"word":"méli-mélo","word_nosc":"meli-melo","lemma":"méli-mélo","pos":"NOM"} ,
		{"word":"mélilot","word_nosc":"melilot","lemma":"mélilot","pos":"NOM"} ,
		{"word":"mélinite","word_nosc":"melinite","lemma":"mélinite","pos":"NOM"} ,
		{"word":"mélis-mélos","word_nosc":"melis-melos","lemma":"méli-mélo","pos":"NOM"} ,
		{"word":"mélisse","word_nosc":"melisse","lemma":"mélisse","pos":"NOM"} ,
		{"word":"mélo","word_nosc":"melo","lemma":"mélo","pos":"NOM"} ,
		{"word":"mélodie","word_nosc":"melodie","lemma":"mélodie","pos":"NOM"} ,
		{"word":"mélodies","word_nosc":"melodies","lemma":"mélodie","pos":"NOM"} ,
		{"word":"mélodrame","word_nosc":"melodrame","lemma":"mélodrame","pos":"NOM"} ,
		{"word":"mélodrames","word_nosc":"melodrames","lemma":"mélodrame","pos":"NOM"} ,
		{"word":"mélomane","word_nosc":"melomane","lemma":"mélomane","pos":"NOM"} ,
		{"word":"mélomanes","word_nosc":"melomanes","lemma":"mélomane","pos":"NOM"} ,
		{"word":"mélopée","word_nosc":"melopee","lemma":"mélopée","pos":"NOM"} ,
		{"word":"mélopées","word_nosc":"melopees","lemma":"mélopée","pos":"NOM"} ,
		{"word":"mélos","word_nosc":"melos","lemma":"mélo","pos":"NOM"} ,
		{"word":"mélèze","word_nosc":"meleze","lemma":"mélèze","pos":"NOM"} ,
		{"word":"mélèzes","word_nosc":"melezes","lemma":"mélèze","pos":"NOM"} ,
		{"word":"mémentos","word_nosc":"mementos","lemma":"mémento","pos":"NOM"} ,
		{"word":"mémo","word_nosc":"memo","lemma":"mémo","pos":"NOM"} ,
		{"word":"mémoire","word_nosc":"memoire","lemma":"mémoire","pos":"NOM"} ,
		{"word":"mémoires","word_nosc":"memoires","lemma":"mémoire","pos":"NOM"} ,
		{"word":"mémorabilité","word_nosc":"memorabilite","lemma":"mémorabilité","pos":"NOM"} ,
		{"word":"mémorandum","word_nosc":"memorandum","lemma":"mémorandum","pos":"NOM"} ,
		{"word":"mémorandums","word_nosc":"memorandums","lemma":"mémorandum","pos":"NOM"} ,
		{"word":"mémorial","word_nosc":"memorial","lemma":"mémorial","pos":"NOM"} ,
		{"word":"mémorialiste","word_nosc":"memorialiste","lemma":"mémorialiste","pos":"NOM"} ,
		{"word":"mémorialistes","word_nosc":"memorialistes","lemma":"mémorialiste","pos":"NOM"} ,
		{"word":"mémoriaux","word_nosc":"memoriaux","lemma":"mémorial","pos":"NOM"} ,
		{"word":"mémorisation","word_nosc":"memorisation","lemma":"mémorisation","pos":"NOM"} ,
		{"word":"mémos","word_nosc":"memos","lemma":"mémo","pos":"NOM"} ,
		{"word":"mémère","word_nosc":"memere","lemma":"mémère","pos":"NOM"} ,
		{"word":"mémères","word_nosc":"memeres","lemma":"mémère","pos":"NOM"} ,
		{"word":"mémé","word_nosc":"meme","lemma":"mémé","pos":"NOM"} ,
		{"word":"mémés","word_nosc":"memes","lemma":"mémé","pos":"NOM"} ,
		{"word":"ménade","word_nosc":"menade","lemma":"ménade","pos":"NOM"} ,
		{"word":"ménades","word_nosc":"menades","lemma":"ménade","pos":"NOM"} ,
		{"word":"ménage","word_nosc":"menage","lemma":"ménage","pos":"NOM"} ,
		{"word":"ménagement","word_nosc":"menagement","lemma":"ménagement","pos":"NOM"} ,
		{"word":"ménagements","word_nosc":"menagements","lemma":"ménagement","pos":"NOM"} ,
		{"word":"ménager","word_nosc":"menager","lemma":"ménager","pos":"NOM"} ,
		{"word":"ménagerie","word_nosc":"menagerie","lemma":"ménagerie","pos":"NOM"} ,
		{"word":"ménageries","word_nosc":"menageries","lemma":"ménagerie","pos":"NOM"} ,
		{"word":"ménages","word_nosc":"menages","lemma":"ménage","pos":"NOM"} ,
		{"word":"ménagère","word_nosc":"menagere","lemma":"ménager","pos":"NOM"} ,
		{"word":"ménagères","word_nosc":"menageres","lemma":"ménager","pos":"NOM"} ,
		{"word":"ménesse","word_nosc":"menesse","lemma":"ménesse","pos":"NOM"} ,
		{"word":"ménestrel","word_nosc":"menestrel","lemma":"ménestrel","pos":"NOM"} ,
		{"word":"ménestrels","word_nosc":"menestrels","lemma":"ménestrel","pos":"NOM"} ,
		{"word":"méninges","word_nosc":"meninges","lemma":"méninge","pos":"NOM"} ,
		{"word":"méningiome","word_nosc":"meningiome","lemma":"méningiome","pos":"NOM"} ,
		{"word":"méningite","word_nosc":"meningite","lemma":"méningite","pos":"NOM"} ,
		{"word":"méningites","word_nosc":"meningites","lemma":"méningite","pos":"NOM"} ,
		{"word":"méningocoque","word_nosc":"meningocoque","lemma":"méningocoque","pos":"NOM"} ,
		{"word":"ménisque","word_nosc":"menisque","lemma":"ménisque","pos":"NOM"} ,
		{"word":"ménisques","word_nosc":"menisques","lemma":"ménisque","pos":"NOM"} ,
		{"word":"ménopause","word_nosc":"menopause","lemma":"ménopause","pos":"NOM"} ,
		{"word":"méplat","word_nosc":"meplat","lemma":"méplat","pos":"NOM"} ,
		{"word":"méplats","word_nosc":"meplats","lemma":"méplat","pos":"NOM"} ,
		{"word":"mépris","word_nosc":"mepris","lemma":"mépris","pos":"NOM"} ,
		{"word":"méprise","word_nosc":"meprise","lemma":"méprise","pos":"NOM"} ,
		{"word":"méprises","word_nosc":"meprises","lemma":"méprise","pos":"NOM"} ,
		{"word":"méridien","word_nosc":"meridien","lemma":"méridien","pos":"NOM"} ,
		{"word":"méridienne","word_nosc":"meridienne","lemma":"méridien","pos":"NOM"} ,
		{"word":"méridiens","word_nosc":"meridiens","lemma":"méridien","pos":"NOM"} ,
		{"word":"méridional","word_nosc":"meridional","lemma":"méridional","pos":"NOM"} ,
		{"word":"méridionaux","word_nosc":"meridionaux","lemma":"méridional","pos":"NOM"} ,
		{"word":"mérinos","word_nosc":"merinos","lemma":"mérinos","pos":"NOM"} ,
		{"word":"mérite","word_nosc":"merite","lemma":"mérite","pos":"NOM"} ,
		{"word":"mérites","word_nosc":"merites","lemma":"mérite","pos":"NOM"} ,
		{"word":"méritocratie","word_nosc":"meritocratie","lemma":"méritocratie","pos":"NOM"} ,
		{"word":"mérou","word_nosc":"merou","lemma":"mérou","pos":"NOM"} ,
		{"word":"mésalliance","word_nosc":"mesalliance","lemma":"mésalliance","pos":"NOM"} ,
		{"word":"mésalliances","word_nosc":"mesalliances","lemma":"mésalliance","pos":"NOM"} ,
		{"word":"mésange","word_nosc":"mesange","lemma":"mésange","pos":"NOM"} ,
		{"word":"mésanges","word_nosc":"mesanges","lemma":"mésange","pos":"NOM"} ,
		{"word":"mésaventure","word_nosc":"mesaventure","lemma":"mésaventure","pos":"NOM"} ,
		{"word":"mésaventures","word_nosc":"mesaventures","lemma":"mésaventure","pos":"NOM"} ,
		{"word":"mésencéphale","word_nosc":"mesencephale","lemma":"mésencéphale","pos":"NOM"} ,
		{"word":"mésentente","word_nosc":"mesentente","lemma":"mésentente","pos":"NOM"} ,
		{"word":"mésententes","word_nosc":"mesententes","lemma":"mésentente","pos":"NOM"} ,
		{"word":"mésentère","word_nosc":"mesentere","lemma":"mésentère","pos":"NOM"} ,
		{"word":"mésestime","word_nosc":"mesestime","lemma":"mésestime","pos":"NOM"} ,
		{"word":"mésintelligence","word_nosc":"mesintelligence","lemma":"mésintelligence","pos":"NOM"} ,
		{"word":"méson","word_nosc":"meson","lemma":"méson","pos":"NOM"} ,
		{"word":"mésons","word_nosc":"mesons","lemma":"méson","pos":"NOM"} ,
		{"word":"mésopotamienne","word_nosc":"mesopotamienne","lemma":"mésopotamienne","pos":"NOM"} ,
		{"word":"mésopotamiens","word_nosc":"mesopotamiens","lemma":"mésopotamien","pos":"NOM"} ,
		{"word":"mésosphère","word_nosc":"mesosphere","lemma":"mésosphère","pos":"NOM"} ,
		{"word":"mésozoïque","word_nosc":"mesozoique","lemma":"mésozoïque","pos":"NOM"} ,
		{"word":"mésusage","word_nosc":"mesusage","lemma":"mésusage","pos":"NOM"} ,
		{"word":"méta","word_nosc":"meta","lemma":"méta","pos":"NOM"} ,
		{"word":"métabolisme","word_nosc":"metabolisme","lemma":"métabolisme","pos":"NOM"} ,
		{"word":"métabolismes","word_nosc":"metabolismes","lemma":"métabolisme","pos":"NOM"} ,
		{"word":"métabolite","word_nosc":"metabolite","lemma":"métabolite","pos":"NOM"} ,
		{"word":"métacarpe","word_nosc":"metacarpe","lemma":"métacarpe","pos":"NOM"} ,
		{"word":"métacarpien","word_nosc":"metacarpien","lemma":"métacarpien","pos":"NOM"} ,
		{"word":"métacarpiens","word_nosc":"metacarpiens","lemma":"métacarpien","pos":"NOM"} ,
		{"word":"métairie","word_nosc":"metairie","lemma":"métairie","pos":"NOM"} ,
		{"word":"métairies","word_nosc":"metairies","lemma":"métairie","pos":"NOM"} ,
		{"word":"métal","word_nosc":"metal","lemma":"métal","pos":"NOM"} ,
		{"word":"métallisation","word_nosc":"metallisation","lemma":"métallisation","pos":"NOM"} ,
		{"word":"métallo","word_nosc":"metallo","lemma":"métallo","pos":"NOM"} ,
		{"word":"métallographie","word_nosc":"metallographie","lemma":"métallographie","pos":"NOM"} ,
		{"word":"métallos","word_nosc":"metallos","lemma":"métallo","pos":"NOM"} ,
		{"word":"métallurgie","word_nosc":"metallurgie","lemma":"métallurgie","pos":"NOM"} ,
		{"word":"métallurgiste","word_nosc":"metallurgiste","lemma":"métallurgiste","pos":"NOM"} ,
		{"word":"métallurgistes","word_nosc":"metallurgistes","lemma":"métallurgiste","pos":"NOM"} ,
		{"word":"métamorphose","word_nosc":"metamorphose","lemma":"métamorphose","pos":"NOM"} ,
		{"word":"métamorphoses","word_nosc":"metamorphoses","lemma":"métamorphose","pos":"NOM"} ,
		{"word":"métaphase","word_nosc":"metaphase","lemma":"métaphase","pos":"NOM"} ,
		{"word":"métaphore","word_nosc":"metaphore","lemma":"métaphore","pos":"NOM"} ,
		{"word":"métaphores","word_nosc":"metaphores","lemma":"métaphore","pos":"NOM"} ,
		{"word":"métaphysicien","word_nosc":"metaphysicien","lemma":"métaphysicien","pos":"NOM"} ,
		{"word":"métaphysicienne","word_nosc":"metaphysicienne","lemma":"métaphysicien","pos":"NOM"} ,
		{"word":"métaphysiciens","word_nosc":"metaphysiciens","lemma":"métaphysicien","pos":"NOM"} ,
		{"word":"métaphysique","word_nosc":"metaphysique","lemma":"métaphysique","pos":"NOM"} ,
		{"word":"métaphysiques","word_nosc":"metaphysiques","lemma":"métaphysique","pos":"NOM"} ,
		{"word":"métapsychologie","word_nosc":"metapsychologie","lemma":"métapsychologie","pos":"NOM"} ,
		{"word":"métastase","word_nosc":"metastase","lemma":"métastase","pos":"NOM"} ,
		{"word":"métastases","word_nosc":"metastases","lemma":"métastase","pos":"NOM"} ,
		{"word":"métatarses","word_nosc":"metatarses","lemma":"métatarse","pos":"NOM"} ,
		{"word":"métaux","word_nosc":"metaux","lemma":"métal","pos":"NOM"} ,
		{"word":"métayage","word_nosc":"metayage","lemma":"métayage","pos":"NOM"} ,
		{"word":"métayer","word_nosc":"metayer","lemma":"métayer","pos":"NOM"} ,
		{"word":"métayers","word_nosc":"metayers","lemma":"métayer","pos":"NOM"} ,
		{"word":"métazoaires","word_nosc":"metazoaires","lemma":"métazoaire","pos":"NOM"} ,
		{"word":"métempsychose","word_nosc":"metempsychose","lemma":"métempsychose","pos":"NOM"} ,
		{"word":"métempsycose","word_nosc":"metempsycose","lemma":"métempsycose","pos":"NOM"} ,
		{"word":"méthacrylate","word_nosc":"methacrylate","lemma":"méthacrylate","pos":"NOM"} ,
		{"word":"méthadone","word_nosc":"methadone","lemma":"méthadone","pos":"NOM"} ,
		{"word":"méthanal","word_nosc":"methanal","lemma":"méthanal","pos":"NOM"} ,
		{"word":"méthane","word_nosc":"methane","lemma":"méthane","pos":"NOM"} ,
		{"word":"méthanol","word_nosc":"methanol","lemma":"méthanol","pos":"NOM"} ,
		{"word":"méthode","word_nosc":"methode","lemma":"méthode","pos":"NOM"} ,
		{"word":"méthodes","word_nosc":"methodes","lemma":"méthode","pos":"NOM"} ,
		{"word":"méthodisme","word_nosc":"methodisme","lemma":"méthodisme","pos":"NOM"} ,
		{"word":"méthodiste","word_nosc":"methodiste","lemma":"méthodiste","pos":"NOM"} ,
		{"word":"méthodistes","word_nosc":"methodistes","lemma":"méthodiste","pos":"NOM"} ,
		{"word":"méthodologie","word_nosc":"methodologie","lemma":"méthodologie","pos":"NOM"} ,
		{"word":"méthyle","word_nosc":"methyle","lemma":"méthyle","pos":"NOM"} ,
		{"word":"méthylène","word_nosc":"methylene","lemma":"méthylène","pos":"NOM"} ,
		{"word":"méthédrine","word_nosc":"methedrine","lemma":"méthédrine","pos":"NOM"} ,
		{"word":"méticulosité","word_nosc":"meticulosite","lemma":"méticulosité","pos":"NOM"} ,
		{"word":"métier","word_nosc":"metier","lemma":"métier","pos":"NOM"} ,
		{"word":"métiers","word_nosc":"metiers","lemma":"métier","pos":"NOM"} ,
		{"word":"métingue","word_nosc":"metingue","lemma":"métingue","pos":"NOM"} ,
		{"word":"métis","word_nosc":"metis","lemma":"métis","pos":"NOM"} ,
		{"word":"métissage","word_nosc":"metissage","lemma":"métissage","pos":"NOM"} ,
		{"word":"métissages","word_nosc":"metissages","lemma":"métissage","pos":"NOM"} ,
		{"word":"métisse","word_nosc":"metisse","lemma":"métis","pos":"NOM"} ,
		{"word":"métisses","word_nosc":"metisses","lemma":"métis","pos":"NOM"} ,
		{"word":"métonymie","word_nosc":"metonymie","lemma":"métonymie","pos":"NOM"} ,
		{"word":"métopes","word_nosc":"metopes","lemma":"métope","pos":"NOM"} ,
		{"word":"métrage","word_nosc":"metrage","lemma":"métrage","pos":"NOM"} ,
		{"word":"métrages","word_nosc":"metrages","lemma":"métrage","pos":"NOM"} ,
		{"word":"métreur","word_nosc":"metreur","lemma":"métreur","pos":"NOM"} ,
		{"word":"métrique","word_nosc":"metrique","lemma":"métrique","pos":"NOM"} ,
		{"word":"métriques","word_nosc":"metriques","lemma":"métrique","pos":"NOM"} ,
		{"word":"métrite","word_nosc":"metrite","lemma":"métrite","pos":"NOM"} ,
		{"word":"métrites","word_nosc":"metrites","lemma":"métrite","pos":"NOM"} ,
		{"word":"métro","word_nosc":"metro","lemma":"métro","pos":"NOM"} ,
		{"word":"métronome","word_nosc":"metronome","lemma":"métronome","pos":"NOM"} ,
		{"word":"métronomes","word_nosc":"metronomes","lemma":"métronome","pos":"NOM"} ,
		{"word":"métropole","word_nosc":"metropole","lemma":"métropole","pos":"NOM"} ,
		{"word":"métropoles","word_nosc":"metropoles","lemma":"métropole","pos":"NOM"} ,
		{"word":"métropolitain","word_nosc":"metropolitain","lemma":"métropolitain","pos":"NOM"} ,
		{"word":"métropolitains","word_nosc":"metropolitains","lemma":"métropolitain","pos":"NOM"} ,
		{"word":"métropolite","word_nosc":"metropolite","lemma":"métropolite","pos":"NOM"} ,
		{"word":"métros","word_nosc":"metros","lemma":"métro","pos":"NOM"} ,
		{"word":"métèque","word_nosc":"meteque","lemma":"métèque","pos":"NOM"} ,
		{"word":"métèques","word_nosc":"meteques","lemma":"métèque","pos":"NOM"} ,
		{"word":"météo","word_nosc":"meteo","lemma":"météo","pos":"NOM"} ,
		{"word":"météore","word_nosc":"meteore","lemma":"météore","pos":"NOM"} ,
		{"word":"météores","word_nosc":"meteores","lemma":"météore","pos":"NOM"} ,
		{"word":"météorite","word_nosc":"meteorite","lemma":"météorite","pos":"NOM"} ,
		{"word":"météorites","word_nosc":"meteorites","lemma":"météorite","pos":"NOM"} ,
		{"word":"météorologie","word_nosc":"meteorologie","lemma":"météorologie","pos":"NOM"} ,
		{"word":"météorologiste","word_nosc":"meteorologiste","lemma":"météorologiste","pos":"NOM"} ,
		{"word":"météorologue","word_nosc":"meteorologue","lemma":"météorologue","pos":"NOM"} ,
		{"word":"météorologues","word_nosc":"meteorologues","lemma":"météorologue","pos":"NOM"} ,
		{"word":"mévente","word_nosc":"mevente","lemma":"mévente","pos":"NOM"} ,
		{"word":"mêle-tout","word_nosc":"mele-tout","lemma":"mêle-tout","pos":"NOM"} ,
		{"word":"mêlé-cass","word_nosc":"mele-cass","lemma":"mêlé-cass","pos":"NOM"} ,
		{"word":"mêlé-casse","word_nosc":"mele-casse","lemma":"mêlé-casse","pos":"NOM"} ,
		{"word":"mêlécasse","word_nosc":"melecasse","lemma":"mêlécasse","pos":"NOM"} ,
		{"word":"mêlée","word_nosc":"melee","lemma":"mêlée","pos":"NOM"} ,
		{"word":"mêlées","word_nosc":"melees","lemma":"mêlée","pos":"NOM"} ,
		{"word":"môle","word_nosc":"mole","lemma":"môle","pos":"NOM"} ,
		{"word":"môles","word_nosc":"moles","lemma":"môle","pos":"NOM"} ,
		{"word":"môme","word_nosc":"mome","lemma":"môme","pos":"NOM"} ,
		{"word":"mômeries","word_nosc":"momeries","lemma":"mômerie","pos":"NOM"} ,
		{"word":"mômes","word_nosc":"momes","lemma":"môme","pos":"NOM"} ,
		{"word":"mûre","word_nosc":"mure","lemma":"mûr","pos":"NOM"} ,
		{"word":"mûres","word_nosc":"mures","lemma":"mûr","pos":"NOM"} ,
		{"word":"mûrier","word_nosc":"murier","lemma":"mûrier","pos":"NOM"} ,
		{"word":"mûriers","word_nosc":"muriers","lemma":"mûrier","pos":"NOM"} ,
		{"word":"mûrissement","word_nosc":"murissement","lemma":"mûrissement","pos":"NOM"} ,
		{"word":"mûrissements","word_nosc":"murissements","lemma":"mûrissement","pos":"NOM"} ,
		{"word":"mûrisseries","word_nosc":"murisseries","lemma":"mûrisserie","pos":"NOM"} ,
		{"word":"mûron","word_nosc":"muron","lemma":"mûron","pos":"NOM"} ,
		{"word":"n","word_nosc":"n","lemma":"ne","pos":"NOM"} ,
		{"word":"n'","word_nosc":"n'","lemma":"n","pos":"NOM"} ,
		{"word":"na","word_nosc":"na","lemma":"na","pos":"NOM"} ,
		{"word":"nabab","word_nosc":"nabab","lemma":"nabab","pos":"NOM"} ,
		{"word":"nababs","word_nosc":"nababs","lemma":"nabab","pos":"NOM"} ,
		{"word":"nabi","word_nosc":"nabi","lemma":"nabi","pos":"NOM"} ,
		{"word":"nable","word_nosc":"nable","lemma":"nable","pos":"NOM"} ,
		{"word":"nabot","word_nosc":"nabot","lemma":"nabot","pos":"NOM"} ,
		{"word":"nabote","word_nosc":"nabote","lemma":"nabot","pos":"NOM"} ,
		{"word":"nabots","word_nosc":"nabots","lemma":"nabot","pos":"NOM"} ,
		{"word":"nacarat","word_nosc":"nacarat","lemma":"nacarat","pos":"NOM"} ,
		{"word":"nacelle","word_nosc":"nacelle","lemma":"nacelle","pos":"NOM"} ,
		{"word":"nacelles","word_nosc":"nacelles","lemma":"nacelle","pos":"NOM"} ,
		{"word":"nacre","word_nosc":"nacre","lemma":"nacre","pos":"NOM"} ,
		{"word":"nacres","word_nosc":"nacres","lemma":"nacre","pos":"NOM"} ,
		{"word":"nacrure","word_nosc":"nacrure","lemma":"nacrure","pos":"NOM"} ,
		{"word":"nadir","word_nosc":"nadir","lemma":"nadir","pos":"NOM"} ,
		{"word":"naevus","word_nosc":"naevus","lemma":"naevus","pos":"NOM"} ,
		{"word":"naga","word_nosc":"naga","lemma":"naga","pos":"NOM"} ,
		{"word":"nage","word_nosc":"nage","lemma":"nage","pos":"NOM"} ,
		{"word":"nageoire","word_nosc":"nageoire","lemma":"nageoire","pos":"NOM"} ,
		{"word":"nageoires","word_nosc":"nageoires","lemma":"nageoire","pos":"NOM"} ,
		{"word":"nages","word_nosc":"nages","lemma":"nage","pos":"NOM"} ,
		{"word":"nageur","word_nosc":"nageur","lemma":"nageur","pos":"NOM"} ,
		{"word":"nageurs","word_nosc":"nageurs","lemma":"nageur","pos":"NOM"} ,
		{"word":"nageuse","word_nosc":"nageuse","lemma":"nageur","pos":"NOM"} ,
		{"word":"nageuses","word_nosc":"nageuses","lemma":"nageur","pos":"NOM"} ,
		{"word":"nain","word_nosc":"nain","lemma":"nain","pos":"NOM"} ,
		{"word":"naine","word_nosc":"naine","lemma":"nain","pos":"NOM"} ,
		{"word":"naines","word_nosc":"naines","lemma":"nain","pos":"NOM"} ,
		{"word":"nains","word_nosc":"nains","lemma":"nain","pos":"NOM"} ,
		{"word":"naira","word_nosc":"naira","lemma":"naira","pos":"NOM"} ,
		{"word":"naissain","word_nosc":"naissain","lemma":"naissain","pos":"NOM"} ,
		{"word":"naissance","word_nosc":"naissance","lemma":"naissance","pos":"NOM"} ,
		{"word":"naissances","word_nosc":"naissances","lemma":"naissance","pos":"NOM"} ,
		{"word":"naja","word_nosc":"naja","lemma":"naja","pos":"NOM"} ,
		{"word":"najas","word_nosc":"najas","lemma":"naja","pos":"NOM"} ,
		{"word":"namibiens","word_nosc":"namibiens","lemma":"namibien","pos":"NOM"} ,
		{"word":"nan","word_nosc":"nan","lemma":"nan","pos":"NOM"} ,
		{"word":"nana","word_nosc":"nana","lemma":"nana","pos":"NOM"} ,
		{"word":"nanan","word_nosc":"nanan","lemma":"nanan","pos":"NOM"} ,
		{"word":"nanar","word_nosc":"nanar","lemma":"nanar","pos":"NOM"} ,
		{"word":"nanard","word_nosc":"nanard","lemma":"nanard","pos":"NOM"} ,
		{"word":"nanas","word_nosc":"nanas","lemma":"nana","pos":"NOM"} ,
		{"word":"nanisme","word_nosc":"nanisme","lemma":"nanisme","pos":"NOM"} ,
		{"word":"nankin","word_nosc":"nankin","lemma":"nankin","pos":"NOM"} ,
		{"word":"nanomètre","word_nosc":"nanometre","lemma":"nanomètre","pos":"NOM"} ,
		{"word":"nanomètres","word_nosc":"nanometres","lemma":"nanomètre","pos":"NOM"} ,
		{"word":"nanoseconde","word_nosc":"nanoseconde","lemma":"nanoseconde","pos":"NOM"} ,
		{"word":"nanosecondes","word_nosc":"nanosecondes","lemma":"nanoseconde","pos":"NOM"} ,
		{"word":"nanotechnologie","word_nosc":"nanotechnologie","lemma":"nanotechnologie","pos":"NOM"} ,
		{"word":"nant","word_nosc":"nant","lemma":"nant","pos":"NOM"} ,
		{"word":"nantais","word_nosc":"nantais","lemma":"nantais","pos":"NOM"} ,
		{"word":"nantaise","word_nosc":"nantaise","lemma":"nantaise","pos":"NOM"} ,
		{"word":"nantaises","word_nosc":"nantaises","lemma":"nantais","pos":"NOM"} ,
		{"word":"nanti","word_nosc":"nanti","lemma":"nanti","pos":"NOM"} ,
		{"word":"nantie","word_nosc":"nantie","lemma":"nanti","pos":"NOM"} ,
		{"word":"nantis","word_nosc":"nantis","lemma":"nanti","pos":"NOM"} ,
		{"word":"nantissement","word_nosc":"nantissement","lemma":"nantissement","pos":"NOM"} ,
		{"word":"napalm","word_nosc":"napalm","lemma":"napalm","pos":"NOM"} ,
		{"word":"napel","word_nosc":"napel","lemma":"napel","pos":"NOM"} ,
		{"word":"naphtaline","word_nosc":"naphtaline","lemma":"naphtaline","pos":"NOM"} ,
		{"word":"naphtalines","word_nosc":"naphtalines","lemma":"naphtaline","pos":"NOM"} ,
		{"word":"naphtalène","word_nosc":"naphtalene","lemma":"naphtalène","pos":"NOM"} ,
		{"word":"naphte","word_nosc":"naphte","lemma":"naphte","pos":"NOM"} ,
		{"word":"napolitain","word_nosc":"napolitain","lemma":"napolitain","pos":"NOM"} ,
		{"word":"napolitaine","word_nosc":"napolitaine","lemma":"napolitain","pos":"NOM"} ,
		{"word":"napolitains","word_nosc":"napolitains","lemma":"napolitain","pos":"NOM"} ,
		{"word":"napoléon","word_nosc":"napoleon","lemma":"napoléon","pos":"NOM"} ,
		{"word":"napoléons","word_nosc":"napoleons","lemma":"napoléon","pos":"NOM"} ,
		{"word":"nappage","word_nosc":"nappage","lemma":"nappage","pos":"NOM"} ,
		{"word":"nappe","word_nosc":"nappe","lemma":"nappe","pos":"NOM"} ,
		{"word":"napperon","word_nosc":"napperon","lemma":"napperon","pos":"NOM"} ,
		{"word":"napperons","word_nosc":"napperons","lemma":"napperon","pos":"NOM"} ,
		{"word":"nappes","word_nosc":"nappes","lemma":"nappe","pos":"NOM"} ,
		{"word":"narcisse","word_nosc":"narcisse","lemma":"narcisse","pos":"NOM"} ,
		{"word":"narcisses","word_nosc":"narcisses","lemma":"narcisse","pos":"NOM"} ,
		{"word":"narcissisme","word_nosc":"narcissisme","lemma":"narcissisme","pos":"NOM"} ,
		{"word":"narcissiste","word_nosc":"narcissiste","lemma":"narcissiste","pos":"NOM"} ,
		{"word":"narco-analyse","word_nosc":"narco-analyse","lemma":"narco-analyse","pos":"NOM"} ,
		{"word":"narcolepsie","word_nosc":"narcolepsie","lemma":"narcolepsie","pos":"NOM"} ,
		{"word":"narcose","word_nosc":"narcose","lemma":"narcose","pos":"NOM"} ,
		{"word":"narcotique","word_nosc":"narcotique","lemma":"narcotique","pos":"NOM"} ,
		{"word":"narcotiques","word_nosc":"narcotiques","lemma":"narcotique","pos":"NOM"} ,
		{"word":"narcotrafic","word_nosc":"narcotrafic","lemma":"narcotrafic","pos":"NOM"} ,
		{"word":"narcotrafiquants","word_nosc":"narcotrafiquants","lemma":"narcotrafiquant","pos":"NOM"} ,
		{"word":"nard","word_nosc":"nard","lemma":"nard","pos":"NOM"} ,
		{"word":"nards","word_nosc":"nards","lemma":"nard","pos":"NOM"} ,
		{"word":"narghileh","word_nosc":"narghileh","lemma":"narghileh","pos":"NOM"} ,
		{"word":"narghilé","word_nosc":"narghile","lemma":"narghilé","pos":"NOM"} ,
		{"word":"narguilé","word_nosc":"narguile","lemma":"narguilé","pos":"NOM"} ,
		{"word":"narguilés","word_nosc":"narguiles","lemma":"narguilé","pos":"NOM"} ,
		{"word":"narine","word_nosc":"narine","lemma":"narine","pos":"NOM"} ,
		{"word":"narines","word_nosc":"narines","lemma":"narine","pos":"NOM"} ,
		{"word":"narquoiserie","word_nosc":"narquoiserie","lemma":"narquoiserie","pos":"NOM"} ,
		{"word":"narrateur","word_nosc":"narrateur","lemma":"narrateur","pos":"NOM"} ,
		{"word":"narration","word_nosc":"narration","lemma":"narration","pos":"NOM"} ,
		{"word":"narrations","word_nosc":"narrations","lemma":"narration","pos":"NOM"} ,
		{"word":"narratrice","word_nosc":"narratrice","lemma":"narrateur","pos":"NOM"} ,
		{"word":"narratrices","word_nosc":"narratrices","lemma":"narrateur","pos":"NOM"} ,
		{"word":"narthex","word_nosc":"narthex","lemma":"narthex","pos":"NOM"} ,
		{"word":"narval","word_nosc":"narval","lemma":"narval","pos":"NOM"} ,
		{"word":"narvals","word_nosc":"narvals","lemma":"narval","pos":"NOM"} ,
		{"word":"nasal","word_nosc":"nasal","lemma":"nasal","pos":"NOM"} ,
		{"word":"nasard","word_nosc":"nasard","lemma":"nasard","pos":"NOM"} ,
		{"word":"nasardes","word_nosc":"nasardes","lemma":"nasard","pos":"NOM"} ,
		{"word":"nasdaq","word_nosc":"nasdaq","lemma":"nasdaq","pos":"NOM"} ,
		{"word":"nase","word_nosc":"nase","lemma":"nase","pos":"NOM"} ,
		{"word":"naseau","word_nosc":"naseau","lemma":"naseau","pos":"NOM"} ,
		{"word":"naseaux","word_nosc":"naseaux","lemma":"naseau","pos":"NOM"} ,
		{"word":"nases","word_nosc":"nases","lemma":"nase","pos":"NOM"} ,
		{"word":"nasillement","word_nosc":"nasillement","lemma":"nasillement","pos":"NOM"} ,
		{"word":"nasique","word_nosc":"nasique","lemma":"nasique","pos":"NOM"} ,
		{"word":"nasopharynx","word_nosc":"nasopharynx","lemma":"nasopharynx","pos":"NOM"} ,
		{"word":"nasse","word_nosc":"nasse","lemma":"nasse","pos":"NOM"} ,
		{"word":"nasses","word_nosc":"nasses","lemma":"nasse","pos":"NOM"} ,
		{"word":"natalité","word_nosc":"natalite","lemma":"natalité","pos":"NOM"} ,
		{"word":"natation","word_nosc":"natation","lemma":"natation","pos":"NOM"} ,
		{"word":"natif","word_nosc":"natif","lemma":"natif","pos":"NOM"} ,
		{"word":"natifs","word_nosc":"natifs","lemma":"natif","pos":"NOM"} ,
		{"word":"nation","word_nosc":"nation","lemma":"nation","pos":"NOM"} ,
		{"word":"national","word_nosc":"national","lemma":"national","pos":"NOM"} ,
		{"word":"national-socialisme","word_nosc":"national-socialisme","lemma":"national-socialisme","pos":"NOM"} ,
		{"word":"national-socialiste","word_nosc":"national-socialiste","lemma":"national-socialiste","pos":"NOM"} ,
		{"word":"nationale","word_nosc":"nationale","lemma":"nationale","pos":"NOM"} ,
		{"word":"nationales","word_nosc":"nationales","lemma":"nationale","pos":"NOM"} ,
		{"word":"nationalisation","word_nosc":"nationalisation","lemma":"nationalisation","pos":"NOM"} ,
		{"word":"nationalisations","word_nosc":"nationalisations","lemma":"nationalisation","pos":"NOM"} ,
		{"word":"nationalisme","word_nosc":"nationalisme","lemma":"nationalisme","pos":"NOM"} ,
		{"word":"nationaliste","word_nosc":"nationaliste","lemma":"nationaliste","pos":"NOM"} ,
		{"word":"nationalistes","word_nosc":"nationalistes","lemma":"nationaliste","pos":"NOM"} ,
		{"word":"nationalité","word_nosc":"nationalite","lemma":"nationalité","pos":"NOM"} ,
		{"word":"nationalités","word_nosc":"nationalites","lemma":"nationalité","pos":"NOM"} ,
		{"word":"nationaux","word_nosc":"nationaux","lemma":"national","pos":"NOM"} ,
		{"word":"nations","word_nosc":"nations","lemma":"nation","pos":"NOM"} ,
		{"word":"native","word_nosc":"native","lemma":"native","pos":"NOM"} ,
		{"word":"natives","word_nosc":"natives","lemma":"native","pos":"NOM"} ,
		{"word":"nativité","word_nosc":"nativite","lemma":"nativité","pos":"NOM"} ,
		{"word":"nativités","word_nosc":"nativites","lemma":"nativité","pos":"NOM"} ,
		{"word":"natron","word_nosc":"natron","lemma":"natron","pos":"NOM"} ,
		{"word":"natrum","word_nosc":"natrum","lemma":"natrum","pos":"NOM"} ,
		{"word":"natrémie","word_nosc":"natremie","lemma":"natrémie","pos":"NOM"} ,
		{"word":"natte","word_nosc":"natte","lemma":"natte","pos":"NOM"} ,
		{"word":"nattes","word_nosc":"nattes","lemma":"natte","pos":"NOM"} ,
		{"word":"nattier","word_nosc":"nattier","lemma":"nattier","pos":"NOM"} ,
		{"word":"naturalisation","word_nosc":"naturalisation","lemma":"naturalisation","pos":"NOM"} ,
		{"word":"naturalisme","word_nosc":"naturalisme","lemma":"naturalisme","pos":"NOM"} ,
		{"word":"naturaliste","word_nosc":"naturaliste","lemma":"naturaliste","pos":"NOM"} ,
		{"word":"naturalistes","word_nosc":"naturalistes","lemma":"naturaliste","pos":"NOM"} ,
		{"word":"naturalisé","word_nosc":"naturalise","lemma":"naturalisé","pos":"NOM"} ,
		{"word":"naturalisés","word_nosc":"naturalises","lemma":"naturalisé","pos":"NOM"} ,
		{"word":"nature","word_nosc":"nature","lemma":"nature","pos":"NOM"} ,
		{"word":"naturel","word_nosc":"naturel","lemma":"naturel","pos":"NOM"} ,
		{"word":"naturels","word_nosc":"naturels","lemma":"naturel","pos":"NOM"} ,
		{"word":"natures","word_nosc":"natures","lemma":"nature","pos":"NOM"} ,
		{"word":"naturisme","word_nosc":"naturisme","lemma":"naturisme","pos":"NOM"} ,
		{"word":"naturiste","word_nosc":"naturiste","lemma":"naturiste","pos":"NOM"} ,
		{"word":"naturistes","word_nosc":"naturistes","lemma":"naturiste","pos":"NOM"} ,
		{"word":"naturopathe","word_nosc":"naturopathe","lemma":"naturopathe","pos":"NOM"} ,
		{"word":"naufrage","word_nosc":"naufrage","lemma":"naufrage","pos":"NOM"} ,
		{"word":"naufrages","word_nosc":"naufrages","lemma":"naufrage","pos":"NOM"} ,
		{"word":"naufrageur","word_nosc":"naufrageur","lemma":"naufrageur","pos":"NOM"} ,
		{"word":"naufrageurs","word_nosc":"naufrageurs","lemma":"naufrageur","pos":"NOM"} ,
		{"word":"naufrageuse","word_nosc":"naufrageuse","lemma":"naufrageur","pos":"NOM"} ,
		{"word":"naufragé","word_nosc":"naufrage","lemma":"naufragé","pos":"NOM"} ,
		{"word":"naufragée","word_nosc":"naufragee","lemma":"naufragé","pos":"NOM"} ,
		{"word":"naufragés","word_nosc":"naufrages","lemma":"naufragé","pos":"NOM"} ,
		{"word":"nausée","word_nosc":"nausee","lemma":"nausée","pos":"NOM"} ,
		{"word":"nausées","word_nosc":"nausees","lemma":"nausée","pos":"NOM"} ,
		{"word":"nautes","word_nosc":"nautes","lemma":"naute","pos":"NOM"} ,
		{"word":"nautile","word_nosc":"nautile","lemma":"nautile","pos":"NOM"} ,
		{"word":"nautilus","word_nosc":"nautilus","lemma":"nautilus","pos":"NOM"} ,
		{"word":"nautonier","word_nosc":"nautonier","lemma":"nautonier","pos":"NOM"} ,
		{"word":"nautoniers","word_nosc":"nautoniers","lemma":"nautonier","pos":"NOM"} ,
		{"word":"navaja","word_nosc":"navaja","lemma":"navaja","pos":"NOM"} ,
		{"word":"navajas","word_nosc":"navajas","lemma":"navaja","pos":"NOM"} ,
		{"word":"navajo","word_nosc":"navajo","lemma":"navajo","pos":"NOM"} ,
		{"word":"navajos","word_nosc":"navajos","lemma":"navajo","pos":"NOM"} ,
		{"word":"navale","word_nosc":"navale","lemma":"navale","pos":"NOM"} ,
		{"word":"navales","word_nosc":"navales","lemma":"navale","pos":"NOM"} ,
		{"word":"navarin","word_nosc":"navarin","lemma":"navarin","pos":"NOM"} ,
		{"word":"navarins","word_nosc":"navarins","lemma":"navarin","pos":"NOM"} ,
		{"word":"navarrais","word_nosc":"navarrais","lemma":"navarrais","pos":"NOM"} ,
		{"word":"nave","word_nosc":"nave","lemma":"nave","pos":"NOM"} ,
		{"word":"navel","word_nosc":"navel","lemma":"navel","pos":"NOM"} ,
		{"word":"navet","word_nosc":"navet","lemma":"navet","pos":"NOM"} ,
		{"word":"navets","word_nosc":"navets","lemma":"navet","pos":"NOM"} ,
		{"word":"navette","word_nosc":"navette","lemma":"navette","pos":"NOM"} ,
		{"word":"navettes","word_nosc":"navettes","lemma":"navette","pos":"NOM"} ,
		{"word":"navicert","word_nosc":"navicert","lemma":"navicert","pos":"NOM"} ,
		{"word":"navigabilité","word_nosc":"navigabilite","lemma":"navigabilité","pos":"NOM"} ,
		{"word":"navigant","word_nosc":"navigant","lemma":"navigant","pos":"NOM"} ,
		{"word":"navigateur","word_nosc":"navigateur","lemma":"navigateur","pos":"NOM"} ,
		{"word":"navigateurs","word_nosc":"navigateurs","lemma":"navigateur","pos":"NOM"} ,
		{"word":"navigation","word_nosc":"navigation","lemma":"navigation","pos":"NOM"} ,
		{"word":"navigations","word_nosc":"navigations","lemma":"navigation","pos":"NOM"} ,
		{"word":"navigatrice","word_nosc":"navigatrice","lemma":"navigateur","pos":"NOM"} ,
		{"word":"navire","word_nosc":"navire","lemma":"navire","pos":"NOM"} ,
		{"word":"navire-citerne","word_nosc":"navire-citerne","lemma":"navire-citerne","pos":"NOM"} ,
		{"word":"navire-hôpital","word_nosc":"navire-hopital","lemma":"navire-hôpital","pos":"NOM"} ,
		{"word":"navires","word_nosc":"navires","lemma":"navire","pos":"NOM"} ,
		{"word":"navires-citernes","word_nosc":"navires-citernes","lemma":"navire-citerne","pos":"NOM"} ,
		{"word":"navires-écoles","word_nosc":"navires-ecoles","lemma":"navire-école","pos":"NOM"} ,
		{"word":"navrance","word_nosc":"navrance","lemma":"navrance","pos":"NOM"} ,
		{"word":"nay","word_nosc":"nay","lemma":"nay","pos":"NOM"} ,
		{"word":"nazaréen","word_nosc":"nazareen","lemma":"nazaréen","pos":"NOM"} ,
		{"word":"naze","word_nosc":"naze","lemma":"naze","pos":"NOM"} ,
		{"word":"nazes","word_nosc":"nazes","lemma":"naze","pos":"NOM"} ,
		{"word":"nazi","word_nosc":"nazi","lemma":"nazi","pos":"NOM"} ,
		{"word":"nazie","word_nosc":"nazie","lemma":"nazi","pos":"NOM"} ,
		{"word":"nazies","word_nosc":"nazies","lemma":"nazi","pos":"NOM"} ,
		{"word":"nazillons","word_nosc":"nazillons","lemma":"nazillon","pos":"NOM"} ,
		{"word":"nazis","word_nosc":"nazis","lemma":"nazi","pos":"NOM"} ,
		{"word":"nazisme","word_nosc":"nazisme","lemma":"nazisme","pos":"NOM"} ,
		{"word":"naïade","word_nosc":"naiade","lemma":"naïade","pos":"NOM"} ,
		{"word":"naïades","word_nosc":"naiades","lemma":"naïade","pos":"NOM"} ,
		{"word":"naïf","word_nosc":"naif","lemma":"naïf","pos":"NOM"} ,
		{"word":"naïfs","word_nosc":"naifs","lemma":"naïf","pos":"NOM"} ,
		{"word":"naïve","word_nosc":"naive","lemma":"naïve","pos":"NOM"} ,
		{"word":"naïves","word_nosc":"naives","lemma":"naïve","pos":"NOM"} ,
		{"word":"naïveté","word_nosc":"naivete","lemma":"naïveté","pos":"NOM"} ,
		{"word":"naïvetés","word_nosc":"naivetes","lemma":"naïveté","pos":"NOM"} ,
		{"word":"nec","word_nosc":"nec","lemma":"nec","pos":"NOM"} ,
		{"word":"neck","word_nosc":"neck","lemma":"neck","pos":"NOM"} ,
		{"word":"nectar","word_nosc":"nectar","lemma":"nectar","pos":"NOM"} ,
		{"word":"nectarine","word_nosc":"nectarine","lemma":"nectarine","pos":"NOM"} ,
		{"word":"nectarines","word_nosc":"nectarines","lemma":"nectarine","pos":"NOM"} ,
		{"word":"nef","word_nosc":"nef","lemma":"nef","pos":"NOM"} ,
		{"word":"nefs","word_nosc":"nefs","lemma":"nef","pos":"NOM"} ,
		{"word":"negro","word_nosc":"negro","lemma":"negro","pos":"NOM"} ,
		{"word":"negro spiritual","word_nosc":"negro spiritual","lemma":"negro spiritual","pos":"NOM"} ,
		{"word":"negro spirituals","word_nosc":"negro spirituals","lemma":"negro spiritual","pos":"NOM"} ,
		{"word":"neige","word_nosc":"neige","lemma":"neige","pos":"NOM"} ,
		{"word":"neiges","word_nosc":"neiges","lemma":"neige","pos":"NOM"} ,
		{"word":"nem","word_nosc":"nem","lemma":"nem","pos":"NOM"} ,
		{"word":"nemrod","word_nosc":"nemrod","lemma":"nemrod","pos":"NOM"} ,
		{"word":"nems","word_nosc":"nems","lemma":"nem","pos":"NOM"} ,
		{"word":"nephtys","word_nosc":"nephtys","lemma":"nephtys","pos":"NOM"} ,
		{"word":"nerf","word_nosc":"nerf","lemma":"nerf","pos":"NOM"} ,
		{"word":"nerfs","word_nosc":"nerfs","lemma":"nerf","pos":"NOM"} ,
		{"word":"nerprun","word_nosc":"nerprun","lemma":"nerprun","pos":"NOM"} ,
		{"word":"nerpruns","word_nosc":"nerpruns","lemma":"nerprun","pos":"NOM"} ,
		{"word":"nerveuse","word_nosc":"nerveuse","lemma":"nerveux","pos":"NOM"} ,
		{"word":"nerveuses","word_nosc":"nerveuses","lemma":"nerveux","pos":"NOM"} ,
		{"word":"nerveux","word_nosc":"nerveux","lemma":"nerveux","pos":"NOM"} ,
		{"word":"nervi","word_nosc":"nervi","lemma":"nervi","pos":"NOM"} ,
		{"word":"nervis","word_nosc":"nervis","lemma":"nervi","pos":"NOM"} ,
		{"word":"nervosité","word_nosc":"nervosite","lemma":"nervosité","pos":"NOM"} ,
		{"word":"nervosités","word_nosc":"nervosites","lemma":"nervosité","pos":"NOM"} ,
		{"word":"nervure","word_nosc":"nervure","lemma":"nervure","pos":"NOM"} ,
		{"word":"nervures","word_nosc":"nervures","lemma":"nervure","pos":"NOM"} ,
		{"word":"nescafé","word_nosc":"nescafe","lemma":"nescafé","pos":"NOM"} ,
		{"word":"nestor","word_nosc":"nestor","lemma":"nestor","pos":"NOM"} ,
		{"word":"nestorianisme","word_nosc":"nestorianisme","lemma":"nestorianisme","pos":"NOM"} ,
		{"word":"nestorien","word_nosc":"nestorien","lemma":"nestorien","pos":"NOM"} ,
		{"word":"nestoriens","word_nosc":"nestoriens","lemma":"nestorien","pos":"NOM"} ,
		{"word":"net","word_nosc":"net","lemma":"net","pos":"NOM"} ,
		{"word":"nets","word_nosc":"nets","lemma":"net","pos":"NOM"} ,
		{"word":"netteté","word_nosc":"nettete","lemma":"netteté","pos":"NOM"} ,
		{"word":"nettoiement","word_nosc":"nettoiement","lemma":"nettoiement","pos":"NOM"} ,
		{"word":"nettoyage","word_nosc":"nettoyage","lemma":"nettoyage","pos":"NOM"} ,
		{"word":"nettoyages","word_nosc":"nettoyages","lemma":"nettoyage","pos":"NOM"} ,
		{"word":"nettoyant","word_nosc":"nettoyant","lemma":"nettoyant","pos":"NOM"} ,
		{"word":"nettoyants","word_nosc":"nettoyants","lemma":"nettoyant","pos":"NOM"} ,
		{"word":"nettoyeur","word_nosc":"nettoyeur","lemma":"nettoyeur","pos":"NOM"} ,
		{"word":"nettoyeurs","word_nosc":"nettoyeurs","lemma":"nettoyeur","pos":"NOM"} ,
		{"word":"nettoyeuse","word_nosc":"nettoyeuse","lemma":"nettoyeur","pos":"NOM"} ,
		{"word":"nettoyeuses","word_nosc":"nettoyeuses","lemma":"nettoyeur","pos":"NOM"} ,
		{"word":"neuneu","word_nosc":"neuneu","lemma":"neuneu","pos":"NOM"} ,
		{"word":"neurasthénie","word_nosc":"neurasthenie","lemma":"neurasthénie","pos":"NOM"} ,
		{"word":"neurasthénique","word_nosc":"neurasthenique","lemma":"neurasthénique","pos":"NOM"} ,
		{"word":"neurasthéniques","word_nosc":"neurastheniques","lemma":"neurasthénique","pos":"NOM"} ,
		{"word":"neurinome","word_nosc":"neurinome","lemma":"neurinome","pos":"NOM"} ,
		{"word":"neurobiologie","word_nosc":"neurobiologie","lemma":"neurobiologie","pos":"NOM"} ,
		{"word":"neurobiologiste","word_nosc":"neurobiologiste","lemma":"neurobiologiste","pos":"NOM"} ,
		{"word":"neurochimie","word_nosc":"neurochimie","lemma":"neurochimie","pos":"NOM"} ,
		{"word":"neurochirurgie","word_nosc":"neurochirurgie","lemma":"neurochirurgie","pos":"NOM"} ,
		{"word":"neurochirurgien","word_nosc":"neurochirurgien","lemma":"neurochirurgien","pos":"NOM"} ,
		{"word":"neurochirurgienne","word_nosc":"neurochirurgienne","lemma":"neurochirurgien","pos":"NOM"} ,
		{"word":"neurofibromatose","word_nosc":"neurofibromatose","lemma":"neurofibromatose","pos":"NOM"} ,
		{"word":"neuroleptique","word_nosc":"neuroleptique","lemma":"neuroleptique","pos":"NOM"} ,
		{"word":"neuroleptiques","word_nosc":"neuroleptiques","lemma":"neuroleptique","pos":"NOM"} ,
		{"word":"neurolinguistique","word_nosc":"neurolinguistique","lemma":"neurolinguistique","pos":"NOM"} ,
		{"word":"neurologie","word_nosc":"neurologie","lemma":"neurologie","pos":"NOM"} ,
		{"word":"neurologiste","word_nosc":"neurologiste","lemma":"neurologiste","pos":"NOM"} ,
		{"word":"neurologue","word_nosc":"neurologue","lemma":"neurologue","pos":"NOM"} ,
		{"word":"neurologues","word_nosc":"neurologues","lemma":"neurologue","pos":"NOM"} ,
		{"word":"neurone","word_nosc":"neurone","lemma":"neurone","pos":"NOM"} ,
		{"word":"neurones","word_nosc":"neurones","lemma":"neurone","pos":"NOM"} ,
		{"word":"neuropathie","word_nosc":"neuropathie","lemma":"neuropathie","pos":"NOM"} ,
		{"word":"neuropathologie","word_nosc":"neuropathologie","lemma":"neuropathologie","pos":"NOM"} ,
		{"word":"neuropeptide","word_nosc":"neuropeptide","lemma":"neuropeptide","pos":"NOM"} ,
		{"word":"neurophysiologie","word_nosc":"neurophysiologie","lemma":"neurophysiologie","pos":"NOM"} ,
		{"word":"neuropsychiatrie","word_nosc":"neuropsychiatrie","lemma":"neuropsychiatrie","pos":"NOM"} ,
		{"word":"neuropsychologue","word_nosc":"neuropsychologue","lemma":"neuropsychologue","pos":"NOM"} ,
		{"word":"neuroscience","word_nosc":"neuroscience","lemma":"neuroscience","pos":"NOM"} ,
		{"word":"neurotoxine","word_nosc":"neurotoxine","lemma":"neurotoxine","pos":"NOM"} ,
		{"word":"neurotransmetteur","word_nosc":"neurotransmetteur","lemma":"neurotransmetteur","pos":"NOM"} ,
		{"word":"neurotransmetteurs","word_nosc":"neurotransmetteurs","lemma":"neurotransmetteur","pos":"NOM"} ,
		{"word":"neutralisateur","word_nosc":"neutralisateur","lemma":"neutralisateur","pos":"NOM"} ,
		{"word":"neutralisation","word_nosc":"neutralisation","lemma":"neutralisation","pos":"NOM"} ,
		{"word":"neutraliste","word_nosc":"neutraliste","lemma":"neutraliste","pos":"NOM"} ,
		{"word":"neutralité","word_nosc":"neutralite","lemma":"neutralité","pos":"NOM"} ,
		{"word":"neutre","word_nosc":"neutre","lemma":"neutre","pos":"NOM"} ,
		{"word":"neutres","word_nosc":"neutres","lemma":"neutre","pos":"NOM"} ,
		{"word":"neutrino","word_nosc":"neutrino","lemma":"neutrino","pos":"NOM"} ,
		{"word":"neutrinos","word_nosc":"neutrinos","lemma":"neutrino","pos":"NOM"} ,
		{"word":"neutron","word_nosc":"neutron","lemma":"neutron","pos":"NOM"} ,
		{"word":"neutrons","word_nosc":"neutrons","lemma":"neutron","pos":"NOM"} ,
		{"word":"neutropénie","word_nosc":"neutropenie","lemma":"neutropénie","pos":"NOM"} ,
		{"word":"neuvaine","word_nosc":"neuvaine","lemma":"neuvaine","pos":"NOM"} ,
		{"word":"neuvaines","word_nosc":"neuvaines","lemma":"neuvaine","pos":"NOM"} ,
		{"word":"neuvième","word_nosc":"neuvieme","lemma":"neuvième","pos":"NOM"} ,
		{"word":"neuvièmes","word_nosc":"neuviemes","lemma":"neuvième","pos":"NOM"} ,
		{"word":"neveu","word_nosc":"neveu","lemma":"neveu","pos":"NOM"} ,
		{"word":"neveux","word_nosc":"neveux","lemma":"neveux","pos":"NOM"} ,
		{"word":"new wave","word_nosc":"new wave","lemma":"new wave","pos":"NOM"} ,
		{"word":"new-yorkais","word_nosc":"new-yorkais","lemma":"new-yorkais","pos":"NOM"} ,
		{"word":"new-yorkaise","word_nosc":"new-yorkaise","lemma":"new-yorkais","pos":"NOM"} ,
		{"word":"news","word_nosc":"news","lemma":"new","pos":"NOM"} ,
		{"word":"ney","word_nosc":"ney","lemma":"ney","pos":"NOM"} ,
		{"word":"nez","word_nosc":"nez","lemma":"nez","pos":"NOM"} ,
		{"word":"niais","word_nosc":"niais","lemma":"niais","pos":"NOM"} ,
		{"word":"niaise","word_nosc":"niaise","lemma":"niais","pos":"NOM"} ,
		{"word":"niaiserie","word_nosc":"niaiserie","lemma":"niaiserie","pos":"NOM"} ,
		{"word":"niaiseries","word_nosc":"niaiseries","lemma":"niaiserie","pos":"NOM"} ,
		{"word":"niaises","word_nosc":"niaises","lemma":"niais","pos":"NOM"} ,
		{"word":"niakoué","word_nosc":"niakoue","lemma":"niakoué","pos":"NOM"} ,
		{"word":"niakoués","word_nosc":"niakoues","lemma":"niakoué","pos":"NOM"} ,
		{"word":"nibard","word_nosc":"nibard","lemma":"nibard","pos":"NOM"} ,
		{"word":"nibards","word_nosc":"nibards","lemma":"nibard","pos":"NOM"} ,
		{"word":"nicaraguayenne","word_nosc":"nicaraguayenne","lemma":"nicaraguayen","pos":"NOM"} ,
		{"word":"nicaraguayens","word_nosc":"nicaraguayens","lemma":"nicaraguayen","pos":"NOM"} ,
		{"word":"nice","word_nosc":"nice","lemma":"nice","pos":"NOM"} ,
		{"word":"nichan","word_nosc":"nichan","lemma":"nichan","pos":"NOM"} ,
		{"word":"niche","word_nosc":"niche","lemma":"niche","pos":"NOM"} ,
		{"word":"niches","word_nosc":"niches","lemma":"niche","pos":"NOM"} ,
		{"word":"nichon","word_nosc":"nichon","lemma":"nichon","pos":"NOM"} ,
		{"word":"nichons","word_nosc":"nichons","lemma":"nichon","pos":"NOM"} ,
		{"word":"nichée","word_nosc":"nichee","lemma":"nichée","pos":"NOM"} ,
		{"word":"nichées","word_nosc":"nichees","lemma":"nichée","pos":"NOM"} ,
		{"word":"nickel","word_nosc":"nickel","lemma":"nickel","pos":"NOM"} ,
		{"word":"nickels","word_nosc":"nickels","lemma":"nickel","pos":"NOM"} ,
		{"word":"nicotine","word_nosc":"nicotine","lemma":"nicotine","pos":"NOM"} ,
		{"word":"nictation","word_nosc":"nictation","lemma":"nictation","pos":"NOM"} ,
		{"word":"nid","word_nosc":"nid","lemma":"nid","pos":"NOM"} ,
		{"word":"nid-de-poule","word_nosc":"nid-de-poule","lemma":"nid-de-poule","pos":"NOM"} ,
		{"word":"nidification","word_nosc":"nidification","lemma":"nidification","pos":"NOM"} ,
		{"word":"nidificatrices","word_nosc":"nidificatrices","lemma":"nidificateur","pos":"NOM"} ,
		{"word":"nids","word_nosc":"nids","lemma":"nid","pos":"NOM"} ,
		{"word":"nids-de-poule","word_nosc":"nids-de-poule","lemma":"nid-de-poule","pos":"NOM"} ,
		{"word":"nielle","word_nosc":"nielle","lemma":"nielle","pos":"NOM"} ,
		{"word":"nietzschéen","word_nosc":"nietzscheen","lemma":"nietzschéen","pos":"NOM"} ,
		{"word":"nietzschéenne","word_nosc":"nietzscheenne","lemma":"nietzschéenne","pos":"NOM"} ,
		{"word":"nietzschéennes","word_nosc":"nietzscheennes","lemma":"nietzschéenne","pos":"NOM"} ,
		{"word":"nigaud","word_nosc":"nigaud","lemma":"nigaud","pos":"NOM"} ,
		{"word":"nigaude","word_nosc":"nigaude","lemma":"nigaud","pos":"NOM"} ,
		{"word":"nigauderie","word_nosc":"nigauderie","lemma":"nigauderie","pos":"NOM"} ,
		{"word":"nigauds","word_nosc":"nigauds","lemma":"nigaud","pos":"NOM"} ,
		{"word":"nigelle","word_nosc":"nigelle","lemma":"nigel","pos":"NOM"} ,
		{"word":"night club","word_nosc":"night club","lemma":"night-club","pos":"NOM"} ,
		{"word":"night-club","word_nosc":"night-club","lemma":"night-club","pos":"NOM"} ,
		{"word":"night-clubs","word_nosc":"night-clubs","lemma":"night-club","pos":"NOM"} ,
		{"word":"niguedouille","word_nosc":"niguedouille","lemma":"niguedouille","pos":"NOM"} ,
		{"word":"nigérian","word_nosc":"nigerian","lemma":"nigérian","pos":"NOM"} ,
		{"word":"nigérians","word_nosc":"nigerians","lemma":"nigérian","pos":"NOM"} ,
		{"word":"nigérien","word_nosc":"nigerien","lemma":"nigérien","pos":"NOM"} ,
		{"word":"nigériens","word_nosc":"nigeriens","lemma":"nigérien","pos":"NOM"} ,
		{"word":"nihilisme","word_nosc":"nihilisme","lemma":"nihilisme","pos":"NOM"} ,
		{"word":"nihiliste","word_nosc":"nihiliste","lemma":"nihiliste","pos":"NOM"} ,
		{"word":"nihilistes","word_nosc":"nihilistes","lemma":"nihiliste","pos":"NOM"} ,
		{"word":"nikkei","word_nosc":"nikkei","lemma":"nikkei","pos":"NOM"} ,
		{"word":"nil","word_nosc":"nil","lemma":"nil","pos":"NOM"} ,
		{"word":"nim","word_nosc":"nim","lemma":"nim","pos":"NOM"} ,
		{"word":"nimbe","word_nosc":"nimbe","lemma":"nimbe","pos":"NOM"} ,
		{"word":"nimbes","word_nosc":"nimbes","lemma":"nimbe","pos":"NOM"} ,
		{"word":"nimbo-stratus","word_nosc":"nimbo-stratus","lemma":"nimbo-stratus","pos":"NOM"} ,
		{"word":"nimbostratus","word_nosc":"nimbostratus","lemma":"nimbostratus","pos":"NOM"} ,
		{"word":"nimbus","word_nosc":"nimbus","lemma":"nimbus","pos":"NOM"} ,
		{"word":"nimbé","word_nosc":"nimbe","lemma":"nimbé","pos":"NOM"} ,
		{"word":"ninas","word_nosc":"ninas","lemma":"ninas","pos":"NOM"} ,
		{"word":"niobium","word_nosc":"niobium","lemma":"niobium","pos":"NOM"} ,
		{"word":"niolo","word_nosc":"niolo","lemma":"niolo","pos":"NOM"} ,
		{"word":"nippe","word_nosc":"nippe","lemma":"nippe","pos":"NOM"} ,
		{"word":"nippes","word_nosc":"nippes","lemma":"nippe","pos":"NOM"} ,
		{"word":"nippon","word_nosc":"nippon","lemma":"nippon","pos":"NOM"} ,
		{"word":"nippons","word_nosc":"nippons","lemma":"nippon","pos":"NOM"} ,
		{"word":"nique","word_nosc":"nique","lemma":"nique","pos":"NOM"} ,
		{"word":"niquedouille","word_nosc":"niquedouille","lemma":"niquedouille","pos":"NOM"} ,
		{"word":"nirvana","word_nosc":"nirvana","lemma":"nirvana","pos":"NOM"} ,
		{"word":"nirvanas","word_nosc":"nirvanas","lemma":"nirvana","pos":"NOM"} ,
		{"word":"nirvâna","word_nosc":"nirvana","lemma":"nirvâna","pos":"NOM"} ,
		{"word":"nisan","word_nosc":"nisan","lemma":"nisan","pos":"NOM"} ,
		{"word":"nitrate","word_nosc":"nitrate","lemma":"nitrate","pos":"NOM"} ,
		{"word":"nitrates","word_nosc":"nitrates","lemma":"nitrate","pos":"NOM"} ,
		{"word":"nitre","word_nosc":"nitre","lemma":"nitre","pos":"NOM"} ,
		{"word":"nitrite","word_nosc":"nitrite","lemma":"nitrite","pos":"NOM"} ,
		{"word":"nitroglycérine","word_nosc":"nitroglycerine","lemma":"nitroglycérine","pos":"NOM"} ,
		{"word":"nitrogène","word_nosc":"nitrogene","lemma":"nitrogène","pos":"NOM"} ,
		{"word":"nivaquine","word_nosc":"nivaquine","lemma":"nivaquine","pos":"NOM"} ,
		{"word":"niveau","word_nosc":"niveau","lemma":"niveau","pos":"NOM"} ,
		{"word":"niveaux","word_nosc":"niveaux","lemma":"niveau","pos":"NOM"} ,
		{"word":"niveleur","word_nosc":"niveleur","lemma":"niveleur","pos":"NOM"} ,
		{"word":"niveleurs","word_nosc":"niveleurs","lemma":"niveleur","pos":"NOM"} ,
		{"word":"niveleuse","word_nosc":"niveleuse","lemma":"niveleur","pos":"NOM"} ,
		{"word":"nivellement","word_nosc":"nivellement","lemma":"nivellement","pos":"NOM"} ,
		{"word":"nivôse","word_nosc":"nivose","lemma":"nivôse","pos":"NOM"} ,
		{"word":"nixe","word_nosc":"nixe","lemma":"nixe","pos":"NOM"} ,
		{"word":"niçois","word_nosc":"nicois","lemma":"niçois","pos":"NOM"} ,
		{"word":"niçoise","word_nosc":"nicoise","lemma":"niçois","pos":"NOM"} ,
		{"word":"niçoises","word_nosc":"nicoises","lemma":"niçois","pos":"NOM"} ,
		{"word":"nièce","word_nosc":"niece","lemma":"nièce","pos":"NOM"} ,
		{"word":"nièces","word_nosc":"nieces","lemma":"nièce","pos":"NOM"} ,
		{"word":"nième","word_nosc":"nieme","lemma":"nième","pos":"NOM"} ,
		{"word":"nière","word_nosc":"niere","lemma":"nière","pos":"NOM"} ,
		{"word":"niébé","word_nosc":"niebe","lemma":"niébé","pos":"NOM"} ,
		{"word":"no man's land","word_nosc":"no man's land","lemma":"no man's land","pos":"NOM"} ,
		{"word":"nobiliaire","word_nosc":"nobiliaire","lemma":"nobiliaire","pos":"NOM"} ,
		{"word":"nobilitas","word_nosc":"nobilitas","lemma":"nobilitas","pos":"NOM"} ,
		{"word":"noblaillon","word_nosc":"noblaillon","lemma":"noblaillon","pos":"NOM"} ,
		{"word":"noble","word_nosc":"noble","lemma":"noble","pos":"NOM"} ,
		{"word":"nobles","word_nosc":"nobles","lemma":"noble","pos":"NOM"} ,
		{"word":"noblesse","word_nosc":"noblesse","lemma":"noblesse","pos":"NOM"} ,
		{"word":"noblesses","word_nosc":"noblesses","lemma":"noblesse","pos":"NOM"} ,
		{"word":"nobliau","word_nosc":"nobliau","lemma":"nobliau","pos":"NOM"} ,
		{"word":"nobliaux","word_nosc":"nobliaux","lemma":"nobliau","pos":"NOM"} ,
		{"word":"noce","word_nosc":"noce","lemma":"noce","pos":"NOM"} ,
		{"word":"noces","word_nosc":"noces","lemma":"noce","pos":"NOM"} ,
		{"word":"noceur","word_nosc":"noceur","lemma":"noceur","pos":"NOM"} ,
		{"word":"noceurs","word_nosc":"noceurs","lemma":"noceur","pos":"NOM"} ,
		{"word":"noceuse","word_nosc":"noceuse","lemma":"noceur","pos":"NOM"} ,
		{"word":"noceuses","word_nosc":"noceuses","lemma":"noceur","pos":"NOM"} ,
		{"word":"nocher","word_nosc":"nocher","lemma":"nocher","pos":"NOM"} ,
		{"word":"nochère","word_nosc":"nochere","lemma":"nochère","pos":"NOM"} ,
		{"word":"nocivité","word_nosc":"nocivite","lemma":"nocivité","pos":"NOM"} ,
		{"word":"noctambule","word_nosc":"noctambule","lemma":"noctambule","pos":"NOM"} ,
		{"word":"noctambules","word_nosc":"noctambules","lemma":"noctambule","pos":"NOM"} ,
		{"word":"noctambulisme","word_nosc":"noctambulisme","lemma":"noctambulisme","pos":"NOM"} ,
		{"word":"nocturnal","word_nosc":"nocturnal","lemma":"nocturnal","pos":"NOM"} ,
		{"word":"nocturne","word_nosc":"nocturne","lemma":"nocturne","pos":"NOM"} ,
		{"word":"nocturnes","word_nosc":"nocturnes","lemma":"nocturne","pos":"NOM"} ,
		{"word":"nodosités","word_nosc":"nodosites","lemma":"nodosité","pos":"NOM"} ,
		{"word":"nodule","word_nosc":"nodule","lemma":"nodule","pos":"NOM"} ,
		{"word":"nodules","word_nosc":"nodules","lemma":"nodule","pos":"NOM"} ,
		{"word":"nodus","word_nosc":"nodus","lemma":"nodus","pos":"NOM"} ,
		{"word":"noeud","word_nosc":"noeud","lemma":"noeud","pos":"NOM"} ,
		{"word":"noeuds","word_nosc":"noeuds","lemma":"noeud","pos":"NOM"} ,
		{"word":"noir","word_nosc":"noir","lemma":"noir","pos":"NOM"} ,
		{"word":"noiraud","word_nosc":"noiraud","lemma":"noiraud","pos":"NOM"} ,
		{"word":"noirauds","word_nosc":"noirauds","lemma":"noiraud","pos":"NOM"} ,
		{"word":"noirceur","word_nosc":"noirceur","lemma":"noirceur","pos":"NOM"} ,
		{"word":"noirceurs","word_nosc":"noirceurs","lemma":"noirceur","pos":"NOM"} ,
		{"word":"noire","word_nosc":"noire","lemma":"noir","pos":"NOM"} ,
		{"word":"noires","word_nosc":"noires","lemma":"noir","pos":"NOM"} ,
		{"word":"noirs","word_nosc":"noirs","lemma":"noir","pos":"NOM"} ,
		{"word":"noise","word_nosc":"noise","lemma":"noise","pos":"NOM"} ,
		{"word":"noises","word_nosc":"noises","lemma":"noise","pos":"NOM"} ,
		{"word":"noisetier","word_nosc":"noisetier","lemma":"noisetier","pos":"NOM"} ,
		{"word":"noisetiers","word_nosc":"noisetiers","lemma":"noisetier","pos":"NOM"} ,
		{"word":"noisette","word_nosc":"noisette","lemma":"noisette","pos":"NOM"} ,
		{"word":"noisettes","word_nosc":"noisettes","lemma":"noisette","pos":"NOM"} ,
		{"word":"noix","word_nosc":"noix","lemma":"noix","pos":"NOM"} ,
		{"word":"nolis","word_nosc":"nolis","lemma":"nolis","pos":"NOM"} ,
		{"word":"nom","word_nosc":"nom","lemma":"nom","pos":"NOM"} ,
		{"word":"nomade","word_nosc":"nomade","lemma":"nomade","pos":"NOM"} ,
		{"word":"nomades","word_nosc":"nomades","lemma":"nomade","pos":"NOM"} ,
		{"word":"nomadisme","word_nosc":"nomadisme","lemma":"nomadisme","pos":"NOM"} ,
		{"word":"nombre","word_nosc":"nombre","lemma":"nombre","pos":"NOM"} ,
		{"word":"nombres","word_nosc":"nombres","lemma":"nombre","pos":"NOM"} ,
		{"word":"nombril","word_nosc":"nombril","lemma":"nombril","pos":"NOM"} ,
		{"word":"nombrilisme","word_nosc":"nombrilisme","lemma":"nombrilisme","pos":"NOM"} ,
		{"word":"nombriliste","word_nosc":"nombriliste","lemma":"nombriliste","pos":"NOM"} ,
		{"word":"nombrils","word_nosc":"nombrils","lemma":"nombril","pos":"NOM"} ,
		{"word":"nome","word_nosc":"nome","lemma":"nome","pos":"NOM"} ,
		{"word":"nomenclature","word_nosc":"nomenclature","lemma":"nomenclature","pos":"NOM"} ,
		{"word":"nomenclatures","word_nosc":"nomenclatures","lemma":"nomenclature","pos":"NOM"} ,
		{"word":"nomenklatura","word_nosc":"nomenklatura","lemma":"nomenklatura","pos":"NOM"} ,
		{"word":"nomination","word_nosc":"nomination","lemma":"nomination","pos":"NOM"} ,
		{"word":"nominations","word_nosc":"nominations","lemma":"nomination","pos":"NOM"} ,
		{"word":"noms","word_nosc":"noms","lemma":"nom","pos":"NOM"} ,
		{"word":"non","word_nosc":"non","lemma":"non","pos":"NOM"} ,
		{"word":"non-agression","word_nosc":"non-agression","lemma":"non-agression","pos":"NOM"} ,
		{"word":"non-alignement","word_nosc":"non-alignement","lemma":"non-alignement","pos":"NOM"} ,
		{"word":"non-amour","word_nosc":"non-amour","lemma":"non-amour","pos":"NOM"} ,
		{"word":"non-appartenance","word_nosc":"non-appartenance","lemma":"non-appartenance","pos":"NOM"} ,
		{"word":"non-assistance","word_nosc":"non-assistance","lemma":"non-assistance","pos":"NOM"} ,
		{"word":"non-blanc","word_nosc":"non-blanc","lemma":"non-blanc","pos":"NOM"} ,
		{"word":"non-blancs","word_nosc":"non-blancs","lemma":"non-blanc","pos":"NOM"} ,
		{"word":"non-combattant","word_nosc":"non-combattant","lemma":"non-combattant","pos":"NOM"} ,
		{"word":"non-combattants","word_nosc":"non-combattants","lemma":"non-combattant","pos":"NOM"} ,
		{"word":"non-comparution","word_nosc":"non-comparution","lemma":"non-comparution","pos":"NOM"} ,
		{"word":"non-concurrence","word_nosc":"non-concurrence","lemma":"non-concurrence","pos":"NOM"} ,
		{"word":"non-conformisme","word_nosc":"non-conformisme","lemma":"non-conformisme","pos":"NOM"} ,
		{"word":"non-conformiste","word_nosc":"non-conformiste","lemma":"non-conformiste","pos":"NOM"} ,
		{"word":"non-conformistes","word_nosc":"non-conformistes","lemma":"non-conformiste","pos":"NOM"} ,
		{"word":"non-conformité","word_nosc":"non-conformite","lemma":"non-conformité","pos":"NOM"} ,
		{"word":"non-consommation","word_nosc":"non-consommation","lemma":"non-consommation","pos":"NOM"} ,
		{"word":"non-croyance","word_nosc":"non-croyance","lemma":"non-croyance","pos":"NOM"} ,
		{"word":"non-croyant","word_nosc":"non-croyant","lemma":"non-croyant","pos":"NOM"} ,
		{"word":"non-croyants","word_nosc":"non-croyants","lemma":"non-croyant","pos":"NOM"} ,
		{"word":"non-culpabilité","word_nosc":"non-culpabilite","lemma":"non-culpabilité","pos":"NOM"} ,
		{"word":"non-dit","word_nosc":"non-dit","lemma":"non-dit","pos":"NOM"} ,
		{"word":"non-dits","word_nosc":"non-dits","lemma":"non-dit","pos":"NOM"} ,
		{"word":"non-droit","word_nosc":"non-droit","lemma":"non-droit","pos":"NOM"} ,
		{"word":"non-existence","word_nosc":"non-existence","lemma":"non-existence","pos":"NOM"} ,
		{"word":"non-ferreux","word_nosc":"non-ferreux","lemma":"non-ferreux","pos":"NOM"} ,
		{"word":"non-fumeur","word_nosc":"non-fumeur","lemma":"non-fumeur","pos":"NOM"} ,
		{"word":"non-initié","word_nosc":"non-initie","lemma":"non-initié","pos":"NOM"} ,
		{"word":"non-initiés","word_nosc":"non-inities","lemma":"non-initié","pos":"NOM"} ,
		{"word":"non-intervention","word_nosc":"non-intervention","lemma":"non-intervention","pos":"NOM"} ,
		{"word":"non-lieu","word_nosc":"non-lieu","lemma":"non-lieu","pos":"NOM"} ,
		{"word":"non-lieux","word_nosc":"non-lieux","lemma":"non-lieux","pos":"NOM"} ,
		{"word":"non-malades","word_nosc":"non-malades","lemma":"non-malade","pos":"NOM"} ,
		{"word":"non-paiement","word_nosc":"non-paiement","lemma":"non-paiement","pos":"NOM"} ,
		{"word":"non-participation","word_nosc":"non-participation","lemma":"non-participation","pos":"NOM"} ,
		{"word":"non-pesanteur","word_nosc":"non-pesanteur","lemma":"non-pesanteur","pos":"NOM"} ,
		{"word":"non-prolifération","word_nosc":"non-proliferation","lemma":"non-prolifération","pos":"NOM"} ,
		{"word":"non-présence","word_nosc":"non-presence","lemma":"non-présence","pos":"NOM"} ,
		{"word":"non-recevoir","word_nosc":"non-recevoir","lemma":"non-recevoir","pos":"NOM"} ,
		{"word":"non-représentation","word_nosc":"non-representation","lemma":"non-représentation","pos":"NOM"} ,
		{"word":"non-respect","word_nosc":"non-respect","lemma":"non-respect","pos":"NOM"} ,
		{"word":"non-retour","word_nosc":"non-retour","lemma":"non-retour","pos":"NOM"} ,
		{"word":"non-résistance","word_nosc":"non-resistance","lemma":"non-résistance","pos":"NOM"} ,
		{"word":"non-savoir","word_nosc":"non-savoir","lemma":"non-savoir","pos":"NOM"} ,
		{"word":"non-sens","word_nosc":"non-sens","lemma":"non-sens","pos":"NOM"} ,
		{"word":"non-spécialiste","word_nosc":"non-specialiste","lemma":"non-spécialiste","pos":"NOM"} ,
		{"word":"non-spécialistes","word_nosc":"non-specialistes","lemma":"non-spécialiste","pos":"NOM"} ,
		{"word":"non-séparation","word_nosc":"non-separation","lemma":"non-séparation","pos":"NOM"} ,
		{"word":"non-utilisation","word_nosc":"non-utilisation","lemma":"non-utilisation","pos":"NOM"} ,
		{"word":"non-vie","word_nosc":"non-vie","lemma":"non-vie","pos":"NOM"} ,
		{"word":"non-violence","word_nosc":"non-violence","lemma":"non-violence","pos":"NOM"} ,
		{"word":"non-violent","word_nosc":"non-violent","lemma":"non-violent","pos":"NOM"} ,
		{"word":"non-violente","word_nosc":"non-violente","lemma":"non-violent","pos":"NOM"} ,
		{"word":"non-violents","word_nosc":"non-violents","lemma":"non-violent","pos":"NOM"} ,
		{"word":"non-vouloir","word_nosc":"non-vouloir","lemma":"non-vouloir","pos":"NOM"} ,
		{"word":"non-voyant","word_nosc":"non-voyant","lemma":"non-voyant","pos":"NOM"} ,
		{"word":"non-voyante","word_nosc":"non-voyante","lemma":"non-voyant","pos":"NOM"} ,
		{"word":"non-voyants","word_nosc":"non-voyants","lemma":"non-voyant","pos":"NOM"} ,
		{"word":"non-événement","word_nosc":"non-evenement","lemma":"non-événement","pos":"NOM"} ,
		{"word":"non-être","word_nosc":"non-etre","lemma":"non-être","pos":"NOM"} ,
		{"word":"nonagénaire","word_nosc":"nonagenaire","lemma":"nonagénaire","pos":"NOM"} ,
		{"word":"nonagénaires","word_nosc":"nonagenaires","lemma":"nonagénaire","pos":"NOM"} ,
		{"word":"nonce","word_nosc":"nonce","lemma":"nonce","pos":"NOM"} ,
		{"word":"nonchalance","word_nosc":"nonchalance","lemma":"nonchalance","pos":"NOM"} ,
		{"word":"nonciature","word_nosc":"nonciature","lemma":"nonciature","pos":"NOM"} ,
		{"word":"none","word_nosc":"none","lemma":"none","pos":"NOM"} ,
		{"word":"nones","word_nosc":"nones","lemma":"none","pos":"NOM"} ,
		{"word":"nonette","word_nosc":"nonette","lemma":"nonette","pos":"NOM"} ,
		{"word":"nonettes","word_nosc":"nonettes","lemma":"nonette","pos":"NOM"} ,
		{"word":"nonidi","word_nosc":"nonidi","lemma":"nonidi","pos":"NOM"} ,
		{"word":"nonnain","word_nosc":"nonnain","lemma":"nonnain","pos":"NOM"} ,
		{"word":"nonne","word_nosc":"nonne","lemma":"nonne","pos":"NOM"} ,
		{"word":"nonnes","word_nosc":"nonnes","lemma":"nonne","pos":"NOM"} ,
		{"word":"nonnette","word_nosc":"nonnette","lemma":"nonnette","pos":"NOM"} ,
		{"word":"nonnettes","word_nosc":"nonnettes","lemma":"nonnette","pos":"NOM"} ,
		{"word":"nonsense","word_nosc":"nonsense","lemma":"nonsense","pos":"NOM"} ,
		{"word":"nope","word_nosc":"nope","lemma":"nope","pos":"NOM"} ,
		{"word":"noradrénaline","word_nosc":"noradrenaline","lemma":"noradrénaline","pos":"NOM"} ,
		{"word":"nord","word_nosc":"nord","lemma":"nord","pos":"NOM"} ,
		{"word":"nord-africain","word_nosc":"nord-africain","lemma":"nord-africain","pos":"NOM"} ,
		{"word":"nord-africains","word_nosc":"nord-africains","lemma":"nord-africain","pos":"NOM"} ,
		{"word":"nord-américain","word_nosc":"nord-americain","lemma":"nord-américain","pos":"NOM"} ,
		{"word":"nord-américains","word_nosc":"nord-americains","lemma":"nord-américain","pos":"NOM"} ,
		{"word":"nord-coréen","word_nosc":"nord-coreen","lemma":"nord-coréen","pos":"NOM"} ,
		{"word":"nord-coréenne","word_nosc":"nord-coreenne","lemma":"nord-coréen","pos":"NOM"} ,
		{"word":"nord-coréens","word_nosc":"nord-coreens","lemma":"nord-coréen","pos":"NOM"} ,
		{"word":"nord-est","word_nosc":"nord-est","lemma":"nord-est","pos":"NOM"} ,
		{"word":"nord-nord-est","word_nosc":"nord-nord-est","lemma":"nord-nord-est","pos":"NOM"} ,
		{"word":"nord-ouest","word_nosc":"nord-ouest","lemma":"nord-ouest","pos":"NOM"} ,
		{"word":"nord-vietnamien","word_nosc":"nord-vietnamien","lemma":"nord-vietnamien","pos":"NOM"} ,
		{"word":"nord-vietnamiens","word_nosc":"nord-vietnamiens","lemma":"nord-vietnamien","pos":"NOM"} ,
		{"word":"nordet","word_nosc":"nordet","lemma":"nordet","pos":"NOM"} ,
		{"word":"nordicité","word_nosc":"nordicite","lemma":"nordicité","pos":"NOM"} ,
		{"word":"nordique","word_nosc":"nordique","lemma":"nordique","pos":"NOM"} ,
		{"word":"nordiques","word_nosc":"nordiques","lemma":"nordique","pos":"NOM"} ,
		{"word":"nordiste","word_nosc":"nordiste","lemma":"nordiste","pos":"NOM"} ,
		{"word":"nordistes","word_nosc":"nordistes","lemma":"nordiste","pos":"NOM"} ,
		{"word":"noria","word_nosc":"noria","lemma":"noria","pos":"NOM"} ,
		{"word":"norias","word_nosc":"norias","lemma":"noria","pos":"NOM"} ,
		{"word":"normal","word_nosc":"normal","lemma":"normal","pos":"NOM"} ,
		{"word":"normale","word_nosc":"normale","lemma":"normale","pos":"NOM"} ,
		{"word":"normales","word_nosc":"normales","lemma":"normale","pos":"NOM"} ,
		{"word":"normalien","word_nosc":"normalien","lemma":"normalien","pos":"NOM"} ,
		{"word":"normaliennes","word_nosc":"normaliennes","lemma":"normalien","pos":"NOM"} ,
		{"word":"normaliens","word_nosc":"normaliens","lemma":"normalien","pos":"NOM"} ,
		{"word":"normalisation","word_nosc":"normalisation","lemma":"normalisation","pos":"NOM"} ,
		{"word":"normalité","word_nosc":"normalite","lemma":"normalité","pos":"NOM"} ,
		{"word":"normand","word_nosc":"normand","lemma":"normand","pos":"NOM"} ,
		{"word":"normande","word_nosc":"normande","lemma":"normand","pos":"NOM"} ,
		{"word":"normandes","word_nosc":"normandes","lemma":"normand","pos":"NOM"} ,
		{"word":"normands","word_nosc":"normands","lemma":"normand","pos":"NOM"} ,
		{"word":"norme","word_nosc":"norme","lemma":"norme","pos":"NOM"} ,
		{"word":"normes","word_nosc":"normes","lemma":"norme","pos":"NOM"} ,
		{"word":"noroît","word_nosc":"noroit","lemma":"noroît","pos":"NOM"} ,
		{"word":"norvégien","word_nosc":"norvegien","lemma":"norvégien","pos":"NOM"} ,
		{"word":"norvégienne","word_nosc":"norvegienne","lemma":"norvégienne","pos":"NOM"} ,
		{"word":"norvégiennes","word_nosc":"norvegiennes","lemma":"norvégienne","pos":"NOM"} ,
		{"word":"norvégiens","word_nosc":"norvegiens","lemma":"norvégien","pos":"NOM"} ,
		{"word":"nostalgie","word_nosc":"nostalgie","lemma":"nostalgie","pos":"NOM"} ,
		{"word":"nostalgies","word_nosc":"nostalgies","lemma":"nostalgie","pos":"NOM"} ,
		{"word":"nostalgique","word_nosc":"nostalgique","lemma":"nostalgique","pos":"NOM"} ,
		{"word":"nostalgiques","word_nosc":"nostalgiques","lemma":"nostalgique","pos":"NOM"} ,
		{"word":"not","word_nosc":"not","lemma":"not","pos":"NOM"} ,
		{"word":"nota","word_nosc":"nota","lemma":"nota","pos":"NOM"} ,
		{"word":"notabilité","word_nosc":"notabilite","lemma":"notabilité","pos":"NOM"} ,
		{"word":"notabilités","word_nosc":"notabilites","lemma":"notabilité","pos":"NOM"} ,
		{"word":"notable","word_nosc":"notable","lemma":"notable","pos":"NOM"} ,
		{"word":"notables","word_nosc":"notables","lemma":"notable","pos":"NOM"} ,
		{"word":"notaire","word_nosc":"notaire","lemma":"notaire","pos":"NOM"} ,
		{"word":"notaires","word_nosc":"notaires","lemma":"notaire","pos":"NOM"} ,
		{"word":"notariat","word_nosc":"notariat","lemma":"notariat","pos":"NOM"} ,
		{"word":"notation","word_nosc":"notation","lemma":"notation","pos":"NOM"} ,
		{"word":"notations","word_nosc":"notations","lemma":"notation","pos":"NOM"} ,
		{"word":"note","word_nosc":"note","lemma":"note","pos":"NOM"} ,
		{"word":"notes","word_nosc":"notes","lemma":"note","pos":"NOM"} ,
		{"word":"notice","word_nosc":"notice","lemma":"notice","pos":"NOM"} ,
		{"word":"notices","word_nosc":"notices","lemma":"notice","pos":"NOM"} ,
		{"word":"notification","word_nosc":"notification","lemma":"notification","pos":"NOM"} ,
		{"word":"notion","word_nosc":"notion","lemma":"notion","pos":"NOM"} ,
		{"word":"notions","word_nosc":"notions","lemma":"notion","pos":"NOM"} ,
		{"word":"notonecte","word_nosc":"notonecte","lemma":"notonecte","pos":"NOM"} ,
		{"word":"notonectes","word_nosc":"notonectes","lemma":"notonecte","pos":"NOM"} ,
		{"word":"notoriété","word_nosc":"notoriete","lemma":"notoriété","pos":"NOM"} ,
		{"word":"notoriétés","word_nosc":"notorietes","lemma":"notoriété","pos":"NOM"} ,
		{"word":"notre-dame","word_nosc":"notre-dame","lemma":"notre-dame","pos":"NOM"} ,
		{"word":"notules","word_nosc":"notules","lemma":"notule","pos":"NOM"} ,
		{"word":"nouage","word_nosc":"nouage","lemma":"nouage","pos":"NOM"} ,
		{"word":"nouba","word_nosc":"nouba","lemma":"nouba","pos":"NOM"} ,
		{"word":"noubas","word_nosc":"noubas","lemma":"nouba","pos":"NOM"} ,
		{"word":"noue","word_nosc":"noue","lemma":"noue","pos":"NOM"} ,
		{"word":"nougat","word_nosc":"nougat","lemma":"nougat","pos":"NOM"} ,
		{"word":"nougatine","word_nosc":"nougatine","lemma":"nougatine","pos":"NOM"} ,
		{"word":"nougatines","word_nosc":"nougatines","lemma":"nougatine","pos":"NOM"} ,
		{"word":"nougats","word_nosc":"nougats","lemma":"nougat","pos":"NOM"} ,
		{"word":"nouille","word_nosc":"nouille","lemma":"nouille","pos":"NOM"} ,
		{"word":"nouilles","word_nosc":"nouilles","lemma":"nouille","pos":"NOM"} ,
		{"word":"noumène","word_nosc":"noumene","lemma":"noumène","pos":"NOM"} ,
		{"word":"nounou","word_nosc":"nounou","lemma":"nounou","pos":"NOM"} ,
		{"word":"nounours","word_nosc":"nounours","lemma":"nounours","pos":"NOM"} ,
		{"word":"nounous","word_nosc":"nounous","lemma":"nounou","pos":"NOM"} ,
		{"word":"nourrice","word_nosc":"nourrice","lemma":"nourrice","pos":"NOM"} ,
		{"word":"nourrices","word_nosc":"nourrices","lemma":"nourrice","pos":"NOM"} ,
		{"word":"nourricier","word_nosc":"nourricier","lemma":"nourricier","pos":"NOM"} ,
		{"word":"nourriciers","word_nosc":"nourriciers","lemma":"nourricier","pos":"NOM"} ,
		{"word":"nourricière","word_nosc":"nourriciere","lemma":"nourricier","pos":"NOM"} ,
		{"word":"nourricières","word_nosc":"nourricieres","lemma":"nourricier","pos":"NOM"} ,
		{"word":"nourrissage","word_nosc":"nourrissage","lemma":"nourrissage","pos":"NOM"} ,
		{"word":"nourrisseur","word_nosc":"nourrisseur","lemma":"nourrisseur","pos":"NOM"} ,
		{"word":"nourrisson","word_nosc":"nourrisson","lemma":"nourrisson","pos":"NOM"} ,
		{"word":"nourrissons","word_nosc":"nourrissons","lemma":"nourrisson","pos":"NOM"} ,
		{"word":"nourriture","word_nosc":"nourriture","lemma":"nourriture","pos":"NOM"} ,
		{"word":"nourritures","word_nosc":"nourritures","lemma":"nourriture","pos":"NOM"} ,
		{"word":"nouveau","word_nosc":"nouveau","lemma":"nouveau","pos":"NOM"} ,
		{"word":"nouveau-né","word_nosc":"nouveau-ne","lemma":"nouveau-né","pos":"NOM"} ,
		{"word":"nouveau-nés","word_nosc":"nouveau-nes","lemma":"nouveau-né","pos":"NOM"} ,
		{"word":"nouveauté","word_nosc":"nouveaute","lemma":"nouveauté","pos":"NOM"} ,
		{"word":"nouveautés","word_nosc":"nouveautes","lemma":"nouveauté","pos":"NOM"} ,
		{"word":"nouveaux","word_nosc":"nouveaux","lemma":"nouveau","pos":"NOM"} ,
		{"word":"nouveaux-nés","word_nosc":"nouveaux-nes","lemma":"nouveau-né","pos":"NOM"} ,
		{"word":"nouvelle","word_nosc":"nouvelle","lemma":"nouveau","pos":"NOM"} ,
		{"word":"nouvelles","word_nosc":"nouvelles","lemma":"nouveau","pos":"NOM"} ,
		{"word":"nouvelleté","word_nosc":"nouvellete","lemma":"nouvelleté","pos":"NOM"} ,
		{"word":"nouvelliste","word_nosc":"nouvelliste","lemma":"nouvelliste","pos":"NOM"} ,
		{"word":"nova","word_nosc":"nova","lemma":"nova","pos":"NOM"} ,
		{"word":"novateur","word_nosc":"novateur","lemma":"novateur","pos":"NOM"} ,
		{"word":"novateurs","word_nosc":"novateurs","lemma":"novateur","pos":"NOM"} ,
		{"word":"novation","word_nosc":"novation","lemma":"novation","pos":"NOM"} ,
		{"word":"novelettes","word_nosc":"novelettes","lemma":"novelette","pos":"NOM"} ,
		{"word":"novembre","word_nosc":"novembre","lemma":"novembre","pos":"NOM"} ,
		{"word":"novice","word_nosc":"novice","lemma":"novice","pos":"NOM"} ,
		{"word":"novices","word_nosc":"novices","lemma":"novice","pos":"NOM"} ,
		{"word":"noviciat","word_nosc":"noviciat","lemma":"noviciat","pos":"NOM"} ,
		{"word":"novilleros","word_nosc":"novilleros","lemma":"novillero","pos":"NOM"} ,
		{"word":"novillo","word_nosc":"novillo","lemma":"novillo","pos":"NOM"} ,
		{"word":"novocaïne","word_nosc":"novocaine","lemma":"novocaïne","pos":"NOM"} ,
		{"word":"novotique","word_nosc":"novotique","lemma":"novotique","pos":"NOM"} ,
		{"word":"novélisation","word_nosc":"novelisation","lemma":"novélisation","pos":"NOM"} ,
		{"word":"noyade","word_nosc":"noyade","lemma":"noyade","pos":"NOM"} ,
		{"word":"noyades","word_nosc":"noyades","lemma":"noyade","pos":"NOM"} ,
		{"word":"noyau","word_nosc":"noyau","lemma":"noyau","pos":"NOM"} ,
		{"word":"noyautage","word_nosc":"noyautage","lemma":"noyautage","pos":"NOM"} ,
		{"word":"noyaux","word_nosc":"noyaux","lemma":"noyau","pos":"NOM"} ,
		{"word":"noyer","word_nosc":"noyer","lemma":"noyer","pos":"NOM"} ,
		{"word":"noyers","word_nosc":"noyers","lemma":"noyer","pos":"NOM"} ,
		{"word":"noyé","word_nosc":"noye","lemma":"noyé","pos":"NOM"} ,
		{"word":"noyée","word_nosc":"noyee","lemma":"noyé","pos":"NOM"} ,
		{"word":"noyées","word_nosc":"noyees","lemma":"noyé","pos":"NOM"} ,
		{"word":"noyés","word_nosc":"noyes","lemma":"noyé","pos":"NOM"} ,
		{"word":"noël","word_nosc":"noel","lemma":"noël","pos":"NOM"} ,
		{"word":"noëls","word_nosc":"noels","lemma":"noël","pos":"NOM"} ,
		{"word":"nu","word_nosc":"nu","lemma":"nu","pos":"NOM"} ,
		{"word":"nu-propriétaire","word_nosc":"nu-proprietaire","lemma":"nu-propriétaire","pos":"NOM"} ,
		{"word":"nuage","word_nosc":"nuage","lemma":"nuage","pos":"NOM"} ,
		{"word":"nuages","word_nosc":"nuages","lemma":"nuage","pos":"NOM"} ,
		{"word":"nuance","word_nosc":"nuance","lemma":"nuance","pos":"NOM"} ,
		{"word":"nuances","word_nosc":"nuances","lemma":"nuance","pos":"NOM"} ,
		{"word":"nuancier","word_nosc":"nuancier","lemma":"nuancier","pos":"NOM"} ,
		{"word":"nubien","word_nosc":"nubien","lemma":"nubien","pos":"NOM"} ,
		{"word":"nubiens","word_nosc":"nubiens","lemma":"nubien","pos":"NOM"} ,
		{"word":"nubilité","word_nosc":"nubilite","lemma":"nubilité","pos":"NOM"} ,
		{"word":"nubuck","word_nosc":"nubuck","lemma":"nubuck","pos":"NOM"} ,
		{"word":"nucléaire","word_nosc":"nucleaire","lemma":"nucléaire","pos":"NOM"} ,
		{"word":"nucléaires","word_nosc":"nucleaires","lemma":"nucléaire","pos":"NOM"} ,
		{"word":"nucléoside","word_nosc":"nucleoside","lemma":"nucléoside","pos":"NOM"} ,
		{"word":"nucléotides","word_nosc":"nucleotides","lemma":"nucléotide","pos":"NOM"} ,
		{"word":"nucléus","word_nosc":"nucleus","lemma":"nucléus","pos":"NOM"} ,
		{"word":"nudisme","word_nosc":"nudisme","lemma":"nudisme","pos":"NOM"} ,
		{"word":"nudiste","word_nosc":"nudiste","lemma":"nudiste","pos":"NOM"} ,
		{"word":"nudistes","word_nosc":"nudistes","lemma":"nudiste","pos":"NOM"} ,
		{"word":"nudité","word_nosc":"nudite","lemma":"nudité","pos":"NOM"} ,
		{"word":"nudités","word_nosc":"nudites","lemma":"nudité","pos":"NOM"} ,
		{"word":"nue","word_nosc":"nue","lemma":"nu","pos":"NOM"} ,
		{"word":"nue-propriété","word_nosc":"nue-propriete","lemma":"nue-propriété","pos":"NOM"} ,
		{"word":"nues","word_nosc":"nues","lemma":"nu","pos":"NOM"} ,
		{"word":"nuisance","word_nosc":"nuisance","lemma":"nuisance","pos":"NOM"} ,
		{"word":"nuisances","word_nosc":"nuisances","lemma":"nuisance","pos":"NOM"} ,
		{"word":"nuisette","word_nosc":"nuisette","lemma":"nuisette","pos":"NOM"} ,
		{"word":"nuisible","word_nosc":"nuisible","lemma":"nuisible","pos":"NOM"} ,
		{"word":"nuisibles","word_nosc":"nuisibles","lemma":"nuisible","pos":"NOM"} ,
		{"word":"nuit","word_nosc":"nuit","lemma":"nuit","pos":"NOM"} ,
		{"word":"nuitards","word_nosc":"nuitards","lemma":"nuitard","pos":"NOM"} ,
		{"word":"nuiteux","word_nosc":"nuiteux","lemma":"nuiteux","pos":"NOM"} ,
		{"word":"nuits","word_nosc":"nuits","lemma":"nuit","pos":"NOM"} ,
		{"word":"nuitée","word_nosc":"nuitee","lemma":"nuitée","pos":"NOM"} ,
		{"word":"nuitées","word_nosc":"nuitees","lemma":"nuitée","pos":"NOM"} ,
		{"word":"nul","word_nosc":"nul","lemma":"nul","pos":"NOM"} ,
		{"word":"nullard","word_nosc":"nullard","lemma":"nullard","pos":"NOM"} ,
		{"word":"nullards","word_nosc":"nullards","lemma":"nullard","pos":"NOM"} ,
		{"word":"nullité","word_nosc":"nullite","lemma":"nullité","pos":"NOM"} ,
		{"word":"nullités","word_nosc":"nullites","lemma":"nullité","pos":"NOM"} ,
		{"word":"nuls","word_nosc":"nuls","lemma":"nul","pos":"NOM"} ,
		{"word":"numerus clausus","word_nosc":"numerus clausus","lemma":"numerus clausus","pos":"NOM"} ,
		{"word":"numide","word_nosc":"numide","lemma":"numide","pos":"NOM"} ,
		{"word":"numismate","word_nosc":"numismate","lemma":"numismate","pos":"NOM"} ,
		{"word":"numismates","word_nosc":"numismates","lemma":"numismate","pos":"NOM"} ,
		{"word":"numéraire","word_nosc":"numeraire","lemma":"numéraire","pos":"NOM"} ,
		{"word":"numérateur","word_nosc":"numerateur","lemma":"numérateur","pos":"NOM"} ,
		{"word":"numération","word_nosc":"numeration","lemma":"numération","pos":"NOM"} ,
		{"word":"numérisation","word_nosc":"numerisation","lemma":"numérisation","pos":"NOM"} ,
		{"word":"numéro","word_nosc":"numero","lemma":"numéro","pos":"NOM"} ,
		{"word":"numérologie","word_nosc":"numerologie","lemma":"numérologie","pos":"NOM"} ,
		{"word":"numérologue","word_nosc":"numerologue","lemma":"numérologue","pos":"NOM"} ,
		{"word":"numéros","word_nosc":"numeros","lemma":"numéro","pos":"NOM"} ,
		{"word":"numérotage","word_nosc":"numerotage","lemma":"numérotage","pos":"NOM"} ,
		{"word":"numérotation","word_nosc":"numerotation","lemma":"numérotation","pos":"NOM"} ,
		{"word":"nunchaku","word_nosc":"nunchaku","lemma":"nunchaku","pos":"NOM"} ,
		{"word":"nunchakus","word_nosc":"nunchakus","lemma":"nunchaku","pos":"NOM"} ,
		{"word":"nuoc-mâm","word_nosc":"nuoc-mam","lemma":"nuoc-mâm","pos":"NOM"} ,
		{"word":"nuptialité","word_nosc":"nuptialite","lemma":"nuptialité","pos":"NOM"} ,
		{"word":"nuque","word_nosc":"nuque","lemma":"nuque","pos":"NOM"} ,
		{"word":"nuques","word_nosc":"nuques","lemma":"nuque","pos":"NOM"} ,
		{"word":"nurse","word_nosc":"nurse","lemma":"nurse","pos":"NOM"} ,
		{"word":"nurseries","word_nosc":"nurseries","lemma":"nurseries","pos":"NOM"} ,
		{"word":"nursery","word_nosc":"nursery","lemma":"nursery","pos":"NOM"} ,
		{"word":"nurses","word_nosc":"nurses","lemma":"nurse","pos":"NOM"} ,
		{"word":"nursing","word_nosc":"nursing","lemma":"nursing","pos":"NOM"} ,
		{"word":"nus","word_nosc":"nus","lemma":"nu","pos":"NOM"} ,
		{"word":"nutriment","word_nosc":"nutriment","lemma":"nutriment","pos":"NOM"} ,
		{"word":"nutriments","word_nosc":"nutriments","lemma":"nutriment","pos":"NOM"} ,
		{"word":"nutrition","word_nosc":"nutrition","lemma":"nutrition","pos":"NOM"} ,
		{"word":"nutritionniste","word_nosc":"nutritionniste","lemma":"nutritionniste","pos":"NOM"} ,
		{"word":"nuée","word_nosc":"nuee","lemma":"nuée","pos":"NOM"} ,
		{"word":"nuées","word_nosc":"nuees","lemma":"nuée","pos":"NOM"} ,
		{"word":"nyctalope","word_nosc":"nyctalope","lemma":"nyctalope","pos":"NOM"} ,
		{"word":"nyctalopes","word_nosc":"nyctalopes","lemma":"nyctalope","pos":"NOM"} ,
		{"word":"nylon","word_nosc":"nylon","lemma":"nylon","pos":"NOM"} ,
		{"word":"nylons","word_nosc":"nylons","lemma":"nylon","pos":"NOM"} ,
		{"word":"nymphe","word_nosc":"nymphe","lemma":"nymphe","pos":"NOM"} ,
		{"word":"nymphes","word_nosc":"nymphes","lemma":"nymphe","pos":"NOM"} ,
		{"word":"nymphette","word_nosc":"nymphette","lemma":"nymphette","pos":"NOM"} ,
		{"word":"nymphettes","word_nosc":"nymphettes","lemma":"nymphette","pos":"NOM"} ,
		{"word":"nympho","word_nosc":"nympho","lemma":"nympho","pos":"NOM"} ,
		{"word":"nymphomane","word_nosc":"nymphomane","lemma":"nymphomane","pos":"NOM"} ,
		{"word":"nymphomanes","word_nosc":"nymphomanes","lemma":"nymphomane","pos":"NOM"} ,
		{"word":"nymphomanie","word_nosc":"nymphomanie","lemma":"nymphomanie","pos":"NOM"} ,
		{"word":"nymphos","word_nosc":"nymphos","lemma":"nympho","pos":"NOM"} ,
		{"word":"nymphéa","word_nosc":"nymphea","lemma":"nymphéa","pos":"NOM"} ,
		{"word":"nymphéas","word_nosc":"nympheas","lemma":"nymphéa","pos":"NOM"} ,
		{"word":"nymphées","word_nosc":"nymphees","lemma":"nymphée","pos":"NOM"} ,
		{"word":"nèfles","word_nosc":"nefles","lemma":"nèfle","pos":"NOM"} ,
		{"word":"nègre","word_nosc":"negre","lemma":"nègre","pos":"NOM"} ,
		{"word":"nègres","word_nosc":"negres","lemma":"nègre","pos":"NOM"} ,
		{"word":"nèpe","word_nosc":"nepe","lemma":"nèpe","pos":"NOM"} ,
		{"word":"nèpes","word_nosc":"nepes","lemma":"nèpe","pos":"NOM"} ,
		{"word":"néandertaliens","word_nosc":"neandertaliens","lemma":"néandertalien","pos":"NOM"} ,
		{"word":"néanderthalien","word_nosc":"neanderthalien","lemma":"néanderthalien","pos":"NOM"} ,
		{"word":"néant","word_nosc":"neant","lemma":"néant","pos":"NOM"} ,
		{"word":"néantisation","word_nosc":"neantisation","lemma":"néantisation","pos":"NOM"} ,
		{"word":"néants","word_nosc":"neants","lemma":"néant","pos":"NOM"} ,
		{"word":"nébuleuse","word_nosc":"nebuleuse","lemma":"nébuleux","pos":"NOM"} ,
		{"word":"nébuleuses","word_nosc":"nebuleuses","lemma":"nébuleuse","pos":"NOM"} ,
		{"word":"nébuleuses","word_nosc":"nebuleuses","lemma":"nébuleux","pos":"NOM"} ,
		{"word":"nébuliseur","word_nosc":"nebuliseur","lemma":"nébuliseur","pos":"NOM"} ,
		{"word":"nébulosité","word_nosc":"nebulosite","lemma":"nébulosité","pos":"NOM"} ,
		{"word":"nécessaire","word_nosc":"necessaire","lemma":"nécessaire","pos":"NOM"} ,
		{"word":"nécessaires","word_nosc":"necessaires","lemma":"nécessaire","pos":"NOM"} ,
		{"word":"nécessiteux","word_nosc":"necessiteux","lemma":"nécessiteux","pos":"NOM"} ,
		{"word":"nécessité","word_nosc":"necessite","lemma":"nécessité","pos":"NOM"} ,
		{"word":"nécessités","word_nosc":"necessites","lemma":"nécessité","pos":"NOM"} ,
		{"word":"nécro","word_nosc":"necro","lemma":"nécro","pos":"NOM"} ,
		{"word":"nécrobioses","word_nosc":"necrobioses","lemma":"nécrobiose","pos":"NOM"} ,
		{"word":"nécrologe","word_nosc":"necrologe","lemma":"nécrologe","pos":"NOM"} ,
		{"word":"nécrologie","word_nosc":"necrologie","lemma":"nécrologie","pos":"NOM"} ,
		{"word":"nécrologies","word_nosc":"necrologies","lemma":"nécrologie","pos":"NOM"} ,
		{"word":"nécrologue","word_nosc":"necrologue","lemma":"nécrologue","pos":"NOM"} ,
		{"word":"nécromancie","word_nosc":"necromancie","lemma":"nécromancie","pos":"NOM"} ,
		{"word":"nécromancien","word_nosc":"necromancien","lemma":"nécromancien","pos":"NOM"} ,
		{"word":"nécromancienne","word_nosc":"necromancienne","lemma":"nécromancien","pos":"NOM"} ,
		{"word":"nécromanciens","word_nosc":"necromanciens","lemma":"nécromancien","pos":"NOM"} ,
		{"word":"nécromant","word_nosc":"necromant","lemma":"nécromant","pos":"NOM"} ,
		{"word":"nécrophage","word_nosc":"necrophage","lemma":"nécrophage","pos":"NOM"} ,
		{"word":"nécrophagie","word_nosc":"necrophagie","lemma":"nécrophagie","pos":"NOM"} ,
		{"word":"nécrophile","word_nosc":"necrophile","lemma":"nécrophile","pos":"NOM"} ,
		{"word":"nécrophiles","word_nosc":"necrophiles","lemma":"nécrophile","pos":"NOM"} ,
		{"word":"nécrophilie","word_nosc":"necrophilie","lemma":"nécrophilie","pos":"NOM"} ,
		{"word":"nécropole","word_nosc":"necropole","lemma":"nécropole","pos":"NOM"} ,
		{"word":"nécropoles","word_nosc":"necropoles","lemma":"nécropole","pos":"NOM"} ,
		{"word":"nécropsie","word_nosc":"necropsie","lemma":"nécropsie","pos":"NOM"} ,
		{"word":"nécros","word_nosc":"necros","lemma":"nécro","pos":"NOM"} ,
		{"word":"nécrose","word_nosc":"necrose","lemma":"nécrose","pos":"NOM"} ,
		{"word":"nécroses","word_nosc":"necroses","lemma":"nécrose","pos":"NOM"} ,
		{"word":"néerlandais","word_nosc":"neerlandais","lemma":"néerlandais","pos":"NOM"} ,
		{"word":"néerlandaise","word_nosc":"neerlandaise","lemma":"néerlandais","pos":"NOM"} ,
		{"word":"néflier","word_nosc":"neflier","lemma":"néflier","pos":"NOM"} ,
		{"word":"néfliers","word_nosc":"nefliers","lemma":"néflier","pos":"NOM"} ,
		{"word":"négatif","word_nosc":"negatif","lemma":"négatif","pos":"NOM"} ,
		{"word":"négatifs","word_nosc":"negatifs","lemma":"négatif","pos":"NOM"} ,
		{"word":"négation","word_nosc":"negation","lemma":"négation","pos":"NOM"} ,
		{"word":"négationnisme","word_nosc":"negationnisme","lemma":"négationnisme","pos":"NOM"} ,
		{"word":"négations","word_nosc":"negations","lemma":"négation","pos":"NOM"} ,
		{"word":"négative","word_nosc":"negative","lemma":"négative","pos":"NOM"} ,
		{"word":"négatives","word_nosc":"negatives","lemma":"négative","pos":"NOM"} ,
		{"word":"négativisme","word_nosc":"negativisme","lemma":"négativisme","pos":"NOM"} ,
		{"word":"négativiste","word_nosc":"negativiste","lemma":"négativiste","pos":"NOM"} ,
		{"word":"négativité","word_nosc":"negativite","lemma":"négativité","pos":"NOM"} ,
		{"word":"négligence","word_nosc":"negligence","lemma":"négligence","pos":"NOM"} ,
		{"word":"négligences","word_nosc":"negligences","lemma":"négligence","pos":"NOM"} ,
		{"word":"négligent","word_nosc":"negligent","lemma":"négligent","pos":"NOM"} ,
		{"word":"négligents","word_nosc":"negligents","lemma":"négligent","pos":"NOM"} ,
		{"word":"négligé","word_nosc":"neglige","lemma":"négligé","pos":"NOM"} ,
		{"word":"négligés","word_nosc":"negliges","lemma":"négligé","pos":"NOM"} ,
		{"word":"négoce","word_nosc":"negoce","lemma":"négoce","pos":"NOM"} ,
		{"word":"négoces","word_nosc":"negoces","lemma":"négoce","pos":"NOM"} ,
		{"word":"négociant","word_nosc":"negociant","lemma":"négociant","pos":"NOM"} ,
		{"word":"négociante","word_nosc":"negociante","lemma":"négociant","pos":"NOM"} ,
		{"word":"négociants","word_nosc":"negociants","lemma":"négociant","pos":"NOM"} ,
		{"word":"négociateur","word_nosc":"negociateur","lemma":"négociateur","pos":"NOM"} ,
		{"word":"négociateurs","word_nosc":"negociateurs","lemma":"négociateur","pos":"NOM"} ,
		{"word":"négociation","word_nosc":"negociation","lemma":"négociation","pos":"NOM"} ,
		{"word":"négociations","word_nosc":"negociations","lemma":"négociation","pos":"NOM"} ,
		{"word":"négociatrice","word_nosc":"negociatrice","lemma":"négociateur","pos":"NOM"} ,
		{"word":"négociatrices","word_nosc":"negociatrices","lemma":"négociateur","pos":"NOM"} ,
		{"word":"négresse","word_nosc":"negresse","lemma":"nègre","pos":"NOM"} ,
		{"word":"négresses","word_nosc":"negresses","lemma":"nègre","pos":"NOM"} ,
		{"word":"négrier","word_nosc":"negrier","lemma":"négrier","pos":"NOM"} ,
		{"word":"négriers","word_nosc":"negriers","lemma":"négrier","pos":"NOM"} ,
		{"word":"négril","word_nosc":"negril","lemma":"négril","pos":"NOM"} ,
		{"word":"négrillon","word_nosc":"negrillon","lemma":"négrillon","pos":"NOM"} ,
		{"word":"négrillons","word_nosc":"negrillons","lemma":"négrillon","pos":"NOM"} ,
		{"word":"négritude","word_nosc":"negritude","lemma":"négritude","pos":"NOM"} ,
		{"word":"négrière","word_nosc":"negriere","lemma":"négrier","pos":"NOM"} ,
		{"word":"négro","word_nosc":"negro","lemma":"négro","pos":"NOM"} ,
		{"word":"négros","word_nosc":"negros","lemma":"négro","pos":"NOM"} ,
		{"word":"négus","word_nosc":"negus","lemma":"négus","pos":"NOM"} ,
		{"word":"nématodes","word_nosc":"nematodes","lemma":"nématode","pos":"NOM"} ,
		{"word":"nénesse","word_nosc":"nenesse","lemma":"nénesse","pos":"NOM"} ,
		{"word":"nénesses","word_nosc":"nenesses","lemma":"nénesse","pos":"NOM"} ,
		{"word":"nénette","word_nosc":"nenette","lemma":"nénette","pos":"NOM"} ,
		{"word":"nénettes","word_nosc":"nenettes","lemma":"nénette","pos":"NOM"} ,
		{"word":"nénuphar","word_nosc":"nenuphar","lemma":"nénuphar","pos":"NOM"} ,
		{"word":"nénuphars","word_nosc":"nenuphars","lemma":"nénuphar","pos":"NOM"} ,
		{"word":"néné","word_nosc":"nene","lemma":"néné","pos":"NOM"} ,
		{"word":"nénés","word_nosc":"nenes","lemma":"néné","pos":"NOM"} ,
		{"word":"néo-colonialisme","word_nosc":"neo-colonialisme","lemma":"néo-colonialisme","pos":"NOM"} ,
		{"word":"néo-fascisme","word_nosc":"neo-fascisme","lemma":"néo-fascisme","pos":"NOM"} ,
		{"word":"néo-fascistes","word_nosc":"neo-fascistes","lemma":"néo-fasciste","pos":"NOM"} ,
		{"word":"néo-figuration","word_nosc":"neo-figuration","lemma":"néo-figuration","pos":"NOM"} ,
		{"word":"néo-flics","word_nosc":"neo-flics","lemma":"néo-flic","pos":"NOM"} ,
		{"word":"néo-gangster","word_nosc":"neo-gangster","lemma":"néo-gangster","pos":"NOM"} ,
		{"word":"néo-gothique","word_nosc":"neo-gothique","lemma":"néo-gothique","pos":"NOM"} ,
		{"word":"néo-malthusianisme","word_nosc":"neo-malthusianisme","lemma":"néo-malthusianisme","pos":"NOM"} ,
		{"word":"néo-mouvement","word_nosc":"neo-mouvement","lemma":"néo-mouvement","pos":"NOM"} ,
		{"word":"néo-réalisme","word_nosc":"neo-realisme","lemma":"néo-réalisme","pos":"NOM"} ,
		{"word":"néo-zélandais","word_nosc":"neo-zelandais","lemma":"néo-zélandais","pos":"NOM"} ,
		{"word":"néocolonialisme","word_nosc":"neocolonialisme","lemma":"néocolonialisme","pos":"NOM"} ,
		{"word":"néocortex","word_nosc":"neocortex","lemma":"néocortex","pos":"NOM"} ,
		{"word":"néofascisme","word_nosc":"neofascisme","lemma":"néofascisme","pos":"NOM"} ,
		{"word":"néolibéralisme","word_nosc":"neoliberalisme","lemma":"néolibéralisme","pos":"NOM"} ,
		{"word":"néolithique","word_nosc":"neolithique","lemma":"néolithique","pos":"NOM"} ,
		{"word":"néologisme","word_nosc":"neologisme","lemma":"néologisme","pos":"NOM"} ,
		{"word":"néologismes","word_nosc":"neologismes","lemma":"néologisme","pos":"NOM"} ,
		{"word":"néon","word_nosc":"neon","lemma":"néon","pos":"NOM"} ,
		{"word":"néonazis","word_nosc":"neonazis","lemma":"néonazi","pos":"NOM"} ,
		{"word":"néons","word_nosc":"neons","lemma":"néon","pos":"NOM"} ,
		{"word":"néophyte","word_nosc":"neophyte","lemma":"néophyte","pos":"NOM"} ,
		{"word":"néophytes","word_nosc":"neophytes","lemma":"néophyte","pos":"NOM"} ,
		{"word":"néoplasie","word_nosc":"neoplasie","lemma":"néoplasie","pos":"NOM"} ,
		{"word":"néoplasme","word_nosc":"neoplasme","lemma":"néoplasme","pos":"NOM"} ,
		{"word":"néoplatonisme","word_nosc":"neoplatonisme","lemma":"néoplatonisme","pos":"NOM"} ,
		{"word":"néoprène","word_nosc":"neoprene","lemma":"néoprène","pos":"NOM"} ,
		{"word":"néoréalisme","word_nosc":"neorealisme","lemma":"néoréalisme","pos":"NOM"} ,
		{"word":"népalais","word_nosc":"nepalais","lemma":"népalais","pos":"NOM"} ,
		{"word":"népalaise","word_nosc":"nepalaise","lemma":"népalais","pos":"NOM"} ,
		{"word":"népenthès","word_nosc":"nepenthes","lemma":"népenthès","pos":"NOM"} ,
		{"word":"néphrectomie","word_nosc":"nephrectomie","lemma":"néphrectomie","pos":"NOM"} ,
		{"word":"néphrite","word_nosc":"nephrite","lemma":"néphrite","pos":"NOM"} ,
		{"word":"néphrologie","word_nosc":"nephrologie","lemma":"néphrologie","pos":"NOM"} ,
		{"word":"néphrologue","word_nosc":"nephrologue","lemma":"néphrologue","pos":"NOM"} ,
		{"word":"népotisme","word_nosc":"nepotisme","lemma":"népotisme","pos":"NOM"} ,
		{"word":"néroli","word_nosc":"neroli","lemma":"néroli","pos":"NOM"} ,
		{"word":"néréide","word_nosc":"nereide","lemma":"néréide","pos":"NOM"} ,
		{"word":"néréides","word_nosc":"nereides","lemma":"néréide","pos":"NOM"} ,
		{"word":"névralgie","word_nosc":"nevralgie","lemma":"névralgie","pos":"NOM"} ,
		{"word":"névralgies","word_nosc":"nevralgies","lemma":"névralgie","pos":"NOM"} ,
		{"word":"névrite","word_nosc":"nevrite","lemma":"névrite","pos":"NOM"} ,
		{"word":"névropathe","word_nosc":"nevropathe","lemma":"névropathe","pos":"NOM"} ,
		{"word":"névroptères","word_nosc":"nevropteres","lemma":"névroptère","pos":"NOM"} ,
		{"word":"névrose","word_nosc":"nevrose","lemma":"névrose","pos":"NOM"} ,
		{"word":"névroses","word_nosc":"nevroses","lemma":"névrose","pos":"NOM"} ,
		{"word":"névrosé","word_nosc":"nevrose","lemma":"névrosé","pos":"NOM"} ,
		{"word":"névrosés","word_nosc":"nevroses","lemma":"névrosé","pos":"NOM"} ,
		{"word":"névé","word_nosc":"neve","lemma":"névé","pos":"NOM"} ,
		{"word":"névés","word_nosc":"neves","lemma":"névé","pos":"NOM"} ,
		{"word":"o","word_nosc":"o","lemma":"o","pos":"NOM"} ,
		{"word":"oaristys","word_nosc":"oaristys","lemma":"oaristys","pos":"NOM"} ,
		{"word":"oasis","word_nosc":"oasis","lemma":"oasis","pos":"NOM"} ,
		{"word":"ob","word_nosc":"ob","lemma":"ob","pos":"NOM"} ,
		{"word":"obi","word_nosc":"obi","lemma":"obi","pos":"NOM"} ,
		{"word":"objecteur","word_nosc":"objecteur","lemma":"objecteur","pos":"NOM"} ,
		{"word":"objecteurs","word_nosc":"objecteurs","lemma":"objecteur","pos":"NOM"} ,
		{"word":"objectif","word_nosc":"objectif","lemma":"objectif","pos":"NOM"} ,
		{"word":"objectifs","word_nosc":"objectifs","lemma":"objectif","pos":"NOM"} ,
		{"word":"objection","word_nosc":"objection","lemma":"objection","pos":"NOM"} ,
		{"word":"objections","word_nosc":"objections","lemma":"objection","pos":"NOM"} ,
		{"word":"objectivation","word_nosc":"objectivation","lemma":"objectivation","pos":"NOM"} ,
		{"word":"objectivité","word_nosc":"objectivite","lemma":"objectivité","pos":"NOM"} ,
		{"word":"objet","word_nosc":"objet","lemma":"objet","pos":"NOM"} ,
		{"word":"objets","word_nosc":"objets","lemma":"objet","pos":"NOM"} ,
		{"word":"objurgation","word_nosc":"objurgation","lemma":"objurgation","pos":"NOM"} ,
		{"word":"objurgations","word_nosc":"objurgations","lemma":"objurgation","pos":"NOM"} ,
		{"word":"oblation","word_nosc":"oblation","lemma":"oblation","pos":"NOM"} ,
		{"word":"oblats","word_nosc":"oblats","lemma":"oblat","pos":"NOM"} ,
		{"word":"obligation","word_nosc":"obligation","lemma":"obligation","pos":"NOM"} ,
		{"word":"obligations","word_nosc":"obligations","lemma":"obligation","pos":"NOM"} ,
		{"word":"obligeance","word_nosc":"obligeance","lemma":"obligeance","pos":"NOM"} ,
		{"word":"obligeances","word_nosc":"obligeances","lemma":"obligeance","pos":"NOM"} ,
		{"word":"obligé","word_nosc":"oblige","lemma":"obligé","pos":"NOM"} ,
		{"word":"obligée","word_nosc":"obligee","lemma":"obligé","pos":"NOM"} ,
		{"word":"obligées","word_nosc":"obligees","lemma":"obligé","pos":"NOM"} ,
		{"word":"obligés","word_nosc":"obliges","lemma":"obligé","pos":"NOM"} ,
		{"word":"oblique","word_nosc":"oblique","lemma":"oblique","pos":"NOM"} ,
		{"word":"obliques","word_nosc":"obliques","lemma":"oblique","pos":"NOM"} ,
		{"word":"obliquité","word_nosc":"obliquite","lemma":"obliquité","pos":"NOM"} ,
		{"word":"oblitération","word_nosc":"obliteration","lemma":"oblitération","pos":"NOM"} ,
		{"word":"obnubilation","word_nosc":"obnubilation","lemma":"obnubilation","pos":"NOM"} ,
		{"word":"obole","word_nosc":"obole","lemma":"obole","pos":"NOM"} ,
		{"word":"oboles","word_nosc":"oboles","lemma":"obole","pos":"NOM"} ,
		{"word":"obscurantisme","word_nosc":"obscurantisme","lemma":"obscurantisme","pos":"NOM"} ,
		{"word":"obscurantismes","word_nosc":"obscurantismes","lemma":"obscurantisme","pos":"NOM"} ,
		{"word":"obscurantiste","word_nosc":"obscurantiste","lemma":"obscurantiste","pos":"NOM"} ,
		{"word":"obscurcissement","word_nosc":"obscurcissement","lemma":"obscurcissement","pos":"NOM"} ,
		{"word":"obscurcissements","word_nosc":"obscurcissements","lemma":"obscurcissement","pos":"NOM"} ,
		{"word":"obscurité","word_nosc":"obscurite","lemma":"obscurité","pos":"NOM"} ,
		{"word":"obscurités","word_nosc":"obscurites","lemma":"obscurité","pos":"NOM"} ,
		{"word":"obscénité","word_nosc":"obscenite","lemma":"obscénité","pos":"NOM"} ,
		{"word":"obscénités","word_nosc":"obscenites","lemma":"obscénité","pos":"NOM"} ,
		{"word":"observance","word_nosc":"observance","lemma":"observance","pos":"NOM"} ,
		{"word":"observances","word_nosc":"observances","lemma":"observance","pos":"NOM"} ,
		{"word":"observant","word_nosc":"observant","lemma":"observant","pos":"NOM"} ,
		{"word":"observateur","word_nosc":"observateur","lemma":"observateur","pos":"NOM"} ,
		{"word":"observateurs","word_nosc":"observateurs","lemma":"observateur","pos":"NOM"} ,
		{"word":"observation","word_nosc":"observation","lemma":"observation","pos":"NOM"} ,
		{"word":"observations","word_nosc":"observations","lemma":"observation","pos":"NOM"} ,
		{"word":"observatoire","word_nosc":"observatoire","lemma":"observatoire","pos":"NOM"} ,
		{"word":"observatoires","word_nosc":"observatoires","lemma":"observatoire","pos":"NOM"} ,
		{"word":"observatrice","word_nosc":"observatrice","lemma":"observateur","pos":"NOM"} ,
		{"word":"observatrices","word_nosc":"observatrices","lemma":"observateur","pos":"NOM"} ,
		{"word":"obsession","word_nosc":"obsession","lemma":"obsession","pos":"NOM"} ,
		{"word":"obsessions","word_nosc":"obsessions","lemma":"obsession","pos":"NOM"} ,
		{"word":"obsidienne","word_nosc":"obsidienne","lemma":"obsidienne","pos":"NOM"} ,
		{"word":"obsolescence","word_nosc":"obsolescence","lemma":"obsolescence","pos":"NOM"} ,
		{"word":"obstacle","word_nosc":"obstacle","lemma":"obstacle","pos":"NOM"} ,
		{"word":"obstacles","word_nosc":"obstacles","lemma":"obstacle","pos":"NOM"} ,
		{"word":"obstination","word_nosc":"obstination","lemma":"obstination","pos":"NOM"} ,
		{"word":"obstinations","word_nosc":"obstinations","lemma":"obstination","pos":"NOM"} ,
		{"word":"obstiné","word_nosc":"obstine","lemma":"obstiné","pos":"NOM"} ,
		{"word":"obstinée","word_nosc":"obstinee","lemma":"obstiné","pos":"NOM"} ,
		{"word":"obstinées","word_nosc":"obstinees","lemma":"obstiné","pos":"NOM"} ,
		{"word":"obstinés","word_nosc":"obstines","lemma":"obstiné","pos":"NOM"} ,
		{"word":"obstruction","word_nosc":"obstruction","lemma":"obstruction","pos":"NOM"} ,
		{"word":"obstructionnisme","word_nosc":"obstructionnisme","lemma":"obstructionnisme","pos":"NOM"} ,
		{"word":"obstructions","word_nosc":"obstructions","lemma":"obstruction","pos":"NOM"} ,
		{"word":"obstétricien","word_nosc":"obstetricien","lemma":"obstétricien","pos":"NOM"} ,
		{"word":"obstétricienne","word_nosc":"obstetricienne","lemma":"obstétricien","pos":"NOM"} ,
		{"word":"obstétriciens","word_nosc":"obstetriciens","lemma":"obstétricien","pos":"NOM"} ,
		{"word":"obstétrique","word_nosc":"obstetrique","lemma":"obstétrique","pos":"NOM"} ,
		{"word":"obsèques","word_nosc":"obseques","lemma":"obsèques","pos":"NOM"} ,
		{"word":"obsédé","word_nosc":"obsede","lemma":"obsédé","pos":"NOM"} ,
		{"word":"obsédés","word_nosc":"obsedes","lemma":"obsédé","pos":"NOM"} ,
		{"word":"obséquiosité","word_nosc":"obsequiosite","lemma":"obséquiosité","pos":"NOM"} ,
		{"word":"obtention","word_nosc":"obtention","lemma":"obtention","pos":"NOM"} ,
		{"word":"obturateur","word_nosc":"obturateur","lemma":"obturateur","pos":"NOM"} ,
		{"word":"obturateurs","word_nosc":"obturateurs","lemma":"obturateur","pos":"NOM"} ,
		{"word":"obturation","word_nosc":"obturation","lemma":"obturation","pos":"NOM"} ,
		{"word":"obus","word_nosc":"obus","lemma":"obus","pos":"NOM"} ,
		{"word":"obusier","word_nosc":"obusier","lemma":"obusier","pos":"NOM"} ,
		{"word":"obusiers","word_nosc":"obusiers","lemma":"obusier","pos":"NOM"} ,
		{"word":"obverse","word_nosc":"obverse","lemma":"obvers","pos":"NOM"} ,
		{"word":"obèse","word_nosc":"obese","lemma":"obèse","pos":"NOM"} ,
		{"word":"obèses","word_nosc":"obeses","lemma":"obèse","pos":"NOM"} ,
		{"word":"obédience","word_nosc":"obedience","lemma":"obédience","pos":"NOM"} ,
		{"word":"obédiences","word_nosc":"obediences","lemma":"obédience","pos":"NOM"} ,
		{"word":"obéissance","word_nosc":"obeissance","lemma":"obéissance","pos":"NOM"} ,
		{"word":"obélisque","word_nosc":"obelisque","lemma":"obélisque","pos":"NOM"} ,
		{"word":"obélisques","word_nosc":"obelisques","lemma":"obélisque","pos":"NOM"} ,
		{"word":"obésité","word_nosc":"obesite","lemma":"obésité","pos":"NOM"} ,
		{"word":"obésités","word_nosc":"obesites","lemma":"obésité","pos":"NOM"} ,
		{"word":"ocarina","word_nosc":"ocarina","lemma":"ocarina","pos":"NOM"} ,
		{"word":"ocarinas","word_nosc":"ocarinas","lemma":"ocarina","pos":"NOM"} ,
		{"word":"occase","word_nosc":"occase","lemma":"occase","pos":"NOM"} ,
		{"word":"occases","word_nosc":"occases","lemma":"occase","pos":"NOM"} ,
		{"word":"occasion","word_nosc":"occasion","lemma":"occasion","pos":"NOM"} ,
		{"word":"occasions","word_nosc":"occasions","lemma":"occasion","pos":"NOM"} ,
		{"word":"occident","word_nosc":"occident","lemma":"occident","pos":"NOM"} ,
		{"word":"occidental","word_nosc":"occidental","lemma":"occidental","pos":"NOM"} ,
		{"word":"occidentale","word_nosc":"occidentale","lemma":"occidental","pos":"NOM"} ,
		{"word":"occidentales","word_nosc":"occidentales","lemma":"occidental","pos":"NOM"} ,
		{"word":"occidentalisation","word_nosc":"occidentalisation","lemma":"occidentalisation","pos":"NOM"} ,
		{"word":"occidentalisme","word_nosc":"occidentalisme","lemma":"occidentalisme","pos":"NOM"} ,
		{"word":"occidentaux","word_nosc":"occidentaux","lemma":"occidental","pos":"NOM"} ,
		{"word":"occiput","word_nosc":"occiput","lemma":"occiput","pos":"NOM"} ,
		{"word":"occitan","word_nosc":"occitan","lemma":"occitan","pos":"NOM"} ,
		{"word":"occlusion","word_nosc":"occlusion","lemma":"occlusion","pos":"NOM"} ,
		{"word":"occultation","word_nosc":"occultation","lemma":"occultation","pos":"NOM"} ,
		{"word":"occultations","word_nosc":"occultations","lemma":"occultation","pos":"NOM"} ,
		{"word":"occultisme","word_nosc":"occultisme","lemma":"occultisme","pos":"NOM"} ,
		{"word":"occultismes","word_nosc":"occultismes","lemma":"occultisme","pos":"NOM"} ,
		{"word":"occultiste","word_nosc":"occultiste","lemma":"occultiste","pos":"NOM"} ,
		{"word":"occultistes","word_nosc":"occultistes","lemma":"occultiste","pos":"NOM"} ,
		{"word":"occupant","word_nosc":"occupant","lemma":"occupant","pos":"NOM"} ,
		{"word":"occupante","word_nosc":"occupante","lemma":"occupant","pos":"NOM"} ,
		{"word":"occupantes","word_nosc":"occupantes","lemma":"occupant","pos":"NOM"} ,
		{"word":"occupants","word_nosc":"occupants","lemma":"occupant","pos":"NOM"} ,
		{"word":"occupation","word_nosc":"occupation","lemma":"occupation","pos":"NOM"} ,
		{"word":"occupations","word_nosc":"occupations","lemma":"occupation","pos":"NOM"} ,
		{"word":"occurrence","word_nosc":"occurrence","lemma":"occurrence","pos":"NOM"} ,
		{"word":"occurrences","word_nosc":"occurrences","lemma":"occurrence","pos":"NOM"} ,
		{"word":"ocelles","word_nosc":"ocelles","lemma":"ocelle","pos":"NOM"} ,
		{"word":"ocelot","word_nosc":"ocelot","lemma":"ocelot","pos":"NOM"} ,
		{"word":"ocelots","word_nosc":"ocelots","lemma":"ocelot","pos":"NOM"} ,
		{"word":"ocre","word_nosc":"ocre","lemma":"ocre","pos":"NOM"} ,
		{"word":"ocres","word_nosc":"ocres","lemma":"ocre","pos":"NOM"} ,
		{"word":"octane","word_nosc":"octane","lemma":"octane","pos":"NOM"} ,
		{"word":"octant","word_nosc":"octant","lemma":"octant","pos":"NOM"} ,
		{"word":"octave","word_nosc":"octave","lemma":"octave","pos":"NOM"} ,
		{"word":"octaves","word_nosc":"octaves","lemma":"octave","pos":"NOM"} ,
		{"word":"octet","word_nosc":"octet","lemma":"octet","pos":"NOM"} ,
		{"word":"octets","word_nosc":"octets","lemma":"octet","pos":"NOM"} ,
		{"word":"octidi","word_nosc":"octidi","lemma":"octidi","pos":"NOM"} ,
		{"word":"octobre","word_nosc":"octobre","lemma":"octobre","pos":"NOM"} ,
		{"word":"octogone","word_nosc":"octogone","lemma":"octogone","pos":"NOM"} ,
		{"word":"octogones","word_nosc":"octogones","lemma":"octogone","pos":"NOM"} ,
		{"word":"octogénaire","word_nosc":"octogenaire","lemma":"octogénaire","pos":"NOM"} ,
		{"word":"octogénaires","word_nosc":"octogenaires","lemma":"octogénaire","pos":"NOM"} ,
		{"word":"octosyllabe","word_nosc":"octosyllabe","lemma":"octosyllabe","pos":"NOM"} ,
		{"word":"octosyllabes","word_nosc":"octosyllabes","lemma":"octosyllabe","pos":"NOM"} ,
		{"word":"octroi","word_nosc":"octroi","lemma":"octroi","pos":"NOM"} ,
		{"word":"octrois","word_nosc":"octrois","lemma":"octroi","pos":"NOM"} ,
		{"word":"octuor","word_nosc":"octuor","lemma":"octuor","pos":"NOM"} ,
		{"word":"oculaire","word_nosc":"oculaire","lemma":"oculaire","pos":"NOM"} ,
		{"word":"oculaires","word_nosc":"oculaires","lemma":"oculaire","pos":"NOM"} ,
		{"word":"oculi","word_nosc":"oculi","lemma":"oculus","pos":"NOM"} ,
		{"word":"oculiste","word_nosc":"oculiste","lemma":"oculiste","pos":"NOM"} ,
		{"word":"oculistes","word_nosc":"oculistes","lemma":"oculiste","pos":"NOM"} ,
		{"word":"oculus","word_nosc":"oculus","lemma":"oculus","pos":"NOM"} ,
		{"word":"ocytocine","word_nosc":"ocytocine","lemma":"ocytocine","pos":"NOM"} ,
		{"word":"océan","word_nosc":"ocean","lemma":"océan","pos":"NOM"} ,
		{"word":"océanographe","word_nosc":"oceanographe","lemma":"océanographe","pos":"NOM"} ,
		{"word":"océanographie","word_nosc":"oceanographie","lemma":"océanographie","pos":"NOM"} ,
		{"word":"océanologie","word_nosc":"oceanologie","lemma":"océanologie","pos":"NOM"} ,
		{"word":"océanologue","word_nosc":"oceanologue","lemma":"océanologue","pos":"NOM"} ,
		{"word":"océans","word_nosc":"oceans","lemma":"océan","pos":"NOM"} ,
		{"word":"odalisque","word_nosc":"odalisque","lemma":"odalisque","pos":"NOM"} ,
		{"word":"odalisques","word_nosc":"odalisques","lemma":"odalisque","pos":"NOM"} ,
		{"word":"ode","word_nosc":"ode","lemma":"ode","pos":"NOM"} ,
		{"word":"odelette","word_nosc":"odelette","lemma":"odelette","pos":"NOM"} ,
		{"word":"odelettes","word_nosc":"odelettes","lemma":"odelette","pos":"NOM"} ,
		{"word":"odes","word_nosc":"odes","lemma":"ode","pos":"NOM"} ,
		{"word":"odeur","word_nosc":"odeur","lemma":"odeur","pos":"NOM"} ,
		{"word":"odeurs","word_nosc":"odeurs","lemma":"odeur","pos":"NOM"} ,
		{"word":"odomètre","word_nosc":"odometre","lemma":"odomètre","pos":"NOM"} ,
		{"word":"odontologie","word_nosc":"odontologie","lemma":"odontologie","pos":"NOM"} ,
		{"word":"odontologiste","word_nosc":"odontologiste","lemma":"odontologiste","pos":"NOM"} ,
		{"word":"odontologues","word_nosc":"odontologues","lemma":"odontologue","pos":"NOM"} ,
		{"word":"odorat","word_nosc":"odorat","lemma":"odorat","pos":"NOM"} ,
		{"word":"odyssée","word_nosc":"odyssee","lemma":"odyssée","pos":"NOM"} ,
		{"word":"odyssées","word_nosc":"odyssees","lemma":"odyssée","pos":"NOM"} ,
		{"word":"odéon","word_nosc":"odeon","lemma":"odéon","pos":"NOM"} ,
		{"word":"oecuménisme","word_nosc":"oecumenisme","lemma":"oecuménisme","pos":"NOM"} ,
		{"word":"oedipe","word_nosc":"oedipe","lemma":"oedipe","pos":"NOM"} ,
		{"word":"oedème","word_nosc":"oedeme","lemma":"oedème","pos":"NOM"} ,
		{"word":"oedèmes","word_nosc":"oedemes","lemma":"oedème","pos":"NOM"} ,
		{"word":"oeil","word_nosc":"oeil","lemma":"oeil","pos":"NOM"} ,
		{"word":"oeil-de-boeuf","word_nosc":"oeil-de-boeuf","lemma":"oeil-de-boeuf","pos":"NOM"} ,
		{"word":"oeillade","word_nosc":"oeillade","lemma":"oeillade","pos":"NOM"} ,
		{"word":"oeillades","word_nosc":"oeillades","lemma":"oeillade","pos":"NOM"} ,
		{"word":"oeillet","word_nosc":"oeillet","lemma":"oeillet","pos":"NOM"} ,
		{"word":"oeilleton","word_nosc":"oeilleton","lemma":"oeilleton","pos":"NOM"} ,
		{"word":"oeilletons","word_nosc":"oeilletons","lemma":"oeilleton","pos":"NOM"} ,
		{"word":"oeillets","word_nosc":"oeillets","lemma":"oeillet","pos":"NOM"} ,
		{"word":"oeillette","word_nosc":"oeillette","lemma":"oeillette","pos":"NOM"} ,
		{"word":"oeillère","word_nosc":"oeillere","lemma":"oeillère","pos":"NOM"} ,
		{"word":"oeillères","word_nosc":"oeilleres","lemma":"oeillère","pos":"NOM"} ,
		{"word":"oeils","word_nosc":"oeils","lemma":"oeil","pos":"NOM"} ,
		{"word":"oeils-de-boeuf","word_nosc":"oeils-de-boeuf","lemma":"oeil-de-boeuf","pos":"NOM"} ,
		{"word":"oeils-de-perdrix","word_nosc":"oeils-de-perdrix","lemma":"oeil-de-perdrix","pos":"NOM"} ,
		{"word":"oenologie","word_nosc":"oenologie","lemma":"oenologie","pos":"NOM"} ,
		{"word":"oenologues","word_nosc":"oenologues","lemma":"oenologue","pos":"NOM"} ,
		{"word":"oenothera","word_nosc":"oenothera","lemma":"oenothera","pos":"NOM"} ,
		{"word":"oenothère","word_nosc":"oenothere","lemma":"oenothère","pos":"NOM"} ,
		{"word":"oesophage","word_nosc":"oesophage","lemma":"oesophage","pos":"NOM"} ,
		{"word":"oesophages","word_nosc":"oesophages","lemma":"oesophage","pos":"NOM"} ,
		{"word":"oestrogène","word_nosc":"oestrogene","lemma":"oestrogène","pos":"NOM"} ,
		{"word":"oestrogènes","word_nosc":"oestrogenes","lemma":"oestrogène","pos":"NOM"} ,
		{"word":"oeuf","word_nosc":"oeuf","lemma":"oeuf","pos":"NOM"} ,
		{"word":"oeufs","word_nosc":"oeufs","lemma":"oeuf","pos":"NOM"} ,
		{"word":"oeuvre","word_nosc":"oeuvre","lemma":"oeuvre","pos":"NOM"} ,
		{"word":"oeuvres","word_nosc":"oeuvres","lemma":"oeuvre","pos":"NOM"} ,
		{"word":"oeuvrette","word_nosc":"oeuvrette","lemma":"oeuvrette","pos":"NOM"} ,
		{"word":"oeuvrettes","word_nosc":"oeuvrettes","lemma":"oeuvrette","pos":"NOM"} ,
		{"word":"off-shore","word_nosc":"off-shore","lemma":"off-shore","pos":"NOM"} ,
		{"word":"offense","word_nosc":"offense","lemma":"offense","pos":"NOM"} ,
		{"word":"offenses","word_nosc":"offenses","lemma":"offense","pos":"NOM"} ,
		{"word":"offenseur","word_nosc":"offenseur","lemma":"offenseur","pos":"NOM"} ,
		{"word":"offenseurs","word_nosc":"offenseurs","lemma":"offenseur","pos":"NOM"} ,
		{"word":"offensive","word_nosc":"offensive","lemma":"offensive","pos":"NOM"} ,
		{"word":"offensives","word_nosc":"offensives","lemma":"offensive","pos":"NOM"} ,
		{"word":"offensé","word_nosc":"offense","lemma":"offensé","pos":"NOM"} ,
		{"word":"offensés","word_nosc":"offenses","lemma":"offensé","pos":"NOM"} ,
		{"word":"offertoire","word_nosc":"offertoire","lemma":"offertoire","pos":"NOM"} ,
		{"word":"office","word_nosc":"office","lemma":"office","pos":"NOM"} ,
		{"word":"offices","word_nosc":"offices","lemma":"office","pos":"NOM"} ,
		{"word":"official","word_nosc":"official","lemma":"official","pos":"NOM"} ,
		{"word":"officialité","word_nosc":"officialite","lemma":"officialité","pos":"NOM"} ,
		{"word":"officiant","word_nosc":"officiant","lemma":"officiant","pos":"NOM"} ,
		{"word":"officiants","word_nosc":"officiants","lemma":"officiant","pos":"NOM"} ,
		{"word":"officiel","word_nosc":"officiel","lemma":"officiel","pos":"NOM"} ,
		{"word":"officielle","word_nosc":"officielle","lemma":"officiel","pos":"NOM"} ,
		{"word":"officielles","word_nosc":"officielles","lemma":"officiel","pos":"NOM"} ,
		{"word":"officiels","word_nosc":"officiels","lemma":"officiel","pos":"NOM"} ,
		{"word":"officier","word_nosc":"officier","lemma":"officier","pos":"NOM"} ,
		{"word":"officiers","word_nosc":"officiers","lemma":"officier","pos":"NOM"} ,
		{"word":"officine","word_nosc":"officine","lemma":"officine","pos":"NOM"} ,
		{"word":"officines","word_nosc":"officines","lemma":"officine","pos":"NOM"} ,
		{"word":"officière","word_nosc":"officiere","lemma":"officier","pos":"NOM"} ,
		{"word":"offrande","word_nosc":"offrande","lemma":"offrande","pos":"NOM"} ,
		{"word":"offrandes","word_nosc":"offrandes","lemma":"offrande","pos":"NOM"} ,
		{"word":"offrant","word_nosc":"offrant","lemma":"offrant","pos":"NOM"} ,
		{"word":"offrants","word_nosc":"offrants","lemma":"offrant","pos":"NOM"} ,
		{"word":"offre","word_nosc":"offre","lemma":"offre","pos":"NOM"} ,
		{"word":"offres","word_nosc":"offres","lemma":"offre","pos":"NOM"} ,
		{"word":"offset","word_nosc":"offset","lemma":"offset","pos":"NOM"} ,
		{"word":"offshore","word_nosc":"offshore","lemma":"offshore","pos":"NOM"} ,
		{"word":"offuscation","word_nosc":"offuscation","lemma":"offuscation","pos":"NOM"} ,
		{"word":"offuscations","word_nosc":"offuscations","lemma":"offuscation","pos":"NOM"} ,
		{"word":"oflag","word_nosc":"oflag","lemma":"oflag","pos":"NOM"} ,
		{"word":"oflags","word_nosc":"oflags","lemma":"oflag","pos":"NOM"} ,
		{"word":"ogive","word_nosc":"ogive","lemma":"ogive","pos":"NOM"} ,
		{"word":"ogives","word_nosc":"ogives","lemma":"ogive","pos":"NOM"} ,
		{"word":"ognons","word_nosc":"ognons","lemma":"ognon","pos":"NOM"} ,
		{"word":"ogre","word_nosc":"ogre","lemma":"ogre","pos":"NOM"} ,
		{"word":"ogres","word_nosc":"ogres","lemma":"ogre","pos":"NOM"} ,
		{"word":"ogresse","word_nosc":"ogresse","lemma":"ogresse","pos":"NOM"} ,
		{"word":"ogresses","word_nosc":"ogresses","lemma":"ogresse","pos":"NOM"} ,
		{"word":"oh","word_nosc":"oh","lemma":"oh","pos":"NOM"} ,
		{"word":"ohms","word_nosc":"ohms","lemma":"ohm","pos":"NOM"} ,
		{"word":"oie","word_nosc":"oie","lemma":"oie","pos":"NOM"} ,
		{"word":"oies","word_nosc":"oies","lemma":"oie","pos":"NOM"} ,
		{"word":"oignon","word_nosc":"oignon","lemma":"oignon","pos":"NOM"} ,
		{"word":"oignons","word_nosc":"oignons","lemma":"oignon","pos":"NOM"} ,
		{"word":"oille","word_nosc":"oille","lemma":"oille","pos":"NOM"} ,
		{"word":"oing","word_nosc":"oing","lemma":"oing","pos":"NOM"} ,
		{"word":"oint","word_nosc":"oint","lemma":"oint","pos":"NOM"} ,
		{"word":"oints","word_nosc":"oints","lemma":"oint","pos":"NOM"} ,
		{"word":"oiseau","word_nosc":"oiseau","lemma":"oiseau","pos":"NOM"} ,
		{"word":"oiseau-clé","word_nosc":"oiseau-cle","lemma":"oiseau-clé","pos":"NOM"} ,
		{"word":"oiseau-lyre","word_nosc":"oiseau-lyre","lemma":"oiseau-lyre","pos":"NOM"} ,
		{"word":"oiseau-mouche","word_nosc":"oiseau-mouche","lemma":"oiseau-mouche","pos":"NOM"} ,
		{"word":"oiseau-vedette","word_nosc":"oiseau-vedette","lemma":"oiseau-vedette","pos":"NOM"} ,
		{"word":"oiseaux","word_nosc":"oiseaux","lemma":"oiseau","pos":"NOM"} ,
		{"word":"oiseaux-mouches","word_nosc":"oiseaux-mouches","lemma":"oiseau-mouche","pos":"NOM"} ,
		{"word":"oiselet","word_nosc":"oiselet","lemma":"oiselet","pos":"NOM"} ,
		{"word":"oiselets","word_nosc":"oiselets","lemma":"oiselet","pos":"NOM"} ,
		{"word":"oiseleur","word_nosc":"oiseleur","lemma":"oiseleur","pos":"NOM"} ,
		{"word":"oiseleurs","word_nosc":"oiseleurs","lemma":"oiseleur","pos":"NOM"} ,
		{"word":"oiselier","word_nosc":"oiselier","lemma":"oiselier","pos":"NOM"} ,
		{"word":"oiseliers","word_nosc":"oiseliers","lemma":"oiselier","pos":"NOM"} ,
		{"word":"oiselière","word_nosc":"oiseliere","lemma":"oiselier","pos":"NOM"} ,
		{"word":"oiselle","word_nosc":"oiselle","lemma":"oiselle","pos":"NOM"} ,
		{"word":"oisellerie","word_nosc":"oisellerie","lemma":"oisellerie","pos":"NOM"} ,
		{"word":"oiselles","word_nosc":"oiselles","lemma":"oiselle","pos":"NOM"} ,
		{"word":"oisif","word_nosc":"oisif","lemma":"oisif","pos":"NOM"} ,
		{"word":"oisifs","word_nosc":"oisifs","lemma":"oisif","pos":"NOM"} ,
		{"word":"oisillon","word_nosc":"oisillon","lemma":"oisillon","pos":"NOM"} ,
		{"word":"oisillons","word_nosc":"oisillons","lemma":"oisillon","pos":"NOM"} ,
		{"word":"oisiveté","word_nosc":"oisivete","lemma":"oisiveté","pos":"NOM"} ,
		{"word":"oison","word_nosc":"oison","lemma":"oison","pos":"NOM"} ,
		{"word":"oisons","word_nosc":"oisons","lemma":"oison","pos":"NOM"} ,
		{"word":"oka","word_nosc":"oka","lemma":"oka","pos":"NOM"} ,
		{"word":"okapi","word_nosc":"okapi","lemma":"okapi","pos":"NOM"} ,
		{"word":"okoumé","word_nosc":"okoume","lemma":"okoumé","pos":"NOM"} ,
		{"word":"ola","word_nosc":"ola","lemma":"ola","pos":"NOM"} ,
		{"word":"olfactomètre","word_nosc":"olfactometre","lemma":"olfactomètre","pos":"NOM"} ,
		{"word":"olibrius","word_nosc":"olibrius","lemma":"olibrius","pos":"NOM"} ,
		{"word":"olifant","word_nosc":"olifant","lemma":"olifant","pos":"NOM"} ,
		{"word":"oligarchie","word_nosc":"oligarchie","lemma":"oligarchie","pos":"NOM"} ,
		{"word":"oligo-éléments","word_nosc":"oligo-elements","lemma":"oligo-élément","pos":"NOM"} ,
		{"word":"olivaies","word_nosc":"olivaies","lemma":"olivaie","pos":"NOM"} ,
		{"word":"olive","word_nosc":"olive","lemma":"olive","pos":"NOM"} ,
		{"word":"oliver","word_nosc":"oliver","lemma":"oliver","pos":"NOM"} ,
		{"word":"oliveraie","word_nosc":"oliveraie","lemma":"oliveraie","pos":"NOM"} ,
		{"word":"oliveraies","word_nosc":"oliveraies","lemma":"oliveraie","pos":"NOM"} ,
		{"word":"olives","word_nosc":"olives","lemma":"olive","pos":"NOM"} ,
		{"word":"olivette","word_nosc":"olivette","lemma":"olivette","pos":"NOM"} ,
		{"word":"olivettes","word_nosc":"olivettes","lemma":"olivette","pos":"NOM"} ,
		{"word":"olivier","word_nosc":"olivier","lemma":"olivier","pos":"NOM"} ,
		{"word":"oliviers","word_nosc":"oliviers","lemma":"olivier","pos":"NOM"} ,
		{"word":"olivine","word_nosc":"olivine","lemma":"olivine","pos":"NOM"} ,
		{"word":"olla podrida","word_nosc":"olla podrida","lemma":"olla-podrida","pos":"NOM"} ,
		{"word":"olmèques","word_nosc":"olmeques","lemma":"olmèque","pos":"NOM"} ,
		{"word":"olympe","word_nosc":"olympe","lemma":"olympe","pos":"NOM"} ,
		{"word":"olympes","word_nosc":"olympes","lemma":"olympe","pos":"NOM"} ,
		{"word":"olympiade","word_nosc":"olympiade","lemma":"olympiade","pos":"NOM"} ,
		{"word":"olympiades","word_nosc":"olympiades","lemma":"olympiade","pos":"NOM"} ,
		{"word":"oléagineux","word_nosc":"oleagineux","lemma":"oléagineux","pos":"NOM"} ,
		{"word":"olécrane","word_nosc":"olecrane","lemma":"olécrane","pos":"NOM"} ,
		{"word":"olécrâne","word_nosc":"olecrane","lemma":"olécrâne","pos":"NOM"} ,
		{"word":"oléfine","word_nosc":"olefine","lemma":"oléfine","pos":"NOM"} ,
		{"word":"oléiculteurs","word_nosc":"oleiculteurs","lemma":"oléiculteur","pos":"NOM"} ,
		{"word":"oléine","word_nosc":"oleine","lemma":"oléine","pos":"NOM"} ,
		{"word":"oléoduc","word_nosc":"oleoduc","lemma":"oléoduc","pos":"NOM"} ,
		{"word":"oléoducs","word_nosc":"oleoducs","lemma":"oléoduc","pos":"NOM"} ,
		{"word":"ombelle","word_nosc":"ombelle","lemma":"ombelle","pos":"NOM"} ,
		{"word":"ombelles","word_nosc":"ombelles","lemma":"ombelle","pos":"NOM"} ,
		{"word":"ombellifères","word_nosc":"ombelliferes","lemma":"ombellifère","pos":"NOM"} ,
		{"word":"ombilic","word_nosc":"ombilic","lemma":"ombilic","pos":"NOM"} ,
		{"word":"omble","word_nosc":"omble","lemma":"omble","pos":"NOM"} ,
		{"word":"ombles","word_nosc":"ombles","lemma":"omble","pos":"NOM"} ,
		{"word":"ombrage","word_nosc":"ombrage","lemma":"ombrage","pos":"NOM"} ,
		{"word":"ombrages","word_nosc":"ombrages","lemma":"ombrage","pos":"NOM"} ,
		{"word":"ombre","word_nosc":"ombre","lemma":"ombre","pos":"NOM"} ,
		{"word":"ombrelle","word_nosc":"ombrelle","lemma":"ombrelle","pos":"NOM"} ,
		{"word":"ombrelles","word_nosc":"ombrelles","lemma":"ombrelle","pos":"NOM"} ,
		{"word":"ombres","word_nosc":"ombres","lemma":"ombre","pos":"NOM"} ,
		{"word":"ombrée","word_nosc":"ombree","lemma":"ombrée","pos":"NOM"} ,
		{"word":"ombrées","word_nosc":"ombrees","lemma":"ombrée","pos":"NOM"} ,
		{"word":"omelette","word_nosc":"omelette","lemma":"omelette","pos":"NOM"} ,
		{"word":"omelettes","word_nosc":"omelettes","lemma":"omelette","pos":"NOM"} ,
		{"word":"omerta","word_nosc":"omerta","lemma":"omerta","pos":"NOM"} ,
		{"word":"omicron","word_nosc":"omicron","lemma":"omicron","pos":"NOM"} ,
		{"word":"omis","word_nosc":"omis","lemma":"omis","pos":"NOM"} ,
		{"word":"omission","word_nosc":"omission","lemma":"omission","pos":"NOM"} ,
		{"word":"omissions","word_nosc":"omissions","lemma":"omission","pos":"NOM"} ,
		{"word":"omnibus","word_nosc":"omnibus","lemma":"omnibus","pos":"NOM"} ,
		{"word":"omnipotence","word_nosc":"omnipotence","lemma":"omnipotence","pos":"NOM"} ,
		{"word":"omniprésence","word_nosc":"omnipresence","lemma":"omniprésence","pos":"NOM"} ,
		{"word":"omnipuissance","word_nosc":"omnipuissance","lemma":"omnipuissance","pos":"NOM"} ,
		{"word":"omniscience","word_nosc":"omniscience","lemma":"omniscience","pos":"NOM"} ,
		{"word":"omnium","word_nosc":"omnium","lemma":"omnium","pos":"NOM"} ,
		{"word":"omnivore","word_nosc":"omnivore","lemma":"omnivore","pos":"NOM"} ,
		{"word":"omnivores","word_nosc":"omnivores","lemma":"omnivore","pos":"NOM"} ,
		{"word":"omoplate","word_nosc":"omoplate","lemma":"omoplate","pos":"NOM"} ,
		{"word":"omoplates","word_nosc":"omoplates","lemma":"omoplate","pos":"NOM"} ,
		{"word":"omphalos","word_nosc":"omphalos","lemma":"omphalos","pos":"NOM"} ,
		{"word":"oméga","word_nosc":"omega","lemma":"oméga","pos":"NOM"} ,
		{"word":"on","word_nosc":"on","lemma":"on","pos":"NOM"} ,
		{"word":"on-dit","word_nosc":"on-dit","lemma":"on-dit","pos":"NOM"} ,
		{"word":"onagre","word_nosc":"onagre","lemma":"onagre","pos":"NOM"} ,
		{"word":"onanisme","word_nosc":"onanisme","lemma":"onanisme","pos":"NOM"} ,
		{"word":"onanismes","word_nosc":"onanismes","lemma":"onanisme","pos":"NOM"} ,
		{"word":"onaniste","word_nosc":"onaniste","lemma":"onaniste","pos":"NOM"} ,
		{"word":"once","word_nosc":"once","lemma":"once","pos":"NOM"} ,
		{"word":"onces","word_nosc":"onces","lemma":"once","pos":"NOM"} ,
		{"word":"oncle","word_nosc":"oncle","lemma":"oncle","pos":"NOM"} ,
		{"word":"oncles","word_nosc":"oncles","lemma":"oncle","pos":"NOM"} ,
		{"word":"oncologie","word_nosc":"oncologie","lemma":"oncologie","pos":"NOM"} ,
		{"word":"oncologue","word_nosc":"oncologue","lemma":"oncologue","pos":"NOM"} ,
		{"word":"oncologues","word_nosc":"oncologues","lemma":"oncologue","pos":"NOM"} ,
		{"word":"onction","word_nosc":"onction","lemma":"onction","pos":"NOM"} ,
		{"word":"onctions","word_nosc":"onctions","lemma":"onction","pos":"NOM"} ,
		{"word":"onctuosité","word_nosc":"onctuosite","lemma":"onctuosité","pos":"NOM"} ,
		{"word":"ondatra","word_nosc":"ondatra","lemma":"ondatra","pos":"NOM"} ,
		{"word":"onde","word_nosc":"onde","lemma":"onde","pos":"NOM"} ,
		{"word":"ondes","word_nosc":"ondes","lemma":"onde","pos":"NOM"} ,
		{"word":"ondin","word_nosc":"ondin","lemma":"ondin","pos":"NOM"} ,
		{"word":"ondine","word_nosc":"ondine","lemma":"ondine","pos":"NOM"} ,
		{"word":"ondines","word_nosc":"ondines","lemma":"ondine","pos":"NOM"} ,
		{"word":"ondins","word_nosc":"ondins","lemma":"ondin","pos":"NOM"} ,
		{"word":"ondoiement","word_nosc":"ondoiement","lemma":"ondoiement","pos":"NOM"} ,
		{"word":"ondoiements","word_nosc":"ondoiements","lemma":"ondoiement","pos":"NOM"} ,
		{"word":"ondulation","word_nosc":"ondulation","lemma":"ondulation","pos":"NOM"} ,
		{"word":"ondulations","word_nosc":"ondulations","lemma":"ondulation","pos":"NOM"} ,
		{"word":"ondée","word_nosc":"ondee","lemma":"ondée","pos":"NOM"} ,
		{"word":"ondées","word_nosc":"ondees","lemma":"ondée","pos":"NOM"} ,
		{"word":"one-step","word_nosc":"one-step","lemma":"one-step","pos":"NOM"} ,
		{"word":"ongle","word_nosc":"ongle","lemma":"ongle","pos":"NOM"} ,
		{"word":"ongles","word_nosc":"ongles","lemma":"ongle","pos":"NOM"} ,
		{"word":"onglet","word_nosc":"onglet","lemma":"onglet","pos":"NOM"} ,
		{"word":"onglets","word_nosc":"onglets","lemma":"onglet","pos":"NOM"} ,
		{"word":"onglon","word_nosc":"onglon","lemma":"onglon","pos":"NOM"} ,
		{"word":"onglée","word_nosc":"onglee","lemma":"onglée","pos":"NOM"} ,
		{"word":"onguent","word_nosc":"onguent","lemma":"onguent","pos":"NOM"} ,
		{"word":"onguents","word_nosc":"onguents","lemma":"onguent","pos":"NOM"} ,
		{"word":"ongulé","word_nosc":"ongule","lemma":"ongulé","pos":"NOM"} ,
		{"word":"ongulés","word_nosc":"ongules","lemma":"ongulé","pos":"NOM"} ,
		{"word":"onirisme","word_nosc":"onirisme","lemma":"onirisme","pos":"NOM"} ,
		{"word":"onirologie","word_nosc":"onirologie","lemma":"onirologie","pos":"NOM"} ,
		{"word":"onomatopée","word_nosc":"onomatopee","lemma":"onomatopée","pos":"NOM"} ,
		{"word":"onomatopées","word_nosc":"onomatopees","lemma":"onomatopée","pos":"NOM"} ,
		{"word":"onopordons","word_nosc":"onopordons","lemma":"onopordon","pos":"NOM"} ,
		{"word":"ontologie","word_nosc":"ontologie","lemma":"ontologie","pos":"NOM"} ,
		{"word":"onyx","word_nosc":"onyx","lemma":"onyx","pos":"NOM"} ,
		{"word":"onze","word_nosc":"onze","lemma":"onze","pos":"NOM"} ,
		{"word":"onzième","word_nosc":"onzieme","lemma":"onzième","pos":"NOM"} ,
		{"word":"opacification","word_nosc":"opacification","lemma":"opacification","pos":"NOM"} ,
		{"word":"opacité","word_nosc":"opacite","lemma":"opacité","pos":"NOM"} ,
		{"word":"opacités","word_nosc":"opacites","lemma":"opacité","pos":"NOM"} ,
		{"word":"opale","word_nosc":"opale","lemma":"opale","pos":"NOM"} ,
		{"word":"opales","word_nosc":"opales","lemma":"opale","pos":"NOM"} ,
		{"word":"opalescence","word_nosc":"opalescence","lemma":"opalescence","pos":"NOM"} ,
		{"word":"opaline","word_nosc":"opaline","lemma":"opalin","pos":"NOM"} ,
		{"word":"opalines","word_nosc":"opalines","lemma":"opalin","pos":"NOM"} ,
		{"word":"ope","word_nosc":"ope","lemma":"ope","pos":"NOM"} ,
		{"word":"open","word_nosc":"open","lemma":"open","pos":"NOM"} ,
		{"word":"opercule","word_nosc":"opercule","lemma":"opercule","pos":"NOM"} ,
		{"word":"ophidien","word_nosc":"ophidien","lemma":"ophidien","pos":"NOM"} ,
		{"word":"ophidiens","word_nosc":"ophidiens","lemma":"ophidien","pos":"NOM"} ,
		{"word":"ophite","word_nosc":"ophite","lemma":"ophite","pos":"NOM"} ,
		{"word":"ophtalmologie","word_nosc":"ophtalmologie","lemma":"ophtalmologie","pos":"NOM"} ,
		{"word":"ophtalmologiste","word_nosc":"ophtalmologiste","lemma":"ophtalmologiste","pos":"NOM"} ,
		{"word":"ophtalmologistes","word_nosc":"ophtalmologistes","lemma":"ophtalmologiste","pos":"NOM"} ,
		{"word":"ophtalmologue","word_nosc":"ophtalmologue","lemma":"ophtalmologue","pos":"NOM"} ,
		{"word":"ophtalmoscope","word_nosc":"ophtalmoscope","lemma":"ophtalmoscope","pos":"NOM"} ,
		{"word":"opiacé","word_nosc":"opiace","lemma":"opiacé","pos":"NOM"} ,
		{"word":"opiacés","word_nosc":"opiaces","lemma":"opiacé","pos":"NOM"} ,
		{"word":"opiat","word_nosc":"opiat","lemma":"opiat","pos":"NOM"} ,
		{"word":"opiats","word_nosc":"opiats","lemma":"opiat","pos":"NOM"} ,
		{"word":"opinel","word_nosc":"opinel","lemma":"opinel","pos":"NOM"} ,
		{"word":"opinels","word_nosc":"opinels","lemma":"opinel","pos":"NOM"} ,
		{"word":"opinion","word_nosc":"opinion","lemma":"opinion","pos":"NOM"} ,
		{"word":"opinions","word_nosc":"opinions","lemma":"opinion","pos":"NOM"} ,
		{"word":"opiniâtreté","word_nosc":"opiniatrete","lemma":"opiniâtreté","pos":"NOM"} ,
		{"word":"opiomane","word_nosc":"opiomane","lemma":"opiomane","pos":"NOM"} ,
		{"word":"opium","word_nosc":"opium","lemma":"opium","pos":"NOM"} ,
		{"word":"opopanax","word_nosc":"opopanax","lemma":"opopanax","pos":"NOM"} ,
		{"word":"opoponax","word_nosc":"opoponax","lemma":"opoponax","pos":"NOM"} ,
		{"word":"opossum","word_nosc":"opossum","lemma":"opossum","pos":"NOM"} ,
		{"word":"opossums","word_nosc":"opossums","lemma":"opossum","pos":"NOM"} ,
		{"word":"oppidum","word_nosc":"oppidum","lemma":"oppidum","pos":"NOM"} ,
		{"word":"opportunisme","word_nosc":"opportunisme","lemma":"opportunisme","pos":"NOM"} ,
		{"word":"opportuniste","word_nosc":"opportuniste","lemma":"opportuniste","pos":"NOM"} ,
		{"word":"opportunistes","word_nosc":"opportunistes","lemma":"opportuniste","pos":"NOM"} ,
		{"word":"opportunité","word_nosc":"opportunite","lemma":"opportunité","pos":"NOM"} ,
		{"word":"opportunités","word_nosc":"opportunites","lemma":"opportunité","pos":"NOM"} ,
		{"word":"opposant","word_nosc":"opposant","lemma":"opposant","pos":"NOM"} ,
		{"word":"opposante","word_nosc":"opposante","lemma":"opposant","pos":"NOM"} ,
		{"word":"opposants","word_nosc":"opposants","lemma":"opposant","pos":"NOM"} ,
		{"word":"opposition","word_nosc":"opposition","lemma":"opposition","pos":"NOM"} ,
		{"word":"oppositions","word_nosc":"oppositions","lemma":"opposition","pos":"NOM"} ,
		{"word":"opposé","word_nosc":"oppose","lemma":"opposé","pos":"NOM"} ,
		{"word":"opposés","word_nosc":"opposes","lemma":"opposé","pos":"NOM"} ,
		{"word":"oppresseur","word_nosc":"oppresseur","lemma":"oppresseur","pos":"NOM"} ,
		{"word":"oppresseurs","word_nosc":"oppresseurs","lemma":"oppresseur","pos":"NOM"} ,
		{"word":"oppression","word_nosc":"oppression","lemma":"oppression","pos":"NOM"} ,
		{"word":"oppressions","word_nosc":"oppressions","lemma":"oppression","pos":"NOM"} ,
		{"word":"opprimé","word_nosc":"opprime","lemma":"opprimé","pos":"NOM"} ,
		{"word":"opprimée","word_nosc":"opprimee","lemma":"opprimé","pos":"NOM"} ,
		{"word":"opprimées","word_nosc":"opprimees","lemma":"opprimé","pos":"NOM"} ,
		{"word":"opprimés","word_nosc":"opprimes","lemma":"opprimé","pos":"NOM"} ,
		{"word":"opprobre","word_nosc":"opprobre","lemma":"opprobre","pos":"NOM"} ,
		{"word":"opprobres","word_nosc":"opprobres","lemma":"opprobre","pos":"NOM"} ,
		{"word":"optalidon","word_nosc":"optalidon","lemma":"optalidon","pos":"NOM"} ,
		{"word":"optant","word_nosc":"optant","lemma":"optant","pos":"NOM"} ,
		{"word":"opticien","word_nosc":"opticien","lemma":"opticien","pos":"NOM"} ,
		{"word":"opticienne","word_nosc":"opticienne","lemma":"opticien","pos":"NOM"} ,
		{"word":"opticiens","word_nosc":"opticiens","lemma":"opticien","pos":"NOM"} ,
		{"word":"optima","word_nosc":"optima","lemma":"optimum","pos":"NOM"} ,
		{"word":"optimisation","word_nosc":"optimisation","lemma":"optimisation","pos":"NOM"} ,
		{"word":"optimisme","word_nosc":"optimisme","lemma":"optimisme","pos":"NOM"} ,
		{"word":"optimiste","word_nosc":"optimiste","lemma":"optimiste","pos":"NOM"} ,
		{"word":"optimistes","word_nosc":"optimistes","lemma":"optimiste","pos":"NOM"} ,
		{"word":"optimum","word_nosc":"optimum","lemma":"optimum","pos":"NOM"} ,
		{"word":"option","word_nosc":"option","lemma":"option","pos":"NOM"} ,
		{"word":"options","word_nosc":"options","lemma":"option","pos":"NOM"} ,
		{"word":"optique","word_nosc":"optique","lemma":"optique","pos":"NOM"} ,
		{"word":"optiques","word_nosc":"optiques","lemma":"optique","pos":"NOM"} ,
		{"word":"optométrie","word_nosc":"optometrie","lemma":"optométrie","pos":"NOM"} ,
		{"word":"optométriste","word_nosc":"optometriste","lemma":"optométriste","pos":"NOM"} ,
		{"word":"opulence","word_nosc":"opulence","lemma":"opulence","pos":"NOM"} ,
		{"word":"opulences","word_nosc":"opulences","lemma":"opulence","pos":"NOM"} ,
		{"word":"opus","word_nosc":"opus","lemma":"opus","pos":"NOM"} ,
		{"word":"opuscule","word_nosc":"opuscule","lemma":"opuscule","pos":"NOM"} ,
		{"word":"opuscules","word_nosc":"opuscules","lemma":"opuscule","pos":"NOM"} ,
		{"word":"opéra","word_nosc":"opera","lemma":"opéra","pos":"NOM"} ,
		{"word":"opéra-comique","word_nosc":"opera-comique","lemma":"opéra-comique","pos":"NOM"} ,
		{"word":"opéras","word_nosc":"operas","lemma":"opéra","pos":"NOM"} ,
		{"word":"opérateur","word_nosc":"operateur","lemma":"opérateur","pos":"NOM"} ,
		{"word":"opérateurs","word_nosc":"operateurs","lemma":"opérateur","pos":"NOM"} ,
		{"word":"opération","word_nosc":"operation","lemma":"opération","pos":"NOM"} ,
		{"word":"opération-miracle","word_nosc":"operation-miracle","lemma":"opération-miracle","pos":"NOM"} ,
		{"word":"opérations","word_nosc":"operations","lemma":"opération","pos":"NOM"} ,
		{"word":"opératrice","word_nosc":"operatrice","lemma":"opérateur","pos":"NOM"} ,
		{"word":"opératrices","word_nosc":"operatrices","lemma":"opérateur","pos":"NOM"} ,
		{"word":"opérette","word_nosc":"operette","lemma":"opérette","pos":"NOM"} ,
		{"word":"opérettes","word_nosc":"operettes","lemma":"opérette","pos":"NOM"} ,
		{"word":"opéré","word_nosc":"opere","lemma":"opéré","pos":"NOM"} ,
		{"word":"opérés","word_nosc":"operes","lemma":"opéré","pos":"NOM"} ,
		{"word":"or","word_nosc":"or","lemma":"or","pos":"NOM"} ,
		{"word":"oracle","word_nosc":"oracle","lemma":"oracle","pos":"NOM"} ,
		{"word":"oracles","word_nosc":"oracles","lemma":"oracle","pos":"NOM"} ,
		{"word":"orage","word_nosc":"orage","lemma":"orage","pos":"NOM"} ,
		{"word":"orages","word_nosc":"orages","lemma":"orage","pos":"NOM"} ,
		{"word":"oraison","word_nosc":"oraison","lemma":"oraison","pos":"NOM"} ,
		{"word":"oraisons","word_nosc":"oraisons","lemma":"oraison","pos":"NOM"} ,
		{"word":"oral","word_nosc":"oral","lemma":"oral","pos":"NOM"} ,
		{"word":"oralité","word_nosc":"oralite","lemma":"oralité","pos":"NOM"} ,
		{"word":"orang-outan","word_nosc":"orang-outan","lemma":"orang-outan","pos":"NOM"} ,
		{"word":"orang-outang","word_nosc":"orang-outang","lemma":"orang-outang","pos":"NOM"} ,
		{"word":"orange","word_nosc":"orange","lemma":"orange","pos":"NOM"} ,
		{"word":"orangeade","word_nosc":"orangeade","lemma":"orangeade","pos":"NOM"} ,
		{"word":"orangeades","word_nosc":"orangeades","lemma":"orangeade","pos":"NOM"} ,
		{"word":"oranger","word_nosc":"oranger","lemma":"oranger","pos":"NOM"} ,
		{"word":"orangeraie","word_nosc":"orangeraie","lemma":"orangeraie","pos":"NOM"} ,
		{"word":"orangeraies","word_nosc":"orangeraies","lemma":"orangeraie","pos":"NOM"} ,
		{"word":"orangerie","word_nosc":"orangerie","lemma":"orangerie","pos":"NOM"} ,
		{"word":"orangeries","word_nosc":"orangeries","lemma":"orangerie","pos":"NOM"} ,
		{"word":"orangers","word_nosc":"orangers","lemma":"oranger","pos":"NOM"} ,
		{"word":"oranges","word_nosc":"oranges","lemma":"orange","pos":"NOM"} ,
		{"word":"orangistes","word_nosc":"orangistes","lemma":"orangiste","pos":"NOM"} ,
		{"word":"orangs-outangs","word_nosc":"orangs-outangs","lemma":"orang-outang","pos":"NOM"} ,
		{"word":"orangs-outans","word_nosc":"orangs-outans","lemma":"orang-outan","pos":"NOM"} ,
		{"word":"orant","word_nosc":"orant","lemma":"orant","pos":"NOM"} ,
		{"word":"orante","word_nosc":"orante","lemma":"orant","pos":"NOM"} ,
		{"word":"orants","word_nosc":"orants","lemma":"orant","pos":"NOM"} ,
		{"word":"orateur","word_nosc":"orateur","lemma":"orateur","pos":"NOM"} ,
		{"word":"orateurs","word_nosc":"orateurs","lemma":"orateur","pos":"NOM"} ,
		{"word":"oratoire","word_nosc":"oratoire","lemma":"oratoire","pos":"NOM"} ,
		{"word":"oratoires","word_nosc":"oratoires","lemma":"oratoire","pos":"NOM"} ,
		{"word":"oratorien","word_nosc":"oratorien","lemma":"oratorien","pos":"NOM"} ,
		{"word":"oratoriens","word_nosc":"oratoriens","lemma":"oratorien","pos":"NOM"} ,
		{"word":"oratorio","word_nosc":"oratorio","lemma":"oratorio","pos":"NOM"} ,
		{"word":"oratorios","word_nosc":"oratorios","lemma":"oratorio","pos":"NOM"} ,
		{"word":"oratrice","word_nosc":"oratrice","lemma":"orateur","pos":"NOM"} ,
		{"word":"orbe","word_nosc":"orbe","lemma":"orbe","pos":"NOM"} ,
		{"word":"orbes","word_nosc":"orbes","lemma":"orbe","pos":"NOM"} ,
		{"word":"orbite","word_nosc":"orbite","lemma":"orbite","pos":"NOM"} ,
		{"word":"orbites","word_nosc":"orbites","lemma":"orbite","pos":"NOM"} ,
		{"word":"orchestration","word_nosc":"orchestration","lemma":"orchestration","pos":"NOM"} ,
		{"word":"orchestrations","word_nosc":"orchestrations","lemma":"orchestration","pos":"NOM"} ,
		{"word":"orchestre","word_nosc":"orchestre","lemma":"orchestre","pos":"NOM"} ,
		{"word":"orchestres","word_nosc":"orchestres","lemma":"orchestre","pos":"NOM"} ,
		{"word":"orchidée","word_nosc":"orchidee","lemma":"orchidée","pos":"NOM"} ,
		{"word":"orchidées","word_nosc":"orchidees","lemma":"orchidée","pos":"NOM"} ,
		{"word":"orchis","word_nosc":"orchis","lemma":"orchis","pos":"NOM"} ,
		{"word":"ordalie","word_nosc":"ordalie","lemma":"ordalie","pos":"NOM"} ,
		{"word":"ordinaire","word_nosc":"ordinaire","lemma":"ordinaire","pos":"NOM"} ,
		{"word":"ordinaires","word_nosc":"ordinaires","lemma":"ordinaire","pos":"NOM"} ,
		{"word":"ordinant","word_nosc":"ordinant","lemma":"ordinant","pos":"NOM"} ,
		{"word":"ordinateur","word_nosc":"ordinateur","lemma":"ordinateur","pos":"NOM"} ,
		{"word":"ordinateurs","word_nosc":"ordinateurs","lemma":"ordinateur","pos":"NOM"} ,
		{"word":"ordination","word_nosc":"ordination","lemma":"ordination","pos":"NOM"} ,
		{"word":"ordo","word_nosc":"ordo","lemma":"ordo","pos":"NOM"} ,
		{"word":"ordonnance","word_nosc":"ordonnance","lemma":"ordonnance","pos":"NOM"} ,
		{"word":"ordonnancement","word_nosc":"ordonnancement","lemma":"ordonnancement","pos":"NOM"} ,
		{"word":"ordonnances","word_nosc":"ordonnances","lemma":"ordonnance","pos":"NOM"} ,
		{"word":"ordonnateur","word_nosc":"ordonnateur","lemma":"ordonnateur","pos":"NOM"} ,
		{"word":"ordonnateurs","word_nosc":"ordonnateurs","lemma":"ordonnateur","pos":"NOM"} ,
		{"word":"ordonnatrice","word_nosc":"ordonnatrice","lemma":"ordonnateur","pos":"NOM"} ,
		{"word":"ordre","word_nosc":"ordre","lemma":"ordre","pos":"NOM"} ,
		{"word":"ordres","word_nosc":"ordres","lemma":"ordre","pos":"NOM"} ,
		{"word":"ordure","word_nosc":"ordure","lemma":"ordure","pos":"NOM"} ,
		{"word":"ordureries","word_nosc":"ordureries","lemma":"ordurerie","pos":"NOM"} ,
		{"word":"ordures","word_nosc":"ordures","lemma":"ordure","pos":"NOM"} ,
		{"word":"oreille","word_nosc":"oreille","lemma":"oreille","pos":"NOM"} ,
		{"word":"oreiller","word_nosc":"oreiller","lemma":"oreiller","pos":"NOM"} ,
		{"word":"oreillers","word_nosc":"oreillers","lemma":"oreiller","pos":"NOM"} ,
		{"word":"oreilles","word_nosc":"oreilles","lemma":"oreille","pos":"NOM"} ,
		{"word":"oreillette","word_nosc":"oreillette","lemma":"oreillette","pos":"NOM"} ,
		{"word":"oreillettes","word_nosc":"oreillettes","lemma":"oreillette","pos":"NOM"} ,
		{"word":"oreillons","word_nosc":"oreillons","lemma":"oreillon","pos":"NOM"} ,
		{"word":"orfraie","word_nosc":"orfraie","lemma":"orfraie","pos":"NOM"} ,
		{"word":"orfèvre","word_nosc":"orfevre","lemma":"orfèvre","pos":"NOM"} ,
		{"word":"orfèvrerie","word_nosc":"orfevrerie","lemma":"orfèvrerie","pos":"NOM"} ,
		{"word":"orfèvreries","word_nosc":"orfevreries","lemma":"orfèvrerie","pos":"NOM"} ,
		{"word":"orfèvres","word_nosc":"orfevres","lemma":"orfèvre","pos":"NOM"} ,
		{"word":"organdi","word_nosc":"organdi","lemma":"organdi","pos":"NOM"} ,
		{"word":"organdis","word_nosc":"organdis","lemma":"organdi","pos":"NOM"} ,
		{"word":"organe","word_nosc":"organe","lemma":"organe","pos":"NOM"} ,
		{"word":"organes","word_nosc":"organes","lemma":"organe","pos":"NOM"} ,
		{"word":"organigramme","word_nosc":"organigramme","lemma":"organigramme","pos":"NOM"} ,
		{"word":"organisateur","word_nosc":"organisateur","lemma":"organisateur","pos":"NOM"} ,
		{"word":"organisateurs","word_nosc":"organisateurs","lemma":"organisateur","pos":"NOM"} ,
		{"word":"organisation","word_nosc":"organisation","lemma":"organisation","pos":"NOM"} ,
		{"word":"organisations","word_nosc":"organisations","lemma":"organisation","pos":"NOM"} ,
		{"word":"organisatrice","word_nosc":"organisatrice","lemma":"organisateur","pos":"NOM"} ,
		{"word":"organiseur","word_nosc":"organiseur","lemma":"organiseur","pos":"NOM"} ,
		{"word":"organisme","word_nosc":"organisme","lemma":"organisme","pos":"NOM"} ,
		{"word":"organismes","word_nosc":"organismes","lemma":"organisme","pos":"NOM"} ,
		{"word":"organiste","word_nosc":"organiste","lemma":"organiste","pos":"NOM"} ,
		{"word":"organistes","word_nosc":"organistes","lemma":"organiste","pos":"NOM"} ,
		{"word":"organon","word_nosc":"organon","lemma":"organon","pos":"NOM"} ,
		{"word":"orgasme","word_nosc":"orgasme","lemma":"orgasme","pos":"NOM"} ,
		{"word":"orgasmes","word_nosc":"orgasmes","lemma":"orgasme","pos":"NOM"} ,
		{"word":"orge","word_nosc":"orge","lemma":"orge","pos":"NOM"} ,
		{"word":"orgeat","word_nosc":"orgeat","lemma":"orgeat","pos":"NOM"} ,
		{"word":"orgelet","word_nosc":"orgelet","lemma":"orgelet","pos":"NOM"} ,
		{"word":"orgelets","word_nosc":"orgelets","lemma":"orgelet","pos":"NOM"} ,
		{"word":"orges","word_nosc":"orges","lemma":"orge","pos":"NOM"} ,
		{"word":"orgie","word_nosc":"orgie","lemma":"orgie","pos":"NOM"} ,
		{"word":"orgies","word_nosc":"orgies","lemma":"orgie","pos":"NOM"} ,
		{"word":"orgue","word_nosc":"orgue","lemma":"orgue","pos":"NOM"} ,
		{"word":"orgueil","word_nosc":"orgueil","lemma":"orgueil","pos":"NOM"} ,
		{"word":"orgueilleuse","word_nosc":"orgueilleuse","lemma":"orgueilleux","pos":"NOM"} ,
		{"word":"orgueilleuses","word_nosc":"orgueilleuses","lemma":"orgueilleux","pos":"NOM"} ,
		{"word":"orgueilleux","word_nosc":"orgueilleux","lemma":"orgueilleux","pos":"NOM"} ,
		{"word":"orgueils","word_nosc":"orgueils","lemma":"orgueil","pos":"NOM"} ,
		{"word":"orgues","word_nosc":"orgues","lemma":"orgue","pos":"NOM"} ,
		{"word":"oribus","word_nosc":"oribus","lemma":"oribus","pos":"NOM"} ,
		{"word":"orient","word_nosc":"orient","lemma":"orient","pos":"NOM"} ,
		{"word":"oriental","word_nosc":"oriental","lemma":"oriental","pos":"NOM"} ,
		{"word":"orientale","word_nosc":"orientale","lemma":"oriental","pos":"NOM"} ,
		{"word":"orientales","word_nosc":"orientales","lemma":"oriental","pos":"NOM"} ,
		{"word":"orientalisme","word_nosc":"orientalisme","lemma":"orientalisme","pos":"NOM"} ,
		{"word":"orientaliste","word_nosc":"orientaliste","lemma":"orientaliste","pos":"NOM"} ,
		{"word":"orientalistes","word_nosc":"orientalistes","lemma":"orientaliste","pos":"NOM"} ,
		{"word":"orientateurs","word_nosc":"orientateurs","lemma":"orientateur","pos":"NOM"} ,
		{"word":"orientation","word_nosc":"orientation","lemma":"orientation","pos":"NOM"} ,
		{"word":"orientations","word_nosc":"orientations","lemma":"orientation","pos":"NOM"} ,
		{"word":"orientaux","word_nosc":"orientaux","lemma":"oriental","pos":"NOM"} ,
		{"word":"orienteur","word_nosc":"orienteur","lemma":"orienteur","pos":"NOM"} ,
		{"word":"orienteurs","word_nosc":"orienteurs","lemma":"orienteur","pos":"NOM"} ,
		{"word":"orienteuse","word_nosc":"orienteuse","lemma":"orienteur","pos":"NOM"} ,
		{"word":"orients","word_nosc":"orients","lemma":"orient","pos":"NOM"} ,
		{"word":"orifice","word_nosc":"orifice","lemma":"orifice","pos":"NOM"} ,
		{"word":"orifices","word_nosc":"orifices","lemma":"orifice","pos":"NOM"} ,
		{"word":"oriflamme","word_nosc":"oriflamme","lemma":"oriflamme","pos":"NOM"} ,
		{"word":"oriflammes","word_nosc":"oriflammes","lemma":"oriflamme","pos":"NOM"} ,
		{"word":"origami","word_nosc":"origami","lemma":"origami","pos":"NOM"} ,
		{"word":"origan","word_nosc":"origan","lemma":"origan","pos":"NOM"} ,
		{"word":"original","word_nosc":"original","lemma":"original","pos":"NOM"} ,
		{"word":"originale","word_nosc":"originale","lemma":"original","pos":"NOM"} ,
		{"word":"originales","word_nosc":"originales","lemma":"original","pos":"NOM"} ,
		{"word":"originalité","word_nosc":"originalite","lemma":"originalité","pos":"NOM"} ,
		{"word":"originalités","word_nosc":"originalites","lemma":"originalité","pos":"NOM"} ,
		{"word":"originaux","word_nosc":"originaux","lemma":"original","pos":"NOM"} ,
		{"word":"origine","word_nosc":"origine","lemma":"origine","pos":"NOM"} ,
		{"word":"origines","word_nosc":"origines","lemma":"origine","pos":"NOM"} ,
		{"word":"orignal","word_nosc":"orignal","lemma":"orignal","pos":"NOM"} ,
		{"word":"orignaux","word_nosc":"orignaux","lemma":"orignal","pos":"NOM"} ,
		{"word":"orillon","word_nosc":"orillon","lemma":"orillon","pos":"NOM"} ,
		{"word":"orillons","word_nosc":"orillons","lemma":"orillon","pos":"NOM"} ,
		{"word":"orin","word_nosc":"orin","lemma":"orin","pos":"NOM"} ,
		{"word":"oriol","word_nosc":"oriol","lemma":"oriol","pos":"NOM"} ,
		{"word":"oripeaux","word_nosc":"oripeaux","lemma":"oripeau","pos":"NOM"} ,
		{"word":"orlon","word_nosc":"orlon","lemma":"orlon","pos":"NOM"} ,
		{"word":"orléanisme","word_nosc":"orleanisme","lemma":"orléanisme","pos":"NOM"} ,
		{"word":"orléanistes","word_nosc":"orleanistes","lemma":"orléaniste","pos":"NOM"} ,
		{"word":"orme","word_nosc":"orme","lemma":"orme","pos":"NOM"} ,
		{"word":"ormeau","word_nosc":"ormeau","lemma":"ormeau","pos":"NOM"} ,
		{"word":"ormeaux","word_nosc":"ormeaux","lemma":"ormeau","pos":"NOM"} ,
		{"word":"ormes","word_nosc":"ormes","lemma":"orme","pos":"NOM"} ,
		{"word":"ornement","word_nosc":"ornement","lemma":"ornement","pos":"NOM"} ,
		{"word":"ornementation","word_nosc":"ornementation","lemma":"ornementation","pos":"NOM"} ,
		{"word":"ornementations","word_nosc":"ornementations","lemma":"ornementation","pos":"NOM"} ,
		{"word":"ornements","word_nosc":"ornements","lemma":"ornement","pos":"NOM"} ,
		{"word":"ornithologie","word_nosc":"ornithologie","lemma":"ornithologie","pos":"NOM"} ,
		{"word":"ornithologue","word_nosc":"ornithologue","lemma":"ornithologue","pos":"NOM"} ,
		{"word":"ornithologues","word_nosc":"ornithologues","lemma":"ornithologue","pos":"NOM"} ,
		{"word":"ornithoptère","word_nosc":"ornithoptere","lemma":"ornithoptère","pos":"NOM"} ,
		{"word":"ornithorynque","word_nosc":"ornithorynque","lemma":"ornithorynque","pos":"NOM"} ,
		{"word":"ornithorynques","word_nosc":"ornithorynques","lemma":"ornithorynque","pos":"NOM"} ,
		{"word":"ornière","word_nosc":"orniere","lemma":"ornière","pos":"NOM"} ,
		{"word":"ornières","word_nosc":"ornieres","lemma":"ornière","pos":"NOM"} ,
		{"word":"orographie","word_nosc":"orographie","lemma":"orographie","pos":"NOM"} ,
		{"word":"oronge","word_nosc":"oronge","lemma":"oronge","pos":"NOM"} ,
		{"word":"oronges","word_nosc":"oronges","lemma":"oronge","pos":"NOM"} ,
		{"word":"oropharynx","word_nosc":"oropharynx","lemma":"oropharynx","pos":"NOM"} ,
		{"word":"orpailleur","word_nosc":"orpailleur","lemma":"orpailleur","pos":"NOM"} ,
		{"word":"orpailleurs","word_nosc":"orpailleurs","lemma":"orpailleur","pos":"NOM"} ,
		{"word":"orphelin","word_nosc":"orphelin","lemma":"orphelin","pos":"NOM"} ,
		{"word":"orphelinat","word_nosc":"orphelinat","lemma":"orphelinat","pos":"NOM"} ,
		{"word":"orphelinats","word_nosc":"orphelinats","lemma":"orphelinat","pos":"NOM"} ,
		{"word":"orpheline","word_nosc":"orpheline","lemma":"orphelin","pos":"NOM"} ,
		{"word":"orphelines","word_nosc":"orphelines","lemma":"orphelin","pos":"NOM"} ,
		{"word":"orphelins","word_nosc":"orphelins","lemma":"orphelin","pos":"NOM"} ,
		{"word":"orphie","word_nosc":"orphie","lemma":"orphie","pos":"NOM"} ,
		{"word":"orphisme","word_nosc":"orphisme","lemma":"orphisme","pos":"NOM"} ,
		{"word":"orphée","word_nosc":"orphee","lemma":"orphée","pos":"NOM"} ,
		{"word":"orphéon","word_nosc":"orpheon","lemma":"orphéon","pos":"NOM"} ,
		{"word":"orphéoniste","word_nosc":"orpheoniste","lemma":"orphéoniste","pos":"NOM"} ,
		{"word":"orphéonistes","word_nosc":"orpheonistes","lemma":"orphéoniste","pos":"NOM"} ,
		{"word":"orphéons","word_nosc":"orpheons","lemma":"orphéon","pos":"NOM"} ,
		{"word":"orpiment","word_nosc":"orpiment","lemma":"orpiment","pos":"NOM"} ,
		{"word":"orpin","word_nosc":"orpin","lemma":"orpin","pos":"NOM"} ,
		{"word":"orque","word_nosc":"orque","lemma":"orque","pos":"NOM"} ,
		{"word":"orques","word_nosc":"orques","lemma":"orque","pos":"NOM"} ,
		{"word":"ors","word_nosc":"ors","lemma":"or","pos":"NOM"} ,
		{"word":"orsec","word_nosc":"orsec","lemma":"orsec","pos":"NOM"} ,
		{"word":"orteil","word_nosc":"orteil","lemma":"orteil","pos":"NOM"} ,
		{"word":"orteils","word_nosc":"orteils","lemma":"orteil","pos":"NOM"} ,
		{"word":"orthodontie","word_nosc":"orthodontie","lemma":"orthodontie","pos":"NOM"} ,
		{"word":"orthodontiste","word_nosc":"orthodontiste","lemma":"orthodontiste","pos":"NOM"} ,
		{"word":"orthodontistes","word_nosc":"orthodontistes","lemma":"orthodontiste","pos":"NOM"} ,
		{"word":"orthodoxe","word_nosc":"orthodoxe","lemma":"orthodoxe","pos":"NOM"} ,
		{"word":"orthodoxes","word_nosc":"orthodoxes","lemma":"orthodoxe","pos":"NOM"} ,
		{"word":"orthodoxie","word_nosc":"orthodoxie","lemma":"orthodoxie","pos":"NOM"} ,
		{"word":"orthographe","word_nosc":"orthographe","lemma":"orthographe","pos":"NOM"} ,
		{"word":"orthophonie","word_nosc":"orthophonie","lemma":"orthophonie","pos":"NOM"} ,
		{"word":"orthophoniste","word_nosc":"orthophoniste","lemma":"orthophoniste","pos":"NOM"} ,
		{"word":"orthophonistes","word_nosc":"orthophonistes","lemma":"orthophoniste","pos":"NOM"} ,
		{"word":"orthoptère","word_nosc":"orthoptere","lemma":"orthoptère","pos":"NOM"} ,
		{"word":"orthoptères","word_nosc":"orthopteres","lemma":"orthoptère","pos":"NOM"} ,
		{"word":"orthopédie","word_nosc":"orthopedie","lemma":"orthopédie","pos":"NOM"} ,
		{"word":"orthopédiste","word_nosc":"orthopediste","lemma":"orthopédiste","pos":"NOM"} ,
		{"word":"ortie","word_nosc":"ortie","lemma":"ortie","pos":"NOM"} ,
		{"word":"orties","word_nosc":"orties","lemma":"ortie","pos":"NOM"} ,
		{"word":"ortolan","word_nosc":"ortolan","lemma":"ortolan","pos":"NOM"} ,
		{"word":"ortolans","word_nosc":"ortolans","lemma":"ortolan","pos":"NOM"} ,
		{"word":"ortédrine","word_nosc":"ortedrine","lemma":"ortédrine","pos":"NOM"} ,
		{"word":"orvet","word_nosc":"orvet","lemma":"orvet","pos":"NOM"} ,
		{"word":"oryctérope","word_nosc":"orycterope","lemma":"oryctérope","pos":"NOM"} ,
		{"word":"oryx","word_nosc":"oryx","lemma":"oryx","pos":"NOM"} ,
		{"word":"oréade","word_nosc":"oreade","lemma":"oréade","pos":"NOM"} ,
		{"word":"orée","word_nosc":"oree","lemma":"orée","pos":"NOM"} ,
		{"word":"orémus","word_nosc":"oremus","lemma":"orémus","pos":"NOM"} ,
		{"word":"os","word_nosc":"os","lemma":"os","pos":"NOM"} ,
		{"word":"oscar","word_nosc":"oscar","lemma":"oscar","pos":"NOM"} ,
		{"word":"oscars","word_nosc":"oscars","lemma":"oscar","pos":"NOM"} ,
		{"word":"oscillateur","word_nosc":"oscillateur","lemma":"oscillateur","pos":"NOM"} ,
		{"word":"oscillation","word_nosc":"oscillation","lemma":"oscillation","pos":"NOM"} ,
		{"word":"oscillations","word_nosc":"oscillations","lemma":"oscillation","pos":"NOM"} ,
		{"word":"oscillographe","word_nosc":"oscillographe","lemma":"oscillographe","pos":"NOM"} ,
		{"word":"oscillomètre","word_nosc":"oscillometre","lemma":"oscillomètre","pos":"NOM"} ,
		{"word":"oscilloscope","word_nosc":"oscilloscope","lemma":"oscilloscope","pos":"NOM"} ,
		{"word":"ose","word_nosc":"ose","lemma":"ose","pos":"NOM"} ,
		{"word":"oseille","word_nosc":"oseille","lemma":"oseille","pos":"NOM"} ,
		{"word":"oseilles","word_nosc":"oseilles","lemma":"oseille","pos":"NOM"} ,
		{"word":"oseraie","word_nosc":"oseraie","lemma":"oseraie","pos":"NOM"} ,
		{"word":"oseraies","word_nosc":"oseraies","lemma":"oseraie","pos":"NOM"} ,
		{"word":"oses","word_nosc":"oses","lemma":"ose","pos":"NOM"} ,
		{"word":"osier","word_nosc":"osier","lemma":"osier","pos":"NOM"} ,
		{"word":"osiers","word_nosc":"osiers","lemma":"osier","pos":"NOM"} ,
		{"word":"osmium","word_nosc":"osmium","lemma":"osmium","pos":"NOM"} ,
		{"word":"osmonde","word_nosc":"osmonde","lemma":"osmonde","pos":"NOM"} ,
		{"word":"osmose","word_nosc":"osmose","lemma":"osmose","pos":"NOM"} ,
		{"word":"osmoses","word_nosc":"osmoses","lemma":"osmose","pos":"NOM"} ,
		{"word":"ossature","word_nosc":"ossature","lemma":"ossature","pos":"NOM"} ,
		{"word":"ossatures","word_nosc":"ossatures","lemma":"ossature","pos":"NOM"} ,
		{"word":"osselet","word_nosc":"osselet","lemma":"osselet","pos":"NOM"} ,
		{"word":"osselets","word_nosc":"osselets","lemma":"osselet","pos":"NOM"} ,
		{"word":"ossement","word_nosc":"ossement","lemma":"ossement","pos":"NOM"} ,
		{"word":"ossements","word_nosc":"ossements","lemma":"ossement","pos":"NOM"} ,
		{"word":"ossification","word_nosc":"ossification","lemma":"ossification","pos":"NOM"} ,
		{"word":"osso buco","word_nosc":"osso buco","lemma":"osso buco","pos":"NOM"} ,
		{"word":"ossuaire","word_nosc":"ossuaire","lemma":"ossuaire","pos":"NOM"} ,
		{"word":"ossuaires","word_nosc":"ossuaires","lemma":"ossuaire","pos":"NOM"} ,
		{"word":"ost","word_nosc":"ost","lemma":"ost","pos":"NOM"} ,
		{"word":"ostensoir","word_nosc":"ostensoir","lemma":"ostensoir","pos":"NOM"} ,
		{"word":"ostensoirs","word_nosc":"ostensoirs","lemma":"ostensoir","pos":"NOM"} ,
		{"word":"ostentation","word_nosc":"ostentation","lemma":"ostentation","pos":"NOM"} ,
		{"word":"ostracisme","word_nosc":"ostracisme","lemma":"ostracisme","pos":"NOM"} ,
		{"word":"ostrogoth","word_nosc":"ostrogoth","lemma":"ostrogoth","pos":"NOM"} ,
		{"word":"ostrogoths","word_nosc":"ostrogoths","lemma":"ostrogoth","pos":"NOM"} ,
		{"word":"ostréiculteur","word_nosc":"ostreiculteur","lemma":"ostréiculteur","pos":"NOM"} ,
		{"word":"ostréiculteurs","word_nosc":"ostreiculteurs","lemma":"ostréiculteur","pos":"NOM"} ,
		{"word":"ostréiculture","word_nosc":"ostreiculture","lemma":"ostréiculture","pos":"NOM"} ,
		{"word":"ostéoarthrite","word_nosc":"osteoarthrite","lemma":"ostéoarthrite","pos":"NOM"} ,
		{"word":"ostéogenèse","word_nosc":"osteogenese","lemma":"ostéogenèse","pos":"NOM"} ,
		{"word":"ostéomyélite","word_nosc":"osteomyelite","lemma":"ostéomyélite","pos":"NOM"} ,
		{"word":"ostéopathe","word_nosc":"osteopathe","lemma":"ostéopathe","pos":"NOM"} ,
		{"word":"ostéopathes","word_nosc":"osteopathes","lemma":"ostéopathe","pos":"NOM"} ,
		{"word":"ostéopathie","word_nosc":"osteopathie","lemma":"ostéopathie","pos":"NOM"} ,
		{"word":"ostéoporose","word_nosc":"osteoporose","lemma":"ostéoporose","pos":"NOM"} ,
		{"word":"ostéosarcome","word_nosc":"osteosarcome","lemma":"ostéosarcome","pos":"NOM"} ,
		{"word":"ostéotomie","word_nosc":"osteotomie","lemma":"ostéotomie","pos":"NOM"} ,
		{"word":"otage","word_nosc":"otage","lemma":"otage","pos":"NOM"} ,
		{"word":"otages","word_nosc":"otages","lemma":"otage","pos":"NOM"} ,
		{"word":"otalgie","word_nosc":"otalgie","lemma":"otalgie","pos":"NOM"} ,
		{"word":"otarie","word_nosc":"otarie","lemma":"otarie","pos":"NOM"} ,
		{"word":"otaries","word_nosc":"otaries","lemma":"otarie","pos":"NOM"} ,
		{"word":"otite","word_nosc":"otite","lemma":"otite","pos":"NOM"} ,
		{"word":"otites","word_nosc":"otites","lemma":"otite","pos":"NOM"} ,
		{"word":"oto rhino","word_nosc":"oto rhino","lemma":"oto-rhino","pos":"NOM"} ,
		{"word":"oto-rhino","word_nosc":"oto-rhino","lemma":"oto-rhino","pos":"NOM"} ,
		{"word":"oto-rhino-laryngologiste","word_nosc":"oto-rhino-laryngologiste","lemma":"oto-rhino-laryngologiste","pos":"NOM"} ,
		{"word":"oto-rhinos","word_nosc":"oto-rhinos","lemma":"oto-rhino","pos":"NOM"} ,
		{"word":"otoscope","word_nosc":"otoscope","lemma":"otoscope","pos":"NOM"} ,
		{"word":"otospongiose","word_nosc":"otospongiose","lemma":"otospongiose","pos":"NOM"} ,
		{"word":"ottoman","word_nosc":"ottoman","lemma":"ottoman","pos":"NOM"} ,
		{"word":"ottomane","word_nosc":"ottomane","lemma":"ottomane","pos":"NOM"} ,
		{"word":"ouaille","word_nosc":"ouaille","lemma":"ouaille","pos":"NOM"} ,
		{"word":"ouailles","word_nosc":"ouailles","lemma":"ouaille","pos":"NOM"} ,
		{"word":"ouananiche","word_nosc":"ouananiche","lemma":"ouananiche","pos":"NOM"} ,
		{"word":"ouananiches","word_nosc":"ouananiches","lemma":"ouananiche","pos":"NOM"} ,
		{"word":"ouaouaron","word_nosc":"ouaouaron","lemma":"ouaouaron","pos":"NOM"} ,
		{"word":"ouate","word_nosc":"ouate","lemma":"ouate","pos":"NOM"} ,
		{"word":"ouates","word_nosc":"ouates","lemma":"ouate","pos":"NOM"} ,
		{"word":"oubli","word_nosc":"oubli","lemma":"oubli","pos":"NOM"} ,
		{"word":"oublie","word_nosc":"oublie","lemma":"oublie","pos":"NOM"} ,
		{"word":"oublies","word_nosc":"oublies","lemma":"oubli","pos":"NOM"} ,
		{"word":"oubliette","word_nosc":"oubliette","lemma":"oubliette","pos":"NOM"} ,
		{"word":"oubliettes","word_nosc":"oubliettes","lemma":"oubliette","pos":"NOM"} ,
		{"word":"oublis","word_nosc":"oublis","lemma":"oubli","pos":"NOM"} ,
		{"word":"ouche","word_nosc":"ouche","lemma":"ouche","pos":"NOM"} ,
		{"word":"oued","word_nosc":"oued","lemma":"oued","pos":"NOM"} ,
		{"word":"oueds","word_nosc":"oueds","lemma":"oued","pos":"NOM"} ,
		{"word":"ouest","word_nosc":"ouest","lemma":"ouest","pos":"NOM"} ,
		{"word":"ougandais","word_nosc":"ougandais","lemma":"ougandais","pos":"NOM"} ,
		{"word":"ouighour","word_nosc":"ouighour","lemma":"ouighour","pos":"NOM"} ,
		{"word":"ouighours","word_nosc":"ouighours","lemma":"ouighour","pos":"NOM"} ,
		{"word":"ouistiti","word_nosc":"ouistiti","lemma":"ouistiti","pos":"NOM"} ,
		{"word":"ouistitis","word_nosc":"ouistitis","lemma":"ouistiti","pos":"NOM"} ,
		{"word":"oukases","word_nosc":"oukases","lemma":"oukase","pos":"NOM"} ,
		{"word":"ouléma","word_nosc":"oulema","lemma":"ouléma","pos":"NOM"} ,
		{"word":"oulémas","word_nosc":"oulemas","lemma":"ouléma","pos":"NOM"} ,
		{"word":"ounce","word_nosc":"ounce","lemma":"ounce","pos":"NOM"} ,
		{"word":"ouragan","word_nosc":"ouragan","lemma":"ouragan","pos":"NOM"} ,
		{"word":"ouragans","word_nosc":"ouragans","lemma":"ouragan","pos":"NOM"} ,
		{"word":"ourdissage","word_nosc":"ourdissage","lemma":"ourdissage","pos":"NOM"} ,
		{"word":"ourdisseur","word_nosc":"ourdisseur","lemma":"ourdisseur","pos":"NOM"} ,
		{"word":"ourdisseurs","word_nosc":"ourdisseurs","lemma":"ourdisseur","pos":"NOM"} ,
		{"word":"ourdisseuses","word_nosc":"ourdisseuses","lemma":"ourdisseur","pos":"NOM"} ,
		{"word":"ourdissoir","word_nosc":"ourdissoir","lemma":"ourdissoir","pos":"NOM"} ,
		{"word":"ourdou","word_nosc":"ourdou","lemma":"ourdou","pos":"NOM"} ,
		{"word":"ourlet","word_nosc":"ourlet","lemma":"ourlet","pos":"NOM"} ,
		{"word":"ourlets","word_nosc":"ourlets","lemma":"ourlet","pos":"NOM"} ,
		{"word":"ouroboros","word_nosc":"ouroboros","lemma":"ouroboros","pos":"NOM"} ,
		{"word":"ours","word_nosc":"ours","lemma":"ours","pos":"NOM"} ,
		{"word":"ourse","word_nosc":"ourse","lemma":"ours","pos":"NOM"} ,
		{"word":"ourses","word_nosc":"ourses","lemma":"ours","pos":"NOM"} ,
		{"word":"oursin","word_nosc":"oursin","lemma":"oursin","pos":"NOM"} ,
		{"word":"oursins","word_nosc":"oursins","lemma":"oursin","pos":"NOM"} ,
		{"word":"ourson","word_nosc":"ourson","lemma":"ourson","pos":"NOM"} ,
		{"word":"oursonne","word_nosc":"oursonne","lemma":"ourson","pos":"NOM"} ,
		{"word":"oursonnes","word_nosc":"oursonnes","lemma":"ourson","pos":"NOM"} ,
		{"word":"oursons","word_nosc":"oursons","lemma":"ourson","pos":"NOM"} ,
		{"word":"oustachi","word_nosc":"oustachi","lemma":"oustachi","pos":"NOM"} ,
		{"word":"oustachis","word_nosc":"oustachis","lemma":"oustachi","pos":"NOM"} ,
		{"word":"outarde","word_nosc":"outarde","lemma":"outarde","pos":"NOM"} ,
		{"word":"outardes","word_nosc":"outardes","lemma":"outarde","pos":"NOM"} ,
		{"word":"outil","word_nosc":"outil","lemma":"outil","pos":"NOM"} ,
		{"word":"outillage","word_nosc":"outillage","lemma":"outillage","pos":"NOM"} ,
		{"word":"outillages","word_nosc":"outillages","lemma":"outillage","pos":"NOM"} ,
		{"word":"outilleur","word_nosc":"outilleur","lemma":"outilleur","pos":"NOM"} ,
		{"word":"outils","word_nosc":"outils","lemma":"outil","pos":"NOM"} ,
		{"word":"outlaw","word_nosc":"outlaw","lemma":"outlaw","pos":"NOM"} ,
		{"word":"outlaws","word_nosc":"outlaws","lemma":"outlaw","pos":"NOM"} ,
		{"word":"outrage","word_nosc":"outrage","lemma":"outrage","pos":"NOM"} ,
		{"word":"outrages","word_nosc":"outrages","lemma":"outrage","pos":"NOM"} ,
		{"word":"outrance","word_nosc":"outrance","lemma":"outrance","pos":"NOM"} ,
		{"word":"outrances","word_nosc":"outrances","lemma":"outrance","pos":"NOM"} ,
		{"word":"outre","word_nosc":"outre","lemma":"outre","pos":"NOM"} ,
		{"word":"outrecuidance","word_nosc":"outrecuidance","lemma":"outrecuidance","pos":"NOM"} ,
		{"word":"outremer","word_nosc":"outremer","lemma":"outremer","pos":"NOM"} ,
		{"word":"outres","word_nosc":"outres","lemma":"outre","pos":"NOM"} ,
		{"word":"outrigger","word_nosc":"outrigger","lemma":"outrigger","pos":"NOM"} ,
		{"word":"outsider","word_nosc":"outsider","lemma":"outsider","pos":"NOM"} ,
		{"word":"outsiders","word_nosc":"outsiders","lemma":"outsider","pos":"NOM"} ,
		{"word":"ouverture","word_nosc":"ouverture","lemma":"ouverture","pos":"NOM"} ,
		{"word":"ouvertures","word_nosc":"ouvertures","lemma":"ouverture","pos":"NOM"} ,
		{"word":"ouvrage","word_nosc":"ouvrage","lemma":"ouvrage","pos":"NOM"} ,
		{"word":"ouvrages","word_nosc":"ouvrages","lemma":"ouvrage","pos":"NOM"} ,
		{"word":"ouvrant","word_nosc":"ouvrant","lemma":"ouvrant","pos":"NOM"} ,
		{"word":"ouvre-bouteille","word_nosc":"ouvre-bouteille","lemma":"ouvre-bouteille","pos":"NOM"} ,
		{"word":"ouvre-bouteilles","word_nosc":"ouvre-bouteilles","lemma":"ouvre-bouteille","pos":"NOM"} ,
		{"word":"ouvre-boîte","word_nosc":"ouvre-boite","lemma":"ouvre-boîte","pos":"NOM"} ,
		{"word":"ouvre-boîtes","word_nosc":"ouvre-boites","lemma":"ouvre-boîtes","pos":"NOM"} ,
		{"word":"ouvreur","word_nosc":"ouvreur","lemma":"ouvreur","pos":"NOM"} ,
		{"word":"ouvreurs","word_nosc":"ouvreurs","lemma":"ouvreur","pos":"NOM"} ,
		{"word":"ouvreuse","word_nosc":"ouvreuse","lemma":"ouvreur","pos":"NOM"} ,
		{"word":"ouvreuses","word_nosc":"ouvreuses","lemma":"ouvreur","pos":"NOM"} ,
		{"word":"ouvrier","word_nosc":"ouvrier","lemma":"ouvrier","pos":"NOM"} ,
		{"word":"ouvriers","word_nosc":"ouvriers","lemma":"ouvrier","pos":"NOM"} ,
		{"word":"ouvrière","word_nosc":"ouvriere","lemma":"ouvrier","pos":"NOM"} ,
		{"word":"ouvrières","word_nosc":"ouvrieres","lemma":"ouvrier","pos":"NOM"} ,
		{"word":"ouvriérisme","word_nosc":"ouvrierisme","lemma":"ouvriérisme","pos":"NOM"} ,
		{"word":"ouvroir","word_nosc":"ouvroir","lemma":"ouvroir","pos":"NOM"} ,
		{"word":"ouvroirs","word_nosc":"ouvroirs","lemma":"ouvroir","pos":"NOM"} ,
		{"word":"ouzo","word_nosc":"ouzo","lemma":"ouzo","pos":"NOM"} ,
		{"word":"ouzos","word_nosc":"ouzos","lemma":"ouzo","pos":"NOM"} ,
		{"word":"ouï-dire","word_nosc":"oui-dire","lemma":"ouï-dire","pos":"NOM"} ,
		{"word":"ouïe","word_nosc":"ouie","lemma":"ouïe","pos":"NOM"} ,
		{"word":"ouïes","word_nosc":"ouies","lemma":"ouïe","pos":"NOM"} ,
		{"word":"ouïgour","word_nosc":"ouigour","lemma":"ouïgour","pos":"NOM"} ,
		{"word":"ovaire","word_nosc":"ovaire","lemma":"ovaire","pos":"NOM"} ,
		{"word":"ovaires","word_nosc":"ovaires","lemma":"ovaire","pos":"NOM"} ,
		{"word":"ovale","word_nosc":"ovale","lemma":"ovale","pos":"NOM"} ,
		{"word":"ovales","word_nosc":"ovales","lemma":"ovale","pos":"NOM"} ,
		{"word":"ovariectomie","word_nosc":"ovariectomie","lemma":"ovariectomie","pos":"NOM"} ,
		{"word":"ovariotomie","word_nosc":"ovariotomie","lemma":"ovariotomie","pos":"NOM"} ,
		{"word":"ovation","word_nosc":"ovation","lemma":"ovation","pos":"NOM"} ,
		{"word":"ovations","word_nosc":"ovations","lemma":"ovation","pos":"NOM"} ,
		{"word":"ove","word_nosc":"ove","lemma":"ove","pos":"NOM"} ,
		{"word":"overdose","word_nosc":"overdose","lemma":"overdose","pos":"NOM"} ,
		{"word":"overdoses","word_nosc":"overdoses","lemma":"overdose","pos":"NOM"} ,
		{"word":"overdrive","word_nosc":"overdrive","lemma":"overdrive","pos":"NOM"} ,
		{"word":"ovibos","word_nosc":"ovibos","lemma":"ovibos","pos":"NOM"} ,
		{"word":"ovins","word_nosc":"ovins","lemma":"ovin","pos":"NOM"} ,
		{"word":"ovni","word_nosc":"ovni","lemma":"ovni","pos":"NOM"} ,
		{"word":"ovnis","word_nosc":"ovnis","lemma":"ovni","pos":"NOM"} ,
		{"word":"ovocyte","word_nosc":"ovocyte","lemma":"ovocyte","pos":"NOM"} ,
		{"word":"ovocytes","word_nosc":"ovocytes","lemma":"ovocyte","pos":"NOM"} ,
		{"word":"ovulation","word_nosc":"ovulation","lemma":"ovulation","pos":"NOM"} ,
		{"word":"ovule","word_nosc":"ovule","lemma":"ovule","pos":"NOM"} ,
		{"word":"ovules","word_nosc":"ovules","lemma":"ovule","pos":"NOM"} ,
		{"word":"oxalate","word_nosc":"oxalate","lemma":"oxalate","pos":"NOM"} ,
		{"word":"oxer","word_nosc":"oxer","lemma":"oxer","pos":"NOM"} ,
		{"word":"oxfords","word_nosc":"oxfords","lemma":"oxford","pos":"NOM"} ,
		{"word":"oxonienne","word_nosc":"oxonienne","lemma":"oxonien","pos":"NOM"} ,
		{"word":"oxychlorure","word_nosc":"oxychlorure","lemma":"oxychlorure","pos":"NOM"} ,
		{"word":"oxydant","word_nosc":"oxydant","lemma":"oxydant","pos":"NOM"} ,
		{"word":"oxydants","word_nosc":"oxydants","lemma":"oxydant","pos":"NOM"} ,
		{"word":"oxydation","word_nosc":"oxydation","lemma":"oxydation","pos":"NOM"} ,
		{"word":"oxyde","word_nosc":"oxyde","lemma":"oxyde","pos":"NOM"} ,
		{"word":"oxydes","word_nosc":"oxydes","lemma":"oxyde","pos":"NOM"} ,
		{"word":"oxygène","word_nosc":"oxygene","lemma":"oxygène","pos":"NOM"} ,
		{"word":"oxygénation","word_nosc":"oxygenation","lemma":"oxygénation","pos":"NOM"} ,
		{"word":"oxymore","word_nosc":"oxymore","lemma":"oxymore","pos":"NOM"} ,
		{"word":"oxymores","word_nosc":"oxymores","lemma":"oxymore","pos":"NOM"} ,
		{"word":"oxymoron","word_nosc":"oxymoron","lemma":"oxymoron","pos":"NOM"} ,
		{"word":"oxymétrie","word_nosc":"oxymetrie","lemma":"oxymétrie","pos":"NOM"} ,
		{"word":"oyat","word_nosc":"oyat","lemma":"oyat","pos":"NOM"} ,
		{"word":"oyats","word_nosc":"oyats","lemma":"oyat","pos":"NOM"} ,
		{"word":"oye","word_nosc":"oye","lemma":"oye","pos":"NOM"} ,
		{"word":"ozone","word_nosc":"ozone","lemma":"ozone","pos":"NOM"} ,
		{"word":"oïl","word_nosc":"oil","lemma":"oïl","pos":"NOM"} ,
		{"word":"p.","word_nosc":"p.","lemma":"p.","pos":"NOM"} ,
		{"word":"p.m.","word_nosc":"p.m.","lemma":"p.m.","pos":"NOM"} ,
		{"word":"pa","word_nosc":"pa","lemma":"pa","pos":"NOM"} ,
		{"word":"pacage","word_nosc":"pacage","lemma":"pacage","pos":"NOM"} ,
		{"word":"pacages","word_nosc":"pacages","lemma":"pacage","pos":"NOM"} ,
		{"word":"pacemaker","word_nosc":"pacemaker","lemma":"pacemaker","pos":"NOM"} ,
		{"word":"pacemakers","word_nosc":"pacemakers","lemma":"pacemaker","pos":"NOM"} ,
		{"word":"pacha","word_nosc":"pacha","lemma":"pacha","pos":"NOM"} ,
		{"word":"pachas","word_nosc":"pachas","lemma":"pacha","pos":"NOM"} ,
		{"word":"pachinko","word_nosc":"pachinko","lemma":"pachinko","pos":"NOM"} ,
		{"word":"pachon","word_nosc":"pachon","lemma":"pachon","pos":"NOM"} ,
		{"word":"pachyderme","word_nosc":"pachyderme","lemma":"pachyderme","pos":"NOM"} ,
		{"word":"pachydermes","word_nosc":"pachydermes","lemma":"pachyderme","pos":"NOM"} ,
		{"word":"pacificateur","word_nosc":"pacificateur","lemma":"pacificateur","pos":"NOM"} ,
		{"word":"pacificateurs","word_nosc":"pacificateurs","lemma":"pacificateur","pos":"NOM"} ,
		{"word":"pacification","word_nosc":"pacification","lemma":"pacification","pos":"NOM"} ,
		{"word":"pacificatrice","word_nosc":"pacificatrice","lemma":"pacificateur","pos":"NOM"} ,
		{"word":"pacifique","word_nosc":"pacifique","lemma":"pacifique","pos":"NOM"} ,
		{"word":"pacifiques","word_nosc":"pacifiques","lemma":"pacifique","pos":"NOM"} ,
		{"word":"pacifisme","word_nosc":"pacifisme","lemma":"pacifisme","pos":"NOM"} ,
		{"word":"pacifiste","word_nosc":"pacifiste","lemma":"pacifiste","pos":"NOM"} ,
		{"word":"pacifistes","word_nosc":"pacifistes","lemma":"pacifiste","pos":"NOM"} ,
		{"word":"pack","word_nosc":"pack","lemma":"pack","pos":"NOM"} ,
		{"word":"package","word_nosc":"package","lemma":"package","pos":"NOM"} ,
		{"word":"packaging","word_nosc":"packaging","lemma":"packaging","pos":"NOM"} ,
		{"word":"packs","word_nosc":"packs","lemma":"pack","pos":"NOM"} ,
		{"word":"packson","word_nosc":"packson","lemma":"packson","pos":"NOM"} ,
		{"word":"pacotille","word_nosc":"pacotille","lemma":"pacotille","pos":"NOM"} ,
		{"word":"pacotilles","word_nosc":"pacotilles","lemma":"pacotille","pos":"NOM"} ,
		{"word":"pacs","word_nosc":"pacs","lemma":"pacs","pos":"NOM"} ,
		{"word":"pacsif","word_nosc":"pacsif","lemma":"pacsif","pos":"NOM"} ,
		{"word":"pacsifs","word_nosc":"pacsifs","lemma":"pacsif","pos":"NOM"} ,
		{"word":"pacson","word_nosc":"pacson","lemma":"pacson","pos":"NOM"} ,
		{"word":"pacsons","word_nosc":"pacsons","lemma":"pacson","pos":"NOM"} ,
		{"word":"pacte","word_nosc":"pacte","lemma":"pacte","pos":"NOM"} ,
		{"word":"pactes","word_nosc":"pactes","lemma":"pacte","pos":"NOM"} ,
		{"word":"pactiseurs","word_nosc":"pactiseurs","lemma":"pactiseur","pos":"NOM"} ,
		{"word":"pactole","word_nosc":"pactole","lemma":"pactole","pos":"NOM"} ,
		{"word":"pactoles","word_nosc":"pactoles","lemma":"pactole","pos":"NOM"} ,
		{"word":"pad","word_nosc":"pad","lemma":"pad","pos":"NOM"} ,
		{"word":"paddock","word_nosc":"paddock","lemma":"paddock","pos":"NOM"} ,
		{"word":"paddocks","word_nosc":"paddocks","lemma":"paddock","pos":"NOM"} ,
		{"word":"paddy","word_nosc":"paddy","lemma":"paddy","pos":"NOM"} ,
		{"word":"paella","word_nosc":"paella","lemma":"paella","pos":"NOM"} ,
		{"word":"paellas","word_nosc":"paellas","lemma":"paella","pos":"NOM"} ,
		{"word":"paf","word_nosc":"paf","lemma":"paf","pos":"NOM"} ,
		{"word":"pagaie","word_nosc":"pagaie","lemma":"pagaie","pos":"NOM"} ,
		{"word":"pagaies","word_nosc":"pagaies","lemma":"pagaie","pos":"NOM"} ,
		{"word":"pagaille","word_nosc":"pagaille","lemma":"pagaille","pos":"NOM"} ,
		{"word":"pagailles","word_nosc":"pagailles","lemma":"pagaille","pos":"NOM"} ,
		{"word":"paganisme","word_nosc":"paganisme","lemma":"paganisme","pos":"NOM"} ,
		{"word":"pagaye","word_nosc":"pagaye","lemma":"pagaye","pos":"NOM"} ,
		{"word":"pagayeur","word_nosc":"pagayeur","lemma":"pagayeur","pos":"NOM"} ,
		{"word":"pagayeurs","word_nosc":"pagayeurs","lemma":"pagayeur","pos":"NOM"} ,
		{"word":"pagaïe","word_nosc":"pagaie","lemma":"pagaïe","pos":"NOM"} ,
		{"word":"page","word_nosc":"page","lemma":"page","pos":"NOM"} ,
		{"word":"pagels","word_nosc":"pagels","lemma":"pagel","pos":"NOM"} ,
		{"word":"pageot","word_nosc":"pageot","lemma":"pageot","pos":"NOM"} ,
		{"word":"pageots","word_nosc":"pageots","lemma":"pageot","pos":"NOM"} ,
		{"word":"pages","word_nosc":"pages","lemma":"page","pos":"NOM"} ,
		{"word":"pagination","word_nosc":"pagination","lemma":"pagination","pos":"NOM"} ,
		{"word":"pagne","word_nosc":"pagne","lemma":"pagne","pos":"NOM"} ,
		{"word":"pagnes","word_nosc":"pagnes","lemma":"pagne","pos":"NOM"} ,
		{"word":"pagnon","word_nosc":"pagnon","lemma":"pagnon","pos":"NOM"} ,
		{"word":"pagode","word_nosc":"pagode","lemma":"pagode","pos":"NOM"} ,
		{"word":"pagodes","word_nosc":"pagodes","lemma":"pagode","pos":"NOM"} ,
		{"word":"pagodon","word_nosc":"pagodon","lemma":"pagodon","pos":"NOM"} ,
		{"word":"pagure","word_nosc":"pagure","lemma":"pagure","pos":"NOM"} ,
		{"word":"pagures","word_nosc":"pagures","lemma":"pagure","pos":"NOM"} ,
		{"word":"pagus","word_nosc":"pagus","lemma":"pagus","pos":"NOM"} ,
		{"word":"paie","word_nosc":"paie","lemma":"paie","pos":"NOM"} ,
		{"word":"paiement","word_nosc":"paiement","lemma":"paiement","pos":"NOM"} ,
		{"word":"paiements","word_nosc":"paiements","lemma":"paiement","pos":"NOM"} ,
		{"word":"paierie","word_nosc":"paierie","lemma":"paierie","pos":"NOM"} ,
		{"word":"paies","word_nosc":"paies","lemma":"paie","pos":"NOM"} ,
		{"word":"paillard","word_nosc":"paillard","lemma":"paillard","pos":"NOM"} ,
		{"word":"paillardes","word_nosc":"paillardes","lemma":"paillard","pos":"NOM"} ,
		{"word":"paillardise","word_nosc":"paillardise","lemma":"paillardise","pos":"NOM"} ,
		{"word":"paillasse","word_nosc":"paillasse","lemma":"paillasse","pos":"NOM"} ,
		{"word":"paillasses","word_nosc":"paillasses","lemma":"paillasse","pos":"NOM"} ,
		{"word":"paillasson","word_nosc":"paillasson","lemma":"paillasson","pos":"NOM"} ,
		{"word":"paillassons","word_nosc":"paillassons","lemma":"paillasson","pos":"NOM"} ,
		{"word":"paille","word_nosc":"paille","lemma":"paille","pos":"NOM"} ,
		{"word":"pailler","word_nosc":"pailler","lemma":"pailler","pos":"NOM"} ,
		{"word":"pailles","word_nosc":"pailles","lemma":"paille","pos":"NOM"} ,
		{"word":"paillette","word_nosc":"paillette","lemma":"paillette","pos":"NOM"} ,
		{"word":"paillettes","word_nosc":"paillettes","lemma":"paillette","pos":"NOM"} ,
		{"word":"paillis","word_nosc":"paillis","lemma":"paillis","pos":"NOM"} ,
		{"word":"paillon","word_nosc":"paillon","lemma":"paillon","pos":"NOM"} ,
		{"word":"paillons","word_nosc":"paillons","lemma":"paillon","pos":"NOM"} ,
		{"word":"paillot","word_nosc":"paillot","lemma":"paillot","pos":"NOM"} ,
		{"word":"paillote","word_nosc":"paillote","lemma":"paillote","pos":"NOM"} ,
		{"word":"paillotes","word_nosc":"paillotes","lemma":"paillote","pos":"NOM"} ,
		{"word":"paimpolaise","word_nosc":"paimpolaise","lemma":"paimpolais","pos":"NOM"} ,
		{"word":"paimpolaises","word_nosc":"paimpolaises","lemma":"paimpolais","pos":"NOM"} ,
		{"word":"pain","word_nosc":"pain","lemma":"pain","pos":"NOM"} ,
		{"word":"pains","word_nosc":"pains","lemma":"pain","pos":"NOM"} ,
		{"word":"pair","word_nosc":"pair","lemma":"pair","pos":"NOM"} ,
		{"word":"paire","word_nosc":"paire","lemma":"paire","pos":"NOM"} ,
		{"word":"paires","word_nosc":"paires","lemma":"paire","pos":"NOM"} ,
		{"word":"pairesses","word_nosc":"pairesses","lemma":"pairesse","pos":"NOM"} ,
		{"word":"pairie","word_nosc":"pairie","lemma":"pairie","pos":"NOM"} ,
		{"word":"pairs","word_nosc":"pairs","lemma":"pair","pos":"NOM"} ,
		{"word":"paiute","word_nosc":"paiute","lemma":"paiute","pos":"NOM"} ,
		{"word":"paiutes","word_nosc":"paiutes","lemma":"paiute","pos":"NOM"} ,
		{"word":"paix","word_nosc":"paix","lemma":"paix","pos":"NOM"} ,
		{"word":"pakistanais","word_nosc":"pakistanais","lemma":"pakistanais","pos":"NOM"} ,
		{"word":"pakistanaise","word_nosc":"pakistanaise","lemma":"pakistanais","pos":"NOM"} ,
		{"word":"pal","word_nosc":"pal","lemma":"pal","pos":"NOM"} ,
		{"word":"palabre","word_nosc":"palabre","lemma":"palabre","pos":"NOM"} ,
		{"word":"palabres","word_nosc":"palabres","lemma":"palabre","pos":"NOM"} ,
		{"word":"palabreur","word_nosc":"palabreur","lemma":"palabreur","pos":"NOM"} ,
		{"word":"palabreurs","word_nosc":"palabreurs","lemma":"palabreur","pos":"NOM"} ,
		{"word":"palace","word_nosc":"palace","lemma":"palace","pos":"NOM"} ,
		{"word":"palaces","word_nosc":"palaces","lemma":"palace","pos":"NOM"} ,
		{"word":"paladin","word_nosc":"paladin","lemma":"paladin","pos":"NOM"} ,
		{"word":"paladins","word_nosc":"paladins","lemma":"paladin","pos":"NOM"} ,
		{"word":"palais","word_nosc":"palais","lemma":"palais","pos":"NOM"} ,
		{"word":"palan","word_nosc":"palan","lemma":"palan","pos":"NOM"} ,
		{"word":"palanche","word_nosc":"palanche","lemma":"palanche","pos":"NOM"} ,
		{"word":"palanches","word_nosc":"palanches","lemma":"palanche","pos":"NOM"} ,
		{"word":"palangrotte","word_nosc":"palangrotte","lemma":"palangrotte","pos":"NOM"} ,
		{"word":"palanquin","word_nosc":"palanquin","lemma":"palanquin","pos":"NOM"} ,
		{"word":"palanquins","word_nosc":"palanquins","lemma":"palanquin","pos":"NOM"} ,
		{"word":"palans","word_nosc":"palans","lemma":"palan","pos":"NOM"} ,
		{"word":"palastre","word_nosc":"palastre","lemma":"palastre","pos":"NOM"} ,
		{"word":"palatin","word_nosc":"palatin","lemma":"palatin","pos":"NOM"} ,
		{"word":"palatines","word_nosc":"palatines","lemma":"palatine","pos":"NOM"} ,
		{"word":"palatisation","word_nosc":"palatisation","lemma":"palatisation","pos":"NOM"} ,
		{"word":"palazzo","word_nosc":"palazzo","lemma":"palazzo","pos":"NOM"} ,
		{"word":"pale","word_nosc":"pale","lemma":"pale","pos":"NOM"} ,
		{"word":"palefrenier","word_nosc":"palefrenier","lemma":"palefrenier","pos":"NOM"} ,
		{"word":"palefreniers","word_nosc":"palefreniers","lemma":"palefrenier","pos":"NOM"} ,
		{"word":"palefrenière","word_nosc":"palefreniere","lemma":"palefrenier","pos":"NOM"} ,
		{"word":"palefroi","word_nosc":"palefroi","lemma":"palefroi","pos":"NOM"} ,
		{"word":"palefrois","word_nosc":"palefrois","lemma":"palefroi","pos":"NOM"} ,
		{"word":"paleron","word_nosc":"paleron","lemma":"paleron","pos":"NOM"} ,
		{"word":"pales","word_nosc":"pales","lemma":"pale","pos":"NOM"} ,
		{"word":"palestinien","word_nosc":"palestinien","lemma":"palestinien","pos":"NOM"} ,
		{"word":"palestiniens","word_nosc":"palestiniens","lemma":"palestinien","pos":"NOM"} ,
		{"word":"palestre","word_nosc":"palestre","lemma":"palestre","pos":"NOM"} ,
		{"word":"palestres","word_nosc":"palestres","lemma":"palestre","pos":"NOM"} ,
		{"word":"palet","word_nosc":"palet","lemma":"palet","pos":"NOM"} ,
		{"word":"paletot","word_nosc":"paletot","lemma":"paletot","pos":"NOM"} ,
		{"word":"paletots","word_nosc":"paletots","lemma":"paletot","pos":"NOM"} ,
		{"word":"palets","word_nosc":"palets","lemma":"palet","pos":"NOM"} ,
		{"word":"palette","word_nosc":"palette","lemma":"palette","pos":"NOM"} ,
		{"word":"palettes","word_nosc":"palettes","lemma":"palette","pos":"NOM"} ,
		{"word":"palier","word_nosc":"palier","lemma":"palier","pos":"NOM"} ,
		{"word":"paliers","word_nosc":"paliers","lemma":"palier","pos":"NOM"} ,
		{"word":"palikare","word_nosc":"palikare","lemma":"palikare","pos":"NOM"} ,
		{"word":"palikares","word_nosc":"palikares","lemma":"palikare","pos":"NOM"} ,
		{"word":"palilalie","word_nosc":"palilalie","lemma":"palilalie","pos":"NOM"} ,
		{"word":"palimpseste","word_nosc":"palimpseste","lemma":"palimpseste","pos":"NOM"} ,
		{"word":"palindrome","word_nosc":"palindrome","lemma":"palindrome","pos":"NOM"} ,
		{"word":"palindromes","word_nosc":"palindromes","lemma":"palindrome","pos":"NOM"} ,
		{"word":"palinodie","word_nosc":"palinodie","lemma":"palinodie","pos":"NOM"} ,
		{"word":"palinodies","word_nosc":"palinodies","lemma":"palinodie","pos":"NOM"} ,
		{"word":"palis","word_nosc":"palis","lemma":"palis","pos":"NOM"} ,
		{"word":"palissade","word_nosc":"palissade","lemma":"palissade","pos":"NOM"} ,
		{"word":"palissades","word_nosc":"palissades","lemma":"palissade","pos":"NOM"} ,
		{"word":"palissandre","word_nosc":"palissandre","lemma":"palissandre","pos":"NOM"} ,
		{"word":"palissonnage","word_nosc":"palissonnage","lemma":"palissonnage","pos":"NOM"} ,
		{"word":"palladium","word_nosc":"palladium","lemma":"palladium","pos":"NOM"} ,
		{"word":"palle","word_nosc":"palle","lemma":"palle","pos":"NOM"} ,
		{"word":"palliatif","word_nosc":"palliatif","lemma":"palliatif","pos":"NOM"} ,
		{"word":"palliatifs","word_nosc":"palliatifs","lemma":"palliatif","pos":"NOM"} ,
		{"word":"palma-christi","word_nosc":"palma-christi","lemma":"palma-christi","pos":"NOM"} ,
		{"word":"palmarès","word_nosc":"palmares","lemma":"palmarès","pos":"NOM"} ,
		{"word":"palmas","word_nosc":"palmas","lemma":"palma","pos":"NOM"} ,
		{"word":"palme","word_nosc":"palme","lemma":"palme","pos":"NOM"} ,
		{"word":"palmer","word_nosc":"palmer","lemma":"palmer","pos":"NOM"} ,
		{"word":"palmeraie","word_nosc":"palmeraie","lemma":"palmeraie","pos":"NOM"} ,
		{"word":"palmeraies","word_nosc":"palmeraies","lemma":"palmeraie","pos":"NOM"} ,
		{"word":"palmes","word_nosc":"palmes","lemma":"palme","pos":"NOM"} ,
		{"word":"palmette","word_nosc":"palmette","lemma":"palmette","pos":"NOM"} ,
		{"word":"palmettes","word_nosc":"palmettes","lemma":"palmette","pos":"NOM"} ,
		{"word":"palmier","word_nosc":"palmier","lemma":"palmier","pos":"NOM"} ,
		{"word":"palmiers","word_nosc":"palmiers","lemma":"palmier","pos":"NOM"} ,
		{"word":"palmipède","word_nosc":"palmipede","lemma":"palmipède","pos":"NOM"} ,
		{"word":"palmipèdes","word_nosc":"palmipedes","lemma":"palmipède","pos":"NOM"} ,
		{"word":"palmiste","word_nosc":"palmiste","lemma":"palmiste","pos":"NOM"} ,
		{"word":"palmistes","word_nosc":"palmistes","lemma":"palmiste","pos":"NOM"} ,
		{"word":"palmure","word_nosc":"palmure","lemma":"palmure","pos":"NOM"} ,
		{"word":"palmures","word_nosc":"palmures","lemma":"palmure","pos":"NOM"} ,
		{"word":"palombe","word_nosc":"palombe","lemma":"palombe","pos":"NOM"} ,
		{"word":"palombes","word_nosc":"palombes","lemma":"palombe","pos":"NOM"} ,
		{"word":"palonnier","word_nosc":"palonnier","lemma":"palonnier","pos":"NOM"} ,
		{"word":"palot","word_nosc":"palot","lemma":"palot","pos":"NOM"} ,
		{"word":"palots","word_nosc":"palots","lemma":"palot","pos":"NOM"} ,
		{"word":"palourde","word_nosc":"palourde","lemma":"palourde","pos":"NOM"} ,
		{"word":"palourdes","word_nosc":"palourdes","lemma":"palourde","pos":"NOM"} ,
		{"word":"palpation","word_nosc":"palpation","lemma":"palpation","pos":"NOM"} ,
		{"word":"palpations","word_nosc":"palpations","lemma":"palpation","pos":"NOM"} ,
		{"word":"palpe","word_nosc":"palpe","lemma":"palpe","pos":"NOM"} ,
		{"word":"palpeur","word_nosc":"palpeur","lemma":"palpeur","pos":"NOM"} ,
		{"word":"palpeurs","word_nosc":"palpeurs","lemma":"palpeur","pos":"NOM"} ,
		{"word":"palpitation","word_nosc":"palpitation","lemma":"palpitation","pos":"NOM"} ,
		{"word":"palpitations","word_nosc":"palpitations","lemma":"palpitation","pos":"NOM"} ,
		{"word":"palpitement","word_nosc":"palpitement","lemma":"palpitement","pos":"NOM"} ,
		{"word":"pals","word_nosc":"pals","lemma":"pal","pos":"NOM"} ,
		{"word":"paltoquet","word_nosc":"paltoquet","lemma":"paltoquet","pos":"NOM"} ,
		{"word":"palu","word_nosc":"palu","lemma":"palu","pos":"NOM"} ,
		{"word":"paluche","word_nosc":"paluche","lemma":"paluche","pos":"NOM"} ,
		{"word":"paluches","word_nosc":"paluches","lemma":"paluche","pos":"NOM"} ,
		{"word":"paludamentum","word_nosc":"paludamentum","lemma":"paludamentum","pos":"NOM"} ,
		{"word":"paludes","word_nosc":"paludes","lemma":"palude","pos":"NOM"} ,
		{"word":"paludiers","word_nosc":"paludiers","lemma":"paludier","pos":"NOM"} ,
		{"word":"paludisme","word_nosc":"paludisme","lemma":"paludisme","pos":"NOM"} ,
		{"word":"paludière","word_nosc":"paludiere","lemma":"paludière","pos":"NOM"} ,
		{"word":"palus","word_nosc":"palus","lemma":"palus","pos":"NOM"} ,
		{"word":"palynologie","word_nosc":"palynologie","lemma":"palynologie","pos":"NOM"} ,
		{"word":"paléographie","word_nosc":"paleographie","lemma":"paléographie","pos":"NOM"} ,
		{"word":"paléolithique","word_nosc":"paleolithique","lemma":"paléolithique","pos":"NOM"} ,
		{"word":"paléontologie","word_nosc":"paleontologie","lemma":"paléontologie","pos":"NOM"} ,
		{"word":"paléontologiste","word_nosc":"paleontologiste","lemma":"paléontologiste","pos":"NOM"} ,
		{"word":"paléontologistes","word_nosc":"paleontologistes","lemma":"paléontologiste","pos":"NOM"} ,
		{"word":"paléontologue","word_nosc":"paleontologue","lemma":"paléontologue","pos":"NOM"} ,
		{"word":"paléontologues","word_nosc":"paleontologues","lemma":"paléontologue","pos":"NOM"} ,
		{"word":"paléozoïque","word_nosc":"paleozoique","lemma":"paléozoïque","pos":"NOM"} ,
		{"word":"palétuvier","word_nosc":"paletuvier","lemma":"palétuvier","pos":"NOM"} ,
		{"word":"palétuviers","word_nosc":"paletuviers","lemma":"palétuvier","pos":"NOM"} ,
		{"word":"pampa","word_nosc":"pampa","lemma":"pampa","pos":"NOM"} ,
		{"word":"pampas","word_nosc":"pampas","lemma":"pampa","pos":"NOM"} ,
		{"word":"pampero","word_nosc":"pampero","lemma":"pampero","pos":"NOM"} ,
		{"word":"pamphlet","word_nosc":"pamphlet","lemma":"pamphlet","pos":"NOM"} ,
		{"word":"pamphlets","word_nosc":"pamphlets","lemma":"pamphlet","pos":"NOM"} ,
		{"word":"pamphlétaire","word_nosc":"pamphletaire","lemma":"pamphlétaire","pos":"NOM"} ,
		{"word":"pamphlétaires","word_nosc":"pamphletaires","lemma":"pamphlétaire","pos":"NOM"} ,
		{"word":"pamplemousse","word_nosc":"pamplemousse","lemma":"pamplemousse","pos":"NOM"} ,
		{"word":"pamplemousses","word_nosc":"pamplemousses","lemma":"pamplemousse","pos":"NOM"} ,
		{"word":"pamplemoussier","word_nosc":"pamplemoussier","lemma":"pamplemoussier","pos":"NOM"} ,
		{"word":"pamplemoussiers","word_nosc":"pamplemoussiers","lemma":"pamplemoussier","pos":"NOM"} ,
		{"word":"pampre","word_nosc":"pampre","lemma":"pampre","pos":"NOM"} ,
		{"word":"pampres","word_nosc":"pampres","lemma":"pampre","pos":"NOM"} ,
		{"word":"pan","word_nosc":"pan","lemma":"pan","pos":"NOM"} ,
		{"word":"pan bagnat","word_nosc":"pan bagnat","lemma":"pan bagnat","pos":"NOM"} ,
		{"word":"panachage","word_nosc":"panachage","lemma":"panachage","pos":"NOM"} ,
		{"word":"panache","word_nosc":"panache","lemma":"panache","pos":"NOM"} ,
		{"word":"panaches","word_nosc":"panaches","lemma":"panache","pos":"NOM"} ,
		{"word":"panaché","word_nosc":"panache","lemma":"panaché","pos":"NOM"} ,
		{"word":"panachés","word_nosc":"panaches","lemma":"panaché","pos":"NOM"} ,
		{"word":"panacée","word_nosc":"panacee","lemma":"panacée","pos":"NOM"} ,
		{"word":"panacées","word_nosc":"panacees","lemma":"panacée","pos":"NOM"} ,
		{"word":"panade","word_nosc":"panade","lemma":"panade","pos":"NOM"} ,
		{"word":"panades","word_nosc":"panades","lemma":"panade","pos":"NOM"} ,
		{"word":"panais","word_nosc":"panais","lemma":"panais","pos":"NOM"} ,
		{"word":"panama","word_nosc":"panama","lemma":"panama","pos":"NOM"} ,
		{"word":"panamas","word_nosc":"panamas","lemma":"panama","pos":"NOM"} ,
		{"word":"panaméen","word_nosc":"panameen","lemma":"panaméen","pos":"NOM"} ,
		{"word":"panarabisme","word_nosc":"panarabisme","lemma":"panarabisme","pos":"NOM"} ,
		{"word":"panard","word_nosc":"panard","lemma":"panard","pos":"NOM"} ,
		{"word":"panards","word_nosc":"panards","lemma":"panard","pos":"NOM"} ,
		{"word":"panaris","word_nosc":"panaris","lemma":"panaris","pos":"NOM"} ,
		{"word":"panatella","word_nosc":"panatella","lemma":"panatella","pos":"NOM"} ,
		{"word":"panathénées","word_nosc":"panathenees","lemma":"panathénées","pos":"NOM"} ,
		{"word":"pancake","word_nosc":"pancake","lemma":"pancake","pos":"NOM"} ,
		{"word":"pancakes","word_nosc":"pancakes","lemma":"pancake","pos":"NOM"} ,
		{"word":"pancarte","word_nosc":"pancarte","lemma":"pancarte","pos":"NOM"} ,
		{"word":"pancartes","word_nosc":"pancartes","lemma":"pancarte","pos":"NOM"} ,
		{"word":"pancetta","word_nosc":"pancetta","lemma":"pancetta","pos":"NOM"} ,
		{"word":"panclastite","word_nosc":"panclastite","lemma":"panclastite","pos":"NOM"} ,
		{"word":"pancrace","word_nosc":"pancrace","lemma":"pancrace","pos":"NOM"} ,
		{"word":"pancraces","word_nosc":"pancraces","lemma":"pancrace","pos":"NOM"} ,
		{"word":"pancréas","word_nosc":"pancreas","lemma":"pancréas","pos":"NOM"} ,
		{"word":"pancréatectomie","word_nosc":"pancreatectomie","lemma":"pancréatectomie","pos":"NOM"} ,
		{"word":"pancréatite","word_nosc":"pancreatite","lemma":"pancréatite","pos":"NOM"} ,
		{"word":"panda","word_nosc":"panda","lemma":"panda","pos":"NOM"} ,
		{"word":"pandanus","word_nosc":"pandanus","lemma":"pandanus","pos":"NOM"} ,
		{"word":"pandas","word_nosc":"pandas","lemma":"panda","pos":"NOM"} ,
		{"word":"pandit","word_nosc":"pandit","lemma":"pandit","pos":"NOM"} ,
		{"word":"pandore","word_nosc":"pandore","lemma":"pandore","pos":"NOM"} ,
		{"word":"pandores","word_nosc":"pandores","lemma":"pandore","pos":"NOM"} ,
		{"word":"pandémie","word_nosc":"pandemie","lemma":"pandémie","pos":"NOM"} ,
		{"word":"pandémonium","word_nosc":"pandemonium","lemma":"pandémonium","pos":"NOM"} ,
		{"word":"pandémoniums","word_nosc":"pandemoniums","lemma":"pandémonium","pos":"NOM"} ,
		{"word":"panel","word_nosc":"panel","lemma":"panel","pos":"NOM"} ,
		{"word":"panetier","word_nosc":"panetier","lemma":"panetier","pos":"NOM"} ,
		{"word":"panetière","word_nosc":"panetiere","lemma":"panetier","pos":"NOM"} ,
		{"word":"panetières","word_nosc":"panetieres","lemma":"panetier","pos":"NOM"} ,
		{"word":"pangermanisme","word_nosc":"pangermanisme","lemma":"pangermanisme","pos":"NOM"} ,
		{"word":"panic","word_nosc":"panic","lemma":"panic","pos":"NOM"} ,
		{"word":"panicules","word_nosc":"panicules","lemma":"panicule","pos":"NOM"} ,
		{"word":"panier","word_nosc":"panier","lemma":"panier","pos":"NOM"} ,
		{"word":"panier-repas","word_nosc":"panier-repas","lemma":"panier-repas","pos":"NOM"} ,
		{"word":"paniers","word_nosc":"paniers","lemma":"panier","pos":"NOM"} ,
		{"word":"panification","word_nosc":"panification","lemma":"panification","pos":"NOM"} ,
		{"word":"panini","word_nosc":"panini","lemma":"panini","pos":"NOM"} ,
		{"word":"paniquards","word_nosc":"paniquards","lemma":"paniquard","pos":"NOM"} ,
		{"word":"panique","word_nosc":"panique","lemma":"panique","pos":"NOM"} ,
		{"word":"paniques","word_nosc":"paniques","lemma":"panique","pos":"NOM"} ,
		{"word":"panis","word_nosc":"panis","lemma":"panis","pos":"NOM"} ,
		{"word":"panière","word_nosc":"paniere","lemma":"panière","pos":"NOM"} ,
		{"word":"panières","word_nosc":"panieres","lemma":"panière","pos":"NOM"} ,
		{"word":"panka","word_nosc":"panka","lemma":"panka","pos":"NOM"} ,
		{"word":"pankas","word_nosc":"pankas","lemma":"panka","pos":"NOM"} ,
		{"word":"panne","word_nosc":"panne","lemma":"panne","pos":"NOM"} ,
		{"word":"panneau","word_nosc":"panneau","lemma":"panneau","pos":"NOM"} ,
		{"word":"panneau-réclame","word_nosc":"panneau-reclame","lemma":"panneau-réclame","pos":"NOM"} ,
		{"word":"panneaux","word_nosc":"panneaux","lemma":"panneau","pos":"NOM"} ,
		{"word":"pannes","word_nosc":"pannes","lemma":"panne","pos":"NOM"} ,
		{"word":"panneton","word_nosc":"panneton","lemma":"panneton","pos":"NOM"} ,
		{"word":"panonceau","word_nosc":"panonceau","lemma":"panonceau","pos":"NOM"} ,
		{"word":"panonceaux","word_nosc":"panonceaux","lemma":"panonceau","pos":"NOM"} ,
		{"word":"panoplie","word_nosc":"panoplie","lemma":"panoplie","pos":"NOM"} ,
		{"word":"panoplies","word_nosc":"panoplies","lemma":"panoplie","pos":"NOM"} ,
		{"word":"panorama","word_nosc":"panorama","lemma":"panorama","pos":"NOM"} ,
		{"word":"panoramas","word_nosc":"panoramas","lemma":"panorama","pos":"NOM"} ,
		{"word":"panoramique","word_nosc":"panoramique","lemma":"panoramique","pos":"NOM"} ,
		{"word":"panoramiques","word_nosc":"panoramiques","lemma":"panoramique","pos":"NOM"} ,
		{"word":"panosse","word_nosc":"panosse","lemma":"panosse","pos":"NOM"} ,
		{"word":"panouille","word_nosc":"panouille","lemma":"panouille","pos":"NOM"} ,
		{"word":"panouilles","word_nosc":"panouilles","lemma":"panouille","pos":"NOM"} ,
		{"word":"pans","word_nosc":"pans","lemma":"pan","pos":"NOM"} ,
		{"word":"pansage","word_nosc":"pansage","lemma":"pansage","pos":"NOM"} ,
		{"word":"panse","word_nosc":"panse","lemma":"panse","pos":"NOM"} ,
		{"word":"pansement","word_nosc":"pansement","lemma":"pansement","pos":"NOM"} ,
		{"word":"pansements","word_nosc":"pansements","lemma":"pansement","pos":"NOM"} ,
		{"word":"panses","word_nosc":"panses","lemma":"panse","pos":"NOM"} ,
		{"word":"panspermie","word_nosc":"panspermie","lemma":"panspermie","pos":"NOM"} ,
		{"word":"pantalon","word_nosc":"pantalon","lemma":"pantalon","pos":"NOM"} ,
		{"word":"pantalonnade","word_nosc":"pantalonnade","lemma":"pantalonnade","pos":"NOM"} ,
		{"word":"pantalonnades","word_nosc":"pantalonnades","lemma":"pantalonnade","pos":"NOM"} ,
		{"word":"pantalons","word_nosc":"pantalons","lemma":"pantalon","pos":"NOM"} ,
		{"word":"pante","word_nosc":"pante","lemma":"pante","pos":"NOM"} ,
		{"word":"pantes","word_nosc":"pantes","lemma":"pante","pos":"NOM"} ,
		{"word":"panthère","word_nosc":"panthere","lemma":"panthère","pos":"NOM"} ,
		{"word":"panthères","word_nosc":"pantheres","lemma":"panthère","pos":"NOM"} ,
		{"word":"panthéisme","word_nosc":"pantheisme","lemma":"panthéisme","pos":"NOM"} ,
		{"word":"panthéon","word_nosc":"pantheon","lemma":"panthéon","pos":"NOM"} ,
		{"word":"panthéons","word_nosc":"pantheons","lemma":"panthéon","pos":"NOM"} ,
		{"word":"pantin","word_nosc":"pantin","lemma":"pantin","pos":"NOM"} ,
		{"word":"pantins","word_nosc":"pantins","lemma":"pantin","pos":"NOM"} ,
		{"word":"pantière","word_nosc":"pantiere","lemma":"pantière","pos":"NOM"} ,
		{"word":"pantocrator","word_nosc":"pantocrator","lemma":"pantocrator","pos":"NOM"} ,
		{"word":"pantographe","word_nosc":"pantographe","lemma":"pantographe","pos":"NOM"} ,
		{"word":"pantographes","word_nosc":"pantographes","lemma":"pantographe","pos":"NOM"} ,
		{"word":"pantomime","word_nosc":"pantomime","lemma":"pantomime","pos":"NOM"} ,
		{"word":"pantomimes","word_nosc":"pantomimes","lemma":"pantomime","pos":"NOM"} ,
		{"word":"pantomètres","word_nosc":"pantometres","lemma":"pantomètre","pos":"NOM"} ,
		{"word":"pantouflard","word_nosc":"pantouflard","lemma":"pantouflard","pos":"NOM"} ,
		{"word":"pantouflards","word_nosc":"pantouflards","lemma":"pantouflard","pos":"NOM"} ,
		{"word":"pantoufle","word_nosc":"pantoufle","lemma":"pantoufle","pos":"NOM"} ,
		{"word":"pantoufles","word_nosc":"pantoufles","lemma":"pantoufle","pos":"NOM"} ,
		{"word":"panty","word_nosc":"panty","lemma":"panty","pos":"NOM"} ,
		{"word":"panurge","word_nosc":"panurge","lemma":"panurge","pos":"NOM"} ,
		{"word":"panurgisme","word_nosc":"panurgisme","lemma":"panurgisme","pos":"NOM"} ,
		{"word":"panzer","word_nosc":"panzer","lemma":"panzer","pos":"NOM"} ,
		{"word":"panzers","word_nosc":"panzers","lemma":"panzer","pos":"NOM"} ,
		{"word":"panégyrique","word_nosc":"panegyrique","lemma":"panégyrique","pos":"NOM"} ,
		{"word":"panégyriques","word_nosc":"panegyriques","lemma":"panégyrique","pos":"NOM"} ,
		{"word":"panégyristes","word_nosc":"panegyristes","lemma":"panégyriste","pos":"NOM"} ,
		{"word":"paolo","word_nosc":"paolo","lemma":"paolo","pos":"NOM"} ,
		{"word":"paon","word_nosc":"paon","lemma":"paon","pos":"NOM"} ,
		{"word":"paonne","word_nosc":"paonne","lemma":"paon","pos":"NOM"} ,
		{"word":"paons","word_nosc":"paons","lemma":"paon","pos":"NOM"} ,
		{"word":"papa","word_nosc":"papa","lemma":"papa","pos":"NOM"} ,
		{"word":"papa-cadeau","word_nosc":"papa-cadeau","lemma":"papa-cadeau","pos":"NOM"} ,
		{"word":"papamobile","word_nosc":"papamobile","lemma":"papamobile","pos":"NOM"} ,
		{"word":"paparazzi","word_nosc":"paparazzi","lemma":"paparazzi","pos":"NOM"} ,
		{"word":"paparazzis","word_nosc":"paparazzis","lemma":"paparazzi","pos":"NOM"} ,
		{"word":"paparazzo","word_nosc":"paparazzo","lemma":"paparazzo","pos":"NOM"} ,
		{"word":"papas","word_nosc":"papas","lemma":"papas","pos":"NOM"} ,
		{"word":"papauté","word_nosc":"papaute","lemma":"papauté","pos":"NOM"} ,
		{"word":"papaver","word_nosc":"papaver","lemma":"papaver","pos":"NOM"} ,
		{"word":"papavérine","word_nosc":"papaverine","lemma":"papavérine","pos":"NOM"} ,
		{"word":"papaye","word_nosc":"papaye","lemma":"papaye","pos":"NOM"} ,
		{"word":"papayer","word_nosc":"papayer","lemma":"papayer","pos":"NOM"} ,
		{"word":"papayers","word_nosc":"papayers","lemma":"papayer","pos":"NOM"} ,
		{"word":"papayes","word_nosc":"papayes","lemma":"papaye","pos":"NOM"} ,
		{"word":"pape","word_nosc":"pape","lemma":"pape","pos":"NOM"} ,
		{"word":"papegai","word_nosc":"papegai","lemma":"papegai","pos":"NOM"} ,
		{"word":"papegais","word_nosc":"papegais","lemma":"papegai","pos":"NOM"} ,
		{"word":"papelard","word_nosc":"papelard","lemma":"papelard","pos":"NOM"} ,
		{"word":"papelards","word_nosc":"papelards","lemma":"papelard","pos":"NOM"} ,
		{"word":"paperasse","word_nosc":"paperasse","lemma":"paperasse","pos":"NOM"} ,
		{"word":"paperasserie","word_nosc":"paperasserie","lemma":"paperasserie","pos":"NOM"} ,
		{"word":"paperasseries","word_nosc":"paperasseries","lemma":"paperasserie","pos":"NOM"} ,
		{"word":"paperasses","word_nosc":"paperasses","lemma":"paperasse","pos":"NOM"} ,
		{"word":"paperassier","word_nosc":"paperassier","lemma":"paperassier","pos":"NOM"} ,
		{"word":"papes","word_nosc":"papes","lemma":"pape","pos":"NOM"} ,
		{"word":"papesse","word_nosc":"papesse","lemma":"papesse","pos":"NOM"} ,
		{"word":"papet","word_nosc":"papet","lemma":"papet","pos":"NOM"} ,
		{"word":"papeterie","word_nosc":"papeterie","lemma":"papeterie","pos":"NOM"} ,
		{"word":"papeteries","word_nosc":"papeteries","lemma":"papeterie","pos":"NOM"} ,
		{"word":"papetier","word_nosc":"papetier","lemma":"papetier","pos":"NOM"} ,
		{"word":"papetiers","word_nosc":"papetiers","lemma":"papetier","pos":"NOM"} ,
		{"word":"papi","word_nosc":"papi","lemma":"papi","pos":"NOM"} ,
		{"word":"papier","word_nosc":"papier","lemma":"papier","pos":"NOM"} ,
		{"word":"papier-cadeau","word_nosc":"papier-cadeau","lemma":"papier-cadeau","pos":"NOM"} ,
		{"word":"papier-monnaie","word_nosc":"papier-monnaie","lemma":"papier-monnaie","pos":"NOM"} ,
		{"word":"papiers","word_nosc":"papiers","lemma":"papier","pos":"NOM"} ,
		{"word":"papiers-calque","word_nosc":"papiers-calque","lemma":"papier-calque","pos":"NOM"} ,
		{"word":"papille","word_nosc":"papille","lemma":"papille","pos":"NOM"} ,
		{"word":"papilles","word_nosc":"papilles","lemma":"papille","pos":"NOM"} ,
		{"word":"papillomavirus","word_nosc":"papillomavirus","lemma":"papillomavirus","pos":"NOM"} ,
		{"word":"papillomes","word_nosc":"papillomes","lemma":"papillome","pos":"NOM"} ,
		{"word":"papillon","word_nosc":"papillon","lemma":"papillon","pos":"NOM"} ,
		{"word":"papillonnage","word_nosc":"papillonnage","lemma":"papillonnage","pos":"NOM"} ,
		{"word":"papillonnement","word_nosc":"papillonnement","lemma":"papillonnement","pos":"NOM"} ,
		{"word":"papillons","word_nosc":"papillons","lemma":"papillon","pos":"NOM"} ,
		{"word":"papillote","word_nosc":"papillote","lemma":"papillote","pos":"NOM"} ,
		{"word":"papillotes","word_nosc":"papillotes","lemma":"papillote","pos":"NOM"} ,
		{"word":"papis","word_nosc":"papis","lemma":"papi","pos":"NOM"} ,
		{"word":"papisme","word_nosc":"papisme","lemma":"papisme","pos":"NOM"} ,
		{"word":"papiste","word_nosc":"papiste","lemma":"papiste","pos":"NOM"} ,
		{"word":"papistes","word_nosc":"papistes","lemma":"papiste","pos":"NOM"} ,
		{"word":"papotage","word_nosc":"papotage","lemma":"papotage","pos":"NOM"} ,
		{"word":"papotages","word_nosc":"papotages","lemma":"papotage","pos":"NOM"} ,
		{"word":"papou","word_nosc":"papou","lemma":"papou","pos":"NOM"} ,
		{"word":"papouille","word_nosc":"papouille","lemma":"papouille","pos":"NOM"} ,
		{"word":"papouilles","word_nosc":"papouilles","lemma":"papouille","pos":"NOM"} ,
		{"word":"papous","word_nosc":"papous","lemma":"papou","pos":"NOM"} ,
		{"word":"paprika","word_nosc":"paprika","lemma":"paprika","pos":"NOM"} ,
		{"word":"papy","word_nosc":"papy","lemma":"papy","pos":"NOM"} ,
		{"word":"papyrus","word_nosc":"papyrus","lemma":"papyrus","pos":"NOM"} ,
		{"word":"papys","word_nosc":"papys","lemma":"papy","pos":"NOM"} ,
		{"word":"papé","word_nosc":"pape","lemma":"papé","pos":"NOM"} ,
		{"word":"paqson","word_nosc":"paqson","lemma":"paqson","pos":"NOM"} ,
		{"word":"paquebot","word_nosc":"paquebot","lemma":"paquebot","pos":"NOM"} ,
		{"word":"paquebots","word_nosc":"paquebots","lemma":"paquebot","pos":"NOM"} ,
		{"word":"paquet","word_nosc":"paquet","lemma":"paquet","pos":"NOM"} ,
		{"word":"paquet-cadeau","word_nosc":"paquet-cadeau","lemma":"paquet-cadeau","pos":"NOM"} ,
		{"word":"paquetage","word_nosc":"paquetage","lemma":"paquetage","pos":"NOM"} ,
		{"word":"paquetages","word_nosc":"paquetages","lemma":"paquetage","pos":"NOM"} ,
		{"word":"paquets","word_nosc":"paquets","lemma":"paquet","pos":"NOM"} ,
		{"word":"paquets-cadeaux","word_nosc":"paquets-cadeaux","lemma":"paquets-cadeaux","pos":"NOM"} ,
		{"word":"par","word_nosc":"par","lemma":"par","pos":"NOM"} ,
		{"word":"para","word_nosc":"para","lemma":"para","pos":"NOM"} ,
		{"word":"para-humain","word_nosc":"para-humain","lemma":"para-humain","pos":"NOM"} ,
		{"word":"parabellum","word_nosc":"parabellum","lemma":"parabellum","pos":"NOM"} ,
		{"word":"parabellums","word_nosc":"parabellums","lemma":"parabellum","pos":"NOM"} ,
		{"word":"parabole","word_nosc":"parabole","lemma":"parabole","pos":"NOM"} ,
		{"word":"paraboles","word_nosc":"paraboles","lemma":"parabole","pos":"NOM"} ,
		{"word":"paraboloïde","word_nosc":"paraboloide","lemma":"paraboloïde","pos":"NOM"} ,
		{"word":"parachutage","word_nosc":"parachutage","lemma":"parachutage","pos":"NOM"} ,
		{"word":"parachutages","word_nosc":"parachutages","lemma":"parachutage","pos":"NOM"} ,
		{"word":"parachute","word_nosc":"parachute","lemma":"parachute","pos":"NOM"} ,
		{"word":"parachutes","word_nosc":"parachutes","lemma":"parachute","pos":"NOM"} ,
		{"word":"parachutisme","word_nosc":"parachutisme","lemma":"parachutisme","pos":"NOM"} ,
		{"word":"parachutiste","word_nosc":"parachutiste","lemma":"parachutiste","pos":"NOM"} ,
		{"word":"parachutistes","word_nosc":"parachutistes","lemma":"parachutiste","pos":"NOM"} ,
		{"word":"parachèvement","word_nosc":"parachevement","lemma":"parachèvement","pos":"NOM"} ,
		{"word":"paraclet","word_nosc":"paraclet","lemma":"paraclet","pos":"NOM"} ,
		{"word":"paracétamol","word_nosc":"paracetamol","lemma":"paracétamol","pos":"NOM"} ,
		{"word":"parade","word_nosc":"parade","lemma":"parade","pos":"NOM"} ,
		{"word":"parades","word_nosc":"parades","lemma":"parade","pos":"NOM"} ,
		{"word":"paradeurs","word_nosc":"paradeurs","lemma":"paradeur","pos":"NOM"} ,
		{"word":"paradigme","word_nosc":"paradigme","lemma":"paradigme","pos":"NOM"} ,
		{"word":"paradigmes","word_nosc":"paradigmes","lemma":"paradigme","pos":"NOM"} ,
		{"word":"paradis","word_nosc":"paradis","lemma":"paradis","pos":"NOM"} ,
		{"word":"paradisiers","word_nosc":"paradisiers","lemma":"paradisier","pos":"NOM"} ,
		{"word":"parador","word_nosc":"parador","lemma":"parador","pos":"NOM"} ,
		{"word":"parados","word_nosc":"parados","lemma":"parados","pos":"NOM"} ,
		{"word":"paradoxe","word_nosc":"paradoxe","lemma":"paradoxe","pos":"NOM"} ,
		{"word":"paradoxes","word_nosc":"paradoxes","lemma":"paradoxe","pos":"NOM"} ,
		{"word":"paradoxie","word_nosc":"paradoxie","lemma":"paradoxie","pos":"NOM"} ,
		{"word":"paraffine","word_nosc":"paraffine","lemma":"paraffine","pos":"NOM"} ,
		{"word":"parage","word_nosc":"parage","lemma":"parage","pos":"NOM"} ,
		{"word":"parages","word_nosc":"parages","lemma":"parage","pos":"NOM"} ,
		{"word":"paragraphe","word_nosc":"paragraphe","lemma":"paragraphe","pos":"NOM"} ,
		{"word":"paragraphes","word_nosc":"paragraphes","lemma":"paragraphe","pos":"NOM"} ,
		{"word":"paraguayen","word_nosc":"paraguayen","lemma":"paraguayen","pos":"NOM"} ,
		{"word":"paraguayens","word_nosc":"paraguayens","lemma":"paraguayen","pos":"NOM"} ,
		{"word":"parallaxe","word_nosc":"parallaxe","lemma":"parallaxe","pos":"NOM"} ,
		{"word":"parallaxes","word_nosc":"parallaxes","lemma":"parallaxe","pos":"NOM"} ,
		{"word":"parallèle","word_nosc":"parallele","lemma":"parallèle","pos":"NOM"} ,
		{"word":"parallèles","word_nosc":"paralleles","lemma":"parallèle","pos":"NOM"} ,
		{"word":"parallélisme","word_nosc":"parallelisme","lemma":"parallélisme","pos":"NOM"} ,
		{"word":"parallélogramme","word_nosc":"parallelogramme","lemma":"parallélogramme","pos":"NOM"} ,
		{"word":"parallélépipède","word_nosc":"parallelepipede","lemma":"parallélépipède","pos":"NOM"} ,
		{"word":"parallélépipèdes","word_nosc":"parallelepipedes","lemma":"parallélépipède","pos":"NOM"} ,
		{"word":"paralogismes","word_nosc":"paralogismes","lemma":"paralogisme","pos":"NOM"} ,
		{"word":"paralympiques","word_nosc":"paralympiques","lemma":"paralympique","pos":"NOM"} ,
		{"word":"paralysie","word_nosc":"paralysie","lemma":"paralysie","pos":"NOM"} ,
		{"word":"paralysies","word_nosc":"paralysies","lemma":"paralysie","pos":"NOM"} ,
		{"word":"paralysé","word_nosc":"paralyse","lemma":"paralysé","pos":"NOM"} ,
		{"word":"paralysée","word_nosc":"paralysee","lemma":"paralysé","pos":"NOM"} ,
		{"word":"paralysées","word_nosc":"paralysees","lemma":"paralysé","pos":"NOM"} ,
		{"word":"paralysés","word_nosc":"paralyses","lemma":"paralysé","pos":"NOM"} ,
		{"word":"paramnésie","word_nosc":"paramnesie","lemma":"paramnésie","pos":"NOM"} ,
		{"word":"paramètre","word_nosc":"parametre","lemma":"paramètre","pos":"NOM"} ,
		{"word":"paramètres","word_nosc":"parametres","lemma":"paramètre","pos":"NOM"} ,
		{"word":"parangon","word_nosc":"parangon","lemma":"parangon","pos":"NOM"} ,
		{"word":"parangonnages","word_nosc":"parangonnages","lemma":"parangonnage","pos":"NOM"} ,
		{"word":"parangons","word_nosc":"parangons","lemma":"parangon","pos":"NOM"} ,
		{"word":"parano","word_nosc":"parano","lemma":"parano","pos":"NOM"} ,
		{"word":"paranormal","word_nosc":"paranormal","lemma":"paranormal","pos":"NOM"} ,
		{"word":"paranos","word_nosc":"paranos","lemma":"parano","pos":"NOM"} ,
		{"word":"paranoïa","word_nosc":"paranoia","lemma":"paranoïa","pos":"NOM"} ,
		{"word":"paranoïaque","word_nosc":"paranoiaque","lemma":"paranoïaque","pos":"NOM"} ,
		{"word":"paranoïaques","word_nosc":"paranoiaques","lemma":"paranoïaque","pos":"NOM"} ,
		{"word":"parapente","word_nosc":"parapente","lemma":"parapente","pos":"NOM"} ,
		{"word":"parapet","word_nosc":"parapet","lemma":"parapet","pos":"NOM"} ,
		{"word":"parapets","word_nosc":"parapets","lemma":"parapet","pos":"NOM"} ,
		{"word":"parapharmacie","word_nosc":"parapharmacie","lemma":"parapharmacie","pos":"NOM"} ,
		{"word":"paraphe","word_nosc":"paraphe","lemma":"paraphe","pos":"NOM"} ,
		{"word":"paraphes","word_nosc":"paraphes","lemma":"paraphe","pos":"NOM"} ,
		{"word":"parapheurs","word_nosc":"parapheurs","lemma":"parapheur","pos":"NOM"} ,
		{"word":"paraphrase","word_nosc":"paraphrase","lemma":"paraphrase","pos":"NOM"} ,
		{"word":"paraphrases","word_nosc":"paraphrases","lemma":"paraphrase","pos":"NOM"} ,
		{"word":"parapluie","word_nosc":"parapluie","lemma":"parapluie","pos":"NOM"} ,
		{"word":"parapluies","word_nosc":"parapluies","lemma":"parapluie","pos":"NOM"} ,
		{"word":"parapsychologie","word_nosc":"parapsychologie","lemma":"parapsychologie","pos":"NOM"} ,
		{"word":"parapsychologue","word_nosc":"parapsychologue","lemma":"parapsychologue","pos":"NOM"} ,
		{"word":"parapsychologues","word_nosc":"parapsychologues","lemma":"parapsychologue","pos":"NOM"} ,
		{"word":"paras","word_nosc":"paras","lemma":"para","pos":"NOM"} ,
		{"word":"parascève","word_nosc":"parasceve","lemma":"parascève","pos":"NOM"} ,
		{"word":"parasite","word_nosc":"parasite","lemma":"parasite","pos":"NOM"} ,
		{"word":"parasites","word_nosc":"parasites","lemma":"parasite","pos":"NOM"} ,
		{"word":"parasitisme","word_nosc":"parasitisme","lemma":"parasitisme","pos":"NOM"} ,
		{"word":"parasitologie","word_nosc":"parasitologie","lemma":"parasitologie","pos":"NOM"} ,
		{"word":"parasitose","word_nosc":"parasitose","lemma":"parasitose","pos":"NOM"} ,
		{"word":"parasol","word_nosc":"parasol","lemma":"parasol","pos":"NOM"} ,
		{"word":"parasols","word_nosc":"parasols","lemma":"parasol","pos":"NOM"} ,
		{"word":"paratonnerre","word_nosc":"paratonnerre","lemma":"paratonnerre","pos":"NOM"} ,
		{"word":"paratonnerres","word_nosc":"paratonnerres","lemma":"paratonnerre","pos":"NOM"} ,
		{"word":"paravent","word_nosc":"paravent","lemma":"paravent","pos":"NOM"} ,
		{"word":"paravents","word_nosc":"paravents","lemma":"paravent","pos":"NOM"} ,
		{"word":"parc","word_nosc":"parc","lemma":"parc","pos":"NOM"} ,
		{"word":"parcage","word_nosc":"parcage","lemma":"parcage","pos":"NOM"} ,
		{"word":"parcelle","word_nosc":"parcelle","lemma":"parcelle","pos":"NOM"} ,
		{"word":"parcelles","word_nosc":"parcelles","lemma":"parcelle","pos":"NOM"} ,
		{"word":"parchemin","word_nosc":"parchemin","lemma":"parchemin","pos":"NOM"} ,
		{"word":"parchemins","word_nosc":"parchemins","lemma":"parchemin","pos":"NOM"} ,
		{"word":"parcimonie","word_nosc":"parcimonie","lemma":"parcimonie","pos":"NOM"} ,
		{"word":"parcmètre","word_nosc":"parcmetre","lemma":"parcmètre","pos":"NOM"} ,
		{"word":"parcmètres","word_nosc":"parcmetres","lemma":"parcmètre","pos":"NOM"} ,
		{"word":"parcomètre","word_nosc":"parcometre","lemma":"parcomètre","pos":"NOM"} ,
		{"word":"parcours","word_nosc":"parcours","lemma":"parcours","pos":"NOM"} ,
		{"word":"parcs","word_nosc":"parcs","lemma":"parc","pos":"NOM"} ,
		{"word":"pardessus","word_nosc":"pardessus","lemma":"pardessus","pos":"NOM"} ,
		{"word":"pardingue","word_nosc":"pardingue","lemma":"pardingue","pos":"NOM"} ,
		{"word":"pardon","word_nosc":"pardon","lemma":"pardon","pos":"NOM"} ,
		{"word":"pardons","word_nosc":"pardons","lemma":"pardon","pos":"NOM"} ,
		{"word":"pare-balle","word_nosc":"pare-balle","lemma":"pare-balle","pos":"NOM"} ,
		{"word":"pare-balles","word_nosc":"pare-balles","lemma":"pare-balles","pos":"NOM"} ,
		{"word":"pare-boue","word_nosc":"pare-boue","lemma":"pare-boue","pos":"NOM"} ,
		{"word":"pare-brise","word_nosc":"pare-brise","lemma":"pare-brise","pos":"NOM"} ,
		{"word":"pare-brises","word_nosc":"pare-brises","lemma":"pare-brise","pos":"NOM"} ,
		{"word":"pare-chocs","word_nosc":"pare-chocs","lemma":"pare-chocs","pos":"NOM"} ,
		{"word":"pare-feu","word_nosc":"pare-feu","lemma":"pare-feu","pos":"NOM"} ,
		{"word":"pare-feux","word_nosc":"pare-feux","lemma":"pare-feux","pos":"NOM"} ,
		{"word":"pare-soleil","word_nosc":"pare-soleil","lemma":"pare-soleil","pos":"NOM"} ,
		{"word":"pare-éclats","word_nosc":"pare-eclats","lemma":"pare-éclats","pos":"NOM"} ,
		{"word":"pare-étincelles","word_nosc":"pare-etincelles","lemma":"pare-étincelles","pos":"NOM"} ,
		{"word":"pareil","word_nosc":"pareil","lemma":"pareil","pos":"NOM"} ,
		{"word":"pareille","word_nosc":"pareille","lemma":"pareil","pos":"NOM"} ,
		{"word":"pareilles","word_nosc":"pareilles","lemma":"pareil","pos":"NOM"} ,
		{"word":"pareils","word_nosc":"pareils","lemma":"pareil","pos":"NOM"} ,
		{"word":"parement","word_nosc":"parement","lemma":"parement","pos":"NOM"} ,
		{"word":"parements","word_nosc":"parements","lemma":"parement","pos":"NOM"} ,
		{"word":"parenchyme","word_nosc":"parenchyme","lemma":"parenchyme","pos":"NOM"} ,
		{"word":"parent","word_nosc":"parent","lemma":"parent","pos":"NOM"} ,
		{"word":"parentage","word_nosc":"parentage","lemma":"parentage","pos":"NOM"} ,
		{"word":"parente","word_nosc":"parente","lemma":"parent","pos":"NOM"} ,
		{"word":"parentes","word_nosc":"parentes","lemma":"parent","pos":"NOM"} ,
		{"word":"parenthèse","word_nosc":"parenthese","lemma":"parenthèse","pos":"NOM"} ,
		{"word":"parenthèses","word_nosc":"parentheses","lemma":"parenthèse","pos":"NOM"} ,
		{"word":"parents","word_nosc":"parents","lemma":"parent","pos":"NOM"} ,
		{"word":"parentèle","word_nosc":"parentele","lemma":"parentèle","pos":"NOM"} ,
		{"word":"parentèles","word_nosc":"parenteles","lemma":"parentèle","pos":"NOM"} ,
		{"word":"parenté","word_nosc":"parente","lemma":"parenté","pos":"NOM"} ,
		{"word":"parentés","word_nosc":"parentes","lemma":"parenté","pos":"NOM"} ,
		{"word":"paresse","word_nosc":"paresse","lemma":"paresse","pos":"NOM"} ,
		{"word":"paresses","word_nosc":"paresses","lemma":"paresse","pos":"NOM"} ,
		{"word":"paresseuse","word_nosc":"paresseuse","lemma":"paresseux","pos":"NOM"} ,
		{"word":"paresseuses","word_nosc":"paresseuses","lemma":"paresseux","pos":"NOM"} ,
		{"word":"paresseux","word_nosc":"paresseux","lemma":"paresseux","pos":"NOM"} ,
		{"word":"paresthésie","word_nosc":"paresthesie","lemma":"paresthésie","pos":"NOM"} ,
		{"word":"pareur","word_nosc":"pareur","lemma":"pareur","pos":"NOM"} ,
		{"word":"parfait","word_nosc":"parfait","lemma":"parfait","pos":"NOM"} ,
		{"word":"parfaite","word_nosc":"parfaite","lemma":"parfait","pos":"NOM"} ,
		{"word":"parfaites","word_nosc":"parfaites","lemma":"parfait","pos":"NOM"} ,
		{"word":"parfaits","word_nosc":"parfaits","lemma":"parfait","pos":"NOM"} ,
		{"word":"parfum","word_nosc":"parfum","lemma":"parfum","pos":"NOM"} ,
		{"word":"parfumerie","word_nosc":"parfumerie","lemma":"parfumerie","pos":"NOM"} ,
		{"word":"parfumeries","word_nosc":"parfumeries","lemma":"parfumerie","pos":"NOM"} ,
		{"word":"parfumeur","word_nosc":"parfumeur","lemma":"parfumeur","pos":"NOM"} ,
		{"word":"parfumeurs","word_nosc":"parfumeurs","lemma":"parfumeur","pos":"NOM"} ,
		{"word":"parfumeuse","word_nosc":"parfumeuse","lemma":"parfumeur","pos":"NOM"} ,
		{"word":"parfums","word_nosc":"parfums","lemma":"parfum","pos":"NOM"} ,
		{"word":"pari","word_nosc":"pari","lemma":"pari","pos":"NOM"} ,
		{"word":"paria","word_nosc":"paria","lemma":"paria","pos":"NOM"} ,
		{"word":"pariade","word_nosc":"pariade","lemma":"pariade","pos":"NOM"} ,
		{"word":"parias","word_nosc":"parias","lemma":"paria","pos":"NOM"} ,
		{"word":"parieur","word_nosc":"parieur","lemma":"parieur","pos":"NOM"} ,
		{"word":"parieurs","word_nosc":"parieurs","lemma":"parieur","pos":"NOM"} ,
		{"word":"parieuse","word_nosc":"parieuse","lemma":"parieur","pos":"NOM"} ,
		{"word":"parigot","word_nosc":"parigot","lemma":"parigot","pos":"NOM"} ,
		{"word":"parigote","word_nosc":"parigote","lemma":"parigot","pos":"NOM"} ,
		{"word":"parigotes","word_nosc":"parigotes","lemma":"parigot","pos":"NOM"} ,
		{"word":"parigots","word_nosc":"parigots","lemma":"parigot","pos":"NOM"} ,
		{"word":"paris","word_nosc":"paris","lemma":"pari","pos":"NOM"} ,
		{"word":"paris-brest","word_nosc":"paris-brest","lemma":"paris-brest","pos":"NOM"} ,
		{"word":"parisien","word_nosc":"parisien","lemma":"parisien","pos":"NOM"} ,
		{"word":"parisienne","word_nosc":"parisienne","lemma":"parisien","pos":"NOM"} ,
		{"word":"parisiennes","word_nosc":"parisiennes","lemma":"parisien","pos":"NOM"} ,
		{"word":"parisiens","word_nosc":"parisiens","lemma":"parisien","pos":"NOM"} ,
		{"word":"parité","word_nosc":"parite","lemma":"parité","pos":"NOM"} ,
		{"word":"parjure","word_nosc":"parjure","lemma":"parjure","pos":"NOM"} ,
		{"word":"parjures","word_nosc":"parjures","lemma":"parjure","pos":"NOM"} ,
		{"word":"parka","word_nosc":"parka","lemma":"parka","pos":"NOM"} ,
		{"word":"parking","word_nosc":"parking","lemma":"parking","pos":"NOM"} ,
		{"word":"parkings","word_nosc":"parkings","lemma":"parking","pos":"NOM"} ,
		{"word":"parkinson","word_nosc":"parkinson","lemma":"parkinson","pos":"NOM"} ,
		{"word":"parlant","word_nosc":"parlant","lemma":"parlant","pos":"NOM"} ,
		{"word":"parlante","word_nosc":"parlante","lemma":"parlante","pos":"NOM"} ,
		{"word":"parlement","word_nosc":"parlement","lemma":"parlement","pos":"NOM"} ,
		{"word":"parlementaire","word_nosc":"parlementaire","lemma":"parlementaire","pos":"NOM"} ,
		{"word":"parlementaires","word_nosc":"parlementaires","lemma":"parlementaire","pos":"NOM"} ,
		{"word":"parlementarisme","word_nosc":"parlementarisme","lemma":"parlementarisme","pos":"NOM"} ,
		{"word":"parlements","word_nosc":"parlements","lemma":"parlement","pos":"NOM"} ,
		{"word":"parler","word_nosc":"parler","lemma":"parler","pos":"NOM"} ,
		{"word":"parlerie","word_nosc":"parlerie","lemma":"parlerie","pos":"NOM"} ,
		{"word":"parlers","word_nosc":"parlers","lemma":"parler","pos":"NOM"} ,
		{"word":"parleur","word_nosc":"parleur","lemma":"parleur","pos":"NOM"} ,
		{"word":"parleurs","word_nosc":"parleurs","lemma":"parleur","pos":"NOM"} ,
		{"word":"parleuse","word_nosc":"parleuse","lemma":"parleur","pos":"NOM"} ,
		{"word":"parloir","word_nosc":"parloir","lemma":"parloir","pos":"NOM"} ,
		{"word":"parloirs","word_nosc":"parloirs","lemma":"parloir","pos":"NOM"} ,
		{"word":"parlophone","word_nosc":"parlophone","lemma":"parlophone","pos":"NOM"} ,
		{"word":"parlote","word_nosc":"parlote","lemma":"parlote","pos":"NOM"} ,
		{"word":"parlotes","word_nosc":"parlotes","lemma":"parlote","pos":"NOM"} ,
		{"word":"parlotte","word_nosc":"parlotte","lemma":"parlotte","pos":"NOM"} ,
		{"word":"parlottes","word_nosc":"parlottes","lemma":"parlotte","pos":"NOM"} ,
		{"word":"parmentier","word_nosc":"parmentier","lemma":"parmentier","pos":"NOM"} ,
		{"word":"parmentures","word_nosc":"parmentures","lemma":"parmenture","pos":"NOM"} ,
		{"word":"parmesan","word_nosc":"parmesan","lemma":"parmesan","pos":"NOM"} ,
		{"word":"parmesans","word_nosc":"parmesans","lemma":"parmesan","pos":"NOM"} ,
		{"word":"parmélies","word_nosc":"parmelies","lemma":"parmélie","pos":"NOM"} ,
		{"word":"parodie","word_nosc":"parodie","lemma":"parodie","pos":"NOM"} ,
		{"word":"parodies","word_nosc":"parodies","lemma":"parodie","pos":"NOM"} ,
		{"word":"paroi","word_nosc":"paroi","lemma":"paroi","pos":"NOM"} ,
		{"word":"paroir","word_nosc":"paroir","lemma":"paroir","pos":"NOM"} ,
		{"word":"paroirs","word_nosc":"paroirs","lemma":"paroir","pos":"NOM"} ,
		{"word":"parois","word_nosc":"parois","lemma":"paroi","pos":"NOM"} ,
		{"word":"paroisse","word_nosc":"paroisse","lemma":"paroisse","pos":"NOM"} ,
		{"word":"paroisses","word_nosc":"paroisses","lemma":"paroisse","pos":"NOM"} ,
		{"word":"paroissien","word_nosc":"paroissien","lemma":"paroissien","pos":"NOM"} ,
		{"word":"paroissienne","word_nosc":"paroissienne","lemma":"paroissien","pos":"NOM"} ,
		{"word":"paroissiennes","word_nosc":"paroissiennes","lemma":"paroissien","pos":"NOM"} ,
		{"word":"paroissiens","word_nosc":"paroissiens","lemma":"paroissien","pos":"NOM"} ,
		{"word":"parole","word_nosc":"parole","lemma":"parole","pos":"NOM"} ,
		{"word":"paroles","word_nosc":"paroles","lemma":"parole","pos":"NOM"} ,
		{"word":"paroli","word_nosc":"paroli","lemma":"paroli","pos":"NOM"} ,
		{"word":"parolier","word_nosc":"parolier","lemma":"parolier","pos":"NOM"} ,
		{"word":"parolière","word_nosc":"paroliere","lemma":"parolier","pos":"NOM"} ,
		{"word":"paronomasie","word_nosc":"paronomasie","lemma":"paronomasie","pos":"NOM"} ,
		{"word":"paros","word_nosc":"paros","lemma":"paros","pos":"NOM"} ,
		{"word":"parousie","word_nosc":"parousie","lemma":"parousie","pos":"NOM"} ,
		{"word":"paroxysme","word_nosc":"paroxysme","lemma":"paroxysme","pos":"NOM"} ,
		{"word":"paroxysmes","word_nosc":"paroxysmes","lemma":"paroxysme","pos":"NOM"} ,
		{"word":"parpaillot","word_nosc":"parpaillot","lemma":"parpaillot","pos":"NOM"} ,
		{"word":"parpaillote","word_nosc":"parpaillote","lemma":"parpaillot","pos":"NOM"} ,
		{"word":"parpaillotes","word_nosc":"parpaillotes","lemma":"parpaillot","pos":"NOM"} ,
		{"word":"parpaing","word_nosc":"parpaing","lemma":"parpaing","pos":"NOM"} ,
		{"word":"parpaings","word_nosc":"parpaings","lemma":"parpaing","pos":"NOM"} ,
		{"word":"parque","word_nosc":"parque","lemma":"parque","pos":"NOM"} ,
		{"word":"parquet","word_nosc":"parquet","lemma":"parquet","pos":"NOM"} ,
		{"word":"parquetage","word_nosc":"parquetage","lemma":"parquetage","pos":"NOM"} ,
		{"word":"parqueteur","word_nosc":"parqueteur","lemma":"parqueteur","pos":"NOM"} ,
		{"word":"parqueteuse","word_nosc":"parqueteuse","lemma":"parqueteur","pos":"NOM"} ,
		{"word":"parquets","word_nosc":"parquets","lemma":"parquet","pos":"NOM"} ,
		{"word":"parr","word_nosc":"parr","lemma":"parr","pos":"NOM"} ,
		{"word":"parrain","word_nosc":"parrain","lemma":"parrain","pos":"NOM"} ,
		{"word":"parrainage","word_nosc":"parrainage","lemma":"parrainage","pos":"NOM"} ,
		{"word":"parraineuse","word_nosc":"parraineuse","lemma":"parraineur","pos":"NOM"} ,
		{"word":"parrains","word_nosc":"parrains","lemma":"parrain","pos":"NOM"} ,
		{"word":"parricide","word_nosc":"parricide","lemma":"parricide","pos":"NOM"} ,
		{"word":"pars","word_nosc":"pars","lemma":"pars","pos":"NOM"} ,
		{"word":"parsec","word_nosc":"parsec","lemma":"parsec","pos":"NOM"} ,
		{"word":"parsecs","word_nosc":"parsecs","lemma":"parsec","pos":"NOM"} ,
		{"word":"parsi","word_nosc":"parsi","lemma":"parsi","pos":"NOM"} ,
		{"word":"parsis","word_nosc":"parsis","lemma":"parsi","pos":"NOM"} ,
		{"word":"part","word_nosc":"part","lemma":"part","pos":"NOM"} ,
		{"word":"part time","word_nosc":"part time","lemma":"part time","pos":"NOM"} ,
		{"word":"partage","word_nosc":"partage","lemma":"partage","pos":"NOM"} ,
		{"word":"partages","word_nosc":"partages","lemma":"partage","pos":"NOM"} ,
		{"word":"partageux","word_nosc":"partageux","lemma":"partageux","pos":"NOM"} ,
		{"word":"partance","word_nosc":"partance","lemma":"partance","pos":"NOM"} ,
		{"word":"partant","word_nosc":"partant","lemma":"partant","pos":"NOM"} ,
		{"word":"partants","word_nosc":"partants","lemma":"partant","pos":"NOM"} ,
		{"word":"partenaire","word_nosc":"partenaire","lemma":"partenaire","pos":"NOM"} ,
		{"word":"partenaire-robot","word_nosc":"partenaire-robot","lemma":"partenaire-robot","pos":"NOM"} ,
		{"word":"partenaires","word_nosc":"partenaires","lemma":"partenaire","pos":"NOM"} ,
		{"word":"partenariat","word_nosc":"partenariat","lemma":"partenariat","pos":"NOM"} ,
		{"word":"parterre","word_nosc":"parterre","lemma":"parterre","pos":"NOM"} ,
		{"word":"parterres","word_nosc":"parterres","lemma":"parterre","pos":"NOM"} ,
		{"word":"parthénogenèse","word_nosc":"parthenogenese","lemma":"parthénogenèse","pos":"NOM"} ,
		{"word":"parthénogénèse","word_nosc":"parthenogenese","lemma":"parthénogénèse","pos":"NOM"} ,
		{"word":"parti","word_nosc":"parti","lemma":"parti","pos":"NOM"} ,
		{"word":"partialité","word_nosc":"partialite","lemma":"partialité","pos":"NOM"} ,
		{"word":"participant","word_nosc":"participant","lemma":"participant","pos":"NOM"} ,
		{"word":"participante","word_nosc":"participante","lemma":"participant","pos":"NOM"} ,
		{"word":"participantes","word_nosc":"participantes","lemma":"participant","pos":"NOM"} ,
		{"word":"participants","word_nosc":"participants","lemma":"participant","pos":"NOM"} ,
		{"word":"participation","word_nosc":"participation","lemma":"participation","pos":"NOM"} ,
		{"word":"participations","word_nosc":"participations","lemma":"participation","pos":"NOM"} ,
		{"word":"participe","word_nosc":"participe","lemma":"participe","pos":"NOM"} ,
		{"word":"participes","word_nosc":"participes","lemma":"participe","pos":"NOM"} ,
		{"word":"particularisme","word_nosc":"particularisme","lemma":"particularisme","pos":"NOM"} ,
		{"word":"particularismes","word_nosc":"particularismes","lemma":"particularisme","pos":"NOM"} ,
		{"word":"particularité","word_nosc":"particularite","lemma":"particularité","pos":"NOM"} ,
		{"word":"particularités","word_nosc":"particularites","lemma":"particularité","pos":"NOM"} ,
		{"word":"particule","word_nosc":"particule","lemma":"particule","pos":"NOM"} ,
		{"word":"particules","word_nosc":"particules","lemma":"particule","pos":"NOM"} ,
		{"word":"particulier","word_nosc":"particulier","lemma":"particulier","pos":"NOM"} ,
		{"word":"particuliers","word_nosc":"particuliers","lemma":"particulier","pos":"NOM"} ,
		{"word":"particulière","word_nosc":"particuliere","lemma":"particulier","pos":"NOM"} ,
		{"word":"particulières","word_nosc":"particulieres","lemma":"particulier","pos":"NOM"} ,
		{"word":"partie","word_nosc":"partie","lemma":"partie","pos":"NOM"} ,
		{"word":"partiel","word_nosc":"partiel","lemma":"partiel","pos":"NOM"} ,
		{"word":"partielle","word_nosc":"partielle","lemma":"partiel","pos":"NOM"} ,
		{"word":"partielles","word_nosc":"partielles","lemma":"partiel","pos":"NOM"} ,
		{"word":"partiels","word_nosc":"partiels","lemma":"partiel","pos":"NOM"} ,
		{"word":"parties","word_nosc":"parties","lemma":"partie","pos":"NOM"} ,
		{"word":"partis","word_nosc":"partis","lemma":"parti","pos":"NOM"} ,
		{"word":"partisan","word_nosc":"partisan","lemma":"partisan","pos":"NOM"} ,
		{"word":"partisane","word_nosc":"partisane","lemma":"partisan","pos":"NOM"} ,
		{"word":"partisans","word_nosc":"partisans","lemma":"partisan","pos":"NOM"} ,
		{"word":"partita","word_nosc":"partita","lemma":"partita","pos":"NOM"} ,
		{"word":"partitas","word_nosc":"partitas","lemma":"partita","pos":"NOM"} ,
		{"word":"partition","word_nosc":"partition","lemma":"partition","pos":"NOM"} ,
		{"word":"partitions","word_nosc":"partitions","lemma":"partition","pos":"NOM"} ,
		{"word":"partner","word_nosc":"partner","lemma":"partner","pos":"NOM"} ,
		{"word":"partouse","word_nosc":"partouse","lemma":"partouse","pos":"NOM"} ,
		{"word":"partouzards","word_nosc":"partouzards","lemma":"partouzard","pos":"NOM"} ,
		{"word":"partouze","word_nosc":"partouze","lemma":"partouze","pos":"NOM"} ,
		{"word":"partouzes","word_nosc":"partouzes","lemma":"partouze","pos":"NOM"} ,
		{"word":"partouzeur","word_nosc":"partouzeur","lemma":"partouzeur","pos":"NOM"} ,
		{"word":"partouzeurs","word_nosc":"partouzeurs","lemma":"partouzeur","pos":"NOM"} ,
		{"word":"parts","word_nosc":"parts","lemma":"part","pos":"NOM"} ,
		{"word":"parturiente","word_nosc":"parturiente","lemma":"parturiente","pos":"NOM"} ,
		{"word":"parturientes","word_nosc":"parturientes","lemma":"parturiente","pos":"NOM"} ,
		{"word":"parturition","word_nosc":"parturition","lemma":"parturition","pos":"NOM"} ,
		{"word":"parturitions","word_nosc":"parturitions","lemma":"parturition","pos":"NOM"} ,
		{"word":"party","word_nosc":"party","lemma":"party","pos":"NOM"} ,
		{"word":"parure","word_nosc":"parure","lemma":"parure","pos":"NOM"} ,
		{"word":"parures","word_nosc":"parures","lemma":"parure","pos":"NOM"} ,
		{"word":"parution","word_nosc":"parution","lemma":"parution","pos":"NOM"} ,
		{"word":"parvenu","word_nosc":"parvenu","lemma":"parvenu","pos":"NOM"} ,
		{"word":"parvenue","word_nosc":"parvenue","lemma":"parvenu","pos":"NOM"} ,
		{"word":"parvenues","word_nosc":"parvenues","lemma":"parvenu","pos":"NOM"} ,
		{"word":"parvenus","word_nosc":"parvenus","lemma":"parvenu","pos":"NOM"} ,
		{"word":"parvis","word_nosc":"parvis","lemma":"parvis","pos":"NOM"} ,
		{"word":"parâtre","word_nosc":"paratre","lemma":"parâtre","pos":"NOM"} ,
		{"word":"paréo","word_nosc":"pareo","lemma":"paréo","pos":"NOM"} ,
		{"word":"paréos","word_nosc":"pareos","lemma":"paréo","pos":"NOM"} ,
		{"word":"parésie","word_nosc":"paresie","lemma":"parésie","pos":"NOM"} ,
		{"word":"pas","word_nosc":"pas","lemma":"pas","pos":"NOM"} ,
		{"word":"pas-de-porte","word_nosc":"pas-de-porte","lemma":"pas-de-porte","pos":"NOM"} ,
		{"word":"pascal","word_nosc":"pascal","lemma":"pascal","pos":"NOM"} ,
		{"word":"paseo","word_nosc":"paseo","lemma":"paseo","pos":"NOM"} ,
		{"word":"pasionaria","word_nosc":"pasionaria","lemma":"pasionaria","pos":"NOM"} ,
		{"word":"pasionarias","word_nosc":"pasionarias","lemma":"pasionaria","pos":"NOM"} ,
		{"word":"paso","word_nosc":"paso","lemma":"paso","pos":"NOM"} ,
		{"word":"paso doble","word_nosc":"paso doble","lemma":"paso doble","pos":"NOM"} ,
		{"word":"paso-doble","word_nosc":"paso-doble","lemma":"paso-doble","pos":"NOM"} ,
		{"word":"pasos","word_nosc":"pasos","lemma":"paso","pos":"NOM"} ,
		{"word":"pasquins","word_nosc":"pasquins","lemma":"pasquin","pos":"NOM"} ,
		{"word":"passade","word_nosc":"passade","lemma":"passade","pos":"NOM"} ,
		{"word":"passades","word_nosc":"passades","lemma":"passade","pos":"NOM"} ,
		{"word":"passage","word_nosc":"passage","lemma":"passage","pos":"NOM"} ,
		{"word":"passager","word_nosc":"passager","lemma":"passager","pos":"NOM"} ,
		{"word":"passagers","word_nosc":"passagers","lemma":"passager","pos":"NOM"} ,
		{"word":"passages","word_nosc":"passages","lemma":"passage","pos":"NOM"} ,
		{"word":"passagère","word_nosc":"passagere","lemma":"passager","pos":"NOM"} ,
		{"word":"passagères","word_nosc":"passageres","lemma":"passager","pos":"NOM"} ,
		{"word":"passant","word_nosc":"passant","lemma":"passant","pos":"NOM"} ,
		{"word":"passante","word_nosc":"passante","lemma":"passant","pos":"NOM"} ,
		{"word":"passantes","word_nosc":"passantes","lemma":"passant","pos":"NOM"} ,
		{"word":"passants","word_nosc":"passants","lemma":"passant","pos":"NOM"} ,
		{"word":"passation","word_nosc":"passation","lemma":"passation","pos":"NOM"} ,
		{"word":"passavant","word_nosc":"passavant","lemma":"passavant","pos":"NOM"} ,
		{"word":"passe","word_nosc":"passe","lemma":"passe","pos":"NOM"} ,
		{"word":"passe-boule","word_nosc":"passe-boule","lemma":"passe-boule","pos":"NOM"} ,
		{"word":"passe-boules","word_nosc":"passe-boules","lemma":"passe-boules","pos":"NOM"} ,
		{"word":"passe-crassane","word_nosc":"passe-crassane","lemma":"passe-crassane","pos":"NOM"} ,
		{"word":"passe-droit","word_nosc":"passe-droit","lemma":"passe-droit","pos":"NOM"} ,
		{"word":"passe-droits","word_nosc":"passe-droits","lemma":"passe-droit","pos":"NOM"} ,
		{"word":"passe-la-moi","word_nosc":"passe-la-moi","lemma":"passe-la-moi","pos":"NOM"} ,
		{"word":"passe-lacet","word_nosc":"passe-lacet","lemma":"passe-lacet","pos":"NOM"} ,
		{"word":"passe-lacets","word_nosc":"passe-lacets","lemma":"passe-lacet","pos":"NOM"} ,
		{"word":"passe-montagne","word_nosc":"passe-montagne","lemma":"passe-montagne","pos":"NOM"} ,
		{"word":"passe-montagnes","word_nosc":"passe-montagnes","lemma":"passe-montagne","pos":"NOM"} ,
		{"word":"passe-muraille","word_nosc":"passe-muraille","lemma":"passe-muraille","pos":"NOM"} ,
		{"word":"passe-partout","word_nosc":"passe-partout","lemma":"passe-partout","pos":"NOM"} ,
		{"word":"passe-passe","word_nosc":"passe-passe","lemma":"passe-passe","pos":"NOM"} ,
		{"word":"passe-pied","word_nosc":"passe-pied","lemma":"passe-pied","pos":"NOM"} ,
		{"word":"passe-plat","word_nosc":"passe-plat","lemma":"passe-plat","pos":"NOM"} ,
		{"word":"passe-roses","word_nosc":"passe-roses","lemma":"passe-rose","pos":"NOM"} ,
		{"word":"passe-temps","word_nosc":"passe-temps","lemma":"passe-temps","pos":"NOM"} ,
		{"word":"passement","word_nosc":"passement","lemma":"passement","pos":"NOM"} ,
		{"word":"passementerie","word_nosc":"passementerie","lemma":"passementerie","pos":"NOM"} ,
		{"word":"passementeries","word_nosc":"passementeries","lemma":"passementerie","pos":"NOM"} ,
		{"word":"passementier","word_nosc":"passementier","lemma":"passementier","pos":"NOM"} ,
		{"word":"passementière","word_nosc":"passementiere","lemma":"passementier","pos":"NOM"} ,
		{"word":"passepoil","word_nosc":"passepoil","lemma":"passepoil","pos":"NOM"} ,
		{"word":"passeport","word_nosc":"passeport","lemma":"passeport","pos":"NOM"} ,
		{"word":"passeports","word_nosc":"passeports","lemma":"passeport","pos":"NOM"} ,
		{"word":"passereau","word_nosc":"passereau","lemma":"passereau","pos":"NOM"} ,
		{"word":"passereaux","word_nosc":"passereaux","lemma":"passereau","pos":"NOM"} ,
		{"word":"passerelle","word_nosc":"passerelle","lemma":"passerelle","pos":"NOM"} ,
		{"word":"passerelles","word_nosc":"passerelles","lemma":"passerelle","pos":"NOM"} ,
		{"word":"passeroses","word_nosc":"passeroses","lemma":"passerose","pos":"NOM"} ,
		{"word":"passes","word_nosc":"passes","lemma":"passe","pos":"NOM"} ,
		{"word":"passeur","word_nosc":"passeur","lemma":"passeur","pos":"NOM"} ,
		{"word":"passeurs","word_nosc":"passeurs","lemma":"passeur","pos":"NOM"} ,
		{"word":"passeuse","word_nosc":"passeuse","lemma":"passeur","pos":"NOM"} ,
		{"word":"passif","word_nosc":"passif","lemma":"passif","pos":"NOM"} ,
		{"word":"passiflore","word_nosc":"passiflore","lemma":"passiflore","pos":"NOM"} ,
		{"word":"passifs","word_nosc":"passifs","lemma":"passif","pos":"NOM"} ,
		{"word":"passing","word_nosc":"passing","lemma":"passing","pos":"NOM"} ,
		{"word":"passion","word_nosc":"passion","lemma":"passion","pos":"NOM"} ,
		{"word":"passionné","word_nosc":"passionne","lemma":"passionné","pos":"NOM"} ,
		{"word":"passionnés","word_nosc":"passionnes","lemma":"passionné","pos":"NOM"} ,
		{"word":"passions","word_nosc":"passions","lemma":"passion","pos":"NOM"} ,
		{"word":"passiveté","word_nosc":"passivete","lemma":"passiveté","pos":"NOM"} ,
		{"word":"passivité","word_nosc":"passivite","lemma":"passivité","pos":"NOM"} ,
		{"word":"passoire","word_nosc":"passoire","lemma":"passoire","pos":"NOM"} ,
		{"word":"passoires","word_nosc":"passoires","lemma":"passoire","pos":"NOM"} ,
		{"word":"passé","word_nosc":"passe","lemma":"passé","pos":"NOM"} ,
		{"word":"passés","word_nosc":"passes","lemma":"passé","pos":"NOM"} ,
		{"word":"past","word_nosc":"past","lemma":"past","pos":"NOM"} ,
		{"word":"pastaga","word_nosc":"pastaga","lemma":"pastaga","pos":"NOM"} ,
		{"word":"pastagas","word_nosc":"pastagas","lemma":"pastaga","pos":"NOM"} ,
		{"word":"pastel","word_nosc":"pastel","lemma":"pastel","pos":"NOM"} ,
		{"word":"pastels","word_nosc":"pastels","lemma":"pastel","pos":"NOM"} ,
		{"word":"pastenague","word_nosc":"pastenague","lemma":"pastenague","pos":"NOM"} ,
		{"word":"pasteur","word_nosc":"pasteur","lemma":"pasteur","pos":"NOM"} ,
		{"word":"pasteurella","word_nosc":"pasteurella","lemma":"pasteurella","pos":"NOM"} ,
		{"word":"pasteurisation","word_nosc":"pasteurisation","lemma":"pasteurisation","pos":"NOM"} ,
		{"word":"pasteurs","word_nosc":"pasteurs","lemma":"pasteur","pos":"NOM"} ,
		{"word":"pastiche","word_nosc":"pastiche","lemma":"pastiche","pos":"NOM"} ,
		{"word":"pastiches","word_nosc":"pastiches","lemma":"pastiche","pos":"NOM"} ,
		{"word":"pasticheur","word_nosc":"pasticheur","lemma":"pasticheur","pos":"NOM"} ,
		{"word":"pastille","word_nosc":"pastille","lemma":"pastille","pos":"NOM"} ,
		{"word":"pastilles","word_nosc":"pastilles","lemma":"pastille","pos":"NOM"} ,
		{"word":"pastis","word_nosc":"pastis","lemma":"pastis","pos":"NOM"} ,
		{"word":"pastophore","word_nosc":"pastophore","lemma":"pastophore","pos":"NOM"} ,
		{"word":"pastorale","word_nosc":"pastorale","lemma":"pastorale","pos":"NOM"} ,
		{"word":"pastorales","word_nosc":"pastorales","lemma":"pastorale","pos":"NOM"} ,
		{"word":"pastour","word_nosc":"pastour","lemma":"pastour","pos":"NOM"} ,
		{"word":"pastoureau","word_nosc":"pastoureau","lemma":"pastoureau","pos":"NOM"} ,
		{"word":"pastoureaux","word_nosc":"pastoureaux","lemma":"pastoureau","pos":"NOM"} ,
		{"word":"pastourelle","word_nosc":"pastourelle","lemma":"pastoureau","pos":"NOM"} ,
		{"word":"pastourelles","word_nosc":"pastourelles","lemma":"pastoureau","pos":"NOM"} ,
		{"word":"pastèque","word_nosc":"pasteque","lemma":"pastèque","pos":"NOM"} ,
		{"word":"pastèques","word_nosc":"pasteques","lemma":"pastèque","pos":"NOM"} ,
		{"word":"pat","word_nosc":"pat","lemma":"pat","pos":"NOM"} ,
		{"word":"patache","word_nosc":"patache","lemma":"patache","pos":"NOM"} ,
		{"word":"patachon","word_nosc":"patachon","lemma":"patachon","pos":"NOM"} ,
		{"word":"patagons","word_nosc":"patagons","lemma":"patagon","pos":"NOM"} ,
		{"word":"patapouf","word_nosc":"patapouf","lemma":"patapouf","pos":"NOM"} ,
		{"word":"patapoufs","word_nosc":"patapoufs","lemma":"patapouf","pos":"NOM"} ,
		{"word":"pataquès","word_nosc":"pataques","lemma":"pataquès","pos":"NOM"} ,
		{"word":"patarin","word_nosc":"patarin","lemma":"patarin","pos":"NOM"} ,
		{"word":"patate","word_nosc":"patate","lemma":"patate","pos":"NOM"} ,
		{"word":"patates","word_nosc":"patates","lemma":"patate","pos":"NOM"} ,
		{"word":"pataud","word_nosc":"pataud","lemma":"pataud","pos":"NOM"} ,
		{"word":"pataugas","word_nosc":"pataugas","lemma":"pataugas","pos":"NOM"} ,
		{"word":"pataugeoire","word_nosc":"pataugeoire","lemma":"pataugeoire","pos":"NOM"} ,
		{"word":"pataugis","word_nosc":"pataugis","lemma":"pataugis","pos":"NOM"} ,
		{"word":"patch","word_nosc":"patch","lemma":"patch","pos":"NOM"} ,
		{"word":"patches","word_nosc":"patches","lemma":"patch","pos":"NOM"} ,
		{"word":"patchouli","word_nosc":"patchouli","lemma":"patchouli","pos":"NOM"} ,
		{"word":"patchs","word_nosc":"patchs","lemma":"patch","pos":"NOM"} ,
		{"word":"patchwork","word_nosc":"patchwork","lemma":"patchwork","pos":"NOM"} ,
		{"word":"patelin","word_nosc":"patelin","lemma":"patelin","pos":"NOM"} ,
		{"word":"patelins","word_nosc":"patelins","lemma":"patelin","pos":"NOM"} ,
		{"word":"patelles","word_nosc":"patelles","lemma":"patelle","pos":"NOM"} ,
		{"word":"patente","word_nosc":"patente","lemma":"patente","pos":"NOM"} ,
		{"word":"patentes","word_nosc":"patentes","lemma":"patente","pos":"NOM"} ,
		{"word":"patenôtres","word_nosc":"patenotres","lemma":"patenôtre","pos":"NOM"} ,
		{"word":"pater","word_nosc":"pater","lemma":"pater","pos":"NOM"} ,
		{"word":"pater familias","word_nosc":"pater familias","lemma":"pater familias","pos":"NOM"} ,
		{"word":"pater noster","word_nosc":"pater noster","lemma":"pater noster","pos":"NOM"} ,
		{"word":"paternalisme","word_nosc":"paternalisme","lemma":"paternalisme","pos":"NOM"} ,
		{"word":"paternel","word_nosc":"paternel","lemma":"paternel","pos":"NOM"} ,
		{"word":"paternels","word_nosc":"paternels","lemma":"paternel","pos":"NOM"} ,
		{"word":"paternité","word_nosc":"paternite","lemma":"paternité","pos":"NOM"} ,
		{"word":"paternités","word_nosc":"paternites","lemma":"paternité","pos":"NOM"} ,
		{"word":"pathologie","word_nosc":"pathologie","lemma":"pathologie","pos":"NOM"} ,
		{"word":"pathologiste","word_nosc":"pathologiste","lemma":"pathologiste","pos":"NOM"} ,
		{"word":"pathos","word_nosc":"pathos","lemma":"pathos","pos":"NOM"} ,
		{"word":"pathétique","word_nosc":"pathetique","lemma":"pathétique","pos":"NOM"} ,
		{"word":"pathétiques","word_nosc":"pathetiques","lemma":"pathétique","pos":"NOM"} ,
		{"word":"pathétisme","word_nosc":"pathetisme","lemma":"pathétisme","pos":"NOM"} ,
		{"word":"patience","word_nosc":"patience","lemma":"patience","pos":"NOM"} ,
		{"word":"patiences","word_nosc":"patiences","lemma":"patience","pos":"NOM"} ,
		{"word":"patient","word_nosc":"patient","lemma":"patient","pos":"NOM"} ,
		{"word":"patiente","word_nosc":"patiente","lemma":"patient","pos":"NOM"} ,
		{"word":"patientes","word_nosc":"patientes","lemma":"patient","pos":"NOM"} ,
		{"word":"patients","word_nosc":"patients","lemma":"patient","pos":"NOM"} ,
		{"word":"patin","word_nosc":"patin","lemma":"patin","pos":"NOM"} ,
		{"word":"patinage","word_nosc":"patinage","lemma":"patinage","pos":"NOM"} ,
		{"word":"patine","word_nosc":"patine","lemma":"patine","pos":"NOM"} ,
		{"word":"patines","word_nosc":"patines","lemma":"patine","pos":"NOM"} ,
		{"word":"patinette","word_nosc":"patinette","lemma":"patinette","pos":"NOM"} ,
		{"word":"patinettes","word_nosc":"patinettes","lemma":"patinette","pos":"NOM"} ,
		{"word":"patineur","word_nosc":"patineur","lemma":"patineur","pos":"NOM"} ,
		{"word":"patineurs","word_nosc":"patineurs","lemma":"patineur","pos":"NOM"} ,
		{"word":"patineuse","word_nosc":"patineuse","lemma":"patineur","pos":"NOM"} ,
		{"word":"patineuses","word_nosc":"patineuses","lemma":"patineur","pos":"NOM"} ,
		{"word":"patinoire","word_nosc":"patinoire","lemma":"patinoire","pos":"NOM"} ,
		{"word":"patinoires","word_nosc":"patinoires","lemma":"patinoire","pos":"NOM"} ,
		{"word":"patins","word_nosc":"patins","lemma":"patin","pos":"NOM"} ,
		{"word":"patio","word_nosc":"patio","lemma":"patio","pos":"NOM"} ,
		{"word":"patios","word_nosc":"patios","lemma":"patio","pos":"NOM"} ,
		{"word":"patoche","word_nosc":"patoche","lemma":"patoche","pos":"NOM"} ,
		{"word":"patoches","word_nosc":"patoches","lemma":"patoche","pos":"NOM"} ,
		{"word":"patois","word_nosc":"patois","lemma":"patois","pos":"NOM"} ,
		{"word":"patouillard","word_nosc":"patouillard","lemma":"patouillard","pos":"NOM"} ,
		{"word":"patriarcat","word_nosc":"patriarcat","lemma":"patriarcat","pos":"NOM"} ,
		{"word":"patriarcats","word_nosc":"patriarcats","lemma":"patriarcat","pos":"NOM"} ,
		{"word":"patriarche","word_nosc":"patriarche","lemma":"patriarche","pos":"NOM"} ,
		{"word":"patriarches","word_nosc":"patriarches","lemma":"patriarche","pos":"NOM"} ,
		{"word":"patriarchie","word_nosc":"patriarchie","lemma":"patriarchie","pos":"NOM"} ,
		{"word":"patriciat","word_nosc":"patriciat","lemma":"patriciat","pos":"NOM"} ,
		{"word":"patricien","word_nosc":"patricien","lemma":"patricien","pos":"NOM"} ,
		{"word":"patricienne","word_nosc":"patricienne","lemma":"patricien","pos":"NOM"} ,
		{"word":"patriciennes","word_nosc":"patriciennes","lemma":"patricien","pos":"NOM"} ,
		{"word":"patriciens","word_nosc":"patriciens","lemma":"patricien","pos":"NOM"} ,
		{"word":"patrie","word_nosc":"patrie","lemma":"patrie","pos":"NOM"} ,
		{"word":"patries","word_nosc":"patries","lemma":"patrie","pos":"NOM"} ,
		{"word":"patrimoine","word_nosc":"patrimoine","lemma":"patrimoine","pos":"NOM"} ,
		{"word":"patrimoines","word_nosc":"patrimoines","lemma":"patrimoine","pos":"NOM"} ,
		{"word":"patriotards","word_nosc":"patriotards","lemma":"patriotard","pos":"NOM"} ,
		{"word":"patriote","word_nosc":"patriote","lemma":"patriote","pos":"NOM"} ,
		{"word":"patriotes","word_nosc":"patriotes","lemma":"patriote","pos":"NOM"} ,
		{"word":"patriotisme","word_nosc":"patriotisme","lemma":"patriotisme","pos":"NOM"} ,
		{"word":"patron","word_nosc":"patron","lemma":"patron","pos":"NOM"} ,
		{"word":"patronage","word_nosc":"patronage","lemma":"patronage","pos":"NOM"} ,
		{"word":"patronages","word_nosc":"patronages","lemma":"patronage","pos":"NOM"} ,
		{"word":"patronat","word_nosc":"patronat","lemma":"patronat","pos":"NOM"} ,
		{"word":"patronne","word_nosc":"patronne","lemma":"patron","pos":"NOM"} ,
		{"word":"patronnes","word_nosc":"patronnes","lemma":"patron","pos":"NOM"} ,
		{"word":"patrons","word_nosc":"patrons","lemma":"patron","pos":"NOM"} ,
		{"word":"patronyme","word_nosc":"patronyme","lemma":"patronyme","pos":"NOM"} ,
		{"word":"patronymes","word_nosc":"patronymes","lemma":"patronyme","pos":"NOM"} ,
		{"word":"patrouille","word_nosc":"patrouille","lemma":"patrouille","pos":"NOM"} ,
		{"word":"patrouilles","word_nosc":"patrouilles","lemma":"patrouille","pos":"NOM"} ,
		{"word":"patrouilleur","word_nosc":"patrouilleur","lemma":"patrouilleur","pos":"NOM"} ,
		{"word":"patrouilleurs","word_nosc":"patrouilleurs","lemma":"patrouilleur","pos":"NOM"} ,
		{"word":"patte","word_nosc":"patte","lemma":"patte","pos":"NOM"} ,
		{"word":"pattemouille","word_nosc":"pattemouille","lemma":"pattemouille","pos":"NOM"} ,
		{"word":"pattemouilles","word_nosc":"pattemouilles","lemma":"pattemouille","pos":"NOM"} ,
		{"word":"pattern","word_nosc":"pattern","lemma":"pattern","pos":"NOM"} ,
		{"word":"pattes","word_nosc":"pattes","lemma":"patte","pos":"NOM"} ,
		{"word":"paturon","word_nosc":"paturon","lemma":"paturon","pos":"NOM"} ,
		{"word":"paturons","word_nosc":"paturons","lemma":"paturon","pos":"NOM"} ,
		{"word":"patène","word_nosc":"patene","lemma":"patène","pos":"NOM"} ,
		{"word":"patènes","word_nosc":"patenes","lemma":"patène","pos":"NOM"} ,
		{"word":"patère","word_nosc":"patere","lemma":"patère","pos":"NOM"} ,
		{"word":"patères","word_nosc":"pateres","lemma":"patère","pos":"NOM"} ,
		{"word":"pauchouse","word_nosc":"pauchouse","lemma":"pauchouse","pos":"NOM"} ,
		{"word":"pauline","word_nosc":"pauline","lemma":"pauline","pos":"NOM"} ,
		{"word":"paulownias","word_nosc":"paulownias","lemma":"paulownia","pos":"NOM"} ,
		{"word":"paume","word_nosc":"paume","lemma":"paume","pos":"NOM"} ,
		{"word":"paumelle","word_nosc":"paumelle","lemma":"paumelle","pos":"NOM"} ,
		{"word":"paumelles","word_nosc":"paumelles","lemma":"paumelle","pos":"NOM"} ,
		{"word":"paumes","word_nosc":"paumes","lemma":"paume","pos":"NOM"} ,
		{"word":"paumé","word_nosc":"paume","lemma":"paumé","pos":"NOM"} ,
		{"word":"paumée","word_nosc":"paumee","lemma":"paumé","pos":"NOM"} ,
		{"word":"paumées","word_nosc":"paumees","lemma":"paumé","pos":"NOM"} ,
		{"word":"paumés","word_nosc":"paumes","lemma":"paumé","pos":"NOM"} ,
		{"word":"paupiette","word_nosc":"paupiette","lemma":"paupiette","pos":"NOM"} ,
		{"word":"paupiettes","word_nosc":"paupiettes","lemma":"paupiette","pos":"NOM"} ,
		{"word":"paupière","word_nosc":"paupiere","lemma":"paupière","pos":"NOM"} ,
		{"word":"paupières","word_nosc":"paupieres","lemma":"paupière","pos":"NOM"} ,
		{"word":"paupérisation","word_nosc":"pauperisation","lemma":"paupérisation","pos":"NOM"} ,
		{"word":"paupérisme","word_nosc":"pauperisme","lemma":"paupérisme","pos":"NOM"} ,
		{"word":"pause","word_nosc":"pause","lemma":"pause","pos":"NOM"} ,
		{"word":"pause-café","word_nosc":"pause-cafe","lemma":"pause-café","pos":"NOM"} ,
		{"word":"pauses","word_nosc":"pauses","lemma":"pause","pos":"NOM"} ,
		{"word":"pauses-café","word_nosc":"pauses-cafe","lemma":"pause-café","pos":"NOM"} ,
		{"word":"pauses-repas","word_nosc":"pauses-repas","lemma":"pause-repas","pos":"NOM"} ,
		{"word":"pauvre","word_nosc":"pauvre","lemma":"pauvre","pos":"NOM"} ,
		{"word":"pauvres","word_nosc":"pauvres","lemma":"pauvre","pos":"NOM"} ,
		{"word":"pauvresse","word_nosc":"pauvresse","lemma":"pauvresse","pos":"NOM"} ,
		{"word":"pauvresses","word_nosc":"pauvresses","lemma":"pauvresse","pos":"NOM"} ,
		{"word":"pauvret","word_nosc":"pauvret","lemma":"pauvret","pos":"NOM"} ,
		{"word":"pauvrets","word_nosc":"pauvrets","lemma":"pauvret","pos":"NOM"} ,
		{"word":"pauvrette","word_nosc":"pauvrette","lemma":"pauvrette","pos":"NOM"} ,
		{"word":"pauvrettes","word_nosc":"pauvrettes","lemma":"pauvrette","pos":"NOM"} ,
		{"word":"pauvreté","word_nosc":"pauvrete","lemma":"pauvreté","pos":"NOM"} ,
		{"word":"pauvretés","word_nosc":"pauvretes","lemma":"pauvreté","pos":"NOM"} ,
		{"word":"pavage","word_nosc":"pavage","lemma":"pavage","pos":"NOM"} ,
		{"word":"pavane","word_nosc":"pavane","lemma":"pavane","pos":"NOM"} ,
		{"word":"pavanes","word_nosc":"pavanes","lemma":"pavane","pos":"NOM"} ,
		{"word":"pavement","word_nosc":"pavement","lemma":"pavement","pos":"NOM"} ,
		{"word":"pavements","word_nosc":"pavements","lemma":"pavement","pos":"NOM"} ,
		{"word":"paveton","word_nosc":"paveton","lemma":"paveton","pos":"NOM"} ,
		{"word":"pavetons","word_nosc":"pavetons","lemma":"paveton","pos":"NOM"} ,
		{"word":"paveur","word_nosc":"paveur","lemma":"paveur","pos":"NOM"} ,
		{"word":"paveurs","word_nosc":"paveurs","lemma":"paveur","pos":"NOM"} ,
		{"word":"pavillon","word_nosc":"pavillon","lemma":"pavillon","pos":"NOM"} ,
		{"word":"pavillons","word_nosc":"pavillons","lemma":"pavillon","pos":"NOM"} ,
		{"word":"pavois","word_nosc":"pavois","lemma":"pavois","pos":"NOM"} ,
		{"word":"pavoisement","word_nosc":"pavoisement","lemma":"pavoisement","pos":"NOM"} ,
		{"word":"pavot","word_nosc":"pavot","lemma":"pavot","pos":"NOM"} ,
		{"word":"pavots","word_nosc":"pavots","lemma":"pavot","pos":"NOM"} ,
		{"word":"pavé","word_nosc":"pave","lemma":"pavé","pos":"NOM"} ,
		{"word":"pavés","word_nosc":"paves","lemma":"pavé","pos":"NOM"} ,
		{"word":"pax americana","word_nosc":"pax americana","lemma":"pax americana","pos":"NOM"} ,
		{"word":"paxon","word_nosc":"paxon","lemma":"paxon","pos":"NOM"} ,
		{"word":"paye","word_nosc":"paye","lemma":"paye","pos":"NOM"} ,
		{"word":"payement","word_nosc":"payement","lemma":"payement","pos":"NOM"} ,
		{"word":"payements","word_nosc":"payements","lemma":"payement","pos":"NOM"} ,
		{"word":"payes","word_nosc":"payes","lemma":"paye","pos":"NOM"} ,
		{"word":"payeur","word_nosc":"payeur","lemma":"payeur","pos":"NOM"} ,
		{"word":"payeurs","word_nosc":"payeurs","lemma":"payeur","pos":"NOM"} ,
		{"word":"pays","word_nosc":"pays","lemma":"pays","pos":"NOM"} ,
		{"word":"paysage","word_nosc":"paysage","lemma":"paysage","pos":"NOM"} ,
		{"word":"paysages","word_nosc":"paysages","lemma":"paysage","pos":"NOM"} ,
		{"word":"paysagiste","word_nosc":"paysagiste","lemma":"paysagiste","pos":"NOM"} ,
		{"word":"paysagistes","word_nosc":"paysagistes","lemma":"paysagiste","pos":"NOM"} ,
		{"word":"paysan","word_nosc":"paysan","lemma":"paysan","pos":"NOM"} ,
		{"word":"paysanne","word_nosc":"paysanne","lemma":"paysan","pos":"NOM"} ,
		{"word":"paysannerie","word_nosc":"paysannerie","lemma":"paysannerie","pos":"NOM"} ,
		{"word":"paysannes","word_nosc":"paysannes","lemma":"paysan","pos":"NOM"} ,
		{"word":"paysans","word_nosc":"paysans","lemma":"paysan","pos":"NOM"} ,
		{"word":"payse","word_nosc":"payse","lemma":"payse","pos":"NOM"} ,
		{"word":"païen","word_nosc":"paien","lemma":"païen","pos":"NOM"} ,
		{"word":"païenne","word_nosc":"paienne","lemma":"païen","pos":"NOM"} ,
		{"word":"païens","word_nosc":"paiens","lemma":"païen","pos":"NOM"} ,
		{"word":"pc","word_nosc":"pc","lemma":"pc","pos":"NOM"} ,
		{"word":"peanuts","word_nosc":"peanuts","lemma":"peanuts","pos":"NOM"} ,
		{"word":"peau","word_nosc":"peau","lemma":"peau","pos":"NOM"} ,
		{"word":"peau-rouge","word_nosc":"peau-rouge","lemma":"peau-rouge","pos":"NOM"} ,
		{"word":"peaucier","word_nosc":"peaucier","lemma":"peaucier","pos":"NOM"} ,
		{"word":"peauciers","word_nosc":"peauciers","lemma":"peaucier","pos":"NOM"} ,
		{"word":"peaufinage","word_nosc":"peaufinage","lemma":"peaufinage","pos":"NOM"} ,
		{"word":"peausserie","word_nosc":"peausserie","lemma":"peausserie","pos":"NOM"} ,
		{"word":"peausseries","word_nosc":"peausseries","lemma":"peausserie","pos":"NOM"} ,
		{"word":"peaussier","word_nosc":"peaussier","lemma":"peaussier","pos":"NOM"} ,
		{"word":"peaux","word_nosc":"peaux","lemma":"peau","pos":"NOM"} ,
		{"word":"peaux-rouges","word_nosc":"peaux-rouges","lemma":"peau-rouge","pos":"NOM"} ,
		{"word":"peccadille","word_nosc":"peccadille","lemma":"peccadille","pos":"NOM"} ,
		{"word":"peccadilles","word_nosc":"peccadilles","lemma":"peccadille","pos":"NOM"} ,
		{"word":"peccata","word_nosc":"peccata","lemma":"peccata","pos":"NOM"} ,
		{"word":"peccavi","word_nosc":"peccavi","lemma":"peccavi","pos":"NOM"} ,
		{"word":"pecnots","word_nosc":"pecnots","lemma":"pecnot","pos":"NOM"} ,
		{"word":"pecorino","word_nosc":"pecorino","lemma":"pecorino","pos":"NOM"} ,
		{"word":"pectoral","word_nosc":"pectoral","lemma":"pectoral","pos":"NOM"} ,
		{"word":"pectoraux","word_nosc":"pectoraux","lemma":"pectoral","pos":"NOM"} ,
		{"word":"pedigree","word_nosc":"pedigree","lemma":"pedigree","pos":"NOM"} ,
		{"word":"pedigrees","word_nosc":"pedigrees","lemma":"pedigree","pos":"NOM"} ,
		{"word":"pedzouille","word_nosc":"pedzouille","lemma":"pedzouille","pos":"NOM"} ,
		{"word":"pedzouilles","word_nosc":"pedzouilles","lemma":"pedzouille","pos":"NOM"} ,
		{"word":"peeling","word_nosc":"peeling","lemma":"peeling","pos":"NOM"} ,
		{"word":"peigne","word_nosc":"peigne","lemma":"peigne","pos":"NOM"} ,
		{"word":"peigne-cul","word_nosc":"peigne-cul","lemma":"peigne-cul","pos":"NOM"} ,
		{"word":"peigne-culs","word_nosc":"peigne-culs","lemma":"peigne-cul","pos":"NOM"} ,
		{"word":"peigne-zizi","word_nosc":"peigne-zizi","lemma":"peigne-zizi","pos":"NOM"} ,
		{"word":"peignes","word_nosc":"peignes","lemma":"peigne","pos":"NOM"} ,
		{"word":"peignoir","word_nosc":"peignoir","lemma":"peignoir","pos":"NOM"} ,
		{"word":"peignoirs","word_nosc":"peignoirs","lemma":"peignoir","pos":"NOM"} ,
		{"word":"peigné","word_nosc":"peigne","lemma":"peigné","pos":"NOM"} ,
		{"word":"peignée","word_nosc":"peignee","lemma":"peignée","pos":"NOM"} ,
		{"word":"peignées","word_nosc":"peignees","lemma":"peignée","pos":"NOM"} ,
		{"word":"peignés","word_nosc":"peignes","lemma":"peigné","pos":"NOM"} ,
		{"word":"peine","word_nosc":"peine","lemma":"peine","pos":"NOM"} ,
		{"word":"peines","word_nosc":"peines","lemma":"peine","pos":"NOM"} ,
		{"word":"peintre","word_nosc":"peintre","lemma":"peintre","pos":"NOM"} ,
		{"word":"peintres","word_nosc":"peintres","lemma":"peintre","pos":"NOM"} ,
		{"word":"peinture","word_nosc":"peinture","lemma":"peinture","pos":"NOM"} ,
		{"word":"peintures","word_nosc":"peintures","lemma":"peinture","pos":"NOM"} ,
		{"word":"peinturlureur","word_nosc":"peinturlureur","lemma":"peinturlureur","pos":"NOM"} ,
		{"word":"peinturlureurs","word_nosc":"peinturlureurs","lemma":"peinturlureur","pos":"NOM"} ,
		{"word":"pelade","word_nosc":"pelade","lemma":"pelade","pos":"NOM"} ,
		{"word":"pelades","word_nosc":"pelades","lemma":"pelade","pos":"NOM"} ,
		{"word":"pelage","word_nosc":"pelage","lemma":"pelage","pos":"NOM"} ,
		{"word":"pelages","word_nosc":"pelages","lemma":"pelage","pos":"NOM"} ,
		{"word":"pelisse","word_nosc":"pelisse","lemma":"pelisse","pos":"NOM"} ,
		{"word":"pelisses","word_nosc":"pelisses","lemma":"pelisse","pos":"NOM"} ,
		{"word":"pellagre","word_nosc":"pellagre","lemma":"pellagre","pos":"NOM"} ,
		{"word":"pelle","word_nosc":"pelle","lemma":"pelle","pos":"NOM"} ,
		{"word":"pelle-bêche","word_nosc":"pelle-beche","lemma":"pelle-bêche","pos":"NOM"} ,
		{"word":"pelle-pioche","word_nosc":"pelle-pioche","lemma":"pelle-pioche","pos":"NOM"} ,
		{"word":"pelles","word_nosc":"pelles","lemma":"pelle","pos":"NOM"} ,
		{"word":"pelles-bêches","word_nosc":"pelles-beches","lemma":"pelle-bêche","pos":"NOM"} ,
		{"word":"pelles-pioches","word_nosc":"pelles-pioches","lemma":"pelle-pioche","pos":"NOM"} ,
		{"word":"pelletage","word_nosc":"pelletage","lemma":"pelletage","pos":"NOM"} ,
		{"word":"pelleterie","word_nosc":"pelleterie","lemma":"pelleterie","pos":"NOM"} ,
		{"word":"pelleteur","word_nosc":"pelleteur","lemma":"pelleteur","pos":"NOM"} ,
		{"word":"pelleteuse","word_nosc":"pelleteuse","lemma":"pelleteur","pos":"NOM"} ,
		{"word":"pelleteuses","word_nosc":"pelleteuses","lemma":"pelleteur","pos":"NOM"} ,
		{"word":"pelletiers","word_nosc":"pelletiers","lemma":"pelletier","pos":"NOM"} ,
		{"word":"pelletée","word_nosc":"pelletee","lemma":"pelletée","pos":"NOM"} ,
		{"word":"pelletées","word_nosc":"pelletees","lemma":"pelletée","pos":"NOM"} ,
		{"word":"pelliculages","word_nosc":"pelliculages","lemma":"pelliculage","pos":"NOM"} ,
		{"word":"pellicule","word_nosc":"pellicule","lemma":"pellicule","pos":"NOM"} ,
		{"word":"pellicules","word_nosc":"pellicules","lemma":"pellicule","pos":"NOM"} ,
		{"word":"pelloche","word_nosc":"pelloche","lemma":"pelloche","pos":"NOM"} ,
		{"word":"pelotage","word_nosc":"pelotage","lemma":"pelotage","pos":"NOM"} ,
		{"word":"pelotages","word_nosc":"pelotages","lemma":"pelotage","pos":"NOM"} ,
		{"word":"pelote","word_nosc":"pelote","lemma":"pelote","pos":"NOM"} ,
		{"word":"pelotes","word_nosc":"pelotes","lemma":"pelote","pos":"NOM"} ,
		{"word":"peloteur","word_nosc":"peloteur","lemma":"peloteur","pos":"NOM"} ,
		{"word":"peloteurs","word_nosc":"peloteurs","lemma":"peloteur","pos":"NOM"} ,
		{"word":"peloton","word_nosc":"peloton","lemma":"peloton","pos":"NOM"} ,
		{"word":"pelotons","word_nosc":"pelotons","lemma":"peloton","pos":"NOM"} ,
		{"word":"pelouse","word_nosc":"pelouse","lemma":"pelouse","pos":"NOM"} ,
		{"word":"pelouses","word_nosc":"pelouses","lemma":"pelouse","pos":"NOM"} ,
		{"word":"peluche","word_nosc":"peluche","lemma":"peluche","pos":"NOM"} ,
		{"word":"peluches","word_nosc":"peluches","lemma":"peluche","pos":"NOM"} ,
		{"word":"pelure","word_nosc":"pelure","lemma":"pelure","pos":"NOM"} ,
		{"word":"pelures","word_nosc":"pelures","lemma":"pelure","pos":"NOM"} ,
		{"word":"pelvis","word_nosc":"pelvis","lemma":"pelvis","pos":"NOM"} ,
		{"word":"pembina","word_nosc":"pembina","lemma":"pembina","pos":"NOM"} ,
		{"word":"pemmican","word_nosc":"pemmican","lemma":"pemmican","pos":"NOM"} ,
		{"word":"penalties","word_nosc":"penalties","lemma":"penalties","pos":"NOM"} ,
		{"word":"penalty","word_nosc":"penalty","lemma":"penalty","pos":"NOM"} ,
		{"word":"pence","word_nosc":"pence","lemma":"pence","pos":"NOM"} ,
		{"word":"penchant","word_nosc":"penchant","lemma":"penchant","pos":"NOM"} ,
		{"word":"penchants","word_nosc":"penchants","lemma":"penchant","pos":"NOM"} ,
		{"word":"penchement","word_nosc":"penchement","lemma":"penchement","pos":"NOM"} ,
		{"word":"pendaison","word_nosc":"pendaison","lemma":"pendaison","pos":"NOM"} ,
		{"word":"pendaisons","word_nosc":"pendaisons","lemma":"pendaison","pos":"NOM"} ,
		{"word":"pendant","word_nosc":"pendant","lemma":"pendant","pos":"NOM"} ,
		{"word":"pendants","word_nosc":"pendants","lemma":"pendant","pos":"NOM"} ,
		{"word":"pendard","word_nosc":"pendard","lemma":"pendard","pos":"NOM"} ,
		{"word":"pendarde","word_nosc":"pendarde","lemma":"pendard","pos":"NOM"} ,
		{"word":"pendards","word_nosc":"pendards","lemma":"pendard","pos":"NOM"} ,
		{"word":"pendeloque","word_nosc":"pendeloque","lemma":"pendeloque","pos":"NOM"} ,
		{"word":"pendeloques","word_nosc":"pendeloques","lemma":"pendeloque","pos":"NOM"} ,
		{"word":"pendentif","word_nosc":"pendentif","lemma":"pendentif","pos":"NOM"} ,
		{"word":"pendentifs","word_nosc":"pendentifs","lemma":"pendentif","pos":"NOM"} ,
		{"word":"penderie","word_nosc":"penderie","lemma":"penderie","pos":"NOM"} ,
		{"word":"penderies","word_nosc":"penderies","lemma":"penderie","pos":"NOM"} ,
		{"word":"pendeur","word_nosc":"pendeur","lemma":"pendeur","pos":"NOM"} ,
		{"word":"pendjabi","word_nosc":"pendjabi","lemma":"pendjabi","pos":"NOM"} ,
		{"word":"pendu","word_nosc":"pendu","lemma":"pendu","pos":"NOM"} ,
		{"word":"pendue","word_nosc":"pendue","lemma":"pendu","pos":"NOM"} ,
		{"word":"pendues","word_nosc":"pendues","lemma":"pendu","pos":"NOM"} ,
		{"word":"pendule","word_nosc":"pendule","lemma":"pendule","pos":"NOM"} ,
		{"word":"pendules","word_nosc":"pendules","lemma":"pendule","pos":"NOM"} ,
		{"word":"pendulette","word_nosc":"pendulette","lemma":"pendulette","pos":"NOM"} ,
		{"word":"pendulettes","word_nosc":"pendulettes","lemma":"pendulette","pos":"NOM"} ,
		{"word":"pendus","word_nosc":"pendus","lemma":"pendu","pos":"NOM"} ,
		{"word":"pennage","word_nosc":"pennage","lemma":"pennage","pos":"NOM"} ,
		{"word":"penne","word_nosc":"penne","lemma":"penne","pos":"NOM"} ,
		{"word":"pennes","word_nosc":"pennes","lemma":"penne","pos":"NOM"} ,
		{"word":"pennies","word_nosc":"pennies","lemma":"pennies","pos":"NOM"} ,
		{"word":"pennon","word_nosc":"pennon","lemma":"pennon","pos":"NOM"} ,
		{"word":"pennons","word_nosc":"pennons","lemma":"pennon","pos":"NOM"} ,
		{"word":"penny","word_nosc":"penny","lemma":"penny","pos":"NOM"} ,
		{"word":"penon","word_nosc":"penon","lemma":"penon","pos":"NOM"} ,
		{"word":"pense-bête","word_nosc":"pense-bete","lemma":"pense-bête","pos":"NOM"} ,
		{"word":"pense-bêtes","word_nosc":"pense-betes","lemma":"pense-bête","pos":"NOM"} ,
		{"word":"penseur","word_nosc":"penseur","lemma":"penseur","pos":"NOM"} ,
		{"word":"penseurs","word_nosc":"penseurs","lemma":"penseur","pos":"NOM"} ,
		{"word":"penseuse","word_nosc":"penseuse","lemma":"penseur","pos":"NOM"} ,
		{"word":"penseuses","word_nosc":"penseuses","lemma":"penseur","pos":"NOM"} ,
		{"word":"pension","word_nosc":"pension","lemma":"pension","pos":"NOM"} ,
		{"word":"pensionnaire","word_nosc":"pensionnaire","lemma":"pensionnaire","pos":"NOM"} ,
		{"word":"pensionnaires","word_nosc":"pensionnaires","lemma":"pensionnaire","pos":"NOM"} ,
		{"word":"pensionnat","word_nosc":"pensionnat","lemma":"pensionnat","pos":"NOM"} ,
		{"word":"pensionnats","word_nosc":"pensionnats","lemma":"pensionnat","pos":"NOM"} ,
		{"word":"pensionné","word_nosc":"pensionne","lemma":"pensionné","pos":"NOM"} ,
		{"word":"pensionnés","word_nosc":"pensionnes","lemma":"pensionné","pos":"NOM"} ,
		{"word":"pensions","word_nosc":"pensions","lemma":"pension","pos":"NOM"} ,
		{"word":"pensum","word_nosc":"pensum","lemma":"pensum","pos":"NOM"} ,
		{"word":"pensums","word_nosc":"pensums","lemma":"pensum","pos":"NOM"} ,
		{"word":"pensée","word_nosc":"pensee","lemma":"pensée","pos":"NOM"} ,
		{"word":"pensées","word_nosc":"pensees","lemma":"pensée","pos":"NOM"} ,
		{"word":"pentacle","word_nosc":"pentacle","lemma":"pentacle","pos":"NOM"} ,
		{"word":"pentacles","word_nosc":"pentacles","lemma":"pentacle","pos":"NOM"} ,
		{"word":"pentagone","word_nosc":"pentagone","lemma":"pentagone","pos":"NOM"} ,
		{"word":"pentagones","word_nosc":"pentagones","lemma":"pentagone","pos":"NOM"} ,
		{"word":"pentagramme","word_nosc":"pentagramme","lemma":"pentagramme","pos":"NOM"} ,
		{"word":"pentagrammes","word_nosc":"pentagrammes","lemma":"pentagramme","pos":"NOM"} ,
		{"word":"pentamètre","word_nosc":"pentametre","lemma":"pentamètre","pos":"NOM"} ,
		{"word":"pentamètres","word_nosc":"pentametres","lemma":"pentamètre","pos":"NOM"} ,
		{"word":"pentasyllabe","word_nosc":"pentasyllabe","lemma":"pentasyllabe","pos":"NOM"} ,
		{"word":"pentateuque","word_nosc":"pentateuque","lemma":"pentateuque","pos":"NOM"} ,
		{"word":"pentathlon","word_nosc":"pentathlon","lemma":"pentathlon","pos":"NOM"} ,
		{"word":"pente","word_nosc":"pente","lemma":"pente","pos":"NOM"} ,
		{"word":"pentecôte","word_nosc":"pentecote","lemma":"pentecôte","pos":"NOM"} ,
		{"word":"pentecôtiste","word_nosc":"pentecotiste","lemma":"pentecôtiste","pos":"NOM"} ,
		{"word":"pentecôtistes","word_nosc":"pentecotistes","lemma":"pentecôtiste","pos":"NOM"} ,
		{"word":"pentes","word_nosc":"pentes","lemma":"pente","pos":"NOM"} ,
		{"word":"penthiobarbital","word_nosc":"penthiobarbital","lemma":"penthiobarbital","pos":"NOM"} ,
		{"word":"penthotal","word_nosc":"penthotal","lemma":"penthotal","pos":"NOM"} ,
		{"word":"penthouse","word_nosc":"penthouse","lemma":"penthouse","pos":"NOM"} ,
		{"word":"pentothal","word_nosc":"pentothal","lemma":"pentothal","pos":"NOM"} ,
		{"word":"penture","word_nosc":"penture","lemma":"penture","pos":"NOM"} ,
		{"word":"pentures","word_nosc":"pentures","lemma":"penture","pos":"NOM"} ,
		{"word":"people","word_nosc":"people","lemma":"people","pos":"NOM"} ,
		{"word":"pep","word_nosc":"pep","lemma":"pep","pos":"NOM"} ,
		{"word":"peppermint","word_nosc":"peppermint","lemma":"peppermint","pos":"NOM"} ,
		{"word":"peps","word_nosc":"peps","lemma":"pep","pos":"NOM"} ,
		{"word":"pepsine","word_nosc":"pepsine","lemma":"pepsine","pos":"NOM"} ,
		{"word":"peptide","word_nosc":"peptide","lemma":"peptide","pos":"NOM"} ,
		{"word":"percale","word_nosc":"percale","lemma":"percale","pos":"NOM"} ,
		{"word":"percales","word_nosc":"percales","lemma":"percale","pos":"NOM"} ,
		{"word":"perce","word_nosc":"perce","lemma":"perce","pos":"NOM"} ,
		{"word":"perce-oreille","word_nosc":"perce-oreille","lemma":"perce-oreille","pos":"NOM"} ,
		{"word":"perce-oreilles","word_nosc":"perce-oreilles","lemma":"perce-oreille","pos":"NOM"} ,
		{"word":"perce-pierre","word_nosc":"perce-pierre","lemma":"perce-pierre","pos":"NOM"} ,
		{"word":"percement","word_nosc":"percement","lemma":"percement","pos":"NOM"} ,
		{"word":"percepteur","word_nosc":"percepteur","lemma":"percepteur","pos":"NOM"} ,
		{"word":"percepteurs","word_nosc":"percepteurs","lemma":"percepteur","pos":"NOM"} ,
		{"word":"perception","word_nosc":"perception","lemma":"perception","pos":"NOM"} ,
		{"word":"perceptions","word_nosc":"perceptions","lemma":"perception","pos":"NOM"} ,
		{"word":"perceptrice","word_nosc":"perceptrice","lemma":"percepteur","pos":"NOM"} ,
		{"word":"perceur","word_nosc":"perceur","lemma":"perceur","pos":"NOM"} ,
		{"word":"perceurs","word_nosc":"perceurs","lemma":"perceur","pos":"NOM"} ,
		{"word":"perceuse","word_nosc":"perceuse","lemma":"perceur","pos":"NOM"} ,
		{"word":"perceuses","word_nosc":"perceuses","lemma":"perceur","pos":"NOM"} ,
		{"word":"perche","word_nosc":"perche","lemma":"perche","pos":"NOM"} ,
		{"word":"percheron","word_nosc":"percheron","lemma":"percheron","pos":"NOM"} ,
		{"word":"percheronnes","word_nosc":"percheronnes","lemma":"percheron","pos":"NOM"} ,
		{"word":"percherons","word_nosc":"percherons","lemma":"percheron","pos":"NOM"} ,
		{"word":"perches","word_nosc":"perches","lemma":"perche","pos":"NOM"} ,
		{"word":"perchettes","word_nosc":"perchettes","lemma":"perchette","pos":"NOM"} ,
		{"word":"perchiste","word_nosc":"perchiste","lemma":"perchiste","pos":"NOM"} ,
		{"word":"perchlorate","word_nosc":"perchlorate","lemma":"perchlorate","pos":"NOM"} ,
		{"word":"perchman","word_nosc":"perchman","lemma":"perchman","pos":"NOM"} ,
		{"word":"perchmans","word_nosc":"perchmans","lemma":"perchman","pos":"NOM"} ,
		{"word":"perchoir","word_nosc":"perchoir","lemma":"perchoir","pos":"NOM"} ,
		{"word":"perchoirs","word_nosc":"perchoirs","lemma":"perchoir","pos":"NOM"} ,
		{"word":"perchée","word_nosc":"perchee","lemma":"perchée","pos":"NOM"} ,
		{"word":"perchées","word_nosc":"perchees","lemma":"perchée","pos":"NOM"} ,
		{"word":"perco","word_nosc":"perco","lemma":"perco","pos":"NOM"} ,
		{"word":"percolateur","word_nosc":"percolateur","lemma":"percolateur","pos":"NOM"} ,
		{"word":"percolateurs","word_nosc":"percolateurs","lemma":"percolateur","pos":"NOM"} ,
		{"word":"percolation","word_nosc":"percolation","lemma":"percolation","pos":"NOM"} ,
		{"word":"percos","word_nosc":"percos","lemma":"perco","pos":"NOM"} ,
		{"word":"percussion","word_nosc":"percussion","lemma":"percussion","pos":"NOM"} ,
		{"word":"percussionniste","word_nosc":"percussionniste","lemma":"percussionniste","pos":"NOM"} ,
		{"word":"percussionnistes","word_nosc":"percussionnistes","lemma":"percussionniste","pos":"NOM"} ,
		{"word":"percussions","word_nosc":"percussions","lemma":"percussion","pos":"NOM"} ,
		{"word":"percuteur","word_nosc":"percuteur","lemma":"percuteur","pos":"NOM"} ,
		{"word":"percuteurs","word_nosc":"percuteurs","lemma":"percuteur","pos":"NOM"} ,
		{"word":"percé","word_nosc":"perce","lemma":"percé","pos":"NOM"} ,
		{"word":"percée","word_nosc":"percee","lemma":"percée","pos":"NOM"} ,
		{"word":"percées","word_nosc":"percees","lemma":"percée","pos":"NOM"} ,
		{"word":"perdant","word_nosc":"perdant","lemma":"perdant","pos":"NOM"} ,
		{"word":"perdante","word_nosc":"perdante","lemma":"perdant","pos":"NOM"} ,
		{"word":"perdants","word_nosc":"perdants","lemma":"perdant","pos":"NOM"} ,
		{"word":"perdition","word_nosc":"perdition","lemma":"perdition","pos":"NOM"} ,
		{"word":"perdreau","word_nosc":"perdreau","lemma":"perdreau","pos":"NOM"} ,
		{"word":"perdreaux","word_nosc":"perdreaux","lemma":"perdreau","pos":"NOM"} ,
		{"word":"perdrix","word_nosc":"perdrix","lemma":"perdrix","pos":"NOM"} ,
		{"word":"perdu","word_nosc":"perdu","lemma":"perdu","pos":"NOM"} ,
		{"word":"perestroïka","word_nosc":"perestroika","lemma":"perestroïka","pos":"NOM"} ,
		{"word":"perfectibilité","word_nosc":"perfectibilite","lemma":"perfectibilité","pos":"NOM"} ,
		{"word":"perfection","word_nosc":"perfection","lemma":"perfection","pos":"NOM"} ,
		{"word":"perfectionnement","word_nosc":"perfectionnement","lemma":"perfectionnement","pos":"NOM"} ,
		{"word":"perfectionnements","word_nosc":"perfectionnements","lemma":"perfectionnement","pos":"NOM"} ,
		{"word":"perfectionnisme","word_nosc":"perfectionnisme","lemma":"perfectionnisme","pos":"NOM"} ,
		{"word":"perfectionniste","word_nosc":"perfectionniste","lemma":"perfectionniste","pos":"NOM"} ,
		{"word":"perfectionnistes","word_nosc":"perfectionnistes","lemma":"perfectionniste","pos":"NOM"} ,
		{"word":"perfections","word_nosc":"perfections","lemma":"perfection","pos":"NOM"} ,
		{"word":"perfecto","word_nosc":"perfecto","lemma":"perfecto","pos":"NOM"} ,
		{"word":"perfectos","word_nosc":"perfectos","lemma":"perfecto","pos":"NOM"} ,
		{"word":"perfidie","word_nosc":"perfidie","lemma":"perfidie","pos":"NOM"} ,
		{"word":"perfidies","word_nosc":"perfidies","lemma":"perfidie","pos":"NOM"} ,
		{"word":"perforage","word_nosc":"perforage","lemma":"perforage","pos":"NOM"} ,
		{"word":"perforateur","word_nosc":"perforateur","lemma":"perforateur","pos":"NOM"} ,
		{"word":"perforation","word_nosc":"perforation","lemma":"perforation","pos":"NOM"} ,
		{"word":"perforations","word_nosc":"perforations","lemma":"perforation","pos":"NOM"} ,
		{"word":"perforatrice","word_nosc":"perforatrice","lemma":"perforateur","pos":"NOM"} ,
		{"word":"perforeuse","word_nosc":"perforeuse","lemma":"perforeuse","pos":"NOM"} ,
		{"word":"performance","word_nosc":"performance","lemma":"performance","pos":"NOM"} ,
		{"word":"performances","word_nosc":"performances","lemma":"performance","pos":"NOM"} ,
		{"word":"performer","word_nosc":"performer","lemma":"performer","pos":"NOM"} ,
		{"word":"perfusion","word_nosc":"perfusion","lemma":"perfusion","pos":"NOM"} ,
		{"word":"perfusions","word_nosc":"perfusions","lemma":"perfusion","pos":"NOM"} ,
		{"word":"pergola","word_nosc":"pergola","lemma":"pergola","pos":"NOM"} ,
		{"word":"pergélisol","word_nosc":"pergelisol","lemma":"pergélisol","pos":"NOM"} ,
		{"word":"perle","word_nosc":"perle","lemma":"perle","pos":"NOM"} ,
		{"word":"perles","word_nosc":"perles","lemma":"perle","pos":"NOM"} ,
		{"word":"perlimpinpin","word_nosc":"perlimpinpin","lemma":"perlimpinpin","pos":"NOM"} ,
		{"word":"perlot","word_nosc":"perlot","lemma":"perlot","pos":"NOM"} ,
		{"word":"perlots","word_nosc":"perlots","lemma":"perlot","pos":"NOM"} ,
		{"word":"perlouse","word_nosc":"perlouse","lemma":"perlouse","pos":"NOM"} ,
		{"word":"perlouses","word_nosc":"perlouses","lemma":"perlouse","pos":"NOM"} ,
		{"word":"perlouze","word_nosc":"perlouze","lemma":"perlouze","pos":"NOM"} ,
		{"word":"perlouzes","word_nosc":"perlouzes","lemma":"perlouze","pos":"NOM"} ,
		{"word":"perm","word_nosc":"perm","lemma":"perm","pos":"NOM"} ,
		{"word":"permafrost","word_nosc":"permafrost","lemma":"permafrost","pos":"NOM"} ,
		{"word":"permalloy","word_nosc":"permalloy","lemma":"permalloy","pos":"NOM"} ,
		{"word":"permanence","word_nosc":"permanence","lemma":"permanence","pos":"NOM"} ,
		{"word":"permanences","word_nosc":"permanences","lemma":"permanence","pos":"NOM"} ,
		{"word":"permanent","word_nosc":"permanent","lemma":"permanent","pos":"NOM"} ,
		{"word":"permanente","word_nosc":"permanente","lemma":"permanente","pos":"NOM"} ,
		{"word":"permanentes","word_nosc":"permanentes","lemma":"permanente","pos":"NOM"} ,
		{"word":"permanents","word_nosc":"permanents","lemma":"permanent","pos":"NOM"} ,
		{"word":"permanganate","word_nosc":"permanganate","lemma":"permanganate","pos":"NOM"} ,
		{"word":"perme","word_nosc":"perme","lemma":"perme","pos":"NOM"} ,
		{"word":"permes","word_nosc":"permes","lemma":"perme","pos":"NOM"} ,
		{"word":"permis","word_nosc":"permis","lemma":"permis","pos":"NOM"} ,
		{"word":"permission","word_nosc":"permission","lemma":"permission","pos":"NOM"} ,
		{"word":"permissionnaire","word_nosc":"permissionnaire","lemma":"permissionnaire","pos":"NOM"} ,
		{"word":"permissionnaires","word_nosc":"permissionnaires","lemma":"permissionnaire","pos":"NOM"} ,
		{"word":"permissions","word_nosc":"permissions","lemma":"permission","pos":"NOM"} ,
		{"word":"permissivité","word_nosc":"permissivite","lemma":"permissivité","pos":"NOM"} ,
		{"word":"permutation","word_nosc":"permutation","lemma":"permutation","pos":"NOM"} ,
		{"word":"permutations","word_nosc":"permutations","lemma":"permutation","pos":"NOM"} ,
		{"word":"perméabilité","word_nosc":"permeabilite","lemma":"perméabilité","pos":"NOM"} ,
		{"word":"pernod","word_nosc":"pernod","lemma":"pernod","pos":"NOM"} ,
		{"word":"pernods","word_nosc":"pernods","lemma":"pernod","pos":"NOM"} ,
		{"word":"peroxydase","word_nosc":"peroxydase","lemma":"peroxydase","pos":"NOM"} ,
		{"word":"peroxyde","word_nosc":"peroxyde","lemma":"peroxyde","pos":"NOM"} ,
		{"word":"perpendiculaire","word_nosc":"perpendiculaire","lemma":"perpendiculaire","pos":"NOM"} ,
		{"word":"perpendiculaires","word_nosc":"perpendiculaires","lemma":"perpendiculaire","pos":"NOM"} ,
		{"word":"perpette","word_nosc":"perpette","lemma":"perpette","pos":"NOM"} ,
		{"word":"perpignan","word_nosc":"perpignan","lemma":"perpignan","pos":"NOM"} ,
		{"word":"perplexité","word_nosc":"perplexite","lemma":"perplexité","pos":"NOM"} ,
		{"word":"perplexités","word_nosc":"perplexites","lemma":"perplexité","pos":"NOM"} ,
		{"word":"perpète","word_nosc":"perpete","lemma":"perpète","pos":"NOM"} ,
		{"word":"perpétration","word_nosc":"perpetration","lemma":"perpétration","pos":"NOM"} ,
		{"word":"perpétuation","word_nosc":"perpetuation","lemma":"perpétuation","pos":"NOM"} ,
		{"word":"perpétuité","word_nosc":"perpetuite","lemma":"perpétuité","pos":"NOM"} ,
		{"word":"perpétuités","word_nosc":"perpetuites","lemma":"perpétuité","pos":"NOM"} ,
		{"word":"perquise","word_nosc":"perquise","lemma":"perquise","pos":"NOM"} ,
		{"word":"perquisition","word_nosc":"perquisition","lemma":"perquisition","pos":"NOM"} ,
		{"word":"perquisitions","word_nosc":"perquisitions","lemma":"perquisition","pos":"NOM"} ,
		{"word":"perrier","word_nosc":"perrier","lemma":"perrier","pos":"NOM"} ,
		{"word":"perrières","word_nosc":"perrieres","lemma":"perrière","pos":"NOM"} ,
		{"word":"perron","word_nosc":"perron","lemma":"perron","pos":"NOM"} ,
		{"word":"perrons","word_nosc":"perrons","lemma":"perron","pos":"NOM"} ,
		{"word":"perroquet","word_nosc":"perroquet","lemma":"perroquet","pos":"NOM"} ,
		{"word":"perroquets","word_nosc":"perroquets","lemma":"perroquet","pos":"NOM"} ,
		{"word":"perruche","word_nosc":"perruche","lemma":"perruche","pos":"NOM"} ,
		{"word":"perruches","word_nosc":"perruches","lemma":"perruche","pos":"NOM"} ,
		{"word":"perruque","word_nosc":"perruque","lemma":"perruque","pos":"NOM"} ,
		{"word":"perruques","word_nosc":"perruques","lemma":"perruque","pos":"NOM"} ,
		{"word":"perruquier","word_nosc":"perruquier","lemma":"perruquier","pos":"NOM"} ,
		{"word":"perruquiers","word_nosc":"perruquiers","lemma":"perruquier","pos":"NOM"} ,
		{"word":"perruquière","word_nosc":"perruquiere","lemma":"perruquier","pos":"NOM"} ,
		{"word":"perré","word_nosc":"perre","lemma":"perré","pos":"NOM"} ,
		{"word":"perrés","word_nosc":"perres","lemma":"perré","pos":"NOM"} ,
		{"word":"persan","word_nosc":"persan","lemma":"persan","pos":"NOM"} ,
		{"word":"persane","word_nosc":"persane","lemma":"persan","pos":"NOM"} ,
		{"word":"persanes","word_nosc":"persanes","lemma":"persan","pos":"NOM"} ,
		{"word":"persans","word_nosc":"persans","lemma":"persan","pos":"NOM"} ,
		{"word":"persienne","word_nosc":"persienne","lemma":"persienne","pos":"NOM"} ,
		{"word":"persiennes","word_nosc":"persiennes","lemma":"persienne","pos":"NOM"} ,
		{"word":"persiflage","word_nosc":"persiflage","lemma":"persiflage","pos":"NOM"} ,
		{"word":"persiflages","word_nosc":"persiflages","lemma":"persiflage","pos":"NOM"} ,
		{"word":"persifleurs","word_nosc":"persifleurs","lemma":"persifleur","pos":"NOM"} ,
		{"word":"persifleuse","word_nosc":"persifleuse","lemma":"persifleur","pos":"NOM"} ,
		{"word":"persil","word_nosc":"persil","lemma":"persil","pos":"NOM"} ,
		{"word":"persistance","word_nosc":"persistance","lemma":"persistance","pos":"NOM"} ,
		{"word":"persona non grata","word_nosc":"persona non grata","lemma":"persona non grata","pos":"NOM"} ,
		{"word":"personnage","word_nosc":"personnage","lemma":"personnage","pos":"NOM"} ,
		{"word":"personnage-clé","word_nosc":"personnage-cle","lemma":"personnage-clé","pos":"NOM"} ,
		{"word":"personnages","word_nosc":"personnages","lemma":"personnage","pos":"NOM"} ,
		{"word":"personnalisation","word_nosc":"personnalisation","lemma":"personnalisation","pos":"NOM"} ,
		{"word":"personnalisme","word_nosc":"personnalisme","lemma":"personnalisme","pos":"NOM"} ,
		{"word":"personnalité","word_nosc":"personnalite","lemma":"personnalité","pos":"NOM"} ,
		{"word":"personnalités","word_nosc":"personnalites","lemma":"personnalité","pos":"NOM"} ,
		{"word":"personne","word_nosc":"personne","lemma":"personne","pos":"NOM"} ,
		{"word":"personnel","word_nosc":"personnel","lemma":"personnel","pos":"NOM"} ,
		{"word":"personnels","word_nosc":"personnels","lemma":"personnel","pos":"NOM"} ,
		{"word":"personnes","word_nosc":"personnes","lemma":"personne","pos":"NOM"} ,
		{"word":"personnification","word_nosc":"personnification","lemma":"personnification","pos":"NOM"} ,
		{"word":"personnifications","word_nosc":"personnifications","lemma":"personnification","pos":"NOM"} ,
		{"word":"perspective","word_nosc":"perspective","lemma":"perspective","pos":"NOM"} ,
		{"word":"perspectives","word_nosc":"perspectives","lemma":"perspective","pos":"NOM"} ,
		{"word":"perspicacité","word_nosc":"perspicacite","lemma":"perspicacité","pos":"NOM"} ,
		{"word":"perspiration","word_nosc":"perspiration","lemma":"perspiration","pos":"NOM"} ,
		{"word":"persuasion","word_nosc":"persuasion","lemma":"persuasion","pos":"NOM"} ,
		{"word":"persécuteur","word_nosc":"persecuteur","lemma":"persécuteur","pos":"NOM"} ,
		{"word":"persécuteurs","word_nosc":"persecuteurs","lemma":"persécuteur","pos":"NOM"} ,
		{"word":"persécution","word_nosc":"persecution","lemma":"persécution","pos":"NOM"} ,
		{"word":"persécutions","word_nosc":"persecutions","lemma":"persécution","pos":"NOM"} ,
		{"word":"persécutrices","word_nosc":"persecutrices","lemma":"persécuteur","pos":"NOM"} ,
		{"word":"perséides","word_nosc":"perseides","lemma":"perséides","pos":"NOM"} ,
		{"word":"persévérance","word_nosc":"perseverance","lemma":"persévérance","pos":"NOM"} ,
		{"word":"perte","word_nosc":"perte","lemma":"perte","pos":"NOM"} ,
		{"word":"pertes","word_nosc":"pertes","lemma":"perte","pos":"NOM"} ,
		{"word":"pertinence","word_nosc":"pertinence","lemma":"pertinence","pos":"NOM"} ,
		{"word":"pertuis","word_nosc":"pertuis","lemma":"pertuis","pos":"NOM"} ,
		{"word":"pertuisane","word_nosc":"pertuisane","lemma":"pertuisane","pos":"NOM"} ,
		{"word":"pertuisanes","word_nosc":"pertuisanes","lemma":"pertuisane","pos":"NOM"} ,
		{"word":"perturbateur","word_nosc":"perturbateur","lemma":"perturbateur","pos":"NOM"} ,
		{"word":"perturbateurs","word_nosc":"perturbateurs","lemma":"perturbateur","pos":"NOM"} ,
		{"word":"perturbation","word_nosc":"perturbation","lemma":"perturbation","pos":"NOM"} ,
		{"word":"perturbations","word_nosc":"perturbations","lemma":"perturbation","pos":"NOM"} ,
		{"word":"pervenche","word_nosc":"pervenche","lemma":"pervenche","pos":"NOM"} ,
		{"word":"pervenches","word_nosc":"pervenches","lemma":"pervenche","pos":"NOM"} ,
		{"word":"pervers","word_nosc":"pervers","lemma":"pervers","pos":"NOM"} ,
		{"word":"perverse","word_nosc":"perverse","lemma":"pervers","pos":"NOM"} ,
		{"word":"perverses","word_nosc":"perverses","lemma":"pervers","pos":"NOM"} ,
		{"word":"perversion","word_nosc":"perversion","lemma":"perversion","pos":"NOM"} ,
		{"word":"perversions","word_nosc":"perversions","lemma":"perversion","pos":"NOM"} ,
		{"word":"perversité","word_nosc":"perversite","lemma":"perversité","pos":"NOM"} ,
		{"word":"perçage","word_nosc":"percage","lemma":"perçage","pos":"NOM"} ,
		{"word":"pesage","word_nosc":"pesage","lemma":"pesage","pos":"NOM"} ,
		{"word":"pesanteur","word_nosc":"pesanteur","lemma":"pesanteur","pos":"NOM"} ,
		{"word":"pesanteurs","word_nosc":"pesanteurs","lemma":"pesanteur","pos":"NOM"} ,
		{"word":"peseta","word_nosc":"peseta","lemma":"peseta","pos":"NOM"} ,
		{"word":"pesetas","word_nosc":"pesetas","lemma":"peseta","pos":"NOM"} ,
		{"word":"peseurs","word_nosc":"peseurs","lemma":"peseur","pos":"NOM"} ,
		{"word":"peso","word_nosc":"peso","lemma":"peso","pos":"NOM"} ,
		{"word":"peson","word_nosc":"peson","lemma":"peson","pos":"NOM"} ,
		{"word":"pesos","word_nosc":"pesos","lemma":"peso","pos":"NOM"} ,
		{"word":"pessah","word_nosc":"pessah","lemma":"pessah","pos":"NOM"} ,
		{"word":"pessaire","word_nosc":"pessaire","lemma":"pessaire","pos":"NOM"} ,
		{"word":"pesse","word_nosc":"pesse","lemma":"pesse","pos":"NOM"} ,
		{"word":"pessimisme","word_nosc":"pessimisme","lemma":"pessimisme","pos":"NOM"} ,
		{"word":"pessimiste","word_nosc":"pessimiste","lemma":"pessimiste","pos":"NOM"} ,
		{"word":"pessimistes","word_nosc":"pessimistes","lemma":"pessimiste","pos":"NOM"} ,
		{"word":"peste","word_nosc":"peste","lemma":"peste","pos":"NOM"} ,
		{"word":"pestes","word_nosc":"pestes","lemma":"peste","pos":"NOM"} ,
		{"word":"pesticide","word_nosc":"pesticide","lemma":"pesticide","pos":"NOM"} ,
		{"word":"pesticides","word_nosc":"pesticides","lemma":"pesticide","pos":"NOM"} ,
		{"word":"pestiféré","word_nosc":"pestifere","lemma":"pestiféré","pos":"NOM"} ,
		{"word":"pestiférée","word_nosc":"pestiferee","lemma":"pestiféré","pos":"NOM"} ,
		{"word":"pestiférées","word_nosc":"pestiferees","lemma":"pestiféré","pos":"NOM"} ,
		{"word":"pestiférés","word_nosc":"pestiferes","lemma":"pestiféré","pos":"NOM"} ,
		{"word":"pestilence","word_nosc":"pestilence","lemma":"pestilence","pos":"NOM"} ,
		{"word":"pestilences","word_nosc":"pestilences","lemma":"pestilence","pos":"NOM"} ,
		{"word":"pesée","word_nosc":"pesee","lemma":"pesée","pos":"NOM"} ,
		{"word":"pesées","word_nosc":"pesees","lemma":"pesée","pos":"NOM"} ,
		{"word":"pet","word_nosc":"pet","lemma":"pet","pos":"NOM"} ,
		{"word":"pet-de-loup","word_nosc":"pet-de-loup","lemma":"pet-de-loup","pos":"NOM"} ,
		{"word":"petit","word_nosc":"petit","lemma":"petit","pos":"NOM"} ,
		{"word":"petit-beurre","word_nosc":"petit-beurre","lemma":"petit-beurre","pos":"NOM"} ,
		{"word":"petit-bourgeois","word_nosc":"petit-bourgeois","lemma":"petit-bourgeois","pos":"NOM"} ,
		{"word":"petit-cousin","word_nosc":"petit-cousin","lemma":"petit-cousin","pos":"NOM"} ,
		{"word":"petit-déjeuner","word_nosc":"petit-dejeuner","lemma":"petit-déjeuner","pos":"NOM"} ,
		{"word":"petit-fils","word_nosc":"petit-fils","lemma":"petit-fils","pos":"NOM"} ,
		{"word":"petit-four","word_nosc":"petit-four","lemma":"petit-four","pos":"NOM"} ,
		{"word":"petit-gris","word_nosc":"petit-gris","lemma":"petit-gris","pos":"NOM"} ,
		{"word":"petit-lait","word_nosc":"petit-lait","lemma":"petit-lait","pos":"NOM"} ,
		{"word":"petit-maître","word_nosc":"petit-maitre","lemma":"petit-maître","pos":"NOM"} ,
		{"word":"petit-neveu","word_nosc":"petit-neveu","lemma":"petit-neveu","pos":"NOM"} ,
		{"word":"petit-nègre","word_nosc":"petit-negre","lemma":"petit-nègre","pos":"NOM"} ,
		{"word":"petit-salé","word_nosc":"petit-sale","lemma":"petit-salé","pos":"NOM"} ,
		{"word":"petit-suisse","word_nosc":"petit-suisse","lemma":"petit-suisse","pos":"NOM"} ,
		{"word":"petite","word_nosc":"petite","lemma":"petit","pos":"NOM"} ,
		{"word":"petite-bourgeoise","word_nosc":"petite-bourgeoise","lemma":"petite-bourgeoise","pos":"NOM"} ,
		{"word":"petite-cousine","word_nosc":"petite-cousine","lemma":"petite-cousine","pos":"NOM"} ,
		{"word":"petite-fille","word_nosc":"petite-fille","lemma":"petite-fille","pos":"NOM"} ,
		{"word":"petite-nièce","word_nosc":"petite-niece","lemma":"petite-nièce","pos":"NOM"} ,
		{"word":"petites","word_nosc":"petites","lemma":"petit","pos":"NOM"} ,
		{"word":"petites-bourgeoises","word_nosc":"petites-bourgeoises","lemma":"petite-bourgeoise","pos":"NOM"} ,
		{"word":"petites-filles","word_nosc":"petites-filles","lemma":"petite-fille","pos":"NOM"} ,
		{"word":"petitesse","word_nosc":"petitesse","lemma":"petitesse","pos":"NOM"} ,
		{"word":"petitesses","word_nosc":"petitesses","lemma":"petitesse","pos":"NOM"} ,
		{"word":"petits","word_nosc":"petits","lemma":"petit","pos":"NOM"} ,
		{"word":"petits-beurre","word_nosc":"petits-beurre","lemma":"petit-beurre","pos":"NOM"} ,
		{"word":"petits-bourgeois","word_nosc":"petits-bourgeois","lemma":"petit-bourgeois","pos":"NOM"} ,
		{"word":"petits-déjeuners","word_nosc":"petits-dejeuners","lemma":"petit-déjeuner","pos":"NOM"} ,
		{"word":"petits-enfants","word_nosc":"petits-enfants","lemma":"petit-enfant","pos":"NOM"} ,
		{"word":"petits-fils","word_nosc":"petits-fils","lemma":"petit-fils","pos":"NOM"} ,
		{"word":"petits-fours","word_nosc":"petits-fours","lemma":"petit-four","pos":"NOM"} ,
		{"word":"petits-gris","word_nosc":"petits-gris","lemma":"petit-gris","pos":"NOM"} ,
		{"word":"petits-neveux","word_nosc":"petits-neveux","lemma":"petit-neveu","pos":"NOM"} ,
		{"word":"petits-pois","word_nosc":"petits-pois","lemma":"petit-pois","pos":"NOM"} ,
		{"word":"petits-suisses","word_nosc":"petits-suisses","lemma":"petit-suisse","pos":"NOM"} ,
		{"word":"peton","word_nosc":"peton","lemma":"peton","pos":"NOM"} ,
		{"word":"petons","word_nosc":"petons","lemma":"peton","pos":"NOM"} ,
		{"word":"petros","word_nosc":"petros","lemma":"petro","pos":"NOM"} ,
		{"word":"pets","word_nosc":"pets","lemma":"pet","pos":"NOM"} ,
		{"word":"pets-de-nonne","word_nosc":"pets-de-nonne","lemma":"pet-de-nonne","pos":"NOM"} ,
		{"word":"petzouille","word_nosc":"petzouille","lemma":"petzouille","pos":"NOM"} ,
		{"word":"petzouilles","word_nosc":"petzouilles","lemma":"petzouille","pos":"NOM"} ,
		{"word":"peu","word_nosc":"peu","lemma":"peu","pos":"NOM"} ,
		{"word":"peul","word_nosc":"peul","lemma":"peul","pos":"NOM"} ,
		{"word":"peulh","word_nosc":"peulh","lemma":"peulh","pos":"NOM"} ,
		{"word":"peuplade","word_nosc":"peuplade","lemma":"peuplade","pos":"NOM"} ,
		{"word":"peuplades","word_nosc":"peuplades","lemma":"peuplade","pos":"NOM"} ,
		{"word":"peuple","word_nosc":"peuple","lemma":"peuple","pos":"NOM"} ,
		{"word":"peuplement","word_nosc":"peuplement","lemma":"peuplement","pos":"NOM"} ,
		{"word":"peuplements","word_nosc":"peuplements","lemma":"peuplement","pos":"NOM"} ,
		{"word":"peupleraie","word_nosc":"peupleraie","lemma":"peupleraie","pos":"NOM"} ,
		{"word":"peuples","word_nosc":"peuples","lemma":"peuple","pos":"NOM"} ,
		{"word":"peuplier","word_nosc":"peuplier","lemma":"peuplier","pos":"NOM"} ,
		{"word":"peupliers","word_nosc":"peupliers","lemma":"peuplier","pos":"NOM"} ,
		{"word":"peur","word_nosc":"peur","lemma":"peur","pos":"NOM"} ,
		{"word":"peureux","word_nosc":"peureux","lemma":"peureux","pos":"NOM"} ,
		{"word":"peurs","word_nosc":"peurs","lemma":"peur","pos":"NOM"} ,
		{"word":"peyotl","word_nosc":"peyotl","lemma":"peyotl","pos":"NOM"} ,
		{"word":"pfennig","word_nosc":"pfennig","lemma":"pfennig","pos":"NOM"} ,
		{"word":"pfennigs","word_nosc":"pfennigs","lemma":"pfennig","pos":"NOM"} ,
		{"word":"ph","word_nosc":"ph","lemma":"ph","pos":"NOM"} ,
		{"word":"phacochère","word_nosc":"phacochere","lemma":"phacochère","pos":"NOM"} ,
		{"word":"phacochères","word_nosc":"phacocheres","lemma":"phacochère","pos":"NOM"} ,
		{"word":"phagocyte","word_nosc":"phagocyte","lemma":"phagocyte","pos":"NOM"} ,
		{"word":"phagocytes","word_nosc":"phagocytes","lemma":"phagocyte","pos":"NOM"} ,
		{"word":"phalange","word_nosc":"phalange","lemma":"phalange","pos":"NOM"} ,
		{"word":"phalanges","word_nosc":"phalanges","lemma":"phalange","pos":"NOM"} ,
		{"word":"phalangettes","word_nosc":"phalangettes","lemma":"phalangette","pos":"NOM"} ,
		{"word":"phalangine","word_nosc":"phalangine","lemma":"phalangine","pos":"NOM"} ,
		{"word":"phalangisme","word_nosc":"phalangisme","lemma":"phalangisme","pos":"NOM"} ,
		{"word":"phalangiste","word_nosc":"phalangiste","lemma":"phalangiste","pos":"NOM"} ,
		{"word":"phalangistes","word_nosc":"phalangistes","lemma":"phalangiste","pos":"NOM"} ,
		{"word":"phalanstère","word_nosc":"phalanstere","lemma":"phalanstère","pos":"NOM"} ,
		{"word":"phalarope","word_nosc":"phalarope","lemma":"phalarope","pos":"NOM"} ,
		{"word":"phallo","word_nosc":"phallo","lemma":"phallo","pos":"NOM"} ,
		{"word":"phallocrate","word_nosc":"phallocrate","lemma":"phallocrate","pos":"NOM"} ,
		{"word":"phallocratie","word_nosc":"phallocratie","lemma":"phallocratie","pos":"NOM"} ,
		{"word":"phallologie","word_nosc":"phallologie","lemma":"phallologie","pos":"NOM"} ,
		{"word":"phallophore","word_nosc":"phallophore","lemma":"phallophore","pos":"NOM"} ,
		{"word":"phallus","word_nosc":"phallus","lemma":"phallus","pos":"NOM"} ,
		{"word":"phalène","word_nosc":"phalene","lemma":"phalène","pos":"NOM"} ,
		{"word":"phalènes","word_nosc":"phalenes","lemma":"phalène","pos":"NOM"} ,
		{"word":"phalère","word_nosc":"phalere","lemma":"phalère","pos":"NOM"} ,
		{"word":"phantasme","word_nosc":"phantasme","lemma":"phantasme","pos":"NOM"} ,
		{"word":"phantasmes","word_nosc":"phantasmes","lemma":"phantasme","pos":"NOM"} ,
		{"word":"phanères","word_nosc":"phaneres","lemma":"phanère","pos":"NOM"} ,
		{"word":"phanérogames","word_nosc":"phanerogames","lemma":"phanérogame","pos":"NOM"} ,
		{"word":"pharaon","word_nosc":"pharaon","lemma":"pharaon","pos":"NOM"} ,
		{"word":"pharaonnes","word_nosc":"pharaonnes","lemma":"pharaon","pos":"NOM"} ,
		{"word":"pharaons","word_nosc":"pharaons","lemma":"pharaon","pos":"NOM"} ,
		{"word":"phare","word_nosc":"phare","lemma":"phare","pos":"NOM"} ,
		{"word":"phares","word_nosc":"phares","lemma":"phare","pos":"NOM"} ,
		{"word":"pharisaïsme","word_nosc":"pharisaisme","lemma":"pharisaïsme","pos":"NOM"} ,
		{"word":"pharisaïsmes","word_nosc":"pharisaismes","lemma":"pharisaïsme","pos":"NOM"} ,
		{"word":"pharisien","word_nosc":"pharisien","lemma":"pharisien","pos":"NOM"} ,
		{"word":"pharisiens","word_nosc":"pharisiens","lemma":"pharisien","pos":"NOM"} ,
		{"word":"pharma","word_nosc":"pharma","lemma":"pharma","pos":"NOM"} ,
		{"word":"pharmacie","word_nosc":"pharmacie","lemma":"pharmacie","pos":"NOM"} ,
		{"word":"pharmacien","word_nosc":"pharmacien","lemma":"pharmacien","pos":"NOM"} ,
		{"word":"pharmacienne","word_nosc":"pharmacienne","lemma":"pharmacien","pos":"NOM"} ,
		{"word":"pharmaciens","word_nosc":"pharmaciens","lemma":"pharmacien","pos":"NOM"} ,
		{"word":"pharmacies","word_nosc":"pharmacies","lemma":"pharmacie","pos":"NOM"} ,
		{"word":"pharmaco","word_nosc":"pharmaco","lemma":"pharmaco","pos":"NOM"} ,
		{"word":"pharmacognosie","word_nosc":"pharmacognosie","lemma":"pharmacognosie","pos":"NOM"} ,
		{"word":"pharmacologie","word_nosc":"pharmacologie","lemma":"pharmacologie","pos":"NOM"} ,
		{"word":"pharmacomanie","word_nosc":"pharmacomanie","lemma":"pharmacomanie","pos":"NOM"} ,
		{"word":"pharmacopée","word_nosc":"pharmacopee","lemma":"pharmacopée","pos":"NOM"} ,
		{"word":"pharmacothérapie","word_nosc":"pharmacotherapie","lemma":"pharmacothérapie","pos":"NOM"} ,
		{"word":"pharyngite","word_nosc":"pharyngite","lemma":"pharyngite","pos":"NOM"} ,
		{"word":"pharynx","word_nosc":"pharynx","lemma":"pharynx","pos":"NOM"} ,
		{"word":"phascolome","word_nosc":"phascolome","lemma":"phascolome","pos":"NOM"} ,
		{"word":"phase","word_nosc":"phase","lemma":"phase","pos":"NOM"} ,
		{"word":"phases","word_nosc":"phases","lemma":"phase","pos":"NOM"} ,
		{"word":"phasmes","word_nosc":"phasmes","lemma":"phasme","pos":"NOM"} ,
		{"word":"phaéton","word_nosc":"phaeton","lemma":"phaéton","pos":"NOM"} ,
		{"word":"phi","word_nosc":"phi","lemma":"phi","pos":"NOM"} ,
		{"word":"philanthrope","word_nosc":"philanthrope","lemma":"philanthrope","pos":"NOM"} ,
		{"word":"philanthropes","word_nosc":"philanthropes","lemma":"philanthrope","pos":"NOM"} ,
		{"word":"philanthropie","word_nosc":"philanthropie","lemma":"philanthropie","pos":"NOM"} ,
		{"word":"philarète","word_nosc":"philarete","lemma":"philarète","pos":"NOM"} ,
		{"word":"philatélie","word_nosc":"philatelie","lemma":"philatélie","pos":"NOM"} ,
		{"word":"philatéliste","word_nosc":"philateliste","lemma":"philatéliste","pos":"NOM"} ,
		{"word":"philatélistes","word_nosc":"philatelistes","lemma":"philatéliste","pos":"NOM"} ,
		{"word":"philharmonie","word_nosc":"philharmonie","lemma":"philharmonie","pos":"NOM"} ,
		{"word":"philhellène","word_nosc":"philhellene","lemma":"philhellène","pos":"NOM"} ,
		{"word":"philhellènes","word_nosc":"philhellenes","lemma":"philhellène","pos":"NOM"} ,
		{"word":"philippin","word_nosc":"philippin","lemma":"philippin","pos":"NOM"} ,
		{"word":"philippins","word_nosc":"philippins","lemma":"philippin","pos":"NOM"} ,
		{"word":"philistin","word_nosc":"philistin","lemma":"philistin","pos":"NOM"} ,
		{"word":"philistins","word_nosc":"philistins","lemma":"philistin","pos":"NOM"} ,
		{"word":"philo","word_nosc":"philo","lemma":"philo","pos":"NOM"} ,
		{"word":"philodendron","word_nosc":"philodendron","lemma":"philodendron","pos":"NOM"} ,
		{"word":"philodendrons","word_nosc":"philodendrons","lemma":"philodendron","pos":"NOM"} ,
		{"word":"philologie","word_nosc":"philologie","lemma":"philologie","pos":"NOM"} ,
		{"word":"philologue","word_nosc":"philologue","lemma":"philologue","pos":"NOM"} ,
		{"word":"philologues","word_nosc":"philologues","lemma":"philologue","pos":"NOM"} ,
		{"word":"philos","word_nosc":"philos","lemma":"philo","pos":"NOM"} ,
		{"word":"philosophaillerie","word_nosc":"philosophaillerie","lemma":"philosophaillerie","pos":"NOM"} ,
		{"word":"philosophe","word_nosc":"philosophe","lemma":"philosophe","pos":"NOM"} ,
		{"word":"philosophes","word_nosc":"philosophes","lemma":"philosophe","pos":"NOM"} ,
		{"word":"philosophie","word_nosc":"philosophie","lemma":"philosophie","pos":"NOM"} ,
		{"word":"philosophies","word_nosc":"philosophies","lemma":"philosophie","pos":"NOM"} ,
		{"word":"philosémites","word_nosc":"philosemites","lemma":"philosémite","pos":"NOM"} ,
		{"word":"philosémitisme","word_nosc":"philosemitisme","lemma":"philosémitisme","pos":"NOM"} ,
		{"word":"philtre","word_nosc":"philtre","lemma":"philtre","pos":"NOM"} ,
		{"word":"philtres","word_nosc":"philtres","lemma":"philtre","pos":"NOM"} ,
		{"word":"phimosis","word_nosc":"phimosis","lemma":"phimosis","pos":"NOM"} ,
		{"word":"phlox","word_nosc":"phlox","lemma":"phlox","pos":"NOM"} ,
		{"word":"phlébite","word_nosc":"phlebite","lemma":"phlébite","pos":"NOM"} ,
		{"word":"phlébites","word_nosc":"phlebites","lemma":"phlébite","pos":"NOM"} ,
		{"word":"phlébographie","word_nosc":"phlebographie","lemma":"phlébographie","pos":"NOM"} ,
		{"word":"phlébologue","word_nosc":"phlebologue","lemma":"phlébologue","pos":"NOM"} ,
		{"word":"phlébotomie","word_nosc":"phlebotomie","lemma":"phlébotomie","pos":"NOM"} ,
		{"word":"phobie","word_nosc":"phobie","lemma":"phobie","pos":"NOM"} ,
		{"word":"phobies","word_nosc":"phobies","lemma":"phobie","pos":"NOM"} ,
		{"word":"phoenix","word_nosc":"phoenix","lemma":"phoenix","pos":"NOM"} ,
		{"word":"phone","word_nosc":"phone","lemma":"phone","pos":"NOM"} ,
		{"word":"phones","word_nosc":"phones","lemma":"phone","pos":"NOM"} ,
		{"word":"phono","word_nosc":"phono","lemma":"phono","pos":"NOM"} ,
		{"word":"phonographe","word_nosc":"phonographe","lemma":"phonographe","pos":"NOM"} ,
		{"word":"phonographes","word_nosc":"phonographes","lemma":"phonographe","pos":"NOM"} ,
		{"word":"phonologie","word_nosc":"phonologie","lemma":"phonologie","pos":"NOM"} ,
		{"word":"phonos","word_nosc":"phonos","lemma":"phono","pos":"NOM"} ,
		{"word":"phonèmes","word_nosc":"phonemes","lemma":"phonème","pos":"NOM"} ,
		{"word":"phonéticien","word_nosc":"phoneticien","lemma":"phonéticien","pos":"NOM"} ,
		{"word":"phonétique","word_nosc":"phonetique","lemma":"phonétique","pos":"NOM"} ,
		{"word":"phoque","word_nosc":"phoque","lemma":"phoque","pos":"NOM"} ,
		{"word":"phoques","word_nosc":"phoques","lemma":"phoque","pos":"NOM"} ,
		{"word":"phosgène","word_nosc":"phosgene","lemma":"phosgène","pos":"NOM"} ,
		{"word":"phosphatase","word_nosc":"phosphatase","lemma":"phosphatase","pos":"NOM"} ,
		{"word":"phosphate","word_nosc":"phosphate","lemma":"phosphate","pos":"NOM"} ,
		{"word":"phosphates","word_nosc":"phosphates","lemma":"phosphate","pos":"NOM"} ,
		{"word":"phosphatine","word_nosc":"phosphatine","lemma":"phosphatine","pos":"NOM"} ,
		{"word":"phospholipide","word_nosc":"phospholipide","lemma":"phospholipide","pos":"NOM"} ,
		{"word":"phosphore","word_nosc":"phosphore","lemma":"phosphore","pos":"NOM"} ,
		{"word":"phosphorescence","word_nosc":"phosphorescence","lemma":"phosphorescence","pos":"NOM"} ,
		{"word":"phosphorescences","word_nosc":"phosphorescences","lemma":"phosphorescence","pos":"NOM"} ,
		{"word":"phosphorylation","word_nosc":"phosphorylation","lemma":"phosphorylation","pos":"NOM"} ,
		{"word":"phosphène","word_nosc":"phosphene","lemma":"phosphène","pos":"NOM"} ,
		{"word":"phosphènes","word_nosc":"phosphenes","lemma":"phosphène","pos":"NOM"} ,
		{"word":"phot","word_nosc":"phot","lemma":"phot","pos":"NOM"} ,
		{"word":"photo","word_nosc":"photo","lemma":"photo","pos":"NOM"} ,
		{"word":"photo-finish","word_nosc":"photo-finish","lemma":"photo-finish","pos":"NOM"} ,
		{"word":"photo-robot","word_nosc":"photo-robot","lemma":"photo-robot","pos":"NOM"} ,
		{"word":"photocomposition","word_nosc":"photocomposition","lemma":"photocomposition","pos":"NOM"} ,
		{"word":"photocopie","word_nosc":"photocopie","lemma":"photocopie","pos":"NOM"} ,
		{"word":"photocopies","word_nosc":"photocopies","lemma":"photocopie","pos":"NOM"} ,
		{"word":"photocopieur","word_nosc":"photocopieur","lemma":"photocopieur","pos":"NOM"} ,
		{"word":"photocopieurs","word_nosc":"photocopieurs","lemma":"photocopieur","pos":"NOM"} ,
		{"word":"photocopieuse","word_nosc":"photocopieuse","lemma":"photocopieur","pos":"NOM"} ,
		{"word":"photocopieuses","word_nosc":"photocopieuses","lemma":"photocopieur","pos":"NOM"} ,
		{"word":"photogramme","word_nosc":"photogramme","lemma":"photogramme","pos":"NOM"} ,
		{"word":"photographe","word_nosc":"photographe","lemma":"photographe","pos":"NOM"} ,
		{"word":"photographes","word_nosc":"photographes","lemma":"photographe","pos":"NOM"} ,
		{"word":"photographiable","word_nosc":"photographiable","lemma":"photographiable","pos":"NOM"} ,
		{"word":"photographie","word_nosc":"photographie","lemma":"photographie","pos":"NOM"} ,
		{"word":"photographies","word_nosc":"photographies","lemma":"photographie","pos":"NOM"} ,
		{"word":"photograveur","word_nosc":"photograveur","lemma":"photograveur","pos":"NOM"} ,
		{"word":"photogravure","word_nosc":"photogravure","lemma":"photogravure","pos":"NOM"} ,
		{"word":"photogénie","word_nosc":"photogenie","lemma":"photogénie","pos":"NOM"} ,
		{"word":"photojournalisme","word_nosc":"photojournalisme","lemma":"photojournalisme","pos":"NOM"} ,
		{"word":"photojournaliste","word_nosc":"photojournaliste","lemma":"photojournaliste","pos":"NOM"} ,
		{"word":"photomicrographie","word_nosc":"photomicrographie","lemma":"photomicrographie","pos":"NOM"} ,
		{"word":"photomontage","word_nosc":"photomontage","lemma":"photomontage","pos":"NOM"} ,
		{"word":"photomontages","word_nosc":"photomontages","lemma":"photomontage","pos":"NOM"} ,
		{"word":"photon","word_nosc":"photon","lemma":"photon","pos":"NOM"} ,
		{"word":"photons","word_nosc":"photons","lemma":"photon","pos":"NOM"} ,
		{"word":"photophobie","word_nosc":"photophobie","lemma":"photophobie","pos":"NOM"} ,
		{"word":"photopile","word_nosc":"photopile","lemma":"photopile","pos":"NOM"} ,
		{"word":"photos","word_nosc":"photos","lemma":"photo","pos":"NOM"} ,
		{"word":"photosensibilité","word_nosc":"photosensibilite","lemma":"photosensibilité","pos":"NOM"} ,
		{"word":"photostat","word_nosc":"photostat","lemma":"photostat","pos":"NOM"} ,
		{"word":"photosynthèse","word_nosc":"photosynthese","lemma":"photosynthèse","pos":"NOM"} ,
		{"word":"phototaxie","word_nosc":"phototaxie","lemma":"phototaxie","pos":"NOM"} ,
		{"word":"phototype","word_nosc":"phototype","lemma":"phototype","pos":"NOM"} ,
		{"word":"phragmites","word_nosc":"phragmites","lemma":"phragmite","pos":"NOM"} ,
		{"word":"phrase","word_nosc":"phrase","lemma":"phrase","pos":"NOM"} ,
		{"word":"phrase-clé","word_nosc":"phrase-cle","lemma":"phrase-clé","pos":"NOM"} ,
		{"word":"phrase-robot","word_nosc":"phrase-robot","lemma":"phrase-robot","pos":"NOM"} ,
		{"word":"phrases","word_nosc":"phrases","lemma":"phrase","pos":"NOM"} ,
		{"word":"phrases-clés","word_nosc":"phrases-cles","lemma":"phrase-clé","pos":"NOM"} ,
		{"word":"phraseur","word_nosc":"phraseur","lemma":"phraseur","pos":"NOM"} ,
		{"word":"phraseurs","word_nosc":"phraseurs","lemma":"phraseur","pos":"NOM"} ,
		{"word":"phrasé","word_nosc":"phrase","lemma":"phrasé","pos":"NOM"} ,
		{"word":"phraséologie","word_nosc":"phraseologie","lemma":"phraséologie","pos":"NOM"} ,
		{"word":"phraséologies","word_nosc":"phraseologies","lemma":"phraséologie","pos":"NOM"} ,
		{"word":"phrygiens","word_nosc":"phrygiens","lemma":"phrygien","pos":"NOM"} ,
		{"word":"phrénologie","word_nosc":"phrenologie","lemma":"phrénologie","pos":"NOM"} ,
		{"word":"phrénologiste","word_nosc":"phrenologiste","lemma":"phrénologiste","pos":"NOM"} ,
		{"word":"phrénologistes","word_nosc":"phrenologistes","lemma":"phrénologiste","pos":"NOM"} ,
		{"word":"phrénologues","word_nosc":"phrenologues","lemma":"phrénologue","pos":"NOM"} ,
		{"word":"phtaléine","word_nosc":"phtaleine","lemma":"phtaléine","pos":"NOM"} ,
		{"word":"phtiriasis","word_nosc":"phtiriasis","lemma":"phtiriasis","pos":"NOM"} ,
		{"word":"phtisie","word_nosc":"phtisie","lemma":"phtisie","pos":"NOM"} ,
		{"word":"phtisiologie","word_nosc":"phtisiologie","lemma":"phtisiologie","pos":"NOM"} ,
		{"word":"phylactère","word_nosc":"phylactere","lemma":"phylactère","pos":"NOM"} ,
		{"word":"phylloxera","word_nosc":"phylloxera","lemma":"phylloxera","pos":"NOM"} ,
		{"word":"phylloxéra","word_nosc":"phylloxera","lemma":"phylloxéra","pos":"NOM"} ,
		{"word":"phylogenèse","word_nosc":"phylogenese","lemma":"phylogenèse","pos":"NOM"} ,
		{"word":"phylum","word_nosc":"phylum","lemma":"phylum","pos":"NOM"} ,
		{"word":"phynances","word_nosc":"phynances","lemma":"phynances","pos":"NOM"} ,
		{"word":"physicien","word_nosc":"physicien","lemma":"physicien","pos":"NOM"} ,
		{"word":"physicienne","word_nosc":"physicienne","lemma":"physicien","pos":"NOM"} ,
		{"word":"physiciennes","word_nosc":"physiciennes","lemma":"physicien","pos":"NOM"} ,
		{"word":"physiciens","word_nosc":"physiciens","lemma":"physicien","pos":"NOM"} ,
		{"word":"physiologie","word_nosc":"physiologie","lemma":"physiologie","pos":"NOM"} ,
		{"word":"physiologies","word_nosc":"physiologies","lemma":"physiologie","pos":"NOM"} ,
		{"word":"physiologiste","word_nosc":"physiologiste","lemma":"physiologiste","pos":"NOM"} ,
		{"word":"physionomie","word_nosc":"physionomie","lemma":"physionomie","pos":"NOM"} ,
		{"word":"physionomies","word_nosc":"physionomies","lemma":"physionomie","pos":"NOM"} ,
		{"word":"physionomiste","word_nosc":"physionomiste","lemma":"physionomiste","pos":"NOM"} ,
		{"word":"physionomistes","word_nosc":"physionomistes","lemma":"physionomiste","pos":"NOM"} ,
		{"word":"physiothérapie","word_nosc":"physiotherapie","lemma":"physiothérapie","pos":"NOM"} ,
		{"word":"physique","word_nosc":"physique","lemma":"physique","pos":"NOM"} ,
		{"word":"physiques","word_nosc":"physiques","lemma":"physique","pos":"NOM"} ,
		{"word":"phytoplancton","word_nosc":"phytoplancton","lemma":"phytoplancton","pos":"NOM"} ,
		{"word":"phénate","word_nosc":"phenate","lemma":"phénate","pos":"NOM"} ,
		{"word":"phénicien","word_nosc":"phenicien","lemma":"phénicien","pos":"NOM"} ,
		{"word":"phéniciens","word_nosc":"pheniciens","lemma":"phénicien","pos":"NOM"} ,
		{"word":"phénix","word_nosc":"phenix","lemma":"phénix","pos":"NOM"} ,
		{"word":"phénobarbital","word_nosc":"phenobarbital","lemma":"phénobarbital","pos":"NOM"} ,
		{"word":"phénol","word_nosc":"phenol","lemma":"phénol","pos":"NOM"} ,
		{"word":"phénomène","word_nosc":"phenomene","lemma":"phénomène","pos":"NOM"} ,
		{"word":"phénomènes","word_nosc":"phenomenes","lemma":"phénomène","pos":"NOM"} ,
		{"word":"phénoménologie","word_nosc":"phenomenologie","lemma":"phénoménologie","pos":"NOM"} ,
		{"word":"phénoménologues","word_nosc":"phenomenologues","lemma":"phénoménologue","pos":"NOM"} ,
		{"word":"phénothiazine","word_nosc":"phenothiazine","lemma":"phénothiazine","pos":"NOM"} ,
		{"word":"phénotype","word_nosc":"phenotype","lemma":"phénotype","pos":"NOM"} ,
		{"word":"phéochromocytome","word_nosc":"pheochromocytome","lemma":"phéochromocytome","pos":"NOM"} ,
		{"word":"phéromone","word_nosc":"pheromone","lemma":"phéromone","pos":"NOM"} ,
		{"word":"phéromones","word_nosc":"pheromones","lemma":"phéromone","pos":"NOM"} ,
		{"word":"pi","word_nosc":"pi","lemma":"pi","pos":"NOM"} ,
		{"word":"piaf","word_nosc":"piaf","lemma":"piaf","pos":"NOM"} ,
		{"word":"piaffement","word_nosc":"piaffement","lemma":"piaffement","pos":"NOM"} ,
		{"word":"piaffements","word_nosc":"piaffements","lemma":"piaffement","pos":"NOM"} ,
		{"word":"piafs","word_nosc":"piafs","lemma":"piaf","pos":"NOM"} ,
		{"word":"piaillement","word_nosc":"piaillement","lemma":"piaillement","pos":"NOM"} ,
		{"word":"piaillements","word_nosc":"piaillements","lemma":"piaillement","pos":"NOM"} ,
		{"word":"piaillerie","word_nosc":"piaillerie","lemma":"piaillerie","pos":"NOM"} ,
		{"word":"piailleries","word_nosc":"piailleries","lemma":"piaillerie","pos":"NOM"} ,
		{"word":"pian","word_nosc":"pian","lemma":"pian","pos":"NOM"} ,
		{"word":"pianissimo","word_nosc":"pianissimo","lemma":"pianissimo","pos":"NOM"} ,
		{"word":"pianissimos","word_nosc":"pianissimos","lemma":"pianissimo","pos":"NOM"} ,
		{"word":"pianiste","word_nosc":"pianiste","lemma":"pianiste","pos":"NOM"} ,
		{"word":"pianistes","word_nosc":"pianistes","lemma":"pianiste","pos":"NOM"} ,
		{"word":"piano","word_nosc":"piano","lemma":"piano","pos":"NOM"} ,
		{"word":"piano-bar","word_nosc":"piano-bar","lemma":"piano-bar","pos":"NOM"} ,
		{"word":"pianola","word_nosc":"pianola","lemma":"pianola","pos":"NOM"} ,
		{"word":"pianos","word_nosc":"pianos","lemma":"piano","pos":"NOM"} ,
		{"word":"pianotage","word_nosc":"pianotage","lemma":"pianotage","pos":"NOM"} ,
		{"word":"piastre","word_nosc":"piastre","lemma":"piastre","pos":"NOM"} ,
		{"word":"piastres","word_nosc":"piastres","lemma":"piastre","pos":"NOM"} ,
		{"word":"piaule","word_nosc":"piaule","lemma":"piaule","pos":"NOM"} ,
		{"word":"piaulement","word_nosc":"piaulement","lemma":"piaulement","pos":"NOM"} ,
		{"word":"piaulements","word_nosc":"piaulements","lemma":"piaulement","pos":"NOM"} ,
		{"word":"piaules","word_nosc":"piaules","lemma":"piaule","pos":"NOM"} ,
		{"word":"piauleur","word_nosc":"piauleur","lemma":"piauleur","pos":"NOM"} ,
		{"word":"piaye","word_nosc":"piaye","lemma":"piaye","pos":"NOM"} ,
		{"word":"piazza","word_nosc":"piazza","lemma":"piazza","pos":"NOM"} ,
		{"word":"piazzetta","word_nosc":"piazzetta","lemma":"piazzetta","pos":"NOM"} ,
		{"word":"pibloque","word_nosc":"pibloque","lemma":"pibloque","pos":"NOM"} ,
		{"word":"pic","word_nosc":"pic","lemma":"pic","pos":"NOM"} ,
		{"word":"pic-vert","word_nosc":"pic-vert","lemma":"pic-vert","pos":"NOM"} ,
		{"word":"pica","word_nosc":"pica","lemma":"pica","pos":"NOM"} ,
		{"word":"picador","word_nosc":"picador","lemma":"picador","pos":"NOM"} ,
		{"word":"picadors","word_nosc":"picadors","lemma":"picador","pos":"NOM"} ,
		{"word":"picaillon","word_nosc":"picaillon","lemma":"picaillon","pos":"NOM"} ,
		{"word":"picaillons","word_nosc":"picaillons","lemma":"picaillon","pos":"NOM"} ,
		{"word":"picards","word_nosc":"picards","lemma":"picard","pos":"NOM"} ,
		{"word":"picaro","word_nosc":"picaro","lemma":"picaro","pos":"NOM"} ,
		{"word":"piccolo","word_nosc":"piccolo","lemma":"piccolo","pos":"NOM"} ,
		{"word":"piccolos","word_nosc":"piccolos","lemma":"piccolo","pos":"NOM"} ,
		{"word":"pichenette","word_nosc":"pichenette","lemma":"pichenette","pos":"NOM"} ,
		{"word":"pichenettes","word_nosc":"pichenettes","lemma":"pichenette","pos":"NOM"} ,
		{"word":"pichet","word_nosc":"pichet","lemma":"pichet","pos":"NOM"} ,
		{"word":"pichets","word_nosc":"pichets","lemma":"pichet","pos":"NOM"} ,
		{"word":"pick-up","word_nosc":"pick-up","lemma":"pick-up","pos":"NOM"} ,
		{"word":"picker","word_nosc":"picker","lemma":"picker","pos":"NOM"} ,
		{"word":"pickles","word_nosc":"pickles","lemma":"pickle","pos":"NOM"} ,
		{"word":"pickpocket","word_nosc":"pickpocket","lemma":"pickpocket","pos":"NOM"} ,
		{"word":"pickpockets","word_nosc":"pickpockets","lemma":"pickpocket","pos":"NOM"} ,
		{"word":"picoleur","word_nosc":"picoleur","lemma":"picoleur","pos":"NOM"} ,
		{"word":"picoleurs","word_nosc":"picoleurs","lemma":"picoleur","pos":"NOM"} ,
		{"word":"picolo","word_nosc":"picolo","lemma":"picolo","pos":"NOM"} ,
		{"word":"picoreur","word_nosc":"picoreur","lemma":"picoreur","pos":"NOM"} ,
		{"word":"picorée","word_nosc":"picoree","lemma":"picorée","pos":"NOM"} ,
		{"word":"picot","word_nosc":"picot","lemma":"picot","pos":"NOM"} ,
		{"word":"picote","word_nosc":"picote","lemma":"picote","pos":"NOM"} ,
		{"word":"picotement","word_nosc":"picotement","lemma":"picotement","pos":"NOM"} ,
		{"word":"picotements","word_nosc":"picotements","lemma":"picotement","pos":"NOM"} ,
		{"word":"picotin","word_nosc":"picotin","lemma":"picotin","pos":"NOM"} ,
		{"word":"picotis","word_nosc":"picotis","lemma":"picotis","pos":"NOM"} ,
		{"word":"picots","word_nosc":"picots","lemma":"picot","pos":"NOM"} ,
		{"word":"picrate","word_nosc":"picrate","lemma":"picrate","pos":"NOM"} ,
		{"word":"pics","word_nosc":"pics","lemma":"pic","pos":"NOM"} ,
		{"word":"pics-verts","word_nosc":"pics-verts","lemma":"pic-vert","pos":"NOM"} ,
		{"word":"picsou","word_nosc":"picsou","lemma":"picsou","pos":"NOM"} ,
		{"word":"pictogramme","word_nosc":"pictogramme","lemma":"pictogramme","pos":"NOM"} ,
		{"word":"pictogrammes","word_nosc":"pictogrammes","lemma":"pictogramme","pos":"NOM"} ,
		{"word":"picton","word_nosc":"picton","lemma":"picton","pos":"NOM"} ,
		{"word":"pidgin","word_nosc":"pidgin","lemma":"pidgin","pos":"NOM"} ,
		{"word":"pie","word_nosc":"pie","lemma":"pie","pos":"NOM"} ,
		{"word":"pie-grièche","word_nosc":"pie-grieche","lemma":"pie-grièche","pos":"NOM"} ,
		{"word":"pie-mère","word_nosc":"pie-mere","lemma":"pie-mère","pos":"NOM"} ,
		{"word":"pied","word_nosc":"pied","lemma":"pied","pos":"NOM"} ,
		{"word":"pied-bot","word_nosc":"pied-bot","lemma":"pied-bot","pos":"NOM"} ,
		{"word":"pied-d'alouette","word_nosc":"pied-d'alouette","lemma":"pied-d'alouette","pos":"NOM"} ,
		{"word":"pied-d'oeuvre","word_nosc":"pied-d'oeuvre","lemma":"pied-d'oeuvre","pos":"NOM"} ,
		{"word":"pied-de-biche","word_nosc":"pied-de-biche","lemma":"pied-de-biche","pos":"NOM"} ,
		{"word":"pied-de-poule","word_nosc":"pied-de-poule","lemma":"pied-de-poule","pos":"NOM"} ,
		{"word":"pied-noir","word_nosc":"pied-noir","lemma":"pied-noir","pos":"NOM"} ,
		{"word":"pied-plat","word_nosc":"pied-plat","lemma":"pied-plat","pos":"NOM"} ,
		{"word":"piedmont","word_nosc":"piedmont","lemma":"piedmont","pos":"NOM"} ,
		{"word":"pieds","word_nosc":"pieds","lemma":"pied","pos":"NOM"} ,
		{"word":"pieds-de-biche","word_nosc":"pieds-de-biche","lemma":"pied-de-biche","pos":"NOM"} ,
		{"word":"pieds-de-coq","word_nosc":"pieds-de-coq","lemma":"pied-de-coq","pos":"NOM"} ,
		{"word":"pieds-droits","word_nosc":"pieds-droits","lemma":"pied-droit","pos":"NOM"} ,
		{"word":"pieds-noirs","word_nosc":"pieds-noirs","lemma":"pied-noir","pos":"NOM"} ,
		{"word":"pier","word_nosc":"pier","lemma":"pier","pos":"NOM"} ,
		{"word":"piercing","word_nosc":"piercing","lemma":"piercing","pos":"NOM"} ,
		{"word":"piercings","word_nosc":"piercings","lemma":"piercing","pos":"NOM"} ,
		{"word":"pierraille","word_nosc":"pierraille","lemma":"pierraille","pos":"NOM"} ,
		{"word":"pierrailles","word_nosc":"pierrailles","lemma":"pierraille","pos":"NOM"} ,
		{"word":"pierre","word_nosc":"pierre","lemma":"pierre","pos":"NOM"} ,
		{"word":"pierreries","word_nosc":"pierreries","lemma":"pierreries","pos":"NOM"} ,
		{"word":"pierres","word_nosc":"pierres","lemma":"pierre","pos":"NOM"} ,
		{"word":"pierrier","word_nosc":"pierrier","lemma":"pierrier","pos":"NOM"} ,
		{"word":"pierriers","word_nosc":"pierriers","lemma":"pierrier","pos":"NOM"} ,
		{"word":"pierrière","word_nosc":"pierriere","lemma":"pierrière","pos":"NOM"} ,
		{"word":"pierrot","word_nosc":"pierrot","lemma":"pierrot","pos":"NOM"} ,
		{"word":"pierrots","word_nosc":"pierrots","lemma":"pierrot","pos":"NOM"} ,
		{"word":"pierrures","word_nosc":"pierrures","lemma":"pierrure","pos":"NOM"} ,
		{"word":"pies","word_nosc":"pies","lemma":"pie","pos":"NOM"} ,
		{"word":"pietà","word_nosc":"pieta","lemma":"pietà","pos":"NOM"} ,
		{"word":"pieu","word_nosc":"pieu","lemma":"pieu","pos":"NOM"} ,
		{"word":"pieuvre","word_nosc":"pieuvre","lemma":"pieuvre","pos":"NOM"} ,
		{"word":"pieuvres","word_nosc":"pieuvres","lemma":"pieuvre","pos":"NOM"} ,
		{"word":"pieux","word_nosc":"pieux","lemma":"pieux","pos":"NOM"} ,
		{"word":"pif","word_nosc":"pif","lemma":"pif","pos":"NOM"} ,
		{"word":"piffre","word_nosc":"piffre","lemma":"piffre","pos":"NOM"} ,
		{"word":"pifomètre","word_nosc":"pifometre","lemma":"pifomètre","pos":"NOM"} ,
		{"word":"pige","word_nosc":"pige","lemma":"pige","pos":"NOM"} ,
		{"word":"pigeon","word_nosc":"pigeon","lemma":"pigeon","pos":"NOM"} ,
		{"word":"pigeon-voyageur","word_nosc":"pigeon-voyageur","lemma":"pigeon-voyageur","pos":"NOM"} ,
		{"word":"pigeonne","word_nosc":"pigeonne","lemma":"pigeon","pos":"NOM"} ,
		{"word":"pigeonneau","word_nosc":"pigeonneau","lemma":"pigeonneau","pos":"NOM"} ,
		{"word":"pigeonneaux","word_nosc":"pigeonneaux","lemma":"pigeonneau","pos":"NOM"} ,
		{"word":"pigeonnier","word_nosc":"pigeonnier","lemma":"pigeonnier","pos":"NOM"} ,
		{"word":"pigeonniers","word_nosc":"pigeonniers","lemma":"pigeonnier","pos":"NOM"} ,
		{"word":"pigeons","word_nosc":"pigeons","lemma":"pigeon","pos":"NOM"} ,
		{"word":"piges","word_nosc":"piges","lemma":"pige","pos":"NOM"} ,
		{"word":"pigiste","word_nosc":"pigiste","lemma":"pigiste","pos":"NOM"} ,
		{"word":"pigistes","word_nosc":"pigistes","lemma":"pigiste","pos":"NOM"} ,
		{"word":"pigment","word_nosc":"pigment","lemma":"pigment","pos":"NOM"} ,
		{"word":"pigmentation","word_nosc":"pigmentation","lemma":"pigmentation","pos":"NOM"} ,
		{"word":"pigments","word_nosc":"pigments","lemma":"pigment","pos":"NOM"} ,
		{"word":"pigne","word_nosc":"pigne","lemma":"pigne","pos":"NOM"} ,
		{"word":"pignes","word_nosc":"pignes","lemma":"pigne","pos":"NOM"} ,
		{"word":"pignole","word_nosc":"pignole","lemma":"pignole","pos":"NOM"} ,
		{"word":"pignon","word_nosc":"pignon","lemma":"pignon","pos":"NOM"} ,
		{"word":"pignons","word_nosc":"pignons","lemma":"pignon","pos":"NOM"} ,
		{"word":"pignouf","word_nosc":"pignouf","lemma":"pignouf","pos":"NOM"} ,
		{"word":"pignoufs","word_nosc":"pignoufs","lemma":"pignouf","pos":"NOM"} ,
		{"word":"pilaf","word_nosc":"pilaf","lemma":"pilaf","pos":"NOM"} ,
		{"word":"pilastre","word_nosc":"pilastre","lemma":"pilastre","pos":"NOM"} ,
		{"word":"pilastres","word_nosc":"pilastres","lemma":"pilastre","pos":"NOM"} ,
		{"word":"pilchards","word_nosc":"pilchards","lemma":"pilchard","pos":"NOM"} ,
		{"word":"pile","word_nosc":"pile","lemma":"pil","pos":"NOM"} ,
		{"word":"pile","word_nosc":"pile","lemma":"pile","pos":"NOM"} ,
		{"word":"pile-poil","word_nosc":"pile-poil","lemma":"pile-poil","pos":"NOM"} ,
		{"word":"piles","word_nosc":"piles","lemma":"pile","pos":"NOM"} ,
		{"word":"pileuses","word_nosc":"pileuses","lemma":"pileur","pos":"NOM"} ,
		{"word":"pilier","word_nosc":"pilier","lemma":"pilier","pos":"NOM"} ,
		{"word":"piliers","word_nosc":"piliers","lemma":"pilier","pos":"NOM"} ,
		{"word":"pillage","word_nosc":"pillage","lemma":"pillage","pos":"NOM"} ,
		{"word":"pillages","word_nosc":"pillages","lemma":"pillage","pos":"NOM"} ,
		{"word":"pillard","word_nosc":"pillard","lemma":"pillard","pos":"NOM"} ,
		{"word":"pillarde","word_nosc":"pillarde","lemma":"pillard","pos":"NOM"} ,
		{"word":"pillards","word_nosc":"pillards","lemma":"pillard","pos":"NOM"} ,
		{"word":"pilleur","word_nosc":"pilleur","lemma":"pilleur","pos":"NOM"} ,
		{"word":"pilleurs","word_nosc":"pilleurs","lemma":"pilleur","pos":"NOM"} ,
		{"word":"pilleuse","word_nosc":"pilleuse","lemma":"pilleur","pos":"NOM"} ,
		{"word":"piloches","word_nosc":"piloches","lemma":"piloche","pos":"NOM"} ,
		{"word":"pilon","word_nosc":"pilon","lemma":"pilon","pos":"NOM"} ,
		{"word":"pilonnage","word_nosc":"pilonnage","lemma":"pilonnage","pos":"NOM"} ,
		{"word":"pilonnages","word_nosc":"pilonnages","lemma":"pilonnage","pos":"NOM"} ,
		{"word":"pilonneurs","word_nosc":"pilonneurs","lemma":"pilonneur","pos":"NOM"} ,
		{"word":"pilons","word_nosc":"pilons","lemma":"pilon","pos":"NOM"} ,
		{"word":"pilori","word_nosc":"pilori","lemma":"pilori","pos":"NOM"} ,
		{"word":"piloris","word_nosc":"piloris","lemma":"pilori","pos":"NOM"} ,
		{"word":"pilosité","word_nosc":"pilosite","lemma":"pilosité","pos":"NOM"} ,
		{"word":"pilot","word_nosc":"pilot","lemma":"pilot","pos":"NOM"} ,
		{"word":"pilotage","word_nosc":"pilotage","lemma":"pilotage","pos":"NOM"} ,
		{"word":"pilote","word_nosc":"pilote","lemma":"pilote","pos":"NOM"} ,
		{"word":"pilotes","word_nosc":"pilotes","lemma":"pilote","pos":"NOM"} ,
		{"word":"pilotin","word_nosc":"pilotin","lemma":"pilotin","pos":"NOM"} ,
		{"word":"pilotis","word_nosc":"pilotis","lemma":"pilotis","pos":"NOM"} ,
		{"word":"pilou","word_nosc":"pilou","lemma":"pilou","pos":"NOM"} ,
		{"word":"pilsen","word_nosc":"pilsen","lemma":"pilsen","pos":"NOM"} ,
		{"word":"pilule","word_nosc":"pilule","lemma":"pilule","pos":"NOM"} ,
		{"word":"pilules","word_nosc":"pilules","lemma":"pilule","pos":"NOM"} ,
		{"word":"pilum","word_nosc":"pilum","lemma":"pilum","pos":"NOM"} ,
		{"word":"pilée","word_nosc":"pilee","lemma":"pilée","pos":"NOM"} ,
		{"word":"pilées","word_nosc":"pilees","lemma":"pilée","pos":"NOM"} ,
		{"word":"pimbêche","word_nosc":"pimbeche","lemma":"pimbêche","pos":"NOM"} ,
		{"word":"pimbêches","word_nosc":"pimbeches","lemma":"pimbêche","pos":"NOM"} ,
		{"word":"piment","word_nosc":"piment","lemma":"piment","pos":"NOM"} ,
		{"word":"piments","word_nosc":"piments","lemma":"piment","pos":"NOM"} ,
		{"word":"pimpon","word_nosc":"pimpon","lemma":"pimpon","pos":"NOM"} ,
		{"word":"pimpons","word_nosc":"pimpons","lemma":"pimpon","pos":"NOM"} ,
		{"word":"pimprenelle","word_nosc":"pimprenelle","lemma":"pimprenelle","pos":"NOM"} ,
		{"word":"pimprenelles","word_nosc":"pimprenelles","lemma":"pimprenelle","pos":"NOM"} ,
		{"word":"pin","word_nosc":"pin","lemma":"pin","pos":"NOM"} ,
		{"word":"pin's","word_nosc":"pin's","lemma":"pin's","pos":"NOM"} ,
		{"word":"pin-up","word_nosc":"pin-up","lemma":"pin-up","pos":"NOM"} ,
		{"word":"pinacle","word_nosc":"pinacle","lemma":"pinacle","pos":"NOM"} ,
		{"word":"pinacles","word_nosc":"pinacles","lemma":"pinacle","pos":"NOM"} ,
		{"word":"pinacothèque","word_nosc":"pinacotheque","lemma":"pinacothèque","pos":"NOM"} ,
		{"word":"pinaillage","word_nosc":"pinaillage","lemma":"pinaillage","pos":"NOM"} ,
		{"word":"pinailleur","word_nosc":"pinailleur","lemma":"pinailleur","pos":"NOM"} ,
		{"word":"pinard","word_nosc":"pinard","lemma":"pinard","pos":"NOM"} ,
		{"word":"pinardiers","word_nosc":"pinardiers","lemma":"pinardier","pos":"NOM"} ,
		{"word":"pinardière","word_nosc":"pinardiere","lemma":"pinardier","pos":"NOM"} ,
		{"word":"pinards","word_nosc":"pinards","lemma":"pinard","pos":"NOM"} ,
		{"word":"pinasse","word_nosc":"pinasse","lemma":"pinasse","pos":"NOM"} ,
		{"word":"pinasses","word_nosc":"pinasses","lemma":"pinasse","pos":"NOM"} ,
		{"word":"pince","word_nosc":"pince","lemma":"pince","pos":"NOM"} ,
		{"word":"pince-fesses","word_nosc":"pince-fesses","lemma":"pince-fesse","pos":"NOM"} ,
		{"word":"pince-mailles","word_nosc":"pince-mailles","lemma":"pince-maille","pos":"NOM"} ,
		{"word":"pince-monseigneur","word_nosc":"pince-monseigneur","lemma":"pince-monseigneur","pos":"NOM"} ,
		{"word":"pince-nez","word_nosc":"pince-nez","lemma":"pince-nez","pos":"NOM"} ,
		{"word":"pince-sans-rire","word_nosc":"pince-sans-rire","lemma":"pince-sans-rire","pos":"NOM"} ,
		{"word":"pinceau","word_nosc":"pinceau","lemma":"pinceau","pos":"NOM"} ,
		{"word":"pinceaux","word_nosc":"pinceaux","lemma":"pinceau","pos":"NOM"} ,
		{"word":"pincement","word_nosc":"pincement","lemma":"pincement","pos":"NOM"} ,
		{"word":"pincements","word_nosc":"pincements","lemma":"pincement","pos":"NOM"} ,
		{"word":"pinces","word_nosc":"pinces","lemma":"pince","pos":"NOM"} ,
		{"word":"pincette","word_nosc":"pincette","lemma":"pincette","pos":"NOM"} ,
		{"word":"pincettes","word_nosc":"pincettes","lemma":"pincette","pos":"NOM"} ,
		{"word":"pinceur","word_nosc":"pinceur","lemma":"pinceur","pos":"NOM"} ,
		{"word":"pinchart","word_nosc":"pinchart","lemma":"pinchart","pos":"NOM"} ,
		{"word":"pincée","word_nosc":"pincee","lemma":"pincée","pos":"NOM"} ,
		{"word":"pincées","word_nosc":"pincees","lemma":"pincée","pos":"NOM"} ,
		{"word":"pine","word_nosc":"pine","lemma":"pine","pos":"NOM"} ,
		{"word":"pineau","word_nosc":"pineau","lemma":"pineau","pos":"NOM"} ,
		{"word":"pines","word_nosc":"pines","lemma":"pine","pos":"NOM"} ,
		{"word":"ping-pong","word_nosc":"ping-pong","lemma":"ping-pong","pos":"NOM"} ,
		{"word":"pinglot","word_nosc":"pinglot","lemma":"pinglot","pos":"NOM"} ,
		{"word":"pinglots","word_nosc":"pinglots","lemma":"pinglot","pos":"NOM"} ,
		{"word":"pingouin","word_nosc":"pingouin","lemma":"pingouin","pos":"NOM"} ,
		{"word":"pingouins","word_nosc":"pingouins","lemma":"pingouin","pos":"NOM"} ,
		{"word":"pingre","word_nosc":"pingre","lemma":"pingre","pos":"NOM"} ,
		{"word":"pingrerie","word_nosc":"pingrerie","lemma":"pingrerie","pos":"NOM"} ,
		{"word":"pingres","word_nosc":"pingres","lemma":"pingre","pos":"NOM"} ,
		{"word":"pink","word_nosc":"pink","lemma":"pink","pos":"NOM"} ,
		{"word":"pinot","word_nosc":"pinot","lemma":"pinot","pos":"NOM"} ,
		{"word":"pinots","word_nosc":"pinots","lemma":"pinot","pos":"NOM"} ,
		{"word":"pins","word_nosc":"pins","lemma":"pin","pos":"NOM"} ,
		{"word":"pinson","word_nosc":"pinson","lemma":"pinson","pos":"NOM"} ,
		{"word":"pinsons","word_nosc":"pinsons","lemma":"pinson","pos":"NOM"} ,
		{"word":"pintade","word_nosc":"pintade","lemma":"pintade","pos":"NOM"} ,
		{"word":"pintades","word_nosc":"pintades","lemma":"pintade","pos":"NOM"} ,
		{"word":"pinte","word_nosc":"pinte","lemma":"pinte","pos":"NOM"} ,
		{"word":"pintes","word_nosc":"pintes","lemma":"pinte","pos":"NOM"} ,
		{"word":"pinçage","word_nosc":"pincage","lemma":"pinçage","pos":"NOM"} ,
		{"word":"pinçon","word_nosc":"pincon","lemma":"pinçon","pos":"NOM"} ,
		{"word":"pinçons","word_nosc":"pincons","lemma":"pinçon","pos":"NOM"} ,
		{"word":"pinçure","word_nosc":"pincure","lemma":"pinçure","pos":"NOM"} ,
		{"word":"pinède","word_nosc":"pinede","lemma":"pinède","pos":"NOM"} ,
		{"word":"pinèdes","word_nosc":"pinedes","lemma":"pinède","pos":"NOM"} ,
		{"word":"pioche","word_nosc":"pioche","lemma":"pioche","pos":"NOM"} ,
		{"word":"pioches","word_nosc":"pioches","lemma":"pioche","pos":"NOM"} ,
		{"word":"piocheur","word_nosc":"piocheur","lemma":"piocheur","pos":"NOM"} ,
		{"word":"piolet","word_nosc":"piolet","lemma":"piolet","pos":"NOM"} ,
		{"word":"pion","word_nosc":"pion","lemma":"pion","pos":"NOM"} ,
		{"word":"pionne","word_nosc":"pionne","lemma":"pion","pos":"NOM"} ,
		{"word":"pionnier","word_nosc":"pionnier","lemma":"pionnier","pos":"NOM"} ,
		{"word":"pionniers","word_nosc":"pionniers","lemma":"pionnier","pos":"NOM"} ,
		{"word":"pionnière","word_nosc":"pionniere","lemma":"pionnier","pos":"NOM"} ,
		{"word":"pionnières","word_nosc":"pionnieres","lemma":"pionnier","pos":"NOM"} ,
		{"word":"pions","word_nosc":"pions","lemma":"pion","pos":"NOM"} ,
		{"word":"piot","word_nosc":"piot","lemma":"piot","pos":"NOM"} ,
		{"word":"piots","word_nosc":"piots","lemma":"piot","pos":"NOM"} ,
		{"word":"pioupiou","word_nosc":"pioupiou","lemma":"pioupiou","pos":"NOM"} ,
		{"word":"pioupious","word_nosc":"pioupious","lemma":"pioupiou","pos":"NOM"} ,
		{"word":"pipa","word_nosc":"pipa","lemma":"pipa","pos":"NOM"} ,
		{"word":"pipe","word_nosc":"pipe","lemma":"pipe","pos":"NOM"} ,
		{"word":"pipe-line","word_nosc":"pipe-line","lemma":"pipe-line","pos":"NOM"} ,
		{"word":"pipe-lines","word_nosc":"pipe-lines","lemma":"pipe-line","pos":"NOM"} ,
		{"word":"pipeau","word_nosc":"pipeau","lemma":"pipeau","pos":"NOM"} ,
		{"word":"pipeaux","word_nosc":"pipeaux","lemma":"pipeau","pos":"NOM"} ,
		{"word":"pipelet","word_nosc":"pipelet","lemma":"pipelet","pos":"NOM"} ,
		{"word":"pipelets","word_nosc":"pipelets","lemma":"pipelet","pos":"NOM"} ,
		{"word":"pipelette","word_nosc":"pipelette","lemma":"pipelette","pos":"NOM"} ,
		{"word":"pipelettes","word_nosc":"pipelettes","lemma":"pipelette","pos":"NOM"} ,
		{"word":"pipeline","word_nosc":"pipeline","lemma":"pipeline","pos":"NOM"} ,
		{"word":"pipelines","word_nosc":"pipelines","lemma":"pipeline","pos":"NOM"} ,
		{"word":"piperade","word_nosc":"piperade","lemma":"piperade","pos":"NOM"} ,
		{"word":"piperie","word_nosc":"piperie","lemma":"piperie","pos":"NOM"} ,
		{"word":"pipes","word_nosc":"pipes","lemma":"pipe","pos":"NOM"} ,
		{"word":"pipette","word_nosc":"pipette","lemma":"pipette","pos":"NOM"} ,
		{"word":"pipettes","word_nosc":"pipettes","lemma":"pipette","pos":"NOM"} ,
		{"word":"pipeur","word_nosc":"pipeur","lemma":"pipeur","pos":"NOM"} ,
		{"word":"pipeuse","word_nosc":"pipeuse","lemma":"pipeur","pos":"NOM"} ,
		{"word":"pipeuses","word_nosc":"pipeuses","lemma":"pipeur","pos":"NOM"} ,
		{"word":"pipi","word_nosc":"pipi","lemma":"pipi","pos":"NOM"} ,
		{"word":"pipi-room","word_nosc":"pipi-room","lemma":"pipi-room","pos":"NOM"} ,
		{"word":"pipiers","word_nosc":"pipiers","lemma":"pipier","pos":"NOM"} ,
		{"word":"pipis","word_nosc":"pipis","lemma":"pipi","pos":"NOM"} ,
		{"word":"pipistrelle","word_nosc":"pipistrelle","lemma":"pipistrelle","pos":"NOM"} ,
		{"word":"pipo","word_nosc":"pipo","lemma":"pipo","pos":"NOM"} ,
		{"word":"pipée","word_nosc":"pipee","lemma":"pipée","pos":"NOM"} ,
		{"word":"pipées","word_nosc":"pipees","lemma":"pipée","pos":"NOM"} ,
		{"word":"piquage","word_nosc":"piquage","lemma":"piquage","pos":"NOM"} ,
		{"word":"piquages","word_nosc":"piquages","lemma":"piquage","pos":"NOM"} ,
		{"word":"piquant","word_nosc":"piquant","lemma":"piquant","pos":"NOM"} ,
		{"word":"piquants","word_nosc":"piquants","lemma":"piquant","pos":"NOM"} ,
		{"word":"pique","word_nosc":"pique","lemma":"pique","pos":"NOM"} ,
		{"word":"pique-assiette","word_nosc":"pique-assiette","lemma":"pique-assiette","pos":"NOM"} ,
		{"word":"pique-assiettes","word_nosc":"pique-assiettes","lemma":"pique-assiette","pos":"NOM"} ,
		{"word":"pique-boeufs","word_nosc":"pique-boeufs","lemma":"pique-boeuf","pos":"NOM"} ,
		{"word":"pique-feu","word_nosc":"pique-feu","lemma":"pique-feu","pos":"NOM"} ,
		{"word":"pique-nique","word_nosc":"pique-nique","lemma":"pique-nique","pos":"NOM"} ,
		{"word":"pique-niques","word_nosc":"pique-niques","lemma":"pique-nique","pos":"NOM"} ,
		{"word":"pique-niqueurs","word_nosc":"pique-niqueurs","lemma":"pique-niqueur","pos":"NOM"} ,
		{"word":"piquerons","word_nosc":"piquerons","lemma":"piqueron","pos":"NOM"} ,
		{"word":"piques","word_nosc":"piques","lemma":"pique","pos":"NOM"} ,
		{"word":"piquet","word_nosc":"piquet","lemma":"piquet","pos":"NOM"} ,
		{"word":"piquetage","word_nosc":"piquetage","lemma":"piquetage","pos":"NOM"} ,
		{"word":"piquetis","word_nosc":"piquetis","lemma":"piquetis","pos":"NOM"} ,
		{"word":"piquets","word_nosc":"piquets","lemma":"piquet","pos":"NOM"} ,
		{"word":"piquette","word_nosc":"piquette","lemma":"piquette","pos":"NOM"} ,
		{"word":"piquettes","word_nosc":"piquettes","lemma":"piquette","pos":"NOM"} ,
		{"word":"piqueur","word_nosc":"piqueur","lemma":"piqueur","pos":"NOM"} ,
		{"word":"piqueurs","word_nosc":"piqueurs","lemma":"piqueur","pos":"NOM"} ,
		{"word":"piqueuse","word_nosc":"piqueuse","lemma":"piqueur","pos":"NOM"} ,
		{"word":"piqueuses","word_nosc":"piqueuses","lemma":"piqueur","pos":"NOM"} ,
		{"word":"piqueux","word_nosc":"piqueux","lemma":"piqueux","pos":"NOM"} ,
		{"word":"piquier","word_nosc":"piquier","lemma":"piquier","pos":"NOM"} ,
		{"word":"piquouse","word_nosc":"piquouse","lemma":"piquouse","pos":"NOM"} ,
		{"word":"piquouses","word_nosc":"piquouses","lemma":"piquouse","pos":"NOM"} ,
		{"word":"piquouze","word_nosc":"piquouze","lemma":"piquouze","pos":"NOM"} ,
		{"word":"piquouzes","word_nosc":"piquouzes","lemma":"piquouze","pos":"NOM"} ,
		{"word":"piqûre","word_nosc":"piqure","lemma":"piqûre","pos":"NOM"} ,
		{"word":"piqûres","word_nosc":"piqures","lemma":"piqûre","pos":"NOM"} ,
		{"word":"piranha","word_nosc":"piranha","lemma":"piranha","pos":"NOM"} ,
		{"word":"piranhas","word_nosc":"piranhas","lemma":"piranha","pos":"NOM"} ,
		{"word":"piratage","word_nosc":"piratage","lemma":"piratage","pos":"NOM"} ,
		{"word":"piratages","word_nosc":"piratages","lemma":"piratage","pos":"NOM"} ,
		{"word":"pirate","word_nosc":"pirate","lemma":"pirate","pos":"NOM"} ,
		{"word":"piraterie","word_nosc":"piraterie","lemma":"piraterie","pos":"NOM"} ,
		{"word":"pirateries","word_nosc":"pirateries","lemma":"piraterie","pos":"NOM"} ,
		{"word":"pirates","word_nosc":"pirates","lemma":"pirate","pos":"NOM"} ,
		{"word":"pire","word_nosc":"pire","lemma":"pire","pos":"NOM"} ,
		{"word":"pirogue","word_nosc":"pirogue","lemma":"pirogue","pos":"NOM"} ,
		{"word":"pirogues","word_nosc":"pirogues","lemma":"pirogue","pos":"NOM"} ,
		{"word":"pirojki","word_nosc":"pirojki","lemma":"pirojki","pos":"NOM"} ,
		{"word":"pirojkis","word_nosc":"pirojkis","lemma":"pirojki","pos":"NOM"} ,
		{"word":"pirolle","word_nosc":"pirolle","lemma":"pirolle","pos":"NOM"} ,
		{"word":"pirouette","word_nosc":"pirouette","lemma":"pirouette","pos":"NOM"} ,
		{"word":"pirouettes","word_nosc":"pirouettes","lemma":"pirouette","pos":"NOM"} ,
		{"word":"pis","word_nosc":"pis","lemma":"pis","pos":"NOM"} ,
		{"word":"pis-aller","word_nosc":"pis-aller","lemma":"pis-aller","pos":"NOM"} ,
		{"word":"pisans","word_nosc":"pisans","lemma":"pisan","pos":"NOM"} ,
		{"word":"pisciculteur","word_nosc":"pisciculteur","lemma":"pisciculteur","pos":"NOM"} ,
		{"word":"pisciculture","word_nosc":"pisciculture","lemma":"pisciculture","pos":"NOM"} ,
		{"word":"piscine","word_nosc":"piscine","lemma":"piscine","pos":"NOM"} ,
		{"word":"piscines","word_nosc":"piscines","lemma":"piscine","pos":"NOM"} ,
		{"word":"pisiforme","word_nosc":"pisiforme","lemma":"pisiforme","pos":"NOM"} ,
		{"word":"pissaladière","word_nosc":"pissaladiere","lemma":"pissaladière","pos":"NOM"} ,
		{"word":"pissat","word_nosc":"pissat","lemma":"pissat","pos":"NOM"} ,
		{"word":"pisse","word_nosc":"pisse","lemma":"pisse","pos":"NOM"} ,
		{"word":"pisse-copie","word_nosc":"pisse-copie","lemma":"pisse-copie","pos":"NOM"} ,
		{"word":"pisse-copies","word_nosc":"pisse-copies","lemma":"pisse-copie","pos":"NOM"} ,
		{"word":"pisse-froid","word_nosc":"pisse-froid","lemma":"pisse-froid","pos":"NOM"} ,
		{"word":"pisse-vinaigre","word_nosc":"pisse-vinaigre","lemma":"pisse-vinaigre","pos":"NOM"} ,
		{"word":"pissenlit","word_nosc":"pissenlit","lemma":"pissenlit","pos":"NOM"} ,
		{"word":"pissenlits","word_nosc":"pissenlits","lemma":"pissenlit","pos":"NOM"} ,
		{"word":"pisses","word_nosc":"pisses","lemma":"pisse","pos":"NOM"} ,
		{"word":"pissette","word_nosc":"pissette","lemma":"pissette","pos":"NOM"} ,
		{"word":"pisseur","word_nosc":"pisseur","lemma":"pisseur","pos":"NOM"} ,
		{"word":"pisseurs","word_nosc":"pisseurs","lemma":"pisseur","pos":"NOM"} ,
		{"word":"pisseuse","word_nosc":"pisseuse","lemma":"pisseur","pos":"NOM"} ,
		{"word":"pisseuses","word_nosc":"pisseuses","lemma":"pisseur","pos":"NOM"} ,
		{"word":"pissoir","word_nosc":"pissoir","lemma":"pissoir","pos":"NOM"} ,
		{"word":"pissotière","word_nosc":"pissotiere","lemma":"pissotière","pos":"NOM"} ,
		{"word":"pissotières","word_nosc":"pissotieres","lemma":"pissotière","pos":"NOM"} ,
		{"word":"pissou","word_nosc":"pissou","lemma":"pissou","pos":"NOM"} ,
		{"word":"pistache","word_nosc":"pistache","lemma":"pistache","pos":"NOM"} ,
		{"word":"pistaches","word_nosc":"pistaches","lemma":"pistache","pos":"NOM"} ,
		{"word":"pistachier","word_nosc":"pistachier","lemma":"pistachier","pos":"NOM"} ,
		{"word":"pistachiers","word_nosc":"pistachiers","lemma":"pistachier","pos":"NOM"} ,
		{"word":"pistage","word_nosc":"pistage","lemma":"pistage","pos":"NOM"} ,
		{"word":"pistard","word_nosc":"pistard","lemma":"pistard","pos":"NOM"} ,
		{"word":"pistards","word_nosc":"pistards","lemma":"pistard","pos":"NOM"} ,
		{"word":"piste","word_nosc":"piste","lemma":"piste","pos":"NOM"} ,
		{"word":"pistes","word_nosc":"pistes","lemma":"piste","pos":"NOM"} ,
		{"word":"pisteur","word_nosc":"pisteur","lemma":"pisteur","pos":"NOM"} ,
		{"word":"pisteurs","word_nosc":"pisteurs","lemma":"pisteur","pos":"NOM"} ,
		{"word":"pistil","word_nosc":"pistil","lemma":"pistil","pos":"NOM"} ,
		{"word":"pistils","word_nosc":"pistils","lemma":"pistil","pos":"NOM"} ,
		{"word":"pistole","word_nosc":"pistole","lemma":"pistole","pos":"NOM"} ,
		{"word":"pistolero","word_nosc":"pistolero","lemma":"pistolero","pos":"NOM"} ,
		{"word":"pistoleros","word_nosc":"pistoleros","lemma":"pistolero","pos":"NOM"} ,
		{"word":"pistoles","word_nosc":"pistoles","lemma":"pistole","pos":"NOM"} ,
		{"word":"pistolet","word_nosc":"pistolet","lemma":"pistolet","pos":"NOM"} ,
		{"word":"pistolet-mitrailleur","word_nosc":"pistolet-mitrailleur","lemma":"pistolet-mitrailleur","pos":"NOM"} ,
		{"word":"pistolets","word_nosc":"pistolets","lemma":"pistolet","pos":"NOM"} ,
		{"word":"pistoleurs","word_nosc":"pistoleurs","lemma":"pistoleur","pos":"NOM"} ,
		{"word":"pistolétade","word_nosc":"pistoletade","lemma":"pistolétade","pos":"NOM"} ,
		{"word":"piston","word_nosc":"piston","lemma":"piston","pos":"NOM"} ,
		{"word":"pistonné","word_nosc":"pistonne","lemma":"pistonné","pos":"NOM"} ,
		{"word":"pistonnée","word_nosc":"pistonnee","lemma":"pistonné","pos":"NOM"} ,
		{"word":"pistonnés","word_nosc":"pistonnes","lemma":"pistonné","pos":"NOM"} ,
		{"word":"pistons","word_nosc":"pistons","lemma":"piston","pos":"NOM"} ,
		{"word":"pistou","word_nosc":"pistou","lemma":"pistou","pos":"NOM"} ,
		{"word":"pisé","word_nosc":"pise","lemma":"pisé","pos":"NOM"} ,
		{"word":"pit bull","word_nosc":"pit bull","lemma":"pit-bull","pos":"NOM"} ,
		{"word":"pit-bull","word_nosc":"pit-bull","lemma":"pit-bull","pos":"NOM"} ,
		{"word":"pit-bulls","word_nosc":"pit-bulls","lemma":"pit-bull","pos":"NOM"} ,
		{"word":"pita","word_nosc":"pita","lemma":"pita","pos":"NOM"} ,
		{"word":"pitaine","word_nosc":"pitaine","lemma":"pitaine","pos":"NOM"} ,
		{"word":"pitance","word_nosc":"pitance","lemma":"pitance","pos":"NOM"} ,
		{"word":"pitances","word_nosc":"pitances","lemma":"pitance","pos":"NOM"} ,
		{"word":"pitancier","word_nosc":"pitancier","lemma":"pitancier","pos":"NOM"} ,
		{"word":"pitas","word_nosc":"pitas","lemma":"pita","pos":"NOM"} ,
		{"word":"pitbull","word_nosc":"pitbull","lemma":"pitbull","pos":"NOM"} ,
		{"word":"pitbulls","word_nosc":"pitbulls","lemma":"pitbull","pos":"NOM"} ,
		{"word":"pitch","word_nosc":"pitch","lemma":"pitch","pos":"NOM"} ,
		{"word":"pitchoun","word_nosc":"pitchoun","lemma":"pitchoun","pos":"NOM"} ,
		{"word":"pitchoune","word_nosc":"pitchoune","lemma":"pitchoun","pos":"NOM"} ,
		{"word":"pitchounet","word_nosc":"pitchounet","lemma":"pitchounet","pos":"NOM"} ,
		{"word":"pitchounette","word_nosc":"pitchounette","lemma":"pitchounette","pos":"NOM"} ,
		{"word":"pitchpin","word_nosc":"pitchpin","lemma":"pitchpin","pos":"NOM"} ,
		{"word":"pithiviers","word_nosc":"pithiviers","lemma":"pithiviers","pos":"NOM"} ,
		{"word":"pithécanthrope","word_nosc":"pithecanthrope","lemma":"pithécanthrope","pos":"NOM"} ,
		{"word":"pithécanthropes","word_nosc":"pithecanthropes","lemma":"pithécanthrope","pos":"NOM"} ,
		{"word":"pitié","word_nosc":"pitie","lemma":"pitié","pos":"NOM"} ,
		{"word":"pitiés","word_nosc":"pities","lemma":"pitié","pos":"NOM"} ,
		{"word":"piton","word_nosc":"piton","lemma":"piton","pos":"NOM"} ,
		{"word":"pitons","word_nosc":"pitons","lemma":"piton","pos":"NOM"} ,
		{"word":"pitre","word_nosc":"pitre","lemma":"pitre","pos":"NOM"} ,
		{"word":"pitrerie","word_nosc":"pitrerie","lemma":"pitrerie","pos":"NOM"} ,
		{"word":"pitreries","word_nosc":"pitreries","lemma":"pitrerie","pos":"NOM"} ,
		{"word":"pitres","word_nosc":"pitres","lemma":"pitre","pos":"NOM"} ,
		{"word":"pittoresque","word_nosc":"pittoresque","lemma":"pittoresque","pos":"NOM"} ,
		{"word":"pittoresques","word_nosc":"pittoresques","lemma":"pittoresque","pos":"NOM"} ,
		{"word":"pituite","word_nosc":"pituite","lemma":"pituite","pos":"NOM"} ,
		{"word":"pityriasis","word_nosc":"pityriasis","lemma":"pityriasis","pos":"NOM"} ,
		{"word":"pive","word_nosc":"pive","lemma":"pive","pos":"NOM"} ,
		{"word":"pivert","word_nosc":"pivert","lemma":"pivert","pos":"NOM"} ,
		{"word":"piverts","word_nosc":"piverts","lemma":"pivert","pos":"NOM"} ,
		{"word":"pivoine","word_nosc":"pivoine","lemma":"pivoine","pos":"NOM"} ,
		{"word":"pivoines","word_nosc":"pivoines","lemma":"pivoine","pos":"NOM"} ,
		{"word":"pivot","word_nosc":"pivot","lemma":"pivot","pos":"NOM"} ,
		{"word":"pivotement","word_nosc":"pivotement","lemma":"pivotement","pos":"NOM"} ,
		{"word":"pivots","word_nosc":"pivots","lemma":"pivot","pos":"NOM"} ,
		{"word":"pixel","word_nosc":"pixel","lemma":"pixel","pos":"NOM"} ,
		{"word":"pixels","word_nosc":"pixels","lemma":"pixel","pos":"NOM"} ,
		{"word":"pizza","word_nosc":"pizza","lemma":"pizza","pos":"NOM"} ,
		{"word":"pizzaiolo","word_nosc":"pizzaiolo","lemma":"pizzaiolo","pos":"NOM"} ,
		{"word":"pizzas","word_nosc":"pizzas","lemma":"pizza","pos":"NOM"} ,
		{"word":"pizzeria","word_nosc":"pizzeria","lemma":"pizzeria","pos":"NOM"} ,
		{"word":"pizzerias","word_nosc":"pizzerias","lemma":"pizzeria","pos":"NOM"} ,
		{"word":"pizzicato","word_nosc":"pizzicato","lemma":"pizzicato","pos":"NOM"} ,
		{"word":"pièce","word_nosc":"piece","lemma":"pièce","pos":"NOM"} ,
		{"word":"pièces","word_nosc":"pieces","lemma":"pièce","pos":"NOM"} ,
		{"word":"piège","word_nosc":"piege","lemma":"piège","pos":"NOM"} ,
		{"word":"pièges","word_nosc":"pieges","lemma":"piège","pos":"NOM"} ,
		{"word":"piètement","word_nosc":"pietement","lemma":"piètement","pos":"NOM"} ,
		{"word":"piécette","word_nosc":"piecette","lemma":"piécette","pos":"NOM"} ,
		{"word":"piécettes","word_nosc":"piecettes","lemma":"piécette","pos":"NOM"} ,
		{"word":"piédestal","word_nosc":"piedestal","lemma":"piédestal","pos":"NOM"} ,
		{"word":"piédestaux","word_nosc":"piedestaux","lemma":"piédestal","pos":"NOM"} ,
		{"word":"piédroit","word_nosc":"piedroit","lemma":"piédroit","pos":"NOM"} ,
		{"word":"piédroits","word_nosc":"piedroits","lemma":"piédroit","pos":"NOM"} ,
		{"word":"piégeage","word_nosc":"piegeage","lemma":"piégeage","pos":"NOM"} ,
		{"word":"piégeages","word_nosc":"piegeages","lemma":"piégeage","pos":"NOM"} ,
		{"word":"piégeur","word_nosc":"piegeur","lemma":"piégeur","pos":"NOM"} ,
		{"word":"piégeurs","word_nosc":"piegeurs","lemma":"piégeur","pos":"NOM"} ,
		{"word":"piégeuse","word_nosc":"piegeuse","lemma":"piégeur","pos":"NOM"} ,
		{"word":"piégeuses","word_nosc":"piegeuses","lemma":"piégeur","pos":"NOM"} ,
		{"word":"piémont","word_nosc":"piemont","lemma":"piémont","pos":"NOM"} ,
		{"word":"piémontais","word_nosc":"piemontais","lemma":"piémontais","pos":"NOM"} ,
		{"word":"piéride","word_nosc":"pieride","lemma":"piéride","pos":"NOM"} ,
		{"word":"piérides","word_nosc":"pierides","lemma":"piéride","pos":"NOM"} ,
		{"word":"piéta","word_nosc":"pieta","lemma":"piéta","pos":"NOM"} ,
		{"word":"piétaille","word_nosc":"pietaille","lemma":"piétaille","pos":"NOM"} ,
		{"word":"piétement","word_nosc":"pietement","lemma":"piétement","pos":"NOM"} ,
		{"word":"piétin","word_nosc":"pietin","lemma":"piétin","pos":"NOM"} ,
		{"word":"piétinement","word_nosc":"pietinement","lemma":"piétinement","pos":"NOM"} ,
		{"word":"piétinements","word_nosc":"pietinements","lemma":"piétinement","pos":"NOM"} ,
		{"word":"piéton","word_nosc":"pieton","lemma":"piéton","pos":"NOM"} ,
		{"word":"piétonnes","word_nosc":"pietonnes","lemma":"piéton","pos":"NOM"} ,
		{"word":"piétons","word_nosc":"pietons","lemma":"piéton","pos":"NOM"} ,
		{"word":"piété","word_nosc":"piete","lemma":"piété","pos":"NOM"} ,
		{"word":"placage","word_nosc":"placage","lemma":"placage","pos":"NOM"} ,
		{"word":"placages","word_nosc":"placages","lemma":"placage","pos":"NOM"} ,
		{"word":"placard","word_nosc":"placard","lemma":"placard","pos":"NOM"} ,
		{"word":"placardier","word_nosc":"placardier","lemma":"placardier","pos":"NOM"} ,
		{"word":"placards","word_nosc":"placards","lemma":"placard","pos":"NOM"} ,
		{"word":"place","word_nosc":"place","lemma":"place","pos":"NOM"} ,
		{"word":"placebo","word_nosc":"placebo","lemma":"placebo","pos":"NOM"} ,
		{"word":"placebos","word_nosc":"placebos","lemma":"placebo","pos":"NOM"} ,
		{"word":"placement","word_nosc":"placement","lemma":"placement","pos":"NOM"} ,
		{"word":"placements","word_nosc":"placements","lemma":"placement","pos":"NOM"} ,
		{"word":"placenta","word_nosc":"placenta","lemma":"placenta","pos":"NOM"} ,
		{"word":"placentas","word_nosc":"placentas","lemma":"placenta","pos":"NOM"} ,
		{"word":"placer","word_nosc":"placer","lemma":"placer","pos":"NOM"} ,
		{"word":"places","word_nosc":"places","lemma":"place","pos":"NOM"} ,
		{"word":"placet","word_nosc":"placet","lemma":"placet","pos":"NOM"} ,
		{"word":"placets","word_nosc":"placets","lemma":"placet","pos":"NOM"} ,
		{"word":"placette","word_nosc":"placette","lemma":"placette","pos":"NOM"} ,
		{"word":"placettes","word_nosc":"placettes","lemma":"placette","pos":"NOM"} ,
		{"word":"placeur","word_nosc":"placeur","lemma":"placeur","pos":"NOM"} ,
		{"word":"placeurs","word_nosc":"placeurs","lemma":"placeur","pos":"NOM"} ,
		{"word":"placeuse","word_nosc":"placeuse","lemma":"placeur","pos":"NOM"} ,
		{"word":"placeuses","word_nosc":"placeuses","lemma":"placeur","pos":"NOM"} ,
		{"word":"placidité","word_nosc":"placidite","lemma":"placidité","pos":"NOM"} ,
		{"word":"placier","word_nosc":"placier","lemma":"placier","pos":"NOM"} ,
		{"word":"placiers","word_nosc":"placiers","lemma":"placier","pos":"NOM"} ,
		{"word":"placoplâtre","word_nosc":"placoplatre","lemma":"placoplâtre","pos":"NOM"} ,
		{"word":"plafond","word_nosc":"plafond","lemma":"plafond","pos":"NOM"} ,
		{"word":"plafonds","word_nosc":"plafonds","lemma":"plafond","pos":"NOM"} ,
		{"word":"plafonnement","word_nosc":"plafonnement","lemma":"plafonnement","pos":"NOM"} ,
		{"word":"plafonnier","word_nosc":"plafonnier","lemma":"plafonnier","pos":"NOM"} ,
		{"word":"plafonniers","word_nosc":"plafonniers","lemma":"plafonnier","pos":"NOM"} ,
		{"word":"plafonnière","word_nosc":"plafonniere","lemma":"plafonnier","pos":"NOM"} ,
		{"word":"plage","word_nosc":"plage","lemma":"plage","pos":"NOM"} ,
		{"word":"plages","word_nosc":"plages","lemma":"plage","pos":"NOM"} ,
		{"word":"plagiaire","word_nosc":"plagiaire","lemma":"plagiaire","pos":"NOM"} ,
		{"word":"plagiaires","word_nosc":"plagiaires","lemma":"plagiaire","pos":"NOM"} ,
		{"word":"plagiat","word_nosc":"plagiat","lemma":"plagiat","pos":"NOM"} ,
		{"word":"plagiats","word_nosc":"plagiats","lemma":"plagiat","pos":"NOM"} ,
		{"word":"plagioclase","word_nosc":"plagioclase","lemma":"plagioclase","pos":"NOM"} ,
		{"word":"plaid","word_nosc":"plaid","lemma":"plaid","pos":"NOM"} ,
		{"word":"plaideur","word_nosc":"plaideur","lemma":"plaideur","pos":"NOM"} ,
		{"word":"plaideurs","word_nosc":"plaideurs","lemma":"plaideur","pos":"NOM"} ,
		{"word":"plaidoirie","word_nosc":"plaidoirie","lemma":"plaidoirie","pos":"NOM"} ,
		{"word":"plaidoiries","word_nosc":"plaidoiries","lemma":"plaidoirie","pos":"NOM"} ,
		{"word":"plaidoyer","word_nosc":"plaidoyer","lemma":"plaidoyer","pos":"NOM"} ,
		{"word":"plaidoyers","word_nosc":"plaidoyers","lemma":"plaidoyer","pos":"NOM"} ,
		{"word":"plaids","word_nosc":"plaids","lemma":"plaid","pos":"NOM"} ,
		{"word":"plaie","word_nosc":"plaie","lemma":"plaie","pos":"NOM"} ,
		{"word":"plaies","word_nosc":"plaies","lemma":"plaie","pos":"NOM"} ,
		{"word":"plaignant","word_nosc":"plaignant","lemma":"plaignant","pos":"NOM"} ,
		{"word":"plaignante","word_nosc":"plaignante","lemma":"plaignant","pos":"NOM"} ,
		{"word":"plaignantes","word_nosc":"plaignantes","lemma":"plaignant","pos":"NOM"} ,
		{"word":"plaignants","word_nosc":"plaignants","lemma":"plaignant","pos":"NOM"} ,
		{"word":"plain-chant","word_nosc":"plain-chant","lemma":"plain-chant","pos":"NOM"} ,
		{"word":"plain-pied","word_nosc":"plain-pied","lemma":"plain-pied","pos":"NOM"} ,
		{"word":"plaine","word_nosc":"plaine","lemma":"plaine","pos":"NOM"} ,
		{"word":"plaines","word_nosc":"plaines","lemma":"plain","pos":"NOM"} ,
		{"word":"plainte","word_nosc":"plainte","lemma":"plainte","pos":"NOM"} ,
		{"word":"plaintes","word_nosc":"plaintes","lemma":"plainte","pos":"NOM"} ,
		{"word":"plaisance","word_nosc":"plaisance","lemma":"plaisance","pos":"NOM"} ,
		{"word":"plaisancier","word_nosc":"plaisancier","lemma":"plaisancier","pos":"NOM"} ,
		{"word":"plaisanciers","word_nosc":"plaisanciers","lemma":"plaisancier","pos":"NOM"} ,
		{"word":"plaisant","word_nosc":"plaisant","lemma":"plaisant","pos":"NOM"} ,
		{"word":"plaisanterie","word_nosc":"plaisanterie","lemma":"plaisanterie","pos":"NOM"} ,
		{"word":"plaisanteries","word_nosc":"plaisanteries","lemma":"plaisanterie","pos":"NOM"} ,
		{"word":"plaisantin","word_nosc":"plaisantin","lemma":"plaisantin","pos":"NOM"} ,
		{"word":"plaisantins","word_nosc":"plaisantins","lemma":"plaisantin","pos":"NOM"} ,
		{"word":"plaisants","word_nosc":"plaisants","lemma":"plaisant","pos":"NOM"} ,
		{"word":"plaisir","word_nosc":"plaisir","lemma":"plaisir","pos":"NOM"} ,
		{"word":"plaisirs","word_nosc":"plaisirs","lemma":"plaisir","pos":"NOM"} ,
		{"word":"plan","word_nosc":"plan","lemma":"plan","pos":"NOM"} ,
		{"word":"plan-séquence","word_nosc":"plan-sequence","lemma":"plan-séquence","pos":"NOM"} ,
		{"word":"planche","word_nosc":"planche","lemma":"planche","pos":"NOM"} ,
		{"word":"plancher","word_nosc":"plancher","lemma":"plancher","pos":"NOM"} ,
		{"word":"planchers","word_nosc":"planchers","lemma":"plancher","pos":"NOM"} ,
		{"word":"planches","word_nosc":"planches","lemma":"planche","pos":"NOM"} ,
		{"word":"planches-contacts","word_nosc":"planches-contacts","lemma":"planche-contact","pos":"NOM"} ,
		{"word":"planchette","word_nosc":"planchette","lemma":"planchette","pos":"NOM"} ,
		{"word":"planchettes","word_nosc":"planchettes","lemma":"planchette","pos":"NOM"} ,
		{"word":"planchiste","word_nosc":"planchiste","lemma":"planchiste","pos":"NOM"} ,
		{"word":"plancton","word_nosc":"plancton","lemma":"plancton","pos":"NOM"} ,
		{"word":"planctons","word_nosc":"planctons","lemma":"plancton","pos":"NOM"} ,
		{"word":"plane","word_nosc":"plane","lemma":"plane","pos":"NOM"} ,
		{"word":"planelle","word_nosc":"planelle","lemma":"planelle","pos":"NOM"} ,
		{"word":"planement","word_nosc":"planement","lemma":"planement","pos":"NOM"} ,
		{"word":"planes","word_nosc":"planes","lemma":"plane","pos":"NOM"} ,
		{"word":"planeur","word_nosc":"planeur","lemma":"planeur","pos":"NOM"} ,
		{"word":"planeurs","word_nosc":"planeurs","lemma":"planeur","pos":"NOM"} ,
		{"word":"planificateur","word_nosc":"planificateur","lemma":"planificateur","pos":"NOM"} ,
		{"word":"planification","word_nosc":"planification","lemma":"planification","pos":"NOM"} ,
		{"word":"planificatrice","word_nosc":"planificatrice","lemma":"planificateur","pos":"NOM"} ,
		{"word":"planisphère","word_nosc":"planisphere","lemma":"planisphère","pos":"NOM"} ,
		{"word":"planisphères","word_nosc":"planispheres","lemma":"planisphère","pos":"NOM"} ,
		{"word":"planitude","word_nosc":"planitude","lemma":"planitude","pos":"NOM"} ,
		{"word":"planning","word_nosc":"planning","lemma":"planning","pos":"NOM"} ,
		{"word":"plannings","word_nosc":"plannings","lemma":"planning","pos":"NOM"} ,
		{"word":"planque","word_nosc":"planque","lemma":"planque","pos":"NOM"} ,
		{"word":"planques","word_nosc":"planques","lemma":"planque","pos":"NOM"} ,
		{"word":"plans","word_nosc":"plans","lemma":"plan","pos":"NOM"} ,
		{"word":"plant","word_nosc":"plant","lemma":"plant","pos":"NOM"} ,
		{"word":"plantage","word_nosc":"plantage","lemma":"plantage","pos":"NOM"} ,
		{"word":"plantain","word_nosc":"plantain","lemma":"plantain","pos":"NOM"} ,
		{"word":"plantains","word_nosc":"plantains","lemma":"plantain","pos":"NOM"} ,
		{"word":"plantation","word_nosc":"plantation","lemma":"plantation","pos":"NOM"} ,
		{"word":"plantations","word_nosc":"plantations","lemma":"plantation","pos":"NOM"} ,
		{"word":"plante","word_nosc":"plante","lemma":"plante","pos":"NOM"} ,
		{"word":"plantes","word_nosc":"plantes","lemma":"plante","pos":"NOM"} ,
		{"word":"planteur","word_nosc":"planteur","lemma":"planteur","pos":"NOM"} ,
		{"word":"planteurs","word_nosc":"planteurs","lemma":"planteur","pos":"NOM"} ,
		{"word":"plantier","word_nosc":"plantier","lemma":"plantier","pos":"NOM"} ,
		{"word":"plantigrade","word_nosc":"plantigrade","lemma":"plantigrade","pos":"NOM"} ,
		{"word":"plantoir","word_nosc":"plantoir","lemma":"plantoir","pos":"NOM"} ,
		{"word":"planton","word_nosc":"planton","lemma":"planton","pos":"NOM"} ,
		{"word":"plantons","word_nosc":"plantons","lemma":"planton","pos":"NOM"} ,
		{"word":"plants","word_nosc":"plants","lemma":"plant","pos":"NOM"} ,
		{"word":"planète","word_nosc":"planete","lemma":"planète","pos":"NOM"} ,
		{"word":"planètes","word_nosc":"planetes","lemma":"planète","pos":"NOM"} ,
		{"word":"planétarium","word_nosc":"planetarium","lemma":"planétarium","pos":"NOM"} ,
		{"word":"planétariums","word_nosc":"planetariums","lemma":"planétarium","pos":"NOM"} ,
		{"word":"planétoïde","word_nosc":"planetoide","lemma":"planétoïde","pos":"NOM"} ,
		{"word":"plaquage","word_nosc":"plaquage","lemma":"plaquage","pos":"NOM"} ,
		{"word":"plaquages","word_nosc":"plaquages","lemma":"plaquage","pos":"NOM"} ,
		{"word":"plaque","word_nosc":"plaque","lemma":"plaque","pos":"NOM"} ,
		{"word":"plaqueminiers","word_nosc":"plaqueminiers","lemma":"plaqueminier","pos":"NOM"} ,
		{"word":"plaques","word_nosc":"plaques","lemma":"plaque","pos":"NOM"} ,
		{"word":"plaquette","word_nosc":"plaquette","lemma":"plaquette","pos":"NOM"} ,
		{"word":"plaquettes","word_nosc":"plaquettes","lemma":"plaquette","pos":"NOM"} ,
		{"word":"plaqueur","word_nosc":"plaqueur","lemma":"plaqueur","pos":"NOM"} ,
		{"word":"plasma","word_nosc":"plasma","lemma":"plasma","pos":"NOM"} ,
		{"word":"plasmaphérèse","word_nosc":"plasmapherese","lemma":"plasmaphérèse","pos":"NOM"} ,
		{"word":"plaste","word_nosc":"plaste","lemma":"plaste","pos":"NOM"} ,
		{"word":"plastic","word_nosc":"plastic","lemma":"plastic","pos":"NOM"} ,
		{"word":"plasticage","word_nosc":"plasticage","lemma":"plasticage","pos":"NOM"} ,
		{"word":"plasticages","word_nosc":"plasticages","lemma":"plasticage","pos":"NOM"} ,
		{"word":"plasticien","word_nosc":"plasticien","lemma":"plasticien","pos":"NOM"} ,
		{"word":"plasticienne","word_nosc":"plasticienne","lemma":"plasticien","pos":"NOM"} ,
		{"word":"plasticité","word_nosc":"plasticite","lemma":"plasticité","pos":"NOM"} ,
		{"word":"plastie","word_nosc":"plastie","lemma":"plastie","pos":"NOM"} ,
		{"word":"plastifiant","word_nosc":"plastifiant","lemma":"plastifiant","pos":"NOM"} ,
		{"word":"plastification","word_nosc":"plastification","lemma":"plastification","pos":"NOM"} ,
		{"word":"plastiquage","word_nosc":"plastiquage","lemma":"plastiquage","pos":"NOM"} ,
		{"word":"plastique","word_nosc":"plastique","lemma":"plastique","pos":"NOM"} ,
		{"word":"plastiques","word_nosc":"plastiques","lemma":"plastique","pos":"NOM"} ,
		{"word":"plastiqueur","word_nosc":"plastiqueur","lemma":"plastiqueur","pos":"NOM"} ,
		{"word":"plastisol","word_nosc":"plastisol","lemma":"plastisol","pos":"NOM"} ,
		{"word":"plastoc","word_nosc":"plastoc","lemma":"plastoc","pos":"NOM"} ,
		{"word":"plastron","word_nosc":"plastron","lemma":"plastron","pos":"NOM"} ,
		{"word":"plastronneur","word_nosc":"plastronneur","lemma":"plastronneur","pos":"NOM"} ,
		{"word":"plastrons","word_nosc":"plastrons","lemma":"plastron","pos":"NOM"} ,
		{"word":"plat","word_nosc":"plat","lemma":"plat","pos":"NOM"} ,
		{"word":"plat-bord","word_nosc":"plat-bord","lemma":"plat-bord","pos":"NOM"} ,
		{"word":"plat-ventre","word_nosc":"plat-ventre","lemma":"plat-ventre","pos":"NOM"} ,
		{"word":"platane","word_nosc":"platane","lemma":"platane","pos":"NOM"} ,
		{"word":"platanes","word_nosc":"platanes","lemma":"platane","pos":"NOM"} ,
		{"word":"plate","word_nosc":"plate","lemma":"plate","pos":"NOM"} ,
		{"word":"plate-bande","word_nosc":"plate-bande","lemma":"plate-bande","pos":"NOM"} ,
		{"word":"plate-forme","word_nosc":"plate-forme","lemma":"plate-forme","pos":"NOM"} ,
		{"word":"plateau","word_nosc":"plateau","lemma":"plateau","pos":"NOM"} ,
		{"word":"plateau-repas","word_nosc":"plateau-repas","lemma":"plateau-repas","pos":"NOM"} ,
		{"word":"plateaux","word_nosc":"plateaux","lemma":"plateau","pos":"NOM"} ,
		{"word":"plateaux-repas","word_nosc":"plateaux-repas","lemma":"plateaux-repas","pos":"NOM"} ,
		{"word":"platebandes","word_nosc":"platebandes","lemma":"platebande","pos":"NOM"} ,
		{"word":"plateforme","word_nosc":"plateforme","lemma":"plateforme","pos":"NOM"} ,
		{"word":"platelage","word_nosc":"platelage","lemma":"platelage","pos":"NOM"} ,
		{"word":"plates","word_nosc":"plates","lemma":"plate","pos":"NOM"} ,
		{"word":"plates-bandes","word_nosc":"plates-bandes","lemma":"plate-bande","pos":"NOM"} ,
		{"word":"plates-formes","word_nosc":"plates-formes","lemma":"plate-forme","pos":"NOM"} ,
		{"word":"platine","word_nosc":"platine","lemma":"platine","pos":"NOM"} ,
		{"word":"platines","word_nosc":"platines","lemma":"platine","pos":"NOM"} ,
		{"word":"platitude","word_nosc":"platitude","lemma":"platitude","pos":"NOM"} ,
		{"word":"platitudes","word_nosc":"platitudes","lemma":"platitude","pos":"NOM"} ,
		{"word":"platière","word_nosc":"platiere","lemma":"platière","pos":"NOM"} ,
		{"word":"platonicien","word_nosc":"platonicien","lemma":"platonicien","pos":"NOM"} ,
		{"word":"plats","word_nosc":"plats","lemma":"plat","pos":"NOM"} ,
		{"word":"plats-bords","word_nosc":"plats-bords","lemma":"plat-bord","pos":"NOM"} ,
		{"word":"platures","word_nosc":"platures","lemma":"plature","pos":"NOM"} ,
		{"word":"platée","word_nosc":"platee","lemma":"platée","pos":"NOM"} ,
		{"word":"platées","word_nosc":"platees","lemma":"platée","pos":"NOM"} ,
		{"word":"plausibilité","word_nosc":"plausibilite","lemma":"plausibilité","pos":"NOM"} ,
		{"word":"play back","word_nosc":"play back","lemma":"play-back","pos":"NOM"} ,
		{"word":"play-back","word_nosc":"play-back","lemma":"play-back","pos":"NOM"} ,
		{"word":"play-boy","word_nosc":"play-boy","lemma":"play-boy","pos":"NOM"} ,
		{"word":"play-boys","word_nosc":"play-boys","lemma":"play-boy","pos":"NOM"} ,
		{"word":"playboy","word_nosc":"playboy","lemma":"playboy","pos":"NOM"} ,
		{"word":"playmate","word_nosc":"playmate","lemma":"playmate","pos":"NOM"} ,
		{"word":"playmates","word_nosc":"playmates","lemma":"playmate","pos":"NOM"} ,
		{"word":"playon","word_nosc":"playon","lemma":"playon","pos":"NOM"} ,
		{"word":"plaza","word_nosc":"plaza","lemma":"plaza","pos":"NOM"} ,
		{"word":"plazza","word_nosc":"plazza","lemma":"plazza","pos":"NOM"} ,
		{"word":"plectre","word_nosc":"plectre","lemma":"plectre","pos":"NOM"} ,
		{"word":"plein","word_nosc":"plein","lemma":"plein","pos":"NOM"} ,
		{"word":"plein-air","word_nosc":"plein-air","lemma":"plein-air","pos":"NOM"} ,
		{"word":"plein-cintre","word_nosc":"plein-cintre","lemma":"plein-cintre","pos":"NOM"} ,
		{"word":"plein-emploi","word_nosc":"plein-emploi","lemma":"plein-emploi","pos":"NOM"} ,
		{"word":"plein-temps","word_nosc":"plein-temps","lemma":"plein-temps","pos":"NOM"} ,
		{"word":"pleins","word_nosc":"pleins","lemma":"plein","pos":"NOM"} ,
		{"word":"plessis","word_nosc":"plessis","lemma":"plessis","pos":"NOM"} ,
		{"word":"plet","word_nosc":"plet","lemma":"plet","pos":"NOM"} ,
		{"word":"pleur","word_nosc":"pleur","lemma":"pleur","pos":"NOM"} ,
		{"word":"pleurard","word_nosc":"pleurard","lemma":"pleurard","pos":"NOM"} ,
		{"word":"pleurement","word_nosc":"pleurement","lemma":"pleurement","pos":"NOM"} ,
		{"word":"pleurements","word_nosc":"pleurements","lemma":"pleurement","pos":"NOM"} ,
		{"word":"pleureur","word_nosc":"pleureur","lemma":"pleureur","pos":"NOM"} ,
		{"word":"pleureurs","word_nosc":"pleureurs","lemma":"pleureur","pos":"NOM"} ,
		{"word":"pleureuse","word_nosc":"pleureuse","lemma":"pleureur","pos":"NOM"} ,
		{"word":"pleureuses","word_nosc":"pleureuses","lemma":"pleureur","pos":"NOM"} ,
		{"word":"pleurite","word_nosc":"pleurite","lemma":"pleurite","pos":"NOM"} ,
		{"word":"pleurnichage","word_nosc":"pleurnichage","lemma":"pleurnichage","pos":"NOM"} ,
		{"word":"pleurnichages","word_nosc":"pleurnichages","lemma":"pleurnichage","pos":"NOM"} ,
		{"word":"pleurnichard","word_nosc":"pleurnichard","lemma":"pleurnichard","pos":"NOM"} ,
		{"word":"pleurnichards","word_nosc":"pleurnichards","lemma":"pleurnichard","pos":"NOM"} ,
		{"word":"pleurnichement","word_nosc":"pleurnichement","lemma":"pleurnichement","pos":"NOM"} ,
		{"word":"pleurnichements","word_nosc":"pleurnichements","lemma":"pleurnichement","pos":"NOM"} ,
		{"word":"pleurnicherie","word_nosc":"pleurnicherie","lemma":"pleurnicherie","pos":"NOM"} ,
		{"word":"pleurnicheries","word_nosc":"pleurnicheries","lemma":"pleurnicherie","pos":"NOM"} ,
		{"word":"pleurnicheur","word_nosc":"pleurnicheur","lemma":"pleurnicheur","pos":"NOM"} ,
		{"word":"pleurnicheurs","word_nosc":"pleurnicheurs","lemma":"pleurnicheur","pos":"NOM"} ,
		{"word":"pleurnicheuse","word_nosc":"pleurnicheuse","lemma":"pleurnicheur","pos":"NOM"} ,
		{"word":"pleurnicheuses","word_nosc":"pleurnicheuses","lemma":"pleurnicheur","pos":"NOM"} ,
		{"word":"pleurotes","word_nosc":"pleurotes","lemma":"pleurote","pos":"NOM"} ,
		{"word":"pleurs","word_nosc":"pleurs","lemma":"pleur","pos":"NOM"} ,
		{"word":"pleurésie","word_nosc":"pleuresie","lemma":"pleurésie","pos":"NOM"} ,
		{"word":"pleutre","word_nosc":"pleutre","lemma":"pleutre","pos":"NOM"} ,
		{"word":"pleutrerie","word_nosc":"pleutrerie","lemma":"pleutrerie","pos":"NOM"} ,
		{"word":"pleutres","word_nosc":"pleutres","lemma":"pleutre","pos":"NOM"} ,
		{"word":"plexi","word_nosc":"plexi","lemma":"plexi","pos":"NOM"} ,
		{"word":"plexiglas","word_nosc":"plexiglas","lemma":"plexiglas","pos":"NOM"} ,
		{"word":"plexus","word_nosc":"plexus","lemma":"plexus","pos":"NOM"} ,
		{"word":"pleyel","word_nosc":"pleyel","lemma":"pleyel","pos":"NOM"} ,
		{"word":"pli","word_nosc":"pli","lemma":"pli","pos":"NOM"} ,
		{"word":"pliage","word_nosc":"pliage","lemma":"pliage","pos":"NOM"} ,
		{"word":"pliages","word_nosc":"pliages","lemma":"pliage","pos":"NOM"} ,
		{"word":"pliant","word_nosc":"pliant","lemma":"pliant","pos":"NOM"} ,
		{"word":"pliants","word_nosc":"pliants","lemma":"pliant","pos":"NOM"} ,
		{"word":"plie","word_nosc":"plie","lemma":"plie","pos":"NOM"} ,
		{"word":"plies","word_nosc":"plies","lemma":"plie","pos":"NOM"} ,
		{"word":"plieur","word_nosc":"plieur","lemma":"plieur","pos":"NOM"} ,
		{"word":"plieuse","word_nosc":"plieuse","lemma":"plieur","pos":"NOM"} ,
		{"word":"plinthe","word_nosc":"plinthe","lemma":"plinthe","pos":"NOM"} ,
		{"word":"plinthes","word_nosc":"plinthes","lemma":"plinthe","pos":"NOM"} ,
		{"word":"pliocène","word_nosc":"pliocene","lemma":"pliocène","pos":"NOM"} ,
		{"word":"plioir","word_nosc":"plioir","lemma":"plioir","pos":"NOM"} ,
		{"word":"plions","word_nosc":"plions","lemma":"plion","pos":"NOM"} ,
		{"word":"plique","word_nosc":"plique","lemma":"plique","pos":"NOM"} ,
		{"word":"plis","word_nosc":"plis","lemma":"pli","pos":"NOM"} ,
		{"word":"plissement","word_nosc":"plissement","lemma":"plissement","pos":"NOM"} ,
		{"word":"plissements","word_nosc":"plissements","lemma":"plissement","pos":"NOM"} ,
		{"word":"plisseur","word_nosc":"plisseur","lemma":"plisseur","pos":"NOM"} ,
		{"word":"plissure","word_nosc":"plissure","lemma":"plissure","pos":"NOM"} ,
		{"word":"plissures","word_nosc":"plissures","lemma":"plissure","pos":"NOM"} ,
		{"word":"plissé","word_nosc":"plisse","lemma":"plissé","pos":"NOM"} ,
		{"word":"plissés","word_nosc":"plisses","lemma":"plissé","pos":"NOM"} ,
		{"word":"pliure","word_nosc":"pliure","lemma":"pliure","pos":"NOM"} ,
		{"word":"pliures","word_nosc":"pliures","lemma":"pliure","pos":"NOM"} ,
		{"word":"plié","word_nosc":"plie","lemma":"plié","pos":"NOM"} ,
		{"word":"pliés","word_nosc":"plies","lemma":"plié","pos":"NOM"} ,
		{"word":"ploiement","word_nosc":"ploiement","lemma":"ploiement","pos":"NOM"} ,
		{"word":"plomb","word_nosc":"plomb","lemma":"plomb","pos":"NOM"} ,
		{"word":"plombage","word_nosc":"plombage","lemma":"plombage","pos":"NOM"} ,
		{"word":"plombages","word_nosc":"plombages","lemma":"plombage","pos":"NOM"} ,
		{"word":"plombe","word_nosc":"plombe","lemma":"plombe","pos":"NOM"} ,
		{"word":"plomberie","word_nosc":"plomberie","lemma":"plomberie","pos":"NOM"} ,
		{"word":"plomberies","word_nosc":"plomberies","lemma":"plomberie","pos":"NOM"} ,
		{"word":"plombes","word_nosc":"plombes","lemma":"plombe","pos":"NOM"} ,
		{"word":"plombier","word_nosc":"plombier","lemma":"plombier","pos":"NOM"} ,
		{"word":"plombiers","word_nosc":"plombiers","lemma":"plombier","pos":"NOM"} ,
		{"word":"plombières","word_nosc":"plombieres","lemma":"plombières","pos":"NOM"} ,
		{"word":"plombs","word_nosc":"plombs","lemma":"plomb","pos":"NOM"} ,
		{"word":"plombure","word_nosc":"plombure","lemma":"plombure","pos":"NOM"} ,
		{"word":"plombée","word_nosc":"plombee","lemma":"plombée","pos":"NOM"} ,
		{"word":"plombées","word_nosc":"plombees","lemma":"plombée","pos":"NOM"} ,
		{"word":"plonge","word_nosc":"plonge","lemma":"plonge","pos":"NOM"} ,
		{"word":"plongement","word_nosc":"plongement","lemma":"plongement","pos":"NOM"} ,
		{"word":"plongeoir","word_nosc":"plongeoir","lemma":"plongeoir","pos":"NOM"} ,
		{"word":"plongeoirs","word_nosc":"plongeoirs","lemma":"plongeoir","pos":"NOM"} ,
		{"word":"plongeon","word_nosc":"plongeon","lemma":"plongeon","pos":"NOM"} ,
		{"word":"plongeons","word_nosc":"plongeons","lemma":"plongeon","pos":"NOM"} ,
		{"word":"plongeur","word_nosc":"plongeur","lemma":"plongeur","pos":"NOM"} ,
		{"word":"plongeurs","word_nosc":"plongeurs","lemma":"plongeur","pos":"NOM"} ,
		{"word":"plongeuse","word_nosc":"plongeuse","lemma":"plongeur","pos":"NOM"} ,
		{"word":"plongeuses","word_nosc":"plongeuses","lemma":"plongeur","pos":"NOM"} ,
		{"word":"plongée","word_nosc":"plongee","lemma":"plongée","pos":"NOM"} ,
		{"word":"plongées","word_nosc":"plongees","lemma":"plongée","pos":"NOM"} ,
		{"word":"plot","word_nosc":"plot","lemma":"plot","pos":"NOM"} ,
		{"word":"plots","word_nosc":"plots","lemma":"plot","pos":"NOM"} ,
		{"word":"plouc","word_nosc":"plouc","lemma":"plouc","pos":"NOM"} ,
		{"word":"ploucs","word_nosc":"ploucs","lemma":"plouc","pos":"NOM"} ,
		{"word":"plouk","word_nosc":"plouk","lemma":"plouk","pos":"NOM"} ,
		{"word":"ploutocrate","word_nosc":"ploutocrate","lemma":"ploutocrate","pos":"NOM"} ,
		{"word":"ploutocrates","word_nosc":"ploutocrates","lemma":"ploutocrate","pos":"NOM"} ,
		{"word":"ploutocratie","word_nosc":"ploutocratie","lemma":"ploutocratie","pos":"NOM"} ,
		{"word":"pluches","word_nosc":"pluches","lemma":"pluches","pos":"NOM"} ,
		{"word":"pluie","word_nosc":"pluie","lemma":"pluie","pos":"NOM"} ,
		{"word":"pluies","word_nosc":"pluies","lemma":"pluie","pos":"NOM"} ,
		{"word":"plum","word_nosc":"plum","lemma":"plume","pos":"NOM"} ,
		{"word":"plum-pudding","word_nosc":"plum-pudding","lemma":"plum-pudding","pos":"NOM"} ,
		{"word":"plumage","word_nosc":"plumage","lemma":"plumage","pos":"NOM"} ,
		{"word":"plumages","word_nosc":"plumages","lemma":"plumage","pos":"NOM"} ,
		{"word":"plumard","word_nosc":"plumard","lemma":"plumard","pos":"NOM"} ,
		{"word":"plumards","word_nosc":"plumards","lemma":"plumard","pos":"NOM"} ,
		{"word":"plumassière","word_nosc":"plumassiere","lemma":"plumassier","pos":"NOM"} ,
		{"word":"plume","word_nosc":"plume","lemma":"plume","pos":"NOM"} ,
		{"word":"plumeau","word_nosc":"plumeau","lemma":"plumeau","pos":"NOM"} ,
		{"word":"plumeaux","word_nosc":"plumeaux","lemma":"plumeau","pos":"NOM"} ,
		{"word":"plumes","word_nosc":"plumes","lemma":"plume","pos":"NOM"} ,
		{"word":"plumet","word_nosc":"plumet","lemma":"plumet","pos":"NOM"} ,
		{"word":"plumetis","word_nosc":"plumetis","lemma":"plumetis","pos":"NOM"} ,
		{"word":"plumets","word_nosc":"plumets","lemma":"plumet","pos":"NOM"} ,
		{"word":"plumier","word_nosc":"plumier","lemma":"plumier","pos":"NOM"} ,
		{"word":"plumiers","word_nosc":"plumiers","lemma":"plumier","pos":"NOM"} ,
		{"word":"plumitif","word_nosc":"plumitif","lemma":"plumitif","pos":"NOM"} ,
		{"word":"plumitifs","word_nosc":"plumitifs","lemma":"plumitif","pos":"NOM"} ,
		{"word":"plumitives","word_nosc":"plumitives","lemma":"plumitive","pos":"NOM"} ,
		{"word":"plumules","word_nosc":"plumules","lemma":"plumule","pos":"NOM"} ,
		{"word":"plumée","word_nosc":"plumee","lemma":"plumée","pos":"NOM"} ,
		{"word":"pluralisme","word_nosc":"pluralisme","lemma":"pluralisme","pos":"NOM"} ,
		{"word":"pluralité","word_nosc":"pluralite","lemma":"pluralité","pos":"NOM"} ,
		{"word":"pluriel","word_nosc":"pluriel","lemma":"pluriel","pos":"NOM"} ,
		{"word":"pluriels","word_nosc":"pluriels","lemma":"pluriel","pos":"NOM"} ,
		{"word":"plus","word_nosc":"plus","lemma":"plus","pos":"NOM"} ,
		{"word":"plus-que-parfait","word_nosc":"plus-que-parfait","lemma":"plus-que-parfait","pos":"NOM"} ,
		{"word":"plus-que-parfaits","word_nosc":"plus-que-parfaits","lemma":"plus-que-parfait","pos":"NOM"} ,
		{"word":"plus-value","word_nosc":"plus-value","lemma":"plus-value","pos":"NOM"} ,
		{"word":"plus-values","word_nosc":"plus-values","lemma":"plus-value","pos":"NOM"} ,
		{"word":"pluton","word_nosc":"pluton","lemma":"pluton","pos":"NOM"} ,
		{"word":"plutonium","word_nosc":"plutonium","lemma":"plutonium","pos":"NOM"} ,
		{"word":"pluvier","word_nosc":"pluvier","lemma":"pluvier","pos":"NOM"} ,
		{"word":"pluviers","word_nosc":"pluviers","lemma":"pluvier","pos":"NOM"} ,
		{"word":"pluviomètre","word_nosc":"pluviometre","lemma":"pluviomètre","pos":"NOM"} ,
		{"word":"pluviomètres","word_nosc":"pluviometres","lemma":"pluviomètre","pos":"NOM"} ,
		{"word":"pluviométrie","word_nosc":"pluviometrie","lemma":"pluviométrie","pos":"NOM"} ,
		{"word":"pluviosité","word_nosc":"pluviosite","lemma":"pluviosité","pos":"NOM"} ,
		{"word":"pluviôse","word_nosc":"pluviose","lemma":"pluviôse","pos":"NOM"} ,
		{"word":"plâtras","word_nosc":"platras","lemma":"plâtras","pos":"NOM"} ,
		{"word":"plâtre","word_nosc":"platre","lemma":"plâtre","pos":"NOM"} ,
		{"word":"plâtres","word_nosc":"platres","lemma":"plâtre","pos":"NOM"} ,
		{"word":"plâtrier","word_nosc":"platrier","lemma":"plâtrier","pos":"NOM"} ,
		{"word":"plâtriers","word_nosc":"platriers","lemma":"plâtrier","pos":"NOM"} ,
		{"word":"plèbe","word_nosc":"plebe","lemma":"plèbe","pos":"NOM"} ,
		{"word":"plèvre","word_nosc":"plevre","lemma":"plèvre","pos":"NOM"} ,
		{"word":"plèvres","word_nosc":"plevres","lemma":"plèvre","pos":"NOM"} ,
		{"word":"plébiscite","word_nosc":"plebiscite","lemma":"plébiscite","pos":"NOM"} ,
		{"word":"plébiscites","word_nosc":"plebiscites","lemma":"plébiscite","pos":"NOM"} ,
		{"word":"plébéien","word_nosc":"plebeien","lemma":"plébéien","pos":"NOM"} ,
		{"word":"plébéienne","word_nosc":"plebeienne","lemma":"plébéien","pos":"NOM"} ,
		{"word":"plébéiennes","word_nosc":"plebeiennes","lemma":"plébéien","pos":"NOM"} ,
		{"word":"plébéiens","word_nosc":"plebeiens","lemma":"plébéien","pos":"NOM"} ,
		{"word":"pléiade","word_nosc":"pleiade","lemma":"pléiade","pos":"NOM"} ,
		{"word":"pléiades","word_nosc":"pleiades","lemma":"pléiade","pos":"NOM"} ,
		{"word":"pléistocène","word_nosc":"pleistocene","lemma":"pléistocène","pos":"NOM"} ,
		{"word":"plénipotentiaire","word_nosc":"plenipotentiaire","lemma":"plénipotentiaire","pos":"NOM"} ,
		{"word":"plénipotentiaires","word_nosc":"plenipotentiaires","lemma":"plénipotentiaire","pos":"NOM"} ,
		{"word":"plénitude","word_nosc":"plenitude","lemma":"plénitude","pos":"NOM"} ,
		{"word":"plénitudes","word_nosc":"plenitudes","lemma":"plénitude","pos":"NOM"} ,
		{"word":"plénum","word_nosc":"plenum","lemma":"plénum","pos":"NOM"} ,
		{"word":"pléonasme","word_nosc":"pleonasme","lemma":"pléonasme","pos":"NOM"} ,
		{"word":"pléonasmes","word_nosc":"pleonasmes","lemma":"pléonasme","pos":"NOM"} ,
		{"word":"plésiosaure","word_nosc":"plesiosaure","lemma":"plésiosaure","pos":"NOM"} ,
		{"word":"pléthore","word_nosc":"plethore","lemma":"pléthore","pos":"NOM"} ,
		{"word":"pneu","word_nosc":"pneu","lemma":"pneu","pos":"NOM"} ,
		{"word":"pneumatique","word_nosc":"pneumatique","lemma":"pneumatique","pos":"NOM"} ,
		{"word":"pneumatiques","word_nosc":"pneumatiques","lemma":"pneumatique","pos":"NOM"} ,
		{"word":"pneumatophore","word_nosc":"pneumatophore","lemma":"pneumatophore","pos":"NOM"} ,
		{"word":"pneumo","word_nosc":"pneumo","lemma":"pneumo","pos":"NOM"} ,
		{"word":"pneumoconiose","word_nosc":"pneumoconiose","lemma":"pneumoconiose","pos":"NOM"} ,
		{"word":"pneumocoque","word_nosc":"pneumocoque","lemma":"pneumocoque","pos":"NOM"} ,
		{"word":"pneumocoques","word_nosc":"pneumocoques","lemma":"pneumocoque","pos":"NOM"} ,
		{"word":"pneumocystose","word_nosc":"pneumocystose","lemma":"pneumocystose","pos":"NOM"} ,
		{"word":"pneumologue","word_nosc":"pneumologue","lemma":"pneumologue","pos":"NOM"} ,
		{"word":"pneumonectomie","word_nosc":"pneumonectomie","lemma":"pneumonectomie","pos":"NOM"} ,
		{"word":"pneumonie","word_nosc":"pneumonie","lemma":"pneumonie","pos":"NOM"} ,
		{"word":"pneumonies","word_nosc":"pneumonies","lemma":"pneumonie","pos":"NOM"} ,
		{"word":"pneumopathie","word_nosc":"pneumopathie","lemma":"pneumopathie","pos":"NOM"} ,
		{"word":"pneumos","word_nosc":"pneumos","lemma":"pneumo","pos":"NOM"} ,
		{"word":"pneumothorax","word_nosc":"pneumothorax","lemma":"pneumothorax","pos":"NOM"} ,
		{"word":"pneus","word_nosc":"pneus","lemma":"pneu","pos":"NOM"} ,
		{"word":"pneus-neige","word_nosc":"pneus-neige","lemma":"pneus-neige","pos":"NOM"} ,
		{"word":"pochade","word_nosc":"pochade","lemma":"pochade","pos":"NOM"} ,
		{"word":"pochades","word_nosc":"pochades","lemma":"pochade","pos":"NOM"} ,
		{"word":"pochage","word_nosc":"pochage","lemma":"pochage","pos":"NOM"} ,
		{"word":"pochard","word_nosc":"pochard","lemma":"pochard","pos":"NOM"} ,
		{"word":"pocharde","word_nosc":"pocharde","lemma":"pochard","pos":"NOM"} ,
		{"word":"pochards","word_nosc":"pochards","lemma":"pochard","pos":"NOM"} ,
		{"word":"poche","word_nosc":"poche","lemma":"poche","pos":"NOM"} ,
		{"word":"poche-revolver","word_nosc":"poche-revolver","lemma":"poche-revolver","pos":"NOM"} ,
		{"word":"poches","word_nosc":"poches","lemma":"poche","pos":"NOM"} ,
		{"word":"pochetron","word_nosc":"pochetron","lemma":"pochetron","pos":"NOM"} ,
		{"word":"pochetrons","word_nosc":"pochetrons","lemma":"pochetron","pos":"NOM"} ,
		{"word":"pochette","word_nosc":"pochette","lemma":"pochette","pos":"NOM"} ,
		{"word":"pochette-surprise","word_nosc":"pochette-surprise","lemma":"pochette-surprise","pos":"NOM"} ,
		{"word":"pochettes","word_nosc":"pochettes","lemma":"pochette","pos":"NOM"} ,
		{"word":"pochettes-surprises","word_nosc":"pochettes-surprises","lemma":"pochette-surprise","pos":"NOM"} ,
		{"word":"pochetée","word_nosc":"pochetee","lemma":"pocheté","pos":"NOM"} ,
		{"word":"pochetée","word_nosc":"pochetee","lemma":"pochetée","pos":"NOM"} ,
		{"word":"pochetées","word_nosc":"pochetees","lemma":"pocheté","pos":"NOM"} ,
		{"word":"pochoir","word_nosc":"pochoir","lemma":"pochoir","pos":"NOM"} ,
		{"word":"pochon","word_nosc":"pochon","lemma":"pochon","pos":"NOM"} ,
		{"word":"pochons","word_nosc":"pochons","lemma":"pochon","pos":"NOM"} ,
		{"word":"pochtron","word_nosc":"pochtron","lemma":"pochtron","pos":"NOM"} ,
		{"word":"pochtrons","word_nosc":"pochtrons","lemma":"pochtron","pos":"NOM"} ,
		{"word":"podestat","word_nosc":"podestat","lemma":"podestat","pos":"NOM"} ,
		{"word":"podestats","word_nosc":"podestats","lemma":"podestat","pos":"NOM"} ,
		{"word":"podium","word_nosc":"podium","lemma":"podium","pos":"NOM"} ,
		{"word":"podiums","word_nosc":"podiums","lemma":"podium","pos":"NOM"} ,
		{"word":"podologue","word_nosc":"podologue","lemma":"podologue","pos":"NOM"} ,
		{"word":"podomètre","word_nosc":"podometre","lemma":"podomètre","pos":"NOM"} ,
		{"word":"poecile","word_nosc":"poecile","lemma":"poecile","pos":"NOM"} ,
		{"word":"pogne","word_nosc":"pogne","lemma":"pogne","pos":"NOM"} ,
		{"word":"pognes","word_nosc":"pognes","lemma":"pogne","pos":"NOM"} ,
		{"word":"pognon","word_nosc":"pognon","lemma":"pognon","pos":"NOM"} ,
		{"word":"pogo","word_nosc":"pogo","lemma":"pogo","pos":"NOM"} ,
		{"word":"pogrom","word_nosc":"pogrom","lemma":"pogrom","pos":"NOM"} ,
		{"word":"pogrome","word_nosc":"pogrome","lemma":"pogrome","pos":"NOM"} ,
		{"word":"pogromes","word_nosc":"pogromes","lemma":"pogrome","pos":"NOM"} ,
		{"word":"pogroms","word_nosc":"pogroms","lemma":"pogrom","pos":"NOM"} ,
		{"word":"poids","word_nosc":"poids","lemma":"poids","pos":"NOM"} ,
		{"word":"poids-lourds","word_nosc":"poids-lourds","lemma":"poids-lourds","pos":"NOM"} ,
		{"word":"poignard","word_nosc":"poignard","lemma":"poignard","pos":"NOM"} ,
		{"word":"poignards","word_nosc":"poignards","lemma":"poignard","pos":"NOM"} ,
		{"word":"poigne","word_nosc":"poigne","lemma":"poigne","pos":"NOM"} ,
		{"word":"poignes","word_nosc":"poignes","lemma":"poigne","pos":"NOM"} ,
		{"word":"poignet","word_nosc":"poignet","lemma":"poignet","pos":"NOM"} ,
		{"word":"poignets","word_nosc":"poignets","lemma":"poignet","pos":"NOM"} ,
		{"word":"poignée","word_nosc":"poignee","lemma":"poignée","pos":"NOM"} ,
		{"word":"poignées","word_nosc":"poignees","lemma":"poignée","pos":"NOM"} ,
		{"word":"poil","word_nosc":"poil","lemma":"poil","pos":"NOM"} ,
		{"word":"poil-de-carotte","word_nosc":"poil-de-carotte","lemma":"poil-de-carotte","pos":"NOM"} ,
		{"word":"poils","word_nosc":"poils","lemma":"poil","pos":"NOM"} ,
		{"word":"poing","word_nosc":"poing","lemma":"poing","pos":"NOM"} ,
		{"word":"poings","word_nosc":"poings","lemma":"poing","pos":"NOM"} ,
		{"word":"poinsettia","word_nosc":"poinsettia","lemma":"poinsettia","pos":"NOM"} ,
		{"word":"point","word_nosc":"point","lemma":"point","pos":"NOM"} ,
		{"word":"point-clé","word_nosc":"point-cle","lemma":"point-clé","pos":"NOM"} ,
		{"word":"point-virgule","word_nosc":"point-virgule","lemma":"point-virgule","pos":"NOM"} ,
		{"word":"pointage","word_nosc":"pointage","lemma":"pointage","pos":"NOM"} ,
		{"word":"pointages","word_nosc":"pointages","lemma":"pointage","pos":"NOM"} ,
		{"word":"pointe","word_nosc":"pointe","lemma":"pointe","pos":"NOM"} ,
		{"word":"pointeau","word_nosc":"pointeau","lemma":"pointeau","pos":"NOM"} ,
		{"word":"pointement","word_nosc":"pointement","lemma":"pointement","pos":"NOM"} ,
		{"word":"pointer","word_nosc":"pointer","lemma":"pointer","pos":"NOM"} ,
		{"word":"pointers","word_nosc":"pointers","lemma":"pointer","pos":"NOM"} ,
		{"word":"pointes","word_nosc":"pointes","lemma":"pointe","pos":"NOM"} ,
		{"word":"pointeur","word_nosc":"pointeur","lemma":"pointeur","pos":"NOM"} ,
		{"word":"pointeurs","word_nosc":"pointeurs","lemma":"pointeur","pos":"NOM"} ,
		{"word":"pointeuse","word_nosc":"pointeuse","lemma":"pointeur","pos":"NOM"} ,
		{"word":"pointillisme","word_nosc":"pointillisme","lemma":"pointillisme","pos":"NOM"} ,
		{"word":"pointillé","word_nosc":"pointille","lemma":"pointillé","pos":"NOM"} ,
		{"word":"pointillés","word_nosc":"pointilles","lemma":"pointillé","pos":"NOM"} ,
		{"word":"points","word_nosc":"points","lemma":"point","pos":"NOM"} ,
		{"word":"points-virgules","word_nosc":"points-virgules","lemma":"point-virgule","pos":"NOM"} ,
		{"word":"pointure","word_nosc":"pointure","lemma":"pointure","pos":"NOM"} ,
		{"word":"pointures","word_nosc":"pointures","lemma":"pointure","pos":"NOM"} ,
		{"word":"poinçon","word_nosc":"poincon","lemma":"poinçon","pos":"NOM"} ,
		{"word":"poinçonneur","word_nosc":"poinconneur","lemma":"poinçonneur","pos":"NOM"} ,
		{"word":"poinçonneurs","word_nosc":"poinconneurs","lemma":"poinçonneur","pos":"NOM"} ,
		{"word":"poinçons","word_nosc":"poincons","lemma":"poinçon","pos":"NOM"} ,
		{"word":"poire","word_nosc":"poire","lemma":"poire","pos":"NOM"} ,
		{"word":"poire-vérité","word_nosc":"poire-verite","lemma":"poire-vérité","pos":"NOM"} ,
		{"word":"poireau","word_nosc":"poireau","lemma":"poireau","pos":"NOM"} ,
		{"word":"poireaux","word_nosc":"poireaux","lemma":"poireau","pos":"NOM"} ,
		{"word":"poires","word_nosc":"poires","lemma":"poire","pos":"NOM"} ,
		{"word":"poirier","word_nosc":"poirier","lemma":"poirier","pos":"NOM"} ,
		{"word":"poiriers","word_nosc":"poiriers","lemma":"poirier","pos":"NOM"} ,
		{"word":"poiré","word_nosc":"poire","lemma":"poiré","pos":"NOM"} ,
		{"word":"poirée","word_nosc":"poiree","lemma":"poiré","pos":"NOM"} ,
		{"word":"pois","word_nosc":"pois","lemma":"pois","pos":"NOM"} ,
		{"word":"poiscaille","word_nosc":"poiscaille","lemma":"poiscaille","pos":"NOM"} ,
		{"word":"poiscailles","word_nosc":"poiscailles","lemma":"poiscaille","pos":"NOM"} ,
		{"word":"poison","word_nosc":"poison","lemma":"poison","pos":"NOM"} ,
		{"word":"poisons","word_nosc":"poisons","lemma":"poison","pos":"NOM"} ,
		{"word":"poissard","word_nosc":"poissard","lemma":"poissard","pos":"NOM"} ,
		{"word":"poissardes","word_nosc":"poissardes","lemma":"poissard","pos":"NOM"} ,
		{"word":"poisse","word_nosc":"poisse","lemma":"poisse","pos":"NOM"} ,
		{"word":"poisses","word_nosc":"poisses","lemma":"poisse","pos":"NOM"} ,
		{"word":"poisson","word_nosc":"poisson","lemma":"poisson","pos":"NOM"} ,
		{"word":"poisson-chat","word_nosc":"poisson-chat","lemma":"poisson-chat","pos":"NOM"} ,
		{"word":"poisson-globe","word_nosc":"poisson-globe","lemma":"poisson-globe","pos":"NOM"} ,
		{"word":"poisson-lune","word_nosc":"poisson-lune","lemma":"poisson-lune","pos":"NOM"} ,
		{"word":"poisson-pilote","word_nosc":"poisson-pilote","lemma":"poisson-pilote","pos":"NOM"} ,
		{"word":"poisson-épée","word_nosc":"poisson-epee","lemma":"poisson-épée","pos":"NOM"} ,
		{"word":"poissonnerie","word_nosc":"poissonnerie","lemma":"poissonnerie","pos":"NOM"} ,
		{"word":"poissonnier","word_nosc":"poissonnier","lemma":"poissonnier","pos":"NOM"} ,
		{"word":"poissonniers","word_nosc":"poissonniers","lemma":"poissonnier","pos":"NOM"} ,
		{"word":"poissonnière","word_nosc":"poissonniere","lemma":"poissonnière","pos":"NOM"} ,
		{"word":"poissonnières","word_nosc":"poissonnieres","lemma":"poissonnier","pos":"NOM"} ,
		{"word":"poissons","word_nosc":"poissons","lemma":"poisson","pos":"NOM"} ,
		{"word":"poissons-chats","word_nosc":"poissons-chats","lemma":"poisson-chat","pos":"NOM"} ,
		{"word":"poitrail","word_nosc":"poitrail","lemma":"poitrail","pos":"NOM"} ,
		{"word":"poitrails","word_nosc":"poitrails","lemma":"poitrail","pos":"NOM"} ,
		{"word":"poitrine","word_nosc":"poitrine","lemma":"poitrine","pos":"NOM"} ,
		{"word":"poitrines","word_nosc":"poitrines","lemma":"poitrine","pos":"NOM"} ,
		{"word":"poitrinières","word_nosc":"poitrinieres","lemma":"poitrinière","pos":"NOM"} ,
		{"word":"poivrade","word_nosc":"poivrade","lemma":"poivrade","pos":"NOM"} ,
		{"word":"poivrades","word_nosc":"poivrades","lemma":"poivrade","pos":"NOM"} ,
		{"word":"poivre","word_nosc":"poivre","lemma":"poivre","pos":"NOM"} ,
		{"word":"poivres","word_nosc":"poivres","lemma":"poivre","pos":"NOM"} ,
		{"word":"poivrier","word_nosc":"poivrier","lemma":"poivrier","pos":"NOM"} ,
		{"word":"poivriers","word_nosc":"poivriers","lemma":"poivrier","pos":"NOM"} ,
		{"word":"poivrière","word_nosc":"poivriere","lemma":"poivrière","pos":"NOM"} ,
		{"word":"poivrières","word_nosc":"poivrieres","lemma":"poivrière","pos":"NOM"} ,
		{"word":"poivron","word_nosc":"poivron","lemma":"poivron","pos":"NOM"} ,
		{"word":"poivrons","word_nosc":"poivrons","lemma":"poivron","pos":"NOM"} ,
		{"word":"poivrot","word_nosc":"poivrot","lemma":"poivrot","pos":"NOM"} ,
		{"word":"poivrote","word_nosc":"poivrote","lemma":"poivrot","pos":"NOM"} ,
		{"word":"poivrotes","word_nosc":"poivrotes","lemma":"poivrot","pos":"NOM"} ,
		{"word":"poivrots","word_nosc":"poivrots","lemma":"poivrot","pos":"NOM"} ,
		{"word":"poix","word_nosc":"poix","lemma":"poix","pos":"NOM"} ,
		{"word":"poker","word_nosc":"poker","lemma":"poker","pos":"NOM"} ,
		{"word":"pokers","word_nosc":"pokers","lemma":"poker","pos":"NOM"} ,
		{"word":"polack","word_nosc":"polack","lemma":"polack","pos":"NOM"} ,
		{"word":"polacks","word_nosc":"polacks","lemma":"polack","pos":"NOM"} ,
		{"word":"polaire","word_nosc":"polaire","lemma":"polaire","pos":"NOM"} ,
		{"word":"polak","word_nosc":"polak","lemma":"polak","pos":"NOM"} ,
		{"word":"polaks","word_nosc":"polaks","lemma":"polak","pos":"NOM"} ,
		{"word":"polaque","word_nosc":"polaque","lemma":"polaque","pos":"NOM"} ,
		{"word":"polaques","word_nosc":"polaques","lemma":"polaque","pos":"NOM"} ,
		{"word":"polar","word_nosc":"polar","lemma":"polar","pos":"NOM"} ,
		{"word":"polarisation","word_nosc":"polarisation","lemma":"polarisation","pos":"NOM"} ,
		{"word":"polarité","word_nosc":"polarite","lemma":"polarité","pos":"NOM"} ,
		{"word":"polaroid","word_nosc":"polaroid","lemma":"polaroid","pos":"NOM"} ,
		{"word":"polaroïd","word_nosc":"polaroid","lemma":"polaroïd","pos":"NOM"} ,
		{"word":"polaroïds","word_nosc":"polaroids","lemma":"polaroïd","pos":"NOM"} ,
		{"word":"polars","word_nosc":"polars","lemma":"polar","pos":"NOM"} ,
		{"word":"polder","word_nosc":"polder","lemma":"polder","pos":"NOM"} ,
		{"word":"polders","word_nosc":"polders","lemma":"polder","pos":"NOM"} ,
		{"word":"pole","word_nosc":"pole","lemma":"pole","pos":"NOM"} ,
		{"word":"polenta","word_nosc":"polenta","lemma":"polenta","pos":"NOM"} ,
		{"word":"poli","word_nosc":"poli","lemma":"poli","pos":"NOM"} ,
		{"word":"police","word_nosc":"police","lemma":"police","pos":"NOM"} ,
		{"word":"police-secours","word_nosc":"police-secours","lemma":"police-secours","pos":"NOM"} ,
		{"word":"policeman","word_nosc":"policeman","lemma":"policeman","pos":"NOM"} ,
		{"word":"policemen","word_nosc":"policemen","lemma":"policeman","pos":"NOM"} ,
		{"word":"polices","word_nosc":"polices","lemma":"police","pos":"NOM"} ,
		{"word":"polichinelle","word_nosc":"polichinelle","lemma":"polichinelle","pos":"NOM"} ,
		{"word":"polichinelles","word_nosc":"polichinelles","lemma":"polichinelle","pos":"NOM"} ,
		{"word":"policier","word_nosc":"policier","lemma":"policier","pos":"NOM"} ,
		{"word":"policiers","word_nosc":"policiers","lemma":"policier","pos":"NOM"} ,
		{"word":"policière","word_nosc":"policiere","lemma":"policier","pos":"NOM"} ,
		{"word":"policières","word_nosc":"policieres","lemma":"policier","pos":"NOM"} ,
		{"word":"policlinique","word_nosc":"policlinique","lemma":"policlinique","pos":"NOM"} ,
		{"word":"polio","word_nosc":"polio","lemma":"polio","pos":"NOM"} ,
		{"word":"poliomyélite","word_nosc":"poliomyelite","lemma":"poliomyélite","pos":"NOM"} ,
		{"word":"polios","word_nosc":"polios","lemma":"polio","pos":"NOM"} ,
		{"word":"polis","word_nosc":"polis","lemma":"poli","pos":"NOM"} ,
		{"word":"polish","word_nosc":"polish","lemma":"polish","pos":"NOM"} ,
		{"word":"polissage","word_nosc":"polissage","lemma":"polissage","pos":"NOM"} ,
		{"word":"polissages","word_nosc":"polissages","lemma":"polissage","pos":"NOM"} ,
		{"word":"polisseur","word_nosc":"polisseur","lemma":"polisseur","pos":"NOM"} ,
		{"word":"polisseurs","word_nosc":"polisseurs","lemma":"polisseur","pos":"NOM"} ,
		{"word":"polisseuse","word_nosc":"polisseuse","lemma":"polisseur","pos":"NOM"} ,
		{"word":"polissoir","word_nosc":"polissoir","lemma":"polissoir","pos":"NOM"} ,
		{"word":"polisson","word_nosc":"polisson","lemma":"polisson","pos":"NOM"} ,
		{"word":"polissonne","word_nosc":"polissonne","lemma":"polisson","pos":"NOM"} ,
		{"word":"polissonnerie","word_nosc":"polissonnerie","lemma":"polissonnerie","pos":"NOM"} ,
		{"word":"polissonneries","word_nosc":"polissonneries","lemma":"polissonnerie","pos":"NOM"} ,
		{"word":"polissonnes","word_nosc":"polissonnes","lemma":"polisson","pos":"NOM"} ,
		{"word":"polissons","word_nosc":"polissons","lemma":"polisson","pos":"NOM"} ,
		{"word":"polissure","word_nosc":"polissure","lemma":"polissure","pos":"NOM"} ,
		{"word":"politburo","word_nosc":"politburo","lemma":"politburo","pos":"NOM"} ,
		{"word":"politesse","word_nosc":"politesse","lemma":"politesse","pos":"NOM"} ,
		{"word":"politesses","word_nosc":"politesses","lemma":"politesse","pos":"NOM"} ,
		{"word":"politicard","word_nosc":"politicard","lemma":"politicard","pos":"NOM"} ,
		{"word":"politicards","word_nosc":"politicards","lemma":"politicard","pos":"NOM"} ,
		{"word":"politicien","word_nosc":"politicien","lemma":"politicien","pos":"NOM"} ,
		{"word":"politicienne","word_nosc":"politicienne","lemma":"politicien","pos":"NOM"} ,
		{"word":"politiciens","word_nosc":"politiciens","lemma":"politicien","pos":"NOM"} ,
		{"word":"politique","word_nosc":"politique","lemma":"politique","pos":"NOM"} ,
		{"word":"politique-fiction","word_nosc":"politique-fiction","lemma":"politique-fiction","pos":"NOM"} ,
		{"word":"politiques","word_nosc":"politiques","lemma":"politique","pos":"NOM"} ,
		{"word":"politisation","word_nosc":"politisation","lemma":"politisation","pos":"NOM"} ,
		{"word":"polka","word_nosc":"polka","lemma":"polka","pos":"NOM"} ,
		{"word":"polkas","word_nosc":"polkas","lemma":"polka","pos":"NOM"} ,
		{"word":"pollen","word_nosc":"pollen","lemma":"pollen","pos":"NOM"} ,
		{"word":"pollens","word_nosc":"pollens","lemma":"pollen","pos":"NOM"} ,
		{"word":"pollinies","word_nosc":"pollinies","lemma":"pollinie","pos":"NOM"} ,
		{"word":"pollinisation","word_nosc":"pollinisation","lemma":"pollinisation","pos":"NOM"} ,
		{"word":"pollueur","word_nosc":"pollueur","lemma":"pollueur","pos":"NOM"} ,
		{"word":"pollueurs","word_nosc":"pollueurs","lemma":"pollueur","pos":"NOM"} ,
		{"word":"pollueuse","word_nosc":"pollueuse","lemma":"pollueur","pos":"NOM"} ,
		{"word":"pollution","word_nosc":"pollution","lemma":"pollution","pos":"NOM"} ,
		{"word":"pollutions","word_nosc":"pollutions","lemma":"pollution","pos":"NOM"} ,
		{"word":"polo","word_nosc":"polo","lemma":"polo","pos":"NOM"} ,
		{"word":"poloche","word_nosc":"poloche","lemma":"poloche","pos":"NOM"} ,
		{"word":"polochon","word_nosc":"polochon","lemma":"polochon","pos":"NOM"} ,
		{"word":"polochons","word_nosc":"polochons","lemma":"polochon","pos":"NOM"} ,
		{"word":"polonais","word_nosc":"polonais","lemma":"polonais","pos":"NOM"} ,
		{"word":"polonaise","word_nosc":"polonaise","lemma":"polonais","pos":"NOM"} ,
		{"word":"polonaises","word_nosc":"polonaises","lemma":"polonais","pos":"NOM"} ,
		{"word":"polos","word_nosc":"polos","lemma":"polo","pos":"NOM"} ,
		{"word":"polski","word_nosc":"polski","lemma":"polski","pos":"NOM"} ,
		{"word":"poltron","word_nosc":"poltron","lemma":"poltron","pos":"NOM"} ,
		{"word":"poltronnerie","word_nosc":"poltronnerie","lemma":"poltronnerie","pos":"NOM"} ,
		{"word":"poltrons","word_nosc":"poltrons","lemma":"poltron","pos":"NOM"} ,
		{"word":"poly","word_nosc":"poly","lemma":"poly","pos":"NOM"} ,
		{"word":"polyacrylate","word_nosc":"polyacrylate","lemma":"polyacrylate","pos":"NOM"} ,
		{"word":"polyamide","word_nosc":"polyamide","lemma":"polyamide","pos":"NOM"} ,
		{"word":"polyamides","word_nosc":"polyamides","lemma":"polyamide","pos":"NOM"} ,
		{"word":"polyarthrite","word_nosc":"polyarthrite","lemma":"polyarthrite","pos":"NOM"} ,
		{"word":"polycarbonate","word_nosc":"polycarbonate","lemma":"polycarbonate","pos":"NOM"} ,
		{"word":"polychlorure","word_nosc":"polychlorure","lemma":"polychlorure","pos":"NOM"} ,
		{"word":"polychromie","word_nosc":"polychromie","lemma":"polychromie","pos":"NOM"} ,
		{"word":"polyclinique","word_nosc":"polyclinique","lemma":"polyclinique","pos":"NOM"} ,
		{"word":"polycopie","word_nosc":"polycopie","lemma":"polycopie","pos":"NOM"} ,
		{"word":"polycopié","word_nosc":"polycopie","lemma":"polycopié","pos":"NOM"} ,
		{"word":"polycopiés","word_nosc":"polycopies","lemma":"polycopié","pos":"NOM"} ,
		{"word":"polyculture","word_nosc":"polyculture","lemma":"polyculture","pos":"NOM"} ,
		{"word":"polydactylie","word_nosc":"polydactylie","lemma":"polydactylie","pos":"NOM"} ,
		{"word":"polyester","word_nosc":"polyester","lemma":"polyester","pos":"NOM"} ,
		{"word":"polygamie","word_nosc":"polygamie","lemma":"polygamie","pos":"NOM"} ,
		{"word":"polyglotte","word_nosc":"polyglotte","lemma":"polyglotte","pos":"NOM"} ,
		{"word":"polyglottes","word_nosc":"polyglottes","lemma":"polyglotte","pos":"NOM"} ,
		{"word":"polygone","word_nosc":"polygone","lemma":"polygone","pos":"NOM"} ,
		{"word":"polygones","word_nosc":"polygones","lemma":"polygone","pos":"NOM"} ,
		{"word":"polygraphe","word_nosc":"polygraphe","lemma":"polygraphe","pos":"NOM"} ,
		{"word":"polygraphes","word_nosc":"polygraphes","lemma":"polygraphe","pos":"NOM"} ,
		{"word":"polymorphie","word_nosc":"polymorphie","lemma":"polymorphie","pos":"NOM"} ,
		{"word":"polymorphismes","word_nosc":"polymorphismes","lemma":"polymorphisme","pos":"NOM"} ,
		{"word":"polymère","word_nosc":"polymere","lemma":"polymère","pos":"NOM"} ,
		{"word":"polymérase","word_nosc":"polymerase","lemma":"polymérase","pos":"NOM"} ,
		{"word":"polynésien","word_nosc":"polynesien","lemma":"polynésien","pos":"NOM"} ,
		{"word":"polynésiennes","word_nosc":"polynesiennes","lemma":"polynésien","pos":"NOM"} ,
		{"word":"polynésiens","word_nosc":"polynesiens","lemma":"polynésien","pos":"NOM"} ,
		{"word":"polype","word_nosc":"polype","lemma":"polype","pos":"NOM"} ,
		{"word":"polypes","word_nosc":"polypes","lemma":"polype","pos":"NOM"} ,
		{"word":"polyphonie","word_nosc":"polyphonie","lemma":"polyphonie","pos":"NOM"} ,
		{"word":"polypier","word_nosc":"polypier","lemma":"polypier","pos":"NOM"} ,
		{"word":"polypiers","word_nosc":"polypiers","lemma":"polypier","pos":"NOM"} ,
		{"word":"polypropylène","word_nosc":"polypropylene","lemma":"polypropylène","pos":"NOM"} ,
		{"word":"polysaccharides","word_nosc":"polysaccharides","lemma":"polysaccharide","pos":"NOM"} ,
		{"word":"polystyrène","word_nosc":"polystyrene","lemma":"polystyrène","pos":"NOM"} ,
		{"word":"polytechnicien","word_nosc":"polytechnicien","lemma":"polytechnicien","pos":"NOM"} ,
		{"word":"polytechnicienne","word_nosc":"polytechnicienne","lemma":"polytechnicien","pos":"NOM"} ,
		{"word":"polytechniciens","word_nosc":"polytechniciens","lemma":"polytechnicien","pos":"NOM"} ,
		{"word":"polytechnique","word_nosc":"polytechnique","lemma":"polytechnique","pos":"NOM"} ,
		{"word":"polythène","word_nosc":"polythene","lemma":"polythène","pos":"NOM"} ,
		{"word":"polytonalité","word_nosc":"polytonalite","lemma":"polytonalité","pos":"NOM"} ,
		{"word":"polyuréthane","word_nosc":"polyurethane","lemma":"polyuréthane","pos":"NOM"} ,
		{"word":"polyvalence","word_nosc":"polyvalence","lemma":"polyvalence","pos":"NOM"} ,
		{"word":"polyvalents","word_nosc":"polyvalents","lemma":"polyvalent","pos":"NOM"} ,
		{"word":"polyvinyle","word_nosc":"polyvinyle","lemma":"polyvinyle","pos":"NOM"} ,
		{"word":"polyèdre","word_nosc":"polyedre","lemma":"polyèdre","pos":"NOM"} ,
		{"word":"polyèdres","word_nosc":"polyedres","lemma":"polyèdre","pos":"NOM"} ,
		{"word":"polyéthylène","word_nosc":"polyethylene","lemma":"polyéthylène","pos":"NOM"} ,
		{"word":"polémique","word_nosc":"polemique","lemma":"polémique","pos":"NOM"} ,
		{"word":"polémiques","word_nosc":"polemiques","lemma":"polémique","pos":"NOM"} ,
		{"word":"polémiste","word_nosc":"polemiste","lemma":"polémiste","pos":"NOM"} ,
		{"word":"polémistes","word_nosc":"polemistes","lemma":"polémiste","pos":"NOM"} ,
		{"word":"pom-pom girl","word_nosc":"pom-pom girl","lemma":"pom-pom-girl","pos":"NOM"} ,
		{"word":"pom-pom girls","word_nosc":"pom-pom girls","lemma":"pom-pom-girl","pos":"NOM"} ,
		{"word":"pomelo","word_nosc":"pomelo","lemma":"pomelo","pos":"NOM"} ,
		{"word":"pomerols","word_nosc":"pomerols","lemma":"pomerol","pos":"NOM"} ,
		{"word":"pommade","word_nosc":"pommade","lemma":"pommade","pos":"NOM"} ,
		{"word":"pommades","word_nosc":"pommades","lemma":"pommade","pos":"NOM"} ,
		{"word":"pommadin","word_nosc":"pommadin","lemma":"pommadin","pos":"NOM"} ,
		{"word":"pommard","word_nosc":"pommard","lemma":"pommard","pos":"NOM"} ,
		{"word":"pommards","word_nosc":"pommards","lemma":"pommard","pos":"NOM"} ,
		{"word":"pomme","word_nosc":"pomme","lemma":"pomme","pos":"NOM"} ,
		{"word":"pommeau","word_nosc":"pommeau","lemma":"pommeau","pos":"NOM"} ,
		{"word":"pommeaux","word_nosc":"pommeaux","lemma":"pommeau","pos":"NOM"} ,
		{"word":"pommeraie","word_nosc":"pommeraie","lemma":"pommeraie","pos":"NOM"} ,
		{"word":"pommes","word_nosc":"pommes","lemma":"pomme","pos":"NOM"} ,
		{"word":"pommette","word_nosc":"pommette","lemma":"pommette","pos":"NOM"} ,
		{"word":"pommettes","word_nosc":"pommettes","lemma":"pommette","pos":"NOM"} ,
		{"word":"pommier","word_nosc":"pommier","lemma":"pommier","pos":"NOM"} ,
		{"word":"pommiers","word_nosc":"pommiers","lemma":"pommier","pos":"NOM"} ,
		{"word":"pompage","word_nosc":"pompage","lemma":"pompage","pos":"NOM"} ,
		{"word":"pompe","word_nosc":"pompe","lemma":"pompe","pos":"NOM"} ,
		{"word":"pompes","word_nosc":"pompes","lemma":"pompe","pos":"NOM"} ,
		{"word":"pompeur","word_nosc":"pompeur","lemma":"pompeur","pos":"NOM"} ,
		{"word":"pompier","word_nosc":"pompier","lemma":"pompier","pos":"NOM"} ,
		{"word":"pompiers","word_nosc":"pompiers","lemma":"pompier","pos":"NOM"} ,
		{"word":"pompiste","word_nosc":"pompiste","lemma":"pompiste","pos":"NOM"} ,
		{"word":"pompistes","word_nosc":"pompistes","lemma":"pompiste","pos":"NOM"} ,
		{"word":"pompiérisme","word_nosc":"pompierisme","lemma":"pompiérisme","pos":"NOM"} ,
		{"word":"pompon","word_nosc":"pompon","lemma":"pompon","pos":"NOM"} ,
		{"word":"pompons","word_nosc":"pompons","lemma":"pompon","pos":"NOM"} ,
		{"word":"pomélo","word_nosc":"pomelo","lemma":"pomélo","pos":"NOM"} ,
		{"word":"poméranienne","word_nosc":"pomeranienne","lemma":"poméranien","pos":"NOM"} ,
		{"word":"poméraniennes","word_nosc":"pomeraniennes","lemma":"poméranien","pos":"NOM"} ,
		{"word":"poméraniens","word_nosc":"pomeraniens","lemma":"poméranien","pos":"NOM"} ,
		{"word":"ponant","word_nosc":"ponant","lemma":"ponant","pos":"NOM"} ,
		{"word":"ponce","word_nosc":"ponce","lemma":"ponce","pos":"NOM"} ,
		{"word":"ponceau","word_nosc":"ponceau","lemma":"ponceau","pos":"NOM"} ,
		{"word":"ponceaux","word_nosc":"ponceaux","lemma":"ponceau","pos":"NOM"} ,
		{"word":"ponceuse","word_nosc":"ponceuse","lemma":"ponceur","pos":"NOM"} ,
		{"word":"poncho","word_nosc":"poncho","lemma":"poncho","pos":"NOM"} ,
		{"word":"ponchos","word_nosc":"ponchos","lemma":"poncho","pos":"NOM"} ,
		{"word":"poncif","word_nosc":"poncif","lemma":"poncif","pos":"NOM"} ,
		{"word":"poncifs","word_nosc":"poncifs","lemma":"poncif","pos":"NOM"} ,
		{"word":"ponction","word_nosc":"ponction","lemma":"ponction","pos":"NOM"} ,
		{"word":"ponctions","word_nosc":"ponctions","lemma":"ponction","pos":"NOM"} ,
		{"word":"ponctualité","word_nosc":"ponctualite","lemma":"ponctualité","pos":"NOM"} ,
		{"word":"ponctuation","word_nosc":"ponctuation","lemma":"ponctuation","pos":"NOM"} ,
		{"word":"ponctuations","word_nosc":"ponctuations","lemma":"ponctuation","pos":"NOM"} ,
		{"word":"pondeur","word_nosc":"pondeur","lemma":"pondeur","pos":"NOM"} ,
		{"word":"pondeuse","word_nosc":"pondeuse","lemma":"pondeur","pos":"NOM"} ,
		{"word":"pondeuses","word_nosc":"pondeuses","lemma":"pondeur","pos":"NOM"} ,
		{"word":"pondoir","word_nosc":"pondoir","lemma":"pondoir","pos":"NOM"} ,
		{"word":"pondoirs","word_nosc":"pondoirs","lemma":"pondoir","pos":"NOM"} ,
		{"word":"pondération","word_nosc":"ponderation","lemma":"pondération","pos":"NOM"} ,
		{"word":"ponette","word_nosc":"ponette","lemma":"ponette","pos":"NOM"} ,
		{"word":"ponettes","word_nosc":"ponettes","lemma":"ponette","pos":"NOM"} ,
		{"word":"poney","word_nosc":"poney","lemma":"poney","pos":"NOM"} ,
		{"word":"poneys","word_nosc":"poneys","lemma":"poney","pos":"NOM"} ,
		{"word":"pongiste","word_nosc":"pongiste","lemma":"pongiste","pos":"NOM"} ,
		{"word":"pongistes","word_nosc":"pongistes","lemma":"pongiste","pos":"NOM"} ,
		{"word":"pongo","word_nosc":"pongo","lemma":"pongo","pos":"NOM"} ,
		{"word":"pongé","word_nosc":"ponge","lemma":"pongé","pos":"NOM"} ,
		{"word":"pont","word_nosc":"pont","lemma":"pont","pos":"NOM"} ,
		{"word":"pont-l'évêque","word_nosc":"pont-l'eveque","lemma":"pont-l'évêque","pos":"NOM"} ,
		{"word":"pont-levis","word_nosc":"pont-levis","lemma":"pont-levis","pos":"NOM"} ,
		{"word":"pont-neuf","word_nosc":"pont-neuf","lemma":"pont-neuf","pos":"NOM"} ,
		{"word":"pont-promenade","word_nosc":"pont-promenade","lemma":"pont-promenade","pos":"NOM"} ,
		{"word":"pontage","word_nosc":"pontage","lemma":"pontage","pos":"NOM"} ,
		{"word":"pontages","word_nosc":"pontages","lemma":"pontage","pos":"NOM"} ,
		{"word":"ponte","word_nosc":"ponte","lemma":"ponte","pos":"NOM"} ,
		{"word":"pontes","word_nosc":"pontes","lemma":"ponte","pos":"NOM"} ,
		{"word":"pontet","word_nosc":"pontet","lemma":"pontet","pos":"NOM"} ,
		{"word":"pontets","word_nosc":"pontets","lemma":"pontet","pos":"NOM"} ,
		{"word":"pontife","word_nosc":"pontife","lemma":"pontife","pos":"NOM"} ,
		{"word":"pontifes","word_nosc":"pontifes","lemma":"pontife","pos":"NOM"} ,
		{"word":"pontificat","word_nosc":"pontificat","lemma":"pontificat","pos":"NOM"} ,
		{"word":"pontil","word_nosc":"pontil","lemma":"pontil","pos":"NOM"} ,
		{"word":"ponton","word_nosc":"ponton","lemma":"ponton","pos":"NOM"} ,
		{"word":"pontonnier","word_nosc":"pontonnier","lemma":"pontonnier","pos":"NOM"} ,
		{"word":"pontonniers","word_nosc":"pontonniers","lemma":"pontonnier","pos":"NOM"} ,
		{"word":"pontons","word_nosc":"pontons","lemma":"ponton","pos":"NOM"} ,
		{"word":"ponts","word_nosc":"ponts","lemma":"pont","pos":"NOM"} ,
		{"word":"ponts-levis","word_nosc":"ponts-levis","lemma":"ponts-levis","pos":"NOM"} ,
		{"word":"ponçage","word_nosc":"poncage","lemma":"ponçage","pos":"NOM"} ,
		{"word":"pool","word_nosc":"pool","lemma":"pool","pos":"NOM"} ,
		{"word":"pop","word_nosc":"pop","lemma":"pop","pos":"NOM"} ,
		{"word":"pop-club","word_nosc":"pop-club","lemma":"pop-club","pos":"NOM"} ,
		{"word":"pop-corn","word_nosc":"pop-corn","lemma":"pop-corn","pos":"NOM"} ,
		{"word":"popaul","word_nosc":"popaul","lemma":"popaul","pos":"NOM"} ,
		{"word":"pope","word_nosc":"pope","lemma":"pope","pos":"NOM"} ,
		{"word":"popeline","word_nosc":"popeline","lemma":"popeline","pos":"NOM"} ,
		{"word":"popes","word_nosc":"popes","lemma":"pope","pos":"NOM"} ,
		{"word":"popote","word_nosc":"popote","lemma":"popote","pos":"NOM"} ,
		{"word":"popotes","word_nosc":"popotes","lemma":"popote","pos":"NOM"} ,
		{"word":"popotin","word_nosc":"popotin","lemma":"popotin","pos":"NOM"} ,
		{"word":"populace","word_nosc":"populace","lemma":"populace","pos":"NOM"} ,
		{"word":"popularité","word_nosc":"popularite","lemma":"popularité","pos":"NOM"} ,
		{"word":"popularités","word_nosc":"popularites","lemma":"popularité","pos":"NOM"} ,
		{"word":"population","word_nosc":"population","lemma":"population","pos":"NOM"} ,
		{"word":"populations","word_nosc":"populations","lemma":"population","pos":"NOM"} ,
		{"word":"populisme","word_nosc":"populisme","lemma":"populisme","pos":"NOM"} ,
		{"word":"populismes","word_nosc":"populismes","lemma":"populisme","pos":"NOM"} ,
		{"word":"populiste","word_nosc":"populiste","lemma":"populiste","pos":"NOM"} ,
		{"word":"populistes","word_nosc":"populistes","lemma":"populiste","pos":"NOM"} ,
		{"word":"populo","word_nosc":"populo","lemma":"populo","pos":"NOM"} ,
		{"word":"poque","word_nosc":"poque","lemma":"poque","pos":"NOM"} ,
		{"word":"poquette","word_nosc":"poquette","lemma":"poquette","pos":"NOM"} ,
		{"word":"porc","word_nosc":"porc","lemma":"porc","pos":"NOM"} ,
		{"word":"porc-épic","word_nosc":"porc-epic","lemma":"porc-épic","pos":"NOM"} ,
		{"word":"porcelaine","word_nosc":"porcelaine","lemma":"porcelaine","pos":"NOM"} ,
		{"word":"porcelaines","word_nosc":"porcelaines","lemma":"porcelaine","pos":"NOM"} ,
		{"word":"porcelainiers","word_nosc":"porcelainiers","lemma":"porcelainier","pos":"NOM"} ,
		{"word":"porcelet","word_nosc":"porcelet","lemma":"porcelet","pos":"NOM"} ,
		{"word":"porcelets","word_nosc":"porcelets","lemma":"porcelet","pos":"NOM"} ,
		{"word":"porche","word_nosc":"porche","lemma":"porche","pos":"NOM"} ,
		{"word":"porcher","word_nosc":"porcher","lemma":"porcher","pos":"NOM"} ,
		{"word":"porcherie","word_nosc":"porcherie","lemma":"porcherie","pos":"NOM"} ,
		{"word":"porcheries","word_nosc":"porcheries","lemma":"porcherie","pos":"NOM"} ,
		{"word":"porches","word_nosc":"porches","lemma":"porche","pos":"NOM"} ,
		{"word":"porchère","word_nosc":"porchere","lemma":"porcher","pos":"NOM"} ,
		{"word":"porchères","word_nosc":"porcheres","lemma":"porcher","pos":"NOM"} ,
		{"word":"porcif","word_nosc":"porcif","lemma":"porcif","pos":"NOM"} ,
		{"word":"porcifs","word_nosc":"porcifs","lemma":"porcif","pos":"NOM"} ,
		{"word":"porcin","word_nosc":"porcin","lemma":"porcin","pos":"NOM"} ,
		{"word":"porcins","word_nosc":"porcins","lemma":"porcin","pos":"NOM"} ,
		{"word":"porcs","word_nosc":"porcs","lemma":"porc","pos":"NOM"} ,
		{"word":"pore","word_nosc":"pore","lemma":"pore","pos":"NOM"} ,
		{"word":"pores","word_nosc":"pores","lemma":"pore","pos":"NOM"} ,
		{"word":"porno","word_nosc":"porno","lemma":"porno","pos":"NOM"} ,
		{"word":"pornocrate","word_nosc":"pornocrate","lemma":"pornocrate","pos":"NOM"} ,
		{"word":"pornocrates","word_nosc":"pornocrates","lemma":"pornocrate","pos":"NOM"} ,
		{"word":"pornographe","word_nosc":"pornographe","lemma":"pornographe","pos":"NOM"} ,
		{"word":"pornographes","word_nosc":"pornographes","lemma":"pornographe","pos":"NOM"} ,
		{"word":"pornographie","word_nosc":"pornographie","lemma":"pornographie","pos":"NOM"} ,
		{"word":"pornographies","word_nosc":"pornographies","lemma":"pornographie","pos":"NOM"} ,
		{"word":"pornos","word_nosc":"pornos","lemma":"porno","pos":"NOM"} ,
		{"word":"porosité","word_nosc":"porosite","lemma":"porosité","pos":"NOM"} ,
		{"word":"porphyre","word_nosc":"porphyre","lemma":"porphyre","pos":"NOM"} ,
		{"word":"porphyres","word_nosc":"porphyres","lemma":"porphyre","pos":"NOM"} ,
		{"word":"porphyrie","word_nosc":"porphyrie","lemma":"porphyrie","pos":"NOM"} ,
		{"word":"porque","word_nosc":"porque","lemma":"porque","pos":"NOM"} ,
		{"word":"porridge","word_nosc":"porridge","lemma":"porridge","pos":"NOM"} ,
		{"word":"port","word_nosc":"port","lemma":"port","pos":"NOM"} ,
		{"word":"port-salut","word_nosc":"port-salut","lemma":"port-salut","pos":"NOM"} ,
		{"word":"portabilité","word_nosc":"portabilite","lemma":"portabilité","pos":"NOM"} ,
		{"word":"portage","word_nosc":"portage","lemma":"portage","pos":"NOM"} ,
		{"word":"portail","word_nosc":"portail","lemma":"portail","pos":"NOM"} ,
		{"word":"portails","word_nosc":"portails","lemma":"portail","pos":"NOM"} ,
		{"word":"portance","word_nosc":"portance","lemma":"portance","pos":"NOM"} ,
		{"word":"portant","word_nosc":"portant","lemma":"portant","pos":"NOM"} ,
		{"word":"portants","word_nosc":"portants","lemma":"portant","pos":"NOM"} ,
		{"word":"porte","word_nosc":"porte","lemma":"porte","pos":"NOM"} ,
		{"word":"porte-aiguille","word_nosc":"porte-aiguille","lemma":"porte-aiguille","pos":"NOM"} ,
		{"word":"porte-avion","word_nosc":"porte-avion","lemma":"porte-avion","pos":"NOM"} ,
		{"word":"porte-avions","word_nosc":"porte-avions","lemma":"porte-avions","pos":"NOM"} ,
		{"word":"porte-bagages","word_nosc":"porte-bagages","lemma":"porte-bagages","pos":"NOM"} ,
		{"word":"porte-bannière","word_nosc":"porte-banniere","lemma":"porte-bannière","pos":"NOM"} ,
		{"word":"porte-billets","word_nosc":"porte-billets","lemma":"porte-billets","pos":"NOM"} ,
		{"word":"porte-bois","word_nosc":"porte-bois","lemma":"porte-bois","pos":"NOM"} ,
		{"word":"porte-bonheur","word_nosc":"porte-bonheur","lemma":"porte-bonheur","pos":"NOM"} ,
		{"word":"porte-bouteilles","word_nosc":"porte-bouteilles","lemma":"porte-bouteilles","pos":"NOM"} ,
		{"word":"porte-bébé","word_nosc":"porte-bebe","lemma":"porte-bébé","pos":"NOM"} ,
		{"word":"porte-cannes","word_nosc":"porte-cannes","lemma":"porte-cannes","pos":"NOM"} ,
		{"word":"porte-carte","word_nosc":"porte-carte","lemma":"porte-carte","pos":"NOM"} ,
		{"word":"porte-cartes","word_nosc":"porte-cartes","lemma":"porte-cartes","pos":"NOM"} ,
		{"word":"porte-chance","word_nosc":"porte-chance","lemma":"porte-chance","pos":"NOM"} ,
		{"word":"porte-chapeaux","word_nosc":"porte-chapeaux","lemma":"porte-chapeaux","pos":"NOM"} ,
		{"word":"porte-cigarette","word_nosc":"porte-cigarette","lemma":"porte-cigarette","pos":"NOM"} ,
		{"word":"porte-cigarettes","word_nosc":"porte-cigarettes","lemma":"porte-cigarettes","pos":"NOM"} ,
		{"word":"porte-clef","word_nosc":"porte-clef","lemma":"porte-clef","pos":"NOM"} ,
		{"word":"porte-clefs","word_nosc":"porte-clefs","lemma":"porte-clefs","pos":"NOM"} ,
		{"word":"porte-clé","word_nosc":"porte-cle","lemma":"porte-clé","pos":"NOM"} ,
		{"word":"porte-clés","word_nosc":"porte-cles","lemma":"porte-clés","pos":"NOM"} ,
		{"word":"porte-conteneurs","word_nosc":"porte-conteneurs","lemma":"porte-conteneurs","pos":"NOM"} ,
		{"word":"porte-coton","word_nosc":"porte-coton","lemma":"porte-coton","pos":"NOM"} ,
		{"word":"porte-couilles","word_nosc":"porte-couilles","lemma":"porte-couilles","pos":"NOM"} ,
		{"word":"porte-couteau","word_nosc":"porte-couteau","lemma":"porte-couteau","pos":"NOM"} ,
		{"word":"porte-couteaux","word_nosc":"porte-couteaux","lemma":"porte-couteaux","pos":"NOM"} ,
		{"word":"porte-cravate","word_nosc":"porte-cravate","lemma":"porte-cravate","pos":"NOM"} ,
		{"word":"porte-crayon","word_nosc":"porte-crayon","lemma":"porte-crayon","pos":"NOM"} ,
		{"word":"porte-document","word_nosc":"porte-document","lemma":"porte-document","pos":"NOM"} ,
		{"word":"porte-documents","word_nosc":"porte-documents","lemma":"porte-documents","pos":"NOM"} ,
		{"word":"porte-drapeau","word_nosc":"porte-drapeau","lemma":"porte-drapeau","pos":"NOM"} ,
		{"word":"porte-drapeaux","word_nosc":"porte-drapeaux","lemma":"porte-drapeaux","pos":"NOM"} ,
		{"word":"porte-fanion","word_nosc":"porte-fanion","lemma":"porte-fanion","pos":"NOM"} ,
		{"word":"porte-fenêtre","word_nosc":"porte-fenetre","lemma":"porte-fenêtre","pos":"NOM"} ,
		{"word":"porte-flingue","word_nosc":"porte-flingue","lemma":"porte-flingue","pos":"NOM"} ,
		{"word":"porte-flingues","word_nosc":"porte-flingues","lemma":"porte-flingue","pos":"NOM"} ,
		{"word":"porte-forets","word_nosc":"porte-forets","lemma":"porte-foret","pos":"NOM"} ,
		{"word":"porte-glaive","word_nosc":"porte-glaive","lemma":"porte-glaive","pos":"NOM"} ,
		{"word":"porte-jarretelles","word_nosc":"porte-jarretelles","lemma":"porte-jarretelles","pos":"NOM"} ,
		{"word":"porte-malheur","word_nosc":"porte-malheur","lemma":"porte-malheur","pos":"NOM"} ,
		{"word":"porte-mine","word_nosc":"porte-mine","lemma":"porte-mine","pos":"NOM"} ,
		{"word":"porte-monnaie","word_nosc":"porte-monnaie","lemma":"porte-monnaie","pos":"NOM"} ,
		{"word":"porte-musique","word_nosc":"porte-musique","lemma":"porte-musique","pos":"NOM"} ,
		{"word":"porte-objet","word_nosc":"porte-objet","lemma":"porte-objet","pos":"NOM"} ,
		{"word":"porte-papier","word_nosc":"porte-papier","lemma":"porte-papier","pos":"NOM"} ,
		{"word":"porte-parapluie","word_nosc":"porte-parapluie","lemma":"porte-parapluie","pos":"NOM"} ,
		{"word":"porte-parapluies","word_nosc":"porte-parapluies","lemma":"porte-parapluies","pos":"NOM"} ,
		{"word":"porte-plume","word_nosc":"porte-plume","lemma":"porte-plume","pos":"NOM"} ,
		{"word":"porte-plumes","word_nosc":"porte-plumes","lemma":"porte-plume","pos":"NOM"} ,
		{"word":"porte-queue","word_nosc":"porte-queue","lemma":"porte-queue","pos":"NOM"} ,
		{"word":"porte-savon","word_nosc":"porte-savon","lemma":"porte-savon","pos":"NOM"} ,
		{"word":"porte-serviette","word_nosc":"porte-serviette","lemma":"porte-serviette","pos":"NOM"} ,
		{"word":"porte-serviettes","word_nosc":"porte-serviettes","lemma":"porte-serviettes","pos":"NOM"} ,
		{"word":"porte-tambour","word_nosc":"porte-tambour","lemma":"porte-tambour","pos":"NOM"} ,
		{"word":"porte-voix","word_nosc":"porte-voix","lemma":"porte-voix","pos":"NOM"} ,
		{"word":"porte-à-faux","word_nosc":"porte-a-faux","lemma":"porte-à-faux","pos":"NOM"} ,
		{"word":"porte-à-porte","word_nosc":"porte-a-porte","lemma":"porte-à-porte","pos":"NOM"} ,
		{"word":"porte-épée","word_nosc":"porte-epee","lemma":"porte-épée","pos":"NOM"} ,
		{"word":"porte-étendard","word_nosc":"porte-etendard","lemma":"porte-étendard","pos":"NOM"} ,
		{"word":"porte-étendards","word_nosc":"porte-etendards","lemma":"porte-étendard","pos":"NOM"} ,
		{"word":"porte-étrier","word_nosc":"porte-etrier","lemma":"porte-étrier","pos":"NOM"} ,
		{"word":"portefaix","word_nosc":"portefaix","lemma":"portefaix","pos":"NOM"} ,
		{"word":"portefeuille","word_nosc":"portefeuille","lemma":"portefeuille","pos":"NOM"} ,
		{"word":"portefeuilles","word_nosc":"portefeuilles","lemma":"portefeuille","pos":"NOM"} ,
		{"word":"portemanteau","word_nosc":"portemanteau","lemma":"portemanteau","pos":"NOM"} ,
		{"word":"portemanteaux","word_nosc":"portemanteaux","lemma":"portemanteau","pos":"NOM"} ,
		{"word":"portement","word_nosc":"portement","lemma":"portement","pos":"NOM"} ,
		{"word":"porter","word_nosc":"porter","lemma":"porter","pos":"NOM"} ,
		{"word":"porterie","word_nosc":"porterie","lemma":"porterie","pos":"NOM"} ,
		{"word":"portes","word_nosc":"portes","lemma":"porte","pos":"NOM"} ,
		{"word":"portes-fenêtres","word_nosc":"portes-fenetres","lemma":"porte-fenêtre","pos":"NOM"} ,
		{"word":"porteur","word_nosc":"porteur","lemma":"porteur","pos":"NOM"} ,
		{"word":"porteurs","word_nosc":"porteurs","lemma":"porteur","pos":"NOM"} ,
		{"word":"porteuse","word_nosc":"porteuse","lemma":"porteur","pos":"NOM"} ,
		{"word":"porteuses","word_nosc":"porteuses","lemma":"porteur","pos":"NOM"} ,
		{"word":"portfolio","word_nosc":"portfolio","lemma":"portfolio","pos":"NOM"} ,
		{"word":"portier","word_nosc":"portier","lemma":"portier","pos":"NOM"} ,
		{"word":"portiers","word_nosc":"portiers","lemma":"portier","pos":"NOM"} ,
		{"word":"portillon","word_nosc":"portillon","lemma":"portillon","pos":"NOM"} ,
		{"word":"portillons","word_nosc":"portillons","lemma":"portillon","pos":"NOM"} ,
		{"word":"portion","word_nosc":"portion","lemma":"portion","pos":"NOM"} ,
		{"word":"portions","word_nosc":"portions","lemma":"portion","pos":"NOM"} ,
		{"word":"portique","word_nosc":"portique","lemma":"portique","pos":"NOM"} ,
		{"word":"portiques","word_nosc":"portiques","lemma":"portique","pos":"NOM"} ,
		{"word":"portière","word_nosc":"portiere","lemma":"portier","pos":"NOM"} ,
		{"word":"portières","word_nosc":"portieres","lemma":"portier","pos":"NOM"} ,
		{"word":"portland","word_nosc":"portland","lemma":"portland","pos":"NOM"} ,
		{"word":"porto","word_nosc":"porto","lemma":"porto","pos":"NOM"} ,
		{"word":"portor","word_nosc":"portor","lemma":"portor","pos":"NOM"} ,
		{"word":"portoricain","word_nosc":"portoricain","lemma":"portoricain","pos":"NOM"} ,
		{"word":"portoricaine","word_nosc":"portoricaine","lemma":"portoricain","pos":"NOM"} ,
		{"word":"portoricaines","word_nosc":"portoricaines","lemma":"portoricain","pos":"NOM"} ,
		{"word":"portoricains","word_nosc":"portoricains","lemma":"portoricain","pos":"NOM"} ,
		{"word":"portos","word_nosc":"portos","lemma":"porto","pos":"NOM"} ,
		{"word":"portrait","word_nosc":"portrait","lemma":"portrait","pos":"NOM"} ,
		{"word":"portrait-robot","word_nosc":"portrait-robot","lemma":"portrait-robot","pos":"NOM"} ,
		{"word":"portraitiste","word_nosc":"portraitiste","lemma":"portraitiste","pos":"NOM"} ,
		{"word":"portraitistes","word_nosc":"portraitistes","lemma":"portraitiste","pos":"NOM"} ,
		{"word":"portraits","word_nosc":"portraits","lemma":"portrait","pos":"NOM"} ,
		{"word":"portraits-robots","word_nosc":"portraits-robots","lemma":"portrait-robot","pos":"NOM"} ,
		{"word":"ports","word_nosc":"ports","lemma":"port","pos":"NOM"} ,
		{"word":"portugais","word_nosc":"portugais","lemma":"portugais","pos":"NOM"} ,
		{"word":"portugaise","word_nosc":"portugaise","lemma":"portugais","pos":"NOM"} ,
		{"word":"portugaises","word_nosc":"portugaises","lemma":"portugais","pos":"NOM"} ,
		{"word":"portulan","word_nosc":"portulan","lemma":"portulan","pos":"NOM"} ,
		{"word":"portulans","word_nosc":"portulans","lemma":"portulan","pos":"NOM"} ,
		{"word":"portus","word_nosc":"portus","lemma":"portus","pos":"NOM"} ,
		{"word":"portée","word_nosc":"portee","lemma":"portée","pos":"NOM"} ,
		{"word":"portées","word_nosc":"portees","lemma":"portée","pos":"NOM"} ,
		{"word":"posada","word_nosc":"posada","lemma":"posada","pos":"NOM"} ,
		{"word":"posadas","word_nosc":"posadas","lemma":"posada","pos":"NOM"} ,
		{"word":"pose","word_nosc":"pose","lemma":"pose","pos":"NOM"} ,
		{"word":"pose-la-moi","word_nosc":"pose-la-moi","lemma":"pose-la-moi","pos":"NOM"} ,
		{"word":"posemètre","word_nosc":"posemetre","lemma":"posemètre","pos":"NOM"} ,
		{"word":"poses","word_nosc":"poses","lemma":"pose","pos":"NOM"} ,
		{"word":"poseur","word_nosc":"poseur","lemma":"poseur","pos":"NOM"} ,
		{"word":"poseurs","word_nosc":"poseurs","lemma":"poseur","pos":"NOM"} ,
		{"word":"poseuse","word_nosc":"poseuse","lemma":"poseur","pos":"NOM"} ,
		{"word":"positif","word_nosc":"positif","lemma":"positif","pos":"NOM"} ,
		{"word":"positifs","word_nosc":"positifs","lemma":"positif","pos":"NOM"} ,
		{"word":"position","word_nosc":"position","lemma":"position","pos":"NOM"} ,
		{"word":"position-clé","word_nosc":"position-cle","lemma":"position-clé","pos":"NOM"} ,
		{"word":"positionnement","word_nosc":"positionnement","lemma":"positionnement","pos":"NOM"} ,
		{"word":"positionneur","word_nosc":"positionneur","lemma":"positionneur","pos":"NOM"} ,
		{"word":"positions","word_nosc":"positions","lemma":"position","pos":"NOM"} ,
		{"word":"positivisme","word_nosc":"positivisme","lemma":"positivisme","pos":"NOM"} ,
		{"word":"positiviste","word_nosc":"positiviste","lemma":"positiviste","pos":"NOM"} ,
		{"word":"positivité","word_nosc":"positivite","lemma":"positivité","pos":"NOM"} ,
		{"word":"positon","word_nosc":"positon","lemma":"positon","pos":"NOM"} ,
		{"word":"positron","word_nosc":"positron","lemma":"positron","pos":"NOM"} ,
		{"word":"positrons","word_nosc":"positrons","lemma":"positron","pos":"NOM"} ,
		{"word":"posologie","word_nosc":"posologie","lemma":"posologie","pos":"NOM"} ,
		{"word":"posologies","word_nosc":"posologies","lemma":"posologie","pos":"NOM"} ,
		{"word":"possesseur","word_nosc":"possesseur","lemma":"possesseur","pos":"NOM"} ,
		{"word":"possesseurs","word_nosc":"possesseurs","lemma":"possesseur","pos":"NOM"} ,
		{"word":"possessif","word_nosc":"possessif","lemma":"possessif","pos":"NOM"} ,
		{"word":"possessifs","word_nosc":"possessifs","lemma":"possessif","pos":"NOM"} ,
		{"word":"possession","word_nosc":"possession","lemma":"possession","pos":"NOM"} ,
		{"word":"possessions","word_nosc":"possessions","lemma":"possession","pos":"NOM"} ,
		{"word":"possessivité","word_nosc":"possessivite","lemma":"possessivité","pos":"NOM"} ,
		{"word":"possibilité","word_nosc":"possibilite","lemma":"possibilité","pos":"NOM"} ,
		{"word":"possibilités","word_nosc":"possibilites","lemma":"possibilité","pos":"NOM"} ,
		{"word":"possible","word_nosc":"possible","lemma":"possible","pos":"NOM"} ,
		{"word":"possibles","word_nosc":"possibles","lemma":"possible","pos":"NOM"} ,
		{"word":"possédant","word_nosc":"possedant","lemma":"possédant","pos":"NOM"} ,
		{"word":"possédants","word_nosc":"possedants","lemma":"possédant","pos":"NOM"} ,
		{"word":"possédé","word_nosc":"possede","lemma":"possédé","pos":"NOM"} ,
		{"word":"possédée","word_nosc":"possedee","lemma":"possédé","pos":"NOM"} ,
		{"word":"possédées","word_nosc":"possedees","lemma":"possédé","pos":"NOM"} ,
		{"word":"possédés","word_nosc":"possedes","lemma":"possédé","pos":"NOM"} ,
		{"word":"post it","word_nosc":"post it","lemma":"post-it","pos":"NOM"} ,
		{"word":"post-empire","word_nosc":"post-empire","lemma":"post-empire","pos":"NOM"} ,
		{"word":"post-impressionnisme","word_nosc":"post-impressionnisme","lemma":"post-impressionnisme","pos":"NOM"} ,
		{"word":"post-it","word_nosc":"post-it","lemma":"post-it","pos":"NOM"} ,
		{"word":"post-moderne","word_nosc":"post-moderne","lemma":"post-moderne","pos":"NOM"} ,
		{"word":"post-partum","word_nosc":"post-partum","lemma":"post-partum","pos":"NOM"} ,
		{"word":"post-production","word_nosc":"post-production","lemma":"post-production","pos":"NOM"} ,
		{"word":"post-punk","word_nosc":"post-punk","lemma":"post-punk","pos":"NOM"} ,
		{"word":"post-rupture","word_nosc":"post-rupture","lemma":"post-rupture","pos":"NOM"} ,
		{"word":"post-scriptum","word_nosc":"post-scriptum","lemma":"post-scriptum","pos":"NOM"} ,
		{"word":"post-zoo","word_nosc":"post-zoo","lemma":"post-zoo","pos":"NOM"} ,
		{"word":"postage","word_nosc":"postage","lemma":"postage","pos":"NOM"} ,
		{"word":"postcombustion","word_nosc":"postcombustion","lemma":"postcombustion","pos":"NOM"} ,
		{"word":"postcure","word_nosc":"postcure","lemma":"postcure","pos":"NOM"} ,
		{"word":"poste","word_nosc":"poste","lemma":"poste","pos":"NOM"} ,
		{"word":"poste-clé","word_nosc":"poste-cle","lemma":"poste-clé","pos":"NOM"} ,
		{"word":"poster","word_nosc":"poster","lemma":"poster","pos":"NOM"} ,
		{"word":"posters","word_nosc":"posters","lemma":"poster","pos":"NOM"} ,
		{"word":"postes","word_nosc":"postes","lemma":"poste","pos":"NOM"} ,
		{"word":"postes-clés","word_nosc":"postes-cles","lemma":"postes-clé","pos":"NOM"} ,
		{"word":"postface","word_nosc":"postface","lemma":"postface","pos":"NOM"} ,
		{"word":"postfaces","word_nosc":"postfaces","lemma":"postface","pos":"NOM"} ,
		{"word":"postiche","word_nosc":"postiche","lemma":"postiche","pos":"NOM"} ,
		{"word":"postiches","word_nosc":"postiches","lemma":"postiche","pos":"NOM"} ,
		{"word":"postier","word_nosc":"postier","lemma":"postier","pos":"NOM"} ,
		{"word":"postiers","word_nosc":"postiers","lemma":"postier","pos":"NOM"} ,
		{"word":"postillon","word_nosc":"postillon","lemma":"postillon","pos":"NOM"} ,
		{"word":"postillonneur","word_nosc":"postillonneur","lemma":"postillonneur","pos":"NOM"} ,
		{"word":"postillonneurs","word_nosc":"postillonneurs","lemma":"postillonneur","pos":"NOM"} ,
		{"word":"postillons","word_nosc":"postillons","lemma":"postillon","pos":"NOM"} ,
		{"word":"postière","word_nosc":"postiere","lemma":"postier","pos":"NOM"} ,
		{"word":"postmodernisme","word_nosc":"postmodernisme","lemma":"postmodernisme","pos":"NOM"} ,
		{"word":"postproduction","word_nosc":"postproduction","lemma":"postproduction","pos":"NOM"} ,
		{"word":"postsynchronisation","word_nosc":"postsynchronisation","lemma":"postsynchronisation","pos":"NOM"} ,
		{"word":"postulant","word_nosc":"postulant","lemma":"postulant","pos":"NOM"} ,
		{"word":"postulante","word_nosc":"postulante","lemma":"postulant","pos":"NOM"} ,
		{"word":"postulantes","word_nosc":"postulantes","lemma":"postulant","pos":"NOM"} ,
		{"word":"postulants","word_nosc":"postulants","lemma":"postulant","pos":"NOM"} ,
		{"word":"postulat","word_nosc":"postulat","lemma":"postulat","pos":"NOM"} ,
		{"word":"postulations","word_nosc":"postulations","lemma":"postulation","pos":"NOM"} ,
		{"word":"postulats","word_nosc":"postulats","lemma":"postulat","pos":"NOM"} ,
		{"word":"posture","word_nosc":"posture","lemma":"posture","pos":"NOM"} ,
		{"word":"postures","word_nosc":"postures","lemma":"posture","pos":"NOM"} ,
		{"word":"postérieur","word_nosc":"posterieur","lemma":"postérieur","pos":"NOM"} ,
		{"word":"postérieurs","word_nosc":"posterieurs","lemma":"postérieur","pos":"NOM"} ,
		{"word":"postérité","word_nosc":"posterite","lemma":"postérité","pos":"NOM"} ,
		{"word":"postérités","word_nosc":"posterites","lemma":"postérité","pos":"NOM"} ,
		{"word":"pot","word_nosc":"pot","lemma":"pot","pos":"NOM"} ,
		{"word":"pot-au-feu","word_nosc":"pot-au-feu","lemma":"pot-au-feu","pos":"NOM"} ,
		{"word":"pot-bouille","word_nosc":"pot-bouille","lemma":"pot-bouille","pos":"NOM"} ,
		{"word":"pot-de-vin","word_nosc":"pot-de-vin","lemma":"pot-de-vin","pos":"NOM"} ,
		{"word":"pot-pourri","word_nosc":"pot-pourri","lemma":"pot-pourri","pos":"NOM"} ,
		{"word":"potache","word_nosc":"potache","lemma":"potache","pos":"NOM"} ,
		{"word":"potaches","word_nosc":"potaches","lemma":"potache","pos":"NOM"} ,
		{"word":"potage","word_nosc":"potage","lemma":"potage","pos":"NOM"} ,
		{"word":"potager","word_nosc":"potager","lemma":"potager","pos":"NOM"} ,
		{"word":"potagers","word_nosc":"potagers","lemma":"potager","pos":"NOM"} ,
		{"word":"potages","word_nosc":"potages","lemma":"potage","pos":"NOM"} ,
		{"word":"potard","word_nosc":"potard","lemma":"potard","pos":"NOM"} ,
		{"word":"potasse","word_nosc":"potasse","lemma":"potasse","pos":"NOM"} ,
		{"word":"potassium","word_nosc":"potassium","lemma":"potassium","pos":"NOM"} ,
		{"word":"pote","word_nosc":"pote","lemma":"pote","pos":"NOM"} ,
		{"word":"poteau","word_nosc":"poteau","lemma":"poteau","pos":"NOM"} ,
		{"word":"poteaux","word_nosc":"poteaux","lemma":"poteau","pos":"NOM"} ,
		{"word":"potence","word_nosc":"potence","lemma":"potence","pos":"NOM"} ,
		{"word":"potences","word_nosc":"potences","lemma":"potence","pos":"NOM"} ,
		{"word":"potentat","word_nosc":"potentat","lemma":"potentat","pos":"NOM"} ,
		{"word":"potentats","word_nosc":"potentats","lemma":"potentat","pos":"NOM"} ,
		{"word":"potentialisation","word_nosc":"potentialisation","lemma":"potentialisation","pos":"NOM"} ,
		{"word":"potentialité","word_nosc":"potentialite","lemma":"potentialité","pos":"NOM"} ,
		{"word":"potentiel","word_nosc":"potentiel","lemma":"potentiel","pos":"NOM"} ,
		{"word":"potentiels","word_nosc":"potentiels","lemma":"potentiel","pos":"NOM"} ,
		{"word":"potentiomètre","word_nosc":"potentiometre","lemma":"potentiomètre","pos":"NOM"} ,
		{"word":"poterie","word_nosc":"poterie","lemma":"poterie","pos":"NOM"} ,
		{"word":"poteries","word_nosc":"poteries","lemma":"poterie","pos":"NOM"} ,
		{"word":"poterne","word_nosc":"poterne","lemma":"poterne","pos":"NOM"} ,
		{"word":"poternes","word_nosc":"poternes","lemma":"poterne","pos":"NOM"} ,
		{"word":"potes","word_nosc":"potes","lemma":"pote","pos":"NOM"} ,
		{"word":"potiche","word_nosc":"potiche","lemma":"potiche","pos":"NOM"} ,
		{"word":"potiches","word_nosc":"potiches","lemma":"potiche","pos":"NOM"} ,
		{"word":"potier","word_nosc":"potier","lemma":"potier","pos":"NOM"} ,
		{"word":"potiers","word_nosc":"potiers","lemma":"potier","pos":"NOM"} ,
		{"word":"potimarron","word_nosc":"potimarron","lemma":"potimarron","pos":"NOM"} ,
		{"word":"potin","word_nosc":"potin","lemma":"potin","pos":"NOM"} ,
		{"word":"potins","word_nosc":"potins","lemma":"potin","pos":"NOM"} ,
		{"word":"potion","word_nosc":"potion","lemma":"potion","pos":"NOM"} ,
		{"word":"potions","word_nosc":"potions","lemma":"potion","pos":"NOM"} ,
		{"word":"potiron","word_nosc":"potiron","lemma":"potiron","pos":"NOM"} ,
		{"word":"potirons","word_nosc":"potirons","lemma":"potiron","pos":"NOM"} ,
		{"word":"potière","word_nosc":"potiere","lemma":"potier","pos":"NOM"} ,
		{"word":"potlatch","word_nosc":"potlatch","lemma":"potlatch","pos":"NOM"} ,
		{"word":"poto-poto","word_nosc":"poto-poto","lemma":"poto-poto","pos":"NOM"} ,
		{"word":"potos","word_nosc":"potos","lemma":"potos","pos":"NOM"} ,
		{"word":"potron-minet","word_nosc":"potron-minet","lemma":"potron-minet","pos":"NOM"} ,
		{"word":"pots","word_nosc":"pots","lemma":"pot","pos":"NOM"} ,
		{"word":"pots-de-vin","word_nosc":"pots-de-vin","lemma":"pots-de-vin","pos":"NOM"} ,
		{"word":"pots-pourris","word_nosc":"pots-pourris","lemma":"pot-pourri","pos":"NOM"} ,
		{"word":"potée","word_nosc":"potee","lemma":"potée","pos":"NOM"} ,
		{"word":"potées","word_nosc":"potees","lemma":"potée","pos":"NOM"} ,
		{"word":"pou","word_nosc":"pou","lemma":"pou","pos":"NOM"} ,
		{"word":"pouacre","word_nosc":"pouacre","lemma":"pouacre","pos":"NOM"} ,
		{"word":"poubelle","word_nosc":"poubelle","lemma":"poubelle","pos":"NOM"} ,
		{"word":"poubelles","word_nosc":"poubelles","lemma":"poubelle","pos":"NOM"} ,
		{"word":"pouce","word_nosc":"pouce","lemma":"pouce","pos":"NOM"} ,
		{"word":"pouce-pied","word_nosc":"pouce-pied","lemma":"pouce-pied","pos":"NOM"} ,
		{"word":"pouces","word_nosc":"pouces","lemma":"pouce","pos":"NOM"} ,
		{"word":"poucet","word_nosc":"poucet","lemma":"poucet","pos":"NOM"} ,
		{"word":"poucets","word_nosc":"poucets","lemma":"poucet","pos":"NOM"} ,
		{"word":"poucette","word_nosc":"poucette","lemma":"poucette","pos":"NOM"} ,
		{"word":"poucettes","word_nosc":"poucettes","lemma":"poucette","pos":"NOM"} ,
		{"word":"pouding","word_nosc":"pouding","lemma":"pouding","pos":"NOM"} ,
		{"word":"poudingue","word_nosc":"poudingue","lemma":"poudingue","pos":"NOM"} ,
		{"word":"poudrage","word_nosc":"poudrage","lemma":"poudrage","pos":"NOM"} ,
		{"word":"poudre","word_nosc":"poudre","lemma":"poudre","pos":"NOM"} ,
		{"word":"poudres","word_nosc":"poudres","lemma":"poudre","pos":"NOM"} ,
		{"word":"poudrette","word_nosc":"poudrette","lemma":"poudrette","pos":"NOM"} ,
		{"word":"poudreuse","word_nosc":"poudreuse","lemma":"poudreux","pos":"NOM"} ,
		{"word":"poudrier","word_nosc":"poudrier","lemma":"poudrier","pos":"NOM"} ,
		{"word":"poudriers","word_nosc":"poudriers","lemma":"poudrier","pos":"NOM"} ,
		{"word":"poudrière","word_nosc":"poudriere","lemma":"poudrière","pos":"NOM"} ,
		{"word":"poudroiement","word_nosc":"poudroiement","lemma":"poudroiement","pos":"NOM"} ,
		{"word":"poudroiements","word_nosc":"poudroiements","lemma":"poudroiement","pos":"NOM"} ,
		{"word":"pouf","word_nosc":"pouf","lemma":"pouf","pos":"NOM"} ,
		{"word":"pouffement","word_nosc":"pouffement","lemma":"pouffement","pos":"NOM"} ,
		{"word":"pouffements","word_nosc":"pouffements","lemma":"pouffement","pos":"NOM"} ,
		{"word":"pouffiasse","word_nosc":"pouffiasse","lemma":"pouffiasse","pos":"NOM"} ,
		{"word":"pouffiasses","word_nosc":"pouffiasses","lemma":"pouffiasse","pos":"NOM"} ,
		{"word":"poufiasse","word_nosc":"poufiasse","lemma":"poufiasse","pos":"NOM"} ,
		{"word":"poufiasses","word_nosc":"poufiasses","lemma":"poufiasse","pos":"NOM"} ,
		{"word":"poufs","word_nosc":"poufs","lemma":"pouf","pos":"NOM"} ,
		{"word":"pouille","word_nosc":"pouille","lemma":"pouille","pos":"NOM"} ,
		{"word":"pouillerie","word_nosc":"pouillerie","lemma":"pouillerie","pos":"NOM"} ,
		{"word":"pouilleries","word_nosc":"pouilleries","lemma":"pouillerie","pos":"NOM"} ,
		{"word":"pouilles","word_nosc":"pouilles","lemma":"pouille","pos":"NOM"} ,
		{"word":"pouilleuse","word_nosc":"pouilleuse","lemma":"pouilleux","pos":"NOM"} ,
		{"word":"pouilleux","word_nosc":"pouilleux","lemma":"pouilleux","pos":"NOM"} ,
		{"word":"pouillot","word_nosc":"pouillot","lemma":"pouillot","pos":"NOM"} ,
		{"word":"pouillots","word_nosc":"pouillots","lemma":"pouillot","pos":"NOM"} ,
		{"word":"pouilly","word_nosc":"pouilly","lemma":"pouilly","pos":"NOM"} ,
		{"word":"pouillés","word_nosc":"pouilles","lemma":"pouillé","pos":"NOM"} ,
		{"word":"poujadisme","word_nosc":"poujadisme","lemma":"poujadisme","pos":"NOM"} ,
		{"word":"poujadiste","word_nosc":"poujadiste","lemma":"poujadiste","pos":"NOM"} ,
		{"word":"poujadistes","word_nosc":"poujadistes","lemma":"poujadiste","pos":"NOM"} ,
		{"word":"poulaga","word_nosc":"poulaga","lemma":"poulaga","pos":"NOM"} ,
		{"word":"poulagas","word_nosc":"poulagas","lemma":"poulaga","pos":"NOM"} ,
		{"word":"poulaille","word_nosc":"poulaille","lemma":"poulaille","pos":"NOM"} ,
		{"word":"poulailler","word_nosc":"poulailler","lemma":"poulailler","pos":"NOM"} ,
		{"word":"poulaillers","word_nosc":"poulaillers","lemma":"poulailler","pos":"NOM"} ,
		{"word":"poulain","word_nosc":"poulain","lemma":"poulain","pos":"NOM"} ,
		{"word":"poulaine","word_nosc":"poulaine","lemma":"poulain","pos":"NOM"} ,
		{"word":"poulaines","word_nosc":"poulaines","lemma":"poulain","pos":"NOM"} ,
		{"word":"poulains","word_nosc":"poulains","lemma":"poulain","pos":"NOM"} ,
		{"word":"poularde","word_nosc":"poularde","lemma":"poulard","pos":"NOM"} ,
		{"word":"poulardes","word_nosc":"poulardes","lemma":"poulard","pos":"NOM"} ,
		{"word":"poulardin","word_nosc":"poulardin","lemma":"poulardin","pos":"NOM"} ,
		{"word":"poulardins","word_nosc":"poulardins","lemma":"poulardin","pos":"NOM"} ,
		{"word":"poulbot","word_nosc":"poulbot","lemma":"poulbot","pos":"NOM"} ,
		{"word":"poulbots","word_nosc":"poulbots","lemma":"poulbot","pos":"NOM"} ,
		{"word":"poule","word_nosc":"poule","lemma":"poule","pos":"NOM"} ,
		{"word":"poules","word_nosc":"poules","lemma":"poule","pos":"NOM"} ,
		{"word":"poulet","word_nosc":"poulet","lemma":"poulet","pos":"NOM"} ,
		{"word":"poulets","word_nosc":"poulets","lemma":"poulet","pos":"NOM"} ,
		{"word":"poulette","word_nosc":"poulette","lemma":"poulette","pos":"NOM"} ,
		{"word":"poulettes","word_nosc":"poulettes","lemma":"poulette","pos":"NOM"} ,
		{"word":"pouliche","word_nosc":"pouliche","lemma":"pouliche","pos":"NOM"} ,
		{"word":"pouliches","word_nosc":"pouliches","lemma":"pouliche","pos":"NOM"} ,
		{"word":"poulie","word_nosc":"poulie","lemma":"poulie","pos":"NOM"} ,
		{"word":"poulies","word_nosc":"poulies","lemma":"poulie","pos":"NOM"} ,
		{"word":"poulinière","word_nosc":"pouliniere","lemma":"poulinière","pos":"NOM"} ,
		{"word":"poulot","word_nosc":"poulot","lemma":"poulot","pos":"NOM"} ,
		{"word":"poulots","word_nosc":"poulots","lemma":"poulot","pos":"NOM"} ,
		{"word":"poulpe","word_nosc":"poulpe","lemma":"poulpe","pos":"NOM"} ,
		{"word":"poulpes","word_nosc":"poulpes","lemma":"poulpe","pos":"NOM"} ,
		{"word":"pouls","word_nosc":"pouls","lemma":"pouls","pos":"NOM"} ,
		{"word":"poumon","word_nosc":"poumon","lemma":"poumon","pos":"NOM"} ,
		{"word":"poumons","word_nosc":"poumons","lemma":"poumon","pos":"NOM"} ,
		{"word":"pound","word_nosc":"pound","lemma":"pound","pos":"NOM"} ,
		{"word":"pounds","word_nosc":"pounds","lemma":"pound","pos":"NOM"} ,
		{"word":"poupard","word_nosc":"poupard","lemma":"poupard","pos":"NOM"} ,
		{"word":"poupards","word_nosc":"poupards","lemma":"poupard","pos":"NOM"} ,
		{"word":"poupe","word_nosc":"poupe","lemma":"poupe","pos":"NOM"} ,
		{"word":"poupon","word_nosc":"poupon","lemma":"poupon","pos":"NOM"} ,
		{"word":"pouponnage","word_nosc":"pouponnage","lemma":"pouponnage","pos":"NOM"} ,
		{"word":"pouponnière","word_nosc":"pouponniere","lemma":"pouponnière","pos":"NOM"} ,
		{"word":"pouponnières","word_nosc":"pouponnieres","lemma":"pouponnière","pos":"NOM"} ,
		{"word":"poupons","word_nosc":"poupons","lemma":"poupon","pos":"NOM"} ,
		{"word":"poupoule","word_nosc":"poupoule","lemma":"poupoule","pos":"NOM"} ,
		{"word":"poupée","word_nosc":"poupee","lemma":"poupée","pos":"NOM"} ,
		{"word":"poupées","word_nosc":"poupees","lemma":"poupée","pos":"NOM"} ,
		{"word":"pour","word_nosc":"pour","lemma":"pour","pos":"NOM"} ,
		{"word":"pour-cent","word_nosc":"pour-cent","lemma":"pour-cent","pos":"NOM"} ,
		{"word":"pourboire","word_nosc":"pourboire","lemma":"pourboire","pos":"NOM"} ,
		{"word":"pourboires","word_nosc":"pourboires","lemma":"pourboire","pos":"NOM"} ,
		{"word":"pourceau","word_nosc":"pourceau","lemma":"pourceau","pos":"NOM"} ,
		{"word":"pourceaux","word_nosc":"pourceaux","lemma":"pourceau","pos":"NOM"} ,
		{"word":"pourcent","word_nosc":"pourcent","lemma":"pourcent","pos":"NOM"} ,
		{"word":"pourcentage","word_nosc":"pourcentage","lemma":"pourcentage","pos":"NOM"} ,
		{"word":"pourcentages","word_nosc":"pourcentages","lemma":"pourcentage","pos":"NOM"} ,
		{"word":"pourcents","word_nosc":"pourcents","lemma":"pourcent","pos":"NOM"} ,
		{"word":"pourchas","word_nosc":"pourchas","lemma":"pourchas","pos":"NOM"} ,
		{"word":"pourchasseur","word_nosc":"pourchasseur","lemma":"pourchasseur","pos":"NOM"} ,
		{"word":"pourcif","word_nosc":"pourcif","lemma":"pourcif","pos":"NOM"} ,
		{"word":"pourfendeur","word_nosc":"pourfendeur","lemma":"pourfendeur","pos":"NOM"} ,
		{"word":"pourim","word_nosc":"pourim","lemma":"pourim","pos":"NOM"} ,
		{"word":"pourliche","word_nosc":"pourliche","lemma":"pourliche","pos":"NOM"} ,
		{"word":"pourliches","word_nosc":"pourliches","lemma":"pourliche","pos":"NOM"} ,
		{"word":"pourparler","word_nosc":"pourparler","lemma":"pourparler","pos":"NOM"} ,
		{"word":"pourparlers","word_nosc":"pourparlers","lemma":"pourparlers","pos":"NOM"} ,
		{"word":"pourpier","word_nosc":"pourpier","lemma":"pourpier","pos":"NOM"} ,
		{"word":"pourpiers","word_nosc":"pourpiers","lemma":"pourpier","pos":"NOM"} ,
		{"word":"pourpoint","word_nosc":"pourpoint","lemma":"pourpoint","pos":"NOM"} ,
		{"word":"pourpoints","word_nosc":"pourpoints","lemma":"pourpoint","pos":"NOM"} ,
		{"word":"pourpre","word_nosc":"pourpre","lemma":"pourpre","pos":"NOM"} ,
		{"word":"pourpres","word_nosc":"pourpres","lemma":"pourpre","pos":"NOM"} ,
		{"word":"pourquoi","word_nosc":"pourquoi","lemma":"pourquoi","pos":"NOM"} ,
		{"word":"pourri","word_nosc":"pourri","lemma":"pourri","pos":"NOM"} ,
		{"word":"pourris","word_nosc":"pourris","lemma":"pourri","pos":"NOM"} ,
		{"word":"pourrissement","word_nosc":"pourrissement","lemma":"pourrissement","pos":"NOM"} ,
		{"word":"pourrissoir","word_nosc":"pourrissoir","lemma":"pourrissoir","pos":"NOM"} ,
		{"word":"pourriture","word_nosc":"pourriture","lemma":"pourriture","pos":"NOM"} ,
		{"word":"pourritures","word_nosc":"pourritures","lemma":"pourriture","pos":"NOM"} ,
		{"word":"poursuite","word_nosc":"poursuite","lemma":"poursuite","pos":"NOM"} ,
		{"word":"poursuites","word_nosc":"poursuites","lemma":"poursuite","pos":"NOM"} ,
		{"word":"poursuivant","word_nosc":"poursuivant","lemma":"poursuivant","pos":"NOM"} ,
		{"word":"poursuivante","word_nosc":"poursuivante","lemma":"poursuivant","pos":"NOM"} ,
		{"word":"poursuivants","word_nosc":"poursuivants","lemma":"poursuivant","pos":"NOM"} ,
		{"word":"poursuiveurs","word_nosc":"poursuiveurs","lemma":"poursuiveur","pos":"NOM"} ,
		{"word":"pourtour","word_nosc":"pourtour","lemma":"pourtour","pos":"NOM"} ,
		{"word":"pourtours","word_nosc":"pourtours","lemma":"pourtour","pos":"NOM"} ,
		{"word":"pourvoi","word_nosc":"pourvoi","lemma":"pourvoi","pos":"NOM"} ,
		{"word":"pourvois","word_nosc":"pourvois","lemma":"pourvoi","pos":"NOM"} ,
		{"word":"pourvoyeur","word_nosc":"pourvoyeur","lemma":"pourvoyeur","pos":"NOM"} ,
		{"word":"pourvoyeurs","word_nosc":"pourvoyeurs","lemma":"pourvoyeur","pos":"NOM"} ,
		{"word":"pourvoyeuse","word_nosc":"pourvoyeuse","lemma":"pourvoyeur","pos":"NOM"} ,
		{"word":"poussah","word_nosc":"poussah","lemma":"poussah","pos":"NOM"} ,
		{"word":"pousse","word_nosc":"pousse","lemma":"pousse","pos":"NOM"} ,
		{"word":"pousse-au-crime","word_nosc":"pousse-au-crime","lemma":"pousse-au-crime","pos":"NOM"} ,
		{"word":"pousse-café","word_nosc":"pousse-cafe","lemma":"pousse-café","pos":"NOM"} ,
		{"word":"pousse-cailloux","word_nosc":"pousse-cailloux","lemma":"pousse-cailloux","pos":"NOM"} ,
		{"word":"pousse-pousse","word_nosc":"pousse-pousse","lemma":"pousse-pousse","pos":"NOM"} ,
		{"word":"pousses","word_nosc":"pousses","lemma":"pousse","pos":"NOM"} ,
		{"word":"poussette","word_nosc":"poussette","lemma":"poussette","pos":"NOM"} ,
		{"word":"poussettes","word_nosc":"poussettes","lemma":"poussette","pos":"NOM"} ,
		{"word":"pousseur","word_nosc":"pousseur","lemma":"pousseur","pos":"NOM"} ,
		{"word":"pousseurs","word_nosc":"pousseurs","lemma":"pousseur","pos":"NOM"} ,
		{"word":"poussier","word_nosc":"poussier","lemma":"poussier","pos":"NOM"} ,
		{"word":"poussin","word_nosc":"poussin","lemma":"poussin","pos":"NOM"} ,
		{"word":"poussine","word_nosc":"poussine","lemma":"poussine","pos":"NOM"} ,
		{"word":"poussinière","word_nosc":"poussiniere","lemma":"poussinière","pos":"NOM"} ,
		{"word":"poussins","word_nosc":"poussins","lemma":"poussin","pos":"NOM"} ,
		{"word":"poussière","word_nosc":"poussiere","lemma":"poussière","pos":"NOM"} ,
		{"word":"poussières","word_nosc":"poussieres","lemma":"poussière","pos":"NOM"} ,
		{"word":"poussoir","word_nosc":"poussoir","lemma":"poussoir","pos":"NOM"} ,
		{"word":"poussée","word_nosc":"poussee","lemma":"poussée","pos":"NOM"} ,
		{"word":"poussées","word_nosc":"poussees","lemma":"poussée","pos":"NOM"} ,
		{"word":"poutargue","word_nosc":"poutargue","lemma":"poutargue","pos":"NOM"} ,
		{"word":"poutou","word_nosc":"poutou","lemma":"poutou","pos":"NOM"} ,
		{"word":"poutrages","word_nosc":"poutrages","lemma":"poutrage","pos":"NOM"} ,
		{"word":"poutraison","word_nosc":"poutraison","lemma":"poutraison","pos":"NOM"} ,
		{"word":"poutre","word_nosc":"poutre","lemma":"poutre","pos":"NOM"} ,
		{"word":"poutrelle","word_nosc":"poutrelle","lemma":"poutrelle","pos":"NOM"} ,
		{"word":"poutrelles","word_nosc":"poutrelles","lemma":"poutrelle","pos":"NOM"} ,
		{"word":"poutres","word_nosc":"poutres","lemma":"poutre","pos":"NOM"} ,
		{"word":"pouvoir","word_nosc":"pouvoir","lemma":"pouvoir","pos":"NOM"} ,
		{"word":"pouvoirs","word_nosc":"pouvoirs","lemma":"pouvoir","pos":"NOM"} ,
		{"word":"poux","word_nosc":"poux","lemma":"pou","pos":"NOM"} ,
		{"word":"pouzzolane","word_nosc":"pouzzolane","lemma":"pouzzolane","pos":"NOM"} ,
		{"word":"poème","word_nosc":"poeme","lemma":"poème","pos":"NOM"} ,
		{"word":"poèmes","word_nosc":"poemes","lemma":"poème","pos":"NOM"} ,
		{"word":"poète","word_nosc":"poete","lemma":"poète","pos":"NOM"} ,
		{"word":"poètes","word_nosc":"poetes","lemma":"poète","pos":"NOM"} ,
		{"word":"poésie","word_nosc":"poesie","lemma":"poésie","pos":"NOM"} ,
		{"word":"poésies","word_nosc":"poesies","lemma":"poésie","pos":"NOM"} ,
		{"word":"poétesse","word_nosc":"poetesse","lemma":"poète","pos":"NOM"} ,
		{"word":"poétesses","word_nosc":"poetesses","lemma":"poète","pos":"NOM"} ,
		{"word":"poéticien","word_nosc":"poeticien","lemma":"poéticien","pos":"NOM"} ,
		{"word":"poétique","word_nosc":"poetique","lemma":"poétique","pos":"NOM"} ,
		{"word":"poétiques","word_nosc":"poetiques","lemma":"poétique","pos":"NOM"} ,
		{"word":"poêle","word_nosc":"poele","lemma":"poêle","pos":"NOM"} ,
		{"word":"poêles","word_nosc":"poeles","lemma":"poêle","pos":"NOM"} ,
		{"word":"poêlon","word_nosc":"poelon","lemma":"poêlon","pos":"NOM"} ,
		{"word":"practice","word_nosc":"practice","lemma":"practice","pos":"NOM"} ,
		{"word":"pradelle","word_nosc":"pradelle","lemma":"pradel","pos":"NOM"} ,
		{"word":"pradelle","word_nosc":"pradelle","lemma":"pradelle","pos":"NOM"} ,
		{"word":"praesidium","word_nosc":"praesidium","lemma":"praesidium","pos":"NOM"} ,
		{"word":"pragmatisme","word_nosc":"pragmatisme","lemma":"pragmatisme","pos":"NOM"} ,
		{"word":"pragmatiste","word_nosc":"pragmatiste","lemma":"pragmatiste","pos":"NOM"} ,
		{"word":"praire","word_nosc":"praire","lemma":"praire","pos":"NOM"} ,
		{"word":"praires","word_nosc":"praires","lemma":"praire","pos":"NOM"} ,
		{"word":"prairial","word_nosc":"prairial","lemma":"prairial","pos":"NOM"} ,
		{"word":"prairie","word_nosc":"prairie","lemma":"prairie","pos":"NOM"} ,
		{"word":"prairies","word_nosc":"prairies","lemma":"prairie","pos":"NOM"} ,
		{"word":"praline","word_nosc":"praline","lemma":"praline","pos":"NOM"} ,
		{"word":"pralines","word_nosc":"pralines","lemma":"praline","pos":"NOM"} ,
		{"word":"praliné","word_nosc":"praline","lemma":"praliné","pos":"NOM"} ,
		{"word":"prang","word_nosc":"prang","lemma":"prang","pos":"NOM"} ,
		{"word":"praticable","word_nosc":"praticable","lemma":"praticable","pos":"NOM"} ,
		{"word":"praticables","word_nosc":"praticables","lemma":"praticable","pos":"NOM"} ,
		{"word":"praticien","word_nosc":"praticien","lemma":"praticien","pos":"NOM"} ,
		{"word":"praticienne","word_nosc":"praticienne","lemma":"praticien","pos":"NOM"} ,
		{"word":"praticiens","word_nosc":"praticiens","lemma":"praticien","pos":"NOM"} ,
		{"word":"pratiquant","word_nosc":"pratiquant","lemma":"pratiquant","pos":"NOM"} ,
		{"word":"pratiquante","word_nosc":"pratiquante","lemma":"pratiquant","pos":"NOM"} ,
		{"word":"pratiquants","word_nosc":"pratiquants","lemma":"pratiquant","pos":"NOM"} ,
		{"word":"pratique","word_nosc":"pratique","lemma":"pratique","pos":"NOM"} ,
		{"word":"pratiques","word_nosc":"pratiques","lemma":"pratique","pos":"NOM"} ,
		{"word":"praxie","word_nosc":"praxie","lemma":"praxie","pos":"NOM"} ,
		{"word":"premier","word_nosc":"premier","lemma":"premier","pos":"NOM"} ,
		{"word":"premier-maître","word_nosc":"premier-maitre","lemma":"premier-maître","pos":"NOM"} ,
		{"word":"premier-né","word_nosc":"premier-ne","lemma":"premier-né","pos":"NOM"} ,
		{"word":"premiers","word_nosc":"premiers","lemma":"premier","pos":"NOM"} ,
		{"word":"premiers-nés","word_nosc":"premiers-nes","lemma":"premier-né","pos":"NOM"} ,
		{"word":"premium","word_nosc":"premium","lemma":"premium","pos":"NOM"} ,
		{"word":"première","word_nosc":"premiere","lemma":"premier","pos":"NOM"} ,
		{"word":"première-née","word_nosc":"premiere-nee","lemma":"première-née","pos":"NOM"} ,
		{"word":"premières","word_nosc":"premieres","lemma":"premier","pos":"NOM"} ,
		{"word":"preneur","word_nosc":"preneur","lemma":"preneur","pos":"NOM"} ,
		{"word":"preneurs","word_nosc":"preneurs","lemma":"preneur","pos":"NOM"} ,
		{"word":"preneuse","word_nosc":"preneuse","lemma":"preneur","pos":"NOM"} ,
		{"word":"presbyte","word_nosc":"presbyte","lemma":"presbyte","pos":"NOM"} ,
		{"word":"presbyterium","word_nosc":"presbyterium","lemma":"presbyterium","pos":"NOM"} ,
		{"word":"presbytie","word_nosc":"presbytie","lemma":"presbytie","pos":"NOM"} ,
		{"word":"presbytère","word_nosc":"presbytere","lemma":"presbytère","pos":"NOM"} ,
		{"word":"presbytérien","word_nosc":"presbyterien","lemma":"presbytérien","pos":"NOM"} ,
		{"word":"presbytérienne","word_nosc":"presbyterienne","lemma":"presbytérien","pos":"NOM"} ,
		{"word":"prescience","word_nosc":"prescience","lemma":"prescience","pos":"NOM"} ,
		{"word":"prescription","word_nosc":"prescription","lemma":"prescription","pos":"NOM"} ,
		{"word":"prescriptions","word_nosc":"prescriptions","lemma":"prescription","pos":"NOM"} ,
		{"word":"presqu'île","word_nosc":"presqu'ile","lemma":"presqu'île","pos":"NOM"} ,
		{"word":"presqu'îles","word_nosc":"presqu'iles","lemma":"presqu'île","pos":"NOM"} ,
		{"word":"press-book","word_nosc":"press-book","lemma":"press-book","pos":"NOM"} ,
		{"word":"pressage","word_nosc":"pressage","lemma":"pressage","pos":"NOM"} ,
		{"word":"presse","word_nosc":"presse","lemma":"presse","pos":"NOM"} ,
		{"word":"presse-agrumes","word_nosc":"presse-agrumes","lemma":"presse-agrumes","pos":"NOM"} ,
		{"word":"presse-citron","word_nosc":"presse-citron","lemma":"presse-citron","pos":"NOM"} ,
		{"word":"presse-fruits","word_nosc":"presse-fruits","lemma":"presse-fruits","pos":"NOM"} ,
		{"word":"presse-papier","word_nosc":"presse-papier","lemma":"presse-papier","pos":"NOM"} ,
		{"word":"presse-papiers","word_nosc":"presse-papiers","lemma":"presse-papiers","pos":"NOM"} ,
		{"word":"presse-purée","word_nosc":"presse-puree","lemma":"presse-purée","pos":"NOM"} ,
		{"word":"presse-raquette","word_nosc":"presse-raquette","lemma":"presse-raquette","pos":"NOM"} ,
		{"word":"pressement","word_nosc":"pressement","lemma":"pressement","pos":"NOM"} ,
		{"word":"pressements","word_nosc":"pressements","lemma":"pressement","pos":"NOM"} ,
		{"word":"pressentiment","word_nosc":"pressentiment","lemma":"pressentiment","pos":"NOM"} ,
		{"word":"pressentiments","word_nosc":"pressentiments","lemma":"pressentiment","pos":"NOM"} ,
		{"word":"presses","word_nosc":"presses","lemma":"presse","pos":"NOM"} ,
		{"word":"pressing","word_nosc":"pressing","lemma":"pressing","pos":"NOM"} ,
		{"word":"pressings","word_nosc":"pressings","lemma":"pressing","pos":"NOM"} ,
		{"word":"pression","word_nosc":"pression","lemma":"pression","pos":"NOM"} ,
		{"word":"pressions","word_nosc":"pressions","lemma":"pression","pos":"NOM"} ,
		{"word":"pressoir","word_nosc":"pressoir","lemma":"pressoir","pos":"NOM"} ,
		{"word":"pressoirs","word_nosc":"pressoirs","lemma":"pressoir","pos":"NOM"} ,
		{"word":"pressurisation","word_nosc":"pressurisation","lemma":"pressurisation","pos":"NOM"} ,
		{"word":"pressé","word_nosc":"presse","lemma":"pressé","pos":"NOM"} ,
		{"word":"pressée","word_nosc":"pressee","lemma":"pressé","pos":"NOM"} ,
		{"word":"pressées","word_nosc":"pressees","lemma":"pressé","pos":"NOM"} ,
		{"word":"pressés","word_nosc":"presses","lemma":"pressé","pos":"NOM"} ,
		{"word":"prestance","word_nosc":"prestance","lemma":"prestance","pos":"NOM"} ,
		{"word":"prestataire","word_nosc":"prestataire","lemma":"prestataire","pos":"NOM"} ,
		{"word":"prestataires","word_nosc":"prestataires","lemma":"prestataire","pos":"NOM"} ,
		{"word":"prestation","word_nosc":"prestation","lemma":"prestation","pos":"NOM"} ,
		{"word":"prestations","word_nosc":"prestations","lemma":"prestation","pos":"NOM"} ,
		{"word":"prestesse","word_nosc":"prestesse","lemma":"prestesse","pos":"NOM"} ,
		{"word":"prestidigitateur","word_nosc":"prestidigitateur","lemma":"prestidigitateur","pos":"NOM"} ,
		{"word":"prestidigitateurs","word_nosc":"prestidigitateurs","lemma":"prestidigitateur","pos":"NOM"} ,
		{"word":"prestidigitation","word_nosc":"prestidigitation","lemma":"prestidigitation","pos":"NOM"} ,
		{"word":"prestidigitations","word_nosc":"prestidigitations","lemma":"prestidigitation","pos":"NOM"} ,
		{"word":"prestidigitatrice","word_nosc":"prestidigitatrice","lemma":"prestidigitateur","pos":"NOM"} ,
		{"word":"prestige","word_nosc":"prestige","lemma":"prestige","pos":"NOM"} ,
		{"word":"prestiges","word_nosc":"prestiges","lemma":"prestige","pos":"NOM"} ,
		{"word":"preuve","word_nosc":"preuve","lemma":"preuve","pos":"NOM"} ,
		{"word":"preuves","word_nosc":"preuves","lemma":"preuve","pos":"NOM"} ,
		{"word":"preux","word_nosc":"preux","lemma":"preux","pos":"NOM"} ,
		{"word":"priant","word_nosc":"priant","lemma":"priant","pos":"NOM"} ,
		{"word":"priapisme","word_nosc":"priapisme","lemma":"priapisme","pos":"NOM"} ,
		{"word":"prie-dieu","word_nosc":"prie-dieu","lemma":"prie-dieu","pos":"NOM"} ,
		{"word":"prieur","word_nosc":"prieur","lemma":"prieur","pos":"NOM"} ,
		{"word":"prieure","word_nosc":"prieure","lemma":"prieur","pos":"NOM"} ,
		{"word":"prieurs","word_nosc":"prieurs","lemma":"prieur","pos":"NOM"} ,
		{"word":"prieuré","word_nosc":"prieure","lemma":"prieuré","pos":"NOM"} ,
		{"word":"prieurés","word_nosc":"prieures","lemma":"prieuré","pos":"NOM"} ,
		{"word":"primaire","word_nosc":"primaire","lemma":"primaire","pos":"NOM"} ,
		{"word":"primaires","word_nosc":"primaires","lemma":"primaire","pos":"NOM"} ,
		{"word":"primat","word_nosc":"primat","lemma":"primat","pos":"NOM"} ,
		{"word":"primate","word_nosc":"primate","lemma":"primate","pos":"NOM"} ,
		{"word":"primates","word_nosc":"primates","lemma":"primate","pos":"NOM"} ,
		{"word":"primatologue","word_nosc":"primatologue","lemma":"primatologue","pos":"NOM"} ,
		{"word":"primauté","word_nosc":"primaute","lemma":"primauté","pos":"NOM"} ,
		{"word":"prime","word_nosc":"prime","lemma":"prime","pos":"NOM"} ,
		{"word":"prime time","word_nosc":"prime time","lemma":"prime time","pos":"NOM"} ,
		{"word":"prime-saut","word_nosc":"prime-saut","lemma":"prime-saut","pos":"NOM"} ,
		{"word":"primerose","word_nosc":"primerose","lemma":"primerose","pos":"NOM"} ,
		{"word":"primes","word_nosc":"primes","lemma":"prime","pos":"NOM"} ,
		{"word":"primeur","word_nosc":"primeur","lemma":"primeur","pos":"NOM"} ,
		{"word":"primeurs","word_nosc":"primeurs","lemma":"primeur","pos":"NOM"} ,
		{"word":"primevère","word_nosc":"primevere","lemma":"primevère","pos":"NOM"} ,
		{"word":"primevères","word_nosc":"primeveres","lemma":"primevère","pos":"NOM"} ,
		{"word":"primidi","word_nosc":"primidi","lemma":"primidi","pos":"NOM"} ,
		{"word":"primitif","word_nosc":"primitif","lemma":"primitif","pos":"NOM"} ,
		{"word":"primitifs","word_nosc":"primitifs","lemma":"primitif","pos":"NOM"} ,
		{"word":"primitive","word_nosc":"primitive","lemma":"primitif","pos":"NOM"} ,
		{"word":"primitives","word_nosc":"primitives","lemma":"primitif","pos":"NOM"} ,
		{"word":"primitivisme","word_nosc":"primitivisme","lemma":"primitivisme","pos":"NOM"} ,
		{"word":"primo-infection","word_nosc":"primo-infection","lemma":"primo-infection","pos":"NOM"} ,
		{"word":"prin","word_nosc":"prin","lemma":"prin","pos":"NOM"} ,
		{"word":"prince","word_nosc":"prince","lemma":"prince","pos":"NOM"} ,
		{"word":"prince-de-galles","word_nosc":"prince-de-galles","lemma":"prince-de-galles","pos":"NOM"} ,
		{"word":"prince-évêque","word_nosc":"prince-eveque","lemma":"prince-évêque","pos":"NOM"} ,
		{"word":"princes","word_nosc":"princes","lemma":"prince","pos":"NOM"} ,
		{"word":"princesse","word_nosc":"princesse","lemma":"prince","pos":"NOM"} ,
		{"word":"princesses","word_nosc":"princesses","lemma":"prince","pos":"NOM"} ,
		{"word":"principal","word_nosc":"principal","lemma":"principal","pos":"NOM"} ,
		{"word":"principale","word_nosc":"principale","lemma":"principal","pos":"NOM"} ,
		{"word":"principales","word_nosc":"principales","lemma":"principal","pos":"NOM"} ,
		{"word":"principat","word_nosc":"principat","lemma":"principat","pos":"NOM"} ,
		{"word":"principauté","word_nosc":"principaute","lemma":"principauté","pos":"NOM"} ,
		{"word":"principautés","word_nosc":"principautes","lemma":"principauté","pos":"NOM"} ,
		{"word":"principaux","word_nosc":"principaux","lemma":"principal","pos":"NOM"} ,
		{"word":"principe","word_nosc":"principe","lemma":"principe","pos":"NOM"} ,
		{"word":"principes","word_nosc":"principes","lemma":"principe","pos":"NOM"} ,
		{"word":"printemps","word_nosc":"printemps","lemma":"printemps","pos":"NOM"} ,
		{"word":"prion","word_nosc":"prion","lemma":"prion","pos":"NOM"} ,
		{"word":"prions","word_nosc":"prions","lemma":"prion","pos":"NOM"} ,
		{"word":"priorité","word_nosc":"priorite","lemma":"priorité","pos":"NOM"} ,
		{"word":"priorités","word_nosc":"priorites","lemma":"priorité","pos":"NOM"} ,
		{"word":"prise","word_nosc":"prise","lemma":"prise","pos":"NOM"} ,
		{"word":"prises","word_nosc":"prises","lemma":"prise","pos":"NOM"} ,
		{"word":"priseur","word_nosc":"priseur","lemma":"priseur","pos":"NOM"} ,
		{"word":"priseurs","word_nosc":"priseurs","lemma":"priseur","pos":"NOM"} ,
		{"word":"prisme","word_nosc":"prisme","lemma":"prisme","pos":"NOM"} ,
		{"word":"prismes","word_nosc":"prismes","lemma":"prisme","pos":"NOM"} ,
		{"word":"prison","word_nosc":"prison","lemma":"prison","pos":"NOM"} ,
		{"word":"prisonnier","word_nosc":"prisonnier","lemma":"prisonnier","pos":"NOM"} ,
		{"word":"prisonniers","word_nosc":"prisonniers","lemma":"prisonnier","pos":"NOM"} ,
		{"word":"prisonnière","word_nosc":"prisonniere","lemma":"prisonnier","pos":"NOM"} ,
		{"word":"prisonnières","word_nosc":"prisonnieres","lemma":"prisonnier","pos":"NOM"} ,
		{"word":"prisons","word_nosc":"prisons","lemma":"prison","pos":"NOM"} ,
		{"word":"prisons-écoles","word_nosc":"prisons-ecoles","lemma":"prisons-école","pos":"NOM"} ,
		{"word":"prisunic","word_nosc":"prisunic","lemma":"prisunic","pos":"NOM"} ,
		{"word":"prisées","word_nosc":"prisees","lemma":"prisée","pos":"NOM"} ,
		{"word":"privation","word_nosc":"privation","lemma":"privation","pos":"NOM"} ,
		{"word":"privations","word_nosc":"privations","lemma":"privation","pos":"NOM"} ,
		{"word":"privatisation","word_nosc":"privatisation","lemma":"privatisation","pos":"NOM"} ,
		{"word":"privauté","word_nosc":"privaute","lemma":"privauté","pos":"NOM"} ,
		{"word":"privautés","word_nosc":"privautes","lemma":"privauté","pos":"NOM"} ,
		{"word":"privilège","word_nosc":"privilege","lemma":"privilège","pos":"NOM"} ,
		{"word":"privilèges","word_nosc":"privileges","lemma":"privilège","pos":"NOM"} ,
		{"word":"privilégié","word_nosc":"privilegie","lemma":"privilégié","pos":"NOM"} ,
		{"word":"privilégiée","word_nosc":"privilegiee","lemma":"privilégié","pos":"NOM"} ,
		{"word":"privilégiées","word_nosc":"privilegiees","lemma":"privilégié","pos":"NOM"} ,
		{"word":"privilégiés","word_nosc":"privilegies","lemma":"privilégié","pos":"NOM"} ,
		{"word":"privé","word_nosc":"prive","lemma":"privé","pos":"NOM"} ,
		{"word":"privés","word_nosc":"prives","lemma":"privé","pos":"NOM"} ,
		{"word":"prix","word_nosc":"prix","lemma":"prix","pos":"NOM"} ,
		{"word":"prix-choc","word_nosc":"prix-choc","lemma":"prix-choc","pos":"NOM"} ,
		{"word":"prière","word_nosc":"priere","lemma":"prière","pos":"NOM"} ,
		{"word":"prières","word_nosc":"prieres","lemma":"prière","pos":"NOM"} ,
		{"word":"pro","word_nosc":"pro","lemma":"pro","pos":"NOM"} ,
		{"word":"probabilité","word_nosc":"probabilite","lemma":"probabilité","pos":"NOM"} ,
		{"word":"probabilités","word_nosc":"probabilites","lemma":"probabilité","pos":"NOM"} ,
		{"word":"probation","word_nosc":"probation","lemma":"probation","pos":"NOM"} ,
		{"word":"probité","word_nosc":"probite","lemma":"probité","pos":"NOM"} ,
		{"word":"probloc","word_nosc":"probloc","lemma":"probloc","pos":"NOM"} ,
		{"word":"problocs","word_nosc":"problocs","lemma":"probloc","pos":"NOM"} ,
		{"word":"probloque","word_nosc":"probloque","lemma":"probloque","pos":"NOM"} ,
		{"word":"probloques","word_nosc":"probloques","lemma":"probloque","pos":"NOM"} ,
		{"word":"problème","word_nosc":"probleme","lemma":"problème","pos":"NOM"} ,
		{"word":"problèmes","word_nosc":"problemes","lemma":"problème","pos":"NOM"} ,
		{"word":"problématique","word_nosc":"problematique","lemma":"problématique","pos":"NOM"} ,
		{"word":"problématiques","word_nosc":"problematiques","lemma":"problématique","pos":"NOM"} ,
		{"word":"proc","word_nosc":"proc","lemma":"proc","pos":"NOM"} ,
		{"word":"procalmadiol","word_nosc":"procalmadiol","lemma":"procalmadiol","pos":"NOM"} ,
		{"word":"procaïne","word_nosc":"procaine","lemma":"procaïne","pos":"NOM"} ,
		{"word":"process","word_nosc":"process","lemma":"process","pos":"NOM"} ,
		{"word":"processeur","word_nosc":"processeur","lemma":"processeur","pos":"NOM"} ,
		{"word":"processeurs","word_nosc":"processeurs","lemma":"processeur","pos":"NOM"} ,
		{"word":"procession","word_nosc":"procession","lemma":"procession","pos":"NOM"} ,
		{"word":"processionnaires","word_nosc":"processionnaires","lemma":"processionnaire","pos":"NOM"} ,
		{"word":"processions","word_nosc":"processions","lemma":"procession","pos":"NOM"} ,
		{"word":"processus","word_nosc":"processus","lemma":"processus","pos":"NOM"} ,
		{"word":"prochain","word_nosc":"prochain","lemma":"prochain","pos":"NOM"} ,
		{"word":"prochaine","word_nosc":"prochaine","lemma":"prochain","pos":"NOM"} ,
		{"word":"prochaines","word_nosc":"prochaines","lemma":"prochain","pos":"NOM"} ,
		{"word":"prochains","word_nosc":"prochains","lemma":"prochain","pos":"NOM"} ,
		{"word":"proche","word_nosc":"proche","lemma":"proche","pos":"NOM"} ,
		{"word":"proches","word_nosc":"proches","lemma":"proche","pos":"NOM"} ,
		{"word":"proclamation","word_nosc":"proclamation","lemma":"proclamation","pos":"NOM"} ,
		{"word":"proclamations","word_nosc":"proclamations","lemma":"proclamation","pos":"NOM"} ,
		{"word":"proclamatrices","word_nosc":"proclamatrices","lemma":"proclamateur","pos":"NOM"} ,
		{"word":"proconsul","word_nosc":"proconsul","lemma":"proconsul","pos":"NOM"} ,
		{"word":"proconsuls","word_nosc":"proconsuls","lemma":"proconsul","pos":"NOM"} ,
		{"word":"procrastination","word_nosc":"procrastination","lemma":"procrastination","pos":"NOM"} ,
		{"word":"procréateur","word_nosc":"procreateur","lemma":"procréateur","pos":"NOM"} ,
		{"word":"procréateurs","word_nosc":"procreateurs","lemma":"procréateur","pos":"NOM"} ,
		{"word":"procréation","word_nosc":"procreation","lemma":"procréation","pos":"NOM"} ,
		{"word":"procréations","word_nosc":"procreations","lemma":"procréation","pos":"NOM"} ,
		{"word":"procs","word_nosc":"procs","lemma":"proc","pos":"NOM"} ,
		{"word":"proctologie","word_nosc":"proctologie","lemma":"proctologie","pos":"NOM"} ,
		{"word":"proctologue","word_nosc":"proctologue","lemma":"proctologue","pos":"NOM"} ,
		{"word":"proctologues","word_nosc":"proctologues","lemma":"proctologue","pos":"NOM"} ,
		{"word":"procurateur","word_nosc":"procurateur","lemma":"procurateur","pos":"NOM"} ,
		{"word":"procurateurs","word_nosc":"procurateurs","lemma":"procurateur","pos":"NOM"} ,
		{"word":"procuraties","word_nosc":"procuraties","lemma":"procuratie","pos":"NOM"} ,
		{"word":"procuration","word_nosc":"procuration","lemma":"procuration","pos":"NOM"} ,
		{"word":"procuratrice","word_nosc":"procuratrice","lemma":"procurateur","pos":"NOM"} ,
		{"word":"procure","word_nosc":"procure","lemma":"procure","pos":"NOM"} ,
		{"word":"procures","word_nosc":"procures","lemma":"procure","pos":"NOM"} ,
		{"word":"procureur","word_nosc":"procureur","lemma":"procureur","pos":"NOM"} ,
		{"word":"procureurs","word_nosc":"procureurs","lemma":"procureur","pos":"NOM"} ,
		{"word":"procureuse","word_nosc":"procureuse","lemma":"procureur","pos":"NOM"} ,
		{"word":"procureuses","word_nosc":"procureuses","lemma":"procureur","pos":"NOM"} ,
		{"word":"procès","word_nosc":"proces","lemma":"procès","pos":"NOM"} ,
		{"word":"procès-test","word_nosc":"proces-test","lemma":"procès-test","pos":"NOM"} ,
		{"word":"procès-verbal","word_nosc":"proces-verbal","lemma":"procès-verbal","pos":"NOM"} ,
		{"word":"procès-verbaux","word_nosc":"proces-verbaux","lemma":"procès-verbal","pos":"NOM"} ,
		{"word":"procédure","word_nosc":"procedure","lemma":"procédure","pos":"NOM"} ,
		{"word":"procédures","word_nosc":"procedures","lemma":"procédure","pos":"NOM"} ,
		{"word":"procédurier","word_nosc":"procedurier","lemma":"procédurier","pos":"NOM"} ,
		{"word":"procédé","word_nosc":"procede","lemma":"procédé","pos":"NOM"} ,
		{"word":"procédés","word_nosc":"procedes","lemma":"procédé","pos":"NOM"} ,
		{"word":"prodigalité","word_nosc":"prodigalite","lemma":"prodigalité","pos":"NOM"} ,
		{"word":"prodigalités","word_nosc":"prodigalites","lemma":"prodigalité","pos":"NOM"} ,
		{"word":"prodige","word_nosc":"prodige","lemma":"prodige","pos":"NOM"} ,
		{"word":"prodiges","word_nosc":"prodiges","lemma":"prodige","pos":"NOM"} ,
		{"word":"prodigue","word_nosc":"prodigue","lemma":"prodigue","pos":"NOM"} ,
		{"word":"prodigues","word_nosc":"prodigues","lemma":"prodigue","pos":"NOM"} ,
		{"word":"prodrome","word_nosc":"prodrome","lemma":"prodrome","pos":"NOM"} ,
		{"word":"prodromes","word_nosc":"prodromes","lemma":"prodrome","pos":"NOM"} ,
		{"word":"producteur","word_nosc":"producteur","lemma":"producteur","pos":"NOM"} ,
		{"word":"producteurs","word_nosc":"producteurs","lemma":"producteur","pos":"NOM"} ,
		{"word":"production","word_nosc":"production","lemma":"production","pos":"NOM"} ,
		{"word":"productions","word_nosc":"productions","lemma":"production","pos":"NOM"} ,
		{"word":"productivité","word_nosc":"productivite","lemma":"productivité","pos":"NOM"} ,
		{"word":"productrice","word_nosc":"productrice","lemma":"producteur","pos":"NOM"} ,
		{"word":"productrices","word_nosc":"productrices","lemma":"producteur","pos":"NOM"} ,
		{"word":"produit","word_nosc":"produit","lemma":"produit","pos":"NOM"} ,
		{"word":"produits","word_nosc":"produits","lemma":"produit","pos":"NOM"} ,
		{"word":"prof","word_nosc":"prof","lemma":"prof","pos":"NOM"} ,
		{"word":"profanateur","word_nosc":"profanateur","lemma":"profanateur","pos":"NOM"} ,
		{"word":"profanateurs","word_nosc":"profanateurs","lemma":"profanateur","pos":"NOM"} ,
		{"word":"profanation","word_nosc":"profanation","lemma":"profanation","pos":"NOM"} ,
		{"word":"profanations","word_nosc":"profanations","lemma":"profanation","pos":"NOM"} ,
		{"word":"profane","word_nosc":"profane","lemma":"profane","pos":"NOM"} ,
		{"word":"profanes","word_nosc":"profanes","lemma":"profane","pos":"NOM"} ,
		{"word":"professeur","word_nosc":"professeur","lemma":"professeur","pos":"NOM"} ,
		{"word":"professeure","word_nosc":"professeure","lemma":"professeur","pos":"NOM"} ,
		{"word":"professeurs","word_nosc":"professeurs","lemma":"professeur","pos":"NOM"} ,
		{"word":"profession","word_nosc":"profession","lemma":"profession","pos":"NOM"} ,
		{"word":"professionnalisme","word_nosc":"professionnalisme","lemma":"professionnalisme","pos":"NOM"} ,
		{"word":"professionnel","word_nosc":"professionnel","lemma":"professionnel","pos":"NOM"} ,
		{"word":"professionnelle","word_nosc":"professionnelle","lemma":"professionnel","pos":"NOM"} ,
		{"word":"professionnelles","word_nosc":"professionnelles","lemma":"professionnel","pos":"NOM"} ,
		{"word":"professionnels","word_nosc":"professionnels","lemma":"professionnel","pos":"NOM"} ,
		{"word":"professions","word_nosc":"professions","lemma":"profession","pos":"NOM"} ,
		{"word":"professorat","word_nosc":"professorat","lemma":"professorat","pos":"NOM"} ,
		{"word":"profil","word_nosc":"profil","lemma":"profil","pos":"NOM"} ,
		{"word":"profilage","word_nosc":"profilage","lemma":"profilage","pos":"NOM"} ,
		{"word":"profileur","word_nosc":"profileur","lemma":"profileur","pos":"NOM"} ,
		{"word":"profileuse","word_nosc":"profileuse","lemma":"profileur","pos":"NOM"} ,
		{"word":"profils","word_nosc":"profils","lemma":"profil","pos":"NOM"} ,
		{"word":"profilé","word_nosc":"profile","lemma":"profilé","pos":"NOM"} ,
		{"word":"profilés","word_nosc":"profiles","lemma":"profilé","pos":"NOM"} ,
		{"word":"profit","word_nosc":"profit","lemma":"profit","pos":"NOM"} ,
		{"word":"profitabilité","word_nosc":"profitabilite","lemma":"profitabilité","pos":"NOM"} ,
		{"word":"profiterole","word_nosc":"profiterole","lemma":"profiterole","pos":"NOM"} ,
		{"word":"profiteroles","word_nosc":"profiteroles","lemma":"profiterole","pos":"NOM"} ,
		{"word":"profiteur","word_nosc":"profiteur","lemma":"profiteur","pos":"NOM"} ,
		{"word":"profiteurs","word_nosc":"profiteurs","lemma":"profiteur","pos":"NOM"} ,
		{"word":"profiteuse","word_nosc":"profiteuse","lemma":"profiteur","pos":"NOM"} ,
		{"word":"profits","word_nosc":"profits","lemma":"profit","pos":"NOM"} ,
		{"word":"profond","word_nosc":"profond","lemma":"profond","pos":"NOM"} ,
		{"word":"profondeur","word_nosc":"profondeur","lemma":"profondeur","pos":"NOM"} ,
		{"word":"profondeurs","word_nosc":"profondeurs","lemma":"profondeur","pos":"NOM"} ,
		{"word":"profonds","word_nosc":"profonds","lemma":"profond","pos":"NOM"} ,
		{"word":"profs","word_nosc":"profs","lemma":"prof","pos":"NOM"} ,
		{"word":"profusion","word_nosc":"profusion","lemma":"profusion","pos":"NOM"} ,
		{"word":"profusions","word_nosc":"profusions","lemma":"profusion","pos":"NOM"} ,
		{"word":"proférations","word_nosc":"proferations","lemma":"profération","pos":"NOM"} ,
		{"word":"progestérone","word_nosc":"progesterone","lemma":"progestérone","pos":"NOM"} ,
		{"word":"prognathe","word_nosc":"prognathe","lemma":"prognathe","pos":"NOM"} ,
		{"word":"prognathisme","word_nosc":"prognathisme","lemma":"prognathisme","pos":"NOM"} ,
		{"word":"programmateur","word_nosc":"programmateur","lemma":"programmateur","pos":"NOM"} ,
		{"word":"programmateurs","word_nosc":"programmateurs","lemma":"programmateur","pos":"NOM"} ,
		{"word":"programmation","word_nosc":"programmation","lemma":"programmation","pos":"NOM"} ,
		{"word":"programmatrice","word_nosc":"programmatrice","lemma":"programmateur","pos":"NOM"} ,
		{"word":"programme","word_nosc":"programme","lemma":"programme","pos":"NOM"} ,
		{"word":"programmes","word_nosc":"programmes","lemma":"programme","pos":"NOM"} ,
		{"word":"programmeur","word_nosc":"programmeur","lemma":"programmeur","pos":"NOM"} ,
		{"word":"programmeurs","word_nosc":"programmeurs","lemma":"programmeur","pos":"NOM"} ,
		{"word":"programmeuse","word_nosc":"programmeuse","lemma":"programmeur","pos":"NOM"} ,
		{"word":"progression","word_nosc":"progression","lemma":"progression","pos":"NOM"} ,
		{"word":"progressions","word_nosc":"progressions","lemma":"progression","pos":"NOM"} ,
		{"word":"progressisme","word_nosc":"progressisme","lemma":"progressisme","pos":"NOM"} ,
		{"word":"progressiste","word_nosc":"progressiste","lemma":"progressiste","pos":"NOM"} ,
		{"word":"progressistes","word_nosc":"progressistes","lemma":"progressiste","pos":"NOM"} ,
		{"word":"progrès","word_nosc":"progres","lemma":"progrès","pos":"NOM"} ,
		{"word":"progéniteur","word_nosc":"progeniteur","lemma":"progéniteur","pos":"NOM"} ,
		{"word":"progéniture","word_nosc":"progeniture","lemma":"progéniture","pos":"NOM"} ,
		{"word":"progénitures","word_nosc":"progenitures","lemma":"progéniture","pos":"NOM"} ,
		{"word":"prohibition","word_nosc":"prohibition","lemma":"prohibition","pos":"NOM"} ,
		{"word":"proie","word_nosc":"proie","lemma":"proie","pos":"NOM"} ,
		{"word":"proies","word_nosc":"proies","lemma":"proie","pos":"NOM"} ,
		{"word":"projecteur","word_nosc":"projecteur","lemma":"projecteur","pos":"NOM"} ,
		{"word":"projecteurs","word_nosc":"projecteurs","lemma":"projecteur","pos":"NOM"} ,
		{"word":"projectile","word_nosc":"projectile","lemma":"projectile","pos":"NOM"} ,
		{"word":"projectiles","word_nosc":"projectiles","lemma":"projectile","pos":"NOM"} ,
		{"word":"projection","word_nosc":"projection","lemma":"projection","pos":"NOM"} ,
		{"word":"projectionniste","word_nosc":"projectionniste","lemma":"projectionniste","pos":"NOM"} ,
		{"word":"projectionnistes","word_nosc":"projectionnistes","lemma":"projectionniste","pos":"NOM"} ,
		{"word":"projections","word_nosc":"projections","lemma":"projection","pos":"NOM"} ,
		{"word":"projet","word_nosc":"projet","lemma":"projet","pos":"NOM"} ,
		{"word":"projets","word_nosc":"projets","lemma":"projet","pos":"NOM"} ,
		{"word":"projo","word_nosc":"projo","lemma":"projo","pos":"NOM"} ,
		{"word":"projos","word_nosc":"projos","lemma":"projo","pos":"NOM"} ,
		{"word":"prolapsus","word_nosc":"prolapsus","lemma":"prolapsus","pos":"NOM"} ,
		{"word":"prolifération","word_nosc":"proliferation","lemma":"prolifération","pos":"NOM"} ,
		{"word":"proline","word_nosc":"proline","lemma":"proline","pos":"NOM"} ,
		{"word":"prolixité","word_nosc":"prolixite","lemma":"prolixité","pos":"NOM"} ,
		{"word":"prolo","word_nosc":"prolo","lemma":"prolo","pos":"NOM"} ,
		{"word":"prologue","word_nosc":"prologue","lemma":"prologue","pos":"NOM"} ,
		{"word":"prolongateur","word_nosc":"prolongateur","lemma":"prolongateur","pos":"NOM"} ,
		{"word":"prolongation","word_nosc":"prolongation","lemma":"prolongation","pos":"NOM"} ,
		{"word":"prolongations","word_nosc":"prolongations","lemma":"prolongation","pos":"NOM"} ,
		{"word":"prolonge","word_nosc":"prolonge","lemma":"prolonge","pos":"NOM"} ,
		{"word":"prolongement","word_nosc":"prolongement","lemma":"prolongement","pos":"NOM"} ,
		{"word":"prolongements","word_nosc":"prolongements","lemma":"prolongement","pos":"NOM"} ,
		{"word":"prolonges","word_nosc":"prolonges","lemma":"prolonge","pos":"NOM"} ,
		{"word":"prolos","word_nosc":"prolos","lemma":"prolo","pos":"NOM"} ,
		{"word":"prolégomènes","word_nosc":"prolegomenes","lemma":"prolégomènes","pos":"NOM"} ,
		{"word":"prolétaire","word_nosc":"proletaire","lemma":"prolétaire","pos":"NOM"} ,
		{"word":"prolétaires","word_nosc":"proletaires","lemma":"prolétaire","pos":"NOM"} ,
		{"word":"prolétariat","word_nosc":"proletariat","lemma":"prolétariat","pos":"NOM"} ,
		{"word":"prolétariats","word_nosc":"proletariats","lemma":"prolétariat","pos":"NOM"} ,
		{"word":"prolétarisation","word_nosc":"proletarisation","lemma":"prolétarisation","pos":"NOM"} ,
		{"word":"promazine","word_nosc":"promazine","lemma":"promazine","pos":"NOM"} ,
		{"word":"promenade","word_nosc":"promenade","lemma":"promenade","pos":"NOM"} ,
		{"word":"promenades","word_nosc":"promenades","lemma":"promenade","pos":"NOM"} ,
		{"word":"promeneur","word_nosc":"promeneur","lemma":"promeneur","pos":"NOM"} ,
		{"word":"promeneurs","word_nosc":"promeneurs","lemma":"promeneur","pos":"NOM"} ,
		{"word":"promeneuse","word_nosc":"promeneuse","lemma":"promeneur","pos":"NOM"} ,
		{"word":"promeneuses","word_nosc":"promeneuses","lemma":"promeneur","pos":"NOM"} ,
		{"word":"promenoir","word_nosc":"promenoir","lemma":"promenoir","pos":"NOM"} ,
		{"word":"promesse","word_nosc":"promesse","lemma":"promesse","pos":"NOM"} ,
		{"word":"promesses","word_nosc":"promesses","lemma":"promesse","pos":"NOM"} ,
		{"word":"prometteur","word_nosc":"prometteur","lemma":"prometteur","pos":"NOM"} ,
		{"word":"prometteurs","word_nosc":"prometteurs","lemma":"prometteur","pos":"NOM"} ,
		{"word":"prometteuse","word_nosc":"prometteuse","lemma":"prometteur","pos":"NOM"} ,
		{"word":"promis","word_nosc":"promis","lemma":"promis","pos":"NOM"} ,
		{"word":"promiscuité","word_nosc":"promiscuite","lemma":"promiscuité","pos":"NOM"} ,
		{"word":"promiscuités","word_nosc":"promiscuites","lemma":"promiscuité","pos":"NOM"} ,
		{"word":"promise","word_nosc":"promise","lemma":"promis","pos":"NOM"} ,
		{"word":"promises","word_nosc":"promises","lemma":"promis","pos":"NOM"} ,
		{"word":"promo","word_nosc":"promo","lemma":"promo","pos":"NOM"} ,
		{"word":"promontoire","word_nosc":"promontoire","lemma":"promontoire","pos":"NOM"} ,
		{"word":"promontoires","word_nosc":"promontoires","lemma":"promontoire","pos":"NOM"} ,
		{"word":"promos","word_nosc":"promos","lemma":"promo","pos":"NOM"} ,
		{"word":"promoteur","word_nosc":"promoteur","lemma":"promoteur","pos":"NOM"} ,
		{"word":"promoteurs","word_nosc":"promoteurs","lemma":"promoteur","pos":"NOM"} ,
		{"word":"promotion","word_nosc":"promotion","lemma":"promotion","pos":"NOM"} ,
		{"word":"promotions","word_nosc":"promotions","lemma":"promotion","pos":"NOM"} ,
		{"word":"promotrice","word_nosc":"promotrice","lemma":"promoteur","pos":"NOM"} ,
		{"word":"prompteur","word_nosc":"prompteur","lemma":"prompteur","pos":"NOM"} ,
		{"word":"prompteurs","word_nosc":"prompteurs","lemma":"prompteur","pos":"NOM"} ,
		{"word":"promptitude","word_nosc":"promptitude","lemma":"promptitude","pos":"NOM"} ,
		{"word":"promulgation","word_nosc":"promulgation","lemma":"promulgation","pos":"NOM"} ,
		{"word":"pronation","word_nosc":"pronation","lemma":"pronation","pos":"NOM"} ,
		{"word":"pronom","word_nosc":"pronom","lemma":"pronom","pos":"NOM"} ,
		{"word":"pronoms","word_nosc":"pronoms","lemma":"pronom","pos":"NOM"} ,
		{"word":"prononciation","word_nosc":"prononciation","lemma":"prononciation","pos":"NOM"} ,
		{"word":"prononciations","word_nosc":"prononciations","lemma":"prononciation","pos":"NOM"} ,
		{"word":"pronostic","word_nosc":"pronostic","lemma":"pronostic","pos":"NOM"} ,
		{"word":"pronostics","word_nosc":"pronostics","lemma":"pronostic","pos":"NOM"} ,
		{"word":"pronostiqueur","word_nosc":"pronostiqueur","lemma":"pronostiqueur","pos":"NOM"} ,
		{"word":"pronunciamiento","word_nosc":"pronunciamiento","lemma":"pronunciamiento","pos":"NOM"} ,
		{"word":"propagande","word_nosc":"propagande","lemma":"propagande","pos":"NOM"} ,
		{"word":"propagandes","word_nosc":"propagandes","lemma":"propagande","pos":"NOM"} ,
		{"word":"propagandiste","word_nosc":"propagandiste","lemma":"propagandiste","pos":"NOM"} ,
		{"word":"propagandistes","word_nosc":"propagandistes","lemma":"propagandiste","pos":"NOM"} ,
		{"word":"propagateur","word_nosc":"propagateur","lemma":"propagateur","pos":"NOM"} ,
		{"word":"propagateurs","word_nosc":"propagateurs","lemma":"propagateur","pos":"NOM"} ,
		{"word":"propagation","word_nosc":"propagation","lemma":"propagation","pos":"NOM"} ,
		{"word":"propagations","word_nosc":"propagations","lemma":"propagation","pos":"NOM"} ,
		{"word":"propane","word_nosc":"propane","lemma":"propane","pos":"NOM"} ,
		{"word":"propension","word_nosc":"propension","lemma":"propension","pos":"NOM"} ,
		{"word":"propergol","word_nosc":"propergol","lemma":"propergol","pos":"NOM"} ,
		{"word":"prophase","word_nosc":"prophase","lemma":"prophase","pos":"NOM"} ,
		{"word":"prophylaxie","word_nosc":"prophylaxie","lemma":"prophylaxie","pos":"NOM"} ,
		{"word":"prophète","word_nosc":"prophete","lemma":"prophète","pos":"NOM"} ,
		{"word":"prophètes","word_nosc":"prophetes","lemma":"prophète","pos":"NOM"} ,
		{"word":"prophétesse","word_nosc":"prophetesse","lemma":"prophète","pos":"NOM"} ,
		{"word":"prophétie","word_nosc":"prophetie","lemma":"prophétie","pos":"NOM"} ,
		{"word":"prophéties","word_nosc":"propheties","lemma":"prophétie","pos":"NOM"} ,
		{"word":"propionate","word_nosc":"propionate","lemma":"propionate","pos":"NOM"} ,
		{"word":"propolis","word_nosc":"propolis","lemma":"propolis","pos":"NOM"} ,
		{"word":"proportion","word_nosc":"proportion","lemma":"proportion","pos":"NOM"} ,
		{"word":"proportionnalité","word_nosc":"proportionnalite","lemma":"proportionnalité","pos":"NOM"} ,
		{"word":"proportionnelle","word_nosc":"proportionnelle","lemma":"proportionnel","pos":"NOM"} ,
		{"word":"proportions","word_nosc":"proportions","lemma":"proportion","pos":"NOM"} ,
		{"word":"propos","word_nosc":"propos","lemma":"propos","pos":"NOM"} ,
		{"word":"proposition","word_nosc":"proposition","lemma":"proposition","pos":"NOM"} ,
		{"word":"propositions","word_nosc":"propositions","lemma":"proposition","pos":"NOM"} ,
		{"word":"propre","word_nosc":"propre","lemma":"propre","pos":"NOM"} ,
		{"word":"propre-à-rien","word_nosc":"propre-a-rien","lemma":"propre-à-rien","pos":"NOM"} ,
		{"word":"propres","word_nosc":"propres","lemma":"propre","pos":"NOM"} ,
		{"word":"propreté","word_nosc":"proprete","lemma":"propreté","pos":"NOM"} ,
		{"word":"proprio","word_nosc":"proprio","lemma":"proprio","pos":"NOM"} ,
		{"word":"proprios","word_nosc":"proprios","lemma":"proprio","pos":"NOM"} ,
		{"word":"propriétaire","word_nosc":"proprietaire","lemma":"propriétaire","pos":"NOM"} ,
		{"word":"propriétaires","word_nosc":"proprietaires","lemma":"propriétaire","pos":"NOM"} ,
		{"word":"propriété","word_nosc":"propriete","lemma":"propriété","pos":"NOM"} ,
		{"word":"propriétés","word_nosc":"proprietes","lemma":"propriété","pos":"NOM"} ,
		{"word":"propulseur","word_nosc":"propulseur","lemma":"propulseur","pos":"NOM"} ,
		{"word":"propulseurs","word_nosc":"propulseurs","lemma":"propulseur","pos":"NOM"} ,
		{"word":"propulsion","word_nosc":"propulsion","lemma":"propulsion","pos":"NOM"} ,
		{"word":"propulsions","word_nosc":"propulsions","lemma":"propulsion","pos":"NOM"} ,
		{"word":"propylène","word_nosc":"propylene","lemma":"propylène","pos":"NOM"} ,
		{"word":"propylées","word_nosc":"propylees","lemma":"propylée","pos":"NOM"} ,
		{"word":"propédeute","word_nosc":"propedeute","lemma":"propédeute","pos":"NOM"} ,
		{"word":"prorata","word_nosc":"prorata","lemma":"prorata","pos":"NOM"} ,
		{"word":"prorogation","word_nosc":"prorogation","lemma":"prorogation","pos":"NOM"} ,
		{"word":"pros","word_nosc":"pros","lemma":"pro","pos":"NOM"} ,
		{"word":"prosateur","word_nosc":"prosateur","lemma":"prosateur","pos":"NOM"} ,
		{"word":"prosateurs","word_nosc":"prosateurs","lemma":"prosateur","pos":"NOM"} ,
		{"word":"prosaïsme","word_nosc":"prosaisme","lemma":"prosaïsme","pos":"NOM"} ,
		{"word":"proscenium","word_nosc":"proscenium","lemma":"proscenium","pos":"NOM"} ,
		{"word":"proscription","word_nosc":"proscription","lemma":"proscription","pos":"NOM"} ,
		{"word":"proscriptions","word_nosc":"proscriptions","lemma":"proscription","pos":"NOM"} ,
		{"word":"proscrit","word_nosc":"proscrit","lemma":"proscrit","pos":"NOM"} ,
		{"word":"proscrite","word_nosc":"proscrite","lemma":"proscrit","pos":"NOM"} ,
		{"word":"proscrits","word_nosc":"proscrits","lemma":"proscrit","pos":"NOM"} ,
		{"word":"prose","word_nosc":"prose","lemma":"prose","pos":"NOM"} ,
		{"word":"prosodie","word_nosc":"prosodie","lemma":"prosodie","pos":"NOM"} ,
		{"word":"prosopopées","word_nosc":"prosopopees","lemma":"prosopopée","pos":"NOM"} ,
		{"word":"prospect","word_nosc":"prospect","lemma":"prospect","pos":"NOM"} ,
		{"word":"prospecteur","word_nosc":"prospecteur","lemma":"prospecteur","pos":"NOM"} ,
		{"word":"prospecteurs","word_nosc":"prospecteurs","lemma":"prospecteur","pos":"NOM"} ,
		{"word":"prospection","word_nosc":"prospection","lemma":"prospection","pos":"NOM"} ,
		{"word":"prospections","word_nosc":"prospections","lemma":"prospection","pos":"NOM"} ,
		{"word":"prospective","word_nosc":"prospective","lemma":"prospective","pos":"NOM"} ,
		{"word":"prospectus","word_nosc":"prospectus","lemma":"prospectus","pos":"NOM"} ,
		{"word":"prospérité","word_nosc":"prosperite","lemma":"prospérité","pos":"NOM"} ,
		{"word":"prospérités","word_nosc":"prosperites","lemma":"prospérité","pos":"NOM"} ,
		{"word":"prostate","word_nosc":"prostate","lemma":"prostate","pos":"NOM"} ,
		{"word":"prostatectomie","word_nosc":"prostatectomie","lemma":"prostatectomie","pos":"NOM"} ,
		{"word":"prostatite","word_nosc":"prostatite","lemma":"prostatite","pos":"NOM"} ,
		{"word":"prosternation","word_nosc":"prosternation","lemma":"prosternation","pos":"NOM"} ,
		{"word":"prosternations","word_nosc":"prosternations","lemma":"prosternation","pos":"NOM"} ,
		{"word":"prostitution","word_nosc":"prostitution","lemma":"prostitution","pos":"NOM"} ,
		{"word":"prostitutions","word_nosc":"prostitutions","lemma":"prostitution","pos":"NOM"} ,
		{"word":"prostitué","word_nosc":"prostitue","lemma":"prostitué","pos":"NOM"} ,
		{"word":"prostituée","word_nosc":"prostituee","lemma":"prostitué","pos":"NOM"} ,
		{"word":"prostituées","word_nosc":"prostituees","lemma":"prostitué","pos":"NOM"} ,
		{"word":"prostitués","word_nosc":"prostitues","lemma":"prostitué","pos":"NOM"} ,
		{"word":"prostration","word_nosc":"prostration","lemma":"prostration","pos":"NOM"} ,
		{"word":"prostrations","word_nosc":"prostrations","lemma":"prostration","pos":"NOM"} ,
		{"word":"prosélyte","word_nosc":"proselyte","lemma":"prosélyte","pos":"NOM"} ,
		{"word":"prosélytes","word_nosc":"proselytes","lemma":"prosélyte","pos":"NOM"} ,
		{"word":"prosélytisme","word_nosc":"proselytisme","lemma":"prosélytisme","pos":"NOM"} ,
		{"word":"protagoniste","word_nosc":"protagoniste","lemma":"protagoniste","pos":"NOM"} ,
		{"word":"protagonistes","word_nosc":"protagonistes","lemma":"protagoniste","pos":"NOM"} ,
		{"word":"protal","word_nosc":"protal","lemma":"protal","pos":"NOM"} ,
		{"word":"prote","word_nosc":"prote","lemma":"prote","pos":"NOM"} ,
		{"word":"protecteur","word_nosc":"protecteur","lemma":"protecteur","pos":"NOM"} ,
		{"word":"protecteurs","word_nosc":"protecteurs","lemma":"protecteur","pos":"NOM"} ,
		{"word":"protection","word_nosc":"protection","lemma":"protection","pos":"NOM"} ,
		{"word":"protectionnisme","word_nosc":"protectionnisme","lemma":"protectionnisme","pos":"NOM"} ,
		{"word":"protections","word_nosc":"protections","lemma":"protection","pos":"NOM"} ,
		{"word":"protectorat","word_nosc":"protectorat","lemma":"protectorat","pos":"NOM"} ,
		{"word":"protectorats","word_nosc":"protectorats","lemma":"protectorat","pos":"NOM"} ,
		{"word":"protectrice","word_nosc":"protectrice","lemma":"protecteur","pos":"NOM"} ,
		{"word":"protectrices","word_nosc":"protectrices","lemma":"protecteur","pos":"NOM"} ,
		{"word":"protes","word_nosc":"protes","lemma":"prote","pos":"NOM"} ,
		{"word":"protestant","word_nosc":"protestant","lemma":"protestant","pos":"NOM"} ,
		{"word":"protestante","word_nosc":"protestante","lemma":"protestant","pos":"NOM"} ,
		{"word":"protestantes","word_nosc":"protestantes","lemma":"protestant","pos":"NOM"} ,
		{"word":"protestantisme","word_nosc":"protestantisme","lemma":"protestantisme","pos":"NOM"} ,
		{"word":"protestants","word_nosc":"protestants","lemma":"protestant","pos":"NOM"} ,
		{"word":"protestataires","word_nosc":"protestataires","lemma":"protestataire","pos":"NOM"} ,
		{"word":"protestation","word_nosc":"protestation","lemma":"protestation","pos":"NOM"} ,
		{"word":"protestations","word_nosc":"protestations","lemma":"protestation","pos":"NOM"} ,
		{"word":"proteus","word_nosc":"proteus","lemma":"proteus","pos":"NOM"} ,
		{"word":"prothrombine","word_nosc":"prothrombine","lemma":"prothrombine","pos":"NOM"} ,
		{"word":"prothèse","word_nosc":"prothese","lemma":"prothèse","pos":"NOM"} ,
		{"word":"prothèses","word_nosc":"protheses","lemma":"prothèse","pos":"NOM"} ,
		{"word":"prothésiste","word_nosc":"prothesiste","lemma":"prothésiste","pos":"NOM"} ,
		{"word":"prothésistes","word_nosc":"prothesistes","lemma":"prothésiste","pos":"NOM"} ,
		{"word":"protide","word_nosc":"protide","lemma":"protide","pos":"NOM"} ,
		{"word":"protides","word_nosc":"protides","lemma":"protide","pos":"NOM"} ,
		{"word":"protistes","word_nosc":"protistes","lemma":"protiste","pos":"NOM"} ,
		{"word":"protococcus","word_nosc":"protococcus","lemma":"protococcus","pos":"NOM"} ,
		{"word":"protocole","word_nosc":"protocole","lemma":"protocole","pos":"NOM"} ,
		{"word":"protocoles","word_nosc":"protocoles","lemma":"protocole","pos":"NOM"} ,
		{"word":"protocoques","word_nosc":"protocoques","lemma":"protocoque","pos":"NOM"} ,
		{"word":"protohistoire","word_nosc":"protohistoire","lemma":"protohistoire","pos":"NOM"} ,
		{"word":"proton","word_nosc":"proton","lemma":"proton","pos":"NOM"} ,
		{"word":"protonotaire","word_nosc":"protonotaire","lemma":"protonotaire","pos":"NOM"} ,
		{"word":"protons","word_nosc":"protons","lemma":"proton","pos":"NOM"} ,
		{"word":"protoplasme","word_nosc":"protoplasme","lemma":"protoplasme","pos":"NOM"} ,
		{"word":"protoplasmes","word_nosc":"protoplasmes","lemma":"protoplasme","pos":"NOM"} ,
		{"word":"protoplaste","word_nosc":"protoplaste","lemma":"protoplaste","pos":"NOM"} ,
		{"word":"prototype","word_nosc":"prototype","lemma":"prototype","pos":"NOM"} ,
		{"word":"prototypes","word_nosc":"prototypes","lemma":"prototype","pos":"NOM"} ,
		{"word":"protoxyde","word_nosc":"protoxyde","lemma":"protoxyde","pos":"NOM"} ,
		{"word":"protozoaire","word_nosc":"protozoaire","lemma":"protozoaire","pos":"NOM"} ,
		{"word":"protrusion","word_nosc":"protrusion","lemma":"protrusion","pos":"NOM"} ,
		{"word":"protubérance","word_nosc":"protuberance","lemma":"protubérance","pos":"NOM"} ,
		{"word":"protubérances","word_nosc":"protuberances","lemma":"protubérance","pos":"NOM"} ,
		{"word":"protège-cahier","word_nosc":"protege-cahier","lemma":"protège-cahier","pos":"NOM"} ,
		{"word":"protège-dents","word_nosc":"protege-dents","lemma":"protège-dents","pos":"NOM"} ,
		{"word":"protège-slip","word_nosc":"protege-slip","lemma":"protège-slip","pos":"NOM"} ,
		{"word":"protège-tibia","word_nosc":"protege-tibia","lemma":"protège-tibia","pos":"NOM"} ,
		{"word":"protéase","word_nosc":"protease","lemma":"protéase","pos":"NOM"} ,
		{"word":"protégé","word_nosc":"protege","lemma":"protégé","pos":"NOM"} ,
		{"word":"protégée","word_nosc":"protegee","lemma":"protégé","pos":"NOM"} ,
		{"word":"protégées","word_nosc":"protegees","lemma":"protégé","pos":"NOM"} ,
		{"word":"protégés","word_nosc":"proteges","lemma":"protégé","pos":"NOM"} ,
		{"word":"protéine","word_nosc":"proteine","lemma":"protéine","pos":"NOM"} ,
		{"word":"protéines","word_nosc":"proteines","lemma":"protéine","pos":"NOM"} ,
		{"word":"protéinurie","word_nosc":"proteinurie","lemma":"protéinurie","pos":"NOM"} ,
		{"word":"protêt","word_nosc":"protet","lemma":"protêt","pos":"NOM"} ,
		{"word":"protêts","word_nosc":"protets","lemma":"protêt","pos":"NOM"} ,
		{"word":"proudhonisme","word_nosc":"proudhonisme","lemma":"proudhonisme","pos":"NOM"} ,
		{"word":"proue","word_nosc":"proue","lemma":"proue","pos":"NOM"} ,
		{"word":"proues","word_nosc":"proues","lemma":"proue","pos":"NOM"} ,
		{"word":"prouesse","word_nosc":"prouesse","lemma":"prouesse","pos":"NOM"} ,
		{"word":"prouesses","word_nosc":"prouesses","lemma":"prouesse","pos":"NOM"} ,
		{"word":"prout","word_nosc":"prout","lemma":"prout","pos":"NOM"} ,
		{"word":"prouts","word_nosc":"prouts","lemma":"prout","pos":"NOM"} ,
		{"word":"provenance","word_nosc":"provenance","lemma":"provenance","pos":"NOM"} ,
		{"word":"provenances","word_nosc":"provenances","lemma":"provenance","pos":"NOM"} ,
		{"word":"provende","word_nosc":"provende","lemma":"provende","pos":"NOM"} ,
		{"word":"proverbe","word_nosc":"proverbe","lemma":"proverbe","pos":"NOM"} ,
		{"word":"proverbes","word_nosc":"proverbes","lemma":"proverbe","pos":"NOM"} ,
		{"word":"providence","word_nosc":"providence","lemma":"providence","pos":"NOM"} ,
		{"word":"provider","word_nosc":"provider","lemma":"provider","pos":"NOM"} ,
		{"word":"province","word_nosc":"province","lemma":"province","pos":"NOM"} ,
		{"word":"provinces","word_nosc":"provinces","lemma":"province","pos":"NOM"} ,
		{"word":"provincial","word_nosc":"provincial","lemma":"provincial","pos":"NOM"} ,
		{"word":"provinciaux","word_nosc":"provinciaux","lemma":"provincial","pos":"NOM"} ,
		{"word":"proviseur","word_nosc":"proviseur","lemma":"proviseur","pos":"NOM"} ,
		{"word":"proviseurs","word_nosc":"proviseurs","lemma":"proviseur","pos":"NOM"} ,
		{"word":"provision","word_nosc":"provision","lemma":"provision","pos":"NOM"} ,
		{"word":"provisions","word_nosc":"provisions","lemma":"provision","pos":"NOM"} ,
		{"word":"provisoire","word_nosc":"provisoire","lemma":"provisoire","pos":"NOM"} ,
		{"word":"provo","word_nosc":"provo","lemma":"provo","pos":"NOM"} ,
		{"word":"provoc","word_nosc":"provoc","lemma":"provoc","pos":"NOM"} ,
		{"word":"provocateur","word_nosc":"provocateur","lemma":"provocateur","pos":"NOM"} ,
		{"word":"provocateurs","word_nosc":"provocateurs","lemma":"provocateur","pos":"NOM"} ,
		{"word":"provocation","word_nosc":"provocation","lemma":"provocation","pos":"NOM"} ,
		{"word":"provocations","word_nosc":"provocations","lemma":"provocation","pos":"NOM"} ,
		{"word":"provocatrice","word_nosc":"provocatrice","lemma":"provocateur","pos":"NOM"} ,
		{"word":"provolone","word_nosc":"provolone","lemma":"provolone","pos":"NOM"} ,
		{"word":"provos","word_nosc":"provos","lemma":"provo","pos":"NOM"} ,
		{"word":"provéditeur","word_nosc":"provediteur","lemma":"provéditeur","pos":"NOM"} ,
		{"word":"provéditeurs","word_nosc":"provediteurs","lemma":"provéditeur","pos":"NOM"} ,
		{"word":"proximité","word_nosc":"proximite","lemma":"proximité","pos":"NOM"} ,
		{"word":"proxo","word_nosc":"proxo","lemma":"proxo","pos":"NOM"} ,
		{"word":"proxos","word_nosc":"proxos","lemma":"proxo","pos":"NOM"} ,
		{"word":"proxénète","word_nosc":"proxenete","lemma":"proxénète","pos":"NOM"} ,
		{"word":"proxénètes","word_nosc":"proxenetes","lemma":"proxénète","pos":"NOM"} ,
		{"word":"proxénétisme","word_nosc":"proxenetisme","lemma":"proxénétisme","pos":"NOM"} ,
		{"word":"proze","word_nosc":"proze","lemma":"proze","pos":"NOM"} ,
		{"word":"proèdre","word_nosc":"proedre","lemma":"proèdre","pos":"NOM"} ,
		{"word":"proéminence","word_nosc":"proeminence","lemma":"proéminence","pos":"NOM"} ,
		{"word":"proéminences","word_nosc":"proeminences","lemma":"proéminence","pos":"NOM"} ,
		{"word":"prud'homme","word_nosc":"prud'homme","lemma":"prud'homme","pos":"NOM"} ,
		{"word":"prud'hommes","word_nosc":"prud'hommes","lemma":"prud'homme","pos":"NOM"} ,
		{"word":"prude","word_nosc":"prude","lemma":"prude","pos":"NOM"} ,
		{"word":"prudence","word_nosc":"prudence","lemma":"prudence","pos":"NOM"} ,
		{"word":"prudences","word_nosc":"prudences","lemma":"prudence","pos":"NOM"} ,
		{"word":"prudent","word_nosc":"prudent","lemma":"prudent","pos":"NOM"} ,
		{"word":"prudents","word_nosc":"prudents","lemma":"prudent","pos":"NOM"} ,
		{"word":"pruderie","word_nosc":"pruderie","lemma":"pruderie","pos":"NOM"} ,
		{"word":"pruderies","word_nosc":"pruderies","lemma":"pruderie","pos":"NOM"} ,
		{"word":"prudes","word_nosc":"prudes","lemma":"prude","pos":"NOM"} ,
		{"word":"prune","word_nosc":"prune","lemma":"prune","pos":"NOM"} ,
		{"word":"pruneau","word_nosc":"pruneau","lemma":"pruneau","pos":"NOM"} ,
		{"word":"pruneaux","word_nosc":"pruneaux","lemma":"pruneau","pos":"NOM"} ,
		{"word":"prunelle","word_nosc":"prunelle","lemma":"prunelle","pos":"NOM"} ,
		{"word":"prunelles","word_nosc":"prunelles","lemma":"prunelle","pos":"NOM"} ,
		{"word":"prunellier","word_nosc":"prunellier","lemma":"prunellier","pos":"NOM"} ,
		{"word":"prunelliers","word_nosc":"prunelliers","lemma":"prunellier","pos":"NOM"} ,
		{"word":"prunes","word_nosc":"prunes","lemma":"prune","pos":"NOM"} ,
		{"word":"prunier","word_nosc":"prunier","lemma":"prunier","pos":"NOM"} ,
		{"word":"pruniers","word_nosc":"pruniers","lemma":"prunier","pos":"NOM"} ,
		{"word":"prunus","word_nosc":"prunus","lemma":"prunus","pos":"NOM"} ,
		{"word":"prurigo","word_nosc":"prurigo","lemma":"prurigo","pos":"NOM"} ,
		{"word":"prurit","word_nosc":"prurit","lemma":"prurit","pos":"NOM"} ,
		{"word":"prussien","word_nosc":"prussien","lemma":"prussien","pos":"NOM"} ,
		{"word":"prussienne","word_nosc":"prussienne","lemma":"prussien","pos":"NOM"} ,
		{"word":"prussiennes","word_nosc":"prussiennes","lemma":"prussien","pos":"NOM"} ,
		{"word":"prussiens","word_nosc":"prussiens","lemma":"prussien","pos":"NOM"} ,
		{"word":"prussik","word_nosc":"prussik","lemma":"prussik","pos":"NOM"} ,
		{"word":"prytanée","word_nosc":"prytanee","lemma":"prytanée","pos":"NOM"} ,
		{"word":"prèle","word_nosc":"prele","lemma":"prèle","pos":"NOM"} ,
		{"word":"pré","word_nosc":"pre","lemma":"pré","pos":"NOM"} ,
		{"word":"pré-salé","word_nosc":"pre-sale","lemma":"pré-salé","pos":"NOM"} ,
		{"word":"préadolescence","word_nosc":"preadolescence","lemma":"préadolescence","pos":"NOM"} ,
		{"word":"préadolescente","word_nosc":"preadolescente","lemma":"préadolescent","pos":"NOM"} ,
		{"word":"préalable","word_nosc":"prealable","lemma":"préalable","pos":"NOM"} ,
		{"word":"préalables","word_nosc":"prealables","lemma":"préalable","pos":"NOM"} ,
		{"word":"préambule","word_nosc":"preambule","lemma":"préambule","pos":"NOM"} ,
		{"word":"préambules","word_nosc":"preambules","lemma":"préambule","pos":"NOM"} ,
		{"word":"préau","word_nosc":"preau","lemma":"préau","pos":"NOM"} ,
		{"word":"préaux","word_nosc":"preaux","lemma":"préau","pos":"NOM"} ,
		{"word":"préavis","word_nosc":"preavis","lemma":"préavis","pos":"NOM"} ,
		{"word":"prébendes","word_nosc":"prebendes","lemma":"prébende","pos":"NOM"} ,
		{"word":"précambrien","word_nosc":"precambrien","lemma":"précambrien","pos":"NOM"} ,
		{"word":"précarité","word_nosc":"precarite","lemma":"précarité","pos":"NOM"} ,
		{"word":"précaution","word_nosc":"precaution","lemma":"précaution","pos":"NOM"} ,
		{"word":"précautions","word_nosc":"precautions","lemma":"précaution","pos":"NOM"} ,
		{"word":"précellence","word_nosc":"precellence","lemma":"précellence","pos":"NOM"} ,
		{"word":"précelles","word_nosc":"precelles","lemma":"précelles","pos":"NOM"} ,
		{"word":"précepte","word_nosc":"precepte","lemma":"précepte","pos":"NOM"} ,
		{"word":"préceptes","word_nosc":"preceptes","lemma":"précepte","pos":"NOM"} ,
		{"word":"précepteur","word_nosc":"precepteur","lemma":"précepteur","pos":"NOM"} ,
		{"word":"précepteurs","word_nosc":"precepteurs","lemma":"précepteur","pos":"NOM"} ,
		{"word":"préceptrice","word_nosc":"preceptrice","lemma":"précepteur","pos":"NOM"} ,
		{"word":"précession","word_nosc":"precession","lemma":"précession","pos":"NOM"} ,
		{"word":"préchauffage","word_nosc":"prechauffage","lemma":"préchauffage","pos":"NOM"} ,
		{"word":"précieuse","word_nosc":"precieuse","lemma":"précieux","pos":"NOM"} ,
		{"word":"précieuses","word_nosc":"precieuses","lemma":"précieuse","pos":"NOM"} ,
		{"word":"précieuses","word_nosc":"precieuses","lemma":"précieux","pos":"NOM"} ,
		{"word":"préciosité","word_nosc":"preciosite","lemma":"préciosité","pos":"NOM"} ,
		{"word":"préciosités","word_nosc":"preciosites","lemma":"préciosité","pos":"NOM"} ,
		{"word":"précipice","word_nosc":"precipice","lemma":"précipice","pos":"NOM"} ,
		{"word":"précipices","word_nosc":"precipices","lemma":"précipice","pos":"NOM"} ,
		{"word":"précipitant","word_nosc":"precipitant","lemma":"précipitant","pos":"NOM"} ,
		{"word":"précipitation","word_nosc":"precipitation","lemma":"précipitation","pos":"NOM"} ,
		{"word":"précipitations","word_nosc":"precipitations","lemma":"précipitation","pos":"NOM"} ,
		{"word":"précipité","word_nosc":"precipite","lemma":"précipité","pos":"NOM"} ,
		{"word":"précipités","word_nosc":"precipites","lemma":"précipité","pos":"NOM"} ,
		{"word":"préciputs","word_nosc":"preciputs","lemma":"préciput","pos":"NOM"} ,
		{"word":"précis","word_nosc":"precis","lemma":"précis","pos":"NOM"} ,
		{"word":"précision","word_nosc":"precision","lemma":"précision","pos":"NOM"} ,
		{"word":"précisions","word_nosc":"precisions","lemma":"précision","pos":"NOM"} ,
		{"word":"précocité","word_nosc":"precocite","lemma":"précocité","pos":"NOM"} ,
		{"word":"précognition","word_nosc":"precognition","lemma":"précognition","pos":"NOM"} ,
		{"word":"préconisation","word_nosc":"preconisation","lemma":"préconisation","pos":"NOM"} ,
		{"word":"précurseur","word_nosc":"precurseur","lemma":"précurseur","pos":"NOM"} ,
		{"word":"précurseurs","word_nosc":"precurseurs","lemma":"précurseur","pos":"NOM"} ,
		{"word":"précédent","word_nosc":"precedent","lemma":"précédent","pos":"NOM"} ,
		{"word":"précédente","word_nosc":"precedente","lemma":"précédent","pos":"NOM"} ,
		{"word":"précédentes","word_nosc":"precedentes","lemma":"précédent","pos":"NOM"} ,
		{"word":"précédents","word_nosc":"precedents","lemma":"précédent","pos":"NOM"} ,
		{"word":"prédateur","word_nosc":"predateur","lemma":"prédateur","pos":"NOM"} ,
		{"word":"prédateurs","word_nosc":"predateurs","lemma":"prédateur","pos":"NOM"} ,
		{"word":"prédation","word_nosc":"predation","lemma":"prédation","pos":"NOM"} ,
		{"word":"prédelle","word_nosc":"predelle","lemma":"prédelle","pos":"NOM"} ,
		{"word":"prédestination","word_nosc":"predestination","lemma":"prédestination","pos":"NOM"} ,
		{"word":"prédestinations","word_nosc":"predestinations","lemma":"prédestination","pos":"NOM"} ,
		{"word":"prédestiné","word_nosc":"predestine","lemma":"prédestiné","pos":"NOM"} ,
		{"word":"prédestinée","word_nosc":"predestinee","lemma":"prédestiné","pos":"NOM"} ,
		{"word":"prédicant","word_nosc":"predicant","lemma":"prédicant","pos":"NOM"} ,
		{"word":"prédicants","word_nosc":"predicants","lemma":"prédicant","pos":"NOM"} ,
		{"word":"prédicat","word_nosc":"predicat","lemma":"prédicat","pos":"NOM"} ,
		{"word":"prédicateur","word_nosc":"predicateur","lemma":"prédicateur","pos":"NOM"} ,
		{"word":"prédicateurs","word_nosc":"predicateurs","lemma":"prédicateur","pos":"NOM"} ,
		{"word":"prédication","word_nosc":"predication","lemma":"prédication","pos":"NOM"} ,
		{"word":"prédications","word_nosc":"predications","lemma":"prédication","pos":"NOM"} ,
		{"word":"prédiction","word_nosc":"prediction","lemma":"prédiction","pos":"NOM"} ,
		{"word":"prédictions","word_nosc":"predictions","lemma":"prédiction","pos":"NOM"} ,
		{"word":"prédigestion","word_nosc":"predigestion","lemma":"prédigestion","pos":"NOM"} ,
		{"word":"prédilection","word_nosc":"predilection","lemma":"prédilection","pos":"NOM"} ,
		{"word":"prédilections","word_nosc":"predilections","lemma":"prédilection","pos":"NOM"} ,
		{"word":"prédisposition","word_nosc":"predisposition","lemma":"prédisposition","pos":"NOM"} ,
		{"word":"prédispositions","word_nosc":"predispositions","lemma":"prédisposition","pos":"NOM"} ,
		{"word":"prédominance","word_nosc":"predominance","lemma":"prédominance","pos":"NOM"} ,
		{"word":"prédécesseur","word_nosc":"predecesseur","lemma":"prédécesseur","pos":"NOM"} ,
		{"word":"prédécesseurs","word_nosc":"predecesseurs","lemma":"prédécesseur","pos":"NOM"} ,
		{"word":"préemption","word_nosc":"preemption","lemma":"préemption","pos":"NOM"} ,
		{"word":"préface","word_nosc":"preface","lemma":"préface","pos":"NOM"} ,
		{"word":"préfaces","word_nosc":"prefaces","lemma":"préface","pos":"NOM"} ,
		{"word":"préfacier","word_nosc":"prefacier","lemma":"préfacier","pos":"NOM"} ,
		{"word":"préfectance","word_nosc":"prefectance","lemma":"préfectance","pos":"NOM"} ,
		{"word":"préfecture","word_nosc":"prefecture","lemma":"préfecture","pos":"NOM"} ,
		{"word":"préfectures","word_nosc":"prefectures","lemma":"préfecture","pos":"NOM"} ,
		{"word":"préfet","word_nosc":"prefet","lemma":"préfet","pos":"NOM"} ,
		{"word":"préfets","word_nosc":"prefets","lemma":"préfet","pos":"NOM"} ,
		{"word":"préfiguration","word_nosc":"prefiguration","lemma":"préfiguration","pos":"NOM"} ,
		{"word":"préfixation","word_nosc":"prefixation","lemma":"préfixation","pos":"NOM"} ,
		{"word":"préfixe","word_nosc":"prefixe","lemma":"préfixe","pos":"NOM"} ,
		{"word":"préfixes","word_nosc":"prefixes","lemma":"préfixe","pos":"NOM"} ,
		{"word":"préformation","word_nosc":"preformation","lemma":"préformation","pos":"NOM"} ,
		{"word":"préférence","word_nosc":"preference","lemma":"préférence","pos":"NOM"} ,
		{"word":"préférences","word_nosc":"preferences","lemma":"préférence","pos":"NOM"} ,
		{"word":"préféré","word_nosc":"prefere","lemma":"préféré","pos":"NOM"} ,
		{"word":"préférée","word_nosc":"preferee","lemma":"préféré","pos":"NOM"} ,
		{"word":"préférées","word_nosc":"preferees","lemma":"préféré","pos":"NOM"} ,
		{"word":"préférés","word_nosc":"preferes","lemma":"préféré","pos":"NOM"} ,
		{"word":"prégnance","word_nosc":"pregnance","lemma":"prégnance","pos":"NOM"} ,
		{"word":"préhension","word_nosc":"prehension","lemma":"préhension","pos":"NOM"} ,
		{"word":"préhistoire","word_nosc":"prehistoire","lemma":"préhistoire","pos":"NOM"} ,
		{"word":"préhistorien","word_nosc":"prehistorien","lemma":"préhistorien","pos":"NOM"} ,
		{"word":"préhominien","word_nosc":"prehominien","lemma":"préhominien","pos":"NOM"} ,
		{"word":"préjudice","word_nosc":"prejudice","lemma":"préjudice","pos":"NOM"} ,
		{"word":"préjudices","word_nosc":"prejudices","lemma":"préjudice","pos":"NOM"} ,
		{"word":"préjugé","word_nosc":"prejuge","lemma":"préjugé","pos":"NOM"} ,
		{"word":"préjugés","word_nosc":"prejuges","lemma":"préjugé","pos":"NOM"} ,
		{"word":"prélat","word_nosc":"prelat","lemma":"prélat","pos":"NOM"} ,
		{"word":"prélats","word_nosc":"prelats","lemma":"prélat","pos":"NOM"} ,
		{"word":"prélavage","word_nosc":"prelavage","lemma":"prélavage","pos":"NOM"} ,
		{"word":"prélegs","word_nosc":"prelegs","lemma":"prélegs","pos":"NOM"} ,
		{"word":"préliminaire","word_nosc":"preliminaire","lemma":"préliminaire","pos":"NOM"} ,
		{"word":"préliminaires","word_nosc":"preliminaires","lemma":"préliminaire","pos":"NOM"} ,
		{"word":"prélude","word_nosc":"prelude","lemma":"prélude","pos":"NOM"} ,
		{"word":"préludes","word_nosc":"preludes","lemma":"prélude","pos":"NOM"} ,
		{"word":"prélèvement","word_nosc":"prelevement","lemma":"prélèvement","pos":"NOM"} ,
		{"word":"prélèvements","word_nosc":"prelevements","lemma":"prélèvement","pos":"NOM"} ,
		{"word":"prématurité","word_nosc":"prematurite","lemma":"prématurité","pos":"NOM"} ,
		{"word":"prématuré","word_nosc":"premature","lemma":"prématuré","pos":"NOM"} ,
		{"word":"prématurée","word_nosc":"prematuree","lemma":"prématuré","pos":"NOM"} ,
		{"word":"prématurés","word_nosc":"prematures","lemma":"prématuré","pos":"NOM"} ,
		{"word":"prémices","word_nosc":"premices","lemma":"prémices","pos":"NOM"} ,
		{"word":"prémisse","word_nosc":"premisse","lemma":"prémisse","pos":"NOM"} ,
		{"word":"prémisses","word_nosc":"premisses","lemma":"prémisse","pos":"NOM"} ,
		{"word":"prémolaire","word_nosc":"premolaire","lemma":"prémolaire","pos":"NOM"} ,
		{"word":"prémolaires","word_nosc":"premolaires","lemma":"prémolaire","pos":"NOM"} ,
		{"word":"prémonition","word_nosc":"premonition","lemma":"prémonition","pos":"NOM"} ,
		{"word":"prémonitions","word_nosc":"premonitions","lemma":"prémonition","pos":"NOM"} ,
		{"word":"préméditation","word_nosc":"premeditation","lemma":"préméditation","pos":"NOM"} ,
		{"word":"préméditations","word_nosc":"premeditations","lemma":"préméditation","pos":"NOM"} ,
		{"word":"prénom","word_nosc":"prenom","lemma":"prénom","pos":"NOM"} ,
		{"word":"prénom-type","word_nosc":"prenom-type","lemma":"prénom-type","pos":"NOM"} ,
		{"word":"prénommé","word_nosc":"prenomme","lemma":"prénommé","pos":"NOM"} ,
		{"word":"prénommée","word_nosc":"prenommee","lemma":"prénommé","pos":"NOM"} ,
		{"word":"prénoms","word_nosc":"prenoms","lemma":"prénom","pos":"NOM"} ,
		{"word":"préoccupation","word_nosc":"preoccupation","lemma":"préoccupation","pos":"NOM"} ,
		{"word":"préoccupations","word_nosc":"preoccupations","lemma":"préoccupation","pos":"NOM"} ,
		{"word":"prépa","word_nosc":"prepa","lemma":"prépa","pos":"NOM"} ,
		{"word":"préparateur","word_nosc":"preparateur","lemma":"préparateur","pos":"NOM"} ,
		{"word":"préparateurs","word_nosc":"preparateurs","lemma":"préparateur","pos":"NOM"} ,
		{"word":"préparatif","word_nosc":"preparatif","lemma":"préparatif","pos":"NOM"} ,
		{"word":"préparatifs","word_nosc":"preparatifs","lemma":"préparatif","pos":"NOM"} ,
		{"word":"préparation","word_nosc":"preparation","lemma":"préparation","pos":"NOM"} ,
		{"word":"préparations","word_nosc":"preparations","lemma":"préparation","pos":"NOM"} ,
		{"word":"préparatrice","word_nosc":"preparatrice","lemma":"préparateur","pos":"NOM"} ,
		{"word":"préparatrices","word_nosc":"preparatrices","lemma":"préparateur","pos":"NOM"} ,
		{"word":"prépondérance","word_nosc":"preponderance","lemma":"prépondérance","pos":"NOM"} ,
		{"word":"préposition","word_nosc":"preposition","lemma":"préposition","pos":"NOM"} ,
		{"word":"prépositions","word_nosc":"prepositions","lemma":"préposition","pos":"NOM"} ,
		{"word":"préposé","word_nosc":"prepose","lemma":"préposé","pos":"NOM"} ,
		{"word":"préposée","word_nosc":"preposee","lemma":"préposé","pos":"NOM"} ,
		{"word":"préposées","word_nosc":"preposees","lemma":"préposé","pos":"NOM"} ,
		{"word":"préposés","word_nosc":"preposes","lemma":"préposé","pos":"NOM"} ,
		{"word":"prépotence","word_nosc":"prepotence","lemma":"prépotence","pos":"NOM"} ,
		{"word":"prépuce","word_nosc":"prepuce","lemma":"prépuce","pos":"NOM"} ,
		{"word":"prépuces","word_nosc":"prepuces","lemma":"prépuce","pos":"NOM"} ,
		{"word":"prérentrée","word_nosc":"prerentree","lemma":"prérentrée","pos":"NOM"} ,
		{"word":"préretraite","word_nosc":"preretraite","lemma":"préretraite","pos":"NOM"} ,
		{"word":"prérogative","word_nosc":"prerogative","lemma":"prérogative","pos":"NOM"} ,
		{"word":"prérogatives","word_nosc":"prerogatives","lemma":"prérogative","pos":"NOM"} ,
		{"word":"prés","word_nosc":"pres","lemma":"pré","pos":"NOM"} ,
		{"word":"présage","word_nosc":"presage","lemma":"présage","pos":"NOM"} ,
		{"word":"présages","word_nosc":"presages","lemma":"présage","pos":"NOM"} ,
		{"word":"présence","word_nosc":"presence","lemma":"présence","pos":"NOM"} ,
		{"word":"présences","word_nosc":"presences","lemma":"présence","pos":"NOM"} ,
		{"word":"présent","word_nosc":"present","lemma":"présent","pos":"NOM"} ,
		{"word":"présentateur","word_nosc":"presentateur","lemma":"présentateur","pos":"NOM"} ,
		{"word":"présentateurs","word_nosc":"presentateurs","lemma":"présentateur","pos":"NOM"} ,
		{"word":"présentation","word_nosc":"presentation","lemma":"présentation","pos":"NOM"} ,
		{"word":"présentations","word_nosc":"presentations","lemma":"présentation","pos":"NOM"} ,
		{"word":"présentatrice","word_nosc":"presentatrice","lemma":"présentateur","pos":"NOM"} ,
		{"word":"présentatrices","word_nosc":"presentatrices","lemma":"présentateur","pos":"NOM"} ,
		{"word":"présente","word_nosc":"presente","lemma":"présente","pos":"NOM"} ,
		{"word":"présentes","word_nosc":"presentes","lemma":"présente","pos":"NOM"} ,
		{"word":"présentoir","word_nosc":"presentoir","lemma":"présentoir","pos":"NOM"} ,
		{"word":"présentoirs","word_nosc":"presentoirs","lemma":"présentoir","pos":"NOM"} ,
		{"word":"présents","word_nosc":"presents","lemma":"présent","pos":"NOM"} ,
		{"word":"préservateur","word_nosc":"preservateur","lemma":"préservateur","pos":"NOM"} ,
		{"word":"préservatif","word_nosc":"preservatif","lemma":"préservatif","pos":"NOM"} ,
		{"word":"préservatifs","word_nosc":"preservatifs","lemma":"préservatif","pos":"NOM"} ,
		{"word":"préservation","word_nosc":"preservation","lemma":"préservation","pos":"NOM"} ,
		{"word":"préside","word_nosc":"preside","lemma":"préside","pos":"NOM"} ,
		{"word":"présidence","word_nosc":"presidence","lemma":"présidence","pos":"NOM"} ,
		{"word":"présidences","word_nosc":"presidences","lemma":"présidence","pos":"NOM"} ,
		{"word":"président","word_nosc":"president","lemma":"président","pos":"NOM"} ,
		{"word":"président-directeur","word_nosc":"president-directeur","lemma":"président-directeur","pos":"NOM"} ,
		{"word":"présidente","word_nosc":"presidente","lemma":"président","pos":"NOM"} ,
		{"word":"présidentes","word_nosc":"presidentes","lemma":"président","pos":"NOM"} ,
		{"word":"présidentielle","word_nosc":"presidentielle","lemma":"présidentiel","pos":"NOM"} ,
		{"word":"présidentielles","word_nosc":"presidentielles","lemma":"présidentiel","pos":"NOM"} ,
		{"word":"présidents","word_nosc":"presidents","lemma":"président","pos":"NOM"} ,
		{"word":"présidents-directeurs","word_nosc":"presidents-directeurs","lemma":"président-directeur","pos":"NOM"} ,
		{"word":"présides","word_nosc":"presides","lemma":"préside","pos":"NOM"} ,
		{"word":"présidium","word_nosc":"presidium","lemma":"présidium","pos":"NOM"} ,
		{"word":"présomption","word_nosc":"presomption","lemma":"présomption","pos":"NOM"} ,
		{"word":"présomptions","word_nosc":"presomptions","lemma":"présomption","pos":"NOM"} ,
		{"word":"présomptueuse","word_nosc":"presomptueuse","lemma":"présomptueux","pos":"NOM"} ,
		{"word":"présomptueuses","word_nosc":"presomptueuses","lemma":"présomptueux","pos":"NOM"} ,
		{"word":"présomptueux","word_nosc":"presomptueux","lemma":"présomptueux","pos":"NOM"} ,
		{"word":"préséance","word_nosc":"preseance","lemma":"préséance","pos":"NOM"} ,
		{"word":"préséances","word_nosc":"preseances","lemma":"préséance","pos":"NOM"} ,
		{"word":"présélection","word_nosc":"preselection","lemma":"présélection","pos":"NOM"} ,
		{"word":"présélections","word_nosc":"preselections","lemma":"présélection","pos":"NOM"} ,
		{"word":"prétendant","word_nosc":"pretendant","lemma":"prétendant","pos":"NOM"} ,
		{"word":"prétendante","word_nosc":"pretendante","lemma":"prétendant","pos":"NOM"} ,
		{"word":"prétendants","word_nosc":"pretendants","lemma":"prétendant","pos":"NOM"} ,
		{"word":"prétentaine","word_nosc":"pretentaine","lemma":"prétentaine","pos":"NOM"} ,
		{"word":"prétentiard","word_nosc":"pretentiard","lemma":"prétentiard","pos":"NOM"} ,
		{"word":"prétentiarde","word_nosc":"pretentiarde","lemma":"prétentiard","pos":"NOM"} ,
		{"word":"prétentiards","word_nosc":"pretentiards","lemma":"prétentiard","pos":"NOM"} ,
		{"word":"prétentieuse","word_nosc":"pretentieuse","lemma":"prétentieux","pos":"NOM"} ,
		{"word":"prétentieuses","word_nosc":"pretentieuses","lemma":"prétentieux","pos":"NOM"} ,
		{"word":"prétentieux","word_nosc":"pretentieux","lemma":"prétentieux","pos":"NOM"} ,
		{"word":"prétention","word_nosc":"pretention","lemma":"prétention","pos":"NOM"} ,
		{"word":"prétentions","word_nosc":"pretentions","lemma":"prétention","pos":"NOM"} ,
		{"word":"préteur","word_nosc":"preteur","lemma":"préteur","pos":"NOM"} ,
		{"word":"préteurs","word_nosc":"preteurs","lemma":"préteur","pos":"NOM"} ,
		{"word":"prétexte","word_nosc":"pretexte","lemma":"prétexte","pos":"NOM"} ,
		{"word":"prétextes","word_nosc":"pretextes","lemma":"prétexte","pos":"NOM"} ,
		{"word":"prétoire","word_nosc":"pretoire","lemma":"prétoire","pos":"NOM"} ,
		{"word":"prétoires","word_nosc":"pretoires","lemma":"prétoire","pos":"NOM"} ,
		{"word":"prétorien","word_nosc":"pretorien","lemma":"prétorien","pos":"NOM"} ,
		{"word":"prétoriens","word_nosc":"pretoriens","lemma":"prétorien","pos":"NOM"} ,
		{"word":"préture","word_nosc":"preture","lemma":"préture","pos":"NOM"} ,
		{"word":"prévalence","word_nosc":"prevalence","lemma":"prévalence","pos":"NOM"} ,
		{"word":"prévaricateur","word_nosc":"prevaricateur","lemma":"prévaricateur","pos":"NOM"} ,
		{"word":"prévenance","word_nosc":"prevenance","lemma":"prévenance","pos":"NOM"} ,
		{"word":"prévenances","word_nosc":"prevenances","lemma":"prévenance","pos":"NOM"} ,
		{"word":"prévention","word_nosc":"prevention","lemma":"prévention","pos":"NOM"} ,
		{"word":"préventions","word_nosc":"preventions","lemma":"prévention","pos":"NOM"} ,
		{"word":"préventorium","word_nosc":"preventorium","lemma":"préventorium","pos":"NOM"} ,
		{"word":"prévenu","word_nosc":"prevenu","lemma":"prévenu","pos":"NOM"} ,
		{"word":"prévenue","word_nosc":"prevenue","lemma":"prévenu","pos":"NOM"} ,
		{"word":"prévenus","word_nosc":"prevenus","lemma":"prévenu","pos":"NOM"} ,
		{"word":"prévis","word_nosc":"previs","lemma":"prévis","pos":"NOM"} ,
		{"word":"prévisibilité","word_nosc":"previsibilite","lemma":"prévisibilité","pos":"NOM"} ,
		{"word":"prévision","word_nosc":"prevision","lemma":"prévision","pos":"NOM"} ,
		{"word":"prévisions","word_nosc":"previsions","lemma":"prévision","pos":"NOM"} ,
		{"word":"prévoyance","word_nosc":"prevoyance","lemma":"prévoyance","pos":"NOM"} ,
		{"word":"prévoyances","word_nosc":"prevoyances","lemma":"prévoyance","pos":"NOM"} ,
		{"word":"prévôt","word_nosc":"prevot","lemma":"prévôt","pos":"NOM"} ,
		{"word":"prévôts","word_nosc":"prevots","lemma":"prévôt","pos":"NOM"} ,
		{"word":"prévôté","word_nosc":"prevote","lemma":"prévôté","pos":"NOM"} ,
		{"word":"prééminence","word_nosc":"preeminence","lemma":"prééminence","pos":"NOM"} ,
		{"word":"prêche","word_nosc":"preche","lemma":"prêche","pos":"NOM"} ,
		{"word":"prêches","word_nosc":"preches","lemma":"prêche","pos":"NOM"} ,
		{"word":"prêcheur","word_nosc":"precheur","lemma":"prêcheur","pos":"NOM"} ,
		{"word":"prêcheurs","word_nosc":"precheurs","lemma":"prêcheur","pos":"NOM"} ,
		{"word":"prêcheuse","word_nosc":"precheuse","lemma":"prêcheur","pos":"NOM"} ,
		{"word":"prêchi-prêcha","word_nosc":"prechi-precha","lemma":"prêchi-prêcha","pos":"NOM"} ,
		{"word":"prêles","word_nosc":"preles","lemma":"prêle","pos":"NOM"} ,
		{"word":"prêt","word_nosc":"pret","lemma":"prêt","pos":"NOM"} ,
		{"word":"prêt-bail","word_nosc":"pret-bail","lemma":"prêt-bail","pos":"NOM"} ,
		{"word":"prêt-à-porter","word_nosc":"pret-a-porter","lemma":"prêt-à-porter","pos":"NOM"} ,
		{"word":"prête-nom","word_nosc":"prete-nom","lemma":"prête-nom","pos":"NOM"} ,
		{"word":"prêteur","word_nosc":"preteur","lemma":"prêteur","pos":"NOM"} ,
		{"word":"prêteurs","word_nosc":"preteurs","lemma":"prêteur","pos":"NOM"} ,
		{"word":"prêteuse","word_nosc":"preteuse","lemma":"prêteur","pos":"NOM"} ,
		{"word":"prêtraille","word_nosc":"pretraille","lemma":"prêtraille","pos":"NOM"} ,
		{"word":"prêtre","word_nosc":"pretre","lemma":"prêtre","pos":"NOM"} ,
		{"word":"prêtre-ouvrier","word_nosc":"pretre-ouvrier","lemma":"prêtre-ouvrier","pos":"NOM"} ,
		{"word":"prêtres","word_nosc":"pretres","lemma":"prêtre","pos":"NOM"} ,
		{"word":"prêtresse","word_nosc":"pretresse","lemma":"prêtre","pos":"NOM"} ,
		{"word":"prêtresses","word_nosc":"pretresses","lemma":"prêtre","pos":"NOM"} ,
		{"word":"prêtrise","word_nosc":"pretrise","lemma":"prêtrise","pos":"NOM"} ,
		{"word":"prêts","word_nosc":"prets","lemma":"prêt","pos":"NOM"} ,
		{"word":"prêté","word_nosc":"prete","lemma":"prêté","pos":"NOM"} ,
		{"word":"prêtés","word_nosc":"pretes","lemma":"prêté","pos":"NOM"} ,
		{"word":"prône","word_nosc":"prone","lemma":"prône","pos":"NOM"} ,
		{"word":"prônes","word_nosc":"prones","lemma":"prône","pos":"NOM"} ,
		{"word":"prôneur","word_nosc":"proneur","lemma":"prôneur","pos":"NOM"} ,
		{"word":"psalliotes","word_nosc":"psalliotes","lemma":"psalliote","pos":"NOM"} ,
		{"word":"psalmiste","word_nosc":"psalmiste","lemma":"psalmiste","pos":"NOM"} ,
		{"word":"psalmodie","word_nosc":"psalmodie","lemma":"psalmodie","pos":"NOM"} ,
		{"word":"psalmodies","word_nosc":"psalmodies","lemma":"psalmodie","pos":"NOM"} ,
		{"word":"psaume","word_nosc":"psaume","lemma":"psaume","pos":"NOM"} ,
		{"word":"psaumes","word_nosc":"psaumes","lemma":"psaume","pos":"NOM"} ,
		{"word":"psautier","word_nosc":"psautier","lemma":"psautier","pos":"NOM"} ,
		{"word":"pschent","word_nosc":"pschent","lemma":"pschent","pos":"NOM"} ,
		{"word":"pseudo","word_nosc":"pseudo","lemma":"pseudo","pos":"NOM"} ,
		{"word":"pseudo-gouvernement","word_nosc":"pseudo-gouvernement","lemma":"pseudo-gouvernement","pos":"NOM"} ,
		{"word":"pseudomonas","word_nosc":"pseudomonas","lemma":"pseudomonas","pos":"NOM"} ,
		{"word":"pseudonyme","word_nosc":"pseudonyme","lemma":"pseudonyme","pos":"NOM"} ,
		{"word":"pseudonymes","word_nosc":"pseudonymes","lemma":"pseudonyme","pos":"NOM"} ,
		{"word":"pseudopodes","word_nosc":"pseudopodes","lemma":"pseudopode","pos":"NOM"} ,
		{"word":"pseudos","word_nosc":"pseudos","lemma":"pseudo","pos":"NOM"} ,
		{"word":"psi","word_nosc":"psi","lemma":"psi","pos":"NOM"} ,
		{"word":"psilocybine","word_nosc":"psilocybine","lemma":"psilocybine","pos":"NOM"} ,
		{"word":"psittacose","word_nosc":"psittacose","lemma":"psittacose","pos":"NOM"} ,
		{"word":"psoriasis","word_nosc":"psoriasis","lemma":"psoriasis","pos":"NOM"} ,
		{"word":"psy","word_nosc":"psy","lemma":"psy","pos":"NOM"} ,
		{"word":"psychanalyse","word_nosc":"psychanalyse","lemma":"psychanalyse","pos":"NOM"} ,
		{"word":"psychanalyses","word_nosc":"psychanalyses","lemma":"psychanalyse","pos":"NOM"} ,
		{"word":"psychanalyste","word_nosc":"psychanalyste","lemma":"psychanalyste","pos":"NOM"} ,
		{"word":"psychanalystes","word_nosc":"psychanalystes","lemma":"psychanalyste","pos":"NOM"} ,
		{"word":"psychiatre","word_nosc":"psychiatre","lemma":"psychiatre","pos":"NOM"} ,
		{"word":"psychiatre-conseil","word_nosc":"psychiatre-conseil","lemma":"psychiatre-conseil","pos":"NOM"} ,
		{"word":"psychiatres","word_nosc":"psychiatres","lemma":"psychiatre","pos":"NOM"} ,
		{"word":"psychiatrie","word_nosc":"psychiatrie","lemma":"psychiatrie","pos":"NOM"} ,
		{"word":"psychisme","word_nosc":"psychisme","lemma":"psychisme","pos":"NOM"} ,
		{"word":"psychismes","word_nosc":"psychismes","lemma":"psychisme","pos":"NOM"} ,
		{"word":"psychochirurgie","word_nosc":"psychochirurgie","lemma":"psychochirurgie","pos":"NOM"} ,
		{"word":"psychodrame","word_nosc":"psychodrame","lemma":"psychodrame","pos":"NOM"} ,
		{"word":"psychokinésie","word_nosc":"psychokinesie","lemma":"psychokinésie","pos":"NOM"} ,
		{"word":"psycholinguistes","word_nosc":"psycholinguistes","lemma":"psycholinguiste","pos":"NOM"} ,
		{"word":"psychologie","word_nosc":"psychologie","lemma":"psychologie","pos":"NOM"} ,
		{"word":"psychologie-fiction","word_nosc":"psychologie-fiction","lemma":"psychologie-fiction","pos":"NOM"} ,
		{"word":"psychologies","word_nosc":"psychologies","lemma":"psychologie","pos":"NOM"} ,
		{"word":"psychologisation","word_nosc":"psychologisation","lemma":"psychologisation","pos":"NOM"} ,
		{"word":"psychologue","word_nosc":"psychologue","lemma":"psychologue","pos":"NOM"} ,
		{"word":"psychologues","word_nosc":"psychologues","lemma":"psychologue","pos":"NOM"} ,
		{"word":"psychonévrose","word_nosc":"psychonevrose","lemma":"psychonévrose","pos":"NOM"} ,
		{"word":"psychopathe","word_nosc":"psychopathe","lemma":"psychopathe","pos":"NOM"} ,
		{"word":"psychopathes","word_nosc":"psychopathes","lemma":"psychopathe","pos":"NOM"} ,
		{"word":"psychopathie","word_nosc":"psychopathie","lemma":"psychopathie","pos":"NOM"} ,
		{"word":"psychopathologie","word_nosc":"psychopathologie","lemma":"psychopathologie","pos":"NOM"} ,
		{"word":"psychopharmacologie","word_nosc":"psychopharmacologie","lemma":"psychopharmacologie","pos":"NOM"} ,
		{"word":"psychophysiologie","word_nosc":"psychophysiologie","lemma":"psychophysiologie","pos":"NOM"} ,
		{"word":"psychose","word_nosc":"psychose","lemma":"psychose","pos":"NOM"} ,
		{"word":"psychosociologie","word_nosc":"psychosociologie","lemma":"psychosociologie","pos":"NOM"} ,
		{"word":"psychosociologue","word_nosc":"psychosociologue","lemma":"psychosociologue","pos":"NOM"} ,
		{"word":"psychosomaticien","word_nosc":"psychosomaticien","lemma":"psychosomaticien","pos":"NOM"} ,
		{"word":"psychotechnicien","word_nosc":"psychotechnicien","lemma":"psychotechnicien","pos":"NOM"} ,
		{"word":"psychotechniciens","word_nosc":"psychotechniciens","lemma":"psychotechnicien","pos":"NOM"} ,
		{"word":"psychothérapeute","word_nosc":"psychotherapeute","lemma":"psychothérapeute","pos":"NOM"} ,
		{"word":"psychothérapie","word_nosc":"psychotherapie","lemma":"psychothérapie","pos":"NOM"} ,
		{"word":"psychotique","word_nosc":"psychotique","lemma":"psychotique","pos":"NOM"} ,
		{"word":"psychotoniques","word_nosc":"psychotoniques","lemma":"psychotonique","pos":"NOM"} ,
		{"word":"psychotrope","word_nosc":"psychotrope","lemma":"psychotrope","pos":"NOM"} ,
		{"word":"psychotropes","word_nosc":"psychotropes","lemma":"psychotrope","pos":"NOM"} ,
		{"word":"psyché","word_nosc":"psyche","lemma":"psyché","pos":"NOM"} ,
		{"word":"psychés","word_nosc":"psyches","lemma":"psyché","pos":"NOM"} ,
		{"word":"psyllium","word_nosc":"psyllium","lemma":"psyllium","pos":"NOM"} ,
		{"word":"psys","word_nosc":"psys","lemma":"psy","pos":"NOM"} ,
		{"word":"ptosis","word_nosc":"ptosis","lemma":"ptosis","pos":"NOM"} ,
		{"word":"ptyx","word_nosc":"ptyx","lemma":"ptyx","pos":"NOM"} ,
		{"word":"ptérodactyle","word_nosc":"pterodactyle","lemma":"ptérodactyle","pos":"NOM"} ,
		{"word":"ptérodactyles","word_nosc":"pterodactyles","lemma":"ptérodactyle","pos":"NOM"} ,
		{"word":"puanteur","word_nosc":"puanteur","lemma":"puanteur","pos":"NOM"} ,
		{"word":"puanteurs","word_nosc":"puanteurs","lemma":"puanteur","pos":"NOM"} ,
		{"word":"pub","word_nosc":"pub","lemma":"pub","pos":"NOM"} ,
		{"word":"puberté","word_nosc":"puberte","lemma":"puberté","pos":"NOM"} ,
		{"word":"pubis","word_nosc":"pubis","lemma":"pubis","pos":"NOM"} ,
		{"word":"public","word_nosc":"public","lemma":"public","pos":"NOM"} ,
		{"word":"public schools","word_nosc":"public schools","lemma":"public school","pos":"NOM"} ,
		{"word":"public-relations","word_nosc":"public-relations","lemma":"public-relations","pos":"NOM"} ,
		{"word":"publicain","word_nosc":"publicain","lemma":"publicain","pos":"NOM"} ,
		{"word":"publicains","word_nosc":"publicains","lemma":"publicain","pos":"NOM"} ,
		{"word":"publication","word_nosc":"publication","lemma":"publication","pos":"NOM"} ,
		{"word":"publications","word_nosc":"publications","lemma":"publication","pos":"NOM"} ,
		{"word":"publiciste","word_nosc":"publiciste","lemma":"publiciste","pos":"NOM"} ,
		{"word":"publicistes","word_nosc":"publicistes","lemma":"publiciste","pos":"NOM"} ,
		{"word":"publicitaire","word_nosc":"publicitaire","lemma":"publicitaire","pos":"NOM"} ,
		{"word":"publicitaires","word_nosc":"publicitaires","lemma":"publicitaire","pos":"NOM"} ,
		{"word":"publicité","word_nosc":"publicite","lemma":"publicité","pos":"NOM"} ,
		{"word":"publicités","word_nosc":"publicites","lemma":"publicité","pos":"NOM"} ,
		{"word":"publics","word_nosc":"publics","lemma":"public","pos":"NOM"} ,
		{"word":"publieur","word_nosc":"publieur","lemma":"publieur","pos":"NOM"} ,
		{"word":"publiphone","word_nosc":"publiphone","lemma":"publiphone","pos":"NOM"} ,
		{"word":"publireportage","word_nosc":"publireportage","lemma":"publireportage","pos":"NOM"} ,
		{"word":"pubs","word_nosc":"pubs","lemma":"pub","pos":"NOM"} ,
		{"word":"puce","word_nosc":"puce","lemma":"puce","pos":"NOM"} ,
		{"word":"puceau","word_nosc":"puceau","lemma":"puceau","pos":"NOM"} ,
		{"word":"puceaux","word_nosc":"puceaux","lemma":"puceau","pos":"NOM"} ,
		{"word":"pucelage","word_nosc":"pucelage","lemma":"pucelage","pos":"NOM"} ,
		{"word":"pucelages","word_nosc":"pucelages","lemma":"pucelage","pos":"NOM"} ,
		{"word":"pucelle","word_nosc":"pucelle","lemma":"puceau","pos":"NOM"} ,
		{"word":"pucelles","word_nosc":"pucelles","lemma":"puceau","pos":"NOM"} ,
		{"word":"puceron","word_nosc":"puceron","lemma":"puceron","pos":"NOM"} ,
		{"word":"pucerons","word_nosc":"pucerons","lemma":"puceron","pos":"NOM"} ,
		{"word":"puces","word_nosc":"puces","lemma":"puce","pos":"NOM"} ,
		{"word":"pucheux","word_nosc":"pucheux","lemma":"pucheux","pos":"NOM"} ,
		{"word":"pucier","word_nosc":"pucier","lemma":"pucier","pos":"NOM"} ,
		{"word":"puciers","word_nosc":"puciers","lemma":"pucier","pos":"NOM"} ,
		{"word":"pudding","word_nosc":"pudding","lemma":"pudding","pos":"NOM"} ,
		{"word":"puddings","word_nosc":"puddings","lemma":"pudding","pos":"NOM"} ,
		{"word":"pudeur","word_nosc":"pudeur","lemma":"pudeur","pos":"NOM"} ,
		{"word":"pudeurs","word_nosc":"pudeurs","lemma":"pudeur","pos":"NOM"} ,
		{"word":"pudibonderie","word_nosc":"pudibonderie","lemma":"pudibonderie","pos":"NOM"} ,
		{"word":"pudibonderies","word_nosc":"pudibonderies","lemma":"pudibonderie","pos":"NOM"} ,
		{"word":"pudicité","word_nosc":"pudicite","lemma":"pudicité","pos":"NOM"} ,
		{"word":"puff","word_nosc":"puff","lemma":"puff","pos":"NOM"} ,
		{"word":"puffin","word_nosc":"puffin","lemma":"puffin","pos":"NOM"} ,
		{"word":"pugilat","word_nosc":"pugilat","lemma":"pugilat","pos":"NOM"} ,
		{"word":"pugilats","word_nosc":"pugilats","lemma":"pugilat","pos":"NOM"} ,
		{"word":"pugiliste","word_nosc":"pugiliste","lemma":"pugiliste","pos":"NOM"} ,
		{"word":"pugilistes","word_nosc":"pugilistes","lemma":"pugiliste","pos":"NOM"} ,
		{"word":"pugnacité","word_nosc":"pugnacite","lemma":"pugnacité","pos":"NOM"} ,
		{"word":"puisard","word_nosc":"puisard","lemma":"puisard","pos":"NOM"} ,
		{"word":"puisards","word_nosc":"puisards","lemma":"puisard","pos":"NOM"} ,
		{"word":"puisatier","word_nosc":"puisatier","lemma":"puisatier","pos":"NOM"} ,
		{"word":"puisette","word_nosc":"puisette","lemma":"puisette","pos":"NOM"} ,
		{"word":"puissance","word_nosc":"puissance","lemma":"puissance","pos":"NOM"} ,
		{"word":"puissances","word_nosc":"puissances","lemma":"puissance","pos":"NOM"} ,
		{"word":"puissant","word_nosc":"puissant","lemma":"puissant","pos":"NOM"} ,
		{"word":"puissants","word_nosc":"puissants","lemma":"puissant","pos":"NOM"} ,
		{"word":"puits","word_nosc":"puits","lemma":"puits","pos":"NOM"} ,
		{"word":"pull","word_nosc":"pull","lemma":"pull","pos":"NOM"} ,
		{"word":"pull-over","word_nosc":"pull-over","lemma":"pull-over","pos":"NOM"} ,
		{"word":"pull-overs","word_nosc":"pull-overs","lemma":"pull-over","pos":"NOM"} ,
		{"word":"pullman","word_nosc":"pullman","lemma":"pullman","pos":"NOM"} ,
		{"word":"pullmans","word_nosc":"pullmans","lemma":"pullman","pos":"NOM"} ,
		{"word":"pulls","word_nosc":"pulls","lemma":"pull","pos":"NOM"} ,
		{"word":"pullulement","word_nosc":"pullulement","lemma":"pullulement","pos":"NOM"} ,
		{"word":"pulmonaire","word_nosc":"pulmonaire","lemma":"pulmonaire","pos":"NOM"} ,
		{"word":"pulmonaires","word_nosc":"pulmonaires","lemma":"pulmonaire","pos":"NOM"} ,
		{"word":"pulmonie","word_nosc":"pulmonie","lemma":"pulmonie","pos":"NOM"} ,
		{"word":"pulpe","word_nosc":"pulpe","lemma":"pulpe","pos":"NOM"} ,
		{"word":"pulpes","word_nosc":"pulpes","lemma":"pulpe","pos":"NOM"} ,
		{"word":"pulque","word_nosc":"pulque","lemma":"pulque","pos":"NOM"} ,
		{"word":"pulsar","word_nosc":"pulsar","lemma":"pulsar","pos":"NOM"} ,
		{"word":"pulsation","word_nosc":"pulsation","lemma":"pulsation","pos":"NOM"} ,
		{"word":"pulsations","word_nosc":"pulsations","lemma":"pulsation","pos":"NOM"} ,
		{"word":"pulseur","word_nosc":"pulseur","lemma":"pulseur","pos":"NOM"} ,
		{"word":"pulsion","word_nosc":"pulsion","lemma":"pulsion","pos":"NOM"} ,
		{"word":"pulsions","word_nosc":"pulsions","lemma":"pulsion","pos":"NOM"} ,
		{"word":"pulso-réacteurs","word_nosc":"pulso-reacteurs","lemma":"pulso-réacteur","pos":"NOM"} ,
		{"word":"pulsoréacteurs","word_nosc":"pulsoreacteurs","lemma":"pulsoréacteur","pos":"NOM"} ,
		{"word":"pulvérin","word_nosc":"pulverin","lemma":"pulvérin","pos":"NOM"} ,
		{"word":"pulvérisateur","word_nosc":"pulverisateur","lemma":"pulvérisateur","pos":"NOM"} ,
		{"word":"pulvérisateurs","word_nosc":"pulverisateurs","lemma":"pulvérisateur","pos":"NOM"} ,
		{"word":"pulvérisation","word_nosc":"pulverisation","lemma":"pulvérisation","pos":"NOM"} ,
		{"word":"pulvérisations","word_nosc":"pulverisations","lemma":"pulvérisation","pos":"NOM"} ,
		{"word":"pulvérulence","word_nosc":"pulverulence","lemma":"pulvérulence","pos":"NOM"} ,
		{"word":"puma","word_nosc":"puma","lemma":"puma","pos":"NOM"} ,
		{"word":"pumas","word_nosc":"pumas","lemma":"puma","pos":"NOM"} ,
		{"word":"punaise","word_nosc":"punaise","lemma":"punaise","pos":"NOM"} ,
		{"word":"punaises","word_nosc":"punaises","lemma":"punaise","pos":"NOM"} ,
		{"word":"punch","word_nosc":"punch","lemma":"punch","pos":"NOM"} ,
		{"word":"puncheur","word_nosc":"puncheur","lemma":"puncheur","pos":"NOM"} ,
		{"word":"punching ball","word_nosc":"punching ball","lemma":"punching-ball","pos":"NOM"} ,
		{"word":"punching-ball","word_nosc":"punching-ball","lemma":"punching-ball","pos":"NOM"} ,
		{"word":"punchs","word_nosc":"punchs","lemma":"punch","pos":"NOM"} ,
		{"word":"puncture","word_nosc":"puncture","lemma":"puncture","pos":"NOM"} ,
		{"word":"punisseur","word_nosc":"punisseur","lemma":"punisseur","pos":"NOM"} ,
		{"word":"punition","word_nosc":"punition","lemma":"punition","pos":"NOM"} ,
		{"word":"punitions","word_nosc":"punitions","lemma":"punition","pos":"NOM"} ,
		{"word":"punk","word_nosc":"punk","lemma":"punk","pos":"NOM"} ,
		{"word":"punkette","word_nosc":"punkette","lemma":"punkette","pos":"NOM"} ,
		{"word":"punks","word_nosc":"punks","lemma":"punk","pos":"NOM"} ,
		{"word":"punt","word_nosc":"punt","lemma":"punt","pos":"NOM"} ,
		{"word":"pupazzo","word_nosc":"pupazzo","lemma":"pupazzo","pos":"NOM"} ,
		{"word":"pupe","word_nosc":"pupe","lemma":"pupe","pos":"NOM"} ,
		{"word":"pupille","word_nosc":"pupille","lemma":"pupille","pos":"NOM"} ,
		{"word":"pupilles","word_nosc":"pupilles","lemma":"pupille","pos":"NOM"} ,
		{"word":"pupitre","word_nosc":"pupitre","lemma":"pupitre","pos":"NOM"} ,
		{"word":"pupitres","word_nosc":"pupitres","lemma":"pupitre","pos":"NOM"} ,
		{"word":"pupitreur","word_nosc":"pupitreur","lemma":"pupitreur","pos":"NOM"} ,
		{"word":"pur-sang","word_nosc":"pur-sang","lemma":"pur-sang","pos":"NOM"} ,
		{"word":"pureau","word_nosc":"pureau","lemma":"pureau","pos":"NOM"} ,
		{"word":"pureté","word_nosc":"purete","lemma":"pureté","pos":"NOM"} ,
		{"word":"puretés","word_nosc":"puretes","lemma":"pureté","pos":"NOM"} ,
		{"word":"purgatif","word_nosc":"purgatif","lemma":"purgatif","pos":"NOM"} ,
		{"word":"purgation","word_nosc":"purgation","lemma":"purgation","pos":"NOM"} ,
		{"word":"purgations","word_nosc":"purgations","lemma":"purgation","pos":"NOM"} ,
		{"word":"purgatoire","word_nosc":"purgatoire","lemma":"purgatoire","pos":"NOM"} ,
		{"word":"purgatoires","word_nosc":"purgatoires","lemma":"purgatoire","pos":"NOM"} ,
		{"word":"purge","word_nosc":"purge","lemma":"purge","pos":"NOM"} ,
		{"word":"purges","word_nosc":"purges","lemma":"purge","pos":"NOM"} ,
		{"word":"purificateur","word_nosc":"purificateur","lemma":"purificateur","pos":"NOM"} ,
		{"word":"purificateurs","word_nosc":"purificateurs","lemma":"purificateur","pos":"NOM"} ,
		{"word":"purification","word_nosc":"purification","lemma":"purification","pos":"NOM"} ,
		{"word":"purifications","word_nosc":"purifications","lemma":"purification","pos":"NOM"} ,
		{"word":"purin","word_nosc":"purin","lemma":"purin","pos":"NOM"} ,
		{"word":"puriste","word_nosc":"puriste","lemma":"puriste","pos":"NOM"} ,
		{"word":"puristes","word_nosc":"puristes","lemma":"puriste","pos":"NOM"} ,
		{"word":"puritain","word_nosc":"puritain","lemma":"puritain","pos":"NOM"} ,
		{"word":"puritaine","word_nosc":"puritaine","lemma":"puritain","pos":"NOM"} ,
		{"word":"puritaines","word_nosc":"puritaines","lemma":"puritain","pos":"NOM"} ,
		{"word":"puritains","word_nosc":"puritains","lemma":"puritain","pos":"NOM"} ,
		{"word":"puritanisme","word_nosc":"puritanisme","lemma":"puritanisme","pos":"NOM"} ,
		{"word":"purotin","word_nosc":"purotin","lemma":"purotin","pos":"NOM"} ,
		{"word":"purotins","word_nosc":"purotins","lemma":"purotin","pos":"NOM"} ,
		{"word":"purpura","word_nosc":"purpura","lemma":"purpura","pos":"NOM"} ,
		{"word":"purulence","word_nosc":"purulence","lemma":"purulence","pos":"NOM"} ,
		{"word":"purulences","word_nosc":"purulences","lemma":"purulence","pos":"NOM"} ,
		{"word":"purée","word_nosc":"puree","lemma":"purée","pos":"NOM"} ,
		{"word":"purées","word_nosc":"purees","lemma":"purée","pos":"NOM"} ,
		{"word":"pus","word_nosc":"pus","lemma":"pus","pos":"NOM"} ,
		{"word":"pusillanimité","word_nosc":"pusillanimite","lemma":"pusillanimité","pos":"NOM"} ,
		{"word":"pustule","word_nosc":"pustule","lemma":"pustule","pos":"NOM"} ,
		{"word":"pustules","word_nosc":"pustules","lemma":"pustule","pos":"NOM"} ,
		{"word":"putain","word_nosc":"putain","lemma":"putain","pos":"NOM"} ,
		{"word":"putains","word_nosc":"putains","lemma":"putain","pos":"NOM"} ,
		{"word":"putanat","word_nosc":"putanat","lemma":"putanat","pos":"NOM"} ,
		{"word":"putasserie","word_nosc":"putasserie","lemma":"putasserie","pos":"NOM"} ,
		{"word":"putasseries","word_nosc":"putasseries","lemma":"putasserie","pos":"NOM"} ,
		{"word":"pute","word_nosc":"pute","lemma":"pute","pos":"NOM"} ,
		{"word":"putes","word_nosc":"putes","lemma":"pute","pos":"NOM"} ,
		{"word":"putier","word_nosc":"putier","lemma":"putier","pos":"NOM"} ,
		{"word":"putois","word_nosc":"putois","lemma":"putois","pos":"NOM"} ,
		{"word":"putrescence","word_nosc":"putrescence","lemma":"putrescence","pos":"NOM"} ,
		{"word":"putrescences","word_nosc":"putrescences","lemma":"putrescence","pos":"NOM"} ,
		{"word":"putrescine","word_nosc":"putrescine","lemma":"putrescine","pos":"NOM"} ,
		{"word":"putréfaction","word_nosc":"putrefaction","lemma":"putréfaction","pos":"NOM"} ,
		{"word":"putsch","word_nosc":"putsch","lemma":"putsch","pos":"NOM"} ,
		{"word":"putt","word_nosc":"putt","lemma":"putt","pos":"NOM"} ,
		{"word":"putti","word_nosc":"putti","lemma":"putto","pos":"NOM"} ,
		{"word":"putting","word_nosc":"putting","lemma":"putting","pos":"NOM"} ,
		{"word":"puy","word_nosc":"puy","lemma":"puy","pos":"NOM"} ,
		{"word":"puzzle","word_nosc":"puzzle","lemma":"puzzle","pos":"NOM"} ,
		{"word":"puzzles","word_nosc":"puzzles","lemma":"puzzle","pos":"NOM"} ,
		{"word":"puéricultrice","word_nosc":"puericultrice","lemma":"puériculteur","pos":"NOM"} ,
		{"word":"puéricultrices","word_nosc":"puericultrices","lemma":"puériculteur","pos":"NOM"} ,
		{"word":"puériculture","word_nosc":"puericulture","lemma":"puériculture","pos":"NOM"} ,
		{"word":"puérilité","word_nosc":"puerilite","lemma":"puérilité","pos":"NOM"} ,
		{"word":"puérilités","word_nosc":"puerilites","lemma":"puérilité","pos":"NOM"} ,
		{"word":"puîné","word_nosc":"puine","lemma":"puîné","pos":"NOM"} ,
		{"word":"puînés","word_nosc":"puines","lemma":"puîné","pos":"NOM"} ,
		{"word":"pygmée","word_nosc":"pygmee","lemma":"pygmée","pos":"NOM"} ,
		{"word":"pygmées","word_nosc":"pygmees","lemma":"pygmée","pos":"NOM"} ,
		{"word":"pyjama","word_nosc":"pyjama","lemma":"pyjama","pos":"NOM"} ,
		{"word":"pyjamas","word_nosc":"pyjamas","lemma":"pyjama","pos":"NOM"} ,
		{"word":"pylore","word_nosc":"pylore","lemma":"pylore","pos":"NOM"} ,
		{"word":"pylône","word_nosc":"pylone","lemma":"pylône","pos":"NOM"} ,
		{"word":"pylônes","word_nosc":"pylones","lemma":"pylône","pos":"NOM"} ,
		{"word":"pyralène","word_nosc":"pyralene","lemma":"pyralène","pos":"NOM"} ,
		{"word":"pyrame","word_nosc":"pyrame","lemma":"pyrame","pos":"NOM"} ,
		{"word":"pyramide","word_nosc":"pyramide","lemma":"pyramide","pos":"NOM"} ,
		{"word":"pyramides","word_nosc":"pyramides","lemma":"pyramide","pos":"NOM"} ,
		{"word":"pyrex","word_nosc":"pyrex","lemma":"pyrex","pos":"NOM"} ,
		{"word":"pyrexie","word_nosc":"pyrexie","lemma":"pyrexie","pos":"NOM"} ,
		{"word":"pyridoxine","word_nosc":"pyridoxine","lemma":"pyridoxine","pos":"NOM"} ,
		{"word":"pyrite","word_nosc":"pyrite","lemma":"pyrite","pos":"NOM"} ,
		{"word":"pyrites","word_nosc":"pyrites","lemma":"pyrite","pos":"NOM"} ,
		{"word":"pyrolyse","word_nosc":"pyrolyse","lemma":"pyrolyse","pos":"NOM"} ,
		{"word":"pyromane","word_nosc":"pyromane","lemma":"pyromane","pos":"NOM"} ,
		{"word":"pyromanes","word_nosc":"pyromanes","lemma":"pyromane","pos":"NOM"} ,
		{"word":"pyromanie","word_nosc":"pyromanie","lemma":"pyromanie","pos":"NOM"} ,
		{"word":"pyromètre","word_nosc":"pyrometre","lemma":"pyromètre","pos":"NOM"} ,
		{"word":"pyrosphère","word_nosc":"pyrosphere","lemma":"pyrosphère","pos":"NOM"} ,
		{"word":"pyrotechnicien","word_nosc":"pyrotechnicien","lemma":"pyrotechnicien","pos":"NOM"} ,
		{"word":"pyrotechnie","word_nosc":"pyrotechnie","lemma":"pyrotechnie","pos":"NOM"} ,
		{"word":"pyroxène","word_nosc":"pyroxene","lemma":"pyroxène","pos":"NOM"} ,
		{"word":"pyrrhique","word_nosc":"pyrrhique","lemma":"pyrrhique","pos":"NOM"} ,
		{"word":"pyrrhonien","word_nosc":"pyrrhonien","lemma":"pyrrhonien","pos":"NOM"} ,
		{"word":"pyrrhonisme","word_nosc":"pyrrhonisme","lemma":"pyrrhonisme","pos":"NOM"} ,
		{"word":"pyrèthre","word_nosc":"pyrethre","lemma":"pyrèthre","pos":"NOM"} ,
		{"word":"pyréthrine","word_nosc":"pyrethrine","lemma":"pyréthrine","pos":"NOM"} ,
		{"word":"pythie","word_nosc":"pythie","lemma":"pythie","pos":"NOM"} ,
		{"word":"pythies","word_nosc":"pythies","lemma":"pythie","pos":"NOM"} ,
		{"word":"python","word_nosc":"python","lemma":"python","pos":"NOM"} ,
		{"word":"pythonisse","word_nosc":"pythonisse","lemma":"pythonisse","pos":"NOM"} ,
		{"word":"pythons","word_nosc":"pythons","lemma":"python","pos":"NOM"} ,
		{"word":"pyxides","word_nosc":"pyxides","lemma":"pyxide","pos":"NOM"} ,
		{"word":"pâleur","word_nosc":"paleur","lemma":"pâleur","pos":"NOM"} ,
		{"word":"pâleurs","word_nosc":"paleurs","lemma":"pâleur","pos":"NOM"} ,
		{"word":"pâmoison","word_nosc":"pamoison","lemma":"pâmoison","pos":"NOM"} ,
		{"word":"pâmoisons","word_nosc":"pamoisons","lemma":"pâmoison","pos":"NOM"} ,
		{"word":"pâque","word_nosc":"paque","lemma":"pâque","pos":"NOM"} ,
		{"word":"pâquerette","word_nosc":"paquerette","lemma":"pâquerette","pos":"NOM"} ,
		{"word":"pâquerettes","word_nosc":"paquerettes","lemma":"pâquerette","pos":"NOM"} ,
		{"word":"pâques","word_nosc":"paques","lemma":"pâques","pos":"NOM"} ,
		{"word":"pâquis","word_nosc":"paquis","lemma":"pâquis","pos":"NOM"} ,
		{"word":"pât","word_nosc":"pat","lemma":"pâte","pos":"NOM"} ,
		{"word":"pâte","word_nosc":"pate","lemma":"pâte","pos":"NOM"} ,
		{"word":"pâtes","word_nosc":"pates","lemma":"pâte","pos":"NOM"} ,
		{"word":"pâtis","word_nosc":"patis","lemma":"pâtis","pos":"NOM"} ,
		{"word":"pâtisserie","word_nosc":"patisserie","lemma":"pâtisserie","pos":"NOM"} ,
		{"word":"pâtisseries","word_nosc":"patisseries","lemma":"pâtisserie","pos":"NOM"} ,
		{"word":"pâtissier","word_nosc":"patissier","lemma":"pâtissier","pos":"NOM"} ,
		{"word":"pâtissiers","word_nosc":"patissiers","lemma":"pâtissier","pos":"NOM"} ,
		{"word":"pâtissière","word_nosc":"patissiere","lemma":"pâtissier","pos":"NOM"} ,
		{"word":"pâton","word_nosc":"paton","lemma":"pâton","pos":"NOM"} ,
		{"word":"pâtons","word_nosc":"patons","lemma":"pâton","pos":"NOM"} ,
		{"word":"pâtour","word_nosc":"patour","lemma":"pâtour","pos":"NOM"} ,
		{"word":"pâtre","word_nosc":"patre","lemma":"pâtre","pos":"NOM"} ,
		{"word":"pâtres","word_nosc":"patres","lemma":"pâtre","pos":"NOM"} ,
		{"word":"pâturage","word_nosc":"paturage","lemma":"pâturage","pos":"NOM"} ,
		{"word":"pâturages","word_nosc":"paturages","lemma":"pâturage","pos":"NOM"} ,
		{"word":"pâture","word_nosc":"pature","lemma":"pâture","pos":"NOM"} ,
		{"word":"pâtures","word_nosc":"patures","lemma":"pâture","pos":"NOM"} ,
		{"word":"pâturin","word_nosc":"paturin","lemma":"pâturin","pos":"NOM"} ,
		{"word":"pâté","word_nosc":"pate","lemma":"pâté","pos":"NOM"} ,
		{"word":"pâtée","word_nosc":"patee","lemma":"pâtée","pos":"NOM"} ,
		{"word":"pâtées","word_nosc":"patees","lemma":"pâtée","pos":"NOM"} ,
		{"word":"pâtés","word_nosc":"pates","lemma":"pâté","pos":"NOM"} ,
		{"word":"pègre","word_nosc":"pegre","lemma":"pègre","pos":"NOM"} ,
		{"word":"pèlerin","word_nosc":"pelerin","lemma":"pèlerin","pos":"NOM"} ,
		{"word":"pèlerinage","word_nosc":"pelerinage","lemma":"pèlerinage","pos":"NOM"} ,
		{"word":"pèlerinages","word_nosc":"pelerinages","lemma":"pèlerinage","pos":"NOM"} ,
		{"word":"pèlerine","word_nosc":"pelerine","lemma":"pèlerin","pos":"NOM"} ,
		{"word":"pèlerines","word_nosc":"pelerines","lemma":"pèlerin","pos":"NOM"} ,
		{"word":"pèlerins","word_nosc":"pelerins","lemma":"pèlerin","pos":"NOM"} ,
		{"word":"père","word_nosc":"pere","lemma":"père","pos":"NOM"} ,
		{"word":"pères","word_nosc":"peres","lemma":"père","pos":"NOM"} ,
		{"word":"pèse-acide","word_nosc":"pese-acide","lemma":"pèse-acide","pos":"NOM"} ,
		{"word":"pèse-bébé","word_nosc":"pese-bebe","lemma":"pèse-bébé","pos":"NOM"} ,
		{"word":"pèse-lettre","word_nosc":"pese-lettre","lemma":"pèse-lettre","pos":"NOM"} ,
		{"word":"pèse-personne","word_nosc":"pese-personne","lemma":"pèse-personne","pos":"NOM"} ,
		{"word":"pèse-personnes","word_nosc":"pese-personnes","lemma":"pèse-personne","pos":"NOM"} ,
		{"word":"pèze","word_nosc":"peze","lemma":"pèze","pos":"NOM"} ,
		{"word":"péage","word_nosc":"peage","lemma":"péage","pos":"NOM"} ,
		{"word":"péages","word_nosc":"peages","lemma":"péage","pos":"NOM"} ,
		{"word":"péagiste","word_nosc":"peagiste","lemma":"péagiste","pos":"NOM"} ,
		{"word":"péan","word_nosc":"pean","lemma":"péan","pos":"NOM"} ,
		{"word":"pébroc","word_nosc":"pebroc","lemma":"pébroc","pos":"NOM"} ,
		{"word":"pébroque","word_nosc":"pebroque","lemma":"pébroque","pos":"NOM"} ,
		{"word":"pébroques","word_nosc":"pebroques","lemma":"pébroque","pos":"NOM"} ,
		{"word":"pécan","word_nosc":"pecan","lemma":"pécan","pos":"NOM"} ,
		{"word":"pécari","word_nosc":"pecari","lemma":"pécari","pos":"NOM"} ,
		{"word":"pécaris","word_nosc":"pecaris","lemma":"pécari","pos":"NOM"} ,
		{"word":"pécheresse","word_nosc":"pecheresse","lemma":"pécheur","pos":"NOM"} ,
		{"word":"pécheresses","word_nosc":"pecheresses","lemma":"pécheur","pos":"NOM"} ,
		{"word":"pécheur","word_nosc":"pecheur","lemma":"pécheur","pos":"NOM"} ,
		{"word":"pécheurs","word_nosc":"pecheurs","lemma":"pécheur","pos":"NOM"} ,
		{"word":"péchons","word_nosc":"pechons","lemma":"péchon","pos":"NOM"} ,
		{"word":"péché","word_nosc":"peche","lemma":"péche","pos":"NOM"} ,
		{"word":"péché","word_nosc":"peche","lemma":"péché","pos":"NOM"} ,
		{"word":"péchés","word_nosc":"peches","lemma":"péché","pos":"NOM"} ,
		{"word":"pécore","word_nosc":"pecore","lemma":"pécore","pos":"NOM"} ,
		{"word":"pécores","word_nosc":"pecores","lemma":"pécore","pos":"NOM"} ,
		{"word":"pécule","word_nosc":"pecule","lemma":"pécule","pos":"NOM"} ,
		{"word":"pécules","word_nosc":"pecules","lemma":"pécule","pos":"NOM"} ,
		{"word":"pécune","word_nosc":"pecune","lemma":"pécune","pos":"NOM"} ,
		{"word":"pédagogie","word_nosc":"pedagogie","lemma":"pédagogie","pos":"NOM"} ,
		{"word":"pédagogue","word_nosc":"pedagogue","lemma":"pédagogue","pos":"NOM"} ,
		{"word":"pédagogues","word_nosc":"pedagogues","lemma":"pédagogue","pos":"NOM"} ,
		{"word":"pédalage","word_nosc":"pedalage","lemma":"pédalage","pos":"NOM"} ,
		{"word":"pédale","word_nosc":"pedale","lemma":"pédal","pos":"NOM"} ,
		{"word":"pédale","word_nosc":"pedale","lemma":"pédale","pos":"NOM"} ,
		{"word":"pédales","word_nosc":"pedales","lemma":"pédal","pos":"NOM"} ,
		{"word":"pédales","word_nosc":"pedales","lemma":"pédale","pos":"NOM"} ,
		{"word":"pédaleur","word_nosc":"pedaleur","lemma":"pédaleur","pos":"NOM"} ,
		{"word":"pédaleuses","word_nosc":"pedaleuses","lemma":"pédaleur","pos":"NOM"} ,
		{"word":"pédalier","word_nosc":"pedalier","lemma":"pédalier","pos":"NOM"} ,
		{"word":"pédaliers","word_nosc":"pedaliers","lemma":"pédalier","pos":"NOM"} ,
		{"word":"pédalo","word_nosc":"pedalo","lemma":"pédalo","pos":"NOM"} ,
		{"word":"pédalos","word_nosc":"pedalos","lemma":"pédalo","pos":"NOM"} ,
		{"word":"pédant","word_nosc":"pedant","lemma":"pédant","pos":"NOM"} ,
		{"word":"pédante","word_nosc":"pedante","lemma":"pédant","pos":"NOM"} ,
		{"word":"pédanterie","word_nosc":"pedanterie","lemma":"pédanterie","pos":"NOM"} ,
		{"word":"pédanteries","word_nosc":"pedanteries","lemma":"pédanterie","pos":"NOM"} ,
		{"word":"pédantes","word_nosc":"pedantes","lemma":"pédant","pos":"NOM"} ,
		{"word":"pédantisme","word_nosc":"pedantisme","lemma":"pédantisme","pos":"NOM"} ,
		{"word":"pédants","word_nosc":"pedants","lemma":"pédant","pos":"NOM"} ,
		{"word":"pédezouille","word_nosc":"pedezouille","lemma":"pédezouille","pos":"NOM"} ,
		{"word":"pédiatre","word_nosc":"pediatre","lemma":"pédiatre","pos":"NOM"} ,
		{"word":"pédiatres","word_nosc":"pediatres","lemma":"pédiatre","pos":"NOM"} ,
		{"word":"pédiatrie","word_nosc":"pediatrie","lemma":"pédiatrie","pos":"NOM"} ,
		{"word":"pédicule","word_nosc":"pedicule","lemma":"pédicule","pos":"NOM"} ,
		{"word":"pédicure","word_nosc":"pedicure","lemma":"pédicure","pos":"NOM"} ,
		{"word":"pédicures","word_nosc":"pedicures","lemma":"pédicure","pos":"NOM"} ,
		{"word":"pédicurie","word_nosc":"pedicurie","lemma":"pédicurie","pos":"NOM"} ,
		{"word":"pédologue","word_nosc":"pedologue","lemma":"pédologue","pos":"NOM"} ,
		{"word":"pédomètre","word_nosc":"pedometre","lemma":"pédomètre","pos":"NOM"} ,
		{"word":"pédoncule","word_nosc":"pedoncule","lemma":"pédoncule","pos":"NOM"} ,
		{"word":"pédoncules","word_nosc":"pedoncules","lemma":"pédoncule","pos":"NOM"} ,
		{"word":"pédophile","word_nosc":"pedophile","lemma":"pédophile","pos":"NOM"} ,
		{"word":"pédophiles","word_nosc":"pedophiles","lemma":"pédophile","pos":"NOM"} ,
		{"word":"pédophilie","word_nosc":"pedophilie","lemma":"pédophilie","pos":"NOM"} ,
		{"word":"pédopsychiatre","word_nosc":"pedopsychiatre","lemma":"pédopsychiatre","pos":"NOM"} ,
		{"word":"pédoque","word_nosc":"pedoque","lemma":"pédoque","pos":"NOM"} ,
		{"word":"pédé","word_nosc":"pede","lemma":"pédé","pos":"NOM"} ,
		{"word":"pédégé","word_nosc":"pedege","lemma":"pédégé","pos":"NOM"} ,
		{"word":"pédéraste","word_nosc":"pederaste","lemma":"pédéraste","pos":"NOM"} ,
		{"word":"pédérastes","word_nosc":"pederastes","lemma":"pédéraste","pos":"NOM"} ,
		{"word":"pédérastie","word_nosc":"pederastie","lemma":"pédérastie","pos":"NOM"} ,
		{"word":"pédés","word_nosc":"pedes","lemma":"pédé","pos":"NOM"} ,
		{"word":"pégamoïd","word_nosc":"pegamoid","lemma":"pégamoïd","pos":"NOM"} ,
		{"word":"pégriot","word_nosc":"pegriot","lemma":"pégriot","pos":"NOM"} ,
		{"word":"pégriots","word_nosc":"pegriots","lemma":"pégriot","pos":"NOM"} ,
		{"word":"pékan","word_nosc":"pekan","lemma":"pékan","pos":"NOM"} ,
		{"word":"pékin","word_nosc":"pekin","lemma":"pékin","pos":"NOM"} ,
		{"word":"pékinois","word_nosc":"pekinois","lemma":"pékinois","pos":"NOM"} ,
		{"word":"pékins","word_nosc":"pekins","lemma":"pékin","pos":"NOM"} ,
		{"word":"pélican","word_nosc":"pelican","lemma":"pélican","pos":"NOM"} ,
		{"word":"pélicans","word_nosc":"pelicans","lemma":"pélican","pos":"NOM"} ,
		{"word":"pélot","word_nosc":"pelot","lemma":"pélot","pos":"NOM"} ,
		{"word":"pénalisation","word_nosc":"penalisation","lemma":"pénalisation","pos":"NOM"} ,
		{"word":"pénaliste","word_nosc":"penaliste","lemma":"pénaliste","pos":"NOM"} ,
		{"word":"pénalistes","word_nosc":"penalistes","lemma":"pénaliste","pos":"NOM"} ,
		{"word":"pénalité","word_nosc":"penalite","lemma":"pénalité","pos":"NOM"} ,
		{"word":"pénalités","word_nosc":"penalites","lemma":"pénalité","pos":"NOM"} ,
		{"word":"pénates","word_nosc":"penates","lemma":"pénates","pos":"NOM"} ,
		{"word":"péniche","word_nosc":"peniche","lemma":"péniche","pos":"NOM"} ,
		{"word":"péniches","word_nosc":"peniches","lemma":"péniche","pos":"NOM"} ,
		{"word":"pénicilline","word_nosc":"penicilline","lemma":"pénicilline","pos":"NOM"} ,
		{"word":"pénil","word_nosc":"penil","lemma":"pénil","pos":"NOM"} ,
		{"word":"péninsule","word_nosc":"peninsule","lemma":"péninsule","pos":"NOM"} ,
		{"word":"péninsules","word_nosc":"peninsules","lemma":"péninsule","pos":"NOM"} ,
		{"word":"pénis","word_nosc":"penis","lemma":"pénis","pos":"NOM"} ,
		{"word":"pénitence","word_nosc":"penitence","lemma":"pénitence","pos":"NOM"} ,
		{"word":"pénitences","word_nosc":"penitences","lemma":"pénitence","pos":"NOM"} ,
		{"word":"pénitencier","word_nosc":"penitencier","lemma":"pénitencier","pos":"NOM"} ,
		{"word":"pénitenciers","word_nosc":"penitenciers","lemma":"pénitencier","pos":"NOM"} ,
		{"word":"pénitent","word_nosc":"penitent","lemma":"pénitent","pos":"NOM"} ,
		{"word":"pénitente","word_nosc":"penitente","lemma":"pénitent","pos":"NOM"} ,
		{"word":"pénitentes","word_nosc":"penitentes","lemma":"pénitent","pos":"NOM"} ,
		{"word":"pénitents","word_nosc":"penitents","lemma":"pénitent","pos":"NOM"} ,
		{"word":"pénologie","word_nosc":"penologie","lemma":"pénologie","pos":"NOM"} ,
		{"word":"pénombre","word_nosc":"penombre","lemma":"pénombre","pos":"NOM"} ,
		{"word":"pénombres","word_nosc":"penombres","lemma":"pénombre","pos":"NOM"} ,
		{"word":"pénultième","word_nosc":"penultieme","lemma":"pénultième","pos":"NOM"} ,
		{"word":"pénurie","word_nosc":"penurie","lemma":"pénurie","pos":"NOM"} ,
		{"word":"pénuries","word_nosc":"penuries","lemma":"pénurie","pos":"NOM"} ,
		{"word":"pénéplaines","word_nosc":"peneplaines","lemma":"pénéplaine","pos":"NOM"} ,
		{"word":"pénétrantes","word_nosc":"penetrantes","lemma":"pénétrant","pos":"NOM"} ,
		{"word":"pénétration","word_nosc":"penetration","lemma":"pénétration","pos":"NOM"} ,
		{"word":"pénétrations","word_nosc":"penetrations","lemma":"pénétration","pos":"NOM"} ,
		{"word":"péon","word_nosc":"peon","lemma":"péon","pos":"NOM"} ,
		{"word":"péons","word_nosc":"peons","lemma":"péon","pos":"NOM"} ,
		{"word":"péottes","word_nosc":"peottes","lemma":"péotte","pos":"NOM"} ,
		{"word":"pépettes","word_nosc":"pepettes","lemma":"pépettes","pos":"NOM"} ,
		{"word":"pépie","word_nosc":"pepie","lemma":"pépie","pos":"NOM"} ,
		{"word":"pépiement","word_nosc":"pepiement","lemma":"pépiement","pos":"NOM"} ,
		{"word":"pépiements","word_nosc":"pepiements","lemma":"pépiement","pos":"NOM"} ,
		{"word":"pépin","word_nosc":"pepin","lemma":"pépin","pos":"NOM"} ,
		{"word":"pépinière","word_nosc":"pepiniere","lemma":"pépinière","pos":"NOM"} ,
		{"word":"pépinières","word_nosc":"pepinieres","lemma":"pépinière","pos":"NOM"} ,
		{"word":"pépiniériste","word_nosc":"pepinieriste","lemma":"pépiniériste","pos":"NOM"} ,
		{"word":"pépiniéristes","word_nosc":"pepinieristes","lemma":"pépiniériste","pos":"NOM"} ,
		{"word":"pépins","word_nosc":"pepins","lemma":"pépin","pos":"NOM"} ,
		{"word":"pépite","word_nosc":"pepite","lemma":"pépite","pos":"NOM"} ,
		{"word":"pépites","word_nosc":"pepites","lemma":"pépite","pos":"NOM"} ,
		{"word":"péplum","word_nosc":"peplum","lemma":"péplum","pos":"NOM"} ,
		{"word":"péplums","word_nosc":"peplums","lemma":"péplum","pos":"NOM"} ,
		{"word":"pépère","word_nosc":"pepere","lemma":"pépère","pos":"NOM"} ,
		{"word":"pépères","word_nosc":"peperes","lemma":"pépère","pos":"NOM"} ,
		{"word":"pépètes","word_nosc":"pepetes","lemma":"pépètes","pos":"NOM"} ,
		{"word":"pépé","word_nosc":"pepe","lemma":"pépé","pos":"NOM"} ,
		{"word":"pépée","word_nosc":"pepee","lemma":"pépée","pos":"NOM"} ,
		{"word":"pépées","word_nosc":"pepees","lemma":"pépée","pos":"NOM"} ,
		{"word":"pépés","word_nosc":"pepes","lemma":"pépé","pos":"NOM"} ,
		{"word":"péquenaud","word_nosc":"pequenaud","lemma":"péquenaud","pos":"NOM"} ,
		{"word":"péquenaude","word_nosc":"pequenaude","lemma":"péquenaud","pos":"NOM"} ,
		{"word":"péquenaudes","word_nosc":"pequenaudes","lemma":"péquenaud","pos":"NOM"} ,
		{"word":"péquenauds","word_nosc":"pequenauds","lemma":"péquenaud","pos":"NOM"} ,
		{"word":"péquenot","word_nosc":"pequenot","lemma":"péquenot","pos":"NOM"} ,
		{"word":"péquenots","word_nosc":"pequenots","lemma":"péquenot","pos":"NOM"} ,
		{"word":"péquin","word_nosc":"pequin","lemma":"péquin","pos":"NOM"} ,
		{"word":"péquins","word_nosc":"pequins","lemma":"péquin","pos":"NOM"} ,
		{"word":"pérennité","word_nosc":"perennite","lemma":"pérennité","pos":"NOM"} ,
		{"word":"péricarde","word_nosc":"pericarde","lemma":"péricarde","pos":"NOM"} ,
		{"word":"péricardite","word_nosc":"pericardite","lemma":"péricardite","pos":"NOM"} ,
		{"word":"péricarpe","word_nosc":"pericarpe","lemma":"péricarpe","pos":"NOM"} ,
		{"word":"péridurale","word_nosc":"peridurale","lemma":"péridural","pos":"NOM"} ,
		{"word":"périf","word_nosc":"perif","lemma":"périf","pos":"NOM"} ,
		{"word":"périgée","word_nosc":"perigee","lemma":"périgée","pos":"NOM"} ,
		{"word":"péril","word_nosc":"peril","lemma":"péril","pos":"NOM"} ,
		{"word":"périls","word_nosc":"perils","lemma":"péril","pos":"NOM"} ,
		{"word":"périmètre","word_nosc":"perimetre","lemma":"périmètre","pos":"NOM"} ,
		{"word":"périmètres","word_nosc":"perimetres","lemma":"périmètre","pos":"NOM"} ,
		{"word":"périnée","word_nosc":"perinee","lemma":"périnée","pos":"NOM"} ,
		{"word":"période","word_nosc":"periode","lemma":"période","pos":"NOM"} ,
		{"word":"périodes","word_nosc":"periodes","lemma":"période","pos":"NOM"} ,
		{"word":"périodicité","word_nosc":"periodicite","lemma":"périodicité","pos":"NOM"} ,
		{"word":"périodique","word_nosc":"periodique","lemma":"périodique","pos":"NOM"} ,
		{"word":"périodiques","word_nosc":"periodiques","lemma":"périodique","pos":"NOM"} ,
		{"word":"péripatéticiennes","word_nosc":"peripateticiennes","lemma":"péripatéticien","pos":"NOM"} ,
		{"word":"péripatéticiennes","word_nosc":"peripateticiennes","lemma":"péripatéticienne","pos":"NOM"} ,
		{"word":"périph","word_nosc":"periph","lemma":"périph","pos":"NOM"} ,
		{"word":"périphrase","word_nosc":"periphrase","lemma":"périphrase","pos":"NOM"} ,
		{"word":"périphrases","word_nosc":"periphrases","lemma":"périphrase","pos":"NOM"} ,
		{"word":"périphérie","word_nosc":"peripherie","lemma":"périphérie","pos":"NOM"} ,
		{"word":"périphéries","word_nosc":"peripheries","lemma":"périphérie","pos":"NOM"} ,
		{"word":"périphérique","word_nosc":"peripherique","lemma":"périphérique","pos":"NOM"} ,
		{"word":"périphériques","word_nosc":"peripheriques","lemma":"périphérique","pos":"NOM"} ,
		{"word":"périple","word_nosc":"periple","lemma":"périple","pos":"NOM"} ,
		{"word":"périples","word_nosc":"periples","lemma":"périple","pos":"NOM"} ,
		{"word":"péripétie","word_nosc":"peripetie","lemma":"péripétie","pos":"NOM"} ,
		{"word":"péripéties","word_nosc":"peripeties","lemma":"péripétie","pos":"NOM"} ,
		{"word":"périscope","word_nosc":"periscope","lemma":"périscope","pos":"NOM"} ,
		{"word":"périscopes","word_nosc":"periscopes","lemma":"périscope","pos":"NOM"} ,
		{"word":"périssodactyle","word_nosc":"perissodactyle","lemma":"périssodactyle","pos":"NOM"} ,
		{"word":"périssoire","word_nosc":"perissoire","lemma":"périssoire","pos":"NOM"} ,
		{"word":"périssoires","word_nosc":"perissoires","lemma":"périssoire","pos":"NOM"} ,
		{"word":"péristaltisme","word_nosc":"peristaltisme","lemma":"péristaltisme","pos":"NOM"} ,
		{"word":"péristyle","word_nosc":"peristyle","lemma":"péristyle","pos":"NOM"} ,
		{"word":"péritoine","word_nosc":"peritoine","lemma":"péritoine","pos":"NOM"} ,
		{"word":"péritonite","word_nosc":"peritonite","lemma":"péritonite","pos":"NOM"} ,
		{"word":"pérodictique","word_nosc":"perodictique","lemma":"pérodictique","pos":"NOM"} ,
		{"word":"péroniste","word_nosc":"peroniste","lemma":"péroniste","pos":"NOM"} ,
		{"word":"péronnelle","word_nosc":"peronnelle","lemma":"péronnelle","pos":"NOM"} ,
		{"word":"péroné","word_nosc":"perone","lemma":"péroné","pos":"NOM"} ,
		{"word":"péronés","word_nosc":"perones","lemma":"péroné","pos":"NOM"} ,
		{"word":"péroraison","word_nosc":"peroraison","lemma":"péroraison","pos":"NOM"} ,
		{"word":"péroraisons","word_nosc":"peroraisons","lemma":"péroraison","pos":"NOM"} ,
		{"word":"péroreur","word_nosc":"peroreur","lemma":"péroreur","pos":"NOM"} ,
		{"word":"pérot","word_nosc":"perot","lemma":"pérot","pos":"NOM"} ,
		{"word":"pérou","word_nosc":"perou","lemma":"pérou","pos":"NOM"} ,
		{"word":"pérégrination","word_nosc":"peregrination","lemma":"pérégrination","pos":"NOM"} ,
		{"word":"pérégrinations","word_nosc":"peregrinations","lemma":"pérégrination","pos":"NOM"} ,
		{"word":"péréquation","word_nosc":"perequation","lemma":"péréquation","pos":"NOM"} ,
		{"word":"péréquations","word_nosc":"perequations","lemma":"péréquation","pos":"NOM"} ,
		{"word":"pétainiste","word_nosc":"petainiste","lemma":"pétainiste","pos":"NOM"} ,
		{"word":"pétainistes","word_nosc":"petainistes","lemma":"pétainiste","pos":"NOM"} ,
		{"word":"pétale","word_nosc":"petale","lemma":"pétale","pos":"NOM"} ,
		{"word":"pétales","word_nosc":"petales","lemma":"pétale","pos":"NOM"} ,
		{"word":"pétanque","word_nosc":"petanque","lemma":"pétanque","pos":"NOM"} ,
		{"word":"pétarade","word_nosc":"petarade","lemma":"pétarade","pos":"NOM"} ,
		{"word":"pétarades","word_nosc":"petarades","lemma":"pétarade","pos":"NOM"} ,
		{"word":"pétard","word_nosc":"petard","lemma":"pétard","pos":"NOM"} ,
		{"word":"pétardier","word_nosc":"petardier","lemma":"pétardier","pos":"NOM"} ,
		{"word":"pétardière","word_nosc":"petardiere","lemma":"pétardier","pos":"NOM"} ,
		{"word":"pétards","word_nosc":"petards","lemma":"pétard","pos":"NOM"} ,
		{"word":"pétase","word_nosc":"petase","lemma":"pétase","pos":"NOM"} ,
		{"word":"pétasse","word_nosc":"petasse","lemma":"pétasse","pos":"NOM"} ,
		{"word":"pétasses","word_nosc":"petasses","lemma":"pétasse","pos":"NOM"} ,
		{"word":"pétaudière","word_nosc":"petaudiere","lemma":"pétaudière","pos":"NOM"} ,
		{"word":"péteur","word_nosc":"peteur","lemma":"péteur","pos":"NOM"} ,
		{"word":"péteuse","word_nosc":"peteuse","lemma":"péteuse","pos":"NOM"} ,
		{"word":"péteux","word_nosc":"peteux","lemma":"péteux","pos":"NOM"} ,
		{"word":"pétillement","word_nosc":"petillement","lemma":"pétillement","pos":"NOM"} ,
		{"word":"pétillements","word_nosc":"petillements","lemma":"pétillement","pos":"NOM"} ,
		{"word":"pétiole","word_nosc":"petiole","lemma":"pétiole","pos":"NOM"} ,
		{"word":"pétition","word_nosc":"petition","lemma":"pétition","pos":"NOM"} ,
		{"word":"pétitionnaires","word_nosc":"petitionnaires","lemma":"pétitionnaire","pos":"NOM"} ,
		{"word":"pétitions","word_nosc":"petitions","lemma":"pétition","pos":"NOM"} ,
		{"word":"pétoche","word_nosc":"petoche","lemma":"pétoche","pos":"NOM"} ,
		{"word":"pétoches","word_nosc":"petoches","lemma":"pétoche","pos":"NOM"} ,
		{"word":"pétoire","word_nosc":"petoire","lemma":"pétoire","pos":"NOM"} ,
		{"word":"pétoires","word_nosc":"petoires","lemma":"pétoire","pos":"NOM"} ,
		{"word":"pétomane","word_nosc":"petomane","lemma":"pétomane","pos":"NOM"} ,
		{"word":"pétomanes","word_nosc":"petomanes","lemma":"pétomane","pos":"NOM"} ,
		{"word":"pétoncle","word_nosc":"petoncle","lemma":"pétoncle","pos":"NOM"} ,
		{"word":"pétoncles","word_nosc":"petoncles","lemma":"pétoncle","pos":"NOM"} ,
		{"word":"pétoulet","word_nosc":"petoulet","lemma":"pétoulet","pos":"NOM"} ,
		{"word":"pétrarquistes","word_nosc":"petrarquistes","lemma":"pétrarquiste","pos":"NOM"} ,
		{"word":"pétrel","word_nosc":"petrel","lemma":"pétrel","pos":"NOM"} ,
		{"word":"pétrification","word_nosc":"petrification","lemma":"pétrification","pos":"NOM"} ,
		{"word":"pétrin","word_nosc":"petrin","lemma":"pétrin","pos":"NOM"} ,
		{"word":"pétrins","word_nosc":"petrins","lemma":"pétrin","pos":"NOM"} ,
		{"word":"pétrissage","word_nosc":"petrissage","lemma":"pétrissage","pos":"NOM"} ,
		{"word":"pétrisseur","word_nosc":"petrisseur","lemma":"pétrisseur","pos":"NOM"} ,
		{"word":"pétrochimie","word_nosc":"petrochimie","lemma":"pétrochimie","pos":"NOM"} ,
		{"word":"pétrodollars","word_nosc":"petrodollars","lemma":"pétrodollar","pos":"NOM"} ,
		{"word":"pétrole","word_nosc":"petrole","lemma":"pétrole","pos":"NOM"} ,
		{"word":"pétroles","word_nosc":"petroles","lemma":"pétrole","pos":"NOM"} ,
		{"word":"pétrolette","word_nosc":"petrolette","lemma":"pétrolette","pos":"NOM"} ,
		{"word":"pétroleuse","word_nosc":"petroleuse","lemma":"pétroleur","pos":"NOM"} ,
		{"word":"pétroleuses","word_nosc":"petroleuses","lemma":"pétroleur","pos":"NOM"} ,
		{"word":"pétrolier","word_nosc":"petrolier","lemma":"pétrolier","pos":"NOM"} ,
		{"word":"pétroliers","word_nosc":"petroliers","lemma":"pétrolier","pos":"NOM"} ,
		{"word":"pétrousquin","word_nosc":"petrousquin","lemma":"pétrousquin","pos":"NOM"} ,
		{"word":"pétrus","word_nosc":"petrus","lemma":"pétrus","pos":"NOM"} ,
		{"word":"pétulance","word_nosc":"petulance","lemma":"pétulance","pos":"NOM"} ,
		{"word":"pétunia","word_nosc":"petunia","lemma":"pétunia","pos":"NOM"} ,
		{"word":"pétunias","word_nosc":"petunias","lemma":"pétunia","pos":"NOM"} ,
		{"word":"pétéchie","word_nosc":"petechie","lemma":"pétéchie","pos":"NOM"} ,
		{"word":"pétéchies","word_nosc":"petechies","lemma":"pétéchie","pos":"NOM"} ,
		{"word":"pêche","word_nosc":"peche","lemma":"pêche","pos":"NOM"} ,
		{"word":"pêcher","word_nosc":"pecher","lemma":"pêcher","pos":"NOM"} ,
		{"word":"pêcherie","word_nosc":"pecherie","lemma":"pêcherie","pos":"NOM"} ,
		{"word":"pêcheries","word_nosc":"pecheries","lemma":"pêcherie","pos":"NOM"} ,
		{"word":"pêchers","word_nosc":"pechers","lemma":"pêcher","pos":"NOM"} ,
		{"word":"pêches","word_nosc":"peches","lemma":"pêche","pos":"NOM"} ,
		{"word":"pêcheur","word_nosc":"pecheur","lemma":"pêcheur","pos":"NOM"} ,
		{"word":"pêcheurs","word_nosc":"pecheurs","lemma":"pêcheur","pos":"NOM"} ,
		{"word":"pêcheuse","word_nosc":"pecheuse","lemma":"pêcheur","pos":"NOM"} ,
		{"word":"pêcheuses","word_nosc":"pecheuses","lemma":"pêcheur","pos":"NOM"} ,
		{"word":"pêne","word_nosc":"pene","lemma":"pêne","pos":"NOM"} ,
		{"word":"pôle","word_nosc":"pole","lemma":"pôle","pos":"NOM"} ,
		{"word":"pôles","word_nosc":"poles","lemma":"pôle","pos":"NOM"} ,
		{"word":"q","word_nosc":"q","lemma":"q","pos":"NOM"} ,
		{"word":"qu'en-dira-t-on","word_nosc":"qu'en-dira-t-on","lemma":"qu'en-dira-t-on","pos":"NOM"} ,
		{"word":"quadra","word_nosc":"quadra","lemma":"quadra","pos":"NOM"} ,
		{"word":"quadragénaire","word_nosc":"quadragenaire","lemma":"quadragénaire","pos":"NOM"} ,
		{"word":"quadragénaires","word_nosc":"quadragenaires","lemma":"quadragénaire","pos":"NOM"} ,
		{"word":"quadrangle","word_nosc":"quadrangle","lemma":"quadrangle","pos":"NOM"} ,
		{"word":"quadrant","word_nosc":"quadrant","lemma":"quadrant","pos":"NOM"} ,
		{"word":"quadrants","word_nosc":"quadrants","lemma":"quadrant","pos":"NOM"} ,
		{"word":"quadras","word_nosc":"quadras","lemma":"quadra","pos":"NOM"} ,
		{"word":"quadrature","word_nosc":"quadrature","lemma":"quadrature","pos":"NOM"} ,
		{"word":"quadri","word_nosc":"quadri","lemma":"quadri","pos":"NOM"} ,
		{"word":"quadriceps","word_nosc":"quadriceps","lemma":"quadriceps","pos":"NOM"} ,
		{"word":"quadrichromie","word_nosc":"quadrichromie","lemma":"quadrichromie","pos":"NOM"} ,
		{"word":"quadrichromies","word_nosc":"quadrichromies","lemma":"quadrichromie","pos":"NOM"} ,
		{"word":"quadrige","word_nosc":"quadrige","lemma":"quadrige","pos":"NOM"} ,
		{"word":"quadrilatère","word_nosc":"quadrilatere","lemma":"quadrilatère","pos":"NOM"} ,
		{"word":"quadrilatères","word_nosc":"quadrilateres","lemma":"quadrilatère","pos":"NOM"} ,
		{"word":"quadrillage","word_nosc":"quadrillage","lemma":"quadrillage","pos":"NOM"} ,
		{"word":"quadrillages","word_nosc":"quadrillages","lemma":"quadrillage","pos":"NOM"} ,
		{"word":"quadrille","word_nosc":"quadrille","lemma":"quadrille","pos":"NOM"} ,
		{"word":"quadrilles","word_nosc":"quadrilles","lemma":"quadrille","pos":"NOM"} ,
		{"word":"quadrillion","word_nosc":"quadrillion","lemma":"quadrillion","pos":"NOM"} ,
		{"word":"quadrimestre","word_nosc":"quadrimestre","lemma":"quadrimestre","pos":"NOM"} ,
		{"word":"quadrimoteur","word_nosc":"quadrimoteur","lemma":"quadrimoteur","pos":"NOM"} ,
		{"word":"quadriphonie","word_nosc":"quadriphonie","lemma":"quadriphonie","pos":"NOM"} ,
		{"word":"quadriréacteur","word_nosc":"quadrireacteur","lemma":"quadriréacteur","pos":"NOM"} ,
		{"word":"quadrivium","word_nosc":"quadrivium","lemma":"quadrivium","pos":"NOM"} ,
		{"word":"quadrumane","word_nosc":"quadrumane","lemma":"quadrumane","pos":"NOM"} ,
		{"word":"quadruple","word_nosc":"quadruple","lemma":"quadruple","pos":"NOM"} ,
		{"word":"quadruples","word_nosc":"quadruples","lemma":"quadruple","pos":"NOM"} ,
		{"word":"quadruplées","word_nosc":"quadruplees","lemma":"quadruplée","pos":"NOM"} ,
		{"word":"quadruplés","word_nosc":"quadruples","lemma":"quadruplé","pos":"NOM"} ,
		{"word":"quadrupède","word_nosc":"quadrupede","lemma":"quadrupède","pos":"NOM"} ,
		{"word":"quadrupèdes","word_nosc":"quadrupedes","lemma":"quadrupède","pos":"NOM"} ,
		{"word":"quai","word_nosc":"quai","lemma":"quai","pos":"NOM"} ,
		{"word":"quais","word_nosc":"quais","lemma":"quai","pos":"NOM"} ,
		{"word":"quaker","word_nosc":"quaker","lemma":"quaker","pos":"NOM"} ,
		{"word":"quakeresse","word_nosc":"quakeresse","lemma":"quakeresse","pos":"NOM"} ,
		{"word":"quakers","word_nosc":"quakers","lemma":"quaker","pos":"NOM"} ,
		{"word":"qualificatif","word_nosc":"qualificatif","lemma":"qualificatif","pos":"NOM"} ,
		{"word":"qualificatifs","word_nosc":"qualificatifs","lemma":"qualificatif","pos":"NOM"} ,
		{"word":"qualification","word_nosc":"qualification","lemma":"qualification","pos":"NOM"} ,
		{"word":"qualifications","word_nosc":"qualifications","lemma":"qualification","pos":"NOM"} ,
		{"word":"qualité","word_nosc":"qualite","lemma":"qualité","pos":"NOM"} ,
		{"word":"qualités","word_nosc":"qualites","lemma":"qualité","pos":"NOM"} ,
		{"word":"quant","word_nosc":"quant","lemma":"quant","pos":"NOM"} ,
		{"word":"quant-à-moi","word_nosc":"quant-a-moi","lemma":"quant-à-moi","pos":"NOM"} ,
		{"word":"quant-à-soi","word_nosc":"quant-a-soi","lemma":"quant-à-soi","pos":"NOM"} ,
		{"word":"quanta","word_nosc":"quanta","lemma":"quantum","pos":"NOM"} ,
		{"word":"quantité","word_nosc":"quantite","lemma":"quantité","pos":"NOM"} ,
		{"word":"quantités","word_nosc":"quantites","lemma":"quantité","pos":"NOM"} ,
		{"word":"quantième","word_nosc":"quantieme","lemma":"quantième","pos":"NOM"} ,
		{"word":"quantièmes","word_nosc":"quantiemes","lemma":"quantième","pos":"NOM"} ,
		{"word":"quantum","word_nosc":"quantum","lemma":"quantum","pos":"NOM"} ,
		{"word":"quarantaine","word_nosc":"quarantaine","lemma":"quarantaine","pos":"NOM"} ,
		{"word":"quarantaines","word_nosc":"quarantaines","lemma":"quarantaine","pos":"NOM"} ,
		{"word":"quarante","word_nosc":"quarante","lemma":"quarante","pos":"NOM"} ,
		{"word":"quarante-huitards","word_nosc":"quarante-huitards","lemma":"quarante-huitard","pos":"NOM"} ,
		{"word":"quarantième","word_nosc":"quarantieme","lemma":"quarantième","pos":"NOM"} ,
		{"word":"quarantièmes","word_nosc":"quarantiemes","lemma":"quarantième","pos":"NOM"} ,
		{"word":"quark","word_nosc":"quark","lemma":"quark","pos":"NOM"} ,
		{"word":"quarks","word_nosc":"quarks","lemma":"quark","pos":"NOM"} ,
		{"word":"quarre","word_nosc":"quarre","lemma":"quarre","pos":"NOM"} ,
		{"word":"quart","word_nosc":"quart","lemma":"quart","pos":"NOM"} ,
		{"word":"quart-monde","word_nosc":"quart-monde","lemma":"quart-monde","pos":"NOM"} ,
		{"word":"quartaut","word_nosc":"quartaut","lemma":"quartaut","pos":"NOM"} ,
		{"word":"quarte","word_nosc":"quarte","lemma":"quarte","pos":"NOM"} ,
		{"word":"quartenier","word_nosc":"quartenier","lemma":"quartenier","pos":"NOM"} ,
		{"word":"quarteron","word_nosc":"quarteron","lemma":"quarteron","pos":"NOM"} ,
		{"word":"quarteronnes","word_nosc":"quarteronnes","lemma":"quarteron","pos":"NOM"} ,
		{"word":"quarterons","word_nosc":"quarterons","lemma":"quarteron","pos":"NOM"} ,
		{"word":"quartet","word_nosc":"quartet","lemma":"quartet","pos":"NOM"} ,
		{"word":"quartette","word_nosc":"quartette","lemma":"quartette","pos":"NOM"} ,
		{"word":"quartettes","word_nosc":"quartettes","lemma":"quartette","pos":"NOM"} ,
		{"word":"quartidi","word_nosc":"quartidi","lemma":"quartidi","pos":"NOM"} ,
		{"word":"quartier","word_nosc":"quartier","lemma":"quartier","pos":"NOM"} ,
		{"word":"quartier-maître","word_nosc":"quartier-maitre","lemma":"quartier-maître","pos":"NOM"} ,
		{"word":"quartiers","word_nosc":"quartiers","lemma":"quartier","pos":"NOM"} ,
		{"word":"quartiers-maîtres","word_nosc":"quartiers-maitres","lemma":"quartier-maître","pos":"NOM"} ,
		{"word":"quarts","word_nosc":"quarts","lemma":"quart","pos":"NOM"} ,
		{"word":"quartz","word_nosc":"quartz","lemma":"quartz","pos":"NOM"} ,
		{"word":"quarté","word_nosc":"quarte","lemma":"quarté","pos":"NOM"} ,
		{"word":"quasar","word_nosc":"quasar","lemma":"quasar","pos":"NOM"} ,
		{"word":"quasi","word_nosc":"quasi","lemma":"quasi","pos":"NOM"} ,
		{"word":"quasi-agonie","word_nosc":"quasi-agonie","lemma":"quasi-agonie","pos":"NOM"} ,
		{"word":"quasi-blasphème","word_nosc":"quasi-blaspheme","lemma":"quasi-blasphème","pos":"NOM"} ,
		{"word":"quasi-bonheur","word_nosc":"quasi-bonheur","lemma":"quasi-bonheur","pos":"NOM"} ,
		{"word":"quasi-certitude","word_nosc":"quasi-certitude","lemma":"quasi-certitude","pos":"NOM"} ,
		{"word":"quasi-chômage","word_nosc":"quasi-chomage","lemma":"quasi-chômage","pos":"NOM"} ,
		{"word":"quasi-décapitation","word_nosc":"quasi-decapitation","lemma":"quasi-décapitation","pos":"NOM"} ,
		{"word":"quasi-désertification","word_nosc":"quasi-desertification","lemma":"quasi-désertification","pos":"NOM"} ,
		{"word":"quasi-fiancée","word_nosc":"quasi-fiancee","lemma":"quasi-fiancé","pos":"NOM"} ,
		{"word":"quasi-génocide","word_nosc":"quasi-genocide","lemma":"quasi-génocide","pos":"NOM"} ,
		{"word":"quasi-hérétique","word_nosc":"quasi-heretique","lemma":"quasi-hérétique","pos":"NOM"} ,
		{"word":"quasi-ignare","word_nosc":"quasi-ignare","lemma":"quasi-ignare","pos":"NOM"} ,
		{"word":"quasi-impossibilité","word_nosc":"quasi-impossibilite","lemma":"quasi-impossibilité","pos":"NOM"} ,
		{"word":"quasi-impunité","word_nosc":"quasi-impunite","lemma":"quasi-impunité","pos":"NOM"} ,
		{"word":"quasi-indifférence","word_nosc":"quasi-indifference","lemma":"quasi-indifférence","pos":"NOM"} ,
		{"word":"quasi-infirme","word_nosc":"quasi-infirme","lemma":"quasi-infirme","pos":"NOM"} ,
		{"word":"quasi-jungienne","word_nosc":"quasi-jungienne","lemma":"quasi-jungienne","pos":"NOM"} ,
		{"word":"quasi-mariage","word_nosc":"quasi-mariage","lemma":"quasi-mariage","pos":"NOM"} ,
		{"word":"quasi-mendiant","word_nosc":"quasi-mendiant","lemma":"quasi-mendiant","pos":"NOM"} ,
		{"word":"quasi-miracle","word_nosc":"quasi-miracle","lemma":"quasi-miracle","pos":"NOM"} ,
		{"word":"quasi-monopole","word_nosc":"quasi-monopole","lemma":"quasi-monopole","pos":"NOM"} ,
		{"word":"quasi-morceau","word_nosc":"quasi-morceau","lemma":"quasi-morceau","pos":"NOM"} ,
		{"word":"quasi-noyade","word_nosc":"quasi-noyade","lemma":"quasi-noyade","pos":"NOM"} ,
		{"word":"quasi-nudité","word_nosc":"quasi-nudite","lemma":"quasi-nudité","pos":"NOM"} ,
		{"word":"quasi-permanence","word_nosc":"quasi-permanence","lemma":"quasi-permanence","pos":"NOM"} ,
		{"word":"quasi-protection","word_nosc":"quasi-protection","lemma":"quasi-protection","pos":"NOM"} ,
		{"word":"quasi-respect","word_nosc":"quasi-respect","lemma":"quasi-respect","pos":"NOM"} ,
		{"word":"quasi-totalité","word_nosc":"quasi-totalite","lemma":"quasi-totalité","pos":"NOM"} ,
		{"word":"quasi-unanimité","word_nosc":"quasi-unanimite","lemma":"quasi-unanimité","pos":"NOM"} ,
		{"word":"quasi-veuvage","word_nosc":"quasi-veuvage","lemma":"quasi-veuvage","pos":"NOM"} ,
		{"word":"quasi-ville","word_nosc":"quasi-ville","lemma":"quasi-ville","pos":"NOM"} ,
		{"word":"quasi-émeute","word_nosc":"quasi-emeute","lemma":"quasi-émeute","pos":"NOM"} ,
		{"word":"quasimodo","word_nosc":"quasimodo","lemma":"quasimodo","pos":"NOM"} ,
		{"word":"quasimodos","word_nosc":"quasimodos","lemma":"quasimodo","pos":"NOM"} ,
		{"word":"quat'zarts","word_nosc":"quat'zarts","lemma":"quat'zarts","pos":"NOM"} ,
		{"word":"quaternaire","word_nosc":"quaternaire","lemma":"quaternaire","pos":"NOM"} ,
		{"word":"quatorzième","word_nosc":"quatorzieme","lemma":"quatorzième","pos":"NOM"} ,
		{"word":"quatrain","word_nosc":"quatrain","lemma":"quatrain","pos":"NOM"} ,
		{"word":"quatrains","word_nosc":"quatrains","lemma":"quatrain","pos":"NOM"} ,
		{"word":"quatre","word_nosc":"quatre","lemma":"quatre","pos":"NOM"} ,
		{"word":"quatre-feuilles","word_nosc":"quatre-feuilles","lemma":"quatre-feuilles","pos":"NOM"} ,
		{"word":"quatre-heures","word_nosc":"quatre-heures","lemma":"quatre-heures","pos":"NOM"} ,
		{"word":"quatre-mâts","word_nosc":"quatre-mats","lemma":"quatre-mâts","pos":"NOM"} ,
		{"word":"quatre-quarts","word_nosc":"quatre-quarts","lemma":"quatre-quarts","pos":"NOM"} ,
		{"word":"quatre-saisons","word_nosc":"quatre-saisons","lemma":"quatre-saisons","pos":"NOM"} ,
		{"word":"quatre-vingt","word_nosc":"quatre-vingt","lemma":"quatre-vingt","pos":"NOM"} ,
		{"word":"quatre-vingt-dix","word_nosc":"quatre-vingt-dix","lemma":"quatre-vingt-dix","pos":"NOM"} ,
		{"word":"quatre-vingtième","word_nosc":"quatre-vingtieme","lemma":"quatre-vingtième","pos":"NOM"} ,
		{"word":"quatrillion","word_nosc":"quatrillion","lemma":"quatrillion","pos":"NOM"} ,
		{"word":"quatrième","word_nosc":"quatrieme","lemma":"quatrième","pos":"NOM"} ,
		{"word":"quatrièmes","word_nosc":"quatriemes","lemma":"quatrième","pos":"NOM"} ,
		{"word":"quattrocento","word_nosc":"quattrocento","lemma":"quattrocento","pos":"NOM"} ,
		{"word":"quatuor","word_nosc":"quatuor","lemma":"quatuor","pos":"NOM"} ,
		{"word":"quatuors","word_nosc":"quatuors","lemma":"quatuor","pos":"NOM"} ,
		{"word":"quechua","word_nosc":"quechua","lemma":"quechua","pos":"NOM"} ,
		{"word":"quenelle","word_nosc":"quenelle","lemma":"quenelle","pos":"NOM"} ,
		{"word":"quenelles","word_nosc":"quenelles","lemma":"quenelle","pos":"NOM"} ,
		{"word":"quenotte","word_nosc":"quenotte","lemma":"quenotte","pos":"NOM"} ,
		{"word":"quenottes","word_nosc":"quenottes","lemma":"quenotte","pos":"NOM"} ,
		{"word":"quenouille","word_nosc":"quenouille","lemma":"quenouille","pos":"NOM"} ,
		{"word":"quenouilles","word_nosc":"quenouilles","lemma":"quenouille","pos":"NOM"} ,
		{"word":"querelle","word_nosc":"querelle","lemma":"querelle","pos":"NOM"} ,
		{"word":"querelles","word_nosc":"querelles","lemma":"querelle","pos":"NOM"} ,
		{"word":"querelleur","word_nosc":"querelleur","lemma":"querelleur","pos":"NOM"} ,
		{"word":"querelleurs","word_nosc":"querelleurs","lemma":"querelleur","pos":"NOM"} ,
		{"word":"questeur","word_nosc":"questeur","lemma":"questeur","pos":"NOM"} ,
		{"word":"questeurs","word_nosc":"questeurs","lemma":"questeur","pos":"NOM"} ,
		{"word":"question","word_nosc":"question","lemma":"question","pos":"NOM"} ,
		{"word":"question-clé","word_nosc":"question-cle","lemma":"question-clé","pos":"NOM"} ,
		{"word":"question-réponse","word_nosc":"question-reponse","lemma":"question-réponse","pos":"NOM"} ,
		{"word":"questionnaire","word_nosc":"questionnaire","lemma":"questionnaire","pos":"NOM"} ,
		{"word":"questionnaires","word_nosc":"questionnaires","lemma":"questionnaire","pos":"NOM"} ,
		{"word":"questionnement","word_nosc":"questionnement","lemma":"questionnement","pos":"NOM"} ,
		{"word":"questionnements","word_nosc":"questionnements","lemma":"questionnement","pos":"NOM"} ,
		{"word":"questionneur","word_nosc":"questionneur","lemma":"questionneur","pos":"NOM"} ,
		{"word":"questionneurs","word_nosc":"questionneurs","lemma":"questionneur","pos":"NOM"} ,
		{"word":"questionneuse","word_nosc":"questionneuse","lemma":"questionneur","pos":"NOM"} ,
		{"word":"questions","word_nosc":"questions","lemma":"question","pos":"NOM"} ,
		{"word":"questions-réponses","word_nosc":"questions-reponses","lemma":"question-réponse","pos":"NOM"} ,
		{"word":"questure","word_nosc":"questure","lemma":"questure","pos":"NOM"} ,
		{"word":"quetsche","word_nosc":"quetsche","lemma":"quetsche","pos":"NOM"} ,
		{"word":"quetsches","word_nosc":"quetsches","lemma":"quetsche","pos":"NOM"} ,
		{"word":"quette","word_nosc":"quette","lemma":"quette","pos":"NOM"} ,
		{"word":"quetzal","word_nosc":"quetzal","lemma":"quetzal","pos":"NOM"} ,
		{"word":"queue","word_nosc":"queue","lemma":"queue","pos":"NOM"} ,
		{"word":"queue-d'aronde","word_nosc":"queue-d'aronde","lemma":"queue-d'aronde","pos":"NOM"} ,
		{"word":"queue-de-cheval","word_nosc":"queue-de-cheval","lemma":"queue-de-cheval","pos":"NOM"} ,
		{"word":"queue-de-pie","word_nosc":"queue-de-pie","lemma":"queue-de-pie","pos":"NOM"} ,
		{"word":"queues","word_nosc":"queues","lemma":"queue","pos":"NOM"} ,
		{"word":"queues-d'aronde","word_nosc":"queues-d'aronde","lemma":"queue-d'aronde","pos":"NOM"} ,
		{"word":"queues-de-rat","word_nosc":"queues-de-rat","lemma":"queue-de-rat","pos":"NOM"} ,
		{"word":"queursoir","word_nosc":"queursoir","lemma":"queursoir","pos":"NOM"} ,
		{"word":"queutard","word_nosc":"queutard","lemma":"queutard","pos":"NOM"} ,
		{"word":"qui-vive","word_nosc":"qui-vive","lemma":"qui-vive","pos":"NOM"} ,
		{"word":"quibus","word_nosc":"quibus","lemma":"quibus","pos":"NOM"} ,
		{"word":"quiche","word_nosc":"quiche","lemma":"quiche","pos":"NOM"} ,
		{"word":"quiches","word_nosc":"quiches","lemma":"quiche","pos":"NOM"} ,
		{"word":"quichotte","word_nosc":"quichotte","lemma":"quichotte","pos":"NOM"} ,
		{"word":"quick","word_nosc":"quick","lemma":"quick","pos":"NOM"} ,
		{"word":"quidam","word_nosc":"quidam","lemma":"quidam","pos":"NOM"} ,
		{"word":"quidams","word_nosc":"quidams","lemma":"quidam","pos":"NOM"} ,
		{"word":"quignon","word_nosc":"quignon","lemma":"quignon","pos":"NOM"} ,
		{"word":"quignons","word_nosc":"quignons","lemma":"quignon","pos":"NOM"} ,
		{"word":"quillards","word_nosc":"quillards","lemma":"quillard","pos":"NOM"} ,
		{"word":"quille","word_nosc":"quille","lemma":"quille","pos":"NOM"} ,
		{"word":"quilles","word_nosc":"quilles","lemma":"quille","pos":"NOM"} ,
		{"word":"quillon","word_nosc":"quillon","lemma":"quillon","pos":"NOM"} ,
		{"word":"quillons","word_nosc":"quillons","lemma":"quillon","pos":"NOM"} ,
		{"word":"quincaille","word_nosc":"quincaille","lemma":"quincaille","pos":"NOM"} ,
		{"word":"quincaillerie","word_nosc":"quincaillerie","lemma":"quincaillerie","pos":"NOM"} ,
		{"word":"quincailleries","word_nosc":"quincailleries","lemma":"quincaillerie","pos":"NOM"} ,
		{"word":"quincaillier","word_nosc":"quincaillier","lemma":"quincaillier","pos":"NOM"} ,
		{"word":"quincailliers","word_nosc":"quincailliers","lemma":"quincaillier","pos":"NOM"} ,
		{"word":"quincaillière","word_nosc":"quincailliere","lemma":"quincaillier","pos":"NOM"} ,
		{"word":"quinconce","word_nosc":"quinconce","lemma":"quinconce","pos":"NOM"} ,
		{"word":"quinconces","word_nosc":"quinconces","lemma":"quinconce","pos":"NOM"} ,
		{"word":"quine","word_nosc":"quine","lemma":"quine","pos":"NOM"} ,
		{"word":"quinine","word_nosc":"quinine","lemma":"quinine","pos":"NOM"} ,
		{"word":"quinium","word_nosc":"quinium","lemma":"quinium","pos":"NOM"} ,
		{"word":"quinoa","word_nosc":"quinoa","lemma":"quinoa","pos":"NOM"} ,
		{"word":"quinqua","word_nosc":"quinqua","lemma":"quinqua","pos":"NOM"} ,
		{"word":"quinquagénaire","word_nosc":"quinquagenaire","lemma":"quinquagénaire","pos":"NOM"} ,
		{"word":"quinquagénaires","word_nosc":"quinquagenaires","lemma":"quinquagénaire","pos":"NOM"} ,
		{"word":"quinquagésime","word_nosc":"quinquagesime","lemma":"quinquagésime","pos":"NOM"} ,
		{"word":"quinquennat","word_nosc":"quinquennat","lemma":"quinquennat","pos":"NOM"} ,
		{"word":"quinquet","word_nosc":"quinquet","lemma":"quinquet","pos":"NOM"} ,
		{"word":"quinquets","word_nosc":"quinquets","lemma":"quinquet","pos":"NOM"} ,
		{"word":"quinquina","word_nosc":"quinquina","lemma":"quinquina","pos":"NOM"} ,
		{"word":"quintaine","word_nosc":"quintaine","lemma":"quintaine","pos":"NOM"} ,
		{"word":"quintal","word_nosc":"quintal","lemma":"quintal","pos":"NOM"} ,
		{"word":"quintaux","word_nosc":"quintaux","lemma":"quintal","pos":"NOM"} ,
		{"word":"quinte","word_nosc":"quinte","lemma":"quinte","pos":"NOM"} ,
		{"word":"quintes","word_nosc":"quintes","lemma":"quinte","pos":"NOM"} ,
		{"word":"quintessence","word_nosc":"quintessence","lemma":"quintessence","pos":"NOM"} ,
		{"word":"quintet","word_nosc":"quintet","lemma":"quintet","pos":"NOM"} ,
		{"word":"quintette","word_nosc":"quintette","lemma":"quintette","pos":"NOM"} ,
		{"word":"quintettes","word_nosc":"quintettes","lemma":"quintette","pos":"NOM"} ,
		{"word":"quintidi","word_nosc":"quintidi","lemma":"quintidi","pos":"NOM"} ,
		{"word":"quintolet","word_nosc":"quintolet","lemma":"quintolet","pos":"NOM"} ,
		{"word":"quinton","word_nosc":"quinton","lemma":"quinton","pos":"NOM"} ,
		{"word":"quintuplées","word_nosc":"quintuplees","lemma":"quintuplée","pos":"NOM"} ,
		{"word":"quintuplés","word_nosc":"quintuples","lemma":"quintuplé","pos":"NOM"} ,
		{"word":"quinté","word_nosc":"quinte","lemma":"quinté","pos":"NOM"} ,
		{"word":"quinzaine","word_nosc":"quinzaine","lemma":"quinzaine","pos":"NOM"} ,
		{"word":"quinzaines","word_nosc":"quinzaines","lemma":"quinzaine","pos":"NOM"} ,
		{"word":"quinzième","word_nosc":"quinzieme","lemma":"quinzième","pos":"NOM"} ,
		{"word":"quipos","word_nosc":"quipos","lemma":"quipo","pos":"NOM"} ,
		{"word":"quiproquo","word_nosc":"quiproquo","lemma":"quiproquo","pos":"NOM"} ,
		{"word":"quiproquos","word_nosc":"quiproquos","lemma":"quiproquo","pos":"NOM"} ,
		{"word":"quipu","word_nosc":"quipu","lemma":"quipu","pos":"NOM"} ,
		{"word":"quiquette","word_nosc":"quiquette","lemma":"quiquette","pos":"NOM"} ,
		{"word":"quiqui","word_nosc":"quiqui","lemma":"quiqui","pos":"NOM"} ,
		{"word":"quiscale","word_nosc":"quiscale","lemma":"quiscale","pos":"NOM"} ,
		{"word":"quite","word_nosc":"quite","lemma":"quite","pos":"NOM"} ,
		{"word":"quittance","word_nosc":"quittance","lemma":"quittance","pos":"NOM"} ,
		{"word":"quittances","word_nosc":"quittances","lemma":"quittance","pos":"NOM"} ,
		{"word":"quitus","word_nosc":"quitus","lemma":"quitus","pos":"NOM"} ,
		{"word":"quiz","word_nosc":"quiz","lemma":"quiz","pos":"NOM"} ,
		{"word":"quiétisme","word_nosc":"quietisme","lemma":"quiétisme","pos":"NOM"} ,
		{"word":"quiétiste","word_nosc":"quietiste","lemma":"quiétiste","pos":"NOM"} ,
		{"word":"quiétude","word_nosc":"quietude","lemma":"quiétude","pos":"NOM"} ,
		{"word":"quiétudes","word_nosc":"quietudes","lemma":"quiétude","pos":"NOM"} ,
		{"word":"quolibet","word_nosc":"quolibet","lemma":"quolibet","pos":"NOM"} ,
		{"word":"quolibets","word_nosc":"quolibets","lemma":"quolibet","pos":"NOM"} ,
		{"word":"quorum","word_nosc":"quorum","lemma":"quorum","pos":"NOM"} ,
		{"word":"quota","word_nosc":"quota","lemma":"quota","pos":"NOM"} ,
		{"word":"quotas","word_nosc":"quotas","lemma":"quota","pos":"NOM"} ,
		{"word":"quote-part","word_nosc":"quote-part","lemma":"quote-part","pos":"NOM"} ,
		{"word":"quotidien","word_nosc":"quotidien","lemma":"quotidien","pos":"NOM"} ,
		{"word":"quotidienneté","word_nosc":"quotidiennete","lemma":"quotidienneté","pos":"NOM"} ,
		{"word":"quotidiens","word_nosc":"quotidiens","lemma":"quotidien","pos":"NOM"} ,
		{"word":"quotient","word_nosc":"quotient","lemma":"quotient","pos":"NOM"} ,
		{"word":"quotients","word_nosc":"quotients","lemma":"quotient","pos":"NOM"} ,
		{"word":"quotité","word_nosc":"quotite","lemma":"quotité","pos":"NOM"} ,
		{"word":"québécois","word_nosc":"quebecois","lemma":"québécois","pos":"NOM"} ,
		{"word":"quémandeur","word_nosc":"quemandeur","lemma":"quémandeur","pos":"NOM"} ,
		{"word":"quémandeurs","word_nosc":"quemandeurs","lemma":"quémandeur","pos":"NOM"} ,
		{"word":"quémandeuse","word_nosc":"quemandeuse","lemma":"quémandeur","pos":"NOM"} ,
		{"word":"quémandeuses","word_nosc":"quemandeuses","lemma":"quémandeur","pos":"NOM"} ,
		{"word":"quéquette","word_nosc":"quequette","lemma":"quéquette","pos":"NOM"} ,
		{"word":"quéquettes","word_nosc":"quequettes","lemma":"quéquette","pos":"NOM"} ,
		{"word":"quête","word_nosc":"quete","lemma":"quête","pos":"NOM"} ,
		{"word":"quêtes","word_nosc":"quetes","lemma":"quête","pos":"NOM"} ,
		{"word":"quêteur","word_nosc":"queteur","lemma":"quêteur","pos":"NOM"} ,
		{"word":"quêteurs","word_nosc":"queteurs","lemma":"quêteur","pos":"NOM"} ,
		{"word":"quêteuse","word_nosc":"queteuse","lemma":"quêteur","pos":"NOM"} ,
		{"word":"ra","word_nosc":"ra","lemma":"ra","pos":"NOM"} ,
		{"word":"rab","word_nosc":"rab","lemma":"rab","pos":"NOM"} ,
		{"word":"rabais","word_nosc":"rabais","lemma":"rabais","pos":"NOM"} ,
		{"word":"rabaissement","word_nosc":"rabaissement","lemma":"rabaissement","pos":"NOM"} ,
		{"word":"raban","word_nosc":"raban","lemma":"raban","pos":"NOM"} ,
		{"word":"rabane","word_nosc":"rabane","lemma":"rabane","pos":"NOM"} ,
		{"word":"rabanes","word_nosc":"rabanes","lemma":"rabane","pos":"NOM"} ,
		{"word":"rabans","word_nosc":"rabans","lemma":"raban","pos":"NOM"} ,
		{"word":"rabat","word_nosc":"rabat","lemma":"rabat","pos":"NOM"} ,
		{"word":"rabats","word_nosc":"rabats","lemma":"rabat","pos":"NOM"} ,
		{"word":"rabatteur","word_nosc":"rabatteur","lemma":"rabatteur","pos":"NOM"} ,
		{"word":"rabatteurs","word_nosc":"rabatteurs","lemma":"rabatteur","pos":"NOM"} ,
		{"word":"rabatteuse","word_nosc":"rabatteuse","lemma":"rabatteur","pos":"NOM"} ,
		{"word":"rabatteuses","word_nosc":"rabatteuses","lemma":"rabatteur","pos":"NOM"} ,
		{"word":"rabbi","word_nosc":"rabbi","lemma":"rabbi","pos":"NOM"} ,
		{"word":"rabbin","word_nosc":"rabbin","lemma":"rabbin","pos":"NOM"} ,
		{"word":"rabbinat","word_nosc":"rabbinat","lemma":"rabbinat","pos":"NOM"} ,
		{"word":"rabbins","word_nosc":"rabbins","lemma":"rabbin","pos":"NOM"} ,
		{"word":"rabe","word_nosc":"rabe","lemma":"rabe","pos":"NOM"} ,
		{"word":"rabes","word_nosc":"rabes","lemma":"rabe","pos":"NOM"} ,
		{"word":"rabibochage","word_nosc":"rabibochage","lemma":"rabibochage","pos":"NOM"} ,
		{"word":"rabiot","word_nosc":"rabiot","lemma":"rabiot","pos":"NOM"} ,
		{"word":"rabot","word_nosc":"rabot","lemma":"rabot","pos":"NOM"} ,
		{"word":"rabotage","word_nosc":"rabotage","lemma":"rabotage","pos":"NOM"} ,
		{"word":"raboteur","word_nosc":"raboteur","lemma":"raboteur","pos":"NOM"} ,
		{"word":"rabotin","word_nosc":"rabotin","lemma":"rabotin","pos":"NOM"} ,
		{"word":"rabots","word_nosc":"rabots","lemma":"rabot","pos":"NOM"} ,
		{"word":"rabouillères","word_nosc":"rabouilleres","lemma":"rabouiller","pos":"NOM"} ,
		{"word":"rabouin","word_nosc":"rabouin","lemma":"rabouin","pos":"NOM"} ,
		{"word":"rabs","word_nosc":"rabs","lemma":"rab","pos":"NOM"} ,
		{"word":"rabâchage","word_nosc":"rabachage","lemma":"rabâchage","pos":"NOM"} ,
		{"word":"rabâchages","word_nosc":"rabachages","lemma":"rabâchage","pos":"NOM"} ,
		{"word":"racaille","word_nosc":"racaille","lemma":"racaille","pos":"NOM"} ,
		{"word":"racailles","word_nosc":"racailles","lemma":"racaille","pos":"NOM"} ,
		{"word":"raccommodage","word_nosc":"raccommodage","lemma":"raccommodage","pos":"NOM"} ,
		{"word":"raccommodages","word_nosc":"raccommodages","lemma":"raccommodage","pos":"NOM"} ,
		{"word":"raccommodements","word_nosc":"raccommodements","lemma":"raccommodement","pos":"NOM"} ,
		{"word":"raccommodeurs","word_nosc":"raccommodeurs","lemma":"raccommodeur","pos":"NOM"} ,
		{"word":"raccord","word_nosc":"raccord","lemma":"raccord","pos":"NOM"} ,
		{"word":"raccordement","word_nosc":"raccordement","lemma":"raccordement","pos":"NOM"} ,
		{"word":"raccordements","word_nosc":"raccordements","lemma":"raccordement","pos":"NOM"} ,
		{"word":"raccords","word_nosc":"raccords","lemma":"raccord","pos":"NOM"} ,
		{"word":"raccourci","word_nosc":"raccourci","lemma":"raccourci","pos":"NOM"} ,
		{"word":"raccourcis","word_nosc":"raccourcis","lemma":"raccourci","pos":"NOM"} ,
		{"word":"raccourcissement","word_nosc":"raccourcissement","lemma":"raccourcissement","pos":"NOM"} ,
		{"word":"raccroc","word_nosc":"raccroc","lemma":"raccroc","pos":"NOM"} ,
		{"word":"raccrochage","word_nosc":"raccrochage","lemma":"raccrochage","pos":"NOM"} ,
		{"word":"raccrochages","word_nosc":"raccrochages","lemma":"raccrochage","pos":"NOM"} ,
		{"word":"raccrocs","word_nosc":"raccrocs","lemma":"raccroc","pos":"NOM"} ,
		{"word":"race","word_nosc":"race","lemma":"race","pos":"NOM"} ,
		{"word":"races","word_nosc":"races","lemma":"race","pos":"NOM"} ,
		{"word":"rachat","word_nosc":"rachat","lemma":"rachat","pos":"NOM"} ,
		{"word":"rachats","word_nosc":"rachats","lemma":"rachat","pos":"NOM"} ,
		{"word":"rachianesthésie","word_nosc":"rachianesthesie","lemma":"rachianesthésie","pos":"NOM"} ,
		{"word":"rachis","word_nosc":"rachis","lemma":"rachis","pos":"NOM"} ,
		{"word":"rachitique","word_nosc":"rachitique","lemma":"rachitique","pos":"NOM"} ,
		{"word":"rachitiques","word_nosc":"rachitiques","lemma":"rachitique","pos":"NOM"} ,
		{"word":"rachitisme","word_nosc":"rachitisme","lemma":"rachitisme","pos":"NOM"} ,
		{"word":"racine","word_nosc":"racine","lemma":"racine","pos":"NOM"} ,
		{"word":"racines","word_nosc":"racines","lemma":"racine","pos":"NOM"} ,
		{"word":"racingman","word_nosc":"racingman","lemma":"racingman","pos":"NOM"} ,
		{"word":"racisme","word_nosc":"racisme","lemma":"racisme","pos":"NOM"} ,
		{"word":"racismes","word_nosc":"racismes","lemma":"racisme","pos":"NOM"} ,
		{"word":"raciste","word_nosc":"raciste","lemma":"raciste","pos":"NOM"} ,
		{"word":"racistes","word_nosc":"racistes","lemma":"raciste","pos":"NOM"} ,
		{"word":"rack","word_nosc":"rack","lemma":"rack","pos":"NOM"} ,
		{"word":"racket","word_nosc":"racket","lemma":"racket","pos":"NOM"} ,
		{"word":"rackets","word_nosc":"rackets","lemma":"racket","pos":"NOM"} ,
		{"word":"racketteur","word_nosc":"racketteur","lemma":"racketteur","pos":"NOM"} ,
		{"word":"raclages","word_nosc":"raclages","lemma":"raclage","pos":"NOM"} ,
		{"word":"racle","word_nosc":"racle","lemma":"racle","pos":"NOM"} ,
		{"word":"raclement","word_nosc":"raclement","lemma":"raclement","pos":"NOM"} ,
		{"word":"raclements","word_nosc":"raclements","lemma":"raclement","pos":"NOM"} ,
		{"word":"raclette","word_nosc":"raclette","lemma":"raclette","pos":"NOM"} ,
		{"word":"racleur","word_nosc":"racleur","lemma":"racleur","pos":"NOM"} ,
		{"word":"racleuse","word_nosc":"racleuse","lemma":"racleur","pos":"NOM"} ,
		{"word":"racloir","word_nosc":"racloir","lemma":"racloir","pos":"NOM"} ,
		{"word":"raclons","word_nosc":"raclons","lemma":"raclon","pos":"NOM"} ,
		{"word":"raclure","word_nosc":"raclure","lemma":"raclure","pos":"NOM"} ,
		{"word":"raclures","word_nosc":"raclures","lemma":"raclure","pos":"NOM"} ,
		{"word":"raclée","word_nosc":"raclee","lemma":"raclée","pos":"NOM"} ,
		{"word":"raclées","word_nosc":"raclees","lemma":"raclée","pos":"NOM"} ,
		{"word":"racolage","word_nosc":"racolage","lemma":"racolage","pos":"NOM"} ,
		{"word":"racolages","word_nosc":"racolages","lemma":"racolage","pos":"NOM"} ,
		{"word":"racoleur","word_nosc":"racoleur","lemma":"racoleur","pos":"NOM"} ,
		{"word":"racoleurs","word_nosc":"racoleurs","lemma":"racoleur","pos":"NOM"} ,
		{"word":"racoleuse","word_nosc":"racoleuse","lemma":"racoleur","pos":"NOM"} ,
		{"word":"racoleuses","word_nosc":"racoleuses","lemma":"racoleur","pos":"NOM"} ,
		{"word":"racontar","word_nosc":"racontar","lemma":"racontar","pos":"NOM"} ,
		{"word":"racontars","word_nosc":"racontars","lemma":"racontar","pos":"NOM"} ,
		{"word":"raconteur","word_nosc":"raconteur","lemma":"raconteur","pos":"NOM"} ,
		{"word":"raconteuse","word_nosc":"raconteuse","lemma":"raconteur","pos":"NOM"} ,
		{"word":"rad","word_nosc":"rad","lemma":"rad","pos":"NOM"} ,
		{"word":"radada","word_nosc":"radada","lemma":"radada","pos":"NOM"} ,
		{"word":"radar","word_nosc":"radar","lemma":"radar","pos":"NOM"} ,
		{"word":"radars","word_nosc":"radars","lemma":"radar","pos":"NOM"} ,
		{"word":"rade","word_nosc":"rade","lemma":"rade","pos":"NOM"} ,
		{"word":"radeau","word_nosc":"radeau","lemma":"radeau","pos":"NOM"} ,
		{"word":"radeau-radar","word_nosc":"radeau-radar","lemma":"radeau-radar","pos":"NOM"} ,
		{"word":"radeaux","word_nosc":"radeaux","lemma":"radeau","pos":"NOM"} ,
		{"word":"rades","word_nosc":"rades","lemma":"rade","pos":"NOM"} ,
		{"word":"radeuse","word_nosc":"radeuse","lemma":"radeuse","pos":"NOM"} ,
		{"word":"radeuses","word_nosc":"radeuses","lemma":"radeuse","pos":"NOM"} ,
		{"word":"radiant","word_nosc":"radiant","lemma":"radiant","pos":"NOM"} ,
		{"word":"radiateur","word_nosc":"radiateur","lemma":"radiateur","pos":"NOM"} ,
		{"word":"radiateurs","word_nosc":"radiateurs","lemma":"radiateur","pos":"NOM"} ,
		{"word":"radiation","word_nosc":"radiation","lemma":"radiation","pos":"NOM"} ,
		{"word":"radiations","word_nosc":"radiations","lemma":"radiation","pos":"NOM"} ,
		{"word":"radical","word_nosc":"radical","lemma":"radical","pos":"NOM"} ,
		{"word":"radical-socialisme","word_nosc":"radical-socialisme","lemma":"radical-socialisme","pos":"NOM"} ,
		{"word":"radical-socialiste","word_nosc":"radical-socialiste","lemma":"radical-socialiste","pos":"NOM"} ,
		{"word":"radicale","word_nosc":"radicale","lemma":"radical","pos":"NOM"} ,
		{"word":"radicale-socialiste","word_nosc":"radicale-socialiste","lemma":"radicale-socialiste","pos":"NOM"} ,
		{"word":"radicalisation","word_nosc":"radicalisation","lemma":"radicalisation","pos":"NOM"} ,
		{"word":"radicalisme","word_nosc":"radicalisme","lemma":"radicalisme","pos":"NOM"} ,
		{"word":"radicaux-socialistes","word_nosc":"radicaux-socialistes","lemma":"radical-socialiste","pos":"NOM"} ,
		{"word":"radicelles","word_nosc":"radicelles","lemma":"radicelle","pos":"NOM"} ,
		{"word":"radicule","word_nosc":"radicule","lemma":"radicule","pos":"NOM"} ,
		{"word":"radicules","word_nosc":"radicules","lemma":"radicule","pos":"NOM"} ,
		{"word":"radier","word_nosc":"radier","lemma":"radier","pos":"NOM"} ,
		{"word":"radiers","word_nosc":"radiers","lemma":"radier","pos":"NOM"} ,
		{"word":"radiesthésiste","word_nosc":"radiesthesiste","lemma":"radiesthésiste","pos":"NOM"} ,
		{"word":"radin","word_nosc":"radin","lemma":"radin","pos":"NOM"} ,
		{"word":"radine","word_nosc":"radine","lemma":"radin","pos":"NOM"} ,
		{"word":"radinerie","word_nosc":"radinerie","lemma":"radinerie","pos":"NOM"} ,
		{"word":"radines","word_nosc":"radines","lemma":"radin","pos":"NOM"} ,
		{"word":"radins","word_nosc":"radins","lemma":"radin","pos":"NOM"} ,
		{"word":"radio","word_nosc":"radio","lemma":"radio","pos":"NOM"} ,
		{"word":"radio-isotope","word_nosc":"radio-isotope","lemma":"radio-isotope","pos":"NOM"} ,
		{"word":"radio-réveil","word_nosc":"radio-reveil","lemma":"radio-réveil","pos":"NOM"} ,
		{"word":"radio-taxi","word_nosc":"radio-taxi","lemma":"radio-taxi","pos":"NOM"} ,
		{"word":"radioactivité","word_nosc":"radioactivite","lemma":"radioactivité","pos":"NOM"} ,
		{"word":"radioamateur","word_nosc":"radioamateur","lemma":"radioamateur","pos":"NOM"} ,
		{"word":"radioastronome","word_nosc":"radioastronome","lemma":"radioastronome","pos":"NOM"} ,
		{"word":"radiobalisage","word_nosc":"radiobalisage","lemma":"radiobalisage","pos":"NOM"} ,
		{"word":"radiobalise","word_nosc":"radiobalise","lemma":"radiobalise","pos":"NOM"} ,
		{"word":"radiocassette","word_nosc":"radiocassette","lemma":"radiocassette","pos":"NOM"} ,
		{"word":"radiodiffusion","word_nosc":"radiodiffusion","lemma":"radiodiffusion","pos":"NOM"} ,
		{"word":"radiogoniomètre","word_nosc":"radiogoniometre","lemma":"radiogoniomètre","pos":"NOM"} ,
		{"word":"radiogramme","word_nosc":"radiogramme","lemma":"radiogramme","pos":"NOM"} ,
		{"word":"radiogrammes","word_nosc":"radiogrammes","lemma":"radiogramme","pos":"NOM"} ,
		{"word":"radiographie","word_nosc":"radiographie","lemma":"radiographie","pos":"NOM"} ,
		{"word":"radiographies","word_nosc":"radiographies","lemma":"radiographie","pos":"NOM"} ,
		{"word":"radioguidage","word_nosc":"radioguidage","lemma":"radioguidage","pos":"NOM"} ,
		{"word":"radiologie","word_nosc":"radiologie","lemma":"radiologie","pos":"NOM"} ,
		{"word":"radiologiste","word_nosc":"radiologiste","lemma":"radiologiste","pos":"NOM"} ,
		{"word":"radiologue","word_nosc":"radiologue","lemma":"radiologue","pos":"NOM"} ,
		{"word":"radiologues","word_nosc":"radiologues","lemma":"radiologue","pos":"NOM"} ,
		{"word":"radiomètre","word_nosc":"radiometre","lemma":"radiomètre","pos":"NOM"} ,
		{"word":"radiomètres","word_nosc":"radiometres","lemma":"radiomètre","pos":"NOM"} ,
		{"word":"radiométrie","word_nosc":"radiometrie","lemma":"radiométrie","pos":"NOM"} ,
		{"word":"radiophare","word_nosc":"radiophare","lemma":"radiophare","pos":"NOM"} ,
		{"word":"radiophonie","word_nosc":"radiophonie","lemma":"radiophonie","pos":"NOM"} ,
		{"word":"radioréveil","word_nosc":"radioreveil","lemma":"radioréveil","pos":"NOM"} ,
		{"word":"radios","word_nosc":"radios","lemma":"radio","pos":"NOM"} ,
		{"word":"radioscopie","word_nosc":"radioscopie","lemma":"radioscopie","pos":"NOM"} ,
		{"word":"radiosonde","word_nosc":"radiosonde","lemma":"radiosonde","pos":"NOM"} ,
		{"word":"radiothérapie","word_nosc":"radiotherapie","lemma":"radiothérapie","pos":"NOM"} ,
		{"word":"radiotélescope","word_nosc":"radiotelescope","lemma":"radiotélescope","pos":"NOM"} ,
		{"word":"radiotélescopes","word_nosc":"radiotelescopes","lemma":"radiotélescope","pos":"NOM"} ,
		{"word":"radiotélégraphie","word_nosc":"radiotelegraphie","lemma":"radiotélégraphie","pos":"NOM"} ,
		{"word":"radiotélégraphiste","word_nosc":"radiotelegraphiste","lemma":"radiotélégraphiste","pos":"NOM"} ,
		{"word":"radiotéléphone","word_nosc":"radiotelephone","lemma":"radiotéléphone","pos":"NOM"} ,
		{"word":"radiotéléphones","word_nosc":"radiotelephones","lemma":"radiotéléphone","pos":"NOM"} ,
		{"word":"radiotéléphonie","word_nosc":"radiotelephonie","lemma":"radiotéléphonie","pos":"NOM"} ,
		{"word":"radioélément","word_nosc":"radioelement","lemma":"radioélément","pos":"NOM"} ,
		{"word":"radis","word_nosc":"radis","lemma":"radis","pos":"NOM"} ,
		{"word":"radium","word_nosc":"radium","lemma":"radium","pos":"NOM"} ,
		{"word":"radius","word_nosc":"radius","lemma":"radius","pos":"NOM"} ,
		{"word":"radja","word_nosc":"radja","lemma":"radja","pos":"NOM"} ,
		{"word":"radjah","word_nosc":"radjah","lemma":"radjah","pos":"NOM"} ,
		{"word":"radjahs","word_nosc":"radjahs","lemma":"radjah","pos":"NOM"} ,
		{"word":"radon","word_nosc":"radon","lemma":"radon","pos":"NOM"} ,
		{"word":"radotage","word_nosc":"radotage","lemma":"radotage","pos":"NOM"} ,
		{"word":"radotages","word_nosc":"radotages","lemma":"radotage","pos":"NOM"} ,
		{"word":"radoteur","word_nosc":"radoteur","lemma":"radoteur","pos":"NOM"} ,
		{"word":"radoteurs","word_nosc":"radoteurs","lemma":"radoteur","pos":"NOM"} ,
		{"word":"radoteuses","word_nosc":"radoteuses","lemma":"radoteur","pos":"NOM"} ,
		{"word":"radoub","word_nosc":"radoub","lemma":"radoub","pos":"NOM"} ,
		{"word":"radoubeur","word_nosc":"radoubeur","lemma":"radoubeur","pos":"NOM"} ,
		{"word":"radoucissement","word_nosc":"radoucissement","lemma":"radoucissement","pos":"NOM"} ,
		{"word":"rads","word_nosc":"rads","lemma":"rad","pos":"NOM"} ,
		{"word":"radôme","word_nosc":"radome","lemma":"radôme","pos":"NOM"} ,
		{"word":"rafale","word_nosc":"rafale","lemma":"rafale","pos":"NOM"} ,
		{"word":"rafales","word_nosc":"rafales","lemma":"rafale","pos":"NOM"} ,
		{"word":"raff","word_nosc":"raff","lemma":"raff","pos":"NOM"} ,
		{"word":"raffinage","word_nosc":"raffinage","lemma":"raffinage","pos":"NOM"} ,
		{"word":"raffinement","word_nosc":"raffinement","lemma":"raffinement","pos":"NOM"} ,
		{"word":"raffinements","word_nosc":"raffinements","lemma":"raffinement","pos":"NOM"} ,
		{"word":"raffinerie","word_nosc":"raffinerie","lemma":"raffinerie","pos":"NOM"} ,
		{"word":"raffineries","word_nosc":"raffineries","lemma":"raffinerie","pos":"NOM"} ,
		{"word":"raffineur","word_nosc":"raffineur","lemma":"raffineur","pos":"NOM"} ,
		{"word":"raffut","word_nosc":"raffut","lemma":"raffut","pos":"NOM"} ,
		{"word":"raffuts","word_nosc":"raffuts","lemma":"raffut","pos":"NOM"} ,
		{"word":"rafiau","word_nosc":"rafiau","lemma":"rafiau","pos":"NOM"} ,
		{"word":"rafiot","word_nosc":"rafiot","lemma":"rafiot","pos":"NOM"} ,
		{"word":"rafiots","word_nosc":"rafiots","lemma":"rafiot","pos":"NOM"} ,
		{"word":"rafistolage","word_nosc":"rafistolage","lemma":"rafistolage","pos":"NOM"} ,
		{"word":"rafistolages","word_nosc":"rafistolages","lemma":"rafistolage","pos":"NOM"} ,
		{"word":"rafle","word_nosc":"rafle","lemma":"rafle","pos":"NOM"} ,
		{"word":"rafles","word_nosc":"rafles","lemma":"rafle","pos":"NOM"} ,
		{"word":"rafraîchissement","word_nosc":"rafraichissement","lemma":"rafraîchissement","pos":"NOM"} ,
		{"word":"rafraîchissements","word_nosc":"rafraichissements","lemma":"rafraîchissement","pos":"NOM"} ,
		{"word":"rafraîchisseur","word_nosc":"rafraichisseur","lemma":"rafraîchisseur","pos":"NOM"} ,
		{"word":"rafraîchissoir","word_nosc":"rafraichissoir","lemma":"rafraîchissoir","pos":"NOM"} ,
		{"word":"rafraîchissoirs","word_nosc":"rafraichissoirs","lemma":"rafraîchissoir","pos":"NOM"} ,
		{"word":"raft","word_nosc":"raft","lemma":"raft","pos":"NOM"} ,
		{"word":"rafting","word_nosc":"rafting","lemma":"rafting","pos":"NOM"} ,
		{"word":"rag","word_nosc":"rag","lemma":"rag","pos":"NOM"} ,
		{"word":"raga","word_nosc":"raga","lemma":"raga","pos":"NOM"} ,
		{"word":"rage","word_nosc":"rage","lemma":"rage","pos":"NOM"} ,
		{"word":"rages","word_nosc":"rages","lemma":"rage","pos":"NOM"} ,
		{"word":"raglan","word_nosc":"raglan","lemma":"raglan","pos":"NOM"} ,
		{"word":"raglans","word_nosc":"raglans","lemma":"raglan","pos":"NOM"} ,
		{"word":"ragnagnas","word_nosc":"ragnagnas","lemma":"ragnagnas","pos":"NOM"} ,
		{"word":"ragondins","word_nosc":"ragondins","lemma":"ragondin","pos":"NOM"} ,
		{"word":"ragot","word_nosc":"ragot","lemma":"ragot","pos":"NOM"} ,
		{"word":"ragots","word_nosc":"ragots","lemma":"ragot","pos":"NOM"} ,
		{"word":"ragougnasse","word_nosc":"ragougnasse","lemma":"ragougnasse","pos":"NOM"} ,
		{"word":"ragougnasses","word_nosc":"ragougnasses","lemma":"ragougnasse","pos":"NOM"} ,
		{"word":"ragoût","word_nosc":"ragout","lemma":"ragoût","pos":"NOM"} ,
		{"word":"ragoûts","word_nosc":"ragouts","lemma":"ragoût","pos":"NOM"} ,
		{"word":"ragtime","word_nosc":"ragtime","lemma":"ragtime","pos":"NOM"} ,
		{"word":"rahat-lokoum","word_nosc":"rahat-lokoum","lemma":"rahat-lokoum","pos":"NOM"} ,
		{"word":"rahat-lokoums","word_nosc":"rahat-lokoums","lemma":"rahat-lokoum","pos":"NOM"} ,
		{"word":"rahat-loukoums","word_nosc":"rahat-loukoums","lemma":"rahat-loukoum","pos":"NOM"} ,
		{"word":"rai","word_nosc":"rai","lemma":"rai","pos":"NOM"} ,
		{"word":"raid","word_nosc":"raid","lemma":"raid","pos":"NOM"} ,
		{"word":"raider","word_nosc":"raider","lemma":"raider","pos":"NOM"} ,
		{"word":"raiders","word_nosc":"raiders","lemma":"raider","pos":"NOM"} ,
		{"word":"raideur","word_nosc":"raideur","lemma":"raideur","pos":"NOM"} ,
		{"word":"raidillon","word_nosc":"raidillon","lemma":"raidillon","pos":"NOM"} ,
		{"word":"raidillons","word_nosc":"raidillons","lemma":"raidillon","pos":"NOM"} ,
		{"word":"raidissement","word_nosc":"raidissement","lemma":"raidissement","pos":"NOM"} ,
		{"word":"raidissements","word_nosc":"raidissements","lemma":"raidissement","pos":"NOM"} ,
		{"word":"raids","word_nosc":"raids","lemma":"raid","pos":"NOM"} ,
		{"word":"raie","word_nosc":"raie","lemma":"raie","pos":"NOM"} ,
		{"word":"raies","word_nosc":"raies","lemma":"raie","pos":"NOM"} ,
		{"word":"raifort","word_nosc":"raifort","lemma":"raifort","pos":"NOM"} ,
		{"word":"rail","word_nosc":"rail","lemma":"rail","pos":"NOM"} ,
		{"word":"raillerie","word_nosc":"raillerie","lemma":"raillerie","pos":"NOM"} ,
		{"word":"railleries","word_nosc":"railleries","lemma":"raillerie","pos":"NOM"} ,
		{"word":"railleur","word_nosc":"railleur","lemma":"railleur","pos":"NOM"} ,
		{"word":"railleurs","word_nosc":"railleurs","lemma":"railleur","pos":"NOM"} ,
		{"word":"rails","word_nosc":"rails","lemma":"rail","pos":"NOM"} ,
		{"word":"railway","word_nosc":"railway","lemma":"railway","pos":"NOM"} ,
		{"word":"rain","word_nosc":"rain","lemma":"rain","pos":"NOM"} ,
		{"word":"rainette","word_nosc":"rainette","lemma":"rainette","pos":"NOM"} ,
		{"word":"rainettes","word_nosc":"rainettes","lemma":"rainette","pos":"NOM"} ,
		{"word":"raineuse","word_nosc":"raineuse","lemma":"raineuse","pos":"NOM"} ,
		{"word":"rainure","word_nosc":"rainure","lemma":"rainure","pos":"NOM"} ,
		{"word":"rainures","word_nosc":"rainures","lemma":"rainure","pos":"NOM"} ,
		{"word":"raiponce","word_nosc":"raiponce","lemma":"raiponce","pos":"NOM"} ,
		{"word":"rais","word_nosc":"rais","lemma":"rai","pos":"NOM"} ,
		{"word":"raisin","word_nosc":"raisin","lemma":"raisin","pos":"NOM"} ,
		{"word":"raisinets","word_nosc":"raisinets","lemma":"raisinet","pos":"NOM"} ,
		{"word":"raisins","word_nosc":"raisins","lemma":"raisin","pos":"NOM"} ,
		{"word":"raisiné","word_nosc":"raisine","lemma":"raisiné","pos":"NOM"} ,
		{"word":"raison","word_nosc":"raison","lemma":"raison","pos":"NOM"} ,
		{"word":"raisonnement","word_nosc":"raisonnement","lemma":"raisonnement","pos":"NOM"} ,
		{"word":"raisonnements","word_nosc":"raisonnements","lemma":"raisonnement","pos":"NOM"} ,
		{"word":"raisonneur","word_nosc":"raisonneur","lemma":"raisonneur","pos":"NOM"} ,
		{"word":"raisonneurs","word_nosc":"raisonneurs","lemma":"raisonneur","pos":"NOM"} ,
		{"word":"raisonneuse","word_nosc":"raisonneuse","lemma":"raisonneur","pos":"NOM"} ,
		{"word":"raisons","word_nosc":"raisons","lemma":"raison","pos":"NOM"} ,
		{"word":"raja","word_nosc":"raja","lemma":"raja","pos":"NOM"} ,
		{"word":"rajah","word_nosc":"rajah","lemma":"rajah","pos":"NOM"} ,
		{"word":"rajahs","word_nosc":"rajahs","lemma":"rajah","pos":"NOM"} ,
		{"word":"rajeunissement","word_nosc":"rajeunissement","lemma":"rajeunissement","pos":"NOM"} ,
		{"word":"rajeunisseurs","word_nosc":"rajeunisseurs","lemma":"rajeunisseur","pos":"NOM"} ,
		{"word":"rajout","word_nosc":"rajout","lemma":"rajout","pos":"NOM"} ,
		{"word":"rajouts","word_nosc":"rajouts","lemma":"rajout","pos":"NOM"} ,
		{"word":"rajustement","word_nosc":"rajustement","lemma":"rajustement","pos":"NOM"} ,
		{"word":"raki","word_nosc":"raki","lemma":"raki","pos":"NOM"} ,
		{"word":"ralbol","word_nosc":"ralbol","lemma":"ralbol","pos":"NOM"} ,
		{"word":"ralenti","word_nosc":"ralenti","lemma":"ralenti","pos":"NOM"} ,
		{"word":"ralentis","word_nosc":"ralentis","lemma":"ralenti","pos":"NOM"} ,
		{"word":"ralentissement","word_nosc":"ralentissement","lemma":"ralentissement","pos":"NOM"} ,
		{"word":"ralentissements","word_nosc":"ralentissements","lemma":"ralentissement","pos":"NOM"} ,
		{"word":"ralentisseur","word_nosc":"ralentisseur","lemma":"ralentisseur","pos":"NOM"} ,
		{"word":"ralentisseurs","word_nosc":"ralentisseurs","lemma":"ralentisseur","pos":"NOM"} ,
		{"word":"ralingue","word_nosc":"ralingue","lemma":"ralingue","pos":"NOM"} ,
		{"word":"ralingues","word_nosc":"ralingues","lemma":"ralingue","pos":"NOM"} ,
		{"word":"ralliement","word_nosc":"ralliement","lemma":"ralliement","pos":"NOM"} ,
		{"word":"ralliements","word_nosc":"ralliements","lemma":"ralliement","pos":"NOM"} ,
		{"word":"rallié","word_nosc":"rallie","lemma":"rallié","pos":"NOM"} ,
		{"word":"ralliés","word_nosc":"rallies","lemma":"rallié","pos":"NOM"} ,
		{"word":"rallonge","word_nosc":"rallonge","lemma":"rallonge","pos":"NOM"} ,
		{"word":"rallongement","word_nosc":"rallongement","lemma":"rallongement","pos":"NOM"} ,
		{"word":"rallonges","word_nosc":"rallonges","lemma":"rallonge","pos":"NOM"} ,
		{"word":"rallumage","word_nosc":"rallumage","lemma":"rallumage","pos":"NOM"} ,
		{"word":"rallye","word_nosc":"rallye","lemma":"rallye","pos":"NOM"} ,
		{"word":"rallyes","word_nosc":"rallyes","lemma":"rallye","pos":"NOM"} ,
		{"word":"ram","word_nosc":"ram","lemma":"ram","pos":"NOM"} ,
		{"word":"ramadan","word_nosc":"ramadan","lemma":"ramadan","pos":"NOM"} ,
		{"word":"ramage","word_nosc":"ramage","lemma":"ramage","pos":"NOM"} ,
		{"word":"ramages","word_nosc":"ramages","lemma":"ramage","pos":"NOM"} ,
		{"word":"ramas","word_nosc":"ramas","lemma":"ramas","pos":"NOM"} ,
		{"word":"ramassage","word_nosc":"ramassage","lemma":"ramassage","pos":"NOM"} ,
		{"word":"ramassages","word_nosc":"ramassages","lemma":"ramassage","pos":"NOM"} ,
		{"word":"ramasse-miettes","word_nosc":"ramasse-miettes","lemma":"ramasse-miettes","pos":"NOM"} ,
		{"word":"ramasse-poussière","word_nosc":"ramasse-poussiere","lemma":"ramasse-poussière","pos":"NOM"} ,
		{"word":"ramasseur","word_nosc":"ramasseur","lemma":"ramasseur","pos":"NOM"} ,
		{"word":"ramasseurs","word_nosc":"ramasseurs","lemma":"ramasseur","pos":"NOM"} ,
		{"word":"ramasseuse","word_nosc":"ramasseuse","lemma":"ramasseur","pos":"NOM"} ,
		{"word":"ramassis","word_nosc":"ramassis","lemma":"ramassis","pos":"NOM"} ,
		{"word":"rambarde","word_nosc":"rambarde","lemma":"rambarde","pos":"NOM"} ,
		{"word":"rambardes","word_nosc":"rambardes","lemma":"rambarde","pos":"NOM"} ,
		{"word":"rambin","word_nosc":"rambin","lemma":"rambin","pos":"NOM"} ,
		{"word":"rambineur","word_nosc":"rambineur","lemma":"rambineur","pos":"NOM"} ,
		{"word":"rambla","word_nosc":"rambla","lemma":"rambla","pos":"NOM"} ,
		{"word":"rambot","word_nosc":"rambot","lemma":"rambot","pos":"NOM"} ,
		{"word":"rambour","word_nosc":"rambour","lemma":"rambour","pos":"NOM"} ,
		{"word":"ramdam","word_nosc":"ramdam","lemma":"ramdam","pos":"NOM"} ,
		{"word":"ramdams","word_nosc":"ramdams","lemma":"ramdam","pos":"NOM"} ,
		{"word":"rame","word_nosc":"rame","lemma":"rame","pos":"NOM"} ,
		{"word":"rameau","word_nosc":"rameau","lemma":"rameau","pos":"NOM"} ,
		{"word":"rameaux","word_nosc":"rameaux","lemma":"rameau","pos":"NOM"} ,
		{"word":"ramenard","word_nosc":"ramenard","lemma":"ramenard","pos":"NOM"} ,
		{"word":"rames","word_nosc":"rames","lemma":"rame","pos":"NOM"} ,
		{"word":"ramette","word_nosc":"ramette","lemma":"ramette","pos":"NOM"} ,
		{"word":"rameur","word_nosc":"rameur","lemma":"rameur","pos":"NOM"} ,
		{"word":"rameurs","word_nosc":"rameurs","lemma":"rameur","pos":"NOM"} ,
		{"word":"rami","word_nosc":"rami","lemma":"rami","pos":"NOM"} ,
		{"word":"ramier","word_nosc":"ramier","lemma":"ramier","pos":"NOM"} ,
		{"word":"ramiers","word_nosc":"ramiers","lemma":"ramier","pos":"NOM"} ,
		{"word":"ramies","word_nosc":"ramies","lemma":"ramie","pos":"NOM"} ,
		{"word":"ramification","word_nosc":"ramification","lemma":"ramification","pos":"NOM"} ,
		{"word":"ramifications","word_nosc":"ramifications","lemma":"ramification","pos":"NOM"} ,
		{"word":"ramille","word_nosc":"ramille","lemma":"ramille","pos":"NOM"} ,
		{"word":"ramilles","word_nosc":"ramilles","lemma":"ramille","pos":"NOM"} ,
		{"word":"raminagrobis","word_nosc":"raminagrobis","lemma":"raminagrobis","pos":"NOM"} ,
		{"word":"ramis","word_nosc":"ramis","lemma":"rami","pos":"NOM"} ,
		{"word":"ramollissement","word_nosc":"ramollissement","lemma":"ramollissement","pos":"NOM"} ,
		{"word":"ramon","word_nosc":"ramon","lemma":"ramon","pos":"NOM"} ,
		{"word":"ramonage","word_nosc":"ramonage","lemma":"ramonage","pos":"NOM"} ,
		{"word":"ramonages","word_nosc":"ramonages","lemma":"ramonage","pos":"NOM"} ,
		{"word":"ramoneur","word_nosc":"ramoneur","lemma":"ramoneur","pos":"NOM"} ,
		{"word":"ramoneurs","word_nosc":"ramoneurs","lemma":"ramoneur","pos":"NOM"} ,
		{"word":"rampant","word_nosc":"rampant","lemma":"rampant","pos":"NOM"} ,
		{"word":"rampants","word_nosc":"rampants","lemma":"rampant","pos":"NOM"} ,
		{"word":"rampe","word_nosc":"rampe","lemma":"rampe","pos":"NOM"} ,
		{"word":"rampement","word_nosc":"rampement","lemma":"rampement","pos":"NOM"} ,
		{"word":"rampements","word_nosc":"rampements","lemma":"rampement","pos":"NOM"} ,
		{"word":"rampes","word_nosc":"rampes","lemma":"rampe","pos":"NOM"} ,
		{"word":"ramponneau","word_nosc":"ramponneau","lemma":"ramponneau","pos":"NOM"} ,
		{"word":"rams","word_nosc":"rams","lemma":"rams","pos":"NOM"} ,
		{"word":"ramure","word_nosc":"ramure","lemma":"ramure","pos":"NOM"} ,
		{"word":"ramures","word_nosc":"ramures","lemma":"ramure","pos":"NOM"} ,
		{"word":"ramée","word_nosc":"ramee","lemma":"ramée","pos":"NOM"} ,
		{"word":"ramées","word_nosc":"ramees","lemma":"ramée","pos":"NOM"} ,
		{"word":"ran","word_nosc":"ran","lemma":"ran","pos":"NOM"} ,
		{"word":"ranatres","word_nosc":"ranatres","lemma":"ranatre","pos":"NOM"} ,
		{"word":"rancard","word_nosc":"rancard","lemma":"rancard","pos":"NOM"} ,
		{"word":"rancards","word_nosc":"rancards","lemma":"rancard","pos":"NOM"} ,
		{"word":"rancart","word_nosc":"rancart","lemma":"rancart","pos":"NOM"} ,
		{"word":"rancarts","word_nosc":"rancarts","lemma":"rancart","pos":"NOM"} ,
		{"word":"ranch","word_nosc":"ranch","lemma":"ranch","pos":"NOM"} ,
		{"word":"ranche","word_nosc":"ranche","lemma":"ranche","pos":"NOM"} ,
		{"word":"rancher","word_nosc":"rancher","lemma":"rancher","pos":"NOM"} ,
		{"word":"ranches","word_nosc":"ranches","lemma":"ranche","pos":"NOM"} ,
		{"word":"rancho","word_nosc":"rancho","lemma":"rancho","pos":"NOM"} ,
		{"word":"ranchs","word_nosc":"ranchs","lemma":"ranch","pos":"NOM"} ,
		{"word":"ranci","word_nosc":"ranci","lemma":"ranci","pos":"NOM"} ,
		{"word":"rancio","word_nosc":"rancio","lemma":"rancio","pos":"NOM"} ,
		{"word":"rancissure","word_nosc":"rancissure","lemma":"rancissure","pos":"NOM"} ,
		{"word":"rancoeur","word_nosc":"rancoeur","lemma":"rancoeur","pos":"NOM"} ,
		{"word":"rancoeurs","word_nosc":"rancoeurs","lemma":"rancoeur","pos":"NOM"} ,
		{"word":"rancune","word_nosc":"rancune","lemma":"rancune","pos":"NOM"} ,
		{"word":"rancunes","word_nosc":"rancunes","lemma":"rancune","pos":"NOM"} ,
		{"word":"rancunier","word_nosc":"rancunier","lemma":"rancunier","pos":"NOM"} ,
		{"word":"rancuniers","word_nosc":"rancuniers","lemma":"rancunier","pos":"NOM"} ,
		{"word":"rancunière","word_nosc":"rancuniere","lemma":"rancunier","pos":"NOM"} ,
		{"word":"randonneur","word_nosc":"randonneur","lemma":"randonneur","pos":"NOM"} ,
		{"word":"randonneurs","word_nosc":"randonneurs","lemma":"randonneur","pos":"NOM"} ,
		{"word":"randonneuse","word_nosc":"randonneuse","lemma":"randonneur","pos":"NOM"} ,
		{"word":"randonnée","word_nosc":"randonnee","lemma":"randonnée","pos":"NOM"} ,
		{"word":"randonnées","word_nosc":"randonnees","lemma":"randonnée","pos":"NOM"} ,
		{"word":"rang","word_nosc":"rang","lemma":"rang","pos":"NOM"} ,
		{"word":"rangement","word_nosc":"rangement","lemma":"rangement","pos":"NOM"} ,
		{"word":"rangements","word_nosc":"rangements","lemma":"rangement","pos":"NOM"} ,
		{"word":"ranger","word_nosc":"ranger","lemma":"ranger","pos":"NOM"} ,
		{"word":"rangers","word_nosc":"rangers","lemma":"ranger","pos":"NOM"} ,
		{"word":"rangs","word_nosc":"rangs","lemma":"rang","pos":"NOM"} ,
		{"word":"rangée","word_nosc":"rangee","lemma":"rangée","pos":"NOM"} ,
		{"word":"rangées","word_nosc":"rangees","lemma":"rangée","pos":"NOM"} ,
		{"word":"rani","word_nosc":"rani","lemma":"rani","pos":"NOM"} ,
		{"word":"ranimation","word_nosc":"ranimation","lemma":"ranimation","pos":"NOM"} ,
		{"word":"ranz","word_nosc":"ranz","lemma":"ranz","pos":"NOM"} ,
		{"word":"rançon","word_nosc":"rancon","lemma":"rançon","pos":"NOM"} ,
		{"word":"rançonnage","word_nosc":"ranconnage","lemma":"rançonnage","pos":"NOM"} ,
		{"word":"rançonnement","word_nosc":"ranconnement","lemma":"rançonnement","pos":"NOM"} ,
		{"word":"rançonneurs","word_nosc":"ranconneurs","lemma":"rançonneur","pos":"NOM"} ,
		{"word":"rançons","word_nosc":"rancons","lemma":"rançon","pos":"NOM"} ,
		{"word":"raout","word_nosc":"raout","lemma":"raout","pos":"NOM"} ,
		{"word":"raouts","word_nosc":"raouts","lemma":"raout","pos":"NOM"} ,
		{"word":"rap","word_nosc":"rap","lemma":"rap","pos":"NOM"} ,
		{"word":"rapace","word_nosc":"rapace","lemma":"rapace","pos":"NOM"} ,
		{"word":"rapaces","word_nosc":"rapaces","lemma":"rapace","pos":"NOM"} ,
		{"word":"rapacité","word_nosc":"rapacite","lemma":"rapacité","pos":"NOM"} ,
		{"word":"rapatriement","word_nosc":"rapatriement","lemma":"rapatriement","pos":"NOM"} ,
		{"word":"rapatriements","word_nosc":"rapatriements","lemma":"rapatriement","pos":"NOM"} ,
		{"word":"rapatrié","word_nosc":"rapatrie","lemma":"rapatrié","pos":"NOM"} ,
		{"word":"rapatriés","word_nosc":"rapatries","lemma":"rapatrié","pos":"NOM"} ,
		{"word":"rapetassage","word_nosc":"rapetassage","lemma":"rapetassage","pos":"NOM"} ,
		{"word":"rapetassages","word_nosc":"rapetassages","lemma":"rapetassage","pos":"NOM"} ,
		{"word":"rapetasseur","word_nosc":"rapetasseur","lemma":"rapetasseur","pos":"NOM"} ,
		{"word":"rapetissement","word_nosc":"rapetissement","lemma":"rapetissement","pos":"NOM"} ,
		{"word":"raphia","word_nosc":"raphia","lemma":"raphia","pos":"NOM"} ,
		{"word":"raphé","word_nosc":"raphe","lemma":"raphé","pos":"NOM"} ,
		{"word":"rapiat","word_nosc":"rapiat","lemma":"rapiat","pos":"NOM"} ,
		{"word":"rapiaterie","word_nosc":"rapiaterie","lemma":"rapiaterie","pos":"NOM"} ,
		{"word":"rapiats","word_nosc":"rapiats","lemma":"rapiat","pos":"NOM"} ,
		{"word":"rapide","word_nosc":"rapide","lemma":"rapide","pos":"NOM"} ,
		{"word":"rapides","word_nosc":"rapides","lemma":"rapide","pos":"NOM"} ,
		{"word":"rapidité","word_nosc":"rapidite","lemma":"rapidité","pos":"NOM"} ,
		{"word":"rapidités","word_nosc":"rapidites","lemma":"rapidité","pos":"NOM"} ,
		{"word":"rapin","word_nosc":"rapin","lemma":"rapin","pos":"NOM"} ,
		{"word":"rapine","word_nosc":"rapine","lemma":"rapine","pos":"NOM"} ,
		{"word":"rapines","word_nosc":"rapines","lemma":"rapine","pos":"NOM"} ,
		{"word":"rapins","word_nosc":"rapins","lemma":"rapin","pos":"NOM"} ,
		{"word":"rapière","word_nosc":"rapiere","lemma":"rapière","pos":"NOM"} ,
		{"word":"rapiéçage","word_nosc":"rapiecage","lemma":"rapiéçage","pos":"NOM"} ,
		{"word":"rapiéçages","word_nosc":"rapiecages","lemma":"rapiéçage","pos":"NOM"} ,
		{"word":"rappel","word_nosc":"rappel","lemma":"rappel","pos":"NOM"} ,
		{"word":"rappels","word_nosc":"rappels","lemma":"rappel","pos":"NOM"} ,
		{"word":"rappeur","word_nosc":"rappeur","lemma":"rappeur","pos":"NOM"} ,
		{"word":"rappeurs","word_nosc":"rappeurs","lemma":"rappeur","pos":"NOM"} ,
		{"word":"rappeuse","word_nosc":"rappeuse","lemma":"rappeur","pos":"NOM"} ,
		{"word":"rapport","word_nosc":"rapport","lemma":"rapport","pos":"NOM"} ,
		{"word":"rapportage","word_nosc":"rapportage","lemma":"rapportage","pos":"NOM"} ,
		{"word":"rapporteur","word_nosc":"rapporteur","lemma":"rapporteur","pos":"NOM"} ,
		{"word":"rapporteurs","word_nosc":"rapporteurs","lemma":"rapporteur","pos":"NOM"} ,
		{"word":"rapporteuse","word_nosc":"rapporteuse","lemma":"rapporteur","pos":"NOM"} ,
		{"word":"rapporteuses","word_nosc":"rapporteuses","lemma":"rapporteur","pos":"NOM"} ,
		{"word":"rapports","word_nosc":"rapports","lemma":"rapport","pos":"NOM"} ,
		{"word":"rapprochement","word_nosc":"rapprochement","lemma":"rapprochement","pos":"NOM"} ,
		{"word":"rapprochements","word_nosc":"rapprochements","lemma":"rapprochement","pos":"NOM"} ,
		{"word":"rapprocheur","word_nosc":"rapprocheur","lemma":"rapprocheur","pos":"NOM"} ,
		{"word":"rapsodie","word_nosc":"rapsodie","lemma":"rapsodie","pos":"NOM"} ,
		{"word":"rapt","word_nosc":"rapt","lemma":"rapt","pos":"NOM"} ,
		{"word":"rapts","word_nosc":"rapts","lemma":"rapt","pos":"NOM"} ,
		{"word":"raquette","word_nosc":"raquette","lemma":"raquette","pos":"NOM"} ,
		{"word":"raquettes","word_nosc":"raquettes","lemma":"raquette","pos":"NOM"} ,
		{"word":"rareté","word_nosc":"rarete","lemma":"rareté","pos":"NOM"} ,
		{"word":"raretés","word_nosc":"raretes","lemma":"rareté","pos":"NOM"} ,
		{"word":"raréfaction","word_nosc":"rarefaction","lemma":"raréfaction","pos":"NOM"} ,
		{"word":"ras","word_nosc":"ras","lemma":"ras","pos":"NOM"} ,
		{"word":"ras-le-bol","word_nosc":"ras-le-bol","lemma":"ras-le-bol","pos":"NOM"} ,
		{"word":"rasade","word_nosc":"rasade","lemma":"rasade","pos":"NOM"} ,
		{"word":"rasades","word_nosc":"rasades","lemma":"rasade","pos":"NOM"} ,
		{"word":"rasage","word_nosc":"rasage","lemma":"rasage","pos":"NOM"} ,
		{"word":"rasages","word_nosc":"rasages","lemma":"rasage","pos":"NOM"} ,
		{"word":"rascasse","word_nosc":"rascasse","lemma":"rascasse","pos":"NOM"} ,
		{"word":"rascasses","word_nosc":"rascasses","lemma":"rascasse","pos":"NOM"} ,
		{"word":"rase-mottes","word_nosc":"rase-mottes","lemma":"rase-mottes","pos":"NOM"} ,
		{"word":"rase-pet","word_nosc":"rase-pet","lemma":"rase-pet","pos":"NOM"} ,
		{"word":"rase-pets","word_nosc":"rase-pets","lemma":"rase-pet","pos":"NOM"} ,
		{"word":"raseur","word_nosc":"raseur","lemma":"raseur","pos":"NOM"} ,
		{"word":"raseurs","word_nosc":"raseurs","lemma":"raseur","pos":"NOM"} ,
		{"word":"raseuse","word_nosc":"raseuse","lemma":"raseur","pos":"NOM"} ,
		{"word":"raseuses","word_nosc":"raseuses","lemma":"raseur","pos":"NOM"} ,
		{"word":"rash","word_nosc":"rash","lemma":"rash","pos":"NOM"} ,
		{"word":"rasif","word_nosc":"rasif","lemma":"rasif","pos":"NOM"} ,
		{"word":"rasifs","word_nosc":"rasifs","lemma":"rasif","pos":"NOM"} ,
		{"word":"rasoir","word_nosc":"rasoir","lemma":"rasoir","pos":"NOM"} ,
		{"word":"rasoirs","word_nosc":"rasoirs","lemma":"rasoir","pos":"NOM"} ,
		{"word":"rasse","word_nosc":"rasse","lemma":"rasse","pos":"NOM"} ,
		{"word":"rassemblement","word_nosc":"rassemblement","lemma":"rassemblement","pos":"NOM"} ,
		{"word":"rassemblements","word_nosc":"rassemblements","lemma":"rassemblement","pos":"NOM"} ,
		{"word":"rassembleur","word_nosc":"rassembleur","lemma":"rassembleur","pos":"NOM"} ,
		{"word":"rassortiment","word_nosc":"rassortiment","lemma":"rassortiment","pos":"NOM"} ,
		{"word":"rassurement","word_nosc":"rassurement","lemma":"rassurement","pos":"NOM"} ,
		{"word":"rasta","word_nosc":"rasta","lemma":"rasta","pos":"NOM"} ,
		{"word":"rastafari","word_nosc":"rastafari","lemma":"rastafari","pos":"NOM"} ,
		{"word":"rastaquouère","word_nosc":"rastaquouere","lemma":"rastaquouère","pos":"NOM"} ,
		{"word":"rastaquouères","word_nosc":"rastaquoueres","lemma":"rastaquouère","pos":"NOM"} ,
		{"word":"rastas","word_nosc":"rastas","lemma":"rasta","pos":"NOM"} ,
		{"word":"rat","word_nosc":"rat","lemma":"rat","pos":"NOM"} ,
		{"word":"rat-de-cave","word_nosc":"rat-de-cave","lemma":"rat-de-cave","pos":"NOM"} ,
		{"word":"rata","word_nosc":"rata","lemma":"rata","pos":"NOM"} ,
		{"word":"ratafia","word_nosc":"ratafia","lemma":"ratafia","pos":"NOM"} ,
		{"word":"ratage","word_nosc":"ratage","lemma":"ratage","pos":"NOM"} ,
		{"word":"ratages","word_nosc":"ratages","lemma":"ratage","pos":"NOM"} ,
		{"word":"ratatouille","word_nosc":"ratatouille","lemma":"ratatouille","pos":"NOM"} ,
		{"word":"ratatouilles","word_nosc":"ratatouilles","lemma":"ratatouille","pos":"NOM"} ,
		{"word":"rate","word_nosc":"rate","lemma":"rate","pos":"NOM"} ,
		{"word":"rates","word_nosc":"rates","lemma":"rate","pos":"NOM"} ,
		{"word":"ratiche","word_nosc":"ratiche","lemma":"ratiche","pos":"NOM"} ,
		{"word":"ratiches","word_nosc":"ratiches","lemma":"ratiche","pos":"NOM"} ,
		{"word":"ratichon","word_nosc":"ratichon","lemma":"ratichon","pos":"NOM"} ,
		{"word":"ratichons","word_nosc":"ratichons","lemma":"ratichon","pos":"NOM"} ,
		{"word":"raticide","word_nosc":"raticide","lemma":"raticide","pos":"NOM"} ,
		{"word":"ratier","word_nosc":"ratier","lemma":"ratier","pos":"NOM"} ,
		{"word":"ratification","word_nosc":"ratification","lemma":"ratification","pos":"NOM"} ,
		{"word":"ratine","word_nosc":"ratine","lemma":"ratine","pos":"NOM"} ,
		{"word":"ratio","word_nosc":"ratio","lemma":"ratio","pos":"NOM"} ,
		{"word":"ratiocination","word_nosc":"ratiocination","lemma":"ratiocination","pos":"NOM"} ,
		{"word":"ratiocinations","word_nosc":"ratiocinations","lemma":"ratiocination","pos":"NOM"} ,
		{"word":"ratiocineur","word_nosc":"ratiocineur","lemma":"ratiocineur","pos":"NOM"} ,
		{"word":"ration","word_nosc":"ration","lemma":"ration","pos":"NOM"} ,
		{"word":"rational","word_nosc":"rational","lemma":"rational","pos":"NOM"} ,
		{"word":"rationalisation","word_nosc":"rationalisation","lemma":"rationalisation","pos":"NOM"} ,
		{"word":"rationalisme","word_nosc":"rationalisme","lemma":"rationalisme","pos":"NOM"} ,
		{"word":"rationaliste","word_nosc":"rationaliste","lemma":"rationaliste","pos":"NOM"} ,
		{"word":"rationalistes","word_nosc":"rationalistes","lemma":"rationaliste","pos":"NOM"} ,
		{"word":"rationalité","word_nosc":"rationalite","lemma":"rationalité","pos":"NOM"} ,
		{"word":"rationnel","word_nosc":"rationnel","lemma":"rationnel","pos":"NOM"} ,
		{"word":"rationnels","word_nosc":"rationnels","lemma":"rationnel","pos":"NOM"} ,
		{"word":"rationnement","word_nosc":"rationnement","lemma":"rationnement","pos":"NOM"} ,
		{"word":"rations","word_nosc":"rations","lemma":"ration","pos":"NOM"} ,
		{"word":"ratissage","word_nosc":"ratissage","lemma":"ratissage","pos":"NOM"} ,
		{"word":"ratite","word_nosc":"ratite","lemma":"ratite","pos":"NOM"} ,
		{"word":"ratites","word_nosc":"ratites","lemma":"ratite","pos":"NOM"} ,
		{"word":"ratière","word_nosc":"ratiere","lemma":"ratière","pos":"NOM"} ,
		{"word":"ratières","word_nosc":"ratieres","lemma":"ratière","pos":"NOM"} ,
		{"word":"raton","word_nosc":"raton","lemma":"raton","pos":"NOM"} ,
		{"word":"ratonnade","word_nosc":"ratonnade","lemma":"ratonnade","pos":"NOM"} ,
		{"word":"ratonnades","word_nosc":"ratonnades","lemma":"ratonnade","pos":"NOM"} ,
		{"word":"ratons","word_nosc":"ratons","lemma":"raton","pos":"NOM"} ,
		{"word":"rats","word_nosc":"rats","lemma":"rat","pos":"NOM"} ,
		{"word":"rattachement","word_nosc":"rattachement","lemma":"rattachement","pos":"NOM"} ,
		{"word":"ratte","word_nosc":"ratte","lemma":"ratte","pos":"NOM"} ,
		{"word":"rattrapage","word_nosc":"rattrapage","lemma":"rattrapage","pos":"NOM"} ,
		{"word":"rattrapages","word_nosc":"rattrapages","lemma":"rattrapage","pos":"NOM"} ,
		{"word":"rature","word_nosc":"rature","lemma":"rature","pos":"NOM"} ,
		{"word":"ratures","word_nosc":"ratures","lemma":"rature","pos":"NOM"} ,
		{"word":"raté","word_nosc":"rate","lemma":"raté","pos":"NOM"} ,
		{"word":"ratée","word_nosc":"ratee","lemma":"raté","pos":"NOM"} ,
		{"word":"ratées","word_nosc":"ratees","lemma":"raté","pos":"NOM"} ,
		{"word":"ratés","word_nosc":"rates","lemma":"raté","pos":"NOM"} ,
		{"word":"raucité","word_nosc":"raucite","lemma":"raucité","pos":"NOM"} ,
		{"word":"rauquement","word_nosc":"rauquement","lemma":"rauquement","pos":"NOM"} ,
		{"word":"ravage","word_nosc":"ravage","lemma":"ravage","pos":"NOM"} ,
		{"word":"ravages","word_nosc":"ravages","lemma":"ravage","pos":"NOM"} ,
		{"word":"ravageur","word_nosc":"ravageur","lemma":"ravageur","pos":"NOM"} ,
		{"word":"ravageurs","word_nosc":"ravageurs","lemma":"ravageur","pos":"NOM"} ,
		{"word":"ravageuse","word_nosc":"ravageuse","lemma":"ravageur","pos":"NOM"} ,
		{"word":"ravageuses","word_nosc":"ravageuses","lemma":"ravageur","pos":"NOM"} ,
		{"word":"raval","word_nosc":"raval","lemma":"raval","pos":"NOM"} ,
		{"word":"ravalement","word_nosc":"ravalement","lemma":"ravalement","pos":"NOM"} ,
		{"word":"ravaleur","word_nosc":"ravaleur","lemma":"ravaleur","pos":"NOM"} ,
		{"word":"ravaudage","word_nosc":"ravaudage","lemma":"ravaudage","pos":"NOM"} ,
		{"word":"rave","word_nosc":"rave","lemma":"rave","pos":"NOM"} ,
		{"word":"ravenelles","word_nosc":"ravenelles","lemma":"ravenelle","pos":"NOM"} ,
		{"word":"raves","word_nosc":"raves","lemma":"rave","pos":"NOM"} ,
		{"word":"ravi","word_nosc":"ravi","lemma":"ravi","pos":"NOM"} ,
		{"word":"ravier","word_nosc":"ravier","lemma":"ravier","pos":"NOM"} ,
		{"word":"raviers","word_nosc":"raviers","lemma":"ravier","pos":"NOM"} ,
		{"word":"ravigote","word_nosc":"ravigote","lemma":"ravigote","pos":"NOM"} ,
		{"word":"ravin","word_nosc":"ravin","lemma":"ravin","pos":"NOM"} ,
		{"word":"ravine","word_nosc":"ravine","lemma":"ravine","pos":"NOM"} ,
		{"word":"ravinement","word_nosc":"ravinement","lemma":"ravinement","pos":"NOM"} ,
		{"word":"ravinements","word_nosc":"ravinements","lemma":"ravinement","pos":"NOM"} ,
		{"word":"ravines","word_nosc":"ravines","lemma":"ravine","pos":"NOM"} ,
		{"word":"ravins","word_nosc":"ravins","lemma":"ravin","pos":"NOM"} ,
		{"word":"ravioli","word_nosc":"ravioli","lemma":"ravioli","pos":"NOM"} ,
		{"word":"raviolis","word_nosc":"raviolis","lemma":"ravioli","pos":"NOM"} ,
		{"word":"ravissement","word_nosc":"ravissement","lemma":"ravissement","pos":"NOM"} ,
		{"word":"ravissements","word_nosc":"ravissements","lemma":"ravissement","pos":"NOM"} ,
		{"word":"ravisseur","word_nosc":"ravisseur","lemma":"ravisseur","pos":"NOM"} ,
		{"word":"ravisseurs","word_nosc":"ravisseurs","lemma":"ravisseur","pos":"NOM"} ,
		{"word":"ravisseuse","word_nosc":"ravisseuse","lemma":"ravisseur","pos":"NOM"} ,
		{"word":"ravitaillement","word_nosc":"ravitaillement","lemma":"ravitaillement","pos":"NOM"} ,
		{"word":"ravitaillements","word_nosc":"ravitaillements","lemma":"ravitaillement","pos":"NOM"} ,
		{"word":"ravitailleur","word_nosc":"ravitailleur","lemma":"ravitailleur","pos":"NOM"} ,
		{"word":"ravitailleurs","word_nosc":"ravitailleurs","lemma":"ravitailleur","pos":"NOM"} ,
		{"word":"ravitailleuse","word_nosc":"ravitailleuse","lemma":"ravitailleur","pos":"NOM"} ,
		{"word":"ravivement","word_nosc":"ravivement","lemma":"ravivement","pos":"NOM"} ,
		{"word":"ray-grass","word_nosc":"ray-grass","lemma":"ray-grass","pos":"NOM"} ,
		{"word":"rayon","word_nosc":"rayon","lemma":"rayon","pos":"NOM"} ,
		{"word":"rayon-éclair","word_nosc":"rayon-eclair","lemma":"rayon-éclair","pos":"NOM"} ,
		{"word":"rayonnage","word_nosc":"rayonnage","lemma":"rayonnage","pos":"NOM"} ,
		{"word":"rayonnages","word_nosc":"rayonnages","lemma":"rayonnage","pos":"NOM"} ,
		{"word":"rayonne","word_nosc":"rayonne","lemma":"rayon","pos":"NOM"} ,
		{"word":"rayonnement","word_nosc":"rayonnement","lemma":"rayonnement","pos":"NOM"} ,
		{"word":"rayonnements","word_nosc":"rayonnements","lemma":"rayonnement","pos":"NOM"} ,
		{"word":"rayonneur","word_nosc":"rayonneur","lemma":"rayonneur","pos":"NOM"} ,
		{"word":"rayons","word_nosc":"rayons","lemma":"rayon","pos":"NOM"} ,
		{"word":"rayure","word_nosc":"rayure","lemma":"rayure","pos":"NOM"} ,
		{"word":"rayures","word_nosc":"rayures","lemma":"rayure","pos":"NOM"} ,
		{"word":"raz","word_nosc":"raz","lemma":"raz","pos":"NOM"} ,
		{"word":"raz-de-marée","word_nosc":"raz-de-maree","lemma":"raz-de-marée","pos":"NOM"} ,
		{"word":"razzia","word_nosc":"razzia","lemma":"razzia","pos":"NOM"} ,
		{"word":"razzias","word_nosc":"razzias","lemma":"razzia","pos":"NOM"} ,
		{"word":"raïa","word_nosc":"raia","lemma":"raïa","pos":"NOM"} ,
		{"word":"raïs","word_nosc":"rais","lemma":"raïs","pos":"NOM"} ,
		{"word":"re-baptême","word_nosc":"re-bapteme","lemma":"rebaptême","pos":"NOM"} ,
		{"word":"re-bide","word_nosc":"re-bide","lemma":"rebide","pos":"NOM"} ,
		{"word":"re-bombardons","word_nosc":"re-bombardons","lemma":"rebombardon","pos":"NOM"} ,
		{"word":"re-cailloux","word_nosc":"re-cailloux","lemma":"recailloux","pos":"NOM"} ,
		{"word":"re-chanteur","word_nosc":"re-chanteur","lemma":"rechanteur","pos":"NOM"} ,
		{"word":"re-coup","word_nosc":"re-coup","lemma":"recoup","pos":"NOM"} ,
		{"word":"re-crac","word_nosc":"re-crac","lemma":"recrac","pos":"NOM"} ,
		{"word":"re-croquis","word_nosc":"re-croquis","lemma":"recroquis","pos":"NOM"} ,
		{"word":"re-création","word_nosc":"re-creation","lemma":"recréation","pos":"NOM"} ,
		{"word":"re-cul","word_nosc":"re-cul","lemma":"recul","pos":"NOM"} ,
		{"word":"re-dose","word_nosc":"re-dose","lemma":"redose","pos":"NOM"} ,
		{"word":"re-drapeau","word_nosc":"re-drapeau","lemma":"redrapeau","pos":"NOM"} ,
		{"word":"re-déclic","word_nosc":"re-declic","lemma":"redéclic","pos":"NOM"} ,
		{"word":"re-faim","word_nosc":"re-faim","lemma":"refaim","pos":"NOM"} ,
		{"word":"re-gomme","word_nosc":"re-gomme","lemma":"regomme","pos":"NOM"} ,
		{"word":"re-histoires","word_nosc":"re-histoires","lemma":"rehistoire","pos":"NOM"} ,
		{"word":"re-hygiène","word_nosc":"re-hygiene","lemma":"rehygiène","pos":"NOM"} ,
		{"word":"re-inter","word_nosc":"re-inter","lemma":"re-inter","pos":"NOM"} ,
		{"word":"re-lettre","word_nosc":"re-lettre","lemma":"relettre","pos":"NOM"} ,
		{"word":"re-main","word_nosc":"re-main","lemma":"remain","pos":"NOM"} ,
		{"word":"re-matérialisation","word_nosc":"re-materialisation","lemma":"rematérialisation","pos":"NOM"} ,
		{"word":"re-morphine","word_nosc":"re-morphine","lemma":"remorphine","pos":"NOM"} ,
		{"word":"re-programmation","word_nosc":"re-programmation","lemma":"reprogrammation","pos":"NOM"} ,
		{"word":"re-rigole","word_nosc":"re-rigole","lemma":"rerigole","pos":"NOM"} ,
		{"word":"re-sevrage","word_nosc":"re-sevrage","lemma":"resevrage","pos":"NOM"} ,
		{"word":"re-sommeil","word_nosc":"re-sommeil","lemma":"resommeil","pos":"NOM"} ,
		{"word":"re-structuration","word_nosc":"re-structuration","lemma":"restructuration","pos":"NOM"} ,
		{"word":"re-taloche","word_nosc":"re-taloche","lemma":"retaloche","pos":"NOM"} ,
		{"word":"re-travail","word_nosc":"re-travail","lemma":"retravail","pos":"NOM"} ,
		{"word":"re-tuage","word_nosc":"re-tuage","lemma":"retuage","pos":"NOM"} ,
		{"word":"re-vie","word_nosc":"re-vie","lemma":"revie","pos":"NOM"} ,
		{"word":"reader","word_nosc":"reader","lemma":"reader","pos":"NOM"} ,
		{"word":"ready-made","word_nosc":"ready-made","lemma":"ready-made","pos":"NOM"} ,
		{"word":"rebab","word_nosc":"rebab","lemma":"rebab","pos":"NOM"} ,
		{"word":"rebec","word_nosc":"rebec","lemma":"rebec","pos":"NOM"} ,
		{"word":"rebelle","word_nosc":"rebelle","lemma":"rebelle","pos":"NOM"} ,
		{"word":"rebelles","word_nosc":"rebelles","lemma":"rebelle","pos":"NOM"} ,
		{"word":"reblochon","word_nosc":"reblochon","lemma":"reblochon","pos":"NOM"} ,
		{"word":"reblochons","word_nosc":"reblochons","lemma":"reblochon","pos":"NOM"} ,
		{"word":"reboisement","word_nosc":"reboisement","lemma":"reboisement","pos":"NOM"} ,
		{"word":"rebond","word_nosc":"rebond","lemma":"rebond","pos":"NOM"} ,
		{"word":"rebondissement","word_nosc":"rebondissement","lemma":"rebondissement","pos":"NOM"} ,
		{"word":"rebondissements","word_nosc":"rebondissements","lemma":"rebondissement","pos":"NOM"} ,
		{"word":"rebonds","word_nosc":"rebonds","lemma":"rebond","pos":"NOM"} ,
		{"word":"rebonjour","word_nosc":"rebonjour","lemma":"rebonjour","pos":"NOM"} ,
		{"word":"rebord","word_nosc":"rebord","lemma":"rebord","pos":"NOM"} ,
		{"word":"rebords","word_nosc":"rebords","lemma":"rebord","pos":"NOM"} ,
		{"word":"reboule","word_nosc":"reboule","lemma":"reboule","pos":"NOM"} ,
		{"word":"rebouteuse","word_nosc":"rebouteuse","lemma":"rebouteur","pos":"NOM"} ,
		{"word":"rebouteux","word_nosc":"rebouteux","lemma":"rebouteux","pos":"NOM"} ,
		{"word":"rebroussement","word_nosc":"rebroussement","lemma":"rebroussement","pos":"NOM"} ,
		{"word":"rebuffade","word_nosc":"rebuffade","lemma":"rebuffade","pos":"NOM"} ,
		{"word":"rebuffades","word_nosc":"rebuffades","lemma":"rebuffade","pos":"NOM"} ,
		{"word":"rebut","word_nosc":"rebut","lemma":"rebut","pos":"NOM"} ,
		{"word":"rebuts","word_nosc":"rebuts","lemma":"rebut","pos":"NOM"} ,
		{"word":"recalage","word_nosc":"recalage","lemma":"recalage","pos":"NOM"} ,
		{"word":"recel","word_nosc":"recel","lemma":"recel","pos":"NOM"} ,
		{"word":"receleur","word_nosc":"receleur","lemma":"receleur","pos":"NOM"} ,
		{"word":"receleur-miracle","word_nosc":"receleur-miracle","lemma":"receleur-miracle","pos":"NOM"} ,
		{"word":"receleurs","word_nosc":"receleurs","lemma":"receleur","pos":"NOM"} ,
		{"word":"receleuse","word_nosc":"receleuse","lemma":"receleur","pos":"NOM"} ,
		{"word":"receleuses","word_nosc":"receleuses","lemma":"receleur","pos":"NOM"} ,
		{"word":"recensement","word_nosc":"recensement","lemma":"recensement","pos":"NOM"} ,
		{"word":"recensements","word_nosc":"recensements","lemma":"recensement","pos":"NOM"} ,
		{"word":"recenseur","word_nosc":"recenseur","lemma":"recenseur","pos":"NOM"} ,
		{"word":"recension","word_nosc":"recension","lemma":"recension","pos":"NOM"} ,
		{"word":"recentrage","word_nosc":"recentrage","lemma":"recentrage","pos":"NOM"} ,
		{"word":"recette","word_nosc":"recette","lemma":"recette","pos":"NOM"} ,
		{"word":"recettes","word_nosc":"recettes","lemma":"recette","pos":"NOM"} ,
		{"word":"recevabilité","word_nosc":"recevabilite","lemma":"recevabilité","pos":"NOM"} ,
		{"word":"receveur","word_nosc":"receveur","lemma":"receveur","pos":"NOM"} ,
		{"word":"receveurs","word_nosc":"receveurs","lemma":"receveur","pos":"NOM"} ,
		{"word":"receveuse","word_nosc":"receveuse","lemma":"receveur","pos":"NOM"} ,
		{"word":"receveuses","word_nosc":"receveuses","lemma":"receveur","pos":"NOM"} ,
		{"word":"rechampi","word_nosc":"rechampi","lemma":"rechampi","pos":"NOM"} ,
		{"word":"rechange","word_nosc":"rechange","lemma":"rechange","pos":"NOM"} ,
		{"word":"rechanges","word_nosc":"rechanges","lemma":"rechange","pos":"NOM"} ,
		{"word":"recharge","word_nosc":"recharge","lemma":"recharge","pos":"NOM"} ,
		{"word":"rechargement","word_nosc":"rechargement","lemma":"rechargement","pos":"NOM"} ,
		{"word":"recharges","word_nosc":"recharges","lemma":"recharge","pos":"NOM"} ,
		{"word":"recherche","word_nosc":"recherche","lemma":"recherche","pos":"NOM"} ,
		{"word":"recherches","word_nosc":"recherches","lemma":"recherche","pos":"NOM"} ,
		{"word":"recherchiste","word_nosc":"recherchiste","lemma":"recherchiste","pos":"NOM"} ,
		{"word":"rechute","word_nosc":"rechute","lemma":"rechute","pos":"NOM"} ,
		{"word":"rechutes","word_nosc":"rechutes","lemma":"rechute","pos":"NOM"} ,
		{"word":"reclassement","word_nosc":"reclassement","lemma":"reclassement","pos":"NOM"} ,
		{"word":"reclus","word_nosc":"reclus","lemma":"reclus","pos":"NOM"} ,
		{"word":"recluse","word_nosc":"recluse","lemma":"reclus","pos":"NOM"} ,
		{"word":"recluses","word_nosc":"recluses","lemma":"reclus","pos":"NOM"} ,
		{"word":"recodage","word_nosc":"recodage","lemma":"recodage","pos":"NOM"} ,
		{"word":"recognition","word_nosc":"recognition","lemma":"recognition","pos":"NOM"} ,
		{"word":"recoin","word_nosc":"recoin","lemma":"recoin","pos":"NOM"} ,
		{"word":"recoins","word_nosc":"recoins","lemma":"recoin","pos":"NOM"} ,
		{"word":"recollage","word_nosc":"recollage","lemma":"recollage","pos":"NOM"} ,
		{"word":"recoloration","word_nosc":"recoloration","lemma":"recoloration","pos":"NOM"} ,
		{"word":"recombinaison","word_nosc":"recombinaison","lemma":"recombinaison","pos":"NOM"} ,
		{"word":"recommandation","word_nosc":"recommandation","lemma":"recommandation","pos":"NOM"} ,
		{"word":"recommandations","word_nosc":"recommandations","lemma":"recommandation","pos":"NOM"} ,
		{"word":"recommandé","word_nosc":"recommande","lemma":"recommandé","pos":"NOM"} ,
		{"word":"recommandés","word_nosc":"recommandes","lemma":"recommandé","pos":"NOM"} ,
		{"word":"recommencement","word_nosc":"recommencement","lemma":"recommencement","pos":"NOM"} ,
		{"word":"recommencements","word_nosc":"recommencements","lemma":"recommencement","pos":"NOM"} ,
		{"word":"recomposition","word_nosc":"recomposition","lemma":"recomposition","pos":"NOM"} ,
		{"word":"reconditionnement","word_nosc":"reconditionnement","lemma":"reconditionnement","pos":"NOM"} ,
		{"word":"reconduction","word_nosc":"reconduction","lemma":"reconduction","pos":"NOM"} ,
		{"word":"reconfiguration","word_nosc":"reconfiguration","lemma":"reconfiguration","pos":"NOM"} ,
		{"word":"reconnaissance","word_nosc":"reconnaissance","lemma":"reconnaissance","pos":"NOM"} ,
		{"word":"reconnaissances","word_nosc":"reconnaissances","lemma":"reconnaissance","pos":"NOM"} ,
		{"word":"reconquista","word_nosc":"reconquista","lemma":"reconquista","pos":"NOM"} ,
		{"word":"reconquête","word_nosc":"reconquete","lemma":"reconquête","pos":"NOM"} ,
		{"word":"reconstituant","word_nosc":"reconstituant","lemma":"reconstituant","pos":"NOM"} ,
		{"word":"reconstituants","word_nosc":"reconstituants","lemma":"reconstituant","pos":"NOM"} ,
		{"word":"reconstitution","word_nosc":"reconstitution","lemma":"reconstitution","pos":"NOM"} ,
		{"word":"reconstitutions","word_nosc":"reconstitutions","lemma":"reconstitution","pos":"NOM"} ,
		{"word":"reconstructeur","word_nosc":"reconstructeur","lemma":"reconstructeur","pos":"NOM"} ,
		{"word":"reconstruction","word_nosc":"reconstruction","lemma":"reconstruction","pos":"NOM"} ,
		{"word":"reconstructions","word_nosc":"reconstructions","lemma":"reconstruction","pos":"NOM"} ,
		{"word":"reconversion","word_nosc":"reconversion","lemma":"reconversion","pos":"NOM"} ,
		{"word":"recopiage","word_nosc":"recopiage","lemma":"recopiage","pos":"NOM"} ,
		{"word":"record","word_nosc":"record","lemma":"record","pos":"NOM"} ,
		{"word":"recordman","word_nosc":"recordman","lemma":"recordman","pos":"NOM"} ,
		{"word":"recordmen","word_nosc":"recordmen","lemma":"recordman","pos":"NOM"} ,
		{"word":"records","word_nosc":"records","lemma":"record","pos":"NOM"} ,
		{"word":"recors","word_nosc":"recors","lemma":"recors","pos":"NOM"} ,
		{"word":"recoupement","word_nosc":"recoupement","lemma":"recoupement","pos":"NOM"} ,
		{"word":"recoupements","word_nosc":"recoupements","lemma":"recoupement","pos":"NOM"} ,
		{"word":"recours","word_nosc":"recours","lemma":"recours","pos":"NOM"} ,
		{"word":"recouvrance","word_nosc":"recouvrance","lemma":"recouvrance","pos":"NOM"} ,
		{"word":"recouvrement","word_nosc":"recouvrement","lemma":"recouvrement","pos":"NOM"} ,
		{"word":"recroquevillement","word_nosc":"recroquevillement","lemma":"recroquevillement","pos":"NOM"} ,
		{"word":"recrudescence","word_nosc":"recrudescence","lemma":"recrudescence","pos":"NOM"} ,
		{"word":"recrue","word_nosc":"recrue","lemma":"recrue","pos":"NOM"} ,
		{"word":"recrues","word_nosc":"recrues","lemma":"recrue","pos":"NOM"} ,
		{"word":"recrutement","word_nosc":"recrutement","lemma":"recrutement","pos":"NOM"} ,
		{"word":"recruteur","word_nosc":"recruteur","lemma":"recruteur","pos":"NOM"} ,
		{"word":"recruteurs","word_nosc":"recruteurs","lemma":"recruteur","pos":"NOM"} ,
		{"word":"recréation","word_nosc":"recreation","lemma":"recréation","pos":"NOM"} ,
		{"word":"rectangle","word_nosc":"rectangle","lemma":"rectangle","pos":"NOM"} ,
		{"word":"rectangles","word_nosc":"rectangles","lemma":"rectangle","pos":"NOM"} ,
		{"word":"recteur","word_nosc":"recteur","lemma":"recteur","pos":"NOM"} ,
		{"word":"recteurs","word_nosc":"recteurs","lemma":"recteur","pos":"NOM"} ,
		{"word":"rectificatif","word_nosc":"rectificatif","lemma":"rectificatif","pos":"NOM"} ,
		{"word":"rectification","word_nosc":"rectification","lemma":"rectification","pos":"NOM"} ,
		{"word":"rectifications","word_nosc":"rectifications","lemma":"rectification","pos":"NOM"} ,
		{"word":"rectitude","word_nosc":"rectitude","lemma":"rectitude","pos":"NOM"} ,
		{"word":"recto","word_nosc":"recto","lemma":"recto","pos":"NOM"} ,
		{"word":"rectorat","word_nosc":"rectorat","lemma":"rectorat","pos":"NOM"} ,
		{"word":"rectos","word_nosc":"rectos","lemma":"recto","pos":"NOM"} ,
		{"word":"rectrices","word_nosc":"rectrices","lemma":"recteur","pos":"NOM"} ,
		{"word":"rectum","word_nosc":"rectum","lemma":"rectum","pos":"NOM"} ,
		{"word":"recueil","word_nosc":"recueil","lemma":"recueil","pos":"NOM"} ,
		{"word":"recueillement","word_nosc":"recueillement","lemma":"recueillement","pos":"NOM"} ,
		{"word":"recueillements","word_nosc":"recueillements","lemma":"recueillement","pos":"NOM"} ,
		{"word":"recueils","word_nosc":"recueils","lemma":"recueil","pos":"NOM"} ,
		{"word":"recuisson","word_nosc":"recuisson","lemma":"recuisson","pos":"NOM"} ,
		{"word":"recul","word_nosc":"recul","lemma":"recul","pos":"NOM"} ,
		{"word":"reculade","word_nosc":"reculade","lemma":"reculade","pos":"NOM"} ,
		{"word":"reculades","word_nosc":"reculades","lemma":"reculade","pos":"NOM"} ,
		{"word":"reculs","word_nosc":"reculs","lemma":"recul","pos":"NOM"} ,
		{"word":"recyclage","word_nosc":"recyclage","lemma":"recyclage","pos":"NOM"} ,
		{"word":"recycleur","word_nosc":"recycleur","lemma":"recycleur","pos":"NOM"} ,
		{"word":"recès","word_nosc":"reces","lemma":"recès","pos":"NOM"} ,
		{"word":"recépages","word_nosc":"recepages","lemma":"recépage","pos":"NOM"} ,
		{"word":"red river","word_nosc":"red river","lemma":"red river","pos":"NOM"} ,
		{"word":"redan","word_nosc":"redan","lemma":"redan","pos":"NOM"} ,
		{"word":"reddition","word_nosc":"reddition","lemma":"reddition","pos":"NOM"} ,
		{"word":"redents","word_nosc":"redents","lemma":"redent","pos":"NOM"} ,
		{"word":"redescente","word_nosc":"redescente","lemma":"redescente","pos":"NOM"} ,
		{"word":"redevance","word_nosc":"redevance","lemma":"redevance","pos":"NOM"} ,
		{"word":"redevances","word_nosc":"redevances","lemma":"redevance","pos":"NOM"} ,
		{"word":"rediffusion","word_nosc":"rediffusion","lemma":"rediffusion","pos":"NOM"} ,
		{"word":"rediffusions","word_nosc":"rediffusions","lemma":"rediffusion","pos":"NOM"} ,
		{"word":"redingote","word_nosc":"redingote","lemma":"redingote","pos":"NOM"} ,
		{"word":"redingotes","word_nosc":"redingotes","lemma":"redingote","pos":"NOM"} ,
		{"word":"redistribution","word_nosc":"redistribution","lemma":"redistribution","pos":"NOM"} ,
		{"word":"redistributions","word_nosc":"redistributions","lemma":"redistribution","pos":"NOM"} ,
		{"word":"redite","word_nosc":"redite","lemma":"redite","pos":"NOM"} ,
		{"word":"redites","word_nosc":"redites","lemma":"redite","pos":"NOM"} ,
		{"word":"redondance","word_nosc":"redondance","lemma":"redondance","pos":"NOM"} ,
		{"word":"redondances","word_nosc":"redondances","lemma":"redondance","pos":"NOM"} ,
		{"word":"redoublants","word_nosc":"redoublants","lemma":"redoublant","pos":"NOM"} ,
		{"word":"redoublement","word_nosc":"redoublement","lemma":"redoublement","pos":"NOM"} ,
		{"word":"redoute","word_nosc":"redoute","lemma":"redoute","pos":"NOM"} ,
		{"word":"redoutes","word_nosc":"redoutes","lemma":"redoute","pos":"NOM"} ,
		{"word":"redoux","word_nosc":"redoux","lemma":"redoux","pos":"NOM"} ,
		{"word":"redressement","word_nosc":"redressement","lemma":"redressement","pos":"NOM"} ,
		{"word":"redressements","word_nosc":"redressements","lemma":"redressement","pos":"NOM"} ,
		{"word":"redresseur","word_nosc":"redresseur","lemma":"redresseur","pos":"NOM"} ,
		{"word":"redresseurs","word_nosc":"redresseurs","lemma":"redresseur","pos":"NOM"} ,
		{"word":"redresseuse","word_nosc":"redresseuse","lemma":"redresseur","pos":"NOM"} ,
		{"word":"redécoupage","word_nosc":"redecoupage","lemma":"redécoupage","pos":"NOM"} ,
		{"word":"redécouverte","word_nosc":"redecouverte","lemma":"redécouverte","pos":"NOM"} ,
		{"word":"redéfinition","word_nosc":"redefinition","lemma":"redéfinition","pos":"NOM"} ,
		{"word":"redémarrage","word_nosc":"redemarrage","lemma":"redémarrage","pos":"NOM"} ,
		{"word":"redéploiement","word_nosc":"redeploiement","lemma":"redéploiement","pos":"NOM"} ,
		{"word":"referendum","word_nosc":"referendum","lemma":"referendum","pos":"NOM"} ,
		{"word":"refil","word_nosc":"refil","lemma":"refil","pos":"NOM"} ,
		{"word":"refinancement","word_nosc":"refinancement","lemma":"refinancement","pos":"NOM"} ,
		{"word":"reflet","word_nosc":"reflet","lemma":"reflet","pos":"NOM"} ,
		{"word":"reflets","word_nosc":"reflets","lemma":"reflet","pos":"NOM"} ,
		{"word":"reflex","word_nosc":"reflex","lemma":"reflex","pos":"NOM"} ,
		{"word":"reflux","word_nosc":"reflux","lemma":"reflux","pos":"NOM"} ,
		{"word":"refonte","word_nosc":"refonte","lemma":"refonte","pos":"NOM"} ,
		{"word":"reformation","word_nosc":"reformation","lemma":"reformation","pos":"NOM"} ,
		{"word":"reformulation","word_nosc":"reformulation","lemma":"reformulation","pos":"NOM"} ,
		{"word":"refoulement","word_nosc":"refoulement","lemma":"refoulement","pos":"NOM"} ,
		{"word":"refoulements","word_nosc":"refoulements","lemma":"refoulement","pos":"NOM"} ,
		{"word":"refouloir","word_nosc":"refouloir","lemma":"refouloir","pos":"NOM"} ,
		{"word":"refoulé","word_nosc":"refoule","lemma":"refoulé","pos":"NOM"} ,
		{"word":"refoulée","word_nosc":"refoulee","lemma":"refoulé","pos":"NOM"} ,
		{"word":"refoulés","word_nosc":"refoules","lemma":"refoulé","pos":"NOM"} ,
		{"word":"refrain","word_nosc":"refrain","lemma":"refrain","pos":"NOM"} ,
		{"word":"refrains","word_nosc":"refrains","lemma":"refrain","pos":"NOM"} ,
		{"word":"refroidissement","word_nosc":"refroidissement","lemma":"refroidissement","pos":"NOM"} ,
		{"word":"refroidisseur","word_nosc":"refroidisseur","lemma":"refroidisseur","pos":"NOM"} ,
		{"word":"refuge","word_nosc":"refuge","lemma":"refuge","pos":"NOM"} ,
		{"word":"refuges","word_nosc":"refuges","lemma":"refuge","pos":"NOM"} ,
		{"word":"refuites","word_nosc":"refuites","lemma":"refuite","pos":"NOM"} ,
		{"word":"refus","word_nosc":"refus","lemma":"refus","pos":"NOM"} ,
		{"word":"refusé","word_nosc":"refuse","lemma":"refusé","pos":"NOM"} ,
		{"word":"refusée","word_nosc":"refusee","lemma":"refusé","pos":"NOM"} ,
		{"word":"refusées","word_nosc":"refusees","lemma":"refusé","pos":"NOM"} ,
		{"word":"refusés","word_nosc":"refuses","lemma":"refusé","pos":"NOM"} ,
		{"word":"reg","word_nosc":"reg","lemma":"reg","pos":"NOM"} ,
		{"word":"regain","word_nosc":"regain","lemma":"regain","pos":"NOM"} ,
		{"word":"regains","word_nosc":"regains","lemma":"regain","pos":"NOM"} ,
		{"word":"regard","word_nosc":"regard","lemma":"regard","pos":"NOM"} ,
		{"word":"regardeur","word_nosc":"regardeur","lemma":"regardeur","pos":"NOM"} ,
		{"word":"regards","word_nosc":"regards","lemma":"regard","pos":"NOM"} ,
		{"word":"regency","word_nosc":"regency","lemma":"regency","pos":"NOM"} ,
		{"word":"reggae","word_nosc":"reggae","lemma":"reggae","pos":"NOM"} ,
		{"word":"reggaes","word_nosc":"reggaes","lemma":"reggae","pos":"NOM"} ,
		{"word":"regimbements","word_nosc":"regimbements","lemma":"regimbement","pos":"NOM"} ,
		{"word":"registre","word_nosc":"registre","lemma":"registre","pos":"NOM"} ,
		{"word":"registres","word_nosc":"registres","lemma":"registre","pos":"NOM"} ,
		{"word":"regonflage","word_nosc":"regonflage","lemma":"regonflage","pos":"NOM"} ,
		{"word":"regrattières","word_nosc":"regrattieres","lemma":"regrattier","pos":"NOM"} ,
		{"word":"regret","word_nosc":"regret","lemma":"regret","pos":"NOM"} ,
		{"word":"regrets","word_nosc":"regrets","lemma":"regret","pos":"NOM"} ,
		{"word":"regroupement","word_nosc":"regroupement","lemma":"regroupement","pos":"NOM"} ,
		{"word":"regroupements","word_nosc":"regroupements","lemma":"regroupement","pos":"NOM"} ,
		{"word":"rehausse","word_nosc":"rehausse","lemma":"rehausse","pos":"NOM"} ,
		{"word":"rehaussement","word_nosc":"rehaussement","lemma":"rehaussement","pos":"NOM"} ,
		{"word":"rehausses","word_nosc":"rehausses","lemma":"rehausse","pos":"NOM"} ,
		{"word":"rehausseur","word_nosc":"rehausseur","lemma":"rehausseur","pos":"NOM"} ,
		{"word":"rehaut","word_nosc":"rehaut","lemma":"rehaut","pos":"NOM"} ,
		{"word":"rehauts","word_nosc":"rehauts","lemma":"rehaut","pos":"NOM"} ,
		{"word":"reich","word_nosc":"reich","lemma":"reich","pos":"NOM"} ,
		{"word":"reichsmark","word_nosc":"reichsmark","lemma":"reichsmark","pos":"NOM"} ,
		{"word":"reichstag","word_nosc":"reichstag","lemma":"reichstag","pos":"NOM"} ,
		{"word":"reichswehr","word_nosc":"reichswehr","lemma":"reichswehr","pos":"NOM"} ,
		{"word":"rein","word_nosc":"rein","lemma":"rein","pos":"NOM"} ,
		{"word":"reine","word_nosc":"reine","lemma":"reine","pos":"NOM"} ,
		{"word":"reine-claude","word_nosc":"reine-claude","lemma":"reine-claude","pos":"NOM"} ,
		{"word":"reine-mère","word_nosc":"reine-mere","lemma":"reine-mère","pos":"NOM"} ,
		{"word":"reines","word_nosc":"reines","lemma":"reine","pos":"NOM"} ,
		{"word":"reines-claudes","word_nosc":"reines-claudes","lemma":"reine-claude","pos":"NOM"} ,
		{"word":"reines-marguerites","word_nosc":"reines-marguerites","lemma":"reine-marguerite","pos":"NOM"} ,
		{"word":"reinette","word_nosc":"reinette","lemma":"reinette","pos":"NOM"} ,
		{"word":"reinettes","word_nosc":"reinettes","lemma":"reinette","pos":"NOM"} ,
		{"word":"reins","word_nosc":"reins","lemma":"rein","pos":"NOM"} ,
		{"word":"reis","word_nosc":"reis","lemma":"reis","pos":"NOM"} ,
		{"word":"rejaillissements","word_nosc":"rejaillissements","lemma":"rejaillissement","pos":"NOM"} ,
		{"word":"rejet","word_nosc":"rejet","lemma":"rejet","pos":"NOM"} ,
		{"word":"rejeton","word_nosc":"rejeton","lemma":"rejeton","pos":"NOM"} ,
		{"word":"rejetons","word_nosc":"rejetons","lemma":"rejeton","pos":"NOM"} ,
		{"word":"rejets","word_nosc":"rejets","lemma":"rejet","pos":"NOM"} ,
		{"word":"relais","word_nosc":"relais","lemma":"relais","pos":"NOM"} ,
		{"word":"relance","word_nosc":"relance","lemma":"relance","pos":"NOM"} ,
		{"word":"relancement","word_nosc":"relancement","lemma":"relancement","pos":"NOM"} ,
		{"word":"relances","word_nosc":"relances","lemma":"relance","pos":"NOM"} ,
		{"word":"relanceur","word_nosc":"relanceur","lemma":"relanceur","pos":"NOM"} ,
		{"word":"relaps","word_nosc":"relaps","lemma":"relaps","pos":"NOM"} ,
		{"word":"relapses","word_nosc":"relapses","lemma":"relaps","pos":"NOM"} ,
		{"word":"relation","word_nosc":"relation","lemma":"relation","pos":"NOM"} ,
		{"word":"relations","word_nosc":"relations","lemma":"relation","pos":"NOM"} ,
		{"word":"relative","word_nosc":"relative","lemma":"relative","pos":"NOM"} ,
		{"word":"relativisation","word_nosc":"relativisation","lemma":"relativisation","pos":"NOM"} ,
		{"word":"relativisme","word_nosc":"relativisme","lemma":"relativisme","pos":"NOM"} ,
		{"word":"relativité","word_nosc":"relativite","lemma":"relativité","pos":"NOM"} ,
		{"word":"relax","word_nosc":"relax","lemma":"relax","pos":"NOM"} ,
		{"word":"relaxant","word_nosc":"relaxant","lemma":"relaxant","pos":"NOM"} ,
		{"word":"relaxants","word_nosc":"relaxants","lemma":"relaxant","pos":"NOM"} ,
		{"word":"relaxation","word_nosc":"relaxation","lemma":"relaxation","pos":"NOM"} ,
		{"word":"relaxe","word_nosc":"relaxe","lemma":"relaxe","pos":"NOM"} ,
		{"word":"relaxes","word_nosc":"relaxes","lemma":"relaxe","pos":"NOM"} ,
		{"word":"relayeur","word_nosc":"relayeur","lemma":"relayeur","pos":"NOM"} ,
		{"word":"relecture","word_nosc":"relecture","lemma":"relecture","pos":"NOM"} ,
		{"word":"relent","word_nosc":"relent","lemma":"relent","pos":"NOM"} ,
		{"word":"relents","word_nosc":"relents","lemma":"relent","pos":"NOM"} ,
		{"word":"relevailles","word_nosc":"relevailles","lemma":"relevailles","pos":"NOM"} ,
		{"word":"releveur","word_nosc":"releveur","lemma":"releveur","pos":"NOM"} ,
		{"word":"releveurs","word_nosc":"releveurs","lemma":"releveur","pos":"NOM"} ,
		{"word":"relevé","word_nosc":"releve","lemma":"relevé","pos":"NOM"} ,
		{"word":"relevée","word_nosc":"relevee","lemma":"relevée","pos":"NOM"} ,
		{"word":"relevées","word_nosc":"relevees","lemma":"relevée","pos":"NOM"} ,
		{"word":"relevés","word_nosc":"releves","lemma":"relevé","pos":"NOM"} ,
		{"word":"reliage","word_nosc":"reliage","lemma":"reliage","pos":"NOM"} ,
		{"word":"relief","word_nosc":"relief","lemma":"relief","pos":"NOM"} ,
		{"word":"reliefs","word_nosc":"reliefs","lemma":"relief","pos":"NOM"} ,
		{"word":"relieur","word_nosc":"relieur","lemma":"relieur","pos":"NOM"} ,
		{"word":"relieurs","word_nosc":"relieurs","lemma":"relieur","pos":"NOM"} ,
		{"word":"religieuse","word_nosc":"religieuse","lemma":"religieux","pos":"NOM"} ,
		{"word":"religieuses","word_nosc":"religieuses","lemma":"religieux","pos":"NOM"} ,
		{"word":"religieux","word_nosc":"religieux","lemma":"religieux","pos":"NOM"} ,
		{"word":"religion","word_nosc":"religion","lemma":"religion","pos":"NOM"} ,
		{"word":"religionnaires","word_nosc":"religionnaires","lemma":"religionnaire","pos":"NOM"} ,
		{"word":"religions","word_nosc":"religions","lemma":"religion","pos":"NOM"} ,
		{"word":"religiosité","word_nosc":"religiosite","lemma":"religiosité","pos":"NOM"} ,
		{"word":"reliquaire","word_nosc":"reliquaire","lemma":"reliquaire","pos":"NOM"} ,
		{"word":"reliquaires","word_nosc":"reliquaires","lemma":"reliquaire","pos":"NOM"} ,
		{"word":"reliquat","word_nosc":"reliquat","lemma":"reliquat","pos":"NOM"} ,
		{"word":"reliquats","word_nosc":"reliquats","lemma":"reliquat","pos":"NOM"} ,
		{"word":"relique","word_nosc":"relique","lemma":"relique","pos":"NOM"} ,
		{"word":"reliques","word_nosc":"reliques","lemma":"relique","pos":"NOM"} ,
		{"word":"reliure","word_nosc":"reliure","lemma":"reliure","pos":"NOM"} ,
		{"word":"reliures","word_nosc":"reliures","lemma":"reliure","pos":"NOM"} ,
		{"word":"relogement","word_nosc":"relogement","lemma":"relogement","pos":"NOM"} ,
		{"word":"relogements","word_nosc":"relogements","lemma":"relogement","pos":"NOM"} ,
		{"word":"relâche","word_nosc":"relache","lemma":"relâche","pos":"NOM"} ,
		{"word":"relâchement","word_nosc":"relachement","lemma":"relâchement","pos":"NOM"} ,
		{"word":"relâchements","word_nosc":"relachements","lemma":"relâchement","pos":"NOM"} ,
		{"word":"relève","word_nosc":"releve","lemma":"relève","pos":"NOM"} ,
		{"word":"relèvement","word_nosc":"relevement","lemma":"relèvement","pos":"NOM"} ,
		{"word":"relèvements","word_nosc":"relevements","lemma":"relèvement","pos":"NOM"} ,
		{"word":"relèves","word_nosc":"releves","lemma":"relève","pos":"NOM"} ,
		{"word":"relégation","word_nosc":"relegation","lemma":"relégation","pos":"NOM"} ,
		{"word":"relégué","word_nosc":"relegue","lemma":"relégué","pos":"NOM"} ,
		{"word":"reléguée","word_nosc":"releguee","lemma":"relégué","pos":"NOM"} ,
		{"word":"relégués","word_nosc":"relegues","lemma":"relégué","pos":"NOM"} ,
		{"word":"remaillage","word_nosc":"remaillage","lemma":"remaillage","pos":"NOM"} ,
		{"word":"remailleuse","word_nosc":"remailleuse","lemma":"mailleur","pos":"NOM"} ,
		{"word":"remake","word_nosc":"remake","lemma":"remake","pos":"NOM"} ,
		{"word":"remaniement","word_nosc":"remaniement","lemma":"remaniement","pos":"NOM"} ,
		{"word":"remaniements","word_nosc":"remaniements","lemma":"remaniement","pos":"NOM"} ,
		{"word":"remariage","word_nosc":"remariage","lemma":"remariage","pos":"NOM"} ,
		{"word":"remarque","word_nosc":"remarque","lemma":"remarque","pos":"NOM"} ,
		{"word":"remarques","word_nosc":"remarques","lemma":"remarque","pos":"NOM"} ,
		{"word":"remballage","word_nosc":"remballage","lemma":"remballage","pos":"NOM"} ,
		{"word":"rembarquement","word_nosc":"rembarquement","lemma":"rembarquement","pos":"NOM"} ,
		{"word":"rembarquements","word_nosc":"rembarquements","lemma":"rembarquement","pos":"NOM"} ,
		{"word":"remblai","word_nosc":"remblai","lemma":"remblai","pos":"NOM"} ,
		{"word":"remblaiement","word_nosc":"remblaiement","lemma":"remblaiement","pos":"NOM"} ,
		{"word":"remblais","word_nosc":"remblais","lemma":"remblai","pos":"NOM"} ,
		{"word":"rembobinage","word_nosc":"rembobinage","lemma":"rembobinage","pos":"NOM"} ,
		{"word":"rembour","word_nosc":"rembour","lemma":"rembour","pos":"NOM"} ,
		{"word":"rembourrage","word_nosc":"rembourrage","lemma":"rembourrage","pos":"NOM"} ,
		{"word":"rembourrages","word_nosc":"rembourrages","lemma":"rembourrage","pos":"NOM"} ,
		{"word":"remboursement","word_nosc":"remboursement","lemma":"remboursement","pos":"NOM"} ,
		{"word":"remboursements","word_nosc":"remboursements","lemma":"remboursement","pos":"NOM"} ,
		{"word":"remembrance","word_nosc":"remembrance","lemma":"remembrance","pos":"NOM"} ,
		{"word":"remembrement","word_nosc":"remembrement","lemma":"remembrement","pos":"NOM"} ,
		{"word":"remerciement","word_nosc":"remerciement","lemma":"remerciement","pos":"NOM"} ,
		{"word":"remerciements","word_nosc":"remerciements","lemma":"remerciement","pos":"NOM"} ,
		{"word":"remettant","word_nosc":"remettant","lemma":"remettant","pos":"NOM"} ,
		{"word":"remise","word_nosc":"remise","lemma":"remise","pos":"NOM"} ,
		{"word":"remises","word_nosc":"remises","lemma":"remise","pos":"NOM"} ,
		{"word":"remix","word_nosc":"remix","lemma":"remix","pos":"NOM"} ,
		{"word":"remmailleuses","word_nosc":"remmailleuses","lemma":"remmailleuse","pos":"NOM"} ,
		{"word":"remodelage","word_nosc":"remodelage","lemma":"remodelage","pos":"NOM"} ,
		{"word":"remontage","word_nosc":"remontage","lemma":"remontage","pos":"NOM"} ,
		{"word":"remontant","word_nosc":"remontant","lemma":"remontant","pos":"NOM"} ,
		{"word":"remontants","word_nosc":"remontants","lemma":"remontant","pos":"NOM"} ,
		{"word":"remonte","word_nosc":"remonte","lemma":"remonte","pos":"NOM"} ,
		{"word":"remonte-pente","word_nosc":"remonte-pente","lemma":"remonte-pente","pos":"NOM"} ,
		{"word":"remonte-pentes","word_nosc":"remonte-pentes","lemma":"remonte-pentes","pos":"NOM"} ,
		{"word":"remontes","word_nosc":"remontes","lemma":"remonte","pos":"NOM"} ,
		{"word":"remontoir","word_nosc":"remontoir","lemma":"remontoir","pos":"NOM"} ,
		{"word":"remontrance","word_nosc":"remontrance","lemma":"remontrance","pos":"NOM"} ,
		{"word":"remontrances","word_nosc":"remontrances","lemma":"remontrance","pos":"NOM"} ,
		{"word":"remontée","word_nosc":"remontee","lemma":"remontée","pos":"NOM"} ,
		{"word":"remontées","word_nosc":"remontees","lemma":"remontée","pos":"NOM"} ,
		{"word":"remords","word_nosc":"remords","lemma":"remords","pos":"NOM"} ,
		{"word":"remorquage","word_nosc":"remorquage","lemma":"remorquage","pos":"NOM"} ,
		{"word":"remorque","word_nosc":"remorque","lemma":"remorque","pos":"NOM"} ,
		{"word":"remorques","word_nosc":"remorques","lemma":"remorque","pos":"NOM"} ,
		{"word":"remorqueur","word_nosc":"remorqueur","lemma":"remorqueur","pos":"NOM"} ,
		{"word":"remorqueurs","word_nosc":"remorqueurs","lemma":"remorqueur","pos":"NOM"} ,
		{"word":"remous","word_nosc":"remous","lemma":"remous","pos":"NOM"} ,
		{"word":"rempailleur","word_nosc":"rempailleur","lemma":"rempailleur","pos":"NOM"} ,
		{"word":"rempailleurs","word_nosc":"rempailleurs","lemma":"rempailleur","pos":"NOM"} ,
		{"word":"rempailleuses","word_nosc":"rempailleuses","lemma":"rempailleur","pos":"NOM"} ,
		{"word":"rempart","word_nosc":"rempart","lemma":"rempart","pos":"NOM"} ,
		{"word":"remparts","word_nosc":"remparts","lemma":"rempart","pos":"NOM"} ,
		{"word":"remplacement","word_nosc":"remplacement","lemma":"remplacement","pos":"NOM"} ,
		{"word":"remplacements","word_nosc":"remplacements","lemma":"remplacement","pos":"NOM"} ,
		{"word":"remplaçant","word_nosc":"remplacant","lemma":"remplaçant","pos":"NOM"} ,
		{"word":"remplaçante","word_nosc":"remplacante","lemma":"remplaçant","pos":"NOM"} ,
		{"word":"remplaçantes","word_nosc":"remplacantes","lemma":"remplaçant","pos":"NOM"} ,
		{"word":"remplaçants","word_nosc":"remplacants","lemma":"remplaçant","pos":"NOM"} ,
		{"word":"remplissage","word_nosc":"remplissage","lemma":"remplissage","pos":"NOM"} ,
		{"word":"remplisseur","word_nosc":"remplisseur","lemma":"remplisseur","pos":"NOM"} ,
		{"word":"remploi","word_nosc":"remploi","lemma":"remploi","pos":"NOM"} ,
		{"word":"remuage","word_nosc":"remuage","lemma":"remuage","pos":"NOM"} ,
		{"word":"remue","word_nosc":"remue","lemma":"remue","pos":"NOM"} ,
		{"word":"remue-ménage","word_nosc":"remue-menage","lemma":"remue-ménage","pos":"NOM"} ,
		{"word":"remue-méninges","word_nosc":"remue-meninges","lemma":"remue-méninges","pos":"NOM"} ,
		{"word":"remuement","word_nosc":"remuement","lemma":"remuement","pos":"NOM"} ,
		{"word":"remuements","word_nosc":"remuements","lemma":"remuement","pos":"NOM"} ,
		{"word":"remues","word_nosc":"remues","lemma":"remue","pos":"NOM"} ,
		{"word":"remueur","word_nosc":"remueur","lemma":"remueur","pos":"NOM"} ,
		{"word":"remueurs","word_nosc":"remueurs","lemma":"remueur","pos":"NOM"} ,
		{"word":"remugle","word_nosc":"remugle","lemma":"remugle","pos":"NOM"} ,
		{"word":"remugles","word_nosc":"remugles","lemma":"remugle","pos":"NOM"} ,
		{"word":"remède","word_nosc":"remede","lemma":"remède","pos":"NOM"} ,
		{"word":"remède-miracle","word_nosc":"remede-miracle","lemma":"remède-miracle","pos":"NOM"} ,
		{"word":"remèdes","word_nosc":"remedes","lemma":"remède","pos":"NOM"} ,
		{"word":"remémoration","word_nosc":"rememoration","lemma":"remémoration","pos":"NOM"} ,
		{"word":"renaissance","word_nosc":"renaissance","lemma":"renaissance","pos":"NOM"} ,
		{"word":"renaissances","word_nosc":"renaissances","lemma":"renaissance","pos":"NOM"} ,
		{"word":"renard","word_nosc":"renard","lemma":"renard","pos":"NOM"} ,
		{"word":"renarde","word_nosc":"renarde","lemma":"renard","pos":"NOM"} ,
		{"word":"renardeau","word_nosc":"renardeau","lemma":"renardeau","pos":"NOM"} ,
		{"word":"renardeaux","word_nosc":"renardeaux","lemma":"renardeau","pos":"NOM"} ,
		{"word":"renardes","word_nosc":"renardes","lemma":"renard","pos":"NOM"} ,
		{"word":"renards","word_nosc":"renards","lemma":"renard","pos":"NOM"} ,
		{"word":"renaud","word_nosc":"renaud","lemma":"renaud","pos":"NOM"} ,
		{"word":"renaudeur","word_nosc":"renaudeur","lemma":"renaudeur","pos":"NOM"} ,
		{"word":"rencard","word_nosc":"rencard","lemma":"rencard","pos":"NOM"} ,
		{"word":"rencards","word_nosc":"rencards","lemma":"rencard","pos":"NOM"} ,
		{"word":"rencontre","word_nosc":"rencontre","lemma":"rencontre","pos":"NOM"} ,
		{"word":"rencontres","word_nosc":"rencontres","lemma":"rencontre","pos":"NOM"} ,
		{"word":"rendement","word_nosc":"rendement","lemma":"rendement","pos":"NOM"} ,
		{"word":"rendements","word_nosc":"rendements","lemma":"rendement","pos":"NOM"} ,
		{"word":"rendez-vous","word_nosc":"rendez-vous","lemma":"rendez-vous","pos":"NOM"} ,
		{"word":"rendu","word_nosc":"rendu","lemma":"rendu","pos":"NOM"} ,
		{"word":"rendus","word_nosc":"rendus","lemma":"rendu","pos":"NOM"} ,
		{"word":"renfermement","word_nosc":"renfermement","lemma":"renfermement","pos":"NOM"} ,
		{"word":"renfermé","word_nosc":"renferme","lemma":"renfermé","pos":"NOM"} ,
		{"word":"renfermée","word_nosc":"renfermee","lemma":"renfermé","pos":"NOM"} ,
		{"word":"renfermées","word_nosc":"renfermees","lemma":"renfermé","pos":"NOM"} ,
		{"word":"renfermés","word_nosc":"renfermes","lemma":"renfermé","pos":"NOM"} ,
		{"word":"renflement","word_nosc":"renflement","lemma":"renflement","pos":"NOM"} ,
		{"word":"renflements","word_nosc":"renflements","lemma":"renflement","pos":"NOM"} ,
		{"word":"renflouage","word_nosc":"renflouage","lemma":"renflouage","pos":"NOM"} ,
		{"word":"renflouement","word_nosc":"renflouement","lemma":"renflouement","pos":"NOM"} ,
		{"word":"renfoncement","word_nosc":"renfoncement","lemma":"renfoncement","pos":"NOM"} ,
		{"word":"renfoncements","word_nosc":"renfoncements","lemma":"renfoncement","pos":"NOM"} ,
		{"word":"renforcement","word_nosc":"renforcement","lemma":"renforcement","pos":"NOM"} ,
		{"word":"renforcements","word_nosc":"renforcements","lemma":"renforcement","pos":"NOM"} ,
		{"word":"renfort","word_nosc":"renfort","lemma":"renfort","pos":"NOM"} ,
		{"word":"renforts","word_nosc":"renforts","lemma":"renfort","pos":"NOM"} ,
		{"word":"renfrognement","word_nosc":"renfrognement","lemma":"renfrognement","pos":"NOM"} ,
		{"word":"rengagement","word_nosc":"rengagement","lemma":"rengagement","pos":"NOM"} ,
		{"word":"rengagés","word_nosc":"rengages","lemma":"rengagé","pos":"NOM"} ,
		{"word":"rengaine","word_nosc":"rengaine","lemma":"rengaine","pos":"NOM"} ,
		{"word":"rengaines","word_nosc":"rengaines","lemma":"rengaine","pos":"NOM"} ,
		{"word":"rengorgement","word_nosc":"rengorgement","lemma":"rengorgement","pos":"NOM"} ,
		{"word":"rengorgements","word_nosc":"rengorgements","lemma":"rengorgement","pos":"NOM"} ,
		{"word":"reniement","word_nosc":"reniement","lemma":"reniement","pos":"NOM"} ,
		{"word":"reniements","word_nosc":"reniements","lemma":"reniement","pos":"NOM"} ,
		{"word":"reniflage","word_nosc":"reniflage","lemma":"reniflage","pos":"NOM"} ,
		{"word":"reniflement","word_nosc":"reniflement","lemma":"reniflement","pos":"NOM"} ,
		{"word":"reniflements","word_nosc":"reniflements","lemma":"reniflement","pos":"NOM"} ,
		{"word":"renifleries","word_nosc":"renifleries","lemma":"reniflerie","pos":"NOM"} ,
		{"word":"reniflette","word_nosc":"reniflette","lemma":"reniflette","pos":"NOM"} ,
		{"word":"renifleur","word_nosc":"renifleur","lemma":"renifleur","pos":"NOM"} ,
		{"word":"renifleurs","word_nosc":"renifleurs","lemma":"renifleur","pos":"NOM"} ,
		{"word":"renne","word_nosc":"renne","lemma":"renne","pos":"NOM"} ,
		{"word":"rennes","word_nosc":"rennes","lemma":"renne","pos":"NOM"} ,
		{"word":"renom","word_nosc":"renom","lemma":"renom","pos":"NOM"} ,
		{"word":"renommée","word_nosc":"renommee","lemma":"renommée","pos":"NOM"} ,
		{"word":"renonce","word_nosc":"renonce","lemma":"renonce","pos":"NOM"} ,
		{"word":"renoncement","word_nosc":"renoncement","lemma":"renoncement","pos":"NOM"} ,
		{"word":"renoncements","word_nosc":"renoncements","lemma":"renoncement","pos":"NOM"} ,
		{"word":"renonces","word_nosc":"renonces","lemma":"renonce","pos":"NOM"} ,
		{"word":"renonciateur","word_nosc":"renonciateur","lemma":"renonciateur","pos":"NOM"} ,
		{"word":"renonciation","word_nosc":"renonciation","lemma":"renonciation","pos":"NOM"} ,
		{"word":"renonciations","word_nosc":"renonciations","lemma":"renonciation","pos":"NOM"} ,
		{"word":"renonculacées","word_nosc":"renonculacees","lemma":"renonculacée","pos":"NOM"} ,
		{"word":"renoncule","word_nosc":"renoncule","lemma":"renoncule","pos":"NOM"} ,
		{"word":"renoncules","word_nosc":"renoncules","lemma":"renoncule","pos":"NOM"} ,
		{"word":"renouveau","word_nosc":"renouveau","lemma":"renouveau","pos":"NOM"} ,
		{"word":"renouveaux","word_nosc":"renouveaux","lemma":"renouveau","pos":"NOM"} ,
		{"word":"renouvellement","word_nosc":"renouvellement","lemma":"renouvellement","pos":"NOM"} ,
		{"word":"renouvellements","word_nosc":"renouvellements","lemma":"renouvellement","pos":"NOM"} ,
		{"word":"renouée","word_nosc":"renouee","lemma":"renouée","pos":"NOM"} ,
		{"word":"renseignement","word_nosc":"renseignement","lemma":"renseignement","pos":"NOM"} ,
		{"word":"renseignements","word_nosc":"renseignements","lemma":"renseignement","pos":"NOM"} ,
		{"word":"rentabilisation","word_nosc":"rentabilisation","lemma":"rentabilisation","pos":"NOM"} ,
		{"word":"rentabilité","word_nosc":"rentabilite","lemma":"rentabilité","pos":"NOM"} ,
		{"word":"rente","word_nosc":"rente","lemma":"rente","pos":"NOM"} ,
		{"word":"rentes","word_nosc":"rentes","lemma":"rente","pos":"NOM"} ,
		{"word":"rentier","word_nosc":"rentier","lemma":"rentier","pos":"NOM"} ,
		{"word":"rentiers","word_nosc":"rentiers","lemma":"rentier","pos":"NOM"} ,
		{"word":"rentière","word_nosc":"rentiere","lemma":"rentier","pos":"NOM"} ,
		{"word":"rentières","word_nosc":"rentieres","lemma":"rentier","pos":"NOM"} ,
		{"word":"rentoilages","word_nosc":"rentoilages","lemma":"rentoilage","pos":"NOM"} ,
		{"word":"rentre-dedans","word_nosc":"rentre-dedans","lemma":"rentre-dedans","pos":"NOM"} ,
		{"word":"rentré","word_nosc":"rentre","lemma":"rentré","pos":"NOM"} ,
		{"word":"rentrée","word_nosc":"rentree","lemma":"rentrée","pos":"NOM"} ,
		{"word":"rentrées","word_nosc":"rentrees","lemma":"rentrée","pos":"NOM"} ,
		{"word":"rentrés","word_nosc":"rentres","lemma":"rentré","pos":"NOM"} ,
		{"word":"renverse","word_nosc":"renverse","lemma":"renverse","pos":"NOM"} ,
		{"word":"renversement","word_nosc":"renversement","lemma":"renversement","pos":"NOM"} ,
		{"word":"renversements","word_nosc":"renversements","lemma":"renversement","pos":"NOM"} ,
		{"word":"renverseur","word_nosc":"renverseur","lemma":"renverseur","pos":"NOM"} ,
		{"word":"renvoi","word_nosc":"renvoi","lemma":"renvoi","pos":"NOM"} ,
		{"word":"renvois","word_nosc":"renvois","lemma":"renvoi","pos":"NOM"} ,
		{"word":"renégat","word_nosc":"renegat","lemma":"renégat","pos":"NOM"} ,
		{"word":"renégate","word_nosc":"renegate","lemma":"renégat","pos":"NOM"} ,
		{"word":"renégats","word_nosc":"renegats","lemma":"renégat","pos":"NOM"} ,
		{"word":"renégociation","word_nosc":"renegociation","lemma":"renégociation","pos":"NOM"} ,
		{"word":"rep","word_nosc":"rep","lemma":"rep","pos":"NOM"} ,
		{"word":"repaire","word_nosc":"repaire","lemma":"repaire","pos":"NOM"} ,
		{"word":"repaires","word_nosc":"repaires","lemma":"repaire","pos":"NOM"} ,
		{"word":"repartie","word_nosc":"repartie","lemma":"repartie","pos":"NOM"} ,
		{"word":"reparties","word_nosc":"reparties","lemma":"repartie","pos":"NOM"} ,
		{"word":"repas","word_nosc":"repas","lemma":"repas","pos":"NOM"} ,
		{"word":"repassage","word_nosc":"repassage","lemma":"repassage","pos":"NOM"} ,
		{"word":"repassages","word_nosc":"repassages","lemma":"repassage","pos":"NOM"} ,
		{"word":"repasseur","word_nosc":"repasseur","lemma":"repasseur","pos":"NOM"} ,
		{"word":"repasseuse","word_nosc":"repasseuse","lemma":"repasseur","pos":"NOM"} ,
		{"word":"repasseuses","word_nosc":"repasseuses","lemma":"repasseur","pos":"NOM"} ,
		{"word":"repavage","word_nosc":"repavage","lemma":"repavage","pos":"NOM"} ,
		{"word":"repeint","word_nosc":"repeint","lemma":"repeint","pos":"NOM"} ,
		{"word":"repeints","word_nosc":"repeints","lemma":"repeint","pos":"NOM"} ,
		{"word":"repentance","word_nosc":"repentance","lemma":"repentance","pos":"NOM"} ,
		{"word":"repentant","word_nosc":"repentant","lemma":"repentant","pos":"NOM"} ,
		{"word":"repentante","word_nosc":"repentante","lemma":"repentant","pos":"NOM"} ,
		{"word":"repentants","word_nosc":"repentants","lemma":"repentant","pos":"NOM"} ,
		{"word":"repenti","word_nosc":"repenti","lemma":"repenti","pos":"NOM"} ,
		{"word":"repentie","word_nosc":"repentie","lemma":"repenti","pos":"NOM"} ,
		{"word":"repentir","word_nosc":"repentir","lemma":"repentir","pos":"NOM"} ,
		{"word":"repentirs","word_nosc":"repentirs","lemma":"repentir","pos":"NOM"} ,
		{"word":"repentis","word_nosc":"repentis","lemma":"repenti","pos":"NOM"} ,
		{"word":"repeuplement","word_nosc":"repeuplement","lemma":"repeuplement","pos":"NOM"} ,
		{"word":"repiquage","word_nosc":"repiquage","lemma":"repiquage","pos":"NOM"} ,
		{"word":"repique","word_nosc":"repique","lemma":"repique","pos":"NOM"} ,
		{"word":"replacement","word_nosc":"replacement","lemma":"replacement","pos":"NOM"} ,
		{"word":"replantation","word_nosc":"replantation","lemma":"replantation","pos":"NOM"} ,
		{"word":"replat","word_nosc":"replat","lemma":"replat","pos":"NOM"} ,
		{"word":"replats","word_nosc":"replats","lemma":"replat","pos":"NOM"} ,
		{"word":"repli","word_nosc":"repli","lemma":"repli","pos":"NOM"} ,
		{"word":"repliement","word_nosc":"repliement","lemma":"repliement","pos":"NOM"} ,
		{"word":"replis","word_nosc":"replis","lemma":"repli","pos":"NOM"} ,
		{"word":"replâtrage","word_nosc":"replatrage","lemma":"replâtrage","pos":"NOM"} ,
		{"word":"report","word_nosc":"report","lemma":"report","pos":"NOM"} ,
		{"word":"reportage","word_nosc":"reportage","lemma":"reportage","pos":"NOM"} ,
		{"word":"reportage-vérité","word_nosc":"reportage-verite","lemma":"reportage-vérité","pos":"NOM"} ,
		{"word":"reportages","word_nosc":"reportages","lemma":"reportage","pos":"NOM"} ,
		{"word":"reporter","word_nosc":"reporter","lemma":"reporter","pos":"NOM"} ,
		{"word":"reporters","word_nosc":"reporters","lemma":"reporter","pos":"NOM"} ,
		{"word":"reports","word_nosc":"reports","lemma":"report","pos":"NOM"} ,
		{"word":"repos","word_nosc":"repos","lemma":"repos","pos":"NOM"} ,
		{"word":"repose","word_nosc":"repose","lemma":"repose","pos":"NOM"} ,
		{"word":"repose-pied","word_nosc":"repose-pied","lemma":"repose-pied","pos":"NOM"} ,
		{"word":"repose-pieds","word_nosc":"repose-pieds","lemma":"repose-pied","pos":"NOM"} ,
		{"word":"reposes","word_nosc":"reposes","lemma":"repose","pos":"NOM"} ,
		{"word":"reposoir","word_nosc":"reposoir","lemma":"reposoir","pos":"NOM"} ,
		{"word":"reposoirs","word_nosc":"reposoirs","lemma":"reposoir","pos":"NOM"} ,
		{"word":"reposée","word_nosc":"reposee","lemma":"reposé","pos":"NOM"} ,
		{"word":"reposées","word_nosc":"reposees","lemma":"reposé","pos":"NOM"} ,
		{"word":"repoussage","word_nosc":"repoussage","lemma":"repoussage","pos":"NOM"} ,
		{"word":"repousse","word_nosc":"repousse","lemma":"repousse","pos":"NOM"} ,
		{"word":"repousses","word_nosc":"repousses","lemma":"repousse","pos":"NOM"} ,
		{"word":"repoussoir","word_nosc":"repoussoir","lemma":"repoussoir","pos":"NOM"} ,
		{"word":"repoussoirs","word_nosc":"repoussoirs","lemma":"repoussoir","pos":"NOM"} ,
		{"word":"repreneur","word_nosc":"repreneur","lemma":"repreneur","pos":"NOM"} ,
		{"word":"repreneurs","word_nosc":"repreneurs","lemma":"repreneur","pos":"NOM"} ,
		{"word":"repris","word_nosc":"repris","lemma":"repris","pos":"NOM"} ,
		{"word":"reprise","word_nosc":"reprise","lemma":"reprise","pos":"NOM"} ,
		{"word":"reprises","word_nosc":"reprises","lemma":"reprise","pos":"NOM"} ,
		{"word":"reproche","word_nosc":"reproche","lemma":"reproche","pos":"NOM"} ,
		{"word":"reproches","word_nosc":"reproches","lemma":"reproche","pos":"NOM"} ,
		{"word":"reproducteur","word_nosc":"reproducteur","lemma":"reproducteur","pos":"NOM"} ,
		{"word":"reproducteurs","word_nosc":"reproducteurs","lemma":"reproducteur","pos":"NOM"} ,
		{"word":"reproduction","word_nosc":"reproduction","lemma":"reproduction","pos":"NOM"} ,
		{"word":"reproductions","word_nosc":"reproductions","lemma":"reproduction","pos":"NOM"} ,
		{"word":"reproductrice","word_nosc":"reproductrice","lemma":"reproducteur","pos":"NOM"} ,
		{"word":"reproductrices","word_nosc":"reproductrices","lemma":"reproducteur","pos":"NOM"} ,
		{"word":"reprographie","word_nosc":"reprographie","lemma":"reprographie","pos":"NOM"} ,
		{"word":"représailles","word_nosc":"represailles","lemma":"représaille","pos":"NOM"} ,
		{"word":"représentant","word_nosc":"representant","lemma":"représentant","pos":"NOM"} ,
		{"word":"représentante","word_nosc":"representante","lemma":"représentant","pos":"NOM"} ,
		{"word":"représentantes","word_nosc":"representantes","lemma":"représentant","pos":"NOM"} ,
		{"word":"représentants","word_nosc":"representants","lemma":"représentant","pos":"NOM"} ,
		{"word":"représentation","word_nosc":"representation","lemma":"représentation","pos":"NOM"} ,
		{"word":"représentations","word_nosc":"representations","lemma":"représentation","pos":"NOM"} ,
		{"word":"reps","word_nosc":"reps","lemma":"reps","pos":"NOM"} ,
		{"word":"reptation","word_nosc":"reptation","lemma":"reptation","pos":"NOM"} ,
		{"word":"reptations","word_nosc":"reptations","lemma":"reptation","pos":"NOM"} ,
		{"word":"reptile","word_nosc":"reptile","lemma":"reptile","pos":"NOM"} ,
		{"word":"reptiles","word_nosc":"reptiles","lemma":"reptile","pos":"NOM"} ,
		{"word":"republication","word_nosc":"republication","lemma":"republication","pos":"NOM"} ,
		{"word":"repère","word_nosc":"repere","lemma":"repère","pos":"NOM"} ,
		{"word":"repères","word_nosc":"reperes","lemma":"repère","pos":"NOM"} ,
		{"word":"repérage","word_nosc":"reperage","lemma":"repérage","pos":"NOM"} ,
		{"word":"repérages","word_nosc":"reperages","lemma":"repérage","pos":"NOM"} ,
		{"word":"repéreur","word_nosc":"repereur","lemma":"repéreur","pos":"NOM"} ,
		{"word":"repêchage","word_nosc":"repechage","lemma":"repêchage","pos":"NOM"} ,
		{"word":"repêchages","word_nosc":"repechages","lemma":"repêchage","pos":"NOM"} ,
		{"word":"requiem","word_nosc":"requiem","lemma":"requiem","pos":"NOM"} ,
		{"word":"requiems","word_nosc":"requiems","lemma":"requiem","pos":"NOM"} ,
		{"word":"requiescat in pace","word_nosc":"requiescat in pace","lemma":"requiescat in pace","pos":"NOM"} ,
		{"word":"requin","word_nosc":"requin","lemma":"requin","pos":"NOM"} ,
		{"word":"requin-baleine","word_nosc":"requin-baleine","lemma":"requin-baleine","pos":"NOM"} ,
		{"word":"requin-tigre","word_nosc":"requin-tigre","lemma":"requin-tigre","pos":"NOM"} ,
		{"word":"requins","word_nosc":"requins","lemma":"requin","pos":"NOM"} ,
		{"word":"requins-marteaux","word_nosc":"requins-marteaux","lemma":"requin-marteau","pos":"NOM"} ,
		{"word":"requis","word_nosc":"requis","lemma":"requis","pos":"NOM"} ,
		{"word":"requérant","word_nosc":"requerant","lemma":"requérant","pos":"NOM"} ,
		{"word":"requérante","word_nosc":"requerante","lemma":"requérant","pos":"NOM"} ,
		{"word":"requérants","word_nosc":"requerants","lemma":"requérant","pos":"NOM"} ,
		{"word":"requête","word_nosc":"requete","lemma":"requête","pos":"NOM"} ,
		{"word":"requêtes","word_nosc":"requetes","lemma":"requête","pos":"NOM"} ,
		{"word":"rescapé","word_nosc":"rescape","lemma":"rescapé","pos":"NOM"} ,
		{"word":"rescapée","word_nosc":"rescapee","lemma":"rescapé","pos":"NOM"} ,
		{"word":"rescapées","word_nosc":"rescapees","lemma":"rescapé","pos":"NOM"} ,
		{"word":"rescapés","word_nosc":"rescapes","lemma":"rescapé","pos":"NOM"} ,
		{"word":"rescision","word_nosc":"rescision","lemma":"rescision","pos":"NOM"} ,
		{"word":"rescousse","word_nosc":"rescousse","lemma":"rescousse","pos":"NOM"} ,
		{"word":"rescrit","word_nosc":"rescrit","lemma":"rescrit","pos":"NOM"} ,
		{"word":"reset","word_nosc":"reset","lemma":"reset","pos":"NOM"} ,
		{"word":"resocialisation","word_nosc":"resocialisation","lemma":"resocialisation","pos":"NOM"} ,
		{"word":"respect","word_nosc":"respect","lemma":"respect","pos":"NOM"} ,
		{"word":"respectabilité","word_nosc":"respectabilite","lemma":"respectabilité","pos":"NOM"} ,
		{"word":"respects","word_nosc":"respects","lemma":"respect","pos":"NOM"} ,
		{"word":"respir","word_nosc":"respir","lemma":"respir","pos":"NOM"} ,
		{"word":"respirateur","word_nosc":"respirateur","lemma":"respirateur","pos":"NOM"} ,
		{"word":"respirateurs","word_nosc":"respirateurs","lemma":"respirateur","pos":"NOM"} ,
		{"word":"respiration","word_nosc":"respiration","lemma":"respiration","pos":"NOM"} ,
		{"word":"respirations","word_nosc":"respirations","lemma":"respiration","pos":"NOM"} ,
		{"word":"resplendissement","word_nosc":"resplendissement","lemma":"resplendissement","pos":"NOM"} ,
		{"word":"responsabilisation","word_nosc":"responsabilisation","lemma":"responsabilisation","pos":"NOM"} ,
		{"word":"responsabilité","word_nosc":"responsabilite","lemma":"responsabilité","pos":"NOM"} ,
		{"word":"responsabilités","word_nosc":"responsabilites","lemma":"responsabilité","pos":"NOM"} ,
		{"word":"responsable","word_nosc":"responsable","lemma":"responsable","pos":"NOM"} ,
		{"word":"responsables","word_nosc":"responsables","lemma":"responsable","pos":"NOM"} ,
		{"word":"resquille","word_nosc":"resquille","lemma":"resquille","pos":"NOM"} ,
		{"word":"resquilleur","word_nosc":"resquilleur","lemma":"resquilleur","pos":"NOM"} ,
		{"word":"resquilleurs","word_nosc":"resquilleurs","lemma":"resquilleur","pos":"NOM"} ,
		{"word":"resquilleuse","word_nosc":"resquilleuse","lemma":"resquilleur","pos":"NOM"} ,
		{"word":"resquilleuses","word_nosc":"resquilleuses","lemma":"resquilleur","pos":"NOM"} ,
		{"word":"ressac","word_nosc":"ressac","lemma":"ressac","pos":"NOM"} ,
		{"word":"ressacs","word_nosc":"ressacs","lemma":"ressac","pos":"NOM"} ,
		{"word":"ressaisissement","word_nosc":"ressaisissement","lemma":"ressaisissement","pos":"NOM"} ,
		{"word":"ressassement","word_nosc":"ressassement","lemma":"ressassement","pos":"NOM"} ,
		{"word":"ressasseur","word_nosc":"ressasseur","lemma":"ressasseur","pos":"NOM"} ,
		{"word":"ressaut","word_nosc":"ressaut","lemma":"ressaut","pos":"NOM"} ,
		{"word":"ressauts","word_nosc":"ressauts","lemma":"ressaut","pos":"NOM"} ,
		{"word":"ressemblance","word_nosc":"ressemblance","lemma":"ressemblance","pos":"NOM"} ,
		{"word":"ressemblances","word_nosc":"ressemblances","lemma":"ressemblance","pos":"NOM"} ,
		{"word":"ressemelage","word_nosc":"ressemelage","lemma":"ressemelage","pos":"NOM"} ,
		{"word":"ressemelages","word_nosc":"ressemelages","lemma":"ressemelage","pos":"NOM"} ,
		{"word":"ressentiment","word_nosc":"ressentiment","lemma":"ressentiment","pos":"NOM"} ,
		{"word":"ressentiments","word_nosc":"ressentiments","lemma":"ressentiment","pos":"NOM"} ,
		{"word":"resserrage","word_nosc":"resserrage","lemma":"resserrage","pos":"NOM"} ,
		{"word":"resserre","word_nosc":"resserre","lemma":"resserre","pos":"NOM"} ,
		{"word":"resserrement","word_nosc":"resserrement","lemma":"resserrement","pos":"NOM"} ,
		{"word":"resserres","word_nosc":"resserres","lemma":"resserre","pos":"NOM"} ,
		{"word":"ressort","word_nosc":"ressort","lemma":"ressort","pos":"NOM"} ,
		{"word":"ressortissant","word_nosc":"ressortissant","lemma":"ressortissant","pos":"NOM"} ,
		{"word":"ressortissante","word_nosc":"ressortissante","lemma":"ressortissant","pos":"NOM"} ,
		{"word":"ressortissants","word_nosc":"ressortissants","lemma":"ressortissant","pos":"NOM"} ,
		{"word":"ressorts","word_nosc":"ressorts","lemma":"ressort","pos":"NOM"} ,
		{"word":"ressource","word_nosc":"ressource","lemma":"ressource","pos":"NOM"} ,
		{"word":"ressourcement","word_nosc":"ressourcement","lemma":"ressourcement","pos":"NOM"} ,
		{"word":"ressources","word_nosc":"ressources","lemma":"ressource","pos":"NOM"} ,
		{"word":"ressui","word_nosc":"ressui","lemma":"ressui","pos":"NOM"} ,
		{"word":"ressuiement","word_nosc":"ressuiement","lemma":"ressuiement","pos":"NOM"} ,
		{"word":"restant","word_nosc":"restant","lemma":"restant","pos":"NOM"} ,
		{"word":"restants","word_nosc":"restants","lemma":"restant","pos":"NOM"} ,
		{"word":"restau","word_nosc":"restau","lemma":"restau","pos":"NOM"} ,
		{"word":"restaurant","word_nosc":"restaurant","lemma":"restaurant","pos":"NOM"} ,
		{"word":"restaurants","word_nosc":"restaurants","lemma":"restaurant","pos":"NOM"} ,
		{"word":"restaurateur","word_nosc":"restaurateur","lemma":"restaurateur","pos":"NOM"} ,
		{"word":"restaurateurs","word_nosc":"restaurateurs","lemma":"restaurateur","pos":"NOM"} ,
		{"word":"restauration","word_nosc":"restauration","lemma":"restauration","pos":"NOM"} ,
		{"word":"restaurations","word_nosc":"restaurations","lemma":"restauration","pos":"NOM"} ,
		{"word":"restauratrice","word_nosc":"restauratrice","lemma":"restaurateur","pos":"NOM"} ,
		{"word":"restauroute","word_nosc":"restauroute","lemma":"restauroute","pos":"NOM"} ,
		{"word":"restaus","word_nosc":"restaus","lemma":"restau","pos":"NOM"} ,
		{"word":"reste","word_nosc":"reste","lemma":"reste","pos":"NOM"} ,
		{"word":"restes","word_nosc":"restes","lemma":"reste","pos":"NOM"} ,
		{"word":"restitution","word_nosc":"restitution","lemma":"restitution","pos":"NOM"} ,
		{"word":"resto","word_nosc":"resto","lemma":"resto","pos":"NOM"} ,
		{"word":"restoroute","word_nosc":"restoroute","lemma":"restoroute","pos":"NOM"} ,
		{"word":"restos","word_nosc":"restos","lemma":"resto","pos":"NOM"} ,
		{"word":"restriction","word_nosc":"restriction","lemma":"restriction","pos":"NOM"} ,
		{"word":"restrictions","word_nosc":"restrictions","lemma":"restriction","pos":"NOM"} ,
		{"word":"restructuration","word_nosc":"restructuration","lemma":"restructuration","pos":"NOM"} ,
		{"word":"restructurations","word_nosc":"restructurations","lemma":"restructuration","pos":"NOM"} ,
		{"word":"resucée","word_nosc":"resucee","lemma":"resucée","pos":"NOM"} ,
		{"word":"retable","word_nosc":"retable","lemma":"retable","pos":"NOM"} ,
		{"word":"retables","word_nosc":"retables","lemma":"retable","pos":"NOM"} ,
		{"word":"retape","word_nosc":"retape","lemma":"retape","pos":"NOM"} ,
		{"word":"retapeur","word_nosc":"retapeur","lemma":"retapeur","pos":"NOM"} ,
		{"word":"retard","word_nosc":"retard","lemma":"retard","pos":"NOM"} ,
		{"word":"retardataire","word_nosc":"retardataire","lemma":"retardataire","pos":"NOM"} ,
		{"word":"retardataires","word_nosc":"retardataires","lemma":"retardataire","pos":"NOM"} ,
		{"word":"retardateur","word_nosc":"retardateur","lemma":"retardateur","pos":"NOM"} ,
		{"word":"retardement","word_nosc":"retardement","lemma":"retardement","pos":"NOM"} ,
		{"word":"retards","word_nosc":"retards","lemma":"retard","pos":"NOM"} ,
		{"word":"retardé","word_nosc":"retarde","lemma":"retardé","pos":"NOM"} ,
		{"word":"retardée","word_nosc":"retardee","lemma":"retardé","pos":"NOM"} ,
		{"word":"retardées","word_nosc":"retardees","lemma":"retardé","pos":"NOM"} ,
		{"word":"retardés","word_nosc":"retardes","lemma":"retardé","pos":"NOM"} ,
		{"word":"retentissement","word_nosc":"retentissement","lemma":"retentissement","pos":"NOM"} ,
		{"word":"retenue","word_nosc":"retenue","lemma":"retenue","pos":"NOM"} ,
		{"word":"retenues","word_nosc":"retenues","lemma":"retenue","pos":"NOM"} ,
		{"word":"retirement","word_nosc":"retirement","lemma":"retirement","pos":"NOM"} ,
		{"word":"retiro","word_nosc":"retiro","lemma":"retiro","pos":"NOM"} ,
		{"word":"retombe","word_nosc":"retombe","lemma":"retombe","pos":"NOM"} ,
		{"word":"retombement","word_nosc":"retombement","lemma":"retombement","pos":"NOM"} ,
		{"word":"retombée","word_nosc":"retombee","lemma":"retombée","pos":"NOM"} ,
		{"word":"retombées","word_nosc":"retombees","lemma":"retombée","pos":"NOM"} ,
		{"word":"retouche","word_nosc":"retouche","lemma":"retouche","pos":"NOM"} ,
		{"word":"retouches","word_nosc":"retouches","lemma":"retouche","pos":"NOM"} ,
		{"word":"retoucheur","word_nosc":"retoucheur","lemma":"retoucheur","pos":"NOM"} ,
		{"word":"retour","word_nosc":"retour","lemma":"retour","pos":"NOM"} ,
		{"word":"retourne","word_nosc":"retourne","lemma":"retourne","pos":"NOM"} ,
		{"word":"retournement","word_nosc":"retournement","lemma":"retournement","pos":"NOM"} ,
		{"word":"retournements","word_nosc":"retournements","lemma":"retournement","pos":"NOM"} ,
		{"word":"retournes","word_nosc":"retournes","lemma":"retourne","pos":"NOM"} ,
		{"word":"retourné","word_nosc":"retourne","lemma":"retourné","pos":"NOM"} ,
		{"word":"retournés","word_nosc":"retournes","lemma":"retourné","pos":"NOM"} ,
		{"word":"retours","word_nosc":"retours","lemma":"retour","pos":"NOM"} ,
		{"word":"retraduction","word_nosc":"retraduction","lemma":"retraduction","pos":"NOM"} ,
		{"word":"retrait","word_nosc":"retrait","lemma":"retrait","pos":"NOM"} ,
		{"word":"retraite","word_nosc":"retraite","lemma":"retraite","pos":"NOM"} ,
		{"word":"retraitement","word_nosc":"retraitement","lemma":"retraitement","pos":"NOM"} ,
		{"word":"retraites","word_nosc":"retraites","lemma":"retraite","pos":"NOM"} ,
		{"word":"retraits","word_nosc":"retraits","lemma":"retrait","pos":"NOM"} ,
		{"word":"retraité","word_nosc":"retraite","lemma":"retraité","pos":"NOM"} ,
		{"word":"retraitée","word_nosc":"retraitee","lemma":"retraité","pos":"NOM"} ,
		{"word":"retraitées","word_nosc":"retraitees","lemma":"retraité","pos":"NOM"} ,
		{"word":"retraités","word_nosc":"retraites","lemma":"retraité","pos":"NOM"} ,
		{"word":"retranchement","word_nosc":"retranchement","lemma":"retranchement","pos":"NOM"} ,
		{"word":"retranchements","word_nosc":"retranchements","lemma":"retranchement","pos":"NOM"} ,
		{"word":"retranscription","word_nosc":"retranscription","lemma":"retranscription","pos":"NOM"} ,
		{"word":"retransmission","word_nosc":"retransmission","lemma":"retransmission","pos":"NOM"} ,
		{"word":"retransmissions","word_nosc":"retransmissions","lemma":"retransmission","pos":"NOM"} ,
		{"word":"retreinte","word_nosc":"retreinte","lemma":"retreinte","pos":"NOM"} ,
		{"word":"retriever","word_nosc":"retriever","lemma":"retriever","pos":"NOM"} ,
		{"word":"retroussage","word_nosc":"retroussage","lemma":"retroussage","pos":"NOM"} ,
		{"word":"retroussette","word_nosc":"retroussette","lemma":"retroussette","pos":"NOM"} ,
		{"word":"retroussis","word_nosc":"retroussis","lemma":"retroussis","pos":"NOM"} ,
		{"word":"retrouvaille","word_nosc":"retrouvaille","lemma":"retrouvaille","pos":"NOM"} ,
		{"word":"retrouvailles","word_nosc":"retrouvailles","lemma":"retrouvaille","pos":"NOM"} ,
		{"word":"rets","word_nosc":"rets","lemma":"rets","pos":"NOM"} ,
		{"word":"retsina","word_nosc":"retsina","lemma":"retsina","pos":"NOM"} ,
		{"word":"reubeu","word_nosc":"reubeu","lemma":"reubeu","pos":"NOM"} ,
		{"word":"reubeus","word_nosc":"reubeus","lemma":"reubeu","pos":"NOM"} ,
		{"word":"revalidation","word_nosc":"revalidation","lemma":"revalidation","pos":"NOM"} ,
		{"word":"revalorisation","word_nosc":"revalorisation","lemma":"revalorisation","pos":"NOM"} ,
		{"word":"revanchard","word_nosc":"revanchard","lemma":"revanchard","pos":"NOM"} ,
		{"word":"revancharde","word_nosc":"revancharde","lemma":"revanchard","pos":"NOM"} ,
		{"word":"revanchards","word_nosc":"revanchards","lemma":"revanchard","pos":"NOM"} ,
		{"word":"revanche","word_nosc":"revanche","lemma":"revanche","pos":"NOM"} ,
		{"word":"revanches","word_nosc":"revanches","lemma":"revanche","pos":"NOM"} ,
		{"word":"revenant","word_nosc":"revenant","lemma":"revenant","pos":"NOM"} ,
		{"word":"revenants","word_nosc":"revenants","lemma":"revenant","pos":"NOM"} ,
		{"word":"revendeur","word_nosc":"revendeur","lemma":"revendeur","pos":"NOM"} ,
		{"word":"revendeurs","word_nosc":"revendeurs","lemma":"revendeur","pos":"NOM"} ,
		{"word":"revendeuse","word_nosc":"revendeuse","lemma":"revendeur","pos":"NOM"} ,
		{"word":"revendicateurs","word_nosc":"revendicateurs","lemma":"revendicateur","pos":"NOM"} ,
		{"word":"revendication","word_nosc":"revendication","lemma":"revendication","pos":"NOM"} ,
		{"word":"revendications","word_nosc":"revendications","lemma":"revendication","pos":"NOM"} ,
		{"word":"revente","word_nosc":"revente","lemma":"revente","pos":"NOM"} ,
		{"word":"reventes","word_nosc":"reventes","lemma":"revente","pos":"NOM"} ,
		{"word":"revenu","word_nosc":"revenu","lemma":"revenu","pos":"NOM"} ,
		{"word":"revenus","word_nosc":"revenus","lemma":"revenu","pos":"NOM"} ,
		{"word":"revers","word_nosc":"revers","lemma":"revers","pos":"NOM"} ,
		{"word":"revient","word_nosc":"revient","lemma":"revient","pos":"NOM"} ,
		{"word":"revirement","word_nosc":"revirement","lemma":"revirement","pos":"NOM"} ,
		{"word":"revirements","word_nosc":"revirements","lemma":"revirement","pos":"NOM"} ,
		{"word":"revitalisation","word_nosc":"revitalisation","lemma":"revitalisation","pos":"NOM"} ,
		{"word":"revival","word_nosc":"revival","lemma":"revival","pos":"NOM"} ,
		{"word":"reviviscence","word_nosc":"reviviscence","lemma":"reviviscence","pos":"NOM"} ,
		{"word":"revoir","word_nosc":"revoir","lemma":"revoir","pos":"NOM"} ,
		{"word":"revolver","word_nosc":"revolver","lemma":"revolver","pos":"NOM"} ,
		{"word":"revolvers","word_nosc":"revolvers","lemma":"revolver","pos":"NOM"} ,
		{"word":"revoyure","word_nosc":"revoyure","lemma":"revoyure","pos":"NOM"} ,
		{"word":"revue","word_nosc":"revue","lemma":"revue","pos":"NOM"} ,
		{"word":"revues","word_nosc":"revues","lemma":"revue","pos":"NOM"} ,
		{"word":"revuistes","word_nosc":"revuistes","lemma":"revuiste","pos":"NOM"} ,
		{"word":"revêtement","word_nosc":"revetement","lemma":"revêtement","pos":"NOM"} ,
		{"word":"revêtements","word_nosc":"revetements","lemma":"revêtement","pos":"NOM"} ,
		{"word":"rex","word_nosc":"rex","lemma":"rex","pos":"NOM"} ,
		{"word":"rexistes","word_nosc":"rexistes","lemma":"rexiste","pos":"NOM"} ,
		{"word":"rez-de-chaussée","word_nosc":"rez-de-chaussee","lemma":"rez-de-chaussée","pos":"NOM"} ,
		{"word":"rez-de-jardin","word_nosc":"rez-de-jardin","lemma":"rez-de-jardin","pos":"NOM"} ,
		{"word":"rezzou","word_nosc":"rezzou","lemma":"rezzou","pos":"NOM"} ,
		{"word":"rezzous","word_nosc":"rezzous","lemma":"rezzou","pos":"NOM"} ,
		{"word":"reçu","word_nosc":"recu","lemma":"reçu","pos":"NOM"} ,
		{"word":"reçus","word_nosc":"recus","lemma":"reçu","pos":"NOM"} ,
		{"word":"reître","word_nosc":"reitre","lemma":"reître","pos":"NOM"} ,
		{"word":"reîtres","word_nosc":"reitres","lemma":"reître","pos":"NOM"} ,
		{"word":"rhabillage","word_nosc":"rhabillage","lemma":"rhabillage","pos":"NOM"} ,
		{"word":"rhabilleur","word_nosc":"rhabilleur","lemma":"rhabilleur","pos":"NOM"} ,
		{"word":"rhapsodie","word_nosc":"rhapsodie","lemma":"rhapsodie","pos":"NOM"} ,
		{"word":"rhapsodies","word_nosc":"rhapsodies","lemma":"rhapsodie","pos":"NOM"} ,
		{"word":"rhingraves","word_nosc":"rhingraves","lemma":"rhingrave","pos":"NOM"} ,
		{"word":"rhinite","word_nosc":"rhinite","lemma":"rhinite","pos":"NOM"} ,
		{"word":"rhinites","word_nosc":"rhinites","lemma":"rhinite","pos":"NOM"} ,
		{"word":"rhino","word_nosc":"rhino","lemma":"rhino","pos":"NOM"} ,
		{"word":"rhinocéros","word_nosc":"rhinoceros","lemma":"rhinocéros","pos":"NOM"} ,
		{"word":"rhinoplastie","word_nosc":"rhinoplastie","lemma":"rhinoplastie","pos":"NOM"} ,
		{"word":"rhinos","word_nosc":"rhinos","lemma":"rhino","pos":"NOM"} ,
		{"word":"rhinoscopie","word_nosc":"rhinoscopie","lemma":"rhinoscopie","pos":"NOM"} ,
		{"word":"rhizome","word_nosc":"rhizome","lemma":"rhizome","pos":"NOM"} ,
		{"word":"rhizopus","word_nosc":"rhizopus","lemma":"rhizopus","pos":"NOM"} ,
		{"word":"rho","word_nosc":"rho","lemma":"rho","pos":"NOM"} ,
		{"word":"rhodamine","word_nosc":"rhodamine","lemma":"rhodamine","pos":"NOM"} ,
		{"word":"rhodia","word_nosc":"rhodia","lemma":"rhodia","pos":"NOM"} ,
		{"word":"rhodium","word_nosc":"rhodium","lemma":"rhodium","pos":"NOM"} ,
		{"word":"rhodo","word_nosc":"rhodo","lemma":"rhodo","pos":"NOM"} ,
		{"word":"rhododendron","word_nosc":"rhododendron","lemma":"rhododendron","pos":"NOM"} ,
		{"word":"rhododendrons","word_nosc":"rhododendrons","lemma":"rhododendron","pos":"NOM"} ,
		{"word":"rhodoïd","word_nosc":"rhodoid","lemma":"rhodoïd","pos":"NOM"} ,
		{"word":"rhombe","word_nosc":"rhombe","lemma":"rhombe","pos":"NOM"} ,
		{"word":"rhomboïdes","word_nosc":"rhomboides","lemma":"rhomboïde","pos":"NOM"} ,
		{"word":"rhovyl","word_nosc":"rhovyl","lemma":"rhovyl","pos":"NOM"} ,
		{"word":"rhubarbe","word_nosc":"rhubarbe","lemma":"rhubarbe","pos":"NOM"} ,
		{"word":"rhubarbes","word_nosc":"rhubarbes","lemma":"rhubarbe","pos":"NOM"} ,
		{"word":"rhum","word_nosc":"rhum","lemma":"rhum","pos":"NOM"} ,
		{"word":"rhumatisme","word_nosc":"rhumatisme","lemma":"rhumatisme","pos":"NOM"} ,
		{"word":"rhumatismes","word_nosc":"rhumatismes","lemma":"rhumatisme","pos":"NOM"} ,
		{"word":"rhumatologie","word_nosc":"rhumatologie","lemma":"rhumatologie","pos":"NOM"} ,
		{"word":"rhumatologue","word_nosc":"rhumatologue","lemma":"rhumatologue","pos":"NOM"} ,
		{"word":"rhumbs","word_nosc":"rhumbs","lemma":"rhumb","pos":"NOM"} ,
		{"word":"rhume","word_nosc":"rhume","lemma":"rhume","pos":"NOM"} ,
		{"word":"rhumerie","word_nosc":"rhumerie","lemma":"rhumerie","pos":"NOM"} ,
		{"word":"rhumes","word_nosc":"rhumes","lemma":"rhume","pos":"NOM"} ,
		{"word":"rhynchites","word_nosc":"rhynchites","lemma":"rhynchite","pos":"NOM"} ,
		{"word":"rhyolite","word_nosc":"rhyolite","lemma":"rhyolite","pos":"NOM"} ,
		{"word":"rhythm and blues","word_nosc":"rhythm and blues","lemma":"rhythm and blues","pos":"NOM"} ,
		{"word":"rhythm'n'blues","word_nosc":"rhythm'n'blues","lemma":"rhythm'n'blues","pos":"NOM"} ,
		{"word":"rhéostat","word_nosc":"rheostat","lemma":"rhéostat","pos":"NOM"} ,
		{"word":"rhésus","word_nosc":"rhesus","lemma":"rhésus","pos":"NOM"} ,
		{"word":"rhéteur","word_nosc":"rheteur","lemma":"rhéteur","pos":"NOM"} ,
		{"word":"rhéteurs","word_nosc":"rheteurs","lemma":"rhéteur","pos":"NOM"} ,
		{"word":"rhétorique","word_nosc":"rhetorique","lemma":"rhétorique","pos":"NOM"} ,
		{"word":"rhétoriques","word_nosc":"rhetoriques","lemma":"rhétorique","pos":"NOM"} ,
		{"word":"rhô","word_nosc":"rho","lemma":"rhô","pos":"NOM"} ,
		{"word":"ria","word_nosc":"ria","lemma":"ria","pos":"NOM"} ,
		{"word":"rial","word_nosc":"rial","lemma":"rial","pos":"NOM"} ,
		{"word":"rials","word_nosc":"rials","lemma":"rial","pos":"NOM"} ,
		{"word":"ribambelle","word_nosc":"ribambelle","lemma":"ribambelle","pos":"NOM"} ,
		{"word":"ribambelles","word_nosc":"ribambelles","lemma":"ribambelle","pos":"NOM"} ,
		{"word":"ribaud","word_nosc":"ribaud","lemma":"ribaud","pos":"NOM"} ,
		{"word":"ribauds","word_nosc":"ribauds","lemma":"ribaud","pos":"NOM"} ,
		{"word":"ribes","word_nosc":"ribes","lemma":"ribes","pos":"NOM"} ,
		{"word":"ribleur","word_nosc":"ribleur","lemma":"ribleur","pos":"NOM"} ,
		{"word":"riboflavine","word_nosc":"riboflavine","lemma":"riboflavine","pos":"NOM"} ,
		{"word":"ribosomes","word_nosc":"ribosomes","lemma":"ribosome","pos":"NOM"} ,
		{"word":"ribot","word_nosc":"ribot","lemma":"ribot","pos":"NOM"} ,
		{"word":"ribote","word_nosc":"ribote","lemma":"ribot","pos":"NOM"} ,
		{"word":"riboteur","word_nosc":"riboteur","lemma":"riboteur","pos":"NOM"} ,
		{"word":"ribouis","word_nosc":"ribouis","lemma":"ribouis","pos":"NOM"} ,
		{"word":"ribouldingue","word_nosc":"ribouldingue","lemma":"ribouldingue","pos":"NOM"} ,
		{"word":"ricain","word_nosc":"ricain","lemma":"ricain","pos":"NOM"} ,
		{"word":"ricaines","word_nosc":"ricaines","lemma":"ricain","pos":"NOM"} ,
		{"word":"ricains","word_nosc":"ricains","lemma":"ricain","pos":"NOM"} ,
		{"word":"ricanement","word_nosc":"ricanement","lemma":"ricanement","pos":"NOM"} ,
		{"word":"ricanements","word_nosc":"ricanements","lemma":"ricanement","pos":"NOM"} ,
		{"word":"ricaneur","word_nosc":"ricaneur","lemma":"ricaneur","pos":"NOM"} ,
		{"word":"ricaneurs","word_nosc":"ricaneurs","lemma":"ricaneur","pos":"NOM"} ,
		{"word":"richard","word_nosc":"richard","lemma":"richard","pos":"NOM"} ,
		{"word":"richarde","word_nosc":"richarde","lemma":"richard","pos":"NOM"} ,
		{"word":"richards","word_nosc":"richards","lemma":"richard","pos":"NOM"} ,
		{"word":"riche","word_nosc":"riche","lemma":"riche","pos":"NOM"} ,
		{"word":"richelieu","word_nosc":"richelieu","lemma":"richelieu","pos":"NOM"} ,
		{"word":"richelieus","word_nosc":"richelieus","lemma":"richelieu","pos":"NOM"} ,
		{"word":"riches","word_nosc":"riches","lemma":"riche","pos":"NOM"} ,
		{"word":"richesse","word_nosc":"richesse","lemma":"richesse","pos":"NOM"} ,
		{"word":"richesses","word_nosc":"richesses","lemma":"richesse","pos":"NOM"} ,
		{"word":"richissime","word_nosc":"richissime","lemma":"richissime","pos":"NOM"} ,
		{"word":"richissimes","word_nosc":"richissimes","lemma":"richissime","pos":"NOM"} ,
		{"word":"richomme","word_nosc":"richomme","lemma":"richomme","pos":"NOM"} ,
		{"word":"ricin","word_nosc":"ricin","lemma":"ricin","pos":"NOM"} ,
		{"word":"ricochet","word_nosc":"ricochet","lemma":"ricochet","pos":"NOM"} ,
		{"word":"ricochets","word_nosc":"ricochets","lemma":"ricochet","pos":"NOM"} ,
		{"word":"ricotta","word_nosc":"ricotta","lemma":"ricotta","pos":"NOM"} ,
		{"word":"rictus","word_nosc":"rictus","lemma":"rictus","pos":"NOM"} ,
		{"word":"ride","word_nosc":"ride","lemma":"ride","pos":"NOM"} ,
		{"word":"rideau","word_nosc":"rideau","lemma":"rideau","pos":"NOM"} ,
		{"word":"rideaux","word_nosc":"rideaux","lemma":"rideau","pos":"NOM"} ,
		{"word":"ridelle","word_nosc":"ridelle","lemma":"rideau","pos":"NOM"} ,
		{"word":"ridelles","word_nosc":"ridelles","lemma":"rideau","pos":"NOM"} ,
		{"word":"rides","word_nosc":"rides","lemma":"ride","pos":"NOM"} ,
		{"word":"ridicule","word_nosc":"ridicule","lemma":"ridicule","pos":"NOM"} ,
		{"word":"ridicules","word_nosc":"ridicules","lemma":"ridicule","pos":"NOM"} ,
		{"word":"ridiculisation","word_nosc":"ridiculisation","lemma":"ridiculisation","pos":"NOM"} ,
		{"word":"ridiculités","word_nosc":"ridiculites","lemma":"ridiculité","pos":"NOM"} ,
		{"word":"ridule","word_nosc":"ridule","lemma":"ridule","pos":"NOM"} ,
		{"word":"ridules","word_nosc":"ridules","lemma":"ridule","pos":"NOM"} ,
		{"word":"riel","word_nosc":"riel","lemma":"riel","pos":"NOM"} ,
		{"word":"rien","word_nosc":"rien","lemma":"rien","pos":"NOM"} ,
		{"word":"riens","word_nosc":"riens","lemma":"rien","pos":"NOM"} ,
		{"word":"riesling","word_nosc":"riesling","lemma":"riesling","pos":"NOM"} ,
		{"word":"rieur","word_nosc":"rieur","lemma":"rieur","pos":"NOM"} ,
		{"word":"rieurs","word_nosc":"rieurs","lemma":"rieur","pos":"NOM"} ,
		{"word":"rieuse","word_nosc":"rieuse","lemma":"rieur","pos":"NOM"} ,
		{"word":"rif","word_nosc":"rif","lemma":"rif","pos":"NOM"} ,
		{"word":"rifain","word_nosc":"rifain","lemma":"rifain","pos":"NOM"} ,
		{"word":"rifains","word_nosc":"rifains","lemma":"rifain","pos":"NOM"} ,
		{"word":"riff","word_nosc":"riff","lemma":"riff","pos":"NOM"} ,
		{"word":"riffs","word_nosc":"riffs","lemma":"riff","pos":"NOM"} ,
		{"word":"rififi","word_nosc":"rififi","lemma":"rififi","pos":"NOM"} ,
		{"word":"riflard","word_nosc":"riflard","lemma":"riflard","pos":"NOM"} ,
		{"word":"rifle","word_nosc":"rifle","lemma":"rifle","pos":"NOM"} ,
		{"word":"rifles","word_nosc":"rifles","lemma":"rifle","pos":"NOM"} ,
		{"word":"riflette","word_nosc":"riflette","lemma":"riflette","pos":"NOM"} ,
		{"word":"rift","word_nosc":"rift","lemma":"rift","pos":"NOM"} ,
		{"word":"rigaudon","word_nosc":"rigaudon","lemma":"rigaudon","pos":"NOM"} ,
		{"word":"rigaudons","word_nosc":"rigaudons","lemma":"rigaudon","pos":"NOM"} ,
		{"word":"rigidité","word_nosc":"rigidite","lemma":"rigidité","pos":"NOM"} ,
		{"word":"rigidités","word_nosc":"rigidites","lemma":"rigidité","pos":"NOM"} ,
		{"word":"rigodon","word_nosc":"rigodon","lemma":"rigodon","pos":"NOM"} ,
		{"word":"rigolade","word_nosc":"rigolade","lemma":"rigolade","pos":"NOM"} ,
		{"word":"rigolades","word_nosc":"rigolades","lemma":"rigolade","pos":"NOM"} ,
		{"word":"rigolage","word_nosc":"rigolage","lemma":"rigolage","pos":"NOM"} ,
		{"word":"rigolard","word_nosc":"rigolard","lemma":"rigolard","pos":"NOM"} ,
		{"word":"rigolarde","word_nosc":"rigolarde","lemma":"rigolard","pos":"NOM"} ,
		{"word":"rigolards","word_nosc":"rigolards","lemma":"rigolard","pos":"NOM"} ,
		{"word":"rigole","word_nosc":"rigole","lemma":"rigole","pos":"NOM"} ,
		{"word":"rigoles","word_nosc":"rigoles","lemma":"rigole","pos":"NOM"} ,
		{"word":"rigollot","word_nosc":"rigollot","lemma":"rigollot","pos":"NOM"} ,
		{"word":"rigolo","word_nosc":"rigolo","lemma":"rigolo","pos":"NOM"} ,
		{"word":"rigolos","word_nosc":"rigolos","lemma":"rigolo","pos":"NOM"} ,
		{"word":"rigolote","word_nosc":"rigolote","lemma":"rigolote","pos":"NOM"} ,
		{"word":"rigolotes","word_nosc":"rigolotes","lemma":"rigolote","pos":"NOM"} ,
		{"word":"rigorisme","word_nosc":"rigorisme","lemma":"rigorisme","pos":"NOM"} ,
		{"word":"rigoristes","word_nosc":"rigoristes","lemma":"rigoriste","pos":"NOM"} ,
		{"word":"rigoureuse","word_nosc":"rigoureuse","lemma":"rigoureux","pos":"NOM"} ,
		{"word":"rigueur","word_nosc":"rigueur","lemma":"rigueur","pos":"NOM"} ,
		{"word":"rigueurs","word_nosc":"rigueurs","lemma":"rigueur","pos":"NOM"} ,
		{"word":"rillettes","word_nosc":"rillettes","lemma":"rillettes","pos":"NOM"} ,
		{"word":"rillons","word_nosc":"rillons","lemma":"rillons","pos":"NOM"} ,
		{"word":"rimaillerie","word_nosc":"rimaillerie","lemma":"rimaillerie","pos":"NOM"} ,
		{"word":"rimailleur","word_nosc":"rimailleur","lemma":"rimailleur","pos":"NOM"} ,
		{"word":"rimailleurs","word_nosc":"rimailleurs","lemma":"rimailleur","pos":"NOM"} ,
		{"word":"rimaye","word_nosc":"rimaye","lemma":"rimaye","pos":"NOM"} ,
		{"word":"rime","word_nosc":"rime","lemma":"rime","pos":"NOM"} ,
		{"word":"rimes","word_nosc":"rimes","lemma":"rime","pos":"NOM"} ,
		{"word":"rimeur","word_nosc":"rimeur","lemma":"rimeur","pos":"NOM"} ,
		{"word":"rimeurs","word_nosc":"rimeurs","lemma":"rimeur","pos":"NOM"} ,
		{"word":"rimeuse","word_nosc":"rimeuse","lemma":"rimeur","pos":"NOM"} ,
		{"word":"rimmel","word_nosc":"rimmel","lemma":"rimmel","pos":"NOM"} ,
		{"word":"rince-bouche","word_nosc":"rince-bouche","lemma":"rince-bouche","pos":"NOM"} ,
		{"word":"rince-doigts","word_nosc":"rince-doigts","lemma":"rince-doigts","pos":"NOM"} ,
		{"word":"rinceau","word_nosc":"rinceau","lemma":"rinceau","pos":"NOM"} ,
		{"word":"rinceaux","word_nosc":"rinceaux","lemma":"rinceau","pos":"NOM"} ,
		{"word":"rincette","word_nosc":"rincette","lemma":"rincette","pos":"NOM"} ,
		{"word":"rinceur","word_nosc":"rinceur","lemma":"rinceur","pos":"NOM"} ,
		{"word":"rincée","word_nosc":"rincee","lemma":"rincée","pos":"NOM"} ,
		{"word":"ring","word_nosc":"ring","lemma":"ring","pos":"NOM"} ,
		{"word":"ringard","word_nosc":"ringard","lemma":"ringard","pos":"NOM"} ,
		{"word":"ringarde","word_nosc":"ringarde","lemma":"ringard","pos":"NOM"} ,
		{"word":"ringardes","word_nosc":"ringardes","lemma":"ringard","pos":"NOM"} ,
		{"word":"ringardise","word_nosc":"ringardise","lemma":"ringardise","pos":"NOM"} ,
		{"word":"ringardises","word_nosc":"ringardises","lemma":"ringardise","pos":"NOM"} ,
		{"word":"ringards","word_nosc":"ringards","lemma":"ringard","pos":"NOM"} ,
		{"word":"rings","word_nosc":"rings","lemma":"ring","pos":"NOM"} ,
		{"word":"rink","word_nosc":"rink","lemma":"rink","pos":"NOM"} ,
		{"word":"rinçage","word_nosc":"rincage","lemma":"rinçage","pos":"NOM"} ,
		{"word":"rinçages","word_nosc":"rincages","lemma":"rinçage","pos":"NOM"} ,
		{"word":"rioja","word_nosc":"rioja","lemma":"rioja","pos":"NOM"} ,
		{"word":"ripaille","word_nosc":"ripaille","lemma":"ripaille","pos":"NOM"} ,
		{"word":"ripailles","word_nosc":"ripailles","lemma":"ripaille","pos":"NOM"} ,
		{"word":"ripaton","word_nosc":"ripaton","lemma":"ripaton","pos":"NOM"} ,
		{"word":"ripatons","word_nosc":"ripatons","lemma":"ripaton","pos":"NOM"} ,
		{"word":"ripe","word_nosc":"ripe","lemma":"rip","pos":"NOM"} ,
		{"word":"ripeur","word_nosc":"ripeur","lemma":"ripeur","pos":"NOM"} ,
		{"word":"ripolin","word_nosc":"ripolin","lemma":"ripolin","pos":"NOM"} ,
		{"word":"riposte","word_nosc":"riposte","lemma":"riposte","pos":"NOM"} ,
		{"word":"ripostes","word_nosc":"ripostes","lemma":"riposte","pos":"NOM"} ,
		{"word":"ripoux","word_nosc":"ripoux","lemma":"ripoux","pos":"NOM"} ,
		{"word":"ripper","word_nosc":"ripper","lemma":"ripper","pos":"NOM"} ,
		{"word":"rire","word_nosc":"rire","lemma":"rire","pos":"NOM"} ,
		{"word":"rires","word_nosc":"rires","lemma":"rire","pos":"NOM"} ,
		{"word":"ris","word_nosc":"ris","lemma":"ris","pos":"NOM"} ,
		{"word":"rise","word_nosc":"rise","lemma":"rise","pos":"NOM"} ,
		{"word":"riser","word_nosc":"riser","lemma":"riser","pos":"NOM"} ,
		{"word":"risette","word_nosc":"risette","lemma":"risette","pos":"NOM"} ,
		{"word":"risettes","word_nosc":"risettes","lemma":"risette","pos":"NOM"} ,
		{"word":"risibilité","word_nosc":"risibilite","lemma":"risibilité","pos":"NOM"} ,
		{"word":"risorius","word_nosc":"risorius","lemma":"risorius","pos":"NOM"} ,
		{"word":"risotto","word_nosc":"risotto","lemma":"risotto","pos":"NOM"} ,
		{"word":"risque","word_nosc":"risque","lemma":"risque","pos":"NOM"} ,
		{"word":"risques","word_nosc":"risques","lemma":"risque","pos":"NOM"} ,
		{"word":"rissole","word_nosc":"rissole","lemma":"rissole","pos":"NOM"} ,
		{"word":"rissoles","word_nosc":"rissoles","lemma":"rissole","pos":"NOM"} ,
		{"word":"ristourne","word_nosc":"ristourne","lemma":"ristourne","pos":"NOM"} ,
		{"word":"ristournes","word_nosc":"ristournes","lemma":"ristourne","pos":"NOM"} ,
		{"word":"risée","word_nosc":"risee","lemma":"risée","pos":"NOM"} ,
		{"word":"risées","word_nosc":"risees","lemma":"risée","pos":"NOM"} ,
		{"word":"rital","word_nosc":"rital","lemma":"rital","pos":"NOM"} ,
		{"word":"ritale","word_nosc":"ritale","lemma":"rital","pos":"NOM"} ,
		{"word":"ritales","word_nosc":"ritales","lemma":"rital","pos":"NOM"} ,
		{"word":"ritals","word_nosc":"ritals","lemma":"rital","pos":"NOM"} ,
		{"word":"rite","word_nosc":"rite","lemma":"rite","pos":"NOM"} ,
		{"word":"rites","word_nosc":"rites","lemma":"rite","pos":"NOM"} ,
		{"word":"ritournelle","word_nosc":"ritournelle","lemma":"ritournelle","pos":"NOM"} ,
		{"word":"ritournelles","word_nosc":"ritournelles","lemma":"ritournelle","pos":"NOM"} ,
		{"word":"rittes","word_nosc":"rittes","lemma":"ritt","pos":"NOM"} ,
		{"word":"ritualisation","word_nosc":"ritualisation","lemma":"ritualisation","pos":"NOM"} ,
		{"word":"ritualistes","word_nosc":"ritualistes","lemma":"ritualiste","pos":"NOM"} ,
		{"word":"rituel","word_nosc":"rituel","lemma":"rituel","pos":"NOM"} ,
		{"word":"rituels","word_nosc":"rituels","lemma":"rituel","pos":"NOM"} ,
		{"word":"rivage","word_nosc":"rivage","lemma":"rivage","pos":"NOM"} ,
		{"word":"rivages","word_nosc":"rivages","lemma":"rivage","pos":"NOM"} ,
		{"word":"rival","word_nosc":"rival","lemma":"rival","pos":"NOM"} ,
		{"word":"rivale","word_nosc":"rivale","lemma":"rival","pos":"NOM"} ,
		{"word":"rivales","word_nosc":"rivales","lemma":"rival","pos":"NOM"} ,
		{"word":"rivalité","word_nosc":"rivalite","lemma":"rivalité","pos":"NOM"} ,
		{"word":"rivalités","word_nosc":"rivalites","lemma":"rivalité","pos":"NOM"} ,
		{"word":"rivaux","word_nosc":"rivaux","lemma":"rival","pos":"NOM"} ,
		{"word":"rive","word_nosc":"rive","lemma":"rive","pos":"NOM"} ,
		{"word":"riverain","word_nosc":"riverain","lemma":"riverain","pos":"NOM"} ,
		{"word":"riverains","word_nosc":"riverains","lemma":"riverain","pos":"NOM"} ,
		{"word":"rives","word_nosc":"rives","lemma":"rive","pos":"NOM"} ,
		{"word":"rivet","word_nosc":"rivet","lemma":"rivet","pos":"NOM"} ,
		{"word":"rivetage","word_nosc":"rivetage","lemma":"rivetage","pos":"NOM"} ,
		{"word":"riveteuse","word_nosc":"riveteuse","lemma":"riveteur","pos":"NOM"} ,
		{"word":"riveteuse","word_nosc":"riveteuse","lemma":"riveteuse","pos":"NOM"} ,
		{"word":"rivets","word_nosc":"rivets","lemma":"rivet","pos":"NOM"} ,
		{"word":"riviera","word_nosc":"riviera","lemma":"riviera","pos":"NOM"} ,
		{"word":"rivieras","word_nosc":"rivieras","lemma":"riviera","pos":"NOM"} ,
		{"word":"rivière","word_nosc":"riviere","lemma":"rivière","pos":"NOM"} ,
		{"word":"rivières","word_nosc":"rivieres","lemma":"rivière","pos":"NOM"} ,
		{"word":"rivoir","word_nosc":"rivoir","lemma":"rivoir","pos":"NOM"} ,
		{"word":"rixdales","word_nosc":"rixdales","lemma":"rixdale","pos":"NOM"} ,
		{"word":"rixe","word_nosc":"rixe","lemma":"rixe","pos":"NOM"} ,
		{"word":"rixes","word_nosc":"rixes","lemma":"rixe","pos":"NOM"} ,
		{"word":"riz","word_nosc":"riz","lemma":"riz","pos":"NOM"} ,
		{"word":"riz-minute","word_nosc":"riz-minute","lemma":"riz-minute","pos":"NOM"} ,
		{"word":"riz-pain-sel","word_nosc":"riz-pain-sel","lemma":"riz-pain-sel","pos":"NOM"} ,
		{"word":"rizière","word_nosc":"riziere","lemma":"rizière","pos":"NOM"} ,
		{"word":"rizières","word_nosc":"rizieres","lemma":"rizière","pos":"NOM"} ,
		{"word":"roadster","word_nosc":"roadster","lemma":"roadster","pos":"NOM"} ,
		{"word":"roast beef","word_nosc":"roast beef","lemma":"roast-beef","pos":"NOM"} ,
		{"word":"roast-beef","word_nosc":"roast-beef","lemma":"roast-beef","pos":"NOM"} ,
		{"word":"robe","word_nosc":"robe","lemma":"robe","pos":"NOM"} ,
		{"word":"robert","word_nosc":"robert","lemma":"robert","pos":"NOM"} ,
		{"word":"roberts","word_nosc":"roberts","lemma":"robert","pos":"NOM"} ,
		{"word":"robes","word_nosc":"robes","lemma":"robe","pos":"NOM"} ,
		{"word":"robette","word_nosc":"robette","lemma":"robette","pos":"NOM"} ,
		{"word":"robin","word_nosc":"robin","lemma":"robin","pos":"NOM"} ,
		{"word":"robinet","word_nosc":"robinet","lemma":"robinet","pos":"NOM"} ,
		{"word":"robinets","word_nosc":"robinets","lemma":"robinet","pos":"NOM"} ,
		{"word":"robinetterie","word_nosc":"robinetterie","lemma":"robinetterie","pos":"NOM"} ,
		{"word":"robiniers","word_nosc":"robiniers","lemma":"robinier","pos":"NOM"} ,
		{"word":"robins","word_nosc":"robins","lemma":"robin","pos":"NOM"} ,
		{"word":"robinsonnade","word_nosc":"robinsonnade","lemma":"robinsonnade","pos":"NOM"} ,
		{"word":"robinsons","word_nosc":"robinsons","lemma":"robinson","pos":"NOM"} ,
		{"word":"roblot","word_nosc":"roblot","lemma":"roblot","pos":"NOM"} ,
		{"word":"robot","word_nosc":"robot","lemma":"robot","pos":"NOM"} ,
		{"word":"robotique","word_nosc":"robotique","lemma":"robotique","pos":"NOM"} ,
		{"word":"robotiques","word_nosc":"robotiques","lemma":"robotique","pos":"NOM"} ,
		{"word":"robots","word_nosc":"robots","lemma":"robot","pos":"NOM"} ,
		{"word":"roburite","word_nosc":"roburite","lemma":"roburite","pos":"NOM"} ,
		{"word":"robustesse","word_nosc":"robustesse","lemma":"robustesse","pos":"NOM"} ,
		{"word":"roc","word_nosc":"roc","lemma":"roc","pos":"NOM"} ,
		{"word":"rocade","word_nosc":"rocade","lemma":"rocade","pos":"NOM"} ,
		{"word":"rocades","word_nosc":"rocades","lemma":"rocade","pos":"NOM"} ,
		{"word":"rocaille","word_nosc":"rocaille","lemma":"rocaille","pos":"NOM"} ,
		{"word":"rocailles","word_nosc":"rocailles","lemma":"rocaille","pos":"NOM"} ,
		{"word":"rocambole","word_nosc":"rocambole","lemma":"rocambole","pos":"NOM"} ,
		{"word":"rochassier","word_nosc":"rochassier","lemma":"rochassier","pos":"NOM"} ,
		{"word":"roche","word_nosc":"roche","lemma":"roche","pos":"NOM"} ,
		{"word":"rochelle","word_nosc":"rochelle","lemma":"rochelle","pos":"NOM"} ,
		{"word":"rocher","word_nosc":"rocher","lemma":"rocher","pos":"NOM"} ,
		{"word":"rochers","word_nosc":"rochers","lemma":"rocher","pos":"NOM"} ,
		{"word":"roches","word_nosc":"roches","lemma":"roche","pos":"NOM"} ,
		{"word":"rochet","word_nosc":"rochet","lemma":"rochet","pos":"NOM"} ,
		{"word":"rochiers","word_nosc":"rochiers","lemma":"rochier","pos":"NOM"} ,
		{"word":"rock","word_nosc":"rock","lemma":"rock","pos":"NOM"} ,
		{"word":"rock","word_nosc":"rock","lemma":"rocke","pos":"NOM"} ,
		{"word":"rock'n'roll","word_nosc":"rock'n'roll","lemma":"rock'n'roll","pos":"NOM"} ,
		{"word":"rock-'n-roll","word_nosc":"rock-'n-roll","lemma":"rock-n-roll","pos":"NOM"} ,
		{"word":"rocker","word_nosc":"rocker","lemma":"rocker","pos":"NOM"} ,
		{"word":"rockers","word_nosc":"rockers","lemma":"rocker","pos":"NOM"} ,
		{"word":"rocket","word_nosc":"rocket","lemma":"rocket","pos":"NOM"} ,
		{"word":"rockets","word_nosc":"rockets","lemma":"rocket","pos":"NOM"} ,
		{"word":"rockeur","word_nosc":"rockeur","lemma":"rockeur","pos":"NOM"} ,
		{"word":"rockeurs","word_nosc":"rockeurs","lemma":"rockeur","pos":"NOM"} ,
		{"word":"rockeuse","word_nosc":"rockeuse","lemma":"rockeur","pos":"NOM"} ,
		{"word":"rocking chair","word_nosc":"rocking chair","lemma":"rocking-chair","pos":"NOM"} ,
		{"word":"rocking-chair","word_nosc":"rocking-chair","lemma":"rocking-chair","pos":"NOM"} ,
		{"word":"rocking-chairs","word_nosc":"rocking-chairs","lemma":"rocking-chair","pos":"NOM"} ,
		{"word":"rocks","word_nosc":"rocks","lemma":"rock","pos":"NOM"} ,
		{"word":"rococo","word_nosc":"rococo","lemma":"rococo","pos":"NOM"} ,
		{"word":"rococos","word_nosc":"rococos","lemma":"rococo","pos":"NOM"} ,
		{"word":"rocs","word_nosc":"rocs","lemma":"roc","pos":"NOM"} ,
		{"word":"rodage","word_nosc":"rodage","lemma":"rodage","pos":"NOM"} ,
		{"word":"rodages","word_nosc":"rodages","lemma":"rodage","pos":"NOM"} ,
		{"word":"rodeo","word_nosc":"rodeo","lemma":"rodeo","pos":"NOM"} ,
		{"word":"rodomontades","word_nosc":"rodomontades","lemma":"rodomontade","pos":"NOM"} ,
		{"word":"rodéo","word_nosc":"rodeo","lemma":"rodéo","pos":"NOM"} ,
		{"word":"rodéos","word_nosc":"rodeos","lemma":"rodéo","pos":"NOM"} ,
		{"word":"roentgens","word_nosc":"roentgens","lemma":"roentgen","pos":"NOM"} ,
		{"word":"rogations","word_nosc":"rogations","lemma":"rogation","pos":"NOM"} ,
		{"word":"rogaton","word_nosc":"rogaton","lemma":"rogaton","pos":"NOM"} ,
		{"word":"rogatons","word_nosc":"rogatons","lemma":"rogaton","pos":"NOM"} ,
		{"word":"rogne","word_nosc":"rogne","lemma":"rogne","pos":"NOM"} ,
		{"word":"rogne-pied","word_nosc":"rogne-pied","lemma":"rogne-pied","pos":"NOM"} ,
		{"word":"rognes","word_nosc":"rognes","lemma":"rogne","pos":"NOM"} ,
		{"word":"rognon","word_nosc":"rognon","lemma":"rognon","pos":"NOM"} ,
		{"word":"rognons","word_nosc":"rognons","lemma":"rognon","pos":"NOM"} ,
		{"word":"rognure","word_nosc":"rognure","lemma":"rognure","pos":"NOM"} ,
		{"word":"rognures","word_nosc":"rognures","lemma":"rognure","pos":"NOM"} ,
		{"word":"rogomme","word_nosc":"rogomme","lemma":"rogomme","pos":"NOM"} ,
		{"word":"rogommes","word_nosc":"rogommes","lemma":"rogomme","pos":"NOM"} ,
		{"word":"rogue","word_nosc":"rogue","lemma":"rogue","pos":"NOM"} ,
		{"word":"rogues","word_nosc":"rogues","lemma":"rogue","pos":"NOM"} ,
		{"word":"roi","word_nosc":"roi","lemma":"roi","pos":"NOM"} ,
		{"word":"roi-roi","word_nosc":"roi-roi","lemma":"roi-roi","pos":"NOM"} ,
		{"word":"roi-soleil","word_nosc":"roi-soleil","lemma":"roi-soleil","pos":"NOM"} ,
		{"word":"roideur","word_nosc":"roideur","lemma":"roideur","pos":"NOM"} ,
		{"word":"rois","word_nosc":"rois","lemma":"roi","pos":"NOM"} ,
		{"word":"roitelet","word_nosc":"roitelet","lemma":"roitelet","pos":"NOM"} ,
		{"word":"roitelets","word_nosc":"roitelets","lemma":"roitelet","pos":"NOM"} ,
		{"word":"roll","word_nosc":"roll","lemma":"roll","pos":"NOM"} ,
		{"word":"rolle","word_nosc":"rolle","lemma":"rolle","pos":"NOM"} ,
		{"word":"roller","word_nosc":"roller","lemma":"roller","pos":"NOM"} ,
		{"word":"rollers","word_nosc":"rollers","lemma":"roller","pos":"NOM"} ,
		{"word":"rollmops","word_nosc":"rollmops","lemma":"rollmops","pos":"NOM"} ,
		{"word":"rom","word_nosc":"rom","lemma":"rom","pos":"NOM"} ,
		{"word":"romain","word_nosc":"romain","lemma":"romain","pos":"NOM"} ,
		{"word":"romaine","word_nosc":"romaine","lemma":"romain","pos":"NOM"} ,
		{"word":"romaines","word_nosc":"romaines","lemma":"romain","pos":"NOM"} ,
		{"word":"romains","word_nosc":"romains","lemma":"romain","pos":"NOM"} ,
		{"word":"roman","word_nosc":"roman","lemma":"roman","pos":"NOM"} ,
		{"word":"roman-feuilleton","word_nosc":"roman-feuilleton","lemma":"roman-feuilleton","pos":"NOM"} ,
		{"word":"roman-fleuve","word_nosc":"roman-fleuve","lemma":"roman-fleuve","pos":"NOM"} ,
		{"word":"roman-photo","word_nosc":"roman-photo","lemma":"roman-photo","pos":"NOM"} ,
		{"word":"romance","word_nosc":"romance","lemma":"romance","pos":"NOM"} ,
		{"word":"romancero","word_nosc":"romancero","lemma":"romancero","pos":"NOM"} ,
		{"word":"romances","word_nosc":"romances","lemma":"romance","pos":"NOM"} ,
		{"word":"romanche","word_nosc":"romanche","lemma":"romanche","pos":"NOM"} ,
		{"word":"romancier","word_nosc":"romancier","lemma":"romancier","pos":"NOM"} ,
		{"word":"romanciers","word_nosc":"romanciers","lemma":"romancier","pos":"NOM"} ,
		{"word":"romancière","word_nosc":"romanciere","lemma":"romancier","pos":"NOM"} ,
		{"word":"romancières","word_nosc":"romancieres","lemma":"romancier","pos":"NOM"} ,
		{"word":"romani","word_nosc":"romani","lemma":"romani","pos":"NOM"} ,
		{"word":"romanichel","word_nosc":"romanichel","lemma":"romanichel","pos":"NOM"} ,
		{"word":"romanichelle","word_nosc":"romanichelle","lemma":"romanichel","pos":"NOM"} ,
		{"word":"romanichelles","word_nosc":"romanichelles","lemma":"romanichel","pos":"NOM"} ,
		{"word":"romanichels","word_nosc":"romanichels","lemma":"romanichel","pos":"NOM"} ,
		{"word":"romanisation","word_nosc":"romanisation","lemma":"romanisation","pos":"NOM"} ,
		{"word":"romanistes","word_nosc":"romanistes","lemma":"romaniste","pos":"NOM"} ,
		{"word":"romanité","word_nosc":"romanite","lemma":"romanité","pos":"NOM"} ,
		{"word":"romano","word_nosc":"romano","lemma":"romano","pos":"NOM"} ,
		{"word":"romanos","word_nosc":"romanos","lemma":"romano","pos":"NOM"} ,
		{"word":"romans","word_nosc":"romans","lemma":"roman","pos":"NOM"} ,
		{"word":"romans-feuilletons","word_nosc":"romans-feuilletons","lemma":"roman-feuilleton","pos":"NOM"} ,
		{"word":"romans-photos","word_nosc":"romans-photos","lemma":"roman-photo","pos":"NOM"} ,
		{"word":"romanticisme","word_nosc":"romanticisme","lemma":"romanticisme","pos":"NOM"} ,
		{"word":"romantique","word_nosc":"romantique","lemma":"romantique","pos":"NOM"} ,
		{"word":"romantiques","word_nosc":"romantiques","lemma":"romantique","pos":"NOM"} ,
		{"word":"romantisme","word_nosc":"romantisme","lemma":"romantisme","pos":"NOM"} ,
		{"word":"romantismes","word_nosc":"romantismes","lemma":"romantisme","pos":"NOM"} ,
		{"word":"romarin","word_nosc":"romarin","lemma":"romarin","pos":"NOM"} ,
		{"word":"rombière","word_nosc":"rombiere","lemma":"rombier","pos":"NOM"} ,
		{"word":"rombières","word_nosc":"rombieres","lemma":"rombier","pos":"NOM"} ,
		{"word":"rombières","word_nosc":"rombieres","lemma":"rombière","pos":"NOM"} ,
		{"word":"roms","word_nosc":"roms","lemma":"rom","pos":"NOM"} ,
		{"word":"ron","word_nosc":"ron","lemma":"ron","pos":"NOM"} ,
		{"word":"ronce","word_nosc":"ronce","lemma":"ronce","pos":"NOM"} ,
		{"word":"ronces","word_nosc":"ronces","lemma":"ronce","pos":"NOM"} ,
		{"word":"roncet","word_nosc":"roncet","lemma":"roncet","pos":"NOM"} ,
		{"word":"ronchon","word_nosc":"ronchon","lemma":"ronchon","pos":"NOM"} ,
		{"word":"ronchonne","word_nosc":"ronchonne","lemma":"ronchon","pos":"NOM"} ,
		{"word":"ronchonneur","word_nosc":"ronchonneur","lemma":"ronchonneur","pos":"NOM"} ,
		{"word":"ronchonneuse","word_nosc":"ronchonneuse","lemma":"ronchonneur","pos":"NOM"} ,
		{"word":"ronchonnot","word_nosc":"ronchonnot","lemma":"ronchonnot","pos":"NOM"} ,
		{"word":"roncier","word_nosc":"roncier","lemma":"roncier","pos":"NOM"} ,
		{"word":"ronciers","word_nosc":"ronciers","lemma":"roncier","pos":"NOM"} ,
		{"word":"rond","word_nosc":"rond","lemma":"rond","pos":"NOM"} ,
		{"word":"rond-de-cuir","word_nosc":"rond-de-cuir","lemma":"rond-de-cuir","pos":"NOM"} ,
		{"word":"rond-point","word_nosc":"rond-point","lemma":"rond-point","pos":"NOM"} ,
		{"word":"rondache","word_nosc":"rondache","lemma":"rondache","pos":"NOM"} ,
		{"word":"ronde","word_nosc":"ronde","lemma":"ronde","pos":"NOM"} ,
		{"word":"ronde-bosse","word_nosc":"ronde-bosse","lemma":"ronde-bosse","pos":"NOM"} ,
		{"word":"rondeau","word_nosc":"rondeau","lemma":"rondeau","pos":"NOM"} ,
		{"word":"rondelet","word_nosc":"rondelet","lemma":"rondelet","pos":"NOM"} ,
		{"word":"rondelle","word_nosc":"rondelle","lemma":"rondelle","pos":"NOM"} ,
		{"word":"rondelles","word_nosc":"rondelles","lemma":"rondelle","pos":"NOM"} ,
		{"word":"rondes","word_nosc":"rondes","lemma":"ronde","pos":"NOM"} ,
		{"word":"rondeur","word_nosc":"rondeur","lemma":"rondeur","pos":"NOM"} ,
		{"word":"rondeurs","word_nosc":"rondeurs","lemma":"rondeur","pos":"NOM"} ,
		{"word":"rondin","word_nosc":"rondin","lemma":"rondin","pos":"NOM"} ,
		{"word":"rondins","word_nosc":"rondins","lemma":"rondin","pos":"NOM"} ,
		{"word":"rondo","word_nosc":"rondo","lemma":"rondo","pos":"NOM"} ,
		{"word":"rondos","word_nosc":"rondos","lemma":"rondo","pos":"NOM"} ,
		{"word":"ronds","word_nosc":"ronds","lemma":"rond","pos":"NOM"} ,
		{"word":"ronds-de-cuir","word_nosc":"ronds-de-cuir","lemma":"rond-de-cuir","pos":"NOM"} ,
		{"word":"ronds-points","word_nosc":"ronds-points","lemma":"rond-point","pos":"NOM"} ,
		{"word":"ronflement","word_nosc":"ronflement","lemma":"ronflement","pos":"NOM"} ,
		{"word":"ronflements","word_nosc":"ronflements","lemma":"ronflement","pos":"NOM"} ,
		{"word":"ronflette","word_nosc":"ronflette","lemma":"ronflette","pos":"NOM"} ,
		{"word":"ronfleur","word_nosc":"ronfleur","lemma":"ronfleur","pos":"NOM"} ,
		{"word":"ronfleurs","word_nosc":"ronfleurs","lemma":"ronfleur","pos":"NOM"} ,
		{"word":"ronfleuse","word_nosc":"ronfleuse","lemma":"ronfleur","pos":"NOM"} ,
		{"word":"rongement","word_nosc":"rongement","lemma":"rongement","pos":"NOM"} ,
		{"word":"rongeur","word_nosc":"rongeur","lemma":"rongeur","pos":"NOM"} ,
		{"word":"rongeurs","word_nosc":"rongeurs","lemma":"rongeur","pos":"NOM"} ,
		{"word":"ronin","word_nosc":"ronin","lemma":"ronin","pos":"NOM"} ,
		{"word":"ronron","word_nosc":"ronron","lemma":"ronron","pos":"NOM"} ,
		{"word":"ronronnement","word_nosc":"ronronnement","lemma":"ronronnement","pos":"NOM"} ,
		{"word":"ronronnements","word_nosc":"ronronnements","lemma":"ronronnement","pos":"NOM"} ,
		{"word":"ronrons","word_nosc":"ronrons","lemma":"ronron","pos":"NOM"} ,
		{"word":"ronéo","word_nosc":"roneo","lemma":"ronéo","pos":"NOM"} ,
		{"word":"roof","word_nosc":"roof","lemma":"roof","pos":"NOM"} ,
		{"word":"roploplos","word_nosc":"roploplos","lemma":"roploplo","pos":"NOM"} ,
		{"word":"roque","word_nosc":"roque","lemma":"roque","pos":"NOM"} ,
		{"word":"roquefort","word_nosc":"roquefort","lemma":"roquefort","pos":"NOM"} ,
		{"word":"roquentin","word_nosc":"roquentin","lemma":"roquentin","pos":"NOM"} ,
		{"word":"roques","word_nosc":"roques","lemma":"roque","pos":"NOM"} ,
		{"word":"roquet","word_nosc":"roquet","lemma":"roquet","pos":"NOM"} ,
		{"word":"roquets","word_nosc":"roquets","lemma":"roquet","pos":"NOM"} ,
		{"word":"roquette","word_nosc":"roquette","lemma":"roquette","pos":"NOM"} ,
		{"word":"roquettes","word_nosc":"roquettes","lemma":"roquette","pos":"NOM"} ,
		{"word":"rorqual","word_nosc":"rorqual","lemma":"rorqual","pos":"NOM"} ,
		{"word":"rorquals","word_nosc":"rorquals","lemma":"rorqual","pos":"NOM"} ,
		{"word":"rosace","word_nosc":"rosace","lemma":"rosace","pos":"NOM"} ,
		{"word":"rosaces","word_nosc":"rosaces","lemma":"rosace","pos":"NOM"} ,
		{"word":"rosages","word_nosc":"rosages","lemma":"rosage","pos":"NOM"} ,
		{"word":"rosaire","word_nosc":"rosaire","lemma":"rosaire","pos":"NOM"} ,
		{"word":"rosaires","word_nosc":"rosaires","lemma":"rosaire","pos":"NOM"} ,
		{"word":"rosales","word_nosc":"rosales","lemma":"rosales","pos":"NOM"} ,
		{"word":"rosbif","word_nosc":"rosbif","lemma":"rosbif","pos":"NOM"} ,
		{"word":"rosbifs","word_nosc":"rosbifs","lemma":"rosbif","pos":"NOM"} ,
		{"word":"rose","word_nosc":"rose","lemma":"rose","pos":"NOM"} ,
		{"word":"rose-croix","word_nosc":"rose-croix","lemma":"rose-croix","pos":"NOM"} ,
		{"word":"rose-thé","word_nosc":"rose-the","lemma":"rose-thé","pos":"NOM"} ,
		{"word":"roseau","word_nosc":"roseau","lemma":"roseau","pos":"NOM"} ,
		{"word":"roseaux","word_nosc":"roseaux","lemma":"roseau","pos":"NOM"} ,
		{"word":"roselière","word_nosc":"roseliere","lemma":"roselier","pos":"NOM"} ,
		{"word":"roselières","word_nosc":"roselieres","lemma":"roselier","pos":"NOM"} ,
		{"word":"roseraie","word_nosc":"roseraie","lemma":"roseraie","pos":"NOM"} ,
		{"word":"roseraies","word_nosc":"roseraies","lemma":"roseraie","pos":"NOM"} ,
		{"word":"roses","word_nosc":"roses","lemma":"rose","pos":"NOM"} ,
		{"word":"rosette","word_nosc":"rosette","lemma":"rosette","pos":"NOM"} ,
		{"word":"rosettes","word_nosc":"rosettes","lemma":"rosette","pos":"NOM"} ,
		{"word":"roseur","word_nosc":"roseur","lemma":"roseur","pos":"NOM"} ,
		{"word":"roseurs","word_nosc":"roseurs","lemma":"roseur","pos":"NOM"} ,
		{"word":"rosicrucienne","word_nosc":"rosicrucienne","lemma":"rosicrucien","pos":"NOM"} ,
		{"word":"rosicruciens","word_nosc":"rosicruciens","lemma":"rosicrucien","pos":"NOM"} ,
		{"word":"rosier","word_nosc":"rosier","lemma":"rosier","pos":"NOM"} ,
		{"word":"rosiers","word_nosc":"rosiers","lemma":"rosier","pos":"NOM"} ,
		{"word":"rosière","word_nosc":"rosiere","lemma":"rosière","pos":"NOM"} ,
		{"word":"rossard","word_nosc":"rossard","lemma":"rossard","pos":"NOM"} ,
		{"word":"rossards","word_nosc":"rossards","lemma":"rossard","pos":"NOM"} ,
		{"word":"rosse","word_nosc":"rosse","lemma":"rosse","pos":"NOM"} ,
		{"word":"rosserie","word_nosc":"rosserie","lemma":"rosserie","pos":"NOM"} ,
		{"word":"rosseries","word_nosc":"rosseries","lemma":"rosserie","pos":"NOM"} ,
		{"word":"rosses","word_nosc":"rosses","lemma":"rosse","pos":"NOM"} ,
		{"word":"rossignol","word_nosc":"rossignol","lemma":"rossignol","pos":"NOM"} ,
		{"word":"rossignols","word_nosc":"rossignols","lemma":"rossignol","pos":"NOM"} ,
		{"word":"rossinante","word_nosc":"rossinante","lemma":"rossinante","pos":"NOM"} ,
		{"word":"rossinantes","word_nosc":"rossinantes","lemma":"rossinante","pos":"NOM"} ,
		{"word":"rossée","word_nosc":"rossee","lemma":"rossée","pos":"NOM"} ,
		{"word":"rostre","word_nosc":"rostre","lemma":"rostre","pos":"NOM"} ,
		{"word":"rostres","word_nosc":"rostres","lemma":"rostre","pos":"NOM"} ,
		{"word":"rosé","word_nosc":"rose","lemma":"rosé","pos":"NOM"} ,
		{"word":"rosée","word_nosc":"rosee","lemma":"rosée","pos":"NOM"} ,
		{"word":"rosées","word_nosc":"rosees","lemma":"rosée","pos":"NOM"} ,
		{"word":"roséole","word_nosc":"roseole","lemma":"roséole","pos":"NOM"} ,
		{"word":"rosés","word_nosc":"roses","lemma":"rosé","pos":"NOM"} ,
		{"word":"rot","word_nosc":"rot","lemma":"rot","pos":"NOM"} ,
		{"word":"rotary","word_nosc":"rotary","lemma":"rotary","pos":"NOM"} ,
		{"word":"rotation","word_nosc":"rotation","lemma":"rotation","pos":"NOM"} ,
		{"word":"rotations","word_nosc":"rotations","lemma":"rotation","pos":"NOM"} ,
		{"word":"rotative","word_nosc":"rotative","lemma":"rotative","pos":"NOM"} ,
		{"word":"rotatives","word_nosc":"rotatives","lemma":"rotative","pos":"NOM"} ,
		{"word":"rotativistes","word_nosc":"rotativistes","lemma":"rotativiste","pos":"NOM"} ,
		{"word":"rote","word_nosc":"rote","lemma":"rote","pos":"NOM"} ,
		{"word":"rotengles","word_nosc":"rotengles","lemma":"rotengle","pos":"NOM"} ,
		{"word":"roteur","word_nosc":"roteur","lemma":"roteur","pos":"NOM"} ,
		{"word":"roteuse","word_nosc":"roteuse","lemma":"roteur","pos":"NOM"} ,
		{"word":"roteuses","word_nosc":"roteuses","lemma":"roteur","pos":"NOM"} ,
		{"word":"rotin","word_nosc":"rotin","lemma":"rotin","pos":"NOM"} ,
		{"word":"roto","word_nosc":"roto","lemma":"roto","pos":"NOM"} ,
		{"word":"rotonde","word_nosc":"rotonde","lemma":"rotond","pos":"NOM"} ,
		{"word":"rotonde","word_nosc":"rotonde","lemma":"rotonde","pos":"NOM"} ,
		{"word":"rotondes","word_nosc":"rotondes","lemma":"rotond","pos":"NOM"} ,
		{"word":"rotondité","word_nosc":"rotondite","lemma":"rotondité","pos":"NOM"} ,
		{"word":"rotondités","word_nosc":"rotondites","lemma":"rotondité","pos":"NOM"} ,
		{"word":"rotoplos","word_nosc":"rotoplos","lemma":"rotoplos","pos":"NOM"} ,
		{"word":"rotoplots","word_nosc":"rotoplots","lemma":"rotoplots","pos":"NOM"} ,
		{"word":"rotor","word_nosc":"rotor","lemma":"rotor","pos":"NOM"} ,
		{"word":"rotors","word_nosc":"rotors","lemma":"rotor","pos":"NOM"} ,
		{"word":"rotos","word_nosc":"rotos","lemma":"roto","pos":"NOM"} ,
		{"word":"rototo","word_nosc":"rototo","lemma":"rototo","pos":"NOM"} ,
		{"word":"rototos","word_nosc":"rototos","lemma":"rototo","pos":"NOM"} ,
		{"word":"rots","word_nosc":"rots","lemma":"rot","pos":"NOM"} ,
		{"word":"rotule","word_nosc":"rotule","lemma":"rotule","pos":"NOM"} ,
		{"word":"rotules","word_nosc":"rotules","lemma":"rotule","pos":"NOM"} ,
		{"word":"roture","word_nosc":"roture","lemma":"roture","pos":"NOM"} ,
		{"word":"roturier","word_nosc":"roturier","lemma":"roturier","pos":"NOM"} ,
		{"word":"roturiers","word_nosc":"roturiers","lemma":"roturier","pos":"NOM"} ,
		{"word":"roturière","word_nosc":"roturiere","lemma":"roturier","pos":"NOM"} ,
		{"word":"roténone","word_nosc":"rotenone","lemma":"roténone","pos":"NOM"} ,
		{"word":"rouage","word_nosc":"rouage","lemma":"rouage","pos":"NOM"} ,
		{"word":"rouages","word_nosc":"rouages","lemma":"rouage","pos":"NOM"} ,
		{"word":"rouan","word_nosc":"rouan","lemma":"rouan","pos":"NOM"} ,
		{"word":"roubaisienne","word_nosc":"roubaisienne","lemma":"roubaisien","pos":"NOM"} ,
		{"word":"roubignoles","word_nosc":"roubignoles","lemma":"roubignoles","pos":"NOM"} ,
		{"word":"roublard","word_nosc":"roublard","lemma":"roublard","pos":"NOM"} ,
		{"word":"roublarde","word_nosc":"roublarde","lemma":"roublard","pos":"NOM"} ,
		{"word":"roublardise","word_nosc":"roublardise","lemma":"roublardise","pos":"NOM"} ,
		{"word":"roublardises","word_nosc":"roublardises","lemma":"roublardise","pos":"NOM"} ,
		{"word":"rouble","word_nosc":"rouble","lemma":"rouble","pos":"NOM"} ,
		{"word":"roubles","word_nosc":"roubles","lemma":"rouble","pos":"NOM"} ,
		{"word":"roucoulade","word_nosc":"roucoulade","lemma":"roucoulade","pos":"NOM"} ,
		{"word":"roucoulades","word_nosc":"roucoulades","lemma":"roucoulade","pos":"NOM"} ,
		{"word":"roucoulement","word_nosc":"roucoulement","lemma":"roucoulement","pos":"NOM"} ,
		{"word":"roucoulements","word_nosc":"roucoulements","lemma":"roucoulement","pos":"NOM"} ,
		{"word":"roucouleur","word_nosc":"roucouleur","lemma":"roucouleur","pos":"NOM"} ,
		{"word":"roudoudou","word_nosc":"roudoudou","lemma":"roudoudou","pos":"NOM"} ,
		{"word":"roudoudous","word_nosc":"roudoudous","lemma":"roudoudou","pos":"NOM"} ,
		{"word":"roue","word_nosc":"roue","lemma":"roue","pos":"NOM"} ,
		{"word":"rouelle","word_nosc":"rouelle","lemma":"rouelle","pos":"NOM"} ,
		{"word":"rouennaise","word_nosc":"rouennaise","lemma":"rouennais","pos":"NOM"} ,
		{"word":"rouenneries","word_nosc":"rouenneries","lemma":"rouennerie","pos":"NOM"} ,
		{"word":"rouerie","word_nosc":"rouerie","lemma":"rouerie","pos":"NOM"} ,
		{"word":"roueries","word_nosc":"roueries","lemma":"rouerie","pos":"NOM"} ,
		{"word":"roues","word_nosc":"roues","lemma":"roue","pos":"NOM"} ,
		{"word":"rouet","word_nosc":"rouet","lemma":"rouet","pos":"NOM"} ,
		{"word":"rouf","word_nosc":"rouf","lemma":"rouf","pos":"NOM"} ,
		{"word":"rouflaquettes","word_nosc":"rouflaquettes","lemma":"rouflaquette","pos":"NOM"} ,
		{"word":"rouge","word_nosc":"rouge","lemma":"rouge","pos":"NOM"} ,
		{"word":"rouge-gorge","word_nosc":"rouge-gorge","lemma":"rouge-gorge","pos":"NOM"} ,
		{"word":"rouge-queue","word_nosc":"rouge-queue","lemma":"rouge-queue","pos":"NOM"} ,
		{"word":"rouge-sang","word_nosc":"rouge-sang","lemma":"rouge-sang","pos":"NOM"} ,
		{"word":"rougeaud","word_nosc":"rougeaud","lemma":"rougeaud","pos":"NOM"} ,
		{"word":"rougeaude","word_nosc":"rougeaude","lemma":"rougeaud","pos":"NOM"} ,
		{"word":"rougeoiement","word_nosc":"rougeoiement","lemma":"rougeoiement","pos":"NOM"} ,
		{"word":"rougeoiements","word_nosc":"rougeoiements","lemma":"rougeoiement","pos":"NOM"} ,
		{"word":"rougeole","word_nosc":"rougeole","lemma":"rougeole","pos":"NOM"} ,
		{"word":"rouges","word_nosc":"rouges","lemma":"rouge","pos":"NOM"} ,
		{"word":"rouges-gorges","word_nosc":"rouges-gorges","lemma":"rouge-gorge","pos":"NOM"} ,
		{"word":"rouget","word_nosc":"rouget","lemma":"rouget","pos":"NOM"} ,
		{"word":"rougets","word_nosc":"rougets","lemma":"rouget","pos":"NOM"} ,
		{"word":"rougeur","word_nosc":"rougeur","lemma":"rougeur","pos":"NOM"} ,
		{"word":"rougeurs","word_nosc":"rougeurs","lemma":"rougeur","pos":"NOM"} ,
		{"word":"rough","word_nosc":"rough","lemma":"rough","pos":"NOM"} ,
		{"word":"rougissement","word_nosc":"rougissement","lemma":"rougissement","pos":"NOM"} ,
		{"word":"rougissements","word_nosc":"rougissements","lemma":"rougissement","pos":"NOM"} ,
		{"word":"rouillarde","word_nosc":"rouillarde","lemma":"rouillarde","pos":"NOM"} ,
		{"word":"rouille","word_nosc":"rouille","lemma":"rouille","pos":"NOM"} ,
		{"word":"rouilles","word_nosc":"rouilles","lemma":"rouille","pos":"NOM"} ,
		{"word":"rouillures","word_nosc":"rouillures","lemma":"rouillure","pos":"NOM"} ,
		{"word":"roulade","word_nosc":"roulade","lemma":"roulade","pos":"NOM"} ,
		{"word":"roulades","word_nosc":"roulades","lemma":"roulade","pos":"NOM"} ,
		{"word":"roulage","word_nosc":"roulage","lemma":"roulage","pos":"NOM"} ,
		{"word":"roulages","word_nosc":"roulages","lemma":"roulage","pos":"NOM"} ,
		{"word":"roulante","word_nosc":"roulante","lemma":"roulante","pos":"NOM"} ,
		{"word":"roulantes","word_nosc":"roulantes","lemma":"roulante","pos":"NOM"} ,
		{"word":"roule","word_nosc":"roule","lemma":"roule","pos":"NOM"} ,
		{"word":"rouleau","word_nosc":"rouleau","lemma":"rouleau","pos":"NOM"} ,
		{"word":"rouleaux","word_nosc":"rouleaux","lemma":"rouleau","pos":"NOM"} ,
		{"word":"roulement","word_nosc":"roulement","lemma":"roulement","pos":"NOM"} ,
		{"word":"roulements","word_nosc":"roulements","lemma":"roulement","pos":"NOM"} ,
		{"word":"roules","word_nosc":"roules","lemma":"roule","pos":"NOM"} ,
		{"word":"roulette","word_nosc":"roulette","lemma":"roulette","pos":"NOM"} ,
		{"word":"roulettes","word_nosc":"roulettes","lemma":"roulette","pos":"NOM"} ,
		{"word":"rouleur","word_nosc":"rouleur","lemma":"rouleur","pos":"NOM"} ,
		{"word":"rouleurs","word_nosc":"rouleurs","lemma":"rouleur","pos":"NOM"} ,
		{"word":"roulier","word_nosc":"roulier","lemma":"roulier","pos":"NOM"} ,
		{"word":"rouliers","word_nosc":"rouliers","lemma":"roulier","pos":"NOM"} ,
		{"word":"roulis","word_nosc":"roulis","lemma":"roulis","pos":"NOM"} ,
		{"word":"roulotte","word_nosc":"roulotte","lemma":"roulotte","pos":"NOM"} ,
		{"word":"roulottes","word_nosc":"roulottes","lemma":"roulotte","pos":"NOM"} ,
		{"word":"roulottiers","word_nosc":"roulottiers","lemma":"roulottier","pos":"NOM"} ,
		{"word":"roulottière","word_nosc":"roulottiere","lemma":"roulottier","pos":"NOM"} ,
		{"word":"roulure","word_nosc":"roulure","lemma":"roulure","pos":"NOM"} ,
		{"word":"roulures","word_nosc":"roulures","lemma":"roulure","pos":"NOM"} ,
		{"word":"roulé-boulé","word_nosc":"roule-boule","lemma":"roulé-boulé","pos":"NOM"} ,
		{"word":"roulés-boulés","word_nosc":"roules-boules","lemma":"roulé-boulé","pos":"NOM"} ,
		{"word":"roumain","word_nosc":"roumain","lemma":"roumain","pos":"NOM"} ,
		{"word":"roumaine","word_nosc":"roumaine","lemma":"roumain","pos":"NOM"} ,
		{"word":"roumaines","word_nosc":"roumaines","lemma":"roumain","pos":"NOM"} ,
		{"word":"roumains","word_nosc":"roumains","lemma":"roumain","pos":"NOM"} ,
		{"word":"roumi","word_nosc":"roumi","lemma":"roumi","pos":"NOM"} ,
		{"word":"roumis","word_nosc":"roumis","lemma":"roumi","pos":"NOM"} ,
		{"word":"round","word_nosc":"round","lemma":"round","pos":"NOM"} ,
		{"word":"rounds","word_nosc":"rounds","lemma":"round","pos":"NOM"} ,
		{"word":"roupane","word_nosc":"roupane","lemma":"roupane","pos":"NOM"} ,
		{"word":"roupette","word_nosc":"roupette","lemma":"roupette","pos":"NOM"} ,
		{"word":"roupettes","word_nosc":"roupettes","lemma":"roupettes","pos":"NOM"} ,
		{"word":"roupie","word_nosc":"roupie","lemma":"roupie","pos":"NOM"} ,
		{"word":"roupies","word_nosc":"roupies","lemma":"roupie","pos":"NOM"} ,
		{"word":"roupillon","word_nosc":"roupillon","lemma":"roupillon","pos":"NOM"} ,
		{"word":"roupillons","word_nosc":"roupillons","lemma":"roupillon","pos":"NOM"} ,
		{"word":"rouquemoute","word_nosc":"rouquemoute","lemma":"rouquemoute","pos":"NOM"} ,
		{"word":"rouquette","word_nosc":"rouquette","lemma":"rouquette","pos":"NOM"} ,
		{"word":"rouquin","word_nosc":"rouquin","lemma":"rouquin","pos":"NOM"} ,
		{"word":"rouquine","word_nosc":"rouquine","lemma":"rouquin","pos":"NOM"} ,
		{"word":"rouquines","word_nosc":"rouquines","lemma":"rouquin","pos":"NOM"} ,
		{"word":"rouquins","word_nosc":"rouquins","lemma":"rouquin","pos":"NOM"} ,
		{"word":"rouspétage","word_nosc":"rouspetage","lemma":"rouspétage","pos":"NOM"} ,
		{"word":"rouspétance","word_nosc":"rouspetance","lemma":"rouspétance","pos":"NOM"} ,
		{"word":"rouspéteur","word_nosc":"rouspeteur","lemma":"rouspéteur","pos":"NOM"} ,
		{"word":"rouspéteurs","word_nosc":"rouspeteurs","lemma":"rouspéteur","pos":"NOM"} ,
		{"word":"rousse","word_nosc":"rousse","lemma":"roux","pos":"NOM"} ,
		{"word":"rousseau","word_nosc":"rousseau","lemma":"rousseau","pos":"NOM"} ,
		{"word":"rousseaux","word_nosc":"rousseaux","lemma":"rousseau","pos":"NOM"} ,
		{"word":"rousserolle","word_nosc":"rousserolle","lemma":"rousserolle","pos":"NOM"} ,
		{"word":"rousses","word_nosc":"rousses","lemma":"roux","pos":"NOM"} ,
		{"word":"roussette","word_nosc":"roussette","lemma":"roussette","pos":"NOM"} ,
		{"word":"roussettes","word_nosc":"roussettes","lemma":"roussette","pos":"NOM"} ,
		{"word":"rousseur","word_nosc":"rousseur","lemma":"rousseur","pos":"NOM"} ,
		{"word":"rousseurs","word_nosc":"rousseurs","lemma":"rousseur","pos":"NOM"} ,
		{"word":"roussi","word_nosc":"roussi","lemma":"roussi","pos":"NOM"} ,
		{"word":"roussin","word_nosc":"roussin","lemma":"roussin","pos":"NOM"} ,
		{"word":"roussins","word_nosc":"roussins","lemma":"roussin","pos":"NOM"} ,
		{"word":"roussis","word_nosc":"roussis","lemma":"roussi","pos":"NOM"} ,
		{"word":"roussissures","word_nosc":"roussissures","lemma":"roussissure","pos":"NOM"} ,
		{"word":"rouste","word_nosc":"rouste","lemma":"rouste","pos":"NOM"} ,
		{"word":"roustes","word_nosc":"roustes","lemma":"rouste","pos":"NOM"} ,
		{"word":"rouston","word_nosc":"rouston","lemma":"rouston","pos":"NOM"} ,
		{"word":"roustons","word_nosc":"roustons","lemma":"roustons","pos":"NOM"} ,
		{"word":"routage","word_nosc":"routage","lemma":"routage","pos":"NOM"} ,
		{"word":"routard","word_nosc":"routard","lemma":"routard","pos":"NOM"} ,
		{"word":"routards","word_nosc":"routards","lemma":"routard","pos":"NOM"} ,
		{"word":"route","word_nosc":"route","lemma":"route","pos":"NOM"} ,
		{"word":"routes","word_nosc":"routes","lemma":"route","pos":"NOM"} ,
		{"word":"routeur","word_nosc":"routeur","lemma":"routeur","pos":"NOM"} ,
		{"word":"routeurs","word_nosc":"routeurs","lemma":"routeur","pos":"NOM"} ,
		{"word":"routier","word_nosc":"routier","lemma":"routier","pos":"NOM"} ,
		{"word":"routiers","word_nosc":"routiers","lemma":"routier","pos":"NOM"} ,
		{"word":"routin","word_nosc":"routin","lemma":"routin","pos":"NOM"} ,
		{"word":"routine","word_nosc":"routine","lemma":"routine","pos":"NOM"} ,
		{"word":"routines","word_nosc":"routines","lemma":"routine","pos":"NOM"} ,
		{"word":"routière","word_nosc":"routiere","lemma":"routier","pos":"NOM"} ,
		{"word":"rouvre","word_nosc":"rouvre","lemma":"rouvre","pos":"NOM"} ,
		{"word":"rouvres","word_nosc":"rouvres","lemma":"rouvre","pos":"NOM"} ,
		{"word":"roux","word_nosc":"roux","lemma":"roux","pos":"NOM"} ,
		{"word":"roy","word_nosc":"roy","lemma":"roy","pos":"NOM"} ,
		{"word":"royale","word_nosc":"royale","lemma":"royale","pos":"NOM"} ,
		{"word":"royales","word_nosc":"royales","lemma":"royal","pos":"NOM"} ,
		{"word":"royaliste","word_nosc":"royaliste","lemma":"royaliste","pos":"NOM"} ,
		{"word":"royalistes","word_nosc":"royalistes","lemma":"royaliste","pos":"NOM"} ,
		{"word":"royalties","word_nosc":"royalties","lemma":"royalties","pos":"NOM"} ,
		{"word":"royalty","word_nosc":"royalty","lemma":"royalty","pos":"NOM"} ,
		{"word":"royaume","word_nosc":"royaume","lemma":"royaume","pos":"NOM"} ,
		{"word":"royaumes","word_nosc":"royaumes","lemma":"royaume","pos":"NOM"} ,
		{"word":"royauté","word_nosc":"royaute","lemma":"royauté","pos":"NOM"} ,
		{"word":"royautés","word_nosc":"royautes","lemma":"royauté","pos":"NOM"} ,
		{"word":"ru","word_nosc":"ru","lemma":"ru","pos":"NOM"} ,
		{"word":"ruade","word_nosc":"ruade","lemma":"ruade","pos":"NOM"} ,
		{"word":"ruades","word_nosc":"ruades","lemma":"ruade","pos":"NOM"} ,
		{"word":"ruban","word_nosc":"ruban","lemma":"ruban","pos":"NOM"} ,
		{"word":"rubans","word_nosc":"rubans","lemma":"ruban","pos":"NOM"} ,
		{"word":"rubia","word_nosc":"rubia","lemma":"rubia","pos":"NOM"} ,
		{"word":"rubis","word_nosc":"rubis","lemma":"rubis","pos":"NOM"} ,
		{"word":"rubricard","word_nosc":"rubricard","lemma":"rubricard","pos":"NOM"} ,
		{"word":"rubrique","word_nosc":"rubrique","lemma":"rubrique","pos":"NOM"} ,
		{"word":"rubriques","word_nosc":"rubriques","lemma":"rubrique","pos":"NOM"} ,
		{"word":"rubéole","word_nosc":"rubeole","lemma":"rubéole","pos":"NOM"} ,
		{"word":"ruche","word_nosc":"ruche","lemma":"ruche","pos":"NOM"} ,
		{"word":"rucher","word_nosc":"rucher","lemma":"rucher","pos":"NOM"} ,
		{"word":"ruchers","word_nosc":"ruchers","lemma":"rucher","pos":"NOM"} ,
		{"word":"ruches","word_nosc":"ruches","lemma":"ruche","pos":"NOM"} ,
		{"word":"ruché","word_nosc":"ruche","lemma":"ruché","pos":"NOM"} ,
		{"word":"ruchée","word_nosc":"ruchee","lemma":"ruché","pos":"NOM"} ,
		{"word":"rucksack","word_nosc":"rucksack","lemma":"rucksack","pos":"NOM"} ,
		{"word":"rudesse","word_nosc":"rudesse","lemma":"rudesse","pos":"NOM"} ,
		{"word":"rudesses","word_nosc":"rudesses","lemma":"rudesse","pos":"NOM"} ,
		{"word":"rudiment","word_nosc":"rudiment","lemma":"rudiment","pos":"NOM"} ,
		{"word":"rudiments","word_nosc":"rudiments","lemma":"rudiment","pos":"NOM"} ,
		{"word":"rudoiement","word_nosc":"rudoiement","lemma":"rudoiement","pos":"NOM"} ,
		{"word":"rudoiements","word_nosc":"rudoiements","lemma":"rudoiement","pos":"NOM"} ,
		{"word":"rue","word_nosc":"rue","lemma":"rue","pos":"NOM"} ,
		{"word":"ruelle","word_nosc":"ruelle","lemma":"ruelle","pos":"NOM"} ,
		{"word":"ruelles","word_nosc":"ruelles","lemma":"ruelle","pos":"NOM"} ,
		{"word":"rues","word_nosc":"rues","lemma":"rue","pos":"NOM"} ,
		{"word":"ruffian","word_nosc":"ruffian","lemma":"ruffian","pos":"NOM"} ,
		{"word":"ruffians","word_nosc":"ruffians","lemma":"ruffian","pos":"NOM"} ,
		{"word":"rufian","word_nosc":"rufian","lemma":"rufian","pos":"NOM"} ,
		{"word":"rugby","word_nosc":"rugby","lemma":"rugby","pos":"NOM"} ,
		{"word":"rugbyman","word_nosc":"rugbyman","lemma":"rugbyman","pos":"NOM"} ,
		{"word":"rugbymen","word_nosc":"rugbymen","lemma":"rugbyman","pos":"NOM"} ,
		{"word":"rugissement","word_nosc":"rugissement","lemma":"rugissement","pos":"NOM"} ,
		{"word":"rugissements","word_nosc":"rugissements","lemma":"rugissement","pos":"NOM"} ,
		{"word":"rugosité","word_nosc":"rugosite","lemma":"rugosité","pos":"NOM"} ,
		{"word":"rugosités","word_nosc":"rugosites","lemma":"rugosité","pos":"NOM"} ,
		{"word":"ruine","word_nosc":"ruine","lemma":"ruine","pos":"NOM"} ,
		{"word":"ruines","word_nosc":"ruines","lemma":"ruine","pos":"NOM"} ,
		{"word":"ruisseau","word_nosc":"ruisseau","lemma":"ruisseau","pos":"NOM"} ,
		{"word":"ruisseaux","word_nosc":"ruisseaux","lemma":"ruisseau","pos":"NOM"} ,
		{"word":"ruisselet","word_nosc":"ruisselet","lemma":"ruisselet","pos":"NOM"} ,
		{"word":"ruisselets","word_nosc":"ruisselets","lemma":"ruisselet","pos":"NOM"} ,
		{"word":"ruissellement","word_nosc":"ruissellement","lemma":"ruissellement","pos":"NOM"} ,
		{"word":"ruissellements","word_nosc":"ruissellements","lemma":"ruissellement","pos":"NOM"} ,
		{"word":"ruisson","word_nosc":"ruisson","lemma":"ruisson","pos":"NOM"} ,
		{"word":"rumba","word_nosc":"rumba","lemma":"rumba","pos":"NOM"} ,
		{"word":"rumbas","word_nosc":"rumbas","lemma":"rumba","pos":"NOM"} ,
		{"word":"rumeur","word_nosc":"rumeur","lemma":"rumeur","pos":"NOM"} ,
		{"word":"rumeurs","word_nosc":"rumeurs","lemma":"rumeur","pos":"NOM"} ,
		{"word":"ruminant","word_nosc":"ruminant","lemma":"ruminant","pos":"NOM"} ,
		{"word":"ruminants","word_nosc":"ruminants","lemma":"ruminant","pos":"NOM"} ,
		{"word":"rumination","word_nosc":"rumination","lemma":"rumination","pos":"NOM"} ,
		{"word":"ruminations","word_nosc":"ruminations","lemma":"rumination","pos":"NOM"} ,
		{"word":"ruminement","word_nosc":"ruminement","lemma":"ruminement","pos":"NOM"} ,
		{"word":"ruminements","word_nosc":"ruminements","lemma":"ruminement","pos":"NOM"} ,
		{"word":"rumsteck","word_nosc":"rumsteck","lemma":"rumsteck","pos":"NOM"} ,
		{"word":"rune","word_nosc":"rune","lemma":"rune","pos":"NOM"} ,
		{"word":"runes","word_nosc":"runes","lemma":"rune","pos":"NOM"} ,
		{"word":"running","word_nosc":"running","lemma":"running","pos":"NOM"} ,
		{"word":"rupin","word_nosc":"rupin","lemma":"rupin","pos":"NOM"} ,
		{"word":"rupines","word_nosc":"rupines","lemma":"rupin","pos":"NOM"} ,
		{"word":"rupins","word_nosc":"rupins","lemma":"rupin","pos":"NOM"} ,
		{"word":"rupteur","word_nosc":"rupteur","lemma":"rupteur","pos":"NOM"} ,
		{"word":"rupture","word_nosc":"rupture","lemma":"rupture","pos":"NOM"} ,
		{"word":"ruptures","word_nosc":"ruptures","lemma":"rupture","pos":"NOM"} ,
		{"word":"rural","word_nosc":"rural","lemma":"rural","pos":"NOM"} ,
		{"word":"rurale","word_nosc":"rurale","lemma":"rural","pos":"NOM"} ,
		{"word":"rurales","word_nosc":"rurales","lemma":"rural","pos":"NOM"} ,
		{"word":"rus","word_nosc":"rus","lemma":"ru","pos":"NOM"} ,
		{"word":"ruse","word_nosc":"ruse","lemma":"ruse","pos":"NOM"} ,
		{"word":"ruses","word_nosc":"ruses","lemma":"ruse","pos":"NOM"} ,
		{"word":"rush","word_nosc":"rush","lemma":"rush","pos":"NOM"} ,
		{"word":"rushes","word_nosc":"rushes","lemma":"rush","pos":"NOM"} ,
		{"word":"russe","word_nosc":"russe","lemma":"russe","pos":"NOM"} ,
		{"word":"russes","word_nosc":"russes","lemma":"russe","pos":"NOM"} ,
		{"word":"russification","word_nosc":"russification","lemma":"russification","pos":"NOM"} ,
		{"word":"russules","word_nosc":"russules","lemma":"russule","pos":"NOM"} ,
		{"word":"rustaud","word_nosc":"rustaud","lemma":"rustaud","pos":"NOM"} ,
		{"word":"rustaudes","word_nosc":"rustaudes","lemma":"rustaud","pos":"NOM"} ,
		{"word":"rustauds","word_nosc":"rustauds","lemma":"rustaud","pos":"NOM"} ,
		{"word":"rusticité","word_nosc":"rusticite","lemma":"rusticité","pos":"NOM"} ,
		{"word":"rustine","word_nosc":"rustine","lemma":"rustine","pos":"NOM"} ,
		{"word":"rustines","word_nosc":"rustines","lemma":"rustine","pos":"NOM"} ,
		{"word":"rustre","word_nosc":"rustre","lemma":"rustre","pos":"NOM"} ,
		{"word":"rustres","word_nosc":"rustres","lemma":"rustre","pos":"NOM"} ,
		{"word":"rusé","word_nosc":"ruse","lemma":"rusé","pos":"NOM"} ,
		{"word":"rusée","word_nosc":"rusee","lemma":"rusé","pos":"NOM"} ,
		{"word":"rusées","word_nosc":"rusees","lemma":"rusé","pos":"NOM"} ,
		{"word":"rusés","word_nosc":"ruses","lemma":"rusé","pos":"NOM"} ,
		{"word":"rut","word_nosc":"rut","lemma":"rut","pos":"NOM"} ,
		{"word":"rutabaga","word_nosc":"rutabaga","lemma":"rutabaga","pos":"NOM"} ,
		{"word":"rutabagas","word_nosc":"rutabagas","lemma":"rutabaga","pos":"NOM"} ,
		{"word":"rutherford","word_nosc":"rutherford","lemma":"rutherford","pos":"NOM"} ,
		{"word":"rutilances","word_nosc":"rutilances","lemma":"rutilance","pos":"NOM"} ,
		{"word":"ruée","word_nosc":"ruee","lemma":"ruée","pos":"NOM"} ,
		{"word":"ruées","word_nosc":"ruees","lemma":"ruée","pos":"NOM"} ,
		{"word":"rythme","word_nosc":"rythme","lemma":"rythme","pos":"NOM"} ,
		{"word":"rythmes","word_nosc":"rythmes","lemma":"rythme","pos":"NOM"} ,
		{"word":"rythmique","word_nosc":"rythmique","lemma":"rythmique","pos":"NOM"} ,
		{"word":"rythmiques","word_nosc":"rythmiques","lemma":"rythmique","pos":"NOM"} ,
		{"word":"râble","word_nosc":"rable","lemma":"râble","pos":"NOM"} ,
		{"word":"râle","word_nosc":"rale","lemma":"râle","pos":"NOM"} ,
		{"word":"râlement","word_nosc":"ralement","lemma":"râlement","pos":"NOM"} ,
		{"word":"râles","word_nosc":"rales","lemma":"râle","pos":"NOM"} ,
		{"word":"râleur","word_nosc":"raleur","lemma":"râleur","pos":"NOM"} ,
		{"word":"râleurs","word_nosc":"raleurs","lemma":"râleur","pos":"NOM"} ,
		{"word":"râleuse","word_nosc":"raleuse","lemma":"râleur","pos":"NOM"} ,
		{"word":"râleuses","word_nosc":"raleuses","lemma":"râleur","pos":"NOM"} ,
		{"word":"râleux","word_nosc":"raleux","lemma":"râleux","pos":"NOM"} ,
		{"word":"râpe","word_nosc":"rape","lemma":"râpe","pos":"NOM"} ,
		{"word":"râpes","word_nosc":"rapes","lemma":"râpe","pos":"NOM"} ,
		{"word":"râpé","word_nosc":"rape","lemma":"râpé","pos":"NOM"} ,
		{"word":"râteau","word_nosc":"rateau","lemma":"râteau","pos":"NOM"} ,
		{"word":"râteaux","word_nosc":"rateaux","lemma":"râteau","pos":"NOM"} ,
		{"word":"râtelier","word_nosc":"ratelier","lemma":"râtelier","pos":"NOM"} ,
		{"word":"râteliers","word_nosc":"rateliers","lemma":"râtelier","pos":"NOM"} ,
		{"word":"règle","word_nosc":"regle","lemma":"règle","pos":"NOM"} ,
		{"word":"règlement","word_nosc":"reglement","lemma":"règlement","pos":"NOM"} ,
		{"word":"règlements","word_nosc":"reglements","lemma":"règlement","pos":"NOM"} ,
		{"word":"règles","word_nosc":"regles","lemma":"règle","pos":"NOM"} ,
		{"word":"règne","word_nosc":"regne","lemma":"règne","pos":"NOM"} ,
		{"word":"règnes","word_nosc":"regnes","lemma":"règne","pos":"NOM"} ,
		{"word":"ré","word_nosc":"re","lemma":"ré","pos":"NOM"} ,
		{"word":"réa","word_nosc":"rea","lemma":"réa","pos":"NOM"} ,
		{"word":"réabonnement","word_nosc":"reabonnement","lemma":"réabonnement","pos":"NOM"} ,
		{"word":"réabsorption","word_nosc":"reabsorption","lemma":"réabsorption","pos":"NOM"} ,
		{"word":"réactant","word_nosc":"reactant","lemma":"réactant","pos":"NOM"} ,
		{"word":"réacteur","word_nosc":"reacteur","lemma":"réacteur","pos":"NOM"} ,
		{"word":"réacteurs","word_nosc":"reacteurs","lemma":"réacteur","pos":"NOM"} ,
		{"word":"réactif","word_nosc":"reactif","lemma":"réactif","pos":"NOM"} ,
		{"word":"réactifs","word_nosc":"reactifs","lemma":"réactif","pos":"NOM"} ,
		{"word":"réaction","word_nosc":"reaction","lemma":"réaction","pos":"NOM"} ,
		{"word":"réactionnaire","word_nosc":"reactionnaire","lemma":"réactionnaire","pos":"NOM"} ,
		{"word":"réactionnaires","word_nosc":"reactionnaires","lemma":"réactionnaire","pos":"NOM"} ,
		{"word":"réactions","word_nosc":"reactions","lemma":"réaction","pos":"NOM"} ,
		{"word":"réactivation","word_nosc":"reactivation","lemma":"réactivation","pos":"NOM"} ,
		{"word":"réactivité","word_nosc":"reactivite","lemma":"réactivité","pos":"NOM"} ,
		{"word":"réactualisation","word_nosc":"reactualisation","lemma":"réactualisation","pos":"NOM"} ,
		{"word":"réadaptation","word_nosc":"readaptation","lemma":"réadaptation","pos":"NOM"} ,
		{"word":"réadmission","word_nosc":"readmission","lemma":"réadmission","pos":"NOM"} ,
		{"word":"réaffectation","word_nosc":"reaffectation","lemma":"réaffectation","pos":"NOM"} ,
		{"word":"réaffirmation","word_nosc":"reaffirmation","lemma":"réaffirmation","pos":"NOM"} ,
		{"word":"réagencement","word_nosc":"reagencement","lemma":"réagencement","pos":"NOM"} ,
		{"word":"réajustement","word_nosc":"reajustement","lemma":"réajustement","pos":"NOM"} ,
		{"word":"réajustements","word_nosc":"reajustements","lemma":"réajustement","pos":"NOM"} ,
		{"word":"réal","word_nosc":"real","lemma":"réal","pos":"NOM"} ,
		{"word":"réalignement","word_nosc":"realignement","lemma":"réalignement","pos":"NOM"} ,
		{"word":"réalisateur","word_nosc":"realisateur","lemma":"réalisateur","pos":"NOM"} ,
		{"word":"réalisateurs","word_nosc":"realisateurs","lemma":"réalisateur","pos":"NOM"} ,
		{"word":"réalisation","word_nosc":"realisation","lemma":"réalisation","pos":"NOM"} ,
		{"word":"réalisations","word_nosc":"realisations","lemma":"réalisation","pos":"NOM"} ,
		{"word":"réalisatrice","word_nosc":"realisatrice","lemma":"réalisateur","pos":"NOM"} ,
		{"word":"réalisatrices","word_nosc":"realisatrices","lemma":"réalisateur","pos":"NOM"} ,
		{"word":"réalisme","word_nosc":"realisme","lemma":"réalisme","pos":"NOM"} ,
		{"word":"réaliste","word_nosc":"realiste","lemma":"réaliste","pos":"NOM"} ,
		{"word":"réalistes","word_nosc":"realistes","lemma":"réaliste","pos":"NOM"} ,
		{"word":"réalité","word_nosc":"realite","lemma":"réalité","pos":"NOM"} ,
		{"word":"réalités","word_nosc":"realites","lemma":"réalité","pos":"NOM"} ,
		{"word":"réamorçage","word_nosc":"reamorcage","lemma":"réamorçage","pos":"NOM"} ,
		{"word":"réaménagement","word_nosc":"reamenagement","lemma":"réaménagement","pos":"NOM"} ,
		{"word":"réaménagements","word_nosc":"reamenagements","lemma":"réaménagement","pos":"NOM"} ,
		{"word":"réanimateur","word_nosc":"reanimateur","lemma":"réanimateur","pos":"NOM"} ,
		{"word":"réanimation","word_nosc":"reanimation","lemma":"réanimation","pos":"NOM"} ,
		{"word":"réapparition","word_nosc":"reapparition","lemma":"réapparition","pos":"NOM"} ,
		{"word":"réapparitions","word_nosc":"reapparitions","lemma":"réapparition","pos":"NOM"} ,
		{"word":"réapprovisionnement","word_nosc":"reapprovisionnement","lemma":"réapprovisionnement","pos":"NOM"} ,
		{"word":"réarmement","word_nosc":"rearmement","lemma":"réarmement","pos":"NOM"} ,
		{"word":"réarrangement","word_nosc":"rearrangement","lemma":"réarrangement","pos":"NOM"} ,
		{"word":"réassignation","word_nosc":"reassignation","lemma":"réassignation","pos":"NOM"} ,
		{"word":"rébecca","word_nosc":"rebecca","lemma":"rébecca","pos":"NOM"} ,
		{"word":"rébellion","word_nosc":"rebellion","lemma":"rébellion","pos":"NOM"} ,
		{"word":"rébellions","word_nosc":"rebellions","lemma":"rébellion","pos":"NOM"} ,
		{"word":"rébus","word_nosc":"rebus","lemma":"rébus","pos":"NOM"} ,
		{"word":"récalcitrant","word_nosc":"recalcitrant","lemma":"récalcitrant","pos":"NOM"} ,
		{"word":"récalcitrantes","word_nosc":"recalcitrantes","lemma":"récalcitrant","pos":"NOM"} ,
		{"word":"récalcitrants","word_nosc":"recalcitrants","lemma":"récalcitrant","pos":"NOM"} ,
		{"word":"récapitulatif","word_nosc":"recapitulatif","lemma":"récapitulatif","pos":"NOM"} ,
		{"word":"récapitulation","word_nosc":"recapitulation","lemma":"récapitulation","pos":"NOM"} ,
		{"word":"réceptacle","word_nosc":"receptacle","lemma":"réceptacle","pos":"NOM"} ,
		{"word":"réceptacles","word_nosc":"receptacles","lemma":"réceptacle","pos":"NOM"} ,
		{"word":"récepteur","word_nosc":"recepteur","lemma":"récepteur","pos":"NOM"} ,
		{"word":"récepteurs","word_nosc":"recepteurs","lemma":"récepteur","pos":"NOM"} ,
		{"word":"réception","word_nosc":"reception","lemma":"réception","pos":"NOM"} ,
		{"word":"réceptionniste","word_nosc":"receptionniste","lemma":"réceptionniste","pos":"NOM"} ,
		{"word":"réceptionnistes","word_nosc":"receptionnistes","lemma":"réceptionniste","pos":"NOM"} ,
		{"word":"réceptions","word_nosc":"receptions","lemma":"réception","pos":"NOM"} ,
		{"word":"réceptivité","word_nosc":"receptivite","lemma":"réceptivité","pos":"NOM"} ,
		{"word":"récession","word_nosc":"recession","lemma":"récession","pos":"NOM"} ,
		{"word":"réchappé","word_nosc":"rechappe","lemma":"réchappé","pos":"NOM"} ,
		{"word":"réchappés","word_nosc":"rechappes","lemma":"réchappé","pos":"NOM"} ,
		{"word":"réchaud","word_nosc":"rechaud","lemma":"réchaud","pos":"NOM"} ,
		{"word":"réchauds","word_nosc":"rechauds","lemma":"réchaud","pos":"NOM"} ,
		{"word":"réchauffage","word_nosc":"rechauffage","lemma":"réchauffage","pos":"NOM"} ,
		{"word":"réchauffement","word_nosc":"rechauffement","lemma":"réchauffement","pos":"NOM"} ,
		{"word":"réchauffeur","word_nosc":"rechauffeur","lemma":"réchauffeur","pos":"NOM"} ,
		{"word":"récidive","word_nosc":"recidive","lemma":"récidive","pos":"NOM"} ,
		{"word":"récidives","word_nosc":"recidives","lemma":"récidive","pos":"NOM"} ,
		{"word":"récidiviste","word_nosc":"recidiviste","lemma":"récidiviste","pos":"NOM"} ,
		{"word":"récidivistes","word_nosc":"recidivistes","lemma":"récidiviste","pos":"NOM"} ,
		{"word":"récif","word_nosc":"recif","lemma":"récif","pos":"NOM"} ,
		{"word":"récifs","word_nosc":"recifs","lemma":"récif","pos":"NOM"} ,
		{"word":"récipiendaire","word_nosc":"recipiendaire","lemma":"récipiendaire","pos":"NOM"} ,
		{"word":"récipiendaires","word_nosc":"recipiendaires","lemma":"récipiendaire","pos":"NOM"} ,
		{"word":"récipient","word_nosc":"recipient","lemma":"récipient","pos":"NOM"} ,
		{"word":"récipients","word_nosc":"recipients","lemma":"récipient","pos":"NOM"} ,
		{"word":"réciprocité","word_nosc":"reciprocite","lemma":"réciprocité","pos":"NOM"} ,
		{"word":"réciproque","word_nosc":"reciproque","lemma":"réciproque","pos":"NOM"} ,
		{"word":"récit","word_nosc":"recit","lemma":"récit","pos":"NOM"} ,
		{"word":"récital","word_nosc":"recital","lemma":"récital","pos":"NOM"} ,
		{"word":"récitals","word_nosc":"recitals","lemma":"récital","pos":"NOM"} ,
		{"word":"récitant","word_nosc":"recitant","lemma":"récitant","pos":"NOM"} ,
		{"word":"récitante","word_nosc":"recitante","lemma":"récitant","pos":"NOM"} ,
		{"word":"récitants","word_nosc":"recitants","lemma":"récitant","pos":"NOM"} ,
		{"word":"récitatif","word_nosc":"recitatif","lemma":"récitatif","pos":"NOM"} ,
		{"word":"récitatifs","word_nosc":"recitatifs","lemma":"récitatif","pos":"NOM"} ,
		{"word":"récitation","word_nosc":"recitation","lemma":"récitation","pos":"NOM"} ,
		{"word":"récitations","word_nosc":"recitations","lemma":"récitation","pos":"NOM"} ,
		{"word":"récits","word_nosc":"recits","lemma":"récit","pos":"NOM"} ,
		{"word":"réclamantes","word_nosc":"reclamantes","lemma":"réclamant","pos":"NOM"} ,
		{"word":"réclamation","word_nosc":"reclamation","lemma":"réclamation","pos":"NOM"} ,
		{"word":"réclamations","word_nosc":"reclamations","lemma":"réclamation","pos":"NOM"} ,
		{"word":"réclame","word_nosc":"reclame","lemma":"réclame","pos":"NOM"} ,
		{"word":"réclames","word_nosc":"reclames","lemma":"réclame","pos":"NOM"} ,
		{"word":"réclusion","word_nosc":"reclusion","lemma":"réclusion","pos":"NOM"} ,
		{"word":"réclusionnaires","word_nosc":"reclusionnaires","lemma":"réclusionnaire","pos":"NOM"} ,
		{"word":"réclusions","word_nosc":"reclusions","lemma":"réclusion","pos":"NOM"} ,
		{"word":"récollet","word_nosc":"recollet","lemma":"récollet","pos":"NOM"} ,
		{"word":"récollets","word_nosc":"recollets","lemma":"récollet","pos":"NOM"} ,
		{"word":"récoltant","word_nosc":"recoltant","lemma":"récoltant","pos":"NOM"} ,
		{"word":"récolte","word_nosc":"recolte","lemma":"récolte","pos":"NOM"} ,
		{"word":"récoltes","word_nosc":"recoltes","lemma":"récolte","pos":"NOM"} ,
		{"word":"récolteurs","word_nosc":"recolteurs","lemma":"récolteur","pos":"NOM"} ,
		{"word":"récolteuse","word_nosc":"recolteuse","lemma":"récolteur","pos":"NOM"} ,
		{"word":"récompense","word_nosc":"recompense","lemma":"récompense","pos":"NOM"} ,
		{"word":"récompenses","word_nosc":"recompenses","lemma":"récompense","pos":"NOM"} ,
		{"word":"réconciliateur","word_nosc":"reconciliateur","lemma":"réconciliateur","pos":"NOM"} ,
		{"word":"réconciliation","word_nosc":"reconciliation","lemma":"réconciliation","pos":"NOM"} ,
		{"word":"réconciliations","word_nosc":"reconciliations","lemma":"réconciliation","pos":"NOM"} ,
		{"word":"réconfort","word_nosc":"reconfort","lemma":"réconfort","pos":"NOM"} ,
		{"word":"réconforts","word_nosc":"reconforts","lemma":"réconfort","pos":"NOM"} ,
		{"word":"récri","word_nosc":"recri","lemma":"récri","pos":"NOM"} ,
		{"word":"récrimination","word_nosc":"recrimination","lemma":"récrimination","pos":"NOM"} ,
		{"word":"récriminations","word_nosc":"recriminations","lemma":"récrimination","pos":"NOM"} ,
		{"word":"récris","word_nosc":"recris","lemma":"récri","pos":"NOM"} ,
		{"word":"récré","word_nosc":"recre","lemma":"récré","pos":"NOM"} ,
		{"word":"récréation","word_nosc":"recreation","lemma":"récréation","pos":"NOM"} ,
		{"word":"récréations","word_nosc":"recreations","lemma":"récréation","pos":"NOM"} ,
		{"word":"récrés","word_nosc":"recres","lemma":"récré","pos":"NOM"} ,
		{"word":"récup","word_nosc":"recup","lemma":"récup","pos":"NOM"} ,
		{"word":"récupe","word_nosc":"recupe","lemma":"récup","pos":"NOM"} ,
		{"word":"récupérateur","word_nosc":"recuperateur","lemma":"récupérateur","pos":"NOM"} ,
		{"word":"récupérateurs","word_nosc":"recuperateurs","lemma":"récupérateur","pos":"NOM"} ,
		{"word":"récupération","word_nosc":"recuperation","lemma":"récupération","pos":"NOM"} ,
		{"word":"récupérations","word_nosc":"recuperations","lemma":"récupération","pos":"NOM"} ,
		{"word":"récurage","word_nosc":"recurage","lemma":"récurage","pos":"NOM"} ,
		{"word":"récurrence","word_nosc":"recurrence","lemma":"récurrence","pos":"NOM"} ,
		{"word":"récurrences","word_nosc":"recurrences","lemma":"récurrence","pos":"NOM"} ,
		{"word":"récusation","word_nosc":"recusation","lemma":"récusation","pos":"NOM"} ,
		{"word":"récusations","word_nosc":"recusations","lemma":"récusation","pos":"NOM"} ,
		{"word":"récépissé","word_nosc":"recepisse","lemma":"récépissé","pos":"NOM"} ,
		{"word":"rédacteur","word_nosc":"redacteur","lemma":"rédacteur","pos":"NOM"} ,
		{"word":"rédacteurs","word_nosc":"redacteurs","lemma":"rédacteur","pos":"NOM"} ,
		{"word":"rédaction","word_nosc":"redaction","lemma":"rédaction","pos":"NOM"} ,
		{"word":"rédactions","word_nosc":"redactions","lemma":"rédaction","pos":"NOM"} ,
		{"word":"rédactrice","word_nosc":"redactrice","lemma":"rédacteur","pos":"NOM"} ,
		{"word":"rédactrices","word_nosc":"redactrices","lemma":"rédacteur","pos":"NOM"} ,
		{"word":"rédempteur","word_nosc":"redempteur","lemma":"rédempteur","pos":"NOM"} ,
		{"word":"rédemption","word_nosc":"redemption","lemma":"rédemption","pos":"NOM"} ,
		{"word":"rédemptions","word_nosc":"redemptions","lemma":"rédemption","pos":"NOM"} ,
		{"word":"rédemptoriste","word_nosc":"redemptoriste","lemma":"rédemptoriste","pos":"NOM"} ,
		{"word":"rédemptrice","word_nosc":"redemptrice","lemma":"rédempteur","pos":"NOM"} ,
		{"word":"rédhibition","word_nosc":"redhibition","lemma":"rédhibition","pos":"NOM"} ,
		{"word":"réducteur","word_nosc":"reducteur","lemma":"réducteur","pos":"NOM"} ,
		{"word":"réducteurs","word_nosc":"reducteurs","lemma":"réducteur","pos":"NOM"} ,
		{"word":"réduction","word_nosc":"reduction","lemma":"réduction","pos":"NOM"} ,
		{"word":"réductionnisme","word_nosc":"reductionnisme","lemma":"réductionnisme","pos":"NOM"} ,
		{"word":"réductions","word_nosc":"reductions","lemma":"réduction","pos":"NOM"} ,
		{"word":"réduit","word_nosc":"reduit","lemma":"réduit","pos":"NOM"} ,
		{"word":"réduits","word_nosc":"reduits","lemma":"réduit","pos":"NOM"} ,
		{"word":"réduplication","word_nosc":"reduplication","lemma":"réduplication","pos":"NOM"} ,
		{"word":"réel","word_nosc":"reel","lemma":"réel","pos":"NOM"} ,
		{"word":"réels","word_nosc":"reels","lemma":"réel","pos":"NOM"} ,
		{"word":"réembarquement","word_nosc":"reembarquement","lemma":"réembarquement","pos":"NOM"} ,
		{"word":"réemploi","word_nosc":"reemploi","lemma":"réemploi","pos":"NOM"} ,
		{"word":"réengagement","word_nosc":"reengagement","lemma":"réengagement","pos":"NOM"} ,
		{"word":"réexamen","word_nosc":"reexamen","lemma":"réexamen","pos":"NOM"} ,
		{"word":"réexpédition","word_nosc":"reexpedition","lemma":"réexpédition","pos":"NOM"} ,
		{"word":"réf","word_nosc":"ref","lemma":"réf","pos":"NOM"} ,
		{"word":"réfection","word_nosc":"refection","lemma":"réfection","pos":"NOM"} ,
		{"word":"réfections","word_nosc":"refections","lemma":"réfection","pos":"NOM"} ,
		{"word":"réfectoire","word_nosc":"refectoire","lemma":"réfectoire","pos":"NOM"} ,
		{"word":"réfectoires","word_nosc":"refectoires","lemma":"réfectoire","pos":"NOM"} ,
		{"word":"réflecteur","word_nosc":"reflecteur","lemma":"réflecteur","pos":"NOM"} ,
		{"word":"réflecteurs","word_nosc":"reflecteurs","lemma":"réflecteur","pos":"NOM"} ,
		{"word":"réflexe","word_nosc":"reflexe","lemma":"réflexe","pos":"NOM"} ,
		{"word":"réflexes","word_nosc":"reflexes","lemma":"réflexe","pos":"NOM"} ,
		{"word":"réflexion","word_nosc":"reflexion","lemma":"réflexion","pos":"NOM"} ,
		{"word":"réflexions","word_nosc":"reflexions","lemma":"réflexion","pos":"NOM"} ,
		{"word":"réflexologie","word_nosc":"reflexologie","lemma":"réflexologie","pos":"NOM"} ,
		{"word":"réformateur","word_nosc":"reformateur","lemma":"réformateur","pos":"NOM"} ,
		{"word":"réformateurs","word_nosc":"reformateurs","lemma":"réformateur","pos":"NOM"} ,
		{"word":"réformation","word_nosc":"reformation","lemma":"réformation","pos":"NOM"} ,
		{"word":"réforme","word_nosc":"reforme","lemma":"réforme","pos":"NOM"} ,
		{"word":"réformes","word_nosc":"reformes","lemma":"réforme","pos":"NOM"} ,
		{"word":"réformisme","word_nosc":"reformisme","lemma":"réformisme","pos":"NOM"} ,
		{"word":"réformé","word_nosc":"reforme","lemma":"réformé","pos":"NOM"} ,
		{"word":"réformés","word_nosc":"reformes","lemma":"réformé","pos":"NOM"} ,
		{"word":"réfractaire","word_nosc":"refractaire","lemma":"réfractaire","pos":"NOM"} ,
		{"word":"réfractaires","word_nosc":"refractaires","lemma":"réfractaire","pos":"NOM"} ,
		{"word":"réfraction","word_nosc":"refraction","lemma":"réfraction","pos":"NOM"} ,
		{"word":"réfractionniste","word_nosc":"refractionniste","lemma":"réfractionniste","pos":"NOM"} ,
		{"word":"réfrigérant","word_nosc":"refrigerant","lemma":"réfrigérant","pos":"NOM"} ,
		{"word":"réfrigérants","word_nosc":"refrigerants","lemma":"réfrigérant","pos":"NOM"} ,
		{"word":"réfrigérateur","word_nosc":"refrigerateur","lemma":"réfrigérateur","pos":"NOM"} ,
		{"word":"réfrigérateurs","word_nosc":"refrigerateurs","lemma":"réfrigérateur","pos":"NOM"} ,
		{"word":"réfrigération","word_nosc":"refrigeration","lemma":"réfrigération","pos":"NOM"} ,
		{"word":"réfugié","word_nosc":"refugie","lemma":"réfugié","pos":"NOM"} ,
		{"word":"réfugiée","word_nosc":"refugiee","lemma":"réfugié","pos":"NOM"} ,
		{"word":"réfugiées","word_nosc":"refugiees","lemma":"réfugié","pos":"NOM"} ,
		{"word":"réfugiés","word_nosc":"refugies","lemma":"réfugié","pos":"NOM"} ,
		{"word":"réfutation","word_nosc":"refutation","lemma":"réfutation","pos":"NOM"} ,
		{"word":"réfutations","word_nosc":"refutations","lemma":"réfutation","pos":"NOM"} ,
		{"word":"référence","word_nosc":"reference","lemma":"référence","pos":"NOM"} ,
		{"word":"référencement","word_nosc":"referencement","lemma":"référencement","pos":"NOM"} ,
		{"word":"références","word_nosc":"references","lemma":"référence","pos":"NOM"} ,
		{"word":"référendum","word_nosc":"referendum","lemma":"référendum","pos":"NOM"} ,
		{"word":"référendums","word_nosc":"referendums","lemma":"référendum","pos":"NOM"} ,
		{"word":"référent","word_nosc":"referent","lemma":"référent","pos":"NOM"} ,
		{"word":"référé","word_nosc":"refere","lemma":"référé","pos":"NOM"} ,
		{"word":"référés","word_nosc":"referes","lemma":"référé","pos":"NOM"} ,
		{"word":"régal","word_nosc":"regal","lemma":"régal","pos":"NOM"} ,
		{"word":"régalade","word_nosc":"regalade","lemma":"régalade","pos":"NOM"} ,
		{"word":"régale","word_nosc":"regale","lemma":"régale","pos":"NOM"} ,
		{"word":"régaleur","word_nosc":"regaleur","lemma":"régaleur","pos":"NOM"} ,
		{"word":"régals","word_nosc":"regals","lemma":"régal","pos":"NOM"} ,
		{"word":"régate","word_nosc":"regate","lemma":"régate","pos":"NOM"} ,
		{"word":"régates","word_nosc":"regates","lemma":"régate","pos":"NOM"} ,
		{"word":"régence","word_nosc":"regence","lemma":"régence","pos":"NOM"} ,
		{"word":"régent","word_nosc":"regent","lemma":"régent","pos":"NOM"} ,
		{"word":"régente","word_nosc":"regente","lemma":"régent","pos":"NOM"} ,
		{"word":"régentes","word_nosc":"regentes","lemma":"régent","pos":"NOM"} ,
		{"word":"régicide","word_nosc":"regicide","lemma":"régicide","pos":"NOM"} ,
		{"word":"régicides","word_nosc":"regicides","lemma":"régicide","pos":"NOM"} ,
		{"word":"régie","word_nosc":"regie","lemma":"régie","pos":"NOM"} ,
		{"word":"régies","word_nosc":"regies","lemma":"régie","pos":"NOM"} ,
		{"word":"régime","word_nosc":"regime","lemma":"régime","pos":"NOM"} ,
		{"word":"régiment","word_nosc":"regiment","lemma":"régiment","pos":"NOM"} ,
		{"word":"régiments","word_nosc":"regiments","lemma":"régiment","pos":"NOM"} ,
		{"word":"régimes","word_nosc":"regimes","lemma":"régime","pos":"NOM"} ,
		{"word":"région","word_nosc":"region","lemma":"région","pos":"NOM"} ,
		{"word":"régional","word_nosc":"regional","lemma":"régional","pos":"NOM"} ,
		{"word":"régionale","word_nosc":"regionale","lemma":"régional","pos":"NOM"} ,
		{"word":"régionales","word_nosc":"regionales","lemma":"régional","pos":"NOM"} ,
		{"word":"régionalisme","word_nosc":"regionalisme","lemma":"régionalisme","pos":"NOM"} ,
		{"word":"régionalistes","word_nosc":"regionalistes","lemma":"régionaliste","pos":"NOM"} ,
		{"word":"régionaux","word_nosc":"regionaux","lemma":"régional","pos":"NOM"} ,
		{"word":"régions","word_nosc":"regions","lemma":"région","pos":"NOM"} ,
		{"word":"régisseur","word_nosc":"regisseur","lemma":"régisseur","pos":"NOM"} ,
		{"word":"régisseurs","word_nosc":"regisseurs","lemma":"régisseur","pos":"NOM"} ,
		{"word":"régisseuse","word_nosc":"regisseuse","lemma":"régisseur","pos":"NOM"} ,
		{"word":"réglage","word_nosc":"reglage","lemma":"réglage","pos":"NOM"} ,
		{"word":"réglages","word_nosc":"reglages","lemma":"réglage","pos":"NOM"} ,
		{"word":"réglementation","word_nosc":"reglementation","lemma":"réglementation","pos":"NOM"} ,
		{"word":"réglementations","word_nosc":"reglementations","lemma":"réglementation","pos":"NOM"} ,
		{"word":"réglette","word_nosc":"reglette","lemma":"réglette","pos":"NOM"} ,
		{"word":"régleur","word_nosc":"regleur","lemma":"régleur","pos":"NOM"} ,
		{"word":"réglisse","word_nosc":"reglisse","lemma":"réglisse","pos":"NOM"} ,
		{"word":"réglisses","word_nosc":"reglisses","lemma":"réglisse","pos":"NOM"} ,
		{"word":"réglure","word_nosc":"reglure","lemma":"réglure","pos":"NOM"} ,
		{"word":"régression","word_nosc":"regression","lemma":"régression","pos":"NOM"} ,
		{"word":"régressions","word_nosc":"regressions","lemma":"régression","pos":"NOM"} ,
		{"word":"régularisation","word_nosc":"regularisation","lemma":"régularisation","pos":"NOM"} ,
		{"word":"régularisations","word_nosc":"regularisations","lemma":"régularisation","pos":"NOM"} ,
		{"word":"régularité","word_nosc":"regularite","lemma":"régularité","pos":"NOM"} ,
		{"word":"régulateur","word_nosc":"regulateur","lemma":"régulateur","pos":"NOM"} ,
		{"word":"régulateurs","word_nosc":"regulateurs","lemma":"régulateur","pos":"NOM"} ,
		{"word":"régulation","word_nosc":"regulation","lemma":"régulation","pos":"NOM"} ,
		{"word":"régulatrices","word_nosc":"regulatrices","lemma":"régulatrice","pos":"NOM"} ,
		{"word":"régule","word_nosc":"regule","lemma":"régule","pos":"NOM"} ,
		{"word":"régulier","word_nosc":"regulier","lemma":"régulier","pos":"NOM"} ,
		{"word":"réguliers","word_nosc":"reguliers","lemma":"régulier","pos":"NOM"} ,
		{"word":"régulière","word_nosc":"reguliere","lemma":"régulier","pos":"NOM"} ,
		{"word":"régulières","word_nosc":"regulieres","lemma":"régulier","pos":"NOM"} ,
		{"word":"régurgitation","word_nosc":"regurgitation","lemma":"régurgitation","pos":"NOM"} ,
		{"word":"régurgitations","word_nosc":"regurgitations","lemma":"régurgitation","pos":"NOM"} ,
		{"word":"régénérateur","word_nosc":"regenerateur","lemma":"régénérateur","pos":"NOM"} ,
		{"word":"régénérateurs","word_nosc":"regenerateurs","lemma":"régénérateur","pos":"NOM"} ,
		{"word":"régénération","word_nosc":"regeneration","lemma":"régénération","pos":"NOM"} ,
		{"word":"régénérescence","word_nosc":"regenerescence","lemma":"régénérescence","pos":"NOM"} ,
		{"word":"réhabilitation","word_nosc":"rehabilitation","lemma":"réhabilitation","pos":"NOM"} ,
		{"word":"réhabilité","word_nosc":"rehabilite","lemma":"réhabilité","pos":"NOM"} ,
		{"word":"réhabilités","word_nosc":"rehabilites","lemma":"réhabilité","pos":"NOM"} ,
		{"word":"réhydratation","word_nosc":"rehydratation","lemma":"réhydratation","pos":"NOM"} ,
		{"word":"réimplantation","word_nosc":"reimplantation","lemma":"réimplantation","pos":"NOM"} ,
		{"word":"réimpression","word_nosc":"reimpression","lemma":"réimpression","pos":"NOM"} ,
		{"word":"réincarnation","word_nosc":"reincarnation","lemma":"réincarnation","pos":"NOM"} ,
		{"word":"réincarnations","word_nosc":"reincarnations","lemma":"réincarnation","pos":"NOM"} ,
		{"word":"réinitialisation","word_nosc":"reinitialisation","lemma":"réinitialisation","pos":"NOM"} ,
		{"word":"réinjection","word_nosc":"reinjection","lemma":"réinjection","pos":"NOM"} ,
		{"word":"réinsertion","word_nosc":"reinsertion","lemma":"réinsertion","pos":"NOM"} ,
		{"word":"réinstallation","word_nosc":"reinstallation","lemma":"réinstallation","pos":"NOM"} ,
		{"word":"réinterprétation","word_nosc":"reinterpretation","lemma":"réinterprétation","pos":"NOM"} ,
		{"word":"réintégration","word_nosc":"reintegration","lemma":"réintégration","pos":"NOM"} ,
		{"word":"réintégrations","word_nosc":"reintegrations","lemma":"réintégration","pos":"NOM"} ,
		{"word":"réinvention","word_nosc":"reinvention","lemma":"réinvention","pos":"NOM"} ,
		{"word":"réinvestissement","word_nosc":"reinvestissement","lemma":"réinvestissement","pos":"NOM"} ,
		{"word":"réitération","word_nosc":"reiteration","lemma":"réitération","pos":"NOM"} ,
		{"word":"réitérations","word_nosc":"reiterations","lemma":"réitération","pos":"NOM"} ,
		{"word":"réjouissance","word_nosc":"rejouissance","lemma":"réjouissance","pos":"NOM"} ,
		{"word":"réjouissances","word_nosc":"rejouissances","lemma":"réjouissance","pos":"NOM"} ,
		{"word":"rémanence","word_nosc":"remanence","lemma":"rémanence","pos":"NOM"} ,
		{"word":"rémiges","word_nosc":"remiges","lemma":"rémige","pos":"NOM"} ,
		{"word":"réminiscence","word_nosc":"reminiscence","lemma":"réminiscence","pos":"NOM"} ,
		{"word":"réminiscences","word_nosc":"reminiscences","lemma":"réminiscence","pos":"NOM"} ,
		{"word":"rémission","word_nosc":"remission","lemma":"rémission","pos":"NOM"} ,
		{"word":"rémissions","word_nosc":"remissions","lemma":"rémission","pos":"NOM"} ,
		{"word":"rémiz","word_nosc":"remiz","lemma":"rémiz","pos":"NOM"} ,
		{"word":"rémora","word_nosc":"remora","lemma":"rémora","pos":"NOM"} ,
		{"word":"rémoulade","word_nosc":"remoulade","lemma":"rémoulade","pos":"NOM"} ,
		{"word":"rémouleur","word_nosc":"remouleur","lemma":"rémouleur","pos":"NOM"} ,
		{"word":"rémouleurs","word_nosc":"remouleurs","lemma":"rémouleur","pos":"NOM"} ,
		{"word":"rémunération","word_nosc":"remuneration","lemma":"rémunération","pos":"NOM"} ,
		{"word":"rémunérations","word_nosc":"remunerations","lemma":"rémunération","pos":"NOM"} ,
		{"word":"rénettes","word_nosc":"renettes","lemma":"rénette","pos":"NOM"} ,
		{"word":"rénine","word_nosc":"renine","lemma":"rénine","pos":"NOM"} ,
		{"word":"rénovateur","word_nosc":"renovateur","lemma":"rénovateur","pos":"NOM"} ,
		{"word":"rénovateurs","word_nosc":"renovateurs","lemma":"rénovateur","pos":"NOM"} ,
		{"word":"rénovation","word_nosc":"renovation","lemma":"rénovation","pos":"NOM"} ,
		{"word":"rénovations","word_nosc":"renovations","lemma":"rénovation","pos":"NOM"} ,
		{"word":"réorganisation","word_nosc":"reorganisation","lemma":"réorganisation","pos":"NOM"} ,
		{"word":"réorganisations","word_nosc":"reorganisations","lemma":"réorganisation","pos":"NOM"} ,
		{"word":"réorientation","word_nosc":"reorientation","lemma":"réorientation","pos":"NOM"} ,
		{"word":"réouverture","word_nosc":"reouverture","lemma":"réouverture","pos":"NOM"} ,
		{"word":"réouvertures","word_nosc":"reouvertures","lemma":"réouverture","pos":"NOM"} ,
		{"word":"répandeur","word_nosc":"repandeur","lemma":"répandeur","pos":"NOM"} ,
		{"word":"réparateur","word_nosc":"reparateur","lemma":"réparateur","pos":"NOM"} ,
		{"word":"réparateurs","word_nosc":"reparateurs","lemma":"réparateur","pos":"NOM"} ,
		{"word":"réparation","word_nosc":"reparation","lemma":"réparation","pos":"NOM"} ,
		{"word":"réparations","word_nosc":"reparations","lemma":"réparation","pos":"NOM"} ,
		{"word":"réparatrice","word_nosc":"reparatrice","lemma":"réparateur","pos":"NOM"} ,
		{"word":"répartie","word_nosc":"repartie","lemma":"répartie","pos":"NOM"} ,
		{"word":"réparties","word_nosc":"reparties","lemma":"répartie","pos":"NOM"} ,
		{"word":"répartiteur","word_nosc":"repartiteur","lemma":"répartiteur","pos":"NOM"} ,
		{"word":"répartition","word_nosc":"repartition","lemma":"répartition","pos":"NOM"} ,
		{"word":"répartitions","word_nosc":"repartitions","lemma":"répartition","pos":"NOM"} ,
		{"word":"répercussion","word_nosc":"repercussion","lemma":"répercussion","pos":"NOM"} ,
		{"word":"répercussions","word_nosc":"repercussions","lemma":"répercussion","pos":"NOM"} ,
		{"word":"répertoire","word_nosc":"repertoire","lemma":"répertoire","pos":"NOM"} ,
		{"word":"répertoires","word_nosc":"repertoires","lemma":"répertoire","pos":"NOM"} ,
		{"word":"répit","word_nosc":"repit","lemma":"répit","pos":"NOM"} ,
		{"word":"répits","word_nosc":"repits","lemma":"répit","pos":"NOM"} ,
		{"word":"réplication","word_nosc":"replication","lemma":"réplication","pos":"NOM"} ,
		{"word":"réplique","word_nosc":"replique","lemma":"réplique","pos":"NOM"} ,
		{"word":"répliques","word_nosc":"repliques","lemma":"réplique","pos":"NOM"} ,
		{"word":"répondant","word_nosc":"repondant","lemma":"répondant","pos":"NOM"} ,
		{"word":"répondants","word_nosc":"repondants","lemma":"répondant","pos":"NOM"} ,
		{"word":"répondeur","word_nosc":"repondeur","lemma":"répondeur","pos":"NOM"} ,
		{"word":"répondeurs","word_nosc":"repondeurs","lemma":"répondeur","pos":"NOM"} ,
		{"word":"répons","word_nosc":"repons","lemma":"répons","pos":"NOM"} ,
		{"word":"réponse","word_nosc":"reponse","lemma":"réponse","pos":"NOM"} ,
		{"word":"réponses","word_nosc":"reponses","lemma":"réponse","pos":"NOM"} ,
		{"word":"répresseur","word_nosc":"represseur","lemma":"répresseur","pos":"NOM"} ,
		{"word":"répression","word_nosc":"repression","lemma":"répression","pos":"NOM"} ,
		{"word":"répressions","word_nosc":"repressions","lemma":"répression","pos":"NOM"} ,
		{"word":"réprimande","word_nosc":"reprimande","lemma":"réprimande","pos":"NOM"} ,
		{"word":"réprimandes","word_nosc":"reprimandes","lemma":"réprimande","pos":"NOM"} ,
		{"word":"réprobation","word_nosc":"reprobation","lemma":"réprobation","pos":"NOM"} ,
		{"word":"réprouvé","word_nosc":"reprouve","lemma":"réprouvé","pos":"NOM"} ,
		{"word":"réprouvées","word_nosc":"reprouvees","lemma":"réprouvé","pos":"NOM"} ,
		{"word":"réprouvés","word_nosc":"reprouves","lemma":"réprouvé","pos":"NOM"} ,
		{"word":"républicain","word_nosc":"republicain","lemma":"républicain","pos":"NOM"} ,
		{"word":"républicaine","word_nosc":"republicaine","lemma":"républicain","pos":"NOM"} ,
		{"word":"républicaines","word_nosc":"republicaines","lemma":"républicain","pos":"NOM"} ,
		{"word":"républicains","word_nosc":"republicains","lemma":"républicain","pos":"NOM"} ,
		{"word":"république","word_nosc":"republique","lemma":"république","pos":"NOM"} ,
		{"word":"républiques","word_nosc":"republiques","lemma":"république","pos":"NOM"} ,
		{"word":"répudiation","word_nosc":"repudiation","lemma":"répudiation","pos":"NOM"} ,
		{"word":"répugnance","word_nosc":"repugnance","lemma":"répugnance","pos":"NOM"} ,
		{"word":"répugnances","word_nosc":"repugnances","lemma":"répugnance","pos":"NOM"} ,
		{"word":"répulsion","word_nosc":"repulsion","lemma":"répulsion","pos":"NOM"} ,
		{"word":"répulsions","word_nosc":"repulsions","lemma":"répulsion","pos":"NOM"} ,
		{"word":"réputation","word_nosc":"reputation","lemma":"réputation","pos":"NOM"} ,
		{"word":"réputations","word_nosc":"reputations","lemma":"réputation","pos":"NOM"} ,
		{"word":"répétiteur","word_nosc":"repetiteur","lemma":"répétiteur","pos":"NOM"} ,
		{"word":"répétiteurs","word_nosc":"repetiteurs","lemma":"répétiteur","pos":"NOM"} ,
		{"word":"répétition","word_nosc":"repetition","lemma":"répétition","pos":"NOM"} ,
		{"word":"répétitions","word_nosc":"repetitions","lemma":"répétition","pos":"NOM"} ,
		{"word":"répétitrice","word_nosc":"repetitrice","lemma":"répétiteur","pos":"NOM"} ,
		{"word":"réquisition","word_nosc":"requisition","lemma":"réquisition","pos":"NOM"} ,
		{"word":"réquisitions","word_nosc":"requisitions","lemma":"réquisition","pos":"NOM"} ,
		{"word":"réquisitoire","word_nosc":"requisitoire","lemma":"réquisitoire","pos":"NOM"} ,
		{"word":"réquisitoires","word_nosc":"requisitoires","lemma":"réquisitoire","pos":"NOM"} ,
		{"word":"réseau","word_nosc":"reseau","lemma":"réseau","pos":"NOM"} ,
		{"word":"réseaux","word_nosc":"reseaux","lemma":"réseau","pos":"NOM"} ,
		{"word":"résection","word_nosc":"resection","lemma":"résection","pos":"NOM"} ,
		{"word":"réservation","word_nosc":"reservation","lemma":"réservation","pos":"NOM"} ,
		{"word":"réservations","word_nosc":"reservations","lemma":"réservation","pos":"NOM"} ,
		{"word":"réserve","word_nosc":"reserve","lemma":"réserve","pos":"NOM"} ,
		{"word":"réserves","word_nosc":"reserves","lemma":"réserve","pos":"NOM"} ,
		{"word":"réserviste","word_nosc":"reserviste","lemma":"réserviste","pos":"NOM"} ,
		{"word":"réservistes","word_nosc":"reservistes","lemma":"réserviste","pos":"NOM"} ,
		{"word":"réservoir","word_nosc":"reservoir","lemma":"réservoir","pos":"NOM"} ,
		{"word":"réservoirs","word_nosc":"reservoirs","lemma":"réservoir","pos":"NOM"} ,
		{"word":"résidant","word_nosc":"residant","lemma":"résidant","pos":"NOM"} ,
		{"word":"résidence","word_nosc":"residence","lemma":"résidence","pos":"NOM"} ,
		{"word":"résidences","word_nosc":"residences","lemma":"résidence","pos":"NOM"} ,
		{"word":"résident","word_nosc":"resident","lemma":"résident","pos":"NOM"} ,
		{"word":"résidente","word_nosc":"residente","lemma":"résident","pos":"NOM"} ,
		{"word":"résidents","word_nosc":"residents","lemma":"résident","pos":"NOM"} ,
		{"word":"résidu","word_nosc":"residu","lemma":"résidu","pos":"NOM"} ,
		{"word":"résidus","word_nosc":"residus","lemma":"résidu","pos":"NOM"} ,
		{"word":"résignation","word_nosc":"resignation","lemma":"résignation","pos":"NOM"} ,
		{"word":"résignations","word_nosc":"resignations","lemma":"résignation","pos":"NOM"} ,
		{"word":"résigné","word_nosc":"resigne","lemma":"résigné","pos":"NOM"} ,
		{"word":"résignée","word_nosc":"resignee","lemma":"résigné","pos":"NOM"} ,
		{"word":"résignées","word_nosc":"resignees","lemma":"résigné","pos":"NOM"} ,
		{"word":"résignés","word_nosc":"resignes","lemma":"résigné","pos":"NOM"} ,
		{"word":"résiliation","word_nosc":"resiliation","lemma":"résiliation","pos":"NOM"} ,
		{"word":"résilience","word_nosc":"resilience","lemma":"résilience","pos":"NOM"} ,
		{"word":"résille","word_nosc":"resille","lemma":"résille","pos":"NOM"} ,
		{"word":"résilles","word_nosc":"resilles","lemma":"résille","pos":"NOM"} ,
		{"word":"résine","word_nosc":"resine","lemma":"résine","pos":"NOM"} ,
		{"word":"résines","word_nosc":"resines","lemma":"résine","pos":"NOM"} ,
		{"word":"résineux","word_nosc":"resineux","lemma":"résineux","pos":"NOM"} ,
		{"word":"résiné","word_nosc":"resine","lemma":"résiné","pos":"NOM"} ,
		{"word":"résipiscence","word_nosc":"resipiscence","lemma":"résipiscence","pos":"NOM"} ,
		{"word":"résistance","word_nosc":"resistance","lemma":"résistance","pos":"NOM"} ,
		{"word":"résistances","word_nosc":"resistances","lemma":"résistance","pos":"NOM"} ,
		{"word":"résistant","word_nosc":"resistant","lemma":"résistant","pos":"NOM"} ,
		{"word":"résistante","word_nosc":"resistante","lemma":"résistant","pos":"NOM"} ,
		{"word":"résistantes","word_nosc":"resistantes","lemma":"résistant","pos":"NOM"} ,
		{"word":"résistants","word_nosc":"resistants","lemma":"résistant","pos":"NOM"} ,
		{"word":"résolution","word_nosc":"resolution","lemma":"résolution","pos":"NOM"} ,
		{"word":"résolutions","word_nosc":"resolutions","lemma":"résolution","pos":"NOM"} ,
		{"word":"résonance","word_nosc":"resonance","lemma":"résonance","pos":"NOM"} ,
		{"word":"résonances","word_nosc":"resonances","lemma":"résonance","pos":"NOM"} ,
		{"word":"résonateur","word_nosc":"resonateur","lemma":"résonateur","pos":"NOM"} ,
		{"word":"résorption","word_nosc":"resorption","lemma":"résorption","pos":"NOM"} ,
		{"word":"résorptions","word_nosc":"resorptions","lemma":"résorption","pos":"NOM"} ,
		{"word":"résultante","word_nosc":"resultante","lemma":"résultante","pos":"NOM"} ,
		{"word":"résultantes","word_nosc":"resultantes","lemma":"résultante","pos":"NOM"} ,
		{"word":"résultat","word_nosc":"resultat","lemma":"résultat","pos":"NOM"} ,
		{"word":"résultats","word_nosc":"resultats","lemma":"résultat","pos":"NOM"} ,
		{"word":"résumé","word_nosc":"resume","lemma":"résumé","pos":"NOM"} ,
		{"word":"résumés","word_nosc":"resumes","lemma":"résumé","pos":"NOM"} ,
		{"word":"résurgence","word_nosc":"resurgence","lemma":"résurgence","pos":"NOM"} ,
		{"word":"résurgences","word_nosc":"resurgences","lemma":"résurgence","pos":"NOM"} ,
		{"word":"résurrection","word_nosc":"resurrection","lemma":"résurrection","pos":"NOM"} ,
		{"word":"résurrections","word_nosc":"resurrections","lemma":"résurrection","pos":"NOM"} ,
		{"word":"réséda","word_nosc":"reseda","lemma":"réséda","pos":"NOM"} ,
		{"word":"résédas","word_nosc":"resedas","lemma":"réséda","pos":"NOM"} ,
		{"word":"rétablissement","word_nosc":"retablissement","lemma":"rétablissement","pos":"NOM"} ,
		{"word":"rétablissements","word_nosc":"retablissements","lemma":"rétablissement","pos":"NOM"} ,
		{"word":"rétameur","word_nosc":"retameur","lemma":"rétameur","pos":"NOM"} ,
		{"word":"rétention","word_nosc":"retention","lemma":"rétention","pos":"NOM"} ,
		{"word":"réticence","word_nosc":"reticence","lemma":"réticence","pos":"NOM"} ,
		{"word":"réticences","word_nosc":"reticences","lemma":"réticence","pos":"NOM"} ,
		{"word":"réticulation","word_nosc":"reticulation","lemma":"réticulation","pos":"NOM"} ,
		{"word":"réticule","word_nosc":"reticule","lemma":"réticule","pos":"NOM"} ,
		{"word":"réticulum","word_nosc":"reticulum","lemma":"réticulum","pos":"NOM"} ,
		{"word":"rétinal","word_nosc":"retinal","lemma":"rétinal","pos":"NOM"} ,
		{"word":"rétine","word_nosc":"retine","lemma":"rétine","pos":"NOM"} ,
		{"word":"rétines","word_nosc":"retines","lemma":"rétine","pos":"NOM"} ,
		{"word":"rétinite","word_nosc":"retinite","lemma":"rétinite","pos":"NOM"} ,
		{"word":"rétinopathie","word_nosc":"retinopathie","lemma":"rétinopathie","pos":"NOM"} ,
		{"word":"rétorsion","word_nosc":"retorsion","lemma":"rétorsion","pos":"NOM"} ,
		{"word":"rétractation","word_nosc":"retractation","lemma":"rétractation","pos":"NOM"} ,
		{"word":"rétractations","word_nosc":"retractations","lemma":"rétractation","pos":"NOM"} ,
		{"word":"rétracteur","word_nosc":"retracteur","lemma":"rétracteur","pos":"NOM"} ,
		{"word":"rétracteurs","word_nosc":"retracteurs","lemma":"rétracteur","pos":"NOM"} ,
		{"word":"rétraction","word_nosc":"retraction","lemma":"rétraction","pos":"NOM"} ,
		{"word":"rétribution","word_nosc":"retribution","lemma":"rétribution","pos":"NOM"} ,
		{"word":"rétributions","word_nosc":"retributions","lemma":"rétribution","pos":"NOM"} ,
		{"word":"rétro","word_nosc":"retro","lemma":"rétro","pos":"NOM"} ,
		{"word":"rétroaction","word_nosc":"retroaction","lemma":"rétroaction","pos":"NOM"} ,
		{"word":"rétrocession","word_nosc":"retrocession","lemma":"rétrocession","pos":"NOM"} ,
		{"word":"rétrofusée","word_nosc":"retrofusee","lemma":"rétrofusée","pos":"NOM"} ,
		{"word":"rétrofusées","word_nosc":"retrofusees","lemma":"rétrofusée","pos":"NOM"} ,
		{"word":"rétrogradation","word_nosc":"retrogradation","lemma":"rétrogradation","pos":"NOM"} ,
		{"word":"rétrogradations","word_nosc":"retrogradations","lemma":"rétrogradation","pos":"NOM"} ,
		{"word":"rétrogression","word_nosc":"retrogression","lemma":"rétrogression","pos":"NOM"} ,
		{"word":"rétroprojecteur","word_nosc":"retroprojecteur","lemma":"rétroprojecteur","pos":"NOM"} ,
		{"word":"rétropropulsion","word_nosc":"retropropulsion","lemma":"rétropropulsion","pos":"NOM"} ,
		{"word":"rétros","word_nosc":"retros","lemma":"rétro","pos":"NOM"} ,
		{"word":"rétrospection","word_nosc":"retrospection","lemma":"rétrospection","pos":"NOM"} ,
		{"word":"rétrospective","word_nosc":"retrospective","lemma":"rétrospective","pos":"NOM"} ,
		{"word":"rétrospectives","word_nosc":"retrospectives","lemma":"rétrospective","pos":"NOM"} ,
		{"word":"rétroversion","word_nosc":"retroversion","lemma":"rétroversion","pos":"NOM"} ,
		{"word":"rétrovirus","word_nosc":"retrovirus","lemma":"rétrovirus","pos":"NOM"} ,
		{"word":"rétroviseur","word_nosc":"retroviseur","lemma":"rétroviseur","pos":"NOM"} ,
		{"word":"rétroviseurs","word_nosc":"retroviseurs","lemma":"rétroviseur","pos":"NOM"} ,
		{"word":"rétrécissement","word_nosc":"retrecissement","lemma":"rétrécissement","pos":"NOM"} ,
		{"word":"rétrécissements","word_nosc":"retrecissements","lemma":"rétrécissement","pos":"NOM"} ,
		{"word":"réunification","word_nosc":"reunification","lemma":"réunification","pos":"NOM"} ,
		{"word":"réunion","word_nosc":"reunion","lemma":"réunion","pos":"NOM"} ,
		{"word":"réunionnais","word_nosc":"reunionnais","lemma":"réunionnais","pos":"NOM"} ,
		{"word":"réunionnite","word_nosc":"reunionnite","lemma":"réunionnite","pos":"NOM"} ,
		{"word":"réunions","word_nosc":"reunions","lemma":"réunion","pos":"NOM"} ,
		{"word":"réussite","word_nosc":"reussite","lemma":"réussite","pos":"NOM"} ,
		{"word":"réussites","word_nosc":"reussites","lemma":"réussite","pos":"NOM"} ,
		{"word":"réutilisation","word_nosc":"reutilisation","lemma":"réutilisation","pos":"NOM"} ,
		{"word":"réveil","word_nosc":"reveil","lemma":"réveil","pos":"NOM"} ,
		{"word":"réveille-matin","word_nosc":"reveille-matin","lemma":"réveille-matin","pos":"NOM"} ,
		{"word":"réveilleurs","word_nosc":"reveilleurs","lemma":"réveilleur","pos":"NOM"} ,
		{"word":"réveillon","word_nosc":"reveillon","lemma":"réveillon","pos":"NOM"} ,
		{"word":"réveillonneurs","word_nosc":"reveillonneurs","lemma":"réveillonneur","pos":"NOM"} ,
		{"word":"réveillons","word_nosc":"reveillons","lemma":"réveillon","pos":"NOM"} ,
		{"word":"réveils","word_nosc":"reveils","lemma":"réveil","pos":"NOM"} ,
		{"word":"réverbère","word_nosc":"reverbere","lemma":"réverbère","pos":"NOM"} ,
		{"word":"réverbères","word_nosc":"reverberes","lemma":"réverbère","pos":"NOM"} ,
		{"word":"réverbération","word_nosc":"reverberation","lemma":"réverbération","pos":"NOM"} ,
		{"word":"réverbérations","word_nosc":"reverberations","lemma":"réverbération","pos":"NOM"} ,
		{"word":"réversibilité","word_nosc":"reversibilite","lemma":"réversibilité","pos":"NOM"} ,
		{"word":"réversion","word_nosc":"reversion","lemma":"réversion","pos":"NOM"} ,
		{"word":"réviseur","word_nosc":"reviseur","lemma":"réviseur","pos":"NOM"} ,
		{"word":"révision","word_nosc":"revision","lemma":"révision","pos":"NOM"} ,
		{"word":"révisionnisme","word_nosc":"revisionnisme","lemma":"révisionnisme","pos":"NOM"} ,
		{"word":"révisionniste","word_nosc":"revisionniste","lemma":"révisionniste","pos":"NOM"} ,
		{"word":"révisionnistes","word_nosc":"revisionnistes","lemma":"révisionniste","pos":"NOM"} ,
		{"word":"révisions","word_nosc":"revisions","lemma":"révision","pos":"NOM"} ,
		{"word":"réviso","word_nosc":"reviso","lemma":"réviso","pos":"NOM"} ,
		{"word":"révocation","word_nosc":"revocation","lemma":"révocation","pos":"NOM"} ,
		{"word":"révocations","word_nosc":"revocations","lemma":"révocation","pos":"NOM"} ,
		{"word":"révolte","word_nosc":"revolte","lemma":"révolte","pos":"NOM"} ,
		{"word":"révoltes","word_nosc":"revoltes","lemma":"révolte","pos":"NOM"} ,
		{"word":"révolté","word_nosc":"revolte","lemma":"révolté","pos":"NOM"} ,
		{"word":"révoltée","word_nosc":"revoltee","lemma":"révolté","pos":"NOM"} ,
		{"word":"révoltées","word_nosc":"revoltees","lemma":"révolté","pos":"NOM"} ,
		{"word":"révoltés","word_nosc":"revoltes","lemma":"révolté","pos":"NOM"} ,
		{"word":"révolution","word_nosc":"revolution","lemma":"révolution","pos":"NOM"} ,
		{"word":"révolutionnaire","word_nosc":"revolutionnaire","lemma":"révolutionnaire","pos":"NOM"} ,
		{"word":"révolutionnaires","word_nosc":"revolutionnaires","lemma":"révolutionnaire","pos":"NOM"} ,
		{"word":"révolutions","word_nosc":"revolutions","lemma":"révolution","pos":"NOM"} ,
		{"word":"révulsif","word_nosc":"revulsif","lemma":"révulsif","pos":"NOM"} ,
		{"word":"révulsion","word_nosc":"revulsion","lemma":"révulsion","pos":"NOM"} ,
		{"word":"révélateur","word_nosc":"revelateur","lemma":"révélateur","pos":"NOM"} ,
		{"word":"révélateurs","word_nosc":"revelateurs","lemma":"révélateur","pos":"NOM"} ,
		{"word":"révélation","word_nosc":"revelation","lemma":"révélation","pos":"NOM"} ,
		{"word":"révélations","word_nosc":"revelations","lemma":"révélation","pos":"NOM"} ,
		{"word":"révérence","word_nosc":"reverence","lemma":"révérence","pos":"NOM"} ,
		{"word":"révérences","word_nosc":"reverences","lemma":"révérence","pos":"NOM"} ,
		{"word":"révérend","word_nosc":"reverend","lemma":"révérend","pos":"NOM"} ,
		{"word":"révérends","word_nosc":"reverends","lemma":"révérend","pos":"NOM"} ,
		{"word":"rééchelonnement","word_nosc":"reechelonnement","lemma":"rééchelonnement","pos":"NOM"} ,
		{"word":"réécriture","word_nosc":"reecriture","lemma":"réécriture","pos":"NOM"} ,
		{"word":"réécritures","word_nosc":"reecritures","lemma":"réécriture","pos":"NOM"} ,
		{"word":"réédition","word_nosc":"reedition","lemma":"réédition","pos":"NOM"} ,
		{"word":"rééditions","word_nosc":"reeditions","lemma":"réédition","pos":"NOM"} ,
		{"word":"rééducateur","word_nosc":"reeducateur","lemma":"rééducateur","pos":"NOM"} ,
		{"word":"rééducation","word_nosc":"reeducation","lemma":"rééducation","pos":"NOM"} ,
		{"word":"réélection","word_nosc":"reelection","lemma":"réélection","pos":"NOM"} ,
		{"word":"réélu","word_nosc":"reelu","lemma":"réélu","pos":"NOM"} ,
		{"word":"rééquilibrage","word_nosc":"reequilibrage","lemma":"rééquilibrage","pos":"NOM"} ,
		{"word":"réévaluation","word_nosc":"reevaluation","lemma":"réévaluation","pos":"NOM"} ,
		{"word":"rêne","word_nosc":"rene","lemma":"rêne","pos":"NOM"} ,
		{"word":"rênes","word_nosc":"renes","lemma":"rêne","pos":"NOM"} ,
		{"word":"rêvasserie","word_nosc":"revasserie","lemma":"rêvasserie","pos":"NOM"} ,
		{"word":"rêvasseries","word_nosc":"revasseries","lemma":"rêvasserie","pos":"NOM"} ,
		{"word":"rêve","word_nosc":"reve","lemma":"rêve","pos":"NOM"} ,
		{"word":"rêverie","word_nosc":"reverie","lemma":"rêverie","pos":"NOM"} ,
		{"word":"rêveries","word_nosc":"reveries","lemma":"rêverie","pos":"NOM"} ,
		{"word":"rêves","word_nosc":"reves","lemma":"rêve","pos":"NOM"} ,
		{"word":"rêveur","word_nosc":"reveur","lemma":"rêveur","pos":"NOM"} ,
		{"word":"rêveurs","word_nosc":"reveurs","lemma":"rêveur","pos":"NOM"} ,
		{"word":"rêveuse","word_nosc":"reveuse","lemma":"rêveur","pos":"NOM"} ,
		{"word":"rêveuses","word_nosc":"reveuses","lemma":"rêveur","pos":"NOM"} ,
		{"word":"rôdeur","word_nosc":"rodeur","lemma":"rôdeur","pos":"NOM"} ,
		{"word":"rôdeurs","word_nosc":"rodeurs","lemma":"rôdeur","pos":"NOM"} ,
		{"word":"rôdeuse","word_nosc":"rodeuse","lemma":"rôdeur","pos":"NOM"} ,
		{"word":"rôdeuses","word_nosc":"rodeuses","lemma":"rôdeur","pos":"NOM"} ,
		{"word":"rôle","word_nosc":"role","lemma":"rôle","pos":"NOM"} ,
		{"word":"rôle-titre","word_nosc":"role-titre","lemma":"rôle-titre","pos":"NOM"} ,
		{"word":"rôles","word_nosc":"roles","lemma":"rôle","pos":"NOM"} ,
		{"word":"rônier","word_nosc":"ronier","lemma":"rônier","pos":"NOM"} ,
		{"word":"rôniers","word_nosc":"roniers","lemma":"rônier","pos":"NOM"} ,
		{"word":"rônin","word_nosc":"ronin","lemma":"rônin","pos":"NOM"} ,
		{"word":"rônins","word_nosc":"ronins","lemma":"rônin","pos":"NOM"} ,
		{"word":"rôt","word_nosc":"rot","lemma":"rôt","pos":"NOM"} ,
		{"word":"rôti","word_nosc":"roti","lemma":"rôti","pos":"NOM"} ,
		{"word":"rôtie","word_nosc":"rotie","lemma":"rôtie","pos":"NOM"} ,
		{"word":"rôties","word_nosc":"roties","lemma":"rôtie","pos":"NOM"} ,
		{"word":"rôtis","word_nosc":"rotis","lemma":"rôti","pos":"NOM"} ,
		{"word":"rôtisserie","word_nosc":"rotisserie","lemma":"rôtisserie","pos":"NOM"} ,
		{"word":"rôtisseur","word_nosc":"rotisseur","lemma":"rôtisseur","pos":"NOM"} ,
		{"word":"rôtisseurs","word_nosc":"rotisseurs","lemma":"rôtisseur","pos":"NOM"} ,
		{"word":"rôtisseuse","word_nosc":"rotisseuse","lemma":"rôtisseur","pos":"NOM"} ,
		{"word":"rôtisseuses","word_nosc":"rotisseuses","lemma":"rôtisseur","pos":"NOM"} ,
		{"word":"rôtissoire","word_nosc":"rotissoire","lemma":"rôtissoire","pos":"NOM"} ,
		{"word":"rôtissoires","word_nosc":"rotissoires","lemma":"rôtissoire","pos":"NOM"} ,
		{"word":"rôts","word_nosc":"rots","lemma":"rôt","pos":"NOM"} ,
		{"word":"röntgens","word_nosc":"rontgens","lemma":"röntgen","pos":"NOM"} ,
		{"word":"s","word_nosc":"s","lemma":"s","pos":"NOM"} ,
		{"word":"s'","word_nosc":"s'","lemma":"s","pos":"NOM"} ,
		{"word":"sa","word_nosc":"sa","lemma":"sa","pos":"NOM"} ,
		{"word":"sabayon","word_nosc":"sabayon","lemma":"sabayon","pos":"NOM"} ,
		{"word":"sabayons","word_nosc":"sabayons","lemma":"sabayon","pos":"NOM"} ,
		{"word":"sabbat","word_nosc":"sabbat","lemma":"sabbat","pos":"NOM"} ,
		{"word":"sabbats","word_nosc":"sabbats","lemma":"sabbat","pos":"NOM"} ,
		{"word":"sabelles","word_nosc":"sabelles","lemma":"sabelle","pos":"NOM"} ,
		{"word":"sabines","word_nosc":"sabines","lemma":"sabine","pos":"NOM"} ,
		{"word":"sabir","word_nosc":"sabir","lemma":"sabir","pos":"NOM"} ,
		{"word":"sable","word_nosc":"sable","lemma":"sable","pos":"NOM"} ,
		{"word":"sables","word_nosc":"sables","lemma":"sable","pos":"NOM"} ,
		{"word":"sablier","word_nosc":"sablier","lemma":"sablier","pos":"NOM"} ,
		{"word":"sabliers","word_nosc":"sabliers","lemma":"sablier","pos":"NOM"} ,
		{"word":"sablière","word_nosc":"sabliere","lemma":"sablière","pos":"NOM"} ,
		{"word":"sablières","word_nosc":"sablieres","lemma":"sablière","pos":"NOM"} ,
		{"word":"sablons","word_nosc":"sablons","lemma":"sablon","pos":"NOM"} ,
		{"word":"sabord","word_nosc":"sabord","lemma":"sabord","pos":"NOM"} ,
		{"word":"sabordage","word_nosc":"sabordage","lemma":"sabordage","pos":"NOM"} ,
		{"word":"sabords","word_nosc":"sabords","lemma":"sabord","pos":"NOM"} ,
		{"word":"sabot","word_nosc":"sabot","lemma":"sabot","pos":"NOM"} ,
		{"word":"sabotage","word_nosc":"sabotage","lemma":"sabotage","pos":"NOM"} ,
		{"word":"sabotages","word_nosc":"sabotages","lemma":"sabotage","pos":"NOM"} ,
		{"word":"saboterie","word_nosc":"saboterie","lemma":"saboterie","pos":"NOM"} ,
		{"word":"saboteur","word_nosc":"saboteur","lemma":"saboteur","pos":"NOM"} ,
		{"word":"saboteurs","word_nosc":"saboteurs","lemma":"saboteur","pos":"NOM"} ,
		{"word":"saboteuse","word_nosc":"saboteuse","lemma":"saboteur","pos":"NOM"} ,
		{"word":"sabotier","word_nosc":"sabotier","lemma":"sabotier","pos":"NOM"} ,
		{"word":"sabotiers","word_nosc":"sabotiers","lemma":"sabotier","pos":"NOM"} ,
		{"word":"sabots","word_nosc":"sabots","lemma":"sabot","pos":"NOM"} ,
		{"word":"sabra","word_nosc":"sabra","lemma":"sabra","pos":"NOM"} ,
		{"word":"sabre","word_nosc":"sabre","lemma":"sabre","pos":"NOM"} ,
		{"word":"sabres","word_nosc":"sabres","lemma":"sabre","pos":"NOM"} ,
		{"word":"sabretache","word_nosc":"sabretache","lemma":"sabretache","pos":"NOM"} ,
		{"word":"sabretaches","word_nosc":"sabretaches","lemma":"sabretache","pos":"NOM"} ,
		{"word":"sabreur","word_nosc":"sabreur","lemma":"sabreur","pos":"NOM"} ,
		{"word":"sabreurs","word_nosc":"sabreurs","lemma":"sabreur","pos":"NOM"} ,
		{"word":"sac","word_nosc":"sac","lemma":"sac","pos":"NOM"} ,
		{"word":"sac-poubelle","word_nosc":"sac-poubelle","lemma":"sac-poubelle","pos":"NOM"} ,
		{"word":"saccade","word_nosc":"saccade","lemma":"saccade","pos":"NOM"} ,
		{"word":"saccades","word_nosc":"saccades","lemma":"saccade","pos":"NOM"} ,
		{"word":"saccage","word_nosc":"saccage","lemma":"saccage","pos":"NOM"} ,
		{"word":"saccages","word_nosc":"saccages","lemma":"saccage","pos":"NOM"} ,
		{"word":"saccageurs","word_nosc":"saccageurs","lemma":"saccageur","pos":"NOM"} ,
		{"word":"saccagne","word_nosc":"saccagne","lemma":"saccagne","pos":"NOM"} ,
		{"word":"saccharine","word_nosc":"saccharine","lemma":"saccharine","pos":"NOM"} ,
		{"word":"saccharomyces","word_nosc":"saccharomyces","lemma":"saccharomyces","pos":"NOM"} ,
		{"word":"saccharose","word_nosc":"saccharose","lemma":"saccharose","pos":"NOM"} ,
		{"word":"sacculine","word_nosc":"sacculine","lemma":"sacculine","pos":"NOM"} ,
		{"word":"sacculines","word_nosc":"sacculines","lemma":"sacculine","pos":"NOM"} ,
		{"word":"sacerdoce","word_nosc":"sacerdoce","lemma":"sacerdoce","pos":"NOM"} ,
		{"word":"sacerdoces","word_nosc":"sacerdoces","lemma":"sacerdoce","pos":"NOM"} ,
		{"word":"sachem","word_nosc":"sachem","lemma":"sachem","pos":"NOM"} ,
		{"word":"sachet","word_nosc":"sachet","lemma":"sachet","pos":"NOM"} ,
		{"word":"sachets","word_nosc":"sachets","lemma":"sachet","pos":"NOM"} ,
		{"word":"sacoche","word_nosc":"sacoche","lemma":"sacoche","pos":"NOM"} ,
		{"word":"sacoches","word_nosc":"sacoches","lemma":"sacoche","pos":"NOM"} ,
		{"word":"sacralisation","word_nosc":"sacralisation","lemma":"sacralisation","pos":"NOM"} ,
		{"word":"sacre","word_nosc":"sacre","lemma":"sacre","pos":"NOM"} ,
		{"word":"sacrement","word_nosc":"sacrement","lemma":"sacrement","pos":"NOM"} ,
		{"word":"sacrements","word_nosc":"sacrements","lemma":"sacrement","pos":"NOM"} ,
		{"word":"sacres","word_nosc":"sacres","lemma":"sacre","pos":"NOM"} ,
		{"word":"sacret","word_nosc":"sacret","lemma":"sacret","pos":"NOM"} ,
		{"word":"sacrificateur","word_nosc":"sacrificateur","lemma":"sacrificateur","pos":"NOM"} ,
		{"word":"sacrificateurs","word_nosc":"sacrificateurs","lemma":"sacrificateur","pos":"NOM"} ,
		{"word":"sacrifice","word_nosc":"sacrifice","lemma":"sacrifice","pos":"NOM"} ,
		{"word":"sacrifices","word_nosc":"sacrifices","lemma":"sacrifice","pos":"NOM"} ,
		{"word":"sacrifié","word_nosc":"sacrifie","lemma":"sacrifié","pos":"NOM"} ,
		{"word":"sacrifiée","word_nosc":"sacrifiee","lemma":"sacrifié","pos":"NOM"} ,
		{"word":"sacrifiés","word_nosc":"sacrifies","lemma":"sacrifié","pos":"NOM"} ,
		{"word":"sacrilège","word_nosc":"sacrilege","lemma":"sacrilège","pos":"NOM"} ,
		{"word":"sacrilèges","word_nosc":"sacrileges","lemma":"sacrilège","pos":"NOM"} ,
		{"word":"sacripant","word_nosc":"sacripant","lemma":"sacripant","pos":"NOM"} ,
		{"word":"sacripants","word_nosc":"sacripants","lemma":"sacripant","pos":"NOM"} ,
		{"word":"sacristain","word_nosc":"sacristain","lemma":"sacristain","pos":"NOM"} ,
		{"word":"sacristaine","word_nosc":"sacristaine","lemma":"sacristain","pos":"NOM"} ,
		{"word":"sacristaines","word_nosc":"sacristaines","lemma":"sacristain","pos":"NOM"} ,
		{"word":"sacristains","word_nosc":"sacristains","lemma":"sacristain","pos":"NOM"} ,
		{"word":"sacristie","word_nosc":"sacristie","lemma":"sacristie","pos":"NOM"} ,
		{"word":"sacristies","word_nosc":"sacristies","lemma":"sacristie","pos":"NOM"} ,
		{"word":"sacristine","word_nosc":"sacristine","lemma":"sacristine","pos":"NOM"} ,
		{"word":"sacrum","word_nosc":"sacrum","lemma":"sacrum","pos":"NOM"} ,
		{"word":"sacré","word_nosc":"sacre","lemma":"sacré","pos":"NOM"} ,
		{"word":"sacré-coeur","word_nosc":"sacre-coeur","lemma":"sacré-coeur","pos":"NOM"} ,
		{"word":"sacrés","word_nosc":"sacres","lemma":"sacré","pos":"NOM"} ,
		{"word":"sacs","word_nosc":"sacs","lemma":"sac","pos":"NOM"} ,
		{"word":"sacs-poubelles","word_nosc":"sacs-poubelles","lemma":"sac-poubelle","pos":"NOM"} ,
		{"word":"sadducéen","word_nosc":"sadduceen","lemma":"sadducéen","pos":"NOM"} ,
		{"word":"sadique","word_nosc":"sadique","lemma":"sadique","pos":"NOM"} ,
		{"word":"sadiques","word_nosc":"sadiques","lemma":"sadique","pos":"NOM"} ,
		{"word":"sadisme","word_nosc":"sadisme","lemma":"sadisme","pos":"NOM"} ,
		{"word":"sadismes","word_nosc":"sadismes","lemma":"sadisme","pos":"NOM"} ,
		{"word":"sadomasochisme","word_nosc":"sadomasochisme","lemma":"sadomasochisme","pos":"NOM"} ,
		{"word":"sadomasochiste","word_nosc":"sadomasochiste","lemma":"sadomasochiste","pos":"NOM"} ,
		{"word":"sados","word_nosc":"sados","lemma":"sado","pos":"NOM"} ,
		{"word":"saducéen","word_nosc":"saduceen","lemma":"saducéen","pos":"NOM"} ,
		{"word":"safari","word_nosc":"safari","lemma":"safari","pos":"NOM"} ,
		{"word":"safari-photo","word_nosc":"safari-photo","lemma":"safari-photo","pos":"NOM"} ,
		{"word":"safaris","word_nosc":"safaris","lemma":"safari","pos":"NOM"} ,
		{"word":"safran","word_nosc":"safran","lemma":"safran","pos":"NOM"} ,
		{"word":"saga","word_nosc":"saga","lemma":"saga","pos":"NOM"} ,
		{"word":"sagacité","word_nosc":"sagacite","lemma":"sagacité","pos":"NOM"} ,
		{"word":"sagaie","word_nosc":"sagaie","lemma":"sagaie","pos":"NOM"} ,
		{"word":"sagaies","word_nosc":"sagaies","lemma":"sagaie","pos":"NOM"} ,
		{"word":"sagard","word_nosc":"sagard","lemma":"sagard","pos":"NOM"} ,
		{"word":"sagas","word_nosc":"sagas","lemma":"saga","pos":"NOM"} ,
		{"word":"sage","word_nosc":"sage","lemma":"sage","pos":"NOM"} ,
		{"word":"sage-femme","word_nosc":"sage-femme","lemma":"sage-femme","pos":"NOM"} ,
		{"word":"sages","word_nosc":"sages","lemma":"sage","pos":"NOM"} ,
		{"word":"sages-femmes","word_nosc":"sages-femmes","lemma":"sage-femme","pos":"NOM"} ,
		{"word":"sagesse","word_nosc":"sagesse","lemma":"sagesse","pos":"NOM"} ,
		{"word":"sagesses","word_nosc":"sagesses","lemma":"sagesse","pos":"NOM"} ,
		{"word":"sagettes","word_nosc":"sagettes","lemma":"sagette","pos":"NOM"} ,
		{"word":"sagittaire","word_nosc":"sagittaire","lemma":"sagittaire","pos":"NOM"} ,
		{"word":"sagittaires","word_nosc":"sagittaires","lemma":"sagittaire","pos":"NOM"} ,
		{"word":"sagouin","word_nosc":"sagouin","lemma":"sagouin","pos":"NOM"} ,
		{"word":"sagouins","word_nosc":"sagouins","lemma":"sagouin","pos":"NOM"} ,
		{"word":"sagoutier","word_nosc":"sagoutier","lemma":"sagoutier","pos":"NOM"} ,
		{"word":"sagum","word_nosc":"sagum","lemma":"sagum","pos":"NOM"} ,
		{"word":"saharien","word_nosc":"saharien","lemma":"saharien","pos":"NOM"} ,
		{"word":"saharienne","word_nosc":"saharienne","lemma":"saharien","pos":"NOM"} ,
		{"word":"sahariennes","word_nosc":"sahariennes","lemma":"saharien","pos":"NOM"} ,
		{"word":"sahariens","word_nosc":"sahariens","lemma":"saharien","pos":"NOM"} ,
		{"word":"sahib","word_nosc":"sahib","lemma":"sahib","pos":"NOM"} ,
		{"word":"sahibs","word_nosc":"sahibs","lemma":"sahib","pos":"NOM"} ,
		{"word":"saie","word_nosc":"saie","lemma":"saie","pos":"NOM"} ,
		{"word":"saignement","word_nosc":"saignement","lemma":"saignement","pos":"NOM"} ,
		{"word":"saignements","word_nosc":"saignements","lemma":"saignement","pos":"NOM"} ,
		{"word":"saigneur","word_nosc":"saigneur","lemma":"saigneur","pos":"NOM"} ,
		{"word":"saignée","word_nosc":"saignee","lemma":"saignée","pos":"NOM"} ,
		{"word":"saignées","word_nosc":"saignees","lemma":"saignée","pos":"NOM"} ,
		{"word":"saillie","word_nosc":"saillie","lemma":"saillie","pos":"NOM"} ,
		{"word":"saillies","word_nosc":"saillies","lemma":"saillie","pos":"NOM"} ,
		{"word":"saindoux","word_nosc":"saindoux","lemma":"saindoux","pos":"NOM"} ,
		{"word":"sainfoin","word_nosc":"sainfoin","lemma":"sainfoin","pos":"NOM"} ,
		{"word":"sainfoins","word_nosc":"sainfoins","lemma":"sainfoin","pos":"NOM"} ,
		{"word":"saint","word_nosc":"saint","lemma":"saint","pos":"NOM"} ,
		{"word":"saint-bernard","word_nosc":"saint-bernard","lemma":"saint-bernard","pos":"NOM"} ,
		{"word":"saint-crépin","word_nosc":"saint-crepin","lemma":"saint-crépin","pos":"NOM"} ,
		{"word":"saint-cyrien","word_nosc":"saint-cyrien","lemma":"saint-cyrien","pos":"NOM"} ,
		{"word":"saint-cyriens","word_nosc":"saint-cyriens","lemma":"saint-cyrien","pos":"NOM"} ,
		{"word":"saint-esprit","word_nosc":"saint-esprit","lemma":"saint-esprit","pos":"NOM"} ,
		{"word":"saint-frusquin","word_nosc":"saint-frusquin","lemma":"saint-frusquin","pos":"NOM"} ,
		{"word":"saint-germain","word_nosc":"saint-germain","lemma":"saint-germain","pos":"NOM"} ,
		{"word":"saint-glinglin","word_nosc":"saint-glinglin","lemma":"saint-glinglin","pos":"NOM"} ,
		{"word":"saint-guy","word_nosc":"saint-guy","lemma":"saint-guy","pos":"NOM"} ,
		{"word":"saint-honoré","word_nosc":"saint-honore","lemma":"saint-honoré","pos":"NOM"} ,
		{"word":"saint-hubert","word_nosc":"saint-hubert","lemma":"saint-hubert","pos":"NOM"} ,
		{"word":"saint-michel","word_nosc":"saint-michel","lemma":"saint-michel","pos":"NOM"} ,
		{"word":"saint-nectaire","word_nosc":"saint-nectaire","lemma":"saint-nectaire","pos":"NOM"} ,
		{"word":"saint-paulin","word_nosc":"saint-paulin","lemma":"saint-paulin","pos":"NOM"} ,
		{"word":"saint-pierre","word_nosc":"saint-pierre","lemma":"saint-pierre","pos":"NOM"} ,
		{"word":"saint-père","word_nosc":"saint-pere","lemma":"saint-père","pos":"NOM"} ,
		{"word":"saint-siège","word_nosc":"saint-siege","lemma":"saint-siège","pos":"NOM"} ,
		{"word":"saint-synode","word_nosc":"saint-synode","lemma":"saint-synode","pos":"NOM"} ,
		{"word":"saint-émilion","word_nosc":"saint-emilion","lemma":"saint-émilion","pos":"NOM"} ,
		{"word":"sainte","word_nosc":"sainte","lemma":"saint","pos":"NOM"} ,
		{"word":"sainte nitouche","word_nosc":"sainte nitouche","lemma":"sainte nitouche","pos":"NOM"} ,
		{"word":"sainte-nitouche","word_nosc":"sainte-nitouche","lemma":"sainte-nitouche","pos":"NOM"} ,
		{"word":"saintes","word_nosc":"saintes","lemma":"saint","pos":"NOM"} ,
		{"word":"saintes-nitouches","word_nosc":"saintes-nitouches","lemma":"sainte-nitouche","pos":"NOM"} ,
		{"word":"sainteté","word_nosc":"saintete","lemma":"sainteté","pos":"NOM"} ,
		{"word":"saintetés","word_nosc":"saintetes","lemma":"sainteté","pos":"NOM"} ,
		{"word":"saints","word_nosc":"saints","lemma":"saint","pos":"NOM"} ,
		{"word":"saints-pères","word_nosc":"saints-peres","lemma":"saint-père","pos":"NOM"} ,
		{"word":"saisie","word_nosc":"saisie","lemma":"saisie","pos":"NOM"} ,
		{"word":"saisie-arrêt","word_nosc":"saisie-arret","lemma":"saisie-arrêt","pos":"NOM"} ,
		{"word":"saisies","word_nosc":"saisies","lemma":"saisie","pos":"NOM"} ,
		{"word":"saisissement","word_nosc":"saisissement","lemma":"saisissement","pos":"NOM"} ,
		{"word":"saisissements","word_nosc":"saisissements","lemma":"saisissement","pos":"NOM"} ,
		{"word":"saison","word_nosc":"saison","lemma":"saison","pos":"NOM"} ,
		{"word":"saisonnier","word_nosc":"saisonnier","lemma":"saisonnier","pos":"NOM"} ,
		{"word":"saisonniers","word_nosc":"saisonniers","lemma":"saisonnier","pos":"NOM"} ,
		{"word":"saisonnière","word_nosc":"saisonniere","lemma":"saisonnier","pos":"NOM"} ,
		{"word":"saisons","word_nosc":"saisons","lemma":"saison","pos":"NOM"} ,
		{"word":"saki","word_nosc":"saki","lemma":"saki","pos":"NOM"} ,
		{"word":"saké","word_nosc":"sake","lemma":"saké","pos":"NOM"} ,
		{"word":"sakés","word_nosc":"sakes","lemma":"saké","pos":"NOM"} ,
		{"word":"salacité","word_nosc":"salacite","lemma":"salacité","pos":"NOM"} ,
		{"word":"salacités","word_nosc":"salacites","lemma":"salacité","pos":"NOM"} ,
		{"word":"salade","word_nosc":"salade","lemma":"salade","pos":"NOM"} ,
		{"word":"salades","word_nosc":"salades","lemma":"salade","pos":"NOM"} ,
		{"word":"saladier","word_nosc":"saladier","lemma":"saladier","pos":"NOM"} ,
		{"word":"saladiers","word_nosc":"saladiers","lemma":"saladier","pos":"NOM"} ,
		{"word":"salaire","word_nosc":"salaire","lemma":"salaire","pos":"NOM"} ,
		{"word":"salaires","word_nosc":"salaires","lemma":"salaire","pos":"NOM"} ,
		{"word":"salaison","word_nosc":"salaison","lemma":"salaison","pos":"NOM"} ,
		{"word":"salaisons","word_nosc":"salaisons","lemma":"salaison","pos":"NOM"} ,
		{"word":"salam","word_nosc":"salam","lemma":"salam","pos":"NOM"} ,
		{"word":"salamalec","word_nosc":"salamalec","lemma":"salamalec","pos":"NOM"} ,
		{"word":"salamalecs","word_nosc":"salamalecs","lemma":"salamalec","pos":"NOM"} ,
		{"word":"salamandre","word_nosc":"salamandre","lemma":"salamandre","pos":"NOM"} ,
		{"word":"salamandres","word_nosc":"salamandres","lemma":"salamandre","pos":"NOM"} ,
		{"word":"salami","word_nosc":"salami","lemma":"salami","pos":"NOM"} ,
		{"word":"salamis","word_nosc":"salamis","lemma":"salami","pos":"NOM"} ,
		{"word":"salanque","word_nosc":"salanque","lemma":"salanque","pos":"NOM"} ,
		{"word":"salariat","word_nosc":"salariat","lemma":"salariat","pos":"NOM"} ,
		{"word":"salarié","word_nosc":"salarie","lemma":"salarié","pos":"NOM"} ,
		{"word":"salariée","word_nosc":"salariee","lemma":"salarié","pos":"NOM"} ,
		{"word":"salariés","word_nosc":"salaries","lemma":"salarié","pos":"NOM"} ,
		{"word":"salat","word_nosc":"salat","lemma":"salat","pos":"NOM"} ,
		{"word":"salaud","word_nosc":"salaud","lemma":"salaud","pos":"NOM"} ,
		{"word":"salauds","word_nosc":"salauds","lemma":"salaud","pos":"NOM"} ,
		{"word":"saleté","word_nosc":"salete","lemma":"saleté","pos":"NOM"} ,
		{"word":"saletés","word_nosc":"saletes","lemma":"saleté","pos":"NOM"} ,
		{"word":"saleur","word_nosc":"saleur","lemma":"saleur","pos":"NOM"} ,
		{"word":"salicaires","word_nosc":"salicaires","lemma":"salicaire","pos":"NOM"} ,
		{"word":"salicine","word_nosc":"salicine","lemma":"salicine","pos":"NOM"} ,
		{"word":"salicorne","word_nosc":"salicorne","lemma":"salicorne","pos":"NOM"} ,
		{"word":"saligaud","word_nosc":"saligaud","lemma":"saligaud","pos":"NOM"} ,
		{"word":"saligauds","word_nosc":"saligauds","lemma":"saligaud","pos":"NOM"} ,
		{"word":"saline","word_nosc":"saline","lemma":"saline","pos":"NOM"} ,
		{"word":"salines","word_nosc":"salines","lemma":"salin","pos":"NOM"} ,
		{"word":"salinité","word_nosc":"salinite","lemma":"salinité","pos":"NOM"} ,
		{"word":"salins","word_nosc":"salins","lemma":"salin","pos":"NOM"} ,
		{"word":"salissure","word_nosc":"salissure","lemma":"salissure","pos":"NOM"} ,
		{"word":"salissures","word_nosc":"salissures","lemma":"salissure","pos":"NOM"} ,
		{"word":"salivation","word_nosc":"salivation","lemma":"salivation","pos":"NOM"} ,
		{"word":"salive","word_nosc":"salive","lemma":"salive","pos":"NOM"} ,
		{"word":"salives","word_nosc":"salives","lemma":"salive","pos":"NOM"} ,
		{"word":"saliveur","word_nosc":"saliveur","lemma":"saliveur","pos":"NOM"} ,
		{"word":"salière","word_nosc":"saliere","lemma":"salière","pos":"NOM"} ,
		{"word":"salières","word_nosc":"salieres","lemma":"salière","pos":"NOM"} ,
		{"word":"salle","word_nosc":"salle","lemma":"salle","pos":"NOM"} ,
		{"word":"salles","word_nosc":"salles","lemma":"salle","pos":"NOM"} ,
		{"word":"salmigondis","word_nosc":"salmigondis","lemma":"salmigondis","pos":"NOM"} ,
		{"word":"salmis","word_nosc":"salmis","lemma":"salmis","pos":"NOM"} ,
		{"word":"salmonelle","word_nosc":"salmonelle","lemma":"salmonelle","pos":"NOM"} ,
		{"word":"salmonellose","word_nosc":"salmonellose","lemma":"salmonellose","pos":"NOM"} ,
		{"word":"salmonidé","word_nosc":"salmonide","lemma":"salmonidé","pos":"NOM"} ,
		{"word":"saloir","word_nosc":"saloir","lemma":"saloir","pos":"NOM"} ,
		{"word":"saloirs","word_nosc":"saloirs","lemma":"saloir","pos":"NOM"} ,
		{"word":"salon","word_nosc":"salon","lemma":"salon","pos":"NOM"} ,
		{"word":"salonnard","word_nosc":"salonnard","lemma":"salonnard","pos":"NOM"} ,
		{"word":"salonniers","word_nosc":"salonniers","lemma":"salonnier","pos":"NOM"} ,
		{"word":"salonnières","word_nosc":"salonnieres","lemma":"salonnière","pos":"NOM"} ,
		{"word":"salons","word_nosc":"salons","lemma":"salon","pos":"NOM"} ,
		{"word":"saloon","word_nosc":"saloon","lemma":"saloon","pos":"NOM"} ,
		{"word":"saloons","word_nosc":"saloons","lemma":"saloon","pos":"NOM"} ,
		{"word":"salop","word_nosc":"salop","lemma":"salop","pos":"NOM"} ,
		{"word":"salopard","word_nosc":"salopard","lemma":"salopard","pos":"NOM"} ,
		{"word":"salopards","word_nosc":"salopards","lemma":"salopard","pos":"NOM"} ,
		{"word":"salope","word_nosc":"salope","lemma":"salope","pos":"NOM"} ,
		{"word":"saloperie","word_nosc":"saloperie","lemma":"saloperie","pos":"NOM"} ,
		{"word":"saloperies","word_nosc":"saloperies","lemma":"saloperie","pos":"NOM"} ,
		{"word":"salopes","word_nosc":"salopes","lemma":"salope","pos":"NOM"} ,
		{"word":"salopette","word_nosc":"salopette","lemma":"salopette","pos":"NOM"} ,
		{"word":"salopettes","word_nosc":"salopettes","lemma":"salopette","pos":"NOM"} ,
		{"word":"salopiaud","word_nosc":"salopiaud","lemma":"salopiaud","pos":"NOM"} ,
		{"word":"salopiauds","word_nosc":"salopiauds","lemma":"salopiaud","pos":"NOM"} ,
		{"word":"salopiaux","word_nosc":"salopiaux","lemma":"salopiau","pos":"NOM"} ,
		{"word":"salopiot","word_nosc":"salopiot","lemma":"salopiot","pos":"NOM"} ,
		{"word":"salopiots","word_nosc":"salopiots","lemma":"salopiot","pos":"NOM"} ,
		{"word":"salops","word_nosc":"salops","lemma":"salop","pos":"NOM"} ,
		{"word":"salpicon","word_nosc":"salpicon","lemma":"salpicon","pos":"NOM"} ,
		{"word":"salpingite","word_nosc":"salpingite","lemma":"salpingite","pos":"NOM"} ,
		{"word":"salpêtre","word_nosc":"salpetre","lemma":"salpêtre","pos":"NOM"} ,
		{"word":"salpêtrière","word_nosc":"salpetriere","lemma":"salpêtrière","pos":"NOM"} ,
		{"word":"sals","word_nosc":"sals","lemma":"sal","pos":"NOM"} ,
		{"word":"salsa","word_nosc":"salsa","lemma":"salsa","pos":"NOM"} ,
		{"word":"salsepareille","word_nosc":"salsepareille","lemma":"salsepareille","pos":"NOM"} ,
		{"word":"salsifis","word_nosc":"salsifis","lemma":"salsifis","pos":"NOM"} ,
		{"word":"saltimbanque","word_nosc":"saltimbanque","lemma":"saltimbanque","pos":"NOM"} ,
		{"word":"saltimbanques","word_nosc":"saltimbanques","lemma":"saltimbanque","pos":"NOM"} ,
		{"word":"salto","word_nosc":"salto","lemma":"salto","pos":"NOM"} ,
		{"word":"salubrité","word_nosc":"salubrite","lemma":"salubrité","pos":"NOM"} ,
		{"word":"salueur","word_nosc":"salueur","lemma":"salueur","pos":"NOM"} ,
		{"word":"salut","word_nosc":"salut","lemma":"salut","pos":"NOM"} ,
		{"word":"salutation","word_nosc":"salutation","lemma":"salutation","pos":"NOM"} ,
		{"word":"salutations","word_nosc":"salutations","lemma":"salutation","pos":"NOM"} ,
		{"word":"salutiste","word_nosc":"salutiste","lemma":"salutiste","pos":"NOM"} ,
		{"word":"salutistes","word_nosc":"salutistes","lemma":"salutiste","pos":"NOM"} ,
		{"word":"saluts","word_nosc":"saluts","lemma":"salut","pos":"NOM"} ,
		{"word":"salvadorien","word_nosc":"salvadorien","lemma":"salvadorien","pos":"NOM"} ,
		{"word":"salvadorienne","word_nosc":"salvadorienne","lemma":"salvadorienne","pos":"NOM"} ,
		{"word":"salvadoriens","word_nosc":"salvadoriens","lemma":"salvadorien","pos":"NOM"} ,
		{"word":"salvation","word_nosc":"salvation","lemma":"salvation","pos":"NOM"} ,
		{"word":"salvatrice","word_nosc":"salvatrice","lemma":"sauveur","pos":"NOM"} ,
		{"word":"salve","word_nosc":"salve","lemma":"salve","pos":"NOM"} ,
		{"word":"salves","word_nosc":"salves","lemma":"salve","pos":"NOM"} ,
		{"word":"salé","word_nosc":"sale","lemma":"salé","pos":"NOM"} ,
		{"word":"salés","word_nosc":"sales","lemma":"salé","pos":"NOM"} ,
		{"word":"samaras","word_nosc":"samaras","lemma":"samara","pos":"NOM"} ,
		{"word":"samaritain","word_nosc":"samaritain","lemma":"samaritain","pos":"NOM"} ,
		{"word":"samaritaine","word_nosc":"samaritaine","lemma":"samaritaine","pos":"NOM"} ,
		{"word":"samaritains","word_nosc":"samaritains","lemma":"samaritain","pos":"NOM"} ,
		{"word":"samba","word_nosc":"samba","lemma":"samba","pos":"NOM"} ,
		{"word":"sambenito","word_nosc":"sambenito","lemma":"sambenito","pos":"NOM"} ,
		{"word":"sambo","word_nosc":"sambo","lemma":"sambo","pos":"NOM"} ,
		{"word":"samedi","word_nosc":"samedi","lemma":"samedi","pos":"NOM"} ,
		{"word":"samedis","word_nosc":"samedis","lemma":"samedi","pos":"NOM"} ,
		{"word":"samizdats","word_nosc":"samizdats","lemma":"samizdat","pos":"NOM"} ,
		{"word":"sammy","word_nosc":"sammy","lemma":"sammy","pos":"NOM"} ,
		{"word":"samouraï","word_nosc":"samourai","lemma":"samouraï","pos":"NOM"} ,
		{"word":"samouraïs","word_nosc":"samourais","lemma":"samouraï","pos":"NOM"} ,
		{"word":"samovar","word_nosc":"samovar","lemma":"samovar","pos":"NOM"} ,
		{"word":"samovars","word_nosc":"samovars","lemma":"samovar","pos":"NOM"} ,
		{"word":"samoyède","word_nosc":"samoyede","lemma":"samoyède","pos":"NOM"} ,
		{"word":"sampan","word_nosc":"sampan","lemma":"sampan","pos":"NOM"} ,
		{"word":"sampang","word_nosc":"sampang","lemma":"sampang","pos":"NOM"} ,
		{"word":"sampangs","word_nosc":"sampangs","lemma":"sampang","pos":"NOM"} ,
		{"word":"sample","word_nosc":"sample","lemma":"sample","pos":"NOM"} ,
		{"word":"sampler","word_nosc":"sampler","lemma":"sampler","pos":"NOM"} ,
		{"word":"samurai","word_nosc":"samurai","lemma":"samurai","pos":"NOM"} ,
		{"word":"san francisco","word_nosc":"san francisco","lemma":"san francisco","pos":"NOM"} ,
		{"word":"sana","word_nosc":"sana","lemma":"sana","pos":"NOM"} ,
		{"word":"sanas","word_nosc":"sanas","lemma":"sana","pos":"NOM"} ,
		{"word":"sanatorium","word_nosc":"sanatorium","lemma":"sanatorium","pos":"NOM"} ,
		{"word":"sanatoriums","word_nosc":"sanatoriums","lemma":"sanatorium","pos":"NOM"} ,
		{"word":"sancerre","word_nosc":"sancerre","lemma":"sancerre","pos":"NOM"} ,
		{"word":"sanctification","word_nosc":"sanctification","lemma":"sanctification","pos":"NOM"} ,
		{"word":"sanction","word_nosc":"sanction","lemma":"sanction","pos":"NOM"} ,
		{"word":"sanctions","word_nosc":"sanctions","lemma":"sanction","pos":"NOM"} ,
		{"word":"sanctuaire","word_nosc":"sanctuaire","lemma":"sanctuaire","pos":"NOM"} ,
		{"word":"sanctuaires","word_nosc":"sanctuaires","lemma":"sanctuaire","pos":"NOM"} ,
		{"word":"sanctus","word_nosc":"sanctus","lemma":"sanctus","pos":"NOM"} ,
		{"word":"sandale","word_nosc":"sandale","lemma":"sandal","pos":"NOM"} ,
		{"word":"sandales","word_nosc":"sandales","lemma":"sandal","pos":"NOM"} ,
		{"word":"sandales","word_nosc":"sandales","lemma":"sandale","pos":"NOM"} ,
		{"word":"sandalettes","word_nosc":"sandalettes","lemma":"sandalette","pos":"NOM"} ,
		{"word":"sandalier","word_nosc":"sandalier","lemma":"sandalier","pos":"NOM"} ,
		{"word":"sanderling","word_nosc":"sanderling","lemma":"sanderling","pos":"NOM"} ,
		{"word":"sandinistes","word_nosc":"sandinistes","lemma":"sandiniste","pos":"NOM"} ,
		{"word":"sandjak","word_nosc":"sandjak","lemma":"sandjak","pos":"NOM"} ,
		{"word":"sandow","word_nosc":"sandow","lemma":"sandow","pos":"NOM"} ,
		{"word":"sandows","word_nosc":"sandows","lemma":"sandow","pos":"NOM"} ,
		{"word":"sandre","word_nosc":"sandre","lemma":"sandre","pos":"NOM"} ,
		{"word":"sandres","word_nosc":"sandres","lemma":"sandre","pos":"NOM"} ,
		{"word":"sandwich","word_nosc":"sandwich","lemma":"sandwich","pos":"NOM"} ,
		{"word":"sandwiches","word_nosc":"sandwiches","lemma":"sandwiche","pos":"NOM"} ,
		{"word":"sandwichs","word_nosc":"sandwichs","lemma":"sandwich","pos":"NOM"} ,
		{"word":"sang","word_nosc":"sang","lemma":"sang","pos":"NOM"} ,
		{"word":"sang-froid","word_nosc":"sang-froid","lemma":"sang-froid","pos":"NOM"} ,
		{"word":"sangle","word_nosc":"sangle","lemma":"sangle","pos":"NOM"} ,
		{"word":"sangles","word_nosc":"sangles","lemma":"sangle","pos":"NOM"} ,
		{"word":"sanglier","word_nosc":"sanglier","lemma":"sanglier","pos":"NOM"} ,
		{"word":"sangliers","word_nosc":"sangliers","lemma":"sanglier","pos":"NOM"} ,
		{"word":"sanglot","word_nosc":"sanglot","lemma":"sanglot","pos":"NOM"} ,
		{"word":"sanglots","word_nosc":"sanglots","lemma":"sanglot","pos":"NOM"} ,
		{"word":"sangria","word_nosc":"sangria","lemma":"sangria","pos":"NOM"} ,
		{"word":"sangs","word_nosc":"sangs","lemma":"sang","pos":"NOM"} ,
		{"word":"sangsue","word_nosc":"sangsue","lemma":"sangsue","pos":"NOM"} ,
		{"word":"sangsues","word_nosc":"sangsues","lemma":"sangsue","pos":"NOM"} ,
		{"word":"sanguin","word_nosc":"sanguin","lemma":"sanguin","pos":"NOM"} ,
		{"word":"sanguine","word_nosc":"sanguine","lemma":"sanguine","pos":"NOM"} ,
		{"word":"sanguines","word_nosc":"sanguines","lemma":"sanguine","pos":"NOM"} ,
		{"word":"sanguins","word_nosc":"sanguins","lemma":"sanguin","pos":"NOM"} ,
		{"word":"sanhédrin","word_nosc":"sanhedrin","lemma":"sanhédrin","pos":"NOM"} ,
		{"word":"sanie","word_nosc":"sanie","lemma":"sanie","pos":"NOM"} ,
		{"word":"sanies","word_nosc":"sanies","lemma":"sanie","pos":"NOM"} ,
		{"word":"sanitaire","word_nosc":"sanitaire","lemma":"sanitaire","pos":"NOM"} ,
		{"word":"sanitaires","word_nosc":"sanitaires","lemma":"sanitaire","pos":"NOM"} ,
		{"word":"sans-culotte","word_nosc":"sans-culotte","lemma":"sans-culotte","pos":"NOM"} ,
		{"word":"sans-culottes","word_nosc":"sans-culottes","lemma":"sans-culotte","pos":"NOM"} ,
		{"word":"sans-culottides","word_nosc":"sans-culottides","lemma":"sans-culottide","pos":"NOM"} ,
		{"word":"sans-faute","word_nosc":"sans-faute","lemma":"sans-faute","pos":"NOM"} ,
		{"word":"sans-façon","word_nosc":"sans-facon","lemma":"sans-façon","pos":"NOM"} ,
		{"word":"sans-fil","word_nosc":"sans-fil","lemma":"sans-fil","pos":"NOM"} ,
		{"word":"sans-filiste","word_nosc":"sans-filiste","lemma":"sans-filiste","pos":"NOM"} ,
		{"word":"sans-filistes","word_nosc":"sans-filistes","lemma":"sans-filiste","pos":"NOM"} ,
		{"word":"sans-grade","word_nosc":"sans-grade","lemma":"sans-grade","pos":"NOM"} ,
		{"word":"sans-pareil","word_nosc":"sans-pareil","lemma":"sans-pareil","pos":"NOM"} ,
		{"word":"sanscrit","word_nosc":"sanscrit","lemma":"sanscrit","pos":"NOM"} ,
		{"word":"sanskrit","word_nosc":"sanskrit","lemma":"sanskrit","pos":"NOM"} ,
		{"word":"sansonnet","word_nosc":"sansonnet","lemma":"sansonnet","pos":"NOM"} ,
		{"word":"sansonnets","word_nosc":"sansonnets","lemma":"sansonnet","pos":"NOM"} ,
		{"word":"santal","word_nosc":"santal","lemma":"santal","pos":"NOM"} ,
		{"word":"santals","word_nosc":"santals","lemma":"santal","pos":"NOM"} ,
		{"word":"santiag","word_nosc":"santiag","lemma":"santiag","pos":"NOM"} ,
		{"word":"santiags","word_nosc":"santiags","lemma":"santiag","pos":"NOM"} ,
		{"word":"santoche","word_nosc":"santoche","lemma":"santoche","pos":"NOM"} ,
		{"word":"santon","word_nosc":"santon","lemma":"santon","pos":"NOM"} ,
		{"word":"santons","word_nosc":"santons","lemma":"santon","pos":"NOM"} ,
		{"word":"santé","word_nosc":"sante","lemma":"santé","pos":"NOM"} ,
		{"word":"santés","word_nosc":"santes","lemma":"santé","pos":"NOM"} ,
		{"word":"saoudien","word_nosc":"saoudien","lemma":"saoudien","pos":"NOM"} ,
		{"word":"saoudienne","word_nosc":"saoudienne","lemma":"saoudienne","pos":"NOM"} ,
		{"word":"saoudiennes","word_nosc":"saoudiennes","lemma":"saoudienne","pos":"NOM"} ,
		{"word":"saoudiens","word_nosc":"saoudiens","lemma":"saoudien","pos":"NOM"} ,
		{"word":"saoulard","word_nosc":"saoulard","lemma":"saoulard","pos":"NOM"} ,
		{"word":"saoulerie","word_nosc":"saoulerie","lemma":"saoulerie","pos":"NOM"} ,
		{"word":"saouleries","word_nosc":"saouleries","lemma":"saoulerie","pos":"NOM"} ,
		{"word":"sapajou","word_nosc":"sapajou","lemma":"sapajou","pos":"NOM"} ,
		{"word":"sapajous","word_nosc":"sapajous","lemma":"sapajou","pos":"NOM"} ,
		{"word":"sape","word_nosc":"sape","lemma":"sape","pos":"NOM"} ,
		{"word":"sapement","word_nosc":"sapement","lemma":"sapement","pos":"NOM"} ,
		{"word":"sapements","word_nosc":"sapements","lemma":"sapement","pos":"NOM"} ,
		{"word":"saperlotte","word_nosc":"saperlotte","lemma":"saperlotte","pos":"NOM"} ,
		{"word":"sapes","word_nosc":"sapes","lemma":"sape","pos":"NOM"} ,
		{"word":"sapeur","word_nosc":"sapeur","lemma":"sapeur","pos":"NOM"} ,
		{"word":"sapeur-pompier","word_nosc":"sapeur-pompier","lemma":"sapeur-pompier","pos":"NOM"} ,
		{"word":"sapeurs","word_nosc":"sapeurs","lemma":"sapeur","pos":"NOM"} ,
		{"word":"sapeurs-pompiers","word_nosc":"sapeurs-pompiers","lemma":"sapeur-pompier","pos":"NOM"} ,
		{"word":"saphir","word_nosc":"saphir","lemma":"saphir","pos":"NOM"} ,
		{"word":"saphirs","word_nosc":"saphirs","lemma":"saphir","pos":"NOM"} ,
		{"word":"saphisme","word_nosc":"saphisme","lemma":"saphisme","pos":"NOM"} ,
		{"word":"sapidité","word_nosc":"sapidite","lemma":"sapidité","pos":"NOM"} ,
		{"word":"sapience","word_nosc":"sapience","lemma":"sapience","pos":"NOM"} ,
		{"word":"sapin","word_nosc":"sapin","lemma":"sapin","pos":"NOM"} ,
		{"word":"sapines","word_nosc":"sapines","lemma":"sapine","pos":"NOM"} ,
		{"word":"sapinette","word_nosc":"sapinette","lemma":"sapinette","pos":"NOM"} ,
		{"word":"sapinettes","word_nosc":"sapinettes","lemma":"sapinette","pos":"NOM"} ,
		{"word":"sapinière","word_nosc":"sapiniere","lemma":"sapinière","pos":"NOM"} ,
		{"word":"sapinières","word_nosc":"sapinieres","lemma":"sapinière","pos":"NOM"} ,
		{"word":"sapins","word_nosc":"sapins","lemma":"sapin","pos":"NOM"} ,
		{"word":"saponaires","word_nosc":"saponaires","lemma":"saponaire","pos":"NOM"} ,
		{"word":"saponification","word_nosc":"saponification","lemma":"saponification","pos":"NOM"} ,
		{"word":"saponite","word_nosc":"saponite","lemma":"saponite","pos":"NOM"} ,
		{"word":"sapotille","word_nosc":"sapotille","lemma":"sapotille","pos":"NOM"} ,
		{"word":"sapotillier","word_nosc":"sapotillier","lemma":"sapotillier","pos":"NOM"} ,
		{"word":"sar","word_nosc":"sar","lemma":"sar","pos":"NOM"} ,
		{"word":"sara","word_nosc":"sara","lemma":"sara","pos":"NOM"} ,
		{"word":"sarabande","word_nosc":"sarabande","lemma":"sarabande","pos":"NOM"} ,
		{"word":"sarabandes","word_nosc":"sarabandes","lemma":"sarabande","pos":"NOM"} ,
		{"word":"sarbacane","word_nosc":"sarbacane","lemma":"sarbacane","pos":"NOM"} ,
		{"word":"sarbacanes","word_nosc":"sarbacanes","lemma":"sarbacane","pos":"NOM"} ,
		{"word":"sarcasme","word_nosc":"sarcasme","lemma":"sarcasme","pos":"NOM"} ,
		{"word":"sarcasmes","word_nosc":"sarcasmes","lemma":"sarcasme","pos":"NOM"} ,
		{"word":"sarcelle","word_nosc":"sarcelle","lemma":"sarcelle","pos":"NOM"} ,
		{"word":"sarcelles","word_nosc":"sarcelles","lemma":"sarcelle","pos":"NOM"} ,
		{"word":"sarcine","word_nosc":"sarcine","lemma":"sarcine","pos":"NOM"} ,
		{"word":"sarclage","word_nosc":"sarclage","lemma":"sarclage","pos":"NOM"} ,
		{"word":"sarclette","word_nosc":"sarclette","lemma":"sarclette","pos":"NOM"} ,
		{"word":"sarcleuse","word_nosc":"sarcleuse","lemma":"sarcleur","pos":"NOM"} ,
		{"word":"sarcloir","word_nosc":"sarcloir","lemma":"sarcloir","pos":"NOM"} ,
		{"word":"sarcome","word_nosc":"sarcome","lemma":"sarcome","pos":"NOM"} ,
		{"word":"sarcophage","word_nosc":"sarcophage","lemma":"sarcophage","pos":"NOM"} ,
		{"word":"sarcophages","word_nosc":"sarcophages","lemma":"sarcophage","pos":"NOM"} ,
		{"word":"sarcoïdose","word_nosc":"sarcoidose","lemma":"sarcoïdose","pos":"NOM"} ,
		{"word":"sardanes","word_nosc":"sardanes","lemma":"sardane","pos":"NOM"} ,
		{"word":"sarde","word_nosc":"sarde","lemma":"sarde","pos":"NOM"} ,
		{"word":"sardine","word_nosc":"sardine","lemma":"sardine","pos":"NOM"} ,
		{"word":"sardines","word_nosc":"sardines","lemma":"sardine","pos":"NOM"} ,
		{"word":"sardiniers","word_nosc":"sardiniers","lemma":"sardinier","pos":"NOM"} ,
		{"word":"sardoine","word_nosc":"sardoine","lemma":"sardoine","pos":"NOM"} ,
		{"word":"sargasse","word_nosc":"sargasse","lemma":"sargasse","pos":"NOM"} ,
		{"word":"sargasses","word_nosc":"sargasses","lemma":"sargasse","pos":"NOM"} ,
		{"word":"sari","word_nosc":"sari","lemma":"sari","pos":"NOM"} ,
		{"word":"sarigue","word_nosc":"sarigue","lemma":"sarigue","pos":"NOM"} ,
		{"word":"saris","word_nosc":"saris","lemma":"sari","pos":"NOM"} ,
		{"word":"sarment","word_nosc":"sarment","lemma":"sarment","pos":"NOM"} ,
		{"word":"sarments","word_nosc":"sarments","lemma":"sarment","pos":"NOM"} ,
		{"word":"sarong","word_nosc":"sarong","lemma":"sarong","pos":"NOM"} ,
		{"word":"saroual","word_nosc":"saroual","lemma":"saroual","pos":"NOM"} ,
		{"word":"sarouel","word_nosc":"sarouel","lemma":"sarouel","pos":"NOM"} ,
		{"word":"sarracénie","word_nosc":"sarracenie","lemma":"sarracénie","pos":"NOM"} ,
		{"word":"sarrasin","word_nosc":"sarrasin","lemma":"sarrasin","pos":"NOM"} ,
		{"word":"sarrasine","word_nosc":"sarrasine","lemma":"sarrasin","pos":"NOM"} ,
		{"word":"sarrasins","word_nosc":"sarrasins","lemma":"sarrasin","pos":"NOM"} ,
		{"word":"sarrau","word_nosc":"sarrau","lemma":"sarrau","pos":"NOM"} ,
		{"word":"sarraus","word_nosc":"sarraus","lemma":"sarrau","pos":"NOM"} ,
		{"word":"sarreau","word_nosc":"sarreau","lemma":"sarreau","pos":"NOM"} ,
		{"word":"sarriette","word_nosc":"sarriette","lemma":"sarriette","pos":"NOM"} ,
		{"word":"sarrois","word_nosc":"sarrois","lemma":"sarrois","pos":"NOM"} ,
		{"word":"sarrussophone","word_nosc":"sarrussophone","lemma":"sarrussophone","pos":"NOM"} ,
		{"word":"sas","word_nosc":"sas","lemma":"sas","pos":"NOM"} ,
		{"word":"sashimi","word_nosc":"sashimi","lemma":"sashimi","pos":"NOM"} ,
		{"word":"sassafras","word_nosc":"sassafras","lemma":"sassafras","pos":"NOM"} ,
		{"word":"sasseur","word_nosc":"sasseur","lemma":"sasseur","pos":"NOM"} ,
		{"word":"satan","word_nosc":"satan","lemma":"satan","pos":"NOM"} ,
		{"word":"satanisme","word_nosc":"satanisme","lemma":"satanisme","pos":"NOM"} ,
		{"word":"satellite","word_nosc":"satellite","lemma":"satellite","pos":"NOM"} ,
		{"word":"satellites","word_nosc":"satellites","lemma":"satellite","pos":"NOM"} ,
		{"word":"satellites-espions","word_nosc":"satellites-espions","lemma":"satellites-espion","pos":"NOM"} ,
		{"word":"sati","word_nosc":"sati","lemma":"sati","pos":"NOM"} ,
		{"word":"satiation","word_nosc":"satiation","lemma":"satiation","pos":"NOM"} ,
		{"word":"satin","word_nosc":"satin","lemma":"satin","pos":"NOM"} ,
		{"word":"satinette","word_nosc":"satinette","lemma":"satinette","pos":"NOM"} ,
		{"word":"satins","word_nosc":"satins","lemma":"satin","pos":"NOM"} ,
		{"word":"satire","word_nosc":"satire","lemma":"satire","pos":"NOM"} ,
		{"word":"satires","word_nosc":"satires","lemma":"satire","pos":"NOM"} ,
		{"word":"satiriste","word_nosc":"satiriste","lemma":"satiriste","pos":"NOM"} ,
		{"word":"satisfaction","word_nosc":"satisfaction","lemma":"satisfaction","pos":"NOM"} ,
		{"word":"satisfactions","word_nosc":"satisfactions","lemma":"satisfaction","pos":"NOM"} ,
		{"word":"satisfecit","word_nosc":"satisfecit","lemma":"satisfecit","pos":"NOM"} ,
		{"word":"satiété","word_nosc":"satiete","lemma":"satiété","pos":"NOM"} ,
		{"word":"satrape","word_nosc":"satrape","lemma":"satrape","pos":"NOM"} ,
		{"word":"satrapes","word_nosc":"satrapes","lemma":"satrape","pos":"NOM"} ,
		{"word":"satrapie","word_nosc":"satrapie","lemma":"satrapie","pos":"NOM"} ,
		{"word":"saturation","word_nosc":"saturation","lemma":"saturation","pos":"NOM"} ,
		{"word":"saturnales","word_nosc":"saturnales","lemma":"saturnales","pos":"NOM"} ,
		{"word":"saturniennes","word_nosc":"saturniennes","lemma":"saturnienne","pos":"NOM"} ,
		{"word":"saturnisme","word_nosc":"saturnisme","lemma":"saturnisme","pos":"NOM"} ,
		{"word":"satyre","word_nosc":"satyre","lemma":"satyre","pos":"NOM"} ,
		{"word":"satyres","word_nosc":"satyres","lemma":"satyre","pos":"NOM"} ,
		{"word":"sauce","word_nosc":"sauce","lemma":"sauce","pos":"NOM"} ,
		{"word":"sauces","word_nosc":"sauces","lemma":"sauce","pos":"NOM"} ,
		{"word":"saucier","word_nosc":"saucier","lemma":"saucier","pos":"NOM"} ,
		{"word":"sauciflard","word_nosc":"sauciflard","lemma":"sauciflard","pos":"NOM"} ,
		{"word":"saucisse","word_nosc":"saucisse","lemma":"saucisse","pos":"NOM"} ,
		{"word":"saucisses","word_nosc":"saucisses","lemma":"saucisse","pos":"NOM"} ,
		{"word":"saucisson","word_nosc":"saucisson","lemma":"saucisson","pos":"NOM"} ,
		{"word":"saucissonnage","word_nosc":"saucissonnage","lemma":"saucissonnage","pos":"NOM"} ,
		{"word":"saucissonnages","word_nosc":"saucissonnages","lemma":"saucissonnage","pos":"NOM"} ,
		{"word":"saucissons","word_nosc":"saucissons","lemma":"saucisson","pos":"NOM"} ,
		{"word":"saucière","word_nosc":"sauciere","lemma":"saucière","pos":"NOM"} ,
		{"word":"saucières","word_nosc":"saucieres","lemma":"saucière","pos":"NOM"} ,
		{"word":"saucée","word_nosc":"saucee","lemma":"saucée","pos":"NOM"} ,
		{"word":"sauf-conduit","word_nosc":"sauf-conduit","lemma":"sauf-conduit","pos":"NOM"} ,
		{"word":"sauf-conduits","word_nosc":"sauf-conduits","lemma":"sauf-conduit","pos":"NOM"} ,
		{"word":"sauge","word_nosc":"sauge","lemma":"sauge","pos":"NOM"} ,
		{"word":"saulaie","word_nosc":"saulaie","lemma":"saulaie","pos":"NOM"} ,
		{"word":"saulaies","word_nosc":"saulaies","lemma":"saulaie","pos":"NOM"} ,
		{"word":"saule","word_nosc":"saule","lemma":"saule","pos":"NOM"} ,
		{"word":"saules","word_nosc":"saules","lemma":"saule","pos":"NOM"} ,
		{"word":"saulnier","word_nosc":"saulnier","lemma":"saulnier","pos":"NOM"} ,
		{"word":"saumon","word_nosc":"saumon","lemma":"saumon","pos":"NOM"} ,
		{"word":"saumoneau","word_nosc":"saumoneau","lemma":"saumoneau","pos":"NOM"} ,
		{"word":"saumonette","word_nosc":"saumonette","lemma":"saumonette","pos":"NOM"} ,
		{"word":"saumons","word_nosc":"saumons","lemma":"saumon","pos":"NOM"} ,
		{"word":"saumurage","word_nosc":"saumurage","lemma":"saumurage","pos":"NOM"} ,
		{"word":"saumure","word_nosc":"saumure","lemma":"saumure","pos":"NOM"} ,
		{"word":"sauna","word_nosc":"sauna","lemma":"sauna","pos":"NOM"} ,
		{"word":"saunas","word_nosc":"saunas","lemma":"sauna","pos":"NOM"} ,
		{"word":"saunier","word_nosc":"saunier","lemma":"saunier","pos":"NOM"} ,
		{"word":"sauniers","word_nosc":"sauniers","lemma":"saunier","pos":"NOM"} ,
		{"word":"saunières","word_nosc":"saunieres","lemma":"saunière","pos":"NOM"} ,
		{"word":"saupoudrage","word_nosc":"saupoudrage","lemma":"saupoudrage","pos":"NOM"} ,
		{"word":"saupoudreur","word_nosc":"saupoudreur","lemma":"saupoudreur","pos":"NOM"} ,
		{"word":"saura","word_nosc":"saura","lemma":"saura","pos":"NOM"} ,
		{"word":"saurien","word_nosc":"saurien","lemma":"saurien","pos":"NOM"} ,
		{"word":"sauriens","word_nosc":"sauriens","lemma":"saurien","pos":"NOM"} ,
		{"word":"saurin","word_nosc":"saurin","lemma":"saurin","pos":"NOM"} ,
		{"word":"saussaies","word_nosc":"saussaies","lemma":"saussaie","pos":"NOM"} ,
		{"word":"saut","word_nosc":"saut","lemma":"saut","pos":"NOM"} ,
		{"word":"saut-de-lit","word_nosc":"saut-de-lit","lemma":"saut-de-lit","pos":"NOM"} ,
		{"word":"saute","word_nosc":"saute","lemma":"saute","pos":"NOM"} ,
		{"word":"saute-mouton","word_nosc":"saute-mouton","lemma":"saute-mouton","pos":"NOM"} ,
		{"word":"saute-ruisseau","word_nosc":"saute-ruisseau","lemma":"saute-ruisseau","pos":"NOM"} ,
		{"word":"sautelle","word_nosc":"sautelle","lemma":"sautelle","pos":"NOM"} ,
		{"word":"sauterelle","word_nosc":"sauterelle","lemma":"sauterelle","pos":"NOM"} ,
		{"word":"sauterelles","word_nosc":"sauterelles","lemma":"sauterelle","pos":"NOM"} ,
		{"word":"sauterelles","word_nosc":"sauterelles","lemma":"sauterelles","pos":"NOM"} ,
		{"word":"sauterie","word_nosc":"sauterie","lemma":"sauterie","pos":"NOM"} ,
		{"word":"sauteries","word_nosc":"sauteries","lemma":"sauterie","pos":"NOM"} ,
		{"word":"sauternes","word_nosc":"sauternes","lemma":"sauternes","pos":"NOM"} ,
		{"word":"sautes","word_nosc":"sautes","lemma":"saute","pos":"NOM"} ,
		{"word":"sauteur","word_nosc":"sauteur","lemma":"sauteur","pos":"NOM"} ,
		{"word":"sauteurs","word_nosc":"sauteurs","lemma":"sauteur","pos":"NOM"} ,
		{"word":"sauteuse","word_nosc":"sauteuse","lemma":"sauteur","pos":"NOM"} ,
		{"word":"sauteuses","word_nosc":"sauteuses","lemma":"sauteur","pos":"NOM"} ,
		{"word":"sautillement","word_nosc":"sautillement","lemma":"sautillement","pos":"NOM"} ,
		{"word":"sautillements","word_nosc":"sautillements","lemma":"sautillement","pos":"NOM"} ,
		{"word":"sautoir","word_nosc":"sautoir","lemma":"sautoir","pos":"NOM"} ,
		{"word":"sautoirs","word_nosc":"sautoirs","lemma":"sautoir","pos":"NOM"} ,
		{"word":"sauton","word_nosc":"sauton","lemma":"sauton","pos":"NOM"} ,
		{"word":"sautons","word_nosc":"sautons","lemma":"sauton","pos":"NOM"} ,
		{"word":"sauts","word_nosc":"sauts","lemma":"saut","pos":"NOM"} ,
		{"word":"sauts-de-lit","word_nosc":"sauts-de-lit","lemma":"saut-de-lit","pos":"NOM"} ,
		{"word":"sauté","word_nosc":"saute","lemma":"sauté","pos":"NOM"} ,
		{"word":"sautés","word_nosc":"sautes","lemma":"sauté","pos":"NOM"} ,
		{"word":"sauvage","word_nosc":"sauvage","lemma":"sauvage","pos":"NOM"} ,
		{"word":"sauvageon","word_nosc":"sauvageon","lemma":"sauvageon","pos":"NOM"} ,
		{"word":"sauvageonne","word_nosc":"sauvageonne","lemma":"sauvageon","pos":"NOM"} ,
		{"word":"sauvageonnes","word_nosc":"sauvageonnes","lemma":"sauvageon","pos":"NOM"} ,
		{"word":"sauvageons","word_nosc":"sauvageons","lemma":"sauvageon","pos":"NOM"} ,
		{"word":"sauvagerie","word_nosc":"sauvagerie","lemma":"sauvagerie","pos":"NOM"} ,
		{"word":"sauvageries","word_nosc":"sauvageries","lemma":"sauvagerie","pos":"NOM"} ,
		{"word":"sauvages","word_nosc":"sauvages","lemma":"sauvage","pos":"NOM"} ,
		{"word":"sauvagesse","word_nosc":"sauvagesse","lemma":"sauvagesse","pos":"NOM"} ,
		{"word":"sauvagesses","word_nosc":"sauvagesses","lemma":"sauvagesse","pos":"NOM"} ,
		{"word":"sauvagin","word_nosc":"sauvagin","lemma":"sauvagin","pos":"NOM"} ,
		{"word":"sauvagine","word_nosc":"sauvagine","lemma":"sauvagin","pos":"NOM"} ,
		{"word":"sauvagines","word_nosc":"sauvagines","lemma":"sauvagin","pos":"NOM"} ,
		{"word":"sauve","word_nosc":"sauve","lemma":"sauve","pos":"NOM"} ,
		{"word":"sauve-qui-peut","word_nosc":"sauve-qui-peut","lemma":"sauve-qui-peut","pos":"NOM"} ,
		{"word":"sauvegarde","word_nosc":"sauvegarde","lemma":"sauvegarde","pos":"NOM"} ,
		{"word":"sauvegardes","word_nosc":"sauvegardes","lemma":"sauvegarde","pos":"NOM"} ,
		{"word":"sauvetage","word_nosc":"sauvetage","lemma":"sauvetage","pos":"NOM"} ,
		{"word":"sauvetages","word_nosc":"sauvetages","lemma":"sauvetage","pos":"NOM"} ,
		{"word":"sauveteur","word_nosc":"sauveteur","lemma":"sauveteur","pos":"NOM"} ,
		{"word":"sauveteurs","word_nosc":"sauveteurs","lemma":"sauveteur","pos":"NOM"} ,
		{"word":"sauveté","word_nosc":"sauvete","lemma":"sauveté","pos":"NOM"} ,
		{"word":"sauveur","word_nosc":"sauveur","lemma":"sauveur","pos":"NOM"} ,
		{"word":"sauveurs","word_nosc":"sauveurs","lemma":"sauveur","pos":"NOM"} ,
		{"word":"sauveuse","word_nosc":"sauveuse","lemma":"sauveur","pos":"NOM"} ,
		{"word":"sauvignon","word_nosc":"sauvignon","lemma":"sauvignon","pos":"NOM"} ,
		{"word":"savane","word_nosc":"savane","lemma":"savane","pos":"NOM"} ,
		{"word":"savanes","word_nosc":"savanes","lemma":"savane","pos":"NOM"} ,
		{"word":"savant","word_nosc":"savant","lemma":"savant","pos":"NOM"} ,
		{"word":"savantasses","word_nosc":"savantasses","lemma":"savantasse","pos":"NOM"} ,
		{"word":"savante","word_nosc":"savante","lemma":"savant","pos":"NOM"} ,
		{"word":"savants","word_nosc":"savants","lemma":"savant","pos":"NOM"} ,
		{"word":"savarin","word_nosc":"savarin","lemma":"savarin","pos":"NOM"} ,
		{"word":"savate","word_nosc":"savate","lemma":"savate","pos":"NOM"} ,
		{"word":"savates","word_nosc":"savates","lemma":"savate","pos":"NOM"} ,
		{"word":"savetier","word_nosc":"savetier","lemma":"savetier","pos":"NOM"} ,
		{"word":"savetiers","word_nosc":"savetiers","lemma":"savetier","pos":"NOM"} ,
		{"word":"saveur","word_nosc":"saveur","lemma":"saveur","pos":"NOM"} ,
		{"word":"saveurs","word_nosc":"saveurs","lemma":"saveur","pos":"NOM"} ,
		{"word":"savoir","word_nosc":"savoir","lemma":"savoir","pos":"NOM"} ,
		{"word":"savoir-faire","word_nosc":"savoir-faire","lemma":"savoir-faire","pos":"NOM"} ,
		{"word":"savoir-vivre","word_nosc":"savoir-vivre","lemma":"savoir-vivre","pos":"NOM"} ,
		{"word":"savoirs","word_nosc":"savoirs","lemma":"savoir","pos":"NOM"} ,
		{"word":"savon","word_nosc":"savon","lemma":"savon","pos":"NOM"} ,
		{"word":"savonnage","word_nosc":"savonnage","lemma":"savonnage","pos":"NOM"} ,
		{"word":"savonnages","word_nosc":"savonnages","lemma":"savonnage","pos":"NOM"} ,
		{"word":"savonneries","word_nosc":"savonneries","lemma":"savonnerie","pos":"NOM"} ,
		{"word":"savonnette","word_nosc":"savonnette","lemma":"savonnette","pos":"NOM"} ,
		{"word":"savonnettes","word_nosc":"savonnettes","lemma":"savonnette","pos":"NOM"} ,
		{"word":"savonnée","word_nosc":"savonnee","lemma":"savonnée","pos":"NOM"} ,
		{"word":"savons","word_nosc":"savons","lemma":"savon","pos":"NOM"} ,
		{"word":"savoyard","word_nosc":"savoyard","lemma":"savoyard","pos":"NOM"} ,
		{"word":"savoyarde","word_nosc":"savoyarde","lemma":"savoyard","pos":"NOM"} ,
		{"word":"savoyards","word_nosc":"savoyards","lemma":"savoyard","pos":"NOM"} ,
		{"word":"sax","word_nosc":"sax","lemma":"sax","pos":"NOM"} ,
		{"word":"saxes","word_nosc":"saxes","lemma":"saxe","pos":"NOM"} ,
		{"word":"saxhorn","word_nosc":"saxhorn","lemma":"saxhorn","pos":"NOM"} ,
		{"word":"saxifragacées","word_nosc":"saxifragacees","lemma":"saxifragacée","pos":"NOM"} ,
		{"word":"saxifrage","word_nosc":"saxifrage","lemma":"saxifrage","pos":"NOM"} ,
		{"word":"saxifrages","word_nosc":"saxifrages","lemma":"saxifrage","pos":"NOM"} ,
		{"word":"saxo","word_nosc":"saxo","lemma":"saxo","pos":"NOM"} ,
		{"word":"saxon","word_nosc":"saxon","lemma":"saxon","pos":"NOM"} ,
		{"word":"saxonnes","word_nosc":"saxonnes","lemma":"saxonne","pos":"NOM"} ,
		{"word":"saxons","word_nosc":"saxons","lemma":"saxon","pos":"NOM"} ,
		{"word":"saxophone","word_nosc":"saxophone","lemma":"saxophone","pos":"NOM"} ,
		{"word":"saxophones","word_nosc":"saxophones","lemma":"saxophone","pos":"NOM"} ,
		{"word":"saxophoniste","word_nosc":"saxophoniste","lemma":"saxophoniste","pos":"NOM"} ,
		{"word":"saxophonistes","word_nosc":"saxophonistes","lemma":"saxophoniste","pos":"NOM"} ,
		{"word":"saxos","word_nosc":"saxos","lemma":"saxo","pos":"NOM"} ,
		{"word":"saynète","word_nosc":"saynete","lemma":"saynète","pos":"NOM"} ,
		{"word":"saynètes","word_nosc":"saynetes","lemma":"saynète","pos":"NOM"} ,
		{"word":"sayon","word_nosc":"sayon","lemma":"sayon","pos":"NOM"} ,
		{"word":"saï","word_nosc":"sai","lemma":"saï","pos":"NOM"} ,
		{"word":"sbire","word_nosc":"sbire","lemma":"sbire","pos":"NOM"} ,
		{"word":"sbires","word_nosc":"sbires","lemma":"sbire","pos":"NOM"} ,
		{"word":"scaferlati","word_nosc":"scaferlati","lemma":"scaferlati","pos":"NOM"} ,
		{"word":"scalaire","word_nosc":"scalaire","lemma":"scalaire","pos":"NOM"} ,
		{"word":"scalp","word_nosc":"scalp","lemma":"scalp","pos":"NOM"} ,
		{"word":"scalpel","word_nosc":"scalpel","lemma":"scalpel","pos":"NOM"} ,
		{"word":"scalpels","word_nosc":"scalpels","lemma":"scalpel","pos":"NOM"} ,
		{"word":"scalpeur","word_nosc":"scalpeur","lemma":"scalpeur","pos":"NOM"} ,
		{"word":"scalps","word_nosc":"scalps","lemma":"scalp","pos":"NOM"} ,
		{"word":"scampi","word_nosc":"scampi","lemma":"scampi","pos":"NOM"} ,
		{"word":"scandale","word_nosc":"scandale","lemma":"scandale","pos":"NOM"} ,
		{"word":"scandales","word_nosc":"scandales","lemma":"scandale","pos":"NOM"} ,
		{"word":"scandinave","word_nosc":"scandinave","lemma":"scandinave","pos":"NOM"} ,
		{"word":"scandinaves","word_nosc":"scandinaves","lemma":"scandinave","pos":"NOM"} ,
		{"word":"scannage","word_nosc":"scannage","lemma":"scannage","pos":"NOM"} ,
		{"word":"scanner","word_nosc":"scanner","lemma":"scanner","pos":"NOM"} ,
		{"word":"scanners","word_nosc":"scanners","lemma":"scanner","pos":"NOM"} ,
		{"word":"scanneur","word_nosc":"scanneur","lemma":"scanneur","pos":"NOM"} ,
		{"word":"scanning","word_nosc":"scanning","lemma":"scanning","pos":"NOM"} ,
		{"word":"scanographie","word_nosc":"scanographie","lemma":"scanographie","pos":"NOM"} ,
		{"word":"scansion","word_nosc":"scansion","lemma":"scansion","pos":"NOM"} ,
		{"word":"scansions","word_nosc":"scansions","lemma":"scansion","pos":"NOM"} ,
		{"word":"scaphandre","word_nosc":"scaphandre","lemma":"scaphandre","pos":"NOM"} ,
		{"word":"scaphandres","word_nosc":"scaphandres","lemma":"scaphandre","pos":"NOM"} ,
		{"word":"scaphandrier","word_nosc":"scaphandrier","lemma":"scaphandrier","pos":"NOM"} ,
		{"word":"scaphandriers","word_nosc":"scaphandriers","lemma":"scaphandrier","pos":"NOM"} ,
		{"word":"scapin","word_nosc":"scapin","lemma":"scapin","pos":"NOM"} ,
		{"word":"scapulaire","word_nosc":"scapulaire","lemma":"scapulaire","pos":"NOM"} ,
		{"word":"scapulaires","word_nosc":"scapulaires","lemma":"scapulaire","pos":"NOM"} ,
		{"word":"scarabée","word_nosc":"scarabee","lemma":"scarabée","pos":"NOM"} ,
		{"word":"scarabées","word_nosc":"scarabees","lemma":"scarabée","pos":"NOM"} ,
		{"word":"scare","word_nosc":"scare","lemma":"scare","pos":"NOM"} ,
		{"word":"scarification","word_nosc":"scarification","lemma":"scarification","pos":"NOM"} ,
		{"word":"scarifications","word_nosc":"scarifications","lemma":"scarification","pos":"NOM"} ,
		{"word":"scarlatine","word_nosc":"scarlatine","lemma":"scarlatin","pos":"NOM"} ,
		{"word":"scarlatine","word_nosc":"scarlatine","lemma":"scarlatine","pos":"NOM"} ,
		{"word":"scarlatines","word_nosc":"scarlatines","lemma":"scarlatin","pos":"NOM"} ,
		{"word":"scarole","word_nosc":"scarole","lemma":"scarole","pos":"NOM"} ,
		{"word":"scaroles","word_nosc":"scaroles","lemma":"scarole","pos":"NOM"} ,
		{"word":"scat","word_nosc":"scat","lemma":"scat","pos":"NOM"} ,
		{"word":"scatologie","word_nosc":"scatologie","lemma":"scatologie","pos":"NOM"} ,
		{"word":"scatologue","word_nosc":"scatologue","lemma":"scatologue","pos":"NOM"} ,
		{"word":"scatter","word_nosc":"scatter","lemma":"scatter","pos":"NOM"} ,
		{"word":"scazons","word_nosc":"scazons","lemma":"scazon","pos":"NOM"} ,
		{"word":"sceau","word_nosc":"sceau","lemma":"sceau","pos":"NOM"} ,
		{"word":"sceaux","word_nosc":"sceaux","lemma":"sceau","pos":"NOM"} ,
		{"word":"scellage","word_nosc":"scellage","lemma":"scellage","pos":"NOM"} ,
		{"word":"scellement","word_nosc":"scellement","lemma":"scellement","pos":"NOM"} ,
		{"word":"scellements","word_nosc":"scellements","lemma":"scellement","pos":"NOM"} ,
		{"word":"scellé","word_nosc":"scelle","lemma":"scellé","pos":"NOM"} ,
		{"word":"scellés","word_nosc":"scelles","lemma":"scellé","pos":"NOM"} ,
		{"word":"scenarii","word_nosc":"scenarii","lemma":"scenarii","pos":"NOM"} ,
		{"word":"scenic railway","word_nosc":"scenic railway","lemma":"scenic railway","pos":"NOM"} ,
		{"word":"scepticisme","word_nosc":"scepticisme","lemma":"scepticisme","pos":"NOM"} ,
		{"word":"sceptique","word_nosc":"sceptique","lemma":"sceptique","pos":"NOM"} ,
		{"word":"sceptiques","word_nosc":"sceptiques","lemma":"sceptique","pos":"NOM"} ,
		{"word":"sceptre","word_nosc":"sceptre","lemma":"sceptre","pos":"NOM"} ,
		{"word":"sceptres","word_nosc":"sceptres","lemma":"sceptre","pos":"NOM"} ,
		{"word":"schah","word_nosc":"schah","lemma":"schah","pos":"NOM"} ,
		{"word":"schako","word_nosc":"schako","lemma":"schako","pos":"NOM"} ,
		{"word":"schampooing","word_nosc":"schampooing","lemma":"schampooing","pos":"NOM"} ,
		{"word":"schbeb","word_nosc":"schbeb","lemma":"schbeb","pos":"NOM"} ,
		{"word":"scheik","word_nosc":"scheik","lemma":"scheik","pos":"NOM"} ,
		{"word":"schilling","word_nosc":"schilling","lemma":"schilling","pos":"NOM"} ,
		{"word":"schillings","word_nosc":"schillings","lemma":"schilling","pos":"NOM"} ,
		{"word":"schisme","word_nosc":"schisme","lemma":"schisme","pos":"NOM"} ,
		{"word":"schismes","word_nosc":"schismes","lemma":"schisme","pos":"NOM"} ,
		{"word":"schiste","word_nosc":"schiste","lemma":"schiste","pos":"NOM"} ,
		{"word":"schistes","word_nosc":"schistes","lemma":"schiste","pos":"NOM"} ,
		{"word":"schizophasie","word_nosc":"schizophasie","lemma":"schizophasie","pos":"NOM"} ,
		{"word":"schizophrène","word_nosc":"schizophrene","lemma":"schizophrène","pos":"NOM"} ,
		{"word":"schizophrènes","word_nosc":"schizophrenes","lemma":"schizophrène","pos":"NOM"} ,
		{"word":"schizophrénie","word_nosc":"schizophrenie","lemma":"schizophrénie","pos":"NOM"} ,
		{"word":"schlague","word_nosc":"schlague","lemma":"schlague","pos":"NOM"} ,
		{"word":"schlass","word_nosc":"schlass","lemma":"schlass","pos":"NOM"} ,
		{"word":"schlem","word_nosc":"schlem","lemma":"schlem","pos":"NOM"} ,
		{"word":"schleu","word_nosc":"schleu","lemma":"schleu","pos":"NOM"} ,
		{"word":"schlitte","word_nosc":"schlitte","lemma":"schlitte","pos":"NOM"} ,
		{"word":"schnaps","word_nosc":"schnaps","lemma":"schnaps","pos":"NOM"} ,
		{"word":"schnauzer","word_nosc":"schnauzer","lemma":"schnauzer","pos":"NOM"} ,
		{"word":"schnick","word_nosc":"schnick","lemma":"schnick","pos":"NOM"} ,
		{"word":"schnitzel","word_nosc":"schnitzel","lemma":"schnitzel","pos":"NOM"} ,
		{"word":"schnock","word_nosc":"schnock","lemma":"schnock","pos":"NOM"} ,
		{"word":"schnocks","word_nosc":"schnocks","lemma":"schnock","pos":"NOM"} ,
		{"word":"schnoque","word_nosc":"schnoque","lemma":"schnoque","pos":"NOM"} ,
		{"word":"schnoques","word_nosc":"schnoques","lemma":"schnoque","pos":"NOM"} ,
		{"word":"schnouf","word_nosc":"schnouf","lemma":"schnouf","pos":"NOM"} ,
		{"word":"schnouff","word_nosc":"schnouff","lemma":"schnouff","pos":"NOM"} ,
		{"word":"schooner","word_nosc":"schooner","lemma":"schooner","pos":"NOM"} ,
		{"word":"schooners","word_nosc":"schooners","lemma":"schooner","pos":"NOM"} ,
		{"word":"schpile","word_nosc":"schpile","lemma":"schpile","pos":"NOM"} ,
		{"word":"schproum","word_nosc":"schproum","lemma":"schproum","pos":"NOM"} ,
		{"word":"schtroumf","word_nosc":"schtroumf","lemma":"schtroumf","pos":"NOM"} ,
		{"word":"schtroumpf","word_nosc":"schtroumpf","lemma":"schtroumpf","pos":"NOM"} ,
		{"word":"schtroumpfs","word_nosc":"schtroumpfs","lemma":"schtroumpf","pos":"NOM"} ,
		{"word":"schupo","word_nosc":"schupo","lemma":"schupo","pos":"NOM"} ,
		{"word":"schupos","word_nosc":"schupos","lemma":"schupo","pos":"NOM"} ,
		{"word":"schuss","word_nosc":"schuss","lemma":"schuss","pos":"NOM"} ,
		{"word":"schème","word_nosc":"scheme","lemma":"schème","pos":"NOM"} ,
		{"word":"schèmes","word_nosc":"schemes","lemma":"schème","pos":"NOM"} ,
		{"word":"schéma","word_nosc":"schema","lemma":"schéma","pos":"NOM"} ,
		{"word":"schémas","word_nosc":"schemas","lemma":"schéma","pos":"NOM"} ,
		{"word":"schématisme","word_nosc":"schematisme","lemma":"schématisme","pos":"NOM"} ,
		{"word":"sciage","word_nosc":"sciage","lemma":"sciage","pos":"NOM"} ,
		{"word":"sciatique","word_nosc":"sciatique","lemma":"sciatique","pos":"NOM"} ,
		{"word":"scie","word_nosc":"scie","lemma":"scie","pos":"NOM"} ,
		{"word":"science","word_nosc":"science","lemma":"science","pos":"NOM"} ,
		{"word":"science-fiction","word_nosc":"science-fiction","lemma":"science-fiction","pos":"NOM"} ,
		{"word":"sciences","word_nosc":"sciences","lemma":"science","pos":"NOM"} ,
		{"word":"scientificité","word_nosc":"scientificite","lemma":"scientificité","pos":"NOM"} ,
		{"word":"scientifique","word_nosc":"scientifique","lemma":"scientifique","pos":"NOM"} ,
		{"word":"scientifiques","word_nosc":"scientifiques","lemma":"scientifique","pos":"NOM"} ,
		{"word":"scientisme","word_nosc":"scientisme","lemma":"scientisme","pos":"NOM"} ,
		{"word":"scientiste","word_nosc":"scientiste","lemma":"scientiste","pos":"NOM"} ,
		{"word":"scientologie","word_nosc":"scientologie","lemma":"scientologie","pos":"NOM"} ,
		{"word":"scientologue","word_nosc":"scientologue","lemma":"scientologue","pos":"NOM"} ,
		{"word":"scientologues","word_nosc":"scientologues","lemma":"scientologue","pos":"NOM"} ,
		{"word":"scierie","word_nosc":"scierie","lemma":"scierie","pos":"NOM"} ,
		{"word":"scieries","word_nosc":"scieries","lemma":"scierie","pos":"NOM"} ,
		{"word":"scies","word_nosc":"scies","lemma":"scie","pos":"NOM"} ,
		{"word":"scieur","word_nosc":"scieur","lemma":"scieur","pos":"NOM"} ,
		{"word":"scieurs","word_nosc":"scieurs","lemma":"scieur","pos":"NOM"} ,
		{"word":"scintigraphie","word_nosc":"scintigraphie","lemma":"scintigraphie","pos":"NOM"} ,
		{"word":"scintillation","word_nosc":"scintillation","lemma":"scintillation","pos":"NOM"} ,
		{"word":"scintillations","word_nosc":"scintillations","lemma":"scintillation","pos":"NOM"} ,
		{"word":"scintillement","word_nosc":"scintillement","lemma":"scintillement","pos":"NOM"} ,
		{"word":"scintillements","word_nosc":"scintillements","lemma":"scintillement","pos":"NOM"} ,
		{"word":"scion","word_nosc":"scion","lemma":"scion","pos":"NOM"} ,
		{"word":"scions","word_nosc":"scions","lemma":"scion","pos":"NOM"} ,
		{"word":"scirpe","word_nosc":"scirpe","lemma":"scirpe","pos":"NOM"} ,
		{"word":"scirpes","word_nosc":"scirpes","lemma":"scirpe","pos":"NOM"} ,
		{"word":"scission","word_nosc":"scission","lemma":"scission","pos":"NOM"} ,
		{"word":"scissiparité","word_nosc":"scissiparite","lemma":"scissiparité","pos":"NOM"} ,
		{"word":"scissures","word_nosc":"scissures","lemma":"scissure","pos":"NOM"} ,
		{"word":"sciure","word_nosc":"sciure","lemma":"sciure","pos":"NOM"} ,
		{"word":"sciures","word_nosc":"sciures","lemma":"sciure","pos":"NOM"} ,
		{"word":"sciène","word_nosc":"sciene","lemma":"sciène","pos":"NOM"} ,
		{"word":"sclérodermie","word_nosc":"sclerodermie","lemma":"sclérodermie","pos":"NOM"} ,
		{"word":"sclérose","word_nosc":"sclerose","lemma":"sclérose","pos":"NOM"} ,
		{"word":"scléroses","word_nosc":"scleroses","lemma":"sclérose","pos":"NOM"} ,
		{"word":"sclérotique","word_nosc":"sclerotique","lemma":"sclérotique","pos":"NOM"} ,
		{"word":"sclérotiques","word_nosc":"sclerotiques","lemma":"sclérotique","pos":"NOM"} ,
		{"word":"scolaire","word_nosc":"scolaire","lemma":"scolaire","pos":"NOM"} ,
		{"word":"scolaires","word_nosc":"scolaires","lemma":"scolaire","pos":"NOM"} ,
		{"word":"scolarisation","word_nosc":"scolarisation","lemma":"scolarisation","pos":"NOM"} ,
		{"word":"scolarité","word_nosc":"scolarite","lemma":"scolarité","pos":"NOM"} ,
		{"word":"scolarités","word_nosc":"scolarites","lemma":"scolarité","pos":"NOM"} ,
		{"word":"scolie","word_nosc":"scolie","lemma":"scolie","pos":"NOM"} ,
		{"word":"scoliose","word_nosc":"scoliose","lemma":"scoliose","pos":"NOM"} ,
		{"word":"scolioses","word_nosc":"scolioses","lemma":"scoliose","pos":"NOM"} ,
		{"word":"scolopendre","word_nosc":"scolopendre","lemma":"scolopendre","pos":"NOM"} ,
		{"word":"scolopendres","word_nosc":"scolopendres","lemma":"scolopendre","pos":"NOM"} ,
		{"word":"scolyte","word_nosc":"scolyte","lemma":"scolyte","pos":"NOM"} ,
		{"word":"scone","word_nosc":"scone","lemma":"scone","pos":"NOM"} ,
		{"word":"scones","word_nosc":"scones","lemma":"scone","pos":"NOM"} ,
		{"word":"sconse","word_nosc":"sconse","lemma":"sconse","pos":"NOM"} ,
		{"word":"scoop","word_nosc":"scoop","lemma":"scoop","pos":"NOM"} ,
		{"word":"scoops","word_nosc":"scoops","lemma":"scoop","pos":"NOM"} ,
		{"word":"scooter","word_nosc":"scooter","lemma":"scooter","pos":"NOM"} ,
		{"word":"scooters","word_nosc":"scooters","lemma":"scooter","pos":"NOM"} ,
		{"word":"scope","word_nosc":"scope","lemma":"scope","pos":"NOM"} ,
		{"word":"scopes","word_nosc":"scopes","lemma":"scope","pos":"NOM"} ,
		{"word":"scopie","word_nosc":"scopie","lemma":"scopie","pos":"NOM"} ,
		{"word":"scopitones","word_nosc":"scopitones","lemma":"scopitone","pos":"NOM"} ,
		{"word":"scopolamine","word_nosc":"scopolamine","lemma":"scopolamine","pos":"NOM"} ,
		{"word":"scorbut","word_nosc":"scorbut","lemma":"scorbut","pos":"NOM"} ,
		{"word":"score","word_nosc":"score","lemma":"score","pos":"NOM"} ,
		{"word":"scores","word_nosc":"scores","lemma":"score","pos":"NOM"} ,
		{"word":"scories","word_nosc":"scories","lemma":"scorie","pos":"NOM"} ,
		{"word":"scorpion","word_nosc":"scorpion","lemma":"scorpion","pos":"NOM"} ,
		{"word":"scorpions","word_nosc":"scorpions","lemma":"scorpion","pos":"NOM"} ,
		{"word":"scotch","word_nosc":"scotch","lemma":"scotch","pos":"NOM"} ,
		{"word":"scotch-terrier","word_nosc":"scotch-terrier","lemma":"scotch-terrier","pos":"NOM"} ,
		{"word":"scotchs","word_nosc":"scotchs","lemma":"scotch","pos":"NOM"} ,
		{"word":"scottish","word_nosc":"scottish","lemma":"scottish","pos":"NOM"} ,
		{"word":"scoubidou","word_nosc":"scoubidou","lemma":"scoubidou","pos":"NOM"} ,
		{"word":"scoubidous","word_nosc":"scoubidous","lemma":"scoubidou","pos":"NOM"} ,
		{"word":"scoumoune","word_nosc":"scoumoune","lemma":"scoumoune","pos":"NOM"} ,
		{"word":"scout","word_nosc":"scout","lemma":"scout","pos":"NOM"} ,
		{"word":"scoute","word_nosc":"scoute","lemma":"scout","pos":"NOM"} ,
		{"word":"scoutisme","word_nosc":"scoutisme","lemma":"scoutisme","pos":"NOM"} ,
		{"word":"scouts","word_nosc":"scouts","lemma":"scout","pos":"NOM"} ,
		{"word":"scrabble","word_nosc":"scrabble","lemma":"scrabble","pos":"NOM"} ,
		{"word":"scratch","word_nosc":"scratch","lemma":"scratch","pos":"NOM"} ,
		{"word":"scratching","word_nosc":"scratching","lemma":"scratching","pos":"NOM"} ,
		{"word":"scriban","word_nosc":"scriban","lemma":"scriban","pos":"NOM"} ,
		{"word":"scribe","word_nosc":"scribe","lemma":"scribe","pos":"NOM"} ,
		{"word":"scribes","word_nosc":"scribes","lemma":"scribe","pos":"NOM"} ,
		{"word":"scribouillages","word_nosc":"scribouillages","lemma":"scribouillage","pos":"NOM"} ,
		{"word":"scribouillard","word_nosc":"scribouillard","lemma":"scribouillard","pos":"NOM"} ,
		{"word":"scribouillards","word_nosc":"scribouillards","lemma":"scribouillard","pos":"NOM"} ,
		{"word":"script","word_nosc":"script","lemma":"script","pos":"NOM"} ,
		{"word":"script-girl","word_nosc":"script-girl","lemma":"script-girl","pos":"NOM"} ,
		{"word":"scripte","word_nosc":"scripte","lemma":"scripte","pos":"NOM"} ,
		{"word":"scripteur","word_nosc":"scripteur","lemma":"scripteur","pos":"NOM"} ,
		{"word":"scripteurs","word_nosc":"scripteurs","lemma":"scripteur","pos":"NOM"} ,
		{"word":"scripts","word_nosc":"scripts","lemma":"script","pos":"NOM"} ,
		{"word":"scrofulaire","word_nosc":"scrofulaire","lemma":"scrofulaire","pos":"NOM"} ,
		{"word":"scrofule","word_nosc":"scrofule","lemma":"scrofule","pos":"NOM"} ,
		{"word":"scrofules","word_nosc":"scrofules","lemma":"scrofule","pos":"NOM"} ,
		{"word":"scrotum","word_nosc":"scrotum","lemma":"scrotum","pos":"NOM"} ,
		{"word":"scrub","word_nosc":"scrub","lemma":"scrub","pos":"NOM"} ,
		{"word":"scrubs","word_nosc":"scrubs","lemma":"scrub","pos":"NOM"} ,
		{"word":"scrupule","word_nosc":"scrupule","lemma":"scrupule","pos":"NOM"} ,
		{"word":"scrupules","word_nosc":"scrupules","lemma":"scrupule","pos":"NOM"} ,
		{"word":"scrupulosité","word_nosc":"scrupulosite","lemma":"scrupulosité","pos":"NOM"} ,
		{"word":"scrutateur","word_nosc":"scrutateur","lemma":"scrutateur","pos":"NOM"} ,
		{"word":"scrutation","word_nosc":"scrutation","lemma":"scrutation","pos":"NOM"} ,
		{"word":"scrutin","word_nosc":"scrutin","lemma":"scrutin","pos":"NOM"} ,
		{"word":"scrutins","word_nosc":"scrutins","lemma":"scrutin","pos":"NOM"} ,
		{"word":"sculpteur","word_nosc":"sculpteur","lemma":"sculpteur","pos":"NOM"} ,
		{"word":"sculpteurs","word_nosc":"sculpteurs","lemma":"sculpteur","pos":"NOM"} ,
		{"word":"sculptrice","word_nosc":"sculptrice","lemma":"sculpteur","pos":"NOM"} ,
		{"word":"sculpture","word_nosc":"sculpture","lemma":"sculpture","pos":"NOM"} ,
		{"word":"sculptures","word_nosc":"sculptures","lemma":"sculpture","pos":"NOM"} ,
		{"word":"scutigère","word_nosc":"scutigere","lemma":"scutigère","pos":"NOM"} ,
		{"word":"scutigères","word_nosc":"scutigeres","lemma":"scutigère","pos":"NOM"} ,
		{"word":"scène","word_nosc":"scene","lemma":"scène","pos":"NOM"} ,
		{"word":"scène-clé","word_nosc":"scene-cle","lemma":"scène-clé","pos":"NOM"} ,
		{"word":"scènes","word_nosc":"scenes","lemma":"scène","pos":"NOM"} ,
		{"word":"scènes-clés","word_nosc":"scenes-cles","lemma":"scènes-clé","pos":"NOM"} ,
		{"word":"scélérat","word_nosc":"scelerat","lemma":"scélérat","pos":"NOM"} ,
		{"word":"scélérate","word_nosc":"scelerate","lemma":"scélérat","pos":"NOM"} ,
		{"word":"scélératesse","word_nosc":"sceleratesse","lemma":"scélératesse","pos":"NOM"} ,
		{"word":"scélératesses","word_nosc":"sceleratesses","lemma":"scélératesse","pos":"NOM"} ,
		{"word":"scélérats","word_nosc":"scelerats","lemma":"scélérat","pos":"NOM"} ,
		{"word":"scénar","word_nosc":"scenar","lemma":"scénar","pos":"NOM"} ,
		{"word":"scénarii","word_nosc":"scenarii","lemma":"scénario","pos":"NOM"} ,
		{"word":"scénarimage","word_nosc":"scenarimage","lemma":"scénarimage","pos":"NOM"} ,
		{"word":"scénario","word_nosc":"scenario","lemma":"scénario","pos":"NOM"} ,
		{"word":"scénarios","word_nosc":"scenarios","lemma":"scénario","pos":"NOM"} ,
		{"word":"scénariste","word_nosc":"scenariste","lemma":"scénariste","pos":"NOM"} ,
		{"word":"scénaristes","word_nosc":"scenaristes","lemma":"scénariste","pos":"NOM"} ,
		{"word":"scénars","word_nosc":"scenars","lemma":"scénar","pos":"NOM"} ,
		{"word":"scénographe","word_nosc":"scenographe","lemma":"scénographe","pos":"NOM"} ,
		{"word":"scénographie","word_nosc":"scenographie","lemma":"scénographie","pos":"NOM"} ,
		{"word":"seaborgium","word_nosc":"seaborgium","lemma":"seaborgium","pos":"NOM"} ,
		{"word":"seau","word_nosc":"seau","lemma":"seau","pos":"NOM"} ,
		{"word":"seaux","word_nosc":"seaux","lemma":"seau","pos":"NOM"} ,
		{"word":"secco","word_nosc":"secco","lemma":"secco","pos":"NOM"} ,
		{"word":"seccos","word_nosc":"seccos","lemma":"secco","pos":"NOM"} ,
		{"word":"seccotine","word_nosc":"seccotine","lemma":"seccotine","pos":"NOM"} ,
		{"word":"second","word_nosc":"second","lemma":"second","pos":"NOM"} ,
		{"word":"second-maître","word_nosc":"second-maitre","lemma":"second-maître","pos":"NOM"} ,
		{"word":"secondaire","word_nosc":"secondaire","lemma":"secondaire","pos":"NOM"} ,
		{"word":"secondaires","word_nosc":"secondaires","lemma":"secondaire","pos":"NOM"} ,
		{"word":"seconde","word_nosc":"seconde","lemma":"seconde","pos":"NOM"} ,
		{"word":"secondes","word_nosc":"secondes","lemma":"seconde","pos":"NOM"} ,
		{"word":"secondo","word_nosc":"secondo","lemma":"secondo","pos":"NOM"} ,
		{"word":"seconds","word_nosc":"seconds","lemma":"second","pos":"NOM"} ,
		{"word":"secouement","word_nosc":"secouement","lemma":"secouement","pos":"NOM"} ,
		{"word":"secoueur","word_nosc":"secoueur","lemma":"secoueur","pos":"NOM"} ,
		{"word":"secoueurs","word_nosc":"secoueurs","lemma":"secoueur","pos":"NOM"} ,
		{"word":"secourisme","word_nosc":"secourisme","lemma":"secourisme","pos":"NOM"} ,
		{"word":"secouriste","word_nosc":"secouriste","lemma":"secouriste","pos":"NOM"} ,
		{"word":"secouristes","word_nosc":"secouristes","lemma":"secouriste","pos":"NOM"} ,
		{"word":"secours","word_nosc":"secours","lemma":"secours","pos":"NOM"} ,
		{"word":"secousse","word_nosc":"secousse","lemma":"secousse","pos":"NOM"} ,
		{"word":"secousses","word_nosc":"secousses","lemma":"secousse","pos":"NOM"} ,
		{"word":"secret","word_nosc":"secret","lemma":"secret","pos":"NOM"} ,
		{"word":"secrets","word_nosc":"secrets","lemma":"secret","pos":"NOM"} ,
		{"word":"secrète","word_nosc":"secrete","lemma":"secrète","pos":"NOM"} ,
		{"word":"secrètes","word_nosc":"secretes","lemma":"secrète","pos":"NOM"} ,
		{"word":"secrétaire","word_nosc":"secretaire","lemma":"secrétaire","pos":"NOM"} ,
		{"word":"secrétairerie","word_nosc":"secretairerie","lemma":"secrétairerie","pos":"NOM"} ,
		{"word":"secrétaires","word_nosc":"secretaires","lemma":"secrétaire","pos":"NOM"} ,
		{"word":"secrétariat","word_nosc":"secretariat","lemma":"secrétariat","pos":"NOM"} ,
		{"word":"secrétariats","word_nosc":"secretariats","lemma":"secrétariat","pos":"NOM"} ,
		{"word":"sectaire","word_nosc":"sectaire","lemma":"sectaire","pos":"NOM"} ,
		{"word":"sectaires","word_nosc":"sectaires","lemma":"sectaire","pos":"NOM"} ,
		{"word":"sectarisme","word_nosc":"sectarisme","lemma":"sectarisme","pos":"NOM"} ,
		{"word":"sectateur","word_nosc":"sectateur","lemma":"sectateur","pos":"NOM"} ,
		{"word":"sectateurs","word_nosc":"sectateurs","lemma":"sectateur","pos":"NOM"} ,
		{"word":"secte","word_nosc":"secte","lemma":"secte","pos":"NOM"} ,
		{"word":"sectes","word_nosc":"sectes","lemma":"secte","pos":"NOM"} ,
		{"word":"secteur","word_nosc":"secteur","lemma":"secteur","pos":"NOM"} ,
		{"word":"secteurs","word_nosc":"secteurs","lemma":"secteur","pos":"NOM"} ,
		{"word":"section","word_nosc":"section","lemma":"section","pos":"NOM"} ,
		{"word":"sectionnement","word_nosc":"sectionnement","lemma":"sectionnement","pos":"NOM"} ,
		{"word":"sections","word_nosc":"sections","lemma":"section","pos":"NOM"} ,
		{"word":"sectorisation","word_nosc":"sectorisation","lemma":"sectorisation","pos":"NOM"} ,
		{"word":"sedan","word_nosc":"sedan","lemma":"sedan","pos":"NOM"} ,
		{"word":"sedia gestatoria","word_nosc":"sedia gestatoria","lemma":"sedia gestatoria","pos":"NOM"} ,
		{"word":"sedums","word_nosc":"sedums","lemma":"sedum","pos":"NOM"} ,
		{"word":"seersucker","word_nosc":"seersucker","lemma":"seersucker","pos":"NOM"} ,
		{"word":"segment","word_nosc":"segment","lemma":"segment","pos":"NOM"} ,
		{"word":"segments","word_nosc":"segments","lemma":"segment","pos":"NOM"} ,
		{"word":"seguedilla","word_nosc":"seguedilla","lemma":"seguedilla","pos":"NOM"} ,
		{"word":"seguedillas","word_nosc":"seguedillas","lemma":"seguedilla","pos":"NOM"} ,
		{"word":"seiche","word_nosc":"seiche","lemma":"seiche","pos":"NOM"} ,
		{"word":"seiches","word_nosc":"seiches","lemma":"seiche","pos":"NOM"} ,
		{"word":"seigle","word_nosc":"seigle","lemma":"seigle","pos":"NOM"} ,
		{"word":"seigles","word_nosc":"seigles","lemma":"seigle","pos":"NOM"} ,
		{"word":"seigneur","word_nosc":"seigneur","lemma":"seigneur","pos":"NOM"} ,
		{"word":"seigneurie","word_nosc":"seigneurie","lemma":"seigneurie","pos":"NOM"} ,
		{"word":"seigneuries","word_nosc":"seigneuries","lemma":"seigneurie","pos":"NOM"} ,
		{"word":"seigneurs","word_nosc":"seigneurs","lemma":"seigneur","pos":"NOM"} ,
		{"word":"seille","word_nosc":"seille","lemma":"seille","pos":"NOM"} ,
		{"word":"seilles","word_nosc":"seilles","lemma":"seille","pos":"NOM"} ,
		{"word":"seillon","word_nosc":"seillon","lemma":"seillon","pos":"NOM"} ,
		{"word":"sein","word_nosc":"sein","lemma":"sein","pos":"NOM"} ,
		{"word":"seine","word_nosc":"seine","lemma":"seine","pos":"NOM"} ,
		{"word":"seing","word_nosc":"seing","lemma":"seing","pos":"NOM"} ,
		{"word":"seins","word_nosc":"seins","lemma":"sein","pos":"NOM"} ,
		{"word":"seizième","word_nosc":"seizieme","lemma":"seizième","pos":"NOM"} ,
		{"word":"sel","word_nosc":"sel","lemma":"sel","pos":"NOM"} ,
		{"word":"seldjoukides","word_nosc":"seldjoukides","lemma":"seldjoukide","pos":"NOM"} ,
		{"word":"self","word_nosc":"self","lemma":"self","pos":"NOM"} ,
		{"word":"self-control","word_nosc":"self-control","lemma":"self-control","pos":"NOM"} ,
		{"word":"self-contrôle","word_nosc":"self-controle","lemma":"self-contrôle","pos":"NOM"} ,
		{"word":"self-défense","word_nosc":"self-defense","lemma":"self-défense","pos":"NOM"} ,
		{"word":"self-made man","word_nosc":"self-made man","lemma":"self-made man","pos":"NOM"} ,
		{"word":"self-made-man","word_nosc":"self-made-man","lemma":"self-made-man","pos":"NOM"} ,
		{"word":"self-made-men","word_nosc":"self-made-men","lemma":"self-made-men","pos":"NOM"} ,
		{"word":"self-service","word_nosc":"self-service","lemma":"self-service","pos":"NOM"} ,
		{"word":"self-services","word_nosc":"self-services","lemma":"self-service","pos":"NOM"} ,
		{"word":"selfs","word_nosc":"selfs","lemma":"self","pos":"NOM"} ,
		{"word":"selle","word_nosc":"selle","lemma":"selle","pos":"NOM"} ,
		{"word":"sellerie","word_nosc":"sellerie","lemma":"sellerie","pos":"NOM"} ,
		{"word":"selleries","word_nosc":"selleries","lemma":"sellerie","pos":"NOM"} ,
		{"word":"selles","word_nosc":"selles","lemma":"selle","pos":"NOM"} ,
		{"word":"sellette","word_nosc":"sellette","lemma":"sellette","pos":"NOM"} ,
		{"word":"sellier","word_nosc":"sellier","lemma":"sellier","pos":"NOM"} ,
		{"word":"sels","word_nosc":"sels","lemma":"sel","pos":"NOM"} ,
		{"word":"seltz","word_nosc":"seltz","lemma":"seltz","pos":"NOM"} ,
		{"word":"selva","word_nosc":"selva","lemma":"selva","pos":"NOM"} ,
		{"word":"semailles","word_nosc":"semailles","lemma":"semaille","pos":"NOM"} ,
		{"word":"semaine","word_nosc":"semaine","lemma":"semaine","pos":"NOM"} ,
		{"word":"semaines","word_nosc":"semaines","lemma":"semaine","pos":"NOM"} ,
		{"word":"semainier","word_nosc":"semainier","lemma":"semainier","pos":"NOM"} ,
		{"word":"semblable","word_nosc":"semblable","lemma":"semblable","pos":"NOM"} ,
		{"word":"semblables","word_nosc":"semblables","lemma":"semblable","pos":"NOM"} ,
		{"word":"semblance","word_nosc":"semblance","lemma":"semblance","pos":"NOM"} ,
		{"word":"semblant","word_nosc":"semblant","lemma":"semblant","pos":"NOM"} ,
		{"word":"semblants","word_nosc":"semblants","lemma":"semblant","pos":"NOM"} ,
		{"word":"semelle","word_nosc":"semelle","lemma":"semelle","pos":"NOM"} ,
		{"word":"semelles","word_nosc":"semelles","lemma":"semelle","pos":"NOM"} ,
		{"word":"semen-contra","word_nosc":"semen-contra","lemma":"semen-contra","pos":"NOM"} ,
		{"word":"semence","word_nosc":"semence","lemma":"semence","pos":"NOM"} ,
		{"word":"semences","word_nosc":"semences","lemma":"semence","pos":"NOM"} ,
		{"word":"semestre","word_nosc":"semestre","lemma":"semestre","pos":"NOM"} ,
		{"word":"semestres","word_nosc":"semestres","lemma":"semestre","pos":"NOM"} ,
		{"word":"semeur","word_nosc":"semeur","lemma":"semeur","pos":"NOM"} ,
		{"word":"semeurs","word_nosc":"semeurs","lemma":"semeur","pos":"NOM"} ,
		{"word":"semeuse","word_nosc":"semeuse","lemma":"semeuse","pos":"NOM"} ,
		{"word":"semeuses","word_nosc":"semeuses","lemma":"semeur","pos":"NOM"} ,
		{"word":"semi","word_nosc":"semi","lemma":"semi","pos":"NOM"} ,
		{"word":"semi-conducteur","word_nosc":"semi-conducteur","lemma":"semi-conducteur","pos":"NOM"} ,
		{"word":"semi-conducteurs","word_nosc":"semi-conducteurs","lemma":"semi-conducteur","pos":"NOM"} ,
		{"word":"semi-liberté","word_nosc":"semi-liberte","lemma":"semi-liberté","pos":"NOM"} ,
		{"word":"semi-remorque","word_nosc":"semi-remorque","lemma":"semi-remorque","pos":"NOM"} ,
		{"word":"semi-remorques","word_nosc":"semi-remorques","lemma":"semi-remorque","pos":"NOM"} ,
		{"word":"semis","word_nosc":"semis","lemma":"semis","pos":"NOM"} ,
		{"word":"semoir","word_nosc":"semoir","lemma":"semoir","pos":"NOM"} ,
		{"word":"semoirs","word_nosc":"semoirs","lemma":"semoir","pos":"NOM"} ,
		{"word":"semonce","word_nosc":"semonce","lemma":"semonce","pos":"NOM"} ,
		{"word":"semonces","word_nosc":"semonces","lemma":"semonce","pos":"NOM"} ,
		{"word":"semoule","word_nosc":"semoule","lemma":"semoule","pos":"NOM"} ,
		{"word":"semtex","word_nosc":"semtex","lemma":"semtex","pos":"NOM"} ,
		{"word":"sen","word_nosc":"sen","lemma":"sen","pos":"NOM"} ,
		{"word":"senior","word_nosc":"senior","lemma":"senior","pos":"NOM"} ,
		{"word":"seniors","word_nosc":"seniors","lemma":"senior","pos":"NOM"} ,
		{"word":"senne","word_nosc":"senne","lemma":"seine","pos":"NOM"} ,
		{"word":"sens","word_nosc":"sens","lemma":"sens","pos":"NOM"} ,
		{"word":"sensation","word_nosc":"sensation","lemma":"sensation","pos":"NOM"} ,
		{"word":"sensationnalisme","word_nosc":"sensationnalisme","lemma":"sensationnalisme","pos":"NOM"} ,
		{"word":"sensationnel","word_nosc":"sensationnel","lemma":"sensationnel","pos":"NOM"} ,
		{"word":"sensationnels","word_nosc":"sensationnels","lemma":"sensationnel","pos":"NOM"} ,
		{"word":"sensations","word_nosc":"sensations","lemma":"sensation","pos":"NOM"} ,
		{"word":"senseur","word_nosc":"senseur","lemma":"senseur","pos":"NOM"} ,
		{"word":"senseurs","word_nosc":"senseurs","lemma":"senseur","pos":"NOM"} ,
		{"word":"sensibilisation","word_nosc":"sensibilisation","lemma":"sensibilisation","pos":"NOM"} ,
		{"word":"sensibilité","word_nosc":"sensibilite","lemma":"sensibilité","pos":"NOM"} ,
		{"word":"sensibilités","word_nosc":"sensibilites","lemma":"sensibilité","pos":"NOM"} ,
		{"word":"sensiblerie","word_nosc":"sensiblerie","lemma":"sensiblerie","pos":"NOM"} ,
		{"word":"sensibleries","word_nosc":"sensibleries","lemma":"sensiblerie","pos":"NOM"} ,
		{"word":"sensorialité","word_nosc":"sensorialite","lemma":"sensorialité","pos":"NOM"} ,
		{"word":"sensualiste","word_nosc":"sensualiste","lemma":"sensualiste","pos":"NOM"} ,
		{"word":"sensualité","word_nosc":"sensualite","lemma":"sensualité","pos":"NOM"} ,
		{"word":"sensualités","word_nosc":"sensualites","lemma":"sensualité","pos":"NOM"} ,
		{"word":"sent-bon","word_nosc":"sent-bon","lemma":"sent-bon","pos":"NOM"} ,
		{"word":"sente","word_nosc":"sente","lemma":"sente","pos":"NOM"} ,
		{"word":"sentence","word_nosc":"sentence","lemma":"sentence","pos":"NOM"} ,
		{"word":"sentences","word_nosc":"sentences","lemma":"sentence","pos":"NOM"} ,
		{"word":"sentes","word_nosc":"sentes","lemma":"sente","pos":"NOM"} ,
		{"word":"senteur","word_nosc":"senteur","lemma":"senteur","pos":"NOM"} ,
		{"word":"senteurs","word_nosc":"senteurs","lemma":"senteur","pos":"NOM"} ,
		{"word":"senti","word_nosc":"senti","lemma":"senti","pos":"NOM"} ,
		{"word":"sentier","word_nosc":"sentier","lemma":"sentier","pos":"NOM"} ,
		{"word":"sentiers","word_nosc":"sentiers","lemma":"sentier","pos":"NOM"} ,
		{"word":"sentiment","word_nosc":"sentiment","lemma":"sentiment","pos":"NOM"} ,
		{"word":"sentimental","word_nosc":"sentimental","lemma":"sentimental","pos":"NOM"} ,
		{"word":"sentimentale","word_nosc":"sentimentale","lemma":"sentimental","pos":"NOM"} ,
		{"word":"sentimentales","word_nosc":"sentimentales","lemma":"sentimental","pos":"NOM"} ,
		{"word":"sentimentalisme","word_nosc":"sentimentalisme","lemma":"sentimentalisme","pos":"NOM"} ,
		{"word":"sentimentalité","word_nosc":"sentimentalite","lemma":"sentimentalité","pos":"NOM"} ,
		{"word":"sentimentalités","word_nosc":"sentimentalites","lemma":"sentimentalité","pos":"NOM"} ,
		{"word":"sentimentaux","word_nosc":"sentimentaux","lemma":"sentimental","pos":"NOM"} ,
		{"word":"sentiments","word_nosc":"sentiments","lemma":"sentiment","pos":"NOM"} ,
		{"word":"sentine","word_nosc":"sentine","lemma":"sentine","pos":"NOM"} ,
		{"word":"sentinelle","word_nosc":"sentinelle","lemma":"sentinelle","pos":"NOM"} ,
		{"word":"sentinelles","word_nosc":"sentinelles","lemma":"sentinelle","pos":"NOM"} ,
		{"word":"sentines","word_nosc":"sentines","lemma":"sentine","pos":"NOM"} ,
		{"word":"sentis","word_nosc":"sentis","lemma":"senti","pos":"NOM"} ,
		{"word":"seppuku","word_nosc":"seppuku","lemma":"seppuku","pos":"NOM"} ,
		{"word":"seps","word_nosc":"seps","lemma":"seps","pos":"NOM"} ,
		{"word":"sept","word_nosc":"sept","lemma":"sept","pos":"NOM"} ,
		{"word":"septante","word_nosc":"septante","lemma":"septante","pos":"NOM"} ,
		{"word":"septembre","word_nosc":"septembre","lemma":"septembre","pos":"NOM"} ,
		{"word":"septembriseur","word_nosc":"septembriseur","lemma":"septembriseur","pos":"NOM"} ,
		{"word":"septennat","word_nosc":"septennat","lemma":"septennat","pos":"NOM"} ,
		{"word":"septentrion","word_nosc":"septentrion","lemma":"septentrion","pos":"NOM"} ,
		{"word":"septicité","word_nosc":"septicite","lemma":"septicité","pos":"NOM"} ,
		{"word":"septicémie","word_nosc":"septicemie","lemma":"septicémie","pos":"NOM"} ,
		{"word":"septidi","word_nosc":"septidi","lemma":"septidi","pos":"NOM"} ,
		{"word":"septime","word_nosc":"septime","lemma":"septime","pos":"NOM"} ,
		{"word":"septième","word_nosc":"septieme","lemma":"septième","pos":"NOM"} ,
		{"word":"septièmes","word_nosc":"septiemes","lemma":"septième","pos":"NOM"} ,
		{"word":"septuagénaire","word_nosc":"septuagenaire","lemma":"septuagénaire","pos":"NOM"} ,
		{"word":"septuagénaires","word_nosc":"septuagenaires","lemma":"septuagénaire","pos":"NOM"} ,
		{"word":"septum","word_nosc":"septum","lemma":"septum","pos":"NOM"} ,
		{"word":"septuor","word_nosc":"septuor","lemma":"septuor","pos":"NOM"} ,
		{"word":"septuple","word_nosc":"septuple","lemma":"septuple","pos":"NOM"} ,
		{"word":"sepuku","word_nosc":"sepuku","lemma":"sepuku","pos":"NOM"} ,
		{"word":"sequin","word_nosc":"sequin","lemma":"sequin","pos":"NOM"} ,
		{"word":"sequins","word_nosc":"sequins","lemma":"sequin","pos":"NOM"} ,
		{"word":"serbe","word_nosc":"serbe","lemma":"serbe","pos":"NOM"} ,
		{"word":"serbes","word_nosc":"serbes","lemma":"serbe","pos":"NOM"} ,
		{"word":"serbo-croate","word_nosc":"serbo-croate","lemma":"serbo-croate","pos":"NOM"} ,
		{"word":"serein","word_nosc":"serein","lemma":"serein","pos":"NOM"} ,
		{"word":"sereine","word_nosc":"sereine","lemma":"serein","pos":"NOM"} ,
		{"word":"serf","word_nosc":"serf","lemma":"serf","pos":"NOM"} ,
		{"word":"serfouette","word_nosc":"serfouette","lemma":"serfouette","pos":"NOM"} ,
		{"word":"serfs","word_nosc":"serfs","lemma":"serf","pos":"NOM"} ,
		{"word":"serge","word_nosc":"serge","lemma":"serge","pos":"NOM"} ,
		{"word":"sergent","word_nosc":"sergent","lemma":"sergent","pos":"NOM"} ,
		{"word":"sergent-chef","word_nosc":"sergent-chef","lemma":"sergent-chef","pos":"NOM"} ,
		{"word":"sergent-major","word_nosc":"sergent-major","lemma":"sergent-major","pos":"NOM"} ,
		{"word":"sergent-pilote","word_nosc":"sergent-pilote","lemma":"sergent-pilote","pos":"NOM"} ,
		{"word":"sergents","word_nosc":"sergents","lemma":"sergent","pos":"NOM"} ,
		{"word":"sergents-chefs","word_nosc":"sergents-chefs","lemma":"sergent-chef","pos":"NOM"} ,
		{"word":"sergents-majors","word_nosc":"sergents-majors","lemma":"sergent-major","pos":"NOM"} ,
		{"word":"serges","word_nosc":"serges","lemma":"serge","pos":"NOM"} ,
		{"word":"sergot","word_nosc":"sergot","lemma":"sergot","pos":"NOM"} ,
		{"word":"sergots","word_nosc":"sergots","lemma":"sergot","pos":"NOM"} ,
		{"word":"sergé","word_nosc":"serge","lemma":"sergé","pos":"NOM"} ,
		{"word":"serial","word_nosc":"serial","lemma":"serial","pos":"NOM"} ,
		{"word":"serin","word_nosc":"serin","lemma":"serin","pos":"NOM"} ,
		{"word":"serine","word_nosc":"serine","lemma":"serin","pos":"NOM"} ,
		{"word":"serines","word_nosc":"serines","lemma":"serin","pos":"NOM"} ,
		{"word":"seringa","word_nosc":"seringa","lemma":"seringa","pos":"NOM"} ,
		{"word":"seringas","word_nosc":"seringas","lemma":"seringa","pos":"NOM"} ,
		{"word":"seringue","word_nosc":"seringue","lemma":"seringue","pos":"NOM"} ,
		{"word":"seringueiros","word_nosc":"seringueiros","lemma":"seringueiro","pos":"NOM"} ,
		{"word":"seringues","word_nosc":"seringues","lemma":"seringue","pos":"NOM"} ,
		{"word":"serins","word_nosc":"serins","lemma":"serin","pos":"NOM"} ,
		{"word":"serment","word_nosc":"serment","lemma":"serment","pos":"NOM"} ,
		{"word":"serments","word_nosc":"serments","lemma":"serment","pos":"NOM"} ,
		{"word":"sermon","word_nosc":"sermon","lemma":"sermon","pos":"NOM"} ,
		{"word":"sermonneur","word_nosc":"sermonneur","lemma":"sermonneur","pos":"NOM"} ,
		{"word":"sermonneurs","word_nosc":"sermonneurs","lemma":"sermonneur","pos":"NOM"} ,
		{"word":"sermons","word_nosc":"sermons","lemma":"sermon","pos":"NOM"} ,
		{"word":"serpe","word_nosc":"serpe","lemma":"serpe","pos":"NOM"} ,
		{"word":"serpent","word_nosc":"serpent","lemma":"serpent","pos":"NOM"} ,
		{"word":"serpentaire","word_nosc":"serpentaire","lemma":"serpentaire","pos":"NOM"} ,
		{"word":"serpenteau","word_nosc":"serpenteau","lemma":"serpenteau","pos":"NOM"} ,
		{"word":"serpentement","word_nosc":"serpentement","lemma":"serpentement","pos":"NOM"} ,
		{"word":"serpentements","word_nosc":"serpentements","lemma":"serpentement","pos":"NOM"} ,
		{"word":"serpentin","word_nosc":"serpentin","lemma":"serpentin","pos":"NOM"} ,
		{"word":"serpentine","word_nosc":"serpentine","lemma":"serpentin","pos":"NOM"} ,
		{"word":"serpentins","word_nosc":"serpentins","lemma":"serpentin","pos":"NOM"} ,
		{"word":"serpents","word_nosc":"serpents","lemma":"serpent","pos":"NOM"} ,
		{"word":"serpes","word_nosc":"serpes","lemma":"serpe","pos":"NOM"} ,
		{"word":"serpette","word_nosc":"serpette","lemma":"serpette","pos":"NOM"} ,
		{"word":"serpillière","word_nosc":"serpilliere","lemma":"serpillière","pos":"NOM"} ,
		{"word":"serpillières","word_nosc":"serpillieres","lemma":"serpillière","pos":"NOM"} ,
		{"word":"serpolet","word_nosc":"serpolet","lemma":"serpolet","pos":"NOM"} ,
		{"word":"serra","word_nosc":"serra","lemma":"serra","pos":"NOM"} ,
		{"word":"serrage","word_nosc":"serrage","lemma":"serrage","pos":"NOM"} ,
		{"word":"serrante","word_nosc":"serrante","lemma":"serrante","pos":"NOM"} ,
		{"word":"serre","word_nosc":"serre","lemma":"serre","pos":"NOM"} ,
		{"word":"serre-file","word_nosc":"serre-file","lemma":"serre-file","pos":"NOM"} ,
		{"word":"serre-joint","word_nosc":"serre-joint","lemma":"serre-joint","pos":"NOM"} ,
		{"word":"serre-joints","word_nosc":"serre-joints","lemma":"serre-joint","pos":"NOM"} ,
		{"word":"serre-livres","word_nosc":"serre-livres","lemma":"serre-livres","pos":"NOM"} ,
		{"word":"serre-tête","word_nosc":"serre-tete","lemma":"serre-tête","pos":"NOM"} ,
		{"word":"serre-têtes","word_nosc":"serre-tetes","lemma":"serre-tête","pos":"NOM"} ,
		{"word":"serrement","word_nosc":"serrement","lemma":"serrement","pos":"NOM"} ,
		{"word":"serrements","word_nosc":"serrements","lemma":"serrement","pos":"NOM"} ,
		{"word":"serres","word_nosc":"serres","lemma":"serre","pos":"NOM"} ,
		{"word":"serrure","word_nosc":"serrure","lemma":"serrure","pos":"NOM"} ,
		{"word":"serrurerie","word_nosc":"serrurerie","lemma":"serrurerie","pos":"NOM"} ,
		{"word":"serrures","word_nosc":"serrures","lemma":"serrure","pos":"NOM"} ,
		{"word":"serrurier","word_nosc":"serrurier","lemma":"serrurier","pos":"NOM"} ,
		{"word":"serruriers","word_nosc":"serruriers","lemma":"serrurier","pos":"NOM"} ,
		{"word":"serrurière","word_nosc":"serruriere","lemma":"serrurier","pos":"NOM"} ,
		{"word":"sert","word_nosc":"sert","lemma":"sert","pos":"NOM"} ,
		{"word":"sertao","word_nosc":"sertao","lemma":"sertao","pos":"NOM"} ,
		{"word":"serti","word_nosc":"serti","lemma":"serti","pos":"NOM"} ,
		{"word":"sertis","word_nosc":"sertis","lemma":"serti","pos":"NOM"} ,
		{"word":"sertissage","word_nosc":"sertissage","lemma":"sertissage","pos":"NOM"} ,
		{"word":"sertisseur","word_nosc":"sertisseur","lemma":"sertisseur","pos":"NOM"} ,
		{"word":"sertão","word_nosc":"sertao","lemma":"sertão","pos":"NOM"} ,
		{"word":"servage","word_nosc":"servage","lemma":"servage","pos":"NOM"} ,
		{"word":"serval","word_nosc":"serval","lemma":"serval","pos":"NOM"} ,
		{"word":"servant","word_nosc":"servant","lemma":"servant","pos":"NOM"} ,
		{"word":"servante","word_nosc":"servante","lemma":"servant","pos":"NOM"} ,
		{"word":"servantes","word_nosc":"servantes","lemma":"servant","pos":"NOM"} ,
		{"word":"servants","word_nosc":"servants","lemma":"servant","pos":"NOM"} ,
		{"word":"serve","word_nosc":"serve","lemma":"serve","pos":"NOM"} ,
		{"word":"serves","word_nosc":"serves","lemma":"serve","pos":"NOM"} ,
		{"word":"serveur","word_nosc":"serveur","lemma":"serveur","pos":"NOM"} ,
		{"word":"serveurs","word_nosc":"serveurs","lemma":"serveur","pos":"NOM"} ,
		{"word":"serveuse","word_nosc":"serveuse","lemma":"serveur","pos":"NOM"} ,
		{"word":"serveuses","word_nosc":"serveuses","lemma":"serveur","pos":"NOM"} ,
		{"word":"serviabilité","word_nosc":"serviabilite","lemma":"serviabilité","pos":"NOM"} ,
		{"word":"service","word_nosc":"service","lemma":"service","pos":"NOM"} ,
		{"word":"services","word_nosc":"services","lemma":"service","pos":"NOM"} ,
		{"word":"serviette","word_nosc":"serviette","lemma":"serviette","pos":"NOM"} ,
		{"word":"serviette-éponge","word_nosc":"serviette-eponge","lemma":"serviette-éponge","pos":"NOM"} ,
		{"word":"serviettes","word_nosc":"serviettes","lemma":"serviette","pos":"NOM"} ,
		{"word":"serviettes-éponges","word_nosc":"serviettes-eponges","lemma":"serviette-éponge","pos":"NOM"} ,
		{"word":"servilité","word_nosc":"servilite","lemma":"servilité","pos":"NOM"} ,
		{"word":"servilités","word_nosc":"servilites","lemma":"servilité","pos":"NOM"} ,
		{"word":"serviteur","word_nosc":"serviteur","lemma":"serviteur","pos":"NOM"} ,
		{"word":"serviteurs","word_nosc":"serviteurs","lemma":"serviteur","pos":"NOM"} ,
		{"word":"servitude","word_nosc":"servitude","lemma":"servitude","pos":"NOM"} ,
		{"word":"servitudes","word_nosc":"servitudes","lemma":"servitude","pos":"NOM"} ,
		{"word":"servofrein","word_nosc":"servofrein","lemma":"servofrein","pos":"NOM"} ,
		{"word":"servomoteur","word_nosc":"servomoteur","lemma":"servomoteur","pos":"NOM"} ,
		{"word":"servomoteurs","word_nosc":"servomoteurs","lemma":"servomoteur","pos":"NOM"} ,
		{"word":"servomécanisme","word_nosc":"servomecanisme","lemma":"servomécanisme","pos":"NOM"} ,
		{"word":"ses","word_nosc":"ses","lemma":"ses","pos":"NOM"} ,
		{"word":"session","word_nosc":"session","lemma":"session","pos":"NOM"} ,
		{"word":"sessions","word_nosc":"sessions","lemma":"session","pos":"NOM"} ,
		{"word":"sesterces","word_nosc":"sesterces","lemma":"sesterce","pos":"NOM"} ,
		{"word":"set","word_nosc":"set","lemma":"set","pos":"NOM"} ,
		{"word":"sets","word_nosc":"sets","lemma":"set","pos":"NOM"} ,
		{"word":"setter","word_nosc":"setter","lemma":"setter","pos":"NOM"} ,
		{"word":"setters","word_nosc":"setters","lemma":"setter","pos":"NOM"} ,
		{"word":"seuil","word_nosc":"seuil","lemma":"seuil","pos":"NOM"} ,
		{"word":"seuils","word_nosc":"seuils","lemma":"seuil","pos":"NOM"} ,
		{"word":"seul","word_nosc":"seul","lemma":"seul","pos":"NOM"} ,
		{"word":"seule","word_nosc":"seule","lemma":"seul","pos":"NOM"} ,
		{"word":"seules","word_nosc":"seules","lemma":"seul","pos":"NOM"} ,
		{"word":"seuls","word_nosc":"seuls","lemma":"seul","pos":"NOM"} ,
		{"word":"seventies","word_nosc":"seventies","lemma":"seventies","pos":"NOM"} ,
		{"word":"sevrage","word_nosc":"sevrage","lemma":"sevrage","pos":"NOM"} ,
		{"word":"sevrages","word_nosc":"sevrages","lemma":"sevrage","pos":"NOM"} ,
		{"word":"sex appeal","word_nosc":"sex appeal","lemma":"sex-appeal","pos":"NOM"} ,
		{"word":"sex shop","word_nosc":"sex shop","lemma":"sex-shop","pos":"NOM"} ,
		{"word":"sex shops","word_nosc":"sex shops","lemma":"sex-shop","pos":"NOM"} ,
		{"word":"sex-appeal","word_nosc":"sex-appeal","lemma":"sex-appeal","pos":"NOM"} ,
		{"word":"sex-shop","word_nosc":"sex-shop","lemma":"sex-shop","pos":"NOM"} ,
		{"word":"sex-shops","word_nosc":"sex-shops","lemma":"sex-shop","pos":"NOM"} ,
		{"word":"sex-symbol","word_nosc":"sex-symbol","lemma":"sex-symbol","pos":"NOM"} ,
		{"word":"sexagénaire","word_nosc":"sexagenaire","lemma":"sexagénaire","pos":"NOM"} ,
		{"word":"sexagénaires","word_nosc":"sexagenaires","lemma":"sexagénaire","pos":"NOM"} ,
		{"word":"sexe","word_nosc":"sexe","lemma":"sexe","pos":"NOM"} ,
		{"word":"sexes","word_nosc":"sexes","lemma":"sexe","pos":"NOM"} ,
		{"word":"sexisme","word_nosc":"sexisme","lemma":"sexisme","pos":"NOM"} ,
		{"word":"sexiste","word_nosc":"sexiste","lemma":"sexiste","pos":"NOM"} ,
		{"word":"sexistes","word_nosc":"sexistes","lemma":"sexiste","pos":"NOM"} ,
		{"word":"sexologie","word_nosc":"sexologie","lemma":"sexologie","pos":"NOM"} ,
		{"word":"sexologue","word_nosc":"sexologue","lemma":"sexologue","pos":"NOM"} ,
		{"word":"sexologues","word_nosc":"sexologues","lemma":"sexologue","pos":"NOM"} ,
		{"word":"sextant","word_nosc":"sextant","lemma":"sextant","pos":"NOM"} ,
		{"word":"sextidi","word_nosc":"sextidi","lemma":"sextidi","pos":"NOM"} ,
		{"word":"sextuor","word_nosc":"sextuor","lemma":"sextuor","pos":"NOM"} ,
		{"word":"sexualité","word_nosc":"sexualite","lemma":"sexualité","pos":"NOM"} ,
		{"word":"señor","word_nosc":"senor","lemma":"señor","pos":"NOM"} ,
		{"word":"shabbat","word_nosc":"shabbat","lemma":"shabbat","pos":"NOM"} ,
		{"word":"shah","word_nosc":"shah","lemma":"shah","pos":"NOM"} ,
		{"word":"shahs","word_nosc":"shahs","lemma":"shah","pos":"NOM"} ,
		{"word":"shake-hand","word_nosc":"shake-hand","lemma":"shake-hand","pos":"NOM"} ,
		{"word":"shaker","word_nosc":"shaker","lemma":"shaker","pos":"NOM"} ,
		{"word":"shakespearien","word_nosc":"shakespearien","lemma":"shakespearien","pos":"NOM"} ,
		{"word":"shakespearienne","word_nosc":"shakespearienne","lemma":"shakespearien","pos":"NOM"} ,
		{"word":"shakespeariens","word_nosc":"shakespeariens","lemma":"shakespearien","pos":"NOM"} ,
		{"word":"shako","word_nosc":"shako","lemma":"shako","pos":"NOM"} ,
		{"word":"shakos","word_nosc":"shakos","lemma":"shako","pos":"NOM"} ,
		{"word":"shale","word_nosc":"shale","lemma":"shale","pos":"NOM"} ,
		{"word":"shaman","word_nosc":"shaman","lemma":"shaman","pos":"NOM"} ,
		{"word":"shamans","word_nosc":"shamans","lemma":"shaman","pos":"NOM"} ,
		{"word":"shamisen","word_nosc":"shamisen","lemma":"shamisen","pos":"NOM"} ,
		{"word":"shampoing","word_nosc":"shampoing","lemma":"shampoing","pos":"NOM"} ,
		{"word":"shampoings","word_nosc":"shampoings","lemma":"shampoing","pos":"NOM"} ,
		{"word":"shampooing","word_nosc":"shampooing","lemma":"shampooing","pos":"NOM"} ,
		{"word":"shampooings","word_nosc":"shampooings","lemma":"shampooing","pos":"NOM"} ,
		{"word":"shampouineur","word_nosc":"shampouineur","lemma":"shampouineur","pos":"NOM"} ,
		{"word":"shampouineuse","word_nosc":"shampouineuse","lemma":"shampouineur","pos":"NOM"} ,
		{"word":"shampouineuses","word_nosc":"shampouineuses","lemma":"shampouineur","pos":"NOM"} ,
		{"word":"shanghaien","word_nosc":"shanghaien","lemma":"shanghaien","pos":"NOM"} ,
		{"word":"shanghaiens","word_nosc":"shanghaiens","lemma":"shanghaien","pos":"NOM"} ,
		{"word":"shantung","word_nosc":"shantung","lemma":"shantung","pos":"NOM"} ,
		{"word":"shed","word_nosc":"shed","lemma":"shed","pos":"NOM"} ,
		{"word":"shekels","word_nosc":"shekels","lemma":"shekel","pos":"NOM"} ,
		{"word":"sheriff","word_nosc":"sheriff","lemma":"sheriff","pos":"NOM"} ,
		{"word":"sherpa","word_nosc":"sherpa","lemma":"sherpa","pos":"NOM"} ,
		{"word":"sherpas","word_nosc":"sherpas","lemma":"sherpa","pos":"NOM"} ,
		{"word":"sherry","word_nosc":"sherry","lemma":"sherry","pos":"NOM"} ,
		{"word":"shetland","word_nosc":"shetland","lemma":"shetland","pos":"NOM"} ,
		{"word":"shetlands","word_nosc":"shetlands","lemma":"shetland","pos":"NOM"} ,
		{"word":"shiatsu","word_nosc":"shiatsu","lemma":"shiatsu","pos":"NOM"} ,
		{"word":"shilling","word_nosc":"shilling","lemma":"shilling","pos":"NOM"} ,
		{"word":"shillings","word_nosc":"shillings","lemma":"shilling","pos":"NOM"} ,
		{"word":"shilom","word_nosc":"shilom","lemma":"shilom","pos":"NOM"} ,
		{"word":"shimmy","word_nosc":"shimmy","lemma":"shimmy","pos":"NOM"} ,
		{"word":"shingle","word_nosc":"shingle","lemma":"shingle","pos":"NOM"} ,
		{"word":"shintoïsme","word_nosc":"shintoisme","lemma":"shintoïsme","pos":"NOM"} ,
		{"word":"shintoïste","word_nosc":"shintoiste","lemma":"shintoïste","pos":"NOM"} ,
		{"word":"shintô","word_nosc":"shinto","lemma":"shintô","pos":"NOM"} ,
		{"word":"shipchandler","word_nosc":"shipchandler","lemma":"shipchandler","pos":"NOM"} ,
		{"word":"shipping","word_nosc":"shipping","lemma":"shipping","pos":"NOM"} ,
		{"word":"shirting","word_nosc":"shirting","lemma":"shirting","pos":"NOM"} ,
		{"word":"shit","word_nosc":"shit","lemma":"shit","pos":"NOM"} ,
		{"word":"shogoun","word_nosc":"shogoun","lemma":"shogoun","pos":"NOM"} ,
		{"word":"shogun","word_nosc":"shogun","lemma":"shogun","pos":"NOM"} ,
		{"word":"shogunat","word_nosc":"shogunat","lemma":"shogunat","pos":"NOM"} ,
		{"word":"shoot","word_nosc":"shoot","lemma":"shoot","pos":"NOM"} ,
		{"word":"shooteuse","word_nosc":"shooteuse","lemma":"shooteur","pos":"NOM"} ,
		{"word":"shoots","word_nosc":"shoots","lemma":"shoot","pos":"NOM"} ,
		{"word":"shopping","word_nosc":"shopping","lemma":"shopping","pos":"NOM"} ,
		{"word":"shoppings","word_nosc":"shoppings","lemma":"shopping","pos":"NOM"} ,
		{"word":"short","word_nosc":"short","lemma":"short","pos":"NOM"} ,
		{"word":"shorts","word_nosc":"shorts","lemma":"short","pos":"NOM"} ,
		{"word":"shoshones","word_nosc":"shoshones","lemma":"shoshone","pos":"NOM"} ,
		{"word":"show","word_nosc":"show","lemma":"show","pos":"NOM"} ,
		{"word":"show-business","word_nosc":"show-business","lemma":"show-business","pos":"NOM"} ,
		{"word":"showbiz","word_nosc":"showbiz","lemma":"showbiz","pos":"NOM"} ,
		{"word":"shows","word_nosc":"shows","lemma":"show","pos":"NOM"} ,
		{"word":"shrapnel","word_nosc":"shrapnel","lemma":"shrapnel","pos":"NOM"} ,
		{"word":"shrapnell","word_nosc":"shrapnell","lemma":"shrapnell","pos":"NOM"} ,
		{"word":"shrapnells","word_nosc":"shrapnells","lemma":"shrapnell","pos":"NOM"} ,
		{"word":"shrapnels","word_nosc":"shrapnels","lemma":"shrapnel","pos":"NOM"} ,
		{"word":"shunt","word_nosc":"shunt","lemma":"shunt","pos":"NOM"} ,
		{"word":"shéol","word_nosc":"sheol","lemma":"shéol","pos":"NOM"} ,
		{"word":"shérif","word_nosc":"sherif","lemma":"shérif","pos":"NOM"} ,
		{"word":"shérifs","word_nosc":"sherifs","lemma":"shérif","pos":"NOM"} ,
		{"word":"shôgun","word_nosc":"shogun","lemma":"shôgun","pos":"NOM"} ,
		{"word":"si","word_nosc":"si","lemma":"si","pos":"NOM"} ,
		{"word":"siam","word_nosc":"siam","lemma":"siam","pos":"NOM"} ,
		{"word":"siamois","word_nosc":"siamois","lemma":"siamois","pos":"NOM"} ,
		{"word":"siamoise","word_nosc":"siamoise","lemma":"siamois","pos":"NOM"} ,
		{"word":"siamoises","word_nosc":"siamoises","lemma":"siamois","pos":"NOM"} ,
		{"word":"sibylle","word_nosc":"sibylle","lemma":"sibylle","pos":"NOM"} ,
		{"word":"sibylles","word_nosc":"sibylles","lemma":"sibylle","pos":"NOM"} ,
		{"word":"sibérien","word_nosc":"siberien","lemma":"sibérien","pos":"NOM"} ,
		{"word":"sibérienne","word_nosc":"siberienne","lemma":"sibérienne","pos":"NOM"} ,
		{"word":"sibériens","word_nosc":"siberiens","lemma":"sibérien","pos":"NOM"} ,
		{"word":"sicaire","word_nosc":"sicaire","lemma":"sicaire","pos":"NOM"} ,
		{"word":"sicaires","word_nosc":"sicaires","lemma":"sicaire","pos":"NOM"} ,
		{"word":"sicilien","word_nosc":"sicilien","lemma":"sicilien","pos":"NOM"} ,
		{"word":"sicilienne","word_nosc":"sicilienne","lemma":"sicilien","pos":"NOM"} ,
		{"word":"siciliennes","word_nosc":"siciliennes","lemma":"sicilien","pos":"NOM"} ,
		{"word":"siciliens","word_nosc":"siciliens","lemma":"sicilien","pos":"NOM"} ,
		{"word":"sicle","word_nosc":"sicle","lemma":"sicle","pos":"NOM"} ,
		{"word":"sida","word_nosc":"sida","lemma":"sida","pos":"NOM"} ,
		{"word":"sidas","word_nosc":"sidas","lemma":"sida","pos":"NOM"} ,
		{"word":"side-car","word_nosc":"side-car","lemma":"side-car","pos":"NOM"} ,
		{"word":"side-cars","word_nosc":"side-cars","lemma":"side-car","pos":"NOM"} ,
		{"word":"sidi","word_nosc":"sidi","lemma":"sidi","pos":"NOM"} ,
		{"word":"sidis","word_nosc":"sidis","lemma":"sidi","pos":"NOM"} ,
		{"word":"sidéens","word_nosc":"sideens","lemma":"sidéen","pos":"NOM"} ,
		{"word":"sidération","word_nosc":"sideration","lemma":"sidération","pos":"NOM"} ,
		{"word":"sidérite","word_nosc":"siderite","lemma":"sidérite","pos":"NOM"} ,
		{"word":"sidérurgie","word_nosc":"siderurgie","lemma":"sidérurgie","pos":"NOM"} ,
		{"word":"sidérurgistes","word_nosc":"siderurgistes","lemma":"sidérurgiste","pos":"NOM"} ,
		{"word":"siennois","word_nosc":"siennois","lemma":"siennois","pos":"NOM"} ,
		{"word":"sierra","word_nosc":"sierra","lemma":"sierra","pos":"NOM"} ,
		{"word":"sierras","word_nosc":"sierras","lemma":"sierra","pos":"NOM"} ,
		{"word":"sieste","word_nosc":"sieste","lemma":"sieste","pos":"NOM"} ,
		{"word":"siestes","word_nosc":"siestes","lemma":"sieste","pos":"NOM"} ,
		{"word":"sieur","word_nosc":"sieur","lemma":"sieur","pos":"NOM"} ,
		{"word":"sieurs","word_nosc":"sieurs","lemma":"sieur","pos":"NOM"} ,
		{"word":"sifflante","word_nosc":"sifflante","lemma":"sifflante","pos":"NOM"} ,
		{"word":"sifflantes","word_nosc":"sifflantes","lemma":"sifflant","pos":"NOM"} ,
		{"word":"sifflard","word_nosc":"sifflard","lemma":"sifflard","pos":"NOM"} ,
		{"word":"sifflement","word_nosc":"sifflement","lemma":"sifflement","pos":"NOM"} ,
		{"word":"sifflements","word_nosc":"sifflements","lemma":"sifflement","pos":"NOM"} ,
		{"word":"sifflet","word_nosc":"sifflet","lemma":"sifflet","pos":"NOM"} ,
		{"word":"sifflets","word_nosc":"sifflets","lemma":"sifflet","pos":"NOM"} ,
		{"word":"siffleur","word_nosc":"siffleur","lemma":"siffleur","pos":"NOM"} ,
		{"word":"sifflotement","word_nosc":"sifflotement","lemma":"sifflotement","pos":"NOM"} ,
		{"word":"sifflotis","word_nosc":"sifflotis","lemma":"sifflotis","pos":"NOM"} ,
		{"word":"sigisbée","word_nosc":"sigisbee","lemma":"sigisbée","pos":"NOM"} ,
		{"word":"sigisbées","word_nosc":"sigisbees","lemma":"sigisbée","pos":"NOM"} ,
		{"word":"sigle","word_nosc":"sigle","lemma":"sigle","pos":"NOM"} ,
		{"word":"sigles","word_nosc":"sigles","lemma":"sigle","pos":"NOM"} ,
		{"word":"signal","word_nosc":"signal","lemma":"signal","pos":"NOM"} ,
		{"word":"signalement","word_nosc":"signalement","lemma":"signalement","pos":"NOM"} ,
		{"word":"signalements","word_nosc":"signalements","lemma":"signalement","pos":"NOM"} ,
		{"word":"signaleur","word_nosc":"signaleur","lemma":"signaleur","pos":"NOM"} ,
		{"word":"signaleurs","word_nosc":"signaleurs","lemma":"signaleur","pos":"NOM"} ,
		{"word":"signalisation","word_nosc":"signalisation","lemma":"signalisation","pos":"NOM"} ,
		{"word":"signalisations","word_nosc":"signalisations","lemma":"signalisation","pos":"NOM"} ,
		{"word":"signataire","word_nosc":"signataire","lemma":"signataire","pos":"NOM"} ,
		{"word":"signataires","word_nosc":"signataires","lemma":"signataire","pos":"NOM"} ,
		{"word":"signature","word_nosc":"signature","lemma":"signature","pos":"NOM"} ,
		{"word":"signatures","word_nosc":"signatures","lemma":"signature","pos":"NOM"} ,
		{"word":"signaux","word_nosc":"signaux","lemma":"signal","pos":"NOM"} ,
		{"word":"signe","word_nosc":"signe","lemma":"signe","pos":"NOM"} ,
		{"word":"signes","word_nosc":"signes","lemma":"signe","pos":"NOM"} ,
		{"word":"signet","word_nosc":"signet","lemma":"signet","pos":"NOM"} ,
		{"word":"signets","word_nosc":"signets","lemma":"signet","pos":"NOM"} ,
		{"word":"signifiance","word_nosc":"signifiance","lemma":"signifiance","pos":"NOM"} ,
		{"word":"signification","word_nosc":"signification","lemma":"signification","pos":"NOM"} ,
		{"word":"significations","word_nosc":"significations","lemma":"signification","pos":"NOM"} ,
		{"word":"signifié","word_nosc":"signifie","lemma":"signifié","pos":"NOM"} ,
		{"word":"signor","word_nosc":"signor","lemma":"signor","pos":"NOM"} ,
		{"word":"signora","word_nosc":"signora","lemma":"signor","pos":"NOM"} ,
		{"word":"signorina","word_nosc":"signorina","lemma":"signorina","pos":"NOM"} ,
		{"word":"sikh","word_nosc":"sikh","lemma":"sikh","pos":"NOM"} ,
		{"word":"sikhs","word_nosc":"sikhs","lemma":"sikh","pos":"NOM"} ,
		{"word":"sil","word_nosc":"sil","lemma":"sil","pos":"NOM"} ,
		{"word":"silence","word_nosc":"silence","lemma":"silence","pos":"NOM"} ,
		{"word":"silences","word_nosc":"silences","lemma":"silence","pos":"NOM"} ,
		{"word":"silencieux","word_nosc":"silencieux","lemma":"silencieux","pos":"NOM"} ,
		{"word":"silex","word_nosc":"silex","lemma":"silex","pos":"NOM"} ,
		{"word":"silhouette","word_nosc":"silhouette","lemma":"silhouette","pos":"NOM"} ,
		{"word":"silhouettes","word_nosc":"silhouettes","lemma":"silhouette","pos":"NOM"} ,
		{"word":"silicate","word_nosc":"silicate","lemma":"silicate","pos":"NOM"} ,
		{"word":"silice","word_nosc":"silice","lemma":"silice","pos":"NOM"} ,
		{"word":"silices","word_nosc":"silices","lemma":"silice","pos":"NOM"} ,
		{"word":"silicium","word_nosc":"silicium","lemma":"silicium","pos":"NOM"} ,
		{"word":"silicone","word_nosc":"silicone","lemma":"silicone","pos":"NOM"} ,
		{"word":"silicose","word_nosc":"silicose","lemma":"silicose","pos":"NOM"} ,
		{"word":"silionne","word_nosc":"silionne","lemma":"silionne","pos":"NOM"} ,
		{"word":"sillage","word_nosc":"sillage","lemma":"sillage","pos":"NOM"} ,
		{"word":"sillages","word_nosc":"sillages","lemma":"sillage","pos":"NOM"} ,
		{"word":"sillet","word_nosc":"sillet","lemma":"sillet","pos":"NOM"} ,
		{"word":"sillon","word_nosc":"sillon","lemma":"sillon","pos":"NOM"} ,
		{"word":"sillons","word_nosc":"sillons","lemma":"sillon","pos":"NOM"} ,
		{"word":"silo","word_nosc":"silo","lemma":"silo","pos":"NOM"} ,
		{"word":"silos","word_nosc":"silos","lemma":"silo","pos":"NOM"} ,
		{"word":"sils","word_nosc":"sils","lemma":"sil","pos":"NOM"} ,
		{"word":"silvaner","word_nosc":"silvaner","lemma":"silvaner","pos":"NOM"} ,
		{"word":"silène","word_nosc":"silene","lemma":"silène","pos":"NOM"} ,
		{"word":"silènes","word_nosc":"silenes","lemma":"silène","pos":"NOM"} ,
		{"word":"sima","word_nosc":"sima","lemma":"sima","pos":"NOM"} ,
		{"word":"simagrée","word_nosc":"simagree","lemma":"simagrée","pos":"NOM"} ,
		{"word":"simagrées","word_nosc":"simagrees","lemma":"simagrée","pos":"NOM"} ,
		{"word":"simarre","word_nosc":"simarre","lemma":"simarre","pos":"NOM"} ,
		{"word":"simien","word_nosc":"simien","lemma":"simien","pos":"NOM"} ,
		{"word":"simiens","word_nosc":"simiens","lemma":"simien","pos":"NOM"} ,
		{"word":"similarité","word_nosc":"similarite","lemma":"similarité","pos":"NOM"} ,
		{"word":"similarités","word_nosc":"similarites","lemma":"similarité","pos":"NOM"} ,
		{"word":"simili","word_nosc":"simili","lemma":"simili","pos":"NOM"} ,
		{"word":"similicuir","word_nosc":"similicuir","lemma":"similicuir","pos":"NOM"} ,
		{"word":"similitude","word_nosc":"similitude","lemma":"similitude","pos":"NOM"} ,
		{"word":"similitudes","word_nosc":"similitudes","lemma":"similitude","pos":"NOM"} ,
		{"word":"similor","word_nosc":"similor","lemma":"similor","pos":"NOM"} ,
		{"word":"similors","word_nosc":"similors","lemma":"similor","pos":"NOM"} ,
		{"word":"simoun","word_nosc":"simoun","lemma":"simoun","pos":"NOM"} ,
		{"word":"simple","word_nosc":"simple","lemma":"simple","pos":"NOM"} ,
		{"word":"simples","word_nosc":"simples","lemma":"simple","pos":"NOM"} ,
		{"word":"simplesse","word_nosc":"simplesse","lemma":"simplesse","pos":"NOM"} ,
		{"word":"simplet","word_nosc":"simplet","lemma":"simplet","pos":"NOM"} ,
		{"word":"simplets","word_nosc":"simplets","lemma":"simplet","pos":"NOM"} ,
		{"word":"simplette","word_nosc":"simplette","lemma":"simplette","pos":"NOM"} ,
		{"word":"simplicité","word_nosc":"simplicite","lemma":"simplicité","pos":"NOM"} ,
		{"word":"simplification","word_nosc":"simplification","lemma":"simplification","pos":"NOM"} ,
		{"word":"simplifications","word_nosc":"simplifications","lemma":"simplification","pos":"NOM"} ,
		{"word":"simpliste","word_nosc":"simpliste","lemma":"simpliste","pos":"NOM"} ,
		{"word":"simulacre","word_nosc":"simulacre","lemma":"simulacre","pos":"NOM"} ,
		{"word":"simulacres","word_nosc":"simulacres","lemma":"simulacre","pos":"NOM"} ,
		{"word":"simulateur","word_nosc":"simulateur","lemma":"simulateur","pos":"NOM"} ,
		{"word":"simulateurs","word_nosc":"simulateurs","lemma":"simulateur","pos":"NOM"} ,
		{"word":"simulation","word_nosc":"simulation","lemma":"simulation","pos":"NOM"} ,
		{"word":"simulations","word_nosc":"simulations","lemma":"simulation","pos":"NOM"} ,
		{"word":"simulatrice","word_nosc":"simulatrice","lemma":"simulateur","pos":"NOM"} ,
		{"word":"simultanéité","word_nosc":"simultaneite","lemma":"simultanéité","pos":"NOM"} ,
		{"word":"sinapisme","word_nosc":"sinapisme","lemma":"sinapisme","pos":"NOM"} ,
		{"word":"sinapismes","word_nosc":"sinapismes","lemma":"sinapisme","pos":"NOM"} ,
		{"word":"sinciput","word_nosc":"sinciput","lemma":"sinciput","pos":"NOM"} ,
		{"word":"sincérité","word_nosc":"sincerite","lemma":"sincérité","pos":"NOM"} ,
		{"word":"sindon","word_nosc":"sindon","lemma":"sindon","pos":"NOM"} ,
		{"word":"singe","word_nosc":"singe","lemma":"singe","pos":"NOM"} ,
		{"word":"singe-araignée","word_nosc":"singe-araignee","lemma":"singe-araignée","pos":"NOM"} ,
		{"word":"singerie","word_nosc":"singerie","lemma":"singerie","pos":"NOM"} ,
		{"word":"singeries","word_nosc":"singeries","lemma":"singerie","pos":"NOM"} ,
		{"word":"singes","word_nosc":"singes","lemma":"singe","pos":"NOM"} ,
		{"word":"single","word_nosc":"single","lemma":"single","pos":"NOM"} ,
		{"word":"singles","word_nosc":"singles","lemma":"single","pos":"NOM"} ,
		{"word":"singleton","word_nosc":"singleton","lemma":"singleton","pos":"NOM"} ,
		{"word":"singularité","word_nosc":"singularite","lemma":"singularité","pos":"NOM"} ,
		{"word":"singularités","word_nosc":"singularites","lemma":"singularité","pos":"NOM"} ,
		{"word":"singulier","word_nosc":"singulier","lemma":"singulier","pos":"NOM"} ,
		{"word":"singuliers","word_nosc":"singuliers","lemma":"singulier","pos":"NOM"} ,
		{"word":"sinistre","word_nosc":"sinistre","lemma":"sinistre","pos":"NOM"} ,
		{"word":"sinistres","word_nosc":"sinistres","lemma":"sinistre","pos":"NOM"} ,
		{"word":"sinistrose","word_nosc":"sinistrose","lemma":"sinistrose","pos":"NOM"} ,
		{"word":"sinistré","word_nosc":"sinistre","lemma":"sinistré","pos":"NOM"} ,
		{"word":"sinistrés","word_nosc":"sinistres","lemma":"sinistré","pos":"NOM"} ,
		{"word":"sino-américain","word_nosc":"sino-americain","lemma":"sino-américain","pos":"NOM"} ,
		{"word":"sinologie","word_nosc":"sinologie","lemma":"sinologie","pos":"NOM"} ,
		{"word":"sinople","word_nosc":"sinople","lemma":"sinople","pos":"NOM"} ,
		{"word":"sinoquet","word_nosc":"sinoquet","lemma":"sinoquet","pos":"NOM"} ,
		{"word":"sinuosité","word_nosc":"sinuosite","lemma":"sinuosité","pos":"NOM"} ,
		{"word":"sinuosités","word_nosc":"sinuosites","lemma":"sinuosité","pos":"NOM"} ,
		{"word":"sinus","word_nosc":"sinus","lemma":"sinus","pos":"NOM"} ,
		{"word":"sinusite","word_nosc":"sinusite","lemma":"sinusite","pos":"NOM"} ,
		{"word":"sinusites","word_nosc":"sinusites","lemma":"sinusite","pos":"NOM"} ,
		{"word":"sinusoïdes","word_nosc":"sinusoides","lemma":"sinusoïde","pos":"NOM"} ,
		{"word":"sinécure","word_nosc":"sinecure","lemma":"sinécure","pos":"NOM"} ,
		{"word":"sinécures","word_nosc":"sinecures","lemma":"sinécure","pos":"NOM"} ,
		{"word":"sionisme","word_nosc":"sionisme","lemma":"sionisme","pos":"NOM"} ,
		{"word":"sioniste","word_nosc":"sioniste","lemma":"sioniste","pos":"NOM"} ,
		{"word":"sionistes","word_nosc":"sionistes","lemma":"sioniste","pos":"NOM"} ,
		{"word":"sioux","word_nosc":"sioux","lemma":"sioux","pos":"NOM"} ,
		{"word":"siphon","word_nosc":"siphon","lemma":"siphon","pos":"NOM"} ,
		{"word":"siphonnage","word_nosc":"siphonnage","lemma":"siphonnage","pos":"NOM"} ,
		{"word":"siphons","word_nosc":"siphons","lemma":"siphon","pos":"NOM"} ,
		{"word":"sipo","word_nosc":"sipo","lemma":"sipo","pos":"NOM"} ,
		{"word":"sir","word_nosc":"sir","lemma":"sir","pos":"NOM"} ,
		{"word":"sirdar","word_nosc":"sirdar","lemma":"sirdar","pos":"NOM"} ,
		{"word":"sire","word_nosc":"sire","lemma":"sire","pos":"NOM"} ,
		{"word":"sires","word_nosc":"sires","lemma":"sire","pos":"NOM"} ,
		{"word":"sirocco","word_nosc":"sirocco","lemma":"sirocco","pos":"NOM"} ,
		{"word":"sirop","word_nosc":"sirop","lemma":"sirop","pos":"NOM"} ,
		{"word":"sirops","word_nosc":"sirops","lemma":"sirop","pos":"NOM"} ,
		{"word":"sirventès","word_nosc":"sirventes","lemma":"sirventès","pos":"NOM"} ,
		{"word":"sirène","word_nosc":"sirene","lemma":"sirène","pos":"NOM"} ,
		{"word":"sirènes","word_nosc":"sirenes","lemma":"sirène","pos":"NOM"} ,
		{"word":"sisal","word_nosc":"sisal","lemma":"sisal","pos":"NOM"} ,
		{"word":"sismographe","word_nosc":"sismographe","lemma":"sismographe","pos":"NOM"} ,
		{"word":"sismographes","word_nosc":"sismographes","lemma":"sismographe","pos":"NOM"} ,
		{"word":"sismologie","word_nosc":"sismologie","lemma":"sismologie","pos":"NOM"} ,
		{"word":"sismologue","word_nosc":"sismologue","lemma":"sismologue","pos":"NOM"} ,
		{"word":"sismologues","word_nosc":"sismologues","lemma":"sismologue","pos":"NOM"} ,
		{"word":"sismomètre","word_nosc":"sismometre","lemma":"sismomètre","pos":"NOM"} ,
		{"word":"sismothérapie","word_nosc":"sismotherapie","lemma":"sismothérapie","pos":"NOM"} ,
		{"word":"sisymbre","word_nosc":"sisymbre","lemma":"sisymbre","pos":"NOM"} ,
		{"word":"sit-in","word_nosc":"sit-in","lemma":"sit-in","pos":"NOM"} ,
		{"word":"sitar","word_nosc":"sitar","lemma":"sitar","pos":"NOM"} ,
		{"word":"sitariste","word_nosc":"sitariste","lemma":"sitariste","pos":"NOM"} ,
		{"word":"sitcom","word_nosc":"sitcom","lemma":"sitcom","pos":"NOM"} ,
		{"word":"sitcoms","word_nosc":"sitcoms","lemma":"sitcom","pos":"NOM"} ,
		{"word":"site","word_nosc":"site","lemma":"site","pos":"NOM"} ,
		{"word":"sites","word_nosc":"sites","lemma":"site","pos":"NOM"} ,
		{"word":"sittelle","word_nosc":"sittelle","lemma":"sittelle","pos":"NOM"} ,
		{"word":"situ","word_nosc":"situ","lemma":"situ","pos":"NOM"} ,
		{"word":"situation","word_nosc":"situation","lemma":"situation","pos":"NOM"} ,
		{"word":"situationnisme","word_nosc":"situationnisme","lemma":"situationnisme","pos":"NOM"} ,
		{"word":"situations","word_nosc":"situations","lemma":"situation","pos":"NOM"} ,
		{"word":"six-quatre","word_nosc":"six-quatre","lemma":"six-quatre","pos":"NOM"} ,
		{"word":"sixain","word_nosc":"sixain","lemma":"sixain","pos":"NOM"} ,
		{"word":"sixième","word_nosc":"sixieme","lemma":"sixième","pos":"NOM"} ,
		{"word":"sixièmes","word_nosc":"sixiemes","lemma":"sixième","pos":"NOM"} ,
		{"word":"sixte","word_nosc":"sixte","lemma":"sixte","pos":"NOM"} ,
		{"word":"sixties","word_nosc":"sixties","lemma":"sixties","pos":"NOM"} ,
		{"word":"sixtus","word_nosc":"sixtus","lemma":"sixtus","pos":"NOM"} ,
		{"word":"sizain","word_nosc":"sizain","lemma":"sizain","pos":"NOM"} ,
		{"word":"siècle","word_nosc":"siecle","lemma":"siècle","pos":"NOM"} ,
		{"word":"siècles","word_nosc":"siecles","lemma":"siècle","pos":"NOM"} ,
		{"word":"siège","word_nosc":"siege","lemma":"siège","pos":"NOM"} ,
		{"word":"sièges","word_nosc":"sieges","lemma":"siège","pos":"NOM"} ,
		{"word":"ska","word_nosc":"ska","lemma":"ska","pos":"NOM"} ,
		{"word":"skate","word_nosc":"skate","lemma":"skate","pos":"NOM"} ,
		{"word":"skate-board","word_nosc":"skate-board","lemma":"skate-board","pos":"NOM"} ,
		{"word":"skateboard","word_nosc":"skateboard","lemma":"skateboard","pos":"NOM"} ,
		{"word":"skateboards","word_nosc":"skateboards","lemma":"skateboard","pos":"NOM"} ,
		{"word":"skates","word_nosc":"skates","lemma":"skate","pos":"NOM"} ,
		{"word":"skating","word_nosc":"skating","lemma":"skating","pos":"NOM"} ,
		{"word":"skaï","word_nosc":"skai","lemma":"skaï","pos":"NOM"} ,
		{"word":"skeet","word_nosc":"skeet","lemma":"skeet","pos":"NOM"} ,
		{"word":"skeets","word_nosc":"skeets","lemma":"skeet","pos":"NOM"} ,
		{"word":"sketch","word_nosc":"sketch","lemma":"sketch","pos":"NOM"} ,
		{"word":"sketches","word_nosc":"sketches","lemma":"sketch","pos":"NOM"} ,
		{"word":"sketchs","word_nosc":"sketchs","lemma":"sketch","pos":"NOM"} ,
		{"word":"ski","word_nosc":"ski","lemma":"ski","pos":"NOM"} ,
		{"word":"skieur","word_nosc":"skieur","lemma":"skieur","pos":"NOM"} ,
		{"word":"skieurs","word_nosc":"skieurs","lemma":"skieur","pos":"NOM"} ,
		{"word":"skieuse","word_nosc":"skieuse","lemma":"skieur","pos":"NOM"} ,
		{"word":"skieuses","word_nosc":"skieuses","lemma":"skieur","pos":"NOM"} ,
		{"word":"skiff","word_nosc":"skiff","lemma":"skiff","pos":"NOM"} ,
		{"word":"skiffs","word_nosc":"skiffs","lemma":"skiff","pos":"NOM"} ,
		{"word":"skin","word_nosc":"skin","lemma":"skin","pos":"NOM"} ,
		{"word":"skinhead","word_nosc":"skinhead","lemma":"skinhead","pos":"NOM"} ,
		{"word":"skinheads","word_nosc":"skinheads","lemma":"skinhead","pos":"NOM"} ,
		{"word":"skins","word_nosc":"skins","lemma":"skin","pos":"NOM"} ,
		{"word":"skip","word_nosc":"skip","lemma":"skip","pos":"NOM"} ,
		{"word":"skipper","word_nosc":"skipper","lemma":"skipper","pos":"NOM"} ,
		{"word":"skis","word_nosc":"skis","lemma":"ski","pos":"NOM"} ,
		{"word":"skunks","word_nosc":"skunks","lemma":"skunks","pos":"NOM"} ,
		{"word":"skydome","word_nosc":"skydome","lemma":"skydome","pos":"NOM"} ,
		{"word":"slalom","word_nosc":"slalom","lemma":"slalom","pos":"NOM"} ,
		{"word":"slaloms","word_nosc":"slaloms","lemma":"slalom","pos":"NOM"} ,
		{"word":"slang","word_nosc":"slang","lemma":"slang","pos":"NOM"} ,
		{"word":"slash","word_nosc":"slash","lemma":"slash","pos":"NOM"} ,
		{"word":"slave","word_nosc":"slave","lemma":"slave","pos":"NOM"} ,
		{"word":"slaves","word_nosc":"slaves","lemma":"slave","pos":"NOM"} ,
		{"word":"slavisme","word_nosc":"slavisme","lemma":"slavisme","pos":"NOM"} ,
		{"word":"slavon","word_nosc":"slavon","lemma":"slavon","pos":"NOM"} ,
		{"word":"sleeping","word_nosc":"sleeping","lemma":"sleeping","pos":"NOM"} ,
		{"word":"sleepings","word_nosc":"sleepings","lemma":"sleeping","pos":"NOM"} ,
		{"word":"slibard","word_nosc":"slibard","lemma":"slibard","pos":"NOM"} ,
		{"word":"slice","word_nosc":"slice","lemma":"slice","pos":"NOM"} ,
		{"word":"slip","word_nosc":"slip","lemma":"slip","pos":"NOM"} ,
		{"word":"slips","word_nosc":"slips","lemma":"slip","pos":"NOM"} ,
		{"word":"slogan","word_nosc":"slogan","lemma":"slogan","pos":"NOM"} ,
		{"word":"slogans","word_nosc":"slogans","lemma":"slogan","pos":"NOM"} ,
		{"word":"sloop","word_nosc":"sloop","lemma":"sloop","pos":"NOM"} ,
		{"word":"sloughi","word_nosc":"sloughi","lemma":"sloughi","pos":"NOM"} ,
		{"word":"slovaques","word_nosc":"slovaques","lemma":"slovaque","pos":"NOM"} ,
		{"word":"slow","word_nosc":"slow","lemma":"slow","pos":"NOM"} ,
		{"word":"slows","word_nosc":"slows","lemma":"slow","pos":"NOM"} ,
		{"word":"slug","word_nosc":"slug","lemma":"slug","pos":"NOM"} ,
		{"word":"slush","word_nosc":"slush","lemma":"slush","pos":"NOM"} ,
		{"word":"smack","word_nosc":"smack","lemma":"smack","pos":"NOM"} ,
		{"word":"smacks","word_nosc":"smacks","lemma":"smack","pos":"NOM"} ,
		{"word":"smala","word_nosc":"smala","lemma":"smala","pos":"NOM"} ,
		{"word":"smalah","word_nosc":"smalah","lemma":"smalah","pos":"NOM"} ,
		{"word":"smash","word_nosc":"smash","lemma":"smash","pos":"NOM"} ,
		{"word":"smegma","word_nosc":"smegma","lemma":"smegma","pos":"NOM"} ,
		{"word":"smicard","word_nosc":"smicard","lemma":"smicard","pos":"NOM"} ,
		{"word":"smicards","word_nosc":"smicards","lemma":"smicard","pos":"NOM"} ,
		{"word":"smigard","word_nosc":"smigard","lemma":"smigard","pos":"NOM"} ,
		{"word":"smiley","word_nosc":"smiley","lemma":"smiley","pos":"NOM"} ,
		{"word":"smocks","word_nosc":"smocks","lemma":"smocks","pos":"NOM"} ,
		{"word":"smog","word_nosc":"smog","lemma":"smog","pos":"NOM"} ,
		{"word":"smoking","word_nosc":"smoking","lemma":"smoking","pos":"NOM"} ,
		{"word":"smokings","word_nosc":"smokings","lemma":"smoking","pos":"NOM"} ,
		{"word":"smorrebrod","word_nosc":"smorrebrod","lemma":"smorrebrod","pos":"NOM"} ,
		{"word":"smurf","word_nosc":"smurf","lemma":"smurf","pos":"NOM"} ,
		{"word":"smyrniote","word_nosc":"smyrniote","lemma":"smyrniote","pos":"NOM"} ,
		{"word":"snack","word_nosc":"snack","lemma":"snack","pos":"NOM"} ,
		{"word":"snack-bar","word_nosc":"snack-bar","lemma":"snack-bar","pos":"NOM"} ,
		{"word":"snack-bars","word_nosc":"snack-bars","lemma":"snack-bar","pos":"NOM"} ,
		{"word":"snacks","word_nosc":"snacks","lemma":"snack","pos":"NOM"} ,
		{"word":"sniffette","word_nosc":"sniffette","lemma":"sniffette","pos":"NOM"} ,
		{"word":"snipe","word_nosc":"snipe","lemma":"snipe","pos":"NOM"} ,
		{"word":"sniper","word_nosc":"sniper","lemma":"sniper","pos":"NOM"} ,
		{"word":"snipers","word_nosc":"snipers","lemma":"sniper","pos":"NOM"} ,
		{"word":"snob","word_nosc":"snob","lemma":"snob","pos":"NOM"} ,
		{"word":"snobinard","word_nosc":"snobinard","lemma":"snobinard","pos":"NOM"} ,
		{"word":"snobinarde","word_nosc":"snobinarde","lemma":"snobinard","pos":"NOM"} ,
		{"word":"snobinardes","word_nosc":"snobinardes","lemma":"snobinard","pos":"NOM"} ,
		{"word":"snobinards","word_nosc":"snobinards","lemma":"snobinard","pos":"NOM"} ,
		{"word":"snobisme","word_nosc":"snobisme","lemma":"snobisme","pos":"NOM"} ,
		{"word":"snobismes","word_nosc":"snobismes","lemma":"snobisme","pos":"NOM"} ,
		{"word":"snobs","word_nosc":"snobs","lemma":"snob","pos":"NOM"} ,
		{"word":"snow-boots","word_nosc":"snow-boots","lemma":"snow-boot","pos":"NOM"} ,
		{"word":"soap opéra","word_nosc":"soap opera","lemma":"soap-opéra","pos":"NOM"} ,
		{"word":"soap-opéra","word_nosc":"soap-opera","lemma":"soap-opéra","pos":"NOM"} ,
		{"word":"sobriquet","word_nosc":"sobriquet","lemma":"sobriquet","pos":"NOM"} ,
		{"word":"sobriquets","word_nosc":"sobriquets","lemma":"sobriquet","pos":"NOM"} ,
		{"word":"sobriété","word_nosc":"sobriete","lemma":"sobriété","pos":"NOM"} ,
		{"word":"soc","word_nosc":"soc","lemma":"soc","pos":"NOM"} ,
		{"word":"soccer","word_nosc":"soccer","lemma":"soccer","pos":"NOM"} ,
		{"word":"sociabilité","word_nosc":"sociabilite","lemma":"sociabilité","pos":"NOM"} ,
		{"word":"social","word_nosc":"social","lemma":"social","pos":"NOM"} ,
		{"word":"social-démocrate","word_nosc":"social-democrate","lemma":"social-démocrate","pos":"NOM"} ,
		{"word":"social-démocratie","word_nosc":"social-democratie","lemma":"social-démocratie","pos":"NOM"} ,
		{"word":"social-traître","word_nosc":"social-traitre","lemma":"social-traître","pos":"NOM"} ,
		{"word":"socialisation","word_nosc":"socialisation","lemma":"socialisation","pos":"NOM"} ,
		{"word":"socialisme","word_nosc":"socialisme","lemma":"socialisme","pos":"NOM"} ,
		{"word":"socialismes","word_nosc":"socialismes","lemma":"socialisme","pos":"NOM"} ,
		{"word":"socialiste","word_nosc":"socialiste","lemma":"socialiste","pos":"NOM"} ,
		{"word":"socialistes","word_nosc":"socialistes","lemma":"socialiste","pos":"NOM"} ,
		{"word":"socialo","word_nosc":"socialo","lemma":"socialo","pos":"NOM"} ,
		{"word":"socialos","word_nosc":"socialos","lemma":"socialo","pos":"NOM"} ,
		{"word":"sociaux-démocrates","word_nosc":"sociaux-democrates","lemma":"social-démocrate","pos":"NOM"} ,
		{"word":"sociniens","word_nosc":"sociniens","lemma":"socinien","pos":"NOM"} ,
		{"word":"socio","word_nosc":"socio","lemma":"socio","pos":"NOM"} ,
		{"word":"sociobiologie","word_nosc":"sociobiologie","lemma":"sociobiologie","pos":"NOM"} ,
		{"word":"sociologie","word_nosc":"sociologie","lemma":"sociologie","pos":"NOM"} ,
		{"word":"sociologue","word_nosc":"sociologue","lemma":"sociologue","pos":"NOM"} ,
		{"word":"sociologues","word_nosc":"sociologues","lemma":"sociologue","pos":"NOM"} ,
		{"word":"sociopathie","word_nosc":"sociopathie","lemma":"sociopathie","pos":"NOM"} ,
		{"word":"socius","word_nosc":"socius","lemma":"socius","pos":"NOM"} ,
		{"word":"sociétaire","word_nosc":"societaire","lemma":"sociétaire","pos":"NOM"} ,
		{"word":"sociétaires","word_nosc":"societaires","lemma":"sociétaire","pos":"NOM"} ,
		{"word":"société","word_nosc":"societe","lemma":"société","pos":"NOM"} ,
		{"word":"sociétés","word_nosc":"societes","lemma":"société","pos":"NOM"} ,
		{"word":"socket","word_nosc":"socket","lemma":"socket","pos":"NOM"} ,
		{"word":"socle","word_nosc":"socle","lemma":"socle","pos":"NOM"} ,
		{"word":"socles","word_nosc":"socles","lemma":"socle","pos":"NOM"} ,
		{"word":"socque","word_nosc":"socque","lemma":"socque","pos":"NOM"} ,
		{"word":"socques","word_nosc":"socques","lemma":"socque","pos":"NOM"} ,
		{"word":"socquette","word_nosc":"socquette","lemma":"socquette","pos":"NOM"} ,
		{"word":"socquettes","word_nosc":"socquettes","lemma":"socquette","pos":"NOM"} ,
		{"word":"socs","word_nosc":"socs","lemma":"soc","pos":"NOM"} ,
		{"word":"soda","word_nosc":"soda","lemma":"soda","pos":"NOM"} ,
		{"word":"sodas","word_nosc":"sodas","lemma":"soda","pos":"NOM"} ,
		{"word":"sodium","word_nosc":"sodium","lemma":"sodium","pos":"NOM"} ,
		{"word":"sodomie","word_nosc":"sodomie","lemma":"sodomie","pos":"NOM"} ,
		{"word":"sodomisation","word_nosc":"sodomisation","lemma":"sodomisation","pos":"NOM"} ,
		{"word":"sodomite","word_nosc":"sodomite","lemma":"sodomite","pos":"NOM"} ,
		{"word":"sodomites","word_nosc":"sodomites","lemma":"sodomite","pos":"NOM"} ,
		{"word":"soeur","word_nosc":"soeur","lemma":"soeur","pos":"NOM"} ,
		{"word":"soeurette","word_nosc":"soeurette","lemma":"soeurette","pos":"NOM"} ,
		{"word":"soeurs","word_nosc":"soeurs","lemma":"soeur","pos":"NOM"} ,
		{"word":"sofa","word_nosc":"sofa","lemma":"sofa","pos":"NOM"} ,
		{"word":"sofas","word_nosc":"sofas","lemma":"sofa","pos":"NOM"} ,
		{"word":"soft","word_nosc":"soft","lemma":"soft","pos":"NOM"} ,
		{"word":"softball","word_nosc":"softball","lemma":"softball","pos":"NOM"} ,
		{"word":"soi","word_nosc":"soi","lemma":"soi","pos":"NOM"} ,
		{"word":"soie","word_nosc":"soie","lemma":"soie","pos":"NOM"} ,
		{"word":"soierie","word_nosc":"soierie","lemma":"soierie","pos":"NOM"} ,
		{"word":"soieries","word_nosc":"soieries","lemma":"soierie","pos":"NOM"} ,
		{"word":"soies","word_nosc":"soies","lemma":"soie","pos":"NOM"} ,
		{"word":"soif","word_nosc":"soif","lemma":"soif","pos":"NOM"} ,
		{"word":"soiffard","word_nosc":"soiffard","lemma":"soiffard","pos":"NOM"} ,
		{"word":"soiffarde","word_nosc":"soiffarde","lemma":"soiffard","pos":"NOM"} ,
		{"word":"soiffards","word_nosc":"soiffards","lemma":"soiffard","pos":"NOM"} ,
		{"word":"soifs","word_nosc":"soifs","lemma":"soif","pos":"NOM"} ,
		{"word":"soignant","word_nosc":"soignant","lemma":"soignant","pos":"NOM"} ,
		{"word":"soignante","word_nosc":"soignante","lemma":"soignant","pos":"NOM"} ,
		{"word":"soignantes","word_nosc":"soignantes","lemma":"soignant","pos":"NOM"} ,
		{"word":"soignants","word_nosc":"soignants","lemma":"soignant","pos":"NOM"} ,
		{"word":"soigneur","word_nosc":"soigneur","lemma":"soigneur","pos":"NOM"} ,
		{"word":"soigneurs","word_nosc":"soigneurs","lemma":"soigneur","pos":"NOM"} ,
		{"word":"soin","word_nosc":"soin","lemma":"soin","pos":"NOM"} ,
		{"word":"soins","word_nosc":"soins","lemma":"soin","pos":"NOM"} ,
		{"word":"soir","word_nosc":"soir","lemma":"soir","pos":"NOM"} ,
		{"word":"soirs","word_nosc":"soirs","lemma":"soir","pos":"NOM"} ,
		{"word":"soirée","word_nosc":"soiree","lemma":"soirée","pos":"NOM"} ,
		{"word":"soirées","word_nosc":"soirees","lemma":"soirée","pos":"NOM"} ,
		{"word":"soissonnais","word_nosc":"soissonnais","lemma":"soissonnais","pos":"NOM"} ,
		{"word":"soixantaine","word_nosc":"soixantaine","lemma":"soixantaine","pos":"NOM"} ,
		{"word":"soixante","word_nosc":"soixante","lemma":"soixante","pos":"NOM"} ,
		{"word":"soixante-dix","word_nosc":"soixante-dix","lemma":"soixante-dix","pos":"NOM"} ,
		{"word":"soixante-dixième","word_nosc":"soixante-dixieme","lemma":"soixante-dixième","pos":"NOM"} ,
		{"word":"soixante-huitard","word_nosc":"soixante-huitard","lemma":"soixante-huitard","pos":"NOM"} ,
		{"word":"soixante-huitards","word_nosc":"soixante-huitards","lemma":"soixante-huitard","pos":"NOM"} ,
		{"word":"soixantième","word_nosc":"soixantieme","lemma":"soixantième","pos":"NOM"} ,
		{"word":"soja","word_nosc":"soja","lemma":"soja","pos":"NOM"} ,
		{"word":"sol","word_nosc":"sol","lemma":"sol","pos":"NOM"} ,
		{"word":"solanacée","word_nosc":"solanacee","lemma":"solanacée","pos":"NOM"} ,
		{"word":"solanacées","word_nosc":"solanacees","lemma":"solanacée","pos":"NOM"} ,
		{"word":"solarium","word_nosc":"solarium","lemma":"solarium","pos":"NOM"} ,
		{"word":"solariums","word_nosc":"solariums","lemma":"solarium","pos":"NOM"} ,
		{"word":"soldat","word_nosc":"soldat","lemma":"soldat","pos":"NOM"} ,
		{"word":"soldate","word_nosc":"soldate","lemma":"soldat","pos":"NOM"} ,
		{"word":"soldatesque","word_nosc":"soldatesque","lemma":"soldatesque","pos":"NOM"} ,
		{"word":"soldats","word_nosc":"soldats","lemma":"soldat","pos":"NOM"} ,
		{"word":"solde","word_nosc":"solde","lemma":"solde","pos":"NOM"} ,
		{"word":"solderie","word_nosc":"solderie","lemma":"solderie","pos":"NOM"} ,
		{"word":"soldes","word_nosc":"soldes","lemma":"solde","pos":"NOM"} ,
		{"word":"soldeur","word_nosc":"soldeur","lemma":"soldeur","pos":"NOM"} ,
		{"word":"soldeurs","word_nosc":"soldeurs","lemma":"soldeur","pos":"NOM"} ,
		{"word":"sole","word_nosc":"sole","lemma":"sole","pos":"NOM"} ,
		{"word":"soleil","word_nosc":"soleil","lemma":"soleil","pos":"NOM"} ,
		{"word":"soleil-roi","word_nosc":"soleil-roi","lemma":"soleil-roi","pos":"NOM"} ,
		{"word":"soleils","word_nosc":"soleils","lemma":"soleil","pos":"NOM"} ,
		{"word":"solen","word_nosc":"solen","lemma":"solen","pos":"NOM"} ,
		{"word":"solennité","word_nosc":"solennite","lemma":"solennité","pos":"NOM"} ,
		{"word":"solennités","word_nosc":"solennites","lemma":"solennité","pos":"NOM"} ,
		{"word":"soles","word_nosc":"soles","lemma":"sole","pos":"NOM"} ,
		{"word":"solex","word_nosc":"solex","lemma":"solex","pos":"NOM"} ,
		{"word":"solfatares","word_nosc":"solfatares","lemma":"solfatare","pos":"NOM"} ,
		{"word":"solfège","word_nosc":"solfege","lemma":"solfège","pos":"NOM"} ,
		{"word":"soli","word_nosc":"soli","lemma":"solo","pos":"NOM"} ,
		{"word":"solicitor","word_nosc":"solicitor","lemma":"solicitor","pos":"NOM"} ,
		{"word":"solicitors","word_nosc":"solicitors","lemma":"solicitor","pos":"NOM"} ,
		{"word":"solidarité","word_nosc":"solidarite","lemma":"solidarité","pos":"NOM"} ,
		{"word":"solidarités","word_nosc":"solidarites","lemma":"solidarité","pos":"NOM"} ,
		{"word":"solide","word_nosc":"solide","lemma":"solide","pos":"NOM"} ,
		{"word":"solides","word_nosc":"solides","lemma":"solide","pos":"NOM"} ,
		{"word":"solidification","word_nosc":"solidification","lemma":"solidification","pos":"NOM"} ,
		{"word":"solidité","word_nosc":"solidite","lemma":"solidité","pos":"NOM"} ,
		{"word":"solidus","word_nosc":"solidus","lemma":"solidus","pos":"NOM"} ,
		{"word":"soliflore","word_nosc":"soliflore","lemma":"soliflore","pos":"NOM"} ,
		{"word":"soliloque","word_nosc":"soliloque","lemma":"soliloque","pos":"NOM"} ,
		{"word":"soliloques","word_nosc":"soliloques","lemma":"soliloque","pos":"NOM"} ,
		{"word":"solipsisme","word_nosc":"solipsisme","lemma":"solipsisme","pos":"NOM"} ,
		{"word":"solipsiste","word_nosc":"solipsiste","lemma":"solipsiste","pos":"NOM"} ,
		{"word":"soliste","word_nosc":"soliste","lemma":"soliste","pos":"NOM"} ,
		{"word":"solistes","word_nosc":"solistes","lemma":"soliste","pos":"NOM"} ,
		{"word":"solitaire","word_nosc":"solitaire","lemma":"solitaire","pos":"NOM"} ,
		{"word":"solitaires","word_nosc":"solitaires","lemma":"solitaire","pos":"NOM"} ,
		{"word":"solitude","word_nosc":"solitude","lemma":"solitude","pos":"NOM"} ,
		{"word":"solitudes","word_nosc":"solitudes","lemma":"solitude","pos":"NOM"} ,
		{"word":"solive","word_nosc":"solive","lemma":"solive","pos":"NOM"} ,
		{"word":"soliveau","word_nosc":"soliveau","lemma":"soliveau","pos":"NOM"} ,
		{"word":"solives","word_nosc":"solives","lemma":"solive","pos":"NOM"} ,
		{"word":"sollicitation","word_nosc":"sollicitation","lemma":"sollicitation","pos":"NOM"} ,
		{"word":"sollicitations","word_nosc":"sollicitations","lemma":"sollicitation","pos":"NOM"} ,
		{"word":"solliciteur","word_nosc":"solliciteur","lemma":"solliciteur","pos":"NOM"} ,
		{"word":"solliciteurs","word_nosc":"solliciteurs","lemma":"solliciteur","pos":"NOM"} ,
		{"word":"solliciteuse","word_nosc":"solliciteuse","lemma":"solliciteur","pos":"NOM"} ,
		{"word":"sollicitude","word_nosc":"sollicitude","lemma":"sollicitude","pos":"NOM"} ,
		{"word":"sollicitudes","word_nosc":"sollicitudes","lemma":"sollicitude","pos":"NOM"} ,
		{"word":"solo","word_nosc":"solo","lemma":"solo","pos":"NOM"} ,
		{"word":"solos","word_nosc":"solos","lemma":"solo","pos":"NOM"} ,
		{"word":"sols","word_nosc":"sols","lemma":"sol","pos":"NOM"} ,
		{"word":"solstice","word_nosc":"solstice","lemma":"solstice","pos":"NOM"} ,
		{"word":"solstices","word_nosc":"solstices","lemma":"solstice","pos":"NOM"} ,
		{"word":"solubilité","word_nosc":"solubilite","lemma":"solubilité","pos":"NOM"} ,
		{"word":"solucamphre","word_nosc":"solucamphre","lemma":"solucamphre","pos":"NOM"} ,
		{"word":"solution","word_nosc":"solution","lemma":"solution","pos":"NOM"} ,
		{"word":"solutionneur","word_nosc":"solutionneur","lemma":"solutionneur","pos":"NOM"} ,
		{"word":"solutions","word_nosc":"solutions","lemma":"solution","pos":"NOM"} ,
		{"word":"soluté","word_nosc":"solute","lemma":"soluté","pos":"NOM"} ,
		{"word":"solutés","word_nosc":"solutes","lemma":"soluté","pos":"NOM"} ,
		{"word":"solvabilité","word_nosc":"solvabilite","lemma":"solvabilité","pos":"NOM"} ,
		{"word":"solvant","word_nosc":"solvant","lemma":"solvant","pos":"NOM"} ,
		{"word":"solvants","word_nosc":"solvants","lemma":"solvant","pos":"NOM"} ,
		{"word":"solécismes","word_nosc":"solecismes","lemma":"solécisme","pos":"NOM"} ,
		{"word":"solénoïde","word_nosc":"solenoide","lemma":"solénoïde","pos":"NOM"} ,
		{"word":"solénoïdes","word_nosc":"solenoides","lemma":"solénoïde","pos":"NOM"} ,
		{"word":"soma","word_nosc":"soma","lemma":"soma","pos":"NOM"} ,
		{"word":"somaliens","word_nosc":"somaliens","lemma":"somalien","pos":"NOM"} ,
		{"word":"sombrero","word_nosc":"sombrero","lemma":"sombrero","pos":"NOM"} ,
		{"word":"sombreros","word_nosc":"sombreros","lemma":"sombrero","pos":"NOM"} ,
		{"word":"sommaire","word_nosc":"sommaire","lemma":"sommaire","pos":"NOM"} ,
		{"word":"sommaires","word_nosc":"sommaires","lemma":"sommaire","pos":"NOM"} ,
		{"word":"sommation","word_nosc":"sommation","lemma":"sommation","pos":"NOM"} ,
		{"word":"sommations","word_nosc":"sommations","lemma":"sommation","pos":"NOM"} ,
		{"word":"somme","word_nosc":"somme","lemma":"somme","pos":"NOM"} ,
		{"word":"sommeil","word_nosc":"sommeil","lemma":"sommeil","pos":"NOM"} ,
		{"word":"sommeils","word_nosc":"sommeils","lemma":"sommeil","pos":"NOM"} ,
		{"word":"sommelier","word_nosc":"sommelier","lemma":"sommelier","pos":"NOM"} ,
		{"word":"sommes","word_nosc":"sommes","lemma":"somme","pos":"NOM"} ,
		{"word":"sommet","word_nosc":"sommet","lemma":"sommet","pos":"NOM"} ,
		{"word":"sommets","word_nosc":"sommets","lemma":"sommet","pos":"NOM"} ,
		{"word":"sommier","word_nosc":"sommier","lemma":"sommier","pos":"NOM"} ,
		{"word":"sommiers","word_nosc":"sommiers","lemma":"sommier","pos":"NOM"} ,
		{"word":"sommité","word_nosc":"sommite","lemma":"sommité","pos":"NOM"} ,
		{"word":"sommités","word_nosc":"sommites","lemma":"sommité","pos":"NOM"} ,
		{"word":"somnambule","word_nosc":"somnambule","lemma":"somnambule","pos":"NOM"} ,
		{"word":"somnambules","word_nosc":"somnambules","lemma":"somnambule","pos":"NOM"} ,
		{"word":"somnambulisme","word_nosc":"somnambulisme","lemma":"somnambulisme","pos":"NOM"} ,
		{"word":"somnifère","word_nosc":"somnifere","lemma":"somnifère","pos":"NOM"} ,
		{"word":"somnifères","word_nosc":"somniferes","lemma":"somnifère","pos":"NOM"} ,
		{"word":"somno","word_nosc":"somno","lemma":"somno","pos":"NOM"} ,
		{"word":"somnolence","word_nosc":"somnolence","lemma":"somnolence","pos":"NOM"} ,
		{"word":"somnolences","word_nosc":"somnolences","lemma":"somnolence","pos":"NOM"} ,
		{"word":"somozistes","word_nosc":"somozistes","lemma":"somoziste","pos":"NOM"} ,
		{"word":"somptuosité","word_nosc":"somptuosite","lemma":"somptuosité","pos":"NOM"} ,
		{"word":"somptuosités","word_nosc":"somptuosites","lemma":"somptuosité","pos":"NOM"} ,
		{"word":"son","word_nosc":"son","lemma":"son","pos":"NOM"} ,
		{"word":"sonagramme","word_nosc":"sonagramme","lemma":"sonagramme","pos":"NOM"} ,
		{"word":"sonar","word_nosc":"sonar","lemma":"sonar","pos":"NOM"} ,
		{"word":"sonars","word_nosc":"sonars","lemma":"sonar","pos":"NOM"} ,
		{"word":"sonate","word_nosc":"sonate","lemma":"sonate","pos":"NOM"} ,
		{"word":"sonates","word_nosc":"sonates","lemma":"sonate","pos":"NOM"} ,
		{"word":"sonatine","word_nosc":"sonatine","lemma":"sonatine","pos":"NOM"} ,
		{"word":"sondage","word_nosc":"sondage","lemma":"sondage","pos":"NOM"} ,
		{"word":"sondages","word_nosc":"sondages","lemma":"sondage","pos":"NOM"} ,
		{"word":"sonde","word_nosc":"sonde","lemma":"sonde","pos":"NOM"} ,
		{"word":"sondes","word_nosc":"sondes","lemma":"sonde","pos":"NOM"} ,
		{"word":"sondeur","word_nosc":"sondeur","lemma":"sondeur","pos":"NOM"} ,
		{"word":"sondeurs","word_nosc":"sondeurs","lemma":"sondeur","pos":"NOM"} ,
		{"word":"sondeuse","word_nosc":"sondeuse","lemma":"sondeur","pos":"NOM"} ,
		{"word":"sondé","word_nosc":"sonde","lemma":"sondé","pos":"NOM"} ,
		{"word":"sondée","word_nosc":"sondee","lemma":"sondé","pos":"NOM"} ,
		{"word":"sondés","word_nosc":"sondes","lemma":"sondé","pos":"NOM"} ,
		{"word":"song","word_nosc":"song","lemma":"song","pos":"NOM"} ,
		{"word":"songe","word_nosc":"songe","lemma":"songe","pos":"NOM"} ,
		{"word":"songe-creux","word_nosc":"songe-creux","lemma":"songe-creux","pos":"NOM"} ,
		{"word":"songerie","word_nosc":"songerie","lemma":"songerie","pos":"NOM"} ,
		{"word":"songeries","word_nosc":"songeries","lemma":"songerie","pos":"NOM"} ,
		{"word":"songes","word_nosc":"songes","lemma":"songe","pos":"NOM"} ,
		{"word":"songeuse","word_nosc":"songeuse","lemma":"songeur","pos":"NOM"} ,
		{"word":"sonnaille","word_nosc":"sonnaille","lemma":"sonnaille","pos":"NOM"} ,
		{"word":"sonnailles","word_nosc":"sonnailles","lemma":"sonnaille","pos":"NOM"} ,
		{"word":"sonnerie","word_nosc":"sonnerie","lemma":"sonnerie","pos":"NOM"} ,
		{"word":"sonneries","word_nosc":"sonneries","lemma":"sonnerie","pos":"NOM"} ,
		{"word":"sonnet","word_nosc":"sonnet","lemma":"sonnet","pos":"NOM"} ,
		{"word":"sonnets","word_nosc":"sonnets","lemma":"sonnet","pos":"NOM"} ,
		{"word":"sonnette","word_nosc":"sonnette","lemma":"sonnette","pos":"NOM"} ,
		{"word":"sonnettes","word_nosc":"sonnettes","lemma":"sonnette","pos":"NOM"} ,
		{"word":"sonneur","word_nosc":"sonneur","lemma":"sonneur","pos":"NOM"} ,
		{"word":"sonneurs","word_nosc":"sonneurs","lemma":"sonneur","pos":"NOM"} ,
		{"word":"sono","word_nosc":"sono","lemma":"sono","pos":"NOM"} ,
		{"word":"sonoluminescence","word_nosc":"sonoluminescence","lemma":"sonoluminescence","pos":"NOM"} ,
		{"word":"sonomètres","word_nosc":"sonometres","lemma":"sonomètre","pos":"NOM"} ,
		{"word":"sonorisateurs","word_nosc":"sonorisateurs","lemma":"sonorisateur","pos":"NOM"} ,
		{"word":"sonorisation","word_nosc":"sonorisation","lemma":"sonorisation","pos":"NOM"} ,
		{"word":"sonorité","word_nosc":"sonorite","lemma":"sonorité","pos":"NOM"} ,
		{"word":"sonorités","word_nosc":"sonorites","lemma":"sonorité","pos":"NOM"} ,
		{"word":"sonos","word_nosc":"sonos","lemma":"sono","pos":"NOM"} ,
		{"word":"sonothèque","word_nosc":"sonotheque","lemma":"sonothèque","pos":"NOM"} ,
		{"word":"sonotone","word_nosc":"sonotone","lemma":"sonotone","pos":"NOM"} ,
		{"word":"sonotones","word_nosc":"sonotones","lemma":"sonotone","pos":"NOM"} ,
		{"word":"sons","word_nosc":"sons","lemma":"son","pos":"NOM"} ,
		{"word":"sopha","word_nosc":"sopha","lemma":"sopha","pos":"NOM"} ,
		{"word":"sophie","word_nosc":"sophie","lemma":"sophie","pos":"NOM"} ,
		{"word":"sophisme","word_nosc":"sophisme","lemma":"sophisme","pos":"NOM"} ,
		{"word":"sophismes","word_nosc":"sophismes","lemma":"sophisme","pos":"NOM"} ,
		{"word":"sophiste","word_nosc":"sophiste","lemma":"sophiste","pos":"NOM"} ,
		{"word":"sophistes","word_nosc":"sophistes","lemma":"sophiste","pos":"NOM"} ,
		{"word":"sophistication","word_nosc":"sophistication","lemma":"sophistication","pos":"NOM"} ,
		{"word":"sophistications","word_nosc":"sophistications","lemma":"sophistication","pos":"NOM"} ,
		{"word":"sophora","word_nosc":"sophora","lemma":"sophora","pos":"NOM"} ,
		{"word":"sopor","word_nosc":"sopor","lemma":"sopor","pos":"NOM"} ,
		{"word":"soprano","word_nosc":"soprano","lemma":"soprano","pos":"NOM"} ,
		{"word":"sopranos","word_nosc":"sopranos","lemma":"soprano","pos":"NOM"} ,
		{"word":"sorbes","word_nosc":"sorbes","lemma":"sorbe","pos":"NOM"} ,
		{"word":"sorbet","word_nosc":"sorbet","lemma":"sorbet","pos":"NOM"} ,
		{"word":"sorbetière","word_nosc":"sorbetiere","lemma":"sorbetière","pos":"NOM"} ,
		{"word":"sorbetières","word_nosc":"sorbetieres","lemma":"sorbetière","pos":"NOM"} ,
		{"word":"sorbets","word_nosc":"sorbets","lemma":"sorbet","pos":"NOM"} ,
		{"word":"sorbier","word_nosc":"sorbier","lemma":"sorbier","pos":"NOM"} ,
		{"word":"sorbiers","word_nosc":"sorbiers","lemma":"sorbier","pos":"NOM"} ,
		{"word":"sorbitol","word_nosc":"sorbitol","lemma":"sorbitol","pos":"NOM"} ,
		{"word":"sorbonnarde","word_nosc":"sorbonnarde","lemma":"sorbonnard","pos":"NOM"} ,
		{"word":"sorbonne","word_nosc":"sorbonne","lemma":"sorbon","pos":"NOM"} ,
		{"word":"sorbonne","word_nosc":"sorbonne","lemma":"sorbonne","pos":"NOM"} ,
		{"word":"sorcellerie","word_nosc":"sorcellerie","lemma":"sorcellerie","pos":"NOM"} ,
		{"word":"sorcelleries","word_nosc":"sorcelleries","lemma":"sorcellerie","pos":"NOM"} ,
		{"word":"sorcier","word_nosc":"sorcier","lemma":"sorcier","pos":"NOM"} ,
		{"word":"sorciers","word_nosc":"sorciers","lemma":"sorcier","pos":"NOM"} ,
		{"word":"sorcière","word_nosc":"sorciere","lemma":"sorcier","pos":"NOM"} ,
		{"word":"sorcières","word_nosc":"sorcieres","lemma":"sorcier","pos":"NOM"} ,
		{"word":"sordidité","word_nosc":"sordidite","lemma":"sordidité","pos":"NOM"} ,
		{"word":"sorgho","word_nosc":"sorgho","lemma":"sorgho","pos":"NOM"} ,
		{"word":"sorite","word_nosc":"sorite","lemma":"sorite","pos":"NOM"} ,
		{"word":"sornette","word_nosc":"sornette","lemma":"sornette","pos":"NOM"} ,
		{"word":"sornettes","word_nosc":"sornettes","lemma":"sornette","pos":"NOM"} ,
		{"word":"sororité","word_nosc":"sororite","lemma":"sororité","pos":"NOM"} ,
		{"word":"sort","word_nosc":"sort","lemma":"sort","pos":"NOM"} ,
		{"word":"sorte","word_nosc":"sorte","lemma":"sorte","pos":"NOM"} ,
		{"word":"sortes","word_nosc":"sortes","lemma":"sorte","pos":"NOM"} ,
		{"word":"sortie","word_nosc":"sortie","lemma":"sortie","pos":"NOM"} ,
		{"word":"sorties","word_nosc":"sorties","lemma":"sortie","pos":"NOM"} ,
		{"word":"sortilège","word_nosc":"sortilege","lemma":"sortilège","pos":"NOM"} ,
		{"word":"sortilèges","word_nosc":"sortileges","lemma":"sortilège","pos":"NOM"} ,
		{"word":"sortir","word_nosc":"sortir","lemma":"sortir","pos":"NOM"} ,
		{"word":"sorts","word_nosc":"sorts","lemma":"sort","pos":"NOM"} ,
		{"word":"sosie","word_nosc":"sosie","lemma":"sosie","pos":"NOM"} ,
		{"word":"sosies","word_nosc":"sosies","lemma":"sosie","pos":"NOM"} ,
		{"word":"sot","word_nosc":"sot","lemma":"sot","pos":"NOM"} ,
		{"word":"sotie","word_nosc":"sotie","lemma":"sotie","pos":"NOM"} ,
		{"word":"sots","word_nosc":"sots","lemma":"sot","pos":"NOM"} ,
		{"word":"sotte","word_nosc":"sotte","lemma":"sot","pos":"NOM"} ,
		{"word":"sottes","word_nosc":"sottes","lemma":"sot","pos":"NOM"} ,
		{"word":"sottise","word_nosc":"sottise","lemma":"sottise","pos":"NOM"} ,
		{"word":"sottises","word_nosc":"sottises","lemma":"sottise","pos":"NOM"} ,
		{"word":"sou","word_nosc":"sou","lemma":"sou","pos":"NOM"} ,
		{"word":"souabes","word_nosc":"souabes","lemma":"souabe","pos":"NOM"} ,
		{"word":"soubassement","word_nosc":"soubassement","lemma":"soubassement","pos":"NOM"} ,
		{"word":"soubassements","word_nosc":"soubassements","lemma":"soubassement","pos":"NOM"} ,
		{"word":"soubise","word_nosc":"soubise","lemma":"soubise","pos":"NOM"} ,
		{"word":"soubresaut","word_nosc":"soubresaut","lemma":"soubresaut","pos":"NOM"} ,
		{"word":"soubresauts","word_nosc":"soubresauts","lemma":"soubresaut","pos":"NOM"} ,
		{"word":"soubrette","word_nosc":"soubrette","lemma":"soubrette","pos":"NOM"} ,
		{"word":"soubrettes","word_nosc":"soubrettes","lemma":"soubrette","pos":"NOM"} ,
		{"word":"souche","word_nosc":"souche","lemma":"souche","pos":"NOM"} ,
		{"word":"souches","word_nosc":"souches","lemma":"souche","pos":"NOM"} ,
		{"word":"souchet","word_nosc":"souchet","lemma":"souchet","pos":"NOM"} ,
		{"word":"souchette","word_nosc":"souchette","lemma":"souchette","pos":"NOM"} ,
		{"word":"souci","word_nosc":"souci","lemma":"souci","pos":"NOM"} ,
		{"word":"soucis","word_nosc":"soucis","lemma":"souci","pos":"NOM"} ,
		{"word":"soucoupe","word_nosc":"soucoupe","lemma":"soucoupe","pos":"NOM"} ,
		{"word":"soucoupes","word_nosc":"soucoupes","lemma":"soucoupe","pos":"NOM"} ,
		{"word":"soudage","word_nosc":"soudage","lemma":"soudage","pos":"NOM"} ,
		{"word":"soudaineté","word_nosc":"soudainete","lemma":"soudaineté","pos":"NOM"} ,
		{"word":"soudanais","word_nosc":"soudanais","lemma":"soudanais","pos":"NOM"} ,
		{"word":"soudard","word_nosc":"soudard","lemma":"soudard","pos":"NOM"} ,
		{"word":"soudards","word_nosc":"soudards","lemma":"soudard","pos":"NOM"} ,
		{"word":"soude","word_nosc":"soude","lemma":"soude","pos":"NOM"} ,
		{"word":"soudeur","word_nosc":"soudeur","lemma":"soudeur","pos":"NOM"} ,
		{"word":"soudeurs","word_nosc":"soudeurs","lemma":"soudeur","pos":"NOM"} ,
		{"word":"soudeuse","word_nosc":"soudeuse","lemma":"soudeur","pos":"NOM"} ,
		{"word":"soudure","word_nosc":"soudure","lemma":"soudure","pos":"NOM"} ,
		{"word":"soudures","word_nosc":"soudures","lemma":"soudure","pos":"NOM"} ,
		{"word":"soue","word_nosc":"soue","lemma":"soue","pos":"NOM"} ,
		{"word":"soufflages","word_nosc":"soufflages","lemma":"soufflage","pos":"NOM"} ,
		{"word":"soufflant","word_nosc":"soufflant","lemma":"soufflant","pos":"NOM"} ,
		{"word":"soufflante","word_nosc":"soufflante","lemma":"soufflant","pos":"NOM"} ,
		{"word":"soufflants","word_nosc":"soufflants","lemma":"soufflant","pos":"NOM"} ,
		{"word":"souffle","word_nosc":"souffle","lemma":"souffle","pos":"NOM"} ,
		{"word":"soufflement","word_nosc":"soufflement","lemma":"soufflement","pos":"NOM"} ,
		{"word":"soufflements","word_nosc":"soufflements","lemma":"soufflement","pos":"NOM"} ,
		{"word":"soufflerie","word_nosc":"soufflerie","lemma":"soufflerie","pos":"NOM"} ,
		{"word":"souffles","word_nosc":"souffles","lemma":"souffle","pos":"NOM"} ,
		{"word":"soufflet","word_nosc":"soufflet","lemma":"soufflet","pos":"NOM"} ,
		{"word":"soufflets","word_nosc":"soufflets","lemma":"soufflet","pos":"NOM"} ,
		{"word":"souffleur","word_nosc":"souffleur","lemma":"souffleur","pos":"NOM"} ,
		{"word":"souffleurs","word_nosc":"souffleurs","lemma":"souffleur","pos":"NOM"} ,
		{"word":"souffleuse","word_nosc":"souffleuse","lemma":"souffleur","pos":"NOM"} ,
		{"word":"soufflure","word_nosc":"soufflure","lemma":"soufflure","pos":"NOM"} ,
		{"word":"soufflures","word_nosc":"soufflures","lemma":"soufflure","pos":"NOM"} ,
		{"word":"soufflé","word_nosc":"souffle","lemma":"soufflé","pos":"NOM"} ,
		{"word":"soufflés","word_nosc":"souffles","lemma":"soufflé","pos":"NOM"} ,
		{"word":"souffrance","word_nosc":"souffrance","lemma":"souffrance","pos":"NOM"} ,
		{"word":"souffrances","word_nosc":"souffrances","lemma":"souffrance","pos":"NOM"} ,
		{"word":"souffre-douleur","word_nosc":"souffre-douleur","lemma":"souffre-douleur","pos":"NOM"} ,
		{"word":"soufis","word_nosc":"soufis","lemma":"soufi","pos":"NOM"} ,
		{"word":"soufrages","word_nosc":"soufrages","lemma":"soufrage","pos":"NOM"} ,
		{"word":"soufre","word_nosc":"soufre","lemma":"soufre","pos":"NOM"} ,
		{"word":"souhait","word_nosc":"souhait","lemma":"souhait","pos":"NOM"} ,
		{"word":"souhaits","word_nosc":"souhaits","lemma":"souhait","pos":"NOM"} ,
		{"word":"souillage","word_nosc":"souillage","lemma":"souillage","pos":"NOM"} ,
		{"word":"souillarde","word_nosc":"souillarde","lemma":"souillard","pos":"NOM"} ,
		{"word":"souille","word_nosc":"souille","lemma":"souille","pos":"NOM"} ,
		{"word":"souilles","word_nosc":"souilles","lemma":"souille","pos":"NOM"} ,
		{"word":"souillon","word_nosc":"souillon","lemma":"souillon","pos":"NOM"} ,
		{"word":"souillons","word_nosc":"souillons","lemma":"souillon","pos":"NOM"} ,
		{"word":"souillure","word_nosc":"souillure","lemma":"souillure","pos":"NOM"} ,
		{"word":"souillures","word_nosc":"souillures","lemma":"souillure","pos":"NOM"} ,
		{"word":"souk","word_nosc":"souk","lemma":"souk","pos":"NOM"} ,
		{"word":"souks","word_nosc":"souks","lemma":"souk","pos":"NOM"} ,
		{"word":"soul","word_nosc":"soul","lemma":"soul","pos":"NOM"} ,
		{"word":"soulagement","word_nosc":"soulagement","lemma":"soulagement","pos":"NOM"} ,
		{"word":"soulagements","word_nosc":"soulagements","lemma":"soulagement","pos":"NOM"} ,
		{"word":"soule","word_nosc":"soule","lemma":"soule","pos":"NOM"} ,
		{"word":"soulier","word_nosc":"soulier","lemma":"soulier","pos":"NOM"} ,
		{"word":"souliers","word_nosc":"souliers","lemma":"soulier","pos":"NOM"} ,
		{"word":"soulèvement","word_nosc":"soulevement","lemma":"soulèvement","pos":"NOM"} ,
		{"word":"soulèvements","word_nosc":"soulevements","lemma":"soulèvement","pos":"NOM"} ,
		{"word":"soumission","word_nosc":"soumission","lemma":"soumission","pos":"NOM"} ,
		{"word":"soumissions","word_nosc":"soumissions","lemma":"soumission","pos":"NOM"} ,
		{"word":"soupape","word_nosc":"soupape","lemma":"soupape","pos":"NOM"} ,
		{"word":"soupapes","word_nosc":"soupapes","lemma":"soupape","pos":"NOM"} ,
		{"word":"soupe","word_nosc":"soupe","lemma":"soupe","pos":"NOM"} ,
		{"word":"soupente","word_nosc":"soupente","lemma":"soupente","pos":"NOM"} ,
		{"word":"soupentes","word_nosc":"soupentes","lemma":"soupente","pos":"NOM"} ,
		{"word":"souper","word_nosc":"souper","lemma":"souper","pos":"NOM"} ,
		{"word":"soupers","word_nosc":"soupers","lemma":"souper","pos":"NOM"} ,
		{"word":"soupes","word_nosc":"soupes","lemma":"soupe","pos":"NOM"} ,
		{"word":"soupeur","word_nosc":"soupeur","lemma":"soupeur","pos":"NOM"} ,
		{"word":"soupeurs","word_nosc":"soupeurs","lemma":"soupeur","pos":"NOM"} ,
		{"word":"soupir","word_nosc":"soupir","lemma":"soupir","pos":"NOM"} ,
		{"word":"soupirail","word_nosc":"soupirail","lemma":"soupirail","pos":"NOM"} ,
		{"word":"soupirant","word_nosc":"soupirant","lemma":"soupirant","pos":"NOM"} ,
		{"word":"soupirants","word_nosc":"soupirants","lemma":"soupirant","pos":"NOM"} ,
		{"word":"soupiraux","word_nosc":"soupiraux","lemma":"soupirail","pos":"NOM"} ,
		{"word":"soupirs","word_nosc":"soupirs","lemma":"soupir","pos":"NOM"} ,
		{"word":"soupière","word_nosc":"soupiere","lemma":"soupière","pos":"NOM"} ,
		{"word":"soupières","word_nosc":"soupieres","lemma":"soupière","pos":"NOM"} ,
		{"word":"souplesse","word_nosc":"souplesse","lemma":"souplesse","pos":"NOM"} ,
		{"word":"souplesses","word_nosc":"souplesses","lemma":"souplesse","pos":"NOM"} ,
		{"word":"soupçon","word_nosc":"soupcon","lemma":"soupçon","pos":"NOM"} ,
		{"word":"soupçons","word_nosc":"soupcons","lemma":"soupçon","pos":"NOM"} ,
		{"word":"souquenille","word_nosc":"souquenille","lemma":"souquenille","pos":"NOM"} ,
		{"word":"souquenilles","word_nosc":"souquenilles","lemma":"souquenille","pos":"NOM"} ,
		{"word":"sourate","word_nosc":"sourate","lemma":"sourate","pos":"NOM"} ,
		{"word":"sourates","word_nosc":"sourates","lemma":"sourate","pos":"NOM"} ,
		{"word":"source","word_nosc":"source","lemma":"source","pos":"NOM"} ,
		{"word":"sources","word_nosc":"sources","lemma":"source","pos":"NOM"} ,
		{"word":"sourcier","word_nosc":"sourcier","lemma":"sourcier","pos":"NOM"} ,
		{"word":"sourciers","word_nosc":"sourciers","lemma":"sourcier","pos":"NOM"} ,
		{"word":"sourcil","word_nosc":"sourcil","lemma":"sourcil","pos":"NOM"} ,
		{"word":"sourcils","word_nosc":"sourcils","lemma":"sourcil","pos":"NOM"} ,
		{"word":"sourd","word_nosc":"sourd","lemma":"sourd","pos":"NOM"} ,
		{"word":"sourd-muet","word_nosc":"sourd-muet","lemma":"sourd-muet","pos":"NOM"} ,
		{"word":"sourde-muette","word_nosc":"sourde-muette","lemma":"sourde-muette","pos":"NOM"} ,
		{"word":"sourdine","word_nosc":"sourdine","lemma":"sourdine","pos":"NOM"} ,
		{"word":"sourdingue","word_nosc":"sourdingue","lemma":"sourdingue","pos":"NOM"} ,
		{"word":"sourdingues","word_nosc":"sourdingues","lemma":"sourdingue","pos":"NOM"} ,
		{"word":"sourds","word_nosc":"sourds","lemma":"sourd","pos":"NOM"} ,
		{"word":"sourds-muets","word_nosc":"sourds-muets","lemma":"sourd-muet","pos":"NOM"} ,
		{"word":"souriceau","word_nosc":"souriceau","lemma":"souriceau","pos":"NOM"} ,
		{"word":"souriceaux","word_nosc":"souriceaux","lemma":"souriceau","pos":"NOM"} ,
		{"word":"souricière","word_nosc":"souriciere","lemma":"souricière","pos":"NOM"} ,
		{"word":"souricières","word_nosc":"souricieres","lemma":"souricière","pos":"NOM"} ,
		{"word":"sourire","word_nosc":"sourire","lemma":"sourire","pos":"NOM"} ,
		{"word":"sourires","word_nosc":"sourires","lemma":"sourire","pos":"NOM"} ,
		{"word":"souris","word_nosc":"souris","lemma":"souris","pos":"NOM"} ,
		{"word":"sournois","word_nosc":"sournois","lemma":"sournois","pos":"NOM"} ,
		{"word":"sournoise","word_nosc":"sournoise","lemma":"sournois","pos":"NOM"} ,
		{"word":"sournoiserie","word_nosc":"sournoiserie","lemma":"sournoiserie","pos":"NOM"} ,
		{"word":"sournoiseries","word_nosc":"sournoiseries","lemma":"sournoiserie","pos":"NOM"} ,
		{"word":"sournoises","word_nosc":"sournoises","lemma":"sournois","pos":"NOM"} ,
		{"word":"sous","word_nosc":"sous","lemma":"sou","pos":"NOM"} ,
		{"word":"sous-alimentation","word_nosc":"sous-alimentation","lemma":"sous-alimentation","pos":"NOM"} ,
		{"word":"sous-alimenté","word_nosc":"sous-alimente","lemma":"sous-alimenté","pos":"NOM"} ,
		{"word":"sous-alimentés","word_nosc":"sous-alimentes","lemma":"sous-alimenté","pos":"NOM"} ,
		{"word":"sous-bibliothécaire","word_nosc":"sous-bibliothecaire","lemma":"sous-bibliothécaire","pos":"NOM"} ,
		{"word":"sous-bois","word_nosc":"sous-bois","lemma":"sous-bois","pos":"NOM"} ,
		{"word":"sous-chef","word_nosc":"sous-chef","lemma":"sous-chef","pos":"NOM"} ,
		{"word":"sous-chefs","word_nosc":"sous-chefs","lemma":"sous-chef","pos":"NOM"} ,
		{"word":"sous-classe","word_nosc":"sous-classe","lemma":"sous-classe","pos":"NOM"} ,
		{"word":"sous-comité","word_nosc":"sous-comite","lemma":"sous-comité","pos":"NOM"} ,
		{"word":"sous-commission","word_nosc":"sous-commission","lemma":"sous-commission","pos":"NOM"} ,
		{"word":"sous-continent","word_nosc":"sous-continent","lemma":"sous-continent","pos":"NOM"} ,
		{"word":"sous-continents","word_nosc":"sous-continents","lemma":"sous-continent","pos":"NOM"} ,
		{"word":"sous-couche","word_nosc":"sous-couche","lemma":"sous-couche","pos":"NOM"} ,
		{"word":"sous-cul","word_nosc":"sous-cul","lemma":"sous-cul","pos":"NOM"} ,
		{"word":"sous-diacre","word_nosc":"sous-diacre","lemma":"sous-diacre","pos":"NOM"} ,
		{"word":"sous-directeur","word_nosc":"sous-directeur","lemma":"sous-directeur","pos":"NOM"} ,
		{"word":"sous-directeurs","word_nosc":"sous-directeurs","lemma":"sous-directeur","pos":"NOM"} ,
		{"word":"sous-directrice","word_nosc":"sous-directrice","lemma":"sous-directeur","pos":"NOM"} ,
		{"word":"sous-division","word_nosc":"sous-division","lemma":"sous-division","pos":"NOM"} ,
		{"word":"sous-dominante","word_nosc":"sous-dominante","lemma":"sous-dominante","pos":"NOM"} ,
		{"word":"sous-développement","word_nosc":"sous-developpement","lemma":"sous-développement","pos":"NOM"} ,
		{"word":"sous-développé","word_nosc":"sous-developpe","lemma":"sous-développé","pos":"NOM"} ,
		{"word":"sous-développés","word_nosc":"sous-developpes","lemma":"sous-développé","pos":"NOM"} ,
		{"word":"sous-effectif","word_nosc":"sous-effectif","lemma":"sous-effectif","pos":"NOM"} ,
		{"word":"sous-effectifs","word_nosc":"sous-effectifs","lemma":"sous-effectif","pos":"NOM"} ,
		{"word":"sous-emploi","word_nosc":"sous-emploi","lemma":"sous-emploi","pos":"NOM"} ,
		{"word":"sous-ensemble","word_nosc":"sous-ensemble","lemma":"sous-ensemble","pos":"NOM"} ,
		{"word":"sous-entendu","word_nosc":"sous-entendu","lemma":"sous-entendu","pos":"NOM"} ,
		{"word":"sous-entendus","word_nosc":"sous-entendus","lemma":"sous-entendu","pos":"NOM"} ,
		{"word":"sous-espace","word_nosc":"sous-espace","lemma":"sous-espace","pos":"NOM"} ,
		{"word":"sous-espèce","word_nosc":"sous-espece","lemma":"sous-espèce","pos":"NOM"} ,
		{"word":"sous-estimation","word_nosc":"sous-estimation","lemma":"sous-estimation","pos":"NOM"} ,
		{"word":"sous-fifre","word_nosc":"sous-fifre","lemma":"sous-fifre","pos":"NOM"} ,
		{"word":"sous-fifres","word_nosc":"sous-fifres","lemma":"sous-fifre","pos":"NOM"} ,
		{"word":"sous-garde","word_nosc":"sous-garde","lemma":"sous-garde","pos":"NOM"} ,
		{"word":"sous-genre","word_nosc":"sous-genre","lemma":"sous-genre","pos":"NOM"} ,
		{"word":"sous-gorge","word_nosc":"sous-gorge","lemma":"sous-gorge","pos":"NOM"} ,
		{"word":"sous-groupe","word_nosc":"sous-groupe","lemma":"sous-groupe","pos":"NOM"} ,
		{"word":"sous-homme","word_nosc":"sous-homme","lemma":"sous-homme","pos":"NOM"} ,
		{"word":"sous-hommes","word_nosc":"sous-hommes","lemma":"sous-homme","pos":"NOM"} ,
		{"word":"sous-humanité","word_nosc":"sous-humanite","lemma":"sous-humanité","pos":"NOM"} ,
		{"word":"sous-intendant","word_nosc":"sous-intendant","lemma":"sous-intendant","pos":"NOM"} ,
		{"word":"sous-intendants","word_nosc":"sous-intendants","lemma":"sous-intendant","pos":"NOM"} ,
		{"word":"sous-lieutenant","word_nosc":"sous-lieutenant","lemma":"sous-lieutenant","pos":"NOM"} ,
		{"word":"sous-lieutenants","word_nosc":"sous-lieutenants","lemma":"sous-lieutenant","pos":"NOM"} ,
		{"word":"sous-locataire","word_nosc":"sous-locataire","lemma":"sous-locataire","pos":"NOM"} ,
		{"word":"sous-locataires","word_nosc":"sous-locataires","lemma":"sous-locataire","pos":"NOM"} ,
		{"word":"sous-location","word_nosc":"sous-location","lemma":"sous-location","pos":"NOM"} ,
		{"word":"sous-locations","word_nosc":"sous-locations","lemma":"sous-location","pos":"NOM"} ,
		{"word":"sous-main","word_nosc":"sous-main","lemma":"sous-main","pos":"NOM"} ,
		{"word":"sous-marin","word_nosc":"sous-marin","lemma":"sous-marin","pos":"NOM"} ,
		{"word":"sous-marinier","word_nosc":"sous-marinier","lemma":"sous-marinier","pos":"NOM"} ,
		{"word":"sous-mariniers","word_nosc":"sous-mariniers","lemma":"sous-marinier","pos":"NOM"} ,
		{"word":"sous-marins","word_nosc":"sous-marins","lemma":"sous-marin","pos":"NOM"} ,
		{"word":"sous-marque","word_nosc":"sous-marque","lemma":"sous-marque","pos":"NOM"} ,
		{"word":"sous-marques","word_nosc":"sous-marques","lemma":"sous-marque","pos":"NOM"} ,
		{"word":"sous-maîtresse","word_nosc":"sous-maitresse","lemma":"sous-maîtresse","pos":"NOM"} ,
		{"word":"sous-merde","word_nosc":"sous-merde","lemma":"sous-merde","pos":"NOM"} ,
		{"word":"sous-merdes","word_nosc":"sous-merdes","lemma":"sous-merde","pos":"NOM"} ,
		{"word":"sous-ministre","word_nosc":"sous-ministre","lemma":"sous-ministre","pos":"NOM"} ,
		{"word":"sous-off","word_nosc":"sous-off","lemma":"sous-off","pos":"NOM"} ,
		{"word":"sous-officier","word_nosc":"sous-officier","lemma":"sous-officier","pos":"NOM"} ,
		{"word":"sous-officiers","word_nosc":"sous-officiers","lemma":"sous-officier","pos":"NOM"} ,
		{"word":"sous-offs","word_nosc":"sous-offs","lemma":"sous-off","pos":"NOM"} ,
		{"word":"sous-ordre","word_nosc":"sous-ordre","lemma":"sous-ordre","pos":"NOM"} ,
		{"word":"sous-ordres","word_nosc":"sous-ordres","lemma":"sous-ordre","pos":"NOM"} ,
		{"word":"sous-pied","word_nosc":"sous-pied","lemma":"sous-pied","pos":"NOM"} ,
		{"word":"sous-pieds","word_nosc":"sous-pieds","lemma":"sous-pied","pos":"NOM"} ,
		{"word":"sous-plat","word_nosc":"sous-plat","lemma":"sous-plat","pos":"NOM"} ,
		{"word":"sous-prieur","word_nosc":"sous-prieur","lemma":"sous-prieur","pos":"NOM"} ,
		{"word":"sous-produit","word_nosc":"sous-produit","lemma":"sous-produit","pos":"NOM"} ,
		{"word":"sous-produits","word_nosc":"sous-produits","lemma":"sous-produit","pos":"NOM"} ,
		{"word":"sous-programme","word_nosc":"sous-programme","lemma":"sous-programme","pos":"NOM"} ,
		{"word":"sous-programmes","word_nosc":"sous-programmes","lemma":"sous-programme","pos":"NOM"} ,
		{"word":"sous-prolétaire","word_nosc":"sous-proletaire","lemma":"sous-prolétaire","pos":"NOM"} ,
		{"word":"sous-prolétariat","word_nosc":"sous-proletariat","lemma":"sous-prolétariat","pos":"NOM"} ,
		{"word":"sous-préfecture","word_nosc":"sous-prefecture","lemma":"sous-préfecture","pos":"NOM"} ,
		{"word":"sous-préfectures","word_nosc":"sous-prefectures","lemma":"sous-préfecture","pos":"NOM"} ,
		{"word":"sous-préfet","word_nosc":"sous-prefet","lemma":"sous-préfet","pos":"NOM"} ,
		{"word":"sous-préfets","word_nosc":"sous-prefets","lemma":"sous-préfet","pos":"NOM"} ,
		{"word":"sous-pulls","word_nosc":"sous-pulls","lemma":"sous-pull","pos":"NOM"} ,
		{"word":"sous-secrétaire","word_nosc":"sous-secretaire","lemma":"sous-secrétaire","pos":"NOM"} ,
		{"word":"sous-secrétaires","word_nosc":"sous-secretaires","lemma":"sous-secrétaire","pos":"NOM"} ,
		{"word":"sous-secrétariat","word_nosc":"sous-secretariat","lemma":"sous-secrétariat","pos":"NOM"} ,
		{"word":"sous-secteur","word_nosc":"sous-secteur","lemma":"sous-secteur","pos":"NOM"} ,
		{"word":"sous-section","word_nosc":"sous-section","lemma":"sous-section","pos":"NOM"} ,
		{"word":"sous-sol","word_nosc":"sous-sol","lemma":"sous-sol","pos":"NOM"} ,
		{"word":"sous-sols","word_nosc":"sous-sols","lemma":"sous-sol","pos":"NOM"} ,
		{"word":"sous-station","word_nosc":"sous-station","lemma":"sous-station","pos":"NOM"} ,
		{"word":"sous-système","word_nosc":"sous-systeme","lemma":"sous-système","pos":"NOM"} ,
		{"word":"sous-tasse","word_nosc":"sous-tasse","lemma":"sous-tasse","pos":"NOM"} ,
		{"word":"sous-tasses","word_nosc":"sous-tasses","lemma":"sous-tasse","pos":"NOM"} ,
		{"word":"sous-tension","word_nosc":"sous-tension","lemma":"sous-tension","pos":"NOM"} ,
		{"word":"sous-titrage","word_nosc":"sous-titrage","lemma":"sous-titrage","pos":"NOM"} ,
		{"word":"sous-titre","word_nosc":"sous-titre","lemma":"sous-titre","pos":"NOM"} ,
		{"word":"sous-titres","word_nosc":"sous-titres","lemma":"sous-titre","pos":"NOM"} ,
		{"word":"sous-traitance","word_nosc":"sous-traitance","lemma":"sous-traitance","pos":"NOM"} ,
		{"word":"sous-traitant","word_nosc":"sous-traitant","lemma":"sous-traitant","pos":"NOM"} ,
		{"word":"sous-traitants","word_nosc":"sous-traitants","lemma":"sous-traitant","pos":"NOM"} ,
		{"word":"sous-ventrière","word_nosc":"sous-ventriere","lemma":"sous-ventrière","pos":"NOM"} ,
		{"word":"sous-verge","word_nosc":"sous-verge","lemma":"sous-verge","pos":"NOM"} ,
		{"word":"sous-verre","word_nosc":"sous-verre","lemma":"sous-verre","pos":"NOM"} ,
		{"word":"sous-vêtement","word_nosc":"sous-vetement","lemma":"sous-vêtement","pos":"NOM"} ,
		{"word":"sous-vêtements","word_nosc":"sous-vetements","lemma":"sous-vêtement","pos":"NOM"} ,
		{"word":"souscripteur","word_nosc":"souscripteur","lemma":"souscripteur","pos":"NOM"} ,
		{"word":"souscripteurs","word_nosc":"souscripteurs","lemma":"souscripteur","pos":"NOM"} ,
		{"word":"souscription","word_nosc":"souscription","lemma":"souscription","pos":"NOM"} ,
		{"word":"souscriptions","word_nosc":"souscriptions","lemma":"souscription","pos":"NOM"} ,
		{"word":"soussou","word_nosc":"soussou","lemma":"soussou","pos":"NOM"} ,
		{"word":"soussous","word_nosc":"soussous","lemma":"soussou","pos":"NOM"} ,
		{"word":"soustraction","word_nosc":"soustraction","lemma":"soustraction","pos":"NOM"} ,
		{"word":"soustractions","word_nosc":"soustractions","lemma":"soustraction","pos":"NOM"} ,
		{"word":"soutache","word_nosc":"soutache","lemma":"soutache","pos":"NOM"} ,
		{"word":"soutaches","word_nosc":"soutaches","lemma":"soutache","pos":"NOM"} ,
		{"word":"soutane","word_nosc":"soutane","lemma":"soutane","pos":"NOM"} ,
		{"word":"soutanelle","word_nosc":"soutanelle","lemma":"soutanelle","pos":"NOM"} ,
		{"word":"soutanes","word_nosc":"soutanes","lemma":"soutane","pos":"NOM"} ,
		{"word":"soute","word_nosc":"soute","lemma":"soute","pos":"NOM"} ,
		{"word":"soutenance","word_nosc":"soutenance","lemma":"soutenance","pos":"NOM"} ,
		{"word":"soutenant","word_nosc":"soutenant","lemma":"soutenant","pos":"NOM"} ,
		{"word":"souteneur","word_nosc":"souteneur","lemma":"souteneur","pos":"NOM"} ,
		{"word":"souteneurs","word_nosc":"souteneurs","lemma":"souteneur","pos":"NOM"} ,
		{"word":"souterrain","word_nosc":"souterrain","lemma":"souterrain","pos":"NOM"} ,
		{"word":"souterrains","word_nosc":"souterrains","lemma":"souterrain","pos":"NOM"} ,
		{"word":"soutes","word_nosc":"soutes","lemma":"soute","pos":"NOM"} ,
		{"word":"soutien","word_nosc":"soutien","lemma":"soutien","pos":"NOM"} ,
		{"word":"soutien-gorge","word_nosc":"soutien-gorge","lemma":"soutien-gorge","pos":"NOM"} ,
		{"word":"soutiens","word_nosc":"soutiens","lemma":"soutien","pos":"NOM"} ,
		{"word":"soutiens-gorge","word_nosc":"soutiens-gorge","lemma":"soutien-gorge","pos":"NOM"} ,
		{"word":"soutier","word_nosc":"soutier","lemma":"soutier","pos":"NOM"} ,
		{"word":"soutiers","word_nosc":"soutiers","lemma":"soutier","pos":"NOM"} ,
		{"word":"soutif","word_nosc":"soutif","lemma":"soutif","pos":"NOM"} ,
		{"word":"soutifs","word_nosc":"soutifs","lemma":"soutif","pos":"NOM"} ,
		{"word":"soutirage","word_nosc":"soutirage","lemma":"soutirage","pos":"NOM"} ,
		{"word":"soutra","word_nosc":"soutra","lemma":"soutra","pos":"NOM"} ,
		{"word":"soutènement","word_nosc":"soutenement","lemma":"soutènement","pos":"NOM"} ,
		{"word":"souvenance","word_nosc":"souvenance","lemma":"souvenance","pos":"NOM"} ,
		{"word":"souvenances","word_nosc":"souvenances","lemma":"souvenance","pos":"NOM"} ,
		{"word":"souvenir","word_nosc":"souvenir","lemma":"souvenir","pos":"NOM"} ,
		{"word":"souvenirs","word_nosc":"souvenirs","lemma":"souvenir","pos":"NOM"} ,
		{"word":"souverain","word_nosc":"souverain","lemma":"souverain","pos":"NOM"} ,
		{"word":"souveraine","word_nosc":"souveraine","lemma":"souverain","pos":"NOM"} ,
		{"word":"souveraines","word_nosc":"souveraines","lemma":"souverain","pos":"NOM"} ,
		{"word":"souveraineté","word_nosc":"souverainete","lemma":"souveraineté","pos":"NOM"} ,
		{"word":"souverainetés","word_nosc":"souverainetes","lemma":"souveraineté","pos":"NOM"} ,
		{"word":"souverains","word_nosc":"souverains","lemma":"souverain","pos":"NOM"} ,
		{"word":"souvlaki","word_nosc":"souvlaki","lemma":"souvlaki","pos":"NOM"} ,
		{"word":"soviet","word_nosc":"soviet","lemma":"soviet","pos":"NOM"} ,
		{"word":"soviets","word_nosc":"soviets","lemma":"soviet","pos":"NOM"} ,
		{"word":"soviétique","word_nosc":"sovietique","lemma":"soviétique","pos":"NOM"} ,
		{"word":"soviétiques","word_nosc":"sovietiques","lemma":"soviétique","pos":"NOM"} ,
		{"word":"soviétisation","word_nosc":"sovietisation","lemma":"soviétisation","pos":"NOM"} ,
		{"word":"soviétisme","word_nosc":"sovietisme","lemma":"soviétisme","pos":"NOM"} ,
		{"word":"soviétologues","word_nosc":"sovietologues","lemma":"soviétologue","pos":"NOM"} ,
		{"word":"sovkhoze","word_nosc":"sovkhoze","lemma":"sovkhoze","pos":"NOM"} ,
		{"word":"sovkhozes","word_nosc":"sovkhozes","lemma":"sovkhoze","pos":"NOM"} ,
		{"word":"soya","word_nosc":"soya","lemma":"soya","pos":"NOM"} ,
		{"word":"soûlard","word_nosc":"soulard","lemma":"soûlard","pos":"NOM"} ,
		{"word":"soûlarde","word_nosc":"soularde","lemma":"soûlard","pos":"NOM"} ,
		{"word":"soûlards","word_nosc":"soulards","lemma":"soûlard","pos":"NOM"} ,
		{"word":"soûlerie","word_nosc":"soulerie","lemma":"soûlerie","pos":"NOM"} ,
		{"word":"soûlographe","word_nosc":"soulographe","lemma":"soûlographe","pos":"NOM"} ,
		{"word":"soûlographie","word_nosc":"soulographie","lemma":"soûlographie","pos":"NOM"} ,
		{"word":"soûlot","word_nosc":"soulot","lemma":"soûlot","pos":"NOM"} ,
		{"word":"soûlots","word_nosc":"soulots","lemma":"soûlot","pos":"NOM"} ,
		{"word":"spa","word_nosc":"spa","lemma":"spa","pos":"NOM"} ,
		{"word":"spadassin","word_nosc":"spadassin","lemma":"spadassin","pos":"NOM"} ,
		{"word":"spadassins","word_nosc":"spadassins","lemma":"spadassin","pos":"NOM"} ,
		{"word":"spadille","word_nosc":"spadille","lemma":"spadille","pos":"NOM"} ,
		{"word":"spaghetti","word_nosc":"spaghetti","lemma":"spaghetti","pos":"NOM"} ,
		{"word":"spaghettis","word_nosc":"spaghettis","lemma":"spaghetti","pos":"NOM"} ,
		{"word":"spahi","word_nosc":"spahi","lemma":"spahi","pos":"NOM"} ,
		{"word":"spahis","word_nosc":"spahis","lemma":"spahi","pos":"NOM"} ,
		{"word":"spamming","word_nosc":"spamming","lemma":"spamming","pos":"NOM"} ,
		{"word":"sparadrap","word_nosc":"sparadrap","lemma":"sparadrap","pos":"NOM"} ,
		{"word":"sparadraps","word_nosc":"sparadraps","lemma":"sparadrap","pos":"NOM"} ,
		{"word":"sparring-partner","word_nosc":"sparring-partner","lemma":"sparring-partner","pos":"NOM"} ,
		{"word":"spartakistes","word_nosc":"spartakistes","lemma":"spartakiste","pos":"NOM"} ,
		{"word":"sparte","word_nosc":"sparte","lemma":"sparte","pos":"NOM"} ,
		{"word":"sparterie","word_nosc":"sparterie","lemma":"sparterie","pos":"NOM"} ,
		{"word":"spartiate","word_nosc":"spartiate","lemma":"spartiate","pos":"NOM"} ,
		{"word":"spartiates","word_nosc":"spartiates","lemma":"spartiate","pos":"NOM"} ,
		{"word":"spartéine","word_nosc":"sparteine","lemma":"spartéine","pos":"NOM"} ,
		{"word":"spas","word_nosc":"spas","lemma":"spa","pos":"NOM"} ,
		{"word":"spasme","word_nosc":"spasme","lemma":"spasme","pos":"NOM"} ,
		{"word":"spasmes","word_nosc":"spasmes","lemma":"spasme","pos":"NOM"} ,
		{"word":"spasmophilie","word_nosc":"spasmophilie","lemma":"spasmophilie","pos":"NOM"} ,
		{"word":"spasticité","word_nosc":"spasticite","lemma":"spasticité","pos":"NOM"} ,
		{"word":"spath","word_nosc":"spath","lemma":"spath","pos":"NOM"} ,
		{"word":"spatial","word_nosc":"spatial","lemma":"spatial","pos":"NOM"} ,
		{"word":"spationaute","word_nosc":"spationaute","lemma":"spationaute","pos":"NOM"} ,
		{"word":"spatule","word_nosc":"spatule","lemma":"spatule","pos":"NOM"} ,
		{"word":"spatules","word_nosc":"spatules","lemma":"spatule","pos":"NOM"} ,
		{"word":"speakeasy","word_nosc":"speakeasy","lemma":"speakeasy","pos":"NOM"} ,
		{"word":"speaker","word_nosc":"speaker","lemma":"speaker","pos":"NOM"} ,
		{"word":"speakerine","word_nosc":"speakerine","lemma":"speaker","pos":"NOM"} ,
		{"word":"speakerines","word_nosc":"speakerines","lemma":"speaker","pos":"NOM"} ,
		{"word":"speakers","word_nosc":"speakers","lemma":"speaker","pos":"NOM"} ,
		{"word":"species","word_nosc":"species","lemma":"species","pos":"NOM"} ,
		{"word":"spectacle","word_nosc":"spectacle","lemma":"spectacle","pos":"NOM"} ,
		{"word":"spectacles","word_nosc":"spectacles","lemma":"spectacle","pos":"NOM"} ,
		{"word":"spectateur","word_nosc":"spectateur","lemma":"spectateur","pos":"NOM"} ,
		{"word":"spectateurs","word_nosc":"spectateurs","lemma":"spectateur","pos":"NOM"} ,
		{"word":"spectatrice","word_nosc":"spectatrice","lemma":"spectateur","pos":"NOM"} ,
		{"word":"spectatrices","word_nosc":"spectatrices","lemma":"spectateur","pos":"NOM"} ,
		{"word":"spectre","word_nosc":"spectre","lemma":"spectre","pos":"NOM"} ,
		{"word":"spectres","word_nosc":"spectres","lemma":"spectre","pos":"NOM"} ,
		{"word":"spectrogramme","word_nosc":"spectrogramme","lemma":"spectrogramme","pos":"NOM"} ,
		{"word":"spectrographe","word_nosc":"spectrographe","lemma":"spectrographe","pos":"NOM"} ,
		{"word":"spectrographie","word_nosc":"spectrographie","lemma":"spectrographie","pos":"NOM"} ,
		{"word":"spectrohéliographe","word_nosc":"spectroheliographe","lemma":"spectrohéliographe","pos":"NOM"} ,
		{"word":"spectromètre","word_nosc":"spectrometre","lemma":"spectromètre","pos":"NOM"} ,
		{"word":"spectrométrie","word_nosc":"spectrometrie","lemma":"spectrométrie","pos":"NOM"} ,
		{"word":"spectrophotomètre","word_nosc":"spectrophotometre","lemma":"spectrophotomètre","pos":"NOM"} ,
		{"word":"spectroscope","word_nosc":"spectroscope","lemma":"spectroscope","pos":"NOM"} ,
		{"word":"spectroscopie","word_nosc":"spectroscopie","lemma":"spectroscopie","pos":"NOM"} ,
		{"word":"speech","word_nosc":"speech","lemma":"speech","pos":"NOM"} ,
		{"word":"speed","word_nosc":"speed","lemma":"speed","pos":"NOM"} ,
		{"word":"spencer","word_nosc":"spencer","lemma":"spencer","pos":"NOM"} ,
		{"word":"spencers","word_nosc":"spencers","lemma":"spencer","pos":"NOM"} ,
		{"word":"spenglérienne","word_nosc":"spenglerienne","lemma":"spenglérienne","pos":"NOM"} ,
		{"word":"spermaceti","word_nosc":"spermaceti","lemma":"spermaceti","pos":"NOM"} ,
		{"word":"spermato","word_nosc":"spermato","lemma":"spermato","pos":"NOM"} ,
		{"word":"spermatogenèse","word_nosc":"spermatogenese","lemma":"spermatogenèse","pos":"NOM"} ,
		{"word":"spermatos","word_nosc":"spermatos","lemma":"spermato","pos":"NOM"} ,
		{"word":"spermatozoïde","word_nosc":"spermatozoide","lemma":"spermatozoïde","pos":"NOM"} ,
		{"word":"spermatozoïdes","word_nosc":"spermatozoides","lemma":"spermatozoïde","pos":"NOM"} ,
		{"word":"sperme","word_nosc":"sperme","lemma":"sperme","pos":"NOM"} ,
		{"word":"spermes","word_nosc":"spermes","lemma":"sperme","pos":"NOM"} ,
		{"word":"spermicide","word_nosc":"spermicide","lemma":"spermicide","pos":"NOM"} ,
		{"word":"spermogramme","word_nosc":"spermogramme","lemma":"spermogramme","pos":"NOM"} ,
		{"word":"spermophiles","word_nosc":"spermophiles","lemma":"spermophile","pos":"NOM"} ,
		{"word":"spetsnaz","word_nosc":"spetsnaz","lemma":"spetsnaz","pos":"NOM"} ,
		{"word":"sphaigne","word_nosc":"sphaigne","lemma":"sphaigne","pos":"NOM"} ,
		{"word":"sphaignes","word_nosc":"sphaignes","lemma":"sphaigne","pos":"NOM"} ,
		{"word":"sphincter","word_nosc":"sphincter","lemma":"sphincter","pos":"NOM"} ,
		{"word":"sphincters","word_nosc":"sphincters","lemma":"sphincter","pos":"NOM"} ,
		{"word":"sphinge","word_nosc":"sphinge","lemma":"sphinge","pos":"NOM"} ,
		{"word":"sphinges","word_nosc":"sphinges","lemma":"sphinge","pos":"NOM"} ,
		{"word":"sphinx","word_nosc":"sphinx","lemma":"sphinx","pos":"NOM"} ,
		{"word":"sphygmomanomètre","word_nosc":"sphygmomanometre","lemma":"sphygmomanomètre","pos":"NOM"} ,
		{"word":"sphère","word_nosc":"sphere","lemma":"sphère","pos":"NOM"} ,
		{"word":"sphères","word_nosc":"spheres","lemma":"sphère","pos":"NOM"} ,
		{"word":"sphéricité","word_nosc":"sphericite","lemma":"sphéricité","pos":"NOM"} ,
		{"word":"sphéroïde","word_nosc":"spheroide","lemma":"sphéroïde","pos":"NOM"} ,
		{"word":"sphéroïdes","word_nosc":"spheroides","lemma":"sphéroïde","pos":"NOM"} ,
		{"word":"spi","word_nosc":"spi","lemma":"spi","pos":"NOM"} ,
		{"word":"spic","word_nosc":"spic","lemma":"spic","pos":"NOM"} ,
		{"word":"spica","word_nosc":"spica","lemma":"spica","pos":"NOM"} ,
		{"word":"spics","word_nosc":"spics","lemma":"spic","pos":"NOM"} ,
		{"word":"spicules","word_nosc":"spicules","lemma":"spicule","pos":"NOM"} ,
		{"word":"spider","word_nosc":"spider","lemma":"spider","pos":"NOM"} ,
		{"word":"spin","word_nosc":"spin","lemma":"spin","pos":"NOM"} ,
		{"word":"spina","word_nosc":"spina","lemma":"spina","pos":"NOM"} ,
		{"word":"spina-bifida","word_nosc":"spina-bifida","lemma":"spina-bifida","pos":"NOM"} ,
		{"word":"spina-ventosa","word_nosc":"spina-ventosa","lemma":"spina-ventosa","pos":"NOM"} ,
		{"word":"spinnaker","word_nosc":"spinnaker","lemma":"spinnaker","pos":"NOM"} ,
		{"word":"spinnakers","word_nosc":"spinnakers","lemma":"spinnaker","pos":"NOM"} ,
		{"word":"spinozisme","word_nosc":"spinozisme","lemma":"spinozisme","pos":"NOM"} ,
		{"word":"spirale","word_nosc":"spirale","lemma":"spirale","pos":"NOM"} ,
		{"word":"spirales","word_nosc":"spirales","lemma":"spirale","pos":"NOM"} ,
		{"word":"spire","word_nosc":"spire","lemma":"spire","pos":"NOM"} ,
		{"word":"spires","word_nosc":"spires","lemma":"spire","pos":"NOM"} ,
		{"word":"spirite","word_nosc":"spirite","lemma":"spirite","pos":"NOM"} ,
		{"word":"spirites","word_nosc":"spirites","lemma":"spirite","pos":"NOM"} ,
		{"word":"spiritisme","word_nosc":"spiritisme","lemma":"spiritisme","pos":"NOM"} ,
		{"word":"spiritualisme","word_nosc":"spiritualisme","lemma":"spiritualisme","pos":"NOM"} ,
		{"word":"spiritualistes","word_nosc":"spiritualistes","lemma":"spiritualiste","pos":"NOM"} ,
		{"word":"spiritualité","word_nosc":"spiritualite","lemma":"spiritualité","pos":"NOM"} ,
		{"word":"spiritualités","word_nosc":"spiritualites","lemma":"spiritualité","pos":"NOM"} ,
		{"word":"spirituel","word_nosc":"spirituel","lemma":"spirituel","pos":"NOM"} ,
		{"word":"spirituels","word_nosc":"spirituels","lemma":"spirituel","pos":"NOM"} ,
		{"word":"spiritueux","word_nosc":"spiritueux","lemma":"spiritueux","pos":"NOM"} ,
		{"word":"spirochète","word_nosc":"spirochete","lemma":"spirochète","pos":"NOM"} ,
		{"word":"spirographe","word_nosc":"spirographe","lemma":"spirographe","pos":"NOM"} ,
		{"word":"spiromètres","word_nosc":"spirometres","lemma":"spiromètre","pos":"NOM"} ,
		{"word":"spiruline","word_nosc":"spiruline","lemma":"spiruline","pos":"NOM"} ,
		{"word":"spirées","word_nosc":"spirees","lemma":"spirée","pos":"NOM"} ,
		{"word":"spitz","word_nosc":"spitz","lemma":"spitz","pos":"NOM"} ,
		{"word":"spleen","word_nosc":"spleen","lemma":"spleen","pos":"NOM"} ,
		{"word":"spleens","word_nosc":"spleens","lemma":"spleen","pos":"NOM"} ,
		{"word":"splendeur","word_nosc":"splendeur","lemma":"splendeur","pos":"NOM"} ,
		{"word":"splendeurs","word_nosc":"splendeurs","lemma":"splendeur","pos":"NOM"} ,
		{"word":"splénectomie","word_nosc":"splenectomie","lemma":"splénectomie","pos":"NOM"} ,
		{"word":"splénique","word_nosc":"splenique","lemma":"splénique","pos":"NOM"} ,
		{"word":"spoiler","word_nosc":"spoiler","lemma":"spoiler","pos":"NOM"} ,
		{"word":"spoliation","word_nosc":"spoliation","lemma":"spoliation","pos":"NOM"} ,
		{"word":"spoliations","word_nosc":"spoliations","lemma":"spoliation","pos":"NOM"} ,
		{"word":"spolié","word_nosc":"spolie","lemma":"spolié","pos":"NOM"} ,
		{"word":"spoliées","word_nosc":"spoliees","lemma":"spolié","pos":"NOM"} ,
		{"word":"spondylarthrose","word_nosc":"spondylarthrose","lemma":"spondylarthrose","pos":"NOM"} ,
		{"word":"spondylite","word_nosc":"spondylite","lemma":"spondylite","pos":"NOM"} ,
		{"word":"spondées","word_nosc":"spondees","lemma":"spondée","pos":"NOM"} ,
		{"word":"sponsor","word_nosc":"sponsor","lemma":"sponsor","pos":"NOM"} ,
		{"word":"sponsoring","word_nosc":"sponsoring","lemma":"sponsoring","pos":"NOM"} ,
		{"word":"sponsors","word_nosc":"sponsors","lemma":"sponsor","pos":"NOM"} ,
		{"word":"spontanéité","word_nosc":"spontaneite","lemma":"spontanéité","pos":"NOM"} ,
		{"word":"spoon","word_nosc":"spoon","lemma":"spoon","pos":"NOM"} ,
		{"word":"spore","word_nosc":"spore","lemma":"spore","pos":"NOM"} ,
		{"word":"spores","word_nosc":"spores","lemma":"spore","pos":"NOM"} ,
		{"word":"sport","word_nosc":"sport","lemma":"sport","pos":"NOM"} ,
		{"word":"sportif","word_nosc":"sportif","lemma":"sportif","pos":"NOM"} ,
		{"word":"sportifs","word_nosc":"sportifs","lemma":"sportif","pos":"NOM"} ,
		{"word":"sportive","word_nosc":"sportive","lemma":"sportif","pos":"NOM"} ,
		{"word":"sportives","word_nosc":"sportives","lemma":"sportif","pos":"NOM"} ,
		{"word":"sportivité","word_nosc":"sportivite","lemma":"sportivité","pos":"NOM"} ,
		{"word":"sports","word_nosc":"sports","lemma":"sport","pos":"NOM"} ,
		{"word":"sportsman","word_nosc":"sportsman","lemma":"sportsman","pos":"NOM"} ,
		{"word":"sportsmen","word_nosc":"sportsmen","lemma":"sportsman","pos":"NOM"} ,
		{"word":"sportswear","word_nosc":"sportswear","lemma":"sportswear","pos":"NOM"} ,
		{"word":"spot","word_nosc":"spot","lemma":"spot","pos":"NOM"} ,
		{"word":"spots","word_nosc":"spots","lemma":"spot","pos":"NOM"} ,
		{"word":"spoutnik","word_nosc":"spoutnik","lemma":"spoutnik","pos":"NOM"} ,
		{"word":"spoutniks","word_nosc":"spoutniks","lemma":"spoutnik","pos":"NOM"} ,
		{"word":"sprat","word_nosc":"sprat","lemma":"sprat","pos":"NOM"} ,
		{"word":"sprats","word_nosc":"sprats","lemma":"sprat","pos":"NOM"} ,
		{"word":"spray","word_nosc":"spray","lemma":"spray","pos":"NOM"} ,
		{"word":"sprays","word_nosc":"sprays","lemma":"spray","pos":"NOM"} ,
		{"word":"sprechgesang","word_nosc":"sprechgesang","lemma":"sprechgesang","pos":"NOM"} ,
		{"word":"spring","word_nosc":"spring","lemma":"spring","pos":"NOM"} ,
		{"word":"springer","word_nosc":"springer","lemma":"springer","pos":"NOM"} ,
		{"word":"sprinkler","word_nosc":"sprinkler","lemma":"sprinkler","pos":"NOM"} ,
		{"word":"sprinklers","word_nosc":"sprinklers","lemma":"sprinkler","pos":"NOM"} ,
		{"word":"sprint","word_nosc":"sprint","lemma":"sprint","pos":"NOM"} ,
		{"word":"sprinter","word_nosc":"sprinter","lemma":"sprinter","pos":"NOM"} ,
		{"word":"sprinters","word_nosc":"sprinters","lemma":"sprinter","pos":"NOM"} ,
		{"word":"sprinteur","word_nosc":"sprinteur","lemma":"sprinteur","pos":"NOM"} ,
		{"word":"sprinteurs","word_nosc":"sprinteurs","lemma":"sprinteur","pos":"NOM"} ,
		{"word":"sprints","word_nosc":"sprints","lemma":"sprint","pos":"NOM"} ,
		{"word":"spruce","word_nosc":"spruce","lemma":"spruce","pos":"NOM"} ,
		{"word":"spumosité","word_nosc":"spumosite","lemma":"spumosité","pos":"NOM"} ,
		{"word":"spéciale","word_nosc":"speciale","lemma":"spéciale","pos":"NOM"} ,
		{"word":"spéciales","word_nosc":"speciales","lemma":"spéciale","pos":"NOM"} ,
		{"word":"spécialisation","word_nosc":"specialisation","lemma":"spécialisation","pos":"NOM"} ,
		{"word":"spécialiste","word_nosc":"specialiste","lemma":"spécialiste","pos":"NOM"} ,
		{"word":"spécialistes","word_nosc":"specialistes","lemma":"spécialiste","pos":"NOM"} ,
		{"word":"spécialité","word_nosc":"specialite","lemma":"spécialité","pos":"NOM"} ,
		{"word":"spécialités","word_nosc":"specialites","lemma":"spécialité","pos":"NOM"} ,
		{"word":"spécification","word_nosc":"specification","lemma":"spécification","pos":"NOM"} ,
		{"word":"spécifications","word_nosc":"specifications","lemma":"spécification","pos":"NOM"} ,
		{"word":"spécificité","word_nosc":"specificite","lemma":"spécificité","pos":"NOM"} ,
		{"word":"spécificités","word_nosc":"specificites","lemma":"spécificité","pos":"NOM"} ,
		{"word":"spécimen","word_nosc":"specimen","lemma":"spécimen","pos":"NOM"} ,
		{"word":"spécimens","word_nosc":"specimens","lemma":"spécimen","pos":"NOM"} ,
		{"word":"spéculateur","word_nosc":"speculateur","lemma":"spéculateur","pos":"NOM"} ,
		{"word":"spéculateurs","word_nosc":"speculateurs","lemma":"spéculateur","pos":"NOM"} ,
		{"word":"spéculation","word_nosc":"speculation","lemma":"spéculation","pos":"NOM"} ,
		{"word":"spéculations","word_nosc":"speculations","lemma":"spéculation","pos":"NOM"} ,
		{"word":"spéculatrice","word_nosc":"speculatrice","lemma":"spéculateur","pos":"NOM"} ,
		{"word":"spéculatrices","word_nosc":"speculatrices","lemma":"spéculateur","pos":"NOM"} ,
		{"word":"spéculoos","word_nosc":"speculoos","lemma":"spéculoos","pos":"NOM"} ,
		{"word":"spéculum","word_nosc":"speculum","lemma":"spéculum","pos":"NOM"} ,
		{"word":"spéléo","word_nosc":"speleo","lemma":"spéléo","pos":"NOM"} ,
		{"word":"spéléologie","word_nosc":"speleologie","lemma":"spéléologie","pos":"NOM"} ,
		{"word":"spéléologue","word_nosc":"speleologue","lemma":"spéléologue","pos":"NOM"} ,
		{"word":"spéléologues","word_nosc":"speleologues","lemma":"spéléologue","pos":"NOM"} ,
		{"word":"spéléotomie","word_nosc":"speleotomie","lemma":"spéléotomie","pos":"NOM"} ,
		{"word":"squale","word_nosc":"squale","lemma":"squale","pos":"NOM"} ,
		{"word":"squales","word_nosc":"squales","lemma":"squale","pos":"NOM"} ,
		{"word":"squames","word_nosc":"squames","lemma":"squame","pos":"NOM"} ,
		{"word":"square","word_nosc":"square","lemma":"square","pos":"NOM"} ,
		{"word":"squares","word_nosc":"squares","lemma":"square","pos":"NOM"} ,
		{"word":"squash","word_nosc":"squash","lemma":"squash","pos":"NOM"} ,
		{"word":"squat","word_nosc":"squat","lemma":"squat","pos":"NOM"} ,
		{"word":"squats","word_nosc":"squats","lemma":"squat","pos":"NOM"} ,
		{"word":"squatter","word_nosc":"squatter","lemma":"squatter","pos":"NOM"} ,
		{"word":"squatters","word_nosc":"squatters","lemma":"squatter","pos":"NOM"} ,
		{"word":"squatteur","word_nosc":"squatteur","lemma":"squatteur","pos":"NOM"} ,
		{"word":"squatteurs","word_nosc":"squatteurs","lemma":"squatteur","pos":"NOM"} ,
		{"word":"squaw","word_nosc":"squaw","lemma":"squaw","pos":"NOM"} ,
		{"word":"squaws","word_nosc":"squaws","lemma":"squaw","pos":"NOM"} ,
		{"word":"squeeze","word_nosc":"squeeze","lemma":"squeeze","pos":"NOM"} ,
		{"word":"squelette","word_nosc":"squelette","lemma":"squelette","pos":"NOM"} ,
		{"word":"squelettes","word_nosc":"squelettes","lemma":"squelette","pos":"NOM"} ,
		{"word":"squire","word_nosc":"squire","lemma":"squire","pos":"NOM"} ,
		{"word":"squires","word_nosc":"squires","lemma":"squire","pos":"NOM"} ,
		{"word":"sri lankais","word_nosc":"sri lankais","lemma":"sri lankais","pos":"NOM"} ,
		{"word":"stabat mater","word_nosc":"stabat mater","lemma":"stabat mater","pos":"NOM"} ,
		{"word":"stabilisant","word_nosc":"stabilisant","lemma":"stabilisant","pos":"NOM"} ,
		{"word":"stabilisateur","word_nosc":"stabilisateur","lemma":"stabilisateur","pos":"NOM"} ,
		{"word":"stabilisateurs","word_nosc":"stabilisateurs","lemma":"stabilisateur","pos":"NOM"} ,
		{"word":"stabilisation","word_nosc":"stabilisation","lemma":"stabilisation","pos":"NOM"} ,
		{"word":"stabilité","word_nosc":"stabilite","lemma":"stabilité","pos":"NOM"} ,
		{"word":"stabulation","word_nosc":"stabulation","lemma":"stabulation","pos":"NOM"} ,
		{"word":"staccato","word_nosc":"staccato","lemma":"staccato","pos":"NOM"} ,
		{"word":"stade","word_nosc":"stade","lemma":"stade","pos":"NOM"} ,
		{"word":"stades","word_nosc":"stades","lemma":"stade","pos":"NOM"} ,
		{"word":"stadium","word_nosc":"stadium","lemma":"stadium","pos":"NOM"} ,
		{"word":"staff","word_nosc":"staff","lemma":"staff","pos":"NOM"} ,
		{"word":"staffs","word_nosc":"staffs","lemma":"staff","pos":"NOM"} ,
		{"word":"stage","word_nosc":"stage","lemma":"stage","pos":"NOM"} ,
		{"word":"stages","word_nosc":"stages","lemma":"stage","pos":"NOM"} ,
		{"word":"stagflation","word_nosc":"stagflation","lemma":"stagflation","pos":"NOM"} ,
		{"word":"stagiaire","word_nosc":"stagiaire","lemma":"stagiaire","pos":"NOM"} ,
		{"word":"stagiaires","word_nosc":"stagiaires","lemma":"stagiaire","pos":"NOM"} ,
		{"word":"stagnation","word_nosc":"stagnation","lemma":"stagnation","pos":"NOM"} ,
		{"word":"stakhanoviste","word_nosc":"stakhanoviste","lemma":"stakhanoviste","pos":"NOM"} ,
		{"word":"stalactite","word_nosc":"stalactite","lemma":"stalactite","pos":"NOM"} ,
		{"word":"stalactites","word_nosc":"stalactites","lemma":"stalactite","pos":"NOM"} ,
		{"word":"stalag","word_nosc":"stalag","lemma":"stalag","pos":"NOM"} ,
		{"word":"stalagmite","word_nosc":"stalagmite","lemma":"stalagmite","pos":"NOM"} ,
		{"word":"stalagmites","word_nosc":"stalagmites","lemma":"stalagmite","pos":"NOM"} ,
		{"word":"stalags","word_nosc":"stalags","lemma":"stalag","pos":"NOM"} ,
		{"word":"stalinien","word_nosc":"stalinien","lemma":"stalinien","pos":"NOM"} ,
		{"word":"staliniens","word_nosc":"staliniens","lemma":"stalinien","pos":"NOM"} ,
		{"word":"stalinisme","word_nosc":"stalinisme","lemma":"stalinisme","pos":"NOM"} ,
		{"word":"stalle","word_nosc":"stalle","lemma":"stalle","pos":"NOM"} ,
		{"word":"stalles","word_nosc":"stalles","lemma":"stalle","pos":"NOM"} ,
		{"word":"stals","word_nosc":"stals","lemma":"stal","pos":"NOM"} ,
		{"word":"stance","word_nosc":"stance","lemma":"stance","pos":"NOM"} ,
		{"word":"stances","word_nosc":"stances","lemma":"stance","pos":"NOM"} ,
		{"word":"stand","word_nosc":"stand","lemma":"stand","pos":"NOM"} ,
		{"word":"stand-by","word_nosc":"stand-by","lemma":"stand-by","pos":"NOM"} ,
		{"word":"standard","word_nosc":"standard","lemma":"standard","pos":"NOM"} ,
		{"word":"standardisation","word_nosc":"standardisation","lemma":"standardisation","pos":"NOM"} ,
		{"word":"standardiste","word_nosc":"standardiste","lemma":"standardiste","pos":"NOM"} ,
		{"word":"standardistes","word_nosc":"standardistes","lemma":"standardiste","pos":"NOM"} ,
		{"word":"standards","word_nosc":"standards","lemma":"standard","pos":"NOM"} ,
		{"word":"standing","word_nosc":"standing","lemma":"standing","pos":"NOM"} ,
		{"word":"stands","word_nosc":"stands","lemma":"stand","pos":"NOM"} ,
		{"word":"staphylococcie","word_nosc":"staphylococcie","lemma":"staphylococcie","pos":"NOM"} ,
		{"word":"staphylocoque","word_nosc":"staphylocoque","lemma":"staphylocoque","pos":"NOM"} ,
		{"word":"staphylocoques","word_nosc":"staphylocoques","lemma":"staphylocoque","pos":"NOM"} ,
		{"word":"star","word_nosc":"star","lemma":"star","pos":"NOM"} ,
		{"word":"star-system","word_nosc":"star-system","lemma":"star-system","pos":"NOM"} ,
		{"word":"starking","word_nosc":"starking","lemma":"starking","pos":"NOM"} ,
		{"word":"starlette","word_nosc":"starlette","lemma":"starlette","pos":"NOM"} ,
		{"word":"starlettes","word_nosc":"starlettes","lemma":"starlette","pos":"NOM"} ,
		{"word":"staroste","word_nosc":"staroste","lemma":"staroste","pos":"NOM"} ,
		{"word":"stars","word_nosc":"stars","lemma":"star","pos":"NOM"} ,
		{"word":"start-up","word_nosc":"start-up","lemma":"start-up","pos":"NOM"} ,
		{"word":"starter","word_nosc":"starter","lemma":"starter","pos":"NOM"} ,
		{"word":"starting-gate","word_nosc":"starting-gate","lemma":"starting-gate","pos":"NOM"} ,
		{"word":"stase","word_nosc":"stase","lemma":"stase","pos":"NOM"} ,
		{"word":"stat","word_nosc":"stat","lemma":"stat","pos":"NOM"} ,
		{"word":"statices","word_nosc":"statices","lemma":"statice","pos":"NOM"} ,
		{"word":"station","word_nosc":"station","lemma":"station","pos":"NOM"} ,
		{"word":"station-service","word_nosc":"station-service","lemma":"station-service","pos":"NOM"} ,
		{"word":"station-éclair","word_nosc":"station-eclair","lemma":"station-éclair","pos":"NOM"} ,
		{"word":"stationnement","word_nosc":"stationnement","lemma":"stationnement","pos":"NOM"} ,
		{"word":"stationnements","word_nosc":"stationnements","lemma":"stationnement","pos":"NOM"} ,
		{"word":"stations","word_nosc":"stations","lemma":"station","pos":"NOM"} ,
		{"word":"stations-service","word_nosc":"stations-service","lemma":"station-service","pos":"NOM"} ,
		{"word":"statique","word_nosc":"statique","lemma":"statique","pos":"NOM"} ,
		{"word":"statiques","word_nosc":"statiques","lemma":"statique","pos":"NOM"} ,
		{"word":"statisticien","word_nosc":"statisticien","lemma":"statisticien","pos":"NOM"} ,
		{"word":"statisticienne","word_nosc":"statisticienne","lemma":"statisticien","pos":"NOM"} ,
		{"word":"statistique","word_nosc":"statistique","lemma":"statistique","pos":"NOM"} ,
		{"word":"statistiques","word_nosc":"statistiques","lemma":"statistique","pos":"NOM"} ,
		{"word":"statu quo","word_nosc":"statu quo","lemma":"statu quo","pos":"NOM"} ,
		{"word":"statuaire","word_nosc":"statuaire","lemma":"statuaire","pos":"NOM"} ,
		{"word":"statue","word_nosc":"statue","lemma":"statue","pos":"NOM"} ,
		{"word":"statues","word_nosc":"statues","lemma":"statue","pos":"NOM"} ,
		{"word":"statuette","word_nosc":"statuette","lemma":"statuette","pos":"NOM"} ,
		{"word":"statuettes","word_nosc":"statuettes","lemma":"statuette","pos":"NOM"} ,
		{"word":"stature","word_nosc":"stature","lemma":"stature","pos":"NOM"} ,
		{"word":"statures","word_nosc":"statures","lemma":"stature","pos":"NOM"} ,
		{"word":"status","word_nosc":"status","lemma":"status","pos":"NOM"} ,
		{"word":"statut","word_nosc":"statut","lemma":"statut","pos":"NOM"} ,
		{"word":"statuts","word_nosc":"statuts","lemma":"statut","pos":"NOM"} ,
		{"word":"statère","word_nosc":"statere","lemma":"statère","pos":"NOM"} ,
		{"word":"statères","word_nosc":"stateres","lemma":"statère","pos":"NOM"} ,
		{"word":"stayer","word_nosc":"stayer","lemma":"stayer","pos":"NOM"} ,
		{"word":"steak","word_nosc":"steak","lemma":"steak","pos":"NOM"} ,
		{"word":"steaks","word_nosc":"steaks","lemma":"steak","pos":"NOM"} ,
		{"word":"steamboat","word_nosc":"steamboat","lemma":"steamboat","pos":"NOM"} ,
		{"word":"steamer","word_nosc":"steamer","lemma":"steamer","pos":"NOM"} ,
		{"word":"steamers","word_nosc":"steamers","lemma":"steamer","pos":"NOM"} ,
		{"word":"steeple","word_nosc":"steeple","lemma":"steeple","pos":"NOM"} ,
		{"word":"steeple-chase","word_nosc":"steeple-chase","lemma":"steeple-chase","pos":"NOM"} ,
		{"word":"steeples","word_nosc":"steeples","lemma":"steeple","pos":"NOM"} ,
		{"word":"stegomya","word_nosc":"stegomya","lemma":"stegomya","pos":"NOM"} ,
		{"word":"stem","word_nosc":"stem","lemma":"stem","pos":"NOM"} ,
		{"word":"stencil","word_nosc":"stencil","lemma":"stencil","pos":"NOM"} ,
		{"word":"stendhalien","word_nosc":"stendhalien","lemma":"stendhalien","pos":"NOM"} ,
		{"word":"stentor","word_nosc":"stentor","lemma":"stentor","pos":"NOM"} ,
		{"word":"stentors","word_nosc":"stentors","lemma":"stentor","pos":"NOM"} ,
		{"word":"steppe","word_nosc":"steppe","lemma":"steppe","pos":"NOM"} ,
		{"word":"stepper","word_nosc":"stepper","lemma":"stepper","pos":"NOM"} ,
		{"word":"steppers","word_nosc":"steppers","lemma":"stepper","pos":"NOM"} ,
		{"word":"steppes","word_nosc":"steppes","lemma":"steppe","pos":"NOM"} ,
		{"word":"stercoraires","word_nosc":"stercoraires","lemma":"stercoraire","pos":"NOM"} ,
		{"word":"sterling","word_nosc":"sterling","lemma":"sterling","pos":"NOM"} ,
		{"word":"sterne","word_nosc":"sterne","lemma":"sterne","pos":"NOM"} ,
		{"word":"sternes","word_nosc":"sternes","lemma":"sterne","pos":"NOM"} ,
		{"word":"sternum","word_nosc":"sternum","lemma":"sternum","pos":"NOM"} ,
		{"word":"stetson","word_nosc":"stetson","lemma":"stetson","pos":"NOM"} ,
		{"word":"steward","word_nosc":"steward","lemma":"steward","pos":"NOM"} ,
		{"word":"stick","word_nosc":"stick","lemma":"stick","pos":"NOM"} ,
		{"word":"sticker","word_nosc":"sticker","lemma":"sticker","pos":"NOM"} ,
		{"word":"sticks","word_nosc":"sticks","lemma":"stick","pos":"NOM"} ,
		{"word":"stigma","word_nosc":"stigma","lemma":"stigma","pos":"NOM"} ,
		{"word":"stigmate","word_nosc":"stigmate","lemma":"stigmate","pos":"NOM"} ,
		{"word":"stigmates","word_nosc":"stigmates","lemma":"stigmate","pos":"NOM"} ,
		{"word":"stilton","word_nosc":"stilton","lemma":"stilton","pos":"NOM"} ,
		{"word":"stimulant","word_nosc":"stimulant","lemma":"stimulant","pos":"NOM"} ,
		{"word":"stimulants","word_nosc":"stimulants","lemma":"stimulant","pos":"NOM"} ,
		{"word":"stimulateur","word_nosc":"stimulateur","lemma":"stimulateur","pos":"NOM"} ,
		{"word":"stimulation","word_nosc":"stimulation","lemma":"stimulation","pos":"NOM"} ,
		{"word":"stimulations","word_nosc":"stimulations","lemma":"stimulation","pos":"NOM"} ,
		{"word":"stimuli","word_nosc":"stimuli","lemma":"stimulus","pos":"NOM"} ,
		{"word":"stimulus","word_nosc":"stimulus","lemma":"stimulus","pos":"NOM"} ,
		{"word":"stimulus-réponse","word_nosc":"stimulus-reponse","lemma":"stimulus-réponse","pos":"NOM"} ,
		{"word":"stipes","word_nosc":"stipes","lemma":"stipe","pos":"NOM"} ,
		{"word":"stipulation","word_nosc":"stipulation","lemma":"stipulation","pos":"NOM"} ,
		{"word":"stipulations","word_nosc":"stipulations","lemma":"stipulation","pos":"NOM"} ,
		{"word":"stipule","word_nosc":"stipule","lemma":"stipule","pos":"NOM"} ,
		{"word":"stock","word_nosc":"stock","lemma":"stock","pos":"NOM"} ,
		{"word":"stock-car","word_nosc":"stock-car","lemma":"stock-car","pos":"NOM"} ,
		{"word":"stock-cars","word_nosc":"stock-cars","lemma":"stock-car","pos":"NOM"} ,
		{"word":"stock-options","word_nosc":"stock-options","lemma":"stock-option","pos":"NOM"} ,
		{"word":"stockage","word_nosc":"stockage","lemma":"stockage","pos":"NOM"} ,
		{"word":"stockages","word_nosc":"stockages","lemma":"stockage","pos":"NOM"} ,
		{"word":"stocks","word_nosc":"stocks","lemma":"stock","pos":"NOM"} ,
		{"word":"stoker","word_nosc":"stoker","lemma":"stoker","pos":"NOM"} ,
		{"word":"stokes","word_nosc":"stokes","lemma":"stokes","pos":"NOM"} ,
		{"word":"stomates","word_nosc":"stomates","lemma":"stomate","pos":"NOM"} ,
		{"word":"stomie","word_nosc":"stomie","lemma":"stomie","pos":"NOM"} ,
		{"word":"stomoxys","word_nosc":"stomoxys","lemma":"stomoxys","pos":"NOM"} ,
		{"word":"stop","word_nosc":"stop","lemma":"stop","pos":"NOM"} ,
		{"word":"stoppage","word_nosc":"stoppage","lemma":"stoppage","pos":"NOM"} ,
		{"word":"stoppeur","word_nosc":"stoppeur","lemma":"stoppeur","pos":"NOM"} ,
		{"word":"stoppeurs","word_nosc":"stoppeurs","lemma":"stoppeur","pos":"NOM"} ,
		{"word":"stoppeuse","word_nosc":"stoppeuse","lemma":"stoppeur","pos":"NOM"} ,
		{"word":"stops","word_nosc":"stops","lemma":"stop","pos":"NOM"} ,
		{"word":"storage","word_nosc":"storage","lemma":"storage","pos":"NOM"} ,
		{"word":"store","word_nosc":"store","lemma":"store","pos":"NOM"} ,
		{"word":"stores","word_nosc":"stores","lemma":"store","pos":"NOM"} ,
		{"word":"story board","word_nosc":"story board","lemma":"story-board","pos":"NOM"} ,
		{"word":"story-board","word_nosc":"story-board","lemma":"story-board","pos":"NOM"} ,
		{"word":"story-boards","word_nosc":"story-boards","lemma":"story-board","pos":"NOM"} ,
		{"word":"stout","word_nosc":"stout","lemma":"stout","pos":"NOM"} ,
		{"word":"stoïcien","word_nosc":"stoicien","lemma":"stoïcien","pos":"NOM"} ,
		{"word":"stoïciens","word_nosc":"stoiciens","lemma":"stoïcien","pos":"NOM"} ,
		{"word":"stoïcisme","word_nosc":"stoicisme","lemma":"stoïcisme","pos":"NOM"} ,
		{"word":"stoïque","word_nosc":"stoique","lemma":"stoïque","pos":"NOM"} ,
		{"word":"stoïques","word_nosc":"stoiques","lemma":"stoïque","pos":"NOM"} ,
		{"word":"strabisme","word_nosc":"strabisme","lemma":"strabisme","pos":"NOM"} ,
		{"word":"strabismes","word_nosc":"strabismes","lemma":"strabisme","pos":"NOM"} ,
		{"word":"stradivarius","word_nosc":"stradivarius","lemma":"stradivarius","pos":"NOM"} ,
		{"word":"stramoine","word_nosc":"stramoine","lemma":"stramoine","pos":"NOM"} ,
		{"word":"stramonium","word_nosc":"stramonium","lemma":"stramonium","pos":"NOM"} ,
		{"word":"strangulation","word_nosc":"strangulation","lemma":"strangulation","pos":"NOM"} ,
		{"word":"strapontin","word_nosc":"strapontin","lemma":"strapontin","pos":"NOM"} ,
		{"word":"strapontins","word_nosc":"strapontins","lemma":"strapontin","pos":"NOM"} ,
		{"word":"strass","word_nosc":"strass","lemma":"strass","pos":"NOM"} ,
		{"word":"strasse","word_nosc":"strasse","lemma":"strasse","pos":"NOM"} ,
		{"word":"stratagème","word_nosc":"stratageme","lemma":"stratagème","pos":"NOM"} ,
		{"word":"stratagèmes","word_nosc":"stratagemes","lemma":"stratagème","pos":"NOM"} ,
		{"word":"strate","word_nosc":"strate","lemma":"strate","pos":"NOM"} ,
		{"word":"strates","word_nosc":"strates","lemma":"strate","pos":"NOM"} ,
		{"word":"stratification","word_nosc":"stratification","lemma":"stratification","pos":"NOM"} ,
		{"word":"stratifications","word_nosc":"stratifications","lemma":"stratification","pos":"NOM"} ,
		{"word":"stratosphère","word_nosc":"stratosphere","lemma":"stratosphère","pos":"NOM"} ,
		{"word":"stratus","word_nosc":"stratus","lemma":"stratus","pos":"NOM"} ,
		{"word":"stratège","word_nosc":"stratege","lemma":"stratège","pos":"NOM"} ,
		{"word":"stratèges","word_nosc":"strateges","lemma":"stratège","pos":"NOM"} ,
		{"word":"stratégie","word_nosc":"strategie","lemma":"stratégie","pos":"NOM"} ,
		{"word":"stratégies","word_nosc":"strategies","lemma":"stratégie","pos":"NOM"} ,
		{"word":"stratégiste","word_nosc":"strategiste","lemma":"stratégiste","pos":"NOM"} ,
		{"word":"strelitzia","word_nosc":"strelitzia","lemma":"strelitzia","pos":"NOM"} ,
		{"word":"streptococcie","word_nosc":"streptococcie","lemma":"streptococcie","pos":"NOM"} ,
		{"word":"streptocoque","word_nosc":"streptocoque","lemma":"streptocoque","pos":"NOM"} ,
		{"word":"streptokinase","word_nosc":"streptokinase","lemma":"streptokinase","pos":"NOM"} ,
		{"word":"streptomycine","word_nosc":"streptomycine","lemma":"streptomycine","pos":"NOM"} ,
		{"word":"stress","word_nosc":"stress","lemma":"stress","pos":"NOM"} ,
		{"word":"stretch","word_nosc":"stretch","lemma":"stretch","pos":"NOM"} ,
		{"word":"stretching","word_nosc":"stretching","lemma":"stretching","pos":"NOM"} ,
		{"word":"striations","word_nosc":"striations","lemma":"striation","pos":"NOM"} ,
		{"word":"stridence","word_nosc":"stridence","lemma":"stridence","pos":"NOM"} ,
		{"word":"stridences","word_nosc":"stridences","lemma":"stridence","pos":"NOM"} ,
		{"word":"stridor","word_nosc":"stridor","lemma":"stridor","pos":"NOM"} ,
		{"word":"stridulation","word_nosc":"stridulation","lemma":"stridulation","pos":"NOM"} ,
		{"word":"stridulations","word_nosc":"stridulations","lemma":"stridulation","pos":"NOM"} ,
		{"word":"strie","word_nosc":"strie","lemma":"strie","pos":"NOM"} ,
		{"word":"stries","word_nosc":"stries","lemma":"strie","pos":"NOM"} ,
		{"word":"string","word_nosc":"string","lemma":"string","pos":"NOM"} ,
		{"word":"strings","word_nosc":"strings","lemma":"string","pos":"NOM"} ,
		{"word":"strip","word_nosc":"strip","lemma":"strip","pos":"NOM"} ,
		{"word":"strip-poker","word_nosc":"strip-poker","lemma":"strip-poker","pos":"NOM"} ,
		{"word":"strip-tease","word_nosc":"strip-tease","lemma":"strip-tease","pos":"NOM"} ,
		{"word":"strip-teases","word_nosc":"strip-teases","lemma":"strip-tease","pos":"NOM"} ,
		{"word":"strip-teaseur","word_nosc":"strip-teaseur","lemma":"strip-teaseur","pos":"NOM"} ,
		{"word":"strip-teaseurs","word_nosc":"strip-teaseurs","lemma":"strip-teaseur","pos":"NOM"} ,
		{"word":"strip-teaseuse","word_nosc":"strip-teaseuse","lemma":"strip-teaseur","pos":"NOM"} ,
		{"word":"strip-teaseuses","word_nosc":"strip-teaseuses","lemma":"strip-teaseur","pos":"NOM"} ,
		{"word":"striptease","word_nosc":"striptease","lemma":"striptease","pos":"NOM"} ,
		{"word":"stripteaseuse","word_nosc":"stripteaseuse","lemma":"stripteaseur","pos":"NOM"} ,
		{"word":"stripteaseuses","word_nosc":"stripteaseuses","lemma":"stripteaseur","pos":"NOM"} ,
		{"word":"striures","word_nosc":"striures","lemma":"striure","pos":"NOM"} ,
		{"word":"stroboscope","word_nosc":"stroboscope","lemma":"stroboscope","pos":"NOM"} ,
		{"word":"strontiane","word_nosc":"strontiane","lemma":"strontiane","pos":"NOM"} ,
		{"word":"strontium","word_nosc":"strontium","lemma":"strontium","pos":"NOM"} ,
		{"word":"strophe","word_nosc":"strophe","lemma":"strophe","pos":"NOM"} ,
		{"word":"strophes","word_nosc":"strophes","lemma":"strophe","pos":"NOM"} ,
		{"word":"stropiats","word_nosc":"stropiats","lemma":"stropiat","pos":"NOM"} ,
		{"word":"structuralisme","word_nosc":"structuralisme","lemma":"structuralisme","pos":"NOM"} ,
		{"word":"structurations","word_nosc":"structurations","lemma":"structuration","pos":"NOM"} ,
		{"word":"structure","word_nosc":"structure","lemma":"structure","pos":"NOM"} ,
		{"word":"structures","word_nosc":"structures","lemma":"structure","pos":"NOM"} ,
		{"word":"strudel","word_nosc":"strudel","lemma":"strudel","pos":"NOM"} ,
		{"word":"strudels","word_nosc":"strudels","lemma":"strudel","pos":"NOM"} ,
		{"word":"struggle for life","word_nosc":"struggle for life","lemma":"struggle for life","pos":"NOM"} ,
		{"word":"strychnine","word_nosc":"strychnine","lemma":"strychnine","pos":"NOM"} ,
		{"word":"strychnos","word_nosc":"strychnos","lemma":"strychnos","pos":"NOM"} ,
		{"word":"stryges","word_nosc":"stryges","lemma":"stryge","pos":"NOM"} ,
		{"word":"stuc","word_nosc":"stuc","lemma":"stuc","pos":"NOM"} ,
		{"word":"stucs","word_nosc":"stucs","lemma":"stuc","pos":"NOM"} ,
		{"word":"studette","word_nosc":"studette","lemma":"studette","pos":"NOM"} ,
		{"word":"studio","word_nosc":"studio","lemma":"studio","pos":"NOM"} ,
		{"word":"studiolo","word_nosc":"studiolo","lemma":"studiolo","pos":"NOM"} ,
		{"word":"studios","word_nosc":"studios","lemma":"studio","pos":"NOM"} ,
		{"word":"stuka","word_nosc":"stuka","lemma":"stuka","pos":"NOM"} ,
		{"word":"stukas","word_nosc":"stukas","lemma":"stuka","pos":"NOM"} ,
		{"word":"stup","word_nosc":"stup","lemma":"stups","pos":"NOM"} ,
		{"word":"stupas","word_nosc":"stupas","lemma":"stupa","pos":"NOM"} ,
		{"word":"stupeur","word_nosc":"stupeur","lemma":"stupeur","pos":"NOM"} ,
		{"word":"stupide","word_nosc":"stupide","lemma":"stupide","pos":"NOM"} ,
		{"word":"stupides","word_nosc":"stupides","lemma":"stupide","pos":"NOM"} ,
		{"word":"stupidité","word_nosc":"stupidite","lemma":"stupidité","pos":"NOM"} ,
		{"word":"stupidités","word_nosc":"stupidites","lemma":"stupidité","pos":"NOM"} ,
		{"word":"stupre","word_nosc":"stupre","lemma":"stupre","pos":"NOM"} ,
		{"word":"stups","word_nosc":"stups","lemma":"stup","pos":"NOM"} ,
		{"word":"stupéfaction","word_nosc":"stupefaction","lemma":"stupéfaction","pos":"NOM"} ,
		{"word":"stupéfiant","word_nosc":"stupefiant","lemma":"stupéfiant","pos":"NOM"} ,
		{"word":"stupéfiants","word_nosc":"stupefiants","lemma":"stupéfiant","pos":"NOM"} ,
		{"word":"style","word_nosc":"style","lemma":"style","pos":"NOM"} ,
		{"word":"styles","word_nosc":"styles","lemma":"style","pos":"NOM"} ,
		{"word":"stylet","word_nosc":"stylet","lemma":"stylet","pos":"NOM"} ,
		{"word":"stylets","word_nosc":"stylets","lemma":"stylet","pos":"NOM"} ,
		{"word":"stylisation","word_nosc":"stylisation","lemma":"stylisation","pos":"NOM"} ,
		{"word":"stylisme","word_nosc":"stylisme","lemma":"stylisme","pos":"NOM"} ,
		{"word":"styliste","word_nosc":"styliste","lemma":"styliste","pos":"NOM"} ,
		{"word":"stylistes","word_nosc":"stylistes","lemma":"styliste","pos":"NOM"} ,
		{"word":"stylite","word_nosc":"stylite","lemma":"stylite","pos":"NOM"} ,
		{"word":"stylo","word_nosc":"stylo","lemma":"stylo","pos":"NOM"} ,
		{"word":"stylo-bille","word_nosc":"stylo-bille","lemma":"stylo-bille","pos":"NOM"} ,
		{"word":"stylo-feutre","word_nosc":"stylo-feutre","lemma":"stylo-feutre","pos":"NOM"} ,
		{"word":"stylobille","word_nosc":"stylobille","lemma":"stylobille","pos":"NOM"} ,
		{"word":"stylographe","word_nosc":"stylographe","lemma":"stylographe","pos":"NOM"} ,
		{"word":"stylographes","word_nosc":"stylographes","lemma":"stylographe","pos":"NOM"} ,
		{"word":"stylomine","word_nosc":"stylomine","lemma":"stylomine","pos":"NOM"} ,
		{"word":"stylos","word_nosc":"stylos","lemma":"stylo","pos":"NOM"} ,
		{"word":"stylos-feutres","word_nosc":"stylos-feutres","lemma":"stylo-feutre","pos":"NOM"} ,
		{"word":"styrax","word_nosc":"styrax","lemma":"styrax","pos":"NOM"} ,
		{"word":"styrène","word_nosc":"styrene","lemma":"styrène","pos":"NOM"} ,
		{"word":"stèle","word_nosc":"stele","lemma":"stèle","pos":"NOM"} ,
		{"word":"stèles","word_nosc":"steles","lemma":"stèle","pos":"NOM"} ,
		{"word":"stère","word_nosc":"stere","lemma":"stère","pos":"NOM"} ,
		{"word":"stères","word_nosc":"steres","lemma":"stère","pos":"NOM"} ,
		{"word":"stéarate","word_nosc":"stearate","lemma":"stéarate","pos":"NOM"} ,
		{"word":"stégosaure","word_nosc":"stegosaure","lemma":"stégosaure","pos":"NOM"} ,
		{"word":"sténo","word_nosc":"steno","lemma":"sténo","pos":"NOM"} ,
		{"word":"sténodactylo","word_nosc":"stenodactylo","lemma":"sténodactylo","pos":"NOM"} ,
		{"word":"sténodactylographie","word_nosc":"stenodactylographie","lemma":"sténodactylographie","pos":"NOM"} ,
		{"word":"sténographe","word_nosc":"stenographe","lemma":"sténographe","pos":"NOM"} ,
		{"word":"sténographie","word_nosc":"stenographie","lemma":"sténographie","pos":"NOM"} ,
		{"word":"sténopé","word_nosc":"stenope","lemma":"sténopé","pos":"NOM"} ,
		{"word":"sténopés","word_nosc":"stenopes","lemma":"sténopé","pos":"NOM"} ,
		{"word":"sténos","word_nosc":"stenos","lemma":"sténo","pos":"NOM"} ,
		{"word":"sténose","word_nosc":"stenose","lemma":"sténose","pos":"NOM"} ,
		{"word":"sténotype","word_nosc":"stenotype","lemma":"sténotype","pos":"NOM"} ,
		{"word":"sténotypiste","word_nosc":"stenotypiste","lemma":"sténotypiste","pos":"NOM"} ,
		{"word":"sténotypistes","word_nosc":"stenotypistes","lemma":"sténotypiste","pos":"NOM"} ,
		{"word":"stéphanois","word_nosc":"stephanois","lemma":"stéphanois","pos":"NOM"} ,
		{"word":"stérilet","word_nosc":"sterilet","lemma":"stérilet","pos":"NOM"} ,
		{"word":"stérilisateur","word_nosc":"sterilisateur","lemma":"stérilisateur","pos":"NOM"} ,
		{"word":"stérilisation","word_nosc":"sterilisation","lemma":"stérilisation","pos":"NOM"} ,
		{"word":"stérilisations","word_nosc":"sterilisations","lemma":"stérilisation","pos":"NOM"} ,
		{"word":"stérilité","word_nosc":"sterilite","lemma":"stérilité","pos":"NOM"} ,
		{"word":"stéréo","word_nosc":"stereo","lemma":"stéréo","pos":"NOM"} ,
		{"word":"stéréophonie","word_nosc":"stereophonie","lemma":"stéréophonie","pos":"NOM"} ,
		{"word":"stéréos","word_nosc":"stereos","lemma":"stéréo","pos":"NOM"} ,
		{"word":"stéréoscope","word_nosc":"stereoscope","lemma":"stéréoscope","pos":"NOM"} ,
		{"word":"stéréoscopie","word_nosc":"stereoscopie","lemma":"stéréoscopie","pos":"NOM"} ,
		{"word":"stéréotype","word_nosc":"stereotype","lemma":"stéréotype","pos":"NOM"} ,
		{"word":"stéréotypes","word_nosc":"stereotypes","lemma":"stéréotype","pos":"NOM"} ,
		{"word":"stéréotypie","word_nosc":"stereotypie","lemma":"stéréotypie","pos":"NOM"} ,
		{"word":"stéthoscope","word_nosc":"stethoscope","lemma":"stéthoscope","pos":"NOM"} ,
		{"word":"stéthoscopes","word_nosc":"stethoscopes","lemma":"stéthoscope","pos":"NOM"} ,
		{"word":"suaire","word_nosc":"suaire","lemma":"suaire","pos":"NOM"} ,
		{"word":"suaires","word_nosc":"suaires","lemma":"suaire","pos":"NOM"} ,
		{"word":"suavité","word_nosc":"suavite","lemma":"suavité","pos":"NOM"} ,
		{"word":"sub-espace","word_nosc":"sub-espace","lemma":"sub-espace","pos":"NOM"} ,
		{"word":"sub-zéro","word_nosc":"sub-zero","lemma":"sub-zéro","pos":"NOM"} ,
		{"word":"subalterne","word_nosc":"subalterne","lemma":"subalterne","pos":"NOM"} ,
		{"word":"subalternes","word_nosc":"subalternes","lemma":"subalterne","pos":"NOM"} ,
		{"word":"subconscient","word_nosc":"subconscient","lemma":"subconscient","pos":"NOM"} ,
		{"word":"subconscients","word_nosc":"subconscients","lemma":"subconscient","pos":"NOM"} ,
		{"word":"subculture","word_nosc":"subculture","lemma":"subculture","pos":"NOM"} ,
		{"word":"subdivision","word_nosc":"subdivision","lemma":"subdivision","pos":"NOM"} ,
		{"word":"subdivisions","word_nosc":"subdivisions","lemma":"subdivision","pos":"NOM"} ,
		{"word":"subduction","word_nosc":"subduction","lemma":"subduction","pos":"NOM"} ,
		{"word":"suber","word_nosc":"suber","lemma":"suber","pos":"NOM"} ,
		{"word":"subjectivisme","word_nosc":"subjectivisme","lemma":"subjectivisme","pos":"NOM"} ,
		{"word":"subjectivité","word_nosc":"subjectivite","lemma":"subjectivité","pos":"NOM"} ,
		{"word":"subjonctif","word_nosc":"subjonctif","lemma":"subjonctif","pos":"NOM"} ,
		{"word":"subjonctifs","word_nosc":"subjonctifs","lemma":"subjonctif","pos":"NOM"} ,
		{"word":"subjugation","word_nosc":"subjugation","lemma":"subjugation","pos":"NOM"} ,
		{"word":"sublimation","word_nosc":"sublimation","lemma":"sublimation","pos":"NOM"} ,
		{"word":"sublimations","word_nosc":"sublimations","lemma":"sublimation","pos":"NOM"} ,
		{"word":"sublimité","word_nosc":"sublimite","lemma":"sublimité","pos":"NOM"} ,
		{"word":"sublimités","word_nosc":"sublimites","lemma":"sublimité","pos":"NOM"} ,
		{"word":"sublimé","word_nosc":"sublime","lemma":"sublimé","pos":"NOM"} ,
		{"word":"sublimés","word_nosc":"sublimes","lemma":"sublimé","pos":"NOM"} ,
		{"word":"submersible","word_nosc":"submersible","lemma":"submersible","pos":"NOM"} ,
		{"word":"submersibles","word_nosc":"submersibles","lemma":"submersible","pos":"NOM"} ,
		{"word":"submersion","word_nosc":"submersion","lemma":"submersion","pos":"NOM"} ,
		{"word":"subordination","word_nosc":"subordination","lemma":"subordination","pos":"NOM"} ,
		{"word":"subordonné","word_nosc":"subordonne","lemma":"subordonné","pos":"NOM"} ,
		{"word":"subordonnée","word_nosc":"subordonnee","lemma":"subordonnée","pos":"NOM"} ,
		{"word":"subordonnées","word_nosc":"subordonnees","lemma":"subordonnée","pos":"NOM"} ,
		{"word":"subordonnés","word_nosc":"subordonnes","lemma":"subordonné","pos":"NOM"} ,
		{"word":"subornation","word_nosc":"subornation","lemma":"subornation","pos":"NOM"} ,
		{"word":"suborneur","word_nosc":"suborneur","lemma":"suborneur","pos":"NOM"} ,
		{"word":"suborneurs","word_nosc":"suborneurs","lemma":"suborneur","pos":"NOM"} ,
		{"word":"subrogation","word_nosc":"subrogation","lemma":"subrogation","pos":"NOM"} ,
		{"word":"subrécargue","word_nosc":"subrecargue","lemma":"subrécargue","pos":"NOM"} ,
		{"word":"subside","word_nosc":"subside","lemma":"subside","pos":"NOM"} ,
		{"word":"subsides","word_nosc":"subsides","lemma":"subside","pos":"NOM"} ,
		{"word":"subsistance","word_nosc":"subsistance","lemma":"subsistance","pos":"NOM"} ,
		{"word":"subsistances","word_nosc":"subsistances","lemma":"subsistance","pos":"NOM"} ,
		{"word":"substance","word_nosc":"substance","lemma":"substance","pos":"NOM"} ,
		{"word":"substances","word_nosc":"substances","lemma":"substance","pos":"NOM"} ,
		{"word":"substantif","word_nosc":"substantif","lemma":"substantif","pos":"NOM"} ,
		{"word":"substantifs","word_nosc":"substantifs","lemma":"substantif","pos":"NOM"} ,
		{"word":"substitut","word_nosc":"substitut","lemma":"substitut","pos":"NOM"} ,
		{"word":"substitution","word_nosc":"substitution","lemma":"substitution","pos":"NOM"} ,
		{"word":"substitutions","word_nosc":"substitutions","lemma":"substitution","pos":"NOM"} ,
		{"word":"substituts","word_nosc":"substituts","lemma":"substitut","pos":"NOM"} ,
		{"word":"substrat","word_nosc":"substrat","lemma":"substrat","pos":"NOM"} ,
		{"word":"substratum","word_nosc":"substratum","lemma":"substratum","pos":"NOM"} ,
		{"word":"substruction","word_nosc":"substruction","lemma":"substruction","pos":"NOM"} ,
		{"word":"subterfuge","word_nosc":"subterfuge","lemma":"subterfuge","pos":"NOM"} ,
		{"word":"subterfuges","word_nosc":"subterfuges","lemma":"subterfuge","pos":"NOM"} ,
		{"word":"subtilité","word_nosc":"subtilite","lemma":"subtilité","pos":"NOM"} ,
		{"word":"subtilités","word_nosc":"subtilites","lemma":"subtilité","pos":"NOM"} ,
		{"word":"subvention","word_nosc":"subvention","lemma":"subvention","pos":"NOM"} ,
		{"word":"subventions","word_nosc":"subventions","lemma":"subvention","pos":"NOM"} ,
		{"word":"subversion","word_nosc":"subversion","lemma":"subversion","pos":"NOM"} ,
		{"word":"subversions","word_nosc":"subversions","lemma":"subversion","pos":"NOM"} ,
		{"word":"suc","word_nosc":"suc","lemma":"suc","pos":"NOM"} ,
		{"word":"successeur","word_nosc":"successeur","lemma":"successeur","pos":"NOM"} ,
		{"word":"successeurs","word_nosc":"successeurs","lemma":"successeur","pos":"NOM"} ,
		{"word":"succession","word_nosc":"succession","lemma":"succession","pos":"NOM"} ,
		{"word":"successions","word_nosc":"successions","lemma":"succession","pos":"NOM"} ,
		{"word":"succion","word_nosc":"succion","lemma":"succion","pos":"NOM"} ,
		{"word":"succions","word_nosc":"succions","lemma":"succion","pos":"NOM"} ,
		{"word":"succube","word_nosc":"succube","lemma":"succube","pos":"NOM"} ,
		{"word":"succubes","word_nosc":"succubes","lemma":"succube","pos":"NOM"} ,
		{"word":"succulence","word_nosc":"succulence","lemma":"succulence","pos":"NOM"} ,
		{"word":"succursale","word_nosc":"succursale","lemma":"succursale","pos":"NOM"} ,
		{"word":"succursales","word_nosc":"succursales","lemma":"succursale","pos":"NOM"} ,
		{"word":"succès","word_nosc":"succes","lemma":"succès","pos":"NOM"} ,
		{"word":"succédané","word_nosc":"succedane","lemma":"succédané","pos":"NOM"} ,
		{"word":"succédanés","word_nosc":"succedanes","lemma":"succédané","pos":"NOM"} ,
		{"word":"sucette","word_nosc":"sucette","lemma":"sucette","pos":"NOM"} ,
		{"word":"sucettes","word_nosc":"sucettes","lemma":"sucette","pos":"NOM"} ,
		{"word":"suceur","word_nosc":"suceur","lemma":"suceur","pos":"NOM"} ,
		{"word":"suceurs","word_nosc":"suceurs","lemma":"suceur","pos":"NOM"} ,
		{"word":"suceuse","word_nosc":"suceuse","lemma":"suceur","pos":"NOM"} ,
		{"word":"suceuses","word_nosc":"suceuses","lemma":"suceur","pos":"NOM"} ,
		{"word":"sucre","word_nosc":"sucre","lemma":"sucre","pos":"NOM"} ,
		{"word":"sucrerie","word_nosc":"sucrerie","lemma":"sucrerie","pos":"NOM"} ,
		{"word":"sucreries","word_nosc":"sucreries","lemma":"sucrerie","pos":"NOM"} ,
		{"word":"sucres","word_nosc":"sucres","lemma":"sucre","pos":"NOM"} ,
		{"word":"sucrette","word_nosc":"sucrette","lemma":"sucrette","pos":"NOM"} ,
		{"word":"sucrettes","word_nosc":"sucrettes","lemma":"sucrette","pos":"NOM"} ,
		{"word":"sucrier","word_nosc":"sucrier","lemma":"sucrier","pos":"NOM"} ,
		{"word":"sucriers","word_nosc":"sucriers","lemma":"sucrier","pos":"NOM"} ,
		{"word":"sucrière","word_nosc":"sucriere","lemma":"sucrier","pos":"NOM"} ,
		{"word":"sucrières","word_nosc":"sucrieres","lemma":"sucrier","pos":"NOM"} ,
		{"word":"sucré","word_nosc":"sucre","lemma":"sucré","pos":"NOM"} ,
		{"word":"sucrés","word_nosc":"sucres","lemma":"sucré","pos":"NOM"} ,
		{"word":"sucs","word_nosc":"sucs","lemma":"suc","pos":"NOM"} ,
		{"word":"sud","word_nosc":"sud","lemma":"sud","pos":"NOM"} ,
		{"word":"sud-africain","word_nosc":"sud-africain","lemma":"sud-africain","pos":"NOM"} ,
		{"word":"sud-africaine","word_nosc":"sud-africaine","lemma":"sud-africain","pos":"NOM"} ,
		{"word":"sud-africains","word_nosc":"sud-africains","lemma":"sud-africain","pos":"NOM"} ,
		{"word":"sud-américain","word_nosc":"sud-americain","lemma":"sud-américain","pos":"NOM"} ,
		{"word":"sud-américaine","word_nosc":"sud-americaine","lemma":"sud-américain","pos":"NOM"} ,
		{"word":"sud-américaines","word_nosc":"sud-americaines","lemma":"sud-américain","pos":"NOM"} ,
		{"word":"sud-américains","word_nosc":"sud-americains","lemma":"sud-américain","pos":"NOM"} ,
		{"word":"sud-coréen","word_nosc":"sud-coreen","lemma":"sud-coréen","pos":"NOM"} ,
		{"word":"sud-est","word_nosc":"sud-est","lemma":"sud-est","pos":"NOM"} ,
		{"word":"sud-ouest","word_nosc":"sud-ouest","lemma":"sud-ouest","pos":"NOM"} ,
		{"word":"sud-sud-est","word_nosc":"sud-sud-est","lemma":"sud-sud-est","pos":"NOM"} ,
		{"word":"sud-vietnamien","word_nosc":"sud-vietnamien","lemma":"sud-vietnamien","pos":"NOM"} ,
		{"word":"sud-vietnamiens","word_nosc":"sud-vietnamiens","lemma":"sud-vietnamien","pos":"NOM"} ,
		{"word":"sudation","word_nosc":"sudation","lemma":"sudation","pos":"NOM"} ,
		{"word":"sudiste","word_nosc":"sudiste","lemma":"sudiste","pos":"NOM"} ,
		{"word":"sudistes","word_nosc":"sudistes","lemma":"sudiste","pos":"NOM"} ,
		{"word":"suerte","word_nosc":"suerte","lemma":"suerte","pos":"NOM"} ,
		{"word":"sueur","word_nosc":"sueur","lemma":"sueur","pos":"NOM"} ,
		{"word":"sueurs","word_nosc":"sueurs","lemma":"sueur","pos":"NOM"} ,
		{"word":"suffisance","word_nosc":"suffisance","lemma":"suffisance","pos":"NOM"} ,
		{"word":"suffisances","word_nosc":"suffisances","lemma":"suffisance","pos":"NOM"} ,
		{"word":"suffixes","word_nosc":"suffixes","lemma":"suffixe","pos":"NOM"} ,
		{"word":"suffocation","word_nosc":"suffocation","lemma":"suffocation","pos":"NOM"} ,
		{"word":"suffocations","word_nosc":"suffocations","lemma":"suffocation","pos":"NOM"} ,
		{"word":"suffrage","word_nosc":"suffrage","lemma":"suffrage","pos":"NOM"} ,
		{"word":"suffrages","word_nosc":"suffrages","lemma":"suffrage","pos":"NOM"} ,
		{"word":"suffragette","word_nosc":"suffragette","lemma":"suffragette","pos":"NOM"} ,
		{"word":"suffragettes","word_nosc":"suffragettes","lemma":"suffragette","pos":"NOM"} ,
		{"word":"suffète","word_nosc":"suffete","lemma":"suffète","pos":"NOM"} ,
		{"word":"suggestibilité","word_nosc":"suggestibilite","lemma":"suggestibilité","pos":"NOM"} ,
		{"word":"suggestion","word_nosc":"suggestion","lemma":"suggestion","pos":"NOM"} ,
		{"word":"suggestions","word_nosc":"suggestions","lemma":"suggestion","pos":"NOM"} ,
		{"word":"suggestivité","word_nosc":"suggestivite","lemma":"suggestivité","pos":"NOM"} ,
		{"word":"sui","word_nosc":"sui","lemma":"sui","pos":"NOM"} ,
		{"word":"suicidaire","word_nosc":"suicidaire","lemma":"suicidaire","pos":"NOM"} ,
		{"word":"suicidaires","word_nosc":"suicidaires","lemma":"suicidaire","pos":"NOM"} ,
		{"word":"suicidant","word_nosc":"suicidant","lemma":"suicidant","pos":"NOM"} ,
		{"word":"suicidants","word_nosc":"suicidants","lemma":"suicidant","pos":"NOM"} ,
		{"word":"suicide","word_nosc":"suicide","lemma":"suicide","pos":"NOM"} ,
		{"word":"suicides","word_nosc":"suicides","lemma":"suicide","pos":"NOM"} ,
		{"word":"suicidé","word_nosc":"suicide","lemma":"suicidé","pos":"NOM"} ,
		{"word":"suicidée","word_nosc":"suicidee","lemma":"suicidé","pos":"NOM"} ,
		{"word":"suicidés","word_nosc":"suicides","lemma":"suicidé","pos":"NOM"} ,
		{"word":"suie","word_nosc":"suie","lemma":"suie","pos":"NOM"} ,
		{"word":"suies","word_nosc":"suies","lemma":"suie","pos":"NOM"} ,
		{"word":"suif","word_nosc":"suif","lemma":"suif","pos":"NOM"} ,
		{"word":"suiffard","word_nosc":"suiffard","lemma":"suiffard","pos":"NOM"} ,
		{"word":"suint","word_nosc":"suint","lemma":"suint","pos":"NOM"} ,
		{"word":"suintement","word_nosc":"suintement","lemma":"suintement","pos":"NOM"} ,
		{"word":"suintements","word_nosc":"suintements","lemma":"suintement","pos":"NOM"} ,
		{"word":"suisse","word_nosc":"suisse","lemma":"suisse","pos":"NOM"} ,
		{"word":"suisses","word_nosc":"suisses","lemma":"suisse","pos":"NOM"} ,
		{"word":"suissesse","word_nosc":"suissesse","lemma":"suisse","pos":"NOM"} ,
		{"word":"suissesses","word_nosc":"suissesses","lemma":"suisse","pos":"NOM"} ,
		{"word":"suite","word_nosc":"suite","lemma":"suite","pos":"NOM"} ,
		{"word":"suites","word_nosc":"suites","lemma":"suit","pos":"NOM"} ,
		{"word":"suites","word_nosc":"suites","lemma":"suite","pos":"NOM"} ,
		{"word":"suivant","word_nosc":"suivant","lemma":"suivant","pos":"NOM"} ,
		{"word":"suivante","word_nosc":"suivante","lemma":"suivant","pos":"NOM"} ,
		{"word":"suivantes","word_nosc":"suivantes","lemma":"suivant","pos":"NOM"} ,
		{"word":"suivants","word_nosc":"suivants","lemma":"suivant","pos":"NOM"} ,
		{"word":"suiveur","word_nosc":"suiveur","lemma":"suiveur","pos":"NOM"} ,
		{"word":"suiveurs","word_nosc":"suiveurs","lemma":"suiveur","pos":"NOM"} ,
		{"word":"suiveuse","word_nosc":"suiveuse","lemma":"suiveur","pos":"NOM"} ,
		{"word":"suivez-moi-jeune-homme","word_nosc":"suivez-moi-jeune-homme","lemma":"suivez-moi-jeune-homme","pos":"NOM"} ,
		{"word":"suivi","word_nosc":"suivi","lemma":"suivi","pos":"NOM"} ,
		{"word":"suivis","word_nosc":"suivis","lemma":"suivi","pos":"NOM"} ,
		{"word":"suivistes","word_nosc":"suivistes","lemma":"suiviste","pos":"NOM"} ,
		{"word":"sujet","word_nosc":"sujet","lemma":"sujet","pos":"NOM"} ,
		{"word":"sujets","word_nosc":"sujets","lemma":"sujet","pos":"NOM"} ,
		{"word":"sujette","word_nosc":"sujette","lemma":"sujette","pos":"NOM"} ,
		{"word":"sujettes","word_nosc":"sujettes","lemma":"sujette","pos":"NOM"} ,
		{"word":"sujétion","word_nosc":"sujetion","lemma":"sujétion","pos":"NOM"} ,
		{"word":"sujétions","word_nosc":"sujetions","lemma":"sujétion","pos":"NOM"} ,
		{"word":"sulfamide","word_nosc":"sulfamide","lemma":"sulfamide","pos":"NOM"} ,
		{"word":"sulfamides","word_nosc":"sulfamides","lemma":"sulfamide","pos":"NOM"} ,
		{"word":"sulfatages","word_nosc":"sulfatages","lemma":"sulfatage","pos":"NOM"} ,
		{"word":"sulfate","word_nosc":"sulfate","lemma":"sulfate","pos":"NOM"} ,
		{"word":"sulfates","word_nosc":"sulfates","lemma":"sulfate","pos":"NOM"} ,
		{"word":"sulfateuse","word_nosc":"sulfateuse","lemma":"sulfateur","pos":"NOM"} ,
		{"word":"sulfateuses","word_nosc":"sulfateuses","lemma":"sulfateur","pos":"NOM"} ,
		{"word":"sulfite","word_nosc":"sulfite","lemma":"sulfite","pos":"NOM"} ,
		{"word":"sulfonate","word_nosc":"sulfonate","lemma":"sulfonate","pos":"NOM"} ,
		{"word":"sulfure","word_nosc":"sulfure","lemma":"sulfure","pos":"NOM"} ,
		{"word":"sulfures","word_nosc":"sulfures","lemma":"sulfure","pos":"NOM"} ,
		{"word":"sulky","word_nosc":"sulky","lemma":"sulky","pos":"NOM"} ,
		{"word":"sultan","word_nosc":"sultan","lemma":"sultan","pos":"NOM"} ,
		{"word":"sultanat","word_nosc":"sultanat","lemma":"sultanat","pos":"NOM"} ,
		{"word":"sultane","word_nosc":"sultane","lemma":"sultane","pos":"NOM"} ,
		{"word":"sultanes","word_nosc":"sultanes","lemma":"sultan","pos":"NOM"} ,
		{"word":"sultans","word_nosc":"sultans","lemma":"sultan","pos":"NOM"} ,
		{"word":"sumac","word_nosc":"sumac","lemma":"sumac","pos":"NOM"} ,
		{"word":"summum","word_nosc":"summum","lemma":"summum","pos":"NOM"} ,
		{"word":"sumo","word_nosc":"sumo","lemma":"sumo","pos":"NOM"} ,
		{"word":"sumérien","word_nosc":"sumerien","lemma":"sumérien","pos":"NOM"} ,
		{"word":"sunlight","word_nosc":"sunlight","lemma":"sunlight","pos":"NOM"} ,
		{"word":"sunlights","word_nosc":"sunlights","lemma":"sunlight","pos":"NOM"} ,
		{"word":"sunna","word_nosc":"sunna","lemma":"sunna","pos":"NOM"} ,
		{"word":"sunnites","word_nosc":"sunnites","lemma":"sunnite","pos":"NOM"} ,
		{"word":"super","word_nosc":"super","lemma":"super","pos":"NOM"} ,
		{"word":"super-espion","word_nosc":"super-espion","lemma":"super-espion","pos":"NOM"} ,
		{"word":"super-grands","word_nosc":"super-grands","lemma":"super-grand","pos":"NOM"} ,
		{"word":"super-puissances","word_nosc":"super-puissances","lemma":"super-puissance","pos":"NOM"} ,
		{"word":"superbe","word_nosc":"superbe","lemma":"superbe","pos":"NOM"} ,
		{"word":"superbes","word_nosc":"superbes","lemma":"superbe","pos":"NOM"} ,
		{"word":"supercalculateur","word_nosc":"supercalculateur","lemma":"supercalculateur","pos":"NOM"} ,
		{"word":"supercarburant","word_nosc":"supercarburant","lemma":"supercarburant","pos":"NOM"} ,
		{"word":"superchampion","word_nosc":"superchampion","lemma":"superchampion","pos":"NOM"} ,
		{"word":"superchampions","word_nosc":"superchampions","lemma":"superchampion","pos":"NOM"} ,
		{"word":"supercherie","word_nosc":"supercherie","lemma":"supercherie","pos":"NOM"} ,
		{"word":"supercheries","word_nosc":"supercheries","lemma":"supercherie","pos":"NOM"} ,
		{"word":"superfamille","word_nosc":"superfamille","lemma":"superfamille","pos":"NOM"} ,
		{"word":"superficialité","word_nosc":"superficialite","lemma":"superficialité","pos":"NOM"} ,
		{"word":"superficialités","word_nosc":"superficialites","lemma":"superficialité","pos":"NOM"} ,
		{"word":"superficie","word_nosc":"superficie","lemma":"superficie","pos":"NOM"} ,
		{"word":"superflu","word_nosc":"superflu","lemma":"superflu","pos":"NOM"} ,
		{"word":"superfluité","word_nosc":"superfluite","lemma":"superfluité","pos":"NOM"} ,
		{"word":"superfluités","word_nosc":"superfluites","lemma":"superfluité","pos":"NOM"} ,
		{"word":"superflus","word_nosc":"superflus","lemma":"superflu","pos":"NOM"} ,
		{"word":"supergrand","word_nosc":"supergrand","lemma":"supergrand","pos":"NOM"} ,
		{"word":"superintendant","word_nosc":"superintendant","lemma":"superintendant","pos":"NOM"} ,
		{"word":"superintendante","word_nosc":"superintendante","lemma":"superintendante","pos":"NOM"} ,
		{"word":"superintendants","word_nosc":"superintendants","lemma":"superintendant","pos":"NOM"} ,
		{"word":"superlatifs","word_nosc":"superlatifs","lemma":"superlatif","pos":"NOM"} ,
		{"word":"superman","word_nosc":"superman","lemma":"superman","pos":"NOM"} ,
		{"word":"supermarché","word_nosc":"supermarche","lemma":"supermarché","pos":"NOM"} ,
		{"word":"supermarchés","word_nosc":"supermarches","lemma":"supermarché","pos":"NOM"} ,
		{"word":"supermen","word_nosc":"supermen","lemma":"superman","pos":"NOM"} ,
		{"word":"supernova","word_nosc":"supernova","lemma":"supernova","pos":"NOM"} ,
		{"word":"supernovas","word_nosc":"supernovas","lemma":"supernova","pos":"NOM"} ,
		{"word":"superordinateur","word_nosc":"superordinateur","lemma":"superordinateur","pos":"NOM"} ,
		{"word":"superphosphates","word_nosc":"superphosphates","lemma":"superphosphate","pos":"NOM"} ,
		{"word":"superposition","word_nosc":"superposition","lemma":"superposition","pos":"NOM"} ,
		{"word":"superpositions","word_nosc":"superpositions","lemma":"superposition","pos":"NOM"} ,
		{"word":"superpouvoirs","word_nosc":"superpouvoirs","lemma":"superpouvoir","pos":"NOM"} ,
		{"word":"superproduction","word_nosc":"superproduction","lemma":"superproduction","pos":"NOM"} ,
		{"word":"superproductions","word_nosc":"superproductions","lemma":"superproduction","pos":"NOM"} ,
		{"word":"superpuissance","word_nosc":"superpuissance","lemma":"superpuissance","pos":"NOM"} ,
		{"word":"superpuissances","word_nosc":"superpuissances","lemma":"superpuissance","pos":"NOM"} ,
		{"word":"supers","word_nosc":"supers","lemma":"super","pos":"NOM"} ,
		{"word":"supersonique","word_nosc":"supersonique","lemma":"supersonique","pos":"NOM"} ,
		{"word":"supersoniques","word_nosc":"supersoniques","lemma":"supersonique","pos":"NOM"} ,
		{"word":"superstar","word_nosc":"superstar","lemma":"superstar","pos":"NOM"} ,
		{"word":"superstars","word_nosc":"superstars","lemma":"superstar","pos":"NOM"} ,
		{"word":"superstitieuse","word_nosc":"superstitieuse","lemma":"superstitieux","pos":"NOM"} ,
		{"word":"superstitieuses","word_nosc":"superstitieuses","lemma":"superstitieux","pos":"NOM"} ,
		{"word":"superstitieux","word_nosc":"superstitieux","lemma":"superstitieux","pos":"NOM"} ,
		{"word":"superstition","word_nosc":"superstition","lemma":"superstition","pos":"NOM"} ,
		{"word":"superstitions","word_nosc":"superstitions","lemma":"superstition","pos":"NOM"} ,
		{"word":"superstructure","word_nosc":"superstructure","lemma":"superstructure","pos":"NOM"} ,
		{"word":"superstructures","word_nosc":"superstructures","lemma":"superstructure","pos":"NOM"} ,
		{"word":"supertanker","word_nosc":"supertanker","lemma":"supertanker","pos":"NOM"} ,
		{"word":"superviseur","word_nosc":"superviseur","lemma":"superviseur","pos":"NOM"} ,
		{"word":"superviseurs","word_nosc":"superviseurs","lemma":"superviseur","pos":"NOM"} ,
		{"word":"supervision","word_nosc":"supervision","lemma":"supervision","pos":"NOM"} ,
		{"word":"supervisions","word_nosc":"supervisions","lemma":"supervision","pos":"NOM"} ,
		{"word":"supin","word_nosc":"supin","lemma":"supin","pos":"NOM"} ,
		{"word":"supination","word_nosc":"supination","lemma":"supination","pos":"NOM"} ,
		{"word":"supplication","word_nosc":"supplication","lemma":"supplication","pos":"NOM"} ,
		{"word":"supplications","word_nosc":"supplications","lemma":"supplication","pos":"NOM"} ,
		{"word":"supplice","word_nosc":"supplice","lemma":"supplice","pos":"NOM"} ,
		{"word":"supplices","word_nosc":"supplices","lemma":"supplice","pos":"NOM"} ,
		{"word":"supplicié","word_nosc":"supplicie","lemma":"supplicié","pos":"NOM"} ,
		{"word":"suppliciée","word_nosc":"suppliciee","lemma":"supplicié","pos":"NOM"} ,
		{"word":"suppliciées","word_nosc":"suppliciees","lemma":"supplicié","pos":"NOM"} ,
		{"word":"suppliciés","word_nosc":"supplicies","lemma":"supplicié","pos":"NOM"} ,
		{"word":"supplique","word_nosc":"supplique","lemma":"supplique","pos":"NOM"} ,
		{"word":"suppliques","word_nosc":"suppliques","lemma":"supplique","pos":"NOM"} ,
		{"word":"suppléance","word_nosc":"suppleance","lemma":"suppléance","pos":"NOM"} ,
		{"word":"suppléant","word_nosc":"suppleant","lemma":"suppléant","pos":"NOM"} ,
		{"word":"suppléante","word_nosc":"suppleante","lemma":"suppléant","pos":"NOM"} ,
		{"word":"suppléants","word_nosc":"suppleants","lemma":"suppléant","pos":"NOM"} ,
		{"word":"supplément","word_nosc":"supplement","lemma":"supplément","pos":"NOM"} ,
		{"word":"suppléments","word_nosc":"supplements","lemma":"supplément","pos":"NOM"} ,
		{"word":"supplétifs","word_nosc":"suppletifs","lemma":"supplétif","pos":"NOM"} ,
		{"word":"support","word_nosc":"support","lemma":"support","pos":"NOM"} ,
		{"word":"supporter","word_nosc":"supporter","lemma":"supporter","pos":"NOM"} ,
		{"word":"supporters","word_nosc":"supporters","lemma":"supporter","pos":"NOM"} ,
		{"word":"supporteur","word_nosc":"supporteur","lemma":"supporteur","pos":"NOM"} ,
		{"word":"supportrice","word_nosc":"supportrice","lemma":"supporteur","pos":"NOM"} ,
		{"word":"supportrices","word_nosc":"supportrices","lemma":"supporteur","pos":"NOM"} ,
		{"word":"supports","word_nosc":"supports","lemma":"support","pos":"NOM"} ,
		{"word":"supports-chaussettes","word_nosc":"supports-chaussettes","lemma":"support-chaussette","pos":"NOM"} ,
		{"word":"supposition","word_nosc":"supposition","lemma":"supposition","pos":"NOM"} ,
		{"word":"suppositions","word_nosc":"suppositions","lemma":"supposition","pos":"NOM"} ,
		{"word":"suppositoire","word_nosc":"suppositoire","lemma":"suppositoire","pos":"NOM"} ,
		{"word":"suppositoires","word_nosc":"suppositoires","lemma":"suppositoire","pos":"NOM"} ,
		{"word":"suppresseur","word_nosc":"suppresseur","lemma":"suppresseur","pos":"NOM"} ,
		{"word":"suppression","word_nosc":"suppression","lemma":"suppression","pos":"NOM"} ,
		{"word":"suppressions","word_nosc":"suppressions","lemma":"suppression","pos":"NOM"} ,
		{"word":"suppuration","word_nosc":"suppuration","lemma":"suppuration","pos":"NOM"} ,
		{"word":"suppurations","word_nosc":"suppurations","lemma":"suppuration","pos":"NOM"} ,
		{"word":"supputation","word_nosc":"supputation","lemma":"supputation","pos":"NOM"} ,
		{"word":"supputations","word_nosc":"supputations","lemma":"supputation","pos":"NOM"} ,
		{"word":"suppôt","word_nosc":"suppot","lemma":"suppôt","pos":"NOM"} ,
		{"word":"suppôts","word_nosc":"suppots","lemma":"suppôt","pos":"NOM"} ,
		{"word":"supraconducteur","word_nosc":"supraconducteur","lemma":"supraconducteur","pos":"NOM"} ,
		{"word":"supraconducteurs","word_nosc":"supraconducteurs","lemma":"supraconducteur","pos":"NOM"} ,
		{"word":"supraconductivité","word_nosc":"supraconductivite","lemma":"supraconductivité","pos":"NOM"} ,
		{"word":"suprématie","word_nosc":"suprematie","lemma":"suprématie","pos":"NOM"} ,
		{"word":"supérette","word_nosc":"superette","lemma":"supérette","pos":"NOM"} ,
		{"word":"supérieur","word_nosc":"superieur","lemma":"supérieur","pos":"NOM"} ,
		{"word":"supérieure","word_nosc":"superieure","lemma":"supérieur","pos":"NOM"} ,
		{"word":"supérieures","word_nosc":"superieures","lemma":"supérieur","pos":"NOM"} ,
		{"word":"supérieurs","word_nosc":"superieurs","lemma":"supérieur","pos":"NOM"} ,
		{"word":"supériorité","word_nosc":"superiorite","lemma":"supériorité","pos":"NOM"} ,
		{"word":"supériorités","word_nosc":"superiorites","lemma":"supériorité","pos":"NOM"} ,
		{"word":"sur","word_nosc":"sur","lemma":"sur","pos":"NOM"} ,
		{"word":"sur-mesure","word_nosc":"sur-mesure","lemma":"sur-mesure","pos":"NOM"} ,
		{"word":"sur-place","word_nosc":"sur-place","lemma":"sur-place","pos":"NOM"} ,
		{"word":"surabondance","word_nosc":"surabondance","lemma":"surabondance","pos":"NOM"} ,
		{"word":"surabondances","word_nosc":"surabondances","lemma":"surabondance","pos":"NOM"} ,
		{"word":"suractivité","word_nosc":"suractivite","lemma":"suractivité","pos":"NOM"} ,
		{"word":"suradaptation","word_nosc":"suradaptation","lemma":"suradaptation","pos":"NOM"} ,
		{"word":"surah","word_nosc":"surah","lemma":"surah","pos":"NOM"} ,
		{"word":"suralimentation","word_nosc":"suralimentation","lemma":"suralimentation","pos":"NOM"} ,
		{"word":"surard","word_nosc":"surard","lemma":"surard","pos":"NOM"} ,
		{"word":"surarmement","word_nosc":"surarmement","lemma":"surarmement","pos":"NOM"} ,
		{"word":"surboum","word_nosc":"surboum","lemma":"surboum","pos":"NOM"} ,
		{"word":"surboums","word_nosc":"surboums","lemma":"surboum","pos":"NOM"} ,
		{"word":"surbrillance","word_nosc":"surbrillance","lemma":"surbrillance","pos":"NOM"} ,
		{"word":"surcapacité","word_nosc":"surcapacite","lemma":"surcapacité","pos":"NOM"} ,
		{"word":"surcharge","word_nosc":"surcharge","lemma":"surcharge","pos":"NOM"} ,
		{"word":"surcharges","word_nosc":"surcharges","lemma":"surcharge","pos":"NOM"} ,
		{"word":"surchauffe","word_nosc":"surchauffe","lemma":"surchauffe","pos":"NOM"} ,
		{"word":"surchoix","word_nosc":"surchoix","lemma":"surchoix","pos":"NOM"} ,
		{"word":"surcompensation","word_nosc":"surcompensation","lemma":"surcompensation","pos":"NOM"} ,
		{"word":"surconsommation","word_nosc":"surconsommation","lemma":"surconsommation","pos":"NOM"} ,
		{"word":"surcontre","word_nosc":"surcontre","lemma":"surcontre","pos":"NOM"} ,
		{"word":"surcot","word_nosc":"surcot","lemma":"surcot","pos":"NOM"} ,
		{"word":"surcots","word_nosc":"surcots","lemma":"surcot","pos":"NOM"} ,
		{"word":"surcoût","word_nosc":"surcout","lemma":"surcoût","pos":"NOM"} ,
		{"word":"surcroît","word_nosc":"surcroit","lemma":"surcroît","pos":"NOM"} ,
		{"word":"surcroîts","word_nosc":"surcroits","lemma":"surcroît","pos":"NOM"} ,
		{"word":"surdité","word_nosc":"surdite","lemma":"surdité","pos":"NOM"} ,
		{"word":"surdosage","word_nosc":"surdosage","lemma":"surdosage","pos":"NOM"} ,
		{"word":"surdose","word_nosc":"surdose","lemma":"surdose","pos":"NOM"} ,
		{"word":"surdoué","word_nosc":"surdoue","lemma":"surdoué","pos":"NOM"} ,
		{"word":"surdouée","word_nosc":"surdouee","lemma":"surdoué","pos":"NOM"} ,
		{"word":"surdoués","word_nosc":"surdoues","lemma":"surdoué","pos":"NOM"} ,
		{"word":"sureau","word_nosc":"sureau","lemma":"sureau","pos":"NOM"} ,
		{"word":"sureaux","word_nosc":"sureaux","lemma":"sureau","pos":"NOM"} ,
		{"word":"sureffectif","word_nosc":"sureffectif","lemma":"sureffectif","pos":"NOM"} ,
		{"word":"suremploi","word_nosc":"suremploi","lemma":"suremploi","pos":"NOM"} ,
		{"word":"surenchère","word_nosc":"surenchere","lemma":"surenchère","pos":"NOM"} ,
		{"word":"surenchères","word_nosc":"surencheres","lemma":"surenchère","pos":"NOM"} ,
		{"word":"surentraînement","word_nosc":"surentrainement","lemma":"surentraînement","pos":"NOM"} ,
		{"word":"surestimation","word_nosc":"surestimation","lemma":"surestimation","pos":"NOM"} ,
		{"word":"surexcitation","word_nosc":"surexcitation","lemma":"surexcitation","pos":"NOM"} ,
		{"word":"surexposition","word_nosc":"surexposition","lemma":"surexposition","pos":"NOM"} ,
		{"word":"surf","word_nosc":"surf","lemma":"surf","pos":"NOM"} ,
		{"word":"surface","word_nosc":"surface","lemma":"surface","pos":"NOM"} ,
		{"word":"surfaces","word_nosc":"surfaces","lemma":"surfac","pos":"NOM"} ,
		{"word":"surfaces","word_nosc":"surfaces","lemma":"surface","pos":"NOM"} ,
		{"word":"surfactant","word_nosc":"surfactant","lemma":"surfactant","pos":"NOM"} ,
		{"word":"surfacturation","word_nosc":"surfacturation","lemma":"surfacturation","pos":"NOM"} ,
		{"word":"surfaçage","word_nosc":"surfacage","lemma":"surfaçage","pos":"NOM"} ,
		{"word":"surfeur","word_nosc":"surfeur","lemma":"surfeur","pos":"NOM"} ,
		{"word":"surfeurs","word_nosc":"surfeurs","lemma":"surfeur","pos":"NOM"} ,
		{"word":"surfeuse","word_nosc":"surfeuse","lemma":"surfeur","pos":"NOM"} ,
		{"word":"surfeuses","word_nosc":"surfeuses","lemma":"surfeur","pos":"NOM"} ,
		{"word":"surfilage","word_nosc":"surfilage","lemma":"surfilage","pos":"NOM"} ,
		{"word":"surgelé","word_nosc":"surgele","lemma":"surgelé","pos":"NOM"} ,
		{"word":"surgelés","word_nosc":"surgeles","lemma":"surgelé","pos":"NOM"} ,
		{"word":"surgeon","word_nosc":"surgeon","lemma":"surgeon","pos":"NOM"} ,
		{"word":"surgeons","word_nosc":"surgeons","lemma":"surgeon","pos":"NOM"} ,
		{"word":"surgissement","word_nosc":"surgissement","lemma":"surgissement","pos":"NOM"} ,
		{"word":"surgissements","word_nosc":"surgissements","lemma":"surgissement","pos":"NOM"} ,
		{"word":"surgé","word_nosc":"surge","lemma":"surgé","pos":"NOM"} ,
		{"word":"surgénérateur","word_nosc":"surgenerateur","lemma":"surgénérateur","pos":"NOM"} ,
		{"word":"surhomme","word_nosc":"surhomme","lemma":"surhomme","pos":"NOM"} ,
		{"word":"surhommes","word_nosc":"surhommes","lemma":"surhomme","pos":"NOM"} ,
		{"word":"surhumain","word_nosc":"surhumain","lemma":"surhumain","pos":"NOM"} ,
		{"word":"surhumanité","word_nosc":"surhumanite","lemma":"surhumanité","pos":"NOM"} ,
		{"word":"suricate","word_nosc":"suricate","lemma":"suricate","pos":"NOM"} ,
		{"word":"surimi","word_nosc":"surimi","lemma":"surimi","pos":"NOM"} ,
		{"word":"surimpression","word_nosc":"surimpression","lemma":"surimpression","pos":"NOM"} ,
		{"word":"surimpressions","word_nosc":"surimpressions","lemma":"surimpression","pos":"NOM"} ,
		{"word":"surin","word_nosc":"surin","lemma":"surin","pos":"NOM"} ,
		{"word":"surinfections","word_nosc":"surinfections","lemma":"surinfection","pos":"NOM"} ,
		{"word":"surinformation","word_nosc":"surinformation","lemma":"surinformation","pos":"NOM"} ,
		{"word":"surins","word_nosc":"surins","lemma":"surin","pos":"NOM"} ,
		{"word":"surintendance","word_nosc":"surintendance","lemma":"surintendance","pos":"NOM"} ,
		{"word":"surintendant","word_nosc":"surintendant","lemma":"surintendant","pos":"NOM"} ,
		{"word":"surintendante","word_nosc":"surintendante","lemma":"surintendant","pos":"NOM"} ,
		{"word":"surintensité","word_nosc":"surintensite","lemma":"surintensité","pos":"NOM"} ,
		{"word":"surinvestissement","word_nosc":"surinvestissement","lemma":"surinvestissement","pos":"NOM"} ,
		{"word":"surjet","word_nosc":"surjet","lemma":"surjet","pos":"NOM"} ,
		{"word":"surjeteuse","word_nosc":"surjeteuse","lemma":"jeteur","pos":"NOM"} ,
		{"word":"surjets","word_nosc":"surjets","lemma":"surjet","pos":"NOM"} ,
		{"word":"surlendemain","word_nosc":"surlendemain","lemma":"surlendemain","pos":"NOM"} ,
		{"word":"surligneur","word_nosc":"surligneur","lemma":"surligneur","pos":"NOM"} ,
		{"word":"surmenage","word_nosc":"surmenage","lemma":"surmenage","pos":"NOM"} ,
		{"word":"surmoi","word_nosc":"surmoi","lemma":"surmoi","pos":"NOM"} ,
		{"word":"surmulot","word_nosc":"surmulot","lemma":"surmulot","pos":"NOM"} ,
		{"word":"surmulots","word_nosc":"surmulots","lemma":"surmulot","pos":"NOM"} ,
		{"word":"surmâle","word_nosc":"surmale","lemma":"surmâle","pos":"NOM"} ,
		{"word":"surmédicalisation","word_nosc":"surmedicalisation","lemma":"surmédicalisation","pos":"NOM"} ,
		{"word":"surnaturel","word_nosc":"surnaturel","lemma":"surnaturel","pos":"NOM"} ,
		{"word":"surnom","word_nosc":"surnom","lemma":"surnom","pos":"NOM"} ,
		{"word":"surnombre","word_nosc":"surnombre","lemma":"surnombre","pos":"NOM"} ,
		{"word":"surnoms","word_nosc":"surnoms","lemma":"surnom","pos":"NOM"} ,
		{"word":"surnuméraire","word_nosc":"surnumeraire","lemma":"surnuméraire","pos":"NOM"} ,
		{"word":"surnuméraires","word_nosc":"surnumeraires","lemma":"surnuméraire","pos":"NOM"} ,
		{"word":"suroît","word_nosc":"suroit","lemma":"suroît","pos":"NOM"} ,
		{"word":"suroîts","word_nosc":"suroits","lemma":"suroît","pos":"NOM"} ,
		{"word":"surpatte","word_nosc":"surpatte","lemma":"surpatte","pos":"NOM"} ,
		{"word":"surpattes","word_nosc":"surpattes","lemma":"surpatte","pos":"NOM"} ,
		{"word":"surpeuplement","word_nosc":"surpeuplement","lemma":"surpeuplement","pos":"NOM"} ,
		{"word":"surpiqûres","word_nosc":"surpiqures","lemma":"surpiqûre","pos":"NOM"} ,
		{"word":"surplace","word_nosc":"surplace","lemma":"surplace","pos":"NOM"} ,
		{"word":"surplis","word_nosc":"surplis","lemma":"surplis","pos":"NOM"} ,
		{"word":"surplomb","word_nosc":"surplomb","lemma":"surplomb","pos":"NOM"} ,
		{"word":"surplombs","word_nosc":"surplombs","lemma":"surplomb","pos":"NOM"} ,
		{"word":"surplus","word_nosc":"surplus","lemma":"surplus","pos":"NOM"} ,
		{"word":"surpoids","word_nosc":"surpoids","lemma":"surpoids","pos":"NOM"} ,
		{"word":"surpopulation","word_nosc":"surpopulation","lemma":"surpopulation","pos":"NOM"} ,
		{"word":"surpresseur","word_nosc":"surpresseur","lemma":"surpresseur","pos":"NOM"} ,
		{"word":"surpression","word_nosc":"surpression","lemma":"surpression","pos":"NOM"} ,
		{"word":"surprime","word_nosc":"surprime","lemma":"surprime","pos":"NOM"} ,
		{"word":"surprise","word_nosc":"surprise","lemma":"surprise","pos":"NOM"} ,
		{"word":"surprise-partie","word_nosc":"surprise-partie","lemma":"surprise-partie","pos":"NOM"} ,
		{"word":"surprise-party","word_nosc":"surprise-party","lemma":"surprise-party","pos":"NOM"} ,
		{"word":"surprises","word_nosc":"surprises","lemma":"surprise","pos":"NOM"} ,
		{"word":"surprises-parties","word_nosc":"surprises-parties","lemma":"surprise-partie","pos":"NOM"} ,
		{"word":"surproduction","word_nosc":"surproduction","lemma":"surproduction","pos":"NOM"} ,
		{"word":"surprotectrice","word_nosc":"surprotectrice","lemma":"surprotecteur","pos":"NOM"} ,
		{"word":"surpuissance","word_nosc":"surpuissance","lemma":"surpuissance","pos":"NOM"} ,
		{"word":"surréalisme","word_nosc":"surrealisme","lemma":"surréalisme","pos":"NOM"} ,
		{"word":"surréaliste","word_nosc":"surrealiste","lemma":"surréaliste","pos":"NOM"} ,
		{"word":"surréalistes","word_nosc":"surrealistes","lemma":"surréaliste","pos":"NOM"} ,
		{"word":"surréalité","word_nosc":"surrealite","lemma":"surréalité","pos":"NOM"} ,
		{"word":"surréel","word_nosc":"surreel","lemma":"surréel","pos":"NOM"} ,
		{"word":"surrégime","word_nosc":"surregime","lemma":"surrégime","pos":"NOM"} ,
		{"word":"surréservation","word_nosc":"surreservation","lemma":"surréservation","pos":"NOM"} ,
		{"word":"sursaut","word_nosc":"sursaut","lemma":"sursaut","pos":"NOM"} ,
		{"word":"sursauts","word_nosc":"sursauts","lemma":"sursaut","pos":"NOM"} ,
		{"word":"sursis","word_nosc":"sursis","lemma":"sursis","pos":"NOM"} ,
		{"word":"sursitaires","word_nosc":"sursitaires","lemma":"sursitaire","pos":"NOM"} ,
		{"word":"surtaxe","word_nosc":"surtaxe","lemma":"surtaxe","pos":"NOM"} ,
		{"word":"surtaxes","word_nosc":"surtaxes","lemma":"surtaxe","pos":"NOM"} ,
		{"word":"surtension","word_nosc":"surtension","lemma":"surtension","pos":"NOM"} ,
		{"word":"surtout","word_nosc":"surtout","lemma":"surtout","pos":"NOM"} ,
		{"word":"surtouts","word_nosc":"surtouts","lemma":"surtout","pos":"NOM"} ,
		{"word":"surveillance","word_nosc":"surveillance","lemma":"surveillance","pos":"NOM"} ,
		{"word":"surveillances","word_nosc":"surveillances","lemma":"surveillance","pos":"NOM"} ,
		{"word":"surveillant","word_nosc":"surveillant","lemma":"surveillant","pos":"NOM"} ,
		{"word":"surveillante","word_nosc":"surveillante","lemma":"surveillant","pos":"NOM"} ,
		{"word":"surveillantes","word_nosc":"surveillantes","lemma":"surveillant","pos":"NOM"} ,
		{"word":"surveillants","word_nosc":"surveillants","lemma":"surveillant","pos":"NOM"} ,
		{"word":"survenants","word_nosc":"survenants","lemma":"survenant","pos":"NOM"} ,
		{"word":"survenue","word_nosc":"survenue","lemma":"survenue","pos":"NOM"} ,
		{"word":"survenues","word_nosc":"survenues","lemma":"survenue","pos":"NOM"} ,
		{"word":"survie","word_nosc":"survie","lemma":"survie","pos":"NOM"} ,
		{"word":"survitesse","word_nosc":"survitesse","lemma":"survitesse","pos":"NOM"} ,
		{"word":"survivaliste","word_nosc":"survivaliste","lemma":"survivaliste","pos":"NOM"} ,
		{"word":"survivance","word_nosc":"survivance","lemma":"survivance","pos":"NOM"} ,
		{"word":"survivances","word_nosc":"survivances","lemma":"survivance","pos":"NOM"} ,
		{"word":"survivant","word_nosc":"survivant","lemma":"survivant","pos":"NOM"} ,
		{"word":"survivante","word_nosc":"survivante","lemma":"survivant","pos":"NOM"} ,
		{"word":"survivantes","word_nosc":"survivantes","lemma":"survivant","pos":"NOM"} ,
		{"word":"survivants","word_nosc":"survivants","lemma":"survivant","pos":"NOM"} ,
		{"word":"survol","word_nosc":"survol","lemma":"survol","pos":"NOM"} ,
		{"word":"survols","word_nosc":"survols","lemma":"survol","pos":"NOM"} ,
		{"word":"survoltage","word_nosc":"survoltage","lemma":"survoltage","pos":"NOM"} ,
		{"word":"survolteur","word_nosc":"survolteur","lemma":"survolteur","pos":"NOM"} ,
		{"word":"survêt","word_nosc":"survet","lemma":"survêt","pos":"NOM"} ,
		{"word":"survêtement","word_nosc":"survetement","lemma":"survêtement","pos":"NOM"} ,
		{"word":"survêtements","word_nosc":"survetements","lemma":"survêtement","pos":"NOM"} ,
		{"word":"survêts","word_nosc":"survets","lemma":"survêt","pos":"NOM"} ,
		{"word":"surélévation","word_nosc":"surelevation","lemma":"surélévation","pos":"NOM"} ,
		{"word":"surévaluation","word_nosc":"surevaluation","lemma":"surévaluation","pos":"NOM"} ,
		{"word":"susceptibilité","word_nosc":"susceptibilite","lemma":"susceptibilité","pos":"NOM"} ,
		{"word":"susceptibilités","word_nosc":"susceptibilites","lemma":"susceptibilité","pos":"NOM"} ,
		{"word":"suscription","word_nosc":"suscription","lemma":"suscription","pos":"NOM"} ,
		{"word":"susdit","word_nosc":"susdit","lemma":"susdit","pos":"NOM"} ,
		{"word":"susdite","word_nosc":"susdite","lemma":"susdit","pos":"NOM"} ,
		{"word":"sushi","word_nosc":"sushi","lemma":"sushi","pos":"NOM"} ,
		{"word":"susnommé","word_nosc":"susnomme","lemma":"susnommé","pos":"NOM"} ,
		{"word":"suspect","word_nosc":"suspect","lemma":"suspect","pos":"NOM"} ,
		{"word":"suspecte","word_nosc":"suspecte","lemma":"suspect","pos":"NOM"} ,
		{"word":"suspectes","word_nosc":"suspectes","lemma":"suspect","pos":"NOM"} ,
		{"word":"suspects","word_nosc":"suspects","lemma":"suspect","pos":"NOM"} ,
		{"word":"suspens","word_nosc":"suspens","lemma":"suspens","pos":"NOM"} ,
		{"word":"suspense","word_nosc":"suspense","lemma":"suspense","pos":"NOM"} ,
		{"word":"suspenses","word_nosc":"suspenses","lemma":"suspense","pos":"NOM"} ,
		{"word":"suspension","word_nosc":"suspension","lemma":"suspension","pos":"NOM"} ,
		{"word":"suspensions","word_nosc":"suspensions","lemma":"suspension","pos":"NOM"} ,
		{"word":"suspensoir","word_nosc":"suspensoir","lemma":"suspensoir","pos":"NOM"} ,
		{"word":"suspensoirs","word_nosc":"suspensoirs","lemma":"suspensoir","pos":"NOM"} ,
		{"word":"suspente","word_nosc":"suspente","lemma":"suspente","pos":"NOM"} ,
		{"word":"suspentes","word_nosc":"suspentes","lemma":"suspente","pos":"NOM"} ,
		{"word":"suspicion","word_nosc":"suspicion","lemma":"suspicion","pos":"NOM"} ,
		{"word":"suspicions","word_nosc":"suspicions","lemma":"suspicion","pos":"NOM"} ,
		{"word":"sustentation","word_nosc":"sustentation","lemma":"sustentation","pos":"NOM"} ,
		{"word":"susucre","word_nosc":"susucre","lemma":"susucre","pos":"NOM"} ,
		{"word":"susurrement","word_nosc":"susurrement","lemma":"susurrement","pos":"NOM"} ,
		{"word":"susurrements","word_nosc":"susurrements","lemma":"susurrement","pos":"NOM"} ,
		{"word":"sutra","word_nosc":"sutra","lemma":"sutra","pos":"NOM"} ,
		{"word":"suture","word_nosc":"suture","lemma":"suture","pos":"NOM"} ,
		{"word":"sutures","word_nosc":"sutures","lemma":"suture","pos":"NOM"} ,
		{"word":"sutémi","word_nosc":"sutemi","lemma":"sutémi","pos":"NOM"} ,
		{"word":"suzerain","word_nosc":"suzerain","lemma":"suzerain","pos":"NOM"} ,
		{"word":"suzeraine","word_nosc":"suzeraine","lemma":"suzerain","pos":"NOM"} ,
		{"word":"suzeraineté","word_nosc":"suzerainete","lemma":"suzeraineté","pos":"NOM"} ,
		{"word":"suzerains","word_nosc":"suzerains","lemma":"suzerain","pos":"NOM"} ,
		{"word":"suçage","word_nosc":"sucage","lemma":"suçage","pos":"NOM"} ,
		{"word":"suçages","word_nosc":"sucages","lemma":"suçage","pos":"NOM"} ,
		{"word":"suçoir","word_nosc":"sucoir","lemma":"suçoir","pos":"NOM"} ,
		{"word":"suçoirs","word_nosc":"sucoirs","lemma":"suçoir","pos":"NOM"} ,
		{"word":"suçon","word_nosc":"sucon","lemma":"suçon","pos":"NOM"} ,
		{"word":"suçons","word_nosc":"sucons","lemma":"suçon","pos":"NOM"} ,
		{"word":"suçotements","word_nosc":"sucotements","lemma":"suçotement","pos":"NOM"} ,
		{"word":"suède","word_nosc":"suede","lemma":"suède","pos":"NOM"} ,
		{"word":"suédine","word_nosc":"suedine","lemma":"suédine","pos":"NOM"} ,
		{"word":"suédois","word_nosc":"suedois","lemma":"suédois","pos":"NOM"} ,
		{"word":"suédoise","word_nosc":"suedoise","lemma":"suédois","pos":"NOM"} ,
		{"word":"suédoises","word_nosc":"suedoises","lemma":"suédois","pos":"NOM"} ,
		{"word":"suée","word_nosc":"suee","lemma":"suée","pos":"NOM"} ,
		{"word":"suées","word_nosc":"suees","lemma":"suée","pos":"NOM"} ,
		{"word":"svastika","word_nosc":"svastika","lemma":"svastika","pos":"NOM"} ,
		{"word":"svastikas","word_nosc":"svastikas","lemma":"svastika","pos":"NOM"} ,
		{"word":"sveltesse","word_nosc":"sveltesse","lemma":"sveltesse","pos":"NOM"} ,
		{"word":"swahili","word_nosc":"swahili","lemma":"swahili","pos":"NOM"} ,
		{"word":"sweat-shirt","word_nosc":"sweat-shirt","lemma":"sweat-shirt","pos":"NOM"} ,
		{"word":"sweater","word_nosc":"sweater","lemma":"sweater","pos":"NOM"} ,
		{"word":"sweaters","word_nosc":"sweaters","lemma":"sweater","pos":"NOM"} ,
		{"word":"sweepstake","word_nosc":"sweepstake","lemma":"sweepstake","pos":"NOM"} ,
		{"word":"swing","word_nosc":"swing","lemma":"swing","pos":"NOM"} ,
		{"word":"swings","word_nosc":"swings","lemma":"swing","pos":"NOM"} ,
		{"word":"sybarites","word_nosc":"sybarites","lemma":"sybarite","pos":"NOM"} ,
		{"word":"sybaritisme","word_nosc":"sybaritisme","lemma":"sybaritisme","pos":"NOM"} ,
		{"word":"sycomore","word_nosc":"sycomore","lemma":"sycomore","pos":"NOM"} ,
		{"word":"sycomores","word_nosc":"sycomores","lemma":"sycomore","pos":"NOM"} ,
		{"word":"sycophante","word_nosc":"sycophante","lemma":"sycophante","pos":"NOM"} ,
		{"word":"sycophantes","word_nosc":"sycophantes","lemma":"sycophante","pos":"NOM"} ,
		{"word":"syllabe","word_nosc":"syllabe","lemma":"syllabe","pos":"NOM"} ,
		{"word":"syllabes","word_nosc":"syllabes","lemma":"syllabe","pos":"NOM"} ,
		{"word":"syllabus","word_nosc":"syllabus","lemma":"syllabus","pos":"NOM"} ,
		{"word":"syllepse","word_nosc":"syllepse","lemma":"syllepse","pos":"NOM"} ,
		{"word":"syllogisme","word_nosc":"syllogisme","lemma":"syllogisme","pos":"NOM"} ,
		{"word":"syllogismes","word_nosc":"syllogismes","lemma":"syllogisme","pos":"NOM"} ,
		{"word":"sylphes","word_nosc":"sylphes","lemma":"sylphe","pos":"NOM"} ,
		{"word":"sylphide","word_nosc":"sylphide","lemma":"sylphide","pos":"NOM"} ,
		{"word":"sylphides","word_nosc":"sylphides","lemma":"sylphide","pos":"NOM"} ,
		{"word":"sylvain","word_nosc":"sylvain","lemma":"sylvain","pos":"NOM"} ,
		{"word":"sylvains","word_nosc":"sylvains","lemma":"sylvain","pos":"NOM"} ,
		{"word":"sylvaner","word_nosc":"sylvaner","lemma":"sylvaner","pos":"NOM"} ,
		{"word":"sylve","word_nosc":"sylve","lemma":"sylve","pos":"NOM"} ,
		{"word":"sylviculture","word_nosc":"sylviculture","lemma":"sylviculture","pos":"NOM"} ,
		{"word":"sylvie","word_nosc":"sylvie","lemma":"sylvie","pos":"NOM"} ,
		{"word":"symbionte","word_nosc":"symbionte","lemma":"symbionte","pos":"NOM"} ,
		{"word":"symbiose","word_nosc":"symbiose","lemma":"symbiose","pos":"NOM"} ,
		{"word":"symbiote","word_nosc":"symbiote","lemma":"symbiote","pos":"NOM"} ,
		{"word":"symbiotes","word_nosc":"symbiotes","lemma":"symbiote","pos":"NOM"} ,
		{"word":"symbole","word_nosc":"symbole","lemma":"symbole","pos":"NOM"} ,
		{"word":"symboles","word_nosc":"symboles","lemma":"symbole","pos":"NOM"} ,
		{"word":"symbolisation","word_nosc":"symbolisation","lemma":"symbolisation","pos":"NOM"} ,
		{"word":"symbolisme","word_nosc":"symbolisme","lemma":"symbolisme","pos":"NOM"} ,
		{"word":"symboliste","word_nosc":"symboliste","lemma":"symboliste","pos":"NOM"} ,
		{"word":"sympathectomie","word_nosc":"sympathectomie","lemma":"sympathectomie","pos":"NOM"} ,
		{"word":"sympathie","word_nosc":"sympathie","lemma":"sympathie","pos":"NOM"} ,
		{"word":"sympathies","word_nosc":"sympathies","lemma":"sympathie","pos":"NOM"} ,
		{"word":"sympathisant","word_nosc":"sympathisant","lemma":"sympathisant","pos":"NOM"} ,
		{"word":"sympathisante","word_nosc":"sympathisante","lemma":"sympathisant","pos":"NOM"} ,
		{"word":"sympathisantes","word_nosc":"sympathisantes","lemma":"sympathisant","pos":"NOM"} ,
		{"word":"sympathisants","word_nosc":"sympathisants","lemma":"sympathisant","pos":"NOM"} ,
		{"word":"symphonie","word_nosc":"symphonie","lemma":"symphonie","pos":"NOM"} ,
		{"word":"symphonies","word_nosc":"symphonies","lemma":"symphonie","pos":"NOM"} ,
		{"word":"symphorines","word_nosc":"symphorines","lemma":"symphorine","pos":"NOM"} ,
		{"word":"symphyse","word_nosc":"symphyse","lemma":"symphyse","pos":"NOM"} ,
		{"word":"symposium","word_nosc":"symposium","lemma":"symposium","pos":"NOM"} ,
		{"word":"symposiums","word_nosc":"symposiums","lemma":"symposium","pos":"NOM"} ,
		{"word":"symptomatologie","word_nosc":"symptomatologie","lemma":"symptomatologie","pos":"NOM"} ,
		{"word":"symptôme","word_nosc":"symptome","lemma":"symptôme","pos":"NOM"} ,
		{"word":"symptômes","word_nosc":"symptomes","lemma":"symptôme","pos":"NOM"} ,
		{"word":"symétrie","word_nosc":"symetrie","lemma":"symétrie","pos":"NOM"} ,
		{"word":"symétries","word_nosc":"symetries","lemma":"symétrie","pos":"NOM"} ,
		{"word":"synagogue","word_nosc":"synagogue","lemma":"synagogue","pos":"NOM"} ,
		{"word":"synagogues","word_nosc":"synagogues","lemma":"synagogue","pos":"NOM"} ,
		{"word":"synapse","word_nosc":"synapse","lemma":"synapse","pos":"NOM"} ,
		{"word":"synapses","word_nosc":"synapses","lemma":"synapse","pos":"NOM"} ,
		{"word":"synchronie","word_nosc":"synchronie","lemma":"synchronie","pos":"NOM"} ,
		{"word":"synchronisation","word_nosc":"synchronisation","lemma":"synchronisation","pos":"NOM"} ,
		{"word":"synchroniseur","word_nosc":"synchroniseur","lemma":"synchroniseur","pos":"NOM"} ,
		{"word":"synchronisme","word_nosc":"synchronisme","lemma":"synchronisme","pos":"NOM"} ,
		{"word":"synchrotron","word_nosc":"synchrotron","lemma":"synchrotron","pos":"NOM"} ,
		{"word":"syncope","word_nosc":"syncope","lemma":"syncope","pos":"NOM"} ,
		{"word":"syncopes","word_nosc":"syncopes","lemma":"syncope","pos":"NOM"} ,
		{"word":"syncrétisme","word_nosc":"syncretisme","lemma":"syncrétisme","pos":"NOM"} ,
		{"word":"syndic","word_nosc":"syndic","lemma":"syndic","pos":"NOM"} ,
		{"word":"syndicalisation","word_nosc":"syndicalisation","lemma":"syndicalisation","pos":"NOM"} ,
		{"word":"syndicalisme","word_nosc":"syndicalisme","lemma":"syndicalisme","pos":"NOM"} ,
		{"word":"syndicaliste","word_nosc":"syndicaliste","lemma":"syndicaliste","pos":"NOM"} ,
		{"word":"syndicalistes","word_nosc":"syndicalistes","lemma":"syndicaliste","pos":"NOM"} ,
		{"word":"syndicat","word_nosc":"syndicat","lemma":"syndicat","pos":"NOM"} ,
		{"word":"syndication","word_nosc":"syndication","lemma":"syndication","pos":"NOM"} ,
		{"word":"syndicats","word_nosc":"syndicats","lemma":"syndicat","pos":"NOM"} ,
		{"word":"syndics","word_nosc":"syndics","lemma":"syndic","pos":"NOM"} ,
		{"word":"syndiqué","word_nosc":"syndique","lemma":"syndiqué","pos":"NOM"} ,
		{"word":"syndiqués","word_nosc":"syndiques","lemma":"syndiqué","pos":"NOM"} ,
		{"word":"syndrome","word_nosc":"syndrome","lemma":"syndrome","pos":"NOM"} ,
		{"word":"syndromes","word_nosc":"syndromes","lemma":"syndrome","pos":"NOM"} ,
		{"word":"synecdoque","word_nosc":"synecdoque","lemma":"synecdoque","pos":"NOM"} ,
		{"word":"synergie","word_nosc":"synergie","lemma":"synergie","pos":"NOM"} ,
		{"word":"synergies","word_nosc":"synergies","lemma":"synergie","pos":"NOM"} ,
		{"word":"synesthésie","word_nosc":"synesthesie","lemma":"synesthésie","pos":"NOM"} ,
		{"word":"synode","word_nosc":"synode","lemma":"synode","pos":"NOM"} ,
		{"word":"synonyme","word_nosc":"synonyme","lemma":"synonyme","pos":"NOM"} ,
		{"word":"synonymes","word_nosc":"synonymes","lemma":"synonyme","pos":"NOM"} ,
		{"word":"synopsie","word_nosc":"synopsie","lemma":"synopsie","pos":"NOM"} ,
		{"word":"synopsis","word_nosc":"synopsis","lemma":"synopsis","pos":"NOM"} ,
		{"word":"synovite","word_nosc":"synovite","lemma":"synovite","pos":"NOM"} ,
		{"word":"syntagme","word_nosc":"syntagme","lemma":"syntagme","pos":"NOM"} ,
		{"word":"syntaxe","word_nosc":"syntaxe","lemma":"syntaxe","pos":"NOM"} ,
		{"word":"syntaxes","word_nosc":"syntaxes","lemma":"syntaxe","pos":"NOM"} ,
		{"word":"synthèse","word_nosc":"synthese","lemma":"synthèse","pos":"NOM"} ,
		{"word":"synthèses","word_nosc":"syntheses","lemma":"synthèse","pos":"NOM"} ,
		{"word":"synthé","word_nosc":"synthe","lemma":"synthé","pos":"NOM"} ,
		{"word":"synthés","word_nosc":"synthes","lemma":"synthé","pos":"NOM"} ,
		{"word":"synthétiseur","word_nosc":"synthetiseur","lemma":"synthétiseur","pos":"NOM"} ,
		{"word":"synthétiseurs","word_nosc":"synthetiseurs","lemma":"synthétiseur","pos":"NOM"} ,
		{"word":"synérèses","word_nosc":"synereses","lemma":"synérèse","pos":"NOM"} ,
		{"word":"syphilis","word_nosc":"syphilis","lemma":"syphilis","pos":"NOM"} ,
		{"word":"syrah","word_nosc":"syrah","lemma":"syrah","pos":"NOM"} ,
		{"word":"syriaque","word_nosc":"syriaque","lemma":"syriaque","pos":"NOM"} ,
		{"word":"syrien","word_nosc":"syrien","lemma":"syrien","pos":"NOM"} ,
		{"word":"syrienne","word_nosc":"syrienne","lemma":"syrien","pos":"NOM"} ,
		{"word":"syriennes","word_nosc":"syriennes","lemma":"syrien","pos":"NOM"} ,
		{"word":"syriens","word_nosc":"syriens","lemma":"syrien","pos":"NOM"} ,
		{"word":"syringomyélie","word_nosc":"syringomyelie","lemma":"syringomyélie","pos":"NOM"} ,
		{"word":"syrinx","word_nosc":"syrinx","lemma":"syrinx","pos":"NOM"} ,
		{"word":"syrtes","word_nosc":"syrtes","lemma":"syrte","pos":"NOM"} ,
		{"word":"systole","word_nosc":"systole","lemma":"systole","pos":"NOM"} ,
		{"word":"système","word_nosc":"systeme","lemma":"système","pos":"NOM"} ,
		{"word":"systèmes","word_nosc":"systemes","lemma":"système","pos":"NOM"} ,
		{"word":"systèmes-clés","word_nosc":"systemes-cles","lemma":"systèmes-clé","pos":"NOM"} ,
		{"word":"systématisation","word_nosc":"systematisation","lemma":"systématisation","pos":"NOM"} ,
		{"word":"syzygie","word_nosc":"syzygie","lemma":"syzygie","pos":"NOM"} ,
		{"word":"syzygies","word_nosc":"syzygies","lemma":"syzygie","pos":"NOM"} ,
		{"word":"syénite","word_nosc":"syenite","lemma":"syénite","pos":"NOM"} ,
		{"word":"sèche-cheveux","word_nosc":"seche-cheveux","lemma":"sèche-cheveux","pos":"NOM"} ,
		{"word":"sèche-linge","word_nosc":"seche-linge","lemma":"sèche-linge","pos":"NOM"} ,
		{"word":"sèche-mains","word_nosc":"seche-mains","lemma":"sèche-mains","pos":"NOM"} ,
		{"word":"sème","word_nosc":"seme","lemma":"sème","pos":"NOM"} ,
		{"word":"sève","word_nosc":"seve","lemma":"sève","pos":"NOM"} ,
		{"word":"sèves","word_nosc":"seves","lemma":"sève","pos":"NOM"} ,
		{"word":"sèvres","word_nosc":"sevres","lemma":"sèvres","pos":"NOM"} ,
		{"word":"séance","word_nosc":"seance","lemma":"séance","pos":"NOM"} ,
		{"word":"séances","word_nosc":"seances","lemma":"séance","pos":"NOM"} ,
		{"word":"séant","word_nosc":"seant","lemma":"séant","pos":"NOM"} ,
		{"word":"sébaste","word_nosc":"sebaste","lemma":"sébaste","pos":"NOM"} ,
		{"word":"sébile","word_nosc":"sebile","lemma":"sébile","pos":"NOM"} ,
		{"word":"sébiles","word_nosc":"sebiles","lemma":"sébile","pos":"NOM"} ,
		{"word":"séborrhée","word_nosc":"seborrhee","lemma":"séborrhée","pos":"NOM"} ,
		{"word":"sébum","word_nosc":"sebum","lemma":"sébum","pos":"NOM"} ,
		{"word":"sécateur","word_nosc":"secateur","lemma":"sécateur","pos":"NOM"} ,
		{"word":"sécateurs","word_nosc":"secateurs","lemma":"sécateur","pos":"NOM"} ,
		{"word":"sécession","word_nosc":"secession","lemma":"sécession","pos":"NOM"} ,
		{"word":"sécessionniste","word_nosc":"secessionniste","lemma":"sécessionniste","pos":"NOM"} ,
		{"word":"sécessionnistes","word_nosc":"secessionnistes","lemma":"sécessionniste","pos":"NOM"} ,
		{"word":"séchage","word_nosc":"sechage","lemma":"séchage","pos":"NOM"} ,
		{"word":"séchard","word_nosc":"sechard","lemma":"séchard","pos":"NOM"} ,
		{"word":"sécheresse","word_nosc":"secheresse","lemma":"sécheresse","pos":"NOM"} ,
		{"word":"sécheresses","word_nosc":"secheresses","lemma":"sécheresse","pos":"NOM"} ,
		{"word":"sécherie","word_nosc":"secherie","lemma":"sécherie","pos":"NOM"} ,
		{"word":"sécheries","word_nosc":"secheries","lemma":"sécherie","pos":"NOM"} ,
		{"word":"sécheuse","word_nosc":"secheuse","lemma":"sécheur","pos":"NOM"} ,
		{"word":"séchoir","word_nosc":"sechoir","lemma":"séchoir","pos":"NOM"} ,
		{"word":"séchoirs","word_nosc":"sechoirs","lemma":"séchoir","pos":"NOM"} ,
		{"word":"sécot","word_nosc":"secot","lemma":"sécot","pos":"NOM"} ,
		{"word":"sécréteur","word_nosc":"secreteur","lemma":"sécréteur","pos":"NOM"} ,
		{"word":"sécréteurs","word_nosc":"secreteurs","lemma":"sécréteur","pos":"NOM"} ,
		{"word":"sécrétine","word_nosc":"secretine","lemma":"sécrétine","pos":"NOM"} ,
		{"word":"sécrétion","word_nosc":"secretion","lemma":"sécrétion","pos":"NOM"} ,
		{"word":"sécrétions","word_nosc":"secretions","lemma":"sécrétion","pos":"NOM"} ,
		{"word":"sécu","word_nosc":"secu","lemma":"sécu","pos":"NOM"} ,
		{"word":"sécurisation","word_nosc":"securisation","lemma":"sécurisation","pos":"NOM"} ,
		{"word":"sécurit","word_nosc":"securit","lemma":"sécurit","pos":"NOM"} ,
		{"word":"sécurité","word_nosc":"securite","lemma":"sécurité","pos":"NOM"} ,
		{"word":"sécurités","word_nosc":"securites","lemma":"sécurité","pos":"NOM"} ,
		{"word":"sédatif","word_nosc":"sedatif","lemma":"sédatif","pos":"NOM"} ,
		{"word":"sédatifs","word_nosc":"sedatifs","lemma":"sédatif","pos":"NOM"} ,
		{"word":"sédation","word_nosc":"sedation","lemma":"sédation","pos":"NOM"} ,
		{"word":"sédentaire","word_nosc":"sedentaire","lemma":"sédentaire","pos":"NOM"} ,
		{"word":"sédentaires","word_nosc":"sedentaires","lemma":"sédentaire","pos":"NOM"} ,
		{"word":"sédentarisation","word_nosc":"sedentarisation","lemma":"sédentarisation","pos":"NOM"} ,
		{"word":"sédentarisme","word_nosc":"sedentarisme","lemma":"sédentarisme","pos":"NOM"} ,
		{"word":"sédentarité","word_nosc":"sedentarite","lemma":"sédentarité","pos":"NOM"} ,
		{"word":"sédiment","word_nosc":"sediment","lemma":"sédiment","pos":"NOM"} ,
		{"word":"sédimentation","word_nosc":"sedimentation","lemma":"sédimentation","pos":"NOM"} ,
		{"word":"sédimentations","word_nosc":"sedimentations","lemma":"sédimentation","pos":"NOM"} ,
		{"word":"sédiments","word_nosc":"sediments","lemma":"sédiment","pos":"NOM"} ,
		{"word":"séditieux","word_nosc":"seditieux","lemma":"séditieux","pos":"NOM"} ,
		{"word":"sédition","word_nosc":"sedition","lemma":"sédition","pos":"NOM"} ,
		{"word":"séducteur","word_nosc":"seducteur","lemma":"séducteur","pos":"NOM"} ,
		{"word":"séducteurs","word_nosc":"seducteurs","lemma":"séducteur","pos":"NOM"} ,
		{"word":"séduction","word_nosc":"seduction","lemma":"séduction","pos":"NOM"} ,
		{"word":"séductions","word_nosc":"seductions","lemma":"séduction","pos":"NOM"} ,
		{"word":"séductrice","word_nosc":"seductrice","lemma":"séducteur","pos":"NOM"} ,
		{"word":"séductrices","word_nosc":"seductrices","lemma":"séducteur","pos":"NOM"} ,
		{"word":"séfarade","word_nosc":"sefarade","lemma":"séfarade","pos":"NOM"} ,
		{"word":"ségrégation","word_nosc":"segregation","lemma":"ségrégation","pos":"NOM"} ,
		{"word":"séguedille","word_nosc":"seguedille","lemma":"séguedille","pos":"NOM"} ,
		{"word":"séide","word_nosc":"seide","lemma":"séide","pos":"NOM"} ,
		{"word":"séides","word_nosc":"seides","lemma":"séide","pos":"NOM"} ,
		{"word":"séisme","word_nosc":"seisme","lemma":"séisme","pos":"NOM"} ,
		{"word":"séismes","word_nosc":"seismes","lemma":"séisme","pos":"NOM"} ,
		{"word":"séjour","word_nosc":"sejour","lemma":"séjour","pos":"NOM"} ,
		{"word":"séjours","word_nosc":"sejours","lemma":"séjour","pos":"NOM"} ,
		{"word":"sélecteur","word_nosc":"selecteur","lemma":"sélecteur","pos":"NOM"} ,
		{"word":"sélecteurs","word_nosc":"selecteurs","lemma":"sélecteur","pos":"NOM"} ,
		{"word":"sélection","word_nosc":"selection","lemma":"sélection","pos":"NOM"} ,
		{"word":"sélectionneur","word_nosc":"selectionneur","lemma":"sélectionneur","pos":"NOM"} ,
		{"word":"sélectionneuse","word_nosc":"selectionneuse","lemma":"sélectionneuse","pos":"NOM"} ,
		{"word":"sélectionné","word_nosc":"selectionne","lemma":"sélectionné","pos":"NOM"} ,
		{"word":"sélectionnés","word_nosc":"selectionnes","lemma":"sélectionné","pos":"NOM"} ,
		{"word":"sélections","word_nosc":"selections","lemma":"sélection","pos":"NOM"} ,
		{"word":"sélectivité","word_nosc":"selectivite","lemma":"sélectivité","pos":"NOM"} ,
		{"word":"sélénite","word_nosc":"selenite","lemma":"sélénite","pos":"NOM"} ,
		{"word":"sélénites","word_nosc":"selenites","lemma":"sélénite","pos":"NOM"} ,
		{"word":"sélénium","word_nosc":"selenium","lemma":"sélénium","pos":"NOM"} ,
		{"word":"séléniure","word_nosc":"seleniure","lemma":"séléniure","pos":"NOM"} ,
		{"word":"sélénographie","word_nosc":"selenographie","lemma":"sélénographie","pos":"NOM"} ,
		{"word":"sémantique","word_nosc":"semantique","lemma":"sémantique","pos":"NOM"} ,
		{"word":"sémaphore","word_nosc":"semaphore","lemma":"sémaphore","pos":"NOM"} ,
		{"word":"sémaphores","word_nosc":"semaphores","lemma":"sémaphore","pos":"NOM"} ,
		{"word":"sémillon","word_nosc":"semillon","lemma":"sémillon","pos":"NOM"} ,
		{"word":"séminaire","word_nosc":"seminaire","lemma":"séminaire","pos":"NOM"} ,
		{"word":"séminaires","word_nosc":"seminaires","lemma":"séminaire","pos":"NOM"} ,
		{"word":"séminariste","word_nosc":"seminariste","lemma":"séminariste","pos":"NOM"} ,
		{"word":"séminaristes","word_nosc":"seminaristes","lemma":"séminariste","pos":"NOM"} ,
		{"word":"séminole","word_nosc":"seminole","lemma":"séminole","pos":"NOM"} ,
		{"word":"séminoles","word_nosc":"seminoles","lemma":"séminole","pos":"NOM"} ,
		{"word":"séminome","word_nosc":"seminome","lemma":"séminome","pos":"NOM"} ,
		{"word":"sémiologie","word_nosc":"semiologie","lemma":"sémiologie","pos":"NOM"} ,
		{"word":"sémiologue","word_nosc":"semiologue","lemma":"sémiologue","pos":"NOM"} ,
		{"word":"sémiotique","word_nosc":"semiotique","lemma":"sémiotique","pos":"NOM"} ,
		{"word":"sémiotiques","word_nosc":"semiotiques","lemma":"sémiotique","pos":"NOM"} ,
		{"word":"sémite","word_nosc":"semite","lemma":"sémite","pos":"NOM"} ,
		{"word":"sémites","word_nosc":"semites","lemma":"sémite","pos":"NOM"} ,
		{"word":"sénat","word_nosc":"senat","lemma":"sénat","pos":"NOM"} ,
		{"word":"sénateur","word_nosc":"senateur","lemma":"sénateur","pos":"NOM"} ,
		{"word":"sénateurs","word_nosc":"senateurs","lemma":"sénateur","pos":"NOM"} ,
		{"word":"sénatoriales","word_nosc":"senatoriales","lemma":"sénatorial","pos":"NOM"} ,
		{"word":"sénatrice","word_nosc":"senatrice","lemma":"sénateur","pos":"NOM"} ,
		{"word":"sénatus-consulte","word_nosc":"senatus-consulte","lemma":"sénatus-consulte","pos":"NOM"} ,
		{"word":"sénescence","word_nosc":"senescence","lemma":"sénescence","pos":"NOM"} ,
		{"word":"sénevé","word_nosc":"seneve","lemma":"sénevé","pos":"NOM"} ,
		{"word":"sénilité","word_nosc":"senilite","lemma":"sénilité","pos":"NOM"} ,
		{"word":"séné","word_nosc":"sene","lemma":"séné","pos":"NOM"} ,
		{"word":"sénéchal","word_nosc":"senechal","lemma":"sénéchal","pos":"NOM"} ,
		{"word":"sénéchaux","word_nosc":"senechaux","lemma":"sénéchal","pos":"NOM"} ,
		{"word":"sénégalais","word_nosc":"senegalais","lemma":"sénégalais","pos":"NOM"} ,
		{"word":"sénégalaise","word_nosc":"senegalaise","lemma":"sénégalais","pos":"NOM"} ,
		{"word":"sénégalaises","word_nosc":"senegalaises","lemma":"sénégalais","pos":"NOM"} ,
		{"word":"sénés","word_nosc":"senes","lemma":"séné","pos":"NOM"} ,
		{"word":"sépale","word_nosc":"sepale","lemma":"sépale","pos":"NOM"} ,
		{"word":"sépales","word_nosc":"sepales","lemma":"sépale","pos":"NOM"} ,
		{"word":"séparateur","word_nosc":"separateur","lemma":"séparateur","pos":"NOM"} ,
		{"word":"séparation","word_nosc":"separation","lemma":"séparation","pos":"NOM"} ,
		{"word":"séparations","word_nosc":"separations","lemma":"séparation","pos":"NOM"} ,
		{"word":"séparatiste","word_nosc":"separatiste","lemma":"séparatiste","pos":"NOM"} ,
		{"word":"séparatistes","word_nosc":"separatistes","lemma":"séparatiste","pos":"NOM"} ,
		{"word":"sépharade","word_nosc":"sepharade","lemma":"sépharade","pos":"NOM"} ,
		{"word":"sépharades","word_nosc":"sepharades","lemma":"sépharade","pos":"NOM"} ,
		{"word":"sépia","word_nosc":"sepia","lemma":"sépia","pos":"NOM"} ,
		{"word":"sépias","word_nosc":"sepias","lemma":"sépia","pos":"NOM"} ,
		{"word":"sépulcre","word_nosc":"sepulcre","lemma":"sépulcre","pos":"NOM"} ,
		{"word":"sépulcres","word_nosc":"sepulcres","lemma":"sépulcre","pos":"NOM"} ,
		{"word":"sépulture","word_nosc":"sepulture","lemma":"sépulture","pos":"NOM"} ,
		{"word":"sépultures","word_nosc":"sepultures","lemma":"sépulture","pos":"NOM"} ,
		{"word":"séquanaise","word_nosc":"sequanaise","lemma":"séquanais","pos":"NOM"} ,
		{"word":"séquelle","word_nosc":"sequelle","lemma":"séquelle","pos":"NOM"} ,
		{"word":"séquelles","word_nosc":"sequelles","lemma":"séquelle","pos":"NOM"} ,
		{"word":"séquence","word_nosc":"sequence","lemma":"séquence","pos":"NOM"} ,
		{"word":"séquences","word_nosc":"sequences","lemma":"séquence","pos":"NOM"} ,
		{"word":"séquenceur","word_nosc":"sequenceur","lemma":"séquenceur","pos":"NOM"} ,
		{"word":"séquençage","word_nosc":"sequencage","lemma":"séquençage","pos":"NOM"} ,
		{"word":"séquestration","word_nosc":"sequestration","lemma":"séquestration","pos":"NOM"} ,
		{"word":"séquestrations","word_nosc":"sequestrations","lemma":"séquestration","pos":"NOM"} ,
		{"word":"séquestre","word_nosc":"sequestre","lemma":"séquestre","pos":"NOM"} ,
		{"word":"séquestres","word_nosc":"sequestres","lemma":"séquestre","pos":"NOM"} ,
		{"word":"séquoia","word_nosc":"sequoia","lemma":"séquoia","pos":"NOM"} ,
		{"word":"séquoias","word_nosc":"sequoias","lemma":"séquoia","pos":"NOM"} ,
		{"word":"sérac","word_nosc":"serac","lemma":"sérac","pos":"NOM"} ,
		{"word":"séracs","word_nosc":"seracs","lemma":"sérac","pos":"NOM"} ,
		{"word":"sérail","word_nosc":"serail","lemma":"sérail","pos":"NOM"} ,
		{"word":"séraphin","word_nosc":"seraphin","lemma":"séraphin","pos":"NOM"} ,
		{"word":"séraphins","word_nosc":"seraphins","lemma":"séraphin","pos":"NOM"} ,
		{"word":"sérial","word_nosc":"serial","lemma":"sérial","pos":"NOM"} ,
		{"word":"série","word_nosc":"serie","lemma":"série","pos":"NOM"} ,
		{"word":"séries","word_nosc":"series","lemma":"série","pos":"NOM"} ,
		{"word":"sérieuse","word_nosc":"serieuse","lemma":"sérieux","pos":"NOM"} ,
		{"word":"sérieuses","word_nosc":"serieuses","lemma":"sérieux","pos":"NOM"} ,
		{"word":"sérieux","word_nosc":"serieux","lemma":"sérieux","pos":"NOM"} ,
		{"word":"sérologie","word_nosc":"serologie","lemma":"sérologie","pos":"NOM"} ,
		{"word":"séronégatif","word_nosc":"seronegatif","lemma":"séronégatif","pos":"NOM"} ,
		{"word":"séronégatifs","word_nosc":"seronegatifs","lemma":"séronégatif","pos":"NOM"} ,
		{"word":"séropo","word_nosc":"seropo","lemma":"séropo","pos":"NOM"} ,
		{"word":"séropositif","word_nosc":"seropositif","lemma":"séropositif","pos":"NOM"} ,
		{"word":"séropositifs","word_nosc":"seropositifs","lemma":"séropositif","pos":"NOM"} ,
		{"word":"séropositive","word_nosc":"seropositive","lemma":"séropositive","pos":"NOM"} ,
		{"word":"séropositivité","word_nosc":"seropositivite","lemma":"séropositivité","pos":"NOM"} ,
		{"word":"sérosité","word_nosc":"serosite","lemma":"sérosité","pos":"NOM"} ,
		{"word":"sérotonine","word_nosc":"serotonine","lemma":"sérotonine","pos":"NOM"} ,
		{"word":"sérum","word_nosc":"serum","lemma":"sérum","pos":"NOM"} ,
		{"word":"sérums","word_nosc":"serums","lemma":"sérum","pos":"NOM"} ,
		{"word":"sérénade","word_nosc":"serenade","lemma":"sérénade","pos":"NOM"} ,
		{"word":"sérénades","word_nosc":"serenades","lemma":"sérénade","pos":"NOM"} ,
		{"word":"sérénité","word_nosc":"serenite","lemma":"sérénité","pos":"NOM"} ,
		{"word":"sésame","word_nosc":"sesame","lemma":"sésame","pos":"NOM"} ,
		{"word":"séton","word_nosc":"seton","lemma":"séton","pos":"NOM"} ,
		{"word":"sévice","word_nosc":"sevice","lemma":"sévices","pos":"NOM"} ,
		{"word":"sévices","word_nosc":"sevices","lemma":"sévices","pos":"NOM"} ,
		{"word":"sévillan","word_nosc":"sevillan","lemma":"sévillan","pos":"NOM"} ,
		{"word":"sévillane","word_nosc":"sevillane","lemma":"sévillan","pos":"NOM"} ,
		{"word":"sévrienne","word_nosc":"sevrienne","lemma":"sévrienne","pos":"NOM"} ,
		{"word":"sévriennes","word_nosc":"sevriennes","lemma":"sévrienne","pos":"NOM"} ,
		{"word":"sévérité","word_nosc":"severite","lemma":"sévérité","pos":"NOM"} ,
		{"word":"sévérités","word_nosc":"severites","lemma":"sévérité","pos":"NOM"} ,
		{"word":"sûreté","word_nosc":"surete","lemma":"sûreté","pos":"NOM"} ,
		{"word":"sûretés","word_nosc":"suretes","lemma":"sûreté","pos":"NOM"} ,
		{"word":"t","word_nosc":"t","lemma":"t","pos":"NOM"} ,
		{"word":"t'","word_nosc":"t'","lemma":"t","pos":"NOM"} ,
		{"word":"t-shirt","word_nosc":"t-shirt","lemma":"t-shirt","pos":"NOM"} ,
		{"word":"t-shirts","word_nosc":"t-shirts","lemma":"t-shirt","pos":"NOM"} ,
		{"word":"tabac","word_nosc":"tabac","lemma":"tabac","pos":"NOM"} ,
		{"word":"tabacs","word_nosc":"tabacs","lemma":"tabac","pos":"NOM"} ,
		{"word":"tabagie","word_nosc":"tabagie","lemma":"tabagie","pos":"NOM"} ,
		{"word":"tabagies","word_nosc":"tabagies","lemma":"tabagie","pos":"NOM"} ,
		{"word":"tabagisme","word_nosc":"tabagisme","lemma":"tabagisme","pos":"NOM"} ,
		{"word":"tabard","word_nosc":"tabard","lemma":"tabard","pos":"NOM"} ,
		{"word":"tabaski","word_nosc":"tabaski","lemma":"tabaski","pos":"NOM"} ,
		{"word":"tabassage","word_nosc":"tabassage","lemma":"tabassage","pos":"NOM"} ,
		{"word":"tabassages","word_nosc":"tabassages","lemma":"tabassage","pos":"NOM"} ,
		{"word":"tabassée","word_nosc":"tabassee","lemma":"tabassée","pos":"NOM"} ,
		{"word":"tabatière","word_nosc":"tabatiere","lemma":"tabatière","pos":"NOM"} ,
		{"word":"tabatières","word_nosc":"tabatieres","lemma":"tabatière","pos":"NOM"} ,
		{"word":"tabellion","word_nosc":"tabellion","lemma":"tabellion","pos":"NOM"} ,
		{"word":"tabellions","word_nosc":"tabellions","lemma":"tabellion","pos":"NOM"} ,
		{"word":"tabernacle","word_nosc":"tabernacle","lemma":"tabernacle","pos":"NOM"} ,
		{"word":"tabernacles","word_nosc":"tabernacles","lemma":"tabernacle","pos":"NOM"} ,
		{"word":"tabla","word_nosc":"tabla","lemma":"tabla","pos":"NOM"} ,
		{"word":"tablature","word_nosc":"tablature","lemma":"tablature","pos":"NOM"} ,
		{"word":"table","word_nosc":"table","lemma":"table","pos":"NOM"} ,
		{"word":"table-bureau","word_nosc":"table-bureau","lemma":"table-bureau","pos":"NOM"} ,
		{"word":"table-coiffeuse","word_nosc":"table-coiffeuse","lemma":"table-coiffeuse","pos":"NOM"} ,
		{"word":"tableau","word_nosc":"tableau","lemma":"tableau","pos":"NOM"} ,
		{"word":"tableautin","word_nosc":"tableautin","lemma":"tableautin","pos":"NOM"} ,
		{"word":"tableautins","word_nosc":"tableautins","lemma":"tableautin","pos":"NOM"} ,
		{"word":"tableaux","word_nosc":"tableaux","lemma":"tableau","pos":"NOM"} ,
		{"word":"tables","word_nosc":"tables","lemma":"table","pos":"NOM"} ,
		{"word":"tablette","word_nosc":"tablette","lemma":"tablette","pos":"NOM"} ,
		{"word":"tabletterie","word_nosc":"tabletterie","lemma":"tabletterie","pos":"NOM"} ,
		{"word":"tablettes","word_nosc":"tablettes","lemma":"tablette","pos":"NOM"} ,
		{"word":"tableur","word_nosc":"tableur","lemma":"tableur","pos":"NOM"} ,
		{"word":"tablier","word_nosc":"tablier","lemma":"tablier","pos":"NOM"} ,
		{"word":"tabliers","word_nosc":"tabliers","lemma":"tablier","pos":"NOM"} ,
		{"word":"tabloïd","word_nosc":"tabloid","lemma":"tabloïd","pos":"NOM"} ,
		{"word":"tabloïde","word_nosc":"tabloide","lemma":"tabloïde","pos":"NOM"} ,
		{"word":"tabloïdes","word_nosc":"tabloides","lemma":"tabloïde","pos":"NOM"} ,
		{"word":"tabloïds","word_nosc":"tabloids","lemma":"tabloïd","pos":"NOM"} ,
		{"word":"tablée","word_nosc":"tablee","lemma":"tablée","pos":"NOM"} ,
		{"word":"tablées","word_nosc":"tablees","lemma":"tablée","pos":"NOM"} ,
		{"word":"tabor","word_nosc":"tabor","lemma":"tabor","pos":"NOM"} ,
		{"word":"taborites","word_nosc":"taborites","lemma":"taborite","pos":"NOM"} ,
		{"word":"tabors","word_nosc":"tabors","lemma":"tabor","pos":"NOM"} ,
		{"word":"tabou","word_nosc":"tabou","lemma":"tabou","pos":"NOM"} ,
		{"word":"taboulé","word_nosc":"taboule","lemma":"taboulé","pos":"NOM"} ,
		{"word":"tabouret","word_nosc":"tabouret","lemma":"tabouret","pos":"NOM"} ,
		{"word":"tabourets","word_nosc":"tabourets","lemma":"tabouret","pos":"NOM"} ,
		{"word":"tabous","word_nosc":"tabous","lemma":"tabou","pos":"NOM"} ,
		{"word":"tabulateur","word_nosc":"tabulateur","lemma":"tabulateur","pos":"NOM"} ,
		{"word":"tabulations","word_nosc":"tabulations","lemma":"tabulation","pos":"NOM"} ,
		{"word":"tabès","word_nosc":"tabes","lemma":"tabès","pos":"NOM"} ,
		{"word":"tac","word_nosc":"tac","lemma":"tac","pos":"NOM"} ,
		{"word":"tacauds","word_nosc":"tacauds","lemma":"tacaud","pos":"NOM"} ,
		{"word":"tache","word_nosc":"tache","lemma":"tache","pos":"NOM"} ,
		{"word":"taches","word_nosc":"taches","lemma":"tache","pos":"NOM"} ,
		{"word":"tachetures","word_nosc":"tachetures","lemma":"tacheture","pos":"NOM"} ,
		{"word":"tachyarythmie","word_nosc":"tachyarythmie","lemma":"tachyarythmie","pos":"NOM"} ,
		{"word":"tachycardie","word_nosc":"tachycardie","lemma":"tachycardie","pos":"NOM"} ,
		{"word":"tachycardies","word_nosc":"tachycardies","lemma":"tachycardie","pos":"NOM"} ,
		{"word":"tachymètre","word_nosc":"tachymetre","lemma":"tachymètre","pos":"NOM"} ,
		{"word":"tachyon","word_nosc":"tachyon","lemma":"tachyon","pos":"NOM"} ,
		{"word":"tachyons","word_nosc":"tachyons","lemma":"tachyon","pos":"NOM"} ,
		{"word":"tachéomètre","word_nosc":"tacheometre","lemma":"tachéomètre","pos":"NOM"} ,
		{"word":"taciturnité","word_nosc":"taciturnite","lemma":"taciturnité","pos":"NOM"} ,
		{"word":"tacle","word_nosc":"tacle","lemma":"tacle","pos":"NOM"} ,
		{"word":"taco","word_nosc":"taco","lemma":"taco","pos":"NOM"} ,
		{"word":"tacon","word_nosc":"tacon","lemma":"tacon","pos":"NOM"} ,
		{"word":"taconnet","word_nosc":"taconnet","lemma":"taconnet","pos":"NOM"} ,
		{"word":"tacos","word_nosc":"tacos","lemma":"taco","pos":"NOM"} ,
		{"word":"tacot","word_nosc":"tacot","lemma":"tacot","pos":"NOM"} ,
		{"word":"tacots","word_nosc":"tacots","lemma":"tacot","pos":"NOM"} ,
		{"word":"tacs","word_nosc":"tacs","lemma":"tac","pos":"NOM"} ,
		{"word":"tact","word_nosc":"tact","lemma":"tact","pos":"NOM"} ,
		{"word":"tacticien","word_nosc":"tacticien","lemma":"tacticien","pos":"NOM"} ,
		{"word":"tacticienne","word_nosc":"tacticienne","lemma":"tacticien","pos":"NOM"} ,
		{"word":"tacticiens","word_nosc":"tacticiens","lemma":"tacticien","pos":"NOM"} ,
		{"word":"tactique","word_nosc":"tactique","lemma":"tactique","pos":"NOM"} ,
		{"word":"tactiques","word_nosc":"tactiques","lemma":"tactique","pos":"NOM"} ,
		{"word":"tadjik","word_nosc":"tadjik","lemma":"tadjik","pos":"NOM"} ,
		{"word":"tadorne","word_nosc":"tadorne","lemma":"tadorne","pos":"NOM"} ,
		{"word":"tadornes","word_nosc":"tadornes","lemma":"tadorne","pos":"NOM"} ,
		{"word":"taenia","word_nosc":"taenia","lemma":"taenia","pos":"NOM"} ,
		{"word":"taf","word_nosc":"taf","lemma":"taf","pos":"NOM"} ,
		{"word":"tafanard","word_nosc":"tafanard","lemma":"tafanard","pos":"NOM"} ,
		{"word":"taffe","word_nosc":"taffe","lemma":"taffe","pos":"NOM"} ,
		{"word":"taffes","word_nosc":"taffes","lemma":"taffe","pos":"NOM"} ,
		{"word":"taffetas","word_nosc":"taffetas","lemma":"taffetas","pos":"NOM"} ,
		{"word":"tafia","word_nosc":"tafia","lemma":"tafia","pos":"NOM"} ,
		{"word":"tafs","word_nosc":"tafs","lemma":"taf","pos":"NOM"} ,
		{"word":"tag","word_nosc":"tag","lemma":"tag","pos":"NOM"} ,
		{"word":"tagalog","word_nosc":"tagalog","lemma":"tagalog","pos":"NOM"} ,
		{"word":"tagger","word_nosc":"tagger","lemma":"tagger","pos":"NOM"} ,
		{"word":"tagliatelle","word_nosc":"tagliatelle","lemma":"tagliatelle","pos":"NOM"} ,
		{"word":"tagliatelles","word_nosc":"tagliatelles","lemma":"tagliatelle","pos":"NOM"} ,
		{"word":"tagueur","word_nosc":"tagueur","lemma":"tagueur","pos":"NOM"} ,
		{"word":"tagueurs","word_nosc":"tagueurs","lemma":"tagueur","pos":"NOM"} ,
		{"word":"tahitien","word_nosc":"tahitien","lemma":"tahitien","pos":"NOM"} ,
		{"word":"tahitienne","word_nosc":"tahitienne","lemma":"tahitien","pos":"NOM"} ,
		{"word":"tahitiennes","word_nosc":"tahitiennes","lemma":"tahitien","pos":"NOM"} ,
		{"word":"tahitiens","word_nosc":"tahitiens","lemma":"tahitien","pos":"NOM"} ,
		{"word":"tai-chi","word_nosc":"tai-chi","lemma":"tai-chi","pos":"NOM"} ,
		{"word":"taie","word_nosc":"taie","lemma":"taie","pos":"NOM"} ,
		{"word":"taies","word_nosc":"taies","lemma":"taie","pos":"NOM"} ,
		{"word":"taifas","word_nosc":"taifas","lemma":"taifa","pos":"NOM"} ,
		{"word":"taillade","word_nosc":"taillade","lemma":"taillade","pos":"NOM"} ,
		{"word":"taillandier","word_nosc":"taillandier","lemma":"taillandier","pos":"NOM"} ,
		{"word":"taillants","word_nosc":"taillants","lemma":"taillant","pos":"NOM"} ,
		{"word":"taille","word_nosc":"taille","lemma":"taille","pos":"NOM"} ,
		{"word":"taille-crayon","word_nosc":"taille-crayon","lemma":"taille-crayon","pos":"NOM"} ,
		{"word":"taille-crayons","word_nosc":"taille-crayons","lemma":"taille-crayon","pos":"NOM"} ,
		{"word":"taille-douce","word_nosc":"taille-douce","lemma":"taille-douce","pos":"NOM"} ,
		{"word":"taille-haie","word_nosc":"taille-haie","lemma":"taille-haie","pos":"NOM"} ,
		{"word":"taille-haies","word_nosc":"taille-haies","lemma":"taille-haie","pos":"NOM"} ,
		{"word":"tailles","word_nosc":"tailles","lemma":"taille","pos":"NOM"} ,
		{"word":"tailles-douces","word_nosc":"tailles-douces","lemma":"taille-douce","pos":"NOM"} ,
		{"word":"tailleur","word_nosc":"tailleur","lemma":"tailleur","pos":"NOM"} ,
		{"word":"tailleur-pantalon","word_nosc":"tailleur-pantalon","lemma":"tailleur-pantalon","pos":"NOM"} ,
		{"word":"tailleurs","word_nosc":"tailleurs","lemma":"tailleur","pos":"NOM"} ,
		{"word":"tailleuse","word_nosc":"tailleuse","lemma":"tailleuse","pos":"NOM"} ,
		{"word":"taillis","word_nosc":"taillis","lemma":"taillis","pos":"NOM"} ,
		{"word":"tailloir","word_nosc":"tailloir","lemma":"tailloir","pos":"NOM"} ,
		{"word":"tailloirs","word_nosc":"tailloirs","lemma":"tailloir","pos":"NOM"} ,
		{"word":"taillons","word_nosc":"taillons","lemma":"taillon","pos":"NOM"} ,
		{"word":"tain","word_nosc":"tain","lemma":"tain","pos":"NOM"} ,
		{"word":"tajine","word_nosc":"tajine","lemma":"tajine","pos":"NOM"} ,
		{"word":"tajines","word_nosc":"tajines","lemma":"tajine","pos":"NOM"} ,
		{"word":"take-off","word_nosc":"take-off","lemma":"take-off","pos":"NOM"} ,
		{"word":"tala","word_nosc":"tala","lemma":"tala","pos":"NOM"} ,
		{"word":"talas","word_nosc":"talas","lemma":"tala","pos":"NOM"} ,
		{"word":"talavera de la reina","word_nosc":"talavera de la reina","lemma":"talavera de la reina","pos":"NOM"} ,
		{"word":"talbin","word_nosc":"talbin","lemma":"talbin","pos":"NOM"} ,
		{"word":"talbins","word_nosc":"talbins","lemma":"talbin","pos":"NOM"} ,
		{"word":"talc","word_nosc":"talc","lemma":"talc","pos":"NOM"} ,
		{"word":"talent","word_nosc":"talent","lemma":"talent","pos":"NOM"} ,
		{"word":"talents","word_nosc":"talents","lemma":"talent","pos":"NOM"} ,
		{"word":"taleth","word_nosc":"taleth","lemma":"taleth","pos":"NOM"} ,
		{"word":"taleths","word_nosc":"taleths","lemma":"taleth","pos":"NOM"} ,
		{"word":"taliban","word_nosc":"taliban","lemma":"taliban","pos":"NOM"} ,
		{"word":"talibans","word_nosc":"talibans","lemma":"taliban","pos":"NOM"} ,
		{"word":"talion","word_nosc":"talion","lemma":"talion","pos":"NOM"} ,
		{"word":"talisman","word_nosc":"talisman","lemma":"talisman","pos":"NOM"} ,
		{"word":"talismans","word_nosc":"talismans","lemma":"talisman","pos":"NOM"} ,
		{"word":"talkie","word_nosc":"talkie","lemma":"talkie","pos":"NOM"} ,
		{"word":"talkie-walkie","word_nosc":"talkie-walkie","lemma":"talkie-walkie","pos":"NOM"} ,
		{"word":"talkies","word_nosc":"talkies","lemma":"talkie","pos":"NOM"} ,
		{"word":"talkies-walkies","word_nosc":"talkies-walkies","lemma":"talkie-walkie","pos":"NOM"} ,
		{"word":"talle","word_nosc":"talle","lemma":"talle","pos":"NOM"} ,
		{"word":"tallipot","word_nosc":"tallipot","lemma":"tallipot","pos":"NOM"} ,
		{"word":"talmouse","word_nosc":"talmouse","lemma":"talmouse","pos":"NOM"} ,
		{"word":"talmudiste","word_nosc":"talmudiste","lemma":"talmudiste","pos":"NOM"} ,
		{"word":"talmudistes","word_nosc":"talmudistes","lemma":"talmudiste","pos":"NOM"} ,
		{"word":"taloche","word_nosc":"taloche","lemma":"taloche","pos":"NOM"} ,
		{"word":"taloches","word_nosc":"taloches","lemma":"taloche","pos":"NOM"} ,
		{"word":"talon","word_nosc":"talon","lemma":"talon","pos":"NOM"} ,
		{"word":"talonnades","word_nosc":"talonnades","lemma":"talonnade","pos":"NOM"} ,
		{"word":"talonnette","word_nosc":"talonnette","lemma":"talonnette","pos":"NOM"} ,
		{"word":"talonnettes","word_nosc":"talonnettes","lemma":"talonnette","pos":"NOM"} ,
		{"word":"talonneur","word_nosc":"talonneur","lemma":"talonneur","pos":"NOM"} ,
		{"word":"talons","word_nosc":"talons","lemma":"talon","pos":"NOM"} ,
		{"word":"talures","word_nosc":"talures","lemma":"talure","pos":"NOM"} ,
		{"word":"talus","word_nosc":"talus","lemma":"talus","pos":"NOM"} ,
		{"word":"tam-tam","word_nosc":"tam-tam","lemma":"tam-tam","pos":"NOM"} ,
		{"word":"tam-tams","word_nosc":"tam-tams","lemma":"tam-tam","pos":"NOM"} ,
		{"word":"tamagotchi","word_nosc":"tamagotchi","lemma":"tamagotchi","pos":"NOM"} ,
		{"word":"tamanoir","word_nosc":"tamanoir","lemma":"tamanoir","pos":"NOM"} ,
		{"word":"tamarin","word_nosc":"tamarin","lemma":"tamarin","pos":"NOM"} ,
		{"word":"tamarinier","word_nosc":"tamarinier","lemma":"tamarinier","pos":"NOM"} ,
		{"word":"tamariniers","word_nosc":"tamariniers","lemma":"tamarinier","pos":"NOM"} ,
		{"word":"tamaris","word_nosc":"tamaris","lemma":"tamaris","pos":"NOM"} ,
		{"word":"tambouille","word_nosc":"tambouille","lemma":"tambouille","pos":"NOM"} ,
		{"word":"tambouilles","word_nosc":"tambouilles","lemma":"tambouille","pos":"NOM"} ,
		{"word":"tambour","word_nosc":"tambour","lemma":"tambour","pos":"NOM"} ,
		{"word":"tambour-major","word_nosc":"tambour-major","lemma":"tambour-major","pos":"NOM"} ,
		{"word":"tambourin","word_nosc":"tambourin","lemma":"tambourin","pos":"NOM"} ,
		{"word":"tambourinade","word_nosc":"tambourinade","lemma":"tambourinade","pos":"NOM"} ,
		{"word":"tambourinaire","word_nosc":"tambourinaire","lemma":"tambourinaire","pos":"NOM"} ,
		{"word":"tambourinaires","word_nosc":"tambourinaires","lemma":"tambourinaire","pos":"NOM"} ,
		{"word":"tambourinement","word_nosc":"tambourinement","lemma":"tambourinement","pos":"NOM"} ,
		{"word":"tambourineur","word_nosc":"tambourineur","lemma":"tambourineur","pos":"NOM"} ,
		{"word":"tambourins","word_nosc":"tambourins","lemma":"tambourin","pos":"NOM"} ,
		{"word":"tambours","word_nosc":"tambours","lemma":"tambour","pos":"NOM"} ,
		{"word":"tamia","word_nosc":"tamia","lemma":"tamia","pos":"NOM"} ,
		{"word":"tamis","word_nosc":"tamis","lemma":"tamis","pos":"NOM"} ,
		{"word":"tamisage","word_nosc":"tamisage","lemma":"tamisage","pos":"NOM"} ,
		{"word":"tamise","word_nosc":"tamise","lemma":"tamise","pos":"NOM"} ,
		{"word":"tamiseur","word_nosc":"tamiseur","lemma":"tamiseur","pos":"NOM"} ,
		{"word":"tamoul","word_nosc":"tamoul","lemma":"tamoul","pos":"NOM"} ,
		{"word":"tamouré","word_nosc":"tamoure","lemma":"tamouré","pos":"NOM"} ,
		{"word":"tampax","word_nosc":"tampax","lemma":"tampax","pos":"NOM"} ,
		{"word":"tampon","word_nosc":"tampon","lemma":"tampon","pos":"NOM"} ,
		{"word":"tampon-buvard","word_nosc":"tampon-buvard","lemma":"tampon-buvard","pos":"NOM"} ,
		{"word":"tamponnade","word_nosc":"tamponnade","lemma":"tamponnade","pos":"NOM"} ,
		{"word":"tamponnage","word_nosc":"tamponnage","lemma":"tamponnage","pos":"NOM"} ,
		{"word":"tamponnages","word_nosc":"tamponnages","lemma":"tamponnage","pos":"NOM"} ,
		{"word":"tamponnement","word_nosc":"tamponnement","lemma":"tamponnement","pos":"NOM"} ,
		{"word":"tamponnoir","word_nosc":"tamponnoir","lemma":"tamponnoir","pos":"NOM"} ,
		{"word":"tamponnoirs","word_nosc":"tamponnoirs","lemma":"tamponnoir","pos":"NOM"} ,
		{"word":"tampons","word_nosc":"tampons","lemma":"tampon","pos":"NOM"} ,
		{"word":"tamtam","word_nosc":"tamtam","lemma":"tamtam","pos":"NOM"} ,
		{"word":"tan","word_nosc":"tan","lemma":"tan","pos":"NOM"} ,
		{"word":"tan-sad","word_nosc":"tan-sad","lemma":"tan-sad","pos":"NOM"} ,
		{"word":"tanaisie","word_nosc":"tanaisie","lemma":"tanaisie","pos":"NOM"} ,
		{"word":"tanche","word_nosc":"tanche","lemma":"tanche","pos":"NOM"} ,
		{"word":"tanches","word_nosc":"tanches","lemma":"tanche","pos":"NOM"} ,
		{"word":"tandem","word_nosc":"tandem","lemma":"tandem","pos":"NOM"} ,
		{"word":"tandems","word_nosc":"tandems","lemma":"tandem","pos":"NOM"} ,
		{"word":"tandoori","word_nosc":"tandoori","lemma":"tandoori","pos":"NOM"} ,
		{"word":"tangage","word_nosc":"tangage","lemma":"tangage","pos":"NOM"} ,
		{"word":"tangara","word_nosc":"tangara","lemma":"tangara","pos":"NOM"} ,
		{"word":"tangaras","word_nosc":"tangaras","lemma":"tangara","pos":"NOM"} ,
		{"word":"tangence","word_nosc":"tangence","lemma":"tangence","pos":"NOM"} ,
		{"word":"tangente","word_nosc":"tangente","lemma":"tangente","pos":"NOM"} ,
		{"word":"tangentes","word_nosc":"tangentes","lemma":"tangente","pos":"NOM"} ,
		{"word":"tangerine","word_nosc":"tangerine","lemma":"tangerine","pos":"NOM"} ,
		{"word":"tangibilité","word_nosc":"tangibilite","lemma":"tangibilité","pos":"NOM"} ,
		{"word":"tango","word_nosc":"tango","lemma":"tango","pos":"NOM"} ,
		{"word":"tangon","word_nosc":"tangon","lemma":"tangon","pos":"NOM"} ,
		{"word":"tangos","word_nosc":"tangos","lemma":"tango","pos":"NOM"} ,
		{"word":"tangue","word_nosc":"tangue","lemma":"tangue","pos":"NOM"} ,
		{"word":"tanin","word_nosc":"tanin","lemma":"tanin","pos":"NOM"} ,
		{"word":"tanière","word_nosc":"taniere","lemma":"tanière","pos":"NOM"} ,
		{"word":"tanières","word_nosc":"tanieres","lemma":"tanière","pos":"NOM"} ,
		{"word":"tank","word_nosc":"tank","lemma":"tank","pos":"NOM"} ,
		{"word":"tanka","word_nosc":"tanka","lemma":"tanka","pos":"NOM"} ,
		{"word":"tanker","word_nosc":"tanker","lemma":"tanker","pos":"NOM"} ,
		{"word":"tankers","word_nosc":"tankers","lemma":"tanker","pos":"NOM"} ,
		{"word":"tankiste","word_nosc":"tankiste","lemma":"tankiste","pos":"NOM"} ,
		{"word":"tankistes","word_nosc":"tankistes","lemma":"tankiste","pos":"NOM"} ,
		{"word":"tanks","word_nosc":"tanks","lemma":"tank","pos":"NOM"} ,
		{"word":"tannage","word_nosc":"tannage","lemma":"tannage","pos":"NOM"} ,
		{"word":"tanne","word_nosc":"tanne","lemma":"tanne","pos":"NOM"} ,
		{"word":"tannerie","word_nosc":"tannerie","lemma":"tannerie","pos":"NOM"} ,
		{"word":"tannes","word_nosc":"tannes","lemma":"tanne","pos":"NOM"} ,
		{"word":"tanneur","word_nosc":"tanneur","lemma":"tanneur","pos":"NOM"} ,
		{"word":"tanneurs","word_nosc":"tanneurs","lemma":"tanneur","pos":"NOM"} ,
		{"word":"tannin","word_nosc":"tannin","lemma":"tannin","pos":"NOM"} ,
		{"word":"tannée","word_nosc":"tannee","lemma":"tannée","pos":"NOM"} ,
		{"word":"tannées","word_nosc":"tannees","lemma":"tannée","pos":"NOM"} ,
		{"word":"tanrec","word_nosc":"tanrec","lemma":"tanrec","pos":"NOM"} ,
		{"word":"tans","word_nosc":"tans","lemma":"tan","pos":"NOM"} ,
		{"word":"tansad","word_nosc":"tansad","lemma":"tansad","pos":"NOM"} ,
		{"word":"tante","word_nosc":"tante","lemma":"tante","pos":"NOM"} ,
		{"word":"tantes","word_nosc":"tantes","lemma":"tante","pos":"NOM"} ,
		{"word":"tantine","word_nosc":"tantine","lemma":"tantine","pos":"NOM"} ,
		{"word":"tantines","word_nosc":"tantines","lemma":"tantine","pos":"NOM"} ,
		{"word":"tantinet","word_nosc":"tantinet","lemma":"tantinet","pos":"NOM"} ,
		{"word":"tantièmes","word_nosc":"tantiemes","lemma":"tantième","pos":"NOM"} ,
		{"word":"tantouse","word_nosc":"tantouse","lemma":"tantouse","pos":"NOM"} ,
		{"word":"tantouses","word_nosc":"tantouses","lemma":"tantouse","pos":"NOM"} ,
		{"word":"tantouze","word_nosc":"tantouze","lemma":"tantouze","pos":"NOM"} ,
		{"word":"tantouzes","word_nosc":"tantouzes","lemma":"tantouze","pos":"NOM"} ,
		{"word":"tantra","word_nosc":"tantra","lemma":"tantra","pos":"NOM"} ,
		{"word":"tantrisme","word_nosc":"tantrisme","lemma":"tantrisme","pos":"NOM"} ,
		{"word":"tantôt","word_nosc":"tantot","lemma":"tantôt","pos":"NOM"} ,
		{"word":"tao","word_nosc":"tao","lemma":"tao","pos":"NOM"} ,
		{"word":"taon","word_nosc":"taon","lemma":"taon","pos":"NOM"} ,
		{"word":"taons","word_nosc":"taons","lemma":"taon","pos":"NOM"} ,
		{"word":"taoïsme","word_nosc":"taoisme","lemma":"taoïsme","pos":"NOM"} ,
		{"word":"taoïste","word_nosc":"taoiste","lemma":"taoïste","pos":"NOM"} ,
		{"word":"taoïstes","word_nosc":"taoistes","lemma":"taoïste","pos":"NOM"} ,
		{"word":"tapage","word_nosc":"tapage","lemma":"tapage","pos":"NOM"} ,
		{"word":"tapages","word_nosc":"tapages","lemma":"tapage","pos":"NOM"} ,
		{"word":"tapageur","word_nosc":"tapageur","lemma":"tapageur","pos":"NOM"} ,
		{"word":"tapageurs","word_nosc":"tapageurs","lemma":"tapageur","pos":"NOM"} ,
		{"word":"tapas","word_nosc":"tapas","lemma":"tapa","pos":"NOM"} ,
		{"word":"tape","word_nosc":"tape","lemma":"tap","pos":"NOM"} ,
		{"word":"tape","word_nosc":"tape","lemma":"tape","pos":"NOM"} ,
		{"word":"tape-cul","word_nosc":"tape-cul","lemma":"tape-cul","pos":"NOM"} ,
		{"word":"tape-à-l'oeil","word_nosc":"tape-a-l'oeil","lemma":"tape-à-l'oeil","pos":"NOM"} ,
		{"word":"tapecul","word_nosc":"tapecul","lemma":"tapecul","pos":"NOM"} ,
		{"word":"tapement","word_nosc":"tapement","lemma":"tapement","pos":"NOM"} ,
		{"word":"tapements","word_nosc":"tapements","lemma":"tapement","pos":"NOM"} ,
		{"word":"tapenade","word_nosc":"tapenade","lemma":"tapenade","pos":"NOM"} ,
		{"word":"tapes","word_nosc":"tapes","lemma":"tap","pos":"NOM"} ,
		{"word":"tapes","word_nosc":"tapes","lemma":"tape","pos":"NOM"} ,
		{"word":"tapette","word_nosc":"tapette","lemma":"tapette","pos":"NOM"} ,
		{"word":"tapettes","word_nosc":"tapettes","lemma":"tapette","pos":"NOM"} ,
		{"word":"tapeur","word_nosc":"tapeur","lemma":"tapeur","pos":"NOM"} ,
		{"word":"tapeurs","word_nosc":"tapeurs","lemma":"tapeur","pos":"NOM"} ,
		{"word":"tapeuses","word_nosc":"tapeuses","lemma":"tapeur","pos":"NOM"} ,
		{"word":"tapin","word_nosc":"tapin","lemma":"tapin","pos":"NOM"} ,
		{"word":"tapinage","word_nosc":"tapinage","lemma":"tapinage","pos":"NOM"} ,
		{"word":"tapineuse","word_nosc":"tapineuse","lemma":"tapineur","pos":"NOM"} ,
		{"word":"tapineuses","word_nosc":"tapineuses","lemma":"tapineur","pos":"NOM"} ,
		{"word":"tapins","word_nosc":"tapins","lemma":"tapin","pos":"NOM"} ,
		{"word":"tapioca","word_nosc":"tapioca","lemma":"tapioca","pos":"NOM"} ,
		{"word":"tapir","word_nosc":"tapir","lemma":"tapir","pos":"NOM"} ,
		{"word":"tapirs","word_nosc":"tapirs","lemma":"tapir","pos":"NOM"} ,
		{"word":"tapis","word_nosc":"tapis","lemma":"tapis","pos":"NOM"} ,
		{"word":"tapis-brosse","word_nosc":"tapis-brosse","lemma":"tapis-brosse","pos":"NOM"} ,
		{"word":"tapisserie","word_nosc":"tapisserie","lemma":"tapisserie","pos":"NOM"} ,
		{"word":"tapisseries","word_nosc":"tapisseries","lemma":"tapisserie","pos":"NOM"} ,
		{"word":"tapissier","word_nosc":"tapissier","lemma":"tapissier","pos":"NOM"} ,
		{"word":"tapissiers","word_nosc":"tapissiers","lemma":"tapissier","pos":"NOM"} ,
		{"word":"tapissière","word_nosc":"tapissiere","lemma":"tapissier","pos":"NOM"} ,
		{"word":"tapotement","word_nosc":"tapotement","lemma":"tapotement","pos":"NOM"} ,
		{"word":"tapotements","word_nosc":"tapotements","lemma":"tapotement","pos":"NOM"} ,
		{"word":"tapotis","word_nosc":"tapotis","lemma":"tapotis","pos":"NOM"} ,
		{"word":"tapée","word_nosc":"tapee","lemma":"tapée","pos":"NOM"} ,
		{"word":"tapées","word_nosc":"tapees","lemma":"tapée","pos":"NOM"} ,
		{"word":"taquet","word_nosc":"taquet","lemma":"taquet","pos":"NOM"} ,
		{"word":"taquets","word_nosc":"taquets","lemma":"taquet","pos":"NOM"} ,
		{"word":"taquinerie","word_nosc":"taquinerie","lemma":"taquinerie","pos":"NOM"} ,
		{"word":"taquineries","word_nosc":"taquineries","lemma":"taquinerie","pos":"NOM"} ,
		{"word":"taquoir","word_nosc":"taquoir","lemma":"taquoir","pos":"NOM"} ,
		{"word":"tarabiscot","word_nosc":"tarabiscot","lemma":"tarabiscot","pos":"NOM"} ,
		{"word":"tarabiscotages","word_nosc":"tarabiscotages","lemma":"tarabiscotage","pos":"NOM"} ,
		{"word":"tarama","word_nosc":"tarama","lemma":"tarama","pos":"NOM"} ,
		{"word":"tarare","word_nosc":"tarare","lemma":"tarare","pos":"NOM"} ,
		{"word":"taraud","word_nosc":"taraud","lemma":"taraud","pos":"NOM"} ,
		{"word":"tarauds","word_nosc":"tarauds","lemma":"taraud","pos":"NOM"} ,
		{"word":"tarbais","word_nosc":"tarbais","lemma":"tarbais","pos":"NOM"} ,
		{"word":"tarbouche","word_nosc":"tarbouche","lemma":"tarbouche","pos":"NOM"} ,
		{"word":"tarbouif","word_nosc":"tarbouif","lemma":"tarbouif","pos":"NOM"} ,
		{"word":"tard","word_nosc":"tard","lemma":"tard","pos":"NOM"} ,
		{"word":"tarderie","word_nosc":"tarderie","lemma":"tarderie","pos":"NOM"} ,
		{"word":"tarderies","word_nosc":"tarderies","lemma":"tarderie","pos":"NOM"} ,
		{"word":"tardigrade","word_nosc":"tardigrade","lemma":"tardigrade","pos":"NOM"} ,
		{"word":"tardillon","word_nosc":"tardillon","lemma":"tardillon","pos":"NOM"} ,
		{"word":"tare","word_nosc":"tare","lemma":"tare","pos":"NOM"} ,
		{"word":"tarentelle","word_nosc":"tarentelle","lemma":"tarentelle","pos":"NOM"} ,
		{"word":"tarentelles","word_nosc":"tarentelles","lemma":"tarentelle","pos":"NOM"} ,
		{"word":"tarentule","word_nosc":"tarentule","lemma":"tarentule","pos":"NOM"} ,
		{"word":"tarentules","word_nosc":"tarentules","lemma":"tarentule","pos":"NOM"} ,
		{"word":"tares","word_nosc":"tares","lemma":"tare","pos":"NOM"} ,
		{"word":"taret","word_nosc":"taret","lemma":"taret","pos":"NOM"} ,
		{"word":"tarets","word_nosc":"tarets","lemma":"taret","pos":"NOM"} ,
		{"word":"targe","word_nosc":"targe","lemma":"targe","pos":"NOM"} ,
		{"word":"targette","word_nosc":"targette","lemma":"targette","pos":"NOM"} ,
		{"word":"targettes","word_nosc":"targettes","lemma":"targette","pos":"NOM"} ,
		{"word":"targui","word_nosc":"targui","lemma":"targui","pos":"NOM"} ,
		{"word":"taride","word_nosc":"taride","lemma":"taride","pos":"NOM"} ,
		{"word":"tarif","word_nosc":"tarif","lemma":"tarif","pos":"NOM"} ,
		{"word":"tarification","word_nosc":"tarification","lemma":"tarification","pos":"NOM"} ,
		{"word":"tarifications","word_nosc":"tarifications","lemma":"tarification","pos":"NOM"} ,
		{"word":"tarifs","word_nosc":"tarifs","lemma":"tarif","pos":"NOM"} ,
		{"word":"tarin","word_nosc":"tarin","lemma":"tarin","pos":"NOM"} ,
		{"word":"tarissement","word_nosc":"tarissement","lemma":"tarissement","pos":"NOM"} ,
		{"word":"tarière","word_nosc":"tariere","lemma":"tarière","pos":"NOM"} ,
		{"word":"tarières","word_nosc":"tarieres","lemma":"tarière","pos":"NOM"} ,
		{"word":"tarlatane","word_nosc":"tarlatane","lemma":"tarlatane","pos":"NOM"} ,
		{"word":"tarmac","word_nosc":"tarmac","lemma":"tarmac","pos":"NOM"} ,
		{"word":"taro","word_nosc":"taro","lemma":"taro","pos":"NOM"} ,
		{"word":"tarot","word_nosc":"tarot","lemma":"tarot","pos":"NOM"} ,
		{"word":"tarots","word_nosc":"tarots","lemma":"tarot","pos":"NOM"} ,
		{"word":"tarpan","word_nosc":"tarpan","lemma":"tarpan","pos":"NOM"} ,
		{"word":"tarpans","word_nosc":"tarpans","lemma":"tarpan","pos":"NOM"} ,
		{"word":"tarpon","word_nosc":"tarpon","lemma":"tarpon","pos":"NOM"} ,
		{"word":"tarpé","word_nosc":"tarpe","lemma":"tarpé","pos":"NOM"} ,
		{"word":"tarpéienne","word_nosc":"tarpeienne","lemma":"tarpéienne","pos":"NOM"} ,
		{"word":"tarse","word_nosc":"tarse","lemma":"tarse","pos":"NOM"} ,
		{"word":"tarses","word_nosc":"tarses","lemma":"tarse","pos":"NOM"} ,
		{"word":"tarsier","word_nosc":"tarsier","lemma":"tarsier","pos":"NOM"} ,
		{"word":"tartan","word_nosc":"tartan","lemma":"tartan","pos":"NOM"} ,
		{"word":"tartane","word_nosc":"tartane","lemma":"tartan","pos":"NOM"} ,
		{"word":"tartare","word_nosc":"tartare","lemma":"tartare","pos":"NOM"} ,
		{"word":"tartares","word_nosc":"tartares","lemma":"tartare","pos":"NOM"} ,
		{"word":"tartarin","word_nosc":"tartarin","lemma":"tartarin","pos":"NOM"} ,
		{"word":"tartarinades","word_nosc":"tartarinades","lemma":"tartarinade","pos":"NOM"} ,
		{"word":"tartarins","word_nosc":"tartarins","lemma":"tartarin","pos":"NOM"} ,
		{"word":"tarte","word_nosc":"tarte","lemma":"tarte","pos":"NOM"} ,
		{"word":"tartelette","word_nosc":"tartelette","lemma":"tartelette","pos":"NOM"} ,
		{"word":"tartelettes","word_nosc":"tartelettes","lemma":"tartelette","pos":"NOM"} ,
		{"word":"tartempion","word_nosc":"tartempion","lemma":"tartempion","pos":"NOM"} ,
		{"word":"tartes","word_nosc":"tartes","lemma":"tarte","pos":"NOM"} ,
		{"word":"tartine","word_nosc":"tartine","lemma":"tartine","pos":"NOM"} ,
		{"word":"tartines","word_nosc":"tartines","lemma":"tartine","pos":"NOM"} ,
		{"word":"tartiss","word_nosc":"tartiss","lemma":"tartiss","pos":"NOM"} ,
		{"word":"tartouille","word_nosc":"tartouille","lemma":"tartouille","pos":"NOM"} ,
		{"word":"tartouilles","word_nosc":"tartouilles","lemma":"tartouille","pos":"NOM"} ,
		{"word":"tartre","word_nosc":"tartre","lemma":"tartre","pos":"NOM"} ,
		{"word":"tartufe","word_nosc":"tartufe","lemma":"tartufe","pos":"NOM"} ,
		{"word":"tartuferie","word_nosc":"tartuferie","lemma":"tartuferie","pos":"NOM"} ,
		{"word":"tartuferies","word_nosc":"tartuferies","lemma":"tartuferie","pos":"NOM"} ,
		{"word":"tartufferie","word_nosc":"tartufferie","lemma":"tartufferie","pos":"NOM"} ,
		{"word":"tartuffes","word_nosc":"tartuffes","lemma":"tartuffe","pos":"NOM"} ,
		{"word":"tarzan","word_nosc":"tarzan","lemma":"tarzan","pos":"NOM"} ,
		{"word":"tarzans","word_nosc":"tarzans","lemma":"tarzan","pos":"NOM"} ,
		{"word":"taré","word_nosc":"tare","lemma":"taré","pos":"NOM"} ,
		{"word":"tarés","word_nosc":"tares","lemma":"taré","pos":"NOM"} ,
		{"word":"tas","word_nosc":"tas","lemma":"tas","pos":"NOM"} ,
		{"word":"tasmanien","word_nosc":"tasmanien","lemma":"tasmanien","pos":"NOM"} ,
		{"word":"tasse","word_nosc":"tasse","lemma":"tasse","pos":"NOM"} ,
		{"word":"tasseau","word_nosc":"tasseau","lemma":"tasseau","pos":"NOM"} ,
		{"word":"tassement","word_nosc":"tassement","lemma":"tassement","pos":"NOM"} ,
		{"word":"tassements","word_nosc":"tassements","lemma":"tassement","pos":"NOM"} ,
		{"word":"tasses","word_nosc":"tasses","lemma":"tasse","pos":"NOM"} ,
		{"word":"tassili","word_nosc":"tassili","lemma":"tassili","pos":"NOM"} ,
		{"word":"taste-vin","word_nosc":"taste-vin","lemma":"taste-vin","pos":"NOM"} ,
		{"word":"tata","word_nosc":"tata","lemma":"tata","pos":"NOM"} ,
		{"word":"tatami","word_nosc":"tatami","lemma":"tatami","pos":"NOM"} ,
		{"word":"tatamis","word_nosc":"tatamis","lemma":"tatami","pos":"NOM"} ,
		{"word":"tatane","word_nosc":"tatane","lemma":"tatane","pos":"NOM"} ,
		{"word":"tatanes","word_nosc":"tatanes","lemma":"tatane","pos":"NOM"} ,
		{"word":"tatar","word_nosc":"tatar","lemma":"tatar","pos":"NOM"} ,
		{"word":"tatas","word_nosc":"tatas","lemma":"tata","pos":"NOM"} ,
		{"word":"tatillon","word_nosc":"tatillon","lemma":"tatillon","pos":"NOM"} ,
		{"word":"tatillonne","word_nosc":"tatillonne","lemma":"tatillon","pos":"NOM"} ,
		{"word":"tatillons","word_nosc":"tatillons","lemma":"tatillon","pos":"NOM"} ,
		{"word":"tatin","word_nosc":"tatin","lemma":"tatin","pos":"NOM"} ,
		{"word":"tatou","word_nosc":"tatou","lemma":"tatou","pos":"NOM"} ,
		{"word":"tatouage","word_nosc":"tatouage","lemma":"tatouage","pos":"NOM"} ,
		{"word":"tatouages","word_nosc":"tatouages","lemma":"tatouage","pos":"NOM"} ,
		{"word":"tatoueur","word_nosc":"tatoueur","lemma":"tatoueur","pos":"NOM"} ,
		{"word":"tatoueurs","word_nosc":"tatoueurs","lemma":"tatoueur","pos":"NOM"} ,
		{"word":"tatoueuse","word_nosc":"tatoueuse","lemma":"tatoueur","pos":"NOM"} ,
		{"word":"tatous","word_nosc":"tatous","lemma":"tatou","pos":"NOM"} ,
		{"word":"tau","word_nosc":"tau","lemma":"tau","pos":"NOM"} ,
		{"word":"taube","word_nosc":"taube","lemma":"taube","pos":"NOM"} ,
		{"word":"taudis","word_nosc":"taudis","lemma":"taudis","pos":"NOM"} ,
		{"word":"taulard","word_nosc":"taulard","lemma":"taulard","pos":"NOM"} ,
		{"word":"taularde","word_nosc":"taularde","lemma":"taulard","pos":"NOM"} ,
		{"word":"taulardes","word_nosc":"taulardes","lemma":"taulard","pos":"NOM"} ,
		{"word":"taulards","word_nosc":"taulards","lemma":"taulard","pos":"NOM"} ,
		{"word":"taule","word_nosc":"taule","lemma":"taule","pos":"NOM"} ,
		{"word":"taules","word_nosc":"taules","lemma":"taule","pos":"NOM"} ,
		{"word":"taulier","word_nosc":"taulier","lemma":"taulier","pos":"NOM"} ,
		{"word":"tauliers","word_nosc":"tauliers","lemma":"taulier","pos":"NOM"} ,
		{"word":"taulière","word_nosc":"tauliere","lemma":"taulier","pos":"NOM"} ,
		{"word":"taulières","word_nosc":"taulieres","lemma":"taulier","pos":"NOM"} ,
		{"word":"taupe","word_nosc":"taupe","lemma":"taupe","pos":"NOM"} ,
		{"word":"taupes","word_nosc":"taupes","lemma":"taupe","pos":"NOM"} ,
		{"word":"taupicide","word_nosc":"taupicide","lemma":"taupicide","pos":"NOM"} ,
		{"word":"taupin","word_nosc":"taupin","lemma":"taupin","pos":"NOM"} ,
		{"word":"taupinière","word_nosc":"taupiniere","lemma":"taupinière","pos":"NOM"} ,
		{"word":"taupinières","word_nosc":"taupinieres","lemma":"taupinière","pos":"NOM"} ,
		{"word":"taupinées","word_nosc":"taupinees","lemma":"taupinée","pos":"NOM"} ,
		{"word":"taure","word_nosc":"taure","lemma":"taure","pos":"NOM"} ,
		{"word":"taureau","word_nosc":"taureau","lemma":"taureau","pos":"NOM"} ,
		{"word":"taureaux","word_nosc":"taureaux","lemma":"taureau","pos":"NOM"} ,
		{"word":"taurillon","word_nosc":"taurillon","lemma":"taurillon","pos":"NOM"} ,
		{"word":"taurillons","word_nosc":"taurillons","lemma":"taurillon","pos":"NOM"} ,
		{"word":"taurobole","word_nosc":"taurobole","lemma":"taurobole","pos":"NOM"} ,
		{"word":"tauromachie","word_nosc":"tauromachie","lemma":"tauromachie","pos":"NOM"} ,
		{"word":"tautologie","word_nosc":"tautologie","lemma":"tautologie","pos":"NOM"} ,
		{"word":"taux","word_nosc":"taux","lemma":"taux","pos":"NOM"} ,
		{"word":"tavel","word_nosc":"tavel","lemma":"tavel","pos":"NOM"} ,
		{"word":"tavelures","word_nosc":"tavelures","lemma":"tavelure","pos":"NOM"} ,
		{"word":"taverne","word_nosc":"taverne","lemma":"taverne","pos":"NOM"} ,
		{"word":"tavernes","word_nosc":"tavernes","lemma":"taverne","pos":"NOM"} ,
		{"word":"tavernier","word_nosc":"tavernier","lemma":"tavernier","pos":"NOM"} ,
		{"word":"taverniers","word_nosc":"taverniers","lemma":"tavernier","pos":"NOM"} ,
		{"word":"taxation","word_nosc":"taxation","lemma":"taxation","pos":"NOM"} ,
		{"word":"taxaudier","word_nosc":"taxaudier","lemma":"taxaudier","pos":"NOM"} ,
		{"word":"taxe","word_nosc":"taxe","lemma":"taxe","pos":"NOM"} ,
		{"word":"taxes","word_nosc":"taxes","lemma":"taxe","pos":"NOM"} ,
		{"word":"taxi","word_nosc":"taxi","lemma":"taxi","pos":"NOM"} ,
		{"word":"taxi-auto","word_nosc":"taxi-auto","lemma":"taxi-auto","pos":"NOM"} ,
		{"word":"taxi-brousse","word_nosc":"taxi-brousse","lemma":"taxi-brousse","pos":"NOM"} ,
		{"word":"taxi-girl","word_nosc":"taxi-girl","lemma":"taxi-girl","pos":"NOM"} ,
		{"word":"taxi-girls","word_nosc":"taxi-girls","lemma":"taxi-girl","pos":"NOM"} ,
		{"word":"taxidermie","word_nosc":"taxidermie","lemma":"taxidermie","pos":"NOM"} ,
		{"word":"taxidermiste","word_nosc":"taxidermiste","lemma":"taxidermiste","pos":"NOM"} ,
		{"word":"taxidermistes","word_nosc":"taxidermistes","lemma":"taxidermiste","pos":"NOM"} ,
		{"word":"taximan","word_nosc":"taximan","lemma":"taximan","pos":"NOM"} ,
		{"word":"taximen","word_nosc":"taximen","lemma":"taximan","pos":"NOM"} ,
		{"word":"taximètre","word_nosc":"taximetre","lemma":"taximètre","pos":"NOM"} ,
		{"word":"taxinomie","word_nosc":"taxinomie","lemma":"taxinomie","pos":"NOM"} ,
		{"word":"taxinomiste","word_nosc":"taxinomiste","lemma":"taxinomiste","pos":"NOM"} ,
		{"word":"taxiphone","word_nosc":"taxiphone","lemma":"taxiphone","pos":"NOM"} ,
		{"word":"taxiphones","word_nosc":"taxiphones","lemma":"taxiphone","pos":"NOM"} ,
		{"word":"taxis","word_nosc":"taxis","lemma":"taxi","pos":"NOM"} ,
		{"word":"taxonomie","word_nosc":"taxonomie","lemma":"taxonomie","pos":"NOM"} ,
		{"word":"taylorisme","word_nosc":"taylorisme","lemma":"taylorisme","pos":"NOM"} ,
		{"word":"taï chi","word_nosc":"tai chi","lemma":"taï chi","pos":"NOM"} ,
		{"word":"taïga","word_nosc":"taiga","lemma":"taïga","pos":"NOM"} ,
		{"word":"taïgas","word_nosc":"taigas","lemma":"taïga","pos":"NOM"} ,
		{"word":"tchadiens","word_nosc":"tchadiens","lemma":"tchadien","pos":"NOM"} ,
		{"word":"tchador","word_nosc":"tchador","lemma":"tchador","pos":"NOM"} ,
		{"word":"tchatche","word_nosc":"tchatche","lemma":"tchatche","pos":"NOM"} ,
		{"word":"tcherkesse","word_nosc":"tcherkesse","lemma":"tcherkesse","pos":"NOM"} ,
		{"word":"tchernoziom","word_nosc":"tchernoziom","lemma":"tchernoziom","pos":"NOM"} ,
		{"word":"tchetnik","word_nosc":"tchetnik","lemma":"tchetnik","pos":"NOM"} ,
		{"word":"tchetniks","word_nosc":"tchetniks","lemma":"tchetnik","pos":"NOM"} ,
		{"word":"tchèque","word_nosc":"tcheque","lemma":"tchèque","pos":"NOM"} ,
		{"word":"tchèques","word_nosc":"tcheques","lemma":"tchèque","pos":"NOM"} ,
		{"word":"tchécoslovaque","word_nosc":"tchecoslovaque","lemma":"tchécoslovaque","pos":"NOM"} ,
		{"word":"tchécoslovaques","word_nosc":"tchecoslovaques","lemma":"tchécoslovaque","pos":"NOM"} ,
		{"word":"tchékiste","word_nosc":"tchekiste","lemma":"tchékiste","pos":"NOM"} ,
		{"word":"tchétchène","word_nosc":"tchetchene","lemma":"tchétchène","pos":"NOM"} ,
		{"word":"tchétchènes","word_nosc":"tchetchenes","lemma":"tchétchène","pos":"NOM"} ,
		{"word":"te deum","word_nosc":"te deum","lemma":"te deum","pos":"NOM"} ,
		{"word":"tea-room","word_nosc":"tea-room","lemma":"tea-room","pos":"NOM"} ,
		{"word":"team","word_nosc":"team","lemma":"team","pos":"NOM"} ,
		{"word":"teams","word_nosc":"teams","lemma":"team","pos":"NOM"} ,
		{"word":"teaser","word_nosc":"teaser","lemma":"teaser","pos":"NOM"} ,
		{"word":"tec","word_nosc":"tec","lemma":"tec","pos":"NOM"} ,
		{"word":"technicien","word_nosc":"technicien","lemma":"technicien","pos":"NOM"} ,
		{"word":"technicienne","word_nosc":"technicienne","lemma":"technicien","pos":"NOM"} ,
		{"word":"techniciens","word_nosc":"techniciens","lemma":"technicien","pos":"NOM"} ,
		{"word":"technicité","word_nosc":"technicite","lemma":"technicité","pos":"NOM"} ,
		{"word":"technico-commerciaux","word_nosc":"technico-commerciaux","lemma":"technico-commerciaux","pos":"NOM"} ,
		{"word":"technicolor","word_nosc":"technicolor","lemma":"technicolor","pos":"NOM"} ,
		{"word":"technique","word_nosc":"technique","lemma":"technique","pos":"NOM"} ,
		{"word":"techniques","word_nosc":"techniques","lemma":"technique","pos":"NOM"} ,
		{"word":"techno","word_nosc":"techno","lemma":"techno","pos":"NOM"} ,
		{"word":"technocrate","word_nosc":"technocrate","lemma":"technocrate","pos":"NOM"} ,
		{"word":"technocrates","word_nosc":"technocrates","lemma":"technocrate","pos":"NOM"} ,
		{"word":"technocratisme","word_nosc":"technocratisme","lemma":"technocratisme","pos":"NOM"} ,
		{"word":"technologie","word_nosc":"technologie","lemma":"technologie","pos":"NOM"} ,
		{"word":"technologies","word_nosc":"technologies","lemma":"technologie","pos":"NOM"} ,
		{"word":"technétium","word_nosc":"technetium","lemma":"technétium","pos":"NOM"} ,
		{"word":"teck","word_nosc":"teck","lemma":"teck","pos":"NOM"} ,
		{"word":"teckel","word_nosc":"teckel","lemma":"teckel","pos":"NOM"} ,
		{"word":"teckels","word_nosc":"teckels","lemma":"teckel","pos":"NOM"} ,
		{"word":"tectonique","word_nosc":"tectonique","lemma":"tectonique","pos":"NOM"} ,
		{"word":"tee","word_nosc":"tee","lemma":"tee","pos":"NOM"} ,
		{"word":"tee-shirt","word_nosc":"tee-shirt","lemma":"tee-shirt","pos":"NOM"} ,
		{"word":"tee-shirts","word_nosc":"tee-shirts","lemma":"tee-shirt","pos":"NOM"} ,
		{"word":"teen-agers","word_nosc":"teen-agers","lemma":"teen-ager","pos":"NOM"} ,
		{"word":"teenager","word_nosc":"teenager","lemma":"teenager","pos":"NOM"} ,
		{"word":"teenagers","word_nosc":"teenagers","lemma":"teenager","pos":"NOM"} ,
		{"word":"tees","word_nosc":"tees","lemma":"tee","pos":"NOM"} ,
		{"word":"tefillin","word_nosc":"tefillin","lemma":"tefillin","pos":"NOM"} ,
		{"word":"teigne","word_nosc":"teigne","lemma":"teigne","pos":"NOM"} ,
		{"word":"teignes","word_nosc":"teignes","lemma":"teigne","pos":"NOM"} ,
		{"word":"teint","word_nosc":"teint","lemma":"teint","pos":"NOM"} ,
		{"word":"teinte","word_nosc":"teinte","lemma":"teinte","pos":"NOM"} ,
		{"word":"teintes","word_nosc":"teintes","lemma":"teinte","pos":"NOM"} ,
		{"word":"teints","word_nosc":"teints","lemma":"teint","pos":"NOM"} ,
		{"word":"teinture","word_nosc":"teinture","lemma":"teinture","pos":"NOM"} ,
		{"word":"teinturerie","word_nosc":"teinturerie","lemma":"teinturerie","pos":"NOM"} ,
		{"word":"teintureries","word_nosc":"teintureries","lemma":"teinturerie","pos":"NOM"} ,
		{"word":"teintures","word_nosc":"teintures","lemma":"teinture","pos":"NOM"} ,
		{"word":"teinturier","word_nosc":"teinturier","lemma":"teinturier","pos":"NOM"} ,
		{"word":"teinturiers","word_nosc":"teinturiers","lemma":"teinturier","pos":"NOM"} ,
		{"word":"teinturière","word_nosc":"teinturiere","lemma":"teinturier","pos":"NOM"} ,
		{"word":"tek","word_nosc":"tek","lemma":"tek","pos":"NOM"} ,
		{"word":"tell","word_nosc":"tell","lemma":"tell","pos":"NOM"} ,
		{"word":"tellure","word_nosc":"tellure","lemma":"tellure","pos":"NOM"} ,
		{"word":"telson","word_nosc":"telson","lemma":"telson","pos":"NOM"} ,
		{"word":"tem","word_nosc":"tem","lemma":"tem","pos":"NOM"} ,
		{"word":"tempe","word_nosc":"tempe","lemma":"tempe","pos":"NOM"} ,
		{"word":"tempera","word_nosc":"tempera","lemma":"tempera","pos":"NOM"} ,
		{"word":"tempes","word_nosc":"tempes","lemma":"tempe","pos":"NOM"} ,
		{"word":"tempi","word_nosc":"tempi","lemma":"tempo","pos":"NOM"} ,
		{"word":"temple","word_nosc":"temple","lemma":"temple","pos":"NOM"} ,
		{"word":"temples","word_nosc":"temples","lemma":"temple","pos":"NOM"} ,
		{"word":"templier","word_nosc":"templier","lemma":"templier","pos":"NOM"} ,
		{"word":"templiers","word_nosc":"templiers","lemma":"templier","pos":"NOM"} ,
		{"word":"tempo","word_nosc":"tempo","lemma":"tempo","pos":"NOM"} ,
		{"word":"temporalité","word_nosc":"temporalite","lemma":"temporalité","pos":"NOM"} ,
		{"word":"temporalités","word_nosc":"temporalites","lemma":"temporalité","pos":"NOM"} ,
		{"word":"temporaux","word_nosc":"temporaux","lemma":"temporal","pos":"NOM"} ,
		{"word":"temporisation","word_nosc":"temporisation","lemma":"temporisation","pos":"NOM"} ,
		{"word":"tempos","word_nosc":"tempos","lemma":"tempo","pos":"NOM"} ,
		{"word":"temps","word_nosc":"temps","lemma":"temps","pos":"NOM"} ,
		{"word":"tempura","word_nosc":"tempura","lemma":"tempura","pos":"NOM"} ,
		{"word":"tempuras","word_nosc":"tempuras","lemma":"tempura","pos":"NOM"} ,
		{"word":"tempérament","word_nosc":"temperament","lemma":"tempérament","pos":"NOM"} ,
		{"word":"tempéraments","word_nosc":"temperaments","lemma":"tempérament","pos":"NOM"} ,
		{"word":"tempérance","word_nosc":"temperance","lemma":"tempérance","pos":"NOM"} ,
		{"word":"température","word_nosc":"temperature","lemma":"température","pos":"NOM"} ,
		{"word":"températures","word_nosc":"temperatures","lemma":"température","pos":"NOM"} ,
		{"word":"tempête","word_nosc":"tempete","lemma":"tempête","pos":"NOM"} ,
		{"word":"tempêtes","word_nosc":"tempetes","lemma":"tempête","pos":"NOM"} ,
		{"word":"tenaille","word_nosc":"tenaille","lemma":"tenaille","pos":"NOM"} ,
		{"word":"tenailles","word_nosc":"tenailles","lemma":"tenaille","pos":"NOM"} ,
		{"word":"tenancier","word_nosc":"tenancier","lemma":"tenancier","pos":"NOM"} ,
		{"word":"tenanciers","word_nosc":"tenanciers","lemma":"tenancier","pos":"NOM"} ,
		{"word":"tenancière","word_nosc":"tenanciere","lemma":"tenancier","pos":"NOM"} ,
		{"word":"tenancières","word_nosc":"tenancieres","lemma":"tenancier","pos":"NOM"} ,
		{"word":"tenant","word_nosc":"tenant","lemma":"tenant","pos":"NOM"} ,
		{"word":"tenants","word_nosc":"tenants","lemma":"tenant","pos":"NOM"} ,
		{"word":"tendance","word_nosc":"tendance","lemma":"tendance","pos":"NOM"} ,
		{"word":"tendances","word_nosc":"tendances","lemma":"tendance","pos":"NOM"} ,
		{"word":"tendelet","word_nosc":"tendelet","lemma":"tendelet","pos":"NOM"} ,
		{"word":"tender","word_nosc":"tender","lemma":"tender","pos":"NOM"} ,
		{"word":"tenders","word_nosc":"tenders","lemma":"tender","pos":"NOM"} ,
		{"word":"tendeur","word_nosc":"tendeur","lemma":"tendeur","pos":"NOM"} ,
		{"word":"tendeurs","word_nosc":"tendeurs","lemma":"tendeur","pos":"NOM"} ,
		{"word":"tendinite","word_nosc":"tendinite","lemma":"tendinite","pos":"NOM"} ,
		{"word":"tendon","word_nosc":"tendon","lemma":"tendon","pos":"NOM"} ,
		{"word":"tendons","word_nosc":"tendons","lemma":"tendon","pos":"NOM"} ,
		{"word":"tendre","word_nosc":"tendre","lemma":"tendre","pos":"NOM"} ,
		{"word":"tendres","word_nosc":"tendres","lemma":"tendre","pos":"NOM"} ,
		{"word":"tendresse","word_nosc":"tendresse","lemma":"tendresse","pos":"NOM"} ,
		{"word":"tendresses","word_nosc":"tendresses","lemma":"tendresse","pos":"NOM"} ,
		{"word":"tendreté","word_nosc":"tendrete","lemma":"tendreté","pos":"NOM"} ,
		{"word":"tendron","word_nosc":"tendron","lemma":"tendron","pos":"NOM"} ,
		{"word":"tendrons","word_nosc":"tendrons","lemma":"tendron","pos":"NOM"} ,
		{"word":"teneur","word_nosc":"teneur","lemma":"teneur","pos":"NOM"} ,
		{"word":"tennis","word_nosc":"tennis","lemma":"tennis","pos":"NOM"} ,
		{"word":"tennis-ballon","word_nosc":"tennis-ballon","lemma":"tennis-ballon","pos":"NOM"} ,
		{"word":"tennis-club","word_nosc":"tennis-club","lemma":"tennis-club","pos":"NOM"} ,
		{"word":"tennisman","word_nosc":"tennisman","lemma":"tennisman","pos":"NOM"} ,
		{"word":"tennismen","word_nosc":"tennismen","lemma":"tennisman","pos":"NOM"} ,
		{"word":"tenon","word_nosc":"tenon","lemma":"tenon","pos":"NOM"} ,
		{"word":"tenons","word_nosc":"tenons","lemma":"tenon","pos":"NOM"} ,
		{"word":"tenseur","word_nosc":"tenseur","lemma":"tenseur","pos":"NOM"} ,
		{"word":"tensiomètre","word_nosc":"tensiometre","lemma":"tensiomètre","pos":"NOM"} ,
		{"word":"tension","word_nosc":"tension","lemma":"tension","pos":"NOM"} ,
		{"word":"tensions","word_nosc":"tensions","lemma":"tension","pos":"NOM"} ,
		{"word":"tentacule","word_nosc":"tentacule","lemma":"tentacule","pos":"NOM"} ,
		{"word":"tentacules","word_nosc":"tentacules","lemma":"tentacule","pos":"NOM"} ,
		{"word":"tentateur","word_nosc":"tentateur","lemma":"tentateur","pos":"NOM"} ,
		{"word":"tentateurs","word_nosc":"tentateurs","lemma":"tentateur","pos":"NOM"} ,
		{"word":"tentation","word_nosc":"tentation","lemma":"tentation","pos":"NOM"} ,
		{"word":"tentations","word_nosc":"tentations","lemma":"tentation","pos":"NOM"} ,
		{"word":"tentative","word_nosc":"tentative","lemma":"tentative","pos":"NOM"} ,
		{"word":"tentatives","word_nosc":"tentatives","lemma":"tentative","pos":"NOM"} ,
		{"word":"tentatrice","word_nosc":"tentatrice","lemma":"tentateur","pos":"NOM"} ,
		{"word":"tentatrices","word_nosc":"tentatrices","lemma":"tentateur","pos":"NOM"} ,
		{"word":"tente","word_nosc":"tente","lemma":"tente","pos":"NOM"} ,
		{"word":"tentes","word_nosc":"tentes","lemma":"tente","pos":"NOM"} ,
		{"word":"tentiaire","word_nosc":"tentiaire","lemma":"tentiaire","pos":"NOM"} ,
		{"word":"tenture","word_nosc":"tenture","lemma":"tenture","pos":"NOM"} ,
		{"word":"tentures","word_nosc":"tentures","lemma":"tenture","pos":"NOM"} ,
		{"word":"tenue","word_nosc":"tenue","lemma":"tenue","pos":"NOM"} ,
		{"word":"tenues","word_nosc":"tenues","lemma":"tenue","pos":"NOM"} ,
		{"word":"tenure","word_nosc":"tenure","lemma":"tenure","pos":"NOM"} ,
		{"word":"tepidarium","word_nosc":"tepidarium","lemma":"tepidarium","pos":"NOM"} ,
		{"word":"tequila","word_nosc":"tequila","lemma":"tequila","pos":"NOM"} ,
		{"word":"tequilas","word_nosc":"tequilas","lemma":"tequila","pos":"NOM"} ,
		{"word":"tercets","word_nosc":"tercets","lemma":"tercet","pos":"NOM"} ,
		{"word":"tercio","word_nosc":"tercio","lemma":"tercio","pos":"NOM"} ,
		{"word":"tergal","word_nosc":"tergal","lemma":"tergal","pos":"NOM"} ,
		{"word":"tergiversation","word_nosc":"tergiversation","lemma":"tergiversation","pos":"NOM"} ,
		{"word":"tergiversations","word_nosc":"tergiversations","lemma":"tergiversation","pos":"NOM"} ,
		{"word":"terme","word_nosc":"terme","lemma":"terme","pos":"NOM"} ,
		{"word":"termes","word_nosc":"termes","lemma":"terme","pos":"NOM"} ,
		{"word":"terminaison","word_nosc":"terminaison","lemma":"terminaison","pos":"NOM"} ,
		{"word":"terminaisons","word_nosc":"terminaisons","lemma":"terminaison","pos":"NOM"} ,
		{"word":"terminal","word_nosc":"terminal","lemma":"terminal","pos":"NOM"} ,
		{"word":"terminale","word_nosc":"terminale","lemma":"terminal","pos":"NOM"} ,
		{"word":"terminales","word_nosc":"terminales","lemma":"terminal","pos":"NOM"} ,
		{"word":"terminateur","word_nosc":"terminateur","lemma":"terminateur","pos":"NOM"} ,
		{"word":"terminaux","word_nosc":"terminaux","lemma":"terminal","pos":"NOM"} ,
		{"word":"terminologie","word_nosc":"terminologie","lemma":"terminologie","pos":"NOM"} ,
		{"word":"terminus","word_nosc":"terminus","lemma":"terminus","pos":"NOM"} ,
		{"word":"termite","word_nosc":"termite","lemma":"termite","pos":"NOM"} ,
		{"word":"termites","word_nosc":"termites","lemma":"termite","pos":"NOM"} ,
		{"word":"termitière","word_nosc":"termitiere","lemma":"termitière","pos":"NOM"} ,
		{"word":"termitières","word_nosc":"termitieres","lemma":"termitière","pos":"NOM"} ,
		{"word":"terra incognita","word_nosc":"terra incognita","lemma":"terra incognita","pos":"NOM"} ,
		{"word":"terrage","word_nosc":"terrage","lemma":"terrage","pos":"NOM"} ,
		{"word":"terrain","word_nosc":"terrain","lemma":"terrain","pos":"NOM"} ,
		{"word":"terrains","word_nosc":"terrains","lemma":"terrain","pos":"NOM"} ,
		{"word":"terramycine","word_nosc":"terramycine","lemma":"terramycine","pos":"NOM"} ,
		{"word":"terraplane","word_nosc":"terraplane","lemma":"terraplane","pos":"NOM"} ,
		{"word":"terrarium","word_nosc":"terrarium","lemma":"terrarium","pos":"NOM"} ,
		{"word":"terrasse","word_nosc":"terrasse","lemma":"terrasse","pos":"NOM"} ,
		{"word":"terrassement","word_nosc":"terrassement","lemma":"terrassement","pos":"NOM"} ,
		{"word":"terrassements","word_nosc":"terrassements","lemma":"terrassement","pos":"NOM"} ,
		{"word":"terrasses","word_nosc":"terrasses","lemma":"terrasse","pos":"NOM"} ,
		{"word":"terrassier","word_nosc":"terrassier","lemma":"terrassier","pos":"NOM"} ,
		{"word":"terrassiers","word_nosc":"terrassiers","lemma":"terrassier","pos":"NOM"} ,
		{"word":"terrasson","word_nosc":"terrasson","lemma":"terrasson","pos":"NOM"} ,
		{"word":"terre","word_nosc":"terre","lemma":"terre","pos":"NOM"} ,
		{"word":"terre-neuvas","word_nosc":"terre-neuvas","lemma":"terre-neuvas","pos":"NOM"} ,
		{"word":"terre-neuve","word_nosc":"terre-neuve","lemma":"terre-neuve","pos":"NOM"} ,
		{"word":"terre-neuvien","word_nosc":"terre-neuvien","lemma":"terre-neuvien","pos":"NOM"} ,
		{"word":"terre-plein","word_nosc":"terre-plein","lemma":"terre-plein","pos":"NOM"} ,
		{"word":"terre-pleins","word_nosc":"terre-pleins","lemma":"terre-plein","pos":"NOM"} ,
		{"word":"terreau","word_nosc":"terreau","lemma":"terreau","pos":"NOM"} ,
		{"word":"terreaux","word_nosc":"terreaux","lemma":"terreau","pos":"NOM"} ,
		{"word":"terres","word_nosc":"terres","lemma":"terre","pos":"NOM"} ,
		{"word":"terreur","word_nosc":"terreur","lemma":"terreur","pos":"NOM"} ,
		{"word":"terreurs","word_nosc":"terreurs","lemma":"terreur","pos":"NOM"} ,
		{"word":"terri","word_nosc":"terri","lemma":"terri","pos":"NOM"} ,
		{"word":"terrien","word_nosc":"terrien","lemma":"terrien","pos":"NOM"} ,
		{"word":"terrienne","word_nosc":"terrienne","lemma":"terrien","pos":"NOM"} ,
		{"word":"terriennes","word_nosc":"terriennes","lemma":"terrien","pos":"NOM"} ,
		{"word":"terriens","word_nosc":"terriens","lemma":"terrien","pos":"NOM"} ,
		{"word":"terrier","word_nosc":"terrier","lemma":"terrier","pos":"NOM"} ,
		{"word":"terriers","word_nosc":"terriers","lemma":"terrier","pos":"NOM"} ,
		{"word":"terril","word_nosc":"terril","lemma":"terril","pos":"NOM"} ,
		{"word":"terrils","word_nosc":"terrils","lemma":"terril","pos":"NOM"} ,
		{"word":"terrine","word_nosc":"terrine","lemma":"terrine","pos":"NOM"} ,
		{"word":"terrines","word_nosc":"terrines","lemma":"terrine","pos":"NOM"} ,
		{"word":"territoire","word_nosc":"territoire","lemma":"territoire","pos":"NOM"} ,
		{"word":"territoires","word_nosc":"territoires","lemma":"territoire","pos":"NOM"} ,
		{"word":"territorialité","word_nosc":"territorialite","lemma":"territorialité","pos":"NOM"} ,
		{"word":"terroir","word_nosc":"terroir","lemma":"terroir","pos":"NOM"} ,
		{"word":"terrorisme","word_nosc":"terrorisme","lemma":"terrorisme","pos":"NOM"} ,
		{"word":"terrorismes","word_nosc":"terrorismes","lemma":"terrorisme","pos":"NOM"} ,
		{"word":"terroriste","word_nosc":"terroriste","lemma":"terroriste","pos":"NOM"} ,
		{"word":"terroristes","word_nosc":"terroristes","lemma":"terroriste","pos":"NOM"} ,
		{"word":"tertiaire","word_nosc":"tertiaire","lemma":"tertiaire","pos":"NOM"} ,
		{"word":"tertre","word_nosc":"tertre","lemma":"tertre","pos":"NOM"} ,
		{"word":"tertres","word_nosc":"tertres","lemma":"tertre","pos":"NOM"} ,
		{"word":"tesla","word_nosc":"tesla","lemma":"tesla","pos":"NOM"} ,
		{"word":"tessiture","word_nosc":"tessiture","lemma":"tessiture","pos":"NOM"} ,
		{"word":"tesson","word_nosc":"tesson","lemma":"tesson","pos":"NOM"} ,
		{"word":"tessons","word_nosc":"tessons","lemma":"tesson","pos":"NOM"} ,
		{"word":"test","word_nosc":"test","lemma":"test","pos":"NOM"} ,
		{"word":"test-match","word_nosc":"test-match","lemma":"test-match","pos":"NOM"} ,
		{"word":"testament","word_nosc":"testament","lemma":"testament","pos":"NOM"} ,
		{"word":"testaments","word_nosc":"testaments","lemma":"testament","pos":"NOM"} ,
		{"word":"teste","word_nosc":"teste","lemma":"teste","pos":"NOM"} ,
		{"word":"testeur","word_nosc":"testeur","lemma":"testeur","pos":"NOM"} ,
		{"word":"testeuse","word_nosc":"testeuse","lemma":"testeur","pos":"NOM"} ,
		{"word":"testicule","word_nosc":"testicule","lemma":"testicule","pos":"NOM"} ,
		{"word":"testicules","word_nosc":"testicules","lemma":"testicule","pos":"NOM"} ,
		{"word":"testons","word_nosc":"testons","lemma":"teston","pos":"NOM"} ,
		{"word":"testostérone","word_nosc":"testosterone","lemma":"testostérone","pos":"NOM"} ,
		{"word":"testostérones","word_nosc":"testosterones","lemma":"testostérone","pos":"NOM"} ,
		{"word":"tests","word_nosc":"tests","lemma":"test","pos":"NOM"} ,
		{"word":"testu","word_nosc":"testu","lemma":"testu","pos":"NOM"} ,
		{"word":"tette","word_nosc":"tette","lemma":"tette","pos":"NOM"} ,
		{"word":"teuf-teuf","word_nosc":"teuf-teuf","lemma":"teuf-teuf","pos":"NOM"} ,
		{"word":"teuton","word_nosc":"teuton","lemma":"teuton","pos":"NOM"} ,
		{"word":"teutonne","word_nosc":"teutonne","lemma":"teutonne","pos":"NOM"} ,
		{"word":"teutonnes","word_nosc":"teutonnes","lemma":"teutonne","pos":"NOM"} ,
		{"word":"teutons","word_nosc":"teutons","lemma":"teuton","pos":"NOM"} ,
		{"word":"tex","word_nosc":"tex","lemma":"tex","pos":"NOM"} ,
		{"word":"texan","word_nosc":"texan","lemma":"texan","pos":"NOM"} ,
		{"word":"texane","word_nosc":"texane","lemma":"texan","pos":"NOM"} ,
		{"word":"texanes","word_nosc":"texanes","lemma":"texan","pos":"NOM"} ,
		{"word":"texans","word_nosc":"texans","lemma":"texan","pos":"NOM"} ,
		{"word":"texte","word_nosc":"texte","lemma":"texte","pos":"NOM"} ,
		{"word":"textes","word_nosc":"textes","lemma":"texte","pos":"NOM"} ,
		{"word":"textile","word_nosc":"textile","lemma":"textile","pos":"NOM"} ,
		{"word":"textiles","word_nosc":"textiles","lemma":"textile","pos":"NOM"} ,
		{"word":"textologie","word_nosc":"textologie","lemma":"textologie","pos":"NOM"} ,
		{"word":"texture","word_nosc":"texture","lemma":"texture","pos":"NOM"} ,
		{"word":"textures","word_nosc":"textures","lemma":"texture","pos":"NOM"} ,
		{"word":"thalamus","word_nosc":"thalamus","lemma":"thalamus","pos":"NOM"} ,
		{"word":"thalasso","word_nosc":"thalasso","lemma":"thalasso","pos":"NOM"} ,
		{"word":"thalassothérapeutes","word_nosc":"thalassotherapeutes","lemma":"thalassothérapeute","pos":"NOM"} ,
		{"word":"thalassothérapie","word_nosc":"thalassotherapie","lemma":"thalassothérapie","pos":"NOM"} ,
		{"word":"thalassémie","word_nosc":"thalassemie","lemma":"thalassémie","pos":"NOM"} ,
		{"word":"thaler","word_nosc":"thaler","lemma":"thaler","pos":"NOM"} ,
		{"word":"thalers","word_nosc":"thalers","lemma":"thaler","pos":"NOM"} ,
		{"word":"thalidomide","word_nosc":"thalidomide","lemma":"thalidomide","pos":"NOM"} ,
		{"word":"thallium","word_nosc":"thallium","lemma":"thallium","pos":"NOM"} ,
		{"word":"thalweg","word_nosc":"thalweg","lemma":"thalweg","pos":"NOM"} ,
		{"word":"thanatologie","word_nosc":"thanatologie","lemma":"thanatologie","pos":"NOM"} ,
		{"word":"thanatopraxie","word_nosc":"thanatopraxie","lemma":"thanatopraxie","pos":"NOM"} ,
		{"word":"thanatos","word_nosc":"thanatos","lemma":"thanatos","pos":"NOM"} ,
		{"word":"thanksgiving","word_nosc":"thanksgiving","lemma":"thanksgiving","pos":"NOM"} ,
		{"word":"thaumaturge","word_nosc":"thaumaturge","lemma":"thaumaturge","pos":"NOM"} ,
		{"word":"thaumaturges","word_nosc":"thaumaturges","lemma":"thaumaturge","pos":"NOM"} ,
		{"word":"thaumaturgie","word_nosc":"thaumaturgie","lemma":"thaumaturgie","pos":"NOM"} ,
		{"word":"thaï","word_nosc":"thai","lemma":"thaï","pos":"NOM"} ,
		{"word":"thaïlandais","word_nosc":"thailandais","lemma":"thaïlandais","pos":"NOM"} ,
		{"word":"thermes","word_nosc":"thermes","lemma":"thermes","pos":"NOM"} ,
		{"word":"thermidor","word_nosc":"thermidor","lemma":"thermidor","pos":"NOM"} ,
		{"word":"thermique","word_nosc":"thermique","lemma":"thermique","pos":"NOM"} ,
		{"word":"thermiques","word_nosc":"thermiques","lemma":"thermique","pos":"NOM"} ,
		{"word":"thermite","word_nosc":"thermite","lemma":"thermite","pos":"NOM"} ,
		{"word":"thermo","word_nosc":"thermo","lemma":"thermo","pos":"NOM"} ,
		{"word":"thermodynamique","word_nosc":"thermodynamique","lemma":"thermodynamique","pos":"NOM"} ,
		{"word":"thermoformage","word_nosc":"thermoformage","lemma":"thermoformage","pos":"NOM"} ,
		{"word":"thermographe","word_nosc":"thermographe","lemma":"thermographe","pos":"NOM"} ,
		{"word":"thermographie","word_nosc":"thermographie","lemma":"thermographie","pos":"NOM"} ,
		{"word":"thermoluminescence","word_nosc":"thermoluminescence","lemma":"thermoluminescence","pos":"NOM"} ,
		{"word":"thermomètre","word_nosc":"thermometre","lemma":"thermomètre","pos":"NOM"} ,
		{"word":"thermomètres","word_nosc":"thermometres","lemma":"thermomètre","pos":"NOM"} ,
		{"word":"thermostat","word_nosc":"thermostat","lemma":"thermostat","pos":"NOM"} ,
		{"word":"thermostats","word_nosc":"thermostats","lemma":"thermostat","pos":"NOM"} ,
		{"word":"thesaurus","word_nosc":"thesaurus","lemma":"thesaurus","pos":"NOM"} ,
		{"word":"thiamine","word_nosc":"thiamine","lemma":"thiamine","pos":"NOM"} ,
		{"word":"thibaude","word_nosc":"thibaude","lemma":"thibaude","pos":"NOM"} ,
		{"word":"thomas","word_nosc":"thomas","lemma":"thomas","pos":"NOM"} ,
		{"word":"thomisme","word_nosc":"thomisme","lemma":"thomisme","pos":"NOM"} ,
		{"word":"thon","word_nosc":"thon","lemma":"thon","pos":"NOM"} ,
		{"word":"thonier","word_nosc":"thonier","lemma":"thonier","pos":"NOM"} ,
		{"word":"thoniers","word_nosc":"thoniers","lemma":"thonier","pos":"NOM"} ,
		{"word":"thons","word_nosc":"thons","lemma":"thon","pos":"NOM"} ,
		{"word":"thoracentèse","word_nosc":"thoracentese","lemma":"thoracentèse","pos":"NOM"} ,
		{"word":"thoracotomie","word_nosc":"thoracotomie","lemma":"thoracotomie","pos":"NOM"} ,
		{"word":"thorax","word_nosc":"thorax","lemma":"thorax","pos":"NOM"} ,
		{"word":"thorine","word_nosc":"thorine","lemma":"thorine","pos":"NOM"} ,
		{"word":"thoron","word_nosc":"thoron","lemma":"thoron","pos":"NOM"} ,
		{"word":"thrill","word_nosc":"thrill","lemma":"thrill","pos":"NOM"} ,
		{"word":"thriller","word_nosc":"thriller","lemma":"thriller","pos":"NOM"} ,
		{"word":"thrillers","word_nosc":"thrillers","lemma":"thriller","pos":"NOM"} ,
		{"word":"thrombolyse","word_nosc":"thrombolyse","lemma":"thrombolyse","pos":"NOM"} ,
		{"word":"thrombose","word_nosc":"thrombose","lemma":"thrombose","pos":"NOM"} ,
		{"word":"thrène","word_nosc":"threne","lemma":"thrène","pos":"NOM"} ,
		{"word":"thug","word_nosc":"thug","lemma":"thug","pos":"NOM"} ,
		{"word":"thugs","word_nosc":"thugs","lemma":"thug","pos":"NOM"} ,
		{"word":"thune","word_nosc":"thune","lemma":"thune","pos":"NOM"} ,
		{"word":"thunes","word_nosc":"thunes","lemma":"thune","pos":"NOM"} ,
		{"word":"thuriféraire","word_nosc":"thuriferaire","lemma":"thuriféraire","pos":"NOM"} ,
		{"word":"thuriféraires","word_nosc":"thuriferaires","lemma":"thuriféraire","pos":"NOM"} ,
		{"word":"thuya","word_nosc":"thuya","lemma":"thuya","pos":"NOM"} ,
		{"word":"thuyas","word_nosc":"thuyas","lemma":"thuya","pos":"NOM"} ,
		{"word":"thym","word_nosc":"thym","lemma":"thym","pos":"NOM"} ,
		{"word":"thymectomie","word_nosc":"thymectomie","lemma":"thymectomie","pos":"NOM"} ,
		{"word":"thymine","word_nosc":"thymine","lemma":"thymine","pos":"NOM"} ,
		{"word":"thymus","word_nosc":"thymus","lemma":"thymus","pos":"NOM"} ,
		{"word":"thyroxine","word_nosc":"thyroxine","lemma":"thyroxine","pos":"NOM"} ,
		{"word":"thyroïde","word_nosc":"thyroide","lemma":"thyroïde","pos":"NOM"} ,
		{"word":"thyroïdectomie","word_nosc":"thyroidectomie","lemma":"thyroïdectomie","pos":"NOM"} ,
		{"word":"thyrses","word_nosc":"thyrses","lemma":"thyrse","pos":"NOM"} ,
		{"word":"thyréotoxicose","word_nosc":"thyreotoxicose","lemma":"thyréotoxicose","pos":"NOM"} ,
		{"word":"thème","word_nosc":"theme","lemma":"thème","pos":"NOM"} ,
		{"word":"thèmes","word_nosc":"themes","lemma":"thème","pos":"NOM"} ,
		{"word":"thèse","word_nosc":"these","lemma":"thèse","pos":"NOM"} ,
		{"word":"thèses","word_nosc":"theses","lemma":"thèse","pos":"NOM"} ,
		{"word":"thé","word_nosc":"the","lemma":"thé","pos":"NOM"} ,
		{"word":"thébaïde","word_nosc":"thebaide","lemma":"thébaïde","pos":"NOM"} ,
		{"word":"thébaïdes","word_nosc":"thebaides","lemma":"thébaïde","pos":"NOM"} ,
		{"word":"thébaïne","word_nosc":"thebaine","lemma":"thébaïne","pos":"NOM"} ,
		{"word":"théier","word_nosc":"theier","lemma":"théier","pos":"NOM"} ,
		{"word":"théine","word_nosc":"theine","lemma":"théine","pos":"NOM"} ,
		{"word":"théière","word_nosc":"theiere","lemma":"théière","pos":"NOM"} ,
		{"word":"théières","word_nosc":"theieres","lemma":"théière","pos":"NOM"} ,
		{"word":"thématique","word_nosc":"thematique","lemma":"thématique","pos":"NOM"} ,
		{"word":"thématiques","word_nosc":"thematiques","lemma":"thématique","pos":"NOM"} ,
		{"word":"théo","word_nosc":"theo","lemma":"théo","pos":"NOM"} ,
		{"word":"théodolite","word_nosc":"theodolite","lemma":"théodolite","pos":"NOM"} ,
		{"word":"théologie","word_nosc":"theologie","lemma":"théologie","pos":"NOM"} ,
		{"word":"théologien","word_nosc":"theologien","lemma":"théologien","pos":"NOM"} ,
		{"word":"théologiennes","word_nosc":"theologiennes","lemma":"théologien","pos":"NOM"} ,
		{"word":"théologiens","word_nosc":"theologiens","lemma":"théologien","pos":"NOM"} ,
		{"word":"théologies","word_nosc":"theologies","lemma":"théologie","pos":"NOM"} ,
		{"word":"théophylline","word_nosc":"theophylline","lemma":"théophylline","pos":"NOM"} ,
		{"word":"théorbe","word_nosc":"theorbe","lemma":"théorbe","pos":"NOM"} ,
		{"word":"théorbes","word_nosc":"theorbes","lemma":"théorbe","pos":"NOM"} ,
		{"word":"théoricien","word_nosc":"theoricien","lemma":"théoricien","pos":"NOM"} ,
		{"word":"théoricienne","word_nosc":"theoricienne","lemma":"théoricien","pos":"NOM"} ,
		{"word":"théoriciens","word_nosc":"theoriciens","lemma":"théoricien","pos":"NOM"} ,
		{"word":"théorie","word_nosc":"theorie","lemma":"théorie","pos":"NOM"} ,
		{"word":"théories","word_nosc":"theories","lemma":"théorie","pos":"NOM"} ,
		{"word":"théorème","word_nosc":"theoreme","lemma":"théorème","pos":"NOM"} ,
		{"word":"théorèmes","word_nosc":"theoremes","lemma":"théorème","pos":"NOM"} ,
		{"word":"théosophes","word_nosc":"theosophes","lemma":"théosophe","pos":"NOM"} ,
		{"word":"théosophie","word_nosc":"theosophie","lemma":"théosophie","pos":"NOM"} ,
		{"word":"thérapeute","word_nosc":"therapeute","lemma":"thérapeute","pos":"NOM"} ,
		{"word":"thérapeutes","word_nosc":"therapeutes","lemma":"thérapeute","pos":"NOM"} ,
		{"word":"thérapeutique","word_nosc":"therapeutique","lemma":"thérapeutique","pos":"NOM"} ,
		{"word":"thérapie","word_nosc":"therapie","lemma":"thérapie","pos":"NOM"} ,
		{"word":"thérapies","word_nosc":"therapies","lemma":"thérapie","pos":"NOM"} ,
		{"word":"thés","word_nosc":"thes","lemma":"thé","pos":"NOM"} ,
		{"word":"thésard","word_nosc":"thesard","lemma":"thésard","pos":"NOM"} ,
		{"word":"thésards","word_nosc":"thesards","lemma":"thésard","pos":"NOM"} ,
		{"word":"thésaurus","word_nosc":"thesaurus","lemma":"thésaurus","pos":"NOM"} ,
		{"word":"théâtralité","word_nosc":"theatralite","lemma":"théâtralité","pos":"NOM"} ,
		{"word":"théâtre","word_nosc":"theatre","lemma":"théâtre","pos":"NOM"} ,
		{"word":"théâtres","word_nosc":"theatres","lemma":"théâtre","pos":"NOM"} ,
		{"word":"théâtreuse","word_nosc":"theatreuse","lemma":"théâtreux","pos":"NOM"} ,
		{"word":"théâtreux","word_nosc":"theatreux","lemma":"théâtreux","pos":"NOM"} ,
		{"word":"thêta","word_nosc":"theta","lemma":"thêta","pos":"NOM"} ,
		{"word":"tiama","word_nosc":"tiama","lemma":"tiama","pos":"NOM"} ,
		{"word":"tian","word_nosc":"tian","lemma":"tian","pos":"NOM"} ,
		{"word":"tiare","word_nosc":"tiare","lemma":"tiare","pos":"NOM"} ,
		{"word":"tiares","word_nosc":"tiares","lemma":"tiare","pos":"NOM"} ,
		{"word":"tiaré","word_nosc":"tiare","lemma":"tiaré","pos":"NOM"} ,
		{"word":"tibia","word_nosc":"tibia","lemma":"tibia","pos":"NOM"} ,
		{"word":"tibias","word_nosc":"tibias","lemma":"tibia","pos":"NOM"} ,
		{"word":"tibétain","word_nosc":"tibetain","lemma":"tibétain","pos":"NOM"} ,
		{"word":"tibétains","word_nosc":"tibetains","lemma":"tibétain","pos":"NOM"} ,
		{"word":"tic","word_nosc":"tic","lemma":"tic","pos":"NOM"} ,
		{"word":"tic-tac","word_nosc":"tic-tac","lemma":"tic-tac","pos":"NOM"} ,
		{"word":"ticheurte","word_nosc":"ticheurte","lemma":"ticheurte","pos":"NOM"} ,
		{"word":"ticket","word_nosc":"ticket","lemma":"ticket","pos":"NOM"} ,
		{"word":"tickets","word_nosc":"tickets","lemma":"ticket","pos":"NOM"} ,
		{"word":"tics","word_nosc":"tics","lemma":"tic","pos":"NOM"} ,
		{"word":"ticsons","word_nosc":"ticsons","lemma":"ticson","pos":"NOM"} ,
		{"word":"tie-break","word_nosc":"tie-break","lemma":"tie-break","pos":"NOM"} ,
		{"word":"tiens","word_nosc":"tiens","lemma":"tien","pos":"NOM"} ,
		{"word":"tierce","word_nosc":"tierce","lemma":"tiers","pos":"NOM"} ,
		{"word":"tiercefeuille","word_nosc":"tiercefeuille","lemma":"tiercefeuille","pos":"NOM"} ,
		{"word":"tiercelet","word_nosc":"tiercelet","lemma":"tiercelet","pos":"NOM"} ,
		{"word":"tiercelets","word_nosc":"tiercelets","lemma":"tiercelet","pos":"NOM"} ,
		{"word":"tierces","word_nosc":"tierces","lemma":"tiers","pos":"NOM"} ,
		{"word":"tiercé","word_nosc":"tierce","lemma":"tiercé","pos":"NOM"} ,
		{"word":"tiercés","word_nosc":"tierces","lemma":"tiercé","pos":"NOM"} ,
		{"word":"tiers","word_nosc":"tiers","lemma":"tiers","pos":"NOM"} ,
		{"word":"tiers-monde","word_nosc":"tiers-monde","lemma":"tiers-monde","pos":"NOM"} ,
		{"word":"tiers-mondes","word_nosc":"tiers-mondes","lemma":"tiers-monde","pos":"NOM"} ,
		{"word":"tiers-mondisme","word_nosc":"tiers-mondisme","lemma":"tiers-mondisme","pos":"NOM"} ,
		{"word":"tiers-ordre","word_nosc":"tiers-ordre","lemma":"tiers-ordre","pos":"NOM"} ,
		{"word":"tiers-point","word_nosc":"tiers-point","lemma":"tiers-point","pos":"NOM"} ,
		{"word":"tiers-état","word_nosc":"tiers-etat","lemma":"tiers-état","pos":"NOM"} ,
		{"word":"tif","word_nosc":"tif","lemma":"tif","pos":"NOM"} ,
		{"word":"tifs","word_nosc":"tifs","lemma":"tif","pos":"NOM"} ,
		{"word":"tige","word_nosc":"tige","lemma":"tige","pos":"NOM"} ,
		{"word":"tigelles","word_nosc":"tigelles","lemma":"tigelle","pos":"NOM"} ,
		{"word":"tiges","word_nosc":"tiges","lemma":"tig","pos":"NOM"} ,
		{"word":"tiges","word_nosc":"tiges","lemma":"tige","pos":"NOM"} ,
		{"word":"tignasse","word_nosc":"tignasse","lemma":"tignasse","pos":"NOM"} ,
		{"word":"tignasses","word_nosc":"tignasses","lemma":"tignasse","pos":"NOM"} ,
		{"word":"tigre","word_nosc":"tigre","lemma":"tigre","pos":"NOM"} ,
		{"word":"tigres","word_nosc":"tigres","lemma":"tigre","pos":"NOM"} ,
		{"word":"tigresse","word_nosc":"tigresse","lemma":"tigre","pos":"NOM"} ,
		{"word":"tigresses","word_nosc":"tigresses","lemma":"tigre","pos":"NOM"} ,
		{"word":"tigrure","word_nosc":"tigrure","lemma":"tigrure","pos":"NOM"} ,
		{"word":"tiki","word_nosc":"tiki","lemma":"tiki","pos":"NOM"} ,
		{"word":"tilbury","word_nosc":"tilbury","lemma":"tilbury","pos":"NOM"} ,
		{"word":"tilburys","word_nosc":"tilburys","lemma":"tilbury","pos":"NOM"} ,
		{"word":"till","word_nosc":"till","lemma":"till","pos":"NOM"} ,
		{"word":"tillac","word_nosc":"tillac","lemma":"tillac","pos":"NOM"} ,
		{"word":"tillacs","word_nosc":"tillacs","lemma":"tillac","pos":"NOM"} ,
		{"word":"tille","word_nosc":"tille","lemma":"tille","pos":"NOM"} ,
		{"word":"tilles","word_nosc":"tilles","lemma":"tille","pos":"NOM"} ,
		{"word":"tilleul","word_nosc":"tilleul","lemma":"tilleul","pos":"NOM"} ,
		{"word":"tilleuls","word_nosc":"tilleuls","lemma":"tilleul","pos":"NOM"} ,
		{"word":"tilt","word_nosc":"tilt","lemma":"tilt","pos":"NOM"} ,
		{"word":"tilts","word_nosc":"tilts","lemma":"tilt","pos":"NOM"} ,
		{"word":"timbale","word_nosc":"timbale","lemma":"timbale","pos":"NOM"} ,
		{"word":"timbales","word_nosc":"timbales","lemma":"timbale","pos":"NOM"} ,
		{"word":"timbalier","word_nosc":"timbalier","lemma":"timbalier","pos":"NOM"} ,
		{"word":"timbaliers","word_nosc":"timbaliers","lemma":"timbalier","pos":"NOM"} ,
		{"word":"timbrage","word_nosc":"timbrage","lemma":"timbrage","pos":"NOM"} ,
		{"word":"timbre","word_nosc":"timbre","lemma":"timbre","pos":"NOM"} ,
		{"word":"timbre-poste","word_nosc":"timbre-poste","lemma":"timbre-poste","pos":"NOM"} ,
		{"word":"timbres","word_nosc":"timbres","lemma":"timbre","pos":"NOM"} ,
		{"word":"timbres-poste","word_nosc":"timbres-poste","lemma":"timbre-poste","pos":"NOM"} ,
		{"word":"time-sharing","word_nosc":"time-sharing","lemma":"time-sharing","pos":"NOM"} ,
		{"word":"timidité","word_nosc":"timidite","lemma":"timidité","pos":"NOM"} ,
		{"word":"timidités","word_nosc":"timidites","lemma":"timidité","pos":"NOM"} ,
		{"word":"timing","word_nosc":"timing","lemma":"timing","pos":"NOM"} ,
		{"word":"timings","word_nosc":"timings","lemma":"timing","pos":"NOM"} ,
		{"word":"timon","word_nosc":"timon","lemma":"timon","pos":"NOM"} ,
		{"word":"timonerie","word_nosc":"timonerie","lemma":"timonerie","pos":"NOM"} ,
		{"word":"timonier","word_nosc":"timonier","lemma":"timonier","pos":"NOM"} ,
		{"word":"timoniers","word_nosc":"timoniers","lemma":"timonier","pos":"NOM"} ,
		{"word":"timons","word_nosc":"timons","lemma":"timon","pos":"NOM"} ,
		{"word":"tin","word_nosc":"tin","lemma":"tin","pos":"NOM"} ,
		{"word":"tine","word_nosc":"tine","lemma":"tine","pos":"NOM"} ,
		{"word":"tinette","word_nosc":"tinette","lemma":"tinette","pos":"NOM"} ,
		{"word":"tinettes","word_nosc":"tinettes","lemma":"tinette","pos":"NOM"} ,
		{"word":"tins","word_nosc":"tins","lemma":"tin","pos":"NOM"} ,
		{"word":"tintamarre","word_nosc":"tintamarre","lemma":"tintamarre","pos":"NOM"} ,
		{"word":"tintamarres","word_nosc":"tintamarres","lemma":"tintamarre","pos":"NOM"} ,
		{"word":"tintement","word_nosc":"tintement","lemma":"tintement","pos":"NOM"} ,
		{"word":"tintements","word_nosc":"tintements","lemma":"tintement","pos":"NOM"} ,
		{"word":"tintinnabulement","word_nosc":"tintinnabulement","lemma":"tintinnabulement","pos":"NOM"} ,
		{"word":"tintinnabulements","word_nosc":"tintinnabulements","lemma":"tintinnabulement","pos":"NOM"} ,
		{"word":"tintouin","word_nosc":"tintouin","lemma":"tintouin","pos":"NOM"} ,
		{"word":"tintouins","word_nosc":"tintouins","lemma":"tintouin","pos":"NOM"} ,
		{"word":"tinée","word_nosc":"tinee","lemma":"tinée","pos":"NOM"} ,
		{"word":"tipi","word_nosc":"tipi","lemma":"tipi","pos":"NOM"} ,
		{"word":"tipis","word_nosc":"tipis","lemma":"tipi","pos":"NOM"} ,
		{"word":"tipule","word_nosc":"tipule","lemma":"tipule","pos":"NOM"} ,
		{"word":"tique","word_nosc":"tique","lemma":"tique","pos":"NOM"} ,
		{"word":"tiques","word_nosc":"tiques","lemma":"tique","pos":"NOM"} ,
		{"word":"tir","word_nosc":"tir","lemma":"tir","pos":"NOM"} ,
		{"word":"tirade","word_nosc":"tirade","lemma":"tirade","pos":"NOM"} ,
		{"word":"tirades","word_nosc":"tirades","lemma":"tirade","pos":"NOM"} ,
		{"word":"tirage","word_nosc":"tirage","lemma":"tirage","pos":"NOM"} ,
		{"word":"tirages","word_nosc":"tirages","lemma":"tirage","pos":"NOM"} ,
		{"word":"tiraillement","word_nosc":"tiraillement","lemma":"tiraillement","pos":"NOM"} ,
		{"word":"tiraillements","word_nosc":"tiraillements","lemma":"tiraillement","pos":"NOM"} ,
		{"word":"tirailleries","word_nosc":"tirailleries","lemma":"tiraillerie","pos":"NOM"} ,
		{"word":"tirailleur","word_nosc":"tirailleur","lemma":"tirailleur","pos":"NOM"} ,
		{"word":"tirailleurs","word_nosc":"tirailleurs","lemma":"tirailleur","pos":"NOM"} ,
		{"word":"tiramisu","word_nosc":"tiramisu","lemma":"tiramisu","pos":"NOM"} ,
		{"word":"tirant","word_nosc":"tirant","lemma":"tirant","pos":"NOM"} ,
		{"word":"tirants","word_nosc":"tirants","lemma":"tirant","pos":"NOM"} ,
		{"word":"tire","word_nosc":"tire","lemma":"tire","pos":"NOM"} ,
		{"word":"tire-au-cul","word_nosc":"tire-au-cul","lemma":"tire-au-cul","pos":"NOM"} ,
		{"word":"tire-au-flanc","word_nosc":"tire-au-flanc","lemma":"tire-au-flanc","pos":"NOM"} ,
		{"word":"tire-botte","word_nosc":"tire-botte","lemma":"tire-botte","pos":"NOM"} ,
		{"word":"tire-bouchon","word_nosc":"tire-bouchon","lemma":"tire-bouchon","pos":"NOM"} ,
		{"word":"tire-bouchons","word_nosc":"tire-bouchons","lemma":"tire-bouchon","pos":"NOM"} ,
		{"word":"tire-bouton","word_nosc":"tire-bouton","lemma":"tire-bouton","pos":"NOM"} ,
		{"word":"tire-d'aile","word_nosc":"tire-d'aile","lemma":"tire-d'aile","pos":"NOM"} ,
		{"word":"tire-fesse","word_nosc":"tire-fesse","lemma":"tire-fesse","pos":"NOM"} ,
		{"word":"tire-jus","word_nosc":"tire-jus","lemma":"tire-jus","pos":"NOM"} ,
		{"word":"tire-laine","word_nosc":"tire-laine","lemma":"tire-laine","pos":"NOM"} ,
		{"word":"tire-lait","word_nosc":"tire-lait","lemma":"tire-lait","pos":"NOM"} ,
		{"word":"tire-larigot","word_nosc":"tire-larigot","lemma":"tire-larigot","pos":"NOM"} ,
		{"word":"tire-ligne","word_nosc":"tire-ligne","lemma":"tire-ligne","pos":"NOM"} ,
		{"word":"tire-lignes","word_nosc":"tire-lignes","lemma":"tire-ligne","pos":"NOM"} ,
		{"word":"tire-lire","word_nosc":"tire-lire","lemma":"tire-lire","pos":"NOM"} ,
		{"word":"tirelire","word_nosc":"tirelire","lemma":"tirelire","pos":"NOM"} ,
		{"word":"tirelires","word_nosc":"tirelires","lemma":"tirelire","pos":"NOM"} ,
		{"word":"tires","word_nosc":"tires","lemma":"tire","pos":"NOM"} ,
		{"word":"tiret","word_nosc":"tiret","lemma":"tiret","pos":"NOM"} ,
		{"word":"tirets","word_nosc":"tirets","lemma":"tiret","pos":"NOM"} ,
		{"word":"tirette","word_nosc":"tirette","lemma":"tirette","pos":"NOM"} ,
		{"word":"tirettes","word_nosc":"tirettes","lemma":"tirette","pos":"NOM"} ,
		{"word":"tireur","word_nosc":"tireur","lemma":"tireur","pos":"NOM"} ,
		{"word":"tireurs","word_nosc":"tireurs","lemma":"tireur","pos":"NOM"} ,
		{"word":"tireuse","word_nosc":"tireuse","lemma":"tireur","pos":"NOM"} ,
		{"word":"tireuses","word_nosc":"tireuses","lemma":"tireur","pos":"NOM"} ,
		{"word":"tiroir","word_nosc":"tiroir","lemma":"tiroir","pos":"NOM"} ,
		{"word":"tiroir-caisse","word_nosc":"tiroir-caisse","lemma":"tiroir-caisse","pos":"NOM"} ,
		{"word":"tiroirs","word_nosc":"tiroirs","lemma":"tiroir","pos":"NOM"} ,
		{"word":"tiroirs-caisses","word_nosc":"tiroirs-caisses","lemma":"tiroir-caisse","pos":"NOM"} ,
		{"word":"tirs","word_nosc":"tirs","lemma":"tir","pos":"NOM"} ,
		{"word":"tirée","word_nosc":"tiree","lemma":"tirée","pos":"NOM"} ,
		{"word":"tirées","word_nosc":"tirees","lemma":"tirée","pos":"NOM"} ,
		{"word":"tisane","word_nosc":"tisane","lemma":"tisane","pos":"NOM"} ,
		{"word":"tisanes","word_nosc":"tisanes","lemma":"tisane","pos":"NOM"} ,
		{"word":"tisanière","word_nosc":"tisaniere","lemma":"tisanière","pos":"NOM"} ,
		{"word":"tison","word_nosc":"tison","lemma":"tison","pos":"NOM"} ,
		{"word":"tisonnier","word_nosc":"tisonnier","lemma":"tisonnier","pos":"NOM"} ,
		{"word":"tisonniers","word_nosc":"tisonniers","lemma":"tisonnier","pos":"NOM"} ,
		{"word":"tisons","word_nosc":"tisons","lemma":"tison","pos":"NOM"} ,
		{"word":"tissage","word_nosc":"tissage","lemma":"tissage","pos":"NOM"} ,
		{"word":"tissages","word_nosc":"tissages","lemma":"tissage","pos":"NOM"} ,
		{"word":"tisserand","word_nosc":"tisserand","lemma":"tisserand","pos":"NOM"} ,
		{"word":"tisserande","word_nosc":"tisserande","lemma":"tisserand","pos":"NOM"} ,
		{"word":"tisserands","word_nosc":"tisserands","lemma":"tisserand","pos":"NOM"} ,
		{"word":"tisseur","word_nosc":"tisseur","lemma":"tisseur","pos":"NOM"} ,
		{"word":"tisseuse","word_nosc":"tisseuse","lemma":"tisseur","pos":"NOM"} ,
		{"word":"tissu","word_nosc":"tissu","lemma":"tissu","pos":"NOM"} ,
		{"word":"tissu-éponge","word_nosc":"tissu-eponge","lemma":"tissu-éponge","pos":"NOM"} ,
		{"word":"tissus","word_nosc":"tissus","lemma":"tissu","pos":"NOM"} ,
		{"word":"tissuterie","word_nosc":"tissuterie","lemma":"tissuterie","pos":"NOM"} ,
		{"word":"tissutier","word_nosc":"tissutier","lemma":"tissutier","pos":"NOM"} ,
		{"word":"titan","word_nosc":"titan","lemma":"titan","pos":"NOM"} ,
		{"word":"titane","word_nosc":"titane","lemma":"titane","pos":"NOM"} ,
		{"word":"titans","word_nosc":"titans","lemma":"titan","pos":"NOM"} ,
		{"word":"titi","word_nosc":"titi","lemma":"titi","pos":"NOM"} ,
		{"word":"titillation","word_nosc":"titillation","lemma":"titillation","pos":"NOM"} ,
		{"word":"titillations","word_nosc":"titillations","lemma":"titillation","pos":"NOM"} ,
		{"word":"titillement","word_nosc":"titillement","lemma":"titillement","pos":"NOM"} ,
		{"word":"titillements","word_nosc":"titillements","lemma":"titillement","pos":"NOM"} ,
		{"word":"titis","word_nosc":"titis","lemma":"titi","pos":"NOM"} ,
		{"word":"titisme","word_nosc":"titisme","lemma":"titisme","pos":"NOM"} ,
		{"word":"titrage","word_nosc":"titrage","lemma":"titrage","pos":"NOM"} ,
		{"word":"titre","word_nosc":"titre","lemma":"titre","pos":"NOM"} ,
		{"word":"titres","word_nosc":"titres","lemma":"titre","pos":"NOM"} ,
		{"word":"titubation","word_nosc":"titubation","lemma":"titubation","pos":"NOM"} ,
		{"word":"titubations","word_nosc":"titubations","lemma":"titubation","pos":"NOM"} ,
		{"word":"titubement","word_nosc":"titubement","lemma":"titubement","pos":"NOM"} ,
		{"word":"titulaire","word_nosc":"titulaire","lemma":"titulaire","pos":"NOM"} ,
		{"word":"titulaires","word_nosc":"titulaires","lemma":"titulaire","pos":"NOM"} ,
		{"word":"titularisation","word_nosc":"titularisation","lemma":"titularisation","pos":"NOM"} ,
		{"word":"tiédeur","word_nosc":"tiedeur","lemma":"tiédeur","pos":"NOM"} ,
		{"word":"tiédeurs","word_nosc":"tiedeurs","lemma":"tiédeur","pos":"NOM"} ,
		{"word":"to","word_nosc":"to","lemma":"to","pos":"NOM"} ,
		{"word":"toast","word_nosc":"toast","lemma":"toast","pos":"NOM"} ,
		{"word":"toaster","word_nosc":"toaster","lemma":"toaster","pos":"NOM"} ,
		{"word":"toasteur","word_nosc":"toasteur","lemma":"toasteur","pos":"NOM"} ,
		{"word":"toasts","word_nosc":"toasts","lemma":"toast","pos":"NOM"} ,
		{"word":"toboggan","word_nosc":"toboggan","lemma":"toboggan","pos":"NOM"} ,
		{"word":"toboggans","word_nosc":"toboggans","lemma":"toboggan","pos":"NOM"} ,
		{"word":"toc","word_nosc":"toc","lemma":"toc","pos":"NOM"} ,
		{"word":"tocade","word_nosc":"tocade","lemma":"tocade","pos":"NOM"} ,
		{"word":"tocante","word_nosc":"tocante","lemma":"tocante","pos":"NOM"} ,
		{"word":"tocard","word_nosc":"tocard","lemma":"tocard","pos":"NOM"} ,
		{"word":"tocards","word_nosc":"tocards","lemma":"tocard","pos":"NOM"} ,
		{"word":"toccata","word_nosc":"toccata","lemma":"toccata","pos":"NOM"} ,
		{"word":"tocs","word_nosc":"tocs","lemma":"toc","pos":"NOM"} ,
		{"word":"tocsin","word_nosc":"tocsin","lemma":"tocsin","pos":"NOM"} ,
		{"word":"tocsins","word_nosc":"tocsins","lemma":"tocsin","pos":"NOM"} ,
		{"word":"toffee","word_nosc":"toffee","lemma":"toffee","pos":"NOM"} ,
		{"word":"tofu","word_nosc":"tofu","lemma":"tofu","pos":"NOM"} ,
		{"word":"toge","word_nosc":"toge","lemma":"toge","pos":"NOM"} ,
		{"word":"toges","word_nosc":"toges","lemma":"toge","pos":"NOM"} ,
		{"word":"togolais","word_nosc":"togolais","lemma":"togolais","pos":"NOM"} ,
		{"word":"togolaises","word_nosc":"togolaises","lemma":"togolais","pos":"NOM"} ,
		{"word":"tohu-bohu","word_nosc":"tohu-bohu","lemma":"tohu-bohu","pos":"NOM"} ,
		{"word":"toile","word_nosc":"toile","lemma":"toile","pos":"NOM"} ,
		{"word":"toiles","word_nosc":"toiles","lemma":"toile","pos":"NOM"} ,
		{"word":"toilettage","word_nosc":"toilettage","lemma":"toilettage","pos":"NOM"} ,
		{"word":"toilette","word_nosc":"toilette","lemma":"toilette","pos":"NOM"} ,
		{"word":"toilettes","word_nosc":"toilettes","lemma":"toilette","pos":"NOM"} ,
		{"word":"toiletteur","word_nosc":"toiletteur","lemma":"toiletteur","pos":"NOM"} ,
		{"word":"toise","word_nosc":"toise","lemma":"toise","pos":"NOM"} ,
		{"word":"toises","word_nosc":"toises","lemma":"toise","pos":"NOM"} ,
		{"word":"toison","word_nosc":"toison","lemma":"toison","pos":"NOM"} ,
		{"word":"toisons","word_nosc":"toisons","lemma":"toison","pos":"NOM"} ,
		{"word":"toit","word_nosc":"toit","lemma":"toit","pos":"NOM"} ,
		{"word":"toits","word_nosc":"toits","lemma":"toit","pos":"NOM"} ,
		{"word":"toiture","word_nosc":"toiture","lemma":"toiture","pos":"NOM"} ,
		{"word":"toitures","word_nosc":"toitures","lemma":"toiture","pos":"NOM"} ,
		{"word":"tokamak","word_nosc":"tokamak","lemma":"tokamak","pos":"NOM"} ,
		{"word":"tokay","word_nosc":"tokay","lemma":"tokay","pos":"NOM"} ,
		{"word":"tokharien","word_nosc":"tokharien","lemma":"tokharien","pos":"NOM"} ,
		{"word":"tolar","word_nosc":"tolar","lemma":"tolar","pos":"NOM"} ,
		{"word":"tolet","word_nosc":"tolet","lemma":"tolet","pos":"NOM"} ,
		{"word":"tolets","word_nosc":"tolets","lemma":"tolet","pos":"NOM"} ,
		{"word":"tollé","word_nosc":"tolle","lemma":"tollé","pos":"NOM"} ,
		{"word":"tolu","word_nosc":"tolu","lemma":"tolu","pos":"NOM"} ,
		{"word":"toluidine","word_nosc":"toluidine","lemma":"toluidine","pos":"NOM"} ,
		{"word":"toluène","word_nosc":"toluene","lemma":"toluène","pos":"NOM"} ,
		{"word":"tolérance","word_nosc":"tolerance","lemma":"tolérance","pos":"NOM"} ,
		{"word":"tom","word_nosc":"tom","lemma":"tom","pos":"NOM"} ,
		{"word":"tom-pouce","word_nosc":"tom-pouce","lemma":"tom-pouce","pos":"NOM"} ,
		{"word":"tom-tom","word_nosc":"tom-tom","lemma":"tom-tom","pos":"NOM"} ,
		{"word":"tomahawk","word_nosc":"tomahawk","lemma":"tomahawk","pos":"NOM"} ,
		{"word":"tomahawks","word_nosc":"tomahawks","lemma":"tomahawk","pos":"NOM"} ,
		{"word":"toman","word_nosc":"toman","lemma":"toman","pos":"NOM"} ,
		{"word":"tomate","word_nosc":"tomate","lemma":"tomate","pos":"NOM"} ,
		{"word":"tomates","word_nosc":"tomates","lemma":"tomate","pos":"NOM"} ,
		{"word":"tombe","word_nosc":"tombe","lemma":"tombe","pos":"NOM"} ,
		{"word":"tombeau","word_nosc":"tombeau","lemma":"tombeau","pos":"NOM"} ,
		{"word":"tombeaux","word_nosc":"tombeaux","lemma":"tombeau","pos":"NOM"} ,
		{"word":"tombereau","word_nosc":"tombereau","lemma":"tombereau","pos":"NOM"} ,
		{"word":"tombereaux","word_nosc":"tombereaux","lemma":"tombereau","pos":"NOM"} ,
		{"word":"tombes","word_nosc":"tombes","lemma":"tombe","pos":"NOM"} ,
		{"word":"tombeur","word_nosc":"tombeur","lemma":"tombeur","pos":"NOM"} ,
		{"word":"tombeurs","word_nosc":"tombeurs","lemma":"tombeur","pos":"NOM"} ,
		{"word":"tombeuse","word_nosc":"tombeuse","lemma":"tombeur","pos":"NOM"} ,
		{"word":"tombeuses","word_nosc":"tombeuses","lemma":"tombeur","pos":"NOM"} ,
		{"word":"tombola","word_nosc":"tombola","lemma":"tombola","pos":"NOM"} ,
		{"word":"tombolas","word_nosc":"tombolas","lemma":"tombola","pos":"NOM"} ,
		{"word":"tombée","word_nosc":"tombee","lemma":"tombée","pos":"NOM"} ,
		{"word":"tombées","word_nosc":"tombees","lemma":"tombée","pos":"NOM"} ,
		{"word":"tome","word_nosc":"tome","lemma":"tome","pos":"NOM"} ,
		{"word":"tomes","word_nosc":"tomes","lemma":"tome","pos":"NOM"} ,
		{"word":"tomettes","word_nosc":"tomettes","lemma":"tomette","pos":"NOM"} ,
		{"word":"tomme","word_nosc":"tomme","lemma":"tomme","pos":"NOM"} ,
		{"word":"tommes","word_nosc":"tommes","lemma":"tomme","pos":"NOM"} ,
		{"word":"tommette","word_nosc":"tommette","lemma":"tommette","pos":"NOM"} ,
		{"word":"tommettes","word_nosc":"tommettes","lemma":"tommette","pos":"NOM"} ,
		{"word":"tommies","word_nosc":"tommies","lemma":"tommies","pos":"NOM"} ,
		{"word":"tommy","word_nosc":"tommy","lemma":"tommy","pos":"NOM"} ,
		{"word":"tomodensitomètre","word_nosc":"tomodensitometre","lemma":"tomodensitomètre","pos":"NOM"} ,
		{"word":"tomodensitométrie","word_nosc":"tomodensitometrie","lemma":"tomodensitométrie","pos":"NOM"} ,
		{"word":"tomographe","word_nosc":"tomographe","lemma":"tomographe","pos":"NOM"} ,
		{"word":"tomographie","word_nosc":"tomographie","lemma":"tomographie","pos":"NOM"} ,
		{"word":"ton","word_nosc":"ton","lemma":"ton","pos":"NOM"} ,
		{"word":"tonalité","word_nosc":"tonalite","lemma":"tonalité","pos":"NOM"} ,
		{"word":"tonalités","word_nosc":"tonalites","lemma":"tonalité","pos":"NOM"} ,
		{"word":"tondeur","word_nosc":"tondeur","lemma":"tondeur","pos":"NOM"} ,
		{"word":"tondeuse","word_nosc":"tondeuse","lemma":"tondeur","pos":"NOM"} ,
		{"word":"tondeuses","word_nosc":"tondeuses","lemma":"tondeur","pos":"NOM"} ,
		{"word":"toner","word_nosc":"toner","lemma":"toner","pos":"NOM"} ,
		{"word":"tong","word_nosc":"tong","lemma":"tong","pos":"NOM"} ,
		{"word":"tongs","word_nosc":"tongs","lemma":"tong","pos":"NOM"} ,
		{"word":"tonic","word_nosc":"tonic","lemma":"tonic","pos":"NOM"} ,
		{"word":"tonicardiaque","word_nosc":"tonicardiaque","lemma":"tonicardiaque","pos":"NOM"} ,
		{"word":"tonicité","word_nosc":"tonicite","lemma":"tonicité","pos":"NOM"} ,
		{"word":"tonics","word_nosc":"tonics","lemma":"tonic","pos":"NOM"} ,
		{"word":"tonie","word_nosc":"tonie","lemma":"tonie","pos":"NOM"} ,
		{"word":"tonique","word_nosc":"tonique","lemma":"tonique","pos":"NOM"} ,
		{"word":"toniques","word_nosc":"toniques","lemma":"tonique","pos":"NOM"} ,
		{"word":"tonitruance","word_nosc":"tonitruance","lemma":"tonitruance","pos":"NOM"} ,
		{"word":"tonitruances","word_nosc":"tonitruances","lemma":"tonitruance","pos":"NOM"} ,
		{"word":"tonka","word_nosc":"tonka","lemma":"tonka","pos":"NOM"} ,
		{"word":"tonkinois","word_nosc":"tonkinois","lemma":"tonkinois","pos":"NOM"} ,
		{"word":"tonkinoise","word_nosc":"tonkinoise","lemma":"tonkinois","pos":"NOM"} ,
		{"word":"tonnage","word_nosc":"tonnage","lemma":"tonnage","pos":"NOM"} ,
		{"word":"tonnages","word_nosc":"tonnages","lemma":"tonnage","pos":"NOM"} ,
		{"word":"tonne","word_nosc":"tonne","lemma":"tonne","pos":"NOM"} ,
		{"word":"tonneau","word_nosc":"tonneau","lemma":"tonneau","pos":"NOM"} ,
		{"word":"tonneaux","word_nosc":"tonneaux","lemma":"tonneau","pos":"NOM"} ,
		{"word":"tonnelet","word_nosc":"tonnelet","lemma":"tonnelet","pos":"NOM"} ,
		{"word":"tonnelets","word_nosc":"tonnelets","lemma":"tonnelet","pos":"NOM"} ,
		{"word":"tonnelier","word_nosc":"tonnelier","lemma":"tonnelier","pos":"NOM"} ,
		{"word":"tonneliers","word_nosc":"tonneliers","lemma":"tonnelier","pos":"NOM"} ,
		{"word":"tonnelle","word_nosc":"tonnelle","lemma":"tonnelle","pos":"NOM"} ,
		{"word":"tonnellerie","word_nosc":"tonnellerie","lemma":"tonnellerie","pos":"NOM"} ,
		{"word":"tonnelleries","word_nosc":"tonnelleries","lemma":"tonnellerie","pos":"NOM"} ,
		{"word":"tonnelles","word_nosc":"tonnelles","lemma":"tonnelle","pos":"NOM"} ,
		{"word":"tonnerre","word_nosc":"tonnerre","lemma":"tonnerre","pos":"NOM"} ,
		{"word":"tonnerres","word_nosc":"tonnerres","lemma":"tonnerre","pos":"NOM"} ,
		{"word":"tonnes","word_nosc":"tonnes","lemma":"tonne","pos":"NOM"} ,
		{"word":"tons","word_nosc":"tons","lemma":"ton","pos":"NOM"} ,
		{"word":"tonsure","word_nosc":"tonsure","lemma":"tonsure","pos":"NOM"} ,
		{"word":"tonsures","word_nosc":"tonsures","lemma":"tonsure","pos":"NOM"} ,
		{"word":"tonte","word_nosc":"tonte","lemma":"tonte","pos":"NOM"} ,
		{"word":"tonton","word_nosc":"tonton","lemma":"tonton","pos":"NOM"} ,
		{"word":"tontons","word_nosc":"tontons","lemma":"tonton","pos":"NOM"} ,
		{"word":"tonus","word_nosc":"tonus","lemma":"tonus","pos":"NOM"} ,
		{"word":"top","word_nosc":"top","lemma":"top","pos":"NOM"} ,
		{"word":"top models","word_nosc":"top models","lemma":"top model","pos":"NOM"} ,
		{"word":"top-modèle","word_nosc":"top-modele","lemma":"top-modèle","pos":"NOM"} ,
		{"word":"top-modèles","word_nosc":"top-modeles","lemma":"top-modèle","pos":"NOM"} ,
		{"word":"topaze","word_nosc":"topaze","lemma":"topaze","pos":"NOM"} ,
		{"word":"topazes","word_nosc":"topazes","lemma":"topaze","pos":"NOM"} ,
		{"word":"tope","word_nosc":"tope","lemma":"tope","pos":"NOM"} ,
		{"word":"topette","word_nosc":"topette","lemma":"topette","pos":"NOM"} ,
		{"word":"topettes","word_nosc":"topettes","lemma":"topette","pos":"NOM"} ,
		{"word":"tophus","word_nosc":"tophus","lemma":"tophus","pos":"NOM"} ,
		{"word":"topinambour","word_nosc":"topinambour","lemma":"topinambour","pos":"NOM"} ,
		{"word":"topinambours","word_nosc":"topinambours","lemma":"topinambour","pos":"NOM"} ,
		{"word":"topique","word_nosc":"topique","lemma":"topique","pos":"NOM"} ,
		{"word":"topless","word_nosc":"topless","lemma":"topless","pos":"NOM"} ,
		{"word":"topo","word_nosc":"topo","lemma":"topo","pos":"NOM"} ,
		{"word":"topographie","word_nosc":"topographie","lemma":"topographie","pos":"NOM"} ,
		{"word":"topologie","word_nosc":"topologie","lemma":"topologie","pos":"NOM"} ,
		{"word":"toponymie","word_nosc":"toponymie","lemma":"toponymie","pos":"NOM"} ,
		{"word":"topos","word_nosc":"topos","lemma":"topo","pos":"NOM"} ,
		{"word":"tops","word_nosc":"tops","lemma":"top","pos":"NOM"} ,
		{"word":"toquade","word_nosc":"toquade","lemma":"toquade","pos":"NOM"} ,
		{"word":"toquades","word_nosc":"toquades","lemma":"toquade","pos":"NOM"} ,
		{"word":"toquante","word_nosc":"toquante","lemma":"toquante","pos":"NOM"} ,
		{"word":"toquantes","word_nosc":"toquantes","lemma":"toquante","pos":"NOM"} ,
		{"word":"toquard","word_nosc":"toquard","lemma":"toquard","pos":"NOM"} ,
		{"word":"toquards","word_nosc":"toquards","lemma":"toquard","pos":"NOM"} ,
		{"word":"toque","word_nosc":"toque","lemma":"toque","pos":"NOM"} ,
		{"word":"toques","word_nosc":"toques","lemma":"toque","pos":"NOM"} ,
		{"word":"torana","word_nosc":"torana","lemma":"torana","pos":"NOM"} ,
		{"word":"torche","word_nosc":"torche","lemma":"torche","pos":"NOM"} ,
		{"word":"torche-cul","word_nosc":"torche-cul","lemma":"torche-cul","pos":"NOM"} ,
		{"word":"torches","word_nosc":"torches","lemma":"torche","pos":"NOM"} ,
		{"word":"torchis","word_nosc":"torchis","lemma":"torchis","pos":"NOM"} ,
		{"word":"torchon","word_nosc":"torchon","lemma":"torchon","pos":"NOM"} ,
		{"word":"torchons","word_nosc":"torchons","lemma":"torchon","pos":"NOM"} ,
		{"word":"torchère","word_nosc":"torchere","lemma":"torchère","pos":"NOM"} ,
		{"word":"torchères","word_nosc":"torcheres","lemma":"torchère","pos":"NOM"} ,
		{"word":"torchées","word_nosc":"torchees","lemma":"torchée","pos":"NOM"} ,
		{"word":"tord-boyaux","word_nosc":"tord-boyaux","lemma":"tord-boyaux","pos":"NOM"} ,
		{"word":"tord-nez","word_nosc":"tord-nez","lemma":"tord-nez","pos":"NOM"} ,
		{"word":"tordage","word_nosc":"tordage","lemma":"tordage","pos":"NOM"} ,
		{"word":"tordeur","word_nosc":"tordeur","lemma":"tordeur","pos":"NOM"} ,
		{"word":"tordeurs","word_nosc":"tordeurs","lemma":"tordeur","pos":"NOM"} ,
		{"word":"tordion","word_nosc":"tordion","lemma":"tordion","pos":"NOM"} ,
		{"word":"tordions","word_nosc":"tordions","lemma":"tordion","pos":"NOM"} ,
		{"word":"tore","word_nosc":"tore","lemma":"tore","pos":"NOM"} ,
		{"word":"torera","word_nosc":"torera","lemma":"torera","pos":"NOM"} ,
		{"word":"torero","word_nosc":"torero","lemma":"torero","pos":"NOM"} ,
		{"word":"toreros","word_nosc":"toreros","lemma":"torero","pos":"NOM"} ,
		{"word":"tores","word_nosc":"tores","lemma":"tore","pos":"NOM"} ,
		{"word":"torgnole","word_nosc":"torgnole","lemma":"torgnole","pos":"NOM"} ,
		{"word":"torgnoles","word_nosc":"torgnoles","lemma":"torgnole","pos":"NOM"} ,
		{"word":"tories","word_nosc":"tories","lemma":"tories","pos":"NOM"} ,
		{"word":"toril","word_nosc":"toril","lemma":"toril","pos":"NOM"} ,
		{"word":"tornade","word_nosc":"tornade","lemma":"tornade","pos":"NOM"} ,
		{"word":"tornades","word_nosc":"tornades","lemma":"tornade","pos":"NOM"} ,
		{"word":"toro","word_nosc":"toro","lemma":"toro","pos":"NOM"} ,
		{"word":"torons","word_nosc":"torons","lemma":"toron","pos":"NOM"} ,
		{"word":"torpeur","word_nosc":"torpeur","lemma":"torpeur","pos":"NOM"} ,
		{"word":"torpeurs","word_nosc":"torpeurs","lemma":"torpeur","pos":"NOM"} ,
		{"word":"torpillage","word_nosc":"torpillage","lemma":"torpillage","pos":"NOM"} ,
		{"word":"torpillages","word_nosc":"torpillages","lemma":"torpillage","pos":"NOM"} ,
		{"word":"torpille","word_nosc":"torpille","lemma":"torpille","pos":"NOM"} ,
		{"word":"torpilles","word_nosc":"torpilles","lemma":"torpille","pos":"NOM"} ,
		{"word":"torpilleur","word_nosc":"torpilleur","lemma":"torpilleur","pos":"NOM"} ,
		{"word":"torpilleurs","word_nosc":"torpilleurs","lemma":"torpilleur","pos":"NOM"} ,
		{"word":"torpédo","word_nosc":"torpedo","lemma":"torpédo","pos":"NOM"} ,
		{"word":"torpédos","word_nosc":"torpedos","lemma":"torpédo","pos":"NOM"} ,
		{"word":"torque","word_nosc":"torque","lemma":"torque","pos":"NOM"} ,
		{"word":"torrent","word_nosc":"torrent","lemma":"torrent","pos":"NOM"} ,
		{"word":"torrents","word_nosc":"torrents","lemma":"torrent","pos":"NOM"} ,
		{"word":"torréfaction","word_nosc":"torrefaction","lemma":"torréfaction","pos":"NOM"} ,
		{"word":"torsade","word_nosc":"torsade","lemma":"torsade","pos":"NOM"} ,
		{"word":"torsades","word_nosc":"torsades","lemma":"torsade","pos":"NOM"} ,
		{"word":"torse","word_nosc":"torse","lemma":"torse","pos":"NOM"} ,
		{"word":"torses","word_nosc":"torses","lemma":"torse","pos":"NOM"} ,
		{"word":"torsion","word_nosc":"torsion","lemma":"torsion","pos":"NOM"} ,
		{"word":"torsions","word_nosc":"torsions","lemma":"torsion","pos":"NOM"} ,
		{"word":"tort","word_nosc":"tort","lemma":"tort","pos":"NOM"} ,
		{"word":"tortellini","word_nosc":"tortellini","lemma":"tortellini","pos":"NOM"} ,
		{"word":"tortellinis","word_nosc":"tortellinis","lemma":"tortellini","pos":"NOM"} ,
		{"word":"torticolis","word_nosc":"torticolis","lemma":"torticolis","pos":"NOM"} ,
		{"word":"tortil","word_nosc":"tortil","lemma":"tortil","pos":"NOM"} ,
		{"word":"tortilla","word_nosc":"tortilla","lemma":"tortilla","pos":"NOM"} ,
		{"word":"tortillard","word_nosc":"tortillard","lemma":"tortillard","pos":"NOM"} ,
		{"word":"tortillards","word_nosc":"tortillards","lemma":"tortillard","pos":"NOM"} ,
		{"word":"tortillas","word_nosc":"tortillas","lemma":"tortilla","pos":"NOM"} ,
		{"word":"tortille","word_nosc":"tortille","lemma":"tortille","pos":"NOM"} ,
		{"word":"tortillement","word_nosc":"tortillement","lemma":"tortillement","pos":"NOM"} ,
		{"word":"tortillements","word_nosc":"tortillements","lemma":"tortillement","pos":"NOM"} ,
		{"word":"tortillon","word_nosc":"tortillon","lemma":"tortillon","pos":"NOM"} ,
		{"word":"tortillons","word_nosc":"tortillons","lemma":"tortillon","pos":"NOM"} ,
		{"word":"tortils","word_nosc":"tortils","lemma":"tortil","pos":"NOM"} ,
		{"word":"tortionnaire","word_nosc":"tortionnaire","lemma":"tortionnaire","pos":"NOM"} ,
		{"word":"tortionnaires","word_nosc":"tortionnaires","lemma":"tortionnaire","pos":"NOM"} ,
		{"word":"tortore","word_nosc":"tortore","lemma":"tortore","pos":"NOM"} ,
		{"word":"torts","word_nosc":"torts","lemma":"tort","pos":"NOM"} ,
		{"word":"tortue","word_nosc":"tortue","lemma":"tortue","pos":"NOM"} ,
		{"word":"tortues","word_nosc":"tortues","lemma":"tortue","pos":"NOM"} ,
		{"word":"torture","word_nosc":"torture","lemma":"torture","pos":"NOM"} ,
		{"word":"tortures","word_nosc":"tortures","lemma":"torture","pos":"NOM"} ,
		{"word":"tortureurs","word_nosc":"tortureurs","lemma":"tortureur","pos":"NOM"} ,
		{"word":"toréador","word_nosc":"toreador","lemma":"toréador","pos":"NOM"} ,
		{"word":"toréador-vedette","word_nosc":"toreador-vedette","lemma":"toréador-vedette","pos":"NOM"} ,
		{"word":"toréadors","word_nosc":"toreadors","lemma":"toréador","pos":"NOM"} ,
		{"word":"tos","word_nosc":"tos","lemma":"to","pos":"NOM"} ,
		{"word":"toscan","word_nosc":"toscan","lemma":"toscan","pos":"NOM"} ,
		{"word":"toscans","word_nosc":"toscans","lemma":"toscan","pos":"NOM"} ,
		{"word":"toss","word_nosc":"toss","lemma":"toss","pos":"NOM"} ,
		{"word":"total","word_nosc":"total","lemma":"total","pos":"NOM"} ,
		{"word":"totalisateur","word_nosc":"totalisateur","lemma":"totalisateur","pos":"NOM"} ,
		{"word":"totalisation","word_nosc":"totalisation","lemma":"totalisation","pos":"NOM"} ,
		{"word":"totalitarisme","word_nosc":"totalitarisme","lemma":"totalitarisme","pos":"NOM"} ,
		{"word":"totalitarismes","word_nosc":"totalitarismes","lemma":"totalitarisme","pos":"NOM"} ,
		{"word":"totalité","word_nosc":"totalite","lemma":"totalité","pos":"NOM"} ,
		{"word":"totalités","word_nosc":"totalites","lemma":"totalité","pos":"NOM"} ,
		{"word":"totaux","word_nosc":"totaux","lemma":"total","pos":"NOM"} ,
		{"word":"totem","word_nosc":"totem","lemma":"totem","pos":"NOM"} ,
		{"word":"totems","word_nosc":"totems","lemma":"totem","pos":"NOM"} ,
		{"word":"toto","word_nosc":"toto","lemma":"toto","pos":"NOM"} ,
		{"word":"totoche","word_nosc":"totoche","lemma":"totoche","pos":"NOM"} ,
		{"word":"totoches","word_nosc":"totoches","lemma":"totoche","pos":"NOM"} ,
		{"word":"toton","word_nosc":"toton","lemma":"toton","pos":"NOM"} ,
		{"word":"totons","word_nosc":"totons","lemma":"toton","pos":"NOM"} ,
		{"word":"totos","word_nosc":"totos","lemma":"toto","pos":"NOM"} ,
		{"word":"totémisme","word_nosc":"totemisme","lemma":"totémisme","pos":"NOM"} ,
		{"word":"touareg","word_nosc":"touareg","lemma":"touareg","pos":"NOM"} ,
		{"word":"touaregs","word_nosc":"touaregs","lemma":"touareg","pos":"NOM"} ,
		{"word":"toubab","word_nosc":"toubab","lemma":"toubab","pos":"NOM"} ,
		{"word":"toubabs","word_nosc":"toubabs","lemma":"toubab","pos":"NOM"} ,
		{"word":"toubib","word_nosc":"toubib","lemma":"toubib","pos":"NOM"} ,
		{"word":"toubibs","word_nosc":"toubibs","lemma":"toubib","pos":"NOM"} ,
		{"word":"toucan","word_nosc":"toucan","lemma":"toucan","pos":"NOM"} ,
		{"word":"touche","word_nosc":"touche","lemma":"touche","pos":"NOM"} ,
		{"word":"touche-pipi","word_nosc":"touche-pipi","lemma":"touche-pipi","pos":"NOM"} ,
		{"word":"touche-touche","word_nosc":"touche-touche","lemma":"touche-touche","pos":"NOM"} ,
		{"word":"toucher","word_nosc":"toucher","lemma":"toucher","pos":"NOM"} ,
		{"word":"touchers","word_nosc":"touchers","lemma":"toucher","pos":"NOM"} ,
		{"word":"touches","word_nosc":"touches","lemma":"touche","pos":"NOM"} ,
		{"word":"touchette","word_nosc":"touchette","lemma":"touchette","pos":"NOM"} ,
		{"word":"touchotter","word_nosc":"touchotter","lemma":"touchotter","pos":"NOM"} ,
		{"word":"toue","word_nosc":"toue","lemma":"toue","pos":"NOM"} ,
		{"word":"toues","word_nosc":"toues","lemma":"toue","pos":"NOM"} ,
		{"word":"touffe","word_nosc":"touffe","lemma":"touffe","pos":"NOM"} ,
		{"word":"touffes","word_nosc":"touffes","lemma":"touffe","pos":"NOM"} ,
		{"word":"touffeur","word_nosc":"touffeur","lemma":"touffeur","pos":"NOM"} ,
		{"word":"touffeurs","word_nosc":"touffeurs","lemma":"touffeur","pos":"NOM"} ,
		{"word":"touille","word_nosc":"touille","lemma":"touille","pos":"NOM"} ,
		{"word":"toulonnais","word_nosc":"toulonnais","lemma":"toulonnais","pos":"NOM"} ,
		{"word":"touloupes","word_nosc":"touloupes","lemma":"touloupe","pos":"NOM"} ,
		{"word":"toulousain","word_nosc":"toulousain","lemma":"toulousain","pos":"NOM"} ,
		{"word":"toulousaine","word_nosc":"toulousaine","lemma":"toulousain","pos":"NOM"} ,
		{"word":"toulousains","word_nosc":"toulousains","lemma":"toulousain","pos":"NOM"} ,
		{"word":"toundra","word_nosc":"toundra","lemma":"toundra","pos":"NOM"} ,
		{"word":"toundras","word_nosc":"toundras","lemma":"toundra","pos":"NOM"} ,
		{"word":"toupet","word_nosc":"toupet","lemma":"toupet","pos":"NOM"} ,
		{"word":"toupets","word_nosc":"toupets","lemma":"toupet","pos":"NOM"} ,
		{"word":"toupie","word_nosc":"toupie","lemma":"toupie","pos":"NOM"} ,
		{"word":"toupies","word_nosc":"toupies","lemma":"toupie","pos":"NOM"} ,
		{"word":"toupilleur","word_nosc":"toupilleur","lemma":"toupilleur","pos":"NOM"} ,
		{"word":"touque","word_nosc":"touque","lemma":"touque","pos":"NOM"} ,
		{"word":"touques","word_nosc":"touques","lemma":"touque","pos":"NOM"} ,
		{"word":"tour","word_nosc":"tour","lemma":"tour","pos":"NOM"} ,
		{"word":"tourbe","word_nosc":"tourbe","lemma":"tourbe","pos":"NOM"} ,
		{"word":"tourbes","word_nosc":"tourbes","lemma":"tourbe","pos":"NOM"} ,
		{"word":"tourbiers","word_nosc":"tourbiers","lemma":"tourbier","pos":"NOM"} ,
		{"word":"tourbillon","word_nosc":"tourbillon","lemma":"tourbillon","pos":"NOM"} ,
		{"word":"tourbillonnement","word_nosc":"tourbillonnement","lemma":"tourbillonnement","pos":"NOM"} ,
		{"word":"tourbillonnements","word_nosc":"tourbillonnements","lemma":"tourbillonnement","pos":"NOM"} ,
		{"word":"tourbillons","word_nosc":"tourbillons","lemma":"tourbillon","pos":"NOM"} ,
		{"word":"tourbière","word_nosc":"tourbiere","lemma":"tourbière","pos":"NOM"} ,
		{"word":"tourbières","word_nosc":"tourbieres","lemma":"tourbière","pos":"NOM"} ,
		{"word":"tourde","word_nosc":"tourde","lemma":"tourd","pos":"NOM"} ,
		{"word":"tourelle","word_nosc":"tourelle","lemma":"tourelle","pos":"NOM"} ,
		{"word":"tourelles","word_nosc":"tourelles","lemma":"tourelle","pos":"NOM"} ,
		{"word":"touret","word_nosc":"touret","lemma":"touret","pos":"NOM"} ,
		{"word":"tourillon","word_nosc":"tourillon","lemma":"tourillon","pos":"NOM"} ,
		{"word":"tourisme","word_nosc":"tourisme","lemma":"tourisme","pos":"NOM"} ,
		{"word":"touriste","word_nosc":"touriste","lemma":"touriste","pos":"NOM"} ,
		{"word":"touristes","word_nosc":"touristes","lemma":"touriste","pos":"NOM"} ,
		{"word":"tourière","word_nosc":"touriere","lemma":"tourier","pos":"NOM"} ,
		{"word":"tourlourou","word_nosc":"tourlourou","lemma":"tourlourou","pos":"NOM"} ,
		{"word":"tourlousine","word_nosc":"tourlousine","lemma":"tourlousine","pos":"NOM"} ,
		{"word":"tourmalines","word_nosc":"tourmalines","lemma":"tourmaline","pos":"NOM"} ,
		{"word":"tourment","word_nosc":"tourment","lemma":"tourment","pos":"NOM"} ,
		{"word":"tourmente","word_nosc":"tourmente","lemma":"tourmente","pos":"NOM"} ,
		{"word":"tourmentes","word_nosc":"tourmentes","lemma":"tourmente","pos":"NOM"} ,
		{"word":"tourmenteur","word_nosc":"tourmenteur","lemma":"tourmenteur","pos":"NOM"} ,
		{"word":"tourmenteurs","word_nosc":"tourmenteurs","lemma":"tourmenteur","pos":"NOM"} ,
		{"word":"tourmentin","word_nosc":"tourmentin","lemma":"tourmentin","pos":"NOM"} ,
		{"word":"tourmentins","word_nosc":"tourmentins","lemma":"tourmentin","pos":"NOM"} ,
		{"word":"tourments","word_nosc":"tourments","lemma":"tourment","pos":"NOM"} ,
		{"word":"tournage","word_nosc":"tournage","lemma":"tournage","pos":"NOM"} ,
		{"word":"tournages","word_nosc":"tournages","lemma":"tournage","pos":"NOM"} ,
		{"word":"tournant","word_nosc":"tournant","lemma":"tournant","pos":"NOM"} ,
		{"word":"tournants","word_nosc":"tournants","lemma":"tournant","pos":"NOM"} ,
		{"word":"tourne","word_nosc":"tourne","lemma":"tourne","pos":"NOM"} ,
		{"word":"tourne-disque","word_nosc":"tourne-disque","lemma":"tourne-disque","pos":"NOM"} ,
		{"word":"tourne-disques","word_nosc":"tourne-disques","lemma":"tourne-disque","pos":"NOM"} ,
		{"word":"tournebroche","word_nosc":"tournebroche","lemma":"tournebroche","pos":"NOM"} ,
		{"word":"tournedos","word_nosc":"tournedos","lemma":"tournedos","pos":"NOM"} ,
		{"word":"tournelle","word_nosc":"tournelle","lemma":"tournelle","pos":"NOM"} ,
		{"word":"tournemain","word_nosc":"tournemain","lemma":"tournemain","pos":"NOM"} ,
		{"word":"tournements","word_nosc":"tournements","lemma":"tournement","pos":"NOM"} ,
		{"word":"tourneries","word_nosc":"tourneries","lemma":"tournerie","pos":"NOM"} ,
		{"word":"tournes","word_nosc":"tournes","lemma":"tourne","pos":"NOM"} ,
		{"word":"tournesol","word_nosc":"tournesol","lemma":"tournesol","pos":"NOM"} ,
		{"word":"tournesols","word_nosc":"tournesols","lemma":"tournesol","pos":"NOM"} ,
		{"word":"tournette","word_nosc":"tournette","lemma":"tournette","pos":"NOM"} ,
		{"word":"tourneur","word_nosc":"tourneur","lemma":"tourneur","pos":"NOM"} ,
		{"word":"tourneurs","word_nosc":"tourneurs","lemma":"tourneur","pos":"NOM"} ,
		{"word":"tourneuses","word_nosc":"tourneuses","lemma":"tourneur","pos":"NOM"} ,
		{"word":"tournevis","word_nosc":"tournevis","lemma":"tournevis","pos":"NOM"} ,
		{"word":"tourniquet","word_nosc":"tourniquet","lemma":"tourniquet","pos":"NOM"} ,
		{"word":"tourniquets","word_nosc":"tourniquets","lemma":"tourniquet","pos":"NOM"} ,
		{"word":"tournis","word_nosc":"tournis","lemma":"tournis","pos":"NOM"} ,
		{"word":"tournoi","word_nosc":"tournoi","lemma":"tournoi","pos":"NOM"} ,
		{"word":"tournoiement","word_nosc":"tournoiement","lemma":"tournoiement","pos":"NOM"} ,
		{"word":"tournoiements","word_nosc":"tournoiements","lemma":"tournoiement","pos":"NOM"} ,
		{"word":"tournois","word_nosc":"tournois","lemma":"tournoi","pos":"NOM"} ,
		{"word":"tournure","word_nosc":"tournure","lemma":"tournure","pos":"NOM"} ,
		{"word":"tournures","word_nosc":"tournures","lemma":"tournure","pos":"NOM"} ,
		{"word":"tournée","word_nosc":"tournee","lemma":"tournée","pos":"NOM"} ,
		{"word":"tournées","word_nosc":"tournees","lemma":"tournée","pos":"NOM"} ,
		{"word":"tours","word_nosc":"tours","lemma":"tour","pos":"NOM"} ,
		{"word":"tours-minute","word_nosc":"tours-minute","lemma":"tours-minute","pos":"NOM"} ,
		{"word":"tourte","word_nosc":"tourte","lemma":"tourte","pos":"NOM"} ,
		{"word":"tourteau","word_nosc":"tourteau","lemma":"tourteau","pos":"NOM"} ,
		{"word":"tourteaux","word_nosc":"tourteaux","lemma":"tourteau","pos":"NOM"} ,
		{"word":"tourtereau","word_nosc":"tourtereau","lemma":"tourtereau","pos":"NOM"} ,
		{"word":"tourtereaux","word_nosc":"tourtereaux","lemma":"tourtereau","pos":"NOM"} ,
		{"word":"tourterelle","word_nosc":"tourterelle","lemma":"tourtereau","pos":"NOM"} ,
		{"word":"tourterelles","word_nosc":"tourterelles","lemma":"tourtereau","pos":"NOM"} ,
		{"word":"tourtes","word_nosc":"tourtes","lemma":"tourte","pos":"NOM"} ,
		{"word":"tourtière","word_nosc":"tourtiere","lemma":"tourtière","pos":"NOM"} ,
		{"word":"tourtières","word_nosc":"tourtieres","lemma":"tourtière","pos":"NOM"} ,
		{"word":"toussaint","word_nosc":"toussaint","lemma":"toussaint","pos":"NOM"} ,
		{"word":"toussotement","word_nosc":"toussotement","lemma":"toussotement","pos":"NOM"} ,
		{"word":"toussotements","word_nosc":"toussotements","lemma":"toussotement","pos":"NOM"} ,
		{"word":"tout","word_nosc":"tout","lemma":"tout","pos":"NOM"} ,
		{"word":"tout-fait","word_nosc":"tout-fait","lemma":"tout-fait","pos":"NOM"} ,
		{"word":"tout-paris","word_nosc":"tout-paris","lemma":"tout-paris","pos":"NOM"} ,
		{"word":"tout-petit","word_nosc":"tout-petit","lemma":"tout-petit","pos":"NOM"} ,
		{"word":"tout-petits","word_nosc":"tout-petits","lemma":"tout-petit","pos":"NOM"} ,
		{"word":"tout-puissant","word_nosc":"tout-puissant","lemma":"tout-puissant","pos":"NOM"} ,
		{"word":"tout-puissants","word_nosc":"tout-puissants","lemma":"tout-puissant","pos":"NOM"} ,
		{"word":"tout-terrain","word_nosc":"tout-terrain","lemma":"tout-terrain","pos":"NOM"} ,
		{"word":"tout-venant","word_nosc":"tout-venant","lemma":"tout-venant","pos":"NOM"} ,
		{"word":"tout-à-l'égout","word_nosc":"tout-a-l'egout","lemma":"tout-à-l'égout","pos":"NOM"} ,
		{"word":"tout-étoile","word_nosc":"tout-etoile","lemma":"tout-étoile","pos":"NOM"} ,
		{"word":"toute-puissance","word_nosc":"toute-puissance","lemma":"toute-puissance","pos":"NOM"} ,
		{"word":"toutim","word_nosc":"toutim","lemma":"toutim","pos":"NOM"} ,
		{"word":"toutime","word_nosc":"toutime","lemma":"toutime","pos":"NOM"} ,
		{"word":"toutou","word_nosc":"toutou","lemma":"toutou","pos":"NOM"} ,
		{"word":"toutous","word_nosc":"toutous","lemma":"toutou","pos":"NOM"} ,
		{"word":"touts","word_nosc":"touts","lemma":"tout","pos":"NOM"} ,
		{"word":"toux","word_nosc":"toux","lemma":"toux","pos":"NOM"} ,
		{"word":"toxicité","word_nosc":"toxicite","lemma":"toxicité","pos":"NOM"} ,
		{"word":"toxico","word_nosc":"toxico","lemma":"toxico","pos":"NOM"} ,
		{"word":"toxicologie","word_nosc":"toxicologie","lemma":"toxicologie","pos":"NOM"} ,
		{"word":"toxicologue","word_nosc":"toxicologue","lemma":"toxicologue","pos":"NOM"} ,
		{"word":"toxicologues","word_nosc":"toxicologues","lemma":"toxicologue","pos":"NOM"} ,
		{"word":"toxicomane","word_nosc":"toxicomane","lemma":"toxicomane","pos":"NOM"} ,
		{"word":"toxicomanes","word_nosc":"toxicomanes","lemma":"toxicomane","pos":"NOM"} ,
		{"word":"toxicomanie","word_nosc":"toxicomanie","lemma":"toxicomanie","pos":"NOM"} ,
		{"word":"toxicos","word_nosc":"toxicos","lemma":"toxico","pos":"NOM"} ,
		{"word":"toxine","word_nosc":"toxine","lemma":"toxine","pos":"NOM"} ,
		{"word":"toxines","word_nosc":"toxines","lemma":"toxine","pos":"NOM"} ,
		{"word":"toxique","word_nosc":"toxique","lemma":"toxique","pos":"NOM"} ,
		{"word":"toxiques","word_nosc":"toxiques","lemma":"toxique","pos":"NOM"} ,
		{"word":"toxoplasmose","word_nosc":"toxoplasmose","lemma":"toxoplasmose","pos":"NOM"} ,
		{"word":"trabans","word_nosc":"trabans","lemma":"traban","pos":"NOM"} ,
		{"word":"traboules","word_nosc":"traboules","lemma":"traboule","pos":"NOM"} ,
		{"word":"trabuco","word_nosc":"trabuco","lemma":"trabuco","pos":"NOM"} ,
		{"word":"trac","word_nosc":"trac","lemma":"trac","pos":"NOM"} ,
		{"word":"tracas","word_nosc":"tracas","lemma":"tracas","pos":"NOM"} ,
		{"word":"tracasserie","word_nosc":"tracasserie","lemma":"tracasserie","pos":"NOM"} ,
		{"word":"tracasseries","word_nosc":"tracasseries","lemma":"tracasserie","pos":"NOM"} ,
		{"word":"tracassin","word_nosc":"tracassin","lemma":"tracassin","pos":"NOM"} ,
		{"word":"tracassière","word_nosc":"tracassiere","lemma":"tracassier","pos":"NOM"} ,
		{"word":"trace","word_nosc":"trace","lemma":"trace","pos":"NOM"} ,
		{"word":"traces","word_nosc":"traces","lemma":"trace","pos":"NOM"} ,
		{"word":"traceur","word_nosc":"traceur","lemma":"traceur","pos":"NOM"} ,
		{"word":"traceurs","word_nosc":"traceurs","lemma":"traceur","pos":"NOM"} ,
		{"word":"trachome","word_nosc":"trachome","lemma":"trachome","pos":"NOM"} ,
		{"word":"trachée","word_nosc":"trachee","lemma":"trachée","pos":"NOM"} ,
		{"word":"trachée-artère","word_nosc":"trachee-artere","lemma":"trachée-artère","pos":"NOM"} ,
		{"word":"trachées","word_nosc":"trachees","lemma":"trachée","pos":"NOM"} ,
		{"word":"trachéite","word_nosc":"tracheite","lemma":"trachéite","pos":"NOM"} ,
		{"word":"trachéotomie","word_nosc":"tracheotomie","lemma":"trachéotomie","pos":"NOM"} ,
		{"word":"tracs","word_nosc":"tracs","lemma":"trac","pos":"NOM"} ,
		{"word":"tract","word_nosc":"tract","lemma":"tract","pos":"NOM"} ,
		{"word":"tractage","word_nosc":"tractage","lemma":"tractage","pos":"NOM"} ,
		{"word":"tractation","word_nosc":"tractation","lemma":"tractation","pos":"NOM"} ,
		{"word":"tractations","word_nosc":"tractations","lemma":"tractation","pos":"NOM"} ,
		{"word":"tracteur","word_nosc":"tracteur","lemma":"tracteur","pos":"NOM"} ,
		{"word":"tracteurs","word_nosc":"tracteurs","lemma":"tracteur","pos":"NOM"} ,
		{"word":"traction","word_nosc":"traction","lemma":"traction","pos":"NOM"} ,
		{"word":"traction-avant","word_nosc":"traction-avant","lemma":"traction-avant","pos":"NOM"} ,
		{"word":"tractions","word_nosc":"tractions","lemma":"traction","pos":"NOM"} ,
		{"word":"tractopelle","word_nosc":"tractopelle","lemma":"tractopelle","pos":"NOM"} ,
		{"word":"tractoriste","word_nosc":"tractoriste","lemma":"tractoriste","pos":"NOM"} ,
		{"word":"tractoristes","word_nosc":"tractoristes","lemma":"tractoriste","pos":"NOM"} ,
		{"word":"tracts","word_nosc":"tracts","lemma":"tract","pos":"NOM"} ,
		{"word":"tractus","word_nosc":"tractus","lemma":"tractus","pos":"NOM"} ,
		{"word":"tracé","word_nosc":"trace","lemma":"tracé","pos":"NOM"} ,
		{"word":"tracés","word_nosc":"traces","lemma":"tracé","pos":"NOM"} ,
		{"word":"trader","word_nosc":"trader","lemma":"trader","pos":"NOM"} ,
		{"word":"tradition","word_nosc":"tradition","lemma":"tradition","pos":"NOM"} ,
		{"word":"traditionalisme","word_nosc":"traditionalisme","lemma":"traditionalisme","pos":"NOM"} ,
		{"word":"traditionaliste","word_nosc":"traditionaliste","lemma":"traditionaliste","pos":"NOM"} ,
		{"word":"traditionalistes","word_nosc":"traditionalistes","lemma":"traditionaliste","pos":"NOM"} ,
		{"word":"traditions","word_nosc":"traditions","lemma":"tradition","pos":"NOM"} ,
		{"word":"traduc","word_nosc":"traduc","lemma":"traduc","pos":"NOM"} ,
		{"word":"traducteur","word_nosc":"traducteur","lemma":"traducteur","pos":"NOM"} ,
		{"word":"traducteurs","word_nosc":"traducteurs","lemma":"traducteur","pos":"NOM"} ,
		{"word":"traduction","word_nosc":"traduction","lemma":"traduction","pos":"NOM"} ,
		{"word":"traductions","word_nosc":"traductions","lemma":"traduction","pos":"NOM"} ,
		{"word":"traductrice","word_nosc":"traductrice","lemma":"traducteur","pos":"NOM"} ,
		{"word":"traductrices","word_nosc":"traductrices","lemma":"traducteur","pos":"NOM"} ,
		{"word":"trafalgar","word_nosc":"trafalgar","lemma":"trafalgar","pos":"NOM"} ,
		{"word":"trafic","word_nosc":"trafic","lemma":"trafic","pos":"NOM"} ,
		{"word":"traficoteurs","word_nosc":"traficoteurs","lemma":"traficoteur","pos":"NOM"} ,
		{"word":"trafics","word_nosc":"trafics","lemma":"trafic","pos":"NOM"} ,
		{"word":"trafiquant","word_nosc":"trafiquant","lemma":"trafiquant","pos":"NOM"} ,
		{"word":"trafiquant-espion","word_nosc":"trafiquant-espion","lemma":"trafiquant-espion","pos":"NOM"} ,
		{"word":"trafiquante","word_nosc":"trafiquante","lemma":"trafiquant","pos":"NOM"} ,
		{"word":"trafiquants","word_nosc":"trafiquants","lemma":"trafiquant","pos":"NOM"} ,
		{"word":"tragi-comédie","word_nosc":"tragi-comedie","lemma":"tragi-comédie","pos":"NOM"} ,
		{"word":"tragi-comédies","word_nosc":"tragi-comedies","lemma":"tragi-comédie","pos":"NOM"} ,
		{"word":"tragicomédies","word_nosc":"tragicomedies","lemma":"tragicomédie","pos":"NOM"} ,
		{"word":"tragique","word_nosc":"tragique","lemma":"tragique","pos":"NOM"} ,
		{"word":"tragiques","word_nosc":"tragiques","lemma":"tragique","pos":"NOM"} ,
		{"word":"tragédie","word_nosc":"tragedie","lemma":"tragédie","pos":"NOM"} ,
		{"word":"tragédien","word_nosc":"tragedien","lemma":"tragédien","pos":"NOM"} ,
		{"word":"tragédienne","word_nosc":"tragedienne","lemma":"tragédien","pos":"NOM"} ,
		{"word":"tragédiennes","word_nosc":"tragediennes","lemma":"tragédien","pos":"NOM"} ,
		{"word":"tragédiens","word_nosc":"tragediens","lemma":"tragédien","pos":"NOM"} ,
		{"word":"tragédies","word_nosc":"tragedies","lemma":"tragédie","pos":"NOM"} ,
		{"word":"trahison","word_nosc":"trahison","lemma":"trahison","pos":"NOM"} ,
		{"word":"trahisons","word_nosc":"trahisons","lemma":"trahison","pos":"NOM"} ,
		{"word":"train","word_nosc":"train","lemma":"train","pos":"NOM"} ,
		{"word":"train-train","word_nosc":"train-train","lemma":"train-train","pos":"NOM"} ,
		{"word":"trainglots","word_nosc":"trainglots","lemma":"trainglot","pos":"NOM"} ,
		{"word":"training","word_nosc":"training","lemma":"training","pos":"NOM"} ,
		{"word":"trains","word_nosc":"trains","lemma":"train","pos":"NOM"} ,
		{"word":"traintrain","word_nosc":"traintrain","lemma":"traintrain","pos":"NOM"} ,
		{"word":"trait","word_nosc":"trait","lemma":"trait","pos":"NOM"} ,
		{"word":"traite","word_nosc":"traite","lemma":"traite","pos":"NOM"} ,
		{"word":"traitement","word_nosc":"traitement","lemma":"traitement","pos":"NOM"} ,
		{"word":"traitements","word_nosc":"traitements","lemma":"traitement","pos":"NOM"} ,
		{"word":"traites","word_nosc":"traites","lemma":"traite","pos":"NOM"} ,
		{"word":"traiteur","word_nosc":"traiteur","lemma":"traiteur","pos":"NOM"} ,
		{"word":"traiteurs","word_nosc":"traiteurs","lemma":"traiteur","pos":"NOM"} ,
		{"word":"traits","word_nosc":"traits","lemma":"trait","pos":"NOM"} ,
		{"word":"traité","word_nosc":"traite","lemma":"traité","pos":"NOM"} ,
		{"word":"traités","word_nosc":"traites","lemma":"traité","pos":"NOM"} ,
		{"word":"trajectographie","word_nosc":"trajectographie","lemma":"trajectographie","pos":"NOM"} ,
		{"word":"trajectoire","word_nosc":"trajectoire","lemma":"trajectoire","pos":"NOM"} ,
		{"word":"trajectoires","word_nosc":"trajectoires","lemma":"trajectoire","pos":"NOM"} ,
		{"word":"trajet","word_nosc":"trajet","lemma":"trajet","pos":"NOM"} ,
		{"word":"trajets","word_nosc":"trajets","lemma":"trajet","pos":"NOM"} ,
		{"word":"tralala","word_nosc":"tralala","lemma":"tralala","pos":"NOM"} ,
		{"word":"tralalas","word_nosc":"tralalas","lemma":"tralala","pos":"NOM"} ,
		{"word":"tram","word_nosc":"tram","lemma":"tram","pos":"NOM"} ,
		{"word":"tramail","word_nosc":"tramail","lemma":"tramail","pos":"NOM"} ,
		{"word":"tramails","word_nosc":"tramails","lemma":"tramail","pos":"NOM"} ,
		{"word":"trame","word_nosc":"trame","lemma":"trame","pos":"NOM"} ,
		{"word":"trames","word_nosc":"trames","lemma":"trame","pos":"NOM"} ,
		{"word":"tramontane","word_nosc":"tramontane","lemma":"tramontane","pos":"NOM"} ,
		{"word":"tramontanes","word_nosc":"tramontanes","lemma":"tramontane","pos":"NOM"} ,
		{"word":"tramp","word_nosc":"tramp","lemma":"tramp","pos":"NOM"} ,
		{"word":"trampoline","word_nosc":"trampoline","lemma":"trampoline","pos":"NOM"} ,
		{"word":"trams","word_nosc":"trams","lemma":"tram","pos":"NOM"} ,
		{"word":"tramway","word_nosc":"tramway","lemma":"tramway","pos":"NOM"} ,
		{"word":"tramways","word_nosc":"tramways","lemma":"tramway","pos":"NOM"} ,
		{"word":"tranchage","word_nosc":"tranchage","lemma":"tranchage","pos":"NOM"} ,
		{"word":"tranchant","word_nosc":"tranchant","lemma":"tranchant","pos":"NOM"} ,
		{"word":"tranchants","word_nosc":"tranchants","lemma":"tranchant","pos":"NOM"} ,
		{"word":"tranche","word_nosc":"tranche","lemma":"tranche","pos":"NOM"} ,
		{"word":"tranche-montagne","word_nosc":"tranche-montagne","lemma":"tranche-montagne","pos":"NOM"} ,
		{"word":"tranchecaille","word_nosc":"tranchecaille","lemma":"tranchecaille","pos":"NOM"} ,
		{"word":"tranchelards","word_nosc":"tranchelards","lemma":"tranchelard","pos":"NOM"} ,
		{"word":"tranches","word_nosc":"tranches","lemma":"tranche","pos":"NOM"} ,
		{"word":"tranchet","word_nosc":"tranchet","lemma":"tranchet","pos":"NOM"} ,
		{"word":"tranchets","word_nosc":"tranchets","lemma":"tranchet","pos":"NOM"} ,
		{"word":"trancheur","word_nosc":"trancheur","lemma":"trancheur","pos":"NOM"} ,
		{"word":"trancheuse","word_nosc":"trancheuse","lemma":"trancheur","pos":"NOM"} ,
		{"word":"tranchoir","word_nosc":"tranchoir","lemma":"tranchoir","pos":"NOM"} ,
		{"word":"tranchoirs","word_nosc":"tranchoirs","lemma":"tranchoir","pos":"NOM"} ,
		{"word":"tranchée","word_nosc":"tranchee","lemma":"tranchée","pos":"NOM"} ,
		{"word":"tranchée-abri","word_nosc":"tranchee-abri","lemma":"tranchée-abri","pos":"NOM"} ,
		{"word":"tranchées","word_nosc":"tranchees","lemma":"tranchée","pos":"NOM"} ,
		{"word":"tranchées-abris","word_nosc":"tranchees-abris","lemma":"tranchée-abri","pos":"NOM"} ,
		{"word":"tranquille","word_nosc":"tranquille","lemma":"tranquille","pos":"NOM"} ,
		{"word":"tranquillisant","word_nosc":"tranquillisant","lemma":"tranquillisant","pos":"NOM"} ,
		{"word":"tranquillisants","word_nosc":"tranquillisants","lemma":"tranquillisant","pos":"NOM"} ,
		{"word":"tranquillité","word_nosc":"tranquillite","lemma":"tranquillité","pos":"NOM"} ,
		{"word":"tranquillités","word_nosc":"tranquillites","lemma":"tranquillité","pos":"NOM"} ,
		{"word":"transaction","word_nosc":"transaction","lemma":"transaction","pos":"NOM"} ,
		{"word":"transactions","word_nosc":"transactions","lemma":"transaction","pos":"NOM"} ,
		{"word":"transaminase","word_nosc":"transaminase","lemma":"transaminase","pos":"NOM"} ,
		{"word":"transaminases","word_nosc":"transaminases","lemma":"transaminase","pos":"NOM"} ,
		{"word":"transat","word_nosc":"transat","lemma":"transat","pos":"NOM"} ,
		{"word":"transatlantique","word_nosc":"transatlantique","lemma":"transatlantique","pos":"NOM"} ,
		{"word":"transatlantiques","word_nosc":"transatlantiques","lemma":"transatlantique","pos":"NOM"} ,
		{"word":"transats","word_nosc":"transats","lemma":"transat","pos":"NOM"} ,
		{"word":"transbordement","word_nosc":"transbordement","lemma":"transbordement","pos":"NOM"} ,
		{"word":"transbordements","word_nosc":"transbordements","lemma":"transbordement","pos":"NOM"} ,
		{"word":"transbordeur","word_nosc":"transbordeur","lemma":"transbordeur","pos":"NOM"} ,
		{"word":"transcendance","word_nosc":"transcendance","lemma":"transcendance","pos":"NOM"} ,
		{"word":"transcendantalistes","word_nosc":"transcendantalistes","lemma":"transcendantaliste","pos":"NOM"} ,
		{"word":"transcriptase","word_nosc":"transcriptase","lemma":"transcriptase","pos":"NOM"} ,
		{"word":"transcripteur","word_nosc":"transcripteur","lemma":"transcripteur","pos":"NOM"} ,
		{"word":"transcription","word_nosc":"transcription","lemma":"transcription","pos":"NOM"} ,
		{"word":"transcriptions","word_nosc":"transcriptions","lemma":"transcription","pos":"NOM"} ,
		{"word":"transducteur","word_nosc":"transducteur","lemma":"transducteur","pos":"NOM"} ,
		{"word":"transducteurs","word_nosc":"transducteurs","lemma":"transducteur","pos":"NOM"} ,
		{"word":"transduction","word_nosc":"transduction","lemma":"transduction","pos":"NOM"} ,
		{"word":"transe","word_nosc":"transe","lemma":"transe","pos":"NOM"} ,
		{"word":"transept","word_nosc":"transept","lemma":"transept","pos":"NOM"} ,
		{"word":"transepts","word_nosc":"transepts","lemma":"transept","pos":"NOM"} ,
		{"word":"transes","word_nosc":"transes","lemma":"transe","pos":"NOM"} ,
		{"word":"transfert","word_nosc":"transfert","lemma":"transfert","pos":"NOM"} ,
		{"word":"transferts","word_nosc":"transferts","lemma":"transfert","pos":"NOM"} ,
		{"word":"transfiguration","word_nosc":"transfiguration","lemma":"transfiguration","pos":"NOM"} ,
		{"word":"transfigurations","word_nosc":"transfigurations","lemma":"transfiguration","pos":"NOM"} ,
		{"word":"transfiguratrice","word_nosc":"transfiguratrice","lemma":"transfigurateur","pos":"NOM"} ,
		{"word":"transfo","word_nosc":"transfo","lemma":"transfo","pos":"NOM"} ,
		{"word":"transformateur","word_nosc":"transformateur","lemma":"transformateur","pos":"NOM"} ,
		{"word":"transformateurs","word_nosc":"transformateurs","lemma":"transformateur","pos":"NOM"} ,
		{"word":"transformation","word_nosc":"transformation","lemma":"transformation","pos":"NOM"} ,
		{"word":"transformations","word_nosc":"transformations","lemma":"transformation","pos":"NOM"} ,
		{"word":"transformiste","word_nosc":"transformiste","lemma":"transformiste","pos":"NOM"} ,
		{"word":"transfos","word_nosc":"transfos","lemma":"transfo","pos":"NOM"} ,
		{"word":"transfuge","word_nosc":"transfuge","lemma":"transfuge","pos":"NOM"} ,
		{"word":"transfuges","word_nosc":"transfuges","lemma":"transfuge","pos":"NOM"} ,
		{"word":"transfuseur","word_nosc":"transfuseur","lemma":"transfuseur","pos":"NOM"} ,
		{"word":"transfusion","word_nosc":"transfusion","lemma":"transfusion","pos":"NOM"} ,
		{"word":"transfusions","word_nosc":"transfusions","lemma":"transfusion","pos":"NOM"} ,
		{"word":"transfusée","word_nosc":"transfusee","lemma":"transfusé","pos":"NOM"} ,
		{"word":"transfèrement","word_nosc":"transferement","lemma":"transfèrement","pos":"NOM"} ,
		{"word":"transfèrements","word_nosc":"transferements","lemma":"transfèrement","pos":"NOM"} ,
		{"word":"transgresseur","word_nosc":"transgresseur","lemma":"transgresseur","pos":"NOM"} ,
		{"word":"transgression","word_nosc":"transgression","lemma":"transgression","pos":"NOM"} ,
		{"word":"transgressions","word_nosc":"transgressions","lemma":"transgression","pos":"NOM"} ,
		{"word":"transhumance","word_nosc":"transhumance","lemma":"transhumance","pos":"NOM"} ,
		{"word":"transhumances","word_nosc":"transhumances","lemma":"transhumance","pos":"NOM"} ,
		{"word":"transillumination","word_nosc":"transillumination","lemma":"transillumination","pos":"NOM"} ,
		{"word":"transistor","word_nosc":"transistor","lemma":"transistor","pos":"NOM"} ,
		{"word":"transistors","word_nosc":"transistors","lemma":"transistor","pos":"NOM"} ,
		{"word":"transit","word_nosc":"transit","lemma":"transit","pos":"NOM"} ,
		{"word":"transitaires","word_nosc":"transitaires","lemma":"transitaire","pos":"NOM"} ,
		{"word":"transition","word_nosc":"transition","lemma":"transition","pos":"NOM"} ,
		{"word":"transitions","word_nosc":"transitions","lemma":"transition","pos":"NOM"} ,
		{"word":"transits","word_nosc":"transits","lemma":"transit","pos":"NOM"} ,
		{"word":"translation","word_nosc":"translation","lemma":"translation","pos":"NOM"} ,
		{"word":"translations","word_nosc":"translations","lemma":"translation","pos":"NOM"} ,
		{"word":"translocation","word_nosc":"translocation","lemma":"translocation","pos":"NOM"} ,
		{"word":"translucidité","word_nosc":"translucidite","lemma":"translucidité","pos":"NOM"} ,
		{"word":"transmetteur","word_nosc":"transmetteur","lemma":"transmetteur","pos":"NOM"} ,
		{"word":"transmetteurs","word_nosc":"transmetteurs","lemma":"transmetteur","pos":"NOM"} ,
		{"word":"transmigration","word_nosc":"transmigration","lemma":"transmigration","pos":"NOM"} ,
		{"word":"transmission","word_nosc":"transmission","lemma":"transmission","pos":"NOM"} ,
		{"word":"transmissions","word_nosc":"transmissions","lemma":"transmission","pos":"NOM"} ,
		{"word":"transmutation","word_nosc":"transmutation","lemma":"transmutation","pos":"NOM"} ,
		{"word":"transmutations","word_nosc":"transmutations","lemma":"transmutation","pos":"NOM"} ,
		{"word":"transparence","word_nosc":"transparence","lemma":"transparence","pos":"NOM"} ,
		{"word":"transparences","word_nosc":"transparences","lemma":"transparence","pos":"NOM"} ,
		{"word":"transparent","word_nosc":"transparent","lemma":"transparent","pos":"NOM"} ,
		{"word":"transparents","word_nosc":"transparents","lemma":"transparent","pos":"NOM"} ,
		{"word":"transpiration","word_nosc":"transpiration","lemma":"transpiration","pos":"NOM"} ,
		{"word":"transplantation","word_nosc":"transplantation","lemma":"transplantation","pos":"NOM"} ,
		{"word":"transplantations","word_nosc":"transplantations","lemma":"transplantation","pos":"NOM"} ,
		{"word":"transplants","word_nosc":"transplants","lemma":"transplant","pos":"NOM"} ,
		{"word":"transpondeur","word_nosc":"transpondeur","lemma":"transpondeur","pos":"NOM"} ,
		{"word":"transport","word_nosc":"transport","lemma":"transport","pos":"NOM"} ,
		{"word":"transportation","word_nosc":"transportation","lemma":"transportation","pos":"NOM"} ,
		{"word":"transporteur","word_nosc":"transporteur","lemma":"transporteur","pos":"NOM"} ,
		{"word":"transporteurs","word_nosc":"transporteurs","lemma":"transporteur","pos":"NOM"} ,
		{"word":"transports","word_nosc":"transports","lemma":"transport","pos":"NOM"} ,
		{"word":"transporté","word_nosc":"transporte","lemma":"transporté","pos":"NOM"} ,
		{"word":"transportée","word_nosc":"transportee","lemma":"transporté","pos":"NOM"} ,
		{"word":"transportées","word_nosc":"transportees","lemma":"transporté","pos":"NOM"} ,
		{"word":"transportés","word_nosc":"transportes","lemma":"transporté","pos":"NOM"} ,
		{"word":"transposition","word_nosc":"transposition","lemma":"transposition","pos":"NOM"} ,
		{"word":"transpositions","word_nosc":"transpositions","lemma":"transposition","pos":"NOM"} ,
		{"word":"transposon","word_nosc":"transposon","lemma":"transposon","pos":"NOM"} ,
		{"word":"transsaharienne","word_nosc":"transsaharienne","lemma":"transsaharien","pos":"NOM"} ,
		{"word":"transsexualisme","word_nosc":"transsexualisme","lemma":"transsexualisme","pos":"NOM"} ,
		{"word":"transsexualité","word_nosc":"transsexualite","lemma":"transsexualité","pos":"NOM"} ,
		{"word":"transsibérien","word_nosc":"transsiberien","lemma":"transsibérien","pos":"NOM"} ,
		{"word":"transsubstantiation","word_nosc":"transsubstantiation","lemma":"transsubstantiation","pos":"NOM"} ,
		{"word":"transsudat","word_nosc":"transsudat","lemma":"transsudat","pos":"NOM"} ,
		{"word":"transvestisme","word_nosc":"transvestisme","lemma":"transvestisme","pos":"NOM"} ,
		{"word":"trappe","word_nosc":"trappe","lemma":"trappe","pos":"NOM"} ,
		{"word":"trappes","word_nosc":"trappes","lemma":"trappe","pos":"NOM"} ,
		{"word":"trappeur","word_nosc":"trappeur","lemma":"trappeur","pos":"NOM"} ,
		{"word":"trappeurs","word_nosc":"trappeurs","lemma":"trappeur","pos":"NOM"} ,
		{"word":"trappiste","word_nosc":"trappiste","lemma":"trappiste","pos":"NOM"} ,
		{"word":"trappistes","word_nosc":"trappistes","lemma":"trappiste","pos":"NOM"} ,
		{"word":"trappon","word_nosc":"trappon","lemma":"trappon","pos":"NOM"} ,
		{"word":"trapèze","word_nosc":"trapeze","lemma":"trapèze","pos":"NOM"} ,
		{"word":"trapèzes","word_nosc":"trapezes","lemma":"trapèze","pos":"NOM"} ,
		{"word":"trapéziste","word_nosc":"trapeziste","lemma":"trapéziste","pos":"NOM"} ,
		{"word":"trapézistes","word_nosc":"trapezistes","lemma":"trapéziste","pos":"NOM"} ,
		{"word":"traque","word_nosc":"traque","lemma":"traque","pos":"NOM"} ,
		{"word":"traquenard","word_nosc":"traquenard","lemma":"traquenard","pos":"NOM"} ,
		{"word":"traquenards","word_nosc":"traquenards","lemma":"traquenard","pos":"NOM"} ,
		{"word":"traques","word_nosc":"traques","lemma":"traque","pos":"NOM"} ,
		{"word":"traquet","word_nosc":"traquet","lemma":"traquet","pos":"NOM"} ,
		{"word":"traquets","word_nosc":"traquets","lemma":"traquet","pos":"NOM"} ,
		{"word":"traqueur","word_nosc":"traqueur","lemma":"traqueur","pos":"NOM"} ,
		{"word":"traqueurs","word_nosc":"traqueurs","lemma":"traqueur","pos":"NOM"} ,
		{"word":"traqueuse","word_nosc":"traqueuse","lemma":"traqueur","pos":"NOM"} ,
		{"word":"trattoria","word_nosc":"trattoria","lemma":"trattoria","pos":"NOM"} ,
		{"word":"trattorias","word_nosc":"trattorias","lemma":"trattoria","pos":"NOM"} ,
		{"word":"trauma","word_nosc":"trauma","lemma":"trauma","pos":"NOM"} ,
		{"word":"traumas","word_nosc":"traumas","lemma":"trauma","pos":"NOM"} ,
		{"word":"traumatisme","word_nosc":"traumatisme","lemma":"traumatisme","pos":"NOM"} ,
		{"word":"traumatismes","word_nosc":"traumatismes","lemma":"traumatisme","pos":"NOM"} ,
		{"word":"traumatologie","word_nosc":"traumatologie","lemma":"traumatologie","pos":"NOM"} ,
		{"word":"traumatologue","word_nosc":"traumatologue","lemma":"traumatologue","pos":"NOM"} ,
		{"word":"travail","word_nosc":"travail","lemma":"travail","pos":"NOM"} ,
		{"word":"travailleur","word_nosc":"travailleur","lemma":"travailleur","pos":"NOM"} ,
		{"word":"travailleurs","word_nosc":"travailleurs","lemma":"travailleur","pos":"NOM"} ,
		{"word":"travailleuse","word_nosc":"travailleuse","lemma":"travailleur","pos":"NOM"} ,
		{"word":"travailleuses","word_nosc":"travailleuses","lemma":"travailleur","pos":"NOM"} ,
		{"word":"travailliste","word_nosc":"travailliste","lemma":"travailliste","pos":"NOM"} ,
		{"word":"travaillistes","word_nosc":"travaillistes","lemma":"travailliste","pos":"NOM"} ,
		{"word":"travaux","word_nosc":"travaux","lemma":"travail","pos":"NOM"} ,
		{"word":"trave","word_nosc":"trave","lemma":"trave","pos":"NOM"} ,
		{"word":"traveller","word_nosc":"traveller","lemma":"traveller","pos":"NOM"} ,
		{"word":"traveller's chèques","word_nosc":"traveller's cheques","lemma":"traveller's chèque","pos":"NOM"} ,
		{"word":"travellers","word_nosc":"travellers","lemma":"traveller","pos":"NOM"} ,
		{"word":"travelling","word_nosc":"travelling","lemma":"travelling","pos":"NOM"} ,
		{"word":"travellings","word_nosc":"travellings","lemma":"travelling","pos":"NOM"} ,
		{"word":"travelo","word_nosc":"travelo","lemma":"travelo","pos":"NOM"} ,
		{"word":"travelos","word_nosc":"travelos","lemma":"travelo","pos":"NOM"} ,
		{"word":"travers","word_nosc":"travers","lemma":"travers","pos":"NOM"} ,
		{"word":"traverse","word_nosc":"traverse","lemma":"traverse","pos":"NOM"} ,
		{"word":"traverses","word_nosc":"traverses","lemma":"traverse","pos":"NOM"} ,
		{"word":"traversier","word_nosc":"traversier","lemma":"traversier","pos":"NOM"} ,
		{"word":"traversin","word_nosc":"traversin","lemma":"traversin","pos":"NOM"} ,
		{"word":"traversine","word_nosc":"traversine","lemma":"traversine","pos":"NOM"} ,
		{"word":"traversins","word_nosc":"traversins","lemma":"traversin","pos":"NOM"} ,
		{"word":"traversée","word_nosc":"traversee","lemma":"traversée","pos":"NOM"} ,
		{"word":"traversées","word_nosc":"traversees","lemma":"traversée","pos":"NOM"} ,
		{"word":"travertin","word_nosc":"travertin","lemma":"travertin","pos":"NOM"} ,
		{"word":"travesti","word_nosc":"travesti","lemma":"travesti","pos":"NOM"} ,
		{"word":"travestis","word_nosc":"travestis","lemma":"travesti","pos":"NOM"} ,
		{"word":"travestisme","word_nosc":"travestisme","lemma":"travestisme","pos":"NOM"} ,
		{"word":"travestissement","word_nosc":"travestissement","lemma":"travestissement","pos":"NOM"} ,
		{"word":"travestissements","word_nosc":"travestissements","lemma":"travestissement","pos":"NOM"} ,
		{"word":"travois","word_nosc":"travois","lemma":"travois","pos":"NOM"} ,
		{"word":"travée","word_nosc":"travee","lemma":"travée","pos":"NOM"} ,
		{"word":"travées","word_nosc":"travees","lemma":"travée","pos":"NOM"} ,
		{"word":"trax","word_nosc":"trax","lemma":"trax","pos":"NOM"} ,
		{"word":"trayeuse","word_nosc":"trayeuse","lemma":"trayeur","pos":"NOM"} ,
		{"word":"trayeuses","word_nosc":"trayeuses","lemma":"trayeur","pos":"NOM"} ,
		{"word":"trayon","word_nosc":"trayon","lemma":"trayon","pos":"NOM"} ,
		{"word":"trayons","word_nosc":"trayons","lemma":"trayon","pos":"NOM"} ,
		{"word":"traçabilité","word_nosc":"tracabilite","lemma":"traçabilité","pos":"NOM"} ,
		{"word":"traçage","word_nosc":"tracage","lemma":"traçage","pos":"NOM"} ,
		{"word":"traînage","word_nosc":"trainage","lemma":"traînage","pos":"NOM"} ,
		{"word":"traînard","word_nosc":"trainard","lemma":"traînard","pos":"NOM"} ,
		{"word":"traînarde","word_nosc":"trainarde","lemma":"traînard","pos":"NOM"} ,
		{"word":"traînards","word_nosc":"trainards","lemma":"traînard","pos":"NOM"} ,
		{"word":"traîne","word_nosc":"traine","lemma":"traîne","pos":"NOM"} ,
		{"word":"traîne-misère","word_nosc":"traine-misere","lemma":"traîne-misère","pos":"NOM"} ,
		{"word":"traîne-patins","word_nosc":"traine-patins","lemma":"traîne-patins","pos":"NOM"} ,
		{"word":"traîne-savate","word_nosc":"traine-savate","lemma":"traîne-savate","pos":"NOM"} ,
		{"word":"traîne-savates","word_nosc":"traine-savates","lemma":"traîne-savates","pos":"NOM"} ,
		{"word":"traîne-semelle","word_nosc":"traine-semelle","lemma":"traîne-semelle","pos":"NOM"} ,
		{"word":"traîne-semelles","word_nosc":"traine-semelles","lemma":"traîne-semelles","pos":"NOM"} ,
		{"word":"traîneau","word_nosc":"traineau","lemma":"traîneau","pos":"NOM"} ,
		{"word":"traîneaux","word_nosc":"traineaux","lemma":"traîneau","pos":"NOM"} ,
		{"word":"traînement","word_nosc":"trainement","lemma":"traînement","pos":"NOM"} ,
		{"word":"traînes","word_nosc":"traines","lemma":"traîne","pos":"NOM"} ,
		{"word":"traîneur","word_nosc":"traineur","lemma":"traîneur","pos":"NOM"} ,
		{"word":"traîneurs","word_nosc":"traineurs","lemma":"traîneur","pos":"NOM"} ,
		{"word":"traîneuses","word_nosc":"traineuses","lemma":"traîneur","pos":"NOM"} ,
		{"word":"traîneuses","word_nosc":"traineuses","lemma":"traîneuse","pos":"NOM"} ,
		{"word":"traînier","word_nosc":"trainier","lemma":"traînier","pos":"NOM"} ,
		{"word":"traînée","word_nosc":"trainee","lemma":"traînée","pos":"NOM"} ,
		{"word":"traînées","word_nosc":"trainees","lemma":"traînée","pos":"NOM"} ,
		{"word":"traître","word_nosc":"traitre","lemma":"traître","pos":"NOM"} ,
		{"word":"traîtres","word_nosc":"traitres","lemma":"traître","pos":"NOM"} ,
		{"word":"traîtresse","word_nosc":"traitresse","lemma":"traîtresse","pos":"NOM"} ,
		{"word":"traîtresses","word_nosc":"traitresses","lemma":"traîtresse","pos":"NOM"} ,
		{"word":"traîtrise","word_nosc":"traitrise","lemma":"traîtrise","pos":"NOM"} ,
		{"word":"traîtrises","word_nosc":"traitrises","lemma":"traîtrise","pos":"NOM"} ,
		{"word":"trecento","word_nosc":"trecento","lemma":"trecento","pos":"NOM"} ,
		{"word":"treillage","word_nosc":"treillage","lemma":"treillage","pos":"NOM"} ,
		{"word":"treillages","word_nosc":"treillages","lemma":"treillage","pos":"NOM"} ,
		{"word":"treillard","word_nosc":"treillard","lemma":"treillard","pos":"NOM"} ,
		{"word":"treille","word_nosc":"treille","lemma":"treille","pos":"NOM"} ,
		{"word":"treilles","word_nosc":"treilles","lemma":"treille","pos":"NOM"} ,
		{"word":"treillis","word_nosc":"treillis","lemma":"treillis","pos":"NOM"} ,
		{"word":"treizaine","word_nosc":"treizaine","lemma":"treizain","pos":"NOM"} ,
		{"word":"treizième","word_nosc":"treizieme","lemma":"treizième","pos":"NOM"} ,
		{"word":"trek","word_nosc":"trek","lemma":"trek","pos":"NOM"} ,
		{"word":"trekkeur","word_nosc":"trekkeur","lemma":"trekkeur","pos":"NOM"} ,
		{"word":"trekking","word_nosc":"trekking","lemma":"trekking","pos":"NOM"} ,
		{"word":"tremble","word_nosc":"tremble","lemma":"tremble","pos":"NOM"} ,
		{"word":"tremblement","word_nosc":"tremblement","lemma":"tremblement","pos":"NOM"} ,
		{"word":"tremblements","word_nosc":"tremblements","lemma":"tremblement","pos":"NOM"} ,
		{"word":"trembles","word_nosc":"trembles","lemma":"tremble","pos":"NOM"} ,
		{"word":"trembleur","word_nosc":"trembleur","lemma":"trembleur","pos":"NOM"} ,
		{"word":"tremblote","word_nosc":"tremblote","lemma":"tremblote","pos":"NOM"} ,
		{"word":"tremblotement","word_nosc":"tremblotement","lemma":"tremblotement","pos":"NOM"} ,
		{"word":"tremblotements","word_nosc":"tremblotements","lemma":"tremblotement","pos":"NOM"} ,
		{"word":"tremblé","word_nosc":"tremble","lemma":"tremblé","pos":"NOM"} ,
		{"word":"tremolos","word_nosc":"tremolos","lemma":"tremolo","pos":"NOM"} ,
		{"word":"trempage","word_nosc":"trempage","lemma":"trempage","pos":"NOM"} ,
		{"word":"trempages","word_nosc":"trempages","lemma":"trempage","pos":"NOM"} ,
		{"word":"trempe","word_nosc":"trempe","lemma":"trempe","pos":"NOM"} ,
		{"word":"trempes","word_nosc":"trempes","lemma":"trempe","pos":"NOM"} ,
		{"word":"trempette","word_nosc":"trempette","lemma":"trempette","pos":"NOM"} ,
		{"word":"trempeur","word_nosc":"trempeur","lemma":"trempeur","pos":"NOM"} ,
		{"word":"tremplin","word_nosc":"tremplin","lemma":"tremplin","pos":"NOM"} ,
		{"word":"trempoline","word_nosc":"trempoline","lemma":"trempoline","pos":"NOM"} ,
		{"word":"trench","word_nosc":"trench","lemma":"trench","pos":"NOM"} ,
		{"word":"trench-coat","word_nosc":"trench-coat","lemma":"trench-coat","pos":"NOM"} ,
		{"word":"trench-coats","word_nosc":"trench-coats","lemma":"trench-coat","pos":"NOM"} ,
		{"word":"trentaine","word_nosc":"trentaine","lemma":"trentaine","pos":"NOM"} ,
		{"word":"trente","word_nosc":"trente","lemma":"trente","pos":"NOM"} ,
		{"word":"trente-et-quarante","word_nosc":"trente-et-quarante","lemma":"trente-et-quarante","pos":"NOM"} ,
		{"word":"trente-et-un","word_nosc":"trente-et-un","lemma":"trente-et-un","pos":"NOM"} ,
		{"word":"tressaillement","word_nosc":"tressaillement","lemma":"tressaillement","pos":"NOM"} ,
		{"word":"tressaillements","word_nosc":"tressaillements","lemma":"tressaillement","pos":"NOM"} ,
		{"word":"tressautement","word_nosc":"tressautement","lemma":"tressautement","pos":"NOM"} ,
		{"word":"tressautements","word_nosc":"tressautements","lemma":"tressautement","pos":"NOM"} ,
		{"word":"tresse","word_nosc":"tresse","lemma":"tresse","pos":"NOM"} ,
		{"word":"tresses","word_nosc":"tresses","lemma":"tresse","pos":"NOM"} ,
		{"word":"tresseurs","word_nosc":"tresseurs","lemma":"tresseur","pos":"NOM"} ,
		{"word":"treuil","word_nosc":"treuil","lemma":"treuil","pos":"NOM"} ,
		{"word":"treuils","word_nosc":"treuils","lemma":"treuil","pos":"NOM"} ,
		{"word":"tri","word_nosc":"tri","lemma":"tri","pos":"NOM"} ,
		{"word":"triade","word_nosc":"triade","lemma":"triade","pos":"NOM"} ,
		{"word":"triades","word_nosc":"triades","lemma":"triade","pos":"NOM"} ,
		{"word":"triage","word_nosc":"triage","lemma":"triage","pos":"NOM"} ,
		{"word":"trial","word_nosc":"trial","lemma":"trial","pos":"NOM"} ,
		{"word":"triangle","word_nosc":"triangle","lemma":"triangle","pos":"NOM"} ,
		{"word":"triangles","word_nosc":"triangles","lemma":"triangle","pos":"NOM"} ,
		{"word":"triangulaire","word_nosc":"triangulaire","lemma":"triangulaire","pos":"NOM"} ,
		{"word":"triangulaires","word_nosc":"triangulaires","lemma":"triangulaire","pos":"NOM"} ,
		{"word":"triangulation","word_nosc":"triangulation","lemma":"triangulation","pos":"NOM"} ,
		{"word":"trianon","word_nosc":"trianon","lemma":"trianon","pos":"NOM"} ,
		{"word":"trias","word_nosc":"trias","lemma":"trias","pos":"NOM"} ,
		{"word":"triathlon","word_nosc":"triathlon","lemma":"triathlon","pos":"NOM"} ,
		{"word":"triathlète","word_nosc":"triathlete","lemma":"triathlète","pos":"NOM"} ,
		{"word":"tribart","word_nosc":"tribart","lemma":"tribart","pos":"NOM"} ,
		{"word":"tribord","word_nosc":"tribord","lemma":"tribord","pos":"NOM"} ,
		{"word":"tribu","word_nosc":"tribu","lemma":"tribu","pos":"NOM"} ,
		{"word":"tribulation","word_nosc":"tribulation","lemma":"tribulation","pos":"NOM"} ,
		{"word":"tribulations","word_nosc":"tribulations","lemma":"tribulation","pos":"NOM"} ,
		{"word":"tribun","word_nosc":"tribun","lemma":"tribun","pos":"NOM"} ,
		{"word":"tribunal","word_nosc":"tribunal","lemma":"tribunal","pos":"NOM"} ,
		{"word":"tribunat","word_nosc":"tribunat","lemma":"tribunat","pos":"NOM"} ,
		{"word":"tribunaux","word_nosc":"tribunaux","lemma":"tribunal","pos":"NOM"} ,
		{"word":"tribune","word_nosc":"tribune","lemma":"tribune","pos":"NOM"} ,
		{"word":"tribunes","word_nosc":"tribunes","lemma":"tribune","pos":"NOM"} ,
		{"word":"tribuns","word_nosc":"tribuns","lemma":"tribun","pos":"NOM"} ,
		{"word":"tribus","word_nosc":"tribus","lemma":"tribu","pos":"NOM"} ,
		{"word":"tribut","word_nosc":"tribut","lemma":"tribut","pos":"NOM"} ,
		{"word":"tributs","word_nosc":"tributs","lemma":"tribut","pos":"NOM"} ,
		{"word":"tric","word_nosc":"tric","lemma":"tric","pos":"NOM"} ,
		{"word":"tricard","word_nosc":"tricard","lemma":"tricard","pos":"NOM"} ,
		{"word":"tricentenaire","word_nosc":"tricentenaire","lemma":"tricentenaire","pos":"NOM"} ,
		{"word":"triceps","word_nosc":"triceps","lemma":"triceps","pos":"NOM"} ,
		{"word":"triche","word_nosc":"triche","lemma":"triche","pos":"NOM"} ,
		{"word":"tricherie","word_nosc":"tricherie","lemma":"tricherie","pos":"NOM"} ,
		{"word":"tricheries","word_nosc":"tricheries","lemma":"tricherie","pos":"NOM"} ,
		{"word":"triches","word_nosc":"triches","lemma":"triche","pos":"NOM"} ,
		{"word":"tricheur","word_nosc":"tricheur","lemma":"tricheur","pos":"NOM"} ,
		{"word":"tricheurs","word_nosc":"tricheurs","lemma":"tricheur","pos":"NOM"} ,
		{"word":"tricheuse","word_nosc":"tricheuse","lemma":"tricheur","pos":"NOM"} ,
		{"word":"tricheuses","word_nosc":"tricheuses","lemma":"tricheur","pos":"NOM"} ,
		{"word":"trichinose","word_nosc":"trichinose","lemma":"trichinose","pos":"NOM"} ,
		{"word":"trichlorure","word_nosc":"trichlorure","lemma":"trichlorure","pos":"NOM"} ,
		{"word":"trichloréthylène","word_nosc":"trichlorethylene","lemma":"trichloréthylène","pos":"NOM"} ,
		{"word":"trichophyties","word_nosc":"trichophyties","lemma":"trichophytie","pos":"NOM"} ,
		{"word":"trick","word_nosc":"trick","lemma":"trick","pos":"NOM"} ,
		{"word":"tricoises","word_nosc":"tricoises","lemma":"tricoises","pos":"NOM"} ,
		{"word":"tricorne","word_nosc":"tricorne","lemma":"tricorne","pos":"NOM"} ,
		{"word":"tricornes","word_nosc":"tricornes","lemma":"tricorne","pos":"NOM"} ,
		{"word":"tricot","word_nosc":"tricot","lemma":"tricot","pos":"NOM"} ,
		{"word":"tricotage","word_nosc":"tricotage","lemma":"tricotage","pos":"NOM"} ,
		{"word":"tricotages","word_nosc":"tricotages","lemma":"tricotage","pos":"NOM"} ,
		{"word":"tricoteur","word_nosc":"tricoteur","lemma":"tricoteur","pos":"NOM"} ,
		{"word":"tricoteuse","word_nosc":"tricoteuse","lemma":"tricoteur","pos":"NOM"} ,
		{"word":"tricoteuses","word_nosc":"tricoteuses","lemma":"tricoteur","pos":"NOM"} ,
		{"word":"tricots","word_nosc":"tricots","lemma":"tricot","pos":"NOM"} ,
		{"word":"trictrac","word_nosc":"trictrac","lemma":"trictrac","pos":"NOM"} ,
		{"word":"tricycle","word_nosc":"tricycle","lemma":"tricycle","pos":"NOM"} ,
		{"word":"tricycles","word_nosc":"tricycles","lemma":"tricycle","pos":"NOM"} ,
		{"word":"tricycliste","word_nosc":"tricycliste","lemma":"tricycliste","pos":"NOM"} ,
		{"word":"tricératops","word_nosc":"triceratops","lemma":"tricératops","pos":"NOM"} ,
		{"word":"trident","word_nosc":"trident","lemma":"trident","pos":"NOM"} ,
		{"word":"tridents","word_nosc":"tridents","lemma":"trident","pos":"NOM"} ,
		{"word":"tridi","word_nosc":"tridi","lemma":"tridi","pos":"NOM"} ,
		{"word":"trieur","word_nosc":"trieur","lemma":"trieur","pos":"NOM"} ,
		{"word":"trieurs","word_nosc":"trieurs","lemma":"trieur","pos":"NOM"} ,
		{"word":"trieuse","word_nosc":"trieuse","lemma":"trieur","pos":"NOM"} ,
		{"word":"trieuses","word_nosc":"trieuses","lemma":"trieur","pos":"NOM"} ,
		{"word":"trifolium","word_nosc":"trifolium","lemma":"trifolium","pos":"NOM"} ,
		{"word":"triforium","word_nosc":"triforium","lemma":"triforium","pos":"NOM"} ,
		{"word":"trigger","word_nosc":"trigger","lemma":"trigger","pos":"NOM"} ,
		{"word":"trigles","word_nosc":"trigles","lemma":"trigle","pos":"NOM"} ,
		{"word":"triglycéride","word_nosc":"triglyceride","lemma":"triglycéride","pos":"NOM"} ,
		{"word":"triglycérides","word_nosc":"triglycerides","lemma":"triglycéride","pos":"NOM"} ,
		{"word":"trigonométrie","word_nosc":"trigonometrie","lemma":"trigonométrie","pos":"NOM"} ,
		{"word":"trigrammes","word_nosc":"trigrammes","lemma":"trigramme","pos":"NOM"} ,
		{"word":"trille","word_nosc":"trille","lemma":"trille","pos":"NOM"} ,
		{"word":"trilles","word_nosc":"trilles","lemma":"trille","pos":"NOM"} ,
		{"word":"trillion","word_nosc":"trillion","lemma":"trillion","pos":"NOM"} ,
		{"word":"trillions","word_nosc":"trillions","lemma":"trillion","pos":"NOM"} ,
		{"word":"trilobite","word_nosc":"trilobite","lemma":"trilobite","pos":"NOM"} ,
		{"word":"trilogie","word_nosc":"trilogie","lemma":"trilogie","pos":"NOM"} ,
		{"word":"trimard","word_nosc":"trimard","lemma":"trimard","pos":"NOM"} ,
		{"word":"trimardeur","word_nosc":"trimardeur","lemma":"trimardeur","pos":"NOM"} ,
		{"word":"trimardeurs","word_nosc":"trimardeurs","lemma":"trimardeur","pos":"NOM"} ,
		{"word":"trimards","word_nosc":"trimards","lemma":"trimard","pos":"NOM"} ,
		{"word":"trimballage","word_nosc":"trimballage","lemma":"trimballage","pos":"NOM"} ,
		{"word":"trimestre","word_nosc":"trimestre","lemma":"trimestre","pos":"NOM"} ,
		{"word":"trimestres","word_nosc":"trimestres","lemma":"trimestre","pos":"NOM"} ,
		{"word":"trimeur","word_nosc":"trimeur","lemma":"trimeur","pos":"NOM"} ,
		{"word":"trimeurs","word_nosc":"trimeurs","lemma":"trimeur","pos":"NOM"} ,
		{"word":"trimmer","word_nosc":"trimmer","lemma":"trimmer","pos":"NOM"} ,
		{"word":"trinacrie","word_nosc":"trinacrie","lemma":"trinacrie","pos":"NOM"} ,
		{"word":"tringle","word_nosc":"tringle","lemma":"tringle","pos":"NOM"} ,
		{"word":"tringles","word_nosc":"tringles","lemma":"tringle","pos":"NOM"} ,
		{"word":"tringlette","word_nosc":"tringlette","lemma":"tringlette","pos":"NOM"} ,
		{"word":"tringlot","word_nosc":"tringlot","lemma":"tringlot","pos":"NOM"} ,
		{"word":"tringlots","word_nosc":"tringlots","lemma":"tringlot","pos":"NOM"} ,
		{"word":"trinidadienne","word_nosc":"trinidadienne","lemma":"trinidadienne","pos":"NOM"} ,
		{"word":"trinitrine","word_nosc":"trinitrine","lemma":"trinitrine","pos":"NOM"} ,
		{"word":"trinité","word_nosc":"trinite","lemma":"trinité","pos":"NOM"} ,
		{"word":"trinquette","word_nosc":"trinquette","lemma":"trinquette","pos":"NOM"} ,
		{"word":"trinôme","word_nosc":"trinome","lemma":"trinôme","pos":"NOM"} ,
		{"word":"trio","word_nosc":"trio","lemma":"trio","pos":"NOM"} ,
		{"word":"triode","word_nosc":"triode","lemma":"triode","pos":"NOM"} ,
		{"word":"triolet","word_nosc":"triolet","lemma":"triolet","pos":"NOM"} ,
		{"word":"triolets","word_nosc":"triolets","lemma":"triolet","pos":"NOM"} ,
		{"word":"triolisme","word_nosc":"triolisme","lemma":"triolisme","pos":"NOM"} ,
		{"word":"triomphalisme","word_nosc":"triomphalisme","lemma":"triomphalisme","pos":"NOM"} ,
		{"word":"triomphateur","word_nosc":"triomphateur","lemma":"triomphateur","pos":"NOM"} ,
		{"word":"triomphateurs","word_nosc":"triomphateurs","lemma":"triomphateur","pos":"NOM"} ,
		{"word":"triomphatrice","word_nosc":"triomphatrice","lemma":"triomphateur","pos":"NOM"} ,
		{"word":"triomphatrices","word_nosc":"triomphatrices","lemma":"triomphateur","pos":"NOM"} ,
		{"word":"triomphe","word_nosc":"triomphe","lemma":"triomphe","pos":"NOM"} ,
		{"word":"triomphes","word_nosc":"triomphes","lemma":"triomphe","pos":"NOM"} ,
		{"word":"trional","word_nosc":"trional","lemma":"trional","pos":"NOM"} ,
		{"word":"trionix","word_nosc":"trionix","lemma":"trionix","pos":"NOM"} ,
		{"word":"trios","word_nosc":"trios","lemma":"trio","pos":"NOM"} ,
		{"word":"trip","word_nosc":"trip","lemma":"trip","pos":"NOM"} ,
		{"word":"tripaille","word_nosc":"tripaille","lemma":"tripaille","pos":"NOM"} ,
		{"word":"tripailles","word_nosc":"tripailles","lemma":"tripaille","pos":"NOM"} ,
		{"word":"tripang","word_nosc":"tripang","lemma":"tripang","pos":"NOM"} ,
		{"word":"tripangs","word_nosc":"tripangs","lemma":"tripang","pos":"NOM"} ,
		{"word":"tripatouillage","word_nosc":"tripatouillage","lemma":"tripatouillage","pos":"NOM"} ,
		{"word":"tripatouillages","word_nosc":"tripatouillages","lemma":"tripatouillage","pos":"NOM"} ,
		{"word":"tripe","word_nosc":"tripe","lemma":"tripe","pos":"NOM"} ,
		{"word":"triperie","word_nosc":"triperie","lemma":"triperie","pos":"NOM"} ,
		{"word":"triperies","word_nosc":"triperies","lemma":"triperie","pos":"NOM"} ,
		{"word":"tripes","word_nosc":"tripes","lemma":"tripe","pos":"NOM"} ,
		{"word":"tripette","word_nosc":"tripette","lemma":"tripette","pos":"NOM"} ,
		{"word":"triphosphate","word_nosc":"triphosphate","lemma":"triphosphate","pos":"NOM"} ,
		{"word":"tripier","word_nosc":"tripier","lemma":"tripier","pos":"NOM"} ,
		{"word":"tripiers","word_nosc":"tripiers","lemma":"tripier","pos":"NOM"} ,
		{"word":"tripière","word_nosc":"tripiere","lemma":"tripier","pos":"NOM"} ,
		{"word":"triple","word_nosc":"triple","lemma":"triple","pos":"NOM"} ,
		{"word":"triplement","word_nosc":"triplement","lemma":"triplement","pos":"NOM"} ,
		{"word":"triples","word_nosc":"triples","lemma":"triple","pos":"NOM"} ,
		{"word":"triplets","word_nosc":"triplets","lemma":"triplet","pos":"NOM"} ,
		{"word":"triplette","word_nosc":"triplette","lemma":"triplette","pos":"NOM"} ,
		{"word":"triplex","word_nosc":"triplex","lemma":"triplex","pos":"NOM"} ,
		{"word":"triplice","word_nosc":"triplice","lemma":"triplice","pos":"NOM"} ,
		{"word":"triplé","word_nosc":"triple","lemma":"triplé","pos":"NOM"} ,
		{"word":"triplées","word_nosc":"triplees","lemma":"triplé","pos":"NOM"} ,
		{"word":"triplés","word_nosc":"triples","lemma":"triplé","pos":"NOM"} ,
		{"word":"tripode","word_nosc":"tripode","lemma":"tripode","pos":"NOM"} ,
		{"word":"tripoli","word_nosc":"tripoli","lemma":"tripoli","pos":"NOM"} ,
		{"word":"triporteur","word_nosc":"triporteur","lemma":"triporteur","pos":"NOM"} ,
		{"word":"triporteurs","word_nosc":"triporteurs","lemma":"triporteur","pos":"NOM"} ,
		{"word":"tripot","word_nosc":"tripot","lemma":"tripot","pos":"NOM"} ,
		{"word":"tripotage","word_nosc":"tripotage","lemma":"tripotage","pos":"NOM"} ,
		{"word":"tripotages","word_nosc":"tripotages","lemma":"tripotage","pos":"NOM"} ,
		{"word":"tripoteur","word_nosc":"tripoteur","lemma":"tripoteur","pos":"NOM"} ,
		{"word":"tripoteuse","word_nosc":"tripoteuse","lemma":"tripoteur","pos":"NOM"} ,
		{"word":"tripots","word_nosc":"tripots","lemma":"tripot","pos":"NOM"} ,
		{"word":"tripotée","word_nosc":"tripotee","lemma":"tripotée","pos":"NOM"} ,
		{"word":"tripoux","word_nosc":"tripoux","lemma":"tripoux","pos":"NOM"} ,
		{"word":"trips","word_nosc":"trips","lemma":"trip","pos":"NOM"} ,
		{"word":"triptyque","word_nosc":"triptyque","lemma":"triptyque","pos":"NOM"} ,
		{"word":"triptyques","word_nosc":"triptyques","lemma":"triptyque","pos":"NOM"} ,
		{"word":"trique","word_nosc":"trique","lemma":"trique","pos":"NOM"} ,
		{"word":"triqueballe","word_nosc":"triqueballe","lemma":"triqueballe","pos":"NOM"} ,
		{"word":"triques","word_nosc":"triques","lemma":"trique","pos":"NOM"} ,
		{"word":"trirème","word_nosc":"trireme","lemma":"trirème","pos":"NOM"} ,
		{"word":"trirèmes","word_nosc":"triremes","lemma":"trirème","pos":"NOM"} ,
		{"word":"tris","word_nosc":"tris","lemma":"tri","pos":"NOM"} ,
		{"word":"trisaïeul","word_nosc":"trisaieul","lemma":"trisaïeul","pos":"NOM"} ,
		{"word":"trisaïeule","word_nosc":"trisaieule","lemma":"trisaïeul","pos":"NOM"} ,
		{"word":"trisaïeuls","word_nosc":"trisaieuls","lemma":"trisaïeul","pos":"NOM"} ,
		{"word":"trismus","word_nosc":"trismus","lemma":"trismus","pos":"NOM"} ,
		{"word":"trisomie","word_nosc":"trisomie","lemma":"trisomie","pos":"NOM"} ,
		{"word":"tristesse","word_nosc":"tristesse","lemma":"tristesse","pos":"NOM"} ,
		{"word":"tristesses","word_nosc":"tristesses","lemma":"tristesse","pos":"NOM"} ,
		{"word":"trithéisme","word_nosc":"tritheisme","lemma":"trithéisme","pos":"NOM"} ,
		{"word":"trithérapie","word_nosc":"tritherapie","lemma":"trithérapie","pos":"NOM"} ,
		{"word":"tritium","word_nosc":"tritium","lemma":"tritium","pos":"NOM"} ,
		{"word":"triton","word_nosc":"triton","lemma":"triton","pos":"NOM"} ,
		{"word":"tritons","word_nosc":"tritons","lemma":"triton","pos":"NOM"} ,
		{"word":"trituration","word_nosc":"trituration","lemma":"trituration","pos":"NOM"} ,
		{"word":"triturations","word_nosc":"triturations","lemma":"trituration","pos":"NOM"} ,
		{"word":"triumvir","word_nosc":"triumvir","lemma":"triumvir","pos":"NOM"} ,
		{"word":"triumvirat","word_nosc":"triumvirat","lemma":"triumvirat","pos":"NOM"} ,
		{"word":"trivialité","word_nosc":"trivialite","lemma":"trivialité","pos":"NOM"} ,
		{"word":"trivialités","word_nosc":"trivialites","lemma":"trivialité","pos":"NOM"} ,
		{"word":"trivium","word_nosc":"trivium","lemma":"trivium","pos":"NOM"} ,
		{"word":"trobriandais","word_nosc":"trobriandais","lemma":"trobriandais","pos":"NOM"} ,
		{"word":"troc","word_nosc":"troc","lemma":"troc","pos":"NOM"} ,
		{"word":"trocart","word_nosc":"trocart","lemma":"trocart","pos":"NOM"} ,
		{"word":"trochanter","word_nosc":"trochanter","lemma":"trochanter","pos":"NOM"} ,
		{"word":"troche","word_nosc":"troche","lemma":"troche","pos":"NOM"} ,
		{"word":"trochures","word_nosc":"trochures","lemma":"trochure","pos":"NOM"} ,
		{"word":"trochée","word_nosc":"trochee","lemma":"trochée","pos":"NOM"} ,
		{"word":"trocs","word_nosc":"trocs","lemma":"troc","pos":"NOM"} ,
		{"word":"troglodyte","word_nosc":"troglodyte","lemma":"troglodyte","pos":"NOM"} ,
		{"word":"troglodytes","word_nosc":"troglodytes","lemma":"troglodyte","pos":"NOM"} ,
		{"word":"trogne","word_nosc":"trogne","lemma":"trogne","pos":"NOM"} ,
		{"word":"trognes","word_nosc":"trognes","lemma":"trogne","pos":"NOM"} ,
		{"word":"trognon","word_nosc":"trognon","lemma":"trognon","pos":"NOM"} ,
		{"word":"trognons","word_nosc":"trognons","lemma":"trognon","pos":"NOM"} ,
		{"word":"trois-deux","word_nosc":"trois-deux","lemma":"trois-deux","pos":"NOM"} ,
		{"word":"trois-huit","word_nosc":"trois-huit","lemma":"trois-huit","pos":"NOM"} ,
		{"word":"trois-mâts","word_nosc":"trois-mats","lemma":"trois-mâts","pos":"NOM"} ,
		{"word":"trois-points","word_nosc":"trois-points","lemma":"trois-points","pos":"NOM"} ,
		{"word":"trois-quarts","word_nosc":"trois-quarts","lemma":"trois-quarts","pos":"NOM"} ,
		{"word":"trois-quatre","word_nosc":"trois-quatre","lemma":"trois-quatre","pos":"NOM"} ,
		{"word":"trois-six","word_nosc":"trois-six","lemma":"trois-six","pos":"NOM"} ,
		{"word":"troisième","word_nosc":"troisieme","lemma":"troisième","pos":"NOM"} ,
		{"word":"troisièmes","word_nosc":"troisiemes","lemma":"troisième","pos":"NOM"} ,
		{"word":"troll","word_nosc":"troll","lemma":"troll","pos":"NOM"} ,
		{"word":"trolle","word_nosc":"trolle","lemma":"trolle","pos":"NOM"} ,
		{"word":"trolley","word_nosc":"trolley","lemma":"trolley","pos":"NOM"} ,
		{"word":"trolleybus","word_nosc":"trolleybus","lemma":"trolleybus","pos":"NOM"} ,
		{"word":"trolleys","word_nosc":"trolleys","lemma":"trolley","pos":"NOM"} ,
		{"word":"trolls","word_nosc":"trolls","lemma":"troll","pos":"NOM"} ,
		{"word":"trombe","word_nosc":"trombe","lemma":"trombe","pos":"NOM"} ,
		{"word":"trombes","word_nosc":"trombes","lemma":"trombe","pos":"NOM"} ,
		{"word":"trombine","word_nosc":"trombine","lemma":"trombine","pos":"NOM"} ,
		{"word":"trombines","word_nosc":"trombines","lemma":"trombine","pos":"NOM"} ,
		{"word":"trombinoscope","word_nosc":"trombinoscope","lemma":"trombinoscope","pos":"NOM"} ,
		{"word":"tromblon","word_nosc":"tromblon","lemma":"tromblon","pos":"NOM"} ,
		{"word":"tromblons","word_nosc":"tromblons","lemma":"tromblon","pos":"NOM"} ,
		{"word":"trombone","word_nosc":"trombone","lemma":"trombone","pos":"NOM"} ,
		{"word":"trombones","word_nosc":"trombones","lemma":"trombone","pos":"NOM"} ,
		{"word":"tromboniste","word_nosc":"tromboniste","lemma":"tromboniste","pos":"NOM"} ,
		{"word":"trommel","word_nosc":"trommel","lemma":"trommel","pos":"NOM"} ,
		{"word":"trompe","word_nosc":"trompe","lemma":"trompe","pos":"NOM"} ,
		{"word":"trompe-l'oeil","word_nosc":"trompe-l'oeil","lemma":"trompe-l'oeil","pos":"NOM"} ,
		{"word":"tromperie","word_nosc":"tromperie","lemma":"tromperie","pos":"NOM"} ,
		{"word":"tromperies","word_nosc":"tromperies","lemma":"tromperie","pos":"NOM"} ,
		{"word":"trompes","word_nosc":"trompes","lemma":"trompe","pos":"NOM"} ,
		{"word":"trompette","word_nosc":"trompette","lemma":"trompette","pos":"NOM"} ,
		{"word":"trompettes","word_nosc":"trompettes","lemma":"trompette","pos":"NOM"} ,
		{"word":"trompettiste","word_nosc":"trompettiste","lemma":"trompettiste","pos":"NOM"} ,
		{"word":"trompettistes","word_nosc":"trompettistes","lemma":"trompettiste","pos":"NOM"} ,
		{"word":"trompeur","word_nosc":"trompeur","lemma":"trompeur","pos":"NOM"} ,
		{"word":"trompeurs","word_nosc":"trompeurs","lemma":"trompeur","pos":"NOM"} ,
		{"word":"tronc","word_nosc":"tronc","lemma":"tronc","pos":"NOM"} ,
		{"word":"tronche","word_nosc":"tronche","lemma":"tronche","pos":"NOM"} ,
		{"word":"tronches","word_nosc":"tronches","lemma":"tronche","pos":"NOM"} ,
		{"word":"troncs","word_nosc":"troncs","lemma":"tronc","pos":"NOM"} ,
		{"word":"tronçon","word_nosc":"troncon","lemma":"tronçon","pos":"NOM"} ,
		{"word":"tronçonneuse","word_nosc":"tronconneuse","lemma":"tronçonneur","pos":"NOM"} ,
		{"word":"tronçonneuses","word_nosc":"tronconneuses","lemma":"tronçonneur","pos":"NOM"} ,
		{"word":"tronçons","word_nosc":"troncons","lemma":"tronçon","pos":"NOM"} ,
		{"word":"trop-perçu","word_nosc":"trop-percu","lemma":"trop-perçu","pos":"NOM"} ,
		{"word":"trop-plein","word_nosc":"trop-plein","lemma":"trop-plein","pos":"NOM"} ,
		{"word":"trop-pleins","word_nosc":"trop-pleins","lemma":"trop-plein","pos":"NOM"} ,
		{"word":"trope","word_nosc":"trope","lemma":"trope","pos":"NOM"} ,
		{"word":"trophée","word_nosc":"trophee","lemma":"trophée","pos":"NOM"} ,
		{"word":"trophées","word_nosc":"trophees","lemma":"trophée","pos":"NOM"} ,
		{"word":"tropique","word_nosc":"tropique","lemma":"tropique","pos":"NOM"} ,
		{"word":"tropiques","word_nosc":"tropiques","lemma":"tropique","pos":"NOM"} ,
		{"word":"tropisme","word_nosc":"tropisme","lemma":"tropisme","pos":"NOM"} ,
		{"word":"tropismes","word_nosc":"tropismes","lemma":"tropisme","pos":"NOM"} ,
		{"word":"troposphère","word_nosc":"troposphere","lemma":"troposphère","pos":"NOM"} ,
		{"word":"tropézienne","word_nosc":"tropezienne","lemma":"tropézien","pos":"NOM"} ,
		{"word":"troquet","word_nosc":"troquet","lemma":"troquet","pos":"NOM"} ,
		{"word":"troquets","word_nosc":"troquets","lemma":"troquet","pos":"NOM"} ,
		{"word":"trot","word_nosc":"trot","lemma":"trot","pos":"NOM"} ,
		{"word":"trots","word_nosc":"trots","lemma":"trot","pos":"NOM"} ,
		{"word":"trotskisme","word_nosc":"trotskisme","lemma":"trotskisme","pos":"NOM"} ,
		{"word":"trotskiste","word_nosc":"trotskiste","lemma":"trotskiste","pos":"NOM"} ,
		{"word":"trotskistes","word_nosc":"trotskistes","lemma":"trotskiste","pos":"NOM"} ,
		{"word":"trotskystes","word_nosc":"trotskystes","lemma":"trotskyste","pos":"NOM"} ,
		{"word":"trotte","word_nosc":"trotte","lemma":"trotte","pos":"NOM"} ,
		{"word":"trottes","word_nosc":"trottes","lemma":"trotte","pos":"NOM"} ,
		{"word":"trotteur","word_nosc":"trotteur","lemma":"trotteur","pos":"NOM"} ,
		{"word":"trotteurs","word_nosc":"trotteurs","lemma":"trotteur","pos":"NOM"} ,
		{"word":"trotteuse","word_nosc":"trotteuse","lemma":"trotteur","pos":"NOM"} ,
		{"word":"trotteuses","word_nosc":"trotteuses","lemma":"trotteur","pos":"NOM"} ,
		{"word":"trottignolles","word_nosc":"trottignolles","lemma":"trottignolle","pos":"NOM"} ,
		{"word":"trottin","word_nosc":"trottin","lemma":"trottin","pos":"NOM"} ,
		{"word":"trottinement","word_nosc":"trottinement","lemma":"trottinement","pos":"NOM"} ,
		{"word":"trottinements","word_nosc":"trottinements","lemma":"trottinement","pos":"NOM"} ,
		{"word":"trottinette","word_nosc":"trottinette","lemma":"trottinette","pos":"NOM"} ,
		{"word":"trottinettes","word_nosc":"trottinettes","lemma":"trottinette","pos":"NOM"} ,
		{"word":"trottins","word_nosc":"trottins","lemma":"trottin","pos":"NOM"} ,
		{"word":"trottoir","word_nosc":"trottoir","lemma":"trottoir","pos":"NOM"} ,
		{"word":"trottoirs","word_nosc":"trottoirs","lemma":"trottoir","pos":"NOM"} ,
		{"word":"trou","word_nosc":"trou","lemma":"trou","pos":"NOM"} ,
		{"word":"trou-du-cul","word_nosc":"trou-du-cul","lemma":"trou-du-cul","pos":"NOM"} ,
		{"word":"trou-trou","word_nosc":"trou-trou","lemma":"trou-trou","pos":"NOM"} ,
		{"word":"trou-trous","word_nosc":"trou-trous","lemma":"trou-trou","pos":"NOM"} ,
		{"word":"troubades","word_nosc":"troubades","lemma":"troubade","pos":"NOM"} ,
		{"word":"troubadour","word_nosc":"troubadour","lemma":"troubadour","pos":"NOM"} ,
		{"word":"troubadours","word_nosc":"troubadours","lemma":"troubadour","pos":"NOM"} ,
		{"word":"trouble","word_nosc":"trouble","lemma":"trouble","pos":"NOM"} ,
		{"word":"trouble-fêtes","word_nosc":"trouble-fetes","lemma":"trouble-fête","pos":"NOM"} ,
		{"word":"troubles","word_nosc":"troubles","lemma":"trouble","pos":"NOM"} ,
		{"word":"trouduc","word_nosc":"trouduc","lemma":"trouduc","pos":"NOM"} ,
		{"word":"trouducuteries","word_nosc":"trouducuteries","lemma":"trouducuterie","pos":"NOM"} ,
		{"word":"troufignard","word_nosc":"troufignard","lemma":"troufignard","pos":"NOM"} ,
		{"word":"troufignon","word_nosc":"troufignon","lemma":"troufignon","pos":"NOM"} ,
		{"word":"troufignons","word_nosc":"troufignons","lemma":"troufignon","pos":"NOM"} ,
		{"word":"troufion","word_nosc":"troufion","lemma":"troufion","pos":"NOM"} ,
		{"word":"troufions","word_nosc":"troufions","lemma":"troufion","pos":"NOM"} ,
		{"word":"trouillard","word_nosc":"trouillard","lemma":"trouillard","pos":"NOM"} ,
		{"word":"trouillarde","word_nosc":"trouillarde","lemma":"trouillard","pos":"NOM"} ,
		{"word":"trouillards","word_nosc":"trouillards","lemma":"trouillard","pos":"NOM"} ,
		{"word":"trouille","word_nosc":"trouille","lemma":"trouille","pos":"NOM"} ,
		{"word":"trouilles","word_nosc":"trouilles","lemma":"trouille","pos":"NOM"} ,
		{"word":"trouillomètre","word_nosc":"trouillometre","lemma":"trouillomètre","pos":"NOM"} ,
		{"word":"troupe","word_nosc":"troupe","lemma":"troupe","pos":"NOM"} ,
		{"word":"troupeau","word_nosc":"troupeau","lemma":"troupeau","pos":"NOM"} ,
		{"word":"troupeaux","word_nosc":"troupeaux","lemma":"troupeau","pos":"NOM"} ,
		{"word":"troupes","word_nosc":"troupes","lemma":"troupe","pos":"NOM"} ,
		{"word":"troupier","word_nosc":"troupier","lemma":"troupier","pos":"NOM"} ,
		{"word":"troupiers","word_nosc":"troupiers","lemma":"troupier","pos":"NOM"} ,
		{"word":"trous","word_nosc":"trous","lemma":"trou","pos":"NOM"} ,
		{"word":"trous-du-cul","word_nosc":"trous-du-cul","lemma":"trou-du-cul","pos":"NOM"} ,
		{"word":"trousse","word_nosc":"trousse","lemma":"trousse","pos":"NOM"} ,
		{"word":"trousseau","word_nosc":"trousseau","lemma":"trousseau","pos":"NOM"} ,
		{"word":"trousseaux","word_nosc":"trousseaux","lemma":"trousseau","pos":"NOM"} ,
		{"word":"troussequin","word_nosc":"troussequin","lemma":"troussequin","pos":"NOM"} ,
		{"word":"trousses","word_nosc":"trousses","lemma":"trousse","pos":"NOM"} ,
		{"word":"trousseur","word_nosc":"trousseur","lemma":"trousseur","pos":"NOM"} ,
		{"word":"trouvaille","word_nosc":"trouvaille","lemma":"trouvaille","pos":"NOM"} ,
		{"word":"trouvailles","word_nosc":"trouvailles","lemma":"trouvaille","pos":"NOM"} ,
		{"word":"trouveur","word_nosc":"trouveur","lemma":"trouveur","pos":"NOM"} ,
		{"word":"trouveurs","word_nosc":"trouveurs","lemma":"trouveur","pos":"NOM"} ,
		{"word":"trouvère","word_nosc":"trouvere","lemma":"trouvère","pos":"NOM"} ,
		{"word":"trouvères","word_nosc":"trouveres","lemma":"trouvère","pos":"NOM"} ,
		{"word":"trouée","word_nosc":"trouee","lemma":"trouée","pos":"NOM"} ,
		{"word":"trouées","word_nosc":"trouees","lemma":"trouée","pos":"NOM"} ,
		{"word":"troyen","word_nosc":"troyen","lemma":"troyen","pos":"NOM"} ,
		{"word":"troène","word_nosc":"troene","lemma":"troène","pos":"NOM"} ,
		{"word":"troènes","word_nosc":"troenes","lemma":"troène","pos":"NOM"} ,
		{"word":"troïka","word_nosc":"troika","lemma":"troïka","pos":"NOM"} ,
		{"word":"troïkas","word_nosc":"troikas","lemma":"troïka","pos":"NOM"} ,
		{"word":"truand","word_nosc":"truand","lemma":"truand","pos":"NOM"} ,
		{"word":"truandage","word_nosc":"truandage","lemma":"truandage","pos":"NOM"} ,
		{"word":"truandages","word_nosc":"truandages","lemma":"truandage","pos":"NOM"} ,
		{"word":"truandaille","word_nosc":"truandaille","lemma":"truandaille","pos":"NOM"} ,
		{"word":"truande","word_nosc":"truande","lemma":"truand","pos":"NOM"} ,
		{"word":"truanderie","word_nosc":"truanderie","lemma":"truanderie","pos":"NOM"} ,
		{"word":"truandes","word_nosc":"truandes","lemma":"truand","pos":"NOM"} ,
		{"word":"truands","word_nosc":"truands","lemma":"truand","pos":"NOM"} ,
		{"word":"trublion","word_nosc":"trublion","lemma":"trublion","pos":"NOM"} ,
		{"word":"trublions","word_nosc":"trublions","lemma":"trublion","pos":"NOM"} ,
		{"word":"truc","word_nosc":"truc","lemma":"truc","pos":"NOM"} ,
		{"word":"trucage","word_nosc":"trucage","lemma":"trucage","pos":"NOM"} ,
		{"word":"trucages","word_nosc":"trucages","lemma":"trucage","pos":"NOM"} ,
		{"word":"truchement","word_nosc":"truchement","lemma":"truchement","pos":"NOM"} ,
		{"word":"truchements","word_nosc":"truchements","lemma":"truchement","pos":"NOM"} ,
		{"word":"truck","word_nosc":"truck","lemma":"truck","pos":"NOM"} ,
		{"word":"trucks","word_nosc":"trucks","lemma":"truck","pos":"NOM"} ,
		{"word":"trucmuche","word_nosc":"trucmuche","lemma":"trucmuche","pos":"NOM"} ,
		{"word":"trucs","word_nosc":"trucs","lemma":"truc","pos":"NOM"} ,
		{"word":"truculence","word_nosc":"truculence","lemma":"truculence","pos":"NOM"} ,
		{"word":"truelle","word_nosc":"truelle","lemma":"truelle","pos":"NOM"} ,
		{"word":"truelles","word_nosc":"truelles","lemma":"truelle","pos":"NOM"} ,
		{"word":"truffe","word_nosc":"truffe","lemma":"truffe","pos":"NOM"} ,
		{"word":"truffes","word_nosc":"truffes","lemma":"truffe","pos":"NOM"} ,
		{"word":"truffier","word_nosc":"truffier","lemma":"truffier","pos":"NOM"} ,
		{"word":"truffiers","word_nosc":"truffiers","lemma":"truffier","pos":"NOM"} ,
		{"word":"truie","word_nosc":"truie","lemma":"truie","pos":"NOM"} ,
		{"word":"truies","word_nosc":"truies","lemma":"truie","pos":"NOM"} ,
		{"word":"truisme","word_nosc":"truisme","lemma":"truisme","pos":"NOM"} ,
		{"word":"truismes","word_nosc":"truismes","lemma":"truisme","pos":"NOM"} ,
		{"word":"truite","word_nosc":"truite","lemma":"truite","pos":"NOM"} ,
		{"word":"truitelle","word_nosc":"truitelle","lemma":"truitelle","pos":"NOM"} ,
		{"word":"truites","word_nosc":"truites","lemma":"truite","pos":"NOM"} ,
		{"word":"trumeau","word_nosc":"trumeau","lemma":"trumeau","pos":"NOM"} ,
		{"word":"trumeaux","word_nosc":"trumeaux","lemma":"trumeau","pos":"NOM"} ,
		{"word":"truquage","word_nosc":"truquage","lemma":"truquage","pos":"NOM"} ,
		{"word":"truquages","word_nosc":"truquages","lemma":"truquage","pos":"NOM"} ,
		{"word":"truqueur","word_nosc":"truqueur","lemma":"truqueur","pos":"NOM"} ,
		{"word":"truqueurs","word_nosc":"truqueurs","lemma":"truqueur","pos":"NOM"} ,
		{"word":"truqueuse","word_nosc":"truqueuse","lemma":"truqueur","pos":"NOM"} ,
		{"word":"trusquin","word_nosc":"trusquin","lemma":"trusquin","pos":"NOM"} ,
		{"word":"trust","word_nosc":"trust","lemma":"trust","pos":"NOM"} ,
		{"word":"trustee","word_nosc":"trustee","lemma":"trustee","pos":"NOM"} ,
		{"word":"trustees","word_nosc":"trustees","lemma":"trustee","pos":"NOM"} ,
		{"word":"trusteeship","word_nosc":"trusteeship","lemma":"trusteeship","pos":"NOM"} ,
		{"word":"trusteeships","word_nosc":"trusteeships","lemma":"trusteeship","pos":"NOM"} ,
		{"word":"trusts","word_nosc":"trusts","lemma":"trust","pos":"NOM"} ,
		{"word":"trypanosome","word_nosc":"trypanosome","lemma":"trypanosome","pos":"NOM"} ,
		{"word":"trypanosomes","word_nosc":"trypanosomes","lemma":"trypanosome","pos":"NOM"} ,
		{"word":"trypanosomiase","word_nosc":"trypanosomiase","lemma":"trypanosomiase","pos":"NOM"} ,
		{"word":"trypsine","word_nosc":"trypsine","lemma":"trypsine","pos":"NOM"} ,
		{"word":"tryptophane","word_nosc":"tryptophane","lemma":"tryptophane","pos":"NOM"} ,
		{"word":"trâlée","word_nosc":"tralee","lemma":"trâlée","pos":"NOM"} ,
		{"word":"trèfle","word_nosc":"trefle","lemma":"trèfle","pos":"NOM"} ,
		{"word":"trèfles","word_nosc":"trefles","lemma":"trèfle","pos":"NOM"} ,
		{"word":"trèpe","word_nosc":"trepe","lemma":"trèpe","pos":"NOM"} ,
		{"word":"trébuchement","word_nosc":"trebuchement","lemma":"trébuchement","pos":"NOM"} ,
		{"word":"trébuchements","word_nosc":"trebuchements","lemma":"trébuchement","pos":"NOM"} ,
		{"word":"trébuchet","word_nosc":"trebuchet","lemma":"trébuchet","pos":"NOM"} ,
		{"word":"tréflières","word_nosc":"treflieres","lemma":"tréflière","pos":"NOM"} ,
		{"word":"tréfonds","word_nosc":"trefonds","lemma":"tréfonds","pos":"NOM"} ,
		{"word":"tréma","word_nosc":"trema","lemma":"tréma","pos":"NOM"} ,
		{"word":"trémail","word_nosc":"tremail","lemma":"trémail","pos":"NOM"} ,
		{"word":"trémas","word_nosc":"tremas","lemma":"tréma","pos":"NOM"} ,
		{"word":"trémie","word_nosc":"tremie","lemma":"trémie","pos":"NOM"} ,
		{"word":"trémies","word_nosc":"tremies","lemma":"trémie","pos":"NOM"} ,
		{"word":"trémois","word_nosc":"tremois","lemma":"trémois","pos":"NOM"} ,
		{"word":"trémolo","word_nosc":"tremolo","lemma":"trémolo","pos":"NOM"} ,
		{"word":"trémolos","word_nosc":"tremolos","lemma":"trémolo","pos":"NOM"} ,
		{"word":"trémoussement","word_nosc":"tremoussement","lemma":"trémoussement","pos":"NOM"} ,
		{"word":"trémoussements","word_nosc":"tremoussements","lemma":"trémoussement","pos":"NOM"} ,
		{"word":"trémulation","word_nosc":"tremulation","lemma":"trémulation","pos":"NOM"} ,
		{"word":"trépan","word_nosc":"trepan","lemma":"trépan","pos":"NOM"} ,
		{"word":"trépanation","word_nosc":"trepanation","lemma":"trépanation","pos":"NOM"} ,
		{"word":"trépané","word_nosc":"trepane","lemma":"trépané","pos":"NOM"} ,
		{"word":"trépanée","word_nosc":"trepanee","lemma":"trépané","pos":"NOM"} ,
		{"word":"trépas","word_nosc":"trepas","lemma":"trépas","pos":"NOM"} ,
		{"word":"trépassé","word_nosc":"trepasse","lemma":"trépassé","pos":"NOM"} ,
		{"word":"trépassée","word_nosc":"trepassee","lemma":"trépassé","pos":"NOM"} ,
		{"word":"trépassées","word_nosc":"trepassees","lemma":"trépassé","pos":"NOM"} ,
		{"word":"trépassés","word_nosc":"trepasses","lemma":"trépassé","pos":"NOM"} ,
		{"word":"trépidation","word_nosc":"trepidation","lemma":"trépidation","pos":"NOM"} ,
		{"word":"trépidations","word_nosc":"trepidations","lemma":"trépidation","pos":"NOM"} ,
		{"word":"trépied","word_nosc":"trepied","lemma":"trépied","pos":"NOM"} ,
		{"word":"trépieds","word_nosc":"trepieds","lemma":"trépied","pos":"NOM"} ,
		{"word":"trépignement","word_nosc":"trepignement","lemma":"trépignement","pos":"NOM"} ,
		{"word":"trépignements","word_nosc":"trepignements","lemma":"trépignement","pos":"NOM"} ,
		{"word":"tréponème","word_nosc":"treponeme","lemma":"tréponème","pos":"NOM"} ,
		{"word":"tréponèmes","word_nosc":"treponemes","lemma":"tréponème","pos":"NOM"} ,
		{"word":"trésor","word_nosc":"tresor","lemma":"trésor","pos":"NOM"} ,
		{"word":"trésorerie","word_nosc":"tresorerie","lemma":"trésorerie","pos":"NOM"} ,
		{"word":"trésorier","word_nosc":"tresorier","lemma":"trésorier","pos":"NOM"} ,
		{"word":"trésorier-payeur","word_nosc":"tresorier-payeur","lemma":"trésorier-payeur","pos":"NOM"} ,
		{"word":"trésoriers","word_nosc":"tresoriers","lemma":"trésorier","pos":"NOM"} ,
		{"word":"trésorière","word_nosc":"tresoriere","lemma":"trésorier","pos":"NOM"} ,
		{"word":"trésors","word_nosc":"tresors","lemma":"trésor","pos":"NOM"} ,
		{"word":"tréteau","word_nosc":"treteau","lemma":"tréteau","pos":"NOM"} ,
		{"word":"tréteaux","word_nosc":"treteaux","lemma":"tréteau","pos":"NOM"} ,
		{"word":"trêve","word_nosc":"treve","lemma":"trêve","pos":"NOM"} ,
		{"word":"trêves","word_nosc":"treves","lemma":"trêve","pos":"NOM"} ,
		{"word":"trône","word_nosc":"trone","lemma":"trône","pos":"NOM"} ,
		{"word":"trônes","word_nosc":"trones","lemma":"trône","pos":"NOM"} ,
		{"word":"tsar","word_nosc":"tsar","lemma":"tsar","pos":"NOM"} ,
		{"word":"tsarine","word_nosc":"tsarine","lemma":"tsar","pos":"NOM"} ,
		{"word":"tsarisme","word_nosc":"tsarisme","lemma":"tsarisme","pos":"NOM"} ,
		{"word":"tsaristes","word_nosc":"tsaristes","lemma":"tsariste","pos":"NOM"} ,
		{"word":"tsars","word_nosc":"tsars","lemma":"tsar","pos":"NOM"} ,
		{"word":"tsarévitch","word_nosc":"tsarevitch","lemma":"tsarévitch","pos":"NOM"} ,
		{"word":"tsigane","word_nosc":"tsigane","lemma":"tsigane","pos":"NOM"} ,
		{"word":"tsiganes","word_nosc":"tsiganes","lemma":"tsigane","pos":"NOM"} ,
		{"word":"tsoin-tsoin","word_nosc":"tsoin-tsoin","lemma":"tsoin-tsoin","pos":"NOM"} ,
		{"word":"tss tss","word_nosc":"tss tss","lemma":"tss-tss","pos":"NOM"} ,
		{"word":"tss-tss","word_nosc":"tss-tss","lemma":"tss-tss","pos":"NOM"} ,
		{"word":"tsuica","word_nosc":"tsuica","lemma":"tsuica","pos":"NOM"} ,
		{"word":"tsunami","word_nosc":"tsunami","lemma":"tsunami","pos":"NOM"} ,
		{"word":"tsé-tsé","word_nosc":"tse-tse","lemma":"tsé-tsé","pos":"NOM"} ,
		{"word":"tu","word_nosc":"tu","lemma":"tu","pos":"NOM"} ,
		{"word":"tu autem","word_nosc":"tu autem","lemma":"tu autem","pos":"NOM"} ,
		{"word":"tub","word_nosc":"tub","lemma":"tub","pos":"NOM"} ,
		{"word":"tuba","word_nosc":"tuba","lemma":"tuba","pos":"NOM"} ,
		{"word":"tubages","word_nosc":"tubages","lemma":"tubage","pos":"NOM"} ,
		{"word":"tubard","word_nosc":"tubard","lemma":"tubard","pos":"NOM"} ,
		{"word":"tubardise","word_nosc":"tubardise","lemma":"tubardise","pos":"NOM"} ,
		{"word":"tubards","word_nosc":"tubards","lemma":"tubard","pos":"NOM"} ,
		{"word":"tubas","word_nosc":"tubas","lemma":"tuba","pos":"NOM"} ,
		{"word":"tube","word_nosc":"tube","lemma":"tube","pos":"NOM"} ,
		{"word":"tubercule","word_nosc":"tubercule","lemma":"tubercule","pos":"NOM"} ,
		{"word":"tubercules","word_nosc":"tubercules","lemma":"tubercule","pos":"NOM"} ,
		{"word":"tuberculeuse","word_nosc":"tuberculeuse","lemma":"tuberculeux","pos":"NOM"} ,
		{"word":"tuberculeux","word_nosc":"tuberculeux","lemma":"tuberculeux","pos":"NOM"} ,
		{"word":"tuberculine","word_nosc":"tuberculine","lemma":"tuberculine","pos":"NOM"} ,
		{"word":"tuberculose","word_nosc":"tuberculose","lemma":"tuberculose","pos":"NOM"} ,
		{"word":"tubes","word_nosc":"tubes","lemma":"tube","pos":"NOM"} ,
		{"word":"tubs","word_nosc":"tubs","lemma":"tub","pos":"NOM"} ,
		{"word":"tubule","word_nosc":"tubule","lemma":"tubule","pos":"NOM"} ,
		{"word":"tubulure","word_nosc":"tubulure","lemma":"tubulure","pos":"NOM"} ,
		{"word":"tubulures","word_nosc":"tubulures","lemma":"tubulure","pos":"NOM"} ,
		{"word":"tubérosité","word_nosc":"tuberosite","lemma":"tubérosité","pos":"NOM"} ,
		{"word":"tue-loup","word_nosc":"tue-loup","lemma":"tue-loup","pos":"NOM"} ,
		{"word":"tue-loups","word_nosc":"tue-loups","lemma":"tue-loup","pos":"NOM"} ,
		{"word":"tue-mouches","word_nosc":"tue-mouches","lemma":"tue-mouche","pos":"NOM"} ,
		{"word":"tuerie","word_nosc":"tuerie","lemma":"tuerie","pos":"NOM"} ,
		{"word":"tueries","word_nosc":"tueries","lemma":"tuerie","pos":"NOM"} ,
		{"word":"tueur","word_nosc":"tueur","lemma":"tueur","pos":"NOM"} ,
		{"word":"tueurs","word_nosc":"tueurs","lemma":"tueur","pos":"NOM"} ,
		{"word":"tueuse","word_nosc":"tueuse","lemma":"tueuse","pos":"NOM"} ,
		{"word":"tueuses","word_nosc":"tueuses","lemma":"tueur","pos":"NOM"} ,
		{"word":"tuf","word_nosc":"tuf","lemma":"tuf","pos":"NOM"} ,
		{"word":"tuffeau","word_nosc":"tuffeau","lemma":"tuffeau","pos":"NOM"} ,
		{"word":"tuffeaux","word_nosc":"tuffeaux","lemma":"tuffeau","pos":"NOM"} ,
		{"word":"tuile","word_nosc":"tuile","lemma":"tuile","pos":"NOM"} ,
		{"word":"tuilerie","word_nosc":"tuilerie","lemma":"tuilerie","pos":"NOM"} ,
		{"word":"tuileries","word_nosc":"tuileries","lemma":"tuilerie","pos":"NOM"} ,
		{"word":"tuiles","word_nosc":"tuiles","lemma":"tuile","pos":"NOM"} ,
		{"word":"tularémie","word_nosc":"tularemie","lemma":"tularémie","pos":"NOM"} ,
		{"word":"tulipe","word_nosc":"tulipe","lemma":"tulipe","pos":"NOM"} ,
		{"word":"tulipes","word_nosc":"tulipes","lemma":"tulipe","pos":"NOM"} ,
		{"word":"tulipier","word_nosc":"tulipier","lemma":"tulipier","pos":"NOM"} ,
		{"word":"tulipiers","word_nosc":"tulipiers","lemma":"tulipier","pos":"NOM"} ,
		{"word":"tulle","word_nosc":"tulle","lemma":"tulle","pos":"NOM"} ,
		{"word":"tulles","word_nosc":"tulles","lemma":"tulle","pos":"NOM"} ,
		{"word":"tumescence","word_nosc":"tumescence","lemma":"tumescence","pos":"NOM"} ,
		{"word":"tumeur","word_nosc":"tumeur","lemma":"tumeur","pos":"NOM"} ,
		{"word":"tumeurs","word_nosc":"tumeurs","lemma":"tumeur","pos":"NOM"} ,
		{"word":"tumuli","word_nosc":"tumuli","lemma":"tumulus","pos":"NOM"} ,
		{"word":"tumulte","word_nosc":"tumulte","lemma":"tumulte","pos":"NOM"} ,
		{"word":"tumultes","word_nosc":"tumultes","lemma":"tumulte","pos":"NOM"} ,
		{"word":"tumulus","word_nosc":"tumulus","lemma":"tumulus","pos":"NOM"} ,
		{"word":"tuméfaction","word_nosc":"tumefaction","lemma":"tuméfaction","pos":"NOM"} ,
		{"word":"tune","word_nosc":"tune","lemma":"tune","pos":"NOM"} ,
		{"word":"tuner","word_nosc":"tuner","lemma":"tuner","pos":"NOM"} ,
		{"word":"tunes","word_nosc":"tunes","lemma":"tune","pos":"NOM"} ,
		{"word":"tungstène","word_nosc":"tungstene","lemma":"tungstène","pos":"NOM"} ,
		{"word":"tunique","word_nosc":"tunique","lemma":"tunique","pos":"NOM"} ,
		{"word":"tuniques","word_nosc":"tuniques","lemma":"tunique","pos":"NOM"} ,
		{"word":"tunnel","word_nosc":"tunnel","lemma":"tunnel","pos":"NOM"} ,
		{"word":"tunnels","word_nosc":"tunnels","lemma":"tunnel","pos":"NOM"} ,
		{"word":"tuque","word_nosc":"tuque","lemma":"tuque","pos":"NOM"} ,
		{"word":"turban","word_nosc":"turban","lemma":"turban","pos":"NOM"} ,
		{"word":"turbans","word_nosc":"turbans","lemma":"turban","pos":"NOM"} ,
		{"word":"turbin","word_nosc":"turbin","lemma":"turbin","pos":"NOM"} ,
		{"word":"turbine","word_nosc":"turbine","lemma":"turbine","pos":"NOM"} ,
		{"word":"turbines","word_nosc":"turbines","lemma":"turbine","pos":"NOM"} ,
		{"word":"turbins","word_nosc":"turbins","lemma":"turbin","pos":"NOM"} ,
		{"word":"turbo","word_nosc":"turbo","lemma":"turbo","pos":"NOM"} ,
		{"word":"turbocompresseur","word_nosc":"turbocompresseur","lemma":"turbocompresseur","pos":"NOM"} ,
		{"word":"turbocompresseurs","word_nosc":"turbocompresseurs","lemma":"turbocompresseur","pos":"NOM"} ,
		{"word":"turbopropulseur","word_nosc":"turbopropulseur","lemma":"turbopropulseur","pos":"NOM"} ,
		{"word":"turboréacteur","word_nosc":"turboreacteur","lemma":"turboréacteur","pos":"NOM"} ,
		{"word":"turbos","word_nosc":"turbos","lemma":"turbo","pos":"NOM"} ,
		{"word":"turbot","word_nosc":"turbot","lemma":"turbot","pos":"NOM"} ,
		{"word":"turbotière","word_nosc":"turbotiere","lemma":"turbotière","pos":"NOM"} ,
		{"word":"turbotières","word_nosc":"turbotieres","lemma":"turbotière","pos":"NOM"} ,
		{"word":"turbulence","word_nosc":"turbulence","lemma":"turbulence","pos":"NOM"} ,
		{"word":"turbulences","word_nosc":"turbulences","lemma":"turbulence","pos":"NOM"} ,
		{"word":"turbé","word_nosc":"turbe","lemma":"turbé","pos":"NOM"} ,
		{"word":"turbés","word_nosc":"turbes","lemma":"turbé","pos":"NOM"} ,
		{"word":"turc","word_nosc":"turc","lemma":"turc","pos":"NOM"} ,
		{"word":"turco","word_nosc":"turco","lemma":"turco","pos":"NOM"} ,
		{"word":"turcoman","word_nosc":"turcoman","lemma":"turcoman","pos":"NOM"} ,
		{"word":"turcomans","word_nosc":"turcomans","lemma":"turcoman","pos":"NOM"} ,
		{"word":"turcos","word_nosc":"turcos","lemma":"turco","pos":"NOM"} ,
		{"word":"turcs","word_nosc":"turcs","lemma":"turc","pos":"NOM"} ,
		{"word":"turdus","word_nosc":"turdus","lemma":"turdus","pos":"NOM"} ,
		{"word":"turelure","word_nosc":"turelure","lemma":"turelure","pos":"NOM"} ,
		{"word":"turf","word_nosc":"turf","lemma":"turf","pos":"NOM"} ,
		{"word":"turfiste","word_nosc":"turfiste","lemma":"turfiste","pos":"NOM"} ,
		{"word":"turfistes","word_nosc":"turfistes","lemma":"turfiste","pos":"NOM"} ,
		{"word":"turfs","word_nosc":"turfs","lemma":"turf","pos":"NOM"} ,
		{"word":"turgescence","word_nosc":"turgescence","lemma":"turgescence","pos":"NOM"} ,
		{"word":"turgotine","word_nosc":"turgotine","lemma":"turgotine","pos":"NOM"} ,
		{"word":"turinois","word_nosc":"turinois","lemma":"turinois","pos":"NOM"} ,
		{"word":"turista","word_nosc":"turista","lemma":"turista","pos":"NOM"} ,
		{"word":"turistas","word_nosc":"turistas","lemma":"turista","pos":"NOM"} ,
		{"word":"turlu","word_nosc":"turlu","lemma":"turlu","pos":"NOM"} ,
		{"word":"turlupinade","word_nosc":"turlupinade","lemma":"turlupinade","pos":"NOM"} ,
		{"word":"turlupinades","word_nosc":"turlupinades","lemma":"turlupinade","pos":"NOM"} ,
		{"word":"turlupins","word_nosc":"turlupins","lemma":"turlupin","pos":"NOM"} ,
		{"word":"turlutaines","word_nosc":"turlutaines","lemma":"turlutaine","pos":"NOM"} ,
		{"word":"turlutte","word_nosc":"turlutte","lemma":"turlutte","pos":"NOM"} ,
		{"word":"turne","word_nosc":"turne","lemma":"turne","pos":"NOM"} ,
		{"word":"turnes","word_nosc":"turnes","lemma":"turne","pos":"NOM"} ,
		{"word":"turnover","word_nosc":"turnover","lemma":"turnover","pos":"NOM"} ,
		{"word":"turpitude","word_nosc":"turpitude","lemma":"turpitude","pos":"NOM"} ,
		{"word":"turpitudes","word_nosc":"turpitudes","lemma":"turpitude","pos":"NOM"} ,
		{"word":"turque","word_nosc":"turque","lemma":"turc","pos":"NOM"} ,
		{"word":"turquerie","word_nosc":"turquerie","lemma":"turquerie","pos":"NOM"} ,
		{"word":"turques","word_nosc":"turques","lemma":"turc","pos":"NOM"} ,
		{"word":"turquoise","word_nosc":"turquoise","lemma":"turquoise","pos":"NOM"} ,
		{"word":"turquoises","word_nosc":"turquoises","lemma":"turquoise","pos":"NOM"} ,
		{"word":"tussilage","word_nosc":"tussilage","lemma":"tussilage","pos":"NOM"} ,
		{"word":"tussor","word_nosc":"tussor","lemma":"tussor","pos":"NOM"} ,
		{"word":"tutelle","word_nosc":"tutelle","lemma":"tutelle","pos":"NOM"} ,
		{"word":"tuteur","word_nosc":"tuteur","lemma":"tuteur","pos":"NOM"} ,
		{"word":"tuteurs","word_nosc":"tuteurs","lemma":"tuteur","pos":"NOM"} ,
		{"word":"tutoiement","word_nosc":"tutoiement","lemma":"tutoiement","pos":"NOM"} ,
		{"word":"tutoiements","word_nosc":"tutoiements","lemma":"tutoiement","pos":"NOM"} ,
		{"word":"tutorat","word_nosc":"tutorat","lemma":"tutorat","pos":"NOM"} ,
		{"word":"tutrice","word_nosc":"tutrice","lemma":"tuteur","pos":"NOM"} ,
		{"word":"tutu","word_nosc":"tutu","lemma":"tutu","pos":"NOM"} ,
		{"word":"tutus","word_nosc":"tutus","lemma":"tutu","pos":"NOM"} ,
		{"word":"tutute","word_nosc":"tutute","lemma":"tutut","pos":"NOM"} ,
		{"word":"tuyau","word_nosc":"tuyau","lemma":"tuyau","pos":"NOM"} ,
		{"word":"tuyautages","word_nosc":"tuyautages","lemma":"tuyautage","pos":"NOM"} ,
		{"word":"tuyauterie","word_nosc":"tuyauterie","lemma":"tuyauterie","pos":"NOM"} ,
		{"word":"tuyauteries","word_nosc":"tuyauteries","lemma":"tuyauterie","pos":"NOM"} ,
		{"word":"tuyauteur","word_nosc":"tuyauteur","lemma":"tuyauteur","pos":"NOM"} ,
		{"word":"tuyauteurs","word_nosc":"tuyauteurs","lemma":"tuyauteur","pos":"NOM"} ,
		{"word":"tuyauté","word_nosc":"tuyaute","lemma":"tuyauté","pos":"NOM"} ,
		{"word":"tuyautés","word_nosc":"tuyautes","lemma":"tuyauté","pos":"NOM"} ,
		{"word":"tuyaux","word_nosc":"tuyaux","lemma":"tuyau","pos":"NOM"} ,
		{"word":"tuyère","word_nosc":"tuyere","lemma":"tuyère","pos":"NOM"} ,
		{"word":"tuyères","word_nosc":"tuyeres","lemma":"tuyère","pos":"NOM"} ,
		{"word":"tué","word_nosc":"tue","lemma":"tué","pos":"NOM"} ,
		{"word":"tuée","word_nosc":"tuee","lemma":"tué","pos":"NOM"} ,
		{"word":"tuées","word_nosc":"tuees","lemma":"tué","pos":"NOM"} ,
		{"word":"tués","word_nosc":"tues","lemma":"tué","pos":"NOM"} ,
		{"word":"tweed","word_nosc":"tweed","lemma":"tweed","pos":"NOM"} ,
		{"word":"tweeds","word_nosc":"tweeds","lemma":"tweed","pos":"NOM"} ,
		{"word":"twill","word_nosc":"twill","lemma":"twill","pos":"NOM"} ,
		{"word":"twin-set","word_nosc":"twin-set","lemma":"twin-set","pos":"NOM"} ,
		{"word":"twist","word_nosc":"twist","lemma":"twist","pos":"NOM"} ,
		{"word":"twists","word_nosc":"twists","lemma":"twist","pos":"NOM"} ,
		{"word":"tympan","word_nosc":"tympan","lemma":"tympan","pos":"NOM"} ,
		{"word":"tympanon","word_nosc":"tympanon","lemma":"tympanon","pos":"NOM"} ,
		{"word":"tympans","word_nosc":"tympans","lemma":"tympan","pos":"NOM"} ,
		{"word":"type","word_nosc":"type","lemma":"type","pos":"NOM"} ,
		{"word":"types","word_nosc":"types","lemma":"type","pos":"NOM"} ,
		{"word":"typhon","word_nosc":"typhon","lemma":"typhon","pos":"NOM"} ,
		{"word":"typhons","word_nosc":"typhons","lemma":"typhon","pos":"NOM"} ,
		{"word":"typhoïde","word_nosc":"typhoide","lemma":"typhoïde","pos":"NOM"} ,
		{"word":"typhus","word_nosc":"typhus","lemma":"typhus","pos":"NOM"} ,
		{"word":"typo","word_nosc":"typo","lemma":"typo","pos":"NOM"} ,
		{"word":"typographe","word_nosc":"typographe","lemma":"typographe","pos":"NOM"} ,
		{"word":"typographes","word_nosc":"typographes","lemma":"typographe","pos":"NOM"} ,
		{"word":"typographie","word_nosc":"typographie","lemma":"typographie","pos":"NOM"} ,
		{"word":"typologie","word_nosc":"typologie","lemma":"typologie","pos":"NOM"} ,
		{"word":"typomètre","word_nosc":"typometre","lemma":"typomètre","pos":"NOM"} ,
		{"word":"typos","word_nosc":"typos","lemma":"typo","pos":"NOM"} ,
		{"word":"tyran","word_nosc":"tyran","lemma":"tyran","pos":"NOM"} ,
		{"word":"tyranneau","word_nosc":"tyranneau","lemma":"tyranneau","pos":"NOM"} ,
		{"word":"tyranneaux","word_nosc":"tyranneaux","lemma":"tyranneau","pos":"NOM"} ,
		{"word":"tyrannicide","word_nosc":"tyrannicide","lemma":"tyrannicide","pos":"NOM"} ,
		{"word":"tyrannicides","word_nosc":"tyrannicides","lemma":"tyrannicide","pos":"NOM"} ,
		{"word":"tyrannie","word_nosc":"tyrannie","lemma":"tyrannie","pos":"NOM"} ,
		{"word":"tyrannies","word_nosc":"tyrannies","lemma":"tyrannie","pos":"NOM"} ,
		{"word":"tyrannosaure","word_nosc":"tyrannosaure","lemma":"tyrannosaure","pos":"NOM"} ,
		{"word":"tyrannosaures","word_nosc":"tyrannosaures","lemma":"tyrannosaure","pos":"NOM"} ,
		{"word":"tyrans","word_nosc":"tyrans","lemma":"tyran","pos":"NOM"} ,
		{"word":"tyrolien","word_nosc":"tyrolien","lemma":"tyrolien","pos":"NOM"} ,
		{"word":"tyroliens","word_nosc":"tyroliens","lemma":"tyrolien","pos":"NOM"} ,
		{"word":"tzar","word_nosc":"tzar","lemma":"tzar","pos":"NOM"} ,
		{"word":"tzarevitch","word_nosc":"tzarevitch","lemma":"tzarevitch","pos":"NOM"} ,
		{"word":"tzarine","word_nosc":"tzarine","lemma":"tzar","pos":"NOM"} ,
		{"word":"tzars","word_nosc":"tzars","lemma":"tzar","pos":"NOM"} ,
		{"word":"tzigane","word_nosc":"tzigane","lemma":"tzigane","pos":"NOM"} ,
		{"word":"tziganes","word_nosc":"tziganes","lemma":"tzigane","pos":"NOM"} ,
		{"word":"tâche","word_nosc":"tache","lemma":"tâche","pos":"NOM"} ,
		{"word":"tâcheron","word_nosc":"tacheron","lemma":"tâcheron","pos":"NOM"} ,
		{"word":"tâcherons","word_nosc":"tacherons","lemma":"tâcheron","pos":"NOM"} ,
		{"word":"tâches","word_nosc":"taches","lemma":"tâche","pos":"NOM"} ,
		{"word":"tâtonnement","word_nosc":"tatonnement","lemma":"tâtonnement","pos":"NOM"} ,
		{"word":"tâtonnements","word_nosc":"tatonnements","lemma":"tâtonnement","pos":"NOM"} ,
		{"word":"té","word_nosc":"te","lemma":"té","pos":"NOM"} ,
		{"word":"téflon","word_nosc":"teflon","lemma":"téflon","pos":"NOM"} ,
		{"word":"tégument","word_nosc":"tegument","lemma":"tégument","pos":"NOM"} ,
		{"word":"tégénaires","word_nosc":"tegenaires","lemma":"tégénaire","pos":"NOM"} ,
		{"word":"tél","word_nosc":"tel","lemma":"tél","pos":"NOM"} ,
		{"word":"tél.","word_nosc":"tel.","lemma":"tél.","pos":"NOM"} ,
		{"word":"télencéphale","word_nosc":"telencephale","lemma":"télencéphale","pos":"NOM"} ,
		{"word":"télescopage","word_nosc":"telescopage","lemma":"télescopage","pos":"NOM"} ,
		{"word":"télescope","word_nosc":"telescope","lemma":"télescope","pos":"NOM"} ,
		{"word":"télescopes","word_nosc":"telescopes","lemma":"télescope","pos":"NOM"} ,
		{"word":"télescripteurs","word_nosc":"telescripteurs","lemma":"télescripteur","pos":"NOM"} ,
		{"word":"télex","word_nosc":"telex","lemma":"télex","pos":"NOM"} ,
		{"word":"téloche","word_nosc":"teloche","lemma":"téloche","pos":"NOM"} ,
		{"word":"télègue","word_nosc":"telegue","lemma":"télègue","pos":"NOM"} ,
		{"word":"télé","word_nosc":"tele","lemma":"télé","pos":"NOM"} ,
		{"word":"téléachat","word_nosc":"teleachat","lemma":"téléachat","pos":"NOM"} ,
		{"word":"téléavertisseur","word_nosc":"teleavertisseur","lemma":"téléavertisseur","pos":"NOM"} ,
		{"word":"télécabine","word_nosc":"telecabine","lemma":"télécabine","pos":"NOM"} ,
		{"word":"télécartes","word_nosc":"telecartes","lemma":"télécarte","pos":"NOM"} ,
		{"word":"téléchargement","word_nosc":"telechargement","lemma":"téléchargement","pos":"NOM"} ,
		{"word":"télécinéma","word_nosc":"telecinema","lemma":"télécinéma","pos":"NOM"} ,
		{"word":"télécommande","word_nosc":"telecommande","lemma":"télécommande","pos":"NOM"} ,
		{"word":"télécommandes","word_nosc":"telecommandes","lemma":"télécommande","pos":"NOM"} ,
		{"word":"télécommunication","word_nosc":"telecommunication","lemma":"télécommunication","pos":"NOM"} ,
		{"word":"télécommunications","word_nosc":"telecommunications","lemma":"télécommunication","pos":"NOM"} ,
		{"word":"télécoms","word_nosc":"telecoms","lemma":"télécom","pos":"NOM"} ,
		{"word":"téléconférence","word_nosc":"teleconference","lemma":"téléconférence","pos":"NOM"} ,
		{"word":"télécopieur","word_nosc":"telecopieur","lemma":"télécopieur","pos":"NOM"} ,
		{"word":"télécran","word_nosc":"telecran","lemma":"télécran","pos":"NOM"} ,
		{"word":"télécrans","word_nosc":"telecrans","lemma":"télécran","pos":"NOM"} ,
		{"word":"télédiffusion","word_nosc":"telediffusion","lemma":"télédiffusion","pos":"NOM"} ,
		{"word":"téléfax","word_nosc":"telefax","lemma":"téléfax","pos":"NOM"} ,
		{"word":"téléfilm","word_nosc":"telefilm","lemma":"téléfilm","pos":"NOM"} ,
		{"word":"téléfilms","word_nosc":"telefilms","lemma":"téléfilm","pos":"NOM"} ,
		{"word":"téléfériques","word_nosc":"teleferiques","lemma":"téléférique","pos":"NOM"} ,
		{"word":"télégramme","word_nosc":"telegramme","lemma":"télégramme","pos":"NOM"} ,
		{"word":"télégrammes","word_nosc":"telegrammes","lemma":"télégramme","pos":"NOM"} ,
		{"word":"télégraphe","word_nosc":"telegraphe","lemma":"télégraphe","pos":"NOM"} ,
		{"word":"télégraphes","word_nosc":"telegraphes","lemma":"télégraphe","pos":"NOM"} ,
		{"word":"télégraphie","word_nosc":"telegraphie","lemma":"télégraphie","pos":"NOM"} ,
		{"word":"télégraphiste","word_nosc":"telegraphiste","lemma":"télégraphiste","pos":"NOM"} ,
		{"word":"télégraphistes","word_nosc":"telegraphistes","lemma":"télégraphiste","pos":"NOM"} ,
		{"word":"téléguidage","word_nosc":"teleguidage","lemma":"téléguidage","pos":"NOM"} ,
		{"word":"télégénie","word_nosc":"telegenie","lemma":"télégénie","pos":"NOM"} ,
		{"word":"télékinésie","word_nosc":"telekinesie","lemma":"télékinésie","pos":"NOM"} ,
		{"word":"télémark","word_nosc":"telemark","lemma":"télémark","pos":"NOM"} ,
		{"word":"télémarketing","word_nosc":"telemarketing","lemma":"télémarketing","pos":"NOM"} ,
		{"word":"télémesure","word_nosc":"telemesure","lemma":"télémesure","pos":"NOM"} ,
		{"word":"télémesures","word_nosc":"telemesures","lemma":"télémesure","pos":"NOM"} ,
		{"word":"télémètre","word_nosc":"telemetre","lemma":"télémètre","pos":"NOM"} ,
		{"word":"télémètres","word_nosc":"telemetres","lemma":"télémètre","pos":"NOM"} ,
		{"word":"télémétrie","word_nosc":"telemetrie","lemma":"télémétrie","pos":"NOM"} ,
		{"word":"téléobjectif","word_nosc":"teleobjectif","lemma":"téléobjectif","pos":"NOM"} ,
		{"word":"téléobjectifs","word_nosc":"teleobjectifs","lemma":"téléobjectif","pos":"NOM"} ,
		{"word":"télépathe","word_nosc":"telepathe","lemma":"télépathe","pos":"NOM"} ,
		{"word":"télépathes","word_nosc":"telepathes","lemma":"télépathe","pos":"NOM"} ,
		{"word":"télépathie","word_nosc":"telepathie","lemma":"télépathie","pos":"NOM"} ,
		{"word":"téléphonage","word_nosc":"telephonage","lemma":"téléphonage","pos":"NOM"} ,
		{"word":"téléphone","word_nosc":"telephone","lemma":"téléphone","pos":"NOM"} ,
		{"word":"téléphones","word_nosc":"telephones","lemma":"téléphone","pos":"NOM"} ,
		{"word":"téléphonie","word_nosc":"telephonie","lemma":"téléphonie","pos":"NOM"} ,
		{"word":"téléphoniste","word_nosc":"telephoniste","lemma":"téléphoniste","pos":"NOM"} ,
		{"word":"téléphonistes","word_nosc":"telephonistes","lemma":"téléphoniste","pos":"NOM"} ,
		{"word":"téléphérique","word_nosc":"telepherique","lemma":"téléphérique","pos":"NOM"} ,
		{"word":"téléport","word_nosc":"teleport","lemma":"téléport","pos":"NOM"} ,
		{"word":"téléportation","word_nosc":"teleportation","lemma":"téléportation","pos":"NOM"} ,
		{"word":"téléprompteur","word_nosc":"teleprompteur","lemma":"téléprompteur","pos":"NOM"} ,
		{"word":"téléreportage","word_nosc":"telereportage","lemma":"téléreportage","pos":"NOM"} ,
		{"word":"téléroman","word_nosc":"teleroman","lemma":"téléroman","pos":"NOM"} ,
		{"word":"télés","word_nosc":"teles","lemma":"télé","pos":"NOM"} ,
		{"word":"téléscripteur","word_nosc":"telescripteur","lemma":"téléscripteur","pos":"NOM"} ,
		{"word":"téléscripteurs","word_nosc":"telescripteurs","lemma":"téléscripteur","pos":"NOM"} ,
		{"word":"télésiège","word_nosc":"telesiege","lemma":"télésiège","pos":"NOM"} ,
		{"word":"télésièges","word_nosc":"telesieges","lemma":"télésiège","pos":"NOM"} ,
		{"word":"téléski","word_nosc":"teleski","lemma":"téléski","pos":"NOM"} ,
		{"word":"téléspectateur","word_nosc":"telespectateur","lemma":"téléspectateur","pos":"NOM"} ,
		{"word":"téléspectateurs","word_nosc":"telespectateurs","lemma":"téléspectateur","pos":"NOM"} ,
		{"word":"téléspectatrice","word_nosc":"telespectatrice","lemma":"téléspectateur","pos":"NOM"} ,
		{"word":"téléspectatrices","word_nosc":"telespectatrices","lemma":"téléspectateur","pos":"NOM"} ,
		{"word":"télésurveillance","word_nosc":"telesurveillance","lemma":"télésurveillance","pos":"NOM"} ,
		{"word":"télétexte","word_nosc":"teletexte","lemma":"télétexte","pos":"NOM"} ,
		{"word":"télétravail","word_nosc":"teletravail","lemma":"télétravail","pos":"NOM"} ,
		{"word":"télétravailleuse","word_nosc":"teletravailleuse","lemma":"télétravailleur","pos":"NOM"} ,
		{"word":"télétype","word_nosc":"teletype","lemma":"télétype","pos":"NOM"} ,
		{"word":"télétypes","word_nosc":"teletypes","lemma":"télétype","pos":"NOM"} ,
		{"word":"télévangéliste","word_nosc":"televangeliste","lemma":"télévangéliste","pos":"NOM"} ,
		{"word":"télévangélistes","word_nosc":"televangelistes","lemma":"télévangéliste","pos":"NOM"} ,
		{"word":"télévendeur","word_nosc":"televendeur","lemma":"télévendeur","pos":"NOM"} ,
		{"word":"télévente","word_nosc":"televente","lemma":"télévente","pos":"NOM"} ,
		{"word":"téléviseur","word_nosc":"televiseur","lemma":"téléviseur","pos":"NOM"} ,
		{"word":"téléviseurs","word_nosc":"televiseurs","lemma":"téléviseur","pos":"NOM"} ,
		{"word":"télévision","word_nosc":"television","lemma":"télévision","pos":"NOM"} ,
		{"word":"télévisions","word_nosc":"televisions","lemma":"télévision","pos":"NOM"} ,
		{"word":"témoignage","word_nosc":"temoignage","lemma":"témoignage","pos":"NOM"} ,
		{"word":"témoignages","word_nosc":"temoignages","lemma":"témoignage","pos":"NOM"} ,
		{"word":"témoin","word_nosc":"temoin","lemma":"témoin","pos":"NOM"} ,
		{"word":"témoin-clé","word_nosc":"temoin-cle","lemma":"témoin-clé","pos":"NOM"} ,
		{"word":"témoin-vedette","word_nosc":"temoin-vedette","lemma":"témoin-vedette","pos":"NOM"} ,
		{"word":"témoins","word_nosc":"temoins","lemma":"témoin","pos":"NOM"} ,
		{"word":"témoins-clés","word_nosc":"temoins-cles","lemma":"témoin-clé","pos":"NOM"} ,
		{"word":"témérité","word_nosc":"temerite","lemma":"témérité","pos":"NOM"} ,
		{"word":"témérités","word_nosc":"temerites","lemma":"témérité","pos":"NOM"} ,
		{"word":"ténacité","word_nosc":"tenacite","lemma":"ténacité","pos":"NOM"} ,
		{"word":"ténia","word_nosc":"tenia","lemma":"ténia","pos":"NOM"} ,
		{"word":"ténias","word_nosc":"tenias","lemma":"ténia","pos":"NOM"} ,
		{"word":"ténor","word_nosc":"tenor","lemma":"ténor","pos":"NOM"} ,
		{"word":"ténors","word_nosc":"tenors","lemma":"ténor","pos":"NOM"} ,
		{"word":"ténuité","word_nosc":"tenuite","lemma":"ténuité","pos":"NOM"} ,
		{"word":"ténèbre","word_nosc":"tenebre","lemma":"ténèbre","pos":"NOM"} ,
		{"word":"ténèbres","word_nosc":"tenebres","lemma":"ténèbres","pos":"NOM"} ,
		{"word":"ténébrionidé","word_nosc":"tenebrionide","lemma":"ténébrionidé","pos":"NOM"} ,
		{"word":"téraoctets","word_nosc":"teraoctets","lemma":"téraoctet","pos":"NOM"} ,
		{"word":"tératome","word_nosc":"teratome","lemma":"tératome","pos":"NOM"} ,
		{"word":"térébenthine","word_nosc":"terebenthine","lemma":"térébenthine","pos":"NOM"} ,
		{"word":"térébinthe","word_nosc":"terebinthe","lemma":"térébinthe","pos":"NOM"} ,
		{"word":"térébinthes","word_nosc":"terebinthes","lemma":"térébinthe","pos":"NOM"} ,
		{"word":"tés","word_nosc":"tes","lemma":"té","pos":"NOM"} ,
		{"word":"tétanie","word_nosc":"tetanie","lemma":"tétanie","pos":"NOM"} ,
		{"word":"tétanisation","word_nosc":"tetanisation","lemma":"tétanisation","pos":"NOM"} ,
		{"word":"tétanos","word_nosc":"tetanos","lemma":"tétanos","pos":"NOM"} ,
		{"word":"téteur","word_nosc":"teteur","lemma":"téteur","pos":"NOM"} ,
		{"word":"tétine","word_nosc":"tetine","lemma":"tétine","pos":"NOM"} ,
		{"word":"tétines","word_nosc":"tetines","lemma":"tétine","pos":"NOM"} ,
		{"word":"tétins","word_nosc":"tetins","lemma":"tétin","pos":"NOM"} ,
		{"word":"téton","word_nosc":"teton","lemma":"téton","pos":"NOM"} ,
		{"word":"tétons","word_nosc":"tetons","lemma":"téton","pos":"NOM"} ,
		{"word":"tétra","word_nosc":"tetra","lemma":"tétra","pos":"NOM"} ,
		{"word":"tétrachlorure","word_nosc":"tetrachlorure","lemma":"tétrachlorure","pos":"NOM"} ,
		{"word":"tétracycline","word_nosc":"tetracycline","lemma":"tétracycline","pos":"NOM"} ,
		{"word":"tétradrachme","word_nosc":"tetradrachme","lemma":"tétradrachme","pos":"NOM"} ,
		{"word":"tétragones","word_nosc":"tetragones","lemma":"tétragone","pos":"NOM"} ,
		{"word":"tétragramme","word_nosc":"tetragramme","lemma":"tétragramme","pos":"NOM"} ,
		{"word":"tétragrammes","word_nosc":"tetragrammes","lemma":"tétragramme","pos":"NOM"} ,
		{"word":"tétralogie","word_nosc":"tetralogie","lemma":"tétralogie","pos":"NOM"} ,
		{"word":"tétramètre","word_nosc":"tetrametre","lemma":"tétramètre","pos":"NOM"} ,
		{"word":"tétraplégie","word_nosc":"tetraplegie","lemma":"tétraplégie","pos":"NOM"} ,
		{"word":"tétrarque","word_nosc":"tetrarque","lemma":"tétrarque","pos":"NOM"} ,
		{"word":"tétrarques","word_nosc":"tetrarques","lemma":"tétrarque","pos":"NOM"} ,
		{"word":"tétras","word_nosc":"tetras","lemma":"tétras","pos":"NOM"} ,
		{"word":"tétrodotoxine","word_nosc":"tetrodotoxine","lemma":"tétrodotoxine","pos":"NOM"} ,
		{"word":"tétère","word_nosc":"tetere","lemma":"téter","pos":"NOM"} ,
		{"word":"tétée","word_nosc":"tetee","lemma":"tétée","pos":"NOM"} ,
		{"word":"tétées","word_nosc":"tetees","lemma":"tétée","pos":"NOM"} ,
		{"word":"tévé","word_nosc":"teve","lemma":"tévé","pos":"NOM"} ,
		{"word":"têt","word_nosc":"tet","lemma":"têt","pos":"NOM"} ,
		{"word":"têtard","word_nosc":"tetard","lemma":"têtard","pos":"NOM"} ,
		{"word":"têtards","word_nosc":"tetards","lemma":"têtard","pos":"NOM"} ,
		{"word":"tête","word_nosc":"tete","lemma":"tête","pos":"NOM"} ,
		{"word":"tête-bêche","word_nosc":"tete-beche","lemma":"tête-bêche","pos":"NOM"} ,
		{"word":"tête-de-mort","word_nosc":"tete-de-mort","lemma":"tête-de-mort","pos":"NOM"} ,
		{"word":"tête-à-queue","word_nosc":"tete-a-queue","lemma":"tête-à-queue","pos":"NOM"} ,
		{"word":"tête-à-tête","word_nosc":"tete-a-tete","lemma":"tête-à-tête","pos":"NOM"} ,
		{"word":"têtes","word_nosc":"tetes","lemma":"tête","pos":"NOM"} ,
		{"word":"têtes-de-loup","word_nosc":"tetes-de-loup","lemma":"tête-de-loup","pos":"NOM"} ,
		{"word":"têtière","word_nosc":"tetiere","lemma":"têtière","pos":"NOM"} ,
		{"word":"têtières","word_nosc":"tetieres","lemma":"têtière","pos":"NOM"} ,
		{"word":"tôlarde","word_nosc":"tolarde","lemma":"tôlard","pos":"NOM"} ,
		{"word":"tôle","word_nosc":"tole","lemma":"tôle","pos":"NOM"} ,
		{"word":"tôlerie","word_nosc":"tolerie","lemma":"tôlerie","pos":"NOM"} ,
		{"word":"tôles","word_nosc":"toles","lemma":"tôle","pos":"NOM"} ,
		{"word":"tôlier","word_nosc":"tolier","lemma":"tôlier","pos":"NOM"} ,
		{"word":"tôliers","word_nosc":"toliers","lemma":"tôlier","pos":"NOM"} ,
		{"word":"tôlière","word_nosc":"toliere","lemma":"tôlier","pos":"NOM"} ,
		{"word":"tôt-fait","word_nosc":"tot-fait","lemma":"tôt-fait","pos":"NOM"} ,
		{"word":"u","word_nosc":"u","lemma":"u","pos":"NOM"} ,
		{"word":"ubac","word_nosc":"ubac","lemma":"ubac","pos":"NOM"} ,
		{"word":"ubiquiste","word_nosc":"ubiquiste","lemma":"ubiquiste","pos":"NOM"} ,
		{"word":"ubiquité","word_nosc":"ubiquite","lemma":"ubiquité","pos":"NOM"} ,
		{"word":"ufologie","word_nosc":"ufologie","lemma":"ufologie","pos":"NOM"} ,
		{"word":"uhlan","word_nosc":"uhlan","lemma":"uhlan","pos":"NOM"} ,
		{"word":"uhlans","word_nosc":"uhlans","lemma":"uhlan","pos":"NOM"} ,
		{"word":"ukase","word_nosc":"ukase","lemma":"ukase","pos":"NOM"} ,
		{"word":"ukases","word_nosc":"ukases","lemma":"ukase","pos":"NOM"} ,
		{"word":"ukrainien","word_nosc":"ukrainien","lemma":"ukrainien","pos":"NOM"} ,
		{"word":"ukrainienne","word_nosc":"ukrainienne","lemma":"ukrainien","pos":"NOM"} ,
		{"word":"ukrainiens","word_nosc":"ukrainiens","lemma":"ukrainien","pos":"NOM"} ,
		{"word":"ukulélé","word_nosc":"ukulele","lemma":"ukulélé","pos":"NOM"} ,
		{"word":"ulcère","word_nosc":"ulcere","lemma":"ulcère","pos":"NOM"} ,
		{"word":"ulcères","word_nosc":"ulceres","lemma":"ulcère","pos":"NOM"} ,
		{"word":"ulcérations","word_nosc":"ulcerations","lemma":"ulcération","pos":"NOM"} ,
		{"word":"ulna","word_nosc":"ulna","lemma":"ulna","pos":"NOM"} ,
		{"word":"ultima ratio","word_nosc":"ultima ratio","lemma":"ultima ratio","pos":"NOM"} ,
		{"word":"ultimatum","word_nosc":"ultimatum","lemma":"ultimatum","pos":"NOM"} ,
		{"word":"ultimatums","word_nosc":"ultimatums","lemma":"ultimatum","pos":"NOM"} ,
		{"word":"ultra","word_nosc":"ultra","lemma":"ultra","pos":"NOM"} ,
		{"word":"ultra-gauche","word_nosc":"ultra-gauche","lemma":"ultra-gauche","pos":"NOM"} ,
		{"word":"ultra-secret","word_nosc":"ultra-secret","lemma":"ultra-secret","pos":"NOM"} ,
		{"word":"ultra-sons","word_nosc":"ultra-sons","lemma":"ultra-son","pos":"NOM"} ,
		{"word":"ultra-violets","word_nosc":"ultra-violets","lemma":"ultra-violet","pos":"NOM"} ,
		{"word":"ultragauche","word_nosc":"ultragauche","lemma":"ultragauche","pos":"NOM"} ,
		{"word":"ultras","word_nosc":"ultras","lemma":"ultra","pos":"NOM"} ,
		{"word":"ultrason","word_nosc":"ultrason","lemma":"ultrason","pos":"NOM"} ,
		{"word":"ultrasons","word_nosc":"ultrasons","lemma":"ultrason","pos":"NOM"} ,
		{"word":"ultraviolet","word_nosc":"ultraviolet","lemma":"ultraviolet","pos":"NOM"} ,
		{"word":"ultraviolets","word_nosc":"ultraviolets","lemma":"ultraviolet","pos":"NOM"} ,
		{"word":"ultravirus","word_nosc":"ultravirus","lemma":"ultravirus","pos":"NOM"} ,
		{"word":"ululement","word_nosc":"ululement","lemma":"ululement","pos":"NOM"} ,
		{"word":"ululements","word_nosc":"ululements","lemma":"ululement","pos":"NOM"} ,
		{"word":"umbanda","word_nosc":"umbanda","lemma":"umbanda","pos":"NOM"} ,
		{"word":"unanimisme","word_nosc":"unanimisme","lemma":"unanimisme","pos":"NOM"} ,
		{"word":"unanimité","word_nosc":"unanimite","lemma":"unanimité","pos":"NOM"} ,
		{"word":"unau","word_nosc":"unau","lemma":"unau","pos":"NOM"} ,
		{"word":"underground","word_nosc":"underground","lemma":"underground","pos":"NOM"} ,
		{"word":"une","word_nosc":"une","lemma":"une","pos":"NOM"} ,
		{"word":"unetelle","word_nosc":"unetelle","lemma":"unetelle","pos":"NOM"} ,
		{"word":"uni","word_nosc":"uni","lemma":"uni","pos":"NOM"} ,
		{"word":"unicité","word_nosc":"unicite","lemma":"unicité","pos":"NOM"} ,
		{"word":"unification","word_nosc":"unification","lemma":"unification","pos":"NOM"} ,
		{"word":"uniforme","word_nosc":"uniforme","lemma":"uniforme","pos":"NOM"} ,
		{"word":"uniformes","word_nosc":"uniformes","lemma":"uniforme","pos":"NOM"} ,
		{"word":"uniformisation","word_nosc":"uniformisation","lemma":"uniformisation","pos":"NOM"} ,
		{"word":"uniformité","word_nosc":"uniformite","lemma":"uniformité","pos":"NOM"} ,
		{"word":"unijambiste","word_nosc":"unijambiste","lemma":"unijambiste","pos":"NOM"} ,
		{"word":"unijambistes","word_nosc":"unijambistes","lemma":"unijambiste","pos":"NOM"} ,
		{"word":"unilatéralité","word_nosc":"unilateralite","lemma":"unilatéralité","pos":"NOM"} ,
		{"word":"union","word_nosc":"union","lemma":"union","pos":"NOM"} ,
		{"word":"unioniste","word_nosc":"unioniste","lemma":"unioniste","pos":"NOM"} ,
		{"word":"unionistes","word_nosc":"unionistes","lemma":"unioniste","pos":"NOM"} ,
		{"word":"unions","word_nosc":"unions","lemma":"union","pos":"NOM"} ,
		{"word":"uniprix","word_nosc":"uniprix","lemma":"uniprix","pos":"NOM"} ,
		{"word":"unis","word_nosc":"unis","lemma":"uni","pos":"NOM"} ,
		{"word":"unisson","word_nosc":"unisson","lemma":"unisson","pos":"NOM"} ,
		{"word":"unité","word_nosc":"unite","lemma":"unité","pos":"NOM"} ,
		{"word":"unités","word_nosc":"unites","lemma":"unité","pos":"NOM"} ,
		{"word":"univers","word_nosc":"univers","lemma":"univers","pos":"NOM"} ,
		{"word":"universal","word_nosc":"universal","lemma":"universal","pos":"NOM"} ,
		{"word":"universalisme","word_nosc":"universalisme","lemma":"universalisme","pos":"NOM"} ,
		{"word":"universaliste","word_nosc":"universaliste","lemma":"universaliste","pos":"NOM"} ,
		{"word":"universalité","word_nosc":"universalite","lemma":"universalité","pos":"NOM"} ,
		{"word":"universel","word_nosc":"universel","lemma":"universel","pos":"NOM"} ,
		{"word":"universitaire","word_nosc":"universitaire","lemma":"universitaire","pos":"NOM"} ,
		{"word":"universitaires","word_nosc":"universitaires","lemma":"universitaire","pos":"NOM"} ,
		{"word":"université","word_nosc":"universite","lemma":"université","pos":"NOM"} ,
		{"word":"universités","word_nosc":"universites","lemma":"université","pos":"NOM"} ,
		{"word":"untel","word_nosc":"untel","lemma":"untel","pos":"NOM"} ,
		{"word":"upanisads","word_nosc":"upanisads","lemma":"upanisad","pos":"NOM"} ,
		{"word":"upanishad","word_nosc":"upanishad","lemma":"upanishad","pos":"NOM"} ,
		{"word":"upas","word_nosc":"upas","lemma":"upas","pos":"NOM"} ,
		{"word":"update","word_nosc":"update","lemma":"update","pos":"NOM"} ,
		{"word":"uppercut","word_nosc":"uppercut","lemma":"uppercut","pos":"NOM"} ,
		{"word":"uppercuts","word_nosc":"uppercuts","lemma":"uppercut","pos":"NOM"} ,
		{"word":"urane","word_nosc":"urane","lemma":"urane","pos":"NOM"} ,
		{"word":"uranisme","word_nosc":"uranisme","lemma":"uranisme","pos":"NOM"} ,
		{"word":"uraniste","word_nosc":"uraniste","lemma":"uraniste","pos":"NOM"} ,
		{"word":"uranium","word_nosc":"uranium","lemma":"uranium","pos":"NOM"} ,
		{"word":"urbanisation","word_nosc":"urbanisation","lemma":"urbanisation","pos":"NOM"} ,
		{"word":"urbanisme","word_nosc":"urbanisme","lemma":"urbanisme","pos":"NOM"} ,
		{"word":"urbaniste","word_nosc":"urbaniste","lemma":"urbaniste","pos":"NOM"} ,
		{"word":"urbanistes","word_nosc":"urbanistes","lemma":"urbaniste","pos":"NOM"} ,
		{"word":"urbanité","word_nosc":"urbanite","lemma":"urbanité","pos":"NOM"} ,
		{"word":"urdu","word_nosc":"urdu","lemma":"urdu","pos":"NOM"} ,
		{"word":"ures","word_nosc":"ures","lemma":"ure","pos":"NOM"} ,
		{"word":"uretère","word_nosc":"uretere","lemma":"uretère","pos":"NOM"} ,
		{"word":"urgence","word_nosc":"urgence","lemma":"urgence","pos":"NOM"} ,
		{"word":"urgences","word_nosc":"urgences","lemma":"urgence","pos":"NOM"} ,
		{"word":"urgentiste","word_nosc":"urgentiste","lemma":"urgentiste","pos":"NOM"} ,
		{"word":"urinal","word_nosc":"urinal","lemma":"urinal","pos":"NOM"} ,
		{"word":"urine","word_nosc":"urine","lemma":"urine","pos":"NOM"} ,
		{"word":"urines","word_nosc":"urines","lemma":"urine","pos":"NOM"} ,
		{"word":"urinoir","word_nosc":"urinoir","lemma":"urinoir","pos":"NOM"} ,
		{"word":"urinoirs","word_nosc":"urinoirs","lemma":"urinoir","pos":"NOM"} ,
		{"word":"urne","word_nosc":"urne","lemma":"urne","pos":"NOM"} ,
		{"word":"urnes","word_nosc":"urnes","lemma":"urne","pos":"NOM"} ,
		{"word":"urographie","word_nosc":"urographie","lemma":"urographie","pos":"NOM"} ,
		{"word":"urokinase","word_nosc":"urokinase","lemma":"urokinase","pos":"NOM"} ,
		{"word":"urologie","word_nosc":"urologie","lemma":"urologie","pos":"NOM"} ,
		{"word":"urologue","word_nosc":"urologue","lemma":"urologue","pos":"NOM"} ,
		{"word":"uroscopie","word_nosc":"uroscopie","lemma":"uroscopie","pos":"NOM"} ,
		{"word":"ursuline","word_nosc":"ursuline","lemma":"ursuline","pos":"NOM"} ,
		{"word":"ursulines","word_nosc":"ursulines","lemma":"ursuline","pos":"NOM"} ,
		{"word":"urticaire","word_nosc":"urticaire","lemma":"urticaire","pos":"NOM"} ,
		{"word":"urubu","word_nosc":"urubu","lemma":"urubu","pos":"NOM"} ,
		{"word":"urubus","word_nosc":"urubus","lemma":"urubu","pos":"NOM"} ,
		{"word":"uruguayen","word_nosc":"uruguayen","lemma":"uruguayen","pos":"NOM"} ,
		{"word":"urus","word_nosc":"urus","lemma":"urus","pos":"NOM"} ,
		{"word":"urètre","word_nosc":"uretre","lemma":"urètre","pos":"NOM"} ,
		{"word":"urée","word_nosc":"uree","lemma":"urée","pos":"NOM"} ,
		{"word":"urémie","word_nosc":"uremie","lemma":"urémie","pos":"NOM"} ,
		{"word":"uréthane","word_nosc":"urethane","lemma":"uréthane","pos":"NOM"} ,
		{"word":"urétrite","word_nosc":"uretrite","lemma":"urétrite","pos":"NOM"} ,
		{"word":"us","word_nosc":"us","lemma":"us","pos":"NOM"} ,
		{"word":"usage","word_nosc":"usage","lemma":"usage","pos":"NOM"} ,
		{"word":"usager","word_nosc":"usager","lemma":"usager","pos":"NOM"} ,
		{"word":"usagers","word_nosc":"usagers","lemma":"usager","pos":"NOM"} ,
		{"word":"usages","word_nosc":"usages","lemma":"usage","pos":"NOM"} ,
		{"word":"usagères","word_nosc":"usageres","lemma":"usager","pos":"NOM"} ,
		{"word":"usinage","word_nosc":"usinage","lemma":"usinage","pos":"NOM"} ,
		{"word":"usine","word_nosc":"usine","lemma":"usine","pos":"NOM"} ,
		{"word":"usine-pilote","word_nosc":"usine-pilote","lemma":"usine-pilote","pos":"NOM"} ,
		{"word":"usines","word_nosc":"usines","lemma":"usine","pos":"NOM"} ,
		{"word":"usiniers","word_nosc":"usiniers","lemma":"usinier","pos":"NOM"} ,
		{"word":"ustensile","word_nosc":"ustensile","lemma":"ustensile","pos":"NOM"} ,
		{"word":"ustensiles","word_nosc":"ustensiles","lemma":"ustensile","pos":"NOM"} ,
		{"word":"ustion","word_nosc":"ustion","lemma":"ustion","pos":"NOM"} ,
		{"word":"usufruit","word_nosc":"usufruit","lemma":"usufruit","pos":"NOM"} ,
		{"word":"usufruitier","word_nosc":"usufruitier","lemma":"usufruitier","pos":"NOM"} ,
		{"word":"usure","word_nosc":"usure","lemma":"usure","pos":"NOM"} ,
		{"word":"usures","word_nosc":"usures","lemma":"usure","pos":"NOM"} ,
		{"word":"usurier","word_nosc":"usurier","lemma":"usurier","pos":"NOM"} ,
		{"word":"usuriers","word_nosc":"usuriers","lemma":"usurier","pos":"NOM"} ,
		{"word":"usurière","word_nosc":"usuriere","lemma":"usurier","pos":"NOM"} ,
		{"word":"usurpateur","word_nosc":"usurpateur","lemma":"usurpateur","pos":"NOM"} ,
		{"word":"usurpateurs","word_nosc":"usurpateurs","lemma":"usurpateur","pos":"NOM"} ,
		{"word":"usurpation","word_nosc":"usurpation","lemma":"usurpation","pos":"NOM"} ,
		{"word":"usurpatrice","word_nosc":"usurpatrice","lemma":"usurpateur","pos":"NOM"} ,
		{"word":"ut","word_nosc":"ut","lemma":"ut","pos":"NOM"} ,
		{"word":"utile","word_nosc":"utile","lemma":"utile","pos":"NOM"} ,
		{"word":"utilisateur","word_nosc":"utilisateur","lemma":"utilisateur","pos":"NOM"} ,
		{"word":"utilisateurs","word_nosc":"utilisateurs","lemma":"utilisateur","pos":"NOM"} ,
		{"word":"utilisation","word_nosc":"utilisation","lemma":"utilisation","pos":"NOM"} ,
		{"word":"utilisations","word_nosc":"utilisations","lemma":"utilisation","pos":"NOM"} ,
		{"word":"utilisatrice","word_nosc":"utilisatrice","lemma":"utilisateur","pos":"NOM"} ,
		{"word":"utilitaire","word_nosc":"utilitaire","lemma":"utilitaire","pos":"NOM"} ,
		{"word":"utilitaires","word_nosc":"utilitaires","lemma":"utilitaire","pos":"NOM"} ,
		{"word":"utilitarisme","word_nosc":"utilitarisme","lemma":"utilitarisme","pos":"NOM"} ,
		{"word":"utilité","word_nosc":"utilite","lemma":"utilité","pos":"NOM"} ,
		{"word":"utilités","word_nosc":"utilites","lemma":"utilité","pos":"NOM"} ,
		{"word":"utopie","word_nosc":"utopie","lemma":"utopie","pos":"NOM"} ,
		{"word":"utopies","word_nosc":"utopies","lemma":"utopie","pos":"NOM"} ,
		{"word":"utopiste","word_nosc":"utopiste","lemma":"utopiste","pos":"NOM"} ,
		{"word":"utopistes","word_nosc":"utopistes","lemma":"utopiste","pos":"NOM"} ,
		{"word":"utérus","word_nosc":"uterus","lemma":"utérus","pos":"NOM"} ,
		{"word":"v","word_nosc":"v","lemma":"v","pos":"NOM"} ,
		{"word":"va","word_nosc":"va","lemma":"va","pos":"NOM"} ,
		{"word":"va-et-vient","word_nosc":"va-et-vient","lemma":"va-et-vient","pos":"NOM"} ,
		{"word":"va-tout","word_nosc":"va-tout","lemma":"va-tout","pos":"NOM"} ,
		{"word":"vacance","word_nosc":"vacance","lemma":"vacance","pos":"NOM"} ,
		{"word":"vacances","word_nosc":"vacances","lemma":"vacance","pos":"NOM"} ,
		{"word":"vacancier","word_nosc":"vacancier","lemma":"vacancier","pos":"NOM"} ,
		{"word":"vacanciers","word_nosc":"vacanciers","lemma":"vacancier","pos":"NOM"} ,
		{"word":"vacancière","word_nosc":"vacanciere","lemma":"vacancier","pos":"NOM"} ,
		{"word":"vacancières","word_nosc":"vacancieres","lemma":"vacancier","pos":"NOM"} ,
		{"word":"vacarme","word_nosc":"vacarme","lemma":"vacarme","pos":"NOM"} ,
		{"word":"vacarmes","word_nosc":"vacarmes","lemma":"vacarme","pos":"NOM"} ,
		{"word":"vacataire","word_nosc":"vacataire","lemma":"vacataire","pos":"NOM"} ,
		{"word":"vacation","word_nosc":"vacation","lemma":"vacation","pos":"NOM"} ,
		{"word":"vacations","word_nosc":"vacations","lemma":"vacation","pos":"NOM"} ,
		{"word":"vaccin","word_nosc":"vaccin","lemma":"vaccin","pos":"NOM"} ,
		{"word":"vaccination","word_nosc":"vaccination","lemma":"vaccination","pos":"NOM"} ,
		{"word":"vaccinations","word_nosc":"vaccinations","lemma":"vaccination","pos":"NOM"} ,
		{"word":"vaccine","word_nosc":"vaccine","lemma":"vaccine","pos":"NOM"} ,
		{"word":"vaccins","word_nosc":"vaccins","lemma":"vaccin","pos":"NOM"} ,
		{"word":"vachardise","word_nosc":"vachardise","lemma":"vachardise","pos":"NOM"} ,
		{"word":"vache","word_nosc":"vache","lemma":"vache","pos":"NOM"} ,
		{"word":"vacher","word_nosc":"vacher","lemma":"vacher","pos":"NOM"} ,
		{"word":"vacherie","word_nosc":"vacherie","lemma":"vacherie","pos":"NOM"} ,
		{"word":"vacheries","word_nosc":"vacheries","lemma":"vacherie","pos":"NOM"} ,
		{"word":"vacherin","word_nosc":"vacherin","lemma":"vacherin","pos":"NOM"} ,
		{"word":"vachers","word_nosc":"vachers","lemma":"vacher","pos":"NOM"} ,
		{"word":"vaches","word_nosc":"vaches","lemma":"vache","pos":"NOM"} ,
		{"word":"vachette","word_nosc":"vachette","lemma":"vachette","pos":"NOM"} ,
		{"word":"vachettes","word_nosc":"vachettes","lemma":"vachette","pos":"NOM"} ,
		{"word":"vachère","word_nosc":"vachere","lemma":"vachère","pos":"NOM"} ,
		{"word":"vachères","word_nosc":"vacheres","lemma":"vachère","pos":"NOM"} ,
		{"word":"vacillations","word_nosc":"vacillations","lemma":"vacillation","pos":"NOM"} ,
		{"word":"vacillement","word_nosc":"vacillement","lemma":"vacillement","pos":"NOM"} ,
		{"word":"vacuité","word_nosc":"vacuite","lemma":"vacuité","pos":"NOM"} ,
		{"word":"vacuole","word_nosc":"vacuole","lemma":"vacuole","pos":"NOM"} ,
		{"word":"vacuolisation","word_nosc":"vacuolisation","lemma":"vacuolisation","pos":"NOM"} ,
		{"word":"vacuum","word_nosc":"vacuum","lemma":"vacuum","pos":"NOM"} ,
		{"word":"vadrouille","word_nosc":"vadrouille","lemma":"vadrouille","pos":"NOM"} ,
		{"word":"vadrouilles","word_nosc":"vadrouilles","lemma":"vadrouille","pos":"NOM"} ,
		{"word":"vadrouilleur","word_nosc":"vadrouilleur","lemma":"vadrouilleur","pos":"NOM"} ,
		{"word":"vadrouilleurs","word_nosc":"vadrouilleurs","lemma":"vadrouilleur","pos":"NOM"} ,
		{"word":"vadrouilleuse","word_nosc":"vadrouilleuse","lemma":"vadrouilleur","pos":"NOM"} ,
		{"word":"vadrouilleuses","word_nosc":"vadrouilleuses","lemma":"vadrouilleur","pos":"NOM"} ,
		{"word":"vagabond","word_nosc":"vagabond","lemma":"vagabond","pos":"NOM"} ,
		{"word":"vagabondage","word_nosc":"vagabondage","lemma":"vagabondage","pos":"NOM"} ,
		{"word":"vagabondages","word_nosc":"vagabondages","lemma":"vagabondage","pos":"NOM"} ,
		{"word":"vagabonde","word_nosc":"vagabonde","lemma":"vagabond","pos":"NOM"} ,
		{"word":"vagabondes","word_nosc":"vagabondes","lemma":"vagabond","pos":"NOM"} ,
		{"word":"vagabonds","word_nosc":"vagabonds","lemma":"vagabond","pos":"NOM"} ,
		{"word":"vagin","word_nosc":"vagin","lemma":"vagin","pos":"NOM"} ,
		{"word":"vaginite","word_nosc":"vaginite","lemma":"vaginite","pos":"NOM"} ,
		{"word":"vagins","word_nosc":"vagins","lemma":"vagin","pos":"NOM"} ,
		{"word":"vagissement","word_nosc":"vagissement","lemma":"vagissement","pos":"NOM"} ,
		{"word":"vagissements","word_nosc":"vagissements","lemma":"vagissement","pos":"NOM"} ,
		{"word":"vagotomie","word_nosc":"vagotomie","lemma":"vagotomie","pos":"NOM"} ,
		{"word":"vague","word_nosc":"vague","lemma":"vague","pos":"NOM"} ,
		{"word":"vaguelette","word_nosc":"vaguelette","lemma":"vaguelette","pos":"NOM"} ,
		{"word":"vaguelettes","word_nosc":"vaguelettes","lemma":"vaguelette","pos":"NOM"} ,
		{"word":"vaguemestre","word_nosc":"vaguemestre","lemma":"vaguemestre","pos":"NOM"} ,
		{"word":"vagues","word_nosc":"vagues","lemma":"vague","pos":"NOM"} ,
		{"word":"vahiné","word_nosc":"vahine","lemma":"vahiné","pos":"NOM"} ,
		{"word":"vahinés","word_nosc":"vahines","lemma":"vahiné","pos":"NOM"} ,
		{"word":"vaillance","word_nosc":"vaillance","lemma":"vaillance","pos":"NOM"} ,
		{"word":"vaincu","word_nosc":"vaincu","lemma":"vaincu","pos":"NOM"} ,
		{"word":"vaincue","word_nosc":"vaincue","lemma":"vaincu","pos":"NOM"} ,
		{"word":"vaincues","word_nosc":"vaincues","lemma":"vaincu","pos":"NOM"} ,
		{"word":"vaincus","word_nosc":"vaincus","lemma":"vaincu","pos":"NOM"} ,
		{"word":"vainqueur","word_nosc":"vainqueur","lemma":"vainqueur","pos":"NOM"} ,
		{"word":"vainqueurs","word_nosc":"vainqueurs","lemma":"vainqueur","pos":"NOM"} ,
		{"word":"vair","word_nosc":"vair","lemma":"vair","pos":"NOM"} ,
		{"word":"vairon","word_nosc":"vairon","lemma":"vairon","pos":"NOM"} ,
		{"word":"vairons","word_nosc":"vairons","lemma":"vairon","pos":"NOM"} ,
		{"word":"vaisseau","word_nosc":"vaisseau","lemma":"vaisseau","pos":"NOM"} ,
		{"word":"vaisseau-amiral","word_nosc":"vaisseau-amiral","lemma":"vaisseau-amiral","pos":"NOM"} ,
		{"word":"vaisseaux","word_nosc":"vaisseaux","lemma":"vaisseau","pos":"NOM"} ,
		{"word":"vaisselier","word_nosc":"vaisselier","lemma":"vaisselier","pos":"NOM"} ,
		{"word":"vaisseliers","word_nosc":"vaisseliers","lemma":"vaisselier","pos":"NOM"} ,
		{"word":"vaisselle","word_nosc":"vaisselle","lemma":"vaisselle","pos":"NOM"} ,
		{"word":"vaisselles","word_nosc":"vaisselles","lemma":"vaisselle","pos":"NOM"} ,
		{"word":"val","word_nosc":"val","lemma":"val","pos":"NOM"} ,
		{"word":"valaque","word_nosc":"valaque","lemma":"valaque","pos":"NOM"} ,
		{"word":"valdôtains","word_nosc":"valdotains","lemma":"valdôtain","pos":"NOM"} ,
		{"word":"valence","word_nosc":"valence","lemma":"valence","pos":"NOM"} ,
		{"word":"valencien","word_nosc":"valencien","lemma":"valencien","pos":"NOM"} ,
		{"word":"valencienne","word_nosc":"valencienne","lemma":"valencien","pos":"NOM"} ,
		{"word":"valentin","word_nosc":"valentin","lemma":"valentin","pos":"NOM"} ,
		{"word":"valentine","word_nosc":"valentine","lemma":"valentin","pos":"NOM"} ,
		{"word":"valentinien","word_nosc":"valentinien","lemma":"valentinien","pos":"NOM"} ,
		{"word":"valet","word_nosc":"valet","lemma":"valet","pos":"NOM"} ,
		{"word":"valetaille","word_nosc":"valetaille","lemma":"valetaille","pos":"NOM"} ,
		{"word":"valets","word_nosc":"valets","lemma":"valet","pos":"NOM"} ,
		{"word":"valeur","word_nosc":"valeur","lemma":"valeur","pos":"NOM"} ,
		{"word":"valeur-refuge","word_nosc":"valeur-refuge","lemma":"valeur-refuge","pos":"NOM"} ,
		{"word":"valeurs","word_nosc":"valeurs","lemma":"valeur","pos":"NOM"} ,
		{"word":"validation","word_nosc":"validation","lemma":"validation","pos":"NOM"} ,
		{"word":"validité","word_nosc":"validite","lemma":"validité","pos":"NOM"} ,
		{"word":"validé","word_nosc":"valide","lemma":"validé","pos":"NOM"} ,
		{"word":"valise","word_nosc":"valise","lemma":"valise","pos":"NOM"} ,
		{"word":"valises","word_nosc":"valises","lemma":"valise","pos":"NOM"} ,
		{"word":"valkyries","word_nosc":"valkyries","lemma":"valkyrie","pos":"NOM"} ,
		{"word":"valleuse","word_nosc":"valleuse","lemma":"valleuse","pos":"NOM"} ,
		{"word":"vallon","word_nosc":"vallon","lemma":"vallon","pos":"NOM"} ,
		{"word":"vallonnement","word_nosc":"vallonnement","lemma":"vallonnement","pos":"NOM"} ,
		{"word":"vallonnements","word_nosc":"vallonnements","lemma":"vallonnement","pos":"NOM"} ,
		{"word":"vallons","word_nosc":"vallons","lemma":"vallon","pos":"NOM"} ,
		{"word":"vallée","word_nosc":"vallee","lemma":"vallée","pos":"NOM"} ,
		{"word":"vallées","word_nosc":"vallees","lemma":"vallée","pos":"NOM"} ,
		{"word":"valoche","word_nosc":"valoche","lemma":"valoche","pos":"NOM"} ,
		{"word":"valoches","word_nosc":"valoches","lemma":"valoche","pos":"NOM"} ,
		{"word":"valorisation","word_nosc":"valorisation","lemma":"valorisation","pos":"NOM"} ,
		{"word":"valpolicella","word_nosc":"valpolicella","lemma":"valpolicella","pos":"NOM"} ,
		{"word":"vals","word_nosc":"vals","lemma":"val","pos":"NOM"} ,
		{"word":"valse","word_nosc":"valse","lemma":"valse","pos":"NOM"} ,
		{"word":"valse-hésitation","word_nosc":"valse-hesitation","lemma":"valse-hésitation","pos":"NOM"} ,
		{"word":"valses","word_nosc":"valses","lemma":"valse","pos":"NOM"} ,
		{"word":"valses-hésitations","word_nosc":"valses-hesitations","lemma":"valse-hésitation","pos":"NOM"} ,
		{"word":"valseur","word_nosc":"valseur","lemma":"valseur","pos":"NOM"} ,
		{"word":"valseurs","word_nosc":"valseurs","lemma":"valseur","pos":"NOM"} ,
		{"word":"valseuse","word_nosc":"valseuse","lemma":"valseur","pos":"NOM"} ,
		{"word":"valseuses","word_nosc":"valseuses","lemma":"valseur","pos":"NOM"} ,
		{"word":"valve","word_nosc":"valve","lemma":"valve","pos":"NOM"} ,
		{"word":"valves","word_nosc":"valves","lemma":"valve","pos":"NOM"} ,
		{"word":"valvule","word_nosc":"valvule","lemma":"valvule","pos":"NOM"} ,
		{"word":"valériane","word_nosc":"valeriane","lemma":"valériane","pos":"NOM"} ,
		{"word":"vamp","word_nosc":"vamp","lemma":"vamp","pos":"NOM"} ,
		{"word":"vamp-club","word_nosc":"vamp-club","lemma":"vamp-club","pos":"NOM"} ,
		{"word":"vampire","word_nosc":"vampire","lemma":"vampire","pos":"NOM"} ,
		{"word":"vampires","word_nosc":"vampires","lemma":"vampire","pos":"NOM"} ,
		{"word":"vampirisme","word_nosc":"vampirisme","lemma":"vampirisme","pos":"NOM"} ,
		{"word":"vamps","word_nosc":"vamps","lemma":"vamp","pos":"NOM"} ,
		{"word":"van","word_nosc":"van","lemma":"van","pos":"NOM"} ,
		{"word":"vanadium","word_nosc":"vanadium","lemma":"vanadium","pos":"NOM"} ,
		{"word":"vandale","word_nosc":"vandale","lemma":"vandale","pos":"NOM"} ,
		{"word":"vandales","word_nosc":"vandales","lemma":"vandale","pos":"NOM"} ,
		{"word":"vandalisme","word_nosc":"vandalisme","lemma":"vandalisme","pos":"NOM"} ,
		{"word":"vandoises","word_nosc":"vandoises","lemma":"vandoise","pos":"NOM"} ,
		{"word":"vanille","word_nosc":"vanille","lemma":"vanille","pos":"NOM"} ,
		{"word":"vaniteuse","word_nosc":"vaniteuse","lemma":"vaniteux","pos":"NOM"} ,
		{"word":"vaniteux","word_nosc":"vaniteux","lemma":"vaniteux","pos":"NOM"} ,
		{"word":"vanity-case","word_nosc":"vanity-case","lemma":"vanity-case","pos":"NOM"} ,
		{"word":"vanité","word_nosc":"vanite","lemma":"vanité","pos":"NOM"} ,
		{"word":"vanités","word_nosc":"vanites","lemma":"vanité","pos":"NOM"} ,
		{"word":"vanne","word_nosc":"vanne","lemma":"vanne","pos":"NOM"} ,
		{"word":"vanneau","word_nosc":"vanneau","lemma":"vanneau","pos":"NOM"} ,
		{"word":"vanneaux","word_nosc":"vanneaux","lemma":"vanneau","pos":"NOM"} ,
		{"word":"vannerie","word_nosc":"vannerie","lemma":"vannerie","pos":"NOM"} ,
		{"word":"vanneries","word_nosc":"vanneries","lemma":"vannerie","pos":"NOM"} ,
		{"word":"vannes","word_nosc":"vannes","lemma":"vanne","pos":"NOM"} ,
		{"word":"vanneur","word_nosc":"vanneur","lemma":"vanneur","pos":"NOM"} ,
		{"word":"vanneurs","word_nosc":"vanneurs","lemma":"vanneur","pos":"NOM"} ,
		{"word":"vannier","word_nosc":"vannier","lemma":"vannier","pos":"NOM"} ,
		{"word":"vanniers","word_nosc":"vanniers","lemma":"vannier","pos":"NOM"} ,
		{"word":"vannières","word_nosc":"vannieres","lemma":"vannière","pos":"NOM"} ,
		{"word":"vans","word_nosc":"vans","lemma":"van","pos":"NOM"} ,
		{"word":"vantail","word_nosc":"vantail","lemma":"vantail","pos":"NOM"} ,
		{"word":"vantard","word_nosc":"vantard","lemma":"vantard","pos":"NOM"} ,
		{"word":"vantarde","word_nosc":"vantarde","lemma":"vantard","pos":"NOM"} ,
		{"word":"vantardise","word_nosc":"vantardise","lemma":"vantardise","pos":"NOM"} ,
		{"word":"vantardises","word_nosc":"vantardises","lemma":"vantardise","pos":"NOM"} ,
		{"word":"vantards","word_nosc":"vantards","lemma":"vantard","pos":"NOM"} ,
		{"word":"vantaux","word_nosc":"vantaux","lemma":"vantail","pos":"NOM"} ,
		{"word":"vanterie","word_nosc":"vanterie","lemma":"vanterie","pos":"NOM"} ,
		{"word":"vanteries","word_nosc":"vanteries","lemma":"vanterie","pos":"NOM"} ,
		{"word":"vape","word_nosc":"vape","lemma":"vape","pos":"NOM"} ,
		{"word":"vapes","word_nosc":"vapes","lemma":"vape","pos":"NOM"} ,
		{"word":"vapeur","word_nosc":"vapeur","lemma":"vapeur","pos":"NOM"} ,
		{"word":"vapeurs","word_nosc":"vapeurs","lemma":"vapeur","pos":"NOM"} ,
		{"word":"vaporetto","word_nosc":"vaporetto","lemma":"vaporetto","pos":"NOM"} ,
		{"word":"vaporisateur","word_nosc":"vaporisateur","lemma":"vaporisateur","pos":"NOM"} ,
		{"word":"vaporisateurs","word_nosc":"vaporisateurs","lemma":"vaporisateur","pos":"NOM"} ,
		{"word":"vaporisation","word_nosc":"vaporisation","lemma":"vaporisation","pos":"NOM"} ,
		{"word":"vaporisations","word_nosc":"vaporisations","lemma":"vaporisation","pos":"NOM"} ,
		{"word":"vaps","word_nosc":"vaps","lemma":"vaps","pos":"NOM"} ,
		{"word":"vaquero","word_nosc":"vaquero","lemma":"vaquero","pos":"NOM"} ,
		{"word":"vaqueros","word_nosc":"vaqueros","lemma":"vaquero","pos":"NOM"} ,
		{"word":"var","word_nosc":"var","lemma":"var","pos":"NOM"} ,
		{"word":"varan","word_nosc":"varan","lemma":"varan","pos":"NOM"} ,
		{"word":"varans","word_nosc":"varans","lemma":"varan","pos":"NOM"} ,
		{"word":"varappe","word_nosc":"varappe","lemma":"varappe","pos":"NOM"} ,
		{"word":"varappeur","word_nosc":"varappeur","lemma":"varappeur","pos":"NOM"} ,
		{"word":"varech","word_nosc":"varech","lemma":"varech","pos":"NOM"} ,
		{"word":"varechs","word_nosc":"varechs","lemma":"varech","pos":"NOM"} ,
		{"word":"varenne","word_nosc":"varenne","lemma":"varenne","pos":"NOM"} ,
		{"word":"varennes","word_nosc":"varennes","lemma":"varenne","pos":"NOM"} ,
		{"word":"vareuse","word_nosc":"vareuse","lemma":"vareuse","pos":"NOM"} ,
		{"word":"vareuses","word_nosc":"vareuses","lemma":"vareuse","pos":"NOM"} ,
		{"word":"varia","word_nosc":"varia","lemma":"varia","pos":"NOM"} ,
		{"word":"variabilité","word_nosc":"variabilite","lemma":"variabilité","pos":"NOM"} ,
		{"word":"variable","word_nosc":"variable","lemma":"variable","pos":"NOM"} ,
		{"word":"variables","word_nosc":"variables","lemma":"variable","pos":"NOM"} ,
		{"word":"variance","word_nosc":"variance","lemma":"variance","pos":"NOM"} ,
		{"word":"variante","word_nosc":"variante","lemma":"variante","pos":"NOM"} ,
		{"word":"variantes","word_nosc":"variantes","lemma":"variante","pos":"NOM"} ,
		{"word":"variateur","word_nosc":"variateur","lemma":"variateur","pos":"NOM"} ,
		{"word":"variation","word_nosc":"variation","lemma":"variation","pos":"NOM"} ,
		{"word":"variations","word_nosc":"variations","lemma":"variation","pos":"NOM"} ,
		{"word":"varice","word_nosc":"varice","lemma":"varice","pos":"NOM"} ,
		{"word":"varicelle","word_nosc":"varicelle","lemma":"varicelle","pos":"NOM"} ,
		{"word":"varices","word_nosc":"varices","lemma":"varice","pos":"NOM"} ,
		{"word":"variole","word_nosc":"variole","lemma":"variole","pos":"NOM"} ,
		{"word":"variorum","word_nosc":"variorum","lemma":"variorum","pos":"NOM"} ,
		{"word":"variété","word_nosc":"variete","lemma":"variété","pos":"NOM"} ,
		{"word":"variétés","word_nosc":"varietes","lemma":"variété","pos":"NOM"} ,
		{"word":"varlet","word_nosc":"varlet","lemma":"varlet","pos":"NOM"} ,
		{"word":"varlope","word_nosc":"varlope","lemma":"varlope","pos":"NOM"} ,
		{"word":"varon","word_nosc":"varon","lemma":"varon","pos":"NOM"} ,
		{"word":"varsovien","word_nosc":"varsovien","lemma":"varsovien","pos":"NOM"} ,
		{"word":"vasais","word_nosc":"vasais","lemma":"vasais","pos":"NOM"} ,
		{"word":"vasculo-nerveux","word_nosc":"vasculo-nerveux","lemma":"vasculo-nerveux","pos":"NOM"} ,
		{"word":"vase","word_nosc":"vase","lemma":"vase","pos":"NOM"} ,
		{"word":"vasectomie","word_nosc":"vasectomie","lemma":"vasectomie","pos":"NOM"} ,
		{"word":"vaseline","word_nosc":"vaseline","lemma":"vaseline","pos":"NOM"} ,
		{"word":"vases","word_nosc":"vases","lemma":"vase","pos":"NOM"} ,
		{"word":"vasistas","word_nosc":"vasistas","lemma":"vasistas","pos":"NOM"} ,
		{"word":"vasière","word_nosc":"vasiere","lemma":"vasière","pos":"NOM"} ,
		{"word":"vasières","word_nosc":"vasieres","lemma":"vasière","pos":"NOM"} ,
		{"word":"vasoconstriction","word_nosc":"vasoconstriction","lemma":"vasoconstriction","pos":"NOM"} ,
		{"word":"vasodilatation","word_nosc":"vasodilatation","lemma":"vasodilatation","pos":"NOM"} ,
		{"word":"vasopressine","word_nosc":"vasopressine","lemma":"vasopressine","pos":"NOM"} ,
		{"word":"vasque","word_nosc":"vasque","lemma":"vasque","pos":"NOM"} ,
		{"word":"vasques","word_nosc":"vasques","lemma":"vasque","pos":"NOM"} ,
		{"word":"vassal","word_nosc":"vassal","lemma":"vassal","pos":"NOM"} ,
		{"word":"vassale","word_nosc":"vassale","lemma":"vassal","pos":"NOM"} ,
		{"word":"vassalité","word_nosc":"vassalite","lemma":"vassalité","pos":"NOM"} ,
		{"word":"vassaux","word_nosc":"vassaux","lemma":"vassal","pos":"NOM"} ,
		{"word":"vastitude","word_nosc":"vastitude","lemma":"vastitude","pos":"NOM"} ,
		{"word":"vastitudes","word_nosc":"vastitudes","lemma":"vastitude","pos":"NOM"} ,
		{"word":"vastité","word_nosc":"vastite","lemma":"vastité","pos":"NOM"} ,
		{"word":"vater","word_nosc":"vater","lemma":"vater","pos":"NOM"} ,
		{"word":"vaticination","word_nosc":"vaticination","lemma":"vaticination","pos":"NOM"} ,
		{"word":"vaticinations","word_nosc":"vaticinations","lemma":"vaticination","pos":"NOM"} ,
		{"word":"vatères","word_nosc":"vateres","lemma":"vatère","pos":"NOM"} ,
		{"word":"vau","word_nosc":"vau","lemma":"vau","pos":"NOM"} ,
		{"word":"vau-l'eau","word_nosc":"vau-l'eau","lemma":"vau-l'eau","pos":"NOM"} ,
		{"word":"vaudeville","word_nosc":"vaudeville","lemma":"vaudeville","pos":"NOM"} ,
		{"word":"vaudevilles","word_nosc":"vaudevilles","lemma":"vaudeville","pos":"NOM"} ,
		{"word":"vaudois","word_nosc":"vaudois","lemma":"vaudois","pos":"NOM"} ,
		{"word":"vaudou","word_nosc":"vaudou","lemma":"vaudou","pos":"NOM"} ,
		{"word":"vaudouisme","word_nosc":"vaudouisme","lemma":"vaudouisme","pos":"NOM"} ,
		{"word":"vaudous","word_nosc":"vaudous","lemma":"vaudou","pos":"NOM"} ,
		{"word":"vaurien","word_nosc":"vaurien","lemma":"vaurien","pos":"NOM"} ,
		{"word":"vaurienne","word_nosc":"vaurienne","lemma":"vaurien","pos":"NOM"} ,
		{"word":"vauriens","word_nosc":"vauriens","lemma":"vaurien","pos":"NOM"} ,
		{"word":"vautour","word_nosc":"vautour","lemma":"vautour","pos":"NOM"} ,
		{"word":"vautours","word_nosc":"vautours","lemma":"vautour","pos":"NOM"} ,
		{"word":"vautre","word_nosc":"vautre","lemma":"vautre","pos":"NOM"} ,
		{"word":"vauvert","word_nosc":"vauvert","lemma":"vauvert","pos":"NOM"} ,
		{"word":"vaux","word_nosc":"vaux","lemma":"val","pos":"NOM"} ,
		{"word":"vauxhall","word_nosc":"vauxhall","lemma":"vauxhall","pos":"NOM"} ,
		{"word":"veau","word_nosc":"veau","lemma":"veau","pos":"NOM"} ,
		{"word":"veaux","word_nosc":"veaux","lemma":"veau","pos":"NOM"} ,
		{"word":"vecteur","word_nosc":"vecteur","lemma":"vecteur","pos":"NOM"} ,
		{"word":"vecteurs","word_nosc":"vecteurs","lemma":"vecteur","pos":"NOM"} ,
		{"word":"vedettariat","word_nosc":"vedettariat","lemma":"vedettariat","pos":"NOM"} ,
		{"word":"vedette","word_nosc":"vedette","lemma":"vedette","pos":"NOM"} ,
		{"word":"vedettes","word_nosc":"vedettes","lemma":"vedette","pos":"NOM"} ,
		{"word":"veille","word_nosc":"veille","lemma":"veille","pos":"NOM"} ,
		{"word":"veilles","word_nosc":"veilles","lemma":"veille","pos":"NOM"} ,
		{"word":"veilleur","word_nosc":"veilleur","lemma":"veilleur","pos":"NOM"} ,
		{"word":"veilleurs","word_nosc":"veilleurs","lemma":"veilleur","pos":"NOM"} ,
		{"word":"veilleuse","word_nosc":"veilleuse","lemma":"veilleuse","pos":"NOM"} ,
		{"word":"veilleuses","word_nosc":"veilleuses","lemma":"veilleuse","pos":"NOM"} ,
		{"word":"veillée","word_nosc":"veillee","lemma":"veillée","pos":"NOM"} ,
		{"word":"veillées","word_nosc":"veillees","lemma":"veillée","pos":"NOM"} ,
		{"word":"veinard","word_nosc":"veinard","lemma":"veinard","pos":"NOM"} ,
		{"word":"veinarde","word_nosc":"veinarde","lemma":"veinard","pos":"NOM"} ,
		{"word":"veinardes","word_nosc":"veinardes","lemma":"veinard","pos":"NOM"} ,
		{"word":"veinards","word_nosc":"veinards","lemma":"veinard","pos":"NOM"} ,
		{"word":"veine","word_nosc":"veine","lemma":"veine","pos":"NOM"} ,
		{"word":"veines","word_nosc":"veines","lemma":"veine","pos":"NOM"} ,
		{"word":"veinule","word_nosc":"veinule","lemma":"veinule","pos":"NOM"} ,
		{"word":"veinules","word_nosc":"veinules","lemma":"veinule","pos":"NOM"} ,
		{"word":"veinures","word_nosc":"veinures","lemma":"veinure","pos":"NOM"} ,
		{"word":"velcro","word_nosc":"velcro","lemma":"velcro","pos":"NOM"} ,
		{"word":"veld","word_nosc":"veld","lemma":"veld","pos":"NOM"} ,
		{"word":"veldt","word_nosc":"veldt","lemma":"veldt","pos":"NOM"} ,
		{"word":"velléité","word_nosc":"velleite","lemma":"velléité","pos":"NOM"} ,
		{"word":"velléités","word_nosc":"velleites","lemma":"velléité","pos":"NOM"} ,
		{"word":"velours","word_nosc":"velours","lemma":"velours","pos":"NOM"} ,
		{"word":"velouté","word_nosc":"veloute","lemma":"velouté","pos":"NOM"} ,
		{"word":"veloutés","word_nosc":"veloutes","lemma":"velouté","pos":"NOM"} ,
		{"word":"velte","word_nosc":"velte","lemma":"velte","pos":"NOM"} ,
		{"word":"velum","word_nosc":"velum","lemma":"velum","pos":"NOM"} ,
		{"word":"velux","word_nosc":"velux","lemma":"velux","pos":"NOM"} ,
		{"word":"velvet","word_nosc":"velvet","lemma":"velvet","pos":"NOM"} ,
		{"word":"venaison","word_nosc":"venaison","lemma":"venaison","pos":"NOM"} ,
		{"word":"venaisons","word_nosc":"venaisons","lemma":"venaison","pos":"NOM"} ,
		{"word":"venant","word_nosc":"venant","lemma":"venant","pos":"NOM"} ,
		{"word":"vendange","word_nosc":"vendange","lemma":"vendange","pos":"NOM"} ,
		{"word":"vendanges","word_nosc":"vendanges","lemma":"vendange","pos":"NOM"} ,
		{"word":"vendangeur","word_nosc":"vendangeur","lemma":"vendangeur","pos":"NOM"} ,
		{"word":"vendangeurs","word_nosc":"vendangeurs","lemma":"vendangeur","pos":"NOM"} ,
		{"word":"vendangeuses","word_nosc":"vendangeuses","lemma":"vendangeur","pos":"NOM"} ,
		{"word":"vendetta","word_nosc":"vendetta","lemma":"vendetta","pos":"NOM"} ,
		{"word":"vendettas","word_nosc":"vendettas","lemma":"vendetta","pos":"NOM"} ,
		{"word":"vendeur","word_nosc":"vendeur","lemma":"vendeur","pos":"NOM"} ,
		{"word":"vendeurs","word_nosc":"vendeurs","lemma":"vendeur","pos":"NOM"} ,
		{"word":"vendeuse","word_nosc":"vendeuse","lemma":"vendeur","pos":"NOM"} ,
		{"word":"vendeuses","word_nosc":"vendeuses","lemma":"vendeur","pos":"NOM"} ,
		{"word":"vendredi","word_nosc":"vendredi","lemma":"vendredi","pos":"NOM"} ,
		{"word":"vendredis","word_nosc":"vendredis","lemma":"vendredi","pos":"NOM"} ,
		{"word":"vendu","word_nosc":"vendu","lemma":"vendu","pos":"NOM"} ,
		{"word":"vendue","word_nosc":"vendue","lemma":"vendu","pos":"NOM"} ,
		{"word":"vendues","word_nosc":"vendues","lemma":"vendu","pos":"NOM"} ,
		{"word":"vendus","word_nosc":"vendus","lemma":"vendu","pos":"NOM"} ,
		{"word":"vendéen","word_nosc":"vendeen","lemma":"vendéen","pos":"NOM"} ,
		{"word":"vendémiaire","word_nosc":"vendemiaire","lemma":"vendémiaire","pos":"NOM"} ,
		{"word":"venelle","word_nosc":"venelle","lemma":"venelle","pos":"NOM"} ,
		{"word":"venelles","word_nosc":"venelles","lemma":"venelle","pos":"NOM"} ,
		{"word":"venette","word_nosc":"venette","lemma":"venette","pos":"NOM"} ,
		{"word":"veneur","word_nosc":"veneur","lemma":"veneur","pos":"NOM"} ,
		{"word":"veneurs","word_nosc":"veneurs","lemma":"veneur","pos":"NOM"} ,
		{"word":"vengeance","word_nosc":"vengeance","lemma":"vengeance","pos":"NOM"} ,
		{"word":"vengeances","word_nosc":"vengeances","lemma":"vengeance","pos":"NOM"} ,
		{"word":"vengeresse","word_nosc":"vengeresse","lemma":"vengeur","pos":"NOM"} ,
		{"word":"vengeur","word_nosc":"vengeur","lemma":"vengeur","pos":"NOM"} ,
		{"word":"vengeurs","word_nosc":"vengeurs","lemma":"vengeur","pos":"NOM"} ,
		{"word":"venin","word_nosc":"venin","lemma":"venin","pos":"NOM"} ,
		{"word":"venins","word_nosc":"venins","lemma":"venin","pos":"NOM"} ,
		{"word":"venise","word_nosc":"venise","lemma":"venise","pos":"NOM"} ,
		{"word":"vent","word_nosc":"vent","lemma":"vent","pos":"NOM"} ,
		{"word":"ventail","word_nosc":"ventail","lemma":"ventail","pos":"NOM"} ,
		{"word":"vente","word_nosc":"vente","lemma":"vente","pos":"NOM"} ,
		{"word":"ventes","word_nosc":"ventes","lemma":"vente","pos":"NOM"} ,
		{"word":"ventilateur","word_nosc":"ventilateur","lemma":"ventilateur","pos":"NOM"} ,
		{"word":"ventilateurs","word_nosc":"ventilateurs","lemma":"ventilateur","pos":"NOM"} ,
		{"word":"ventilation","word_nosc":"ventilation","lemma":"ventilation","pos":"NOM"} ,
		{"word":"ventilations","word_nosc":"ventilations","lemma":"ventilation","pos":"NOM"} ,
		{"word":"ventilo","word_nosc":"ventilo","lemma":"ventilo","pos":"NOM"} ,
		{"word":"ventilos","word_nosc":"ventilos","lemma":"ventilo","pos":"NOM"} ,
		{"word":"ventis","word_nosc":"ventis","lemma":"ventis","pos":"NOM"} ,
		{"word":"ventouse","word_nosc":"ventouse","lemma":"ventouse","pos":"NOM"} ,
		{"word":"ventouses","word_nosc":"ventouses","lemma":"ventouse","pos":"NOM"} ,
		{"word":"ventre","word_nosc":"ventre","lemma":"ventre","pos":"NOM"} ,
		{"word":"ventres","word_nosc":"ventres","lemma":"ventre","pos":"NOM"} ,
		{"word":"ventricule","word_nosc":"ventricule","lemma":"ventricule","pos":"NOM"} ,
		{"word":"ventricules","word_nosc":"ventricules","lemma":"ventricule","pos":"NOM"} ,
		{"word":"ventriloque","word_nosc":"ventriloque","lemma":"ventriloque","pos":"NOM"} ,
		{"word":"ventriloques","word_nosc":"ventriloques","lemma":"ventriloque","pos":"NOM"} ,
		{"word":"ventriloquie","word_nosc":"ventriloquie","lemma":"ventriloquie","pos":"NOM"} ,
		{"word":"ventripotent","word_nosc":"ventripotent","lemma":"ventripotent","pos":"NOM"} ,
		{"word":"ventrière","word_nosc":"ventriere","lemma":"ventrière","pos":"NOM"} ,
		{"word":"ventrée","word_nosc":"ventree","lemma":"ventrée","pos":"NOM"} ,
		{"word":"ventrées","word_nosc":"ventrees","lemma":"ventrée","pos":"NOM"} ,
		{"word":"vents","word_nosc":"vents","lemma":"vent","pos":"NOM"} ,
		{"word":"ventôse","word_nosc":"ventose","lemma":"ventôse","pos":"NOM"} ,
		{"word":"venue","word_nosc":"venue","lemma":"venue","pos":"NOM"} ,
		{"word":"venues","word_nosc":"venues","lemma":"venue","pos":"NOM"} ,
		{"word":"venus","word_nosc":"venus","lemma":"venu","pos":"NOM"} ,
		{"word":"ver","word_nosc":"ver","lemma":"ver","pos":"NOM"} ,
		{"word":"verbalisation","word_nosc":"verbalisation","lemma":"verbalisation","pos":"NOM"} ,
		{"word":"verbalisme","word_nosc":"verbalisme","lemma":"verbalisme","pos":"NOM"} ,
		{"word":"verbaliste","word_nosc":"verbaliste","lemma":"verbaliste","pos":"NOM"} ,
		{"word":"verbatim","word_nosc":"verbatim","lemma":"verbatim","pos":"NOM"} ,
		{"word":"verbe","word_nosc":"verbe","lemma":"verbe","pos":"NOM"} ,
		{"word":"verbes","word_nosc":"verbes","lemma":"verbe","pos":"NOM"} ,
		{"word":"verbiage","word_nosc":"verbiage","lemma":"verbiage","pos":"NOM"} ,
		{"word":"verbosité","word_nosc":"verbosite","lemma":"verbosité","pos":"NOM"} ,
		{"word":"verbosités","word_nosc":"verbosites","lemma":"verbosité","pos":"NOM"} ,
		{"word":"verdeur","word_nosc":"verdeur","lemma":"verdeur","pos":"NOM"} ,
		{"word":"verdeurs","word_nosc":"verdeurs","lemma":"verdeur","pos":"NOM"} ,
		{"word":"verdict","word_nosc":"verdict","lemma":"verdict","pos":"NOM"} ,
		{"word":"verdicts","word_nosc":"verdicts","lemma":"verdict","pos":"NOM"} ,
		{"word":"verdier","word_nosc":"verdier","lemma":"verdier","pos":"NOM"} ,
		{"word":"verdiers","word_nosc":"verdiers","lemma":"verdier","pos":"NOM"} ,
		{"word":"verdine","word_nosc":"verdine","lemma":"verdin","pos":"NOM"} ,
		{"word":"verdoiement","word_nosc":"verdoiement","lemma":"verdoiement","pos":"NOM"} ,
		{"word":"verdure","word_nosc":"verdure","lemma":"verdure","pos":"NOM"} ,
		{"word":"verdures","word_nosc":"verdures","lemma":"verdure","pos":"NOM"} ,
		{"word":"verge","word_nosc":"verge","lemma":"verge","pos":"NOM"} ,
		{"word":"vergence","word_nosc":"vergence","lemma":"vergence","pos":"NOM"} ,
		{"word":"verger","word_nosc":"verger","lemma":"verger","pos":"NOM"} ,
		{"word":"vergers","word_nosc":"vergers","lemma":"verger","pos":"NOM"} ,
		{"word":"verges","word_nosc":"verges","lemma":"verge","pos":"NOM"} ,
		{"word":"vergeture","word_nosc":"vergeture","lemma":"vergeture","pos":"NOM"} ,
		{"word":"vergetures","word_nosc":"vergetures","lemma":"vergeture","pos":"NOM"} ,
		{"word":"verglas","word_nosc":"verglas","lemma":"verglas","pos":"NOM"} ,
		{"word":"vergne","word_nosc":"vergne","lemma":"vergne","pos":"NOM"} ,
		{"word":"vergnes","word_nosc":"vergnes","lemma":"vergne","pos":"NOM"} ,
		{"word":"vergogne","word_nosc":"vergogne","lemma":"vergogne","pos":"NOM"} ,
		{"word":"vergue","word_nosc":"vergue","lemma":"vergue","pos":"NOM"} ,
		{"word":"vergues","word_nosc":"vergues","lemma":"vergue","pos":"NOM"} ,
		{"word":"verjus","word_nosc":"verjus","lemma":"verjus","pos":"NOM"} ,
		{"word":"verlan","word_nosc":"verlan","lemma":"verlan","pos":"NOM"} ,
		{"word":"vermeil","word_nosc":"vermeil","lemma":"vermeil","pos":"NOM"} ,
		{"word":"vermicelle","word_nosc":"vermicelle","lemma":"vermicelle","pos":"NOM"} ,
		{"word":"vermicelles","word_nosc":"vermicelles","lemma":"vermicelle","pos":"NOM"} ,
		{"word":"vermifuge","word_nosc":"vermifuge","lemma":"vermifuge","pos":"NOM"} ,
		{"word":"vermillon","word_nosc":"vermillon","lemma":"vermillon","pos":"NOM"} ,
		{"word":"vermillons","word_nosc":"vermillons","lemma":"vermillon","pos":"NOM"} ,
		{"word":"vermine","word_nosc":"vermine","lemma":"vermine","pos":"NOM"} ,
		{"word":"vermines","word_nosc":"vermines","lemma":"vermine","pos":"NOM"} ,
		{"word":"vermisseau","word_nosc":"vermisseau","lemma":"vermisseau","pos":"NOM"} ,
		{"word":"vermisseaux","word_nosc":"vermisseaux","lemma":"vermisseau","pos":"NOM"} ,
		{"word":"vermoulure","word_nosc":"vermoulure","lemma":"vermoulure","pos":"NOM"} ,
		{"word":"vermoulures","word_nosc":"vermoulures","lemma":"vermoulure","pos":"NOM"} ,
		{"word":"vermout","word_nosc":"vermout","lemma":"vermout","pos":"NOM"} ,
		{"word":"vermouth","word_nosc":"vermouth","lemma":"vermouth","pos":"NOM"} ,
		{"word":"vermouths","word_nosc":"vermouths","lemma":"vermouth","pos":"NOM"} ,
		{"word":"verne","word_nosc":"verne","lemma":"verne","pos":"NOM"} ,
		{"word":"vernier","word_nosc":"vernier","lemma":"vernier","pos":"NOM"} ,
		{"word":"vernis","word_nosc":"vernis","lemma":"vernis","pos":"NOM"} ,
		{"word":"vernissage","word_nosc":"vernissage","lemma":"vernissage","pos":"NOM"} ,
		{"word":"vernissages","word_nosc":"vernissages","lemma":"vernissage","pos":"NOM"} ,
		{"word":"vernisseur","word_nosc":"vernisseur","lemma":"vernisseur","pos":"NOM"} ,
		{"word":"verrat","word_nosc":"verrat","lemma":"verrat","pos":"NOM"} ,
		{"word":"verrats","word_nosc":"verrats","lemma":"verrat","pos":"NOM"} ,
		{"word":"verre","word_nosc":"verre","lemma":"verre","pos":"NOM"} ,
		{"word":"verrerie","word_nosc":"verrerie","lemma":"verrerie","pos":"NOM"} ,
		{"word":"verreries","word_nosc":"verreries","lemma":"verrerie","pos":"NOM"} ,
		{"word":"verres","word_nosc":"verres","lemma":"verre","pos":"NOM"} ,
		{"word":"verriers","word_nosc":"verriers","lemma":"verrier","pos":"NOM"} ,
		{"word":"verrière","word_nosc":"verriere","lemma":"verrière","pos":"NOM"} ,
		{"word":"verrières","word_nosc":"verrieres","lemma":"verrière","pos":"NOM"} ,
		{"word":"verroterie","word_nosc":"verroterie","lemma":"verroterie","pos":"NOM"} ,
		{"word":"verroteries","word_nosc":"verroteries","lemma":"verroterie","pos":"NOM"} ,
		{"word":"verrou","word_nosc":"verrou","lemma":"verrou","pos":"NOM"} ,
		{"word":"verrouillage","word_nosc":"verrouillage","lemma":"verrouillage","pos":"NOM"} ,
		{"word":"verrous","word_nosc":"verrous","lemma":"verrou","pos":"NOM"} ,
		{"word":"verrue","word_nosc":"verrue","lemma":"verrue","pos":"NOM"} ,
		{"word":"verrues","word_nosc":"verrues","lemma":"verrue","pos":"NOM"} ,
		{"word":"vers","word_nosc":"vers","lemma":"vers","pos":"NOM"} ,
		{"word":"versaillais","word_nosc":"versaillais","lemma":"versaillais","pos":"NOM"} ,
		{"word":"versant","word_nosc":"versant","lemma":"versant","pos":"NOM"} ,
		{"word":"versants","word_nosc":"versants","lemma":"versant","pos":"NOM"} ,
		{"word":"versatilité","word_nosc":"versatilite","lemma":"versatilité","pos":"NOM"} ,
		{"word":"verse","word_nosc":"verse","lemma":"verse","pos":"NOM"} ,
		{"word":"verseau","word_nosc":"verseau","lemma":"verseau","pos":"NOM"} ,
		{"word":"versement","word_nosc":"versement","lemma":"versement","pos":"NOM"} ,
		{"word":"versements","word_nosc":"versements","lemma":"versement","pos":"NOM"} ,
		{"word":"verses","word_nosc":"verses","lemma":"verse","pos":"NOM"} ,
		{"word":"verset","word_nosc":"verset","lemma":"verset","pos":"NOM"} ,
		{"word":"versets","word_nosc":"versets","lemma":"verset","pos":"NOM"} ,
		{"word":"verseuse","word_nosc":"verseuse","lemma":"verseur","pos":"NOM"} ,
		{"word":"verseuses","word_nosc":"verseuses","lemma":"verseur","pos":"NOM"} ,
		{"word":"versiculets","word_nosc":"versiculets","lemma":"versiculet","pos":"NOM"} ,
		{"word":"versificateur","word_nosc":"versificateur","lemma":"versificateur","pos":"NOM"} ,
		{"word":"versification","word_nosc":"versification","lemma":"versification","pos":"NOM"} ,
		{"word":"version","word_nosc":"version","lemma":"version","pos":"NOM"} ,
		{"word":"versions","word_nosc":"versions","lemma":"version","pos":"NOM"} ,
		{"word":"verso","word_nosc":"verso","lemma":"verso","pos":"NOM"} ,
		{"word":"versos","word_nosc":"versos","lemma":"verso","pos":"NOM"} ,
		{"word":"verstes","word_nosc":"verstes","lemma":"verste","pos":"NOM"} ,
		{"word":"vert","word_nosc":"vert","lemma":"vert","pos":"NOM"} ,
		{"word":"vert-de-gris","word_nosc":"vert-de-gris","lemma":"vert-de-gris","pos":"NOM"} ,
		{"word":"vert-galant","word_nosc":"vert-galant","lemma":"vert-galant","pos":"NOM"} ,
		{"word":"vert-jaune","word_nosc":"vert-jaune","lemma":"vert-jaune","pos":"NOM"} ,
		{"word":"vertex","word_nosc":"vertex","lemma":"vertex","pos":"NOM"} ,
		{"word":"vertical","word_nosc":"vertical","lemma":"vertical","pos":"NOM"} ,
		{"word":"verticale","word_nosc":"verticale","lemma":"verticale","pos":"NOM"} ,
		{"word":"verticales","word_nosc":"verticales","lemma":"verticale","pos":"NOM"} ,
		{"word":"verticalité","word_nosc":"verticalite","lemma":"verticalité","pos":"NOM"} ,
		{"word":"verticille","word_nosc":"verticille","lemma":"verticille","pos":"NOM"} ,
		{"word":"vertige","word_nosc":"vertige","lemma":"vertige","pos":"NOM"} ,
		{"word":"vertiges","word_nosc":"vertiges","lemma":"vertige","pos":"NOM"} ,
		{"word":"vertigo","word_nosc":"vertigo","lemma":"vertigo","pos":"NOM"} ,
		{"word":"verts","word_nosc":"verts","lemma":"vert","pos":"NOM"} ,
		{"word":"vertu","word_nosc":"vertu","lemma":"vertu","pos":"NOM"} ,
		{"word":"vertugadin","word_nosc":"vertugadin","lemma":"vertugadin","pos":"NOM"} ,
		{"word":"vertus","word_nosc":"vertus","lemma":"vertu","pos":"NOM"} ,
		{"word":"vertèbre","word_nosc":"vertebre","lemma":"vertèbre","pos":"NOM"} ,
		{"word":"vertèbres","word_nosc":"vertebres","lemma":"vertèbre","pos":"NOM"} ,
		{"word":"vertébré","word_nosc":"vertebre","lemma":"vertébré","pos":"NOM"} ,
		{"word":"vertébrés","word_nosc":"vertebres","lemma":"vertébré","pos":"NOM"} ,
		{"word":"verve","word_nosc":"verve","lemma":"verve","pos":"NOM"} ,
		{"word":"verveine","word_nosc":"verveine","lemma":"verveine","pos":"NOM"} ,
		{"word":"vesce","word_nosc":"vesce","lemma":"vesce","pos":"NOM"} ,
		{"word":"vesces","word_nosc":"vesces","lemma":"vesce","pos":"NOM"} ,
		{"word":"vespa","word_nosc":"vespa","lemma":"vespa","pos":"NOM"} ,
		{"word":"vespas","word_nosc":"vespas","lemma":"vespa","pos":"NOM"} ,
		{"word":"vespasienne","word_nosc":"vespasienne","lemma":"vespasien","pos":"NOM"} ,
		{"word":"vespasienne","word_nosc":"vespasienne","lemma":"vespasienne","pos":"NOM"} ,
		{"word":"vespasiennes","word_nosc":"vespasiennes","lemma":"vespasien","pos":"NOM"} ,
		{"word":"vesprée","word_nosc":"vespree","lemma":"vesprée","pos":"NOM"} ,
		{"word":"vesse-de-loup","word_nosc":"vesse-de-loup","lemma":"vesse-de-loup","pos":"NOM"} ,
		{"word":"vesses","word_nosc":"vesses","lemma":"vesse","pos":"NOM"} ,
		{"word":"vesses-de-loup","word_nosc":"vesses-de-loup","lemma":"vesse-de-loup","pos":"NOM"} ,
		{"word":"vessie","word_nosc":"vessie","lemma":"vessie","pos":"NOM"} ,
		{"word":"vessies","word_nosc":"vessies","lemma":"vessie","pos":"NOM"} ,
		{"word":"vestalat","word_nosc":"vestalat","lemma":"vestalat","pos":"NOM"} ,
		{"word":"vestale","word_nosc":"vestale","lemma":"vestale","pos":"NOM"} ,
		{"word":"vestales","word_nosc":"vestales","lemma":"vestale","pos":"NOM"} ,
		{"word":"veste","word_nosc":"veste","lemma":"veste","pos":"NOM"} ,
		{"word":"vestes","word_nosc":"vestes","lemma":"veste","pos":"NOM"} ,
		{"word":"vestiaire","word_nosc":"vestiaire","lemma":"vestiaire","pos":"NOM"} ,
		{"word":"vestiaires","word_nosc":"vestiaires","lemma":"vestiaire","pos":"NOM"} ,
		{"word":"vestibule","word_nosc":"vestibule","lemma":"vestibule","pos":"NOM"} ,
		{"word":"vestibules","word_nosc":"vestibules","lemma":"vestibule","pos":"NOM"} ,
		{"word":"vestige","word_nosc":"vestige","lemma":"vestige","pos":"NOM"} ,
		{"word":"vestiges","word_nosc":"vestiges","lemma":"vestige","pos":"NOM"} ,
		{"word":"veston","word_nosc":"veston","lemma":"veston","pos":"NOM"} ,
		{"word":"vestons","word_nosc":"vestons","lemma":"veston","pos":"NOM"} ,
		{"word":"veto","word_nosc":"veto","lemma":"veto","pos":"NOM"} ,
		{"word":"veuf","word_nosc":"veuf","lemma":"veuf","pos":"NOM"} ,
		{"word":"veufs","word_nosc":"veufs","lemma":"veuf","pos":"NOM"} ,
		{"word":"veulerie","word_nosc":"veulerie","lemma":"veulerie","pos":"NOM"} ,
		{"word":"veuleries","word_nosc":"veuleries","lemma":"veulerie","pos":"NOM"} ,
		{"word":"veuvage","word_nosc":"veuvage","lemma":"veuvage","pos":"NOM"} ,
		{"word":"veuvages","word_nosc":"veuvages","lemma":"veuvage","pos":"NOM"} ,
		{"word":"veuve","word_nosc":"veuve","lemma":"veuf","pos":"NOM"} ,
		{"word":"veuves","word_nosc":"veuves","lemma":"veuf","pos":"NOM"} ,
		{"word":"vexation","word_nosc":"vexation","lemma":"vexation","pos":"NOM"} ,
		{"word":"vexations","word_nosc":"vexations","lemma":"vexation","pos":"NOM"} ,
		{"word":"viabilité","word_nosc":"viabilite","lemma":"viabilité","pos":"NOM"} ,
		{"word":"viaduc","word_nosc":"viaduc","lemma":"viaduc","pos":"NOM"} ,
		{"word":"viager","word_nosc":"viager","lemma":"viager","pos":"NOM"} ,
		{"word":"viagra","word_nosc":"viagra","lemma":"viagra","pos":"NOM"} ,
		{"word":"viandard","word_nosc":"viandard","lemma":"viandard","pos":"NOM"} ,
		{"word":"viande","word_nosc":"viande","lemma":"viande","pos":"NOM"} ,
		{"word":"viandes","word_nosc":"viandes","lemma":"viande","pos":"NOM"} ,
		{"word":"viandox","word_nosc":"viandox","lemma":"viandox","pos":"NOM"} ,
		{"word":"viatique","word_nosc":"viatique","lemma":"viatique","pos":"NOM"} ,
		{"word":"vibraphone","word_nosc":"vibraphone","lemma":"vibraphone","pos":"NOM"} ,
		{"word":"vibraphones","word_nosc":"vibraphones","lemma":"vibraphone","pos":"NOM"} ,
		{"word":"vibrateur","word_nosc":"vibrateur","lemma":"vibrateur","pos":"NOM"} ,
		{"word":"vibrateurs","word_nosc":"vibrateurs","lemma":"vibrateur","pos":"NOM"} ,
		{"word":"vibration","word_nosc":"vibration","lemma":"vibration","pos":"NOM"} ,
		{"word":"vibrations","word_nosc":"vibrations","lemma":"vibration","pos":"NOM"} ,
		{"word":"vibrato","word_nosc":"vibrato","lemma":"vibrato","pos":"NOM"} ,
		{"word":"vibratos","word_nosc":"vibratos","lemma":"vibrato","pos":"NOM"} ,
		{"word":"vibreur","word_nosc":"vibreur","lemma":"vibreur","pos":"NOM"} ,
		{"word":"vibrions","word_nosc":"vibrions","lemma":"vibrion","pos":"NOM"} ,
		{"word":"vibrisses","word_nosc":"vibrisses","lemma":"vibrisse","pos":"NOM"} ,
		{"word":"vibro-masseur","word_nosc":"vibro-masseur","lemma":"vibro-masseur","pos":"NOM"} ,
		{"word":"vibromasseur","word_nosc":"vibromasseur","lemma":"vibromasseur","pos":"NOM"} ,
		{"word":"vibromasseurs","word_nosc":"vibromasseurs","lemma":"vibromasseur","pos":"NOM"} ,
		{"word":"vicaire","word_nosc":"vicaire","lemma":"vicaire","pos":"NOM"} ,
		{"word":"vicaires","word_nosc":"vicaires","lemma":"vicaire","pos":"NOM"} ,
		{"word":"vice","word_nosc":"vice","lemma":"vice","pos":"NOM"} ,
		{"word":"vice-amiral","word_nosc":"vice-amiral","lemma":"vice-amiral","pos":"NOM"} ,
		{"word":"vice-chancelier","word_nosc":"vice-chancelier","lemma":"vice-chancelier","pos":"NOM"} ,
		{"word":"vice-consul","word_nosc":"vice-consul","lemma":"vice-consul","pos":"NOM"} ,
		{"word":"vice-ministre","word_nosc":"vice-ministre","lemma":"vice-ministre","pos":"NOM"} ,
		{"word":"vice-ministres","word_nosc":"vice-ministres","lemma":"vice-ministre","pos":"NOM"} ,
		{"word":"vice-premier","word_nosc":"vice-premier","lemma":"vice-premier","pos":"NOM"} ,
		{"word":"vice-présidence","word_nosc":"vice-presidence","lemma":"vice-présidence","pos":"NOM"} ,
		{"word":"vice-président","word_nosc":"vice-president","lemma":"vice-président","pos":"NOM"} ,
		{"word":"vice-présidente","word_nosc":"vice-presidente","lemma":"vice-président","pos":"NOM"} ,
		{"word":"vice-présidents","word_nosc":"vice-presidents","lemma":"vice-président","pos":"NOM"} ,
		{"word":"vice-recteur","word_nosc":"vice-recteur","lemma":"vice-recteur","pos":"NOM"} ,
		{"word":"vice-roi","word_nosc":"vice-roi","lemma":"vice-roi","pos":"NOM"} ,
		{"word":"vice-rois","word_nosc":"vice-rois","lemma":"vice-roi","pos":"NOM"} ,
		{"word":"vicelardise","word_nosc":"vicelardise","lemma":"vicelardise","pos":"NOM"} ,
		{"word":"vicelardises","word_nosc":"vicelardises","lemma":"vicelardise","pos":"NOM"} ,
		{"word":"vices","word_nosc":"vices","lemma":"vice","pos":"NOM"} ,
		{"word":"vichy","word_nosc":"vichy","lemma":"vichy","pos":"NOM"} ,
		{"word":"vichysme","word_nosc":"vichysme","lemma":"vichysme","pos":"NOM"} ,
		{"word":"vichyssois","word_nosc":"vichyssois","lemma":"vichyssois","pos":"NOM"} ,
		{"word":"vichystes","word_nosc":"vichystes","lemma":"vichyste","pos":"NOM"} ,
		{"word":"vicieuse","word_nosc":"vicieuse","lemma":"vicieux","pos":"NOM"} ,
		{"word":"vicieuses","word_nosc":"vicieuses","lemma":"vicieux","pos":"NOM"} ,
		{"word":"vicieux","word_nosc":"vicieux","lemma":"vicieux","pos":"NOM"} ,
		{"word":"vicinalité","word_nosc":"vicinalite","lemma":"vicinalité","pos":"NOM"} ,
		{"word":"vicissitude","word_nosc":"vicissitude","lemma":"vicissitude","pos":"NOM"} ,
		{"word":"vicissitudes","word_nosc":"vicissitudes","lemma":"vicissitude","pos":"NOM"} ,
		{"word":"vicomte","word_nosc":"vicomte","lemma":"vicomte","pos":"NOM"} ,
		{"word":"vicomtes","word_nosc":"vicomtes","lemma":"vicomte","pos":"NOM"} ,
		{"word":"vicomtesse","word_nosc":"vicomtesse","lemma":"vicomte","pos":"NOM"} ,
		{"word":"vicomtesses","word_nosc":"vicomtesses","lemma":"vicomte","pos":"NOM"} ,
		{"word":"vicomté","word_nosc":"vicomte","lemma":"vicomté","pos":"NOM"} ,
		{"word":"victimaire","word_nosc":"victimaire","lemma":"victimaire","pos":"NOM"} ,
		{"word":"victime","word_nosc":"victime","lemma":"victime","pos":"NOM"} ,
		{"word":"victimes","word_nosc":"victimes","lemma":"victime","pos":"NOM"} ,
		{"word":"victimologie","word_nosc":"victimologie","lemma":"victimologie","pos":"NOM"} ,
		{"word":"victoire","word_nosc":"victoire","lemma":"victoire","pos":"NOM"} ,
		{"word":"victoires","word_nosc":"victoires","lemma":"victoire","pos":"NOM"} ,
		{"word":"victoria","word_nosc":"victoria","lemma":"victoria","pos":"NOM"} ,
		{"word":"victorias","word_nosc":"victorias","lemma":"victoria","pos":"NOM"} ,
		{"word":"victuaille","word_nosc":"victuaille","lemma":"victuaille","pos":"NOM"} ,
		{"word":"victuailles","word_nosc":"victuailles","lemma":"victuaille","pos":"NOM"} ,
		{"word":"vidage","word_nosc":"vidage","lemma":"vidage","pos":"NOM"} ,
		{"word":"vidame","word_nosc":"vidame","lemma":"vidame","pos":"NOM"} ,
		{"word":"vidange","word_nosc":"vidange","lemma":"vidange","pos":"NOM"} ,
		{"word":"vidanges","word_nosc":"vidanges","lemma":"vidange","pos":"NOM"} ,
		{"word":"vidangeur","word_nosc":"vidangeur","lemma":"vidangeur","pos":"NOM"} ,
		{"word":"vidangeurs","word_nosc":"vidangeurs","lemma":"vidangeur","pos":"NOM"} ,
		{"word":"vide","word_nosc":"vide","lemma":"vide","pos":"NOM"} ,
		{"word":"vide-gousset","word_nosc":"vide-gousset","lemma":"vide-gousset","pos":"NOM"} ,
		{"word":"vide-greniers","word_nosc":"vide-greniers","lemma":"vide-greniers","pos":"NOM"} ,
		{"word":"vide-ordure","word_nosc":"vide-ordure","lemma":"vide-ordure","pos":"NOM"} ,
		{"word":"vide-ordures","word_nosc":"vide-ordures","lemma":"vide-ordures","pos":"NOM"} ,
		{"word":"vide-poche","word_nosc":"vide-poche","lemma":"vide-poche","pos":"NOM"} ,
		{"word":"vide-poches","word_nosc":"vide-poches","lemma":"vide-poches","pos":"NOM"} ,
		{"word":"vides","word_nosc":"vides","lemma":"vide","pos":"NOM"} ,
		{"word":"videur","word_nosc":"videur","lemma":"videur","pos":"NOM"} ,
		{"word":"videurs","word_nosc":"videurs","lemma":"videur","pos":"NOM"} ,
		{"word":"vidicon","word_nosc":"vidicon","lemma":"vidicon","pos":"NOM"} ,
		{"word":"viduité","word_nosc":"viduite","lemma":"viduité","pos":"NOM"} ,
		{"word":"vidures","word_nosc":"vidures","lemma":"vidure","pos":"NOM"} ,
		{"word":"vidéaste","word_nosc":"videaste","lemma":"vidéaste","pos":"NOM"} ,
		{"word":"vidéastes","word_nosc":"videastes","lemma":"vidéaste","pos":"NOM"} ,
		{"word":"vidéo","word_nosc":"video","lemma":"vidéo","pos":"NOM"} ,
		{"word":"vidéo-clip","word_nosc":"video-clip","lemma":"vidéo-clip","pos":"NOM"} ,
		{"word":"vidéo-clips","word_nosc":"video-clips","lemma":"vidéo-clip","pos":"NOM"} ,
		{"word":"vidéo-club","word_nosc":"video-club","lemma":"vidéo-club","pos":"NOM"} ,
		{"word":"vidéo-espion","word_nosc":"video-espion","lemma":"vidéo-espion","pos":"NOM"} ,
		{"word":"vidéocassette","word_nosc":"videocassette","lemma":"vidéocassette","pos":"NOM"} ,
		{"word":"vidéocassettes","word_nosc":"videocassettes","lemma":"vidéocassette","pos":"NOM"} ,
		{"word":"vidéoclip","word_nosc":"videoclip","lemma":"vidéoclip","pos":"NOM"} ,
		{"word":"vidéoclub","word_nosc":"videoclub","lemma":"vidéoclub","pos":"NOM"} ,
		{"word":"vidéoconférence","word_nosc":"videoconference","lemma":"vidéoconférence","pos":"NOM"} ,
		{"word":"vidéogramme","word_nosc":"videogramme","lemma":"vidéogramme","pos":"NOM"} ,
		{"word":"vidéophone","word_nosc":"videophone","lemma":"vidéophone","pos":"NOM"} ,
		{"word":"vidéophonie","word_nosc":"videophonie","lemma":"vidéophonie","pos":"NOM"} ,
		{"word":"vidéos","word_nosc":"videos","lemma":"vidéo","pos":"NOM"} ,
		{"word":"vidéosurveillance","word_nosc":"videosurveillance","lemma":"vidéosurveillance","pos":"NOM"} ,
		{"word":"vidéothèque","word_nosc":"videotheque","lemma":"vidéothèque","pos":"NOM"} ,
		{"word":"vie","word_nosc":"vie","lemma":"vie","pos":"NOM"} ,
		{"word":"vieillard","word_nosc":"vieillard","lemma":"vieillard","pos":"NOM"} ,
		{"word":"vieillarde","word_nosc":"vieillarde","lemma":"vieillard","pos":"NOM"} ,
		{"word":"vieillardes","word_nosc":"vieillardes","lemma":"vieillard","pos":"NOM"} ,
		{"word":"vieillards","word_nosc":"vieillards","lemma":"vieillard","pos":"NOM"} ,
		{"word":"vieille","word_nosc":"vieille","lemma":"vieux","pos":"NOM"} ,
		{"word":"vieillerie","word_nosc":"vieillerie","lemma":"vieillerie","pos":"NOM"} ,
		{"word":"vieilleries","word_nosc":"vieilleries","lemma":"vieillerie","pos":"NOM"} ,
		{"word":"vieilles","word_nosc":"vieilles","lemma":"vieux","pos":"NOM"} ,
		{"word":"vieillesse","word_nosc":"vieillesse","lemma":"vieillesse","pos":"NOM"} ,
		{"word":"vieillesses","word_nosc":"vieillesses","lemma":"vieillesse","pos":"NOM"} ,
		{"word":"vieillissement","word_nosc":"vieillissement","lemma":"vieillissement","pos":"NOM"} ,
		{"word":"vielle","word_nosc":"vielle","lemma":"vielle","pos":"NOM"} ,
		{"word":"vielles","word_nosc":"vielles","lemma":"vielle","pos":"NOM"} ,
		{"word":"vielleux","word_nosc":"vielleux","lemma":"vielleux","pos":"NOM"} ,
		{"word":"viennois","word_nosc":"viennois","lemma":"viennois","pos":"NOM"} ,
		{"word":"viennoiserie","word_nosc":"viennoiserie","lemma":"viennoiserie","pos":"NOM"} ,
		{"word":"viennoiseries","word_nosc":"viennoiseries","lemma":"viennoiserie","pos":"NOM"} ,
		{"word":"vierge","word_nosc":"vierge","lemma":"vierge","pos":"NOM"} ,
		{"word":"vierges","word_nosc":"vierges","lemma":"vierge","pos":"NOM"} ,
		{"word":"vies","word_nosc":"vies","lemma":"vie","pos":"NOM"} ,
		{"word":"vietnamien","word_nosc":"vietnamien","lemma":"vietnamien","pos":"NOM"} ,
		{"word":"vietnamiens","word_nosc":"vietnamiens","lemma":"vietnamien","pos":"NOM"} ,
		{"word":"vieux","word_nosc":"vieux","lemma":"vieux","pos":"NOM"} ,
		{"word":"vieux-rose","word_nosc":"vieux-rose","lemma":"vieux-rose","pos":"NOM"} ,
		{"word":"vif","word_nosc":"vif","lemma":"vif","pos":"NOM"} ,
		{"word":"vif-argent","word_nosc":"vif-argent","lemma":"vif-argent","pos":"NOM"} ,
		{"word":"vifs","word_nosc":"vifs","lemma":"vif","pos":"NOM"} ,
		{"word":"vigie","word_nosc":"vigie","lemma":"vigie","pos":"NOM"} ,
		{"word":"vigies","word_nosc":"vigies","lemma":"vigie","pos":"NOM"} ,
		{"word":"vigilance","word_nosc":"vigilance","lemma":"vigilance","pos":"NOM"} ,
		{"word":"vigilances","word_nosc":"vigilances","lemma":"vigilance","pos":"NOM"} ,
		{"word":"vigile","word_nosc":"vigile","lemma":"vigile","pos":"NOM"} ,
		{"word":"vigiles","word_nosc":"vigiles","lemma":"vigile","pos":"NOM"} ,
		{"word":"vigne","word_nosc":"vigne","lemma":"vigne","pos":"NOM"} ,
		{"word":"vigneaux","word_nosc":"vigneaux","lemma":"vigneau","pos":"NOM"} ,
		{"word":"vigneron","word_nosc":"vigneron","lemma":"vigneron","pos":"NOM"} ,
		{"word":"vigneronne","word_nosc":"vigneronne","lemma":"vigneron","pos":"NOM"} ,
		{"word":"vigneronnes","word_nosc":"vigneronnes","lemma":"vigneron","pos":"NOM"} ,
		{"word":"vignerons","word_nosc":"vignerons","lemma":"vigneron","pos":"NOM"} ,
		{"word":"vignes","word_nosc":"vignes","lemma":"vigne","pos":"NOM"} ,
		{"word":"vignette","word_nosc":"vignette","lemma":"vignette","pos":"NOM"} ,
		{"word":"vignettes","word_nosc":"vignettes","lemma":"vignette","pos":"NOM"} ,
		{"word":"vignoble","word_nosc":"vignoble","lemma":"vignoble","pos":"NOM"} ,
		{"word":"vignobles","word_nosc":"vignobles","lemma":"vignoble","pos":"NOM"} ,
		{"word":"vigogne","word_nosc":"vigogne","lemma":"vigogne","pos":"NOM"} ,
		{"word":"vigueur","word_nosc":"vigueur","lemma":"vigueur","pos":"NOM"} ,
		{"word":"vigueurs","word_nosc":"vigueurs","lemma":"vigueur","pos":"NOM"} ,
		{"word":"viguier","word_nosc":"viguier","lemma":"viguier","pos":"NOM"} ,
		{"word":"viguiers","word_nosc":"viguiers","lemma":"viguier","pos":"NOM"} ,
		{"word":"viking","word_nosc":"viking","lemma":"viking","pos":"NOM"} ,
		{"word":"vikings","word_nosc":"vikings","lemma":"viking","pos":"NOM"} ,
		{"word":"vilain","word_nosc":"vilain","lemma":"vilain","pos":"NOM"} ,
		{"word":"vilains","word_nosc":"vilains","lemma":"vilain","pos":"NOM"} ,
		{"word":"vilebrequin","word_nosc":"vilebrequin","lemma":"vilebrequin","pos":"NOM"} ,
		{"word":"vilebrequins","word_nosc":"vilebrequins","lemma":"vilebrequin","pos":"NOM"} ,
		{"word":"vilenie","word_nosc":"vilenie","lemma":"vilenie","pos":"NOM"} ,
		{"word":"vilenies","word_nosc":"vilenies","lemma":"vilenie","pos":"NOM"} ,
		{"word":"villa","word_nosc":"villa","lemma":"villa","pos":"NOM"} ,
		{"word":"village","word_nosc":"village","lemma":"village","pos":"NOM"} ,
		{"word":"villageois","word_nosc":"villageois","lemma":"villageois","pos":"NOM"} ,
		{"word":"villageoise","word_nosc":"villageoise","lemma":"villageois","pos":"NOM"} ,
		{"word":"villageoises","word_nosc":"villageoises","lemma":"villageois","pos":"NOM"} ,
		{"word":"villages","word_nosc":"villages","lemma":"village","pos":"NOM"} ,
		{"word":"villas","word_nosc":"villas","lemma":"villa","pos":"NOM"} ,
		{"word":"ville","word_nosc":"ville","lemma":"ville","pos":"NOM"} ,
		{"word":"ville-champignon","word_nosc":"ville-champignon","lemma":"ville-champignon","pos":"NOM"} ,
		{"word":"ville-dortoir","word_nosc":"ville-dortoir","lemma":"ville-dortoir","pos":"NOM"} ,
		{"word":"villes","word_nosc":"villes","lemma":"ville","pos":"NOM"} ,
		{"word":"villes-clés","word_nosc":"villes-cles","lemma":"villes-clé","pos":"NOM"} ,
		{"word":"villette","word_nosc":"villette","lemma":"villette","pos":"NOM"} ,
		{"word":"villégiature","word_nosc":"villegiature","lemma":"villégiature","pos":"NOM"} ,
		{"word":"villégiatures","word_nosc":"villegiatures","lemma":"villégiature","pos":"NOM"} ,
		{"word":"vin","word_nosc":"vin","lemma":"vin","pos":"NOM"} ,
		{"word":"vina","word_nosc":"vina","lemma":"vina","pos":"NOM"} ,
		{"word":"vinaigre","word_nosc":"vinaigre","lemma":"vinaigre","pos":"NOM"} ,
		{"word":"vinaigres","word_nosc":"vinaigres","lemma":"vinaigre","pos":"NOM"} ,
		{"word":"vinaigrette","word_nosc":"vinaigrette","lemma":"vinaigrette","pos":"NOM"} ,
		{"word":"vinasse","word_nosc":"vinasse","lemma":"vinasse","pos":"NOM"} ,
		{"word":"vindicte","word_nosc":"vindicte","lemma":"vindicte","pos":"NOM"} ,
		{"word":"vingt-et-un","word_nosc":"vingt-et-un","lemma":"vingt-et-un","pos":"NOM"} ,
		{"word":"vingt-et-une","word_nosc":"vingt-et-une","lemma":"vingt-et-un","pos":"NOM"} ,
		{"word":"vingtaine","word_nosc":"vingtaine","lemma":"vingtaine","pos":"NOM"} ,
		{"word":"vingtaines","word_nosc":"vingtaines","lemma":"vingtaine","pos":"NOM"} ,
		{"word":"vingtième","word_nosc":"vingtieme","lemma":"vingtième","pos":"NOM"} ,
		{"word":"vinificatrice","word_nosc":"vinificatrice","lemma":"vinificateur","pos":"NOM"} ,
		{"word":"vins","word_nosc":"vins","lemma":"vin","pos":"NOM"} ,
		{"word":"vintage","word_nosc":"vintage","lemma":"vintage","pos":"NOM"} ,
		{"word":"vinyle","word_nosc":"vinyle","lemma":"vinyle","pos":"NOM"} ,
		{"word":"vinyles","word_nosc":"vinyles","lemma":"vinyle","pos":"NOM"} ,
		{"word":"vioc","word_nosc":"vioc","lemma":"vioc","pos":"NOM"} ,
		{"word":"viocard","word_nosc":"viocard","lemma":"viocard","pos":"NOM"} ,
		{"word":"viocque","word_nosc":"viocque","lemma":"viocque","pos":"NOM"} ,
		{"word":"viocs","word_nosc":"viocs","lemma":"vioc","pos":"NOM"} ,
		{"word":"viol","word_nosc":"viol","lemma":"viol","pos":"NOM"} ,
		{"word":"violation","word_nosc":"violation","lemma":"violation","pos":"NOM"} ,
		{"word":"violations","word_nosc":"violations","lemma":"violation","pos":"NOM"} ,
		{"word":"viole","word_nosc":"viole","lemma":"viole","pos":"NOM"} ,
		{"word":"violence","word_nosc":"violence","lemma":"violence","pos":"NOM"} ,
		{"word":"violences","word_nosc":"violences","lemma":"violence","pos":"NOM"} ,
		{"word":"violent","word_nosc":"violent","lemma":"violent","pos":"NOM"} ,
		{"word":"violents","word_nosc":"violents","lemma":"violent","pos":"NOM"} ,
		{"word":"violes","word_nosc":"violes","lemma":"viole","pos":"NOM"} ,
		{"word":"violet","word_nosc":"violet","lemma":"violet","pos":"NOM"} ,
		{"word":"violets","word_nosc":"violets","lemma":"violet","pos":"NOM"} ,
		{"word":"violette","word_nosc":"violette","lemma":"violette","pos":"NOM"} ,
		{"word":"violettes","word_nosc":"violettes","lemma":"violette","pos":"NOM"} ,
		{"word":"violeur","word_nosc":"violeur","lemma":"violeur","pos":"NOM"} ,
		{"word":"violeurs","word_nosc":"violeurs","lemma":"violeur","pos":"NOM"} ,
		{"word":"violeuses","word_nosc":"violeuses","lemma":"violeur","pos":"NOM"} ,
		{"word":"violine","word_nosc":"violine","lemma":"violine","pos":"NOM"} ,
		{"word":"violon","word_nosc":"violon","lemma":"violon","pos":"NOM"} ,
		{"word":"violoncelle","word_nosc":"violoncelle","lemma":"violoncelle","pos":"NOM"} ,
		{"word":"violoncelles","word_nosc":"violoncelles","lemma":"violoncelle","pos":"NOM"} ,
		{"word":"violoncelliste","word_nosc":"violoncelliste","lemma":"violoncelliste","pos":"NOM"} ,
		{"word":"violoncellistes","word_nosc":"violoncellistes","lemma":"violoncelliste","pos":"NOM"} ,
		{"word":"violoneux","word_nosc":"violoneux","lemma":"violoneux","pos":"NOM"} ,
		{"word":"violoniste","word_nosc":"violoniste","lemma":"violoniste","pos":"NOM"} ,
		{"word":"violonistes","word_nosc":"violonistes","lemma":"violoniste","pos":"NOM"} ,
		{"word":"violons","word_nosc":"violons","lemma":"violon","pos":"NOM"} ,
		{"word":"viols","word_nosc":"viols","lemma":"viol","pos":"NOM"} ,
		{"word":"vioque","word_nosc":"vioque","lemma":"vioque","pos":"NOM"} ,
		{"word":"vioques","word_nosc":"vioques","lemma":"vioque","pos":"NOM"} ,
		{"word":"viorne","word_nosc":"viorne","lemma":"viorne","pos":"NOM"} ,
		{"word":"viornes","word_nosc":"viornes","lemma":"viorne","pos":"NOM"} ,
		{"word":"vipère","word_nosc":"vipere","lemma":"vipère","pos":"NOM"} ,
		{"word":"vipères","word_nosc":"viperes","lemma":"vipère","pos":"NOM"} ,
		{"word":"vipéreau","word_nosc":"vipereau","lemma":"vipéreau","pos":"NOM"} ,
		{"word":"virage","word_nosc":"virage","lemma":"virage","pos":"NOM"} ,
		{"word":"virages","word_nosc":"virages","lemma":"virage","pos":"NOM"} ,
		{"word":"virago","word_nosc":"virago","lemma":"virago","pos":"NOM"} ,
		{"word":"viragos","word_nosc":"viragos","lemma":"virago","pos":"NOM"} ,
		{"word":"vire","word_nosc":"vire","lemma":"vire","pos":"NOM"} ,
		{"word":"virement","word_nosc":"virement","lemma":"virement","pos":"NOM"} ,
		{"word":"virements","word_nosc":"virements","lemma":"virement","pos":"NOM"} ,
		{"word":"vires","word_nosc":"vires","lemma":"vire","pos":"NOM"} ,
		{"word":"virevolte","word_nosc":"virevolte","lemma":"virevolte","pos":"NOM"} ,
		{"word":"virevoltes","word_nosc":"virevoltes","lemma":"virevolte","pos":"NOM"} ,
		{"word":"virginal","word_nosc":"virginal","lemma":"virginal","pos":"NOM"} ,
		{"word":"virginie","word_nosc":"virginie","lemma":"virginie","pos":"NOM"} ,
		{"word":"virginien","word_nosc":"virginien","lemma":"virginien","pos":"NOM"} ,
		{"word":"virginiens","word_nosc":"virginiens","lemma":"virginien","pos":"NOM"} ,
		{"word":"virginité","word_nosc":"virginite","lemma":"virginité","pos":"NOM"} ,
		{"word":"virginités","word_nosc":"virginites","lemma":"virginité","pos":"NOM"} ,
		{"word":"virgule","word_nosc":"virgule","lemma":"virgule","pos":"NOM"} ,
		{"word":"virgules","word_nosc":"virgules","lemma":"virgule","pos":"NOM"} ,
		{"word":"virilité","word_nosc":"virilite","lemma":"virilité","pos":"NOM"} ,
		{"word":"virilités","word_nosc":"virilites","lemma":"virilité","pos":"NOM"} ,
		{"word":"viroles","word_nosc":"viroles","lemma":"virole","pos":"NOM"} ,
		{"word":"virolet","word_nosc":"virolet","lemma":"virolet","pos":"NOM"} ,
		{"word":"virologie","word_nosc":"virologie","lemma":"virologie","pos":"NOM"} ,
		{"word":"virologiste","word_nosc":"virologiste","lemma":"virologiste","pos":"NOM"} ,
		{"word":"virologue","word_nosc":"virologue","lemma":"virologue","pos":"NOM"} ,
		{"word":"virologues","word_nosc":"virologues","lemma":"virologue","pos":"NOM"} ,
		{"word":"virtualité","word_nosc":"virtualite","lemma":"virtualité","pos":"NOM"} ,
		{"word":"virtualités","word_nosc":"virtualites","lemma":"virtualité","pos":"NOM"} ,
		{"word":"virtuose","word_nosc":"virtuose","lemma":"virtuose","pos":"NOM"} ,
		{"word":"virtuoses","word_nosc":"virtuoses","lemma":"virtuose","pos":"NOM"} ,
		{"word":"virtuosité","word_nosc":"virtuosite","lemma":"virtuosité","pos":"NOM"} ,
		{"word":"virulence","word_nosc":"virulence","lemma":"virulence","pos":"NOM"} ,
		{"word":"virus","word_nosc":"virus","lemma":"virus","pos":"NOM"} ,
		{"word":"virée","word_nosc":"viree","lemma":"virée","pos":"NOM"} ,
		{"word":"virées","word_nosc":"virees","lemma":"virée","pos":"NOM"} ,
		{"word":"vis","word_nosc":"vis","lemma":"vis","pos":"NOM"} ,
		{"word":"vis-à-vis","word_nosc":"vis-a-vis","lemma":"vis-à-vis","pos":"NOM"} ,
		{"word":"visa","word_nosc":"visa","lemma":"visa","pos":"NOM"} ,
		{"word":"visage","word_nosc":"visage","lemma":"visage","pos":"NOM"} ,
		{"word":"visages","word_nosc":"visages","lemma":"visage","pos":"NOM"} ,
		{"word":"visagiste","word_nosc":"visagiste","lemma":"visagiste","pos":"NOM"} ,
		{"word":"visas","word_nosc":"visas","lemma":"visa","pos":"NOM"} ,
		{"word":"viscose","word_nosc":"viscose","lemma":"viscose","pos":"NOM"} ,
		{"word":"viscoses","word_nosc":"viscoses","lemma":"viscose","pos":"NOM"} ,
		{"word":"viscosité","word_nosc":"viscosite","lemma":"viscosité","pos":"NOM"} ,
		{"word":"viscosités","word_nosc":"viscosites","lemma":"viscosité","pos":"NOM"} ,
		{"word":"viscère","word_nosc":"viscere","lemma":"viscère","pos":"NOM"} ,
		{"word":"viscères","word_nosc":"visceres","lemma":"viscère","pos":"NOM"} ,
		{"word":"viseur","word_nosc":"viseur","lemma":"viseur","pos":"NOM"} ,
		{"word":"viseurs","word_nosc":"viseurs","lemma":"viseur","pos":"NOM"} ,
		{"word":"vishnouisme","word_nosc":"vishnouisme","lemma":"vishnouisme","pos":"NOM"} ,
		{"word":"visibilité","word_nosc":"visibilite","lemma":"visibilité","pos":"NOM"} ,
		{"word":"visioconférence","word_nosc":"visioconference","lemma":"visioconférence","pos":"NOM"} ,
		{"word":"vision","word_nosc":"vision","lemma":"vision","pos":"NOM"} ,
		{"word":"visionnage","word_nosc":"visionnage","lemma":"visionnage","pos":"NOM"} ,
		{"word":"visionnaire","word_nosc":"visionnaire","lemma":"visionnaire","pos":"NOM"} ,
		{"word":"visionnaires","word_nosc":"visionnaires","lemma":"visionnaire","pos":"NOM"} ,
		{"word":"visionneuse","word_nosc":"visionneuse","lemma":"visionneur","pos":"NOM"} ,
		{"word":"visions","word_nosc":"visions","lemma":"vision","pos":"NOM"} ,
		{"word":"visiophone","word_nosc":"visiophone","lemma":"visiophone","pos":"NOM"} ,
		{"word":"visitandines","word_nosc":"visitandines","lemma":"visitandine","pos":"NOM"} ,
		{"word":"visitation","word_nosc":"visitation","lemma":"visitation","pos":"NOM"} ,
		{"word":"visite","word_nosc":"visite","lemma":"visite","pos":"NOM"} ,
		{"word":"visite-éclair","word_nosc":"visite-eclair","lemma":"visite-éclair","pos":"NOM"} ,
		{"word":"visites","word_nosc":"visites","lemma":"visite","pos":"NOM"} ,
		{"word":"visiteur","word_nosc":"visiteur","lemma":"visiteur","pos":"NOM"} ,
		{"word":"visiteurs","word_nosc":"visiteurs","lemma":"visiteur","pos":"NOM"} ,
		{"word":"visiteuse","word_nosc":"visiteuse","lemma":"visiteur","pos":"NOM"} ,
		{"word":"visiteuses","word_nosc":"visiteuses","lemma":"visiteur","pos":"NOM"} ,
		{"word":"visière","word_nosc":"visiere","lemma":"visière","pos":"NOM"} ,
		{"word":"visières","word_nosc":"visieres","lemma":"visière","pos":"NOM"} ,
		{"word":"vison","word_nosc":"vison","lemma":"vison","pos":"NOM"} ,
		{"word":"visons","word_nosc":"visons","lemma":"vison","pos":"NOM"} ,
		{"word":"visserie","word_nosc":"visserie","lemma":"visserie","pos":"NOM"} ,
		{"word":"vista","word_nosc":"vista","lemma":"vista","pos":"NOM"} ,
		{"word":"visualisation","word_nosc":"visualisation","lemma":"visualisation","pos":"NOM"} ,
		{"word":"visé","word_nosc":"vise","lemma":"visé","pos":"NOM"} ,
		{"word":"visée","word_nosc":"visee","lemma":"visée","pos":"NOM"} ,
		{"word":"visées","word_nosc":"visees","lemma":"visée","pos":"NOM"} ,
		{"word":"visés","word_nosc":"vises","lemma":"visé","pos":"NOM"} ,
		{"word":"vitalité","word_nosc":"vitalite","lemma":"vitalité","pos":"NOM"} ,
		{"word":"vitamine","word_nosc":"vitamine","lemma":"vitamine","pos":"NOM"} ,
		{"word":"vitamines","word_nosc":"vitamines","lemma":"vitamine","pos":"NOM"} ,
		{"word":"vitesse","word_nosc":"vitesse","lemma":"vitesse","pos":"NOM"} ,
		{"word":"vitesses","word_nosc":"vitesses","lemma":"vitesse","pos":"NOM"} ,
		{"word":"viticulteur","word_nosc":"viticulteur","lemma":"viticulteur","pos":"NOM"} ,
		{"word":"viticulteurs","word_nosc":"viticulteurs","lemma":"viticulteur","pos":"NOM"} ,
		{"word":"viticultrice","word_nosc":"viticultrice","lemma":"viticulteur","pos":"NOM"} ,
		{"word":"viticulture","word_nosc":"viticulture","lemma":"viticulture","pos":"NOM"} ,
		{"word":"vitiligo","word_nosc":"vitiligo","lemma":"vitiligo","pos":"NOM"} ,
		{"word":"vitrage","word_nosc":"vitrage","lemma":"vitrage","pos":"NOM"} ,
		{"word":"vitrages","word_nosc":"vitrages","lemma":"vitrage","pos":"NOM"} ,
		{"word":"vitrail","word_nosc":"vitrail","lemma":"vitrail","pos":"NOM"} ,
		{"word":"vitrauphanie","word_nosc":"vitrauphanie","lemma":"vitrauphanie","pos":"NOM"} ,
		{"word":"vitraux","word_nosc":"vitraux","lemma":"vitrail","pos":"NOM"} ,
		{"word":"vitre","word_nosc":"vitre","lemma":"vitre","pos":"NOM"} ,
		{"word":"vitres","word_nosc":"vitres","lemma":"vitre","pos":"NOM"} ,
		{"word":"vitrier","word_nosc":"vitrier","lemma":"vitrier","pos":"NOM"} ,
		{"word":"vitriers","word_nosc":"vitriers","lemma":"vitrier","pos":"NOM"} ,
		{"word":"vitrine","word_nosc":"vitrine","lemma":"vitrine","pos":"NOM"} ,
		{"word":"vitrines","word_nosc":"vitrines","lemma":"vitrine","pos":"NOM"} ,
		{"word":"vitriol","word_nosc":"vitriol","lemma":"vitriol","pos":"NOM"} ,
		{"word":"vitrioleurs","word_nosc":"vitrioleurs","lemma":"vitrioleur","pos":"NOM"} ,
		{"word":"vitriols","word_nosc":"vitriols","lemma":"vitriol","pos":"NOM"} ,
		{"word":"vitrophanie","word_nosc":"vitrophanie","lemma":"vitrophanie","pos":"NOM"} ,
		{"word":"vits","word_nosc":"vits","lemma":"vit","pos":"NOM"} ,
		{"word":"vitupérations","word_nosc":"vituperations","lemma":"vitupération","pos":"NOM"} ,
		{"word":"vivacité","word_nosc":"vivacite","lemma":"vivacité","pos":"NOM"} ,
		{"word":"vivacités","word_nosc":"vivacites","lemma":"vivacité","pos":"NOM"} ,
		{"word":"vivandier","word_nosc":"vivandier","lemma":"vivandier","pos":"NOM"} ,
		{"word":"vivandiers","word_nosc":"vivandiers","lemma":"vivandier","pos":"NOM"} ,
		{"word":"vivandière","word_nosc":"vivandiere","lemma":"vivandier","pos":"NOM"} ,
		{"word":"vivandières","word_nosc":"vivandieres","lemma":"vivandier","pos":"NOM"} ,
		{"word":"vivant","word_nosc":"vivant","lemma":"vivant","pos":"NOM"} ,
		{"word":"vivants","word_nosc":"vivants","lemma":"vivant","pos":"NOM"} ,
		{"word":"vivarium","word_nosc":"vivarium","lemma":"vivarium","pos":"NOM"} ,
		{"word":"vivat","word_nosc":"vivat","lemma":"vivat","pos":"NOM"} ,
		{"word":"vivats","word_nosc":"vivats","lemma":"vivats","pos":"NOM"} ,
		{"word":"vive","word_nosc":"vive","lemma":"vive","pos":"NOM"} ,
		{"word":"vives","word_nosc":"vives","lemma":"vive","pos":"NOM"} ,
		{"word":"viveur","word_nosc":"viveur","lemma":"viveur","pos":"NOM"} ,
		{"word":"viveurs","word_nosc":"viveurs","lemma":"viveur","pos":"NOM"} ,
		{"word":"vivier","word_nosc":"vivier","lemma":"vivier","pos":"NOM"} ,
		{"word":"viviers","word_nosc":"viviers","lemma":"vivier","pos":"NOM"} ,
		{"word":"vivification","word_nosc":"vivification","lemma":"vivification","pos":"NOM"} ,
		{"word":"vivisecteur","word_nosc":"vivisecteur","lemma":"vivisecteur","pos":"NOM"} ,
		{"word":"vivisection","word_nosc":"vivisection","lemma":"vivisection","pos":"NOM"} ,
		{"word":"vivisections","word_nosc":"vivisections","lemma":"vivisection","pos":"NOM"} ,
		{"word":"vivre","word_nosc":"vivre","lemma":"vivre","pos":"NOM"} ,
		{"word":"vivres","word_nosc":"vivres","lemma":"vivre","pos":"NOM"} ,
		{"word":"vizir","word_nosc":"vizir","lemma":"vizir","pos":"NOM"} ,
		{"word":"vizirs","word_nosc":"vizirs","lemma":"vizir","pos":"NOM"} ,
		{"word":"vocable","word_nosc":"vocable","lemma":"vocable","pos":"NOM"} ,
		{"word":"vocables","word_nosc":"vocables","lemma":"vocable","pos":"NOM"} ,
		{"word":"vocabulaire","word_nosc":"vocabulaire","lemma":"vocabulaire","pos":"NOM"} ,
		{"word":"vocabulaires","word_nosc":"vocabulaires","lemma":"vocabulaire","pos":"NOM"} ,
		{"word":"vocalisation","word_nosc":"vocalisation","lemma":"vocalisation","pos":"NOM"} ,
		{"word":"vocalisations","word_nosc":"vocalisations","lemma":"vocalisation","pos":"NOM"} ,
		{"word":"vocalise","word_nosc":"vocalise","lemma":"vocalise","pos":"NOM"} ,
		{"word":"vocalises","word_nosc":"vocalises","lemma":"vocalise","pos":"NOM"} ,
		{"word":"vocaliste","word_nosc":"vocaliste","lemma":"vocaliste","pos":"NOM"} ,
		{"word":"vocatif","word_nosc":"vocatif","lemma":"vocatif","pos":"NOM"} ,
		{"word":"vocatifs","word_nosc":"vocatifs","lemma":"vocatif","pos":"NOM"} ,
		{"word":"vocation","word_nosc":"vocation","lemma":"vocation","pos":"NOM"} ,
		{"word":"vocations","word_nosc":"vocations","lemma":"vocation","pos":"NOM"} ,
		{"word":"vocifération","word_nosc":"vociferation","lemma":"vocifération","pos":"NOM"} ,
		{"word":"vociférations","word_nosc":"vociferations","lemma":"vocifération","pos":"NOM"} ,
		{"word":"vocodeur","word_nosc":"vocodeur","lemma":"vocodeur","pos":"NOM"} ,
		{"word":"vodka","word_nosc":"vodka","lemma":"vodka","pos":"NOM"} ,
		{"word":"vodkas","word_nosc":"vodkas","lemma":"vodka","pos":"NOM"} ,
		{"word":"vodou","word_nosc":"vodou","lemma":"vodou","pos":"NOM"} ,
		{"word":"vodous","word_nosc":"vodous","lemma":"vodou","pos":"NOM"} ,
		{"word":"voeu","word_nosc":"voeu","lemma":"voeu","pos":"NOM"} ,
		{"word":"voeux","word_nosc":"voeux","lemma":"voeu","pos":"NOM"} ,
		{"word":"vogue","word_nosc":"vogue","lemma":"vogue","pos":"NOM"} ,
		{"word":"vogues","word_nosc":"vogues","lemma":"vogue","pos":"NOM"} ,
		{"word":"voie","word_nosc":"voie","lemma":"voie","pos":"NOM"} ,
		{"word":"voies","word_nosc":"voies","lemma":"voie","pos":"NOM"} ,
		{"word":"voilage","word_nosc":"voilage","lemma":"voilage","pos":"NOM"} ,
		{"word":"voilages","word_nosc":"voilages","lemma":"voilage","pos":"NOM"} ,
		{"word":"voile","word_nosc":"voile","lemma":"voile","pos":"NOM"} ,
		{"word":"voiles","word_nosc":"voiles","lemma":"voile","pos":"NOM"} ,
		{"word":"voilette","word_nosc":"voilette","lemma":"voilette","pos":"NOM"} ,
		{"word":"voilettes","word_nosc":"voilettes","lemma":"voilette","pos":"NOM"} ,
		{"word":"voilier","word_nosc":"voilier","lemma":"voilier","pos":"NOM"} ,
		{"word":"voiliers","word_nosc":"voiliers","lemma":"voilier","pos":"NOM"} ,
		{"word":"voilure","word_nosc":"voilure","lemma":"voilure","pos":"NOM"} ,
		{"word":"voilures","word_nosc":"voilures","lemma":"voilure","pos":"NOM"} ,
		{"word":"voirie","word_nosc":"voirie","lemma":"voirie","pos":"NOM"} ,
		{"word":"voisin","word_nosc":"voisin","lemma":"voisin","pos":"NOM"} ,
		{"word":"voisinage","word_nosc":"voisinage","lemma":"voisinage","pos":"NOM"} ,
		{"word":"voisinages","word_nosc":"voisinages","lemma":"voisinage","pos":"NOM"} ,
		{"word":"voisine","word_nosc":"voisine","lemma":"voisin","pos":"NOM"} ,
		{"word":"voisines","word_nosc":"voisines","lemma":"voisin","pos":"NOM"} ,
		{"word":"voisins","word_nosc":"voisins","lemma":"voisin","pos":"NOM"} ,
		{"word":"voiture","word_nosc":"voiture","lemma":"voiture","pos":"NOM"} ,
		{"word":"voiture-balai","word_nosc":"voiture-balai","lemma":"voiture-balai","pos":"NOM"} ,
		{"word":"voiture-bar","word_nosc":"voiture-bar","lemma":"voiture-bar","pos":"NOM"} ,
		{"word":"voiture-lit","word_nosc":"voiture-lit","lemma":"voiture-lit","pos":"NOM"} ,
		{"word":"voiture-restaurant","word_nosc":"voiture-restaurant","lemma":"voiture-restaurant","pos":"NOM"} ,
		{"word":"voitures","word_nosc":"voitures","lemma":"voiture","pos":"NOM"} ,
		{"word":"voitures-balais","word_nosc":"voitures-balais","lemma":"voiture-balai","pos":"NOM"} ,
		{"word":"voiturette","word_nosc":"voiturette","lemma":"voiturette","pos":"NOM"} ,
		{"word":"voiturettes","word_nosc":"voiturettes","lemma":"voiturette","pos":"NOM"} ,
		{"word":"voiturier","word_nosc":"voiturier","lemma":"voiturier","pos":"NOM"} ,
		{"word":"voituriers","word_nosc":"voituriers","lemma":"voiturier","pos":"NOM"} ,
		{"word":"voiturin","word_nosc":"voiturin","lemma":"voiturin","pos":"NOM"} ,
		{"word":"voix","word_nosc":"voix","lemma":"voix","pos":"NOM"} ,
		{"word":"voix-off","word_nosc":"voix-off","lemma":"voix-off","pos":"NOM"} ,
		{"word":"vol","word_nosc":"vol","lemma":"vol","pos":"NOM"} ,
		{"word":"vol-au-vent","word_nosc":"vol-au-vent","lemma":"vol-au-vent","pos":"NOM"} ,
		{"word":"volaille","word_nosc":"volaille","lemma":"volaille","pos":"NOM"} ,
		{"word":"volailler","word_nosc":"volailler","lemma":"volailler","pos":"NOM"} ,
		{"word":"volaillers","word_nosc":"volaillers","lemma":"volailler","pos":"NOM"} ,
		{"word":"volailles","word_nosc":"volailles","lemma":"volaille","pos":"NOM"} ,
		{"word":"volant","word_nosc":"volant","lemma":"volant","pos":"NOM"} ,
		{"word":"volants","word_nosc":"volants","lemma":"volant","pos":"NOM"} ,
		{"word":"volapük","word_nosc":"volapuk","lemma":"volapük","pos":"NOM"} ,
		{"word":"volatile","word_nosc":"volatile","lemma":"volatile","pos":"NOM"} ,
		{"word":"volatiles","word_nosc":"volatiles","lemma":"volatile","pos":"NOM"} ,
		{"word":"volatilisation","word_nosc":"volatilisation","lemma":"volatilisation","pos":"NOM"} ,
		{"word":"volatilité","word_nosc":"volatilite","lemma":"volatilité","pos":"NOM"} ,
		{"word":"volcan","word_nosc":"volcan","lemma":"volcan","pos":"NOM"} ,
		{"word":"volcanologue","word_nosc":"volcanologue","lemma":"volcanologue","pos":"NOM"} ,
		{"word":"volcans","word_nosc":"volcans","lemma":"volcan","pos":"NOM"} ,
		{"word":"vole","word_nosc":"vole","lemma":"vole","pos":"NOM"} ,
		{"word":"volerie","word_nosc":"volerie","lemma":"volerie","pos":"NOM"} ,
		{"word":"voles","word_nosc":"voles","lemma":"vole","pos":"NOM"} ,
		{"word":"volet","word_nosc":"volet","lemma":"volet","pos":"NOM"} ,
		{"word":"volets","word_nosc":"volets","lemma":"volet","pos":"NOM"} ,
		{"word":"voleur","word_nosc":"voleur","lemma":"voleur","pos":"NOM"} ,
		{"word":"voleurs","word_nosc":"voleurs","lemma":"voleur","pos":"NOM"} ,
		{"word":"voleuse","word_nosc":"voleuse","lemma":"voleur","pos":"NOM"} ,
		{"word":"voleuses","word_nosc":"voleuses","lemma":"voleur","pos":"NOM"} ,
		{"word":"volige","word_nosc":"volige","lemma":"volige","pos":"NOM"} ,
		{"word":"voliges","word_nosc":"voliges","lemma":"volige","pos":"NOM"} ,
		{"word":"volition","word_nosc":"volition","lemma":"volition","pos":"NOM"} ,
		{"word":"volitions","word_nosc":"volitions","lemma":"volition","pos":"NOM"} ,
		{"word":"volière","word_nosc":"voliere","lemma":"volière","pos":"NOM"} ,
		{"word":"volières","word_nosc":"volieres","lemma":"volière","pos":"NOM"} ,
		{"word":"volley","word_nosc":"volley","lemma":"volley","pos":"NOM"} ,
		{"word":"volley-ball","word_nosc":"volley-ball","lemma":"volley-ball","pos":"NOM"} ,
		{"word":"volleyeur","word_nosc":"volleyeur","lemma":"volleyeur","pos":"NOM"} ,
		{"word":"volleyeurs","word_nosc":"volleyeurs","lemma":"volleyeur","pos":"NOM"} ,
		{"word":"volontaire","word_nosc":"volontaire","lemma":"volontaire","pos":"NOM"} ,
		{"word":"volontaires","word_nosc":"volontaires","lemma":"volontaire","pos":"NOM"} ,
		{"word":"volontariat","word_nosc":"volontariat","lemma":"volontariat","pos":"NOM"} ,
		{"word":"volontarisme","word_nosc":"volontarisme","lemma":"volontarisme","pos":"NOM"} ,
		{"word":"volonté","word_nosc":"volonte","lemma":"volonté","pos":"NOM"} ,
		{"word":"volontés","word_nosc":"volontes","lemma":"volonté","pos":"NOM"} ,
		{"word":"vols","word_nosc":"vols","lemma":"vol","pos":"NOM"} ,
		{"word":"volt","word_nosc":"volt","lemma":"volt","pos":"NOM"} ,
		{"word":"voltage","word_nosc":"voltage","lemma":"voltage","pos":"NOM"} ,
		{"word":"voltaire","word_nosc":"voltaire","lemma":"voltaire","pos":"NOM"} ,
		{"word":"voltaires","word_nosc":"voltaires","lemma":"voltaire","pos":"NOM"} ,
		{"word":"volte","word_nosc":"volte","lemma":"volte","pos":"NOM"} ,
		{"word":"volte-face","word_nosc":"volte-face","lemma":"volte-face","pos":"NOM"} ,
		{"word":"voltes","word_nosc":"voltes","lemma":"volte","pos":"NOM"} ,
		{"word":"voltige","word_nosc":"voltige","lemma":"voltige","pos":"NOM"} ,
		{"word":"voltigement","word_nosc":"voltigement","lemma":"voltigement","pos":"NOM"} ,
		{"word":"voltiges","word_nosc":"voltiges","lemma":"voltige","pos":"NOM"} ,
		{"word":"voltigeur","word_nosc":"voltigeur","lemma":"voltigeur","pos":"NOM"} ,
		{"word":"voltigeurs","word_nosc":"voltigeurs","lemma":"voltigeur","pos":"NOM"} ,
		{"word":"voltmètre","word_nosc":"voltmetre","lemma":"voltmètre","pos":"NOM"} ,
		{"word":"volts","word_nosc":"volts","lemma":"volt","pos":"NOM"} ,
		{"word":"volubilis","word_nosc":"volubilis","lemma":"volubilis","pos":"NOM"} ,
		{"word":"volubilité","word_nosc":"volubilite","lemma":"volubilité","pos":"NOM"} ,
		{"word":"volume","word_nosc":"volume","lemma":"volume","pos":"NOM"} ,
		{"word":"volumes","word_nosc":"volumes","lemma":"volume","pos":"NOM"} ,
		{"word":"volupté","word_nosc":"volupte","lemma":"volupté","pos":"NOM"} ,
		{"word":"voluptés","word_nosc":"voluptes","lemma":"volupté","pos":"NOM"} ,
		{"word":"volute","word_nosc":"volute","lemma":"volute","pos":"NOM"} ,
		{"word":"volutes","word_nosc":"volutes","lemma":"volute","pos":"NOM"} ,
		{"word":"volvaires","word_nosc":"volvaires","lemma":"volvaire","pos":"NOM"} ,
		{"word":"volve","word_nosc":"volve","lemma":"volve","pos":"NOM"} ,
		{"word":"volée","word_nosc":"volee","lemma":"volée","pos":"NOM"} ,
		{"word":"volées","word_nosc":"volees","lemma":"volée","pos":"NOM"} ,
		{"word":"volémie","word_nosc":"volemie","lemma":"volémie","pos":"NOM"} ,
		{"word":"vomi","word_nosc":"vomi","lemma":"vomi","pos":"NOM"} ,
		{"word":"vomis","word_nosc":"vomis","lemma":"vomi","pos":"NOM"} ,
		{"word":"vomissement","word_nosc":"vomissement","lemma":"vomissement","pos":"NOM"} ,
		{"word":"vomissements","word_nosc":"vomissements","lemma":"vomissement","pos":"NOM"} ,
		{"word":"vomissure","word_nosc":"vomissure","lemma":"vomissure","pos":"NOM"} ,
		{"word":"vomissures","word_nosc":"vomissures","lemma":"vomissure","pos":"NOM"} ,
		{"word":"vomitif","word_nosc":"vomitif","lemma":"vomitif","pos":"NOM"} ,
		{"word":"vomitifs","word_nosc":"vomitifs","lemma":"vomitif","pos":"NOM"} ,
		{"word":"vomito negro","word_nosc":"vomito negro","lemma":"vomito negro","pos":"NOM"} ,
		{"word":"vomitoire","word_nosc":"vomitoire","lemma":"vomitoire","pos":"NOM"} ,
		{"word":"vomitoires","word_nosc":"vomitoires","lemma":"vomitoire","pos":"NOM"} ,
		{"word":"vopo","word_nosc":"vopo","lemma":"vopo","pos":"NOM"} ,
		{"word":"voracité","word_nosc":"voracite","lemma":"voracité","pos":"NOM"} ,
		{"word":"vortex","word_nosc":"vortex","lemma":"vortex","pos":"NOM"} ,
		{"word":"vosgien","word_nosc":"vosgien","lemma":"vosgien","pos":"NOM"} ,
		{"word":"votant","word_nosc":"votant","lemma":"votant","pos":"NOM"} ,
		{"word":"votants","word_nosc":"votants","lemma":"votant","pos":"NOM"} ,
		{"word":"vote","word_nosc":"vote","lemma":"vote","pos":"NOM"} ,
		{"word":"votes","word_nosc":"votes","lemma":"vote","pos":"NOM"} ,
		{"word":"vouge","word_nosc":"vouge","lemma":"vouge","pos":"NOM"} ,
		{"word":"vouivre","word_nosc":"vouivre","lemma":"vouivre","pos":"NOM"} ,
		{"word":"vouivres","word_nosc":"vouivres","lemma":"vouivre","pos":"NOM"} ,
		{"word":"vouloir","word_nosc":"vouloir","lemma":"vouloir","pos":"NOM"} ,
		{"word":"vouloirs","word_nosc":"vouloirs","lemma":"vouloir","pos":"NOM"} ,
		{"word":"voussoiement","word_nosc":"voussoiement","lemma":"voussoiement","pos":"NOM"} ,
		{"word":"voussure","word_nosc":"voussure","lemma":"voussure","pos":"NOM"} ,
		{"word":"voussures","word_nosc":"voussures","lemma":"voussure","pos":"NOM"} ,
		{"word":"vouvoiement","word_nosc":"vouvoiement","lemma":"vouvoiement","pos":"NOM"} ,
		{"word":"vouvoiements","word_nosc":"vouvoiements","lemma":"vouvoiement","pos":"NOM"} ,
		{"word":"vouvray","word_nosc":"vouvray","lemma":"vouvray","pos":"NOM"} ,
		{"word":"vox populi","word_nosc":"vox populi","lemma":"vox populi","pos":"NOM"} ,
		{"word":"voyage","word_nosc":"voyage","lemma":"voyage","pos":"NOM"} ,
		{"word":"voyages","word_nosc":"voyages","lemma":"voyage","pos":"NOM"} ,
		{"word":"voyages-éclair","word_nosc":"voyages-eclair","lemma":"voyage-éclair","pos":"NOM"} ,
		{"word":"voyageur","word_nosc":"voyageur","lemma":"voyageur","pos":"NOM"} ,
		{"word":"voyageurs","word_nosc":"voyageurs","lemma":"voyageur","pos":"NOM"} ,
		{"word":"voyageuse","word_nosc":"voyageuse","lemma":"voyageur","pos":"NOM"} ,
		{"word":"voyageuses","word_nosc":"voyageuses","lemma":"voyageur","pos":"NOM"} ,
		{"word":"voyance","word_nosc":"voyance","lemma":"voyance","pos":"NOM"} ,
		{"word":"voyances","word_nosc":"voyances","lemma":"voyance","pos":"NOM"} ,
		{"word":"voyant","word_nosc":"voyant","lemma":"voyant","pos":"NOM"} ,
		{"word":"voyante","word_nosc":"voyante","lemma":"voyant","pos":"NOM"} ,
		{"word":"voyantes","word_nosc":"voyantes","lemma":"voyant","pos":"NOM"} ,
		{"word":"voyants","word_nosc":"voyants","lemma":"voyant","pos":"NOM"} ,
		{"word":"voyelle","word_nosc":"voyelle","lemma":"voyelle","pos":"NOM"} ,
		{"word":"voyelles","word_nosc":"voyelles","lemma":"voyelle","pos":"NOM"} ,
		{"word":"voyer","word_nosc":"voyer","lemma":"voyer","pos":"NOM"} ,
		{"word":"voyeur","word_nosc":"voyeur","lemma":"voyeur","pos":"NOM"} ,
		{"word":"voyeurisme","word_nosc":"voyeurisme","lemma":"voyeurisme","pos":"NOM"} ,
		{"word":"voyeurs","word_nosc":"voyeurs","lemma":"voyeur","pos":"NOM"} ,
		{"word":"voyeuse","word_nosc":"voyeuse","lemma":"voyeur","pos":"NOM"} ,
		{"word":"voyou","word_nosc":"voyou","lemma":"voyou","pos":"NOM"} ,
		{"word":"voyoucratie","word_nosc":"voyoucratie","lemma":"voyoucratie","pos":"NOM"} ,
		{"word":"voyous","word_nosc":"voyous","lemma":"voyou","pos":"NOM"} ,
		{"word":"voyoute","word_nosc":"voyoute","lemma":"voyou","pos":"NOM"} ,
		{"word":"voyouterie","word_nosc":"voyouterie","lemma":"voyouterie","pos":"NOM"} ,
		{"word":"voyoutisme","word_nosc":"voyoutisme","lemma":"voyoutisme","pos":"NOM"} ,
		{"word":"voïvodie","word_nosc":"voivodie","lemma":"voïvodie","pos":"NOM"} ,
		{"word":"voïvodies","word_nosc":"voivodies","lemma":"voïvodie","pos":"NOM"} ,
		{"word":"voûte","word_nosc":"voute","lemma":"voûte","pos":"NOM"} ,
		{"word":"voûtes","word_nosc":"voutes","lemma":"voûte","pos":"NOM"} ,
		{"word":"vrac","word_nosc":"vrac","lemma":"vrac","pos":"NOM"} ,
		{"word":"vrai","word_nosc":"vrai","lemma":"vrai","pos":"NOM"} ,
		{"word":"vrais","word_nosc":"vrais","lemma":"vrai","pos":"NOM"} ,
		{"word":"vraisemblance","word_nosc":"vraisemblance","lemma":"vraisemblance","pos":"NOM"} ,
		{"word":"vraisemblances","word_nosc":"vraisemblances","lemma":"vraisemblance","pos":"NOM"} ,
		{"word":"vraquier","word_nosc":"vraquier","lemma":"vraquier","pos":"NOM"} ,
		{"word":"vrille","word_nosc":"vrille","lemma":"vrille","pos":"NOM"} ,
		{"word":"vrillement","word_nosc":"vrillement","lemma":"vrillement","pos":"NOM"} ,
		{"word":"vrilles","word_nosc":"vrilles","lemma":"vrille","pos":"NOM"} ,
		{"word":"vrillette","word_nosc":"vrillette","lemma":"vrillette","pos":"NOM"} ,
		{"word":"vrillettes","word_nosc":"vrillettes","lemma":"vrillette","pos":"NOM"} ,
		{"word":"vrombissement","word_nosc":"vrombissement","lemma":"vrombissement","pos":"NOM"} ,
		{"word":"vrombissements","word_nosc":"vrombissements","lemma":"vrombissement","pos":"NOM"} ,
		{"word":"vu","word_nosc":"vu","lemma":"vu","pos":"NOM"} ,
		{"word":"vue","word_nosc":"vue","lemma":"vue","pos":"NOM"} ,
		{"word":"vues","word_nosc":"vues","lemma":"vue","pos":"NOM"} ,
		{"word":"vulcain","word_nosc":"vulcain","lemma":"vulcain","pos":"NOM"} ,
		{"word":"vulcanisation","word_nosc":"vulcanisation","lemma":"vulcanisation","pos":"NOM"} ,
		{"word":"vulcanologue","word_nosc":"vulcanologue","lemma":"vulcanologue","pos":"NOM"} ,
		{"word":"vulgaire","word_nosc":"vulgaire","lemma":"vulgaire","pos":"NOM"} ,
		{"word":"vulgaires","word_nosc":"vulgaires","lemma":"vulgaire","pos":"NOM"} ,
		{"word":"vulgarisateurs","word_nosc":"vulgarisateurs","lemma":"vulgarisateur","pos":"NOM"} ,
		{"word":"vulgarisation","word_nosc":"vulgarisation","lemma":"vulgarisation","pos":"NOM"} ,
		{"word":"vulgarisations","word_nosc":"vulgarisations","lemma":"vulgarisation","pos":"NOM"} ,
		{"word":"vulgarité","word_nosc":"vulgarite","lemma":"vulgarité","pos":"NOM"} ,
		{"word":"vulgarités","word_nosc":"vulgarites","lemma":"vulgarité","pos":"NOM"} ,
		{"word":"vulgate","word_nosc":"vulgate","lemma":"vulgate","pos":"NOM"} ,
		{"word":"vulgum pecus","word_nosc":"vulgum pecus","lemma":"vulgum pecus","pos":"NOM"} ,
		{"word":"vulnérabilité","word_nosc":"vulnerabilite","lemma":"vulnérabilité","pos":"NOM"} ,
		{"word":"vulnéraire","word_nosc":"vulneraire","lemma":"vulnéraire","pos":"NOM"} ,
		{"word":"vulnéraires","word_nosc":"vulneraires","lemma":"vulnéraire","pos":"NOM"} ,
		{"word":"vulpin","word_nosc":"vulpin","lemma":"vulpin","pos":"NOM"} ,
		{"word":"vulvaire","word_nosc":"vulvaire","lemma":"vulvaire","pos":"NOM"} ,
		{"word":"vulvaires","word_nosc":"vulvaires","lemma":"vulvaire","pos":"NOM"} ,
		{"word":"vulve","word_nosc":"vulve","lemma":"vulve","pos":"NOM"} ,
		{"word":"vulves","word_nosc":"vulves","lemma":"vulve","pos":"NOM"} ,
		{"word":"vécu","word_nosc":"vecu","lemma":"vécu","pos":"NOM"} ,
		{"word":"vécus","word_nosc":"vecus","lemma":"vécu","pos":"NOM"} ,
		{"word":"vécés","word_nosc":"veces","lemma":"vécés","pos":"NOM"} ,
		{"word":"végétal","word_nosc":"vegetal","lemma":"végétal","pos":"NOM"} ,
		{"word":"végétalien","word_nosc":"vegetalien","lemma":"végétalien","pos":"NOM"} ,
		{"word":"végétalienne","word_nosc":"vegetalienne","lemma":"végétalien","pos":"NOM"} ,
		{"word":"végétalisme","word_nosc":"vegetalisme","lemma":"végétalisme","pos":"NOM"} ,
		{"word":"végétarien","word_nosc":"vegetarien","lemma":"végétarien","pos":"NOM"} ,
		{"word":"végétariens","word_nosc":"vegetariens","lemma":"végétarien","pos":"NOM"} ,
		{"word":"végétarisme","word_nosc":"vegetarisme","lemma":"végétarisme","pos":"NOM"} ,
		{"word":"végétation","word_nosc":"vegetation","lemma":"végétation","pos":"NOM"} ,
		{"word":"végétations","word_nosc":"vegetations","lemma":"végétation","pos":"NOM"} ,
		{"word":"végétaux","word_nosc":"vegetaux","lemma":"végétal","pos":"NOM"} ,
		{"word":"véhicule","word_nosc":"vehicule","lemma":"véhicule","pos":"NOM"} ,
		{"word":"véhicules","word_nosc":"vehicules","lemma":"véhicule","pos":"NOM"} ,
		{"word":"véhémence","word_nosc":"vehemence","lemma":"véhémence","pos":"NOM"} ,
		{"word":"vélaires","word_nosc":"velaires","lemma":"vélaire","pos":"NOM"} ,
		{"word":"vélar","word_nosc":"velar","lemma":"vélar","pos":"NOM"} ,
		{"word":"vélin","word_nosc":"velin","lemma":"vélin","pos":"NOM"} ,
		{"word":"vélins","word_nosc":"velins","lemma":"vélin","pos":"NOM"} ,
		{"word":"véliplanchiste","word_nosc":"veliplanchiste","lemma":"véliplanchiste","pos":"NOM"} ,
		{"word":"vélo","word_nosc":"velo","lemma":"vélo","pos":"NOM"} ,
		{"word":"vélo-cross","word_nosc":"velo-cross","lemma":"vélo-cross","pos":"NOM"} ,
		{"word":"vélo-pousse","word_nosc":"velo-pousse","lemma":"vélo-pousse","pos":"NOM"} ,
		{"word":"vélocipède","word_nosc":"velocipede","lemma":"vélocipède","pos":"NOM"} ,
		{"word":"vélocipèdes","word_nosc":"velocipedes","lemma":"vélocipède","pos":"NOM"} ,
		{"word":"vélocipédistes","word_nosc":"velocipedistes","lemma":"vélocipédiste","pos":"NOM"} ,
		{"word":"vélocité","word_nosc":"velocite","lemma":"vélocité","pos":"NOM"} ,
		{"word":"vélodrome","word_nosc":"velodrome","lemma":"vélodrome","pos":"NOM"} ,
		{"word":"vélodromes","word_nosc":"velodromes","lemma":"vélodrome","pos":"NOM"} ,
		{"word":"vélomoteur","word_nosc":"velomoteur","lemma":"vélomoteur","pos":"NOM"} ,
		{"word":"vélomoteurs","word_nosc":"velomoteurs","lemma":"vélomoteur","pos":"NOM"} ,
		{"word":"vélos","word_nosc":"velos","lemma":"vélo","pos":"NOM"} ,
		{"word":"vélum","word_nosc":"velum","lemma":"vélum","pos":"NOM"} ,
		{"word":"vélums","word_nosc":"velums","lemma":"vélum","pos":"NOM"} ,
		{"word":"vénalité","word_nosc":"venalite","lemma":"vénalité","pos":"NOM"} ,
		{"word":"vénerie","word_nosc":"venerie","lemma":"vénerie","pos":"NOM"} ,
		{"word":"vénitien","word_nosc":"venitien","lemma":"vénitien","pos":"NOM"} ,
		{"word":"vénitienne","word_nosc":"venitienne","lemma":"vénitienne","pos":"NOM"} ,
		{"word":"vénitiennes","word_nosc":"venitiennes","lemma":"vénitienne","pos":"NOM"} ,
		{"word":"vénitiens","word_nosc":"venitiens","lemma":"vénitien","pos":"NOM"} ,
		{"word":"vénus","word_nosc":"venus","lemma":"vénus","pos":"NOM"} ,
		{"word":"vénusté","word_nosc":"venuste","lemma":"vénusté","pos":"NOM"} ,
		{"word":"vénérable","word_nosc":"venerable","lemma":"vénérable","pos":"NOM"} ,
		{"word":"vénérables","word_nosc":"venerables","lemma":"vénérable","pos":"NOM"} ,
		{"word":"vénération","word_nosc":"veneration","lemma":"vénération","pos":"NOM"} ,
		{"word":"vénérations","word_nosc":"venerations","lemma":"vénération","pos":"NOM"} ,
		{"word":"vénéréologie","word_nosc":"venereologie","lemma":"vénéréologie","pos":"NOM"} ,
		{"word":"vénézuélien","word_nosc":"venezuelien","lemma":"vénézuélien","pos":"NOM"} ,
		{"word":"vénézuéliens","word_nosc":"venezueliens","lemma":"vénézuélien","pos":"NOM"} ,
		{"word":"véracité","word_nosc":"veracite","lemma":"véracité","pos":"NOM"} ,
		{"word":"véranda","word_nosc":"veranda","lemma":"véranda","pos":"NOM"} ,
		{"word":"vérandas","word_nosc":"verandas","lemma":"véranda","pos":"NOM"} ,
		{"word":"vérif","word_nosc":"verif","lemma":"vérif","pos":"NOM"} ,
		{"word":"vérificateur","word_nosc":"verificateur","lemma":"vérificateur","pos":"NOM"} ,
		{"word":"vérificateurs","word_nosc":"verificateurs","lemma":"vérificateur","pos":"NOM"} ,
		{"word":"vérification","word_nosc":"verification","lemma":"vérification","pos":"NOM"} ,
		{"word":"vérifications","word_nosc":"verifications","lemma":"vérification","pos":"NOM"} ,
		{"word":"vérificatrice","word_nosc":"verificatrice","lemma":"vérificateur","pos":"NOM"} ,
		{"word":"vérifieur","word_nosc":"verifieur","lemma":"vérifieur","pos":"NOM"} ,
		{"word":"vérin","word_nosc":"verin","lemma":"vérin","pos":"NOM"} ,
		{"word":"vérins","word_nosc":"verins","lemma":"vérin","pos":"NOM"} ,
		{"word":"vérité","word_nosc":"verite","lemma":"vérité","pos":"NOM"} ,
		{"word":"vérités","word_nosc":"verites","lemma":"vérité","pos":"NOM"} ,
		{"word":"vérole","word_nosc":"verole","lemma":"vérole","pos":"NOM"} ,
		{"word":"véroles","word_nosc":"veroles","lemma":"vérole","pos":"NOM"} ,
		{"word":"véronal","word_nosc":"veronal","lemma":"véronal","pos":"NOM"} ,
		{"word":"véronique","word_nosc":"veronique","lemma":"véronique","pos":"NOM"} ,
		{"word":"véroniques","word_nosc":"veroniques","lemma":"véronique","pos":"NOM"} ,
		{"word":"vésanie","word_nosc":"vesanie","lemma":"vésanie","pos":"NOM"} ,
		{"word":"vésanies","word_nosc":"vesanies","lemma":"vésanie","pos":"NOM"} ,
		{"word":"vésicule","word_nosc":"vesicule","lemma":"vésicule","pos":"NOM"} ,
		{"word":"vésicules","word_nosc":"vesicules","lemma":"vésicule","pos":"NOM"} ,
		{"word":"vétille","word_nosc":"vetille","lemma":"vétille","pos":"NOM"} ,
		{"word":"vétilles","word_nosc":"vetilles","lemma":"vétille","pos":"NOM"} ,
		{"word":"vétiver","word_nosc":"vetiver","lemma":"vétiver","pos":"NOM"} ,
		{"word":"vétusté","word_nosc":"vetuste","lemma":"vétusté","pos":"NOM"} ,
		{"word":"vétyver","word_nosc":"vetyver","lemma":"vétyver","pos":"NOM"} ,
		{"word":"vétéran","word_nosc":"veteran","lemma":"vétéran","pos":"NOM"} ,
		{"word":"vétérans","word_nosc":"veterans","lemma":"vétéran","pos":"NOM"} ,
		{"word":"vétérinaire","word_nosc":"veterinaire","lemma":"vétérinaire","pos":"NOM"} ,
		{"word":"vétérinaires","word_nosc":"veterinaires","lemma":"vétérinaire","pos":"NOM"} ,
		{"word":"vêlage","word_nosc":"velage","lemma":"vêlage","pos":"NOM"} ,
		{"word":"vêlages","word_nosc":"velages","lemma":"vêlage","pos":"NOM"} ,
		{"word":"vêpre","word_nosc":"vepre","lemma":"vêpres","pos":"NOM"} ,
		{"word":"vêpres","word_nosc":"vepres","lemma":"vêpre","pos":"NOM"} ,
		{"word":"vêtement","word_nosc":"vetement","lemma":"vêtement","pos":"NOM"} ,
		{"word":"vêtements","word_nosc":"vetements","lemma":"vêtement","pos":"NOM"} ,
		{"word":"vêture","word_nosc":"veture","lemma":"vêture","pos":"NOM"} ,
		{"word":"vêtures","word_nosc":"vetures","lemma":"vêture","pos":"NOM"} ,
		{"word":"w","word_nosc":"w","lemma":"w","pos":"NOM"} ,
		{"word":"wagon","word_nosc":"wagon","lemma":"wagon","pos":"NOM"} ,
		{"word":"wagon-bar","word_nosc":"wagon-bar","lemma":"wagon-bar","pos":"NOM"} ,
		{"word":"wagon-lit","word_nosc":"wagon-lit","lemma":"wagon-lit","pos":"NOM"} ,
		{"word":"wagon-lits","word_nosc":"wagon-lits","lemma":"wagon-lits","pos":"NOM"} ,
		{"word":"wagon-restaurant","word_nosc":"wagon-restaurant","lemma":"wagon-restaurant","pos":"NOM"} ,
		{"word":"wagon-salon","word_nosc":"wagon-salon","lemma":"wagon-salon","pos":"NOM"} ,
		{"word":"wagonnet","word_nosc":"wagonnet","lemma":"wagonnet","pos":"NOM"} ,
		{"word":"wagonnets","word_nosc":"wagonnets","lemma":"wagonnet","pos":"NOM"} ,
		{"word":"wagons","word_nosc":"wagons","lemma":"wagon","pos":"NOM"} ,
		{"word":"wagons-citernes","word_nosc":"wagons-citernes","lemma":"wagon-citerne","pos":"NOM"} ,
		{"word":"wagons-lits","word_nosc":"wagons-lits","lemma":"wagon-lit","pos":"NOM"} ,
		{"word":"wagons-restaurants","word_nosc":"wagons-restaurants","lemma":"wagon-restaurant","pos":"NOM"} ,
		{"word":"wait and see","word_nosc":"wait and see","lemma":"wait and see","pos":"NOM"} ,
		{"word":"wali","word_nosc":"wali","lemma":"wali","pos":"NOM"} ,
		{"word":"walkie-talkie","word_nosc":"walkie-talkie","lemma":"walkie-talkie","pos":"NOM"} ,
		{"word":"walkies-talkies","word_nosc":"walkies-talkies","lemma":"walkie-talkie","pos":"NOM"} ,
		{"word":"walkman","word_nosc":"walkman","lemma":"walkman","pos":"NOM"} ,
		{"word":"walkmans","word_nosc":"walkmans","lemma":"walkman","pos":"NOM"} ,
		{"word":"walkyrie","word_nosc":"walkyrie","lemma":"walkyrie","pos":"NOM"} ,
		{"word":"wall street","word_nosc":"wall street","lemma":"wall street","pos":"NOM"} ,
		{"word":"wallaby","word_nosc":"wallaby","lemma":"wallaby","pos":"NOM"} ,
		{"word":"wallace","word_nosc":"wallace","lemma":"wallace","pos":"NOM"} ,
		{"word":"walter","word_nosc":"walter","lemma":"walter","pos":"NOM"} ,
		{"word":"wapiti","word_nosc":"wapiti","lemma":"wapiti","pos":"NOM"} ,
		{"word":"wapitis","word_nosc":"wapitis","lemma":"wapiti","pos":"NOM"} ,
		{"word":"warning","word_nosc":"warning","lemma":"warning","pos":"NOM"} ,
		{"word":"warnings","word_nosc":"warnings","lemma":"warning","pos":"NOM"} ,
		{"word":"warrants","word_nosc":"warrants","lemma":"warrant","pos":"NOM"} ,
		{"word":"wassingue","word_nosc":"wassingue","lemma":"wassingue","pos":"NOM"} ,
		{"word":"wassingues","word_nosc":"wassingues","lemma":"wassingue","pos":"NOM"} ,
		{"word":"water","word_nosc":"water","lemma":"water","pos":"NOM"} ,
		{"word":"water-closet","word_nosc":"water-closet","lemma":"water-closet","pos":"NOM"} ,
		{"word":"water-polo","word_nosc":"water-polo","lemma":"water-polo","pos":"NOM"} ,
		{"word":"waterman","word_nosc":"waterman","lemma":"waterman","pos":"NOM"} ,
		{"word":"waters","word_nosc":"waters","lemma":"water","pos":"NOM"} ,
		{"word":"watt","word_nosc":"watt","lemma":"watt","pos":"NOM"} ,
		{"word":"wattman","word_nosc":"wattman","lemma":"wattman","pos":"NOM"} ,
		{"word":"watts","word_nosc":"watts","lemma":"watt","pos":"NOM"} ,
		{"word":"wax","word_nosc":"wax","lemma":"wax","pos":"NOM"} ,
		{"word":"way of life","word_nosc":"way of life","lemma":"way of life","pos":"NOM"} ,
		{"word":"web","word_nosc":"web","lemma":"web","pos":"NOM"} ,
		{"word":"webcam","word_nosc":"webcam","lemma":"webcam","pos":"NOM"} ,
		{"word":"week end","word_nosc":"week end","lemma":"week-end","pos":"NOM"} ,
		{"word":"week-end","word_nosc":"week-end","lemma":"week-end","pos":"NOM"} ,
		{"word":"week-ends","word_nosc":"week-ends","lemma":"week-end","pos":"NOM"} ,
		{"word":"weltanschauung","word_nosc":"weltanschauung","lemma":"weltanschauung","pos":"NOM"} ,
		{"word":"welter","word_nosc":"welter","lemma":"welter","pos":"NOM"} ,
		{"word":"welters","word_nosc":"welters","lemma":"welter","pos":"NOM"} ,
		{"word":"western","word_nosc":"western","lemma":"western","pos":"NOM"} ,
		{"word":"western-spaghetti","word_nosc":"western-spaghetti","lemma":"western-spaghetti","pos":"NOM"} ,
		{"word":"westerns","word_nosc":"westerns","lemma":"western","pos":"NOM"} ,
		{"word":"wharf","word_nosc":"wharf","lemma":"wharf","pos":"NOM"} ,
		{"word":"whig","word_nosc":"whig","lemma":"whig","pos":"NOM"} ,
		{"word":"whigs","word_nosc":"whigs","lemma":"whig","pos":"NOM"} ,
		{"word":"whipcord","word_nosc":"whipcord","lemma":"whipcord","pos":"NOM"} ,
		{"word":"whiskey","word_nosc":"whiskey","lemma":"whiskey","pos":"NOM"} ,
		{"word":"whiskies","word_nosc":"whiskies","lemma":"whiskies","pos":"NOM"} ,
		{"word":"whisky","word_nosc":"whisky","lemma":"whisky","pos":"NOM"} ,
		{"word":"whisky-soda","word_nosc":"whisky-soda","lemma":"whisky-soda","pos":"NOM"} ,
		{"word":"whiskys","word_nosc":"whiskys","lemma":"whisky","pos":"NOM"} ,
		{"word":"whist","word_nosc":"whist","lemma":"whist","pos":"NOM"} ,
		{"word":"white-spirit","word_nosc":"white-spirit","lemma":"white-spirit","pos":"NOM"} ,
		{"word":"wicket","word_nosc":"wicket","lemma":"wicket","pos":"NOM"} ,
		{"word":"wigwam","word_nosc":"wigwam","lemma":"wigwam","pos":"NOM"} ,
		{"word":"wigwams","word_nosc":"wigwams","lemma":"wigwam","pos":"NOM"} ,
		{"word":"wildcat","word_nosc":"wildcat","lemma":"wildcat","pos":"NOM"} ,
		{"word":"willaya","word_nosc":"willaya","lemma":"willaya","pos":"NOM"} ,
		{"word":"willayas","word_nosc":"willayas","lemma":"willaya","pos":"NOM"} ,
		{"word":"william","word_nosc":"william","lemma":"william","pos":"NOM"} ,
		{"word":"williams","word_nosc":"williams","lemma":"williams","pos":"NOM"} ,
		{"word":"winchester","word_nosc":"winchester","lemma":"winchester","pos":"NOM"} ,
		{"word":"winchesters","word_nosc":"winchesters","lemma":"winchester","pos":"NOM"} ,
		{"word":"windsurf","word_nosc":"windsurf","lemma":"windsurf","pos":"NOM"} ,
		{"word":"wintergreen","word_nosc":"wintergreen","lemma":"wintergreen","pos":"NOM"} ,
		{"word":"wishbone","word_nosc":"wishbone","lemma":"wishbone","pos":"NOM"} ,
		{"word":"wisigothe","word_nosc":"wisigothe","lemma":"wisigoth","pos":"NOM"} ,
		{"word":"wisigoths","word_nosc":"wisigoths","lemma":"wisigoth","pos":"NOM"} ,
		{"word":"witz","word_nosc":"witz","lemma":"witz","pos":"NOM"} ,
		{"word":"wombat","word_nosc":"wombat","lemma":"wombat","pos":"NOM"} ,
		{"word":"woofer","word_nosc":"woofer","lemma":"woofer","pos":"NOM"} ,
		{"word":"woofers","word_nosc":"woofers","lemma":"woofer","pos":"NOM"} ,
		{"word":"world music","word_nosc":"world music","lemma":"world music","pos":"NOM"} ,
		{"word":"wurtembergeois","word_nosc":"wurtembergeois","lemma":"wurtembergeois","pos":"NOM"} ,
		{"word":"xavier","word_nosc":"xavier","lemma":"xavier","pos":"NOM"} ,
		{"word":"xiang","word_nosc":"xiang","lemma":"xiang","pos":"NOM"} ,
		{"word":"xiphidion","word_nosc":"xiphidion","lemma":"xiphidion","pos":"NOM"} ,
		{"word":"xylographie","word_nosc":"xylographie","lemma":"xylographie","pos":"NOM"} ,
		{"word":"xylophone","word_nosc":"xylophone","lemma":"xylophone","pos":"NOM"} ,
		{"word":"xylophones","word_nosc":"xylophones","lemma":"xylophone","pos":"NOM"} ,
		{"word":"xylophoniste","word_nosc":"xylophoniste","lemma":"xylophoniste","pos":"NOM"} ,
		{"word":"xylène","word_nosc":"xylene","lemma":"xylène","pos":"NOM"} ,
		{"word":"xénogenèse","word_nosc":"xenogenese","lemma":"xénogenèse","pos":"NOM"} ,
		{"word":"xénogreffe","word_nosc":"xenogreffe","lemma":"xénogreffe","pos":"NOM"} ,
		{"word":"xénon","word_nosc":"xenon","lemma":"xénon","pos":"NOM"} ,
		{"word":"xénophilie","word_nosc":"xenophilie","lemma":"xénophilie","pos":"NOM"} ,
		{"word":"xénophobe","word_nosc":"xenophobe","lemma":"xénophobe","pos":"NOM"} ,
		{"word":"xénophobes","word_nosc":"xenophobes","lemma":"xénophobe","pos":"NOM"} ,
		{"word":"xénophobie","word_nosc":"xenophobie","lemma":"xénophobie","pos":"NOM"} ,
		{"word":"xérographie","word_nosc":"xerographie","lemma":"xérographie","pos":"NOM"} ,
		{"word":"xérès","word_nosc":"xeres","lemma":"xérès","pos":"NOM"} ,
		{"word":"y","word_nosc":"y","lemma":"y","pos":"NOM"} ,
		{"word":"ya","word_nosc":"ya","lemma":"ya","pos":"NOM"} ,
		{"word":"yacht","word_nosc":"yacht","lemma":"yacht","pos":"NOM"} ,
		{"word":"yacht-club","word_nosc":"yacht-club","lemma":"yacht-club","pos":"NOM"} ,
		{"word":"yachting","word_nosc":"yachting","lemma":"yachting","pos":"NOM"} ,
		{"word":"yachtman","word_nosc":"yachtman","lemma":"yachtman","pos":"NOM"} ,
		{"word":"yachtmen","word_nosc":"yachtmen","lemma":"yachtman","pos":"NOM"} ,
		{"word":"yachts","word_nosc":"yachts","lemma":"yacht","pos":"NOM"} ,
		{"word":"yachtwoman","word_nosc":"yachtwoman","lemma":"yachtwoman","pos":"NOM"} ,
		{"word":"yack","word_nosc":"yack","lemma":"yack","pos":"NOM"} ,
		{"word":"yacks","word_nosc":"yacks","lemma":"yack","pos":"NOM"} ,
		{"word":"yak","word_nosc":"yak","lemma":"yak","pos":"NOM"} ,
		{"word":"yakitori","word_nosc":"yakitori","lemma":"yakitori","pos":"NOM"} ,
		{"word":"yaks","word_nosc":"yaks","lemma":"yak","pos":"NOM"} ,
		{"word":"yakusa","word_nosc":"yakusa","lemma":"yakusa","pos":"NOM"} ,
		{"word":"yakuza","word_nosc":"yakuza","lemma":"yakuza","pos":"NOM"} ,
		{"word":"yakuzas","word_nosc":"yakuzas","lemma":"yakuza","pos":"NOM"} ,
		{"word":"yali","word_nosc":"yali","lemma":"yali","pos":"NOM"} ,
		{"word":"yalis","word_nosc":"yalis","lemma":"yali","pos":"NOM"} ,
		{"word":"yama","word_nosc":"yama","lemma":"yama","pos":"NOM"} ,
		{"word":"yang","word_nosc":"yang","lemma":"yang","pos":"NOM"} ,
		{"word":"yankee","word_nosc":"yankee","lemma":"yankee","pos":"NOM"} ,
		{"word":"yankees","word_nosc":"yankees","lemma":"yankee","pos":"NOM"} ,
		{"word":"yaourt","word_nosc":"yaourt","lemma":"yaourt","pos":"NOM"} ,
		{"word":"yaourtières","word_nosc":"yaourtieres","lemma":"yaourtière","pos":"NOM"} ,
		{"word":"yaourts","word_nosc":"yaourts","lemma":"yaourt","pos":"NOM"} ,
		{"word":"yard","word_nosc":"yard","lemma":"yard","pos":"NOM"} ,
		{"word":"yards","word_nosc":"yards","lemma":"yard","pos":"NOM"} ,
		{"word":"yatagan","word_nosc":"yatagan","lemma":"yatagan","pos":"NOM"} ,
		{"word":"yatagans","word_nosc":"yatagans","lemma":"yatagan","pos":"NOM"} ,
		{"word":"yearling","word_nosc":"yearling","lemma":"yearling","pos":"NOM"} ,
		{"word":"yen","word_nosc":"yen","lemma":"yen","pos":"NOM"} ,
		{"word":"yens","word_nosc":"yens","lemma":"yen","pos":"NOM"} ,
		{"word":"yeshiva","word_nosc":"yeshiva","lemma":"yeshiva","pos":"NOM"} ,
		{"word":"yeti","word_nosc":"yeti","lemma":"yeti","pos":"NOM"} ,
		{"word":"yeuse","word_nosc":"yeuse","lemma":"yeuse","pos":"NOM"} ,
		{"word":"yeuses","word_nosc":"yeuses","lemma":"yeuse","pos":"NOM"} ,
		{"word":"yeux","word_nosc":"yeux","lemma":"oeil","pos":"NOM"} ,
		{"word":"yeux-radars","word_nosc":"yeux-radars","lemma":"oeil-radars","pos":"NOM"} ,
		{"word":"yin","word_nosc":"yin","lemma":"yin","pos":"NOM"} ,
		{"word":"ylang-ylang","word_nosc":"ylang-ylang","lemma":"ylang-ylang","pos":"NOM"} ,
		{"word":"yo-yo","word_nosc":"yo-yo","lemma":"yo-yo","pos":"NOM"} ,
		{"word":"yoga","word_nosc":"yoga","lemma":"yoga","pos":"NOM"} ,
		{"word":"yoghourt","word_nosc":"yoghourt","lemma":"yoghourt","pos":"NOM"} ,
		{"word":"yogi","word_nosc":"yogi","lemma":"yogi","pos":"NOM"} ,
		{"word":"yogis","word_nosc":"yogis","lemma":"yogi","pos":"NOM"} ,
		{"word":"yogourt","word_nosc":"yogourt","lemma":"yogourt","pos":"NOM"} ,
		{"word":"yole","word_nosc":"yole","lemma":"yole","pos":"NOM"} ,
		{"word":"yom kippour","word_nosc":"yom kippour","lemma":"yom kippour","pos":"NOM"} ,
		{"word":"yom kippur","word_nosc":"yom kippur","lemma":"yom kippur","pos":"NOM"} ,
		{"word":"york","word_nosc":"york","lemma":"york","pos":"NOM"} ,
		{"word":"yorkshire","word_nosc":"yorkshire","lemma":"yorkshire","pos":"NOM"} ,
		{"word":"you-you","word_nosc":"you-you","lemma":"you-you","pos":"NOM"} ,
		{"word":"yougoslave","word_nosc":"yougoslave","lemma":"yougoslave","pos":"NOM"} ,
		{"word":"yougoslaves","word_nosc":"yougoslaves","lemma":"yougoslave","pos":"NOM"} ,
		{"word":"youpin","word_nosc":"youpin","lemma":"youpin","pos":"NOM"} ,
		{"word":"youpine","word_nosc":"youpine","lemma":"youpin","pos":"NOM"} ,
		{"word":"yourte","word_nosc":"yourte","lemma":"yourte","pos":"NOM"} ,
		{"word":"yourtes","word_nosc":"yourtes","lemma":"yourte","pos":"NOM"} ,
		{"word":"youtre","word_nosc":"youtre","lemma":"youtre","pos":"NOM"} ,
		{"word":"youyou","word_nosc":"youyou","lemma":"youyou","pos":"NOM"} ,
		{"word":"youyous","word_nosc":"youyous","lemma":"youyou","pos":"NOM"} ,
		{"word":"yoyo","word_nosc":"yoyo","lemma":"yoyo","pos":"NOM"} ,
		{"word":"yoyos","word_nosc":"yoyos","lemma":"yoyo","pos":"NOM"} ,
		{"word":"ypérite","word_nosc":"yperite","lemma":"ypérite","pos":"NOM"} ,
		{"word":"yu","word_nosc":"yu","lemma":"y","pos":"NOM"} ,
		{"word":"yuan","word_nosc":"yuan","lemma":"yuan","pos":"NOM"} ,
		{"word":"yuans","word_nosc":"yuans","lemma":"yuan","pos":"NOM"} ,
		{"word":"yucca","word_nosc":"yucca","lemma":"yucca","pos":"NOM"} ,
		{"word":"yuccas","word_nosc":"yuccas","lemma":"yucca","pos":"NOM"} ,
		{"word":"yue","word_nosc":"yue","lemma":"yue","pos":"NOM"} ,
		{"word":"yuppie","word_nosc":"yuppie","lemma":"yuppie","pos":"NOM"} ,
		{"word":"yuppies","word_nosc":"yuppies","lemma":"yuppie","pos":"NOM"} ,
		{"word":"yèbles","word_nosc":"yebles","lemma":"yèble","pos":"NOM"} ,
		{"word":"yé-yé","word_nosc":"ye-ye","lemma":"yé-yé","pos":"NOM"} ,
		{"word":"yéménites","word_nosc":"yemenites","lemma":"yéménite","pos":"NOM"} ,
		{"word":"yéti","word_nosc":"yeti","lemma":"yéti","pos":"NOM"} ,
		{"word":"yéyé","word_nosc":"yeye","lemma":"yéyé","pos":"NOM"} ,
		{"word":"yéyés","word_nosc":"yeyes","lemma":"yéyé","pos":"NOM"} ,
		{"word":"z","word_nosc":"z","lemma":"z","pos":"NOM"} ,
		{"word":"zaibatsu","word_nosc":"zaibatsu","lemma":"zaibatsu","pos":"NOM"} ,
		{"word":"zakouski","word_nosc":"zakouski","lemma":"zakouski","pos":"NOM"} ,
		{"word":"zakouskis","word_nosc":"zakouskis","lemma":"zakouski","pos":"NOM"} ,
		{"word":"zani","word_nosc":"zani","lemma":"zani","pos":"NOM"} ,
		{"word":"zanimaux","word_nosc":"zanimaux","lemma":"zanimaux","pos":"NOM"} ,
		{"word":"zanzi","word_nosc":"zanzi","lemma":"zanzi","pos":"NOM"} ,
		{"word":"zanzibar","word_nosc":"zanzibar","lemma":"zanzibar","pos":"NOM"} ,
		{"word":"zappeur","word_nosc":"zappeur","lemma":"zappeur","pos":"NOM"} ,
		{"word":"zapping","word_nosc":"zapping","lemma":"zapping","pos":"NOM"} ,
		{"word":"zarabe","word_nosc":"zarabe","lemma":"zarabe","pos":"NOM"} ,
		{"word":"zazou","word_nosc":"zazou","lemma":"zazou","pos":"NOM"} ,
		{"word":"zazous","word_nosc":"zazous","lemma":"zazou","pos":"NOM"} ,
		{"word":"zaïrois","word_nosc":"zairois","lemma":"zaïrois","pos":"NOM"} ,
		{"word":"zeb","word_nosc":"zeb","lemma":"zeb","pos":"NOM"} ,
		{"word":"zef","word_nosc":"zef","lemma":"zef","pos":"NOM"} ,
		{"word":"zelle","word_nosc":"zelle","lemma":"zelle","pos":"NOM"} ,
		{"word":"zemstvo","word_nosc":"zemstvo","lemma":"zemstvo","pos":"NOM"} ,
		{"word":"zen","word_nosc":"zen","lemma":"zen","pos":"NOM"} ,
		{"word":"zens","word_nosc":"zens","lemma":"zen","pos":"NOM"} ,
		{"word":"zeppelin","word_nosc":"zeppelin","lemma":"zeppelin","pos":"NOM"} ,
		{"word":"zeppelins","word_nosc":"zeppelins","lemma":"zeppelin","pos":"NOM"} ,
		{"word":"zest","word_nosc":"zest","lemma":"zest","pos":"NOM"} ,
		{"word":"zeste","word_nosc":"zeste","lemma":"zeste","pos":"NOM"} ,
		{"word":"zestes","word_nosc":"zestes","lemma":"zeste","pos":"NOM"} ,
		{"word":"zibeline","word_nosc":"zibeline","lemma":"zibeline","pos":"NOM"} ,
		{"word":"zibelines","word_nosc":"zibelines","lemma":"zibeline","pos":"NOM"} ,
		{"word":"zicmu","word_nosc":"zicmu","lemma":"zicmu","pos":"NOM"} ,
		{"word":"zig","word_nosc":"zig","lemma":"zig","pos":"NOM"} ,
		{"word":"ziggourat","word_nosc":"ziggourat","lemma":"ziggourat","pos":"NOM"} ,
		{"word":"ziggourats","word_nosc":"ziggourats","lemma":"ziggourat","pos":"NOM"} ,
		{"word":"zigomar","word_nosc":"zigomar","lemma":"zigomar","pos":"NOM"} ,
		{"word":"zigomars","word_nosc":"zigomars","lemma":"zigomar","pos":"NOM"} ,
		{"word":"zigoto","word_nosc":"zigoto","lemma":"zigoto","pos":"NOM"} ,
		{"word":"zigotos","word_nosc":"zigotos","lemma":"zigoto","pos":"NOM"} ,
		{"word":"zigounette","word_nosc":"zigounette","lemma":"zigounette","pos":"NOM"} ,
		{"word":"zigue","word_nosc":"zigue","lemma":"zigue","pos":"NOM"} ,
		{"word":"zigues","word_nosc":"zigues","lemma":"zigue","pos":"NOM"} ,
		{"word":"zigzag","word_nosc":"zigzag","lemma":"zigzag","pos":"NOM"} ,
		{"word":"zigzags","word_nosc":"zigzags","lemma":"zigzag","pos":"NOM"} ,
		{"word":"zinc","word_nosc":"zinc","lemma":"zinc","pos":"NOM"} ,
		{"word":"zincs","word_nosc":"zincs","lemma":"zinc","pos":"NOM"} ,
		{"word":"zingage","word_nosc":"zingage","lemma":"zingage","pos":"NOM"} ,
		{"word":"zingaro","word_nosc":"zingaro","lemma":"zingaro","pos":"NOM"} ,
		{"word":"zinnia","word_nosc":"zinnia","lemma":"zinnia","pos":"NOM"} ,
		{"word":"zinnias","word_nosc":"zinnias","lemma":"zinnia","pos":"NOM"} ,
		{"word":"zinzin","word_nosc":"zinzin","lemma":"zinzin","pos":"NOM"} ,
		{"word":"zinzins","word_nosc":"zinzins","lemma":"zinzin","pos":"NOM"} ,
		{"word":"zip","word_nosc":"zip","lemma":"zip","pos":"NOM"} ,
		{"word":"zircon","word_nosc":"zircon","lemma":"zircon","pos":"NOM"} ,
		{"word":"zirconium","word_nosc":"zirconium","lemma":"zirconium","pos":"NOM"} ,
		{"word":"zircons","word_nosc":"zircons","lemma":"zircon","pos":"NOM"} ,
		{"word":"zizanie","word_nosc":"zizanie","lemma":"zizanie","pos":"NOM"} ,
		{"word":"zizanies","word_nosc":"zizanies","lemma":"zizanie","pos":"NOM"} ,
		{"word":"zizi","word_nosc":"zizi","lemma":"zizi","pos":"NOM"} ,
		{"word":"zizique","word_nosc":"zizique","lemma":"zizique","pos":"NOM"} ,
		{"word":"zizis","word_nosc":"zizis","lemma":"zizi","pos":"NOM"} ,
		{"word":"zloty","word_nosc":"zloty","lemma":"zloty","pos":"NOM"} ,
		{"word":"zlotys","word_nosc":"zlotys","lemma":"zloty","pos":"NOM"} ,
		{"word":"zob","word_nosc":"zob","lemma":"zob","pos":"NOM"} ,
		{"word":"zobs","word_nosc":"zobs","lemma":"zob","pos":"NOM"} ,
		{"word":"zodiac","word_nosc":"zodiac","lemma":"zodiac","pos":"NOM"} ,
		{"word":"zodiaque","word_nosc":"zodiaque","lemma":"zodiaque","pos":"NOM"} ,
		{"word":"zombi","word_nosc":"zombi","lemma":"zombi","pos":"NOM"} ,
		{"word":"zombie","word_nosc":"zombie","lemma":"zombie","pos":"NOM"} ,
		{"word":"zombies","word_nosc":"zombies","lemma":"zombie","pos":"NOM"} ,
		{"word":"zombification","word_nosc":"zombification","lemma":"zombification","pos":"NOM"} ,
		{"word":"zombis","word_nosc":"zombis","lemma":"zombi","pos":"NOM"} ,
		{"word":"zona","word_nosc":"zona","lemma":"zona","pos":"NOM"} ,
		{"word":"zonage","word_nosc":"zonage","lemma":"zonage","pos":"NOM"} ,
		{"word":"zonard","word_nosc":"zonard","lemma":"zonard","pos":"NOM"} ,
		{"word":"zonarde","word_nosc":"zonarde","lemma":"zonard","pos":"NOM"} ,
		{"word":"zonardes","word_nosc":"zonardes","lemma":"zonard","pos":"NOM"} ,
		{"word":"zonards","word_nosc":"zonards","lemma":"zonard","pos":"NOM"} ,
		{"word":"zonas","word_nosc":"zonas","lemma":"zona","pos":"NOM"} ,
		{"word":"zone","word_nosc":"zone","lemma":"zone","pos":"NOM"} ,
		{"word":"zones","word_nosc":"zones","lemma":"zone","pos":"NOM"} ,
		{"word":"zones-clés","word_nosc":"zones-cles","lemma":"zone-clé","pos":"NOM"} ,
		{"word":"zoning","word_nosc":"zoning","lemma":"zoning","pos":"NOM"} ,
		{"word":"zonzon","word_nosc":"zonzon","lemma":"zonzon","pos":"NOM"} ,
		{"word":"zoo","word_nosc":"zoo","lemma":"zoo","pos":"NOM"} ,
		{"word":"zoologie","word_nosc":"zoologie","lemma":"zoologie","pos":"NOM"} ,
		{"word":"zoologiste","word_nosc":"zoologiste","lemma":"zoologiste","pos":"NOM"} ,
		{"word":"zoologue","word_nosc":"zoologue","lemma":"zoologue","pos":"NOM"} ,
		{"word":"zoom","word_nosc":"zoom","lemma":"zoom","pos":"NOM"} ,
		{"word":"zooms","word_nosc":"zooms","lemma":"zoom","pos":"NOM"} ,
		{"word":"zoophiles","word_nosc":"zoophiles","lemma":"zoophile","pos":"NOM"} ,
		{"word":"zoophilie","word_nosc":"zoophilie","lemma":"zoophilie","pos":"NOM"} ,
		{"word":"zoos","word_nosc":"zoos","lemma":"zoo","pos":"NOM"} ,
		{"word":"zoreille","word_nosc":"zoreille","lemma":"zoreille","pos":"NOM"} ,
		{"word":"zoreilles","word_nosc":"zoreilles","lemma":"zoreille","pos":"NOM"} ,
		{"word":"zorille","word_nosc":"zorille","lemma":"zorille","pos":"NOM"} ,
		{"word":"zoroastrien","word_nosc":"zoroastrien","lemma":"zoroastrien","pos":"NOM"} ,
		{"word":"zoroastriens","word_nosc":"zoroastriens","lemma":"zoroastrien","pos":"NOM"} ,
		{"word":"zoroastrisme","word_nosc":"zoroastrisme","lemma":"zoroastrisme","pos":"NOM"} ,
		{"word":"zouave","word_nosc":"zouave","lemma":"zouave","pos":"NOM"} ,
		{"word":"zouaves","word_nosc":"zouaves","lemma":"zouave","pos":"NOM"} ,
		{"word":"zouk","word_nosc":"zouk","lemma":"zouk","pos":"NOM"} ,
		{"word":"zoulou","word_nosc":"zoulou","lemma":"zoulou","pos":"NOM"} ,
		{"word":"zoulous","word_nosc":"zoulous","lemma":"zoulou","pos":"NOM"} ,
		{"word":"zoziaux","word_nosc":"zoziaux","lemma":"zoziaux","pos":"NOM"} ,
		{"word":"zozo","word_nosc":"zozo","lemma":"zozo","pos":"NOM"} ,
		{"word":"zozos","word_nosc":"zozos","lemma":"zozo","pos":"NOM"} ,
		{"word":"zozotement","word_nosc":"zozotement","lemma":"zozotement","pos":"NOM"} ,
		{"word":"zozotements","word_nosc":"zozotements","lemma":"zozotement","pos":"NOM"} ,
		{"word":"zozoteuse","word_nosc":"zozoteuse","lemma":"zozoteur","pos":"NOM"} ,
		{"word":"zurichois","word_nosc":"zurichois","lemma":"zurichois","pos":"NOM"} ,
		{"word":"zydeco","word_nosc":"zydeco","lemma":"zydeco","pos":"NOM"} ,
		{"word":"zygoma","word_nosc":"zygoma","lemma":"zygoma","pos":"NOM"} ,
		{"word":"zygote","word_nosc":"zygote","lemma":"zygote","pos":"NOM"} ,
		{"word":"zyklon","word_nosc":"zyklon","lemma":"zyklon","pos":"NOM"} ,
		{"word":"zèbre","word_nosc":"zebre","lemma":"zèbre","pos":"NOM"} ,
		{"word":"zèbres","word_nosc":"zebres","lemma":"zèbre","pos":"NOM"} ,
		{"word":"zèle","word_nosc":"zele","lemma":"zèle","pos":"NOM"} ,
		{"word":"zèles","word_nosc":"zeles","lemma":"zèle","pos":"NOM"} ,
		{"word":"zébrure","word_nosc":"zebrure","lemma":"zébrure","pos":"NOM"} ,
		{"word":"zébrures","word_nosc":"zebrures","lemma":"zébrure","pos":"NOM"} ,
		{"word":"zébu","word_nosc":"zebu","lemma":"zébu","pos":"NOM"} ,
		{"word":"zébus","word_nosc":"zebus","lemma":"zébu","pos":"NOM"} ,
		{"word":"zélateur","word_nosc":"zelateur","lemma":"zélateur","pos":"NOM"} ,
		{"word":"zélateurs","word_nosc":"zelateurs","lemma":"zélateur","pos":"NOM"} ,
		{"word":"zélatrice","word_nosc":"zelatrice","lemma":"zélateur","pos":"NOM"} ,
		{"word":"zélote","word_nosc":"zelote","lemma":"zélote","pos":"NOM"} ,
		{"word":"zélotes","word_nosc":"zelotes","lemma":"zélote","pos":"NOM"} ,
		{"word":"zénana","word_nosc":"zenana","lemma":"zénana","pos":"NOM"} ,
		{"word":"zénith","word_nosc":"zenith","lemma":"zénith","pos":"NOM"} ,
		{"word":"zéphire","word_nosc":"zephire","lemma":"zéphire","pos":"NOM"} ,
		{"word":"zéphyr","word_nosc":"zephyr","lemma":"zéphyr","pos":"NOM"} ,
		{"word":"zéphyrs","word_nosc":"zephyrs","lemma":"zéphyr","pos":"NOM"} ,
		{"word":"zéro","word_nosc":"zero","lemma":"zéro","pos":"NOM"} ,
		{"word":"zéros","word_nosc":"zeros","lemma":"zéro","pos":"NOM"} ,
		{"word":"zézaiement","word_nosc":"zezaiement","lemma":"zézaiement","pos":"NOM"} ,
		{"word":"zézette","word_nosc":"zezette","lemma":"zézette","pos":"NOM"} ,
		{"word":"zézettes","word_nosc":"zezettes","lemma":"zézette","pos":"NOM"} ,
		{"word":"à-coup","word_nosc":"a-coup","lemma":"à-coup","pos":"NOM"} ,
		{"word":"à-coups","word_nosc":"a-coups","lemma":"à-coup","pos":"NOM"} ,
		{"word":"à-côté","word_nosc":"a-cote","lemma":"à-côté","pos":"NOM"} ,
		{"word":"à-côtés","word_nosc":"a-cotes","lemma":"à-côté","pos":"NOM"} ,
		{"word":"à-peu-près","word_nosc":"a-peu-pres","lemma":"à-peu-près","pos":"NOM"} ,
		{"word":"à-pic","word_nosc":"a-pic","lemma":"à-pic","pos":"NOM"} ,
		{"word":"à-pics","word_nosc":"a-pics","lemma":"à-pic","pos":"NOM"} ,
		{"word":"à-plat","word_nosc":"a-plat","lemma":"à-plat","pos":"NOM"} ,
		{"word":"à-plats","word_nosc":"a-plats","lemma":"à-plat","pos":"NOM"} ,
		{"word":"à-propos","word_nosc":"a-propos","lemma":"à-propos","pos":"NOM"} ,
		{"word":"à-valoir","word_nosc":"a-valoir","lemma":"à-valoir","pos":"NOM"} ,
		{"word":"âcreté","word_nosc":"acrete","lemma":"âcreté","pos":"NOM"} ,
		{"word":"âge","word_nosc":"age","lemma":"âge","pos":"NOM"} ,
		{"word":"âges","word_nosc":"ages","lemma":"âge","pos":"NOM"} ,
		{"word":"âgisme","word_nosc":"agisme","lemma":"âgisme","pos":"NOM"} ,
		{"word":"âme","word_nosc":"ame","lemma":"âme","pos":"NOM"} ,
		{"word":"âme-soeur","word_nosc":"ame-soeur","lemma":"âme-soeur","pos":"NOM"} ,
		{"word":"âmes","word_nosc":"ames","lemma":"âme","pos":"NOM"} ,
		{"word":"âne","word_nosc":"ane","lemma":"âne","pos":"NOM"} ,
		{"word":"ânerie","word_nosc":"anerie","lemma":"ânerie","pos":"NOM"} ,
		{"word":"âneries","word_nosc":"aneries","lemma":"ânerie","pos":"NOM"} ,
		{"word":"ânes","word_nosc":"anes","lemma":"âne","pos":"NOM"} ,
		{"word":"ânesse","word_nosc":"anesse","lemma":"ânesse","pos":"NOM"} ,
		{"word":"ânesses","word_nosc":"anesses","lemma":"ânesse","pos":"NOM"} ,
		{"word":"ânier","word_nosc":"anier","lemma":"ânier","pos":"NOM"} ,
		{"word":"ânon","word_nosc":"anon","lemma":"ânon","pos":"NOM"} ,
		{"word":"ânonnement","word_nosc":"anonnement","lemma":"ânonnement","pos":"NOM"} ,
		{"word":"ânonnements","word_nosc":"anonnements","lemma":"ânonnement","pos":"NOM"} ,
		{"word":"ânons","word_nosc":"anons","lemma":"ânon","pos":"NOM"} ,
		{"word":"âpreté","word_nosc":"aprete","lemma":"âpreté","pos":"NOM"} ,
		{"word":"âpretés","word_nosc":"apretes","lemma":"âpreté","pos":"NOM"} ,
		{"word":"âtre","word_nosc":"atre","lemma":"âtre","pos":"NOM"} ,
		{"word":"âtres","word_nosc":"atres","lemma":"âtre","pos":"NOM"} ,
		{"word":"ça","word_nosc":"ca","lemma":"ça","pos":"NOM"} ,
		{"word":"çruti","word_nosc":"cruti","lemma":"çruti","pos":"NOM"} ,
		{"word":"ère","word_nosc":"ere","lemma":"ère","pos":"NOM"} ,
		{"word":"ères","word_nosc":"eres","lemma":"ère","pos":"NOM"} ,
		{"word":"ève","word_nosc":"eve","lemma":"ève","pos":"NOM"} ,
		{"word":"ébahissement","word_nosc":"ebahissement","lemma":"ébahissement","pos":"NOM"} ,
		{"word":"ébarbage","word_nosc":"ebarbage","lemma":"ébarbage","pos":"NOM"} ,
		{"word":"ébats","word_nosc":"ebats","lemma":"ébat","pos":"NOM"} ,
		{"word":"ébattements","word_nosc":"ebattements","lemma":"ébattement","pos":"NOM"} ,
		{"word":"ébauche","word_nosc":"ebauche","lemma":"ébauche","pos":"NOM"} ,
		{"word":"ébauches","word_nosc":"ebauches","lemma":"ébauche","pos":"NOM"} ,
		{"word":"ébaucheurs","word_nosc":"ebaucheurs","lemma":"ébaucheur","pos":"NOM"} ,
		{"word":"ébauchons","word_nosc":"ebauchons","lemma":"ébauchon","pos":"NOM"} ,
		{"word":"éblouissement","word_nosc":"eblouissement","lemma":"éblouissement","pos":"NOM"} ,
		{"word":"éblouissements","word_nosc":"eblouissements","lemma":"éblouissement","pos":"NOM"} ,
		{"word":"ébonite","word_nosc":"ebonite","lemma":"ébonite","pos":"NOM"} ,
		{"word":"éboueur","word_nosc":"eboueur","lemma":"éboueur","pos":"NOM"} ,
		{"word":"éboueurs","word_nosc":"eboueurs","lemma":"éboueur","pos":"NOM"} ,
		{"word":"éboulement","word_nosc":"eboulement","lemma":"éboulement","pos":"NOM"} ,
		{"word":"éboulements","word_nosc":"eboulements","lemma":"éboulement","pos":"NOM"} ,
		{"word":"éboulis","word_nosc":"eboulis","lemma":"éboulis","pos":"NOM"} ,
		{"word":"ébranchement","word_nosc":"ebranchement","lemma":"ébranchement","pos":"NOM"} ,
		{"word":"ébranlement","word_nosc":"ebranlement","lemma":"ébranlement","pos":"NOM"} ,
		{"word":"ébranlements","word_nosc":"ebranlements","lemma":"ébranlement","pos":"NOM"} ,
		{"word":"ébriété","word_nosc":"ebriete","lemma":"ébriété","pos":"NOM"} ,
		{"word":"ébrouement","word_nosc":"ebrouement","lemma":"ébrouement","pos":"NOM"} ,
		{"word":"ébréchure","word_nosc":"ebrechure","lemma":"ébréchure","pos":"NOM"} ,
		{"word":"ébréchures","word_nosc":"ebrechures","lemma":"ébréchure","pos":"NOM"} ,
		{"word":"ébullition","word_nosc":"ebullition","lemma":"ébullition","pos":"NOM"} ,
		{"word":"ébène","word_nosc":"ebene","lemma":"ébène","pos":"NOM"} ,
		{"word":"ébéniste","word_nosc":"ebeniste","lemma":"ébéniste","pos":"NOM"} ,
		{"word":"ébénisterie","word_nosc":"ebenisterie","lemma":"ébénisterie","pos":"NOM"} ,
		{"word":"ébénisteries","word_nosc":"ebenisteries","lemma":"ébénisterie","pos":"NOM"} ,
		{"word":"ébénistes","word_nosc":"ebenistes","lemma":"ébéniste","pos":"NOM"} ,
		{"word":"écaillage","word_nosc":"ecaillage","lemma":"écaillage","pos":"NOM"} ,
		{"word":"écaille","word_nosc":"ecaille","lemma":"écaille","pos":"NOM"} ,
		{"word":"écailler","word_nosc":"ecailler","lemma":"écailler","pos":"NOM"} ,
		{"word":"écaillers","word_nosc":"ecaillers","lemma":"écailler","pos":"NOM"} ,
		{"word":"écailles","word_nosc":"ecailles","lemma":"écaille","pos":"NOM"} ,
		{"word":"écailleur","word_nosc":"ecailleur","lemma":"écailleur","pos":"NOM"} ,
		{"word":"écaillure","word_nosc":"ecaillure","lemma":"écaillure","pos":"NOM"} ,
		{"word":"écaillures","word_nosc":"ecaillures","lemma":"écaillure","pos":"NOM"} ,
		{"word":"écaillère","word_nosc":"ecaillere","lemma":"écailler","pos":"NOM"} ,
		{"word":"écale","word_nosc":"ecale","lemma":"écale","pos":"NOM"} ,
		{"word":"écales","word_nosc":"ecales","lemma":"écale","pos":"NOM"} ,
		{"word":"écarlate","word_nosc":"ecarlate","lemma":"écarlate","pos":"NOM"} ,
		{"word":"écarquillement","word_nosc":"ecarquillement","lemma":"écarquillement","pos":"NOM"} ,
		{"word":"écart","word_nosc":"ecart","lemma":"écart","pos":"NOM"} ,
		{"word":"écartelé","word_nosc":"ecartele","lemma":"écartelé","pos":"NOM"} ,
		{"word":"écartelés","word_nosc":"ecarteles","lemma":"écartelé","pos":"NOM"} ,
		{"word":"écartement","word_nosc":"ecartement","lemma":"écartement","pos":"NOM"} ,
		{"word":"écartements","word_nosc":"ecartements","lemma":"écartement","pos":"NOM"} ,
		{"word":"écarteur","word_nosc":"ecarteur","lemma":"écarteur","pos":"NOM"} ,
		{"word":"écarts","word_nosc":"ecarts","lemma":"écart","pos":"NOM"} ,
		{"word":"écartèlement","word_nosc":"ecartelement","lemma":"écartèlement","pos":"NOM"} ,
		{"word":"écartèlements","word_nosc":"ecartelements","lemma":"écartèlement","pos":"NOM"} ,
		{"word":"écarté","word_nosc":"ecarte","lemma":"écarté","pos":"NOM"} ,
		{"word":"écartés","word_nosc":"ecartes","lemma":"écarté","pos":"NOM"} ,
		{"word":"écervelé","word_nosc":"ecervele","lemma":"écervelé","pos":"NOM"} ,
		{"word":"écervelée","word_nosc":"ecervelee","lemma":"écervelé","pos":"NOM"} ,
		{"word":"écervelées","word_nosc":"ecervelees","lemma":"écervelé","pos":"NOM"} ,
		{"word":"écervelés","word_nosc":"ecerveles","lemma":"écervelé","pos":"NOM"} ,
		{"word":"échafaud","word_nosc":"echafaud","lemma":"échafaud","pos":"NOM"} ,
		{"word":"échafaudage","word_nosc":"echafaudage","lemma":"échafaudage","pos":"NOM"} ,
		{"word":"échafaudages","word_nosc":"echafaudages","lemma":"échafaudage","pos":"NOM"} ,
		{"word":"échafauds","word_nosc":"echafauds","lemma":"échafaud","pos":"NOM"} ,
		{"word":"échalas","word_nosc":"echalas","lemma":"échalas","pos":"NOM"} ,
		{"word":"échalier","word_nosc":"echalier","lemma":"échalier","pos":"NOM"} ,
		{"word":"échaliers","word_nosc":"echaliers","lemma":"échalier","pos":"NOM"} ,
		{"word":"échalote","word_nosc":"echalote","lemma":"échalote","pos":"NOM"} ,
		{"word":"échalotes","word_nosc":"echalotes","lemma":"échalote","pos":"NOM"} ,
		{"word":"échancrure","word_nosc":"echancrure","lemma":"échancrure","pos":"NOM"} ,
		{"word":"échancrures","word_nosc":"echancrures","lemma":"échancrure","pos":"NOM"} ,
		{"word":"échange","word_nosc":"echange","lemma":"échange","pos":"NOM"} ,
		{"word":"échanges","word_nosc":"echanges","lemma":"échange","pos":"NOM"} ,
		{"word":"échangeur","word_nosc":"echangeur","lemma":"échangeur","pos":"NOM"} ,
		{"word":"échangeurs","word_nosc":"echangeurs","lemma":"échangeur","pos":"NOM"} ,
		{"word":"échangisme","word_nosc":"echangisme","lemma":"échangisme","pos":"NOM"} ,
		{"word":"échangiste","word_nosc":"echangiste","lemma":"échangiste","pos":"NOM"} ,
		{"word":"échangistes","word_nosc":"echangistes","lemma":"échangiste","pos":"NOM"} ,
		{"word":"échanson","word_nosc":"echanson","lemma":"échanson","pos":"NOM"} ,
		{"word":"échansons","word_nosc":"echansons","lemma":"échanson","pos":"NOM"} ,
		{"word":"échantillon","word_nosc":"echantillon","lemma":"échantillon","pos":"NOM"} ,
		{"word":"échantillonnage","word_nosc":"echantillonnage","lemma":"échantillonnage","pos":"NOM"} ,
		{"word":"échantillons","word_nosc":"echantillons","lemma":"échantillon","pos":"NOM"} ,
		{"word":"échappatoire","word_nosc":"echappatoire","lemma":"échappatoire","pos":"NOM"} ,
		{"word":"échappatoires","word_nosc":"echappatoires","lemma":"échappatoire","pos":"NOM"} ,
		{"word":"échappement","word_nosc":"echappement","lemma":"échappement","pos":"NOM"} ,
		{"word":"échappements","word_nosc":"echappements","lemma":"échappement","pos":"NOM"} ,
		{"word":"échappé","word_nosc":"echappe","lemma":"échappé","pos":"NOM"} ,
		{"word":"échappée","word_nosc":"echappee","lemma":"échappée","pos":"NOM"} ,
		{"word":"échappées","word_nosc":"echappees","lemma":"échappée","pos":"NOM"} ,
		{"word":"échappés","word_nosc":"echappes","lemma":"échappé","pos":"NOM"} ,
		{"word":"écharde","word_nosc":"echarde","lemma":"écharde","pos":"NOM"} ,
		{"word":"échardes","word_nosc":"echardes","lemma":"écharde","pos":"NOM"} ,
		{"word":"écharpe","word_nosc":"echarpe","lemma":"écharpe","pos":"NOM"} ,
		{"word":"écharpes","word_nosc":"echarpes","lemma":"écharpe","pos":"NOM"} ,
		{"word":"échasse","word_nosc":"echasse","lemma":"échasse","pos":"NOM"} ,
		{"word":"échasses","word_nosc":"echasses","lemma":"échasse","pos":"NOM"} ,
		{"word":"échassier","word_nosc":"echassier","lemma":"échassier","pos":"NOM"} ,
		{"word":"échassiers","word_nosc":"echassiers","lemma":"échassier","pos":"NOM"} ,
		{"word":"échaudé","word_nosc":"echaude","lemma":"échaudé","pos":"NOM"} ,
		{"word":"échaudés","word_nosc":"echaudes","lemma":"échaudé","pos":"NOM"} ,
		{"word":"échauffement","word_nosc":"echauffement","lemma":"échauffement","pos":"NOM"} ,
		{"word":"échauffements","word_nosc":"echauffements","lemma":"échauffement","pos":"NOM"} ,
		{"word":"échauffourée","word_nosc":"echauffouree","lemma":"échauffourée","pos":"NOM"} ,
		{"word":"échauffourées","word_nosc":"echauffourees","lemma":"échauffourée","pos":"NOM"} ,
		{"word":"échauguette","word_nosc":"echauguette","lemma":"échauguette","pos":"NOM"} ,
		{"word":"échauguettes","word_nosc":"echauguettes","lemma":"échauguette","pos":"NOM"} ,
		{"word":"échec","word_nosc":"echec","lemma":"échec","pos":"NOM"} ,
		{"word":"échecs","word_nosc":"echecs","lemma":"échec","pos":"NOM"} ,
		{"word":"échelle","word_nosc":"echelle","lemma":"échelle","pos":"NOM"} ,
		{"word":"échelles","word_nosc":"echelles","lemma":"échelle","pos":"NOM"} ,
		{"word":"échelon","word_nosc":"echelon","lemma":"échelon","pos":"NOM"} ,
		{"word":"échelons","word_nosc":"echelons","lemma":"échelon","pos":"NOM"} ,
		{"word":"écheveau","word_nosc":"echeveau","lemma":"écheveau","pos":"NOM"} ,
		{"word":"écheveaux","word_nosc":"echeveaux","lemma":"écheveau","pos":"NOM"} ,
		{"word":"échevellement","word_nosc":"echevellement","lemma":"échevellement","pos":"NOM"} ,
		{"word":"échevin","word_nosc":"echevin","lemma":"échevin","pos":"NOM"} ,
		{"word":"échevins","word_nosc":"echevins","lemma":"échevin","pos":"NOM"} ,
		{"word":"échine","word_nosc":"echine","lemma":"échine","pos":"NOM"} ,
		{"word":"échines","word_nosc":"echines","lemma":"échine","pos":"NOM"} ,
		{"word":"échinodermes","word_nosc":"echinodermes","lemma":"échinoderme","pos":"NOM"} ,
		{"word":"échiquier","word_nosc":"echiquier","lemma":"échiquier","pos":"NOM"} ,
		{"word":"échiquiers","word_nosc":"echiquiers","lemma":"échiquier","pos":"NOM"} ,
		{"word":"écho","word_nosc":"echo","lemma":"écho","pos":"NOM"} ,
		{"word":"échocardiogramme","word_nosc":"echocardiogramme","lemma":"échocardiogramme","pos":"NOM"} ,
		{"word":"échocardiographie","word_nosc":"echocardiographie","lemma":"échocardiographie","pos":"NOM"} ,
		{"word":"échographie","word_nosc":"echographie","lemma":"échographie","pos":"NOM"} ,
		{"word":"échographiste","word_nosc":"echographiste","lemma":"échographiste","pos":"NOM"} ,
		{"word":"écholocalisation","word_nosc":"echolocalisation","lemma":"écholocalisation","pos":"NOM"} ,
		{"word":"écholocation","word_nosc":"echolocation","lemma":"écholocation","pos":"NOM"} ,
		{"word":"échoppe","word_nosc":"echoppe","lemma":"échoppe","pos":"NOM"} ,
		{"word":"échoppes","word_nosc":"echoppes","lemma":"échoppe","pos":"NOM"} ,
		{"word":"échos","word_nosc":"echos","lemma":"écho","pos":"NOM"} ,
		{"word":"échos-radars","word_nosc":"echos-radars","lemma":"échos-radar","pos":"NOM"} ,
		{"word":"échotier","word_nosc":"echotier","lemma":"échotier","pos":"NOM"} ,
		{"word":"échotiers","word_nosc":"echotiers","lemma":"échotier","pos":"NOM"} ,
		{"word":"échotière","word_nosc":"echotiere","lemma":"échotier","pos":"NOM"} ,
		{"word":"échouage","word_nosc":"echouage","lemma":"échouage","pos":"NOM"} ,
		{"word":"échouages","word_nosc":"echouages","lemma":"échouage","pos":"NOM"} ,
		{"word":"échouement","word_nosc":"echouement","lemma":"échouement","pos":"NOM"} ,
		{"word":"échéance","word_nosc":"echeance","lemma":"échéance","pos":"NOM"} ,
		{"word":"échéances","word_nosc":"echeances","lemma":"échéance","pos":"NOM"} ,
		{"word":"échéancier","word_nosc":"echeancier","lemma":"échéancier","pos":"NOM"} ,
		{"word":"éclaboussement","word_nosc":"eclaboussement","lemma":"éclaboussement","pos":"NOM"} ,
		{"word":"éclaboussements","word_nosc":"eclaboussements","lemma":"éclaboussement","pos":"NOM"} ,
		{"word":"éclaboussure","word_nosc":"eclaboussure","lemma":"éclaboussure","pos":"NOM"} ,
		{"word":"éclaboussures","word_nosc":"eclaboussures","lemma":"éclaboussure","pos":"NOM"} ,
		{"word":"éclair","word_nosc":"eclair","lemma":"éclair","pos":"NOM"} ,
		{"word":"éclairage","word_nosc":"eclairage","lemma":"éclairage","pos":"NOM"} ,
		{"word":"éclairages","word_nosc":"eclairages","lemma":"éclairage","pos":"NOM"} ,
		{"word":"éclairagiste","word_nosc":"eclairagiste","lemma":"éclairagiste","pos":"NOM"} ,
		{"word":"éclairagistes","word_nosc":"eclairagistes","lemma":"éclairagiste","pos":"NOM"} ,
		{"word":"éclaircie","word_nosc":"eclaircie","lemma":"éclaircie","pos":"NOM"} ,
		{"word":"éclaircies","word_nosc":"eclaircies","lemma":"éclaircie","pos":"NOM"} ,
		{"word":"éclaircissage","word_nosc":"eclaircissage","lemma":"éclaircissage","pos":"NOM"} ,
		{"word":"éclaircissement","word_nosc":"eclaircissement","lemma":"éclaircissement","pos":"NOM"} ,
		{"word":"éclaircissements","word_nosc":"eclaircissements","lemma":"éclaircissement","pos":"NOM"} ,
		{"word":"éclaire","word_nosc":"eclaire","lemma":"éclaire","pos":"NOM"} ,
		{"word":"éclairement","word_nosc":"eclairement","lemma":"éclairement","pos":"NOM"} ,
		{"word":"éclaires","word_nosc":"eclaires","lemma":"éclaire","pos":"NOM"} ,
		{"word":"éclaireur","word_nosc":"eclaireur","lemma":"éclaireur","pos":"NOM"} ,
		{"word":"éclaireurs","word_nosc":"eclaireurs","lemma":"éclaireur","pos":"NOM"} ,
		{"word":"éclaireuse","word_nosc":"eclaireuse","lemma":"éclaireur","pos":"NOM"} ,
		{"word":"éclaireuses","word_nosc":"eclaireuses","lemma":"éclaireur","pos":"NOM"} ,
		{"word":"éclairs","word_nosc":"eclairs","lemma":"éclair","pos":"NOM"} ,
		{"word":"éclampsie","word_nosc":"eclampsie","lemma":"éclampsie","pos":"NOM"} ,
		{"word":"éclat","word_nosc":"eclat","lemma":"éclat","pos":"NOM"} ,
		{"word":"éclatage","word_nosc":"eclatage","lemma":"éclatage","pos":"NOM"} ,
		{"word":"éclatement","word_nosc":"eclatement","lemma":"éclatement","pos":"NOM"} ,
		{"word":"éclatements","word_nosc":"eclatements","lemma":"éclatement","pos":"NOM"} ,
		{"word":"éclateurs","word_nosc":"eclateurs","lemma":"éclateur","pos":"NOM"} ,
		{"word":"éclats","word_nosc":"eclats","lemma":"éclat","pos":"NOM"} ,
		{"word":"éclaté","word_nosc":"eclate","lemma":"éclaté","pos":"NOM"} ,
		{"word":"éclatés","word_nosc":"eclates","lemma":"éclaté","pos":"NOM"} ,
		{"word":"éclectique","word_nosc":"eclectique","lemma":"éclectique","pos":"NOM"} ,
		{"word":"éclectisme","word_nosc":"eclectisme","lemma":"éclectisme","pos":"NOM"} ,
		{"word":"éclipse","word_nosc":"eclipse","lemma":"éclipse","pos":"NOM"} ,
		{"word":"éclipses","word_nosc":"eclipses","lemma":"éclipse","pos":"NOM"} ,
		{"word":"écliptique","word_nosc":"ecliptique","lemma":"écliptique","pos":"NOM"} ,
		{"word":"éclisse","word_nosc":"eclisse","lemma":"éclisse","pos":"NOM"} ,
		{"word":"éclisses","word_nosc":"eclisses","lemma":"éclisse","pos":"NOM"} ,
		{"word":"éclopé","word_nosc":"eclope","lemma":"éclopé","pos":"NOM"} ,
		{"word":"éclopée","word_nosc":"eclopee","lemma":"éclopé","pos":"NOM"} ,
		{"word":"éclopés","word_nosc":"eclopes","lemma":"éclopé","pos":"NOM"} ,
		{"word":"éclosion","word_nosc":"eclosion","lemma":"éclosion","pos":"NOM"} ,
		{"word":"éclosions","word_nosc":"eclosions","lemma":"éclosion","pos":"NOM"} ,
		{"word":"écluse","word_nosc":"ecluse","lemma":"écluse","pos":"NOM"} ,
		{"word":"écluses","word_nosc":"ecluses","lemma":"écluse","pos":"NOM"} ,
		{"word":"éclusier","word_nosc":"eclusier","lemma":"éclusier","pos":"NOM"} ,
		{"word":"éclusiers","word_nosc":"eclusiers","lemma":"éclusier","pos":"NOM"} ,
		{"word":"écoeurement","word_nosc":"ecoeurement","lemma":"écoeurement","pos":"NOM"} ,
		{"word":"écoeurements","word_nosc":"ecoeurements","lemma":"écoeurement","pos":"NOM"} ,
		{"word":"écoinçon","word_nosc":"ecoincon","lemma":"écoinçon","pos":"NOM"} ,
		{"word":"école","word_nosc":"ecole","lemma":"école","pos":"NOM"} ,
		{"word":"écoles","word_nosc":"ecoles","lemma":"école","pos":"NOM"} ,
		{"word":"écolier","word_nosc":"ecolier","lemma":"écolier","pos":"NOM"} ,
		{"word":"écoliers","word_nosc":"ecoliers","lemma":"écolier","pos":"NOM"} ,
		{"word":"écolière","word_nosc":"ecoliere","lemma":"écolier","pos":"NOM"} ,
		{"word":"écolières","word_nosc":"ecolieres","lemma":"écolier","pos":"NOM"} ,
		{"word":"écolo","word_nosc":"ecolo","lemma":"écolo","pos":"NOM"} ,
		{"word":"écologie","word_nosc":"ecologie","lemma":"écologie","pos":"NOM"} ,
		{"word":"écologiste","word_nosc":"ecologiste","lemma":"écologiste","pos":"NOM"} ,
		{"word":"écologistes","word_nosc":"ecologistes","lemma":"écologiste","pos":"NOM"} ,
		{"word":"écolos","word_nosc":"ecolos","lemma":"écolo","pos":"NOM"} ,
		{"word":"éconocroques","word_nosc":"econocroques","lemma":"éconocroques","pos":"NOM"} ,
		{"word":"économat","word_nosc":"economat","lemma":"économat","pos":"NOM"} ,
		{"word":"économe","word_nosc":"econome","lemma":"économe","pos":"NOM"} ,
		{"word":"économes","word_nosc":"economes","lemma":"économe","pos":"NOM"} ,
		{"word":"économie","word_nosc":"economie","lemma":"économie","pos":"NOM"} ,
		{"word":"économies","word_nosc":"economies","lemma":"économie","pos":"NOM"} ,
		{"word":"économique","word_nosc":"economique","lemma":"économique","pos":"NOM"} ,
		{"word":"économiques","word_nosc":"economiques","lemma":"économique","pos":"NOM"} ,
		{"word":"économiseur","word_nosc":"economiseur","lemma":"économiseur","pos":"NOM"} ,
		{"word":"économiseurs","word_nosc":"economiseurs","lemma":"économiseur","pos":"NOM"} ,
		{"word":"économisme","word_nosc":"economisme","lemma":"économisme","pos":"NOM"} ,
		{"word":"économiste","word_nosc":"economiste","lemma":"économiste","pos":"NOM"} ,
		{"word":"économistes","word_nosc":"economistes","lemma":"économiste","pos":"NOM"} ,
		{"word":"écope","word_nosc":"ecope","lemma":"écope","pos":"NOM"} ,
		{"word":"écoperches","word_nosc":"ecoperches","lemma":"écoperche","pos":"NOM"} ,
		{"word":"écopes","word_nosc":"ecopes","lemma":"écope","pos":"NOM"} ,
		{"word":"écorce","word_nosc":"ecorce","lemma":"écorce","pos":"NOM"} ,
		{"word":"écorces","word_nosc":"ecorces","lemma":"écorce","pos":"NOM"} ,
		{"word":"écorchage","word_nosc":"ecorchage","lemma":"écorchage","pos":"NOM"} ,
		{"word":"écorchement","word_nosc":"ecorchement","lemma":"écorchement","pos":"NOM"} ,
		{"word":"écorcherie","word_nosc":"ecorcherie","lemma":"écorcherie","pos":"NOM"} ,
		{"word":"écorcheur","word_nosc":"ecorcheur","lemma":"écorcheur","pos":"NOM"} ,
		{"word":"écorcheurs","word_nosc":"ecorcheurs","lemma":"écorcheur","pos":"NOM"} ,
		{"word":"écorchure","word_nosc":"ecorchure","lemma":"écorchure","pos":"NOM"} ,
		{"word":"écorchures","word_nosc":"ecorchures","lemma":"écorchure","pos":"NOM"} ,
		{"word":"écorché","word_nosc":"ecorche","lemma":"écorché","pos":"NOM"} ,
		{"word":"écorchée","word_nosc":"ecorchee","lemma":"écorché","pos":"NOM"} ,
		{"word":"écorchées","word_nosc":"ecorchees","lemma":"écorché","pos":"NOM"} ,
		{"word":"écorchés","word_nosc":"ecorches","lemma":"écorché","pos":"NOM"} ,
		{"word":"écosphère","word_nosc":"ecosphere","lemma":"écosphère","pos":"NOM"} ,
		{"word":"écossais","word_nosc":"ecossais","lemma":"écossais","pos":"NOM"} ,
		{"word":"écossaise","word_nosc":"ecossaise","lemma":"écossais","pos":"NOM"} ,
		{"word":"écossaises","word_nosc":"ecossaises","lemma":"écossais","pos":"NOM"} ,
		{"word":"écosse","word_nosc":"ecosse","lemma":"écosse","pos":"NOM"} ,
		{"word":"écosystème","word_nosc":"ecosysteme","lemma":"écosystème","pos":"NOM"} ,
		{"word":"écosystèmes","word_nosc":"ecosystemes","lemma":"écosystème","pos":"NOM"} ,
		{"word":"écot","word_nosc":"ecot","lemma":"écot","pos":"NOM"} ,
		{"word":"écotone","word_nosc":"ecotone","lemma":"écotone","pos":"NOM"} ,
		{"word":"écoulement","word_nosc":"ecoulement","lemma":"écoulement","pos":"NOM"} ,
		{"word":"écoulements","word_nosc":"ecoulements","lemma":"écoulement","pos":"NOM"} ,
		{"word":"écoutant","word_nosc":"ecoutant","lemma":"écoutant","pos":"NOM"} ,
		{"word":"écoute","word_nosc":"ecoute","lemma":"écoute","pos":"NOM"} ,
		{"word":"écoutes","word_nosc":"ecoutes","lemma":"écoute","pos":"NOM"} ,
		{"word":"écouteur","word_nosc":"ecouteur","lemma":"écouteur","pos":"NOM"} ,
		{"word":"écouteurs","word_nosc":"ecouteurs","lemma":"écouteur","pos":"NOM"} ,
		{"word":"écouteuse","word_nosc":"ecouteuse","lemma":"écouteur","pos":"NOM"} ,
		{"word":"écoutille","word_nosc":"ecoutille","lemma":"écoutille","pos":"NOM"} ,
		{"word":"écoutilles","word_nosc":"ecoutilles","lemma":"écoutille","pos":"NOM"} ,
		{"word":"écouvillon","word_nosc":"ecouvillon","lemma":"écouvillon","pos":"NOM"} ,
		{"word":"écouvillons","word_nosc":"ecouvillons","lemma":"écouvillon","pos":"NOM"} ,
		{"word":"écrabouillage","word_nosc":"ecrabouillage","lemma":"écrabouillage","pos":"NOM"} ,
		{"word":"écrabouillement","word_nosc":"ecrabouillement","lemma":"écrabouillement","pos":"NOM"} ,
		{"word":"écrabouillements","word_nosc":"ecrabouillements","lemma":"écrabouillement","pos":"NOM"} ,
		{"word":"écran","word_nosc":"ecran","lemma":"écran","pos":"NOM"} ,
		{"word":"écrans","word_nosc":"ecrans","lemma":"écran","pos":"NOM"} ,
		{"word":"écrasage","word_nosc":"ecrasage","lemma":"écrasage","pos":"NOM"} ,
		{"word":"écrase-merde","word_nosc":"ecrase-merde","lemma":"écrase-merde","pos":"NOM"} ,
		{"word":"écrase-merdes","word_nosc":"ecrase-merdes","lemma":"écrase-merde","pos":"NOM"} ,
		{"word":"écrasement","word_nosc":"ecrasement","lemma":"écrasement","pos":"NOM"} ,
		{"word":"écrasements","word_nosc":"ecrasements","lemma":"écrasement","pos":"NOM"} ,
		{"word":"écraseur","word_nosc":"ecraseur","lemma":"écraseur","pos":"NOM"} ,
		{"word":"écrasure","word_nosc":"ecrasure","lemma":"écrasure","pos":"NOM"} ,
		{"word":"écrevisse","word_nosc":"ecrevisse","lemma":"écrevisse","pos":"NOM"} ,
		{"word":"écrevisses","word_nosc":"ecrevisses","lemma":"écrevisse","pos":"NOM"} ,
		{"word":"écrin","word_nosc":"ecrin","lemma":"écrin","pos":"NOM"} ,
		{"word":"écrins","word_nosc":"ecrins","lemma":"écrin","pos":"NOM"} ,
		{"word":"écrit","word_nosc":"ecrit","lemma":"écrit","pos":"NOM"} ,
		{"word":"écriteau","word_nosc":"ecriteau","lemma":"écriteau","pos":"NOM"} ,
		{"word":"écriteaux","word_nosc":"ecriteaux","lemma":"écriteau","pos":"NOM"} ,
		{"word":"écritoire","word_nosc":"ecritoire","lemma":"écritoire","pos":"NOM"} ,
		{"word":"écritoires","word_nosc":"ecritoires","lemma":"écritoire","pos":"NOM"} ,
		{"word":"écrits","word_nosc":"ecrits","lemma":"écrit","pos":"NOM"} ,
		{"word":"écriture","word_nosc":"ecriture","lemma":"écriture","pos":"NOM"} ,
		{"word":"écritures","word_nosc":"ecritures","lemma":"écriture","pos":"NOM"} ,
		{"word":"écrivailleur","word_nosc":"ecrivailleur","lemma":"écrivailleur","pos":"NOM"} ,
		{"word":"écrivaillon","word_nosc":"ecrivaillon","lemma":"écrivaillon","pos":"NOM"} ,
		{"word":"écrivaillons","word_nosc":"ecrivaillons","lemma":"écrivaillon","pos":"NOM"} ,
		{"word":"écrivain","word_nosc":"ecrivain","lemma":"écrivain","pos":"NOM"} ,
		{"word":"écrivaine","word_nosc":"ecrivaine","lemma":"écrivain","pos":"NOM"} ,
		{"word":"écrivaines","word_nosc":"ecrivaines","lemma":"écrivain","pos":"NOM"} ,
		{"word":"écrivains","word_nosc":"ecrivains","lemma":"écrivain","pos":"NOM"} ,
		{"word":"écrivassiers","word_nosc":"ecrivassiers","lemma":"écrivassier","pos":"NOM"} ,
		{"word":"écriveur","word_nosc":"ecriveur","lemma":"écriveur","pos":"NOM"} ,
		{"word":"écrou","word_nosc":"ecrou","lemma":"écrou","pos":"NOM"} ,
		{"word":"écrouelles","word_nosc":"ecrouelles","lemma":"écrouelles","pos":"NOM"} ,
		{"word":"écroulement","word_nosc":"ecroulement","lemma":"écroulement","pos":"NOM"} ,
		{"word":"écroulements","word_nosc":"ecroulements","lemma":"écroulement","pos":"NOM"} ,
		{"word":"écrous","word_nosc":"ecrous","lemma":"écrou","pos":"NOM"} ,
		{"word":"écrémage","word_nosc":"ecremage","lemma":"écrémage","pos":"NOM"} ,
		{"word":"écrémeuse","word_nosc":"ecremeuse","lemma":"écrémeur","pos":"NOM"} ,
		{"word":"écu","word_nosc":"ecu","lemma":"écu","pos":"NOM"} ,
		{"word":"écubier","word_nosc":"ecubier","lemma":"écubier","pos":"NOM"} ,
		{"word":"écueil","word_nosc":"ecueil","lemma":"écueil","pos":"NOM"} ,
		{"word":"écueils","word_nosc":"ecueils","lemma":"écueil","pos":"NOM"} ,
		{"word":"écuelle","word_nosc":"ecuelle","lemma":"écuelle","pos":"NOM"} ,
		{"word":"écuelles","word_nosc":"ecuelles","lemma":"écuelle","pos":"NOM"} ,
		{"word":"écuellée","word_nosc":"ecuellee","lemma":"écuellée","pos":"NOM"} ,
		{"word":"écuellées","word_nosc":"ecuellees","lemma":"écuellée","pos":"NOM"} ,
		{"word":"écume","word_nosc":"ecume","lemma":"écume","pos":"NOM"} ,
		{"word":"écumes","word_nosc":"ecumes","lemma":"écume","pos":"NOM"} ,
		{"word":"écumeur","word_nosc":"ecumeur","lemma":"écumeur","pos":"NOM"} ,
		{"word":"écumeurs","word_nosc":"ecumeurs","lemma":"écumeur","pos":"NOM"} ,
		{"word":"écumeuses","word_nosc":"ecumeuses","lemma":"écumeur","pos":"NOM"} ,
		{"word":"écumoire","word_nosc":"ecumoire","lemma":"écumoire","pos":"NOM"} ,
		{"word":"écumoires","word_nosc":"ecumoires","lemma":"écumoire","pos":"NOM"} ,
		{"word":"écureuil","word_nosc":"ecureuil","lemma":"écureuil","pos":"NOM"} ,
		{"word":"écureuils","word_nosc":"ecureuils","lemma":"écureuil","pos":"NOM"} ,
		{"word":"écurie","word_nosc":"ecurie","lemma":"écurie","pos":"NOM"} ,
		{"word":"écuries","word_nosc":"ecuries","lemma":"écurie","pos":"NOM"} ,
		{"word":"écus","word_nosc":"ecus","lemma":"écu","pos":"NOM"} ,
		{"word":"écusson","word_nosc":"ecusson","lemma":"écusson","pos":"NOM"} ,
		{"word":"écussons","word_nosc":"ecussons","lemma":"écusson","pos":"NOM"} ,
		{"word":"écuyer","word_nosc":"ecuyer","lemma":"écuyer","pos":"NOM"} ,
		{"word":"écuyers","word_nosc":"ecuyers","lemma":"écuyer","pos":"NOM"} ,
		{"word":"écuyère","word_nosc":"ecuyere","lemma":"écuyer","pos":"NOM"} ,
		{"word":"écuyères","word_nosc":"ecuyeres","lemma":"écuyer","pos":"NOM"} ,
		{"word":"édam","word_nosc":"edam","lemma":"édam","pos":"NOM"} ,
		{"word":"éden","word_nosc":"eden","lemma":"éden","pos":"NOM"} ,
		{"word":"édens","word_nosc":"edens","lemma":"éden","pos":"NOM"} ,
		{"word":"édenté","word_nosc":"edente","lemma":"édenté","pos":"NOM"} ,
		{"word":"édentée","word_nosc":"edentee","lemma":"édenté","pos":"NOM"} ,
		{"word":"édentées","word_nosc":"edentees","lemma":"édenté","pos":"NOM"} ,
		{"word":"édentés","word_nosc":"edentes","lemma":"édenté","pos":"NOM"} ,
		{"word":"édicule","word_nosc":"edicule","lemma":"édicule","pos":"NOM"} ,
		{"word":"édicules","word_nosc":"edicules","lemma":"édicule","pos":"NOM"} ,
		{"word":"édification","word_nosc":"edification","lemma":"édification","pos":"NOM"} ,
		{"word":"édifice","word_nosc":"edifice","lemma":"édifice","pos":"NOM"} ,
		{"word":"édifices","word_nosc":"edifices","lemma":"édifice","pos":"NOM"} ,
		{"word":"édiles","word_nosc":"ediles","lemma":"édile","pos":"NOM"} ,
		{"word":"édit","word_nosc":"edit","lemma":"édit","pos":"NOM"} ,
		{"word":"éditeur","word_nosc":"editeur","lemma":"éditeur","pos":"NOM"} ,
		{"word":"éditeurs","word_nosc":"editeurs","lemma":"éditeur","pos":"NOM"} ,
		{"word":"édition","word_nosc":"edition","lemma":"édition","pos":"NOM"} ,
		{"word":"éditions","word_nosc":"editions","lemma":"édition","pos":"NOM"} ,
		{"word":"édito","word_nosc":"edito","lemma":"édito","pos":"NOM"} ,
		{"word":"éditorial","word_nosc":"editorial","lemma":"éditorial","pos":"NOM"} ,
		{"word":"éditorialiste","word_nosc":"editorialiste","lemma":"éditorialiste","pos":"NOM"} ,
		{"word":"éditorialistes","word_nosc":"editorialistes","lemma":"éditorialiste","pos":"NOM"} ,
		{"word":"éditoriaux","word_nosc":"editoriaux","lemma":"éditorial","pos":"NOM"} ,
		{"word":"éditos","word_nosc":"editos","lemma":"édito","pos":"NOM"} ,
		{"word":"éditrice","word_nosc":"editrice","lemma":"éditeur","pos":"NOM"} ,
		{"word":"éditrices","word_nosc":"editrices","lemma":"éditeur","pos":"NOM"} ,
		{"word":"édits","word_nosc":"edits","lemma":"édit","pos":"NOM"} ,
		{"word":"édredon","word_nosc":"edredon","lemma":"édredon","pos":"NOM"} ,
		{"word":"édredons","word_nosc":"edredons","lemma":"édredon","pos":"NOM"} ,
		{"word":"éducateur","word_nosc":"educateur","lemma":"éducateur","pos":"NOM"} ,
		{"word":"éducateurs","word_nosc":"educateurs","lemma":"éducateur","pos":"NOM"} ,
		{"word":"éducation","word_nosc":"education","lemma":"éducation","pos":"NOM"} ,
		{"word":"éducations","word_nosc":"educations","lemma":"éducation","pos":"NOM"} ,
		{"word":"éducatrice","word_nosc":"educatrice","lemma":"éducateur","pos":"NOM"} ,
		{"word":"éducatrices","word_nosc":"educatrices","lemma":"éducateur","pos":"NOM"} ,
		{"word":"édulcorant","word_nosc":"edulcorant","lemma":"édulcorant","pos":"NOM"} ,
		{"word":"égal","word_nosc":"egal","lemma":"égal","pos":"NOM"} ,
		{"word":"égalisateur","word_nosc":"egalisateur","lemma":"égalisateur","pos":"NOM"} ,
		{"word":"égalisation","word_nosc":"egalisation","lemma":"égalisation","pos":"NOM"} ,
		{"word":"égaliseur","word_nosc":"egaliseur","lemma":"égaliseur","pos":"NOM"} ,
		{"word":"égalitarisme","word_nosc":"egalitarisme","lemma":"égalitarisme","pos":"NOM"} ,
		{"word":"égalité","word_nosc":"egalite","lemma":"égalité","pos":"NOM"} ,
		{"word":"égalités","word_nosc":"egalites","lemma":"égalité","pos":"NOM"} ,
		{"word":"égard","word_nosc":"egard","lemma":"égard","pos":"NOM"} ,
		{"word":"égards","word_nosc":"egards","lemma":"égard","pos":"NOM"} ,
		{"word":"égarement","word_nosc":"egarement","lemma":"égarement","pos":"NOM"} ,
		{"word":"égarements","word_nosc":"egarements","lemma":"égarement","pos":"NOM"} ,
		{"word":"égaux","word_nosc":"egaux","lemma":"égal","pos":"NOM"} ,
		{"word":"égide","word_nosc":"egide","lemma":"égide","pos":"NOM"} ,
		{"word":"égipan","word_nosc":"egipan","lemma":"égipan","pos":"NOM"} ,
		{"word":"égipans","word_nosc":"egipans","lemma":"égipan","pos":"NOM"} ,
		{"word":"églantier","word_nosc":"eglantier","lemma":"églantier","pos":"NOM"} ,
		{"word":"églantiers","word_nosc":"eglantiers","lemma":"églantier","pos":"NOM"} ,
		{"word":"églantine","word_nosc":"eglantine","lemma":"églantine","pos":"NOM"} ,
		{"word":"églantines","word_nosc":"eglantines","lemma":"églantine","pos":"NOM"} ,
		{"word":"église","word_nosc":"eglise","lemma":"église","pos":"NOM"} ,
		{"word":"églises","word_nosc":"eglises","lemma":"église","pos":"NOM"} ,
		{"word":"églogue","word_nosc":"eglogue","lemma":"églogue","pos":"NOM"} ,
		{"word":"églogues","word_nosc":"eglogues","lemma":"églogue","pos":"NOM"} ,
		{"word":"égocentrique","word_nosc":"egocentrique","lemma":"égocentrique","pos":"NOM"} ,
		{"word":"égocentriques","word_nosc":"egocentriques","lemma":"égocentrique","pos":"NOM"} ,
		{"word":"égocentrisme","word_nosc":"egocentrisme","lemma":"égocentrisme","pos":"NOM"} ,
		{"word":"égocentrismes","word_nosc":"egocentrismes","lemma":"égocentrisme","pos":"NOM"} ,
		{"word":"égocentriste","word_nosc":"egocentriste","lemma":"égocentriste","pos":"NOM"} ,
		{"word":"égocentristes","word_nosc":"egocentristes","lemma":"égocentriste","pos":"NOM"} ,
		{"word":"égorgement","word_nosc":"egorgement","lemma":"égorgement","pos":"NOM"} ,
		{"word":"égorgements","word_nosc":"egorgements","lemma":"égorgement","pos":"NOM"} ,
		{"word":"égorgeoir","word_nosc":"egorgeoir","lemma":"égorgeoir","pos":"NOM"} ,
		{"word":"égorgeur","word_nosc":"egorgeur","lemma":"égorgeur","pos":"NOM"} ,
		{"word":"égorgeurs","word_nosc":"egorgeurs","lemma":"égorgeur","pos":"NOM"} ,
		{"word":"égorgeuses","word_nosc":"egorgeuses","lemma":"égorgeur","pos":"NOM"} ,
		{"word":"égotisme","word_nosc":"egotisme","lemma":"égotisme","pos":"NOM"} ,
		{"word":"égotiste","word_nosc":"egotiste","lemma":"égotiste","pos":"NOM"} ,
		{"word":"égotistes","word_nosc":"egotistes","lemma":"égotiste","pos":"NOM"} ,
		{"word":"égout","word_nosc":"egout","lemma":"égout","pos":"NOM"} ,
		{"word":"égoutier","word_nosc":"egoutier","lemma":"égoutier","pos":"NOM"} ,
		{"word":"égoutiers","word_nosc":"egoutiers","lemma":"égoutier","pos":"NOM"} ,
		{"word":"égouts","word_nosc":"egouts","lemma":"égout","pos":"NOM"} ,
		{"word":"égouttement","word_nosc":"egouttement","lemma":"égouttement","pos":"NOM"} ,
		{"word":"égouttis","word_nosc":"egouttis","lemma":"égouttis","pos":"NOM"} ,
		{"word":"égouttoir","word_nosc":"egouttoir","lemma":"égouttoir","pos":"NOM"} ,
		{"word":"égoïne","word_nosc":"egoine","lemma":"égoïne","pos":"NOM"} ,
		{"word":"égoïsme","word_nosc":"egoisme","lemma":"égoïsme","pos":"NOM"} ,
		{"word":"égoïsmes","word_nosc":"egoismes","lemma":"égoïsme","pos":"NOM"} ,
		{"word":"égoïste","word_nosc":"egoiste","lemma":"égoïste","pos":"NOM"} ,
		{"word":"égoïstes","word_nosc":"egoistes","lemma":"égoïste","pos":"NOM"} ,
		{"word":"égratignure","word_nosc":"egratignure","lemma":"égratignure","pos":"NOM"} ,
		{"word":"égratignures","word_nosc":"egratignures","lemma":"égratignure","pos":"NOM"} ,
		{"word":"égrenage","word_nosc":"egrenage","lemma":"égrenage","pos":"NOM"} ,
		{"word":"égreneuse","word_nosc":"egreneuse","lemma":"égreneur","pos":"NOM"} ,
		{"word":"égrènements","word_nosc":"egrenements","lemma":"égrènement","pos":"NOM"} ,
		{"word":"égyptien","word_nosc":"egyptien","lemma":"égyptien","pos":"NOM"} ,
		{"word":"égyptienne","word_nosc":"egyptienne","lemma":"égyptien","pos":"NOM"} ,
		{"word":"égyptiennes","word_nosc":"egyptiennes","lemma":"égyptien","pos":"NOM"} ,
		{"word":"égyptiens","word_nosc":"egyptiens","lemma":"égyptien","pos":"NOM"} ,
		{"word":"égyptologie","word_nosc":"egyptologie","lemma":"égyptologie","pos":"NOM"} ,
		{"word":"égyptologue","word_nosc":"egyptologue","lemma":"égyptologue","pos":"NOM"} ,
		{"word":"égyptologues","word_nosc":"egyptologues","lemma":"égyptologue","pos":"NOM"} ,
		{"word":"égérie","word_nosc":"egerie","lemma":"égérie","pos":"NOM"} ,
		{"word":"éjaculat","word_nosc":"ejaculat","lemma":"éjaculat","pos":"NOM"} ,
		{"word":"éjaculateur","word_nosc":"ejaculateur","lemma":"éjaculateur","pos":"NOM"} ,
		{"word":"éjaculateurs","word_nosc":"ejaculateurs","lemma":"éjaculateur","pos":"NOM"} ,
		{"word":"éjaculation","word_nosc":"ejaculation","lemma":"éjaculation","pos":"NOM"} ,
		{"word":"éjaculations","word_nosc":"ejaculations","lemma":"éjaculation","pos":"NOM"} ,
		{"word":"éjecteur","word_nosc":"ejecteur","lemma":"éjecteur","pos":"NOM"} ,
		{"word":"éjecteurs","word_nosc":"ejecteurs","lemma":"éjecteur","pos":"NOM"} ,
		{"word":"éjection","word_nosc":"ejection","lemma":"éjection","pos":"NOM"} ,
		{"word":"éjections","word_nosc":"ejections","lemma":"éjection","pos":"NOM"} ,
		{"word":"élaboration","word_nosc":"elaboration","lemma":"élaboration","pos":"NOM"} ,
		{"word":"élaborations","word_nosc":"elaborations","lemma":"élaboration","pos":"NOM"} ,
		{"word":"élagage","word_nosc":"elagage","lemma":"élagage","pos":"NOM"} ,
		{"word":"élagueur","word_nosc":"elagueur","lemma":"élagueur","pos":"NOM"} ,
		{"word":"élagueurs","word_nosc":"elagueurs","lemma":"élagueur","pos":"NOM"} ,
		{"word":"élan","word_nosc":"elan","lemma":"élan","pos":"NOM"} ,
		{"word":"élancement","word_nosc":"elancement","lemma":"élancement","pos":"NOM"} ,
		{"word":"élancements","word_nosc":"elancements","lemma":"élancement","pos":"NOM"} ,
		{"word":"élans","word_nosc":"elans","lemma":"élan","pos":"NOM"} ,
		{"word":"élargissement","word_nosc":"elargissement","lemma":"élargissement","pos":"NOM"} ,
		{"word":"élasticimétrie","word_nosc":"elasticimetrie","lemma":"élasticimétrie","pos":"NOM"} ,
		{"word":"élasticité","word_nosc":"elasticite","lemma":"élasticité","pos":"NOM"} ,
		{"word":"élastique","word_nosc":"elastique","lemma":"élastique","pos":"NOM"} ,
		{"word":"élastiques","word_nosc":"elastiques","lemma":"élastique","pos":"NOM"} ,
		{"word":"élastomère","word_nosc":"elastomere","lemma":"élastomère","pos":"NOM"} ,
		{"word":"électeur","word_nosc":"electeur","lemma":"électeur","pos":"NOM"} ,
		{"word":"électeurs","word_nosc":"electeurs","lemma":"électeur","pos":"NOM"} ,
		{"word":"élection","word_nosc":"election","lemma":"élection","pos":"NOM"} ,
		{"word":"élections","word_nosc":"elections","lemma":"élection","pos":"NOM"} ,
		{"word":"électorat","word_nosc":"electorat","lemma":"électorat","pos":"NOM"} ,
		{"word":"électrice","word_nosc":"electrice","lemma":"électeur","pos":"NOM"} ,
		{"word":"électrices","word_nosc":"electrices","lemma":"électeur","pos":"NOM"} ,
		{"word":"électricien","word_nosc":"electricien","lemma":"électricien","pos":"NOM"} ,
		{"word":"électricienne","word_nosc":"electricienne","lemma":"électricien","pos":"NOM"} ,
		{"word":"électriciennes","word_nosc":"electriciennes","lemma":"électricien","pos":"NOM"} ,
		{"word":"électriciens","word_nosc":"electriciens","lemma":"électricien","pos":"NOM"} ,
		{"word":"électricité","word_nosc":"electricite","lemma":"électricité","pos":"NOM"} ,
		{"word":"électrification","word_nosc":"electrification","lemma":"électrification","pos":"NOM"} ,
		{"word":"électrisation","word_nosc":"electrisation","lemma":"électrisation","pos":"NOM"} ,
		{"word":"électro-acoustique","word_nosc":"electro-acoustique","lemma":"électro-acoustique","pos":"NOM"} ,
		{"word":"électro-aimant","word_nosc":"electro-aimant","lemma":"électro-aimant","pos":"NOM"} ,
		{"word":"électro-encéphalogramme","word_nosc":"electro-encephalogramme","lemma":"électro-encéphalogramme","pos":"NOM"} ,
		{"word":"électroacoustique","word_nosc":"electroacoustique","lemma":"électroacoustique","pos":"NOM"} ,
		{"word":"électroaimant","word_nosc":"electroaimant","lemma":"électroaimant","pos":"NOM"} ,
		{"word":"électroaimants","word_nosc":"electroaimants","lemma":"électroaimant","pos":"NOM"} ,
		{"word":"électrobiologie","word_nosc":"electrobiologie","lemma":"électrobiologie","pos":"NOM"} ,
		{"word":"électrocardiogramme","word_nosc":"electrocardiogramme","lemma":"électrocardiogramme","pos":"NOM"} ,
		{"word":"électrochimie","word_nosc":"electrochimie","lemma":"électrochimie","pos":"NOM"} ,
		{"word":"électrochoc","word_nosc":"electrochoc","lemma":"électrochoc","pos":"NOM"} ,
		{"word":"électrochocs","word_nosc":"electrochocs","lemma":"électrochoc","pos":"NOM"} ,
		{"word":"électrocution","word_nosc":"electrocution","lemma":"électrocution","pos":"NOM"} ,
		{"word":"électrode","word_nosc":"electrode","lemma":"électrode","pos":"NOM"} ,
		{"word":"électrodes","word_nosc":"electrodes","lemma":"électrode","pos":"NOM"} ,
		{"word":"électrodynamique","word_nosc":"electrodynamique","lemma":"électrodynamique","pos":"NOM"} ,
		{"word":"électroencéphalogramme","word_nosc":"electroencephalogramme","lemma":"électroencéphalogramme","pos":"NOM"} ,
		{"word":"électroencéphalographie","word_nosc":"electroencephalographie","lemma":"électroencéphalographie","pos":"NOM"} ,
		{"word":"électrologie","word_nosc":"electrologie","lemma":"électrologie","pos":"NOM"} ,
		{"word":"électrolyse","word_nosc":"electrolyse","lemma":"électrolyse","pos":"NOM"} ,
		{"word":"électrolyte","word_nosc":"electrolyte","lemma":"électrolyte","pos":"NOM"} ,
		{"word":"électrolytes","word_nosc":"electrolytes","lemma":"électrolyte","pos":"NOM"} ,
		{"word":"électromagnétisme","word_nosc":"electromagnetisme","lemma":"électromagnétisme","pos":"NOM"} ,
		{"word":"électromètre","word_nosc":"electrometre","lemma":"électromètre","pos":"NOM"} ,
		{"word":"électroménager","word_nosc":"electromenager","lemma":"électroménager","pos":"NOM"} ,
		{"word":"électron","word_nosc":"electron","lemma":"électron","pos":"NOM"} ,
		{"word":"électronicien","word_nosc":"electronicien","lemma":"électronicien","pos":"NOM"} ,
		{"word":"électroniciens","word_nosc":"electroniciens","lemma":"électronicien","pos":"NOM"} ,
		{"word":"électronique","word_nosc":"electronique","lemma":"électronique","pos":"NOM"} ,
		{"word":"électroniques","word_nosc":"electroniques","lemma":"électronique","pos":"NOM"} ,
		{"word":"électrons","word_nosc":"electrons","lemma":"électron","pos":"NOM"} ,
		{"word":"électronvolts","word_nosc":"electronvolts","lemma":"électronvolt","pos":"NOM"} ,
		{"word":"électrophone","word_nosc":"electrophone","lemma":"électrophone","pos":"NOM"} ,
		{"word":"électrophones","word_nosc":"electrophones","lemma":"électrophone","pos":"NOM"} ,
		{"word":"électrophorèse","word_nosc":"electrophorese","lemma":"électrophorèse","pos":"NOM"} ,
		{"word":"électrostatique","word_nosc":"electrostatique","lemma":"électrostatique","pos":"NOM"} ,
		{"word":"électrum","word_nosc":"electrum","lemma":"électrum","pos":"NOM"} ,
		{"word":"électuaires","word_nosc":"electuaires","lemma":"électuaire","pos":"NOM"} ,
		{"word":"élevage","word_nosc":"elevage","lemma":"élevage","pos":"NOM"} ,
		{"word":"élevages","word_nosc":"elevages","lemma":"élevage","pos":"NOM"} ,
		{"word":"éleveur","word_nosc":"eleveur","lemma":"éleveur","pos":"NOM"} ,
		{"word":"éleveurs","word_nosc":"eleveurs","lemma":"éleveur","pos":"NOM"} ,
		{"word":"éleveuse","word_nosc":"eleveuse","lemma":"éleveur","pos":"NOM"} ,
		{"word":"élevons","word_nosc":"elevons","lemma":"élevon","pos":"NOM"} ,
		{"word":"élevures","word_nosc":"elevures","lemma":"élevure","pos":"NOM"} ,
		{"word":"éligibilité","word_nosc":"eligibilite","lemma":"éligibilité","pos":"NOM"} ,
		{"word":"élimination","word_nosc":"elimination","lemma":"élimination","pos":"NOM"} ,
		{"word":"éliminations","word_nosc":"eliminations","lemma":"élimination","pos":"NOM"} ,
		{"word":"éliminatoire","word_nosc":"eliminatoire","lemma":"éliminatoire","pos":"NOM"} ,
		{"word":"éliminatoires","word_nosc":"eliminatoires","lemma":"éliminatoire","pos":"NOM"} ,
		{"word":"élingue","word_nosc":"elingue","lemma":"élingue","pos":"NOM"} ,
		{"word":"élite","word_nosc":"elite","lemma":"élite","pos":"NOM"} ,
		{"word":"élites","word_nosc":"elites","lemma":"élite","pos":"NOM"} ,
		{"word":"élitisme","word_nosc":"elitisme","lemma":"élitisme","pos":"NOM"} ,
		{"word":"élitiste","word_nosc":"elitiste","lemma":"élitiste","pos":"NOM"} ,
		{"word":"élitistes","word_nosc":"elitistes","lemma":"élitiste","pos":"NOM"} ,
		{"word":"élixir","word_nosc":"elixir","lemma":"élixir","pos":"NOM"} ,
		{"word":"élixirs","word_nosc":"elixirs","lemma":"élixir","pos":"NOM"} ,
		{"word":"élocution","word_nosc":"elocution","lemma":"élocution","pos":"NOM"} ,
		{"word":"éloge","word_nosc":"eloge","lemma":"éloge","pos":"NOM"} ,
		{"word":"éloges","word_nosc":"eloges","lemma":"éloge","pos":"NOM"} ,
		{"word":"éloignement","word_nosc":"eloignement","lemma":"éloignement","pos":"NOM"} ,
		{"word":"élongation","word_nosc":"elongation","lemma":"élongation","pos":"NOM"} ,
		{"word":"élongations","word_nosc":"elongations","lemma":"élongation","pos":"NOM"} ,
		{"word":"éloquence","word_nosc":"eloquence","lemma":"éloquence","pos":"NOM"} ,
		{"word":"élu","word_nosc":"elu","lemma":"élu","pos":"NOM"} ,
		{"word":"élucidation","word_nosc":"elucidation","lemma":"élucidation","pos":"NOM"} ,
		{"word":"élucubration","word_nosc":"elucubration","lemma":"élucubration","pos":"NOM"} ,
		{"word":"élucubrations","word_nosc":"elucubrations","lemma":"élucubration","pos":"NOM"} ,
		{"word":"élus","word_nosc":"elus","lemma":"élu","pos":"NOM"} ,
		{"word":"élysée","word_nosc":"elysee","lemma":"élysée","pos":"NOM"} ,
		{"word":"élytre","word_nosc":"elytre","lemma":"élytre","pos":"NOM"} ,
		{"word":"élytres","word_nosc":"elytres","lemma":"élytre","pos":"NOM"} ,
		{"word":"élève","word_nosc":"eleve","lemma":"élève","pos":"NOM"} ,
		{"word":"élèves","word_nosc":"eleves","lemma":"élève","pos":"NOM"} ,
		{"word":"éléates","word_nosc":"eleates","lemma":"éléate","pos":"NOM"} ,
		{"word":"élégance","word_nosc":"elegance","lemma":"élégance","pos":"NOM"} ,
		{"word":"élégances","word_nosc":"elegances","lemma":"élégance","pos":"NOM"} ,
		{"word":"élégant","word_nosc":"elegant","lemma":"élégant","pos":"NOM"} ,
		{"word":"élégante","word_nosc":"elegante","lemma":"élégant","pos":"NOM"} ,
		{"word":"élégantes","word_nosc":"elegantes","lemma":"élégant","pos":"NOM"} ,
		{"word":"élégants","word_nosc":"elegants","lemma":"élégant","pos":"NOM"} ,
		{"word":"élégiaque","word_nosc":"elegiaque","lemma":"élégiaque","pos":"NOM"} ,
		{"word":"élégiaques","word_nosc":"elegiaques","lemma":"élégiaque","pos":"NOM"} ,
		{"word":"élégie","word_nosc":"elegie","lemma":"élégie","pos":"NOM"} ,
		{"word":"élégies","word_nosc":"elegies","lemma":"élégie","pos":"NOM"} ,
		{"word":"élément","word_nosc":"element","lemma":"élément","pos":"NOM"} ,
		{"word":"élément-clé","word_nosc":"element-cle","lemma":"élément-clé","pos":"NOM"} ,
		{"word":"élémentarité","word_nosc":"elementarite","lemma":"élémentarité","pos":"NOM"} ,
		{"word":"éléments","word_nosc":"elements","lemma":"élément","pos":"NOM"} ,
		{"word":"éléments-clés","word_nosc":"elements-cles","lemma":"éléments-clé","pos":"NOM"} ,
		{"word":"éléphant","word_nosc":"elephant","lemma":"éléphant","pos":"NOM"} ,
		{"word":"éléphante","word_nosc":"elephante","lemma":"éléphant","pos":"NOM"} ,
		{"word":"éléphanteau","word_nosc":"elephanteau","lemma":"éléphanteau","pos":"NOM"} ,
		{"word":"éléphantes","word_nosc":"elephantes","lemma":"éléphant","pos":"NOM"} ,
		{"word":"éléphantiasis","word_nosc":"elephantiasis","lemma":"éléphantiasis","pos":"NOM"} ,
		{"word":"éléphants","word_nosc":"elephants","lemma":"éléphant","pos":"NOM"} ,
		{"word":"élévateur","word_nosc":"elevateur","lemma":"élévateur","pos":"NOM"} ,
		{"word":"élévateurs","word_nosc":"elevateurs","lemma":"élévateur","pos":"NOM"} ,
		{"word":"élévation","word_nosc":"elevation","lemma":"élévation","pos":"NOM"} ,
		{"word":"élévations","word_nosc":"elevations","lemma":"élévation","pos":"NOM"} ,
		{"word":"émail","word_nosc":"email","lemma":"émail","pos":"NOM"} ,
		{"word":"émanation","word_nosc":"emanation","lemma":"émanation","pos":"NOM"} ,
		{"word":"émanations","word_nosc":"emanations","lemma":"émanation","pos":"NOM"} ,
		{"word":"émancipation","word_nosc":"emancipation","lemma":"émancipation","pos":"NOM"} ,
		{"word":"émargement","word_nosc":"emargement","lemma":"émargement","pos":"NOM"} ,
		{"word":"émasculation","word_nosc":"emasculation","lemma":"émasculation","pos":"NOM"} ,
		{"word":"émaux","word_nosc":"emaux","lemma":"émail","pos":"NOM"} ,
		{"word":"émeraude","word_nosc":"emeraude","lemma":"émeraude","pos":"NOM"} ,
		{"word":"émeraudes","word_nosc":"emeraudes","lemma":"émeraude","pos":"NOM"} ,
		{"word":"émergence","word_nosc":"emergence","lemma":"émergence","pos":"NOM"} ,
		{"word":"émergences","word_nosc":"emergences","lemma":"émergence","pos":"NOM"} ,
		{"word":"émeri","word_nosc":"emeri","lemma":"émeri","pos":"NOM"} ,
		{"word":"émerillon","word_nosc":"emerillon","lemma":"émerillon","pos":"NOM"} ,
		{"word":"émersion","word_nosc":"emersion","lemma":"émersion","pos":"NOM"} ,
		{"word":"émerveillement","word_nosc":"emerveillement","lemma":"émerveillement","pos":"NOM"} ,
		{"word":"émerveillements","word_nosc":"emerveillements","lemma":"émerveillement","pos":"NOM"} ,
		{"word":"émetteur","word_nosc":"emetteur","lemma":"émetteur","pos":"NOM"} ,
		{"word":"émetteur-récepteur","word_nosc":"emetteur-recepteur","lemma":"émetteur-récepteur","pos":"NOM"} ,
		{"word":"émetteurs","word_nosc":"emetteurs","lemma":"émetteur","pos":"NOM"} ,
		{"word":"émeu","word_nosc":"emeu","lemma":"émeu","pos":"NOM"} ,
		{"word":"émeus","word_nosc":"emeus","lemma":"émeu","pos":"NOM"} ,
		{"word":"émeute","word_nosc":"emeute","lemma":"émeute","pos":"NOM"} ,
		{"word":"émeutes","word_nosc":"emeutes","lemma":"émeute","pos":"NOM"} ,
		{"word":"émeutier","word_nosc":"emeutier","lemma":"émeutier","pos":"NOM"} ,
		{"word":"émeutiers","word_nosc":"emeutiers","lemma":"émeutier","pos":"NOM"} ,
		{"word":"émiettement","word_nosc":"emiettement","lemma":"émiettement","pos":"NOM"} ,
		{"word":"émiettements","word_nosc":"emiettements","lemma":"émiettement","pos":"NOM"} ,
		{"word":"émigrant","word_nosc":"emigrant","lemma":"émigrant","pos":"NOM"} ,
		{"word":"émigrante","word_nosc":"emigrante","lemma":"émigrant","pos":"NOM"} ,
		{"word":"émigrants","word_nosc":"emigrants","lemma":"émigrant","pos":"NOM"} ,
		{"word":"émigration","word_nosc":"emigration","lemma":"émigration","pos":"NOM"} ,
		{"word":"émigré","word_nosc":"emigre","lemma":"émigré","pos":"NOM"} ,
		{"word":"émigrée","word_nosc":"emigree","lemma":"émigré","pos":"NOM"} ,
		{"word":"émigrés","word_nosc":"emigres","lemma":"émigré","pos":"NOM"} ,
		{"word":"émilien","word_nosc":"emilien","lemma":"émilien","pos":"NOM"} ,
		{"word":"émilienne","word_nosc":"emilienne","lemma":"émilien","pos":"NOM"} ,
		{"word":"émincé","word_nosc":"emince","lemma":"émincé","pos":"NOM"} ,
		{"word":"émincés","word_nosc":"eminces","lemma":"émincé","pos":"NOM"} ,
		{"word":"éminence","word_nosc":"eminence","lemma":"éminence","pos":"NOM"} ,
		{"word":"éminences","word_nosc":"eminences","lemma":"éminence","pos":"NOM"} ,
		{"word":"émir","word_nosc":"emir","lemma":"émir","pos":"NOM"} ,
		{"word":"émirat","word_nosc":"emirat","lemma":"émirat","pos":"NOM"} ,
		{"word":"émirats","word_nosc":"emirats","lemma":"émirat","pos":"NOM"} ,
		{"word":"émirs","word_nosc":"emirs","lemma":"émir","pos":"NOM"} ,
		{"word":"émissaire","word_nosc":"emissaire","lemma":"émissaire","pos":"NOM"} ,
		{"word":"émissaires","word_nosc":"emissaires","lemma":"émissaire","pos":"NOM"} ,
		{"word":"émission","word_nosc":"emission","lemma":"émission","pos":"NOM"} ,
		{"word":"émissions","word_nosc":"emissions","lemma":"émission","pos":"NOM"} ,
		{"word":"émoi","word_nosc":"emoi","lemma":"émoi","pos":"NOM"} ,
		{"word":"émois","word_nosc":"emois","lemma":"émoi","pos":"NOM"} ,
		{"word":"émoluments","word_nosc":"emoluments","lemma":"émolument","pos":"NOM"} ,
		{"word":"émondeur","word_nosc":"emondeur","lemma":"émondeur","pos":"NOM"} ,
		{"word":"émotif","word_nosc":"emotif","lemma":"émotif","pos":"NOM"} ,
		{"word":"émotifs","word_nosc":"emotifs","lemma":"émotif","pos":"NOM"} ,
		{"word":"émotion","word_nosc":"emotion","lemma":"émotion","pos":"NOM"} ,
		{"word":"émotions","word_nosc":"emotions","lemma":"émotion","pos":"NOM"} ,
		{"word":"émotivité","word_nosc":"emotivite","lemma":"émotivité","pos":"NOM"} ,
		{"word":"émouchets","word_nosc":"emouchets","lemma":"émouchet","pos":"NOM"} ,
		{"word":"émoussement","word_nosc":"emoussement","lemma":"émoussement","pos":"NOM"} ,
		{"word":"émulation","word_nosc":"emulation","lemma":"émulation","pos":"NOM"} ,
		{"word":"émulations","word_nosc":"emulations","lemma":"émulation","pos":"NOM"} ,
		{"word":"émule","word_nosc":"emule","lemma":"émule","pos":"NOM"} ,
		{"word":"émules","word_nosc":"emules","lemma":"émule","pos":"NOM"} ,
		{"word":"émulsion","word_nosc":"emulsion","lemma":"émulsion","pos":"NOM"} ,
		{"word":"émétine","word_nosc":"emetine","lemma":"émétine","pos":"NOM"} ,
		{"word":"émétique","word_nosc":"emetique","lemma":"émétique","pos":"NOM"} ,
		{"word":"énarque","word_nosc":"enarque","lemma":"énarque","pos":"NOM"} ,
		{"word":"énarques","word_nosc":"enarques","lemma":"énarque","pos":"NOM"} ,
		{"word":"énergie","word_nosc":"energie","lemma":"énergie","pos":"NOM"} ,
		{"word":"énergies","word_nosc":"energies","lemma":"énergie","pos":"NOM"} ,
		{"word":"énergisant","word_nosc":"energisant","lemma":"énergisant","pos":"NOM"} ,
		{"word":"énergumène","word_nosc":"energumene","lemma":"énergumène","pos":"NOM"} ,
		{"word":"énergumènes","word_nosc":"energumenes","lemma":"énergumène","pos":"NOM"} ,
		{"word":"énergétique","word_nosc":"energetique","lemma":"énergétique","pos":"NOM"} ,
		{"word":"énervement","word_nosc":"enervement","lemma":"énervement","pos":"NOM"} ,
		{"word":"énervements","word_nosc":"enervements","lemma":"énervement","pos":"NOM"} ,
		{"word":"énigme","word_nosc":"enigme","lemma":"énigme","pos":"NOM"} ,
		{"word":"énigmes","word_nosc":"enigmes","lemma":"énigme","pos":"NOM"} ,
		{"word":"énième","word_nosc":"enieme","lemma":"énième","pos":"NOM"} ,
		{"word":"énonciateurs","word_nosc":"enonciateurs","lemma":"énonciateur","pos":"NOM"} ,
		{"word":"énonciation","word_nosc":"enonciation","lemma":"énonciation","pos":"NOM"} ,
		{"word":"énoncé","word_nosc":"enonce","lemma":"énoncé","pos":"NOM"} ,
		{"word":"énoncés","word_nosc":"enonces","lemma":"énoncé","pos":"NOM"} ,
		{"word":"énormité","word_nosc":"enormite","lemma":"énormité","pos":"NOM"} ,
		{"word":"énormités","word_nosc":"enormites","lemma":"énormité","pos":"NOM"} ,
		{"word":"énucléation","word_nosc":"enucleation","lemma":"énucléation","pos":"NOM"} ,
		{"word":"énumération","word_nosc":"enumeration","lemma":"énumération","pos":"NOM"} ,
		{"word":"énumérations","word_nosc":"enumerations","lemma":"énumération","pos":"NOM"} ,
		{"word":"énurésie","word_nosc":"enuresie","lemma":"énurésie","pos":"NOM"} ,
		{"word":"énurétique","word_nosc":"enuretique","lemma":"énurétique","pos":"NOM"} ,
		{"word":"éocène","word_nosc":"eocene","lemma":"éocène","pos":"NOM"} ,
		{"word":"éolien","word_nosc":"eolien","lemma":"éolien","pos":"NOM"} ,
		{"word":"éolienne","word_nosc":"eolienne","lemma":"éolien","pos":"NOM"} ,
		{"word":"éoliennes","word_nosc":"eoliennes","lemma":"éolien","pos":"NOM"} ,
		{"word":"éon","word_nosc":"eon","lemma":"éon","pos":"NOM"} ,
		{"word":"éonisme","word_nosc":"eonisme","lemma":"éonisme","pos":"NOM"} ,
		{"word":"éosine","word_nosc":"eosine","lemma":"éosine","pos":"NOM"} ,
		{"word":"épagneul","word_nosc":"epagneul","lemma":"épagneul","pos":"NOM"} ,
		{"word":"épagneule","word_nosc":"epagneule","lemma":"épagneul","pos":"NOM"} ,
		{"word":"épaisseur","word_nosc":"epaisseur","lemma":"épaisseur","pos":"NOM"} ,
		{"word":"épaisseurs","word_nosc":"epaisseurs","lemma":"épaisseur","pos":"NOM"} ,
		{"word":"épaississement","word_nosc":"epaississement","lemma":"épaississement","pos":"NOM"} ,
		{"word":"épanchement","word_nosc":"epanchement","lemma":"épanchement","pos":"NOM"} ,
		{"word":"épanchements","word_nosc":"epanchements","lemma":"épanchement","pos":"NOM"} ,
		{"word":"épandage","word_nosc":"epandage","lemma":"épandage","pos":"NOM"} ,
		{"word":"épandages","word_nosc":"epandages","lemma":"épandage","pos":"NOM"} ,
		{"word":"épandeur","word_nosc":"epandeur","lemma":"épandeur","pos":"NOM"} ,
		{"word":"épanouissement","word_nosc":"epanouissement","lemma":"épanouissement","pos":"NOM"} ,
		{"word":"épanouissements","word_nosc":"epanouissements","lemma":"épanouissement","pos":"NOM"} ,
		{"word":"épargnant","word_nosc":"epargnant","lemma":"épargnant","pos":"NOM"} ,
		{"word":"épargnants","word_nosc":"epargnants","lemma":"épargnant","pos":"NOM"} ,
		{"word":"épargne","word_nosc":"epargne","lemma":"épargne","pos":"NOM"} ,
		{"word":"épargnes","word_nosc":"epargnes","lemma":"épargne","pos":"NOM"} ,
		{"word":"éparpillement","word_nosc":"eparpillement","lemma":"éparpillement","pos":"NOM"} ,
		{"word":"épars","word_nosc":"epars","lemma":"épars","pos":"NOM"} ,
		{"word":"épart","word_nosc":"epart","lemma":"épart","pos":"NOM"} ,
		{"word":"épate","word_nosc":"epate","lemma":"épate","pos":"NOM"} ,
		{"word":"épatement","word_nosc":"epatement","lemma":"épatement","pos":"NOM"} ,
		{"word":"épates","word_nosc":"epates","lemma":"épate","pos":"NOM"} ,
		{"word":"épaulard","word_nosc":"epaulard","lemma":"épaulard","pos":"NOM"} ,
		{"word":"épaulards","word_nosc":"epaulards","lemma":"épaulard","pos":"NOM"} ,
		{"word":"épaule","word_nosc":"epaule","lemma":"épaule","pos":"NOM"} ,
		{"word":"épaulement","word_nosc":"epaulement","lemma":"épaulement","pos":"NOM"} ,
		{"word":"épaulements","word_nosc":"epaulements","lemma":"épaulement","pos":"NOM"} ,
		{"word":"épaules","word_nosc":"epaules","lemma":"épaule","pos":"NOM"} ,
		{"word":"épaulette","word_nosc":"epaulette","lemma":"épaulette","pos":"NOM"} ,
		{"word":"épaulettes","word_nosc":"epaulettes","lemma":"épaulette","pos":"NOM"} ,
		{"word":"épaulière","word_nosc":"epauliere","lemma":"épaulière","pos":"NOM"} ,
		{"word":"épaulières","word_nosc":"epaulieres","lemma":"épaulière","pos":"NOM"} ,
		{"word":"épaulé","word_nosc":"epaule","lemma":"épaulé","pos":"NOM"} ,
		{"word":"épave","word_nosc":"epave","lemma":"épave","pos":"NOM"} ,
		{"word":"épaves","word_nosc":"epaves","lemma":"épave","pos":"NOM"} ,
		{"word":"épeautre","word_nosc":"epeautre","lemma":"épeautre","pos":"NOM"} ,
		{"word":"épectase","word_nosc":"epectase","lemma":"épectase","pos":"NOM"} ,
		{"word":"épeichette","word_nosc":"epeichette","lemma":"épeichette","pos":"NOM"} ,
		{"word":"épeire","word_nosc":"epeire","lemma":"épeire","pos":"NOM"} ,
		{"word":"épellation","word_nosc":"epellation","lemma":"épellation","pos":"NOM"} ,
		{"word":"épendyme","word_nosc":"ependyme","lemma":"épendyme","pos":"NOM"} ,
		{"word":"éperlan","word_nosc":"eperlan","lemma":"éperlan","pos":"NOM"} ,
		{"word":"éperlans","word_nosc":"eperlans","lemma":"éperlan","pos":"NOM"} ,
		{"word":"éperon","word_nosc":"eperon","lemma":"éperon","pos":"NOM"} ,
		{"word":"éperons","word_nosc":"eperons","lemma":"éperon","pos":"NOM"} ,
		{"word":"épervier","word_nosc":"epervier","lemma":"épervier","pos":"NOM"} ,
		{"word":"éperviers","word_nosc":"eperviers","lemma":"épervier","pos":"NOM"} ,
		{"word":"épervière","word_nosc":"eperviere","lemma":"épervière","pos":"NOM"} ,
		{"word":"épervières","word_nosc":"epervieres","lemma":"épervière","pos":"NOM"} ,
		{"word":"éphèbe","word_nosc":"ephebe","lemma":"éphèbe","pos":"NOM"} ,
		{"word":"éphèbes","word_nosc":"ephebes","lemma":"éphèbe","pos":"NOM"} ,
		{"word":"éphébie","word_nosc":"ephebie","lemma":"éphébie","pos":"NOM"} ,
		{"word":"éphédrine","word_nosc":"ephedrine","lemma":"éphédrine","pos":"NOM"} ,
		{"word":"éphélides","word_nosc":"ephelides","lemma":"éphélide","pos":"NOM"} ,
		{"word":"éphémère","word_nosc":"ephemere","lemma":"éphémère","pos":"NOM"} ,
		{"word":"éphémères","word_nosc":"ephemeres","lemma":"éphémère","pos":"NOM"} ,
		{"word":"éphéméride","word_nosc":"ephemeride","lemma":"éphéméride","pos":"NOM"} ,
		{"word":"éphémérides","word_nosc":"ephemerides","lemma":"éphéméride","pos":"NOM"} ,
		{"word":"éphésiens","word_nosc":"ephesiens","lemma":"éphésien","pos":"NOM"} ,
		{"word":"épi","word_nosc":"epi","lemma":"épi","pos":"NOM"} ,
		{"word":"épicanthus","word_nosc":"epicanthus","lemma":"épicanthus","pos":"NOM"} ,
		{"word":"épice","word_nosc":"epice","lemma":"épice","pos":"NOM"} ,
		{"word":"épicemard","word_nosc":"epicemard","lemma":"épicemard","pos":"NOM"} ,
		{"word":"épicemards","word_nosc":"epicemards","lemma":"épicemard","pos":"NOM"} ,
		{"word":"épicentre","word_nosc":"epicentre","lemma":"épicentre","pos":"NOM"} ,
		{"word":"épicerie","word_nosc":"epicerie","lemma":"épicerie","pos":"NOM"} ,
		{"word":"épiceries","word_nosc":"epiceries","lemma":"épicerie","pos":"NOM"} ,
		{"word":"épices","word_nosc":"epices","lemma":"épice","pos":"NOM"} ,
		{"word":"épicier","word_nosc":"epicier","lemma":"épicier","pos":"NOM"} ,
		{"word":"épiciers","word_nosc":"epiciers","lemma":"épicier","pos":"NOM"} ,
		{"word":"épicière","word_nosc":"epiciere","lemma":"épicier","pos":"NOM"} ,
		{"word":"épicières","word_nosc":"epicieres","lemma":"épicier","pos":"NOM"} ,
		{"word":"épicondyle","word_nosc":"epicondyle","lemma":"épicondyle","pos":"NOM"} ,
		{"word":"épicurien","word_nosc":"epicurien","lemma":"épicurien","pos":"NOM"} ,
		{"word":"épicuriens","word_nosc":"epicuriens","lemma":"épicurien","pos":"NOM"} ,
		{"word":"épicurisme","word_nosc":"epicurisme","lemma":"épicurisme","pos":"NOM"} ,
		{"word":"épicéa","word_nosc":"epicea","lemma":"épicéa","pos":"NOM"} ,
		{"word":"épiderme","word_nosc":"epiderme","lemma":"épiderme","pos":"NOM"} ,
		{"word":"épidermes","word_nosc":"epidermes","lemma":"épiderme","pos":"NOM"} ,
		{"word":"épidiascopes","word_nosc":"epidiascopes","lemma":"épidiascope","pos":"NOM"} ,
		{"word":"épididyme","word_nosc":"epididyme","lemma":"épididyme","pos":"NOM"} ,
		{"word":"épididymes","word_nosc":"epididymes","lemma":"épididyme","pos":"NOM"} ,
		{"word":"épidémie","word_nosc":"epidemie","lemma":"épidémie","pos":"NOM"} ,
		{"word":"épidémies","word_nosc":"epidemies","lemma":"épidémie","pos":"NOM"} ,
		{"word":"épidémiologie","word_nosc":"epidemiologie","lemma":"épidémiologie","pos":"NOM"} ,
		{"word":"épidémiologiste","word_nosc":"epidemiologiste","lemma":"épidémiologiste","pos":"NOM"} ,
		{"word":"épieu","word_nosc":"epieu","lemma":"épieu","pos":"NOM"} ,
		{"word":"épieux","word_nosc":"epieux","lemma":"épieux","pos":"NOM"} ,
		{"word":"épigastre","word_nosc":"epigastre","lemma":"épigastre","pos":"NOM"} ,
		{"word":"épiglotte","word_nosc":"epiglotte","lemma":"épiglotte","pos":"NOM"} ,
		{"word":"épigone","word_nosc":"epigone","lemma":"épigone","pos":"NOM"} ,
		{"word":"épigones","word_nosc":"epigones","lemma":"épigone","pos":"NOM"} ,
		{"word":"épigramme","word_nosc":"epigramme","lemma":"épigramme","pos":"NOM"} ,
		{"word":"épigrammes","word_nosc":"epigrammes","lemma":"épigramme","pos":"NOM"} ,
		{"word":"épigraphe","word_nosc":"epigraphe","lemma":"épigraphe","pos":"NOM"} ,
		{"word":"épigraphie","word_nosc":"epigraphie","lemma":"épigraphie","pos":"NOM"} ,
		{"word":"épigraphistes","word_nosc":"epigraphistes","lemma":"épigraphiste","pos":"NOM"} ,
		{"word":"épilateur","word_nosc":"epilateur","lemma":"épilateur","pos":"NOM"} ,
		{"word":"épilation","word_nosc":"epilation","lemma":"épilation","pos":"NOM"} ,
		{"word":"épilepsie","word_nosc":"epilepsie","lemma":"épilepsie","pos":"NOM"} ,
		{"word":"épileptique","word_nosc":"epileptique","lemma":"épileptique","pos":"NOM"} ,
		{"word":"épileptiques","word_nosc":"epileptiques","lemma":"épileptique","pos":"NOM"} ,
		{"word":"épileuse","word_nosc":"epileuse","lemma":"épileur","pos":"NOM"} ,
		{"word":"épilogue","word_nosc":"epilogue","lemma":"épilogue","pos":"NOM"} ,
		{"word":"épilogues","word_nosc":"epilogues","lemma":"épilogue","pos":"NOM"} ,
		{"word":"épinaie","word_nosc":"epinaie","lemma":"épinaie","pos":"NOM"} ,
		{"word":"épinard","word_nosc":"epinard","lemma":"épinard","pos":"NOM"} ,
		{"word":"épinards","word_nosc":"epinards","lemma":"épinard","pos":"NOM"} ,
		{"word":"épine","word_nosc":"epine","lemma":"épine","pos":"NOM"} ,
		{"word":"épine-vinette","word_nosc":"epine-vinette","lemma":"épine-vinette","pos":"NOM"} ,
		{"word":"épines","word_nosc":"epines","lemma":"épine","pos":"NOM"} ,
		{"word":"épinette","word_nosc":"epinette","lemma":"épinette","pos":"NOM"} ,
		{"word":"épinettes","word_nosc":"epinettes","lemma":"épinette","pos":"NOM"} ,
		{"word":"épineux","word_nosc":"epineux","lemma":"épineux","pos":"NOM"} ,
		{"word":"épinglage","word_nosc":"epinglage","lemma":"épinglage","pos":"NOM"} ,
		{"word":"épingle","word_nosc":"epingle","lemma":"épingle","pos":"NOM"} ,
		{"word":"épingles","word_nosc":"epingles","lemma":"épingle","pos":"NOM"} ,
		{"word":"épinglette","word_nosc":"epinglette","lemma":"épinglette","pos":"NOM"} ,
		{"word":"épiniers","word_nosc":"epiniers","lemma":"épinier","pos":"NOM"} ,
		{"word":"épinoches","word_nosc":"epinoches","lemma":"épinoche","pos":"NOM"} ,
		{"word":"épiphanie","word_nosc":"epiphanie","lemma":"épiphanie","pos":"NOM"} ,
		{"word":"épiphylle","word_nosc":"epiphylle","lemma":"épiphylle","pos":"NOM"} ,
		{"word":"épiphylles","word_nosc":"epiphylles","lemma":"épiphylle","pos":"NOM"} ,
		{"word":"épiphyse","word_nosc":"epiphyse","lemma":"épiphyse","pos":"NOM"} ,
		{"word":"épiphyte","word_nosc":"epiphyte","lemma":"épiphyte","pos":"NOM"} ,
		{"word":"épiphytes","word_nosc":"epiphytes","lemma":"épiphyte","pos":"NOM"} ,
		{"word":"épiphénomène","word_nosc":"epiphenomene","lemma":"épiphénomène","pos":"NOM"} ,
		{"word":"épiploon","word_nosc":"epiploon","lemma":"épiploon","pos":"NOM"} ,
		{"word":"épiploons","word_nosc":"epiploons","lemma":"épiploon","pos":"NOM"} ,
		{"word":"épis","word_nosc":"epis","lemma":"épi","pos":"NOM"} ,
		{"word":"épiscopalien","word_nosc":"episcopalien","lemma":"épiscopalien","pos":"NOM"} ,
		{"word":"épiscopalienne","word_nosc":"episcopalienne","lemma":"épiscopalien","pos":"NOM"} ,
		{"word":"épiscopat","word_nosc":"episcopat","lemma":"épiscopat","pos":"NOM"} ,
		{"word":"épisiotomie","word_nosc":"episiotomie","lemma":"épisiotomie","pos":"NOM"} ,
		{"word":"épisode","word_nosc":"episode","lemma":"épisode","pos":"NOM"} ,
		{"word":"épisodes","word_nosc":"episodes","lemma":"épisode","pos":"NOM"} ,
		{"word":"épissoirs","word_nosc":"epissoirs","lemma":"épissoir","pos":"NOM"} ,
		{"word":"épissures","word_nosc":"epissures","lemma":"épissure","pos":"NOM"} ,
		{"word":"épistolier","word_nosc":"epistolier","lemma":"épistolier","pos":"NOM"} ,
		{"word":"épistolière","word_nosc":"epistoliere","lemma":"épistolier","pos":"NOM"} ,
		{"word":"épistémologie","word_nosc":"epistemologie","lemma":"épistémologie","pos":"NOM"} ,
		{"word":"épistémologues","word_nosc":"epistemologues","lemma":"épistémologue","pos":"NOM"} ,
		{"word":"épitaphe","word_nosc":"epitaphe","lemma":"épitaphe","pos":"NOM"} ,
		{"word":"épitaphes","word_nosc":"epitaphes","lemma":"épitaphe","pos":"NOM"} ,
		{"word":"épithalame","word_nosc":"epithalame","lemma":"épithalame","pos":"NOM"} ,
		{"word":"épithalames","word_nosc":"epithalames","lemma":"épithalame","pos":"NOM"} ,
		{"word":"épithète","word_nosc":"epithete","lemma":"épithète","pos":"NOM"} ,
		{"word":"épithètes","word_nosc":"epithetes","lemma":"épithète","pos":"NOM"} ,
		{"word":"épithélioma","word_nosc":"epithelioma","lemma":"épithélioma","pos":"NOM"} ,
		{"word":"épithélium","word_nosc":"epithelium","lemma":"épithélium","pos":"NOM"} ,
		{"word":"épitoge","word_nosc":"epitoge","lemma":"épitoge","pos":"NOM"} ,
		{"word":"épitomé","word_nosc":"epitome","lemma":"épitomé","pos":"NOM"} ,
		{"word":"épluchage","word_nosc":"epluchage","lemma":"épluchage","pos":"NOM"} ,
		{"word":"épluchages","word_nosc":"epluchages","lemma":"épluchage","pos":"NOM"} ,
		{"word":"épluche-légumes","word_nosc":"epluche-legumes","lemma":"épluche-légumes","pos":"NOM"} ,
		{"word":"éplucheur","word_nosc":"eplucheur","lemma":"éplucheur","pos":"NOM"} ,
		{"word":"éplucheurs","word_nosc":"eplucheurs","lemma":"éplucheur","pos":"NOM"} ,
		{"word":"éplucheuses","word_nosc":"eplucheuses","lemma":"éplucheur","pos":"NOM"} ,
		{"word":"épluchure","word_nosc":"epluchure","lemma":"épluchure","pos":"NOM"} ,
		{"word":"épluchures","word_nosc":"epluchures","lemma":"épluchure","pos":"NOM"} ,
		{"word":"éponge","word_nosc":"eponge","lemma":"éponge","pos":"NOM"} ,
		{"word":"épongeage","word_nosc":"epongeage","lemma":"épongeage","pos":"NOM"} ,
		{"word":"éponges","word_nosc":"eponges","lemma":"éponge","pos":"NOM"} ,
		{"word":"épopée","word_nosc":"epopee","lemma":"épopée","pos":"NOM"} ,
		{"word":"épopées","word_nosc":"epopees","lemma":"épopée","pos":"NOM"} ,
		{"word":"époque","word_nosc":"epoque","lemma":"époque","pos":"NOM"} ,
		{"word":"époques","word_nosc":"epoques","lemma":"époque","pos":"NOM"} ,
		{"word":"épouillage","word_nosc":"epouillage","lemma":"épouillage","pos":"NOM"} ,
		{"word":"épousailles","word_nosc":"epousailles","lemma":"épousailles","pos":"NOM"} ,
		{"word":"épouse","word_nosc":"epouse","lemma":"époux","pos":"NOM"} ,
		{"word":"épouses","word_nosc":"epouses","lemma":"époux","pos":"NOM"} ,
		{"word":"épouseur","word_nosc":"epouseur","lemma":"épouseur","pos":"NOM"} ,
		{"word":"épouseurs","word_nosc":"epouseurs","lemma":"épouseur","pos":"NOM"} ,
		{"word":"époussetage","word_nosc":"epoussetage","lemma":"époussetage","pos":"NOM"} ,
		{"word":"épousée","word_nosc":"epousee","lemma":"épousée","pos":"NOM"} ,
		{"word":"épousées","word_nosc":"epousees","lemma":"épousée","pos":"NOM"} ,
		{"word":"épouvantail","word_nosc":"epouvantail","lemma":"épouvantail","pos":"NOM"} ,
		{"word":"épouvantails","word_nosc":"epouvantails","lemma":"épouvantail","pos":"NOM"} ,
		{"word":"épouvante","word_nosc":"epouvante","lemma":"épouvante","pos":"NOM"} ,
		{"word":"épouvantement","word_nosc":"epouvantement","lemma":"épouvantement","pos":"NOM"} ,
		{"word":"épouvantements","word_nosc":"epouvantements","lemma":"épouvantement","pos":"NOM"} ,
		{"word":"épouvantes","word_nosc":"epouvantes","lemma":"épouvante","pos":"NOM"} ,
		{"word":"époux","word_nosc":"epoux","lemma":"époux","pos":"NOM"} ,
		{"word":"époxyde","word_nosc":"epoxyde","lemma":"époxyde","pos":"NOM"} ,
		{"word":"épreuve","word_nosc":"epreuve","lemma":"épreuve","pos":"NOM"} ,
		{"word":"épreuves","word_nosc":"epreuves","lemma":"épreuve","pos":"NOM"} ,
		{"word":"éprouvette","word_nosc":"eprouvette","lemma":"éprouvette","pos":"NOM"} ,
		{"word":"éprouvettes","word_nosc":"eprouvettes","lemma":"éprouvette","pos":"NOM"} ,
		{"word":"épuisement","word_nosc":"epuisement","lemma":"épuisement","pos":"NOM"} ,
		{"word":"épuisements","word_nosc":"epuisements","lemma":"épuisement","pos":"NOM"} ,
		{"word":"épuisette","word_nosc":"epuisette","lemma":"épuisette","pos":"NOM"} ,
		{"word":"épuisettes","word_nosc":"epuisettes","lemma":"épuisette","pos":"NOM"} ,
		{"word":"épulie","word_nosc":"epulie","lemma":"épulie","pos":"NOM"} ,
		{"word":"épurateur","word_nosc":"epurateur","lemma":"épurateur","pos":"NOM"} ,
		{"word":"épuration","word_nosc":"epuration","lemma":"épuration","pos":"NOM"} ,
		{"word":"épurations","word_nosc":"epurations","lemma":"épuration","pos":"NOM"} ,
		{"word":"épure","word_nosc":"epure","lemma":"épure","pos":"NOM"} ,
		{"word":"épures","word_nosc":"epures","lemma":"épure","pos":"NOM"} ,
		{"word":"épée","word_nosc":"epee","lemma":"épée","pos":"NOM"} ,
		{"word":"épées","word_nosc":"epees","lemma":"épée","pos":"NOM"} ,
		{"word":"épéiste","word_nosc":"epeiste","lemma":"épéiste","pos":"NOM"} ,
		{"word":"épéistes","word_nosc":"epeistes","lemma":"épéiste","pos":"NOM"} ,
		{"word":"épître","word_nosc":"epitre","lemma":"épître","pos":"NOM"} ,
		{"word":"épîtres","word_nosc":"epitres","lemma":"épître","pos":"NOM"} ,
		{"word":"équanimité","word_nosc":"equanimite","lemma":"équanimité","pos":"NOM"} ,
		{"word":"équarisseur","word_nosc":"equarisseur","lemma":"équarisseur","pos":"NOM"} ,
		{"word":"équarisseurs","word_nosc":"equarisseurs","lemma":"équarisseur","pos":"NOM"} ,
		{"word":"équarrissage","word_nosc":"equarrissage","lemma":"équarrissage","pos":"NOM"} ,
		{"word":"équarrisseur","word_nosc":"equarrisseur","lemma":"équarrisseur","pos":"NOM"} ,
		{"word":"équarrisseurs","word_nosc":"equarrisseurs","lemma":"équarrisseur","pos":"NOM"} ,
		{"word":"équateur","word_nosc":"equateur","lemma":"équateur","pos":"NOM"} ,
		{"word":"équation","word_nosc":"equation","lemma":"équation","pos":"NOM"} ,
		{"word":"équations","word_nosc":"equations","lemma":"équation","pos":"NOM"} ,
		{"word":"équatorien","word_nosc":"equatorien","lemma":"équatorien","pos":"NOM"} ,
		{"word":"équerrage","word_nosc":"equerrage","lemma":"équerrage","pos":"NOM"} ,
		{"word":"équerre","word_nosc":"equerre","lemma":"équerre","pos":"NOM"} ,
		{"word":"équerres","word_nosc":"equerres","lemma":"équerre","pos":"NOM"} ,
		{"word":"équidistance","word_nosc":"equidistance","lemma":"équidistance","pos":"NOM"} ,
		{"word":"équidés","word_nosc":"equides","lemma":"équidé","pos":"NOM"} ,
		{"word":"équilibrage","word_nosc":"equilibrage","lemma":"équilibrage","pos":"NOM"} ,
		{"word":"équilibre","word_nosc":"equilibre","lemma":"équilibre","pos":"NOM"} ,
		{"word":"équilibres","word_nosc":"equilibres","lemma":"équilibre","pos":"NOM"} ,
		{"word":"équilibreur","word_nosc":"equilibreur","lemma":"équilibreur","pos":"NOM"} ,
		{"word":"équilibrisme","word_nosc":"equilibrisme","lemma":"équilibrisme","pos":"NOM"} ,
		{"word":"équilibriste","word_nosc":"equilibriste","lemma":"équilibriste","pos":"NOM"} ,
		{"word":"équilibristes","word_nosc":"equilibristes","lemma":"équilibriste","pos":"NOM"} ,
		{"word":"équinoxe","word_nosc":"equinoxe","lemma":"équinoxe","pos":"NOM"} ,
		{"word":"équinoxes","word_nosc":"equinoxes","lemma":"équinoxe","pos":"NOM"} ,
		{"word":"équipage","word_nosc":"equipage","lemma":"équipage","pos":"NOM"} ,
		{"word":"équipages","word_nosc":"equipages","lemma":"équipage","pos":"NOM"} ,
		{"word":"équipe","word_nosc":"equipe","lemma":"équipe","pos":"NOM"} ,
		{"word":"équipement","word_nosc":"equipement","lemma":"équipement","pos":"NOM"} ,
		{"word":"équipements","word_nosc":"equipements","lemma":"équipement","pos":"NOM"} ,
		{"word":"équipes","word_nosc":"equipes","lemma":"équipe","pos":"NOM"} ,
		{"word":"équipier","word_nosc":"equipier","lemma":"équipier","pos":"NOM"} ,
		{"word":"équipiers","word_nosc":"equipiers","lemma":"équipier","pos":"NOM"} ,
		{"word":"équipière","word_nosc":"equipiere","lemma":"équipier","pos":"NOM"} ,
		{"word":"équipée","word_nosc":"equipee","lemma":"équipée","pos":"NOM"} ,
		{"word":"équipées","word_nosc":"equipees","lemma":"équipée","pos":"NOM"} ,
		{"word":"équitation","word_nosc":"equitation","lemma":"équitation","pos":"NOM"} ,
		{"word":"équité","word_nosc":"equite","lemma":"équité","pos":"NOM"} ,
		{"word":"équivalence","word_nosc":"equivalence","lemma":"équivalence","pos":"NOM"} ,
		{"word":"équivalences","word_nosc":"equivalences","lemma":"équivalence","pos":"NOM"} ,
		{"word":"équivalent","word_nosc":"equivalent","lemma":"équivalent","pos":"NOM"} ,
		{"word":"équivalents","word_nosc":"equivalents","lemma":"équivalent","pos":"NOM"} ,
		{"word":"équivoque","word_nosc":"equivoque","lemma":"équivoque","pos":"NOM"} ,
		{"word":"équivoques","word_nosc":"equivoques","lemma":"équivoque","pos":"NOM"} ,
		{"word":"érable","word_nosc":"erable","lemma":"érable","pos":"NOM"} ,
		{"word":"érables","word_nosc":"erables","lemma":"érable","pos":"NOM"} ,
		{"word":"érablières","word_nosc":"erablieres","lemma":"érablière","pos":"NOM"} ,
		{"word":"éradicateur","word_nosc":"eradicateur","lemma":"éradicateur","pos":"NOM"} ,
		{"word":"éradication","word_nosc":"eradication","lemma":"éradication","pos":"NOM"} ,
		{"word":"éraflure","word_nosc":"eraflure","lemma":"éraflure","pos":"NOM"} ,
		{"word":"éraflures","word_nosc":"eraflures","lemma":"éraflure","pos":"NOM"} ,
		{"word":"éraillures","word_nosc":"eraillures","lemma":"éraillure","pos":"NOM"} ,
		{"word":"érection","word_nosc":"erection","lemma":"érection","pos":"NOM"} ,
		{"word":"érections","word_nosc":"erections","lemma":"érection","pos":"NOM"} ,
		{"word":"éreintement","word_nosc":"ereintement","lemma":"éreintement","pos":"NOM"} ,
		{"word":"éreinteur","word_nosc":"ereinteur","lemma":"éreinteur","pos":"NOM"} ,
		{"word":"éros","word_nosc":"eros","lemma":"éros","pos":"NOM"} ,
		{"word":"érosion","word_nosc":"erosion","lemma":"érosion","pos":"NOM"} ,
		{"word":"érosions","word_nosc":"erosions","lemma":"érosion","pos":"NOM"} ,
		{"word":"érotisme","word_nosc":"erotisme","lemma":"érotisme","pos":"NOM"} ,
		{"word":"érotomane","word_nosc":"erotomane","lemma":"érotomane","pos":"NOM"} ,
		{"word":"érotomanie","word_nosc":"erotomanie","lemma":"érotomanie","pos":"NOM"} ,
		{"word":"éructation","word_nosc":"eructation","lemma":"éructation","pos":"NOM"} ,
		{"word":"éructations","word_nosc":"eructations","lemma":"éructation","pos":"NOM"} ,
		{"word":"érudit","word_nosc":"erudit","lemma":"érudit","pos":"NOM"} ,
		{"word":"érudite","word_nosc":"erudite","lemma":"érudit","pos":"NOM"} ,
		{"word":"érudition","word_nosc":"erudition","lemma":"érudition","pos":"NOM"} ,
		{"word":"érudits","word_nosc":"erudits","lemma":"érudit","pos":"NOM"} ,
		{"word":"éruption","word_nosc":"eruption","lemma":"éruption","pos":"NOM"} ,
		{"word":"éruptions","word_nosc":"eruptions","lemma":"éruption","pos":"NOM"} ,
		{"word":"érythromycine","word_nosc":"erythromycine","lemma":"érythromycine","pos":"NOM"} ,
		{"word":"érythrophobie","word_nosc":"erythrophobie","lemma":"érythrophobie","pos":"NOM"} ,
		{"word":"érythréenne","word_nosc":"erythreenne","lemma":"érythréen","pos":"NOM"} ,
		{"word":"érythème","word_nosc":"erytheme","lemma":"érythème","pos":"NOM"} ,
		{"word":"érythèmes","word_nosc":"erythemes","lemma":"érythème","pos":"NOM"} ,
		{"word":"ésotérisme","word_nosc":"esoterisme","lemma":"ésotérisme","pos":"NOM"} ,
		{"word":"ésotéristes","word_nosc":"esoteristes","lemma":"ésotériste","pos":"NOM"} ,
		{"word":"étable","word_nosc":"etable","lemma":"étable","pos":"NOM"} ,
		{"word":"étables","word_nosc":"etables","lemma":"étable","pos":"NOM"} ,
		{"word":"établi","word_nosc":"etabli","lemma":"établi","pos":"NOM"} ,
		{"word":"établis","word_nosc":"etablis","lemma":"établi","pos":"NOM"} ,
		{"word":"établissement","word_nosc":"etablissement","lemma":"établissement","pos":"NOM"} ,
		{"word":"établissements","word_nosc":"etablissements","lemma":"établissement","pos":"NOM"} ,
		{"word":"étage","word_nosc":"etage","lemma":"étage","pos":"NOM"} ,
		{"word":"étages","word_nosc":"etages","lemma":"étage","pos":"NOM"} ,
		{"word":"étagère","word_nosc":"etagere","lemma":"étagère","pos":"NOM"} ,
		{"word":"étagères","word_nosc":"etageres","lemma":"étagère","pos":"NOM"} ,
		{"word":"étai","word_nosc":"etai","lemma":"étai","pos":"NOM"} ,
		{"word":"étain","word_nosc":"etain","lemma":"étain","pos":"NOM"} ,
		{"word":"étains","word_nosc":"etains","lemma":"étain","pos":"NOM"} ,
		{"word":"étais","word_nosc":"etais","lemma":"étai","pos":"NOM"} ,
		{"word":"étal","word_nosc":"etal","lemma":"étal","pos":"NOM"} ,
		{"word":"étalage","word_nosc":"etalage","lemma":"étalage","pos":"NOM"} ,
		{"word":"étalages","word_nosc":"etalages","lemma":"étalage","pos":"NOM"} ,
		{"word":"étalagiste","word_nosc":"etalagiste","lemma":"étalagiste","pos":"NOM"} ,
		{"word":"étale","word_nosc":"etale","lemma":"étale","pos":"NOM"} ,
		{"word":"étalement","word_nosc":"etalement","lemma":"étalement","pos":"NOM"} ,
		{"word":"étalements","word_nosc":"etalements","lemma":"étalement","pos":"NOM"} ,
		{"word":"étalon","word_nosc":"etalon","lemma":"étalon","pos":"NOM"} ,
		{"word":"étalonnage","word_nosc":"etalonnage","lemma":"étalonnage","pos":"NOM"} ,
		{"word":"étalonnages","word_nosc":"etalonnages","lemma":"étalonnage","pos":"NOM"} ,
		{"word":"étalons","word_nosc":"etalons","lemma":"étalon","pos":"NOM"} ,
		{"word":"étals","word_nosc":"etals","lemma":"étal","pos":"NOM"} ,
		{"word":"étambot","word_nosc":"etambot","lemma":"étambot","pos":"NOM"} ,
		{"word":"étameur","word_nosc":"etameur","lemma":"étameur","pos":"NOM"} ,
		{"word":"étameurs","word_nosc":"etameurs","lemma":"étameur","pos":"NOM"} ,
		{"word":"étamine","word_nosc":"etamine","lemma":"étamine","pos":"NOM"} ,
		{"word":"étamines","word_nosc":"etamines","lemma":"étamine","pos":"NOM"} ,
		{"word":"étampes","word_nosc":"etampes","lemma":"étampe","pos":"NOM"} ,
		{"word":"étampures","word_nosc":"etampures","lemma":"étampure","pos":"NOM"} ,
		{"word":"étanchement","word_nosc":"etanchement","lemma":"étanchement","pos":"NOM"} ,
		{"word":"étanchéité","word_nosc":"etancheite","lemma":"étanchéité","pos":"NOM"} ,
		{"word":"étang","word_nosc":"etang","lemma":"étang","pos":"NOM"} ,
		{"word":"étangs","word_nosc":"etangs","lemma":"étang","pos":"NOM"} ,
		{"word":"étançon","word_nosc":"etancon","lemma":"étançon","pos":"NOM"} ,
		{"word":"étape","word_nosc":"etape","lemma":"étape","pos":"NOM"} ,
		{"word":"étapes","word_nosc":"etapes","lemma":"étape","pos":"NOM"} ,
		{"word":"état","word_nosc":"etat","lemma":"état","pos":"NOM"} ,
		{"word":"état-civil","word_nosc":"etat-civil","lemma":"état-civil","pos":"NOM"} ,
		{"word":"état-major","word_nosc":"etat-major","lemma":"état-major","pos":"NOM"} ,
		{"word":"étatisation","word_nosc":"etatisation","lemma":"étatisation","pos":"NOM"} ,
		{"word":"étatisme","word_nosc":"etatisme","lemma":"étatisme","pos":"NOM"} ,
		{"word":"états","word_nosc":"etats","lemma":"état","pos":"NOM"} ,
		{"word":"états-civils","word_nosc":"etats-civils","lemma":"état-civil","pos":"NOM"} ,
		{"word":"états-majors","word_nosc":"etats-majors","lemma":"état-major","pos":"NOM"} ,
		{"word":"étau","word_nosc":"etau","lemma":"étau","pos":"NOM"} ,
		{"word":"étaux","word_nosc":"etaux","lemma":"étau","pos":"NOM"} ,
		{"word":"étayage","word_nosc":"etayage","lemma":"étayage","pos":"NOM"} ,
		{"word":"éteignoir","word_nosc":"eteignoir","lemma":"éteignoir","pos":"NOM"} ,
		{"word":"étendage","word_nosc":"etendage","lemma":"étendage","pos":"NOM"} ,
		{"word":"étendard","word_nosc":"etendard","lemma":"étendard","pos":"NOM"} ,
		{"word":"étendards","word_nosc":"etendards","lemma":"étendard","pos":"NOM"} ,
		{"word":"étendoir","word_nosc":"etendoir","lemma":"étendoir","pos":"NOM"} ,
		{"word":"étendue","word_nosc":"etendue","lemma":"étendue","pos":"NOM"} ,
		{"word":"étendues","word_nosc":"etendues","lemma":"étendue","pos":"NOM"} ,
		{"word":"éternel","word_nosc":"eternel","lemma":"éternel","pos":"NOM"} ,
		{"word":"éternelle","word_nosc":"eternelle","lemma":"éternel","pos":"NOM"} ,
		{"word":"éternelles","word_nosc":"eternelles","lemma":"éternel","pos":"NOM"} ,
		{"word":"éternels","word_nosc":"eternels","lemma":"éternel","pos":"NOM"} ,
		{"word":"éternité","word_nosc":"eternite","lemma":"éternité","pos":"NOM"} ,
		{"word":"éternités","word_nosc":"eternites","lemma":"éternité","pos":"NOM"} ,
		{"word":"éternuement","word_nosc":"eternuement","lemma":"éternuement","pos":"NOM"} ,
		{"word":"éternuements","word_nosc":"eternuements","lemma":"éternuement","pos":"NOM"} ,
		{"word":"éteule","word_nosc":"eteule","lemma":"éteule","pos":"NOM"} ,
		{"word":"éteules","word_nosc":"eteules","lemma":"éteule","pos":"NOM"} ,
		{"word":"éthane","word_nosc":"ethane","lemma":"éthane","pos":"NOM"} ,
		{"word":"éthanol","word_nosc":"ethanol","lemma":"éthanol","pos":"NOM"} ,
		{"word":"éther","word_nosc":"ether","lemma":"éther","pos":"NOM"} ,
		{"word":"éthers","word_nosc":"ethers","lemma":"éther","pos":"NOM"} ,
		{"word":"éthiopien","word_nosc":"ethiopien","lemma":"éthiopien","pos":"NOM"} ,
		{"word":"éthiopiennes","word_nosc":"ethiopiennes","lemma":"éthiopien","pos":"NOM"} ,
		{"word":"éthiopiens","word_nosc":"ethiopiens","lemma":"éthiopien","pos":"NOM"} ,
		{"word":"éthique","word_nosc":"ethique","lemma":"éthique","pos":"NOM"} ,
		{"word":"éthiques","word_nosc":"ethiques","lemma":"éthique","pos":"NOM"} ,
		{"word":"éthologie","word_nosc":"ethologie","lemma":"éthologie","pos":"NOM"} ,
		{"word":"éthologue","word_nosc":"ethologue","lemma":"éthologue","pos":"NOM"} ,
		{"word":"éthyle","word_nosc":"ethyle","lemma":"éthyle","pos":"NOM"} ,
		{"word":"éthylique","word_nosc":"ethylique","lemma":"éthylique","pos":"NOM"} ,
		{"word":"éthylisme","word_nosc":"ethylisme","lemma":"éthylisme","pos":"NOM"} ,
		{"word":"éthylotest","word_nosc":"ethylotest","lemma":"éthylotest","pos":"NOM"} ,
		{"word":"éthylène","word_nosc":"ethylene","lemma":"éthylène","pos":"NOM"} ,
		{"word":"étiage","word_nosc":"etiage","lemma":"étiage","pos":"NOM"} ,
		{"word":"étier","word_nosc":"etier","lemma":"étier","pos":"NOM"} ,
		{"word":"étiers","word_nosc":"etiers","lemma":"étier","pos":"NOM"} ,
		{"word":"étincelle","word_nosc":"etincelle","lemma":"étincelle","pos":"NOM"} ,
		{"word":"étincellement","word_nosc":"etincellement","lemma":"étincellement","pos":"NOM"} ,
		{"word":"étincellements","word_nosc":"etincellements","lemma":"étincellement","pos":"NOM"} ,
		{"word":"étincelles","word_nosc":"etincelles","lemma":"étincelle","pos":"NOM"} ,
		{"word":"étiolement","word_nosc":"etiolement","lemma":"étiolement","pos":"NOM"} ,
		{"word":"étiologie","word_nosc":"etiologie","lemma":"étiologie","pos":"NOM"} ,
		{"word":"étiquetage","word_nosc":"etiquetage","lemma":"étiquetage","pos":"NOM"} ,
		{"word":"étiqueteuse","word_nosc":"etiqueteuse","lemma":"étiqueteur","pos":"NOM"} ,
		{"word":"étiquette","word_nosc":"etiquette","lemma":"étiquette","pos":"NOM"} ,
		{"word":"étiquettes","word_nosc":"etiquettes","lemma":"étiquette","pos":"NOM"} ,
		{"word":"étirage","word_nosc":"etirage","lemma":"étirage","pos":"NOM"} ,
		{"word":"étirement","word_nosc":"etirement","lemma":"étirement","pos":"NOM"} ,
		{"word":"étirements","word_nosc":"etirements","lemma":"étirement","pos":"NOM"} ,
		{"word":"étisie","word_nosc":"etisie","lemma":"étisie","pos":"NOM"} ,
		{"word":"étoffe","word_nosc":"etoffe","lemma":"étoffe","pos":"NOM"} ,
		{"word":"étoffes","word_nosc":"etoffes","lemma":"étoffe","pos":"NOM"} ,
		{"word":"étoile","word_nosc":"etoile","lemma":"étoile","pos":"NOM"} ,
		{"word":"étoiles","word_nosc":"etoiles","lemma":"étoile","pos":"NOM"} ,
		{"word":"étole","word_nosc":"etole","lemma":"étole","pos":"NOM"} ,
		{"word":"étoles","word_nosc":"etoles","lemma":"étole","pos":"NOM"} ,
		{"word":"étonnement","word_nosc":"etonnement","lemma":"étonnement","pos":"NOM"} ,
		{"word":"étonnements","word_nosc":"etonnements","lemma":"étonnement","pos":"NOM"} ,
		{"word":"étouffe-chrétien","word_nosc":"etouffe-chretien","lemma":"étouffe-chrétien","pos":"NOM"} ,
		{"word":"étouffement","word_nosc":"etouffement","lemma":"étouffement","pos":"NOM"} ,
		{"word":"étouffements","word_nosc":"etouffements","lemma":"étouffement","pos":"NOM"} ,
		{"word":"étouffeur","word_nosc":"etouffeur","lemma":"étouffeur","pos":"NOM"} ,
		{"word":"étouffeuses","word_nosc":"etouffeuses","lemma":"étouffeur","pos":"NOM"} ,
		{"word":"étouffoir","word_nosc":"etouffoir","lemma":"étouffoir","pos":"NOM"} ,
		{"word":"étouffoirs","word_nosc":"etouffoirs","lemma":"étouffoir","pos":"NOM"} ,
		{"word":"étouffée","word_nosc":"etouffee","lemma":"étouffée","pos":"NOM"} ,
		{"word":"étouffées","word_nosc":"etouffees","lemma":"étouffée","pos":"NOM"} ,
		{"word":"étoupe","word_nosc":"etoupe","lemma":"étoupe","pos":"NOM"} ,
		{"word":"étourderie","word_nosc":"etourderie","lemma":"étourderie","pos":"NOM"} ,
		{"word":"étourderies","word_nosc":"etourderies","lemma":"étourderie","pos":"NOM"} ,
		{"word":"étourdi","word_nosc":"etourdi","lemma":"étourdi","pos":"NOM"} ,
		{"word":"étourdie","word_nosc":"etourdie","lemma":"étourdi","pos":"NOM"} ,
		{"word":"étourdies","word_nosc":"etourdies","lemma":"étourdi","pos":"NOM"} ,
		{"word":"étourdis","word_nosc":"etourdis","lemma":"étourdi","pos":"NOM"} ,
		{"word":"étourdissement","word_nosc":"etourdissement","lemma":"étourdissement","pos":"NOM"} ,
		{"word":"étourdissements","word_nosc":"etourdissements","lemma":"étourdissement","pos":"NOM"} ,
		{"word":"étourneau","word_nosc":"etourneau","lemma":"étourneau","pos":"NOM"} ,
		{"word":"étourneaux","word_nosc":"etourneaux","lemma":"étourneau","pos":"NOM"} ,
		{"word":"étrange","word_nosc":"etrange","lemma":"étrange","pos":"NOM"} ,
		{"word":"étranger","word_nosc":"etranger","lemma":"étranger","pos":"NOM"} ,
		{"word":"étrangers","word_nosc":"etrangers","lemma":"étranger","pos":"NOM"} ,
		{"word":"étranges","word_nosc":"etranges","lemma":"étrange","pos":"NOM"} ,
		{"word":"étrangeté","word_nosc":"etrangete","lemma":"étrangeté","pos":"NOM"} ,
		{"word":"étrangetés","word_nosc":"etrangetes","lemma":"étrangeté","pos":"NOM"} ,
		{"word":"étranglement","word_nosc":"etranglement","lemma":"étranglement","pos":"NOM"} ,
		{"word":"étranglements","word_nosc":"etranglements","lemma":"étranglement","pos":"NOM"} ,
		{"word":"étrangleur","word_nosc":"etrangleur","lemma":"étrangleur","pos":"NOM"} ,
		{"word":"étrangleurs","word_nosc":"etrangleurs","lemma":"étrangleur","pos":"NOM"} ,
		{"word":"étrangleuse","word_nosc":"etrangleuse","lemma":"étrangleur","pos":"NOM"} ,
		{"word":"étrangère","word_nosc":"etrangere","lemma":"étranger","pos":"NOM"} ,
		{"word":"étrangères","word_nosc":"etrangeres","lemma":"étranger","pos":"NOM"} ,
		{"word":"étrangéité","word_nosc":"etrangeite","lemma":"étrangéité","pos":"NOM"} ,
		{"word":"étrave","word_nosc":"etrave","lemma":"étrave","pos":"NOM"} ,
		{"word":"étraves","word_nosc":"etraves","lemma":"étrave","pos":"NOM"} ,
		{"word":"étreinte","word_nosc":"etreinte","lemma":"étreinte","pos":"NOM"} ,
		{"word":"étreintes","word_nosc":"etreintes","lemma":"étreinte","pos":"NOM"} ,
		{"word":"étrenne","word_nosc":"etrenne","lemma":"étrenne","pos":"NOM"} ,
		{"word":"étrennes","word_nosc":"etrennes","lemma":"étrenne","pos":"NOM"} ,
		{"word":"étrier","word_nosc":"etrier","lemma":"étrier","pos":"NOM"} ,
		{"word":"étriers","word_nosc":"etriers","lemma":"étrier","pos":"NOM"} ,
		{"word":"étrillage","word_nosc":"etrillage","lemma":"étrillage","pos":"NOM"} ,
		{"word":"étrille","word_nosc":"etrille","lemma":"étrille","pos":"NOM"} ,
		{"word":"étrilles","word_nosc":"etrilles","lemma":"étrille","pos":"NOM"} ,
		{"word":"étripage","word_nosc":"etripage","lemma":"étripage","pos":"NOM"} ,
		{"word":"étripages","word_nosc":"etripages","lemma":"étripage","pos":"NOM"} ,
		{"word":"étrivière","word_nosc":"etriviere","lemma":"étrivière","pos":"NOM"} ,
		{"word":"étrivières","word_nosc":"etrivieres","lemma":"étrivière","pos":"NOM"} ,
		{"word":"étroitesse","word_nosc":"etroitesse","lemma":"étroitesse","pos":"NOM"} ,
		{"word":"étron","word_nosc":"etron","lemma":"étron","pos":"NOM"} ,
		{"word":"étrons","word_nosc":"etrons","lemma":"étron","pos":"NOM"} ,
		{"word":"étrusque","word_nosc":"etrusque","lemma":"étrusque","pos":"NOM"} ,
		{"word":"étrusques","word_nosc":"etrusques","lemma":"étrusque","pos":"NOM"} ,
		{"word":"étrécissement","word_nosc":"etrecissement","lemma":"étrécissement","pos":"NOM"} ,
		{"word":"étrésillon","word_nosc":"etresillon","lemma":"étrésillon","pos":"NOM"} ,
		{"word":"étude","word_nosc":"etude","lemma":"étude","pos":"NOM"} ,
		{"word":"études","word_nosc":"etudes","lemma":"étude","pos":"NOM"} ,
		{"word":"étudiant","word_nosc":"etudiant","lemma":"étudiant","pos":"NOM"} ,
		{"word":"étudiante","word_nosc":"etudiante","lemma":"étudiant","pos":"NOM"} ,
		{"word":"étudiantes","word_nosc":"etudiantes","lemma":"étudiant","pos":"NOM"} ,
		{"word":"étudiants","word_nosc":"etudiants","lemma":"étudiant","pos":"NOM"} ,
		{"word":"étui","word_nosc":"etui","lemma":"étui","pos":"NOM"} ,
		{"word":"étuis","word_nosc":"etuis","lemma":"étui","pos":"NOM"} ,
		{"word":"étuve","word_nosc":"etuve","lemma":"étuve","pos":"NOM"} ,
		{"word":"étuves","word_nosc":"etuves","lemma":"étuve","pos":"NOM"} ,
		{"word":"étuveuse","word_nosc":"etuveuse","lemma":"étuveur","pos":"NOM"} ,
		{"word":"étuvée","word_nosc":"etuvee","lemma":"étuvée","pos":"NOM"} ,
		{"word":"étymologie","word_nosc":"etymologie","lemma":"étymologie","pos":"NOM"} ,
		{"word":"étymologies","word_nosc":"etymologies","lemma":"étymologie","pos":"NOM"} ,
		{"word":"étymologiste","word_nosc":"etymologiste","lemma":"étymologiste","pos":"NOM"} ,
		{"word":"été","word_nosc":"ete","lemma":"été","pos":"NOM"} ,
		{"word":"étés","word_nosc":"etes","lemma":"été","pos":"NOM"} ,
		{"word":"évacuation","word_nosc":"evacuation","lemma":"évacuation","pos":"NOM"} ,
		{"word":"évacuations","word_nosc":"evacuations","lemma":"évacuation","pos":"NOM"} ,
		{"word":"évacué","word_nosc":"evacue","lemma":"évacué","pos":"NOM"} ,
		{"word":"évacuée","word_nosc":"evacuee","lemma":"évacué","pos":"NOM"} ,
		{"word":"évacués","word_nosc":"evacues","lemma":"évacué","pos":"NOM"} ,
		{"word":"évadé","word_nosc":"evade","lemma":"évadé","pos":"NOM"} ,
		{"word":"évadée","word_nosc":"evadee","lemma":"évadé","pos":"NOM"} ,
		{"word":"évadées","word_nosc":"evadees","lemma":"évadé","pos":"NOM"} ,
		{"word":"évadés","word_nosc":"evades","lemma":"évadé","pos":"NOM"} ,
		{"word":"évaluateur","word_nosc":"evaluateur","lemma":"évaluateur","pos":"NOM"} ,
		{"word":"évaluation","word_nosc":"evaluation","lemma":"évaluation","pos":"NOM"} ,
		{"word":"évaluations","word_nosc":"evaluations","lemma":"évaluation","pos":"NOM"} ,
		{"word":"évanescence","word_nosc":"evanescence","lemma":"évanescence","pos":"NOM"} ,
		{"word":"évanescences","word_nosc":"evanescences","lemma":"évanescence","pos":"NOM"} ,
		{"word":"évangile","word_nosc":"evangile","lemma":"évangile","pos":"NOM"} ,
		{"word":"évangiles","word_nosc":"evangiles","lemma":"évangile","pos":"NOM"} ,
		{"word":"évangéliaire","word_nosc":"evangeliaire","lemma":"évangéliaire","pos":"NOM"} ,
		{"word":"évangélisateur","word_nosc":"evangelisateur","lemma":"évangélisateur","pos":"NOM"} ,
		{"word":"évangélisation","word_nosc":"evangelisation","lemma":"évangélisation","pos":"NOM"} ,
		{"word":"évangéliste","word_nosc":"evangeliste","lemma":"évangéliste","pos":"NOM"} ,
		{"word":"évangélistes","word_nosc":"evangelistes","lemma":"évangéliste","pos":"NOM"} ,
		{"word":"évanouissement","word_nosc":"evanouissement","lemma":"évanouissement","pos":"NOM"} ,
		{"word":"évanouissements","word_nosc":"evanouissements","lemma":"évanouissement","pos":"NOM"} ,
		{"word":"évaporateur","word_nosc":"evaporateur","lemma":"évaporateur","pos":"NOM"} ,
		{"word":"évaporateurs","word_nosc":"evaporateurs","lemma":"évaporateur","pos":"NOM"} ,
		{"word":"évaporation","word_nosc":"evaporation","lemma":"évaporation","pos":"NOM"} ,
		{"word":"évaporée","word_nosc":"evaporee","lemma":"évaporé","pos":"NOM"} ,
		{"word":"évaporées","word_nosc":"evaporees","lemma":"évaporé","pos":"NOM"} ,
		{"word":"évaporées","word_nosc":"evaporees","lemma":"évaporée","pos":"NOM"} ,
		{"word":"évasement","word_nosc":"evasement","lemma":"évasement","pos":"NOM"} ,
		{"word":"évasion","word_nosc":"evasion","lemma":"évasion","pos":"NOM"} ,
		{"word":"évasions","word_nosc":"evasions","lemma":"évasion","pos":"NOM"} ,
		{"word":"éveil","word_nosc":"eveil","lemma":"éveil","pos":"NOM"} ,
		{"word":"éveilleur","word_nosc":"eveilleur","lemma":"éveilleur","pos":"NOM"} ,
		{"word":"éveils","word_nosc":"eveils","lemma":"éveil","pos":"NOM"} ,
		{"word":"éveinage","word_nosc":"eveinage","lemma":"éveinage","pos":"NOM"} ,
		{"word":"évent","word_nosc":"event","lemma":"évent","pos":"NOM"} ,
		{"word":"éventail","word_nosc":"eventail","lemma":"éventail","pos":"NOM"} ,
		{"word":"éventails","word_nosc":"eventails","lemma":"éventail","pos":"NOM"} ,
		{"word":"éventaire","word_nosc":"eventaire","lemma":"éventaire","pos":"NOM"} ,
		{"word":"éventaires","word_nosc":"eventaires","lemma":"éventaire","pos":"NOM"} ,
		{"word":"éventration","word_nosc":"eventration","lemma":"éventration","pos":"NOM"} ,
		{"word":"éventrement","word_nosc":"eventrement","lemma":"éventrement","pos":"NOM"} ,
		{"word":"éventreur","word_nosc":"eventreur","lemma":"éventreur","pos":"NOM"} ,
		{"word":"éventreurs","word_nosc":"eventreurs","lemma":"éventreur","pos":"NOM"} ,
		{"word":"évents","word_nosc":"events","lemma":"évent","pos":"NOM"} ,
		{"word":"éventualité","word_nosc":"eventualite","lemma":"éventualité","pos":"NOM"} ,
		{"word":"éventualités","word_nosc":"eventualites","lemma":"éventualité","pos":"NOM"} ,
		{"word":"éventuel","word_nosc":"eventuel","lemma":"éventuel","pos":"NOM"} ,
		{"word":"éventuels","word_nosc":"eventuels","lemma":"éventuel","pos":"NOM"} ,
		{"word":"éviction","word_nosc":"eviction","lemma":"éviction","pos":"NOM"} ,
		{"word":"évidement","word_nosc":"evidement","lemma":"évidement","pos":"NOM"} ,
		{"word":"évidence","word_nosc":"evidence","lemma":"évidence","pos":"NOM"} ,
		{"word":"évidences","word_nosc":"evidences","lemma":"évidence","pos":"NOM"} ,
		{"word":"évier","word_nosc":"evier","lemma":"évier","pos":"NOM"} ,
		{"word":"éviers","word_nosc":"eviers","lemma":"évier","pos":"NOM"} ,
		{"word":"éviscération","word_nosc":"evisceration","lemma":"éviscération","pos":"NOM"} ,
		{"word":"évitement","word_nosc":"evitement","lemma":"évitement","pos":"NOM"} ,
		{"word":"évitée","word_nosc":"evitee","lemma":"évitée","pos":"NOM"} ,
		{"word":"évocateur","word_nosc":"evocateur","lemma":"évocateur","pos":"NOM"} ,
		{"word":"évocation","word_nosc":"evocation","lemma":"évocation","pos":"NOM"} ,
		{"word":"évocations","word_nosc":"evocations","lemma":"évocation","pos":"NOM"} ,
		{"word":"évolution","word_nosc":"evolution","lemma":"évolution","pos":"NOM"} ,
		{"word":"évolutions","word_nosc":"evolutions","lemma":"évolution","pos":"NOM"} ,
		{"word":"évolué","word_nosc":"evolue","lemma":"évolué","pos":"NOM"} ,
		{"word":"évoluée","word_nosc":"evoluee","lemma":"évolué","pos":"NOM"} ,
		{"word":"évoluées","word_nosc":"evoluees","lemma":"évolué","pos":"NOM"} ,
		{"word":"évolués","word_nosc":"evolues","lemma":"évolué","pos":"NOM"} ,
		{"word":"évènement","word_nosc":"evenement","lemma":"évènement","pos":"NOM"} ,
		{"word":"évènements","word_nosc":"evenements","lemma":"évènement","pos":"NOM"} ,
		{"word":"événement","word_nosc":"evenement","lemma":"événement","pos":"NOM"} ,
		{"word":"événements","word_nosc":"evenements","lemma":"événement","pos":"NOM"} ,
		{"word":"évêché","word_nosc":"eveche","lemma":"évêché","pos":"NOM"} ,
		{"word":"évêchés","word_nosc":"eveches","lemma":"évêché","pos":"NOM"} ,
		{"word":"évêque","word_nosc":"eveque","lemma":"évêque","pos":"NOM"} ,
		{"word":"évêques","word_nosc":"eveques","lemma":"évêque","pos":"NOM"} ,
		{"word":"éwé","word_nosc":"ewe","lemma":"éwé","pos":"NOM"} ,
		{"word":"être","word_nosc":"etre","lemma":"être","pos":"NOM"} ,
		{"word":"être-là","word_nosc":"etre-la","lemma":"être-là","pos":"NOM"} ,
		{"word":"êtres","word_nosc":"etres","lemma":"être","pos":"NOM"} ,
		{"word":"île","word_nosc":"ile","lemma":"île","pos":"NOM"} ,
		{"word":"îles","word_nosc":"iles","lemma":"île","pos":"NOM"} ,
		{"word":"îlette","word_nosc":"ilette","lemma":"îlette","pos":"NOM"} ,
		{"word":"îlot","word_nosc":"ilot","lemma":"îlot","pos":"NOM"} ,
		{"word":"îlotier","word_nosc":"ilotier","lemma":"îlotier","pos":"NOM"} ,
		{"word":"îlots","word_nosc":"ilots","lemma":"îlot","pos":"NOM"}
	]
}