'use strict';

module.exports = {
    lexi: [
		    {"word":"a capella","word_nosc":"a capella","lemma":"a capella","pos":"ADV"} ,
		{"word":"a cappella","word_nosc":"a cappella","lemma":"a cappella","pos":"ADV"} ,
		{"word":"a contrario","word_nosc":"a contrario","lemma":"a contrario","pos":"ADV"} ,
		{"word":"a fortiori","word_nosc":"a fortiori","lemma":"a fortiori","pos":"ADV"} ,
		{"word":"a giorno","word_nosc":"a giorno","lemma":"a giorno","pos":"ADV"} ,
		{"word":"a jeun","word_nosc":"a jeun","lemma":"à jeun","pos":"ADV"} ,
		{"word":"a posteriori","word_nosc":"a posteriori","lemma":"a posteriori","pos":"ADV"} ,
		{"word":"a priori","word_nosc":"a priori","lemma":"a priori","pos":"ADV"} ,
		{"word":"ab absurdo","word_nosc":"ab absurdo","lemma":"ab absurdo","pos":"ADV"} ,
		{"word":"ab initio","word_nosc":"ab initio","lemma":"ab initio","pos":"ADV"} ,
		{"word":"ab ovo","word_nosc":"ab ovo","lemma":"ab ovo","pos":"ADV"} ,
		{"word":"abjectement","word_nosc":"abjectement","lemma":"abjectement","pos":"ADV"} ,
		{"word":"abominablement","word_nosc":"abominablement","lemma":"abominablement","pos":"ADV"} ,
		{"word":"abondamment","word_nosc":"abondamment","lemma":"abondamment","pos":"ADV"} ,
		{"word":"abruptement","word_nosc":"abruptement","lemma":"abruptement","pos":"ADV"} ,
		{"word":"absolument","word_nosc":"absolument","lemma":"absolument","pos":"ADV"} ,
		{"word":"abstraitement","word_nosc":"abstraitement","lemma":"abstraitement","pos":"ADV"} ,
		{"word":"absurdement","word_nosc":"absurdement","lemma":"absurdement","pos":"ADV"} ,
		{"word":"abusivement","word_nosc":"abusivement","lemma":"abusivement","pos":"ADV"} ,
		{"word":"académiquement","word_nosc":"academiquement","lemma":"académiquement","pos":"ADV"} ,
		{"word":"accelerando","word_nosc":"accelerando","lemma":"accelerando","pos":"ADV"} ,
		{"word":"accessoirement","word_nosc":"accessoirement","lemma":"accessoirement","pos":"ADV"} ,
		{"word":"accidentellement","word_nosc":"accidentellement","lemma":"accidentellement","pos":"ADV"} ,
		{"word":"acrimonieusement","word_nosc":"acrimonieusement","lemma":"acrimonieusement","pos":"ADV"} ,
		{"word":"acrobatiquement","word_nosc":"acrobatiquement","lemma":"acrobatiquement","pos":"ADV"} ,
		{"word":"activement","word_nosc":"activement","lemma":"activement","pos":"ADV"} ,
		{"word":"actuellement","word_nosc":"actuellement","lemma":"actuellement","pos":"ADV"} ,
		{"word":"ad infinitum","word_nosc":"ad infinitum","lemma":"ad infinitum","pos":"ADV"} ,
		{"word":"ad libitum","word_nosc":"ad libitum","lemma":"ad libitum","pos":"ADV"} ,
		{"word":"ad limina","word_nosc":"ad limina","lemma":"ad limina","pos":"ADV"} ,
		{"word":"ad majorem dei gloriam","word_nosc":"ad majorem dei gloriam","lemma":"ad majorem dei gloriam","pos":"ADV"} ,
		{"word":"ad nutum","word_nosc":"ad nutum","lemma":"ad nutum","pos":"ADV"} ,
		{"word":"ad patres","word_nosc":"ad patres","lemma":"ad patres","pos":"ADV"} ,
		{"word":"ad vitam aeternam","word_nosc":"ad vitam aeternam","lemma":"ad vitam aeternam","pos":"ADV"} ,
		{"word":"adagio","word_nosc":"adagio","lemma":"adagio","pos":"ADV"} ,
		{"word":"administrativement","word_nosc":"administrativement","lemma":"administrativement","pos":"ADV"} ,
		{"word":"admirablement","word_nosc":"admirablement","lemma":"admirablement","pos":"ADV"} ,
		{"word":"admirativement","word_nosc":"admirativement","lemma":"admirativement","pos":"ADV"} ,
		{"word":"adonc","word_nosc":"adonc","lemma":"adonc","pos":"ADV"} ,
		{"word":"adoncques","word_nosc":"adoncques","lemma":"adoncques","pos":"ADV"} ,
		{"word":"adorablement","word_nosc":"adorablement","lemma":"adorablement","pos":"ADV"} ,
		{"word":"adroitement","word_nosc":"adroitement","lemma":"adroitement","pos":"ADV"} ,
		{"word":"adéquatement","word_nosc":"adequatement","lemma":"adéquatement","pos":"ADV"} ,
		{"word":"affablement","word_nosc":"affablement","lemma":"affablement","pos":"ADV"} ,
		{"word":"affectivement","word_nosc":"affectivement","lemma":"affectivement","pos":"ADV"} ,
		{"word":"affectueusement","word_nosc":"affectueusement","lemma":"affectueusement","pos":"ADV"} ,
		{"word":"affirmativement","word_nosc":"affirmativement","lemma":"affirmativement","pos":"ADV"} ,
		{"word":"affreusement","word_nosc":"affreusement","lemma":"affreusement","pos":"ADV"} ,
		{"word":"afin","word_nosc":"afin","lemma":"afin","pos":"ADV"} ,
		{"word":"agitato","word_nosc":"agitato","lemma":"agitato","pos":"ADV"} ,
		{"word":"aglagla","word_nosc":"aglagla","lemma":"aglagla","pos":"ADV"} ,
		{"word":"agressivement","word_nosc":"agressivement","lemma":"agressivement","pos":"ADV"} ,
		{"word":"agréablement","word_nosc":"agreablement","lemma":"agréablement","pos":"ADV"} ,
		{"word":"ahi","word_nosc":"ahi","lemma":"ahi","pos":"ADV"} ,
		{"word":"ahou","word_nosc":"ahou","lemma":"ahou","pos":"ADV"} ,
		{"word":"aigrement","word_nosc":"aigrement","lemma":"aigrement","pos":"ADV"} ,
		{"word":"ailleurs","word_nosc":"ailleurs","lemma":"ailleurs","pos":"ADV"} ,
		{"word":"aimablement","word_nosc":"aimablement","lemma":"aimablement","pos":"ADV"} ,
		{"word":"ainsi","word_nosc":"ainsi","lemma":"ainsi","pos":"ADV"} ,
		{"word":"aisément","word_nosc":"aisement","lemma":"aisément","pos":"ADV"} ,
		{"word":"al dente","word_nosc":"al dente","lemma":"al dente","pos":"ADV"} ,
		{"word":"alea jacta est","word_nosc":"alea jacta est","lemma":"alea jacta est","pos":"ADV"} ,
		{"word":"alentour","word_nosc":"alentour","lemma":"alentour","pos":"ADV"} ,
		{"word":"alerte","word_nosc":"alerte","lemma":"alerte","pos":"ADV"} ,
		{"word":"alertement","word_nosc":"alertement","lemma":"alertement","pos":"ADV"} ,
		{"word":"algébriquement","word_nosc":"algebriquement","lemma":"algébriquement","pos":"ADV"} ,
		{"word":"alias","word_nosc":"alias","lemma":"alias","pos":"ADV"} ,
		{"word":"all right","word_nosc":"all right","lemma":"all right","pos":"ADV"} ,
		{"word":"allegretto","word_nosc":"allegretto","lemma":"allegretto","pos":"ADV"} ,
		{"word":"allegro","word_nosc":"allegro","lemma":"allegro","pos":"ADV"} ,
		{"word":"allusivement","word_nosc":"allusivement","lemma":"allusivement","pos":"ADV"} ,
		{"word":"allègrement","word_nosc":"allegrement","lemma":"allègrement","pos":"ADV"} ,
		{"word":"allégoriquement","word_nosc":"allegoriquement","lemma":"allégoriquement","pos":"ADV"} ,
		{"word":"allégrement","word_nosc":"allegrement","lemma":"allégrement","pos":"ADV"} ,
		{"word":"alors","word_nosc":"alors","lemma":"alors","pos":"ADV"} ,
		{"word":"alphabétiquement","word_nosc":"alphabetiquement","lemma":"alphabétiquement","pos":"ADV"} ,
		{"word":"alter","word_nosc":"alter","lemma":"alter","pos":"ADV"} ,
		{"word":"alternativement","word_nosc":"alternativement","lemma":"alternativement","pos":"ADV"} ,
		{"word":"altièrement","word_nosc":"altierement","lemma":"altièrement","pos":"ADV"} ,
		{"word":"aléatoirement","word_nosc":"aleatoirement","lemma":"aléatoirement","pos":"ADV"} ,
		{"word":"amicalement","word_nosc":"amicalement","lemma":"amicalement","pos":"ADV"} ,
		{"word":"amoroso","word_nosc":"amoroso","lemma":"amoroso","pos":"ADV"} ,
		{"word":"amoureusement","word_nosc":"amoureusement","lemma":"amoureusement","pos":"ADV"} ,
		{"word":"amplement","word_nosc":"amplement","lemma":"amplement","pos":"ADV"} ,
		{"word":"amèrement","word_nosc":"amerement","lemma":"amèrement","pos":"ADV"} ,
		{"word":"anachroniquement","word_nosc":"anachroniquement","lemma":"anachroniquement","pos":"ADV"} ,
		{"word":"analytiquement","word_nosc":"analytiquement","lemma":"analytiquement","pos":"ADV"} ,
		{"word":"anaphoriquement","word_nosc":"anaphoriquement","lemma":"anaphoriquement","pos":"ADV"} ,
		{"word":"anarchiquement","word_nosc":"anarchiquement","lemma":"anarchiquement","pos":"ADV"} ,
		{"word":"anatomiquement","word_nosc":"anatomiquement","lemma":"anatomiquement","pos":"ADV"} ,
		{"word":"ancestralement","word_nosc":"ancestralement","lemma":"ancestralement","pos":"ADV"} ,
		{"word":"anch'io son pittore","word_nosc":"anch'io son pittore","lemma":"anch'io son pittore","pos":"ADV"} ,
		{"word":"anciennement","word_nosc":"anciennement","lemma":"anciennement","pos":"ADV"} ,
		{"word":"andante","word_nosc":"andante","lemma":"andante","pos":"ADV"} ,
		{"word":"anecdotiquement","word_nosc":"anecdotiquement","lemma":"anecdotiquement","pos":"ADV"} ,
		{"word":"angéliquement","word_nosc":"angeliquement","lemma":"angéliquement","pos":"ADV"} ,
		{"word":"animalement","word_nosc":"animalement","lemma":"animalement","pos":"ADV"} ,
		{"word":"annuellement","word_nosc":"annuellement","lemma":"annuellement","pos":"ADV"} ,
		{"word":"anonymement","word_nosc":"anonymement","lemma":"anonymement","pos":"ADV"} ,
		{"word":"anormalement","word_nosc":"anormalement","lemma":"anormalement","pos":"ADV"} ,
		{"word":"anthropologiquement","word_nosc":"anthropologiquement","lemma":"anthropologiquement","pos":"ADV"} ,
		{"word":"anti","word_nosc":"anti","lemma":"anti","pos":"ADV"} ,
		{"word":"anticonstitutionnellement","word_nosc":"anticonstitutionnellement","lemma":"anticonstitutionnellement","pos":"ADV"} ,
		{"word":"antérieurement","word_nosc":"anterieurement","lemma":"antérieurement","pos":"ADV"} ,
		{"word":"anxieusement","word_nosc":"anxieusement","lemma":"anxieusement","pos":"ADV"} ,
		{"word":"apparemment","word_nosc":"apparemment","lemma":"apparemment","pos":"ADV"} ,
		{"word":"approbativement","word_nosc":"approbativement","lemma":"approbativement","pos":"ADV"} ,
		{"word":"approximativement","word_nosc":"approximativement","lemma":"approximativement","pos":"ADV"} ,
		{"word":"après","word_nosc":"apres","lemma":"après","pos":"ADV"} ,
		{"word":"après-demain","word_nosc":"apres-demain","lemma":"après-demain","pos":"ADV"} ,
		{"word":"arbitrairement","word_nosc":"arbitrairement","lemma":"arbitrairement","pos":"ADV"} ,
		{"word":"architecturalement","word_nosc":"architecturalement","lemma":"architecturalement","pos":"ADV"} ,
		{"word":"ardemment","word_nosc":"ardemment","lemma":"ardemment","pos":"ADV"} ,
		{"word":"ardito","word_nosc":"ardito","lemma":"ardito","pos":"ADV"} ,
		{"word":"ardûment","word_nosc":"ardument","lemma":"ardûment","pos":"ADV"} ,
		{"word":"aristocratiquement","word_nosc":"aristocratiquement","lemma":"aristocratiquement","pos":"ADV"} ,
		{"word":"arithmétiquement","word_nosc":"arithmetiquement","lemma":"arithmétiquement","pos":"ADV"} ,
		{"word":"arrière","word_nosc":"arriere","lemma":"arrière","pos":"ADV"} ,
		{"word":"arrogamment","word_nosc":"arrogamment","lemma":"arrogamment","pos":"ADV"} ,
		{"word":"artificiellement","word_nosc":"artificiellement","lemma":"artificiellement","pos":"ADV"} ,
		{"word":"artisanalement","word_nosc":"artisanalement","lemma":"artisanalement","pos":"ADV"} ,
		{"word":"artistement","word_nosc":"artistement","lemma":"artistement","pos":"ADV"} ,
		{"word":"artistiquement","word_nosc":"artistiquement","lemma":"artistiquement","pos":"ADV"} ,
		{"word":"assez","word_nosc":"assez","lemma":"assez","pos":"ADV"} ,
		{"word":"assidûment","word_nosc":"assidument","lemma":"assidûment","pos":"ADV"} ,
		{"word":"assurément","word_nosc":"assurement","lemma":"assurément","pos":"ADV"} ,
		{"word":"assyro","word_nosc":"assyro","lemma":"assyro","pos":"ADV"} ,
		{"word":"astro","word_nosc":"astro","lemma":"astro","pos":"ADV"} ,
		{"word":"astrologiquement","word_nosc":"astrologiquement","lemma":"astrologiquement","pos":"ADV"} ,
		{"word":"astronomiquement","word_nosc":"astronomiquement","lemma":"astronomiquement","pos":"ADV"} ,
		{"word":"astucieusement","word_nosc":"astucieusement","lemma":"astucieusement","pos":"ADV"} ,
		{"word":"asymétriquement","word_nosc":"asymetriquement","lemma":"asymétriquement","pos":"ADV"} ,
		{"word":"atrocement","word_nosc":"atrocement","lemma":"atrocement","pos":"ADV"} ,
		{"word":"attentivement","word_nosc":"attentivement","lemma":"attentivement","pos":"ADV"} ,
		{"word":"au-dedans","word_nosc":"au-dedans","lemma":"au-dedans","pos":"ADV"} ,
		{"word":"au-dehors","word_nosc":"au-dehors","lemma":"au-dehors","pos":"ADV"} ,
		{"word":"au-delà","word_nosc":"au-dela","lemma":"au-delà","pos":"ADV"} ,
		{"word":"au-dessous","word_nosc":"au-dessous","lemma":"au-dessous","pos":"ADV"} ,
		{"word":"au-dessus","word_nosc":"au-dessus","lemma":"au-dessus","pos":"ADV"} ,
		{"word":"au-devant","word_nosc":"au-devant","lemma":"au-devant","pos":"ADV"} ,
		{"word":"aucunement","word_nosc":"aucunement","lemma":"aucunement","pos":"ADV"} ,
		{"word":"audacieusement","word_nosc":"audacieusement","lemma":"audacieusement","pos":"ADV"} ,
		{"word":"aujourd'hui","word_nosc":"aujourd'hui","lemma":"aujourd'hui","pos":"ADV"} ,
		{"word":"aujourd'hui","word_nosc":"aujourd'hui","lemma":"aujourd'huie","pos":"ADV"} ,
		{"word":"auparavant","word_nosc":"auparavant","lemma":"auparavant","pos":"ADV"} ,
		{"word":"auprès","word_nosc":"aupres","lemma":"auprès","pos":"ADV"} ,
		{"word":"aussi","word_nosc":"aussi","lemma":"aussi","pos":"ADV"} ,
		{"word":"aussitôt","word_nosc":"aussitot","lemma":"aussitôt","pos":"ADV"} ,
		{"word":"austro","word_nosc":"austro","lemma":"austro","pos":"ADV"} ,
		{"word":"austèrement","word_nosc":"austerement","lemma":"austèrement","pos":"ADV"} ,
		{"word":"autant","word_nosc":"autant","lemma":"autant","pos":"ADV"} ,
		{"word":"authentiquement","word_nosc":"authentiquement","lemma":"authentiquement","pos":"ADV"} ,
		{"word":"automatico","word_nosc":"automatico","lemma":"automatico","pos":"ADV"} ,
		{"word":"automatiquement","word_nosc":"automatiquement","lemma":"automatiquement","pos":"ADV"} ,
		{"word":"autoritairement","word_nosc":"autoritairement","lemma":"autoritairement","pos":"ADV"} ,
		{"word":"autour","word_nosc":"autour","lemma":"autour","pos":"ADV"} ,
		{"word":"autre","word_nosc":"autre","lemma":"autre","pos":"ADV"} ,
		{"word":"autrefois","word_nosc":"autrefois","lemma":"autrefois","pos":"ADV"} ,
		{"word":"autrement","word_nosc":"autrement","lemma":"autrement","pos":"ADV"} ,
		{"word":"autres","word_nosc":"autres","lemma":"autres","pos":"ADV"} ,
		{"word":"aux aguets","word_nosc":"aux aguets","lemma":"aux aguets","pos":"ADV"} ,
		{"word":"avant","word_nosc":"avant","lemma":"avant","pos":"ADV"} ,
		{"word":"avant-hier","word_nosc":"avant-hier","lemma":"avant-hier","pos":"ADV"} ,
		{"word":"avantageusement","word_nosc":"avantageusement","lemma":"avantageusement","pos":"ADV"} ,
		{"word":"avarement","word_nosc":"avarement","lemma":"avarement","pos":"ADV"} ,
		{"word":"avaricieusement","word_nosc":"avaricieusement","lemma":"avaricieusement","pos":"ADV"} ,
		{"word":"aveuglément","word_nosc":"aveuglement","lemma":"aveuglément","pos":"ADV"} ,
		{"word":"avidement","word_nosc":"avidement","lemma":"avidement","pos":"ADV"} ,
		{"word":"balistiquement","word_nosc":"balistiquement","lemma":"balistiquement","pos":"ADV"} ,
		{"word":"balto","word_nosc":"balto","lemma":"balto","pos":"ADV"} ,
		{"word":"banalement","word_nosc":"banalement","lemma":"banalement","pos":"ADV"} ,
		{"word":"barbarement","word_nosc":"barbarement","lemma":"barbarement","pos":"ADV"} ,
		{"word":"barca","word_nosc":"barca","lemma":"barca","pos":"ADV"} ,
		{"word":"bas","word_nosc":"bas","lemma":"bas","pos":"ADV"} ,
		{"word":"bassement","word_nosc":"bassement","lemma":"bassement","pos":"ADV"} ,
		{"word":"beau","word_nosc":"beau","lemma":"beau","pos":"ADV"} ,
		{"word":"beaucoup","word_nosc":"beaucoup","lemma":"beaucoup","pos":"ADV"} ,
		{"word":"bellement","word_nosc":"bellement","lemma":"bellement","pos":"ADV"} ,
		{"word":"belliqueusement","word_nosc":"belliqueusement","lemma":"belliqueusement","pos":"ADV"} ,
		{"word":"ben","word_nosc":"ben","lemma":"ben","pos":"ADV"} ,
		{"word":"benoîtement","word_nosc":"benoitement","lemma":"benoîtement","pos":"ADV"} ,
		{"word":"besef","word_nosc":"besef","lemma":"besef","pos":"ADV"} ,
		{"word":"bestialement","word_nosc":"bestialement","lemma":"bestialement","pos":"ADV"} ,
		{"word":"beuh","word_nosc":"beuh","lemma":"beuh","pos":"ADV"} ,
		{"word":"bezef","word_nosc":"bezef","lemma":"bezef","pos":"ADV"} ,
		{"word":"bibliquement","word_nosc":"bibliquement","lemma":"bibliquement","pos":"ADV"} ,
		{"word":"bien","word_nosc":"bien","lemma":"bien","pos":"ADV"} ,
		{"word":"bienheureusement","word_nosc":"bienheureusement","lemma":"bienheureusement","pos":"ADV"} ,
		{"word":"bientôt","word_nosc":"bientot","lemma":"bientôt","pos":"ADV"} ,
		{"word":"bienveillamment","word_nosc":"bienveillamment","lemma":"bienveillamment","pos":"ADV"} ,
		{"word":"bigrement","word_nosc":"bigrement","lemma":"bigrement","pos":"ADV"} ,
		{"word":"bilatéralement","word_nosc":"bilateralement","lemma":"bilatéralement","pos":"ADV"} ,
		{"word":"biochimiquement","word_nosc":"biochimiquement","lemma":"biochimiquement","pos":"ADV"} ,
		{"word":"biologiquement","word_nosc":"biologiquement","lemma":"biologiquement","pos":"ADV"} ,
		{"word":"bis","word_nosc":"bis","lemma":"bis","pos":"ADV"} ,
		{"word":"bizarrement","word_nosc":"bizarrement","lemma":"bizarrement","pos":"ADV"} ,
		{"word":"bon","word_nosc":"bon","lemma":"bon","pos":"ADV"} ,
		{"word":"bonassement","word_nosc":"bonassement","lemma":"bonassement","pos":"ADV"} ,
		{"word":"bonnement","word_nosc":"bonnement","lemma":"bonnement","pos":"ADV"} ,
		{"word":"bons-cadeaux","word_nosc":"bons-cadeaux","lemma":"bon-cadeaux","pos":"ADV"} ,
		{"word":"botaniquement","word_nosc":"botaniquement","lemma":"botaniquement","pos":"ADV"} ,
		{"word":"boudi","word_nosc":"boudi","lemma":"boudi","pos":"ADV"} ,
		{"word":"bouffonnement","word_nosc":"bouffonnement","lemma":"bouffonnement","pos":"ADV"} ,
		{"word":"bougrement","word_nosc":"bougrement","lemma":"bougrement","pos":"ADV"} ,
		{"word":"bourgeoisement","word_nosc":"bourgeoisement","lemma":"bourgeoisement","pos":"ADV"} ,
		{"word":"bravement","word_nosc":"bravement","lemma":"bravement","pos":"ADV"} ,
		{"word":"bref","word_nosc":"bref","lemma":"bref","pos":"ADV"} ,
		{"word":"bric et de broc","word_nosc":"bric et de broc","lemma":"bric et de broc","pos":"ADV"} ,
		{"word":"brillamment","word_nosc":"brillamment","lemma":"brillamment","pos":"ADV"} ,
		{"word":"brièvement","word_nosc":"brievement","lemma":"brièvement","pos":"ADV"} ,
		{"word":"brusquement","word_nosc":"brusquement","lemma":"brusquement","pos":"ADV"} ,
		{"word":"brut","word_nosc":"brut","lemma":"brut","pos":"ADV"} ,
		{"word":"brutalement","word_nosc":"brutalement","lemma":"brutalement","pos":"ADV"} ,
		{"word":"bruyamment","word_nosc":"bruyamment","lemma":"bruyamment","pos":"ADV"} ,
		{"word":"budgétairement","word_nosc":"budgetairement","lemma":"budgétairement","pos":"ADV"} ,
		{"word":"bulgaro","word_nosc":"bulgaro","lemma":"bulgaro","pos":"ADV"} ,
		{"word":"bureaucratiquement","word_nosc":"bureaucratiquement","lemma":"bureaucratiquement","pos":"ADV"} ,
		{"word":"béatement","word_nosc":"beatement","lemma":"béatement","pos":"ADV"} ,
		{"word":"bénignement","word_nosc":"benignement","lemma":"bénignement","pos":"ADV"} ,
		{"word":"bénévolement","word_nosc":"benevolement","lemma":"bénévolement","pos":"ADV"} ,
		{"word":"bésef","word_nosc":"besef","lemma":"bésef","pos":"ADV"} ,
		{"word":"bézef","word_nosc":"bezef","lemma":"bézef","pos":"ADV"} ,
		{"word":"bêta","word_nosc":"beta","lemma":"bêta","pos":"ADV"} ,
		{"word":"bêtement","word_nosc":"betement","lemma":"bêtement","pos":"ADV"} ,
		{"word":"c'est-à-dire","word_nosc":"c'est-a-dire","lemma":"c'est-à-dire","pos":"ADV"} ,
		{"word":"c'est-à-dire","word_nosc":"c'est-a-dire","lemma":"c'est-à-diree","pos":"ADV"} ,
		{"word":"caf","word_nosc":"caf","lemma":"caf","pos":"ADV"} ,
		{"word":"cahin-caha","word_nosc":"cahin-caha","lemma":"cahin-caha","pos":"ADV"} ,
		{"word":"califourchon","word_nosc":"califourchon","lemma":"califourchon","pos":"ADV"} ,
		{"word":"calligraphiquement","word_nosc":"calligraphiquement","lemma":"calligraphiquement","pos":"ADV"} ,
		{"word":"calmement","word_nosc":"calmement","lemma":"calmement","pos":"ADV"} ,
		{"word":"canado","word_nosc":"canado","lemma":"canado","pos":"ADV"} ,
		{"word":"canaillement","word_nosc":"canaillement","lemma":"canaillement","pos":"ADV"} ,
		{"word":"candidement","word_nosc":"candidement","lemma":"candidement","pos":"ADV"} ,
		{"word":"canoniquement","word_nosc":"canoniquement","lemma":"canoniquement","pos":"ADV"} ,
		{"word":"capitalement","word_nosc":"capitalement","lemma":"capitalement","pos":"ADV"} ,
		{"word":"capricieusement","word_nosc":"capricieusement","lemma":"capricieusement","pos":"ADV"} ,
		{"word":"carrément","word_nosc":"carrement","lemma":"carrément","pos":"ADV"} ,
		{"word":"cash","word_nosc":"cash","lemma":"cash","pos":"ADV"} ,
		{"word":"catastrophiquement","word_nosc":"catastrophiquement","lemma":"catastrophiquement","pos":"ADV"} ,
		{"word":"catholiquement","word_nosc":"catholiquement","lemma":"catholiquement","pos":"ADV"} ,
		{"word":"catégoriquement","word_nosc":"categoriquement","lemma":"catégoriquement","pos":"ADV"} ,
		{"word":"cauteleusement","word_nosc":"cauteleusement","lemma":"cauteleusement","pos":"ADV"} ,
		{"word":"cavalièrement","word_nosc":"cavalierement","lemma":"cavalièrement","pos":"ADV"} ,
		{"word":"cellulairement","word_nosc":"cellulairement","lemma":"cellulairement","pos":"ADV"} ,
		{"word":"censément","word_nosc":"censement","lemma":"censément","pos":"ADV"} ,
		{"word":"cependant","word_nosc":"cependant","lemma":"cependant","pos":"ADV"} ,
		{"word":"certainement","word_nosc":"certainement","lemma":"certainement","pos":"ADV"} ,
		{"word":"certes","word_nosc":"certes","lemma":"certes","pos":"ADV"} ,
		{"word":"chaleureusement","word_nosc":"chaleureusement","lemma":"chaleureusement","pos":"ADV"} ,
		{"word":"chao","word_nosc":"chao","lemma":"chao","pos":"ADV"} ,
		{"word":"chaotiquement","word_nosc":"chaotiquement","lemma":"chaotiquement","pos":"ADV"} ,
		{"word":"charitablement","word_nosc":"charitablement","lemma":"charitablement","pos":"ADV"} ,
		{"word":"charnellement","word_nosc":"charnellement","lemma":"charnellement","pos":"ADV"} ,
		{"word":"chastement","word_nosc":"chastement","lemma":"chastement","pos":"ADV"} ,
		{"word":"chaud","word_nosc":"chaud","lemma":"chaud","pos":"ADV"} ,
		{"word":"chaudement","word_nosc":"chaudement","lemma":"chaudement","pos":"ADV"} ,
		{"word":"cher","word_nosc":"cher","lemma":"cher","pos":"ADV"} ,
		{"word":"chevaleresquement","word_nosc":"chevaleresquement","lemma":"chevaleresquement","pos":"ADV"} ,
		{"word":"chic","word_nosc":"chic","lemma":"chic","pos":"ADV"} ,
		{"word":"chichement","word_nosc":"chichement","lemma":"chichement","pos":"ADV"} ,
		{"word":"chimiquement","word_nosc":"chimiquement","lemma":"chimiquement","pos":"ADV"} ,
		{"word":"chimériquement","word_nosc":"chimeriquement","lemma":"chimériquement","pos":"ADV"} ,
		{"word":"chiquement","word_nosc":"chiquement","lemma":"chiquement","pos":"ADV"} ,
		{"word":"chirurgicalement","word_nosc":"chirurgicalement","lemma":"chirurgicalement","pos":"ADV"} ,
		{"word":"chouette","word_nosc":"chouette","lemma":"chouette","pos":"ADV"} ,
		{"word":"chouettement","word_nosc":"chouettement","lemma":"chouettement","pos":"ADV"} ,
		{"word":"chouïa","word_nosc":"chouia","lemma":"chouïa","pos":"ADV"} ,
		{"word":"chroniquement","word_nosc":"chroniquement","lemma":"chroniquement","pos":"ADV"} ,
		{"word":"chronologiquement","word_nosc":"chronologiquement","lemma":"chronologiquement","pos":"ADV"} ,
		{"word":"chrétiennement","word_nosc":"chretiennement","lemma":"chrétiennement","pos":"ADV"} ,
		{"word":"chèrement","word_nosc":"cherement","lemma":"chèrement","pos":"ADV"} ,
		{"word":"chétivement","word_nosc":"chetivement","lemma":"chétivement","pos":"ADV"} ,
		{"word":"ci","word_nosc":"ci","lemma":"ci","pos":"ADV"} ,
		{"word":"ci-après","word_nosc":"ci-apres","lemma":"ci-après","pos":"ADV"} ,
		{"word":"ci-contre","word_nosc":"ci-contre","lemma":"ci-contre","pos":"ADV"} ,
		{"word":"ci-dessous","word_nosc":"ci-dessous","lemma":"ci-dessous","pos":"ADV"} ,
		{"word":"ci-dessus","word_nosc":"ci-dessus","lemma":"ci-dessus","pos":"ADV"} ,
		{"word":"ci-gît","word_nosc":"ci-git","lemma":"ci-gît","pos":"ADV"} ,
		{"word":"cinquièmement","word_nosc":"cinquiemement","lemma":"cinquièmement","pos":"ADV"} ,
		{"word":"cinématographiquement","word_nosc":"cinematographiquement","lemma":"cinématographiquement","pos":"ADV"} ,
		{"word":"circulairement","word_nosc":"circulairement","lemma":"circulairement","pos":"ADV"} ,
		{"word":"civilement","word_nosc":"civilement","lemma":"civilement","pos":"ADV"} ,
		{"word":"clairement","word_nosc":"clairement","lemma":"clairement","pos":"ADV"} ,
		{"word":"clandestinement","word_nosc":"clandestinement","lemma":"clandestinement","pos":"ADV"} ,
		{"word":"classico","word_nosc":"classico","lemma":"classico","pos":"ADV"} ,
		{"word":"classiquement","word_nosc":"classiquement","lemma":"classiquement","pos":"ADV"} ,
		{"word":"cliniquement","word_nosc":"cliniquement","lemma":"cliniquement","pos":"ADV"} ,
		{"word":"clopin-clopant","word_nosc":"clopin-clopant","lemma":"clopin-clopant","pos":"ADV"} ,
		{"word":"co","word_nosc":"co","lemma":"co","pos":"ADV"} ,
		{"word":"cocassement","word_nosc":"cocassement","lemma":"cocassement","pos":"ADV"} ,
		{"word":"collectivement","word_nosc":"collectivement","lemma":"collectivement","pos":"ADV"} ,
		{"word":"coléreusement","word_nosc":"colereusement","lemma":"coléreusement","pos":"ADV"} ,
		{"word":"combien","word_nosc":"combien","lemma":"combien","pos":"ADV"} ,
		{"word":"comiquement","word_nosc":"comiquement","lemma":"comiquement","pos":"ADV"} ,
		{"word":"comme","word_nosc":"comme","lemma":"comme","pos":"ADV"} ,
		{"word":"comment","word_nosc":"comment","lemma":"comment","pos":"ADV"} ,
		{"word":"commercialement","word_nosc":"commercialement","lemma":"commercialement","pos":"ADV"} ,
		{"word":"commodément","word_nosc":"commodement","lemma":"commodément","pos":"ADV"} ,
		{"word":"communément","word_nosc":"communement","lemma":"communément","pos":"ADV"} ,
		{"word":"comparativement","word_nosc":"comparativement","lemma":"comparativement","pos":"ADV"} ,
		{"word":"complaisamment","word_nosc":"complaisamment","lemma":"complaisamment","pos":"ADV"} ,
		{"word":"complètement","word_nosc":"completement","lemma":"complètement","pos":"ADV"} ,
		{"word":"complémentairement","word_nosc":"complementairement","lemma":"complémentairement","pos":"ADV"} ,
		{"word":"compulsivement","word_nosc":"compulsivement","lemma":"compulsivement","pos":"ADV"} ,
		{"word":"conceptuellement","word_nosc":"conceptuellement","lemma":"conceptuellement","pos":"ADV"} ,
		{"word":"concrètement","word_nosc":"concretement","lemma":"concrètement","pos":"ADV"} ,
		{"word":"concurremment","word_nosc":"concurremment","lemma":"concurremment","pos":"ADV"} ,
		{"word":"conditionnellement","word_nosc":"conditionnellement","lemma":"conditionnellement","pos":"ADV"} ,
		{"word":"confidentiellement","word_nosc":"confidentiellement","lemma":"confidentiellement","pos":"ADV"} ,
		{"word":"conformément","word_nosc":"conformement","lemma":"conformément","pos":"ADV"} ,
		{"word":"confortablement","word_nosc":"confortablement","lemma":"confortablement","pos":"ADV"} ,
		{"word":"confraternellement","word_nosc":"confraternellement","lemma":"confraternellement","pos":"ADV"} ,
		{"word":"confusément","word_nosc":"confusement","lemma":"confusément","pos":"ADV"} ,
		{"word":"congrûment","word_nosc":"congrument","lemma":"congrûment","pos":"ADV"} ,
		{"word":"congénitalement","word_nosc":"congenitalement","lemma":"congénitalement","pos":"ADV"} ,
		{"word":"conjointement","word_nosc":"conjointement","lemma":"conjointement","pos":"ADV"} ,
		{"word":"conjugalement","word_nosc":"conjugalement","lemma":"conjugalement","pos":"ADV"} ,
		{"word":"connement","word_nosc":"connement","lemma":"connement","pos":"ADV"} ,
		{"word":"consciemment","word_nosc":"consciemment","lemma":"consciemment","pos":"ADV"} ,
		{"word":"consciencieusement","word_nosc":"consciencieusement","lemma":"consciencieusement","pos":"ADV"} ,
		{"word":"considérablement","word_nosc":"considerablement","lemma":"considérablement","pos":"ADV"} ,
		{"word":"constamment","word_nosc":"constamment","lemma":"constamment","pos":"ADV"} ,
		{"word":"constitutionnellement","word_nosc":"constitutionnellement","lemma":"constitutionnellement","pos":"ADV"} ,
		{"word":"consécutivement","word_nosc":"consecutivement","lemma":"consécutivement","pos":"ADV"} ,
		{"word":"conséquemment","word_nosc":"consequemment","lemma":"conséquemment","pos":"ADV"} ,
		{"word":"contemplativement","word_nosc":"contemplativement","lemma":"contemplativement","pos":"ADV"} ,
		{"word":"continuellement","word_nosc":"continuellement","lemma":"continuellement","pos":"ADV"} ,
		{"word":"continûment","word_nosc":"continument","lemma":"continûment","pos":"ADV"} ,
		{"word":"contractuellement","word_nosc":"contractuellement","lemma":"contractuellement","pos":"ADV"} ,
		{"word":"contradictoirement","word_nosc":"contradictoirement","lemma":"contradictoirement","pos":"ADV"} ,
		{"word":"contrairement","word_nosc":"contrairement","lemma":"contrairement","pos":"ADV"} ,
		{"word":"contrebas","word_nosc":"contrebas","lemma":"contrebas","pos":"ADV"} ,
		{"word":"convenablement","word_nosc":"convenablement","lemma":"convenablement","pos":"ADV"} ,
		{"word":"conventionnellement","word_nosc":"conventionnellement","lemma":"conventionnellement","pos":"ADV"} ,
		{"word":"conventuellement","word_nosc":"conventuellement","lemma":"conventuellement","pos":"ADV"} ,
		{"word":"convulsivement","word_nosc":"convulsivement","lemma":"convulsivement","pos":"ADV"} ,
		{"word":"copieusement","word_nosc":"copieusement","lemma":"copieusement","pos":"ADV"} ,
		{"word":"coquettement","word_nosc":"coquettement","lemma":"coquettement","pos":"ADV"} ,
		{"word":"coquinement","word_nosc":"coquinement","lemma":"coquinement","pos":"ADV"} ,
		{"word":"cordialement","word_nosc":"cordialement","lemma":"cordialement","pos":"ADV"} ,
		{"word":"correctement","word_nosc":"correctement","lemma":"correctement","pos":"ADV"} ,
		{"word":"corrélativement","word_nosc":"correlativement","lemma":"corrélativement","pos":"ADV"} ,
		{"word":"cosmiquement","word_nosc":"cosmiquement","lemma":"cosmiquement","pos":"ADV"} ,
		{"word":"cosmo","word_nosc":"cosmo","lemma":"cosmo","pos":"ADV"} ,
		{"word":"couci-couça","word_nosc":"couci-couca","lemma":"couci-couça","pos":"ADV"} ,
		{"word":"coupablement","word_nosc":"coupablement","lemma":"coupablement","pos":"ADV"} ,
		{"word":"courageusement","word_nosc":"courageusement","lemma":"courageusement","pos":"ADV"} ,
		{"word":"couramment","word_nosc":"couramment","lemma":"couramment","pos":"ADV"} ,
		{"word":"court","word_nosc":"court","lemma":"court","pos":"ADV"} ,
		{"word":"courtement","word_nosc":"courtement","lemma":"courtement","pos":"ADV"} ,
		{"word":"courtoisement","word_nosc":"courtoisement","lemma":"courtoisement","pos":"ADV"} ,
		{"word":"coutumièrement","word_nosc":"coutumierement","lemma":"coutumièrement","pos":"ADV"} ,
		{"word":"coûteusement","word_nosc":"couteusement","lemma":"coûteusement","pos":"ADV"} ,
		{"word":"craintivement","word_nosc":"craintivement","lemma":"craintivement","pos":"ADV"} ,
		{"word":"crapuleusement","word_nosc":"crapuleusement","lemma":"crapuleusement","pos":"ADV"} ,
		{"word":"crescendo","word_nosc":"crescendo","lemma":"crescendo","pos":"ADV"} ,
		{"word":"criminellement","word_nosc":"criminellement","lemma":"criminellement","pos":"ADV"} ,
		{"word":"croa croa","word_nosc":"croa croa","lemma":"croa-croa","pos":"ADV"} ,
		{"word":"croa-croa","word_nosc":"croa-croa","lemma":"croa-croa","pos":"ADV"} ,
		{"word":"crouille","word_nosc":"crouille","lemma":"crouille","pos":"ADV"} ,
		{"word":"crucialement","word_nosc":"crucialement","lemma":"crucialement","pos":"ADV"} ,
		{"word":"cruellement","word_nosc":"cruellement","lemma":"cruellement","pos":"ADV"} ,
		{"word":"crânement","word_nosc":"cranement","lemma":"crânement","pos":"ADV"} ,
		{"word":"crédié","word_nosc":"credie","lemma":"crédié","pos":"ADV"} ,
		{"word":"crûment","word_nosc":"crument","lemma":"crûment","pos":"ADV"} ,
		{"word":"culturellement","word_nosc":"culturellement","lemma":"culturellement","pos":"ADV"} ,
		{"word":"cupidement","word_nosc":"cupidement","lemma":"cupidement","pos":"ADV"} ,
		{"word":"curieusement","word_nosc":"curieusement","lemma":"curieusement","pos":"ADV"} ,
		{"word":"cycliquement","word_nosc":"cycliquement","lemma":"cycliquement","pos":"ADV"} ,
		{"word":"cyniquement","word_nosc":"cyniquement","lemma":"cyniquement","pos":"ADV"} ,
		{"word":"câlinement","word_nosc":"calinement","lemma":"câlinement","pos":"ADV"} ,
		{"word":"céans","word_nosc":"ceans","lemma":"céans","pos":"ADV"} ,
		{"word":"cérébralement","word_nosc":"cerebralement","lemma":"cérébralement","pos":"ADV"} ,
		{"word":"cérémonieusement","word_nosc":"ceremonieusement","lemma":"cérémonieusement","pos":"ADV"} ,
		{"word":"d'abord","word_nosc":"d'abord","lemma":"d'abord","pos":"ADV"} ,
		{"word":"d'emblée","word_nosc":"d'emblee","lemma":"d'emblée","pos":"ADV"} ,
		{"word":"d'emblée","word_nosc":"d'emblee","lemma":"d'embléee","pos":"ADV"} ,
		{"word":"d'ores et déjà","word_nosc":"d'ores et deja","lemma":"d'ores et déjà","pos":"ADV"} ,
		{"word":"dangereusement","word_nosc":"dangereusement","lemma":"dangereusement","pos":"ADV"} ,
		{"word":"dare dare","word_nosc":"dare dare","lemma":"dare-dare","pos":"ADV"} ,
		{"word":"dare-dare","word_nosc":"dare-dare","lemma":"dare-dare","pos":"ADV"} ,
		{"word":"davantage","word_nosc":"davantage","lemma":"davantage","pos":"ADV"} ,
		{"word":"de auditu","word_nosc":"de auditu","lemma":"de auditu","pos":"ADV"} ,
		{"word":"de facto","word_nosc":"de facto","lemma":"de facto","pos":"ADV"} ,
		{"word":"de guingois","word_nosc":"de guingois","lemma":"de guingois","pos":"ADV"} ,
		{"word":"de plano","word_nosc":"de plano","lemma":"de plano","pos":"ADV"} ,
		{"word":"de traviole","word_nosc":"de traviole","lemma":"de traviole","pos":"ADV"} ,
		{"word":"de visu","word_nosc":"de visu","lemma":"de visu","pos":"ADV"} ,
		{"word":"debout","word_nosc":"debout","lemma":"debout","pos":"ADV"} ,
		{"word":"decrescendo","word_nosc":"decrescendo","lemma":"decrescendo","pos":"ADV"} ,
		{"word":"dedans","word_nosc":"dedans","lemma":"dedans","pos":"ADV"} ,
		{"word":"dehors","word_nosc":"dehors","lemma":"dehors","pos":"ADV"} ,
		{"word":"delà","word_nosc":"dela","lemma":"delà","pos":"ADV"} ,
		{"word":"demain","word_nosc":"demain","lemma":"demain","pos":"ADV"} ,
		{"word":"demi","word_nosc":"demi","lemma":"demi","pos":"ADV"} ,
		{"word":"demi-nu","word_nosc":"demi-nu","lemma":"demi-nu","pos":"ADV"} ,
		{"word":"demi-pas","word_nosc":"demi-pas","lemma":"demi-pas","pos":"ADV"} ,
		{"word":"depuis","word_nosc":"depuis","lemma":"depuis","pos":"ADV"} ,
		{"word":"derechef","word_nosc":"derechef","lemma":"derechef","pos":"ADV"} ,
		{"word":"dernièrement","word_nosc":"dernierement","lemma":"dernièrement","pos":"ADV"} ,
		{"word":"derrière","word_nosc":"derriere","lemma":"derrière","pos":"ADV"} ,
		{"word":"dessous","word_nosc":"dessous","lemma":"dessous","pos":"ADV"} ,
		{"word":"dessus","word_nosc":"dessus","lemma":"dessus","pos":"ADV"} ,
		{"word":"deusio","word_nosc":"deusio","lemma":"deusio","pos":"ADV"} ,
		{"word":"deuxièmement","word_nosc":"deuxiemement","lemma":"deuxièmement","pos":"ADV"} ,
		{"word":"deuzio","word_nosc":"deuzio","lemma":"deuzio","pos":"ADV"} ,
		{"word":"devant","word_nosc":"devant","lemma":"devant","pos":"ADV"} ,
		{"word":"dextrement","word_nosc":"dextrement","lemma":"dextrement","pos":"ADV"} ,
		{"word":"deçà","word_nosc":"deca","lemma":"deçà","pos":"ADV"} ,
		{"word":"diablement","word_nosc":"diablement","lemma":"diablement","pos":"ADV"} ,
		{"word":"diaboliquement","word_nosc":"diaboliquement","lemma":"diaboliquement","pos":"ADV"} ,
		{"word":"diagonalement","word_nosc":"diagonalement","lemma":"diagonalement","pos":"ADV"} ,
		{"word":"diamétralement","word_nosc":"diametralement","lemma":"diamétralement","pos":"ADV"} ,
		{"word":"didactiquement","word_nosc":"didactiquement","lemma":"didactiquement","pos":"ADV"} ,
		{"word":"difficilement","word_nosc":"difficilement","lemma":"difficilement","pos":"ADV"} ,
		{"word":"différemment","word_nosc":"differemment","lemma":"différemment","pos":"ADV"} ,
		{"word":"dignement","word_nosc":"dignement","lemma":"dignement","pos":"ADV"} ,
		{"word":"dignus est intrare","word_nosc":"dignus est intrare","lemma":"dignus est intrare","pos":"ADV"} ,
		{"word":"diligemment","word_nosc":"diligemment","lemma":"diligemment","pos":"ADV"} ,
		{"word":"diminuendo","word_nosc":"diminuendo","lemma":"diminuendo","pos":"ADV"} ,
		{"word":"diplomatiquement","word_nosc":"diplomatiquement","lemma":"diplomatiquement","pos":"ADV"} ,
		{"word":"directement","word_nosc":"directement","lemma":"directement","pos":"ADV"} ,
		{"word":"directo","word_nosc":"directo","lemma":"directo","pos":"ADV"} ,
		{"word":"directos","word_nosc":"directos","lemma":"directos","pos":"ADV"} ,
		{"word":"discrètement","word_nosc":"discretement","lemma":"discrètement","pos":"ADV"} ,
		{"word":"disgracieusement","word_nosc":"disgracieusement","lemma":"disgracieusement","pos":"ADV"} ,
		{"word":"dispendieusement","word_nosc":"dispendieusement","lemma":"dispendieusement","pos":"ADV"} ,
		{"word":"distinctement","word_nosc":"distinctement","lemma":"distinctement","pos":"ADV"} ,
		{"word":"distraitement","word_nosc":"distraitement","lemma":"distraitement","pos":"ADV"} ,
		{"word":"dito","word_nosc":"dito","lemma":"dito","pos":"ADV"} ,
		{"word":"diversement","word_nosc":"diversement","lemma":"diversement","pos":"ADV"} ,
		{"word":"divinement","word_nosc":"divinement","lemma":"divinement","pos":"ADV"} ,
		{"word":"dixièmement","word_nosc":"dixiemement","lemma":"dixièmement","pos":"ADV"} ,
		{"word":"docilement","word_nosc":"docilement","lemma":"docilement","pos":"ADV"} ,
		{"word":"doctement","word_nosc":"doctement","lemma":"doctement","pos":"ADV"} ,
		{"word":"dolce","word_nosc":"dolce","lemma":"dolce","pos":"ADV"} ,
		{"word":"domestiquement","word_nosc":"domestiquement","lemma":"domestiquement","pos":"ADV"} ,
		{"word":"donnant-donnant","word_nosc":"donnant-donnant","lemma":"donnant-donnant","pos":"ADV"} ,
		{"word":"dorénavant","word_nosc":"dorenavant","lemma":"dorénavant","pos":"ADV"} ,
		{"word":"doublement","word_nosc":"doublement","lemma":"doublement","pos":"ADV"} ,
		{"word":"doucement","word_nosc":"doucement","lemma":"doucement","pos":"ADV"} ,
		{"word":"doucereusement","word_nosc":"doucereusement","lemma":"doucereusement","pos":"ADV"} ,
		{"word":"doucettement","word_nosc":"doucettement","lemma":"doucettement","pos":"ADV"} ,
		{"word":"douillettement","word_nosc":"douillettement","lemma":"douillettement","pos":"ADV"} ,
		{"word":"douloureusement","word_nosc":"douloureusement","lemma":"douloureusement","pos":"ADV"} ,
		{"word":"douteusement","word_nosc":"douteusement","lemma":"douteusement","pos":"ADV"} ,
		{"word":"doux","word_nosc":"doux","lemma":"doux","pos":"ADV"} ,
		{"word":"dramatiquement","word_nosc":"dramatiquement","lemma":"dramatiquement","pos":"ADV"} ,
		{"word":"drastiquement","word_nosc":"drastiquement","lemma":"drastiquement","pos":"ADV"} ,
		{"word":"droit","word_nosc":"droit","lemma":"droit","pos":"ADV"} ,
		{"word":"droitement","word_nosc":"droitement","lemma":"droitement","pos":"ADV"} ,
		{"word":"dru","word_nosc":"dru","lemma":"dru","pos":"ADV"} ,
		{"word":"drôlement","word_nosc":"drolement","lemma":"drôlement","pos":"ADV"} ,
		{"word":"dubitativement","word_nosc":"dubitativement","lemma":"dubitativement","pos":"ADV"} ,
		{"word":"dur","word_nosc":"dur","lemma":"dur","pos":"ADV"} ,
		{"word":"durablement","word_nosc":"durablement","lemma":"durablement","pos":"ADV"} ,
		{"word":"durement","word_nosc":"durement","lemma":"durement","pos":"ADV"} ,
		{"word":"débilement","word_nosc":"debilement","lemma":"débilement","pos":"ADV"} ,
		{"word":"décemment","word_nosc":"decemment","lemma":"décemment","pos":"ADV"} ,
		{"word":"décidément","word_nosc":"decidement","lemma":"décidément","pos":"ADV"} ,
		{"word":"dédaigneusement","word_nosc":"dedaigneusement","lemma":"dédaigneusement","pos":"ADV"} ,
		{"word":"défavorablement","word_nosc":"defavorablement","lemma":"défavorablement","pos":"ADV"} ,
		{"word":"défectueusement","word_nosc":"defectueusement","lemma":"défectueusement","pos":"ADV"} ,
		{"word":"définitivement","word_nosc":"definitivement","lemma":"définitivement","pos":"ADV"} ,
		{"word":"dégueulassement","word_nosc":"degueulassement","lemma":"dégueulassement","pos":"ADV"} ,
		{"word":"déjà","word_nosc":"deja","lemma":"déjà","pos":"ADV"} ,
		{"word":"délibérément","word_nosc":"deliberement","lemma":"délibérément","pos":"ADV"} ,
		{"word":"délicatement","word_nosc":"delicatement","lemma":"délicatement","pos":"ADV"} ,
		{"word":"délicieusement","word_nosc":"delicieusement","lemma":"délicieusement","pos":"ADV"} ,
		{"word":"déloyalement","word_nosc":"deloyalement","lemma":"déloyalement","pos":"ADV"} ,
		{"word":"démesurément","word_nosc":"demesurement","lemma":"démesurément","pos":"ADV"} ,
		{"word":"démocratiquement","word_nosc":"democratiquement","lemma":"démocratiquement","pos":"ADV"} ,
		{"word":"démographiquement","word_nosc":"demographiquement","lemma":"démographiquement","pos":"ADV"} ,
		{"word":"démoniaquement","word_nosc":"demoniaquement","lemma":"démoniaquement","pos":"ADV"} ,
		{"word":"démonstrativement","word_nosc":"demonstrativement","lemma":"démonstrativement","pos":"ADV"} ,
		{"word":"déplorablement","word_nosc":"deplorablement","lemma":"déplorablement","pos":"ADV"} ,
		{"word":"déraisonnablement","word_nosc":"deraisonnablement","lemma":"déraisonnablement","pos":"ADV"} ,
		{"word":"dérisoirement","word_nosc":"derisoirement","lemma":"dérisoirement","pos":"ADV"} ,
		{"word":"désagréablement","word_nosc":"desagreablement","lemma":"désagréablement","pos":"ADV"} ,
		{"word":"désastreusement","word_nosc":"desastreusement","lemma":"désastreusement","pos":"ADV"} ,
		{"word":"désespérément","word_nosc":"desesperement","lemma":"désespérément","pos":"ADV"} ,
		{"word":"désordonnément","word_nosc":"desordonnement","lemma":"désordonnément","pos":"ADV"} ,
		{"word":"désormais","word_nosc":"desormais","lemma":"désormais","pos":"ADV"} ,
		{"word":"déterminément","word_nosc":"determinement","lemma":"déterminément","pos":"ADV"} ,
		{"word":"détestablement","word_nosc":"detestablement","lemma":"détestablement","pos":"ADV"} ,
		{"word":"dévotement","word_nosc":"devotement","lemma":"dévotement","pos":"ADV"} ,
		{"word":"dévotieusement","word_nosc":"devotieusement","lemma":"dévotieusement","pos":"ADV"} ,
		{"word":"dûment","word_nosc":"dument","lemma":"dûment","pos":"ADV"} ,
		{"word":"ecce homo","word_nosc":"ecce homo","lemma":"ecce homo","pos":"ADV"} ,
		{"word":"ecsta","word_nosc":"ecsta","lemma":"ecsta","pos":"ADV"} ,
		{"word":"effectivement","word_nosc":"effectivement","lemma":"effectivement","pos":"ADV"} ,
		{"word":"efficacement","word_nosc":"efficacement","lemma":"efficacement","pos":"ADV"} ,
		{"word":"effrayamment","word_nosc":"effrayamment","lemma":"effrayamment","pos":"ADV"} ,
		{"word":"effrontément","word_nosc":"effrontement","lemma":"effrontément","pos":"ADV"} ,
		{"word":"effroyablement","word_nosc":"effroyablement","lemma":"effroyablement","pos":"ADV"} ,
		{"word":"emmi","word_nosc":"emmi","lemma":"emmi","pos":"ADV"} ,
		{"word":"emphatiquement","word_nosc":"emphatiquement","lemma":"emphatiquement","pos":"ADV"} ,
		{"word":"empiriquement","word_nosc":"empiriquement","lemma":"empiriquement","pos":"ADV"} ,
		{"word":"en","word_nosc":"en","lemma":"en","pos":"ADV"} ,
		{"word":"en catimini","word_nosc":"en catimini","lemma":"en catimini","pos":"ADV"} ,
		{"word":"en loucedé","word_nosc":"en loucede","lemma":"en loucedé","pos":"ADV"} ,
		{"word":"en tapinois","word_nosc":"en tapinois","lemma":"en tapinois","pos":"ADV"} ,
		{"word":"encor","word_nosc":"encor","lemma":"encor","pos":"ADV"} ,
		{"word":"encore","word_nosc":"encore","lemma":"encore","pos":"ADV"} ,
		{"word":"enfantinement","word_nosc":"enfantinement","lemma":"enfantinement","pos":"ADV"} ,
		{"word":"enfin","word_nosc":"enfin","lemma":"enfin","pos":"ADV"} ,
		{"word":"ensemble","word_nosc":"ensemble","lemma":"ensemble","pos":"ADV"} ,
		{"word":"ensuite","word_nosc":"ensuite","lemma":"ensuite","pos":"ADV"} ,
		{"word":"entièrement","word_nosc":"entierement","lemma":"entièrement","pos":"ADV"} ,
		{"word":"entre-temps","word_nosc":"entre-temps","lemma":"entre-temps","pos":"ADV"} ,
		{"word":"environ","word_nosc":"environ","lemma":"environ","pos":"ADV"} ,
		{"word":"ergo","word_nosc":"ergo","lemma":"ergo","pos":"ADV"} ,
		{"word":"espièglement","word_nosc":"espieglement","lemma":"espièglement","pos":"ADV"} ,
		{"word":"essentiellement","word_nosc":"essentiellement","lemma":"essentiellement","pos":"ADV"} ,
		{"word":"est-ce","word_nosc":"est-ce","lemma":"est-ce","pos":"ADV"} ,
		{"word":"est-ce qu","word_nosc":"est-ce qu","lemma":"est-ce qu","pos":"ADV"} ,
		{"word":"est-ce que","word_nosc":"est-ce que","lemma":"est-ce que","pos":"ADV"} ,
		{"word":"est-ce-que","word_nosc":"est-ce-que","lemma":"est-ce-que","pos":"ADV"} ,
		{"word":"esthétiquement","word_nosc":"esthetiquement","lemma":"esthétiquement","pos":"ADV"} ,
		{"word":"et caetera","word_nosc":"et caetera","lemma":"et caetera","pos":"ADV"} ,
		{"word":"et cetera","word_nosc":"et cetera","lemma":"et cetera","pos":"ADV"} ,
		{"word":"et seq","word_nosc":"et seq","lemma":"et seq","pos":"ADV"} ,
		{"word":"etc","word_nosc":"etc","lemma":"etc","pos":"ADV"} ,
		{"word":"etc.","word_nosc":"etc.","lemma":"etc.","pos":"ADV"} ,
		{"word":"etcetera","word_nosc":"etcetera","lemma":"etcetera","pos":"ADV"} ,
		{"word":"ethniquement","word_nosc":"ethniquement","lemma":"ethniquement","pos":"ADV"} ,
		{"word":"euphoniquement","word_nosc":"euphoniquement","lemma":"euphoniquement","pos":"ADV"} ,
		{"word":"ex abrupto","word_nosc":"ex abrupto","lemma":"ex abrupto","pos":"ADV"} ,
		{"word":"ex nihilo","word_nosc":"ex nihilo","lemma":"ex nihilo","pos":"ADV"} ,
		{"word":"ex-petit","word_nosc":"ex-petit","lemma":"ex-petit","pos":"ADV"} ,
		{"word":"exactement","word_nosc":"exactement","lemma":"exactement","pos":"ADV"} ,
		{"word":"exagérément","word_nosc":"exagerement","lemma":"exagérément","pos":"ADV"} ,
		{"word":"excellemment","word_nosc":"excellemment","lemma":"excellemment","pos":"ADV"} ,
		{"word":"exceptionnellement","word_nosc":"exceptionnellement","lemma":"exceptionnellement","pos":"ADV"} ,
		{"word":"excessivement","word_nosc":"excessivement","lemma":"excessivement","pos":"ADV"} ,
		{"word":"exclusivement","word_nosc":"exclusivement","lemma":"exclusivement","pos":"ADV"} ,
		{"word":"exemplairement","word_nosc":"exemplairement","lemma":"exemplairement","pos":"ADV"} ,
		{"word":"exhaustivement","word_nosc":"exhaustivement","lemma":"exhaustivement","pos":"ADV"} ,
		{"word":"exotiquement","word_nosc":"exotiquement","lemma":"exotiquement","pos":"ADV"} ,
		{"word":"expertement","word_nosc":"expertement","lemma":"expertement","pos":"ADV"} ,
		{"word":"explicitement","word_nosc":"explicitement","lemma":"explicitement","pos":"ADV"} ,
		{"word":"exponentiellement","word_nosc":"exponentiellement","lemma":"exponentiellement","pos":"ADV"} ,
		{"word":"expressivement","word_nosc":"expressivement","lemma":"expressivement","pos":"ADV"} ,
		{"word":"expressément","word_nosc":"expressement","lemma":"expressément","pos":"ADV"} ,
		{"word":"exprès","word_nosc":"expres","lemma":"exprès","pos":"ADV"} ,
		{"word":"expérimentalement","word_nosc":"experimentalement","lemma":"expérimentalement","pos":"ADV"} ,
		{"word":"extatiquement","word_nosc":"extatiquement","lemma":"extatiquement","pos":"ADV"} ,
		{"word":"extra-muros","word_nosc":"extra-muros","lemma":"extra-muros","pos":"ADV"} ,
		{"word":"extraordinairement","word_nosc":"extraordinairement","lemma":"extraordinairement","pos":"ADV"} ,
		{"word":"extrêmement","word_nosc":"extremement","lemma":"extrêmement","pos":"ADV"} ,
		{"word":"extérieurement","word_nosc":"exterieurement","lemma":"extérieurement","pos":"ADV"} ,
		{"word":"fabuleusement","word_nosc":"fabuleusement","lemma":"fabuleusement","pos":"ADV"} ,
		{"word":"facilement","word_nosc":"facilement","lemma":"facilement","pos":"ADV"} ,
		{"word":"facétieusement","word_nosc":"facetieusement","lemma":"facétieusement","pos":"ADV"} ,
		{"word":"fadement","word_nosc":"fadement","lemma":"fadement","pos":"ADV"} ,
		{"word":"faiblement","word_nosc":"faiblement","lemma":"faiblement","pos":"ADV"} ,
		{"word":"fallacieusement","word_nosc":"fallacieusement","lemma":"fallacieusement","pos":"ADV"} ,
		{"word":"fameusement","word_nosc":"fameusement","lemma":"fameusement","pos":"ADV"} ,
		{"word":"familièrement","word_nosc":"familierement","lemma":"familièrement","pos":"ADV"} ,
		{"word":"fanatiquement","word_nosc":"fanatiquement","lemma":"fanatiquement","pos":"ADV"} ,
		{"word":"fantasmatiquement","word_nosc":"fantasmatiquement","lemma":"fantasmatiquement","pos":"ADV"} ,
		{"word":"fantasquement","word_nosc":"fantasquement","lemma":"fantasquement","pos":"ADV"} ,
		{"word":"fantastiquement","word_nosc":"fantastiquement","lemma":"fantastiquement","pos":"ADV"} ,
		{"word":"fantomatiquement","word_nosc":"fantomatiquement","lemma":"fantomatiquement","pos":"ADV"} ,
		{"word":"farouchement","word_nosc":"farouchement","lemma":"farouchement","pos":"ADV"} ,
		{"word":"fastidieusement","word_nosc":"fastidieusement","lemma":"fastidieusement","pos":"ADV"} ,
		{"word":"fastueusement","word_nosc":"fastueusement","lemma":"fastueusement","pos":"ADV"} ,
		{"word":"fatalement","word_nosc":"fatalement","lemma":"fatalement","pos":"ADV"} ,
		{"word":"faussement","word_nosc":"faussement","lemma":"faussement","pos":"ADV"} ,
		{"word":"faux","word_nosc":"faux","lemma":"faux","pos":"ADV"} ,
		{"word":"favorablement","word_nosc":"favorablement","lemma":"favorablement","pos":"ADV"} ,
		{"word":"ferme","word_nosc":"ferme","lemma":"ferme","pos":"ADV"} ,
		{"word":"fermement","word_nosc":"fermement","lemma":"fermement","pos":"ADV"} ,
		{"word":"fichtrement","word_nosc":"fichtrement","lemma":"fichtrement","pos":"ADV"} ,
		{"word":"fictivement","word_nosc":"fictivement","lemma":"fictivement","pos":"ADV"} ,
		{"word":"fidèlement","word_nosc":"fidelement","lemma":"fidèlement","pos":"ADV"} ,
		{"word":"fielleusement","word_nosc":"fielleusement","lemma":"fielleusement","pos":"ADV"} ,
		{"word":"fifty-fifty","word_nosc":"fifty-fifty","lemma":"fifty-fifty","pos":"ADV"} ,
		{"word":"figurativement","word_nosc":"figurativement","lemma":"figurativement","pos":"ADV"} ,
		{"word":"filialement","word_nosc":"filialement","lemma":"filialement","pos":"ADV"} ,
		{"word":"fin","word_nosc":"fin","lemma":"fin","pos":"ADV"} ,
		{"word":"finalement","word_nosc":"finalement","lemma":"finalement","pos":"ADV"} ,
		{"word":"financièrement","word_nosc":"financierement","lemma":"financièrement","pos":"ADV"} ,
		{"word":"finement","word_nosc":"finement","lemma":"finement","pos":"ADV"} ,
		{"word":"fiscalement","word_nosc":"fiscalement","lemma":"fiscalement","pos":"ADV"} ,
		{"word":"fissa","word_nosc":"fissa","lemma":"fissa","pos":"ADV"} ,
		{"word":"fixement","word_nosc":"fixement","lemma":"fixement","pos":"ADV"} ,
		{"word":"fièrement","word_nosc":"fierement","lemma":"fièrement","pos":"ADV"} ,
		{"word":"fiévreusement","word_nosc":"fievreusement","lemma":"fiévreusement","pos":"ADV"} ,
		{"word":"flegmatiquement","word_nosc":"flegmatiquement","lemma":"flegmatiquement","pos":"ADV"} ,
		{"word":"florès","word_nosc":"flores","lemma":"florès","pos":"ADV"} ,
		{"word":"fluidement","word_nosc":"fluidement","lemma":"fluidement","pos":"ADV"} ,
		{"word":"follement","word_nosc":"follement","lemma":"follement","pos":"ADV"} ,
		{"word":"foncièrement","word_nosc":"foncierement","lemma":"foncièrement","pos":"ADV"} ,
		{"word":"fonctionnellement","word_nosc":"fonctionnellement","lemma":"fonctionnellement","pos":"ADV"} ,
		{"word":"fondamentalement","word_nosc":"fondamentalement","lemma":"fondamentalement","pos":"ADV"} ,
		{"word":"forcément","word_nosc":"forcement","lemma":"forcément","pos":"ADV"} ,
		{"word":"formellement","word_nosc":"formellement","lemma":"formellement","pos":"ADV"} ,
		{"word":"formidablement","word_nosc":"formidablement","lemma":"formidablement","pos":"ADV"} ,
		{"word":"fort","word_nosc":"fort","lemma":"fort","pos":"ADV"} ,
		{"word":"forte","word_nosc":"forte","lemma":"forte","pos":"ADV"} ,
		{"word":"fortement","word_nosc":"fortement","lemma":"fortement","pos":"ADV"} ,
		{"word":"fortissimo","word_nosc":"fortissimo","lemma":"fortissimo","pos":"ADV"} ,
		{"word":"fortuitement","word_nosc":"fortuitement","lemma":"fortuitement","pos":"ADV"} ,
		{"word":"fougueusement","word_nosc":"fougueusement","lemma":"fougueusement","pos":"ADV"} ,
		{"word":"foutrement","word_nosc":"foutrement","lemma":"foutrement","pos":"ADV"} ,
		{"word":"foutument","word_nosc":"foutument","lemma":"foutument","pos":"ADV"} ,
		{"word":"fragilement","word_nosc":"fragilement","lemma":"fragilement","pos":"ADV"} ,
		{"word":"frais","word_nosc":"frais","lemma":"frais","pos":"ADV"} ,
		{"word":"franc","word_nosc":"franc","lemma":"franc","pos":"ADV"} ,
		{"word":"franchement","word_nosc":"franchement","lemma":"franchement","pos":"ADV"} ,
		{"word":"franco","word_nosc":"franco","lemma":"franco","pos":"ADV"} ,
		{"word":"fraternellement","word_nosc":"fraternellement","lemma":"fraternellement","pos":"ADV"} ,
		{"word":"frauduleusement","word_nosc":"frauduleusement","lemma":"frauduleusement","pos":"ADV"} ,
		{"word":"fraîchement","word_nosc":"fraichement","lemma":"fraîchement","pos":"ADV"} ,
		{"word":"frileusement","word_nosc":"frileusement","lemma":"frileusement","pos":"ADV"} ,
		{"word":"froidement","word_nosc":"froidement","lemma":"froidement","pos":"ADV"} ,
		{"word":"frugalement","word_nosc":"frugalement","lemma":"frugalement","pos":"ADV"} ,
		{"word":"frénétiquement","word_nosc":"frenetiquement","lemma":"frénétiquement","pos":"ADV"} ,
		{"word":"fréquemment","word_nosc":"frequemment","lemma":"fréquemment","pos":"ADV"} ,
		{"word":"fugacement","word_nosc":"fugacement","lemma":"fugacement","pos":"ADV"} ,
		{"word":"fugitivement","word_nosc":"fugitivement","lemma":"fugitivement","pos":"ADV"} ,
		{"word":"funestement","word_nosc":"funestement","lemma":"funestement","pos":"ADV"} ,
		{"word":"furieusement","word_nosc":"furieusement","lemma":"furieusement","pos":"ADV"} ,
		{"word":"furtivement","word_nosc":"furtivement","lemma":"furtivement","pos":"ADV"} ,
		{"word":"futilement","word_nosc":"futilement","lemma":"futilement","pos":"ADV"} ,
		{"word":"fâcheusement","word_nosc":"facheusement","lemma":"fâcheusement","pos":"ADV"} ,
		{"word":"fébrilement","word_nosc":"febrilement","lemma":"fébrilement","pos":"ADV"} ,
		{"word":"féeriquement","word_nosc":"feeriquement","lemma":"féeriquement","pos":"ADV"} ,
		{"word":"félinement","word_nosc":"felinement","lemma":"félinement","pos":"ADV"} ,
		{"word":"férocement","word_nosc":"ferocement","lemma":"férocement","pos":"ADV"} ,
		{"word":"gaiement","word_nosc":"gaiement","lemma":"gaiement","pos":"ADV"} ,
		{"word":"gaillardement","word_nosc":"gaillardement","lemma":"gaillardement","pos":"ADV"} ,
		{"word":"gal","word_nosc":"gal","lemma":"gal","pos":"ADV"} ,
		{"word":"galamment","word_nosc":"galamment","lemma":"galamment","pos":"ADV"} ,
		{"word":"gauchement","word_nosc":"gauchement","lemma":"gauchement","pos":"ADV"} ,
		{"word":"gauloisement","word_nosc":"gauloisement","lemma":"gauloisement","pos":"ADV"} ,
		{"word":"gaîment","word_nosc":"gaiment","lemma":"gaîment","pos":"ADV"} ,
		{"word":"gentiment","word_nosc":"gentiment","lemma":"gentiment","pos":"ADV"} ,
		{"word":"germano","word_nosc":"germano","lemma":"germano","pos":"ADV"} ,
		{"word":"gi","word_nosc":"gi","lemma":"gi","pos":"ADV"} ,
		{"word":"glacialement","word_nosc":"glacialement","lemma":"glacialement","pos":"ADV"} ,
		{"word":"glauquement","word_nosc":"glauquement","lemma":"glauquement","pos":"ADV"} ,
		{"word":"globalement","word_nosc":"globalement","lemma":"globalement","pos":"ADV"} ,
		{"word":"glorieusement","word_nosc":"glorieusement","lemma":"glorieusement","pos":"ADV"} ,
		{"word":"gloutonnement","word_nosc":"gloutonnement","lemma":"gloutonnement","pos":"ADV"} ,
		{"word":"goulûment","word_nosc":"goulument","lemma":"goulûment","pos":"ADV"} ,
		{"word":"gourmandement","word_nosc":"gourmandement","lemma":"gourmandement","pos":"ADV"} ,
		{"word":"goutte","word_nosc":"goutte","lemma":"goutte","pos":"ADV"} ,
		{"word":"gracieusement","word_nosc":"gracieusement","lemma":"gracieusement","pos":"ADV"} ,
		{"word":"graduellement","word_nosc":"graduellement","lemma":"graduellement","pos":"ADV"} ,
		{"word":"grammaticalement","word_nosc":"grammaticalement","lemma":"grammaticalement","pos":"ADV"} ,
		{"word":"grand","word_nosc":"grand","lemma":"grand","pos":"ADV"} ,
		{"word":"grand-faim","word_nosc":"grand-faim","lemma":"grand-faim","pos":"ADV"} ,
		{"word":"grand-hâte","word_nosc":"grand-hate","lemma":"grand-hâte","pos":"ADV"} ,
		{"word":"grand-peine","word_nosc":"grand-peine","lemma":"grand-peine","pos":"ADV"} ,
		{"word":"grand-peur","word_nosc":"grand-peur","lemma":"grand-peur","pos":"ADV"} ,
		{"word":"grandement","word_nosc":"grandement","lemma":"grandement","pos":"ADV"} ,
		{"word":"grandiosement","word_nosc":"grandiosement","lemma":"grandiosement","pos":"ADV"} ,
		{"word":"gras","word_nosc":"gras","lemma":"gras","pos":"ADV"} ,
		{"word":"grassement","word_nosc":"grassement","lemma":"grassement","pos":"ADV"} ,
		{"word":"gratis","word_nosc":"gratis","lemma":"gratis","pos":"ADV"} ,
		{"word":"gratis pro deo","word_nosc":"gratis pro deo","lemma":"gratis pro deo","pos":"ADV"} ,
		{"word":"gratos","word_nosc":"gratos","lemma":"gratos","pos":"ADV"} ,
		{"word":"gratuitement","word_nosc":"gratuitement","lemma":"gratuitement","pos":"ADV"} ,
		{"word":"gravement","word_nosc":"gravement","lemma":"gravement","pos":"ADV"} ,
		{"word":"grièvement","word_nosc":"grievement","lemma":"grièvement","pos":"ADV"} ,
		{"word":"gros","word_nosc":"gros","lemma":"gros","pos":"ADV"} ,
		{"word":"grossièrement","word_nosc":"grossierement","lemma":"grossièrement","pos":"ADV"} ,
		{"word":"grosso modo","word_nosc":"grosso modo","lemma":"grosso modo","pos":"ADV"} ,
		{"word":"grotesquement","word_nosc":"grotesquement","lemma":"grotesquement","pos":"ADV"} ,
		{"word":"gréco","word_nosc":"greco","lemma":"gréco","pos":"ADV"} ,
		{"word":"gusto","word_nosc":"gusto","lemma":"gusto","pos":"ADV"} ,
		{"word":"guère","word_nosc":"guere","lemma":"guère","pos":"ADV"} ,
		{"word":"génialement","word_nosc":"genialement","lemma":"génialement","pos":"ADV"} ,
		{"word":"généralement","word_nosc":"generalement","lemma":"généralement","pos":"ADV"} ,
		{"word":"généreusement","word_nosc":"genereusement","lemma":"généreusement","pos":"ADV"} ,
		{"word":"génétiquement","word_nosc":"genetiquement","lemma":"génétiquement","pos":"ADV"} ,
		{"word":"géographiquement","word_nosc":"geographiquement","lemma":"géographiquement","pos":"ADV"} ,
		{"word":"géologiquement","word_nosc":"geologiquement","lemma":"géologiquement","pos":"ADV"} ,
		{"word":"géométriquement","word_nosc":"geometriquement","lemma":"géométriquement","pos":"ADV"} ,
		{"word":"habilement","word_nosc":"habilement","lemma":"habilement","pos":"ADV"} ,
		{"word":"habituellement","word_nosc":"habituellement","lemma":"habituellement","pos":"ADV"} ,
		{"word":"haineusement","word_nosc":"haineusement","lemma":"haineusement","pos":"ADV"} ,
		{"word":"hardiment","word_nosc":"hardiment","lemma":"hardiment","pos":"ADV"} ,
		{"word":"hargneusement","word_nosc":"hargneusement","lemma":"hargneusement","pos":"ADV"} ,
		{"word":"harmonieusement","word_nosc":"harmonieusement","lemma":"harmonieusement","pos":"ADV"} ,
		{"word":"harmoniquement","word_nosc":"harmoniquement","lemma":"harmoniquement","pos":"ADV"} ,
		{"word":"hasardeusement","word_nosc":"hasardeusement","lemma":"hasardeusement","pos":"ADV"} ,
		{"word":"haut","word_nosc":"haut","lemma":"haut","pos":"ADV"} ,
		{"word":"hautainement","word_nosc":"hautainement","lemma":"hautainement","pos":"ADV"} ,
		{"word":"hautement","word_nosc":"hautement","lemma":"hautement","pos":"ADV"} ,
		{"word":"hebdomadairement","word_nosc":"hebdomadairement","lemma":"hebdomadairement","pos":"ADV"} ,
		{"word":"hermétiquement","word_nosc":"hermetiquement","lemma":"hermétiquement","pos":"ADV"} ,
		{"word":"heureusement","word_nosc":"heureusement","lemma":"heureusement","pos":"ADV"} ,
		{"word":"hic et nunc","word_nosc":"hic et nunc","lemma":"hic et nunc","pos":"ADV"} ,
		{"word":"hideusement","word_nosc":"hideusement","lemma":"hideusement","pos":"ADV"} ,
		{"word":"hier","word_nosc":"hier","lemma":"hier","pos":"ADV"} ,
		{"word":"hispano","word_nosc":"hispano","lemma":"hispano","pos":"ADV"} ,
		{"word":"historiquement","word_nosc":"historiquement","lemma":"historiquement","pos":"ADV"} ,
		{"word":"hiérarchiquement","word_nosc":"hierarchiquement","lemma":"hiérarchiquement","pos":"ADV"} ,
		{"word":"hiératiquement","word_nosc":"hieratiquement","lemma":"hiératiquement","pos":"ADV"} ,
		{"word":"honnêtement","word_nosc":"honnetement","lemma":"honnêtement","pos":"ADV"} ,
		{"word":"honorablement","word_nosc":"honorablement","lemma":"honorablement","pos":"ADV"} ,
		{"word":"honoris causa","word_nosc":"honoris causa","lemma":"honoris causa","pos":"ADV"} ,
		{"word":"honteusement","word_nosc":"honteusement","lemma":"honteusement","pos":"ADV"} ,
		{"word":"horizontalement","word_nosc":"horizontalement","lemma":"horizontalement","pos":"ADV"} ,
		{"word":"horriblement","word_nosc":"horriblement","lemma":"horriblement","pos":"ADV"} ,
		{"word":"hostilement","word_nosc":"hostilement","lemma":"hostilement","pos":"ADV"} ,
		{"word":"humainement","word_nosc":"humainement","lemma":"humainement","pos":"ADV"} ,
		{"word":"humano","word_nosc":"humano","lemma":"humano","pos":"ADV"} ,
		{"word":"humblement","word_nosc":"humblement","lemma":"humblement","pos":"ADV"} ,
		{"word":"humidement","word_nosc":"humidement","lemma":"humidement","pos":"ADV"} ,
		{"word":"hydro","word_nosc":"hydro","lemma":"hydro","pos":"ADV"} ,
		{"word":"hygiéniquement","word_nosc":"hygieniquement","lemma":"hygiéniquement","pos":"ADV"} ,
		{"word":"hyper","word_nosc":"hyper","lemma":"hyper","pos":"ADV"} ,
		{"word":"hyperboliquement","word_nosc":"hyperboliquement","lemma":"hyperboliquement","pos":"ADV"} ,
		{"word":"hypnotiquement","word_nosc":"hypnotiquement","lemma":"hypnotiquement","pos":"ADV"} ,
		{"word":"hypo","word_nosc":"hypo","lemma":"hypo","pos":"ADV"} ,
		{"word":"hypocritement","word_nosc":"hypocritement","lemma":"hypocritement","pos":"ADV"} ,
		{"word":"hypothétiquement","word_nosc":"hypothetiquement","lemma":"hypothétiquement","pos":"ADV"} ,
		{"word":"hystériquement","word_nosc":"hysteriquement","lemma":"hystériquement","pos":"ADV"} ,
		{"word":"hâtivement","word_nosc":"hativement","lemma":"hâtivement","pos":"ADV"} ,
		{"word":"hébraïquement","word_nosc":"hebraiquement","lemma":"hébraïquement","pos":"ADV"} ,
		{"word":"hélico","word_nosc":"helico","lemma":"hélico","pos":"ADV"} ,
		{"word":"hémi","word_nosc":"hemi","lemma":"hémi","pos":"ADV"} ,
		{"word":"héroïquement","word_nosc":"heroiquement","lemma":"héroïquement","pos":"ADV"} ,
		{"word":"héréditairement","word_nosc":"hereditairement","lemma":"héréditairement","pos":"ADV"} ,
		{"word":"hérédo","word_nosc":"heredo","lemma":"hérédo","pos":"ADV"} ,
		{"word":"ici","word_nosc":"ici","lemma":"ici","pos":"ADV"} ,
		{"word":"ici-bas","word_nosc":"ici-bas","lemma":"ici-bas","pos":"ADV"} ,
		{"word":"idem","word_nosc":"idem","lemma":"idem","pos":"ADV"} ,
		{"word":"identiquement","word_nosc":"identiquement","lemma":"identiquement","pos":"ADV"} ,
		{"word":"idiotement","word_nosc":"idiotement","lemma":"idiotement","pos":"ADV"} ,
		{"word":"idéalement","word_nosc":"idealement","lemma":"idéalement","pos":"ADV"} ,
		{"word":"idéologiquement","word_nosc":"ideologiquement","lemma":"idéologiquement","pos":"ADV"} ,
		{"word":"ignoblement","word_nosc":"ignoblement","lemma":"ignoblement","pos":"ADV"} ,
		{"word":"ignominieusement","word_nosc":"ignominieusement","lemma":"ignominieusement","pos":"ADV"} ,
		{"word":"il","word_nosc":"il","lemma":"il","pos":"ADV"} ,
		{"word":"illicitement","word_nosc":"illicitement","lemma":"illicitement","pos":"ADV"} ,
		{"word":"illico","word_nosc":"illico","lemma":"illico","pos":"ADV"} ,
		{"word":"illogiquement","word_nosc":"illogiquement","lemma":"illogiquement","pos":"ADV"} ,
		{"word":"illusoirement","word_nosc":"illusoirement","lemma":"illusoirement","pos":"ADV"} ,
		{"word":"illustrement","word_nosc":"illustrement","lemma":"illustrement","pos":"ADV"} ,
		{"word":"illégalement","word_nosc":"illegalement","lemma":"illégalement","pos":"ADV"} ,
		{"word":"illégitimement","word_nosc":"illegitimement","lemma":"illégitimement","pos":"ADV"} ,
		{"word":"imaginairement","word_nosc":"imaginairement","lemma":"imaginairement","pos":"ADV"} ,
		{"word":"imbécilement","word_nosc":"imbecilement","lemma":"imbécilement","pos":"ADV"} ,
		{"word":"immanquablement","word_nosc":"immanquablement","lemma":"immanquablement","pos":"ADV"} ,
		{"word":"immensément","word_nosc":"immensement","lemma":"immensément","pos":"ADV"} ,
		{"word":"immodérément","word_nosc":"immoderement","lemma":"immodérément","pos":"ADV"} ,
		{"word":"immoralement","word_nosc":"immoralement","lemma":"immoralement","pos":"ADV"} ,
		{"word":"immuablement","word_nosc":"immuablement","lemma":"immuablement","pos":"ADV"} ,
		{"word":"immédiatement","word_nosc":"immediatement","lemma":"immédiatement","pos":"ADV"} ,
		{"word":"impalpablement","word_nosc":"impalpablement","lemma":"impalpablement","pos":"ADV"} ,
		{"word":"imparablement","word_nosc":"imparablement","lemma":"imparablement","pos":"ADV"} ,
		{"word":"impardonnablement","word_nosc":"impardonnablement","lemma":"impardonnablement","pos":"ADV"} ,
		{"word":"imparfaitement","word_nosc":"imparfaitement","lemma":"imparfaitement","pos":"ADV"} ,
		{"word":"impartialement","word_nosc":"impartialement","lemma":"impartialement","pos":"ADV"} ,
		{"word":"impassiblement","word_nosc":"impassiblement","lemma":"impassiblement","pos":"ADV"} ,
		{"word":"impatiemment","word_nosc":"impatiemment","lemma":"impatiemment","pos":"ADV"} ,
		{"word":"impeccablement","word_nosc":"impeccablement","lemma":"impeccablement","pos":"ADV"} ,
		{"word":"imperceptiblement","word_nosc":"imperceptiblement","lemma":"imperceptiblement","pos":"ADV"} ,
		{"word":"impertinemment","word_nosc":"impertinemment","lemma":"impertinemment","pos":"ADV"} ,
		{"word":"imperturbablement","word_nosc":"imperturbablement","lemma":"imperturbablement","pos":"ADV"} ,
		{"word":"impitoyablement","word_nosc":"impitoyablement","lemma":"impitoyablement","pos":"ADV"} ,
		{"word":"implacablement","word_nosc":"implacablement","lemma":"implacablement","pos":"ADV"} ,
		{"word":"implicitement","word_nosc":"implicitement","lemma":"implicitement","pos":"ADV"} ,
		{"word":"impoliment","word_nosc":"impoliment","lemma":"impoliment","pos":"ADV"} ,
		{"word":"impromptu","word_nosc":"impromptu","lemma":"impromptu","pos":"ADV"} ,
		{"word":"improprement","word_nosc":"improprement","lemma":"improprement","pos":"ADV"} ,
		{"word":"imprudemment","word_nosc":"imprudemment","lemma":"imprudemment","pos":"ADV"} ,
		{"word":"imprévisiblement","word_nosc":"imprevisiblement","lemma":"imprévisiblement","pos":"ADV"} ,
		{"word":"impudemment","word_nosc":"impudemment","lemma":"impudemment","pos":"ADV"} ,
		{"word":"impudiquement","word_nosc":"impudiquement","lemma":"impudiquement","pos":"ADV"} ,
		{"word":"impulsivement","word_nosc":"impulsivement","lemma":"impulsivement","pos":"ADV"} ,
		{"word":"impunément","word_nosc":"impunement","lemma":"impunément","pos":"ADV"} ,
		{"word":"impérativement","word_nosc":"imperativement","lemma":"impérativement","pos":"ADV"} ,
		{"word":"impérieusement","word_nosc":"imperieusement","lemma":"impérieusement","pos":"ADV"} ,
		{"word":"impétueusement","word_nosc":"impetueusement","lemma":"impétueusement","pos":"ADV"} ,
		{"word":"in absentia","word_nosc":"in absentia","lemma":"in absentia","pos":"ADV"} ,
		{"word":"in abstracto","word_nosc":"in abstracto","lemma":"in abstracto","pos":"ADV"} ,
		{"word":"in anima vili","word_nosc":"in anima vili","lemma":"in anima vili","pos":"ADV"} ,
		{"word":"in cauda venenum","word_nosc":"in cauda venenum","lemma":"in cauda venenum","pos":"ADV"} ,
		{"word":"in extenso","word_nosc":"in extenso","lemma":"in extenso","pos":"ADV"} ,
		{"word":"in extremis","word_nosc":"in extremis","lemma":"in extremis","pos":"ADV"} ,
		{"word":"in memoriam","word_nosc":"in memoriam","lemma":"in memoriam","pos":"ADV"} ,
		{"word":"in petto","word_nosc":"in petto","lemma":"in petto","pos":"ADV"} ,
		{"word":"in utero","word_nosc":"in utero","lemma":"in utero","pos":"ADV"} ,
		{"word":"in vino veritas","word_nosc":"in vino veritas","lemma":"in vino veritas","pos":"ADV"} ,
		{"word":"in vitro","word_nosc":"in vitro","lemma":"in vitro","pos":"ADV"} ,
		{"word":"in vivo","word_nosc":"in vivo","lemma":"in vivo","pos":"ADV"} ,
		{"word":"inadéquatement","word_nosc":"inadequatement","lemma":"inadéquatement","pos":"ADV"} ,
		{"word":"inavouablement","word_nosc":"inavouablement","lemma":"inavouablement","pos":"ADV"} ,
		{"word":"incessamment","word_nosc":"incessamment","lemma":"incessamment","pos":"ADV"} ,
		{"word":"incidemment","word_nosc":"incidemment","lemma":"incidemment","pos":"ADV"} ,
		{"word":"inclusivement","word_nosc":"inclusivement","lemma":"inclusivement","pos":"ADV"} ,
		{"word":"incoerciblement","word_nosc":"incoerciblement","lemma":"incoerciblement","pos":"ADV"} ,
		{"word":"incognito","word_nosc":"incognito","lemma":"incognito","pos":"ADV"} ,
		{"word":"incommensurablement","word_nosc":"incommensurablement","lemma":"incommensurablement","pos":"ADV"} ,
		{"word":"incomparablement","word_nosc":"incomparablement","lemma":"incomparablement","pos":"ADV"} ,
		{"word":"incomplètement","word_nosc":"incompletement","lemma":"incomplètement","pos":"ADV"} ,
		{"word":"incompréhensiblement","word_nosc":"incomprehensiblement","lemma":"incompréhensiblement","pos":"ADV"} ,
		{"word":"inconcevablement","word_nosc":"inconcevablement","lemma":"inconcevablement","pos":"ADV"} ,
		{"word":"inconditionnellement","word_nosc":"inconditionnellement","lemma":"inconditionnellement","pos":"ADV"} ,
		{"word":"inconfortablement","word_nosc":"inconfortablement","lemma":"inconfortablement","pos":"ADV"} ,
		{"word":"inconsciemment","word_nosc":"inconsciemment","lemma":"inconsciemment","pos":"ADV"} ,
		{"word":"inconsidérément","word_nosc":"inconsiderement","lemma":"inconsidérément","pos":"ADV"} ,
		{"word":"incontestablement","word_nosc":"incontestablement","lemma":"incontestablement","pos":"ADV"} ,
		{"word":"incontinent","word_nosc":"incontinent","lemma":"incontinent","pos":"ADV"} ,
		{"word":"incorrectement","word_nosc":"incorrectement","lemma":"incorrectement","pos":"ADV"} ,
		{"word":"incorrigiblement","word_nosc":"incorrigiblement","lemma":"incorrigiblement","pos":"ADV"} ,
		{"word":"incroyablement","word_nosc":"incroyablement","lemma":"incroyablement","pos":"ADV"} ,
		{"word":"incurablement","word_nosc":"incurablement","lemma":"incurablement","pos":"ADV"} ,
		{"word":"indescriptiblement","word_nosc":"indescriptiblement","lemma":"indescriptiblement","pos":"ADV"} ,
		{"word":"indiciblement","word_nosc":"indiciblement","lemma":"indiciblement","pos":"ADV"} ,
		{"word":"indifféremment","word_nosc":"indifferemment","lemma":"indifféremment","pos":"ADV"} ,
		{"word":"indignement","word_nosc":"indignement","lemma":"indignement","pos":"ADV"} ,
		{"word":"indirectement","word_nosc":"indirectement","lemma":"indirectement","pos":"ADV"} ,
		{"word":"indiscrètement","word_nosc":"indiscretement","lemma":"indiscrètement","pos":"ADV"} ,
		{"word":"indiscutablement","word_nosc":"indiscutablement","lemma":"indiscutablement","pos":"ADV"} ,
		{"word":"indissolublement","word_nosc":"indissolublement","lemma":"indissolublement","pos":"ADV"} ,
		{"word":"indistinctement","word_nosc":"indistinctement","lemma":"indistinctement","pos":"ADV"} ,
		{"word":"individuellement","word_nosc":"individuellement","lemma":"individuellement","pos":"ADV"} ,
		{"word":"indo","word_nosc":"indo","lemma":"indo","pos":"ADV"} ,
		{"word":"indolemment","word_nosc":"indolemment","lemma":"indolemment","pos":"ADV"} ,
		{"word":"indubitablement","word_nosc":"indubitablement","lemma":"indubitablement","pos":"ADV"} ,
		{"word":"industriellement","word_nosc":"industriellement","lemma":"industriellement","pos":"ADV"} ,
		{"word":"industrieusement","word_nosc":"industrieusement","lemma":"industrieusement","pos":"ADV"} ,
		{"word":"indécemment","word_nosc":"indecemment","lemma":"indécemment","pos":"ADV"} ,
		{"word":"indécrottablement","word_nosc":"indecrottablement","lemma":"indécrottablement","pos":"ADV"} ,
		{"word":"indéfectiblement","word_nosc":"indefectiblement","lemma":"indéfectiblement","pos":"ADV"} ,
		{"word":"indéfiniment","word_nosc":"indefiniment","lemma":"indéfiniment","pos":"ADV"} ,
		{"word":"indéfinissablement","word_nosc":"indefinissablement","lemma":"indéfinissablement","pos":"ADV"} ,
		{"word":"indéniablement","word_nosc":"indeniablement","lemma":"indéniablement","pos":"ADV"} ,
		{"word":"indépendamment","word_nosc":"independamment","lemma":"indépendamment","pos":"ADV"} ,
		{"word":"indûment","word_nosc":"indument","lemma":"indûment","pos":"ADV"} ,
		{"word":"ineffablement","word_nosc":"ineffablement","lemma":"ineffablement","pos":"ADV"} ,
		{"word":"ineffaçablement","word_nosc":"ineffacablement","lemma":"ineffaçablement","pos":"ADV"} ,
		{"word":"ineptement","word_nosc":"ineptement","lemma":"ineptement","pos":"ADV"} ,
		{"word":"inespérément","word_nosc":"inesperement","lemma":"inespérément","pos":"ADV"} ,
		{"word":"inexactement","word_nosc":"inexactement","lemma":"inexactement","pos":"ADV"} ,
		{"word":"inexorablement","word_nosc":"inexorablement","lemma":"inexorablement","pos":"ADV"} ,
		{"word":"inexplicablement","word_nosc":"inexplicablement","lemma":"inexplicablement","pos":"ADV"} ,
		{"word":"inexprimablement","word_nosc":"inexprimablement","lemma":"inexprimablement","pos":"ADV"} ,
		{"word":"inexpugnablement","word_nosc":"inexpugnablement","lemma":"inexpugnablement","pos":"ADV"} ,
		{"word":"inextricablement","word_nosc":"inextricablement","lemma":"inextricablement","pos":"ADV"} ,
		{"word":"infailliblement","word_nosc":"infailliblement","lemma":"infailliblement","pos":"ADV"} ,
		{"word":"infantilement","word_nosc":"infantilement","lemma":"infantilement","pos":"ADV"} ,
		{"word":"infatigablement","word_nosc":"infatigablement","lemma":"infatigablement","pos":"ADV"} ,
		{"word":"infectement","word_nosc":"infectement","lemma":"infectement","pos":"ADV"} ,
		{"word":"infernalement","word_nosc":"infernalement","lemma":"infernalement","pos":"ADV"} ,
		{"word":"infiniment","word_nosc":"infiniment","lemma":"infiniment","pos":"ADV"} ,
		{"word":"infinitésimalement","word_nosc":"infinitesimalement","lemma":"infinitésimalement","pos":"ADV"} ,
		{"word":"inflexiblement","word_nosc":"inflexiblement","lemma":"inflexiblement","pos":"ADV"} ,
		{"word":"informatiquement","word_nosc":"informatiquement","lemma":"informatiquement","pos":"ADV"} ,
		{"word":"infra","word_nosc":"infra","lemma":"infra","pos":"ADV"} ,
		{"word":"inglorieusement","word_nosc":"inglorieusement","lemma":"inglorieusement","pos":"ADV"} ,
		{"word":"ingratement","word_nosc":"ingratement","lemma":"ingratement","pos":"ADV"} ,
		{"word":"ingénieusement","word_nosc":"ingenieusement","lemma":"ingénieusement","pos":"ADV"} ,
		{"word":"ingénument","word_nosc":"ingenument","lemma":"ingénument","pos":"ADV"} ,
		{"word":"inhabituellement","word_nosc":"inhabituellement","lemma":"inhabituellement","pos":"ADV"} ,
		{"word":"inhumainement","word_nosc":"inhumainement","lemma":"inhumainement","pos":"ADV"} ,
		{"word":"inimaginablement","word_nosc":"inimaginablement","lemma":"inimaginablement","pos":"ADV"} ,
		{"word":"inimitablement","word_nosc":"inimitablement","lemma":"inimitablement","pos":"ADV"} ,
		{"word":"initialement","word_nosc":"initialement","lemma":"initialement","pos":"ADV"} ,
		{"word":"injurieusement","word_nosc":"injurieusement","lemma":"injurieusement","pos":"ADV"} ,
		{"word":"injustement","word_nosc":"injustement","lemma":"injustement","pos":"ADV"} ,
		{"word":"inlassablement","word_nosc":"inlassablement","lemma":"inlassablement","pos":"ADV"} ,
		{"word":"innocemment","word_nosc":"innocemment","lemma":"innocemment","pos":"ADV"} ,
		{"word":"innombrablement","word_nosc":"innombrablement","lemma":"innombrablement","pos":"ADV"} ,
		{"word":"inopinément","word_nosc":"inopinement","lemma":"inopinément","pos":"ADV"} ,
		{"word":"inopportunément","word_nosc":"inopportunement","lemma":"inopportunément","pos":"ADV"} ,
		{"word":"inoubliablement","word_nosc":"inoubliablement","lemma":"inoubliablement","pos":"ADV"} ,
		{"word":"insatiablement","word_nosc":"insatiablement","lemma":"insatiablement","pos":"ADV"} ,
		{"word":"insensiblement","word_nosc":"insensiblement","lemma":"insensiblement","pos":"ADV"} ,
		{"word":"insensément","word_nosc":"insensement","lemma":"insensément","pos":"ADV"} ,
		{"word":"insidieusement","word_nosc":"insidieusement","lemma":"insidieusement","pos":"ADV"} ,
		{"word":"insolemment","word_nosc":"insolemment","lemma":"insolemment","pos":"ADV"} ,
		{"word":"insolitement","word_nosc":"insolitement","lemma":"insolitement","pos":"ADV"} ,
		{"word":"insondablement","word_nosc":"insondablement","lemma":"insondablement","pos":"ADV"} ,
		{"word":"insoucieusement","word_nosc":"insoucieusement","lemma":"insoucieusement","pos":"ADV"} ,
		{"word":"instamment","word_nosc":"instamment","lemma":"instamment","pos":"ADV"} ,
		{"word":"instantanément","word_nosc":"instantanement","lemma":"instantanément","pos":"ADV"} ,
		{"word":"instinctivement","word_nosc":"instinctivement","lemma":"instinctivement","pos":"ADV"} ,
		{"word":"instinctivo","word_nosc":"instinctivo","lemma":"instinctivo","pos":"ADV"} ,
		{"word":"insuffisamment","word_nosc":"insuffisamment","lemma":"insuffisamment","pos":"ADV"} ,
		{"word":"insupportablement","word_nosc":"insupportablement","lemma":"insupportablement","pos":"ADV"} ,
		{"word":"inséparablement","word_nosc":"inseparablement","lemma":"inséparablement","pos":"ADV"} ,
		{"word":"intarissablement","word_nosc":"intarissablement","lemma":"intarissablement","pos":"ADV"} ,
		{"word":"intellectuellement","word_nosc":"intellectuellement","lemma":"intellectuellement","pos":"ADV"} ,
		{"word":"intelligemment","word_nosc":"intelligemment","lemma":"intelligemment","pos":"ADV"} ,
		{"word":"intelligiblement","word_nosc":"intelligiblement","lemma":"intelligiblement","pos":"ADV"} ,
		{"word":"intempestivement","word_nosc":"intempestivement","lemma":"intempestivement","pos":"ADV"} ,
		{"word":"intensivement","word_nosc":"intensivement","lemma":"intensivement","pos":"ADV"} ,
		{"word":"intensément","word_nosc":"intensement","lemma":"intensément","pos":"ADV"} ,
		{"word":"intentionnellement","word_nosc":"intentionnellement","lemma":"intentionnellement","pos":"ADV"} ,
		{"word":"interminablement","word_nosc":"interminablement","lemma":"interminablement","pos":"ADV"} ,
		{"word":"internationalement","word_nosc":"internationalement","lemma":"internationalement","pos":"ADV"} ,
		{"word":"interrogativement","word_nosc":"interrogativement","lemma":"interrogativement","pos":"ADV"} ,
		{"word":"intimement","word_nosc":"intimement","lemma":"intimement","pos":"ADV"} ,
		{"word":"intolérablement","word_nosc":"intolerablement","lemma":"intolérablement","pos":"ADV"} ,
		{"word":"intra","word_nosc":"intra","lemma":"intra","pos":"ADV"} ,
		{"word":"intra muros","word_nosc":"intra muros","lemma":"intra muros","pos":"ADV"} ,
		{"word":"intra-muros","word_nosc":"intra-muros","lemma":"intra-muros","pos":"ADV"} ,
		{"word":"intrinsèquement","word_nosc":"intrinsequement","lemma":"intrinsèquement","pos":"ADV"} ,
		{"word":"intrépidement","word_nosc":"intrepidement","lemma":"intrépidement","pos":"ADV"} ,
		{"word":"intuitivement","word_nosc":"intuitivement","lemma":"intuitivement","pos":"ADV"} ,
		{"word":"intégralement","word_nosc":"integralement","lemma":"intégralement","pos":"ADV"} ,
		{"word":"intérieurement","word_nosc":"interieurement","lemma":"intérieurement","pos":"ADV"} ,
		{"word":"inutilement","word_nosc":"inutilement","lemma":"inutilement","pos":"ADV"} ,
		{"word":"invariablement","word_nosc":"invariablement","lemma":"invariablement","pos":"ADV"} ,
		{"word":"inversement","word_nosc":"inversement","lemma":"inversement","pos":"ADV"} ,
		{"word":"invinciblement","word_nosc":"invinciblement","lemma":"invinciblement","pos":"ADV"} ,
		{"word":"invisiblement","word_nosc":"invisiblement","lemma":"invisiblement","pos":"ADV"} ,
		{"word":"involontairement","word_nosc":"involontairement","lemma":"involontairement","pos":"ADV"} ,
		{"word":"invraisemblablement","word_nosc":"invraisemblablement","lemma":"invraisemblablement","pos":"ADV"} ,
		{"word":"inébranlablement","word_nosc":"inebranlablement","lemma":"inébranlablement","pos":"ADV"} ,
		{"word":"inégalement","word_nosc":"inegalement","lemma":"inégalement","pos":"ADV"} ,
		{"word":"inéluctablement","word_nosc":"ineluctablement","lemma":"inéluctablement","pos":"ADV"} ,
		{"word":"inélégamment","word_nosc":"inelegamment","lemma":"inélégamment","pos":"ADV"} ,
		{"word":"inépuisablement","word_nosc":"inepuisablement","lemma":"inépuisablement","pos":"ADV"} ,
		{"word":"inévitablement","word_nosc":"inevitablement","lemma":"inévitablement","pos":"ADV"} ,
		{"word":"ipso facto","word_nosc":"ipso facto","lemma":"ipso facto","pos":"ADV"} ,
		{"word":"ironiquement","word_nosc":"ironiquement","lemma":"ironiquement","pos":"ADV"} ,
		{"word":"irrationnellement","word_nosc":"irrationnellement","lemma":"irrationnellement","pos":"ADV"} ,
		{"word":"irrespectueusement","word_nosc":"irrespectueusement","lemma":"irrespectueusement","pos":"ADV"} ,
		{"word":"irréductiblement","word_nosc":"irreductiblement","lemma":"irréductiblement","pos":"ADV"} ,
		{"word":"irréellement","word_nosc":"irreellement","lemma":"irréellement","pos":"ADV"} ,
		{"word":"irréfutablement","word_nosc":"irrefutablement","lemma":"irréfutablement","pos":"ADV"} ,
		{"word":"irrégulièrement","word_nosc":"irregulierement","lemma":"irrégulièrement","pos":"ADV"} ,
		{"word":"irrémédiablement","word_nosc":"irremediablement","lemma":"irrémédiablement","pos":"ADV"} ,
		{"word":"irréparablement","word_nosc":"irreparablement","lemma":"irréparablement","pos":"ADV"} ,
		{"word":"irréprochablement","word_nosc":"irreprochablement","lemma":"irréprochablement","pos":"ADV"} ,
		{"word":"irrésistiblement","word_nosc":"irresistiblement","lemma":"irrésistiblement","pos":"ADV"} ,
		{"word":"irréversiblement","word_nosc":"irreversiblement","lemma":"irréversiblement","pos":"ADV"} ,
		{"word":"irrévocablement","word_nosc":"irrevocablement","lemma":"irrévocablement","pos":"ADV"} ,
		{"word":"irrévérencieusement","word_nosc":"irreverencieusement","lemma":"irrévérencieusement","pos":"ADV"} ,
		{"word":"isolément","word_nosc":"isolement","lemma":"isolément","pos":"ADV"} ,
		{"word":"item","word_nosc":"item","lemma":"item","pos":"ADV"} ,
		{"word":"itou","word_nosc":"itou","lemma":"itou","pos":"ADV"} ,
		{"word":"jacqueline","word_nosc":"jacqueline","lemma":"jacqueline","pos":"ADV"} ,
		{"word":"jadis","word_nosc":"jadis","lemma":"jadis","pos":"ADV"} ,
		{"word":"jalousement","word_nosc":"jalousement","lemma":"jalousement","pos":"ADV"} ,
		{"word":"jamais","word_nosc":"jamais","lemma":"jamais","pos":"ADV"} ,
		{"word":"jeunement","word_nosc":"jeunement","lemma":"jeunement","pos":"ADV"} ,
		{"word":"joliment","word_nosc":"joliment","lemma":"joliment","pos":"ADV"} ,
		{"word":"journellement","word_nosc":"journellement","lemma":"journellement","pos":"ADV"} ,
		{"word":"jovialement","word_nosc":"jovialement","lemma":"jovialement","pos":"ADV"} ,
		{"word":"joyeusement","word_nosc":"joyeusement","lemma":"joyeusement","pos":"ADV"} ,
		{"word":"judicieusement","word_nosc":"judicieusement","lemma":"judicieusement","pos":"ADV"} ,
		{"word":"judéo","word_nosc":"judeo","lemma":"judéo","pos":"ADV"} ,
		{"word":"juridiquement","word_nosc":"juridiquement","lemma":"juridiquement","pos":"ADV"} ,
		{"word":"jusque","word_nosc":"jusque","lemma":"jusque","pos":"ADV"} ,
		{"word":"jusque-là","word_nosc":"jusque-la","lemma":"jusque-là","pos":"ADV"} ,
		{"word":"jusques","word_nosc":"jusques","lemma":"jusques","pos":"ADV"} ,
		{"word":"juste","word_nosc":"juste","lemma":"juste","pos":"ADV"} ,
		{"word":"justement","word_nosc":"justement","lemma":"justement","pos":"ADV"} ,
		{"word":"jà","word_nosc":"ja","lemma":"jà","pos":"ADV"} ,
		{"word":"km","word_nosc":"km","lemma":"km","pos":"ADV"} ,
		{"word":"l'une","word_nosc":"l'une","lemma":"l'une","pos":"ADV"} ,
		{"word":"laborieusement","word_nosc":"laborieusement","lemma":"laborieusement","pos":"ADV"} ,
		{"word":"laconiquement","word_nosc":"laconiquement","lemma":"laconiquement","pos":"ADV"} ,
		{"word":"laidement","word_nosc":"laidement","lemma":"laidement","pos":"ADV"} ,
		{"word":"lamentablement","word_nosc":"lamentablement","lemma":"lamentablement","pos":"ADV"} ,
		{"word":"langoureusement","word_nosc":"langoureusement","lemma":"langoureusement","pos":"ADV"} ,
		{"word":"languissamment","word_nosc":"languissamment","lemma":"languissamment","pos":"ADV"} ,
		{"word":"lanlaire","word_nosc":"lanlaire","lemma":"lanlaire","pos":"ADV"} ,
		{"word":"largement","word_nosc":"largement","lemma":"largement","pos":"ADV"} ,
		{"word":"largo","word_nosc":"largo","lemma":"largo","pos":"ADV"} ,
		{"word":"lascivement","word_nosc":"lascivement","lemma":"lascivement","pos":"ADV"} ,
		{"word":"latéralement","word_nosc":"lateralement","lemma":"latéralement","pos":"ADV"} ,
		{"word":"legato","word_nosc":"legato","lemma":"legato","pos":"ADV"} ,
		{"word":"lentement","word_nosc":"lentement","lemma":"lentement","pos":"ADV"} ,
		{"word":"lento","word_nosc":"lento","lemma":"lento","pos":"ADV"} ,
		{"word":"lerch","word_nosc":"lerch","lemma":"lerch","pos":"ADV"} ,
		{"word":"lerche","word_nosc":"lerche","lemma":"lerche","pos":"ADV"} ,
		{"word":"lestement","word_nosc":"lestement","lemma":"lestement","pos":"ADV"} ,
		{"word":"librement","word_nosc":"librement","lemma":"librement","pos":"ADV"} ,
		{"word":"libéralement","word_nosc":"liberalement","lemma":"libéralement","pos":"ADV"} ,
		{"word":"linguistiquement","word_nosc":"linguistiquement","lemma":"linguistiquement","pos":"ADV"} ,
		{"word":"linéairement","word_nosc":"lineairement","lemma":"linéairement","pos":"ADV"} ,
		{"word":"lisiblement","word_nosc":"lisiblement","lemma":"lisiblement","pos":"ADV"} ,
		{"word":"littérairement","word_nosc":"litterairement","lemma":"littérairement","pos":"ADV"} ,
		{"word":"littéralement","word_nosc":"litteralement","lemma":"littéralement","pos":"ADV"} ,
		{"word":"localement","word_nosc":"localement","lemma":"localement","pos":"ADV"} ,
		{"word":"loco","word_nosc":"loco","lemma":"loco","pos":"ADV"} ,
		{"word":"logiquement","word_nosc":"logiquement","lemma":"logiquement","pos":"ADV"} ,
		{"word":"loin","word_nosc":"loin","lemma":"loin","pos":"ADV"} ,
		{"word":"lointainement","word_nosc":"lointainement","lemma":"lointainement","pos":"ADV"} ,
		{"word":"long","word_nosc":"long","lemma":"long","pos":"ADV"} ,
		{"word":"longitudinalement","word_nosc":"longitudinalement","lemma":"longitudinalement","pos":"ADV"} ,
		{"word":"longtemps","word_nosc":"longtemps","lemma":"longtemps","pos":"ADV"} ,
		{"word":"longuement","word_nosc":"longuement","lemma":"longuement","pos":"ADV"} ,
		{"word":"lors","word_nosc":"lors","lemma":"lors","pos":"ADV"} ,
		{"word":"lorsque","word_nosc":"lorsque","lemma":"lorsque","pos":"ADV"} ,
		{"word":"louablement","word_nosc":"louablement","lemma":"louablement","pos":"ADV"} ,
		{"word":"louis","word_nosc":"louis","lemma":"louis","pos":"ADV"} ,
		{"word":"lourd","word_nosc":"lourd","lemma":"lourd","pos":"ADV"} ,
		{"word":"lourdement","word_nosc":"lourdement","lemma":"lourdement","pos":"ADV"} ,
		{"word":"loyalement","word_nosc":"loyalement","lemma":"loyalement","pos":"ADV"} ,
		{"word":"lucidement","word_nosc":"lucidement","lemma":"lucidement","pos":"ADV"} ,
		{"word":"lugubrement","word_nosc":"lugubrement","lemma":"lugubrement","pos":"ADV"} ,
		{"word":"lumineusement","word_nosc":"lumineusement","lemma":"lumineusement","pos":"ADV"} ,
		{"word":"luxueusement","word_nosc":"luxueusement","lemma":"luxueusement","pos":"ADV"} ,
		{"word":"là","word_nosc":"la","lemma":"là","pos":"ADV"} ,
		{"word":"là-bas","word_nosc":"la-bas","lemma":"là-bas","pos":"ADV"} ,
		{"word":"là-dedans","word_nosc":"la-dedans","lemma":"là-dedans","pos":"ADV"} ,
		{"word":"là-dehors","word_nosc":"la-dehors","lemma":"là-dehors","pos":"ADV"} ,
		{"word":"là-derrière","word_nosc":"la-derriere","lemma":"là-derrière","pos":"ADV"} ,
		{"word":"là-dessous","word_nosc":"la-dessous","lemma":"là-dessous","pos":"ADV"} ,
		{"word":"là-dessus","word_nosc":"la-dessus","lemma":"là-dessus","pos":"ADV"} ,
		{"word":"là-devant","word_nosc":"la-devant","lemma":"là-devant","pos":"ADV"} ,
		{"word":"là-haut","word_nosc":"la-haut","lemma":"là-haut","pos":"ADV"} ,
		{"word":"lâchement","word_nosc":"lachement","lemma":"lâchement","pos":"ADV"} ,
		{"word":"légalement","word_nosc":"legalement","lemma":"légalement","pos":"ADV"} ,
		{"word":"légitimement","word_nosc":"legitimement","lemma":"légitimement","pos":"ADV"} ,
		{"word":"légèrement","word_nosc":"legerement","lemma":"légèrement","pos":"ADV"} ,
		{"word":"macache","word_nosc":"macache","lemma":"macache","pos":"ADV"} ,
		{"word":"machinalement","word_nosc":"machinalement","lemma":"machinalement","pos":"ADV"} ,
		{"word":"made in","word_nosc":"made in","lemma":"made in","pos":"ADV"} ,
		{"word":"magico","word_nosc":"magico","lemma":"magico","pos":"ADV"} ,
		{"word":"magiquement","word_nosc":"magiquement","lemma":"magiquement","pos":"ADV"} ,
		{"word":"magistralement","word_nosc":"magistralement","lemma":"magistralement","pos":"ADV"} ,
		{"word":"magnifiquement","word_nosc":"magnifiquement","lemma":"magnifiquement","pos":"ADV"} ,
		{"word":"magnétiquement","word_nosc":"magnetiquement","lemma":"magnétiquement","pos":"ADV"} ,
		{"word":"maigrement","word_nosc":"maigrement","lemma":"maigrement","pos":"ADV"} ,
		{"word":"maintenant","word_nosc":"maintenant","lemma":"maintenant","pos":"ADV"} ,
		{"word":"mais","word_nosc":"mais","lemma":"mais","pos":"ADV"} ,
		{"word":"majestueusement","word_nosc":"majestueusement","lemma":"majestueusement","pos":"ADV"} ,
		{"word":"majoritairement","word_nosc":"majoritairement","lemma":"majoritairement","pos":"ADV"} ,
		{"word":"mal","word_nosc":"mal","lemma":"mal","pos":"ADV"} ,
		{"word":"maladivement","word_nosc":"maladivement","lemma":"maladivement","pos":"ADV"} ,
		{"word":"maladroitement","word_nosc":"maladroitement","lemma":"maladroitement","pos":"ADV"} ,
		{"word":"malaisément","word_nosc":"malaisement","lemma":"malaisément","pos":"ADV"} ,
		{"word":"malencontreusement","word_nosc":"malencontreusement","lemma":"malencontreusement","pos":"ADV"} ,
		{"word":"malhabilement","word_nosc":"malhabilement","lemma":"malhabilement","pos":"ADV"} ,
		{"word":"malheureusement","word_nosc":"malheureusement","lemma":"malheureusement","pos":"ADV"} ,
		{"word":"malhonnêtement","word_nosc":"malhonnetement","lemma":"malhonnêtement","pos":"ADV"} ,
		{"word":"malicieusement","word_nosc":"malicieusement","lemma":"malicieusement","pos":"ADV"} ,
		{"word":"malignement","word_nosc":"malignement","lemma":"malignement","pos":"ADV"} ,
		{"word":"malproprement","word_nosc":"malproprement","lemma":"malproprement","pos":"ADV"} ,
		{"word":"malsainement","word_nosc":"malsainement","lemma":"malsainement","pos":"ADV"} ,
		{"word":"maniaquement","word_nosc":"maniaquement","lemma":"maniaquement","pos":"ADV"} ,
		{"word":"manifestement","word_nosc":"manifestement","lemma":"manifestement","pos":"ADV"} ,
		{"word":"manu militari","word_nosc":"manu militari","lemma":"manu militari","pos":"ADV"} ,
		{"word":"manuellement","word_nosc":"manuellement","lemma":"manuellement","pos":"ADV"} ,
		{"word":"marginalement","word_nosc":"marginalement","lemma":"marginalement","pos":"ADV"} ,
		{"word":"maritalement","word_nosc":"maritalement","lemma":"maritalement","pos":"ADV"} ,
		{"word":"marre","word_nosc":"marre","lemma":"marre","pos":"ADV"} ,
		{"word":"massivement","word_nosc":"massivement","lemma":"massivement","pos":"ADV"} ,
		{"word":"maternellement","word_nosc":"maternellement","lemma":"maternellement","pos":"ADV"} ,
		{"word":"mathématiquement","word_nosc":"mathematiquement","lemma":"mathématiquement","pos":"ADV"} ,
		{"word":"matinalement","word_nosc":"matinalement","lemma":"matinalement","pos":"ADV"} ,
		{"word":"matériellement","word_nosc":"materiellement","lemma":"matériellement","pos":"ADV"} ,
		{"word":"maussadement","word_nosc":"maussadement","lemma":"maussadement","pos":"ADV"} ,
		{"word":"mauvais","word_nosc":"mauvais","lemma":"mauvais","pos":"ADV"} ,
		{"word":"mauvaisement","word_nosc":"mauvaisement","lemma":"mauvaisement","pos":"ADV"} ,
		{"word":"meilleur","word_nosc":"meilleur","lemma":"meilleur","pos":"ADV"} ,
		{"word":"mensongèrement","word_nosc":"mensongerement","lemma":"mensongèrement","pos":"ADV"} ,
		{"word":"mensuellement","word_nosc":"mensuellement","lemma":"mensuellement","pos":"ADV"} ,
		{"word":"mentalement","word_nosc":"mentalement","lemma":"mentalement","pos":"ADV"} ,
		{"word":"menu","word_nosc":"menu","lemma":"menu","pos":"ADV"} ,
		{"word":"merveilleusement","word_nosc":"merveilleusement","lemma":"merveilleusement","pos":"ADV"} ,
		{"word":"mesquinement","word_nosc":"mesquinement","lemma":"mesquinement","pos":"ADV"} ,
		{"word":"mexico","word_nosc":"mexico","lemma":"mexico","pos":"ADV"} ,
		{"word":"mezza voce","word_nosc":"mezza voce","lemma":"mezza voce","pos":"ADV"} ,
		{"word":"mezza-voce","word_nosc":"mezza-voce","lemma":"mezza-voce","pos":"ADV"} ,
		{"word":"mezzo","word_nosc":"mezzo","lemma":"mezzo","pos":"ADV"} ,
		{"word":"microscopiquement","word_nosc":"microscopiquement","lemma":"microscopiquement","pos":"ADV"} ,
		{"word":"mieux","word_nosc":"mieux","lemma":"mieux","pos":"ADV"} ,
		{"word":"mignardement","word_nosc":"mignardement","lemma":"mignardement","pos":"ADV"} ,
		{"word":"mignonnement","word_nosc":"mignonnement","lemma":"mignonnement","pos":"ADV"} ,
		{"word":"milieu","word_nosc":"milieu","lemma":"milieu","pos":"ADV"} ,
		{"word":"militairement","word_nosc":"militairement","lemma":"militairement","pos":"ADV"} ,
		{"word":"militaro","word_nosc":"militaro","lemma":"militaro","pos":"ADV"} ,
		{"word":"milli","word_nosc":"milli","lemma":"milli","pos":"ADV"} ,
		{"word":"minablement","word_nosc":"minablement","lemma":"minablement","pos":"ADV"} ,
		{"word":"minutieusement","word_nosc":"minutieusement","lemma":"minutieusement","pos":"ADV"} ,
		{"word":"miraculeusement","word_nosc":"miraculeusement","lemma":"miraculeusement","pos":"ADV"} ,
		{"word":"misérablement","word_nosc":"miserablement","lemma":"misérablement","pos":"ADV"} ,
		{"word":"miséricordieusement","word_nosc":"misericordieusement","lemma":"miséricordieusement","pos":"ADV"} ,
		{"word":"miteusement","word_nosc":"miteusement","lemma":"miteusement","pos":"ADV"} ,
		{"word":"mièvrement","word_nosc":"mievrement","lemma":"mièvrement","pos":"ADV"} ,
		{"word":"mn","word_nosc":"mn","lemma":"mn","pos":"ADV"} ,
		{"word":"moderato","word_nosc":"moderato","lemma":"moderato","pos":"ADV"} ,
		{"word":"modestement","word_nosc":"modestement","lemma":"modestement","pos":"ADV"} ,
		{"word":"modérément","word_nosc":"moderement","lemma":"modérément","pos":"ADV"} ,
		{"word":"moelleusement","word_nosc":"moelleusement","lemma":"moelleusement","pos":"ADV"} ,
		{"word":"moindrement","word_nosc":"moindrement","lemma":"moindrement","pos":"ADV"} ,
		{"word":"moins","word_nosc":"moins","lemma":"moins","pos":"ADV"} ,
		{"word":"moitié","word_nosc":"moitie","lemma":"moitié","pos":"ADV"} ,
		{"word":"moitié-moitié","word_nosc":"moitie-moitie","lemma":"moitié-moitié","pos":"ADV"} ,
		{"word":"mollement","word_nosc":"mollement","lemma":"mollement","pos":"ADV"} ,
		{"word":"mollo","word_nosc":"mollo","lemma":"mollo","pos":"ADV"} ,
		{"word":"molto","word_nosc":"molto","lemma":"molto","pos":"ADV"} ,
		{"word":"momentanément","word_nosc":"momentanement","lemma":"momentanément","pos":"ADV"} ,
		{"word":"mondainement","word_nosc":"mondainement","lemma":"mondainement","pos":"ADV"} ,
		{"word":"mondialement","word_nosc":"mondialement","lemma":"mondialement","pos":"ADV"} ,
		{"word":"monotonement","word_nosc":"monotonement","lemma":"monotonement","pos":"ADV"} ,
		{"word":"monstrueusement","word_nosc":"monstrueusement","lemma":"monstrueusement","pos":"ADV"} ,
		{"word":"monumentalement","word_nosc":"monumentalement","lemma":"monumentalement","pos":"ADV"} ,
		{"word":"moqueusement","word_nosc":"moqueusement","lemma":"moqueusement","pos":"ADV"} ,
		{"word":"moralement","word_nosc":"moralement","lemma":"moralement","pos":"ADV"} ,
		{"word":"morbidement","word_nosc":"morbidement","lemma":"morbidement","pos":"ADV"} ,
		{"word":"mordicus","word_nosc":"mordicus","lemma":"mordicus","pos":"ADV"} ,
		{"word":"mornement","word_nosc":"mornement","lemma":"mornement","pos":"ADV"} ,
		{"word":"morosement","word_nosc":"morosement","lemma":"morosement","pos":"ADV"} ,
		{"word":"morpho","word_nosc":"morpho","lemma":"morpho","pos":"ADV"} ,
		{"word":"mortellement","word_nosc":"mortellement","lemma":"mortellement","pos":"ADV"} ,
		{"word":"motu proprio","word_nosc":"motu proprio","lemma":"motu proprio","pos":"ADV"} ,
		{"word":"moult","word_nosc":"moult","lemma":"moult","pos":"ADV"} ,
		{"word":"moyennement","word_nosc":"moyennement","lemma":"moyennement","pos":"ADV"} ,
		{"word":"muettement","word_nosc":"muettement","lemma":"muettement","pos":"ADV"} ,
		{"word":"multi","word_nosc":"multi","lemma":"multi","pos":"ADV"} ,
		{"word":"musicalement","word_nosc":"musicalement","lemma":"musicalement","pos":"ADV"} ,
		{"word":"musico","word_nosc":"musico","lemma":"musico","pos":"ADV"} ,
		{"word":"mutatis mutandis","word_nosc":"mutatis mutandis","lemma":"mutatis mutandis","pos":"ADV"} ,
		{"word":"mutuellement","word_nosc":"mutuellement","lemma":"mutuellement","pos":"ADV"} ,
		{"word":"mystiquement","word_nosc":"mystiquement","lemma":"mystiquement","pos":"ADV"} ,
		{"word":"mystérieusement","word_nosc":"mysterieusement","lemma":"mystérieusement","pos":"ADV"} ,
		{"word":"mytho","word_nosc":"mytho","lemma":"mytho","pos":"ADV"} ,
		{"word":"mécaniquement","word_nosc":"mecaniquement","lemma":"mécaniquement","pos":"ADV"} ,
		{"word":"méchamment","word_nosc":"mechamment","lemma":"méchamment","pos":"ADV"} ,
		{"word":"médicalement","word_nosc":"medicalement","lemma":"médicalement","pos":"ADV"} ,
		{"word":"médiocrement","word_nosc":"mediocrement","lemma":"médiocrement","pos":"ADV"} ,
		{"word":"méditativement","word_nosc":"meditativement","lemma":"méditativement","pos":"ADV"} ,
		{"word":"mélancoliquement","word_nosc":"melancoliquement","lemma":"mélancoliquement","pos":"ADV"} ,
		{"word":"mélodieusement","word_nosc":"melodieusement","lemma":"mélodieusement","pos":"ADV"} ,
		{"word":"méprisamment","word_nosc":"meprisamment","lemma":"méprisamment","pos":"ADV"} ,
		{"word":"métaphoriquement","word_nosc":"metaphoriquement","lemma":"métaphoriquement","pos":"ADV"} ,
		{"word":"métaphysiquement","word_nosc":"metaphysiquement","lemma":"métaphysiquement","pos":"ADV"} ,
		{"word":"méthodiquement","word_nosc":"methodiquement","lemma":"méthodiquement","pos":"ADV"} ,
		{"word":"méticuleusement","word_nosc":"meticuleusement","lemma":"méticuleusement","pos":"ADV"} ,
		{"word":"météoriquement","word_nosc":"meteoriquement","lemma":"météoriquement","pos":"ADV"} ,
		{"word":"même","word_nosc":"meme","lemma":"même","pos":"ADV"} ,
		{"word":"mêmement","word_nosc":"memement","lemma":"mêmement","pos":"ADV"} ,
		{"word":"mûrement","word_nosc":"murement","lemma":"mûrement","pos":"ADV"} ,
		{"word":"n","word_nosc":"n","lemma":"ne","pos":"ADV"} ,
		{"word":"n'","word_nosc":"n'","lemma":"ne","pos":"ADV"} ,
		{"word":"n'est-ce pas","word_nosc":"n'est-ce pas","lemma":"n'est-ce pas","pos":"ADV"} ,
		{"word":"nada","word_nosc":"nada","lemma":"nada","pos":"ADV"} ,
		{"word":"naguère","word_nosc":"naguere","lemma":"naguère","pos":"ADV"} ,
		{"word":"nano","word_nosc":"nano","lemma":"nano","pos":"ADV"} ,
		{"word":"narcissiquement","word_nosc":"narcissiquement","lemma":"narcissiquement","pos":"ADV"} ,
		{"word":"narquoisement","word_nosc":"narquoisement","lemma":"narquoisement","pos":"ADV"} ,
		{"word":"nationalement","word_nosc":"nationalement","lemma":"nationalement","pos":"ADV"} ,
		{"word":"naturellement","word_nosc":"naturellement","lemma":"naturellement","pos":"ADV"} ,
		{"word":"naturlich","word_nosc":"naturlich","lemma":"naturlich","pos":"ADV"} ,
		{"word":"naïvement","word_nosc":"naivement","lemma":"naïvement","pos":"ADV"} ,
		{"word":"ne","word_nosc":"ne","lemma":"ne","pos":"ADV"} ,
		{"word":"ne varietur","word_nosc":"ne varietur","lemma":"ne varietur","pos":"ADV"} ,
		{"word":"nenni","word_nosc":"nenni","lemma":"nenni","pos":"ADV"} ,
		{"word":"nerveusement","word_nosc":"nerveusement","lemma":"nerveusement","pos":"ADV"} ,
		{"word":"net","word_nosc":"net","lemma":"net","pos":"ADV"} ,
		{"word":"nettement","word_nosc":"nettement","lemma":"nettement","pos":"ADV"} ,
		{"word":"neurologiquement","word_nosc":"neurologiquement","lemma":"neurologiquement","pos":"ADV"} ,
		{"word":"niaisement","word_nosc":"niaisement","lemma":"niaisement","pos":"ADV"} ,
		{"word":"nib","word_nosc":"nib","lemma":"nib","pos":"ADV"} ,
		{"word":"nigaudement","word_nosc":"nigaudement","lemma":"nigaudement","pos":"ADV"} ,
		{"word":"nihil obstat","word_nosc":"nihil obstat","lemma":"nihil obstat","pos":"ADV"} ,
		{"word":"nimbo","word_nosc":"nimbo","lemma":"nimbo","pos":"ADV"} ,
		{"word":"nippo","word_nosc":"nippo","lemma":"nippo","pos":"ADV"} ,
		{"word":"noblement","word_nosc":"noblement","lemma":"noblement","pos":"ADV"} ,
		{"word":"nominalement","word_nosc":"nominalement","lemma":"nominalement","pos":"ADV"} ,
		{"word":"nommément","word_nosc":"nommement","lemma":"nommément","pos":"ADV"} ,
		{"word":"non","word_nosc":"non","lemma":"non","pos":"ADV"} ,
		{"word":"non troppo","word_nosc":"non troppo","lemma":"non troppo","pos":"ADV"} ,
		{"word":"nonchalamment","word_nosc":"nonchalamment","lemma":"nonchalamment","pos":"ADV"} ,
		{"word":"nonobstant","word_nosc":"nonobstant","lemma":"nonobstant","pos":"ADV"} ,
		{"word":"normalement","word_nosc":"normalement","lemma":"normalement","pos":"ADV"} ,
		{"word":"nostalgiquement","word_nosc":"nostalgiquement","lemma":"nostalgiquement","pos":"ADV"} ,
		{"word":"nota bene","word_nosc":"nota bene","lemma":"nota bene","pos":"ADV"} ,
		{"word":"notablement","word_nosc":"notablement","lemma":"notablement","pos":"ADV"} ,
		{"word":"notamment","word_nosc":"notamment","lemma":"notamment","pos":"ADV"} ,
		{"word":"notoirement","word_nosc":"notoirement","lemma":"notoirement","pos":"ADV"} ,
		{"word":"nouvellement","word_nosc":"nouvellement","lemma":"nouvellement","pos":"ADV"} ,
		{"word":"nuitamment","word_nosc":"nuitamment","lemma":"nuitamment","pos":"ADV"} ,
		{"word":"nullement","word_nosc":"nullement","lemma":"nullement","pos":"ADV"} ,
		{"word":"numériquement","word_nosc":"numeriquement","lemma":"numériquement","pos":"ADV"} ,
		{"word":"néanmoins","word_nosc":"neanmoins","lemma":"néanmoins","pos":"ADV"} ,
		{"word":"nécessairement","word_nosc":"necessairement","lemma":"nécessairement","pos":"ADV"} ,
		{"word":"négativement","word_nosc":"negativement","lemma":"négativement","pos":"ADV"} ,
		{"word":"négligemment","word_nosc":"negligemment","lemma":"négligemment","pos":"ADV"} ,
		{"word":"néo","word_nosc":"neo","lemma":"néo","pos":"ADV"} ,
		{"word":"objectivement","word_nosc":"objectivement","lemma":"objectivement","pos":"ADV"} ,
		{"word":"obligado","word_nosc":"obligado","lemma":"obligado","pos":"ADV"} ,
		{"word":"obligatoirement","word_nosc":"obligatoirement","lemma":"obligatoirement","pos":"ADV"} ,
		{"word":"obligeamment","word_nosc":"obligeamment","lemma":"obligeamment","pos":"ADV"} ,
		{"word":"obliquement","word_nosc":"obliquement","lemma":"obliquement","pos":"ADV"} ,
		{"word":"obscurément","word_nosc":"obscurement","lemma":"obscurément","pos":"ADV"} ,
		{"word":"obscènement","word_nosc":"obscenement","lemma":"obscènement","pos":"ADV"} ,
		{"word":"obsessionnellement","word_nosc":"obsessionnellement","lemma":"obsessionnellement","pos":"ADV"} ,
		{"word":"obstinément","word_nosc":"obstinement","lemma":"obstinément","pos":"ADV"} ,
		{"word":"obséquieusement","word_nosc":"obsequieusement","lemma":"obséquieusement","pos":"ADV"} ,
		{"word":"occasionnellement","word_nosc":"occasionnellement","lemma":"occasionnellement","pos":"ADV"} ,
		{"word":"occultement","word_nosc":"occultement","lemma":"occultement","pos":"ADV"} ,
		{"word":"octavo","word_nosc":"octavo","lemma":"octavo","pos":"ADV"} ,
		{"word":"odieusement","word_nosc":"odieusement","lemma":"odieusement","pos":"ADV"} ,
		{"word":"officiellement","word_nosc":"officiellement","lemma":"officiellement","pos":"ADV"} ,
		{"word":"officieusement","word_nosc":"officieusement","lemma":"officieusement","pos":"ADV"} ,
		{"word":"oisivement","word_nosc":"oisivement","lemma":"oisivement","pos":"ADV"} ,
		{"word":"ok","word_nosc":"ok","lemma":"ok","pos":"ADV"} ,
		{"word":"ombrageusement","word_nosc":"ombrageusement","lemma":"ombrageusement","pos":"ADV"} ,
		{"word":"omni","word_nosc":"omni","lemma":"omni","pos":"ADV"} ,
		{"word":"on-line","word_nosc":"on-line","lemma":"on-line","pos":"ADV"} ,
		{"word":"onc","word_nosc":"onc","lemma":"onc","pos":"ADV"} ,
		{"word":"onctueusement","word_nosc":"onctueusement","lemma":"onctueusement","pos":"ADV"} ,
		{"word":"onto","word_nosc":"onto","lemma":"onto","pos":"ADV"} ,
		{"word":"ontologiquement","word_nosc":"ontologiquement","lemma":"ontologiquement","pos":"ADV"} ,
		{"word":"opiniâtrement","word_nosc":"opiniatrement","lemma":"opiniâtrement","pos":"ADV"} ,
		{"word":"opportunément","word_nosc":"opportunement","lemma":"opportunément","pos":"ADV"} ,
		{"word":"optime","word_nosc":"optime","lemma":"optime","pos":"ADV"} ,
		{"word":"oralement","word_nosc":"oralement","lemma":"oralement","pos":"ADV"} ,
		{"word":"ordinairement","word_nosc":"ordinairement","lemma":"ordinairement","pos":"ADV"} ,
		{"word":"ore","word_nosc":"ore","lemma":"ore","pos":"ADV"} ,
		{"word":"organiquement","word_nosc":"organiquement","lemma":"organiquement","pos":"ADV"} ,
		{"word":"orgueilleusement","word_nosc":"orgueilleusement","lemma":"orgueilleusement","pos":"ADV"} ,
		{"word":"originairement","word_nosc":"originairement","lemma":"originairement","pos":"ADV"} ,
		{"word":"originalement","word_nosc":"originalement","lemma":"originalement","pos":"ADV"} ,
		{"word":"originellement","word_nosc":"originellement","lemma":"originellement","pos":"ADV"} ,
		{"word":"ortho","word_nosc":"ortho","lemma":"ortho","pos":"ADV"} ,
		{"word":"oseraient","word_nosc":"oseraient","lemma":"oseraient","pos":"ADV"} ,
		{"word":"ostensiblement","word_nosc":"ostensiblement","lemma":"ostensiblement","pos":"ADV"} ,
		{"word":"ostentatoirement","word_nosc":"ostentatoirement","lemma":"ostentatoirement","pos":"ADV"} ,
		{"word":"ostinato","word_nosc":"ostinato","lemma":"ostinato","pos":"ADV"} ,
		{"word":"ouais","word_nosc":"ouais","lemma":"ouais","pos":"ADV"} ,
		{"word":"oui","word_nosc":"oui","lemma":"oui","pos":"ADV"} ,
		{"word":"ousque","word_nosc":"ousque","lemma":"ousque","pos":"ADV"} ,
		{"word":"out","word_nosc":"out","lemma":"out","pos":"ADV"} ,
		{"word":"outrageusement","word_nosc":"outrageusement","lemma":"outrageusement","pos":"ADV"} ,
		{"word":"outre","word_nosc":"outre","lemma":"outre","pos":"ADV"} ,
		{"word":"outre-atlantique","word_nosc":"outre-atlantique","lemma":"outre-atlantique","pos":"ADV"} ,
		{"word":"outre-manche","word_nosc":"outre-manche","lemma":"outre-manche","pos":"ADV"} ,
		{"word":"outre-mer","word_nosc":"outre-mer","lemma":"outre-mer","pos":"ADV"} ,
		{"word":"outre-rhin","word_nosc":"outre-rhin","lemma":"outre-rhin","pos":"ADV"} ,
		{"word":"ouvertement","word_nosc":"ouvertement","lemma":"ouvertement","pos":"ADV"} ,
		{"word":"pacifiquement","word_nosc":"pacifiquement","lemma":"pacifiquement","pos":"ADV"} ,
		{"word":"paillardement","word_nosc":"paillardement","lemma":"paillardement","pos":"ADV"} ,
		{"word":"paisiblement","word_nosc":"paisiblement","lemma":"paisiblement","pos":"ADV"} ,
		{"word":"pakistano","word_nosc":"pakistano","lemma":"pakistano","pos":"ADV"} ,
		{"word":"paléo","word_nosc":"paleo","lemma":"paléo","pos":"ADV"} ,
		{"word":"par mégarde","word_nosc":"par megarde","lemma":"par mégarde","pos":"ADV"} ,
		{"word":"par-ci","word_nosc":"par-ci","lemma":"par-ci","pos":"ADV"} ,
		{"word":"paraboliquement","word_nosc":"paraboliquement","lemma":"paraboliquement","pos":"ADV"} ,
		{"word":"paradoxalement","word_nosc":"paradoxalement","lemma":"paradoxalement","pos":"ADV"} ,
		{"word":"parallèlement","word_nosc":"parallelement","lemma":"parallèlement","pos":"ADV"} ,
		{"word":"parce","word_nosc":"parce","lemma":"parce","pos":"ADV"} ,
		{"word":"parce que","word_nosc":"parce que","lemma":"parce que","pos":"ADV"} ,
		{"word":"parcimonieusement","word_nosc":"parcimonieusement","lemma":"parcimonieusement","pos":"ADV"} ,
		{"word":"pareil","word_nosc":"pareil","lemma":"pareil","pos":"ADV"} ,
		{"word":"pareillement","word_nosc":"pareillement","lemma":"pareillement","pos":"ADV"} ,
		{"word":"paresseusement","word_nosc":"paresseusement","lemma":"paresseusement","pos":"ADV"} ,
		{"word":"parfaitement","word_nosc":"parfaitement","lemma":"parfaitement","pos":"ADV"} ,
		{"word":"parfois","word_nosc":"parfois","lemma":"parfois","pos":"ADV"} ,
		{"word":"parodiquement","word_nosc":"parodiquement","lemma":"parodiquement","pos":"ADV"} ,
		{"word":"particulièrement","word_nosc":"particulierement","lemma":"particulièrement","pos":"ADV"} ,
		{"word":"partiellement","word_nosc":"partiellement","lemma":"partiellement","pos":"ADV"} ,
		{"word":"partout","word_nosc":"partout","lemma":"partout","pos":"ADV"} ,
		{"word":"pas","word_nosc":"pas","lemma":"pas","pos":"ADV"} ,
		{"word":"pas-je","word_nosc":"pas-je","lemma":"pas-je","pos":"ADV"} ,
		{"word":"pasque","word_nosc":"pasque","lemma":"pasque","pos":"ADV"} ,
		{"word":"passablement","word_nosc":"passablement","lemma":"passablement","pos":"ADV"} ,
		{"word":"passagèrement","word_nosc":"passagerement","lemma":"passagèrement","pos":"ADV"} ,
		{"word":"passim","word_nosc":"passim","lemma":"passim","pos":"ADV"} ,
		{"word":"passionnellement","word_nosc":"passionnellement","lemma":"passionnellement","pos":"ADV"} ,
		{"word":"passionnément","word_nosc":"passionnement","lemma":"passionnément","pos":"ADV"} ,
		{"word":"passivement","word_nosc":"passivement","lemma":"passivement","pos":"ADV"} ,
		{"word":"passé","word_nosc":"passe","lemma":"passé","pos":"ADV"} ,
		{"word":"paternellement","word_nosc":"paternellement","lemma":"paternellement","pos":"ADV"} ,
		{"word":"pathologiquement","word_nosc":"pathologiquement","lemma":"pathologiquement","pos":"ADV"} ,
		{"word":"pathétiquement","word_nosc":"pathetiquement","lemma":"pathétiquement","pos":"ADV"} ,
		{"word":"patiemment","word_nosc":"patiemment","lemma":"patiemment","pos":"ADV"} ,
		{"word":"patri","word_nosc":"patri","lemma":"patri","pos":"ADV"} ,
		{"word":"patriotiquement","word_nosc":"patriotiquement","lemma":"patriotiquement","pos":"ADV"} ,
		{"word":"pauvrement","word_nosc":"pauvrement","lemma":"pauvrement","pos":"ADV"} ,
		{"word":"peinardement","word_nosc":"peinardement","lemma":"peinardement","pos":"ADV"} ,
		{"word":"pensivement","word_nosc":"pensivement","lemma":"pensivement","pos":"ADV"} ,
		{"word":"perfidement","word_nosc":"perfidement","lemma":"perfidement","pos":"ADV"} ,
		{"word":"perpendiculairement","word_nosc":"perpendiculairement","lemma":"perpendiculairement","pos":"ADV"} ,
		{"word":"perpète","word_nosc":"perpete","lemma":"perpète","pos":"ADV"} ,
		{"word":"perpétuellement","word_nosc":"perpetuellement","lemma":"perpétuellement","pos":"ADV"} ,
		{"word":"personnellement","word_nosc":"personnellement","lemma":"personnellement","pos":"ADV"} ,
		{"word":"persévéramment","word_nosc":"perseveramment","lemma":"persévéramment","pos":"ADV"} ,
		{"word":"pertinemment","word_nosc":"pertinemment","lemma":"pertinemment","pos":"ADV"} ,
		{"word":"perversement","word_nosc":"perversement","lemma":"perversement","pos":"ADV"} ,
		{"word":"pesamment","word_nosc":"pesamment","lemma":"pesamment","pos":"ADV"} ,
		{"word":"petit","word_nosc":"petit","lemma":"petit","pos":"ADV"} ,
		{"word":"petitement","word_nosc":"petitement","lemma":"petitement","pos":"ADV"} ,
		{"word":"peu","word_nosc":"peu","lemma":"peu","pos":"ADV"} ,
		{"word":"peu ou prou","word_nosc":"peu ou prou","lemma":"peu ou prou","pos":"ADV"} ,
		{"word":"peureusement","word_nosc":"peureusement","lemma":"peureusement","pos":"ADV"} ,
		{"word":"peut-être","word_nosc":"peut-etre","lemma":"peut-être","pos":"ADV"} ,
		{"word":"philosophiquement","word_nosc":"philosophiquement","lemma":"philosophiquement","pos":"ADV"} ,
		{"word":"phonétiquement","word_nosc":"phonetiquement","lemma":"phonétiquement","pos":"ADV"} ,
		{"word":"physio","word_nosc":"physio","lemma":"physio","pos":"ADV"} ,
		{"word":"physiologiquement","word_nosc":"physiologiquement","lemma":"physiologiquement","pos":"ADV"} ,
		{"word":"physiquement","word_nosc":"physiquement","lemma":"physiquement","pos":"ADV"} ,
		{"word":"phénoménalement","word_nosc":"phenomenalement","lemma":"phénoménalement","pos":"ADV"} ,
		{"word":"pianissimo","word_nosc":"pianissimo","lemma":"pianissimo","pos":"ADV"} ,
		{"word":"piano","word_nosc":"piano","lemma":"piano","pos":"ADV"} ,
		{"word":"pico","word_nosc":"pico","lemma":"pico","pos":"ADV"} ,
		{"word":"picturalement","word_nosc":"picturalement","lemma":"picturalement","pos":"ADV"} ,
		{"word":"pieusement","word_nosc":"pieusement","lemma":"pieusement","pos":"ADV"} ,
		{"word":"pile","word_nosc":"pile","lemma":"pile","pos":"ADV"} ,
		{"word":"pile-poil","word_nosc":"pile-poil","lemma":"pile-poil","pos":"ADV"} ,
		{"word":"pipo","word_nosc":"pipo","lemma":"pipo","pos":"ADV"} ,
		{"word":"pis","word_nosc":"pis","lemma":"pis","pos":"ADV"} ,
		{"word":"piteusement","word_nosc":"piteusement","lemma":"piteusement","pos":"ADV"} ,
		{"word":"pitoyablement","word_nosc":"pitoyablement","lemma":"pitoyablement","pos":"ADV"} ,
		{"word":"piu","word_nosc":"piu","lemma":"piu","pos":"ADV"} ,
		{"word":"piètrement","word_nosc":"pietrement","lemma":"piètrement","pos":"ADV"} ,
		{"word":"più","word_nosc":"piu","lemma":"più","pos":"ADV"} ,
		{"word":"placidement","word_nosc":"placidement","lemma":"placidement","pos":"ADV"} ,
		{"word":"plaintivement","word_nosc":"plaintivement","lemma":"plaintivement","pos":"ADV"} ,
		{"word":"plaisamment","word_nosc":"plaisamment","lemma":"plaisamment","pos":"ADV"} ,
		{"word":"platement","word_nosc":"platement","lemma":"platement","pos":"ADV"} ,
		{"word":"platoniquement","word_nosc":"platoniquement","lemma":"platoniquement","pos":"ADV"} ,
		{"word":"plausiblement","word_nosc":"plausiblement","lemma":"plausiblement","pos":"ADV"} ,
		{"word":"play","word_nosc":"play","lemma":"play","pos":"ADV"} ,
		{"word":"please","word_nosc":"please","lemma":"please","pos":"ADV"} ,
		{"word":"plein","word_nosc":"plein","lemma":"plein","pos":"ADV"} ,
		{"word":"plein-air","word_nosc":"plein-air","lemma":"plein-air","pos":"ADV"} ,
		{"word":"pleinement","word_nosc":"pleinement","lemma":"pleinement","pos":"ADV"} ,
		{"word":"plue","word_nosc":"plue","lemma":"plue","pos":"ADV"} ,
		{"word":"plupart","word_nosc":"plupart","lemma":"plupart","pos":"ADV"} ,
		{"word":"plus","word_nosc":"plus","lemma":"plus","pos":"ADV"} ,
		{"word":"plutôt","word_nosc":"plutot","lemma":"plutôt","pos":"ADV"} ,
		{"word":"point","word_nosc":"point","lemma":"point","pos":"ADV"} ,
		{"word":"poliment","word_nosc":"poliment","lemma":"poliment","pos":"ADV"} ,
		{"word":"politico","word_nosc":"politico","lemma":"politico","pos":"ADV"} ,
		{"word":"politiquement","word_nosc":"politiquement","lemma":"politiquement","pos":"ADV"} ,
		{"word":"pompeusement","word_nosc":"pompeusement","lemma":"pompeusement","pos":"ADV"} ,
		{"word":"ponctuellement","word_nosc":"ponctuellement","lemma":"ponctuellement","pos":"ADV"} ,
		{"word":"populairement","word_nosc":"populairement","lemma":"populairement","pos":"ADV"} ,
		{"word":"positivement","word_nosc":"positivement","lemma":"positivement","pos":"ADV"} ,
		{"word":"possiblement","word_nosc":"possiblement","lemma":"possiblement","pos":"ADV"} ,
		{"word":"post","word_nosc":"post","lemma":"post","pos":"ADV"} ,
		{"word":"post mortem","word_nosc":"post mortem","lemma":"post mortem","pos":"ADV"} ,
		{"word":"postérieurement","word_nosc":"posterieurement","lemma":"postérieurement","pos":"ADV"} ,
		{"word":"posément","word_nosc":"posement","lemma":"posément","pos":"ADV"} ,
		{"word":"potentiellement","word_nosc":"potentiellement","lemma":"potentiellement","pos":"ADV"} ,
		{"word":"pourquoi","word_nosc":"pourquoi","lemma":"pourquoi","pos":"ADV"} ,
		{"word":"pourtant","word_nosc":"pourtant","lemma":"pourtant","pos":"ADV"} ,
		{"word":"poussivement","word_nosc":"poussivement","lemma":"poussivement","pos":"ADV"} ,
		{"word":"poétiquement","word_nosc":"poetiquement","lemma":"poétiquement","pos":"ADV"} ,
		{"word":"pragmatiquement","word_nosc":"pragmatiquement","lemma":"pragmatiquement","pos":"ADV"} ,
		{"word":"pratiquement","word_nosc":"pratiquement","lemma":"pratiquement","pos":"ADV"} ,
		{"word":"premièrement","word_nosc":"premierement","lemma":"premièrement","pos":"ADV"} ,
		{"word":"presque","word_nosc":"presque","lemma":"presque","pos":"ADV"} ,
		{"word":"prestement","word_nosc":"prestement","lemma":"prestement","pos":"ADV"} ,
		{"word":"presto","word_nosc":"presto","lemma":"presto","pos":"ADV"} ,
		{"word":"primitivement","word_nosc":"primitivement","lemma":"primitivement","pos":"ADV"} ,
		{"word":"primo","word_nosc":"primo","lemma":"primo","pos":"ADV"} ,
		{"word":"primordialement","word_nosc":"primordialement","lemma":"primordialement","pos":"ADV"} ,
		{"word":"principalement","word_nosc":"principalement","lemma":"principalement","pos":"ADV"} ,
		{"word":"princièrement","word_nosc":"princierement","lemma":"princièrement","pos":"ADV"} ,
		{"word":"privément","word_nosc":"privement","lemma":"privément","pos":"ADV"} ,
		{"word":"probablement","word_nosc":"probablement","lemma":"probablement","pos":"ADV"} ,
		{"word":"prochainement","word_nosc":"prochainement","lemma":"prochainement","pos":"ADV"} ,
		{"word":"prodigieusement","word_nosc":"prodigieusement","lemma":"prodigieusement","pos":"ADV"} ,
		{"word":"professionnellement","word_nosc":"professionnellement","lemma":"professionnellement","pos":"ADV"} ,
		{"word":"profond","word_nosc":"profond","lemma":"profond","pos":"ADV"} ,
		{"word":"profondément","word_nosc":"profondement","lemma":"profondément","pos":"ADV"} ,
		{"word":"progressivement","word_nosc":"progressivement","lemma":"progressivement","pos":"ADV"} ,
		{"word":"promptement","word_nosc":"promptement","lemma":"promptement","pos":"ADV"} ,
		{"word":"prompto","word_nosc":"prompto","lemma":"prompto","pos":"ADV"} ,
		{"word":"prophétiquement","word_nosc":"prophetiquement","lemma":"prophétiquement","pos":"ADV"} ,
		{"word":"proportionnellement","word_nosc":"proportionnellement","lemma":"proportionnellement","pos":"ADV"} ,
		{"word":"proprement","word_nosc":"proprement","lemma":"proprement","pos":"ADV"} ,
		{"word":"prosaïquement","word_nosc":"prosaiquement","lemma":"prosaïquement","pos":"ADV"} ,
		{"word":"providentiellement","word_nosc":"providentiellement","lemma":"providentiellement","pos":"ADV"} ,
		{"word":"provincialement","word_nosc":"provincialement","lemma":"provincialement","pos":"ADV"} ,
		{"word":"provisoirement","word_nosc":"provisoirement","lemma":"provisoirement","pos":"ADV"} ,
		{"word":"prudemment","word_nosc":"prudemment","lemma":"prudemment","pos":"ADV"} ,
		{"word":"près","word_nosc":"pres","lemma":"près","pos":"ADV"} ,
		{"word":"près de","word_nosc":"pres de","lemma":"près de","pos":"ADV"} ,
		{"word":"préalablement","word_nosc":"prealablement","lemma":"préalablement","pos":"ADV"} ,
		{"word":"précairement","word_nosc":"precairement","lemma":"précairement","pos":"ADV"} ,
		{"word":"précautionneusement","word_nosc":"precautionneusement","lemma":"précautionneusement","pos":"ADV"} ,
		{"word":"précieusement","word_nosc":"precieusement","lemma":"précieusement","pos":"ADV"} ,
		{"word":"précipitamment","word_nosc":"precipitamment","lemma":"précipitamment","pos":"ADV"} ,
		{"word":"précisément","word_nosc":"precisement","lemma":"précisément","pos":"ADV"} ,
		{"word":"précocement","word_nosc":"precocement","lemma":"précocement","pos":"ADV"} ,
		{"word":"précédemment","word_nosc":"precedemment","lemma":"précédemment","pos":"ADV"} ,
		{"word":"préférablement","word_nosc":"preferablement","lemma":"préférablement","pos":"ADV"} ,
		{"word":"prématurément","word_nosc":"prematurement","lemma":"prématurément","pos":"ADV"} ,
		{"word":"présentement","word_nosc":"presentement","lemma":"présentement","pos":"ADV"} ,
		{"word":"prétendument","word_nosc":"pretendument","lemma":"prétendument","pos":"ADV"} ,
		{"word":"préventivement","word_nosc":"preventivement","lemma":"préventivement","pos":"ADV"} ,
		{"word":"psychiquement","word_nosc":"psychiquement","lemma":"psychiquement","pos":"ADV"} ,
		{"word":"psycho","word_nosc":"psycho","lemma":"psycho","pos":"ADV"} ,
		{"word":"psychologiquement","word_nosc":"psychologiquement","lemma":"psychologiquement","pos":"ADV"} ,
		{"word":"publicitairement","word_nosc":"publicitairement","lemma":"publicitairement","pos":"ADV"} ,
		{"word":"publico","word_nosc":"publico","lemma":"publico","pos":"ADV"} ,
		{"word":"publiquement","word_nosc":"publiquement","lemma":"publiquement","pos":"ADV"} ,
		{"word":"pudiquement","word_nosc":"pudiquement","lemma":"pudiquement","pos":"ADV"} ,
		{"word":"puis","word_nosc":"puis","lemma":"puis","pos":"ADV"} ,
		{"word":"puisque","word_nosc":"puisque","lemma":"puisque","pos":"ADV"} ,
		{"word":"puissamment","word_nosc":"puissamment","lemma":"puissamment","pos":"ADV"} ,
		{"word":"purement","word_nosc":"purement","lemma":"purement","pos":"ADV"} ,
		{"word":"puérilement","word_nosc":"puerilement","lemma":"puérilement","pos":"ADV"} ,
		{"word":"pyramidalement","word_nosc":"pyramidalement","lemma":"pyramidalement","pos":"ADV"} ,
		{"word":"pâlement","word_nosc":"palement","lemma":"pâlement","pos":"ADV"} ,
		{"word":"pâteusement","word_nosc":"pateusement","lemma":"pâteusement","pos":"ADV"} ,
		{"word":"pécuniairement","word_nosc":"pecuniairement","lemma":"pécuniairement","pos":"ADV"} ,
		{"word":"pédestrement","word_nosc":"pedestrement","lemma":"pédestrement","pos":"ADV"} ,
		{"word":"pédibus","word_nosc":"pedibus","lemma":"pédibus","pos":"ADV"} ,
		{"word":"péjorativement","word_nosc":"pejorativement","lemma":"péjorativement","pos":"ADV"} ,
		{"word":"pénalement","word_nosc":"penalement","lemma":"pénalement","pos":"ADV"} ,
		{"word":"pénardement","word_nosc":"penardement","lemma":"pénardement","pos":"ADV"} ,
		{"word":"péniblement","word_nosc":"peniblement","lemma":"péniblement","pos":"ADV"} ,
		{"word":"péremptoirement","word_nosc":"peremptoirement","lemma":"péremptoirement","pos":"ADV"} ,
		{"word":"périlleusement","word_nosc":"perilleusement","lemma":"périlleusement","pos":"ADV"} ,
		{"word":"périodiquement","word_nosc":"periodiquement","lemma":"périodiquement","pos":"ADV"} ,
		{"word":"pêle-mêle","word_nosc":"pele-mele","lemma":"pêle-mêle","pos":"ADV"} ,
		{"word":"quand","word_nosc":"quand","lemma":"quand","pos":"ADV"} ,
		{"word":"quantitativement","word_nosc":"quantitativement","lemma":"quantitativement","pos":"ADV"} ,
		{"word":"quanto","word_nosc":"quanto","lemma":"quanto","pos":"ADV"} ,
		{"word":"quarto","word_nosc":"quarto","lemma":"quarto","pos":"ADV"} ,
		{"word":"quasi","word_nosc":"quasi","lemma":"quasi","pos":"ADV"} ,
		{"word":"quasi-débutant","word_nosc":"quasi-debutant","lemma":"quasi-débutant","pos":"ADV"} ,
		{"word":"quasi-inconnu","word_nosc":"quasi-inconnu","lemma":"quasi-inconnu","pos":"ADV"} ,
		{"word":"quasi-instantanée","word_nosc":"quasi-instantanee","lemma":"quasi-instantanée","pos":"ADV"} ,
		{"word":"quasi-maximum","word_nosc":"quasi-maximum","lemma":"quasi-maximum","pos":"ADV"} ,
		{"word":"quasi-voisines","word_nosc":"quasi-voisines","lemma":"quasi-voisines","pos":"ADV"} ,
		{"word":"quasiment","word_nosc":"quasiment","lemma":"quasiment","pos":"ADV"} ,
		{"word":"quater","word_nosc":"quater","lemma":"quater","pos":"ADV"} ,
		{"word":"quatrièmement","word_nosc":"quatriemement","lemma":"quatrièmement","pos":"ADV"} ,
		{"word":"quelque","word_nosc":"quelque","lemma":"quelque","pos":"ADV"} ,
		{"word":"quelquefois","word_nosc":"quelquefois","lemma":"quelquefois","pos":"ADV"} ,
		{"word":"quinto","word_nosc":"quinto","lemma":"quinto","pos":"ADV"} ,
		{"word":"quiètement","word_nosc":"quietement","lemma":"quiètement","pos":"ADV"} ,
		{"word":"quoique","word_nosc":"quoique","lemma":"quoique","pos":"ADV"} ,
		{"word":"quotidiennement","word_nosc":"quotidiennement","lemma":"quotidiennement","pos":"ADV"} ,
		{"word":"racialement","word_nosc":"racialement","lemma":"racialement","pos":"ADV"} ,
		{"word":"racisme","word_nosc":"racisme","lemma":"racisme","pos":"ADV"} ,
		{"word":"radicalement","word_nosc":"radicalement","lemma":"radicalement","pos":"ADV"} ,
		{"word":"radieusement","word_nosc":"radieusement","lemma":"radieusement","pos":"ADV"} ,
		{"word":"rageusement","word_nosc":"rageusement","lemma":"rageusement","pos":"ADV"} ,
		{"word":"raide","word_nosc":"raide","lemma":"raide","pos":"ADV"} ,
		{"word":"raidement","word_nosc":"raidement","lemma":"raidement","pos":"ADV"} ,
		{"word":"raisonnablement","word_nosc":"raisonnablement","lemma":"raisonnablement","pos":"ADV"} ,
		{"word":"rapidement","word_nosc":"rapidement","lemma":"rapidement","pos":"ADV"} ,
		{"word":"rapidos","word_nosc":"rapidos","lemma":"rapidos","pos":"ADV"} ,
		{"word":"rarement","word_nosc":"rarement","lemma":"rarement","pos":"ADV"} ,
		{"word":"rarissimement","word_nosc":"rarissimement","lemma":"rarissimement","pos":"ADV"} ,
		{"word":"ras","word_nosc":"ras","lemma":"ras","pos":"ADV"} ,
		{"word":"rasibus","word_nosc":"rasibus","lemma":"rasibus","pos":"ADV"} ,
		{"word":"rationnellement","word_nosc":"rationnellement","lemma":"rationnellement","pos":"ADV"} ,
		{"word":"re","word_nosc":"re","lemma":"r","pos":"ADV"} ,
		{"word":"rebours","word_nosc":"rebours","lemma":"rebours","pos":"ADV"} ,
		{"word":"recta","word_nosc":"recta","lemma":"recta","pos":"ADV"} ,
		{"word":"recto tono","word_nosc":"recto tono","lemma":"recto tono","pos":"ADV"} ,
		{"word":"redoutablement","word_nosc":"redoutablement","lemma":"redoutablement","pos":"ADV"} ,
		{"word":"regrettablement","word_nosc":"regrettablement","lemma":"regrettablement","pos":"ADV"} ,
		{"word":"relativement","word_nosc":"relativement","lemma":"relativement","pos":"ADV"} ,
		{"word":"religieusement","word_nosc":"religieusement","lemma":"religieusement","pos":"ADV"} ,
		{"word":"remarquablement","word_nosc":"remarquablement","lemma":"remarquablement","pos":"ADV"} ,
		{"word":"respectivement","word_nosc":"respectivement","lemma":"respectivement","pos":"ADV"} ,
		{"word":"respectueusement","word_nosc":"respectueusement","lemma":"respectueusement","pos":"ADV"} ,
		{"word":"ric et rac","word_nosc":"ric et rac","lemma":"ric et rac","pos":"ADV"} ,
		{"word":"ric-rac","word_nosc":"ric-rac","lemma":"ric-rac","pos":"ADV"} ,
		{"word":"ric-à-rac","word_nosc":"ric-a-rac","lemma":"ric-à-rac","pos":"ADV"} ,
		{"word":"richement","word_nosc":"richement","lemma":"richement","pos":"ADV"} ,
		{"word":"ridiculement","word_nosc":"ridiculement","lemma":"ridiculement","pos":"ADV"} ,
		{"word":"rigidement","word_nosc":"rigidement","lemma":"rigidement","pos":"ADV"} ,
		{"word":"rigoureusement","word_nosc":"rigoureusement","lemma":"rigoureusement","pos":"ADV"} ,
		{"word":"rinforzando","word_nosc":"rinforzando","lemma":"rinforzando","pos":"ADV"} ,
		{"word":"risiblement","word_nosc":"risiblement","lemma":"risiblement","pos":"ADV"} ,
		{"word":"rituellement","word_nosc":"rituellement","lemma":"rituellement","pos":"ADV"} ,
		{"word":"robustement","word_nosc":"robustement","lemma":"robustement","pos":"ADV"} ,
		{"word":"roi","word_nosc":"roi","lemma":"roi","pos":"ADV"} ,
		{"word":"roidement","word_nosc":"roidement","lemma":"roidement","pos":"ADV"} ,
		{"word":"romantiquement","word_nosc":"romantiquement","lemma":"romantiquement","pos":"ADV"} ,
		{"word":"rondement","word_nosc":"rondement","lemma":"rondement","pos":"ADV"} ,
		{"word":"royalement","word_nosc":"royalement","lemma":"royalement","pos":"ADV"} ,
		{"word":"rubato","word_nosc":"rubato","lemma":"rubato","pos":"ADV"} ,
		{"word":"rudement","word_nosc":"rudement","lemma":"rudement","pos":"ADV"} ,
		{"word":"rythmiquement","word_nosc":"rythmiquement","lemma":"rythmiquement","pos":"ADV"} ,
		{"word":"récemment","word_nosc":"recemment","lemma":"récemment","pos":"ADV"} ,
		{"word":"réciproquement","word_nosc":"reciproquement","lemma":"réciproquement","pos":"ADV"} ,
		{"word":"réellement","word_nosc":"reellement","lemma":"réellement","pos":"ADV"} ,
		{"word":"réglementairement","word_nosc":"reglementairement","lemma":"réglementairement","pos":"ADV"} ,
		{"word":"régulièrement","word_nosc":"regulierement","lemma":"régulièrement","pos":"ADV"} ,
		{"word":"résolument","word_nosc":"resolument","lemma":"résolument","pos":"ADV"} ,
		{"word":"rétroactivement","word_nosc":"retroactivement","lemma":"rétroactivement","pos":"ADV"} ,
		{"word":"rétrospectivement","word_nosc":"retrospectivement","lemma":"rétrospectivement","pos":"ADV"} ,
		{"word":"révolutionnairement","word_nosc":"revolutionnairement","lemma":"révolutionnairement","pos":"ADV"} ,
		{"word":"révérencieusement","word_nosc":"reverencieusement","lemma":"révérencieusement","pos":"ADV"} ,
		{"word":"rêveusement","word_nosc":"reveusement","lemma":"rêveusement","pos":"ADV"} ,
		{"word":"sacro","word_nosc":"sacro","lemma":"sacro","pos":"ADV"} ,
		{"word":"sacrément","word_nosc":"sacrement","lemma":"sacrément","pos":"ADV"} ,
		{"word":"sadiquement","word_nosc":"sadiquement","lemma":"sadiquement","pos":"ADV"} ,
		{"word":"sagement","word_nosc":"sagement","lemma":"sagement","pos":"ADV"} ,
		{"word":"sainement","word_nosc":"sainement","lemma":"sainement","pos":"ADV"} ,
		{"word":"saintement","word_nosc":"saintement","lemma":"saintement","pos":"ADV"} ,
		{"word":"salement","word_nosc":"salement","lemma":"salement","pos":"ADV"} ,
		{"word":"salutairement","word_nosc":"salutairement","lemma":"salutairement","pos":"ADV"} ,
		{"word":"sarcastiquement","word_nosc":"sarcastiquement","lemma":"sarcastiquement","pos":"ADV"} ,
		{"word":"sardoniquement","word_nosc":"sardoniquement","lemma":"sardoniquement","pos":"ADV"} ,
		{"word":"sauvagement","word_nosc":"sauvagement","lemma":"sauvagement","pos":"ADV"} ,
		{"word":"savamment","word_nosc":"savamment","lemma":"savamment","pos":"ADV"} ,
		{"word":"savoureusement","word_nosc":"savoureusement","lemma":"savoureusement","pos":"ADV"} ,
		{"word":"scandaleusement","word_nosc":"scandaleusement","lemma":"scandaleusement","pos":"ADV"} ,
		{"word":"schématiquement","word_nosc":"schematiquement","lemma":"schématiquement","pos":"ADV"} ,
		{"word":"sciemment","word_nosc":"sciemment","lemma":"sciemment","pos":"ADV"} ,
		{"word":"scientifiquement","word_nosc":"scientifiquement","lemma":"scientifiquement","pos":"ADV"} ,
		{"word":"scolairement","word_nosc":"scolairement","lemma":"scolairement","pos":"ADV"} ,
		{"word":"scrupuleusement","word_nosc":"scrupuleusement","lemma":"scrupuleusement","pos":"ADV"} ,
		{"word":"sec","word_nosc":"sec","lemma":"sec","pos":"ADV"} ,
		{"word":"secondairement","word_nosc":"secondairement","lemma":"secondairement","pos":"ADV"} ,
		{"word":"secondement","word_nosc":"secondement","lemma":"secondement","pos":"ADV"} ,
		{"word":"secrètement","word_nosc":"secretement","lemma":"secrètement","pos":"ADV"} ,
		{"word":"secundo","word_nosc":"secundo","lemma":"secundo","pos":"ADV"} ,
		{"word":"semblablement","word_nosc":"semblablement","lemma":"semblablement","pos":"ADV"} ,
		{"word":"semestriellement","word_nosc":"semestriellement","lemma":"semestriellement","pos":"ADV"} ,
		{"word":"sempiternellement","word_nosc":"sempiternellement","lemma":"sempiternellement","pos":"ADV"} ,
		{"word":"sensationnellement","word_nosc":"sensationnellement","lemma":"sensationnellement","pos":"ADV"} ,
		{"word":"sensiblement","word_nosc":"sensiblement","lemma":"sensiblement","pos":"ADV"} ,
		{"word":"sensuellement","word_nosc":"sensuellement","lemma":"sensuellement","pos":"ADV"} ,
		{"word":"sensément","word_nosc":"sensement","lemma":"sensément","pos":"ADV"} ,
		{"word":"sentencieusement","word_nosc":"sentencieusement","lemma":"sentencieusement","pos":"ADV"} ,
		{"word":"sentimentalement","word_nosc":"sentimentalement","lemma":"sentimentalement","pos":"ADV"} ,
		{"word":"sereinement","word_nosc":"sereinement","lemma":"sereinement","pos":"ADV"} ,
		{"word":"servilement","word_nosc":"servilement","lemma":"servilement","pos":"ADV"} ,
		{"word":"seulement","word_nosc":"seulement","lemma":"seulement","pos":"ADV"} ,
		{"word":"sexto","word_nosc":"sexto","lemma":"sexto","pos":"ADV"} ,
		{"word":"sexuellement","word_nosc":"sexuellement","lemma":"sexuellement","pos":"ADV"} ,
		{"word":"si","word_nosc":"si","lemma":"si","pos":"ADV"} ,
		{"word":"sic","word_nosc":"sic","lemma":"sic","pos":"ADV"} ,
		{"word":"sic transit gloria mundi","word_nosc":"sic transit gloria mundi","lemma":"sic transit gloria mundi","pos":"ADV"} ,
		{"word":"significativement","word_nosc":"significativement","lemma":"significativement","pos":"ADV"} ,
		{"word":"silencieusement","word_nosc":"silencieusement","lemma":"silencieusement","pos":"ADV"} ,
		{"word":"similairement","word_nosc":"similairement","lemma":"similairement","pos":"ADV"} ,
		{"word":"simplement","word_nosc":"simplement","lemma":"simplement","pos":"ADV"} ,
		{"word":"simultanément","word_nosc":"simultanement","lemma":"simultanément","pos":"ADV"} ,
		{"word":"sincèrement","word_nosc":"sincerement","lemma":"sincèrement","pos":"ADV"} ,
		{"word":"sine die","word_nosc":"sine die","lemma":"sine die","pos":"ADV"} ,
		{"word":"sine qua non","word_nosc":"sine qua non","lemma":"sine qua non","pos":"ADV"} ,
		{"word":"singulièrement","word_nosc":"singulierement","lemma":"singulièrement","pos":"ADV"} ,
		{"word":"sinistrement","word_nosc":"sinistrement","lemma":"sinistrement","pos":"ADV"} ,
		{"word":"sino","word_nosc":"sino","lemma":"sino","pos":"ADV"} ,
		{"word":"sinon","word_nosc":"sinon","lemma":"sinon","pos":"ADV"} ,
		{"word":"sinueusement","word_nosc":"sinueusement","lemma":"sinueusement","pos":"ADV"} ,
		{"word":"sitcom","word_nosc":"sitcom","lemma":"sitcom","pos":"ADV"} ,
		{"word":"sitôt","word_nosc":"sitot","lemma":"sitôt","pos":"ADV"} ,
		{"word":"sixièmement","word_nosc":"sixiemement","lemma":"sixièmement","pos":"ADV"} ,
		{"word":"sobrement","word_nosc":"sobrement","lemma":"sobrement","pos":"ADV"} ,
		{"word":"socialement","word_nosc":"socialement","lemma":"socialement","pos":"ADV"} ,
		{"word":"socio","word_nosc":"socio","lemma":"socio","pos":"ADV"} ,
		{"word":"sociologiquement","word_nosc":"sociologiquement","lemma":"sociologiquement","pos":"ADV"} ,
		{"word":"soigneusement","word_nosc":"soigneusement","lemma":"soigneusement","pos":"ADV"} ,
		{"word":"soit","word_nosc":"soit","lemma":"soit","pos":"ADV"} ,
		{"word":"solennellement","word_nosc":"solennellement","lemma":"solennellement","pos":"ADV"} ,
		{"word":"solidairement","word_nosc":"solidairement","lemma":"solidairement","pos":"ADV"} ,
		{"word":"solidement","word_nosc":"solidement","lemma":"solidement","pos":"ADV"} ,
		{"word":"solitairement","word_nosc":"solitairement","lemma":"solitairement","pos":"ADV"} ,
		{"word":"sombrement","word_nosc":"sombrement","lemma":"sombrement","pos":"ADV"} ,
		{"word":"sommairement","word_nosc":"sommairement","lemma":"sommairement","pos":"ADV"} ,
		{"word":"somnambuliquement","word_nosc":"somnambuliquement","lemma":"somnambuliquement","pos":"ADV"} ,
		{"word":"somptueusement","word_nosc":"somptueusement","lemma":"somptueusement","pos":"ADV"} ,
		{"word":"songeusement","word_nosc":"songeusement","lemma":"songeusement","pos":"ADV"} ,
		{"word":"sonorement","word_nosc":"sonorement","lemma":"sonorement","pos":"ADV"} ,
		{"word":"sordidement","word_nosc":"sordidement","lemma":"sordidement","pos":"ADV"} ,
		{"word":"sostenuto","word_nosc":"sostenuto","lemma":"sostenuto","pos":"ADV"} ,
		{"word":"sottement","word_nosc":"sottement","lemma":"sottement","pos":"ADV"} ,
		{"word":"soudain","word_nosc":"soudain","lemma":"soudain","pos":"ADV"} ,
		{"word":"soudainement","word_nosc":"soudainement","lemma":"soudainement","pos":"ADV"} ,
		{"word":"souplement","word_nosc":"souplement","lemma":"souplement","pos":"ADV"} ,
		{"word":"sourdement","word_nosc":"sourdement","lemma":"sourdement","pos":"ADV"} ,
		{"word":"sournoisement","word_nosc":"sournoisement","lemma":"sournoisement","pos":"ADV"} ,
		{"word":"souterrainement","word_nosc":"souterrainement","lemma":"souterrainement","pos":"ADV"} ,
		{"word":"souvent","word_nosc":"souvent","lemma":"souvent","pos":"ADV"} ,
		{"word":"souventefois","word_nosc":"souventefois","lemma":"souventefois","pos":"ADV"} ,
		{"word":"souventes fois","word_nosc":"souventes fois","lemma":"souventes fois","pos":"ADV"} ,
		{"word":"souverainement","word_nosc":"souverainement","lemma":"souverainement","pos":"ADV"} ,
		{"word":"soyeusement","word_nosc":"soyeusement","lemma":"soyeusement","pos":"ADV"} ,
		{"word":"spasmodiquement","word_nosc":"spasmodiquement","lemma":"spasmodiquement","pos":"ADV"} ,
		{"word":"spatialement","word_nosc":"spatialement","lemma":"spatialement","pos":"ADV"} ,
		{"word":"spectaculairement","word_nosc":"spectaculairement","lemma":"spectaculairement","pos":"ADV"} ,
		{"word":"sphériquement","word_nosc":"spheriquement","lemma":"sphériquement","pos":"ADV"} ,
		{"word":"spirituellement","word_nosc":"spirituellement","lemma":"spirituellement","pos":"ADV"} ,
		{"word":"splendidement","word_nosc":"splendidement","lemma":"splendidement","pos":"ADV"} ,
		{"word":"spontanément","word_nosc":"spontanement","lemma":"spontanément","pos":"ADV"} ,
		{"word":"sporadiquement","word_nosc":"sporadiquement","lemma":"sporadiquement","pos":"ADV"} ,
		{"word":"sportivement","word_nosc":"sportivement","lemma":"sportivement","pos":"ADV"} ,
		{"word":"spécialement","word_nosc":"specialement","lemma":"spécialement","pos":"ADV"} ,
		{"word":"spécieusement","word_nosc":"specieusement","lemma":"spécieusement","pos":"ADV"} ,
		{"word":"spécifiquement","word_nosc":"specifiquement","lemma":"spécifiquement","pos":"ADV"} ,
		{"word":"stablement","word_nosc":"stablement","lemma":"stablement","pos":"ADV"} ,
		{"word":"stalino","word_nosc":"stalino","lemma":"stalino","pos":"ADV"} ,
		{"word":"statistiquement","word_nosc":"statistiquement","lemma":"statistiquement","pos":"ADV"} ,
		{"word":"stoïquement","word_nosc":"stoiquement","lemma":"stoïquement","pos":"ADV"} ,
		{"word":"stratégiquement","word_nosc":"strategiquement","lemma":"stratégiquement","pos":"ADV"} ,
		{"word":"strictement","word_nosc":"strictement","lemma":"strictement","pos":"ADV"} ,
		{"word":"stricto sensu","word_nosc":"stricto sensu","lemma":"stricto sensu","pos":"ADV"} ,
		{"word":"structuralement","word_nosc":"structuralement","lemma":"structuralement","pos":"ADV"} ,
		{"word":"structurellement","word_nosc":"structurellement","lemma":"structurellement","pos":"ADV"} ,
		{"word":"studieusement","word_nosc":"studieusement","lemma":"studieusement","pos":"ADV"} ,
		{"word":"stupidement","word_nosc":"stupidement","lemma":"stupidement","pos":"ADV"} ,
		{"word":"stérilement","word_nosc":"sterilement","lemma":"stérilement","pos":"ADV"} ,
		{"word":"suavement","word_nosc":"suavement","lemma":"suavement","pos":"ADV"} ,
		{"word":"sub","word_nosc":"sub","lemma":"sub","pos":"ADV"} ,
		{"word":"subconsciemment","word_nosc":"subconsciemment","lemma":"subconsciemment","pos":"ADV"} ,
		{"word":"subitement","word_nosc":"subitement","lemma":"subitement","pos":"ADV"} ,
		{"word":"subito","word_nosc":"subito","lemma":"subito","pos":"ADV"} ,
		{"word":"subjectivement","word_nosc":"subjectivement","lemma":"subjectivement","pos":"ADV"} ,
		{"word":"sublimement","word_nosc":"sublimement","lemma":"sublimement","pos":"ADV"} ,
		{"word":"subrepticement","word_nosc":"subrepticement","lemma":"subrepticement","pos":"ADV"} ,
		{"word":"subsidiairement","word_nosc":"subsidiairement","lemma":"subsidiairement","pos":"ADV"} ,
		{"word":"substantiellement","word_nosc":"substantiellement","lemma":"substantiellement","pos":"ADV"} ,
		{"word":"subséquemment","word_nosc":"subsequemment","lemma":"subséquemment","pos":"ADV"} ,
		{"word":"subtilement","word_nosc":"subtilement","lemma":"subtilement","pos":"ADV"} ,
		{"word":"successivement","word_nosc":"successivement","lemma":"successivement","pos":"ADV"} ,
		{"word":"succinctement","word_nosc":"succinctement","lemma":"succinctement","pos":"ADV"} ,
		{"word":"sufficit","word_nosc":"sufficit","lemma":"sufficit","pos":"ADV"} ,
		{"word":"suffisamment","word_nosc":"suffisamment","lemma":"suffisamment","pos":"ADV"} ,
		{"word":"suivant","word_nosc":"suivant","lemma":"suivant","pos":"ADV"} ,
		{"word":"superbement","word_nosc":"superbement","lemma":"superbement","pos":"ADV"} ,
		{"word":"superficiellement","word_nosc":"superficiellement","lemma":"superficiellement","pos":"ADV"} ,
		{"word":"superlativement","word_nosc":"superlativement","lemma":"superlativement","pos":"ADV"} ,
		{"word":"superstitieusement","word_nosc":"superstitieusement","lemma":"superstitieusement","pos":"ADV"} ,
		{"word":"supposément","word_nosc":"supposement","lemma":"supposément","pos":"ADV"} ,
		{"word":"supra","word_nosc":"supra","lemma":"supra","pos":"ADV"} ,
		{"word":"suprêmement","word_nosc":"supremement","lemma":"suprêmement","pos":"ADV"} ,
		{"word":"supérieurement","word_nosc":"superieurement","lemma":"supérieurement","pos":"ADV"} ,
		{"word":"sur-le-champ","word_nosc":"sur-le-champ","lemma":"sur-le-champ","pos":"ADV"} ,
		{"word":"surabondamment","word_nosc":"surabondamment","lemma":"surabondamment","pos":"ADV"} ,
		{"word":"sursum corda","word_nosc":"sursum corda","lemma":"sursum corda","pos":"ADV"} ,
		{"word":"surtout","word_nosc":"surtout","lemma":"surtout","pos":"ADV"} ,
		{"word":"sus","word_nosc":"sus","lemma":"sus","pos":"ADV"} ,
		{"word":"suspicieusement","word_nosc":"suspicieusement","lemma":"suspicieusement","pos":"ADV"} ,
		{"word":"svp","word_nosc":"svp","lemma":"svp","pos":"ADV"} ,
		{"word":"symboliquement","word_nosc":"symboliquement","lemma":"symboliquement","pos":"ADV"} ,
		{"word":"symétriquement","word_nosc":"symetriquement","lemma":"symétriquement","pos":"ADV"} ,
		{"word":"synthétiquement","word_nosc":"synthetiquement","lemma":"synthétiquement","pos":"ADV"} ,
		{"word":"syro","word_nosc":"syro","lemma":"syro","pos":"ADV"} ,
		{"word":"systématiquement","word_nosc":"systematiquement","lemma":"systématiquement","pos":"ADV"} ,
		{"word":"sèchement","word_nosc":"sechement","lemma":"sèchement","pos":"ADV"} ,
		{"word":"séculairement","word_nosc":"seculairement","lemma":"séculairement","pos":"ADV"} ,
		{"word":"sélectivement","word_nosc":"selectivement","lemma":"sélectivement","pos":"ADV"} ,
		{"word":"sénilement","word_nosc":"senilement","lemma":"sénilement","pos":"ADV"} ,
		{"word":"séparément","word_nosc":"separement","lemma":"séparément","pos":"ADV"} ,
		{"word":"séquentiellement","word_nosc":"sequentiellement","lemma":"séquentiellement","pos":"ADV"} ,
		{"word":"sérieusement","word_nosc":"serieusement","lemma":"sérieusement","pos":"ADV"} ,
		{"word":"sévèrement","word_nosc":"severement","lemma":"sévèrement","pos":"ADV"} ,
		{"word":"sûrement","word_nosc":"surement","lemma":"sûrement","pos":"ADV"} ,
		{"word":"tacitement","word_nosc":"tacitement","lemma":"tacitement","pos":"ADV"} ,
		{"word":"tactilement","word_nosc":"tactilement","lemma":"tactilement","pos":"ADV"} ,
		{"word":"tactiquement","word_nosc":"tactiquement","lemma":"tactiquement","pos":"ADV"} ,
		{"word":"talentueusement","word_nosc":"talentueusement","lemma":"talentueusement","pos":"ADV"} ,
		{"word":"tandis que","word_nosc":"tandis que","lemma":"tandis que","pos":"ADV"} ,
		{"word":"tant","word_nosc":"tant","lemma":"tant","pos":"ADV"} ,
		{"word":"tantôt","word_nosc":"tantot","lemma":"tantôt","pos":"ADV"} ,
		{"word":"tard","word_nosc":"tard","lemma":"tard","pos":"ADV"} ,
		{"word":"tardivement","word_nosc":"tardivement","lemma":"tardivement","pos":"ADV"} ,
		{"word":"techniquement","word_nosc":"techniquement","lemma":"techniquement","pos":"ADV"} ,
		{"word":"technologiquement","word_nosc":"technologiquement","lemma":"technologiquement","pos":"ADV"} ,
		{"word":"tellement","word_nosc":"tellement","lemma":"tellement","pos":"ADV"} ,
		{"word":"temporairement","word_nosc":"temporairement","lemma":"temporairement","pos":"ADV"} ,
		{"word":"temporellement","word_nosc":"temporellement","lemma":"temporellement","pos":"ADV"} ,
		{"word":"tenacement","word_nosc":"tenacement","lemma":"tenacement","pos":"ADV"} ,
		{"word":"tendancieusement","word_nosc":"tendancieusement","lemma":"tendancieusement","pos":"ADV"} ,
		{"word":"tendrement","word_nosc":"tendrement","lemma":"tendrement","pos":"ADV"} ,
		{"word":"ter","word_nosc":"ter","lemma":"ter","pos":"ADV"} ,
		{"word":"terriblement","word_nosc":"terriblement","lemma":"terriblement","pos":"ADV"} ,
		{"word":"territorialement","word_nosc":"territorialement","lemma":"territorialement","pos":"ADV"} ,
		{"word":"tertio","word_nosc":"tertio","lemma":"tertio","pos":"ADV"} ,
		{"word":"texto","word_nosc":"texto","lemma":"texto","pos":"ADV"} ,
		{"word":"textuellement","word_nosc":"textuellement","lemma":"textuellement","pos":"ADV"} ,
		{"word":"thématiquement","word_nosc":"thematiquement","lemma":"thématiquement","pos":"ADV"} ,
		{"word":"théologiquement","word_nosc":"theologiquement","lemma":"théologiquement","pos":"ADV"} ,
		{"word":"théoriquement","word_nosc":"theoriquement","lemma":"théoriquement","pos":"ADV"} ,
		{"word":"théâtralement","word_nosc":"theatralement","lemma":"théâtralement","pos":"ADV"} ,
		{"word":"timidement","word_nosc":"timidement","lemma":"timidement","pos":"ADV"} ,
		{"word":"tièdement","word_nosc":"tiedement","lemma":"tièdement","pos":"ADV"} ,
		{"word":"topographiquement","word_nosc":"topographiquement","lemma":"topographiquement","pos":"ADV"} ,
		{"word":"totalement","word_nosc":"totalement","lemma":"totalement","pos":"ADV"} ,
		{"word":"toujours","word_nosc":"toujours","lemma":"toujours","pos":"ADV"} ,
		{"word":"tout","word_nosc":"tout","lemma":"tout","pos":"ADV"} ,
		{"word":"tout-fait","word_nosc":"tout-fait","lemma":"tout-fait","pos":"ADV"} ,
		{"word":"toutefois","word_nosc":"toutefois","lemma":"toutefois","pos":"ADV"} ,
		{"word":"traditionnellement","word_nosc":"traditionnellement","lemma":"traditionnellement","pos":"ADV"} ,
		{"word":"tragiquement","word_nosc":"tragiquement","lemma":"tragiquement","pos":"ADV"} ,
		{"word":"tranquillement","word_nosc":"tranquillement","lemma":"tranquillement","pos":"ADV"} ,
		{"word":"tranquillos","word_nosc":"tranquillos","lemma":"tranquillos","pos":"ADV"} ,
		{"word":"trans","word_nosc":"trans","lemma":"trans","pos":"ADV"} ,
		{"word":"transitoirement","word_nosc":"transitoirement","lemma":"transitoirement","pos":"ADV"} ,
		{"word":"transversalement","word_nosc":"transversalement","lemma":"transversalement","pos":"ADV"} ,
		{"word":"traîtreusement","word_nosc":"traitreusement","lemma":"traîtreusement","pos":"ADV"} ,
		{"word":"triangulairement","word_nosc":"triangulairement","lemma":"triangulairement","pos":"ADV"} ,
		{"word":"trimestriellement","word_nosc":"trimestriellement","lemma":"trimestriellement","pos":"ADV"} ,
		{"word":"triomphalement","word_nosc":"triomphalement","lemma":"triomphalement","pos":"ADV"} ,
		{"word":"triplement","word_nosc":"triplement","lemma":"triplement","pos":"ADV"} ,
		{"word":"tristement","word_nosc":"tristement","lemma":"tristement","pos":"ADV"} ,
		{"word":"trivialement","word_nosc":"trivialement","lemma":"trivialement","pos":"ADV"} ,
		{"word":"troisièmement","word_nosc":"troisiemement","lemma":"troisièmement","pos":"ADV"} ,
		{"word":"trompeusement","word_nosc":"trompeusement","lemma":"trompeusement","pos":"ADV"} ,
		{"word":"trop","word_nosc":"trop","lemma":"trop","pos":"ADV"} ,
		{"word":"très","word_nosc":"tres","lemma":"très","pos":"ADV"} ,
		{"word":"tumultueusement","word_nosc":"tumultueusement","lemma":"tumultueusement","pos":"ADV"} ,
		{"word":"tutti frutti","word_nosc":"tutti frutti","lemma":"tutti frutti","pos":"ADV"} ,
		{"word":"tutti quanti","word_nosc":"tutti quanti","lemma":"tutti quanti","pos":"ADV"} ,
		{"word":"typiquement","word_nosc":"typiquement","lemma":"typiquement","pos":"ADV"} ,
		{"word":"tyranniquement","word_nosc":"tyranniquement","lemma":"tyranniquement","pos":"ADV"} ,
		{"word":"télépathiquement","word_nosc":"telepathiquement","lemma":"télépathiquement","pos":"ADV"} ,
		{"word":"téléphoniquement","word_nosc":"telephoniquement","lemma":"téléphoniquement","pos":"ADV"} ,
		{"word":"témérairement","word_nosc":"temerairement","lemma":"témérairement","pos":"ADV"} ,
		{"word":"tête-bêche","word_nosc":"tete-beche","lemma":"tête-bêche","pos":"ADV"} ,
		{"word":"tôt","word_nosc":"tot","lemma":"tôt","pos":"ADV"} ,
		{"word":"ultimement","word_nosc":"ultimement","lemma":"ultimement","pos":"ADV"} ,
		{"word":"ultimo","word_nosc":"ultimo","lemma":"ultimo","pos":"ADV"} ,
		{"word":"ultérieurement","word_nosc":"ulterieurement","lemma":"ultérieurement","pos":"ADV"} ,
		{"word":"unanimement","word_nosc":"unanimement","lemma":"unanimement","pos":"ADV"} ,
		{"word":"uniformément","word_nosc":"uniformement","lemma":"uniformément","pos":"ADV"} ,
		{"word":"unilatéralement","word_nosc":"unilateralement","lemma":"unilatéralement","pos":"ADV"} ,
		{"word":"uniment","word_nosc":"uniment","lemma":"uniment","pos":"ADV"} ,
		{"word":"uniquement","word_nosc":"uniquement","lemma":"uniquement","pos":"ADV"} ,
		{"word":"universellement","word_nosc":"universellement","lemma":"universellement","pos":"ADV"} ,
		{"word":"upas","word_nosc":"upas","lemma":"upas","pos":"ADV"} ,
		{"word":"urbi et orbi","word_nosc":"urbi et orbi","lemma":"urbi et orbi","pos":"ADV"} ,
		{"word":"urgemment","word_nosc":"urgemment","lemma":"urgemment","pos":"ADV"} ,
		{"word":"usuellement","word_nosc":"usuellement","lemma":"usuellement","pos":"ADV"} ,
		{"word":"utilement","word_nosc":"utilement","lemma":"utilement","pos":"ADV"} ,
		{"word":"vachement","word_nosc":"vachement","lemma":"vachement","pos":"ADV"} ,
		{"word":"vade retro","word_nosc":"vade retro","lemma":"vade retro","pos":"ADV"} ,
		{"word":"vae soli","word_nosc":"vae soli","lemma":"vae soli","pos":"ADV"} ,
		{"word":"vae victis","word_nosc":"vae victis","lemma":"vae victis","pos":"ADV"} ,
		{"word":"vaguement","word_nosc":"vaguement","lemma":"vaguement","pos":"ADV"} ,
		{"word":"vaillamment","word_nosc":"vaillamment","lemma":"vaillamment","pos":"ADV"} ,
		{"word":"vainement","word_nosc":"vainement","lemma":"vainement","pos":"ADV"} ,
		{"word":"valablement","word_nosc":"valablement","lemma":"valablement","pos":"ADV"} ,
		{"word":"valeureusement","word_nosc":"valeureusement","lemma":"valeureusement","pos":"ADV"} ,
		{"word":"vaniteusement","word_nosc":"vaniteusement","lemma":"vaniteusement","pos":"ADV"} ,
		{"word":"venimeusement","word_nosc":"venimeusement","lemma":"venimeusement","pos":"ADV"} ,
		{"word":"verbalement","word_nosc":"verbalement","lemma":"verbalement","pos":"ADV"} ,
		{"word":"vertement","word_nosc":"vertement","lemma":"vertement","pos":"ADV"} ,
		{"word":"verticalement","word_nosc":"verticalement","lemma":"verticalement","pos":"ADV"} ,
		{"word":"vertigineusement","word_nosc":"vertigineusement","lemma":"vertigineusement","pos":"ADV"} ,
		{"word":"vertueusement","word_nosc":"vertueusement","lemma":"vertueusement","pos":"ADV"} ,
		{"word":"vice-versa","word_nosc":"vice-versa","lemma":"vice-versa","pos":"ADV"} ,
		{"word":"vicieusement","word_nosc":"vicieusement","lemma":"vicieusement","pos":"ADV"} ,
		{"word":"victorieusement","word_nosc":"victorieusement","lemma":"victorieusement","pos":"ADV"} ,
		{"word":"vigoureusement","word_nosc":"vigoureusement","lemma":"vigoureusement","pos":"ADV"} ,
		{"word":"vilainement","word_nosc":"vilainement","lemma":"vilainement","pos":"ADV"} ,
		{"word":"vilement","word_nosc":"vilement","lemma":"vilement","pos":"ADV"} ,
		{"word":"vindicativement","word_nosc":"vindicativement","lemma":"vindicativement","pos":"ADV"} ,
		{"word":"violemment","word_nosc":"violemment","lemma":"violemment","pos":"ADV"} ,
		{"word":"virginalement","word_nosc":"virginalement","lemma":"virginalement","pos":"ADV"} ,
		{"word":"virilement","word_nosc":"virilement","lemma":"virilement","pos":"ADV"} ,
		{"word":"virtuellement","word_nosc":"virtuellement","lemma":"virtuellement","pos":"ADV"} ,
		{"word":"viscéralement","word_nosc":"visceralement","lemma":"viscéralement","pos":"ADV"} ,
		{"word":"visiblement","word_nosc":"visiblement","lemma":"visiblement","pos":"ADV"} ,
		{"word":"visuellement","word_nosc":"visuellement","lemma":"visuellement","pos":"ADV"} ,
		{"word":"vite","word_nosc":"vite","lemma":"vite","pos":"ADV"} ,
		{"word":"vitement","word_nosc":"vitement","lemma":"vitement","pos":"ADV"} ,
		{"word":"vivement","word_nosc":"vivement","lemma":"vivement","pos":"ADV"} ,
		{"word":"vocalement","word_nosc":"vocalement","lemma":"vocalement","pos":"ADV"} ,
		{"word":"voire","word_nosc":"voire","lemma":"voire","pos":"ADV"} ,
		{"word":"volontairement","word_nosc":"volontairement","lemma":"volontairement","pos":"ADV"} ,
		{"word":"volontiers","word_nosc":"volontiers","lemma":"volontiers","pos":"ADV"} ,
		{"word":"volubilement","word_nosc":"volubilement","lemma":"volubilement","pos":"ADV"} ,
		{"word":"voluptueusement","word_nosc":"voluptueusement","lemma":"voluptueusement","pos":"ADV"} ,
		{"word":"voracement","word_nosc":"voracement","lemma":"voracement","pos":"ADV"} ,
		{"word":"vox populi","word_nosc":"vox populi","lemma":"vox populi","pos":"ADV"} ,
		{"word":"vraiment","word_nosc":"vraiment","lemma":"vraiment","pos":"ADV"} ,
		{"word":"vraisemblablement","word_nosc":"vraisemblablement","lemma":"vraisemblablement","pos":"ADV"} ,
		{"word":"vulgairement","word_nosc":"vulgairement","lemma":"vulgairement","pos":"ADV"} ,
		{"word":"véhémentement","word_nosc":"vehementement","lemma":"véhémentement","pos":"ADV"} ,
		{"word":"vélocement","word_nosc":"velocement","lemma":"vélocement","pos":"ADV"} ,
		{"word":"véridiquement","word_nosc":"veridiquement","lemma":"véridiquement","pos":"ADV"} ,
		{"word":"véritablement","word_nosc":"veritablement","lemma":"véritablement","pos":"ADV"} ,
		{"word":"y","word_nosc":"y","lemma":"yu","pos":"ADV"} ,
		{"word":"zig-zig","word_nosc":"zig-zig","lemma":"zig-zig","pos":"ADV"} ,
		{"word":"à brûle-pourpoint","word_nosc":"a brule-pourpoint","lemma":"à_brûle-pourpoint","pos":"ADV"} ,
		{"word":"à cloche-pied","word_nosc":"a cloche-pied","lemma":"à_cloche-pied","pos":"ADV"} ,
		{"word":"à cropetons","word_nosc":"a cropetons","lemma":"à cropetons","pos":"ADV"} ,
		{"word":"à croupetons","word_nosc":"a croupetons","lemma":"à croupetons","pos":"ADV"} ,
		{"word":"à fortiori","word_nosc":"a fortiori","lemma":"à fortiori","pos":"ADV"} ,
		{"word":"à giorno","word_nosc":"a giorno","lemma":"à giorno","pos":"ADV"} ,
		{"word":"à glagla","word_nosc":"a glagla","lemma":"à glagla","pos":"ADV"} ,
		{"word":"à jeun","word_nosc":"a jeun","lemma":"à jeun","pos":"ADV"} ,
		{"word":"à l'aveuglette","word_nosc":"a l'aveuglette","lemma":"à l'aveuglette","pos":"ADV"} ,
		{"word":"à l'encan","word_nosc":"a l'encan","lemma":"à l'encan","pos":"ADV"} ,
		{"word":"à l'envi","word_nosc":"a l'envi","lemma":"à l'envi","pos":"ADV"} ,
		{"word":"à l'improviste","word_nosc":"a l'improviste","lemma":"à l'improviste","pos":"ADV"} ,
		{"word":"à la daumont","word_nosc":"a la daumont","lemma":"à la daumont","pos":"ADV"} ,
		{"word":"à la saint-glinglin","word_nosc":"a la saint-glinglin","lemma":"à la saint-glinglin","pos":"ADV"} ,
		{"word":"à leur encontre","word_nosc":"a leur encontre","lemma":"à leur encontre","pos":"ADV"} ,
		{"word":"à lurelure","word_nosc":"a lurelure","lemma":"à lurelure","pos":"ADV"} ,
		{"word":"à mon encontre","word_nosc":"a mon encontre","lemma":"à mon encontre","pos":"ADV"} ,
		{"word":"à notre encontre","word_nosc":"a notre encontre","lemma":"à notre encontre","pos":"ADV"} ,
		{"word":"à posteriori","word_nosc":"a posteriori","lemma":"a posteriori","pos":"ADV"} ,
		{"word":"à priori","word_nosc":"a priori","lemma":"a priori","pos":"ADV"} ,
		{"word":"à rebrousse-poil","word_nosc":"a rebrousse-poil","lemma":"à_rebrousse-poil","pos":"ADV"} ,
		{"word":"à son encontre","word_nosc":"a son encontre","lemma":"à son encontre","pos":"ADV"} ,
		{"word":"à tire-larigot","word_nosc":"a tire-larigot","lemma":"à_tire-larigot","pos":"ADV"} ,
		{"word":"à ton encontre","word_nosc":"a ton encontre","lemma":"à ton encontre","pos":"ADV"} ,
		{"word":"à touche-touche","word_nosc":"a touche-touche","lemma":"à_touche-touche","pos":"ADV"} ,
		{"word":"à tue-tête","word_nosc":"a tue-tete","lemma":"à_tue-tête","pos":"ADV"} ,
		{"word":"à tâtons","word_nosc":"a tatons","lemma":"à tâtons","pos":"ADV"} ,
		{"word":"à votre encontre","word_nosc":"a votre encontre","lemma":"à votre encontre","pos":"ADV"} ,
		{"word":"âcrement","word_nosc":"acrement","lemma":"âcrement","pos":"ADV"} ,
		{"word":"âprement","word_nosc":"aprement","lemma":"âprement","pos":"ADV"} ,
		{"word":"çà","word_nosc":"ca","lemma":"çà","pos":"ADV"} ,
		{"word":"é","word_nosc":"e","lemma":"é","pos":"ADV"} ,
		{"word":"écologiquement","word_nosc":"ecologiquement","lemma":"écologiquement","pos":"ADV"} ,
		{"word":"économiquement","word_nosc":"economiquement","lemma":"économiquement","pos":"ADV"} ,
		{"word":"également","word_nosc":"egalement","lemma":"également","pos":"ADV"} ,
		{"word":"égoïstement","word_nosc":"egoistement","lemma":"égoïstement","pos":"ADV"} ,
		{"word":"électivement","word_nosc":"electivement","lemma":"électivement","pos":"ADV"} ,
		{"word":"électoralement","word_nosc":"electoralement","lemma":"électoralement","pos":"ADV"} ,
		{"word":"électriquement","word_nosc":"electriquement","lemma":"électriquement","pos":"ADV"} ,
		{"word":"électroniquement","word_nosc":"electroniquement","lemma":"électroniquement","pos":"ADV"} ,
		{"word":"éloquemment","word_nosc":"eloquemment","lemma":"éloquemment","pos":"ADV"} ,
		{"word":"élégamment","word_nosc":"elegamment","lemma":"élégamment","pos":"ADV"} ,
		{"word":"éminemment","word_nosc":"eminemment","lemma":"éminemment","pos":"ADV"} ,
		{"word":"émotionnellement","word_nosc":"emotionnellement","lemma":"émotionnellement","pos":"ADV"} ,
		{"word":"énergiquement","word_nosc":"energiquement","lemma":"énergiquement","pos":"ADV"} ,
		{"word":"énigmatiquement","word_nosc":"enigmatiquement","lemma":"énigmatiquement","pos":"ADV"} ,
		{"word":"énormément","word_nosc":"enormement","lemma":"énormément","pos":"ADV"} ,
		{"word":"épais","word_nosc":"epais","lemma":"épais","pos":"ADV"} ,
		{"word":"épaissement","word_nosc":"epaissement","lemma":"épaissement","pos":"ADV"} ,
		{"word":"épatamment","word_nosc":"epatamment","lemma":"épatamment","pos":"ADV"} ,
		{"word":"éperdument","word_nosc":"eperdument","lemma":"éperdument","pos":"ADV"} ,
		{"word":"éphémèrement","word_nosc":"ephemerement","lemma":"éphémèrement","pos":"ADV"} ,
		{"word":"épisodiquement","word_nosc":"episodiquement","lemma":"épisodiquement","pos":"ADV"} ,
		{"word":"épouvantablement","word_nosc":"epouvantablement","lemma":"épouvantablement","pos":"ADV"} ,
		{"word":"équitablement","word_nosc":"equitablement","lemma":"équitablement","pos":"ADV"} ,
		{"word":"éternellement","word_nosc":"eternellement","lemma":"éternellement","pos":"ADV"} ,
		{"word":"éthiquement","word_nosc":"ethiquement","lemma":"éthiquement","pos":"ADV"} ,
		{"word":"étonnamment","word_nosc":"etonnamment","lemma":"étonnamment","pos":"ADV"} ,
		{"word":"étourdiment","word_nosc":"etourdiment","lemma":"étourdiment","pos":"ADV"} ,
		{"word":"étrangement","word_nosc":"etrangement","lemma":"étrangement","pos":"ADV"} ,
		{"word":"étroitement","word_nosc":"etroitement","lemma":"étroitement","pos":"ADV"} ,
		{"word":"étymologiquement","word_nosc":"etymologiquement","lemma":"étymologiquement","pos":"ADV"} ,
		{"word":"évasivement","word_nosc":"evasivement","lemma":"évasivement","pos":"ADV"} ,
		{"word":"éventuellement","word_nosc":"eventuellement","lemma":"éventuellement","pos":"ADV"} ,
		{"word":"évidemment","word_nosc":"evidemment","lemma":"évidemment","pos":"ADV"}
	]
}