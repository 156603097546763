'use strict';

module.exports = {
    lexi: [
    	{"word":"acré","word_nosc":"acre","lemma":"acré","pos":"ONO"} ,
		{"word":"adieu","word_nosc":"adieu","lemma":"adieu","pos":"ONO"} ,
		{"word":"ah","word_nosc":"ah","lemma":"ah","pos":"ONO"} ,
		{"word":"alerte","word_nosc":"alerte","lemma":"alerte","pos":"ONO"} ,
		{"word":"allo","word_nosc":"allo","lemma":"allo","pos":"ONO"} ,
		{"word":"alléluia","word_nosc":"alleluia","lemma":"alléluia","pos":"ONO"} ,
		{"word":"allô","word_nosc":"allo","lemma":"allô","pos":"ONO"} ,
		{"word":"am stram gram","word_nosc":"am stram gram","lemma":"am stram gram","pos":"ONO"} ,
		{"word":"amen","word_nosc":"amen","lemma":"amen","pos":"ONO"} ,
		{"word":"arrière","word_nosc":"arriere","lemma":"arrière","pos":"ONO"} ,
		{"word":"atchoum","word_nosc":"atchoum","lemma":"atchoum","pos":"ONO"} ,
		{"word":"attention","word_nosc":"attention","lemma":"attention","pos":"ONO"} ,
		{"word":"aïe","word_nosc":"aie","lemma":"aïe","pos":"ONO"} ,
		{"word":"badabam","word_nosc":"badabam","lemma":"badabam","pos":"ONO"} ,
		{"word":"badaboum","word_nosc":"badaboum","lemma":"badaboum","pos":"ONO"} ,
		{"word":"bah","word_nosc":"bah","lemma":"bah","pos":"ONO"} ,
		{"word":"balle-peau","word_nosc":"balle-peau","lemma":"balle-peau","pos":"ONO"} ,
		{"word":"balpeau","word_nosc":"balpeau","lemma":"balpeau","pos":"ONO"} ,
		{"word":"banco","word_nosc":"banco","lemma":"banco","pos":"ONO"} ,
		{"word":"bang","word_nosc":"bang","lemma":"bang","pos":"ONO"} ,
		{"word":"basta","word_nosc":"basta","lemma":"basta","pos":"ONO"} ,
		{"word":"baste","word_nosc":"baste","lemma":"baste","pos":"ONO"} ,
		{"word":"berk","word_nosc":"berk","lemma":"berk","pos":"ONO"} ,
		{"word":"bernique","word_nosc":"bernique","lemma":"bernique","pos":"ONO"} ,
		{"word":"beu","word_nosc":"beu","lemma":"beu","pos":"ONO"} ,
		{"word":"beuark","word_nosc":"beuark","lemma":"beuark","pos":"ONO"} ,
		{"word":"beurk","word_nosc":"beurk","lemma":"beurk","pos":"ONO"} ,
		{"word":"bigre","word_nosc":"bigre","lemma":"bigre","pos":"ONO"} ,
		{"word":"bim","word_nosc":"bim","lemma":"bim","pos":"ONO"} ,
		{"word":"bing","word_nosc":"bing","lemma":"bing","pos":"ONO"} ,
		{"word":"bof","word_nosc":"bof","lemma":"bof","pos":"ONO"} ,
		{"word":"bon","word_nosc":"bon","lemma":"bon","pos":"ONO"} ,
		{"word":"boudiou","word_nosc":"boudiou","lemma":"boudiou","pos":"ONO"} ,
		{"word":"boudu","word_nosc":"boudu","lemma":"boudu","pos":"ONO"} ,
		{"word":"bouf","word_nosc":"bouf","lemma":"bouf","pos":"ONO"} ,
		{"word":"bougre","word_nosc":"bougre","lemma":"bougre","pos":"ONO"} ,
		{"word":"boum","word_nosc":"boum","lemma":"boum","pos":"ONO"} ,
		{"word":"bravo","word_nosc":"bravo","lemma":"bravo","pos":"ONO"} ,
		{"word":"broum","word_nosc":"broum","lemma":"broum","pos":"ONO"} ,
		{"word":"brrr","word_nosc":"brrr","lemma":"brrr","pos":"ONO"} ,
		{"word":"bye","word_nosc":"bye","lemma":"bye","pos":"ONO"} ,
		{"word":"bye-bye","word_nosc":"bye-bye","lemma":"bye-bye","pos":"ONO"} ,
		{"word":"bé","word_nosc":"be","lemma":"bé","pos":"ONO"} ,
		{"word":"calmos","word_nosc":"calmos","lemma":"calmos","pos":"ONO"} ,
		{"word":"caramba","word_nosc":"caramba","lemma":"caramba","pos":"ONO"} ,
		{"word":"chic","word_nosc":"chic","lemma":"chic","pos":"ONO"} ,
		{"word":"chiche","word_nosc":"chiche","lemma":"chiche","pos":"ONO"} ,
		{"word":"chouette","word_nosc":"chouette","lemma":"chouette","pos":"ONO"} ,
		{"word":"chut","word_nosc":"chut","lemma":"chut","pos":"ONO"} ,
		{"word":"ciao","word_nosc":"ciao","lemma":"ciao","pos":"ONO"} ,
		{"word":"clac","word_nosc":"clac","lemma":"clac","pos":"ONO"} ,
		{"word":"clic","word_nosc":"clic","lemma":"clic","pos":"ONO"} ,
		{"word":"clic-clac","word_nosc":"clic-clac","lemma":"clic-clac","pos":"ONO"} ,
		{"word":"comment","word_nosc":"comment","lemma":"comment","pos":"ONO"} ,
		{"word":"cot cot codec","word_nosc":"cot cot codec","lemma":"cot cot codec","pos":"ONO"} ,
		{"word":"cric-crac","word_nosc":"cric-crac","lemma":"cric-crac","pos":"ONO"} ,
		{"word":"cristi","word_nosc":"cristi","lemma":"cristi","pos":"ONO"} ,
		{"word":"cré","word_nosc":"cre","lemma":"cré","pos":"ONO"} ,
		{"word":"crénom","word_nosc":"crenom","lemma":"crénom","pos":"ONO"} ,
		{"word":"da","word_nosc":"da","lemma":"da","pos":"ONO"} ,
		{"word":"dame","word_nosc":"dame","lemma":"dame","pos":"ONO"} ,
		{"word":"damned","word_nosc":"damned","lemma":"damned","pos":"ONO"} ,
		{"word":"dehors","word_nosc":"dehors","lemma":"dehors","pos":"ONO"} ,
		{"word":"dia","word_nosc":"dia","lemma":"dia","pos":"ONO"} ,
		{"word":"diable","word_nosc":"diable","lemma":"diable","pos":"ONO"} ,
		{"word":"diantre","word_nosc":"diantre","lemma":"diantre","pos":"ONO"} ,
		{"word":"dig","word_nosc":"dig","lemma":"dig","pos":"ONO"} ,
		{"word":"ding","word_nosc":"ding","lemma":"ding","pos":"ONO"} ,
		{"word":"dommage","word_nosc":"dommage","lemma":"dommage","pos":"ONO"} ,
		{"word":"dong","word_nosc":"dong","lemma":"dong","pos":"ONO"} ,
		{"word":"dring","word_nosc":"dring","lemma":"dring","pos":"ONO"} ,
		{"word":"déjà","word_nosc":"deja","lemma":"déjà","pos":"ONO"} ,
		{"word":"eh","word_nosc":"eh","lemma":"eh","pos":"ONO"} ,
		{"word":"euh","word_nosc":"euh","lemma":"euh","pos":"ONO"} ,
		{"word":"eurêka","word_nosc":"eureka","lemma":"eurêka","pos":"ONO"} ,
		{"word":"fi","word_nosc":"fi","lemma":"fi","pos":"ONO"} ,
		{"word":"fichtre","word_nosc":"fichtre","lemma":"fichtre","pos":"ONO"} ,
		{"word":"flac","word_nosc":"flac","lemma":"flac","pos":"ONO"} ,
		{"word":"floc","word_nosc":"floc","lemma":"floc","pos":"ONO"} ,
		{"word":"flop","word_nosc":"flop","lemma":"flop","pos":"ONO"} ,
		{"word":"fouchtra","word_nosc":"fouchtra","lemma":"fouchtra","pos":"ONO"} ,
		{"word":"gare","word_nosc":"gare","lemma":"gare","pos":"ONO"} ,
		{"word":"gna gna","word_nosc":"gna gna","lemma":"gna gna","pos":"ONO"} ,
		{"word":"gnagnagna","word_nosc":"gnagnagna","lemma":"gnagnagna","pos":"ONO"} ,
		{"word":"goddam","word_nosc":"goddam","lemma":"goddam","pos":"ONO"} ,
		{"word":"grâce","word_nosc":"grace","lemma":"grâce","pos":"ONO"} ,
		{"word":"gué","word_nosc":"gue","lemma":"gué","pos":"ONO"} ,
		{"word":"gy","word_nosc":"gy","lemma":"gy","pos":"ONO"} ,
		{"word":"ha","word_nosc":"ha","lemma":"ha","pos":"ONO"} ,
		{"word":"haha","word_nosc":"haha","lemma":"haha","pos":"ONO"} ,
		{"word":"hai","word_nosc":"hai","lemma":"hai","pos":"ONO"} ,
		{"word":"halte","word_nosc":"halte","lemma":"halte","pos":"ONO"} ,
		{"word":"hardi","word_nosc":"hardi","lemma":"hardi","pos":"ONO"} ,
		{"word":"hare","word_nosc":"hare","lemma":"hare","pos":"ONO"} ,
		{"word":"hein","word_nosc":"hein","lemma":"hein","pos":"ONO"} ,
		{"word":"hello","word_nosc":"hello","lemma":"hello","pos":"ONO"} ,
		{"word":"hem","word_nosc":"hem","lemma":"hem","pos":"ONO"} ,
		{"word":"hep","word_nosc":"hep","lemma":"hep","pos":"ONO"} ,
		{"word":"heu","word_nosc":"heu","lemma":"heu","pos":"ONO"} ,
		{"word":"hi","word_nosc":"hi","lemma":"hi","pos":"ONO"} ,
		{"word":"hi-han","word_nosc":"hi-han","lemma":"hi-han","pos":"ONO"} ,
		{"word":"hip","word_nosc":"hip","lemma":"hip","pos":"ONO"} ,
		{"word":"hisse","word_nosc":"hisse","lemma":"hisse","pos":"ONO"} ,
		{"word":"ho","word_nosc":"ho","lemma":"ho","pos":"ONO"} ,
		{"word":"holà","word_nosc":"hola","lemma":"holà","pos":"ONO"} ,
		{"word":"hom","word_nosc":"hom","lemma":"hom","pos":"ONO"} ,
		{"word":"hon","word_nosc":"hon","lemma":"hon","pos":"ONO"} ,
		{"word":"hop","word_nosc":"hop","lemma":"hop","pos":"ONO"} ,
		{"word":"hou","word_nosc":"hou","lemma":"hou","pos":"ONO"} ,
		{"word":"houhou","word_nosc":"houhou","lemma":"houhou","pos":"ONO"} ,
		{"word":"houlà","word_nosc":"houla","lemma":"houlà","pos":"ONO"} ,
		{"word":"houp","word_nosc":"houp","lemma":"houp","pos":"ONO"} ,
		{"word":"hourra","word_nosc":"hourra","lemma":"hourra","pos":"ONO"} ,
		{"word":"hue","word_nosc":"hue","lemma":"hue","pos":"ONO"} ,
		{"word":"hugh","word_nosc":"hugh","lemma":"hugh","pos":"ONO"} ,
		{"word":"hum","word_nosc":"hum","lemma":"hum","pos":"ONO"} ,
		{"word":"hurrah","word_nosc":"hurrah","lemma":"hurrah","pos":"ONO"} ,
		{"word":"hé","word_nosc":"he","lemma":"hé","pos":"ONO"} ,
		{"word":"hélas","word_nosc":"helas","lemma":"hélas","pos":"ONO"} ,
		{"word":"inch allah","word_nosc":"inch allah","lemma":"inch allah","pos":"ONO"} ,
		{"word":"jarnicoton","word_nosc":"jarnicoton","lemma":"jarnicoton","pos":"ONO"} ,
		{"word":"lala","word_nosc":"lala","lemma":"lala","pos":"ONO"} ,
		{"word":"las","word_nosc":"las","lemma":"las","pos":"ONO"} ,
		{"word":"là","word_nosc":"la","lemma":"là","pos":"ONO"} ,
		{"word":"macarel","word_nosc":"macarel","lemma":"macarel","pos":"ONO"} ,
		{"word":"macarelle","word_nosc":"macarelle","lemma":"macarelle","pos":"ONO"} ,
		{"word":"mazette","word_nosc":"mazette","lemma":"mazette","pos":"ONO"} ,
		{"word":"merci","word_nosc":"merci","lemma":"merci","pos":"ONO"} ,
		{"word":"merdasse","word_nosc":"merdasse","lemma":"merdasse","pos":"ONO"} ,
		{"word":"merde","word_nosc":"merde","lemma":"merde","pos":"ONO"} ,
		{"word":"merdre","word_nosc":"merdre","lemma":"merdre","pos":"ONO"} ,
		{"word":"meuh","word_nosc":"meuh","lemma":"meuh","pos":"ONO"} ,
		{"word":"miam","word_nosc":"miam","lemma":"miam","pos":"ONO"} ,
		{"word":"miam-miam","word_nosc":"miam-miam","lemma":"miam-miam","pos":"ONO"} ,
		{"word":"mince","word_nosc":"mince","lemma":"mince","pos":"ONO"} ,
		{"word":"morbleu","word_nosc":"morbleu","lemma":"morbleu","pos":"ONO"} ,
		{"word":"mordieu","word_nosc":"mordieu","lemma":"mordieu","pos":"ONO"} ,
		{"word":"motus","word_nosc":"motus","lemma":"motus","pos":"ONO"} ,
		{"word":"mouais","word_nosc":"mouais","lemma":"mouais","pos":"ONO"} ,
		{"word":"mâtin","word_nosc":"matin","lemma":"mâtin","pos":"ONO"} ,
		{"word":"na","word_nosc":"na","lemma":"na","pos":"ONO"} ,
		{"word":"niet","word_nosc":"niet","lemma":"niet","pos":"ONO"} ,
		{"word":"oh","word_nosc":"oh","lemma":"oh","pos":"ONO"} ,
		{"word":"ohé","word_nosc":"ohe","lemma":"ohé","pos":"ONO"} ,
		{"word":"ollé","word_nosc":"olle","lemma":"ollé","pos":"ONO"} ,
		{"word":"olé","word_nosc":"ole","lemma":"olé","pos":"ONO"} ,
		{"word":"ouah","word_nosc":"ouah","lemma":"ouah","pos":"ONO"} ,
		{"word":"ouf","word_nosc":"ouf","lemma":"ouf","pos":"ONO"} ,
		{"word":"ouh","word_nosc":"ouh","lemma":"ouh","pos":"ONO"} ,
		{"word":"oui-da","word_nosc":"oui-da","lemma":"oui-da","pos":"ONO"} ,
		{"word":"ouiche","word_nosc":"ouiche","lemma":"ouiche","pos":"ONO"} ,
		{"word":"ouille","word_nosc":"ouille","lemma":"ouille","pos":"ONO"} ,
		{"word":"oust","word_nosc":"oust","lemma":"oust","pos":"ONO"} ,
		{"word":"ouste","word_nosc":"ouste","lemma":"ouste","pos":"ONO"} ,
		{"word":"palsambleu","word_nosc":"palsambleu","lemma":"palsambleu","pos":"ONO"} ,
		{"word":"pan","word_nosc":"pan","lemma":"pan","pos":"ONO"} ,
		{"word":"parbleu","word_nosc":"parbleu","lemma":"parbleu","pos":"ONO"} ,
		{"word":"pardi","word_nosc":"pardi","lemma":"pardi","pos":"ONO"} ,
		{"word":"pardieu","word_nosc":"pardieu","lemma":"pardieu","pos":"ONO"} ,
		{"word":"pardon","word_nosc":"pardon","lemma":"pardon","pos":"ONO"} ,
		{"word":"patapouf","word_nosc":"patapouf","lemma":"patapouf","pos":"ONO"} ,
		{"word":"patata","word_nosc":"patata","lemma":"patata","pos":"ONO"} ,
		{"word":"patati","word_nosc":"patati","lemma":"patati","pos":"ONO"} ,
		{"word":"patatras","word_nosc":"patatras","lemma":"patatras","pos":"ONO"} ,
		{"word":"pchitt","word_nosc":"pchitt","lemma":"pchitt","pos":"ONO"} ,
		{"word":"peste","word_nosc":"peste","lemma":"peste","pos":"ONO"} ,
		{"word":"peuchère","word_nosc":"peuchere","lemma":"peuchère","pos":"ONO"} ,
		{"word":"peuh","word_nosc":"peuh","lemma":"peuh","pos":"ONO"} ,
		{"word":"pff","word_nosc":"pff","lemma":"pff","pos":"ONO"} ,
		{"word":"pfft","word_nosc":"pfft","lemma":"pfft","pos":"ONO"} ,
		{"word":"pfutt","word_nosc":"pfutt","lemma":"pfutt","pos":"ONO"} ,
		{"word":"pin-pon","word_nosc":"pin-pon","lemma":"pin-pon","pos":"ONO"} ,
		{"word":"ploc","word_nosc":"ploc","lemma":"ploc","pos":"ONO"} ,
		{"word":"plouf","word_nosc":"plouf","lemma":"plouf","pos":"ONO"} ,
		{"word":"pollope","word_nosc":"pollope","lemma":"pollope","pos":"ONO"} ,
		{"word":"polope","word_nosc":"polope","lemma":"polope","pos":"ONO"} ,
		{"word":"pouah","word_nosc":"pouah","lemma":"pouah","pos":"ONO"} ,
		{"word":"pouce","word_nosc":"pouce","lemma":"pouce","pos":"ONO"} ,
		{"word":"pouf","word_nosc":"pouf","lemma":"pouf","pos":"ONO"} ,
		{"word":"pouh","word_nosc":"pouh","lemma":"pouh","pos":"ONO"} ,
		{"word":"pouic","word_nosc":"pouic","lemma":"pouic","pos":"ONO"} ,
		{"word":"pristi","word_nosc":"pristi","lemma":"pristi","pos":"ONO"} ,
		{"word":"prosit","word_nosc":"prosit","lemma":"prosit","pos":"ONO"} ,
		{"word":"prout","word_nosc":"prout","lemma":"prout","pos":"ONO"} ,
		{"word":"pschitt","word_nosc":"pschitt","lemma":"pschitt","pos":"ONO"} ,
		{"word":"psitt","word_nosc":"psitt","lemma":"psitt","pos":"ONO"} ,
		{"word":"pst","word_nosc":"pst","lemma":"pst","pos":"ONO"} ,
		{"word":"qui-vive","word_nosc":"qui-vive","lemma":"qui-vive","pos":"ONO"} ,
		{"word":"quoi","word_nosc":"quoi","lemma":"quoi","pos":"ONO"} ,
		{"word":"rantanplan","word_nosc":"rantanplan","lemma":"rantanplan","pos":"ONO"} ,
		{"word":"rataplan","word_nosc":"rataplan","lemma":"rataplan","pos":"ONO"} ,
		{"word":"re-belote","word_nosc":"re-belote","lemma":"re-belote","pos":"ONO"} ,
		{"word":"rebelote","word_nosc":"rebelote","lemma":"rebelote","pos":"ONO"} ,
		{"word":"sacrebleu","word_nosc":"sacrebleu","lemma":"sacrebleu","pos":"ONO"} ,
		{"word":"sacredieu","word_nosc":"sacredieu","lemma":"sacredieu","pos":"ONO"} ,
		{"word":"sacrédié","word_nosc":"sacredie","lemma":"sacrédié","pos":"ONO"} ,
		{"word":"salut","word_nosc":"salut","lemma":"salut","pos":"ONO"} ,
		{"word":"saperlipopette","word_nosc":"saperlipopette","lemma":"saperlipopette","pos":"ONO"} ,
		{"word":"sapristi","word_nosc":"sapristi","lemma":"sapristi","pos":"ONO"} ,
		{"word":"scrogneugneu","word_nosc":"scrogneugneu","lemma":"scrogneugneu","pos":"ONO"} ,
		{"word":"skaal","word_nosc":"skaal","lemma":"skaal","pos":"ONO"} ,
		{"word":"snif","word_nosc":"snif","lemma":"snif","pos":"ONO"} ,
		{"word":"sniff","word_nosc":"sniff","lemma":"sniff","pos":"ONO"} ,
		{"word":"splash","word_nosc":"splash","lemma":"splash","pos":"ONO"} ,
		{"word":"tacatac","word_nosc":"tacatac","lemma":"tacatac","pos":"ONO"} ,
		{"word":"tacatacatac","word_nosc":"tacatacatac","lemma":"tacatacatac","pos":"ONO"} ,
		{"word":"tagada","word_nosc":"tagada","lemma":"tagada","pos":"ONO"} ,
		{"word":"tap","word_nosc":"tap","lemma":"tap","pos":"ONO"} ,
		{"word":"taratata","word_nosc":"taratata","lemma":"taratata","pos":"ONO"} ,
		{"word":"taïaut","word_nosc":"taiaut","lemma":"taïaut","pos":"ONO"} ,
		{"word":"tchao","word_nosc":"tchao","lemma":"tchao","pos":"ONO"} ,
		{"word":"tchin tchin","word_nosc":"tchin tchin","lemma":"tchin tchin","pos":"ONO"} ,
		{"word":"tchin-tchin","word_nosc":"tchin-tchin","lemma":"tchin-tchin","pos":"ONO"} ,
		{"word":"tiens","word_nosc":"tiens","lemma":"tiens","pos":"ONO"} ,
		{"word":"tintin","word_nosc":"tintin","lemma":"tintin","pos":"ONO"} ,
		{"word":"tudieu","word_nosc":"tudieu","lemma":"tudieu","pos":"ONO"} ,
		{"word":"turlututu","word_nosc":"turlututu","lemma":"turlututu","pos":"ONO"} ,
		{"word":"té","word_nosc":"te","lemma":"té","pos":"ONO"} ,
		{"word":"ventre-saint-gris","word_nosc":"ventre-saint-gris","lemma":"ventre-saint-gris","pos":"ONO"} ,
		{"word":"ventrebleu","word_nosc":"ventrebleu","lemma":"ventrebleu","pos":"ONO"} ,
		{"word":"vertubleu","word_nosc":"vertubleu","lemma":"vertubleu","pos":"ONO"} ,
		{"word":"vive","word_nosc":"vive","lemma":"vive","pos":"ONO"} ,
		{"word":"vlan","word_nosc":"vlan","lemma":"vlan","pos":"ONO"} ,
		{"word":"vlouf","word_nosc":"vlouf","lemma":"vlouf","pos":"ONO"} ,
		{"word":"vroom","word_nosc":"vroom","lemma":"vroom","pos":"ONO"} ,
		{"word":"vroum","word_nosc":"vroum","lemma":"vroum","pos":"ONO"} ,
		{"word":"wouah","word_nosc":"wouah","lemma":"wouah","pos":"ONO"} ,
		{"word":"yeah","word_nosc":"yeah","lemma":"yeah","pos":"ONO"} ,
		{"word":"youp","word_nosc":"youp","lemma":"youp","pos":"ONO"} ,
		{"word":"youpi","word_nosc":"youpi","lemma":"youpi","pos":"ONO"} ,
		{"word":"zou","word_nosc":"zou","lemma":"zou","pos":"ONO"} ,
		{"word":"zut","word_nosc":"zut","lemma":"zut","pos":"ONO"} ,
		{"word":"zzz","word_nosc":"zzz","lemma":"zzz","pos":"ONO"} ,
		{"word":"zzzz","word_nosc":"zzzz","lemma":"zzzz","pos":"ONO"} ,
		{"word":"à-dieu-vat","word_nosc":"a-dieu-vat","lemma":"à-dieu-vat","pos":"ONO"} ,
		{"word":"ô","word_nosc":"o","lemma":"ô","pos":"ONO"} 
	]
}