'use strict';

module.exports = {
    lexi: [
    	{"word":"aucun","word_nosc":"aucun","lemma":"aucun","pos":"PRO:ind"} ,
		{"word":"aucune","word_nosc":"aucune","lemma":"aucune","pos":"PRO:ind"} ,
		{"word":"auquel","word_nosc":"auquel","lemma":"auquel","pos":"PRO:int"} ,
		{"word":"auquel","word_nosc":"auquel","lemma":"auquel","pos":"PRO:rel"} ,
		{"word":"autre","word_nosc":"autre","lemma":"autre","pos":"PRO:ind"} ,
		{"word":"autrefois","word_nosc":"autrefois","lemma":"autrefois","pos":"PRO:ind"} ,
		{"word":"autres","word_nosc":"autres","lemma":"autres","pos":"PRO:ind"} ,
		{"word":"autrichiens","word_nosc":"autrichiens","lemma":"autrichiens","pos":"PRO:ind"} ,
		{"word":"autrui","word_nosc":"autrui","lemma":"autrui","pos":"PRO:ind"} ,
		{"word":"auxquelles","word_nosc":"auxquelles","lemma":"auxquelles","pos":"PRO:int"} ,
		{"word":"auxquelles","word_nosc":"auxquelles","lemma":"auxquelles","pos":"PRO:rel"} ,
		{"word":"auxquels","word_nosc":"auxquels","lemma":"auxquels","pos":"PRO:int"} ,
		{"word":"auxquels","word_nosc":"auxquels","lemma":"auxquels","pos":"PRO:rel"} ,
		{"word":"c","word_nosc":"c","lemma":"c","pos":"PRO:dem"} ,
		{"word":"ce","word_nosc":"ce","lemma":"ce","pos":"PRO:dem"} ,
		{"word":"ceci","word_nosc":"ceci","lemma":"ceci","pos":"PRO:dem"} ,
		{"word":"cela","word_nosc":"cela","lemma":"cela","pos":"PRO:dem"} ,
		{"word":"celle","word_nosc":"celle","lemma":"celle","pos":"PRO:dem"} ,
		{"word":"celle-ci","word_nosc":"celle-ci","lemma":"celle-ci","pos":"PRO:dem"} ,
		{"word":"celle-là","word_nosc":"celle-la","lemma":"celle-là","pos":"PRO:dem"} ,
		{"word":"celles","word_nosc":"celles","lemma":"celles","pos":"PRO:dem"} ,
		{"word":"celles-ci","word_nosc":"celles-ci","lemma":"celles-ci","pos":"PRO:dem"} ,
		{"word":"celles-là","word_nosc":"celles-la","lemma":"celles-là","pos":"PRO:dem"} ,
		{"word":"celui","word_nosc":"celui","lemma":"celui","pos":"PRO:dem"} ,
		{"word":"celui-ci","word_nosc":"celui-ci","lemma":"celui-ci","pos":"PRO:dem"} ,
		{"word":"celui-là","word_nosc":"celui-la","lemma":"celui-là","pos":"PRO:dem"} ,
		{"word":"certaine","word_nosc":"certaine","lemma":"certaine","pos":"PRO:ind"} ,
		{"word":"certaines","word_nosc":"certaines","lemma":"certaines","pos":"PRO:ind"} ,
		{"word":"certains","word_nosc":"certains","lemma":"certains","pos":"PRO:ind"} ,
		{"word":"ceux","word_nosc":"ceux","lemma":"ceux","pos":"PRO:dem"} ,
		{"word":"ceux-ci","word_nosc":"ceux-ci","lemma":"ceux-ci","pos":"PRO:dem"} ,
		{"word":"ceux-là","word_nosc":"ceux-la","lemma":"ceux-là","pos":"PRO:dem"} ,
		{"word":"chacun","word_nosc":"chacun","lemma":"chacun","pos":"PRO:ind"} ,
		{"word":"chacune","word_nosc":"chacune","lemma":"chacune","pos":"PRO:ind"} ,
		{"word":"con","word_nosc":"con","lemma":"con","pos":"PRO:per"} ,
		{"word":"d'autres","word_nosc":"d'autres","lemma":"d'autres","pos":"PRO:ind"} ,
		{"word":"desquelles","word_nosc":"desquelles","lemma":"desquelles","pos":"PRO:int"} ,
		{"word":"desquelles","word_nosc":"desquelles","lemma":"desquelles","pos":"PRO:rel"} ,
		{"word":"desquels","word_nosc":"desquels","lemma":"desquels","pos":"PRO:int"} ,
		{"word":"desquels","word_nosc":"desquels","lemma":"desquels","pos":"PRO:rel"} ,
		{"word":"dont","word_nosc":"dont","lemma":"dont","pos":"PRO:int"} ,
		{"word":"dont","word_nosc":"dont","lemma":"dont","pos":"PRO:rel"} ,
		{"word":"duquel","word_nosc":"duquel","lemma":"duquel","pos":"PRO:int"} ,
		{"word":"duquel","word_nosc":"duquel","lemma":"duquel","pos":"PRO:rel"} ,
		{"word":"elle","word_nosc":"elle","lemma":"elle","pos":"PRO:per"} ,
		{"word":"elle-même","word_nosc":"elle-meme","lemma":"elle-même","pos":"PRO:per"} ,
		{"word":"elles","word_nosc":"elles","lemma":"elles","pos":"PRO:per"} ,
		{"word":"elles-mêmes","word_nosc":"elles-memes","lemma":"elles-mêmes","pos":"PRO:per"} ,
		{"word":"en","word_nosc":"en","lemma":"en","pos":"PRO:per"} ,
		{"word":"eux","word_nosc":"eux","lemma":"eux","pos":"PRO:per"} ,
		{"word":"eux-mêmes","word_nosc":"eux-memes","lemma":"eux-mêmes","pos":"PRO:per"} ,
		{"word":"grand-chose","word_nosc":"grand-chose","lemma":"grand-chose","pos":"PRO:ind"} ,
		{"word":"icelle","word_nosc":"icelle","lemma":"icelle","pos":"PRO:ind"} ,
		{"word":"icelles","word_nosc":"icelles","lemma":"icelles","pos":"PRO:ind"} ,
		{"word":"icelui","word_nosc":"icelui","lemma":"icelui","pos":"PRO:ind"} ,
		{"word":"il","word_nosc":"il","lemma":"il","pos":"PRO:per"} ,
		{"word":"ils","word_nosc":"ils","lemma":"ils","pos":"PRO:per"} ,
		{"word":"j","word_nosc":"j","lemma":"j","pos":"PRO:per"} ,
		{"word":"je","word_nosc":"je","lemma":"je","pos":"PRO:per"} ,
		{"word":"l","word_nosc":"l","lemma":"l","pos":"PRO:per"} ,
		{"word":"l'","word_nosc":"l'","lemma":"l'","pos":"PRO:per"} ,
		{"word":"l'un","word_nosc":"l'un","lemma":"l'un","pos":"PRO:ind"} ,
		{"word":"l'une","word_nosc":"l'une","lemma":"l'une","pos":"PRO:ind"} ,
		{"word":"la","word_nosc":"la","lemma":"la","pos":"PRO:per"} ,
		{"word":"la plupart","word_nosc":"la plupart","lemma":"la plupart","pos":"PRO:ind"} ,
		{"word":"laquelle","word_nosc":"laquelle","lemma":"laquelle","pos":"PRO:int"} ,
		{"word":"laquelle","word_nosc":"laquelle","lemma":"laquelle","pos":"PRO:rel"} ,
		{"word":"le","word_nosc":"le","lemma":"le","pos":"PRO:per"} ,
		{"word":"lequel","word_nosc":"lequel","lemma":"lequel","pos":"PRO:int"} ,
		{"word":"lequel","word_nosc":"lequel","lemma":"lequel","pos":"PRO:rel"} ,
		{"word":"les","word_nosc":"les","lemma":"les","pos":"PRO:per"} ,
		{"word":"lesquelles","word_nosc":"lesquelles","lemma":"lesquelles","pos":"PRO:int"} ,
		{"word":"lesquelles","word_nosc":"lesquelles","lemma":"lesquelles","pos":"PRO:rel"} ,
		{"word":"lesquels","word_nosc":"lesquels","lemma":"lesquels","pos":"PRO:int"} ,
		{"word":"lesquels","word_nosc":"lesquels","lemma":"lesquels","pos":"PRO:rel"} ,
		{"word":"leur","word_nosc":"leur","lemma":"leur","pos":"PRO:per"} ,
		{"word":"leur","word_nosc":"leur","lemma":"leur","pos":"PRO:pos"} ,
		{"word":"leurs","word_nosc":"leurs","lemma":"leurs","pos":"PRO:pos"} ,
		{"word":"lui","word_nosc":"lui","lemma":"lui","pos":"PRO:per"} ,
		{"word":"lui-même","word_nosc":"lui-meme","lemma":"lui-même","pos":"PRO:per"} ,
		{"word":"m","word_nosc":"m","lemma":"m","pos":"PRO:per"} ,
		{"word":"me","word_nosc":"me","lemma":"me","pos":"PRO:per"} ,
		{"word":"mes","word_nosc":"mes","lemma":"mes","pos":"PRO:pos"} ,
		{"word":"mien","word_nosc":"mien","lemma":"mien","pos":"PRO:pos"} ,
		{"word":"mienne","word_nosc":"mienne","lemma":"mienne","pos":"PRO:pos"} ,
		{"word":"miennes","word_nosc":"miennes","lemma":"miennes","pos":"PRO:pos"} ,
		{"word":"miens","word_nosc":"miens","lemma":"miens","pos":"PRO:pos"} ,
		{"word":"moi","word_nosc":"moi","lemma":"moi","pos":"PRO:per"} ,
		{"word":"moi-même","word_nosc":"moi-meme","lemma":"moi-même","pos":"PRO:per"} ,
		{"word":"mon","word_nosc":"mon","lemma":"mon","pos":"PRO:pos"} ,
		{"word":"mézig","word_nosc":"mezig","lemma":"mézig","pos":"PRO:per"} ,
		{"word":"mézigue","word_nosc":"mezigue","lemma":"mézigue","pos":"PRO:per"} ,
		{"word":"même","word_nosc":"meme","lemma":"même","pos":"PRO:ind"} ,
		{"word":"mêmes","word_nosc":"memes","lemma":"mêmes","pos":"PRO:ind"} ,
		{"word":"n'","word_nosc":"n'","lemma":"n'","pos":"PRO:per"} ,
		{"word":"non","word_nosc":"non","lemma":"non","pos":"PRO:per"} ,
		{"word":"notre","word_nosc":"notre","lemma":"notre","pos":"PRO:pos"} ,
		{"word":"nous","word_nosc":"nous","lemma":"nous","pos":"PRO:per"} ,
		{"word":"nous-même","word_nosc":"nous-meme","lemma":"nous-même","pos":"PRO:per"} ,
		{"word":"nous-mêmes","word_nosc":"nous-memes","lemma":"nous-mêmes","pos":"PRO:per"} ,
		{"word":"nul","word_nosc":"nul","lemma":"nul","pos":"PRO:ind"} ,
		{"word":"nulle","word_nosc":"nulle","lemma":"nulle","pos":"PRO:ind"} ,
		{"word":"nôtre","word_nosc":"notre","lemma":"nôtre","pos":"PRO:pos"} ,
		{"word":"nôtres","word_nosc":"notres","lemma":"nôtres","pos":"PRO:pos"} ,
		{"word":"on","word_nosc":"on","lemma":"on","pos":"PRO:per"} ,
		{"word":"ou","word_nosc":"ou","lemma":"ou","pos":"PRO:per"} ,
		{"word":"où","word_nosc":"ou","lemma":"où","pos":"PRO:int"} ,
		{"word":"où","word_nosc":"ou","lemma":"où","pos":"PRO:rel"} ,
		{"word":"personne","word_nosc":"personne","lemma":"personne","pos":"PRO:ind"} ,
		{"word":"plusieurs","word_nosc":"plusieurs","lemma":"plusieurs","pos":"PRO:ind"} ,
		{"word":"qu","word_nosc":"qu","lemma":"qu","pos":"PRO:int"} ,
		{"word":"qu","word_nosc":"qu","lemma":"qu","pos":"PRO:rel"} ,
		{"word":"que","word_nosc":"que","lemma":"que","pos":"PRO:int"} ,
		{"word":"que","word_nosc":"que","lemma":"que","pos":"PRO:rel"} ,
		{"word":"quelqu'un","word_nosc":"quelqu'un","lemma":"quelqu'un","pos":"PRO:ind"} ,
		{"word":"quelqu'une","word_nosc":"quelqu'une","lemma":"quelqu'une","pos":"PRO:ind"} ,
		{"word":"quelques-unes","word_nosc":"quelques-unes","lemma":"quelques-unes","pos":"PRO:ind"} ,
		{"word":"quelques-uns","word_nosc":"quelques-uns","lemma":"quelques-uns","pos":"PRO:ind"} ,
		{"word":"qui","word_nosc":"qui","lemma":"qui","pos":"PRO:int"} ,
		{"word":"qui","word_nosc":"qui","lemma":"qui","pos":"PRO:rel"} ,
		{"word":"quiconque","word_nosc":"quiconque","lemma":"quiconque","pos":"PRO:ind"} ,
		{"word":"quiconque","word_nosc":"quiconque","lemma":"quiconque","pos":"PRO:int"} ,
		{"word":"quiconque","word_nosc":"quiconque","lemma":"quiconque","pos":"PRO:rel"} ,
		{"word":"quoi","word_nosc":"quoi","lemma":"quoi","pos":"PRO:int"} ,
		{"word":"quoi","word_nosc":"quoi","lemma":"quoi","pos":"PRO:rel"} ,
		{"word":"rien","word_nosc":"rien","lemma":"rien","pos":"PRO:ind"} ,
		{"word":"s","word_nosc":"s","lemma":"s","pos":"PRO:per"} ,
		{"word":"s'","word_nosc":"s'","lemma":"s'","pos":"PRO:per"} ,
		{"word":"se","word_nosc":"se","lemma":"se","pos":"PRO:per"} ,
		{"word":"ses","word_nosc":"ses","lemma":"ses","pos":"PRO:pos"} ,
		{"word":"sien","word_nosc":"sien","lemma":"sien","pos":"PRO:pos"} ,
		{"word":"sienne","word_nosc":"sienne","lemma":"sienne","pos":"PRO:pos"} ,
		{"word":"siennes","word_nosc":"siennes","lemma":"siennes","pos":"PRO:pos"} ,
		{"word":"siens","word_nosc":"siens","lemma":"siens","pos":"PRO:pos"} ,
		{"word":"soi","word_nosc":"soi","lemma":"soi","pos":"PRO:per"} ,
		{"word":"soi-même","word_nosc":"soi-meme","lemma":"soi-même","pos":"PRO:per"} ,
		{"word":"sécolle","word_nosc":"secolle","lemma":"sécolle","pos":"PRO:per"} ,
		{"word":"sézigue","word_nosc":"sezigue","lemma":"sézigue","pos":"PRO:per"} ,
		{"word":"t","word_nosc":"t","lemma":"t","pos":"PRO:per"} ,
		{"word":"t'","word_nosc":"t'","lemma":"t'","pos":"PRO:per"} ,
		{"word":"te","word_nosc":"te","lemma":"te","pos":"PRO:per"} ,
		{"word":"tel","word_nosc":"tel","lemma":"tel","pos":"PRO:ind"} ,
		{"word":"telle","word_nosc":"telle","lemma":"telle","pos":"PRO:ind"} ,
		{"word":"telles","word_nosc":"telles","lemma":"telles","pos":"PRO:ind"} ,
		{"word":"tels","word_nosc":"tels","lemma":"tels","pos":"PRO:ind"} ,
		{"word":"tien","word_nosc":"tien","lemma":"tien","pos":"PRO:pos"} ,
		{"word":"tienne","word_nosc":"tienne","lemma":"tienne","pos":"PRO:pos"} ,
		{"word":"tiennes","word_nosc":"tiennes","lemma":"tiennes","pos":"PRO:pos"} ,
		{"word":"tiens","word_nosc":"tiens","lemma":"tiens","pos":"PRO:pos"} ,
		{"word":"to","word_nosc":"to","lemma":"to","pos":"PRO:per"} ,
		{"word":"toi","word_nosc":"toi","lemma":"toi","pos":"PRO:per"} ,
		{"word":"toi-même","word_nosc":"toi-meme","lemma":"toi-même","pos":"PRO:per"} ,
		{"word":"tous","word_nosc":"tous","lemma":"tous","pos":"PRO:ind"} ,
		{"word":"tout","word_nosc":"tout","lemma":"tout","pos":"PRO:ind"} ,
		{"word":"toute","word_nosc":"toute","lemma":"toute","pos":"PRO:ind"} ,
		{"word":"toutes","word_nosc":"toutes","lemma":"toutes","pos":"PRO:ind"} ,
		{"word":"tu","word_nosc":"tu","lemma":"tu","pos":"PRO:per"} ,
		{"word":"tézig","word_nosc":"tezig","lemma":"tézig","pos":"PRO:per"} ,
		{"word":"tézigue","word_nosc":"tezigue","lemma":"tézigue","pos":"PRO:per"} ,
		{"word":"un","word_nosc":"un","lemma":"un","pos":"PRO:ind"} ,
		{"word":"une","word_nosc":"une","lemma":"une","pos":"PRO:ind"} ,
		{"word":"unes","word_nosc":"unes","lemma":"unes","pos":"PRO:ind"} ,
		{"word":"uns","word_nosc":"uns","lemma":"uns","pos":"PRO:ind"} ,
		{"word":"votre","word_nosc":"votre","lemma":"votre","pos":"PRO:pos"} ,
		{"word":"vous","word_nosc":"vous","lemma":"vous","pos":"PRO:per"} ,
		{"word":"vous-même","word_nosc":"vous-meme","lemma":"vous-même","pos":"PRO:per"} ,
		{"word":"vous-mêmes","word_nosc":"vous-memes","lemma":"vous-mêmes","pos":"PRO:per"} ,
		{"word":"vôtre","word_nosc":"votre","lemma":"vôtre","pos":"PRO:pos"} ,
		{"word":"vôtres","word_nosc":"votres","lemma":"vôtres","pos":"PRO:pos"} ,
		{"word":"y","word_nosc":"y","lemma":"y","pos":"PRO:per"} ,
		{"word":"ça","word_nosc":"ca","lemma":"ça","pos":"PRO:dem"}
	]
}