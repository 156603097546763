'use strict';

module.exports = {
    lexi: [
    	{"word":"afin qu","word_nosc":"afin qu","lemma":"afin qu","pos":"CON"} ,
		{"word":"afin que","word_nosc":"afin que","lemma":"afin que","pos":"CON"} ,
		{"word":"ains","word_nosc":"ains","lemma":"ains","pos":"CON"} ,
		{"word":"because","word_nosc":"because","lemma":"because","pos":"CON"} ,
		{"word":"car","word_nosc":"car","lemma":"car","pos":"CON"} ,
		{"word":"cependant","word_nosc":"cependant","lemma":"cependant","pos":"CON"} ,
		{"word":"comme","word_nosc":"comme","lemma":"comme","pos":"CON"} ,
		{"word":"comment","word_nosc":"comment","lemma":"comment","pos":"CON"} ,
		{"word":"donc","word_nosc":"donc","lemma":"donc","pos":"CON"} ,
		{"word":"et","word_nosc":"et","lemma":"et","pos":"CON"} ,
		{"word":"lorsqu","word_nosc":"lorsqu","lemma":"lorsqu","pos":"CON"} ,
		{"word":"lorsque","word_nosc":"lorsque","lemma":"lorsque","pos":"CON"} ,
		{"word":"mais","word_nosc":"mais","lemma":"mais","pos":"CON"} ,
		{"word":"ni","word_nosc":"ni","lemma":"ni","pos":"CON"} ,
		{"word":"néanmoins","word_nosc":"neanmoins","lemma":"néanmoins","pos":"CON"} ,
		{"word":"or","word_nosc":"or","lemma":"or","pos":"CON"} ,
		{"word":"ou","word_nosc":"ou","lemma":"ou","pos":"CON"} ,
		{"word":"parce qu","word_nosc":"parce qu","lemma":"parce qu","pos":"CON"} ,
		{"word":"parce que","word_nosc":"parce que","lemma":"parce que","pos":"CON"} ,
		{"word":"pasque","word_nosc":"pasque","lemma":"pasque","pos":"CON"} ,
		{"word":"pourquoi","word_nosc":"pourquoi","lemma":"pourquoi","pos":"CON"} ,
		{"word":"puis","word_nosc":"puis","lemma":"puis","pos":"CON"} ,
		{"word":"puisqu","word_nosc":"puisqu","lemma":"puisqu","pos":"CON"} ,
		{"word":"puisque","word_nosc":"puisque","lemma":"puisque","pos":"CON"} ,
		{"word":"puissamment","word_nosc":"puissamment","lemma":"puissamment","pos":"CON"} ,
		{"word":"qu","word_nosc":"qu","lemma":"qu","pos":"CON"} ,
		{"word":"quand","word_nosc":"quand","lemma":"quand","pos":"CON"} ,
		{"word":"quoique","word_nosc":"quoique","lemma":"quoique","pos":"CON"} ,
		{"word":"s","word_nosc":"s","lemma":"s","pos":"CON"} ,
		{"word":"s'","word_nosc":"s'","lemma":"s'","pos":"CON"} ,
		{"word":"si","word_nosc":"si","lemma":"si","pos":"CON"} ,
		{"word":"sinon","word_nosc":"sinon","lemma":"sinon","pos":"CON"} ,
		{"word":"soit","word_nosc":"soit","lemma":"soit","pos":"CON"} ,
		{"word":"tandis qu","word_nosc":"tandis qu","lemma":"tandis qu","pos":"CON"} ,
		{"word":"tandis que","word_nosc":"tandis que","lemma":"tandis que","pos":"CON"}
	]
}
